// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostIntelligentPartnerTaskInfosStatisticsCountByIdParam,
    PostIntelligentPartnerTaskInfosStatisticsCountByIdBody,
    BaseResponseListIntelligentPartnerTaskFinishCountRecord,
} from '../../../../types';

/**
 * 获取单个任务的执行情况(按次数)
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/statistics/count/{id}
 * @param id
 */
export function postIntelligentPartnerTaskInfosStatisticsCountById(
    data: PostIntelligentPartnerTaskInfosStatisticsCountByIdBody,
    options: ApiOptions<PostIntelligentPartnerTaskInfosStatisticsCountByIdParam, never> & {
        segments: PostIntelligentPartnerTaskInfosStatisticsCountByIdParam;
    }
): Promise<BaseResponseListIntelligentPartnerTaskFinishCountRecord> {
    return request<
        PostIntelligentPartnerTaskInfosStatisticsCountByIdBody,
        PostIntelligentPartnerTaskInfosStatisticsCountByIdParam,
        never
    >('/intelligentPartnerTaskInfos/statistics/count/{id}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTaskInfosStatisticsCountById',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/statistics/count/{id}',
        method: 'post',
    },
];
