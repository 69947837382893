// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetAgentScreenQuery, BaseResponseListAgentSearchValue } from '../../types';

/**
 * getListForScreen
 *
 * @export
 * @tags agent-controller
 * @link [GET] /agent/screen
 */
export function getAgentScreen(
    options: ApiOptions<never, GetAgentScreenQuery> & { params: GetAgentScreenQuery }
): Promise<BaseResponseListAgentSearchValue> {
    return request<never, never, GetAgentScreenQuery>('/agent/screen', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getAgentScreen',
    });
}

export const meta = [{ tags: ['agent-controller'], path: '/agent/screen', method: 'get' }];
