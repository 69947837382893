import EventedClass from './evented-class';
import utils from './utils';
import config from './config';
import enums from './enums';
import logger from './logger';
import data from './data';
import browser from './browser';
import ajax, { registerResponseParser } from './ajax';
import ui, { init as initUI, destroy as destroyUI } from './ui';
import app from './app';
import error from './error';
import vendor from './vendor';
import helpers from './helpers';
import business from './business';
import installSafeHtml from '../udesk-plugins/utils/web/safe-html';

class Udesk extends EventedClass {
    constructor() {
        super();
        this.utils = utils;
        this.config = config;
        this.enums = enums;
        this.logger = logger;
        this.data = data;
        this.ui = ui;
        this.browser = browser;
        this.ajax = ajax;
        this.app = app;
        this.error = error;
        this.vendor = vendor;
        this.helpers = helpers;
        this.business = business;
    }
    init({ enableEnumNameCache = true } = {}) {
        this.enums._rebuild({
            enableNameCache: enableEnumNameCache,
        });
        registerResponseParser();
        initUI.call(this.ui);
        // this.ui.setLocale(DEFAULT_LANGUAGE);
        if (!this._triggerInitEvent()) {
            this.trigger('init');
        }
    }
    _triggerInitEvent() {
        return false;
    }
    destroy() {
        // Should call this method when the application is unmount!
        // Especially useful for react SDKs.
        this.trigger('destroy');
        destroyUI.call(this.ui);
        this.off('init').off('destroy');
    }

    // 之所以禁用这段代码，而采用上面的形式，是因为这是ES7的规范，Ember中不支持。
    // 等Ember中支持了以后，需要再启用。
    // utils = utils
    // config = config
    // enums = enums
    // logger = logger
    // data = data
    // ui = ui
    // browser = browser
    // ajax = ajax
    // app = app
    // error = error
    // vendor = vendor
}

let udesk = new Udesk();
installSafeHtml(udesk);

export default udesk;
