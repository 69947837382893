// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostKnowledgeDatasImportBody, BaseResponseVoid } from '../../types';

/**
 * 导入话题
 *
 * @export
 * @tags knowledge-data-controller
 * @link [POST] /knowledgeDatas/import
 */
export function postKnowledgeDatasImport(
    data: PostKnowledgeDatasImportBody
): Promise<BaseResponseVoid>;
export function postKnowledgeDatasImport(
    data: PostKnowledgeDatasImportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postKnowledgeDatasImport(
    data: PostKnowledgeDatasImportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostKnowledgeDatasImportBody, never, never>('/knowledgeDatas/import', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postKnowledgeDatasImport',
    });
}

export const meta = [
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas/import', method: 'post' },
];
