import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';

class DialogueManage extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    privates = {
        childProps: {
            pageNum: Udesk.config.paging.defaultPageNumber,
            pageSize: Udesk.config.paging.defaultPageSize,
            total: null,
            selectedDatum: {},
            selectedFilter: {
                conditionList: [],
                customJudgeLogic: null,
                judgeStrategy: 1
            }
        }
    };
    init() {
        let that = this;
        this.privates.childProps.onPageChanged = function (pageNum, pageSize, total) {
            that.privates.childProps.pageNum = pageNum;
            that.privates.childProps.pageSize = pageSize;
            that.privates.childProps.total = total;
        };
        this.privates.childProps.getPageConfig = function () {
            let {
                pageNum,
                pageSize,
                total
            } = that.privates.childProps;
            return {
                pageNum: pageNum,
                pageSize: pageSize,
                total: total
            };
        };
        this.privates.childProps.onSelectedDatumChanged = function (item) {
            that.privates.childProps.selectedDatum = item;
        };
        this.privates.childProps.onSelectedFilterChanged = function (filter) {
            that.privates.childProps.selectedFilter = filter;
        };
    }

    render() {
        let {
            routes,
            route,
            location,
            sdkOptions,
        } = this.props;
        let {
            childProps
        } = this.privates;
        if (sdkOptions.props.landingPath != null && this.isRedirect(route.path, location.pathname)) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else if (this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name
            });
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else {
            return (
                <div className="quality-checking-task" style={{height: '100%'}}>
                    {routes.map((routeItem, i) => (
                        <SubRoutes key={i} route={routeItem} childProps={childProps} />
                    ))}
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(DialogueManage);