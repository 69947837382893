import React, { useEffect, useState, useMemo } from 'react';
import Udesk from 'Udesk';
import { FormBuilder, Radio, InputNumber, Button, Space } from 'udesk-ui';
import type { FormProps, FormFieldConfig } from 'udesk-ui/lib/form-builder/types/form';

import Page from 'Component/common/udesk-qa-page-layout';
import AnalysisModel from './component/analysis-model';
import MassagePushConfiguration from 'Component/pages/components/massage-push-configuration-wechat/index';
import Locales from 'UdeskLocales';
import './style.scss';

const FormMassagePushConfiguration = (props) => {
    const { value, onChange, ...otherProps } = props;
    return <MassagePushConfiguration onPushConfChange={onChange} pushConf={value} {...otherProps} />;
};

const Count = (props) => {
    const locales = Locales['current'];
    const formLabels = locales.pages.wechatQa.realTimeAnalysis.detail.form;

    const onChange = (value) => {
        props.onChange?.(Math.ceil(value));
    };
    return (
        <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
            {formLabels.pushConfigSwitchTip1}
            <InputNumber {...props} min={1} max={100} onChange={onChange} />
            {formLabels.pushConfigSwitchTip2}
        </div>
    );
};

const PushConfigSwitch = (props) => {
    const locales = Locales['current'];

    const { value, onChange } = props;
    const options = useMemo(
        () => [
            {
                label: locales.labels.switchOn,
                value: 1,
            },
            {
                label: locales.labels.switchOff,
                value: 0,
            },
        ],
        []
    );
    const onRadioChange = (e) => {
        let newValue = e.target.value;
        onChange?.(newValue);
    };
    return (
        <div className='push-config-switch'>
            <Radio.Group optionType='button' value={value} options={options} onChange={onRadioChange} />
            {Boolean(value) && (
                <FormBuilder.Item name='triggerThreshold'>
                    <Count />
                </FormBuilder.Item>
            )}
        </div>
    );
};

const cusConfigMap = {
    AnalysisModel: AnalysisModel,
    MassagePushConfiguration: FormMassagePushConfiguration,
    PushConfigSwitch: PushConfigSwitch,
};

// type CusConfigMap = typeof cusConfigMap;

const ScoreUnit = (props) => {
    const locales = Locales['current'];

    return <div className='udesk-qa-form-score-unit'>{locales.labels.scoreUnit}</div>;
};

function RealTimeAnalysis(props) {
    const locales = Locales['current'];
    const formLabels = locales.pages.wechatQa.realTimeAnalysis.detail.form;

    const id = props.match.params.id;
    const [initialValues, setInitialValues] = useState({
        pointIdListFront: [],
        eventConditionList: Udesk.enums.messagePushType.filter(filterFunction).map((item) => PushConfig(item.id)),
        triggerFlag: 0,
        triggerThreshold: 1,
        status: 1,
    });
    const [loading, setLoading] = useState(false);
    const [inspectionPointGroups, setInspectionPointGroups] = useState([]);
    const [wrapForm] = FormBuilder.useForm();
    // const [triggerPointId, setTriggerPointId] = useState<number | undefined>(undefined);
    const transitionToIndex = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'wechatQaRealTimeAnalysisIndex',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const onFinish = (values) => {
        let url = Udesk.business.apiPath.concatApiPath(`inspectionScenes`, props.sdkOptions);
        let params = { ...values };
        if (values.eventConditionList) {
            params.eventConditionList = values.eventConditionList.map((item) => ({
                type: item.actionType,
                enable: item.enabled ? 1 : 0,
                actionIdList: item.actionDetailId ? [item.actionDetailId] : [],
            }));
        }
        params.status = values.status ? 1 : 0;
        if (id) {
            let url = Udesk.business.apiPath.concatApiPath(`inspectionScenes/${id}`, props.sdkOptions);
            Udesk.ajax.put(url, params).then(
                (resp) => {
                    transitionToIndex();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
            return;
        }
        Udesk.ajax.post(url, params).then(
            (resp) => {
                transitionToIndex();
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const config: FormProps = {
        initialValues: initialValues,
        customComponents: cusConfigMap,
        onFinish: onFinish,
        fields: [
            {
                name: 'name',
                label: formLabels.name,
                type: 'Input',
                props: {
                    showCount: true,
                    maxCount: 20,
                    style: {
                        width: 400,
                    },
                    placeholder: locales.labels.pleaseEnter,
                },
                rules: [
                    {
                        required: true,
                    },
                    {
                        max: 20,
                        type: 'string',
                    },
                ],
            },
            {
                name: 'status',
                label: formLabels.status,
                type: 'Switch',
            },
            {
                name: 'triggerPointId',
                label: formLabels.prevCondition,
                type: 'Select',
                props: {
                    style: {
                        width: 350,
                    },
                    placeholder: locales.labels.pleaseSelect,
                    options: inspectionPointGroups,
                    allowClear: true,
                },
                tooltip: {
                    title: formLabels.prevConditionToolTip,
                },
                rules: [
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || !getFieldValue('pointIdListFront').includes(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(formLabels.prevConditionError));
                        },
                    }),
                ],
            },
            {
                name: 'initialScore',
                label: formLabels.baseScore,
                type: 'InputNumber',
                props: {
                    placeholder: formLabels.scoreInputPlaceholder,
                },
                addonAfter: <ScoreUnit />,
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'qualifiedScore',
                label: formLabels.passScore,
                type: 'InputNumber',
                props: {
                    placeholder: formLabels.scoreInputPlaceholder,
                },
                addonAfter: <ScoreUnit />,
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'scoreUpperLimit',
                label: formLabels.maxScore,
                type: 'InputNumber',
                addonAfter: <ScoreUnit />,
                props: {
                    placeholder: formLabels.scoreInputPlaceholder,
                },
                rules: [
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (
                                getFieldValue('scoreLowerLimit') === undefined ||
                                parseInt(getFieldValue('scoreLowerLimit'), 10) < parseInt(value, 10)
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(formLabels.scoreUpperLimitError));
                        },
                    }),
                ],
            },
            {
                name: 'scoreLowerLimit',
                label: formLabels.minScore,
                type: 'InputNumber',
                addonAfter: <ScoreUnit />,
                props: {
                    placeholder: formLabels.scoreInputPlaceholder,
                },
                rules: [
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (
                                getFieldValue('scoreUpperLimit') === undefined ||
                                parseInt(value, 10) < parseInt(getFieldValue('scoreUpperLimit'), 10)
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(formLabels.scoreUpperLimitError));
                        },
                    }),
                ],
            },
            {
                name: 'pointIdListFront',
                label: formLabels.analysisModel,
                type: 'AnalysisModel' as FormFieldConfig['type'],
                props: props,
                // rules: [
                //     ({ getFieldValue }) => ({
                //         validator(_, value) {
                //           if (!value || !value.includes(getFieldValue('prevCondition')) ) {
                //             return Promise.resolve();
                //           }
                //           return Promise.reject(new Error(formLabels.prevConditionError));
                //         },
                //     }),
                // ]
            },
            {
                name: 'triggerFlag',
                label: formLabels.message,
                type: 'PushConfigSwitch' as FormFieldConfig['type'],
                effect: {
                    visible: {
                        1: ['eventConditionList'],
                    },
                },
            },
            {
                name: 'eventConditionList',
                label: formLabels.pushType,
                type: 'MassagePushConfiguration' as FormFieldConfig['type'],
            },
        ],
        footerRender: () => {
            return (
                <Space>
                    <Button type='primary' htmlType='submit'>
                        {locales.labels[id ? 'save' : 'create']}
                    </Button>
                    <Button onClick={transitionToIndex}>{locales.labels.cancel}</Button>
                </Space>
            );
        },
    };
    // useEffect(() => {
    //
    // }, [triggerPointId])
    useEffect(() => {
        if (id === undefined) return;
        setLoading(true);
        let url = Udesk.business.apiPath.concatApiPath(`inspectionScenes/${id}`, props.sdkOptions);
        Udesk.ajax.get(url).then(
            (resp) => {
                let initValues = resp.data;
                if (!initValues.eventConditionList) {
                    initValues.eventConditionList = Udesk.enums.messagePushType
                        .filter(filterFunction)
                        .map((item) => PushConfig(item.id));
                } else {
                    initValues.eventConditionList = initValues.eventConditionList.map((item) =>
                        PushConfig(item.type, item.actionIdList, item.enable)
                    );
                }
                setInitialValues(initValues);
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, []);
    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues]);
    useEffect(() => {
        let url = Udesk.business.apiPath.concatApiPath(
            `inspectionPointGroups?pageNum=1&pageSize=1000`,
            props.sdkOptions
        );
        const getInspectionPointGroups = async () => {
            try {
                const inspectionPointGroups = await Udesk.ajax.get(url);
                let points: any[] = [];
                let groups = Array.isArray(inspectionPointGroups.data)
                    ? inspectionPointGroups.data.map((group) => {
                        points = Array.isArray(group.points) ? [...points, ...group.points] : points;
                        return {
                            label: group.name,
                            options: group.points.map((point: any) => {
                                return {
                                    label: point.name,
                                    value: point.id,
                                    groupId: point.groupId,
                                };
                            }),
                        };
                    })
                    : [];
                // let hasPoint = groups.length !== 0 && groups.some((group) => group.children?.length);
                // groups.forEach((group) => {
                //     if (group.children.every((c) => c.disabled)) {
                //         group.disabled = true;
                //     }
                // });

                setInspectionPointGroups(groups);
            } catch (err: any) {
                Udesk.ui.notify.error(err.errorMsg);
            }
        };
        getInspectionPointGroups();
    }, []);
    return (
        <Page
            pageBodyClassName='real-time-analysis-page-detail'
            title={locales.labels[id ? 'edit' : 'create'] + locales.pages.wechatQa.realTimeAnalysis.detail.title}
            onBack={transitionToIndex}
            padding={true}
            loading={loading}
        >
            <FormBuilder {...(config as FormProps)} form={wrapForm}></FormBuilder>
        </Page>
    );
}

export default React.memo(RealTimeAnalysis);

function PushConfig(messagePushType, actionDetailId?: number | number[], enabled?: number) {
    if (Array.isArray(actionDetailId)) {
        actionDetailId = actionDetailId[0];
    }
    let config = {
        pushCycle: 1,
        eventSources: [],
        executeTime: '',
        email: '',
        actionDetailId: actionDetailId,
        actionDetails: {
            actionType: messagePushType,
            pushTargetList: [],
            pushUserList: [],
            pushRoleList: [],
            content: '',
        },
        pushType: 1,
        actionType: messagePushType,
        enabled: enabled, //是否生效 被选中
    };
    return config;
}

function filterFunction(i) {
    return i.id <= 2 || i.id === 6;
}