// rules: object,array,Map 三种类型
// pick(), destroy()

import {
    ArgumentNullError,
    InvalidArgumentError,
    WrongTypeError
} from '../error';

const RULES_MAP_SYMBOL = Symbol("[RulesMap]");
const SORT_RULES_KEYS_SYMBOL = Symbol("[SORT_RULES_KEYS_SYMBOL]");

class ValuePicker {
    constructor(rules) {
        if (rules) {
            let rulesMap = new Map();

            if (Object.prototype.toString.call(rules) === "[object Object]") {
                for (let key in rules) {
                    if (Object.prototype.hasOwnProperty.call(rules,key)) {
                        rulesMap.set(key, rules[key]);
                    }
                }
            } else if (Array.isArray(rules)) {
                if (rules.length > 0) {
                    rules.forEach(rule => {
                        if (Object.prototype.hasOwnProperty.call(rule,"key")) {
                            if (Object.prototype.hasOwnProperty.call(rule,"value")) {
                                rulesMap.set(rule.key, rule.value);
                            } else {
                                throw new WrongTypeError("value", "Object");
                            }
                        } else {
                            throw new WrongTypeError("key", "Object");
                        }
                    });
                }
            } else if (rules instanceof Map) {
                rulesMap = rules;
            } else {
                throw new InvalidArgumentError("rules");
            }

            if (rulesMap && rulesMap.size > 0) {
                let ruleKeys = Array.from(rulesMap.keys());
                let hasExistNanType = ruleKeys.some(mapKey => {
                    let key = Number(mapKey);
                    if (isNaN(key)) {
                        return true;
                    } else {
                        return false;
                    }
                });

                if (hasExistNanType) {
                    throw new WrongTypeError("key", "Number");
                } else {
                    if (ruleKeys.length > 0) {
                        ruleKeys.sort((a, b) => Number(a) - Number(b));

                        this[SORT_RULES_KEYS_SYMBOL] = ruleKeys;
                        this[RULES_MAP_SYMBOL] = rulesMap;
                    }
                }
            }
        } else {
            throw new ArgumentNullError("rules");
        }
    }

    pick(key) {
        let newKey = Number(key);
        if (isNaN(newKey)) {
            throw new InvalidArgumentError("key");
        } else {
            let ruleKeys = this[SORT_RULES_KEYS_SYMBOL];
            let minKey = ruleKeys[0];
            let maxKey = ruleKeys[ruleKeys.length - 1];

            let targetKey = minKey;
            if (key <= minKey) {
                targetKey = minKey;
            } else if (key >= maxKey) {
                targetKey = maxKey;
            } else {
                let keysLength = ruleKeys.length;
                for (let i = 1; i <= keysLength - 1; i++) {
                    let prevKey = ruleKeys[i - 1];
                    let currentKey = ruleKeys[i];
                    if (key > prevKey && key <= currentKey) {
                        targetKey = currentKey;
                        break;
                    }
                }
            }

            let targetValue = this[RULES_MAP_SYMBOL].get(targetKey);
            if (targetValue == null) {
                targetValue = this[RULES_MAP_SYMBOL].get(maxKey);
            }
            return targetValue;
        }
    }

    destroy() {
        this[SORT_RULES_KEYS_SYMBOL] = null;

        this[RULES_MAP_SYMBOL].clear();
        this[RULES_MAP_SYMBOL] = null;
    }
}

export default ValuePicker;