import TemplateComponentsListDetailComponent from './component';
import Udesk from 'Udesk';
class TemplateComponentsListDetailRoute extends TemplateComponentsListDetailComponent {
    asyncModel() {
        let { sdkOptions, match } = this.props;
        let myDetailPromise = new Promise((resolve, reject) => {
            resolve({});
        });
        let urlData = Object.assign({}, Object.fromEntries(new URLSearchParams(this.props.location.search)));
        if (match.params.id) {
            let myDetail = Udesk.business.apiPath.concatApiPath(
                `inspectionModules/${match.params.id}/${urlData.version}/1`,
                sdkOptions
            );
            myDetailPromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(myDetail).then(
                    (resp) => {
                        resolve(resp);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        }
        return {
            myDetailData: myDetailPromise,
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}

export default TemplateComponentsListDetailRoute;
