import React, { FC, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import { List, Space, Icon, Button, Popconfirm, Avatar, Tag } from 'udesk-ui';
import './index.scss';
import UdeskLocales from 'UdeskLocales';
import { WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { ToDoFoundResponse } from 'src/api/types';
import { formatDuration } from 'src/util/date';

type TodoListProps = {
    todoData?: ToDoFoundResponse[];
    page?: {
        current?: number;
        pageSize?: number;
        total?: number;
    };
    handlerCallDetail?: (callId: number, todoId: number) => void;
    handlerIgnore?: (id: number) => void;
    handlerPageChange?: (current: number, pageSize?: number) => void;
    loading?: boolean;
    history?: any;
};

const TodoList: FC<TodoListProps> = (props) => {
    const { todoData, page } = props;
    const pageMemo = useRef(page);
    const handlerCallDetail = (callId, todoId) => {
        props.handlerCallDetail && props.handlerCallDetail(callId, todoId);
    };
    const handlerIgnore = (id) => {
        props.handlerIgnore && props.handlerIgnore(id);
    };
    const handlerCoachTast = (id) => {
        const routeOptions = {
            history: props.history,
            routeName: 'coachTaskList',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        const dom = document.getElementsByClassName('home-page-to-do-list-container')?.[0];
        pageMemo.current?.current !== page?.current && dom && (dom.scrollTop = 0);
        pageMemo.current = page;
    }, [page]);
    return (
        <List
            className="home-page-to-do-list-container"
            size="large"
            header={null}
            footer={null}
            pagination={{
                ...page,
                onChange: props.handlerPageChange,
            }}
            loading={props.loading}
            dataSource={todoData}
            renderItem={(item) => (
                <List.Item>
                    <Space direction="vertical" className="components-common-to-do-list-container">
                        <Space className="user-name-message">
                            {/* 是企微通话/会话，且是群聊 */}
                            {(item.inspectDataSource === 13 || item.inspectDataSource === 14) &&
                            item.sessionType === 2 ? (
                                <h3 onClick={handlerCallDetail.bind(null, item.callId, item.id)}>
                                    {item.roomName}
                                </h3>
                            ) : (
                                <>
                                    <h3
                                        onClick={handlerCallDetail.bind(null, item.callId, item.id)}
                                    >
                                        {item.agentName}
                                    </h3>
                                    <h3
                                        onClick={handlerCallDetail.bind(null, item.callId, item.id)}
                                    >
                                        {item.customerName}
                                    </h3>
                                </>
                            )}
                        </Space>
                        <Space className="user-name-durtion">
                            <p
                                style={{
                                    padding: '0 8px',
                                    borderRadius: 2,
                                    fontSize: 12,
                                    color: 'color: rgba(0, 0, 0, 0.65)',
                                    border: '1px solid rgba(0, 0, 0, 0.15)',
                                }}
                            >
                                {/* 是企微会话是，为文本，显示会话条数 */}
                                {item.inspectDataSource === 14
                                    ? `${item.sentenceNum || 0}${
                                          /* 条 */ UdeskLocales['current'].pages.gong.homePage
                                              .common.todoList.strip
                                      }`
                                    : (parseInt(
                                          ((item.voiceDuration || 0) / 1000).toString() ?? 0
                                      ) || 0) /
                                          60 <=
                                      1
                                    ? parseInt(((item.voiceDuration || 0) / 1000).toString() ?? 0) +
                                      's'
                                    : (
                                          (parseInt(
                                              ((item.voiceDuration || 0) / 1000).toString() ?? 0
                                          ) || 0) / 60
                                      ).toFixed(0) + 'm'}
                            </p>
                            <span style={{ fontSize: 12, color: 'color: rgba(0, 0, 0, 0.45)' }}>
                                {item.callTime}
                            </span>
                        </Space>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {item.taskType === Udesk.enums.todoListType.later.id ? (
                                <Space>
                                    <Icon style={{ fontSize: 16 }} type="ic-add-case-line"></Icon>
                                    <span>
                                        {
                                            UdeskLocales['current'].pages.gong.homePage.common
                                                .todoList.theSessionIsAddedTolistenLater
                                        }
                                    </span>
                                </Space>
                            ) : item.taskType === Udesk.enums.todoListType.help.id ? (
                                <Space>
                                    <Icon style={{ fontSize: 16 }} type="ic-ask-tiwen"></Icon>
                                    <Avatar src={item.seekUserPicUrl} />
                                    <span>
                                        {item.seekUserName}
                                        {
                                            UdeskLocales['current'].pages.gong.homePage.common
                                                .todoList.askYouForHelp
                                        }
                                    </span>
                                </Space>
                            ) : item.taskType === Udesk.enums.todoListType.contact.id ? (
                                <Space>
                                    <Icon
                                        style={{ fontSize: 16 }}
                                        type="ic-phone-pause-zantingtonghua"
                                    ></Icon>
                                    <span>
                                        {item.customerName}{' '}
                                        {
                                            UdeskLocales['current'].pages.gong.homePage.common
                                                .todoList.iHopeToContactYouLater
                                        }
                                    </span>
                                </Space>
                            ) : item.taskType === Udesk.enums.todoListType.send.id ? (
                                <React.Fragment>
                                    <Space>
                                        <Icon
                                            style={{ fontSize: 16 }}
                                            type="ic-file-upload-shangchuanwenjian"
                                        ></Icon>
                                        <span>
                                            {item.customerName}{' '}
                                            {
                                                UdeskLocales['current'].pages.gong.homePage.common
                                                    .todoList
                                                    .lookingForwardToSendingTheInformationLater
                                            }
                                        </span>
                                    </Space>
                                </React.Fragment>
                            ) : item.taskType === Udesk.enums.todoListType.warn.id ? (
                                <div style={{ flex: 1 }}>
                                    {item.smartTagInfos
                                        ? item.smartTagInfos.map((info) => {
                                              return (
                                                  <div style={{ display: 'flex' }}>
                                                      <WarningOutlined
                                                          style={{
                                                              verticalAlign: 'top',
                                                              padding: '6px 8px 0 0',
                                                          }}
                                                      />
                                                      <div style={{ flex: 1 }}>
                                                          <Space>
                                                              <span>
                                                                  {item.customerName}{' '}
                                                                  {
                                                                      UdeskLocales['current'].pages
                                                                          .gong.homePage.common
                                                                          .todoList.dialogueRisk
                                                                  }
                                                              </span>
                                                              <Tag
                                                                  color="warning"
                                                                  style={{
                                                                      marginBottom: 4,
                                                                      display: 'inline-flex',
                                                                      overflow: 'hidden',
                                                                      maxWidth: '100%',
                                                                  }}
                                                              >
                                                                  <div
                                                                      title={info.categoryName}
                                                                      style={{
                                                                          flex: 1,
                                                                          overflow: 'hidden',
                                                                          textOverflow: 'ellipsis',
                                                                      }}
                                                                  >
                                                                      {info.categoryName}
                                                                  </div>
                                                                  <span style={{ margin: '0 5px' }}>
                                                                      |
                                                                  </span>
                                                                  <div>{info.tagName}</div>
                                                              </Tag>
                                                          </Space>
                                                          {info.dialogs
                                                              ? info.dialogs.map((dialog) => {
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                color: 'rgba(140, 140, 140, 1)',
                                                                                margin: '4px 0 6px 0',
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    marginRight:
                                                                                        '8px',
                                                                                }}
                                                                            >
                                                                                {dialog.time}
                                                                            </span>
                                                                            {dialog.role ? (
                                                                                <span
                                                                                    style={{
                                                                                        marginRight:
                                                                                            '8px',
                                                                                    }}
                                                                                >
                                                                                    [{dialog.role}]
                                                                                </span>
                                                                            ) : null}
                                                                            <span>
                                                                                {dialog.text}
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                })
                                                              : null}
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </div>
                            ) : null}
                            <span style={{ fontSize: 12, lineHeight: '22px' }}>
                                {item.createTime}
                            </span>
                        </div>
                        {(item.taskType === Udesk.enums.todoListType.contact.id ||
                            item.taskType === Udesk.enums.todoListType.send.id) && (
                            <p className="replay-content">
                                {formatDuration(item.todoTime)} {item.content}
                            </p>
                        )}
                        <Space className="components-common-to-do-list-actions">
                            {item.viewFlag ? (
                                <Space size={2}>
                                    <CheckCircleOutlined />
                                    {
                                        UdeskLocales['current'].pages.gong.homePage.common.todoList
                                            .checked
                                    }
                                </Space>
                            ) : null}
                            {item.taskType === Udesk.enums.todoListType.help.id && (
                                <React.Fragment>
                                    <Space size={2}>
                                        <Icon
                                            style={{ fontSize: 16, color: '#0FBA26' }}
                                            type="ic-right-circle-duigou1"
                                        ></Icon>
                                        <Button
                                            onClick={handlerIgnore.bind(null, item.id)}
                                            style={{ color: '#0FBA26' }}
                                            type="link"
                                        >
                                            {
                                                UdeskLocales['current'].pages.gong.homePage.common
                                                    .todoList.tutored
                                            }
                                        </Button>
                                    </Space>
                                    <Space size={2}>
                                        <Icon
                                            style={{ fontSize: 16, color: '#1A6EFF' }}
                                            type="ic-send-line1"
                                        ></Icon>
                                        <Button
                                            onClick={handlerCoachTast.bind(null, item.id)}
                                            type="link"
                                        >
                                            {
                                                UdeskLocales['current'].pages.gong.homePage.common
                                                    .todoList.goToCounseling
                                            }
                                        </Button>
                                    </Space>
                                </React.Fragment>
                            )}
                            {item.taskType === Udesk.enums.todoListType.contact.id && (
                                <Space size={2}>
                                    <Icon
                                        style={{ fontSize: 16, color: '#0FBA26' }}
                                        type="ic-right-circle-duigou1"
                                    ></Icon>
                                    <Button
                                        onClick={handlerIgnore.bind(null, item.id)}
                                        style={{ color: '#0FBA26' }}
                                        type="link"
                                    >
                                        {
                                            UdeskLocales['current'].pages.gong.homePage.common
                                                .todoList.contacted
                                        }
                                    </Button>
                                </Space>
                            )}
                            {item.taskType === Udesk.enums.todoListType.send.id && (
                                <Space size={2}>
                                    <Icon
                                        style={{ fontSize: 16, color: '#0FBA26' }}
                                        type="ic-right-circle-duigou1"
                                    ></Icon>
                                    <Button
                                        onClick={handlerIgnore.bind(null, item.id)}
                                        style={{ color: '#0FBA26' }}
                                        type="link"
                                    >
                                        {
                                            UdeskLocales['current'].pages.gong.homePage.common
                                                .todoList.hasBeenSent
                                        }
                                    </Button>
                                </Space>
                            )}
                            <Popconfirm
                                title={
                                    UdeskLocales['current'].pages.gong.homePage.common.todoList
                                        .areYouSureYouWantToIgnoreIt
                                }
                                onConfirm={handlerIgnore.bind(null, item.id)}
                                okText={
                                    UdeskLocales['current'].pages.gong.homePage.common.todoList
                                        .determine
                                }
                                cancelText={
                                    UdeskLocales['current'].pages.gong.homePage.common.todoList
                                        .cancel
                                }
                            >
                                <Space size={2}>
                                    <Icon
                                        style={{ fontSize: 14, color: '#1A6EFF' }}
                                        type="ic-minus-circle-jian2"
                                    ></Icon>
                                    <Button type="link">
                                        {
                                            UdeskLocales['current'].pages.gong.homePage.common
                                                .todoList.ignore
                                        }
                                    </Button>
                                </Space>
                            </Popconfirm>
                        </Space>
                    </Space>
                </List.Item>
            )}
        />
    );
};

export default TodoList;
