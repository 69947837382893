import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import LoadMore from './load-more';
import ChatRecordList from './chat-record-list';
// import Udesk from 'Udesk';

import './style.scss';
import { Empty, Spin } from 'udesk-ui';

interface HighlightItem {
    highlightType: 1 | 2;
    index: number;
    length?: number | null;
    start: number;
}
interface HighlightGroup {
    highlightItemList: HighlightItem[];
}
interface HighlightPoints {
    pointId: number;
    highlightGroupsList: HighlightGroup[];
}
interface ChatRecordProps {
    data: any[];
    loadMoreData?: (position) => void;
    width?: number;
    height: number;
    onItemMounted?: (item) => void;
    loading?: boolean;
    showTopMore?: boolean;
    highlightPoint?: string | null;
    allHighlightPoints?: HighlightPoints[];
    closeHighlight?: () => void;
    defaultScrollToTop?: boolean;
    propsFromDetail?: any;
    /**
     * @manualHighlightIndex 录音播放的高亮索引
     */
    manualHighlightIndex?: number | null;
    virtual?: boolean;
    searchHighlightKeywords?: string;
    searchHighlightRoleType?: 'all' | 'customer' | 'agent';
    callId?: number;
    changeToHighlightItemsCallDetail?: (callId?:number) => void;
    showCheckbox?: boolean;
    onMessageItemCheckboxClick?: (ids: (number | string)[]) => void;
    // markFlag?: boolean;
    allHighlightMap?: {[key: string]: any};
    inspectDataSource?: number;
    isDebug?: boolean;
}

const ChatRecord = forwardRef<any, ChatRecordProps>(
    (
        {
            data,
            loadMoreData,
            showTopMore = true,
            height: propsHeight,
            onItemMounted,
            loading,
            highlightPoint,
            allHighlightPoints,
            closeHighlight,
            defaultScrollToTop,
            propsFromDetail,
            manualHighlightIndex,
            virtual = true,
            // markFlag,
            searchHighlightKeywords,
            searchHighlightRoleType,
            callId = null,
            changeToHighlightItemsCallDetail,
            showCheckbox,
            onMessageItemCheckboxClick,
            allHighlightMap,
            inspectDataSource,
            isDebug,
            
            ...args
        },
        ref
    ) => {
        const [position, setPosition] = useState('bottom');
        const loadMore = (position) => {
            setTimeout(() => {
                loadMoreData && loadMoreData(position);
                setPosition(position);
            }, 0);
        };
        const listRef = useRef(null);
        useImperativeHandle(ref, () => listRef.current);
        
        return (
            <div className='udesk-qa-chat-record-list'>
                {Boolean(data?.length) && showTopMore && (
                    <div className='load-more top' style={{ width: '100%' }}>
                        <LoadMore onClick={loadMore.bind(null, 'top')} loading={loading} position='top' />
                    </div>
                )}
                <Spin spinning={loading}>
                    {data.length > 0 ? (
                        <ChatRecordList
                            height={propsHeight}
                            data={data}
                            position={position}
                            ref={listRef}
                            // onItemMounted={onItemMounted}
                            highlightPoint={highlightPoint}
                            allHighlightPoints={allHighlightPoints}
                            closeHighlight={closeHighlight}
                            defaultScrollToTop={defaultScrollToTop}
                            propsFromDetail={propsFromDetail}
                            manualHighlightIndex={manualHighlightIndex}
                            virtual={virtual}
                            searchHighlightKeywords={searchHighlightKeywords}
                            searchHighlightRoleType={searchHighlightRoleType}
                            // markFlag={markFlag}
                            callId={callId}
                            changeToHighlightItemsCallDetail={changeToHighlightItemsCallDetail}
                            showCheckbox={showCheckbox}
                            onMessageItemCheckboxClick={onMessageItemCheckboxClick}
                            allHighlightMap={allHighlightMap}
                            inspectDataSource={inspectDataSource}
                            isDebug={isDebug}
                        />
                    ) : (
                        loading || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </Spin>
                {/* <div className='load-more bottom' style={{width: '100%'}}>
                <LoadMore onClick={loadMore.bind(null, 'bottom')} position='bottom' />
            </div> */}
            </div>
        );
    }
);

export default React.memo(ChatRecord);
export type { ChatRecordProps };
