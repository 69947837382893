export default {
  lang: {
    export: 'Exportar',
    successfully: 'La tarea de exportación se creó correctamente',
    pleaseJumpTo: 'Por favor salte a',
    uploadAndDownloadCenter: 'Centro de carga y descarga',
    check: 'Ver',
    exportAll: '¿Confirmar la exportación de todo el contenido?',
    total: 'Total',
    confirm: '¿Datos de barra, confirmar la exportación?',
    error:
      'La cantidad de datos que se pueden exportar es 0, vuelva a intentarlo después de cambiar el filtro',
  },
};
