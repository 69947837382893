// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetDataCallSessionFlowsSubmitQuery, BaseResponseVoid } from '../../types';

/**
 * 提交生成对话流程请求
 *
 * @export
 * @tags 销售赋能-会话流程接口
 * @link [GET] /dataCallSessionFlows/submit
 */
export function getDataCallSessionFlowsSubmit(
    options: ApiOptions<never, GetDataCallSessionFlowsSubmitQuery> & {
        params: GetDataCallSessionFlowsSubmitQuery;
    }
): Promise<BaseResponseVoid> {
    return request<never, never, GetDataCallSessionFlowsSubmitQuery>(
        '/dataCallSessionFlows/submit',
        { ...options, method: 'get', __$requestCalleeName: 'getDataCallSessionFlowsSubmit' }
    );
}

export const meta = [
    { tags: ['销售赋能-会话流程接口'], path: '/dataCallSessionFlows/submit', method: 'get' },
];
