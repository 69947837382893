import React, { useCallback, useMemo, useState } from 'react';
import { Button, Space } from 'udesk-ui';
import ColumnLeftTitle from '../columnLeftTitle';
import {MAX_WIDTH, LINE_HEIGHT, MIN_WIDTH} from '../config';
import {WechatFilled, PhoneFilled} from "@udesk/icons";
import CustomTooltip, { formatTime } from '../customer-tooltip';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

export default (props) => {
    const {locales, bindModal, companyName, userNameList, dataCallList, userName, handlerModal} = props;
    const [isClosed, setIsClosed] = useState(true);

    const handlerToggle = useCallback(() => {
        if (userNameList?.length > 0) {
            setIsClosed(isClosed => !isClosed);
        }
    }, [
        userNameList
    ]);

    const leftTitle = useMemo(() => {
        return (
            <ColumnLeftTitle 
                {...{
                    className: 'company-name',
                    title: companyName,
                    list: userNameList,
                    isClosed,
                    handlerToggle,
                    footer: [
                        <li className='left-line user-name'>{userName}</li>,
                        <li>
                            <Button type='link' onClick={handlerModal} disabled={!dataCallList.length}>
                                {locales.pages.gong.saleClientCenter.detail.index.contactOverview}
                            </Button>
                        </li>,
                    ]
                }}
            />
        );
    }, [
        companyName, 
        dataCallList.length, 
        handlerModal, 
        handlerToggle, 
        isClosed, 
        locales.pages.gong.saleClientCenter.detail.index.contactOverview, 
        userName, 
        userNameList
    ]);

    const columnRender = useCallback((item, key, index, columns) => {
        return <UlComponent {...{isClosed, item, bindModal, key, companyName, userName, index, columns}}/>;
    }, [bindModal, companyName, isClosed, userName]);

    return [leftTitle, columnRender];
};

export const getIcon = (obj) => {
    let item = obj;

    if (Array.isArray(obj)) {
        item = obj[0];
    }
    if (item) {
        const inspectDataSource = item.inspectDataSource;
        if ([
            Udesk.enums.inspectDataSources.wechatRadio.id,
            Udesk.enums.inspectDataSources.wechatDialogue.id
        ].includes(inspectDataSource)) {
            return <WechatFilled />;
        }

        if ([
            Udesk.enums.inspectDataSources.voiceCall.id
        ].includes(inspectDataSource)) {
            return <PhoneFilled />;
        }
    }

    return null;
};

const UlComponent = React.memo((props: any) => {
    const {isClosed, item, bindModal, key, index, columns, companyName, userName} = props;
    const [visible, setVisible] = useState(false);
    const callTotal = item.data && item.data.speechDuration + item.data.customerSpeechDuration;
    const agentWidth = Math.max((callTotal && item.data && (item.data.speechDuration / callTotal) * MAX_WIDTH ), MIN_WIDTH);
    const customWidth = Math.max((callTotal && item.data && (item.data.customerSpeechDuration / callTotal) * MAX_WIDTH), 1);
    const widthBg = Math.max(agentWidth, customWidth);
    const heightBg = (isClosed ? 1 : item.children.length) * LINE_HEIGHT + 8;
    const {dataCallAnalysisList: list} = item;
    const icon = getIcon(list);
    
    const $ul = useMemo(() => {
        return isClosed ? (
            <>
                <ul 
                    className='user-comparison-proportion' 
                    key={key} 
                    onClick={() => {
                        if (item.data) {
                            bindModal?.open('1', item.data.timeStr);
                        }
                    }}
                >
                    {item.data && agentWidth && customWidth ? (
                        <li
                            style={{
                                width: widthBg,
                                height: heightBg,
                                borderRadius: widthBg,
                                left: `calc(50% - ${widthBg / 2}px)`,
                            }}
                            className='contrast-bg'
                        ></li>
                    ) : null}
                    <li>
                        {item.data && (
                            <Circle {...{ icon, visible, agentWidth, customWidth, type: 'agent'}}/>
                        )}
                    </li>
                    <li>
                        {item.data && (
                            <Circle {...{ icon, visible, agentWidth, customWidth, type: 'customer'}}/>
                        )}
                    </li>
                </ul>
            </>
        ) : (
            <ul 
                className='user-comparison-proportion' 
                key={key}
                onClick={() => {
                    if (item.data) {
                        bindModal?.open('1', item.data.timeStr);
                    }
                }}
            >
                {!!customWidth && item.children?.every((i: number) => i && i >= 0) ? (
                    <li
                        style={{
                            width: customWidth,
                            height: heightBg,
                            borderRadius: customWidth,
                            left: `calc(50% - ${customWidth / 2}px)`,
                            top: '64px',
                        }}
                        className='contrast-bg'
                    ></li>
                ) : null}
                {item.children.map((record: number, index: React.Key | null | undefined) => {
                    let callTotal = record && item.customData && item.customData + record;
                    let agentWidth = record && (record / callTotal) * MAX_WIDTH;
                    return (
                        <>
                            {index === 0 && <li></li>}
                            <li key={index}>
                                {!!record && (
                                    <Circle {...{ icon, visible, agentWidth, customWidth, type: 'agent'}}/>
                                )}
                            </li>
                        </>
                    );
                })}
                <li>
                    {!!item.customData && (
                        <Circle {...{ icon, visible, agentWidth, customWidth, type: 'customer'}}/>
                    )}
                </li>
            </ul>
        );
    }, [
        icon,
        agentWidth, 
        bindModal, 
        customWidth, 
        heightBg, 
        key, isClosed, 
        item.children, 
        item.customData, 
        item.data, 
        visible, 
        widthBg
    ]);

    const getPlacement = (index, list) => {
        if (index < 3) {
            return 'bottomLeft';
        }
        if (index > list.length - 3) {
            return 'bottomRight';
        }
        return 'bottom';
    };

    return (
        <>
            {
                list?.length > 0 ? (
                    <CustomTooltip
                        onVisibleChange={visible => setVisible(visible)}
                        placement={getPlacement(index, columns)}
                        title={[
                            `${userName}～${companyName}`,
                            list.map(item => {
                                return (
                                    <div className='custom-tooltip-content-item'>
                                        <Space className='time'>
                                            <span>{formatTime(item.createTime)}</span>
                                            <span>{getIcon(item)}</span>
                                        </Space>
                                        <div>{/* 关键事件： */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row0.keyEvents}{item.keyEventsList.map(keyEvent => keyEvent.tagName).join('、')}</div>
                                    </div>
                                );
                            })
                        ]} 
                        getPopupContainer={triggerNode => {
                            return $(triggerNode).parents('.udesk-qa-ui-table-container').get(0);
                        }}
                    >
                        {$ul}
                        <span></span>
                    </CustomTooltip>
                ) : $ul
            }
            <ul className='user-comparison-proportion' ><li></li></ul>
        </>
    );  
});

const Circle = React.memo((props:any) => {
    const {visible, agentWidth, customWidth, type, icon} = props;    
    const width = type === 'customer' ? customWidth : agentWidth;
    const className = type === 'customer' ? 'bg-custom' : 'bg-user';
    const style:any = {
        width,
        height: width,
        lineHeight: width,
        fontSize: width * 60 / 14  + '%'
    };

    if (type === 'agent') {
        if (customWidth < 1) {
            style.transform = 'translateX(0%)';
        }
    }

    return width ? (
        <i
            style={style}
            className={className}
        >
            <div>
                {
                    visible ? icon : null
                }
            </div>
        </i>
    ): null;
});