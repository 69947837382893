import Udesk from 'Udesk';
import React from 'react';
import { notification, Button, Icon } from 'udesk-ui';
import XssComponent from 'udesk-react/src/components/xss';
import { getCurrentRouteName } from 'Udesk/system/subApp';
export default class MainMenuComponent extends React.Component {
    //#region state
    state = {
        showUploadDownModel: false,
    };
    //#endregion

    //#region Life Cycle
    componentDidMount() {
        const changeLogUrl = Udesk.business.apiPath.concatApiPath(
            'updateNotices/last',
            this.props.sdkOptions
        );
        Udesk.ajax.get(changeLogUrl).then((resp) => {
            if (resp.data && resp.data.id) {
                let title = (
                    <div style={{ display: 'flex' }}>
                        <Icon
                            style={{ fontSize: '22px', marginRight: 8 }}
                            type="InfoCircleTwoTone"
                            antdIcon={true}
                        />
                        <div className="qa-update-change-log-title">{resp.data.noticeTitle}</div>
                    </div>
                );
                let description = (
                    <React.Fragment>
                        <div className="qa-update-change-log-list">
                            {resp.data.noticeContent && (
                                <XssComponent
                                    classNames="qa-update-change-log-list-content"
                                    value={resp.data.noticeContent}
                                />
                            )}
                            {resp.data.noticeContent &&
                                resp.data.noticeContent.split('<p>').length > 4 && (
                                    <div className="qa-update-change-log-item">......</div>
                                )}
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                onClick={() => notification.close('changeLog')}
                                style={{ marginRight: 8 }}
                                className="udesk-qa-ui-btn"
                            >
                                {this.locales.labels.ignore}
                            </Button>
                            <Button
                                onClick={() =>
                                    resp.data.noticeLink && window.open(resp.data.noticeLink)
                                }
                                className="udesk-qa-ui-btn udesk-qa-ui-btn-primary"
                                type="primary"
                            >
                                {this.locales.labels.seeNow}
                            </Button>
                        </div>
                    </React.Fragment>
                );
                notification.open({
                    duration: null,
                    key: 'changeLog',
                    top: 70,
                    className: 'qa-update-change-log-list-notification',
                    message: title,
                    description: description,
                });
                this.actions.markChangeLog(resp.data.id);
            }
        });
    }
    componentWillUnmount() {}
    //#endregion

    //#region actions
    actions = {
        messageMenuNotice(e) {
            const routeOptions = {
                history: this.props.history,
                routeName: 'adminMsgCenterReply',
            };
            if (e.key === 'reply') {
                routeOptions.routeName = 'adminMsgCenterReply';
            } else if (e.key === 'answer') {
                routeOptions.routeName = 'adminMsgCenterAnswer';
            } else if (e.key === 'share') {
                routeOptions.routeName = 'adminMsgCenterShare';
            } else if (e.key === 'subscription') {
                routeOptions.routeName = 'adminMsgCenterSubscription';
            } else if (e.key === 'study') {
                routeOptions.routeName = 'adminMsgCenterStudy';
            } else if (e.key === 'coach-share') {
                routeOptions.routeName = 'coachMsgCenterShare';
            }

            Udesk.ui.routing.transitionTo(routeOptions);
        },
        menuClick(e) {
            if (e.key === 'logout') {
                const routeOptions = {
                    history: this.props.history,
                    routeName: 'authLogout',
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
            if (e.key === 'systemInformation') {
                window.open('/help/index.html', '_blank');
            }
            if (e.key === 'billingCenter') {
                // 跳转时需要删除下面三项，否则有页面污染
                this.props.sdkOptions.props.biTaskId = null;
                this.props.sdkOptions.props.task = null;
                this.props.sdkOptions.props.basicInfoFields = null;
                const routeOptions = {
                    history: this.props.history,
                    routeName: 'billingCenterIndex',
                };
                const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
                if (hasFeature && hasFeature('hc:charge:root')) {
                    Udesk.ui.routing.transitionTo(routeOptions);
                }
            }
            if (e.key === 'setting') {
                console.log(getCurrentRouteName('adminEntry'));

                const routeOptions = {
                    history: this.props.history,
                    routeName: getCurrentRouteName('adminEntry'),
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        markChangeLog(noticeId) {
            const changeLogUrl = Udesk.business.apiPath.concatApiPath(
                'updateNoticeReadLogs',
                this.props.sdkOptions
            );
            Udesk.ajax.post(changeLogUrl, { noticeId, status: 1 });
        },
        uploadDownModelVisibleChanged(value) {
            this.setState({ showUploadDownModel: value });
        },
    };
    //#endregion
}
