// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseDataCallAnalysisOrgOptionResponse } from '../../types';

/**
 * 获取部门员工列表
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/organizations
 */
export function getIntelligentPartnerTaskInfosOrganizations(): Promise<BaseResponseDataCallAnalysisOrgOptionResponse>;
export function getIntelligentPartnerTaskInfosOrganizations(
    options: ApiOptions<never, never>
): Promise<BaseResponseDataCallAnalysisOrgOptionResponse>;
export function getIntelligentPartnerTaskInfosOrganizations(
    options?: ApiOptions<never, never>
): Promise<BaseResponseDataCallAnalysisOrgOptionResponse> {
    return request<never, never, never>('/intelligentPartnerTaskInfos/organizations', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerTaskInfosOrganizations',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/organizations',
        method: 'get',
    },
];
