import React from "react";
import Udesk from 'Udesk';
import _debounce from 'lodash-es/debounce';
import Locales from 'UdeskLocales';

class BusinessAnalysisFilterComponent extends React.Component {
    privates = {
        num: 0,
        saleBusinessList: [
            {
                type: "1",
                text: /* 关键词 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.keyWord
            },
            {
                type: "3",
                text: /* 会话标签 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.sessionLabel
            },
            {
                type: "4",
                text: /* 关键事件 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.keyEvents
            },
        ],
        saleFieldsList: [
            {
                id: "1",
                list: [
                    {
                        type: "contains_any",
                        name: /* 匹配任意 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.matchAny
                    },
                    {
                        type: "contains_all",
                        name: /* 匹配全部 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.matchAll
                    }
                ]
            },
            {
                id: "3",
                list: [
                    {
                        type: "is",
                        name: /* 等于 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.beEqualTo
                    },
                    {
                        type: "contains_all",
                        name: /* 包含全部 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.includeAll
                    },
                    {
                        type: "is_any",
                        name: /* 包含任意 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.includeAny
                    }
                ]
            },
            {
                id: "4",
                list: [
                    {
                        type: "is",
                        name: /* 等于 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.beEqualTo
                    },
                    {
                        type: "contains_all",
                        name: /* 包含全部 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.includeAll
                    },
                    {
                        type: "is_any",
                        name: /* 包含任意 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.includeAny
                    }
                ]
            },
        ],
        businessList: [
            {
                type: "0",
                text: /* 高级条件 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.advancedConditions
            },
            {
                type: "1",
                text: /* 关键词 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.keyWord
            },
            {
                type: "2",
                text: /* 智能标签 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.smartTag
            }
        ],
        fieldsList: [
            {
                id: "0",
                list: []
            },
            {
                id: "2",
                list: [
                    {
                        type: "is",
                        name: /* 等于 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.beEqualTo
                    },
                    {
                        type: "contains_all",
                        name: /* 包含全部 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.includeAll
                    },
                    {
                        type: "is_any",
                        name: /* 包含任意 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.includeAny
                    }
                ]
            },
            {
                id: "1",
                list: [
                    {
                        type: "contains_any",
                        name: /* 匹配任意 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.matchAny
                    },
                    {
                        type: "contains_all",
                        name: /* 匹配全部 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.matchAll
                    }
                ]
            }
        ],
        intelligenceList: [
            {
                value: "17",
                text: /* 智能标签一 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.smartTagI
            },
            {
                value: "18",
                text: /* 智能标签二 */Locales.current.pages.intelligentAnalysis.businessAnalysis.detail.businessAnalysisFiter.component.smartTagII
            }
        ],
        data: [],
        timer: null,
        findAllSamrtTagsItems: []
    };
    actions = {
        //添加二级及以下分类
        addTwoCustome(value) {
            let { num } = this.privates;
            this.props.fieldAddList(value.num, num);
        },
        //删除二级及以下分类分类
        delTwoCustome(value) {
            this.props.fieldDelList(value);
            this.actions.update();
        },
        dataSourceChanged(item, value) {
            this.privates.data.map(items => {
                if (items.num === item.num) {
                    items.type = value;
                }
                return items;
            });
            this.actions.update();
            this.props.dataSourceChanged(item.num, value);
        },
        dataSourceFromChanged(item, value) {
            this.privates.data.map(items => {
                if (items.num === item.num) {
                    items.operator = value;
                }
                return items;
            });
            this.actions.update();
            this.props.dataSourceFromChanged(item.num, value);
        },
        dataSourceFromThreeChanged(item, str, value) {
            if (str === "input") {
                this.privates.data.map(items => {
                    if (items.num === item.num) {
                        items.value = value.target.value;
                    }
                    return items;
                });
                this.actions.update();
                this.props.dataSourceFromThreeChanged(item.num, value.target.value);
            } else if (str === "select") {
                this.privates.data.map(items => {
                    if (items.num === item.num) {
                        items.value = value;
                    }
                    return items;
                });
                this.actions.update();
                this.props.dataSourceFromThreeChanged(item.num, value);
            }
        },
        dataSourceFromfiveChanged(item, value) {
            this.privates.data.map(items => {
                if (items.num === item.num) {
                    items.value = value;
                }
                return items;
            });
            this.actions.update();
            this.props.dataSourceFromfiveChanged(item.num, value);
        },
        timerInputColor(callBack, param1, param2) {
            if (this.privates.timer) {
                clearTimeout(this.privates.timer);
            }
            this.privates.timer = setTimeout(() => {
                callBack(param1, param2);
            }, 300);
        },
        InputColorItem(item, e) {
            this.actions.timerInputColor(
                this.props.InputColorItemChange,
                item,
                e.target.value
            );
        },
        newAddFilter() {
            this.props.newAddFilterFileds(true);
        },
        displacement(value, str) {
            this.props.displacementChange(value, str);
        },
        searchField(value) {
            let { sdkOptions } = this.props;
            let smartTagsUrl = Udesk.business.apiPath.concatApiPath(
                `smartTags/findAll`,
                sdkOptions
            );
            let findAllSamrtTagsData = {
                pageNum: 1,
                pageSize: 1000,
                tagName: value
            };
            new Promise((resolve, reject) => {
                Udesk.ajax.post(smartTagsUrl, findAllSamrtTagsData).then(
                    resp => {
                        this.privates.findAllSamrtTagsItems = resp.data;
                        resolve(resp.data);
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onSearchSelect: _debounce((value) => {
            this.actions.searchField(value);
        }, 300, { 'trailing': true }),

    };
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.privates.data = this.props.data;
            this.privates.num = this.props.num;
            this.actions.update();
        }
    }
    componentDidMount() {
        this.privates.data = this.props.data;
        this.privates.num = this.props.num;
        this.actions.searchField('');
        this.actions.update();
    }
}
export default BusinessAnalysisFilterComponent;
