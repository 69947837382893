import React, { FC, useContext } from 'react';
import { Item } from '../Container';
import Filter from './components/Filter';
import ItemWraaper from './components/ItemWrapper';
import DoneStatus from './components/DoneStatus';
import ComprehensiveEvaluation from './components/ComprehensiveEvaluation';
import AbilityGrowthTrend from './components/AbilityGrowthTrend';
import ErrorAnalysis from './components/ErrorAnalysis';
import { FilterContext } from '../../context';
import UdeskLocales from 'UdeskLocales';

type EmployeeDataCardsProps = {};

const EmployeeDataCards: FC<EmployeeDataCardsProps> = (props) => {
    const [params] = useContext(FilterContext);

    return (
        <>
            <Item widthRatio={1}>
                <Filter />
            </Item>

            <Item widthRatio={5 / 8}>
                <ItemWraaper title={/* 完成情况 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.index.completionStatus}>
                    <DoneStatus params={params} />
                </ItemWraaper>
            </Item>

            <Item widthRatio={3 / 8}>
                <ItemWraaper title={/* 综合评价 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.index.comprehensiveEvaluation}>
                    <ComprehensiveEvaluation params={params} />
                </ItemWraaper>
            </Item>

            <Item widthRatio={5 / 8}>
                <ItemWraaper title={/* 能力成长趋势 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.index.abilityGrowthTrend}>
                    <AbilityGrowthTrend params={params} />
                </ItemWraaper>
            </Item>

            <Item widthRatio={3 / 8}>
                <ItemWraaper title={/* 易错点分析 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.index.errorProneAnalysis}>
                    <ErrorAnalysis params={params} />
                </ItemWraaper>
            </Item>
        </>
    );
};

export default EmployeeDataCards;
