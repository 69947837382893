import React, {useEffect, useState, useMemo, useCallback} from 'react';
import { TreeSelect, Tag } from 'udesk-ui';

const formatId = (prefix, departmentId, id) => {
    if (typeof id !== 'undefined') {
        return [prefix, departmentId, id].join('/');
    }
    if (typeof departmentId !== 'undefined') {
        return [prefix, departmentId].join('/');
    }
    return prefix.split('/').reverse()[0];
};

const getField = (data, name, isLeaf) => {
    if (typeof name === 'string') {
        return data[name];
    }
    if (typeof name === 'function') {
        return name(data, isLeaf);
    }
};

const addFolder = (id, cache, list = [], tempCache ={}) => {
    if (!tempCache[id]) {
        const folder = cache[id];
        if (folder) {
            list.push(folder);
            tempCache[id] = folder;
            if (folder.pId) {
                addFolder(folder.pId, cache, list, tempCache);
            }
        }
    }
};

class TreeCache {
    list = []; // TreeSelect的数据
    allLeafMap = {}; // 以前缀ID为Key的Map
    leafMap = {}; // 以裸ID为Key的Map
    repeatLeafMap= {}; // 以裸ID为Key的重复前缀ID数组
    disableKeys = [];

    static formatFolderId = formatId.bind(null, 'F');
    static formatLeafId = formatId.bind(null, 'L');

    constructor(config) {
        let tempFolderMap = {};
        let tempLeafMap = {};
        let tempRepeatLeafMap = {};

        const {formatFolderId, formatLeafId} = TreeCache;
        const {
            list, 
            allLeafMap, 
            leafMap, 
            repeatLeafMap 
        } = this;

        const {
            folderData, 
            leafData, 
            fieldNames = {
                id: 'id',
                pId: 'parentId',
                name: 'name', 
                children: 'children'
            },
        } = config;

        if (
            !folderData ||
            !leafData || 
            folderData.length === 0 ||
            leafData.length === 0
        ) {
            return;
        }

        leafData.forEach((leaf) => {
            // map中的key必须和children中的id相对应
            tempLeafMap[getField(leaf, fieldNames.id, true)] = leaf;
        });

        folderData.forEach((folder) => {
            const folderId =  getField(folder, fieldNames.id);
            const folderNodeId = formatFolderId(folderId);
            const children = getField(folder, fieldNames.children);
            const node = {
                id: folderNodeId,
                pId: formatFolderId(getField(folder, fieldNames.pId)),
                label: getField(folder, fieldNames.name),
                value: folderNodeId,
                data: folder
            };

            if (children) {
                children.forEach((leafId) => {
                    const leaf = tempLeafMap[leafId];
                    const leafNodeId = formatLeafId(folderId, leafId);
                    allLeafMap[leafNodeId] = {
                        id: leafNodeId,
                        pId: folderNodeId,
                        label: getField(leaf, fieldNames.name, true),
                        value: leafNodeId,
                        isLeaf: true,
                        data: leaf
                    };
                    if (tempRepeatLeafMap[leafId]) {
                        repeatLeafMap[leafId] = repeatLeafMap[leafId] || [tempRepeatLeafMap[leafId]];
                        repeatLeafMap[leafId].push(leafNodeId);
                    } else {
                        leafMap[leafId] = allLeafMap[leafNodeId];
                        tempRepeatLeafMap[leafId] = leafNodeId;
                    }
                    list.push(allLeafMap[leafNodeId]);
                });
            }

            tempFolderMap[folderNodeId] = node;
        });

        // 过滤没有叶子节点的父级节点
        let folderList = [];
        let tempCache = {};
        list.forEach((leaf) => {
            addFolder(leaf.pId, tempFolderMap, folderList, tempCache);
        });
        list.splice(list.length - 1, 0, ...folderList);

        tempCache = undefined;
        tempFolderMap = undefined;
        tempLeafMap = undefined;
        tempRepeatLeafMap = undefined;
    }
    getValue(value, preValue, isGetFolderId) {
        const {allLeafMap} = this;
        const map = {}; // 存放未过滤的裸id缓存
        const folderMap = {};

        if (value) {
            // 第一步，将重复裸id过滤掉，并将结果保存到map中
            value.forEach((id) => {
                const folderId = id.split('/')[1];
                if (folderId) {
                    folderMap[folderId] = 0;
                }
                
                if (!map[formatId(id)]) {
                    map[formatId(id)] = allLeafMap[id];
                }
            });
        }

        let ids = Object.getOwnPropertyNames(map); // 获取不重复的裸id

        if(preValue) {
            // 获取删除的id,此处id为带前缀的id
            const delIds = preValue.filter((item) => {
                return !value.includes(item.value);
            });
            // 过滤掉所有重复的id
            delIds.forEach(({value: id}) => {
                id = formatId(id);

                const index = ids.indexOf(id);
                if (index > -1) {
                    ids.splice(index, 1);
                }
            });
        }

        if (isGetFolderId) {
            return [ids, Object.getOwnPropertyNames(folderMap)];
        }

        return ids;
    }
    getTreeValue(value) {
        const { repeatLeafMap } = this;
        const nodeIds = this.getRealTreeValue(value);

        let allRepeatNodeIds = []; // 存放所有重复的id
        nodeIds.forEach((realId) => {
            const id = formatId(realId);
            // 将重复的id添加到repeat数组中，此处包含未勾选的重复id
            if (repeatLeafMap[id]) {
                const repeatIds = repeatLeafMap[id];
                allRepeatNodeIds = allRepeatNodeIds.concat(repeatIds.filter((repeatId) => {
                    return repeatId !== realId;
                }));
            }
        });
        return [].concat(nodeIds, allRepeatNodeIds);
    }
    getRealTreeValue(value) {
        const {leafMap} = this;
        const nodeIds = [];

        value.forEach((id) => {
            const leaf = leafMap[id];
            if (leaf) {
                nodeIds.push(leaf.id);
            }
        });

        return nodeIds;
    }
    disableNodeByKeys(keys) {
        const {allLeafMap, disableKeys} = this;

        if (disableKeys) {
            const nodeIds = this.getTreeValue(disableKeys);
            nodeIds.forEach(id =>{
                allLeafMap[id].disabled = false;
            });
        }
        if (keys) {
            const nodeIds = this.getTreeValue(keys);
            nodeIds.forEach(id =>{
                allLeafMap[id].disabled = true;
            });
            this.disableKeys = keys;
        }
    }
}

export const MTMTreeSelect = (props) => {
    const {
        value, 
        onChange, 
        data,
        disableKeys = [],
        maxTagCount = 4,
        fieldNames,
        width,
        isGetFolderId,
    } = props;
    const [treeData, setTreeData] = useState([]);
    const [treeValue, setTreeValue] = useState([]);
    const [realTreeValue, setRealTreeValue] = useState([]);

    const treeCache = useMemo(() => {
        return new TreeCache({
            ...data,
            fieldNames: Object.assign({
                id: 'id',
                pId: 'parentId',
                name: 'name', 
                children: 'users'
            }, fieldNames)
        });
    }, [data, fieldNames]);

    const getValue = useCallback(() => {
        if (isGetFolderId) {
            return value ? value[0] : (value || []);
        } else {
            return value || [];
        }
    }, [value, isGetFolderId]);

    useEffect(() => {
        setTreeData(treeCache.list);
    }, [treeCache]);

    useEffect(() => {
        treeCache.disableNodeByKeys(disableKeys);
        setTreeData([...treeCache.list]);
    }, [disableKeys, treeCache]);

    useEffect(() => {
        setTreeValue(treeCache.getTreeValue(getValue()));
        setRealTreeValue(treeCache.getRealTreeValue(getValue()));
    }, [value, treeCache, getValue]);

    const getMaxTagCount = useCallback(() => {
        const realLength = realTreeValue.length;
        const length = treeValue.length;
        if (length > maxTagCount) {
            if (realLength <= maxTagCount) {
                return length;
            }
        }
        return maxTagCount;
    }, [realTreeValue.length, treeValue.length, maxTagCount]);

    const changeHandle = useCallback((value, label, event) => {
        onChange(treeCache.getValue(value, event.checked ? false : event.preValue, isGetFolderId));
    }, [isGetFolderId, onChange, treeCache]);

    const tagRender = useCallback((props) => {
        return (
            <Tag 
                visible={realTreeValue.includes(props.value) || props.value === undefined} 
                closable={props.closable} 
                onClose={props.onClose}>
                <span style={{
                    maxWidth: '200px',
                    display: 'inline-flex',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>{props.label}</span>
            </Tag>
        );
    }, [realTreeValue]);

    const maxTagPlaceholder = useCallback(() => {
        if (realTreeValue.length > maxTagCount) {
            return <span>{`+${realTreeValue.length - maxTagCount}`}</span>;
        }
    }, [maxTagCount, realTreeValue.length]);

    return (
        <TreeSelect
            style={{width}}
            multiple
            treeCheckable
            value={treeValue}
            onChange={changeHandle}
            maxTagCount={getMaxTagCount()}
            maxTagPlaceholder={maxTagPlaceholder}
            tagRender={tagRender}
            treeNodeFilterProp="label"
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeData={treeData}
            treeDataSimpleMode={true}
        />
    );
};