import Udesk from 'Udesk';
import React from 'react';
import AdminUserGroupsIndexRoute from './route';
import UdeskPagination from 'Component/common/udesk-pagination';
import ReactTable from 'udesk-react/src/components/react-table';
import { NavLink } from 'react-router-dom';
import { PageHeader } from 'udesk-ui';
import { isQaSubApp } from 'Udesk/system/subApp';

export default class AdminUserGroupsIndexTemplate extends AdminUserGroupsIndexRoute {
    render() {
        const { actions, locales, /* props, */ state, privates } = this;
        const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
        return (
            // <div className="page admin-user-groups-index">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.pages.admin.userGroups.index.name}
                    onBack={actions.userHistoryBack}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root admin-user-groups-index' style={{ padding: 16 }}>
                        <div className="page-content">
                            <div className="x_panel clearfix">
                                <div className="x_content clearfix">
                                    <If condition={hasFeature && hasFeature('sys:group:list')}>
                                        <div className="ember-table-header-bar ember-table-header-bar-striped clearfix">
                                            <div className="pull-left">
                                                {locales.pages.admin.userGroups.index.existingUserGroups}
                                            </div>
                                            <If condition={hasFeature && hasFeature('sys:group:add')}>
                                                <div className="pull-right">
                                                    <NavLink to={`/${isQaSubApp() ? 'site' : 'conversation-analysis'}/admin/user-groups/new`} className={'action-link'}>
                                                        <i
                                                            className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tianjia1"
                                                            title={locales.labels.create}></i>
                                                    </NavLink>
                                                </div>
                                            </If>
                                        </div>
                                        <ReactTable columns={state.columns} items={privates.model.userGroups.data} />
                                        {/* <ReactPager
                                            pageNum={privates.model.userGroups.paging.pageNum}
                                            pageSize={privates.model.userGroups.paging.pageSize}
                                            total={privates.model.userGroups.paging.total}
                                            languageCode={props.sdkOptions.props.language}
                                            pageChanged={actions.pageChanged} /> */}
                                        <UdeskPagination
                                            current={privates.model.userGroups.paging.pageNum}
                                            pageSize={privates.model.userGroups.paging.pageSize}
                                            total={privates.model.userGroups.paging.total}
                                            onChange={actions.pageChanged}
                                        />
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
