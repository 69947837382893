import React from 'react';
// import Locales from 'UdeskLocales';
import { Table } from 'udesk-ui';
// import './style.scss';

// const locales = Locales['current'];

const Template = React.memo((props: any) => {
    return <Table {...props} />;
});

export default Template;
