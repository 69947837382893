/*
 * @Author: 唐毓斌
 * @Description: 任务详情
 */
import React, { useEffect, useState } from 'react';
import Page from 'Component/common/udesk-qa-page-layout'; 
import Udesk from 'Udesk';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import SimplePieChart from './components/SimplePieChart';
import ThreePieChart from './components/ThreePieChart';
import GaueChart from './components/GaueChart';
import { Tag, Button } from 'udesk-ui';
import OrganizationMemberSelect from '../../../components/OrganizationMemberSelect';
import { useCoachUserTreeDataWithPermit } from 'src/util/hook/user';
import './index.less';
// import { useRequest } from 'src/util/hook';
import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import {  postIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskId } from 'src/api/intelligentPartnerTaskInfos/speed/analysis/record/{taskId}';



const TaskCenterDetail: React.FC<any> = (props) => {
    const locales = UdeskLocales['current'];
    const { history, match } = props;
    // const request = useRequest();
    const [taskInfo, setTaskInfo] = useState<any>();
    const [taskInfoExtra, setTaskInfoExtra] = useState<any>({});
    const [loading, setLoading] = useState(false);

    // --筛选start
    const [userSelectObj, setUserSelectObj] = useState<any>({
        userIds: [],
        organizationIds: []
    });
    const { treeData } = useCoachUserTreeDataWithPermit();
    const treeDataChange = (data) => {
        // console.log(data);
        setUserSelectObj(data);
    };
    const [ confirmFilterParams, setConfirmFilterParams] = useState<any>({
        userSelectObj: {
            userIds: [],
            organizationIds: []
        }
    });
    const filterConfirm = () => {
        // console.log('confirm',userSelectObj);
        setConfirmFilterParams({
            userSelectObj
        });
    };
    const filterReset = () => {
        setConfirmFilterParams({
            userSelectObj: {
                userIds: [],
                organizationIds: []
            }
        });
        setUserSelectObj({
            userIds: [],
            organizationIds: []
        });
    };
    // --筛选end

    const onBack = () => {
        const routeName = 'coachTaskCenterList';
        Udesk.ui.routing.transitionTo({
            history,
            routeName,
        });
    };

    const init = () => {
        const id = match?.params?.id || '0';
        if (id !== '0') {
            setLoading(true);
            getIntelligentPartnerTaskInfosById({
                segments: { id },
            })
                .then((resp) => {
                    let values: any = {
                        ...resp?.data,
                    };
                    //画像
                    values.imageIdInfo = {
                        status: 2,
                    };
                    if (values.imageId != null) {
                        values.imageIdInfo.status = 1;
                        values.imageIdInfo.selectedItemId = values.imageId;
                    }
                    // 时长限制
                    values.limitDurationInfo = {
                        status: 1,
                    };
                    if (values.limitDuration != null) {
                        values.limitDurationInfo.status = 2;
                        values.limitDurationInfo.inputValue = values.limitDuration;
                    }
                    // 时间
                    values.taskTimeInfo = {
                        status: 1,
                        viewText:
                            /* 不限时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .index.unlimitedTime,
                    };
                    if (values.taskStartTime && values.taskStartTime) {
                        values.taskTimeInfo.status = 2;
                        values.taskTimeInfo.viewText =
                            moment(values.taskStartTime).format('YYYY-MM-DD HH:mm') +
                            /* 至 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .to +
                            moment(values.taskEndTime).format('YYYY-MM-DD HH:mm');
                    }
                    // 评分标准
                    values.gradingStandartInfo = {
                        commitScore: values.commitScore,
                        excellentScore: values.excellentScore,
                    };
                    // 完成要求
                    values.completeStandartInfo = {};
                    if (values.completeByTimes != null) {
                        values.completeStandartInfo.timesStatus = true;
                        values.completeStandartInfo.completeByTimes = values.completeByTimes;
                    }
                    if (values.completeByScore != null) {
                        values.completeStandartInfo.scoreStatus = true;
                        values.completeStandartInfo.completeByScore = values.completeByScore;
                    }
                    setTaskInfo(values);
                    // getTaskScore(values);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
            let params = {
                ...confirmFilterParams.userSelectObj
            };
            postIntelligentPartnerTaskInfosSpeedAnalysisRecordByTaskId(params, {segments: {taskId: id}})
            // request('/intelligentPartnerTaskInfos/speed/analysis/record/'+ id, params, 'post')
            .then((resp) => {
                if (resp?.data) {
                    setTaskInfoExtra(resp?.data || {});
                }
            });
        }
    };

    useEffect(() => {
        init();
    }, [confirmFilterParams.userSelectObj]);

    return (
        <Page
            pageBodyClassName="task-center-detail-container"
            backGroundGhost={true}
            title={`${taskInfo?.taskName || ''}-${
                taskInfo?.taskType === 1
                    ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                          .practice
                    : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                          .examination
            }`}
            onBack={onBack}
        >
                <div className="tcd-header-wrapper">
                        <div className="tcd-header-filter-box" style={{background: '#fff'}}>
                            <div className="tcd-header-filter-box-left">
                                {UdeskLocales['current'].pages.coach.taskCenter.detail.index.staff}：
                                <OrganizationMemberSelect 
                                    placeholder={locales.labels.pleaseSelect}
                                    width={200}
                                    value={userSelectObj}
                                    onChange={treeDataChange}
                                    treeData={treeData}
                                />
                            </div>
                            <div className="tcd-header-filter-box-right">
                                <Button onClick={filterReset}>{locales.labels.reset}</Button>
                                <Button type="primary" onClick={filterConfirm}>{locales.labels.confirm}</Button>
                            </div>
                        </div>
                </div>
                <div className="tcd-header-wrapper">
                                <div className="tcd-header-title">
                                <p> 
                                    {
                                        /* 累计 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                        .accumulative
                                    }
                                    {
                                        taskInfo?.taskType === 1
                                            ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                .practice
                                            : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                .examination
                                    }
                                    {
                                        /* 次数 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                        .frequency
                                    }
                                    <span style={{color: '#5B8FF9'}}>{taskInfoExtra.practiceCount || '-'}</span>
                                    {
                                        /* 次 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                        .times
                                    }
                                </p>
                                <p>
                                    {
                                        /* 累计 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                        .accumulative
                                    }
                                    {
                                        taskInfo?.taskType === 1
                                            ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                .practice
                                            : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                .examination
                                    }
                                    {
                                        /* 话术 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                        .script
                                    }
                                    <span style={{color: '#5B8FF9'}}>{taskInfoExtra.sentenceCount || '-'}</span>
                                    {
                                        /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                        .sentence
                                    }
                                </p>
                                </div>
                                <div className="tcd-header-echarts no-border">
                                    <p className="echarts-title">
                                        {
                                            /* 表达平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .expressionAverage
                                        }
                                    </p>
                                    <p className="echarts-title">
                                        {
                                            /* 语气词分析 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .modalAnalysis
                                        }
                                    </p>
                                </div>
                                <div className="tcd-header-echarts">
                                        <GaueChart
                                            id="executeRateChart11122"
                                            loading={loading}
                                            height={360}
                                            chartTitle={''}
                                            maxValue={taskInfoExtra.maxSpeedScore || 100}
                                            subTitle={
                                                /* 表达平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .implementationRate
                                            }
                                            title={taskInfoExtra.executeRate + '%'}
                                            data={{value: taskInfoExtra.avgSpeedScore}}
                                        />
                                        <SimplePieChart
                                            id="executeRateChart1677"
                                            loading={loading}
                                            height={300}
                                            chartTitle={/* 共 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .total + (taskInfoExtra.sentenceCount || '0' ) + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .sentence}
                                            subTitle={ ' ' }
                                            title={taskInfoExtra.sentenceCount?
                                                ((taskInfoExtra.modalParticleTooMuchTimesSentenceCount/ 
                                                taskInfoExtra.sentenceCount)* 100 ).toFixed(2)+ '%': ' '}
                                            textFontSize={30}
                                            colorArr={['#5B8FF9', '#5AD8A6']}
                                            data={[
                                                {
                                                    name: /* 存在语气词过多 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .muchModalAnalysis + taskInfoExtra.modalParticleTooMuchTimesSentenceCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.modalParticleTooMuchTimesSentenceCount,
                                                },
                                                {
                                                    name: /* 正常 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .normal + taskInfoExtra.normalMuchTimesSentenceCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.normalMuchTimesSentenceCount,
                                                },
                                            ]}
                                        />
                                </div>
                                <div className="tcd-header-echarts no-border">
                                    <p className="echarts-title">
                                        {
                                            /* 语速分析 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .speedAnalysis
                                        }
                                    </p>
                                    <p className="echarts-title">
                                        {
                                            /* 语速分布 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .speedDistribution
                                        }
                                    </p>
                                </div>
                                <div className="tcd-header-echarts">
                                    <GaueChart
                                            id="executeRateChart111223"
                                            // width='calc(25% - 8px)'
                                            loading={loading}
                                            height={360}
                                            chartTitle={' '}
                                            subTitle={
                                                /* 平均语速 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .implementationRate
                                            }
                                            maxValue={taskInfoExtra.maxSpeedAverageSpeed || 100}
                                            title={taskInfoExtra.executeRate + '%'}
                                            data={{value: taskInfoExtra.averageSpeed }}
                                        />
                                        <ThreePieChart
                                            id="executeRateChart16778"
                                            // width='calc(25% - 8px)'
                                            loading={loading}
                                            height={300}
                                            chartTitle={/* 共 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .total+ (taskInfoExtra.sentenceCount || '0' ) + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .sentence}
                                            subTitle={ '' }
                                            title={''}
                                            colorArr={["#5B8FF9",  "#5AD8A6",  "#F6BD16"]}
                                            data={[
                                                {
                                                    name: /* 正常语速 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .normalSpeak + taskInfoExtra.normalTimesCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.normalTimesCount,
                                                },
                                                {
                                                    name: /* 过快语速 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .tooFastSpeak + taskInfoExtra.speedFastTimesCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.speedFastTimesCount,
                                                },
                                                {
                                                    name: /* 过慢语速 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .tooSlowlySpeak + taskInfoExtra.speedSlowTimesCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.speedSlowTimesCount,
                                                },
                                            ]}
                                        />
                                </div>
                                <div className="tcd-header-echarts no-border">
                                    <p className="echarts-title">
                                        {
                                            /* 违规词分析 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .violationWordAnalysis
                                        }
                                    </p>
                                    <p className="echarts-title">{' '}</p>
                                </div>
                                <div className="tcd-header-echarts">
                                        <SimplePieChart
                                            id="executeRateChart1677889"
                                            // width='calc(25% - 8px)'
                                            loading={loading}
                                            height={300}
                                            chartTitle={/* 共 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .total+ (taskInfoExtra.sentenceCount || '0' ) + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                            .sentence}
                                            subTitle={ ' ' }
                                            title={taskInfoExtra.sentenceCount?
                                                ((taskInfoExtra.violationsSentenceCount/ 
                                                taskInfoExtra.sentenceCount)* 100 ).toFixed(2)+ '%': ' '}
                                            textFontSize={30}
                                            colorArr={['#5B8FF9', '#5AD8A6']}
                                            data={[
                                                {
                                                    name: /* 正常 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .normal  + taskInfoExtra.normalViolationsSentenceCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.normalViolationsSentenceCount,
                                                },
                                                {
                                                    name: /* 出现违规 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .violationWord  + taskInfoExtra.violationsSentenceCount + /* 句 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .sentence,
                                                    value: taskInfoExtra.violationsSentenceCount,
                                                },
                                            ]}
                                        />
                                        <div className="Illegal-box">
                                            <div className="Illegal-title">
                                                {
                                                    /* 出现违规 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                    .violationWord
                                                }
                                            </div>
                                            <div className="Illegal-content">
                                                {(taskInfoExtra.violationsWordList && taskInfoExtra.violationsWordList.length > 0) ? (
                                                    taskInfoExtra.violationsWordList.map(item => {
                                                        return (
                                                            <Tag color="geekblue">{item}</Tag>
                                                        );
                                                    })
                                                ) : /* 暂无 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                                .noData}
                                            </div>
                                        </div>
                                </div>
                            </div>
                
                    
        </Page>
    );
};

export default hookProps(TaskCenterDetail);
