import React, { FC, memo } from 'react';
import BraftEditor, { BraftEditorProps } from 'braft-editor';
import 'braft-editor/dist/index.css';
import UdeskLocales from 'UdeskLocales';

interface TemplateProps extends Omit<BraftEditorProps, 'language'> {}

// 这里，我们不使用内置语言，全部自定义
export const language = (languages: any, context: any) => {
    const custom = {
        base: {
            remove: /* 删除 */ UdeskLocales['current'].components.bEditor.index.delete,
            cancel: /* 取消 */ UdeskLocales['current'].components.bEditor.index.cancel,
            confirm: /* 确定 */ UdeskLocales['current'].components.bEditor.index.confirm,
            inert: /* 插入 */ UdeskLocales['current'].components.bEditor.index.insert,
            width: /* 宽度 */ UdeskLocales['current'].components.bEditor.index.width,
            height: /* 高度 */ UdeskLocales['current'].components.bEditor.index.height,
        },
        controls: {
            clear: /* 清除内容 */ UdeskLocales['current'].components.bEditor.index.clearContent,
            undo: /* 撤销 */ UdeskLocales['current'].components.bEditor.index.revoked,
            redo: /* 重做 */ UdeskLocales['current'].components.bEditor.index.redo,
            fontSize: /* 字号 */ UdeskLocales['current'].components.bEditor.index.fontSize,
            lineHeight: /* 行高 */ UdeskLocales['current'].components.bEditor.index.rowHeight,
            letterSpacing:
                /* 字间距 */ UdeskLocales['current'].components.bEditor.index.wordSpacing,
            textIndent:
                /* 段落缩进 */ UdeskLocales['current'].components.bEditor.index.paragraphIndent,
            increaseIndent:
                /* 增加缩进 */ UdeskLocales['current'].components.bEditor.index.increaseIndent,
            decreaseIndent:
                /* 减少缩进 */ UdeskLocales['current'].components.bEditor.index.decreaseIndent,
            border: /* 边框 */ UdeskLocales['current'].components.bEditor.index.border,
            color: /* 颜色 */ UdeskLocales['current'].components.bEditor.index.color,
            textColor: /* 文字颜色 */ UdeskLocales['current'].components.bEditor.index.textColor,
            backgroundColor:
                /* 背景颜色 */ UdeskLocales['current'].components.bEditor.index.backgroundColor,
            tempColors:
                /* 临时颜色 */ UdeskLocales['current'].components.bEditor.index.temporaryColor,
            bold: /* 加粗 */ UdeskLocales['current'].components.bEditor.index.bold,
            italic: /* 斜体 */ UdeskLocales['current'].components.bEditor.index.italic,
            underline: /* 下划线 */ UdeskLocales['current'].components.bEditor.index.underline,
            strikeThrough:
                /* 删除线 */ UdeskLocales['current'].components.bEditor.index.strikethrough,
            fontFamily: /* 字体 */ UdeskLocales['current'].components.bEditor.index.font,
            textAlign:
                /* 文本对齐 */ UdeskLocales['current'].components.bEditor.index.textAlignment,
            alignLeft: /* 居左 */ UdeskLocales['current'].components.bEditor.index.left,
            alignCenter: /* 居中 */ UdeskLocales['current'].components.bEditor.index.centered,
            alignRight: /* 居右 */ UdeskLocales['current'].components.bEditor.index.right,
            alignJustify: /* 两端 */ UdeskLocales['current'].components.bEditor.index.bothEnds,
            floatLeft: /* 左浮动 */ UdeskLocales['current'].components.bEditor.index.leftFloat,
            floatRight: /* 右浮动 */ UdeskLocales['current'].components.bEditor.index.rightFloat,
            superScript: /* 上标 */ UdeskLocales['current'].components.bEditor.index.subrupt,
            subScript: /* 下标 */ UdeskLocales['current'].components.bEditor.index.subscript,
            removeStyles:
                /* 清除样式 */ UdeskLocales['current'].components.bEditor.index.clearStyle,
            headings: /* 标题 */ UdeskLocales['current'].components.bEditor.index.title,
            header: /* 标题 */ UdeskLocales['current'].components.bEditor.index.title,
            normal: /* 常规 */ UdeskLocales['current'].components.bEditor.index.regular,
            orderedList:
                /* 有序列表 */ UdeskLocales['current'].components.bEditor.index.orderedList,
            unorderedList:
                /* 无序列表 */ UdeskLocales['current'].components.bEditor.index.unorderedList,
            blockQuote: /* 引用 */ UdeskLocales['current'].components.bEditor.index.reference,
            code: /* 代码 */ UdeskLocales['current'].components.bEditor.index.code,
            link: /* 链接 */ UdeskLocales['current'].components.bEditor.index.link,
            unlink: /* 清除链接 */ UdeskLocales['current'].components.bEditor.index.clearLink,
            hr: /* 水平线 */ UdeskLocales['current'].components.bEditor.index.horizontalLine,
            media: /* 媒体 */ UdeskLocales['current'].components.bEditor.index.media,
            mediaLibirary:
                /* 媒体库 */ UdeskLocales['current'].components.bEditor.index.mediaLibrary,
            emoji: /* 小表情 */ UdeskLocales['current'].components.bEditor.index.smallExpression,
            fullscreen: /* 全屏 */ UdeskLocales['current'].components.bEditor.index.fullScreen,
            exitFullscreen:
                /* 退出全屏 */ UdeskLocales['current'].components.bEditor.index.exitFullScreen,
        },
        linkEditor: {
            textInputPlaceHolder:
                /* 输入链接文字 */ UdeskLocales['current'].components.bEditor.index.enterLinkText,
            linkInputPlaceHolder:
                /* 输入链接地址 */ UdeskLocales['current'].components.bEditor.index
                    .enterLinkAddress,
            inputWithEnterPlaceHolder:
                /* 输入链接地址并回车 */ UdeskLocales['current'].components.bEditor.index
                    .enterTheLinkAddressAndPressEnter,
            openInNewWindow:
                /* 在新窗口打开 */ UdeskLocales['current'].components.bEditor.index.openInNewWindow,
            removeLink: /* 移除链接 */ UdeskLocales['current'].components.bEditor.index.removeLink,
        },
        audioPlayer: {
            title: /* 播放音频文件 */ UdeskLocales['current'].components.bEditor.index
                .playingAudioFiles,
        },
        videoPlayer: {
            title: /* 播放视频文件 */ UdeskLocales['current'].components.bEditor.index
                .playVideoFiles,
            embedTitle:
                /* 嵌入式媒体 */ UdeskLocales['current'].components.bEditor.index.embeddedMedia,
        },
        media: {
            image: /* 图像 */ UdeskLocales['current'].components.bEditor.index.image,
            video: /* 视频 */ UdeskLocales['current'].components.bEditor.index.video,
            audio: /* 音频 */ UdeskLocales['current'].components.bEditor.index.audio,
            embed: /* 嵌入式媒体 */ UdeskLocales['current'].components.bEditor.index.embeddedMedia,
        },
    };

    return custom;
};

const Template: FC<TemplateProps> = memo((props) => {
    return <BraftEditor language={language} {...props} />;
});

export default Template;
