import Udesk from 'Udesk';
import React from 'react';
import PropTypes from 'prop-types';
import config from '../../../../common/config/index';

const MONTHDAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

export default class TaskConditionGatherComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        monthDays: MONTHDAYS,
        conditions: [],
        taskId: null,
        fields: [],
        onChanged: null,
        disabled: false,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        monthDays: PropTypes.array,
        conditions: PropTypes.array,
        taskId: PropTypes.any,
        fields: PropTypes.array,
        onChanged: PropTypes.func,
        disabled: PropTypes.bool,
    };
    //#endregion

    //#region state
    state = {
        baseFilterVisible: false,
        currentCondition: {
            baseFilter: {
                conditionList: []
            },
            customJudgeLogic: '',
            judgeStrategy: 1,
        },
        conditionList: [],
        judgeStrategy: Udesk.enums.judgeStrategy.all.id,
        customJudgeLogic: '',
    };
    //#endregion

    privates = {
        conditions: [],
        conditionsIndex: 0,
        conditionJsonInfo: [],
        conditionJson: []
    };

    static computes = {
        _fields: ["props.fields", function ({ props, privates, locales }) {
            if (props.fields) {
                return Udesk.business.fields.buildConditionFields({
                    fields: props.fields
                });
            } else {
                return [];
            }
        }],
        _fieldValueSettings: ["props.fields", function ({ props, privates, locales }) {
            return config.getFilterComponentSettings();
        }]
    };

    //#region actions
    actions = {
        onSampleCycleChanged(obj, value) {
            obj.sampleCycle = value;
            switch (value) {
                case Udesk.enums.samplingFrequencyTypes.everyDay.id:
                    obj.sampleScope = Udesk.enums.samplingEveryDayTimeTypes.all.id;
                    break;
                case Udesk.enums.samplingFrequencyTypes.everyWeek.id:
                    obj.sampleScope = Udesk.enums.samplingEveryWeekTimeTypes.all.id;
                    break;
                case Udesk.enums.samplingFrequencyTypes.everyMonth.id:
                    obj.sampleScope = Udesk.enums.samplingEveryMonthTimeTypes.all.id;
                    break;
                default:
                    break;
            }
            this.actions.changeConditions();
        },

        onValueChanged(obj, key, e) {
            let value = e;
            if (e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.changeConditions();
        },

        onStateChanged(key, e) {
            let value = e;
            if (e.target) {
                value = e.target.value;
            }
            this.setState({
                [key]: value
            });
        },

        changeTime(condition, time, timeStr) {
            let value = '';
            if (time) {
                value = time.format('HH:mm:ss').toString();
            }
            condition.sampleConf.executeTime = value;
            this.actions.changeConditions();
        },

        addCondition() {
            let conditions = this.privates.conditions;
            let taskId = this.props.taskId;
            let newCondition = {
                _id: this.privates.conditionsIndex,
                taskId: taskId,
                taskType: 1, //自动抽检1，抽检分配2，复核分配3，自动推送4，申诉复检5.
                sampleConf: initSampleConf(),
                baseFilter: initBaseFilter()
            };
            conditions.push(newCondition);
            this.privates.conditionsIndex += 1;
            this.actions.changeConditions();
        },

        deleteCondition(index) {
            let conditions = this.privates.conditions;
            conditions.splice(index, 1);
            this.actions.changeConditions();
        },

        changeConditions() {
            this.actions.update();
            let conditions = this.privates.conditions;
            this.trigger('onChanged', conditions);
        },

        //显示或隐藏弹出框，将该筛选条件重新复制给一个新的数组
        changeBaseFilterVisible(condition) {
            let baseFilterVisible = this.state.baseFilterVisible;
            if (!baseFilterVisible) {
                this.setState({
                    currentCondition: condition
                }, () => {
                    if (condition && condition.baseFilter && condition.baseFilter.conditionList) {
                        this.setState({
                            conditionList: [].concat(condition.baseFilter.conditionList)
                        });
                    }
                    if (condition && condition.baseFilter && condition.baseFilter.judgeStrategy != null) {
                        this.setState({
                            judgeStrategy: condition.baseFilter.judgeStrategy
                        });
                    }
                    if (condition && condition.baseFilter && condition.baseFilter.customJudgeLogic != null) {
                        this.setState({
                            customJudgeLogic: condition.baseFilter.customJudgeLogic
                        });
                    }    
                });
            }
            this.setState({
                baseFilterVisible: !baseFilterVisible
            });
        },

        //监听新数组的变化
        conditionListChanged(val) {
            this.setState({
                conditionList: [].concat(val)
            });
        },

        //取消变更，不复制给筛选条件
        cancelConditionListChanged() {
            this.actions.changeBaseFilterVisible();
        },

        //确认变更，新数组赋值给筛选条件
        confirmConditionListChanged() {
            let condition = this.state.currentCondition;
            let conditionList = this.state.conditionList;
            let judgeStrategy = this.state.judgeStrategy;
            let customJudgeLogic = this.state.customJudgeLogic;

            if (condition) {
                condition.baseFilter.conditionList = conditionList;
                condition.baseFilter.judgeStrategy = judgeStrategy;
                condition.baseFilter.customJudgeLogic = customJudgeLogic;
            }

            this.actions.changeBaseFilterVisible();
            this.actions.changeConditions();
        },

        changeSampleAvg(condition, e) {
            const value = e.target.checked;
            condition.sampleConf.sampleAvg = value ? 1 : 0;
            this.actions.changeConditions();
        },

        changeSampleRecheck(condition, e) {
            const value = e.target.checked;
            condition.sampleConf.sampleReCheck = value ? 1 : 0;
            this.actions.changeConditions();
        },

        //高级筛选器
        handleConditionCancel() {
            this.setState({
                baseFilterVisible: false
            });
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy) {
            // let { editKey } = this.privates;
            let condition = this.state.currentCondition;
            condition.baseFilter.conditionList = conditionList;
            condition.baseFilter.judgeStrategy = judgeStrategy;
            if (judgeStrategy !== 3) {
                condition.baseFilter.customJudgeLogic = '';
            } else {
                condition.baseFilter.customJudgeLogic = customJudgeLogic;
            }
            this.privates.customId = null;
            this.privates.customName = null;
            // this.setState({currentCondition: condition});
            this.actions.changeBaseFilterVisible(condition);
            this.actions.changeConditions();

        },
        editFilterOhter(condition) {
            this.setState({
                baseFilterVisible: true,
                currentCondition: condition
            });
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.forEach(item => {
                if (item.value) {
                    if (
                        item.field &&
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = "";
                        item.value.forEach(items => {
                            if (items.agentName) {
                                str += items.agentName + ",";
                            }
                        });
                        list = [
                            ...list,
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            str.substring(0, str.length - 1)
                        ];
                    } else if (typeof item.value === "object") {
                        let str =
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === "string") {
                        let str =
                            item.field.label + " " + obj[item.operator] + " " + item.value;
                        list = [...list, str];
                    } else {
                        let str =
                            item.field.label + " " + obj[item.operator] + " " + item.value;
                        list = [...list, str];
                    }
                } else if(item.field) {
                    let str = item.field.label + " " + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <div
                        style={{
                            color: 'rgba(0, 0, 0, 0.65)',
                            marginBottom:
                                index + 1 === list.length
                                    ? '0px'
                                    : '8px',
                        }}
                    >
                        {index + 1}. {item}
                    </div>
                );
            });
        },
    };
    //#endregion

    //#region Life Cycle
    init() {
        const { privates, props } = this;
        if (props.conditions && props.conditions.length > 0) {
            privates.conditions = props.conditions.map((item, index) => {
                if (item.baseFilter == null) {
                    item.baseFilter = initBaseFilter();
                }
                if (item.sampleConf == null) {
                    item.sampleConf = initSampleConf();
                }
                return {
                    _id: index,
                    ...item
                };
            });
            privates.conditionsIndex = props.conditions.length;
        }
    }
    parseProps({ props, prevProps }) {
        let conditions = [];
        let conditionJsonInfo = [];
        let conditionJson = [];
        if (props.conditions && props.conditions.length > 0) {
            conditions = props.conditions.map((item, index) => {
                if (item.baseFilter == null) {
                    item.baseFilter = initBaseFilter();
                }
                if (item.sampleConf == null) {
                    item.sampleConf = initSampleConf();
                }
                return {
                    _id: index,
                    ...item
                };
            });
            conditions.forEach(condition => {
                let res = condition.baseFilter && condition.baseFilter.conditionList && condition.baseFilter.conditionList.map(item => {
                    if (item.field && item.field.statusKey) {
                        return {
                            ...item,
                            field: {
                                ...item.field,
                                id: `${item.field.id}-${item.field.inspectionTaskId}`,
                            }
                        };
                    }
                    return item;    
                });
                conditionJsonInfo.push(res);
            });
            conditions.forEach(condition => {
                let res = condition.baseFilter && condition.baseFilter.conditionList && condition.baseFilter.conditionList.map(item => {
                    if (item.field && item.field.statusKey) {
                        return {
                            field: `${item.field.id}-${item.field.inspectionTaskId}`,
                            operator: item.operator,
                            value: item.value,
                            statusKey: `${item.field.id}-${item.field.inspectionTaskId}`
                        };
                    }
                    return {
                        field: item.field ? item.field.id : null,
                        operator: item.operator,
                        value: item.value
                    };
                });
                conditionJson.push(res);
            });
        }
        return {
            conditionsIndex: props.conditions.length,
            conditions,
            conditionJsonInfo,
            conditionJson,
        };
    }
    componentDidMount() {
    }
    //#endregion
}

function initSampleConf() {
    return {
        sampleCycle: Udesk.enums.samplingFrequencyTypes.everyDay.id,
        dayOfWeek: Udesk.enums.samplingFrequencyWeekDays.Monday.id,
        dayOfMonth: 1,
        executeTime: '02:30:00',
        sampleScope: Udesk.enums.samplingEveryDayTimeTypes.all.id,
        sampleForm: Udesk.enums.samplingTypes.random.id
    };
}

function initBaseFilter() {
    return {
        conditionList: [],
        judgeStrategy: Udesk.enums.judgeStrategy.all.id,
        customJudgeLogic: ''
    };
}
function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter(item => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map(item => {
            return item.name;
        });
        return value.join(",");
    } else {
        return "";
    }
}

