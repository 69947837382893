import React from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import NavBar from 'udesk-react/src/components/nav-bar';
import { Redirect } from 'react-router-dom';
import { PageHeader } from 'udesk-ui';
import './style.scss';

const RoleDetail = (props) => {
    const locales = Locales['current'];
    const id = props.match.params.id;
    const sysModule = parseInt(props.match.params.sysModule, 10);
    let { routes, route, location, sdkOptions } = props;
    // let readOnly = route.name === 'roleView';
    let routeName = route.name;

    let navLists = [
        {
            pathName: 'generalSettingRoleView',
            text: locales.components.pages.role.detail.navTabNames.basicInfo,
            pathParams: { id, sysModule },
        },
        {
            pathName: 'generalSettingRoleViewDataPermissions',
            text: locales.components.pages.role.detail.navTabNames.dataPermissions,
            pathParams: { id, sysModule },
        },
        {
            pathName: 'generalSettingRoleViewTaskPermissions',
            text: locales.components.pages.role.detail.navTabNames.taskPermissions,
            pathParams: { id, sysModule },
        },
    ].filter((nav, index) => {
        if (sysModule === Udesk.enums.sysModuleList.qa.id) {
            return true;
        }
        if (sysModule === Udesk.enums.sysModuleList.coach.id) {
            return index < 2;
        }

        return false;
    });

    const isRedirect = (currentRoute, toPath) => {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentRoute.name,
            pathParams: props.match.params,
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    };
    const backToIndex = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'generalSettingRoleList',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    if (isRedirect(route, location.pathname)) {
        let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
            routeName: route.name,
            pathParams: props.match.params,
        });
        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
    } else {
        return (
            // <div className="role-detail-page">
            <React.Fragment>
                {routeName === 'generalSettingRoleDetail' && (
                    <PageHeader
                        ghost={false}
                        style={{
                            padding: '9px 24px',
                            boxShadow: '0px 1px 0px 0px rgb(0 0 0 / 10%)',
                        }}
                        className='udesk-qa-web-permission-management-role-detail-page-header'
                        title={locales.components.pages.role.detail.view}
                        onBack={backToIndex}
                        extra={
                            <NavBar
                                navLists={navLists}
                                classNames="role-detail-page-header-title-right-part qa-react-page-header-title-right-part"
                                navItemClassNames="role-detail-page-header-nav-item"
                                activeClassNames="role-detail-page-header-nav-item-active"
                            />
                        }
                    />
                )}

                {routes.map((route, i) => (
                    <SubRoutes
                        key={i}
                        route={route}
                        childProps={{ hiddenPageHeader: routeName === 'generalSettingRoleDetail' }}
                    />
                ))}
            </React.Fragment>
        );
    }
};

class Component extends React.Component {
    render() {
        return <RoleDetail {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
