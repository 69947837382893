import React, { FC, memo, useMemo } from 'react';
import { Space, Switch } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const SwitchStatus = {
    OPEN: 1,
    CLOSE: 0,
};

type TemplateProps = {
    value?: number;
    onChange?: (v?: number) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange } = props;

    const checked = useMemo(() => {
        return value === SwitchStatus.OPEN;
    }, [value]);

    const onCheckedChange = (c: boolean) => {
        onChange?.(c ? SwitchStatus.OPEN : SwitchStatus.CLOSE);
    };

    return (
        <Space>
            <Switch checked={checked} onChange={(checked) => onCheckedChange(checked)} />
            <div>{/* 自动生成 */}{UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.components.autoGenerate.index.automaticGeneration}</div>
        </Space>
    );
};

export default memo(Template);
