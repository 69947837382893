import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Input, Tabs, Popover, Icon } from 'udesk-ui';

const associateOperatorIconList = [
    {
        subType: 0,
        icon: 'hanshu',
    },
    {
        subType: 1,
        icon: 'jia',
    },
    {
        subType: 2,
        icon: 'jian',
    },
    {
        subType: 3,
        icon: 'cheng',
    },
    {
        subType: 4,
        icon: 'chu',
    },
    {
        subType: 21,
        icon: 'dengyu',
    },
    {
        subType: 22,
        icon: 'dayu',
    },
    {
        subType: 23,
        icon: 'dayudengyu',
    },
    {
        subType: 24,
        icon: 'xiaoyu',
    },
    {
        subType: 25,
        icon: 'xiaoyudengyu',
    },
    {
        subType: 51,
        icon: 'yu',
    },
    {
        subType: 52,
        icon: 'huo',
    },
    {
        subType: 53,
        icon: 'fei',
    },
];

const AdvancedAssociateSearch = React.memo(props => {
    const {locales, value, onChange, associateInputRef} = props;

    const changeHandle = useCallback((event) => {
        onChange(event.target.value);
    }, [onChange]);

    return (
        <Input.Search
            ref={associateInputRef}
            size='small'
            placeholder={locales.labels.searchPlaceHolder}
            value={value}
            style={{ width: 146 }}
            onSearch={onChange}
            onChange={changeHandle}
        />
    );
});

export const AdvancedAssociate = React.memo(props => {
    const {
        locales,
        operatorItem,
        associateList,
        associateOperatorList,
        changeInputValue,
        handleOperatorItemChange,
    } = props;
    const [isShowAssociate, setIsShowAssociate] = useState(false);
    const [associateActiveTab, setAssociateActiveTab] = useState('data');
    const [associateListSearchValue, setAssociateListSearchValue] = useState({
        data: '',
        operator: ''
    });
    const associateDivRef = useRef(null);
    const associateTextareaRef = useRef(null);
    const showAssociateTimer = useRef(null);
    const associateInputRef = useRef(null);

    const searchValue= useMemo(() => associateListSearchValue[associateActiveTab], [associateActiveTab, associateListSearchValue]);
    const setSearchValue = useCallback((value) => {
        setAssociateListSearchValue(cache => {
            return {
                ...cache,
                [associateActiveTab]: value,
            };
        });
    }, [associateActiveTab]);

    const dataSource = useMemo(() => {
        const list = associateActiveTab === 'data' ? associateList: associateOperatorList;
        if (searchValue) {
            const value = searchValue.replace(/^\[/, '').replace(/\]$/, '');
            if ('data' === associateActiveTab) {
                return list.map(associate => {
                    if (associate.fieldList) {
                        return {
                            type: associate.type,
                            fieldList: associate.fieldList.filter(item => item.label.indexOf(value) !== -1),
                        };
                    }
                    return {
                        type: associate.type,
                        fieldList: [],
                    };
                });
            } else {
                return list.map(operatorList => {
                    if (operatorList.list) {
                        return {
                            type: operatorList.type,
                            list: operatorList.list.filter(item => item.view.indexOf(value) !== -1 || item.note.indexOf(value) !== -1),
                        };
                    }
                    return {
                        type: operatorList.type,
                        list: [],
                    };
                });
            }
        }
        return list;
    }, [associateActiveTab, associateList, associateOperatorList, searchValue]);

    const showAssociate = useCallback((type, visible) => {
        if (type === 'focus') {
            clearTimeout(showAssociateTimer.current);
            showAssociateTimer.current = setTimeout(() => {
                setIsShowAssociate(status => !status);
                if (visible) {
                    associateInputRef.current?.focus();
                }
            }, 100);
            if (isShowAssociate) {
                clearTimeout(showAssociateTimer.current);
            }
        }
        if (type === 'click') {
            showAssociateTimer.current = setTimeout(() => {
                setIsShowAssociate(status => !status);
                if (!visible) {
                    associateTextareaRef.current?.focus();
                }
            }, 100);
        }
    }, [isShowAssociate]);

    const associateDataClick = useCallback((operatorItem, type, item, event) => {
        //阻止冒泡
        event.stopPropagation();
        const textAreaDom = associateDivRef.current.firstChild;
        //为运算操作符前后添加空格，以区分是否为新增字符
        const arrNew = operatorItem.operatorList[0].param.inputContent.split('');

        if (!operatorItem.operatorList[0].param.inputContent) {
            operatorItem.operatorList[0].param.inputContent = '';
        }

        if (type === 1 && item.length === 1) {
            item = ' ' + item + ' ';
        }
        //删除示例中的形参
        if (type === 2) {
            const itemCopy = item.split('');
            itemCopy.splice(item.indexOf('(') + 1, item.lastIndexOf(")") - item.indexOf('(') - 1);
            item = itemCopy.join('');
        }

        arrNew.splice(textAreaDom.selectionStart, 0, item);
        operatorItem.operatorList[0].param.inputContent = arrNew.join('');
        textAreaDom.focus();

        setTimeout(() => {
            let res = operatorItem.operatorList[0].param.inputContent.length;
            //根据元素长度判断是否新增，新增元素长度恒大于1
            arrNew.forEach((ele, index) => {
                //新添加的元素是函数操作符(包含()且不是数据)
                if (ele.indexOf('(') >= 1 && ele.indexOf('[') === -1 && ele.indexOf(']') === -1) {
                    res = index + ele.indexOf('(') + 1;
                }
                //新添加的元素在函数的()中
                if (ele.indexOf('(') < 1 && arrNew[index + 1] && arrNew[index + 1].length !== 1) {
                    res = index + arrNew[index + 1].length + 1;
                }
                //新添加的元素是数据或运算操作符
                if (ele.indexOf('(') < 1 && arrNew[index + 1] && arrNew[index].length !== 1) {
                    res = index + arrNew[index].length;
                }
            });
            //定位光标位置，selectionStart与selectionEnd不同可实现自动选中某区间效果
            associateDivRef.current.firstChild.selectionStart = res;
            associateDivRef.current.firstChild.selectionEnd = res;
        }, 100);

        handleOperatorItemChange();
    }, [handleOperatorItemChange]);

    return (
        <div
            style={{ position: 'relative', width: 'calc(100% - 400px)' }}
            ref={associateDivRef}
        >
            <Input.TextArea
                ref={associateTextareaRef}
                disabled={props.disabled}
                style={{ width: '100%', margin: '16px 8px', resize: 'none' }}
                placeholder={locales.components.operatorListGather.pleaseEnterAssociate}
                value={operatorItem.operatorList[0].param.inputContent}
                onClick={() => showAssociate('focus', true)}
                autoSize
                onChange={changeInputValue}
            />
            <Popover
                autoAdjustOverflow={false}
                trigger='click'
                visible={isShowAssociate}
                onVisibleChange={(visible) => showAssociate('click', visible)}
                placement='right'
                getPopupContainer={() => associateDivRef.current}
                content={
                    <div style={{ width: 280, height: 400 }}>
                        <div>
                            <Tabs
                                size='small'
                                tabBarGutter={8}
                                activeKey={associateActiveTab}
                                tabBarExtraContent={
                                    <AdvancedAssociateSearch 
                                        {...{
                                            locales, 
                                            associateDivRef, 
                                            associateInputRef,
                                            value: searchValue,
                                            onChange: setSearchValue,
                                        }}
                                    />
                                }
                                onChange={setAssociateActiveTab}
                            >
                                <Tabs.TabPane
                                    tab={locales.components.operatorListGather.associateMedol.tabs.data}
                                    key='data'
                                ></Tabs.TabPane>
                                <Tabs.TabPane
                                    tab={
                                        locales.components.operatorListGather.associateMedol.tabs
                                            .operator
                                    }
                                    key='operator'
                                ></Tabs.TabPane>
                            </Tabs>
                        </div>
                        <If condition={associateActiveTab === 'data'}>
                            <div className='operator-list-item-associate-model'>
                                {dataSource.map((associateData) => {
                                    return (
                                        <If
                                            condition={
                                                associateData.fieldList &&
                                                    associateData.fieldList.length > 0
                                            }
                                        >
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                    borderBottom: '1px solid rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '12px',
                                                        color: 'rgba(0,0,0,0.45)',
                                                    }}
                                                >
                                                    {associateData.type === 1
                                                        ? locales.components.operatorListGather
                                                            .associateData
                                                        : associateData.type === 3
                                                            ? locales.components.operatorListGather
                                                                .pretreatmentData
                                                            : associateData.type === 6
                                                                ? locales.components.operatorListGather
                                                                    .businessData
                                                                : ''}
                                                </div>
                                                {associateData.fieldList &&
                                                    associateData.fieldList.map((item) => {
                                                        return (
                                                            <div
                                                                className='operator-list-item-associate-model-item'
                                                                onClick={(event) => associateDataClick(
                                                                    operatorItem, 'data', `[${item.label}]`, event
                                                                )}
                                                            >{`[${item.label}]`}</div>
                                                        );
                                                    })}
                                            </div>
                                        </If>
                                    );
                                })}
                            </div>
                        </If>
                        <If condition={associateActiveTab === 'operator'}>
                            <div className='operator-list-item-associate-model'>
                                {dataSource.map((operatorList) => {
                                    return (
                                        <If
                                            condition={
                                                operatorList.list && operatorList.list.length > 0
                                            }
                                        >
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                    borderBottom: '1px solid rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '12px',
                                                        color: 'rgba(0,0,0,0.45)',
                                                    }}
                                                >
                                                    {operatorList.type === 2
                                                        ? locales.components.operatorListGather
                                                            .functionOperator
                                                        : locales.components.operatorListGather
                                                            .operation}
                                                </div>
                                                <div>
                                                    {operatorList.list &&
                                                        operatorList.list.map((operator) => {
                                                            let iconType = associateOperatorIconList[0];
                                                            if (operator.type === 1) {
                                                                let res = associateOperatorIconList.find(
                                                                    (icon) =>
                                                                        icon.subType ===
                                                                            operator.subType
                                                                );
                                                                if (res) {
                                                                    iconType = res;
                                                                }
                                                            }
                                                            return (
                                                                <div
                                                                    className='operator-list-item-associate-model-operator'
                                                                    onClick={(event) => associateDataClick(
                                                                        operatorItem,
                                                                        operatorList.type,
                                                                        operator.view,
                                                                        event
                                                                    )}
                                                                >
                                                                    <div className='operator-list-item-associate-model-operator-icon'>
                                                                        <Icon
                                                                            type={iconType.icon}
                                                                            style={{
                                                                                fontSize: '22px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='operator-list-item-associate-model-operator-content'>
                                                                        <div className='operator-list-item-associate-model-operator-title'>
                                                                            {operator.view}
                                                                        </div>
                                                                        <div className='operator-list-item-associate-model-operator-des'>
                                                                            {operator.note}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </If>
                                    );
                                })}
                            </div>
                        </If>
                    </div>
                }
            >
                <i style={{ width: 1, height: 0, position: 'absolute', right: 0, top: '50%' }}></i>
            </Popover>
        </div>
    );
});