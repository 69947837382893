import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, FormBuilder, Row, Col, Space } from 'udesk-ui';
// import './style.scss';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import SemanticTagsSelect from 'Component/pages/components/semantic-tags-select';
import { getKnowledgeDatasById, putKnowledgeDatasById } from 'src/api/knowledgeDatas/{id}';
import { postKnowledgeDatas } from 'src/api/knowledgeDatas';
import { KnowledgeDataCreateRequest, KnowledgeDataFoundResponse } from 'src/api/types';
import { getKnowledgeCategorysInitTree } from 'src/api/knowledgeCategorys/initTree';
import { getNode } from '../index';
import { isAlgorithm } from 'Udesk/system/subApp';

interface SemanticTagGroup {
    groupId?: number;
    key?: number;
    semanticName?: string;
    sentence?: string;
}
interface InitialValues
    extends Omit<
        KnowledgeDataFoundResponse,
        'categoryId' | 'answerSemanticTagGroup' | 'questionSemanticTagGroup'
    > {
    categoryId?: string;
    answerSemanticTagGroup?: SemanticTagGroup;
    questionSemanticTagGroup?: SemanticTagGroup;
}

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const id = props.match.params.id;
    const categoryId = props.location.state?.categoryId ?? undefined;
    const keyword = props.location.state?.keyword ?? '';
    // const { sdkOptions } = props;
    const [treeData, setTreeData] = useState<any[]>([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<InitialValues>({
        type: Udesk.enums.knowledgeType.oneToOne.id,
        status: 1,
        categoryId: categoryId,
        afterScope: 5,
    });
    const [baseForm] = FormBuilder.useForm();
    useEffect(() => {
        id && setBtnLoading(true);
        id &&
            getKnowledgeDatasById({
                segments: {
                    id,
                },
            })
                .then((resp) => {
                    resp.data &&
                        setInitialValues({
                            ...resp.data,
                            categoryId: `${resp.data.categoryId}`,
                            answerSemanticTagGroup: {
                                semanticName: resp.data.answerSemanticTagGroup?.title,
                                key: resp.data.answerSemanticTagGroup?.id,
                                sentence: resp.data.answerSemanticTagGroup?.standardContent,
                            },
                            questionSemanticTagGroup: {
                                semanticName: resp.data.questionSemanticTagGroup?.title,
                                key: resp.data.questionSemanticTagGroup?.id,
                                sentence: resp.data.questionSemanticTagGroup?.standardContent,
                            },
                        });
                })
                .finally(() => {
                    setBtnLoading(false);
                });
        getKnowledgeCategorysInitTree().then((resp) => {
            setTreeData(getNode(resp.data));
        });
    }, []);
    useEffect(() => {
        baseForm.resetFields();
    }, [initialValues]);
    const cancel = () => {
        let routeOptions: any = {
            history: props.history,
            routeName: isAlgorithm() ? 'algorithmKnowledgeBaseIndex' : 'knowledgeBaseIndex',
            state: {},
        };
        if (categoryId) {
            routeOptions.state.categoryId = categoryId;
        }
        if (keyword) {
            routeOptions.state.keyword = keyword;
        }
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const onFinish = async (values) => {
        const { answerSemanticTagGroup, questionSemanticTagGroup, ...others } = values;
        const params: KnowledgeDataCreateRequest = {
            ...others,
            standardAnswerId: answerSemanticTagGroup.groupId,
            standardQuestionId: questionSemanticTagGroup.groupId,
            standardQuestionText: questionSemanticTagGroup.sentence,
        };
        if (id) {
            await putKnowledgeDatasById(params, {
                segments: {
                    id,
                },
                successMsg: locales.business.info.saveSuccess,
            });
        } else {
            await postKnowledgeDatas(params, {
                successMsg: locales.business.info.creatSuccess,
            });
        }
        cancel();
    };
    const config: FormProps = {
        customComponents: { SemanticTagsSelect },
        initialValues,
        onFinish: onFinish,
        fields: [
            {
                name: 'type',
                label: locales.components.pages.knowledgeBase.detail.type,
                type: 'RadioGroup',
                props: {
                    options: Udesk.enums.knowledgeType.map((i) => ({ label: i.name, value: i.id })),
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'status',
                label: locales.components.pages.knowledgeBase.detail.status,
                type: 'Select',
                props: {
                    options: [
                        {
                            label: locales.labels.switchOn,
                            value: 1,
                        },
                        {
                            label: locales.labels.switchOff,
                            value: 0,
                        },
                    ],
                    style: {
                        width: 200,
                    },
                    placeholder: locales.labels.pleaseSelect,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'categoryId',
                label: locales.components.pages.knowledgeBase.detail.classification,
                type: 'TreeSelect',
                props: {
                    treeData,
                    showSearch: true,
                    treeNodeFilterProp: 'title',
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'questionSemanticTagGroup',
                label: locales.components.pages.knowledgeBase.detail.standardQuestion,
                type: 'SemanticTagsSelect',
                // props: {
                //     treeData,
                //     showSearch: true,
                //     treeNodeFilterProp: 'title'
                // },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'afterScope',
                label: locales.components.pages.knowledgeBase.detail.answerDetectionRange,
                type: 'InputNumber',
                addonAfter:
                    locales.pages.semanticIntelligence.knowledgeBase.detail.index.intrasentence,
                addonBefore:
                    locales.pages.semanticIntelligence.knowledgeBase.detail.index.afterProblem,
                props: {
                    min: 1,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'answerSemanticTagGroup',
                label: locales.components.pages.knowledgeBase.detail.standardAnswer,
                type: 'SemanticTagsSelect',
                // props: {
                //     treeData,
                //     showSearch: true,
                //     treeNodeFilterProp: 'title'
                // },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        ],
        footerRender: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 13 },
    };
    return (
        <Page
            pageBodyClassName="wechat-staff-center-page-index"
            title={`${locales.labels[props.match.params.id ? 'edit' : 'create']}${
                locales.components.pages.knowledgeBase.title
            }`}
            padding={true}
            onBack={cancel}
        >
            <FormBuilder {...config} form={baseForm} />
            <Row>
                <Col offset={4}>
                    <Space>
                        <Button loading={btnLoading && { delay: 200 }} onClick={cancel}>
                            {locales.labels.cancel}
                        </Button>
                        <Button
                            loading={btnLoading && { delay: 200 }}
                            type="primary"
                            onClick={() => baseForm.submit()}
                        >
                            {id ? locales.labels.save : locales.labels.create}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
