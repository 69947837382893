// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetSummaryCallByCallIdQuery,
    GetSummaryCallByCallIdParam,
    BaseResponseCallServiceSummaryFoundResponse,
    PostSummaryCallByCallIdParam,
    PostSummaryCallByCallIdBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 通对话查询业务小结
 *
 * @export
 * @tags call-service-summary-controller
 * @link [GET] /summary/call/{callId}
 * @param callId
 */
export function getSummaryCallByCallId(
    options: ApiOptions<GetSummaryCallByCallIdParam, GetSummaryCallByCallIdQuery> & {
        segments: GetSummaryCallByCallIdParam;
    } & { params: GetSummaryCallByCallIdQuery }
): Promise<BaseResponseCallServiceSummaryFoundResponse> {
    return request<never, GetSummaryCallByCallIdParam, GetSummaryCallByCallIdQuery>(
        '/summary/call/{callId}',
        { ...options, method: 'get', __$requestCalleeName: 'getSummaryCallByCallId' }
    );
}

/**
 * 通对话生成业务小结
 *
 * @export
 * @tags call-service-summary-controller
 * @link [POST] /summary/call/{callId}
 * @param callId
 */
export function postSummaryCallByCallId(
    data: PostSummaryCallByCallIdBody,
    options: ApiOptions<PostSummaryCallByCallIdParam, never> & {
        segments: PostSummaryCallByCallIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PostSummaryCallByCallIdBody, PostSummaryCallByCallIdParam, never>(
        '/summary/call/{callId}',
        { ...options, data, method: 'post', __$requestCalleeName: 'postSummaryCallByCallId' }
    );
}

export const meta = [
    { tags: ['call-service-summary-controller'], path: '/summary/call/{callId}', method: 'get' },
    { tags: ['call-service-summary-controller'], path: '/summary/call/{callId}', method: 'post' },
];
