import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import UdeskLocales from 'UdeskLocales';

class intelligentAnalysis extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let { route, location, sdkOptions } = this.props;

        let { locales } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:point:template:root')) {
            navLists.push({
                text: /* 规则模版 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                    .index.ruleTemplate,
                pathName: 'ruleTemplate',
                isActive: (match, location) => {
                    return location.pathname.includes('/rule-template');
                },
            });
        }

        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:information:root')) {
            navLists.push({
                text: locales.components.pages.intelligentCollection.title,
                pathName: 'intelligentCollectionEntry',
                isActive: (match, location) => {
                    return location.pathname.includes('/intelligent-collection');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:tag:root')) {
            let pathName = 'intelligentTagsIndex';
            navLists.push({
                text: locales.components.pages.intelligentTags.title,
                pathName,
                isActive: (match, location) => {
                    return location.pathname.includes('/intelligent-tags');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:discovery:root')) {
            navLists.push({
                text: locales.components.pages.sentimentAnalysis.title,
                pathName: 'sentimentAnalysis',
                isActive: (match, location) => {
                    return location.pathname.includes('/public-sentiment');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('datapre:rule:root')) {
            navLists.push({
                text: locales.components.pages.dataPreprocess.title,
                pathName: 'dataPreprocess',
                isActive: (match, location) => {
                    return location.pathname.includes('/data-preprocess');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:call:root')) {
            navLists.push({
                text: locales.components.pages.connectedByConversation.title,
                pathName: 'connectedByConversationIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/connected-by-conversation');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:analysis:root')) {
            navLists.push({
                text: locales.components.pages.businessAnalysis.title,
                pathName: 'businessAnalysisList',
                isActive: (match, location) => {
                    return location.pathname.includes('/business-analysis');
                },
            });
        }

        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('smart:analysis:report:root')) {
            navLists.push({
                text: /* 分析报表 */ locales.pages.intelligentAnalysis.analysisReportTitle,
                pathName: 'analysisReport',
                isActive: (match, location) => {
                    return location.pathname.includes('/analysis-report');
                },
            });
        }

        if (
            this.isRedirect(route.path, location.pathname) &&
            sdkOptions.props.landingPath != null
        ) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="semantic-intelligence">
                    {/* <div className="semantic-intelligence-nav col-md-2 col-sm-3 col-xs-12">
                        <NavBar navLists={navLists} classNames="semantic-intelligence-nav-container" navItemClassNames="semantic-intelligence-nav-item" activeClassNames="semantic-intelligence-nav-item-active" />
                    </div>
                    <div className="semantic-intelligence-content col-md-10 col-sm-9 col-xs-12">
                        <For each="route" index="i" of={routes}>
                            <If condition={!route.authCode || (route.authCode && Udesk.data.init.user.hasFeature(route.authCode))}>
                                <SubRoutes key={i} route={route} />
                            </If>
                        </For>
                    </div> */}
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={locales.components.pages.menuTitleList.intelligentAnalysisTitle}
                    />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(intelligentAnalysis);
