import Udesk from 'Udesk';
import React from 'react';
import TasksManualAddRoute from './route';
// import ReactPager from 'udesk-react/src/components/react-pager';
import './style.scss';
import { Button, Modal, Alert, PageHeader, Space, Pagination } from 'udesk-ui';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
// import _isEmpty from 'lodash-es/isEmpty';
// import _isEqual from 'lodash-es/isEqual';
// import _size from 'lodash-es/size';
// import _includes from 'lodash-es/includes';

const filterList = ['f_8', 'r_1', 'r_2', 'r_3'];
export default class TasksManualAddTemplate extends TasksManualAddRoute {
    render() {
        let { actions, locales, props, state, privates } = this;
        let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
        return (
            <React.Fragment>
                <div className='udesk-qa-web-page'>
                    {/* <div className="page tasks-manage-template-manual-add-index"> */}
                    <PageHeader
                        className='udesk-qa-web-page-header'
                        onBack={actions.gotoTask}
                        title={locales.pages.tasks.manage.template.manualAdd.index.name}
                        subTitle={locales.pages.tasks.manage.template.manualAdd.index.tip}
                    />
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root tasks-manage-template-manual-add-index-content'>
                            <If condition={hasFeature && hasFeature('task:template:manual:view')}>
                                {/* <If condition={true}> */}
                                <If condition={this.privates.model.processCreateStatus.processStatus === 6}>
                                    <Alert
                                        message={locales.pages.tasks.manage.template.basicInfo.index.conduct6}
                                        type='info'
                                        closeText={locales.pages.tasks.manage.template.basicInfo.index.quit}
                                        onClose={actions.exitBoot}
                                    />
                                </If>
                                <div style={{ padding: 16, flex: 1 }}>
                                    <div className='manual-add-content-container clearfix'>
                                        <div className='container-header'>
                                            {locales.pages.tasks.manage.template.manualAdd.index.screeningCondition}
                                        </div>
                                        <If condition={!privates.conditionJson.length}>
                                            <Button onClick={actions.changeFilterVisible}>{locales.fix.addFilterConditions}</Button>
                                        </If>
                                        <div className='container-content'>
                                            <div className='container-screening-condition'>
                                                {/* <CustomFilters
                                                    showConditionNumber={true}
                                                    allowEmptyConditions={false}
                                                    conditions={state.conditionList}
                                                    fields={privates.computes._fields}
                                                    fieldValueSettings={privates.computes._fieldValueSettings}
                                                    theme="bootstrap"
                                                    onChanged={actions.filterContidionChanged}
                                                    fieldSelectorClass="form-control input-sm"
                                                    operatorSelectorClass="form-control input-sm"
                                                    languageCode={props.sdkOptions.props.language}
                                                    fieldNameField="key"
                                                    fieldKeyField="key"
                                                    fieldTextField="label"
                                                    disabled={!hasFeature || !hasFeature('task:template:manual:import')}
                                                /> */}
                                                {privates.conditionJson.length ? (
                                                    <React.Fragment>
                                                        <div
                                                            className='manual-add-records-item-row-filter'
                                                            onClick={actions.editFilterOhter.params(
                                                                privates.conditionJson,
                                                                privates.judgeStrategy,
                                                                privates.customJudgeLogic,
                                                                privates.conditionJsonInfo
                                                            )}
                                                        >
                                                            <div className='manual-add-records-item-row-filter-title'>
                                                                <div>
                                                                    {
                                                                        locales.pages.tasks.manage.template.manualAdd.index
                                                                            .filterTitle
                                                                    }
                                                                </div>
                                                                <div className='index-management-custom-filter-edit'>
                                                                    <Button
                                                                        type='link'
                                                                        size='small'
                                                                        onClick={actions.editFilterOhter.params(
                                                                            privates.conditionJson,
                                                                            privates.judgeStrategy,
                                                                            privates.customJudgeLogic,
                                                                            privates.conditionJsonInfo
                                                                        )}
                                                                    >
                                                                        {locales.labels.edit}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className='index-management-custom-filter-Info'>
                                                                <div className='manual-add-records-item-row-filter-title'>
                                                                    {
                                                                        locales.pages.tasks.manage.template.manualAdd.index
                                                                            .filterRule
                                                                    }
                                                                    {locales.labels.colon}
                                                                </div>
                                                                <div className='manual-add-records-item-row-filter-rules'>
                                                                    {privates.conditionJsonInfo.length &&
                                                                        this.actions.fieldsFilterChange(
                                                                            privates.conditionJsonInfo
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className='index-management-custom-filter-Info' style={{marginBottom: 0}}>
                                                                <div className='manual-add-records-item-row-filter-title' style={{marginBottom: 0}}>
                                                                    {locales.components.common.customFilter.conditionRule}：
                                                                </div>
                                                                <div className='manual-add-records-item-row-filter-rules'>
                                                                    {privates.judgeStrategy !== 3 &&
                                                                        Udesk.enums.operatorRuleTypes.find(
                                                                            (current) =>
                                                                                current.id === (privates.judgeStrategy || Udesk.enums.operatorRuleTypes.all.id)
                                                                        ).name}
                                                                    {privates.judgeStrategy === 3 &&
                                                                        privates.customJudgeLogic}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <If condition={privates.previewData.total || privates.previewData.total === 0}>
                                                            <div style={{marginBottom: 16, color: 'rgba(0,0,0,.65)'}}>
                                                                {Udesk.utils.string.format(locales.pages.tasks.manage.template.manualAdd.index.previewData, privates.previewData.total, privates.previewData.hasInTaskCount, privates.previewData.cnJoinTaskCount, privates.previewData.notJoinTaskCount)}
                                                            </div>
                                                        </If>

                                                        <If condition={hasFeature && hasFeature('task:template:manual:import')}>
                                                            <Space>
                                                                <Button
                                                                    type='primary'
                                                                    disabled={!privates.previewData.cnJoinTaskCount}
                                                                    onClick={actions.importManualAddRecords}
                                                                >
                                                                    {locales.labels.import}
                                                                </Button>
                                                                <If condition={this.privates.type !== Udesk.enums.inspectDataSources.wechatRadio.id}>
                                                                    <Button
                                                                        onClick={actions.transToManage}
                                                                    >
                                                                        {locales.pages.tasks.manage.template.manualAdd.index.preview}
                                                                    </Button>
                                                                </If>
                                                            </Space>
                                                        </If>
                                                    </React.Fragment>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="manual-add-content-container clearfix">
                                        <div className="container-header">
                                            {locales.pages.tasks.manage.template.manualAdd.index.aiScreeningCondition}
                                        </div>
                                        <div className="container-content">
                                            <label className="container-label" htmlFor="checkPoint_rule">
                                                {locales.pages.tasks.manage.template.checkPoint.detail.rule}
                                            </label>
                                            <div className="container-input">
                                                <input
                                                    type="text"
                                                    id="checkPoint_rule"
                                                    className="form-control rule-input"
                                                    maxLength="255"
                                                    value={state.intelligentCondition}
                                                    disabled={!hasFeature || !hasFeature('task:template:manual:import')}
                                                    onChange={actions.inputMutator('state.intelligentCondition', true)}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='manual-add-records-box tasks-manage-template-manual-add-index-content-history'>
                                        <If condition={hasFeature && hasFeature('task:template:manual:history')}>
                                            <If condition={privates.model.manualAddRecords.data && privates.model.manualAddRecords.data.length}>
                                                <div style={{ margin: '0px 0px 15px', fontWeight: 'bold'}}>
                                                    {locales.pages.tasks.manage.template.manualAdd.index.records}
                                                </div>
                                            </If>
                                            <For each='record' index='index' of={privates.model.manualAddRecords.data}>
                                                <div className='manual-add-records-item'>
                                                    <div className='manual-add-records-item-row'>
                                                        <span className='manual-add-records-item-col'>
                                                            <span className='manual-add-records-item-row-title'>
                                                                {
                                                                    locales.pages.tasks.manage.template.manualAdd.index
                                                                        .createTime
                                                                }
                                                                {locales.labels.colon}
                                                            </span>
                                                            <span className='manual-add-records-item-row-content'>
                                                                {record.createTime}
                                                            </span>
                                                        </span>
                                                        <span className='manual-add-records-item-col'>
                                                            <span className='manual-add-records-item-row-title'>
                                                                {
                                                                    locales.pages.tasks.manage.template.manualAdd.index
                                                                        .successCount
                                                                }
                                                                {locales.labels.colon}
                                                            </span>
                                                            <span className='manual-add-records-item-row-content'>
                                                                {record.successCount}
                                                            </span>
                                                        </span>
                                                        <span className='manual-add-records-item-col'>
                                                            <span className='manual-add-records-item-row-title'>
                                                                {
                                                                    locales.pages.tasks.manage.template.manualAdd.index
                                                                        .failCount
                                                                }
                                                                {locales.labels.colon}
                                                            </span>
                                                            <span className='manual-add-records-item-row-content'>
                                                                {record.failCount}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className='manual-add-records-item-row'>
                                                        <span className='manual-add-records-item-col'>
                                                            <span className='manual-add-records-item-row-title'>
                                                                {
                                                                    locales.pages.tasks.manage.template.manualAdd.index
                                                                        .startTime
                                                                }
                                                                {locales.labels.colon}
                                                            </span>
                                                            <span className='manual-add-records-item-row-content'>
                                                                {record.startTime}
                                                            </span>
                                                        </span>
                                                        <span className='manual-add-records-item-col'>
                                                            <span className='manual-add-records-item-row-title'>
                                                                {
                                                                    locales.pages.tasks.manage.template.manualAdd.index
                                                                        .endTime
                                                                }
                                                                {locales.labels.colon}
                                                            </span>
                                                            <span className='manual-add-records-item-row-content'>
                                                                {record.endTime}
                                                            </span>
                                                        </span>
                                                        <span className='manual-add-records-item-col'>
                                                            <span className='manual-add-records-item-row-title'>
                                                                {
                                                                    locales.pages.tasks.manage.template.manualAdd.index
                                                                        .createUserName
                                                                }
                                                                {locales.labels.colon}
                                                            </span>
                                                            <span className='manual-add-records-item-row-content'>
                                                                {record.createUserName}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className='manual-add-records-item-row-filter'>
                                                        {/* <div className='manual-add-records-item-row-filter-title'>
                                                            {
                                                                locales.pages.tasks.manage.template.manualAdd.index
                                                                    .filterTitle
                                                            }
                                                        </div> */}
                                                        <div className='manual-add-records-item-row-filter-rules' style={{marginBottom: 16}}>
                                                            <div className='manual-add-records-item-row-title'>
                                                                {
                                                                    locales.pages.tasks.manage.template.manualAdd.index
                                                                        .filterRule
                                                                }
                                                                {locales.labels.colon}
                                                            </div>
                                                            <div className='manual-add-records-item-row-content'>
                                                                <If condition={record.baseFilter}>
                                                                    <div className='manual-add-records-item-row-condition'>
                                                                        {/* {
                                                                            locales.pages.tasks.manage.template
                                                                                .manualAdd.index.screeningCondition
                                                                        }
                                                                        {' ' + locales.labels.hyphen + ' '} */}
                                                                        <If condition={record.baseFilter.conditionList}>
                                                                            <For
                                                                                each='condition'
                                                                                index='index'
                                                                                of={record.baseFilter.conditionList}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        marginBottom:
                                                                                            index + 1 ===
                                                                                                record.baseFilter
                                                                                                    .conditionList.length
                                                                                                ? '0px'
                                                                                                : '8px',
                                                                                    }}
                                                                                >
                                                                                    {index + 1 + ' '}
                                                                                    {condition.field.label + ' '}
                                                                                    {Udesk.enums.fieldOperators.getName(
                                                                                        condition.operator
                                                                                    ) + ' '}
                                                                                    {Udesk.helpers.manualAddValue(
                                                                                        condition.value,
                                                                                        condition.field
                                                                                    )}
                                                                                </div>
                                                                            </For>
                                                                        </If>
                                                                    </div>
                                                                </If>
                                                                {/* <If condition={record.intelligentCondition}>
                                                                    <span className='manual-add-records-item-row-condition'>
                                                                        {
                                                                            locales.pages.tasks.manage.template
                                                                                .manualAdd.index.aiScreeningCondition
                                                                        }
                                                                        {' ' + locales.labels.hyphen + ' '}
                                                                        {record.intelligentCondition}
                                                                    </span>
                                                                </If> */}
                                                            </div>
                                                        </div>
                                                        <div className='manual-add-records-item-row-filter-rules'>
                                                            <div className='manual-add-records-item-row-title'>
                                                                {locales.components.common.customFilter.conditionRule}：
                                                            </div>
                                                            <div className='manual-add-records-item-row-content'>
                                                                {record && record.baseFilter && record.baseFilter.judgeStrategy && record.baseFilter.judgeStrategy !== 3 &&
                                                                    Udesk.enums.operatorRuleTypes.find(
                                                                        (current) =>
                                                                            current.id === (record.baseFilter.judgeStrategy || Udesk.enums.operatorRuleTypes.all.id)
                                                                    ).name}
                                                                {record && record.baseFilter && record.baseFilter.judgeStrategy === 3 &&
                                                                    record.baseFilter.customJudgeLogic}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </For>
                                            <If condition={state.total > 0}>
                                                <Pagination
                                                    defaultCurrent={1}
                                                    total={state.total}
                                                    pageSize={state.pageSize}
                                                    current={state.pageNum}
                                                    showTotal={actions.showTotal}
                                                    showSizeChanger={true}
                                                    pageSizeOptions={['5', '10', '20', '50', '100']}
                                                    onChange={actions.pageChanged}
                                                    onShowSizeChange={actions.onShowSizeChange}
                                                />
                                            </If>
                                        </If>
                                    </div>
                                </div>
                                <If condition={privates.model.processCreateStatus.processStatus === 6}>
                                    <div className='tasks-manage-template-basic-info-index-footer'>
                                        <Button type='primary' onClick={actions.sendAjaxToNext}>
                                            {locales.pages.tasks.manage.template.basicInfo.index.complete}
                                        </Button>
                                    </div>
                                </If>
                                <Modal
                                    title={locales.fix.taskStartPrompt}
                                    visible={this.state.showModel}
                                    onOk={actions.handleOk}
                                    onCancel={actions.handleCancel}
                                >
                                    <p>{this.props.route.name === 'informationCollectDataAddAuto' ? locales.fix.buttonStartCollectionTask : locales.fix.buttonStartTask}</p>
                                </Modal>
                            </If>
                            <CustomFilterModal
                                key='customFilterModal'
                                visible={privates.isFilterVisible}
                                cancel={actions.handleConditionCancel}
                                applyAction={actions.handleConditionApply}
                                onConditionFilterSaved={actions.onConditionFilterSaved}
                                judgeStrategy={privates.judgeStrategy}
                                customJudgeLogic={privates.customJudgeLogic}
                                conditionList={privates.conditionList}
                                customName={privates.customName}
                                customId={privates.customId}
                                type={privates.type}
                                querryType='manualAdd'
                                taskName={this.props.sdkOptions.props.task.name}
                                filterList={filterList}
                            />
                        </div>
                    </div>
                </div>
                {/* <TasksConditionDictionaries items={privates.model.inspectionConditions} /> */}
            </React.Fragment>
        );
    }
}
