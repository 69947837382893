import React, { useCallback, useState } from 'react';
import Udesk from 'Udesk';
import { Row, Col, Input, Form, Button, Spin, Space, Select, InputNumber } from 'udesk-ui';
import { useLiveEffect } from 'src/util/hook';
import { EnumRadio } from 'Component/common/enum-component';
import SelectTree2 from './selectTree2';
import SmartToolBar from './smartToolbar';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const formatId = (ids) => {
    if (ids) {
        if (typeof ids === 'string') {
            return ids.split(',').map((id) => +id);
        }
        return ids;
    }
    return [];
};

export const useUserTreeData = (request) => {
    const [result, setResult] = useState<any[]>([]);

    useLiveEffect(() => {
        const url = 'organizations/users';
        const next = (data, list: any[]) => {
            data.forEach((item) => {
                const id = `category-${item.id}`;
                const folder = {
                    id,
                    title: item.name,
                    pId: item.parentId,
                };
                if (item.users) {
                    if (item.users.length > 0) {
                        item.users.forEach(user => list.push({
                            id: `point-${user.id}`,
                            title: user.realname,
                            pId: id,
                        }));
                    }
                }
                list.push(folder);
            });
            return list;
        };

        return request(url, {}).then((resp) => setResult(next(resp.data, [])));
    }, []);

    return [result];
};

type subjectOptionType = {
    clusterId: number;
    value: number;
    label: string;
}

export default React.memo((props: any) => {
    const {
        scenario, 
        openSmartToolBarCallback, 
        closeSmartToolBarCallback, 
        selectedRowKey, 
        visible,
        request, 
        closeHandle, 
        locales, 
        SmartToolBarRef
    }= props;
    const [isShow, setIsShow] = useState(true);
    const [form] = Form.useForm();
    const [userTreeData] = useUserTreeData(request);
    const [subjectDataSource, setSubjectDataSource] = useState<subjectOptionType[]>([]);
    const [loading, setLoading] = useState(false);

    const submitHandle= useCallback((values) => {
        const {configId: id, user, subjectId, semanticTagList, ...params} = values;
        let url = 'review/words/config';
        let method = 'post';

        if (id) {
            url = `review/words/config/${id}`;
            method = 'put';
            params.id = id;
        }
        if (user) {
            params.organizationId = user.category.join();
            params.userId = user.point.join();
        }
        if (subjectId && subjectId.length > 0) {
            params.subjectId = subjectId.join();
        }
        if (semanticTagList && semanticTagList.length > 0) {
            params.semanticTagId = semanticTagList.map(item => item.semanticId).join();
        }

        params.scenario = scenario;
        
        request(url, params, method).then(
            resp => {
                Udesk.ui.notify.success(/* 保存成功 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.rightForm.savingSucceeded);
                if (method === 'post') {
                    form.setFieldsValue({
                        ...values,
                        configId: resp.data.id,
                    });
                }
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        locales,
        form, scenario,
        request
    ]);

    const changeHandle = useCallback((value) => {
        if (typeof value.enableRecommend !== 'undefined') {
            setIsShow(!!value.enableRecommend);
        }
    }, []);

    const getDefaultSubjectId = useCallback(
        clusterId=> subjectDataSource.filter(item => item.clusterId === clusterId).map(item => item.value),
        [ 
            subjectDataSource
        ]
    );

    useLiveEffect((success) => {
        if (visible && selectedRowKey) {
            setLoading(true);
            request('/review/words/config', {
                clusterId: selectedRowKey
            }).then(({data}) => {
                success(() => {
                    setLoading(false);

                    if (data) {
                        data.configId = data.id;
                        data.user = {
                            category: [],
                            point: [],
                        };
                        data.user.point = formatId(data.userId);
                        data.user.category = formatId(data.organizationId);
                        data.subjectId = formatId(data.subjectId);
                        data.semanticTagList = data.semanticTagList ?? [];
                    } else {
                        data = {
                            configId: undefined,
                            clusterId: selectedRowKey,
                            user: {
                                point: [],
                                category: []
                            },
                            semanticTagList: [],
                            enableRecommend: 1,
                            remindCheck: 1,
                            subjectId: getDefaultSubjectId(selectedRowKey)
                        };
                    }
                    form.setFieldsValue(data);
                    changeHandle(data);
                });
            });
        }
    }, [visible, selectedRowKey, getDefaultSubjectId]);

    useLiveEffect((success) => {
        request(`/review/clusterResult/subject/${scenario}`).then(({data}) => {
            success(() => {
                setSubjectDataSource(data.map(item => {
                    return {
                        clusterId: item.clusterId,
                        value: item.subjectId,
                        label: item.subjectName
                    };
                }));
            });
        });
    }, [
        scenario
    ]);

    return (

        <Spin spinning={loading} wrapperClassName='favorite-config-table-right'>
            <Form 
                form={form} 
                onFinish={submitHandle}
                onValuesChange={changeHandle}
                labelCol={{span: 7}}
                wrapperCol={{span: 16}}
            >
                <Form.Item name='configId' hidden={true}>
                    <InputNumber  />
                </Form.Item>
                <Form.Item name='clusterId' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item 
                    name='enableRecommend' 
                    label={/* 智能推荐 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.rightForm.intelligentRecommendation}
                    extra={/* 将已采纳的优秀话术自动显示在相似异议的会话侧边栏，员工在会话复盘详情页可直接查看推荐话术进行学习 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.rightForm.theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview}>
                    <EnumRadio enumKey="gradeChooseHits"></EnumRadio>
                </Form.Item>
                {
                    isShow ? (
                        <>
                            <Row className='udesk-qa-ui-form-vertical'>
                                <Col offset={7} span={16}>
                                    <Form.Item 
                                        labelCol={{
                                            span: 24
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        name='subjectId' 
                                        label={/* 默认包含的卖点 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageRightTable.configDrawer.rightForm.defaultIncludedSellingPoints}>
                                        <Select
                                            mode="multiple"
                                            options={subjectDataSource.map(item => ({
                                                value: item.value,
                                                label: item.label
                                            }))}
                                            showSearch={true}
                                            optionFilterProp="label"
                                        />
                                    </Form.Item>
                                    <Form.Item 
                                        labelCol={{
                                            span: 24
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        name='semanticTagList' 
                                        label={/* 增加推荐触发语义 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.rightForm.addRecommendationTriggerSemantics}>
                                        <SmartToolBar 
                                            locales={locales}
                                            openSmartToolBarCallback={openSmartToolBarCallback}
                                            closeSmartToolBarCallback={closeSmartToolBarCallback} 
                                            SmartToolBarRef={SmartToolBarRef} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={7} span={16}>
                                    <Form.Item 
                                        name='remindCheck' 
                                        label={/* 提醒员工查看 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.rightForm.remindEmployeesToView}>
                                        <EnumRadio enumKey="gradeChooseHits"></EnumRadio>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    ) : null
                }
                
                <Form.Item 
                    name='user'
                    label={/* 分享给更多员工 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.rightForm.shareWithMoreEmployees}>
                    <SelectTree2 placeholder={/* 全体员工 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.rightForm.allHands} treeData={userTreeData} treeDataSimpleMode={true}/>
                </Form.Item>
                <Form.Item wrapperCol={{
                    offset: 7,
                    span: 16,
                }}>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            {locales.labels.confirm}
                        </Button>
                        <Button htmlType="button" onClick={closeHandle}>
                            {locales.labels.cancel}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    );
});
