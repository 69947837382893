import React from 'react';
import Udesk from 'Udesk';
import SDK from 'src/components/common/udesk-qa-result-sdk';
import './style.scss';

const Template = React.memo((props: any) => {
    return (
        <div className="qa-result-web-wrapper">
            <div id="qa-result-container"></div>
            <SDK renderSdkContainerId={'qa-result-container'} />
        </div>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
