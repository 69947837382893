import React from 'react';
import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import uploadListRoute from './route';
import './style.scss';

export default class ListManageTemplate extends uploadListRoute {
    render() {
        // let { sdkOptions } = this.props;
        let { paging, columns, data } = this.privates;

        let { actions, locales } = this;
        return (
            <div className='qa-react-list-page-uploadDownload'>
                <div className='qa-react-list-page-body-uploadDownload'>
                    <div className='qa-react-list-page-functions'></div>
                    <div className='qa-react-list-page-content' style={{ position: 'static' }}>
                        <Choose>
                            <When condition={data && data.length > 0}>
                                <ReactTable
                                    horizontalScrollbar={true}
                                    showHorizontalScrollbar={true}
                                    columns={columns}
                                    items={data}
                                />
                            </When>
                            <Otherwise>
                                <div className='qa-react-list-page-content-no-value'>{locales.labels.noValue}</div>
                            </Otherwise>
                        </Choose>
                    </div>
                </div>
                <div className='qa-react-list-page-footer'>
                    <If condition={data && data.length > 0}>
                        {/* <ReactPager
                            showTotalPages={true}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={0}
                            pageSize={paging.pageSize}
                            total={paging.total}
                            pageNum={paging.pageNum}
                            languageCode={sdkOptions.props.language}
                            pageChanged={actions.pageChanged}
                        /> */}
                        <UdeskPagination
                            current={paging.pageNum}
                            pageSize={paging.pageSize}
                            total={paging.total}
                            onChange={actions.pageChanged}
                        />
                    </If>
                </div>
            </div>
        );
    }
}
