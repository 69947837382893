import React from 'react';
import { Modal, message } from 'udesk-ui';
import { deleteInspectionDataByIdDelete } from 'src/api/inspectionData/{id}/delete';
import UdeskLocales from 'UdeskLocales';

class ApplyTemplatesComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        templates: [],
    };
    //#endregion

    state = {};
    privates = {};
    static computes = {};
    actions = {
        transitionToTemplate(template) {
            this.trigger('onClickTemplate', template);
        },
        deleteTemplate(template) {
            Modal.confirm({
                title: /* 确定删除吗？ */UdeskLocales['current'].components.applyTemplates.component.areYouSureToDeleteIt,
                onOk: () => {
                    console.log(template);
                    deleteInspectionDataByIdDelete({ segments: { id: template.id } }).then(() => {
                        message.success(/* 操作成功 */UdeskLocales['current'].components.applyTemplates.component.operationSuccessful);
                        this.props.onRefresh();
                    });
                },
            });
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {}
    componentDidMount() {}
    componentWillUnmount() {}
    asyncModel(changedParams) {}
    //#endregion
}

export default ApplyTemplatesComponent;
