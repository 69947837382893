import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import AdminUserViewComponent from './component';

export default class AdminUserViewRoute extends AdminUserViewComponent {
    model() {
        let params = Object.assign(
            {},
            this.props.match.params,
            Object.fromEntries(new URLSearchParams(this.props.location.search))
        );
        let modelPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(Udesk.business.apiPath.concatApiPath(`/users/${params.id}`, this.props.sdkOptions)).then(
                resp => {
                    if (!resp.data.userGroupList) {
                        resp.data.userGroupList = [];
                    }
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.user.loadUserListError);
                    reject(reason);
                }
            );
        });
        return {
            user: modelPromise
        };
    }
}
