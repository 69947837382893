import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import UdeskLocales from 'UdeskLocales';
class semanticIntelligence extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let { route, location, sdkOptions } = this.props;

        let { locales } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:corpora:root')) {
            navLists.push({
                text: locales.components.pages.smartWordsLibrary.title,
                pathName: 'smartWordsLibrarySynonymWords',
                isActive: (match, location) => {
                    return location.pathname.includes('/smart-words-library');
                },
            });
        }
        //概念词
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:conceptWords:root')) {
            navLists.push({
                text: locales.components.pages.conceptWord.title,
                pathName: 'conceptWord',
                isActive: (match, location) => {
                    return location.pathname.includes('/concept-word');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:semanticTag:root')) {
            navLists.push({
                text: locales.components.pages.semanticTags.title,
                pathName: 'semanticTagsIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/semantic-tags');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:imformation:root')) {
            navLists.push({
                text: locales.components.pages.informationEntities.title,
                pathName: 'informationEntitiesIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/information-entities');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:interactive:root')) {
            navLists.push({
                text: locales.components.pages.interactiveRecognition.title,
                pathName: 'interactiveRecognition',
                isActive: (match, location) => {
                    return location.pathname.includes('/interactive-recognition');
                },
            });
        }
        if (
            Udesk.data.init.user &&
            Udesk.data.init.user.hasFeature('semantic:knowledgeData:root')
        ) {
            navLists.push({
                text: locales.components.pages.knowledgeBase.title,
                pathName: 'knowledgeBase',
                isActive: (match, location) => {
                    return location.pathname.includes('/knowledge-base');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:smartModel:root')) {
            navLists.push({
                text: /* 智能模型 */ UdeskLocales['current'].pages.semanticIntelligence
                    .intelligentModel.index.intelligentModel,
                pathName: 'intelligentModel',
                isActive: (match, location) => {
                    return location.pathname.includes('/intelligent-model');
                },
            });
        }

        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:applyMsg:root')) {
            navLists.push({
                text: /* 应用消息类型 */UdeskLocales['current'].pages.semanticIntelligence.applicationMessageType,
                pathName: 'applicationMessage',
                isActive: (match, location) => {
                    return location.pathname.includes('/application-message');
                },
            });
        }
        // if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:cluster:root")) {
        //     navLists.push({
        //         text: /* 聚类分析 */locales.pages.semanticIntelligence.clusterAnalysis,
        //         pathName: 'clusteringAnalysis',
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/clustering-analysis");
        //         }
        //     });
        // }
        // if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:WordsMining:root")) {
        //     navLists.push({
        //         text: /* 话术挖掘 */locales.pages.semanticIntelligence.speechMining,
        //         pathName: "wordsMining",
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/words-mining");
        //         }
        //     });
        // }
        if (
            this.isRedirect(route.path, location.pathname) &&
            sdkOptions.props.landingPath != null
        ) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="semantic-intelligence">
                    {/* <div className="semantic-intelligence-nav col-md-2 col-sm-3 col-xs-12">
                        <NavBar navLists={navLists} classNames="semantic-intelligence-nav-container" navItemClassNames="semantic-intelligence-nav-item" activeClassNames="semantic-intelligence-nav-item-active" />
                    </div>
                    <div className="semantic-intelligence-content col-md-10 col-sm-9 col-xs-12">
                        <For each="route" index="i" of={routes}>
                            <If condition={!route.authCode || (route.authCode && Udesk.data.init.user.hasFeature(route.authCode))}>
                                <SubRoutes key={i} route={route} />
                            </If>
                        </For>
                    </div> */}
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={locales.components.pages.menuTitleList.semanticIntelligence}
                    />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(semanticIntelligence);
