import Locales from '../locales/index';

let recommendItemActionTypes = [
    { id: 1, key: "similar", name: () => Locales.current.enums.recommendItemActionTypes.similar },
    { id: 2, key: "exclude", name: () => Locales.current.enums.recommendItemActionTypes.exclude },
    { id: 0, key: "ignore", name: () => Locales.current.enums.recommendItemActionTypes.ignore }
];

export default {
    recommendItemActionTypes
};
