import React, { FC, useEffect, useMemo, useState } from 'react';
import { Space, Button, Card, Checkbox, message, Typography, Empty, Spin } from 'udesk-ui';
import { RefreshOutlined } from '@udesk/icons';
import { postSemanticTagRecommend } from 'src/api/semantic-tag/recommend';
import debounce from 'lodash-es/debounce';
import { hasFeature } from 'src/util/permit';
import './index.less';
import UdeskLocales from 'UdeskLocales';

type Question = {
    value: string;
    checked?: boolean;
    [key: string]: unknown;
};

type SimilarQuestionRecommendProps = {
    onSave?: (list: Question[]) => void;
    onCancel?: () => void;
    content?: string;
    btnProps?: { [key: string]: unknown };
    width?: number;
};

const SimilarQuestionRecommend: FC<SimilarQuestionRecommendProps> = (props) => {
    const { onSave, content, width } = props;

    const isLargeModel = hasFeature('sys:largeAi:qa:similarWords');

    const [recommend, setRecommend] = useState<Question[]>([]);
    const [loading, setLoading] = useState(false);

    const getRecommend = useMemo(
        () =>
            debounce((content: string) => {
                if (content) {
                    setLoading(true);
                    postSemanticTagRecommend({ standardContent: content })
                        .then((res) => {
                            if (res.data) {
                                const questions = (res.data || []).map((q: any) => ({
                                    value: q,
                                    checked: false,
                                }));
                                setRecommend(questions);
                            }
                        })
                        .catch(() => {
                            setRecommend([]);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }
            }, 300),
        []
    );

    useEffect(() => {
        isLargeModel && getRecommend(content || '');
    }, [content, getRecommend, isLargeModel]);

    const onCheckedChange = (idx: number, e: any) => {
        setRecommend((_recommend) =>
            _recommend.map((r, _idx) => {
                if (_idx === idx) {
                    return {
                        ...r,
                        checked: e.target.checked,
                    };
                } else {
                    return r;
                }
            })
        );
    };

    const [allChecked, setAllChecked] = useState(false);
    const onAllCheckedChange = (e: any) => {
        setAllChecked(e.target.checked);
        setRecommend((_recommend) =>
            _recommend.map((r) => {
                return {
                    ...r,
                    checked: e.target.checked,
                };
            })
        );
    };

    const onSaveInner = () => {
        const _r = recommend.filter((r) => r.checked);
        if (!_r.length) {
            message.warning(
                /* 请选择问题！ */ UdeskLocales['current'].pages.trainingCenter
                    .similarQuestionRecommend.index.pleaseSelectAQuestion
            );
            return;
        }
        onSave?.(_r);
        setAllChecked(false);
        onRefresh();
    };

    const onRefresh = () => {
        setAllChecked(false);
        getRecommend(content || '');
    };

    return (
        <Card
            size="small"
            title={
                <Space>
                    <span>
                        {/* 推荐相似句(大模型) */}
                        {
                            UdeskLocales['current'].pages.trainingCenter.similarQuestionRecommend
                                .index.recommendedSimilarSentencesLargeModel
                        }
                    </span>
                </Space>
            }
            extra={
                isLargeModel ? (
                    <Space size="small">
                        <Checkbox
                            checked={allChecked}
                            onChange={onAllCheckedChange}
                            disabled={loading}
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                {/* 全选 */}
                                {
                                    UdeskLocales['current'].pages.trainingCenter
                                        .similarQuestionRecommend.index.selectAll
                                }
                            </Button>
                        </Checkbox>
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={onSaveInner}
                            disabled={loading}
                        >
                            {/* 添加 */}
                            {
                                UdeskLocales['current'].pages.trainingCenter
                                    .similarQuestionRecommend.index.add
                            }
                        </Button>
                    </Space>
                ) : null
            }
            style={{ width: width || '100%', height: '100%' }}
            headStyle={{ background: 'rgba(0, 0, 0, 0.03)' }}
            bodyStyle={{
                height: 'calc(100% - 50px)',
                padding: '12px 12px 0',
                background: 'rgba(0, 0, 0, 0.03)',
            }}
        >
            <div style={{ height: 'calc(100% - 32px)', overflowY: 'auto' }}>
                <Spin spinning={loading}>
                    <div style={{ minHeight: 60 }}>
                        {recommend.length ? (
                            recommend.map((r, idx) => (
                                <div
                                    key={r.value + idx}
                                    style={{ width: '100%', display: 'flex', marginBottom: 8 }}
                                >
                                    <Checkbox
                                        style={{ marginRight: 10 }}
                                        checked={!!r.checked}
                                        onChange={(e) => onCheckedChange(idx, e)}
                                    ></Checkbox>
                                    <Typography.Text
                                        ellipsis={{
                                            tooltip: (
                                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                                    {r.value}
                                                </div>
                                            ),
                                        }}
                                    >
                                        {r.value}
                                    </Typography.Text>
                                </div>
                            ))
                        ) : loading ? null : (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    isLargeModel
                                        ? undefined
                                        : /* 暂未开放此功能，如需要请联系系统管理员 */ UdeskLocales[
                                              'current'
                                          ].pages.trainingCenter.similarQuestionRecommend.index
                                              .thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary
                                }
                            />
                        )}
                    </div>
                </Spin>
            </div>

            {isLargeModel ? (
                <div style={{ padding: '4px 0', textAlign: 'right' }}>
                    <Button
                        shape="round"
                        size="small"
                        icon={<RefreshOutlined />}
                        onClick={onRefresh}
                        disabled={loading}
                    >
                        {/* 换一批 */}
                        {
                            UdeskLocales['current'].pages.trainingCenter.similarQuestionRecommend
                                .index.changeBatch
                        }
                    </Button>
                </div>
            ) : null}
        </Card>
    );
};

export default SimilarQuestionRecommend;
