import {
    getReviewIntelligentPartnerNodeInputSceneConfig,
    postReviewIntelligentPartnerNodeInputSceneConfig,
} from 'src/api/review/intelligentPartner/node/inputSceneConfig';
import { putReviewIntelligentPartnerNodeInputSceneConfigById } from 'src/api/review/intelligentPartner/node/inputSceneConfig/{id}';
import FormHeader from '../../FormHeader';
import UdeskLocales from 'UdeskLocales';

const fields = (props) => [
    {
        label: /* 对话场景 */ UdeskLocales['current'].pages.coach.courseManagement.flow.components
            .const.nodeConfigs.aiGenSceneNode.dialogueScenario,
        type: 'TextArea',
        name: 'dialogueScene',
        props: {
            rows: 4,
            placeholder: UdeskLocales['current'].labels.pleaseEnter,
        },
        rules: [{ required: true }],
    },
    {
        label: /* 模拟角色 */ UdeskLocales['current'].pages.coach.courseManagement.flow.components
            .const.nodeConfigs.aiGenSceneNode.simulateCharacter,
        type: 'CustomerPortraitConfigSelect',
        name: 'simulatedRole',
        rules: [{ required: true }],
    },
    {
        label: /* 陪练对象 */ UdeskLocales['current'].pages.coach.courseManagement.flow.components
            .const.nodeConfigs.aiGenSceneNode.accompanyingTrainees,
        type: 'Input',
        name: 'sparringPartner',
        rules: [{ required: true }],
        props: {
            placeholder: UdeskLocales['current'].labels.pleaseEnter,
        },
    },
];

const request = ({ nodeId }) => {
    return getReviewIntelligentPartnerNodeInputSceneConfig({
        params: {
            nodeId,
        },
    }).then((resp) => {
        const res: any = resp.data || {};
        return res;
    });
};

const finish = ({ formInstanceCache, configId, nodeId }) => {
    return (values) => {
        const params = {
            ...values,
            nodeId,
        };

        if (!configId) {
            return postReviewIntelligentPartnerNodeInputSceneConfig(params, {
                successMsg:
                    /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                        .components.const.nodeConfigs.knowledgeNode.successfullySaved,
            });
        } else {
            return putReviewIntelligentPartnerNodeInputSceneConfigById(params, {
                segments: {
                    id: configId,
                },
                successMsg:
                    /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                        .components.const.nodeConfigs.knowledgeNode.successfullySaved,
            });
        }
    };
};

export default {
    fields,
    request,
    finish,
    FormHeader,
};
