import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import Locales from 'UdeskLocales';

export default class DemoIndexComponent extends React.Component {
    //#region state
    state = {
        template: null,
        voiceType: Udesk.enums.voiceDemoTypes.singleChannel.id,
        highlightContent: '',
        highlightSeqList: [],
        voiceUrl: null,
        textUrl: null,
        timerToken: null,
        inspectionData: {},
        modelVisible: false,
        demoResultVisible: false,
        uploadStatus: null,
        uploadTextStatus: null,
        voiceName: null,
        textName: null,
        callId: null,
        iframeUrl: null,
        dataId: null,
        ossKey: null,
        textOssKey: null,
        isComplete: false,
        isFailure: false,
    };
    //#endregion

    privates = {
        audioCallInfoRef: React.createRef(),
        waveAudioComponent: React.createRef(),
        waveAudioOptions: {
            height: "50"
        },
        basicInfoRef: React.createRef(),
        pointRef: React.createRef(),
        semanticContent: '',
        highlightGroupList: [],
        smartTags: [],
        inspectionDetailRef: React.createRef(),
        inspectionStatusMap: [],
        qualityInspectionStarted: false,
        ASRFailure: false,
        queryCheckResultClicked: false,
        inspectionStatusTitleMap: {'1': Locales['current'].fix.asrRunning, '2': Locales['current'].fix.errorCorrectionExecution, '3': Locales['current'].fix.emotionalVolumeRecognition, '4': Locales['current'].fix.dataPreprocessing, '5': Locales['current'].fix.inspectionExecution},
        statusList: {'1':'wait', '2':'process', '3':'finish', '4':'error'},
    };

    //#region Life Cycle
    componentDidMount() {
        const templates = this.privates.model ? this.privates.model.templates : [];
        if (templates && templates.length > 0) {
            this.setState({
                template: templates[0].id
            });
        }
    }
    componentWillUnmount() {
        let timerToken = this.state.timerToken;

        if (timerToken != null) {
            clearInterval(timerToken);
        }
    }
    //#endregion

    //#region actions
    actions = {
        changeSelectTemplate(value) {
            this.setState({
                template: value
            });
        },

        onValueChanged(key, e) {
            let value = e;
            if (e.target) {
                value = e.target.value;
            }
            this.setState({
                [key]: value
            });
        },

        uploadAudio(file) {
            if (file.size > 1024000 * 1000) {
                return Udesk.ui.notify.error(this.locales.pages.demo.uploadAudioTip);
            }
            Udesk.app.fileStorage.upload(file, {
                token: getOssAuthorization(this.privates.model.account, 1),
                successCallback: this.actions.onFileUploadSuccess.bind(this),
                errorCallback: this.actions.onFileUploadError.bind(this),
            });
        },

        onFileUploadSuccess(file, uploadData) {
            this.setState({
                voiceName: file.name,
                voiceUrl: `${uploadData.token.host}/${uploadData.key}`,
                uploadStatus: 1,
                ossKey: uploadData.key,
            });
        },

        onFileUploadError() {
            this.setState({
                uploadStatus: -1
            });
        },

        uploadText(file) {
            if (file.size > 10240 * 1000) {
                return Udesk.ui.notify.error(this.locales.pages.demo.uploadTextTip);
            }
            Udesk.app.fileStorage.upload(file, {
                token: getOssAuthorization(this.privates.model.account, 6),
                successCallback: this.actions.onTextFileUploadSuccess.bind(this),
                errorCallback: this.actions.onTextFileUploadError.bind(this),
            });
        },

        onTextFileUploadSuccess(file, uploadData) {
            this.setState({
                textName: file.name,
                textUrl: `${uploadData.token.host}/${uploadData.key}`,
                uploadTextStatus: 1,
                textOssKey: uploadData.key,
            });
        },

        onTextFileUploadError() {
            this.setState({
                uploadTextStatus: -1
            });
        },

        startQualityInspection() {
            let account = this.privates.model.account;
            let url = `/api/demo/save?email=${account}@udesk.cn`;
            let taskId = this.state.template;
            let voiceType = this.state.voiceType;
            let voiceUrl = this.state.voiceUrl;
            let ossKey = this.state.ossKey;
            let textOssKey = this.state.textOssKey;
            if (taskId && voiceType && voiceUrl && !this.privates.qualityInspectionStarted) {
                this.privates.qualityInspectionStarted = true;
                this.privates.queryCheckResultClicked = false;
                this.privates.inspectionStatusMap = [];
                this.actions.update();
    
                this.setState({
                    demoResultVisible: false,
                    modelVisible: true,
                    dataId: null,
                    isFailure: false,
                    isComplete: false,
                });
                let params = {
                    taskId,
                    voiceType,
                    ossKey,
                    textOssKey
                };
                Udesk.ajax.post(url, params).then(
                    resp => {
                        if (resp && resp.data && resp.data.callId) {
                            this.setState({
                                callId: resp.data.callId
                            });
                            this.actions.queryCheckStatus(resp.data.callId);
                            let timerToken = setInterval(() => {
                                this.actions.queryCheckStatus(resp.data.callId);
                            }, 10 * 1000);
                            this.setState({
                                timerToken: timerToken
                            });
                        }
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            } else {
                Udesk.ui.notify.error(UdeskLocales.current.pages.demo.pleaseCheckParams);
            }
        },

        queryCheckStatus(callId) {
            let taskId = this.state.template;
            let account = this.privates.model.account;
            let url = `/api/demo/status/${callId}/${taskId}?email=${account}@udesk.cn`;
            // let url = `/api/demo/status/72152/410?email=${account}@udesk.cn`;
            // let url = `/api/demo/status/${callId}/${taskId}`;

            Udesk.ajax.get(url).then(
                resp => {
                    if (resp && resp.data) {
                        // resp.data.inspectionStatusMap = {'1': 2, '2':2, '3':1, '4': 1, '5': 1};
                        if(resp.data.inspectionStatusMap){
                            this.privates.inspectionStatusMap = [];
                            for (const [key, value] of Object.entries(resp.data.inspectionStatusMap)) {
                                /* 
                                key
                                ASR执行 ASR(1),
                                纠错执行 RECOVER(2),
                                情绪音量识别 IMPROVE(3),
                                数据预处理 PRE_PROCESS(4),
                                质检执行 INSPECTING(5) 
                                */
                                /* 
                                value
                                等待中 WAITING(1),
                                执行中 PROCESSING(2),
                                执行完成 SUCCESS(3),
                                执行失败 FAILURE(4)
                                */
                                let title = this.privates.inspectionStatusTitleMap[key];
                                let status = this.privates.statusList[value];
                                this.privates.inspectionStatusMap.push({
                                    title,
                                    status,
                                    key,
                                    value,
                                });
                                if(value === 4 && key !== 3){
                                    Udesk.ui.notify.error(UdeskLocales.current.pages.demo.inspectionFailed);
                                    let timerToken = this.state.timerToken;
                                    if (timerToken != null) {
                                        clearInterval(timerToken);
                                    }
                                    this.privates.qualityInspectionStarted = false;
                                    this.setState({
                                        isFailure: true,
                                    });
                                }
                                if(parseInt(key,10) === 5 && value === 3){
                                    let timerToken = this.state.timerToken;
                                    if (timerToken != null) {
                                        clearInterval(timerToken);
                                    }
                                    this.privates.qualityInspectionStarted = false;
                                    this.setState({
                                        isComplete: true,
                                    });
                                }
                                if(parseInt(key,10) === 1 && value === 4){
                                    this.privates.ASRFailure = true;
                                }
                            }
                        }
                        this.actions.update();
                        if (resp.data.id) {
                            this.setState({
                                dataId: resp.data.id
                            });
                        }
                    }
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },

        queryCheckResult() {
            // let account = this.privates.model.account;
            let url = `/api/demo/inspection`;
            let params = {
                taskId: this.state.template,
                callId: this.state.callId,
                id: this.state.dataId,
            };
            if(this.privates.ASRFailure){
                this.privates.ASRFailure = false;
                this.setState({
                    modelVisible: false,
                });
                return;
            }
            this.privates.queryCheckResultClicked = true;
            this.actions.update();
            Udesk.ajax.post(url, params).then(
                resp => {
                    this.privates.queryCheckResultClicked = false;
                    this.privates.qualityInspectionStarted = false;

                    this.setState({
                        highlightContent: '',
                        highlightSeqList: [],
                        inspectionData: resp.data,
                        demoResultVisible: true,
                        modelVisible: false,
                        isComplete: false,
                        isFailure: false,
                    });
                },
                reason => {
                    this.privates.queryCheckResultClicked = false;
                    this.actions.update();
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        qualityInspectionFulfilled() {
            if(this.state.isComplete){
                this.actions.queryCheckResult();
            }
            if(this.state.isFailure){
                this.privates.qualityInspectionStarted = false;
                this.setState({
                    modelVisible: false,
                });
            }
        },
        downloadText() {
            let account = this.privates.model.account;
            let dataId = this.state.dataId;

            if (dataId) {
                let url = `/api/demo/download/inspection/${dataId}?email=${account}@udesk.cn`;
                this.setState({
                    iframeUrl: url
                });
            } else {
                Udesk.ui.notify.error(UdeskLocales.current.pages.demo.noTextDownload);
            }
        },

        // hitCheckPointSelected(checkPoint) {
        //     this.setState({
        //         highlightSeqList: [].concat(checkPoint.highlightSeqList),
        //         highlightContent: checkPoint.name
        //     }, () => {
        //         let audioCallInfoDOM = this.privates.audioCallInfoRef.current;
        //         let searchedFirst = audioCallInfoDOM.getElementsByClassName("audio-text-list-item-text-hit")[0];
        //         searchedFirst && searchedFirst.scrollIntoViewIfNeeded && searchedFirst.scrollIntoViewIfNeeded();
        //     });
        // },

        closeHit() {
            this.setState({
                highlightContent: ''
            });
        },

        onTimeRangeChanged(item) {
            let {
                waveAudioComponent
            } = this.privates;
            if (waveAudioComponent && waveAudioComponent.current) {
                waveAudioComponent.current.actions.playSpecificTimePeriod(item.data.fromSec, item.data.endSec);
            }
        },

        markSemantic(content) {
            // this.privates.semanticMarkShow = true;
            this.privates.semanticContent = content;
            this.actions.update();
        },

        markSemanticClose() {
            this.privates.semanticMarkShow = false;
            this.actions.update();
        },

        hitCheckPointSelected(checkPoint) {
            this.privates.keywords = '';
            this.privates.highlightGroupList = [];
            if (checkPoint.type !== Udesk.enums.pointTypes.smartRules.id) {
                this.privates.highlightSeqList = [].concat(checkPoint.highlightSeqList);
                this.privates.highlightContent = checkPoint.name;
                this.actions.update();
                this.forceUpdate(() => {
                    let audioCallInfoDOM = this.privates.audioCallInfoRef.current;
                    let searchedFirst = audioCallInfoDOM.getElementsByClassName('audio-text-list-item-text-hit')[0];
                    searchedFirst && searchedFirst.scrollIntoViewIfNeeded && searchedFirst.scrollIntoViewIfNeeded();
                });
            } else {
                this.privates.highlightGroupList = [].concat(checkPoint.highlightGroupList || []);
                this.privates.highlightSeqList = [];
                this.privates.highlightContent = checkPoint.name;
                this.actions.update();
            }
        },
    };
    //#endregion
}

function getOssAuthorization(account, type) {
    return new Promise((resolve, reject) => {
        Udesk.ajax
            .get(`/api/demo/oss/tokens/${type}?email=${account}@udesk.cn`)
            .then(
                resp => {
                    if (resp && resp.data) {
                        resp.data.isReadyPublic = true;
                    }
                    resolve(resp.data);
                },
                reason => {
                    reject(reason);
                }
            );
    });
}