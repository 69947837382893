export default {
  lang: {
    export: 'Export',
    successfully: 'Úspěšně vytvořený úkol exportovat',
    pleaseJumpTo: 'Please jump',
    uploadAndDownloadCenter: 'Upload a download center',
    check: 'Zobrazit',
    exportAll: 'Potvrdit exportování veškerého obsahu?',
    total: 'Celkem',
    confirm: 'Data, potvrďte export?',
    error: 'Množství exportovatelných dat je 0, prosím změňte kritéria filtru a zkuste to znovu.',
  },
};
