import React, { useState, useEffect, useRef, useCallback, useImperativeHandle } from 'react';
import Udesk from 'Udesk';
import { Button, Icon, Input as AntInput, Radio, Space } from 'udesk-ui';
import { useAudioRecorder } from './hooks/Input';
import styled from 'styled-components';
import { UploadFile } from 'src/util/upload';
import { getReviewIntelligentPartnerTestStart } from 'src/api/review/intelligentPartner/test/start';
import { IntelligentPartnerDialogFlowGlobalConfig, WordsNode } from 'src/api/types';
import { getDateTimeString } from 'src/util/moment';
import { postReviewIntelligentPartnerTest } from 'src/api/review/intelligentPartner/test';
import { postReviewIntelligentPartnerAiTest } from 'src/api/review/intelligentPartner/ai/test';
import UdeskLocales from 'UdeskLocales';

const Wrap = styled.div`
    margin-top: 4px;
    // min-height: 104px;
    .head-toolbar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        align-items: center;
    }
    .wave-wrap {
        height: 40px;
        display: flex;
        .wave {
            flex: 1;
            text-align: center;
            line-height: 40px;
            color: rgba(0, 0, 0, 0.45);
        }
    }

    .reference-words {
        padding: 4px;
        background: rgba(0, 0, 0, 0.03);
        margin-bottom: 4px;
        max-height: 98px;
        overflow: auto;
    }
    .text-area-input-wrap {
        display: flex;
        align-items: end;
        .udesk-qa-ui-input {
            flex: 1;
            margin-right: 8px;
        }
    }
`;
const InternalInput = React.forwardRef<any, any>((props, ref) => {
    const { currentInputType, setCurrentInputType } = props;
    const { input, startRecord, pauseRecord, abortRecord } = useAudioRecorder(props.task);

    const [textAreaValue, setTextAreaValue] = useState('');
    const [isRecording, setRecording] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            setTextAreaValue,
        };
    });

    useEffect(() => {
        const uploadFile = (input) => {
            UploadFile(
                new window.File([input.blob], `${Udesk.utils.string.uuid()}-${input.id}.wav`),
                ({ url }) => {
                    if (input.url) {
                        (window.URL || window.webkitURL).revokeObjectURL(input.url);
                    }
                    input.url = url;
                    input.blob = null; //这里释放blob的数据
                    input.isFileUploadOver = true;
                }
            );
        };

        if (isRecording) {
            const item = createMessage(input, true);
            props.onPreviewChange?.(item);
        } else {
            if (input.isOver && input.isAsrOver) {
                if (!input.text)
                    return Udesk.ui.notify.error(
                        /* 未检测到声音 */ UdeskLocales['current'].pages.coach.learningCenter
                            .nodeConfigTest.components.input.noSoundDetected
                    );
                const item = createMessage(input);
                props.onChange?.(item);
                uploadFile(item);
            }
        }
    }, [input, isRecording]);

    const options = [
        {
            label: /* 语音 */ UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest
                .components.input.voice,
            value: 1,
        },
        {
            label: /* 文本 */ UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest
                .components.input.text,
            value: 2,
        },
    ];

    const isAudioInput = currentInputType === 1;

    useEffect(() => {
        setTextAreaValue(props.referenceWords);
    }, [props]);

    const pause = () => {
        pauseRecord();
        setRecording(false);
    };
    return (
        <Wrap>
            <div className="head-toolbar">
                <Radio.Group
                    options={options}
                    onChange={({ target: { value } }) => {
                        if (isRecording) {
                            pause();
                        }
                        setCurrentInputType(value);
                    }}
                    optionType="button"
                    value={currentInputType}
                    buttonStyle="solid"
                    size="small"
                />
                {props.extra ?? null}
            </div>

            {isAudioInput ? (
                <>
                    <div className="reference-words">{`${
                        /* 参考话术： */ UdeskLocales['current'].pages.coach.learningCenter
                            .nodeConfigTest.components.input.referenceScript
                    }${props.referenceWords}`}</div>
                    <div className="wave-wrap">
                        <div className={'wave'}>
                            {/* 点击右侧开始录音 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest
                                    .components.input.clickOnTheRightSideToStartRecording
                            }
                        </div>
                        <Space>
                            {isRecording ? (
                                [
                                    <Button
                                        // loading={loadingForSendMessage}
                                        type="primary"
                                        danger
                                        size="large"
                                        onClick={() => {
                                            abortRecord();
                                            setRecording(false);
                                            props.onAbort?.();
                                        }}
                                        icon={<Icon type="DeleteOutlined" antdIcon={true} />}
                                    ></Button>,
                                    <Button
                                        // loading={loadingForSendMessage}
                                        type="primary"
                                        size="large"
                                        onClick={pause}
                                        key="primary"
                                        icon={<Icon type="SendOutlined" antdIcon={true} />}
                                    ></Button>,
                                ]
                            ) : (
                                <Button
                                    // loading={loadingForSendMessage}
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                        startRecord();
                                        setRecording(true);
                                    }}
                                    key="primary"
                                    style={{ width: 88 }}
                                    icon={<Icon type="AudioOutlined" antdIcon={true} />}
                                ></Button>
                            )}
                        </Space>
                    </div>
                </>
            ) : (
                <div className="text-area-input-wrap">
                    <AntInput.TextArea
                        autoSize={{ minRows: 3, maxRows: 6 }}
                        value={textAreaValue}
                        onChange={(e) => setTextAreaValue(e.target.value)}
                    />
                    <Button
                        // loading={loadingForSendMessage}
                        type="primary"
                        onClick={() => {
                            const item = createMessage({
                                text: textAreaValue,
                            });
                            props.onChange?.(item);
                        }}
                        key="primary"
                        icon={<Icon type="SendOutlined" antdIcon={true} />}
                    >
                        {/* 发送 */}
                        {
                            UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest
                                .components.input.sendOut
                        }
                    </Button>
                </div>
            )}
        </Wrap>
    );
});

const useChatInput = (flowId, taskDetail, courseType, dialogueSentenceList) => {
    const [initialChatList, setInitialChatList] = useState<WordsNode[]>([]);
    const [referenceWords, setReferenceWords] = useState<string>('');
    const [globalConfig, setGlobalConfig] = useState<IntelligentPartnerDialogFlowGlobalConfig | {}>(
        {}
    );
    const nextRequestStaticParams = useRef<any>({});
    const [curRecordId, setCurRecordId] = useState<number | string>();
    const start = () => {
        getReviewIntelligentPartnerTestStart({
            params: {
                flowId,
                imageId: taskDetail.customerPersona?.id,
            },
        }).then((resp) => {
            const { nextNodeList, globalConfig, referenceWords, recordId } = resp.data ?? {};
            setCurRecordId(recordId);
            setInitialChatList(
                (nextNodeList ?? []).map((item) => {
                    return createMessage({
                        channel: 1,
                        nodeType: item.nodeType,
                        content: item.words,
                        ossKey: item.ossKey,
                        // startSecond: newStartSecond(),
                    });
                })
            );
            nextRequestStaticParams.current =
                Array.isArray(nextNodeList) && nextNodeList.length > 0
                    ? Object.assign({}, nextNodeList[nextNodeList.length - 1], { recordId })
                    : { recordId };
            setReferenceWords(referenceWords ?? '');
            setGlobalConfig(globalConfig ?? {});
        });
    };

    const isAiGenCourse = courseType === Udesk.enums.lessonType.aiGen.id;

    const sendRequest = useCallback(
        (ajaxParams) => {
            const req = isAiGenCourse
                ? postReviewIntelligentPartnerAiTest
                : postReviewIntelligentPartnerTest;

            return req(ajaxParams).then((resp) => {
                const { nextNodeList, referenceWords, traineeWordsResult } = resp.data ?? {};
                nextRequestStaticParams.current = {
                    recordId: nextRequestStaticParams.current.recordId,
                };
                if (Array.isArray(nextNodeList) && nextNodeList.length > 0) {
                    nextRequestStaticParams.current = Object.assign(
                        nextRequestStaticParams.current,
                        nextNodeList[nextNodeList.length - 1]
                    );
                }
                setReferenceWords(referenceWords ?? '');
                return {
                    ajaxParams: { ...ajaxParams, aiDialogContentList: undefined },
                    traineeWordsResult,
                    nextNodeList: Array.isArray(nextNodeList)
                        ? nextNodeList.map((item) =>
                              createMessage({
                                  channel: 1,
                                  nodeType: item.nodeType,
                                  content: item.words,
                                  ossKey: item.ossKey,
                                  // startSecond: newStartSecond(),
                              })
                          )
                        : null,
                };
            });
        },
        [isAiGenCourse]
    );

    const send = (newItem) => {
        const ajaxParams = {
            ...nextRequestStaticParams.current,
            dialogueMode: 2,
            flowId,
            imageId: taskDetail.customerPersona?.id,
            words: newItem.content,
            // ossKey: undefined,
            // pptUrl: undefined,
            // recordId: undefined,
            // speakDuration: undefined,
            // speakTime: undefined,
            // words: undefined,
            aiDialogContentList: dialogueSentenceList,
        };
        return sendRequest(ajaxParams);
    };

    const refresh = (props) => {
        setReferenceWords(props.traineeWordsResult.standardWords);
        nextRequestStaticParams.current = props.ajaxParams;
    };

    return {
        start,
        initialChatList,
        globalConfig,
        referenceWords,
        send,
        refresh,
        recordId: curRecordId,
    };
};

type InputType = typeof InternalInput & {
    useChatInput: typeof useChatInput;
};

const Input: InputType = InternalInput as InputType;
Input.useChatInput = useChatInput;

export { Input };

export function createMessage(props = {} as any, isPreview?) {
    const {
        channel = 2, // 说话频道 1-非用户输入 2-用户输入
        nodeType = 2, // 节点类型 1-旁白 2-学员说话 3-机器人说话 4-知识库问答 5-ppt节点
        content = '', // 说话内容
        ossKey = '', // 如果是语音，语音上传到云服务器地址
        startSecond = Date.now(), // 开始说话的毫秒数
        bindNodeId,
        pptUrl,
        isSync, // 0:没有同步，1：正在同步，2：已同步
        createDate = getDateTimeString(),
        nodeId, // 对应的节点id
        text = '',
        blob,
        url,
    } = props;

    const chatData: any = {
        id: Udesk.utils.string.uuid(),
        channel,
        nodeType,
        content: content || text,
        ossKey,
        startSecond,
        bindNodeId,
        pptUrl,
        isSync,
        createDate,
        nodeId,
        blob,
        duration: 3230,
        url,
        isPreview,
    };

    return chatData;
}
