// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetInspectionPointsByIdQuery,
    GetInspectionPointsByIdParam,
    BaseResponseInspectionPointFoundResponse,
    PutInspectionPointsByIdQuery,
    PutInspectionPointsByIdParam,
    PutInspectionPointsByIdBody,
    BaseResponseVoid,
    DeleteInspectionPointsByIdQuery,
    DeleteInspectionPointsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 质检点
 * @link [GET] /inspectionPoints/{id}
 * @param id
 */
export function getInspectionPointsById(
    options: ApiOptions<GetInspectionPointsByIdParam, GetInspectionPointsByIdQuery> & {
        segments: GetInspectionPointsByIdParam;
    } & { params: GetInspectionPointsByIdQuery }
): Promise<BaseResponseInspectionPointFoundResponse> {
    return request<never, GetInspectionPointsByIdParam, GetInspectionPointsByIdQuery>(
        '/inspectionPoints/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionPointsById' }
    );
}

/**
 * update
 *
 * @export
 * @tags 质检点
 * @link [PUT] /inspectionPoints/{id}
 * @param id
 */
export function putInspectionPointsById(
    data: PutInspectionPointsByIdBody,
    options: ApiOptions<PutInspectionPointsByIdParam, PutInspectionPointsByIdQuery> & {
        segments: PutInspectionPointsByIdParam;
    } & { params: PutInspectionPointsByIdQuery }
): Promise<BaseResponseVoid> {
    return request<
        PutInspectionPointsByIdBody,
        PutInspectionPointsByIdParam,
        PutInspectionPointsByIdQuery
    >('/inspectionPoints/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putInspectionPointsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 质检点
 * @link [DELETE] /inspectionPoints/{id}
 * @param id
 */
export function deleteInspectionPointsById(
    options: ApiOptions<DeleteInspectionPointsByIdParam, DeleteInspectionPointsByIdQuery> & {
        segments: DeleteInspectionPointsByIdParam;
    } & { params: DeleteInspectionPointsByIdQuery }
): Promise<BaseResponseVoid> {
    return request<never, DeleteInspectionPointsByIdParam, DeleteInspectionPointsByIdQuery>(
        '/inspectionPoints/{id}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteInspectionPointsById' }
    );
}

export const meta = [
    { tags: ['质检点'], path: '/inspectionPoints/{id}', method: 'get' },
    { tags: ['质检点'], path: '/inspectionPoints/{id}', method: 'put' },
    { tags: ['质检点'], path: '/inspectionPoints/{id}', method: 'delete' },
];
