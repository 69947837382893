// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostUploadRecordsResultsSearchBody,
    BaseResponseListUploadRecordsResultFoundResponse,
} from '../../types';

/**
 * search
 *
 * @export
 * @tags upload-records-result-controller
 * @link [POST] /uploadRecordsResults/search
 */
export function postUploadRecordsResultsSearch(
    data: PostUploadRecordsResultsSearchBody
): Promise<BaseResponseListUploadRecordsResultFoundResponse>;
export function postUploadRecordsResultsSearch(
    data: PostUploadRecordsResultsSearchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListUploadRecordsResultFoundResponse>;
export function postUploadRecordsResultsSearch(
    data: PostUploadRecordsResultsSearchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListUploadRecordsResultFoundResponse> {
    return request<PostUploadRecordsResultsSearchBody, never, never>(
        '/uploadRecordsResults/search',
        { ...options, data, method: 'post', __$requestCalleeName: 'postUploadRecordsResultsSearch' }
    );
}

export const meta = [
    {
        tags: ['upload-records-result-controller'],
        path: '/uploadRecordsResults/search',
        method: 'post',
    },
];
