import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
import _cloneDeep from 'lodash-es/cloneDeep';

class InspectionDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        id: null,
        taskId: null,
        hasFeature: null,
        detail: {},
        smartTags: [],
        changeList: [],
        qualityCheckScoreSaving: false,
        qualityCheckScoreType: null,
        keywords: '',
        showQualityCheckScoreTalk: true,
    };
    //#endregion

    state = {};
    privates = {
        id: null,
        taskId: null,
        detail: {},
        smartTags: [],
        changeList: [],
        waveAudioOptions: {
            height: '44',
        },
        semanticMarkShow: false,
        semanticContent: '',
        highlightSeqList: [],
        highlightContent: '',
        audioCallInfoRef: React.createRef(),
        waveAudioInstance: null,
        caseBaseFlag: false,
        qcsRef: React.createRef(),
        labelRef: React.createRef(),
        tagId: null,
        treeValue: null,
        idState: null,
        labelLevelModal: null,
        labelParent: {},
        labelParentModal: {},
        hitData: {},
        treeDataRef: {},
        dropChed: false,
        MarkFlag: false,
        heightList: [],
        handMarkMap: {},
        checkedMap: null,
        basicInfoRef: React.createRef(),
        pointRef: React.createRef(),
        detailRef: React.createRef(),
        pointWidth: '100%',
        timeValue: null,
        waveAduioAction: false,
        spinLoading: false,
        talkAudioFlag: false,
        canRemarkEdit: false,
        showBaseInfo: true,
        autoStepToNext: true,
        currentHighLightPointOrSmartKey: null,
        remark: '',
        hadRequestRelatedRecords: false,
        audioCallContentInfoRef: React.createRef(),
        remarkRef: React.createRef(),
        allPoints: [],
        pointRemarksMap: {},
        isProblemExtraction: false,
        // isRemarkLoading: true,
    };
    static computes = {
        _inspectDataSource: [
            'props.detail',
            function ({ props, privates, locales }) {
                let { detail } = props;
                if (
                    detail &&
                    (detail.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                        detail.inspectDataSource ===
                            Udesk.enums.inspectDataSources.realTimeVoiceCall.id)
                ) {
                    return Udesk.enums.inspectDataSources.voiceCall.id;
                } else if (
                    detail &&
                    (detail.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
                        detail.inspectDataSource ===
                            Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
                ) {
                    return Udesk.enums.inspectDataSources.textDialogue.id;
                } else if (
                    detail &&
                    detail.inspectDataSource === Udesk.enums.inspectDataSources.wechatDialogue.id
                ) {
                    return Udesk.enums.inspectDataSources.wechatDialogue.id;
                } else {
                    return detail.inspectDataSource;
                }
            },
        ],
    };

    actions = {
        onChangeActiveTab(isProblemExtraction) {
            this.privates.isProblemExtraction = isProblemExtraction;
            this.actions.update();
        },
        scrollTo(index) {
            this.privates.audioCallContentInfoRef?.current?.scrollTo({
                index,
                align: 'top',
            });
        },
        changeTaskRemark(richValue, textValue, remarkMap) {
            let { detail } = this.privates;
            // const textValue = this.privates.remarkRef.current?.text??'';

            detail.remark = textValue;
            detail.richRemark = richValue;
            detail.remarkChanged = true;
            this.privates.remark = richValue;
            let { qualityCheckScoreType } = this.props;

            this.privates.pointRemarksMap = remarkMap ?? {};
            this.actions.qualityCheckScoreSaved(
                this.privates.detail.pointCategoryList || [],
                this.privates.detail,
                qualityCheckScoreType
            );
            this.actions.update();
        },
        sendCurrentTimeRange(item) {
            let { waveAudioInstance } = this.privates;
            if (waveAudioInstance) {
                waveAudioInstance.actions.playSpecificTimePeriod(item.fromSec, item.endSec);
            }
            this.privates.autoStepToNext = false;
            this.privates.timeValue = 'null';
        },
        onAudioPause(value) {
            let { waveAudioInstance } = this.privates;
            if (waveAudioInstance) {
                if (value) {
                    waveAudioInstance.actions.play();
                } else {
                    waveAudioInstance.actions.pause('1');
                }
            }
        },
        onAudioStop() {
            this.privates.talkAudioFlag = true;
            this.actions.update();
        },
        onAudioPlay(flag) {
            this.privates.talkAudioFlag = false;
            this.actions.update();
        },
        onWaveAudio(value, flag) {
            this.privates.waveAudio = value;
            this.privates.waveAduioAction = flag;
            this.actions.update();
        },
        markSemantic(content) {
            // this.privates.semanticMarkShow = true;
            this.privates.semanticContent = content;
            this.props?.SmartToolBarRef?.current?.actions?.getKeyword?.(content);
            this.actions.update();
        },
        markSemanticClose() {
            this.privates.semanticMarkShow = false;
            this.actions.update();
        },
        hitCheckPointSelected(checkPoint) {
            this.privates.keywords = '';
            this.privates.highlightGroupList = [];
            this.privates.highlightSeqList = [];
            //跳转到质检点第一个高亮callId对应的通话详情
            if (
                Array.isArray(checkPoint.highlightGroupList) &&
                checkPoint.highlightGroupList.length >= 1
            ) {
                this.props?.changeToHighlightItemsCallDetail?.(
                    checkPoint.highlightGroupList[0].callId
                );
            }
            if (checkPoint.type !== Udesk.enums.pointTypes.smartRules.id) {
                this.privates.highlightSeqList = [].concat(checkPoint.highlightSeqList);
                this.privates.highlightContent = checkPoint.name;
                this.actions.update();
                this.forceUpdate(() => {
                    let audioCallInfoDOM = this.privates.audioCallInfoRef.current;
                    let searchedFirst = audioCallInfoDOM.getElementsByClassName(
                        'audio-text-list-item-text-hit'
                    )[0];
                    searchedFirst &&
                        searchedFirst.scrollIntoViewIfNeeded &&
                        searchedFirst.scrollIntoViewIfNeeded();
                });
            } else {
                this.privates.highlightGroupList = [].concat(checkPoint.highlightGroupList || []);
                this.privates.highlightSeqList = [];
                this.privates.highlightContent = checkPoint.name;
                this.actions.update();
            }
        },
        triggerHighLight(checkPoint) {
            //跳转到质检点第一个高亮callId对应的通话详情
            if (
                Array.isArray(checkPoint.highlightGroupList) &&
                checkPoint.highlightGroupList.length >= 1
            ) {
                this.props?.changeToHighlightItemsCallDetail?.(
                    checkPoint.highlightGroupList[0].callId
                );
            }
            new Promise((resolve) => {
                this.privates.currentHighLightPointOrSmartKey = null;
                this.actions.update();
                resolve();
            }).then(() => {
                this.privates.currentHighLightPointOrSmartKey = `${checkPoint.parentId}-${checkPoint.pointId}`;
                this.actions.update();
            });
        },
        closeHighLight() {
            this.privates.currentHighLightPointOrSmartKey = null;
            this.actions.update();
        },
        closeHit() {
            this.privates.highlightContent = '';
            this.actions.update();
        },
        qualityCheckScoreCanceled() {
            this.trigger('onQualityCheckScoreCancel');
        },
        qualityCheckScoreSaved(pointCategoryList, task, type) {
            this.trigger('onQualityCheckScoreSave', pointCategoryList, task, type);
        },
        qualityCheckScoreSubmitted(pointCategoryList, task) {
            this.trigger('onQualityCheckScoreSubmit', pointCategoryList, task);
        },
        qualityCheckScoreRapidSampling(pointCategoryList, task, type) {
            this.trigger('onQualityCheckScoreRapidSampling', pointCategoryList, task, type);
        },
        onUpdate() {
            this.trigger('onUpdate');
        },
        caseBaseUnRemrks(value, flag) {
            if (flag) {
                this.privates.caseBaseRemark = value || ' ';
                this.privates.caseBaseFlag = flag;
            } else {
                this.privates.caseBaseFlag = flag;
                this.privates.caseBaseRemark = '';
            }
            this.privates.remark = this.privates.caseBaseRemark;
            this.actions.update();
        },
        parentLoadMore(value) {
            this.trigger('parentLoadMore', value);
        },
        getTagTreeInit(categoryId, type, tagId) {
            let { sdkOptions } = this.props;
            let data = {
                categoryId,
                treeType: type,
            };
            if (tagId) {
                data.tagId = tagId;
            }
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/tree`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        resolve(resp.data);
                        if (type === 1) {
                            if (resp.data && resp.data.length > 0) {
                                let level = resp.data[0].tagLevel;
                                if (!_isEmpty(this.privates.treeDataList)) {
                                    let obj = _find(
                                        this.privates.treeDataList,
                                        (item, key) => key === level
                                    );
                                    if (obj) {
                                        this.privates.treeDataList[level] = resp.data;
                                    } else {
                                        this.privates.treeDataList = {
                                            ...this.privates.treeDataList,
                                            [level]: resp.data,
                                        };
                                    }
                                    let data = {};
                                    for (let key in this.privates.treeDataList) {
                                        if (key <= level) {
                                            data[key] = this.privates.treeDataList[key];
                                        }
                                    }
                                    this.privates.treeDataList = data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] =
                                            React.createRef();
                                    }
                                } else {
                                    this.privates.treeDataList[level] = resp.data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] =
                                            React.createRef();
                                    }
                                }
                                this.actions.update();
                            } else {
                                if (this.privates.labelLevelModal) {
                                    this.privates.treeDataList = {
                                        ...this.privates.treeDataList,
                                        [this.privates.labelLevelModal + 1]: [],
                                    };
                                    let data = {};
                                    for (let key in this.privates.treeDataList) {
                                        if (key <= this.privates.labelLevelModal + 1) {
                                            data[key] = this.privates.treeDataList[key];
                                        }
                                    }
                                    this.privates.treeDataList = data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] =
                                            React.createRef();
                                    }
                                }
                                this.actions.update();
                            }
                        } else if (type === 2) {
                            this.privates.treeDataList = resp.data;
                            for (let key in this.privates.treeDataList) {
                                this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                            }
                            this.actions.treeMap(tagId, this.privates.treeDataList, -1);
                            this.actions.hitMap(
                                tagId,
                                this.privates.treeDataList,
                                this.privates.hitData,
                                -1
                            );
                            this.privates.tagId = tagId;
                            this.privates.labelActiveModal = tagId;
                            this.actions.update();
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        treeMap(id, treeDataListObj, level) {
            for (let key in treeDataListObj) {
                if (parseInt(key, 10) === level || level === -1) {
                    if (treeDataListObj[key] && treeDataListObj[key].length > 0) {
                        let obj = treeDataListObj[key].find((item) => item.id === id);
                        if (obj && obj.parentId) {
                            treeDataListObj[key] = treeDataListObj[key].filter(
                                (item) => item.parentId === obj.parentId
                            );
                            this.actions.treeMap(obj.parentId, treeDataListObj, obj.tagLevel - 1);
                            break;
                        }
                    }
                }
            }
        },
        treeDrag(value, obj, e) {
            var x1 =
                e.pageX ||
                e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
            if (value.current) {
                let width = value.current.style.width;
                let wid = parseInt(width.split('px')[0], 10);
                obj.current.onmousemove = (e) => {
                    var x2 =
                        e.pageX ||
                        e.clientX +
                            (document.body.scrollLeft || document.documentElement.scrollLeft);
                    if (x2 >= x1 && x2 - x1 <= 500) {
                        value.current.style.width = x2 - x1 + wid + 'px';
                    } else {
                        if (x2 - x1 < 0) {
                            value.current.style.width = wid - (x1 - x2) + 'px';
                        }
                    }
                };
            }
        },
        fieldUp(obj) {
            obj.current.onmousemove = (e) => {
                return;
            };
        },
        hitMap(id, treeDataListObj, hitList, level) {
            for (let key in treeDataListObj) {
                if (parseInt(key, 10) === level || level === -1) {
                    if (treeDataListObj[key] && treeDataListObj[key].length > 0) {
                        treeDataListObj[key].forEach((item) => {
                            if (item.id === id) {
                                hitList[item.tagLevel] = id;
                                if (item.parentId) {
                                    this.actions.hitMap(
                                        item.parentId,
                                        treeDataListObj,
                                        hitList,
                                        item.tagLevel - 1
                                    );
                                }
                            }
                        });
                    }
                }
            }
        },
        activesLabelModal(value, level, leveflag) {
            let { treeDataList } = this.privates;
            this.privates.labelActiveModal = value;
            this.privates.tagIdModal = value;
            this.privates.labelLevelModal = level;
            this.privates.labelParentModal[level] = value;
            for (let key in this.privates.labelParentModal) {
                if (key > level) {
                    this.privates.labelParentModal[key] = null;
                }
            }
            for (let key in this.privates.hitData) {
                if (key >= level) {
                    this.privates.hitData[key] = null;
                }
            }
            if (leveflag !== 1) {
                this.actions.getTagTreeInit(this.privates.treeValue.categoryId, 1, value, true);
            } else {
                for (let key in treeDataList) {
                    if (key > level) {
                        this.privates.treeDataList[key] = null;
                    }
                }
            }
            this.actions.update();
        },
        treeListVisible(value) {
            this.privates.keywords = '';
            this.privates.highlightGroupList = [];
            if (value) {
                if (value.tagType === 2) {
                    this.privates.treeValue = value;
                    this.privates.labelParentModal = {};
                    this.actions.getTagTreeInit(value.categoryId, 2, value.id);
                    this.privates.visible = true;
                    this.actions.update();
                } else {
                    this.privates.highlightGroupList = [].concat(value.highlightGroups || []);
                    this.privates.currentHighLightPointOrSmartKey = `smartTags-smartTags${value.id}`;
                    this.actions.update();
                }
            }
        },
        onSmartTagsChange(smartTags) {
            this.props.querryChangeList && this.props.querryChangeList.call(null, smartTags);
            this.props?.querrySmartTags?.();
        },
        modalCancel() {
            this.privates.visible = false;
            this.actions.update();
        },
        modalOk() {
            let { treeValue, labelActiveModal, detail } = this.privates;
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `smartTagTrainRecordDetails`,
                sdkOptions
            );
            let data = {
                tagId: labelActiveModal,
                callId: detail.callId,
                categoryId: treeValue.categoryId,
                trainType: treeValue.tagType,
                dataSource: detail.inspectDataSource,
            };
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.visible = false;
                        let smartTagsUrl = Udesk.business.apiPath.concatApiPath(
                            `callTags/${detail.id}?taskId=${this.props.taskId}`,
                            sdkOptions
                        );
                        new Promise((resolve, reject) => {
                            Udesk.ajax.get(smartTagsUrl).then(
                                (resp) => {
                                    resolve(resp);
                                    if (resp.data) {
                                        this.privates.smartTags = resp.data || [];
                                    }
                                    this.actions.update();
                                },
                                (reason) => {
                                    Udesk.ui.notify.error(
                                        reason.errorMsg ||
                                            this.locales.components.pages.components.recordDetail
                                                .getSmartTagsFailed
                                    );
                                    reject(reason);
                                }
                            );
                        });
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        dropChecked(value) {
            this.privates.dropChed = value;
            this.actions.update();
        },
        heightHit(markFlag, value) {
            this.privates.heightList = value;
            this.privates.MarkFlag = markFlag;
            this.actions.update();
        },
        handMarkMap(taMap, list) {
            this.props.handMarkMap && this.props.handMarkMap(taMap);
            this.privates.pointChildrenPerson = list;
            this.actions.update();
        },
        checkedProps(data) {
            this.privates.checkedMap = data;
            this.actions.update();
        },
        resizeWidthChange(flag) {
            if (flag) {
                this.privates.audioCallInfoRef.current.style.width = `calc(100% - 964px)`;
                if (document.body.clientWidth <= 1800) {
                    this.privates.pointRef.current.style.width = '312px';
                    this.privates.audioCallInfoRef.current.style.width = `calc(100% - 652px)`;
                }
                this.privates.basicInfoRef.current.style.width = '340px';
                this.privates.pointWidth = '100%';
            } else {
                this.privates.basicInfoRef.current.style.width = '48px';
                this.privates.audioCallInfoRef.current.style.width = `calc(100% - 672px)`;
                this.privates.pointRef.current.style.width = '624px';
                this.privates.pointWidth = '50%';
            }
            this.actions.update();
        },
        changeBaseInfoOnOff() {
            this.privates.showBaseInfo = !this.privates.showBaseInfo;
            this.actions.update();
        },
        changeBaseInfoOn() {
            this.privates.showBaseInfo = true;
            this.actions.update();
        },
        changeBaseInfoOff() {
            this.privates.showBaseInfo = false;
            this.actions.update();
        },
        evaluateChange(value) {
            this.trigger('evaluateChange', value);
        },
        timeChaged(value) {
            this.privates.timeValue = value;
            this.actions.update();
        },
        reverse() {
            const { sdkOptions } = this.props;
            const { detail } = this.privates;
            if (!detail.id) {
                return;
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionData/${detail.id}/role-reverse`,
                sdkOptions
            );
            Udesk.ajax.post(url).then(
                (resp) => {
                    Udesk.ui.notify.success(this.locales.business.info.editSuccess);
                    setTimeout(() => {
                        this.props?.refresh?.();
                    }, 200);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        recover() {
            const { sdkOptions } = this.props;
            const { detail } = this.privates;
            if (!detail.callId) {
                return;
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `/data/${detail.callId}/recover`,
                sdkOptions
            );
            Udesk.ajax.post(url).then(
                (resp) => {
                    Udesk.ui.notify.success(this.locales.business.info.editSuccess);
                    setTimeout(() => {
                        this.props?.refresh?.();
                    }, 200);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        onManualStartOrStop() {
            this.privates.autoStepToNext = true;
            this.actions.update();
        },
        onScrollToRelatedRecords(inView, entry) {
            const tryRequest = () => {
                requestIdleCallback(() => {
                    const { detail } = this.privates;
                    if (!detail.callId) {
                        tryRequest();
                        return;
                    }
                    this.trigger('requestRelatedRecords');
                    if (inView) {
                        this.privates.hadRequestRelatedRecords = true;
                    }
                });
            };

            if (inView && !this.privates.hadRequestRelatedRecords) {
                tryRequest();
            }
        },
        onAudioRateChange(newAudioRate) {
            this.privates.audioRate = newAudioRate;
            this.actions.update();
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps, isInitial }) {
        let ret = {
            id: props.id,
            taskId: props.taskId,
            // detail: props.detail,
            // changeList: props.changeList,
            // highlightSeqList: [],
            highlightContent: '',
            keywords: props.keywords,
            handMarkMap: props.handMarkMap,
            pointChildrenPerson: props.pointChildrenPerson,
            pointChildrenComputer: props.pointChildrenComputer,
            resetStatus: props.resetStatus,
        };
        if (props.smartTags !== prevProps.smartTags) {
            ret.smartTags = _cloneDeep(props.smartTags);
        }
        if (props.changeList !== prevProps.changeList) {
            ret.changeList = props.changeList;
        }
        if (props.detail !== prevProps.detail || isInitial) {
            // let allHighlightPoints = [];
            ret.hadRequestRelatedRecords = false;
            let allPoints = [];
            //遍历一级分类
            (props.detail?.pointCategoryList ?? []).forEach((pointCategory) => {
                //遍历二级分类
                if (Array.isArray(pointCategory.children) && !this.props.isInformationCollect) {
                    pointCategory.children.forEach((secondCategory) => {
                        if (Array.isArray(secondCategory.pointDataList)) {
                            secondCategory.pointDataList.forEach((i) => {
                                i.parentId = secondCategory.id;
                            });
                        }
                    });
                }
            });
            const isHit = (point) =>
                point.gradeChooseHit === 1 ||
                (Boolean(point.gradeChooseOption) && parseFloat(point.gradeChooseOption) !== 0);
            if (props.detail?.pointCategoryList && props.detail?.pointCategoryList.length > 0) {
                props.detail.pointCategoryList.forEach((item) => {
                    if (item.children) {
                        item.children.forEach((items) => {
                            if (items.pointDataList) {
                                items.pointDataList.forEach((itemss) => {
                                    itemss._isHit = isHit(itemss);
                                    allPoints.push(itemss);
                                });
                                items._isHit = items.pointDataList.some((i) => i._isHit);
                            }
                        });
                        item._isHit = item.children.some((i) => i._isHit);
                    }
                });
            }
            if (!props.detail.richRemark && props.detail.remark) {
                props.detail.richRemark = props.detail.remark
                    .split('\n')
                    .map((i) => `<p>${i}</p>`)
                    .join('');
            }
            ret.detail = props.detail;
            ret.allPoints = allPoints;
        }
        if (prevProps.id !== props.id) {
            ret.currentHighLightPointOrSmartKey = null;
        }
        // if((props.detail?.id && props.detail?.id !== prevProps.detail?.id) || props.detail?.callId !== prevProps.detail?.callId){
        //     // this.privates.pointRemarksMap = this.privates.remarkRef.current?.remarkMap??{};
        //     ret.isRemarkLoading = true;
        // }
        // if(props.loading){
        //     ret.isRemarkLoading = true;
        // }
        return ret;
    }
    componentDidMount() {
        if (document.body.clientWidth > 1800) {
            if (this.privates.pointRef.current) {
                this.privates.pointRef.current.style.width = '624px';
                this.privates.audioCallInfoRef.current.style.width = `calc(100% - 964px)`;
            }
        }
        this.privates.canRemarkEdit = window.location.pathname.includes(
            'workbench/quality-inspection-list/detail'
        )
            ? Udesk.enums.inspectionRemarksType.inspection.id
            : window.location.pathname.includes('workbench/review-list/detail')
            ? Udesk.enums.inspectionRemarksType.review.id
            : window.location.pathname.includes('workbench/spot-check-list/detail')
            ? Udesk.enums.inspectionRemarksType.check.id
            : false;

        this.actions.update();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.id !== prevProps.id) {
            this.privates.heightList = [];
            this.privates.MarkFlag = false;
            let { waveAudioInstance } = this.privates;
            if (waveAudioInstance) {
                this.privates.waveAudio = false;
                waveAudioInstance.actions.pause();
            }
            this.actions.update();
        }
        if (this.props.resetStatus !== prevProps.resetStatus) {
            this.privates.heightList = [];
            this.privates.MarkFlag = false;
            this.privates.semanticContent = null;
            let { waveAudioInstance } = this.privates;
            if (waveAudioInstance) {
                this.privates.waveAudio = false;
                this.privates.timeValue = 'null';
                waveAudioInstance.actions.pause();
            }
            this.actions.update();
        }
        // if(this.props.detail?.id && this.props.detail?.id !== prevProps.detail?.id){
        //     // this.privates.pointRemarksMap = this.privates.remarkRef.current?.remarkMap??{};
        //     this.privates.isRemarkLoading = false;
        //     this.actions.update();
        // }
    }
    componentWillUnmount() {
        let { waveAudioInstance } = this.privates;
        if (waveAudioInstance) {
            waveAudioInstance.actions.pause();
            this.privates.waveAudioInstance = null;
        }
    }
    //#endregion
}

export default InspectionDetailComponent;
