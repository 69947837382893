// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerNodePromptConfigQuery,
    BaseResponseIntelligentPartnerDialogNodePromptConfigFoundResponse,
    PostReviewIntelligentPartnerNodePromptConfigBody,
} from '../../../../types';

/**
 * 根据nodeId查询提示语配置信息
 *
 * @export
 * @tags 智能陪练-AI对话提示语节点配置
 * @link [GET] /review/intelligentPartner/node/promptConfig
 */
export function getReviewIntelligentPartnerNodePromptConfig(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodePromptConfigQuery> & {
        params: GetReviewIntelligentPartnerNodePromptConfigQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodePromptConfigFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerNodePromptConfigQuery>(
        '/review/intelligentPartner/node/promptConfig',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodePromptConfig',
        }
    );
}

/**
 * save
 *
 * @export
 * @tags 智能陪练-AI对话提示语节点配置
 * @link [POST] /review/intelligentPartner/node/promptConfig
 */
export function postReviewIntelligentPartnerNodePromptConfig(
    data: PostReviewIntelligentPartnerNodePromptConfigBody
): Promise<BaseResponseIntelligentPartnerDialogNodePromptConfigFoundResponse>;
export function postReviewIntelligentPartnerNodePromptConfig(
    data: PostReviewIntelligentPartnerNodePromptConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodePromptConfigFoundResponse>;
export function postReviewIntelligentPartnerNodePromptConfig(
    data: PostReviewIntelligentPartnerNodePromptConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodePromptConfigFoundResponse> {
    return request<PostReviewIntelligentPartnerNodePromptConfigBody, never, never>(
        '/review/intelligentPartner/node/promptConfig',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodePromptConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-AI对话提示语节点配置'],
        path: '/review/intelligentPartner/node/promptConfig',
        method: 'get',
    },
    {
        tags: ['智能陪练-AI对话提示语节点配置'],
        path: '/review/intelligentPartner/node/promptConfig',
        method: 'post',
    },
];
