// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    BaseResponseListIntelligentPartnerTagGroupFoundResponse,
    PostIntelligentPartnerTagGroupsBody,
    BaseResponseIntelligentPartnerTagGroupFoundResponse,
} from '../types';

/**
 * 查询客户标签组列表
 *
 * @export
 * @tags 智能陪练-客户标签组
 * @link [GET] /intelligentPartnerTagGroups
 */
export function getIntelligentPartnerTagGroups(): Promise<BaseResponseListIntelligentPartnerTagGroupFoundResponse>;
export function getIntelligentPartnerTagGroups(
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerTagGroupFoundResponse>;
export function getIntelligentPartnerTagGroups(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerTagGroupFoundResponse> {
    return request<never, never, never>('/intelligentPartnerTagGroups', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerTagGroups',
    });
}

/**
 * 新建客户标签组
 *
 * @export
 * @tags 智能陪练-客户标签组
 * @link [POST] /intelligentPartnerTagGroups
 */
export function postIntelligentPartnerTagGroups(
    data: PostIntelligentPartnerTagGroupsBody
): Promise<BaseResponseIntelligentPartnerTagGroupFoundResponse>;
export function postIntelligentPartnerTagGroups(
    data: PostIntelligentPartnerTagGroupsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerTagGroupFoundResponse>;
export function postIntelligentPartnerTagGroups(
    data: PostIntelligentPartnerTagGroupsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerTagGroupFoundResponse> {
    return request<PostIntelligentPartnerTagGroupsBody, never, never>(
        '/intelligentPartnerTagGroups',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerTagGroups',
        }
    );
}

export const meta = [
    { tags: ['智能陪练-客户标签组'], path: '/intelligentPartnerTagGroups', method: 'get' },
    { tags: ['智能陪练-客户标签组'], path: '/intelligentPartnerTagGroups', method: 'post' },
];
