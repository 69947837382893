import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import './style.scss';
import { Button, Form, InputNumber, Select, Space } from 'udesk-ui';
import styled from 'styled-components';
import { postConceptWordsFindAll } from 'src/api/conceptWords/findAll';
import { getIntelligentPartnerScoringSessionDetection } from 'src/api/intelligentPartner/scoring/session/detection';
import { putIntelligentPartnerScoringSessionDetectionById } from 'src/api/intelligentPartner/scoring/session/detection/{id}';
import UdeskLocales from 'UdeskLocales';

const Div = styled.div`
    /* margin-bottom: 16px; */
    .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    .desc {
        color: rgba(0, 0, 0, 0.45);
        margin-bottom: 8px;
    }
`;

const Wrap = (props) => {
    return (
        <Div>
            <div className="title">{props.title}</div>
            <div className="desc">{props.desc}</div>
            {props.children}
        </Div>
    );
};
const ConceptWordSelect = React.memo<any>((props) => {
    const { value = [], onChange, width = 200, options, ...others } = props;

    return (
        <Select
            placeholder={/* 请选择概念词 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.pleaseSelectAConceptWord}
            value={value || []}
            onChange={onChange}
            style={{ width: width }}
            options={options}
            mode="multiple"
            maxTagCount="responsive"
            {...others}
        />
    );
});

const Template = React.memo((props: any) => {
    const [conceptWords, setConceptWords] = useState<any[]>([]);
    const [id, setId] = useState<any>(null);

    const [form] = Form.useForm();
    useEffect(() => {
        postConceptWordsFindAll({}).then((resp) => {
            setConceptWords((resp.data ?? []).map((i) => ({ label: i.name, value: i.id })));
        });
        getIntelligentPartnerScoringSessionDetection().then((resp) => {
            console.log(resp.data);
            form.setFieldsValue(resp.data);
            setId(resp.data?.id);
        });
    }, []);
    return (
        <Page
            pageBodyClassName="udesk-coach-score-dimension-index"
            title={/* 会话检测设置 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.sessionDetectionSettings}
            padding={true}
        >
            <Form
                onFinish={(values) => {
                    console.log(values);
                    console.log(id);
                    putIntelligentPartnerScoringSessionDetectionById(
                        {
                            id,
                            ...values,
                        },
                        {
                            successMsg: /* 保存成功 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.successfullySaved,
                            segments: {
                                id,
                            },
                        }
                    );
                }}
                form={form}
            >
                <Form.Item>
                    <Wrap
                        title={/* 语速异常 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.abnormalSpeechSpeed}
                        desc={/* 支持自定义语速检测标准，结果会判断为语速正常、语速过快、语速过慢。当出现语速异常（过快/过慢），会按评分规则进行减分 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules}
                    >
                        <Form.Item>
                            <Space wrap>
                                <div>{/* 语速过快：每分钟大于 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.speechSpeedTooFastGreaterThanEveryMinute}</div>
                                <Form.Item
                                    name={['speakSpeedConfig', 'gtNum']}
                                    style={{ marginBottom: 0 }}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <div>{/* 个字，语速过慢: 每分钟小于 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.oneWordSpeakingTooSlowlyLessThanPerMinute}</div>
                                <Form.Item
                                    name={['speakSpeedConfig', 'ltNum']}
                                    style={{ marginBottom: 0 }}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <div>{/* 个字，忽略小于 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.charactersIgnoringLessThan}</div>
                                <Form.Item
                                    name={['speakSpeedConfig', 'ignoreNum']}
                                    style={{ marginBottom: 0 }}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <div>{/* 字的句子 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.aSentenceOfWords}</div>
                            </Space>
                        </Form.Item>
                    </Wrap>
                </Form.Item>
                <Form.Item>
                    <Wrap
                        title={/* 语气词过多 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.excessiveModalParticles}
                        desc={/* 支持自定义语气词，并设定语气词过多规则。当出现语气词过多时，会按评分规则进行减分 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules}
                    >
                        <Form.Item>
                            <Space wrap>
                                <Form.Item
                                    name={['modalParticleTooMuchConfig', 'conceptWordsIdList']}
                                    style={{ marginBottom: 0 }}
                                >
                                    <ConceptWordSelect options={conceptWords} />
                                </Form.Item>
                                <div>{/* 重复次数为 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.theNumberOfRepetitionsIs}</div>
                                <Form.Item
                                    name={['modalParticleTooMuchConfig', 'repeatNum']}
                                    style={{ marginBottom: 0 }}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <div>{/* 次时，判定为语气词过多 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles}</div>
                            </Space>
                        </Form.Item>
                    </Wrap>
                </Form.Item>
                <Form.Item>
                    <Wrap
                        title={/* 敏感违规定义 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.definitionOfSensitiveViolations}
                        desc={/* 支持自定义违规词词库，检测一句话中是否出现违规词。当出现违规词时，会按评分规则进行减分 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules}
                    >
                        <Form.Item>
                            <Space wrap>
                                <div>{/* 违规词： */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.violationWords}</div>
                                <Form.Item
                                    name={['wordsViolationConfig', 'conceptWordsIdList']}
                                    style={{ marginBottom: 0 }}
                                >
                                    <ConceptWordSelect options={conceptWords} />
                                </Form.Item>
                            </Space>
                        </Form.Item>
                    </Wrap>
                </Form.Item>
                <Form.Item>
                    <Wrap
                        title={/* 情绪检测 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.emotionalDetection}
                        desc={/* 按系统情绪模型对话术进行情绪检测，结果会判断为情绪正常、情绪负向 (包括负面、生气、抱怨)。当出现情绪负向时，会按评分规则进行减分 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules}
                    ></Wrap>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">{/* 保存 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.dimensionIndex.index.preserve}</Button>
                </Form.Item>
            </Form>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
