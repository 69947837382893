import React, { useState } from 'react';
import { Button, Select, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const reInspectTypes = [
    { label: /* 关键事件 */UdeskLocales['current'].components.gong.reInspect.index.keyEvents, value: 1 },
    { label: /* 监督项 */UdeskLocales['current'].components.gong.reInspect.index.supervisionItem, value: 2 },
    { label: /* 会话场景 */UdeskLocales['current'].components.gong.reInspect.index.conversationScenario, value: 3 },
    { label: /* 客户标签 */UdeskLocales['current'].components.gong.reInspect.index.customerLabel, value: 4 },
];

interface ReInspectProps {
    onReInspect?: (checkRuleList: number[]) => void;
}

export const ReInspect = React.memo((props: ReInspectProps) => {
    const { onReInspect } = props;
    const [checkRuleList, setCheckRuleList] = useState([]);
    return (
        <div id="__reInspect" style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
            <Space>
                <Select
                    options={reInspectTypes}
                    mode="multiple"
                    value={checkRuleList}
                    onChange={setCheckRuleList}
                    style={{
                        minWidth: 150,
                    }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        onReInspect?.(checkRuleList);
                    }}
                >{/* 重新检测规则 */}{UdeskLocales['current'].components.gong.reInspect.index.reDetectionRules}</Button>
            </Space>
        </div>
    );
});
