import React from 'react';
import AdminUserGroupsViewRoute from './route';
import { PageHeader } from 'udesk-ui';

export default class AdminUserGroupsViewTemplate extends AdminUserGroupsViewRoute {
    render() {
        let { actions, locales, privates } = this;
        return (
            // <div className="sub-page admin-user-groups-view">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={privates.model.userGroup.name}
                    onBack={actions.userGroupsHistoryBack}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root admin-user-groups-view' style={{ padding: 16 }}>
                        <div className="page-content">
                            <div className="x_panel clearfix custom-object-list">
                                <div className="x_content row clearfix">
                                    <div className="col-lg-8 col-md-8 col-xs-12 col-12">
                                        <form className="form-horizontal form-label-left" onSubmit="return false;">
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.userGroups.name}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p style={{ minHeight: '30px', lineHeight: '30px' }} className="form-control-static">{privates.model.userGroup.name}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                    {locales.columns.admin.userGroups.member}
                                                </label>
                                                <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                    <p className="form-control-static">
                                                        <For each="user" index="index" of={privates.model.userGroup.userList}>
                                                            <span style={{ marginRight: '5px' }}>{user.realname}</span>
                                                        </For>
                                                    </p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
