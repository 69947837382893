import Udesk from 'Udesk';
import React, {useRef} from 'react';
import XssComponent from 'udesk-react/src/components/xss';
import { Popover, Icon, Card, Tooltip } from 'udesk-ui';
import ReactTinymce from 'udesk-react/src/components/react-tinymce';
import ImageViewer from 'udesk-react/src/components/image-viewer';
import SafeHtml from '../../../../common/safe-html';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClassNames from 'classnames';
import _isEmpty from 'lodash-es/isEmpty';
import Locales from 'UdeskLocales';
import { replaceLineBreak, replaceLink } from 'src/util/text-replace';


const { Meta } = Card;

export const MessageComponent = (props) => {
    const vedioDom = useRef(null);
    let { type, item, fieldFlag, sdkOptions, actions, allData, content, callFields, copied, locales, inputStatus } = props;
    return (
        <Choose>
            <When condition={type === 'message'}>
                {fieldFlag ? (
                    <div>
                        {inputStatus ? (
                            <XssComponent
                                classNames={ClassNames(
                                    '',
                                    {
                                        'audio-text-list-item-text-hit-status':
                                            item.states.isHitStatus && !item.states.isHit,
                                    },
                                    { 'audio-text-list-item-text-hit': item.states.isHit }
                                )}
                                value={Udesk.business.emoji.replaceWechatEmotions(replaceLineBreak(replaceLink(callFields)))}
                            />
                        ) : (
                            <ReactTinymce
                                value={Udesk.business.emoji.replaceWechatEmotions(callFields)}
                                onEditorChange={actions.onEditorChange}
                                tinymcePath={`${Udesk.business.apiPath.getStaticBasePath(
                                    sdkOptions.props.domain,
                                    sdkOptions.props.pathPrefix
                                )}/vendor/tinymce/tinymce.min.js`}
                            />
                        )}
                        {allData && allData.data && allData.data.voice_url && (
                            <Icon
                                type='ic-play-circle-bofang'
                                style={{ marginLeft: '3px' }}
                                onClick={actions.playRudio.params(allData.data.voice_url)}
                            />
                        )}
                    </div>
                ) : (
                    <div onClick={actions.markSemantic.params(content)}>
                        <XssComponent
                            classNames={ClassNames(
                                'display-block',
                                {
                                    'audio-text-list-item-text-hit-status':
                                        item.states.isHitStatus && !item.states.isHit,
                                },
                                { 'audio-text-list-item-text-hit': item.states.isHit }
                            )}
                            value={Udesk.business.emoji.replaceWechatEmotions(replaceLineBreak(replaceLink(content)))}
                        />
                        {allData && allData.data && allData.data.voice_url && (
                            <Icon
                                type='ic-play-circle-bofang'
                                style={{ marginLeft: '3px' }}
                                onClick={actions.playRudio.params(allData.data.voice_url)}
                            />
                        )}
                    </div>
                )}
            </When>
            <When condition={type === 'rich'}>
                <ImageViewer>
                    <SafeHtml
                        className={ClassNames(
                            '',
                            { 'audio-text-list-item-text-hit-status': item.states.isHitStatus && !item.states.isHit },
                            { 'audio-text-list-item-text-hit': item.states.isHit }
                        )}
                        content={Udesk.business.emoji.replaceWechatEmotionsInRich(content)}
                    />
                </ImageViewer>
            </When>
            <When condition={type === 'video'}>
                <video ref={vedioDom} onClick={() => vedioDom && vedioDom.current.webkitRequestFullScreen()} className='audio-text-list-item-text-video' controls="controls" src={content}></video>
            </When>
            <When condition={type === 'image'}>
                <ImageViewer>
                    <img
                        src={content || item.states.content.data.origin_url}
                        className='audio-text-list-item-img'
                        alt=''
                        style={{ width: '100%' }}
                    />
                </ImageViewer>
            </When>
            <When condition={type === 'audio'}>
                {allData && allData.data && allData.data.content && (
                    <Icon
                        type='ic-play-circle-bofang'
                        style={{ marginLeft: '3px' }}
                        onClick={actions.playRudio.params(allData.data.content)}
                    />
                )}
            </When>
            <When condition={type === 'file'}>
                <a href={content}>{item.states.content.filename}</a>
                <span className='audio-text-list-item-file-size'>{`(${item.states.content.filesize})`}</span>
            </When>
            <When condition={type === 'struct'}>
                <Card style={{ width: 240 }} cover={<img alt='' src={allData.data.content.img_url} />}>
                    <Meta
                        description={
                            <div>
                                {allData.data.content.title && (
                                    <div title={allData.data.content.title} className='struct-type-card-item'>{`${
                                        item.data.describes && item.data.describes.find((item) => item.key === 'title')
                                            ? item.data.describes.find((item) => item.key === 'title').text
                                            : 'title'
                                    }: ${allData.data.content.title}`}</div>
                                )}
                                {allData.data.content.description && (
                                    <div
                                        title={allData.data.content.description}
                                        className={`struct-type-card-item${
                                            allData.data.content.buttons && allData.data.content.buttons.length
                                                ? ' struct-type-card-item-bottom'
                                                : ''
                                        }`}
                                    >{`${
                                            item.data.describes &&
                                            item.data.describes.find((item) => item.key === 'description')
                                                ? item.data.describes.find((item) => item.key === 'description').text
                                                : 'description'
                                        }: ${allData.data.content.description}`}</div>
                                )}
                                {allData.data.content.buttons.map((item) => {
                                    return (
                                        <CopyToClipboard text={item.value} onCopy={actions.copyText}>
                                            <Tooltip
                                                trigger={['hover']}
                                                title={copied ? locales.labels.copyed : item.value}
                                            >
                                                <div className='struct-type-card-item'>{`${item.text}: ${item.value}`}</div>
                                            </Tooltip>
                                        </CopyToClipboard>
                                    );
                                })}
                            </div>
                        }
                    />
                </Card>
            </When>
            <Otherwise>
                <Popover content={JSON.stringify(allData)}>
                    {locales.components.pages.appealChekingTask.list.unsupportedFormat}
                    {type}
                </Popover>
            </Otherwise>
        </Choose>
    );
};
export const MarkerListComponent = (props) => {
    const locales = Locales['current'];

    let { markFlag, heightList, pointChildrenPersonInfo, pointChildrenComputerInfo, actions } = props;
    return (
        <div className='marker-list-page'>
            {markFlag && heightList && !_isEmpty(heightList) && (
                <div className='marker-list-page-person'>
                    <Icon type='ic-user-ren' />
                    <span className='marker-list-page-person-name'>{heightList.name}</span>
                </div>
            )}
            {!markFlag && pointChildrenPersonInfo && !_isEmpty(pointChildrenPersonInfo) && (
                <div className='marker-list-page-person'>
                    <Icon type='ic-user-ren' />
                    {pointChildrenPersonInfo.length > 1 && (
                        <Popover placement='bottom' content={actions.proverChange(pointChildrenPersonInfo)}>
                            <span className='marker-list-page-person-name'>
                                {locales.fix.hit}{pointChildrenPersonInfo.length}{locales.fix.checkPoint}
                            </span>
                        </Popover>
                    )}
                    {pointChildrenPersonInfo.length === 1 && (
                        <span className='marker-list-page-person-name' key={pointChildrenPersonInfo[0].id}>
                            {pointChildrenPersonInfo[0].name}
                        </span>
                    )}
                </div>
            )}
            {!markFlag && pointChildrenComputerInfo && !_isEmpty(pointChildrenComputerInfo) && (
                <div className='marker-list-page-person'>
                    <Icon type='ic-computer-diannao' />
                    {pointChildrenComputerInfo.length > 1 && (
                        <Popover placement='bottom' content={actions.proverChange(pointChildrenComputerInfo)}>
                            <span className='marker-list-page-person-name'>
                                {locales.fix.hit}{pointChildrenComputerInfo.length}{locales.fix.checkPoint}
                            </span>
                        </Popover>
                    )}
                    {pointChildrenComputerInfo.length === 1 && (
                        <span className='marker-list-page-person-name' key={pointChildrenComputerInfo[0].id}>
                            {pointChildrenComputerInfo[0].name}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};