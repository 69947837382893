import styled from 'styled-components';

export const TicketTextInfoWarp = styled.div`
    /* margin-top: 8px; */
    padding: 0 16px;
    background-color: #fff;
    white-space: pre-wrap;
`;

export const TicketTextInfoContent = styled.div`
    padding: 16px 0;
    ${(props) => !props.isLastItem && `border-bottom: 1px solid rgba(0, 0, 0, 0.1);`}
`;

export const TicketTextInfoContentHeader = styled.div`
    margin-bottom: 16px;
    .desc {
        color: rgba(0, 0, 0, 0.25);
    }
`;

export const RoleTag = styled.div`
    border-radius: 42px;
    padding: 2px 8px;
    ${(props) => (props.sender === 1 ? `color: #C97C00;` : `background: #E6F3FF;`)}
    ${(props) => (props.sender === 1 ? `background: #FFEBA3;` : `color: #1A6EFF;`)}
`;
