import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Udesk from "../../udesk/index";

const REACT_TOOL_MODES = {
    hover: "hover",
    click: "click"
};

class ReactTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToolVisible: false
        };
    }
    privates = {
        ReactToolComponent: null
    }

    actions = {
        onMouseEnter() {
            if (this.props.mode === REACT_TOOL_MODES.hover) {
                this.actions.calculateToolContentTop();
                this.setState({
                    isToolVisible: true
                });
            }
        },
        onMouseLeave() {
            if (this.props.mode === REACT_TOOL_MODES.hover) {
                this.setState({
                    isToolVisible: false
                });
            }
        },
        onClick() {
            if (this.props.mode === REACT_TOOL_MODES.click) {
                if (!this.state.isToolVisible) {
                    this.privates.ReactToolComponent();
                }
                this.setState({
                    isToolVisible: !this.state.isToolVisible
                });
            }
        },
        calculateToolContentTop() {
            if (this.privates.ReactToolComponent != null) {
                let target = $(this.privates.ReactToolComponent).find(".component-react-tool-main")[0];
                if (target != null) {
                    $(this.privates.ReactToolComponent).find(".component-react-tool-content").css({ top: `${$(target).height() || 0}px` });
                }
            }
        }
    }

    render() {
        let {
            classNames,
            getInitShowContent,
            iconClassNames,
            tooltipData,
            titleField,
            descField,
            svgTriggerClass,
            svgClassNames
        } = this.props;

        let {
            isToolVisible
        } = this.state;
        return (
            <div className={ClassNames("component-react-tool", { [`${classNames}`]: classNames !== "" })} ref={(instance) => this.privates.ReactToolComponent = instance} onClick={this.actions.onClick} onMouseEnter={this.actions.onMouseEnter} onMouseLeave={this.actions.onMouseLeave}>
                <Choose>
                    <When condition={getInitShowContent != null && typeof getInitShowContent === "function"}>
                        <div className="component-react-tool-main">
                            {getInitShowContent()}
                        </div>
                    </When>
                    <When condition={iconClassNames != null && iconClassNames !== ""}>
                        <i className={ClassNames("component-react-tool-main", { [`${iconClassNames}`]: iconClassNames !== "" })}></i>
                    </When>
                    <Otherwise>
                        <svg className={ClassNames("component-react-tool-main", { [`${svgClassNames}`]: svgClassNames !== "" })} aria-hidden="true" >
                            <use xlinkHref={`#${svgTriggerClass}`}></use>
                        </svg>
                    </Otherwise>
                </Choose>
                <div className={ClassNames("component-react-tool-container scrollbar clearfix", { "component-react-tool-container-show": isToolVisible })}>
                    <Choose>
                        <When condition={this.props.children}>
                            {this.props.children}
                        </When>
                        <Otherwise>
                            <ul className="component-react-tool-content">
                                <Choose>
                                    <When condition={(typeof tooltipData === "string") && tooltipData}>
                                        <li className="component-react-tool-content-item">
                                            {tooltipData}
                                        </li>
                                    </When>
                                    <When condition={tooltipData && tooltipData.length > 0}>
                                        {tooltipData.map((item, index) => {
                                            if (item[titleField] && item[descField]) {
                                                return (
                                                    <li className="component-react-tool-content-item" key={`react-tooltip-content-item-${index}`}>
                                                        <div className="component-react-tool-content-item-title" title={item[titleField]} >
                                                            {item[titleField]}
                                                        </div>
                                                        <div className="component-react-tool-content-item-content" >
                                                            {item[descField]}
                                                        </div>
                                                    </li>
                                                );
                                            } else {
                                                return undefined;
                                            }
                                        }).filter(Boolean)}
                                    </When>
                                </Choose>
                            </ul>
                        </Otherwise>
                    </Choose>
                </div>
            </div>
        );
    }
}


ReactTool.propTypes = {
    classNames: PropTypes.string,
    mode: PropTypes.string,
    iconClassNames: PropTypes.string,
    svgClassNames: PropTypes.string,
    svgTriggerClass: PropTypes.string,
    titleField: PropTypes.string,
    descField: PropTypes.string
};

ReactTool.defaultProps = {
    classNames: "",
    mode: "hover",
    iconClassNames: "udesk-react-iconfont icon-udesk-react-bi-help",
    getInitShowContent: null,
    svgClassNames: "",
    svgTriggerClass: "icon-udesk-react-bi-help",
    tooltipData: null,
    titleField: "fieldTitle",
    descField: "fieldDesc"
};


export default Udesk.react.udeskify(ReactTool);