import React, {useCallback, useMemo} from 'react';
import Udesk from 'Udesk';
import PageLeftTable from './components/pageLeftTable';
import PageRightTable from './components/pageRightTable';
import Locales from 'UdeskLocales';
import { useRequest } from 'src/util/hook';
import './index.scss'; 

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const request = useRequest();
    const {
        selectClusterId,
        setSelectedClusterId,
        subjectName,
        setSubjectName,
        selectedRowKey, 
        setSelectedRowKey, 
        selectClusterKey,
        setSelectedClusterKey,
        leftPageKeyword,
        setLeftPageKeyword,
        leftPagePageNum,
        setLeftPagePageNum,
        rightPagePagination,
        setRightPagePagination,
        judgeStrategy,
        conditionList,
        customJudgeLogic,
        setJudgeStrategy,
        setConditionList,
        setCustomJudgeLogic,
        history
    } = props;

    const scenario = useMemo(() => {
        return history.location.pathname.indexOf('dissent') > 0 ? Udesk.enums.applicationScenarios.objection.id : Udesk.enums.applicationScenarios.sellingPoints.id;
    }, [history.location.pathname]);

    const onSelectedRowKeyChange = useCallback((value) => {
        setSelectedRowKey(value);
        setRightPagePagination({
            current: 1,
            pageSize: rightPagePagination.pageSize,
            total: 0,
        });
    }, [
        rightPagePagination.pageSize, 
        setRightPagePagination, 
        setSelectedRowKey
    ]);

    return (
        <div className='udesk-qa-web-page words-mining-dissent-page'>
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root'>
                    <div>
                        <PageLeftTable {
                            ...{
                                request,
                                history,
                                locales,
                                selectedRowKey, 
                                setSubjectName,
                                setSelectedRowKey: onSelectedRowKeyChange, 
                                selectClusterKey,
                                setSelectedClusterKey, 
                                leftPageKeyword,
                                setLeftPageKeyword,
                                leftPagePageNum,
                                setLeftPagePageNum,
                                setSelectedClusterId,
                            }
                        }/>
                        <PageRightTable {
                            ...{
                                scenario,
                                history,
                                locales,
                                selectedRowKey, selectClusterId,
                                subjectName,
                                judgeStrategy,
                                conditionList,
                                customJudgeLogic,
                                setJudgeStrategy,
                                setConditionList,
                                setCustomJudgeLogic,
                                rightPagePagination,
                                setRightPagePagination,
                            }
                        }/>
                    </div>

                </div>
            </div>
        </div>
        
    );
});

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};
class Component extends React.Component {
    privates = {
        storages: {
            subjectName: null as string | null,
            selectClusterKey: null as number | null,
            selectClusterId: null as number | null,
            selectedRowKey: null as number | null,
            leftPageKeyword: '',
            leftPagePageNum: Udesk.config.paging.defaultPageNumber,

            rightPagePagination: defaultPagination,
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            conditionList: [],
            customJudgeLogic: '',
        },
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'sessionStorage',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/algorithm-platform\/training-center\/words-mining\/*/i,
            ],
        },
    };
    actions = {
        saveStorages(changedParams: any) {
            (this as any).privates.storages = { ...(this as any).privates.storages, ...changedParams };
        },
        setSelectedClusterKey (selectClusterKey) {
            (this as any).actions.saveStorages({ selectClusterKey });
            (this as any).actions.update();
        },
        setSelectedClusterId (selectClusterId) {
            (this as any).actions.saveStorages({ selectClusterId });
            (this as any).actions.update();
        },
        setSelectedRowKey (selectedRowKey) {
            (this as any).actions.saveStorages({ selectedRowKey });
            (this as any).actions.update();
        },
        setSubjectName (subjectName){
            (this as any).actions.saveStorages({ subjectName });
            (this as any).actions.update();
        },
        setLeftPageKeyword (leftPageKeyword) {
            (this as any).actions.saveStorages({ leftPageKeyword });
            (this as any).actions.update();
        },
        setLeftPagePageNum (leftPagePageNum) {
            (this as any).actions.saveStorages({ leftPagePageNum });
            (this as any).actions.update();
        },
        setRightPagePagination (rightPagePagination) {
            (this as any).actions.saveStorages({ rightPagePagination });
            (this as any).actions.update();
        },
        setJudgeStrategy (judgeStrategy, isUpdate = false) {
            (this as any).actions.saveStorages({ judgeStrategy });
            if (isUpdate) {
                (this as any).actions.update();
            }
        },
        setConditionList (conditionList, isUpdate = false) {
            (this as any).actions.saveStorages({ conditionList });
            if (isUpdate) {
                (this as any).actions.update();
            }
        },
        setCustomJudgeLogic (customJudgeLogic, isUpdate = false) {
            (this as any).actions.saveStorages({ customJudgeLogic });
            if (isUpdate) {
                (this as any).actions.update();
            }
        },
    };
    componentDidMount() {
        const params = new URLSearchParams((this as any).props.location.search);
        const id = params.get('id');
        if (id) {
            this.privates.storages.selectClusterKey = +id;
        }
    }
    render() {
        return <Template 
            {...this.props}
            subjectName={this.privates.storages.subjectName}
            selectedRowKey={this.privates.storages.selectedRowKey}
            selectClusterId={this.privates.storages.selectClusterId}
            selectClusterKey={this.privates.storages.selectClusterKey}
            leftPageKeyword={this.privates.storages.leftPageKeyword}
            leftPagePageNum={this.privates.storages.leftPagePageNum}
            rightPagePagination={this.privates.storages.rightPagePagination}
            judgeStrategy={this.privates.storages.judgeStrategy}
            conditionList={this.privates.storages.conditionList}
            customJudgeLogic={this.privates.storages.customJudgeLogic}
            setSubjectName={this.actions.setSubjectName} 
            setSelectedRowKey={this.actions.setSelectedRowKey} 
            setSelectedClusterKey={this.actions.setSelectedClusterKey}
            setSelectedClusterId={this.actions.setSelectedClusterId}
            setLeftPageKeyword={this.actions.setLeftPageKeyword}
            setLeftPagePageNum={this.actions.setLeftPagePageNum}
            setRightPagePagination={this.actions.setRightPagePagination}
            setJudgeStrategy={this.actions.setJudgeStrategy}
            setConditionList={this.actions.setConditionList}
            setCustomJudgeLogic={this.actions.setCustomJudgeLogic}
        />;
    }
}

export default Udesk.react.udeskify(Component);