import React from 'react';
import PropTypes from "prop-types";

class SafeHtmlComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        content: "",
        bodyStyle: {},
        className: "",
    };
    static propTypes = {
        content: PropTypes.string,
        bodyStyle: PropTypes.object,
        className: PropTypes.string,
    };
    //#endregion

    actions = {

    };
}

export default SafeHtmlComponent;
