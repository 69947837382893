import Locales from '../locales/index';
let serviceGroupreportDateTypes=[
    { id: "qc_group_trend_day", name: () => Locales.current.enums.reportDateTypes.dailyReport, key: "dailyReport" },
    { id: "qc_group_trend_week", name: () => Locales.current.enums.reportDateTypes.weeklyReport, key: "weeklyReport" },
];
let serviceReportDateTypes=[
    { id: "qc_agent_trend_day", name: () => Locales.current.enums.reportDateTypes.dailyReport, key: "dailyReport" },
    { id: "qc_agent_trend_week", name: () => Locales.current.enums.reportDateTypes.weeklyReport, key: "weeklyReport" },
];
 
export default {
    serviceGroupreportDateTypes,
    serviceReportDateTypes,
};
