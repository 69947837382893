import React from 'react';
import CorpusDataComponent from './component';
import SmartWordsLibraryCommonList from '../smart-words-library-common-list/';
import ReactModal from 'udesk-react/src/components/react-modal';
import ReactSelect from 'udesk-react/src/components/react-select';
import Udesk from 'Udesk';
import classNames from 'classnames';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Icon, Input } from 'udesk-ui';
import EllipsisLnline from 'src/components/common/ellipsis-inline';

export default class CorpusDataTemplate extends CorpusDataComponent {
    render() {
        let { getFieldDecorator } = this.props.form;

        let { locales, actions } = this;

        let {
            columns,
            isCorpusDataModalShow,
            corpusDataModalTitle,
            effectiveness,
            corpusDescription,
            corpusContent,
            isShowWarningNoneCorpusContent,
            isShowWarningCorpusContentOverSetting,
            isShowTextArea,
        } = this.privates;

        let corporaType = Udesk.enums.smartWordsLibraryMenus.corpusData.id;

        return (
            <div>
                <SmartWordsLibraryCommonList
                    navItemType={corporaType}
                    boardName={Udesk.enums.smartWordsLibraryBoard.corpusData.id}
                    columns={columns}
                    getlistDataPath="corpora/corporaDataList"
                    openAddModal={actions.createCorpusData}
                    uploadModalTitle={
                        locales.components.pages.smartWordsLibrary.upload.uploadCorpusData
                    }
                    onCreated={(instance) => {
                        this.privates.smartWordsLibraryListInstance = instance;
                    }}
                />
                <ReactModal
                    cancelText={locales.labels.cancel}
                    visible={isCorpusDataModalShow}
                    title={corpusDataModalTitle}
                    headerClassName="udesk-qa-smart-words-library-modal-header"
                    okText={locales.labels.save}
                    onOk={actions.saveCorpusData}
                    onCancel={actions.closeCorpusDataModal}
                    footer={null}
                >
                    <Form className="smart-words-library-nav-item-form" layout="horizontal">
                        <Form.Item
                            label={
                                <EllipsisLnline>
                                    {locales.fields.smartWordsLibrary.corpusData.corpusDescription}
                                </EllipsisLnline>
                            }
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 18 }}
                        >
                            {getFieldDecorator('corpusDescription', {
                                rules: [
                                    {
                                        required: true,
                                        message:
                                            locales.components.pages.smartWordsLibrary.corpusData
                                                .pleaseEnterCorpusDescription,
                                    },
                                ],
                                initialValue: corpusDescription,
                            })(
                                <Input
                                    className={classNames(
                                        'udesk-qa-smart-words-library-modal-body-input'
                                    )}
                                />
                            )}
                        </Form.Item>

                        <Form.Item
                            label={
                                <EllipsisLnline>
                                    {locales.fields.smartWordsLibrary.corpusData.corpusContent}
                                </EllipsisLnline>
                            }
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 18 }}
                        >
                            <div className="form-group row udesk-qa-modal-body-content-form-group have-corpus-content-textarea-form-group">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 clearfix have-corpus-content-textarea-form-group-right">
                                    <span className="show-corpus-content-required-info">
                                        {
                                            locales.components.pages.smartWordsLibrary.corpusData
                                                .required
                                        }
                                    </span>
                                    <If condition={isShowTextArea}>
                                        <textarea
                                            rows="5"
                                            onChange={actions.changeCorpusContent}
                                            className={classNames(
                                                'form-control corpus-content-textarea col-xs-12 col-sm-12 col-md-12 col-lg-12',
                                                {
                                                    [`no-field-warning`]:
                                                        isShowWarningNoneCorpusContent,
                                                }
                                            )}
                                            defaultValue={corpusContent}
                                        />
                                    </If>
                                    <If condition={isShowWarningNoneCorpusContent}>
                                        <span className="udesk-qa-smart-words-library-modal-body-warning-span">
                                            {
                                                locales.components.pages.smartWordsLibrary
                                                    .corpusData.pleaseEnterCorpusContent
                                            }
                                        </span>
                                    </If>
                                    <div className="corpus-data-select-or-exclamation-box">
                                        <If condition={!isShowTextArea}>
                                            <Icon type="ic-warn-jinggao" className="exclamation" />
                                            <span>
                                                {
                                                    locales.components.pages.smartWordsLibrary
                                                        .corpusData.CorpusContentWordsOverSetting
                                                }
                                            </span>
                                            <span className="upload-btn" onClick={actions.download}>
                                                {
                                                    locales.components.pages.smartWordsLibrary
                                                        .corpusData.download
                                                }
                                            </span>
                                            <span>
                                                {
                                                    locales.components.pages.smartWordsLibrary
                                                        .corpusData.see
                                                }
                                            </span>
                                        </If>
                                    </div>
                                </div>
                            </div>
                        </Form.Item>

                        <Form.Item
                            label={
                                <EllipsisLnline>
                                    {locales.fields.smartWordsLibrary.corpusData.effectiveness}
                                </EllipsisLnline>
                            }
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                        >
                            <div className="corpus-data-select-or-exclamation-box col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                {getFieldDecorator('effectiveness', {
                                    initialValue: effectiveness.toString(),
                                })(
                                    <ReactSelect
                                        id="effectiveness"
                                        classNames="udesk-qa-smart-words-library-react-select"
                                        hasNullSelect={false}
                                        dataScource={Udesk.enums.wordsEffectiveness}
                                        onChanged={actions.changeCorpusDataEffectiveness}
                                    />
                                )}
                                <If
                                    condition={
                                        isShowWarningCorpusContentOverSetting && isShowTextArea
                                    }
                                >
                                    <Icon type="ic-warn-jinggao" className="exclamation" />
                                    <span>
                                        {
                                            locales.components.pages.smartWordsLibrary.corpusData
                                                .CorpusContentWordsOverSetting
                                        }
                                    </span>
                                    <span>
                                        {
                                            locales.components.pages.smartWordsLibrary.corpusData
                                                .canNotSave
                                        }
                                    </span>
                                </If>
                            </div>
                        </Form.Item>

                        <Form.Item className="corpus-data-modal-footer-form-item">
                            <div className="ant-modal-footer udesk-qa-smart-words-library-modal-footer corpus-data-modal">
                                <button
                                    type="button"
                                    onClick={actions.closeCorpusDataModal}
                                    className="btn btn-default"
                                >
                                    {locales.labels.cancel}
                                </button>
                                <button
                                    disabled={isShowWarningCorpusContentOverSetting}
                                    type="button"
                                    onClick={actions.saveCorpusData}
                                    className="btn btn-success"
                                >
                                    {locales.labels.save}
                                </button>
                            </div>
                        </Form.Item>
                    </Form>
                </ReactModal>
            </div>
        );
    }
}
