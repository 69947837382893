import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';
import { CommonPage } from 'udesk_gm_ui';
import { clearTaskCenterPageInfoCache } from './components/cache';

class intelligentTags extends React.Component<any, any> {
    constructor(props) {
        super(props);

        clearTaskCenterPageInfoCache();
    }

    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    componentWillUnmount(): void {
        clearTaskCenterPageInfoCache();
    }

    render() {
        let { routes, route, location, sdkOptions } = this.props;
        console.log('routes',routes);
        return (
            <CommonPage.PageInfoMemo>
                {(() => {
                    if (this.isRedirect(route.path, location.pathname)) {
                        let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                            routeName: route.name,
                        });
                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else {
                        return (
                            <div className="udesk-qa-supervision" style={{ height: '100%' }}>
                                {routes.map((route, i) => {
                                    if (
                                        !route.authCode ||
                                        (route.authCode &&
                                            Udesk.data.init.user.hasFeature(route.authCode))
                                    )
                                        return <SubRoutes key={i} route={route} />;
                                    return null;
                                })}
                            </div>
                        );
                    }
                })()}
            </CommonPage.PageInfoMemo>
        );
    }
}

export default Udesk.react.udeskify(intelligentTags);
