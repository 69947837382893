import Udesk from 'Udesk';
import AdminSystemIndexComponent from './component';
import { isCoach } from 'Udesk/system/subApp';
import { inspect } from 'src/util/core';
import { getCompanysModel } from 'src/api/companys/model';
export default class AdminSystemIndexRoute extends AdminSystemIndexComponent {
    model() {
        const { props } = this;

        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );

        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/companys/sys/config',
                props.sdkOptions
            );
            Udesk.ajax.get(url, params).then(
                (resp) => {
                    inspect(isCoach(), () => {
                        resp.data.loginBulletBox = resp.data.loginBulletBox || {};
                    });
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        const largeModelPromise = new Promise((resolve, reject) => {
            getCompanysModel()
                .then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                )
                .catch(() => {
                    resolve([]);
                });
        });

        return {
            sysConfig: modelPromise,
            largeModel: largeModelPromise,
        };
    }
}
