import { EnumClass} from 'udesk-web-toolkits/src/udesk/enums';

export default new EnumClass({
    prefix: { value: 1 },
    field: { value: 2 },
    operator: { value: 3 },
    value: { value: 4 },
    removalIcon: { value: 5 },
    postfix: { value: 6 }
});
