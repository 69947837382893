import React, { FC, useCallback, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { Button, Divider, Select } from 'udesk-ui';
import styled from 'styled-components';
import { getInspectionPointSystemDialogsFindAll } from 'src/api/inspectionPointSystemDialogs/findAll';
import UdeskLocales from 'UdeskLocales';

const Operations = styled.div`
    text-align: right;
`;

type TemplateProps = {
    value?: number[];
    onChange?: (v?: TemplateProps['value']) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange } = props;

    const [options, setOptions] = useState<any[]>();

    useRequest(getInspectionPointSystemDialogsFindAll, {
        onSuccess: (res) => {
            setOptions(
                (res.data || []).map((m) => {
                    return {
                        value: m.id,
                        label: m.name,
                    };
                })
            );
        },
    });

    const chosenAll = useCallback(() => {
        onChange?.(options?.map((m) => m.value!));
    }, [onChange, options]);

    const cancelChosenAll = useCallback(() => {
        onChange?.([]);
    }, [onChange]);

    const { onClick, label } = useMemo(() => {
        const onClick =
            value?.length === options?.length ? () => cancelChosenAll() : () => chosenAll();

        const label = value?.length === options?.length ? /* 取消全选 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.msgTypesSelect.index.cancelSelectAll : /* 全选 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.msgTypesSelect.index.selectAll;

        return {
            onClick,
            label,
        };
    }, [cancelChosenAll, chosenAll, options?.length, value?.length]);

    return (
        <Select
            value={value}
            onChange={onChange}
            style={{ width: 200 }}
            mode="multiple"
            maxTagCount={1}
            options={options}
            filterOption={(input, option) =>
                ((option?.label ?? '') as string)?.toLowerCase().includes(input?.toLowerCase())
            }
            dropdownRender={(menu) => (
                <>
                    {options?.length ? (
                        <>
                            <Operations>
                                <Button type="link" size="small" onClick={onClick}>
                                    {label}
                                </Button>
                            </Operations>

                            <Divider style={{ margin: '8px 0' }} />
                        </>
                    ) : null}

                    {menu}
                </>
            )}
        />
    );
};

export default Template;
