var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
var HeaderWrap = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  box-sizing: border-box;\n  height: 40px;\n  padding: 10px;\n  border-radius: 2px;\n  background-color: ", ";\n  border: ", ";\n"])), function (props) {
  return props.checked ? '#f0f8ff' : 'unset';
}, function (props) {
  return props.checked ? '1px solid #2c7cf6' : 'unset';
});
var HeaderWrapAllCheck = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
var HeaderWrapCheckAllText = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 0 36px 0 8px;\n"])));
var HeaderWrapAllCheckInfo = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
var HeaderWrapAllCheckInfoNumber = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 0 4px;\n  color: #2c7cf6;\n"])));
var HeaderWrapExtraButton = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
export { HeaderWrap, HeaderWrapAllCheck, HeaderWrapExtraButton, HeaderWrapCheckAllText, HeaderWrapAllCheckInfo, HeaderWrapAllCheckInfoNumber };