import React, { FC } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Div = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: ${(props) => (props.showIsHit ? '85%' : '100%')};
`;

const SubDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const EllipsisDiv = styled.div`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

type StandardProps =
    | {
          content?: string[];
          showIsHit: false;
      }
    | {
          content?: { name: string; id?: number; isHit: boolean }[];
          showIsHit: true;
      };

export const ListCell: FC<StandardProps> = (props) => {
    const { content, showIsHit } = props;

    return showIsHit ? (
        <Div>
            {content?.map((c) => {
                return (
                    <SubDiv id={c.id}>
                        <EllipsisDiv title={c.name}>{c.name}</EllipsisDiv>
                        {c.isHit ? (
                            <CheckCircleOutlined style={{ color: '#52c41a', marginLeft: 20 }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: '#db4a4a', marginLeft: 20 }} />
                        )}
                    </SubDiv>
                );
            })}
        </Div>
    ) : (
        <Div>
            {content?.map((c) => {
                return <EllipsisDiv title={c}>{c}</EllipsisDiv>;
            })}
        </Div>
    );
};
