import React, { useEffect, useState } from 'react';
import { useScoreDetailContext } from '../Context';
import { OverView as OverViewComponent } from 'Component/pages/components/coach/Score/Overview';
import { getIntelligentPartnerByRecordIdEvaluation } from 'src/api/intelligentPartner/{recordId}/evaluation';
import Udesk from 'Udesk';

export const OverView = React.memo((props: any) => {
    const { recordId } = props;
    // const locales = Locales['current'];
    const { scoreOverView, rank, total } = useScoreDetailContext();
    const [modelRuleList, setModelRuleList] = useState<any[]>([]);

    useEffect(() => {
        getIntelligentPartnerByRecordIdEvaluation({
            segments: {
                recordId,
            },
            params: {
                isLastTime: 0,
            },
        }).then((resp) => {
            setModelRuleList(
                resp.data?.scorePointList?.map((i) => ({
                    pointType: i.type,
                    name: i.name,
                    dimension: getDimension(i.type!),
                })) ?? []
            );
        });
    }, []);
    console.log('scoreOverView:: ', scoreOverView);
    console.log('modelRuleList:: ', modelRuleList);
    return (
        <OverViewComponent
            modelRuleList={modelRuleList}
            rank={rank}
            total={total}
            scoreOverView={scoreOverView}
        />
    );
});

function getDimension(pointId: number) {
    if (pointId === 1) return Udesk.enums.dimension.flow.id;
    if (pointId <= 5 && pointId >= 2) return Udesk.enums.dimension.word.id;
    if (pointId === 8) return Udesk.enums.dimension.emotion.id;
    return Udesk.enums.dimension.deliver.id;
}
