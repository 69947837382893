export default function (value, field) {
    if (value) {
        if (field.fieldType === 4 && field.optionValueList && field.optionValueList.length > 0) {
            return getOptionValue(value, field.optionValueList);
        } else if (Array.isArray(value)) {
            let str = '';
            value.forEach(item => {
                if (item.agentName) {
                    str += item.agentName + ",";
                }
            });
            return str.substring(0, str.length - 1);
        } else if (typeof value === 'object') {
            return value.agentName || value.agentGroupName;
        } else if (typeof value === 'string') {
            return value;
        } else {
            return value;
        }
    } else {
        return '';
    }
}

function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter(item => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map(item => {
            return item.name;
        });
        return value.join(",");
    } else {
        return "";
    }
}