// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdParam,
    PostIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdBody,
    BaseResponseFinishRecordResultAnalysisOverview,
} from '../../../../../types';

/**
 * 获取单个任务结果分析概览
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos/result/analysis/overview/{taskId}
 * @param taskId
 */
export function postIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId(
    data: PostIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdBody,
    options: ApiOptions<
        PostIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdParam,
        never
    > & { segments: PostIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdParam }
): Promise<BaseResponseFinishRecordResultAnalysisOverview> {
    return request<
        PostIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdBody,
        PostIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskIdParam,
        never
    >('/intelligentPartnerTaskInfos/result/analysis/overview/{taskId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/result/analysis/overview/{taskId}',
        method: 'post',
    },
];
