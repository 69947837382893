export default {
  labels: {
    clear: "Vacío",
    refresh: "Actualizar",
    nextStep: "Siguiente paso",
    prevStep: "Paso anterior",
    closeDriver: "Salir de la guía",
    complete: "Terminado",
    viewStatistics: "Ver estadísticas",
    colon: "：",
    splitter: "/",
    remark: "Observaciones",
    correction: "Corrección de errores",
    save: "Guardar",
    reset: "Restablecer",
    increase: "Añadir",
    preview: "Vista previa",
    unit: "Artículo",
    cancel: "Cancelación",
    all: "Todo",
    searchPlaceHolder: "Ingrese la consulta de palabras clave",
    greaterThan: "Más grande que",
    lessThan: "Menos de",
    second: "Segundos",
    description: "Descripción",
    column: "Columna",
    pleaseChoice: "Por favor elija",
    filter: "Filtrar",
    saveAndSubmit: "Guardar y enviar",
    submit: "Presentar",
    appeal: "Reclamación",
    review: "Revisión",
    choose: "Selección",
    manage: "Gestión",
    delete: "Eliminar",
    add: "Añadido",
    create: "Nuevo",
    edit: "Edición",
    name: "Nombre",
    describe: "Descripción",
    next: "Siguiente paso",
    percent: "%",
    export: "Exportar",
    exportAll: "Exportar todo",
    confirm: "Determinar",
    back: "Volver",
    versionUpdate:
      "¡Me complace informarle que nuestro sitio web acaba de lanzar una nueva versión! Para garantizar su uso normal, necesitamos que guarde inmediatamente su trabajo actual y luego actualice la página (haga clic en el botón de actualización del navegador o presione la tecla F5) para que nuestra nueva versión surta efecto. ¡Muchas gracias por su cooperación!",
    more: "Más",
    selected: "Seleccionado",
    deduct: "Hebilla",
    maxScore: "Más",
    scoreUnit: "Puntos",
    pointUnit: "A",
    comma: "，",
    view: "Ver",
    batchingImport: "Importar",
    deleteContent:
      "¿Confirma que desea eliminar? No se recuperará después de la eliminación",
    import: "Importar",
    uploadFile: "Subir archivos",
    downloadFile: "Exportar archivos",
    upload: "Subir",
    click: "Haga clic en",
    downloadTemplate: "Descargar plantilla",
    fillInTheForm: "Complete por formato",
    UploadExcelfile: "Cargar Excel para completar la importación por lotes",
    uploadIdentical:
      "Si el nombre de la API existe, los datos que ya están en el sistema se actualizan al importar",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Aplicable",
    leftBracket: "(",
    rightBracket: ")",
    question: "Problema",
    answer: "Respuesta",
    content: "Contenido",
    search: "Búsqueda",
    query: "Consulta",
    cancelEdit: "Cancelar la edición",
    noValue: "Sin datos",
    selectAll: "Todo seleccionado",
    changeAGroup: "Cambiar grupo",
    arrow: " ---> ",
    saveReview: "Presentación de revisión",
    saveSpotCheck: "Presentación de muestreo",
    rapidSampling: "Muestreo rápido",
    file: "Archivo",
    download: "Descargar",
    lastPage: "Anterior",
    nextPage: "Siguiente",
    batchActions: "Operación por lotes",
    cancelDistribution: "Cancelar la asignación",
    againDistribution: "Reasignación",
    deleteRecord: "Eliminar registros",
    oneOptionOpertor: "Inspector de calidad opcional",
    allOptionOpertor: "Todos los inspectores de calidad",
    distributionInfoFirstOne: "Confirme",
    distributionInfoFirstTwo:
      "¿Se reasignan los registros? Seleccione el objetivo de asignación",
    distributionInfoSecondOne: "Seguro de cancelar",
    distributionInfoSecondTwo:
      "La información del asignador del registro se convertirá en un registro de revisión no asignado después de la cancelación.",
    distributionInfoSecondThree:
      "La información del asignador del registro se convertirá en un registro de muestreo no asignado después de la cancelación.",
    distributionInfoThirdOne: "Entre los registros seleccionados",
    distributionInfoThirdTwo:
      "Los registros revisados y los registros revisados no se procesarán.",
    distributionInfoThirdThree:
      "Los registros que han sido muestreados no se procesarán.",
    exportError: "Error de exportación",
    exportSuccess: "Exportar con éxito",
    deleteRecordInfo:
      "¿Confirmo que desea eliminar {0} registros? Los datos no se recuperarán después de la eliminación",
    pleaseSetFilter: "Seleccione las condiciones de filtro",
    noCountAfterFilter:
      "Sin datos de exportación, restablezca las condiciones de filtrado",
    redo: "Volver a ejecutar",
    batchMove: "Movimiento por lotes",
    websiteName: "Inspección de calidad inteligente",
    salesEnable: "Empoderamiento de las ventas",
    systemName: "Inspección de calidad inteligente",
    initSystemError:
      "Excepción de red o error de inicialización de la página, comuníquese con el servicio al cliente",
    day: "Día",
    hour: "Horas",
    minute: "Puntos",
    questionMark: "？",
    personalSettings: "Configuración personal",
    logout: "Cancelación de cuentas",
    ok: "Determinar",
    saveAs: "Guardar como",
    pleaseEnter: "Por favor ingrese",
    executeMessage: {
      executeSuccess: "Ejecutado con éxito {0}",
      executeError: "{0} Error de ejecución",
    },
    field: "Campo",
    operator: "Operador",
    value: "Valor",
    placeholder: "Por favor elija",
    pleaseSelect: "Por favor elija",
    totalScore: "Puntuación total",
    hyphen: "-",
    semicolon: "；",
    wfm: "Programación inteligente",
    user: "Usuarios",
    none: "Ninguno",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "¡Ahorre con éxito! Efectivo después de que el empleado relevante actualice la página",
    saveAsName: "{0}(copia)",
    systemInformation: "Ver datos del sistema",
    billingCenter: "Centro de facturación",
    warningMessage:
      "La cuota restante es insuficiente, recargue lo antes posible",
    fileTypeHolder:
      "El formato de archivo es incorrecto, cargue el archivo de formato {0}",
    dataNo: "Sin datos",
    move: "Mover",
    study:
      "Tiene un punto de inspección de calidad de autoaprendizaje sin marcar",
    studySuccess: "Anotación exitosa",
    studyError: "Anotación fallida",
    editType: "Editar etiquetas de categoría",
    copyed: "Copiado en el portapapeles",
    clickToCopy: "Haga clic en Copiar",
    addFilterConditions: "Agregar condiciones de filtro",
    filterConditions: "Condiciones de filtro",
    messageTime: "Hora del mensaje",
    messageType: "Tipo de mensaje",
    pushType: "Canales de mensajes",
    messageContent: "Contenido del mensaje",
    messageName: "Nombre del mensaje",
    messageStatus: "Estado del mensaje",
    configuration: "Información de configuración",
    sendTarget: "Enviar destino",
    guideUrl: "URL de arranque",
    noticeDetail: "Detalles del mensaje",
    action: "Operación",
    seeDetail: "Ver detalles",
    theDay: "{0} Día",
    theLastDayOfMonth: "Último día del mes",
    ignore: "Ignorar",
    seeNow: "Ver ahora",
    exportConfirmText: {
      export: "Un total de {0} datos, ¿confirmar la exportación?",
      exportAll: "¿Confirmar la exportación de todo el contenido?",
    },
    samrTagAdd: "Añadir etiquetas",
    deletePopconfirmContent: "¿Confirma que desea eliminar?",
    TheLastThirtyDays: "Últimamente 30 días",
    addNumber: "Más",
    subNumber: "Menos",
    addStrategy: "Agregar una estrategia",
    minutes: "Minutos",
    status: "Estado habilitado",
    pushConfig: "Configuración push",
    category: "Clasificación",
    and: "Con",
    interval: "Intervalo",
    switchOn: "Abrir",
    switchOff: "Cerrar",
    batchDel: "Eliminación por lotes",
    exportTxt: "Exportar texto TXT",
    playSpeed: "Velocidad doble",
    messageLogBtn:
      "Ver la comunicación/conversación que activa la notificación",
    dataLink: "Enlace de datos",
    yes: "Sí.",
    no: "No.",
    sentenceUnit: "Oración",
    appealDetail: "Detalles del registro de quejas",
    detail: "Detalles",
    default: "Por defecto",
    customer: "Cliente",
    agent: "Servicio al cliente",
    sort: "Ordenar",
    applyNow: "Aplicación",
    index: "Número de serie",
    allColumns: "Columna que se puede agregar",
    selectedColumns: "Columna agregada",
    syn: "Sincronización",
    saveAndNext: "Guardar y crear el siguiente",
    viewReference: "Ver referencias",
    referenceDetail: "Detalles de la cita",
    goToQaList: "Ir a la inspección de calidad",
    goToCheck: "Ir al muestreo",
    businessCard: "Tarjetas de visita",
    weapp: "Pequeño programa",
    redpacket: "Sobre rojo",
    externalRedpacket: "Sobre rojo interoperable",
    sphfeed: "Número de vídeo",
    exportTaskCreated: "La tarea de exportación se creó correctamente",
    plzGoTo: "Por favor salte a",
    importTaskCreated: "La tarea de importación se creó correctamente",
    taskCreated: "Creación exitosa de tareas",
    reply: "Responder",
    replied: "Respondió",
    release: "Lanzamiento",
    expandMore: "Expande otras {0} respuestas",
    comment: "Comentarios",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Por favor, tenga al menos uno {0}",
      canNotBeEmptyFormat: "{0} no puede estar vacío",
      repeatErrorTipFormat: "{0} Hay duplicados",
      getInformationFailedFormat:
        "No se pudo obtener la información {0}, verifique la conexión de red o vuelva a intentarlo más tarde",
      deleteSuccessFormat: "{0} La eliminación se realizó correctamente.",
      deleteFailedFormat:
        "{0} Error de eliminación, inténtelo de nuevo más tarde.",
      saveSuccessFormat: "{0} Guardado correctamente.",
      saveFailedFormat: "{0} No se pudo guardar, inténtelo de nuevo más tarde.",
      fileSuccessFormat: "{0} El archivo se realizó correctamente.",
      fileFailedFormat: "{0} El archivo falló, inténtelo de nuevo más tarde.",
      exportSuccessFormat: "{0} Exportar con éxito",
      exportFailedFormat:
        "{0} La exportación falló, inténtelo de nuevo más tarde.",
      submitSuccessFormat: "{0} El envío se realizó correctamente.",
      submitFailedFormat: "{0} El envío falló, inténtelo de nuevo más tarde.",
      confirmSuccessFormat: "{0} Confirme el éxito.",
      confirmFailedFormat:
        "{0} Confirme el error, inténtelo de nuevo más tarde.",
      pleaseUploadFile: "¡Sube el archivo primero!",
      importFileSuccess: "¡La importación de archivos es exitosa!",
      importFildFailed: "¡La importación de archivos falló!",
      deleteSuccess: "¡La eliminación se realizó correctamente!",
      deleteFailed: "La eliminación falló, ¡inténtelo de nuevo más tarde!",
      illegalTitle: "Permisos insuficientes",
      illegalMessage:
        "No tiene algunos o todos los permisos para el siguiente paso, comuníquese con la configuración del administrador, haga clic en el botón Aceptar para salir de la guía",
      illegalMessageCurrent:
        "No tiene algunos o todos los permisos de los pasos actuales, comuníquese con la configuración del administrador, haga clic en el botón Aceptar para salir del arranque",
      skipMessage:
        "Si desea omitir este paso temporalmente, haga clic en Omitir",
      errorMsg500:
        "La solicitud del servidor falló, verifique su operación o actualice para volver a intentarlo",
      errorMsg404: "Ooops... la página que estás buscando se ha ido",
      errorMsg400:
        "La solicitud del servidor falló, compruebe sus datos e inténtelo de nuevo",
      pageOverFlow:
        "Actualmente solo admite la visualización de 10,000 elementos, configure las condiciones de filtrado para ver la parte excedente",
      isHandAddSmartTag: "Es una etiqueta inteligente agregada manualmente",
      defaultGradeMustAtLast:
        "La calificación predeterminada debe colocarse al final",
      AutomaticAllocationInspectorsIsNull:
        "Ingrese a la página de información básica para agregar un inspector de calidad",
      timePickerTips:
        "Para no afectar su uso normal, 23:00 ~ 2:00 es el tiempo de mantenimiento del sistema y no se realizan tareas programadas.",
      batchDeleteInfo:
        "Se eliminó con éxito {0} y se falló {1}. El motivo del error fue que los datos se habían utilizado.",
      batchDeleteTips:
        "Un total de {0} datos están verificados para confirmar la eliminación.",
      correctionSuccess: "Corrección de errores exitosa",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError:
            "Agregar hasta 10 formularios de inspección de calidad",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "La suma de los elementos del indicador {0} debe sumar 100",
          banProjectMaxErrorTip: "Agregue hasta 2000 artículos prohibidos",
          deadlyProjectMaxErrorTip: "Agregue hasta 500 artículos fatales",
        },
      },
    },
    info: {
      creatSuccess: "Éxito en la creación",
      creatError: "Error de creación",
      editSuccess: "Modificación exitosa",
      editError: "Error de modificación",
      deleteSuccess: "La eliminación se realizó correctamente",
      addSuccess: "Nuevo éxito",
      deleteError: "Error de eliminación",
      conditionError: "Error de expresión lógica del operador",
      customJudgeLogic:
        "Error de expresión lógica de combinación condicional personalizada",
      exportError: "Error de exportación",
      searchError: "Error de detección",
      loadError: "{0} Error de carga",
      saveSuccess: "Ahorre con éxito",
      saveError: "Error de guardar",
      fileSuccess: "Archivo exitoso",
      fileError: "Error de archivo",
      cannotBeNull: "{0} ¡No puede estar vacío!",
      pleaseSearchAndSelect: "Por favor ingrese la palabra clave para buscar",
      clickAndToggle: "Haga clic para seleccionar el interruptor",
      deleteWarning:
        "{0} La eliminación no se restaurará, ¿está seguro de que desea continuar?",
      getOssAuthFailed:
        "No se pudo obtener la autorización de OSS, verifique la conexión de red o vuelva a intentarlo más tarde.",
      getInsightAuthFailed:
        "No se pudo obtener la autorización de Insight. Compruebe la conexión de red o vuelva a intentarlo más tarde.",
      pictureUploadFailed: "Error de carga de imagen",
      pictureUploadFailedSize:
        "El tamaño de la imagen excede el límite, la imagen no se carga",
      wecomTagsSyncInfo:
        "Actualizado recientemente:{0}, sincronizar {1} mensajes",
      collectSuccess: "Colección exitosa",
    },
  },
  components: {
    common: {
      qcReportEcharts: {
        errorMessage: "Obtención de datos de informes fallidos",
      },
      waveAudio: {
        noWaveMessage:
          "Para mantener su experiencia, las grabaciones de más de {0} minutos no muestran formas de onda, puede reproducirlo directamente",
      },
      customFilter: {
        del: "Eliminar",
        edit: "Edición",
        new: "Filtro personalizado",
        apply: "Aplicación",
        cancel: "Cancelación",
        onlyApply: "Solo aplicable",
        ohterSave: "Guardar como",
        save: "Guardar",
        customFilter: "Filtración común",
        reset: "Restablecer",
        holderKeyWord: "Por favor ingrese las palabras clave",
        customFilterRule: "Filtrar reglas lógicas:",
        customFilterName: "Nombre del filtro:",
        holderCustomFilterName: "Introduzca el nombre del filtro",
        manualConfiguration: "Configuración manual",
        holderCustomRule: "Introduzca una condición lógica personalizada",
        example: "Ejemplo",
        dragConfiguration: "Configuración de arrastrar y soltar",
        condition: "Condiciones",
        operation: "Operación",
        infoDel: "Arrastrar el contenido fuera del área para eliminar",
        toConfigure: "Configuración",
        holderCustomFilterNameLength:
          "La longitud máxima del nombre del filtro es 10",
        customFilterNameNone: "El nombre del filtro no puede estar vacío",
        and: "Y",
        or: "O",
        not: "No",
        delSuccess: "La eliminación se realizó correctamente",
        saveSuccess: "Ahorre con éxito",
        updateSuccess: "Modificación exitosa",
        customFilterCondition: "Condiciones de filtro",
        conditionRule: "Reglas de condición",
        none: "Ninguno",
        filterPlaceholder: "Por favor ingrese las palabras clave",
      },
      labelTabs: {
        addThis: "Añadir a esto",
        goThis: "Mover a este",
        saveGo: "Guardar y entrenar",
      },
      datePicker: {
        today: "Hoy",
        yesterday: "Ayer",
        theDayBeforeYesterday: "Anteayer",
        thisWeek: "Esta semana",
        lastWeek: "La semana pasada",
        thisMonth: "Este mes",
        lastMonth: "El mes pasado",
        lastSevenDay: "Últimamente 7 días",
        lastThirtyDay: "Últimamente 30 días",
        thisQuarter: "Este trimestre",
        lastQuarter: "El último trimestre",
        thisYear: "Este año",
        lastYear: "El año pasado",
        lastYearFromToday: "El año pasado",
      },
      pagination: { page: "Total {0} artículos,{1} páginas" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Cargar mensajes anteriores",
          bottom: "Cargar más mensajes",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Tareas de inspección de calidad",
        informationCollectTasksManageTitle: "Tareas de adquisición",
        customerServiceCenterTitle: "Centro de atención al cliente",
        informationTitle: "Centro de datos",
        intelligentAnalysisTitle: "Análisis inteligente",
        semanticIntelligence: "Inteligencia semántica",
        trainingCenterTitle: "Centro de formación",
        adminTitle: "Gestión de sistemas",
      },
      qualityCheckingTask: {
        title: "Inspección de calidad de llamada",
        index: {
          tipOne:
            "La inspección de calidad de la llamada se utiliza para evaluar la calidad del trabajo del centro de llamadas de los empleados, puede combinar su propio negocio y configurar el formulario de inspección de calidad adecuado",
          tipTwo:
            "Se pueden establecer reglas adecuadas para las tareas de inspección de calidad, incluida la selección y el muestreo. Después de que el inspector de calidad califica, el servicio al cliente puede ver los resultados de la inspección de calidad y apelar más.",
          addTaskTitle: "Agregar tareas de inspección de calidad",
          deleteTipTitle: "Eliminar tareas de inspección de calidad",
          deleteTipContent:
            "La tarea de inspección de calidad no se puede restaurar después de la eliminación, ¿confirma la eliminación?",
          checkingTask: "Tareas de inspección de calidad",
          checkingTaskList: "Lista de tareas de inspección de calidad",
          formList: "Lista de formularios",
          viewHelpDocuments: "Ver la documentación de ayuda",
        },
        manage: {
          taskSetting: {
            title: "Configuración de tareas",
            form: "Formulario",
            basicInfo: "Información básica",
            visibleRange: "Alcance visible",
            visibleRangeTip:
              "El servicio al cliente y el grupo de servicio al cliente seleccionados pueden ver la tarea de inspección de calidad y, además, se pueden combinar con la configuración de permisos para esta tarea. El líder del equipo de servicio al cliente, el servicio al cliente, el inspector de calidad, etc.",
            automaticSampling: "Muestreo automático",
            automaticSamplingTip:
              "Puede establecer reglas de muestreo automático, de modo que el sistema agregará regularmente tareas de inspección de calidad de acuerdo con las reglas que establezca, como extraer el 5% de las llamadas de la semana pasada todos los lunes",
            samplingRate: "Frecuencia de muestreo",
            samplingTime: "Tiempo de muestreo",
            samplingRange: "Rango de muestreo",
            samplingRule: "Reglas de muestreo",
            fillterCondition: "Condiciones de filtro",
            workflow: "Flujo de trabajo de inspección de calidad",
            appealsTime: "Permitir tiempo de apelación",
            appealsTimeTipOne: "Después de enviar la calificación",
            appealsTimeTipTwo: "Dentro de los días",
            appealsTimeTipThree:
              'Más allá del tiempo de apelación permitido, el estado de inspección de calidad del registro se modifica automáticamente a "confirmado"',
            auditTime: "Tiempo de procesamiento de auditoría",
            auditTimeTipOne: "Después de presentar una queja",
            auditTimeTipTwo: "Dentro de los días",
            auditTimeTipThree:
              'Más allá del tiempo de procesamiento de la auditoría, el registro mantiene la puntuación original y el estado de inspección de calidad se modifica automáticamente a "confirmado"',
            noAppealsMode: "Modo sin apelación",
            appealsMode: "Permitir el modo de apelación",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Servicio al cliente",
            customerServicesGroup: "Grupo de servicio al cliente",
            surveyList: "Lista de evaluación",
            customFields: "Campos personalizados",
            fieldConfig: "Configuración de campo",
            from: "De",
            owner: "Responsable",
            inspectorAndReviewerAuthority:
              "Autoridad de revisión de inspección de calidad",
            inspector: "Inspector de calidad",
            reviewer: "Reevaluación",
            inspectorTip: "El inspector de calidad puede calificar la tarea",
            reviewerTip: "Los revisadores pueden calificar la tarea",
            dataStatisticsAuthority: "Permisos de estadísticas de datos",
          },
          samplingRecords: {
            title: "Registro de muestreo",
            index: {},
            manualSampling: {
              title: "Muestreo",
              fillterCondition: "Condiciones de filtro",
              samplingRule: "Reglas de muestreo",
              agent: "Servicio al cliente",
              type: "Tipo",
              result: "Resultados",
              evaluate: "Evaluación",
              relayNumber: "Número de relevo",
              source: "Fuente",
              task: "Tarea",
              queueState: "Estado de la cola",
              callHanger: "Cuelgue la llamada",
              queueTime: "La cola lleva mucho tiempo",
              talkTime: "Duración de la llamada",
              bellTime: "Duración del timbre",
              recordCountTipOne: "Total",
              recordCountTipTwo: "Registro",
              customerServices: "Servicio al cliente",
              customerServicesGroup: "Grupo de servicio al cliente",
              surveyList: "Lista de evaluación",
              customFields: "Campos personalizados",
              fieldConfig: "Configuración de campo",
              defaultFields: "Campo predeterminado",
            },
          },
          taskList: {
            title: "Lista de tareas",
            index: {
              batchDelete: "Eliminación por lotes",
              batchSubmit: "Presentación por lotes",
              batchConfirm: "Confirmación de lote",
              itemFields: "Campos de proyecto de puntuación",
              categoryFields: "Campos de clasificación de segundo nivel",
              forbidsAndDeadlies: "Prohibido y mortal",
              scoreFieldList: "Lista de campos de elementos de puntuación",
              customerName: "Nombre del cliente",
              cellphone: "Teléfono",
              email: "Correo electrónico",
              organization: "Empresa",
              description: "Descripción",
              tags: "Etiqueta",
              taskUserRole: "Rol de usuario de tarea actual",
            },
            workBench: {
              title: "Detalles del registro",
              checkForm: "Formulario de inspección de calidad",
              waitEvaluate: "A evaluar",
              alreadyEvaluate: "Evaluado",
              waitSubmit: "Per de presentación",
              alreadySubmit: "Presentar",
              waitConfirm: "Por confirmar",
              alreadyConfirm: "Confirmado",
              waitReview: "A revisar",
              alreadyReview: "Revitado",
              alreadyDone: "Completado",
              comment: "Observaciones de evaluación",
              totalScore: "Puntuación total",
              score: "Puntos",
              remark: "Observaciones",
              changeRecords: "Registro de cambios",
              subject: "Tema",
              secondLevelScore: "Calificación de segundo nivel",
              deadlyList: "Fatal",
              forbidList: "Prohibido",
              addToCheckTask: "Agregar a la tarea de inspección de calidad",
              submitCheckRemark:
                "Presentar evaluación de inspección de calidad",
              changeCheckRemark: "Evaluación de inspección de calidad revisada",
              doneCheckRemark: "Evaluación de inspección de calidad completada",
              submitCheckReview: "Presentar revisión de inspección de calidad",
              submitCheckAppeal: "Presentó una queja de inspección de calidad",
              saveFlowSuccess: "Evaluar el éxito.",
              saveFlowFailed:
                "La evaluación falló, inténtelo de nuevo más tarde.",
              FormDetail: "Detalles del formulario",
              callDetail: "Detalles del registro de llamadas",
              sampleCalllogDetail: "Detalles del registro",
              appealReasonTip: "Ingrese el motivo de la apelación (requerido)",
              appeal: "Reclamación",
              appealReason: "Motivos de la queja",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Formulario de inspección de calidad",
        basicInformationTitle: "Información básica",
        indicatorsProjectTitle: "Proyecto de indicadores",
        specialProjectTitle: "Artículos especiales",
        totalScore: "Puntuación total",
        indicatorsName: "Nombre del indicador",
        score: "Puntuación",
        singleScore: "Puntos individuales",
        weight: "Pesos",
        maxDeductScore: "Hasta puntos deducidos",
        add: "Aumento",
        detail: "Detalles del formulario",
        list: {
          title: "Formulario de inspección de calidad",
          tipOne:
            "Configurar el formulario de inspección de calidad es el primer paso en el trabajo de inspección de calidad. Puede configurar el formulario de inspección de calidad más adecuado en combinación con el negocio de la empresa.",
          tipTwo:
            "Apoyamos una variedad de formularios, como puntuación directa, puntuación de peso y sistema de deducción. Se recomienda que establezca la regla antes de agregarla al sistema.",
        },
        basicInformation: {
          formTemplate: "Plantilla de formulario",
          directScore: "Puntuación directa",
          weightedScore: "Puntuación de peso",
          deductScore: "Deducción de puntos",
          directScoreSecondLevel: "Puntuación directa-Nivel 2",
          weightedScoreSecondLevel: "Puntuación de peso-Nivel 2",
          deductScoreSecondLevel: "Deducción-Nivel 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Prohibido",
          banProjectTip:
            "Se puede utilizar para marcar la puntuación de la inspección de calidad. Puede considerarse como una infracción o como un complemento de los comentarios, sin afectar la puntuación real.",
          deadlyProject: "Fatal",
          deadlyProjectTip:
            "El elemento fatal se puede usar para verificar cuando se marca la puntuación de inspección de calidad. Si se marca la puntuación fatal, la tarea de inspección de calidad puntuará directamente 0 puntos",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Inspección de calidad de llamada",
          entiretyReportTitle: "Informe general",
          serviceGroupReportTitle: "Informe del grupo de servicio al cliente",
          serviceReportTitle: "Informe de atención al cliente",
          entiretyReport: {
            pandect: "Resumen",
            contrast: "Contraste",
            trend: "Tendencias",
            analyze: "Análisis",
            totalScore: "Puntuación total",
            banProject: "Prohibido",
            deadlyProject: "Fatal",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Lista de resultados de inspección de calidad",
          taskSelectPlaceholder: "Selección de tareas de inspección de calidad",
          agentSelectPlaceholder: "Elija servicio al cliente",
          refresh: "Actualizar",
          all: "Todo",
          unread: "Sin leer",
          inComplaint: "En la denuncia",
          reviewed: "Revitado",
          totalScore: "Revisión general",
          detail: "Detalles de inspección de calidad",
          audioText: "Grabación de texto",
          callInfo: "Información de la llamada",
          ticketInfo: "Información de la orden de trabajo",
          wechatInfo: "Información de la sesión",
          dialogText: "Texto de diálogo",
          dialogInfo: "Mensaje de conversación",
          customer: "Cliente",
          agent: "Servicio al cliente",
          none: "Sin datos de inspección de calidad",
          appealSuccess: "¡La apelación es exitosa!",
          appealFailed: "La apelación falló, ¡inténtelo de nuevo más tarde!",
          pointListIsNull:
            "¡El punto de apelación de inspección de calidad no puede estar vacío!",
          pleaseSelectPoint: "¡Elija el punto de inspección de calidad!",
          unsupportedFormat: "Este formato no es compatible",
          shardingInformation: "La siguiente es la fragmentación {0}",
          smartTags: "Etiquetas inteligentes",
          businessRecords: "Registros comerciales",
          selectedPointsWarning:
            "Marque los elementos de inspección de calidad para apelar, especifique las declaraciones relevantes para los elementos de apelación y luego haga clic en Enviar.",
          applyReason: "Motivo de la apelación (máximo 50 palabras)",
          chatMessageTypes: {
            all: "Todo",
            text: "Texto",
            image: "Imágenes",
            agree: "Aceptar el contenido de la sesión",
            video: "Vídeo",
            voice: "Voz",
            file: "Archivo",
            emotion: "Expresión",
            link: "Enlace",
            chatrecord: "Historial de chat",
            mixed: "Mensajes mixtos",
            default: "Mensaje no soportado",
            deleteFriend: "Eliminar amigos",
            redpacket: "Sobre rojo",
            card: "Tarjetas de visita",
            disAgree: "No estar de acuerdo con el contenido de la sesión",
            weapp: "Pequeño programa",
            externalRedpacket: "Sobre rojo interoperable",
            meetingVoicevCall: "Mensaje de llamada",
            uRL: "Página web",
            replyTimeout: "Respuesta de tiempo de espera",
            sphfeed: "Número de vídeo",
            chatRecordTitle: "Historial de chat",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Puntuación total",
            actualScore: "Calificación",
            actualGrade: "Calificación",
            appeal: "Reclamación",
            remark: "Observaciones manuales",
            none: "Sin detalles de inspección de calidad",
            pointEdit:
              "Clasificación de edición de puntos de inspección de calidad",
          },
          changeList: {
            title: "Registro de cambios",
            user: "Usuarios",
            point: "Punto de inspección de calidad",
          },
          relatedRecord: {
            title: "Registros relacionados",
            qualityList: "Tareas de inspección de calidad",
            none: "Ninguno",
            check: "Ver",
            loadMore: "Cargar más",
          },
        },
      },
      grade: {
        title: "Calificación de inspección de calidad",
        subTitle:
          "La calificación de inspección de calidad se utiliza para clasificar los objetos de inspección de calidad después de que se completa la puntuación de inspección de calidad. Por ejemplo, se puede configurar por debajo de 60 para no estar calificado, 60-85 para bueno y superior a 80 para excelente.",
        list: {
          up: "Mover hacia arriba",
          down: "Mover hacia abajo",
          del: "Eliminar",
          case: "Unirse automáticamente a la biblioteca de casos",
          orderCase: "Biblioteca de casos predeterminada",
          info: "La calificación predeterminada no tiene condiciones coincidentes y no se puede eliminar. Si no cumple con ninguna de las calificaciones anteriores, obtendrá la calificación predeterminada",
          add: "Agregar una calificación",
          save: "Guardar",
          close: "Cancelación",
          max: "Se pueden configurar hasta 20 calificaciones de inspección de calidad",
          name: "Sin nombre",
          success: "Ahorre con éxito",
        },
      },
      qualityInspectionList: {
        title: "Lista de inspección de calidad",
        fastFilter: "Violaciones de inspección de calidad",
        fastFilterTip:
          "El golpe del sistema de reducción de puntos es una violación, el fracaso del sistema de puntos es una violación",
        titleWechat: "Lista de análisis",
        titleCollect: "Lista de colección",
        status: "Estado de análisis",
        reQualityInspectionConfig:
          "Opciones de tarea de reinspección de calidad",
        pleaseSelectRecheckPoints:
          "Seleccione el punto de inspección de calidad que necesita volver a calcular",
        beginReQualityInspection: "Iniciar la ejecución",
        cancelReQualityInspection: "Cancelar tareas",
        reQualityInspection: "Re inspección de calidad",
        reQualityInspectionWechat: "Reanálisis",
        reQualityInspectionUnderWay:
          "En la tarea de reinspección de calidad...",
        reQualityInspectionUnderWayTip:
          "Dejar la página después de cerrar la ventana no afectará la tarea",
        reQualityInspectionDone:
          "Se ha completado la nueva inspección de calidad",
        reQualityInspectionDoneTip:
          "La nueva inspección de calidad se ha completado correctamente, haga clic en el botón Confirmar",
        endReQualityInspection: "Terminación de tareas",
        alreadyDoneTip: "Completado {0}",
        totalTip: "Total {0} Artículo",
        endReInspectionSuccess:
          "La tarea de reinspección de calidad ha finalizado",
        closeWindow: "Cerrar la ventana",
        endReInspectionFailed:
          "La tarea de inspección de calidad falló, ¡vuelva a intentarlo más tarde!",
        detail: {
          title: "Detalles de inspección de calidad",
          wechatTitle: "Detalles del análisis",
          titleCollect: "Detalles de la colección",
          callIndex: "Llamada asociada",
          textIndex: "Conversación relacionada",
          fileConfirm: "¿Confirmar el archivo?",
          fileConfirmDesc:
            "La puntuación de inspección de calidad no se puede editar después de archivar, confirme si archivar",
        },
        sysCloumnsContal: "Gestión de campos de columna del sistema",
        personCloumnsContal: "Gestión de campos de columnas personales",
      },
      spotCheckList: {
        title: "Lista de muestreo",
        onlyMyTip: "Solo mira mi inspección aleatoria",
        plInspector: "Seleccione un inspector",
        allInspector: "Ver todo",
        seInspector: "Ver el inspector designado",
        myInspector: "Solo mira mi",
        noInspector: "Ver inspectores no asignados",
        detail: { title: "Detalles de muestreo" },
      },
      reviewList: {
        title: "Lista de revisión",
        onlyMyTip: "Solo mira mi revisión",
        plInspector: "Seleccione un revisador",
        allInspector: "Ver todo",
        seInspector: "Ver revisadores designados",
        myInspector: "Solo mira mi",
        noInspector: "Ver revisadores no asignados",
        detail: { title: "Detalles de la revisión" },
      },
      caseBaseList: {
        title: "Biblioteca de casos",
        del: "Eliminar",
        delTitle: "Todo el contenido no se guarda después de la eliminación",
        cancel: "Cancelación",
        actions: "Operación",
        seeCaseBase: "Ver caso",
        seeDetail: "Ver detalles",
        edit: "Edición",
        save: "Guardar",
        addCaseBase: "Nueva biblioteca de casos",
        export: "Exportar",
        ok: "Determinar",
        tip: "Los datos típicos de llamadas y conversaciones en la lista de inspección de calidad se pueden guardar en la biblioteca de casos para facilitar el aprendizaje y el intercambio.",
        detail: {
          choiceCaseBase: "Seleccione el caso",
          delCaseBase: "Eliminar caso",
          addCaseBase: "Agregar un caso",
          successed: "Ahorre con éxito",
          info: "Guardar o cancelar el contenido que se está editando primero",
          caseType: "Seleccione la biblioteca de casos a la que desea unirse",
        },
      },
      testSetList: {
        title: "Conjunto de pruebas de inspección de calidad",
        tip: "Los datos de la inspección aleatoria por parte del inspector de calidad se almacenarán en el conjunto de pruebas de inspección de calidad, lo cual es conveniente para optimizar la configuración de las condiciones de inspección de calidad y las reglas de inspección de calidad para algunos errores de inspección de la máquina comparados después de la inspección aleatoria.",
        refresh: "Actualizar",
        reQualityInspectionUnderWay: "En la tarea de reevaluación...",
        reQualityInspectionUnderWayTip:
          "Dejar la página después de cerrar la ventana no afectará la tarea",
        reQualityInspectionDone: "Reevaluación completada",
        reQualityInspectionDoneTip:
          "La reevaluación se ha completado correctamente, haga clic en el botón Confirmar",
        endReQualityInspection: "Terminación de tareas",
        alreadyDoneTip: "Completado {0}",
        totalTip: "Total {0} Artículo",
        endReInspectionSuccess: "Misión de reevaluación terminada",
        closeWindow: "Cerrar la ventana",
        endReInspectionFailed:
          "La tarea de reevaluación falló, ¡inténtelo de nuevo más tarde!",
        recheckPoint:
          "Vuelva a calcular los puntos de inspección de calidad y la tasa de precisión",
        recheckPointTitle: "Opciones de reevaluación",
        delete: {
          reQualityInspectionUnderWay: "Tareas de eliminación por lotes...",
          reQualityInspectionUnderWayTip:
            "Dejar la página después de cerrar la ventana no afectará la tarea",
          reQualityInspectionDone:
            "Se completó la tarea de eliminación por lotes",
          reQualityInspectionDoneTip:
            "La eliminación por lotes se ha completado correctamente, haga clic en el botón Confirmar",
          endReQualityInspection: "Terminación de tareas",
          alreadyDoneTip: "Completado {0}",
          totalTip: "Total {0} Artículo",
          endReInspectionSuccess:
            "La tarea de eliminación por lotes ha finalizado",
          closeWindow: "Cerrar la ventana",
          endReInspectionFailed:
            "La tarea de eliminación por lotes falló, ¡inténtelo de nuevo más tarde!",
        },
        reultList: {
          assessmentTime: "Última evaluación",
          assessmentAll: "Evaluación total",
          strip: "Artículo",
          spot: "Punto",
          deviation: "Desviación",
          accuracyRate: "Precisión",
          info: "Sin resultado de evaluación, evalúe",
          onlyDeviationInfo: "Ver solo el registro de desviación",
          againAssessment: "Reevaluación",
          screen: "Filtrar",
          batchDelete: "Eliminación por lotes",
          matching: "Coincidencia",
          ok: "Sí.",
          noCancel: "No.",
        },
      },
      smartWordsLibrary: {
        title: "Tesauro inteligente",
        tip1: "Sinónimos: se usa para reducir la cantidad de entrenamiento para etiquetas semánticas, oraciones similares para sinónimos sin entrenamiento repetido",
        tip2: "Términos profesionales: optimización de la transferencia y optimización de la comprensión del lenguaje natural para nombres propios como nombres comerciales inexactos, nombres de productos y vocabulario de la industria, para mejorar la precisión del reconocimiento y la precisión del análisis.",
        tip3: "Palabras de atención: preste atención a las palabras a las que desea prestar atención en el análisis de la nube de palabras para ayudar a los puntos calientes de análisis de IA a cumplir con el negocio actual",
        tip4: "Bloquear palabras: bloquee las palabras a las que no desea prestar atención en el análisis de nube de palabras para ayudar a los puntos calientes de análisis de IA a cumplir con el negocio actual",
        tip5: "Peso de la palabra: se utiliza para corregir y optimizar el reconocimiento de etiquetas semánticas. Al modificar el peso de influencia de la palabra en la oración, el efecto de reconocimiento semántico en un escenario específico se optimiza según sea necesario",
        tip6: "Datos de corpus: ingrese texto de párrafo grande para sus respectivos escenarios comerciales, que se utiliza para la corrección de errores de transcripción y el entrenamiento ASR para mejorar la precisión de la transcripción ASR",
        upload: {
          uploadSynonymWords: "Sinónimos de importación",
          uploadProfessionalWords: "Importación de términos profesionales",
          uploadAttentionWords: "Importar palabras de interés",
          uploadIgnoreWords: "Importar palabras ignoradas",
          uploadWordsWeight: "Importar palabras de peso",
          uploadTypo: "Importar un conjunto de errores tipográficos",
          uploadCorpusData: "Importar datos de corpus",
          uploadCorpusContent: "Importar contenido de datos de corpus",
          firstUploadStep:
            "1. Descargue la plantilla y complete el formato de la plantilla.",
          downloadTemplateMessage: "Descargar plantilla:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Haga clic en el botón Importar para cargar el archivo EXCEl que se ajuste al formato de plantilla",
          secondUploadCorpusDataStep:
            "2. Haga clic en el botón Importar para cargar el archivo TXT que se ajuste al formato de plantilla",
          postUploadDataFail: "Error al enviar datos de carga",
          pleaseUploadTemplate: "Sube el archivo EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "Las plantillas de archivo solo pueden estar en formato JSON o xlsx",
          templateNeedTxt:
            "Plantilla de archivo de datos de corpus sólo puede ser un archivo TXT",
          uploadSuccess: "Importación exitosa",
          uploadFailed: "Error de importación",
          downloadError: "Error de descarga",
          downLoadTxtTitle: "Contenido del corpus. txt",
        },
        navTabNames: {
          synonymWords: "Sinónimos",
          professionalWords: "Términos profesionales",
          attentionWords: "Palabras de atención",
          ignoreWords: "Ignorar las palabras",
          wordsWeight: "Peso de la palabra",
          corpusData: "Datos de corpus",
          typoLibrary: "Colección de errores tipográficos",
        },
        synonymWords: {
          createSynonymWords: "Nuevo sinónimo",
          editSynonymWords: "Editar sinónimos",
          addSynonymWords: "Añadir sinónimos",
          pleaseEnterStandardWords: "Por favor ingrese la palabra estándar",
          synonymWordsExists: "El sinónimo ya existe y no se puede agregar",
          synonymWordsCanNotNull:
            "Los sinónimos agregados no pueden estar vacíos",
          pleaseAddSynonymWords: "Por favor agregue sinónimos",
          addSuccess: "Nuevo éxito",
          editSuccess: "Modificación exitosa",
          deleteSuccess: "La eliminación se realizó correctamente",
          deleteError: "Error de eliminación",
          required: "*",
        },
        professionalWords: {
          create: "Nuevo término profesional",
          edit: "Edición de términos profesionales",
          addTargetWords: "Agregar palabras de reemplazo",
          targetWordsExists:
            "La palabra de reemplazo ya existe y no se puede agregar",
          targetWordsCanNotNull:
            "Las palabras de reemplazo agregadas no pueden estar vacías",
          pleaseAddTargetWords: "Por favor agregue palabras de reemplazo",
          pleaseEnter: "Ingrese términos profesionales",
          wordsExists: "El término profesional ya existe",
          createSuccess: "Agregar con éxito",
          editSuccess: "Modificación exitosa",
          deleteSuccess: "La eliminación se realizó correctamente",
          deleteError: "Error de eliminación",
          saveError: "Error de guardar",
          addTargetWordsMaxNumberError:
            "Las palabras de reemplazo se pueden agregar hasta 100",
          addHomophonyWordsMaxNumberError:
            "Las palabras bloqueadas pueden agregar hasta 100",
        },
        attentionWords: {
          create: "Nueva palabra de seguimiento",
          edit: "Siga la edición de palabras",
          pleaseEnter: "Por favor ingrese la palabra de interés",
          wordsExists: "La palabra de preocupación ya existe",
          createSuccess: "Agregar con éxito",
          editSuccess: "Modificación exitosa",
          deleteSuccess: "La eliminación se realizó correctamente",
          deleteError: "Error de eliminación",
        },
        ignoreWords: {
          create: "Nueva palabra ignorada",
          edit: "Ignorar la edición de palabras",
          pleaseEnter: "Ingrese la palabra ignorada",
          wordsExists: "La palabra ignorada ya existe",
          createSuccess: "Agregar con éxito",
          editSuccess: "Modificación exitosa",
          deleteSuccess: "La eliminación se realizó correctamente",
          deleteError: "Error de eliminación",
        },
        wordsWeight: {
          createWordsWeight: "Nueva palabra de peso",
          editWordsWeight: "Editar palabra de peso",
          addWordsWeight: "Agregar palabras de peso",
          pleaseEnterWeightWords: "Por favor ingrese la palabra de peso",
          pleaseEnterWeight: "Por favor ingrese el peso",
          weightNeedInZeroToHundred: "El peso debe estar en 0 ~ Entre 100",
          synonymWordsExists: "La palabra de peso ya existe",
          weightNeedNumber: "Los pesos necesitan un formato entero",
          standardWeightInformation:
            "El peso de referencia es 50, cuanto mayor es el peso, mayor es la influencia en el análisis",
          createSuccess: "Agregar con éxito",
          editSuccess: "Modificación exitosa",
          deleteSuccess: "La eliminación se realizó correctamente",
          deleteError: "Error de eliminación",
        },
        corpusData: {
          createCorpusData: "Nuevos datos de corpus",
          editCorpusData: "Editar datos de corpus",
          addCorpusData: "Añadir datos de corpus",
          pleaseEnterCorpusDescription: "Ingrese la descripción del corpus",
          pleaseEnterCorpusContent: "Ingrese el contenido del corpus",
          synonymWordsExists: "Los datos del corpus ya existen",
          CorpusContentWordsOverSetting:
            "El recuento de palabras del contenido supera las 20.000 palabras,",
          download: "Descargar",
          upload: "Subir",
          see: "Ver",
          canNotSave:
            "Utilice la función de importación, de lo contrario no se puede guardar",
          createSuccess: "Agregar con éxito",
          editSuccess: "Modificación exitosa",
          deleteSuccess: "La eliminación se realizó correctamente",
          deleteError: "Error de eliminación",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Palabra correcta",
            wrongWord: "Palabras equivocadas",
            wrongWordCount: "Número de palabras incorrectas",
            wrongWordTip: "Presione la tecla Enter para agregar",
            status: "Eficacia",
          },
          create: "Nuevo conjunto de errores tipográficos",
          edit: "Editar un conjunto de errores tipográficos",
          tip: 'El conjunto de palabras de errores tipográficos se utiliza para optimizar el efecto de la "detección de errores tipográficos". Por ejemplo, si "reembolso" se envía por error como "reembolso", puede usar esta función para establecer errores tipográficos comunes y palabras correctas. Tenga en cuenta que entrará en vigencia al día siguiente después de guardar.',
          onlyChinese: "Solo admite la entrada de palabras chinas",
          wrongWordLengthErr:
            "La longitud de cada palabra incorrecta debe ser 2 ~ 10 palabras",
        },
      },
      semanticTags: {
        title: "Etiquetas semánticas",
        current: "Etiqueta semántica actual",
        reference1: "Tarea de inspección de calidad: {0} - {1}",
        reference2: "Etiqueta inteligente: {0}",
        reference3: "Preprocesamiento de datos: {0}",
        reference4: "Base de conocimientos: {0}",
        index: { import: "Importar etiquetas semánticas" },
        detail: {
          new: "Nueva etiqueta semántica",
          edit: "Editar etiquetas semánticas",
          name: "Nombre",
          standardSentences: "Oración estándar",
          effectiveness: "Eficacia",
          similarSentences: "Oración similar",
          tip: "Soporte para llamar a la API de la entidad con # #, ejemplo: ¿Su número de teléfono es # sys_phone #?",
          excludeSimilarSentences: "Excluir oraciones similares",
          intelligentRecommend: "Contenido similar recomendado inteligente",
          fastTraining: "Entrenamiento rápido:",
          qualify: "Recreación",
          recallKeyWord: "Palabras clave de recuperación",
          excludeKeywords: "Excluir palabras clave",
          excludeKeywordsInfo1:
            "Excluir palabras clave, la regla tiene prioridad sobre toda la lógica, si se excluye el golpe, la etiqueta semántica no se golpeará",
          keyWordInfo1:
            "Las palabras clave de recuperación admiten el llenado de varias líneas, separadas por comas en inglés, y la lógica es todos los golpes al completar varias.",
          keyWordInfo2:
            "Las palabras clave de recuperación admiten el llenado de varias líneas, y la lógica de completar varias líneas es cualquier golpe.",
          recallRegular: "Recall regular",
          excludeRegular: "Excluir la regla",
          similaSentences: "Excluir oraciones similares",
          simim: "Similitud",
          keyWordInfo3:
            "La exclusión de oraciones similares tiene prioridad sobre toda la lógica. Si el golpe excluye oraciones similares, la etiqueta semántica no se golpeará. La similitud predeterminada es del 80%. Cuanto mayor sea la similitud, más similar.",
          keyWordInfo4:
            "Las reglas son similares a las regulares, el principio y el final existen por defecto. No debe usarse con corchetes, como [no XXXX]",
          keyWordInfo5:
            "Las funciones del módulo avanzado suelen ser funciones experimentales para expertos que están familiarizados con las operaciones. Si no está seguro de cómo debe hacerlo, le recomendamos que no cambie esta sección.",
          keyWordInfo6:
            "La función de detección de oraciones cruzadas le da a las etiquetas semánticas la capacidad de detectar oraciones en todas partes.",
          keyWordInfo7: "A lo sumo en el mismo rol continuo",
          keyWordInfo8:
            "Ignore los diferentes roles, la longitud de la oración no es mayor que",
          keyWordInfo9:
            "Oraciones de palabras para detección de oraciones cruzadas",
          keyWordInfo10:
            "El límite de oración cruzada más largo no es mayor que",
          keyWordInfo11: "El porcentaje similar de eliminación no es menor que",
          keyWordInfo12: "La similitud no es menor que",
          keyWordInfo13:
            "% De oraciones similares se emparejarán de manera similar (80% de similitud predeterminada)",
          keyWordInfo14:
            "La reducción similar se combinará de acuerdo con la similitud de la oración. Se recomienda eliminar oraciones demasiado similares para mejorar la eficiencia de la detección.",
          keyWord: "Palabras clave",
          entity: "Entidad",
          ruleField: "Palabras conceptuales",
          customRule: "Diseño de reglas de habla",
          fanhua: "Preguntas similares",
          test: "Prueba",
          testOk: "Prueba de golpe",
          senior: "Configuración avanzada",
          seniorList: "Detección de oraciones cruzadas",
          up: "Habilitar",
          down: "Cerrar",
          ju: "Oración",
          oneWord: "Palabras",
          simimNo: "Peso similar",
          seniorField: "Umbral de similitud",
          save: "Guardar",
          cancel: "Cancelación",
          specialElements: "Elementos especiales",
          logicRule: "Reglas lógicas",
          every: "Múltiples coincidencias",
          recallOk: "Palabras de recuerdo:",
          recallNo: "Recall fallando",
          ruleOk: "Golpea el retiro regular",
          ruleNo: "Mal golpe",
          rularOk: "Reglas de golpe",
          rularNo: "Reglas de fracaso",
          infoOk: "Golpea la oración estándar o similar:",
          infoNo: "Frase estándar fallada o similar",
          fieldOk: "Golpear para excluir oraciones similares:",
          fieldNo: "Frase fallida para excluir oraciones similares",
          excludeRuleNo: "Deséxito excluido regular",
          excludeRuleOk: "Golpe para excluir regular",
          excludeKeywordNo: "Palabras clave de exclusión fallidas",
          excludeKeywordOk: "Palabras clave de exclusión de aciertos",
          update: "Actualización exitosa",
          fanhuaList:
            "Lista de oraciones similares enviadas a la página de generalización",
          wordList:
            "La lista de entrenamiento está vacía y no se puede enviar a la lista generalizada",
          start: "Empezar",
          end: "Fin",
          keyWordInfo15:
            "Hay campos similares en la lista de generalización (la configuración avanzada es similar a la restricción de reducción)",
          saveSuccess: "Ahorre con éxito",
          errInfo: "Hay campos no completados o completados incorrectamente",
        },
        table: {
          deepTraining: "Entrenamiento profundo {0} rueda",
          fastTraining: "Entrenamiento rápido",
        },
      },
      intelligentCollection: {
        title: "Adquisición inteligente",
        classification: "Lista de grupos",
        new: "Nueva colección",
        table: {
          name: "Nombre del campo",
          props: "Atributos del cliente",
          type: "Tipo de campo",
          desc: "Descripción del campo",
        },
        detail: {
          isCustomerProps: "Guardar en Propiedades del cliente",
          customerPropsHelper:
            "Después de guardar, la información recopilada se actualiza a la información del cliente. Si no se guarda, no se actualizará a la información del cliente",
          name: "Nombre del campo",
          type: "Tipo de campo",
          text: "Texto",
          desc: "Descripción del campo",
          descPlaceHolder:
            "Describir la utilidad de la información recopilada en el campo",
          condition: "Condiciones",
          logic: "Reglas",
          method: "Método de recolección",
          message: "Contenido informativo",
          range: "Rango de extracción",
          entity: "Entidad de extracción",
          base: "Información básica",
          collect: "Recopilación de información",
        },
      },
      intelligentTags: {
        title: "Etiquetas inteligentes",
        sessionLabelTitle: "Escena de conversación",
        keyEventsTitle: "Eventos clave",
        customerLabelTitle: "Etiqueta de cliente",
        addClassification: "Crear una nueva categoría de etiquetas",
        defaultCategoryName: "Clasificación predeterminada",
        index: {
          createTag: "Nueva etiqueta",
          createEvent: "Nuevo evento",
          editTagClassification: "Editar categorías de etiquetas",
          selectTagClassification: "Seleccionar una categoría de etiqueta",
          editTagGrounp: "Edición de grupos",
          callTimeError:
            "El intervalo de tiempo de conversación/inicio de conversación seleccionado no puede exceder de un mes (31 días)",
          callRecordCountError:
            "El número de registros es 0, vuelva a seleccionar el tiempo de llamada/conversación",
          selectItmeCountError:
            "Seleccione las etiquetas inteligentes que deben realizarse en lotes",
          redoSuccess:
            "La reejecución se realizó correctamente, vaya al Centro de tareas para ver",
          redoError:
            "No se pudo volver a ejecutar, vuelva a intentarlo más tarde",
          timeError:
            "El tiempo de consulta no puede exceder de un mes (31 días)",
          categoryName: "Nombre de la categoría",
          categoryColor: "Color de la categoría",
          newTagClassification: "Nueva clasificación de etiquetas",
          newEventClassification: "Nueva clasificación",
          tagClassification: "Clasificación de etiquetas",
          eventClassification: "Clasificación de eventos",
        },
        detail: {
          new: "Nueva etiqueta inteligente",
          edit: "Editar etiquetas inteligentes",
          tagName: "Nombre",
          tagDesc: "Descripción",
          category: "Clasificación",
          tagStatus: "Eficacia",
          operator: "Operador",
          rule: "Reglas",
          labelClassAtion: "Tipo de etiqueta",
          labelClassAtionTip1: "Etiquetado: etiquetado basado en reglas",
          labelClassAtionTip2:
            "Etiquetas de categoría: etiquetado basado en el entrenamiento de modelos",
          group: "Agrupación",
          checkLabel: "Seleccionar categorías",
          warn: "Alerta temprana",
          warnTips:
            "Después de activar la advertencia, cuando la sesión cumpla con esta regla de evento clave, se le pedirá a la persona designada que reciba un recordatorio en la lista de tareas",
          warnError: "Seleccione la persona designada para la alerta temprana",
        },
        subTitle: {
          common: "Etiqueta de sesión inteligente",
          wecom: "Etiqueta de microempresa",
        },
      },
      customerPortrait: {
        title: "Retrato del cliente",
        index: { import: "Importar retratos de clientes" },
        searchPlaceHolder: "ID de cliente, apodo o teléfono",
        callCount: "Número de conversaciones:",
        callDuration: "Duración total de la llamada:",
        dialogCount: "Número total de diálogos:",
        keyWordsStatistics:
          "Estadísticas de palabras clave (Top 20 y palabras de seguimiento)",
        keyWordsCount: "({0})",
        businessTag: "Etiqueta de negocio",
        keyWordsCloudImg: "Mapa de nube de palabras clave",
        customerEmotionTrend: "Tendencia de cambio de sentimiento del cliente",
        notFoundCustomer: "No se ha recuperado ningún cliente relevante",
        noKeyWords: "No se han consultado palabras clave relacionadas",
        noBusinessTags: "No se consultan etiquetas comerciales relacionadas",
        getKeyWordsStatisticsDataError:
          "La consulta de estadísticas de palabras clave del cliente falló",
        getBusinessTagsStatisticsDataError:
          "Error en la consulta de la etiqueta comercial del cliente",
        personCustome: "No se consultó la información del cliente",
        notimeInfo: "No se encontró información emocional",
        timeList: "Rango de fechas",
        operator: "Etiquetas inteligentes",
        placeHolder: "Por favor elija",
        placeOperator: "Seleccione la etiqueta inteligente",
        search: "Consulta",
        reset: "Restablecer",
        searchHolder: "Búsqueda",
        call: "Llamada",
        text: "Diálogo",
      },
      sentimentAnalysis: { title: "Descubrimiento de la opinión pública" },
      saleSentimentAnalysis: { title: "Análisis de la opinión pública" },
      dataPreprocess: {
        title: "Preprocesamiento de datos",
        index: {
          createData: "Nuevos datos",
          selectDataSource: "Seleccionar una fuente de datos",
          selectStatus: "Eficacia de elección",
          callTimeError:
            "El intervalo de tiempo de conversación/inicio de conversación seleccionado no puede exceder de un mes (31 días)",
          callRecordCountError:
            "El número de registros es 0, vuelva a seleccionar el tiempo de llamada/conversación",
          selectItmeCountError:
            "Seleccione los datos que deben realizarse en lotes",
          redoSuccess:
            "La reejecución se realizó correctamente, vaya al Centro de tareas para ver",
          redoError:
            "No se pudo volver a ejecutar, vuelva a intentarlo más tarde",
        },
        detail: {
          new: "Nuevos datos",
          edit: "Edición de datos",
          name: "Nombre",
          desc: "Descripción",
          dataSource: "Fuente de datos",
          status: "Eficacia",
          entity: "Entidad de extracción",
          operator: "Operador",
          rule: "Reglas",
        },
      },
      connectedByConversation: {
        title: "Conconexión de diálogo",
        list: {
          title: "Función de asociación de diálogo",
          info: "La función de asociación de diálogo puede asociar diferentes llamadas con conversaciones de acuerdo con las reglas preestablecidas",
          rule: "Reglas de asociación de diálogo",
          source: "Seleccione la fuente de datos",
          classification: "Por favor seleccione la categoría",
          choose: "Por favor elija",
          input: "Por favor ingrese",
          save: "Guardar",
          cancel: "Cancelación",
          fieldwei: "Bun",
          fieldNot: "Hay campos sin completar",
          fiedldlengthNo: "Longitud del campo por encima del límite",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Se pueden configurar varias palabras clave, separadas por comas",
          applyRole: "Detectar roles",
          preOperator: "Operador frontal",
          applyOperatorScope: "Rango de detección",
          addOperator: "Agregar operador",
          noPreOperator: "Ninguno",
          from: "No.",
          to: "Oración a la primera",
          end: "Oración",
          testRegular: "Probar expresiones regulares",
          test: "Prueba",
          matchSuccess: "Partido exitoso",
          matchError: "Partido fallido",
          querySementicTags: "Seleccionar etiquetas semánticas",
          reQuerySementicTags: "Vuelva a seleccionar las etiquetas semánticas",
          sementicTag: "Etiquetas semánticas",
          standardSentence: "Oración estándar",
          similarity: "Similitud",
          actions: "Operación",
          noFitTag: "No se encontró la etiqueta adecuada,",
          addSemanticTag: "Añadir una nueva etiqueta semántica",
          pleaseEnterRegular: "Ingrese la expresión regular",
          pleaseEnterRegularText: "Ingrese lo que necesita ser verificado",
          newAssociateComponentTip:
            "Si el valor es de tipo numérico, utilice la versión anterior de la función",
        },
        filterManage: {
          noValueTip: "Sin datos",
          allCall: "Todas las llamadas",
          allDialogue: "Todo el diálogo",
          allWechat: "Todas las sesiones",
          getFiltersListFailed:
            "Obtiene la lista de filtros fallidos, ¡inténtalo de nuevo más tarde!",
          getFilterDetailFailed:
            "Obtiene los detalles del filtro fallido, ¡inténtalo de nuevo más tarde!",
          getAllCountFailed:
            "Obtiene el número total de llamadas/conversaciones fallidas, ¡inténtelo de nuevo más tarde!",
        },
        listManage: {
          searchPlaceHolder: "Por favor ingrese la palabra clave para buscar",
          filterConditions: "Condiciones de filtro",
          records: "Registro",
          getRecordsFailed:
            "No se pudo obtener el registro, ¡inténtelo de nuevo más tarde!",
        },
        miniListManage: {
          getRecordsFailed:
            "No se pudo obtener el registro, ¡inténtelo de nuevo más tarde!",
        },
        applyTemplates: {
          title: "Plantilla de inspección de calidad aplicable",
          collectionTitle: "Plantilla de colección aplicable",
        },
        recordDetail: {
          showWaveAudio: "Mostrar grabación",
          getDetailFailed:
            "Obtener detalles falló, por favor inténtelo de nuevo más tarde!",
          getSmartTagsFailed:
            "No se pudo obtener la etiqueta inteligente, ¡inténtelo de nuevo más tarde!",
          getTemplatesFailed:
            "No se pudo obtener la plantilla aplicable, ¡inténtelo de nuevo más tarde!",
        },
        changeList: {
          sampleStatus: "Estado de muestreo",
          sampleAssignInspector: "Asignación de inspectores",
          reviewAssignInspector: "Asignación de revisadores",
          read: "Leer",
          distributivePerson: "Asignación",
          totalScore: "Puntuación total",
        },
        qualityCheckScore: {
          totalScore: "Puntuación total",
          actualScore: "Puntuación",
          actualGrade: "Calificación",
          appeal: "Reclamación",
          remark: "Observaciones",
          manualRemark: "Observaciones manuales",
          none: "Sin detalles de inspección de calidad",
          interactiveTip:
            "Utilice la identificación interactiva para detectar el uso de las preguntas frecuentes de servicio al cliente",
          interactiveIsEmptyTip:
            "Contenido de reconocimiento de interacción no detectado",
        },
        semanticMark: {
          semanticContent: "Oración de etiqueta",
          markedSimilarTitel: "Etiquetado con etiquetas semánticas similares",
          markedExcludeTitle: "Etiquetas semánticas similares excluidas",
          taskTagTitle: "Etiquetas semánticas relacionadas con tareas",
          similarTagTitle:
            "Identificar etiquetas semánticas similares (similitud> 40%,Top 20)",
          delete: "Eliminar",
          markSimilar: "Similar a las marcas",
          markExclude: "Excluir similitudes",
          none: "Ninguno",
        },
        selectCallTime: {
          callTime: "Tiempo de conversación/Hora de inicio de sesión",
          callTimeRemind:
            "Puede seleccionar un registro de la conversación de un solo pase no más de un mes (31 días) en el último año",
          callRecordCount:
            "Ha seleccionado un total de {0} registros de llamadas/conversaciones",
          getCallRecordError:
            "Obtiene el error de registro, inténtelo de nuevo más tarde",
        },
      },
      informationEntities: {
        title: "Entidades de información",
        index: { import: "Importar entidades de información" },
        detail: {
          new: "Crear una nueva entidad de información",
          edit: "Editar la entidad de información",
          name: "Nombre",
          apiName: "Nombre de la API",
          activeFlag: "Eficacia",
          remark: "Descripción",
          logicExp: "Entidad regular",
          sentenceList: "Contenido de la entidad",
          tip: "El contenido de la entidad admite entradas múltiples, separadas por espacios, sinónimos de la misma entidad",
          remarkLengthError:
            "La longitud de la descripción no puede exceder 255",
          nameLengthError: "La longitud del nombre no puede exceder 40",
          entityDuplicateError: "Repetición de contenido de entidad",
        },
      },
      interactiveRecognition: {
        title: "Reconocimiento de interacción",
        classify: "Clasificación",
        addClassify: "Añadir categoría",
        editClassify: "Edición de categorías",
        index: { import: "Importar reconocimiento de interacción" },
        detail: {
          new: "Nueva interacción inteligente",
          edit: "Editar interacción inteligente",
          type: "Tipo",
          standardQuestion: "Cuestiones estándar",
          similarQList: "Problemas similares",
          exceptSimilarQList: "Excluir problemas similares",
          category: "Clasificación",
          targetType: "Objetivo de detección de problemas",
          status: "Eficacia",
          standardAnswer: "Respuestas estándar",
          similarAList: "Respuestas similares",
          wrongAList: "Respuesta incorrecta",
        },
      },
      businessAnalysis: {
        title: "Análisis de negocios",
        tip1: "Análisis de tendencias comerciales: la ordenada es el número de diálogos, es decir, el número total de diálogos que cumplen las condiciones y la abscisa es el tiempo.",
        tip2: "Análisis de correlación: analiza la situación de correlación de múltiples indicadores, la suspensión del mouse muestra la situación de los datos de correlación, la cantidad de datos, la correlación y la tasa de repetición.",
        tip3: "Análisis de conversión: el análisis de múltiples indicadores puede formar una relación de conversión mutua. El criterio de conversión es la misma llamada/diálogo. Si se pueden alcanzar dos indicadores diferentes, se puede considerar que estos dos indicadores tienen una relación de conversión.",
      },
      knowledgeBase: {
        title: "Base de conocimientos",
        list: {
          add: "Agregar subcategorías",
          delete: "Eliminar categorías",
          categoryName: "Nombre de la categoría",
          createSuccess: "La subcategoría se creó con éxito",
          keyWord: "Palabras clave",
        },
        detail: {
          type: "Tipo",
          status: "Estado",
          classification: "Clasificación",
          standardQuestion: "Cuestiones estándar",
          answerDetectionRange: "Rango de detección de respuesta",
          standardAnswer: "Respuestas estándar",
        },
      },
      callManage: {
        title: "Filtro de grabación de llamadas",
        listTitle: "Gestión de llamadas",
        detailTitle: "Detalles de la llamada",
      },
      dialogueManage: {
        title: "Filtro de texto de diálogo",
        listTitle: "Gestión de la conversación",
        detailTitle: "Detalles de la conversación",
      },
      weChatManage: {
        title: "Filtro de conversación Enterprise WeChat",
        listTitle: "Gestión de la conversación WeChat empresarial",
        detailTitle: "Detalles de la conversación corporativa de WeChat",
      },
      speechRecognition: {
        title: "Reconocimiento de voz",
        asr: "Reconocimiento de voz",
        asrRemind:
          "Apagar este interruptor provocará la falla de la función de voz a texto y la función de inspección de calidad de la máquina",
        asrConfig: "Filtración ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Después de habilitar, se ejecutará la transcripción ASR de IM",
        asrConfigRemind:
          "Cuando está habilitado, solo las llamadas elegibles realizan contenido ASR",
        correction: "Corrección de errores inteligente",
        correctionRemind:
          "Una vez habilitado, utilizará \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words 'target =' _ blank'> Tesauro inteligente-sustantivo profesional </a>\" para forzar la conversión de corrección de errores",
        correctionConfig: "Filtro de corrección de errores",
        advancedSettings: "Configuración avanzada",
        advancedSettingsTip:
          "Seleccione el modelo ASR especificado para los datos especificados.",
        mandarinASRModel: "Mandarín ASR modelo",
        cantoneseASRModel: "Cantonés modelo ASR",
        englishASRModel: "Inglés ASR modelo",
        correctionConfigRemind:
          "Una vez habilitada, solo se corregirán las llamadas que cumplan con las siguientes condiciones",
        customJudgeLogicIsNull: "La lógica personalizada no puede estar vacía",
        redoCorrection: "Vuelva a ejecutar la corrección de errores",
        callTime: "Seleccionar tiempo de conversación",
        callTimeRemind:
          "Puede seleccionar un registro de llamadas de hasta un mes (31 días) en el último año",
        callRecordCount:
          "Ha seleccionado un total de {0} registros de llamadas",
        redoCorrectionSubmit: "Presentar la ejecución",
        callTimeError:
          "El intervalo de tiempo de llamada seleccionado no puede exceder de un mes (31 días)",
        callRecordCountError:
          "El número de registros de llamadas es 0, vuelva a seleccionar el tiempo de llamada",
        redoCorrectionSuccess:
          "La corrección de errores se realizó correctamente, vaya al centro de tareas para ver",
        redoCorrectionError:
          "No se pudo corregir el error, vuelva a intentarlo más tarde",
      },
      role: {
        title: "Gestión de roles",
        create: "Nueva función",
        detail: {
          title: "Rol",
          new: "Nueva función",
          view: "Ver los roles",
          edit: "Editar un rol",
          navTabNames: {
            basicInfo: "Permisos de menú",
            taskPermissions: "Autoridad de tarea de inspección de calidad",
            dataPermissions: "Permisos de datos",
          },
          basicInfo: { roleName: "Nombre", permissions: "Permisos" },
          taskPermissions: {
            allTask: "Todas las tareas de inspección de calidad",
            myCreatedTask: "Las tareas de inspección de calidad que creé",
          },
          dataPermissions: {
            dataPermissionsConfig: "Configuración de permisos de datos",
            tips: "El rol puede ver los datos en la categoría seleccionada",
            select: "Seleccionar categorías",
            selectPlaceholder:
              "Seleccione la clasificación que requiere permisos de visibilidad de datos",
          },
        },
      },
      dataMigration: {
        title: "Migración de datos",
        dataUpload: "Traslado de datos",
        dataDowload: "Traslado de datos",
        error:
          "Seleccione la tarea de inspección de calidad que desea exportar",
        list: {
          info: "Seleccione la tarea de inspección de calidad que desea mudarse",
          listInfo:
            "Seleccione si desea migrar los datos relevantes de la tarea de inspección de calidad (como tesauro inteligente, etiquetas semánticas, entidades de información, interacciones inteligentes, etc.)",
          noInfo:
            "Seleccione si desea mover otros datos que no sean de calidad",
          ownInfo: "Solo mude la misión",
          everyInfo: "Relacionado con la tarea de mudanza",
          allInfo: "Traslado de todos",
          callFilter: "Filtros de llamadas",
          talkFilter: "Filtros de conversación",
          smartTag: "Etiquetas inteligentes",
        },
        upload: {
          info: "1. Haga clic en el botón de carga para cargar el archivo JSON que se ajusta al formato",
          uploadSuccess: "Subir correctamente",
          uploadError: "Error de carga",
        },
      },
      dataAnalysis: {
        businessStatistics: "Estadísticas de negocios",
        qualityEffect: "Calidad de la salud",
        qualityAnalysis: "Análisis de inspección de calidad",
        generalInspecitionReport: "Informe general",
        fullAmountWork: "Carga de trabajo del inspector de calidad",
        intelligentTagsAnalysis: "Análisis de etiquetas",
        saleIntelligentTagsAnalysis: "Análisis inteligente de etiquetas",
        generalInspecitionReportTip:
          "Total de datos: dentro del límite actual de tiempo y alcance de la tarea, la cantidad total de datos de llamadas y diálogos, incluido el número y porcentaje de datos de inspección de calidad inteligente, el número y porcentaje de datos de inspección aleatoria manual, el número de quejas, la tasa de reinspección y el gráfico de tendencia de tiempo",
        groupTest: "Prueba de grupo",
      },
      uploadDownloadList: {
        title: "Centro de tareas",
        tabs: {
          upload: "Cargar importación",
          download: "Descargar exportación",
          taskCenter: "Ejecución de tareas",
        },
      },
      uploadDownloadModel: {
        title: "Cargar y descargar",
        list: {
          clear: "Vacío",
          success: "Éxito",
          fail: "Fracaso",
          hand: "En progreso",
          noInfo: "Sin datos",
        },
      },
      billingCenter: {
        title: "Centro de facturación",
        export: "Exportar",
        limit: "Límites de recordatorio",
        tips: "Los resultados de la facturación se desviarán debido al redondeo, solo como referencia",
        dashboard: {
          remainingQuota: "Cuota restante actual",
          usedQuota: "Cuota acumulada utilizada",
          voiceQuality: "Inspección de calidad por voz:",
          voice: "Voz:",
          textQuality: "Inspección de calidad del texto:",
          text: "Texto:",
          hour: "Horas",
          tenThousandHour: "Diez mil horas",
          day: "Día",
          cutoff: "Fecha límite",
          warning:
            "(La cuota restante es insuficiente, recargue lo antes posible)",
          isClosed: "(Estado cerrado)",
        },
        table: {
          quotaRecord: "Registro de cuotas",
          timeFilter: "Fecha de selección:",
          search: "Consulta",
          reset: "Restablecer",
          id: "Número de serie",
          type: "Tipo",
          qualityType: "Tipo de inspección de calidad",
          quotaChange: "Cambios",
          surplus: "Saldo",
          changeTime: "Tiempo",
          voiceQuality: "Inspección de calidad por voz",
          textQuality: "Inspección de calidad de texto",
          sizeChangeText: "Visualización por página",
          used: "Usar",
          give: "Otorgamiento",
          deduction: "Deducción",
          callTime: "Tiempo de conversación",
          count: "Volumen de procesamiento de datos",
          page: "Total {0} artículos,{1} páginas",
        },
        limitModal: {
          call: "La cuota de inspección de calidad de voz es menor que",
          callFix: "Horas para recordar",
          dialogue:
            "El tiempo restante de la inspección de calidad del texto es menor que",
          dialogueFix: "Día para recordar",
        },
      },
      messageCenter: {
        replyTitle: "Responder a mi",
        answerTitle: "@ Mía",
        shareTitle: "Comparte mi",
      },
      columnsManage: { title: "Gestión de campos de inspección de calidad" },
      recordUpload: {
        list: {
          task: "Tareas de inspección de calidad",
          user: "Operadores",
          uploadTime: "Tiempo de carga",
          endTime: "Tiempo de finalización",
          total: "Total de cargas",
          success: "Inspección de calidad exitosa",
          failed: "Fallo en la inspección de calidad",
          progress: "Progreso de la inspección de calidad",
          inProgress: "En progreso",
          finished: "Completado",
        },
        steps: {
          uploading: "Subir archivo de grabación",
          baseInfo: "Rellene la información básica",
          start: "Iniciar inspección de calidad",
        },
        uploadComponent: {
          dragger: "Arrastre y suelte para agregar archivos",
          or: "O",
          selectFile: "Seleccionar archivos",
          des: "Admite formato wav, mp3, frecuencia de muestreo de 8000Hz, archivos de 16 bits",
          limit1: "Subir como máximo a la vez",
          limit2: "Archivos, cada tamaño de archivo no excede",
          uploading: "Subir",
          finished: "Subir completado",
          success: "Éxito",
          failure: "Fracaso",
        },
        baseInfo: {
          task: "Selección de tareas de inspección de calidad",
          type: "Elegir un formato de grabación",
          agentSide: "El canal de atención al cliente",
          excel: "Cargar datos en el camino",
          checkFileInfo: "Verificación de la información del archivo",
          excelFileError: "Sube los datos de seguimiento",
          checking: "Verificación...",
          reCheck: "Vuelva a verificar",
          success: "Verificación exitosa",
          excelUoloadFail: "Error de carga de datos en la carretera",
          tips: {
            tip1: "1. El nombre del archivo de grabación en ecxel debe coincidir con el nombre de la grabación cargada y no puede tener el mismo nombre",
            tip2: "2. El sistema verifica la duplicación con el nombre del archivo de grabación y la identificación de la llamada, y la repetición hará que falle la inspección de calidad.",
            tip3: "3. Descargue la plantilla de grabación de Excel, el color de fuente es rojo es un campo obligatorio",
            tip4: "4. El formato de campo para el tipo de tiempo es: yyyyy-mmm-dd hh:mm:ss, por ejemplo, 2021-04-20 17:36:30",
          },
        },
        title: "Carga de grabación local",
        checkTitle: "Inspección de calidad de grabación",
        start: "Iniciar inspección de calidad",
        disabledTips:
          "Verifique el archivo de grabación antes de realizar la inspección de calidad.",
        tips: "El archivo de grabación del mismo nombre cubre los resultados de la inspección de calidad y la información básica",
        tips1: "Arrastre y suelte el archivo aquí o haga clic en Subir",
        tips2: "Apoyo",
        tips3: "Formato",
        type: "wav、mp3",
        tips4: "Subir como máximo a la vez",
        tips5: "Archivos, cada tamaño de archivo no excede",
        tipsOverSize:
          "La cola actual está llena, elimine los archivos en la cola antes de cargarlos",
        size: "500M",
        count: "20",
        uploaded: "Archivos de grabación cargados",
        uploading: "({0}%)",
        uploadFinished: "(Carga completada)",
        bottomTitle: "Recopilación de información de grabación:",
        recordType: "Seleccione el formato de grabación:",
        double: "Grabación de llamadas de dos canales",
        single: "Grabación de llamadas de un solo canal",
        recordBaseInfo: "Sube la información básica de la grabación:",
        addBtn: "Añadir un archivo",
        downloadTemplate: "Descargar plantilla. excel",
        checkRecord: "Verificación de información de grabación",
        checking: "Verificación...",
        reCheck: "Vuelva a verificar",
        checkAll: "Todo seleccionado",
        cancelCheckAll: "Cancelar todas las selecciones",
        delete: "Eliminar",
        deleteLotSize: "Eliminación por lotes",
        agentChannel: "Canal de atención al cliente:",
        left: "Canal izquierdo",
        right: "Canal derecho",
        donotKonw: "No lo sé.",
        mission: "Seleccione la tarea de inspección de calidad:",
        uploadFail: "Error de carga",
        repeat: "Archivos duplicados",
        overCount: "El número de archivos supera el límite",
        overSize: "Tamaño de archivo fuera del límite",
        uoloadFail: "Error de carga en la nube",
        formatError: "Error de formato",
        seccess: "Verificación exitosa",
        fail: "Error de verificación",
        recordChecking:
          "Durante la inspección de calidad de grabación, espere...",
        recordCheckSuccess: "Inspección de calidad de grabación completada",
        recordCheckFail:
          "La inspección de calidad de grabación falló, verifique si la grabación es duplicada o no es válida",
        backToIndex: "Volver a la lista de carga",
        recordCheckIsStarted:
          "Actualmente se está realizando una tarea de inspección de calidad, comience una nueva tarea después de que finalice la tarea actual",
      },
      customerServicePortrait: {
        title: "Retrato de servicio al cliente",
        tip1: "Ley de distribución de la longitud de la oración: la distribución de la longitud de la oración se cuenta de acuerdo con el número de palabras de la longitud de la oración, y solo se calcula el servicio al cliente.",
        tip2: "La ley de distribución de la velocidad del habla: la distribución de la velocidad del habla se calcula de acuerdo con la velocidad del habla (palabra/minuto), y solo se calcula el servicio al cliente.",
        tip3: "Ley de distribución del tiempo de respuesta: la distribución del tiempo de respuesta, solo calcula la respuesta del servicio al cliente al cliente",
        tip4: "Cumplimiento de inspección de calidad: tasa de cumplimiento de puntos de inspección de calidad",
        tip5: "Puntuación media de las tareas de inspección de calidad: puntuación media de las tareas de inspección de calidad",
        contol: {
          date: "Fecha de selección:",
          today: "Hoy",
          thisMonth: "Este mes",
          lastMonth: "El mes pasado",
          thisQuarter: "Este trimestre",
          lastQuarter: "El último trimestre",
          thisYear: "Este año",
          lastYear: "El año pasado",
          customer: "Servicio al cliente:",
          search: "Consulta",
          reset: "Restablecer",
          export: "Exportar",
          indicator: "Gestión de indicadores",
          selectPlaceholder:
            "Seleccione el servicio al cliente que necesita comparar",
          warning: "Elija hasta 5 servicios al cliente para comparar",
          warning2: "Seleccione al menos 1 servicio al cliente para consultar",
          dataWarning:
            "Solo admite consultas de datos hasta 366 días desde la fecha de inicio",
          seccuss: "La exportación de registros se realizó correctamente",
        },
        echarts: {
          distributionLaw: "Ley de distribución",
          count: "Número de veces",
          bout: "VEsegundo",
        },
        portrait: {
          ability: "Dimensiones de capacidad de servicio al cliente",
          option: "Configuración",
          abilityOption: "Dimensiones de capacidad de configuración",
          placeholder:
            "Búsqueda de palabras clave de dimensión de capacidad de entrada",
          save: "Guardar",
          warning:
            "Seleccione al menos 3 indicadores para configurar, puede elegir hasta 10 indicadores",
        },
        label: { title: "Etiqueta comercial de servicio al cliente" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Clasificación de servicio al cliente",
        select: {
          date: "Fecha de selección:",
          today: "Hoy",
          thisMonth: "Este mes",
          lastMonth: "El mes pasado",
          thisQuarter: "Este trimestre",
          lastQuarter: "El último trimestre",
          thisYear: "Este año",
          lastYear: "El año pasado",
          group: "Grupo de servicio al cliente:",
          groupPlaceholder: "Seleccione el grupo de servicio al cliente",
          customer: "Servicio al cliente:",
          customerPlaceholder: "Elija servicio al cliente",
          search: "Consulta",
          reset: "Restablecer",
          compared: "Contraste",
          export: "Exportar",
          column: "Columna",
          warning: "Elija hasta 5 servicios al cliente para comparar",
          warning2: "Elija al menos un servicio al cliente",
          dataWarning:
            "Solo admite consultas de datos hasta 366 días desde la fecha de inicio",
          selected: "Servicio al cliente seleccionado:",
          seccuss: "La exportación de registros se realizó correctamente",
        },
        compared: {
          title: "Elija servicio al cliente",
          return: "Cancelación",
          compared: "Contraste",
          tips: "Seleccione 2-5 servicios de atención al cliente para comparar",
          warning: "Solo admite 2-5 servicios al cliente para comparar",
        },
        transfer: {
          leftTitle: "Elemento de lista",
          rightTitle: "Visualización",
        },
        table: {
          page: "Total {0} artículos,{1} páginas",
          actionTitle: "Operación",
          action: "Ver",
        },
      },
      conceptWord: {
        title: "Palabras conceptuales",
        new: "Nueva palabra conceptual",
        control: {
          search: "Búsqueda:",
          searchPlaceholder: "Búsqueda por nombre o palabra clave",
          commit: "Consulta",
          reset: "Restablecer",
          import: "Importar",
          export: "Exportar",
          seccuss: "La exportación de registros se realizó correctamente",
        },
        modal: { title: "Importar palabras conceptuales" },
        table: {
          sizeChangeText: "Visualización por página",
          page: "Total {0} artículos,{1} páginas",
          edit: "Edición",
          delete: "Eliminar",
          request:
            "No será recuperable después de la eliminación. ¿Confirmo la eliminación?",
          cancel: "Cancelación",
          title: {
            conceptWord: "Palabras conceptuales",
            describeWordsNum: "Número de palabras de descripción",
            updateTime: "Tiempo de actualización",
            status: "Eficacia",
            effective: "Efectivo",
            invalid: "No válido",
            action: "Operación",
          },
        },
        detail: {
          title: {
            new: "Nueva palabra conceptual",
            edit: "Editar palabras conceptuales",
          },
          name: "Nombre de la palabra conceptual",
          namePlaceholder: "Introduzca el nombre de la palabra conceptual",
          effectiveness: "Eficacia",
          effective: "Efectivo",
          invalid: "No válido",
          description: "Palabras de descripción:",
          descriptionPlaceholder: "Por favor ingrese la palabra descriptiva",
          add: "Añadir",
          repeat: "Contenido duplicado",
          empty: "El descriptivo no puede estar vacío",
          recommendEmpty:
            "No hay más palabras de descripción recomendadas, ingrese manualmente para agregar",
          recommend: "Palabras de descripción recomendadas inteligentes",
          cancel: "Cancelación",
          change: "Cambiar grupo",
          descriptionSelected: "Se han agregado palabras de descripción:",
          save: "Guardar",
          success: "Palabras conceptuales guardadas con éxito",
          warning: "El nombre de la palabra conceptual no puede estar vacío",
          warning2: "Agregue al menos 1 palabra de descripción",
          submitBtnHandle: {
            title:
              "Los términos de descripción seleccionados no se agregan, guardar abandonará los términos de descripción no agregados",
            content:
              "Haga clic en Aceptar para volver a la página de inicio de la palabra conceptual, ¿confirma que continuará?",
          },
        },
      },
      smartTools: {
        btn: "Herramientas inteligentes",
        title: {
          similarWords: "Descubrimiento de palabras relacionadas",
          search: "Oración por palabra",
          semanticTags: "Etiquetas semánticas",
        },
        sentenceSearch: {
          placeholder:
            "Ingrese palabras clave para buscar, admite varias palabras clave",
          more: "Ver texto completo",
          pageUp: "Anterior",
          pageDown: "Siguiente",
          total: "Total {0} registros",
        },
        similarDiscover: {
          placeholder: "Por favor ingrese las palabras clave",
          copyed: "Copiado",
          none: "Sin palabras relacionadas, pruebe otras palabras clave",
        },
        smartTags: {
          searchPlaceHolder: "Ingrese palabras clave u oraciones",
          createSmartTag: "Nuevo",
          createClusteringSmartTag: "Utilice {0} datos para crear uno nuevo",
          addFromKeyword: "Agregar contenido de búsqueda a una oración similar",
          hit: "Golpe",
          similar: "Similar",
          name: "Nombre de la etiqueta semántica",
          namePlaceHolder: "Introduzca el nombre de la etiqueta semántica",
          recallWords: "Palabras de recuerdo",
          recallWordsPlaceHolder: "Ingrese las palabras clave de recuperación",
          recallRegular: "Recall regular",
          recallRegularPlaceHolder: "Ingrese el retiro regular",
          rules: "Reglas",
          standardSentences: "Oración estándar",
          standardSentencesPlaceHolder: "Ingrese la oración estándar",
          similaSentences: "Oración similar",
          similaSentencesPlaceHolder: "Por favor ingrese una oración similar",
          notSaveError: "Guarde la etiqueta semántica antes de buscar",
          add: "Añadir",
          newPage: "Abrir en una nueva página",
          querryError:
            "Hay campos similares en la lista de generalización (la configuración avanzada es similar a la restricción de reducción)",
          detailTitle: "Detalles de la etiqueta semántica",
        },
      },
      trainingCenter: {
        title: "Centro de formación",
        edit: "Edición",
        delete: "Eliminar",
        seleteAll: "Todo seleccionado",
        cancelCheckAll: "Cancelar todas las selecciones",
        add: "Añadir",
        remove: "Quitar",
        save: "Guardar",
        cancel: "Cancelación",
        fastTraining: {
          title: "Entrenamiento rápido",
          titleDes:
            "El entrenamiento rápido está diseñado para ayudar a la generalización rápida de etiquetas semánticas a 20 oraciones similares",
          semanticTags: "Etiquetas semánticas",
          semanticTagsPlaceholder:
            "Ingrese la búsqueda de palabras clave del proceso",
          standardSentence: "Oración estándar:{0}",
          similarSentencesPlaceholder:
            "Introduzca una oración similar para agregar o buscar",
          similarSentencesAdd: "Añadir",
          similarSentencesSearch: "Búsqueda",
          similarSentences: "Se ha agregado una oración similar",
          trainingCount: "Número de entrenamiento:{0}",
          similarSentencesRecommend: "Recomendar oraciones similares",
          noResp:
            "Sin etiquetas semánticas relevantes, cambie las palabras clave",
          similarSentencesModalTitle: "Búsqueda de oraciones similares",
          moreInfo: "Ver contexto",
          moreInfoBtn: "Contexto",
          modalSearchType: {
            all: "Todo",
            customer: "Servicio al cliente",
            client: "Cliente",
          },
          repetWarning:
            "Ya existe la misma oración similar, no agregue repetidamente",
          repetRemoveTip:
            "Guarde el contenido duplicado en oraciones similares, que se ha devuelto automáticamente",
        },
        trainingHistory: {
          title: "Historia de entrenamiento",
          select: {
            date: "Elija el tiempo:",
            today: "Hoy",
            thisMonth: "Este mes",
            lastMonth: "El mes pasado",
            thisQuarter: "Este trimestre",
            lastQuarter: "El último trimestre",
            thisYear: "Este año",
            lastYear: "El año pasado",
            search: "Consulta",
            reset: "Restablecer",
          },
          table: {
            time: "Tiempo",
            user: "Operadores",
            tag: "Etiquetas semánticas",
            similarCount: "Número de marcas de oraciones similares",
            deleteCount: "Quitar el número de oraciones",
            page: "Total {0} artículos,{1} páginas",
          },
        },
        classifiedTraining: {
          title: "Entrenamiento de clasificación",
          tagsGrounp: "Agrupación de etiquetas",
          placeHolder: "Por favor elija",
          cellTime: "Selección de tiempo",
          search: "Consulta",
          reset: "Restablecer",
          classifiedFlag: "Formación de clasificación",
          num: "Valor numérico",
          gas: "Porcentaje",
          noData: "Entrenamiento no clasificado",
          noDataHeight:
            "Los datos no clasificados representan una proporción relativamente alta y se necesita capacitación con urgencia",
          noDataSmall:
            "Los datos no clasificados representan una proporción relativamente alta, se recomienda la formación",
          noDataDays: "{0} días antes de la última ejecución",
          training: "Entrenamiento",
          start: "Empezar",
          addClass: "Incrementar la clasificación",
          custom: "Filtración avanzada",
          marked: "Errata de datos marcada",
          markedHeight:
            "No se ha realizado ninguna errata durante {0} días, se recomienda realizar erratas",
          markedSmall:
            "La errata no se ha realizado durante {0} días y la errata se necesita con urgencia",
          mark: "Errata",
          dataCheck: "Verificación de datos clasificados",
          dataCheckSmall:
            "La divergencia de K-L es superior a 0,5, se recomienda entrenar",
          dataCheckHeight:
            "La divergencia del K-L es superior a 0,8, necesita entrenamiento urgente",
          check: "Verificación",
          history: "Historial de anotaciones",
          historyTime:
            "Las anotaciones y modificaciones diarias ejecutarán automáticamente el entrenamiento del modelo a las 2:00 a.m.",
          today: "Marcado hoy",
          article: "Artículo",
          none: "Ninguno",
          historyCheck: "Anotación total histórica",
          look: "Ver registros",
          historyluate: "Recálculo de datos históricos",
          startluate: "Recálculo",
          classData: "Datos desglosados",
          classStatus: "Progreso del entrenamiento",
          next: "Siguiente",
          prev: "Anterior",
          finish: "Completar el entrenamiento",
          stop: "Termina el entrenamiento",
          classDetail: "Detalles de entrenamiento",
          trainingNone: "Etiqueta inteligente sin entrenamiento",
        },
        classifiedTrainingHistory: {
          title: "Historia de entrenamiento",
          time: "Elija el tiempo:",
          tag: "Tipo de etiqueta:",
          user: "Operador:",
          data: "Seleccione la fuente de datos:",
          search: "Consulta",
          searchBar: "Búsqueda:",
          searchBarPlaceholder:
            "Ingrese el ID de llamada/ID de conversación para buscar",
          reset: "Restablecer",
          export: "Exportar",
          all: "Todo",
          select: {
            today: "Hoy",
            thisMonth: "Este mes",
            lastMonth: "El mes pasado",
            thisQuarter: "Este trimestre",
            lastQuarter: "El último trimestre",
            thisYear: "Este año",
            lastYear: "El año pasado",
            search: "Consulta",
            reset: "Restablecer",
          },
          table: {
            user: "Operador",
            date: "Tiempo de operación",
            data: "Fuente de datos",
            id: "ID de llamada/ID de conversación",
            tagType: "Agrupación de etiquetas inteligentes",
            tagName: "Nombre de la etiqueta",
            state: "Estado",
            sizeChangeText: "Visualización por página",
            page: "Total {0} artículos,{1} páginas",
            call: "Llamada",
            dialogue: "Diálogo",
          },
        },
        clusteringAnnotation: {
          title: "Anotación de conglomerados",
          left: { title: "Resultados de agrupamiento" },
          right: {
            title: "Oraciones de agrupamiento",
            checkAll: "Seleccionar toda esta página",
            tagging: "Anotación",
            detail: "Ver",
            meaningless: "Ignorar",
            meaninglessTips:
              "Los resultados de agrupamiento que se han ignorado ya no se mostrarán en el futuro. ¿Confirma que desea ignorarlos?",
            cancel: "Cancelación",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Gestión de clientes",
          tabTitle: "Clientes del centro de atención al cliente",
        },
        wechatClientManage: {
          title: "Microclientes empresariales",
          table: {
            id: "ID de cliente",
            name: "Apodo del cliente",
            tag: "Etiqueta de cliente",
          },
        },
      },
      collectionRule: {
        title: "Reglas de adquisición",
        form: {
          info: "Recopilación inteligente de información",
          infoPlaceholder: "Por favor seleccione la categoría",
        },
        table: {
          name: "Nombre del campo",
          props: "Atributos del cliente",
          type: "Tipo de campo",
          desc: "Descripción del campo",
        },
      },
      dataAdd: {
        title: "Adición de datos",
        types: { auto: "Añadir automáticamente", manual: "Añadir manualmente" },
      },
      wechatStaff: {
        table: {
          name: "Nombre",
          part: "Departamento",
          phone: "Número de teléfono móvil",
          email: "Correo electrónico",
          wechartId: "ID de WeChat empresarial",
          status: "Estado de servicio",
        },
        info: "Primero seleccione el departamento que necesita sincronizar los datos",
        synSuccess: "Sincronización exitosa",
        init: "Inicialización",
        syn: "Sincronización",
      },
      appealHistory: {
        appealTime: "Tiempo de apelación",
        cancelTime: "Tiempo de revocación",
        reviewTime: "Tiempo de revisión",
        inspector: "Inspector de calidad",
        result: "Resultado de la apelación",
        complainResult:
          "Se apelaron un total de {0} elementos de inspección de calidad, se apelaron con éxito {1} y se rechazaron {2}",
        reason: "Motivos de la denuncia",
        rejection: "Razones para el rechazo",
        cancel: "Revocación",
        tip: "¿Confirmar que desea retirar la apelación?",
      },
      appealSdk: {
        time: "Tiempo de conversación",
        externalIdCall: "ID de registro de llamadas",
        externalIdText: "ID de grabación de conversación",
        customerNickName: "Apodo del cliente",
        readStatus: "Ver estado",
        status: "Estado de la apelación",
        isManual: "Si la inspección manual de calidad",
        complainButtonInfo:
          "Se permiten {0} apelaciones, se han apelado {1} veces y se pueden apelar las {2} restantes. {3} Puede apelar antes",
        submit: "Presentar una queja",
        complain: "Reclamación",
        allComplain: "Todas las quejas",
        pendingSubmit: "Per de presentación",
        taskIds: "Tareas de inspección de calidad",
        graded: "Calificación",
        complainSuccess: "Presentación exitosa de la queja",
        appealSdkIndex: {
          title: "Resultados de inspección de calidad inteligente",
        },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Obtiene la información de la lista de filtros fallida, compruebe la conexión de red o vuelva a intentarlo más tarde.",
      columns: "Columna",
      filter: "Filtrar",
      noSearchResult: "No se encontraron registros",
      pleaseSearch: "Por favor, seleccione un filtro y tratar de buscar",
      sift: "Filtrar",
      create: "Nuevo",
      search: "Búsqueda",
      createTaskTitle: "Nueva tarea",
      title: "Título",
      deadline: "Fecha límite",
      principal: "Responsable",
      enqueueTime: "Tiempo de entrada",
      computeError: "Error de cálculo, por favor más tarde!",
      sum: "La suma",
      max: "Máximo",
      min: "Mínimo",
      avg: "Promedio",
      count: "Recuento",
      maxDate: "Máximo (más reciente)",
      minDate: "Mínimo (más antiguo)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "No se pudo obtener la dirección del enlace de Insight, ¡inténtelo de nuevo más tarde!",
      },
      template: {
        learningReminder: "Recordatorio de aprendizaje",
        subscriptionDynamics: "Noticias de suscripción",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Gestión de tareas", chaneg: "Cambiar" },
        qualityCheckTemplate: {
          text: "Plantilla de inspección de calidad",
          collectText: "Plantilla de colección",
          condition: { text: "Condiciones de inspección de calidad" },
          checkPoint: { text: "Configuración de reglas" },
          automaticAdd: { text: "Automatización de procesos" },
          manualAdd: { text: "Añadir manualmente" },
          roleConfig: { text: "Configuración de roles" },
          basicInfo: { text: "Información básica" },
          smartWordsLibrary: { text: "Tesauro inteligente" },
          grade: { text: "Calificación de inspección de calidad" },
          collectionRule: { text: "Reglas de adquisición" },
          addData: { text: "Añadir datos" },
          collectionList: { text: "Lista de colección" },
        },
        qualityCheckWorkbench: {
          text: "Banco de trabajo de inspección de calidad",
          wechatText: "Banco de trabajo de análisis",
          informationCollectText: "Banco de trabajo de recolección",
          qualityInspectionList: {
            text: "Lista de inspección de calidad",
            wechatText: "Lista de análisis",
            informationCollectText: "Lista de colección",
          },
          condition: { text: "Lista de inspección de calidad (abandonada)" },
          reviewList: { text: "Lista de revisión" },
          spotCheckList: { text: "Lista de muestreo" },
          caseBaseList: { text: "Biblioteca de casos" },
          testSetList: { text: "Conjunto de pruebas de inspección de calidad" },
        },
        statisticsAndReports: {
          text: "Estadísticas e informes",
          wholeReport: { text: "Informe general" },
          groupReport: { text: "Informe del grupo de servicio al cliente" },
          agentReport: { text: "Informe de atención al cliente" },
          customerServiceExecution: { text: "Análisis de servicio al cliente" },
          analysisOverview: { text: "Resumen de análisis" },
          inspectionItemsAnalysis: {
            text: "Análisis de elementos de inspección de calidad",
          },
        },
        wechatQa: {
          text: "Inspección de calidad de WeChat empresarial",
          task: { text: "Tareas de análisis" },
          baseInfo: { text: "Información básica" },
          checkPoint: { text: "Analizar la configuración del modelo" },
          setting: { text: "Configuración de análisis de WeChat empresarial" },
          automaticAdd: { text: "Automatización de procesos" },
          manualAdd: { text: "Añadir manualmente" },
        },
        clientCenter: { text: "Centro de atención al cliente" },
      },
      conditionDictionaries: {
        title: "Todas las condiciones",
        searchDefaultText: "Introduzca su ID o nombre",
        id: "ID",
        name: "Nombre",
        conditionDetailedShow: "Expande",
        conditionDetailedHide: "Abajo",
        remark: "Descripción:",
        checkRole: "Rol:",
        operator: "Operador:",
        operatorLogic: "Lógica:",
      },
      audioTextView: {
        customer: "Cliente",
        agent: "Servicio al cliente",
        unsupportedFormat: "Este formato no es compatible",
      },
      checkPointClassifications: {
        add: "Nueva categoría",
        name: "Nombre",
        enterToSave: "Presione la tecla Enter para guardar",
        highestScore: "Máximo de puntuación",
        minimumScore: "Puntaje mínimo mínimo",
        defaultMinScore: "El valor predeterminado es 0 puntos",
        minimumScoreTitle: "Puntaje mínimo",
        beginScore: "Valor de puntuación general",
        beginScoreTitle: "Valor de puntuación",
        scoreSetTip:
          "El puntaje máximo es menor que la suma de cada puntaje, puede elegir:",
        ignoreExcessSpillover: "Ignorar el exceso de desbordamiento",
        automaticWeightCalculation: "Contabilidad automática de ponderaciones",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Campo" },
    checkRange: {
      numberTipOne: "No.",
      numberTipTwo: "Oración",
      numberTipThree: "Oración a la primera",
      conditionLimit: "Restricciones condicionales",
      roleLimit: "Restricciones de roles",
      contextLimit: "Restricciones de sentencia",
      notZero: "El límite de oración no admite la entrada 0",
      startMustLessThanEnd:
        "La posición de la oración inicial debe ser menor que la posición de la oración final",
    },
    waveAudio: {
      play: "Jugar",
      pause: "Suspensión",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Por favor ingrese",
      pleaseEnterRegular: "Ingrese la expresión regular",
      pleaseEnterGrammar: "Por favor ingrese las reglas de gramática",
      pleaseEnterTestText: "Ingrese lo que necesita ser verificado",
      pleaseEnterLoudness: "Ingrese el decibelio de volumen",
      pleaseEnterRangeAbility: "Introduzca el rango de cambio",
      afterHit: "Compruebe después del golpe",
      pleaseEnterContext: "Ingrese la declaración de referencia",
      wordNumber: "Brecha de palabras",
      intervalSecond: "El intervalo de tiempo es mayor que",
      minute: "Cada minuto",
      wordUnit: "Palabras",
      test: "Prueba",
      testRegular: "Probar expresiones regulares",
      testGrammar: "Prueba de gramática",
      testGrammarError: "Prueba de gramática fallida",
      regularError: "La expresión regular es incorrecta, vuelva a ingresar",
      pleaseEnterSemanticSentence:
        "Ingrese la oración que requiere detección semántica",
      pleaseEnterAssociate:
        "Consulte la descripción de la derecha para construir una expresión de datos en el camino",
      querySemanticTags: "Seleccionar etiquetas semánticas",
      reQuerySemanticTags: "Vuelva a seleccionar las etiquetas semánticas",
      semanticTags: "Etiquetas semánticas",
      standardSentence: "Oración estándar",
      semanticName: "Etiquetas semánticas",
      sentence: "Oración estándar",
      similarity: "Similitud",
      actions: "Operación",
      tipOne: "No se encontró la etiqueta adecuada,",
      tipTwo: "Añadir una nueva etiqueta semántica",
      notLessThan: "No menos que",
      notGreaterThan: "No más grande que",
      ignoreLessThan: "Ignorar menos que",
      wordUnitSet: "Palabra de oración",
      speedWordNotNull:
        "Detección de velocidad del habla El número mínimo de palabras y el número máximo de palabras no pueden estar vacíos",
      speedWordMinValueLessThanMaxValue:
        "Detección de velocidad del habla El número mínimo de palabras no puede ser mayor que el número máximo de palabras",
      speechGrabbingIntervalTip: "Intervalo de conversación> =",
      speechLengthTip: "Agarra la longitud de la oración> =",
      msUnit: "ms",
      sUnit: "Segundos",
      grabIntervalNotNull: "El intervalo de agarre no puede estar vacío",
      grabLengthNotNull: "Las palabras largas no pueden estar vacías",
      pleaseSelectEntity: "Seleccione la entidad de información",
      pleaseSelectOperator: "Por favor seleccione la operación",
      pleaseSelectBasicInfo: "Seleccione la información básica",
      pleaseEnterFixedValue: "Introduzca un valor fijo",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Partido exitoso",
      matchError: "Partido fallido",
      mindetectionStatement: "Declaración de detección mínima",
      conceptWordholder: "Seleccione la palabra conceptual",
      elementHolder: "Por favor ingrese un elemento",
      associateMedol: { tabs: { data: "Datos", operator: "Operadores" } },
      repet: "Repito",
      bout: "VEsegundo",
      ignore: "Ignorar menos que",
      ignoreAffix: "Palabra de oración",
      associateData: "Datos de seguimiento",
      pretreatmentData: "Datos preprocesados",
      reviewTaskData: "Revisar los datos de la tarea",
      taskData: "Datos de tareas de inspección de calidad",
      businessData: "Registros comerciales",
      functionOperator: "Operadores de función",
      operation: "Operadores de operación",
      notAnyMatch: "Cuando falta cualquier problema:",
      times: "Número de apariciones",
      newAssociateDataTip:
        "Si el valor es de tipo numérico, utilice la versión anterior de la función",
    },
    conditionGather: {
      cycle: "Ciclo",
      rlue: "Reglas",
      delete: "Quitar",
      sampling: "Extracción",
      item: "Artículo",
      percent: "%",
      samplingSame: "La misma cantidad por servicio al cliente",
      samplingRecheck:
        "Permitir la extracción repetida de elementos muestreados",
      dateUnit: "Número",
      samplingTip:
        "Las diferentes reglas cubren la misma llamada y no se extraerán repetidamente, y los registros extraídos y no verificados no se volverán a extraer.",
      add: "Nueva regla",
    },
    ruleGather: {
      title: "Reglas",
      screeningCondition: "Condiciones de filtro",
      addRule: "Nueva regla",
      inspector: "Inspector de calidad",
      ruleTip:
        "El modo de asignación de prioridad de la regla, que se asigna de arriba a abajo, no se repite la asignación, y los elementos de regla arbitrarios dentro de la regla o que no coinciden se asignarán aleatoriamente",
      moveUp: "Mover hacia arriba",
      moveDown: "Mover hacia abajo",
      top: "Arriba",
    },
    pushRulesGather: {
      title: "Reglas",
      delete: "Quitar",
      newPush: "Nuevo push",
      newRule: "Nueva regla",
      sampling: "Extracción",
      item: "Artículo",
      percent: "%",
      samplingSame: "La misma cantidad por servicio al cliente",
      dateUnit: "Número",
      screeningCondition: "Condiciones de filtro",
      email: "Correo electrónico",
      emailPlaceholder: "Ingrese el buzón, divida con comas",
      updateType: "Tipo de actualización",
      pushDetail: "Detalles de inserción",
      pushUrl: "URL push",
      pushWay: "Tipo de inserción",
      pushMethod: "Método de empuje",
      pushContentType: "Formato de datos",
      pushData: "Push datos",
      additionalData: "Datos adicionales",
      inspectionType: "Tipo de inspección de calidad",
      all: "Todo",
    },
    taskAdditionalData: {
      newData: "Agregar datos",
      delete: "Quitar",
      placeholder: "Por favor ingrese el nombre",
      dataPlaceholder: "Por favor ingrese el contenido",
    },
    UdeskQaReact: {
      loadSdkError: "La carga del SDK falló, ¡inténtelo de nuevo más tarde!",
    },
    gearOptionList: { evaluation: "Evaluación" },
    tableComponentTransfer: {
      leftTitle: "Elemento de lista",
      rightTitle: "Visualización",
    },
    kmTinymce: {
      link: "Insertar/Editar enlace",
      modalTitle: "Insertar enlace",
      href: "Dirección",
      text: "Mostrar texto",
      title: "Título",
      target: "Modo abierto",
      none: "Ninguno",
      newWindow: "Se abre en una nueva ventana",
      buttonStyle: "Estilos de botón",
      close: "Cerrar",
      open: "Abrir",
      answerMessage: "Saltar/Enviar mensaje",
      answerMessageTitle: "Botón Enviar mensaje",
      answerMessageText: "Mostrar texto",
      answerMessageContent: "Contenido del mensaje",
      answerMessageButtonStyle: "Estilos de botón",
      answerMessageTips:
        "Nota: El botón Enviar mensaje solo es compatible con canales web, configure cuidadosamente",
      answerSwitchStaff: "Saltar/girar manual",
      answerSwitchStaffTitle: "Gire el botón manual",
      answerSwitchStaffText: "Mostrar texto",
      answerSwitchStaffRule: "Reglas de botones",
      answerSwitchStaffButtonStyle: "Estilos de botón",
      answerSwitchStaffTips:
        "Nota: El botón de transferencia manual solo admite canales web, configure cuidadosamente",
      textButton: "Botón de texto",
      borderButton: "Botón con marco",
      message: "Enviar un mensaje",
      switchStaff: "Transferir a mano de obra",
      messageError: "El contenido del botón no puede exceder las 10 palabras",
      messageIsEmptyError: "El contenido del botón no puede estar vacío",
      okText: "Determinar",
      cancelText: "Cancelación",
      video: "Vídeo",
      uploadVideo: "Subir un video",
      uploadText: "Solo admite formato MP4, tamaño no más de 20M",
      videoEmptyOrUploading: "El video está vacío o subido",
      customEmoji: "Expresión",
    },
    massageConfiguration: {
      pushTarget: "Notificar a los objetivos",
      customizePushTarget: "Objetivos de inserción personalizados",
      sysUser: "Usuario actual del sistema",
      sysRole: "Roles actuales del sistema",
      emailPushTarget: "Enviar destino",
      targetEmailsPlaceholder:
        "Ingrese su correo electrónico y use comas en inglés para separar",
      subject: "Nombre del mensaje",
      SDKID: "Identificación del SDK",
      content: "Contenido de la notificación",
      sdkPushType: "Programa push",
      sdkPushURL: "URL push",
      pushData: "Push datos",
      sdkPushWay: "Método de empuje",
      dataType: "Formato de datos",
      additionalData: "Datos adicionales",
      name: "Nombre del mensaje",
      filter: "Condiciones de filtro",
      triggerType: "Mecanismo de activación",
      pushCycle: "Sincronización y frecuencia fija",
      pushContent: "Contenido del informe",
      qualityList: "Lista de resultados de inspección de calidad",
      designated: "Designación de personas",
      messageType: "Tipo de mensaje",
    },
    taskCard: {
      template: {
        cancelCollection: "Cancelar colección",
        collection: "Colección",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Oración estándar:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Colección",
          calls: "Llamadas",
          createdBy: "Creado por:",
          createdOn: "Tiempo de creación:",
        },
        fileItem: {
          uIProvidesIcon: "Ui ofrece icon",
          minute: "Minutos",
          callClip: "Clip de llamada",
          start: "Empezar",
          End: "Fin",
          employees: "Empleados:",
          Customer: "Clientes:",
          sessionTime: "Tiempo de sesión:",
          reasonForRecommendation: "Razón recomendada:",
          addedBy: "Añadido:",
          addTime: "Agregar tiempo:",
        },
        modalAddFile: {
          caseTitle: "Título del caso",
          addSomeClipsToTheCaseLibrary:
            "Agregar algunos clips a la biblioteca de casos",
          addToCaseBase: "Agregar a la biblioteca de casos",
          storedIn: "Almacenado en",
          pleaseSelect: "Por favor elija",
          reasonsForRecommendation: "Razón recomendada",
          pleaseEnter: "Por favor ingrese",
        },
      },
      shareModal: {
        index: {
          customer: "Clientes:",
          employees: "Empleados:",
          timeliness: "Eficacia:",
          recordingLinkValidWithinDays:
            "El enlace de grabación es válido dentro de los días",
          sharingInstructions: "Compartir instrucciones:",
          shareSuccess: "¡Comparta el éxito!",
          cancel: "Cancelación",
          share: "Compartir",
          copySucceeded: "¡Copia exitosa!",
          copyShareLink: "Copiar enlace para compartir",
          shareWithColleagues: "Compartir con colegas",
          shareWithCustomers: "Compartir con los clientes",
          staff: "Empleados",
          selectColleagues: "Selección de colegas",
          pleaseSelectColleaguesToShare: "Seleccione un colega para compartir",
          pleaseEnterTheSharingDescription:
            "Ingrese las instrucciones para compartir",
          taskName: "Tarea:",
          recordingShareValidWithinDays:
            "Compartir enlace dentro de los días es válido",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "¡No se puede encontrar el archivo!",
          sale: "Ventas:",
          customer: "Clientes:",
          topicDetection: "Detección de temas:",
          keyEvents: "Eventos clave:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Selección de colegas",
          helpContent: "Contenido de ayuda",
          visibleRange: "Alcance visible",
          seekCounseling: "Buscando asesoramiento",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Buscando consejo", concatText: "Para" },
      },
      customerInsight: {
        index: {
          customerInformation: "Información del cliente",
          customerInsight: "Información del cliente",
          customerLabel: "Etiqueta de cliente",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Recomendación",
          objectionHandlingScripts: "Manejo de objeciones",
          productSellingPointScripts: "Punto de venta del producto",
          knowledgePointRecommendation: "Puntos de conocimiento recomendados",
          relatedKnowledgePoints: "Puntos de conocimiento relacionados",
          recommendedCases: "Caso recomendado",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Recomendación",
          caseRecommendation: "Recomendación de caso",
          hit: "Golpe",
          miss: "Sin hits",
          supervisionItem: "El elemento de supervisión",
          implementation: "Aplicación",
          reInspection: "Re inspección de calidad",
          supervisionItemSettings: "Configuración del elemento de supervisión",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Llamadas telefónicas",
          enterpriseWeChatCall: "Micro llamada empresarial",
          enterpriseMicroConversation: "Micro conversación empresarial",
          open: "Abrir",
          close: "Cerrar",
          conversationScenario: "Escena de conversación",
          monitorConversationChannels: "Supervisar canales de sesión",
          permanentlyValid: "Efectivo permanente",
          permanentlyInvalid: "Inválido permanente",
          customValidity: "Período de validez personalizado",
          termOfValidity: "Período de validez",
          newRating: "Nueva calificación",
          whenConversation: "Cuando la sesión",
          hit: "Golpe",
          miss: "Sin hits",
          time: "Cuando",
          rated: "La calificación es",
          qualified: "Calificado",
          unqualified: "No calificado",
          modifiedSuccessfully: "Modificación exitosa",
          classificationName: "Nombre de la categoría",
          supervisionObject: "Objeto de supervisión",
          entryintoforceTime: "Tiempo de vigencia",
          supervisionItemRating: "Calificación del elemento de supervisión",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Compruebe si la calificación del elemento de supervisión es correcta",
          ratingRules: "Reglas de calificación",
          settingOfRatingRulesForSupervisionItems:
            "Configuración de reglas de calificación de elementos de supervisión",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Eventos clave",
          supervisionItem: "El elemento de supervisión",
          conversationScenario: "Escena de conversación",
          customerLabel: "Etiqueta de cliente",
          reDetectionRules: "Regla de redetección",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Descargar la grabación",
          downloadText: "Descargar texto",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Subir imágenes",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Seleccione una imagen con un tamaño no superior a 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "O",
            and: "Y",
            wrong: "No",
            matchTo: "Coincidir con",
            rule: "Reglas",
            satisfactionOperator: "Satisfacer al operador",
            customerLabel: "Etiqueta de cliente",
            keyEvents: "Eventos clave",
            knowledgePoints: "Puntos de conocimiento",
            sessionLabel: "Etiqueta de sesión",
            supervisionItem: "El elemento de supervisión",
          },
          linkExplorer: { linkInTheText: "Enlace en el texto:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound:
          "No se encontró ningún componente personalizado:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "Personalización" },
      dropdownMonit: {
        updateSucceeded: "Actualización exitosa",
        areYouSureToDelete: "¿Está seguro de eliminar?",
        custom: "Personalización",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Guarde la configuración del informe actual === Formulario",
        saveCurrentReportConfiguration:
          "Guardar la configuración actual del informe",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Ingrese el nombre de configuración común, ¡la longitud máxima es 20!",
        preservation: "Guardar",
      },
      template: {
        saveCurrentReportConfiguration:
          "Guardar la configuración actual del informe",
        updateCurrentReportConfiguration:
          "Actualizar la configuración actual del informe",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Información de la orden de trabajo",
        workOrder: "Hoja de trabajo",
        triggerScenario: "Escena de activación",
        associatedCallConversation: "Relacionar llamadas/conversaciones",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Pruebe con el texto anterior/diálogo",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "No.", day: "Día" },
      businessSummary: {
        normal: {
          equalTo: "Igual a",
          notEqualTo: "No es igual a",
          contain: "Contiene",
          excluding: "No contiene",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Soy yo, es un fuego artificial diferente @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hola, soy un aviso de aviso de advertencia de inspección de calidad de robot, porque se agregó al grupo el 17 de octubre",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Soy yo, es un fuego artificial diferente @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hola, soy un aviso de aviso de advertencia de inspección de calidad de robot, porque se agregó al grupo el 17 de octubre",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Temas de comunicación",
          conversationSummary: "Resumen de la conversación",
          serviceSuggestions: "Recomendaciones de servicio",
        },
        template: { smartTags: "Etiquetas inteligentes" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Haga clic para saber más" },
      schema: {
        textRobot: "Robot de texto",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Escolta de entrenador, preguntas y respuestas de documentos, diálogo basado en tareas, preguntas y respuestas de formularios, preguntas y respuestas de gráficos",
        capitalRetentionRobot: "Robot de retención",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Abundantes estrategias de seguimiento y retención de capital, alta comunicación antropomórfica, garantiza una alta tasa de retención de capital y una conversión automática eficiente de pistas",
        outboundRobot: "Robot exhalado",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Visitas de revisión de finalización de órdenes de trabajo, investigación de satisfacción del servicio, avisos de advertencia de fallas de operación y mantenimiento, recordatorios de entrega de pedidos, notificaciones de eventos de comercio electrónico, citas de candidatos para ayudar a la automatización empresarial y mejorar la eficiencia empresarial",
        incomingRobot: "Llamar al robot",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Integración flexible de órdenes de trabajo, pedidos y otros sistemas comerciales para realizar la automatización de procesos; reconocimiento personalizado de modelos semánticos de voz, efectos comerciales inmediatos; entidades (número de teléfono móvil, monto del pedido, tiempo del pedido, dirección,...) Identificación, soporte rápido para negocios complejos",
        intelligentQualityInspection: "Inspección de calidad inteligente",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Inspección de calidad completa, oportuna y eficiente, con una tasa de precisión del 85%",
        intelligentSessionAnalysis: "Análisis de sesión inteligente",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Obtenga información sobre las deficiencias comerciales, escuche las voces de los clientes y mejore la conversión de marketing",
        enterpriseWechatConversationAnalysis:
          "Análisis de conversación empresarial WeChat",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Identifique de manera eficiente las oportunidades comerciales potenciales y los riesgos, y aumente la tasa de conversión de marketing en un 20%",
        assistant: "Asistente de asiento",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "El asistente del agente puede garantizar que el servicio al cliente novato complete el servicio y mejore la calidad de las llamadas de servicio al cliente. Haz que cada asiento sea tu carta de triunfo",
        digitalPeople: "Personas digitales",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          'Comunicación antropomórfica "cara a cara", admite interacción multimodal de sonido, texto, video y tacto, realiza una experiencia de comunicación inmersiva y mejora la imagen de marca y la experiencia de servicio al usuario',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Inteligencia artificial Knowledge Zhongtai · Búsqueda empresarial",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Búsqueda de conocimientos, intercambio de conocimientos, búsqueda de documentos, búsqueda de acoplamiento de bases de datos",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Estación de conocimientos de IA · Base de conocimientos de KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Proporcionar gestión del ciclo de vida completo del conocimiento, mantenimiento unificado de robots y bases de conocimiento de mensajería instantánea, soporte para acoplar bases de conocimiento de terceros, plantillas de conocimiento, conocimiento atómico y otras funciones poderosas para brindar una experiencia de gestión del conocimiento más conveniente",
        aIKnowledgeCenterKnowledgeMap:
          "Plataforma de conocimiento de IA · Atlas de conocimiento",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "La construcción de mapas de ventanilla única integra el conocimiento corporativo, el panorama general muestra los detalles del conocimiento y las capacidades de acoplamiento crean una experiencia personalizada",
        modularDevelopmentPlatformOriginalEngineASR:
          "Plataforma de desarrollo modular · Motor original · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Adopte algoritmos de extremo a extremo para lograr una experiencia de efecto personalizada en el negocio, la tasa de reconocimiento de escenas naturales supera el 90% y el reconocimiento de vocabulario personalizado supera el 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Plataforma de desarrollo modular · Marketing inteligente",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Utilice algoritmos de aprendizaje profundo, combinados con retratos de usuarios, para lograr una recomendación precisa de productos y mejorar la conversión de ventas.",
        modularDevelopmentPlatformUserPortrait:
          "Plataforma de desarrollo modular · Retrato de usuario",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Cree con precisión retratos de usuarios basados en datos de usuario y datos de diálogo, describa completamente las características del cliente",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Plataforma de desarrollo modular · Recomendación inteligente",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "De acuerdo con los retratos de usuarios y los retratos de productos, recomiende automáticamente productos que puedan ser de interés para los usuarios y realice recomendaciones personalizadas para miles de personas.",
        modularDevelopmentPlatformNLPPAAS:
          "Plataforma de desarrollo modular · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Proporciona capacidades básicas de PNL de alta precisión, que cubren capacidades subyacentes de diferentes granularidad, como palabras, oraciones y artículos.",
      },
    },
    qualityScore: {
      index: {
        show: "Mostrar:",
        displayBySecondaryClassification:
          "Mostrar por clasificación secundaria",
        showByRule: "Mostrar por reglas",
        pleaseEnterClassificationruleName:
          "Ingrese el nombre de la categoría/regla",
        clickCopy: "Haga clic en Copiar",
        hitDetailsOfOperatorsInRules:
          "Detalles del golpe del operador dentro de la regla:",
        hit: "Golpe",
        miss: "Sin hits",
        filter: "Filtrar:",
        clickToViewDetails: "Haga clic para ver los detalles",
      },
      components: {
        flowNodeList: {
          branch: "Puntos",
          pleaseSelect: "Por favor elija",
          viewTheCompleteProcess: "Ver el proceso completo",
          thereIsCurrentlyNoProcessAvailable: "Sin proceso",
        },
        pointItem: { xiangHit: "Hit" },
        flowNodeListModal: { index: { completeProcess: "Proceso completo" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Si la tarea de inspección de calidad del proceso cambia, vuelva a apelar.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Los datos se han borrado periódicamente",
        subject: "Asunto:",
        description: "Descripción:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask:
              "Agregar a tareas de inspección de calidad",
          },
        },
      },
    },
    relatedDetail: {
      index: {
        associatedCallConversation: "Relacionar llamadas/conversaciones",
      },
    },
    samplingTarget: {
      index: {
        time: "Cuando",
        samplingQuantityIsLessThan: "La cantidad de muestreo es menor que",
        alertWhen: "Advertencia de tiempo",
        everyday: "Diariamente",
        weekly: "Semanalmente",
        monthly: "Mensual",
        samplingInspectionTargetSavedSuccessfully:
          "El objetivo de muestreo se guardó correctamente",
        personnel: "Personal",
        eachCustomerService: "Cada servicio al cliente",
        cycle: "Ciclo",
        samplingInspectionTarget: "Objetivo de muestreo",
        strip: "Artículo",
        alertSettings: "Configuración de alerta temprana",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Servicio al cliente",
        complete: "Terminado",
        Objective: "/Objetivo",
        everyday: "Diariamente",
        weekly: "Semanalmente",
        monthly: "Mensual",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Tasa de interacción de chat grupal",
        maximumResponseIntervalTime: "Intervalo máximo de respuesta",
        numberOfPreemptiveCalls: "Número de palabras",
        numberOfMessages: "Número de mensajes",
        strip: "Artículo",
        proportionOfMessages: "Número de mensajes",
        speechPercentage: "La proporción de habla",
        longestCustomerTalk: "El cliente más largo habla",
        numberOfIndepthDiscussions: "Número de discusiones en profundidad",
        second: "VEsegundo",
        maximumDurationOfContinuousSpeech:
          "El tiempo de conversación continuo más largo",
        questionFrequency: "Frecuencia de preguntas",
        customer: "Cliente",
        keyEvents: "Eventos clave:",
        opportunity: {
          index: {
            generationFailed: "Error de generación",
            generateOpportunityPoints: "Generar puntos de oportunidad",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Error de generación",
            generateSummary: "Generar resumen",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "¿Está seguro de eliminar?",
        add: "Añadir",
        opportunityPoints: "Punto de oportunidad",
        conversationSummary: "Resumen de la conversación",
        cancel: "Cancelación",
        determine: "Determinar",
        edit: "Edición",
        establish: "Crear",
        conversationalInsights: "Información de conversación",
        keyEvents: "Eventos clave",
        numKeyEvents: "Eventos clave",
        questionAnalysis: "Análisis de preguntas",
        find: "Descubrimiento",
        questions: "Preguntas",
        conversationAction: "Acción de sesión",
        customer: "Cliente",
        sale: "Ventas",
        sessionLabel: "Escena de conversación",
        employeeQuestions: "Preguntas de los empleados",
        customerQuestions: "Preguntas de los clientes",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Cliente",
        customerService: "Servicio al cliente",
        replyTo: "Responder a",
        addressee: "Destinatario:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Reidentificación",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "La reidentificación consumirá tiempo de transliteración, tenga cuidado",
        determine: "Determinar",
        executionSuccessful: "Ejecución exitosa",
        addToQualityInspectionTask: "Agregar a tareas de inspección de calidad",
        batchOperation: "Operación por lotes",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Agregar con éxito",
        addToQualityInspectionTask: "Agregar a tareas de inspección de calidad",
        qualityInspectionTask: "Tareas de inspección de calidad",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Detectar roles",
        detectionRange: "Rango de detección",
        sentence: "Oración",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Los datos se han borrado periódicamente",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Volver al sistema empresarial" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Extracción de preguntas",
        businessSummary: "Resumen de negocios",
        keyWord: "Palabras clave",
      },
      components: {
        problemExtraction: {
          no: "No.",
          customerServiceDidNotAnswer: "Servicio al cliente sin respuesta",
          customerServiceHasAnswered: "Servicio al cliente ha respondido",
          problemUnresolved: "Problema no resuelto",
          theProblemHasBeenResolved: "Problema resuelto",
          customerIssue: "Pregunta del cliente:",
          customerServiceAnswer: "Respuesta de servicio al cliente:",
          reExtractQuestions: "Problema de reextracción",
          clickToExtractQuestions: "Haga clic para extraer preguntas",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Nombre del retrato",
            backgroundDescription: "Descripción de antecedentes",
            customerLabel: "Etiqueta de cliente",
            labelManagement: "Gestión de etiquetas",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Nuevo" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Configuración de seguimiento" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Aumentar el cuestionamiento",
              remove: "Quitar",
              addQuestioning: "Agregar seguimiento",
              section: "No.",
              secondInquiry: "Segunda pregunta",
              questioningScore: "Puntaje de seguimiento",
            },
          },
        },
        index: {
          accordingToRules: "Por reglas",
          byScore: "Por puntuación",
          whenMissRule: "Cuando falla la regla",
          whenConductingQuestioning: "Cuando hacer preguntas",
          pleaseSelectARule: "Por favor, seleccione la regla",
          ruleCannotBeEmpty: "Las reglas no pueden estar vacías",
          currentScriptScore: "Cuando la puntuación de palabras ≤",
          pleaseEnterTheScore: "Por favor ingrese la puntuación",
          scoreCannotBeEmpty: "La puntuación no puede estar vacía",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Narrador:" },
          pPT: { slide: "Presentación de diapositivas" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Reseñas de palabras",
              script: "Hablando",
              semantics: "Semántica",
              violatingWords: "Palabras infractoras",
              speechSpeed: "Velocidad del habla",
              emotion: "Emoción",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Reseñas de palabras",
              regenerate: "Regeneración",
              generate: "Generar",
              hitSituation: "Situación de golpe",
              noSemanticTagsMatched: "No coincide con la etiqueta semántica",
              matchToSemanticTags: "Coincidir con las etiquetas semánticas",
              appealToBeSubmitted: "Quejas pendientes de presentación",
              ratingHasBeenReviewed: "La puntuación ha sido revisada",
              ratingAppealInProgress: "Calificaciones en apelación",
              expressionScore: "Puntuación de expresión",
              moodParticlesTimes: "Palabras de aire, {0} veces",
              changeTheModalParticlesTo: "La palabra de tono se cambia a",
              second: "VEsegundo",
              interrogation: "Preguntar",
              questioningWithoutScoring: "Seguimiento sin puntuación",
              branch: "Puntos",
              matchingRate: "Tasa de coincidencia",
              ratingAppeal: "Calificaciones de apelación",
              changeTo: "Sustitúyase por",
              wordPerMinute: "Palabra por minuto",
              completeScript: "Hablando completo",
              incompleteScript: "Hablando incompleto",
              semanticHit: "Golpe semántico",
              semanticMisses: "La semántica falla",
              scriptViolation: "Violación del habla",
              modified: "Modificado",
              notChanged: "Sin modificaciones",
              keyWord: "Palabras clave",
              semanticLabels: "Etiquetas semánticas",
              semanticMatchingRate: "Coincidencia semántica",
              violatingWords: 'Palabras infractoras"',
              delete: "Eliminar",
              unmatched: "No emparejado",
              matching: "Coincidencia",
              matchTo: "Coincidir con",
              notMatchedTo: "No coincide con",
              scriptScore: "Puntuación de palabras",
              standardScript: "Hablando estándar",
              emotionalScore: "Puntuación emocional",
              speechSpeedScore: "Puntuación de velocidad de habla",
              viewResolution: "Ver análisis",
              nodeConfiguration: "Configuración de nodos",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Tiempo de espera para responder esta pregunta, envíe automáticamente el contenido de la respuesta",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Tiempo de espera para responder esta pregunta, envíe automáticamente el contenido de la respuesta",
          },
          correctBtn: {
            saveSuccessful: "Ahorre con éxito",
            errorCorrection: "Corrección de errores",
            cancel: "Cancelación",
            preserve: "Guardar",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Reglas de puntuación",
            cancel: "Cancelación",
            determine: "Determinar",
          },
          scoringRuleItem: {
            whole: "Todo",
            arbitrarily: "Cualquier",
            pleaseEnterARuleName: "Introduzca el nombre de la regla",
            standardScript: "Hablando estándar",
            if: "Si",
            and: "Y",
            or: "O",
            thenScore: "Puntuación",
            pleaseEnterAScore: "Ingrese la puntuación",
            bonusPoints: "Puntos extra",
            minusPoints: "Reducción de puntos",
            hit: "Golpe",
            misses: "Sin hits",
            keyWord: "Palabras clave",
            semantics: "Semántica",
          },
          title: {
            ruleValidation: "Verificación de reglas",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Reglas de puntuación (la puntuación total actual es",
            points: "Puntos)",
            editRules: "Reglas de edición",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Sin etiqueta" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Objetivos de comunicación",
              scoringCriteria: "Criterios de puntuación",
            },
          },
        },
        index: {
          generating: "Generado",
          generateDialogueFramework: "Generar un marco de diálogo",
          theme: "Tema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Mensaje",
            generatePromptLanguage: "Generar mensaje",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Fase de comunicación",
          communicationObjectives: "Objetivos de comunicación",
          scoringCriteria: "Criterios de puntuación",
          suggestionsForImprovement: "Sugerencias de promoción",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Puntuación del proceso",
            contentIntegrity: "Integridad del contenido:",
            NumberOfCompletedProcesses: "%, El número de procesos completados",
            keyHitPointsTotal: "Puntos clave: total",
            notReached: "No alcanzado",
            accurateFollowupAccumulationOfStandardScripts:
              "Siga con precisión: acumulación de palabras estándar",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Palabras, el número total de palabras golpeadas por los estudiantes",
            aWord: "Palabras",
            languageNormsYes: "Especificación del lenguaje: hay",
            emotionsAppearing: "Emoción: aparición",
            speechSpeedSpeakingTooFast:
              "Velocidad del habla: la velocidad del habla es demasiado rápida",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Palabras de aire: demasiadas palabras de aire",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Agregar a Ignorar etiquetas",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "¿Está seguro de que desea volver a ejecutar la etiqueta inteligente?",
        reExecute: "Volver a ejecutar",
      },
      component: { successfullyAdded: "Agregar con éxito" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Desactivar todas las notificaciones",
        reQualityInspectionTask:
          "Reinspección de la tarea de inspección de calidad",
        requalityInspection: "Re inspección de calidad",
        waiting: "Esperando",
        viewDetails: "Ver detalles",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Tiempo de detección:" } },
        },
        template: { responseOpeningSentence: "Respuesta a la oración inicial" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Error de generación",
            generate: "Generar",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Sin datos" } },
      },
      index: { regenerate: "Regeneración" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "¿Está seguro de eliminar?",
        operationSuccessful: "Operación exitosa",
      },
      template: { delete: "Eliminar" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Por favor, seleccione la pregunta!",
        similarQuestionRecommendation: "Recomendaciones similares",
        selectAllOnPage: "Cuando la página está seleccionada",
        cancel: "Cancelación",
        preserve: "Guardar",
        changeBatch: "Cambiar un lote",
      },
    },
    listControl: { index: { columnControl: "Control de columna" } },
    loginUserAvatar: { index: { switch: "Cambiar" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Al agregar datos en tiempo real, las tareas de inspección de calidad aún requieren mucho tiempo. El tiempo específico depende de la cantidad de datos de inspección de calidad, que varía de unos minutos a 2 horas.",
      },
    },
    bEditor: {
      index: {
        delete: "Eliminar",
        cancel: "Cancelación",
        confirm: "Determinar",
        insert: "Insertar",
        width: "Ancho",
        height: "Altura",
        clearContent: "Borrar contenido",
        revoked: "Revocación",
        redo: "Rehacer",
        fontSize: "Tamaño de fuente",
        rowHeight: "Línea alta",
        wordSpacing: "Espaciado entre palabras",
        paragraphIndent: "Sangría de párrafo",
        increaseIndent: "Aumentar la sangría",
        decreaseIndent: "Reducir la sangría",
        border: "Borde",
        color: "Colores",
        textColor: "Color de texto",
        backgroundColor: "Color de fondo",
        temporaryColor: "Colores temporales",
        bold: "En negrita",
        italic: "Cursiva",
        underline: "Subrayado",
        strikethrough: "Tachado",
        font: "Fuente",
        textAlignment: "Alineación de texto",
        left: "A la izquierda",
        centered: "En el centro",
        right: "A la derecha",
        bothEnds: "En ambos extremos",
        leftFloat: "Flotante a la izquierda",
        rightFloat: "Derecha flotante",
        subrupt: "Título superior",
        subscript: "Subíndice",
        clearStyle: "Borrar estilos",
        title: "Título",
        regular: "Convencional",
        orderedList: "Lista ordenada",
        unorderedList: "Lista desordenada",
        reference: "Cita",
        code: "Código",
        link: "Enlace",
        clearLink: "Borrar enlaces",
        horizontalLine: "Línea horizontal",
        media: "Medios de comunicación",
        mediaLibrary: "Biblioteca de medios",
        smallExpression: "Pequeña expresión",
        fullScreen: "Pantalla completa",
        exitFullScreen: "Salir de pantalla completa",
        enterLinkText: "Ingrese el texto del enlace",
        enterLinkAddress: "Ingrese la dirección del enlace",
        enterTheLinkAddressAndPressEnter:
          "Ingrese la dirección del enlace y presione Enter",
        openInNewWindow: "Se abre en una nueva ventana",
        removeLink: "Eliminar un enlace",
        playingAudioFiles: "Reproducción de archivos de audio",
        playVideoFiles: "Reproducción de archivos de video",
        embeddedMedia: "Medios integrados",
        image: "Imágenes",
        video: "Vídeo",
        audio: "Audio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Nombre",
        description: "Descripción",
        type: "Tipo",
        status: "Estado",
        actions: "Operación",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Nombre de la tarea",
        description: "Descripción",
        form: "Formulario de inspección de calidad",
        actions: "Operación",
        createUser: "Creador",
      },
      samplingRecords: {
        createTime: "Tiempo",
        type: "Tipo",
        operateUser: "Operadores",
        number: "Añadir cantidad",
        filterConditionList: "Condiciones de filtro",
        sampleRuleStr: "Condiciones de muestreo",
        timeGreaterThan: "Tiempo mayor que",
        timeLessThan: "El tiempo es menor que",
      },
    },
    customFilters: {
      createdAt: "Tiempo de conversación",
      customerId: "Cliente",
      agentId: "Servicio al cliente",
      userGroupId: "Grupo de servicio al cliente",
      state: "Estado",
      lastEvaluateUserId: "Calificador final",
      submitTime: "Última hora de envío",
      totalScore: "Puntuación total",
      formItemForbidIdList: "Prohibido",
      formItemDeadlyIdList: "Fatal",
    },
    samplingInspection: {
      callTime: "Tiempo de conversación",
      dialogTime: "Tiempo de conversación",
      originator: "Asignación",
      createTime: "Hora de creación",
      updateTime: "Tiempo de actualización",
      status: "Estado del proceso",
      score: "Puntuación",
      actions: "Operación",
      reviewer: "Reevaluación",
      samplingOfficer: "Inspector de muestreo",
      id: "ID de registro de llamadas",
      time: "Tiempo de conversación",
      phone: "Teléfono del cliente",
      staff: "Servicio al cliente",
      inspectionStatus: "Estado de inspección de calidad",
      samplingStatus: "Estado de muestreo",
      inspectionGrade: "Calificación",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Palabra estándar",
        synonymWords: "Sinónimos",
        synonymWordsNumber: "Número de sinónimos",
        useFrequency: "Frecuencia de uso",
        effectiveness: "Eficacia",
        operation: "Operación",
      },
      common: {
        useFrequency: "Frecuencia de uso",
        effectiveness: "Eficacia",
        operation: "Operación",
      },
      professionalWords: {
        professionalWords: "Términos profesionales",
        useFrequency: "Estadísticas de uso",
        homophonic: "Palabras calientes (reemplazo homofónico)",
        target: "Sustitución de sustantivos",
        targetWords: "Sustitución de palabras",
        homophonyWords: "Palabras de bloqueo homofónico",
        operation: "Operación",
      },
      attentionWords: "Palabras de atención",
      ignoreWords: "Ignorar las palabras",
      wordsWeight: {
        selfDefineWeightWords: "Palabras de peso personalizadas",
        weightWords: "Palabras de peso",
        weight: "Pesos",
        useFrequency: "Frecuencia de uso",
        effectiveness: "Eficacia",
        operation: "Operación",
      },
      corpusData: {
        corpusDescription: "Descripción del corpus",
        updateTime: "Tiempo de actualización",
        wordsCount: "Número de palabras",
        effectiveness: "Eficacia",
        operation: "Operación",
        corpusContent: "Contenido",
      },
    },
    semanticTags: {
      title: "Etiquetas semánticas",
      tags: "Oración estándar",
      orgName: "Organización",
      tagCount: "Número de entrenamiento",
      usingCount: "Número de referencias",
      activeFlag: "Eficacia",
      suggestion: "Sugerencias de formación",
      actions: "Operación",
    },
    intelligentTags: {
      tagName: "Nombre",
      tagDesc: "Descripción",
      tagRefCnt: "Número de marcas",
      categoryName: "Clasificación de etiquetas",
      eventName: "Clasificación de eventos",
      tagStatus: "Eficacia",
      actions: "Operación",
      tagType: "Tipo de etiqueta",
    },
    dataPreprocess: {
      name: "Nombre",
      desc: "Descripción",
      dataSource: "Restricciones de fuentes de datos",
      type: "Tipo",
      status: "Eficacia",
      actions: "Operación",
    },
    informationEntities: {
      name: "Nombre",
      apiName: "Nombre de la API",
      type: "Tipo",
      remark: "Descripción",
      activeFlag: "Eficacia",
      actions: "Operación",
    },
    interactiveRecognition: {
      questionAndAnswer: "Preguntas estándar & respuestas",
      type: "Tipo",
      status: "Estado",
      actions: "Operación",
      question: "Problema",
    },
    role: { id: "ID", name: "Nombre", actions: "Operación" },
    uploadDownload: {
      taskName: "Nombre del archivo",
      createTime: "Fecha",
      size: "Tamaño",
      createUserName: "Operador",
      status: "Estado",
      result: "Resultados",
      actions: "Operación",
    },
    taskCenterList: {
      name: "Contenido de la tarea",
      createTime: "Tiempo de presentación",
      startTime: "Hora de inicio",
      endTime: "Tiempo de finalización",
      createUserName: "Operador",
      status: "Estado",
      result: "Resultados",
      actions: "Operación",
    },
    customerInformation: {
      title: "Información de servicio al cliente",
      data: "Ver solo los datos de excepción",
      list: {
        agentNo: "Número de trabajo/ID de servicio al cliente",
        agentName: "Servicio al cliente",
        agentTel: "Teléfono",
        agentEmail: "Correo electrónico",
        updateTime: "Tiempo de actualización",
        actions: "Operación",
      },
      upload: {
        second:
          "2. Haga clic en el botón Importar para cargar el archivo EXCEl que se ajuste al formato de plantilla",
      },
    },
    IndexManagement: {
      title: "Gestión de indicadores",
      list: {
        indicatorName: "Nombre",
        indicatorDesc: "Descripción",
        activity: "Eficacia",
        inspectDataSource: "Fuente de datos",
        sysType: "Tipo",
        agentGroups: "Grupo de servicio al cliente",
        actions: "Operación",
      },
      detail: {
        nameEmpty: "El nombre no puede estar vacío",
        descript: "Descripción",
        descriptEmpty: "La descripción no puede estar vacía",
        active: "Eficacia",
        activeEmpty: "La eficacia no puede estar vacía",
        dataSource: "Fuente de datos",
        dataSourceEmpty: "La fuente de datos no puede estar vacía",
        grounp: "Grupo de servicio al cliente",
        grounpEmpty: "El grupo de servicio al cliente no puede estar vacío",
        grounpHolder: "Seleccione el grupo de servicio al cliente",
        type: "Método de puntuación",
        typeNone: "El método de puntuación no puede estar vacío",
        base: "Puntos básicos",
        baseNone: "Los puntos básicos no pueden estar vacíos",
        positive: "Factores positivos",
        positiveNone: "Los factores positivos no pueden estar vacíos",
        customAdd: "Agregar filtro",
        negative: "Factores negativos",
        negativeNone: "Los factores negativos no pueden estar vacíos",
        customOK: "Condiciones de filtro:",
        judgeStrategy: "Reglas de condición:",
        other: "Otros factores",
        otherAdd: "Agregar factores",
        agent: "Datos sin procesar",
        section: "Cantidad de intervalo",
        sectionNone: "El número de intervalos no puede estar vacío",
        step: "Paso de intervalo",
        stepNone: "Paso de intervalo",
        save: "Guardar",
        cancel: "Cancelación",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Todo",
      staff: "Solo yo",
      department: "Departamento y por debajo",
    },
    gongStatisticsDate: { week: "Semana", month: "Mes", season: "Temporada" },
    gongCoachTaskStatus: {
      wait: "Esperando asesoramiento",
      already: "Tutorial",
      ignore: "Ignorado",
    },
    callRangeStatus: {
      all: "Todas las llamadas",
      sale: "Iniciado por empleados",
      customer: "Iniciado por el cliente",
    },
    todoListType: {
      later: "Escuche más tarde",
      help: "Ayuda de asesoramiento",
      contact: "Contacto más tarde",
      send: "Enviar información",
      warn: "Alerta de riesgo",
    },
    frequencyTypes: {
      everyDay: "Diariamente",
      everyWeek: "Semanalmente",
      everyMonth: "Mensual",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Entrenamiento de datos no clasificados",
        markedDataCorrigenda: "Errata de datos marcada",
        classifiedDataCheck: "Verificación de datos clasificados",
        markedHistoryRecord: "Historial de anotaciones",
        historyDataRecalculate: "Recálculo de datos históricos",
      },
      dataExtractMode: {
        random: "Completamente al azar",
        intelligentRecommend: "Recomendación inteligente",
        custom: "Personalización",
        specifiedClass: "Especificar la clasificación",
      },
      smartRatesList: {
        markRate: "Tasa de etiquetado",
        corrigendaRate: "Tasa de erratas",
        kl: "Diactividad K-L",
        signalNoiseRate: "Relación señal/ruido",
      },
    },
    visibleRangeTypes: {
      all: "Todo",
      customerGroup: "Grupo de servicio al cliente",
      userDefined: "Personalización",
    },
    samplingRateTypes: {
      everyDay: "Extracción diaria",
      everyWeek: "Extracción semanal",
    },
    taskRemindTypes: {
      confirm:
        "Envíe un recordatorio cuando sea necesario confirmar el registro de la tarea de inspección de calidad",
      appeal:
        "Envíe un recordatorio cuando el registro de la tarea de inspección de calidad inicie una apelación",
    },
    weekDays: {
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes.",
      saturday: "Sábado",
      sunday: "Domingo",
    },
    reportDateTypes: {
      dailyReport: "Informe diario",
      weeklyReport: "Informe semanal",
    },
    samplingRangeDayTypes: { today: "Hoy", yesterday: "Ayer" },
    samplingRangeWeekTypes: {
      thisWeek: "Esta semana",
      lastWeek: "La semana pasada",
    },
    samplingRuleTypes: {
      proportion: "Muestreo proporcional",
      random: "Muestreo aleatorio",
    },
    taskStatusTypes: {
      waitEvaluate: "A evaluar",
      waitSubmit: "Per de presentación",
      waitConfirm: "Por confirmar",
      waitReview: "A revisar",
      completed: "Completado",
      all: "Todo",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Puntuación directa",
        weightedScore: "Puntuación de peso",
        deductScore: "Deducción de puntos",
        directScoreSecondLevel: "Puntuación directa-Nivel 2",
        weightedScoreSecondLevel: "Puntuación de peso-Nivel 2",
        deductScoreSecondLevel: "Deducción-Nivel 2",
      },
    },
    fieldOperators: {
      is: "Igual a",
      not: "No es igual a",
      contains: "Contiene",
      notContains: "No contiene",
      not_contains: "No contiene",
      containsAny: "Contiene cualquier",
      contains_any: "Contiene cualquier",
      is_any: "Contiene cualquier",
      not_any: "No contiene ningún",
      notContainsAny: "No contiene ningún",
      not_contains_any: "No contiene ningún",
      containsAll: "Contiene todos",
      contains_all: "Contiene todos",
      notContainsAll: "No contiene todos",
      nont_contains_all: "No contiene todos",
      isNull: "Vacío",
      is_null: "Vacío",
      isNotNull: "No vacío",
      is_not_null: "No vacío",
      greaterThan: "Más grande que",
      greater_than: "Más grande que",
      greaterThanEq: "Mayor o igual a",
      greater_than_eq: "Mayor o igual a",
      lessThan: "Menos de",
      less_than: "Menos de",
      lessThanEq: "Menos o igual a",
      less_than_eq: "Menos o igual a",
      between: "Entre",
      notBetween: "No entre",
      not_between: "No entre",
      relative: "Igual a",
      set: "Establecer en",
      belongs: "Pertenece a",
      notBelongs: "No pertenece",
      not_belongs: "No es igual a",
      today: "Hoy",
      yesterday: "Ayer",
      the_day_before_yesterday: "Anteayer",
      tomorrow: "Mañana",
      next_seven_day: "Los próximos siete días",
      last_seven_day: "Los últimos siete días",
      this_week: "Esta semana",
      next_week: "La semana que viene",
      last_week: "La semana pasada",
      this_month: "Este mes",
      next_month: "El próximo mes",
      last_month: "El mes pasado",
      this_year: "Este año",
      next_year: "El año que viene",
      last_year: "El año pasado",
      exact_match: "Coincidencia precisa",
      add_value: "Nuevo valor agregado",
      remove_value: "Eliminar valores",
      set_null: "Vacío",
      isAny: "Cualquier igual a",
      notAny: "Cualquier no es igual a",
      belongTo: "Pertenece a",
      notBelongTo: "No pertenece",
      hasAny: "Contiene cualquier",
      notHasAny: "No contiene ningún",
      prefixContains: "El comienzo es igual a",
      prefixNotContains: "El comienzo no es igual a",
      suffixContains: "El final es igual a",
      suffixNotContains: "El final no es igual a",
      nextSevenDay: "Los próximos 7 días",
      lastSevenDay: "Últimamente 7 días",
      thisWeek: "Esta semana",
      nextWeek: "La semana que viene",
      lastWeek: "La semana pasada",
      thisMonth: "Este mes",
      nextMonth: "El próximo mes",
      lastMonth: "El mes pasado",
      thisYear: "Este año",
      nextYear: "El año que viene",
      lastYear: "El año pasado",
      after: "Después de (incluido el día)",
      before: "Antes (incluido el día)",
      allMatching: "Coincidir con todos",
      allNotMatching: "Todos no coinciden",
      hit: "Golpe",
      not_hit: "Sin hits",
    },
    fieldsOperators: {
      is: "Igual a",
      is_null: "Al mismo tiempo vacío",
      head_equal: "El comienzo es igual a",
      tail_equal: "El final es igual a",
      is_not_null: "No vacío al mismo tiempo",
      not: "No es igual a",
      diff_equal: "La diferencia es igual a",
      diff_greater_than: "La diferencia es mayor que",
      diff_greater_than_eq: "La diferencia es mayor o igual a",
      diff_less_than: "La diferencia es menor que",
      diff_less_than_eq: "La diferencia es menor o igual a",
      hour_equal: "La misma hora",
      day_equal: "El mismo día",
      week_equal: "La misma semana",
      month_equal: "El mismo mes",
      year_equal: "En el mismo año",
      day: "Día",
      minute: "Minutos",
      hour: "Horas",
      part_equal: "Parte igual a",
    },
    samplingColumnCaptions: {
      created_at: "Tiempo de conversación",
      agent_id: "Servicio al cliente",
      get_direction: "Tipo de llamada",
      call_result: "Resultados de la llamada",
      survey: "Evaluación",
      derived_from_id: "Fuente",
      quit_queue_why: "Estado de la cola",
      queuing_duration: "La cola lleva mucho tiempo",
      talking_seconds: "Duración de la llamada",
      ringing_seconds: "Tiempo de timbre",
      hangup_by: "Cuelgue la llamada",
    },
    callTypes: {
      callIn: "Llamada entrante",
      callOut: "Exhalar",
      threeParty: "(Tripartito)",
      consultation: "(Consulta)",
      insert: "(Insertar fuerte)",
      monitor: "(Monitorear)",
      transfer: "(Transferencia)",
      intercept: "(Intercepción)",
      transferOutside: "(Transferencia a línea externa)",
      threePartyOutside: "(Línea externa tripartita)",
      consultingOutside: "(Consulta externa)",
    },
    callResults: {
      staffAnswer: "Respuesta de atención al cliente",
      staffNotAnswer: "Servicio al cliente no recibido",
      customerAnswer: "Respuesta del cliente",
      customerNotAnswer: "Cliente no recibido",
      phoneBusy: "El teléfono está ocupado",
      phoneOffline: "El teléfono está desconectado",
      customerSpeedHangUp: "Quick del cliente",
      customerHangUp: "Cliente colgado",
      queueTimeout: "El tiempo de espera de la cola",
      queueGiveUp: "Renunciar a la cola",
      outlineAnswer: "Respuesta externa",
      outlineNotAnswer: "Línea externa no conectada",
      noChooseQueue: "Cola no seleccionada",
    },
    defaultSurveys: {
      noEvaluation: "No evaluado",
      noNeedToEvaluate: "No se requiere evaluación",
    },
    queueStates: {
      queueSuccess: "Éxito en la cola",
      queueTimeout: "El tiempo de espera de la cola",
      queueGiveUp: "Renunciar a la cola",
      noStaffOnline: "Sin servicio al cliente en línea",
    },
    callHangers: {
      customer: "Cliente",
      staff: "Servicio al cliente",
      outline: "Línea exterior",
    },
    workFlowTypes: {
      noAppealsMode: "Modo sin apelación",
      appealsMode: "Permitir el modo de apelación",
    },
    fieldCategories: {
      standardCondition: "Criterios de filtro estándar",
      customerCondition: "Filtro definido por el usuario",
    },
    sampleTypes: {
      automatic: "Muestreo automático",
      manual: "Muestreo manual",
    },
    defaultFields: {
      createdAt: "Tiempo de conversación",
      agentId: "Servicio al cliente",
      getDirection: "Tipo de llamada",
      callResult: "Resultados de la llamada",
      survey: "Evaluación",
      derivedFrom: "Fuente",
      quitQueueWhy: "Estado de la cola",
      queuingDuration: "La cola lleva mucho tiempo",
      talkingSeconds: "Duración de la llamada",
      ringingSeconds: "Tiempo de timbre",
      hangupBy: "Cuelgue la llamada",
    },
    conditionFieldCategories: {
      fixedField: "Campo fijo",
      customField: "Campos personalizados",
      secondLevelScore: "Clasificación secundaria",
      formItemScore: "Pen",
      forbids: "Prohibido",
      deadlies: "Fatal",
    },
    columnFieldCategories: {
      agent: "Servicio al cliente",
      callLog: "Grabación de llamadas",
      businessFields: "Información comercial",
      customer: "Cliente",
      customerCustomFields: "Personalización del cliente",
      source: "Fuente",
      queue: "Cola",
      fixed: "Fijo",
      secondLevelScore: "Clasificación secundaria",
      formItemScore: "Pen",
      forbids: "Prohibido",
      deadlies: "Fatal",
    },
    taskListDefaultFields: {
      startTime: "Tiempo de conversación",
      name: "Servicio al cliente",
      state: "Estado",
      totalScore: "Puntuación total",
      formItemForbidList: "Prohibido",
      formItemDeadlyList: "Fatal",
      comment: "Observaciones de evaluación",
      inspector: "Calificador",
      review: "Reevaluación",
    },
    evaluationStates: {
      initial: "Inicialización",
      waitEvaluate: "A evaluar",
      evaluated: "Per de presentación",
      waitConfirm: "Por confirmar",
      waitCheck: "A revisar",
      complete: "Completado",
    },
    workBenchInfoTypes: {
      callInfo: "Información de la llamada",
      businessInfo: "Información comercial",
      customerInfo: "Información del cliente",
    },
    evaluationEventStates: {
      create: "Crear",
      valuate: "Evaluación",
      edit: "Edición",
      submit: "Presentar",
      confirm: "Confirmación",
      appeal: "Reclamación",
      checkConfirm: "Confirmación de revisión",
      check: "Revisión",
    },
    formItemTypes: {
      direct: "Puntuación directa",
      weight: "Pesos",
      deduct: "Deducción de puntos",
      forbid: "Prohibido",
      deadly: "Fatal",
    },
    appealCheckCallTypes: {
      callIn: "Llamada entrante",
      callBack: "Rellamada bidireccional",
      callOut: "Discado directo de llamada externa",
      autoCall: "Llamada externa automática",
    },
    appealCheckCallStatus: {
      waitReview: "A revisar",
      reviewed: "Revitado",
      filed: "Archivado",
    },
    samplingStatus: {
      all: "Todo",
      unchecked: "Extracción no inspeccionada",
      checked: "Muestreo",
    },
    inspectionStatus: {
      all: "Todo",
      unread: "Sin leer",
      readed: "Leer",
      appeal: "Reclamación",
      review: "Revisión",
      filed: "Archivado",
    },
    gradeChooseHits: { yes: "Sí.", no: "No." },
    appealCheckStatus: {
      all: "Todos los estados",
      unread: "Sin leer",
      inComplaint: "En la denuncia",
      reviewed: "Revitado",
      readed: "Leer",
      filed: "Archivado",
      spotchecked: "Muestreo",
    },
    flowStatus: {
      unread: "Sin leer",
      alreadyRead: "Leer",
      complaining: "En la denuncia",
      reviewed: "Revitado",
      sampling: "Extracción no inspeccionada",
      samplingCompleted: "Muestreo",
      complainCancel: "Retirado",
    },
    complainStatus: {
      success: "Éxito",
      fail: "Fracaso",
      default: "En la denuncia",
      canceled: "Retirado",
    },
    reviewInspectionStatus: {
      inComplaint: "En la denuncia",
      reviewed: "Revitado",
    },
    gradeChangeTypes: {
      edit: "Edición",
      check: "Revisión",
      file: "Archivo",
      remark: "Observaciones",
      complain: "Reclamación",
      review: "Revisión",
      reviewEvaluation: "Revisión de la evaluación",
      sample: "Inspección aleatoria",
      sampleEvaluation: "Evaluación de muestreo",
      sampleSubmit: "Presentación de muestreo",
      sampleAssign: "Distribución por muestreo",
      reviewAssign: "Asignación de revisión",
      read: "Leer",
      reviewConfirm: "Confirmación de revisión",
      sampleConfirm: "Confirmación de muestreo",
      caseBase: "Agregar al grupo de casos",
      sampleCancel: "Asignación de cancelación de muestreo",
      reviewCancel: "Revisar la cancelación de la asignación",
      sampleAgain: "Reasignación de muestreo",
      reviewAgain: "Reasignación de revisión",
      btachDelete: "Eliminar registros de muestreo",
      rapidSampling: "Muestreo rápido",
      reInspection: "Re inspección de calidad",
      smartTagsChange: "Cambio de etiqueta inteligente",
      cancelAppeal: "Retirada de la denuncia",
    },
    appealTypes: {
      transliterationError: "Error de transferencia",
      discriminationError: "Error de discriminación",
      otherError: "Otros errores",
    },
    inspectDataSources: {
      voiceCall: "Llamadas de voz",
      textDialogue: "Diálogo de texto",
      realTimeVoiceCall: "Llamadas de voz en tiempo real",
      realTimeTextDialogue: "Conversación de texto en tiempo real",
      wechatDialogue: "Micro conversación empresarial",
      taobao: "Diálogo de texto de comercio electrónico",
      ticket: "Hoja de trabajo",
      wechatRadio: "Microvoz empresarial",
    },
    inspectTaskType: {
      all: "Todo",
      common: "Inspección de calidad de rutina",
      relate: "Inspección de calidad relacionada",
    },
    inspectApproveType: {
      all: "Todo",
      resolve: "Aprobado",
      resolving: "En revisión",
      reject: "Revisión fallada",
    },
    dataSourceFlags: {
      all: "Sin restricciones",
      voiceCall: "Llamada",
      textDialogue: "Diálogo",
    },
    smartWordsLibrary: { effective: "Efectivo", invalid: "No válido" },
    activeFlags: { effiective: "Efectivo", invalid: "No válido" },
    labelTypes: {
      dimensionLabel: "Etiquetado",
      classificationLabel: "Etiquetas de categoría",
    },
    pointTypes: {
      automatic: "Evaluación automática",
      manual: "Evaluación manual",
      interactiveDetection: "Detección de interacción",
      smartRules: "Reglas inteligentes",
      machineLearning: "Modo de autoaprendizaje",
      intelligentModel: "Modelo inteligente",
    },
    pointGradeTypes: {
      radio: "Selección única",
      input: "Entrada",
      select: "Selección",
      all: "Satisfacer a todos",
      any: "Satisfaga cualquier",
      customize: "Personalización",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Coincidencia de palabras clave",
      question: "Oración interrogativa",
      regular: "Expresiones regulares",
      context: "Repetición de contexto",
      semantics: "Coincidencia semántica",
      word: "Palabras similares",
      dialogue: "Intervalo de conversación",
      speed: "Detección de velocidad del habla",
      grabDetection: "Detección de palabras",
      semanticTags: "Etiquetas semánticas",
    },
    applyRoles: {
      all: "Todo",
      customer: "Cliente",
      agent: "Servicio al cliente",
      robot: "Robot",
    },
    saleApplyRoles: { all: "Todo", customer: "Cliente", agent: "Empleados" },
    applyOperatorScopes: {
      all: "Texto completo",
      preCondition: "Condiciones previas",
      scope: "Especificar el rango",
    },
    keywordScopes: {
      all: "Detectar todas las palabras clave",
      any: "Detección de palabras clave arbitrarias",
    },
    preOperatorHitTypes: {
      first: "Primer partido",
      every: "Cada partido",
      last: "Último partido",
      any: "Cualquier partido",
      none: "No emparejado",
    },
    operatorRuleTypes: {
      all: "Conoce a todos",
      any: "Satisfaga cualquier",
      custom: "Lógica personalizada",
    },
    informationEntityTypes: {
      system: "Sistema",
      custom: "Personalización",
      moduleCompany: "Módulo",
    },
    organizationTypes: { null: "Ninguno", moduleCompany: "Módulo" },
    customType: {
      smartTag: "Entidad de extracción",
      scriptTimes: "Cuenta",
      dataCategory: "Clasificación de datos",
    },
    interactiveQuestionTypes: {
      standard: "Preguntas y respuestas estándar",
      multiElement: "Preguntas y respuestas de varios elementos",
      guideMultiRound: "Interacción guiada de múltiples ruedas",
    },
    targetTypes: {
      all: "Todo",
      customerService: "Servicio al cliente",
      customer: "Cliente",
    },
    interactiveTypes: {
      similarQList: "Problemas similares",
      exceptSimilarQList: "Excluir problemas similares",
      similarAList: "Respuestas similares",
      wrongAList: "Respuesta incorrecta",
    },
    filterTypes: {
      call: "Grabación de llamadas",
      dialogue: "Texto de diálogo",
      wechat: "WeChat empresarial",
      wechatAll: "Todas las conversaciones corporativas de WeChat",
      ticket: "Sesión de orden de trabajo",
      taobao: "Conversación de comercio electrónico",
      wechatRadio: "Microvoz empresarial",
    },
    fieldTypes: {
      keyword: "Palabras clave",
      agent: "Servicio al cliente",
      agentGroup: "Grupo de servicio al cliente",
      sigleListbox: "Selección única",
      multiListbox: "Selección múltiple",
      text: "Texto de una sola línea",
      textArea: "Texto de varias líneas",
      dateTime: "Tiempo",
      number: "Números",
      voice: "",
      customer: "Cliente",
      fieldWithLink: "Con campos de enlace",
      percentage: "Porcentaje",
      intelligentClassification: "Clasificación inteligente",
      testSetList: "Campo dinámico de conjunto de pruebas",
      inspector: "Inspector de calidad",
    },
    saveTypes: { save: "Guardar" },
    knowledgeType: { oneToOne: "Una pregunta y una respuesta" },
    updateTypes: { update: "Actualizar", saveAs: "Guardar como" },
    recommendItemActionTypes: {
      similar: "Similar",
      exclude: "Exclusión",
      ignore: "Ignorar",
    },
    gradeStatus: {
      unread: "Sin leer",
      alreadyReview: "Revitado",
      alreadyFiled: "Archivado",
      waitReview: "A revisar",
      alreadyRead: "Leer",
    },
    qualityCheckDetailTypes: {
      review: "Revisión",
      spotCheck: "Inspección aleatoria",
    },
    sampleAndReviewStatus: {
      init: "No extraído",
      unread: "Sin leer",
      alreadyRead: "Leer",
      complaining: "En la denuncia",
      reviewed: "Revitado",
      sampling: "Extracción no inspeccionada",
      samplingCompleted: "Muestreo",
    },
    interactiveAnswerTypes: {
      noAnswer: "Respuesta no detectada",
      answer: "Respuesta detectada",
      wrongAnswer: "Respuesta incorrecta detectada",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "No se detectó ningún problema",
      question: "Problemas detectados",
      eliminateQuestion: "Problemas de solución detectados",
    },
    isManual: {
      yes: "Inspección de calidad manual",
      no: "Sin inspección manual de calidad",
    },
    readStatus: { yes: "Leer", no: "Sin leer" },
    reInspectionType: {
      recalcScore:
        "Vuelva a calcular solo las puntuaciones (incluidas las calificaciones)",
      recheckPoint:
        "Vuelva a calcular los puntos y puntajes de inspección de calidad",
    },
    common: {
      eCommerceReview: "Revisión de comercio electrónico",
      socialMediaComments: "Comentarios de medios sociales",
      customer: "Cliente",
      customerService: "Servicio al cliente",
      robot: "Robot",
      customerInformation: "Información del cliente",
      workOrderFields: "Campo de orden de trabajo",
      intelligentConversationAnalysis: "Análisis de sesión inteligente",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Obtenga información sobre las deficiencias comerciales, escuche las voces de los clientes y mejore la conversión de marketing",
      intelligentAccompanyingPractice: "Entrenamiento inteligente",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Cree un combate de IA inmersivo que simule la escena real del negocio, realice el dominio de las habilidades de la medalla de oro y mejore rápidamente las habilidades de diálogo.",
      intelligentQualityInspection: "Inspección de calidad inteligente",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Inspección de calidad completa, oportuna y eficiente, con una tasa de precisión superior al 85%",
      salesEmpowerment: "Empoderamiento de las ventas",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "La IA reconfigura la gestión de ventas, realiza la digitalización y visualización del proceso de comunicación, gestiona de forma transparente cada eslabón de las ventas, proporciona información inteligente y comunica cada paso, diagnostica de manera refinada los problemas comerciales y replica perfectamente la experiencia de la corona de ventas.",
      algorithmPlatform: "Plataforma de algoritmos",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Basado en la semántica de voz del motor original de desarrollo propio, los modelos ASR y NLP se personalizan para abrir los datos de la escena empresarial, realizar el autoaprendizaje del sistema y lograr el propósito de ser más inteligente y preciso",
      generalSettings: "Configuración universal",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Configuración y administración unificadas de permisos de datos y roles de empleados",
      callLog: "Grabación de llamadas",
      intelligentRules: "Reglas inteligentes",
      businessRecords: "Registros comerciales",
      customFilter: {
        call: "Llamadas de voz",
        text: "Diálogo de texto",
        smartTags: "Etiquetas inteligentes",
        pretreatment: "Pretratamiento",
        smartTagGroup: "Grupos de etiquetas inteligentes",
        currency: "Datos generales",
        wechat: "WeChat empresarial",
        spotCheck: "Inspección aleatoria",
        personaField: "Lista de clientes",
        ticket: "Sesión de orden de trabajo",
        taobao: "Conversación de comercio electrónico",
        wechatRadio: "Microvoz empresarial",
        reviewList: "Revisión",
      },
      talkerRecognitionType: {
        API: "Personajes de pista de identificación de API",
        text: "Reconocimiento de texto",
      },
      operatorTypes: {
        keyword: "Coincidencia de palabras clave",
        question: "Oración interrogativa",
        regular: "Expresiones regulares",
        context: "Repetición de contexto",
        semantics: "Coincidencia semántica",
        word: "Palabras similares",
        dialogue: "Intervalo de conversación",
        speed: "Detección de velocidad del habla",
        grabDetection: "Detección de palabras",
        semanticTags: "Etiquetas semánticas",
        keywordStrict: "Coincidencia de palabras clave (estricto)",
        link: "Campos de enlace",
        entityInfoDetection: "Detección de entidades de información",
        basicInfoDetection: "Detección de información básica",
        volume: "Detección de volumen",
        emotion: "Análisis de emociones",
        grammar: "Reglas de gramática",
        sentenceLength: "Detección de longitud de oración",
        conceptWord: "Palabras conceptuales",
        questionsAndAnswers: "Respuestas de la base de conocimientos (antiguo)",
        element: "Preguntas y respuestas de elementos",
        silent: "El tiempo de espera de respuesta",
        associate: "Datos de seguimiento",
        repeat: "Enviar repetidamente",
        timeInterval: "Duración del intervalo",
        typoDetection: "Detección de errores tipográficos",
        silentOld: "Silencio",
        knowledge: "Respuestas de conocimiento",
        wecomRemark: "Comentarios de la empresa del cliente",
        customerAddTime: "Los clientes agregan tiempo",
      },
      keywordOperators: {
        all: "Coincidir con todos",
        allNot: "Todos no coinciden",
        any: "Coincidencia arbitraria",
      },
      applyRoles: {
        all: "Todo",
        customer: "Cliente",
        agent: "Servicio al cliente",
      },
      sentenceLength: {
        less: "Menos de",
        greater: "Más grande que",
        equal: "Igual a",
        greater_or_equal: "Mayor o igual a",
        less_or_equal: "Menos o igual a",
      },
      dialogueApplyScopes: {
        differentRoles: "Entre diferentes personajes",
        agent: "Servicio al cliente",
        customer: "Cliente",
        agentResponse: "Respuesta de servicio al cliente",
        customerResponse: "Respuesta del cliente",
        customerNoResponse: "Los clientes no responden",
      },
      applyConditionHitTypes: {
        first: "Primer golpe",
        every: "Cada golpe",
        last: "Último golpe",
      },
      applyConditionScopes: {
        current: "Actual",
        before: "Antes",
        after: "Después",
        beforeAll: "Todo antes",
        afterAll: "Después de todo",
        around: "Cerca de aquí.",
      },
      voiceDemoTypes: {
        singleChannel: "Grabación de llamadas de un solo canal",
        dualChannel: "Grabación de llamadas de dos canales",
      },
      sendStatus: {
        sending: "Enviar",
        arrive: "Entregado",
        fail: "Error de envío",
        off_sending: "Enviar sin leer sin conexión",
        off_arrive: "Leer",
        rollback: "Retirado",
      },
      collectionMethod: {
        assignment: "Asignación directa",
        entity: "Extracción de entidades",
      },
      systemTypes: {
        qa: "Inspección de calidad inteligente",
        wfm: "Programación inteligente",
      },
      entityOperators: {
        equals: "Igual a información",
        notEquals: "No es igual a la información",
        contains: "Contiene información",
        notContains: "No contiene información",
        equalsFix: "Igual a un valor fijo",
        notEqualsFix: "No es igual a un valor fijo",
        containsFix: "Contiene valores fijos",
        notContainsFix: "No contiene valores fijos",
      },
      basicInfoOperators: {
        equals: "Igual a",
        notEquals: "No es igual a",
        contains: "Contiene",
        notContains: "No contiene",
        isNull: "Vacío",
        isNotNull: "No vacío",
      },
      volumeDetectionWays: {
        loudness: "DBB de sonoridad",
        rangeAbilitySelf: "El rango de cambio (más que la oración anterior)",
        rangeAbilityOth: "Variación (comparación con la oración anterior)",
        rangeAbility3: "La magnitud del cambio (más que la propia oración)",
      },
      volumeDetectionOperators: {
        is: "Igual a",
        not: "No es igual a",
        greaterThan: "Más grande que",
        greaterThanEq: "Mayor o igual a",
        lessThan: "Menos de",
        lessThanEq: "Menos o igual a",
      },
      sentimentTypes: {
        positive: "Emociones positivas",
        negative: "Emociones negativas",
        neutral: "Emociones neutrales",
        thankful: "Gracias",
        happy: "Feliz",
        complaining: "Quejarse",
        angry: "Enojado",
        post: "Frente",
        negat: "Negativo",
        neut: "Neutral",
      },
      emotionOperators: { is: "Cumplir con", not: "No cumple" },
      propTypes: { system: "Sistema", user: "Personalización" },
      valueTypes: { text: "Texto", number: "Números" },
    },
    tasks: {
      allowCustomGrievance: "Permitir un motivo de apelación personalizado",
      businessSummary: "Resumen de negocios",
      theDayBeforeYesterday: "Anteayer",
      assessActionTypes: { reassess: "Reevaluación" },
      scoreType: {
        aiScore: "Evaluación inteligente",
        manualScore: "Evaluación manual",
        interactiveDetection: "Detección de interacción",
        smartRules: "Reglas inteligentes",
        machineLearning: "Modo de autoaprendizaje",
      },
      inspectType: {
        inspectTrue: "Compruebe a",
        inspectFalse: "No comprobado",
      },
      operatorType: {
        keyword: "Coincidencia de palabras clave",
        notKeyword: "Las palabras clave no coinciden",
      },
      applyRole: {
        all: "Todo",
        customer: "Cliente",
        agent: "Servicio al cliente",
      },
      applyScope: {
        all: "Todo",
        specific: "Oración especificada",
        scope: "Especificar el rango",
      },
      judgeStrategy: {
        all: "Cumplir todas las condiciones",
        arbitrarily: "Satisfacer cualquier condición",
        custom: "Lógica personalizada",
      },
      checkPointStatus: {
        waitFor: "Esperando revisión",
        notDeductScore: "Sin deducción",
        deductScore: "Deducción de puntos",
      },
      applyOptions: {
        hit: "Golpe condicional",
        notHit: "Sin condiciones especificadas",
      },
      gradeTypes: {
        yesOrNo: "Si cumple",
        input: "Entrada manual",
        level: "Subevaluación",
        multipleFactors: "Múltiples elementos",
      },
      gradeChooseHits: { yes: "Sí.", no: "No." },
      gradeChangeTypes: {
        edit: "Edición",
        check: "Revisión",
        file: "Archivo",
        remark: "Observaciones",
        complain: "Reclamación",
        review: "Revisión",
        reviewEvaluation: "Revisión de la evaluación",
        sample: "Inspección aleatoria",
        sampleEvaluation: "Evaluación de muestreo",
        sampleSubmit: "Presentación de muestreo",
        sampleAssign: "Distribución por muestreo",
        reviewAssign: "Asignación de revisión",
        read: "Leer",
        reviewConfirm: "Confirmación de revisión",
        sampleConfirm: "Confirmación de muestreo",
      },
      gradeStatus: {
        unread: "Sin leer",
        alreadyReview: "Revitado",
        alreadyFild: "Archivado",
        waitReview: "A revisar",
        alreadyRead: "Leer",
      },
      samplingFrequencyTypes: {
        everyDay: "Diariamente",
        everyWeek: "Semanalmente",
        everyMonth: "Mensual",
      },
      samplingEveryDayTimeTypes: { yesterday: "Ayer", today: "Hoy" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Esta semana",
        lastWeek: "La semana pasada",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Este mes",
        lastMonth: "El mes pasado",
      },
      samplingTypes: {
        random: "Muestreo aleatorio",
        proportional: "Muestreo proporcional",
      },
      weekdays: {
        Monday: "Lunes",
        Tuesday: "Martes",
        Wednesday: "Miércoles",
        Thursday: "Jueves",
        Friday: "Viernes",
        Saturday: "Sábado",
        Sunday: "Domingo",
      },
      distributeTypes: {
        rule: "Asignación prioritaria de reglas",
        average: "Asignación aleatoria promedio",
      },
      notMatchAnyRuleTypes: {
        random: "Distribución equitativa aleatoria",
        assign: "Asignación a personas designadas",
        ignore: "No asignar",
      },
      inspectorTypes: {
        all: "Todos los inspectores de calidad",
        some: "Inspector de calidad designado",
      },
      pushFrequencyTypes: {
        everyDay: "Diariamente",
        everyWeek: "Semanalmente",
        everyMonth: "Mensual",
        inspectionUpdate: "Actualización de inspección de calidad",
        timelyInspection: "Inspección de calidad en tiempo real",
      },
      pushObjectTypes: {
        email: "Especificar el buzón",
        staff: "Servicio al cliente",
      },
      reviewOptions: {
        reviewResult:
          "Mostrar los resultados de la inspección de calidad durante la revisión",
        reviewRecord: "Mostrar registros relacionados durante la revisión",
        reviewAgentHidden:
          "Ocultar información de servicio al cliente durante la revisión",
        samplingResult:
          "Muestre los resultados de la inspección de calidad durante la inspección aleatoria",
        samplingRecord:
          "Mostrar registros relevantes durante la inspección aleatoria",
        sampleAgentHidden:
          "Ocultar información de servicio al cliente durante la inspección aleatoria",
        checkExportAddCallInfoIphone:
          "Adjuntar registros de llamadas al exportar listas",
        sampleExportAddCallInfoIphone:
          "Adjuntar registros de llamadas al exportar listas",
        checkExportAddCallInfoText:
          "Adjuntar registros de conversación al exportar listas",
        sampleExportAddCallInfoText:
          "Adjuntar registros de conversación al exportar listas",
        inspectionExportAddCallInfoIphone:
          "Adjuntar registros de llamadas al exportar la lista (hasta 10000 cada vez)",
        inspectionExportAddCallInfoText:
          "Adjuntar registros de conversación al exportar la lista (hasta 10000 cada vez)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Permitir la implementación rápida de inspecciones aleatorias en detalles de inspección de calidad",
        fastSampleInSampleDetail:
          "Permitir la implementación rápida de inspecciones aleatorias en los detalles de las inspecciones aleatorias",
        fastSampleInReviewDetail:
          "Permitir la implementación rápida de inspecciones aleatorias en los detalles de revisión",
      },
      permitCustomReasonList: {
        permitCustomReason: "Permitir un motivo de declaración personalizado",
      },
      autoMateTasks: {
        autoLoad: "Añadir automáticamente",
        sample: "Muestreo automático",
        assign: "Asignación automática",
        pushes: "Empuje automático",
        assess: "Evaluación automática",
        check: "Reexamen de apelación",
        case: "Sincronización de la biblioteca de casos",
      },
      qualityCheckDetailTypes: {
        review: "Revisión",
        spotCheck: "Inspección aleatoria",
      },
      appealTypes: {
        transliterationError: "Error de transferencia",
        discriminationError: "Error de discriminación",
        otherError: "Otros errores",
      },
      inspectDataSources: {
        voiceCall: "Llamadas de voz",
        textDialogue: "Diálogo de texto",
        realTimeVoiceCall: "Llamadas de voz en tiempo real",
        realTimeTextDialogue: "Conversación de texto en tiempo real",
      },
      pointTypes: {
        automatic: "Evaluación automática",
        manual: "Evaluación manual",
        interactiveDetection: "Detección de interacción",
      },
      pointGradeTypes: {
        radio: "Selección única",
        input: "Entrada",
        select: "Selección",
      },
      sampleAndReviewStatus: {
        init: "No extraído",
        unread: "Sin leer",
        alreadyRead: "Leer",
        complaining: "En la denuncia",
        reviewed: "Revitado",
        sampling: "Extracción no inspeccionada",
        samplingCompleted: "Muestreo",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Ignorar el exceso de desbordamiento",
        automaticWeightCalculation: "Contabilidad automática de ponderaciones",
      },
      calculateScoreTypes: { add: "Puntos extra", sub: "Reducción de puntos" },
      naChecked: {
        check: "Esto no está involucrado en la detección permitida",
      },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Puntos proporcionales",
        allRight: "Todos los puntos correctos",
        anyRight: "Puntos correctos arbitrarios",
      },
      noHitQuestionResults: {
        true: 'El resultado es "sí"',
        false: 'El resultado es "no"',
      },
      repetRules: {
        same: "El contenido es exactamente el mismo",
        similar: "Semántica similar",
      },
      relateConditions: {
        highlightQuestion: "Resalte el problema",
        highlightAnswer: "Respuestas resaltan",
        hightlightFoctors: "Elementos de resaltado",
      },
      interactiveQuestionTypes: {
        question: "Problemas detectados",
        eliminateQuestion: "Problemas de solución detectados",
      },
      interactiveAnswerTypes: {
        noAnswer: "Respuesta no detectada",
        answer: "Respuesta detectada",
        wrongAnswer: "Respuesta incorrecta detectada",
      },
      inspectionUpdateTypes: {
        autoInspection: "Inspección automática de calidad",
        autoInspectionWechat: "Análisis automático",
        artificialSampling: "Inspección manual",
        artificialReview: "Revisión manual",
        manualModification: "Muestreo rápido",
        timelyInspection: "Inspección de calidad en tiempo real",
        samplingAssign: "Distribución por muestreo",
        artificialComplain: "Presentación de quejas",
        fastSampling: "Presentación rápida de muestreo",
        recheckInspection: "Re inspección de calidad",
        recheckInspectionWechat: "Reanálisis",
      },
      timelyInspectionTypes: {
        timelyInspection: "Inspección de calidad en tiempo real",
      },
      pushActionTypes: { email: "Correo", interfacePush: "Push de interfaz" },
      pushDetailWays: {
        riskAlert: "Alertas de riesgo",
        subscribe: "Suscribirse a los mensajes",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Mensaje de llamada/conversación",
        inspectionResults: "Resultados de inspección de calidad",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Mensaje de llamada/conversación",
        textContent: "Contenido de texto de llamada/conversación",
        inspectionResults: "Resultados de inspección de calidad",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Promedio de puntuación dentro del grupo",
        addOrSubtract: "Puntos de suma y resta directamente",
        noChange: "Sin sumar ni restar puntos",
      },
      defaultScore: {
        empty: "Vacío",
        add: "Puntos extra",
        notAdd: "Sin puntos extra",
        sub: "Reducción de puntos",
        notSub: "Sin pérdida de puntos",
        na: "N/A",
        input: "Entrada manual",
        choose: "Selección",
      },
      strategyTypes: {
        hitAdd: "Puntos de bonificación de golpe",
        hitNotAdd: "Golpe sin puntos extra",
        hitSub: "Pérdida de puntos",
        hitNotSub: "Golpe sin restar puntos",
      },
    },
    download: {
      exportStatus: {
        prepare: "En preparación",
        process: "En progreso",
        faild: "Error de exportación",
        success: "Éxito",
      },
    },
    messageType: {
      all: "Todo",
      monitor: "Monitoreo inmediato",
      trigger: "Activar notificaciones",
      timing: "Informe de tiempo",
    },
    messagePushType: {
      all: "Todo",
      system: "Mensajes del sistema",
      sdk: "SDK de mensajes",
      push: "Push de interfaz",
      email: "Correo",
      udesk: "Notificación udesk",
      udeskWhite: "Notificación del sistema de servicio al cliente",
      weChat: "Micro mensaje empresarial",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Asignación de inspectores",
      sampleInspector: "Inspector de muestreo",
      assignReviewer: "Asignación de revisadores",
      reviewer: "Reevaluación",
      taskInspector: "Inspector de calidad de tareas",
    },
    sdkPushType: {
      webHook: "Webhook estándar",
      dingDing: "Robot de uñas",
      weChat: "Robot empresarial WeChat",
      customer: "Personalizar el contenido de Json",
    },
    inspectionRemarksType: {
      inspection: "Inspección de calidad",
      check: "Inspección aleatoria",
      review: "Revisión",
    },
    segmentationStrategyType: {
      count: "Número de mensajes",
      spacing: "Intervalo de mensajes",
    },
    segmentationStrategy: {
      byTime: "Corte de tiempo",
      customize: "Cortar la combinación",
    },
    inspectType: {
      commonInspect: "Inspección de calidad de rutina",
      associateInspect: "Inspección de calidad relacionada",
    },
    typoDetectionOperators: { less: "Menos de", greater: "Más grande que" },
    autoAddTypes: {
      realTimeAdd: "Añadir en tiempo real",
      timingAdd: "Agregar cronometrado",
    },
    messageTypes: {
      text: "Mensajes de texto",
      markdown: "Mensajes de Markdown",
    },
    inServiceStatus: {
      activated: "Activado",
      disabled: "Deshabilitado",
      inactivated: "No activado",
      resigned: "Salir de la empresa",
    },
    roles: { agent: "Empleados", customer: "Cliente" },
    commentVisibleAuthority: {
      all: "Visible para todos",
      self: "Solo visible para ti",
      designation: "La persona designada es visible",
    },
    appealCheckingTask: {
      eCommerceReview: "Revisión de comercio electrónico",
      socialMediaComments: "Comentarios de medios sociales",
      largeModel: "Modelo grande",
      customModel: "Modelos personalizados",
      universalModel: "Modelo universal",
      enterpriseWeChatText: "Microtexto empresarial",
      generalQualityInspection: "Inspección general de calidad",
      processQualityInspection: "Inspección de calidad del proceso",
      voiceCopy: "Reproducción de voz",
      beEqualTo: "Igual a",
      notEqualTo: "No es igual a",
      canAppeal: "Puede apelar",
      noAppeal: "No se puede apelar",
    },
    wechat: {
      redEnvelopes: "Sobre rojo",
      text: "Texto",
      picture: "Imágenes",
      voice: "Voz",
      voiceCall: "Llamadas de voz",
      video: "Vídeo",
      businessCard: "Tarjetas de visita",
      position: "Ubicación",
      expression: "Expresión",
      link: "Enlace",
      applet: "Pequeño programa",
      chatRecord: "Historial de chat",
      mixedMessage: "Mensajes mixtos",
      file: "Archivo",
    },
    gong: {
      sessionActivity: "Actividad de conversación",
      conversationAction: "Acción de sesión",
      riskSemantics: "Semántica de riesgo",
      daysNotFollowedUp: "Días sin seguimiento",
      waysideData: "Datos de seguimiento",
      notStarted: "Sin empezar",
      completed: "Completado",
      haveInHand: "En progreso",
      unpublished: "No publicado",
      published: "Publicado",
      knowledgePoints: "Puntos de conocimiento",
      technologicalProcess: "Proceso",
      notMarked: "Sin marcar",
      inAnnotation: "Anotación",
      annotated: "Amarcado",
    },
    qualityChecingTask: {
      appealCanceled: "Cancelación de apelación",
      complaintExpired: "Caducidad de la apelación",
      incomingCall: "Llamada entrante",
      exhale: "Exhalar",
      incomingCallthirdParty: "Llamada entrante (tres partes)",
      incomingCallconsultation: "Llamada entrante (consulta)",
      incomingCallforcedInsertion: "Llamada entrante (inserción fuerte)",
      incomingCallmonitoring: "Llamada entrante (monitor)",
      incomingCalltransfer: "Llamada entrante (transferencia)",
      incomingCallinterception: "Llamada entrante (interceptar)",
      incomingCalltransferToExternalLine:
        "Llamada entrante (transferencia de línea externa)",
      incomingCallthirdPartyExternalLine:
        "Llamada entrante (línea externa tripartita)",
      incomingCallexternalLine: "Llamada entrante (consulta externa)",
      outgoingCallthirdParty: "Exhalación (tres partes)",
      outgoingCalltransfer: "Exhalación (transferencia)",
      outgoingCallforcedInsertion: "Exhalación (inserción fuerte)",
      outgoingCallmonitoring: "Exhalación (monitor)",
      outgoingCallinterception: "Exhalación (interceptación)",
      outgoingCalltransferToExternalLine:
        "Exhalación (transferencia de línea externa)",
      outgoingCallthreePartyExternalLine: "Llamada (tres líneas externas)",
      customerServiceAnswer: "Respuesta de atención al cliente",
      customerServiceMissed: "Servicio al cliente no recibido",
      customerAnswer: "Respuesta del cliente",
      customerMissed: "Cliente no recibido",
      theTelephoneIsBusy: "El teléfono está ocupado",
      phoneOffline: "El teléfono está desconectado",
      customerQuickHanging: "Quick del cliente",
      clientHangsUp: "Cliente colgado",
      queueTimeout: "El tiempo de espera de la cola",
      giveUpQueuing: "Renunciar a la cola",
      outsideLineAnswering: "Respuesta externa",
      externalLineIsNotConnected: "Línea externa no conectada",
      noQueueSelected: "Cola no seleccionada",
      notEvaluated: "No evaluado",
      noEvaluationRequired: "No se requiere evaluación",
      queuingSucceeded: "Éxito en la cola",
      noCustomerServiceOnline: "Sin servicio al cliente en línea",
      customer: "Cliente",
      customerService: "Servicio al cliente",
      outsideLines: "Línea exterior",
    },
    sessionActivity: {
      single: "Tiempo medio de conversación único",
      all: "Volumen total de llamadas",
      allTime: "Duración total de la llamada",
    },
    sessionAction: {
      salesProportion: "Las ventas hablan la proporción",
      salesLongest: "El discurso de venta más largo promedio",
      customerLongest: "El cliente promedio más largo habla",
      discussNumber: "Número de discusiones en profundidad",
      frequency: "Frecuencia de preguntas",
    },
    monitorCriteria: {
      all: "Antes y después",
      before: "Antes",
      after: "Después",
    },
    monitorTimeCycle: { day: "Día", week: "Semana", month: "Mes" },
    monitorGroupTestType: {
      ab: "Comparación de dos grupos",
      a: "Grupo A",
      b: "Grupo B",
    },
    fields: {
      department: "Departamento",
      customerTags: "Etiqueta de cliente",
      member: "Miembros",
      time: "Tiempo",
      cascade: "En cascada",
      anyMatch: "Coincidencia arbitraria",
      keyWord: "Palabras clave",
      keyEvents: "Eventos clave",
    },
    semanticIntelligence: {
      staff: "Empleados",
      customer: "Cliente",
      employeesAndCustomers: "Empleados y clientes",
      notStarted: "Sin empezar",
      haveInHand: "En progreso",
      completed: "Completado",
      callRecording: "Grabación de llamadas",
      enterpriseWeChatCall: "Micro llamada empresarial",
      enterpriseWeChatText: "Microtexto empresarial",
      discourseMiningForObjectionHandling: "Manejo de objeciones",
      productSellingPointScriptsMining:
        "Exploración de puntos de venta de productos",
      conversationScenario: "Escena de conversación",
      topicOfConversation: "Tema",
      keyEvents: "Eventos clave",
      customerLabel: "Etiqueta de cliente",
      includeAny: "Contiene cualquier",
      includeAll: "Contiene todo",
      and: "Y",
      or: "O",
      question: "Oración interrogativa",
      nonInterrogativeSentence: "Oración no interrogativa",
    },
    coach: {
      notPassed: "No pasó",
      analogTelephone: "Teléfono analógico",
      enterScenarioInformation: "Ingrese la información del escenario",
      generateDialogueFramework: "Generar un marco de diálogo",
      generatePromptLanguage: "Generar mensaje",
      aIGeneratedDialogue: "Diálogo generado por IA",
      system: "Sistema",
      custom: "Personalización",
      flow: "Proceso",
      script: "Hablando",
      express: "Expresión",
      emotion: "Emoción",
      rateAccordingToCourseConfiguration: "Configurar la puntuación por curso",
      intelligentModelRating: "Puntuación del modelo inteligente",
      allData: "Todos los datos",
      dataFromThisDepartment: "Datos del departamento",
      multidepartmentalData: "Datos multisectoriales",
      underReview: "En revisión",
      no: "No.",
      yes: "Sí.",
      automaticRating: "Puntuación automática",
      auditRating: "Calificación de auditoría",
      reRating: "Volver a calificar",
      inAppeal: "En la denuncia",
      reviewed: "Revitado",
      notViewed: "No visto",
      viewed: "Visto",
      uncorrected: "Sin enmiendas",
      corrected: "Corregido",
      practice: "Ejercicios",
      examination: "Examen",
      other: "Otros",
      notMeetingStandards: "No a la altura",
      meetingStandards: "Cumplimiento",
      excellent: "Excelente",
      invalid: "Fallo",
      notStarted: "Sin empezar",
      haveInHand: "En progreso",
      ended: "Terminado",
      completed: "Completado",
      hangInTheAir: "Sin terminar",
      passed: "Pasado",
      voiceConversation: "Conversación de voz",
      textDialogue: "Diálogo de texto",
      slidePresentation: "Presentación de diapositivas",
      unpublished: "No publicado",
      published: "Publicado",
      narration: "Narrador",
      studentSpeaking: "Los estudiantes hablan",
      robotTalk: "Los robots hablan",
      knowledgeBaseQA: "Preguntas y respuestas de la base de conocimientos",
      slide: "Presentación de diapositivas",
      negativeEmotions: "Negativo emocional",
      emotionallyNormal: "Emoción normal",
      incompleteScript: "Hablando incompleto",
      completeScript: "Hablando completo",
      normalSpeechSpeed: "Velocidad de habla normal",
      speakTooFast: "Velocidad de habla demasiado rápida",
      speakTooSlowly: "La velocidad del habla es demasiado lenta",
      whole: "Todo",
      singlePage: "Una sola página",
      qA: "Una pregunta y una respuesta",
      situationalDialogue: "Diálogo situacional",
      misses: "Sin hits",
      hit: "Golpe",
      sequentialQA: "Preguntas y respuestas secuenciales",
      randomQA: "Preguntas y respuestas aleatorias",
      mastered: "Dominar",
      notMastered: "No dominado",
    },
    algorithm: {
      salesEmpowerment: "Empoderamiento de las ventas",
      enterpriseWeChatVoice: "Voz corporativa de WeChat",
      enterpriseWeChatText: "Texto corporativo de WeChat",
      waiting: "Esperando",
      extracting: "Extracción",
      success: "Éxito",
      fail: "Fracaso",
      intelligentQualityInspection: "Inspección de calidad inteligente",
      textDialogue: "Diálogo de texto",
      voiceCall: "Llamadas de voz",
      smartTags: "Etiquetas inteligentes",
      satisfactionEvaluation: "Evaluación de satisfacción",
      score: "Calificación",
      grade: "Calificación",
      customerServiceTeam: "Grupo de servicio al cliente",
      conversationAnalysis: "Análisis de sesión",
    },
    operatorListGather: {
      businessSummary: "Resumen de negocios",
      firstSentence: "Primera oración",
      lastSentence: "Última oración",
      allTime: "Todo el tiempo",
      nonBusinessWorkingHours: "Horas de trabajo no comerciales",
      businessWorkingHours: "Horas de trabajo de negocios",
    },
    callDialogueManage: {
      eCommerceReview: "Revisión de comercio electrónico",
      socialMediaComments: "Comentarios de medios sociales",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Iniciar sesión",
        email: "Correo electrónico",
        password: "Contraseña",
        forgetPwd: "Olvidé mi contraseña",
        emailCannotBeEmpty: "El buzón no debe estar vacío.",
        passwordCannotByEmpty: "La contraseña no debe estar vacía.",
        mustChangeLogin:
          "Lo sentimos, su contraseña debe restablecerse, póngase en contacto con el administrador para restablecer la contraseña.",
        dismissionLogin: "Lo siento, te has ido.",
        forbiddenLogin: "Lo sentimos, su cuenta está deshabilitada.",
        loginError:
          "Error de inicio de sesión, error de correo electrónico o contraseña.",
        loginPrompt: "Iniciar sesión en el sistema",
        rememberMe: "Recordarme",
      },
      logout: {
        logoutError:
          "No se pudo cerrar la sesión, verifique la conexión de red o vuelva a intentarlo más tarde.",
      },
      resetPassword: {
        forgetPwd: "Olvidé mi contraseña",
        inputEmailPrompt:
          "Introduce tu dirección de correo electrónico a continuación y te enviaremos un correo electrónico con instrucciones para restablecer tu contraseña.",
        sendEmailSuccess:
          "La carta de confirmación de modificación de contraseña se ha enviado correctamente al buzón que completó, verifique a tiempo.",
        sendEmailFailed:
          "No se pudo enviar el buzón. Compruebe la conexión de red o vuelva a intentarlo más tarde.",
        setNewPassword: "Configurar tu nueva contraseña",
        passwordCannotEmpty: "La contraseña no debe estar vacía",
        passwordFormWrong: "Formato de contraseña incorrecto",
        confirmPasswordWrong:
          "Las contraseñas ingresadas dos veces no son iguales.",
        passwordResetSuccess:
          "El restablecimiento de la contraseña se realizó correctamente.",
        passwordResetFailed:
          "No se pudo restablecer la contraseña. Compruebe la conexión de red o vuelva a intentarlo más tarde.",
        linkTimeover:
          "Restablecer el enlace de contraseña caduca, inténtelo de nuevo",
      },
      form: { title: "Exhibición del formulario CASE" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Correo electrónico",
          emailFormatError:
            "El formato del buzón es incorrecto, ¡vuelva a ingresar!",
          forgotPassword: "¿Olvidó su contraseña?",
          describe:
            "Introduce tu dirección de correo electrónico a continuación y te enviaremos un correo electrónico con instrucciones para restablecer tu contraseña.",
          send: "Enviar",
          emailCannotBeEmpty: "El buzón no puede estar vacío",
          resetPasswordExpire:
            "Restablecer el enlace de contraseña caduca, inténtelo de nuevo",
        },
        resetPassword: {
          setPassword: "Configurar tu nueva contraseña",
          newPassword: "Nueva contraseña",
          repeatPassword: "Contraseña de repetición",
          resetPassword: "Restablecer contraseña",
          passwordCannotBeEmpty: "La nueva contraseña no puede estar vacía.",
          confirmPasswordCannotBeEmpty:
            "Las contraseñas duplicadas no pueden estar vacías.",
          passwordsNotMatch: "¡Dos contraseñas inconsistentes!",
          passwordFormWrong:
            "¡La contraseña no puede tener chino y el número es de 6-14!",
          linkExpiration:
            "Restablecer el enlace de contraseña caduca, inténtelo de nuevo",
        },
      },
      errors: {
        haveNoPermissionWarningText: "No tienes permiso para ver esta página",
      },
    },
    downloadCenter: {
      name: "Centro de descargas",
      saveTime: "(Guardado por defecto durante 30 días)",
      headerTitle: "Descargar archivos",
      download: "Descargar",
      downloadPromp: "Se ha enviado la tarea de descarga, vaya a",
      viewProgress: "Ver el progreso",
    },
    uploadCenter: { name: "Centro de carga" },
    tasks: {
      index: {
        name: "Nombre",
        remark: "Descripción",
        qualityCheckTasksTitle: "Banco de trabajo",
        informationCollection: "Tareas de adquisición",
        qualityCheckTasks: "Tareas de inspección de calidad",
        wechatQaTasks: "Tareas de análisis",
        moduleName: "Nuevo con plantilla",
        creatNewQualityCheckTask: "Nueva tarea",
        generalInspectionList: "Informe general de inspección de calidad",
        editQualityCheckTask: "Editar tareas de inspección de calidad",
        creatNewQualityCheckTaskError:
          "La nueva tarea de inspección de calidad falló",
        editQualityCheckTaskError:
          "Error al editar la tarea de inspección de calidad",
        type: "Tipo de formulario",
        singleScore: "Puntaje único",
        singleScoreNullError:
          "Los puntajes individuales no pueden estar vacíos",
        inspector: "Inspector de calidad",
        inspectDataSource: "Fuente de datos",
        taskName: "Nombre de la tarea",
        inspectionType: "Tipo de inspección de calidad",
      },
      manage: {
        screeningCondition: "Condiciones de filtro",
        template: {
          condition: {
            index: {
              name: "Condiciones",
              existingConditions: "Condiciones existentes",
            },
            detail: {
              id: "ID",
              name: "Nombre de la condición",
              remark: "Descripción",
              checkRange: "Rango de detección",
              operator: "Operador",
              operatorLogic: "Lógica del operador",
            },
          },
          checkPointTest: {
            title: "Prueba de reglas",
            running: "En la prueba de reglas, espere...",
            clearSuccess: "El diálogo simulado se vacía con éxito",
            mock: "Conversación simulada",
            save: "Guardar la conversación seleccionada",
            saveAll: "Guardar todas las conversaciones",
            saveSuccess: "Conversación para salvar el éxito",
            close: "Salir",
            delete: "Eliminar seleccionado",
            clear: "Vacío",
            customer: "Cliente",
            agent: "Servicio al cliente",
            test: "Prueba",
            request:
              "El diálogo simulado no se recuperará después de que se vacíe. ¿Se confirma el vaciado?",
            deletePopconfirmContent:
              "¿Confirma eliminar la conversación seleccionada?",
          },
          checkPoint: {
            index: {
              name: "Configuración de reglas",
              wechatTitle: "Analizar la configuración del modelo",
              existingCheckPoints: "Reglas de inspección de calidad existentes",
              currentCheckPointScore:
                "La puntuación actual de la regla de inspección de calidad es",
              currentCheckPointScoreWechat:
                "La puntuación actual del modelo de análisis es",
              currentCheckPointWeight:
                "La suma de los pesos de las reglas de inspección de calidad actuales es",
              weightTotalScoreAndItemScore:
                "Puntuación total: 100 puntos puntos individuales:",
              template: "Plantilla de reglas de inspección de calidad",
              import: "Importar plantillas de reglas de inspección de calidad",
              getTemplateError:
                "No se pudo obtener la plantilla de reglas de inspección de calidad",
              tabs: { template: "Plantilla", demo: "Ejemplo" },
              customize: "Plantilla en blanco",
              move: "Arrastre para ajustar la clasificación y la clasificación",
              classification: "Clasificación de inspección de calidad",
              classificationWeChat: "Clasificación de modelos",
              first: "Clasificación de primer nivel",
              second: "Clasificación secundaria",
              new: "Nuevas reglas de inspección de calidad",
              weChatNew: "Agregar un modelo de análisis",
              createWeChat: "Nuevo modelo de análisis",
              empty: "No hay reglas de inspección de calidad,",
              wechatEmpty: "Sin modelo de análisis,",
              newSecond: "Nueva clasificación secundaria",
              newFirst: "Nueva clasificación de primer nivel",
              checkPoint: "Reglas de inspección de calidad",
              checkPointWeChat: "Modelo de análisis",
            },
            detail: {
              name: "Nombre",
              remark: "Descripción",
              type: "Tipo",
              deduction: "Deducción de puntos",
              rule: "Reglas",
              highlighted: "Resalte",
              gradeType: "Método de puntuación",
              interactiveCategory: "Clasificación de interacción",
              predeterminedScore: "Pestos",
              weight: "Pesos",
              na: "N/A",
              default: "Puntaje por defecto",
              preRule: "Reglas de condición previa",
              analysisHighlighted: "Reglas de análisis",
              categorySelectPrompt: "Por favor seleccione la categoría",
              checkHighlightArrayError:
                "La regla no coincide con el resaltado, vuelva a hacer clic en el botón Analizar regla",
              foctorsHolder: "Seleccione los elementos",
              foctorsErrorNone:
                "La regla de varios elementos está vacía, agregue un tipo de varios elementos",
              naError:
                "El elemento no se puede desactivar cuando la puntuación predeterminada es N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A significa No Applicable. Establece las condiciones previas para la regla de inspección de calidad. Cuando se cumplen las condiciones, se prueban. Cuando no se cumplen las condiciones, la regla no es aplicable y la regla no se detecta;\nInstrucciones para completar las condiciones previas: "& &" significa "y", "| |" significa "o", "!" Significa "no", complete el ejemplo "(R1 || R2) && R3"',
                applicationMessageType: "Tipo de mensaje de aplicación",
                selectAll: "Selección completa",
                highlightTags: "Etiqueta resaltada:",
                ruleType: "Tipos de reglas",
                dataSource: "Fuente de datos",
                whole: "Todo",
                enterpriseMicroConversation: "Micro conversación empresarial",
                enterpriseAndMicroCommunication: "Micro llamada empresarial",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Nodo de juicio",
                    dialogueNode: "Nodo de diálogo",
                    editNodes: "Editar un nodo",
                  },
                  nodeConfig: {
                    nodeName: "Nombre del nodo",
                    nodeRules: "Reglas de nodo",
                    scoringLogic: "Lógica de puntuación",
                    score: "Puntaje:",
                    rule: "Reglas",
                    multiBranchLogic: "Lógica de múltiples ramas",
                    highlight: "Resalte",
                    submit: "Presentar",
                  },
                  ruleList: {
                    branch: "Rama",
                    branchName: "Nombre de la rama:",
                    addRules: "Aumenta la regla",
                  },
                  sidebar: { node: "Nodo" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Acabado automático",
                      canvasAdaptation: "Adaptación de lienzo",
                      copy: "Copiar",
                      delete: "Eliminar",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Determinar que los nodos secundarios de un nodo no pueden ser el destino de la conexión",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "El nodo de inicio no puede ser un destino de conexión",
                      startingNodeCannotConnectMultipleNodes:
                        "El nodo de inicio no puede conectar varios nodos",
                      nodeCannotBeConnectedToItself:
                        "Los nodos no se pueden conectar a sí mismos",
                      startNode: "Nodo de inicio",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Cancelar todas las selecciones",
                    selectAll: "Todo seleccionado",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Si cumple",
                    gradedEvaluation: "Subevaluación",
                  },
                },
              },
            },
            configuration: {
              title: "Reglas de inspección de calidad",
              wechatTitle: "Modelo de análisis",
              neme: "Nombre",
              errorMessage:
                "Error de eliminación, debe haber al menos una condición",
              desc: "Descripción",
              status: "Estado",
              yes: "Efectivo",
              no: "No válido",
              namePlaceHolder: "Introduzca el nombre de la etiqueta",
              desPlaceHolder: "Por favor ingrese la descripción",
              nameEmpty: "El nombre no puede estar vacío",
              desEmpty: "La descripción no puede estar vacía",
              type: "Tipo",
              ruler: "Reglas inteligentes",
              machineLearning: "Modo de autoaprendizaje",
              autoScore: "Calificación inteligente (antiguo)",
              manualScore: "Puntuación manual",
              interaction: "Detección de interacción (antiguo)",
              typeTips: {
                model:
                  'Modelo inteligente: es un sistema de inspección de calidad preestablecido, sin necesidad de configuración y mantenimiento, y puede detectar rápidamente problemas en el servicio. Este modelo es más versátil. Si no cumple con los requisitos comerciales, utilice "reglas inteligentes" o "puntuación manual',
                rule: "Reglas inteligentes: puntuación automática basada en la IA de reglas",
                manual:
                  "Puntuación manual: inspección de calidad manual, puntuación manual",
              },
              model: "Modelo:",
              ruleType: {
                words: "Hablando",
                interaction: "Interacción",
                feature: "Características",
                information: "Información",
              },
              testingMode: {
                all: "Detectar todas las palabras clave",
                any: "Detección de palabras clave arbitrarias",
              },
              operatorTypesOfInteraction: {
                question: "Una pregunta y una respuesta",
                element: "Respuesta del elemento",
                repet: "Repetición de contexto",
                snatch: "Agarra las palabras",
                silent: "Silencio",
              },
              role: "Función de detección:",
              preposition: "Operador frontal:",
              range: "Rango de detección:",
              from: "No.",
              to: "Oración a la primera",
              end: "Oración",
              hasOldSilentTip:
                'Hola, para mejorar la precisión, "Reglas inteligentes-Interacción-Silencio" se ha actualizado a "tiempo de espera de respuesta", ¡se recomienda actualizar las reglas de uso!',
              changeToOld: "Cambiar a la versión anterior",
              changeToNew: "Cambiar a la nueva versión",
              logic: {
                logic: "Lógica de puntuación",
                naTip:
                  "N/A significa No Applicable. Establece las condiciones previas para la regla de inspección de calidad. Cuando se cumplen las condiciones, la regla no es aplicable. Si no se cumplen las condiciones, la regla no se detectará.",
                score: "Puntaje:",
                na: "N/A：",
                naDes: "Esto no está involucrado en la detección permitida",
                preRule: "Reglas de condición previa:",
                notCatchPreRule:
                  "Cuando no se cumplen las condiciones previas:",
                naChecked: "Cuando golpea N/A:",
                preRuleEmpty:
                  "La regla de condición previa no puede estar vacía",
                operatorEmpty:
                  "La condición necesaria en el operador no puede estar vacía",
                multipleEmpty:
                  "Las condiciones necesarias en la puntuación lógica múltiple no pueden estar vacías",
                average: "Promedio de puntuación dentro del grupo",
                addOrSubtract: "Puntos de suma y resta directamente",
                noChange: "Sin sumar ni restar puntos",
                rule: "Reglas:",
                all: "Conoce a todos los operadores",
                any: "Satisfaga cualquier operador",
                customize: "Lógica de operador personalizada",
                multiple: "Puntuación lógica múltiple",
                heightLight: "Resalte:",
                machineLearningTip:
                  "El modo de autoaprendizaje actualmente solo es efectivo para palabras de una sola oración. Utilice reglas inteligentes para palabras complejas.",
                strategy: "Estrategia:",
              },
            },
            component: {
              approvalSubmittedSuccessfully:
                "Presentación de aprobación exitosa",
            },
            components: {
              pageHeaderExtra: {
                test: "Prueba",
                formal: "Oficialmente",
                submit: "Presentar",
              },
              createModal: { index: { myRules: "Mis reglas" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Automatización de procesos",
              screeningCondition: "Condiciones de filtro",
              aiScreeningCondition: "Cribado de filtro inteligente",
              rule: "Reglas",
              automaticAddition: "Añadir automáticamente",
              automaticAdditionTip:
                "Agregue automáticamente datos a tareas en tiempo real o con regularidad para la inspección de calidad de IA",
              autoAdd: {
                autoAddType: "Método de adición automática",
                addFrequency: "Añadir frecuencia",
                add: "Añadir",
              },
              automaticSamplingInspection: "Muestreo automático",
              automaticSamplingInspectionTip:
                "Extraiga automáticamente datos que requieran inspección manual de calidad, por ejemplo, extraiga diariamente el 10% de los datos al azar de ayer para muestreo manual",
              everyAgentCheckLimit:
                "El límite superior por servicio al cliente",
              automaticAllocation: "Asignación automática",
              automaticAllocationTip:
                "Distribución de muestreo: los datos extraídos se asignan automáticamente a los inspectores de calidad. Por ejemplo, el 10% de los datos de ayer se seleccionan diariamente para inspecciones aleatorias manuales, y los 2 inspectores de calidad se asignan al azar en promedio a esta tarea.",
              automaticAllocationTip2:
                "Asignación de revisión: los datos de la apelación se asignan automáticamente al inspector de calidad para su revisión. Por ejemplo, los datos de la apelación diaria se asignan aleatoriamente a los 2 inspectores de calidad de esta tarea para su revisión.",
              automaticPush: "Empuje automático",
              automaticPushTip:
                "Notificación de activación: Notificación/alarma durante la inspección de calidad, por ejemplo, cuando el servicio al cliente tiene un error fatal, envíe un mensaje de notificación al grupo empresarial WeChat",
              automaticPushTip2:
                "Informe de tiempo: envíe los resultados de la inspección de calidad a un informe de tiempo por correo. Por ejemplo, envíe los resultados de la inspección de calidad de la inspección manual de ayer al buzón del supervisor de servicio al cliente a las 9:00 todos los días.",
              automaticAssess: "Evaluación automática",
              automaticAssessTip:
                "Los resultados del muestreo manual se comparan regularmente con los resultados de la inspección de calidad de IA para una evaluación de precisión. Por ejemplo, la precisión de los datos muestreados ayer se evalúa a las 8:00 todos los días.",
              reexaminationComplaint: "Reexamen de apelación",
              spoktReviewDetail: "Configuración empresarial",
              spoktReviewDetailTip:
                "Configure la lista de inspección de calidad, lista de muestreo, servicio de apelación, lista de revisión, comentarios y otras funciones",
              qaDetailAction: "Configuración de inspección de calidad",
              spoktDetailAction: "Configuración de muestreo",
              reviewDetailAction: "Revisar la configuración",
              appealAction: "Configuración de apelación",
              remarkTemplate: "Plantilla de comentarios",
              automaticArchiving: "Archivo automático",
              conditionalRules: "Reglas de condición",
              qualityInspectionConditions:
                "Condiciones de inspección de calidad",
              unopened: "Aún no abierto",
              sampleInspectionDistributionModel: "Distribución por muestreo",
              reviewAllocationModel: "Asignación de revisión",
              distributionModelTitle: "Estrategia de asignación:",
              pushRules: "Reglas de inserción",
              push: "Empuje",
              assessRules: "Reglas de evaluación",
              on: "on",
              off: "off",
              updateStatusSuccess:
                "¡El cambio de estado se realizó correctamente!",
              updateStatusFailed:
                "El cambio de estado falló, ¡inténtelo de nuevo más tarde!",
              sampleNumberOrPercent: "Número o proporción de muestras",
              inspectorList: "Inspector de calidad designado",
              numberOfSubmission: "Número de presentaciones permitidas",
              rewivewDays: "Día",
              TimeOfSubmission:
                "Período de validez de las declaraciones presentadas",
              rewivewSecond: "VEsegundo",
              switchOn: "Abrir",
              switchOff: "Cerrar",
              notMatchAnyRule: "Cuando no coincide con una regla arbitraria:",
              appealOptionsList: {
                hiddenInspector:
                  "La apelación oculta la información del inspector de calidad",
                hiddenHit:
                  "La página de apelación admite golpes de posicionamiento resaltando",
              },
              caseLibrary: "Sincronización de la biblioteca de casos",
              caseLibrarySyncTip:
                "Sincronice los casos agregados por el sistema de control de calidad con el sistema de servicio al cliente",
              addCase: "Aumentar la sincronización",
              caseLibraryId: "Biblioteca de casos",
              caseLibraryIdTip:
                "Agrupación de bibliotecas de casos del sistema de control de calidad",
              udeskCaseLibraryIdTip:
                "Agrupación de casos del sistema de servicio al cliente",
              syncHis: "Caso histórico sincronizado",
              otherSettings: "Otras configuraciones",
              udeskCaseLibraryId: "Grupo de casos push",
              url: "URL push",
              urlTip:
                "Ingrese la dirección de la interfaz que comienza con http:// o https://",
            },
            templateNew: {
              secondaryReview: "Revisión secundaria",
              numberOfAppealsAllowedToBeSubmitted:
                "Número de peticiones permitidas",
              validityOfSubmissionOfAppeal:
                "Período de validez de la presentación de la apelación",
              validityOfAppealReview:
                "Período de validez de la revisión de la apelación",
              hours: "Horas",
              businessSummary: "Resumen del negocio",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Límite inferior de extracción por servicio al cliente",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "El resumen del negocio se genera automáticamente por el gran modelo, y la dimensión de generación se puede personalizar de acuerdo con la situación del negocio.",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Solo la estrategia de asignación de revisadores de primer nivel",
              secondaryCompounding: "Compuesto secundario:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Los revisadores secundarios solo apoyan la distribución equitativa",
              secondLevelReviewer: "Reevaluación de segundo nivel:",
              samplingInspectionTarget: "Objetivo de muestreo",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Establezca el objetivo que debe completarse para la inspección de muestreo manual. Si el objetivo no se alcanza o excede, se le indicará. Por ejemplo, cada servicio al cliente debe completar 100 inspecciones de muestreo por mes. Si es menos de 80, se le indicará la inspección de calidad y la página de inspección de muestreo.",
            },
            component: {
              verificationFailed: "Falló la verificación",
              pleaseAddASecondLevelQualityInspector:
                "Agregue un inspector de calidad de segundo nivel",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Mensaje",
                    fieldName: "Nombre del campo",
                    fieldDescription: "Descripción del campo",
                    generateContent: "Generar contenido",
                    enabled: "Ya sea para habilitar",
                    generationMethod: "Método de generación",
                    automaticGeneration: "Generación automática",
                    manualTrigger: "Disparador manual",
                    filterCondition: "Condiciones de filtro",
                    return: "Volver",
                    preserve: "Guardar",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Generar resultados" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "No limitado",
                    limitedScope: "Rango limitado",
                    add: "Añadir",
                    thereIsCurrentlyNoDataAvailable: "Sin datos",
                  },
                },
              },
              index: {
                fieldName: "Nombre del campo",
                fieldDescription: "Descripción del campo",
                nothing: "Ninguno",
                generationMethod: "Método de generación",
                generateManually: "Generación manual",
                automaticGeneration: "Generación automática",
                generateContent: "Generar contenido",
                notQualified: "No limitado",
                filterCondition: "Condiciones de filtro",
                enabled: "Ya sea para habilitar",
                type: "Tipo",
                system: "Sistema",
                custom: "Personalización",
                operation: "Operación",
                edit: "Edición",
                areYouSureToDeleteIt: "¿Está seguro de eliminar?",
                delete: "Eliminar",
                addField: "Aumentar el campo",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Configuración de roles",
              roleConfigTip:
                "Se utiliza para configurar reglas de identificación para el procesamiento de separación de llamadas cuando los datos de llamadas importados son mono. El rol de servicio al cliente que cumple con las reglas",
              operator: "Operador",
              operatorLogic: "Lógica del operador",
            },
          },
          manualAdd: {
            index: {
              name: "Añadir manualmente",
              tip: "Se utiliza para filtrar datos de vista previa mediante condiciones de configuración manual y agregarlo rápidamente a la tarea de inspección de calidad",
              screeningCondition: "Agregar por filtro",
              aiScreeningCondition: "Cribado de filtro inteligente",
              rule: "Reglas",
              createTime: "Añadir tiempo",
              filterTitle: "Filtrar contenido",
              filterRule: "Condiciones de filtro",
              startTime: "Hora de inicio",
              endTime: "Tiempo de finalización",
              records: "Importar registros",
              createUserName: "Operadores",
              successCount: "Agregar con éxito",
              conditionRule: "Reglas de condición:",
              preview: "Vista previa",
              previewData:
                "Actualmente cumple con la condición de filtro {0}, se ha agregado la tarea {1}, se puede agregar la tarea {2}, no se puede agregar la tarea {3}",
              failCount: "Adición fallida",
            },
          },
          basicInfo: {
            index: {
              title: "Información básica",
              name: "Nombre",
              taskName: "Nombre de la tarea",
              taskNamePlaceholder: "Por favor ingrese el nombre de la tarea",
              requiredName: "El nombre de la tarea no puede estar vacío",
              taskRemarkPlaceholder: "Introduzca la descripción de la tarea",
              requiredRemark: "La descripción de la tarea no puede estar vacía",
              remark: "Descripción",
              taskRemark: "Descripción de la tarea",
              source: "Fuente de datos",
              benchmarkScore: "Puntos de referencia",
              benchmarkScorePlaceholder: "Ingrese la puntuación de referencia",
              scoreLowerLimit: "Puntaje mínimo mínimo",
              scoreUpperLimit: "Máximo de puntuación",
              inspectType: "Tipo de inspección de calidad",
              nlpModel: "Modelos de PNL",
              commonInspect: "Inspección de calidad de rutina",
              associateInspect: "Inspección de calidad relacionada",
              associateCycle: "Ciclo de asociación",
              associateRegulation: "Reglas de asociación",
              judgeStrategy: "Lógica de ejecución",
              rule: "Reglas",
              commonTip:
                "Inspección de calidad de rutina: adecuada para comunicación y diálogo únicos, inspección de calidad del contenido de comunicación única",
              associateTip:
                "Inspección de calidad relacionada: aplicable a múltiples comunicaciones y diálogos, y vincular el contenido de múltiples comunicaciones para la inspección de calidad.",
              cycleTip:
                "Si se asocian múltiples comunicaciones y diálogos, los datos dentro del ciclo de asociación ingresan a la inspección de calidad de la tarea",
              scoreLowerLimitPlaceholder:
                "Ingrese la puntuación mínima y el límite inferior",
              scoreUpperLimitPlaceholder:
                "Ingrese el límite de puntuación más alto",
              call: "Llamadas de voz",
              dialogue: "Diálogo de texto",
              user: "Inspector de calidad",
              wechatUser: "Operador",
              noDesignated: "No especificar",
              type: "Tipo de formulario",
              singleScore: "Puntaje único",
              conduct:
                "Primero complete la siguiente información, haga clic en Siguiente después de completar",
              conduct2:
                "Complete la configuración de las reglas de inspección de calidad y los puntos de inspección de calidad. Si ha completado la configuración, puede hacer clic en Siguiente o salir de la guía",
              conduct3:
                "La calificación de inspección de calidad se basa en los puntos de inspección de calidad y sus calificaciones. Puede definir diferentes niveles de resultados de inspección de calidad. Si no necesita calificaciones, puede omitir este paso directamente. Si ha completado la configuración, puede hacer clic en Siguiente o salir de la guía",
              conduct4:
                "Puede agregar automáticamente la ubicación de acuerdo con las características de los datos que se deben verificar en esta tarea de inspección de calidad, y configurar las características de los datos que se introducirán automáticamente en la tarea. Si no necesita agregar datos automáticamente por el momento, puede hacer clic para omitir. Si ha completado la configuración, Puede hacer clic en Siguiente",
              conduct5:
                "Si está utilizando una grabación mono, asegúrese de configurar este elemento y haga clic en Siguiente. Utilice solo el servicio al cliente para usar la técnica de conversación para ayudar al sistema a identificar las diferentes pistas entre el servicio al cliente y el cliente. Si utiliza la grabación de dos canales o ha completado la configuración, puede hacer clic para omitir",
              conduct6:
                "Puede agregar funciones manualmente para introducir los datos de diálogo existentes en el sistema para la inspección de calidad, o ha enviado tareas de adición manual, haga clic directamente para completar",
              helper: "Ver la documentación de ayuda",
              quit: "Salir de la guía",
              next: "Siguiente paso",
              complete: "Terminado",
              skip: "Saltar",
              cancel: "Cancelación",
              delete: "Eliminar",
              save: "Guardar",
            },
            template: {
              samplingInspector: "Inspector de muestreo",
              sessionType: "Tipo de sesión",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Banco de trabajo de inspección de calidad",
              search: "Filtrar",
              export: "Exportar",
              condition: "Condiciones",
              logic: "Lógica",
              reQualityInspection: "Re inspección de calidad",
              reQualityInspectionUnderWay:
                "En la tarea de reinspección de calidad...",
              reQualityInspectionUnderWayTip:
                "Dejar la página después de cerrar la ventana no afectará la tarea",
              reQualityInspectionDone:
                "Se ha completado la nueva inspección de calidad",
              reQualityInspectionDoneTip:
                "La nueva inspección de calidad se ha completado correctamente, haga clic en el botón Confirmar",
              endReQualityInspection: "Terminación de tareas",
              alreadyDoneTip: "Completado",
              totalTip: "Total",
              unit: "Artículo",
              endReInspectionSuccess:
                "La tarea de reinspección de calidad ha finalizado",
              closeWindow: "Cerrar la ventana",
              endReInspectionFailed:
                "La tarea de inspección de calidad falló, ¡vuelva a intentarlo más tarde!",
            },
            detail: {
              name: "Detalles de inspección de calidad",
              reviewName: "Detalles de la revisión",
              spotCheckName: "Detalles de muestreo",
              audioText: "Grabación de texto",
              callInfo: "Información de la llamada",
              satisfactionEvaluation: "Evaluación de satisfacción:",
              queueTime: "La cola lleva mucho tiempo:",
              ringingTime: "Tiempo de timbre:",
              callTime: "Eventos de llamada:",
              hangUpIdentity: "Parte que cuelga la llamada:",
              location: "Atribución:",
              source: "Fuente:",
              agent: "Servicio al cliente:",
              deviceType: "Tipo de equipo:",
              callType: "Tipo de llamada:",
              phoneNumber: "Número de relevo:",
              task: "Tarea:",
              commentsTip: "Ingrese el comentario de inspección de calidad",
              lastPage: "Anterior",
              nextPage: "Siguiente",
              score: "Calificación de inspección de calidad",
              review: "Revisión",
              file: "Archivo",
              changeList: "Registro de cambios",
              fullMarks: "Puntuación completa",
              most: "Más",
              saveReview: "Presentación de revisión",
              saveSpotCheck: "Presentación de muestreo",
              point: "Punto de inspección de calidad",
              sampleAssignInspector: "Asignación de inspectores",
              reviewAssignInspector: "Asignación de revisadores",
              sampleStatus: "Estado de muestreo",
              read: "Leer",
              user: "Usuarios",
              confirmer: "Confirmación de personas",
              voiceDownloadError:
                "La descarga de voz falló, ¡inténtelo de nuevo más tarde!",
              smartTags: "Etiquetas inteligentes",
              interactiveTip:
                "Utilice la identificación interactiva para detectar el uso de las preguntas frecuentes de servicio al cliente",
              interactiveIsEmptyTip:
                "Contenido de reconocimiento de interacción no detectado",
              businessRecords: "Registros comerciales",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Análisis de chat grupal de WeChat empresarial",
            },
          },
        },
      },
      cards: {
        health: "Calidad de la salud",
        accuracy: "Descubrir la tasa de aprobación",
        sessionsTotal: "Total de sesiones",
        enable: "Habilitar",
        disable: "Deshabilitar",
        none: "No",
        call: "Llamada",
        dialogue: "Diálogo",
        taobao: "Comercio electrónico",
        wecom: "Conversación",
        ticket: "Hoja de trabajo",
      },
      component: {
        backup: "(Copia de seguridad)",
        taskSaveAsSucceeded: "Guardar tareas como éxito",
        copyContent: "Copiar contenido",
        essentialInformation: "Información básica",
        ruleConfiguration: "Configuración de reglas",
        basicInformationQualityInspector:
          "Información básica-Inspector de calidad",
        qualityInspectionRating: "Calificación de inspección de calidad",
        roleConfiguration: "Configuración de roles",
        autoAddConfiguration: "Agregar configuración automáticamente",
        autoPushConfiguration: "Configuración automática de empuje",
        automaticSamplingConfiguration: "Configuración de muestreo automático",
        automaticAllocationConfiguration:
          "Configuración de asignación automática",
        businessConfiguration: "Configuración empresarial",
        caseBaseConfiguration: "Biblioteca de casos",
      },
      template: {
        cancel: "Cancelación",
        complete: "Completar",
        sort: "Ordenar",
        ruleApproval: "Aprobación de reglas",
        enterpriseMicroConversationAnalysisTask:
          "Tareas de análisis de microconversaciones empresariales",
        enterpriseAndMicroVoiceAnalysisTask:
          "Tareas de análisis de microvoz empresarial",
        saveTaskAs: "Guarde la tarea como",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Detalles de la regla" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Modificación exitosa",
            automaticApproval: "Aprobación automática",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Modificación exitosa",
            test: "Prueba",
            formal: "Oficialmente",
            adopt: "Por",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "¿Estás seguro de rechazar?",
              pleaseEnterTheRejectionReason: "Ingrese el motivo del rechazo",
              reject: "Rechazo",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Modificación exitosa",
              taskName: "Nombre de la tarea",
              dataSource: "Fuente de datos",
              qualityInspectionType: "Tipo de inspección de calidad",
              state: "Estado",
              updateTime: "Tiempo de actualización",
              updatedBy: "Actualizar personas",
              approver: "Afirmador",
              reasonForRejection: "Razones para el rechazo",
              operation: "Operación",
              details: "Detalles",
              adopt: "Por",
              whole: "Todo",
              approvalStatus: "Estado de aprobación",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Resumen",
      hi: "Hola,",
      standardEdition: "Edición estándar",
      call: "Saldo de inspección de calidad de voz",
      text: "Período de validez de la inspección de calidad del texto",
      collectionTasks: "Colección de tareas de inspección de calidad",
      noCollection: "No hay tarea de inspección de calidad para la colección,",
      goToCollecte: "Ir a la colección",
      yesterdayOverView: "Estadísticas comerciales de ayer",
      yesterdayAgent: "Ranking de servicio al cliente de ayer",
      yesterdayInspWork: "El trabajo del inspector de calidad ayer",
      operationalStatistics: {
        title: "Estadísticas de negocios",
        qualityTesting: "Inspección de calidad",
        spotCheck: "Inspección aleatoria",
        appeal: "Reclamación",
        chartTitles: {
          qualityTestingTrend: "Tendencia de inspección de calidad",
          qualityTestingAnalysis: "Misión de inspección de calidad",
          spotCheckTrend: "Tendencia de muestreo",
          spotCheckAnalysis: "Misión de muestreo",
          appealTrend: "Tendencias de las quejas",
          appealAnalysis: "Mandato de apelación",
        },
        titles: {
          qualityDataTend: "Distribución de datos de inspección de calidad",
          qualityCover: "Cobertura de inspección de calidad",
          qualityAverageTime:
            "Duración promedio de espera para la inspección de calidad",
          qualityCoverTend: "Tendencia de cobertura de inspección de calidad",
          handCover: "Situación de procesamiento manual",
          checkCover: "Cobertura de muestreo",
          resAverageTime: "Duración media de procesamiento",
          handTend: "Tendencias de procesamiento manual",
          dataTend: "Distribución de tareas de datos",
        },
        qualityAverage: "Puntaje medio de inspección de calidad",
        scoreTrend: "Tendencia de puntuación de inspección de calidad",
        qualityAverageTime:
          "Duración promedio de espera para la inspección de calidad",
        callCoverage: "Cobertura de inspección de calidad de voz",
        textCoverage: "Cobertura de inspección de calidad de texto",
        checkCount: "Volumen de datos de muestreo",
        siphonCount: "Cantidad de datos extraídos",
        reviewCount: "Revisar el volumen de datos",
        appealCount: "Volumen de datos de apelación",
        checkRate: "Proporción de implementación de muestreo",
        reviewRate: "Proporción de revisión de apelaciones",
        PieTitle: {
          totalCount: "Número total de conversaciones",
          totalCall: "Número de llamadas de voz",
          totalIm: "Número de diálogos de texto",
          inspectionCall: "Inspección de calidad por voz",
          inspectionIm: "Cantidad de inspección de calidad de texto",
          checkCall: "Volumen de muestreo de voz",
          unCheckCall: "Voz no muestreada",
          checkIm: "Volumen de muestreo de texto",
          unCheckIm: "Texto no muestreado",
          reviewCall: "Volumen de revisión de voz",
          reviewIm: "Volumen de revisión de texto",
          unReviewCall: "Cantidad de voz no revisada",
          unReviewIm: "Volumen de texto no revisado",
          unInspectionCall: "Calidad de voz no inspeccionada",
          unInspectionIm: "Texto sin calidad",
        },
        check_rate: "Cobertura de muestreo",
        voice_check_rate: "Cobertura de muestreo de voz",
        text_check_rate: "Cobertura de muestreo de texto",
        sample_execute_time: "Duración promedio de procesamiento de muestreo",
        review_execute_time: "Duración media de las apelaciones",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Reglas de inspección de calidad Kanban",
          agent: "Clasificación de servicio al cliente",
        },
        tableTips: {
          conditions:
            "<Div> Ordene el número total de conversaciones que violen y cumplan las reglas de inspección de calidad. <Br/>\nElementos de inspección de calidad: Nombre del punto de inspección de calidad <br />\nNúmero total de diálogos a través de violaciones: Número total de diálogos a través de violaciones en este elemento de inspección de calidad <br />\nLa proporción de violaciones: la proporción de violaciones = el número total de diálogos a través de violaciones/el número total de diálogos a través de </div>",
          agent:
            "<Div> Ordenar la calificación promedio de los agentes <br />\nNombre del servicio al cliente: Nombre del servicio al cliente <br />\nGrupo de servicio al cliente: el grupo de servicio al cliente donde se encuentra el servicio al cliente (información de la organización en la información del servicio al cliente)<br />\nEl número total de diálogos calificados: el número total de diálogos de inspección de calidad del servicio al cliente <br />\nPuntuación media de la inspección de calidad: la puntuación media de la inspección de calidad del servicio al cliente (suma de puntuación/diálogo total)</div>",
        },
        tips: {
          total:
            "Número total de comunicaciones/diálogos que ingresaron a la tarea de inspección de calidad",
          agentTotal:
            "Número total de servicio al cliente de inspección de calidad que ingresó a la tarea de inspección de calidad",
          avg: "Puntuación media de la puntuación de inspección de calidad",
          violation:
            "El número de violaciones de los elementos de inspección de calidad en todos los diálogos",
          compliance:
            "El número de veces que se cumplen los elementos de inspección de calidad en el proceso de inspección de calidad",
          check: "Total de muestreo manual",
          appeal:
            "Número total de quejas presentadas en el número total de conversaciones",
          review:
            "Número total de revisiones enviadas en el número total de diálogos",
        },
        subTips: {
          violation:
            "Tasa de infracción: número total de infracciones/número total de elementos de inspección de calidad * 100%",
          compliance:
            "Tasa de cumplimiento: número total de cumplimiento/número total de elementos de inspección de calidad * 100%",
          check:
            "Tasa de cobertura de muestreo: total de muestreo manual/total de inspección de calidad * 100%",
          appeal:
            "Tasa de quejas: número total de quejas/inspección de calidad total * 100%",
          review:
            "Tasa de revisión: revisión total/inspección de calidad total * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Configuración del sistema",
          systemName: "Nombre del sistema",
          speakerRecognize: "Identificación de hablantes",
          massage: "Notificación de mensajes",
          evaluate: "Evaluación cognitiva artificial",
          evaluateTip:
            "Después de abrir, seleccione la inspección de muestreo rápida en los detalles de la inspección de calidad para solicitar una evaluación manual.",
          speakerRecognizeTip:
            "El acceso a la grabación de llamadas de terceros a través de la API debe identificar si el canal izquierdo es servicio al cliente o cliente",
          speakerRecognizeTip2:
            "La grabación de un solo canal define el rol del servicio al cliente a través del reconocimiento de texto y la configuración de roles",
          enabledImSendStatusFilter:
            "Filtrado de mensajes de inspección de calidad de texto",
          enabledImSendStatusFilterTips:
            "El contenido de detección de la técnica de palabras del operador de inspección de calidad no incluye mensajes con el estado [Retirado], [No se pudo enviar], [Mensaje sin conexión no leído]",
          lang: "Configuración del idioma del sistema",
          zh: "Chino simplificado",
          en: "English",
          tw: "Chino tradicional",
        },
        template: {
          largeModelSettings: "Configuración del modelo grande",
          indonesian: "Idioma indonesio",
          vietnamese: "Vietnamita",
          portuguese: "Portugués",
          malay: "Malayo",
          french: "Francés",
          spanish: "Español",
          japanese: "Japonés",
          thai: "Tailandés",
          blockSystemSendingMessages:
            "Sistema de blindaje para enviar mensajes",
          textDataDesensitization: "Desensibilización de datos de texto",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Cuando se abre el interruptor, se muestra la lista desplegable del campo de desensibilización (admite múltiples opciones), el campo de desensibilización: nombre, número de teléfono móvil, dirección",
          desensitizingField: "Campo de desensibilización",
          name: "Nombre de la persona",
          cellphoneNumber: "Número de teléfono móvil",
          address: "Dirección",
          dataCleaning: "Limpieza de datos",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Después de la configuración, el diálogo de control de calidad y los datos de la conversación de WeChat empresarial se limpiarán con regularidad y no se pueden restaurar.",
          newCleaningRules: "Agregar reglas de limpieza",
          qualityInspectionRuleApproval:
            "Aprobación de reglas de inspección de calidad",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Después de la apertura, la modificación de las reglas de inspección de calidad debe aprobarse antes de que surta efecto.",
          maskOfflineMessageUnread: "Bloquear mensajes sin conexión no leídos",
          privacyNumberProtection: "Protección de números de privacidad",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Después de abrir, desensibilizará el campo "Teléfono del cliente" en la llamada, que se muestra como 1881 *******',
          shieldingWithdrawn: "Se ha retirado el blindaje",
          maskSendingFailed: "Bloquear el error de envío",
        },
        component: {
          conversation: "Llamada",
          dialogue: "Diálogo",
          weCom: "WeChat empresarial",
          day: "Día",
          week: "Semana",
          month: "Mes",
          year: "Año",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Rango de limpieza",
            dataCleaningTimeSetting:
              "Configuración del tiempo de limpieza de datos",
            afterCleanUpTheData: "Después de limpiar los datos",
            dataAfterComplaintCompletion:
              "Datos una vez completada la apelación",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Ejecución exitosa",
          saveSuccessful: "Ahorre con éxito",
          speechRecognition: "Reconocimiento de voz",
          reExecuteErrorCorrection:
            "Vuelva a ejecutar la corrección de errores",
          confirmExecution: "Confirmar la ejecución",
          voiceDetection: "Detección de voz",
          noiseDetectionPowerLevel: "Detección de ruido powerLevel≤",
          pleaseEnterTheThreshold: "Introduzca el umbral",
          volumeDetectionPowerLevel: "Detección de volumen powerLevel ≥",
          reset: "Restablecer",
          preserve: "Guardar",
        },
        coachTemplate: {
          french: "Francés",
          spanish: "Español",
          japanese: "Japonés",
          thai: "Tailandés",
          loginPopupWindow: "Ventana emergente de inicio de sesión",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Después de abrirlo, se le indicará una ventana emergente al ingresar al sistema de entrenamiento inteligente.",
          title: "Título",
          text: "Texto",
          checkBoxContent: "Marque el contenido de la casilla",
          informedConsentForm: "Formulario de consentimiento informado",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Con el fin de ayudarlo a mejorar sus habilidades de comunicación y habla, así como su experiencia en la aplicación, lo que le permite practicar y recibir comentarios en tiempo real en cualquier momento y en cualquier lugar, recopilaremos la siguiente información sobre usted a través de esta plataforma:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "He leído y entendido completamente este formulario de consentimiento informado y declaro solemnemente que acepto procesar mi información personal confidencial de acuerdo con los propósitos y métodos descritos en este formulario de consentimiento informado.",
        },
      },
      systemIntegration: {
        title: "Configuración del sistema",
        ccIntegrationTips:
          "Documento de descripción del centro de llamadas de acoplamiento de inspección de calidad inteligente Udesk",
        unauthorized: "No autorizado",
        authorized: "Autorizado",
        udeskDomain: "Nombre de dominio del sistema de servicio al cliente",
        udeskToken: "Clave del sistema de servicio al cliente",
        udeskAdminEmail:
          "Cuenta de administrador del sistema de servicio al cliente",
        password: "Contraseña de la cuenta de administrador",
        applyAuth: "Autorización",
        cancelAuth: "Cancelar la autorización",
        reApplyAuth: "Reautorización",
        keyTips:
          "Gestión de antecedentes del sistema de servicio al cliente: autenticación de API Token en el inicio de sesión único",
      },
      customerField: {
        title: "Campo de cliente",
        table: {
          propName: "Nombre del campo",
          propDesc: "Descripción del campo",
          valueType: "Tipo de campo",
          propType: "Cómo crear",
          createTime: "Hora de creación",
          canNotEdit: "Los campos del sistema no admiten la edición",
        },
        edit: "Editar campos",
        new: "Nuevo campo",
      },
      wechatDocking: {
        title: "Micro acoplamiento empresarial",
        formLabels: {
          companyId: "ID de empresa",
          communicationKey: "Clave de comunicación",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL de devolución de llamada de evento",
          applicationName: "Nombre de la aplicación",
          applicationId: "ID de aplicación web",
          applicationSecret: "Aplicación secret",
        },
        formTitle: {
          initialForm: "Configuración inicial",
          synchronizeForm: "Sincronización de la libreta de direcciones",
          settingForm: "Configuración de la aplicación",
        },
        qrCodeUrlTip:
          "Pídale al administrador que escanee el código QR a continuación para desarrollar y vincular aplicaciones",
      },
      taobao: {
        formLabels: {
          name: "Nombre de la tienda",
          account: "Cuenta",
          password: "Contraseña",
          confirm: "Confirmar la contraseña",
          authorization: "Autorización",
          switchOn: "Abrir",
          switchOff: "Cerrar",
        },
        tableLabels: {
          name: "Nombre de la tienda",
          account: "Cuenta",
          authorization: "Autorización",
          updateUser: "Actualizado recientemente",
          updateTime: "Última actualización",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Nombre de dominio de segundo nivel de Weifeng",
          secretKeyTip:
            "La clave secreta del inquilino se obtiene en el punto de acoplamiento del sistema de fondo del sistema Weifeng",
        },
      },
      user: {
        index: {
          name: "Gestión de empleados",
          existingUsers: "Empleados ya",
          deleteSuccess: "La eliminación se realizó correctamente",
          deleteFailed: "Error de eliminación",
        },
        detail: {
          accountPrompt: "Por favor ingrese el buzón",
          resetPassword: "Restablecer contraseña",
          passwordPrompt:
            "Contraseña (combinación de letras y números de 6-14 dígitos)",
          originPasswordPrompt: "Ingrese la contraseña inicial",
          resumePasswordPrompt: "Vuelva a ingresar la contraseña",
          passwordFormWrong:
            "¡Ingrese la contraseña para una combinación de 6 a 14 letras y números!",
          resumePasswordWrong: "¡Dos contraseñas inconsistentes!",
          suppertImageType: "Soporte jpg, gif, png, máximo 500Kb",
          namePrompt: "Por favor ingrese el nombre",
          nickNamePrompt: "Por favor ingrese el apodo",
          telephonePrompt: "Introduzca el número de teléfono",
          roleSelectedPrompt: "Por favor seleccione el rol",
          agentIdPrompt: "Ingrese el número de trabajo",
          disabled: "Deshabilitar",
          enabled: "Habilitar",
          reloadBowerInfo:
            "¿Es necesario actualizar el navegador para cambiar la información del usuario?",
          saveUserError:
            "No se pudo guardar la información del usuario, intente nuevamente más tarde",
          resetPasswordSuccess: "Restablecimiento de contraseña exitoso",
          resetPasswordFailed:
            "No se pudo restablecer la contraseña, inténtelo de nuevo más tarde",
        },
      },
      userGroups: {
        index: {
          name: "Gestión de grupos de empleados",
          existingUserGroups: "Grupo de empleados existentes",
        },
        detail: {},
      },
      role: {
        index: {
          name: "Gestión de roles",
          existingRoles: "Personajes existentes",
        },
        edit: {
          editRole: "Editar un rol",
          createRole: "Crear un rol",
          basicInfo: "Información básica",
          taskPermissions: "Permisos de tarea",
          existingTasks: "Tareas ya existentes",
        },
      },
      anomalousData: {
        title: "Datos de excepción",
        list: {
          selectHolder: "Seleccione la fuente de datos",
          businessTime: "Tiempo de negocios",
          businessId: "Id de negocios",
          export: "Exportar",
        },
      },
      templateComponent: {
        list: {
          title: "Mercado de plantillas",
          name: "Nombre de la plantilla",
          searchHolder: "Ingrese lo que desea consultar",
          update: "Actualizaciones",
          sort: "Método de clasificación",
          moduleType: "Tipo de plantilla",
          moduleSourceType: "Tipo aplicable",
          fetchNum: "Número de veces que se obtiene",
          newest: "Última actualización",
          usedMost: "Consigue la mayor cantidad",
          call: "Llamada",
          dialogue: "Diálogo",
          waiting: "Para ser revisado",
          cancel: "Cancelar la publicación",
          success: "Aprobado, publicado",
          failed: "Revisión rechazada",
          no: "No se puede actualizar",
          ok: "Se puede actualizar",
          all: "Todo",
          delete: "La eliminación se realizó correctamente",
          downOk: "Sin éxito",
          withdraw: "Retiro exitoso",
          updateOk: "Actualización exitosa",
          get: "Obtener",
          width: "Retirada",
          getOk: "Obtener éxito",
          orgName: "Organización",
          nowVersion: "Versión actual",
          lastedVersion: "La última versión",
          appStatus: "Estado de la aplicación",
          actions: "Operación",
          down: "Abajo",
          updateTop: "Actualización",
          check: "Ver",
          deleteWhen: "¿Confirmar la eliminación?",
          yes: "Sí.",
          noo: "No.",
          deleteOk: "Eliminar",
          permissible: "Permitir",
          noPermissible: "No está permitido",
          openUp: "Abierto",
          openDown: "No abierto",
          task: "Tareas de inspección de calidad",
          point: "Punto de inspección de calidad",
          tag: "Etiquetas semánticas",
          muti: "Múltiples elementos",
          inter: "Una pregunta y una respuesta",
          info: "Entidades de información",
          concept: "Palabras conceptuales",
          detail: "Detalles del módulo",
          infomation: "Información básica",
          detailConfig: "Configuración detallada",
          twoEdit: "Segunda edición",
          mustUpdate: "Actualizar ahora",
          descition: "Descripción de la plantilla",
          descitionSize:
            "La descripción de la plantilla no puede exceder las 250 palabras",
          stencilsDetail: "Detalles de la plantilla",
          updatedRecords: "Actualizar registros",
          version: "Número de versión",
          updateDescription: "Actualizar la descripción",
          myModule: "Mi plantilla",
          addModule: "Nuevo módulo",
          publishVersion: "Versión de lanzamiento",
          earliestVersion: "Versión más antigua",
          edit: "Edición",
          add: "Añadido",
          configNo:
            "Los detalles de configuración del módulo no pueden estar vacíos",
          updateNo: "Las instrucciones de actualización no pueden estar vacías",
          editSuccess: "Modificación exitosa",
          saveSuccess: "Ahorre con éxito",
          newer: "Actualizar",
          sumbit: "Presentar",
          cancelName: "Cancelación",
          nameRequired: "El nombre del módulo es un campo obligatorio",
          nameSize: "El nombre del módulo no puede exceder las 16 palabras",
          publishStatus: "Estado de publicación",
          submitUpdate: "Enviar actualizaciones",
          updateVersion: "Versión actualizada",
          updateExplain: "Actualizar las instrucciones",
        },
      },
      customDataSource: {
        amazonEcommerce: "Comercio electrónico de Amazon",
        youtubeVideo: "Vídeos de Youtube",
        amazon: {
          component: {
            queuing: "En la cola",
            notStarted: "Sin empezar",
            inProgress: "En progreso",
            success: "Éxito",
            failed: "Fracaso",
            successfullyCreated: "Éxito en la creación",
            editedSuccessfully: "Éxito de edición",
            operationSucceeded: "Operación exitosa",
            deletedSuccessfully: "La eliminación se realizó correctamente",
            link: "Enlace",
            grabState: "Estado de rastreo",
            updateTime: "Tiempo de actualización",
            grab: "Agarrar",
            edit: "Edición",
          },
          template: {
            amazonEcommerce: "Comercio electrónico de Amazon",
            thisProduct: "Este producto",
            competition: "Productos de la competencia",
            new: "Nuevo",
            edit: "Edición",
            link: "Enlace",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Vídeos de Youtube",
            thisProduct: "Este producto",
            competition: "Productos de la competencia",
            new: "Nuevo",
            edit: "Edición",
            link: "Enlace",
          },
          component: {
            queuing: "En la cola",
            notStarted: "Sin empezar",
            inProgress: "En progreso",
            success: "Éxito",
            failed: "Fracaso",
            successfullyCreated: "Éxito en la creación",
            editedSuccessfully: "Éxito de edición",
            operationSucceeded: "Operación exitosa",
            deletedSuccessfully: "La eliminación se realizó correctamente",
            youTubeVideoLink: "Enlace de vídeo de YouTube",
            grabState: "Estado de rastreo",
            updateTime: "Tiempo de actualización",
            grab: "Agarrar",
            edit: "Edición",
          },
        },
      },
    },
    demo: {
      title: "Inspección de calidad inteligente",
      template: "Seleccionar plantilla de inspección de calidad",
      radio: "Elegir un formato de grabación",
      uploadAudio: "Subir una grabación",
      uploadAudioTip:
        "Subir grabaciones solo admite formatos wav y mp3, tamaño no más de 1G",
      uploadText: "Subir texto",
      uploadTextTip:
        "El texto de carga solo es compatible con los formatos srt y txt, y el tamaño no supera los 10M. Después de cargar el texto, ASR ya no se utilizará para analizar el archivo de grabación.",
      uploadSuccess: "El archivo de grabación se cargó correctamente",
      uploadFailed: "Error en la carga del archivo de grabación",
      startQualityInspection: "Iniciar inspección de calidad",
      qualityInspectionStarted: "En inspección de calidad",
      downloadText: "Descarga de texto",
      checking: "Progreso de la inspección de calidad",
      checkingTip: "Espere unos minutos, tenga paciencia",
      noCheckContent: "No hay resultados de inspección de calidad",
      noTextDownload: "¡No hay texto de inspección de calidad para descargar!",
      pleaseCheckParams:
        "Compruebe si desea seleccionar una plantilla, un tipo de voz y cargar un archivo de grabación",
      inspectionFailed:
        "La inspección de calidad falló, ¡inténtelo de nuevo más tarde!",
    },
    wechatQa: {
      baseInfo: { title: "Información básica" },
      conversationSetting: {
        title: "Configuración de la sesión",
        segmentationStrategy: "Estrategia de división",
        rule: "Estrategia",
        time: "Tiempo de corte",
        daily: "Todos los días",
        passScore: "Puntos de aprobación de la sesión",
        ruleLogic: "Lógica de ejecución",
      },
      message: {
        title: "Evento push",
        table: {
          index: "Número de serie",
          name: "Nombre",
          type: "Método de empuje",
          desc: "Descripción",
          status: "Estado",
        },
        subTitle: "Lista de eventos",
      },
      checkPoint: {
        title: "Modelo de análisis",
        groups: "Agrupación de modelos",
        newClassification: "Nuevo grupo",
        editClassification: "Edición de grupos",
        benchmarkScore: "Puntos de referencia",
        pointCount: "Modelo de análisis común en la clasificación actual",
        openPointCount: "La cantidad habilitada es",
        closedPointCount: "La cantidad desactivada es",
        scoreLowerLimit: "Puntaje mínimo mínimo",
        scoreUpperLimit: "Máximo de puntuación",
        classificationName: "Nombre del grupo",
        classificationNamePlaceHolder: "Ingrese el nombre del grupo",
        classificationRemark: "Descripción de la agrupación",
        classificationRemarkPlaceHolder: "Ingrese la descripción del grupo",
      },
      chartRecord: {
        title: "Conversación empresarial de WeChat",
        table: {
          id: "ID de sesión",
          type: "Tipo de sesión",
          startTime: "Hora de inicio de la sesión",
          endTime: "Hora de finalización reciente de la sesión",
          groupId: "ID de grupo",
          client: "Cliente",
          customer: "Servicio al cliente",
          averageScore: "Calificación promedio de inspección de calidad",
        },
        detail: {
          title: "Detalles de la conversación corporativa de WeChat",
          inspectionInfo: "Información de análisis",
          chartInfo: "Información de la sesión",
          tasks: "Tareas de análisis",
          avgScore: "Promedio de análisis",
          qualified: "Calificado",
          unqualified: "No calificado",
          taskTitle: "Plantilla de análisis aplicable",
          info: "Información de análisis en tiempo real",
          today: "Noticias de hoy",
          history: "Noticias históricas",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Por favor ingrese la nota/apodo",
                pleaseEnterTheGroupChatName:
                  "Ingrese el nombre del chat grupal",
              },
            },
          },
          template: { whole: "Todo" },
        },
      },
      realTimeAnalysis: {
        title: "Análisis en tiempo real",
        table: {
          name: "Nombre de la escena",
          count: "Análisis del número de modelos",
          baseScore: "Puntos de referencia",
          passScore: "Puntos de calificación",
          maxScore: "Máximo de puntuación",
          minScore: "Puntaje mínimo mínimo",
          status: "Estado",
          actions: "Operación",
        },
        detail: {
          title: "Análisis de escenarios en tiempo real",
          form: {
            name: "Nombre de la escena",
            prevCondition: "Condiciones de activación de la escena",
            prevConditionToolTip:
              "Si la condición está vacía, este escenario se analiza de forma predeterminada",
            prevConditionError:
              "Las condiciones de activación de la escena no pueden coincidir con el modelo de análisis",
            scoreInputPlaceholder: "Introducir una puntuación",
            baseScore: "Puntos de referencia",
            status: "Estado",
            passScore: "Puntos de calificación",
            maxScore: "Puntuación máxima",
            minScore: "Puntaje mínimo y límite inferior",
            analysisModel: "Modelo de análisis",
            message: "Activar notificaciones",
            pushType: "Método de empuje",
            createAnalysisModel: "Añadir un modelo",
            scoreUpperLimitError:
              "El límite inferior de la puntuación más baja no puede ser mayor que el límite superior de la puntuación más alta",
            table: {
              name: "Nombre del modelo",
              rule: "Reglas",
              score: "Pestos",
            },
            pushConfigSwitchTip1: "Cuando aparece la escena",
            pushConfigSwitchTip2: "Notificaciones push",
          },
        },
      },
      smartCustomerTag: {
        title: "Etiqueta de cliente inteligente",
        table: {
          name: "Nombre de la regla",
          tags: "Etiqueta de microcliente empresarial",
          status: "Estado habilitado",
          createUser: "Creador",
          createTime: "Hora de creación",
        },
        detail: {
          name: "Nombre de la regla",
          condition: "Establecer reglas",
          logic: "Lógica",
          status: "Estado habilitado",
          tags: "Seleccionar etiquetas",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Número de marcas de clientes" },
        },
        messageFiltering: {
          template: {
            edit: "Edición",
            messageContent: "Contenido del mensaje",
            messageTypeID: "ID de tipo de mensaje",
            messageTypeDescription: "Descripción del tipo de mensaje",
            messageFiltering: "Filtrado de mensajes",
            suitableForFilteringDataWithoutQualityInspection:
              "Adecuado para filtrar datos que no requieren inspección de calidad",
            create: "Crear",
            messageDescription: "Descripción del mensaje",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Tiempo de conversación",
          sessionType: "Tipo de sesión",
          whole: "Todo",
          staff: "Empleados",
          customer: "Cliente",
          callKeywords: "Palabras clave de llamada",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Detalles de la llamada de micro voz empresarial",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Archivo de la sesión",
        voiceCall: "Llamadas de voz",
        noMoreVoiceCalls: "No más llamadas de voz",
        enterpriseWechatVoiceCall: "Llamada de voz corporativa de WeChat",
        enterpriseWechatConversationDetails:
          "Detalles de la conversación corporativa de WeChat",
        previous: "Anterior",
        next: "Siguiente",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Tareas de análisis de microconversaciones empresariales",
          enterpriseAndMicroVoiceAnalysisTask:
            "Tareas de análisis de microvoz empresarial",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Análisis de chat grupal de WeChat empresarial",
      },
      wechatQaSettingEntry: { messageFiltering: "Filtrado de mensajes" },
    },
    businessAnalysis: {
      keyword: "Nombre del informe",
      createUser: "Creador",
      createTime: "Hora de creación",
      updateTime: "Tiempo de actualización",
    },
    semanticIntelligence: {
      applicationMessageType: "Tipo de mensaje de aplicación",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "Mejore la configuración",
              generatePromptLanguage: "Generar mensaje",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Si cumple",
              gradedEvaluation: "Subevaluación",
            },
          },
          training: {
            index: {
              pleaseEnter: "Por favor ingrese",
              pleaseGeneratePrompt: "Genere un mensaje",
              pleaseSelectADialogue: "Por favor seleccione la conversación",
              training: "Entrenamiento",
              dataType: "Tipo de datos:",
              voiceCall: "Llamadas de voz",
              textDialogue: "Diálogo de texto",
              generate: "Generar",
              qualityInspectionResults:
                "Resultados de la inspección de calidad:",
              hit: "Hit:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Nombre del modelo",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Ingrese el nombre del modelo, por ejemplo: si el servicio al cliente no está actuando",
            modelDescription: "Descripción del modelo",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Puede describir en detalle el contenido que necesita probar para facilitar la comprensión del modelo. Por ejemplo, si el servicio al cliente no hace nada, es decir, el servicio al cliente ha respondido a la pregunta del cliente, pero la pregunta del cliente no se ha procesado en todo el artículo o el contenido de la respuesta no tiene nada que ver con la respuesta del cliente.",
            validity: "Eficacia",
            effective: "Efectivo",
            invalid: "No válido",
            type: "Tipo",
            hintLanguage: "Mensaje",
            editIntelligentModel: "Edición de modelos inteligentes",
            createANewIntelligentModel: "Nuevo modelo inteligente",
          },
        },
        index: {
          modelName: "Nombre del modelo",
          modelDescription: "Descripción del modelo",
          type: "Tipo",
          whetherItMeetsTheRequirements: "Si cumple",
          gradedEvaluation: "Subevaluación",
          validity: "Eficacia",
          intelligentModel: "Modelo inteligente",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Aplicable a la inspección de calidad subjetiva (como si la actitud del servicio de inspección de calidad es entusiasta, si el servicio es paciente, etc.)",
        },
      },
      clusterAnalysis: "Análisis de conglomerados",
      speechMining: "Excavación de palabras",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Admite la importación por lotes de entidades de información a través de la interfaz API, el nombre de la API no admite la edición después de guardar",
        },
        component: { nothing: "Ninguno" },
      },
      knowledgeBase: {
        index: {
          classification: "Clasificación",
          import: "Importar",
          importKnowledgeBase: "Importar una base de conocimientos",
          moveKnowledgePointsInBatch:
            "Puntos de conocimiento de movimiento por lotes",
          moveTo: "Mover a:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Seleccione los puntos de conocimiento que deben moverse por lotes",
        },
        detail: {
          index: {
            intrasentence: "En la oración",
            afterProblem: "Después de la pregunta",
          },
        },
      },
      semanticTags: { component: { nothing: "Ninguno" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Palabras sensibles",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Palabras sensibles: las palabras sensibles se pueden configurar con palabras de atención empresarial, que se utilizan para encontrar palabras sensibles en la opinión pública.",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Nuevas palabras sensibles",
            editSensitiveWords: "Editar palabras sensibles",
            sensitiveWords: "Palabras sensibles",
          },
          template: {
            sensitiveWords: "Palabras sensibles",
            sensitiveWordsCannotBeEmpty:
              "Las palabras sensibles no pueden estar vacías",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Ingrese palabras sensibles y use ',' para separar entre varias palabras sensibles",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Seleccione el escenario de la aplicación",
            keyWord: "Palabras clave",
            pleaseEnterKeywords: "Por favor ingrese las palabras clave",
            keywordLengthWithinCharacters:
              "Longitud de la palabra clave dentro de 40 caracteres",
            dataSources: "Fuentes de datos",
            pleaseSelectADataSource: "Seleccione la fuente de datos",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "El clúster actual se ha completado, la reedición generará un nuevo dato de clúster y no sobrescribirá los datos de clúster completados, confirme",
            savingSucceeded: "Ahorre con éxito",
            clusteringStart: "Inicio de agrupamiento",
            clustering: "Aglomerado",
            edit: "Edición",
            newlyBuild: "Nuevo",
            clusterName: "Nombre del clúster",
            pleaseEnterAName: "Por favor ingrese el nombre",
            withinCharactersOfTheName:
              "Longitud del nombre dentro de 40 caracteres",
            speaker: "El hablante",
            pleaseSelectASpeaker: "Por favor elija un hablante",
            sessionDate: "Fecha de la sesión",
            pleaseEnterTheSessionDate: "Introduzca la fecha de la sesión",
            selectUpToMonthOfHistoricalSessionData:
              "Seleccione hasta 1 mes de datos de sesión históricos",
            automaticallyClusterDailyNewData:
              "Aglomerado automático Agregar datos diariamente",
            conversationChannel: "Canales de conversación",
            pleaseSelectAConversationChannel:
              "Seleccione el canal de conversación",
            applicationScenario: "Escenarios de aplicación",
            clusteringRules: "Reglas de agrupamiento",
            incompleteClusteringRuleSettings:
              "Configuración incompleta de la regla de agrupamiento",
            startClustering: "Inicio de la agrupación",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Seleccione la tarea de inspección de calidad",
              beEqualTo: "Igual a",
            },
          },
        },
        detail: {
          index: {
            theme: "Tema",
            numberOfCorpora: "Número de corpus",
            corpusCoverage: "Cobertura de corpus",
            clusterDetails: "Detalles de agrupamiento",
          },
          ranking: {
            hotWords: "Palabras calientes",
            numberOfCorpora: "Número de corpus",
            speaker: "El hablante",
            sit: "Siéntate",
            passenger: "Invitado",
            hotSentence: "Frase caliente",
            corpusDetails: "Detalles del corpus",
          },
        },
        index: {
          delete: "Eliminar",
          deletionSucceeded: "La eliminación se realizó correctamente",
          clusterName: "Nombre del clúster",
          speaker: "El hablante",
          creationTime: "Hora de creación",
          state: "Estado",
          operation: "Operación",
          see: "Ver",
          edit: "Edición",
          clusterAnalysis: "Análisis de conglomerados",
          newCluster: "Nuevo clúster",
          clusteringStatus: "Estado de agrupamiento",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Ingrese palabras clave para buscar objeciones",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Biblioteca de palabras",
                recommendedScripts: "Hablando recomendado",
                common: "Total",
                strip: "Artículo",
                scriptFavorites: "Favoritos",
              },
              conditionPostfix: {
                detectRoles: "Detectar roles",
                detectionRange: "Rango de detección",
                sentence: "Oración",
              },
              customerFilter: {
                scriptFiltering: "Filtro de palabras",
                cancel: "Cancelación",
              },
              index: {
                viewDetails: "Ver detalles",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "¿Confirma que quiere darse por vencido? No se recuperará después de abandonar",
                abandonment: "Abandonar",
                deletionSucceeded: "La eliminación se realizó correctamente",
                passenger: "Invitado",
                sit: "Siéntate",
                adopted: "Adoptado",
                determine: "Determinar",
                cancel: "Cancelación",
                adopt: "Adopción",
              },
              pageRightFrom: {
                savingSucceeded: "Ahorre con éxito",
                pleaseEnterTheScriptContent:
                  "Ingrese el contenido del discurso",
                pleaseSelectAFavoriteGroup: "Seleccione el grupo de favoritos",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Biblioteca de palabras de procesamiento de objeciones",
              },
              pageRightTable: {
                index: { pleaseChooseToAdoptScript: "Elija adoptar palabras" },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Favoritos de manejo de objeciones",
            },
            pageRightTable: {
              likeCancel: "Me gusta cancelar",
              likeSuccessfully: "Como éxito",
              adoptScripts: "Adopta palabras",
              operation: "Operación",
              viewConversationDetails: "Ver detalles de la conversación",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Biblioteca de palabras de punto de venta de productos",
              },
              pageRightTable: { keyPointsOfScript: "Puntos de conversación" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Favoritos de manejo de objeciones",
              newGroup: "Nuevo grupo",
              editGroup: "Edición de grupos",
              addGroup: "Añadir grupos",
              savingSucceeded: "Ahorre con éxito",
              groupName: "Nombre del grupo",
              pleaseEnterTheGroupName: "Ingrese el nombre del grupo",
              groupNameWithinCharacters:
                "Longitud del nombre del grupo dentro de 20 caracteres",
              deletionSucceeded: "La eliminación se realizó correctamente",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings: "Configuración recomendada",
                },
                rightForm: {
                  savingSucceeded: "Ahorre con éxito",
                  intelligentRecommendation: "Recomendación inteligente",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Las excelentes palabras que se han adoptado se muestran automáticamente en la barra lateral de la conversación con objeciones similares, y los empleados pueden ver directamente las palabras recomendadas para aprender en la página de detalles de la revisión de la conversación.",
                  objectionsIncludedByDefault:
                    "Objeciones incluidas por defecto",
                  addRecommendationTriggerSemantics:
                    "Aumentar la semántica de activación recomendada",
                  remindEmployeesToView:
                    "Recuerde a los empleados que verifique",
                  shareWithMoreEmployees: "Compartir con más empleados",
                  allHands: "Todos los empleados",
                },
                smartToolbar: {
                  selectSemanticTag: "Seleccionar etiquetas semánticas",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "¿Se exportan todos los registros de datos en el grupo actual?",
                newScript: "Nuevas palabras",
                editingScript: "Editar palabras",
                savingSucceeded: "Ahorre con éxito",
                objection: "Objeción",
                pleaseEnterAScriptObjection: "Ingrese la objeción",
                speechTechnique: "Hablando",
                pleaseEnterTheScriptContent:
                  "Ingrese el contenido del discurso",
                grouping: "Agrupación",
                pleaseSelectAFavoriteGroup: "Seleccione el grupo de favoritos",
                adoptScripts: "Adopta palabras",
                passenger: "Invitado",
                sit: "Siéntate",
                numberOfViews: "Vistas",
                useful: "Útil",
                operation: "Operación",
                edit: "Edición",
                delete: "Eliminar",
                deletionSucceeded: "La eliminación se realizó correctamente",
                scriptRecommendationSettings: "Configuración recomendada",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Biblioteca de palabras",
          scriptMining: "Excavación de palabras",
          objectionHandlingScripts: "Manejo de objeciones",
          productSellingPointScripts: "Punto de venta del producto",
        },
        sellingPoint: {
          index: { newCluster: "Nuevo clúster" },
          components: {
            callDetailModal: {
              sessionDetails: "Detalles de la sesión",
              cancel: "Cancelación",
              determine: "Determinar",
              choice: "Selección",
            },
            pageCenterTable: {
              scriptOverview: "Descripción general del habla",
            },
            pageLeftTable: {
              enterSearchKeywords: "Ingrese las palabras clave de búsqueda",
              keyWord: "Palabras clave",
            },
            pageRightTable: {
              index: {
                viewDetails: "Ver detalles",
                scriptLibrary: "Biblioteca de palabras",
                scriptRefinement: "Refinamiento de palabras",
                adoptScript: "Adopta palabras",
              },
              tagsEdit: { keyPointsOfScript: "Puntos de conversación" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Biblioteca de palabras de punto de venta de productos",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Puntos de venta incluidos por defecto",
                  },
                },
                index: {
                  keyWord: "Palabras clave",
                  pleaseSelectAKeyword: "Seleccione las palabras clave",
                  keyPointsOfScript: "Puntos de conversación",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Ingrese los puntos principales de las palabras",
                  viewDetails: "Ver detalles",
                  pleaseChooseToAdoptScript: "Elija adoptar palabras",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Lógica de coincidencia",
            nLPMatching: "Partido PNL",
            strictlyMatchByWord: "Estrictamente emparejado por palabra",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Nombre del tipo de mensaje",
          messageTypeID: "ID de tipo de mensaje",
          messageTypeDescription: "Descripción del tipo de mensaje",
          applicationMessageType: "Tipo de mensaje de aplicación",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Es adecuado para acceder a datos de diferentes canales para la inspección de calidad y debe clasificarse de acuerdo con la identificación del canal.",
        },
        detail: {
          index: {
            messageTypeName: "Nombre del tipo de mensaje",
            pleaseEnterAMessageTypeName:
              "Introduzca el nombre del tipo de mensaje",
            messageTypeDescription: "Descripción del tipo de mensaje",
            describeYourMessageTypeInDetail:
              "Descripción detallada de su tipo de mensaje",
            editMessageType: "Editar el tipo de mensaje",
            newMessageType: "Nuevo tipo de mensaje",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Seleccione los miembros del equipo",
        existingFilter: "Filtros ya existentes",
        pleaseSelect: "Por favor elija",
        filterCondition: "Condiciones de filtro",
        areYouSureToDeleteTheCurrentFilter:
          "¿Confirma la eliminación del filtro actual?",
        sessionDate: "Fecha de la sesión",
        staff: "Empleados",
        customer: "Cliente",
        pleaseEnterCustomerName: "Por favor ingrese el nombre del cliente",
        keyWord: "Palabras clave",
        whole: "Todo",
        pleaseEnterSessionKeywords:
          "Introduzca las palabras clave de la sesión",
        sessionDuration: "Duración de la sesión",
        sessionLabel: "Escena de conversación",
        pleaseSelectASessionLabel: "Seleccione la escena de la sesión",
        commentTopic: "Temas de comentarios",
        pleaseSelectACommentTopic:
          "Por favor seleccione el tema del comentario",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "¿Se confirma la anulación del filtro actual?",
        overlayFilter: "Filtro de cobertura",
        NewFilter: "Nuevo filtro",
        newFilter: "Nuevo filtro",
        filterName: "Nombre del filtro",
        pleaseEnter: "Por favor ingrese",
        allCall: "Todas las llamadas",
        percentage: "Porcentaje",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'Admite la búsqueda de varias palabras clave, la relación "o" está separada por una coma (chino e inglés), y la relación "y" está separada por una coma, como:',
          callRecordID: "ID de registro de llamadas",
          pleaseFillInTheCallRecordID:
            "Por favor complete el ID del registro de llamadas",
          minute: "Minutos",
          second: "Segundos",
          whole: "Todo",
          callRecording: "Grabación de llamadas",
          enterpriseWechat: "WeChat empresarial",
          conversationChannel: "Canales de conversación",
          pleaseSelectASessionChannel: "Seleccione el canal de conversación",
          learningStatus: "Estado de aprendizaje",
          pleaseSelectLearningStatus: "Seleccione el estado de aprendizaje",
          notLearned: "Sin aprender",
          learned: "Ha aprendido",
          Minutes: "Minutos",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "El filtro está vacío y no se admiten nuevos filtros.",
          filterAddedSuccessfully: "¡El filtro se agregó con éxito!",
          failedToAddFilter: "¡La adición de filtros falló!",
          filterDeletedSuccessfully: "¡El filtro se eliminó con éxito!",
          filterDeletionFailed: "¡La eliminación del filtro falló!",
          filterOverwriteSucceeded: "¡El filtro cubre el éxito!",
          filterOverwriteFailed: "¡Falló la cobertura del filtro!",
          theme: "Tema",
          pleaseSelectATheme: "Por favor, seleccione el tema",
          keyEvents: "Eventos clave",
          pleaseSelectAKeyEvent: "Seleccione el evento clave",
        },
        filterItem: {
          startRetesting: "Iniciar la redetección",
          time: "Tiempo",
          talkTime: "Duración de la llamada",
          keyEvents: "Eventos clave",
          totalImplementationRateOfSupervisionItems:
            "Tasa general de ejecución de la supervisión",
          sort: "Ordenar:",
          seeMore: "Ver más",
        },
        filterStatistics: {
          conversation: "Llamada",
          weChat: "WeChat",
          quantity: "Cantidad",
        },
      },
      caseBase: {
        index: {
          copyTo: "Copiar a",
          pleaseSelectTheLocationToMove:
            "Por favor, seleccione la ubicación para mover!",
          folderCopiedSuccessfully: "¡La carpeta se copió correctamente!",
          cancelCollectionSuccessfully: "¡Cancelar la colección con éxito!",
          collectionSucceeded: "¡La colección es exitosa!",
          starCaseBase: "Biblioteca de casos estándar estrella",
          move: "Mover",
          rename: "Cambiar el nombre",
          delete: "Eliminar",
          folderMovedSuccessfully: "¡La carpeta se movió con éxito!",
          fileMovedSuccessfully: "¡El archivo se movió con éxito!",
          fileMoveFailed: "¡Error en el movimiento de archivos!",
          folderAddedSuccessfully:
            "¡La adición de carpetas se realizó correctamente!",
          folderRenameSucceeded:
            "¡El cambio de nombre de la carpeta se realizó correctamente!",
          fileRenameSucceeded:
            "¡El cambio de nombre del archivo se realizó correctamente!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "No se puede recuperar después de eliminar la carpeta. ¿Confirma la eliminación?",
          deleteSucceeded: "¡La eliminación se realizó correctamente!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Después de eliminar el archivo, no se puede recuperar. ¿Confirma la eliminación?",
          share: "Total",
          folders: "Carpetas,",
          calls: "Llamadas",
          newFolder: "Agregar carpeta",
          modifyFolder: "Modificar la carpeta",
          title: "Título",
          pleaseEnter: "Por favor ingrese",
          selectMoveLocation: "Elegir una ubicación móvil",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Recordatorio de aprendizaje",
          subscriptionDynamics: "Noticias de suscripción",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "La sesión a la que se suscribe (ID",
            thereAreDynamicUpdates: "Hay actualizaciones dinámicas",
            newCommentsReplies: "Agregar comentario/respuesta",
            strip: "Artículo",
            newHelp: "Nueva ayuda",
            goAndWatch: "Ve a mirar",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Respondió a mi comentario",
            replySucceeded: "¡La respuesta es exitosa!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Me en los comentarios",
            replySucceeded: "¡La respuesta es exitosa!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Compartir para tu llamada",
            shareCasesForYou: "Comparte el caso para ti",
          },
        },
        study: {
          index: {
            learned: "Ha aprendido",
            ignored: "Ignorado",
            study: "Aprender",
            ignore: "Ignorar",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Importación exitosa",
          account: "Cuenta",
          password: "Contraseña",
          cover: "Cobertura",
          skip: "Saltar",
          import: "Importar",
          importEmployees: "Importar empleados",
          importPrompt: "Consejos de importación",
          confirm: "Determinar",
          theDataContainsDuplicateAccountsOrNames:
            "Los datos de la barra se duplican en la cuenta o el nombre",
          allCoverage: "Cobertura total",
          skipAll: "Saltar todo",
          headPortrait: "Avatar",
          fullName: "Nombre",
          nickname: "Apodo",
          excellentStaff: "Excelente personal",
          yes: "Sí.",
          no: "No.",
          department: "Departamento",
          accountNumberemail: "Cuenta (correo electrónico)",
          telephone: "Teléfono",
          jobNumber: "Número de trabajo",
          role: "Rol",
          pleaseEnterANameOrPhoneSearch:
            "Ingrese su correo electrónico, nombre o teléfono para buscar",
          person: "Gente)",
          AddEmployee: "Agregar empleados",
        },
        edit: {
          jobNo: "Número de trabajo",
          udeskCustomerServiceSystemIdentity:
            "Identidad del sistema de servicio al cliente de Udesk",
          enterpriseWechatIdentity: "Identidad corporativa de WeChat",
          inputID: "Identificación de entrada",
          newIdentityBinding: "Agregar enlace de identidad",
          identityBinding: "Vinculación de identidad",
          avatarGreaterThan500kb: "Avatar más grande que 500Kb",
          uploadSuccessful: "Subir correctamente",
          upload: "Subir",
          supportJPGGIFAndPNGUpTo500kb: "Soporte jpg, gif, png, máximo 500Kb",
          yes: "Sí.",
          no: "No.",
          accountNumber: "Cuenta",
          pleaseEnterTheAccountNumber: "Por favor ingrese el número de cuenta",
          initialPassword: "Contraseña inicial",
          pleaseInputAPassword: "Por favor ingrese la contraseña",
          confirmPassword: "Confirmar la contraseña",
          thePasswordsEnteredTwiceAreDifferent:
            "¡La contraseña ingresada dos veces no es la misma!",
          headPortrait: "Avatar",
          fullName: "Nombre",
          pleaseEnterYourName: "Por favor ingrese el nombre",
          nickname: "Apodo",
          department: "Departamento",
          excellentStaff: "Excelente personal",
          sessionIdentity: "Identificación de la sesión",
          markTheSessionRecordAsBelongingToTheEmployee:
            "El registro de sesión de marcado pertenece al empleado",
          telephone: "Teléfono",
          jobNumber: "Número de trabajo",
          role: "Rol",
          pleaseSelectARole: "Por favor seleccione el rol",
          preservation: "Guardar",
          cancel: "Cancelación",
          addedSuccessfully: "¡Añadido con éxito!",
          modificationSucceeded: "¡La modificación es exitosa!",
          newEmployees: "Nuevos empleados",
          editEmployee: "Personal de edición",
        },
        detail: {
          viewDetails: "Ver detalles",
          accountNumber: "Cuenta",
          headPortrait: "Avatar",
          fullName: "Nombre",
          nickname: "Apodo",
          department: "Departamento",
          excellentStaff: "Excelente personal",
          yes: "Sí.",
          no: "No.",
          sessionIdentity: "Identificación de la sesión",
          markTheSessionRecordAsBelongingToTheEmployee:
            "El registro de sesión de marcado pertenece al empleado",
          telephone: "Teléfono",
          jobNumber: "Número de trabajo",
          role: "Rol",
        },
      },
      roleManage: {
        list: {
          menu: "Menú",
          functionalCategory: "Categorías funcionales",
          functionalSubclass: "Categoría funcional",
          deletedSuccessfully: "La eliminación se realizó correctamente",
          NewRole: "Nueva función",
          role: "Rol",
          dataPermissions: "Permisos de datos:",
          edit: "Edición",
          areYouSureYouWantToDeleteTheRole:
            "Determinar la eliminación de roles",
          determine: "Determinar",
          cancel: "Cancelación",
          delete: "Eliminar",
          applicationPermission: "Permisos de aplicación",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Solo puede ver sus propios datos de sesión",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Puede ver los datos de la sesión de este departamento y sus departamentos subordinados",
          menu: "Menú",
          functionalCategory: "Categorías funcionales",
          functionalSubclass: "Categoría funcional",
          successfullyAdded: "Nuevo éxito",
          modifiedSuccessfully: "Modificación exitosa",
          edit: "Edición",
          cancel: "Cancelación",
          confirm: "Confirmación",
          roleName: "Nombre del rol",
          pleaseEnterTheRoleName: "Introduzca el nombre del rol",
          dataAuthority: "Permisos de datos",
          youCanViewCompanyWideSessionData:
            "Puede ver los datos de la sesión de toda la empresa",
          applicationPermission: "Permisos de aplicación",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Flujo de sesión",
          generateProcess: "Proceso de generación",
          addProcess: "Agregar un proceso",
          cancel: "Cancelación",
          complete: "Terminado",
          edit: "Edición",
          theSettingWillBeSuccessfulLater:
            "¡La configuración será exitosa más tarde!",
          previous: "Anterior",
          next: "Siguiente",
          debuggingMode: "Modo de depuración",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Después de la activación, si el mensaje coincide con eventos clave, elementos de supervisión, reglas de etiquetas de clientes o reglas de puntos de conocimiento, se mostrará el nombre de la regla con el que coincide el mensaje y el nombre del punto de conocimiento de la base de conocimiento.",
          seeLater: "Mira más tarde",
          determine: "Determinar",
          conversationalInsights: "Información de conversación",
          customerInsight: "Información del cliente",
          knowledgePointRecommendation: "Puntos de conocimiento recomendados",
          comment: "Comentarios",
          sessionQuality: "Calidad de sesión",
          setToListenLaterSuccessfully:
            "Configuración para escuchar con éxito más tarde!",
          listenLater: "Escuche más tarde",
          seekCounselingSuccess: "¡Busque el éxito del asesoramiento!",
          altogether: "Total",
          appearsAt: 'Aparece en el lugar"',
          commentsPublishedSuccessfully: "Comentarios publicados con éxito",
          callRecording: "Grabación de llamadas",
          addedSuccessfully: "Agregar con éxito",
          shareWithColleagues: "Compartir con colegas",
          shareWithCustomers: "Compartir con los clientes",
          resumptionDetails: "Detalles de la revisión",
          addToCaseBase: "Agregar a la biblioteca de casos",
          shareCases: "Compartir caso",
          seekCounseling: "Buscando asesoramiento",
          sessionRecord: "Registro de sesión",
          businessRecord: "Registros comerciales",
          keywordSearch: "Búsqueda de palabras clave",
          whole: "Todo",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Por favor ingrese un comentario, ingrese @ para notificar a otros, ingrese # para agregar un tema",
          subscriptionSession: "Sesiones de suscripción",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Después de suscribirse, recibirá un recordatorio de notificación cuando haya nuevas solicitudes de ayuda, comentarios o respuestas.",
        },
      },
      coachTask: {
        index: {
          employeeName: "Nombre del empleado",
          customerName: "Nombre del cliente",
          source: "Fuente",
          viewSessionRecords: "Ver registros de sesión",
          coachingStatus: "Estado de asesoramiento",
          helpTime: "Tiempo de ayuda",
          counselingTime: "Tiempo de tutoría",
          helpContent: "Contenido de ayuda",
          coachingOperation: "Operación de asesoramiento",
          areYouSureYouWantToIgnoreIt: "¿Estás seguro de ignorar?",
          determine: "Determinar",
          cancel: "Cancelación",
          ignoreHelp: "Ignorar la ayuda",
          coaching: "Tutoría",
          viewCoaching: "Ver tutorías",
          areYouSureToReturnToCounseling:
            "¿Estás seguro de volver al asesoramiento?",
          returnToPendingCoaching: "Restaurar para recibir asesoramiento",
          tutor: "Consejería",
          helpSeekingStatus: "Estado de ayuda",
          recentCoachingTime: "Tiempo de tutoría reciente",
          operation: "Operación",
          ignoreSuccess: "¡Ignore el éxito!",
          recoverySucceeded: "¡La recuperación es exitosa!",
          successfulCoaching: "¡El entrenamiento es exitoso!",
          myMission: "Mi misión",
          selectCoachingStatus: "Elegir el estado de tutoría",
          whole: "Todo",
          iAskedForHelp: "Yo pedí ayuda",
          signOut: "Salir",
          submit: "Presentar",
          helpPerson: "Ayuda",
          customer: "Cliente",
          toBeCoached: "Esperando asesoramiento",
          counselingSuggestions: "Sugerencias de asesoramiento",
          pleaseEnterCoachingSuggestions:
            "Por favor ingrese las sugerencias de tutoría",
        },
      },
      coachTaskCenter: {
        title: "Centro de tareas",
        list: {
          taskName: "Nombre de la tarea",
          lessonGroup: "Clasificación del curso",
          lessonName: "Curso",
          taskType: "Tipo de tarea",
          taskStatus: "Estado de la tarea",
          taskExecutionInfo: "Aplicación",
          totalCount: "Número de personas que deben ejecutarse",
          executeCount: "Número de personas ejecutadas",
          executeRate: "Tasa de ejecución",
          completeCount: "Número de completados",
          completeRate: "Tasa de finalización",
          commitCount: "Número de personas que cumplen con los estándares",
          commitRate: "Tasa de cumplimiento",
          excellentRate: "Tasa de excelencia",
          taskTime: "Tiempo de tarea",
          option: "Operación",
          edit: "Edición",
          taskDetail: "Datos de la tarea",
          delete: "Eliminar",
          new: "Nuevo lanzamiento",
          taskTypeAll: "Todo",
          taskTypePractice: "Ejercicios",
          taskTypeTest: "Examen",
          taskStatusNotStarted: "Sin empezar",
          taskStatusRunning: "En progreso",
          taskStatusEnd: "Terminado",
          taskStatusInvalid: "No en vigor",
          unlimitedTime: "Tiempo ilimitado",
          to: "Para",
          deleteConfirm:
            "Después de la eliminación, todos los registros de aprendizaje de esta tarea se borrarán. ¿Confirmo la eliminación?",
          deleteSuccess: "La tarea se eliminó con éxito",
        },
        edit: {
          addTask: "Nueva tarea de publicación",
          editTask: "Editar tareas de publicación",
          publishSuccess: "Lanzamiento exitoso",
          publishFailed: "Fracaso de publicación",
          updateSuccess: "Actualización exitosa",
          updateFailed: "Error de actualización",
          cannotBeNull: "No puede estar vacío",
          explain: "Descripción",
          taskDescExtra: "(El contenido aquí se mostrará en la entrada {0})",
          taskTargetObj: "Publicar objetos",
          isEnable: "Estado efectivo",
          dialogueMode: "Modo de diálogo",
          voice: "Voz",
          text: "Texto",
          userPortrait: "Retrato de usuario",
          difficultyLevel: "Dificultad",
          limitDuration: "Límite de tiempo",
          tipLabel: "Consejos para hablar",
          tipTooltip: "¿Permitir a los estudiantes ver palabras de referencia?",
          limitTimes: "Número de exámenes",
          time: "Tiempo",
          gradingStandart: "Criterios de puntuación",
          gradingStandartDescAffix:
            "(La puntuación total del proceso de diálogo actual es",
          gradingStandartDescSuffix:
            "Puntos, palabras {0} puntos, emociones {1} puntos, velocidad del habla {2} puntos)",
          completeStandart: "Requisitos de finalización de tareas",
          byTimes: "Por número de veces",
          byScore: "Por puntuación",
          completeByTimes: "Número de ejercicios",
          times: "VEsegundo",
          score: "Puntos",
          anyTimes: "Cualquier vez",
          getScore: "Puntuación",
          offLimitDuration: "Tiempo ilimitado",
          onLimitDuration: "Límite de tiempo largo",
          commitScore: "Estándar estándar, puntuación",
          excellentScore: "Excelente estándar, puntuación",
          offLimitTime: "Tiempo ilimitado",
          onLimitTime: "Tiempo limitado",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Hola," },
          callDynamic: {
            today: "Hoy",
            yesterday: "Ayer",
            sessions: "Conversaciones",
          },
          todoList: {
            strip: "Artículo",
            theSessionIsAddedTolistenLater:
              'La sesión se agregó a "Escuchar más tarde"',
            askYouForHelp: "Pedir ayuda",
            iHopeToContactYouLater: "Espere contactar más tarde",
            lookingForwardToSendingTheInformationLater:
              "Espero enviar información más tarde",
            dialogueRisk: "La sesión tiene puntos de riesgo",
            tutored: "Tutorial",
            goToCounseling: "Ir a la consejería",
            contacted: "Contactado",
            hasBeenSent: "Enviado",
            areYouSureYouWantToIgnoreIt: "¿Estás seguro de ignorar?",
            determine: "Determinar",
            cancel: "Cancelación",
            ignore: "Ignorar",
            checked: "Visto",
          },
          callListItem: {
            strip: "Artículo",
            learned: "Ha aprendido",
            keyEvents: "Eventos clave",
            totalImplementationRateOfSupervisionItems:
              "Tasa general de ejecución de la supervisión",
          },
        },
        index: {
          operationSucceeded: "¡Operación exitosa!",
          sessionDynamics: "Dinámica de conversación",
          my: "Mi",
          department: "Departamento",
          company: "Empresa",
          toDoList: "Cosas por hacer",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Volumen de llamadas efectivo (a)",
          bestPractices: "Mejores prácticas",
          averageDurationOfASingleCallminutes:
            "Duración media de una sola llamada (minutos)",
          totalCallVolumePCs: "Volumen total de llamadas (a)",
          totalTalkTimehours: "Duración total de la llamada (horas)",
          speakingPercentagepercentage: "Porcentaje de habla (porcentaje)",
          maximumContinuousSpeakingTimeminutes:
            "Duración máxima del habla continua (minutos)",
          numberOfIndepthDiscussionsPCs:
            "Número de discusiones en profundidad (a)",
          maximumCustomerTalkminutes: "El cliente más largo habla (minutos)",
          questionFrequencyPCs: "Frecuencia de preguntas (a)",
          averageValue: "Promedio",
          selectDate: "Seleccionar fecha",
          personnel: "Personal",
          pleaseSelect: "Por favor elija",
          callRange: "Alcance de la llamada",
          conversationActivity: "Actividad de conversación",
          conversationAction: "Acción de sesión",
          keyEvents: "Eventos clave",
          callRecord: "Grabación de llamadas",
          filter: "Filtrar:",
          conversation: "Llamada",
          minute: "Minutos",
          sale: "Empleados",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Enero",
            numberOfCalls: "Número de llamadas",
            accountInformation: "Información de la cuenta",
            month: "Mes",
            today: "Hoy",
            customerDetails: "Detalles del cliente",
            searchKeywordsInCommunicationContent:
              "Buscar palabras clave en contenido de comunicación",
            allMembers: "Todos los miembros",
            reset: "Restablecer",
            query: "Consulta",
            allTrip: "Visualización completa del viaje",
            contactOverview: "Descripción general de los contactos",
            lastContact: "Último contacto",
            nextContact: "El próximo contacto",
            previous: "Anterior",
            next: "Siguiente",
            viewDetails: "Ver detalles",
            contactDynamics: "Noticias de contacto",
            lastYear: "El año pasado",
            lastCall: "Llamada más reciente",
            hotTopicsForDiscussion: "Temas de discusión",
            totalInTheLastYear: "Un total del año pasado",
            callTimes: "Llamada",
          },
        },
        list: {
          index: {
            risk: "Riesgo",
            have: "Sí.",
            nothing: "Ninguno",
            channel: "Canales",
            callRecording: "Grabación de llamadas",
            enterpriseWeChatConversation: "Micro conversación empresarial",
            enterpriseWeChatCall: "Micro llamada empresarial",
            followUpSuggestions: "Sugerencias de seguimiento",
            customer: "Centro de atención al cliente",
            followUp: "Seguimiento",
            personInCharge: "Responsable",
            recentFollowup: "Personas de seguimiento recientes",
            lastContactTime: "Última hora de contacto",
            operation: "Operación",
            see: "Ver",
            exceed: "Más de",
            noActivityForMoreThanDays: "Días sin actividad",
            lately: "Recientemente",
            risksOccurredInTheLastDays: "Semántica de riesgo en días",
            savedSuccessfully: "Ahorre con éxito",
            today: "Hoy",
            allDepartments: "Todos los departamentos",
            allMembers: "Todos los miembros",
            customerName: "Nombre del cliente",
            reset: "Restablecer",
            query: "Consulta",
            customers: "Clientes",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Muestre el estado de contacto entre empleados y clientes. Entre ellos, la burbuja rosa es el mensaje del cliente y el azul es el mensaje del empleado. Cuanto mayor sea la proporción de discursos, mayor será la burbuja.",
            riskAllocation: "Configuración de riesgos",
            preservation: "Guardar",
            pleaseEnter: "Por favor ingrese",
            noActivityForDays: "Días sin actividad",
            riskSemanticsWithinDays: "Semántica de riesgo en días",
            addRiskSemantics: "Agregar semántica de riesgo",
            semanticTags: "Etiquetas semánticas:",
            standardSentence: "Oración estándar:",
          },
          components: {
            advancedFilter: {
              components: {
                list: {
                  index: { addNewConditions: "Agregar una nueva condición" },
                },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Cumpla todas las siguientes condiciones",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Filtrar",
                pleaseSelectFilteringCriteria:
                  "Seleccione las condiciones de filtro",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Etapa de oportunidad de cambio",
              theCurrentStageIs: "La etapa actual es 【",
              ConfirmToChangeItTo: "】, Confirme cambiarlo a 【",
              IsIt: "】?",
              StageCoStay: "】 Permaneciendo juntos en la etapa",
              day: "Día",
              start: "Empezar",
              end: "Fin",
              orderInput: "Perder órdenes",
              restart: "Reiniciar",
              pleaseConfigureTheSalesStageInformation:
                "Configure la información de la fase de ventas",
              reasonForDocumentInput: "Razones para perder órdenes:",
              completeOrderInput: "Completa la orden de pérdida",
              pleaseSelectTheReason: "Por favor seleccione la razón",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "Los gerentes recomiendan:",
              },
              index: {
                sessionDetails: "Detalles de la sesión",
                customerDynamics: "Dinámica del cliente",
                riskPoint: "Punto de riesgo",
                followUpSuggestions: "Sugerencias de seguimiento",
              },
              sessionDetails: {
                common: "Total",
                currentlyViewingThe: "Vista actual",
                sessions: "Conversaciones",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "La etapa actual se ha quedado",
              day: "Día",
            },
          },
          business: {
            index: {
              reEnableOpportunity: "Reactivar oportunidades comerciales",
            },
          },
          customerTag: { index: { label: "Etiqueta" } },
          moreCustomer: {
            index: {
              essentialInformation: "Información básica",
              cancel: "Cancelación",
              confirm: "Confirmación",
              customer: "Cliente",
              currentFollowupStage: "Etapa de seguimiento actual",
              telephone: "Teléfono",
              followUpDays: "Días de seguimiento",
              day: "Día",
              remarks: "Observaciones",
              channelPreference: "Preferencias de canal",
              hello: "-Hola.-Hola.",
              customFields: "Campos personalizados",
              opportunityAmount: "Cantidad de oportunidad de negocio",
              focus: "Enfoque",
              customerInformation: "Información del cliente",
              viewMoreCustomerInformation: "Ver más información del cliente",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Eventos clave:" },
                row1: {
                  customerDynamics: "Dinámica del cliente",
                  common: "Total",
                  barDynamic: "Dinámica",
                },
                row2: {
                  riskPoint: "Punto de riesgo",
                  common: "Total",
                  risks: "Riesgo",
                },
                row3: {
                  followUpSuggestions: "Sugerencias de seguimiento",
                  intelligentRecommendation: "Recomendación inteligente",
                  strip: "Artículo",
                  managersSuggestATotalOf: "Los gerentes sugieren un total",
                  recommendedByManagers: "Recomendación del gerente",
                  creationTime: "Hora de creación",
                  administratorSuggestions: "Sugerencias de administradores",
                  suggestionsForNewManagers: "Nuevas sugerencias de gerentes",
                },
              },
            },
            index: { typeTime: "Tipo/Tiempo" },
          },
          userInfo: {
            index: {
              personInCharge: "Responsable",
              recentFollower: "Personas de seguimiento recientes",
              startTime: "Tiempo de primer contacto",
              followUpDays: "Días de seguimiento",
              day: "Día",
              remarks: "Observaciones",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Miembros del equipo",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "El número total de sesiones que los miembros aprenden de otros (para volver a enfatizar), verifique los detalles de la sesión y haga clic en la grabación para reproducir como aprendizaje una vez",
          totalNumberOfStudies: "Total de estudios",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "El número total de comentarios y respuestas iniciados por miembros a las sesiones de otras personas (para volver a)",
          totalNumberOfInteractions: "Total de interacciones",
          learnTheSumOfConversationCallDuration:
            "Suma de la duración de la conversación de aprendizaje",
          lengthOfStudy: "Duración del aprendizaje",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Número total de solicitudes de ayuda iniciadas por miembros a las sesiones de otros (sin peso)",
          totalNumberOfRequests: "Total de solicitudes de ayuda",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Número total de miembros que brindan asesoramiento sobre la ayuda de otros (para volver a)",
          totalCounseling: "Total de tutorías",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Número total de sesiones de miembros agregadas a la biblioteca de casos (sin peso)",
          addCase: "Agregar un caso",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Número total de suscripciones realizadas por miembros a las sesiones de otros (sin peso)",
          subscribe: "Suscripción",
          totalNumberOfSessionsCommunicatedByMembers:
            "Número total de sesiones comunicadas por los miembros",
          totalSessions: "Total de sesiones",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Los miembros reproducen el número total de sus propias sesiones (para volver a), verifican los detalles de la sesión y hacen clic en la grabación para reproducir como una repetición",
          totalNumberOfDoubleDisk: "Número total de reacciones",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Reproducción total/sesión total * 100%",
          proportionOfDoubleDisk: "Reproducción",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Reanuda la duración de la conversación",
          resumptionDuration: "Duración de la revisión",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Número total de solicitudes de ayuda iniciadas por miembros en sus sesiones (para volver a)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Número total de comentarios (incluidas las respuestas) recibidos en las sesiones de los miembros",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Número total de sesiones de miembros agregadas a la biblioteca de casos por otros (sin peso)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Número total de sesiones de miembros suscritas por otros (sin peso)",
          askForHelpInTheLastMonth: "Ayuda en el último mes",
          lastTimeForHelp: "Tiempo de ayuda más reciente",
          operation: "Operación",
          viewAllSessionsInTheLastMonth:
            "Ver todas las sesiones del último mes",
          allDepartments: "Todos los departamentos",
          reset: "Restablecer",
          query: "Consulta",
          altogether: "En total",
          secondSession: "Sesión",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Proyecto de prueba actual:",
            supervisionItem: "El elemento de supervisión",
            keyEvents: "Eventos clave",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Marque los elementos de supervisión o los eventos clave que desea probar y asegúrese de que haya una llamada en la conversación simulada",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Análisis de ejecución" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Proceso 1",
              technologicalProcess: "Proceso",
              newProcess: "Nuevo proceso",
            },
          },
          index: {
            upload: "Subir",
            preservation: "Guardar",
            newlyBuild: "Nuevo",
            cancel: "Cancelación",
            name: "Nombre",
            describe: "Descripción",
            type: "Tipo",
            classification: "Clasificación",
            content: "Contenido",
            enclosure: "Anexo",
            knowledgePointAssociation: "Asociación de puntos de conocimiento",
            open: "Abrir",
            close: "Cerrar",
            operator: "Operador",
            logic: "Lógica",
            see: "Ver",
            edit: "Edición",
          },
        },
        index: {
          name: "Nombre",
          describe: "Descripción",
          createdBy: "Creador",
          type: "Tipo",
          lastUpdated: "Última actualización",
          numberOfReferences: "Número de citas",
          operation: "Operación",
          see: "Ver",
          edit: "Edición",
          delete: "Eliminar",
          categoryDeletedSuccessfully:
            "La clasificación se eliminó correctamente",
          classificationOfKnowledgePoints:
            "Clasificación de puntos de conocimiento",
          newlyBuild: "Nuevo",
          knowledgeBase: "Base de conocimientos",
          knowledgePoints: "Puntos de conocimiento",
          technologicalProcess: "Proceso",
          keyword: "Palabras clave",
          batchExport: "Exportación por lotes",
          batchDelete: "Eliminación por lotes",
          importProcess: "Proceso de importación",
          importKnowledgePoints: "Importar puntos de conocimiento",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Si el punto de conocimiento no existe en la base de conocimiento, agregue directamente el punto de conocimiento, si existe, omita, no actualice",
          editKnowledgePointClassification:
            "Editar clasificación de puntos de conocimiento",
          newKnowledgePointClassification:
            "Nueva clasificación de puntos de conocimiento",
          categoryEditingSucceeded: "Edición exitosa de categorías",
          newClassificationSucceeded: "Nueva clasificación exitosa",
          classificationName: "Nombre de la categoría",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Supervisión ejecutiva" },
      },
      topicCallout: {
        index: {
          whole: "Todo",
          date: "Fecha",
          dimensionStatus: "Estado de la etiqueta",
          callRecordID: "ID de registro de llamadas",
          callRecordIDMustBeNumeric:
            "El ID del registro de llamadas debe ser un número",
          strip: "Artículo",
          topicAnnotation: "Anotación de tema",
          annotated: "Amarcado",
          stripNotMarked: "Artículo, no marcado",
          barInDimension: "Artículo, marcado",
          customer: "Cliente",
          sale: "Ventas",
          common: "Total",
          sessions: "Sesión",
          currentTopic: "Temas actuales:",
          staff: "Empleados",
          topicOfConversation: "Tema",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "La operación es exitosa, la etiqueta se ha restablecido",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Después de reiniciar, todos los registros de etiquetado de la sesión de Bentong se vaciarán, confirme",
          resetAll: "Restablecer todo",
          pleaseSelectTheTopicToBeMarked: "Seleccione el tema que desea marcar",
          pleaseTickTheSentenceToBeMarked: "Marque la oración a marcar",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "La operación es exitosa, la etiqueta ha entrado en vigencia",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "El alcance de esta marca contiene mensajes sobre otros temas, haga clic en Aceptar para cubrir",
          confirmDimensions: "Confirmar la etiqueta",
          talkTime: "Tiempo de conversación",
          callID: "ID de llamada",
        },
      },
      topicList: {
        index: {
          callID: "ID de llamada",
          latestMarkedTime: "Última hora de marcado",
          topicOfConversation: "Tema",
          numberOfSentences: "Número de oraciones",
          sentence: "Sentencia",
          operation: "Operación",
          see: "Ver",
          delete: "Eliminar",
          edit: "Edición",
          newSubTopic: "Nuevo subtema",
          move: "Mover",
          whole: "Todo",
          topicDeletedSuccessfully: "Eliminar tema con éxito",
          newlyBuild: "Nuevo",
          topicList: "Lista de temas",
          callIDMustBeNumeric: "El ID de llamada debe ser digital",
          leadInTopic: "Importar temas",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Cuando el nombre de la categoría se repite, la categoría de tema original no se sobrescribirá, el elemento se omitirá directamente y no se importará.",
          mobileClassification: "Clasificación móvil",
          editTopic: "Editar tema",
          newTopic: "Nuevo tema",
          topicName: "Nombre del tema",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Modificación exitosa",
            createdSuccessfully: "Éxito en la creación",
            modificationFailed: "Error de modificación",
            creationFailed: "Error de creación",
            monitoringName: "Nombre de supervisión",
            upToCharacters: "Hasta 40 caracteres",
            pleaseEnterAName: "Por favor ingrese el nombre",
            monitoringIndicators: "Indicadores de seguimiento",
            sessionActivity: "Actividad de conversación",
            conversationAction: "Acción de sesión",
            keyEvents: "Eventos clave",
            supervisionItems: "El elemento de supervisión",
            monitoringMembers: "Miembros de supervisión",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Grupos que reciben orientación, capacitación o intervención de gestión",
            groupA: "Grupo A",
            pleaseSelectGroupAData: "Seleccione los datos del grupo A",
            thereAreDuplicateUsersInGroupB:
              "Con usuarios duplicados en el grupo B",
            groupARemarks: "Grupo A Observaciones",
            controlGroupGroupComparedWithGroupA:
              "Grupo de control, el grupo que se compara con el grupo A",
            groupB: "Grupo B",
            pleaseSelectGroupBData: "Seleccione los datos del grupo B",
            thereAreDuplicateUsersInGroupA:
              "Usuarios duplicados con el grupo A",
            groupBRemarks: "Grupo B Observaciones",
            monitoringPeriod: "Ciclo de vigilancia",
            startNode: "Nodo de inicio",
            monitoringScope: "Alcance de la vigilancia",
            pleaseSelectMonitoringConditions:
              "Seleccione las condiciones de monitoreo",
            pleaseEnterANumber: "Por favor ingrese el número",
            pleaseSelectATimePeriod: "Seleccione el período de tiempo",
            preservation: "Guardar",
            cancel: "Cancelación",
          },
        },
        list: {
          template: {
            deletionSucceeded: "La eliminación se realizó correctamente",
            notStarted: "Sin empezar",
            haveInHand: "En progreso",
            closed: "Terminado",
            newGroupTest: "Nueva prueba de grupo",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Etapa de ventas",
        communicationStrategy1: "Estrategias de comunicación",
        riskAllocation: "Configuración de riesgos",
        businessConfiguration: "Configuración empresarial",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Manejo de objeciones",
                  productSellingPointScript: "Punto de venta del producto",
                  caseGrouping: "Agrupación de casos",
                  pleaseSelectExcellentCases: "Elija un caso excelente",
                  pleaseSelectKnowledgePoints:
                    "Seleccione puntos de conocimiento",
                  associativeLinks: "Enlace asociado",
                  jumpTo: "Saltar a",
                  jumpType: "Tipo de salto",
                  knowledgePoints: "Puntos de conocimiento",
                  excellentCases: "Excelente caso",
                  excellentScript: "Excelente habla",
                  pleaseSelectASingleKnowledgePoint:
                    "Seleccione un solo punto de conocimiento",
                },
              },
            },
            index: {
              name: "Nombre",
              pushText: "Empuje el texto",
              enableStatus: "Estado habilitado",
              triggerRules: "Reglas de activación",
              communicationStrategy: "Estrategias de comunicación",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping:
                "Agrupación de estrategias de comunicación",
              name: "Nombre",
              pushText: "Empuje el texto",
              effectiveness: "Eficacia",
              openSuccessfully: "Abierto con éxito",
              closeSuccessfully: "Cierre exitoso",
              operationFailed: "Operación fallida",
              operation: "Operación",
              edit: "Edición",
              delete: "Eliminar",
              newlyBuild: "Nuevo",
              communicationStrategy: "Estrategias de comunicación",
              editGroup: "Edición de grupos",
              groupName: "Nombre del grupo",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Todas las sesiones",
                  recentDays: "Días recientes",
                  recentSessions: "Sesiones recientes",
                  scopeOfApplication: "Ámbito de aplicación:",
                  day: "Día",
                  through: "Pase",
                },
              },
            },
            index: {
              describe: "Descripción",
              applicableCustomers: "Clientes aplicables",
              enableStatus: "Estado habilitado",
              triggerRules: "Reglas de activación",
              riskRules: "Reglas de riesgo",
            },
          },
          list: {
            index: {
              riskAllocation: "Configuración de riesgos",
              keyWord: "Palabras clave",
              newRiskRule: "Nueva regla de riesgo",
              riskRuleName: "Nombre de la regla de riesgo",
              describe: "Descripción",
              state: "Estado",
              openSuccessfully: "Abierto con éxito",
              closeSuccessfully: "Cierre exitoso",
              operationFailed: "Operación fallida",
              operation: "Operación",
              edit: "Edición",
              delete: "Eliminar",
              deletionSucceeded: "La eliminación se realizó correctamente",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Perder órdenes" },
              endReasonList: {
                addEndReason: "Agregar motivo de cierre",
                enterEndReason: "Ingrese el motivo del final",
              },
              stageList: {
                keyEvents: "Eventos clave",
                waysideData: "Datos de seguimiento",
                includeAny: "Contiene cualquier",
                includeAll: "Contiene todo",
                pleaseEnterTheStageName: "Introduzca el nombre de la etapa",
                setStageRules: "Establecer reglas de fase",
                stageName: "Nombre de la etapa",
                operator: "Operador",
                logic: "Lógica",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "El formato de entrada del campo de tipo de tiempo es: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "La solicitud falló, inténtelo de nuevo más tarde",
              salesStage: "Etapa de ventas",
              essentialInformation: "Información básica",
              name: "Nombre",
              applicableDepartments: "Departamentos aplicables",
              applicableToOthers: "Aplicar otros",
              manualChangePhase: "Fase de cambio manual",
              enableStatus: "Estado habilitado",
              describe: "Descripción",
              opportunityStageSettings:
                "Configuración de la etapa de oportunidad de negocio",
              stageSettings: "Configuración de la etapa",
              aliasSettings: "Configuración de alias",
              closeOpportunity: "Fin de oportunidad de negocio",
              newSuccessfully: "Nuevo éxito",
              newFailed: "Nuevo fracaso",
              savingSucceeded: "Ahorre con éxito",
              saveFailed: "Error de guardar",
            },
          },
          list: {
            index: {
              salesStage: "Etapa de ventas",
              keyWord: "Palabras clave",
              newSalesStage: "Nueva etapa de ventas",
              salesStageName: "Nombre de la etapa de venta",
              describe: "Descripción",
              state: "Estado",
              openSuccessfully: "Abierto con éxito",
              closeSuccessfully: "Cierre exitoso",
              operationFailed: "Operación fallida",
              operation: "Operación",
              edit: "Edición",
              delete: "Eliminar",
              deletionSucceeded: "La eliminación se realizó correctamente",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Generación de modelos grandes",
            conversationInsights: "Información de conversación",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Generación automática" },
              },
              hooks: {
                conversationSummary: "Resumen de la conversación",
                conversationProcess: "Flujo de sesión",
                opportunityPoints: "Punto de oportunidad",
              },
              prompt: {
                index: {
                  edit: "Edición",
                  restoreDefault: "Restaurar el valor predeterminado",
                  cancel: "Cancelación",
                  confirm: "Confirmación",
                },
              },
            },
            index: {
              saveSuccessful: "Ahorre con éxito",
              hintLanguage: "Mensaje",
              preserve: "Guardar",
              cancel: "Cancelación",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Generación de modelos grandes",
        salesStage: "Etapa de ventas",
        communicationStrategy: "Estrategias de comunicación",
        riskAllocation: "Configuración de riesgos",
        businessConfiguration: "Configuración empresarial",
      },
      salesLearningCenter: {
        knowledgeBase: "Base de conocimientos",
        speechCorpus: "Biblioteca de palabras",
        learningCenter: "Centro de aprendizaje",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Información del cliente" },
          },
        },
        index: { otherInformation: "Información adicional" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Importar" } } },
        hooks: {
          index: {
            all: "Todo",
            intelligentModel: "Modelo inteligente",
            smartRules: "Reglas inteligentes",
            manualScoring: "Puntuación manual",
            enable: "Habilitar",
            notEnabled: "No habilitado",
            voiceCall: "Llamadas de voz",
            textDialogue: "Diálogo de texto",
            ecommerceTextSession:
              "Conversación de texto de comercio electrónico",
            workOrder: "Hoja de trabajo",
          },
        },
        index: {
          ruleName: "Nombre de la regla",
          ruleType: "Tipos de reglas",
          pleaseSelect: "Por favor elija",
          ruleStatus: "Estado de la regla",
          dataSourceType: "Tipo de fuente de datos",
          voiceCall: "Llamadas de voz",
          textDialogue: "Diálogo de texto",
          ecommerceTextSession: "Conversación de texto de comercio electrónico",
          workOrder: "Hoja de trabajo",
          status: "Estado",
          operation: "Operación",
          edit: "Edición",
          ruleTemplate: "Plantilla de regla",
        },
      },
      analysisReportTitle: "Informe de análisis",
      ruleTest: "Prueba de reglas",
      intelligentTags: {
        labelDisplayQuantity: "Cantidad de visualización de etiquetas",
        unlimited: "Ilimitado",
        ignoringTagLibraries: "Ignorar la biblioteca de etiquetas",
        automaticallyGenerateLabels: "Generación automática de etiquetas",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'Después de abrir, la etiqueta se genera automáticamente en la sesión basada en el modelo grande, y la etiqueta generada se clasifica en "Sin categoría" de forma predeterminada',
        defaultValidity: "Validez por defecto",
        supervisionItem: "El elemento de supervisión",
        customerLabel: "Etiqueta de cliente",
        newSupervisionItemRule: "Nueva regla del elemento de supervisión",
        editSupervisionItemRule: "Editar reglas de elementos de supervisión",
        newCustomerLabel: "Nueva etiqueta de cliente",
        editCustomerLabel: "Editar etiquetas de clientes",
        newSessionLabel: "Nueva pestaña de sesión",
        editSessionLabel: "Editar etiquetas de sesión",
        newKeyEvent: "Nuevo evento clave",
        editKeyEvents: "Edición de eventos clave",
        detail: {
          template: {
            nameIsARequiredField: "El nombre es un campo obligatorio",
          },
          formItemComponent: {
            largeModelExtensionLabel: "Etiquetas de expansión de modelo grande",
            sessionRecordID: "Id de grabación de sesión",
            sessionTime: "Tiempo de sesión",
            smartTags: "Etiquetas inteligentes",
            viewDetails: "Ver detalles",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Después de la eliminación, los datos registrados en esta sesión se borrarán. ¿Confirmo la eliminación?",
            tagData: "Marcar los datos",
            batchDeletion: "Eliminación por lotes",
          },
        },
        template: {
          advancedFiltering: "Filtración avanzada",
          dataType: "Tipos de datos",
          filterCriteria: "Condiciones de filtro",
          edit: "Edición",
          createANewSubcategory: "Nueva subcategoría",
          delete: "Eliminar",
          nameLengthCannotExceed: "La longitud del nombre no puede exceder 40",
        },
        component: {
          modelExpansion: "Expansión del modelo",
          enable: "Habilitar",
          notEnabled: "No habilitado",
          standardScreening: "Cribado estándar",
          advancedFiltering: "Filtración avanzada",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Palabras clave",
              sessionLabel: "Etiqueta de sesión",
              keyEvents: "Eventos clave",
              matchAny: "Coincidir con cualquier",
              matchAll: "Coincidir con todos",
              beEqualTo: "Igual a",
              includeAll: "Contiene todo",
              includeAny: "Contiene cualquier",
              advancedConditions: "Condiciones avanzadas",
              smartTag: "Etiquetas inteligentes",
              smartTagI: "Etiqueta inteligente uno",
              smartTagII: "Etiqueta inteligente dos",
            },
            template: {
              pleaseSelectSmartTag: "Seleccione la etiqueta inteligente",
              pleaseSelectASessionLabel: "Seleccione la etiqueta de la sesión",
              pleaseSelectAKeyEvent: "Seleccione el evento clave",
              pleaseSelectAdvancedCondition:
                "Seleccione las condiciones avanzadas",
              newAdvancedFilter: "Nuevo filtrado avanzado",
              moveUp: "Mover hacia arriba",
              moveDown: "Mover hacia abajo",
              moveLeft: "Mover a la izquierda",
              shiftRight: "Mover a la derecha",
            },
          },
          component: {
            businessAnalysispng: "Análisis de negocios. png",
            savedSuccessfully: "Ahorre con éxito",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Efectivo permanente",
            permanentlyInvalid: "Inválido permanente",
            customValidity: "Período de validez personalizado",
            termOfValidity: "Período de validez",
            name: "Nombre",
            explain: "Descripción",
            state: "Estado",
            classification: "Clasificación",
            smartRecommendation: "Recomendación inteligente",
            preconditions: "Condiciones previas",
            SetPreconditionsForThisSupervisionItem:
              "1. Establecer condiciones previas para este elemento de supervisión:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Cuando se cumplen las condiciones previas, es decir, "el elemento de supervisión debe implementarse", y el elemento de supervisión debe probarse;',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Si no se cumplen las condiciones previas, el elemento de supervisión no se probará;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Sin condiciones previas, es decir, todas las sesiones se detectan de forma predeterminada",
            have: "Sí.",
            nothing: "Ninguno",
            preconditionRule: "Reglas de condición previa",
            preconditionLogic: "Lógica condicional previa",
          },
          recommendation: {
            caseGrouping: "Agrupación de casos",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Cuando la ejecución de los empleados no está en su lugar, puede recomendar inteligentemente excelentes habilidades de habla y casos excelentes.",
            smartRecommendation: "Recomendación inteligente",
            open: "Abrir",
            close: "Cerrar",
            whenThisSupervisionItem: "Cuando este elemento de supervisión",
            implement: "Ejecución",
            unexecuted: "No ejecutado",
            when: "Cuando:",
            recommendedKnowledgePoints: "Puntos de conocimiento recomendados",
            caseRecommendation: "Recomendación de caso",
          },
        },
        index: {
          determine: "Determinar",
          sort: "Ordenar",
          sortSucceeded: "Ordenar con éxito",
          supervisionItemClassification:
            "Clasificación de los elementos de supervisión",
          pressEnterToCreateANew: "Presione Enter para crear nuevo",
          nameCannotBeEmpty: "El nombre no puede estar vacío",
          newlyBuild: "Nuevo",
          name: "Nombre",
          explain: "Descripción",
          validStatus: "Estado válido",
          permanentlyValid: "Efectivo permanente",
          permanentlyInvalid: "Inválido permanente",
          operation: "Operación",
          edit: "Edición",
          batchDelete: "Eliminación por lotes",
          newSupervisionItem: "Nuevo elemento de supervisión",
          keyWord: "Palabras clave",
          delete: "Eliminar",
          deleteSucceeded: "La eliminación se realizó correctamente",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Los datos no se pueden recuperar después de la eliminación, ¿confirma la eliminación?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Etiquetas inteligentes",
              beEqualTo: "Igual a",
              includeAll: "Contiene todo",
              includeAny: "Contiene cualquier",
              matchAny: "Coincidir con cualquier",
              matchAll: "Coincidir con todos",
              smartTagI: "Etiqueta inteligente uno",
              smartTagII: "Etiqueta inteligente dos",
            },
            template: {
              pleaseSelectASmartTag: "Seleccione la etiqueta inteligente",
              pleaseSelectAdvancedConditions:
                "Seleccione las condiciones avanzadas",
              newAdvancedFilter: "Nuevo filtrado avanzado",
              rename: "Cambiar el nombre",
              moveUp: "Mover hacia arriba",
              moveDown: "Mover hacia abajo",
              shiftLeft: "Mover a la izquierda",
              shiftRight: "Mover a la derecha",
            },
          },
        },
        template: {
          delete: "Eliminar",
          labelFiltering: "Filtro de etiquetas:",
          satisfyAny: "Satisfaga cualquier",
          meetAll: "Satisfacer a todos",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Etiqueta de clasificación de segundo nivel Número de conversaciones ≥ Clasificación de primer nivel, una sesión puede contener varias etiquetas",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Lógica de cálculo de cambio de cadena: (cantidad de diálogo en el período de tiempo seleccionado-cantidad de diálogo al lado del período de tiempo anterior)/cantidad de diálogo en el período de tiempo anterior",
          proportionOfPrimaryClassification:
            "La proporción de la clasificación de primer nivel",
          saveAs: "Guardar como",
          cover: "Cobertura",
          preservation: "Guardar",
          qualityInspectionTask: "Tareas de análisis:",
          query: "Consulta",
          changeFromMonthToMonth: "Cambios en la cadena",
          tagRanking: "Clasificación de etiquetas",
          proportion: "Situación de proporción",
        },
        component: {
          numberOfConversations: "Número de conversaciones",
          operation: "Operación",
          businessAnalysispng: "Análisis de negocios. png",
          savingSucceeded: "Ahorre con éxito",
          deletionSucceeded: "La eliminación se realizó correctamente",
          sequence: "Secuencia",
          firstLevelClassification: "Clasificación de primer nivel",
          ringRatioChange: "Cambio de cadena",
          classClassification: "Clasificación de grado",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "¿Confirmar la eliminación del documento y su contenido extraído?",
            addKnowledgeBase: "Agregar una base de conocimiento",
            uploadDocument: "Subir documentos",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Admite archivos de formato word, pdf, ppt, excel, txt",
            taskName: "Nombre de la tarea",
            extractConversationDate: "Dibuja la fecha de la conversación",
            pleaseSelectAConversationDate:
              "Seleccione la fecha de la conversación",
            dataSource: "Fuente de datos",
            dataType: "Tipos de datos",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Exportar con éxito",
              problem: "Problema",
              answer: "Respuesta",
              addToKnowledgeBase: "Agregar a la base de conocimientos",
              pleaseConfirmWhetherToDeleteIt:
                "Por favor, determine si eliminar",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Agregar a la base de conocimientos",
              selectClassification: "Seleccionar categorías",
              pleaseSelectAKnowledgeBaseClassification:
                "Seleccione la clasificación de la base de conocimientos",
            },
          },
        },
        index: {
          intelligentExtraction: "Extracción inteligente",
          documentExtraction: "Extracción de documentos",
          dialogueExtraction: "Extracción de diálogo",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operación exitosa",
          labelIgnored: "Etiquetas ignoradas",
          add: "Añadir",
          areYouSureToDeleteIt: "¿Está seguro de eliminar?",
          batchFilterLabels: "Filtrar etiquetas por lotes",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Inspección de calidad inteligente",
        savedSuccessfully: "Ahorre con éxito",
        logoSettings: "Configuración del logotipo",
        default: "Por defecto",
        custom: "Personalización",
        title: "Título",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Se recomienda que el nombre del título que se muestra en el sistema esté dentro de las 6 palabras.",
        logoImageUpload: "Carga de imagen de logotipo",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Seleccione una imagen con un tamaño no superior a 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Para garantizar el efecto de navegación del logotipo, se recomienda cargar imágenes:",
        Size5050Pixels: "1. Tamaño: 50*50 píxeles;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Formato: imagen de fondo transparente, png o jpg, el logotipo gráfico utiliza blanco",
        browserLabelIcon: "Icono de etiqueta del navegador",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Para garantizar el efecto de navegación de etiquetas, se recomienda cargar imágenes:",
        Size3232Pixels: "1. Tamaño: 32*32 píxeles;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Formato: imagen de fondo transparente, png o jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "La inspección de calidad está en curso, espere...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "En 2015, las propiedades inmobiliarias de China llegaron a un momento crítico para la supervivencia, una gran cantidad de casas quedaron desatendidas y la gente no estaba dispuesta a comprarlas. Hangzhou una vez cayó por debajo de 8000 y algunos desarrolladores rompieron los envíos. Shenzhen todavía se estaba consolidando alrededor de 2w-3w en ese momento, y las casas en las ciudades de primer nivel también estaban en peligro.",
        xiaoAn: "Xiaoan",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload Estas barreras de memoria se denominan barreras de memoria jsr, que están a nivel jvm. Hay dos formas de implementarlas en la capa inferior del sistema operativo: 1 es el bus de bloqueo y el segundo es la meta de barrera de memoria, como sfance (barrera de escritura) mfence (barrera completa) (barrera de lectura). La capa inferior de la máquina virtual de hotspots utiliza una declaración de ensamblaje: bloquear addl, bloquear una operación vacía, es decir, bloquear el bus, esto se debe a que algunos sistemas operativos no admiten el meta de barrera de memoria.",
        xiaobei: "Pequeño norte",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Trate de marinar la carne varias veces, agregue los platos fritos después de beber, la carne está podrida, ¡un olor agrio! Desde entonces, no hay vino de cocción en la carne marinada.",
        xiaodong: "Xiaodong",
        xiaoxi: "Xiaoxi",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Trate de marinar la carne varias veces, agregue los platos fritos después de beber, la carne está podrida, ¡un olor agrio! Desde entonces, no hay vino de cocción en la carne marinada.",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Gestión de reseñas de comercio electrónico",
        socialMediaReviewManagement:
          "Gestión de comentarios en medios sociales",
        customDataSource: "Origen de datos personalizados",
        qualityInspectionResults: "Resultados de inspección de calidad",
        accountManagement: "Gestión de cuentas",
        employeeProfile: "Retrato del empleado",
        intelligentAnalysis: "Análisis inteligente",
        customKanban: "Kanban personalizado",
        dataScreening: "Resumen de datos",
        learningRecord: "Registro de aprendizaje",
        dataAnalysis: "Análisis de datos",
        scoringManagement: "Gestión de puntuación",
        businessConfiguration: "Configuración empresarial",
        manualReview: "Revisión manual",
        taskList: "Lista de tareas",
        videoAnalysis: "Análisis de vídeo",
        overview: "Resumen",
        personalSignage: "Kanban personal",
        learningCenter: "Centro de aprendizaje",
        wrongQuestionSet: "Conjunto de preguntas incorrecto",
        abilityImprovement: "Mejora de la capacidad",
        close: "Cerrar",
        switchingSystemApplications: "Cambiar las aplicaciones del sistema",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Mueva el mouse aquí para llamar al menú de cambio de aplicación, haga clic en el elemento del menú para saltar a diferentes aplicaciones",
        teamAnalysis: "Análisis del equipo",
        semanticIntelligence: "Inteligencia semántica",
        permissionManagement: "Gestión de permisos",
        dataConnection: "Acoplamiento de datos",
        courseManagement: "Gestión del curso",
        labelManagement: "Gestión de etiquetas",
        curriculum: "Centro de cursos",
        courseConfiguration: "Configuración del curso",
        task: "Mi misión",
        historicalLearningData: "Historia",
        taskCenter: "Gestión de tareas",
        knowledgeBase: "Base de conocimientos",
        ecommerceSessionManagement:
          "Gestión de sesiones de comercio electrónico",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Cambio de aplicación",
          descriptionOfIntelligentQualityInspectionSystem:
            "Descripción del sistema inteligente de inspección de calidad",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Filtro de sesión de comercio electrónico",
        allEcommerceSessions: "Todas las sesiones de comercio electrónico",
        ecommerceSessionManagement:
          "Gestión de sesiones de comercio electrónico",
      },
    },
    analysisEnable: {
      bestPractices: "Mejores prácticas",
      behaviorAnalysis: "Análisis de comportamiento",
      teamAnalysis: "Análisis del equipo",
      executiveSupervision: "Supervisión ejecutiva",
      executiveForceAnalysis: "Análisis de ejecución",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Calificación:",
          startTime: "Hora de inicio",
          workOrderNo: "Número de orden de trabajo",
          customerServiceHasReadNotRead: "Servicio al cliente leído/no leído",
          canYouAppeal: "Si se puede apelar",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Comentarios empresariales",
          addFriendTime: "Añadir tiempo de amigo",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Iniciar la nueva inspección de calidad",
          callID: "ID de llamada",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            '¡Nueva función! Haga clic en el botón "Muestreo rápido", una vez que el envío sea exitoso, saltará automáticamente a la página siguiente ~~~',
        },
      },
      template: {
        deleteRecord: "Eliminar registros",
        areYouSureToDeleteIt: "¿Está seguro de eliminar?",
        confirm: "Determinar",
        reIdentification: "Reidentificación",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "La reidentificación consumirá tiempo de transliteración, tenga cuidado",
        determine: "Determinar",
        executionSuccessful: "Ejecución exitosa",
      },
      component: {
        pleaseSelectData: "Seleccione los datos",
        operationSuccessful: "Operación exitosa",
        startPerformingReQualityInspection:
          "Iniciar la nueva inspección de calidad",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Subir nombre de archivo",
        analysisTask: "Tareas de análisis",
        analyzeProgress: "Analizar el progreso",
        analysisSucceeded: "Éxito de análisis",
        analysisFailed: "Análisis fallido",
        localDataUpload: "Carga de datos locales",
        recordingUpload: "Carga de grabación",
        textUpload: "Carga de texto",
        uploadTime: "Tiempo de carga",
        taskName: "Nombre de la tarea",
        operator: "Operadores",
        qualityInspectionProgress: "Progreso de la inspección de calidad",
        whole: "Todo",
        haveInHand: "En progreso",
        completed: "Completado",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "¿Se exportan todos los registros de datos completados en la condición de filtro actual?",
        upload: "Subir",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Rellene el nombre",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Complete el nombre, como el tipo/colección/propósito de los datos importados esta vez",
            default: "Por defecto",
            jDCOM: "Jingdong",
            dataType: "Tipos de datos",
            selectAnalysisTask: "Selección de tareas de análisis",
            uploadFiles: "Subir archivos",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Descargue la plantilla de Excel y complete el formato:",
            template: "Plantilla",
            SupportUpTo10000PiecesOfData: "2. Soporte máximo de 10,000 datos",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Admite la carga de datos de diálogo exportados por JD, archivo. txt, tamaño de archivo máximo admite 100M",
            alongTheWayDataTemplate: "Plantilla de datos de seguimiento",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "En la inspección de calidad del texto, espere pacientemente",
            textQualityInspectionCompleted:
              "Inspección de calidad de texto completada",
          },
        },
        template: {
          startAnalysis: "Iniciar análisis",
          pleaseUploadTheFile: "Por favor cargue el archivo",
          localDataUpload: "Carga de datos locales",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            '¡Nueva función! Haga clic en el botón "Enviar de revisión", una vez que el envío sea exitoso, saltará automáticamente a la página siguiente ~~~',
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Autoridad de tarea de inspección de calidad",
            edit: "Edición",
            iParticipated: "Estoy involucrado",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Esta función puede ver los datos en el departamento seleccionado",
            dataPermissions: "Permisos de datos",
            selectDepartment: "Seleccionar departamentos",
            edit: "Edición",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Iniciar la nueva inspección de calidad",
          callID: "ID de llamada",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            '¡Nueva función! Haga clic en los botones "Enviar por muestreo" e "Inspección por muestreo rápido". Una vez que el envío sea exitoso, saltará automáticamente a la página siguiente ~~~',
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Reglas" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Gestión de órdenes de trabajo",
          workOrderDetails: "Detalles de la orden de trabajo",
        },
      },
      template: {
        workOrderFilter: "Filtro de órdenes de trabajo",
        allWorkOrders: "Todas las órdenes de trabajo",
        workOrderManagement: "Gestión de órdenes de trabajo",
      },
    },
    trainingCenter: {
      topicAnnotation: "Anotación de tema",
      topicList: "Lista de temas",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Tipos de roles",
              keyWord: "Palabras clave",
              pleaseEnterKeywordSearch:
                "Ingrese las palabras clave para buscar",
              query: "Consulta",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Esta función aún no está disponible, comuníquese con el administrador del sistema si es necesario",
          pleaseSelectAQuestion: "Por favor, seleccione la pregunta!",
          recommendedSimilarSentencesLargeModel:
            "Oración similar recomendada (modelo grande)",
          selectAll: "Todo seleccionado",
          add: "Añadir",
          changeBatch: "Cambiar un lote",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Horas de trabajo de negocios",
      localDataUpload: "Carga de datos locales",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Ensamble la lista de menús según los permisos de usuario actuales",
      sessionSettings: "Configuración de la sesión",
      logoSettings: "Configuración del logotipo",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "¡Bienvenido de nuevo! Introduzca sus datos",
        mailbox: "Correo electrónico",
        password: "Contraseña",
        signIn: "Iniciar sesión",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin:
            "La IP actual no permite iniciar sesión",
          welcomeToLogin: "Bienvenido a iniciar sesión",
          pursuingExcellenceAndLeadingChange:
            "Perseguir la excelencia y liderar el cambio",
          intelligentPlatform: "Plataforma inteligente",
        },
        virtualLogin: { index: { pleaseSelect: "Por favor, elija" } },
      },
      oauth: {
        illegalCall: "Llamada ilegal",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "¡Bienvenido de nuevo! Haga clic en el botón de abajo para saltar a la plataforma de autenticación de identidad",
        loggingIn: "Iniciar sesión...",
        unifiedIdentityAuthenticationLogin:
          "Inicio de sesión de autenticación de identidad unificado",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Prueba de reglas: algunas reglas no pueden usar la prueba de reglas, como el volumen de velocidad del habla, los datos de seguimiento, etc.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Más información",
          enteringTheSystem: "Entrar en el sistema",
        },
      },
      index: {
        cancel: "Cancelación",
        enteringTheSystem: "Entrar en el sistema",
        close: "Cerrar",
        enterSystemApplication: "Ingrese a la aplicación del sistema",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Haga clic en "Ir al sistema" para ir a la aplicación del sistema habilitada',
      },
    },
    permissionManagement: {
      employeeManagement: "Gestión de empleados",
      roleManagement: "Gestión de roles",
      permissionManagement: "Gestión de permisos",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Igual a",
            contain: "Contiene",
            doesNotContain: "No contiene",
            applicationSystem: "Sistema de aplicación",
            intelligentQualityInspection:
              "Inspección de calidad inteligente/análisis de sesión inteligente",
            salesEmpowerment: "Empoderamiento de las ventas",
            dataSourceType: "Tipo de fuente de datos",
            filterCriteria: "Condiciones de filtro",
          },
        },
        index: {
          dataConnection: "Acoplamiento de datos",
          savingSucceeded: "Ahorre con éxito",
          dataIsolation: "Aislamiento de datos",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Después de encenderlo, puede configurar el rango de datos de cada llamada de acceso al sistema de aplicación y la conversación empresarial de WeChat. Si el interruptor no está encendido, cada sistema accederá a la cantidad total de datos",
          accessRules: "Reglas de acceso",
          preservation: "Guardar",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Nombre de la aplicación",
            accessRights: "Derechos de acceso",
            inAppPermissionSettings:
              "Configuración de permisos en la aplicación",
            setUp: "Configuración",
            applicationAccess: "Acceso a la aplicación",
            newRole: "Añadido rol",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: {
        index: { sessionDetails: "Detalles de la sesión" },
      },
      messageNotification: {
        index: {
          sessionDetails: "Detalles de la sesión",
          caseDetails: "Detalles del caso",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Empleados visibles",
            newCourse: "Nuevo curso",
            editCourse: "Curso de edición",
            courseName: "Nombre del curso",
            courseIntroduction: "Introducción al curso",
            courseType: "Tipos de cursos",
            courseClassification: "Clasificación del curso",
            courseCover: "Cubierta del curso",
            learningContent: "Contenido de aprendizaje",
            uploadLearningMaterials: "Subir materiales de aprendizaje",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Prueba",
              preservation: "Guardar",
              cancel: "Cancelación",
              editNodeName: "Editar el nombre del nodo",
              determine: "Determinar",
              individual: "A",
              of: ", Total",
              branch: "Puntos",
            },
            configDrawerComponents: {
              pPTParsing: "Análisis PPT...",
              uploadSuccessful: "Subir correctamente",
              uploadAttachments: "Subir archivos adjuntos",
              pPTParsingPleaseWait: "Análisis de PPT, por favor más tarde",
            },
            customerPortraitConfigDrawer: {
              cancel: "Cancelación",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "No se puede restaurar después de la eliminación, ¿está seguro de que desea eliminar este retrato?",
              delete: "Eliminar",
              determine: "Determinar",
              customerPortrait: "Retrato del cliente",
              bindSelectedCharacterPortrait:
                "Retrato de personaje seleccionado vinculante",
              customerProfileDetails: "Detalles del retrato del cliente",
              createANewCustomerProfile: "Nuevo retrato del cliente",
            },
            flow: {
              sidebar: { node: "Nodo" },
              useControlButtons: {
                cancellingPleaseWait: "Deshacer, espere...",
                redoInProgressPleaseWait: "Rehacer, por favor más tarde...",
                automaticOrganization: "Acabado automático",
                canvasAdaptation: "Adaptación de lienzo",
                delete: "Eliminar",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Los nodos no se pueden conectar a sí mismos",
              },
            },
            globalConfigDrawer: {
              cancel: "Cancelación",
              determine: "Determinar",
              globalConfiguration: "Configuración global",
              sessionDetection: "Detección de sesión",
              reciprocalRule: "Reglas de interacción",
              robotVoice: "Sonos de robot",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Configuración de tiempo de espera del nodo del estudiante sin respuesta",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Tiempo máximo de silencio (s) permitido por el alumno que no responde",
              promptScriptForFailureToAnswerOverTime:
                "Consejos de tiempo de espera sin respuesta",
              addScript: "Agregar palabras",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (naturaleza)",
              zhishaChat: "Zhisha (chat)",
              zhiqiangentle: "Zhiqian (gentil)",
              zhimiEmotion: "Zhimi (emoción)",
              aiShuoNatural: "Ai Shuo (Naturaleza)",
              zhishuoChat: "Zhishuo (chat)",
              aiHaoAffinity: "Ai Hao (afinidad)",
              zhixiangEmotion: "Zhixiang (emoción)",
              robotVoice: "Sonos de robot",
              schoolboy: "Chicos",
              girlStudent: "Chicas",
              volume: "Volumen",
              tone: "Tono",
              speechSpeed: "Velocidad del habla",
              test: "Prueba",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Hola, soy un entrenador de entrenamiento inteligente y espero comunicarme contigo",
            },
            sessionDetectionForm: {
              expressionDetection: "Detección de expresión",
              excessiveDetectionOfModalParticles:
                "Detección excesiva de palabras de aire",
              theNumberOfRepetitionsIs: "El número de repeticiones es",
              minus: "Segunda vez, menos",
              appear: "Aparece",
              subtract: "Vs, menos",
              branch: "Puntos",
              addRules: "Aumenta la regla",
              includingNegativeAngryComplaining:
                "(Incluyendo negativo, enojado, quejándose)",
              scorePointsDisplay: "Mostrar puntos de puntuación",
              reminderOfSpeakingTooFasttooSlow:
                "Recordatorio de velocidad de habla demasiado rápida/demasiado lenta",
              scriptViolationReminder: "Recordatorio de violación",
              emotionalReminder: "Recordatorio emocional",
              speechSpeedDetection: "Detección de velocidad del habla",
              normalSpeechSpeedScore:
                "Puntuación normal de velocidad del habla",
              speakTooFast: "Velocidad de habla demasiado rápida",
              everyMinuteGreaterThan: "Cada minuto es mayor que",
              aWordAppears: "Una palabra, aparece",
              speakTooSlowly: "La velocidad del habla es demasiado lenta",
              lessThanPerMinute: "Menos de",
              ignoreLessThan: "Ignorar menos que",
              aSentenceOfWords: "Palabra de oración",
              emotionalDetection: "Detección de emociones",
              emotionalNormalScore: "Emoción puntuación normal",
              negativeEmotions: "Emociones negativas",
              illegalWordDetection: "Detección de palabras infractoras",
              scriptViolationConfiguration:
                "Configuración de violación de palabras",
              realTimePromptOnTheFrontEnd: "Consejos front-end en tiempo real",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA:
                    "Preguntas y respuestas de la base de conocimientos",
                  qAMethod: "Método de preguntas y respuestas",
                  numberOfQuestionsAndAnswers:
                    "Cantidad de preguntas y respuestas",
                  scoringRules: "Reglas de puntuación",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Cualquier pregunta y respuesta es correcta,",
                  branch: "Puntos",
                  successfullySaved: "Ahorre con éxito",
                },
                pptNode: {
                  verificationPassed: "Verificación aprobada",
                  upload: "Subir",
                  supportPptPdfFormatFiles: "Soporte ppt, archivo pdf",
                  uploadSlide: "Subir una presentación de diapositivas",
                  assessmentMethod: "Método de evaluación",
                  associateSlide: "Presentación de diapositivas asociada",
                  standardScript: "Hablando estándar",
                  successfullySaved: "Ahorre con éxito",
                },
                robotNode: {
                  robotScript: "Palabras robóticas",
                  randomScript: "Hablando al azar",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Elija palabras semánticas similares, el robot pronunciará una oración al azar",
                  customerPortrait: "Retrato del cliente",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Cuando el retrato del cliente es el mismo, el robot dice esto",
                  setAsDefaultScript: "Establecer como palabra predeterminada",
                  yes: "Sí.",
                  no: "No.",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Esto ocurre cuando el robot no golpea ninguna etiqueta de usuario",
                  successfullySaved: "Ahorre con éxito",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Este nodo de rama tiene una palabra predeterminada. ¿Reemplaza la palabra predeterminada con la palabra actual?",
                },
                startNode: {
                  paragon: "Narración",
                  successfullySaved: "Ahorre con éxito",
                },
                userNode: {
                  verificationPassed: "Verificación aprobada",
                  standardScript: "Hablando estándar",
                  successfullySaved: "Ahorre con éxito",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "Mejore la configuración",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Por favor, introduzca un ejemplo de diálogo",
                  dialogueExample: "Ejemplo de diálogo",
                  generateConversationExamples: "Generar ejemplo de diálogo",
                  mattersNeedingAttention: "Notas",
                  pleaseEnterThePromptLanguage: "Por favor ingrese el mensaje",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Escena de diálogo",
                  simulateCharacter: "Personajes de simulación",
                  accompanyingTrainees: "Objeto de combate",
                  selectFromTemplateLibrary:
                    "Seleccionar de la biblioteca de plantillas",
                  templateLibrary: "Biblioteca de plantillas",
                  role: "Rol",
                  scene: "Escena",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Ventana de prueba de diapositivas 【",
              clearContent: "Borrar contenido",
              close: "Cerrar",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "Proceso de generación de IA",
                scriptStyle: "Estilo de habla",
                theMostConversationRounds:
                  "La mayoría de las rondas de diálogo",
                copyConversation: "Copiar conversación",
                application: "Aplicación",
                regenerate: "Regeneración",
                startGenerating: "Comenzar a generar",
                pleaseGenerateAProcess: "Genere el proceso",
                copySuccessful: "Copia exitosa",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Representante de ventas",
                    customerServicePersonnel: "Personal de atención al cliente",
                    storeCounter: "Mostrador de tienda",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Generar discursos de texto completo",
                speaker: "El orador",
                scriptStyle: "Estilo de habla",
                copyConversation: "Copiar conversación",
                application: "Aplicación",
                regenerate: "Regeneración",
                startGenerating: "Comenzar a generar",
                pleaseGenerateAProcess: "Genere el proceso",
                operationSuccessful: "Operación exitosa",
                copySuccessful: "Copia exitosa",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Retoque" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "¡Las palabras no pueden estar vacías!",
              copySuccessful: "Copia exitosa",
              cancel: "Cancelación",
              copy: "Copiar",
              replaceTheOriginalText: "Sustituir el texto original",
              aiGeneration: "Generación de Ai",
              aiPolishing: "Ai retoque",
              currentStyle: "Estilo actual",
              rigorous: "Estricto",
              mild: "Suave",
              lively: "Animado",
              simplicity: "Simplicidad",
              pleaseEnterTheSpeakerRole: "Por favor ingrese el rol de orador",
              startGenerating: "Comenzar a generar",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "¡Las palabras no pueden estar vacías!",
              copySuccessful: "Copia exitosa",
              aiRewrite: "Ai reescribe",
              cancel: "Cancelación",
              copy: "Copiar",
              replaceTheOriginalText: "Sustituir el texto original",
              currentStyle: "Estilo actual",
              rigorous: "Estricto",
              mild: "Suave",
              lively: "Animado",
              simplicity: "Simplicidad",
              pleaseEnterTheSpeakerRole: "Por favor ingrese el rol de orador",
              startGenerating: "Comenzar a generar",
            },
          },
          index: {
            verificationPassed: "Verificación aprobada",
            ruleValidation: "Verificación de reglas",
            aIGeneration: "Generación de IA",
            generateFullTextSpeechScript: "Generar discursos de texto completo",
            editCourse: "Curso de edición",
            importConversation: "Importar conversaciones",
            uploadExcelToCompleteDialogueImport:
              "Cargar Excel para completar la importación de la conversación",
            editBasicInformation: "Editar información básica",
            test: "Prueba",
            release: "Lanzamiento",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Nueva etiqueta" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "No se puede recuperar después de la eliminación, ¿está seguro de que desea eliminar esta etiqueta?",
              delete: "Eliminar",
              cancel: "Cancelación",
            },
          },
        },
        index: {
          labelManagement: "Gestión de etiquetas",
          newGroup: "Nuevo grupo",
          edit: "Edición",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "No se puede recuperar después de la eliminación. ¿Está seguro de que desea eliminar este grupo de etiquetas?",
          delete: "Eliminar",
          cancel: "Cancelación",
          editGroup: "Edición de grupos",
          labelGroupName: "Nombre del grupo de etiquetas",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Cámara desactivada",
              noCameraDevicesFound:
                "No se encontró ningún dispositivo de cámara",
            },
          },
          cue: {
            index: {
              regenerate: "Regeneración",
              generate: "Generar",
              referenceScript: "Hablando de referencia",
              mattersNeedingAttention: "Notas",
              examFrequency: "Número de exámenes",
              section: "No.",
              timestotal: "Veces/total",
              second: "VEsegundo",
              requirementsForPassingTheExam:
                "Requisitos de aprobación del examen",
              examScore: "Puntuación del examen ≥",
              branch: "Puntos",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Sin retrato",
              customerBackground: "Antecedentes del cliente",
              customerLabel: "Etiqueta de cliente",
            },
          },
          flow: {
            index: {
              processReminder: "Consejos de proceso (",
              score: "Puntuación",
              TotalScore: "/Puntuación total",
            },
          },
          index: {
            taskName: "Nombre de la tarea",
            taskStatus: "Estado de la tarea",
            whole: "Todo",
            completionStatus: "Estado de finalización",
            courseClassification: "Clasificación del curso",
            practiceMode: "Modo de práctica",
            examMode: "Modo de examen",
            practiceTime: "Tiempo de práctica",
          },
          ppt: {
            index: {
              nextPage: "Siguiente",
              tips: "Consejos",
              continueWithCurrentPage: "Continuar con la página actual",
              goToTheNextPage: "Ir a la página siguiente",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Después de ingresar a la página siguiente, ya no podrá volver a la página anterior, confirme que la página actual está completa y luego salte",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Haga clic en la página siguiente no aparecerá este mensaje",
            },
            list: { index: { slide: "Presentación de diapositivas" } },
          },
          record: {
            index: {
              unableToRecord: "No se puede grabar:",
              creatingARecorderService: "Creación de un servicio de grabación",
              turnOffTheRecorderService: "Cerrar el servicio Recorder",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "El cliente actual está conectado al servidor ASR Websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "Después de procesar el último fotograma, restaure el estado",
              aSRConnectionClosed: "La conexión ASR está cerrada...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "La grabación se detiene y se procesa, pero los datos permanecen en la caché",
              foundASRChannelClosedRecreateWebsocketLink:
                "Descubrir el cierre del canal ASR, volver a crear el enlace Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Vuelva a crear el enlace Websocket al descubrir que el canal ASR no está activado",
            },
            ppt: { hook: { index: { serverError: "Error del servidor" } } },
            text: {
              index: {
                pleaseEnter: "Por favor ingrese",
                sendOut: "Enviar",
                startPracticing: "Empezar a practicar",
                startTheExam: "Iniciar el examen",
              },
            },
            util: {
              recorded: "Grabado",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 bits pcm SendFrameSize debe ser un múltiplo entero de 2",
              pCMSamplingRateShouldNotOccur:
                "La frecuencia de muestreo de pcm no debería aparecer",
              andTheRequiredSamplingRate:
                "Y la frecuencia de muestreo requerida",
              atypism: "Inconsistente",
              errorsThatShouldNotOccur: "Errores que no deberían aparecer:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color: rojo"> Datos desordenados, si los requisitos no son altos, puede descartarlos directamente </span>',
              flower: "Flores",
              TransferStopped: ": Se ha detenido la transmisión",
            },
            video: { index: { camera: "Cámara" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "El cliente actual está conectado al servidor ASR Websocket",
                    aSRConnectionClosed: "La conexión ASR está cerrada...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Descubrir el cierre del canal ASR, volver a crear el enlace Websocket",
                    unableToRecord: "No se puede grabar:",
                    aSRChannelClosedReopened:
                      "El canal ASR se cierra y se vuelve a abrir",
                    theASRChannelWasNotCreatedStartingNow:
                      "El canal ASR no se creó, ahora comienza a crear",
                    useEffectCallbackToClearTheASRChannel:
                      "Devolución de llamada useEffect para borrar el canal ASR",
                    creatingARecorderService:
                      "Creación de un servicio de grabación",
                    turnOffTheRecorderService: "Cerrar el servicio Recorder",
                  },
                  util: {
                    recorded: "Grabado",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 bits pcm SendFrameSize debe ser un múltiplo entero de 2",
                    pCMSamplingRateShouldNotOccur:
                      "La frecuencia de muestreo de pcm no debería aparecer",
                    andTheRequiredSamplingRate:
                      "Y la frecuencia de muestreo requerida",
                    atypism: "Inconsistente",
                    errorsThatShouldNotOccur:
                      "Errores que no deberían aparecer:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color: rojo"> Datos desordenados, si los requisitos no son altos, puede descartarlos directamente </span>',
                    flower: "Flores",
                    TransferStopped: ": Se ha detenido la transmisión",
                  },
                },
                index: { failInSend: "Error de envío" },
              },
              index: {
                startRecording: "Iniciar la grabación",
                endRecording: "Finaliza la grabación",
                startPracticing: "Empezar a practicar",
                startTheExam: "Iniciar el examen",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(El número restante del examen actual es",
              totalScriptScore: "Puntaje total de palabras:",
              totalEmotionalScore: "Puntaje total de emociones:",
              totalScoreOfExpression: "Puntuación total de expresión:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "El resultado de la puntuación de la tarea actual debe revisarse manualmente. Se le notificará el resultado final después de que se complete la revisión. Espere pacientemente",
              areYouSureToSubmit: "¿Confirma el envío?",
              theExerciseHasEndedAreYouSureToSubmit:
                "El ejercicio ha finalizado, ¿confirma el envío?",
              theExamHasEndedAreYouSureToSubmit:
                "El examen ha finalizado, ¿se confirma el envío?",
              completed: "Completado",
              page: "Página",
              pageCount: "Total de páginas",
              topic: "Pregunta",
              totalNumberOfQuestions: "Número total de preguntas",
              totalScore: "Puntuación total",
              branch: "Puntos",
              accumulatedCompletionProgress:
                "Progreso de finalización acumulativo",
              accumulatedPracticeDuration: "Duración acumulada del ejercicio",
              lastPauseExerciseResults:
                "Última pausa-Resultados de la práctica",
              exerciseInstructions: "Descripción del ejercicio",
              examInstructions: "Descripción del examen",
              halfwayPausePracticeResults:
                "Pausa a mitad de camino-Resultados de la práctica",
              tips: "Consejos",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Después de dejar de fumar, este ejercicio no se cuenta en los resultados, ¿se confirma el retiro?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "No se permite la salida a mitad del examen, ¿confirma el envío?",
              pleaseOperateWithCaution: "Vez, tenga cuidado)",
              restart: "Empezar de nuevo",
              returnToLearning: "Volver a estudiar",
              continuePracticing: "Continuar practicando",
              continuingTheExam: "Continuar el examen",
              return: "Volver",
              notSubmittedTemporarily: "No enviar",
              iGotIt: "Ya lo sé.",
              confirmExit: "Confirmar la salida",
              confirmSubmission: "Confirmar la presentación",
              signOut: "Salir",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Suspensión",
              submit: "Presentar",
              remainingTimeOfThisQuestion: "Tiempo restante para esta pregunta",
              SecondsLeftUntilTheEndTime: "Quedan 10 segundos para el final",
              remainingTime: "Tiempo restante",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "No se detecta contenido de voz del usuario, intente de nuevo",
          },
          wave: {
            index: {
              continueRecording: "Continuar grabando",
              submit: "Presentar",
              endRecording: "Finaliza la grabación",
              suspendRecording: "Pausar la grabación",
              startPracticing: "Empezar a practicar",
              startTheExam: "Iniciar el examen",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Modo de diálogo",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Demasiado ruido, cambie a un entorno silencioso",
              normalNoiseDetection: "Detección de ruido normal",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "El volumen es demasiado pequeño, aumente el volumen",
              volumeDetectionIsNormal: "Detección de volumen normal",
              noiseDetection: "Detección de ruido",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Por favor, manténgase en silencio y grabe 3s tono de entorno",
              endRecording: "Finaliza la grabación",
              startRecording: "Iniciar la grabación",
              volumeDetection: "Detección de volumen",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Lea "Prueba de volumen ahora" con volumen normal',
              playLastDetectedAudio: "Reproducir el último audio detectado",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Lea atentamente los requisitos antes de comenzar",
              return: "Volver",
              iGotIt: "Ya lo sé.",
              timeLimitForSingleQuestionAnswering:
                "Límite de tiempo de respuesta de una sola pregunta",
              totalTimeLimit: "Límite de tiempo total",
              unlimitedTime: "Tiempo ilimitado",
              stillNeedToCompleteTheTaskInTotal:
                "La tarea total debe completarse",
              practice: "Ejercicios",
              second: "VEsegundo",
              taskScore: "Puntuación de tarea ≥",
              branch: "Puntos",
              or: "O",
              examScore: "Puntuación del examen ≥",
              remainingExamTimes: "Número de exámenes restantes",
              totalScore: "Puntuación total",
              Script: "(Hablando",
              scoreEmotion: "Emociones",
              fractionExpression: "Expresión de puntos",
              points: "Puntos)",
              standardScore: "Puntos estándar",
              excellentScore: "Excelente puntuación",
              exerciseInstructions: "Descripción del ejercicio",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Para responder la pregunta sin problemas, complete la prueba del equipo primero:",
              camera: "Cámara",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Puede verse en el video, lo que significa que la cámara es normal",
              microphone: "Micrófono",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Por favor, hable con el micrófono, ver el patrón de onda significa que el micrófono es normal",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Vacío",
                  copyTo: "Copiar a",
                  classification: "Clasificación",
                  rename: "Cambiar el nombre",
                  newlyBuild: "Nuevo",
                  categoryParent: "Clasificación superior",
                  moveTo: "Mover a",
                  name: "Nombre",
                },
              },
              util: {
                rename: "Cambiar el nombre",
                moveTo: "Mover a",
                copyTo: "Copiar a",
                delete: "Eliminar",
                courseClassification: "Clasificación del curso",
                newlyBuild: "Nuevo",
              },
            },
          },
          edit: { index: { curriculum: "Cursos" } },
          index: {
            learn: "Aprende",
            practice: "Práctica",
            examination: "Prueba",
            curriculum: "Cursos",
            courseIntroduction: "Introducción al curso:",
            createdOn: "Tiempo de creación:",
            published: "Publicado",
            tasks: "Tareas",
            unpublishedTasks: "Tareas no publicadas",
            task: "Tarea",
            operation: "Operación",
            learningMaterials: "Materiales de aprendizaje",
            goPractice: "Ve a practicar",
            publishTask: "Publicar tareas",
            taskData: "Datos de la tarea",
            edit: "Edición",
            copy: "Copiar",
            successfullyCopied: "Copia exitosa",
            delete: "Eliminar",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Después de la eliminación, todos los datos de la tarea en este curso se borrarán. ¿Confirma la eliminación del curso?",
            courseName: "Nombre del curso",
            creationTime: "Hora de creación",
            releaseStatus: "Estado de publicación",
            whole: "Todo",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Sonido no detectado",
              voice: "Voz",
              text: "Texto",
              referenceScript: "Hablar de referencia:",
              clickOnTheRightSideToStartRecording:
                "Haga clic a la derecha para iniciar la grabación",
              sendOut: "Enviar",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "El cliente actual está conectado al servidor ASR Websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "Después de procesar el último fotograma, restaure el estado",
                aSRConnectionClosed: "La conexión ASR está cerrada...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "La grabación se detiene y se procesa, pero los datos permanecen en la caché",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Descubrir el cierre del canal ASR, volver a crear el enlace Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Vuelva a crear el enlace Websocket al descubrir que el canal ASR no está activado",
                unableToRecord: "No se puede grabar:",
                creatingARecorderService:
                  "Creación de un servicio de grabación",
                turnOffTheRecorderService: "Cerrar el servicio Recorder",
              },
              util: {
                recorded: "Grabado",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 bits pcm SendFrameSize debe ser un múltiplo entero de 2",
                pCMSamplingRateShouldNotOccur:
                  "La frecuencia de muestreo de pcm no debería aparecer",
                andTheRequiredSamplingRate:
                  "Y la frecuencia de muestreo requerida",
                atypism: "Inconsistente",
                errorsThatShouldNotOccur: "Errores que no deberían aparecer:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color: rojo"> Datos desordenados, si los requisitos no son altos, puede descartarlos directamente </span>',
                flower: "Flores",
                TransferStopped: ": Se ha detenido la transmisión",
              },
            },
            finishTest: {
              index: {
                endTest: "Finaliza la prueba",
                testResult: "Resultados de la prueba",
                returnToCanvas: "Volver al lienzo",
                retest: "Vuelva a probar",
              },
            },
          },
          index: {
            testFromCurrentNode: "Probar desde el nodo actual",
            listening: "En la radio...",
            canvasTesting: "Prueba de lienzo",
            endOfProcess: "Fin del proceso",
            restart: "Empezar de nuevo",
            start: "Empezar",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Apagar el análisis",
                  expandParsing: "Expande el análisis",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: ", Demasiadas palabras de aire",
                  second: "VEsegundo",
                  ratingOverview: "Descripción general de la calificación",
                  totalScore: "Puntuación total",
                  section: "No.",
                  name: "Nombre",
                  of: "(Total",
                  people: "Gente)",
                  scriptScore: "Puntuación de palabras",
                  common: "Total",
                  keyPoints: "Puntos clave,",
                  notReached: "No alcanzado,",
                  have: "Sí.",
                  violationOfRegulations: "Violación",
                  emotionalScore: "Puntuación emocional",
                  appear: "Aparece",
                  secondaryNegativeEmotions: "Emoción negativa",
                  expressionScore: "Puntuación de expresión",
                  speakTooFast: "Velocidad de habla demasiado rápida",
                  times: "VEdos,",
                  speakTooSlowly: "La velocidad del habla es demasiado lenta",
                  average: "Promedio",
                  wordminute: "Palabra/minuto",
                },
              },
              score: {
                index: {
                  studentNodeName: "Nombre del nodo del estudiante",
                  keyPointName: "Nombre del punto",
                  slideNodeName: "Nombre del nodo de diapositiva",
                  dialogue: "Diálogo",
                  mainPoints: "Puntos clave",
                  slide: "Presentación de diapositivas",
                  detectionResult: "Resultados de la prueba",
                  satisfy: "Satisfacer",
                  dissatisfaction: "No satisfecho",
                  score: "Puntuación",
                  script: "Hablando",
                  semantics: "Semántica",
                  scriptViolation: "Violación del habla",
                  emotion: "Emoción",
                  expression: "Expresión",
                },
              },
              taskTitle: { index: { branch: "Puntos" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Esta vez",
                  lastTime: "La última vez",
                  comprehensiveEvaluation: "Evaluación integral",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Acumulación de palabras estándar',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Palabras, el número total de palabras golpeadas por los estudiantes",
                  aWordspan: "Palabras </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Vs </span><br /><span style = "margin-left: 8px">· La velocidad del habla es demasiado lenta',
                  timesspan: "Veras </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Demasiadas palabras de aire',
                  complete: "Contenido completo",
                  keyHit: "Punto de acierto",
                  languageNorm: "Especificación del lenguaje",
                  fluentExpression: "Expresión fluida",
                  emotionallyPositive: "Emocional positivo",
                  radarChart: "Mapa de radar",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Número completo de procesos',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· Puntos de acierto',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· Hay',
                  dealingWithIllegalWordsspan: "Palabras infractoras </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· La velocidad del habla es demasiado rápida',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Vs </span><br /><span style = "margin-left: 8px">· Demasiadas palabras de aire',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Emociones negativas',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Registro de puntuación",
                  firstRating: "Primera puntuación",
                  totalScore: "Puntuación total",
                  changeTo: "Cambiar a",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Fase de comunicación",
                  communicationObjectives: "Objetivos de comunicación",
                  scoringCriteria: "Criterios de puntuación",
                  suggestionsForImprovement: "Sugerencias de promoción",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Número restante de veces:",
                  second: "VEsegundo",
                  doItAgain: "Una vez más.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "El navegador no admite la reproducción de audio.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Comentarios y sugerencias",
                  regenerate: "Regeneración",
                  generate: "Generar",
                  generationFailed: "Error de generación",
                },
              },
              resultModal: {
                index: { return: "Volver", viewDetails: "Ver detalles" },
              },
            },
            index: {
              reRatingSuccessful: "Vuelva a calificar con éxito",
              reRatingFailed: "No se pudo volver a calificar",
              downloadRecording: "Descargar la grabación",
              downloadText: "Descargar texto",
              download: "Descargar",
              reRating: "Volver a calificar",
              submitARatingAppeal: "Presentar una apelación de calificación",
              reviewSubmission: "Presentación de revisión",
              saveChanges: "Guardar modificaciones",
              previousSentence: "Oración anterior",
              nextSentence: "La siguiente oración",
              score: "Calificación",
              scoringSituation: "Situación de puntuación",
              dialogueAnalysis: "Análisis de diálogo",
            },
          },
          index: {
            ratingAppealInProgress: "Calificaciones en apelación",
            operation: "Operación",
            wrongQuestionSet: "Conjunto de preguntas incorrecto",
            ratingUpdate: "Actualizaciones de calificación",
            viewDetails: "Ver detalles",
            curriculum: "Cursos",
            taskName: "Nombre de la tarea",
            taskType: "Tipo de tarea",
            score: "Puntuación",
            scoringResults: "Resultados de puntuación",
            time: "Tiempo",
            courseClassification: "Clasificación del curso",
            whole: "Todo",
            learningTime: "Tiempo de estudio",
            historicalLearningData: "Datos históricos de aprendizaje",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Suspensión de práctica",
              lastProgress: "Último progreso",
              continuePracticing: "Continuar practicando",
              practice: "Ejercicios",
              startPracticing: "Empezar a practicar",
              historicalData: "Datos históricos",
              courseIntroduction: "Introducción al curso:",
              exerciseDifficulty: "Dificultad de práctica:",
              practiceCompletionRequirements:
                "Requisitos de finalización del ejercicio:",
              practiceFrequency: "Número de ejercicios ≥",
              second: "VEsegundo",
              anyScore: "Cualquier puntuación ≥",
              branch: "Puntos",
              or: "O",
              taskList: "Lista de tareas",
              practiceTime: "Tiempo de práctica:",
              to: "Para",
              unlimitedTime: "Tiempo ilimitado",
              completionStatus: "Estado de finalización",
              numberOfExercises: "Número de ejercicios",
              maximumScore: "Puntuación más alta",
              operation: "Operación",
              enteringLearning: "Entrar en el aprendizaje",
              historicalLearningData: "Datos históricos de aprendizaje",
              wrongQuestionSet: "Conjunto de preguntas incorrecto",
            },
          },
          index: {
            practiceTasks: "Tareas de práctica",
            examTasks: "Tareas de examen",
            task: "Tarea",
          },
          learn: {
            index: {
              courseName: "Nombre del curso",
              courseIntroduction: "Introducción al curso",
              learningContent: "Contenido de aprendizaje",
              downloadLearningMaterials:
                "Descarga de materiales de aprendizaje",
              startPracticing: "Empezar a practicar",
            },
          },
          test: {
            index: {
              taskList: "Lista de tareas",
              courseIntroduction: "Introducción al curso:",
              examDifficulty: "Dificultad del examen:",
              requirementsForPassingTheExam:
                "Requisitos de aprobación del examen:",
              examScore: "Puntuación del examen ≥",
              branch: "Puntos",
              examTime: "Tiempo de examen:",
              to: "Para",
              unlimitedTime: "Tiempo ilimitado",
              completionStatus: "Estado de finalización",
              completionTimes: "Número de terminaciones",
              maximumScore: "Puntuación más alta",
              operation: "Operación",
              immediateExam: "Examen inmediato",
              history: "Historia",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Hablando de referencia" } },
              flow: {
                index: {
                  wrongQuestionItem: "Pregunta incorrecta (",
                  score: "Puntuación",
                  TotalScore: "/Puntuación total",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Error del servidor" } },
                  index: {
                    startRecording: "Iniciar la grabación",
                    endRecording: "Finaliza la grabación",
                    startPracticing: "Empezar a practicar",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Por favor ingrese",
                    sendOut: "Enviar",
                    startPracticing: "Empezar a practicar",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Iniciar la grabación",
                    endRecording: "Finaliza la grabación",
                    startPracticing: "Empezar a practicar",
                  },
                  hook: { index: { failInSend: "Error de envío" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Puntuación de esta práctica de pregunta incorrecta",
                  tips: "Consejos",
                  areYouSureToEnd: "¿Se confirma el final?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Practica de nuevo sin dominar la pregunta incorrecta",
                  continue: "Continuar",
                  returnToTheSetOfIncorrectQuestions:
                    "Volver al conjunto de preguntas incorrecto",
                  end: "Fin",
                  wrongQuestionItem: "Pregunta incorrecta",
                  numberOfExercisesThisTime: "Número de ejercicios",
                  masteringTheSituation: "Dominar la situación",
                  mastered: "Dominar",
                  notMastered: "No dominado",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain:
                    "Practicar esta pregunta nuevamente",
                  nextQuestion: "Pregunta siguiente",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Ejercicios de preguntas incorrectas",
                endExercise: "Termina el ejercicio",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Ejercicios de preguntas incorrectas",
                endExercise: "Termina el ejercicio",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Ejercicios de preguntas incorrectas",
                endExercise: "Termina el ejercicio",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Ver palabras",
                  section: "No.",
                  secondaryDiscourseTechnique: "Segunda palabra",
                },
              },
              tableItem: {
                count: { second: "VEsegundo" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "No se encontró información relevante",
                  recallWords: "Palabras de recuerdo",
                  branch: "Puntos",
                  answerIncorrectly: "Respuesta incorrecta",
                  second: "VEsegundo",
                  wrongAnswerRate: "Tasa de respuesta incorrecta",
                },
                standardScript: {
                  standardScript: "Hablando estándar",
                  myScript: "Mis palabras",
                },
                status: { mastered: "Dominar", notMastered: "No dominado" },
                index: {
                  totalNumberOfQuestionsAnswered: "Número total de respuestas",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Número de veces que se ha practicado la pregunta incorrecta",
                },
              },
            },
            index: {
              wrongQuestionList: "Lista de preguntas incorrectas",
              wrongQuestionSet: "Conjunto de preguntas incorrecto",
              mispracticeExercises: "Ejercicios de preguntas incorrectas",
              taskName: "Nombre de la tarea",
              staff: "Empleados",
              masteringTheSituation: "Dominar la situación",
              whole: "Todo",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Número de ejercicios ≥",
              second: "VEsegundo",
              or: "O",
              excellentScoreAtAnyOneTime:
                "Excelente puntuación en cualquier momento (≥",
              points: "Puntos)",
              examScore: "Puntuación del examen ≥",
              branch: "Puntos",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Distribución equitativa aleatoria",
              assignToDesignatedPersonnel: "Asignación a personas designadas",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Aumentar el número de exámenes exitosos",
              failedToIncreaseTheNumberOfExams:
                "Incrementar el número de exámenes fallidos",
              increaseTheNumberOfExams: "Aumentar el número de exámenes",
              selectObjects: "Selección de objetos",
            },
          },
          executionFilter: {
            index: { participationRate: "Tasa de participación" },
          },
          intelligentReview: {
            index: {
              overallReview: "Revisión general",
              pleaseEnterTheReviewCriterianotMandatory:
                "Ingrese el estándar de revisión (no obligatorio)",
              commentaryOnSingleSentenceRhetoric:
                "Reseñas de palabras de una sola oración",
              realTimeReview: "Reseñas en tiempo real",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Promedio de la prueba",
            averageExamDurationmin: "Duración media del examen (min)",
            accumulatedPracticeDurationmin:
              "Duración acumulada del ejercicio (min)",
            accumulatedPracticeTimes: "Número acumulado de ejercicios",
            totalScore: "Puntuación total",
            average: "Promedio",
            total: "Total",
            keyHitCount: "Número de aciertos",
            practiceAverageScore: "Promedio de ejercicios",
            averagePracticeDurationmin: "Duración media de la práctica (min)",
            topOfTheHighestScore: "Puntaje más alto Top3",
            branch: "Puntos",
            errorProneItemTop: "Top5 elementos propensos a errores",
            hitRate: "Tasa de aciertos",
            hitRateOfKeyPointsInScript: "Puntos clave de la tasa de aciertos",
            averageScriptScore: "Puntuación media de palabras",
            sortByProcess: "Ordenar por proceso",
            sortByScore: "Ordenar por puntuación",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "La puntuación media de la página/puntuación total de la página",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "El puntaje promedio/puntaje total",
            details: "Detalles",
            viewRules: "Ver las reglas",
            standardScript: "Hablando estándar",
          },
          scoreDetailList: {
            section: "No.",
            second: "VEsegundo",
            practice: "Ejercicios",
            examination: "Examen",
            notMeetingStandards: "No a la altura",
            meetingStandards: "Cumplimiento",
            excellent: "Excelente",
            frequency: "Número de veces",
            fraction: "Puntuación",
            result: "Resultados",
            operation: "Operación",
            viewDetails: "Ver detalles",
          },
          index: {
            common: "Total",
            people: "Hombre",
            staff: "Empleados",
            notMeetingStandards: "No a la altura",
            meetingStandards: "Cumplimiento",
            excellent: "Excelente",
            notStarted: "Sin empezar",
            haveInHand: "En progreso",
            completed: "Completado",
            passed: "Pasado",
            hangInTheAir: "Sin terminar",
            failed: "No pasó",
            unlimitedTime: "Tiempo ilimitado",
            to: "Para",
            maximumScoringCriteria: "El puntaje más alto",
            completionStatus: "Estado de finalización",
            employeeName: "Nombre del empleado",
            frequency: "Número de veces",
            practice: "Ejercicios",
            examination: "Examen",
            average: "Promedio",
            speechCraftAvgScore: "Puntuación media de palabras",
            emotionAvgScore: "Puntuación media emocional",
            speedAvgScore: "Puntuación media de expresión",
            flowAvgScore: "Puntuación media del proceso",
            practiceCostTime: "Duración acumulada",
            participateCommitRate: "Tasa de cumplimiento",
            participateExcellentRate: "Tasa de excelencia",
            duration: "Duración",
            score: "Puntuación",
            scoringResults: "Resultados de puntuación",
            flowScore: "Puntuación del proceso",
            craftScore: "Puntuación de palabras",
            emotionScore: "Puntuación emocional",
            speedScore: "Puntuación de expresión",
            contentIntegrity: "Integridad del contenido",
            pointHitRate: "Puntos clave",
            emotionNegativeRate: "La relación negativa emocional",
            paraPhaSiaRate: "Velocidad anormal del habla",
            toneOvermuchRate: "Demasiadas palabras de aire",
            maximumScore: "Puntuación más alta",
            highestScoreResult: "Máximo resultado de puntuación",
            state: "Estado",
            averageDurationmin: "Duración media (min)",
            lately: "Recientemente",
            time: "Tiempo",
            operation: "Operación",
            viewDetails: "Ver detalles",
            wrongQuestionSet: "Conjunto de preguntas incorrecto",
            taskName: "Nombre de la tarea",
            completeRequirements: "Requisitos de finalización",
            publishTo: "Publicar objetos",
            taskTime: "Tiempo de tarea",
            fractionalComposition: "Composición de la fracción",
            totalScore: "Puntuación total",
            branch: "Puntos",
            Script: "(Hablando",
            scoreEmotion: "Emociones",
            fractionExpression: "Expresión de puntos",
            points: "Puntos)",
            implementationRate: "Tasa de participación",
            numberOfExecutors: "Número de participantes",
            numberOfPeopleNotExecuted: "Número de no participantes",
            completionRate: "Tasa de finalización",
            numberOfCompletedPersonnel: "Número de completados",
            numberOfUnfinishedPersonnel: "Número de personas inconclusas",
            complianceRate: "Tasa de cumplimiento",
            numberOfQualifiedPersonnel:
              "Número de personas que cumplen con los estándares",
            numberOfNonCompliantIndividuals:
              "Número de personas que no cumplen con el estándar",
            excellentRate: "Tasa de excelencia",
            participateCompleteRate: "Tasa de finalización de participación",
            numberOfOutstandingIndividuals: "Excelente número de personas",
            numberOfNonOutstandingIndividuals:
              "Número de personas no destacadas",
            export: "Exportar",
            taskData: "Datos de la tarea",
            resultAnalysis: "Análisis de resultados",
            scoreDetails: "Detalles de la puntuación",
            dataOverview: "Visión general de los datos",
            taskDetail: "Detalles de la tarea",
            scriptAnalysis: "Análisis de palabras",
            flowAnalysis: "Análisis de procesos",
            expressionAnalysis: "Análisis de expresión",
            emotionAnalysis: "Análisis de emociones",
            byTime: "Vista de dimensión de tiempo",
            byNumber: "Vista de dimensión de número de veces",
            personData: "Estadísticas por personal",
            numberData: "Detalles del registro por tiempo",
            count: "Por número de veces",
            person: "Por número de personas",
            reachTimes: "Número de toques",
            outOfReachTimes: "Número de touchdown",
            seeOutOfReach: "Haga clic para ver no alcanzado",
            accumulative: "Acumulado",
            script: "Hablando",
            times: "VEsegundo",
            sentence: "Oración",
            expressionAverage: "Promedio de expresión",
            modalAnalysis: "Análisis de palabras de qi",
            total: "Total",
            muchModalAnalysis: "Hay demasiadas palabras de aire",
            normal: "Normal",
            speedAnalysis: "Análisis de la velocidad del habla",
            speedDistribution: "Distribución de la velocidad del habla",
            speakingSpeed: "Velocidad de habla",
            normalSpeak: "Velocidad normal del habla",
            tooFastSpeak: "Velocidad de habla demasiado rápida",
            tooSlowlySpeak: "Velocidad de habla demasiado lenta",
            violationWord: "Palabras infractoras",
            violationWordAnalysis: "Análisis de palabras ilegales",
            noData: "No",
            emotionalScore: "Puntuación emocional",
            emotionalDistribution: "Distribución de emociones",
            negativeEmotion: "Emociones negativas",
            normalEmotion: "Emociones normales",
            getDetail: "Ver detalles",
            barPattern: "Vista de gráfico de barras",
            timeDimension: "Vista de latitud de tiempo",
            timesDimension: "Vista de dimensión de número de veces",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Promedio",
                  averageScoreOfScript: "Promedio de palabras",
                  averageEmotionalScore: "Promedio emocional",
                  expressionAverageScore: "Puntuación media de expresión",
                  processAverageScore: "Promedio del proceso",
                  accumulatedDuration: "Duración acumulada",
                  accumulatedTimes: "Número acumulado de veces",
                  participationComplianceRate:
                    "Tasa de cumplimiento de la participación",
                  participationRateForAchievingExcellence:
                    "Tasa de participación",
                  branch: "Puntos",
                  numberOfPeople: "Número de personas",
                  averageScoreOfExpression: "Promedio de expresión",
                  people: "Hombre",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Emociones negativas" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Puntuación media de palabras",
                  branch: "Puntos",
                },
                useSummaryDetail: { keyHitRate: "Puntos clave" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Revisión inteligente",
            pleaseImproveTheConfiguration: "Mejore la configuración",
            examResults: "Resultados del examen",
            showTheResultsAfterEachExam:
              "Mostrar resultados después de cada examen",
            showTheResultsAfterTheLastExam:
              "Los resultados del último examen se muestran",
            doNotDisplayResults: "No mostrar resultados",
            resultDisplay: "Presentación de resultados",
            score: "Puntuación",
            scoringResults: "Resultados de puntuación",
            allowViewingScoreDetails: "Permitir ver detalles de puntuación",
            viewCanvasConfiguration: "Ver la configuración del lienzo",
            synchronizationFailedProcessNodeHasChanged:
              "Error de sincronización, el nodo de proceso ha producido cambios",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "La sincronización es exitosa, haga clic para publicar y actualizar el curso",
            retrievedTheLatestCanvasConfiguration:
              "Se ha extraido la última configuración de lienzo",
            synchronizeCanvasConfiguration:
              "Sincronizar la configuración del lienzo",
            scoringMethod: "Método de puntuación",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Puntos, proceso {0} puntos, palabras {1} puntos, emociones {2} puntos, velocidad del habla {3} puntos)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Los excelentes estándares deben ser más altos que los estándares",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "El estándar de puntuación debe cumplir más del 0%, menos o igual al 100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "El estándar de puntuación debe cumplir con más de 0 puntos, menos o igual a {0} puntos",
            doRatingsRequireManualReview:
              "¿Es necesario revisar manualmente la puntuación?",
            allocationStrategy: "Estrategia de asignación",
            pleaseSelectTheAssignedPersonnel: "Seleccione el personal asignado",
            pleaseSelectADesignatedPerson: "Seleccione la persona designada",
            timeLimitForSingleQuestionAnswering:
              "Límite de tiempo de respuesta de una sola pregunta",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Publicación predeterminada para nuevos empleados",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "La nueva puntuación significa que se coincidirá de acuerdo con las reglas de puntuación del lienzo más reciente. La tarea actual tiene datos de puntuación revisados manualmente. ¿Se debe sobrescribir la puntuación?",
            notCovered: "No cubierto",
            cover: "Cobertura",
            participationSituation: "Participación",
            numberOfParticipants: "Número de participantes",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "El número de objetos de práctica seleccionados para esta tarea se suma",
            sumOfTheNumberOfParticipantsInTheTask:
              "Número de personas que han participado en la misión",
            numberOfCompletedPersonnel: "Número de completados",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Número de personas que cumplen con los requisitos de finalización de tareas",
            numberOfQualifiedPersonnel:
              "Número de personas que cumplen con los estándares",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "El número de personas que cumplen con el puntaje de la misión",
            excellentPersonnel: "Excelente número de personas",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "El número de personas que cumplen con la puntuación de la misión es excelente",
            participationRate: "Tasa de participación",
            numberOfParticipantsNumberOfParticipants:
              "Número de participantes/número de participantes",
            numberOfCompletedNumberOfParticipants:
              "Número de completados/número de participantes",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Número de personas que cumplen con los estándares/número de participantes",
            excellentNumbernumberOfParticipants:
              "Número de personas destacadas/número de participantes",
            participationCompletionRate:
              "Tasa de finalización de participación",
            numberOfCompletedParticipated:
              "Número de completados/número de participantes",
            participationComplianceRate:
              "Tasa de cumplimiento de la participación",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Número de personas que cumplen con los estándares/número de participantes",
            participationRateForAchievingExcellence: "Tasa de participación",
            accumulatedDuration: "Duración acumulada",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Los participantes participan en la tarea",
            averageDuration: "Duración media",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Duración de la participación de los participantes en la tarea/Número total de participación",
            accumulatedTimes: "Número de tiempos acumulados",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "El número de veces que los estudiantes participan en la suma de tareas",
            averageScore: "Promedio",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Puntuación media de las personas que participan en la misión",
            maximumScore: "Puntuación más alta",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Puntuación más alta para las personas involucradas en la misión",
            averageScoreOfScript: "Promedio de palabras",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Puntuación/participación total de los estudiantes",
            totalScoreOfScript: "Puntaje total de palabras",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Establecer la puntuación total de palabras en esta tarea",
            processAverageScore: "Promedio del proceso",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Puntuación/número total de participantes en el proceso de estudiantes",
            totalProcessScore: "Puntuación total del proceso",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Puntuación total del proceso establecido en esta tarea",
            averageEmotionalScore: "Promedio emocional",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Puntuación emocional de los estudiantes/número total de participación",
            totalEmotionalScore: "Puntuación total de emociones",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "La puntuación total de las emociones establecidas bajo la tarea",
            averageScoreOfExpression: "Promedio de expresión",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Puntuación/participación total de los participantes",
            totalScoreOfExpression: "Expresa la puntuación total",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "La puntuación total de la expresión establecida en esta tarea",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Comience a volver a calificar, puede saltar al Centro de tareas para ver el progreso",
            reRating: "Volver a calificar",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "La nueva puntuación significa que se coincidirá de acuerdo con las reglas de puntuación del lienzo más reciente y sobrescribirá el resultado de puntuación actual",
            releaseTime: "Tiempo de lanzamiento",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Auditado",
              pendingReview: "Para ser revisado",
              enterKeywordSearch: "Ingrese la búsqueda de palabras clave",
            },
            pageRightTable: {
              index: {
                employeeName: "Nombre del empleado",
                systemRating: "Puntuación del sistema",
                scoringResults: "Resultados de puntuación",
                duration: "Duración",
                time: "Tiempo",
                state: "Estado",
                whetherToModifyTheRating: "Ya sea para corregir la puntuación",
                finalScore: "Puntuación final",
                reviewedBy: "Auditor",
                operation: "Operación",
                viewScoreDetails: "Ver detalles de la puntuación",
                viewStatus: "Ver estado",
                whole: "Todo",
                correctionStatus: "Estado de corrección",
                completeReview: "Completar la auditoría",
                tips: "Consejos",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "El puntaje final del estudiante está sujeto al resultado final actual de la revisión, confirme",
              },
            },
          },
          index: { manualReview: "Auditoría manual" },
        },
        review: {
          index: {
            employeeName: "Nombre del empleado",
            score: "Puntuación",
            scoringResults: "Resultados de puntuación",
            duration: "Duración",
            taskName: "Nombre de la tarea",
            numberOfAppealScripts: "Número de quejas",
            appealTime: "Tiempo de apelación",
            processStatus: "Estado del proceso",
            scoreAfterReview: "Puntuación después de la revisión",
            reviewedBy: "Reexaminador",
            operation: "Operación",
            viewScoreStatus: "Ver la puntuación",
            whole: "Todo",
            manualReview: "Revisión manual",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Datos de tareas de práctica",
            implementationRate: "Tasa de ejecución",
            numberOfExecutors: "Número de ejecutivas",
            numberOfPeopleNotExecuted: "Número de personas no ejecutadas",
            completionRate: "Tasa de finalización",
            numberOfCompletedPersonnel: "Número de completados",
            numberOfUnfinishedPersonnel: "Número de personas inconclusas",
            complianceRate: "Tasa de cumplimiento",
            numberOfQualifiedPersonnel:
              "Número de personas que cumplen con los estándares",
            numberOfNonCompliantIndividuals:
              "Número de personas que no cumplen con el estándar",
            excellentRate: "Tasa de excelencia",
            numberOfOutstandingIndividuals: "Excelente número de personas",
            numberOfNonOutstandingIndividuals:
              "Número de personas no destacadas",
            examTaskData: "Datos de tareas de examen",
            published: "Publicado",
            courses: "Cursos",
            courseData: "Datos del curso",
            practiceTasks: "Tareas de práctica",
            examTasks: "Tareas de examen",
          },
          practiceSummary: {
            practiceSummary: "Resumen de ejercicios",
            numberOfPracticeTasks: "Número de tareas de práctica",
            numberOfExercises: "Número de ejercicios",
            accumulatedPracticeDuration: "Duración acumulada del ejercicio",
            complianceRate: "Tasa de cumplimiento",
            excellentRate: "Tasa de excelencia",
            ranking: "Clasificación",
          },
          rankingList: {
            numberOfExercises: "Número de ejercicios",
            exerciseDuration: "Duración de la práctica",
            complianceRate: "Tasa de cumplimiento",
            excellentRate: "Tasa de excelencia",
            diligenceChart: "Lista de diligencia",
            excellentList: "Excelente lista",
            ranking: "Clasificación",
            fullName: "Nombre",
            i: "Yo...",
          },
          taskCard: {
            end: "Fecha límite:",
            noDeadline: "Sin fecha límite",
            second: "VEsegundo",
            branch: "Puntos",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Sin tarea",
            completed: "Completado",
          },
          filter: { employeeDepartment: "Departamento de empleados" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "La exportación es exitosa, vaya al centro de tareas para ver",
              export: "Exportar",
            },
          },
          filterConfig: {
            dropdown: { custom: "Personalización" },
            modal: {
              updateSuccessful: "Actualización exitosa",
              successfullySaved: "Ahorre con éxito",
              setAsACommonReport: "Establecer como informe común",
              updateCurrentReport: "Actualizar el informe actual",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Promedio",
                  averageScoreOfScript: "Puntuación media de palabras",
                  averageEmotionalScore: "Puntuación media emocional",
                  expressionAverageScore: "Puntuación media de expresión",
                  processAverageScore: "Puntuación media del proceso",
                  branch: "Puntos",
                },
              },
              comprehensiveEvaluation: { const: { data: "Datos" } },
              doneStatus: {
                index: {
                  practice: "Ejercicios",
                  examination: "Examen",
                  executed: "Ejecutado",
                  unexecuted: "No ejecutado",
                  accumulatedExamDuration: "Duración acumulada del examen",
                  examFrequency: "Número de exámenes",
                },
              },
              filter: {
                index: {
                  practice: "Ejercicios",
                  examination: "Examen",
                  pleaseCompleteTheRequiredFields:
                    "Por favor complete los elementos requeridos",
                  staff: "Empleados",
                  taskType: "Tipo de tarea",
                  pleaseSelect: "Por favor elija",
                  taskName: "Nombre de la tarea",
                },
              },
            },
            index: {
              completionStatus: "Estado de finalización",
              comprehensiveEvaluation: "Evaluación integral",
              abilityGrowthTrend: "Tendencia de crecimiento de la capacidad",
              errorProneAnalysis: "Análisis de puntos propensos a errores",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Número de personas activas",
            accumulatedPracticeDuration: "Duración acumulada del ejercicio",
            averagePracticeDuration: "Duración media de la práctica",
            people: "Hombre",
            numberOfPracticeTasks: "Número de tareas de práctica",
            numberOfExercises: "Número de ejercicios",
            exerciseDuration: "Duración de la práctica",
            second: "VEsegundo",
            avgScore: "Promedio",
            speechCraftAvgScore: "Puntuación media de palabras",
            emotionAvgScore: "Puntuación media emocional",
            speedAvgScore: "Puntuación media de expresión",
            flowAvgScore: "Puntuación media del proceso",
            practiceCostTime: "Duración acumulada",
            totalPracticeTimes: "Número acumulado de veces",
            participateCommitRate: "Tasa de cumplimiento de la participación",
            participateExcellentRate: "Tasa de participación",
          },
        },
        index: {
          employeeProfile: "Retrato del empleado",
          overview: "Resumen",
          personalSignage: "Kanban personal",
          ongoingExercises: "Ejercicios en curso",
          ongoingExams: "Examen en curso",
          practiceTaskProgress: "Progreso de la tarea de práctica",
          examTaskProgress: "Progreso de la tarea de examen",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Seleccione la palabra conceptual",
              sessionDetectionSettings: "Configuración de detección de sesión",
              successfullySaved: "Ahorre con éxito",
              abnormalSpeechSpeed: "Velocidad anormal del habla",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Admite estándares de prueba de velocidad de habla personalizados, y el resultado se juzgará como velocidad de habla normal, velocidad de habla demasiado rápida y velocidad de habla demasiado lenta. Cuando hay una velocidad de habla anormal (demasiado rápida/demasiado lenta), la puntuación se reducirá de acuerdo con las reglas de puntuación.",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Velocidad de habla demasiado rápida: mayor que cada minuto",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Palabras, la velocidad del habla es demasiado lenta: menos de cada minuto",
              charactersIgnoringLessThan: "Palabras, ignoradas menos que",
              aSentenceOfWords: "Palabra de oración",
              excessiveModalParticles: "Demasiadas palabras de aire",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Admite palabras de aire personalizadas y establece reglas para demasiadas palabras de aire. Cuando hay demasiadas palabras de entonación, la puntuación se reducirá de acuerdo con las reglas de puntuación.",
              theNumberOfRepetitionsIs: "El número de repeticiones es",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "En segundo lugar, se consideró que había demasiadas palabras de aire.",
              definitionOfSensitiveViolations:
                "Definición de infracción sensible",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Admite un tesauro de palabras ilegales personalizado para detectar si hay palabras ilegales en una oración. Cuando hay una palabra de infracción, la puntuación se reducirá de acuerdo con las reglas de puntuación.",
              violationWords: "Palabras infractoras:",
              emotionalDetection: "Detección de emociones",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "La detección de emociones se realiza de acuerdo con la técnica de diálogo del modelo emocional del sistema, y los resultados se juzgarán como emociones normales y negativas (incluidas negativas, enojadas y quejas). Cuando hay una dirección emocional negativa, la puntuación se reducirá de acuerdo con las reglas de puntuación.",
              preserve: "Guardar",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Dimensiones de puntuación",
              ratingPoints: "Puntos de puntuación",
              explain: "Descripción",
              type: "Tipo",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Datos aleatorios" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Acumulación de palabras estándar',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Palabras, el número total de palabras golpeadas por los estudiantes",
                  aWordspan: "Palabras </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Vs </span><br /><span style = "margin-left: 8px">· La velocidad del habla es demasiado lenta',
                  timesspan: "Veras </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Demasiadas palabras de aire',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "La puntuación es" },
                  item: {
                    violationOnceReduced: "Violación 1 reducción",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Velocidad anormal del habla (incluida la velocidad del habla demasiado rápida y la velocidad del habla demasiado lenta) 1 vez menos",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Demasiadas palabras de qi se reducen 1 vez",
                    oneNegativeDecreaseInEmotions:
                      "Sentimiento negativo 1 vez menos",
                    branch: "Puntos",
                  },
                  wrap: { name: "Nombre", preserve: "Guardar" },
                },
              },
              index: {
                complete: "Contenido completo",
                keyHit: "Punto de acierto",
                accurateFollowupReading: "Sigue con precisión",
                languageNorm: "Especificación del lenguaje",
                speechSpeed: "Velocidad del habla",
                modalParticles: "Palabra de tono",
                emotionallyPositive: "Emocional positivo",
                scoringModel: "Modelo de puntuación",
                successfullyModified: "Modificación exitosa",
                newSuccessfullyCreated: "Nuevo éxito",
              },
            },
            index: {
              scoringModel: "Modelo de puntuación",
              newModel: "Nuevo modelo",
              model: "Modelo",
              type: "Tipo",
              operation: "Operación",
              preview: "Vista previa",
              edit: "Edición",
            },
          },
          index: {
            scoringModel: "Modelo de puntuación",
            ratingDimension: "Dimensiones de puntuación",
            sessionDetectionSettings: "Configuración de detección de sesión",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Por favor elija",
                hit: "Golpe",
                misses: "Sin hits",
              },
            },
          },
          index: {
            duration: "Duración",
            processScore: "Puntuación del proceso",
            branch: "Puntos",
            contentCompleteness: "Integridad del contenido",
            scriptScore: "Puntuación de palabras",
            keyHitRate: "Puntos clave",
            emotionalScore: "Puntuación emocional",
            negativeProportionOfEmotions: "La relación negativa emocional",
            expressionScore: "Puntuación de expresión",
            theProportionOfAbnormalSpeechSpeed: "Velocidad anormal del habla",
            excessiveProportionOfModalParticles: "Demasiadas palabras de aire",
            learningRecordData: "Datos de registro de aprendizaje",
            staff: "Empleados",
            curriculum: "Cursos",
            mainPoints: "Puntos clave",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Formularios",
                        lineChart: "Gráfico de líneas",
                        barChart: "Gráfico de barras",
                        pieChart: "Gráfico circular",
                      },
                      index: { switchingTypes: "Tipo de conmutación" },
                    },
                  },
                  index: {
                    saveSuccessful: "Guardar con éxito",
                    editChart: "Editar gráfico",
                    chartName: "Nombre del gráfico",
                    deleteSuccessful: "La eliminación se realizó correctamente",
                    refresh: "Actualizar",
                    export: "Exportar",
                    areYouSureToDeleteIt: "¿Está seguro de eliminar?",
                    delete: "Eliminar",
                    viewFilteringCriteria: "Ver criterios de filtro",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "La exportación es exitosa, vaya al centro de tareas para ver",
              },
            },
            header: {
              index: {
                saveSuccessful: "Ahorre con éxito",
                deleteSuccessful: "La eliminación se realizó correctamente",
                editReport: "Edición de informes",
                reportName: "Nombre del informe",
                refresh: "Actualizar",
                areYouSureToDeleteIt: "¿Está seguro de eliminar?",
                delete: "Eliminar",
                exportToExcel: "Exportar Excel",
                exportSnapshot: "Exportar instantáneas",
                export: "Exportar",
                authority: "Permisos",
                permissionSettings: "Configuración de permisos",
                reportVisiblePermissions: "Permisos visibles del informe",
                visibleToEveryone: "Visible para todos",
                onlyVisibleToOneself: "Solo visible para ti",
                designatedPersonnelVisible: "El personal designado es visible",
                pleaseSelect: "Por favor elija",
                reportDataPermissions: "Permisos de datos de informes",
                restrictedByUserPermissions: "Permisos de usuario limitados",
                visiblePeopleAreTheSame:
                  "Se puede ver que las personas son iguales",
              },
            },
            titleFooter: {
              index: {
                addReport: "Agregar informe",
                reportName: "Nombre del informe",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "La exportación es exitosa, vaya al centro de tareas para ver",
            dataQueryInProgress: "Consulta de datos...",
            customKanban: "Kanban personalizado",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Formularios",
                lineChart: "Gráfico de líneas",
                barChart: "Gráfico de barras",
                pieChart: "Gráfico circular",
              },
              index: {
                successfullyAdded: "Agregar con éxito",
                viewSQL: "Ver sql",
                export: "Exportar",
                addToReport: "Agregar al informe",
              },
            },
            refer: {
              hooks: {
                data: "Datos",
                interrogativeSentence: "Preguntas",
                historicalQuery: "Consulta histórica",
              },
              index: { copySuccessful: "Copia exitosa", copy: "Copiar" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "No a la altura",
                        meetingStandards: "Cumplimiento",
                        excellent: "Excelente",
                        notStartedYet: "Sin empezar",
                        inProgress: "En progreso",
                        completed: "Completado",
                        incomplete: "Sin terminar",
                      },
                    },
                  },
                  const: {
                    taskName: "Nombre de la tarea",
                    department: "Departamento",
                    studentName: "Nombre del estudiante",
                    scoringResults: "Resultados de puntuación",
                    taskCompletionStatus: "Estado de finalización de la tarea",
                  },
                  index: { pleaseSelectADimension: "Seleccione la dimensión" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Ingrese @ para abrir el cuadro emergente para la selección de dimensiones",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Ingrese la declaración de consulta para realizar la consulta directamente",
                query: "Consulta",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "La exportación es exitosa, vaya al centro de tareas para ver",
            dataQueryInProgress: "Consulta de datos...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Búsqueda de palabras clave",
                previous: "Anterior",
                next: "Siguiente",
                theObjectThatTriggersTheScrollingEventIs:
                  "El objeto que desencadena el evento de desplazamiento es",
                userScrolling: "Desplazamiento del usuario",
              },
            },
            summary: {
              index: {
                reminder: "Mensaje:",
                edit: "Edición",
                name: "Nombre",
                hintLanguage: "Mensaje",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Extracción de opinión" },
            },
            outline: {
              index: {
                numberOfKeywords: "Número de palabras clave",
                speaker: "El hablante",
                speechDuration: "Duración de la conversación",
                proportionOfSpeaking: "La proporción de habla",
                maximumSpeechDuration: "Duración máxima del discurso",
                numberOfQuestions: "Número de preguntas",
                averageSpeechSpeed: "Velocidad media del habla",
                conversationalActions: "Acción de sesión",
                contentSummary: "Resumen de contenido",
                generateSummary: "Generar resumen",
                keywordCloud: "Nube de palabras clave",
              },
            },
          },
          index: {
            outline: "Resumen",
            viewpoint: "Opinión",
            contentSummary: "Resumen de contenido",
            viewpointExtraction: "Extracción de opinión",
            summaryOfKeyPoints: "Resumen de los puntos principales",
            videoAnalysis: "Análisis de vídeo",
          },
        },
        list: {
          index: {
            delete: "Eliminar",
            uploadSuccessful: "Subir correctamente",
            videoList: "Lista de videos",
            uploadVideo: "Subir un video",
            videoName: "Nombre del video",
            uploadTime: "Tiempo de carga",
            endTime: "Tiempo de finalización",
            analyzeProgress: "Analizar el progreso",
            haveInHand: "En progreso",
            completed: "Completado",
            fail: "Fracaso",
            toBegin: "Para empezar",
            operation: "Operación",
            see: "Ver",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Añadir tiempo de trabajo" },
        },
      },
      index: {
        businessWorkingHours: "Horas de trabajo de negocios",
        newlyBuild: "Nuevo",
        name: "Nombre",
        describe: "Descripción",
        operation: "Operación",
        edit: "Edición",
        delete: "Eliminar",
        editBusinessWorkingHours: "Editar horas de trabajo de negocios",
        newBusinessWorkingHours: "Horas de trabajo para un nuevo negocio",
        workingHours: "Tiempo de trabajo",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Extracción inteligente",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "¿Confirma que desea cancelar? No se recuperará después de la cancelación",
        },
      },
    },
    accountManagement: {
      securitySetting: "Configuración de seguridad",
      accountManagement: "Gestión de cuentas",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Para",
            switchToIPAddressMode: "Cambiar al modo de dirección IP",
            switchToIPSegmentMode: "Cambiar al modo de segmento IP",
          },
        },
      },
      index: {
        saveSuccessful: "Ahorre con éxito",
        pleaseImproveTheConfiguration: "Mejore la configuración",
        securitySetting: "Configuración de seguridad",
        employeeLoginIPRestrictions:
          "Restricciones de IP de inicio de sesión de empleados",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Limite el rango de IP en el que los empleados pueden iniciar sesión y no se permite iniciar sesión más allá del rango. Solo en el sitio, no hay restricciones para las aplicaciones móviles y no hay restricciones para los administradores.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Al agregar o modificar, complete el segmento IP de la red externa (el mismo segmento C) o una dirección IP de red externa fija.",
        allowLoginIPRange: "Rango de IP que permite el inicio de sesión",
        preserve: "Guardar",
      },
    },
    coachAdminEntry: { speechRecognition: "Reconocimiento de voz" },
    billingCenter: {
      template: {
        details: "Detalles",
        languageType: "Tipo de idioma",
        aSREngine: "Motor ASR",
        voiceQualityQuota: "Cuota de inspección de calidad de voz",
        realtimeData: "Datos en tiempo real",
        calculatedByDay: "Por día",
      },
      component: {
        aSRName: "Nombre de ASR",
        language: "Idioma",
        remainingQuota: "Cuota restante",
        hours: "Horas",
        usedQuota: "Cuota utilizada",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "¡La operación se guardó correctamente!",
        deletedSuccessfully: "¡La eliminación se realizó correctamente!",
        pleaseSelectLanguage: "Seleccione el idioma",
        pleaseConfigureRules: "Configure las reglas",
        updateSuccessful: "¡Actualización exitosa!",
        operationSuccessful: "¡Operación exitosa!",
      },
      template: {
        aSRLanguage: "Lenguaje ASR",
        pleaseSelect: "Por favor elija",
        model: "Modelo",
        pleaseSelectLanguage: "Seleccione el idioma",
        delete: "Eliminar",
        newASRModel: "Nuevo modelo ASR",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Análisis de productos competitivos" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Filtro de comentarios de comercio electrónico",
        allComments: "Todos los comentarios",
        ecommerceReviewManagement: "Gestión de reseñas de comercio electrónico",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Filtro de comentarios de medios sociales",
        allComments: "Todos los comentarios",
        socialMediaReviewManagement:
          "Gestión de comentarios en medios sociales",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Análisis de productos competitivos",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Nombre",
        nickname: "Apodo",
        name: "Nombre",
        email: "Cuenta (correo electrónico)",
        mobile: "Teléfono",
        agentId: "Número de trabajo",
        position: "Puesto",
        role: "Rol",
        actions: "Operación",
        createUser: "Nuevos empleados",
        editUser: "Personal de edición",
        account: "Cuenta",
        originPassword: "Contraseña inicial",
        newPassword: "Nueva contraseña",
        resumePassword: "Confirmar la contraseña",
        userGroup: "Grupo de empleados",
        authorizationGroup: "Grupos de permisos",
        resetPassword: "Restablecer contraseña",
      },
      userGroups: {
        id: "ID",
        name: "Nombre",
        userCount: "Número de empleados",
        actions: "Operación",
        createUserGroup: "Agregar grupo de empleados",
        editUserGroup: "Editar grupos de empleados",
        member: "Miembros",
      },
      role: {
        id: "ID",
        name: "Nombre",
        actions: "Operación",
        all: "Todo",
        taskName: "Tarea",
        readTask: "Vista de tareas",
        manageTemplate: "Gestión de plantillas",
        manualInspection: "Reinspección manual",
        readResultAll: "Vista de resultados",
        readResultPersonal: "Vista de resultados (individual)",
        readProcess: "Vista del proceso de inspección de calidad",
        readReport: "Estadísticas e informes",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Fecha",
        taskName: "Nombre",
        size: "Tamaño",
        status: "Estado",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Nombre",
            remark: "Descripción",
            actions: "Operación",
          },
          checkPoint: {
            name: "Nombre",
            remark: "Descripción",
            type: "Tipo",
            gradeType: "Método de evaluación",
            conditionLogic: "Reglas",
            predeterminedScore: "Pestos",
            weight: "Pesos",
            status: "Estado",
            actions: "Operación",
            switchOn: "Habilitar",
            switchOff: "Desactivación",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Tiempo",
            customer: "Cliente",
            customerPhone: "Teléfono del cliente",
            callType: "Tipo de llamada",
            source: "Fuente",
            voiceRecording: "Grabación de llamadas",
            graded: "Calificación",
            openingGreeting: "Saludos de apertura",
          },
          checkPointList: {
            name: "Punto de inspección de calidad",
            type: "Tipo",
            deductScore: "Puntuación",
            isHit: "Situación de golpe",
            status: "Revisión",
            finalDeductScore: "Confirmar la deducción de puntos",
            hit: "Golpe",
            notHit: "Sin hits",
          },
        },
      },
    },
    custom: { keywords: "Palabras clave de conversación" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Configuración del sistema" },
      systemIntegration: {
        text: "Integración de sistemas",
        subTitle: {
          udesk: "Sistema de servicio al cliente de Udesk",
          udeskWhite: "Sistema de atención al cliente",
          wechat: "WeChat empresarial",
          taobao: "Taobao mil vacas",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Gestión de campos de inspección de calidad" },
      customerField: { text: "Campo de cliente" },
      speechRecognition: { text: "Reconocimiento de voz" },
      user: { text: "Gestión de empleados" },
      userGroups: { text: "Gestión de grupos de empleados" },
      role: { text: "Gestión de roles" },
      dataMigration: { text: "Migración de datos" },
      taskCenter: { text: "Centro de tareas" },
      recordUpload: { text: "Carga de grabación local" },
      anomalousData: { text: "Datos de excepción" },
      systemLog: { text: "Registro de mensajes" },
      msgCenter: { text: "Centro de mensajes" },
      wechatDocking: { text: "Micro acoplamiento empresarial" },
    },
    tasks: {
      homePage: { text: "Inicio" },
      workbench: { text: "Banco de trabajo" },
      semanticIntelligence: { text: "Inteligencia semántica" },
      intelligentAnalysis: { text: "Análisis inteligente" },
      intelligentLabel: { text: "Etiquetas inteligentes" },
      admin: { text: "Gestión" },
      callManage: { text: "Gestión de llamadas" },
      dialogueManage: { text: "Gestión de la conversación" },
      ticketManage: { text: "Gestión de órdenes de trabajo" },
      dataAnalysis: { text: "Análisis de datos" },
      customerService: { text: "Centro de atención al cliente" },
      clientCenter: { text: "Centro de atención al cliente" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Centro de formación" },
      templates: { text: "Gestión de plantillas" },
      wechatQaTasks: { text: "Banco de trabajo empresarial WeChat" },
      wechatQaSetting: {
        text: "Configuración de análisis de WeChat empresarial",
      },
      wechatQaChartRecord: { text: "Conversación empresarial de WeChat" },
      wechatStaffCenter: { text: "Centro de empleados de Enterprise Micro" },
      callAnalysis: { text: "Análisis de sesión" },
      caseBaseFolder: { text: "Biblioteca de casos" },
      coachTask: { text: "Tareas de tutoría" },
      teamAnalysis: { text: "Análisis del equipo" },
      customer: { text: "Centro de atención al cliente" },
      analysisEnable: { text: "Potenciación analítica" },
      analysisHelp: { text: "Análisis de ayuda" },
      analysisStudy: { text: "Análisis de aprendizaje" },
      analysisReview: { text: "Análisis de revisión" },
      analysisTags: { text: "Análisis inteligente de etiquetas" },
      analysisSentiment: { text: "Análisis de la opinión pública" },
      analysisBusiness: { text: "Análisis de negocios" },
      groupTest: { text: "Prueba de grupo" },
    },
    navBar: {
      billingCenter: "Centro de ayuda",
      uploadDownload: "Centro de carga y descarga",
      setting: "Configuración del sistema",
    },
  },
  fix: {
    selectType: "Tipo de selección:",
    all: "Todo",
    call: "Llamada",
    text: "Diálogo",
    wechat: "Conversación empresarial de WeChat",
    businessTrendAnalysis:
      "Análisis de tendencias comerciales de clasificación de primer nivel",
    noEchart: "No hay datos en el gráfico",
    checkPointisRequired: "La configuración de la regla es obligatoria",
    voiceDownLoad: "Descargar la grabación",
    exportTxt: "Exportar texto TXT",
    exportWord: "Exportar texto de palabra",
    sureReRole: "¿Confirmar el papel del hablante inverso?",
    sureRecover: "¿Confirmar para volver a corregir el error?",
    sureReAsr: "¿Confirmar la reidentificación?",
    reRole: "Hablador inverso",
    recover: "Corrección de nuevo",
    reAsr: "Reidentificación",
    recordingOperation: "Operación de grabación",
    onlySave30Day: "(Reservado solo por 30 días)",
    appealError: "Apele al punto de control de calidad antes de enviarlo",
    exportToTaskCenter: "Exportado correctamente al centro de carga y descarga",
    caseBaseLimit: "La biblioteca de casos no puede exceder los 50",
    unnamed: "Sin nombre",
    wordSpeed: "Palabras/puntos",
    hit: "Golpe",
    checkPoint: "Punto de inspección de calidad",
    realTimeAdditionTip1:
      "Adición en tiempo real: la inspección de calidad comienza inmediatamente cuando los datos ingresan al sistema de inspección de calidad",
    realTimeAdditionTip2:
      "Adición programada: agregue datos automáticamente para la inspección de calidad",
    null: "Ninguno",
    collcetedProps: "Campo de adquisición",
    editingNameNow: "Hay un nombre que se está editando",
    upToFourLevels: "Clasificado hasta cuatro niveles",
    default: "Por defecto",
    nameCantBeNull: "El nombre no puede estar vacío",
    editingNow: "Hay una operación editando",
    nameError: "Nombre incorrecto",
    nameMaxLength8: "La longitud máxima del nombre es 8",
    callTime: "Tiempo de conversación",
    sentimentValue: "Valor de sentimiento del cliente",
    agentSentimentValue: "Valor emocional de servicio al cliente",
    sentimentAnalysis: "Análisis de emociones",
    associatedData: "Datos de seguimiento",
    notifyTheOwner: "Notifique al propietario del grupo",
    notifyTheEmployee: "Notificar a los propios empleados",
    notifyTheOwnerTip:
      "El propietario del grupo será notificado cuando el tipo de conversación sea un chat grupal",
    customNotifyEmployee: "Personalizar la notificación a los empleados",
    customNotifyEmployeeTip:
      "Si la persona activada es un empleado, notifique al empleado que pertenece al mensaje",
    clickToLearnMore: "Haga clic para saber más",
    repeatMarkingTip:
      "Este registro ha sido marcado por el punto de inspección de calidad, no es necesario repetirlo",
    successfulEvaluation: "Evaluar el éxito",
    evaluationFailed: "Evaluación fallida",
    qualityInspectionScore: "Calificación de inspección de calidad",
    semanticTags: "Etiquetas semánticas",
    smartTags: "Etiquetas inteligentes",
    collect: "Recopilación de información",
    appealRecord: "Registro de quejas",
    satisfy: "Satisfacción",
    notSatisfy: "No satisfecho",
    appealPlaceHolder: "Ingrese el motivo de la apelación",
    giveUp: "Renunciar",
    rejectReasonPlaceHolder: "Ingrese el motivo del rechazo",
    reject: "Rechazo",
    changeRejectReason: "Modificar el motivo del rechazo",
    pass: "Por",
    revise: "Modificación",
    machineEvaluation: "Evaluación de la máquina",
    poor: "Pobre",
    excellent: "Bien.",
    moderate: "General",
    mustMarked: "El punto de inspección de calidad debe estar marcado",
    pleaseChoose: "Por favor elija",
    manualEvaluation: "Evaluación manual",
    qualityInspectionTemplate: "Plantilla de inspección de calidad aplicable",
    collectionTemplate: "Plantilla de colección aplicable",
    keywordsWordCloud: "Nube de palabras clave",
    editCategoryLabel: "Editar etiquetas de categoría",
    justCall: "Solo mira la llamada",
    justConversation: "Solo mira el diálogo",
    batchOperation: "Operación por lotes",
    customerServicesGroup: "Grupo de servicio al cliente",
    joinGroup: "Unirse al grupo",
    removeGroup: "Fuera del grupo",
    enterInformationEntity: "Ingrese el contenido de la entidad de información",
    enterNotBeNull: "La entrada no puede estar vacía",
    operationGroup: "Grupo de operaciones",
    customerServiceSelected: "Servicio al cliente seleccionado",
    callAndConversation: "Llamada/conversación",
    conversation: "Diálogo",
    system: "Sistema",
    customize: "Personalización",
    effective: "Efectivo",
    invalid: "No válido",
    successfulAndToTaskCenter:
      "La ejecución es exitosa, vaya al centro de tareas para ver",
    recalculationFailed: "Error de recálculo",
    selectRecalculatedIndicator:
      "Seleccione el indicador que desea volver a calcular",
    source: "Seleccione la fuente de datos",
    placeholder: "Por favor ingrese las palabras clave",
    cumulative: "Tipo acumulativo",
    average: "Tipo promedio",
    distribution: "Ley de distribución",
    originalDataCannotEmpty: "Los datos originales no pueden estar vacíos",
    inspection: "Inspección de calidad",
    check: "Inspección aleatoria",
    review: "Revisión",
    draftSavedSuccessfully: "Borradores guardados correctamente",
    optimization: "Optimización",
    agentName: "Nombre de servicio al cliente",
    allTasks: "Todas las tareas de inspección de calidad",
    datePickerSelect: "Seleccionar fecha",
    callSelect: "Elegir una conversación",
    taskSelect: "Selección de tareas",
    genReport: "Generar informes",
    dataSource: "Fuentes de datos",
    callsTotal: "Total de llamadas",
    intelligentQualityInspection: "Inspección de calidad inteligente",
    percentage: "Razón",
    artificialSampling: "Inspección manual",
    appealNumber: "Cantidad de quejas",
    totalConversation: "Total de diálogo",
    samplingReviewRate: "Tasa de reinspección",
    data: "Datos",
    inspectionResults: "Resultados de inspección de calidad",
    inspected: "Inspección de calidad",
    totalScore: "Puntuación total",
    learnMore: "Ver más",
    inspectionEffect: "Efecto de inspección de calidad",
    allTotal: "Total",
    manualInspection: "Reinspección manual",
    artificialReview: "Revisión manual",
    approvalAmountAndRate: "Volumen aprobado y tasa de aprobación",
    customerScore: "Puntuación de servicio al cliente",
    inspectionRecommendations: "Recomendaciones de inspección de calidad",
    saveDraft: "Guardar el borrador",
    historicalAdvice: "Propuesta histórica",
    advicePlaceHolder: "Por favor ingrese una sugerencia...",
    upload: "Carga de archivos",
    customAdd: "Agregar filtro",
    advancedConditions: "Condiciones avanzadas",
    keyWord: "Palabras clave",
    containsAll: "Contiene todo",
    containsAny: "Contiene cualquier",
    matchAny: "Coincidir con cualquier",
    matchAll: "Coincidir con todos",
    smartTagsOne: "Etiqueta inteligente uno",
    smartTagsTwo: "Etiqueta inteligente dos",
    season: "Temporada",
    month: "Mes",
    week: "Semana",
    day: "Día",
    hour: "Cuando",
    notMoreThan366: "El tiempo no puede exceder los 366 días",
    propsCannotBeNull: "Hay campos sin completar",
    other: "Otros",
    correlationCoefficient: "Coeficientes de correlación",
    repetitionRate: "Tasa de repetición",
    configurationItemsMoreThan20: "Agregue hasta 20 elementos de configuración",
    conversionAnalysis: "Análisis de conversión",
    correlationAnalysis: "Análisis de correlación",
    successfulExportAndToTaskCenter:
      "La exportación es exitosa, vaya al centro de tareas para ver",
    typeSelect: "Tipo de selección",
    exportExcel: "Exportar excel",
    exportPng: "Exportar png",
    noDataChart: "No hay datos en el gráfico",
    placeOperator: "Seleccione la etiqueta inteligente",
    placeAdvancedConditions: "Seleccione las condiciones avanzadas",
    createAdvancedConditions: "Nuevo filtrado avanzado",
    moveUp: "Mover hacia arriba",
    moveDown: "Mover hacia abajo",
    moveLeft: "Mover a la izquierda",
    moveRight: "Mover a la derecha",
    statusChangeSuccess: "Modificación de estado exitosa",
    editSuccess: "Modificación exitosa",
    saveSuccess: "Ahorre con éxito",
    firstPage: "Inicio",
    lastPage: "Última página",
    customerInfo: "Información del cliente",
    type: "Tipo",
    NumberTypeErrorMessage:
      "El contenido de información de campo de tipo numérico solo puede contener números o “.”",
    taskStartPrompt: "Mensaje de apertura de tarea",
    startConfirm:
      "Haga clic en el botón de confirmación para iniciar la tarea de inspección de calidad",
    continueToSubmitWithoutEvaluation:
      "Continuación de la presentación sin evaluación",
    rejectTip:
      "No se ha detectado ninguna operación de cambio, ¿confirma que todas las apelaciones se rechazan directamente?",
    unmarkedSelfLearningTip:
      "Tiene un punto de inspección de calidad de autoaprendizaje sin marcar",
    markedSuccessfully: "Anotación exitosa",
    markingFailed: "Anotación fallida",
    exceedingLength: "Más allá de la longitud",
    uncategorized: "Sin categoría",
    addSuccess: "Nuevo éxito",
    deleteSuccess: "La eliminación se realizó correctamente",
    featureName: "Nombre del elemento",
    informationEntity: "Entidades de información",
    standardAnswer: "Respuesta estándar",
    keyElementAnswer: "Respuesta de elementos clave",
    generalStatement: "Declaración general (no se requiere consulta)",
    generalQuestionsAndConfirmation: "Preguntas y confirmaciones generales",
    specialQuestionsAndAnswers: "Preguntas y respuestas especiales",
    agent: "Servicio al cliente",
    customer: "Cliente",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Elementos clave Las etiquetas semánticas y las entidades de información no pueden estar vacías al mismo tiempo",
    standardSentence: "Oración estándar",
    acquaintance: "Conocido",
    smartUePlaceholder: "Ingrese el nombre de la interacción inteligente",
    typeSelectPlaceholder: "Por favor seleccione el tipo",
    keyElement: "Elementos clave",
    reQuerySementicTags: "Vuelva a seleccionar las etiquetas semánticas",
    querySementicTags: "Seleccionar etiquetas semánticas",
    QATestForm: "Formulario de prueba de preguntas y respuestas",
    selectNotBeNull: "La selección no puede estar vacía",
    featureDetectionTarget: "Objetivo de detección de elementos",
    categorized: "Clasificación",
    effectiveness: "Eficacia",
    hideAdvancedSettings: "Ocultar la configuración avanzada",
    showAdvancedSettings: "Mostrar configuración avanzada",
    generalStatementOnly: "Declaración general",
    generalQuestions: "Preguntas generales",
    affirmativeExpression: "Expresión afirmativa",
    negativeIntention: "Intención negativa",
    searchSementicTags: "Consultar etiquetas semánticas",
    choiceQuestion: "Oración interrogativa selectiva",
    selectiveAnswer: "Respuesta selectiva",
    sementicTagsPlaceholder: "Ingrese el contenido de la etiqueta semántica",
    openSpecialQuestion: "Preguntas especiales abiertas",
    openAnswer: "Respuesta abierta",
    customQA: "Preguntas y respuestas personalizadas",
    questions: "Método de preguntas",
    dataPlaceholder: "Por favor ingrese el contenido",
    reply: "Respuesta",
    notFindSuitableLabel: "No se encontró la etiqueta correcta",
    addSemanticTag: "Añadir una nueva etiqueta semántica",
    intelligentInteraction: "Interacción inteligente",
    matching: "Coincidencia",
    bout: "VEsegundo",
    keywordMatch: "Coincidencia de palabras clave",
    addFilterConditions: "Agregar condiciones de filtro",
    buttonStartCollectionTask:
      "Haga clic en el botón Aceptar para abrir la tarea de recopilación",
    buttonStartTask:
      "Haga clic en el botón Aceptar para iniciar la tarea de inspección de calidad",
    ruleConfigurationIsRequired: "La configuración de la regla es obligatoria",
    asrRunning: "Ejecución de ASR",
    errorCorrectionExecution: "Ejecución de corrección de errores",
    emotionalVolumeRecognition: "Reconocimiento de volumen de estado de ánimo",
    dataPreprocessing: "Preprocesamiento de datos",
    inspectionExecution: "Implementación de inspección de calidad",
    businessId: "Id de negocios",
    businessTime: "Tiempo de negocios",
    createTime: "Hora de creación",
    abnormalReason: "Causas de excepción",
    singleChat: "Chat único",
    groupChat: "Chat grupal",
    taskContent: "Contenido de la tarea",
    submissionTime: "Tiempo de presentación",
    startTime: "Hora de inicio",
    endTime: "Tiempo de finalización",
    runningTime: "Tiempo de ejecución",
    totalAmountOfData: "Volumen total de datos",
    resultsOfThe: "Resultados de la ejecución",
    operator: "Operador",
    taskDetails: "Detalles de la tarea",
    executiveFunction: "Funciones de ejecución",
    qualityCheckTasks: "Tareas de inspección de calidad",
    implementationDetails: "Detalles de ejecución",
    conditionalRules: "Reglas de condición",
    smartCondition: "Condiciones inteligentes",
    goThis: "Mover a este",
    pleaseMarkClassification: "Marque la clasificación",
    advancedScreening: "Filtración avanzada",
    noDataToManipulate: "No hay datos que puedan ser manipulados",
    concept: "Palabras conceptuales",
    checkPoint1: "Configuración de reglas",
    requiredFieldsCannotEmpty:
      "Los elementos obligatorios no pueden estar vacíos",
    rule: "Reglas",
    afterFixUnit: "Palabras",
    unmarkedSelfLearningPointsTip:
      "Tiene un punto de inspección de calidad de autoaprendizaje sin marcar",
    onlyReservedFor7Days: "(Reservado solo por 7 días)",
    downloadError: "Error de descarga",
    pause: "Suspensión",
    continue: "Continuar",
    recalcScore:
      "Vuelva a calcular solo las puntuaciones (incluidas las calificaciones)",
    recalculatePointsAndScores:
      "Vuelva a calcular los puntos y puntajes de inspección de calidad",
    recalculatePointsAndScoresWithEmtry:
      "Vuelva a calcular los puntos y puntajes de inspección de calidad (los puntos de inspección de calidad están vacíos)",
    inProgress: "En progreso",
    toBeExecuted: "Para ser ejecutado",
    executeImmediately: "Ejecución inmediata",
    specialElements: "Elementos especiales",
    logicRule: "Reglas lógicas",
    every: "Múltiples coincidencias",
    hitRecall: "Golpear palabras de recuerdo",
    notHitRecall: "Recall fallando",
    hitRecallRegular: "Golpea el retiro regular",
    notHitRecallRegular: "Mal golpe",
    hitRule: "Reglas de golpe",
    notHitRule: "Reglas de fracaso",
    hitSimilarSentence: "Golpear oraciones similares",
    notHitSimilarSentence: "Frase similar fallida",
    hitExcludeSimilarSentences: "Golpe para excluir oraciones similares",
    notHitExcludeSimilarSentences:
      "Frase fallida para excluir oraciones similares",
    updateSuccess: "Actualización exitosa",
    qualify: "Recreación",
    recallKeyWord: "Palabras clave de recuperación",
    grammar: "Reglas de gramática",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "El cambio de validez falló, inténtelo de nuevo",
    postAutoMateTasksCaseByTaskId:
      "Error al guardar la configuración de sincronización de la biblioteca de casos",
    getAutoMateTasksCaseByTaskId:
      "Error de adquisición de la configuración de sincronización de la biblioteca de casos",
    postInspectionRemarkModelConfigs:
      "No se pudo guardar la plantilla de comentarios",
    putInspectionRemarkModelConfigsById:
      "No se pudo guardar la plantilla de comentarios",
    getInspectionRemarkModelConfigsById:
      "Obtiene los datos de la plantilla de notas fallidas",
    postKnowledgeDatas:
      "No se pudo crear la base de conocimientos, vuelva a intentarlo",
    putKnowledgeDatasById:
      "La modificación de la base de conocimientos falló, intente nuevamente",
    getLibraryFiles: "No se pudo obtener la lista de carpetas de precios",
    getLibraryCalls: "No se pudo obtener el archivo",
    getLibraryFilesSearch: "Error de búsqueda",
    putLibraryFilesMoveById: "Pregunte si el clip de precio no se mueve",
    putLibraryCallsById: "¡El cambio de nombre de archivo falló!",
    postLibraryFiles: "No se pudo agregar una carpeta",
    putLibraryFilesById: "Error de cambio de nombre de carpeta",
    deleteLibraryFilesById: "Error de eliminación",
    deleteLibraryCallsById: "Error de eliminación",
    postLibraryCalls: "Adición fallida",
    getVoiceUploadResultById: "No se pudo exportar el resultado de carga",
  },
};
