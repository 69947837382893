import NotImplementedError from './not-implemented-error';
import ArgumentNullError from './argument-null-error';
import InvalidArgumentError from './invalid-argument-error';
import WrongTypeError from './wrong-type-error';

export default {
    NotImplementedError,
    ArgumentNullError,
    InvalidArgumentError,
    WrongTypeError
};

export {
    NotImplementedError,
    ArgumentNullError,
    InvalidArgumentError,
    WrongTypeError
};