import React from 'react';
// import Udesk from 'Udesk';
import BusinessRecordsViewComponent from './component';
import './style.scss';

export default class BusinessRecordsViewTemplate extends BusinessRecordsViewComponent {
    render() {
        let {
            noteInfoExtension
        } = this.props;
        let {
            locales
        } = this;
        return (
            <div className="business-records-list-container">
                <ul className="business-records-list">
                    <If condition={noteInfoExtension.fieldInfo}>
                        {Object.keys(noteInfoExtension.fieldInfo).map(key => (
                            <li key={key} className="business-records-list-item">
                                <span className="business-records-list-item-title" title={noteInfoExtension.fieldInfo[key]}>
                                    {noteInfoExtension.fieldInfo[key]}
                                </span>
                                <span className="business-records-list-item-content">
                                    <If condition={noteInfoExtension.noteInfo && noteInfoExtension.noteInfo[key]}>
                                        <Choose>
                                            <When condition={key==="note_custom_fields"}>
                                                <For each="customField" index="index" of={noteInfoExtension.noteInfo[key]}>
                                                    <span class="business-records-custom-field">
                                                        <span class="business-records-custom-field-name">
                                                            {customField.name}
                                                            {locales.labels.colon}
                                                        </span>
                                                        <span class="business-records-custom-field-value">
                                                            {customField.value}
                                                        </span>
                                                    </span>
                                                </For>
                                            </When>
                                            <Otherwise>
                                                {noteInfoExtension.noteInfo[key]}
                                            </Otherwise>
                                        </Choose>
                                    </If>
                                </span>
                            </li>
                        ))}
                    </If>
                </ul>
            </div>
        );
    }
}