import React from 'react';
//import echarts from '../../vendor/echarts/index';
import echarts from 'echarts';
import 'echarts-wordcloud';
import 'echarts-liquidfill';
import echartsMapLoaderService from '../../services/echarts-map-loader';
import OptionsBuilderFactoryClass from './react-echarts/options-builder-factory';
import Locales from '../../udesk/locales';
import Udesk from '../../udesk/index';

//主题
import themeJson from '../../vendor/echarts/theme-udesk.json';
echarts.registerTheme('theme-udesk', themeJson);

window.__UDESK_REACT_ECHARTS_MAP_LOADED_CALLBACK__ = function (mapName, mapJson) {
    echarts.registerMap(mapName, mapJson);
};

let _optionsBuilderFactory = new OptionsBuilderFactoryClass();

class ReactEcharts extends React.Component {
    //后面使用此处，兼容IE 处理
    export(title) {
        if (this.isDestroyed) {
            return;
        }

        let type = 'png';
        let echartsAPI = this.privates.echartsAPI;
        let url = echartsAPI.getConnectedDataURL({
            type: type,
            pixelRatio: 2,
            backgroundColor: '#fff',
        });

        //IE
        if (window.navigator.msSaveOrOpenBlob) {
            let dataURLtoBlob = function dataURLtoBlob(dataurl) {
                let arr = dataurl.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            };

            window.navigator.msSaveOrOpenBlob(dataURLtoBlob(url), title + '.' + type);
        } else {
            let $a = document.createElement('a');
            //非IE
            $a.target = '_bank';
            $a.href = url;
            $a.download = title;
            $a.click();
        }
    }

    privates = {
        echartsAPI: null,
        currentoptions: {},
        reactEcharsContainer: null,
        isLoaded: true,
    };

    resize = (options) => {
        if (this.privates.echartsAPI) {
            this.privates.echartsAPI.resize(options);
        }
    };

    static computes = {
        isLoading: [
            'props.type',
            'privates.isLoaded',
            function ({ props, state, privates, locales }) {
                let { type } = props;
                let { isLoaded } = privates;

                let chartTypeEnum = null;
                if (type instanceof Udesk.enums.EnumItemClass) {
                    chartTypeEnum = type;
                } else {
                    let possibleEnum = Udesk.enums.echartTypes.get(type);
                    if (possibleEnum != null) {
                        chartTypeEnum = possibleEnum;
                    }
                }
                if (chartTypeEnum == null) {
                    throw new Error(`\`chartType\` must be a valid value of enums \`enums.echartTypes\``);
                }
                // return chartTypeEnum.id === Udesk.enums.echartTypes.map.id;
                let isLoading = false;
                let needLoadAnimation = false;

                if (chartTypeEnum.id === Udesk.enums.echartTypes.map.id) {
                    needLoadAnimation = true;
                    isLoading = isLoaded || echartsMapLoaderService.getFieldLoadStatus();
                } else {
                    needLoadAnimation = false;
                }

                return {
                    isLoading,
                    needLoadAnimation,
                };
            },
        ],
    };

    actions = {
        loadMapCallback() {
            if (!this.isDestroyed) {
                let oldLoadedStatus = this.privates.isLoaded;
                if (!oldLoadedStatus) {
                    this.privates.isLoaded = true;
                    this.actions.update();
                }
                this.actions.directDraw();
            }
        },
        loadWordCloudCallback() {
            if (!this.isDestroyed) {
                this.actions.directDraw();
            }
        },
        click(params) {
            let { type, records, seriesColumns, onSeriesClicked } = this.props;
            /*eslint-disable */
            switch (params.componentType) {
                case 'series': {
                    let args = {};
                    let index = params.seriesIndex;
                    args = {
                        chartType: type,
                        column: seriesColumns[index],
                        record: records[params.dataIndex],
                        value: params.value,
                        name: params.name,
                    };
                    this.trigger('onSeriesClicked', args);

                    if (onSeriesClicked != null && typeof onSeriesClicked === 'function') {
                        onSeriesClicked(args);
                    }
                    break;
                }
            }
            /*eslint-enable */
        },
        draw() {
            let type = this.props.type;
            let oldLoadedStatus = this.privates.isLoaded;
            let chartTypeEnum = null;
            if (type instanceof Udesk.enums.EnumItemClass) {
                chartTypeEnum = type;
            } else {
                let possibleEnum = Udesk.enums.echartTypes.get(type);
                if (possibleEnum != null) {
                    chartTypeEnum = possibleEnum;
                }
            }
            if (chartTypeEnum == null) {
                throw new Error(`\`chartType\` must be a valid value of enums \`enums.echartTypes\``);
            }

            if (chartTypeEnum.id === Udesk.enums.echartTypes.map.id) {
                let mapUrl = this.actions.getMapLoadUrl();
                if (mapUrl) {
                    if (this.isDestroyed) {
                        return;
                    }
                    echartsMapLoaderService.setLoadUrl(mapUrl);
                    let loadedStatus = echartsMapLoaderService.getFieldLoadStatus();
                    if (oldLoadedStatus !== loadedStatus) {
                        this.privates.isLoaded = loadedStatus;
                        this.actions.update();
                    }
                    echartsMapLoaderService.loadAsync(this.actions.loadMapCallback);
                }
            } else {
                this.actions.directDraw();
            }
        },
        directDraw() {
            if (this.isDestroyed) {
                return;
            }
            let { theme, type } = this.props;
            let element = this.privates.reactEcharsContainer;
            if (element) {
                let { width, height } = this.props;
                $(element).css({
                    width: width,
                    height: height,
                });

                if (this.privates.echartsAPI) {
                    this.privates.echartsAPI.dispose();
                }

                this.privates.echartsAPI = echarts.init(element, theme);

                let builder = _optionsBuilderFactory.getBuilder(type);
                let notMerge = builder.notMergeOption();
                let lazyUpdate = builder.lazyUpdate();
                let options = getFullChartOptions(this);
                this.privates.echartsAPI.setOption(options, notMerge, lazyUpdate);
                this.privates.currentoptions = options;

                this.privates.echartsAPI.on('click', (params) => {
                    this.actions.click(params);
                });
            }
        },
        getMapLoadUrl() {
            let { mapName, mapUrls } = this.props;
            if (typeof mapUrls !== 'object') {
                throw new Error(`mapUrls must be object.`);
            }

            let mapUrl = null;
            if (mapUrls && mapName) {
                if (Object.prototype.hasOwnProperty.call(mapUrls, mapName)) {
                    let targetMapUrl = mapUrls[mapName];
                    if (targetMapUrl && typeof targetMapUrl === 'string') {
                        mapUrl = targetMapUrl;
                    } else if (
                        typeof targetMapUrl === 'object' &&
                        Object.prototype.hasOwnProperty.call(targetMapUrl, 'url')
                    ) {
                        mapUrl = targetMapUrl.url;
                    }
                } else {
                    let targetMapUrl = Object.values(mapUrls).find((item) => {
                        if (typeof item === 'object') {
                            if (item.aliasNames && item.aliasNames.length > 0) {
                                return item.aliasNames.includes(mapName);
                            }
                        } else {
                            return false;
                        }
                    });
                    if (targetMapUrl && targetMapUrl.url) {
                        mapUrl = targetMapUrl.url;
                    }
                }
            } else {
                throw new Error(`mapName is not valid.`);
            }
            return mapUrl;
        },
    };

    componentDidMount() {
        this.actions.draw();
    }
    componentDidUpdate(prevProps, prevState) {
        let { type, theme, mapName } = this.props;

        let { type: oldType, theme: oldTheme, mapName: oldMapName } = prevProps;

        if (this.privates.echartsAPI != null) {
            if (type !== oldType || theme !== oldTheme || mapName !== oldMapName) {
                this.actions.draw();
            } else {
                let builder = _optionsBuilderFactory.getBuilder(type);
                // if (isClearOptions && this.privates.echartsAPI) {
                // }
                this.privates.echartsAPI.clear();

                let options = getFullChartOptions(this);
                let notMerge = builder.notMergeOption();
                let lazyUpdate = builder.lazyUpdate();

                this.privates.echartsAPI.setOption(options, notMerge, lazyUpdate);
                this.privates.currentoptions = options;
            }
        } else if (this.privates.reactEcharsContainer != null) {
            this.actions.draw();
        }
    }

    componentWillUnmount() {
        if (this.privates.echartsAPI != null) {
            this.privates.echartsAPI.off('click');
            if (typeof this.privates.echartsAPI.dispose === 'function') {
                this.privates.echartsAPI.dispose();
            }
            this.privates.echartsAPI = null;
        }

        this.privates.currentoptions = null;
        let type = this.props.type;
        let chartTypeEnum = null;
        if (type instanceof Udesk.enums.EnumItemClass) {
            chartTypeEnum = type;
        } else {
            let possibleEnum = Udesk.enums.echartTypes.get(type);
            if (possibleEnum != null) {
                chartTypeEnum = possibleEnum;
            }
        }
        if (chartTypeEnum == null) {
            throw new Error(`\`chartType\` must be a valid value of enums \`enums.echartTypes\``);
        }

        if (chartTypeEnum.id === Udesk.enums.echartTypes.map.id) {
            echartsMapLoaderService.removeCallback(this.actions.loadMapCallback);
        }
    }

    render() {
        let { languageCode } = this.props;
        let {
            privates: { computes },
        } = this;
        return (
            <div className='react-echarts'>
                <If condition={computes.isLoading.needLoadAnimation && !computes.isLoading.isLoading}>
                    <div className='map-loading'>{Locales.get('components.reactEcharts.mapLoading', languageCode)}</div>
                </If>
                <div
                    ref={(reactEcharsContainer) => (this.privates.reactEcharsContainer = reactEcharsContainer)}
                    className='react-echarts-main'
                    style={{ position: 'absolute' }}
                ></div>
            </div>
        );
    }
}

function convertChartOptions(chartType, context) {
    var builder = _optionsBuilderFactory.getBuilder(chartType);
    let options = {
        title: builder.getTitleOptions(context),
        legend: builder.getLegendOptions(context),
        grid: builder.getGridOptions(context),
        xAxis: builder.getXAxisOptions(context),
        yAxis: builder.getYAxisOptions(context),
        visualMap: builder.getVisualMapOptions(context),
        tooltip: builder.getTooltipOptions(context),
        toolbox: builder.getToolboxOptions(context),
        radar: builder.getRadarOptions(context),
        series: builder.getSeriesOptions(context),
    };
    
    if (context.props.nameMap) {
        options.nameMap = context.props.nameMap;
    }

    //custom color,no use theme
    if (context.props.customColor instanceof Array && context.props.customColor.length > 0) {
        options.color = builder.getColorOptions(context);
    }
    if (context.props.textStyle) {
        options.textStyle = builder.getTextStyleOptions(context);
    }
    if (context.props.enableDataZoom) {
        options.dataZoom = builder.getDataZoom(context);
    }

    return options;
}

function getFullChartOptions(context) {
    let { type, finalizeChartOptions, chartOptions } = context.props;

    let options = $.extend({}, convertChartOptions(type, context));
    if (chartOptions) {
        options = $.extend(true, options, chartOptions);
    }

    if (typeof finalizeChartOptions === 'function') {
        options = finalizeChartOptions(options, context.props);
    }
    return options;
}

ReactEcharts.defaultProps = {
    classNames: ['react-echarts'],

    type: undefined,
    width: undefined,
    height: undefined,
    records: [],
    seriesColumns: [],
    theme: 'theme-udesk',

    //实时报表数据接口
    isRealTime: false,
    recordsCountLimit: null,

    xTitle: null,
    showXAxis: true,
    yTitle: null,
    showYAxis: true,
    xNameLocation: 'end',
    yNameLocation: 'end',
    yAxisOptions: null, //Array,Object
    showLegend: true,
    title: undefined,
    subTitle: undefined,
    titleOptions: null,
    seriesLabelContentMode: 'recordValue',
    enableSeriesLabelFallback: false,
    showSeriesLabels: false,
    seriesLabelPosition: null,
    showExtraPercentInLabels: false,
    reverseBarSeries: false,
    barMaxWidth: 30,
    showTooltips: false,
    legendLimit: undefined,
    isStacked: false,
    gaugeMaxValue: null,
    gaugeAxisPrecision: 0,
    enableDataZoom: false,
    dataZoomMinLimit: 0,
    enableMarkPoint: false,
    markPointOptions: null,
    enableMarkLine: false,
    markLineOptions: null,
    customColor: [],
    textStyle: null,
    autoTruncateXLabelConfig: {
        autoTruncateLabel: false,
        truncateCharacter: '**',
        labelTruncateLength: 11,
        labelTruncateMode: 'middle',
    },
    toolboxConfig: 'right',
    legendType: 'scroll',
    legendTop: 'auto',
    legendLeft: 'auto',
    legendRight: 'auto',
    legendBottom: 'auto',
    legendOrient: 'horizontal',
    tooltipEnterable: false,
    tooltipConfine: false,
    tooltipBackgroundColor: null,
    tooltipPadding: null,
    tooltipBorderColor: null,
    tooltipBorderWidth: null,
    tooltipTextFontSize: null,
    tooltipTextColor: null,
    tooltipTextLineHeight: null,
    tooltipAxisPointerType: null,
    extraCssText: 'max-width:100%;',

    seriesLabelEmphasisOptions: null,
    //grid options
    gridTop: 60,
    gridLeft: 100,
    gridRight: 100,
    gridBottom: 20,
    gridContainLabel: false,
    pieLikeCenter: ['50%', '50%'],
    pieLikeRaduis: [0, '50%'],

    funnelSort: 'descending',
    mapUrls: {},
    mapName: 'china', //用于地图显示类型 chinaCities
    mapSeriesRoam: false,
    languageCode: '',

    chartOptions: undefined,
    formatXLabel: undefined,
    formatYLabel: undefined,
    formatTooltip: undefined,
    formatSeriesLabel: undefined,
    formatSeriesLabelValue: undefined,
    finalizeChartOptions: undefined,
    getSeriesLabelValue: undefined,
    isClearOptions: false, //针对特殊情况，需要清空option使用
    //events
    onSeriesClicked: null,
};

export default Udesk.react.udeskify(ReactEcharts);
