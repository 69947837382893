import React from 'react';
import Udesk from 'Udesk';
import TemplateComponentsListDetailRoute from './route';
import { PageHeader, Space, Button, Switch, Icon, Descriptions, Row, Col, Divider } from 'udesk-ui';
import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import ClassNames from 'classnames';
import Locales from 'UdeskLocales';
const InfoItem = ({ list = [] }) => {
    let fieldList = [
        { type: Udesk.enums.templateTypeList.task.id, name: Locales.current.pages.admin.templateComponent.list.task, list: [] },
        { type: Udesk.enums.templateTypeList.point.id, name: Locales.current.pages.admin.templateComponent.list.point, list: [] },
        { type: Udesk.enums.templateTypeList.tag.id, name: Locales.current.pages.admin.templateComponent.list.tag, list: [] },
        { type: Udesk.enums.templateTypeList.concept.id, name: Locales.current.pages.admin.templateComponent.list.concept, list: [] },
        { type: Udesk.enums.templateTypeList.info.id, name: Locales.current.pages.admin.templateComponent.list.info, list: [] },
        { type: Udesk.enums.templateTypeList.inter.id, name: Locales.current.pages.admin.templateComponent.list.inter, list: [] },
        { type: Udesk.enums.templateTypeList.multi.id, name: Locales.current.pages.admin.templateComponent.list.muti, list: [] }
    ];
    list.forEach(item => {
        for (let i = 0; i < fieldList.length; i++) {
            if (fieldList[i].type === item.moduleElementType) {
                fieldList[i].list.push(item);
                break;
            }
        }
    });
    return (
        <div>
            {
                fieldList.filter(item => !_isEmpty(item.list)).map(item => {
                    return (
                        <div className='template-components-list-page-detail-updateList-item' key={item.type}>
                            <div className='template-components-list-page-detail-updateList-item-field'>{item.name}：</div>
                            <div className='template-components-list-page-detail-updateList-item-info'>
                                {
                                    !_isEmpty(item.list.filter(items => items.changeType === Udesk.enums.templateChangeType.newer.id)) &&
                                    <div className='template-components-list-page-detail-list'>
                                        <div className='template-components-list-page-detail-list-field'>{Locales.current.pages.admin.templateComponent.list.newer}：</div>
                                        <div className='template-components-list-page-detail-list-field'>
                                            {
                                                item.list.filter(items => items.changeType === Udesk.enums.templateChangeType.newer.id).map(items => {
                                                    return (
                                                        <Button disabled key={items.id} type='link'>{items.name}</Button>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    !_isEmpty(item.list.filter(items => items.changeType === Udesk.enums.templateChangeType.add.id)) &&
                                    <div className='template-components-list-page-detail-list'>
                                        <div className='template-components-list-page-detail-list-field'>{Locales.current.pages.admin.templateComponent.list.add}：</div>
                                        <div className='template-components-list-page-detail-list-field'>
                                            {
                                                item.list.filter(items => items.changeType === Udesk.enums.templateChangeType.add.id).map(items => {
                                                    return (
                                                        <Button disabled key={items.id} type='link'>{items.name}</Button>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    !_isEmpty(item.list.filter(items => items.changeType === Udesk.enums.templateChangeType.del.id)) &&
                                    <div className='template-components-list-page-detail-list'>
                                        <div className='template-components-list-page-detail-list-field'>{Locales.current.pages.admin.templateComponent.list.deleteOk}：</div>
                                        <div className='template-components-list-page-detail-list-field'>
                                            {
                                                item.list.filter(items => items.changeType === Udesk.enums.templateChangeType.del.id).map(items => {
                                                    return (
                                                        <Button disabled key={items.id} type='link'>{items.name}</Button>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>


    );
};
export default class TemplateComponentsListDetailTemplate extends TemplateComponentsListDetailRoute {
    render() {
        let { taskData,
            fieldData,
            tagData,
            interData,
            conceptData,
            infoData,
            tagField,
            infoField,
            conceptField,
            interField,
            fieldMap,
            multiField,
            multiData,
            taskField,
            name,
            description,
            editEnable,
            showEnable,
            orgName,
            releaseVersion,
            version,
            logList,
            modulePluginId,
            moduleStatus,
            updateFlag
        } = this.privates;
        let { actions } = this;
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={Locales.current.pages.admin.templateComponent.list.detail}
                    onBack={actions.backToIndexPage}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root template-components-list-page-detail'>
                        <div className="template-components-list-page-detail-header">
                            <div className='template-components-list-detail-descrt-title'>
                                <span>{Locales.current.pages.admin.templateComponent.list.infomation}</span>
                                {
                                    modulePluginId ?
                                        <Switch onChange={actions.switchChange} defaultChecked={moduleStatus === Udesk.enums.templateModuleStatus.open.id ? true : moduleStatus === Udesk.enums.templateModuleStatus.open.id && false} />
                                        : null

                                }

                            </div>
                            <Descriptions size='small' style={{ marginTop: '16px' }}>
                                <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.name}>{name}</Descriptions.Item>
                                <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.detailConfig}>{showEnable}</Descriptions.Item>
                                <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.nowVersion}>{version}</Descriptions.Item>
                                <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.orgName}>{orgName}</Descriptions.Item>
                                <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.twoEdit}>{editEnable}</Descriptions.Item>
                                <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.lastedVersion}>
                                    <Space>
                                        <span>{releaseVersion}</span> {
                                            updateFlag ? <Button type='link' onClick={actions.getStatus}>{Locales.current.pages.admin.templateComponent.list.mustUpdate}</Button> : null}
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item className="template-components-list-detail-descrt-item" label={Locales.current.pages.admin.templateComponent.list.descition} span={2}>
                                    {description}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div className="template-components-list-page-detail-body">
                            <div className='template-components-list-detail-descrt-title'>
                                <span>{Locales.current.pages.admin.templateComponent.list.stencilsDetail}</span>
                            </div>
                            <div className='template-components-list-page-detail-body-content'>
                                <Row>
                                    <Col span={6}>
                                        <div className='template-components-list-page-detail-body-list'>
                                            <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.task}</div>
                                            {
                                                !_isEmpty(taskData) && taskData.map(item => {
                                                    return (
                                                        <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': taskField[item.id] })}>
                                                            <div className='template-components-list-page-detail-body-list-content-name'>{item.name}</div>
                                                            <div className='template-components-list-page-detail-body-content-color'>
                                                                <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item, item.type)} />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className='template-components-list-page-detail-body-list'>
                                            <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.point}</div>
                                            {
                                                !_isEmpty(fieldData) && fieldData.map(item => {
                                                    return (
                                                        <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': fieldMap[item.id] })}>
                                                            <div className='template-components-list-page-detail-body-list-content-name'>{item.name}</div>
                                                            <div className='template-components-list-page-detail-body-content-color'>
                                                                <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item, item.type)} />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className='template-components-list-page-detail-body-list'>
                                            <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.muti}</div>
                                            {
                                                !_isEmpty(multiData) && multiData.map(item => {
                                                    return (
                                                        <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': multiField[item.id] })}>
                                                            <div className='template-components-list-page-detail-body-list-content-name'>{item.name}</div>
                                                            <div className='template-components-list-page-detail-body-content-color'>
                                                                <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item, item.type)} />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.inter}</div>
                                            {
                                                !_isEmpty(interData) && interData.map(item => {
                                                    return (
                                                        <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': interField[item.id] })}>
                                                            <div className='template-components-list-page-detail-body-list-content-name'>{item.name}</div>
                                                            {/* <div className='template-components-list-page-detail-body-content-color'>
                                                                <Icon type="ic-log-line-beizhu" />
                                                            </div> */}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>

                                    </Col>
                                    <Col span={6}>
                                        <div className='template-components-list-page-detail-body-list'>
                                            <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.tag}</div>
                                            {
                                                !_isEmpty(tagData) && tagData.map(item => {
                                                    return (
                                                        <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': tagField[item.id] })}>
                                                            <div className='template-components-list-page-detail-body-list-content-name'>{item.name}</div>
                                                            <div className='template-components-list-page-detail-body-content-color'>
                                                                <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item, item.type)} />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.concept}</div>
                                            {
                                                !_isEmpty(conceptData) && conceptData.map(item => {
                                                    return (
                                                        <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': conceptField[item.id] })}>
                                                            <div className='template-components-list-page-detail-body-list-content-name'>{item.name}</div>
                                                            <div className='template-components-list-page-detail-body-content-color'>
                                                                <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item, item.type)} />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.info}</div>
                                            {
                                                !_isEmpty(infoData) && infoData.map(item => {
                                                    return (
                                                        <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': infoField[item.id] })}>
                                                            <div className='template-components-list-page-detail-body-list-content-name'>{item.name}</div>
                                                            <div className='template-components-list-page-detail-body-content-color'>
                                                                <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item, item.type)} />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="template-components-list-page-detail-body">
                            <div className='template-components-list-detail-descrt-title'>
                                <span>{Locales.current.pages.admin.templateComponent.list.updatedRecords}</span>
                            </div>
                            <div className='template-components-list-page-detail-body-content'>
                                {
                                    !_isEmpty(logList) && logList.map(item => {
                                        return (
                                            <div className='template-components-list-page-detail-updateList'>
                                                <div className='template-components-list-page-detail-updateList-item'>
                                                    <span className='template-components-list-page-detail-updateList-item-field'>{Locales.current.pages.admin.templateComponent.list.version}：</span>
                                                    <span>{item.version}</span>
                                                </div>
                                                <div className='template-components-list-page-detail-updateList-item'>
                                                    <span className='template-components-list-page-detail-updateList-item-field'>{Locales.current.pages.admin.templateComponent.list.updateDescription}：</span>
                                                    <span>{item.updateNote}</span>
                                                </div>
                                                {
                                                    !_isEmpty(item.itemList) && <InfoItem list={item.itemList} />
                                                }
                                                <Divider />
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
