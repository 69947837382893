import UdeskLocales from 'UdeskLocales';

export const LatitudeMap = {
    Task: 1,
    Department: 2,
    Student: 3,
    Score: 4,
    TaskStatus: 5,
};

export const useLatitudeOptions = () => {
    const LatitudeOptions = [
        {
            label: /* 任务名称 */ UdeskLocales['current'].pages.coach.dataAnalysis
                .intelligentAnalysis.components.search.components.latitudeModal.const.taskName,
            value: LatitudeMap.Task,
        },
        {
            label: /* 部门 */ UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis
                .components.search.components.latitudeModal.const.department,
            value: LatitudeMap.Department,
        },
        {
            label: /* 学员姓名 */ UdeskLocales['current'].pages.coach.dataAnalysis
                .intelligentAnalysis.components.search.components.latitudeModal.const.studentName,
            value: LatitudeMap.Student,
        },
        {
            label: /* 得分结果 */ UdeskLocales['current'].pages.coach.dataAnalysis
                .intelligentAnalysis.components.search.components.latitudeModal.const
                .scoringResults,
            value: LatitudeMap.Score,
        },
        {
            label: /* 任务完成状态 */ UdeskLocales['current'].pages.coach.dataAnalysis
                .intelligentAnalysis.components.search.components.latitudeModal.const
                .taskCompletionStatus,
            value: LatitudeMap.TaskStatus,
        },
    ];

    return { LatitudeOptions };
};
