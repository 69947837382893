import React, { useCallback, useMemo } from 'react';
import Udesk from 'Udesk';
import { fire, getPropByPath, isArray, isDefined, isFunction, isPlainObject, setPropValue } from 'src/util/core';

function forFire(source, data, path?) {
    let current = data;

    if (isDefined(path)) {
        current = getPropByPath(data, path).value;
    }

    if (isFunction(current)) {
        current = fire(current, getPropByPath(source, path).value);
        if (isDefined(path)) {
            setPropValue(data, path, current);
        } else {
            data = current;
        }
    }

    if (isPlainObject(current) || isArray(current)) {
        Reflect.ownKeys(current).forEach((key: any) => {
            forFire(source, data, `${path || ''}.${key}`);
        });
    }

    return current;
}

export function createComponent (
    Template,
    storages = {
        filterData: {},
        pagination: {
            current: Udesk.config.paging.defaultPageNumber,
            pageSize: Udesk.config.paging.defaultPageSize,
            total: 0,
        },
    } as any,
    notTransitionToRoutes = [
        /^\/coach\/learning-center\/task\/*/i,
    ],
    defaultProps = {},
    storageWay = "memory"
) {
    class Component extends React.Component {
        privates = {
            storages,
        };
        enableStorage = true;
        storageStrategies = {
            storageWay,
            resetStrategies: {
                transitionToRoutes: [],
                notTransitionToRoutes,
            },
        };
        actions = {
            saveStorages(values: any) {
                (this as any).privates.storages = forFire((this as any).privates.storages, values);
                (this as any).actions.update();
            },
            updateStorages(deep: any, values: any = true, isUpdate = true) {
                const privates = (this as any).privates;
                if (deep === false) {
                    privates.storages = $.extend({}, privates.storages, forFire(privates.storages, values));
                } else {
                    privates.storages = $.extend(true, {}, privates.storages, forFire(privates.storages, deep));
                    isUpdate = values;
                }
                if (isUpdate) {
                    (this as any).actions.update();
                }
            },
        };
        render() {
            return <Template
                storages={this.privates.storages}
                saveStorages={this.actions.saveStorages}
                updateStorages={this.actions.updateStorages}
                {...this.props}
                {...defaultProps} 
            />;
        }
    }
    return Udesk.react.udeskify(Component);
}

export function useStorageProps(props, name, defaultValue?, isUpdate?) {
    const {storages, updateStorages} = props;

    const value = useMemo(() => {
        return isDefined(Reflect.get(storages, name), () => Reflect.get(storages, name), () => defaultValue);
    }, [
        storages, 
        name, 
        defaultValue
    ]);
    const setValue = useCallback((value) => {
        updateStorages({[name]: value}, isUpdate);
    }, [
        isUpdate,
        name, 
        updateStorages
    ]);

    return [value, setValue];
}