
import UdeskLocales from 'UdeskLocales';export function getTimeEchartOptions({
    avgScore,
    speechCraftAvgScore,
    emotionAvgScore,
    speedAvgScore,
    flowAvgScore,
    totalPracticeTimes,
    practiceCostTime,
    participateCommitRate,
    participateExcellentRate,
    xAxis,
}) {
    const leftMaxValue = Math.max(
        calMax(avgScore),
        calMax(speechCraftAvgScore),
        calMax(emotionAvgScore),
        calMax(speedAvgScore),
        calMax(flowAvgScore),
        calMax(participateExcellentRate),
        calMax(participateCommitRate)
    );

    const rightMaxValue = Math.max(
        calMax(totalPracticeTimes),
        secondToMin(calMax(practiceCostTime))
    );

    const interval_left = leftMaxValue / 5; //左轴间隔
    const interval_right = rightMaxValue / 5; //右轴间隔

    let series = [
        {
            name: /* 平均分 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.abilityGrowthTrend.utils.averageScore,
            type: 'line',
            yAxisIndex: 0,
            data: avgScore,
            smooth: true,
        },
        {
            name: /* 话术平均得分 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.abilityGrowthTrend.utils.averageScoreOfScript,
            type: 'line',
            yAxisIndex: 0,
            data: speechCraftAvgScore,
            smooth: true,
        },
        {
            name: /* 情绪平均得分 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.abilityGrowthTrend.utils.averageEmotionalScore,
            type: 'line',
            yAxisIndex: 0,
            data: emotionAvgScore,
            smooth: true,
        },

        {
            name: /* 表达平均得分 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.abilityGrowthTrend.utils.expressionAverageScore,
            type: 'line',
            yAxisIndex: 0,
            data: speedAvgScore,
            smooth: true,
        },
        {
            name: /* 流程平均得分 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.abilityGrowthTrend.utils.processAverageScore,
            type: 'line',
            yAxisIndex: 0,
            data: flowAvgScore,
            smooth: true,
        },

        // {
        //     name: '累计时长',
        //     type: 'line',
        //     yAxisIndex: 1,
        //     // data: secondToMin(practiceCostTime),
        //     data: practiceCostTime.map((item) => {
        //         return secondToMin(item);
        //     }),
        //     smooth: true,
        // },

        // {
        //     name: '累计次数',
        //     type: 'line',
        //     yAxisIndex: 1,
        //     data: totalPracticeTimes,
        //     smooth: true,
        // },
        // {
        //     name: '参与达标率',
        //     type: 'line',
        //     yAxisIndex: 0,
        //     data: participateCommitRate,
        //     smooth: true,
        // },
        // {
        //     name: '参与达优率',
        //     type: 'line',
        //     yAxisIndex: 0,
        //     data: participateExcellentRate,
        //     smooth: true,
        // },
    ];
    console.log('opt:Time', series, leftMaxValue, interval_left, rightMaxValue, interval_right);
    const res = getEchartOptions(
        series,
        leftMaxValue,
        interval_left,
        rightMaxValue,
        interval_right,
        xAxis
    );

    res.yAxis[0].axisLabel.formatter = function (value, index) {
        return (
            // value + /* 人 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary.people
            value + /* 分 */UdeskLocales['current'].pages.coach.dashboard.components.employeeDataCards.components.abilityGrowthTrend.utils.branch
        );
    };
    res.yAxis[1].axisLabel.formatter = function (value, index) {
        return Number(value).toFixed(2) + 'min';
    };
    return res;
}

function getEchartOptions(
    series,
    maxLeft,
    interval_left,
    maxRight,
    interval_right,
    xAxis,
    unit = 'min'
) {
    console.log('series', series);
    const option = {
        backgroundColor: '#F5F8FE',
        color: [
            '#5B8FF9',
            '#5AD8A6',
            '#F6BD16',
            '#E86452',
            '#6DC8EC',
            '#945FB9',
            '#FF9845',
            '#1E9493',
            '#FF99C3',
            '#CDDDFD',
            '#CDF3E4',
            '#FCEBB9',
            '#F8D0CB',
            '#D3EEF9',
            '#DECFEA',
            '#FFE0C7',
            '#BBDEDE',
            '#FFE0ED',
        ],
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            top: 24,
            data: series.map((s) => s.name),
        },
        grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
        },
        yAxis: [
            {
                type: 'value',
                min: 0,
                max: maxLeft,
                splitNumber: 5,
                interval: interval_left,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return value + ' ';
                    },
                },
            },
            {
                type: 'value',
                min: 0,
                max: maxRight,
                splitNumber: 5,
                interval: interval_right,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return value + unit;
                    },
                },
            },
        ],
        series,
    };

    return option;
}

function secondToMin(second) {
    return Number((second / 60 / 60).toFixed(2));
}

function calMax(arr) {
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
        // 求出一组数组中的最大值
        if (max < arr[i]) {
            max = arr[i];
        }
    }
    const maxint = Math.ceil(max / 10); // 向上取整
    const maxval = maxint * 10; // 最终设置的最大值
    return maxval; // 输出最大值
}
