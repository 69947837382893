import React from 'react';
import { Space, Icon } from 'udesk-ui';

export default React.memo((props:any) => {
    const {className, title, list, content, footer, isClosed, handlerToggle} = props;
    return (
        <ul className={`row-header ${className}`}>
            {
                title ? (
                    <li className='left-line' style={{ cursor: 'pointer' }} onClick={handlerToggle}>
                        <Space size={4}>
                            <span className='label-name'>
                                {title}
                            </span>
                            {isClosed ? (
                                <div style={{ fontSize: 16, color: '#0D51D9' }}>
                                    <Icon type='zhankai1'></Icon>
                                </div>
                            ) : (
                                <div style={{ fontSize: 16, color: '#0D51D9' }}>
                                    <Icon type='shouqi1'></Icon>
                                </div>
                            )}
                        </Space>
                    </li>
                ) : null
            }
            {
                isClosed ? null : [
                    list?.map((item: { userId: React.Key | null | undefined; userName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
                        return (
                            <li key={item.userId} className='user-name'>
                                {item.userName}
                            </li>
                        );
                    }),
                    content
                ]
            }
            {footer}
        </ul>
    );
});