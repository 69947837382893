// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    PostIntelligentPartnerTaskInfosBody,
    BaseResponseIntelligentPartnerTaskInfoFoundResponse,
} from '../types';

/**
 * 保存任务发布配置
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [POST] /intelligentPartnerTaskInfos
 */
export function postIntelligentPartnerTaskInfos(
    data: PostIntelligentPartnerTaskInfosBody
): Promise<BaseResponseIntelligentPartnerTaskInfoFoundResponse>;
export function postIntelligentPartnerTaskInfos(
    data: PostIntelligentPartnerTaskInfosBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerTaskInfoFoundResponse>;
export function postIntelligentPartnerTaskInfos(
    data: PostIntelligentPartnerTaskInfosBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerTaskInfoFoundResponse> {
    return request<PostIntelligentPartnerTaskInfosBody, never, never>(
        '/intelligentPartnerTaskInfos',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerTaskInfos',
        }
    );
}

export const meta = [
    { tags: ['智能陪练发布任务接口'], path: '/intelligentPartnerTaskInfos', method: 'post' },
];
