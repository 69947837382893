// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerCourseByIdParam,
    BaseResponseIntelligentPartnerCourseFoundResponse,
    PutReviewIntelligentPartnerCourseByIdParam,
    PutReviewIntelligentPartnerCourseByIdBody,
    DeleteReviewIntelligentPartnerCourseByIdParam,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 查询课程
 *
 * @export
 * @tags 智能陪练-课程
 * @link [GET] /review/intelligentPartner/course/{id}
 * @param id
 */
export function getReviewIntelligentPartnerCourseById(
    options: ApiOptions<GetReviewIntelligentPartnerCourseByIdParam, never> & {
        segments: GetReviewIntelligentPartnerCourseByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerCourseFoundResponse> {
    return request<never, GetReviewIntelligentPartnerCourseByIdParam, never>(
        '/review/intelligentPartner/course/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewIntelligentPartnerCourseById' }
    );
}

/**
 * 修改课程
 *
 * @export
 * @tags 智能陪练-课程
 * @link [PUT] /review/intelligentPartner/course/{id}
 * @param id
 */
export function putReviewIntelligentPartnerCourseById(
    data: PutReviewIntelligentPartnerCourseByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerCourseByIdParam, never> & {
        segments: PutReviewIntelligentPartnerCourseByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerCourseFoundResponse> {
    return request<
        PutReviewIntelligentPartnerCourseByIdBody,
        PutReviewIntelligentPartnerCourseByIdParam,
        never
    >('/review/intelligentPartner/course/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerCourseById',
    });
}

/**
 * 删除课程
 *
 * @export
 * @tags 智能陪练-课程
 * @link [DELETE] /review/intelligentPartner/course/{id}
 * @param id
 */
export function deleteReviewIntelligentPartnerCourseById(
    options: ApiOptions<DeleteReviewIntelligentPartnerCourseByIdParam, never> & {
        segments: DeleteReviewIntelligentPartnerCourseByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReviewIntelligentPartnerCourseByIdParam, never>(
        '/review/intelligentPartner/course/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerCourseById',
        }
    );
}

export const meta = [
    { tags: ['智能陪练-课程'], path: '/review/intelligentPartner/course/{id}', method: 'get' },
    { tags: ['智能陪练-课程'], path: '/review/intelligentPartner/course/{id}', method: 'put' },
    { tags: ['智能陪练-课程'], path: '/review/intelligentPartner/course/{id}', method: 'delete' },
];
