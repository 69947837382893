import React, { useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import { Empty } from 'udesk-ui';
import { useImmer } from 'use-immer';
import useResizeObserver from 'use-resize-observer';
import { legend, radarOptionLegend, radarOptionTitle, seriesItemStyle } from './const';
import UdeskLocales from 'UdeskLocales';
import { getIntelligentPartnerScoringPoints } from 'src/api/intelligentPartner/scoring/points';

const Wrap = styled.div`
    margin-top: 24px;
    overflow: hidden;

    > div {
        height: 300px;
    }
`;

const ComprehensiveEvaluation = React.memo((props: any) => {
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const echartsRef = useRef<any>(null);

    const [data] = useImmer<any>({
        radarLegend: [],
        indicator: [],
        series: [],
    });
    const [radarOption, setRadarOption] = useImmer<any>(null);
    const radarSeriesColumns = useMemo(() => {
        return [
            {
                name: '',
                type: 'radar',
                data,
            },
        ];
    }, [data]);

    useEffect(() => {
        const token = requestIdleCallback(() => {
            echartsRef.current?.resize?.();
        });
        return () => {
            cancelIdleCallback(token);
        };
    }, [width, height]);
    useEffect(() => {
        if (!props.detail) return;

        getIntelligentPartnerScoringPoints().then((resp) => {
            const idTypeMap = resp.data?.reduce((p, c) => {
                p[c.id!] = c.pointType;
                return p;
            }, {}) ?? {};
            console.log('idTypeMap', idTypeMap);

            const [getThisTime] = legend.map((l) => getItem(l.areaStyleColor, l.name));
    
            const thisTimeEchartData = getEchartDataFromData(props.detail, idTypeMap);
    
            const { modelRuleList } = props.detail;
            const indicator = modelRuleList.map((modelRule) => {
                return {
                    name: modelRule.name,
                    max: 100,
                };
            });
    
            let radarOption = {
                title: radarOptionTitle,
                color: legend.map((i) => i.color),
                legend: radarOptionLegend,
                tooltip: {
                    confine: true,
                    trigger: 'item',
                    formatter: function (params) {
                        let relVal = params.name;
                        let detail = thisTimeEchartData.detail;
                        relVal += indicator
                            .map((i, index) => {
                                const main =
                                    '<br/><strong>' +
                                    i.name +
                                    ': ' +
                                    params.data.value[index] +
                                    '%</strong>';
                                const sub = detail[index];
                                return main + '<br />' + sub;
                            })
                            .join('');
                        return relVal;
                    },
                },
                radar: [
                    {
                        name: {
                            // (圆外的标签)雷达图每个指示器名称的配置项。
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 14,
                                color: 'rgba(0, 0, 0, .65)',
                            },
                        },
                        nameGap: 15,
                        // 指示器名称和指示器轴的距离。[ default: 15 ]
                        splitNumber: 5,
                        // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
                        shape: 'polygon',
                        // 雷达图绘制类型，支持 'polygon'(多边形) 和 'circle'(圆)。[ default: 'polygon' ]
                        indicator,
                    },
                ],
                series: [
                    {
                        name: /* 雷达图 */ UdeskLocales['current'].pages.coach.learningCenter.record
                            .detail.components.comprehensiveEvaluation.index.radarChart,
                        // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
                        type: 'radar',
                        // 系列类型: 雷达图
                        itemStyle: seriesItemStyle,
                        data: [getThisTime(thisTimeEchartData.res)],
                        zlevel: 2,
                    },
                ],
            };
            setRadarOption(radarOption);
        });
    }, [props.detail]);
    return (
        <Wrap ref={ref}>
            {radarOption ? (
                <ReactEcharts
                    ref={echartsRef}
                    type="radar"
                    // areaStyle={areaStyle}
                    seriesColumns={radarSeriesColumns}
                    top={100}
                    rerenderOptimization={true}
                    finalizeChartOptions={() => radarOption}
                />
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Wrap>
    );
});

export { ComprehensiveEvaluation };

function getEchartDataFromData(data, idTypeMap) {
    const res: any[] = [];

    const violations = 1;
    const finishNodes = 10;
    const totalNodes = 10;
    const commitPoints = 8;
    const totalPoints = 10;
    const speedFastTimes = 1;
    const speedSlowTimes = 1;
    const modalParticleTooMuchTimes = 2;
    const negativeEmotionTimes = 0;
    const totalWordNum = 1000;
    const commitWordNum = 1000;

    console.log('data:: ', data);

    if (!data)
        return {
            res,
            detail: [],
        };
    res.push(finishNodes / totalNodes);
    res.push(commitPoints / totalPoints);
    res.push((10 - violations) / 10);
    res.push(Math.min(2, data.expressScore) / data.expressScore);
    res.push(Math.min(3, data.emotionScore ?? 0) / data.emotionScore ?? 1);
    res.push(Math.min(3, data.emotionScore ?? 0) / data.emotionScore ?? 1);

    return {
        res: res.map((i) => (isNaN(i) ? 0 : (i * 100) >>> 0)),
        detail: data.modelRuleList.reduce((res, point) => {
            switch (idTypeMap[point.pointId]) {
                case 1: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 完整流程数 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .spanStylemarginLeftpxNumberOfCompleteProcesses
                        }${finishNodes}/${totalNodes}</span>`
                    );
                    break;
                }
                case 3:
                case 2: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 命中要点数 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .spanStylemarginLeftpxNumberOfHitPoints
                        }${commitPoints}/${totalPoints}</span>`
                    );
                    break;
                }
                case 4: {
                    res.push(`${/* <span style="margin-left: 8px">· 标准话术累积 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.components.comprehensiveEvaluation.index.spanStylemarginLeftpxStandardScriptAccumulation}${totalWordNum}${/* 个字，学员跟读命中的字数共 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.components.comprehensiveEvaluation.index.theTotalNumberOfWordsHitByTheStudentAfterReading}${commitWordNum}${/* 个字</span> */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.components.comprehensiveEvaluation.index.aWordspan}`);
                    break;
                }
                case 5: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 有 */ UdeskLocales['current'].pages
                                .coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxYes
                        }${violations}${
                            /* 处违规词</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index
                                .dealingWithIllegalWordsspan
                        }`
                    );
                    break;
                }
                case 6: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 语速过快 */ UdeskLocales['current']
                                .pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxSpeakTooFast
                        }${speedFastTimes}${/* 次</span><br /><span style="margin-left: 8px">· 语速过慢 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.components.comprehensiveEvaluation.index.timesspanbrspanStylemarginLeftpxSpeakTooSlowly}${speedSlowTimes}${/* 次</span> */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.components.comprehensiveEvaluation.index.timesspan}`
                    );
                    break;
                }
                case 7: {
                    res.push(
                        `${/* <span style="margin-left: 8px">· 语气词过多 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.components.comprehensiveEvaluation.index.tooManyModalParticles}${modalParticleTooMuchTimes}${
                            /* 次</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.timesspan
                        }`
                    );
                    break;
                }
                case 8: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 负面情绪 */ UdeskLocales['current']
                                .pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxNegativeEmotions
                        }${negativeEmotionTimes}${
                            /* 次</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.timesspan
                        }`
                    );
                    break;
                }
            }
            return res;
        }, []),
    };
}

function getItem(color, name) {
    return (value) => {
        return {
            // name: '本次',
            name,
            // value: [100, 80, 60, 50, 30],
            value,
            symbol: 'circle',
            symbolSize: 5,
            label: {
                normal: {
                    show: true,
                    position: 'top',
                    distance: 5,
                    fontSize: 14,
                    formatter: function (params) {
                        return params.value;
                    },
                },
            },
            lineStyle: {
                normal: {
                    opacity: 0.5,
                },
            },
            areaStyle: {
                // color: 'rgba(51,0,255,0.6)',
                color,
            },
        };
    };
}
