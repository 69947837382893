import React, { useEffect, useState } from 'react';
import { getIntelligentPartnerScoringModels } from 'src/api/intelligentPartner/scoring/models';
import { Select } from 'udesk-ui';

export const ScoringModelsSelect = (props) => {
    const [options, setOptions] = useState<any[]>([]);

    useEffect(() => {
        getIntelligentPartnerScoringModels().then((rsp) => {
            setOptions((rsp.data ?? []).map((i) => ({ label: i.name, value: i.id })));
        });
    }, []);

    return (
        <div>
            <Select
                {...props}
                options={options}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    ((option?.label ?? '') as string).toLowerCase().includes(input.toLowerCase())
                }
            />
        </div>
    );
};
