// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetReviewCallAnalysisUserByIdKeyEventsQuery,
    GetReviewCallAnalysisUserByIdKeyEventsParam,
    BaseResponseDataCallAnalysisUserTagResultResponse,
} from '../../../../../../types';

/**
 * keyEventsByUser
 *
 * @export
 * @tags 团队分析
 * @link [GET] /review/call/analysis/user/{id}/keyEvents
 * @param id
 */
export function getReviewCallAnalysisUserByIdKeyEvents(
    options: ApiOptions<
        GetReviewCallAnalysisUserByIdKeyEventsParam,
        GetReviewCallAnalysisUserByIdKeyEventsQuery
    > & { segments: GetReviewCallAnalysisUserByIdKeyEventsParam } & {
        params: GetReviewCallAnalysisUserByIdKeyEventsQuery;
    }
): Promise<BaseResponseDataCallAnalysisUserTagResultResponse> {
    return request<
        never,
        GetReviewCallAnalysisUserByIdKeyEventsParam,
        GetReviewCallAnalysisUserByIdKeyEventsQuery
    >('/review/call/analysis/user/{id}/keyEvents', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallAnalysisUserByIdKeyEvents',
    });
}

export const meta = [
    { tags: ['团队分析'], path: '/review/call/analysis/user/{id}/keyEvents', method: 'get' },
];
