// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetToDosByIdParam,
    BaseResponseToDoFoundResponse,
    PutToDosByIdParam,
    PutToDosByIdBody,
    BaseResponseVoid,
    DeleteToDosByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags to-do-controller
 * @link [GET] /toDos/{id}
 * @param id
 */
export function getToDosById(
    options: ApiOptions<GetToDosByIdParam, never> & { segments: GetToDosByIdParam }
): Promise<BaseResponseToDoFoundResponse> {
    return request<never, GetToDosByIdParam, never>('/toDos/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getToDosById',
    });
}

/**
 * update
 *
 * @export
 * @tags to-do-controller
 * @link [PUT] /toDos/{id}
 * @param id
 */
export function putToDosById(
    data: PutToDosByIdBody,
    options: ApiOptions<PutToDosByIdParam, never> & { segments: PutToDosByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutToDosByIdBody, PutToDosByIdParam, never>('/toDos/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putToDosById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags to-do-controller
 * @link [DELETE] /toDos/{id}
 * @param id
 */
export function deleteToDosById(
    options: ApiOptions<DeleteToDosByIdParam, never> & { segments: DeleteToDosByIdParam }
): Promise<BaseResponseVoid> {
    return request<never, DeleteToDosByIdParam, never>('/toDos/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteToDosById',
    });
}

export const meta = [
    { tags: ['to-do-controller'], path: '/toDos/{id}', method: 'get' },
    { tags: ['to-do-controller'], path: '/toDos/{id}', method: 'put' },
    { tags: ['to-do-controller'], path: '/toDos/{id}', method: 'delete' },
];
