import { capitalize as capitalizeString } from './string';

export default {
    trim,
    upper,
    lower,
    capitalize
};

function trim(str) {
    str = normalize(str);
    if (str == null) {
        return str;
    }
    return str.trim();
}

function upper(str) {
    str = normalize(str);
    if (str == null) {
        return str;
    }
    return str.toUpperCase();
}

function lower(str) {
    str = normalize(str);
    if (str == null) {
        return str;
    }
    return str.toLowerCase();
}

function capitalize(str) {
    str = normalize(str);
    if (str == null) {
        return str;
    }
    return capitalizeString(str);
}

function normalize(str) {
    if (str == null) {
        return str;
    } else {
        if (typeof str !== "string") {
            str = str.toString();
        }
        return str;
    }
}