import React from 'react';
import { InputNumber, Select } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
import './index.less';

const ExecutionFilter = (props) => {
    const { defaultValue, value, onChange } = props;

    const getValue = () => {
        return value || defaultValue || {};
    };

    const handleChange = (val) => {
        if (typeof onChange === 'function') {
            onChange({
                ...getValue(),
                ...val,
            });
        }
    };

    return (
        <div className="execution-filter-wrapper">
            <Select
                style={{ width: 100 }}
                options={[
                    {
                        label: /* 参与率 */ UdeskLocales['current'].pages.coach.taskCenter
                            .components.executionFilter.index.participationRate,
                        value: 1,
                    },
                    {
                        label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.completeRate,
                        value: 2,
                    },
                    {
                        label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.commitRate,
                        value: 3,
                    },
                    {
                        label: UdeskLocales['current'].pages.gong.coachTaskCenter.list
                            .excellentRate,
                        value: 4,
                    },
                ]}
                placeholder={UdeskLocales['current'].labels.pleaseSelect}
                value={getValue().executeType}
                onChange={(val) => handleChange({ executeType: val })}
            />
            <Select
                style={{ width: 100, marginLeft: 4 }}
                options={[
                    {
                        label: UdeskLocales['current'].enums.fieldOperators.greaterThanEq,
                        value: 'more_than_eq',
                    },
                    { label: UdeskLocales['current'].enums.fieldOperators.relative, value: 'eq' },
                    {
                        label: UdeskLocales['current'].enums.fieldOperators.lessThanEq,
                        value: 'less_than_eq',
                    },
                ]}
                placeholder={UdeskLocales['current'].labels.pleaseSelect}
                value={getValue().executeOperate}
                onChange={(val) => handleChange({ executeOperate: val })}
            />
            <InputNumber
                style={{ width: 100, marginLeft: 4, marginRight: 4 }}
                placeholder={UdeskLocales['current'].labels.pleaseEnter}
                precision={2}
                value={getValue().executeValue}
                onChange={(val) => handleChange({ executeValue: val })}
            />
            <span>%</span>
        </div>
    );
};

export default ExecutionFilter;
