// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetIntelligentExtractTasksQuery,
    BaseResponseListIntelligentExtractTaskFoundResponse,
    PostIntelligentExtractTasksBody,
    BaseResponseIntelligentExtractTaskFoundResponse,
} from '../types';

/**
 * 查询抽取任务列表
 *
 * @export
 * @tags 智能抽取接口
 * @link [GET] /intelligentExtractTasks
 */
export function getIntelligentExtractTasks(): Promise<BaseResponseListIntelligentExtractTaskFoundResponse>;
export function getIntelligentExtractTasks(
    options: ApiOptions<never, GetIntelligentExtractTasksQuery>
): Promise<BaseResponseListIntelligentExtractTaskFoundResponse>;
export function getIntelligentExtractTasks(
    options?: ApiOptions<never, GetIntelligentExtractTasksQuery>
): Promise<BaseResponseListIntelligentExtractTaskFoundResponse> {
    return request<never, never, GetIntelligentExtractTasksQuery>('/intelligentExtractTasks', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentExtractTasks',
    });
}

/**
 * 新建抽取任务
 *
 * @export
 * @tags 智能抽取接口
 * @link [POST] /intelligentExtractTasks
 */
export function postIntelligentExtractTasks(
    data: PostIntelligentExtractTasksBody
): Promise<BaseResponseIntelligentExtractTaskFoundResponse>;
export function postIntelligentExtractTasks(
    data: PostIntelligentExtractTasksBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentExtractTaskFoundResponse>;
export function postIntelligentExtractTasks(
    data: PostIntelligentExtractTasksBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentExtractTaskFoundResponse> {
    return request<PostIntelligentExtractTasksBody, never, never>('/intelligentExtractTasks', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentExtractTasks',
    });
}

export const meta = [
    { tags: ['智能抽取接口'], path: '/intelligentExtractTasks', method: 'get' },
    { tags: ['智能抽取接口'], path: '/intelligentExtractTasks', method: 'post' },
];
