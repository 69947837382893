import React from 'react';
import Udesk from 'Udesk';
import DialogueManageIndexRoute from './route';
import FilterManage from '../components/filter-manage';
import ListManage from '../components/list-manage';
import './style.scss';

export default class DialogueManageIndexTemplate extends DialogueManageIndexRoute {
    render() {
        let { filterVisible, isUpdate, keyword } = this.privates;
        let { selectedFilter } = this.privates.storages;
        let { actions, locales } = this;
        return (
            // <div className="row qa-react-page call-manage-page">
            <div className='udesk-qa-web-page'>
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root ticket-manage-page'>
                        <div className='ticket-manage-page-left-part'>
                            <FilterManage
                                ref={this.privates.filterManageRef}
                                type={Udesk.enums.filterTypes.ticket.id}
                                filterManageTitle={/* 工单筛选器 */locales.pages.ticketManage.template.workOrderFilter}
                                onEditFilter={actions.editFilter}
                                allFilterTitle={/* 全部工单 */locales.pages.ticketManage.template.allWorkOrders}
                                onSelectedFilterChanged={actions.onSelectedFilterChanged}
                                isUpdate={isUpdate}
                            />
                        </div>
                        <div className='ticket-manage-page-right-part'>
                            <ListManage
                                title={/* 工单管理 */locales.pages.ticketManage.template.workOrderManagement}
                                cacheKey='ticket-manage.index'
                                searchPlaceHolder={locales.labels.searchPlaceHolder}
                                type={Udesk.enums.filterTypes.ticket.id}
                                editFilterVisible={filterVisible}
                                onEditFilterVisibleChanged={actions.onEditFilterVisibleChanged}
                                onViewItemDetail={actions.onViewItemDetail}
                                onPageChanged={actions.onPageChanged}
                                selectedFilter={selectedFilter}
                                onConditionFilterSaved={actions.onConditionFilterSaved}
                                keyword={keyword}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
