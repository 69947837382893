import Locales from '../locales/index';

let features = [
    { id: 1, key: "waitEvaluate", name: () => Locales.current.enums.taskStatusTypes.waitEvaluate, },
    { id: 2, key: "waitSubmit", name: () => Locales.current.enums.taskStatusTypes.waitSubmit },
    { id: 3, key: "waitConfirm", name: () => Locales.current.enums.taskStatusTypes.waitConfirm },
    { id: 4, key: "waitReview", name: () => Locales.current.enums.taskStatusTypes.waitReview },
    { id: 5, key: "completed", name: () => Locales.current.enums.taskStatusTypes.completed }
];

let reportFeatures = [
    { id: 1, key: 'entiretyReport', name: "entiretyReport" },
    { id: 2, key: 'serviceGroupReport', name: "serviceGroupReport" },
    { id: 3, key: 'serviceReport', name: "serviceReport" },
];

export default {
    features,
    reportFeatures
};