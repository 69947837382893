import React from 'react';
import PropTypes from 'prop-types';
import Udesk from '../../udesk/index';

const OutputValueModes = {
    object: "object",
    value: "value",
};

class InputBaseClass extends React.Component {
    static get OutputValueModes() {
        return OutputValueModes;
    }

    static get emberName() {
        return 'udesk/custom-field-inputs/input-base-class';
    }

    validate() {
        let isValid = true;
        let messages = null;

        let validateMandatory = this.actions._validateMandatory();
        if (validateMandatory === true) {
            let validate = this.actions._validate();
            if (validate === true) {
                messages = null;
            } else if (validate instanceof Array && validate.length > 0) {
                messages = validate;
            }
        } else if (validateMandatory instanceof Array && validateMandatory.length > 0) {
            messages = validateMandatory;
        }
        isValid = messages == null ? true : false;

        return {
            isValid: isValid,
            messages: messages
        };
    }

    "[privates]"() { 
        return {
            oldValue: null
        };
    }
    
    static computesUsedActions = ["_getInternalValueFromValue"]
    static computes = {
        mergedAttributes: ({ props, state, privates, locales }) => {
            let {
                attributes,
                dynamicAttributes,
                runtimeAttributes
            } = props;
            return Udesk.utils.object.deepCopy({}, attributes, dynamicAttributes, runtimeAttributes);
        },
        internalValue: ({ props, state, privates, actions, locales }) => {
            return actions._getInternalValueFromValue(props.value);
        }
    }

    "[actions]"() { 
        return {
            _getInternalValueFromValue(value) {
                return this.actions._getBaseClassGlobalInternalValueFromValue(value);
            },
            _getBaseClassGlobalInternalValueFromValue(value) {
                let {
                    valueValueField,
                    valueMode,
                } = this.props;
                let internalValue = null;
                if (valueMode === OutputValueModes.object) {
                    if (typeof value !== "object") {
                        value = {};
                    }
                    internalValue = value[valueValueField];
                } else {
                    internalValue = value == null ? "" : value;
                }
                return internalValue;
            },
            _validate() {
                return true;
            },
            _validateMandatory() {
                let {
                    required,
                    name,
                } = this.props;
                let {
                    internalValue
                } = this.privates.computes;
                // mandatory validation
                if (internalValue == null || internalValue === undefined || !Udesk.config.regex.required.test(internalValue)) {
                    if (required) {
                        if (name) {
                            return [Udesk.utils.string.format(this.locales.validation.requiredFormat, name)];
                        }
                        return [this.locales.validation.required];
                    } else {
                        return true;
                    }
                }

                return true;
            },
            _getValueFromInternalValue(internalValue) {
                let {
                    value,
                    valueValueField,
                    valueMode,
                } = this.props;
                if (valueMode === OutputValueModes.object) {
                    if (typeof value !== "object") {
                        value = {};
                    }
                    value[valueValueField] = internalValue;
                } else {
                    value = internalValue;
                }
                return value;
            },
            _onChanged(internalValue) {
                let {
                    value,
                    valueMode,
                    onChanged
                } = this.props;

                if (valueMode === OutputValueModes.value) {
                    value = internalValue;
                } else {
                    value = this.actions._getValueFromInternalValue(internalValue);
                }
                onChanged(value);
            }
        };
    }
}

InputBaseClass.propTypes = {
    valueMode: PropTypes.string,
    dataSource: PropTypes.array,
    disabled: PropTypes.bool,
    attributes: PropTypes.object,
    dynamicAttributes: PropTypes.object,
    runtimeAttributes: PropTypes.object,
    isValid: PropTypes.bool,
    name: PropTypes.string,
    errors: PropTypes.array,
    required: PropTypes.bool,
    onChanged: PropTypes.func,
};

InputBaseClass.defaultProps = {
    value: null,
    language: null,
    valueMode: OutputValueModes.value,
    dataSource: [],
    disabled: false,
    attributes: {},
    dynamicAttributes: {},
    runtimeAttributes: {},
    isValid: true,
    name: null,
    errors: [],
    valueValueField: null,
    valueNameField: null,
    required: false,
    onChanged: null,
};

export default InputBaseClass;