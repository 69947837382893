import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'udesk-ui';
import { fetchEventSource } from 'udesk-llm-fe/dist/utils/fetch/fetchEventSource';
import { useApiPath, useRequest } from 'src/util/hook/request';
import { inspect, isDefined, isNotEmpty } from 'src/util/core';
import { useDebounce } from 'src/util/hook';
import UdeskLocales from 'UdeskLocales';

export default React.memo<{ callId: number }>((props) => {
    const [list, setList] = useState<any[] | undefined>([]);
    const [loading, setLoading] = useState(false);
    const getApiPath = useApiPath();
    const { loading: initLoading, data } = useRequest({
        manual: false,
        defaultApi: `/questionExtractions/${props.callId}`,
    });

    const getLLMText = useDebounce(() => {
        const text: string[] = [];
        setLoading(true);
        if (props.callId) {
            fetchEventSource(getApiPath(`/questionExtractions/model/${props.callId}?clientId=${Math.ceil(Math.random() * 1000000)}`), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                onmessage(resp) {
                    text.push(resp.data);
                },
                onclose() {
                    setList(text.join('') ? JSON.parse(text.join('')) : []);
                    setLoading(false);
                },
                onerror(err) {
                    setLoading(false);
                    throw err;
                },
            });
        }
    }, [
        getApiPath,
        props.callId
    ]);

    useEffect(() => {
        try {
            setList(data?.modelAnswer ? JSON.parse(data.modelAnswer) : undefined);
        } catch (e) {
            setList(undefined);
        }

    }, [data]);

    return (
        <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'auto',
        }}>
            {
                inspect(initLoading, () => (
                    <Spin spinning={initLoading}>
                        <div className={'problem-extraction-markdown'}>
                        </div>
                    </Spin>
                ), () => (
                    <>
                        {
                            isNotEmpty(list, () => (
                                <div style={{
                                    flex: 1,
                                    padding: 16,
                                    overflow: 'auto',
                                }}>
                                    <Spin spinning={loading}>
                                        <div className={'problem-extraction-markdown'}>
                                            {
                                                list?.map(item => (
                                                    <div style={{
                                                        marginBottom: 16,
                                                        color: '#555',
                                                        fontSize: 14,
                                                    }}>
                                                        <div>
                                                            <span
                                                                style={{
                                                                    padding: '0 5px',
                                                                    borderRadius: '8px',
                                                                    background: '#1b6dff',
                                                                    color: 'white',
                                                                }}>
                                                                    {item.questionType}
                                                            </span>
                                                            <span style={{
                                                                marginLeft: 8,
                                                                color: item.isAnswer === /* 否 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.no ? 'red' : 'green',
                                                            }}>
                                                                {item.isAnswer === /* 否 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.no ? /* 客服未解答 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.customerServiceDidNotAnswer : /* 客服已解答 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.customerServiceHasAnswered}
                                                            </span>
                                                            <span style={{
                                                                marginLeft: 8,
                                                                color: item.isSolve === /* 否 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.no ? 'red' : 'green',
                                                            }}>
                                                                {item.isSolve === /* 否 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.no ? /* 问题未解决 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.problemUnresolved : /* 问题已解决 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.theProblemHasBeenResolved}
                                                            </span>
                                                        </div>
                                                        <div style={{
                                                            marginLeft: 5,
                                                            display: 'flex',
                                                        }}>
                                                            <div style={{
                                                                whiteSpace: 'nowrap',
                                                            }}>
                                                                {/* 客户问题： */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.customerIssue}
                                                            </div>
                                                            <div>
                                                                {item.questionContent || '--'}
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            marginLeft: 5,
                                                            display: 'flex',
                                                        }}>
                                                            <div style={{
                                                                whiteSpace: 'nowrap',
                                                            }}>
                                                                {/* 客服解答： */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.customerServiceAnswer}
                                                            </div>
                                                            <div>
                                                                {item.kfAnswer || '--'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Spin>
                                </div>
                            ))
                        }
                        <div style={{
                            padding: 16,
                            textAlign: 'center'
                        }}>
                            <Button disabled={loading} onClick={() => getLLMText()} type='primary'>{isDefined(list, /* 重新抽取问题 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.reExtractQuestions, /* 点击抽取问题 */UdeskLocales['current'].components.qualityCheckScoreTalk.components.problemExtraction.clickToExtractQuestions)}</Button>
                        </div>
                    </>
                ))
            }
        </div>
    );
});