import EventedClass from '../evented-class';
import ajax from '../ajax';

const IMAGE_FILE_EXTENSIONS = [
    "BMP",
    "JPG",
    "JPEG",
    "PNG",
    "GIF",
];
const DEFAULT_AJAX_TIME = 2;

const DEFAULT_OPTIONS = {
    measureTimes: DEFAULT_AJAX_TIME,
    intervalTime: 10,
};

class NetworkClass extends EventedClass {
    constructor(options) {
        super();
        this._currentTimes = 1;
        this._timer = null;
        this._options = Object.assign({}, DEFAULT_OPTIONS, options);
    }

    start() {
        this.measureInternetSpeedInterval();
    }
    stop() {
        clearTimeout(this._timer);
        this._timer = null;
    }
    resetCurrentTime() {
        this._currentTimes = 0;
    }
    _measureInternetSpeedByAjax() {
        let startTime = Date.now();
        let endTime;
        let times = this._options.measureTimes;
        let url = this._options.url;

        function netTest(resolve, reject) {
            ajax({
                url
            }).then(response => {
                this._currentTimes++;
                if (response && this._currentTimes >= times) {
                    endTime = Date.now();
                    let responseSize = response.length;
                    let deferTime = endTime - startTime;
                    let speed = responseSize * times / (deferTime / 1000) / 1024;
                    resolve({
                        speed: Math.floor(speed),
                        rtt: deferTime
                    });
                }
            }, reason => {
                reject(reason);
            });
        }

        if (!url) {
            throw new Error('measure internet speed ajax url connot be null!');
        }

        this.resetCurrentTime();
        return new Promise((resolve, reject) => {
            for (var i = 0; i < times; i++) {
                netTest(resolve, reject);
            }
        });
    }
    _measureInternetSpeedByH5API() {
        return navigator.connection.downlink * 1024 / 8;
    }
    _measureInternetRttByH5API() {
        return navigator.connection.rtt;
    }
    measureInternetSpeedPromise() {
        let context = this;
        return new Promise((resolve, reject) => {
            if (navigator && navigator.onLine === false) {
                resolve({
                    speed: 0,
                    rtt: 0
                });
            } else if (navigator && navigator.onLine && navigator.connection && navigator.connection.downlink != null) {
                let speed = context._measureInternetSpeedByH5API();
                let rtt = context._measureInternetRttByH5API();
                resolve({
                    speed,
                    rtt
                });
            } else {
                context._measureInternetSpeedByAjax().then(response => {
                    resolve(response);
                }, reason => {
                    reject(reason);
                });
            }

        });
    }
    measureInternetSpeedInterval() {
        let intervalTime = this._options.intervalTime;
        let context = this;
        context.measureInternetSpeedPromise().then(response => {
            context.trigger('speedReturned', [response]);
        }, reason => {
            context.trigger('speedError', [reason]);
        }).finally(() => {
            context._timer = setTimeout(function() {
                context.measureInternetSpeedInterval();
            }, intervalTime * 1000)
        });
    }

}

export default {
    isHtml,
    getCurrentUrl,
    htmlEncode,
    htmlDecode,
    isImageFileType,
    getFileExtension,
    openWindow,
    NetworkClass
};

export {
    NetworkClass
}

function isHtml(text) {
    return /<[^>]+>/g.test(text);
}

//Moved to a seperate plugin
// function safeHtml(text) {
//     var div = document.createElement('div');
//     div.innerHTML = text;
//     return (div.innerHTML || '').replace(/class\s*=\s*['"]([\w\s-])+['"]/ig, '');
// }

function getCurrentUrl(rootUrl = "") {
    let url = window.location.pathname + window.location.search + window.location.hash;
    if (rootUrl && url.startsWith(rootUrl)) {
        url = url.replace(rootUrl, "");
    }
    return url;
}

function htmlEncode(str) {
    var s = "";
    if (str == null || str.length === 0) return "";
    s = str.replace(/&/g, "&amp;");
    s = s.replace(/</g, "&lt;");
    s = s.replace(/>/g, "&gt;");
    s = s.replace(/ /g, "&nbsp;");
    s = s.replace(/'/g, "&#39;");
    s = s.replace(/"/g, "&quot;");
    return s;
}

function htmlDecode(str) {
    var s = "";
    if (str == null || str.length === 0) return "";
    s = str.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/&#39;/g, "'");
    s = s.replace(/&quot;/g, "\"");
    return s;
}

function isImageFileType(fileName) {
    let extension = getFileExtension(fileName);
    let upperExtension = extension.toUpperCase();
    return IMAGE_FILE_EXTENSIONS.includes(upperExtension);
}

function getFileExtension(fileName) {
    if (fileName == null) {
        return false;
    }
    if (typeof fileName !== "string") {
        fileName = fileName.toString();
    }
    let extension = "";
    let lastDotPosition = fileName.lastIndexOf(".");
    if (lastDotPosition !== -1) {
        let questionPosition = fileName.indexOf("?");
        if (questionPosition !== -1) {
            fileName = fileName.substr(0, questionPosition);
        }
        lastDotPosition = fileName.lastIndexOf(".");
        if (lastDotPosition !== -1) {
            extension = fileName.substr(lastDotPosition + 1);
        } else {
            extension = "";
        }
    } else {
        extension = fileName;
    }
    return extension;
}

/*
 *提供一个弹开新窗口的方法
 *options { newWindow }
 * newWindow: 适配在ajax内部调用时还是被拦截的问题，需要先在发送ajax之前window.open("about:blank")并把newWindow的值传给openWindow方法
 */

function openWindow(url, options) {
    if (url == null || url === "") {
        return;
    }
    if (options == null) {
        options = {};
    }
    let newWindow = options.newWindow != null ? options.newWindow : window.open("about:blank");
    newWindow.location.href = url;
}