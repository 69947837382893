export default {
  labels: {
    clear: "Boş.",
    refresh: "Yenileme",
    nextStep: "Bir sonraki adım",
    prevStep: "Bir adım daha",
    closeDriver: "Önyükleme çıkın",
    complete: "Tamamla",
    viewStatistics: "İstatistikleri görüntüle",
    colon: "：",
    splitter: "/",
    remark: "Açıklamalar",
    correction: "Hata düzeltme",
    save: "Kaydet",
    reset: "Sıfırla",
    increase: "Ekleme",
    preview: "Önizleme",
    unit: "Madde",
    cancel: "İptal",
    all: "Hepsi...",
    searchPlaceHolder: "Anahtar kelime sorgusu girin",
    greaterThan: "Daha büyük",
    lessThan: "Daha az",
    second: "Saniye",
    description: "Açıklama",
    column: "Sütun",
    pleaseChoice: "Lütfen seçin",
    filter: "Tarama",
    saveAndSubmit: "Kaydet ve Gönder",
    submit: "Gönderme",
    appeal: "İtiraz",
    review: "İnceleme",
    choose: "Seçim",
    manage: "Yönetim",
    delete: "Silme",
    add: "Yeni",
    create: "Yeni",
    edit: "Düzenleme",
    name: "İsim",
    describe: "Açıklama",
    next: "Bir sonraki adım",
    percent: "%",
    export: "İhracat",
    exportAll: "Tüm dışa aktarma",
    confirm: "Emin olmak için",
    back: "Geri dön",
    versionUpdate:
      "Web sitemizin yeni bir sürüm yayınlamaya başladığını size bildirmekten memnuniyet duyarız! Normal kullanımınızı garanti altına almak için, mevcut çalışmanızı hemen kaydetmeniz ve ardından sayfayı yenilemeniz gerekir (tarayıcının yenileme düğmesine tıklayın veya F5 tuşuna basın). İşimiz için çok teşekkür ederim!",
    more: "Daha fazla",
    selected: "Seçilmiş",
    deduct: "Toka",
    maxScore: "En çok",
    scoreUnit: "Puan",
    pointUnit: "Bir tane.",
    comma: "，",
    view: "Görüntüle",
    batchingImport: "İçe aktarma",
    deleteContent:
      "Silmek istediğini onaylıyor musun? Sildikten sonra geri yüklenemez",
    import: "İçe aktarma",
    uploadFile: "Dosya yükleme",
    downloadFile: "Dosyaları dışa aktarma",
    upload: "Yükle",
    click: "Tıklayın",
    downloadTemplate: "Şablonu indirin",
    fillInTheForm: "Biçime göre doldurun",
    UploadExcelfile: "Toplu içe aktarma işlemini tamamlamak için Excel'i yükle",
    uploadIdentical:
      "API adı varsa, sisteminizde bulunan veriler içe aktarıldığında güncellenir",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Uygulanabilir",
    leftBracket: "(",
    rightBracket: ")",
    question: "Soru",
    answer: "Cevap",
    content: "İçerik",
    search: "Arama",
    query: "Sorgulama",
    cancelEdit: "Düzenle'yi iptal et",
    noValue: "Veri yok",
    selectAll: "Tam seçim",
    changeAGroup: "Bir set değiştir",
    arrow: " ---> ",
    saveReview: "Gözden geçirme ve gönderme",
    saveSpotCheck: "Örnekleme gönderme",
    rapidSampling: "Hızlı nokta kontrolü",
    file: "Arşiv",
    download: "İndir",
    lastPage: "Önceki sayfa",
    nextPage: "Sonraki sayfa",
    batchActions: "Toplu işlemler",
    cancelDistribution: "Tahsis iptal edildi",
    againDistribution: "Yeniden atama",
    deleteRecord: "Kayıtları sil",
    oneOptionOpertor: "İsteğe bağlı kalite denetçisi",
    allOptionOpertor: "Tüm kalite denetçileri",
    distributionInfoFirstOne: "Doğru olduğunu onayla",
    distributionInfoFirstTwo:
      "Kayıt yeniden dağıtıldı mı? Lütfen atama hedefini seçin",
    distributionInfoSecondOne: "İptal etmek istediğinde emin olun",
    distributionInfoSecondTwo:
      "Kayıtların atanmış bilgileri iptal edildikten sonra atanmamış bir inceleme kaydı haline gelir.",
    distributionInfoSecondThree:
      "Kayıtların atanmış bilgileri, iptalden sonra atanmamış rastgele inceleme kayıtları olur.",
    distributionInfoThirdOne: "Seçilen kayıtlarda var",
    distributionInfoThirdTwo: "İncelenen kayıtlar işlenmeyecektir.",
    distributionInfoThirdThree: "Madde Örneklenmiş kayıtlar işlenmeyecektir.",
    exportError: "İhracat başarısız oldu",
    exportSuccess: "İhracat başarısı",
    deleteRecordInfo:
      "{0} kaydını silmek istediğime emin misin? Sildikten sonra veriler kurtarılamaz",
    pleaseSetFilter: "Lütfen filtrelemeyi seçin",
    noCountAfterFilter:
      "Dışa aktarma verileri yok, lütfen filtrelemeyi sıfırlayın",
    redo: "Yeniden yürütme",
    batchMove: "Toplu taşıma",
    websiteName: "Akıllı kalite kontrol",
    salesEnable: "Satış gücü",
    systemName: "Akıllı kalite kontrol",
    initSystemError:
      "Ağ anormallikleri veya sayfa başlatma başarısız oldu, lütfen müşteri hizmetlerine başvurun",
    day: "-Tanrım.-Tanrım.",
    hour: "Saatler",
    minute: "Puan",
    questionMark: "？",
    personalSettings: "Kişisel ayarlar",
    logout: "Kapat",
    ok: "Emin olmak için",
    saveAs: "Farklı olarak kaydet",
    pleaseEnter: "Lütfen girin",
    executeMessage: {
      executeSuccess: "{0} çubuğunun başarılı bir şekilde yürütülmesi",
      executeError: "{0} şerit yürütme başarısız",
    },
    field: "Alanlar",
    operator: "Operatör",
    value: "Değerler",
    placeholder: "Lütfen seçin",
    pleaseSelect: "Lütfen seçin",
    totalScore: "Toplam puan",
    hyphen: "-",
    semicolon: "；",
    wfm: "Akıllı vardiya",
    user: "Kullanıcı",
    none: "Hayır, hayır.",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Başarıyla kaydedin! İlgili çalışanlar sayfayı yeniledikten sonra yürürlüğe girecek",
    saveAsName: "{0}(kopya)",
    systemInformation: "Sistem verilerini görüntüleme",
    billingCenter: "Fatura Merkezi",
    warningMessage: "Kalan kontenjan yetersiz, lütfen en kısa sürede şarj edin",
    fileTypeHolder:
      "Dosya formatı yanlış, lütfen {0} formatlı dosyayı yükleyin",
    dataNo: "Veri yok",
    move: "Hareket et",
    study: "İşaretlenmemiş kendi kendine öğrenme kalite kontrol noktanız var",
    studySuccess: "Başarılı etiketleme",
    studyError: "Etiketleme başarısız oldu",
    editType: "Kategori etiketlerini düzenleme",
    copyed: "Panoya kopyalandı",
    clickToCopy: "Kopyalamak için tıklayın",
    addFilterConditions: "Filtreleme kriterleri ekleme",
    filterConditions: "Tarama koşulları",
    messageTime: "Mesaj zamanı",
    messageType: "Mesaj türleri",
    pushType: "Mesaj kanalları",
    messageContent: "Mesaj içeriği",
    messageName: "Mesaj adı",
    messageStatus: "Mesaj durumu",
    configuration: "Yapılandırma Bilgileri",
    sendTarget: "Hedef gönder",
    guideUrl: "Önyükleme URL'si",
    noticeDetail: "Mesaj detayları",
    action: "Operasyon",
    seeDetail: "Ayrıntıları görüntüle",
    theDay: "{0} gün",
    theLastDayOfMonth: "Her ayın son günü",
    ignore: "Görmezden gelin",
    seeNow: "Şimdi kontrol et",
    exportConfirmText: {
      export: "Toplam {0} veri, dışa aktarmayı onaylayın?",
      exportAll: "Tüm içeriği dışa aktarmayı onayla?",
    },
    samrTagAdd: "Etiket ekleme",
    deletePopconfirmContent: "Silmek istediğini onaylıyor musun?",
    TheLastThirtyDays: "Son 30 gün",
    addNumber: "Artı",
    subNumber: "Eksi",
    addStrategy: "Strateji ekle",
    minutes: "Dakika",
    status: "Durumu etkinleştir",
    pushConfig: "İtme yapılandırması",
    category: "Sınıflandırma",
    and: "Ve",
    interval: "Aralık",
    switchOn: "Açık",
    switchOff: "Kapat",
    batchDel: "Toplu silme",
    exportTxt: "TXT metnini dışa aktarma",
    playSpeed: "Hız iki katına",
    messageLogBtn: "Bildirimleri tetikleyen geçmeleri/konuşmaları görüntüleme",
    dataLink: "Veri bağlantısı",
    yes: "Evet, evet.",
    no: "Hayır, hayır.",
    sentenceUnit: "Cümle",
    appealDetail: "Şikayet kaydı detayları",
    detail: "Detaylar",
    default: "Varsayılan",
    customer: "Müşteri",
    agent: "Müşteri Hizmetleri",
    sort: "Sıralama",
    applyNow: "Uygulama",
    index: "Seri numarası",
    allColumns: "Eklenebilir sütunlar",
    selectedColumns: "Eklenen sütunlar",
    syn: "Senkronizasyon",
    saveAndNext: "Kaydet ve bir sonraki oluştur",
    viewReference: "Alıntıları görüntüle",
    referenceDetail: "Alıntı detayları",
    goToQaList: "Kalite kontrolüne git",
    goToCheck: "Örnekleme",
    businessCard: "Kartvizitler",
    weapp: "Küçük program",
    redpacket: "Kırmızı zarf",
    externalRedpacket: "Kırmızı zarflar",
    sphfeed: "Video numarası",
    exportTaskCreated: "Başarıyı oluşturmak için görevleri dışa aktarma",
    plzGoTo: "Lütfen atlayın",
    importTaskCreated: "Başarıyı oluşturmak için görevleri içe aktarma",
    taskCreated: "Görev oluşturma başarısı",
    reply: "Cevap ver",
    replied: "Cevap verdi.",
    release: "Yayınla",
    expandMore: "Diğer {0} yanıtları genişletin",
    comment: "Yorumlar",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Lütfen en az bir tane {0}",
      canNotBeEmptyFormat: "{0} boş olamaz",
      repeatErrorTipFormat: "{0} bir tekrarlama var",
      getInformationFailedFormat:
        "{0} bilgisini alamıyorsanız, lütfen ağ bağlantısını kontrol edin veya daha sonra tekrar deneyin",
      deleteSuccessFormat: "{0} başarıyla silindi.",
      deleteFailedFormat:
        "{0} Silme başarısız oldu, lütfen daha sonra tekrar deneyin.",
      saveSuccessFormat: "{0} başarıyla kaydedildi.",
      saveFailedFormat:
        "{0} Kaydetme başarısız oldu, lütfen daha sonra tekrar deneyin.",
      fileSuccessFormat: "{0} başarıyla arşivlendi.",
      fileFailedFormat:
        "{0} Dosyalama başarısız oldu, lütfen daha sonra tekrar deneyin.",
      exportSuccessFormat: "{0} başarılı ihracat",
      exportFailedFormat:
        "{0} İhracat başarısız oldu, lütfen daha sonra tekrar deneyin.",
      submitSuccessFormat: "{0} başarıyla gönderildi.",
      submitFailedFormat:
        "{0} Gönderim başarısız oldu, lütfen daha sonra tekrar deneyin.",
      confirmSuccessFormat: "{0} Başarıyı onayladı.",
      confirmFailedFormat:
        "{0} başarısız olduğunu onaylar, lütfen daha sonra tekrar deneyin.",
      pleaseUploadFile: "Lütfen önce dosyayı yükleyin!",
      importFileSuccess: "Dosya başarıyla içe aktarılır!",
      importFildFailed: "Dosya içe aktarma başarısız oldu!",
      deleteSuccess: "Silme başarılı oldu!",
      deleteFailed: "Silme başarısız oldu, lütfen daha sonra tekrar deneyin!",
      illegalTitle: "Yetersiz izinler",
      illegalMessage:
        "Bir sonraki adım için izinlerin bir kısmı veya tamamı yok, lütfen yapılandırma için yöneticiye başvurun, kılavuzdan çıkmak için Tamam düğmesine tıklayın",
      illegalMessageCurrent:
        "Geçerli adım için izinlerin bir kısmına veya tümüne sahip değilseniz, lütfen yönetici yapılandırmasına başvurun, önyüklemeye çıkmak için Tamam düğmesine tıklayın",
      skipMessage:
        "Bu adımı geçici olarak atlamak istiyorsanız, Atla 'yı tıklayın",
      errorMsg500:
        "Sunucu isteği başarısız oldu, lütfen işlemlerinizi kontrol edin veya yenileyin ve tekrar deneyin",
      errorMsg404: "Ooops... Aradığınız sayfa eksik",
      errorMsg400:
        "Sunucu isteği başarısız oldu, verilerinizi kontrol ettikten sonra tekrar deneyin",
      pageOverFlow: "Şu anda sadece 10.000 ekran desteklenir.",
      isHandAddSmartTag: "El ile eklenen akıllı etiketler",
      defaultGradeMustAtLast: "Varsayılan derecelendirme en sonunda olmalıdır",
      AutomaticAllocationInspectorsIsNull:
        "Kalite müfettişi eklemek için lütfen temel bilgi sayfasına gidin",
      timePickerTips:
        "Normal kullanımınızı etkilememek için, 23:00 ~ 2:00 sistem bakım zamanıdır ve düzenli görevler yapılmaz.",
      batchDeleteInfo:
        "{0} çubuğu başarıyla silindi ve {1} çubuğu başarısız oldu Başarısızlık nedeni verilerin kullanılmasıdır.",
      batchDeleteTips: "Silmeyi onaylamak için {0} veri çubuğunu kontrol et?",
      correctionSuccess: "Başarılı hata düzeltme",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError: "En fazla 10 kalite kontrol formu ekleyin",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "Gösterge öğelerinin toplamı {0} 100 olmalı",
          banProjectMaxErrorTip: "2 bine kadar yasak madde eklendi",
          deadlyProjectMaxErrorTip: "Ölümcül öğe 500 adede kadar ekler",
        },
      },
    },
    info: {
      creatSuccess: "Başarılı Oluşturma",
      creatError: "Oluşturma başarısız oldu",
      editSuccess: "Değişiklikler başarılı oldu",
      editError: "Değişiklikler başarısız oldu",
      deleteSuccess: "Silme başarısı",
      addSuccess: "Yeni başarı",
      deleteError: "Silme işlemi başarısız oldu",
      conditionError: "Operatör mantık ifadesi hatası",
      customJudgeLogic: "Özel koşullu kombinasyon mantıksal ifade hatası",
      exportError: "İhracat başarısız oldu",
      searchError: "Filtreleme başarısız oldu",
      loadError: "{0} yükleme başarısız",
      saveSuccess: "Başarıyı kaydet",
      saveError: "Başarısız Kaydet",
      fileSuccess: "Başarılı Arşiv",
      fileError: "Arşiv başarısız oldu",
      cannotBeNull: "{0} boş olamaz!",
      pleaseSearchAndSelect: "Lütfen anahtar kelime arama girin",
      clickAndToggle: "Geçiş yapmak için seçmek için tıklayın",
      deleteWarning:
        "{0} Silme geri yüklenmeyecek, devam etmek istediğinizden emin misiniz?",
      getOssAuthFailed:
        "OSS yetkilendirmesi alınamadı, lütfen ağ bağlantısını kontrol edin veya daha sonra tekrar deneyin.",
      getInsightAuthFailed:
        "Insight yetkilendirmesi alınamadı, lütfen ağ bağlantısını kontrol edin veya daha sonra tekrar deneyin.",
      pictureUploadFailed: "Resim yüklemesi başarısız oldu",
      pictureUploadFailedSize:
        "Resim boyutu sınırı aşıyor, resim yükleme başarısız",
      wecomTagsSyncInfo: "Son güncelleme:{0}, senkronizasyon {1} mesajı",
      collectSuccess: "Koleksiyon başarısı",
    },
  },
  components: {
    common: {
      qcReportEcharts: {
        errorMessage: "Rapor verilerini elde etme başarısız oldu",
      },
      waveAudio: {
        noWaveMessage:
          "Deneyiminizi korumak için, {0} dakikadan daha uzun bir kayıt için dalga formu göstermez, doğrudan oynatabilirsiniz",
      },
      customFilter: {
        del: "Silme",
        edit: "Düzenleme",
        new: "Özel filtreleme",
        apply: "Uygulama",
        cancel: "İptal",
        onlyApply: "Sadece uygulanabilir",
        ohterSave: "Farklı olarak kaydet",
        save: "Kaydet",
        customFilter: "Yaygın Tarama",
        reset: "Sıfırla",
        holderKeyWord: "Lütfen anahtar kelimeleri girin",
        customFilterRule: "Filtreleme mantık kuralları:",
        customFilterName: "Filtre adı:",
        holderCustomFilterName: "Lütfen filtre adını girin",
        manualConfiguration: "Manuel yapılandırma",
        holderCustomRule: "Lütfen özel mantık koşullarını girin",
        example: "Örnek",
        dragConfiguration: "Sürükle ve bırak yapılandırma",
        condition: "Koşullar",
        operation: "İşlem",
        infoDel: "İçeriği silmek için alan dışına sürükleyin",
        toConfigure: "Yapılandırma",
        holderCustomFilterNameLength: "Filtre adı maksimum uzunluğu 10",
        customFilterNameNone: "Filtre adı boş olamaz",
        and: "Ve",
        or: "Veya",
        not: "Olmayan",
        delSuccess: "Silme başarısı",
        saveSuccess: "Başarıyı kaydet",
        updateSuccess: "Değişiklikler başarılı oldu",
        customFilterCondition: "Tarama koşulları",
        conditionRule: "Koşul kuralları",
        none: "Hayır, hayır.",
        filterPlaceholder: "Lütfen anahtar kelimeleri girin",
      },
      labelTabs: {
        addThis: "Buna ekle",
        goThis: "Buna Taşı",
        saveGo: "Kaydet ve eğit",
      },
      datePicker: {
        today: "Bugün...",
        yesterday: "Dün.",
        theDayBeforeYesterday: "Dünden önceki gün",
        thisWeek: "Bu hafta",
        lastWeek: "Geçen hafta",
        thisMonth: "Bu ay.",
        lastMonth: "Geçen ay.",
        lastSevenDay: "Son 7 gün",
        lastThirtyDay: "Son 30 gün",
        thisQuarter: "Bu çeyrek",
        lastQuarter: "Son çeyrek",
        thisYear: "Bu yıl",
        lastYear: "Geçen yıl",
        lastYearFromToday: "Son bir yıl",
      },
      pagination: { page: "Toplam {0},{1} sayfa" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Daha önceki mesajları yükleme",
          bottom: "Daha fazla mesaj yükle",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Kalite kontrol görevleri",
        informationCollectTasksManageTitle: "Toplama görevleri",
        customerServiceCenterTitle: "Müşteri Hizmetleri Merkezi",
        informationTitle: "Veri merkezi",
        intelligentAnalysisTitle: "Akıllı analiz",
        semanticIntelligence: "Anlamsal zeka",
        trainingCenterTitle: "Eğitim Merkezi",
        adminTitle: "Sistem yönetimi",
      },
      qualityCheckingTask: {
        title: "Çağrı kalitesi",
        index: {
          tipOne:
            "Çağrı kalitesi, çalışanların çağrı merkezinin iş kalitesini değerlendirmek için kullanılır. Kendi işinizle birlikte uygun bir kalite kontrol formu oluşturabilirsiniz.",
          tipTwo:
            "Tarama ve örnekleme dahil olmak üzere kalite kontrol görevleri için uygun kurallar ayarlanabilir. Kalite denetçisi puan verdikten sonra, müşteri hizmetleri kalite kontrol sonuçlarını görebilir ve daha fazla temyiz edebilir,",
          addTaskTitle: "Kalite Görevleri Ekleme",
          deleteTipTitle: "Kalite kontrol görevlerini silme",
          deleteTipContent:
            "Kalite kontrol görevi silindikten sonra geri yüklenemez, silinmesi onaylanır mı?",
          checkingTask: "Kalite kontrol görevleri",
          checkingTaskList: "Kalite kontrol görevleri listesi",
          formList: "Form listesi",
          viewHelpDocuments: "Yardım Belgelerini Görüntüle",
        },
        manage: {
          taskSetting: {
            title: "Görev ayarları",
            form: "Form",
            basicInfo: "Temel Bilgiler",
            visibleRange: "Görünür aralık",
            visibleRangeTip:
              "Seçilen müşteri hizmetleri ve müşteri hizmetleri grubu kalite kontrol görevini görüntüleyebilir. Ayrıca, bu görev için müşteri hizmetleri ekibi lideri, müşteri hizmetleri, kalite denetçisi vb.",
            automaticSampling: "Otomatik örnekleme",
            automaticSamplingTip:
              "Otomatik örnekleme için kurallar belirleyebilirsiniz, böylece sistem düzenli olarak belirlediğiniz kurallara göre kalite kontrol görevlerini ekleyecektir.",
            samplingRate: "Örnekleme frekansı",
            samplingTime: "Örnekleme zamanı",
            samplingRange: "Örnekleme aralığı",
            samplingRule: "Örnekleme kuralları",
            fillterCondition: "Tarama koşulları",
            workflow: "Kalite kontrol iş akışı",
            appealsTime: "İtirazlara izin verme süresi",
            appealsTimeTipOne: "Puanlama gönderildikten sonra",
            appealsTimeTipTwo: "Gün içinde",
            appealsTimeTipThree:
              'İzin verilen temyiz süresinin ötesinde, kayıt kalite kontrol durumu otomatik olarak "Onaylandı" olarak değiştirilir.',
            auditTime: "Denetim işleme süresi",
            auditTimeTipOne: "Şikayet ettikten sonra",
            auditTimeTipTwo: "Gün içinde",
            auditTimeTipThree:
              'Denetim ve işlem süresinin ötesinde, kayıt orijinal puanı korur ve kalite kontrol durumu otomatik olarak "Onaylandı" olarak değiştirilir.',
            noAppealsMode: "İtiraz modu yok",
            appealsMode: "İtiraz moduna izin ver",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Müşteri Hizmetleri",
            customerServicesGroup: "Müşteri Hizmetleri Grubu",
            surveyList: "Değerlendirme listesi",
            customFields: "Özel alanlar",
            fieldConfig: "Alan Yapılandırması",
            from: "来自",
            owner: "Sorumlu kişi",
            inspectorAndReviewerAuthority: "Kalite kontrol inceleme yetkisi",
            inspector: "Kalite denetçisi",
            reviewer: "Denetçiler",
            inspectorTip: "Kalite müfettişi görevi derecelendirebilir",
            reviewerTip: "Bir denetçi bu görevi derecelendirebilir",
            dataStatisticsAuthority: "Veri istatistikleri izinleri",
          },
          samplingRecords: {
            title: "Örnekleme kaydı",
            index: {},
            manualSampling: {
              title: "Örnekleme",
              fillterCondition: "Tarama koşulları",
              samplingRule: "Örnekleme kuralları",
              agent: "Müşteri Hizmetleri",
              type: "Türü",
              result: "Sonuçlar",
              evaluate: "Değerlendirme",
              relayNumber: "Röle numarası",
              source: "Kaynak",
              task: "Görev",
              queueState: "Kuyruk durumu",
              callHanger: "Çağrı kapanır",
              queueTime: "Sıra almak zaman alıyor",
              talkTime: "Arama süresi",
              bellTime: "Zil süresi",
              recordCountTipOne: "Toplam",
              recordCountTipTwo: "Şerit kaydı",
              customerServices: "Müşteri Hizmetleri",
              customerServicesGroup: "Müşteri Hizmetleri Grubu",
              surveyList: "Değerlendirme listesi",
              customFields: "Özel alanlar",
              fieldConfig: "Alan Yapılandırması",
              defaultFields: "Varsayılan alanlar",
            },
          },
          taskList: {
            title: "Görev listesi",
            index: {
              batchDelete: "Toplu silme",
              batchSubmit: "Toplu gönderme",
              batchConfirm: "Toplu onay",
              itemFields: "Proje alanını puanlama",
              categoryFields: "İkincil sınıflandırma alanı",
              forbidsAndDeadlies: "Yasak ve Ölümcül Öğeler",
              scoreFieldList: "Puanlama alanı listesi",
              customerName: "Müşteri adı",
              cellphone: "Telefon",
              email: "Posta kutusu",
              organization: "Şirket",
              description: "Açıklama",
              tags: "Etiketler",
              taskUserRole: "Geçerli Görev Kullanıcı Rolleri",
            },
            workBench: {
              title: "Kayıt Detayları",
              checkForm: "Kalite kontrol formu",
              waitEvaluate: "Değerlendirilecek",
              alreadyEvaluate: "Değerlendirildi",
              waitSubmit: "Gönderilecek",
              alreadySubmit: "Gönderildi",
              waitConfirm: "Onaylandı",
              alreadyConfirm: "Onaylandı",
              waitReview: "İncelenecek",
              alreadyReview: "İnceleme",
              alreadyDone: "Tamamlanmış.",
              comment: "Değerlendirme Notları",
              totalScore: "Toplam puan",
              score: "Puan",
              remark: "Açıklamalar",
              changeRecords: "Değişim kaydı",
              subject: "Tema",
              secondLevelScore: "İkinci seviye puanı",
              deadlyList: "Ölümcül madde",
              forbidList: "Yasak madde",
              addToCheckTask: "Kalite Görevleri Ekle",
              submitCheckRemark: "Kalite kontrol değerlendirmesi sunuldu",
              changeCheckRemark: "Gözden geçirilmiş kalite değerlendirme",
              doneCheckRemark: "Kalite kontrol değerlendirmesi tamamlandı",
              submitCheckReview: "Kalite incelemesi için gönderildi",
              submitCheckAppeal: "Kalite kontrol şikayetini sundu",
              saveFlowSuccess: "Başarıyı değerlendirin.",
              saveFlowFailed:
                "Değerlendirme başarısız oldu, lütfen daha sonra tekrar deneyin.",
              FormDetail: "Form detayları",
              callDetail: "Arama geçmişi detayları",
              sampleCalllogDetail: "Kayıt Detayları",
              appealReasonTip: "Lütfen itiraz nedenini girin (gerekli)",
              appeal: "İtiraz",
              appealReason: "İtiraz nedeni",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Kalite kontrol formu",
        basicInformationTitle: "Temel Bilgiler",
        indicatorsProjectTitle: "Gösterge projesi",
        specialProjectTitle: "Özel öğe",
        totalScore: "Toplam puan",
        indicatorsName: "Gösterge adı",
        score: "Puan",
        singleScore: "Tek puan",
        weight: "Ağırlık",
        maxDeductScore: "En fazla puan düşülür",
        add: "Arttırmak",
        detail: "Form detayları",
        list: {
          title: "Kalite kontrol formu",
          tipOne:
            "Kalite kontrol formunu yapılandırmak, kalite kontrol çalışmasının ilk adımıdır, en uygun kalite kontrol formunu yapılandırmak için şirketin işini birleştirebilirsiniz.",
          tipTwo:
            "Doğrudan puanlama, ağırlık puanlama, puan sistemi ve diğer formları destekliyoruz. Kuralları ayarladıktan sonra sisteme eklemeniz önerilir.",
        },
        basicInformation: {
          formTemplate: "Form Şablonları",
          directScore: "Doğrudan derecelendirme",
          weightedScore: "Ağırlık puanı",
          deductScore: "Puan düşülür",
          directScoreSecondLevel: "Doğrudan Puanlama-İkinci Seviye",
          weightedScoreSecondLevel: "Ağırlık Puanı-İkinci Seviye",
          deductScoreSecondLevel: "Kesinti-İkinci Seviye",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Yasak madde",
          banProjectTip:
            "Kalite kontrol puanını kontrol etmek için kullanılabilir, ihlal olarak kabul edilebilir veya gerçek puanı etkilemeden ek açıklamalar olarak kabul edilebilir.",
          deadlyProject: "Ölümcül madde",
          deadlyProjectTip:
            "Ölümcül madde, kalite kontrol puanı sırasında kontrol etmek için kullanılabilir. Ölümcül madde işaretli ise, kalite kontrol görevi doğrudan 0 puan",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Çağrı kalitesi",
          entiretyReportTitle: "Genel rapor",
          serviceGroupReportTitle: "Müşteri Hizmetleri Grubu Raporu",
          serviceReportTitle: "Müşteri Hizmetleri Raporu",
          entiretyReport: {
            pandect: "Genel bakış",
            contrast: "Kontrast",
            trend: "Trend",
            analyze: "Analiz",
            totalScore: "Toplam puan",
            banProject: "Yasak madde",
            deadlyProject: "Ölümcül madde",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Kalite kontrol sonuçları listesi",
          taskSelectPlaceholder: "Kalite kontrol görevlerini seçin",
          agentSelectPlaceholder: "Müşteri hizmetlerini seçin",
          refresh: "Yenileme",
          all: "Hepsi...",
          unread: "Okunmamış",
          inComplaint: "İtirazda",
          reviewed: "İnceleme",
          totalScore: "Genel inceleme",
          detail: "Kalite kontrol detayları",
          audioText: "Kayıt metni",
          callInfo: "Arama Bilgileri",
          ticketInfo: "İş emri bilgileri",
          wechatInfo: "Oturum Bilgileri",
          dialogText: "Konuşma metni",
          dialogInfo: "Diyalog bilgileri",
          customer: "Müşteri",
          agent: "Müşteri Hizmetleri",
          none: "Kalite kontrol verileri yok",
          appealSuccess: "İtiraz başarılı!",
          appealFailed:
            "Şikayet başarısız oldu, lütfen daha sonra tekrar deneyin!",
          pointListIsNull: "Şikayet kalite kontrol noktası boş olamaz!",
          pleaseSelectPoint: "Lütfen kalite kontrol noktasını seçin!",
          unsupportedFormat: "Format şu an için desteklenmiyor",
          shardingInformation: "Aşağıdaki parça {0}",
          smartTags: "Akıllı etiketler",
          businessRecords: "İş kayıtları",
          selectedPointsWarning:
            "Lütfen temyiz etmek istediğiniz kalite kontrol maddelerini kontrol edin, temyiz için ilgili cümleleri belirtin ve ardından Gönder 'i tıklayın.",
          applyReason: "Şikayet nedeni (en fazla 50 kelime)",
          chatMessageTypes: {
            all: "Hepsi...",
            text: "Metin",
            image: "Resimler",
            agree: "Oturum içeriğini kabul edin",
            video: "Video",
            voice: "Ses",
            file: "Belgeler",
            emotion: "İfade",
            link: "Bağlantı",
            chatrecord: "Sohbet geçmişi",
            mixed: "Karışık Mesajlar",
            default: "Destek mesajı yok",
            deleteFriend: "Arkadaşları Sil",
            redpacket: "Kırmızı zarf",
            card: "Kartvizitler",
            disAgree: "Oturum içeriğine katılmıyorum",
            weapp: "Küçük program",
            externalRedpacket: "Kırmızı zarflar",
            meetingVoicevCall: "Çağrı mesajları",
            uRL: "Web sayfası",
            replyTimeout: "Zaman aşımı yanıtı",
            sphfeed: "Video numarası",
            chatRecordTitle: "Sohbet geçmişi",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Toplam puan",
            actualScore: "Derecelendirme",
            actualGrade: "Derecelendirme",
            appeal: "İtiraz",
            remark: "Yapay notlar",
            none: "Kalite kontrol detayları yok",
            pointEdit: "Kalite kontrol noktası düzenleme sınıflandırması",
          },
          changeList: {
            title: "Değişim kaydı",
            user: "Kullanıcı",
            point: "Kalite kontrol noktası",
          },
          relatedRecord: {
            title: "İlgili kayıtlar",
            qualityList: "Kalite kontrol görevleri",
            none: "Hayır, hayır.",
            check: "Görüntüle",
            loadMore: "Daha fazla yükle",
          },
        },
      },
      grade: {
        title: "Kalite değerlendirmesi",
        subTitle:
          "Kalite kontrol derecesi, kalite kontrol puanının tamamlanmasından sonra kalite kontrol nesnesini derecelendirmek için kullanılır.",
        list: {
          up: "Yukarı hareket",
          down: "Aşağı in",
          del: "Silme",
          case: "Vaka kütüphanesine otomatik katılım",
          orderCase: "Varsayılan vaka kitaplığı",
          info: "Varsayılan derecelendirmeler eşleşmez ve silinemez. Yukarıdaki derecelendirmelerden herhangi birine uymuyorsa, varsayılan derecelendirmeyi elde edersiniz",
          add: "Derecelendirme ekle",
          save: "Kaydet",
          close: "İptal",
          max: "20 adede kadar kalite kontrol derecesi",
          name: "İsimsiz",
          success: "Başarıyı kaydet",
        },
      },
      qualityInspectionList: {
        title: "Kalite kontrol listesi",
        fastFilter: "Kalite kontrol ihlalleri",
        fastFilterTip:
          "Puanların düşürülmesi yasadışı, puan sisteminin başarısız olması yasadışı",
        titleWechat: "Analiz listesi",
        titleCollect: "Toplama listesi",
        status: "Analiz durumu",
        reQualityInspectionConfig: "Görev seçeneklerini yeniden kontrol et",
        pleaseSelectRecheckPoints:
          "Lütfen yeniden muhasebeleştirmeniz gereken kalite kontrol noktasını seçin",
        beginReQualityInspection: "Yürütme başladı",
        cancelReQualityInspection: "Görevi iptal et",
        reQualityInspection: "Yeniden kalite denetimi",
        reQualityInspectionWechat: "Yeniden analiz",
        reQualityInspectionUnderWay: "Yeniden inceleme görevinde...",
        reQualityInspectionUnderWayTip:
          "Pencereyi kapattıktan sonra sayfadan ayrılmak görevi etkilemez",
        reQualityInspectionDone: "Yeniden kalite kontrol tamamlandı",
        reQualityInspectionDoneTip:
          "Yeniden kalite kontrolü başarıyla tamamlandı, lütfen onay düğmesine tıklayın",
        endReQualityInspection: "Görevin sonlandırılması",
        alreadyDoneTip: "Tamamlanmış {0}",
        totalTip: "Toplam {0}",
        endReInspectionSuccess: "Yeniden kalite kontrol görevi sonlandırıldı",
        closeWindow: "Pencereyi kapat",
        endReInspectionFailed:
          "Yeniden kalite kontrol görevi başarısız oldu, lütfen daha sonra tekrar deneyin!",
        detail: {
          title: "Kalite kontrol detayları",
          wechatTitle: "Analiz detayları",
          titleCollect: "Detaylar Toplama",
          callIndex: "Bağlantı çağrısı",
          textIndex: "Bağlantı diyalogları",
          fileConfirm: "Dosyalama onaylandı mı?",
          fileConfirmDesc:
            "Dosyalamadan sonra kalite kontrol puanı düzenlenemez, lütfen dosyalama olup olmadığını onaylayın",
        },
        sysCloumnsContal: "Sistem Sütunu Alan Yönetimi",
        personCloumnsContal: "Kişisel sütun alan yönetimi",
      },
      spotCheckList: {
        title: "Örnekleme listesi",
        onlyMyTip: "Sadece örneklememi kontrol et",
        plInspector: "Lütfen örnekleme denetçisini seçin",
        allInspector: "Hepsini görün",
        seInspector: "Belirtilen nokta denetçisine bakın",
        myInspector: "Sadece bana bak",
        noInspector: "Atanmayan örnekleme denetçisini görüntüleyin",
        detail: { title: "Örnekleme detayları" },
      },
      reviewList: {
        title: "İnceleme listesi",
        onlyMyTip: "Sadece incelemi kontrol et",
        plInspector: "Lütfen bir denetçi seçin",
        allInspector: "Hepsini görün",
        seInspector: "Belirtilen denetçiyi görüntüleyin",
        myInspector: "Sadece bana bak",
        noInspector: "Atanmayan gözden geçirmeci görün",
        detail: { title: "İnceleme Detayları" },
      },
      caseBaseList: {
        title: "Vaka kütüphanesi",
        del: "Silme",
        delTitle: "Sildikten sonra tüm içerik kaydedilmez",
        cancel: "İptal",
        actions: "Operasyon",
        seeCaseBase: "Davayı görüntüle",
        seeDetail: "Ayrıntıları görüntüle",
        edit: "Düzenleme",
        save: "Kaydet",
        addCaseBase: "Yeni vaka kütüphanesi",
        export: "İhracat",
        ok: "Emin olmak için",
        tip: "Kalite kontrol listesindeki tipik arama ve konuşma verileri, kolay öğrenme ve paylaşım için vaka veritabanına kaydedilebilir",
        detail: {
          choiceCaseBase: "Lütfen bir dava seçin",
          delCaseBase: "Kılıf silme",
          addCaseBase: "Vaka ekleme",
          successed: "Başarıyı kaydet",
          info: "Lütfen önce düzenlemekte olan içeriği kaydedin veya iptal edin",
          caseType: "Lütfen eklemek istediğiniz vaka kütüphanesini seçin",
        },
      },
      testSetList: {
        title: "Kalite kontrol test seti",
        tip: "Kalite denetçisinin rastgele denetiminin verileri, kalite kontrol koşullarının ve kalite kontrol kurallarının yapılandırmasını optimize etmek için uygun olan kalite kontrol test setinde depolanacaktır.",
        refresh: "Yenileme",
        reQualityInspectionUnderWay: "Yeniden değerlendirme görevi...",
        reQualityInspectionUnderWayTip:
          "Pencereyi kapattıktan sonra sayfadan ayrılmak görevi etkilemez",
        reQualityInspectionDone: "Yeniden değerlendirme tamamlandı",
        reQualityInspectionDoneTip:
          "Yeniden değerlendirme başarıyla tamamlandı, lütfen onay düğmesine tıklayın",
        endReQualityInspection: "Görevin sonlandırılması",
        alreadyDoneTip: "Tamamlanmış {0}",
        totalTip: "Toplam {0}",
        endReInspectionSuccess: "Yeniden değerlendirme görevi sonlandırıldı",
        closeWindow: "Pencereyi kapat",
        endReInspectionFailed:
          "Yeniden değerlendirme görevi başarısız oldu, lütfen daha sonra tekrar deneyin!",
        recheckPoint:
          "Kalite kontrol puanlarının ve doğruluğunun yeniden hesaplanması",
        recheckPointTitle: "Seçenekleri yeniden değerlendirin",
        delete: {
          reQualityInspectionUnderWay: "Toplu silme görevinde...",
          reQualityInspectionUnderWayTip:
            "Pencereyi kapattıktan sonra sayfadan ayrılmak görevi etkilemez",
          reQualityInspectionDone: "Toplu silme görevi tamamlandı",
          reQualityInspectionDoneTip:
            "Toplu silme işlemi başarıyla tamamlandı, lütfen onay düğmesini tıklayın",
          endReQualityInspection: "Görevin sonlandırılması",
          alreadyDoneTip: "Tamamlanmış {0}",
          totalTip: "Toplam {0}",
          endReInspectionSuccess: "Toplu silme görevi sonlandırıldı",
          closeWindow: "Pencereyi kapat",
          endReInspectionFailed:
            "Toplu silme görevi başarısız oldu, lütfen daha sonra tekrar deneyin!",
        },
        reultList: {
          assessmentTime: "Son değerlendirme zamanı",
          assessmentAll: "Toplam değerlendirme",
          strip: "Madde",
          spot: "Nokta",
          deviation: "Sapma",
          accuracyRate: "Doğruluk oranı",
          info: "Değerlendirme sonucu yok, lütfen değerlendirin",
          onlyDeviationInfo: "Sadece sapma kayıtlarını görüntüleyin",
          againAssessment: "Yeniden değerlendirme",
          screen: "Tarama",
          batchDelete: "Toplu Kaldırma",
          matching: "Maç",
          ok: "Evet, evet.",
          noCancel: "Hayır, hayır.",
        },
      },
      smartWordsLibrary: {
        title: "Akıllı Eş Anlamlılar Sözlüğü",
        tip1: "Eş anlamlılar: Anlamsal etiketlerin eğitim hacmini azaltmak için kullanılır, eş anlamlı benzer cümlelerin tekrarlama eğitimi gerekmez",
        tip2: "Profesyonel isimler: yanlış şirket isimleri, ürün adları, endüstri kelime hazinesi ve diğer uygun isimlerin dönüştürülmesi, doğal dil anlayışının optimizasyonu, tanımlama doğruluğunu ve analiz doğruluğunu artırmak",
        tip3: "Kelimelere odaklanın: AI analitiği sıcak noktalarının mevcut işle eşleşmesine yardımcı olmak için bulut analizde odaklanmak istediğiniz kelimelere odaklanın",
        tip4: "Engelleme kelimeleri: AI analizi sıcak noktalarının mevcut işle eşleşmesine yardımcı olmak için bulut analizde dikkat etmek istemediğiniz kelimeleri engelleyin",
        tip5: "Kelime ağırlığı: Anlamsal etiketlerin tanımlanması için hata düzeltmesini optimize etmek için kullanılır Cümlelerdeki kelimelerin etki ağırlığını değiştirerek, belirli senaryolarda anlamsal tanıma etkisini gerektiği gibi optimize edin",
        tip6: "Korpusu verileri: ASR çevirisinin doğruluğunu artırmak için translasyon hata düzeltme ve ASR eğitimi için ilgili iş senaryoları için büyük paragraf metinleri girin",
        upload: {
          uploadSynonymWords: "Eş anlamlıları içe aktarma",
          uploadProfessionalWords: "Profesyonel isimleri içe aktarma",
          uploadAttentionWords: "Takip eden kelimeleri içe aktarın",
          uploadIgnoreWords: "Görmezden gelen kelimeleri içe aktarma",
          uploadWordsWeight: "Ağırlık kelimeleri içe aktarma",
          uploadTypo: "Yazım hatası kelime seti içe aktarma",
          uploadCorpusData: "Korpusu verilerini içe aktarma",
          uploadCorpusContent: "Korpusu veri içeriğini içe aktarma",
          firstUploadStep: "1. Şablonu indirin, şablon formatına göre doldurun",
          downloadTemplateMessage: "Şablonu indirin:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Şablon formatına uygun EXCEl dosyalarını yüklemek için içe aktarma düğmesine tıklayın",
          secondUploadCorpusDataStep:
            "2. Şablon formatına uygun TXT dosyalarını yüklemek için içe aktarma düğmesine tıklayın",
          postUploadDataFail: "Veri yükleme işlemi başarısız oldu",
          pleaseUploadTemplate: "Lütfen EXCEl/JSON/TXT dosyasını yükleyin",
          templateNeedJSONOrXLSX:
            "Dosya şablonları sadece JSON veya xlsx formatında",
          templateNeedTxt:
            "Sadece TXT dosyaları için bir korpusu veri dosyası şablonu",
          uploadSuccess: "Başarılı ithalat",
          uploadFailed: "İçe aktarma başarısız oldu",
          downloadError: "İndirme başarısız oldu",
          downLoadTxtTitle: "Korpusu içeriği. txt",
        },
        navTabNames: {
          synonymWords: "Eş anlamlılar",
          professionalWords: "Profesyonel isim",
          attentionWords: "Kelimeleri takip et",
          ignoreWords: "Kelimeleri yoksay",
          wordsWeight: "Kelime Ağırlıkları",
          corpusData: "Korpusu verileri",
          typoLibrary: "Yazım hataları",
        },
        synonymWords: {
          createSynonymWords: "Yeni Eş Anlamlılar",
          editSynonymWords: "Eş anlamlıları düzenleme",
          addSynonymWords: "Eş anlamlılar ekleme",
          pleaseEnterStandardWords: "Lütfen standart kelimeleri girin",
          synonymWordsExists: "Eş anlamlı zaten var ve eklenemiyor",
          synonymWordsCanNotNull: "Eklenen eş anlamlılar boş olamaz",
          pleaseAddSynonymWords: "Lütfen eş anlamlı ekleyin",
          addSuccess: "Yeni başarı",
          editSuccess: "Değişiklikler başarılı oldu",
          deleteSuccess: "Silme başarısı",
          deleteError: "Silme işlemi başarısız oldu",
          required: "*",
        },
        professionalWords: {
          create: "Yeni profesyonel isimler",
          edit: "Profesyonel isim editörü",
          addTargetWords: "Yedek kelime ekleme",
          targetWordsExists: "Değiştirme kelimesi zaten var ve eklenemiyor",
          targetWordsCanNotNull: "Eklenen yedek kelimeler boş olamaz",
          pleaseAddTargetWords: "Lütfen yedek kelime ekleyin",
          pleaseEnter: "Lütfen profesyonel isimler girin",
          wordsExists: "Profesyonel terim zaten var",
          createSuccess: "Başarı ekle",
          editSuccess: "Değişiklikler başarılı oldu",
          deleteSuccess: "Silme başarısı",
          deleteError: "Silme işlemi başarısız oldu",
          saveError: "Başarısız Kaydet",
          addTargetWordsMaxNumberError:
            "Yedek kelimeler 100 adede kadar eklenebilir",
          addHomophonyWordsMaxNumberError:
            "Engelleme kelimeleri en fazla 100 eklenebilir",
        },
        attentionWords: {
          create: "Yeni Takip Sözleri",
          edit: "Kelime Düzenleme Takip",
          pleaseEnter: "Lütfen dikkat kelimesini girin",
          wordsExists: "Söz konusu endişe zaten var",
          createSuccess: "Başarı ekle",
          editSuccess: "Değişiklikler başarılı oldu",
          deleteSuccess: "Silme başarısı",
          deleteError: "Silme işlemi başarısız oldu",
        },
        ignoreWords: {
          create: "Yeni yoksay kelime",
          edit: "Kelime düzenleme yoksay",
          pleaseEnter: "Lütfen yoksay kelimeleri girin",
          wordsExists: "İhmal edilen kelime zaten var",
          createSuccess: "Başarı ekle",
          editSuccess: "Değişiklikler başarılı oldu",
          deleteSuccess: "Silme başarısı",
          deleteError: "Silme işlemi başarısız oldu",
        },
        wordsWeight: {
          createWordsWeight: "Yeni Ağırlık Kelimeler",
          editWordsWeight: "Ağırlık kelimeleri düzenleme",
          addWordsWeight: "Ağırlık sözcükleri ekleyin",
          pleaseEnterWeightWords: "Lütfen ağırlık kelimesini girin",
          pleaseEnterWeight: "Lütfen ağırlığı girin",
          weightNeedInZeroToHundred: "Ağırlık 0 ~ olmalı 100 arasında",
          synonymWordsExists: "Bu ağırlık kelimesi zaten var",
          weightNeedNumber: "Ağırlıklar tamsayı formatı gerektirir",
          standardWeightInformation:
            "Benchmark ağırlığı 50, ağırlık ne kadar büyükse, analizde o kadar güçlü",
          createSuccess: "Başarı ekle",
          editSuccess: "Değişiklikler başarılı oldu",
          deleteSuccess: "Silme başarısı",
          deleteError: "Silme işlemi başarısız oldu",
        },
        corpusData: {
          createCorpusData: "Yeni korpusu verileri",
          editCorpusData: "Korpusu verilerini düzenleme",
          addCorpusData: "Korpusu veri ekleme",
          pleaseEnterCorpusDescription: "Lütfen bir korpusu açıklaması girin",
          pleaseEnterCorpusContent: "Lütfen korpusu içeriğini girin",
          synonymWordsExists: "Bu korpusu verileri zaten var",
          CorpusContentWordsOverSetting:
            "İçerik kelime sayısı 20. 000'den fazla kelime,",
          download: "İndir",
          upload: "Yükle",
          see: "Görüntüle",
          canNotSave:
            "Lütfen içe aktarma işlevini kullanın, aksi takdirde kaydedilemez",
          createSuccess: "Başarı ekle",
          editSuccess: "Değişiklikler başarılı oldu",
          deleteSuccess: "Silme başarısı",
          deleteError: "Silme işlemi başarısız oldu",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Doğru kelime",
            wrongWord: "Yanlış kelime",
            wrongWordCount: "Yanlış kelime sayısı",
            wrongWordTip: "Eklemek için Enter tuşuna basın",
            status: "Geçerlilik",
          },
          create: "Yeni yazım hataları kümesi",
          edit: "Yazım hatası kelime kümesini düzenleme",
          tip: 'Yazım hatası kelime seti, "yazım hatası tespiti" nin etkisini optimize etmek için kullanılır, örneğin, "geri ödeme" yanlışlıkla "geri çekilme" olarak gönderilir, bu işlev, ortak yazım hatalarını ve doğru kelimeleri ayarlamak için kullanılabilir.',
          onlyChinese: "Sadece Çince kelimeleri girmeyi destekler",
          wrongWordLengthErr:
            "Her yanlış kelimenin uzunluğu 2 ~ olmalıdır 10 kelime",
        },
      },
      semanticTags: {
        title: "Anlamsal etiketler",
        current: "Güncel anlamsal etiketler",
        reference1: "Kalite kontrol görevi: {0} - {1}",
        reference2: "Akıllı Etiketler: {0}",
        reference3: "Veri ön işleme: {0}",
        reference4: "Bilgi tabanı: {0}",
        index: { import: "Semantik etiketleri içe aktarma" },
        detail: {
          new: "Yeni semantik etiket",
          edit: "Semantik Etiketleri Düzenle",
          name: "İsim",
          standardSentences: "Standart cümle",
          effectiveness: "Geçerlilik",
          similarSentences: "Benzer cümleler",
          tip: "Varlık API'sini # # ile çağırma desteği, Örnek: Telefon numaranız # sys_phone # mı?",
          excludeSimilarSentences: "Benzer cümleleri hariç tutma",
          intelligentRecommend: "Akıllı Tavsiye Benzer İçerik",
          fastTraining: "Hızlı Eğitim:",
          qualify: "Düzenli",
          recallKeyWord: "Anahtar kelimeleri hatırlama",
          excludeKeywords: "Anahtar kelimeleri hariç tutma",
          excludeKeywordsInfo1:
            "Anahtar kelimeleri ve düzenli hariç tutma, tüm mantığa göre önceliklidir.",
          keyWordInfo1:
            "Geri çağırma anahtar kelimelerinin her satırı, virgülle ayrılmış birden fazla doldurmaya destek verir, birden fazla doldururken mantık tam isabet olur.",
          keyWordInfo2:
            "Geri çağırma anahtar kelimeleri birden fazla satırın doldurulmasını destekler ve birden fazla satırın doldurulmasının mantığı herhangi bir isabet olarak ayarlanabilir.",
          recallRegular: "Düzenli hatırlama",
          excludeRegular: "Düzenli hariç",
          similaSentences: "Benzer cümleleri hariç tutma",
          simim: "Benzerlik",
          keyWordInfo3:
            "Benzer cümleleri hariç tutmak, tüm mantığa göre önceliklidir, benzer cümleleri hariç tutarsanız, semantik etiket vurulmaz, varsayılan benzerlik % 80'dir, benzerlik ne kadar büyükse, benzerlik o kadar benzerdir.",
          keyWordInfo4:
            "Kurallar normal kurallara benzer, başlangıç ve bitiş varsayılan olarak vardır. [XXXX olmayan] gibi parantez içinde kullanılması gerekmeyen",
          keyWordInfo5:
            "Gelişmiş modüllerdeki özellikler genellikle operasyona aşina olan uzmanlar için hazırlanan deneysel özelliklerdir ve bunu nasıl yapmanız gerektiğine karar veremiyorsanız, bu bölümü değiştirmemeniz önerilir.",
          keyWordInfo6:
            "Çapraz cümle algılama işlevi, semantik etiketlere cümleler arasında algılama yeteneği verir.",
          keyWordInfo7: "En fazla aynı karakteri kapsayan",
          keyWordInfo8:
            "Farklı karakter cümlelerin uzunluğundan daha büyük olmadığını göz ardı etmek",
          keyWordInfo9: "Cümle arası cümle algılama",
          keyWordInfo10: "En uzun çapraz cümle sınır cümle daha uzun değil",
          keyWordInfo11: "Benzerlik kaldırma yüzdesi daha az değil",
          keyWordInfo12: "Benzerlik daha az değil",
          keyWordInfo13:
            "% Benzer cümleler benzer eşleşecek (varsayılan benzerlik % 80)",
          keyWordInfo14:
            "Benzerlik, cümlenin benzerliğine göre eşleştirilir, tespit verimliliğini artırmak için çok benzer cümleleri kaldırmanız önerilir.",
          keyWord: "Anahtar kelimeler",
          entity: "Varlık",
          ruleField: "Kavramsal kelime",
          customRule: "Konuşma kuralları tasarımı",
          fanhua: "Benzer sorular",
          test: "Test",
          testOk: "Vur testi",
          senior: "Gelişmiş ayarlar",
          seniorList: "Cümle arası algılama",
          up: "Etkinleştir",
          down: "Kapat",
          ju: "Cümle",
          oneWord: "Bir kelime",
          simimNo: "Benzer ağırlık kaldırma",
          seniorField: "Benzerlik eşiği",
          save: "Kaydet",
          cancel: "İptal",
          specialElements: "Özel elemanlar",
          logicRule: "Mantıksal kurallar",
          every: "Birden fazla maç",
          recallOk: "Vuruş hatırlama kelimesi:",
          recallNo: "İsabetli Geri Çağırma",
          ruleOk: "Vuruş hatırlama düzenli",
          ruleNo: "İsabetsiz Düzenli",
          rularOk: "Vuruş kuralları",
          rularNo: "İsabetsiz kurallar",
          infoOk: "Standart cümleyi veya benzer cümleyi vurun:",
          infoNo: "Standart cümle veya benzer cümle",
          fieldOk: "Benzer cümleler hariç:",
          fieldNo: "Benzer cümleleri dışlamak için başarısız",
          excludeRuleNo: "Düzenli dışlama",
          excludeRuleOk: "Vuruş dışlama düzenli",
          excludeKeywordNo: "Anahtar kelimeler hariç",
          excludeKeywordOk: "Anahtar kelimeleri hariç tutmak için isabet",
          update: "Başarıyla güncellendi",
          fanhuaList:
            "Genelleştirilmiş sayfaya gönderilen benzer cümlelerin listesi",
          wordList:
            "Eğitim listesi boş ve genelleştirilmiş listeye gönderilemiyor",
          start: "Başla",
          end: "Bitiyor",
          keyWordInfo15:
            "Genelleme listesinde benzer alanlar var (Gelişmiş Yapılandırma Benzer Kısıtlamalar)",
          saveSuccess: "Başarıyı kaydet",
          errInfo: "Doldurulmamış veya yanlış doldurulmuş alanlar var",
        },
        table: {
          deepTraining: "Derin Eğitim {0} Tekerlek",
          fastTraining: "Hızlı eğitim",
        },
      },
      intelligentCollection: {
        title: "Akıllı Toplama",
        classification: "Gruplama listesi",
        new: "Yeni toplama",
        table: {
          name: "Alan adı",
          props: "Müşteri özellikleri",
          type: "Alan türleri",
          desc: "Alan açıklaması",
        },
        detail: {
          isCustomerProps: "Müşteri özelliklerine kaydetme",
          customerPropsHelper:
            "Kaydettikten sonra, toplanan bilgiler müşteri bilgilerine güncellenir. Kaydetmezlerse müşteri bilgilerine güncelleme olmaz",
          name: "Alan adı",
          type: "Alan türleri",
          text: "Metin",
          desc: "Alan açıklaması",
          descPlaceHolder: "Bu alanın bilgi edinmenin yararlılığını açıklar",
          condition: "Koşullar",
          logic: "Kurallar",
          method: "Toplama yöntemi",
          message: "Bilgi içeriği",
          range: "Ekstraksiyon aralığı",
          entity: "Varlığı çıkarmak",
          base: "Temel Bilgiler",
          collect: "Bilgi toplama",
        },
      },
      intelligentTags: {
        title: "Akıllı etiketler",
        sessionLabelTitle: "Konuşma Sahnesi",
        keyEventsTitle: "Kritik olaylar",
        customerLabelTitle: "Müşteri Etiketleri",
        addClassification: "Yeni bir etiket kategorisi oluşturun",
        defaultCategoryName: "Varsayılan sınıflandırma",
        index: {
          createTag: "Yeni etiket",
          createEvent: "Yeni olay",
          editTagClassification: "Etiket sınıflandırmasını düzenleme",
          selectTagClassification: "Etiket kategorileri seçin",
          editTagGrounp: "Gruplamayı Düzenle",
          callTimeError:
            "Seçilen konuşma süresi/konuşma başlangıç zaman aralığı bir aydan fazla olamaz (31 gün)",
          callRecordCountError:
            "Kayıt sayısı 0'dır, lütfen arama/konuşma süresini tekrar seçin",
          selectItmeCountError:
            "Lütfen toplu işlem yapmanız gereken akıllı etiketleri seçin",
          redoSuccess:
            "Başarıyla yeniden gerçekleştirin, lütfen Görev Merkezi'nde görüntüleyin",
          redoError:
            "Yeniden yürütme başarısız olur, lütfen daha sonra tekrar deneyin",
          timeError: "Sorgu süresi bir aydan fazla olamaz (31 gün)",
          categoryName: "Sınıflandırma adı",
          categoryColor: "Kategori Renkleri",
          newTagClassification: "Yeni etiket sınıflandırması",
          newEventClassification: "Yeni sınıflandırma",
          tagClassification: "Etiket sınıflandırması",
          eventClassification: "Olayların sınıflandırılması",
        },
        detail: {
          new: "Yeni Akıllı Etiket",
          edit: "Akıllı Etiketleri Düzenle",
          tagName: "İsim",
          tagDesc: "Açıklama",
          category: "Sınıflandırma",
          tagStatus: "Geçerlilik",
          operator: "Operatör",
          rule: "Kurallar",
          labelClassAtion: "Etiket Türü",
          labelClassAtionTip1: "Etiketleme: Kurallara göre etiketleme",
          labelClassAtionTip2:
            "Kategori Etiketler: Model eğitimine dayalı etiket etiketleme",
          group: "Gruplama",
          checkLabel: "Kategoriyi seçin",
          warn: "Erken uyarı",
          warnTips:
            "Uyarıyı açtıktan sonra, oturum bu kritik olay kuralını karşıladığında, belirtilen kişi, yapılacaklar noktasında bir hatırlatma almak için istenir",
          warnError: "Lütfen uyarı atanmış personeli seçin",
        },
        subTitle: {
          common: "Akıllı Oturum Etiketleri",
          wecom: "Kurumsal mikro etiket",
        },
      },
      customerPortrait: {
        title: "Müşteri portreleri",
        index: { import: "Müşteri portrelerini içe aktarma" },
        searchPlaceHolder: "Müşteri kimliği, takma ad veya telefon",
        callCount: "Konuşma sayısı:",
        callDuration: "Toplam arama süresi:",
        dialogCount: "Toplam konuşma sayısı:",
        keyWordsStatistics:
          "Anahtar Kelime İstatistikleri (Top 20 & Endişeler)",
        keyWordsCount: "({0})",
        businessTag: "İş Etiketi",
        keyWordsCloudImg: "Anahtar kelime bulut haritası",
        customerEmotionTrend: "Müşteri duyarlılık eğilimleri",
        notFoundCustomer: "İlgili müşteri alınmadı",
        noKeyWords: "İlgili anahtar kelimeler bulunamadı",
        noBusinessTags: "İlgili işletme etiketi sorgulanmadı",
        getKeyWordsStatisticsDataError:
          "Müşteri anahtar kelime istatistikleri sorgu başarısız",
        getBusinessTagsStatisticsDataError:
          "Müşteri İş Etiketi Sorgulaması Başarısız",
        personCustome: "Müşteri bilgileri sorgulanmadı",
        notimeInfo: "Duygusal bilgi bulunamadı",
        timeList: "Tarih Aralığı",
        operator: "Akıllı etiketler",
        placeHolder: "Lütfen seçin",
        placeOperator: "Lütfen akıllı etiketi seçin",
        search: "Sorgulama",
        reset: "Sıfırla",
        searchHolder: "Arama",
        call: "Çağrı",
        text: "Diyalog",
      },
      sentimentAnalysis: { title: "Kamuoyu keşfi" },
      saleSentimentAnalysis: { title: "Kamuoyu analizi" },
      dataPreprocess: {
        title: "Veri ön işleme",
        index: {
          createData: "Yeni veri oluşturma",
          selectDataSource: "Veri kaynağını seçme",
          selectStatus: "Seçim geçerliliği",
          callTimeError:
            "Seçilen konuşma süresi/konuşma başlangıç zaman aralığı bir aydan fazla olamaz (31 gün)",
          callRecordCountError:
            "Kayıt sayısı 0'dır, lütfen arama/konuşma süresini tekrar seçin",
          selectItmeCountError:
            "Lütfen toplu işlem için gereken verileri seçin",
          redoSuccess:
            "Başarıyla yeniden gerçekleştirin, lütfen Görev Merkezi'nde görüntüleyin",
          redoError:
            "Yeniden yürütme başarısız olur, lütfen daha sonra tekrar deneyin",
        },
        detail: {
          new: "Yeni veri oluşturma",
          edit: "Verileri düzenleme",
          name: "İsim",
          desc: "Açıklama",
          dataSource: "Veri kaynağı",
          status: "Geçerlilik",
          entity: "Varlığı çıkarmak",
          operator: "Operatör",
          rule: "Kurallar",
        },
      },
      connectedByConversation: {
        title: "Diyalog bağlantısı",
        list: {
          title: "Diyalog ilişkilendirme fonksiyonu",
          info: "Diyalog ilişkilendirme işlevi, önceden belirlenmiş kurallara göre farklı aramaları diyaloglarla ilişkilendirebilir",
          rule: "Diyalog bağlantı kuralları",
          source: "Lütfen veri kaynağını seçin",
          classification: "Lütfen kategoriyi seçin",
          choose: "Lütfen seçin",
          input: "Lütfen girin",
          save: "Kaydet",
          cancel: "İptal",
          fieldwei: "Bit",
          fieldNot: "Doldurulmamış alanlar var",
          fiedldlengthNo: "Alan uzunluğu sınırı aşıyor",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Anahtar kelimeler birden fazla ayarlanabilir, virgülle ayrılmış",
          applyRole: "Rolleri tespit et",
          preOperator: "Ön operatör",
          applyOperatorScope: "Algılama aralığı",
          addOperator: "Operatör ekleme",
          noPreOperator: "Hayır, hayır.",
          from: "1.",
          to: "İlk cümle",
          end: "Cümle",
          testRegular: "Düzenli ifadeleri test etme",
          test: "Test",
          matchSuccess: "Maç başarısı",
          matchError: "Maç başarısız oldu",
          querySementicTags: "Semantik etiketleri seçin",
          reQuerySementicTags: "Semantik Etiketleri Yeniden Seçin",
          sementicTag: "Anlamsal etiketler",
          standardSentence: "Standart cümle",
          similarity: "Benzerlik",
          actions: "Operasyon",
          noFitTag: "Doğru etiketi bulamadık,",
          addSemanticTag: "Yeni bir semantik etiket ekle",
          pleaseEnterRegular: "Lütfen düzenli bir ifade girin",
          pleaseEnterRegularText: "Lütfen doğrulama için gerekenleri girin",
          newAssociateComponentTip:
            "Değer bir sayı türü ise eski özellikleri kullanın",
        },
        filterManage: {
          noValueTip: "Veri yok",
          allCall: "Tüm aramalar",
          allDialogue: "Tüm konuşma",
          allWechat: "Tüm oturumlar",
          getFiltersListFailed:
            "Filtre listesini almak başarısız oldu, lütfen daha sonra tekrar deneyin!",
          getFilterDetailFailed:
            "Filtre detayları alınamadı, lütfen daha sonra tekrar deneyin!",
          getAllCountFailed:
            "Toplam arama/konuşma sayısı başarısız oldu, lütfen daha sonra tekrar deneyin!",
        },
        listManage: {
          searchPlaceHolder: "Lütfen anahtar kelime arama girin",
          filterConditions: "Tarama koşulları",
          records: "Kayıt",
          getRecordsFailed:
            "Kayıtları almak başarısız oldu, lütfen daha sonra tekrar deneyin!",
        },
        miniListManage: {
          getRecordsFailed:
            "Kayıtları almak başarısız oldu, lütfen daha sonra tekrar deneyin!",
        },
        applyTemplates: {
          title: "Uygulanabilir kalite kontrol şablonu",
          collectionTitle: "Uygulanabilir toplama şablonları",
        },
        recordDetail: {
          showWaveAudio: "Kaydı göster",
          getDetailFailed:
            "Ayrıntıları almak başarısız oldu, lütfen daha sonra tekrar deneyin!",
          getSmartTagsFailed:
            "Akıllı sekmeleri almak başarısız oldu, lütfen daha sonra tekrar deneyin!",
          getTemplatesFailed:
            "Uygulanabilir şablonu almak başarısız oldu, lütfen daha sonra tekrar deneyin!",
        },
        changeList: {
          sampleStatus: "Örnekleme durumu",
          sampleAssignInspector: "Örnekleme denetçisinin atanması",
          reviewAssignInspector: "Tahsis edilen denetçiler",
          read: "Okunmuş",
          distributivePerson: "Tahsis edilen kişi",
          totalScore: "Toplam puan",
        },
        qualityCheckScore: {
          totalScore: "Toplam puan",
          actualScore: "Skor",
          actualGrade: "Derecelendirme",
          appeal: "İtiraz",
          remark: "Açıklamalar",
          manualRemark: "Yapay notlar",
          none: "Kalite kontrol detayları yok",
          interactiveTip:
            "Müşteri hizmetleri SSS kullanımını tespit etmek için etkileşim tanımlamayı kullanın",
          interactiveIsEmptyTip: "Etkileşim tanımlayıcı içerik algılanmadı",
        },
        semanticMark: {
          semanticContent: "Cümle işaretleme",
          markedSimilarTitel: "Benzer semantik etiketler işaretlenmiş",
          markedExcludeTitle: "Benzer anlamsal etiketlerin hariç tutulması",
          taskTagTitle: "Görev ile ilgili semantik etiketler",
          similarTagTitle:
            "Benzer anlamsal etiketlerin belirlenmesi (benzerlik> % 40, Top 20)",
          delete: "Silme",
          markSimilar: "İşaretleme benzerliği",
          markExclude: "Benzerlikler hariç",
          none: "Hayır, hayır.",
        },
        selectCallTime: {
          callTime: "Konuşma süresi/oturum başlangıç zamanı",
          callTimeRemind:
            "En son bir yıl içinde bir aydan fazla olmayan (31 gün) tek bir konuşma kaydı seçebilirsiniz",
          callRecordCount: "Arama/konuşma kaydı toplam {0}",
          getCallRecordError:
            "Kayıt başarısız oldu, lütfen daha sonra tekrar deneyin",
        },
      },
      informationEntities: {
        title: "Bilgi varlıkları",
        index: { import: "Bilgi varlıklarını içe aktarma" },
        detail: {
          new: "Yeni bilgi varlığı",
          edit: "Bilgi varlıklarını düzenleme",
          name: "İsim",
          apiName: "API adı",
          activeFlag: "Geçerlilik",
          remark: "Açıklama",
          logicExp: "Varlık Düzenli",
          sentenceList: "Fiziksel içerik",
          tip: "Varlık içeriği birden fazla girişi destekler, boşluklarla ayrılır, aynı varlık için eşanlamlı çubuklar",
          remarkLengthError: "Açıklama uzunluğu 255'i geçemez",
          nameLengthError: "İsim uzunluğu 40'ı geçemez",
          entityDuplicateError: "Varlık içeriği çoğaltma",
        },
      },
      interactiveRecognition: {
        title: "Etkileşim tanıma",
        classify: "Sınıflandırma",
        addClassify: "Kategori ekleme",
        editClassify: "Kategori düzenleme",
        index: { import: "İthalat Etkileşim Tanıma" },
        detail: {
          new: "Yeni Akıllı Etkileşim",
          edit: "Akıllı Etkileşimi Düzenleme",
          type: "Türü",
          standardQuestion: "Standart sorular",
          similarQList: "Benzer sorular",
          exceptSimilarQList: "Benzer sorunları hariç tutma",
          category: "Sınıflandırma",
          targetType: "Sorun algılama hedefi",
          status: "Geçerlilik",
          standardAnswer: "Standart cevap",
          similarAList: "Benzer cevaplar",
          wrongAList: "Yanlış cevap",
        },
      },
      businessAnalysis: {
        title: "İş Analizi",
        tip1: "İş eğilimi analizi: koordinatlar, iletişim sayısı, yani koşulları karşılayan toplam iletişim sayısı ve enine koordinatlar zamandır.",
        tip2: "Korelasyon analizi: çoklu göstergelerin korelasyon durumunu analiz edin, fare süspansiyonu korelasyon veri durumunu, veri hacmini, korelasyonu, tekrarlama oranını gösterir",
        tip3: "Dönüşüm analizi: Birden fazla göstergenin analizi karşılıklı bir dönüşüm ilişkisi oluşturabilir, dönüşüm değerlendirme kriteri aynı çağrı/diyalogdır, iki farklı göstergenin vurulabilmesi durumunda, iki göstergenin bir dönüşüm ilişkisi olduğu düşünülebilir.",
      },
      knowledgeBase: {
        title: "Bilgi Bankası",
        list: {
          add: "Alt sınıflandırma ekleme",
          delete: "Kategori silme",
          categoryName: "Sınıflandırma adı",
          createSuccess: "Alt sınıflandırma oluşturma başarısı",
          keyWord: "Anahtar kelimeler",
        },
        detail: {
          type: "Türü",
          status: "Durum",
          classification: "Sınıflandırma",
          standardQuestion: "Standart sorular",
          answerDetectionRange: "Cevap Algılama Aralığı",
          standardAnswer: "Standart cevap",
        },
      },
      callManage: {
        title: "Çağrı Kayıt Filtresi",
        listTitle: "Çağrı yönetimi",
        detailTitle: "Arama Detayları",
      },
      dialogueManage: {
        title: "Konuşma metin filtresi",
        listTitle: "Diyalog yönetimi",
        detailTitle: "Diyalog detayları",
      },
      weChatManage: {
        title: "Kurumsal WeChat Oturum Filtresi",
        listTitle: "Kurumsal WeChat Oturum Yönetimi",
        detailTitle: "Kurumsal WeChat Oturum Detayları",
      },
      speechRecognition: {
        title: "Ses tanıma",
        asr: "Ses tanıma",
        asrRemind:
          "Bu düğmeyi kapatmak, konuşma-metin fonksiyonunun ve makine kalite kontrol fonksiyonunun bozulmasına neden olur",
        asrConfig: "ASR filtreleme",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Etkinleştirildiğinde, IM'in ASR çevirisini çalıştırır",
        asrConfigRemind:
          "Etkinleştirildiğinde, yalnızca uygun aramalar ASR içeriğini gerçekleştirir",
        correction: "Akıllı Hata Düzeltme",
        correctionRemind:
          "Etkinleştirildiğinde, \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words' target =' _ blank'> akıllı eş anlamlılar-profesyonel isimler </a> içeriği kullanılarak zorunlu hata düzeltme dönüşümü yapılır.",
        correctionConfig: "Hata Düzeltme Filtresi",
        advancedSettings: "Gelişmiş ayarlar",
        advancedSettingsTip:
          "Belirtilen veriler için belirtilen ASR modelini seçin.",
        mandarinASRModel: "Mandarin ASR model",
        cantoneseASRModel: "Kanton ASR model",
        englishASRModel: "İngilizce ASR modeli",
        correctionConfigRemind:
          "Etkinleştirildiğinde, yalnızca aşağıdaki kriterleri karşılayan aramalar hata düzeltmesi yapar",
        customJudgeLogicIsNull: "Özel mantık boş olamaz",
        redoCorrection: "Hata düzeltmesini yeniden yürüt",
        callTime: "Konuşma süresini seçin",
        callTimeRemind:
          "Son bir yıl içinde bir aydan fazla olmayan (31 gün) tek bir arama geçmişini seçebilirsiniz",
        callRecordCount: "Toplam {0} arama kaydı seçersiniz",
        redoCorrectionSubmit: "Yürütme için Gönder",
        callTimeError:
          "Seçilen konuşma süresi aralığı bir aydan fazla olamaz (31 gün)",
        callRecordCountError:
          "Arama kaydının sayısı 0'dır, lütfen arama süresini tekrar seçin",
        redoCorrectionSuccess:
          "Yeniden düzeltme başarılı oldu, lütfen görev merkezinde kontrol edin",
        redoCorrectionError:
          "Yeniden hata düzeltme başarısız oldu, lütfen daha sonra tekrar deneyin",
      },
      role: {
        title: "Rol yönetimi",
        create: "Yeni rol",
        detail: {
          title: "Rol",
          new: "Yeni rol",
          view: "Karakterleri görüntüle",
          edit: "Düzenleme rolü",
          navTabNames: {
            basicInfo: "Menü İzinleri",
            taskPermissions: "Kalite kontrol görevi izni",
            dataPermissions: "Veri izinleri",
          },
          basicInfo: { roleName: "İsim", permissions: "İzinler" },
          taskPermissions: {
            allTask: "Tüm kalite kontrol görevleri",
            myCreatedTask: "Yarattığım kalite kontrol görevi",
          },
          dataPermissions: {
            dataPermissionsConfig: "Veri İzinleri Yapılandırması",
            tips: "Bu rol, seçilen kategorideki verileri görebilir",
            select: "Kategoriyi seçin",
            selectPlaceholder:
              "Lütfen veri görünür izinleri gerektiren kategorileri seçin",
          },
        },
      },
      dataMigration: {
        title: "Veri taşıma",
        dataUpload: "Veri taşıma",
        dataDowload: "Veri taşınma",
        error: "Lütfen ihraç edilecek kalite kontrol görevini seçin",
        list: {
          info: "Lütfen taşınacak kalite kontrol görevini seçin",
          listInfo:
            "Lütfen kalite kontrol görevleriyle ilgili verileri (akıllı eş anlamlılar, anlamsal etiketler, bilgi varlıkları, akıllı etkileşimler vb.)",
          noInfo:
            "Lütfen diğer kalite dışı verileri taşımak isteyip istemediğinizi seçin",
          ownInfo: "Sadece görev dışına taşınma",
          everyInfo: "Görevden çıkmayla ilgili",
          allInfo: "Tüm taşınma",
          callFilter: "Çağrı Filtreleri",
          talkFilter: "Konuşma filtreleri",
          smartTag: "Akıllı etiketler",
        },
        upload: {
          info: "1. Yükle düğmesine tıkla ve formatlı JSON dosyalarını yükle",
          uploadSuccess: "Başarılı yükleme",
          uploadError: "Yükleme başarısız oldu",
        },
      },
      dataAnalysis: {
        businessStatistics: "İş İstatistikleri",
        qualityEffect: "Kalite sağlığı",
        qualityAnalysis: "Kalite kontrol analizi",
        generalInspecitionReport: "Evrensel rapor",
        fullAmountWork: "Kalite denetçisinin iş yükü",
        intelligentTagsAnalysis: "Etiket analizi",
        saleIntelligentTagsAnalysis: "Akıllı Etiket Analizi",
        generalInspecitionReportTip:
          "Toplam veri miktarı: mevcut sınırlı zaman ve görev aralığı, toplam arama ve konuşma verileri, akıllı kalite kontrol verilerinin sayısı & yüzdesi, manuel örnekleme verilerinin sayısı & yüzdesi, şikayet miktarı, örnekleme oranı ve zaman eğilimi grafiği",
        groupTest: "Grup testi",
      },
      uploadDownloadList: {
        title: "Görev Merkezi",
        tabs: {
          upload: "İçe aktarma yükleme",
          download: "Dışa Aktarmayı İndirin",
          taskCenter: "Görev yürütme",
        },
      },
      uploadDownloadModel: {
        title: "Yükle indir",
        list: {
          clear: "Boş.",
          success: "Başarı",
          fail: "Başarısızlık",
          hand: "Devam ediyor",
          noInfo: "Veri yok",
        },
      },
      billingCenter: {
        title: "Fatura Merkezi",
        export: "İhracat",
        limit: "Hatırlatma limiti",
        tips: "Faturalandırma sonuçları yuvarlama nedeniyle önyargılı olacaktır, sadece referans içindir",
        dashboard: {
          remainingQuota: "Mevcut kalan kontenjan",
          usedQuota: "Kümülatif kota",
          voiceQuality: "Ses kalite kontrol:",
          voice: "Ses:",
          textQuality: "Metin kalite kontrol:",
          text: "Metin:",
          hour: "Saatler",
          tenThousandHour: "On bin saat",
          day: "-Tanrım.-Tanrım.",
          cutoff: "Son teslim tarihi",
          warning: "(Kalan kota yetersiz, lütfen en kısa sürede şarj edin)",
          isClosed: "(Kapalı durum)",
        },
        table: {
          quotaRecord: "Kota rekoru",
          timeFilter: "Tarihi seçin:",
          search: "Sorgulama",
          reset: "Sıfırla",
          id: "Genel sayı",
          type: "Türü",
          qualityType: "Kalite kontrol tipi",
          quotaChange: "Değişim",
          surplus: "Denge",
          changeTime: "Zaman",
          voiceQuality: "Ses kalitesi",
          textQuality: "Metin kalitesi",
          sizeChangeText: "Sayfa başına ekran",
          used: "Kullanmak",
          give: "Ödül verildi",
          deduction: "Kesinti",
          callTime: "Konuşma süresi",
          count: "Veri hacmini işleme",
          page: "Toplam {0},{1} sayfa",
        },
        limitModal: {
          call: "Ses kalite kontrol kotası düşük",
          callFix: "Hatırlatmak için saat",
          dialogue: "Metin kalite kontrol süresi daha az",
          dialogueFix: "Hatırlatmak için gün",
        },
      },
      messageCenter: {
        replyTitle: "Bana cevap ver",
        answerTitle: "@ Benim",
        shareTitle: "Benim paylaşmak",
      },
      columnsManage: { title: "Kalite kontrol alanı yönetimi" },
      recordUpload: {
        list: {
          task: "Kalite kontrol görevleri",
          user: "Operasyonel kişi",
          uploadTime: "Yükleme süresi",
          endTime: "Bitiş zamanı",
          total: "Toplam yükleme",
          success: "Kalite kontrol başarılı",
          failed: "Kalite kontrol başarısız oldu",
          progress: "Kalite kontrol ilerleme",
          inProgress: "Devam ediyor",
          finished: "Tamamlanmış.",
        },
        steps: {
          uploading: "Kayıt dosyasını yükle",
          baseInfo: "Temel bilgileri doldurun",
          start: "Kalite kontrolüne başlayın",
        },
        uploadComponent: {
          dragger: "Dosya eklemek için sürükle ve bırak",
          or: "Veya",
          selectFile: "Dosya seçimi",
          des: "Destek wav, mp3 formatları, 8000Hz örnekleme hızı, 16 bit dosya",
          limit1: "Bir seferde en fazla yükleme",
          limit2: "Her dosya boyutu aşmayan bir dosya",
          uploading: "Yükle",
          finished: "Yükleme tamamlandı",
          success: "Başarı",
          failure: "Başarısızlık",
        },
        baseInfo: {
          task: "Kalite kontrol görevlerini seçin",
          type: "Kayıt formunu seçin",
          agentSide: "Müşteri hizmetleri kanalı",
          excel: "Yolla veri yükleme",
          checkFileInfo: "Doğrulama dosyası bilgileri",
          excelFileError: "Lütfen yollu verileri yükleyin",
          checking: "Doğrulama...",
          reCheck: "Yeniden doğrulama",
          success: "Başarılı doğrulama",
          excelUoloadFail: "Yol ile veri yükleme başarısız",
          tips: {
            tip1: "1. ecxel'deki kayıt dosyasının adı, yüklenen kayıt adıyla aynı olmalıdır ve yeniden adlandırılamaz",
            tip2: "2. Sistem, kayıt dosyasının adını ve arama kimliğini kontrol eder; bu, tekrarlanan kalite kontrolünün başarısız olmasına neden olur.",
            tip3: "3. excel kayıt şablonunu indirin, kırmızı yazı tipi rengi gerekli bir alandır",
            tip4: "4. Zaman türü için alan formatı: yyyy-mm-dd hh:mm:ss, örneğin 2021-04-20 17:36:30",
          },
        },
        title: "Yerel kayıt yükleme",
        checkTitle: "Kayıt kalitesi",
        start: "Kalite kontrolüne başlayın",
        disabledTips:
          "Kalite kontrol etmeden önce kayıt dosyasını kontrol edin",
        tips: "Aynı isimdeki kayıt dosyası kalite kontrol sonuçlarını ve temel bilgileri kapsar",
        tips1: "Dosyayı buraya sürükleyin veya Yükle 'ye tıklayın",
        tips2: "Destek",
        tips3: "Biçim",
        type: "wav、mp3",
        tips4: "Bir seferde en fazla yükleme",
        tips5: "Her dosya boyutu aşmayan bir dosya",
        tipsOverSize:
          "Kuyruk dolu, lütfen yüklemeden önce dosyaları sıradaki kaldırın",
        size: "500M",
        count: "20",
        uploaded: "Ses kaydı dosyası yüklendi",
        uploading: "({0}%)",
        uploadFinished: "(Yükleme tamamlandı)",
        bottomTitle: "Kayıt bilgi toplama:",
        recordType: "Kayıt formunu seçin:",
        double: "Çift kanallı çağrı kaydı",
        single: "Tek kanallı çağrı kaydı",
        recordBaseInfo: "Kayıt için temel bilgileri yükleyin:",
        addBtn: "Dosya ekleme",
        downloadTemplate: "Şablon indir. excel",
        checkRecord: "Kayıt bilgilerini doğrula",
        checking: "Doğrulama...",
        reCheck: "Yeniden doğrulama",
        checkAll: "Tam seçim",
        cancelCheckAll: "Tam seçimi iptal et",
        delete: "Silme",
        deleteLotSize: "Toplu silme",
        agentChannel: "Müşteri hizmetleri kanalı:",
        left: "Sol kanal",
        right: "Sağ kanal",
        donotKonw: "-Bilmiyorum.-Hayır.",
        mission: "Kalite kontrol görevlerini seçin:",
        uploadFail: "Yükleme başarısız oldu",
        repeat: "Yinelenen dosyalar",
        overCount: "Dosya sayısı sınırı aştı",
        overSize: "Sınırın ötesinde dosya boyutu",
        uoloadFail: "Buluta yükleme başarısız oldu",
        formatError: "Yanlış biçimlendirme",
        seccess: "Başarılı doğrulama",
        fail: "Doğrulama başarısız oldu",
        recordChecking: "Kayıt, daha sonra...",
        recordCheckSuccess: "Kayıt ve kalite kontrol tamamlandı",
        recordCheckFail:
          "Kayıt kalitesi başarısız oldu, lütfen kaydın tekrarlandığını veya geçersiz olduğunu kontrol edin",
        backToIndex: "Yükleme listesine geri dön",
        recordCheckIsStarted:
          "Şu anda kalite kontrol görevleri var, lütfen mevcut görev sona erdikten sonra yeni bir göreve başlayın",
      },
      customerServicePortrait: {
        title: "Müşteri Hizmetleri Portresi",
        tip1: "Cümle uzunluğunun dağılımı: Cümle uzunluğunun dağılımı, cümle uzunluğunun kelime sayısına göre, istatistikler bölümlere ayrılmıştır ve sadece müşteri hizmetleri hesaplanmaktadır.",
        tip2: "Konuşma hızı dağılım yasası: Konuşma hızının dağılımı, konuşma hızına göre (kelime/dakika), bölümlere göre istatistikler, sadece müşteri hizmetlerini hesaplayın",
        tip3: "Tepki süresi dağılım yasası: Tepki süresinin dağılımı, sadece müşteri hizmetlerinin müşteriye yanıt verme durumunu hesaplayın",
        tip4: "Kalite kontrol uyumluluğu: kalite kontrol noktasında uyumluluk oranı",
        tip5: "Kalite kontrol görevinin ortalama puanı: kalite kontrol görevinin ortalama puanı",
        contol: {
          date: "Tarihi seçin:",
          today: "Bugün...",
          thisMonth: "Bu ay.",
          lastMonth: "Geçen ay.",
          thisQuarter: "Bu çeyrek",
          lastQuarter: "Son çeyrek",
          thisYear: "Bu yıl",
          lastYear: "Geçen yıl",
          customer: "Müşteri Hizmetleri:",
          search: "Sorgulama",
          reset: "Sıfırla",
          export: "İhracat",
          indicator: "Gösterge yönetimi",
          selectPlaceholder:
            "Lütfen karşılaştırmanız gereken müşteri hizmetlerini seçin",
          warning: "Karşılaştırma için en fazla 5 müşteri hizmeti seçin",
          warning2: "Lütfen sorularınız için en az 1 müşteri hizmeti seçin",
          dataWarning:
            "Yalnızca başlangıç tarihinden itibaren 366 güne kadar olan verileri sorgulamak için destek",
          seccuss: "Kayıt ihracat başarısı",
        },
        echarts: {
          distributionLaw: "Dağılım kanunu",
          count: "Sayısı",
          bout: "İkinci kez",
        },
        portrait: {
          ability: "Müşteri Hizmetleri Yetenek Boyutu",
          option: "Yapılandırma",
          abilityOption: "Yapılandırma yeteneği boyutları",
          placeholder: "Giriş yeteneği boyut anahtar kelime arama",
          save: "Kaydet",
          warning:
            "Yapılandırmak için en az 3 gösterge seçin, 10 gösterge arasından seçim yapabilirsiniz",
        },
        label: { title: "Müşteri Hizmetleri İş Etiketi" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Müşteri Hizmetleri Sıralaması",
        select: {
          date: "Tarihi seçin:",
          today: "Bugün...",
          thisMonth: "Bu ay.",
          lastMonth: "Geçen ay.",
          thisQuarter: "Bu çeyrek",
          lastQuarter: "Son çeyrek",
          thisYear: "Bu yıl",
          lastYear: "Geçen yıl",
          group: "Müşteri Hizmetleri Grubu:",
          groupPlaceholder: "Lütfen müşteri hizmetleri grubunu seçin",
          customer: "Müşteri Hizmetleri:",
          customerPlaceholder: "Lütfen müşteri hizmetlerini seçin",
          search: "Sorgulama",
          reset: "Sıfırla",
          compared: "Kontrast",
          export: "İhracat",
          column: "Sütun",
          warning: "Karşılaştırma için en fazla 5 müşteri hizmeti seçin",
          warning2: "Lütfen en az bir müşteri hizmeti seçin",
          dataWarning:
            "Yalnızca başlangıç tarihinden itibaren 366 güne kadar olan verileri sorgulamak için destek",
          selected: "Seçilmiş müşteri hizmetleri:",
          seccuss: "Kayıt ihracat başarısı",
        },
        compared: {
          title: "Müşteri hizmetlerini seçin",
          return: "İptal",
          compared: "Kontrast",
          tips: "Lütfen karşılaştırma için 2-5 müşteri hizmeti seçin",
          warning:
            "Karşılaştırma için sadece 2-5 müşteri hizmetini destekleyin",
        },
        transfer: {
          leftTitle: "Liste öğeleri",
          rightTitle: "Gösterim öğeleri",
        },
        table: {
          page: "Toplam {0},{1} sayfa",
          actionTitle: "Operasyon",
          action: "Görüntüle",
        },
      },
      conceptWord: {
        title: "Kavramsal kelime",
        new: "Yeni konsept kelimeler",
        control: {
          search: "Arama:",
          searchPlaceholder: "Ad veya anahtar kelime arama",
          commit: "Sorgulama",
          reset: "Sıfırla",
          import: "İçe aktarma",
          export: "İhracat",
          seccuss: "Kayıt ihracat başarısı",
        },
        modal: { title: "Kavramsal kelimeleri içe aktarma" },
        table: {
          sizeChangeText: "Sayfa başına ekran",
          page: "Toplam {0},{1} sayfa",
          edit: "Düzenleme",
          delete: "Silme",
          request: "Silme işleminden sonra kurtarılamaz. Silme onaylandı mı?",
          cancel: "İptal",
          title: {
            conceptWord: "Kavramsal kelime",
            describeWordsNum: "Kelime sayısını tanımlayın",
            updateTime: "Güncelleme zamanı",
            status: "Geçerlilik",
            effective: "Etkili",
            invalid: "Geçersiz",
            action: "Operasyon",
          },
        },
        detail: {
          title: {
            new: "Yeni konsept kelimeler",
            edit: "Kavramsal kelime düzenleme",
          },
          name: "Kavramsal kelime adı",
          namePlaceholder: "Lütfen kavram kelimesi adını girin",
          effectiveness: "Geçerlilik",
          effective: "Etkili",
          invalid: "Geçersiz",
          description: "Tanımlayıcı kelimeler:",
          descriptionPlaceholder: "Lütfen açıklama kelimesini girin",
          add: "Ekleme",
          repeat: "Yinelenen içerik",
          empty: "Tanımlayıcı kelimeler boş olamaz",
          recommendEmpty:
            "Daha fazla önerilen açıklama kelimesi yok, lütfen manuel olarak ekleyin",
          recommend: "Akıllı tavsiye açıklama kelimeleri",
          cancel: "İptal",
          change: "Bir set değiştir",
          descriptionSelected: "Açıklayıcı kelimeler eklendi:",
          save: "Kaydet",
          success: "Kavramsal kelime başarı kaydeder",
          warning: "Kavramsal kelime adı boş olamaz",
          warning2: "Lütfen en az 1 açıklama kelimesi ekleyin",
          submitBtnHandle: {
            title:
              "Eklenmemiş bir açıklama sözcüğünü işaretleyin, kaydetme eklenmemiş açıklama sözcüğünden vazgeçecektir",
            content:
              "Tamam'a tıklamak, devam etmeyi onaylamak için konsept kelimenin ana sayfasına geri dönecek mi?",
          },
        },
      },
      smartTools: {
        btn: "Akıllı Araçlar",
        title: {
          similarWords: "İlgili Kelime Keşif",
          search: "Sözcüğe göre",
          semanticTags: "Anlamsal etiketler",
        },
        sentenceSearch: {
          placeholder:
            "Lütfen anahtar kelime aramaları girin, birden fazla anahtar kelimeyi destekleyin",
          more: "Tam metni görüntüle",
          pageUp: "Önceki sayfa",
          pageDown: "Sonraki sayfa",
          total: "Toplam {0} şerit kaydı",
        },
        similarDiscover: {
          placeholder: "Lütfen anahtar kelimeleri girin",
          copyed: "Kopyalandı",
          none: "İlgili bir kelime yok, lütfen başka bir anahtar kelime deneyin",
        },
        smartTags: {
          searchPlaceHolder: "Lütfen bir anahtar kelime veya cümle girin",
          createSmartTag: "Yeni",
          createClusteringSmartTag: "{0} veri çubuğu ile yeni",
          addFromKeyword: "Benzer cümleye arama ekleme",
          hit: "Vuruş",
          similar: "Benzer",
          name: "Anlamsal Etiket Adı",
          namePlaceHolder: "Semantik etiket adını girin",
          recallWords: "Geri çağırma",
          recallWordsPlaceHolder:
            "Lütfen geri çağırma anahtar kelimelerini girin",
          recallRegular: "Düzenli hatırlama",
          recallRegularPlaceHolder: "Lütfen geri çağırma kodunu girin",
          rules: "Kurallar",
          standardSentences: "Standart cümle",
          standardSentencesPlaceHolder: "Lütfen standart cümle girin",
          similaSentences: "Benzer cümleler",
          similaSentencesPlaceHolder: "Lütfen benzer cümleler girin",
          notSaveError: "Bulmadan önce semantik etiketi kaydet",
          add: "Ekleme",
          newPage: "Yeni bir sayfada aç",
          querryError:
            "Genelleme listesinde benzer alanlar var (Gelişmiş Yapılandırma Benzer Kısıtlamalar)",
          detailTitle: "Anlamsal Etiket Ayrıntıları",
        },
      },
      trainingCenter: {
        title: "Eğitim Merkezi",
        edit: "Düzenleme",
        delete: "Silme",
        seleteAll: "Tam seçim",
        cancelCheckAll: "Tam seçimi iptal et",
        add: "Ekleme",
        remove: "Kaldır",
        save: "Kaydet",
        cancel: "İptal",
        fastTraining: {
          title: "Hızlı eğitim",
          titleDes:
            "Hızlı eğitim, anlamsal etiketlerin 20 benzer cümleye hızla genelleştirilmesine yardımcı olmayı amaçlamaktadır",
          semanticTags: "Anlamsal etiketler",
          semanticTagsPlaceholder: "Lütfen giriş işlemi anahtar kelime arama",
          standardSentence: "Standart cümle:{0}",
          similarSentencesPlaceholder:
            "Eklemek veya aramak için benzer bir cümle girin",
          similarSentencesAdd: "Ekleme",
          similarSentencesSearch: "Arama",
          similarSentences: "Benzer cümleler eklendi",
          trainingCount: "Eğitim Sayısı:{0}",
          similarSentencesRecommend: "Benzer cümleler önerin",
          noResp:
            "İlgili semantik etiket yok, lütfen anahtar kelimeleri değiştirin",
          similarSentencesModalTitle: "Benzer cümle arama",
          moreInfo: "Bağlamı görüntüle",
          moreInfoBtn: "Bağlam",
          modalSearchType: {
            all: "Hepsi...",
            customer: "Müşteri Hizmetleri",
            client: "Müşteri",
          },
          repetWarning: "Aynı benzer cümle zaten var, tekrar etmeyin",
          repetRemoveTip:
            "Benzer cümlelerde yinelenen içerik kaydedilir ve otomatik olarak yeniden yüklenir",
        },
        trainingHistory: {
          title: "Eğitim Tarihi",
          select: {
            date: "Seçim süresi:",
            today: "Bugün...",
            thisMonth: "Bu ay.",
            lastMonth: "Geçen ay.",
            thisQuarter: "Bu çeyrek",
            lastQuarter: "Son çeyrek",
            thisYear: "Bu yıl",
            lastYear: "Geçen yıl",
            search: "Sorgulama",
            reset: "Sıfırla",
          },
          table: {
            time: "Zaman",
            user: "Operasyonel kişi",
            tag: "Anlamsal etiketler",
            similarCount: "Benzer cümlelerin sayısı",
            deleteCount: "Kaldırma cümlelerinin sayısı",
            page: "Toplam {0},{1} sayfa",
          },
        },
        classifiedTraining: {
          title: "Sınıflandırma eğitimi",
          tagsGrounp: "Etiket Gruplandırma",
          placeHolder: "Lütfen seçin",
          cellTime: "Zaman seçimi",
          search: "Sorgulama",
          reset: "Sıfırla",
          classifiedFlag: "Sınıflandırma eğitimi",
          num: "Sayısal değer",
          gas: "Yüzde",
          noData: "Sınıflandırılmamış eğitim",
          noDataHeight:
            "Kategorilenmemiş veriler nispeten yüksektir ve eğitime acil ihtiyaç vardır",
          noDataSmall:
            "Kategorilenmemiş veriler nispeten yüksek, eğitim önerilir",
          noDataDays: "Son yürütme {0} gün önce",
          training: "Eğitim",
          start: "Başla",
          addClass: "Artan sınıflandırma",
          custom: "Gelişmiş Tarama",
          marked: "İşaretli Veri Hatası",
          markedHeight:
            "Zaten {0} günde bir hata yapılmadı, bir hata yapılması önerilir",
          markedSmall:
            "Zaten {0} günde bir hata yapılmadı ve acilen bir hata yapılması gerekiyor.",
          mark: "Hata",
          dataCheck: "Sınıflandırılmış Veri Doğrulama",
          dataCheckSmall: "0. 5'ten yüksek K-L ayrışması, önerilen eğitim",
          dataCheckHeight: "K-L 0. 8'den yüksek, acil eğitim ihtiyacı",
          check: "Doğrulama",
          history: "Tarih etiketleme",
          historyTime:
            "Günlük etiketleme ve değiştirme otomatik olarak saat 2: 00'de model eğitimi gerçekleştirir",
          today: "Bugün etiketleme",
          article: "Madde",
          none: "Hayır, hayır.",
          historyCheck: "Geçmiş toplam etiketleme",
          look: "Kayıtları görüntüleme",
          historyluate: "Tarihsel veri yeniden hesaplama",
          startluate: "Yeniden hesaplama",
          classData: "Kategori verileri",
          classStatus: "Eğitim ilerlemesi",
          next: "Bir sonraki",
          prev: "Bir önceki",
          finish: "Eğitimin tamamlanması",
          stop: "Eğitimin sonu",
          classDetail: "Eğitim Detayları",
          trainingNone: "Eğitim için akıllı etiket yok",
        },
        classifiedTrainingHistory: {
          title: "Eğitim Tarihi",
          time: "Seçim süresi:",
          tag: "Etiket Türü:",
          user: "Operatör:",
          data: "Veri kaynağını seçin:",
          search: "Sorgulama",
          searchBar: "Arama:",
          searchBarPlaceholder: "Lütfen arama kimliği/konuşma kimliği girin",
          reset: "Sıfırla",
          export: "İhracat",
          all: "Hepsi...",
          select: {
            today: "Bugün...",
            thisMonth: "Bu ay.",
            lastMonth: "Geçen ay.",
            thisQuarter: "Bu çeyrek",
            lastQuarter: "Son çeyrek",
            thisYear: "Bu yıl",
            lastYear: "Geçen yıl",
            search: "Sorgulama",
            reset: "Sıfırla",
          },
          table: {
            user: "Operatör",
            date: "Çalışma süresi",
            data: "Veri kaynağı",
            id: "Çağrı Kimliği/Konuşma Kimliği",
            tagType: "Akıllı Etiket Gruplandırma",
            tagName: "Etiket adı",
            state: "Durum",
            sizeChangeText: "Sayfa başına ekran",
            page: "Toplam {0},{1} sayfa",
            call: "Çağrı",
            dialogue: "Diyalog",
          },
        },
        clusteringAnnotation: {
          title: "Keleme etiketleme",
          left: { title: "Keleme sonuçları" },
          right: {
            title: "Kümeleme cümleleri",
            checkAll: "Bu sayfanın tamamını seçin",
            tagging: "Etiketleme",
            detail: "Görüntüle",
            meaningless: "Görmezden gelin",
            meaninglessTips:
              "İhmal edilen kümeleme sonuçları artık gösterilmeyecek, göz ardı etmek istediğini onaylıyor musunuz?",
            cancel: "İptal",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Müşteri yönetimi",
          tabTitle: "Müşteri Hizmetleri Merkezi",
        },
        wechatClientManage: {
          title: "Kurumsal mikro müşteriler",
          table: {
            id: "Müşteri Kimliği",
            name: "Müşteri takma adı",
            tag: "Müşteri Etiketleri",
          },
        },
      },
      collectionRule: {
        title: "Toplama kuralları",
        form: {
          info: "Akıllı bilgi toplama",
          infoPlaceholder: "Lütfen kategoriyi seçin",
        },
        table: {
          name: "Alan adı",
          props: "Müşteri özellikleri",
          type: "Alan türleri",
          desc: "Alan açıklaması",
        },
      },
      dataAdd: {
        title: "Veri ekleme",
        types: { auto: "Otomatik ekleme", manual: "Manuel olarak ekleme" },
      },
      wechatStaff: {
        table: {
          name: "İsim",
          part: "Bölüm",
          phone: "Telefon numarası",
          email: "Posta kutusu",
          wechartId: "Kurumsal WeChat ID",
          status: "Görev Durumu",
        },
        info: "Verileri senkronize etmek istediğiniz bölümü seçin",
        synSuccess: "Senkronizasyon başarısı",
        init: "Başlatmak",
        syn: "Senkronizasyon",
      },
      appealHistory: {
        appealTime: "İtiraz süresi",
        cancelTime: "İptal süresi",
        reviewTime: "İnceleme süresi",
        inspector: "Kalite denetçisi",
        result: "İtiraz sonucu",
        complainResult:
          "Toplam {0} kalite kontrol maddesi temyiz edildi, {1} başarıyla temyiz edildi ve {2} reddedildi",
        reason: "Şikayet nedeni",
        rejection: "Reddedilen sebep",
        cancel: "İptal",
        tip: "İtirazın geri çekileceğini onaylıyor musunuz?",
      },
      appealSdk: {
        time: "Konuşma süresi",
        externalIdCall: "Arama kaydı kimliği",
        externalIdText: "Konuşma Kayıt Kimliği",
        customerNickName: "Müşteri takma adı",
        readStatus: "Durumu kontrol et",
        status: "İtiraz durumu",
        isManual: "Yapay kalite kontrolü",
        complainButtonInfo:
          "{0} kez temyiz edilmesine izin verilir, {1} kez temyiz edildi ve kalan {2} kez temyiz edilebilir. {3} önce itiraz",
        submit: "Şikayet gönderin",
        complain: "İtiraz",
        allComplain: "Tüm şikayetler",
        pendingSubmit: "Gönderilecek",
        taskIds: "Kalite kontrol görevleri",
        graded: "Derecelendirme",
        complainSuccess: "Temyiz başvurusu başarılı oldu",
        appealSdkIndex: { title: "Akıllı kalite kontrol sonuçları" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Filtre listesi bilgilerini almak başarısız oldu, lütfen ağ bağlantısını kontrol edin veya daha sonra tekrar deneyin!",
      columns: "Sütun",
      filter: "Tarama",
      noSearchResult: "Kayıtlara rastlamadık",
      pleaseSearch: "Bir filtre seçin ve arama deneyin",
      sift: "Tarama",
      create: "Yeni",
      search: "Arama",
      createTaskTitle: "Yeni görev",
      title: "Başlık",
      deadline: "Son teslim tarihi",
      principal: "Sorumlu kişi",
      enqueueTime: "Takım zamanı",
      computeError: "Hesaplama başarısız, lütfen daha sonra!",
      sum: "Arayama",
      max: "Maksimum değer",
      min: "Minimum değer",
      avg: "Ortalama değer",
      count: "Sayma",
      maxDate: "Maksimum (en son)",
      minDate: "Minimum (en eski)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Insight bağlantı adresini alamıyorsanız, lütfen daha sonra tekrar deneyin!",
      },
      template: {
        learningReminder: "Hatırlatmayı öğrenin",
        subscriptionDynamics: "Abonelik dinamikleri",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Görev yönetimi", chaneg: "Geçiş yapma" },
        qualityCheckTemplate: {
          text: "Kalite kontrol şablonu",
          collectText: "Toplama şablonları",
          condition: { text: "Kalite kontrol koşulları" },
          checkPoint: { text: "Kural yapılandırması" },
          automaticAdd: { text: "Süreç otomasyonu" },
          manualAdd: { text: "Manuel olarak ekleme" },
          roleConfig: { text: "Rol Yapılandırması" },
          basicInfo: { text: "Temel Bilgiler" },
          smartWordsLibrary: { text: "Akıllı Eş Anlamlılar Sözlüğü" },
          grade: { text: "Kalite değerlendirmesi" },
          collectionRule: { text: "Toplama kuralları" },
          addData: { text: "Veri ekleme" },
          collectionList: { text: "Toplama listesi" },
        },
        qualityCheckWorkbench: {
          text: "Kalite kontrol çalışma masası",
          wechatText: "Analiz çalışma masası",
          informationCollectText: "Toplama Çalışma Masası",
          qualityInspectionList: {
            text: "Kalite kontrol listesi",
            wechatText: "Analiz listesi",
            informationCollectText: "Toplama listesi",
          },
          condition: { text: "Kalite kontrol listesi (terk edilmiş)" },
          reviewList: { text: "İnceleme listesi" },
          spotCheckList: { text: "Örnekleme listesi" },
          caseBaseList: { text: "Vaka kütüphanesi" },
          testSetList: { text: "Kalite kontrol test seti" },
        },
        statisticsAndReports: {
          text: "İstatistikler ve Raporlama",
          wholeReport: { text: "Genel rapor" },
          groupReport: { text: "Müşteri Hizmetleri Grubu Raporu" },
          agentReport: { text: "Müşteri Hizmetleri Raporu" },
          customerServiceExecution: { text: "Müşteri Hizmetleri Analizi" },
          analysisOverview: { text: "Analizlere genel bakış" },
          inspectionItemsAnalysis: { text: "Kalite kontrol öğesi analizi" },
        },
        wechatQa: {
          text: "Kurumsal WeChat kalite denetimi",
          task: { text: "Analiz görevleri" },
          baseInfo: { text: "Temel Bilgiler" },
          checkPoint: { text: "Model yapılandırmasını analiz edin" },
          setting: { text: "Kurumsal WeChat Analitik Yapılandırma" },
          automaticAdd: { text: "Süreç otomasyonu" },
          manualAdd: { text: "Manuel olarak ekleme" },
        },
        clientCenter: { text: "Müşteri Hizmetleri Merkezi" },
      },
      conditionDictionaries: {
        title: "Tüm koşullar",
        searchDefaultText: "Lütfen kimliğinizi veya adınızı girin",
        id: "ID",
        name: "İsim",
        conditionDetailedShow: "Genişler",
        conditionDetailedHide: "Kal onu.",
        remark: "Açıklama:",
        checkRole: "Rol:",
        operator: "Operatör:",
        operatorLogic: "Mantık:",
      },
      audioTextView: {
        customer: "Müşteri",
        agent: "Müşteri Hizmetleri",
        unsupportedFormat: "Format şu an için desteklenmiyor",
      },
      checkPointClassifications: {
        add: "Kategori eklendi",
        name: "İsim",
        enterToSave: "Kaydetmek için Enter tuşuna basın",
        highestScore: "Maksimum puan sınırı",
        minimumScore: "Minimum puan sınırı",
        defaultMinScore: "Varsayılan 0 puan",
        minimumScoreTitle: "Alt puanlama sınırı",
        beginScore: "Genel başlangıç değeri",
        beginScoreTitle: "Puanlama değeri",
        scoreSetTip:
          "Maksimum puan sınırı, her bir puanın toplamından daha azdır ve şunları seçebilirsiniz:",
        ignoreExcessSpillover: "Aşırı taşmayı görmezden gelin",
        automaticWeightCalculation: "Otomatik ağırlık muhasebesi",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Alanlar" },
    checkRange: {
      numberTipOne: "1.",
      numberTipTwo: "Cümle",
      numberTipThree: "İlk cümle",
      conditionLimit: "Koşullu kısıtlamalar",
      roleLimit: "Rol Sınırları",
      contextLimit: "Cümle sınırı",
      notZero: "Cümle sınırı 0 girişi desteklemiyor",
      startMustLessThanEnd:
        "Başlangıç cümlesinin konumu, bitiş cümlesinin konumundan daha küçük olmalıdır.",
    },
    waveAudio: {
      play: "Oynat",
      pause: "Duraklat",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Lütfen girin",
      pleaseEnterRegular: "Lütfen düzenli bir ifade girin",
      pleaseEnterGrammar: "Lütfen gramer kurallarını girin",
      pleaseEnterTestText: "Lütfen doğrulama için gerekenleri girin",
      pleaseEnterLoudness: "Lütfen ses yüksekliği desibel girin",
      pleaseEnterRangeAbility: "Lütfen değişim aralığını girin",
      afterHit: "Vuruş sonrası kontrol",
      pleaseEnterContext: "Lütfen referans ifadesini girin",
      wordNumber: "Kelime farkı",
      intervalSecond: "Zaman aralığı daha büyük",
      minute: "Dakikada",
      wordUnit: "Bir kelime",
      test: "Test",
      testRegular: "Düzenli ifadeleri test etme",
      testGrammar: "Test dilbilgisi",
      testGrammarError: "Gramer testi başarısız oldu",
      regularError: "Normal ifade hatası, lütfen tekrar girin",
      pleaseEnterSemanticSentence:
        "Anlamsal algılama gerektiren cümleler girin",
      pleaseEnterAssociate:
        "Yol boyunca veri ifadeleri oluşturmak için lütfen sağdaki açıklamaya bakın",
      querySemanticTags: "Semantik etiketleri seçin",
      reQuerySemanticTags: "Semantik Etiketleri Yeniden Seçin",
      semanticTags: "Anlamsal etiketler",
      standardSentence: "Standart cümle",
      semanticName: "Anlamsal etiketler",
      sentence: "Standart cümle",
      similarity: "Benzerlik",
      actions: "Operasyon",
      tipOne: "Doğru etiketi bulamadık,",
      tipTwo: "Yeni bir semantik etiket ekle",
      notLessThan: "Daha az değil",
      notGreaterThan: "Daha büyük değil",
      ignoreLessThan: "Daha az ihmal",
      wordUnitSet: "Kelimelerin cümleleri",
      speedWordNotNull:
        "Konuşma hızı algılama En az kelime ve en fazla kelime boş olamaz",
      speedWordMinValueLessThanMaxValue:
        "Konuşma hızı algılama Minimum kelime sayısı en fazla kelime sayısından büyük olamaz",
      speechGrabbingIntervalTip: "Aralığı kapmak> =",
      speechLengthTip: "Cümle uzun> =",
      msUnit: "ms",
      sUnit: "Saniye",
      grabIntervalNotNull: "Konuşan aralık boş olamaz",
      grabLengthNotNull: "Uzun kelimeler boş olamaz",
      pleaseSelectEntity: "Lütfen bir bilgi varlığı seçin",
      pleaseSelectOperator: "Lütfen eylemi seçin",
      pleaseSelectBasicInfo: "Lütfen temel bilgileri seçin",
      pleaseEnterFixedValue: "Lütfen sabit bir değer girin",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Maç başarısı",
      matchError: "Maç başarısız oldu",
      mindetectionStatement: "Minimum algılama ifadesi",
      conceptWordholder: "Lütfen Kavramsal Kelime Seçin",
      elementHolder: "Lütfen bir eleman girin",
      associateMedol: { tabs: { data: "Veri", operator: "Operatör" } },
      repet: "Tekrar ediyorum.",
      bout: "İkinci kez",
      ignore: "Daha az ihmal",
      ignoreAffix: "Kelimelerin cümleleri",
      associateData: "Yol verileri",
      pretreatmentData: "Verileri ön işleme",
      reviewTaskData: "Görev verilerini gözden geçirme",
      taskData: "Kalite kontrol görev verileri",
      businessData: "İş kayıtları",
      functionOperator: "İşlev Operatör",
      operation: "Operatör",
      notAnyMatch: "Herhangi bir sorunu kaçırırken:",
      times: "Görünme sayısı",
      newAssociateDataTip: "Değer bir sayı türü ise eski özellikleri kullanın",
    },
    conditionGather: {
      cycle: "Döngüsü",
      rlue: "Kurallar",
      delete: "Kaldır",
      sampling: "Ekstraksiyon",
      item: "Madde",
      percent: "%",
      samplingSame: "Müşteri hizmetleri başına aynı sayıda",
      samplingRecheck:
        "Örnekleme girişlerinin tekrar tekrar çekilmesine izin ver",
      dateUnit: "Hayır.",
      samplingTip:
        "Aynı çağrıyı kapsayan farklı kurallar tekrarlanmayacak ve kontrol edilmeyen kayıtlar tekrar çekilmeyecektir.",
      add: "Yeni kural eklendi",
    },
    ruleGather: {
      title: "Kurallar",
      screeningCondition: "Tarama koşulları",
      addRule: "Yeni kural eklendi",
      inspector: "Kalite denetçisi",
      ruleTip:
        "Yukarıdan aşağıya doğru atanmış, tekrarlanan atamaları olmayan, kural içindeki veya herhangi bir kural öğesini eşleştirmeyen kural öncelikleri rastgele dağıtılır",
      moveUp: "Yukarı hareket",
      moveDown: "Aşağı in",
      top: "Üst",
    },
    pushRulesGather: {
      title: "Kurallar",
      delete: "Kaldır",
      newPush: "Yeni push",
      newRule: "Yeni kural eklendi",
      sampling: "Ekstraksiyon",
      item: "Madde",
      percent: "%",
      samplingSame: "Müşteri hizmetleri başına aynı sayıda",
      dateUnit: "Hayır.",
      screeningCondition: "Tarama koşulları",
      email: "Posta kutusu",
      emailPlaceholder: "Posta kutusunu girin, virgülle bölün",
      updateType: "Güncelleme türü",
      pushDetail: "Push Detayları",
      pushUrl: "Push URL",
      pushWay: "İtme türü",
      pushMethod: "İtme yöntemleri",
      pushContentType: "Veri formatları",
      pushData: "Verileri itin",
      additionalData: "Ek veri",
      inspectionType: "Kalite kontrol tipi",
      all: "Hepsi...",
    },
    taskAdditionalData: {
      newData: "Yeni veri",
      delete: "Kaldır",
      placeholder: "Lütfen bir ad girin",
      dataPlaceholder: "Lütfen içeriği girin",
    },
    UdeskQaReact: {
      loadSdkError: "SDK yüklenemedi, lütfen daha sonra tekrar deneyin!",
    },
    gearOptionList: { evaluation: "Değerlendirme" },
    tableComponentTransfer: {
      leftTitle: "Liste öğeleri",
      rightTitle: "Gösterim öğeleri",
    },
    kmTinymce: {
      link: "Bağlantı ekleme/düzenleme",
      modalTitle: "Bağlantı ekle",
      href: "Adres",
      text: "Metni göster",
      title: "Başlık",
      target: "Yolu aç",
      none: "Hayır, hayır.",
      newWindow: "Yeni pencerede açılır",
      buttonStyle: "Düğme Stilleri",
      close: "Kapat",
      open: "Açık",
      answerMessage: "Atlama/Mesaj Gönder",
      answerMessageTitle: "Mesaj gönder düğmesi",
      answerMessageText: "Metni göster",
      answerMessageContent: "Mesaj içeriği",
      answerMessageButtonStyle: "Düğme Stilleri",
      answerMessageTips:
        "Not: Mesaj gönder düğmesi sadece web kanallarını destekler, lütfen dikkatlice yapılandırın",
      answerSwitchStaff: "Sıçrama/Sıçrama",
      answerSwitchStaffTitle: "Yapay düğmeye dön",
      answerSwitchStaffText: "Metni göster",
      answerSwitchStaffRule: "Düğme Kuralları",
      answerSwitchStaffButtonStyle: "Düğme Stilleri",
      answerSwitchStaffTips:
        "Not: manuel düğme yalnızca web kanallarını destekler, lütfen dikkatlice yapılandırın",
      textButton: "Metin düğmesi",
      borderButton: "Çerçeveli düğme",
      message: "Mesaj gönder",
      switchStaff: "İşçilik",
      messageError: "Düğme içeriği 10 kelimeden fazla olamaz",
      messageIsEmptyError: "Düğme içeriği boş olamaz",
      okText: "Emin olmak için",
      cancelText: "İptal",
      video: "Video",
      uploadVideo: "Video yükle",
      uploadText: "Yalnızca MP4 formatını destekler, boyutu 20M 'yi aşmaz",
      videoEmptyOrUploading: "Video boş veya yükleme",
      customEmoji: "İfade",
    },
    massageConfiguration: {
      pushTarget: "Hedefi bildirme",
      customizePushTarget: "Özel push hedefleri",
      sysUser: "Mevcut sistem kullanıcıları",
      sysRole: "Mevcut sistem rolü",
      emailPushTarget: "Hedef gönder",
      targetEmailsPlaceholder: "Lütfen posta kutusunu girin ve virgülle ayırın",
      subject: "Posta Adı",
      SDKID: "SDK Kimlik Kimliği",
      content: "Bildirim içeriği",
      sdkPushType: "Push şeması",
      sdkPushURL: "Push URL",
      pushData: "Verileri itin",
      sdkPushWay: "İtme yöntemleri",
      dataType: "Veri formatları",
      additionalData: "Ek veri",
      name: "Mesaj adı",
      filter: "Tarama koşulları",
      triggerType: "Tetikleme mekanizması",
      pushCycle: "Zamanlama sabit frekans",
      pushContent: "Rapor içeriği",
      qualityList: "Kalite kontrol sonuçları listesi",
      designated: "Belirlenen personel",
      messageType: "Mesaj türleri",
    },
    taskCard: {
      template: {
        cancelCollection: "Koleksiyonu iptal et",
        collection: "Koleksiyon",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Standart cümle:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Koleksiyon",
          calls: "Bir çağrı",
          createdBy: "Oluşturan:",
          createdOn: "Oluşturma zamanı:",
        },
        fileItem: {
          uIProvidesIcon: "Ui icon sunuyor",
          minute: "Dakika",
          callClip: "Çağrı klipleri",
          start: "Başla",
          End: "Bitiyor",
          employees: "Çalışanlar:",
          Customer: "Müşteri:",
          sessionTime: "Oturum süresi:",
          reasonForRecommendation: "Önerilen nedenler:",
          addedBy: "Ekleyen:",
          addTime: "Zaman ekle:",
        },
        modalAddFile: {
          caseTitle: "Dava başlığı",
          addSomeClipsToTheCaseLibrary:
            "Kısmi parçaları vaka kitaplığınıza ekleyin",
          addToCaseBase: "Vaka kütüphanesine ekle",
          storedIn: "Depolama",
          pleaseSelect: "Lütfen seçin",
          reasonsForRecommendation: "Tavsiye edilen nedenler",
          pleaseEnter: "Lütfen girin",
        },
      },
      shareModal: {
        index: {
          customer: "Müşteri:",
          employees: "Çalışanlar:",
          timeliness: "Zamanında:",
          recordingLinkValidWithinDays:
            "Kayıt bağlantısı gün içinde geçerlidir",
          sharingInstructions: "Paylaşım açıklaması:",
          shareSuccess: "Başarıyı paylaş!",
          cancel: "İptal",
          share: "Paylaş",
          copySucceeded: "Kopyalama başarılı oldu!",
          copyShareLink: "Paylaşım bağlantısını kopyala",
          shareWithColleagues: "İş arkadaşlarına paylaş",
          shareWithCustomers: "Müşterilere paylaş",
          staff: "Çalışanlar",
          selectColleagues: "İş arkadaşlarınızı seçin",
          pleaseSelectColleaguesToShare:
            "Lütfen paylaşmak istediğiniz meslektaşlarınızı seçin",
          pleaseEnterTheSharingDescription:
            "Lütfen paylaşım talimatlarını girin",
          taskName: "Görev:",
          recordingShareValidWithinDays: "Paylaşım linki gün içinde geçerli",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "O dosya bulunamadı!",
          sale: "Satış:",
          customer: "Müşteri:",
          topicDetection: "Konu algılama:",
          keyEvents: "Anahtar olaylar:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "İş arkadaşlarınızı seçin",
          helpContent: "Yardım içeriği",
          visibleRange: "Görünür aralık",
          seekCounseling: "Danışmanlık Aramak",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Tavsiye isteyin", concatText: "向" },
      },
      customerInsight: {
        index: {
          customerInformation: "Müşteri Bilgileri",
          customerInsight: "Müşteri içgörüleri",
          customerLabel: "Müşteri Etiketleri",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Konuşma önerisi",
          objectionHandlingScripts: "İtiraz işleme",
          productSellingPointScripts: "Ürün satış noktası",
          knowledgePointRecommendation: "Bilgi noktası önerisi",
          relatedKnowledgePoints: "Bilgi noktalarını ilişkilendirmek",
          recommendedCases: "Tavsiye edilen dava",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Konuşma önerisi",
          caseRecommendation: "Vaka önerisi",
          hit: "Vuruş",
          miss: "Vurmadı",
          supervisionItem: "Denetim maddesi",
          implementation: "Uygulama",
          reInspection: "Yeniden kalite denetimi",
          supervisionItemSettings: "Denetim öğesi ayarları",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Telefon görüşmesi",
          enterpriseWeChatCall: "Kurumsal mikro konuşma",
          enterpriseMicroConversation: "Kurumsal mikro oturum",
          open: "Açık",
          close: "Kapat",
          conversationScenario: "Konuşma Sahnesi",
          monitorConversationChannels: "Oturum kanallarını denetleyin",
          permanentlyValid: "Kalıcı ve etkili",
          permanentlyInvalid: "Kalıcı olarak geçersiz",
          customValidity: "Özel geçerlilik süresi",
          termOfValidity: "Geçerlilik süresi",
          newRating: "Yeni derecelendirme",
          whenConversation: "Ne zaman oturum",
          hit: "Vuruş",
          miss: "Vurmadı",
          time: "Ne zaman",
          rated: "Derecelendirme",
          qualified: "Nitelikli",
          unqualified: "Niteliksiz",
          modifiedSuccessfully: "Değişiklikler başarılı oldu",
          classificationName: "Sınıflandırma adı",
          supervisionObject: "Denetim nesnesi",
          entryintoforceTime: "Yürürlük zamanı",
          supervisionItemRating: "Denetim öğesi derecelendirme",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Lütfen denetim öğesinin derecelendirmesinin doğru olup olmadığını kontrol edin",
          ratingRules: "Derecelendirme kuralları",
          settingOfRatingRulesForSupervisionItems:
            "Denetim öğesi derecelendirme kuralı ayarı",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Kritik olaylar",
          supervisionItem: "Denetim maddesi",
          conversationScenario: "Konuşma Sahnesi",
          customerLabel: "Müşteri Etiketleri",
          reDetectionRules: "Yeniden algılama kuralları",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Kayıt indir",
          downloadText: "Metin indirme",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Resim yükle",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Lütfen boyutu 2048kb'den fazla olmayan bir resim seçin",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Veya",
            and: "Ve",
            wrong: "Olmayan",
            matchTo: "Eşleştir",
            rule: "Kurallar",
            satisfactionOperator: "Tatmin edici operatör",
            customerLabel: "Müşteri Etiketleri",
            keyEvents: "Kritik olaylar",
            knowledgePoints: "Bilgi noktaları",
            sessionLabel: "Oturum Etiketleri",
            supervisionItem: "Denetim maddesi",
          },
          linkExplorer: { linkInTheText: "Metin bağlantısı:" },
        },
      },
    },
    createForm: {
      field: { customComponentNotFound: "Özel bileşen bulunamadı:" },
    },
    sdkLoaderBase: {
      component: { custom: "Özel" },
      dropdownMonit: {
        updateSucceeded: "Başarıyla güncellendi",
        areYouSureToDelete: "Silmek için emin misiniz?",
        custom: "Özel",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Geçerli rapor yapılandırmasını kaydet === Form",
        saveCurrentReportConfiguration:
          "Geçerli rapor yapılandırmasını kaydetme",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Lütfen ortak ayar adını girin, maksimum uzunluk 20!",
        preservation: "Kaydet",
      },
      template: {
        saveCurrentReportConfiguration:
          "Geçerli rapor yapılandırmasını kaydetme",
        updateCurrentReportConfiguration:
          "Mevcut rapor yapılandırmasını güncelleme",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "İş emri bilgileri",
        workOrder: "İş emri",
        triggerScenario: "Tetikleyici sahne",
        associatedCallConversation: "Aramaları/konuşmaları bağlama",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Eski/konuşma metni ile test edin",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "1.", day: "-Tanrım.-Tanrım." },
      businessSummary: {
        normal: {
          equalTo: "Eşittir",
          notEqualTo: "Eşit değil",
          contain: "Dahil",
          excluding: "Dahil değil",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Ben benim, farklı bir havai fişek @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Merhaba, ben robot kalite kontrol uyarı haber bildirimi, 17 Ekim nedeniyle gruba eklendi",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Ben benim, farklı bir havai fişek @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Merhaba, ben robot kalite kontrol uyarı haber bildirimi, 17 Ekim nedeniyle gruba eklendi",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "İletişim Konuları",
          conversationSummary: "Konuşma özeti",
          serviceSuggestions: "Hizmet Önerileri",
        },
        template: { smartTags: "Akıllı etiketler" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Daha fazla bilgi için tıklayın" },
      schema: {
        textRobot: "Metin robotu",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Eskort eğitmeni, belge soruları ve cevapları, görev diyalogları, form soruları ve cevapları, harita soruları ve cevapları",
        capitalRetentionRobot: "Robotlar",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Zengin sorgulama ve tutma stratejileri, yüksek antropomorfik iletişim, yüksek tutma oranı ve ipuçlarının verimli ve otomatik dönüşümünü sağlamak",
        outboundRobot: "Get robot",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "İş emri iade ziyareti, hizmet memnuniyeti araştırması, işletme ve bakım arızası uyarısı, sipariş teslimat hatırlatması, e-ticaret etkinliği bildirimi, aday randevusu, iş otomasyonuna yardımcı olmak ve iş verimliliğini artırmak",
        incomingRobot: "Çağrı robotu",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "İşlem otomasyonunu gerçekleştirmek için iş emri, sipariş ve diğer iş sistemlerinin esnek entegrasyonu, konuşma semantik modelinin özel tanımlanması, iş sonuçları hemen; varlık (cep telefonu numarası, sipariş miktarı, sipariş süresi, adres,...) Tanıma, karmaşık iş için hızlı destek",
        intelligentQualityInspection: "Akıllı kalite kontrol",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Tam kalite kontrol, zamanında ve verimli, % 85 doğruluk oranı",
        intelligentSessionAnalysis: "Akıllı oturum analizi",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "İş eksikliklerine dair içgörü, müşteri seslerini dinlemek ve pazarlama dönüşümünü geliştirmek",
        enterpriseWechatConversationAnalysis: "Kurumsal WeChat Oturum Analizi",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Potansiyel iş fırsatlarını ve riskleri verimli bir şekilde tanımlayın ve pazarlama dönüşüm oranını % 20 artırın",
        assistant: "Oturma asistanı",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Acemi asistanı, acemi müşteri hizmetlerinin hizmeti tamamlamasını ve yetenekli müşteri hizmetleri çağrılarının kalitesini iyileştirmesini sağlayabilir. Her koltuk sizin kozunuz olsun",
        digitalPeople: "Dijital insanlar",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          '"Yüz yüze" antropomorfik iletişim, ses, metin, video ve dokunmatik çok modlu etkileşimi destekler, sürükleyici bir iletişim deneyimi sağlar, marka imajını ve kullanıcı hizmeti deneyimini iyileştirir',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "AI Bilgisi Orta Tayvan · Kurumsal Arama",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Bilgi Arama, Bilgi Paylaşımı, Belge Arama, Veritabanı Yerleştirme Arama",
        aIKnowledgeCenterKCsKnowledgeBase:
          "AI Bilgi Orta Tayvan · KCS Bilgi Bankası",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Bilginin tam yaşam döngüsü yönetimi, robotik ve IM bilgi tabanlarının birleşik bakımı, üçüncü taraf bilgi tabanlarının yerleştirilmesini destekler, bilgi şablonları, atom bilgisi ve daha uygun bir bilgi yönetimi deneyimi için diğer güçlü işlevler sağlar",
        aIKnowledgeCenterKnowledgeMap: "AI Bilgi Orta Tayvan · Bilgi Atlası",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "Kurumsal bilgiyi entegre etmek için tek elden harita oluşturma, bilgi detaylarını göstermek için büyük resim ve özel bir deneyim oluşturmak için yerleştirme yeteneği",
        modularDevelopmentPlatformOriginalEngineASR:
          "Modüler Geliştirme Platformu · Orijinal Kalp Motoru · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "% 90'ın üzerinde doğal sahne tanıma ve % 97'nin üzerinde kişiselleştirilmiş kelime tanıma ile iş özelleştirilmiş etki deneyimi elde etmek için uçtan uca algoritmalar kullanın",
        modularDevelopmentPlatformIntelligentMarketing:
          "Modüler Geliştirme Platformu · Akıllı Pazarlama",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Ürün önerisinin doğru bir şekilde gerçekleştirilmesi ve satış dönüşümünün iyileştirilmesi için kullanıcı portreleriyle birlikte derin öğrenme algoritmaları kullanın",
        modularDevelopmentPlatformUserPortrait:
          "Modüler Geliştirme Platformu · Kullanıcı Portresi",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Müşteri özelliklerini tam olarak tanımlamak için kullanıcı verilerine ve diyalog verilerine göre doğru kullanıcı portreleri oluşturun",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Modüler Geliştirme Platformu · Akıllı Tavsiye",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Kullanıcı portrelerine ve ürün portrelerine göre, kullanıcılara ilgilenebilecek ürünleri otomatik olarak önerin ve binlerce kişinin kişiselleştirilmiş önerilerini gerçekleştirin",
        modularDevelopmentPlatformNLPPAAS:
          "Modüler Geliştirme Platformu · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Kelimeler, cümleler, makaleler ve daha fazlası gibi farklı ayrıntı düzeylerinde temel yeterlilikleri kapsayan yüksek doğrulukta temel NLP yetenekleri sağlar",
      },
    },
    qualityScore: {
      index: {
        show: "Gösterme:",
        displayBySecondaryClassification: "İkinci seviyeye göre göster",
        showByRule: "Kurallara göre göster",
        pleaseEnterClassificationruleName:
          "Lütfen bir kategori/kural adı girin",
        clickCopy: "Kopyalamak için tıklayın",
        hitDetailsOfOperatorsInRules:
          "Kuraldaki operatör vuruşlarının detayları:",
        hit: "Vuruş",
        miss: "Vurmadı",
        filter: "Filtre:",
        clickToViewDetails: "Ayrıntılar için tıklayın",
      },
      components: {
        flowNodeList: {
          branch: "Puan",
          pleaseSelect: "Lütfen seçin",
          viewTheCompleteProcess: "İşlemin tamamını görüntüleyin",
          thereIsCurrentlyNoProcessAvailable: "Süreç yok",
        },
        pointItem: { xiangHit: "Öğe isabeti" },
        flowNodeListModal: { index: { completeProcess: "Tam süreç" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "İşlem kalite kontrol görevi değişti, lütfen tekrar itiraz edin.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Bu veriler düzenli olarak temizlendi",
        subject: "Konu:",
        description: "Açıklama:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "Kalite kontrol görevine ekle" },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Aramaları/konuşmaları bağlama" },
    },
    samplingTarget: {
      index: {
        time: "Ne zaman",
        samplingQuantityIsLessThan: "Örnekleme sayısı daha düşük",
        alertWhen: "Erken uyarı",
        everyday: "Günlük",
        weekly: "Her hafta",
        monthly: "Aylık",
        samplingInspectionTargetSavedSuccessfully:
          "Örnekleme hedefi başarıyla kaydedildi",
        personnel: "Personel",
        eachCustomerService: "Müşteri Hizmetleri Başına",
        cycle: "Döngüsü",
        samplingInspectionTarget: "Örnekleme hedefi",
        strip: "Madde",
        alertSettings: "Erken uyarı ayarları",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Müşteri Hizmetleri",
        complete: "Tamamla",
        Objective: "/Hedef",
        everyday: "Günlük",
        weekly: "Her hafta",
        monthly: "Aylık",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Grup Sohbet Etkileşim Oranı",
        maximumResponseIntervalTime: "En uzun cevap aralığı",
        numberOfPreemptiveCalls: "Stop sayısı",
        numberOfMessages: "Mesaj sayısı",
        strip: "Madde",
        proportionOfMessages: "Mesaj sayısı",
        speechPercentage: "Konuşma oranı",
        longestCustomerTalk: "En uzun müşteri konuşması",
        numberOfIndepthDiscussions: "Derinlemesine tartışma sayısı",
        second: "İkinci kez",
        maximumDurationOfContinuousSpeech: "En uzun sürekli konuşma süresi",
        questionFrequency: "Soru sıklığı",
        customer: "Müşteri",
        keyEvents: "Anahtar olaylar:",
        opportunity: {
          index: {
            generationFailed: "Oluşturma başarısız oldu",
            generateOpportunityPoints: "Şans noktaları oluşturun",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Oluşturma başarısız oldu",
            generateSummary: "Küçük bir düğüm oluşturmak",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Silmek için emin misiniz?",
        add: "Ekleme",
        opportunityPoints: "Fırsat noktası",
        conversationSummary: "Konuşma özeti",
        cancel: "İptal",
        determine: "Emin olmak için",
        edit: "Düzenleme",
        establish: "Oluşturma",
        conversationalInsights: "Konuşma içgörüleri",
        keyEvents: "Kritik olaylar",
        numKeyEvents: "Önemli bir olay",
        questionAnalysis: "Soru analizi",
        find: "Keşif",
        questions: "Soru",
        conversationAction: "Oturum eylemi",
        customer: "Müşteri",
        sale: "Satış",
        sessionLabel: "Konuşma Sahnesi",
        employeeQuestions: "Çalışan sorular",
        customerQuestions: "Müşteri soruları",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Müşteri",
        customerService: "Müşteri Hizmetleri",
        replyTo: "Yanıtla",
        addressee: "Alıcı:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Yeniden tanımlama",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Yeniden tanımlama, harf çevirisi uzunluğunu tüketir, lütfen dikkatli olun",
        determine: "Emin olmak için",
        executionSuccessful: "Başarılı bir yürütme",
        addToQualityInspectionTask: "Kalite kontrol görevine ekle",
        batchOperation: "Toplu işlemler",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Başarı ekle",
        addToQualityInspectionTask: "Kalite kontrol görevine ekle",
        qualityInspectionTask: "Kalite kontrol görevleri",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Rolleri tespit et",
        detectionRange: "Algılama aralığı",
        sentence: "Cümle",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Bu veriler düzenli olarak temizlendi",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "İş sistemine geri dön" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Soru çıkarma",
        businessSummary: "İş özeti",
        keyWord: "Anahtar kelimeler",
      },
      components: {
        problemExtraction: {
          no: "Hayır, hayır.",
          customerServiceDidNotAnswer: "Müşteri hizmetleri cevap vermedi",
          customerServiceHasAnswered: "Müşteri hizmetleri cevap verdi",
          problemUnresolved: "Sorun çözülmedi",
          theProblemHasBeenResolved: "Sorun çözüldü",
          customerIssue: "Müşteri sorunları:",
          customerServiceAnswer: "Müşteri Hizmetleri Cevapları:",
          reExtractQuestions: "Sorunun yeniden çizilme",
          clickToExtractQuestions: "Soru çekmek için tıklayın",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Portre adı",
            backgroundDescription: "Arka plan açıklaması",
            customerLabel: "Müşteri Etiketleri",
            labelManagement: "Etiket yönetimi",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Yeni" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Takip ayarları" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Artan takip",
              remove: "Kaldır",
              addQuestioning: "Takip Ekleme",
              section: "1.",
              secondInquiry: "Takip",
              questioningScore: "Skoru takip et",
            },
          },
        },
        index: {
          accordingToRules: "Kurallara göre",
          byScore: "Puana göre",
          whenMissRule: "Ne zaman başarısız kural",
          whenConductingQuestioning: "Takip ederken",
          pleaseSelectARule: "Lütfen kuralları seçin",
          ruleCannotBeEmpty: "Kurallar boş olamaz",
          currentScriptScore: "Konuşma skoru ≤ olduğunda",
          pleaseEnterTheScore: "Lütfen puanı girin",
          scoreCannotBeEmpty: "Skor boş olamaz",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Anlatıcı:" },
          pPT: { slide: "Slayt gösterisi" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Sözlü Yorumlar",
              script: "Konuşma",
              semantics: "Anlambilim",
              violatingWords: "İhlal sözcükleri",
              speechSpeed: "Konuşma hızı",
              emotion: "Duygular",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Sözlü Yorumlar",
              regenerate: "Yeniden oluşturma",
              generate: "Oluştur",
              hitSituation: "Vuruş durumu",
              noSemanticTagsMatched: "Anlamsal etiketlere uymuyor",
              matchToSemanticTags: "Semantik etiketlere eşleşme",
              appealToBeSubmitted: "İtiraz edilecek",
              ratingHasBeenReviewed: "Puanlar gözden geçirilmiştir",
              ratingAppealInProgress: "Puanlama şikayetinde",
              expressionScore: "Puanı ifade et",
              moodParticlesTimes: "Konuşma sözcüğü, {0} kez",
              changeTheModalParticlesTo: "Ses kelimesi değiştirildi",
              second: "İkinci kez",
              interrogation: "Takip",
              questioningWithoutScoring: "Puanlama olmadan sorgulama",
              branch: "Puan",
              matchingRate: "Eşleşme oranı",
              ratingAppeal: "Puanlama şikayetleri",
              changeTo: "Bunun yerine",
              wordPerMinute: "Dakikada kelime",
              completeScript: "Konuşma tamamlandı",
              incompleteScript: "Sözcülük eksik",
              semanticHit: "Anlamsal isabet",
              semanticMisses: "Anlamsal başarısız",
              scriptViolation: "Konuşma ihlali",
              modified: "Değiştirildi",
              notChanged: "Değiştirilmemiş",
              keyWord: "Anahtar kelimeler",
              semanticLabels: "Anlamsal etiketler",
              semanticMatchingRate: "Anlamsal Eşleşme Oranı",
              violatingWords: 'İhlal sözcüğü"',
              delete: "Silme",
              unmatched: "Eşleşmedi",
              matching: "Maç",
              matchTo: "Eşleştir",
              notMatchedTo: "Eşleşmedi",
              scriptScore: "Konuşma puanı",
              standardScript: "Standart konuşma",
              emotionalScore: "Duygusal skor",
              speechSpeedScore: "Konuşma hızı puanı",
              viewResolution: "Görünüm ayrıştırma",
              nodeConfiguration: "Düğüm yapılandırması",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Bu sorunun cevaplama süresi zaman aşımına uğrar ve cevap içeriği otomatik olarak gönderilir.",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Bu sorunun cevaplama süresi zaman aşımına uğrar ve cevap içeriği otomatik olarak gönderilir.",
          },
          correctBtn: {
            saveSuccessful: "Başarıyı kaydet",
            errorCorrection: "Hata düzeltme",
            cancel: "İptal",
            preserve: "Kaydet",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Puanlama kuralları",
            cancel: "İptal",
            determine: "Emin olmak için",
          },
          scoringRuleItem: {
            whole: "Hepsi...",
            arbitrarily: "Herhangi bir",
            pleaseEnterARuleName: "Lütfen kural adını girin",
            standardScript: "Standart konuşma",
            if: "Eğer",
            and: "Ve",
            or: "Veya",
            thenScore: "Sonra skor",
            pleaseEnterAScore: "Lütfen puan değerini girin",
            bonusPoints: "Ekstra puan",
            minusPoints: "Puan kaybı",
            hit: "Vuruş",
            misses: "Vurmadı",
            keyWord: "Anahtar kelimeler",
            semantics: "Anlambilim",
          },
          title: {
            ruleValidation: "Kural doğrulama",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Puanlama kuralları (mevcut toplam puan",
            points: "Puan)",
            editRules: "Düzenleme kuralları",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Etiket yok" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "İletişim Hedefleri",
              scoringCriteria: "Puanlama kriterleri",
            },
          },
        },
        index: {
          generating: "Üretimde",
          generateDialogueFramework: "Konuşma çerçevesi oluşturma",
          theme: "Tema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "İpuçları",
            generatePromptLanguage: "İstemi Oluşturma",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "İletişim aşaması",
          communicationObjectives: "İletişim Hedefleri",
          scoringCriteria: "Puanlama kriterleri",
          suggestionsForImprovement: "Yükseliş Önerileri",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Süreç Puanı",
            contentIntegrity: "İçerik bütünlüğü:",
            NumberOfCompletedProcesses: "%, Tamamlama Süreci Sayısı",
            keyHitPointsTotal: "Vuruş noktası: toplam",
            notReached: "Ulaşılmadı",
            accurateFollowupAccumulationOfStandardScripts:
              "Doğru Okuma: Standart Konuşma Birikmesi",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Bir kelime, öğrenciler tarafından okunan kelimelerin sayısı",
            aWord: "Bir kelime",
            languageNormsYes: "Dil normları:",
            emotionsAppearing: "Duygular: Görünen",
            speechSpeedSpeakingTooFast: "Konuşma hızı: çok hızlı konuşma",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Dil kelimeleri: Çok fazla kelime ortaya çıkıyor",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Yoksay etiketine ekle",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Akıllı Etiketleri Yeniden Uygulaymak Emin misiniz?",
        reExecute: "Yeniden yürütme",
      },
      component: { successfullyAdded: "Başarı ekle" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Tüm bildirimleri kapatın",
        reQualityInspectionTask: "Yeniden kalite kontrol görevi",
        requalityInspection: "Yeniden kalite denetimi",
        waiting: "Beklemek",
        viewDetails: "Ayrıntıları görüntüle",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Algılama süresi:" } },
        },
        template: { responseOpeningSentence: "Cevap başlangıç cümlesi" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Oluşturma başarısız oldu",
            generate: "Oluştur",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Veri yok" } },
      },
      index: { regenerate: "Yeniden oluşturma" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Silmek için emin misiniz?",
        operationSuccessful: "Operasyon başarılı oldu",
      },
      template: { delete: "Silme" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Lütfen soruları seçin!",
        similarQuestionRecommendation: "Benzer sorular",
        selectAllOnPage: "Sayfaların tamamı seçildiğinde",
        cancel: "İptal",
        preserve: "Kaydet",
        changeBatch: "Başka bir parti",
      },
    },
    listControl: { index: { columnControl: "Sütun kontrolü" } },
    loginUserAvatar: { index: { switch: "Geçiş yapma" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Veriler gerçek zamanlı olarak eklendiğinde, kalite kontrol görevlerinin yürütülmesi hala zaman alır. Belirli bir süre, birkaç dakika ile 2 saat arasında değişen kalite kontrol verilerinin miktarına bağlıdır.",
      },
    },
    bEditor: {
      index: {
        delete: "Silme",
        cancel: "İptal",
        confirm: "Emin olmak için",
        insert: "Ekleme",
        width: "Genişlik",
        height: "Yükseklik",
        clearContent: "İçeriği temizle",
        revoked: "İptal",
        redo: "Yeniden yap",
        fontSize: "Yazı tipi boyutu",
        rowHeight: "Yüksek çizgi",
        wordSpacing: "Kelime aralığı",
        paragraphIndent: "Paragraf girintili",
        increaseIndent: "Girintiyi artırmak",
        decreaseIndent: "Girintiyi azaltın",
        border: "Sınır",
        color: "Renk",
        textColor: "Metin rengi",
        backgroundColor: "Arka plan rengi",
        temporaryColor: "Geçici Renkler",
        bold: "Kaba ekleyin",
        italic: "Italik",
        underline: "Alt çizgi",
        strikethrough: "Çizgiyi sil",
        font: "Yazı tipi",
        textAlignment: "Metin hizalama",
        left: "Sol",
        centered: "Orta",
        right: "Sağ",
        bothEnds: "Her iki uç",
        leftFloat: "Sol yüzer",
        rightFloat: "Sağ yüzer",
        subrupt: "Üst simge",
        subscript: "Alt simge",
        clearStyle: "Temizle Stil",
        title: "Başlık",
        regular: "Düzenli",
        orderedList: "Sıralı liste",
        unorderedList: "Sırasız liste",
        reference: "Alıntılar",
        code: "Kod",
        link: "Bağlantı",
        clearLink: "Bağlantı Temizle",
        horizontalLine: "Yatay çizgi",
        media: "Medya",
        mediaLibrary: "Medya kütüphanesi",
        smallExpression: "Küçük ifadeler",
        fullScreen: "Tam ekran",
        exitFullScreen: "Tam ekrandan çıkın",
        enterLinkText: "Bağlantı metnini girin",
        enterLinkAddress: "Bağlantı adresini girin",
        enterTheLinkAddressAndPressEnter:
          "Bağlantı adresini girin ve enter tuşuna",
        openInNewWindow: "Yeni pencerede açılır",
        removeLink: "Bağlantıyı kaldırma",
        playingAudioFiles: "Ses dosyalarını oynat",
        playVideoFiles: "Video dosyalarını oynat",
        embeddedMedia: "Gömülü Medya",
        image: "Görüntüler",
        video: "Video",
        audio: "Ses",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "İsim",
        description: "Açıklama",
        type: "Türü",
        status: "Durum",
        actions: "Operasyon",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Görev adı",
        description: "Açıklama",
        form: "Kalite kontrol formu",
        actions: "Operasyon",
        createUser: "Yarat",
      },
      samplingRecords: {
        createTime: "Zaman",
        type: "Türü",
        operateUser: "Operasyonel kişi",
        number: "Miktar ekle",
        filterConditionList: "Tarama koşulları",
        sampleRuleStr: "Örnekleme koşulları",
        timeGreaterThan: "Zaman daha büyük",
        timeLessThan: "Daha az zaman",
      },
    },
    customFilters: {
      createdAt: "Konuşma süresi",
      customerId: "Müşteri",
      agentId: "Müşteri Hizmetleri",
      userGroupId: "Müşteri Hizmetleri Grubu",
      state: "Durum",
      lastEvaluateUserId: "Son puan verenler",
      submitTime: "Son Gönderme Zamanı",
      totalScore: "Toplam puan",
      formItemForbidIdList: "Yasak madde",
      formItemDeadlyIdList: "Ölümcül madde",
    },
    samplingInspection: {
      callTime: "Konuşma süresi",
      dialogTime: "Konuşma süresi",
      originator: "Tahsis edilen kişi",
      createTime: "Zaman oluşturma",
      updateTime: "Güncelleme zamanı",
      status: "Süreç durumu",
      score: "Puan",
      actions: "Operasyon",
      reviewer: "Denetçiler",
      samplingOfficer: "Örnekleme denetçisi",
      id: "Arama kaydı kimliği",
      time: "Konuşma süresi",
      phone: "Müşteri telefonu",
      staff: "Müşteri Hizmetleri",
      inspectionStatus: "Kalite kontrol durumu",
      samplingStatus: "Örnekleme durumu",
      inspectionGrade: "Derecelendirme",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Standart kelime",
        synonymWords: "Eş anlamlılar",
        synonymWordsNumber: "Eş anlamlılar sayısı",
        useFrequency: "Kullanım frekansı",
        effectiveness: "Geçerlilik",
        operation: "Operasyon",
      },
      common: {
        useFrequency: "Kullanım frekansı",
        effectiveness: "Geçerlilik",
        operation: "Operasyon",
      },
      professionalWords: {
        professionalWords: "Profesyonel isim",
        useFrequency: "Kullanım İstatistikleri",
        homophonic: "Sıcak kelimeler (homofon değiştirme)",
        target: "İsim değiştirme",
        targetWords: "Kelime değiştirme",
        homophonyWords: "Homofonik kalkan kelimeler",
        operation: "Operasyon",
      },
      attentionWords: "Kelimeleri takip et",
      ignoreWords: "Kelimeleri yoksay",
      wordsWeight: {
        selfDefineWeightWords: "Özel Ağırlık Kelimeler",
        weightWords: "Ağırlık kelimeleri",
        weight: "Ağırlık",
        useFrequency: "Kullanım frekansı",
        effectiveness: "Geçerlilik",
        operation: "Operasyon",
      },
      corpusData: {
        corpusDescription: "Korpusu açıklaması",
        updateTime: "Güncelleme zamanı",
        wordsCount: "Kelime sayısı",
        effectiveness: "Geçerlilik",
        operation: "Operasyon",
        corpusContent: "İçerik",
      },
    },
    semanticTags: {
      title: "Anlamsal etiketler",
      tags: "Standart cümle",
      orgName: "Bağlı kuruluş",
      tagCount: "Eğitim Sayısı",
      usingCount: "Referans sayısı",
      activeFlag: "Geçerlilik",
      suggestion: "Eğitim önerileri",
      actions: "Operasyon",
    },
    intelligentTags: {
      tagName: "İsim",
      tagDesc: "Açıklama",
      tagRefCnt: "İşaretleme sayısı",
      categoryName: "Etiket sınıflandırması",
      eventName: "Olayların sınıflandırılması",
      tagStatus: "Geçerlilik",
      actions: "Operasyon",
      tagType: "Etiket Türü",
    },
    dataPreprocess: {
      name: "İsim",
      desc: "Açıklama",
      dataSource: "Veri kaynağı sınırlamaları",
      type: "Türü",
      status: "Geçerlilik",
      actions: "Operasyon",
    },
    informationEntities: {
      name: "İsim",
      apiName: "API adı",
      type: "Türü",
      remark: "Açıklama",
      activeFlag: "Geçerlilik",
      actions: "Operasyon",
    },
    interactiveRecognition: {
      questionAndAnswer: "Standart Sorular & Cevaplar",
      type: "Türü",
      status: "Durum",
      actions: "Operasyon",
      question: "Soru",
    },
    role: { id: "ID", name: "İsim", actions: "Operasyon" },
    uploadDownload: {
      taskName: "Dosya adı",
      createTime: "Tarih",
      size: "Boyut",
      createUserName: "Operatör",
      status: "Durum",
      result: "Sonuçlar",
      actions: "Operasyon",
    },
    taskCenterList: {
      name: "Görev içeriği",
      createTime: "Gönderme zamanı",
      startTime: "Başlangıç zamanı",
      endTime: "Bitiş zamanı",
      createUserName: "Operatör",
      status: "Durum",
      result: "Sonuçlar",
      actions: "Operasyon",
    },
    customerInformation: {
      title: "Müşteri Hizmetleri Bilgileri",
      data: "Sadece anormal verileri görüntüleyin",
      list: {
        agentNo: "İş numarası/Müşteri Hizmetleri Kimliği",
        agentName: "Müşteri Hizmetleri",
        agentTel: "Telefon",
        agentEmail: "Posta kutusu",
        updateTime: "Güncelleme zamanı",
        actions: "Operasyon",
      },
      upload: {
        second:
          "2. Şablon formatına uygun EXCEl dosyalarını yüklemek için içe aktarma düğmesine tıklayın",
      },
    },
    IndexManagement: {
      title: "Gösterge yönetimi",
      list: {
        indicatorName: "İsim",
        indicatorDesc: "Açıklama",
        activity: "Geçerlilik",
        inspectDataSource: "Veri kaynağı",
        sysType: "Türü",
        agentGroups: "Müşteri Hizmetleri Grubu",
        actions: "Operasyon",
      },
      detail: {
        nameEmpty: "İsimler boş olamaz",
        descript: "Açıklama",
        descriptEmpty: "Açıklama boş olamaz",
        active: "Geçerlilik",
        activeEmpty: "Geçerlilik boş olamaz",
        dataSource: "Veri kaynağı",
        dataSourceEmpty: "Veri kaynağı boş olamaz",
        grounp: "Müşteri Hizmetleri Grubu",
        grounpEmpty: "Müşteri hizmetleri grubu boş olamaz",
        grounpHolder: "Lütfen müşteri hizmetleri grubunu seçin",
        type: "Puanlama yöntemi",
        typeNone: "Puanlama yöntemi boş olamaz",
        base: "Temel puan",
        baseNone: "Temel puan boş olamaz",
        positive: "Olumlu faktörler",
        positiveNone: "Olumlu faktörler boş olamaz",
        customAdd: "Filtreleme ekleme",
        negative: "Olumsuz faktörler",
        negativeNone: "Negatifler boş olamaz",
        customOK: "Tarama koşulları:",
        judgeStrategy: "Koşul kuralları:",
        other: "Diğer faktörler",
        otherAdd: "Ekleme faktörü",
        agent: "Ham veriler",
        section: "Aralık sayısı",
        sectionNone: "Aralık sayısı boş olamaz",
        step: "Adet aralığı",
        stepNone: "Adet aralığı",
        save: "Kaydet",
        cancel: "İptal",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Hepsi...",
      staff: "Sadece ben",
      department: "Bu bölüm ve altı",
    },
    gongStatisticsDate: { week: "Hafta", month: "Ay.", season: "Sezon" },
    gongCoachTaskStatus: {
      wait: "Danışmanlık bekliyor",
      already: "Danışmanlık",
      ignore: "Görmezden geldi",
    },
    callRangeStatus: {
      all: "Tüm aramalar",
      sale: "Çalışan Başlatma",
      customer: "Müşteri Başlatma",
    },
    todoListType: {
      later: "Daha sonra dinle",
      help: "Yardım için danışmanlık",
      contact: "Daha sonra iletişime geçin",
      send: "Bilgi gönderme",
      warn: "Risk uyarısı",
    },
    frequencyTypes: {
      everyDay: "Günlük",
      everyWeek: "Her hafta",
      everyMonth: "Aylık",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Kategorilenmemiş Veri Eğitimi",
        markedDataCorrigenda: "İşaretli Veri Hatası",
        classifiedDataCheck: "Sınıflandırılmış Veri Doğrulama",
        markedHistoryRecord: "Tarih etiketleme",
        historyDataRecalculate: "Tarihsel veri yeniden hesaplama",
      },
      dataExtractMode: {
        random: "Tamamen rastgele",
        intelligentRecommend: "Akıllı Tavsiye",
        custom: "Özel",
        specifiedClass: "Sınıflandırmayı belirtme",
      },
      smartRatesList: {
        markRate: "İşaretleme oranı",
        corrigendaRate: "Hata oranı",
        kl: "K-L dağılım",
        signalNoiseRate: "Sinyal-gürültü oranı",
      },
    },
    visibleRangeTypes: {
      all: "Hepsi...",
      customerGroup: "Müşteri Hizmetleri Grubu",
      userDefined: "Özel",
    },
    samplingRateTypes: {
      everyDay: "Her gün çizilir",
      everyWeek: "Haftalık çekiş",
    },
    taskRemindTypes: {
      confirm:
        "Kalite kontrol görev kaydının onaylanması gerektiğinde bir hatırlatma gönderin",
      appeal:
        "Kalite kontrol görev kaydı bir şikayet başlattığında bir hatırlatma gönder",
    },
    weekDays: {
      monday: "Pazartesi",
      tuesday: "Salı günü.",
      wednesday: "Çarşamba",
      thursday: "Perşembe",
      friday: "Cuma",
      saturday: "Cumartesi",
      sunday: "Pazar",
    },
    reportDateTypes: {
      dailyReport: "Günlük rapor",
      weeklyReport: "Haftalık Raporlar",
    },
    samplingRangeDayTypes: { today: "Bugün...", yesterday: "Dün." },
    samplingRangeWeekTypes: { thisWeek: "Bu hafta", lastWeek: "Geçen hafta" },
    samplingRuleTypes: {
      proportion: "Oransal örnekleme",
      random: "Rastgele örnekleme",
    },
    taskStatusTypes: {
      waitEvaluate: "Değerlendirilecek",
      waitSubmit: "Gönderilecek",
      waitConfirm: "Onaylandı",
      waitReview: "İncelenecek",
      completed: "Tamamlanmış.",
      all: "Hepsi...",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Doğrudan derecelendirme",
        weightedScore: "Ağırlık puanı",
        deductScore: "Puan düşülür",
        directScoreSecondLevel: "Doğrudan Puanlama-İkinci Seviye",
        weightedScoreSecondLevel: "Ağırlık Puanı-İkinci Seviye",
        deductScoreSecondLevel: "Kesinti-İkinci Seviye",
      },
    },
    fieldOperators: {
      is: "Eşittir",
      not: "Eşit değil",
      contains: "Dahil",
      notContains: "Dahil değil",
      not_contains: "Dahil değil",
      containsAny: "Keyfi içerir",
      contains_any: "Keyfi içerir",
      is_any: "Keyfi içerir",
      not_any: "Keyfi içermez",
      notContainsAny: "Keyfi içermez",
      not_contains_any: "Keyfi içermez",
      containsAll: "Tüm içerir",
      contains_all: "Tüm içerir",
      notContainsAll: "Tüm dahil değil",
      nont_contains_all: "Tüm dahil değil",
      isNull: "Boş olarak",
      is_null: "Boş olarak",
      isNotNull: "Boş değil",
      is_not_null: "Boş değil",
      greaterThan: "Daha büyük",
      greater_than: "Daha büyük",
      greaterThanEq: "Büyük eşittir",
      greater_than_eq: "Büyük eşittir",
      lessThan: "Daha az",
      less_than: "Daha az",
      lessThanEq: "Daha az eşit",
      less_than_eq: "Daha az eşit",
      between: "Bir yerde",
      notBetween: "Bir yerde değil",
      not_between: "Bir yerde değil",
      relative: "Eşittir",
      set: "Olarak ayarla",
      belongs: "Ait",
      notBelongs: "Ait değil",
      not_belongs: "Eşit değil",
      today: "Bugün...",
      yesterday: "Dün.",
      the_day_before_yesterday: "Dünden önceki gün",
      tomorrow: "Yarın.",
      next_seven_day: "Önümüzdeki yedi gün",
      last_seven_day: "Son yedi gün",
      this_week: "Bu hafta",
      next_week: "Gelecek hafta",
      last_week: "Geçen hafta",
      this_month: "Bu ay.",
      next_month: "Gelecek ay",
      last_month: "Geçen ay.",
      this_year: "Bu yıl",
      next_year: "Gelecek yıl",
      last_year: "Geçen yıl",
      exact_match: "Tam eşleşme",
      add_value: "Yeni katma değer",
      remove_value: "Değerleri silme",
      set_null: "Boş bırakın",
      isAny: "Herhangi bir eşittir",
      notAny: "Herhangi bir eşit değil",
      belongTo: "Ait",
      notBelongTo: "Ait değil",
      hasAny: "Keyfi içerir",
      notHasAny: "Keyfi içermez",
      prefixContains: "Başlangıç eşittir",
      prefixNotContains: "Başlangıç eşit değil",
      suffixContains: "Bitiş eşittir",
      suffixNotContains: "Sona eşit değil",
      nextSevenDay: "Önümüzdeki 7 gün",
      lastSevenDay: "Son 7 gün",
      thisWeek: "Bu hafta",
      nextWeek: "Gelecek hafta",
      lastWeek: "Geçen hafta",
      thisMonth: "Bu ay.",
      nextMonth: "Gelecek ay",
      lastMonth: "Geçen ay.",
      thisYear: "Bu yıl",
      nextYear: "Gelecek yıl",
      lastYear: "Geçen yıl",
      after: "Geç (gün dahil)",
      before: "Daha önce (gün dahil)",
      allMatching: "Hepsi eşleşti",
      allNotMatching: "Hepsi eşleşmiyor",
      hit: "Vuruş",
      not_hit: "Vurmadı",
    },
    fieldsOperators: {
      is: "Eşittir",
      is_null: "Aynı anda boş",
      head_equal: "Başlangıç eşittir",
      tail_equal: "Bitiş eşittir",
      is_not_null: "Aynı anda boş değil",
      not: "Eşit değil",
      diff_equal: "Fark eşittir",
      diff_greater_than: "Fark daha büyük",
      diff_greater_than_eq: "Fark eşittir daha büyük",
      diff_less_than: "Daha az fark",
      diff_less_than_eq: "Fark eşit veya daha az",
      hour_equal: "Aynı saat",
      day_equal: "Aynı gün",
      week_equal: "Aynı hafta",
      month_equal: "Aynı ay",
      year_equal: "Aynı yıl",
      day: "-Tanrım.-Tanrım.",
      minute: "Dakika",
      hour: "Saatler",
      part_equal: "Kısmi eşittir",
    },
    samplingColumnCaptions: {
      created_at: "Konuşma süresi",
      agent_id: "Müşteri Hizmetleri",
      get_direction: "Çağrı Türü",
      call_result: "Çağrı sonuçları",
      survey: "Değerlendirme",
      derived_from_id: "Kaynak",
      quit_queue_why: "Kuyruk durumu",
      queuing_duration: "Sıra almak zaman alıyor",
      talking_seconds: "Arama süresi",
      ringing_seconds: "Zil zamanı",
      hangup_by: "Çağrı kapanır",
    },
    callTypes: {
      callIn: "Çağrı",
      callOut: "Nefes ver",
      threeParty: "(Üç taraf)",
      consultation: "(Danışmanlık)",
      insert: "(Güçlü fiş)",
      monitor: "(Dinleme)",
      transfer: "(Transfer)",
      intercept: "(Kesişme)",
      transferOutside: "(Dış hat aktarma)",
      threePartyOutside: "(Üç taraf dış hat)",
      consultingOutside: "(Dış hatlara danışın)",
    },
    callResults: {
      staffAnswer: "Müşteri Hizmetleri Cevaplama",
      staffNotAnswer: "Müşteri hizmetleri cevapsız",
      customerAnswer: "Müşteri cevaplama",
      customerNotAnswer: "Müşteri cevapsız",
      phoneBusy: "Telefon meşgul",
      phoneOffline: "Telefon çevrimdışı",
      customerSpeedHangUp: "Müşteri hızlı asılı",
      customerHangUp: "Müşteri kanca",
      queueTimeout: "Kuyruk zaman aşımı",
      queueGiveUp: "Sıradan vazgeç",
      outlineAnswer: "Dış cevap",
      outlineNotAnswer: "Dış hat cevapsız",
      noChooseQueue: "Kuyruk seçilmedi",
    },
    defaultSurveys: {
      noEvaluation: "Değerlendirilmedi",
      noNeedToEvaluate: "Değerlendirmeye gerek yok",
    },
    queueStates: {
      queueSuccess: "Sırada başarı",
      queueTimeout: "Kuyruk zaman aşımı",
      queueGiveUp: "Sıradan vazgeç",
      noStaffOnline: "Hiçbir müşteri hizmetleri çevrimiçi",
    },
    callHangers: {
      customer: "Müşteri",
      staff: "Müşteri Hizmetleri",
      outline: "Dış hat",
    },
    workFlowTypes: {
      noAppealsMode: "İtiraz modu yok",
      appealsMode: "İtiraz moduna izin ver",
    },
    fieldCategories: {
      standardCondition: "Standart tarama koşulları",
      customerCondition: "Kullanıcı Özelleştirilmiş Filtreler",
    },
    sampleTypes: {
      automatic: "Otomatik örnekleme",
      manual: "Manuel örnekleme",
    },
    defaultFields: {
      createdAt: "Konuşma süresi",
      agentId: "Müşteri Hizmetleri",
      getDirection: "Çağrı Türü",
      callResult: "Çağrı sonuçları",
      survey: "Değerlendirme",
      derivedFrom: "Kaynak",
      quitQueueWhy: "Kuyruk durumu",
      queuingDuration: "Sıra almak zaman alıyor",
      talkingSeconds: "Arama süresi",
      ringingSeconds: "Zil zamanı",
      hangupBy: "Çağrı kapanır",
    },
    conditionFieldCategories: {
      fixedField: "Sabit alanlar",
      customField: "Özel alanlar",
      secondLevelScore: "İkincil sınıflandırma",
      formItemScore: "Pin değeri",
      forbids: "Yasak madde",
      deadlies: "Ölümcül madde",
    },
    columnFieldCategories: {
      agent: "Müşteri Hizmetleri",
      callLog: "Arama kaydı",
      businessFields: "İşletme Bilgileri",
      customer: "Müşteri",
      customerCustomFields: "Müşteri Özelleştirme",
      source: "Kaynak",
      queue: "Sıra",
      fixed: "Sabit",
      secondLevelScore: "İkincil sınıflandırma",
      formItemScore: "Pin değeri",
      forbids: "Yasak madde",
      deadlies: "Ölümcül madde",
    },
    taskListDefaultFields: {
      startTime: "Konuşma süresi",
      name: "Müşteri Hizmetleri",
      state: "Durum",
      totalScore: "Toplam puan",
      formItemForbidList: "Yasak madde",
      formItemDeadlyList: "Ölümcül madde",
      comment: "Değerlendirme Notları",
      inspector: "Derecelendirenler",
      review: "Denetçiler",
    },
    evaluationStates: {
      initial: "Başlatmak",
      waitEvaluate: "Değerlendirilecek",
      evaluated: "Gönderilecek",
      waitConfirm: "Onaylandı",
      waitCheck: "İncelenecek",
      complete: "Tamamlanmış.",
    },
    workBenchInfoTypes: {
      callInfo: "Arama Bilgileri",
      businessInfo: "İşletme Bilgileri",
      customerInfo: "Müşteri Bilgileri",
    },
    evaluationEventStates: {
      create: "Oluşturma",
      valuate: "Değerlendirme",
      edit: "Düzenleme",
      submit: "Gönderme",
      confirm: "Onaylandı.",
      appeal: "İtiraz",
      checkConfirm: "İnceleme onayı",
      check: "İnceleme",
    },
    formItemTypes: {
      direct: "Doğrudan derecelendirme",
      weight: "Ağırlık",
      deduct: "Puan düşülür",
      forbid: "Yasak madde",
      deadly: "Ölümcül madde",
    },
    appealCheckCallTypes: {
      callIn: "Çağrı",
      callBack: "İki yönlü geri arama",
      callOut: "Doğrudan arama",
      autoCall: "Otomatik giden çağrı",
    },
    appealCheckCallStatus: {
      waitReview: "İncelenecek",
      reviewed: "İnceleme",
      filed: "Arşivlenmiş",
    },
    samplingStatus: {
      all: "Hepsi...",
      unchecked: "Muayene yok",
      checked: "Örnekleme",
    },
    inspectionStatus: {
      all: "Hepsi...",
      unread: "Okunmamış",
      readed: "Okunmuş",
      appeal: "İtiraz",
      review: "İnceleme",
      filed: "Arşivlenmiş",
    },
    gradeChooseHits: { yes: "Evet, evet.", no: "Hayır, hayır." },
    appealCheckStatus: {
      all: "Tüm durum",
      unread: "Okunmamış",
      inComplaint: "İtirazda",
      reviewed: "İnceleme",
      readed: "Okunmuş",
      filed: "Arşivlenmiş",
      spotchecked: "Örnekleme",
    },
    flowStatus: {
      unread: "Okunmamış",
      alreadyRead: "Okunmuş",
      complaining: "İtirazda",
      reviewed: "İnceleme",
      sampling: "Muayene yok",
      samplingCompleted: "Örnekleme",
      complainCancel: "İptal edildi",
    },
    complainStatus: {
      success: "Başarı",
      fail: "Başarısızlık",
      default: "İtirazda",
      canceled: "İptal edildi",
    },
    reviewInspectionStatus: { inComplaint: "İtirazda", reviewed: "İnceleme" },
    gradeChangeTypes: {
      edit: "Düzenleme",
      check: "İnceleme",
      file: "Arşiv",
      remark: "Açıklamalar",
      complain: "İtiraz",
      review: "İnceleme",
      reviewEvaluation: "İnceleme ve değerlendirme",
      sample: "Örnekleme",
      sampleEvaluation: "Örnekleme değerlendirmesi",
      sampleSubmit: "Örnekleme gönderme",
      sampleAssign: "Örnekleme tahsisi",
      reviewAssign: "Tahsislerin gözden geçirilmesi",
      read: "Okunmuş",
      reviewConfirm: "İnceleme onayı",
      sampleConfirm: "Örnekleme onayı",
      caseBase: "Vaka Grubuna Ekle",
      sampleCancel: "Örnekleme iptali",
      reviewCancel: "Tahsiyi gözden geçirme ve iptal etme",
      sampleAgain: "Örnekleme ve yeniden atama",
      reviewAgain: "Yeniden dağıtımın gözden geçirilmesi",
      btachDelete: "Örnekleme kayıtlarını silme",
      rapidSampling: "Hızlı nokta kontrolü",
      reInspection: "Yeniden kalite denetimi",
      smartTagsChange: "Akıllı etiket değişikliği",
      cancelAppeal: "İtirazın geri çekilmesi",
    },
    appealTypes: {
      transliterationError: "Yanlış harf çevirisi",
      discriminationError: "Yanlış ayrımcılık",
      otherError: "Diğer hatalar",
    },
    inspectDataSources: {
      voiceCall: "Sesli arama",
      textDialogue: "Metin konuşmaları",
      realTimeVoiceCall: "Gerçek zamanlı sesli arama",
      realTimeTextDialogue: "Gerçek zamanlı metin konuşmaları",
      wechatDialogue: "Kurumsal mikro oturum",
      taobao: "E-ticaret metin konuşmaları",
      ticket: "İş emri",
      wechatRadio: "Kurumsal mikro ses",
    },
    inspectTaskType: {
      all: "Hepsi...",
      common: "Rutin kalite kontrol",
      relate: "İlgili kalite kontrol",
    },
    inspectApproveType: {
      all: "Hepsi...",
      resolve: "Denetim geçti",
      resolving: "İnceleme",
      reject: "Denetim başarısız oldu",
    },
    dataSourceFlags: {
      all: "Kısıtlama yok",
      voiceCall: "Çağrı",
      textDialogue: "Diyalog",
    },
    smartWordsLibrary: { effective: "Etkili", invalid: "Geçersiz" },
    activeFlags: { effiective: "Etkili", invalid: "Geçersiz" },
    labelTypes: {
      dimensionLabel: "Etiketleme",
      classificationLabel: "Kategori etiketleri",
    },
    pointTypes: {
      automatic: "Otomatik değerlendirme",
      manual: "Manuel değerlendirme",
      interactiveDetection: "Etkileşim algılama",
      smartRules: "Akıllı kurallar",
      machineLearning: "Kendi kendine öğrenme modu",
      intelligentModel: "Akıllı model",
    },
    pointGradeTypes: {
      radio: "Tek seçenek",
      input: "Giriş",
      select: "Seçim",
      all: "Hepsini tatmin etmek",
      any: "Keyfi ile tanışın",
      customize: "Özel",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Anahtar Kelime Eşleştirme",
      question: "Soru cümleleri",
      regular: "Düzenli ifadeler",
      context: "Bağlam Tekrarlama",
      semantics: "Anlamsal Eşleştirme",
      word: "Benzer kelimeler",
      dialogue: "Konuşma zaman aralığı",
      speed: "Konuşma hızı algılama",
      grabDetection: "Algılama",
      semanticTags: "Anlamsal etiketler",
    },
    applyRoles: {
      all: "Hepsi...",
      customer: "Müşteri",
      agent: "Müşteri Hizmetleri",
      robot: "Robot",
    },
    saleApplyRoles: {
      all: "Hepsi...",
      customer: "Müşteri",
      agent: "Çalışanlar",
    },
    applyOperatorScopes: {
      all: "Tam metin",
      preCondition: "Ön koşul",
      scope: "Menzili belirtin",
    },
    keywordScopes: {
      all: "Tüm anahtar kelimeleri algılama",
      any: "Herhangi bir anahtar kelimeyi tespit etme",
    },
    preOperatorHitTypes: {
      first: "İlk maç",
      every: "Her maç",
      last: "Son maç",
      any: "Herhangi bir eşleşme",
      none: "Eşleşmedi",
    },
    operatorRuleTypes: {
      all: "Tüm karşılamak",
      any: "Keyfi ile tanışın",
      custom: "Özel mantık",
    },
    informationEntityTypes: {
      system: "Sistem",
      custom: "Özel",
      moduleCompany: "Modül",
    },
    organizationTypes: { null: "Hayır, hayır.", moduleCompany: "Modül" },
    customType: {
      smartTag: "Varlığı çıkarmak",
      scriptTimes: "Konuşma sayma",
      dataCategory: "Veri Sınıflandırması",
    },
    interactiveQuestionTypes: {
      standard: "Standart Sorular ve Cevaplar",
      multiElement: "Çok faktörlü soru ve cevap",
      guideMultiRound: "Kılavuz çoklu tekerlek etkileşimi",
    },
    targetTypes: {
      all: "Hepsi...",
      customerService: "Müşteri Hizmetleri",
      customer: "Müşteri",
    },
    interactiveTypes: {
      similarQList: "Benzer sorular",
      exceptSimilarQList: "Benzer sorunları hariç tutma",
      similarAList: "Benzer cevaplar",
      wrongAList: "Yanlış cevap",
    },
    filterTypes: {
      call: "Çağrı kaydı",
      dialogue: "Konuşma metni",
      wechat: "Kurumsal WeChat",
      wechatAll: "Kurumsal WeChat Diyaloğu",
      ticket: "İş emri oturumu",
      taobao: "E-ticaret oturumu",
      wechatRadio: "Kurumsal mikro ses",
    },
    fieldTypes: {
      keyword: "Anahtar kelimeler",
      agent: "Müşteri Hizmetleri",
      agentGroup: "Müşteri Hizmetleri Grubu",
      sigleListbox: "Tek seçenek",
      multiListbox: "Çoklu seçim",
      text: "Tek satır metin",
      textArea: "Çok satırlı metin",
      dateTime: "Zaman",
      number: "Sayılar",
      voice: "",
      customer: "Müşteri",
      fieldWithLink: "Bağlantı alanları ile",
      percentage: "Yüzde",
      intelligentClassification: "Akıllı sınıflandırma",
      testSetList: "Test seti dinamik alanları",
      inspector: "Kalite denetçisi",
    },
    saveTypes: { save: "Kaydet" },
    knowledgeType: { oneToOne: "Bir soru ve cevap" },
    updateTypes: { update: "Güncelleme", saveAs: "Farklı olarak kaydet" },
    recommendItemActionTypes: {
      similar: "Benzer",
      exclude: "Dışlama",
      ignore: "Görmezden gelin",
    },
    gradeStatus: {
      unread: "Okunmamış",
      alreadyReview: "İnceleme",
      alreadyFiled: "Arşivlenmiş",
      waitReview: "İncelenecek",
      alreadyRead: "Okunmuş",
    },
    qualityCheckDetailTypes: { review: "İnceleme", spotCheck: "Örnekleme" },
    sampleAndReviewStatus: {
      init: "Ekstraksiyon yok",
      unread: "Okunmamış",
      alreadyRead: "Okunmuş",
      complaining: "İtirazda",
      reviewed: "İnceleme",
      sampling: "Muayene yok",
      samplingCompleted: "Örnekleme",
    },
    interactiveAnswerTypes: {
      noAnswer: "Cevap tespit edilmedi",
      answer: "Cevaplar tespit edildi",
      wrongAnswer: "Yanlış cevap tespit edildi",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Sorun tespit edilmedi",
      question: "Sorunlar tespit edildi",
      eliminateQuestion: "Dışlama sorunu tespit edildi",
    },
    isManual: { yes: "Yapay kalite denetimi", no: "Manuel kalite kontrolü" },
    readStatus: { yes: "Okunmuş", no: "Okunmamış" },
    reInspectionType: {
      recalcScore: "Sadece puanları yeniden hesaplayın (derecelendirme dahil)",
      recheckPoint:
        "Kalite kontrol noktalarının ve puanların yeniden hesaplanması",
    },
    common: {
      eCommerceReview: "E-ticaret Yorumları",
      socialMediaComments: "Sosyal medya yorumları",
      customer: "Müşteri",
      customerService: "Müşteri Hizmetleri",
      robot: "Robot",
      customerInformation: "Müşteri Bilgileri",
      workOrderFields: "İş emri alanı",
      intelligentConversationAnalysis: "Akıllı oturum analizi",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "İş eksikliklerine dair içgörü, müşteri seslerini dinlemek ve pazarlama dönüşümünü geliştirmek",
      intelligentAccompanyingPractice: "Akıllı tartışma",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "İşletmenin gerçek sahnelerini simüle eden, altın madalya konuşmalarının tam ustalığını gerçekleştiren ve diyalog becerilerini hızla artıran sürükleyici bir AI tartışması oluşturun",
      intelligentQualityInspection: "Akıllı kalite kontrol",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Tam kalite kontrol, zamanında ve verimli, doğruluk oranı % 85'ten fazla",
      salesEmpowerment: "Satış gücü",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI, satış yönetimini yeniden şekillendirir, iletişim sürecini dijitalleştirir, görselleştirir, satışların her bir bölümünü şeffaf bir şekilde yönetir, iletişimin her adımına ilişkin akıllı içgörüler, iş sorunlarını ince bir şekilde teşhis eder ve satış deneyimini mükemmel bir şekilde yeniden kazıtır",
      algorithmPlatform: "Algoritma platformu",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Kendi geliştirdiği orijinal motor ses semantiği, ASR, NLP modeli özelleştirmesi, iş senaryosu verilerini açma, sistem kendi kendine öğrenmesini gerçekleştirme, daha akıllı, daha doğru kullanım amacına ulaşma",
      generalSettings: "Evrensel ayarlar",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Veri izinlerinin ve çalışan rollerinin birleşik kurulum yönetimi",
      callLog: "Arama kaydı",
      intelligentRules: "Akıllı kurallar",
      businessRecords: "İş kayıtları",
      customFilter: {
        call: "Sesli arama",
        text: "Metin konuşmaları",
        smartTags: "Akıllı etiketler",
        pretreatment: "Ön işleme",
        smartTagGroup: "Akıllı Etiket Grubu",
        currency: "Genel veri",
        wechat: "Kurumsal WeChat",
        spotCheck: "Örnekleme",
        personaField: "Müşteri listesi",
        ticket: "İş emri oturumu",
        taobao: "E-ticaret oturumu",
        wechatRadio: "Kurumsal mikro ses",
        reviewList: "İnceleme",
      },
      talkerRecognitionType: {
        API: "API kimlik parça rolü",
        text: "Metin tanıma",
      },
      operatorTypes: {
        keyword: "Anahtar Kelime Eşleştirme",
        question: "Soru cümleleri",
        regular: "Düzenli ifadeler",
        context: "Bağlam Tekrarlama",
        semantics: "Anlamsal Eşleştirme",
        word: "Benzer kelimeler",
        dialogue: "Konuşma zaman aralığı",
        speed: "Konuşma hızı algılama",
        grabDetection: "Algılama",
        semanticTags: "Anlamsal etiketler",
        keywordStrict: "Anahtar kelime eşleştirme (sıkı)",
        link: "Bağlantı alanları",
        entityInfoDetection: "Bilgi Varlığı Algılama",
        basicInfoDetection: "Temel Bilgi Algılama",
        volume: "Ses Algılama",
        emotion: "Duygusal Analiz",
        grammar: "Gramer kuralları",
        sentenceLength: "Cümle uzun testi",
        conceptWord: "Kavramsal kelime",
        questionsAndAnswers: "Bilgi Bankası Cevapları (Eski)",
        element: "Öğe Soru-Cevap",
        silent: "Tepki zaman aşımı",
        associate: "Yol verileri",
        repeat: "Tekrarlanan gönderme",
        timeInterval: "Aralık süresi",
        typoDetection: "Yazım hatası algılama",
        silentOld: "Sessizlik",
        knowledge: "Bilgi cevapları",
        wecomRemark: "Müşteri Kurumsal Mikro Notlar",
        customerAddTime: "Müşteri Ekleme Zamanı",
      },
      keywordOperators: {
        all: "Hepsi eşleşti",
        allNot: "Hepsi eşleşmiyor",
        any: "Herhangi bir eşleşme",
      },
      applyRoles: {
        all: "Hepsi...",
        customer: "Müşteri",
        agent: "Müşteri Hizmetleri",
      },
      sentenceLength: {
        less: "Daha az",
        greater: "Daha büyük",
        equal: "Eşittir",
        greater_or_equal: "Büyük eşittir",
        less_or_equal: "Daha az eşit",
      },
      dialogueApplyScopes: {
        differentRoles: "Farklı karakterler arasında",
        agent: "Müşteri Hizmetleri",
        customer: "Müşteri",
        agentResponse: "Müşteri Hizmetleri Yanıt",
        customerResponse: "Müşteri yanıtı",
        customerNoResponse: "Müşteri yanıt vermiyor",
      },
      applyConditionHitTypes: {
        first: "İlk isabet",
        every: "Her vuruş",
        last: "Son vuruş",
      },
      applyConditionScopes: {
        current: "Güncel",
        before: "Önceki",
        after: "Sonra",
        beforeAll: "Tüm önce",
        afterAll: "Tüm sonra",
        around: "Yakınlarda",
      },
      voiceDemoTypes: {
        singleChannel: "Tek kanallı çağrı kaydı",
        dualChannel: "Çift kanallı çağrı kaydı",
      },
      sendStatus: {
        sending: "Gönderme",
        arrive: "Teslim edildi",
        fail: "Gönderme başarısız oldu",
        off_sending: "Okunmamış çevrimdışı gönder",
        off_arrive: "Okunmuş",
        rollback: "Geri çekildi",
      },
      collectionMethod: {
        assignment: "Doğrudan atama",
        entity: "Varlık çıkarma",
      },
      systemTypes: { qa: "Akıllı kalite kontrol", wfm: "Akıllı vardiya" },
      entityOperators: {
        equals: "Eşit bilgi",
        notEquals: "Eşit bilgi değil",
        contains: "Bilgi içerir",
        notContains: "Bilgi içermiyor",
        equalsFix: "Sabit değere eşittir",
        notEqualsFix: "Sabit değere eşit değil",
        containsFix: "Sabit bir değer içerir",
        notContainsFix: "Sabit bir değer içermez",
      },
      basicInfoOperators: {
        equals: "Eşittir",
        notEquals: "Eşit değil",
        contains: "Dahil",
        notContains: "Dahil değil",
        isNull: "Boş olarak",
        isNotNull: "Boş değil",
      },
      volumeDetectionWays: {
        loudness: "Ses yüksekliği desibel",
        rangeAbilitySelf: "Değişim aralığı (önceki cümleden daha iyi)",
        rangeAbilityOth: "Değişim büyüklüğü (karşılaştırmalı cümle)",
        rangeAbility3: "Değişim büyüklüğü (bu cümleyle karşılaştırıldığında)",
      },
      volumeDetectionOperators: {
        is: "Eşittir",
        not: "Eşit değil",
        greaterThan: "Daha büyük",
        greaterThanEq: "Büyük eşittir",
        lessThan: "Daha az",
        lessThanEq: "Daha az eşit",
      },
      sentimentTypes: {
        positive: "Olumlu duygular",
        negative: "Olumsuz duygular",
        neutral: "Tarafsız ruh hali",
        thankful: "Teşekkür ederim",
        happy: "Mutlu olmak",
        complaining: "Şikayet",
        angry: "Kızgın",
        post: "Ön taraf",
        negat: "Olumsuz",
        neut: "Nötr",
      },
      emotionOperators: { is: "Uyan", not: "Uymuyor" },
      propTypes: { system: "Sistem", user: "Özel" },
      valueTypes: { text: "Metin", number: "Sayılar" },
    },
    tasks: {
      allowCustomGrievance: "Özel şikayet gerekçelerine izin ver",
      businessSummary: "İş özeti",
      theDayBeforeYesterday: "Dünden önceki gün",
      assessActionTypes: { reassess: "Yeniden değerlendirme" },
      scoreType: {
        aiScore: "Akıllı değerlendirme",
        manualScore: "Manuel değerlendirme",
        interactiveDetection: "Etkileşim algılama",
        smartRules: "Akıllı kurallar",
        machineLearning: "Kendi kendine öğrenme modu",
      },
      inspectType: {
        inspectTrue: "Kontrol edildi",
        inspectFalse: "Kontrol edilmedi",
      },
      operatorType: {
        keyword: "Anahtar Kelime Eşleştirme",
        notKeyword: "Anahtar kelimeler eşleşmiyor",
      },
      applyRole: {
        all: "Hepsi...",
        customer: "Müşteri",
        agent: "Müşteri Hizmetleri",
      },
      applyScope: {
        all: "Hepsi...",
        specific: "Belirtilen cümle",
        scope: "Menzili belirtin",
      },
      judgeStrategy: {
        all: "Tüm şartları yerine getirmek",
        arbitrarily: "Herhangi bir koşulun yerine getirilmesi",
        custom: "Özel mantık",
      },
      checkPointStatus: {
        waitFor: "İnceleme bekliyorum",
        notDeductScore: "Kesinti yok",
        deductScore: "Puan düşülür",
      },
      applyOptions: { hit: "Koşullu isabet", notHit: "Belirtilen koşul yok" },
      gradeTypes: {
        yesOrNo: "Uyup uymadığı",
        input: "Manuel giriş",
        level: "Bölünme değerlendirmesi",
        multipleFactors: "Çoklu elemanlar",
      },
      gradeChooseHits: { yes: "Evet, evet.", no: "Hayır, hayır." },
      gradeChangeTypes: {
        edit: "Düzenleme",
        check: "İnceleme",
        file: "Arşiv",
        remark: "Açıklamalar",
        complain: "İtiraz",
        review: "İnceleme",
        reviewEvaluation: "İnceleme ve değerlendirme",
        sample: "Örnekleme",
        sampleEvaluation: "Örnekleme değerlendirmesi",
        sampleSubmit: "Örnekleme gönderme",
        sampleAssign: "Örnekleme tahsisi",
        reviewAssign: "Tahsislerin gözden geçirilmesi",
        read: "Okunmuş",
        reviewConfirm: "İnceleme onayı",
        sampleConfirm: "Örnekleme onayı",
      },
      gradeStatus: {
        unread: "Okunmamış",
        alreadyReview: "İnceleme",
        alreadyFild: "Arşivlenmiş",
        waitReview: "İncelenecek",
        alreadyRead: "Okunmuş",
      },
      samplingFrequencyTypes: {
        everyDay: "Günlük",
        everyWeek: "Her hafta",
        everyMonth: "Aylık",
      },
      samplingEveryDayTimeTypes: { yesterday: "Dün.", today: "Bugün..." },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Bu hafta",
        lastWeek: "Geçen hafta",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Bu ay.",
        lastMonth: "Geçen ay.",
      },
      samplingTypes: {
        random: "Rastgele örnekleme",
        proportional: "Oransal örnekleme",
      },
      weekdays: {
        Monday: "Pazartesi",
        Tuesday: "Salı günü",
        Wednesday: "Çarşamba günü",
        Thursday: "Perşembe",
        Friday: "Cuma",
        Saturday: "Cumartesi",
        Sunday: "Pazar",
      },
      distributeTypes: {
        rule: "Kural Önceliği",
        average: "Ortalama Rastgele Dağıtım",
      },
      notMatchAnyRuleTypes: {
        random: "Rastgele Ortalama Dağıtım",
        assign: "Belirlenen personele atama",
        ignore: "Atama yok",
      },
      inspectorTypes: {
        all: "Tüm kalite denetçileri",
        some: "Kalite denetçisinin ataması",
      },
      pushFrequencyTypes: {
        everyDay: "Günlük",
        everyWeek: "Her hafta",
        everyMonth: "Aylık",
        inspectionUpdate: "Kalite kontrol güncellemesi",
        timelyInspection: "Gerçek zamanlı kalite kontrol",
      },
      pushObjectTypes: {
        email: "Posta kutusunu belirtme",
        staff: "Müşteri Hizmetleri",
      },
      reviewOptions: {
        reviewResult: "İnceleme sırasında kalite kontrol sonuçları",
        reviewRecord: "İnceleme sırasında ilgili kayıtları görüntüleme",
        reviewAgentHidden:
          "İnceleme sırasında müşteri hizmetleri bilgilerini gizleme",
        samplingResult: "Örnekleme sırasında kalite kontrol sonuçları",
        samplingRecord: "Örnekleme sırasında ilgili kayıtları göster",
        sampleAgentHidden:
          "Örnekleme sırasında müşteri hizmetleri bilgilerini gizleme",
        checkExportAddCallInfoIphone:
          "Listeyi dışa aktarırken arama geçmişi ekleme",
        sampleExportAddCallInfoIphone:
          "Listeyi dışa aktarırken arama geçmişi ekleme",
        checkExportAddCallInfoText:
          "Listeyi dışa aktarırken konuşma kaydı ekleme",
        sampleExportAddCallInfoText:
          "Listeyi dışa aktarırken konuşma kaydı ekleme",
        inspectionExportAddCallInfoIphone:
          "Listeyi dışa aktarırken arama kaydı ekleyin (her seferinde en fazla 10.000 adet dışa aktarılabilir)",
        inspectionExportAddCallInfoText:
          "Listeyi dışa aktarırken ek konuşma kaydı (her seferinde en fazla 10.000 adet dışa aktarılabilir)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Kalite kontrol detaylarında nokta kontrollerinin hızlı bir şekilde gerçekleştirilmesine izin verin",
        fastSampleInSampleDetail:
          "Örnekleme ayrıntılarının hızlı bir şekilde gerçekleştirilmesine izin verin",
        fastSampleInReviewDetail:
          "Ayrıntıları gözden geçirmeden önce nokta kontrollerinin hızlı bir şekilde gerçekleştirilmesine izin verin",
      },
      permitCustomReasonList: {
        permitCustomReason: "Özel beyan gerekçelerine izin ver",
      },
      autoMateTasks: {
        autoLoad: "Otomatik ekleme",
        sample: "Otomatik nokta kontrolü",
        assign: "Otomatik atama",
        pushes: "Otomatik itme",
        assess: "Otomatik değerlendirme",
        check: "Temyiz incelemesi",
        case: "Vaka kütüphanesi senkronizasyonu",
      },
      qualityCheckDetailTypes: { review: "İnceleme", spotCheck: "Örnekleme" },
      appealTypes: {
        transliterationError: "Yanlış harf çevirisi",
        discriminationError: "Yanlış ayrımcılık",
        otherError: "Diğer hatalar",
      },
      inspectDataSources: {
        voiceCall: "Sesli arama",
        textDialogue: "Metin konuşmaları",
        realTimeVoiceCall: "Gerçek zamanlı sesli arama",
        realTimeTextDialogue: "Gerçek zamanlı metin konuşmaları",
      },
      pointTypes: {
        automatic: "Otomatik değerlendirme",
        manual: "Manuel değerlendirme",
        interactiveDetection: "Etkileşim algılama",
      },
      pointGradeTypes: {
        radio: "Tek seçenek",
        input: "Giriş",
        select: "Seçim",
      },
      sampleAndReviewStatus: {
        init: "Ekstraksiyon yok",
        unread: "Okunmamış",
        alreadyRead: "Okunmuş",
        complaining: "İtirazda",
        reviewed: "İnceleme",
        sampling: "Muayene yok",
        samplingCompleted: "Örnekleme",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Aşırı taşmayı görmezden gelin",
        automaticWeightCalculation: "Otomatik ağırlık muhasebesi",
      },
      calculateScoreTypes: { add: "Ekstra puan", sub: "Puan kaybı" },
      naChecked: { check: "Teste dahil olmayan bu maddeye izin ver" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Orantılı puan verin",
        allRight: "Hepsi doğru puan",
        anyRight: "Herhangi bir şekilde puan verin",
      },
      noHitQuestionResults: { true: 'Sonuç "Evet"', false: 'Sonuç "Hayır"' },
      repetRules: {
        same: "İçerik tamamen aynı",
        similar: "Anlamsal benzerlik",
      },
      relateConditions: {
        highlightQuestion: "Vurgularla ilgili sorunlar",
        highlightAnswer: "Cevabı vurgula",
        hightlightFoctors: "Vurguları",
      },
      interactiveQuestionTypes: {
        question: "Sorunlar tespit edildi",
        eliminateQuestion: "Dışlama sorunu tespit edildi",
      },
      interactiveAnswerTypes: {
        noAnswer: "Cevap tespit edilmedi",
        answer: "Cevaplar tespit edildi",
        wrongAnswer: "Yanlış cevap tespit edildi",
      },
      inspectionUpdateTypes: {
        autoInspection: "Otomatik kalite kontrol",
        autoInspectionWechat: "Otomatik analiz",
        artificialSampling: "Manuel örnekleme",
        artificialReview: "Yapay inceleme",
        manualModification: "Hızlı nokta kontrolü",
        timelyInspection: "Gerçek zamanlı kalite kontrol",
        samplingAssign: "Örnekleme tahsisi",
        artificialComplain: "Şikayet gönderme",
        fastSampling: "Hızlı örnekleme gönderme",
        recheckInspection: "Yeniden kalite denetimi",
        recheckInspectionWechat: "Yeniden analiz",
      },
      timelyInspectionTypes: {
        timelyInspection: "Gerçek zamanlı kalite kontrol",
      },
      pushActionTypes: { email: "Posta", interfacePush: "Arabirim itme" },
      pushDetailWays: {
        riskAlert: "Risk uyarısı",
        subscribe: "Abonelik mesajı",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Çağrı/konuşma mesajları",
        inspectionResults: "Kalite kontrol sonuçları",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Çağrı/konuşma mesajları",
        textContent: "Çağrı/Konuşma Metin İçeriği",
        inspectionResults: "Kalite kontrol sonuçları",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Grup içi ortalama puan",
        addOrSubtract: "Doğrudan artı veya eksi puan",
        noChange: "Pay ve eksi yok",
      },
      defaultScore: {
        empty: "Boş olarak",
        add: "Ekstra puan",
        notAdd: "Ekstra puan yok",
        sub: "Puan kaybı",
        notSub: "Pay kaybı yok",
        na: "N/A",
        input: "Manuel giriş",
        choose: "Seçim",
      },
      strategyTypes: {
        hitAdd: "Vuruş puan",
        hitNotAdd: "Vuruş puan değil",
        hitSub: "Vuruş eksi",
        hitNotSub: "Vuruş puan kaybetmiyor",
      },
    },
    download: {
      exportStatus: {
        prepare: "Hazırlık",
        process: "Devam ediyor",
        faild: "İhracat başarısız oldu",
        success: "Başarı",
      },
    },
    messageType: {
      all: "Hepsi...",
      monitor: "Anında izleme",
      trigger: "Tetikleyici bildirim",
      timing: "Zamanlanmış raporlar",
    },
    messagePushType: {
      all: "Hepsi...",
      system: "Sistem Mesajları",
      sdk: "Mesaj SDK",
      push: "Arabirim itme",
      email: "Posta",
      udesk: "Udesk bildirimi",
      udeskWhite: "Müşteri Hizmetleri Sistemi Bildirimi",
      weChat: "Kurumsal mikro haberler",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Örnekleme denetçisinin atanması",
      sampleInspector: "Örnekleme denetçisi",
      assignReviewer: "Tahsis edilen denetçiler",
      reviewer: "Denetçiler",
      taskInspector: "Görev kalite müfettişi",
    },
    sdkPushType: {
      webHook: "Standart webhook",
      dingDing: "Çivili robot",
      weChat: "Kurumsal WeChat Robot",
      customer: "Json içeriğini özelleştirme",
    },
    inspectionRemarksType: {
      inspection: "Kalite kontrol",
      check: "Örnekleme",
      review: "İnceleme",
    },
    segmentationStrategyType: {
      count: "Mesaj sayısı",
      spacing: "Mesaj Aralığı",
    },
    segmentationStrategy: {
      byTime: "Zamanlanmış bölünmüş",
      customize: "Kombinasyon",
    },
    inspectType: {
      commonInspect: "Rutin kalite kontrol",
      associateInspect: "İlgili kalite kontrol",
    },
    typoDetectionOperators: { less: "Daha az", greater: "Daha büyük" },
    autoAddTypes: {
      realTimeAdd: "Gerçek zamanlı ekleme",
      timingAdd: "Zamanlama Ekleme",
    },
    messageTypes: { text: "Metin mesajları", markdown: "Markdown mesajları" },
    inServiceStatus: {
      activated: "Etkinleştirildi",
      disabled: "Devre dışı",
      inactivated: "Aktif değil",
      resigned: "İşletmeden çıkın",
    },
    roles: { agent: "Çalışanlar", customer: "Müşteri" },
    commentVisibleAuthority: {
      all: "Herkes için görünür",
      self: "Sadece kendiniz görünür",
      designation: "Belirlenen kişi görünür",
    },
    appealCheckingTask: {
      eCommerceReview: "E-ticaret Yorumları",
      socialMediaComments: "Sosyal medya yorumları",
      largeModel: "Büyük model",
      customModel: "Özel model",
      universalModel: "Genel model",
      enterpriseWeChatText: "Kurumsal mikro metin",
      generalQualityInspection: "Genel kalite kontrol",
      processQualityInspection: "Süreç kalite denetimi",
      voiceCopy: "Sesli inceleme",
      beEqualTo: "Eşittir",
      notEqualTo: "Eşit değil",
      canAppeal: "İtiraz edebilir",
      noAppeal: "İtiraz yok",
    },
    wechat: {
      redEnvelopes: "Kırmızı zarf",
      text: "Metin",
      picture: "Resimler",
      voice: "Ses",
      voiceCall: "Sesli arama",
      video: "Video",
      businessCard: "Kartvizitler",
      position: "Yer",
      expression: "İfade",
      link: "Bağlantı",
      applet: "Küçük program",
      chatRecord: "Sohbet geçmişi",
      mixedMessage: "Karışık Mesajlar",
      file: "Belgeler",
    },
    gong: {
      sessionActivity: "Oturum etkinliği",
      conversationAction: "Oturum eylemi",
      riskSemantics: "Risk semantiği",
      daysNotFollowedUp: "Takibe alınmayan günler",
      waysideData: "Yol verileri",
      notStarted: "Başlamamış.",
      completed: "Tamamlanmış.",
      haveInHand: "Devam ediyor",
      unpublished: "Yayınlanmadı",
      published: "Yayınlanan",
      knowledgePoints: "Bilgi noktaları",
      technologicalProcess: "Süreç",
      notMarked: "Etiketli değil",
      inAnnotation: "Etiketleme",
      annotated: "İşaretli",
    },
    qualityChecingTask: {
      appealCanceled: "İtiraz iptali",
      complaintExpired: "Şikayet süresi doldu",
      incomingCall: "Çağrı",
      exhale: "Nefes ver",
      incomingCallthirdParty: "Çağrı (üç taraf)",
      incomingCallconsultation: "Çağrı (danışma)",
      incomingCallforcedInsertion: "Çağrı (güçlü fiş)",
      incomingCallmonitoring: "Çağrı (dinleme)",
      incomingCalltransfer: "Çağrı (transfer)",
      incomingCallinterception: "Çağrı (kesişme)",
      incomingCalltransferToExternalLine: "Çağrı (dış hat aktarma)",
      incomingCallthirdPartyExternalLine: "Çağrı (üç taraf dış hat)",
      incomingCallexternalLine: "Çağrı (dış hat danışma)",
      outgoingCallthirdParty: "Nefes (üç taraf)",
      outgoingCalltransfer: "Nefes alma (aktarma)",
      outgoingCallforcedInsertion: "Nefes (güçlü fiş)",
      outgoingCallmonitoring: "Nefes (dinleme)",
      outgoingCallinterception: "Nefes (kesişme)",
      outgoingCalltransferToExternalLine: "Giden (dış hat aktarma)",
      outgoingCallthreePartyExternalLine: "Nefes (üç taraf dış hat)",
      customerServiceAnswer: "Müşteri Hizmetleri Cevaplama",
      customerServiceMissed: "Müşteri hizmetleri cevapsız",
      customerAnswer: "Müşteri cevaplama",
      customerMissed: "Müşteri cevapsız",
      theTelephoneIsBusy: "Telefon meşgul",
      phoneOffline: "Telefon çevrimdışı",
      customerQuickHanging: "Müşteri hızlı asılı",
      clientHangsUp: "Müşteri kanca",
      queueTimeout: "Kuyruk zaman aşımı",
      giveUpQueuing: "Sıradan vazgeç",
      outsideLineAnswering: "Dış cevap",
      externalLineIsNotConnected: "Dış hat cevapsız",
      noQueueSelected: "Kuyruk seçilmedi",
      notEvaluated: "Değerlendirilmedi",
      noEvaluationRequired: "Değerlendirmeye gerek yok",
      queuingSucceeded: "Sırada başarı",
      noCustomerServiceOnline: "Hiçbir müşteri hizmetleri çevrimiçi",
      customer: "Müşteri",
      customerService: "Müşteri Hizmetleri",
      outsideLines: "Dış hat",
    },
    sessionActivity: {
      single: "Tek ortalama konuşma süresi",
      all: "Toplam çağrı hacmi",
      allTime: "Toplam arama süresi",
    },
    sessionAction: {
      salesProportion: "Satış Konuşması",
      salesLongest: "Ortalama en uzun satış konuşması",
      customerLongest: "Ortalama en uzun müşteri konuşması",
      discussNumber: "Derinlemesine tartışma sayısı",
      frequency: "Soru sıklığı",
    },
    monitorCriteria: { all: "Ön ve arka", before: "Önceki", after: "Sonra" },
    monitorTimeCycle: { day: "-Tanrım.-Tanrım.", week: "Hafta", month: "Ay." },
    monitorGroupTestType: {
      ab: "İki grubun karşılaştırılması",
      a: "A Grubu",
      b: "B Grubu",
    },
    fields: {
      department: "Bölüm",
      customerTags: "Müşteri Etiketleri",
      member: "Üyeler",
      time: "Zaman",
      cascade: "Kkademeli",
      anyMatch: "Herhangi bir eşleşme",
      keyWord: "Anahtar kelimeler",
      keyEvents: "Kritik olaylar",
    },
    semanticIntelligence: {
      staff: "Çalışanlar",
      customer: "Müşteri",
      employeesAndCustomers: "Çalışanlar ve müşteriler",
      notStarted: "Başlamamış.",
      haveInHand: "Devam ediyor",
      completed: "Tamamlanmış.",
      callRecording: "Çağrı kaydı",
      enterpriseWeChatCall: "Kurumsal mikro konuşma",
      enterpriseWeChatText: "Kurumsal mikro metin",
      discourseMiningForObjectionHandling: "İtiraz işleme konuşma madenciliği",
      productSellingPointScriptsMining:
        "Ürün satış noktası konuşma madenciliği",
      conversationScenario: "Konuşma Sahnesi",
      topicOfConversation: "Konu",
      keyEvents: "Kritik olaylar",
      customerLabel: "Müşteri Etiketleri",
      includeAny: "Keyfi içerir",
      includeAll: "Tüm içerir",
      and: "Ve",
      or: "Veya",
      question: "Soru cümleleri",
      nonInterrogativeSentence: "Soru Dışı Cümle",
    },
    coach: {
      notPassed: "Başarısız",
      analogTelephone: "Analog telefon",
      enterScenarioInformation: "Durum bilgilerini girin",
      generateDialogueFramework: "Konuşma çerçevesi oluşturma",
      generatePromptLanguage: "İstemi Oluşturma",
      aIGeneratedDialogue: "AI konuşma üretir",
      system: "Sistem",
      custom: "Özel",
      flow: "Süreç",
      script: "Konuşma",
      express: "İfade",
      emotion: "Duygular",
      rateAccordingToCourseConfiguration:
        "Derecelendirmeleri derslere göre yapılandırın",
      intelligentModelRating: "Akıllı Model Puanı",
      allData: "Tüm veriler",
      dataFromThisDepartment: "Bölüm verileri",
      multidepartmentalData: "Çok sektörel veriler",
      underReview: "İnceleme",
      no: "Hayır, hayır.",
      yes: "Evet, evet.",
      automaticRating: "Otomatik puanlama",
      auditRating: "Denetim Puanı",
      reRating: "Yeniden değerlendirme",
      inAppeal: "İtirazda",
      reviewed: "İnceleme",
      notViewed: "Görünüm yok",
      viewed: "Görüntülendi",
      uncorrected: "Düzeltilmemiş",
      corrected: "Düzeltildi",
      practice: "Egzersiz",
      examination: "Sınav",
      other: "Diğer",
      notMeetingStandards: "Uyumsuzluk",
      meetingStandards: "Standartlar",
      excellent: "Mükemmel",
      invalid: "Geçersiz",
      notStarted: "Başlamamış.",
      haveInHand: "Devam ediyor",
      ended: "Bitmiş.",
      completed: "Tamamlanmış.",
      hangInTheAir: "Bitmemiş.",
      passed: "Geçti",
      voiceConversation: "Sesli konuşma",
      textDialogue: "Metin konuşmaları",
      slidePresentation: "Slayt sunumu",
      unpublished: "Yayınlanmadı",
      published: "Yayınlanan",
      narration: "Anlatıcı",
      studentSpeaking: "Öğrenci konuşuyor",
      robotTalk: "Robot konuşuyor",
      knowledgeBaseQA: "Bilgi Bankası Sorular ve Cevaplar",
      slide: "Slayt gösterisi",
      negativeEmotions: "Duygusal negatif",
      emotionallyNormal: "Ruh hali normal",
      incompleteScript: "Sözcülük eksik",
      completeScript: "Konuşma tamamlandı",
      normalSpeechSpeed: "Konuşma hızı normal",
      speakTooFast: "Çok hızlı konuşma",
      speakTooSlowly: "Çok yavaş konuşma",
      whole: "Hepsi...",
      singlePage: "Tek sayfa",
      qA: "Bir soru ve cevap",
      situationalDialogue: "Senaryo diyalogu",
      misses: "Vurmadı",
      hit: "Vuruş",
      sequentialQA: "Sıralı Sorular ve Cevaplar",
      randomQA: "Rastgele Soru-Cevap",
      mastered: "Ustalaştı",
      notMastered: "Usta değil",
    },
    algorithm: {
      salesEmpowerment: "Satış gücü",
      enterpriseWeChatVoice: "Kurumsal WeChat Sesi",
      enterpriseWeChatText: "Kurumsal WeChat Metin",
      waiting: "Beklemek",
      extracting: "Ekstraksiyon",
      success: "Başarı",
      fail: "Başarısızlık",
      intelligentQualityInspection: "Akıllı kalite kontrol",
      textDialogue: "Metin konuşmaları",
      voiceCall: "Sesli arama",
      smartTags: "Akıllı etiketler",
      satisfactionEvaluation: "Memnuniyet değerlendirmesi",
      score: "Derecelendirme",
      grade: "Derecelendirme",
      customerServiceTeam: "Müşteri Hizmetleri Grubu",
      conversationAnalysis: "Oturum Analizi",
    },
    operatorListGather: {
      businessSummary: "İş özeti",
      firstSentence: "İlk cümle",
      lastSentence: "Son cümle",
      allTime: "Tüm zaman",
      nonBusinessWorkingHours: "İş dışı çalışma saatleri",
      businessWorkingHours: "İş Çalışma Saatleri",
    },
    callDialogueManage: {
      eCommerceReview: "E-ticaret Yorumları",
      socialMediaComments: "Sosyal medya yorumları",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Giriş yap",
        email: "Posta kutusu",
        password: "Şifre",
        forgetPwd: "Şifremi unuttum",
        emailCannotBeEmpty: "Posta kutusu boş olmamalıdır.",
        passwordCannotByEmpty: "Şifre boş olmamalıdır.",
        mustChangeLogin:
          "Üzgünüz, şifrenizin sıfırlanması gerekir, şifrenizi sıfırlamak için lütfen yöneticiye başvurun.",
        dismissionLogin: "Üzgünüm, işten ayrılıyorsunuz.",
        forbiddenLogin: "Üzgünüm, hesabınız devre dışı.",
        loginError: "Oturum açma hatası, posta kutusu veya şifre hatası.",
        loginPrompt: "Sisteme giriş yapın",
        rememberMe: "Beni hatırla",
      },
      logout: {
        logoutError:
          "Çıkış başarısız olursa, lütfen ağ bağlantısını kontrol edin veya daha sonra tekrar deneyin.",
      },
      resetPassword: {
        forgetPwd: "Şifremi unuttum",
        inputEmailPrompt:
          "Aşağıda hesap numaranızı girin, şifrenizi sıfırlamak için size bir e-posta ve açıklama göndereceğiz.",
        sendEmailSuccess:
          "Şifre değiştirme onay mektubu, doldurduğunuz posta kutusuna başarıyla gönderildi, lütfen zamanında kontrol edin.",
        sendEmailFailed:
          "Posta kutusu gönderilemedi, lütfen ağ bağlantısını kontrol edin veya daha sonra tekrar deneyin.",
        setNewPassword: "Yeni şifrenizi ayarlayın",
        passwordCannotEmpty: "Şifre boş olmamalıdır",
        passwordFormWrong: "Şifreler yanlış biçimlendirilmiş",
        confirmPasswordWrong: "İki kez girilen şifreler eşit değildir.",
        passwordResetSuccess: "Şifre sıfırlama başarılı oldu.",
        passwordResetFailed:
          "Şifre sıfırlama başarısız olursa, lütfen ağ bağlantısını kontrol edin veya daha sonra tekrar deneyin.",
        linkTimeover:
          "Şifre sıfırlama bağlantısı sona eriyor, lütfen tekrar deneyin",
      },
      form: { title: "CASE Form Gösterme" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Posta kutusu",
          emailFormatError:
            "Posta kutusu yanlış biçimlendirilmiş, lütfen tekrar girin!",
          forgotPassword: "Şifrenizi mi unuttunuz?",
          describe:
            "Aşağıda hesap numaranızı girin, şifrenizi sıfırlamak için size bir e-posta ve açıklama göndereceğiz.",
          send: "Gönder",
          emailCannotBeEmpty: "Posta kutusu boş olamaz",
          resetPasswordExpire:
            "Şifre sıfırlama bağlantısı sona eriyor, lütfen tekrar deneyin",
        },
        resetPassword: {
          setPassword: "Yeni şifrenizi ayarlayın",
          newPassword: "Yeni şifre",
          repeatPassword: "Şifreyi tekrarla",
          resetPassword: "Şifrenizi sıfırlama",
          passwordCannotBeEmpty: "Yeni şifre boş olamaz.",
          confirmPasswordCannotBeEmpty: "Tekrarlanan şifreler boş olamaz.",
          passwordsNotMatch: "Şifreler iki kez tutarsız!",
          passwordFormWrong: "Şifre Çince olamaz ve sayı 6-14 haneden!",
          linkExpiration:
            "Şifre sıfırlama bağlantısı sona eriyor, lütfen tekrar deneyin",
        },
      },
      errors: {
        haveNoPermissionWarningText: "Bu sayfa için görüntüleme izniniz yok",
      },
    },
    downloadCenter: {
      name: "İndirme Merkezi",
      saveTime: "(Varsayılan olarak 30 gün kaydedilir)",
      headerTitle: "Dosya indirme",
      download: "İndir",
      downloadPromp: "İndirme görevi gönderildi, lütfen gidin",
      viewProgress: "İlerlemeyi görüntüleme",
    },
    uploadCenter: { name: "Yükleme Merkezi" },
    tasks: {
      index: {
        name: "İsim",
        remark: "Açıklama",
        qualityCheckTasksTitle: "Çalışma Masası",
        informationCollection: "Toplama görevleri",
        qualityCheckTasks: "Kalite kontrol görevleri",
        wechatQaTasks: "Analiz görevleri",
        moduleName: "Şablon kullanarak Yeni",
        creatNewQualityCheckTask: "Yeni görev",
        generalInspectionList: "Genel Kalite Kontrol Raporu",
        editQualityCheckTask: "Kalite kontrol görevlerini düzenleme",
        creatNewQualityCheckTaskError:
          "Yeni kalite kontrol görevi başarısız oldu",
        editQualityCheckTaskError:
          "Düzenleme kalite kontrol görevi başarısız oldu",
        type: "Form türleri",
        singleScore: "Tek puan",
        singleScoreNullError: "Tek puan boş olamaz",
        inspector: "Kalite denetçisi",
        inspectDataSource: "Veri kaynağı",
        taskName: "Görev adı",
        inspectionType: "Kalite kontrol tipi",
      },
      manage: {
        screeningCondition: "Tarama koşulları",
        template: {
          condition: {
            index: { name: "Koşullar", existingConditions: "Mevcut koşullar" },
            detail: {
              id: "ID",
              name: "Koşul adı",
              remark: "Açıklama",
              checkRange: "Algılama aralığı",
              operator: "Operatör",
              operatorLogic: "Operatör mantığı",
            },
          },
          checkPointTest: {
            title: "Kural testi",
            running: "Kural testi, lütfen daha sonra...",
            clearSuccess: "Diyalog boşaltma başarısını simüle etmek",
            mock: "Analog diyalog",
            save: "Seçili konuşmayı kaydet",
            saveAll: "Tüm konuşmaları kaydet",
            saveSuccess: "Konuşma Başarıyı Kaydet",
            close: "Çıkın",
            delete: "Seçili silme",
            clear: "Boş.",
            customer: "Müşteri",
            agent: "Müşteri Hizmetleri",
            test: "Test",
            request:
              "Simüle edilen konuşma boşaltıldıktan sonra kurtarılamaz, boşaltılmadığı doğrulandı mı?",
            deletePopconfirmContent:
              "Seçili konuşmayı silmeyi onaylıyor musunuz?",
          },
          checkPoint: {
            index: {
              name: "Kural yapılandırması",
              wechatTitle: "Model yapılandırmasını analiz edin",
              existingCheckPoints: "Mevcut kalite kontrol kuralları",
              currentCheckPointScore:
                "Mevcut kalite kontrol kurallarının puanları",
              currentCheckPointScoreWechat: "Mevcut analiz modeli puanları",
              currentCheckPointWeight:
                "Mevcut kalite kontrol kurallarının toplam ağırlığı",
              weightTotalScoreAndItemScore: "Toplam puan: 100 puan Tek puan:",
              template: "Kalite kontrol kuralları şablonu",
              import: "Kalite kontrol kuralları şablonunu içe aktarın",
              getTemplateError: "Kalite kontrol kuralları şablonu kazanılamadı",
              tabs: { template: "Şablon", demo: "Örnek örnek" },
              customize: "Boş Şablonlar",
              move: "Sınıflandırmayı ve sıralamayı ayarlamak için sürükleyin",
              classification: "Kalite kontrol sınıflandırması",
              classificationWeChat: "Model Sınıflandırma",
              first: "Birinci seviye sınıflandırma",
              second: "İkincil sınıflandırma",
              new: "Yeni kalite kontrol kuralları",
              weChatNew: "Analiz modeli ekleme",
              createWeChat: "Yeni analiz modeli",
              empty: "Kalite kontrol kuralları yok,",
              wechatEmpty: "Analiz modeli yok,",
              newSecond: "Yeni ikincil sınıflandırma",
              newFirst: "Yeni birinci seviye sınıflandırma",
              checkPoint: "Kalite kontrol kuralları",
              checkPointWeChat: "Analiz modeli",
            },
            detail: {
              name: "İsim",
              remark: "Açıklama",
              type: "Türü",
              deduction: "Puan düşülür",
              rule: "Kurallar",
              highlighted: "Vurguları",
              gradeType: "Puanlama yöntemi",
              interactiveCategory: "Etkileşim sınıflandırması",
              predeterminedScore: "Puan değeri",
              weight: "Ağırlık",
              na: "N/A",
              default: "Varsayılan puan",
              preRule: "Koşullara hazırlık kuralları",
              analysisHighlighted: "Ayrıştırma kuralları",
              categorySelectPrompt: "Lütfen kategoriyi seçin",
              checkHighlightArrayError:
                "Kurallar vurgulama ile eşleşmiyor, lütfen kuralları ayrıştırma düğmesine tekrar tıklayın",
              foctorsHolder: "Lütfen elemanları seçin",
              foctorsErrorNone:
                "Çok faktörlü kural boş, lütfen çok faktörlü bir tür ekleyin",
              naError: "Varsayılan puan N/A olduğunda öğeyi kapatamazsınız",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A, kalite kontrol kuralı için ön koşulları ayarlanan Not Uygulayıcı\'dır, şartları yerine getirdiğinde tespit edilir, şartları yerine getirmediğinde, kural geçerli değildir ve kural test edilmez;\nÖn koşul doldurma talimatları: "&&" "ve", "||" veya ","!" "Olmayan", "(R1 || R2) && R3" örneğini doldurun',
                applicationMessageType: "Uygulama mesaj türleri",
                selectAll: "Tam seçim",
                highlightTags: "Vurguları Etiketler:",
                ruleType: "Kural türleri",
                dataSource: "Veri kaynağı",
                whole: "Hepsi...",
                enterpriseMicroConversation: "Kurumsal mikro oturum",
                enterpriseAndMicroCommunication: "Kurumsal mikro konuşma",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Yargılama düğümleri",
                    dialogueNode: "Diyalog düğümleri",
                    editNodes: "Düğümleri düzenleme",
                  },
                  nodeConfig: {
                    nodeName: "Düğüm adı",
                    nodeRules: "Düğüm kuralları",
                    scoringLogic: "Puanlama mantığı",
                    score: "PUAN:",
                    rule: "Kurallar",
                    multiBranchLogic: "Çok dallı mantık",
                    highlight: "Vurguları",
                    submit: "Gönderme",
                  },
                  ruleList: {
                    branch: "Şube",
                    branchName: "Şube adı:",
                    addRules: "Kural eklendi",
                  },
                  sidebar: { node: "Düğümler" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Otomatik düzenleme",
                      canvasAdaptation: "Tuval Uyarlanabilir",
                      copy: "Kopyalama",
                      delete: "Silme",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Bir düğümün alt düğümünün bağlantı hedefi olarak kullanılamayacağına karar verin",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Başlangıç düğümü bağlantı hedefi olarak kullanılamaz",
                      startingNodeCannotConnectMultipleNodes:
                        "Başlangıç düğümü birden fazla düğüme bağlanamıyor",
                      nodeCannotBeConnectedToItself:
                        "Düğümler kendilerine bağlanamaz",
                      startNode: "Başlangıç Düğümü",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Tam seçimi iptal et",
                    selectAll: "Tam seçim",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Uyup uymadığı",
                    gradedEvaluation: "Bölünme değerlendirmesi",
                  },
                },
              },
            },
            configuration: {
              title: "Kalite kontrol kuralları",
              wechatTitle: "Analiz modeli",
              neme: "İsim",
              errorMessage: "Silme başarısız oldu, en az bir koşulla",
              desc: "Açıklama",
              status: "Durum",
              yes: "Etkili",
              no: "Geçersiz",
              namePlaceHolder: "Lütfen etiket adını girin",
              desPlaceHolder: "Lütfen bir açıklama girin",
              nameEmpty: "İsimler boş olamaz",
              desEmpty: "Açıklama boş olamaz",
              type: "Türü",
              ruler: "Akıllı kurallar",
              machineLearning: "Kendi kendine öğrenme modu",
              autoScore: "Akıllı Puanlar (Eski)",
              manualScore: "Manuel puanlama",
              interaction: "Etkileşim algılama (eski)",
              typeTips: {
                model:
                  'Akıllı model: Yapılandırma ve bakım gerektirmeyen ve hizmette var olan sorunları hızlı bir şekilde kontrol edebilen önceden belirlenmiş bir kalite kontrol sistemidir. Bu model daha evrenseldir. İş taleplerini karşılamıyorsanız, lütfen "Akıllı Kurallar" veya "manuel puanını kullanın.',
                rule: "Akıllı kurallar: yani otomatik olarak AI kurallarına göre puanlama",
                manual:
                  "Manuel puanlama: manuel puanlama ile manuel kalite kontrol",
              },
              model: "Model:",
              ruleType: {
                words: "Konuşma",
                interaction: "Etkileşim",
                feature: "Özellikleri",
                information: "Bilgi",
              },
              testingMode: {
                all: "Tüm anahtar kelimeleri algılama",
                any: "Herhangi bir anahtar kelimeyi tespit etme",
              },
              operatorTypesOfInteraction: {
                question: "Bir soru ve cevap",
                element: "Öğe cevabı",
                repet: "Bağlam Tekrarlama",
                snatch: "Sözleri kapmak",
                silent: "Sessizlik",
              },
              role: "Rolleri tespit etmek için:",
              preposition: "Ön operatör:",
              range: "Algılama aralığı:",
              from: "1.",
              to: "İlk cümle",
              end: "Cümle",
              hasOldSilentTip:
                'Merhaba, doğruluğu artırmak için "Akıllı Kurallar-Etkileşim-Sessizlik" "Yanıt Zaman Aşımı" na yükseltildi, kullanım kurallarını güncellemeniz önerilir!',
              changeToOld: "Eski sürümlere geçin",
              changeToNew: "Yeni sürüme geçme",
              logic: {
                logic: "Puanlama mantığı",
                naTip:
                  "N/A, kalite kontrol kuralı için ön koşulları ayarlaman Not Applicable'dır. Koşullar yerine getirildiğinde tespit edilir. Koşullar yerine getirilmediğinde, kural geçerli değildir.",
                score: "PUAN:",
                na: "N/A：",
                naDes: "Teste dahil olmayan bu maddeye izin ver",
                preRule: "Ön koşul kuralı:",
                notCatchPreRule: "Ön koşul yerine getirilmediğinde:",
                naChecked: "N/A vururken:",
                preRuleEmpty: "Ön koşul kuralları boş olamaz",
                operatorEmpty: "Operatör içinde gerekli koşullar boş olamaz",
                multipleEmpty:
                  "Çoklu mantık skorlarında gerekli koşullar boş olamaz",
                average: "Grup içi ortalama puan",
                addOrSubtract: "Doğrudan artı veya eksi puan",
                noChange: "Pay ve eksi yok",
                rule: "Kurallar:",
                all: "Tüm operatörler için tatmin edici",
                any: "Keyfi operatörler için tatmin edici",
                customize: "Özel operatör mantığı",
                multiple: "Çoklu mantık puanı",
                heightLight: "Vurguları:",
                machineLearningTip:
                  "Kendi kendine öğrenme modu şu anda sadece tek cümle konuşma için etkilidir, lütfen karmaşık konuşma için akıllı kuralları kullanın.",
                strategy: "Strateji:",
              },
            },
            component: {
              approvalSubmittedSuccessfully:
                "Onay başarılı bir şekilde teslim edildi",
            },
            components: {
              pageHeaderExtra: {
                test: "Test",
                formal: "Resmen",
                submit: "Gönderme",
              },
              createModal: { index: { myRules: "Benim kurallarım" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Süreç otomasyonu",
              screeningCondition: "Tarama koşulları",
              aiScreeningCondition: "Akıllı tarama koşulları",
              rule: "Kurallar",
              automaticAddition: "Otomatik ekleme",
              automaticAdditionTip:
                "AI kalite kontrolü için otomatik olarak gerçek zamanlı veya zamanlanmış görevlere veri ekleme",
              autoAdd: {
                autoAddType: "Otomatik ekleme yolları",
                addFrequency: "Frekans ekle",
                add: "Ekleme",
              },
              automaticSamplingInspection: "Otomatik nokta kontrolü",
              automaticSamplingInspectionTip:
                "Manuel kalite kontrol gerektiren verilerin otomatik olarak çıkarılması. Örneğin, dün rastgele verilerin % 10'u manuel örnekleme için günlük olarak alınır.",
              everyAgentCheckLimit: "Müşteri hizmetleri başına üst sınır",
              automaticAllocation: "Otomatik atama",
              automaticAllocationTip:
                "Örnekleme tahsisi: çekilen veriler otomatik olarak kalite denetçisine atanır. Örneğin, dün rastgele verilerin % 10'u manuel örnekleme için günlük olarak alınır ve bu görev için ortalama 2 kalite denetçisine atanır.",
              automaticAllocationTip2:
                "Gözden geçirme ataması: Temyiz edilen veriler otomatik olarak inceleme için kalite denetçisine atanır. Örneğin, günlük temyizden elde edilen veriler inceleme için bu görev için rastgele 2 kalite denetçisine atanır.",
              automaticPush: "Otomatik itme",
              automaticPushTip:
                "Tetikleme bildirimi: Kalite kontrol sırasında bir bildirim/alarm verin. Örneğin, müşteri hizmetleri ölümcül bir hata yaptığında, mikro gruba bir bildirim mesajı gönderin.",
              automaticPushTip2:
                "Düzenli rapor: Kalite kontrol sonuçları e-posta şeklinde düzenli bir rapor gönderin. Örneğin, dün manuel olarak kontrol edilen kalite kontrol sonuçları her gün saat 9: 00'da müşteri hizmetleri yöneticisinin posta kutusuna gönderilir.",
              automaticAssess: "Otomatik değerlendirme",
              automaticAssessTip:
                "Manuel örnekleme sonuçları, AI kalite kontrol sonuçları ile düzenli olarak doğruluk değerlendirmesi için karşılaştırılır. Örneğin, dün saat 8: 00'de alınan verilerin doğruluğu günlük olarak değerlendirilir.",
              reexaminationComplaint: "Temyiz incelemesi",
              spoktReviewDetail: "İş Yapılandırması",
              spoktReviewDetailTip:
                "Kalite kontrol listesi, örnekleme listesi, itiraz işi, inceleme listesi, açıklamalar ve diğer fonksiyonlar için yapılandırma",
              qaDetailAction: "Kalite kontrol yapılandırması",
              spoktDetailAction: "Örnekleme konfigürasyonu",
              reviewDetailAction: "Yapılandırmanın gözden geçirilmesi",
              appealAction: "Temyiz yapılandırması",
              remarkTemplate: "Açıklama şablonları",
              automaticArchiving: "Otomatik arşivleme",
              conditionalRules: "Koşul kuralları",
              qualityInspectionConditions: "Kalite kontrol koşulları",
              unopened: "Henüz açık değil",
              sampleInspectionDistributionModel: "Örnekleme tahsisi",
              reviewAllocationModel: "Tahsislerin gözden geçirilmesi",
              distributionModelTitle: "Dağıtım stratejisi:",
              pushRules: "İtme kuralları",
              push: "İtme",
              assessRules: "Değerlendirme Kuralları",
              on: "on",
              off: "off",
              updateStatusSuccess: "Devlet değişikliği başarılı oldu!",
              updateStatusFailed:
                "Durum değişikliği başarısız oldu, lütfen daha sonra tekrar deneyin!",
              sampleNumberOrPercent: "Örnekleme sayısı veya ölçek",
              inspectorList: "Kalite denetçisinin ataması",
              numberOfSubmission: "İfade için izin verme sayısı",
              rewivewDays: "-Tanrım.-Tanrım.",
              TimeOfSubmission: "Başvurunun geçerlilik süresi",
              rewivewSecond: "İkinci kez",
              switchOn: "Açık",
              switchOff: "Kapat",
              notMatchAnyRule: "Herhangi bir kuralla eşleşmediğinde:",
              appealOptionsList: {
                hiddenInspector:
                  "Şikayet, kalite denetçisinin bilgilerini gizler",
                hiddenHit: "Şikayet sayfası vurguları destekliyor",
              },
              caseLibrary: "Vaka kütüphanesi senkronizasyonu",
              caseLibrarySyncTip:
                "QA sistemi tarafından eklenen vakaların müşteri hizmetleri sistemi ile senkronize edilmesi",
              addCase: "Senkronizasyonu artırın",
              caseLibraryId: "Vaka kütüphanesi",
              caseLibraryIdTip:
                "QA sistemi için vaka kütüphanesi gruplandırması",
              udeskCaseLibraryIdTip:
                "Müşteri Hizmetleri Sistemi Vaka Gruplandırma",
              syncHis: "Senkronizasyon Tarihi Örneği",
              otherSettings: "Diğer ayarlar",
              udeskCaseLibraryId: "Push vaka grubu",
              url: "Push URL",
              urlTip:
                "Lütfen http:// veya https:// ile başlayan arayüz adresini girin",
            },
            templateNew: {
              secondaryReview: "İkinci seviye inceleme",
              numberOfAppealsAllowedToBeSubmitted:
                "İtirazlara izin verme sayısı",
              validityOfSubmissionOfAppeal:
                "Temyiz başvurusu geçerlilik süresi",
              validityOfAppealReview: "Temyiz incelemesi geçerlilik süresi",
              hours: "Saatler",
              businessSummary: "İş özeti",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Müşteri hizmetleri başına alt sınır",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "İş özeti, iş durumuna göre özelleştirilebilir büyük bir model tarafından otomatik olarak oluşturulur",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Sadece birinci seviye denetçi atama stratejisi",
              secondaryCompounding: "İkincil bileşik:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "İkinci seviye denetçiler yalnızca ortalama dağıtımı destekler",
              secondLevelReviewer: "İkinci seviye denetçi:",
              samplingInspectionTarget: "Örnekleme hedefi",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Manuel örnekleme testinin tamamlanması gereken hedefi belirleyin, hedefe ulaşılmadığında veya aşılmadığında, bir hatırlatma verin, örneğin, her müşteri hizmeti ayda 100 örnekleme işlemini tamamlamalıdır, 80'den az olduğunda, kalite kontrol ve örnekleme sayfası bir hatırlatma yapar.",
            },
            component: {
              verificationFailed: "Doğrulama başarısız oldu",
              pleaseAddASecondLevelQualityInspector:
                "Lütfen ikincil bir kalite denetçisi ekleyin",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "İpuçları",
                    fieldName: "Alan adı",
                    fieldDescription: "Alan açıklaması",
                    generateContent: "İçerik oluşturma",
                    enabled: "Etkin olup olmadığı",
                    generationMethod: "Oluşturma yolu",
                    automaticGeneration: "Otomatik Oluşturma",
                    manualTrigger: "Manuel tetikleme",
                    filterCondition: "Tarama koşulları",
                    return: "Geri dön",
                    preserve: "Kaydet",
                  },
                  components: {
                    training: { index: { generateResults: "Sonuç oluşturma" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Sınırlı değil",
                    limitedScope: "Sınırlı kapsam",
                    add: "Ekleme",
                    thereIsCurrentlyNoDataAvailable: "Veri yok",
                  },
                },
              },
              index: {
                fieldName: "Alan adı",
                fieldDescription: "Alan açıklaması",
                nothing: "Hayır, hayır.",
                generationMethod: "Oluşturma yolu",
                generateManually: "Manuel olarak oluşturma",
                automaticGeneration: "Otomatik Oluşturma",
                generateContent: "İçerik oluşturma",
                notQualified: "Sınırlı değil",
                filterCondition: "Tarama koşulları",
                enabled: "Etkin olup olmadığı",
                type: "Türü",
                system: "Sistem",
                custom: "Özel",
                operation: "Operasyon",
                edit: "Düzenleme",
                areYouSureToDeleteIt: "Silmek için emin misiniz?",
                delete: "Silme",
                addField: "Alan artışı",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Rol Yapılandırması",
              roleConfigTip:
                "İçe aktarılan arama verileri mono olduğunda, konuşanı ayırma ve tanımlama kurallarını yapılandırmak için kullanılır. Kurallara uyanlar müşteri hizmetleri rolüdür",
              operator: "Operatör",
              operatorLogic: "Operatör mantığı",
            },
          },
          manualAdd: {
            index: {
              name: "Manuel olarak ekleme",
              tip: "Önizleme verilerini manuel olarak yapılandırarak filtrelemek ve bunları kalite kontrol görevlerine hızlı bir şekilde eklemek için kullanılır",
              screeningCondition: "Filtre kriterlerine göre ekleyin",
              aiScreeningCondition: "Akıllı tarama koşulları",
              rule: "Kurallar",
              createTime: "Zaman ekle",
              filterTitle: "İçeriği filtreleyin",
              filterRule: "Tarama koşulları",
              startTime: "Başlangıç zamanı",
              endTime: "Bitiş zamanı",
              records: "Kayıtları içe aktarma",
              createUserName: "Operasyonel personel",
              successCount: "Başarı ekle",
              conditionRule: "Koşul kuralları:",
              preview: "Önizleme",
              previewData:
                "Şu anda tarama koşullarını yerine getiren {0}, {1} görev eklendi, {2} görev eklendi, {3} görev eklenemez",
              failCount: "Ekleme başarısız oldu",
            },
          },
          basicInfo: {
            index: {
              title: "Temel Bilgiler",
              name: "İsim",
              taskName: "Görev adı",
              taskNamePlaceholder: "Lütfen görev adını girin",
              requiredName: "Görev adı boş olamaz",
              taskRemarkPlaceholder: "Lütfen görev açıklamasını girin",
              requiredRemark: "Görev açıklaması boş olamaz",
              remark: "Açıklama",
              taskRemark: "Görev açıklaması",
              source: "Veri kaynağı",
              benchmarkScore: "Temel puan",
              benchmarkScorePlaceholder: "Lütfen taban puanınızı girin",
              scoreLowerLimit: "Minimum puan sınırı",
              scoreUpperLimit: "Maksimum puan sınırı",
              inspectType: "Kalite kontrol tipi",
              nlpModel: "NLP modeli",
              commonInspect: "Rutin kalite kontrol",
              associateInspect: "İlgili kalite kontrol",
              associateCycle: "İlişki döngüsü",
              associateRegulation: "İlişkilendirme kuralları",
              judgeStrategy: "Yürütme mantığı",
              rule: "Kurallar",
              commonTip:
                "Rutin kalite denetimi: tek iletişim, diyalog ve tek iletişim içeriği için uygundur",
              associateTip:
                "İlgili kalite denetimi: Birden fazla iletişim ve diyalog için uygundur ve kalite kontrolü için çoklu iletişimin içeriğini ilişkilendirir.",
              cycleTip:
                "Birden fazla iletişim ve diyalog ilişkilendirilirse, korelasyon döngüsüne uyan veriler kalite kontrol görevine girer",
              scoreLowerLimitPlaceholder: "Lütfen minimum puan sınırını girin",
              scoreUpperLimitPlaceholder: "Lütfen maksimum puan sınırını girin",
              call: "Sesli arama",
              dialogue: "Metin konuşmaları",
              user: "Kalite denetçisi",
              wechatUser: "Operatör",
              noDesignated: "Belirtmedi",
              type: "Form türleri",
              singleScore: "Tek puan",
              conduct:
                "Lütfen önce aşağıdaki bilgileri iyileştirin, lütfen tamamlandıktan sonra İleri'ye tıklayın",
              conduct2:
                "Lütfen kalite kontrol kurallarının ve kalite kontrol noktalarının yapılandırmasını tamamlayın. Yapılandırmayı tamamladıysanız, İleri'ye tıklayabilir veya kılavuzdan çıkabilirsiniz.",
              conduct3:
                "Kalite kontrol derecelendirmesi, kalite kontrol noktasına ve puanlama durumuna göre derecelendirilmiş bir konfigürasyondur. Farklı kalite kontrol sonucu seviyelerini tanımlayabilirsiniz. Derecelendirmeye ihtiyacınız yoksa, bu adımı doğrudan atlayabilirsiniz.",
              conduct4:
                "Göreve otomatik olarak dahil edilecek veri özelliklerini, bu kalite kontrol görevlerinde kontrol edilmesi gereken veri özelliklerine göre otomatik olarak ekleyebilir ve geçici olarak otomatik olarak veri eklemeniz gerekmiyorsa, atlamayı tıklayabilirsiniz.",
              conduct5:
                "Mono kayıt kullanıyorsanız, lütfen bunu yapılandırdığınızdan ve bir sonraki adıma tıkladığınızdan emin olun, lütfen sistemin müşteri hizmetleri ile müşteri hizmetleri arasındaki farklı parçaları tanımlamasına yardımcı olmak için yalnızca müşteri hizmetlerini kullanın.",
              conduct6:
                "İşlevi manuel olarak ekleyebilir, sistemdeki mevcut iletişim verilerini kalite kontrol için sunabilir veya manuel olarak ekleme görevini göndermiş olabilirsiniz, lütfen Tamam'ı tıklayın",
              helper: "Yardım Belgelerini Görüntüle",
              quit: "Önyükleme çıkın",
              next: "Bir sonraki adım",
              complete: "Tamamla",
              skip: "Atla",
              cancel: "İptal",
              delete: "Silme",
              save: "Kaydet",
            },
            template: {
              samplingInspector: "Örnekleme denetçisi",
              sessionType: "Oturum tipi",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Kalite kontrol çalışma masası",
              search: "Tarama",
              export: "İhracat",
              condition: "Koşullar",
              logic: "Mantık",
              reQualityInspection: "Yeniden kalite denetimi",
              reQualityInspectionUnderWay: "Yeniden inceleme görevinde...",
              reQualityInspectionUnderWayTip:
                "Pencereyi kapattıktan sonra sayfadan ayrılmak görevi etkilemez",
              reQualityInspectionDone: "Yeniden kalite kontrol tamamlandı",
              reQualityInspectionDoneTip:
                "Yeniden kalite kontrolü başarıyla tamamlandı, lütfen onay düğmesine tıklayın",
              endReQualityInspection: "Görevin sonlandırılması",
              alreadyDoneTip: "Tamamlanmış.",
              totalTip: "Toplam",
              unit: "Madde",
              endReInspectionSuccess:
                "Yeniden kalite kontrol görevi sonlandırıldı",
              closeWindow: "Pencereyi kapat",
              endReInspectionFailed:
                "Yeniden kalite kontrol görevi başarısız oldu, lütfen daha sonra tekrar deneyin!",
            },
            detail: {
              name: "Kalite kontrol detayları",
              reviewName: "İnceleme Detayları",
              spotCheckName: "Örnekleme detayları",
              audioText: "Kayıt metni",
              callInfo: "Arama Bilgileri",
              satisfactionEvaluation: "Memnuniyet değerlendirmesi:",
              queueTime: "Kuyruk zaman alıcı:",
              ringingTime: "Zil zamanı:",
              callTime: "Çağrı olayları:",
              hangUpIdentity: "Telefonu kapatan taraf:",
              location: "Aidiyet yeri:",
              source: "Kaynak:",
              agent: "Müşteri Hizmetleri:",
              deviceType: "Cihaz tipi:",
              callType: "Çağrı Türü:",
              phoneNumber: "Röle numarası:",
              task: "Görev:",
              commentsTip: "Lütfen kalite değerlendirmesi girin",
              lastPage: "Önceki sayfa",
              nextPage: "Sonraki sayfa",
              score: "Kalite kontrol puanı",
              review: "İnceleme",
              file: "Arşiv",
              changeList: "Değişim kaydı",
              fullMarks: "Tam not",
              most: "En çok",
              saveReview: "Gözden geçirme ve gönderme",
              saveSpotCheck: "Örnekleme gönderme",
              point: "Kalite kontrol noktası",
              sampleAssignInspector: "Örnekleme denetçisinin atanması",
              reviewAssignInspector: "Tahsis edilen denetçiler",
              sampleStatus: "Örnekleme durumu",
              read: "Okunmuş",
              user: "Kullanıcı",
              confirmer: "Onaylayanlar",
              voiceDownloadError:
                "Sesli indirme başarısız oldu, lütfen daha sonra tekrar deneyin!",
              smartTags: "Akıllı etiketler",
              interactiveTip:
                "Müşteri hizmetleri SSS kullanımını tespit etmek için etkileşim tanımlamayı kullanın",
              interactiveIsEmptyTip: "Etkileşim tanımlayıcı içerik algılanmadı",
              businessRecords: "İş kayıtları",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Kurumsal WeChat grup sohbeti analizi",
            },
          },
        },
      },
      cards: {
        health: "Kalite sağlığı",
        accuracy: "Doğrulama oranı bulundu",
        sessionsTotal: "Toplam oturum sayısı",
        enable: "Etkinleştir",
        disable: "Devre dışı bırak",
        none: "Henüz yok",
        call: "Çağrı",
        dialogue: "Diyalog",
        taobao: "E-ticaret",
        wecom: "Oturum",
        ticket: "İş emri",
      },
      component: {
        backup: "(Yedek)",
        taskSaveAsSucceeded: "Görev Başarı Olarak Kaydet",
        copyContent: "İçeriği kopyala",
        essentialInformation: "Temel Bilgiler",
        ruleConfiguration: "Kural yapılandırması",
        basicInformationQualityInspector: "Temel Bilgiler-Kalite Müfettişi",
        qualityInspectionRating: "Kalite değerlendirmesi",
        roleConfiguration: "Rol Yapılandırması",
        autoAddConfiguration: "Yapılandırmayı otomatik olarak ekleme",
        autoPushConfiguration: "Otomatik Push Yapılandırma",
        automaticSamplingConfiguration:
          "Otomatik nokta kontrolü yapılandırması",
        automaticAllocationConfiguration:
          "Yapılandırmayı otomatik olarak atama",
        businessConfiguration: "İş Yapılandırması",
        caseBaseConfiguration: "Vaka kütüphanesi",
      },
      template: {
        cancel: "İptal",
        complete: "Tamamla",
        sort: "Sıralama",
        ruleApproval: "Kural onayı",
        enterpriseMicroConversationAnalysisTask:
          "Kurumsal mikro oturum analizi görevi",
        enterpriseAndMicroVoiceAnalysisTask:
          "Kurumsal mikro ses analizi görevi",
        saveTaskAs: "Görev olarak kaydet",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Kural detayları" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Değişiklikler başarılı oldu",
            automaticApproval: "Otomatik onay",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Değişiklikler başarılı oldu",
            test: "Test",
            formal: "Resmen",
            adopt: "Tarafından",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Reddedildiğinden emin misiniz?",
              pleaseEnterTheRejectionReason: "Lütfen reddetme nedenini girin",
              reject: "Reddedilen",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Değişiklikler başarılı oldu",
              taskName: "Görev adı",
              dataSource: "Veri kaynağı",
              qualityInspectionType: "Kalite kontrol tipi",
              state: "Durum",
              updateTime: "Güncelleme zamanı",
              updatedBy: "Güncellenmiş insanlar",
              approver: "Onaylayan",
              reasonForRejection: "Reddedilen sebep",
              operation: "Operasyon",
              details: "Detaylar",
              adopt: "Tarafından",
              whole: "Hepsi...",
              approvalStatus: "Onay durumu",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Genel bakış",
      hi: "Hey, hey, hey.",
      standardEdition: "Standart Sürüm",
      call: "Ses kalitesi dengesi",
      text: "Metin kalite kontrol geçerlilik süresi",
      collectionTasks: "Koleksiyon kalite kontrol görevleri",
      noCollection: "Koleksiyonda kalite kontrol görevi yok,",
      goToCollecte: "Koleksiyona git",
      yesterdayOverView: "Dün iş istatistikleri",
      yesterdayAgent: "Dün müşteri hizmetleri sıralaması",
      yesterdayInspWork: "Dün kalite denetçisinin çalışması",
      operationalStatistics: {
        title: "İş İstatistikleri",
        qualityTesting: "Kalite kontrol",
        spotCheck: "Örnekleme",
        appeal: "İtiraz",
        chartTitles: {
          qualityTestingTrend: "Kalite kontrol hacmi eğilimi",
          qualityTestingAnalysis: "Kalite kontrol görevleri",
          spotCheckTrend: "Örnekleme eğilimi",
          spotCheckAnalysis: "Örnekleme görevi",
          appealTrend: "Şikayet Trendleri",
          appealAnalysis: "Şikayet görevi",
        },
        titles: {
          qualityDataTend: "Kalite kontrol veri dağılımı",
          qualityCover: "Kalite kontrol kapsamı",
          qualityAverageTime: "Ortalama kalite kontrol bekleme süresi",
          qualityCoverTend: "Kalite kontrol kapsamı eğilimi",
          handCover: "Manuel tedavi",
          checkCover: "Örnekleme kapsamı",
          resAverageTime: "Ortalama işlem süresi",
          handTend: "Yapay işleme eğilimi",
          dataTend: "Veri görev dağılımı",
        },
        qualityAverage: "Kalite kontrol ortalaması",
        scoreTrend: "Kalite kontrol puanı eğilimi",
        qualityAverageTime: "Ortalama kalite kontrol bekleme süresi",
        callCoverage: "Ses kalitesi kapsamı",
        textCoverage: "Metin kalite kontrol kapsamı",
        checkCount: "Örnekleme veri hacmi",
        siphonCount: "Ekstraksiyon veri hacmi",
        reviewCount: "Veri miktarını gözden geçirme",
        appealCount: "İtiraz veri hacmi",
        checkRate: "Örnekleme uygulama oranı",
        reviewRate: "Temyiz İnceleme Oranı",
        PieTitle: {
          totalCount: "Toplam diyalog sayısı",
          totalCall: "Sesli arama sayısı",
          totalIm: "Metin konuşma sayısı",
          inspectionCall: "Ses kalitesi",
          inspectionIm: "Metin kalitesi",
          checkCall: "Ses örnekleme hacmi",
          unCheckCall: "Ses örnekleme hacmi",
          checkIm: "Metin örnekleme hacmi",
          unCheckIm: "Örnekleme yapılmayan metin",
          reviewCall: "Ses incelemesi",
          reviewIm: "Metin inceleme miktarı",
          unReviewCall: "İncelenmemiş ses miktarı",
          unReviewIm: "İncelenmemiş metin miktarı",
          unInspectionCall: "Ses kalitesi",
          unInspectionIm: "Kalite kontrol edilmeyen metin",
        },
        check_rate: "Örnekleme kapsamı",
        voice_check_rate: "Konuşma örnekleme kapsamı",
        text_check_rate: "Metin örnekleme kapsamı",
        sample_execute_time: "Ortalama örnekleme süresi",
        review_execute_time: "Ortalama şikayet işleme süresi",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Kanban Kalite Kontrol Kuralları",
          agent: "Müşteri Hizmetleri Sıralaması",
        },
        tableTips: {
          conditions:
            "<Div> Kalite kontrol kurallarına aykırı ve uyumlu toplam iletişim sayısını sıralayın. <Br/>\nKalite kontrol maddesi: Kalite kontrol noktasının adı <br />\nToplam yasadışı iletişim diyalogları: Bu kalite kontrol maddesinde yasadışı iletişim diyaloglarının toplam sayısı <br />\nİhlallerin Oranı: İhlallerin Oranı = İhlallerin Toplam Diyalog Sayısı/Toplam Diyalog Sayısı </div>",
          agent:
            "<Div> Oturanlar için ortalama puanı sıralayın <br />\nMüşteri Hizmetleri Adı: Müşteri Hizmetleri Adı <br />\nMüşteri Hizmetleri Grubu: Müşteri Hizmetleri Müşteri Hizmetleri Grubu (Müşteri Hizmetleri Bilgileri içindeki Organizasyon Bilgileri)<br />\nPuanlayan toplam iletişim sayısı: Müşteri hizmetleri için toplam kalite kontrol iletişim sayısı <br />\nKalite kontrol ortalama puanı: Müşteri hizmetleri için kalite kontrol ortalama puanı (toplam puan/iletişim sayısı)</div>",
        },
        tips: {
          total: "Kalite kontrol görevine giren toplam iletişim/diyalog sayısı",
          agentTotal:
            "Kalite kontrol görevine giren toplam kalite kontrol müşteri hizmetleri sayısı",
          avg: "Kalite kontrol puanının ortalama puanı",
          violation: "Tüm diyaloglar",
          compliance:
            "Diyalog sırasında kalite kontrol maddelerinin uygunluğunun toplamı",
          check: "Toplam manuel örnekleme sayısı",
          appeal: "Toplam diyalog sayısında sunulan toplam şikayet sayısı",
          review: "Toplam diyalog sayısında gönderilen toplam inceleme sayısı",
        },
        subTips: {
          violation:
            "İhlal oranı: toplam ihlal sayısı/toplam kalite kontrol sayısı * % 100",
          compliance:
            "Uyum oranı: Toplam uyumluluk sayısı/toplam kalite kontrol sayısı * % 100",
          check:
            "Örnekleme kapsamı: toplam manuel örnekleme sayısı/toplam kalite kontrol * % 100",
          appeal:
            "Şikayet oranı: toplam şikayet sayısı/toplam kalite kontrol * % 100",
          review:
            "İnceleme oranı: toplam inceleme sayısı/toplam kalite kontrol * % 100",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Sistem Ayarları",
          systemName: "Sistem adı",
          speakerRecognize: "Konuşan tanıma",
          massage: "Mesaj Bildirimi",
          evaluate: "Yapay bilişsel değerlendirme",
          evaluateTip:
            "Açtıktan sonra, manuel değerlendirme için kalite kontrol detaylarında hızlı bir nokta kontrolü seçin.",
          speakerRecognizeTip:
            "API üzerinden üçüncü taraf arama kaydına erişim, sol kanalın müşteri hizmetleri mi yoksa müşteri mi olduğunu belirtmeniz gerekir",
          speakerRecognizeTip2:
            "Metin tanıma, rol konfigürasyonu ile müşteri hizmetleri rolünü tanımlayan tek kanallı kayıt",
          enabledImSendStatusFilter: "Metin kalite kontrol mesaj filtreleme",
          enabledImSendStatusFilterTips:
            "Kalite kontrolünde operatör sözlerinin tespit içeriği, [Geri Çekildi], [Gönderilemedi], [Çevrimdışı Mesaj Okunmamış] durumundaki mesajları içermez",
          lang: "Sistem dili ayarları",
          zh: "Basitleştirilmiş Çince",
          en: "English",
          tw: "Geleneksel Çince",
        },
        template: {
          largeModelSettings: "Büyük model ayarları",
          indonesian: "Endonezya dili",
          vietnamese: "Vietnamca",
          portuguese: "Portekizce",
          malay: "Malay dili",
          french: "Fransızca",
          spanish: "İspanyolca",
          japanese: "Japonca",
          thai: "Tay dili",
          blockSystemSendingMessages: "Mesaj göndermek için sistem engelleme",
          textDataDesensitization: "Metin Verileri Duyarsızlaştırma",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Duyarsızlaştırma alanlarının açılır listesini (çoklu seçim desteği) görüntülemek için anahtarı açın, duyarsızlaştırma alanı: kişi adı, cep telefonu numarası, adres",
          desensitizingField: "Duyarsızlaştırma alanı",
          name: "İnsan adı",
          cellphoneNumber: "Telefon numarası",
          address: "Adres",
          dataCleaning: "Veri Temizleme",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Ayarladıktan sonra, kalite kontrol diyalogu ve kurumsal WeChat oturum verileri düzenli olarak temizlenecektir ve geri yüklenemez. Lütfen dikkatli olun",
          newCleaningRules: "Yeni temizleme kuralı",
          qualityInspectionRuleApproval:
            "Kalite kontrol kurallarının onaylanması",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Açtıktan sonra, kalite kontrol kurallarında yapılan değişikliklerin yürürlüğe girmeden önce onaylanması gerekir.",
          maskOfflineMessageUnread: "Okunmamış çevrimdışı mesajları engelleme",
          privacyNumberProtection: "Gizlilik numarası koruması",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Açtıktan sonra, çağrıda "müşteri telefonu" alanını duyarsızlaştırın ve 1881 ******* olarak görüntülenir.',
          shieldingWithdrawn: "Engelleme geri çekildi",
          maskSendingFailed: "Engelleme Gönderme Başarısız",
        },
        component: {
          conversation: "Çağrı",
          dialogue: "Diyalog",
          weCom: "Kurumsal WeChat",
          day: "Gün",
          week: "Hafta",
          month: "Ay.",
          year: "Yıl",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Temizleme aralığı",
            dataCleaningTimeSetting: "Veri Temizleme Zaman Ayarları",
            afterCleanUpTheData: "Sonra, verileri temizleyin",
            dataAfterComplaintCompletion:
              "Şikayet tamamlandıktan sonra veriler",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Başarılı bir yürütme",
          saveSuccessful: "Başarıyı kaydet",
          speechRecognition: "Ses tanıma",
          reExecuteErrorCorrection: "Hata düzeltmesini yeniden yürüt",
          confirmExecution: "Yürütme onayı",
          voiceDetection: "Ses algılama",
          noiseDetectionPowerLevel: "Gürültü algılama powerLevel ≤",
          pleaseEnterTheThreshold: "Lütfen bir eşik girin",
          volumeDetectionPowerLevel: "Ses seviyesi algılama powerLevel ≥",
          reset: "Sıfırla",
          preserve: "Kaydet",
        },
        coachTemplate: {
          french: "Fransızca",
          spanish: "İspanyolca",
          japanese: "Japonca",
          thai: "Tay dili",
          loginPopupWindow: "Açma penceresi",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Açtıktan sonra, akıllı tartışma sistemine girerken açılır pencere istemi",
          title: "Başlık",
          text: "Metin",
          checkBoxContent: "Onay kutusu içeriği",
          informedConsentForm: "Bilgilendirilmiş onam",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "İletişim ve konuşma becerilerinizi ve uzmanlık bilginizi geliştirmenize yardımcı olacak bir uygulama için, istediğiniz zaman, istediğiniz yerde pratik yapmanıza ve gerçek zamanlı geri bildirim almanıza olanak tanır.",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Bu bilgilendirilmiş rızayı tam olarak okudum ve tam olarak anladım ve hassas kişisel bilgilerimin bu bilgilendirilmiş rızada belirtilen amaç ve yönteme uygun olarak ele alınmasını kabul ediyorum.",
        },
      },
      systemIntegration: {
        title: "Sistem Ayarları",
        ccIntegrationTips:
          "Akıllı Kalite Kontrol Yerleştirme Udesk Çağrı Merkezi Açıklama Belgeleri",
        unauthorized: "Yetkisiz",
        authorized: "Yetkili",
        udeskDomain: "Müşteri Hizmetleri Sistemi Alan Adı",
        udeskToken: "Müşteri Hizmetleri Sistem Anahtarı",
        udeskAdminEmail: "Müşteri Hizmetleri Sistemi Yönetici Hesabı",
        password: "Yönetici hesabı şifresi",
        applyAuth: "Yetkilendirme",
        cancelAuth: "Yetki iptali",
        reApplyAuth: "Yeniden yetkilendirme",
        keyTips:
          "Müşteri Hizmetleri Sistemi Arka Plan Yönetimi-Tek Oturum Açmada API Takdiri Token",
      },
      customerField: {
        title: "Müşteri alanı",
        table: {
          propName: "Alan adı",
          propDesc: "Alan açıklaması",
          valueType: "Alan türleri",
          propType: "Oluşturma yolu",
          createTime: "Zaman oluşturma",
          canNotEdit: "Sistem alanları düzenlemeyi desteklemiyor",
        },
        edit: "Alanı düzenleme",
        new: "Yeni alan",
      },
      wechatDocking: {
        title: "Kurumsal mikro yerleştirme",
        formLabels: {
          companyId: "Kurumsal Kimlik",
          communicationKey: "İletişim anahtarı",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "Olay geri arama URL'si",
          applicationName: "Uygulama adı",
          applicationId: "Web uygulaması kimliği",
          applicationSecret: "Uygulama secret",
        },
        formTitle: {
          initialForm: "İlk Kurulum",
          synchronizeForm: "Kişiler senkronizasyonu",
          settingForm: "Uygulama Ayarları",
        },
        qrCodeUrlTip:
          "Yöneticiden uygulama bağlama geliştirmek için aşağıdaki QR kodunu taramasını isteyin",
      },
      taobao: {
        formLabels: {
          name: "Mağaza adı",
          account: "Hesap numarası",
          password: "Şifre",
          confirm: "Şifreyi onaylayın",
          authorization: "Yetkilendirme",
          switchOn: "Açık",
          switchOff: "Kapat",
        },
        tableLabels: {
          name: "Mağaza adı",
          account: "Hesap numarası",
          authorization: "Yetkilendirme",
          updateUser: "Son Güncellemeler",
          updateTime: "Son güncelleme",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Weifeng ikinci seviye alan adı",
          secretKeyTip:
            "Kiracı anahtarı, Weifeng sisteminin arka plan sistemi yerleştirmeden elde edildi",
        },
      },
      user: {
        index: {
          name: "Çalışan Yönetimi",
          existingUsers: "Mevcut çalışanlar",
          deleteSuccess: "Silme başarısı",
          deleteFailed: "Silme işlemi başarısız oldu",
        },
        detail: {
          accountPrompt: "Lütfen e-posta adresini girin",
          resetPassword: "Şifrenizi sıfırlama",
          passwordPrompt: "Şifre (6-14 basamaklı harf ve sayı kombinasyonu)",
          originPasswordPrompt: "Lütfen ilk şifrenizi girin",
          resumePasswordPrompt: "Lütfen şifrenizi tekrar girin",
          passwordFormWrong:
            "Lütfen 6-14 harf ve sayı kombinasyonunun şifresini girin!",
          resumePasswordWrong: "Şifreler iki kez tutarsız!",
          suppertImageType: "Destek jpg, gif, png, maksimum 500Kb",
          namePrompt: "Lütfen adınızı girin",
          nickNamePrompt: "Lütfen takma ad girin",
          telephonePrompt: "Lütfen bir telefon numarası girin",
          roleSelectedPrompt: "Lütfen bir karakter seçin",
          agentIdPrompt: "Lütfen iş numarasını girin",
          disabled: "Devre dışı bırak",
          enabled: "Etkinleştir",
          reloadBowerInfo:
            "Kullanıcı bilgileri değişiyor, tarayıcıyı yeniden yenilemeniz mi gerekiyor?",
          saveUserError:
            "Kullanıcı bilgileri kaydedilemedi, lütfen daha sonra tekrar deneyin",
          resetPasswordSuccess: "Şifre sıfırlama başarılı",
          resetPasswordFailed:
            "Şifre sıfırlama başarısız oldu, lütfen daha sonra tekrar deneyin",
        },
      },
      userGroups: {
        index: {
          name: "Personel Grubu Yönetimi",
          existingUserGroups: "Mevcut personel grubu",
        },
        detail: {},
      },
      role: {
        index: { name: "Rol yönetimi", existingRoles: "Mevcut roller" },
        edit: {
          editRole: "Düzenleme rolü",
          createRole: "Karakter oluşturma",
          basicInfo: "Temel Bilgiler",
          taskPermissions: "Görev İzinleri",
          existingTasks: "Mevcut görevler",
        },
      },
      anomalousData: {
        title: "Anormal veriler",
        list: {
          selectHolder: "Lütfen veri kaynağını seçin",
          businessTime: "İş zamanı",
          businessId: "Id Iş",
          export: "İhracat",
        },
      },
      templateComponent: {
        list: {
          title: "Şablon pazarı",
          name: "Şablon adı",
          searchHolder: "Lütfen kontrol etmek istediğiniz içeriği girin",
          update: "Güncelleme durumu",
          sort: "Sıralama yöntemi",
          moduleType: "Şablon tipi",
          moduleSourceType: "Uygulanabilir tip",
          fetchNum: "Alım sayısı",
          newest: "Son güncelleme",
          usedMost: "En çok alın",
          call: "Çağrı",
          dialogue: "Diyalog",
          waiting: "İncelenecek",
          cancel: "Yayını iptal et",
          success: "Denetim geçti, yayınlandı",
          failed: "İnceleme reddedildi",
          no: "Yükseltilemez",
          ok: "Yükseltilebilir",
          all: "Hepsi...",
          delete: "Silme başarısı",
          downOk: "Başarıyla",
          withdraw: "Çekilme başarısı",
          updateOk: "Başarıyla yükseltme",
          get: "Alın",
          width: "Çekilme",
          getOk: "Başarı elde etmek",
          orgName: "Bağlı kuruluş",
          nowVersion: "Güncel sürüm",
          lastedVersion: "Son sürüm",
          appStatus: "Uygulama durumu",
          actions: "Operasyon",
          down: "Raftan çıkart",
          updateTop: "Yükseltme",
          check: "Görüntüle",
          deleteWhen: "Silme işlemini onaylıyor musunuz?",
          yes: "Evet, evet.",
          noo: "Hayır, hayır.",
          deleteOk: "Silme",
          permissible: "İzin ver",
          noPermissible: "İzin verilmiyor",
          openUp: "Açık",
          openDown: "Açık değil",
          task: "Kalite kontrol görevleri",
          point: "Kalite kontrol noktası",
          tag: "Anlamsal etiketler",
          muti: "Çoklu elemanlar",
          inter: "Bir soru ve cevap",
          info: "Bilgi varlıkları",
          concept: "Kavramsal kelime",
          detail: "Modül detayları",
          infomation: "Temel Bilgiler",
          detailConfig: "Ayrıntılar yapılandırması",
          twoEdit: "İkincil düzenleme",
          mustUpdate: "Şimdi Yükseltme",
          descition: "Şablon açıklaması",
          descitionSize: "Şablon açıklaması 250 kelimeden fazla olamaz",
          stencilsDetail: "Şablon detayları",
          updatedRecords: "Kayıt Güncelleme",
          version: "Sürüm numarası",
          updateDescription: "Güncelleme açıklaması",
          myModule: "Benim Şablon",
          addModule: "Yeni modül",
          publishVersion: "Sürüm yayınlandı",
          earliestVersion: "En eski sürüm",
          edit: "Düzenleme",
          add: "Yeni",
          configNo: "Modül yapılandırma detayları boş olamaz",
          updateNo: "Güncelleme talimatları boş olamaz",
          editSuccess: "Değişiklikler başarılı oldu",
          saveSuccess: "Başarıyı kaydet",
          newer: "Güncelleme",
          sumbit: "Gönderme",
          cancelName: "İptal",
          nameRequired: "Modül adı gerekli bir alandır",
          nameSize: "Modül adı 16 kelimeden fazla olamaz",
          publishStatus: "Yayın durumu",
          submitUpdate: "Güncelleme Gönder",
          updateVersion: "Güncelleme sürümü",
          updateExplain: "Güncelleme talimatları",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon e-ticaret",
        youtubeVideo: "Youtube Videoları",
        amazon: {
          component: {
            queuing: "Sırada",
            notStarted: "Başlamamış.",
            inProgress: "Devam ediyor",
            success: "Başarı",
            failed: "Başarısızlık",
            successfullyCreated: "Başarılı Oluşturma",
            editedSuccessfully: "Düzenleme Başarısı",
            operationSucceeded: "Operasyon başarılı oldu",
            deletedSuccessfully: "Silme başarısı",
            link: "Bağlantı",
            grabState: "Yakalama durumu",
            updateTime: "Güncelleme zamanı",
            grab: "Yakalama",
            edit: "Düzenleme",
          },
          template: {
            amazonEcommerce: "Amazon e-ticaret",
            thisProduct: "Bu ürün",
            competition: "Rekabet",
            new: "Yeni",
            edit: "Düzenleme",
            link: "Bağlantı",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube Videoları",
            thisProduct: "Bu ürün",
            competition: "Rekabet",
            new: "Yeni",
            edit: "Düzenleme",
            link: "Bağlantı",
          },
          component: {
            queuing: "Sırada",
            notStarted: "Başlamamış.",
            inProgress: "Devam ediyor",
            success: "Başarı",
            failed: "Başarısızlık",
            successfullyCreated: "Başarılı Oluşturma",
            editedSuccessfully: "Düzenleme Başarısı",
            operationSucceeded: "Operasyon başarılı oldu",
            deletedSuccessfully: "Silme başarısı",
            youTubeVideoLink: "YouTube Video Bağlantısı",
            grabState: "Yakalama durumu",
            updateTime: "Güncelleme zamanı",
            grab: "Yakalama",
            edit: "Düzenleme",
          },
        },
      },
    },
    demo: {
      title: "Akıllı kalite kontrol",
      template: "Kalite kontrol şablonunu seçin",
      radio: "Kayıt formunu seçin",
      uploadAudio: "Kaydı yükle",
      uploadAudioTip:
        "Yükleme kayıtları sadece wav ve mp3 formatlarını destekler, boyutu 1G 'yi geçmez",
      uploadText: "Metin yükle",
      uploadTextTip:
        "Yükleme metni yalnızca srt ve txt formatlarını destekler, boyutu 10M 'yi geçmez, metni yükledikten sonra ASR artık kayıt dosyalarını ayrıştırmak için kullanılmaz",
      uploadSuccess: "Kayıt dosyası başarıyla yüklendi",
      uploadFailed: "Kayıt dosyası yükleme başarısız oldu",
      startQualityInspection: "Kalite kontrolüne başlayın",
      qualityInspectionStarted: "Kalite kontrolünde",
      downloadText: "Metin indir",
      checking: "Kalite kontrol ilerleme",
      checkingTip: "Birkaç dakika sürmesi bekleniyor, lütfen sabırlı olun",
      noCheckContent: "Kalite kontrol sonucu yok",
      noTextDownload: "İndirilebilecek kalite kontrol metni yok!",
      pleaseCheckParams:
        "Şablon, ses türü ve yükleme kayıt dosyası seçip seçmediğinizi kontrol edin",
      inspectionFailed:
        "Kalite kontrol başarısız oldu, lütfen daha sonra tekrar deneyin!",
    },
    wechatQa: {
      baseInfo: { title: "Temel Bilgiler" },
      conversationSetting: {
        title: "Oturum Ayarları",
        segmentationStrategy: "Kesme stratejisi",
        rule: "Strateji",
        time: "Kesim zamanı",
        daily: "Her gün",
        passScore: "Oturum geçme puanı",
        ruleLogic: "Yürütme mantığı",
      },
      message: {
        title: "İtme olayları",
        table: {
          index: "Seri numarası",
          name: "İsim",
          type: "İtme yöntemleri",
          desc: "Açıklama",
          status: "Durum",
        },
        subTitle: "Etkinlik listesi",
      },
      checkPoint: {
        title: "Analiz modeli",
        groups: "Model gruplama",
        newClassification: "Yeni gruplama",
        editClassification: "Gruplamayı Düzenle",
        benchmarkScore: "Temel puan",
        pointCount: "Mevcut sınıflandırma altında ortak analiz modeli",
        openPointCount: "Etkinleştirme sayısı",
        closedPointCount: "Devre dışı bırakma sayısı",
        scoreLowerLimit: "Minimum puan sınırı",
        scoreUpperLimit: "Maksimum puan sınırı",
        classificationName: "Grup adı",
        classificationNamePlaceHolder: "Lütfen bir grup adı girin",
        classificationRemark: "Grup açıklaması",
        classificationRemarkPlaceHolder: "Lütfen gruplama açıklamasını girin",
      },
      chartRecord: {
        title: "Kurumsal WeChat Oturumu",
        table: {
          id: "Oturum Kimliği",
          type: "Oturum tipi",
          startTime: "Oturum Başlangıç Zamanı",
          endTime: "Oturum Son Bitiş Saati",
          groupId: "Grup kimliği",
          client: "Müşteri",
          customer: "Müşteri Hizmetleri",
          averageScore: "Ortalama kalite kontrol puanı",
        },
        detail: {
          title: "Kurumsal WeChat Oturum Detayları",
          inspectionInfo: "Bilgi analizi",
          chartInfo: "Oturum Bilgileri",
          tasks: "Analiz görevleri",
          avgScore: "Analiz ortalaması",
          qualified: "Nitelikli",
          unqualified: "Niteliksiz",
          taskTitle: "Uygulanabilir analiz şablonları",
          info: "Gerçek zamanlı analiz bilgileri",
          today: "Bugün Haberler",
          history: "Tarihsel mesajlar",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Lütfen not/takma ad girin",
                pleaseEnterTheGroupChatName:
                  "Lütfen grup sohbeti için bir ad girin",
              },
            },
          },
          template: { whole: "Hepsi..." },
        },
      },
      realTimeAnalysis: {
        title: "Gerçek zamanlı analiz",
        table: {
          name: "Sahne adı",
          count: "Analiz modeli sayısı",
          baseScore: "Temel puan",
          passScore: "Nitelikli puan",
          maxScore: "Maksimum puan sınırı",
          minScore: "Minimum puan sınırı",
          status: "Durum",
          actions: "Operasyon",
        },
        detail: {
          title: "Sahneleri gerçek zamanlı olarak analiz edin",
          form: {
            name: "Sahne adı",
            prevCondition: "Sahne Tetikleyici Koşullar",
            prevConditionToolTip:
              "Koşullar boşsa, bu sahne varsayılan olarak analiz edilir",
            prevConditionError:
              "Senaryo tetikleme koşulları analitik modelle çakışamaz",
            scoreInputPlaceholder: "Puanı girin",
            baseScore: "Temel puan",
            status: "Durum",
            passScore: "Nitelikli puan",
            maxScore: "Maksimum puan",
            minScore: "Minimum alt sınır",
            analysisModel: "Analiz modeli",
            message: "Tetikleyici bildirim",
            pushType: "İtme yöntemleri",
            createAnalysisModel: "Model ekle",
            scoreUpperLimitError:
              "Minimum alt sınır, maksimum puanın üst sınırından daha büyük olamaz",
            table: {
              name: "Model adı",
              rule: "Kurallar",
              score: "Puan değeri",
            },
            pushConfigSwitchTip1: "Sahne ortaya çıktığında",
            pushConfigSwitchTip2: "Push bildirimleri zaman ve üzeri",
          },
        },
      },
      smartCustomerTag: {
        title: "Akıllı Müşteri Etiketi",
        table: {
          name: "Kural adı",
          tags: "Kurumsal Mikro Müşteri Etiketi",
          status: "Durumu etkinleştir",
          createUser: "Yarat",
          createTime: "Zaman oluşturma",
        },
        detail: {
          name: "Kural adı",
          condition: "Kuralları ayarla",
          logic: "Mantık",
          status: "Durumu etkinleştir",
          tags: "Etiketleri seçin",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Müşteri İşaretleme Sayısı" },
        },
        messageFiltering: {
          template: {
            edit: "Düzenleme",
            messageContent: "Mesaj içeriği",
            messageTypeID: "Mesaj türü kimliği",
            messageTypeDescription: "Mesaj türü açıklaması",
            messageFiltering: "Mesaj filtreleme",
            suitableForFilteringDataWithoutQualityInspection:
              "Kalite kontrol gerektirmeyen verileri filtrelemek için uygundur",
            create: "Oluşturma",
            messageDescription: "Mesaj açıklaması",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Konuşma süresi",
          sessionType: "Oturum tipi",
          whole: "Hepsi...",
          staff: "Çalışanlar",
          customer: "Müşteri",
          callKeywords: "Arama Anahtar Kelimeler",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Kurumsal mikro sesli arama detayları",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Oturum Arşivi",
        voiceCall: "Sesli arama",
        noMoreVoiceCalls: "Daha fazla sesli arama yok",
        enterpriseWechatVoiceCall: "Kurumsal WeChat Sesli Arama",
        enterpriseWechatConversationDetails: "Kurumsal WeChat Oturum Detayları",
        previous: "Bir önceki",
        next: "Bir sonraki",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Kurumsal mikro oturum analizi görevi",
          enterpriseAndMicroVoiceAnalysisTask:
            "Kurumsal mikro ses analizi görevi",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Kurumsal WeChat grup sohbeti analizi",
      },
      wechatQaSettingEntry: { messageFiltering: "Mesaj filtreleme" },
    },
    businessAnalysis: {
      keyword: "Rapor adı",
      createUser: "Yarat",
      createTime: "Zaman oluşturma",
      updateTime: "Güncelleme zamanı",
    },
    semanticIntelligence: {
      applicationMessageType: "Uygulama mesaj türleri",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration:
                "Lütfen yapılandırmayı mükemmelleştirin",
              generatePromptLanguage: "İstemi Oluşturma",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Uyup uymadığı",
              gradedEvaluation: "Bölünme değerlendirmesi",
            },
          },
          training: {
            index: {
              pleaseEnter: "Lütfen girin",
              pleaseGeneratePrompt: "Lütfen bir hatırlatma oluşturun",
              pleaseSelectADialogue: "Lütfen konuşmayı seçin",
              training: "Eğitim",
              dataType: "Veri Türü:",
              voiceCall: "Sesli arama",
              textDialogue: "Metin konuşmaları",
              generate: "Oluştur",
              qualityInspectionResults: "Kalite kontrol sonuçları:",
              hit: "Vuruş Sözleri:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Model adı",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Lütfen model adını girin, örneğin: müşteri hizmetlerinde herhangi bir eylemsizlik olup olmadığı",
            modelDescription: "Model açıklaması",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Model tarafından anlaşılması kolay, test etmeniz gereken içeriği ayrıntılı olarak açıklayabilirsiniz.",
            validity: "Geçerlilik",
            effective: "Etkili",
            invalid: "Geçersiz",
            type: "Türü",
            hintLanguage: "İpuçları",
            editIntelligentModel: "Akıllı Modeli Düzenle",
            createANewIntelligentModel: "Yeni akıllı model",
          },
        },
        index: {
          modelName: "Model adı",
          modelDescription: "Model açıklaması",
          type: "Türü",
          whetherItMeetsTheRequirements: "Uyup uymadığı",
          gradedEvaluation: "Bölünme değerlendirmesi",
          validity: "Geçerlilik",
          intelligentModel: "Akıllı model",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Öznel kalite kontrolüne uygulanabilir (örneğin, kalite kontrol hizmeti tutumunun coşkusu, hizmetin sabrı vb.)",
        },
      },
      clusterAnalysis: "Keleme analizi",
      speechMining: "Konuşma madenciliği",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "API adı kaydedildikten sonra düzenlemeyi desteklemeyen API arabirimi aracılığıyla bilgi varlıklarının toplu içe aktarılmasını destekler",
        },
        component: { nothing: "Hayır, hayır." },
      },
      knowledgeBase: {
        index: {
          classification: "Sınıflandırma",
          import: "İçe aktarma",
          importKnowledgeBase: "Bilgi bankalarını içe aktarma",
          moveKnowledgePointsInBatch: "Bilgi noktalarını toplu taşıma",
          moveTo: "Taşı:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Lütfen toplu taşıma gerektiren bilgi noktalarını seçin",
        },
        detail: {
          index: {
            intrasentence: "Cümlenin içinde",
            afterProblem: "Sorulardan sonra",
          },
        },
      },
      semanticTags: { component: { nothing: "Hayır, hayır." } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Hassas Kelimeler",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Hassas kelimeler: Hassas kelimeler, hassas kelimeleri bulmak için kamuya açık kelimeler için yapılandırılabilir.",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Yeni hassas kelimeler",
            editSensitiveWords: "Hassas kelimeleri düzenleme",
            sensitiveWords: "Hassas Kelimeler",
          },
          template: {
            sensitiveWords: "Hassas Kelimeler",
            sensitiveWordsCannotBeEmpty: "Hassas kelimeler boş olamaz",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Lütfen hassas kelimeleri girin, ',' ile ayrılmış birden fazla hassas kelime",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Lütfen uygulama sahnesini seçin",
            keyWord: "Anahtar kelimeler",
            pleaseEnterKeywords: "Lütfen anahtar kelimeleri girin",
            keywordLengthWithinCharacters:
              "Anahtar kelime uzunluğu 40 karakter içinde",
            dataSources: "Veri kaynağı",
            pleaseSelectADataSource: "Lütfen veri kaynağını seçin",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Şu anda kümeleme tamamlandı, yeniden düzenleme yeni bir kümeleme verisi üretecek ve tamamlanan kümeleme verilerinin üzerine yazılmayacak, lütfen onaylayın",
            savingSucceeded: "Başarıyı kaydet",
            clusteringStart: "Keleme başlangıcı",
            clustering: "Kümeleme",
            edit: "Düzenleme",
            newlyBuild: "Yeni",
            clusterName: "Keleme adı",
            pleaseEnterAName: "Lütfen bir ad girin",
            withinCharactersOfTheName: "Ad uzunluğu 40 karakter içinde",
            speaker: "Konuşmacılar",
            pleaseSelectASpeaker: "Lütfen konuşmacınızı seçin",
            sessionDate: "Oturum tarihi",
            pleaseEnterTheSessionDate: "Lütfen oturum tarihini girin",
            selectUpToMonthOfHistoricalSessionData:
              "En fazla 1 ay geçmiş oturum verisi seçin",
            automaticallyClusterDailyNewData:
              "Otomatik kümeleme günlük yeni veri",
            conversationChannel: "Oturum kanalları",
            pleaseSelectAConversationChannel: "Lütfen oturum kanalını seçin",
            applicationScenario: "Uygulama senaryoları",
            clusteringRules: "Keleme kuralları",
            incompleteClusteringRuleSettings: "Keleme kuralı ayarı eksik",
            startClustering: "Kaklamaya başla",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Lütfen kalite kontrol görevini seçin",
              beEqualTo: "Eşittir",
            },
          },
        },
        detail: {
          index: {
            theme: "Tema",
            numberOfCorpora: "Korpusu sayısı",
            corpusCoverage: "Korpusu kapsama alanı",
            clusterDetails: "Kküme detayları",
          },
          ranking: {
            hotWords: "Sıcak kelimeler",
            numberOfCorpora: "Korpusu sayısı",
            speaker: "Konuşmacılar",
            sit: "Oturun.",
            passenger: "Konuk",
            hotSentence: "Sıcak cümle",
            corpusDetails: "Korpusu Detayları",
          },
        },
        index: {
          delete: "Silme",
          deletionSucceeded: "Silme başarısı",
          clusterName: "Keleme adı",
          speaker: "Konuşmacılar",
          creationTime: "Zaman oluşturma",
          state: "Durum",
          operation: "Operasyon",
          see: "Görüntüle",
          edit: "Düzenleme",
          clusterAnalysis: "Keleme analizi",
          newCluster: "Yeni kümeleme",
          clusteringStatus: "Keleme durumu",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Anahtar kelime arama itirazlarını girin",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Konuşma kütüphanesi",
                recommendedScripts: "Önerilen konuşma",
                common: "Toplam",
                strip: "Madde",
                scriptFavorites: "Konuşma Sık Kullanılanlar",
              },
              conditionPostfix: {
                detectRoles: "Rolleri tespit et",
                detectionRange: "Algılama aralığı",
                sentence: "Cümle",
              },
              customerFilter: {
                scriptFiltering: "Konuşma filtreleme",
                cancel: "İptal",
              },
              index: {
                viewDetails: "Ayrıntıları görüntüle",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Terk etmek istediğinizden emin misiniz? Terk edildikten sonra iyileşemez",
                abandonment: "Terk edilmiş",
                deletionSucceeded: "Silme başarısı",
                passenger: "Konuk",
                sit: "Oturun.",
                adopted: "Kabul edildi",
                determine: "Emin olmak için",
                cancel: "İptal",
                adopt: "Kabul",
              },
              pageRightFrom: {
                savingSucceeded: "Başarıyı kaydet",
                pleaseEnterTheScriptContent: "Lütfen konuşma içeriğini girin",
                pleaseSelectAFavoriteGroup:
                  "Lütfen Sık Kullanılanlar Gruplandırmayı seçin",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary: "İtiraz işleme kütüphanesi",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "Lütfen konuşmayı seçiniz",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "İtiraz işleme konuşma favorileri",
            },
            pageRightTable: {
              likeCancel: "Beğen iptal",
              likeSuccessfully: "Başarı gibi",
              adoptScripts: "Konuşmacının kabulü",
              operation: "Operasyon",
              viewConversationDetails: "Konuşma ayrıntılarını kontrol et",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Ürün satış noktası konuşma kütüphanesi",
              },
              pageRightTable: { keyPointsOfScript: "Sözcülük noktaları" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "İtiraz işleme konuşma favorileri",
              newGroup: "Yeni gruplama",
              editGroup: "Gruplamayı Düzenle",
              addGroup: "Gruplandırma ekleme",
              savingSucceeded: "Başarıyı kaydet",
              groupName: "Grup adı",
              pleaseEnterTheGroupName: "Lütfen bir grup adı girin",
              groupNameWithinCharacters: "Grup adı uzunluğu 20 karakter içinde",
              deletionSucceeded: "Silme başarısı",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings: "Konuşma tavsiye ayarları",
                },
                rightForm: {
                  savingSucceeded: "Başarıyı kaydet",
                  intelligentRecommendation: "Akıllı Tavsiye",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Kabul edilen mükemmel konuşma, benzer itirazlara sahip konuşma kenar çubuğunda otomatik olarak görüntülenir ve çalışanlar, konuşma incelemesi ayrıntıları sayfasında öğrenmek için önerilen konuşmayı doğrudan görüntüleyebilir.",
                  objectionsIncludedByDefault:
                    "Varsayılan olarak dahil edilen itirazlar",
                  addRecommendationTriggerSemantics:
                    "Artan tavsiye tetikleyici semantik",
                  remindEmployeesToView:
                    "Çalışanları kontrol etmelerini hatırlatın",
                  shareWithMoreEmployees: "Daha fazla çalışanla paylaş",
                  allHands: "Tüm personel",
                },
                smartToolbar: {
                  selectSemanticTag: "Semantik etiketleri seçin",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Geçerli paket altındaki tüm veri kayıtlarını dışa aktarıyor musunuz?",
                newScript: "Yeni kelimeler",
                editingScript: "Konuşma düzenleme",
                savingSucceeded: "Başarıyı kaydet",
                objection: "İtiraz",
                pleaseEnterAScriptObjection: "Lütfen İtirazları girin",
                speechTechnique: "Konuşma",
                pleaseEnterTheScriptContent: "Lütfen konuşma içeriğini girin",
                grouping: "Gruplama",
                pleaseSelectAFavoriteGroup:
                  "Lütfen Sık Kullanılanlar Gruplandırmayı seçin",
                adoptScripts: "Konuşmacının kabulü",
                passenger: "Konuk",
                sit: "Oturun.",
                numberOfViews: "Görüntüleme sayısı",
                useful: "Faydalı.",
                operation: "Operasyon",
                edit: "Düzenleme",
                delete: "Silme",
                deletionSucceeded: "Silme başarısı",
                scriptRecommendationSettings: "Konuşma tavsiye ayarları",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Konuşma kütüphanesi",
          scriptMining: "Konuşma madenciliği",
          objectionHandlingScripts: "İtiraz işleme",
          productSellingPointScripts: "Ürün satış noktası",
        },
        sellingPoint: {
          index: { newCluster: "Yeni kümeleme" },
          components: {
            callDetailModal: {
              sessionDetails: "Oturum Detayları",
              cancel: "İptal",
              determine: "Emin olmak için",
              choice: "Seçim",
            },
            pageCenterTable: { scriptOverview: "Sözcülük Genel Bakış" },
            pageLeftTable: {
              enterSearchKeywords: "Arama anahtar kelimelerini girin",
              keyWord: "Anahtar kelimeler",
            },
            pageRightTable: {
              index: {
                viewDetails: "Ayrıntıları görüntüle",
                scriptLibrary: "Konuşma kütüphanesi",
                scriptRefinement: "Sözcülük",
                adoptScript: "Konuşmacının kabulü",
              },
              tagsEdit: { keyPointsOfScript: "Sözcülük noktaları" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Ürün satış noktası konuşma kütüphanesi",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Varsayılan olarak dahil edilen satış noktaları",
                  },
                },
                index: {
                  keyWord: "Anahtar kelimeler",
                  pleaseSelectAKeyword: "Lütfen anahtar kelimeleri seçin",
                  keyPointsOfScript: "Sözcülük noktaları",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Lütfen konuşma noktalarını girin",
                  viewDetails: "Ayrıntıları görüntüle",
                  pleaseChooseToAdoptScript: "Lütfen konuşmayı seçiniz",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Eşleştirme mantığı",
            nLPMatching: "NLP Eşleştirme",
            strictlyMatchByWord: "Kelimelere Göre Kesinlikle Eşleştirme",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Mesaj türü adı",
          messageTypeID: "Mesaj türü kimliği",
          messageTypeDescription: "Mesaj türü açıklaması",
          applicationMessageType: "Uygulama mesaj türleri",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Kalite denetimi için farklı kanal verilerine erişim için uygundur ve kanal tanımlamasına göre sınıflandırılması gerekir.",
        },
        detail: {
          index: {
            messageTypeName: "Mesaj türü adı",
            pleaseEnterAMessageTypeName: "Lütfen mesaj türü adını girin",
            messageTypeDescription: "Mesaj türü açıklaması",
            describeYourMessageTypeInDetail:
              "Mesaj türünüzü ayrıntılı olarak açıklayabilir",
            editMessageType: "Mesaj türünü düzenleme",
            newMessageType: "Yeni mesaj türü",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Lütfen takım üyelerini seçin",
        existingFilter: "Mevcut filtreler",
        pleaseSelect: "Lütfen seçin",
        filterCondition: "Filtre koşulları",
        areYouSureToDeleteTheCurrentFilter:
          "Geçerli filtrenin silindiğini onaylıyor musunuz?",
        sessionDate: "Oturum tarihi",
        staff: "Çalışanlar",
        customer: "Müşteri",
        pleaseEnterCustomerName: "Lütfen müşteri adını girin",
        keyWord: "Anahtar kelimeler",
        whole: "Hepsi...",
        pleaseEnterSessionKeywords: "Lütfen oturum anahtar kelimelerini girin",
        sessionDuration: "Oturum süresi",
        sessionLabel: "Konuşma Sahnesi",
        pleaseSelectASessionLabel: "Lütfen konuşma sahnesini seçin",
        commentTopic: "Yorum Konuları",
        pleaseSelectACommentTopic: "Lütfen yorum konusunu seçin",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Geçerli filtrenin üzerine yazılıyor mu?",
        overlayFilter: "Kapsama filtresi",
        NewFilter: "Yeni filtre",
        newFilter: "Yeni filtre",
        filterName: "Filtre adı",
        pleaseEnter: "Lütfen girin",
        allCall: "Tüm aramalar",
        percentage: "Yüzde",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'Birden fazla anahtar kelime aramayı destekleyin, "veya" ilişki virgülle (Çince ve İngilizce) ayrılır ve "ve" ilişki aşağıdaki gibi bir bağla ayrılır:',
          callRecordID: "Arama kaydı kimliği",
          pleaseFillInTheCallRecordID:
            "Lütfen arama geçmişi kimliğini doldurun",
          minute: "Dakika",
          second: "Saniye",
          whole: "Hepsi...",
          callRecording: "Çağrı kaydı",
          enterpriseWechat: "Kurumsal WeChat",
          conversationChannel: "Oturum kanalları",
          pleaseSelectASessionChannel: "Lütfen oturum kanalını seçin",
          learningStatus: "Öğrenme Durumu",
          pleaseSelectLearningStatus: "Lütfen öğrenme durumunu seçin",
          notLearned: "Öğrenmedi",
          learned: "Öğrenildi",
          Minutes: "Dakika",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Filtreler boş, yeni filtreler desteklenmiyor!",
          filterAddedSuccessfully: "Filtre başarıyla eklendi!",
          failedToAddFilter: "Filtre yenilgisi eklendi!",
          filterDeletedSuccessfully: "Filtre başarıyla kaldırıldı!",
          filterDeletionFailed: "Filtre silme işlemi başarısız oldu!",
          filterOverwriteSucceeded: "Filtre kapsama başarısı!",
          filterOverwriteFailed: "Filtre kaplaması başarısız oldu!",
          theme: "Tema",
          pleaseSelectATheme: "Lütfen bir tema seçin",
          keyEvents: "Kritik olaylar",
          pleaseSelectAKeyEvent: "Lütfen Anahtar Olayları Seçin",
        },
        filterItem: {
          startRetesting: "Yeniden algılamaya başlayın",
          time: "Zaman",
          talkTime: "Arama süresi",
          keyEvents: "Kritik olaylar",
          totalImplementationRateOfSupervisionItems:
            "Toplam uygulama oranını denetleyin",
          sort: "Sıralama:",
          seeMore: "Daha fazlasını görün",
        },
        filterStatistics: {
          conversation: "Çağrı",
          weChat: "WeChat",
          quantity: "Miktar",
        },
      },
      caseBase: {
        index: {
          copyTo: "Kopyala",
          pleaseSelectTheLocationToMove: "Lütfen taşınacak yeri seçin!",
          folderCopiedSuccessfully: "Klasör başarıyla kopyalandı!",
          cancelCollectionSuccessfully: "Koleksiyonunuzu başarıyla iptal edin!",
          collectionSucceeded: "Koleksiyon başarılı!",
          starCaseBase: "Yıldız Vaka Kütüphanesi",
          move: "Hareket et",
          rename: "Yeniden adlandır",
          delete: "Silme",
          folderMovedSuccessfully: "Klasör başarıyla taşındı!",
          fileMovedSuccessfully: "Dosya başarıyla taşındı!",
          fileMoveFailed: "Dosya hareketi başarısız oldu!",
          folderAddedSuccessfully: "Klasör başarıyla eklendi!",
          folderRenameSucceeded: "Klasör yeniden adlandırma başarılı oldu!",
          fileRenameSucceeded: "Dosya yeniden adlandırma başarılı oldu!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Klasörü sildikten sonra geri alamayacaksınız, silmeyi onaylıyor musunuz?",
          deleteSucceeded: "Silme başarılı oldu!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Dosyaları sildikten sonra geri alamayacaksınız, silmeyi onaylıyor musunuz?",
          share: "Ortak",
          folders: "Klasörler,",
          calls: "Bir çağrı",
          newFolder: "Klasör eklendi",
          modifyFolder: "Klasörü değiştirme",
          title: "Başlık",
          pleaseEnter: "Lütfen girin",
          selectMoveLocation: "Hareket Konumunuzu Seçin",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Hatırlatmayı öğrenin",
          subscriptionDynamics: "Abonelik dinamikleri",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "Abone olduğunuz oturumlar (ID",
            thereAreDynamicUpdates: "Dinamik güncellemeler var",
            newCommentsReplies: "Yeni yorum/cevap",
            strip: "Madde",
            newHelp: "Yardım eklendi",
            goAndWatch: "Git izle",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Yorumuma cevap verdi",
            replySucceeded: "Cevap başarılı!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Yorumlarda beni",
            replySucceeded: "Cevap başarılı!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Sizin için arama paylaşımı",
            shareCasesForYou: "Davayı sizin için paylaşın",
          },
        },
        study: {
          index: {
            learned: "Öğrenildi",
            ignored: "Görmezden geldi",
            study: "Öğrenme",
            ignore: "Görmezden gelin",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Başarılı ithalat",
          account: "Hesap numarası",
          password: "Şifre",
          cover: "Kapsama",
          skip: "Atla",
          import: "İçe aktarma",
          importEmployees: "Çalışanları içe aktarma",
          importPrompt: "İçe aktarma ipuçları",
          confirm: "Emin olmak için",
          theDataContainsDuplicateAccountsOrNames:
            "Veri hesabı veya ad çoğaltılmış",
          allCoverage: "Tüm kapsama alanı",
          skipAll: "Hepsini atla",
          headPortrait: "Avatar",
          fullName: "İsim",
          nickname: "Takma ad",
          excellentStaff: "Mükemmel personel",
          yes: "Evet, evet.",
          no: "Hayır, hayır.",
          department: "Bölüm",
          accountNumberemail: "Hesap (e-posta)",
          telephone: "Telefon",
          jobNumber: "İş numarası",
          role: "Rol",
          pleaseEnterANameOrPhoneSearch:
            "Lütfen e-posta adresinizi, adınızı veya telefon numaranızı girin",
          person: "İnsanlar)",
          AddEmployee: "Çalışan ekleme",
        },
        edit: {
          jobNo: "İş numarası",
          udeskCustomerServiceSystemIdentity:
            "Udesk Müşteri Hizmetleri Sistemi Kimliği",
          enterpriseWechatIdentity: "Kurumsal WeChat Kimlik",
          inputID: "Kimlik girin",
          newIdentityBinding: "Yeni kimlik bağlama",
          identityBinding: "Kimlik bağlama",
          avatarGreaterThan500kb: "500Kb'den büyük avatar",
          uploadSuccessful: "Başarılı yükleme",
          upload: "Yükle",
          supportJPGGIFAndPNGUpTo500kb: "Destek jpg, gif, png, maksimum 500Kb",
          yes: "Evet, evet.",
          no: "Hayır, hayır.",
          accountNumber: "Hesap numarası",
          pleaseEnterTheAccountNumber: "Lütfen hesap numaranızı girin",
          initialPassword: "İlk şifre",
          pleaseInputAPassword: "Lütfen şifrenizi girin",
          confirmPassword: "Şifreyi onaylayın",
          thePasswordsEnteredTwiceAreDifferent:
            "İki kez girilen şifreler farklı!",
          headPortrait: "Avatar",
          fullName: "İsim",
          pleaseEnterYourName: "Lütfen adınızı girin",
          nickname: "Takma ad",
          department: "Bölüm",
          excellentStaff: "Mükemmel personel",
          sessionIdentity: "Oturum kimliği",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Oturum kayıtlarını çalışana göre etiketleyin",
          telephone: "Telefon",
          jobNumber: "İş numarası",
          role: "Rol",
          pleaseSelectARole: "Lütfen bir karakter seçin",
          preservation: "Kaydet",
          cancel: "İptal",
          addedSuccessfully: "Başarı ekle!",
          modificationSucceeded: "Değişiklik başarılı oldu!",
          newEmployees: "Yeni çalışanlar",
          editEmployee: "Editör çalışanları",
        },
        detail: {
          viewDetails: "Ayrıntıları görüntüle",
          accountNumber: "Hesap numarası",
          headPortrait: "Avatar",
          fullName: "İsim",
          nickname: "Takma ad",
          department: "Bölüm",
          excellentStaff: "Mükemmel personel",
          yes: "Evet, evet.",
          no: "Hayır, hayır.",
          sessionIdentity: "Oturum kimliği",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Oturum kayıtlarını çalışana göre etiketleyin",
          telephone: "Telefon",
          jobNumber: "İş numarası",
          role: "Rol",
        },
      },
      roleManage: {
        list: {
          menu: "Menü",
          functionalCategory: "Fonksiyonel kategori",
          functionalSubclass: "Fonksiyonel küçük sınıf",
          deletedSuccessfully: "Silme başarısı",
          NewRole: "Yeni rol",
          role: "Rol",
          dataPermissions: "Veri izinleri:",
          edit: "Düzenleme",
          areYouSureYouWantToDeleteTheRole: "Kaldırma rolünün belirlenmesi",
          determine: "Emin olmak için",
          cancel: "İptal",
          delete: "Silme",
          applicationPermission: "Uygulama İzinleri",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Yalnızca kendi oturum verilerinizi görebilirsiniz",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Bu bölümün ve alt bölümün oturum verilerini görüntüleyebilirsiniz",
          menu: "Menü",
          functionalCategory: "Fonksiyonel kategori",
          functionalSubclass: "Fonksiyonel küçük sınıf",
          successfullyAdded: "Yeni başarı",
          modifiedSuccessfully: "Değişiklikler başarılı oldu",
          edit: "Düzenleme",
          cancel: "İptal",
          confirm: "Onaylandı.",
          roleName: "Karakter adı",
          pleaseEnterTheRoleName: "Lütfen rol adını girin",
          dataAuthority: "Veri izinleri",
          youCanViewCompanyWideSessionData:
            "Şirket genelinde oturum verilerini görüntüleyebilir",
          applicationPermission: "Uygulama İzinleri",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Oturum akışı",
          generateProcess: "Oluşturma süreci",
          addProcess: "Süreç ekleme",
          cancel: "İptal",
          complete: "Tamamla",
          edit: "Düzenleme",
          theSettingWillBeSuccessfulLater:
            "Başarıyı görmek için daha sonra ayarlayın!",
          previous: "Bir önceki",
          next: "Bir sonraki",
          debuggingMode: "Hata ayıklama modu",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Mesaj, kilit olaylarla, denetim öğeleriyle, müşteri etiketinin kurallarıyla veya bilgi noktası kurallarıyla eşleşirse, mesajın eşleştiği kural adı ve bilgi tabanının bilgi noktası adı görüntülenir.",
          seeLater: "Daha sonra görün",
          determine: "Emin olmak için",
          conversationalInsights: "Konuşma içgörüleri",
          customerInsight: "Müşteri içgörüleri",
          knowledgePointRecommendation: "Bilgi noktası önerisi",
          comment: "Yorumlar",
          sessionQuality: "Oturum Kalitesi",
          setToListenLaterSuccessfully:
            "Daha sonra başarıyla dinlemek için ayarlayın!",
          listenLater: "Daha sonra dinle",
          seekCounselingSuccess: "Başarılı danışmanlık isteyin!",
          altogether: "Toplam",
          appearsAt: "Görünen",
          commentsPublishedSuccessfully: "Yorum başarı",
          callRecording: "Çağrı kaydı",
          addedSuccessfully: "Başarı ekle",
          shareWithColleagues: "İş arkadaşlarına paylaş",
          shareWithCustomers: "Müşterilere paylaş",
          resumptionDetails: "Yeniden inceleme detayları",
          addToCaseBase: "Vaka kütüphanesine ekle",
          shareCases: "Davayı paylaş",
          seekCounseling: "Danışmanlık Aramak",
          sessionRecord: "Oturum kaydı",
          businessRecord: "İş kayıtları",
          keywordSearch: "Anahtar Kelime Arama",
          whole: "Hepsi...",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Lütfen yorum yazın, başkalarını bilgilendirmek için @ yazın, konu eklemek için # girin",
          subscriptionSession: "Abonelik oturumları",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Abone olduktan sonra, yeni bir yardım, yorum ve yanıt olduğunda bir uyarı alırsınız",
        },
      },
      coachTask: {
        index: {
          employeeName: "Çalışan adı",
          customerName: "Müşteri adı",
          source: "Kaynak",
          viewSessionRecords: "Oturum kayıtlarını görüntüleme",
          coachingStatus: "Danışmanlık Durumu",
          helpTime: "Yardım zamanı",
          counselingTime: "Özel ders zamanı",
          helpContent: "Yardım içeriği",
          coachingOperation: "Koçluk operasyonu",
          areYouSureYouWantToIgnoreIt: "İhmal etmek emin misiniz?",
          determine: "Emin olmak için",
          cancel: "İptal",
          ignoreHelp: "Yardım için ihmal",
          coaching: "Danışmanlık",
          viewCoaching: "Danışmanlığa göz atın",
          areYouSureToReturnToCounseling:
            "Danışmanlığa geri dönmeye emin misiniz?",
          returnToPendingCoaching: "Danışmanlığa devam et",
          tutor: "Danışmanlık",
          helpSeekingStatus: "Yardım durumu",
          recentCoachingTime: "Son koçluk süresi",
          operation: "Operasyon",
          ignoreSuccess: "Başarıyı görmezden gelin!",
          recoverySucceeded: "Kurtarma başarılı oldu!",
          successfulCoaching: "Koçluk başarılı!",
          myMission: "Benim görevim",
          selectCoachingStatus: "Danışmanlık durumunu seçin",
          whole: "Hepsi...",
          iAskedForHelp: "Yardım istedim",
          signOut: "Çıkın",
          submit: "Gönderme",
          helpPerson: "Yardım",
          customer: "Müşteri",
          toBeCoached: "Danışmanlık bekliyor",
          counselingSuggestions: "Danışmanlık Önerileri",
          pleaseEnterCoachingSuggestions:
            "Lütfen danışmanlık tavsiyelerini girin",
        },
      },
      coachTaskCenter: {
        title: "Görev Merkezi",
        list: {
          taskName: "Görev adı",
          lessonGroup: "Ders Sınıflandırması",
          lessonName: "Kursu",
          taskType: "Görev türleri",
          taskStatus: "Görev durumu",
          taskExecutionInfo: "Uygulama",
          totalCount: "Uygulanacak kişi sayısı",
          executeCount: "Uygulananların sayısı",
          executeRate: "Yürütme oranı",
          completeCount: "Tamamlama sayısı",
          completeRate: "Tamamlama oranı",
          commitCount: "Standart insan sayısı",
          commitRate: "Uyum oranı",
          excellentRate: "Mükemmel oran",
          taskTime: "Görev süresi",
          option: "Operasyon",
          edit: "Düzenleme",
          taskDetail: "Görev Verileri",
          delete: "Silme",
          new: "Yeni sürüm",
          taskTypeAll: "Hepsi...",
          taskTypePractice: "Egzersiz",
          taskTypeTest: "Sınav",
          taskStatusNotStarted: "Başlamamış.",
          taskStatusRunning: "Devam ediyor",
          taskStatusEnd: "Bitmiş.",
          taskStatusInvalid: "Etkili değil",
          unlimitedTime: "Sınırsız süre",
          to: "Için",
          deleteConfirm:
            "Silme işleminden sonra, tüm çalışma kayıtları bu görev altında boşaltılır. Silme onaylandı mı?",
          deleteSuccess: "Görev silme başarısı",
        },
        edit: {
          addTask: "Yeni sürüm görevleri",
          editTask: "Yayın Görevlerini Düzenle",
          publishSuccess: "Başarılı bir şekilde yayınlandı",
          publishFailed: "Yayınlama başarısız oldu",
          updateSuccess: "Başarıyla güncellendi",
          updateFailed: "Güncelleme başarısız oldu",
          cannotBeNull: "Boş olamaz",
          explain: "Açıklama",
          taskDescExtra: "(Burada içerik {0} girişinde gösterilecektir)",
          taskTargetObj: "Yayınlama nesnesi",
          isEnable: "Yürürlük durumu",
          dialogueMode: "Konuşma modu",
          voice: "Ses",
          text: "Metin",
          userPortrait: "Kullanıcı portreleri",
          difficultyLevel: "Zorluk",
          limitDuration: "Süre sınırı",
          tipLabel: "Konuşma ipuçları",
          tipTooltip:
            "Öğrencilerin referans sözlerini görüntülemesine izin verilip verilmeyeceği",
          limitTimes: "Sınav sayısı",
          time: "Zaman",
          gradingStandart: "Puanlama kriterleri",
          gradingStandartDescAffix: "(Mevcut diyalog sürecinin toplam puanı",
          gradingStandartDescSuffix:
            "Skorlar, kelimeler {0} puan, duygular {1} puan, konuşma hızı {2} puan)",
          completeStandart: "Görev Tamamlama Gereksinimi",
          byTimes: "Sayma sayısı",
          byScore: "Puana göre",
          completeByTimes: "Egzersiz sayısı",
          times: "İkinci kez",
          score: "Puan",
          anyTimes: "Herhangi bir kez",
          getScore: "Skor",
          offLimitDuration: "Sınırsız uzunluk",
          onLimitDuration: "Sınırlı zamanlama uzunluğu",
          commitScore: "Standart, puan",
          excellentScore: "Mükemmel standart, puan",
          offLimitTime: "Sınırsız süre",
          onLimitTime: "Sınırlı süre",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Hey, hey, hey." },
          callDynamic: {
            today: "Bugün",
            yesterday: "Dün.",
            sessions: "Bir oturum",
          },
          todoList: {
            strip: "Madde",
            theSessionIsAddedTolistenLater: 'Oturum "daha sonra dinle" eklendi',
            askYouForHelp: "Yardım için",
            iHopeToContactYouLater: "Daha sonra iletişim kurmayı bekleyin",
            lookingForwardToSendingTheInformationLater:
              "Daha sonra veri göndermeyi bekliyoruz",
            dialogueRisk: "Oturum için risk noktaları",
            tutored: "Danışmanlık",
            goToCounseling: "Danışmanlığa git",
            contacted: "İletişim",
            hasBeenSent: "Gönderildi",
            areYouSureYouWantToIgnoreIt: "İhmal etmek emin misiniz?",
            determine: "Emin olmak için",
            cancel: "İptal",
            ignore: "Görmezden gelin",
            checked: "Görüntülendi",
          },
          callListItem: {
            strip: "Madde",
            learned: "Öğrenildi",
            keyEvents: "Kritik olaylar",
            totalImplementationRateOfSupervisionItems:
              "Toplam uygulama oranını denetleyin",
          },
        },
        index: {
          operationSucceeded: "Operasyon başarılı oldu!",
          sessionDynamics: "Oturum dinamikleri",
          my: "Benim...",
          department: "Bölüm",
          company: "Şirket",
          toDoList: "Yapılacaklar",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Etkili çağrı miktarı (adet)",
          bestPractices: "En İyi Uygulamalar",
          averageDurationOfASingleCallminutes:
            "Tek ortalama görüşme süresi (dakika)",
          totalCallVolumePCs: "Toplam çağrı hacmi (adet)",
          totalTalkTimehours: "Toplam arama süresi (saat)",
          speakingPercentagepercentage: "Konuşma oranı (yüzde)",
          maximumContinuousSpeakingTimeminutes:
            "Maksimum sürekli konuşma süresi (dakika)",
          numberOfIndepthDiscussionsPCs: "Derinlemesine tartışma sayısı (a)",
          maximumCustomerTalkminutes: "En uzun müşteri konuşması (dakika)",
          questionFrequencyPCs: "Soru sıklığı (a)",
          averageValue: "Ortalama değer",
          selectDate: "Tarih seçme",
          personnel: "Personel",
          pleaseSelect: "Lütfen seçin",
          callRange: "Arama aralığı",
          conversationActivity: "Oturum etkinliği",
          conversationAction: "Oturum eylemi",
          keyEvents: "Kritik olaylar",
          callRecord: "Arama kaydı",
          filter: "Filtre:",
          conversation: "Çağrı",
          minute: "Dakika",
          sale: "Çalışanlar",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Ocak ayında",
            numberOfCalls: "Çağrı sayısı",
            accountInformation: "Hesap bilgileri",
            month: "Ay.",
            today: "Bugün...",
            customerDetails: "Müşteri detayları",
            searchKeywordsInCommunicationContent:
              "İletişim içeriğinde arama anahtar kelimeler",
            allMembers: "Tüm üyeler",
            reset: "Sıfırla",
            query: "Sorgulama",
            allTrip: "Tam Yolculuk Gösterisi",
            contactOverview: "İletişim genel bakış",
            lastContact: "Son temas",
            nextContact: "Bir dahaki sefere",
            previous: "Bir önceki",
            next: "Bir sonraki",
            viewDetails: "Ayrıntıları görüntüle",
            contactDynamics: "Dinamik iletişim",
            lastYear: "Son bir yıl",
            lastCall: "Son çağrı",
            hotTopicsForDiscussion: "Tartışma konuları sıcak",
            totalInTheLastYear: "Geçen yıl toplam",
            callTimes: "İkinci çağrı",
          },
        },
        list: {
          index: {
            risk: "Risk",
            have: "Var.",
            nothing: "Hayır, hayır.",
            channel: "Kanallar",
            callRecording: "Çağrı kaydı",
            enterpriseWeChatConversation: "Kurumsal mikro oturum",
            enterpriseWeChatCall: "Kurumsal mikro konuşma",
            followUpSuggestions: "Takip önerileri",
            customer: "Müşteri Merkezi",
            followUp: "Durumun takibi",
            personInCharge: "Sorumlu kişi",
            recentFollowup: "Son takipçisi",
            lastContactTime: "En son temas süresi",
            operation: "Operasyon",
            see: "Görüntüle",
            exceed: "Üzerinde",
            noActivityForMoreThanDays: "Gün boyunca etkinlik yok",
            lately: "Son zamanlarda",
            risksOccurredInTheLastDays: "Günler içinde risk semantiği",
            savedSuccessfully: "Başarıyı kaydet",
            today: "Bugün...",
            allDepartments: "Tüm bölümler",
            allMembers: "Tüm üyeler",
            customerName: "Müşteri adı",
            reset: "Sıfırla",
            query: "Sorgulama",
            customers: "Bir müşteri",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Pembe balonun müşteri mesajı olduğu ve mavi çalışan mesajının olduğu çalışanlar ile müşteriler arasındaki iletişimi gösterin.",
            riskAllocation: "Risk yapılandırması",
            preservation: "Kaydet",
            pleaseEnter: "Lütfen girin",
            noActivityForDays: "Gün boyunca etkinlik yok",
            riskSemanticsWithinDays: "Günler içinde risk semantiği",
            addRiskSemantics: "Risk semantiği ekleme",
            semanticTags: "Anlamsal Etiketler:",
            standardSentence: "Standart cümle:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "Yeni Koşullar Ekleme" } },
              },
              index: {
                meetAllOfTheFollowingConditions: "Aşağıdaki koşulların tümü",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Tarama",
                pleaseSelectFilteringCriteria: "Lütfen filtrelemeyi seçin",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "İş fırsatını değiştirme aşaması",
              theCurrentStageIs: "Geçerli aşama [",
              ConfirmToChangeItTo: "], Değiştirmek için emin [",
              IsIt: "] Evet?",
              StageCoStay: "] Aşama toplam kalış",
              day: "-Tanrım.-Tanrım.",
              start: "Başla",
              end: "Bitiyor",
              orderInput: "Tek kaybetmek",
              restart: "Yeniden başlat",
              pleaseConfigureTheSalesStageInformation:
                "Lütfen satış aşaması bilgilerini yapılandırın",
              reasonForDocumentInput: "Kaybetmenin nedenleri:",
              completeOrderInput: "Tamamlama listesi",
              pleaseSelectTheReason: "Lütfen nedenini seçin",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "Yöneticiler şunları önerir:",
              },
              index: {
                sessionDetails: "Oturum Detayları",
                customerDynamics: "Müşteri dinamikleri",
                riskPoint: "Risk noktası",
                followUpSuggestions: "Takip önerileri",
              },
              sessionDetails: {
                common: "Toplam",
                currentlyViewingThe: "Mevcut Görünüm No.",
                sessions: "Bir oturum",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Mevcut aşama kaldı",
              day: "-Tanrım.-Tanrım.",
            },
          },
          business: {
            index: {
              reEnableOpportunity: "İş fırsatlarını yeniden etkinleştirin",
            },
          },
          customerTag: { index: { label: "Etiketler" } },
          moreCustomer: {
            index: {
              essentialInformation: "Temel Bilgiler",
              cancel: "İptal",
              confirm: "Onaylandı.",
              customer: "Müşteri",
              currentFollowupStage: "Mevcut takip aşaması",
              telephone: "Telefon",
              followUpDays: "Takip günleri",
              day: "-Tanrım.-Tanrım.",
              remarks: "Açıklamalar",
              channelPreference: "Kanal tercihleri",
              hello: "Merhaba. Merhaba.",
              customFields: "Özel alanlar",
              opportunityAmount: "İş fırsatı miktarı",
              focus: "Endişeler",
              customerInformation: "Müşteri Bilgileri",
              viewMoreCustomerInformation: "Daha fazla müşteri bilgisi görün",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Anahtar olaylar:" },
                row1: {
                  customerDynamics: "Müşteri dinamikleri",
                  common: "Toplam",
                  barDynamic: "Dinamik çubuk",
                },
                row2: {
                  riskPoint: "Risk noktası",
                  common: "Toplam",
                  risks: "Madde riski",
                },
                row3: {
                  followUpSuggestions: "Takip önerileri",
                  intelligentRecommendation: "Akıllı Tavsiye",
                  strip: "Madde",
                  managersSuggestATotalOf: "Yönetici önerisi",
                  recommendedByManagers:
                    "Yöneticiler tarafından tavsiye edilir",
                  creationTime: "Zaman oluşturma",
                  administratorSuggestions: "Yönetici tavsiyesi",
                  suggestionsForNewManagers: "Yeni yönetici önerileri",
                },
              },
            },
            index: { typeTime: "Tip/Zaman" },
          },
          userInfo: {
            index: {
              personInCharge: "Sorumlu kişi",
              recentFollower: "Son takipçisi",
              startTime: "İlk temas zamanı",
              followUpDays: "Takip günleri",
              day: "-Tanrım.-Tanrım.",
              remarks: "Açıklamalar",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Takım üyesi",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Üyeler, diğer kişilerin toplam oturum sayısını öğrenir (yeniden), oturum ayrıntılarını kontrol eder ve kayıt üzerine tıklar.",
          totalNumberOfStudies: "Toplam çalışma sayısı",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Üyelerin başkalarının oturumlarına yaptığı yorum ve yanıtların toplam sayısı (ağır)",
          totalNumberOfInteractions: "Toplam etkileşim sayısı",
          learnTheSumOfConversationCallDuration:
            "Öğrenme konuşma süresi uzaması",
          lengthOfStudy: "Çalışma süresi",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Başkalarının oturumlarına karşı yardım isteyen toplam üye sayısı (ağır)",
          totalNumberOfRequests: "Toplam yardım",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Başkalarının yardımına danışmanlık yapan toplam üye sayısı (ağır)",
          totalCounseling: "Toplam ders sayısı",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Vaka kütüphanesine eklenen toplam üye sayısı (ağır)",
          addCase: "Vaka ekleme",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Üyelerin başkalarının oturumlarına yaptığı toplam abonelik sayısı (ağır)",
          subscribe: "Abonelik",
          totalNumberOfSessionsCommunicatedByMembers:
            "Üyelerin iletişim kurduğu toplam oturum sayısı",
          totalSessions: "Toplam oturum sayısı",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Üyeler, kendi oturumlarının toplam sayısını (yeniden) gözden geçirir, oturum ayrıntılarını kontrol edin ve kaydın üzerine tıklayın.",
          totalNumberOfDoubleDisk: "Toplam inceleme sayısı",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Toplam inceleme sayısı/toplam oturum sayısı * % 100",
          proportionOfDoubleDisk: "Yeniden değerlendirme oranı",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Oturum arama süresi toplamı",
          resumptionDuration: "Yeniden inceleme süresi",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Kendi oturumlarında yardım için başlatılan toplam üye sayısı (ağır)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Üyelerin oturumlarının yanıtları da dahil olmak üzere başkalarından gelen toplam yorum sayısı",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Vaka kütüphanesine başkaları tarafından eklenen üye oturumlarının toplam sayısı (ağır)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Başkaları tarafından abone olunan üye oturumlarının toplam sayısı (yeniden)",
          askForHelpInTheLastMonth: "Son bir ay yardım istedi",
          lastTimeForHelp: "Son yardım zamanı",
          operation: "Operasyon",
          viewAllSessionsInTheLastMonth:
            "Son ayın tüm oturumlarını görüntüleyin",
          allDepartments: "Tüm bölümler",
          reset: "Sıfırla",
          query: "Sorgulama",
          altogether: "Toplam",
          secondSession: "Oturum",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Mevcut test projesi:",
            supervisionItem: "Denetim maddesi",
            keyEvents: "Kritik olaylar",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Test etmek istediğiniz denetleyici öğeyi veya kritik olayı işaretleyin ve simüle edilmiş bir konuşmada aradığınızdan emin olun",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Yürütme Gücü Analizi" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Süreç 1",
              technologicalProcess: "Süreç",
              newProcess: "Yeni bir süreç oluşturun",
            },
          },
          index: {
            upload: "Yükle",
            preservation: "Kaydet",
            newlyBuild: "Yeni",
            cancel: "İptal",
            name: "İsim",
            describe: "Açıklama",
            type: "Türü",
            classification: "Sınıflandırma",
            content: "İçerik",
            enclosure: "Aksesuar",
            knowledgePointAssociation: "Bilgi noktası ilişkilendirme",
            open: "Açık",
            close: "Kapat",
            operator: "Operatör",
            logic: "Mantık",
            see: "Görüntüle",
            edit: "Düzenleme",
          },
        },
        index: {
          name: "İsim",
          describe: "Açıklama",
          createdBy: "Yarat",
          type: "Türü",
          lastUpdated: "Son güncelleme",
          numberOfReferences: "Referans sayısı",
          operation: "Operasyon",
          see: "Görüntüle",
          edit: "Düzenleme",
          delete: "Silme",
          categoryDeletedSuccessfully: "Kategori silme başarısı",
          classificationOfKnowledgePoints: "Bilgi Noktası Sınıflandırması",
          newlyBuild: "Yeni",
          knowledgeBase: "Bilgi Bankası",
          knowledgePoints: "Bilgi noktaları",
          technologicalProcess: "Süreç",
          keyword: "Anahtar kelimeler",
          batchExport: "Toplu ihracat",
          batchDelete: "Toplu silme",
          importProcess: "İçe aktarma süreci",
          importKnowledgePoints: "Bilgi noktalarını içe aktarma",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Bilgi noktaları bilgi tabanında yoksa, doğrudan bilgi noktaları eklenir, varsa atlanır ve güncellenmez",
          editKnowledgePointClassification:
            "Bilgi Noktası Sınıflandırmasını Düzenleme",
          newKnowledgePointClassification: "Yeni bilgi noktası sınıflandırması",
          categoryEditingSucceeded: "Kategori düzenleme başarısı",
          newClassificationSucceeded: "Yeni sınıflandırma başarılı",
          classificationName: "Sınıflandırma adı",
        },
      },
      monitoringReport: { index: { executiveSupervision: "İcra denetimi" } },
      topicCallout: {
        index: {
          whole: "Hepsi...",
          date: "Tarih",
          dimensionStatus: "Etiketleme durumu",
          callRecordID: "Arama kaydı kimliği",
          callRecordIDMustBeNumeric: "Arama kaydı kimliği dijital olmalıdır",
          strip: "Madde",
          topicAnnotation: "Konu etiketleme",
          annotated: "İşaretli",
          stripNotMarked: "Makale, işaretlenmemiş",
          barInDimension: "Madde, etiketli",
          customer: "Müşteri",
          sale: "Satış",
          common: "Toplam",
          sessions: "Oturum",
          currentTopic: "Güncel konu:",
          staff: "Çalışanlar",
          topicOfConversation: "Konu",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "İşlem başarılı, etiketleme sıfırlandı",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Sıfırlamadan sonra, bu geçiş oturumunun tüm etiketli kayıtları silinecektir, lütfen onaylayın",
          resetAll: "Hepsini sıfırla",
          pleaseSelectTheTopicToBeMarked:
            "Lütfen işaretlemek istediğiniz konuyu seçin",
          pleaseTickTheSentenceToBeMarked:
            "Lütfen işaretlemek istediğiniz cümleyi işaretleyin",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "İşlem başarılı, etiketleme etkili oldu",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Bu etiketleme aralığı diğer konular için mesajlar içerir, üzerine tıklamak için Tamam'ı tıklayın",
          confirmDimensions: "İşaretlemeyi onaylayın",
          talkTime: "Konuşma süresi",
          callID: "Çağrı kimliği",
        },
      },
      topicList: {
        index: {
          callID: "Çağrı Kimliği",
          latestMarkedTime: "En son etiketleme zamanı",
          topicOfConversation: "Konu",
          numberOfSentences: "Cümle sayısı",
          sentence: "Cümle",
          operation: "Operasyon",
          see: "Görüntüle",
          delete: "Silme",
          edit: "Düzenleme",
          newSubTopic: "Yeni alt konular",
          move: "Hareket et",
          whole: "Hepsi...",
          topicDeletedSuccessfully: "Başarılı bir konu silme",
          newlyBuild: "Yeni",
          topicList: "Konu listesi",
          callIDMustBeNumeric: "Çağrı kimliği dijital olmalıdır",
          leadInTopic: "Konuları içe aktarma",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Sınıflandırma adı tekrarlandığında, orijinal konu sınıflandırmasını kapsamaz. Bu madde doğrudan atlanır ve içe aktarılmayacaktır.",
          mobileClassification: "Mobil sınıflandırma",
          editTopic: "Konuyu düzenleme",
          newTopic: "Yeni bir konu",
          topicName: "Konu adı",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Değişiklikler başarılı oldu",
            createdSuccessfully: "Başarılı Oluşturma",
            modificationFailed: "Değişiklikler başarısız oldu",
            creationFailed: "Oluşturma başarısız oldu",
            monitoringName: "İzleme adı",
            upToCharacters: "En fazla 40 karakter",
            pleaseEnterAName: "Lütfen bir ad girin",
            monitoringIndicators: "İzleme göstergeleri",
            sessionActivity: "Oturum etkinliği",
            conversationAction: "Oturum eylemi",
            keyEvents: "Kritik olaylar",
            supervisionItems: "Denetim maddesi",
            monitoringMembers: "Üyeleri izleme",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Rehberlik, eğitim veya müdahale yönetimi alan gruplar",
            groupA: "A Grubu",
            pleaseSelectGroupAData: "Lütfen A grubu verilerini seçin",
            thereAreDuplicateUsersInGroupB: "B grubu ile yinelenen kullanıcı",
            groupARemarks: "A Grubu Notları",
            controlGroupGroupComparedWithGroupA:
              "Kontrol grubu, A grubu ile zıt grup",
            groupB: "B Grubu",
            pleaseSelectGroupBData: "Lütfen B Grubu verilerini seçin",
            thereAreDuplicateUsersInGroupA: "A grubu ile yinelenen kullanıcı",
            groupBRemarks: "B Grubu Notları",
            monitoringPeriod: "İzleme döngüsü",
            startNode: "Başlangıç Düğümü",
            monitoringScope: "İzleme aralığı",
            pleaseSelectMonitoringConditions: "Lütfen izleme koşulunu seçin",
            pleaseEnterANumber: "Lütfen sayıları girin",
            pleaseSelectATimePeriod: "Lütfen bir zaman döngüsü seçin",
            preservation: "Kaydet",
            cancel: "İptal",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Silme başarısı",
            notStarted: "Başlamamış.",
            haveInHand: "Devam ediyor",
            closed: "Bitmiş.",
            newGroupTest: "Yeni Grup Testi",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Satış Aşaması",
        communicationStrategy1: "İletişim stratejileri",
        riskAllocation: "Risk yapılandırması",
        businessConfiguration: "İş Yapılandırması",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "İtiraz işleme",
                  productSellingPointScript: "Ürün satış noktası",
                  caseGrouping: "Vaka gruplaması",
                  pleaseSelectExcellentCases: "Lütfen mükemmel bir dava seçin",
                  pleaseSelectKnowledgePoints: "Lütfen bir bilgi noktası seçin",
                  associativeLinks: "İlişkili bağlantılar",
                  jumpTo: "Zıpla",
                  jumpType: "Atlama Türleri",
                  knowledgePoints: "Bilgi noktaları",
                  excellentCases: "Mükemmel vaka",
                  excellentScript: "Mükemmel konuşma",
                  pleaseSelectASingleKnowledgePoint:
                    "Lütfen tek bir bilgi noktası seçin",
                },
              },
            },
            index: {
              name: "İsim",
              pushText: "İtme metni",
              enableStatus: "Durumu etkinleştir",
              triggerRules: "Tetik kuralları",
              communicationStrategy: "İletişim stratejileri",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "İletişim stratejisi gruplama",
              name: "İsim",
              pushText: "İtme metni",
              effectiveness: "Geçerlilik",
              openSuccessfully: "Başarıyı açma",
              closeSuccessfully: "Başarıyla kapatma",
              operationFailed: "Operasyon başarısız oldu",
              operation: "Operasyon",
              edit: "Düzenleme",
              delete: "Silme",
              newlyBuild: "Yeni",
              communicationStrategy: "İletişim stratejileri",
              editGroup: "Gruplamayı Düzenle",
              groupName: "Grup adı",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Tüm oturumlar",
                  recentDays: "Son Günler",
                  recentSessions: "Son Oturum",
                  scopeOfApplication: "Uygulama kapsamı:",
                  day: "-Tanrım.-Tanrım.",
                  through: "Geçin",
                },
              },
            },
            index: {
              describe: "Açıklama",
              applicableCustomers: "Uygulanabilir müşteriler",
              enableStatus: "Durumu etkinleştir",
              triggerRules: "Tetik kuralları",
              riskRules: "Risk kuralları",
            },
          },
          list: {
            index: {
              riskAllocation: "Risk yapılandırması",
              keyWord: "Anahtar kelimeler",
              newRiskRule: "Yeni risk kuralları",
              riskRuleName: "Risk kuralı adı",
              describe: "Açıklama",
              state: "Durum",
              openSuccessfully: "Başarıyı açma",
              closeSuccessfully: "Başarıyla kapatma",
              operationFailed: "Operasyon başarısız oldu",
              operation: "Operasyon",
              edit: "Düzenleme",
              delete: "Silme",
              deletionSucceeded: "Silme başarısı",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Tek kaybetmek" },
              endReasonList: {
                addEndReason: "Bitiş nedenini ekleme",
                enterEndReason: "Bitiş nedenini girin",
              },
              stageList: {
                keyEvents: "Kritik olaylar",
                waysideData: "Yol verileri",
                includeAny: "Keyfi içerir",
                includeAll: "Tüm içerir",
                pleaseEnterTheStageName: "Lütfen sahne adını girin",
                setStageRules: "Aşama Kurallarını Ayarla",
                stageName: "Sahne adı",
                operator: "Operatör",
                logic: "Mantık",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Zaman tipi alan giriş formatı: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "İstek başarısız oldu, lütfen daha sonra tekrar deneyin",
              salesStage: "Satış Aşaması",
              essentialInformation: "Temel Bilgiler",
              name: "İsim",
              applicableDepartments: "Uygulanabilir bölümler",
              applicableToOthers: "Diğer için geçerli",
              manualChangePhase: "Manuel değişim aşaması",
              enableStatus: "Durumu etkinleştir",
              describe: "Açıklama",
              opportunityStageSettings: "İş fırsatı aşaması ayarı",
              stageSettings: "Aşama ayarları",
              aliasSettings: "Takma ad ayarları",
              closeOpportunity: "İş fırsatlarını sona erdirmek",
              newSuccessfully: "Yeni başarı",
              newFailed: "Yeni başarısız",
              savingSucceeded: "Başarıyı kaydet",
              saveFailed: "Başarısız Kaydet",
            },
          },
          list: {
            index: {
              salesStage: "Satış Aşaması",
              keyWord: "Anahtar kelimeler",
              newSalesStage: "Yeni satış aşaması",
              salesStageName: "Satış aşaması adı",
              describe: "Açıklama",
              state: "Durum",
              openSuccessfully: "Başarıyı açma",
              closeSuccessfully: "Başarıyla kapatma",
              operationFailed: "Operasyon başarısız oldu",
              operation: "Operasyon",
              edit: "Düzenleme",
              delete: "Silme",
              deletionSucceeded: "Silme başarısı",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Büyük model oluşturma",
            conversationInsights: "Konuşma içgörüleri",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Otomatik Oluşturma" },
              },
              hooks: {
                conversationSummary: "Konuşma özeti",
                conversationProcess: "Oturum akışı",
                opportunityPoints: "Fırsat noktası",
              },
              prompt: {
                index: {
                  edit: "Düzenleme",
                  restoreDefault: "Varsayılan kurtarma",
                  cancel: "İptal",
                  confirm: "Onaylandı.",
                },
              },
            },
            index: {
              saveSuccessful: "Başarıyı kaydet",
              hintLanguage: "İpuçları",
              preserve: "Kaydet",
              cancel: "İptal",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Büyük model oluşturma",
        salesStage: "Satış Aşaması",
        communicationStrategy: "İletişim stratejileri",
        riskAllocation: "Risk yapılandırması",
        businessConfiguration: "İş Yapılandırması",
      },
      salesLearningCenter: {
        knowledgeBase: "Bilgi Bankası",
        speechCorpus: "Konuşma kütüphanesi",
        learningCenter: "Öğrenme Merkezi",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "Müşteri Bilgileri" } },
        },
        index: { otherInformation: "Diğer bilgiler" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "İçe aktarma" } } },
        hooks: {
          index: {
            all: "Hepsi...",
            intelligentModel: "Akıllı model",
            smartRules: "Akıllı kurallar",
            manualScoring: "Manuel puanlama",
            enable: "Etkinleştir",
            notEnabled: "Etkin değil",
            voiceCall: "Sesli arama",
            textDialogue: "Metin konuşmaları",
            ecommerceTextSession: "E-ticaret metin oturumu",
            workOrder: "İş emri",
          },
        },
        index: {
          ruleName: "Kural adı",
          ruleType: "Kural türleri",
          pleaseSelect: "Lütfen seçin",
          ruleStatus: "Kural durumu",
          dataSourceType: "Veri kaynağı türleri",
          voiceCall: "Sesli arama",
          textDialogue: "Metin konuşmaları",
          ecommerceTextSession: "E-ticaret metin oturumu",
          workOrder: "İş emri",
          status: "Durum",
          operation: "Operasyon",
          edit: "Düzenleme",
          ruleTemplate: "Kural şablonu",
        },
      },
      analysisReportTitle: "Raporları analiz etme",
      ruleTest: "Kural testi",
      intelligentTags: {
        labelDisplayQuantity: "Etiket ekran sayısı",
        unlimited: "Sınırsız",
        ignoringTagLibraries: "Etiket kütüphanesini yoksay",
        automaticallyGenerateLabels: "Otomatik olarak etiket oluşturma",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'Açtıktan sonra, büyük modele dayalı oturumlar için otomatik etiketler oluşturulur ve oluşturulan etiketler varsayılan olarak "Sınıflandırılmamış" olarak sınıflandırılır.',
        defaultValidity: "Varsayılan geçerlilik",
        supervisionItem: "Denetim maddesi",
        customerLabel: "Müşteri Etiketleri",
        newSupervisionItemRule: "Yeni denetim kuralları",
        editSupervisionItemRule: "Düzenleme denetim maddesi kuralları",
        newCustomerLabel: "Yeni müşteri etiketi oluşturma",
        editCustomerLabel: "Müşteri etiketlerini düzenleme",
        newSessionLabel: "Yeni Oturum Etiketi",
        editSessionLabel: "Oturum etiketlerini düzenleme",
        newKeyEvent: "Yeni kritik olaylar",
        editKeyEvents: "Anahtar olayları düzenleme",
        detail: {
          template: { nameIsARequiredField: "Adı gerekli bir alandır" },
          formItemComponent: {
            largeModelExtensionLabel: "Büyük model genişletme etiketi",
            sessionRecordID: "Oturum kaydı kimliği",
            sessionTime: "Oturum süresi",
            smartTags: "Akıllı etiketler",
            viewDetails: "Ayrıntıları görüntüle",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Silme işleminden sonra, bu oturum kaydındaki veriler boşaltılır. Silme onaylanır mı?",
            tagData: "Verileri etiketleme",
            batchDeletion: "Toplu silme",
          },
        },
        template: {
          advancedFiltering: "Gelişmiş Tarama",
          dataType: "Veri Türleri",
          filterCriteria: "Tarama koşulları",
          edit: "Düzenleme",
          createANewSubcategory: "Yeni alt sınıflandırma",
          delete: "Silme",
          nameLengthCannotExceed: "İsim uzunluğu 40'ı geçemez",
        },
        component: {
          modelExpansion: "Model genişletme",
          enable: "Etkinleştir",
          notEnabled: "Etkin değil",
          standardScreening: "Standart tarama",
          advancedFiltering: "Gelişmiş Tarama",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Anahtar kelimeler",
              sessionLabel: "Oturum Etiketleri",
              keyEvents: "Kritik olaylar",
              matchAny: "Herhangi bir maç",
              matchAll: "Hepsini eşleştirin",
              beEqualTo: "Eşittir",
              includeAll: "Tüm içerir",
              includeAny: "Keyfi içerir",
              advancedConditions: "Gelişmiş koşullar",
              smartTag: "Akıllı etiketler",
              smartTagI: "Akıllı Etiket Bir",
              smartTagII: "Akıllı Etiket II",
            },
            template: {
              pleaseSelectSmartTag: "Lütfen akıllı etiketi seçin",
              pleaseSelectASessionLabel: "Lütfen oturum sekmesini seçin",
              pleaseSelectAKeyEvent: "Lütfen Anahtar Olayları Seçin",
              pleaseSelectAdvancedCondition: "Lütfen gelişmiş koşulları seçin",
              newAdvancedFilter: "Yeni gelişmiş filtreleme",
              moveUp: "Yukarı hareket",
              moveDown: "Aşağı in",
              moveLeft: "Sola kaymak",
              shiftRight: "Sağa doğru hareket et",
            },
          },
          component: {
            businessAnalysispng: "İş analizi. png",
            savedSuccessfully: "Başarıyı kaydet",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Kalıcı ve etkili",
            permanentlyInvalid: "Kalıcı olarak geçersiz",
            customValidity: "Özel geçerlilik süresi",
            termOfValidity: "Geçerlilik süresi",
            name: "İsim",
            explain: "Açıklama",
            state: "Durum",
            classification: "Sınıflandırma",
            smartRecommendation: "Akıllı Tavsiye",
            preconditions: "Ön koşul",
            SetPreconditionsForThisSupervisionItem:
              "1. Bu denetim öğesi için ön koşulları ayarlayın:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Ön koşul yerine getirildiğinde, "uygulanacak denetim maddesi" dir ve denetim maddesi test edilir;',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Ön koşulları yerine getirmediğinde, denetim maddesi test edilmez;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Ön koşul ayarlamamak, yani tüm oturumları varsayılan olarak algılamak",
            have: "Var.",
            nothing: "Hayır, hayır.",
            preconditionRule: "Koşullara hazırlık kuralları",
            preconditionLogic: "Koşullara hazırlık mantığı",
          },
          recommendation: {
            caseGrouping: "Vaka gruplaması",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Çalışanlar yerinde olmadığında, mükemmel kelimeler ve mükemmel durumlar akıllıca önerilebilir",
            smartRecommendation: "Akıllı Tavsiye",
            open: "Açık",
            close: "Kapat",
            whenThisSupervisionItem: "Ne zaman bu denetim öğesi",
            implement: "İcra",
            unexecuted: "Uygulanmadı",
            when: "Ne zaman:",
            recommendedKnowledgePoints: "Önerilen bilgi noktaları",
            caseRecommendation: "Vaka önerisi",
          },
        },
        index: {
          determine: "Emin olmak için",
          sort: "Sıralama",
          sortSucceeded: "Sıralama başarısı",
          supervisionItemClassification: "Denetim öğesi sınıflandırması",
          pressEnterToCreateANew: "Yeni enter tuşuna basın",
          nameCannotBeEmpty: "İsimler boş olamaz",
          newlyBuild: "Yeni",
          name: "İsim",
          explain: "Açıklama",
          validStatus: "Etkili durum",
          permanentlyValid: "Kalıcı ve etkili",
          permanentlyInvalid: "Kalıcı olarak geçersiz",
          operation: "Operasyon",
          edit: "Düzenleme",
          batchDelete: "Toplu silme",
          newSupervisionItem: "Yeni denetim öğeleri",
          keyWord: "Anahtar kelimeler",
          delete: "Silme",
          deleteSucceeded: "Silme başarısı",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Veriler silindikten sonra kurtarılamaz. Silme onaylandı mı?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Akıllı etiketler",
              beEqualTo: "Eşittir",
              includeAll: "Tüm içerir",
              includeAny: "Keyfi içerir",
              matchAny: "Herhangi bir maç",
              matchAll: "Hepsini eşleştirin",
              smartTagI: "Akıllı Etiket Bir",
              smartTagII: "Akıllı Etiket II",
            },
            template: {
              pleaseSelectASmartTag: "Lütfen akıllı etiketi seçin",
              pleaseSelectAdvancedConditions: "Lütfen gelişmiş koşulları seçin",
              newAdvancedFilter: "Yeni gelişmiş filtreleme",
              rename: "Yeniden adlandır",
              moveUp: "Yukarı hareket",
              moveDown: "Aşağı in",
              shiftLeft: "Sola kaymak",
              shiftRight: "Sağa doğru hareket et",
            },
          },
        },
        template: {
          delete: "Silme",
          labelFiltering: "Etiket Filtreleme:",
          satisfyAny: "Keyfi ile tanışın",
          meetAll: "Hepsini tatmin etmek",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "İkinci seviye sınıflandırma etiketi diyalog sayısı ≥ birinci seviye sınıflandırma, bir oturum birden fazla etiket içerebilir",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Zincir değişim hesaplama mantığı: (seçilen zaman dilimindeki konuşma miktarı-önceki zaman diliminin yanındaki konuşma miktarı)/önceki zaman dilimindeki konuşma miktarı",
          proportionOfPrimaryClassification:
            "Birinci seviye sınıflandırma oranı",
          saveAs: "Farklı olarak kaydet",
          cover: "Kapsama",
          preservation: "Kaydet",
          qualityInspectionTask: "Analiz görevleri:",
          query: "Sorgulama",
          changeFromMonthToMonth: "Zincir değişiklikleri",
          tagRanking: "Etiket Sıralaması",
          proportion: "Durum",
        },
        component: {
          numberOfConversations: "Konuşma sayısı",
          operation: "Operasyon",
          businessAnalysispng: "İş analizi. png",
          savingSucceeded: "Başarıyı kaydet",
          deletionSucceeded: "Silme başarısı",
          sequence: "Dizi",
          firstLevelClassification: "Birinci seviye sınıflandırma",
          ringRatioChange: "Zincir değişimi",
          classClassification: "Sınıf sınıflandırması",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Belgelerin silinmesini ve çıkarılmasını onayla?",
            addKnowledgeBase: "Bilgi bankası ekleme",
            uploadDocument: "Belge yükleme",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Word, pdf, ppt, excel, txt formatında dosyaları destekler",
            taskName: "Görev adı",
            extractConversationDate: "Diyalog tarihi",
            pleaseSelectAConversationDate: "Lütfen konuşma tarihini seçin",
            dataSource: "Veri kaynağı",
            dataType: "Veri Türleri",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "İhracat başarısı",
              problem: "Soru",
              answer: "Cevap",
              addToKnowledgeBase: "Bilgi tabanına ekle",
              pleaseConfirmWhetherToDeleteIt:
                "Lütfen silip silmediğinizi belirleyin",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Bilgi tabanına ekle",
              selectClassification: "Kategoriyi seçin",
              pleaseSelectAKnowledgeBaseClassification:
                "Lütfen Bilgi Bankası Sınıflandırmasını seçin",
            },
          },
        },
        index: {
          intelligentExtraction: "Akıllı Ekstraksiyon",
          documentExtraction: "Belge çizme",
          dialogueExtraction: "Diyalog çizme",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operasyon başarılı oldu",
          labelIgnored: "Görmezden gelen etiketler",
          add: "Ekleme",
          areYouSureToDeleteIt: "Silmek için emin misiniz?",
          batchFilterLabels: "Toplu Filtre Etiketleri",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Akıllı kalite kontrol",
        savedSuccessfully: "Başarıyı kaydet",
        logoSettings: "Logo ayarları",
        default: "Varsayılan",
        custom: "Özel",
        title: "Başlık",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Sistem tarafından gösterilen başlık adının 6 kelime içinde olması önerilir.",
        logoImageUpload: "Logo resim yükleme",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Lütfen boyutu 2048kb'den fazla olmayan bir resim seçin",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Logo tarama performansını sağlamak için, resim yükleme önerileri:",
        Size5050Pixels: "1. boyut: 50*50 piksel;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Biçim: şeffaf arka plan resimleri, png veya jpg,logo grafik beyaz kullanır",
        browserLabelIcon: "Tarayıcı etiketi simgesi",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Etiketlerde gezinmeyi sağlamak için resim yükleme önerileri:",
        Size3232Pixels: "1. boyut: 32*32 piksel;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Biçim: şeffaf arka plan resimleri, png veya jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Kalite kontrol devam ediyor, lütfen daha sonra...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "2015 yılında, Çin'in gayrimenkulü hayatta kalmak için kritik bir ana ulaştı, çok sayıda ev gözetimsiz bırakıldı ve sıradan insanlar satın almak istemiyordu. Hangzhou bir zamanlar 8000'in altına düştü, bazı geliştiriciler piyasaya çarptı ve sevk edildi, Shenzhen hala 2w-3w civarında konsolide edildi ve birinci kademe şehirlerdeki evler de tehlikede",
        xiaoAn: "Xiao An",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload Bu bellek bariyerleri jvm düzeyinde olan jsr bellek bariyerleri olarak adlandırılır. İşletim sisteminin altında iki uygulama yöntemi vardır: 1, veriyolunu kilitlemek, diğeri ise sfance (yazma bariyeri) mfence (tam bariyer) gibi bellek bariyerleri. Hotspots sanal makinesinin altında bir montaj ifadesi kullanılır: kilit addl, kilit veri yolu olan boş bir işlem, çünkü bazı işletim sistemleri bellek bariyeri meta dilini desteklemez.",
        xiaobei: "Küçük Kuzey",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Eti birkaç kez marine etmeye çalışın ve içtikten sonra kızartılmış yemekler, et ve kir gibi, ekşi bir koku! Marine et artık şarap pişirmiyor",
        xiaodong: "Xiaodong",
        xiaoxi: "Küçük Batı",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Eti birkaç kez marine etmeye çalışın, yemeklerden sonra kızartın, et ve kir gibi, ekşi bir koku! Marine et artık şarap içmiyor",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "E-ticaret İnceleme Yönetimi",
        socialMediaReviewManagement: "Sosyal medya yorum yönetimi",
        customDataSource: "Özel Veri Orijinal",
        qualityInspectionResults: "Kalite kontrol sonuçları",
        accountManagement: "Hesap Yönetimi",
        employeeProfile: "Çalışanların portreleri",
        intelligentAnalysis: "Akıllı analiz",
        customKanban: "Özel Kanban",
        dataScreening: "Verilere genel bakış",
        learningRecord: "Öğrenme kayıtları",
        dataAnalysis: "Veri analizi",
        scoringManagement: "Derecelendirme yönetimi",
        businessConfiguration: "İş Yapılandırması",
        manualReview: "Yapay inceleme",
        taskList: "Görev listesi",
        videoAnalysis: "Video analizi",
        overview: "Genel bakış",
        personalSignage: "Kişisel Kanban",
        learningCenter: "Öğrenme Merkezi",
        wrongQuestionSet: "Yanlış soru seti",
        abilityImprovement: "Yetenek geliştirme",
        close: "Kapat",
        switchingSystemApplications: "Sistem uygulamaları arasında geçiş yapın",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Uygulama değiştirme menüsünü çağırmak için fareyi buraya getirin, farklı uygulamalara atlamak için menü öğesine tıklayın",
        teamAnalysis: "Takım analizi",
        semanticIntelligence: "Anlamsal zeka",
        permissionManagement: "İzin yönetimi",
        dataConnection: "Veri yerleştirme",
        courseManagement: "Ders Yönetimi",
        labelManagement: "Etiket yönetimi",
        curriculum: "Kurs Merkezi",
        courseConfiguration: "Ders Yapılandırması",
        task: "Benim görevim",
        historicalLearningData: "Tarihi rekor",
        taskCenter: "Görev yönetimi",
        knowledgeBase: "Bilgi Bankası",
        ecommerceSessionManagement: "E-ticaret Oturum Yönetimi",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Uygulama değiştirme",
          descriptionOfIntelligentQualityInspectionSystem:
            "Akıllı kalite kontrol sistemi açıklaması",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "E-ticaret Oturum Filtresi",
        allEcommerceSessions: "Tüm e-ticaret oturumları",
        ecommerceSessionManagement: "E-ticaret Oturum Yönetimi",
      },
    },
    analysisEnable: {
      bestPractices: "En İyi Uygulamalar",
      behaviorAnalysis: "Davranış analizi",
      teamAnalysis: "Takım analizi",
      executiveSupervision: "İcra denetimi",
      executiveForceAnalysis: "Yürütme Gücü Analizi",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Derecelendirmeler:",
          startTime: "Başlangıç zamanı",
          workOrderNo: "İş emri numarası",
          customerServiceHasReadNotRead: "Müşteri hizmetleri okundu/okunmadı",
          canYouAppeal: "İtiraz edilebilir mi",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Kurumsal mikro açıklamalar",
          addFriendTime: "Arkadaş zamanı ekle",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Yeniden kalite kontrolünün başlatılması",
          callID: "Çağrı Kimliği",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Yeni özellikler! 'Hızlı nokta kontrolü' düğmesine tıklayın, başarılı bir şekilde gönderildikten sonra otomatik olarak bir sonraki sayfaya atlayacaksınız ~~~",
        },
      },
      template: {
        deleteRecord: "Kayıtları sil",
        areYouSureToDeleteIt: "Silmek için emin misiniz?",
        confirm: "Emin olmak için",
        reIdentification: "Yeniden tanımlama",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Yeniden tanımlama, harf çevirisi uzunluğunu tüketir, lütfen dikkatli olun",
        determine: "Emin olmak için",
        executionSuccessful: "Başarılı bir yürütme",
      },
      component: {
        pleaseSelectData: "Lütfen verileri seçin",
        operationSuccessful: "Operasyon başarılı oldu",
        startPerformingReQualityInspection:
          "Yeniden kalite kontrolünün başlatılması",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Dosya adı yükle",
        analysisTask: "Analiz görevleri",
        analyzeProgress: "İlerlemeyi analiz etme",
        analysisSucceeded: "Analitik başarı",
        analysisFailed: "Analiz başarısız",
        localDataUpload: "Yerel veri yükleme",
        recordingUpload: "Kayıt yükleme",
        textUpload: "Metin yükleme",
        uploadTime: "Yükleme süresi",
        taskName: "Görev adı",
        operator: "Operasyonel kişi",
        qualityInspectionProgress: "Kalite kontrol ilerleme",
        whole: "Hepsi...",
        haveInHand: "Devam ediyor",
        completed: "Tamamlanmış.",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Geçerli filtreleme koşulunda tamamlanan tüm veri kayıtlarını dışa aktarıyor musunuz?",
        upload: "Yükle",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Adı doldurun",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Bu içe aktarılan verilerin türü/koleksiyonu/amacı gibi bir adı doldurun",
            default: "Varsayılan",
            jDCOM: "JD.",
            dataType: "Veri Türleri",
            selectAnalysisTask: "Analiz görevlerini seçme",
            uploadFiles: "Dosya yükleme",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Excel şablonunu indirin, formatta doldurun:",
            template: "Şablon",
            SupportUpTo10000PiecesOfData: "2. maksimum destek 10000 veri",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Jingdong tarafından dışa aktarılan iletişim verilerinin yüklenmesini destekleyin, .txt dosyası, maksimum dosya boyutu 100M desteği",
            alongTheWayDataTemplate: "Yol Veri Şablonları",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Metin kalite kontrolünde, lütfen sabırlı olun",
            textQualityInspectionCompleted: "Metin kalite kontrol tamamlandı",
          },
        },
        template: {
          startAnalysis: "Analize başlayın",
          pleaseUploadTheFile: "Lütfen dosya yükleyin",
          localDataUpload: "Yerel veri yükleme",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "Yeni özellikler! 'Gözden geçirme gönderimi' düğmesine tıklayın, başarılı bir şekilde gönderildikten sonra otomatik olarak bir sonraki sayfaya atlayacaksınız ~~~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "Kalite kontrol görevi izni",
            edit: "Düzenleme",
            iParticipated: "Ben de dahil oldum.",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Bu rol, seçilen departman altındaki verileri görebilir",
            dataPermissions: "Veri izinleri",
            selectDepartment: "Bölüm seçimi",
            edit: "Düzenleme",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection:
            "Yeniden kalite kontrolünün başlatılması",
          callID: "Çağrı Kimliği",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Yeni özellikler! 'Örnekleme Gönderimi' ve 'Hızlı Örnekleme' düğmelerini tıklayın, başarılı bir şekilde gönderildikten sonra otomatik olarak bir sonraki sayfaya atlayacaksınız ~~~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Kurallar" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "İş emri yönetimi",
          workOrderDetails: "İş emri detayları",
        },
      },
      template: {
        workOrderFilter: "İş emri filtresi",
        allWorkOrders: "Tüm iş siparişleri",
        workOrderManagement: "İş emri yönetimi",
      },
    },
    trainingCenter: {
      topicAnnotation: "Konu etiketleme",
      topicList: "Konu listesi",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Karakter türleri",
              keyWord: "Anahtar kelimeler",
              pleaseEnterKeywordSearch: "Lütfen anahtar kelime arama girin",
              query: "Sorgulama",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Bu özellik henüz açık değilse, lütfen sistem yöneticinizle iletişime geçin",
          pleaseSelectAQuestion: "Lütfen soruları seçin!",
          recommendedSimilarSentencesLargeModel:
            "Önerilen benzer cümleler (büyük model)",
          selectAll: "Tam seçim",
          add: "Ekleme",
          changeBatch: "Başka bir parti",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "İş Çalışma Saatleri",
      localDataUpload: "Yerel veri yükleme",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Mevcut kullanıcı izinlerine göre menü listesini monte edin",
      sessionSettings: "Oturum Ayarları",
      logoSettings: "Logo ayarları",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Tekrar hoş geldiniz! Lütfen bilgilerinizi girin",
        mailbox: "Posta kutusu",
        password: "Şifre",
        signIn: "Giriş yap",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin:
            "Mevcut IP'nin giriş yapmasına izin verilmiyor",
          welcomeToLogin: "Giriş hoş geldiniz",
          pursuingExcellenceAndLeadingChange:
            "Mükemmellik arayışı ve değişime öncülük etmek",
          intelligentPlatform: "Akıllı platform",
        },
        virtualLogin: { index: { pleaseSelect: "Lütfen seçin" } },
      },
      oauth: {
        illegalCall: "Yasadışı arama",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Tekrar hoş geldiniz! Kimlik doğrulama platformuna atlamak için aşağıdaki düğmeye tıklayın",
        loggingIn: "Giriş yap...",
        unifiedIdentityAuthenticationLogin:
          "Birleşik Kimlik Doğrulama Oturum Açma",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Kural testi: Bazı kurallar, konuşma hızı, yol verileri vb. Gibi kural testini kullanamaz.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Daha fazla bilgi edinin",
          enteringTheSystem: "Sisteme giriş",
        },
      },
      index: {
        cancel: "İptal",
        enteringTheSystem: "Sisteme giriş",
        close: "Kapat",
        enterSystemApplication: "Sistem uygulamasına giriş",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Etkinleştirilen sistem uygulamasına atlamak için "Sisteme Git" i tıklayın',
      },
    },
    permissionManagement: {
      employeeManagement: "Çalışan Yönetimi",
      roleManagement: "Rol yönetimi",
      permissionManagement: "İzin yönetimi",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Eşittir",
            contain: "Dahil",
            doesNotContain: "Dahil değil",
            applicationSystem: "Uygulama sistemi",
            intelligentQualityInspection:
              "Akıllı Kalite Kontrol/Akıllı Oturum Analizi",
            salesEmpowerment: "Satış gücü",
            dataSourceType: "Veri kaynağı türleri",
            filterCriteria: "Tarama koşulları",
          },
        },
        index: {
          dataConnection: "Veri yerleştirme",
          savingSucceeded: "Başarıyı kaydet",
          dataIsolation: "Veri yalıtımı",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Açtıktan sonra, her bir uygulama sistemi erişim çağrısının veri aralığını ve kurumsal WeChat oturumunu ayarlayabilirsiniz. Anahtar açık değilse, her sistem tam veri erişimine sahiptir",
          accessRules: "Erişim kuralları",
          preservation: "Kaydet",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Uygulama adı",
            accessRights: "Erişim hakları",
            inAppPermissionSettings: "Uygulama içi izin ayarları",
            setUp: "Ayarlar",
            applicationAccess: "Uygulama erişimi",
            newRole: "Yeni roller",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "Oturum Detayları" } },
      messageNotification: {
        index: {
          sessionDetails: "Oturum Detayları",
          caseDetails: "Vaka detayları",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Görünür çalışanlar",
            newCourse: "Yeni Kurs",
            editCourse: "Dersleri düzenleme",
            courseName: "Ders Adı",
            courseIntroduction: "Kurs tanıtımı",
            courseType: "Ders Türü",
            courseClassification: "Ders Sınıflandırması",
            courseCover: "Ders kapağı",
            learningContent: "Öğrenme içeriği",
            uploadLearningMaterials: "Öğrenme materyallerini yükle",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Test",
              preservation: "Kaydet",
              cancel: "İptal",
              editNodeName: "Düğüm adını düzenleme",
              determine: "Emin olmak için",
              individual: "Bir tane.",
              of: ", Toplam",
              branch: "Puan",
            },
            configDrawerComponents: {
              pPTParsing: "PPT analizinde...",
              uploadSuccessful: "Başarılı yükleme",
              uploadAttachments: "Ekleri yükle",
              pPTParsingPleaseWait: "PPT analizi, lütfen daha sonra",
            },
            customerPortraitConfigDrawer: {
              cancel: "İptal",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Silme işleminden sonra geri yüklenemiyorum, bu portreyi silmek istediğimden emin misiniz?",
              delete: "Silme",
              determine: "Emin olmak için",
              customerPortrait: "Müşteri portreleri",
              bindSelectedCharacterPortrait: "Karakter portrelerini bağlama",
              customerProfileDetails: "Müşteri Portre Ayrıntıları",
              createANewCustomerProfile: "Yeni müşteri portresi",
            },
            flow: {
              sidebar: { node: "Düğümler" },
              useControlButtons: {
                cancellingPleaseWait: "İptal, lütfen sonra...",
                redoInProgressPleaseWait: "Yeniden yap, lütfen daha sonra...",
                automaticOrganization: "Otomatik düzenleme",
                canvasAdaptation: "Tuval Uyarlanabilir",
                delete: "Silme",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Düğümler kendilerine bağlanamaz",
              },
            },
            globalConfigDrawer: {
              cancel: "İptal",
              determine: "Emin olmak için",
              globalConfiguration: "Global Yapılandırma",
              sessionDetection: "Oturum Algılama",
              reciprocalRule: "Etkileşim kuralları",
              robotVoice: "Robot Tını",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Öğrenci düğümü zaman aşımına uğramadı",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Öğrencilerin cevap vermemesi için izin verilen maksimum sessizlik süresi (s)",
              promptScriptForFailureToAnswerOverTime: "Zaman aşımı cevapsız",
              addScript: "Konuşma ekle",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (Doğal)",
              zhishaChat: "Zhisha (Sohbet)",
              zhiqiangentle: "Zhiqian (ılımlı)",
              zhimiEmotion: "Zhimi (duygusal)",
              aiShuoNatural: "Ai Shuo (Doğal)",
              zhishuoChat: "Zhishuo (sohbet)",
              aiHaoAffinity: "Ai Hao (afinite)",
              zhixiangEmotion: "Zhixiang (duygusal)",
              robotVoice: "Robot Tını",
              schoolboy: "Erkekler",
              girlStudent: "Kız",
              volume: "Ses seviyesi",
              tone: "Ton",
              speechSpeed: "Konuşma hızı",
              test: "Test",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Merhaba, ben akıllı eğitim koçu ve sizinle iletişim için sabırsızlanıyoruz",
            },
            sessionDetectionForm: {
              expressionDetection: "İfade Algılama",
              excessiveDetectionOfModalParticles: "Çok fazla kelime tespiti",
              theNumberOfRepetitionsIs: "Tekrarlama sayısı",
              minus: "İkinci kez, eksi",
              appear: "Görünüyor",
              subtract: "Kat, eksi",
              branch: "Puan",
              addRules: "Kural eklendi",
              includingNegativeAngryComplaining:
                "(Olumsuz, kızgın, şikayet dahil)",
              scorePointsDisplay: "Puan puanları gösteriyor",
              reminderOfSpeakingTooFasttooSlow: "Çok hızlı/çok yavaş konuşma",
              scriptViolationReminder: "Konuşma ihlali hatırlatıcısı",
              emotionalReminder: "Duygusal hatırlatma",
              speechSpeedDetection: "Konuşma hızı algılama",
              normalSpeechSpeedScore: "Konuşma hızı normal puan",
              speakTooFast: "Çok hızlı konuşma",
              everyMinuteGreaterThan: "Dakikadan daha büyük",
              aWordAppears: "Bir kelime, belirir",
              speakTooSlowly: "Çok yavaş konuşma",
              lessThanPerMinute: "Dakikadan az",
              ignoreLessThan: "Daha az ihmal",
              aSentenceOfWords: "Kelimelerin cümleleri",
              emotionalDetection: "Duygu algılama",
              emotionalNormalScore: "Ruh hali normal skor",
              negativeEmotions: "Olumsuz duygular",
              illegalWordDetection: "İhlal Kelime Algılama",
              scriptViolationConfiguration: "Konuşma ihlali yapılandırması",
              realTimePromptOnTheFrontEnd: "Ön uç için gerçek zamanlı ipuçları",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Bilgi Bankası Sorular ve Cevaplar",
                  qAMethod: "Sorular ve Cevaplar",
                  numberOfQuestionsAndAnswers: "Soru ve Cevap Sayısı",
                  scoringRules: "Puanlama kuralları",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Herhangi bir soru ve cevap doğru,",
                  branch: "Puan",
                  successfullySaved: "Başarıyı kaydet",
                },
                pptNode: {
                  verificationPassed: "Doğrulama geçti",
                  upload: "Yükle",
                  supportPptPdfFormatFiles: "Destek ppt, pdf formatı dosyaları",
                  uploadSlide: "Slayt gösterisi yükle",
                  assessmentMethod: "Değerlendirme yöntemi",
                  associateSlide: "İlişkili Slayt",
                  standardScript: "Standart konuşma",
                  successfullySaved: "Başarıyı kaydet",
                },
                robotNode: {
                  robotScript: "Robot Sözleri",
                  randomScript: "Rastgele konuşma",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Benzer anlamsal kelimeleri seçin ve robot bu cümlelerden birini rastgele söyleyecektir",
                  customerPortrait: "Müşteri portreleri",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Müşteri portresi aynı olduğunda, robot bunu söyler",
                  setAsDefaultScript: "Varsayılan konuşma olarak ayarla",
                  yes: "Evet, evet.",
                  no: "Hayır, hayır.",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Robot herhangi bir kullanıcı etiketini kaçırdığında",
                  successfullySaved: "Başarıyı kaydet",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Bu dal düğümünde zaten varsayılan konuşma var, varsayılan konuşma geçerli konuşma ile değiştirilir mi?",
                },
                startNode: {
                  paragon: "Anlatım",
                  successfullySaved: "Başarıyı kaydet",
                },
                userNode: {
                  verificationPassed: "Doğrulama geçti",
                  standardScript: "Standart konuşma",
                  successfullySaved: "Başarıyı kaydet",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Lütfen yapılandırmayı mükemmelleştirin",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Lütfen bir konuşma örneği girin",
                  dialogueExample: "Diyalog örneği",
                  generateConversationExamples: "Konuşma örneği oluşturma",
                  mattersNeedingAttention: "Dikkat edilmesi gerekenler",
                  pleaseEnterThePromptLanguage: "Lütfen istemi girin",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Diyalog sahneleri",
                  simulateCharacter: "Simülasyon rolü",
                  accompanyingTrainees: "Eğitim nesnesi",
                  selectFromTemplateLibrary:
                    "Şablon kütüphanesinden seçim yapın",
                  templateLibrary: "Şablon Kütüphanesi",
                  role: "Rol",
                  scene: "Sahne",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Slayt Test Penceresi [",
              clearContent: "İçeriği temizle",
              close: "Kapat",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI oluşturma işlemi",
                scriptStyle: "Konuşma tarzı",
                theMostConversationRounds: "En çok konuşma turu",
                copyConversation: "Bir konuşmayı kopyalama",
                application: "Uygulama",
                regenerate: "Yeniden oluşturma",
                startGenerating: "Oluşturmaya Başla",
                pleaseGenerateAProcess: "Lütfen süreci oluşturun",
                copySuccessful: "Kopyalama başarısı",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Satış temsilcisi",
                    customerServicePersonnel: "Müşteri hizmetleri personeli",
                    storeCounter: "Mağaza sayacı",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "Tam metin konuşma oluşturma",
                speaker: "Konuşmacı",
                scriptStyle: "Konuşma tarzı",
                copyConversation: "Bir konuşmayı kopyalama",
                application: "Uygulama",
                regenerate: "Yeniden oluşturma",
                startGenerating: "Oluşturmaya Başla",
                pleaseGenerateAProcess: "Lütfen süreci oluşturun",
                operationSuccessful: "Operasyon başarılı oldu",
                copySuccessful: "Kopyalama başarısı",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Kelimeler rötuş" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Kelimeler boş olamaz!",
              copySuccessful: "Kopyalama başarısı",
              cancel: "İptal",
              copy: "Kopyalama",
              replaceTheOriginalText: "Orijinal metni değiştirin",
              aiGeneration: "Ai Oluşturma",
              aiPolishing: "Ai Rötuş",
              currentStyle: "Güncel stil",
              rigorous: "Titiz",
              mild: "Hafif",
              lively: "Canlı",
              simplicity: "Minimalist",
              pleaseEnterTheSpeakerRole: "Lütfen konuşmacı rolünü girin",
              startGenerating: "Oluşturmaya Başla",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Kelimeler boş olamaz!",
              copySuccessful: "Kopyalama başarısı",
              aiRewrite: "Ai yeniden yazma",
              cancel: "İptal",
              copy: "Kopyalama",
              replaceTheOriginalText: "Orijinal metni değiştirin",
              currentStyle: "Güncel stil",
              rigorous: "Titiz",
              mild: "Hafif",
              lively: "Canlı",
              simplicity: "Minimalist",
              pleaseEnterTheSpeakerRole: "Lütfen konuşmacı rolünü girin",
              startGenerating: "Oluşturmaya Başla",
            },
          },
          index: {
            verificationPassed: "Doğrulama geçti",
            ruleValidation: "Kural doğrulama",
            aIGeneration: "AI oluşturma",
            generateFullTextSpeechScript: "Tam metin konuşma oluşturma",
            editCourse: "Dersleri düzenleme",
            importConversation: "Konuşmayı içe aktarma",
            uploadExcelToCompleteDialogueImport:
              "Konuşma içe aktarmayı tamamlamak için Excel'i yükle",
            editBasicInformation: "Temel bilgileri düzenleme",
            test: "Test",
            release: "Yayınla",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Yeni etiket" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Silme işleminden sonra geri yüklenemiyor, bu etiketi silmek istediğimden emin misiniz?",
              delete: "Silme",
              cancel: "İptal",
            },
          },
        },
        index: {
          labelManagement: "Etiket yönetimi",
          newGroup: "Yeni gruplama",
          edit: "Düzenleme",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Silme işleminden sonra geri yüklenemiyor, bu etiket grubunu silmek istediğinize emin misiniz?",
          delete: "Silme",
          cancel: "İptal",
          editGroup: "Gruplamayı Düzenle",
          labelGroupName: "Etiket gruplama adı",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Kamera devre dışı bırakıldı",
              noCameraDevicesFound: "Kamera cihazı bulunamadı",
            },
          },
          cue: {
            index: {
              regenerate: "Yeniden oluşturma",
              generate: "Oluştur",
              referenceScript: "Referans Kelimeler",
              mattersNeedingAttention: "Dikkat edilmesi gerekenler",
              examFrequency: "Sınav sayısı",
              section: "1.",
              timestotal: "Kat/toplam",
              second: "İkinci kez",
              requirementsForPassingTheExam: "Sınav şartı geçti",
              examScore: "Sınav puanı ≥",
              branch: "Puan",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Portre yok",
              customerBackground: "Müşteri arka plan",
              customerLabel: "Müşteri Etiketleri",
            },
          },
          flow: {
            index: {
              processReminder: "İşlem istemi",
              score: "Skor",
              TotalScore: "/Toplam puan",
            },
          },
          index: {
            taskName: "Görev adı",
            taskStatus: "Görev durumu",
            whole: "Hepsi...",
            completionStatus: "Tamamlama Durumu",
            courseClassification: "Ders Sınıflandırması",
            practiceMode: "Uygulama modu",
            examMode: "Sınav modu",
            practiceTime: "Uygulama zamanı",
          },
          ppt: {
            index: {
              nextPage: "Sonraki sayfa",
              tips: "İpuçları",
              continueWithCurrentPage: "Geçerli sayfaya devam et",
              goToTheNextPage: "Bir sonraki sayfaya gidin",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Bir sonraki sayfaya girdikten sonra önceki sayfaya geri dönemezsiniz, lütfen atlamadan önce mevcut sayfanın tamamlanmasını onaylayın",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Bir sonraki sayfaya tıklamak bu istemi açmaz",
            },
            list: { index: { slide: "Slayt gösterisi" } },
          },
          record: {
            index: {
              unableToRecord: "Kayıt yapılamıyor:",
              creatingARecorderService: "Kaydedici hizmeti oluşturma",
              turnOffTheRecorderService: "Kaydedici hizmetini kapatma",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Mevcut istemci zaten ASR Websocket sunucusuna bağlı",
              afterProcessingTheLastFrameRestoreTheState:
                "Son kareyi işledikten sonra durumu geri yükle",
              aSRConnectionClosed: "ASR bağlantısı kapalı...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Kayıt duraklatıldı ve işlendi, ancak veriler önbellekte kaldı",
              foundASRChannelClosedRecreateWebsocketLink:
                "ASR kanalı kapalı bulundu, Websocket bağlantısını yeniden yarattı",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "ASR kanalı açılmadı, Websocket bağlantısını yeniden yarattı",
            },
            ppt: { hook: { index: { serverError: "Sunucu hatası" } } },
            text: {
              index: {
                pleaseEnter: "Lütfen girin",
                sendOut: "Gönder",
                startPracticing: "Pratik yapmaya başla",
                startTheExam: "Sınav başlıyor",
              },
            },
            util: {
              recorded: "Kaydedildi",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 bit pcm SendFrameSize 2 tam sayı olmalı",
              pCMSamplingRateShouldNotOccur: "Pcm örnekleme hızı olmamalıdır",
              andTheRequiredSamplingRate: "Ve gerekli örnekleme oranı",
              atypism: "Tutarsız",
              errorsThatShouldNotOccur: "Olmaması gereken hatalar:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> Sıralı veriler, eğer gereksinimler yüksek değilse, doğrudan </span>',
              flower: "Çiçek",
              TransferStopped: ": Aktarım durduruldu",
            },
            video: { index: { camera: "Kamera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Mevcut istemci zaten ASR Websocket sunucusuna bağlı",
                    aSRConnectionClosed: "ASR bağlantısı kapalı...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "ASR kanalı kapalı bulundu, Websocket bağlantısını yeniden yarattı",
                    unableToRecord: "Kayıt yapılamıyor:",
                    aSRChannelClosedReopened:
                      "ASR kanalı kapandı, yeniden açıldı",
                    theASRChannelWasNotCreatedStartingNow:
                      "ASR kanalı oluşturulmadı, şimdi oluşturulmaya başlandı",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect geri arama, ASR kanalını temizlemek için",
                    creatingARecorderService: "Kaydedici hizmeti oluşturma",
                    turnOffTheRecorderService: "Kaydedici hizmetini kapatma",
                  },
                  util: {
                    recorded: "Kaydedildi",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 bit pcm SendFrameSize 2 tam sayı olmalı",
                    pCMSamplingRateShouldNotOccur:
                      "Pcm örnekleme hızı olmamalıdır",
                    andTheRequiredSamplingRate: "Ve gerekli örnekleme oranı",
                    atypism: "Tutarsız",
                    errorsThatShouldNotOccur: "Olmaması gereken hatalar:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> Sıralı veriler, eğer gereksinimler yüksek değilse, doğrudan </span>',
                    flower: "Çiçek",
                    TransferStopped: ": Aktarım durduruldu",
                  },
                },
                index: { failInSend: "Gönderme başarısız oldu" },
              },
              index: {
                startRecording: "Kayıt yapmaya başla",
                endRecording: "Ses kaydını sonlandırın",
                startPracticing: "Pratik yapmaya başla",
                startTheExam: "Sınav başlıyor",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Mevcut sınavda kalan sayı",
              totalScriptScore: "Toplam puan:",
              totalEmotionalScore: "Duygusal toplam puan:",
              totalScoreOfExpression: "Toplam puanı ifade edin:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Mevcut görev puanlama sonuçlarının manuel olarak gözden geçirilmesi gerekiyor, incelemenin tamamlanması nihai sonuçtan haberdar edilecektir, lütfen sabırlı olun",
              areYouSureToSubmit: "Gönderim onaylandı mı?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Uygulama bitti, gönderim onaylandı mı?",
              theExamHasEndedAreYouSureToSubmit:
                "Sınav bitti, başvuru onaylandı mı?",
              completed: "Tamamlanmış.",
              page: "Sayfa",
              pageCount: "Toplam sayfa sayısı",
              topic: "Soru",
              totalNumberOfQuestions: "Toplam soru sayısı",
              totalScore: "Toplam puan",
              branch: "Puan",
              accumulatedCompletionProgress: "Birikmiş tamamlama",
              accumulatedPracticeDuration: "Birikimli egzersiz süresi",
              lastPauseExerciseResults: "Son Duraklatma-Uygulama Sonuçları",
              exerciseInstructions: "Egzersiz Talimatları",
              examInstructions: "Sınav açıklaması",
              halfwayPausePracticeResults: "Midway Duraklat-Uygulama Sonuçları",
              tips: "İpuçları",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Bu uygulama çıkıştan sonra puan olarak sayılmaz, çıkışını onaylıyor musunuz?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Sınavın ortasında geri çekilmesine izin verilmiyor, gönderim onaylandı mı?",
              pleaseOperateWithCaution: "Bu kez, lütfen dikkatli olun)",
              restart: "Tekrar başla",
              returnToLearning: "Çalışmaya geri dön",
              continuePracticing: "Uygulamaya devam et",
              continuingTheExam: "Sınav devam ediyor",
              return: "Geri dön",
              notSubmittedTemporarily: "Şimdilik gönderim yok",
              iGotIt: "Anlıyorum.",
              confirmExit: "Çıkış onayı",
              confirmSubmission: "Onay gönderim",
              signOut: "Çıkın",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Duraklat",
              submit: "Gönderme",
              remainingTimeOfThisQuestion: "Bu soru için kalan süre",
              SecondsLeftUntilTheEndTime: "Bitime 10 saniye kaldı.",
              remainingTime: "Kalan süre",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Kullanıcı ses içeriği algılanmadı, lütfen tekrar deneyin",
          },
          wave: {
            index: {
              continueRecording: "Kayıtlara devam et",
              submit: "Gönderme",
              endRecording: "Ses kaydını sonlandırın",
              suspendRecording: "Kaydı duraklatın",
              startPracticing: "Pratik yapmaya başla",
              startTheExam: "Sınav başlıyor",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Konuşma modu",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Çok fazla gürültü, lütfen sessiz ortamları değiştirin",
              normalNoiseDetection: "Gürültü algılama normal",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Ses çok düşük, lütfen sesi artırın",
              volumeDetectionIsNormal: "Ses Algılama Normal",
              noiseDetection: "Gürültü algılama",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Sessiz olun, 3s ortam sesini kaydedin",
              endRecording: "Ses kaydını sonlandırın",
              startRecording: "Kayıt yapmaya başla",
              volumeDetection: "Ses Algılama",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Lütfen normal ses seviyesinde "Şimdi Ses Testi" ni okuyun',
              playLastDetectedAudio: "Son algılanan sesi çal",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Lütfen başlamadan önce gereksinimleri dikkatlice okuyun",
              return: "Geri dön",
              iGotIt: "Anlıyorum.",
              timeLimitForSingleQuestionAnswering:
                "Tek soru cevap süresi sınırı",
              totalTimeLimit: "Toplam süre",
              unlimitedTime: "Sınırsız süre",
              stillNeedToCompleteTheTaskInTotal:
                "Görevi tamamlamak için hala gerekli",
              practice: "Egzersiz",
              second: "İkinci kez",
              taskScore: "Görev bir kez skor ≥",
              branch: "Puan",
              or: "Veya",
              examScore: "Sınav puanı ≥",
              remainingExamTimes: "Kalan sınav sayısı",
              totalScore: "Toplam puan",
              Script: "(Kelimeler",
              scoreEmotion: "Duyguları bölme",
              fractionExpression: "Bölünmüş ifade",
              points: "Puan)",
              standardScore: "Standart puan",
              excellentScore: "Mükemmel puan",
              exerciseInstructions: "Egzersiz Talimatları",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Soruyu sorunsuz bir şekilde cevaplamak için lütfen önce ekipman testini tamamlayın:",
              camera: "Kamera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Videonun içinde kendini görebilmek, kameranın normal olduğu anlamına gelir",
              microphone: "Mikrofon",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Lütfen mikrofona konuşun, dalga deseni görmek mikrofonun normal olduğu anlamına gelir",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Boş.",
                  copyTo: "Kopyala",
                  classification: "Sınıflandırma",
                  rename: "Yeniden adlandır",
                  newlyBuild: "Yeni",
                  categoryParent: "Üstün sınıflandırma",
                  moveTo: "Taşı",
                  name: "İsim",
                },
              },
              util: {
                rename: "Yeniden adlandır",
                moveTo: "Taşı",
                copyTo: "Kopyala",
                delete: "Silme",
                courseClassification: "Ders Sınıflandırması",
                newlyBuild: "Yeni",
              },
            },
          },
          edit: { index: { curriculum: "Dersler" } },
          index: {
            learn: "Öğrenme",
            practice: "Uygulama",
            examination: "Sınav",
            curriculum: "Dersler",
            courseIntroduction: "Kurs tanıtımı:",
            createdOn: "Oluşturma zamanı:",
            published: "Yayınlanan",
            tasks: "Bir görev",
            unpublishedTasks: "Yayınlanmamış görev",
            task: "Görev",
            operation: "Operasyon",
            learningMaterials: "Öğrenme materyalleri",
            goPractice: "Git pratik yap",
            publishTask: "Görev yayınla",
            taskData: "Görev Verileri",
            edit: "Düzenleme",
            copy: "Kopyalama",
            successfullyCopied: "Kopyalama başarısı",
            delete: "Silme",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Bu kursun altındaki tüm görev verileri silindikten sonra boşaltılır. Dersin silinmesi onaylanır mı?",
            courseName: "Ders Adı",
            creationTime: "Zaman oluşturma",
            releaseStatus: "Yayın durumu",
            whole: "Hepsi...",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Ses tespit edilmedi",
              voice: "Ses",
              text: "Metin",
              referenceScript: "Referans kelimeler:",
              clickOnTheRightSideToStartRecording:
                "Kaydı başlatmak için sağ tarafa dokunun",
              sendOut: "Gönder",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Mevcut istemci zaten ASR Websocket sunucusuna bağlı",
                afterProcessingTheLastFrameRestoreTheState:
                  "Son kareyi işledikten sonra durumu geri yükle",
                aSRConnectionClosed: "ASR bağlantısı kapalı...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Kayıt duraklatıldı ve işlendi, ancak veriler önbellekte kaldı",
                foundASRChannelClosedRecreateWebsocketLink:
                  "ASR kanalı kapalı bulundu, Websocket bağlantısını yeniden yarattı",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "ASR kanalı açılmadı, Websocket bağlantısını yeniden yarattı",
                unableToRecord: "Kayıt yapılamıyor:",
                creatingARecorderService: "Kaydedici hizmeti oluşturma",
                turnOffTheRecorderService: "Kaydedici hizmetini kapatma",
              },
              util: {
                recorded: "Kaydedildi",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 bit pcm SendFrameSize 2 tam sayı olmalı",
                pCMSamplingRateShouldNotOccur: "Pcm örnekleme hızı olmamalıdır",
                andTheRequiredSamplingRate: "Ve gerekli örnekleme oranı",
                atypism: "Tutarsız",
                errorsThatShouldNotOccur: "Olmaması gereken hatalar:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> Sıralı veriler, eğer gereksinimler yüksek değilse, doğrudan </span>',
                flower: "Çiçek",
                TransferStopped: ": Aktarım durduruldu",
              },
            },
            finishTest: {
              index: {
                endTest: "Bitiş testi",
                testResult: "Test sonuçları",
                returnToCanvas: "Tuvale geri dön",
                retest: "Tekrar test et",
              },
            },
          },
          index: {
            testFromCurrentNode: "Geçerli düğümden test",
            listening: "Yayın sırasında...",
            canvasTesting: "Tuval Testi",
            endOfProcess: "Sürecin sonu",
            restart: "Tekrar başla",
            start: "Başla",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Ayrıştırma",
                  expandParsing: "Çözümleme genişletin",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Çok fazla kelime",
                  second: "İkinci kez",
                  ratingOverview: "Puanlama genel bakış",
                  totalScore: "Toplam puan",
                  section: "1.",
                  name: "Adı",
                  of: "(Toplam",
                  people: "İnsanlar)",
                  scriptScore: "Konuşma puanı",
                  common: "Toplam",
                  keyPoints: "Önemli bir nokta,",
                  notReached: "Ulaşılmadı,",
                  have: "Var.",
                  violationOfRegulations: "İhlaller",
                  emotionalScore: "Duygusal skor",
                  appear: "Görünüyor",
                  secondaryNegativeEmotions: "İkinci olumsuz duygular",
                  expressionScore: "Puanı ifade et",
                  speakTooFast: "Çok hızlı konuşma",
                  times: "Kez,",
                  speakTooSlowly: "Çok yavaş konuşma",
                  average: "Ortalama",
                  wordminute: "Kelime/dakika",
                },
              },
              score: {
                index: {
                  studentNodeName: "Öğrenci düğüm adı",
                  keyPointName: "Nokta isimleri",
                  slideNodeName: "Slayt düğüm adı",
                  dialogue: "Diyalog",
                  mainPoints: "Önemli noktalar",
                  slide: "Slayt gösterisi",
                  detectionResult: "Test sonuçları",
                  satisfy: "Memnun",
                  dissatisfaction: "Memnun değil",
                  score: "Skor",
                  script: "Konuşma",
                  semantics: "Anlambilim",
                  scriptViolation: "Konuşma ihlali",
                  emotion: "Duygular",
                  expression: "İfade",
                },
              },
              taskTitle: { index: { branch: "Puan" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Bu sefer",
                  lastTime: "Geçen sefer...",
                  comprehensiveEvaluation: "Kapsamlı değerlendirme",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Standart konuşma birikimi',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Bir kelime, öğrenciler tarafından okunan kelimelerin sayısı",
                  aWordspan: "Bir kelime </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Sonraki </span><br /><span style = "margin-left: 8px">· Çok yavaş konuşma',
                  timesspan: "Kez </span>",
                  tooManyModalParticles: '<Span style = "margin-left: 8px">',
                  complete: "İçerik tamamlandı",
                  keyHit: "Nokta isabet",
                  languageNorm: "Dil normları",
                  fluentExpression: "Akıcı ifade",
                  emotionallyPositive: "Duygusal olarak olumlu",
                  radarChart: "Radar haritası",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Tam işlem sayısı',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· Evet',
                  dealingWithIllegalWordsspan: "Yasadışı kelimeler </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· Çok hızlı konuşma',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Sonraki </span><br /><span style = "margin-left: 8px">',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Olumsuz duygular',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Puanlama kayıtları",
                  firstRating: "İlk derece",
                  totalScore: "Toplam puan",
                  changeTo: "Olarak değiştirin",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "İletişim aşaması",
                  communicationObjectives: "İletişim Hedefleri",
                  scoringCriteria: "Puanlama kriterleri",
                  suggestionsForImprovement: "Yükseliş Önerileri",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Kalan sayı:",
                  second: "İkinci kez",
                  doItAgain: "Bir kere daha.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Tarayıcı ses çalmayı desteklemiyor.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Konuşma Yorumları ve Öneriler",
                  regenerate: "Yeniden oluşturma",
                  generate: "Oluştur",
                  generationFailed: "Oluşturma başarısız oldu",
                },
              },
              resultModal: {
                index: {
                  return: "Geri dön",
                  viewDetails: "Ayrıntıları görüntüle",
                },
              },
            },
            index: {
              reRatingSuccessful: "Yeniden değerlendirme başarısı",
              reRatingFailed: "Yeniden Puanlama Başarısız",
              downloadRecording: "Kayıt indir",
              downloadText: "Metin indirme",
              download: "İndir",
              reRating: "Yeniden değerlendirme",
              submitARatingAppeal: "Puanlama Şikayet Gönder",
              reviewSubmission: "Gözden geçirme ve gönderme",
              saveChanges: "Değişiklikler kaydet",
              previousSentence: "Son cümle",
              nextSentence: "Sonraki cümle",
              score: "Derecelendirme",
              scoringSituation: "Puan durumu",
              dialogueAnalysis: "Diyalog analizi",
            },
          },
          index: {
            ratingAppealInProgress: "Puanlama şikayetinde",
            operation: "Operasyon",
            wrongQuestionSet: "Yanlış soru seti",
            ratingUpdate: "Derecelendirme Güncellemeleri",
            viewDetails: "Ayrıntıları görüntüle",
            curriculum: "Dersler",
            taskName: "Görev adı",
            taskType: "Görev türleri",
            score: "Skor",
            scoringResults: "Puan sonuçları",
            time: "Zaman",
            courseClassification: "Ders Sınıflandırması",
            whole: "Hepsi...",
            learningTime: "Çalışma süresi",
            historicalLearningData: "Tarihsel Öğrenme Verileri",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Uygulama duraklatıldı",
              lastProgress: "Son ilerleme",
              continuePracticing: "Uygulamaya devam et",
              practice: "Egzersiz",
              startPracticing: "Pratik yapmaya başla",
              historicalData: "Tarihsel veriler",
              courseIntroduction: "Kurs tanıtımı:",
              exerciseDifficulty: "Uygulama Zorluğu:",
              practiceCompletionRequirements: "Uygulama Tamamlama Gereksinimi:",
              practiceFrequency: "Egzersiz sayısı ≥",
              second: "İkinci kez",
              anyScore: "Herhangi bir kez skor ≥",
              branch: "Puan",
              or: "Veya",
              taskList: "Görev listesi",
              practiceTime: "Uygulama süresi:",
              to: "Için",
              unlimitedTime: "Sınırsız süre",
              completionStatus: "Tamamlama Durumu",
              numberOfExercises: "Egzersiz sayısı",
              maximumScore: "En yüksek puan",
              operation: "Operasyon",
              enteringLearning: "Öğrenmek için giriş",
              historicalLearningData: "Tarihsel Öğrenme Verileri",
              wrongQuestionSet: "Yanlış soru seti",
            },
          },
          index: {
            practiceTasks: "Uygulama görevleri",
            examTasks: "Sınav görevleri",
            task: "Görev",
          },
          learn: {
            index: {
              courseName: "Ders Adı",
              courseIntroduction: "Kurs tanıtımı",
              learningContent: "Öğrenme içeriği",
              downloadLearningMaterials: "Öğrenme materyalleri indir",
              startPracticing: "Pratik yapmaya başla",
            },
          },
          test: {
            index: {
              taskList: "Görev listesi",
              courseIntroduction: "Kurs tanıtımı:",
              examDifficulty: "Sınav Zorluğu:",
              requirementsForPassingTheExam: "Sınav geçişi için şartlar:",
              examScore: "Sınav puanı ≥",
              branch: "Puan",
              examTime: "Sınav süresi:",
              to: "Için",
              unlimitedTime: "Sınırsız süre",
              completionStatus: "Tamamlama Durumu",
              completionTimes: "Tamamlama sayısı",
              maximumScore: "En yüksek puan",
              operation: "Operasyon",
              immediateExam: "Hemen sınav",
              history: "Tarihi rekor",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Referans Kelimeler" } },
              flow: {
                index: {
                  wrongQuestionItem: "Yanlış soru öğeleri (",
                  score: "Skor",
                  TotalScore: "/Toplam puan",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Sunucu hatası" } },
                  index: {
                    startRecording: "Kayıt yapmaya başla",
                    endRecording: "Ses kaydını sonlandırın",
                    startPracticing: "Pratik yapmaya başla",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Lütfen girin",
                    sendOut: "Gönder",
                    startPracticing: "Pratik yapmaya başla",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Kayıt yapmaya başla",
                    endRecording: "Ses kaydını sonlandırın",
                    startPracticing: "Pratik yapmaya başla",
                  },
                  hook: { index: { failInSend: "Gönderme başarısız oldu" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Bu yanlış uygulama puanı",
                  tips: "İpuçları",
                  areYouSureToEnd: "Bittiği doğrulandı mı?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Yanlış sorulara hakim olmadan tekrar pratik yapın",
                  continue: "Devam et",
                  returnToTheSetOfIncorrectQuestions:
                    "Yanlış soru setine geri dön",
                  end: "Bitiyor",
                  wrongQuestionItem: "Yanlış sorular",
                  numberOfExercisesThisTime: "Bu alıştırma sayısı",
                  masteringTheSituation: "Durumu kavrayın",
                  mastered: "Ustalaştı",
                  notMastered: "Usta değil",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Bu soruyu tekrar alıştırma",
                  nextQuestion: "Sonraki soru",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Yanlış uygulama",
                endExercise: "Egzersizi bitirin",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Yanlış uygulama",
                endExercise: "Egzersizi bitirin",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Yanlış uygulama",
                endExercise: "Egzersizi bitirin",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Konuşma görüntüle",
                  section: "1.",
                  secondaryDiscourseTechnique: "İkinci konuşma",
                },
              },
              tableItem: {
                count: { second: "İkinci kez" },
                scoreRuleList: {
                  noRelevantInformationFound: "İlgili bilgi bulunamadı",
                  recallWords: "Geri çağırma",
                  branch: "Puan",
                  answerIncorrectly: "Yanlış cevap",
                  second: "İkinci kez",
                  wrongAnswerRate: "Yanlış cevap oranı",
                },
                standardScript: {
                  standardScript: "Standart konuşma",
                  myScript: "Sözlerim",
                },
                status: { mastered: "Ustalaştı", notMastered: "Usta değil" },
                index: {
                  totalNumberOfQuestionsAnswered: "Toplam cevap sayısı",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Yanlış soru uygulama sayısı",
                },
              },
            },
            index: {
              wrongQuestionList: "Yanlış soruların listesi",
              wrongQuestionSet: "Yanlış soru seti",
              mispracticeExercises: "Yanlış uygulama",
              taskName: "Görev adı",
              staff: "Çalışanlar",
              masteringTheSituation: "Durumu kavrayın",
              whole: "Hepsi...",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Egzersiz sayısı ≥",
              second: "İkinci kez",
              or: "Veya",
              excellentScoreAtAnyOneTime:
                "Herhangi bir zamanda mükemmel puan (≥",
              points: "Puan)",
              examScore: "Sınav puanı ≥",
              branch: "Puan",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Rastgele Ortalama Dağıtım",
              assignToDesignatedPersonnel: "Belirlenen personele atama",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Başarılı olmak için sınav sayısını artırın",
              failedToIncreaseTheNumberOfExams: "Artan sınav başarısızlıkları",
              increaseTheNumberOfExams: "Sınav sayısını artırın",
              selectObjects: "Nesneleri seçme",
            },
          },
          executionFilter: { index: { participationRate: "Katılım oranı" } },
          intelligentReview: {
            index: {
              overallReview: "Genel inceleme",
              pleaseEnterTheReviewCriterianotMandatory:
                "Lütfen inceleme kriterlerini girin (zorunlu değil)",
              commentaryOnSingleSentenceRhetoric: "Tek cümle yorumu",
              realTimeReview: "Gerçek zamanlı yorumlar",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Sınav ortalaması",
            averageExamDurationmin: "Ortalama sınav süresi (min)",
            accumulatedPracticeDurationmin: "Birikimli egzersiz süresi (min)",
            accumulatedPracticeTimes: "Birikimli egzersiz sayısı",
            totalScore: "Toplam puan",
            average: "Ortalama puan",
            total: "Toplam",
            keyHitCount: "Nokta isabet sayısı",
            practiceAverageScore: "Egzersiz ortalaması",
            averagePracticeDurationmin: "Ortalama egzersiz süresi (min)",
            topOfTheHighestScore: "En yüksek puan Top3",
            branch: "Puan",
            errorProneItemTop: "Hata Top5",
            hitRate: "Atış yüzdesi",
            hitRateOfKeyPointsInScript: "Konuşma noktaları",
            averageScriptScore: "Ortalama Skorlar",
            sortByProcess: "Süreçlere göre sırala",
            sortByScore: "Puanlara göre sırala",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Sayfa ortalaması/Sayfa toplam puanı",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Bu ortalama puan/toplam puan",
            details: "Detaylar",
            viewRules: "Kuralları görüntüle",
            standardScript: "Standart konuşma",
          },
          scoreDetailList: {
            section: "1.",
            second: "İkinci kez",
            practice: "Egzersiz",
            examination: "Sınav",
            notMeetingStandards: "Uyumsuzluk",
            meetingStandards: "Standartlar",
            excellent: "Mükemmel",
            frequency: "Sayısı",
            fraction: "Puan",
            result: "Sonuçlar",
            operation: "Operasyon",
            viewDetails: "Ayrıntıları görüntüle",
          },
          index: {
            common: "Toplam",
            people: "İnsanlar",
            staff: "Çalışanlar",
            notMeetingStandards: "Uyumsuzluk",
            meetingStandards: "Standartlar",
            excellent: "Mükemmel",
            notStarted: "Başlamamış.",
            haveInHand: "Devam ediyor",
            completed: "Tamamlanmış.",
            passed: "Geçti",
            hangInTheAir: "Bitmemiş.",
            failed: "Başarısız",
            unlimitedTime: "Sınırsız süre",
            to: "Için",
            maximumScoringCriteria: "En yüksek puanlama kriteri",
            completionStatus: "Tamamlama Durumu",
            employeeName: "Çalışan adı",
            frequency: "Sayısı",
            practice: "Egzersiz",
            examination: "Sınav",
            average: "Ortalama puan",
            speechCraftAvgScore: "Ortalama Skorlar",
            emotionAvgScore: "Duygusal Ortalama Puanı",
            speedAvgScore: "Ortalama Puanı İfade",
            flowAvgScore: "Süreç Ortalama Puanı",
            practiceCostTime: "Birikimli süre",
            participateCommitRate: "Uyum oranı",
            participateExcellentRate: "Mükemmel oran",
            duration: "Süre",
            score: "Skor",
            scoringResults: "Puan sonuçları",
            flowScore: "Süreç Puanı",
            craftScore: "Konuşma puanı",
            emotionScore: "Duygusal skor",
            speedScore: "Puanı ifade et",
            contentIntegrity: "İçerik bütünlüğü",
            pointHitRate: "Puan isabet oranı",
            emotionNegativeRate: "Duygusal Olumsuz oran",
            paraPhaSiaRate: "Anormal konuşma oranı",
            toneOvermuchRate: "Çok fazla kelime",
            maximumScore: "En yüksek puan",
            highestScoreResult: "En yüksek puan sonuçları",
            state: "Durum",
            averageDurationmin: "Ortalama süre (min)",
            lately: "Son zamanlarda",
            time: "Zaman",
            operation: "Operasyon",
            viewDetails: "Ayrıntıları görüntüle",
            wrongQuestionSet: "Yanlış soru seti",
            taskName: "Görev adı",
            completeRequirements: "Gereksinimi tamamlayın",
            publishTo: "Yayınlama nesnesi",
            taskTime: "Görev süresi",
            fractionalComposition: "Kesirli kompozisyon",
            totalScore: "Toplam puan",
            branch: "Puan",
            Script: "(Kelimeler",
            scoreEmotion: "Duyguları bölme",
            fractionExpression: "Bölünmüş ifade",
            points: "Puan)",
            implementationRate: "Katılım oranı",
            numberOfExecutors: "Katılımcı sayısı",
            numberOfPeopleNotExecuted: "Katılmayan kişi sayısı",
            completionRate: "Tamamlama oranı",
            numberOfCompletedPersonnel: "Tamamlama sayısı",
            numberOfUnfinishedPersonnel: "Tamamlanmamış sayı",
            complianceRate: "Uyum oranı",
            numberOfQualifiedPersonnel: "Standart insan sayısı",
            numberOfNonCompliantIndividuals: "Uyumsuzluk sayısı",
            excellentRate: "Mükemmel oran",
            participateCompleteRate: "Katılım tamamlama oranı",
            numberOfOutstandingIndividuals: "Mükemmel sayı",
            numberOfNonOutstandingIndividuals: "Mükemmel olmayan sayı",
            export: "İhracat",
            taskData: "Görev Verileri",
            resultAnalysis: "Sonuç Analizi",
            scoreDetails: "Puan Detayları-",
            dataOverview: "Verilere genel bakış",
            taskDetail: "Görev detayları",
            scriptAnalysis: "Analitik analiz",
            flowAnalysis: "Süreç analizi",
            expressionAnalysis: "İfade Analizi",
            emotionAnalysis: "Duygusal Analiz",
            byTime: "Zaman boyutu görünümü",
            byNumber: "Sayım boyutu görünümü",
            personData: "Personellere göre istatistikler",
            numberData: "Bir kez kayıt detayları",
            count: "Sayma sayısı",
            person: "Kişi sayısına göre",
            reachTimes: "Dokunma sayısı",
            outOfReachTimes: "Dokunma sayısı",
            seeOutOfReach: "Dokunmamış görmek için tıklayın",
            accumulative: "Kümülatif",
            script: "Konuşma",
            times: "İkinci kez",
            sentence: "Cümle",
            expressionAverage: "Ortalama puanı ifade etmek",
            modalAnalysis: "Kelime analizi",
            total: "Toplam",
            muchModalAnalysis: "Çok fazla ruh hali kelimesi var",
            normal: "Normal.",
            speedAnalysis: "Konuşma hızı analizi",
            speedDistribution: "Konuşma hızı dağılımı",
            speakingSpeed: "Konuşma hızı",
            normalSpeak: "Normal konuşma hızı",
            tooFastSpeak: "Çok hızlı konuşma",
            tooSlowlySpeak: "Çok yavaş konuşma",
            violationWord: "İhlal sözcükleri",
            violationWordAnalysis: "İhlal kelimelerinin analizi",
            noData: "Henüz yok",
            emotionalScore: "Duygusal skor",
            emotionalDistribution: "Duygusal Dağılım",
            negativeEmotion: "Olumsuz duygular",
            normalEmotion: "Normal duygular",
            getDetail: "Ayrıntıları görüntüle",
            barPattern: "Çubuk görünümü",
            timeDimension: "Zaman Enlem Görünümü",
            timesDimension: "Sayım boyutu görünümü",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Ortalama puan",
                  averageScoreOfScript: "Konuşma ortalaması",
                  averageEmotionalScore: "Duygusal ortalama puan",
                  expressionAverageScore: "Ortalama Puanı İfade",
                  processAverageScore: "İşlem ortalaması",
                  accumulatedDuration: "Birikim süresi",
                  accumulatedTimes: "Kümülatif sayı",
                  participationComplianceRate: "Katılım oranı",
                  participationRateForAchievingExcellence: "Katılım oranı",
                  branch: "Puan",
                  numberOfPeople: "Kişi sayısı",
                  averageScoreOfExpression: "Ortalama puanı ifade etmek",
                  people: "İnsanlar",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Olumsuz duygular" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Ortalama Skorlar",
                  branch: "Puan",
                },
                useSummaryDetail: { keyHitRate: "Puan isabet oranı" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Akıllı Yorumlar",
            pleaseImproveTheConfiguration:
              "Lütfen yapılandırmayı mükemmelleştirin",
            examResults: "Sınav sonuçları",
            showTheResultsAfterEachExam: "Her sınavdan sonra sonuçları göster",
            showTheResultsAfterTheLastExam:
              "Son sınavdan sonra sonuçları göster",
            doNotDisplayResults: "Sonuçları göstermemek",
            resultDisplay: "Sonuç gösterisi",
            score: "Skor",
            scoringResults: "Puan sonuçları",
            allowViewingScoreDetails: "Puan detaylarını görüntülemenizi sağlar",
            viewCanvasConfiguration: "Tuval Yapılandırmasını Görüntüle",
            synchronizationFailedProcessNodeHasChanged:
              "Senkronizasyon başarısız oldu, işlem düğümü değişti",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Senkronizasyon başarılı, lütfen güncellenmiş kursu yayınlamak için tıklayın",
            retrievedTheLatestCanvasConfiguration:
              "En son tuval yapılandırmasını çekti",
            synchronizeCanvasConfiguration: "Senkronize Tuval Yapılandırmaları",
            scoringMethod: "Puanlama yöntemi",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Puan, akış {0} puan, konuşma {1} puan, duygu {2} puan, konuşma hızı {3} puan)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Mükemmel standartların standarda göre daha yüksek olması gerekir",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Puanlama kriterlerinin % 0'dan büyük, % 100'den az veya eşit olması gerekir",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Puanlama kriterlerinin 0 puandan büyük, {0} puandan az veya eşit olması gerekir",
            doRatingsRequireManualReview:
              "Puanlama manuel inceleme gerektiriyor mu",
            allocationStrategy: "Dağıtım stratejisi",
            pleaseSelectTheAssignedPersonnel: "Lütfen atanmış personeli seçin",
            pleaseSelectADesignatedPerson: "Lütfen belirtilen kişiyi seçin",
            timeLimitForSingleQuestionAnswering: "Tek soru cevap süresi sınırı",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Yeni eklenen çalışanlara varsayılan sürüm",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Yeniden puanlama, en son tuvalin puanlama kurallarına uygun olarak eşleştirileceğini gösterir. Geçerli görev için manuel olarak gözden geçirilmiş puanlama verileri vardır.",
            notCovered: "Kapak yok",
            cover: "Kapsama",
            participationSituation: "Katılım",
            numberOfParticipants: "Katılımcı sayısı",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Bu görev için seçilen alıştırma nesnelerinin sayısı",
            sumOfTheNumberOfParticipantsInTheTask:
              "Göreve katılan kişilerin sayısı",
            numberOfCompletedPersonnel: "Tamamlama sayısı",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Görev tamamlama gereksinimlerini karşılayan kişi sayısı",
            numberOfQualifiedPersonnel: "Standart insan sayısı",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Görev puanını karşılayan kişi sayısı",
            excellentPersonnel: "Mükemmel sayı",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Mükemmel sayı için görev puanı ile tanışın",
            participationRate: "Katılım oranı",
            numberOfParticipantsNumberOfParticipants:
              "Katılımcı sayısı/Katılımcı sayısı",
            numberOfCompletedNumberOfParticipants:
              "Tamamlanan kişi sayısı/Katılacak kişi sayısı",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Standart kişi sayısı/katılımcı sayısı",
            excellentNumbernumberOfParticipants:
              "Mükemmel sayı/katılımcı sayısı",
            participationCompletionRate: "Katılım tamamlama oranı",
            numberOfCompletedParticipated: "Tamamlama/Katılma Sayısı",
            participationComplianceRate: "Katılım oranı",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Standart kişi sayısı/katılımcı sayısı",
            participationRateForAchievingExcellence: "Katılım oranı",
            accumulatedDuration: "Birikim süresi",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Görevlere katılanlar",
            averageDuration: "Ortalama süre",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Öğrencilerin görev süresi toplamı/toplam katılım sayısı",
            accumulatedTimes: "Birikim sayısı",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Göreve katılan öğrencilerin sayısı",
            averageScore: "Ortalama puan",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Göreve katılan insanlar için ortalama puan",
            maximumScore: "En yüksek puan",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Göreve katılan kişiler için en yüksek puan",
            averageScoreOfScript: "Konuşma ortalaması",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Öğrenci Konuşma Puanı Toplam/Toplam Katılım",
            totalScoreOfScript: "Toplam Konuşma",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Bu görev altında ayarlanan toplam puan",
            processAverageScore: "İşlem ortalaması",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Öğrenci işlem puanı toplamı/toplam katılım sayısı",
            totalProcessScore: "Toplam İşlem Puanı",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Bu görev altında belirlenen toplam işlem puanı",
            averageEmotionalScore: "Duygusal ortalama puan",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Kursiyerlerin Duygusal Puanı Arayışı/Toplam Katılım Sayısı",
            totalEmotionalScore: "Duygusal toplam puan",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Bu görev altında ayarlanan toplam duygusal puan",
            averageScoreOfExpression: "Ortalama puanı ifade etmek",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Öğrenci ifade puanı toplamı/toplam katılım sayısı",
            totalScoreOfExpression: "Toplam puanı ifade etme",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Bu görev altında belirlenen toplam ifade puanı",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Yeniden derecelendirmeye başlayın, ilerlemeyi görmek için Görev Merkezine atlayabilirsiniz",
            reRating: "Yeniden değerlendirme",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Yeniden puanlama, mevcut puanlama sonuçlarını kapsayan en son tuval puanlama kurallarına göre eşleşmeyi gösterir",
            releaseTime: "Yayın zamanı",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "İncelendi",
              pendingReview: "İncelenecek",
              enterKeywordSearch: "Anahtar kelime arama girin",
            },
            pageRightTable: {
              index: {
                employeeName: "Çalışan adı",
                systemRating: "Sistem Puanı",
                scoringResults: "Puan sonuçları",
                duration: "Süre",
                time: "Zaman",
                state: "Durum",
                whetherToModifyTheRating: "Puanın düzeltilip düzeltilmeyeceği",
                finalScore: "Final puanı",
                reviewedBy: "Denetimliler",
                operation: "Operasyon",
                viewScoreDetails: "Puan detaylarını görüntüleyin",
                viewStatus: "Durumu görüntüle",
                whole: "Hepsi...",
                correctionStatus: "Düzeltme durumu",
                completeReview: "İncelemeyi tamamlayın",
                tips: "İpuçları",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Öğrencilerin nihai sonuçları mevcut nihai inceleme sonuçlarına dayanmaktadır, lütfen onaylayın",
              },
            },
          },
          index: { manualReview: "Manuel denetim" },
        },
        review: {
          index: {
            employeeName: "Çalışan adı",
            score: "Skor",
            scoringResults: "Puan sonuçları",
            duration: "Süre",
            taskName: "Görev adı",
            numberOfAppealScripts: "Şikayet sayısı",
            appealTime: "İtiraz süresi",
            processStatus: "Süreç durumu",
            scoreAfterReview: "İnceleme sonrası skor",
            reviewedBy: "İnceleme",
            operation: "Operasyon",
            viewScoreStatus: "Puanı kontrol et",
            whole: "Hepsi...",
            manualReview: "Yapay inceleme",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Uygulama Görev Verileri",
            implementationRate: "Yürütme oranı",
            numberOfExecutors: "İcra sayısı",
            numberOfPeopleNotExecuted: "Uygulanmayan kişi sayısı",
            completionRate: "Tamamlama oranı",
            numberOfCompletedPersonnel: "Tamamlama sayısı",
            numberOfUnfinishedPersonnel: "Tamamlanmamış sayı",
            complianceRate: "Uyum oranı",
            numberOfQualifiedPersonnel: "Standart insan sayısı",
            numberOfNonCompliantIndividuals: "Uyumsuzluk sayısı",
            excellentRate: "Mükemmel oran",
            numberOfOutstandingIndividuals: "Mükemmel sayı",
            numberOfNonOutstandingIndividuals: "Mükemmel olmayan sayı",
            examTaskData: "Sınav Görev Verileri",
            published: "Yayınlanan",
            courses: "Bir ders",
            courseData: "Ders Verileri",
            practiceTasks: "Uygulama görevleri",
            examTasks: "Sınav görevleri",
          },
          practiceSummary: {
            practiceSummary: "Uygulama özeti",
            numberOfPracticeTasks: "Uygulama Görevleri Sayısı",
            numberOfExercises: "Egzersiz sayısı",
            accumulatedPracticeDuration: "Birikimli egzersiz süresi",
            complianceRate: "Uyum oranı",
            excellentRate: "Mükemmel oran",
            ranking: "Sıralama",
          },
          rankingList: {
            numberOfExercises: "Egzersiz sayısı",
            exerciseDuration: "Uygulama süresi",
            complianceRate: "Uyum oranı",
            excellentRate: "Mükemmel oran",
            diligenceChart: "Çalışkanlık listesi",
            excellentList: "Mükemmel liste",
            ranking: "Sıralama",
            fullName: "İsim",
            i: "Ben...",
          },
          taskCard: {
            end: "Son teslim tarihi:",
            noDeadline: "Son tarih yok",
            second: "İkinci kez",
            branch: "Puan",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Görev yok",
            completed: "Tamamlanmış.",
          },
          filter: { employeeDepartment: "Personel bölümü" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Başarılı bir şekilde dışa aktarma, lütfen Görmek için Görev Merkezine gidin",
              export: "İhracat",
            },
          },
          filterConfig: {
            dropdown: { custom: "Özel" },
            modal: {
              updateSuccessful: "Başarıyla güncellendi",
              successfullySaved: "Başarıyı kaydet",
              setAsACommonReport: "Sık Kullanılan Raporlar Olarak Ayarla",
              updateCurrentReport: "Güncel raporu güncelleme",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Ortalama puan",
                  averageScoreOfScript: "Ortalama Skorlar",
                  averageEmotionalScore: "Duygusal Ortalama Puanı",
                  expressionAverageScore: "Ortalama Puanı İfade",
                  processAverageScore: "Süreç Ortalama Puanı",
                  branch: "Puan",
                },
              },
              comprehensiveEvaluation: { const: { data: "Veri" } },
              doneStatus: {
                index: {
                  practice: "Egzersiz",
                  examination: "Sınav",
                  executed: "Uygulanmıştır",
                  unexecuted: "Uygulanmadı",
                  accumulatedExamDuration: "Birikimli sınav süresi",
                  examFrequency: "Sınav sayısı",
                },
              },
              filter: {
                index: {
                  practice: "Egzersiz",
                  examination: "Sınav",
                  pleaseCompleteTheRequiredFields:
                    "Lütfen gerekli öğeleri iyileştirin",
                  staff: "Çalışanlar",
                  taskType: "Görev türleri",
                  pleaseSelect: "Lütfen seçin",
                  taskName: "Görev adı",
                },
              },
            },
            index: {
              completionStatus: "Tamamlama durumu",
              comprehensiveEvaluation: "Kapsamlı değerlendirme",
              abilityGrowthTrend: "Yetenek büyüme eğilimi",
              errorProneAnalysis: "Hatalı nokta analizi",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Aktif sayısı",
            accumulatedPracticeDuration: "Birikimli egzersiz süresi",
            averagePracticeDuration: "Ortalama egzersiz süresi",
            people: "İnsanlar",
            numberOfPracticeTasks: "Uygulama Görevleri Sayısı",
            numberOfExercises: "Egzersiz sayısı",
            exerciseDuration: "Uygulama süresi",
            second: "İkinci kez",
            avgScore: "Ortalama puan",
            speechCraftAvgScore: "Ortalama Skorlar",
            emotionAvgScore: "Duygusal Ortalama Puanı",
            speedAvgScore: "Ortalama Puanı İfade",
            flowAvgScore: "Süreç Ortalama Puanı",
            practiceCostTime: "Birikimli süre",
            totalPracticeTimes: "Kümülatif sayı",
            participateCommitRate: "Katılım oranı",
            participateExcellentRate: "Katılım oranı",
          },
        },
        index: {
          employeeProfile: "Çalışanların portreleri",
          overview: "Genel bakış",
          personalSignage: "Kişisel Kanban",
          ongoingExercises: "Egzersizler devam ediyor",
          ongoingExams: "Sınav devam ediyor",
          practiceTaskProgress: "Görev ilerlemesini uygulayın",
          examTaskProgress: "Sınav Görev İlerleme",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Lütfen Kavramsal Kelime Seçin",
              sessionDetectionSettings: "Oturum algılama ayarları",
              successfullySaved: "Başarıyı kaydet",
              abnormalSpeechSpeed: "Anormal konuşma hızı",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Özel konuşma hızı algılama standartlarını destekleyin, sonuç konuşma hızının normal, konuşma hızının çok hızlı ve konuşma hızının çok yavaş olduğu değerlendirilir. Anormal bir konuşma hızı olduğunda (çok hızlı/çok yavaş), puanlama kurallarına göre puanlar düşecektir.",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Çok Hızlı Konuşma: Dakikadan Daha Büyük",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Bir kelime, çok yavaş konuşma: dakikada daha az",
              charactersIgnoringLessThan: "Bir kelime, daha az göz ardı",
              aSentenceOfWords: "Kelimelerin cümleleri",
              excessiveModalParticles: "Çok fazla kelime",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Özel hava kelimelerini destekleyin ve çok fazla hava kelimesi için kurallar belirleyin. Çok fazla hava kelimesi olduğunda, puanlama kurallarına göre puanlar düşürülecek",
              theNumberOfRepetitionsIs: "Tekrarlama sayısı",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "O zaman, çok fazla kelime olduğu değerlendirilir",
              definitionOfSensitiveViolations: "Hassas ihlal tanımı",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Özel yasadışı kelimelerin eş anlamlığını destekleyin ve bir cümlede yasadışı kelimelerin görünüp görünmediğini tespit edin. İhlal kelimesi ortaya çıktığında, puanlama kurallarına göre puan kaybı",
              violationWords: "İhlal sözcükleri:",
              emotionalDetection: "Duygu algılama",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Sistemik duygusal model diyaloğuna göre duygusal testler yapılır ve sonuçlar normal duygular ve olumsuz duygular (olumsuz, kızgın ve şikayet dahil) olarak değerlendirilir. Duygusal bir olumsuzluk olduğunda, puanlama kurallarına göre puan kaybı",
              preserve: "Kaydet",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Puanlama boyutu",
              ratingPoints: "Puanlama noktaları",
              explain: "Açıklama",
              type: "Türü",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Rastgele veri" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Standart konuşma birikimi',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Bir kelime, öğrenciler tarafından okunan kelimelerin sayısı",
                  aWordspan: "Bir kelime </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Sonraki </span><br /><span style = "margin-left: 8px">· Çok yavaş konuşma',
                  timesspan: "Kez </span>",
                  tooManyModalParticles: '<Span style = "margin-left: 8px">',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Puan değeri" },
                  item: {
                    violationOnceReduced: "İhlal 1 eksi",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Anormal konuşma hızı (çok hızlı konuşma hızı, çok yavaş konuşma hızı dahil)",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Çok fazla kelime azaltma",
                    oneNegativeDecreaseInEmotions:
                      "Olumsuz duygular 1 kez azalır",
                    branch: "Puan",
                  },
                  wrap: { name: "İsim", preserve: "Kaydet" },
                },
              },
              index: {
                complete: "İçerik tamamlandı",
                keyHit: "Nokta isabet",
                accurateFollowupReading: "Doğru okuma",
                languageNorm: "Dil normları",
                speechSpeed: "Konuşma hızı",
                modalParticles: "Kelime",
                emotionallyPositive: "Duygusal olarak olumlu",
                scoringModel: "Puanlama modeli",
                successfullyModified: "Değişiklikler başarılı oldu",
                newSuccessfullyCreated: "Yeni başarı",
              },
            },
            index: {
              scoringModel: "Puanlama modeli",
              newModel: "Yeni model",
              model: "Model",
              type: "Türü",
              operation: "Operasyon",
              preview: "Önizleme",
              edit: "Düzenleme",
            },
          },
          index: {
            scoringModel: "Puanlama modeli",
            ratingDimension: "Puanlama boyutu",
            sessionDetectionSettings: "Oturum algılama ayarları",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Lütfen seçin",
                hit: "Vuruş",
                misses: "Vurmadı",
              },
            },
          },
          index: {
            duration: "Süre",
            processScore: "Süreç Puanı",
            branch: "Puan",
            contentCompleteness: "İçerik bütünlüğü",
            scriptScore: "Konuşma puanı",
            keyHitRate: "Puan isabet oranı",
            emotionalScore: "Duygusal skor",
            negativeProportionOfEmotions: "Duygusal Olumsuz oran",
            expressionScore: "Puanı ifade et",
            theProportionOfAbnormalSpeechSpeed: "Anormal konuşma oranı",
            excessiveProportionOfModalParticles: "Çok fazla kelime",
            learningRecordData: "Veri kaydetmeyi öğrenin",
            staff: "Çalışanlar",
            curriculum: "Dersler",
            mainPoints: "Önemli noktalar",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Formlar",
                        lineChart: "Çizgi grafik",
                        barChart: "Histogram",
                        pieChart: "Pasta haritası",
                      },
                      index: { switchingTypes: "Türleri değiştirme" },
                    },
                  },
                  index: {
                    saveSuccessful: "Başarıyı kaydet",
                    editChart: "Grafiği düzenleme",
                    chartName: "Grafik adı",
                    deleteSuccessful: "Silme başarısı",
                    refresh: "Yenileme",
                    export: "İhracat",
                    areYouSureToDeleteIt: "Silmek için emin misiniz?",
                    delete: "Silme",
                    viewFilteringCriteria: "Filtreleri görüntüleme",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Başarılı bir şekilde dışa aktarma, lütfen Görmek için Görev Merkezine gidin",
              },
            },
            header: {
              index: {
                saveSuccessful: "Başarıyı kaydet",
                deleteSuccessful: "Silme başarısı",
                editReport: "Raporları düzenleme",
                reportName: "Rapor adı",
                refresh: "Yenileme",
                areYouSureToDeleteIt: "Silmek için emin misiniz?",
                delete: "Silme",
                exportToExcel: "Excel'i dışa aktarma",
                exportSnapshot: "Anlık görüntüleri dışa aktarma",
                export: "İhracat",
                authority: "İzinler",
                permissionSettings: "İzin ayarları",
                reportVisiblePermissions: "Rapor Görünür İzinler",
                visibleToEveryone: "Herkes görünür",
                onlyVisibleToOneself: "Sadece kendiniz görünür",
                designatedPersonnelVisible: "Belirlenen kişi görünür",
                pleaseSelect: "Lütfen seçin",
                reportDataPermissions: "Rapor Verileri İzinleri",
                restrictedByUserPermissions: "Sınırlı kullanıcı izinleri",
                visiblePeopleAreTheSame: "Görünür insanlar aynı",
              },
            },
            titleFooter: {
              index: { addReport: "Yeni rapor", reportName: "Rapor adı" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Başarılı bir şekilde dışa aktarma, lütfen Görmek için Görev Merkezine gidin",
            dataQueryInProgress: "Veri sorgulama...",
            customKanban: "Özel Kanban",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Formlar",
                lineChart: "Çizgi grafik",
                barChart: "Histogram",
                pieChart: "Pasta haritası",
              },
              index: {
                successfullyAdded: "Başarı ekle",
                viewSQL: "Sql'i görüntüleyin",
                export: "İhracat",
                addToReport: "Bir rapora ekle",
              },
            },
            refer: {
              hooks: {
                data: "Veri",
                interrogativeSentence: "Soru",
                historicalQuery: "Tarihsel sorgulama",
              },
              index: {
                copySuccessful: "Kopyalama başarısı",
                copy: "Kopyalama",
              },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Uyumsuzluk",
                        meetingStandards: "Standartlar",
                        excellent: "Mükemmel",
                        notStartedYet: "Başlamamış.",
                        inProgress: "Devam ediyor",
                        completed: "Tamamlanmış.",
                        incomplete: "Bitmemiş.",
                      },
                    },
                  },
                  const: {
                    taskName: "Görev adı",
                    department: "Bölüm",
                    studentName: "Öğrenci adı",
                    scoringResults: "Puan sonuçları",
                    taskCompletionStatus: "Görev Tamamlama Durumu",
                  },
                  index: { pleaseSelectADimension: "Lütfen bir boyut seçin" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Boyut seçimi için çerçeveyi açmak için @ girin",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Doğrudan sorgulama için lütfen sorgulama deyimini girin",
                query: "Sorgulama",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Başarılı bir şekilde dışa aktarma, lütfen Görmek için Görev Merkezine gidin",
            dataQueryInProgress: "Veri sorgulama...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Anahtar Kelime Arama",
                previous: "Önceki",
                next: "Sonraki",
                theObjectThatTriggersTheScrollingEventIs:
                  "Kaydırma olayını tetikleyen nesne",
                userScrolling: "Kullanıcı kaydırma",
              },
            },
            summary: {
              index: {
                reminder: "İpuçları:",
                edit: "Düzenleme",
                name: "İsim",
                hintLanguage: "İpuçları",
              },
            },
            viewpoint: { index: { viewpointExtraction: "Fikir çıkarma" } },
            outline: {
              index: {
                numberOfKeywords: "Anahtar kelime sayısı",
                speaker: "Konuşmacılar",
                speechDuration: "Uzun konuşma",
                proportionOfSpeaking: "Konuşma oranı",
                maximumSpeechDuration: "En uzun konuşma süresi",
                numberOfQuestions: "Soru sayısı",
                averageSpeechSpeed: "Ortalama konuşma hızı",
                conversationalActions: "Oturum eylemi",
                contentSummary: "İçerik Özeti",
                generateSummary: "Özet oluşturma",
                keywordCloud: "Anahtar kelime bulut",
              },
            },
          },
          index: {
            outline: "Özet",
            viewpoint: "Bakış açısı",
            contentSummary: "İçerik Özeti",
            viewpointExtraction: "Fikir çıkarma",
            summaryOfKeyPoints: "Özetler",
            videoAnalysis: "Video analizi",
          },
        },
        list: {
          index: {
            delete: "Silme",
            uploadSuccessful: "Başarılı yükleme",
            videoList: "Video listesi",
            uploadVideo: "Video yükle",
            videoName: "Video adı",
            uploadTime: "Yükleme süresi",
            endTime: "Bitiş zamanı",
            analyzeProgress: "İlerlemeyi analiz etme",
            haveInHand: "Devam ediyor",
            completed: "Tamamlanmış.",
            fail: "Başarısızlık",
            toBegin: "Başla",
            operation: "Operasyon",
            see: "Görüntüle",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Çalışma süresi ekleme" },
        },
      },
      index: {
        businessWorkingHours: "İş Çalışma Saatleri",
        newlyBuild: "Yeni",
        name: "İsim",
        describe: "Açıklama",
        operation: "Operasyon",
        edit: "Düzenleme",
        delete: "Silme",
        editBusinessWorkingHours: "Düzenleme iş çalışma saatleri",
        newBusinessWorkingHours: "Yeni iş çalışma saatleri",
        workingHours: "Çalışma saatleri",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Akıllı Ekstraksiyon",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "İptal etmek istediğini onaylıyor musun? İptal edildikten sonra geri yüklenemez",
        },
      },
    },
    accountManagement: {
      securitySetting: "Güvenlik ayarları",
      accountManagement: "Hesap Yönetimi",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Için",
            switchToIPAddressMode: "IP adresi moduna geçme",
            switchToIPSegmentMode: "IP segment moduna geçme",
          },
        },
      },
      index: {
        saveSuccessful: "Başarıyı kaydet",
        pleaseImproveTheConfiguration: "Lütfen yapılandırmayı mükemmelleştirin",
        securitySetting: "Güvenlik ayarları",
        employeeLoginIPRestrictions: "Çalışan giriş IP sınırı",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Çalışanların oturum açabileceği IP aralığını sınırlandırın ve kapsam dışında oturum açmasına izin verilmez. Yalnızca web siteleri için geçerlidir, mobil uygulamalar için herhangi bir kısıtlama yoktur ve yöneticiler için herhangi bir kısıtlama yoktur.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Eklerken veya değiştirirken, lütfen harici IP segmentini (aynı C segmenti) veya sabit bir harici IP adresini girin.",
        allowLoginIPRange: "Giriş için izin verilen IP aralığı",
        preserve: "Kaydet",
      },
    },
    coachAdminEntry: { speechRecognition: "Ses tanıma" },
    billingCenter: {
      template: {
        details: "Detaylar",
        languageType: "Dil türleri",
        aSREngine: "ASR motoru",
        voiceQualityQuota: "Ses kalite kontrol kotası",
        realtimeData: "Gerçek zamanlı veri",
        calculatedByDay: "Güne Göre Hesaplama",
      },
      component: {
        aSRName: "ASR Adı",
        language: "Dil",
        remainingQuota: "Kalan kontenjan",
        hours: "Saatler",
        usedQuota: "Kullanılmış kota",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Operasyon başarıyla kaydedildi!",
        deletedSuccessfully: "Silme başarılı oldu!",
        pleaseSelectLanguage: "Lütfen dili seçin",
        pleaseConfigureRules: "Lütfen kuralları yapılandırın",
        updateSuccessful: "Güncelleme başarılı!",
        operationSuccessful: "Operasyon başarılı oldu!",
      },
      template: {
        aSRLanguage: "ASR dili",
        pleaseSelect: "Lütfen seçin",
        model: "Model",
        pleaseSelectLanguage: "Lütfen dili seçin",
        delete: "Silme",
        newASRModel: "Yeni ASR Modeli",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Rekabet analizi" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "E-ticaret İncelemesi Filtresi",
        allComments: "Tüm yorumlar",
        ecommerceReviewManagement: "E-ticaret İnceleme Yönetimi",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Sosyal medya yorum filtresi",
        allComments: "Tüm yorumlar",
        socialMediaReviewManagement: "Sosyal medya yorum yönetimi",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Rekabet analizi",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "İsim",
        nickname: "Takma ad",
        name: "İsim",
        email: "Hesap (e-posta)",
        mobile: "Telefon",
        agentId: "İş numarası",
        position: "Sonrası",
        role: "Rol",
        actions: "Operasyon",
        createUser: "Yeni çalışanlar",
        editUser: "Editör çalışanları",
        account: "Hesap numarası",
        originPassword: "İlk şifre",
        newPassword: "Yeni şifre",
        resumePassword: "Şifreyi onaylayın",
        userGroup: "Personel grubu",
        authorizationGroup: "İzinler grubu",
        resetPassword: "Şifrenizi sıfırlama",
      },
      userGroups: {
        id: "ID",
        name: "İsim",
        userCount: "Çalışan sayısı",
        actions: "Operasyon",
        createUserGroup: "Yeni personel grubu",
        editUserGroup: "Personel grubunu düzenleyin",
        member: "Üyeler",
      },
      role: {
        id: "ID",
        name: "İsim",
        actions: "Operasyon",
        all: "Hepsi...",
        taskName: "Görev",
        readTask: "Görev Görünüm",
        manageTemplate: "Şablon yönetimi",
        manualInspection: "Manuel yeniden inceleme",
        readResultAll: "Sonuçlar Görünüm",
        readResultPersonal: "Sonuç görüntüleme (kişisel)",
        readProcess: "Kalite kontrol süreci",
        readReport: "İstatistikler ve Raporlama",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Tarih",
        taskName: "İsim",
        size: "Boyut",
        status: "Durum",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "İsim",
            remark: "Açıklama",
            actions: "Operasyon",
          },
          checkPoint: {
            name: "İsim",
            remark: "Açıklama",
            type: "Türü",
            gradeType: "Değerlendirme yöntemi",
            conditionLogic: "Kurallar",
            predeterminedScore: "Puan değeri",
            weight: "Ağırlık",
            status: "Durum",
            actions: "Operasyon",
            switchOn: "Etkinleştir",
            switchOff: "Devre dışı bırakma",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Zaman",
            customer: "Müşteri",
            customerPhone: "Müşteri telefonu",
            callType: "Çağrı Türü",
            source: "Kaynak",
            voiceRecording: "Çağrı kaydı",
            graded: "Derecelendirme",
            openingGreeting: "Açılış selamları",
          },
          checkPointList: {
            name: "Kalite kontrol noktası",
            type: "Türü",
            deductScore: "Puan",
            isHit: "Vuruş durumu",
            status: "İnceleme",
            finalDeductScore: "Pu kesintiini onaylayın",
            hit: "Vuruş",
            notHit: "Vurmadı",
          },
        },
      },
    },
    custom: { keywords: "Diyalog Anahtar Kelimeler" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Sistem Ayarları" },
      systemIntegration: {
        text: "Sistem entegrasyonu",
        subTitle: {
          udesk: "Udesk Müşteri Hizmetleri Sistemi",
          udeskWhite: "Müşteri Hizmetleri Sistemi",
          wechat: "Kurumsal WeChat",
          taobao: "Taobao Qianniu",
          weifeng: "Mikro bolluk",
        },
      },
      columnsManage: { text: "Kalite kontrol alanı yönetimi" },
      customerField: { text: "Müşteri alanı" },
      speechRecognition: { text: "Ses tanıma" },
      user: { text: "Çalışan Yönetimi" },
      userGroups: { text: "Personel Grubu Yönetimi" },
      role: { text: "Rol yönetimi" },
      dataMigration: { text: "Veri taşıma" },
      taskCenter: { text: "Görev Merkezi" },
      recordUpload: { text: "Yerel kayıt yükleme" },
      anomalousData: { text: "Anormal veriler" },
      systemLog: { text: "Mesaj günlüğü" },
      msgCenter: { text: "Mesaj Merkezi" },
      wechatDocking: { text: "Kurumsal mikro yerleştirme" },
    },
    tasks: {
      homePage: { text: "Ana sayfa" },
      workbench: { text: "Çalışma Masası" },
      semanticIntelligence: { text: "Anlamsal zeka" },
      intelligentAnalysis: { text: "Akıllı analiz" },
      intelligentLabel: { text: "Akıllı etiketler" },
      admin: { text: "Yönetim" },
      callManage: { text: "Çağrı yönetimi" },
      dialogueManage: { text: "Diyalog yönetimi" },
      ticketManage: { text: "İş emri yönetimi" },
      dataAnalysis: { text: "Veri analizi" },
      customerService: { text: "Müşteri Hizmetleri Merkezi" },
      clientCenter: { text: "Müşteri Merkezi" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Eğitim Merkezi" },
      templates: { text: "Şablon yönetimi" },
      wechatQaTasks: { text: "Kurumsal WeChat Çalışma Masası" },
      wechatQaSetting: { text: "Kurumsal WeChat Analitik Yapılandırma" },
      wechatQaChartRecord: { text: "Kurumsal WeChat Oturumu" },
      wechatStaffCenter: { text: "Kurumsal Mikro Çalışan Merkezi" },
      callAnalysis: { text: "Oturum Analizi" },
      caseBaseFolder: { text: "Vaka kütüphanesi" },
      coachTask: { text: "Koçluk görevi" },
      teamAnalysis: { text: "Takım analizi" },
      customer: { text: "Müşteri Merkezi" },
      analysisEnable: { text: "Analiz güçlendirici" },
      analysisHelp: { text: "Yardım analizi" },
      analysisStudy: { text: "Öğrenme analizi" },
      analysisReview: { text: "Analiz analizi" },
      analysisTags: { text: "Akıllı Etiket Analizi" },
      analysisSentiment: { text: "Kamuoyu analizi" },
      analysisBusiness: { text: "İş Analizi" },
      groupTest: { text: "Grup testi" },
    },
    navBar: {
      billingCenter: "Yardım Merkezi",
      uploadDownload: "Yükleme Merkezi",
      setting: "Sistem Ayarları",
    },
  },
  fix: {
    selectType: "Seçim türü:",
    all: "Hepsi...",
    call: "Çağrı",
    text: "Diyalog",
    wechat: "Kurumsal WeChat Oturumu",
    businessTrendAnalysis: "Birinci seviye sınıflandırılmış iş trend analizi",
    noEchart: "Grafikte veri yok",
    checkPointisRequired: "Kural yapılandırması zorunludur",
    voiceDownLoad: "Kayıt indir",
    exportTxt: "TXT metnini dışa aktarma",
    exportWord: "Word metnini dışa aktarma",
    sureReRole: "Konuşmacının rolünü tersine çevirme onayı mı?",
    sureRecover: "Yeniden hata düzeltmesini onaylıyor musunuz?",
    sureReAsr: "Yeniden tanımlama onaylandı mı?",
    reRole: "Konuşmacının Ters Çevirilmesi",
    recover: "Yeniden Hata Düzeltme",
    reAsr: "Yeniden tanımlama",
    recordingOperation: "Kayıt işlemi",
    onlySave30Day: "(Sadece 30 gün için ayrılmış)",
    appealError: "Lütfen göndermeden önce kalite kontrol noktasına itiraz edin",
    exportToTaskCenter: "Yükleme İndirme Merkezi'ne başarıyla dışa aktarıldı",
    caseBaseLimit: "Vaka kütüphanesi 50'den fazla olamaz",
    unnamed: "İsimsiz",
    wordSpeed: "Kelime/dakika",
    hit: "Vuruş",
    checkPoint: "Kalite kontrol noktası",
    realTimeAdditionTip1:
      "Gerçek zamanlı ekleme: veriler kalite kontrol sistemine girdiğinde hemen kalite kontrol başlar",
    realTimeAdditionTip2:
      "Zamanlama ekleme: Kalite kontrol için otomatik olarak veri ekleme",
    null: "Hayır, hayır.",
    collcetedProps: "Toplama alanları",
    editingNameNow: "Düzenlenen bir isim var",
    upToFourLevels: "Kategori Dört seviyeye kadar",
    default: "Varsayılan",
    nameCantBeNull: "İsimler boş olamaz",
    editingNow: "Düzenlenen işlemler var",
    nameError: "Yanlış isim",
    nameMaxLength8: "İsim maksimum uzunluğu 8",
    callTime: "Konuşma süresi",
    sentimentValue: "Müşteri Duygu Değeri",
    agentSentimentValue: "Müşteri hizmetleri duygusal değeri",
    sentimentAnalysis: "Duygusal Analiz",
    associatedData: "Yol verileri",
    notifyTheOwner: "Grup sahiplerini bilgilendirin",
    notifyTheEmployee: "Çalışanın kendisini bilgilendirin",
    notifyTheOwnerTip:
      "Konuşma türü grup sohbeti olduğunda grup sahibi bilgilendirilir",
    customNotifyEmployee: "Çalışanlara özel bildirim",
    customNotifyEmployeeTip:
      "Tetikleyici çalışan ise, mesajın kendisine ait olduğunu bildiren çalışan",
    clickToLearnMore: "Daha fazla bilgi için tıklayın",
    repeatMarkingTip:
      "Bu kayıt kalite kontrol noktası tarafından işaretlenmiştir ve tekrar tekrar işaretlemeye gerek yoktur.",
    successfulEvaluation: "Değerlendirme başarısı",
    evaluationFailed: "Değerlendirme başarısız oldu",
    qualityInspectionScore: "Kalite kontrol puanı",
    semanticTags: "Anlamsal etiketler",
    smartTags: "Akıllı etiketler",
    collect: "Bilgi toplama",
    appealRecord: "Şikayet kaydı",
    satisfy: "Memnuniyet",
    notSatisfy: "Memnun değil",
    appealPlaceHolder: "Lütfen şikayet nedenini girin",
    giveUp: "Vazgeç",
    rejectReasonPlaceHolder: "Lütfen reddetme nedenini girin",
    reject: "Reddedilen",
    changeRejectReason: "Reddedilen sebeplerin değiştirilmesi",
    pass: "Tarafından",
    revise: "Değiştirme",
    machineEvaluation: "Makine değerlendirmesi",
    poor: "Kötü",
    excellent: "-Tamam.-Tamam.",
    moderate: "Genel olarak",
    mustMarked: "Kalite kontrol noktası işaretlenmelidir",
    pleaseChoose: "Lütfen seçin",
    manualEvaluation: "Yapay değerlendirme",
    qualityInspectionTemplate: "Uygulanabilir kalite kontrol şablonu",
    collectionTemplate: "Uygulanabilir toplama şablonları",
    keywordsWordCloud: "Anahtar kelime bulut",
    editCategoryLabel: "Kategori etiketlerini düzenleme",
    justCall: "Sadece aramaları izle",
    justConversation: "Sadece sohbete bakın",
    batchOperation: "Toplu işlemler",
    customerServicesGroup: "Müşteri Hizmetleri Grubu",
    joinGroup: "Grubuna katılın",
    removeGroup: "Gruptan çıkar",
    enterInformationEntity: "Lütfen bilgi varlığı içeriğini girin",
    enterNotBeNull: "Giriş boş olamaz",
    operationGroup: "Operasyon grubu",
    customerServiceSelected: "Seçilmiş müşteri hizmetleri",
    callAndConversation: "Çağrı/Konuşma",
    conversation: "Diyalog",
    system: "Sistem",
    customize: "Özel",
    effective: "Etkili",
    invalid: "Geçersiz",
    successfulAndToTaskCenter:
      "Başarılı bir performans için lütfen Görev Merkezine gidin",
    recalculationFailed: "Yeniden hesaplamalar başarısız oldu",
    selectRecalculatedIndicator:
      "Lütfen yeniden hesaplamak istediğiniz metriği seçin",
    source: "Lütfen veri kaynağını seçin",
    placeholder: "Lütfen anahtar kelimeleri girin",
    cumulative: "Kümülatif tip",
    average: "Ortalama tip",
    distribution: "Dağılım kanunu",
    originalDataCannotEmpty: "Ham veriler boş olamaz",
    inspection: "Kalite kontrol",
    check: "Örnekleme",
    review: "İnceleme",
    draftSavedSuccessfully: "Taslak kaydetme başarısı",
    optimization: "Optimizasyon",
    agentName: "Müşteri Hizmetleri Adı",
    allTasks: "Tüm kalite kontrol görevleri",
    datePickerSelect: "Tarih seçme",
    callSelect: "Bir konuşma seçin",
    taskSelect: "Görev seçimi",
    genReport: "Rapor oluşturma",
    dataSource: "Veri kaynağı",
    callsTotal: "Toplam arama",
    intelligentQualityInspection: "Akıllı kalite kontrol",
    percentage: "Oran",
    artificialSampling: "Manuel örnekleme",
    appealNumber: "Şikayet miktarı",
    totalConversation: "Toplam diyalog",
    samplingReviewRate: "Pompalama oranı",
    data: "Veri",
    inspectionResults: "Kalite kontrol sonuçları",
    inspected: "Kalite kontrol",
    totalScore: "Toplam puan",
    learnMore: "Daha fazlasını görün",
    inspectionEffect: "Kalite kontrol etkisi",
    allTotal: "Toplam miktar",
    manualInspection: "Manuel yeniden inceleme",
    artificialReview: "Yapay inceleme",
    approvalAmountAndRate: "Onay hacmi & onay oranı",
    customerScore: "Müşteri Hizmetleri Puanı",
    inspectionRecommendations: "Kalite kontrol önerileri",
    saveDraft: "Taslak kaydetme",
    historicalAdvice: "Tarihsel öneriler",
    advicePlaceHolder: "Lütfen önerilerinizi girin...",
    upload: "Dosya yükleme",
    customAdd: "Filtreleme ekleme",
    advancedConditions: "Gelişmiş koşullar",
    keyWord: "Anahtar kelimeler",
    containsAll: "Tüm içerir",
    containsAny: "Keyfi içerir",
    matchAny: "Herhangi bir maç",
    matchAll: "Hepsini eşleştirin",
    smartTagsOne: "Akıllı Etiket Bir",
    smartTagsTwo: "Akıllı Etiket II",
    season: "Sezon",
    month: "Ay.",
    week: "Hafta",
    day: "-Tanrım.-Tanrım.",
    hour: "Ne zaman",
    notMoreThan366: "366 günü geçemez",
    propsCannotBeNull: "Doldurulmamış alanlar var",
    other: "Diğer",
    correlationCoefficient: "Korelasyon katsayısı",
    repetitionRate: "Tekrarlama oranı",
    configurationItemsMoreThan20: "En fazla 20 yapılandırma öğesi ekleyin",
    conversionAnalysis: "Dönüşüm analizi",
    correlationAnalysis: "Korelasyon analizi",
    successfulExportAndToTaskCenter:
      "Başarılı bir şekilde dışa aktarma, lütfen Görmek için Görev Merkezine gidin",
    typeSelect: "Seçim türü",
    exportExcel: "Excel dışa aktarma",
    exportPng: "Png dışa aktarma",
    noDataChart: "Grafikte veri yok",
    placeOperator: "Lütfen akıllı etiketi seçin",
    placeAdvancedConditions: "Lütfen gelişmiş koşulları seçin",
    createAdvancedConditions: "Yeni gelişmiş filtreleme",
    moveUp: "Yukarı hareket",
    moveDown: "Aşağı in",
    moveLeft: "Sola kaymak",
    moveRight: "Sağa doğru hareket et",
    statusChangeSuccess: "Durum değişikliği başarılı oldu",
    editSuccess: "Değişiklikler başarılı oldu",
    saveSuccess: "Başarıyı kaydet",
    firstPage: "Ana sayfa",
    lastPage: "Kuyruk sayfası",
    customerInfo: "Müşteri Bilgileri",
    type: "Türü",
    NumberTypeErrorMessage: 'Alan bilgisi içeriği yalnızca sayı veya "."',
    taskStartPrompt: "Görev açma ipuçları",
    startConfirm:
      "Kalite kontrol görevini açmak için lütfen onay düğmesine tıklayın",
    continueToSubmitWithoutEvaluation:
      "Değerlendirme yapılmadan gönderim devam ediyor",
    rejectTip:
      "Hiçbir değişiklik tespit edilmemiştir, tüm şikayetlerin doğrudan reddedildiğini onaylıyor musunuz?",
    unmarkedSelfLearningTip:
      "İşaretlenmemiş kendi kendine öğrenme kalite kontrol noktanız var",
    markedSuccessfully: "Başarılı etiketleme",
    markingFailed: "Etiketleme başarısız oldu",
    exceedingLength: "Uzunluğun ötesinde",
    uncategorized: "Sınıflandırılmamış",
    addSuccess: "Yeni başarı",
    deleteSuccess: "Silme başarısı",
    featureName: "Element adı",
    informationEntity: "Bilgi varlıkları",
    standardAnswer: "Standart cevap",
    keyElementAnswer: "Anahtar Öğeler Cevaplandı",
    generalStatement: "Genel açıklama (sormadan)",
    generalQuestionsAndConfirmation: "Genel sorular ve onay",
    specialQuestionsAndAnswers: "Özel sorular ve cevaplar",
    agent: "Müşteri Hizmetleri",
    customer: "Müşteri",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Anahtar elemanlar semantik etiketler ve bilgi varlıkları aynı anda boş olamaz",
    standardSentence: "Standart cümle",
    acquaintance: "Tanışma derecesi",
    smartUePlaceholder: "Lütfen akıllı etkileşim adını girin",
    typeSelectPlaceholder: "Lütfen türünü seçin",
    keyElement: "Anahtar unsurlar",
    reQuerySementicTags: "Semantik Etiketleri Yeniden Seçin",
    querySementicTags: "Semantik etiketleri seçin",
    QATestForm: "Soru-Cevap Algılama Formu",
    selectNotBeNull: "Seçim boş olamaz",
    featureDetectionTarget: "Element algılama hedefi",
    categorized: "Sınıflandırma",
    effectiveness: "Geçerlilik",
    hideAdvancedSettings: "Gelişmiş ayarları gizle",
    showAdvancedSettings: "Gelişmiş ayarları göster",
    generalStatementOnly: "Genel açıklama",
    generalQuestions: "Genel sorular",
    affirmativeExpression: "Olumlu ifade",
    negativeIntention: "Olumsuz niyet",
    searchSementicTags: "Semantik etiketleri sorgula",
    choiceQuestion: "Seçmeli Soru Cümleleri",
    selectiveAnswer: "Seçmeli cevap",
    sementicTagsPlaceholder: "Lütfen semantik etiket içeriğini girin",
    openSpecialQuestion: "Açık tip özel sorular",
    openAnswer: "Açık cevap",
    customQA: "Özel Sorular ve Cevaplar",
    questions: "Soru",
    dataPlaceholder: "Lütfen içeriği girin",
    reply: "Cevap ver.",
    notFindSuitableLabel: "Doğru etiketi bulamadık",
    addSemanticTag: "Yeni bir semantik etiket ekle",
    intelligentInteraction: "Akıllı etkileşim",
    matching: "Maç",
    bout: "İkinci kez",
    keywordMatch: "Anahtar Kelime Eşleştirme",
    addFilterConditions: "Filtreleme kriterleri ekleme",
    buttonStartCollectionTask:
      "Toplama görevini açmak için lütfen Tamam düğmesine tıklayın",
    buttonStartTask:
      "Kalite kontrol görevini açmak için lütfen Tamam düğmesine tıklayın",
    ruleConfigurationIsRequired: "Kural yapılandırması zorunludur",
    asrRunning: "ASR Yürütme",
    errorCorrectionExecution: "Hata Düzeltme Yürütme",
    emotionalVolumeRecognition: "Duygusal ses tanıma",
    dataPreprocessing: "Veri ön işleme",
    inspectionExecution: "Kalite kontrol uygulaması",
    businessId: "Id Iş",
    businessTime: "İş zamanı",
    createTime: "Zaman oluşturma",
    abnormalReason: "Anormal nedenler",
    singleChat: "Tek sohbet",
    groupChat: "Grup sohbeti",
    taskContent: "Görev içeriği",
    submissionTime: "Gönderme zamanı",
    startTime: "Başlangıç zamanı",
    endTime: "Bitiş zamanı",
    runningTime: "Çalışması zaman alıcı",
    totalAmountOfData: "Toplam veri hacmi",
    resultsOfThe: "Uygulama sonuçları",
    operator: "Operatör",
    taskDetails: "Görev detayları",
    executiveFunction: "Yürütme işlevi",
    qualityCheckTasks: "Kalite kontrol görevleri",
    implementationDetails: "Yürütme detayları",
    conditionalRules: "Koşul kuralları",
    smartCondition: "Akıllı koşullar",
    goThis: "Buna Taşı",
    pleaseMarkClassification: "Lütfen sınıflandırmayı işaretleyin",
    advancedScreening: "Gelişmiş Tarama",
    noDataToManipulate: "Çalışacak veri yok",
    concept: "Kavramsal kelime",
    checkPoint1: "Kural yapılandırması",
    requiredFieldsCannotEmpty: "Gerekli öğe boş olamaz",
    rule: "Kurallar",
    afterFixUnit: "Bir kelime",
    unmarkedSelfLearningPointsTip:
      "İşaretlenmemiş kendi kendine öğrenme kalite kontrol noktanız var",
    onlyReservedFor7Days: "(Sadece 7 gün kalır)",
    downloadError: "İndirme başarısız oldu",
    pause: "Duraklat",
    continue: "Devam et",
    recalcScore: "Sadece puanları yeniden hesaplayın (derecelendirme dahil)",
    recalculatePointsAndScores:
      "Kalite kontrol noktalarının ve puanların yeniden hesaplanması",
    recalculatePointsAndScoresWithEmtry:
      "Kalite kontrol noktalarının ve puanların yeniden hesaplanması (kalite kontrol noktaları boş)",
    inProgress: "Devam ediyor",
    toBeExecuted: "Uygulanacak",
    executeImmediately: "Hemen idam",
    specialElements: "Özel elemanlar",
    logicRule: "Mantıksal kurallar",
    every: "Birden fazla maç",
    hitRecall: "Vuruş hatırlama",
    notHitRecall: "İsabetli Geri Çağırma",
    hitRecallRegular: "Vuruş hatırlama düzenli",
    notHitRecallRegular: "İsabetsiz Düzenli",
    hitRule: "Vuruş kuralları",
    notHitRule: "İsabetsiz kurallar",
    hitSimilarSentence: "Benzer cümleleri vurmak",
    notHitSimilarSentence: "Benzer Cümle",
    hitExcludeSimilarSentences: "Benzer cümleler hariç",
    notHitExcludeSimilarSentences: "Benzer cümleleri dışlamak için başarısız",
    updateSuccess: "Başarıyla güncellendi",
    qualify: "Düzenli",
    recallKeyWord: "Anahtar kelimeleri hatırlama",
    grammar: "Gramer kuralları",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Geçerlilik değişikliği başarısız oldu, lütfen tekrar deneyin",
    postAutoMateTasksCaseByTaskId:
      "Vaka kitaplığı senkronizasyonu yapılandırma kaydetme başarısız oldu",
    getAutoMateTasksCaseByTaskId:
      "Vaka Kitaplığı Senkronize Yapılandırma Başarısız Oldu",
    postInspectionRemarkModelConfigs: "Açıklama Şablon kaydetme başarısız oldu",
    putInspectionRemarkModelConfigsById:
      "Açıklama Şablon kaydetme başarısız oldu",
    getInspectionRemarkModelConfigsById:
      "Açıklama Şablon Verileri Başarısız Oldu",
    postKnowledgeDatas:
      "Bilgi Bankası yeni oluşturulamadı, lütfen tekrar deneyin",
    putKnowledgeDatasById:
      "Bilgi Bankası değişikliği başarısız oldu, lütfen tekrar deneyin",
    getLibraryFiles: "Fiyat klasörü listesi alınamadı",
    getLibraryCalls: "Dosya alma başarısız oldu",
    getLibraryFilesSearch: "Arama başarısız oldu",
    putLibraryFilesMoveById: "Fiyat klip hareketi başarısız oldu",
    putLibraryCallsById: "Dosya yeniden adlandırma başarısız oldu!",
    postLibraryFiles: "Klasör Ekleme Başarısız",
    putLibraryFilesById: "Klasör yeniden adlandırma başarısız oldu",
    deleteLibraryFilesById: "Silme işlemi başarısız oldu",
    deleteLibraryCallsById: "Silme işlemi başarısız oldu",
    postLibraryCalls: "Ekleme başarısız oldu",
    getVoiceUploadResultById:
      "Yükleme sonuçlarının dışa aktarılması başarısız oldu",
  },
};
