// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostLibraryFilesCopyBody, BaseResponseVoid } from '../../types';

/**
 * copy
 *
 * @export
 * @tags 案例库文件夹
 * @link [POST] /libraryFiles/copy
 */
export function postLibraryFilesCopy(data: PostLibraryFilesCopyBody): Promise<BaseResponseVoid>;
export function postLibraryFilesCopy(
    data: PostLibraryFilesCopyBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postLibraryFilesCopy(
    data: PostLibraryFilesCopyBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostLibraryFilesCopyBody, never, never>('/libraryFiles/copy', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postLibraryFilesCopy',
    });
}

export const meta = [{ tags: ['案例库文件夹'], path: '/libraryFiles/copy', method: 'post' }];
