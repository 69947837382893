
import locals from '../../locales/index';

let sourceTypes = [
    { id: 0, name: () => locals.current.labels.all, key: "all" },
    { id: 1, name: () => locals.current.components.report.enums.sourceTypes.agent, key: "agent" },
    { id: 2, name: () => locals.current.components.report.enums.sourceTypes.client, key: "client" },
];

// 图表转表格 必须是 toTable 和 toChart 两个类型
let chartToTableTypes = [
    { id: 'toTable', key: 'toTable', name: () => locals.current.components.report.enums.chartDataView.toTable },
    { id: 'toChart', key: 'toChart', name: () => locals.current.components.report.enums.chartDataView.toChart },
];

export default {
    sourceTypes,
    chartToTableTypes
};