import React from 'react';
// import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';

const Template = React.memo((props: any) => {
    const locales = Locales['current'];

    return (
        <div className='related-details-component'>
            <div className='related-details-title'>{/* 关联通话/对话 */}{locales.components.relatedDetail.index.associatedCallConversation}</div>
            <div className='related-details-content'>
                {/* <div>关联对话： #h4t9yt</div>
                <div>关联通话： a8hdkstwndm789</div> */}
            </div>
        </div>
    );
});

export default React.memo(Template);
