// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostReviewIntelligentPartnerNodeAiFlowByFlowIdParam,
    PostReviewIntelligentPartnerNodeAiFlowByFlowIdBody,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * ai生成学员对话流程
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/node/aiFlow/{flowId}
 * @param flowId
 */
export function postReviewIntelligentPartnerNodeAiFlowByFlowId(
    data: PostReviewIntelligentPartnerNodeAiFlowByFlowIdBody,
    options: ApiOptions<PostReviewIntelligentPartnerNodeAiFlowByFlowIdParam, never> & {
        segments: PostReviewIntelligentPartnerNodeAiFlowByFlowIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PostReviewIntelligentPartnerNodeAiFlowByFlowIdBody,
        PostReviewIntelligentPartnerNodeAiFlowByFlowIdParam,
        never
    >('/review/intelligentPartner/node/aiFlow/{flowId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewIntelligentPartnerNodeAiFlowByFlowId',
    });
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/aiFlow/{flowId}',
        method: 'post',
    },
];
