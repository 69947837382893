// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetDataCallSessionFlowsQueryQuery,
    BaseResponseDataCallSessionFlowFoundResponse,
} from '../../types';

/**
 * 获取会话流程
 *
 * @export
 * @tags 销售赋能-会话流程接口
 * @link [GET] /dataCallSessionFlows/query
 */
export function getDataCallSessionFlowsQuery(
    options: ApiOptions<never, GetDataCallSessionFlowsQueryQuery> & {
        params: GetDataCallSessionFlowsQueryQuery;
    }
): Promise<BaseResponseDataCallSessionFlowFoundResponse> {
    return request<never, never, GetDataCallSessionFlowsQueryQuery>('/dataCallSessionFlows/query', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getDataCallSessionFlowsQuery',
    });
}

export const meta = [
    { tags: ['销售赋能-会话流程接口'], path: '/dataCallSessionFlows/query', method: 'get' },
];
