import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Button, Input, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div``;

export type TemplatePropsValue =
    | undefined
    | {
          isPeriod?: boolean;
          start?: string;
          end?: string;
      };

type TemplateProps = {
    value?: TemplatePropsValue;
    onChange?: (v: TemplatePropsValue) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange } = props;

    const onToggle = () => {
        onChange?.({
            ...value,
            isPeriod: !value?.isPeriod,
        });
    };

    const onPeriodStartChange = (v: string) => {
        onChange?.({
            ...value,
            start: v,
        });
    };

    const onPeriodEndChange = (v: string) => {
        onChange?.({
            ...value,
            end: v,
        });
    };

    return (
        <Wrapper>
            {value?.isPeriod ? (
                <Space direction="vertical">
                    <Space>
                        <Input
                            value={value?.start}
                            onChange={(e) => onPeriodStartChange(e.target.value)}
                            style={{ width: 200 }}
                        />
                        <div>{/* 至 */}{UdeskLocales['current'].pages.securitySettings.components.iPInput.index.to}</div>
                        <Input
                            value={value?.end}
                            onChange={(e) => onPeriodEndChange(e.target.value)}
                            style={{ width: 200 }}
                        />
                    </Space>
                    <Button style={{ padding: 0 }} type="link" onClick={onToggle}>{/* 切换为IP地址模式 */}{UdeskLocales['current'].pages.securitySettings.components.iPInput.index.switchToIPAddressMode}</Button>
                </Space>
            ) : (
                <Space direction="vertical">
                    <Input
                        value={value?.start}
                        onChange={(e) => onPeriodStartChange(e.target.value)}
                        style={{ width: 200 }}
                    />
                    <Button style={{ padding: 0 }} type="link" onClick={onToggle}>{/* 切换为IP段模式 */}{UdeskLocales['current'].pages.securitySettings.components.iPInput.index.switchToIPSegmentMode}</Button>
                </Space>
            )}
        </Wrapper>
    );
};

export default memo(Template);
