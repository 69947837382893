// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetRiskSettingsQuery,
    BaseResponseListRiskSettingFoundResponse,
    PostRiskSettingsBody,
    BaseResponseRiskSettingFoundResponse,
} from '../types';

/**
 * 获取风险配置详情-分页
 *
 * @export
 * @tags 销售赋能-风险配置
 * @link [GET] /riskSettings
 */
export function getRiskSettings(): Promise<BaseResponseListRiskSettingFoundResponse>;
export function getRiskSettings(
    options: ApiOptions<never, GetRiskSettingsQuery>
): Promise<BaseResponseListRiskSettingFoundResponse>;
export function getRiskSettings(
    options?: ApiOptions<never, GetRiskSettingsQuery>
): Promise<BaseResponseListRiskSettingFoundResponse> {
    return request<never, never, GetRiskSettingsQuery>('/riskSettings', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getRiskSettings',
    });
}

/**
 * 新增风险配置
 *
 * @export
 * @tags 销售赋能-风险配置
 * @link [POST] /riskSettings
 */
export function postRiskSettings(
    data: PostRiskSettingsBody
): Promise<BaseResponseRiskSettingFoundResponse>;
export function postRiskSettings(
    data: PostRiskSettingsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseRiskSettingFoundResponse>;
export function postRiskSettings(
    data: PostRiskSettingsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseRiskSettingFoundResponse> {
    return request<PostRiskSettingsBody, never, never>('/riskSettings', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postRiskSettings',
    });
}

export const meta = [
    { tags: ['销售赋能-风险配置'], path: '/riskSettings', method: 'get' },
    { tags: ['销售赋能-风险配置'], path: '/riskSettings', method: 'post' },
];
