function filterEffectFields(fields) {
    let effectFirlds = fields.filter(field => field.effect);
    return effectFirlds;
}
//visibleFieldsMap和disableFieldsMap中存储值刷新的逻辑,不是重新渲染，这里仅改变了Map存储的值
function refershFieldsMap(type, field, fieldsMap, formInstance) {
    // 
    if(field.effect[type] === undefined){
        return;
    }
    let names = (Object.values(field.effect[type]) as any).flat(1);
    names.forEach((i) => {
        fieldsMap.set(i, false);
    });
    let value = formInstance.getFieldValue(field.name);
    // 
    if (value && field.effect[type][value]) {
        field.effect[type][value].forEach((i) => {
            // 
            fieldsMap.set(i, true);
        });
    }
}
//获取实例中存储映射的对应值
function getFieldsMapValue(name, fieldsMap) {
    if(typeof name === 'string'){
        return fieldsMap.get(name);
    }else{
        for (let [key, value] of fieldsMap.entries()) {
            if(Array.isArray(key) && key.join(',') === name.join(',')){
                return value;
            }
        }
    }
    return undefined;
}
function getPrevFieldsValue(name, prevFidldsValue) {
    if(typeof name === 'string'){
        return prevFidldsValue[name];
    }
    return name.reduce((xs, x) => (xs && xs[x]) ? xs[x] : undefined, prevFidldsValue);
}
function buildObjectFromNamePaht(effectedField) {
    let setObj = { [effectedField.name]: effectedField.output };
    if(Array.isArray(effectedField.name)){
        setObj = effectedField.name.reduceRight((obj, path, index) => {
            
            if(index === effectedField.name.length - 1){
                obj[path] = effectedField.output;
            }else{
                obj = {[path]: obj};
            }
            return obj;
        }, {});
    }
    return setObj;
}
export {
    filterEffectFields,
    refershFieldsMap,
    getFieldsMapValue,
    getPrevFieldsValue,
    buildObjectFromNamePaht,
};
