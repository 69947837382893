// import Udesk from "Udesk";
import React from 'react';
import CheckPointConditionListGatherComponent from './component';
import './style.scss';
import CheckPointCondition from 'Component/pages/components/check-point-condition-list-gather/check-point-condition';
import { Button, Space } from 'udesk-ui';
import { AssociateProvider, WorkTimeProvider } from './Contxt';
export default class CheckPointConditionListGatherTemplate extends CheckPointConditionListGatherComponent {
    render() {
        let { props, actions, privates } = this;
        let {
            isNewAssociate = 1,
            isAutoAssociateDateSource = 1,
            replenishConditionTypes,
            replenishOperatorTypes,
            showApplyTimeType = false,
        } = props;
        let { conditionList, conditionTypeList, fieldDataList } = privates;

        const showConditionTypeList = props.isCollection
            ? conditionTypeList.filter((item) => item.key === 1)
            : props.type.includes(0)
            ? conditionTypeList.filter((item) => item.key !== 4)
            : conditionTypeList.slice();
        //类型补充函数，可以对显示类型进行修正
        if (replenishConditionTypes) {
            replenishConditionTypes(showConditionTypeList, conditionTypeList);
        }
        return (
            <div className="check-point-condition-list-gather">
                <WorkTimeProvider>
                    <AssociateProvider type={props.type}>
                        {conditionList &&
                            conditionList.length !== 0 &&
                            conditionList.map((condition, index) => {
                                return (
                                    <div
                                        className="check-point-condition-list-item"
                                        key={condition.idCode}
                                    >
                                        <CheckPointCondition
                                            // {...this.props}
                                            isNewAssociate={isNewAssociate}
                                            isAutoAssociateDateSource={isAutoAssociateDateSource}
                                            funcType={props.funcType}
                                            key={condition.idCode}
                                            conditionList={conditionList}
                                            condition={condition}
                                            conditionTypeList={showConditionTypeList}
                                            type={props.type}
                                            ruglar={props.ruglar}
                                            fieldDataList={fieldDataList}
                                            onChange={actions.handleConditionListChange.params(
                                                index
                                            )}
                                            isWechatQa={props.isWechatQa}
                                            isSales={props.isSales}
                                            replenishOperatorTypes={replenishOperatorTypes}
                                            showApplyTimeType={showApplyTimeType}
                                            taskId={props.taskId}
                                            hasSummaryRule={props.hasSummaryRule}
                                        ></CheckPointCondition>

                                        <div
                                            className="tasks-manage-template-configuration-detail-rule-item-delete"
                                            onClick={actions.deleteCondition.params(index)}
                                        >
                                            <span
                                                className="udesk-qa-react-web-iconfont"
                                                style={{
                                                    fontSize: '24px',
                                                    cursor: 'pointer',
                                                    color: 'rgba(0,0,0,0.15)',
                                                }}
                                            >
                                                &#xe653;
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </AssociateProvider>
                </WorkTimeProvider>
                <div
                    className="check-point-condition-list-add"
                    key={'check-point-condition-list-add'}
                >
                    <Space>
                        {showConditionTypeList.map((item) => {
                            return (
                                <Button
                                    key={item.key}
                                    onClick={actions.addCondition.params(item.key)}
                                    // type='text'
                                    // className='check-point-condition-list-add-btn'
                                >
                                    <span
                                        className="udesk-qa-react-web-iconfont"
                                        style={{
                                            fontSize: '16px',
                                            marginRight: '8px',
                                            cursor: 'pointer',
                                            lineHeight: '16px',
                                        }}
                                    >
                                        &#xe610;
                                    </span>
                                    {item.name}
                                </Button>
                            );
                        })}
                    </Space>
                </div>
            </div>
        );
    }
}
