import React from 'react';
import Udesk from 'Udesk';
import './style.scss';
import classifiedTrainingRoute from './route';
import { TreeSelect, Button, Col, Row, Radio, Popover, Icon, PageHeader } from 'udesk-ui';
import moment from 'moment';
import _isEmpty from 'lodash-es/isEmpty';
import ClassNames from 'classnames';
import _map from 'lodash-es/map';
import ReactModal from 'udesk-react/src/components/react-modal';
import SelectCallTimer from '../../components/select-call-time';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import UdeskDatePicker from 'Component/common/udesk-date-picker';

// let { Option } = Select;
export default class classifiedTrainingTemplate extends classifiedTrainingRoute {
    render() {
        let { smartData,
            smartValue,
            statAt,
            noneDataTrain,
            markedDataCorrigenda,
            classifiedDataCheck,
            markedHistoryRecord,
            historyDataRecalculate,
            markedDataCorrigendaValue,
            smartReatsData,
            treeDataList,
            labelActive,
            noneDataValue,
            RadiomarkedDataCorrigendaValue,
            selectCallTimeVisible,
            redoCallIds,
            customName,
            customId,
            conditionList,
            judgeStrategy,
            customJudgeLogic,
            filterVisible,
            classifiedDataCheckValue,
            valueType,
            labelParent,
            hitData,
            RadioclassifiedDataCheckValue,
            nonejudgeStrategy,
            nonecondtionJson,
            nonecustomJudgeLogic,
            classcondtionJson,
            classcustomJudgeLogic,
            classjudgeStrategy,
            markcondtionJson,
            markjudgeStrategy,
            markcustomJudgeLogic,
            treeDataRef,
            labelRef
        } = this.privates;
        let { actions, locales } = this;
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.trainingCenter.classifiedTraining.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root classified-training-pages' style={{ backgroundColor: 'transparent' }}>
                        <div className="classified-training-pager-body">
                            <div className="classified-taining-pager-body-sumbit">
                                <span>
                                    {locales.components.pages.trainingCenter.classifiedTraining.tagsGrounp}：
                                    {/* <Select style={{ width: '130px' }} value={smartValue} placeholder={locales.components.pages.trainingCenter.classifiedTraining.placeHolder} onChange={actions.smartChangeValue}>
                                        {
                                            !_isEmpty(smartData) && smartData.map(item => {
                                                return (
                                                    <Option value={item.id} title={item.categoryName}>{item.categoryName}</Option>
                                                );
                                            })
                                        }
                                    </Select> */}
                                    <TreeSelect
                                        style={{ width: 250 }}
                                        treeData={smartData}
                                        onChange={actions.smartChangeValue}
                                        value={smartValue}
                                        showSearch
                                        // allowClear
                                        treeNodeFilterProp='title'
                                        showArrow
                                        placeholder={locales.components.pages.trainingCenter.classifiedTraining.placeHolder}
                                        treeDefaultExpandAll={false}
                                        treeDataSimpleMode={true}
                                    />
                                </span>
                                <span className='classified-taining-pager-body-sumbit-time'>
                                    {locales.components.pages.trainingCenter.classifiedTraining.cellTime}： <UdeskDatePicker disabledDate={disabledDate} onChange={actions.timeChange} value={statAt} />
                                    {/* <RangePicker value={[moment(statAt[0]), moment(statAt[1])]} onChange={actions.timeChange} disabledDate={disabledDate} /> */}
                                </span>
                                <Button type='primary' className='classified-taining-pager-body-sumbit-time' onClick={actions.sumbitGet}>{locales.components.pages.trainingCenter.classifiedTraining.search}</Button>
                                <Button className='classified-taining-pager-body-sumbit-time' onClick={actions.reseat}>{locales.components.pages.trainingCenter.classifiedTraining.reset}</Button>
                            </div>
                            <div className='classified-training-pager-body-chart'>
                                <Row gutter={16}>
                                    {
                                        Udesk.enums.smartRatesList.map(item => {
                                            return (
                                                <Col span={6}>
                                                    <div className='classified-training-pager-body-chart-card'>
                                                        <div className='classified-training-pager-body-chart-card-info'>
                                                            <div className='classified-training-pager-body-chart-card-info-left'>{item.name}</div>
                                                            <div className='classified-training-pager-body-chart-card-info-right'>
                                                                {
                                                                    (!_isEmpty(smartReatsData) && smartReatsData[item.id]) || <span>{locales.components.pages.trainingCenter.classifiedTraining.none}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </div>
                            <div className='classified-tarining-pager-body-tree'>
                                <div className='classified-tarining-pager-body-tree-header'>
                                    <div className='classified-tarining-pager-body-tree-header-title'>{locales.components.pages.trainingCenter.classifiedTraining.classified}</div>
                                    <div className='classified-tarining-pager-body-tree-header-tabs'>
                                        <span className={ClassNames({ 'classified-training-tab-active': valueType === 1 })} onClick={actions.valueTypeChange.params(1)}>{locales.components.pages.trainingCenter.classifiedTraining.num}</span>
                                        <span className={ClassNames({ 'classified-training-tab-active': valueType === 2 })} onClick={actions.valueTypeChange.params(2)} >{locales.components.pages.trainingCenter.classifiedTraining.gas}</span>
                                    </div>
                                </div>
                                <div className="classified-tarining-pager-body-tree-body">
                                    <div className='label-tabs-list-page' ref={labelRef}>
                                        {
                                            !_isEmpty(treeDataList) && _map(treeDataList, (item, index) => {
                                                if (item) {
                                                    return (
                                                        <div className='label-tabs-list-page-field' key={index} style={{ width: '190px' }} ref={treeDataRef[`tree-${index}`]} onMouseUp={actions.fieldUp}>
                                                            <div className='label-field-target-drag' onMouseDown={actions.treeDrag.params(treeDataRef[`tree-${index}`])} >
                                                                <Icon type="ic-pause-zanting" />
                                                            </div>
                                                            <div className='label-tabs-list-page-field-drag'>
                                                                {
                                                                    !_isEmpty(item) && item.map(items => {
                                                                        return (
                                                                            <div className={ClassNames('label-field-info', { 'label-field-info-active-parent': (labelParent[items.tagLevel] === items.id) || (hitData[items.tagLevel] === items.id) }, { 'label-field-info-active': items.id === labelActive })} key={items.id} onClick={actions.activesLabel.params(items.id, items.tagLevel, items.leafFlag)}>
                                                                                <span className='label-field-info-field' title={valueType === 1 ? items.tagName : items.nameWithData}>
                                                                                    {
                                                                                        valueType === 1 && items.tagName
                                                                                    }
                                                                                    {
                                                                                        valueType === 2 && items.nameWithData
                                                                                    }
                                                                                </span>
                                                                                {
                                                                                    items.leafFlag !== 1 && (
                                                                                        <Icon type="ic-play-line" />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    return;
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='classified-tarining-pager-body-action'>
                                <Row gutter={[16, 16]}>
                                    <Col span={12} style={{ height: '190px' }}>
                                        <div className='classified-tarining-pager-body-action-card'>
                                            <div className='classified-tarining-pager-body-action-card-header'>
                                                <div className='classified-tarining-pager-body-action-card-header-title'>{locales.components.pages.trainingCenter.classifiedTraining.noData} {!_isEmpty(noneDataTrain) && <span> {Udesk.utils.string.format(locales.components.pages.trainingCenter.classifiedTraining.noDataDays, noneDataTrain.lastTimeDays)}</span>}</div>
                                                {
                                                    !_isEmpty(noneDataTrain) && noneDataTrain.signType && (
                                                        <div className='classified-tarining-pager-body-action-card-header-type'>
                                                            {
                                                                noneDataTrain.signType === 1 && <span>{locales.components.pages.trainingCenter.classifiedTraining.noDataSmall}</span>
                                                            }
                                                            {
                                                                noneDataTrain.signType === 2 && <span>{locales.components.pages.trainingCenter.classifiedTraining.noDataHeight}</span>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='classified-tarining-pager-body-action-card-info'>
                                                <div>
                                                    <Radio.Group value={noneDataValue} onChange={actions.noneDataTrainChange}>
                                                        {
                                                            Udesk.enums.dataExtractModeTypeOne.map(item => {
                                                                if (item.id === Udesk.enums.dataExtractMode.custom.id) {
                                                                    return (
                                                                        <Radio value={item.id}>
                                                                            {item.name}{locales.components.pages.trainingCenter.classifiedTraining.training}
                                                                            {
                                                                                noneDataValue === Udesk.enums.dataExtractModeTypeOne.custom.id && (
                                                                                    <Popover content={this.actions.queckListMang(nonecondtionJson, nonejudgeStrategy, nonecustomJudgeLogic)} placement='bottomRight'>
                                                                                        <Button style={{ marginLeft: '5px' }} onClick={actions.changeFilterVisible.params('noneDataValue')}>{locales.components.pages.trainingCenter.classifiedTraining.custom}</Button>
                                                                                    </Popover>
                                                                                )
                                                                            }
                                                                        </Radio>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <Radio value={item.id}>{item.name}{locales.components.pages.trainingCenter.classifiedTraining.training}</Radio>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </Radio.Group>
                                                </div>
                                                <div className='classified-tarining-pager-body-action-card-button'>
                                                    <Button type='primary' style={{ marginRight: '10px' }} onClick={actions.startTrain.params(Udesk.enums.trainTypeList.noneDataTrain.id, noneDataValue)}>{locales.components.pages.trainingCenter.classifiedTraining.start}{locales.components.pages.trainingCenter.classifiedTraining.training}</Button>
                                                    <span style={{ cursor: 'pointer' }} onClick={actions.typeTo}>{locales.components.pages.trainingCenter.classifiedTraining.addClass}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{ height: '190px' }}>
                                        <div className='classified-tarining-pager-body-action-card'>
                                            <div className='classified-tarining-pager-body-action-card-header'>
                                                <div className='classified-tarining-pager-body-action-card-header-title'>{locales.components.pages.trainingCenter.classifiedTraining.marked} {!_isEmpty(markedDataCorrigenda) && <span>{Udesk.utils.string.format(locales.components.pages.trainingCenter.classifiedTraining.noDataDays, markedDataCorrigenda.lastTimeDays)}</span>}</div>
                                                {
                                                    !_isEmpty(markedDataCorrigenda) && markedDataCorrigenda.signType && (
                                                        <div className='classified-tarining-pager-body-action-card-header-type'>
                                                            {
                                                                markedDataCorrigenda.signType === 1 && <span>{Udesk.utils.string.format(locales.components.pages.trainingCenter.classifiedTraining.markedSmall, markedDataCorrigenda.lastTimeDays)} </span>
                                                            }
                                                            {
                                                                markedDataCorrigenda.signType === 2 && <span>{Udesk.utils.string.format(locales.components.pages.trainingCenter.classifiedTraining.markedHeight, markedDataCorrigenda.lastTimeDays)}</span>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='classified-tarining-pager-body-action-card-info'>
                                                <div>
                                                    <Radio.Group value={RadiomarkedDataCorrigendaValue} onChange={actions.radiomarkedDataCorrigenda}>
                                                        {
                                                            Udesk.enums.dataExtractMode.map(item => {
                                                                if (item.id === Udesk.enums.dataExtractMode.custom.id) {
                                                                    return (
                                                                        <Radio value={item.id}>
                                                                            {item.name}{locales.components.pages.trainingCenter.classifiedTraining.mark}
                                                                            {
                                                                                RadiomarkedDataCorrigendaValue === Udesk.enums.dataExtractModeTypeOne.custom.id && (
                                                                                    <Popover content={this.actions.queckListMang(markcondtionJson, markjudgeStrategy, markcustomJudgeLogic)} placement='bottomRight'>
                                                                                        <Button style={{ marginLeft: '5px' }} onClick={actions.changeFilterVisible.params('markedDataCorrigendaValue')}>{locales.components.pages.trainingCenter.classifiedTraining.custom}</Button>
                                                                                    </Popover>
                                                                                )
                                                                            }
                                                                        </Radio>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <Radio value={item.id}>{item.name}{locales.components.pages.trainingCenter.classifiedTraining.mark}</Radio>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                        {
                                                            RadiomarkedDataCorrigendaValue === Udesk.enums.dataExtractMode.specifiedClass.id && (
                                                                <div style={{ width: '200px', display: 'inline-block' }}>
                                                                    <AutoComplete value={markedDataCorrigendaValue} loadOptions={actions.getCateaoryId} onChanged={actions.markedDataCorrigendaChange} enableEmptySearch={true} />
                                                                </div>
                                                            )
                                                        }
                                                    </Radio.Group>
                                                </div>
                                                <div className='classified-tarining-pager-body-action-card-button'>
                                                    <Button type='primary' style={{ marginRight: '10px' }} onClick={actions.startTrain.params(Udesk.enums.trainTypeList.markedDataCorrigenda.id, RadiomarkedDataCorrigendaValue)}>{locales.components.pages.trainingCenter.classifiedTraining.start}{locales.components.pages.trainingCenter.classifiedTraining.mark}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{ height: '190px' }}>
                                        <div className='classified-tarining-pager-body-action-card'>
                                            <div className='classified-tarining-pager-body-action-card-header'>
                                                <div className='classified-tarining-pager-body-action-card-header-title'>{locales.components.pages.trainingCenter.classifiedTraining.dataCheck} {!_isEmpty(classifiedDataCheck) && <span> {Udesk.utils.string.format(locales.components.pages.trainingCenter.classifiedTraining.noDataDays, classifiedDataCheck.lastTimeDays)}</span>}</div>
                                                {
                                                    !_isEmpty(classifiedDataCheck) && classifiedDataCheck.signType && (
                                                        <div className='classified-tarining-pager-body-action-card-header-type'>
                                                            {
                                                                classifiedDataCheck.signType === 1 && <span> {locales.components.pages.trainingCenter.classifiedTraining.dataCheckSmall} </span>
                                                            }
                                                            {
                                                                classifiedDataCheck.signType === 2 && <span>{locales.components.pages.trainingCenter.classifiedTraining.dataCheckHeight} </span>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='classified-tarining-pager-body-action-card-info'>
                                                <div>
                                                    <Radio.Group value={RadioclassifiedDataCheckValue} onChange={actions.RadioclassifiedDataCheckChange}>
                                                        {
                                                            Udesk.enums.dataExtractMode.map(item => {
                                                                if (item.id === Udesk.enums.dataExtractMode.custom.id) {
                                                                    return (
                                                                        <Radio value={item.id}>
                                                                            {item.name}{locales.components.pages.trainingCenter.classifiedTraining.check}
                                                                            {
                                                                                RadioclassifiedDataCheckValue === Udesk.enums.dataExtractModeTypeOne.custom.id && (
                                                                                    <Popover content={this.actions.queckListMang(classcondtionJson, classjudgeStrategy, classcustomJudgeLogic)} placement='bottomRight'>
                                                                                        <Button style={{ marginLeft: '5px' }} onClick={actions.changeFilterVisible.params('classifiedDataCheck')}>{locales.fix.advancedScreening}</Button>
                                                                                    </Popover>
                                                                                )
                                                                            }
                                                                        </Radio>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <Radio value={item.id}>{item.name}{locales.components.pages.trainingCenter.classifiedTraining.check}</Radio>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                        {
                                                            RadioclassifiedDataCheckValue === Udesk.enums.dataExtractMode.specifiedClass.id && (
                                                                <div style={{ width: '200px', display: 'inline-block' }}>
                                                                    <AutoComplete value={classifiedDataCheckValue} loadOptions={actions.getCateaoryId} onChanged={actions.classifiedDataCheckChange} enableEmptySearch={true} />
                                                                </div>
                                                            )
                                                        }
                                                    </Radio.Group>
                                                </div>
                                                <div className='classified-tarining-pager-body-action-card-button'>
                                                    <Button type='primary' style={{ marginRight: '10px' }} onClick={actions.startTrain.params(Udesk.enums.trainTypeList.classifiedDataCheck.id, RadioclassifiedDataCheckValue)} >{locales.components.pages.trainingCenter.classifiedTraining.start}{locales.components.pages.trainingCenter.classifiedTraining.check}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{ height: '190px' }}>
                                        <div className='classified-tarining-pager-body-action-card'>
                                            <div className='classified-tarining-pager-body-action-card-header'>
                                                <div className='classified-tarining-pager-body-action-card-header-title'>{locales.components.pages.trainingCenter.classifiedTraining.history}</div>
                                                <div className='classified-tarining-pager-body-action-card-header-type'>{locales.components.pages.trainingCenter.classifiedTraining.historyTime}</div>
                                            </div>
                                            <div className='classified-tarining-pager-body-action-card-info'>
                                                <div className='classified-tarining-pager-body-action-card-info-list'>
                                                    <div className='classified-tarining-pager-body-action-card-info-list-field'>
                                                        <div>{locales.components.pages.trainingCenter.classifiedTraining.today}</div>
                                                        <div className='classified-tarining-pager-body-action-card-info-list-num'>
                                                            {
                                                                (!_isEmpty(markedHistoryRecord) && (<span>{markedHistoryRecord.todayMarkCount}{locales.components.pages.trainingCenter.classifiedTraining.article}</span>)) || <span>{locales.components.pages.trainingCenter.classifiedTraining.none}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='classified-tarining-pager-body-action-card-info-list-field'>
                                                        <div>{locales.components.pages.trainingCenter.classifiedTraining.historyCheck}</div>
                                                        <div className='classified-tarining-pager-body-action-card-info-list-num'>
                                                            {
                                                                (!_isEmpty(markedHistoryRecord) && (<span>{markedHistoryRecord.totalMarkCount}{locales.components.pages.trainingCenter.classifiedTraining.article}</span>)) || <span>{locales.components.pages.trainingCenter.classifiedTraining.none}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='classified-tarining-pager-body-action-card-button'>
                                                    <Button onClick={actions.historyTo}>{locales.components.pages.trainingCenter.classifiedTraining.look}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{ height: '190px' }}>
                                        <div className='classified-tarining-pager-body-action-card'>
                                            <div className='classified-tarining-pager-body-action-card-header'>
                                                <div className='classified-tarining-pager-body-action-card-header-title'>{locales.components.pages.trainingCenter.classifiedTraining.historyluate} {!_isEmpty(historyDataRecalculate) && <span>{Udesk.utils.string.format(locales.components.pages.trainingCenter.classifiedTraining.noDataDays, historyDataRecalculate.lastTimeDays)}</span>}</div>
                                            </div>
                                            <div className='classified-tarining-pager-body-action-card-info'>
                                                <div className='classified-tarining-pager-body-action-card-button'>
                                                    <Button type='primary' style={{ marginRight: '10px' }} onClick={actions.callModalChange}>{locales.components.pages.trainingCenter.classifiedTraining.startluate}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save} visible={selectCallTimeVisible} title={locales.labels.redo} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={actions.redo.params(false)} onOk={actions.redo.params(true)} headerClassName="udesk-qa-modal-default-header" footerClassName="udesk-qa-modal-default-footer">
                            <SelectCallTimer showTime={true} onDateChanged={actions.onDateChanged} recordCount={redoCallIds.length} ></SelectCallTimer>
                            <div >
                                <span style={{ marginRight: '10px' }}>{locales.components.pages.trainingCenter.classifiedTraining.classData}</span>
                                <div style={{ width: "200px", display: "inline-block" }}>
                                    <AutoComplete isMulti={true} value={this.privates.userValue} loadOptions={actions.loadUserOptions} onChanged={actions.onUserChanged} enableEmptySearch={true} />
                                </div>
                            </div>
                        </ReactModal>

                        <CustomFilterModal
                            visible={filterVisible}
                            cancel={actions.handleConditionCancel}
                            applyAction={actions.handleConditionApply}
                            judgeStrategy={judgeStrategy}
                            customJudgeLogic={customJudgeLogic}
                            customName={customName}
                            customId={customId}
                            conditionList={conditionList}
                            type={this.privates.dataSourceType}
                            filterList={['f_8', 'r_1', 'r_2', 'r_3']}
                        />
                    </div>
                </div>


            </div >
        );
    }
}
function disabledDate(current) {
    return current && current < moment().subtract(1, "years");
}