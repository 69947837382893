// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListSuperVisionRuleResponse } from '../../types';

/**
 * findWithDetail
 *
 * @export
 * @tags supervision-rule-config-controller
 * @link [GET] /supervisionRuleConfigs/findWithDetail
 */
export function getSupervisionRuleConfigsFindWithDetail(): Promise<BaseResponseListSuperVisionRuleResponse>;
export function getSupervisionRuleConfigsFindWithDetail(
    options: ApiOptions<never, never>
): Promise<BaseResponseListSuperVisionRuleResponse>;
export function getSupervisionRuleConfigsFindWithDetail(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListSuperVisionRuleResponse> {
    return request<never, never, never>('/supervisionRuleConfigs/findWithDetail', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSupervisionRuleConfigsFindWithDetail',
    });
}

export const meta = [
    {
        tags: ['supervision-rule-config-controller'],
        path: '/supervisionRuleConfigs/findWithDetail',
        method: 'get',
    },
];
