// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PutReviewIntelligentPartnerNodeRobotConfigByIdParam,
    PutReviewIntelligentPartnerNodeRobotConfigByIdBody,
    BaseResponseIntelligentPartnerDialogNodeRobotConfigFoundResponse,
} from '../../../../../types';

/**
 * 修改节点配置
 *
 * @export
 * @tags 智能陪练-机器人对话节点配置
 * @link [PUT] /review/intelligentPartner/node/robotConfig/{id}
 * @param id
 */
export function putReviewIntelligentPartnerNodeRobotConfigById(
    data: PutReviewIntelligentPartnerNodeRobotConfigByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerNodeRobotConfigByIdParam, never> & {
        segments: PutReviewIntelligentPartnerNodeRobotConfigByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeRobotConfigFoundResponse> {
    return request<
        PutReviewIntelligentPartnerNodeRobotConfigByIdBody,
        PutReviewIntelligentPartnerNodeRobotConfigByIdParam,
        never
    >('/review/intelligentPartner/node/robotConfig/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerNodeRobotConfigById',
    });
}

export const meta = [
    {
        tags: ['智能陪练-机器人对话节点配置'],
        path: '/review/intelligentPartner/node/robotConfig/{id}',
        method: 'put',
    },
];
