import React, { useState, useCallback, useImperativeHandle } from 'react';
import { Drawer } from 'udesk-ui';
import { useLiveEffect } from 'src/util/hook';
import { inspect } from 'src/util/core';
import LeftTable from './leftTable';
import RightForm from './rightForm';
import './index.scss'; 

export default React.forwardRef((props: any, ref) => {
    const {scenario, groupDataSource, request, locales, SmartToolBarRef} = props;
    const [selectedRowKey, setSelectedRowKey] = useState(props.selectedRowKey);
    const [visible, setVisible] = useState(false);
    const [width, setWidth] = useState(800);
    const [zIndex, setZIndex] = useState(1031);

    const closeSmartToolBarCallback = useCallback(() => {
        setWidth(800);
        setZIndex(1031);
    }, []);

    const openSmartToolBarCallback = useCallback(() => {
        setWidth(1168);
        setZIndex(1000);
    }, []);

    const open = useCallback(() => {
        setVisible(true);
    }, []);

    const closeHandle = useCallback(() => {
        if (SmartToolBarRef.current) {
            SmartToolBarRef.current.actions.onClose(true);
        }
        closeSmartToolBarCallback();
        setVisible(false);
    }, [
        SmartToolBarRef, 
        closeSmartToolBarCallback
    ]);

    useImperativeHandle(ref, () => ({
        open
    }));

    useLiveEffect(() => {
        setSelectedRowKey(props.selectedRowKey);
    }, [
        props.selectedRowKey
    ]);

    useLiveEffect(() => {
        inspect(SmartToolBarRef?.current, () => {
            closeSmartToolBarCallback();
            SmartToolBarRef?.current.actions.onClose(true);
        });        
    }, [
        selectedRowKey 
    ]);

    return (
        <Drawer
            title={/* 话术推荐设置 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.configDrawer.index.scriptRecommendationSettings}
            placement='right'
            closable={false}
            onClose={closeHandle}
            width={width}
            visible={visible}
            zIndex={zIndex}
            contentWrapperStyle={{
                transition: 'width 0.6s ease'
            }}
            bodyStyle={{
                padding: 0
            }}
        >
            <div className='favorite-config-table beautiful-scrollbar dissent'>
                <LeftTable {
                    ...{
                        data: groupDataSource,
                        selectedRowKey, 
                        setSelectedRowKey,
                    }
                }/>
                <RightForm {
                    ...{
                        request,
                        locales, 
                        SmartToolBarRef,
                        visible,
                        closeHandle,
                        selectedRowKey, 
                        scenario,
                        openSmartToolBarCallback,
                        closeSmartToolBarCallback
                    }
                }/>
            </div>         
        </Drawer>
    );
});
