import upload from "./upload";
import adapters from "./adapters";
import credentials from "./credentials";

upload.defaults.uploadAdapter = adapters.oss;

export default {
    upload,
    adapters,
    credentials
};