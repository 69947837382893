import React, { useEffect, useRef, useState } from 'react';
// import Locales from 'UdeskLocales';
import { Icon, Space, TreeSelect } from 'udesk-ui';
import { getKnowledgeCategorysInitTree } from 'src/api/knowledgeCategorys/initTree';
import { postKnowledgeDatasFindByKeywordWithoutPage } from 'src/api/knowledgeDatas/findByKeywordWithoutPage';
import styled from 'styled-components';

const Div = styled.div`
    .udesk-qa-ui-select-tree-indent-unit {
        width: 8px;
    }
`;
interface KnowledgeTreeSelectProps {
    onChange: (value: any) => void;
    value: Array<number>;
    knowledgeCategoryIdsList: Array<number>;
}

export const KnowledgeTreeSelect: React.FC<KnowledgeTreeSelectProps> = (props) => {
    // const locales = Locales['current'];
    const { onChange: propsOnChange, value } = props;
    const [treeData, setTreeData] = useState<any[]>([]);
    // const [loading, setLoading] = useState<boolean>(true);
    const wrapDom = useRef(null);

    const requestKnowledgeData = (knowledgeCategoryId) => {
        return postKnowledgeDatasFindByKeywordWithoutPage({
            categoryId: knowledgeCategoryId,
        });
    };
    useEffect(() => {
        getKnowledgeCategorysInitTree()
            .then((resp) => {
                const { data = [] } = resp;
                const stack: any[] = [...data];
                // const categoryMapTemp = {};
                stack.forEach((i) => {
                    i.pId = 0;
                });
                const firstLevelIds = stack.map((i) => i.id);
                const initTreeData: any[] = [];
                while (stack.length) {
                    const current: any = stack.pop();
                    const res = { ...current };
                    res.children = undefined;
                    const categoryMapKey = getCategoryMapKey(current.id);
                    res.id = categoryMapKey;
                    if (current?.children && Array.isArray(current?.children)) {
                        current.children.forEach((itm) => {
                            itm.pId = categoryMapKey;
                        });
                        Array.prototype.push.apply(stack, current.children);
                    }
                    res.title = (
                        <Space>
                            <Icon
                                type="FolderFilled"
                                antdIcon={true}
                                style={{ color: '#1b6dff' }}
                            />
                            <div>{current?.name}</div>
                        </Space>
                    );
                    res.value = categoryMapKey;
                    res.id = categoryMapKey;
                    // categoryMapTemp[categoryMapKey] = res;
                    initTreeData.push(res);
                }
                // categoryMap.current = categoryMapTemp;

                // setTreeData(initTreeData);
                return { firstLevelIds, initTreeData };
            })
            .then(({ firstLevelIds, initTreeData }) => {
                const requestList =
                    firstLevelIds?.map((knowledgeCategoryId) => {
                        return requestKnowledgeData(knowledgeCategoryId).then((resp) => {
                            return resp.data?.filter(i => i.status).map((i) => {
                                return {
                                    ...i,
                                    title: i.standardQuestionText,
                                    value: i.id,
                                    pId: getCategoryMapKey(i.categoryId),
                                    isLeaf: true,
                                };
                            });
                        });
                    }) ?? [];
                if (!requestList.length) return;
                Promise.all(requestList).then((...knowledgeDataListList) => {
                    const allKnowledgeData = knowledgeDataListList.flat(3);

                    setTreeData(deduplicate(initTreeData.concat(allKnowledgeData)));
                });
            })
            .finally(() => {
                // setTimeout(() => setLoading(false), 0);
                // requestAnimationFrame(() => {
                //     setLoading(false);
                // });
            });
    }, []);

    const triggerPnChange = (value, label, extra) => {
        propsOnChange?.(value.filter((i) => typeof i === 'number'));
    };
    return (
        <Div ref={wrapDom}>
            <TreeSelect
                treeDataSimpleMode={{ rootPId: 0 }}
                value={!treeData.length ? undefined : value}
                treeData={treeData}
                treeCheckable={true}
                // loadData={onLoadData}
                onChange={triggerPnChange}
                getPopupContainer={() => wrapDom.current!}
                maxTagCount="responsive"
                allowClear
            ></TreeSelect>
        </Div>
    );
};

function getCategoryMapKey(categoryId) {
    return `category-${categoryId}`;
}
// function getCategoryId(categoryMapKey) {
//     return categoryMapKey.replace(`category-`, '') * 1;
// }
function deduplicate(arr, uniId = 'id') {
    const res = new Map();
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
}
