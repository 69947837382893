import React from 'react';
import ClassNames from 'classnames';

import Modal from 'antd/lib/modal';
// import 'antd/lib/modal/style/index.css';
import '../styles/react-alerts.css';

import {confirmsDefaults} from './widgets/confirms';

export default {
    confirm,
    success,
    warning,
    info,
    error,
};

function confirm(options) {
    return alert(options, "confirm");
}
function success(options) {
    return alert(options, "success");
}
function warning(options) {
    return alert(options, "warning");
}
function info(options) {
    return alert(options, "info");
}
function error(options) {
    return alert(options, "error");
}

function alert(options, type) {
    let  confirmDefaults=confirmsDefaults();
    let newOptions = Object.assign({},confirmDefaults[type], options);

    let modal = {};
    let {
        onCancel,
        onOk
    } = newOptions;

    newOptions.onCancel = function (e) {
        if (typeof onCancel === "function") {
            onCancel.apply(undefined, arguments);
        }
        modal.destroy();
    };
    newOptions.onOk = function (e) {
        if (typeof onOk === "function") {
            onOk.apply(undefined, arguments);
        }
        modal.destroy();
    };

    defaultHeader(newOptions);
    defaultContent(newOptions);
    newOptions.footer = null;
    modal = Modal[type](newOptions);
    return modal;
}

function defaultHeader(options) {
    let {
        title,
        closable,
        titleTipClass,
        closeIconClass,
        onCancel,
    } = options;

    if (typeof title === "string") {
        options.title = (
            <div className={ClassNames("react-alerts-header")} key="react-alert-header-0">
                <If condition={titleTipClass}>
                    <i className={titleTipClass}></i>
                </If>
                {title}
                <If condition={closable}>
                    <i className={ClassNames({ [`${closeIconClass}`]: closeIconClass }, "close-modal")} onClick={(e) => onCancel(e)}></i>
                </If>
            </div>
        );
        options.closeable = false;
    }
}
function defaultContent(options) {
    let {
        content
    } = options;

    if (typeof content === "string") {
        let footer=defaultFooter(options);
        options.content = (
            <If condition={content}>
                <div className={ClassNames("react-alerts-content")} dangerouslySetInnerHTML={{ __html: content }} key="react-alert-content-1" >
                    
                </div>
                <If condition={footer}>
                    {footer}
                </If>
            </If>
        );
    }
}
function defaultFooter(options) {
    let {
        onCancel,
        onOk,
        okText,
        cancelText,
        cancelClass,
        okClass,
        footer,
    } = options;

    if (footer === undefined) {
        return (
            <div className={ClassNames("react-alerts-footer")} key="react-alert-footer-2">
                <button type="button" className={ClassNames("react-alerts-footer-btn react-alerts-footer-btn-cancel", { [`${cancelClass}`]: cancelClass })} onClick={(e) => onCancel(e)} key="react-cancel-btn">
                    {cancelText}
                </button>
                <button type="button" className={ClassNames("react-alerts-footer-btn react-alerts-footer-btn-ok", { [`${okClass}`]: okClass })} onClick={(e) => onOk(e)} key="react-ok-btn">
                    {okText}
                </button>
            </div>
        );
    }
    return footer;
}
