// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostVirtualLoginBody, BaseResponseVoid } from '../../types';

/**
 * virtualLogin
 *
 * @export
 * @tags 登录
 * @link [POST] /virtual/login
 */
export function postVirtualLogin(data: PostVirtualLoginBody): Promise<BaseResponseVoid>;
export function postVirtualLogin(
    data: PostVirtualLoginBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postVirtualLogin(
    data: PostVirtualLoginBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostVirtualLoginBody, never, never>('/virtual/login', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postVirtualLogin',
    });
}

export const meta = [{ tags: ['登录'], path: '/virtual/login', method: 'post' }];
