import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Udesk from '../../udesk/index';

class ReactTreeNodeActions extends React.Component {
    //#region defaultProps
    static defaultProps = {
        onTreeAction: null,
        sort: null,
        enableAdding: true,
        enableSorting: false,
        draggable: false,
        sortIconClass: null,
        sortText: "",
        confirmSortText: "",
        confirmSortIconClass: null,
        runtimeAttrs: null,
        treeAddIconClass: "fa fa-plus-circle",
    }
    static propTypes = {
        onTreeAction: PropTypes.func,
        sort: PropTypes.func,
        enableAdding: PropTypes.bool,
        enableSorting: PropTypes.bool,
        draggable: PropTypes.bool,
        sortIconClass: PropTypes.string,
        sortText: PropTypes.string,
        confirmSortText: PropTypes.string,
        confirmSortIconClass: PropTypes.string,
        treeAddIconClass: PropTypes.string,
        runtimeAttrs: PropTypes.object,
    }
    //#endregion

    privates = {
        sortText: "",
        confirmSortText: "",
    }

    init() {
        let {
            locales
        } = this;
        this.privates.sortText = this.props.sortText || locales.components.emberTreeAction.sortText;
        this.privates.confirmSortText = this.props.confirmSortText || locales.components.emberTreeAction.applyLabel;
    }
    parseProps({ props, prevProps }) {
        if (props.sortText !== prevProps.sortText) {
            this.privates.sortText = locales.components.emberTreeAction.sortText;
        }
        if (props.confirmSortText !== prevProps.confirmSortText) {
            this.privates.confirmSortText = locales.components.emberTreeAction.applyLabel;
        }
        this.actions.update();
    }

    actions = {
        add(parentNode, args) {
            this.trigger("onNodeAction", "add", parentNode, args);
        },
        edit(node, args) {
            this.trigger("onNodeAction", "edit", node, args);
        },
        delete(node, args) {
            this.trigger("onNodeAction", "delete", node, args);
        }
    }
}

export default ReactTreeNodeActions;