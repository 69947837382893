// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetInspectionTasksSimpleQuery,
    BaseResponseListInspectionTaskSimpleInfoFoundResponse,
} from '../../types';

/**
 * 质检任务列表(简略)
 *
 * @export
 * @tags 质检任务
 * @link [GET] /inspectionTasks/simple
 */
export function getInspectionTasksSimple(
    options: ApiOptions<never, GetInspectionTasksSimpleQuery> & {
        params: GetInspectionTasksSimpleQuery;
    }
): Promise<BaseResponseListInspectionTaskSimpleInfoFoundResponse> {
    return request<never, never, GetInspectionTasksSimpleQuery>('/inspectionTasks/simple', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getInspectionTasksSimple',
    });
}

export const meta = [{ tags: ['质检任务'], path: '/inspectionTasks/simple', method: 'get' }];
