// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetRoleDatasByRoleIdParam,
    BaseResponseRoleDataFoundResponse,
    PutRoleDatasByRoleIdParam,
    PutRoleDatasByRoleIdBody,
    BaseResponseVoid,
} from '../../types';

/**
 * findByRoleId
 *
 * @export
 * @tags 角色数据权限
 * @link [GET] /roleDatas/{roleId}
 * @param roleId
 */
export function getRoleDatasByRoleId(
    options: ApiOptions<GetRoleDatasByRoleIdParam, never> & { segments: GetRoleDatasByRoleIdParam }
): Promise<BaseResponseRoleDataFoundResponse> {
    return request<never, GetRoleDatasByRoleIdParam, never>('/roleDatas/{roleId}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getRoleDatasByRoleId',
    });
}

/**
 * update
 *
 * @export
 * @tags 角色数据权限
 * @link [PUT] /roleDatas/{roleId}
 * @param roleId
 */
export function putRoleDatasByRoleId(
    data: PutRoleDatasByRoleIdBody,
    options: ApiOptions<PutRoleDatasByRoleIdParam, never> & { segments: PutRoleDatasByRoleIdParam }
): Promise<BaseResponseVoid> {
    return request<PutRoleDatasByRoleIdBody, PutRoleDatasByRoleIdParam, never>(
        '/roleDatas/{roleId}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putRoleDatasByRoleId' }
    );
}

export const meta = [
    { tags: ['角色数据权限'], path: '/roleDatas/{roleId}', method: 'get' },
    { tags: ['角色数据权限'], path: '/roleDatas/{roleId}', method: 'put' },
];
