import React, { FC, memo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
`;

type TemplateProps = {};

const Template: FC<TemplateProps> = memo((props) => {
    return <Wrapper title={props.children}>{props.children}</Wrapper>;
});

export default Template;
