import React from 'react';
import UdeskLocales from 'UdeskLocales';

class TrainingHistoryIndexComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        startTime: moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00',
        endTime: moment().format('YYYY-MM-DD') + ' 23:59:59',
        columns: [
            {
                title: UdeskLocales.current.components.pages.trainingCenter.trainingHistory.table.time,
                dataIndex: 'time',
                key: 'time',
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.trainingHistory.table.user,
                dataIndex: 'user',
                key: 'user',
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.trainingHistory.table.tag,
                dataIndex: 'tag',
                key: 'tag',
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.trainingHistory.table.similarCount,
                dataIndex: 'similarCount',
                key: 'similarCount',
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.trainingHistory.table.deleteCount,
                dataIndex: 'deleteCount',
                key: 'deleteCount',
            },
        ],
        data: [],
        paging: {
            pageNum: 1,
            pageSize: 10,
            total: 0
        }
    };

    actions = {
        selectTime(dateString) {
            this.privates.startTime = dateString[0];
            this.privates.endTime = dateString[1];
            this.actions.update();
        },
        search(){
            this.actions.reloadAsyncModel();
        },
        reset(){
            this.privates.startTime = moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00';
            this.privates.endTime = moment().format('YYYY-MM-DD') + ' 23:59:59';
            this.actions.reloadAsyncModel();
        },
        pageNumChange(pageNumber) {
            if (pageNumber !== this.privates.paging.pageNum) {
                this.privates.paging.pageNum = pageNumber;
                this.actions.reloadAsyncModel();
                this.actions.update();
            }
        },
        pageSizeChange(current, pageSize) {
            this.privates.paging.pageNum = 1;
            this.privates.paging.pageSize = pageSize;
            this.actions.reloadAsyncModel();
            this.actions.update();
        },

    };
    //#region Life Cycle
    componentDidMount() {
    }
    componentWillUnmount() {}
    //#endregion
    
}

export default TrainingHistoryIndexComponent;
