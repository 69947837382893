/*
 * @Author: 唐毓斌
 * @Description: 简单饼图
 */

import React, { useEffect, useRef } from 'react';
import Echarts from 'echarts';
import { Spin } from 'udesk-ui';
// import UdeskLocales from 'UdeskLocales';

interface SimplePieChartProps {
    id: string;
    maxValue: number|string;
    // width?: number | string;
    loading?: boolean;
    height: number | string;
    title: string;
    chartTitle: string;
    subTitle: string;
    data: { value: number };
}

const SimplePieChart: React.FC<SimplePieChartProps> = (props) => {
    const { id, height, title,maxValue, subTitle, data, loading, chartTitle } = props;
    const currentChartRef = useRef<any>(null);

    useEffect(() => {
        if (!currentChartRef.current) {
            currentChartRef.current = Echarts.init(document.getElementById(id));
        }
        if (currentChartRef.current) {
            const option = {
                title: {
                  text: chartTitle,
                  left: 'left',
                  top: 10,
                  textStyle: {
                    lineHeight: 10,
                    fontSize: 18
                  }
                },
                graphic: {
                  type: 'text',
                  style: {
                      text: chartTitle,
                      font: '22px Microsoft YaHei',
                      fill: '#333',
                      x: 10,
                      y: 0
                  }
              },
                series: [
                  {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    center: ['50%', '75%'],
                    radius: '90%',
                    min: 0,
                    max: maxValue || 100,
                    splitNumber: 10,
                    axisLine: {
                      lineStyle: {
                        width: 6,
                        color: [
                          [0.25, '#FF6E76'],
                          [0.5, '#FDDD60'],
                          [0.75, '#58D9F9'],
                          [1, '#7CFFB2']
                        ]
                      }
                    },
                    // pointer: {
                    //   icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                    //   length: '36%',
                    //   width: 20,
                    //   offsetCenter: [0, '-100%'],
                    //   itemStyle: {
                    //     color: 'auto'
                    //   }
                    // },
                    axisTick: {
                      length: 12,
                      lineStyle: {
                        color: 'auto',
                        width: 2
                      }
                    },
                    splitLine: {
                      length: 20,
                      lineStyle: {
                        color: 'auto',
                        width: 5
                      }
                    },
                    axisLabel: {
                      color: '#464646',
                      fontSize: 20,
                      distance: -55,
                    //   rotate: 'tangential',
                    //   formatter: function (value) {
                    //     if (value === 0.875) {
                    //       return 'Grade A';
                    //     } else if (value === 0.625) {
                    //       return 'Grade B';
                    //     } else if (value === 0.375) {
                    //       return 'Grade C';
                    //     } else if (value === 0.125) {
                    //       return 'Grade D';
                    //     }
                    //     return '';
                    //   }
                    },
                    title: {
                      offsetCenter: [0, '-10%'],
                      fontSize: 20
                    },
                    detail: {
                      fontSize: 70,
                      offsetCenter: [0, 0],
                      valueAnimation: true,
                    //   formatter: function (value) {
                    //     return Math.round(value * 100) + '';
                    //   },
                      color: 'inherit'
                    },
                    data: [
                      {
                        value: data.value,
                        name: data.value
                      }
                    ]
                  }
                ]
              };
            currentChartRef.current.setOption(option);
        }
    }, [data, id, subTitle, title]);

    const chartResize = () => {
        currentChartRef.current && currentChartRef.current.resize();
    };

    useEffect(() => {
        window.addEventListener('resize', chartResize);

        return () => {
            window.removeEventListener('resize', chartResize);
        };
    }, []);

    return (
        <Spin spinning={loading}>
            <div
                id={id}
                style={{
                    // width: width,
                    height: height,
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '3px',
                }}
            />
        </Spin>
    );
};

export default SimplePieChart;
