import Udesk from 'Udesk';
import React from 'react';
import CheckPointOperatorListGatherComponent from './component';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import { Select, Input, Button, TreeSelect, Radio, InputNumber, Popover, Space } from 'udesk-ui';
import Associate from './Associate';
import BusinessSummary from './BusinessSummary';
import './style.scss';

const TimeIntervalComponent = (props) => {
    const [showReSelect, setShowReSelect] = React.useState(false);
    const { operatorItem, locales, actions } = props;
    let semanticTag = operatorItem.operatorList[0][props.semanticKey];
    let content = (
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 4 }}>
            <span
                title={semanticTag && semanticTag.semanticName}
                className="operator-list-item-semantic-tags-result-tag"
            >
                {locales.components.operatorListGather.semanticTags}
                {locales.labels.colon}
                {semanticTag && semanticTag.semanticName}
            </span>
            <span
                title={semanticTag && semanticTag.sentence}
                className="operator-list-item-semantic-tags-result-sentence"
            >
                {locales.components.operatorListGather.standardSentence}
                {locales.labels.colon}
                {semanticTag && semanticTag.sentence}
            </span>
        </div>
    );
    const onMouseEnter = (e) => {
        setShowReSelect(true);
    };
    const onMouseLeave = (e) => {
        setShowReSelect(false);
    };
    return (
        <React.Fragment>
            {semanticTag && semanticTag.semanticName ? (
                <Popover content={content} title={semanticTag.semanticName}>
                    <Button
                        disabled={props.disabled}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        className="operator-list-item-button"
                        style={{ marginLeft: 4 }}
                        onClick={actions.changeTimeIntervalSemanticTagsModalVisible.params(
                            operatorItem,
                            props.semanticKey
                        )}
                    >
                        <div style={{ width: 112, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {showReSelect
                                ? locales.components.operatorListGather.reQuerySemanticTags
                                : semanticTag.semanticName}
                        </div>
                    </Button>
                </Popover>
            ) : (
                <Button
                    disabled={props.disabled}
                    className="operator-list-item-button"
                    style={{ marginLeft: 4 }}
                    onClick={actions.changeTimeIntervalSemanticTagsModalVisible.params(
                        operatorItem,
                        props.semanticKey
                    )}
                >
                    <div style={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {locales.components.operatorListGather.querySemanticTags}
                    </div>
                </Button>
            )}
        </React.Fragment>
    );
};
export { NewAssociateComponent } from './Associate/normal';
export default class CheckPointOperatorListGatherTemplate extends CheckPointOperatorListGatherComponent {
    render() {
        let { props, actions, locales, privates } = this;
        let { operatorItem, testResult, regularText } = privates;

        console.log('props', props);

        return (
            <div className="check-point-operator-list-gather-component" id={props.key}>
                <div>
                    <Select
                        // getPopupContainer={() => document.getElementById(props.key)}
                        disabled={props.disabled}
                        value={
                            props.conditionItem.operatorList[0].type
                                ? props.conditionItem.operatorList[0].type
                                : props.operatorTypes[0]
                                ? props.operatorTypes[0].id
                                : ''
                        }
                        className="operator-list-item-type"
                        onChange={actions.changeOperatorItemType.params(privates.operatorItem)}
                    >
                        {props.funcType === 'REVIEW_CALL' ||
                        props.funcType === 'REVIEW_KEY_EVENT' ? (
                            <For each="item" index="index" of={props.operatorTypes}>
                                <If
                                    condition={
                                        item.id !==
                                            Udesk.enums.operatorTypes.questionsAndAnswers.id &&
                                        item.id !== Udesk.enums.operatorTypes.knowledge.id
                                    }
                                >
                                    <Select.Option
                                        key={item.id}
                                        value={item.id}
                                        title={item.name}
                                        disabled={item.disabled}
                                    >
                                        {item.name}
                                    </Select.Option>
                                </If>
                            </For>
                        ) : (
                            <For each="item" index="index" of={props.operatorTypes}>
                                <Select.Option
                                    key={item.id}
                                    value={item.id}
                                    title={item.name}
                                    disabled={item.disabled}
                                >
                                    {item.name}
                                </Select.Option>
                            </For>
                        )}
                    </Select>
                </div>
                {/* 10、语义标签 27、客户企微备注 */}
                <If
                    condition={[
                        Udesk.enums.operatorTypes.semanticTags.id,
                        Udesk.enums.operatorTypes.wecomRemark.id,
                    ].includes(operatorItem.operatorType)}
                >
                    <div
                        className="operator-list-item-semantic-tags-container"
                        style={{ marginRight: 16 }}
                    >
                        <div className="operator-list-item-semantic-tags-search-container">
                            {/* <Input
                                // type="text"
                                disabled={props.disabled}
                                className="operator-list-item-semantic-tags-sentence"
                                value={operatorItem.operatorList[0].semanticSentence}
                                onChange={actions.changeInputValue.params(operatorItem, "semanticSentence")}
                                placeholder={locales.components.operatorListGather.pleaseEnterSemanticSentence}
                            /> */}
                            <Button
                                // type="button"
                                disabled={props.disabled}
                                className="operator-list-item-button"
                                onClick={actions.changeSemanticTagsModalVisible.params(
                                    operatorItem
                                )}
                            >
                                {operatorItem.operatorList[0].semanticTag &&
                                operatorItem.operatorList[0].semanticTag.semanticName
                                    ? locales.components.operatorListGather.reQuerySemanticTags
                                    : locales.components.operatorListGather.querySemanticTags}
                            </Button>
                        </div>
                    </div>
                    <div className="operator-list-item-semantic-tags-result-container">
                        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 4 }}>
                            <span
                                title={
                                    operatorItem.operatorList[0].semanticTag &&
                                    operatorItem.operatorList[0].semanticTag.semanticName
                                }
                                className="operator-list-item-semantic-tags-result-tag"
                            >
                                {locales.components.operatorListGather.semanticTags}
                                {locales.labels.colon}
                                {operatorItem.operatorList[0].semanticTag &&
                                    operatorItem.operatorList[0].semanticTag.semanticName}
                            </span>
                            <span
                                title={
                                    operatorItem.operatorList[0].semanticTag &&
                                    operatorItem.operatorList[0].semanticTag.sentence
                                }
                                className="operator-list-item-semantic-tags-result-sentence"
                            >
                                {locales.components.operatorListGather.standardSentence}
                                {locales.labels.colon}
                                {operatorItem.operatorList[0].semanticTag &&
                                    operatorItem.operatorList[0].semanticTag.sentence}
                            </span>
                        </div>
                    </div>
                </If>
                {/* 23、间隔时长 */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.timeInterval.id
                    }
                >
                    <div className="operator-list-item-semantic-tags-container">
                        <div
                            className="operator-list-item-semantic-tags-search-container"
                            style={{ display: 'flex', alignItems: 'center', width: 'unset' }}
                        >
                            <TimeIntervalComponent
                                locales={locales}
                                disabled={props.disabled}
                                actions={actions}
                                operatorItem={operatorItem}
                                semanticKey="semanticTagStart"
                            />
                            <div style={{ marginLeft: 4 }}>{locales.labels.and}</div>
                            <TimeIntervalComponent
                                locales={locales}
                                disabled={props.disabled}
                                actions={actions}
                                operatorItem={operatorItem}
                                semanticKey="semanticTagEnd"
                            />
                            <div style={{ marginLeft: 4 }}>{locales.labels.interval}</div>
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.operatorList[0].param.compareType}
                                className="operator-list-item-operator"
                                style={{ width: 98, marginLeft: 4 }}
                                onChange={actions.changeOperatorItem.params(
                                    operatorItem,
                                    'compareType'
                                )}
                            >
                                <For
                                    each="item"
                                    index="index"
                                    of={Udesk.enums.timeIntervalCompareTypes}
                                >
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                </For>
                            </Select>
                            <InputNumber
                                disabled={props.disabled}
                                min={1}
                                className="operator-list-item-word-per-minute"
                                style={{ marginLeft: 4 }}
                                placeholder={locales.components.operatorListGather.pleaseEnter}
                                value={operatorItem.operatorList[0].param.interval}
                                onChange={actions.changeInputValueInParam.params(
                                    operatorItem,
                                    'interval'
                                )}
                            />
                            <div style={{ marginLeft: 4 }}>{locales.labels.second}</div>
                        </div>
                    </div>
                </If>
                {/* 20、概念词 */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.conceptWord.id
                    }
                >
                    <div className="operator-list-item-entity-info-container">
                        <div className="operator-list-item-entity-operator-container">
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.operatorList[0].param.compareType}
                                className="operator-list-item-entity-operator"
                                onChange={actions.changeOperatorItem.params(
                                    operatorItem,
                                    'compareType'
                                )}
                            >
                                <For
                                    each="item"
                                    index="index"
                                    of={Udesk.enums.conceptWordOperators}
                                >
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                </For>
                            </Select>
                        </div>
                        <div
                            className="operator-list-item-entity-field-container"
                            style={{ minWidth: '255px', height: '32px', lineHeight: '24px' }}
                        >
                            {/* <Select
                                showSearch
                                value={operatorItem.operatorList[0].param.conceptWord?operatorItem.operatorList[0].param.conceptWord.id:null}
                                placeholder={locales.components.operatorListGather.conceptWordholder}
                                style={{ width: 128 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={actions.handleSelectSearch.params("conceptWord")}
                                onChange={actions.changeOperatorItem.params(operatorItem, "conceptWord")}
                                notFoundContent={null}
                            >
                                <For each="item" index="index" of={privates.selectOptionList.conceptWord}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select> */}
                            <AutoComplete
                                disabled={props.disabled}
                                url={
                                    props.conceptWordUrl && props.conceptWordUrl !== ''
                                        ? props.conceptWordUrl
                                        : Udesk.business.apiPath.concatApiPath(
                                              `/conceptWords/findAll`,
                                              props.sdkOptions
                                          )
                                }
                                value={operatorItem.operatorList[0].param.conceptWord}
                                method={'POST'}
                                queryParamName={'keyword'}
                                onChanged={actions.conceptWordChanged.params(operatorItem)}
                                enableEmptySearch={true}
                                placeholder={
                                    locales.components.operatorListGather.conceptWordholder
                                }
                            />
                        </div>
                    </div>
                </If>
                {/* 3、正则表达式 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.regular.id}>
                    <div>
                        <Space>
                            <Input
                                disabled={props.disabled}
                                value={operatorItem.operatorList[0].keyword}
                                onChange={actions.changeInputValue.params(operatorItem, 'keyword')}
                                placeholder={locales.components.operatorListGather.pleaseEnter}
                                style={{ width: 200, marginLeft: '8px' }}
                            />
                            <Popover
                                placement="bottom"
                                trigger="hover"
                                content={
                                    <div>
                                        <Input
                                            defaultValue={regularText}
                                            onChange={actions.regularTestChange}
                                        />
                                        <div className="operator-list-item-operator-rugelar-list">
                                            <Button
                                                style={{ paddingLeft: 0 }}
                                                type="link"
                                                onClick={actions.regularTest.params(
                                                    operatorItem.operatorList[0]
                                                )}
                                            >
                                                {locales.components.operatorListGather.test}
                                            </Button>
                                            <span>
                                                <Choose>
                                                    <When condition={testResult}>
                                                        {
                                                            locales.components.pages.components
                                                                .operatorListGather.matchSuccess
                                                        }
                                                    </When>
                                                    <When condition={!testResult}>
                                                        {
                                                            locales.components.pages.components
                                                                .operatorListGather.matchError
                                                        }
                                                    </When>
                                                </Choose>
                                            </span>
                                        </div>
                                    </div>
                                }
                            >
                                <Button>{locales.components.operatorListGather.testRegular}</Button>
                            </Popover>
                        </Space>
                    </div>
                </If>
                {/* 11、关键词匹配（严格） */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.keywordStrict.id
                    }
                >
                    <div>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].operator}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, 'operator')}
                        >
                            <For each="item" index="index" of={Udesk.enums.keywordOperators}>
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            </For>
                        </Select>
                        <Input
                            // type="text"
                            disabled={props.disabled}
                            className="operator-list-item-keyword"
                            value={operatorItem.operatorList[0].keyword}
                            onChange={actions.changeInputValue.params(operatorItem, 'keyword')}
                        />
                    </div>
                </If>
                {/* 1、关键词匹配
                    <If condition={operatorItem.type === Udesk.enums.operatorTypes.keyword.id}>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].operator}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, "operator")}
                        >
                            <For each="item" index="index" of={Udesk.enums.keywordOperators}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                         </Select>
                        <input
                            type="text"
                            disabled={props.disabled}
                            className="operator-list-item-keyword"
                            value={operatorItem.operatorList[0].keyword}
                            onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                        />
                    </If> */}
                {/* 17、文法规则
                    <If condition={operatorItem.type === Udesk.enums.operatorTypes.grammar.id}>
                        <textarea
                            disabled={props.disabled}
                            className="operator-list-item-semantics"
                            rows={3}
                            value={operatorItem.operatorList[0].keyword}
                            onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                        />
                        <div className="operator-list-item-test-container-box">
                            <button type="button" className="btn btn-sm btn-default operator-list-item-button">
                                {locales.components.operatorListGather.testGrammar}
                            </button>
                            <div className="operator-list-item-test-container">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={operatorItem.operatorList[0]._testText}
                                    onChange={actions.changeInputValue.params(operatorItem, "_testText")}
                                    onFocus={actions.cleanTestResult.params(operatorItem)}
                                />
                                <span
                                    className="operator-list-item-test-container-action pull-left"
                                    onClick={actions.grammarTest.params(operatorItem)}
                                >
                                    {locales.components.operatorListGather.test}
                                </span>
                                <span className="pull-right">{operatorItem._testResult}</span>
                            </div>
                        </div>
                    </If> */}
                {/* 一问一答 */}

                <If
                    condition={
                        operatorItem.operatorType ===
                        Udesk.enums.operatorTypes.questionsAndAnswers.id
                    }
                >
                    <TreeSelect
                        disabled={props.disabled}
                        value={operatorItem.operatorList[0].param.interactiveCategoryId}
                        className="operator-list-item-questions-and-answers"
                        onChange={actions.changeOperatorItem.params(
                            operatorItem,
                            'interactiveCategory'
                        )}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={privates.treeSelectOptionList}
                        treeDefaultExpandAll
                    ></TreeSelect>
                    <Radio.Group
                        style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}
                        onChange={actions.changeOperatorItem.params(
                            operatorItem,
                            'interactiveRule'
                        )}
                        value={operatorItem.operatorList[0].param.interactiveRule}
                    >
                        {Udesk.enums.interactiveRules
                            .filter((item) => {
                                return item.id !== 1;
                            })
                            .map((item) => {
                                return (
                                    <Radio key={item.id} value={item.id}>
                                        {item.name}
                                    </Radio>
                                );
                            })}
                    </Radio.Group>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 8 }}>
                            {locales.components.operatorListGather.notAnyMatch}
                        </div>
                        <Radio.Group
                            style={{ display: 'flex', alignItems: 'center' }}
                            onChange={actions.changeOperatorItem.params(
                                operatorItem,
                                'noHitQuestionResult'
                            )}
                            value={operatorItem.operatorList[0].param.noHitQuestionResult}
                        >
                            {Udesk.enums.noHitQuestionResults.map((item) => {
                                return (
                                    <Radio key={`noHitQuestionResults${item.id}`} value={item.id}>
                                        {item.name}
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    </div>
                </If>
                {/* 要素问答 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.element.id}>
                    <div className="operator-list-item-entity-info-container">
                        <div className="operator-list-item-entity-operator-container">
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.operatorList[0].param.hit}
                                className="operator-list-item-entity-operator"
                                onChange={actions.changeOperatorItem.params(
                                    operatorItem,
                                    'compareType'
                                )}
                            >
                                <For each="item" index="index" of={Udesk.enums.inspectFortorsType}>
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                </For>
                            </Select>
                        </div>
                        <div className="operator-list-item-semantic-tags-search-container">
                            <Select
                                value={operatorItem.operatorList[0].param.mulElementId}
                                placeholder={locales.components.operatorListGather.elementHolder}
                                style={{ width: 200 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onChange={actions.handleSelectChange.params(
                                    operatorItem,
                                    'mulElementId'
                                )}
                                notFoundContent={null}
                            >
                                <For
                                    each="item"
                                    index="index"
                                    of={privates.selectOptionList.element}
                                >
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                </For>
                            </Select>
                        </div>
                    </div>
                </If>
                {/* 9、抢话检测 */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.grabDetection.id
                    }
                >
                    <div className="operator-list-item-grab-detection-box">
                        {locales.components.operatorListGather.speechGrabbingIntervalTip}
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].grabIntervalTime}
                            onChange={actions.changeInputValue.params(
                                operatorItem,
                                'grabIntervalTime'
                            )}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateGrabInterval.params(
                                operatorItem,
                                'grabIntervalTime'
                            )}
                        />
                        {locales.components.operatorListGather.msUnit}
                        <span style={{ width: 8, display: 'inline-block' }}></span>
                        {locales.components.operatorListGather.speechLengthTip}
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].grabMaxSpeakTime}
                            onChange={actions.changeInputValue.params(
                                operatorItem,
                                'grabMaxSpeakTime'
                            )}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateGrabLength.params(
                                operatorItem,
                                'grabMaxSpeakTime',
                                operatorItem.operatorList[0].grabMaxSpeakTime
                            )}
                        />
                        {locales.components.operatorListGather.msUnit}
                    </div>
                </If>
                {/* 7、静默 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.dialogue.id}>
                    <div>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].applyScope}
                            className="operator-list-item-entity-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, 'applyScope')}
                        >
                            <For
                                each="item"
                                index="index"
                                of={Udesk.enums.dialogueApplyScopes.filter((i) => i.id <= 3)}
                            >
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            </For>
                        </Select>
                    </div>
                    <div className="operator-list-item-interval-second-box">
                        <span className="operator-list-item-interval-second-tip">
                            {locales.components.operatorListGather.intervalSecond}
                        </span>
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].intervalSecond}
                            onChange={actions.changeInputValue.params(
                                operatorItem,
                                'intervalSecond'
                            )}
                            onBlur={actions.validateMinValue.params(
                                operatorItem,
                                'intervalSecond',
                                operatorItem.intervalSecond
                            )}
                        />
                        {locales.labels.second}
                    </div>
                </If>
                {/* 7、响应超时 */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.agentAnswer.id
                    }
                >
                    <div>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].param?.intervalType}
                            className="operator-list-item-entity-operator"
                            onChange={actions.changeOperatorItem.params(
                                operatorItem,
                                'intervalType'
                            )}
                        >
                            <For
                                each="item"
                                index="index"
                                of={Udesk.enums.dialogueApplyScopes.filter((i) => i.id > 3)}
                            >
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            </For>
                        </Select>
                    </div>
                    <div className="operator-list-item-interval-second-box">
                        <span className="operator-list-item-interval-second-tip">
                            {locales.components.operatorListGather.intervalSecond}
                        </span>
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].param?.intervalTime}
                            onChange={actions.changeInputValue.params(operatorItem, 'intervalTime')}
                        />
                        {locales.labels.second}
                    </div>
                </If>
                {/* 重复话术 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.repeat.id}>
                    <div style={{ marginLeft: 8 }}>
                        <Radio.Group
                            onChange={actions.changeOperatorItem.params(operatorItem, 'repeatType')}
                            value={operatorItem.operatorList[0].param.repeatType}
                        >
                            {Udesk.enums.repeatRules.map((item) => {
                                return (
                                    <Radio key={item.id} value={item.id}>
                                        {item.name}
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    </div>
                    <If
                        condition={
                            operatorItem.operatorList[0].param.repeatType ===
                            Udesk.enums.repeatRules.similar.id
                        }
                    >
                        <div style={{ marginLeft: 8 }}>
                            {locales.components.operatorListGather.similarity}
                            <InputNumber
                                disabled={props.disabled}
                                min={0}
                                max={100}
                                style={{ width: 50 }}
                                className="operator-list-item-grab-input"
                                value={operatorItem.operatorList[0].param.similarity}
                                onChange={actions.changeInputValue.params(
                                    operatorItem,
                                    'similarity'
                                )}
                            />
                            {locales.components.operatorListGather.percent}
                        </div>
                    </If>
                    <div style={{ marginLeft: 8 }}>
                        {locales.components.operatorListGather.repet}
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            style={{ width: 50 }}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].param.repeatTimes}
                            onChange={actions.changeInputValue.params(operatorItem, 'repeatTimes')}
                        />
                        {locales.components.operatorListGather.bout}
                    </div>
                    <div style={{ marginLeft: 8 }}>
                        {locales.components.operatorListGather.ignore}
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            style={{ width: 50 }}
                            className="operator-list-item-grab-input"
                            value={operatorItem.operatorList[0].param.wordsNum}
                            onChange={actions.changeInputValue.params(operatorItem, 'wordsNum')}
                        />
                        {locales.components.operatorListGather.ignoreAffix}
                    </div>
                </If>

                {/* 4、上下文重复
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.context.id}>
                    <input
                        type="text"
                        disabled={props.disabled}
                        className="operator-list-item-context"
                        value={operatorItem.operatorList[0].keyword}
                        onChange={actions.changeInputValue.params(operatorItem, "keyword")}
                        placeholder={locales.components.operatorListGather.pleaseEnterRegular}
                    />
                    <div className="operator-list-item-apply-role-box">
                        <span className="operator-list-item-apply-role-tip">
                            {locales.components.operatorListGather.afterHit}
                        </span>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].applyRole}
                            className="operator-list-item-apply-role"
                            onChange={actions.changeOperatorItem.params(operatorItem, "applyRole")}
                        >
                            <For each="item" index="index" of={Udesk.enums.applyRoles}>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                    </div>
                </If> */}
                {/* 2、疑问句 */}
                <If
                    condition={operatorItem.operatorType === Udesk.enums.operatorTypes.question.id}
                ></If>
                {/* 18、句长检测 */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.sentenceLength.id
                    }
                >
                    <div className="operator-list-item-word-per-minute-box">
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].param.compareType}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(
                                operatorItem,
                                'compareType'
                            )}
                        >
                            <For each="item" index="index" of={Udesk.enums.sentenceLength}>
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            </For>
                        </Select>
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-word-per-minute"
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            value={operatorItem.operatorList[0].param.wordsNumber}
                            onChange={actions.changeInputValueInParam.params(
                                operatorItem,
                                'wordsNumber'
                            )}
                            // formatter={value => `${value}${locales.components.operatorListGather.wordUnit}`}
                            // parser={value => value.replace(`${locales.components.operatorListGather.wordUnit}`, '')}
                            // addonAfter={locales.components.operatorListGather.wordUnit}
                        />
                        {locales.components.operatorListGather.wordUnit}
                    </div>
                </If>
                {/* 8、语速检测 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.speed.id}>
                    <div className="operator-list-item-word-per-minute-box">
                        {locales.components.operatorListGather.minute}
                        {locales.components.operatorListGather.notLessThan}
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-word-per-minute"
                            value={operatorItem.operatorList[0].wordPerMinite}
                            onChange={actions.changeInputValue.params(
                                operatorItem,
                                'wordPerMinite'
                            )}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateSpeedValue.params(
                                operatorItem,
                                'wordPerMinite',
                                operatorItem.operatorList[0].wordPerMinite
                            )}
                        />
                        {locales.components.operatorListGather.wordUnit}
                        {locales.components.operatorListGather.notGreaterThan}
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-word-per-minute"
                            value={operatorItem.operatorList[0].maxWordPerMinite}
                            onChange={actions.changeInputValue.params(
                                operatorItem,
                                'maxWordPerMinite'
                            )}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateSpeedValue.params(
                                operatorItem,
                                'maxWordPerMinite',
                                operatorItem.operatorList[0].maxWordPerMinite
                            )}
                        />
                        {locales.components.operatorListGather.wordUnit}
                        {locales.components.operatorListGather.ignoreLessThan}
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-word-per-minute"
                            value={parseInt(operatorItem.operatorList[0].keyword, 10)}
                            onChange={actions.changeInputValue.params(operatorItem, 'keyword')}
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            onBlur={actions.validateSpeedValue.params(
                                operatorItem,
                                'keyword',
                                operatorItem.operatorList[0].keyword
                            )}
                        />
                        {locales.components.operatorListGather.wordUnitSet}
                    </div>
                </If>
                {/* 15、音量检测 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.volume.id}>
                    <div>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].param.detectionWay}
                            className="operator-list-item-volume-detection-way"
                            onChange={actions.changeOperatorItem.params(
                                operatorItem,
                                'detectionWay'
                            )}
                        >
                            <For each="item" index="index" of={Udesk.enums.volumeDetectionWays}>
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            </For>
                        </Select>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].param.operator}
                            className="operator-list-item-volume-detection-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, 'operator')}
                        >
                            <For
                                each="item"
                                index="index"
                                of={Udesk.enums.volumeDetectionOperators}
                            >
                                <Select.Option value={item.id}>{item.name}</Select.Option>
                            </For>
                        </Select>
                    </div>
                    <div className="operator-list-item-volume-detection-value-box">
                        <Choose>
                            <When
                                condition={
                                    operatorItem.operatorList[0].param.detectionWay ===
                                    Udesk.enums.volumeDetectionWays.loudness.id
                                }
                            >
                                <InputNumber
                                    disabled={props.disabled}
                                    min={0}
                                    max={200}
                                    step="0.01"
                                    className="operator-list-item-volume-detection-value"
                                    value={operatorItem.operatorList[0].param.detectionValue}
                                    onChange={actions.changeInputValueInParam.params(
                                        operatorItem,
                                        'detectionValue'
                                    )}
                                    placeholder={
                                        locales.components.operatorListGather.pleaseEnterLoudness
                                    }
                                    onBlur={actions.validateVolumDetectionValue.params(
                                        operatorItem,
                                        operatorItem.operatorList[0].param.detectionValue
                                    )}
                                />
                                {locales.components.operatorListGather.loudnessUnit}
                            </When>
                            <Otherwise>
                                <InputNumber
                                    disabled={props.disabled}
                                    min={-1000}
                                    max={1000}
                                    step="0.01"
                                    className="operator-list-item-volume-detection-value"
                                    value={operatorItem.operatorList[0].param.detectionValue}
                                    onChange={actions.changeInputValueInParam.params(
                                        operatorItem,
                                        'detectionValue'
                                    )}
                                    placeholder={
                                        locales.components.operatorListGather
                                            .pleaseEnterRangeAbility
                                    }
                                    onBlur={actions.validateVolumDetectionValue.params(
                                        operatorItem,
                                        operatorItem.operatorList[0].param.detectionValue
                                    )}
                                />
                                {locales.components.operatorListGather.percent}
                            </Otherwise>
                        </Choose>
                    </div>
                </If>
                {/* 16、情绪分析 */}
                <If condition={operatorItem.operatorType === Udesk.enums.operatorTypes.emotion.id}>
                    <div>
                        <Select
                            style={{ marginLeft: '8px' }}
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].param.operator}
                            className="operator-list-item-type"
                            onChange={actions.changeOperatorItem.params(operatorItem, 'operator')}
                        >
                            <For each="item" index="index" of={Udesk.enums.emotionOperators}>
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            </For>
                        </Select>
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].param.sentiment}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, 'sentiment')}
                        >
                            <For each="item" index="index" of={Udesk.enums.sentimentTypes}>
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            </For>
                        </Select>
                    </div>
                </If>
                {/* 13、信息实体检测 */}
                <If
                    condition={
                        operatorItem.operatorType ===
                        Udesk.enums.operatorTypes.entityInfoDetection.id
                    }
                >
                    <div className="operator-list-item-entity-info-container">
                        <div
                            className="operator-list-item-entity-field-container"
                            style={{ minWidth: '255px', height: '32px', lineHeight: '24px' }}
                        >
                            <AutoComplete
                                disabled={props.disabled}
                                url={
                                    props.entityUrl && props.entityUrl !== ''
                                        ? props.entityUrl
                                        : Udesk.business.apiPath.concatApiPath(
                                              `/information-entity/list`,
                                              props.sdkOptions
                                          )
                                }
                                value={operatorItem.operatorList[0].param.entity}
                                method={'POST'}
                                // extraQueryParams={{ activeFlag: 1, systemType: Udesk.data.init.company.systemType }}
                                extraQueryParams={{ activeFlag: 1 }}
                                queryParamName={'keyword'}
                                onChanged={actions.changeOperatorItem.params(
                                    operatorItem,
                                    'entity'
                                )}
                                enableEmptySearch={true}
                                placeholder={
                                    locales.components.operatorListGather.pleaseSelectEntity
                                }
                            />
                            {/* <Select
                                disabled={props.disabled}
                                showSearch
                                value={operatorItem.operatorList[0].param.entity}
                                placeholder={locales.components.operatorListGather.pleaseSelectEntity}
                                style={{ width: 128 }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={actions.handleSelectSearch.params("entity")}
                                onChange={actions.changeOperatorItem.params(operatorItem, "entity")}
                                notFoundContent={null}
                            >
                                <For each="item" index="index" of={privates.selectOptionList.entityInfoDetection}>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                </For>
                            </Select> */}
                        </div>
                        <div className="operator-list-item-entity-operator-container">
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.operatorList[0].param.operator}
                                style={{ width: '114px' }}
                                onChange={actions.changeOperatorItem.params(
                                    operatorItem,
                                    'operator'
                                )}
                            >
                                <For each="item" index="index" of={Udesk.enums.entityOperators}>
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                </For>
                            </Select>
                        </div>
                        <div
                            className="operator-list-item-entity-value-container"
                            style={{
                                marginLeft: '8px',
                                minWidth: '255px',
                                height: '32px',
                                lineHeight: '24px',
                            }}
                        >
                            <Choose>
                                <When
                                    condition={
                                        operatorItem.operatorList[0].param.operator === null ||
                                        operatorItem.operatorList[0].param.operator ===
                                            Udesk.enums.entityOperators.equals.id ||
                                        operatorItem.operatorList[0].param.operator ===
                                            Udesk.enums.entityOperators.notEquals.id ||
                                        operatorItem.operatorList[0].param.operator ===
                                            Udesk.enums.entityOperators.contains.id ||
                                        operatorItem.operatorList[0].param.operator ===
                                            Udesk.enums.entityOperators.notContains.id
                                    }
                                >
                                    {/* <PowerSelect
                                                disabled={props.disabled}
                                                dataSource={props.entityFields}
                                                nameField="label"
                                                value={operatorItem.operatorList[0].param.expectedValueObject}
                                                placeholder={locales.components.operatorListGather.pleaseSelectBasicInfo}
                                                onChanged={actions.changeOperatorItem.params(operatorItem.param, "expectedValueObject")}
                                            /> */}
                                    <AutoComplete
                                        disabled={props.disabled}
                                        defaultOptions={props.entityFields}
                                        value={
                                            operatorItem.operatorList[0].param.expectedValueObject
                                        }
                                        nameField="label"
                                        placeholder={
                                            locales.components.operatorListGather
                                                .pleaseSelectBasicInfo
                                        }
                                        onChanged={actions.changeOperatorItem.params(
                                            operatorItem,
                                            'expectedValueObject'
                                        )}
                                        isFilterSelectedOption={true}
                                    />
                                    {/* <Select
                                        disabled={props.disabled}
                                        showSearch
                                        value={operatorItem.operatorList[0].param.expectedValueObject}
                                        placeholder={locales.components.operatorListGather.pleaseSelectBasicInfo}
                                        style={{ width: 128 }}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={actions.handleSelectSearch.params("expectedValueObject")}
                                        onChange={actions.changeOperatorItem.params(operatorItem, "expectedValueObject")}
                                        notFoundContent={null}
                                    >
                                        <For each="item" index="index" of={props.entityFields}>
                                            <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>
                                        </For>
                                    </Select> */}
                                </When>
                                <Otherwise>
                                    <Input
                                        disabled={props.disabled}
                                        value={operatorItem.operatorList[0].param.expectedValue}
                                        onChange={actions.changeInputValue.params(
                                            operatorItem,
                                            'expectedValue'
                                        )}
                                        placeholder={
                                            locales.components.operatorListGather
                                                .pleaseEnterFixedValue
                                        }
                                    />
                                </Otherwise>
                            </Choose>
                        </div>
                    </div>
                </If>
                {/* 随路字段 */}
                <If
                    condition={operatorItem.operatorType === Udesk.enums.operatorTypes.associate.id}
                >
                    <Associate
                        {...{
                            type: props.type,
                            locales,
                            sdkOptions: props.sdkOptions,
                            isNewAssociate: props.isNewAssociate,
                            isAutoAssociateDateSource: props.isAutoAssociateDateSource,
                            operatorItem,
                            changeInputValue: actions.changeInputValue,
                            handleOperatorItemChange: actions.handleOperatorItemChange,
                        }}
                    />
                </If>

                {/* 34 业务小结字段 */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.businessSummary.id
                    }
                >
                    <BusinessSummary
                        {...{
                            type: props.type,
                            locales,
                            sdkOptions: props.sdkOptions,
                            operatorItem,
                            changeInputValue: actions.changeInputValue,
                            options: privates.selectOptionList.businessSummary,
                        }}
                    />
                </If>

                {/* 24、错别字检测 */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.typoDetection.id
                    }
                >
                    <div className="operator-list-item-word-per-minute-box">
                        {locales.components.operatorListGather.times}
                        <Select
                            disabled={props.disabled}
                            value={operatorItem.operatorList[0].param.operator}
                            className="operator-list-item-operator"
                            onChange={actions.changeOperatorItem.params(operatorItem, 'operator')}
                        >
                            <For each="item" index="index" of={Udesk.enums.typoDetectionOperators}>
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            </For>
                        </Select>
                        <InputNumber
                            disabled={props.disabled}
                            min={0}
                            className="operator-list-item-word-per-minute"
                            placeholder={locales.components.operatorListGather.pleaseEnter}
                            value={operatorItem.operatorList[0].param.typoCounts}
                            onChange={actions.changeInputValueInParam.params(
                                operatorItem,
                                'typoCounts'
                            )}
                        />
                        {locales.components.operatorListGather.bout}
                    </div>
                </If>
                {/* 知识解答 */}
                <If
                    condition={operatorItem.operatorType === Udesk.enums.operatorTypes.knowledge.id}
                >
                    <TreeSelect
                        disabled={props.disabled}
                        value={operatorItem.operatorList[0].param.interactiveCategoryId}
                        className="operator-list-item-questions-and-answers"
                        onChange={actions.changeOperatorItem.params(
                            operatorItem,
                            'interactiveCategory'
                        )}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={privates.treeSelectOptionListKnowledge}
                        treeDefaultExpandAll
                    ></TreeSelect>
                    <Radio.Group
                        style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}
                        onChange={actions.changeOperatorItem.params(
                            operatorItem,
                            'interactiveRule'
                        )}
                        value={operatorItem.operatorList[0].param.interactiveRule}
                    >
                        {Udesk.enums.interactiveRules
                            .filter((item) => {
                                return item.id !== 1;
                            })
                            .map((item) => {
                                return (
                                    <Radio key={item.id} value={item.id}>
                                        {item.name}
                                    </Radio>
                                );
                            })}
                    </Radio.Group>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 8 }}>
                            {locales.components.operatorListGather.notAnyMatch}
                        </div>
                        <Radio.Group
                            style={{ display: 'flex', alignItems: 'center' }}
                            onChange={actions.changeOperatorItem.params(
                                operatorItem,
                                'noHitQuestionResult'
                            )}
                            value={operatorItem.operatorList[0].param.noHitQuestionResult}
                        >
                            {Udesk.enums.noHitQuestionResults.map((item) => {
                                return (
                                    <Radio key={`noHitQuestionResults${item.id}`} value={item.id}>
                                        {item.name}
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    </div>
                </If>
                {/* 28、客户添加时间 */}
                <If
                    condition={
                        operatorItem.operatorType === Udesk.enums.operatorTypes.customerAddTime.id
                    }
                >
                    <div className="operator-list-item-semantic-tags-container">
                        <div
                            className="operator-list-item-semantic-tags-search-container"
                            style={{ display: 'flex', alignItems: 'center', width: 'unset' }}
                        >
                            <Select
                                disabled={props.disabled}
                                value={operatorItem.operatorList[0].param.compareType}
                                className="operator-list-item-operator"
                                style={{ width: 98, marginLeft: 4, marginRight: 8 }}
                                onChange={actions.changeOperatorItem.params(
                                    operatorItem,
                                    'compareType'
                                )}
                            >
                                <For
                                    each="item"
                                    index="index"
                                    of={Udesk.enums.customerAddTimeCompareTypes}
                                >
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                </For>
                            </Select>
                            <Space>
                                <div>
                                    {/* 第 */}
                                    {
                                        locales.components.checkPointOperatorListGather.template
                                            .section
                                    }
                                </div>
                                <InputNumber
                                    disabled={props.disabled}
                                    min={1}
                                    className="operator-list-item-word-per-minute"
                                    style={{ marginLeft: 4 }}
                                    placeholder={locales.components.operatorListGather.pleaseEnter}
                                    value={operatorItem.operatorList[0].param.interval}
                                    onChange={actions.changeInputValueInParam.params(
                                        operatorItem,
                                        'interval'
                                    )}
                                />
                                <div>
                                    {/* 天 */}
                                    {locales.components.checkPointOperatorListGather.template.day}
                                </div>
                            </Space>
                        </div>
                    </div>
                </If>
            </div>
        );
    }
}
