import React from 'react';

class AudioCallBasicInfoComponent extends React.Component {
    static defaultProps = {
        width: 340,
        rerenderOptimization: true,
    };
    privates = {
        basicReaf: React.createRef(),
        lineFlag: true,
        phoneFlag: false,
        highlightPoint: null,
        ticketIndex: 0,
    };
    actions = {
        letOutline() {
            this.privates.basicReaf.current.style.width = '48px';
            this.privates.lineFlag = false;
            this.privates.phoneFlag = true;
            this.props.resizeWidthChange && this.props.resizeWidthChange(false);
            this.actions.update();
        },
        rightOutline() {
            this.privates.basicReaf.current.style.width = this.props.width + 'px';
            this.privates.lineFlag = true;
            this.privates.phoneFlag = false;
            this.props.resizeWidthChange && this.props.resizeWidthChange(true);
            this.actions.update();
        },
        setHighlightPoint (value) {
            this.privates.lineFlag = value;
            this.actions.update();
        },
        onTicketChange(value) {
            this.privates.ticketIndex = value;
            this.actions.update();
        }
    };
    // parseProps({ props, prevProps }) {
    //     return {
    //         content: props.content,
    //     };
    // }
}
export default AudioCallBasicInfoComponent;
