// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PutDataCallSessionFlowsUpdateQuery,
    PutDataCallSessionFlowsUpdateBody,
    BaseResponseVoid,
} from '../../types';

/**
 * 修改会话流程
 *
 * @export
 * @tags 销售赋能-会话流程接口
 * @link [PUT] /dataCallSessionFlows/update
 */
export function putDataCallSessionFlowsUpdate(
    data: PutDataCallSessionFlowsUpdateBody,
    options: ApiOptions<never, PutDataCallSessionFlowsUpdateQuery> & {
        params: PutDataCallSessionFlowsUpdateQuery;
    }
): Promise<BaseResponseVoid> {
    return request<PutDataCallSessionFlowsUpdateBody, never, PutDataCallSessionFlowsUpdateQuery>(
        '/dataCallSessionFlows/update',
        { ...options, data, method: 'put', __$requestCalleeName: 'putDataCallSessionFlowsUpdate' }
    );
}

export const meta = [
    { tags: ['销售赋能-会话流程接口'], path: '/dataCallSessionFlows/update', method: 'put' },
];
