import { ArgumentNullError, InvalidArgumentError } from '../../error';
import UploadBaseAdapterClass from './adapters/base';
import UploadProxyCredentialClass from './credentials/upload/proxy';
import UploadOssAdapterClass from './adapters/oss';

/*
 * options:
 *   token token数据 必填 支持object、function(function 支持返回promise、object)、promise
 *   uploadAdapter 选填 可传值adapter 不传时调用upload.defaults.uploadAdapter;
 *   progressCallback 选填 上传进度的回调
 *   successCallback 选填  上传成功的回调
 *   errorCallback 选填  上传失败的回调
 */

function upload(files, options) {
    if (files == null) {
        throw new ArgumentNullError('files');
    }
    if (!(files instanceof Array)) {
        files = [files];
    }
    if (files.some((file) => file == null)) {
        throw new InvalidArgumentError('files');
    }
    if (options == null) {
        throw new ArgumentNullError('options');
    }
    if (!(typeof options === 'object')) {
        throw new InvalidArgumentError('options');
    }
    let token = options.token;
    if (token == null) {
        throw new ArgumentNullError('options.token');
    }
    let tokenPromise =
        typeof token.then === 'function'
            ? token
            : new Promise((resolve, reject) => {
                  if (typeof token === 'function') {
                      let tokenData = token();
                      if (typeof tokenData.then === 'function') {
                          tokenData.then(function (resp) {
                              if (typeof resp === 'object') {
                                  resolve(resp);
                              } else {
                                  reject(`Upload Error: token() result is not valid`);
                              }
                          });
                      } else {
                          if (typeof token === 'object') {
                              resolve(token);
                          } else {
                              reject(`Upload Error: token is not valid`);
                          }
                      }
                  } else {
                      if (typeof token === 'object') {
                          resolve(token);
                      } else {
                          reject(`Upload Error: token result is not valid`);
                      }
                  }
              });

    let proxyUploadCredential = new UploadProxyCredentialClass();
    tokenPromise.then(
        (tokenData) => {
            if (!proxyUploadCredential.isAborted) {
                let currentUploadAdapter = null;
                let getUploadAdapter = upload.uploadStrategies.getUploadAdapter;
                let uploadAdapter = options.uploadAdapter;
                let uploadOptions = options;
                let globalAdapters = this.adapters;

                if (uploadAdapter != null) {
                    if (uploadAdapter instanceof UploadBaseAdapterClass) {
                        currentUploadAdapter = uploadAdapter;
                    } else if (
                        typeof uploadAdapter === 'string' &&
                        Object.prototype.hasOwnProperty.call(this.adapters, uploadAdapter)
                    ) {
                        currentUploadAdapter = this.adapters[uploadAdapter];
                    }
                }
                if (!currentUploadAdapter && typeof options.getUploadAdapter === 'function') {
                    currentUploadAdapter = options.getUploadAdapter({ tokenData, uploadOptions, globalAdapters });
                }
                if (!currentUploadAdapter && typeof getUploadAdapter === 'function') {
                    currentUploadAdapter = getUploadAdapter({ tokenData, uploadOptions, globalAdapters });
                }
                if (
                    typeof currentUploadAdapter === 'string' &&
                    Object.prototype.hasOwnProperty.call(this.adapters, currentUploadAdapter)
                ) {
                    currentUploadAdapter = this.adapters[currentUploadAdapter];
                }
                if (!currentUploadAdapter) {
                    currentUploadAdapter = upload.defaults.uploadAdapter;
                }

                let uploadCredential = currentUploadAdapter.upload(files, {
                    tokenData: tokenData,
                    progressCallback: options.progressCallback,
                    successCallback: options.successCallback,
                    errorCallback: options.errorCallback,
                });
                proxyUploadCredential.connect(uploadCredential);
            }
        },
        (errMsg) => {
            if (typeof options.errorCallback === 'function') {
                options.errorCallback(errMsg);
            }
        }
    );

    return proxyUploadCredential;
}

upload.defaults = {
    // 不是在这里赋值，在 ./index.js 中赋值的
    // uploadAdapter: new UploadOssAdapterClass(),
};

upload.uploadStrategies = {
    getUploadAdapter({ tokenData, uploadOptions, globalAdapters }) {
        if (tokenData && tokenData.storageType) {
            return globalAdapters[tokenData.storageType];
        }
        return null;
    },
};

export default upload;
