import React, { useCallback, useEffect, useMemo } from 'react';
import Page from 'Component/common/udesk-qa-page-layout';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import pathToRegexp from 'path-to-regexp';
import UdeskLocales from 'UdeskLocales';
import { createComponent } from 'src/util/component';
import { Tabs } from 'udesk-ui';
import { linkTo } from 'src/util/link';
import { hasFeature } from 'src/util/permit';
import { fire } from 'src/util/core';
import './index.less';

const Template = React.memo((props: any) => {
    const { storages, saveStorages, routes, location, history } = props;

    const ADD_TYPES = useMemo(
        () => [
            {
                pathName: 'coachLearningCenterExerciseTask',
                key: 'exercise',
                text: /* 练习任务 */ UdeskLocales['current'].pages.coach.learningCenter.task.index
                    .practiceTasks,
                hidden: () => !hasFeature('intelligentpartner:practicetask:root'),
                routes: [] as string[], // 包含在Page组件里面的下级路由名称
            },
            {
                pathName: 'coachLearningCenterTestTask',
                key: 'test',
                text: /* 考试任务 */ UdeskLocales['current'].pages.coach.learningCenter.task.index
                    .examTasks,
                hidden: () => !hasFeature('intelligentpartner:examtask:root'),
                routes: [] as string[], // 包含在Page组件里面的下级路由名称
            },
        ],
        []
    );

    // 获取当前路由
    const activeRoute = useMemo(() => {
        return routes.find(({ path }) => {
            if (path.includes(':')) {
                return pathToRegexp(path).test(location.pathname);
            }
            return path === location.pathname;
        });
    }, [location.pathname, routes]);

    // 判断当前路由是否是Page下的组件
    const isPageRoute = useMemo(() => {
        if (activeRoute) {
            const { name: routeName } = activeRoute;

            return ADD_TYPES.some((item) => {
                if (item.pathName === routeName) {
                    return true;
                }
                if (item.routes) {
                    return item.routes.includes(routeName);
                }
                return false;
            });
        }

        return false;
    }, [activeRoute]);

    const onTabsChange = useCallback(
        (activeTabKey) => {
            const tab = ADD_TYPES.find((tab) => tab.key === activeTabKey);
            if (tab) {
                linkTo(history, tab.pathName);
            }
        },
        [history]
    );

    const renderSubRoutes = useCallback(() => {
        if (activeRoute) {
            return <SubRoutes route={activeRoute} />;
        } else {
            return routes.map((routeItem, index) => <SubRoutes key={index} route={routeItem} />);
        }
    }, [activeRoute, routes]);

    useEffect(() => {
        const currentPath = ADD_TYPES.find((item) => location.pathname.includes(item.key));
        if (currentPath) {
            saveStorages({
                activeTabKey: currentPath?.key,
            });
        } else {
            onTabsChange('exercise');
        }
    }, [location, onTabsChange, saveStorages]);

    return isPageRoute ? (
        <Page
            pageClassName="learning-center-tasks"
            pageBodyClassName="learning-center-tasks-body"
            backGroundGhost={true}
            title={/* 任务 */ UdeskLocales['current'].pages.coach.learningCenter.task.index.task}
            footer={
                <Tabs size="small" activeKey={storages.activeTabKey} onChange={onTabsChange}>
                    {routes.map((route) => {
                        const tab = ADD_TYPES.find((item) => item.pathName === route.name);
                        if (tab && !fire(tab.hidden)) {
                            return <Tabs.TabPane tab={tab.text} key={tab.key} />;
                        }
                        return null;
                    })}
                </Tabs>
            }
        >
            <div className="page-content">{renderSubRoutes()}</div>
        </Page>
    ) : (
        renderSubRoutes()
    );
});

export default createComponent(
    Template,
    {
        activeTabKey: 'exercise',
    },
    [/^\/coach\/learning-center\/task\/*/i]
);
