// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetIntelligentPartnerCustomerPersonasByIdParam,
    BaseResponseIntelligentPartnerCustomerPersonaFoundResponse,
    PutIntelligentPartnerCustomerPersonasByIdParam,
    PutIntelligentPartnerCustomerPersonasByIdBody,
    BaseResponseVoid,
    DeleteIntelligentPartnerCustomerPersonasByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 智能陪练-客户画像配置
 * @link [GET] /intelligentPartnerCustomerPersonas/{id}
 * @param id
 */
export function getIntelligentPartnerCustomerPersonasById(
    options: ApiOptions<GetIntelligentPartnerCustomerPersonasByIdParam, never> & {
        segments: GetIntelligentPartnerCustomerPersonasByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerCustomerPersonaFoundResponse> {
    return request<never, GetIntelligentPartnerCustomerPersonasByIdParam, never>(
        '/intelligentPartnerCustomerPersonas/{id}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerCustomerPersonasById',
        }
    );
}

/**
 * 编辑客户画像
 *
 * @export
 * @tags 智能陪练-客户画像配置
 * @link [PUT] /intelligentPartnerCustomerPersonas/{id}
 * @param id
 */
export function putIntelligentPartnerCustomerPersonasById(
    data: PutIntelligentPartnerCustomerPersonasByIdBody,
    options: ApiOptions<PutIntelligentPartnerCustomerPersonasByIdParam, never> & {
        segments: PutIntelligentPartnerCustomerPersonasByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentPartnerCustomerPersonasByIdBody,
        PutIntelligentPartnerCustomerPersonasByIdParam,
        never
    >('/intelligentPartnerCustomerPersonas/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerCustomerPersonasById',
    });
}

/**
 * 删除客户画像
 *
 * @export
 * @tags 智能陪练-客户画像配置
 * @link [DELETE] /intelligentPartnerCustomerPersonas/{id}
 * @param id
 */
export function deleteIntelligentPartnerCustomerPersonasById(
    options: ApiOptions<DeleteIntelligentPartnerCustomerPersonasByIdParam, never> & {
        segments: DeleteIntelligentPartnerCustomerPersonasByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentPartnerCustomerPersonasByIdParam, never>(
        '/intelligentPartnerCustomerPersonas/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteIntelligentPartnerCustomerPersonasById',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-客户画像配置'],
        path: '/intelligentPartnerCustomerPersonas/{id}',
        method: 'get',
    },
    {
        tags: ['智能陪练-客户画像配置'],
        path: '/intelligentPartnerCustomerPersonas/{id}',
        method: 'put',
    },
    {
        tags: ['智能陪练-客户画像配置'],
        path: '/intelligentPartnerCustomerPersonas/{id}',
        method: 'delete',
    },
];
