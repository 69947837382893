import React from 'react';
import Udesk from 'Udesk';
import CallManageIndexRoute from './route';
import FilterManage from '../components/filter-manage';
import ListManage from '../components/list-manage';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

export default class CallManageIndexTemplate extends CallManageIndexRoute {
    render() {
        let { filterVisible, isUpdate, keyword } = this.privates;
        let { selectedFilter } = this.privates.storages;
        let { actions, locales } = this;

        return (
            // <div className="row qa-react-page call-manage-page">
            <div className="udesk-qa-web-page">
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root call-manage-page">
                        <div className="call-manage-page-left-part">
                            <FilterManage
                                ref={this.privates.filterManageRef}
                                type={Udesk.enums.filterTypes.eComment.id}
                                filterManageTitle={/* 电商评论筛选器 */UdeskLocales['current'].pages.eCommentManage.template.ecommerceReviewFilter}
                                onEditFilter={actions.editFilter}
                                allFilterTitle={/* 全部评论 */UdeskLocales['current'].pages.eCommentManage.template.allComments}
                                onSelectedFilterChanged={actions.onSelectedFilterChanged}
                                isUpdate={isUpdate}
                            />
                        </div>
                        <div className="call-manage-page-right-part">
                            <ListManage
                                title={/* 电商评论管理 */UdeskLocales['current'].pages.eCommentManage.template.ecommerceReviewManagement}
                                cacheKey="e-comment-manage.index"
                                searchPlaceHolder={locales.labels.searchPlaceHolder}
                                type={Udesk.enums.filterTypes.eComment.id}
                                editFilterVisible={filterVisible}
                                onEditFilterVisibleChanged={actions.onEditFilterVisibleChanged}
                                onViewItemDetail={actions.onViewItemDetail}
                                onPageChanged={actions.onPageChanged}
                                selectedFilter={selectedFilter}
                                onConditionFilterSaved={actions.onConditionFilterSaved}
                                keyword={keyword}
                                hideKeywordType
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
