import Udesk from 'Udesk';
import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip } from 'udesk-ui';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

export default (props: any) => {
    const {className, customerTagList, locales, customerId, request} = props;
    const [tags, setTags] = useState<any[]>([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<any>();
    const editInputRef = useRef<any>();

    useEffect(() => {
        if (customerTagList) {
            setTags(customerTagList);
        }
    }, [customerTagList]);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    /**
     * 删除标签
     * @param removedTag 
     */
    const handleClose = (event, removedTag: any) => {
        event.preventDefault();
        request('/review/spectaculars/customerTag', {
            customerId,
            id: removedTag.id,
            tagName: removedTag.tagName,
            tagSource: removedTag.tagSource
        }, 'del').then(
            resp => {
                setTags(tags.filter((tag) => tag !== removedTag));
                Udesk.ui.notify.success(locales.fix.deleteSuccess);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };
    /**
     * 添加标签
     */
    const handleInputConfirm = () => {
        if (inputValue) {
            request('/review/spectaculars/customerTag', {
                customerId,
                tagName: inputValue,
                tagSource: 0,
            }, 'post').then(
                resp => {
                    setTags([...tags, resp.data]);
                    Udesk.ui.notify.success(locales.fix.addSuccess);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                setInputVisible(false);
                setInputValue('');
            });
        } else {
            setInputVisible(false);
        }
    };

    return (
        <div className={className}>
            {tags.map((tag, index) => {
                const isLongTag = tag.tagName.length > 20;

                const tagElem = (
                    <Tag
                        className="edit-tag"
                        color={'rgba(25, 110, 255, 0.15)'}
                        key={tag.id}
                        closable
                        onClose={(event) => handleClose(event, tag)}
                    >
                        <span>
                            {isLongTag ? `${tag.tagName.slice(0, 20)}...` : tag.tagName}
                        </span>
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag.tagName} key={tag.id}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                );
            })}
            {inputVisible && (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    className="tag-input"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            )}
            {!inputVisible && (
                <Tag className="site-tag-plus" onClick={showInput}>
                    <PlusOutlined />{/* 标签 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.customerTag.index.label}</Tag>
            )}
        </div>
    );
};