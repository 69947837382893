// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostSearchTemplateWordsFilterSaveBody,
    BaseResponseSearchTemplate,
} from '../../../types';

/**
 * 话术过滤筛选器保存
 *
 * @export
 * @tags 筛选器
 * @link [POST] /search-template/wordsFilter/save
 */
export function postSearchTemplateWordsFilterSave(
    data: PostSearchTemplateWordsFilterSaveBody
): Promise<BaseResponseSearchTemplate>;
export function postSearchTemplateWordsFilterSave(
    data: PostSearchTemplateWordsFilterSaveBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseSearchTemplate>;
export function postSearchTemplateWordsFilterSave(
    data: PostSearchTemplateWordsFilterSaveBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseSearchTemplate> {
    return request<PostSearchTemplateWordsFilterSaveBody, never, never>(
        '/search-template/wordsFilter/save',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postSearchTemplateWordsFilterSave',
        }
    );
}

export const meta = [
    { tags: ['筛选器'], path: '/search-template/wordsFilter/save', method: 'post' },
];
