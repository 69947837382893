import React, { useMemo } from 'react';
import styled from 'styled-components';
// import Udesk from 'Udesk';
import MagicString from 'magic-string';
import UdeskLocales from 'UdeskLocales';
import { Avatar, Button, Icon, Space } from 'udesk-ui';
import { formatDuration } from 'src/util/date';

export const ActionWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AvatarItem = styled.div`
    font-size: 12px;
    line-height: 20px;
    display: flex;
`;

export const DataWrap = styled.div`
    flex: 1;
    text-align: right;
`;

export const DataValueWrap = styled.div`
    width: 48px;
    text-align: initial;
`;

const Action = (props) => {
    const { userName, avatarUrl, dataList } = props;
    return (
        <ActionWrap>
            <AvatarItem>
                <Space>
                    <Avatar size="small" src={avatarUrl}>
                        {userName}
                    </Avatar>
                    <div>{userName}</div>
                </Space>
            </AvatarItem>
            <DataWrap>
                {dataList.map((data) => {
                    return (
                        <Space>
                            <div>{data.label}:</div>
                            <DataValueWrap>{data.value}</DataValueWrap>
                        </Space>
                    );
                })}
            </DataWrap>
        </ActionWrap>
    );
};

export const ClientAction: React.FC<any> = React.memo((props) => {
    const locales = UdeskLocales['current'];

    const { userName, avatarUrl, info, isWechatIm } = props;
    let dataList;
    if (isWechatIm) {
        dataList = [
            {
                label: /* 消息条数 */ locales.components.sessionInsights.components
                    .numberOfMessages,
                value: `${info.imCount >>> 0}${
                    /* 条 */ locales.components.sessionInsights.components.strip
                }`,
            },
            {
                label: /* 消息条数占比 */ locales.components.sessionInsights.components
                    .proportionOfMessages,
                value: `${(info.imPercentage * 100) >>> 0}%`,
            },
        ].concat(
            info.sessionType === 2
                ? [
                      {
                          label: /* 群聊互动率 */ UdeskLocales['current'].components.sessionInsights
                              .components.groupChatInteractionRate,
                          value: `${(info.customerGroupChatInteractivePercentage * 100) >>> 0}%`,
                      },
                  ]
                : []
        );
    } else {
        dataList = [
            {
                label: /* 说话占比 */ locales.components.sessionInsights.components
                    .speechPercentage,
                value: `${(info.speechPercentage * 100) >>> 0}%`,
            },
            {
                label: /* 最长客户发言 */ locales.components.sessionInsights.components
                    .longestCustomerTalk,
                value: formatDuration((info.longestSpeechDuration ?? 0) / 1000).replace('00:', ''),
            },
            {
                label: /* 深入讨论次数 */ locales.components.sessionInsights.components
                    .numberOfIndepthDiscussions,
                value:
                    info.questionFrequency ??
                    0 + /* 次 */ locales.components.sessionInsights.components.second,
            },
        ];
    }
    // const dataList = [
    //     { label: '说话占比', value: `${(info.speechPercentage * 100) >>> 0}%` },
    //     {
    //         label: '最长客户发言',
    //         value: formatDuration((info.longestSpeechDuration ?? 0) / 1000).replace('00:', ''),
    //     },
    //     { label: '深入讨论次数', value: info.speechPercentage?.toFixed?.(1)??0 + '次' },
    // ];
    return <Action userName={userName} avatarUrl={avatarUrl} dataList={dataList} />;
});

export const EmployeeAction: React.FC<any> = React.memo((props) => {
    const locales = UdeskLocales['current'];

    const { userName, avatarUrl, info, isWechatIm } = props;
    const dataList = isWechatIm
        ? [
              {
                  label: /* 消息条数 */ locales.components.sessionInsights.components
                      .numberOfMessages,
                  value: `${info.imCount >>> 0}${
                      /* 条 */ locales.components.sessionInsights.components.strip
                  }`,
              },
              {
                  label: /* 消息条数占比 */ locales.components.sessionInsights.components
                      .proportionOfMessages,
                  value: `${(info.imPercentage * 100) >>> 0}%`,
              },
              {
                  label: /* 应答最长间隔时间 */ UdeskLocales['current'].components.sessionInsights
                      .components.maximumResponseIntervalTime,
                  value: `${info.agentLongestAnswerIntervalDuration || 0}`,
              },
          ]
        : [
              {
                  label: /* 说话占比 */ locales.components.sessionInsights.components
                      .speechPercentage,
                  value: `${(info.speechPercentage * 100) >>> 0}%`,
              },
              {
                  label: /* 最长持续说话时长 */ locales.components.sessionInsights.components
                      .maximumDurationOfContinuousSpeech,
                  value: formatDuration((info.longestSpeechDuration ?? 0) / 1000).replace(
                      '00:',
                      ''
                  ),
              },
              {
                  label: /* 抢话次数 */ UdeskLocales['current'].components.sessionInsights
                      .components.numberOfPreemptiveCalls,
                  value: info.interceptCount ?? 0,
              },
              {
                  label: /* 提问频次 */ locales.components.sessionInsights.components
                      .questionFrequency,
                  value:
                      info.questionFrequency?.toFixed?.(1) ??
                      0 + /* 次 */ locales.components.sessionInsights.components.second,
              },
          ];
    return <Action userName={userName} avatarUrl={avatarUrl} dataList={dataList} />;
});

const CardTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
`;

export const Card: React.FC<any> = React.memo((props) => {
    return (
        <div>
            <CardTitle>{props.title}</CardTitle>
            <div>{props.children}</div>
        </div>
    );
});

const CountTagWrap = styled.div`
    max-width: 90%;
    width: 206px;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    padding-left: 16px;
    margin-bottom: ${(props) => props.marginBottom + 'px'};
    cursor: pointer;
`;
const CountTagCount = styled.div`
    min-width: 40px;
    height: 24px;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 12px;
    text-align: center;
`;

export const CountTag: React.FC<any> = React.memo((props) => {
    const { backgroundColor, marginBottom = 8, onClick } = props;
    return (
        <CountTagWrap
            onClick={onClick}
            backgroundColor={backgroundColor}
            marginBottom={marginBottom}
        >
            <div>{props.title}</div>
            <CountTagCount backgroundColor={backgroundColor}>{props.count}</CountTagCount>
        </CountTagWrap>
    );
});

export const ListItem = (props) => {
    const locales = UdeskLocales['current'];
    const {
        text,
        fromSec,
        length,
        start,
        nickName,
        backgroundColor = 'transparent',
        tagName,
        onClick,
        endSec,
        chanelType, //0 电话  1 企业会话 2 企业语音通话
        bordered = false,
        inspectDataSource,
        created_at,
    } = props;

    console.log('props', props);

    const textWithHighlight = useMemo(() => {
        if (!text) return text ?? '';
        const s = new MagicString(text);
        s.appendLeft(start, `<span style='color: red;'>`);
        s.appendLeft(start + length, '</span>');
        return s.toString();
    }, [text, length, start]);

    return (
        <div
            className="call-review-detail-page-index-list-item"
            style={{
                backgroundColor,
                borderBottom: bordered ? '1px solid rgba(0, 0, 0, 0.1)' : undefined,
            }}
        >
            <div
                className="call-review-detail-page-index-list-item-header"
                style={{ display: inspectDataSource === 14 ? 'block' : 'flex' }}
            >
                <div
                    style={{
                        maxWidth: 80,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                    title={nickName}
                >
                    {nickName ||
                        (fromSec
                            ? /* 客户 */ locales.components.sessionInsights.components.customer
                            : '-')}
                </div>

                {fromSec && (
                    <Button
                        type="link"
                        size="small"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                        }}
                        icon={<Icon type="ic_dingwei" />}
                        onClick={
                            typeof onClick === 'function'
                                ? () => onClick(fromSec, endSec)
                                : undefined
                        }
                    >
                        {chanelType === 1
                            ? null
                            : inspectDataSource === 14
                            ? created_at
                            : formatDuration(fromSec)}
                    </Button>
                )}
            </div>

            <div
                className="call-review-detail-page-index-list-item-content"
                dangerouslySetInnerHTML={{ __html: textWithHighlight }}
            ></div>

            {tagName && (
                <div style={{ color: '#000000', marginTop: 8 }}>{`${
                    /* 关键事件： */ locales.components.sessionInsights.components.keyEvents
                }${tagName}`}</div>
            )}
        </div>
    );
};
