// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerRecordByRecordIdDownLoadParam,
    InputStreamResource,
} from '../../../../types';

/**
 * 下载记录为文本
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [GET] /intelligentPartner/record/{recordId}/downLoad
 * @param recordId
 */
export function getIntelligentPartnerRecordByRecordIdDownLoad(
    options: ApiOptions<GetIntelligentPartnerRecordByRecordIdDownLoadParam, never> & {
        segments: GetIntelligentPartnerRecordByRecordIdDownLoadParam;
    }
): Promise<InputStreamResource> {
    return request<never, GetIntelligentPartnerRecordByRecordIdDownLoadParam, never>(
        '/intelligentPartner/record/{recordId}/downLoad',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerRecordByRecordIdDownLoad',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/record/{recordId}/downLoad',
        method: 'get',
    },
];
