import PieLikeClass from './pie-like';
import common from '../../../../udesk/index';

class FunnelOptionsBuilderClass extends PieLikeClass {

    _getChartType() {
        return common.enums.echartTypes.funnel.key;
    }

    _formatSeriesLabelValue(params, optionsOwner) {
        let {
            showSeriesLabels,
            seriesLabelContentMode,
        } = optionsOwner.props;

        if (showSeriesLabels) {
            if (seriesLabelContentMode === "recordValue") {
                return `${params.record.xLabel} : ${params.value}`;
            } else if (seriesLabelContentMode === "recordXLabel") {
                return params.record.xLabel;
            }
        }
    }

    _decorateSeries(series, optionsOwner) {
        series = super._decorateSeries(...arguments);
        let {
            funnelSort,
        } = optionsOwner.props;

        if (series != null) {
            for (var i = 0; i < series.length; i++) {
                let seriesItem = series[i];
                seriesItem.sort = funnelSort;
                seriesItem.width = "70%";
            }
        }
        return series;
        // return series.sort((a, b) => b.value - a.value);
    }
}

export default FunnelOptionsBuilderClass;
