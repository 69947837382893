// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListTask } from '../../types';

/**
 * queryTaskList
 *
 * @export
 * @tags inspection-home-page-controller
 * @link [GET] /inspectionHomePage/queryTaskList
 */
export function getInspectionHomePageQueryTaskList(): Promise<BaseResponseListTask>;
export function getInspectionHomePageQueryTaskList(
    options: ApiOptions<never, never>
): Promise<BaseResponseListTask>;
export function getInspectionHomePageQueryTaskList(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListTask> {
    return request<never, never, never>('/inspectionHomePage/queryTaskList', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getInspectionHomePageQueryTaskList',
    });
}

export const meta = [
    {
        tags: ['inspection-home-page-controller'],
        path: '/inspectionHomePage/queryTaskList',
        method: 'get',
    },
];
