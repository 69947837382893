// @ts-nocheck

import { request } from '../../../../../../../context';
import type { ApiOptions } from '../../../../../../../context';
import type {
    GetReviewCallAnalysisOrganizationByIdKeyEventsBestPractiseQuery,
    GetReviewCallAnalysisOrganizationByIdKeyEventsBestPractiseParam,
    BaseResponseListDataCallAnalysisOrgTagResponse,
} from '../../../../../../../types';

/**
 * keyEventsBestPractise
 *
 * @export
 * @tags 团队分析
 * @link [GET] /review/call/analysis/organization/{id}/keyEvents/bestPractise
 * @param id
 */
export function getReviewCallAnalysisOrganizationByIdKeyEventsBestPractise(
    options: ApiOptions<
        GetReviewCallAnalysisOrganizationByIdKeyEventsBestPractiseParam,
        GetReviewCallAnalysisOrganizationByIdKeyEventsBestPractiseQuery
    > & { segments: GetReviewCallAnalysisOrganizationByIdKeyEventsBestPractiseParam } & {
        params: GetReviewCallAnalysisOrganizationByIdKeyEventsBestPractiseQuery;
    }
): Promise<BaseResponseListDataCallAnalysisOrgTagResponse> {
    return request<
        never,
        GetReviewCallAnalysisOrganizationByIdKeyEventsBestPractiseParam,
        GetReviewCallAnalysisOrganizationByIdKeyEventsBestPractiseQuery
    >('/review/call/analysis/organization/{id}/keyEvents/bestPractise', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallAnalysisOrganizationByIdKeyEventsBestPractise',
    });
}

export const meta = [
    {
        tags: ['团队分析'],
        path: '/review/call/analysis/organization/{id}/keyEvents/bestPractise',
        method: 'get',
    },
];
