// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PutUsersSetLanguageBody, BaseResponseVoid } from '../../types';

/**
 * setLanguage
 *
 * @export
 * @tags 用户
 * @link [PUT] /users/setLanguage
 */
export function putUsersSetLanguage(data: PutUsersSetLanguageBody): Promise<BaseResponseVoid>;
export function putUsersSetLanguage(
    data: PutUsersSetLanguageBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putUsersSetLanguage(
    data: PutUsersSetLanguageBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutUsersSetLanguageBody, never, never>('/users/setLanguage', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putUsersSetLanguage',
    });
}

export const meta = [{ tags: ['用户'], path: '/users/setLanguage', method: 'put' }];
