// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostReviewIntelligentPartnerCourseCopyByIdParam,
    BaseResponseIntelligentPartnerCourseFoundResponse,
} from '../../../../../types';

/**
 * 拷贝课程
 *
 * @export
 * @tags 智能陪练-课程
 * @link [POST] /review/intelligentPartner/course/copy/{id}
 * @param id
 */
export function postReviewIntelligentPartnerCourseCopyById(
    options: ApiOptions<PostReviewIntelligentPartnerCourseCopyByIdParam, never> & {
        segments: PostReviewIntelligentPartnerCourseCopyByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerCourseFoundResponse> {
    return request<never, PostReviewIntelligentPartnerCourseCopyByIdParam, never>(
        '/review/intelligentPartner/course/copy/{id}',
        {
            ...options,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerCourseCopyById',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-课程'],
        path: '/review/intelligentPartner/course/copy/{id}',
        method: 'post',
    },
];
