// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { PostInspectionDataBatchDeleteBody, BaseResponseVoid } from '../../../types';

/**
 * batchDeleteInspectData
 *
 * @export
 * @tags 质检工作台
 * @link [POST] /inspectionData/batch/delete
 */
export function postInspectionDataBatchDelete(
    data: PostInspectionDataBatchDeleteBody
): Promise<BaseResponseVoid>;
export function postInspectionDataBatchDelete(
    data: PostInspectionDataBatchDeleteBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postInspectionDataBatchDelete(
    data: PostInspectionDataBatchDeleteBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostInspectionDataBatchDeleteBody, never, never>(
        '/inspectionData/batch/delete',
        { ...options, data, method: 'post', __$requestCalleeName: 'postInspectionDataBatchDelete' }
    );
}

export const meta = [
    { tags: ['质检工作台'], path: '/inspectionData/batch/delete', method: 'post' },
];
