import React, { useMemo, useState } from 'react';
import Udesk from 'Udesk';
import { Select, Icon, Tooltip } from 'udesk-ui';
import './style.scss';

const { Option } = Select;

const colorList = [
    '#5B8FF9',
    '#6DC9EC',
    '#FF9AC3',
    '#5AD8A6',
    '#FF9846',
    '#5B8FF9',
    '#6DC9EC',
    '#FF9AC3',
    '#5AD8A6',
    '#FF9846',
];

const UserVisibleSelect = (props) => {
    const { userList, value, onChange } = props;
    const { authority, authorityUser } = value;
    const [selectOpen, setSelectOpen] = useState(false);
    const tagRender = (props) => {
        const { label, value } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        const user = userList.find((u) => u.id === value);
        const SIZE = '26px';
        return (
            <div
                color={value}
                onMouseDown={onPreventMouseDown}
                title={label}
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: SIZE,
                    borderRadius: '50%',
                    height: SIZE,
                    lineHeight: SIZE,
                    textAlign: 'center',
                    background: user?.headPortraitUrl ? `url('${user.headPortraitUrl}')` : colorList[value % 10],
                    backgroundSize: 'contain',
                    border: '1px solid #fff',
                    color: '#fff',
                    // marginRight: '-10px'
                }}
            >
                {user?.headPortraitUrl ? '' : label}
            </div>
        );
    };

    const onAnyValueChange = (type, v) => {
        let newValue = {
            authority,
            authorityUser: [],
        };
        newValue[type] = v;
        onChange?.(newValue);
    };

    const userMap = useMemo(() => {
        let ret = {};
        userList.forEach((user) => {
            ret[user.value] = user.label;
        });
        return ret;
    }, [userList]);
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
                value={authority}
                onSelect={onAnyValueChange.bind(null, 'authority')}
                style={{ minWidth: 120, maxWidth: 150 }}
                // className='udesk-qa-comment-component-user-select'
                bordered={false}
                suffixIcon={<Icon type='CaretDownOutlined' antdIcon={true} style={{ color: 'rgba(0, 0, 0, 0.85)' }} />}
            >
                {Udesk.enums.commentVisibleAuthority.map((item) => (
                    <Option value={item.id}>
                        <Icon
                            style={{ fontSize: '16px', marginRight: 8 }}
                            type={
                                item.id === Udesk.enums.commentVisibleAuthority.self.id
                                    ? 'UserOutlined'
                                    : 'TeamOutlined'
                            }
                            antdIcon={true}
                        />
                        {item.name}
                    </Option>
                ))}
            </Select>
            {authority === Udesk.enums.commentVisibleAuthority.designation.id && (
                <Tooltip
                    visible={selectOpen ? false : undefined}
                    title={authorityUser
                        .map((i) => userMap[i])
                        .filter(Boolean)
                        .join(', ')}
                >
                    <Select
                        mode='multiple'
                        value={authorityUser}
                        className='udesk-qa-comment-component-user-select'
                        tagRender={tagRender}
                        style={{ flex: 1, marginRight: 36 }}
                        optionFilterProp='label'
                        options={userList}
                        maxTagCount={5}
                        defaultOpen={true}
                        autoFocus={true}
                        onDropdownVisibleChange={(open) => setSelectOpen(open)}
                        onChange={onAnyValueChange.bind(null, 'authorityUser')}
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default React.memo(UserVisibleSelect);
