import React, { useState, useMemo } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Button, Space, Popconfirm, Icon, Tag, Avatar } from 'udesk-ui';
import CommentReply, { CommentReplyProps } from '../comment-reply';
import './style.scss';
import { ReplayComment, ReplayCommentFoundResponse } from 'src/api/types';
import { formatDuration } from 'src/util/date';

interface ChildCommentItem extends Omit<ReplayComment, 'subReplayCommentList'> {}

export interface CommentItemProps extends ReplayCommentFoundResponse {
    isHideReply?: boolean; // 是否可回复
    textType?: string; // 消息类型
    time?: string; //对应通话的时间戳
    isTime?: boolean;
    helpUsers?: any[];
    text?: string; //回复的消息的摘要
    createUserUserName?: string; //评论创建人
    createTime?: string; //评论创建时间
    content?: string; //评论内容
    subReplayCommentList?: ChildCommentItem[]; //子评论或者回复
    replyUserName?: string; //回复谁
    headPortraitUrl?: string; //头像链接
    userList?: any[]; //可以被圈的人的列表
    topicsList?: any[]; //话题列表
    hiddenReplayAvatar?: boolean; //是否隐藏点击回复时弹出的回复组件里的头像
    wrapClassName?: string; //最外侧壳子的className
    replyClassName?: string; //回复的className
    createTimePlacement?: 'top' | 'bottom'; //创建时间显示的位置
    onReply?: CommentReplyProps['onPublish']; //回复评论
    onReplySuccess?: CommentReplyProps['onPublishSuccess']; //回复评论成功后的回调
    onDelete?: (item: any) => void; //删除按钮的回调，并控制删除评论按钮的显示
    onReplyDeleteSuccess?: CommentReplyProps['onPublishSuccess']; //回复评论成功后的回调
    onShowMoreClick?: (id: any) => void;
    onCommentTimeClick?: (fromSec: number, endSec: number) => void; //引用语句时间戳点击时的回调
    editorWrapperClassName?: string; // 富文本外层的类名
    replyComponentWrapperClassName?: string; // 回复组件外层的类名
    isChild?: boolean; // 是否是子评论
}

const CommentItem = React.memo((props: CommentItemProps) => {
    const {
        headPortraitUrl,
        onDelete,
        userList,
        topicsList,
        hiddenReplayAvatar = false,
        wrapClassName = '',
        replyClassName = '',
        totalReplyCount = 0,
        onReply,
        onReplySuccess,
        onReplyDeleteSuccess,
        createUserId,
        createTimePlacement = 'top',
        text: quote,
        onCommentTimeClick,
        isHelp: isHelpProps,
        isHideReply,
        editorWrapperClassName,
        replyComponentWrapperClassName,
        helpUsers,
        isChild,
    } = props;

    const locales = Locales['current'];
    const [isReply, setIsReply] = useState(false);
    const [replyPlaceholder, setReplyPlaceholder] = useState('');
    const subReplayCommentList = useMemo(
        () => props.subReplayCommentList ?? [],
        [props.subReplayCommentList]
    );

    const onReplyVisibleChange = () => {
        setReplyPlaceholder(`${locales.labels.reply} ${props.createUserUserName}`);
        setIsReply((prev) => !prev);
    };

    const showMoreChildComments = () => {
        // setShowCount((prev) => (typeof prev === 'number' ? undefined : DEFAULT_SHOW_COUNT));
        props.onShowMoreClick?.(props.id);
    };
    const changeHelpData = () => {
        let userName: any = [];
        helpUsers?.forEach((item) => {
            userName.push(item.realname);
        });
        return userName.join(',');
    };
    const moreChildCommentsCount = useMemo(() => {
        return Math.max((totalReplyCount || 0) - subReplayCommentList.length, 0);
    }, [totalReplyCount, subReplayCommentList]);
    // const onReplyWith
    const isHelp = useMemo(() => Boolean(isHelpProps), [isHelpProps]);
    return (
        <div className={`comment-display-item ${wrapClassName}`}>
            <div className="avatar" style={isHelp ? { background: '#E6F3FF' } : undefined}>
                {/* {headPortraitUrl ? <img src={headPortraitUrl} alt='' /> : <div></div>} */}
                <Avatar size={isChild ? 24 : 40} src={headPortraitUrl}>
                    {props.createUserUserName}
                </Avatar>
                {isHelp && <Icon type="a-bangzhuzhongxin1" />}
            </div>
            <div className="comment-display-item-body">
                <div className="comment-display-item-body-row">
                    <Space>
                        {/* <div>{props.id}</div> */}
                        <div className="user" title={props.createUserUserName}>
                            {props.createUserUserName}
                            {isHelp
                                ? ' ' +
                                  locales.components.gong.commentDispalyItem.index.concatText +
                                  ' ' +
                                  changeHelpData() +
                                  ' ' +
                                  locales.pages.gong.callDetail.index.seekCounseling
                                : ''}
                        </div>
                        {props.textType ? (
                            <div className="reply">{props.textType}</div>
                        ) : (
                            props.replyUserName && (
                                <React.Fragment>
                                    <div className="reply">{locales.labels.reply}</div>
                                    <div className="reply-to">{props.replyUserName}</div>
                                </React.Fragment>
                            )
                        )}
                        {/* {props.replyUserName && (
                            <React.Fragment>
                                <div className='reply'>{locales.labels.reply}</div>
                                <div className='reply-to'>{props.replyUserName}</div>
                            </React.Fragment>
                        )} */}
                    </Space>
                    {createTimePlacement === 'top' && (
                        <div className="create-time">{props.createTime}</div>
                    )}
                    {isHelp && (
                        <div>
                            <Tag style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                                {locales.components.gong.commentDispalyItem.index.seekAdvice}
                            </Tag>
                        </div>
                    )}
                </div>
                <div>
                    {props.time && <div className="time">{props.time}</div>}
                    {quote && (
                        <div className="quote" title={quote}>
                            {props.isTime ? (
                                props.replyUserName
                            ) : (
                                <Button
                                    onClick={() => {
                                        onCommentTimeClick?.(
                                            (props.beginTime ?? 0) / 1000,
                                            (props.endTime ?? 0) / 1000
                                        );
                                    }}
                                    type="link"
                                    size="small"
                                    style={{ marginRight: 8 }}
                                    icon={<Icon type="ic_dingwei" />}
                                >
                                    {/* chanelType： 0 电话  1 企业会话 2 企业语音通话  */}
                                    {formatDuration((props.beginTime ?? 0) / 1000)}
                                </Button>
                            )}
                            {quote}
                        </div>
                    )}
                </div>
                <div className="content" title={props.content}>
                    {props.content}
                </div>
                {!isHideReply ? (
                    <div>
                        <Space>
                            <Button onClick={onReplyVisibleChange} size="small" type="link">{`${
                                isReply ? locales.labels.cancel : ''
                            }${locales.labels.reply}`}</Button>
                            {onDelete && Udesk.data.init.user.id === createUserId && !isHelp && (
                                <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={onDelete?.bind?.(null, props)}
                                    onCancel={(e) => e!.stopPropagation()}
                                    okText={locales.labels.confirm}
                                    placement="topRight"
                                    cancelText={locales.labels.cancel}
                                >
                                    <Button size="small" type="link" danger={true}>
                                        {locales.labels.delete}
                                    </Button>
                                </Popconfirm>
                            )}
                        </Space>
                        {createTimePlacement === 'bottom' && props.createTime && (
                            <div className="create-time">{props.createTime}</div>
                        )}
                    </div>
                ) : null}
                {isReply && (
                    <div className="comment-display-item-body-reply">
                        <CommentReply
                            userList={userList}
                            topicsList={topicsList}
                            hiddenAvatar={hiddenReplayAvatar}
                            placeholder={replyPlaceholder}
                            onPublish={(...args) => {
                                return new Promise((res, rej) => {
                                    onReply?.(...args)
                                        .then((resp) => {
                                            setIsReply(false);
                                            res(resp);
                                        })
                                        .catch((e) => {
                                            rej(e);
                                        });
                                });
                            }}
                            sourceCommentId={props.sourceCommentId}
                            replyCommentId={props.replyCommentId}
                            onPublishSuccess={onReplySuccess}
                            wrapperClassName={replyComponentWrapperClassName}
                            editorWrapperClassName={editorWrapperClassName}
                        />
                    </div>
                )}
                {subReplayCommentList.length > 0 && (
                    <div className="child-comments">
                        {subReplayCommentList.map((item) => {
                            return (
                                <CommentItem
                                    {...item}
                                    hiddenReplayAvatar={hiddenReplayAvatar}
                                    userList={userList}
                                    topicsList={topicsList}
                                    wrapClassName={replyClassName}
                                    onReply={onReply}
                                    sourceCommentId={props.id}
                                    replyCommentId={item.id}
                                    onReplySuccess={onReplySuccess}
                                    onReplyDeleteSuccess={onReplyDeleteSuccess}
                                    onDelete={onDelete}
                                    createTimePlacement={createTimePlacement}
                                    editorWrapperClassName="udesk-qa-ui-input"
                                    isChild={true}
                                />
                            );
                        })}
                    </div>
                )}
                {moreChildCommentsCount > 0 && (
                    <div className="child-comments">
                        <Button onClick={showMoreChildComments} size="small" type="link">
                            {Udesk.utils.string.format(
                                locales.labels.expandMore,
                                moreChildCommentsCount
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
});
export default React.memo(CommentItem);
