// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostReviewShowFieldsBody,
    BaseResponseListTableShowFieldsFoundResponse,
} from '../../types';

/**
 * batchSave
 *
 * @export
 * @tags 销售赋能列表列字段管理
 * @link [POST] /review/showFields
 */
export function postReviewShowFields(
    data: PostReviewShowFieldsBody
): Promise<BaseResponseListTableShowFieldsFoundResponse>;
export function postReviewShowFields(
    data: PostReviewShowFieldsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListTableShowFieldsFoundResponse>;
export function postReviewShowFields(
    data: PostReviewShowFieldsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListTableShowFieldsFoundResponse> {
    return request<PostReviewShowFieldsBody, never, never>('/review/showFields', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewShowFields',
    });
}

export const meta = [
    { tags: ['销售赋能列表列字段管理'], path: '/review/showFields', method: 'post' },
];
