import React, { useEffect } from 'react';
import { useState } from 'react';
import { InputNumber, Radio, Space } from 'udesk-ui';
import Locales from 'UdeskLocales';
interface DurationLimitValue {
    status?: number;
    inputValue?: string;
}

interface DurationLimitProps {
    disabled?: boolean;
    value?: DurationLimitValue;
    onChange?: (value?: DurationLimitValue) => void;
}

const DurationLimit: React.FC<DurationLimitProps> = (props) => {
    const locales = Locales['current'];
    const { value, onChange, disabled } = props;
    const [status, setStatus] = useState(1);
    const [inputValue, setInputValue] = useState<string | undefined>();

    const triggerChange = (changedValue) => {
        const newValue = {
            status,
            inputValue,
            ...changedValue,
        };
        if (newValue.status === 2 && !newValue.inputValue) {
            onChange?.(undefined);
        } else {
            onChange?.(newValue);
        }
    };

    const onStatusChange = (e) => {
        setStatus(e.target.value);
        triggerChange({ status: e.target.value });
    };

    const onInputValueChange = (val) => {
        setInputValue(val);
        triggerChange({ inputValue: val });
    };

    useEffect(() => {
        if (value) {
            setStatus(value?.status || 1);
            setInputValue(value?.inputValue);
        }
    }, [value]);

    return (
        <div>
            <Radio.Group disabled={disabled} value={value?.status || status} onChange={onStatusChange}>
                <Radio value={1}>{locales.pages.gong.coachTaskCenter.edit.offLimitDuration}</Radio>
                <Radio value={2}>{locales.pages.gong.coachTaskCenter.edit.onLimitDuration}</Radio>
            </Radio.Group>
            {(value?.status || status) === 2 && (
                <Space align="center">
                    <InputNumber
                        disabled={disabled}
                        style={{ width: 100, marginLeft: 10 }}
                        value={value?.inputValue || inputValue}
                        onChange={onInputValueChange}
                    />
                    <span>{locales.labels.minutes}</span>
                </Space>
            )}
        </div>
    );
};

export default DurationLimit;
