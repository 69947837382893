import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'udesk-ui';
import { getOrganizations } from 'src/api/organizations';
import { TreeMenu } from 'udesk_gm_ui';
import { TreeSelectProps } from 'udesk-ui/es/tree-select';
// import { DefaultValueType } from 'rc-tree-select/lib/interface';
type Key = string | number;

type RawValueType = string | number;
interface LabelValueType {
    key?: Key;
    value?: RawValueType;
    label?: React.ReactNode;
    /** Only works on `treeCheckStrictly` */
    halfChecked?: boolean;
}
type DefaultValueType = RawValueType | RawValueType[] | LabelValueType | LabelValueType[];

type DepartmentSelectProps<T> = Omit<TreeSelectProps<T>, 'treeData'>;

const Template = React.memo(<T extends DefaultValueType>(props: DepartmentSelectProps<T>) => {
    const [userGroup, setUserGroup] = useState<any[]>([]);

    useEffect(() => {
        getOrganizations().then((res) => {
            setUserGroup(
                TreeMenu.formatTreeDataFromArrayToTree(
                    (res.data ?? [])
                        .filter((i) => i.id)
                        .map((i) => ({
                            ...i,
                            title: i.name,
                            key: i.id!,
                        })),
                    0,
                    'parentId'
                )
            );
        });
    }, []);

    return (
        <TreeSelect
            showSearch
            allowClear
            multiple
            treeDefaultExpandAll
            treeNodeFilterProp='name'
            maxTagCount='responsive'
            treeCheckable={true}
            {...props}
            treeData={userGroup}
        ></TreeSelect>
    );
});

export default Template;
