// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerHomePageStudentTaskSummaryQuery,
    BaseResponseIntelligentPartnerHomePageTaskSummary,
} from '../../../../types';

/**
 * 学员端任务小结接口
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [GET] /intelligentPartner/homePage/student/taskSummary
 */
export function getIntelligentPartnerHomePageStudentTaskSummary(
    options: ApiOptions<never, GetIntelligentPartnerHomePageStudentTaskSummaryQuery> & {
        params: GetIntelligentPartnerHomePageStudentTaskSummaryQuery;
    }
): Promise<BaseResponseIntelligentPartnerHomePageTaskSummary> {
    return request<never, never, GetIntelligentPartnerHomePageStudentTaskSummaryQuery>(
        '/intelligentPartner/homePage/student/taskSummary',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerHomePageStudentTaskSummary',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/student/taskSummary',
        method: 'get',
    },
];
