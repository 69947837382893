import React from 'react';
import Udesk from 'Udesk';
import { Switch } from 'udesk-ui';

// const UNITS_LIST = ['K', 'M', 'G'];

export default class ProfessionalWordsComponent extends React.Component {
    static defaultProps = {};
    static propTypes = {};

    // #region privates
    privates = {
        navItemType: Udesk.enums.smartWordsLibraryMenus.professionalWords.id,
        navItemName: Udesk.enums.smartWordsLibraryMenus.professionalWords.key,
        isCommonWordsModalShow: false,
        commonWordsModalTitle: '',
        corporaName: '',
        hotWordReplaceStatus: '1',
        targetWordReplaceStatus: '1',
        commonWordsId: '',
        smartWordsLibraryListInstance: null,
        targetWords: [],
        newTargetWords: '',
        newTargetWordsIsNull: false,
        pleaseAddTargetWords: false,
        targetWordsExists: false,
        homophonyWords: [],
        newHomophonyWord: '',
    };
    //#endregion

    // #region cycles
    init() {
        let columns = this.getColumns();
        this.privates.columns = columns;
        // this.actions.update();
    }
    componentWillUnmount() {
        this.privates.smartWordsLibraryListInstance = null;
    }
    //#endregion

    // #region actions
    actions = {
        changeTargetWords(e) {
            this.privates.newTargetWords = e.target.value.trim();
            let { newTargetWords, targetWords } = this.privates;
            if (targetWords !== null && targetWords.length > 0) {
                this.privates.newTargetWordsIsNull = false;
                this.privates.pleaseAddTargetWords = false;
                this.privates.targetWordsExists = false;
                if (newTargetWords && targetWords.indexOf(newTargetWords) !== -1) {
                    this.privates.targetWordsExists = true;
                }
            }
            this.actions.update();
        },
        changeHomophonyWords(e) {
            this.privates.newHomophonyWord = e.target.value.trim();
            this.actions.update();
        },
        pressEnter(e) {
            if (e.keyCode === 13 || e.which === 13 || e.charCode === 13) {
                this.actions.addOneTargetWords();
            }
            return;
        },
        pressHomophonyWordsEnter(e){
            if (e.keyCode === 13 || e.which === 13 || e.charCode === 13) {
                this.actions.addOneHomophonyWords();
            }
            return;
        },
        addOneHomophonyWords() {
            let { newHomophonyWord, homophonyWords } = this.privates;

            if (homophonyWords && homophonyWords.length >= 100) {
                Udesk.ui.notify.error(
                    this.locales.components.pages.smartWordsLibrary.professionalWords.addHomophonyWordsMaxNumberError
                );
                return;
            }

            newHomophonyWord && this.privates.homophonyWords.push(newHomophonyWord);
            this.privates.newHomophonyWord = '';
            this.actions.update();
        },
        addOneTargetWords() {
            let { newTargetWords, targetWords } = this.privates;

            if (targetWords && targetWords.length >= 100) {
                Udesk.ui.notify.error(
                    this.locales.components.pages.smartWordsLibrary.professionalWords.addTargetWordsMaxNumberError
                );
                return;
            }

            if (!newTargetWords || newTargetWords.trim().length === 0) {
                this.privates.newTargetWordsIsNull = true;
                this.privates.pleaseAddTargetWords = false;
                this.privates.targetWordsExists = false;
                this.actions.update();
                return;
            }
            if (newTargetWords && targetWords.indexOf(newTargetWords) !== -1) {
                this.privates.targetWordsExists = true;
                this.privates.newTargetWordsIsNull = false;
                this.privates.pleaseAddTargetWords = false;
                this.actions.update();
                return;
            }

            this.privates.targetWords.push(newTargetWords);
            this.privates.pleaseAddTargetWords = false;
            this.privates.newTargetWordsIsNull = false;
            this.privates.targetWordsExists = false;
            this.privates.newTargetWords = '';
            this.actions.update();
        },
        deleteOneTargetWords(index) {
            this.privates.targetWords.splice(index, 1);

            let { targetWords } = this.privates;

            if (targetWords.length === 0) {
                this.privates.targetWordsExists = false;
                this.privates.pleaseAddTargetWords = true;
                this.privates.newTargetWordsIsNull = false;
            }

            this.actions.update();
        },
        deleteOneHomophonyWords(index) {
            this.privates.homophonyWords.splice(index, 1);
            this.actions.update();
        },

        createCommonWords() {
            let { locales } = this;

            let { navItemName } = this.privates;

            this.privates.isCommonWordsModalShow = true;
            this.privates.commonWordsModalTitle = locales.components.pages.smartWordsLibrary[navItemName].create;
            this.actions.update();
        },
        editCommonWords(id) {
            let { locales } = this;

            let { sdkOptions } = this.props;

            let { navItemName } = this.privates;

            let url = Udesk.business.apiPath.concatApiPath(`corpora/terminology/${id}`, sdkOptions);

            Udesk.ajax.get(url).then(
                (resp, textStatus, jqXHR) => {
                    let responseItem = $.extend(true, {}, resp.data);
                    this.privates.isCommonWordsModalShow = true;
                    this.privates.commonWordsModalTitle = locales.components.pages.smartWordsLibrary[navItemName].edit;
                    this.privates.corporaName = responseItem.corporaName;
                    this.privates.hotWordReplaceStatus = responseItem.hotWordReplaceStatus + '';
                    this.privates.targetWordReplaceStatus = responseItem.targetWordReplaceStatus + '';
                    this.privates.targetWords = responseItem.targetWords || [];
                    this.privates.homophonyWords = responseItem.ignoreWords || [];
                    this.privates.commonWordsId = responseItem.id;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.components.pages.smartWordsLibrary[navItemName].deleteErr
                    );
                }
            );
        },
        closeCommonWordsModal() {
            this.privates.newTargetWords = '';
            this.privates.targetWords = [];
            this.privates.corporaName = '';
            this.privates.hotWordReplaceStatus = '1';
            this.privates.targetWordReplaceStatus = '1';
            this.privates.isCommonWordsModalShow = false;
            this.privates.commonWordsId = '';
            this.privates.newTargetWordsIsNull = false;
            this.privates.pleaseAddTargetWords = false;
            this.privates.targetWordsExists = false;
            this.actions.update();
        },
        deleteCommonWords(id) {
            let that = this;

            let { sdkOptions } = this.props;

            let { navItemName } = this.privates;

            Udesk.ui.alerts.warning({
                content: this.locales.labels.deleteContent,
                onOk: () => {
                    Udesk.ajax.del(`${Udesk.business.apiPath.concatApiPath(`corpora/${id}`, sdkOptions)}`).then(
                        (resp, textStatus, jqXHR) => {
                            Udesk.ui.notify.success(
                                this.locales.components.pages.smartWordsLibrary[navItemName].deleteSuccess
                            );
                            that.privates.smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    this.locales.components.pages.smartWordsLibrary[navItemName].deleteError
                            );
                        }
                    );
                },
            });
        },
        saveCommonWords() {
            let isEdit = false;
            if (this.privates.commonWordsId) {
                isEdit = true;
            }

            let { targetWords, targetWordReplaceStatus, homophonyWords } = this.privates;

            if (targetWordReplaceStatus === '1' && (targetWords === null || targetWords.length === 0)) {
                this.privates.pleaseAddTargetWords = true;
                this.privates.targetWordsExists = false;
                this.privates.targetWordsExists = false;
                this.actions.update();
                return;
            } else {
                this.privates.pleaseAddTargetWords = false;
                this.privates.targetWordsExists = false;
                this.privates.targetWordsExists = false;
                this.actions.update();
            }

            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let url = '';
                    let { locales } = this;
                    let { sdkOptions } = this.props;

                    let { navItemType, navItemName, hotWordReplaceStatus } = this.privates;

                    let params = Object.assign({}, values, {
                        corporaStatus: '1',
                        corporaType: navItemType,
                        hotWordReplaceStatus,
                        targetWordReplaceStatus,
                        targetWords,
                        ignoreWords: homophonyWords,
                    });

                    let title = isEdit
                        ? locales.components.pages.smartWordsLibrary[navItemName].editSuccess
                        : locales.components.pages.smartWordsLibrary[navItemName].createSuccess;

                    let requestType = isEdit ? 'put' : 'post';

                    if (isEdit) {
                        let id = this.privates.commonWordsId;
                        url = Udesk.business.apiPath.concatApiPath(`corpora/terminology/${id}`, sdkOptions);
                    } else {
                        url = Udesk.business.apiPath.concatApiPath('corpora/terminology', sdkOptions);
                    }

                    let that = this;

                    Udesk.ajax[requestType](url, params).then(
                        (resp, textStatus, jqXHR) => {
                            this.privates.newHomophonyWord = '';
                            that.actions.closeCommonWordsModal();
                            Udesk.ui.notify.success(title);
                            that.privates.smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            this.privates.newHomophonyWord = '';
                            this.actions.update();
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    that.locales.components.pages.smartWordsLibrary[navItemName].saveError
                            );
                        }
                    );
                } else {
                    return;
                }
            });
        },
        changeHotWordReplaceStatus(value) {
            this.privates.hotWordReplaceStatus = value;
            this.actions.update();
        },
        changeTargetWordReplaceStatus(value) {
            this.privates.targetWordReplaceStatus = value;
            this.actions.update();
        },
        onOffStatus(target, id, value) {
            let { locales } = this;

            let { sdkOptions } = this.props;

            let { navItemName } = this.privates;

            let params = {};
            params[target] = value ? 1 : 0;
            let url = Udesk.business.apiPath.concatApiPath(`corpora/terminologySwitch/${id}`, sdkOptions);
            Udesk.ajax.put(url, params).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(locales.components.pages.smartWordsLibrary[navItemName].editSuccess);
                    this.privates.smartWordsLibraryListInstance.actions.refresh();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || locales.components.pages.smartWordsLibrary[navItemName].saveError
                    );
                }
            );
        },
    };
    //#endregion

    // #region public methods used "this":
    getColumns() {
        let { locales } = this;

        let columns = [
            {
                name: 'corporaName',
                width: '10%',
                caption: locales.fields.smartWordsLibrary.professionalWords.professionalWords,
            },
            {
                name: 'hotWordReplaceStatus',
                width: '10%',
                caption: locales.fields.smartWordsLibrary.professionalWords.homophonic,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return (
                        <Switch
                            size='small'
                            checked={item.hotWordReplaceStatus === 1}
                            onChange={this.actions.onOffStatus.params('hotWordReplaceStatus', item.id)}
                        />
                    );
                },
            },
            {
                name: 'targetWordReplaceStatus',
                width: '10%',
                caption: locales.fields.smartWordsLibrary.professionalWords.target,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return (
                        <Switch
                            size='small'
                            checked={item.targetWordReplaceStatus === 1}
                            onChange={this.actions.onOffStatus.params('targetWordReplaceStatus', item.id)}
                        />
                    );
                },
            },
            // {
            //     name: 'corporaRate',
            //     width: '10%',
            //     caption: locales.fields.smartWordsLibrary.professionalWords.useFrequency,
            //     hasTemplate: true,
            //     getYieldContent: (name, item, index) => {
            //         return (
            //             <span>{Udesk.business.smartWordsMethods.getFormatFrequency(item.corporaRate, UNITS_LIST)}</span>
            //         );
            //     },
            // },
            {
                name: 'operation',
                width: '15%',
                caption: locales.fields.smartWordsLibrary.professionalWords.operation,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return (
                        <div className='smart-words-library-professional-words-operation'>
                            <If
                                condition={
                                    Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:corpora:edit')
                                }
                            >
                                <span
                                    className='smart-words-library-operation-edit'
                                    title={locales.labels.edit}
                                    onClick={this.actions.editCommonWords.params(item.id)}
                                >
                                    {locales.labels.edit}
                                </span>
                            </If>
                            <If
                                condition={
                                    Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:corpora:delete')
                                }
                            >
                                <span
                                    className='smart-words-library-operation-delete'
                                    title={locales.labels.delete}
                                    onClick={this.actions.deleteCommonWords.params(item.id)}
                                >
                                    {locales.labels.delete}
                                </span>
                            </If>
                        </div>
                    );
                },
            },
        ];
        return columns;
    }
}
