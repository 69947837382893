import React from 'react';
// import Locales from 'UdeskLocales';
import styled from 'styled-components';
import { Switch } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    > div {
        height: 32px;
        line-height: 32px;
    }
`;

export const DetailedInquiryConfigTitle = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { value, onChange } = props;
    return (
        <TitleWrap className="udesk-coach-web-detailed-inquiry-title">
            <div>{/* 追问设置 */}{UdeskLocales['current'].components.coach.detailedInquiryConfig.components.detailedInquiryConfigTitle.index.questioningSettings}</div>
            {/* enableQuestionClosely */}
            <Switch size="small" checked={value} onChange={onChange} />
        </TitleWrap>
    );
});
