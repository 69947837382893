// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetIntelligentPartnerStudyTaskListQuery,
    BaseResponseListIntelligentPartnerTaskInfoFoundResponse,
} from '../../types';

/**
 * 获取任务列表分页
 *
 * @export
 * @tags 智能陪练学习任务接口
 * @link [GET] /intelligentPartnerStudyTask/list
 */
export function getIntelligentPartnerStudyTaskList(): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse>;
export function getIntelligentPartnerStudyTaskList(
    options: ApiOptions<never, GetIntelligentPartnerStudyTaskListQuery>
): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse>;
export function getIntelligentPartnerStudyTaskList(
    options?: ApiOptions<never, GetIntelligentPartnerStudyTaskListQuery>
): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse> {
    return request<never, never, GetIntelligentPartnerStudyTaskListQuery>(
        '/intelligentPartnerStudyTask/list',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentPartnerStudyTaskList' }
    );
}

export const meta = [
    { tags: ['智能陪练学习任务接口'], path: '/intelligentPartnerStudyTask/list', method: 'get' },
];
