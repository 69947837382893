import React, { useState, useCallback, useMemo } from 'react';
import Udesk from 'Udesk';
import ItemForm from './pageRightFrom';
import UdeskLocales from 'UdeskLocales';
import CustomFilter from './customerFilter';
import CallDetailModal from '../callDetailModal';
import { Button, Table, Popconfirm, message, Space, Tabs } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { useStorageProps } from 'src/util/component';
import { inspect, isDefined } from 'src/util/core';
import { hasFeature } from 'src/util/permit';
import { linkTo } from 'src/util/link';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import './index.scss';

const DelBtn = (props: {
    locales: any;
    onDelete: () => void;
}) => {
    const {onDelete, locales} = props;
    return (
        <Popconfirm
            title={/* 您确认要舍弃吗？舍弃后将不能再恢复 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.index.areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding}
            onConfirm={onDelete}
            onCancel={(e) => e?.stopPropagation()}
            okText={locales.labels.confirm}
            placement='bottom'
            cancelText={locales.labels.cancel}
        >
            <Button size="small">{/* 舍弃 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.index.abandonment}</Button>
        </Popconfirm>
    );
};

export default React.memo((props: any) => {
    const {history}= props;
    const locales = UdeskLocales['current'];
    const [loading, setLoading] = useState(false);
    const [tabsActiveKey, setTabsActiveKey] = useState('1');
    const [selectedRow, setSelectedRow] = useState<any>();
    const [selectedRowForLeftPage] = useStorageProps(props, 'leftPageSelectedRow');
    const [selectedRowForCenterPage] = useStorageProps(props, 'centerPageSelectedRow');
    const [pagination, setPagination] = useStorageProps(props, 'rightPagePagination');
    const [judgeStrategy, setJudgeStrategy] = useStorageProps(props, 'judgeStrategy', Udesk.enums.operatorRuleTypes.all.id, false);
    const [conditionList, setConditionList] = useStorageProps(props, 'conditionList', [], false);
    const [customJudgeLogic, setCustomJudgeLogic] = useStorageProps(props, 'customJudgeLogic', '');
    const [data, setData] = useState<any[]>([]);
    
    const request = useRequest();

    const [visible, setVisible] = useState(false);

    const scenario = useMemo(() => {
        return history.location.pathname.indexOf('dissent') > 0 ? Udesk.enums.applicationScenarios.objection.id : Udesk.enums.applicationScenarios.sellingPoints.id;
    }, [history.location.pathname]);

    const isAcceptedPage = useMemo(() => tabsActiveKey === '2', [tabsActiveKey]);

    const onTabsActiveKeyChange = useCallback((key) => {
        setPagination(pagination => {
            return {
                ...pagination, current: 1,
            };
        });
        setTabsActiveKey(key);
    }, [setPagination]);

    const delHandle = useCallback((id) => {
        let {current, pageSize, total} = pagination;
        request(`/review/clusterResult/${id}`, {}, 'del').then((r) => {
            message.success(/* 删除成功 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.index.deletionSucceeded);
            if (current > 1) {
                if ((current - 1) * pageSize >= (total - 1)) {
                    current--;
                } else {
                    setData((list) => {
                        return list.filter(item => item.key !== id);
                    });
                }
                setPagination({
                    current,
                    pageSize,
                    total: total -1
                });
            } else {
                setData((list) => {
                    return list.filter(item => item.key !== id);
                });
            }
        });
    }, [
        locales,
        pagination, 
        request, 
        setPagination
    ]);

    const changeEditStatus = useCallback((item, index, flag) => {
        setData((data) => {
            const list = [...data];
            if (list[index] === item) {
                item.isEdit = flag;
            }
            return list;
        });
    }, []);

    const linkFavorite = () => {
        linkTo(history, getCurrentRouteName('sellingPointFavoriteWordsMining'));
    };

    const renderCell = useCallback((text, record, index) => {
        return (
            <div className='page-right-table-item'>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            {
                                inspect(record.isEdit, () => (
                                    <ItemForm
                                        key={record.id} 
                                        locales={locales}
                                        scenario={scenario}
                                        request={request} data={record}
                                        successCallback={() => {
                                            changeEditStatus(record, index, false);
                                        }}/>
                                ), () => (
                                    <ItemForm 
                                        locales={locales}
                                        scenario={scenario}
                                        request={request} data={record}/>
                                ))
                            }
                        </Space>
                    </div>

                    <div className="toolkit-button">
                        {
                            inspect(record.isEdit, () => (
                                <Space align="end">
                                    <Button 
                                        onClick={() => record.form?.submit()}
                                        size="small" 
                                        type='primary' 
                                    >{/* 确定 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.index.determine}</Button>
                                    <Button 
                                        onClick={() => changeEditStatus(record, index, false)}
                                        size="small" 
                                    >{/* 取消 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.index.cancel}</Button>
                                </Space>
                            ), () => (
                                <Space align="end">
                                    <Button 
                                        disabled={!isDefined(record?.callId)}
                                        size="small" type='link' 
                                        onClick={() => {
                                            setVisible(true);
                                            setSelectedRow(record);
                                        }}
                                    >{/* 查看详情 */}{UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.pageRightTable.index.viewDetails}</Button>
                                    <DelBtn 
                                        locales={locales} 
                                        onDelete={() => delHandle(record.id)} /> 
                                    {
                                        inspect(!isAcceptedPage && !record.accept, () => (
                                            <Button 
                                                onClick={() => changeEditStatus(record, index, true)}
                                                size="small" 
                                                type='primary' 
                                            >
                                                {/* 采纳 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.index.adopt}
                                            </Button>
                                        ))
                                    }
                                </Space>
                            ))
                        }
                    </div>
                </Space>
            </div>
        );
    }, [
        isAcceptedPage,
        scenario, 
        request, 
        locales, 
        changeEditStatus, 
        delHandle
    ]);

    const requestTableSource = useCallback((success) => {
        if (selectedRowForCenterPage?.id) {
            let url = inspect(isAcceptedPage, `review/clusterResult/accepted/${selectedRowForCenterPage.id}`, `review/clusterResult/${selectedRowForCenterPage.id}`);
            let params = inspect(isAcceptedPage, () => ({
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            }), () => ({
                scenario,
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            }));
            setLoading(true);
            request(url, params).then((resp) => {
                success(() => {
                    setData(resp.data.map((item) => ({
                        ...item,
                        key: item.id,
                        isEdit: false,
                        wordsList: [item.answer],
                        clusterId: selectedRowForCenterPage?.clustId,
                        keyword: selectedRowForLeftPage?.keyword
                    })));
                    setPagination((pagination) => ({
                        ...pagination, total: resp.paging.total,
                    }));
                });
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setData([]);
            setPagination((pagination) => ({
                ...pagination, current: 1, total: 0, 
            }));
        }
    }, [
        scenario,
        isAcceptedPage,
        pagination.current,
        pagination.pageSize,
        request, 
        selectedRowForLeftPage?.keyword,
        selectedRowForCenterPage?.id, 
        selectedRowForCenterPage?.clustId,
        setPagination,
    ]);

    const onCheckFormSubmit = useCallback((list) => {
        if (selectedRow) {
            selectedRow.wordsList = list.map(item => {
                return JSON.parse(item.content).data.content;
            });
            changeEditStatus(selectedRow, data.findIndex(item => item === selectedRow), true);
            setVisible(false);
        }
    }, [
        changeEditStatus, 
        data, 
        selectedRow
    ]);

    useLiveEffect(requestTableSource, [requestTableSource]);
    
    return (
        <div className='selling-point-dissent-page-right'>
            <div>
                <CallDetailModal
                    selectedRow={selectedRow} 
                    request={request} 
                    visible={visible} 
                    setVisible={setVisible}
                    onCheckFormSubmit={onCheckFormSubmit}
                />
                {
                    hasFeature("semantic:WordsFavorite:root", () => <Button onClick={linkFavorite} type='primary'>{/* 话术库 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.pageRightTable.index.scriptLibrary}</Button>)
                }
            </div>
            <div>
                <Table
                    scroll={{
                        y: 'calc(100% - 50px)'
                    }}
                    loading={loading}
                    rowSelection={{
                        columnTitle: (
                            <Tabs 
                                activeKey={tabsActiveKey}
                                onChange={onTabsActiveKeyChange}
                                tabBarExtraContent={
                                    inspect(selectedRowForCenterPage?.id, () => (
                                        <CustomFilter {...{
                                            history,
                                            locales,
                                            selectedRowKey: selectedRowForCenterPage?.id,
                                            judgeStrategy,
                                            conditionList,
                                            customJudgeLogic,
                                            setJudgeStrategy,
                                            setConditionList,
                                            setCustomJudgeLogic,
                                            onFilterComplete: requestTableSource,
                                        }}/>
                                    ))
                                }
                            >
                                <Tabs.TabPane tab={/* 话术提炼 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.pageRightTable.index.scriptRefinement} key="1" />
                                <Tabs.TabPane tab={/* 采纳话术 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.pageRightTable.index.adoptScript} key="2" />
                            </Tabs>
                        ),
                        renderCell,
                    }}
                    pagination={{
                        ...pagination,
                        hideOnSinglePage: true,
                        onChange: (current) => {
                            setPagination({ ...pagination, current });
                        },
                    }} 
                    columns={[]}
                    dataSource={data}
                />
            </div>
        </div>
    );
});
