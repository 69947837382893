import React, { useEffect, useState } from 'react';
import { CustomerPortraitList } from 'Component/pages/components/coach/CustomerPortrait/List';
import { getReviewIntelligentPartnerNodeInputSceneConfigTemplate } from 'src/api/review/intelligentPartner/node/inputSceneConfig/template';
import {
    IntelligentPartnerCustomerPersonaFoundResponse,
    IntelligentPartnerDialogNodeAiTemplateFoundResponse,
} from 'src/api/types';

interface SimulatedRoleSelectProps {
    onChange?: (newValue: any[]) => void;
    value?: any[];
}

const SimulatedRoleSelect = React.memo<SimulatedRoleSelectProps>((props) => {
    const [allCustomerPortrait, setAllCustomerPortrait] = useState<
        IntelligentPartnerCustomerPersonaFoundResponse[]
    >([]);

    useEffect(() => {
        getReviewIntelligentPartnerNodeInputSceneConfigTemplate().then((res) => {
            setAllCustomerPortrait(transform(res.data || []));
        });
    }, []);

    const onChange = (id, checkable) => {
        const temp = allCustomerPortrait.filter((p) => p.id === id);
        props.onChange?.(
            temp.map((t) => ({
                simulatedRoleDesc: t.name,
                templateRoleId: t.id,
            }))
        );
    };

    return (
        <CustomerPortraitList
            allCustomerPortrait={allCustomerPortrait}
            value={
                (props.value || [])
                    .map((v) => v.templateRoleId)
                    .filter(Boolean) as unknown as number[]
            }
            onChange={onChange}
            addable={false}
        />
    );
});

const transform = (
    data: IntelligentPartnerDialogNodeAiTemplateFoundResponse[]
): IntelligentPartnerCustomerPersonaFoundResponse[] => {
    return data.map((d) => {
        return {
            ...d,
            name: d.simulatedRole,
        };
    });
};

export { SimulatedRoleSelect };
