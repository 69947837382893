import React from 'react';
import TasksConditionDetailComponent from './component';
import TaskCheckRange from 'Component/pages/components/task-check-range';
import TaskConditionDictionaries from 'Component/pages/components/task-condition-dictionaries';
import TaskOperatorListGather from 'Component/pages/components/task-operator-list-gather';
import './style.scss';
import { PageHeader } from 'udesk-ui';

export default class TasksConditionDetailTemplate extends TasksConditionDetailComponent {
    render() {
        const { actions, locales, props, state, privates } = this;
        const hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
        return (
            <React.Fragment>
                {/* <div className="page sub-page tasks-manage-template-condition-detail"> */}
                <div className='udesk-qa-web-page'>
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        onBack={actions.historyBack}
                        title={privates.computes.isEditing ? locales.pages.tasks.manage.template.condition.index.name + '/' + locales.labels.edit : locales.pages.tasks.manage.template.condition.index.name + '/' + locales.labels.create}
                    />
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root tasks-manage-template-condition-detail' style={{ padding: 16 }}>
                            <div className="page-content">
                                <div className="x_panel clearfix">
                                    <div className="x_content clearfix">
                                        <div className="col-lg-8 col-md-8 col-xs-12 col-12">
                                            <If condition={
                                                (privates.computes.isEditing && hasFeature &&
                                                    hasFeature('task:template:condition:edit')) ||
                                                (!privates.computes.isEditing && hasFeature &&
                                                    hasFeature('task:template:condition:add'))
                                            }>
                                                <form className="form-horizontal form-label-left">
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                            style={{ padding: "0 15px" }}
                                                            htmlFor="condition_id">
                                                            {locales.pages.tasks.manage.template.condition.detail.id}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                            <input
                                                                type="text"
                                                                id="condition_id"
                                                                className="form-control"
                                                                maxLength="50"
                                                                value={privates.model.condition.idCode}
                                                                onChange={actions.inputMutator(
                                                                    'privates.model.condition.idCode',
                                                                    true
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                            style={{ padding: "0 15px" }}
                                                            htmlFor="condition_name">
                                                            {locales.pages.tasks.manage.template.condition.detail.name}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                            <input
                                                                type="text"
                                                                id="condition_name"
                                                                className="form-control"
                                                                maxLength="50"
                                                                value={privates.model.condition.name}
                                                                onChange={actions.inputMutator(
                                                                    'privates.model.condition.name',
                                                                    true
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                            style={{ padding: "0 15px" }}
                                                            htmlFor="condition_remark">
                                                            {locales.pages.tasks.manage.template.condition.detail.remark}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                            <input
                                                                type="text"
                                                                id="condition_remark"
                                                                className="form-control"
                                                                maxLength="200"
                                                                value={privates.model.condition.remark}
                                                                onChange={actions.inputMutator(
                                                                    'privates.model.condition.remark',
                                                                    true
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                            style={{ padding: "0 15px" }}
                                                            htmlFor="condition_checkRange">
                                                            {locales.pages.tasks.manage.template.condition.detail.checkRange}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-offset-2 col-md-offset-3 col-xs-12 col-12">
                                                            <TaskCheckRange
                                                                inspectionConditions={privates.model.inspectionConditions}
                                                                condition={privates.model.condition}
                                                                onChange={actions.checkRangeChanged}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                            style={{ padding: "0 15px" }}
                                                            htmlFor="condition_operator">
                                                            {locales.pages.tasks.manage.template.condition.detail.operator}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-offset-2 col-md-offset-3 col-xs-12 col-12">
                                                            <TaskOperatorListGather
                                                                operatorList={privates.model.condition.operatorList}
                                                                operatorTypes={state.operatorTypes}
                                                                onChange={actions.operatorListChanged}
                                                                entityFields={privates.model.basicInfoFields}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                            style={{ padding: "0 15px" }}
                                                            htmlFor="condition_operatorLogic">
                                                            {locales.pages.tasks.manage.template.condition.detail.operatorLogic}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                            <input
                                                                type="text"
                                                                id="condition_operatorLogic"
                                                                className="form-control"
                                                                maxLength="255"
                                                                value={privates.model.condition.operatorLogic}
                                                                onChange={actions.inputMutator(
                                                                    'privates.model.condition.operatorLogic',
                                                                    true
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                                            <button type="button" className="btn btn-success" onClick={actions.save}>
                                                                {locales.labels.save}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </If>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TaskConditionDictionaries items={privates.model.inspectionConditions} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
