export default buildCompany;

function buildCompany(init) {
    if (init.company) {
        if (window.localStorage) {
            if(init.company.logoType){
                window.localStorage.setItem("company.name", init.company.name || "");
                window.localStorage.setItem("company.logo", init.company.logo || "");
                window.localStorage.setItem("company.icon", init.company.icon || "");
                let iconUrl = init.company.icon;
                if (iconUrl && iconUrl !== 'null') {
                    const changeFavicon = (link) => {
                        let $favicon = document.querySelector('link[rel="icon"]');
                        if ($favicon !== null) {
                            $favicon.href = link;
                        } else {
                            $favicon = document.createElement('link');
                            $favicon.rel = 'icon';
                            $favicon.href = link;
                            document.head.appendChild($favicon);
                        }
                    };
                    // 动态修改网站图标
                    changeFavicon(iconUrl);
                }
            }else{
                window.localStorage.removeItem("company.name");
                window.localStorage.removeItem("company.logo");
                window.localStorage.removeItem("company.icon");
            }
        }
    }
}
