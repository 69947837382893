import React from "react";
import CustomerServiceRankListRoute from "./route";
// import Udesk from 'Udesk';
import "./style.scss";
import { PageHeader, TreeSelect, Button, Popover, Tag, Space } from "udesk-ui";
import UdeskTable from 'Component/common/udesk-table/index.js';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import ExportButton from 'Component/common/export-button';

export default class CustomerServiceRankListTemplate extends CustomerServiceRankListRoute {
    render() {
        let { actions, locales, privates } = this;
        let {
            customerGroupData,
            selectedCustomerGroup,
            selectedRowKeys,
            tableData,
            startTime,
            endTime,
            targetItems,
            selectedagentNames,
            loading,
        } = this.state;
        let {
            paging,
            udeskTableRef
        } = privates;
        return (
            // <div className="customer-service-ranklist-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.customerServiceRankList.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root customer-service-ranklist-index-page'>
                        <div className="customer-service-ranklist-index-page-body">
                            <div className="customer-service-ranklist-index-page-body-context">
                                <div className="customer-service-ranklist-index-page-body-top">
                                    <div className="customer-service-ranklist-index-page-body-top-date">
                                        <div className="customer-service-ranklist-index-page-body-top-date-des">
                                            {
                                                locales.components.pages.customerServiceRankList.select
                                                    .date
                                            }
                                        </div>
                                        <UdeskDatePicker onChange={actions.selectTime} value={[startTime, endTime]} allowClear={false} style={{ width: 246 }} />
                                    </div>
                                    <div className="customer-service-ranklist-index-page-body-top-group">
                                        <div className="customer-service-ranklist-index-page-body-top-group-des">
                                            {
                                                locales.components.pages.customerServiceRankList.select
                                                    .group
                                            }
                                        </div>
                                        <TreeSelect
                                            value={selectedCustomerGroup}
                                            treeData={customerGroupData}
                                            onChange={actions.selectCustomerGroup}
                                            treeCheckable={true}
                                            showSearch={true}
                                            filterTreeNode={actions.filterTreeNode}
                                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                            onDeselect={actions.deselectCustomerGroup}
                                        // searchPlaceholder={locales.components.pages.customerServiceRankList.select.groupPlaceholder}
                                        />
                                    </div>
                                    <div className="customer-service-ranklist-index-page-body-top-btns">
                                        <div className="customer-service-ranklist-index-page-body-top-btns-left">
                                            <Button type="primary" onClick={actions.search}>
                                                {
                                                    locales.components.pages.customerServiceRankList
                                                        .select.search
                                                }
                                            </Button>
                                            <Button
                                                onClick={actions.reset}
                                                style={{ marginLeft: "8px" }}
                                            >
                                                {
                                                    locales.components.pages.customerServiceRankList
                                                        .select.reset
                                                }
                                            </Button>
                                        </div>
                                        <div className="customer-service-ranklist-index-page-body-top-btns-right">
                                            <Space>
                                                <Popover
                                                    placement="bottom"
                                                    overlayClassName='customer-service-ranklist-index-page-body-compared-popover'
                                                    title={
                                                        locales.components.pages.customerServiceRankList
                                                            .select.selected + '(' + selectedRowKeys.length + '/5)'
                                                    }
                                                    content={(
                                                        <div>
                                                            {
                                                                selectedagentNames.map((key, index) => {
                                                                    return (
                                                                        <Tag key={key.agentNo} closable={true} onClose={() => actions.handleClose(index)}>
                                                                            {key.agentName + ' (' + key.agentNo + ')'}
                                                                        </Tag>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    )}
                                                    trigger="hover"
                                                >
                                                    <Button
                                                        onClick={actions.compared}
                                                        disabled={selectedagentNames.length < 2}
                                                    >
                                                        {
                                                            locales.components.pages.customerServiceRankList
                                                                .select.compared
                                                        }
                                                    </Button>
                                                </Popover>
                                                <ExportButton disabled={loading} exportDataCount={paging.total} hasFilterCondition={true} onClick={actions.export} />
                                                <Button
                                                    onClick={actions.onPopoverVisible}
                                                >
                                                    {
                                                        locales.components.pages.customerServiceRankList
                                                            .select.column
                                                    }
                                                </Button>
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                                <UdeskTable
                                    cacheKey='cusRankList'
                                    subtractedHeight={176}
                                    ref={udeskTableRef}
                                    columns={targetItems}
                                    loading={loading}
                                    dataSource={tableData}
                                    onChange={actions.handleTableChange}
                                    rowSelection={{
                                        selectedRowKeys,
                                        onChange: actions.onTableSelectChange,
                                    }}
                                    onRow={actions.onRow}
                                    pagination={{
                                        onChange: actions.pageNumChange,
                                        total: paging.total,
                                        pageSize: paging.pageSize,
                                        onShowSizeChange: actions.pageSizeChange,
                                    }}
                                    needFixedActionColumn={true}
                                >
                                </UdeskTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
