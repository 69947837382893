import Udesk from 'Udesk';
import { Narrator } from '../components/Narrator';
import { RobotNode } from '../components/RobotNode';
import { UserNode } from '../components/UserNode';
import { KnowledgeNode } from '../components/KnowledgeNode';
import { PPT, OnePagePPT } from '../components/PPT';

export const channelNodeTypeMap = {
    [Udesk.enums.nodeTypes.startNode.id]: Narrator,
    [Udesk.enums.nodeTypes.userNode.id]: UserNode,
    [Udesk.enums.nodeTypes.robotNode.id]: RobotNode,
    [Udesk.enums.nodeTypes.knowledgeNode.id]: KnowledgeNode,
    [Udesk.enums.nodeTypes.pptNode.id]: PPT,
    [Udesk.enums.nodeTypes.aiGenDialogueNode.id]: UserNode,
};
/**
 *
 * @param channel 1:非用户输入 2:用户输入
 * @param nodeType 1:旁白 2:学员说话 3:机器人说话 4:知识库问答
 * @returns
 */
export const getChannelComponent = (channel, nodeType) => {
    if (channel === 1) {
        if (nodeType === Udesk.enums.nodeTypes.userNode.id) {
            return RobotNode; // 追问作为机器人节点处理
        }
    }

    if (nodeType === Udesk.enums.nodeTypes.pptNode.id) {
        if (OnePagePPT) {
            return OnePagePPT;
        }
    }

    return nodeType ? channelNodeTypeMap[nodeType] : Narrator;
};
