import React from 'react';
import Udesk from 'Udesk';
import _cloneDeep from 'lodash-es/cloneDeep';
import { Modal } from 'udesk-ui';
import Locales from 'UdeskLocales';

class ReviewListDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        detail: {},
        smartTags: [],
        changeList: [],
        qualityCheckScoreSaving: false,
        relatedDatas: [],
        storages: {
            relatedDatasPaging: {
                pageNum: 1,
                pageSize: 10,
            },
        },
        relatedLoading: false,
        pointCategoryList: [],
        pointCategoryListSafe: [],
        handMarkMapList: {},
        pointChildrenPerson: {},
        pointChildrenComputer: {},
        type: Udesk.enums.qualityCheckDetailTypes.review.id,
        resetStatus: false,
        evaluateFlag: false,
        evaluteVisibe: false,
        enabledEvaluate: false,
        submitButtonDisabled: false,
        showHit: 0,
        isTaskChanged: false,
    };
    static computes = {};

    actions = {
        backToIndexPage() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'reviewListIndex',
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        changePages(type, cb) {
            let { sdkOptions, match, history } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `review/next/${match.params.id}/${type}`,
                sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    const { nextId, nextInspectionId } = resp.data;
                    if (nextInspectionId && nextId) {
                        let routeOptions = {
                            history: history,
                            routeName: 'reviewListDetail',
                            pathParams: {
                                taskId: match.params.taskId,
                                id: nextId,
                            },
                            queryParams: {
                                inspectionId: nextInspectionId,
                            },
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    typeof cb === 'function' && cb?.();
                }
            );
        },
        reviewScoreCanceled() {
            this.actions.reloadAsyncModel();
        },

        handMarkMap(value) {
            this.privates.handMarkMapList = value;
            this.actions.update();
        },
        spotCheckScoreSubmittedVerify() {
            let { pointCategoryList } = this.privates;
            let { actions } = this;
            let pointDataList = getPointList(pointCategoryList);
            if (
                pointDataList
                    .filter((i) => i.pointComplaintInfo)
                    .every(
                        (i) =>
                            i.pointComplaintInfo.complainStatus ===
                            Udesk.enums.complainStatus.default.id
                    )
            ) {
                Modal.confirm({
                    title: Locales['current'].fix.rejectTip,
                    onOk() {
                        actions.spotCheckScoreSubmitted(pointDataList);
                    },
                });
            } else {
                this.actions.spotCheckScoreSubmitted(pointDataList);
            }
        },
        spotCheckScoreSubmitted(pointDataList) {
            let { task, handMarkMapList } = this.privates;
            let { locales } = this;
            // let pointDataList = getPointList(pointCategoryList);
            // let validateResult = validateItemScores(pointDataList);
            // if (!validateResult) {
            //     return Udesk.ui.notify.error(Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.labels.saveSpotCheck));
            // }
            let { sdkOptions, match } = this.props;
            let arr = _cloneDeep(pointDataList);

            let params = {
                flowId: match.params.id,
                taskId: match.params.taskId,
                inspectionId: task.id,
                itemScores: arr,
                richRemark: task.richRemark,
                remark: task.remark,
                complaintLevel: arr.some((item) => item.pointComplaintInfo?.complainLevel === 2)
                    ? 2
                    : 1,
            };
            arr.forEach((item) => {
                delete item.complaintLevel;
                delete item.isAllApprove;
                delete item.allPoint;
                delete item.handMarkHighlightSeqMap;
                if (item.pointComplaintInfo) {
                    delete item.pointComplaintInfo.firstComplaintInfo;
                    if (
                        item.pointComplaintInfo.complainStatus ===
                        Udesk.enums.complainStatus.default.id
                    ) {
                        item.pointComplaintInfo.complainStatus = Udesk.enums.complainStatus.fail.id;
                    }
                }
            });
            let list = pointDataList.map((item) => {
                if (handMarkMapList[item.pointId]) {
                    let markIndices = [];
                    if (handMarkMapList[item.pointId] && handMarkMapList[item.pointId].length > 0) {
                        markIndices = handMarkMapList[item.pointId].map((item) => item.id);
                    }
                    return {
                        pointId: item.pointId,
                        // flowId: resp.data.flowId,
                        // flowType: resp.data.flowType,
                        // callId: resp.data.callId,
                        // inspectId: resp.data.inspectionId,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        gradeChooseHit: item.gradeChooseHit,
                        markType: 1,
                        markIndices,
                    };
                } else {
                    let markIndices = null;
                    if (item.handMarkHighlightSeqMap && item.handMarkHighlightSeqMap[1]) {
                        markIndices = item.handMarkHighlightSeqMap[1];
                    }
                    return {
                        pointId: item.pointId,
                        // flowId: resp.data.flowId,
                        // flowType: resp.data.flowType,
                        // callId: resp.data.callId,
                        // inspectId: resp.data.inspectionId,
                        gradeChooseHit: item.gradeChooseHit,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        markType: 1,
                        markIndices,
                    };
                }
            });
            let pointFlag = true;
            for (let i = 0; i < list.length; i++) {
                if (list[i].pointType === Udesk.enums.pointTypes.machineLearning.id) {
                    if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitSub.id
                    ) {
                        if (list[i].gradeChooseHit === 1) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 0) {
                            list[i].markIndices = null;
                        }
                    } else if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotSub.id
                    ) {
                        if (list[i].gradeChooseHit === 0) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 1) {
                            list[i].markIndices = null;
                        }
                    }
                }
            }
            if (!pointFlag) {
                return Udesk.ui.notify.error(locales.fix.unmarkedSelfLearningTip);
            }
            let url1 = Udesk.business.apiPath.concatApiPath(`smartPointMarkResults`, sdkOptions);
            let url = Udesk.business.apiPath.concatApiPath(`review/${task.id}/submit`, sdkOptions);
            this.privates.qualityCheckScoreSaving = true;
            this.actions.update();

            let url2 = Udesk.business.apiPath.concatApiPath(`review/submit/flow/check`, sdkOptions);
            Udesk.ajax.post(url2, params).then(
                (resp) => {
                    this.privates.isTaskChanged = false;

                    new Promise((resolve, reject) => {
                        Udesk.ajax.put(url, params).then(
                            (resp) => {
                                // saveRemark(task, this).then(() => {
                                //     resolve(resp);
                                // }).catch((reason) => {
                                //     reject(reason);
                                // });
                                resolve(resp);
                            },
                            (reason) => {
                                reject(reason);
                            }
                        );
                    })
                        .then((resp) => {
                            Udesk.ui.notify.success(
                                Udesk.utils.string.format(
                                    locales.business.notifyMessage.saveSuccessFormat,
                                    locales.labels.saveReview
                                )
                            );
                            this.privates.qualityCheckScoreSaving = false;
                            if (resp.data) {
                                let data = {
                                    flowId: resp.data.id,
                                    flowType: resp.data.flowType,
                                    inspectId: resp.data.inspectionId,
                                };
                                let lists = list.map((item) => {
                                    return {
                                        ...item,
                                        flowId: resp.data.flowId,
                                        flowType: resp.data.flowType,
                                        callId: resp.data.callId,
                                        inspectId: resp.data.inspectionId,
                                    };
                                });

                                data.smartPointMarks = lists;
                                new Promise((resolve, reject) => {
                                    Udesk.ajax.post(url1, data).then(
                                        (resp) => {
                                            resolve(resp);
                                            Udesk.ui.notify.success(locales.fix.markedSuccessfully);
                                            this.privates.pointCategoryList = [];
                                            this.privates.handMarkMapList = {};
                                            this.privates.pointChildrenPerson = {};
                                            this.privates.pointChildrenComputer = {};
                                            this.actions.update();
                                        },
                                        (reason) => {
                                            reject(reason);
                                            Udesk.ui.notify.error(locales.fix.markingFailed);
                                        }
                                    );
                                }).then(() => {
                                    Udesk.data.init.sampleNotToNext
                                        ? this.actions.reloadAsyncModel()
                                        : this.actions.changePages(
                                              2,
                                              this.actions.reloadAsyncModel
                                          );
                                });
                            }
                            this.privates.resetStatus = !this.privates.resetStatus;
                            this.privates.handMarkMapList = {};
                            this.actions.update();
                            // this.actions.reloadAsyncModel();
                        })
                        .catch((reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    Udesk.utils.string.format(
                                        locales.business.notifyMessage.saveFailedFormat,
                                        locales.labels.saveSpotCheck
                                    )
                            );
                            this.privates.qualityCheckScoreSaving = false;
                            this.actions.update();
                        });

                    if (task.evaluateDto && task.evaluateDto.handValue) {
                        let url1 = Udesk.business.apiPath.concatApiPath(
                            `call/goodOrBad/${task.callId}?goodBadFlag=${task.evaluateDto.handValue}`,
                            sdkOptions
                        );
                        new Promise((resolve, reject) => {
                            Udesk.ajax.get(url1).then(
                                (resp) => {
                                    resolve(resp);
                                },
                                (reason) => {
                                    reject(reason);
                                }
                            );
                        });
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.qualityCheckScoreSaving = false;
                    this.privates.isTaskChanged = true;
                    this.actions.update();
                }
            );
        },
        onQualityCheckScoreRapidSampling() {
            let { pointCategoryList, task, handMarkMapList, type } = this.privates;
            let { locales } = this;
            let pointDataList = getPointList(pointCategoryList);
            let validateResult = validateItemScores(pointDataList);
            if (!validateResult) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.labels.saveSpotCheck
                    )
                );
            }
            let { sdkOptions, match } = this.props;
            let arr = _cloneDeep(pointDataList);
            arr.forEach((item) => {
                delete item.handMarkHighlightSeqMap;
            });
            let params = {
                taskId: match.params.taskId,
                flowType: type,
                inspectionId: task.id,
                itemScores: arr,
                richRemark: task.richRemark,
                remark: task.remark,
            };
            let list = pointDataList.map((item) => {
                if (handMarkMapList[item.pointId]) {
                    let markIndices = [];
                    if (handMarkMapList[item.pointId] && handMarkMapList[item.pointId].length > 0) {
                        markIndices = handMarkMapList[item.pointId].map((item) => item.id);
                    }
                    return {
                        pointId: item.pointId,
                        // flowId: resp.data.flowId,
                        // flowType: resp.data.flowType,
                        // callId: resp.data.callId,
                        // inspectId: resp.data.inspectionId,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        gradeChooseHit: item.gradeChooseHit,
                        markType: 1,
                        markIndices,
                    };
                } else {
                    let markIndices = null;
                    if (item.handMarkHighlightSeqMap && item.handMarkHighlightSeqMap[1]) {
                        markIndices = item.handMarkHighlightSeqMap[1];
                    }
                    return {
                        pointId: item.pointId,
                        // flowId: resp.data.flowId,
                        // flowType: resp.data.flowType,
                        // callId: resp.data.callId,
                        // inspectId: resp.data.inspectionId,
                        gradeChooseHit: item.gradeChooseHit,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        markType: 1,
                        markIndices,
                    };
                }
            });
            let pointFlag = true;
            for (let i = 0; i < list.length; i++) {
                if (list[i].pointType === Udesk.enums.pointTypes.machineLearning.id) {
                    if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitSub.id
                    ) {
                        if (list[i].gradeChooseHit === 1) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 0) {
                            list[i].markIndices = null;
                        }
                    } else if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotSub.id
                    ) {
                        if (list[i].gradeChooseHit === 0) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 1) {
                            list[i].markIndices = null;
                        }
                    }
                }
            }
            if (!pointFlag) {
                return Udesk.ui.notify.error(locales.fix.unmarkedSelfLearningTip);
            }
            let url1 = Udesk.business.apiPath.concatApiPath(`smartPointMarkResults`, sdkOptions);
            let url = Udesk.business.apiPath.concatApiPath(`inspectionData/fastSample`, sdkOptions);
            this.privates.qualityCheckScoreSaving = true;
            this.actions.update();

            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    (resp) => {
                        // saveRemark(task, this).then(() => {
                        //     resolve(resp);
                        // }).catch((reason) => {
                        //     reject(reason);
                        // });
                        resolve(resp);
                    },
                    (reason) => {
                        reject(reason);
                    }
                );
            })
                .then((resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locales.labels.saveSpotCheck
                        )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    if (resp.data) {
                        let data = {
                            flowId: resp.data.id,
                            flowType: resp.data.flowType,
                            inspectId: resp.data.inspectionId,
                        };
                        let lists = list.map((item) => {
                            return {
                                ...item,
                                isHit: item.gradeChooseHit,
                                flowId: resp.data.flowId,
                                flowType: resp.data.flowType,
                                callId: resp.data.callId,
                                inspectId: resp.data.inspectionId,
                            };
                        });

                        data.smartPointMarks = lists;
                        new Promise((resolve, reject) => {
                            Udesk.ajax.post(url1, data).then(
                                (resp) => {
                                    resolve(resp);
                                    Udesk.ui.notify.success(locales.fix.markedSuccessfully);
                                    this.privates.pointCategoryList = [];
                                    this.privates.handMarkMapList = {};
                                    this.privates.pointChildrenPerson = {};
                                    this.privates.pointChildrenComputer = {};
                                    this.actions.update();
                                },
                                (reason) => {
                                    reject(reason);
                                    Udesk.ui.notify.error(locales.fix.markingFailed);
                                }
                            );
                        });
                    }
                    this.privates.resetStatus = !this.privates.resetStatus;
                    this.privates.handMarkMapList = {};
                    this.actions.update();
                    this.actions.reloadAsyncModel();
                })
                .catch((reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locales.labels.saveSpotCheck
                            )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.actions.update();
                });
            if (task.evaluateDto && task.evaluateDto.handValue) {
                let url1 = Udesk.business.apiPath.concatApiPath(
                    `call/goodOrBad/${task.callId}?goodBadFlag=${task.evaluateDto.handValue}`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.get(url1).then(
                        (resp) => {
                            resolve(resp);
                        },
                        (reason) => {
                            reject(reason);
                        }
                    );
                });
            }
        },
        activeChange(flag) {
            let { sdkOptions } = this.props;
            let activeValue = null;
            let { task, detail } = this.privates;
            if (flag === 'true') {
                this.privates.activeFlag = 'true';
                activeValue = '1';
            } else if (flag === 'false') {
                this.privates.activeFlag = 'false';
                activeValue = '2';
            }

            let url1 = Udesk.business.apiPath.concatApiPath(
                `call/goodOrBad/${task.callId}?goodBadFlag=${activeValue}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url1).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.evaluteVisibe = false;
                        this.privates.evaluateFlag = true;
                        if (
                            detail.sampleStatus !==
                            Udesk.enums.sampleAndReviewStatus.samplingCompleted.id
                        ) {
                            this.actions.spotCheckScoreSubmitted();
                        } else if (
                            detail.sampleStatus ===
                            Udesk.enums.sampleAndReviewStatus.samplingCompleted.id
                        ) {
                            this.actions.onQualityCheckScoreRapidSampling();
                        }
                        this.actions.update();
                    },
                    (reason) => {
                        reject(reason);
                    }
                );
            });
        },
        evaluateChange(value) {
            this.privates.evaluateFlag = value;
            this.actions.update();
        },
        evaluateSave() {
            let { detail } = this.privates;
            this.privates.evaluteVisibe = false;
            this.privates.evaluateFlag = true;
            if (detail.sampleStatus !== Udesk.enums.sampleAndReviewStatus.samplingCompleted.id) {
                this.actions.spotCheckScoreSubmitted();
            } else if (
                detail.sampleStatus === Udesk.enums.sampleAndReviewStatus.samplingCompleted.id
            ) {
                this.actions.onQualityCheckScoreRapidSampling();
            }
            this.actions.update();
        },
        cancelSave() {
            this.privates.evaluteVisibe = false;
            this.actions.update();
        },
        onUpdate() {
            this.actions.reloadAsyncModel({ id: this.props.match.params.id });
        },
        //相关记录查看更多
        parentLoadMore(value) {
            let { relatedDatas, detail } = this.privates;
            let { sdkOptions } = this.props;
            this.privates.relatedLoading = true;
            let relatedData = Object.assign({ id: detail.callId, pageSize: 10 }, value);
            let relatedDataUrl = Udesk.business.apiPath.concatApiPath(
                `data/related-data`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(relatedDataUrl, relatedData).then(
                    (resp) => {
                        resolve(resp);
                        let list = [...relatedDatas, ...resp.data];
                        this.privates.relatedDatas = list;
                        this.privates.storages.relatedDatasPaging = resp.paging;
                        this.privates.relatedLoading = false;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        this.privates.relatedLoading = false;
                        reject(reason);
                    }
                );
            });
        },
        qualityCheckScoreSaved(pointCategoryList, task, type) {
            this.privates.pointCategoryList = pointCategoryList;
            this.privates.task = task;
            this.privates.type = type;
            this.actions.update();
        },
        cancel() {
            this.privates.resetStatus = !this.privates.resetStatus;
            this.privates.handMarkMapList = {};
            this.actions.reloadAsyncModel({ id: this.props.match.params.id });
        },
        changeDialogRemarkList(newRemark) {
            const changeDialogRemarkList = (newDialogRemarkList) => {
                this.privates.dialogRemarkList = newDialogRemarkList.filter((i) => i.remark);
                if (this.privates.related) {
                    this.privates.callDetailsList.some((detail) => {
                        if (detail.callId === parseInt(this.privates.activeCallId, 10)) {
                            detail.remarkList = this.privates.dialogRemarkList;
                        }
                        return detail.callId === parseInt(this.privates.activeCallId, 10);
                    });
                }
                this.actions.update();
            };
            let { index, remark, id, type } = newRemark;
            if (id) {
                //更改逻辑
                let url = Udesk.business.apiPath.concatApiPath(
                    `inspectionRemarks/${id}`,
                    this.props.sdkOptions
                );
                let params = {
                    id: parseInt(id, 10),
                    index,
                    remark,
                    type,
                };
                return Udesk.ajax.put(url, params).then(
                    (resp) => {
                        let { dialogRemarkList } = this.privates;
                        dialogRemarkList.forEach((item) => {
                            if (item.index === index) {
                                item.remark = remark;
                            }
                        });
                        changeDialogRemarkList(dialogRemarkList.filter((i) => i.remark));
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            } else {
                //新增备注
                let url = Udesk.business.apiPath.concatApiPath(
                    `inspectionRemarks`,
                    this.props.sdkOptions
                );
                let params = {
                    dataId: parseInt(this.props.match.params.id, 10),
                    index,
                    type,
                    remark,
                    callId: this.privates.detail.callId,
                };
                return Udesk.ajax.post(url, params).then(
                    (resp) => {
                        let { data } = resp;
                        let { dialogRemarkList } = this.privates;
                        changeDialogRemarkList([...dialogRemarkList, data]);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            }
        },
        querryChangeList(smartTags) {
            this.privates.smartTags = smartTags;
            this.actions.update();
            let { sdkOptions, match } = this.props;
            let { task } = this.privates;
            let id = task.id;
            let taskId = match.params.taskId;
            let changeListUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${id}/changeList?taskId=${taskId}`,
                sdkOptions
            );
            Udesk.ajax.get(changeListUrl).then(
                (resp) => {
                    this.privates.changeList = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        trainRecords(params) {
            const { sdkOptions } = this.props;
            let { task } = this.privates;
            const caseBaseUrl = Udesk.business.apiPath.concatApiPath(`trainRecords`, sdkOptions);
            return Udesk.ajax.post(caseBaseUrl, { ...params, callId: task.callId }).then(
                (resp) => {
                    Udesk.ui.notify.success(this.locales.business.notifyMessage.correctionSuccess);
                    let newDialogSentenceList =
                        this.privates.detail.dialogRecords.dialogSentenceList.map((item, index) => {
                            if (index === params.sentenceIndex) {
                                return {
                                    ...item,
                                    text: params.newContent,
                                };
                            }
                            return item;
                        });
                    this.privates.detail = {
                        ...this.privates.detail,
                        dialogRecords: {
                            dialogSentenceList: newDialogSentenceList,
                        },
                    };
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        showHitChange(showHit) {
            this.privates.showHit = showHit ? 1 : 0;
            this.actions.update();
        },
    };

    //#region Life Cycle
    componentDidUpdate(prevProps) {
        const { props } = this;
        if (props.match.params.id !== prevProps.match.params.id) {
            let historySearch = props.history.location.search;
            let inspectionId = null;
            if (historySearch) {
                inspectionId = historySearch.split('=')[1];
            }
            this.actions.reloadAsyncModel({ id: props.match.params.id, inspectionId });
        }
    }
    // parseProps({ props, prevProps }) {
    //     if (props.match.params.id !== prevProps.match.params.id) {
    //         let historySearch = props.history.location.search;
    //         let inspectionId = null;
    //         if (historySearch) {
    //             inspectionId = historySearch.split("=")[1];
    //         }
    //         if(timer){
    //             clearTimeout(timer);
    //         }
    //         timer = setTimeout(() => {
    //             this.actions.reloadAsyncModel({ id: props.match.params.id, inspectionId });
    //         }, 300);
    //     }
    // }
    componentDidMount() {}
    componentWillUnmount() {}
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'detailWithRelateData') {
            this.privates.relatedLoading = false;
            this.privates.pointChildrenComputer = [];
            this.privates.pointChildrenPerson = [];
            if (asyncResult && asyncResult.detail && asyncResult.detail.data) {
                if (
                    asyncResult.detail.data.evaluateDto &&
                    asyncResult.detail.data.evaluateDto.handValue
                ) {
                    this.privates.evaluateFlag = true;
                }
                this.privates.dialogRemarkList = asyncResult.detail.data.remarkList || [];
                this.privates.detail = asyncResult.detail.data;
                this.privates.pointCategoryListSafe = this.privates.detail.pointCategoryList;

                const complaintInfoHashMap = this.privates.detail.complaintInfoHashMap;
                this.privates.submitButtonDisabled = Object.values(complaintInfoHashMap).some(
                    (i) => i.complainStatus === Udesk.enums.flowStatus.complainCancel.id
                );
                const pointIdShow = Object.keys(complaintInfoHashMap).map((i) => parseInt(i, 10));
                // let pointCategoryList = this.privates.detail.pointCategoryList.map((firstCategory) => {
                //     let children = (firstCategory.children || []).map((secondCategory) => {
                //         return {
                //             ...secondCategory,
                //             pointDataList: (secondCategory.pointDataList || []).filter(point => pointIdShow.includes(point.pointId))
                //         };
                //     }).filter(secondCategory => secondCategory.pointDataList.length > 0);
                //     return {
                //         ...firstCategory,
                //         children,
                //     };
                // }).filter(firstCategory => firstCategory.children.length > 0);
                let getPointComplaintInfo = (complaintInfoHashMap, point) => {
                    const info = complaintInfoHashMap[point.pointId];
                    if (info) {
                        if (info.secondComplaintInfo) {
                            const result = {
                                firstComplaintInfo: {
                                    ...info,
                                },
                                ...info.secondComplaintInfo,
                            };
                            delete result.firstComplaintInfo.secondComplaintInfo;
                            return result;
                        }

                        return info;
                    }
                };

                let pointCategoryList = this.privates.detail.pointCategoryList.map(
                    (firstCategory) => {
                        let children = (firstCategory.children || [])
                            .map((secondCategory) => {
                                return {
                                    ...secondCategory,
                                    pointDataList: (secondCategory.pointDataList || []).map(
                                        (point) => ({
                                            ...point,
                                            _hidden: !pointIdShow.includes(point.pointId),
                                            pointComplaintInfo: getPointComplaintInfo(
                                                complaintInfoHashMap,
                                                point
                                            ),
                                        })
                                    ),
                                };
                            })
                            .map((secondCategory) => ({
                                ...secondCategory,
                                _hidden: secondCategory.pointDataList.every((p) => p._hidden),
                            }));
                        return {
                            ...firstCategory,
                            children: children,
                            _hidden: children.every((c) => c.hidden),
                        };
                    }
                );
                this.privates.pointCategoryList = pointCategoryList;
                this.privates.detail.pointCategoryList = pointCategoryList;
                this.privates.task = this.privates.detail;

                if (this.privates.pointCategoryList && this.privates.pointCategoryList.length > 0) {
                    this.privates.pointCategoryList.forEach((item) => {
                        if (item.children) {
                            item.children.forEach((items) => {
                                if (items.pointDataList) {
                                    items.pointDataList.forEach((itemss) => {
                                        if (itemss.handMarkHighlightSeqMap) {
                                            if (itemss.handMarkHighlightSeqMap[1]) {
                                                itemss.handMarkHighlightSeqMap[1].forEach(
                                                    (itemsss) => {
                                                        if (
                                                            this.privates.pointChildrenPerson[
                                                                itemsss
                                                            ]
                                                        ) {
                                                            this.privates.pointChildrenPerson[
                                                                itemsss
                                                            ].push({
                                                                id: itemss.pointId,
                                                                name: itemss.name,
                                                            });
                                                        } else {
                                                            this.privates.pointChildrenPerson = {
                                                                ...this.privates
                                                                    .pointChildrenPerson,
                                                                [itemsss]: [
                                                                    {
                                                                        id: itemss.pointId,
                                                                        name: itemss.name,
                                                                    },
                                                                ],
                                                            };
                                                        }
                                                    }
                                                );
                                            }
                                            if (itemss.handMarkHighlightSeqMap[2]) {
                                                itemss.handMarkHighlightSeqMap[2].forEach(
                                                    (itemsss) => {
                                                        if (
                                                            this.privates.pointChildrenComputer[
                                                                itemsss
                                                            ]
                                                        ) {
                                                            this.privates.pointChildrenComputer[
                                                                itemsss
                                                            ].push({
                                                                id: itemss.pointId,
                                                                name: itemss.name,
                                                            });
                                                        } else {
                                                            this.privates.pointChildrenComputer = {
                                                                ...this.privates
                                                                    .pointChildrenComputer,
                                                                [itemsss]: [
                                                                    {
                                                                        id: itemss.pointId,
                                                                        name: itemss.name,
                                                                    },
                                                                ],
                                                            };
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                this.actions.update();
            }
            if (asyncResult && asyncResult.relateData && asyncResult.relateData.data) {
                this.privates.relatedDatas = asyncResult.relateData.data;
                this.privates.storages.relatedDatasPaging = asyncResult.relateData.paging;
                this.actions.update();
            }
        } else if (asyncKey === 'changeList') {
            if (asyncResult && asyncResult.data) {
                this.privates.changeList = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'smartTags') {
            if (asyncResult && asyncResult.data) {
                this.privates.smartTags = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'caseBase') {
            if (asyncResult && asyncResult.data) {
                this.privates.caseBase = asyncResult.data;
                this.actions.update();
            }
        }
        if (asyncModel.sysConfig) {
            this.privates.enabledEvaluate = asyncModel.sysConfig.enabledEvaluate;
            this.actions.update();
        }
    }
    //#endregion
}

function getPointList(pointCategoryList) {
    let pointDataList = [];
    pointCategoryList.forEach((firstCategory) => {
        let secondCategorys = firstCategory.children || [];
        secondCategorys.forEach((secondCategory) => {
            if (secondCategory.pointDataList) {
                pointDataList = pointDataList.concat(secondCategory.pointDataList);
            }
        });
    });
    return pointDataList;
}

function validateItemScores(items) {
    let validateResult = true;
    if (items) {
        if (items.length === 0) {
            validateResult = true;
        }
        if (items.length > 0) {
            items.forEach((item) => {
                if (item.naChecked !== 1) {
                    if (item.type !== Udesk.enums.pointTypes.machineLearning.id) {
                        if (
                            item.gradeType === Udesk.enums.pointGradeTypes.radio.id ||
                            item.gradeType === Udesk.enums.pointGradeTypes.foctors.id
                        ) {
                            if (item.gradeChooseHit == null) {
                                validateResult = false;
                            }
                        } else if (item.gradeType === Udesk.enums.pointGradeTypes.input.id) {
                            if (item.gradeInput == null || item.gradeInput === '') {
                                validateResult = false;
                            }
                        } else if (item.gradeType === Udesk.enums.pointGradeTypes.select.id) {
                            if (item.pointHitValue == null || item.pointHitValue === '') {
                                validateResult = false;
                            }
                        }
                    } else {
                        if (item.gradeChooseHit == null) {
                            validateResult = false;
                        }
                    }
                }
            });
        }
        return validateResult;
    }
    return validateResult;
}

// function saveRemark(task, that) {
//     if (!task.remarkChanged) {
//         return Promise.resolve();
//     }
//     let {
//         sdkOptions
//     } = that.props;
//     let url = Udesk.business.apiPath.concatApiPath(`inspectionData/${task.id}/gradeRemark`, sdkOptions);
//     let params = { remark: task.remark, richRemark: task.richRemark };
//     return new Promise((resolve, reject) => {
//         Udesk.ajax.put(url, params)
//             .then(
//                 (resp) => {
//                     resolve(resp);
//                 },
//                 (reason) => {
//                     reject(reason);
//                 }
//             );
//     });
// }

export default ReviewListDetailComponent;
