import featurePermissionEnums from '../config/feature-permission';

function hasFeature(featureEnum, rolePermission) {
    if (featureEnum && rolePermission) {
        let permissionKeys = featurePermissionEnums[featureEnum.key] || [];
        permissionKeys = permissionKeys.filter(permissionKey => {
            return rolePermission[permissionKey];
        });
        if (permissionKeys && permissionKeys.length > 0) {
            return true;
        }
        return false;
    }
}

export default {
    hasFeature
};