// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostBusinessAnalysisConditionConfigsSearchQuery,
    PostBusinessAnalysisConditionConfigsSearchBody,
    BaseResponseListBusinessAnalysisConditionConfigFoundResponse,
} from '../../types';

/**
 * findAll
 *
 * @export
 * @tags business-analysis-condition-config-controller
 * @link [POST] /businessAnalysisConditionConfigs/search
 */
export function postBusinessAnalysisConditionConfigsSearch(
    data: PostBusinessAnalysisConditionConfigsSearchBody
): Promise<BaseResponseListBusinessAnalysisConditionConfigFoundResponse>;
export function postBusinessAnalysisConditionConfigsSearch(
    data: PostBusinessAnalysisConditionConfigsSearchBody,
    options: ApiOptions<never, PostBusinessAnalysisConditionConfigsSearchQuery>
): Promise<BaseResponseListBusinessAnalysisConditionConfigFoundResponse>;
export function postBusinessAnalysisConditionConfigsSearch(
    data: PostBusinessAnalysisConditionConfigsSearchBody,
    options?: ApiOptions<never, PostBusinessAnalysisConditionConfigsSearchQuery>
): Promise<BaseResponseListBusinessAnalysisConditionConfigFoundResponse> {
    return request<
        PostBusinessAnalysisConditionConfigsSearchBody,
        never,
        PostBusinessAnalysisConditionConfigsSearchQuery
    >('/businessAnalysisConditionConfigs/search', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postBusinessAnalysisConditionConfigsSearch',
    });
}

export const meta = [
    {
        tags: ['business-analysis-condition-config-controller'],
        path: '/businessAnalysisConditionConfigs/search',
        method: 'post',
    },
];
