import ConceptWordDetailComponent from './component';
import Udesk from 'Udesk';

class ConceptWordDetailRoute extends ConceptWordDetailComponent {
    model(changedParams) {

        let url = Udesk.business.apiPath.concatApiPath(`/conceptWords/module/${this.props.match.params.moduleVersionId}/${this.props.match.params.taskId}`, this.props.sdkOptions);
        let conceptWordPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    reject(reason);
                }
            );
        });
        return {
            conceptWord: conceptWordPromise
        };
    }
}

export default ConceptWordDetailRoute;
