import React from 'react';
import Udesk from 'Udesk';
import connectedByConversationRoute from './route';
import './style.scss';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Switch, Select, InputNumber, PageHeader, Button, Space } from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import _size from 'lodash-es/size';
import _filter from 'lodash-es/filter';
import _find from 'lodash-es/find';
import _includes from 'lodash-es/includes';
import _isEqual from 'lodash-es/isEqual';
import ClassNames from 'classnames';

export default class CustomerPortraitTemplate extends connectedByConversationRoute {

    render() {
        let { locales, actions } = this;
        let { fieldTypeListString, fieldTypeListNumber, fieldTypeListTime, fieldTypeListSource, inputNumberprice } = this.props;
        let { Option } = Select;
        let { listData, parentNumList, fieldsData, activeFlag, loading } = this.privates;
        let { timeDifferStates, beginAndEndEqual } = this.props;
        let { getFieldDecorator } = this.props.form;
        return (
            // <div className="connected-by-conversation-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.connectedByConversation.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root connected-by-conversation-index-page'>
                        <div>
                            <div className='qa-react-list-connenct-by-converstaion-title-header'>
                                <h2>{locales.components.pages.connectedByConversation.list.title}</h2>
                                <div className="qa-react-list-connenct-by-converstaion-title-header-right-info">
                                    <Switch checked={activeFlag ? true : false} onChange={actions.switchChange} className="connect-by-converstaion-switch" />
                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazaishibai"></i>
                                    <span>{locales.components.pages.connectedByConversation.list.info}</span>
                                </div>
                            </div>
                            <div className='qa-react-list-connenct-by-converstaion-title-header'>
                                <h2>{locales.components.pages.connectedByConversation.list.rule}</h2>
                            </div>
                            <div style={{ height: getHeight() }}>
                                <div className='qa-react-list-connenct-by-converstaion-custome-filter'>
                                    <Form layout="inline" onSubmit={actions.dataCallRelatedSaved}>
                                        <div className='custome-filter-driver-box'>
                                            {
                                                _isEmpty(parentNumList) && <div className='customer-filter-driver-circle-add' onClick={actions.addOneCustome}><i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i></div>
                                            }
                                            {
                                                _size(parentNumList) > 1 && (<div className='custome-filter-driver-box-or'>or</div>)
                                            }
                                            {
                                                !_isEmpty(parentNumList) && parentNumList.map((item, index) => {
                                                    return (
                                                        <div key={`${item.num}-first`} className={ClassNames("custome-filter-driver-one-box", { 'custome-filter-driver-one-box-length': index === _size(parentNumList) - 1 })}>
                                                            {
                                                                index === _size(parentNumList) - 1 && (<div className='customer-filter-driver-circle-add' onClick={actions.addOneCustome}><i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i></div>)
                                                            }
                                                            <div className="custome-filter-driver-one">
                                                                <Form.Item >
                                                                    {
                                                                        getFieldDecorator(`${item.num}-first`,
                                                                            {
                                                                                initialValue: item.source || undefined,
                                                                                rules: [{ required: true }]
                                                                            }
                                                                        )(
                                                                            <Select onChange={actions.dataSourceChanged.params(item, `${item.num}-first`)} placeholder={locales.components.pages.connectedByConversation.list.source}>
                                                                                {
                                                                                    Udesk.data.init.company.enabledCallInspect === 1 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesVoiceCall && Udesk.enums.connectedByConverstationSourcesVoiceCall.map(items => {
                                                                                        return (
                                                                                            <Option key={items.id} value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledCallInspect === 2 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesRealTimeVoiceCall && Udesk.enums.connectedByConverstationSourcesRealTimeVoiceCall.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledCallInspect === 3 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesRealTimeVoiceCall && Udesk.enums.connectedByConverstationSourcesRealTimeVoiceCall.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledCallInspect === 3 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesVoiceCall && Udesk.enums.connectedByConverstationSourcesVoiceCall.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledImInspect === 1 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesTextDialogue && Udesk.enums.connectedByConverstationSourcesTextDialogue.map(items => {
                                                                                        return (
                                                                                            <Option key={items.id} value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledImInspect === 2 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesrealTimeTextDialogue && Udesk.enums.connectedByConverstationSourcesrealTimeTextDialogue.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledImInspect === 3 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesTextDialogue && Udesk.enums.connectedByConverstationSourcesTextDialogue.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledImInspect === 3 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesrealTimeTextDialogue && Udesk.enums.connectedByConverstationSourcesrealTimeTextDialogue.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        )
                                                                    }
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    {
                                                                        getFieldDecorator(`${item.num}-second`,
                                                                            {
                                                                                initialValue: item.sourceFrom || undefined,
                                                                                rules: [{ required: true }]
                                                                            }
                                                                        )(
                                                                            <Select onChange={actions.dataSourceFromChanged.params(item, `${item.num}-second`)} placeholder={locales.components.pages.connectedByConversation.list.source}>
                                                                                {
                                                                                    Udesk.data.init.company.enabledCallInspect === 1 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesVoiceCall && Udesk.enums.connectedByConverstationSourcesVoiceCall.map(items => {
                                                                                        return (
                                                                                            <Option key={items.id} value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledCallInspect === 2 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesRealTimeVoiceCall && Udesk.enums.connectedByConverstationSourcesRealTimeVoiceCall.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledCallInspect === 3 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesRealTimeVoiceCall && Udesk.enums.connectedByConverstationSourcesRealTimeVoiceCall.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledCallInspect === 3 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesVoiceCall && Udesk.enums.connectedByConverstationSourcesVoiceCall.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledImInspect === 1 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesTextDialogue && Udesk.enums.connectedByConverstationSourcesTextDialogue.map(items => {
                                                                                        return (
                                                                                            <Option  key={items.id} value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledImInspect === 2 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesrealTimeTextDialogue && Udesk.enums.connectedByConverstationSourcesrealTimeTextDialogue.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledImInspect === 3 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesTextDialogue && Udesk.enums.connectedByConverstationSourcesTextDialogue.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                                {
                                                                                    Udesk.data.init.company.enabledImInspect === 3 &&
                                                                                    Udesk.enums.connectedByConverstationSourcesrealTimeTextDialogue && Udesk.enums.connectedByConverstationSourcesrealTimeTextDialogue.map(items => {
                                                                                        return (
                                                                                            <Option value={items.id}>{items.name}</Option>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        )
                                                                    }
                                                                </Form.Item>
                                                                <div className='customer-filter-driver-circle-del' onClick={actions.delOneCustome.params(item)}><i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i></div>
                                                            </div>
                                                            <div className={ClassNames("custome-filter-driver-two-box", { 'custome-filter-driver-two-box-driver-length': _size(_filter(listData, current => current.parentNum === item.num)) > 1 })}>
                                                                {
                                                                    _size(_filter(listData, current => current.parentNum === item.num)) <= 1 && (
                                                                        <div className='customer-filter-driver-circle-add' onClick={actions.addTwoCustome.params(item)}><i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i></div>
                                                                    )
                                                                }
                                                                <div className={ClassNames('', { "customer-filter-driver-three-box": _size(_filter(listData, current => current.parentNum === item.num)) > 1 })}>
                                                                    {
                                                                        _size(_filter(listData, current => current.parentNum === item.num)) > 1 && (
                                                                            <div className='custome-filter-driver-box-and'>and</div>
                                                                        )
                                                                    }
                                                                    {
                                                                        _size(_filter(listData, current => current.parentNum === item.num)) > 1 && (
                                                                            <div className='customer-filter-driver-circle-add' onClick={actions.addTwoCustome.params(item)}><i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707"></i></div>
                                                                        )
                                                                    }
                                                                    {
                                                                        _filter(listData, current => current.parentNum === item.num).map(items => {
                                                                            return (
                                                                                <div key={`${items.parentNum}-first-${items.num}`}  className="custome-filter-driver-two">
                                                                                    <Form.Item>
                                                                                        {
                                                                                            getFieldDecorator(`${items.parentNum}-first-${items.num}`,
                                                                                                {
                                                                                                    initialValue: (!_isEmpty(items.field) && items.field.id) || undefined,
                                                                                                    rules: [{ required: true }]
                                                                                                }
                                                                                            )(
                                                                                                <Select placeholder={locales.components.pages.connectedByConversation.list.classification} onChange={actions.customeFilterThreeChange.params(item, items, _find(fieldsData, current => current.source === item.source), `${items.parentNum}-first-${items.num}`)}>
                                                                                                    {
                                                                                                        _find(fieldsData, current => current.source === item.source) && _find(fieldsData, current => current.source === item.source).list.map(itemss => {
                                                                                                            return (
                                                                                                                <Option key={itemss.id} value={itemss.id}>{itemss.label}</Option>
                                                                                                            );
                                                                                                        })
                                                                                                    }
                                                                                                </Select>
                                                                                            )
                                                                                        }

                                                                                    </Form.Item>
                                                                                    <Form.Item>
                                                                                        {
                                                                                            getFieldDecorator(`operator-${items.parentNum}-first-${items.num}-1`,
                                                                                                {
                                                                                                    initialValue: items.operator || undefined,
                                                                                                    rules: [{ required: true }]
                                                                                                }
                                                                                            )(
                                                                                                <Select placeholder={locales.components.pages.connectedByConversation.list.choose} onChange={actions.fieldsChange.params(item, items)}>
                                                                                                    {
                                                                                                        items.field && _includes(fieldTypeListString, items.field.fieldType) ? Udesk.enums.fieldsOperatorString.map(itemss => {
                                                                                                            return (
                                                                                                                <Option  key={itemss.id} value={itemss.id}>{itemss.name}</Option>
                                                                                                            );
                                                                                                        }) : items.field && _includes(fieldTypeListNumber, items.field.fieldType) ? Udesk.enums.fieldsOperatorNumber.map(itemss => {
                                                                                                            return (
                                                                                                                <Option value={itemss.id}>{itemss.name}</Option>
                                                                                                            );
                                                                                                        }) : items.field && _includes(fieldTypeListTime, items.field.fieldType) ? Udesk.enums.fieldsOperatorDateTime.map(itemss => {
                                                                                                            return (
                                                                                                                <Option value={itemss.id}>{itemss.name}</Option>
                                                                                                            );
                                                                                                        }) : items.field && _includes(fieldTypeListSource, items.field.fieldType) ? Udesk.enums.segmentedData.map(itemss => {
                                                                                                            return (
                                                                                                                <Option value={itemss.id}>{itemss.name}</Option>
                                                                                                            );
                                                                                                        }) : null

                                                                                                    }
                                                                                                </Select>

                                                                                            )
                                                                                        }

                                                                                    </Form.Item>
                                                                                    {
                                                                                        items.field && _includes(fieldTypeListTime, items.field.fieldType) && _includes(timeDifferStates, items.operator) && (
                                                                                            <span className='customer-filter-driver-company'>
                                                                                                <Form.Item>
                                                                                                    {
                                                                                                        getFieldDecorator(`value-${items.parentNum}-first-${items.num}-2`,
                                                                                                            {
                                                                                                                initialValue: items.value === 0 ? items.value : parseInt(items.value, 10) || undefined,
                                                                                                                rules: [{ required: true, }]
                                                                                                            }
                                                                                                        )(
                                                                                                            <InputNumber placeholder={locales.components.pages.connectedByConversation.list.input} min={0} onChange={actions.fieldChangedInput.params(item, items)} />
                                                                                                        )
                                                                                                    }
                                                                                                </Form.Item>
                                                                                                <Form.Item>
                                                                                                    {
                                                                                                        getFieldDecorator(`unit-${items.parentNum}-first-${items.num}-3`,
                                                                                                            {
                                                                                                                initialValue: items.unit || undefined,
                                                                                                                rules: [{ required: true }]
                                                                                                            }
                                                                                                        )(
                                                                                                            <Select placeholder={locales.components.pages.connectedByConversation.list.choose} onChange={actions.fieldChangedTime.params(item, items)} >
                                                                                                                {
                                                                                                                    Udesk.enums.dateTimeCompany.map(itemss => {
                                                                                                                        return (
                                                                                                                            <Option value={itemss.id}>{itemss.name}</Option>
                                                                                                                        );
                                                                                                                    })
                                                                                                                }
                                                                                                            </Select>
                                                                                                        )
                                                                                                    }

                                                                                                </Form.Item>
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        items.field && _includes(fieldTypeListString, items.field.fieldType) && _includes(beginAndEndEqual, items.operator) && (
                                                                                            <span className='customer-filter-driver-company'>
                                                                                                <Form.Item>
                                                                                                    {
                                                                                                        getFieldDecorator(`value-${items.parentNum}--first-${items.num}-4`,
                                                                                                            {
                                                                                                                initialValue: items.value === 0 ? items.value : items.value || undefined,
                                                                                                                rules: [{ required: true, }, {
                                                                                                                    validator: (rule, value, callback) => {
                                                                                                                        if (value > 200) {
                                                                                                                            callback('');
                                                                                                                            Udesk.ui.notify.error(locales.components.pages.connectedByConversation.list.fiedldlengthNo);
                                                                                                                        }
                                                                                                                        callback();
                                                                                                                    }
                                                                                                                }]
                                                                                                            }
                                                                                                        )(
                                                                                                            <InputNumber placeholder={locales.components.pages.connectedByConversation.list.input} min={0} onChange={actions.beginAndEndInput.params(item, items)} />
                                                                                                        )
                                                                                                    }
                                                                                                    <span style={{ marginLeft: '5px' }}>{locales.components.pages.connectedByConversation.list.fieldwei}</span>
                                                                                                </Form.Item>

                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        items.field && _includes(fieldTypeListNumber, items.field.fieldType) && _includes(inputNumberprice, items.operator) && (
                                                                                            <span className='customer-filter-driver-company'>
                                                                                                <Form.Item>
                                                                                                    {
                                                                                                        getFieldDecorator(`value-${items.parentNum}-first-${items.num}-5`,
                                                                                                            {
                                                                                                                initialValue: items.value === 0 ? items.value : items.value || undefined,
                                                                                                                rules: [{ required: true }, {
                                                                                                                    validator: (rule, value, callback) => {
                                                                                                                        if (value > 200) {
                                                                                                                            callback('');
                                                                                                                            Udesk.ui.notify.error(locales.components.pages.connectedByConversation.list.fiedldlengthNo);
                                                                                                                        }
                                                                                                                        callback();
                                                                                                                    }
                                                                                                                }]
                                                                                                            }
                                                                                                        )(
                                                                                                            <InputNumber placeholder={locales.components.pages.connectedByConversation.list.input} min={0} max={200} onChange={actions.numberChangeInput.params(item, items)} />
                                                                                                        )
                                                                                                    }
                                                                                                </Form.Item>

                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                    <Form.Item>
                                                                                        {
                                                                                            getFieldDecorator(`${items.parentNum}-first-${items.num}-6-${items.parentNum}-second`,
                                                                                                {
                                                                                                    initialValue: (!_isEmpty(items.fieldFrom) && items.fieldFrom.id) || undefined,
                                                                                                    rules: [{ required: true }]
                                                                                                }
                                                                                            )(
                                                                                                <Select placeholder={locales.components.pages.connectedByConversation.list.classification} onChange={actions.customeFilterThreeChangeFrom.params(item, items, _find(fieldsData, current => current.source === item.sourceFrom))}>
                                                                                                    {
                                                                                                        _find(fieldsData, current => current.source === item.sourceFrom) && _find(fieldsData, current => current.source === item.sourceFrom).list.map(itemss => {
                                                                                                            return (
                                                                                                                <Option key={itemss.id} value={itemss.id} disabled={!(items.field.fieldType && itemss.fieldType && _isEqual(items.field.fieldType, itemss.fieldType))}>{itemss.label}</Option>
                                                                                                            );
                                                                                                        })
                                                                                                    }
                                                                                                </Select>
                                                                                            )
                                                                                        }

                                                                                    </Form.Item>
                                                                                    <div className='customer-filter-driver-circle-del' onClick={actions.delTwoCustome.params(items)}><i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao"></i></div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                })
                                            }
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </div>
                        <div className='qa-react-list-connect-by-converstaion-save-or-cancel'>
                            <Space>
                                <Button type='primary' loading={loading} onClick={actions.dataCallRelatedSaved}>{locales.components.pages.connectedByConversation.list.save}</Button>
                                <Button onClick={actions.dataCallRelatedCancel}>{locales.components.pages.connectedByConversation.list.cancel}</Button>
                            </Space>

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function getHeight() {
    let dom = $('.custome-filter-driver-two-box');
    if (dom) {
        let length = dom.length ? dom.length : 1;
        let height = dom.get(length - 1) ? dom.get(length - 1).offsetHeight : 0;
        return height + $('.qa-react-list-connenct-by-converstaion-custome-filter').height() + 284;
    }
    return $('.qa-react-list-connenct-by-converstaion-custome-filter').height() + 284;
}