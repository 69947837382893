import React, { useCallback, useEffect } from "react";
import { Drawer, Tabs } from "udesk-ui";
import { forwardRef, useImperativeHandle, useState } from 'react';
import CustomerDynamics from "./customerDynamics";
import SessionDetails from "./sessionDetails";
import moment from 'moment';
import {formatTime} from '../../table/components/customer-tooltip';
import './index.less';
import UdeskLocales from 'UdeskLocales';

const sort = (list) => {
    return list.sort((a, b) => {
        return moment(a.time).isBefore(moment(b.time));
    });
};

type Props = {
  ref: any;
  monthData: any[]
};

export type RefProps = {
  open: (key: string, date?: string) => void;
};

export default forwardRef<RefProps, Props>((props, ref) => {
    const {monthData} = props;
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState('1');
    const [date, setDate] = useState('');
    const [customerDynamicList, setCustomerDynamicList] = useState<any[]>([]);
    const [customerRiskList, setCustomerRiskList] = useState<any[]>([]);
    const [communicateList, setCommunicateList] = useState<any[]>([]);


    const closeHandle = () => {
        setIsVisible(false);
    };
    const openHandle = useCallback((activeKey, date?) => {
        setActiveKey(activeKey);
        if (date) {
            setDate(date);
        }
        setIsVisible(true);
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            open(key: string, date?: string) {
                openHandle(key, date);
            },
        }),
        [openHandle],
    );

    const onChange = (activeKey: string) => {
        setActiveKey(activeKey);
    };

    useEffect(() => {
        const cache = monthData.reduce((cache, item) => {
            if (item.customerDynamicList) {
                cache.customerDynamicList.splice(0, 0, ...item.customerDynamicList.map(item => ({
                    time: item.hitTime,
                    timeStr: formatTime(item.hitTime),
                    content: item.content,
                })));
            }
            if (item.customerRiskList) {
                cache.customerRiskList.splice(0, 0, ...item.customerRiskList.map(item => ({
                    time: item.hitTime,
                    timeStr: formatTime(item.hitTime),
                    content: item.description
                })));
            }
            if (item.managerCommunicateList) {
                cache.communicateList.splice(0, 0, ...item.managerCommunicateList.map(item => ({
                    isManagerCommunicate: true,  
                    time: item.hitTime,
                    timeStr: formatTime(item.hitTime),
                    content: item.suggest
                })));
            }
            if (item.communicateStrategyList) {
                cache.communicateList.splice(0, 0, ...item.communicateStrategyList.map(item => ({
                    time: item.hitTime,
                    timeStr: formatTime(item.hitTime),
                    content: item.pushText
                })));
            }

            return cache;
        }, {
            customerDynamicList: [],
            customerRiskList: [],
            communicateList: []
        });
        setCustomerDynamicList(sort(cache.customerDynamicList));
        setCustomerRiskList(sort(cache.customerRiskList));
        setCommunicateList(sort(cache.communicateList));
    }, [
        monthData
    ]);

    return (
        <Drawer
            width={660}
            className={"business-drawer beautiful-scrollbar"}
            visible={isVisible}
            onClose={closeHandle}
        >
            <Tabs
                activeKey={activeKey}
                onChange={onChange}
            >
                <Tabs.TabPane tab={/* 会话详情 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.modal.index.sessionDetails} key="1">
                    <SessionDetails date={date} {...props}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={/* 客户动态 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.modal.index.customerDynamics} key="2">
                    <CustomerDynamics data={customerDynamicList}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={/* 风险点 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.modal.index.riskPoint} key="3">
                    <CustomerDynamics data={customerRiskList}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={/* 跟进建议 */UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.modal.index.followUpSuggestions} key="4">
                    <CustomerDynamics data={communicateList}/>
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    );
});
