import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

import Page from 'Component/common/udesk-qa-page-layout';

import './style.scss';
import { CommonPage, StandardizeTable } from 'udesk_gm_ui';
import { Button, Input, Icon, Modal, FormBuilder } from 'udesk-ui';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import {
    getCommunicateStrategyCategory,
    postCommunicateStrategyCategory,
} from 'src/api/communicateStrategy/category';
// @ts-ignore
import { BaseResponseListCommunicateStrategyCategoryFoundResponse } from 'src/api/types';
import { getCommunicateStrategy } from 'src/api/communicateStrategy';
import {
    deleteCommunicateStrategyById,
    putCommunicateStrategyById,
} from 'src/api/communicateStrategy/{id}';
import {
    deleteCommunicateStrategyCategoryById,
    putCommunicateStrategyCategoryById,
} from 'src/api/communicateStrategy/category/{id}';

const DEFAULT_PAGE = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const ClassificationTitle = (props) => {
    const { treeData, setTreeData, setLoading, storePageInfo, requestClassData } = props;
    const locales = UdeskLocales['current'];

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                {/* 沟通策略分组 */}
                {
                    UdeskLocales['current'].pages.gong.businessConfiguration.communicationStrategy
                        .list.index.communicationStrategyGrouping
                }
            </div>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    if (treeData.some((i) => i.key === 'new-class')) return;
                    setTreeData((prev) => [
                        ...prev,
                        {
                            title: (
                                <Input
                                    autoFocus
                                    size="small"
                                    placeholder={
                                        /* 按回车新建 */ locales.pages.intelligentAnalysis
                                            .supervision.index.pressEnterToCreateANew
                                    }
                                    onPressEnter={(e) => {
                                        if (!(e.target as any).value) {
                                            Udesk.ui.notify.error(
                                                /* 名称不能为空 */ locales.pages.intelligentAnalysis
                                                    .supervision.index.nameCannotBeEmpty
                                            );
                                            return;
                                        }
                                        postCommunicateStrategyCategory({
                                            categoryName: (e.target as any).value,
                                        }).then(() => {
                                            requestClassData()
                                                .then((resp) => {
                                                    storePageInfo((prev) => ({
                                                        ...prev,
                                                        current: 1,
                                                        classificationKey: resp.data?.[0]?.id,
                                                    }));
                                                })
                                                .finally(() => {
                                                    setLoading(false);
                                                });
                                        });
                                    }}
                                    addonAfter={
                                        <Button
                                            type="link"
                                            icon={<Icon type="CloseOutlined" antdIcon={true} />}
                                            size="small"
                                            onClick={() =>
                                                setTreeData((prev) =>
                                                    prev.filter((i) => i.key !== 'new-class')
                                                )
                                            }
                                        />
                                    }
                                ></Input>
                            ),
                            key: 'new-class',
                        },
                    ]);
                }}
            >
                {/* 新建 */}
                {locales.pages.intelligentAnalysis.supervision.index.newlyBuild}
            </Button>
        </div>
    );
};

function Supervision(props) {
    const locales = UdeskLocales['current'];
    const { pageInfo = {}, storePageInfo } = CommonPage.usePageStateMemo();

    const [pagination, setPagination] = useState(DEFAULT_PAGE);

    const [dataSource, setDataSource] = useState<any[]>([]);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [mounted, setMounted] = useState<boolean>(false);
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [currentClass, setCurrentClass] = useState<any>(null);
    const [form] = FormBuilder.useForm();

    const deleteItem = useCallback(
        (id) => {
            return deleteCommunicateStrategyById({
                segments: {
                    id,
                },
            }).then(() => {
                requestList({
                    categoryId: pageInfo.classificationKey,
                    pageNum: pagination.current,
                    pageSize: pagination.pageSize,
                    name: pageInfo.name,
                });
            });
        },
        [pageInfo.classificationKey, pagination, pageInfo.name]
    );

    const columns: StandardizeTableProps['table']['columns'] = useMemo(() => {
        return [
            {
                title: /* 名称 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.list.index.name,
                key: 'name',
                dataIndex: 'name',
                width: 100,
                ellipsis: true,
            },
            {
                title: /* 推送文字 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.list.index.pushText,
                key: 'pushText',
                dataIndex: 'pushText',
                ellipsis: true,
                render: (text) => {
                    return (
                        <div
                            style={{ whiteSpace: 'break-spaces' }}
                            dangerouslySetInnerHTML={{ __html: text }}
                        ></div>
                    );
                },
            },
            {
                title: /* 有效性 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.list.index.effectiveness,
                key: 'isEnable',
                dataIndex: 'isEnable',
                renderType: 'switch',
                onChange: (checked, record, index) => {
                    putCommunicateStrategyById(
                        {
                            ...record,
                            isEnable: checked ? 1 : 0,
                        },
                        {
                            segments: {
                                id: record.id,
                            },
                            successMsg: checked
                                ? /* 开启成功 */ UdeskLocales['current'].pages.gong
                                      .businessConfiguration.communicationStrategy.list.index
                                      .openSuccessfully
                                : /* 关闭成功 */ UdeskLocales['current'].pages.gong
                                      .businessConfiguration.communicationStrategy.list.index
                                      .closeSuccessfully,
                            errorMsg:
                                /* 操作失败 */ UdeskLocales['current'].pages.gong
                                    .businessConfiguration.communicationStrategy.list.index
                                    .operationFailed,
                        }
                    ).then(() => {
                        record.isEnable = checked ? 1 : 0;
                        setDataSource((prev) => [...prev]);
                    });
                },
            },
            {
                title: /* 操作 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.list.index.operation,
                key: 'actions',
                dataIndex: 'actions',
                width: 100,
                renderType: 'actions',
                buttons: [
                    {
                        label: /* 编辑 */ UdeskLocales['current'].pages.gong.businessConfiguration
                            .communicationStrategy.list.index.edit,
                        onClick(item) {
                            let routeOptions = {
                                history: props.history,
                                routeName: 'communicationStrategyDetail',
                                pathParams: { id: item.id },
                                queryParams: {
                                    classificationId: pageInfo.classificationKey,
                                },
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                    },
                    {
                        label: /* 删除 */ UdeskLocales['current'].pages.gong.businessConfiguration
                            .communicationStrategy.list.index.delete,
                        danger: true,
                        onClick(item) {
                            deleteItem.call(null, item.id);
                        },
                    },
                ],
            },
        ];
    }, [deleteItem, props.history, pageInfo.classificationKey, pagination]);

    const toolBarRightButtons = useMemo(() => {
        return [
            {
                label: /* 新建 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.list.index.newlyBuild,
                mainBtn: true,
                onClick: () => {
                    const routeOptions = {
                        history: props.history,
                        routeName: 'communicationStrategyCreate',
                        queryParams: {
                            classificationId: pageInfo.classificationKey,
                        },
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                },
            },
        ];
    }, [pageInfo.classificationKey, pagination, props.history]);

    const requestClassData = useCallback(() => {
        return new Promise<BaseResponseListCommunicateStrategyCategoryFoundResponse>((res, rej) => {
            getCommunicateStrategyCategory({
                params: {
                    pageSize: 100,
                    pageNum: 1,
                },
            })
                .then((resp) => {
                    setTreeData(
                        (resp.data ?? []).map((i) => ({ key: i.id, title: i.categoryName }))
                    );
                    res(resp);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, [pageInfo.classificationKey, storePageInfo]);

    useEffect(() => {
        requestClassData().then((resp) => {
            if (resp.data?.[0] && !pageInfo.classificationKey) {
                storePageInfo((prev) => ({
                    ...prev,
                    current: 1,
                    classificationKey: resp.data?.[0]?.id,
                }));
            }
        });
    }, []);

    const requestList = useCallback((params) => {
        setTableLoading(true);
        setMounted(true);
        getCommunicateStrategy({ params })
            .then((resp) => {
                setDataSource(resp.data ?? []);
                setPagination((prev) =>
                    Object.assign({}, prev, resp.paging, { current: resp.paging?.pageNum })
                );
            })
            .finally(() => {
                setTableLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!pageInfo.classificationKey) return;
        requestList({
            categoryId: pageInfo.classificationKey,
            pageNum: pageInfo.current ?? 1,
            pageSize: pagination.pageSize,
            name: pageInfo.name,
        });
    }, [pageInfo.classificationKey]);

    const filterFields: CommonFilterProps['fields'] = [
        {
            type: 'Input',
            name: 'name',
            label: /* 关键词 */ locales.pages.intelligentAnalysis.supervision.index.keyWord,
            props: {
                placeholder: UdeskLocales['current'].labels.pleaseEnter,
            },
        },
    ];
    const [filterInitialValues] = useState({ name: pageInfo.name });
    useEffect(() => {
        if (visible) {
            form.setFieldsValue({ categoryName: currentClass.title });
        }
    }, [visible]);
    return (
        <Page
            title={
                /* 沟通策略 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.list.index.communicationStrategy
            }
            padding={0}
            pageBodyClassName="supervision-list-page"
            backGroundGhost={true}
        >
            <StandardizeTable
                filter={{
                    fields: filterFields,
                    initialValues: mounted ? {} : filterInitialValues,
                    onSearch: (v, o) => {
                        storePageInfo((prev) => ({
                            ...prev,
                            name: v.name,
                        }));
                        requestList({
                            categoryId: pageInfo.classificationKey,
                            pageNum: 1,
                            pageSize: pagination.pageSize,
                            ...v,
                        });
                    },
                }}
                table={{
                    columns,
                    dataSource,
                    loading: tableLoading,
                    pagination: {
                        ...pagination,
                        onChange: (page, pageSize) => {
                            requestList({
                                categoryId: pageInfo.classificationKey,
                                pageNum: page,
                                pageSize: pageSize,
                                name: pageInfo.name,
                            });
                        },
                    },
                    cacheId: 'aaa',
                }}
                toolBarRightButtons={toolBarRightButtons}
                classification={{
                    title: (
                        <ClassificationTitle
                            {...{
                                treeData,
                                setTreeData,
                                setLoading,
                                storePageInfo,
                                requestClassData,
                            }}
                        />
                    ),
                    loading,
                    treeData,
                    selectedKeys: [pageInfo.classificationKey],
                    treeNodeActionMenu: [
                        {
                            label: /* 编辑 */ locales.pages.intelligentAnalysis.supervision.index
                                .edit,
                            onClick: (item) => {
                                setCurrentClass(item);
                                setVisible(true);
                            },
                            disabled: (item) => item.itemKey === 'new-class',
                        },
                        {
                            label: /* 删除 */ locales.pages.intelligentAnalysis.supervision.index
                                .delete,
                            danger: true,
                            onClick: (item) => {
                                deleteCommunicateStrategyCategoryById({
                                    segments: {
                                        id: item.itemKey,
                                    },
                                    successMsg:
                                        /* 删除成功 */ locales.pages.intelligentAnalysis.supervision
                                            .index.deleteSucceeded,
                                }).then(() => {
                                    requestClassData().then((resp: any) => {
                                        storePageInfo((prev) => ({
                                            ...prev,
                                            current: 1,
                                            classificationKey: resp.data?.[0]?.id,
                                        }));
                                        requestList({
                                            categoryId: resp.data?.[0]?.id,
                                            pageNum: pageInfo.current,
                                            pageSize: pagination.pageSize,
                                            name: pageInfo.name,
                                        });
                                    });
                                });
                            },
                            disabled: (item) => item.itemKey === 'new-class',
                        },
                    ],
                    onSelect: (key, options) => {
                        if (key === 'new-class') return;
                        storePageInfo({
                            ...pageInfo,
                            classificationKey: key,
                        });
                    },
                }}
            />
            <Modal
                visible={visible}
                title={
                    /* 编辑分组 */ UdeskLocales['current'].pages.gong.businessConfiguration
                        .communicationStrategy.list.index.editGroup
                }
                onCancel={() => {
                    setVisible(false);
                    setCurrentClass(null);
                }}
                onOk={() => {
                    form.submit();
                }}
                // currentClassificationId={currentClass?.itemKey}
            >
                <FormBuilder
                    onFinish={(values) => {
                        setVisible(false);
                        putCommunicateStrategyCategoryById(values, {
                            segments: {
                                id: currentClass.itemKey,
                            },
                        }).then(() => {
                            setCurrentClass(null);
                            requestClassData();
                        });
                    }}
                    fields={[
                        {
                            name: 'categoryName',
                            type: 'Input',
                            label: /* 分组名称 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.communicationStrategy.list.index.groupName,
                            props: {
                                placeholder: UdeskLocales['current'].labels.pleaseEnter,
                            },
                        },
                    ]}
                    footerRender={false}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    form={form}
                />
            </Modal>
        </Page>
    );
}

class Component extends React.Component {
    render() {
        return <Supervision {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
