import React from 'react';
import Udesk from 'Udesk';
import _extend from 'lodash-es/extend';
import _cloneDeep from 'lodash-es/cloneDeep';
import { Modal } from 'udesk-ui';
import { getSearchTemplateNoComplianceByTaskId } from 'src/api/search-template/noCompliance/{taskId}';
// import { postInspectionDataDetailByIdRecheck } from 'src/api/inspectionData/detail/{id}/recheck';
import { postSmartTagsRedo } from 'src/api/smartTags/redo';
import { getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

const { confirm } = Modal;

let RelatedDatasPaging = {
    pageNum: 1,
    pageSize: 10,
};

let timer = null;
let FLOWTYPE = 3;
let MARKTYPE = 1;
let NACHECKED = 1;
class QualityInspectionListDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        detail: {},
        smartTags: [],
        changeList: [],
        qualityCheckScoreSaving: false,
        testSetList: null,
        testSetListFlag: false,
        caseLibraryId: null,
        relatedDatas: [],
        storages: {
            relatedDatasPaging: {
                ...RelatedDatasPaging,
            },
            conditionList: [],
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            customJudgeLogic: '',
            keywords: '',
        },
        relatedLoading: false,
        task: null,
        pointCategoryList: [],
        handMarkMapList: {},
        pointChildrenPerson: {},
        pointChildrenComputer: {},
        resetStatus: false,
        pageTransToId: null,
        evaluateFlag: false,
        evaluteVisibe: false,
        enabledEvaluate: false,
        callDetailsList: [],
        activeCallId: null,
        related: false,
        renderLoading: false,
        relatedRecordsLoading: false,
        wrapDom: React.createRef(),
        xhr: null,
        loadMoreXhr: null,
        isInformationCollect: false,
        reInspectionConfigVisible: false,
        allPoints: true,
        reInspectionPoints: [],
        reInspectionType: Udesk.enums.reInspectionTypes.recalcScore.id,
        reCheckLoading: false,
        progressStatus: 1,
        reInspectionModalVisible: false,
        alreadyDoneCount: 0,
        progressValue: '0',
        recheckProcessTotal: 0,
        timerToken: null,
        inspectSearchDefaultShowFields: null,
        fastFilterConditions: [],
        stateParamsType: '',
        circlePercent: 0,
        showHit: 0,
        isTicket: false,
        samplingTargetBannerKey: Math.random(),
        relatedIndexPointTagMap: {},
    };
    static computes = {};

    actions = {
        reInspectionTypeChanged(reInspectionType) {
            this.privates.reInspectionType = reInspectionType;
            this.actions.update();
        },
        onPointsCheckedChange(points, allPoints) {
            this.privates.reInspectionPoints = points;
            this.privates.allPoints = allPoints;
            this.actions.update();
        },
        // 重新质检设置窗口
        changeReInspectionConfigVisible() {
            let { reInspectionConfigVisible } = this.privates;
            // let { conditionList } = this.privates.storages;

            // if (!reInspectionConfigVisible && conditionList.length === 0) {
            //     Udesk.ui.notify.error(this.locales.labels.pleaseSetFilter);
            //     return;
            // }
            // if (!this.privates.taskStatus) {
            //     this.setState({
            //         showModel: true,
            //     });
            //     return;
            // }
            this.privates.reInspectionConfigVisible = !reInspectionConfigVisible;
            this.privates.circlePercent = 0;
            this.actions.update();
        },
        // 重新质检
        reInspection() {
            let { task, reInspectionConfigVisible } = this.privates;
            // let { conditionList, judgeStrategy, customJudgeLogic, keywords } = this.privates.storages;
            let { sdkOptions, match } = this.props;

            this.privates.reInspectionConfigVisible = !reInspectionConfigVisible;
            this.actions.update();
            this.privates.progressStatus = null;
            let taskId = match.params.taskId;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${taskId}/recheck`,
                sdkOptions
            );
            let reCheckCondition = getReCheckCondition(this);
            if (
                reCheckCondition.reCheckType === Udesk.enums.reInspectionTypes.recheckPoint.id &&
                (!reCheckCondition.inspectionPoints ||
                    reCheckCondition.inspectionPoints.length === 0)
            ) {
                Udesk.ui.notify.error(
                    this.locales.components.pages.qualityInspectionList.pleaseSelectRecheckPoints
                );
                return;
            }
            let params = {
                taskId,
                reCheckCondition,
                conditionList: [
                    {
                        field: {
                            id: 'f_200',
                            name: 'call_id',
                            label: /* 通话ID */ this.locales.pages.qualityInspectionList.detail
                                .component.callID,
                            fieldName: 'call_id',
                            fieldType: 2,
                            defaultValue: null,
                            unit: null,
                            optionValueList: null,
                            slotFieldName: '',
                            inspectDataSourceType: 2,
                            inspectionTaskId: null,
                            operators: null,
                            bindFieldType: null,
                        },
                        operator: 'is',
                        value: this.privates.detail.callId,
                    },
                ],
                judgeStrategy: 1,
                customJudgeLogic: '',
                inspectionId: task.id,
                systemModule: getSystemModule(),
            };
            if (this.privates.reCheckLoading) return;
            this.privates.reCheckLoading = true;
            this.actions.update();
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    this.privates.reInspectionModalVisible = true;
                    this.privates.alreadyDoneCount = 0;
                    this.privates.progressValue = 0;
                    this.privates.recheckProcessTotal = resp.data.total;
                    this.privates.progressStatus = Udesk.enums.recheckStatus.waitForExecution.id;
                    // this.privates.timerToken = setInterval(() => {
                    //     this.actions.queryReInspectionProgress(resp.data);
                    // }, 5 * 1000);
                    Udesk.ui.notify.success(
                        /* 开始执行重新质检 */ UdeskLocales['current'].pages.qualityInspectionList
                            .detail.component.startPerformingReQualityInspection
                    );
                    this.privates.reCheckLoading = false;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.reCheckLoading = false;
                    this.actions.update();
                }
            );
        },
        // reInspection() {
        //     let { reInspectionConfigVisible, circlePercent } = this.privates;
        //     let { match } = this.props;
        //     this.privates.reInspectionModalVisible = true;
        //     let timer = null;
        //     // timer = setInterval(() => {
        //     //     let step = Math.floor(Math.random() * 20 + 1);
        //     //     circlePercent += step;
        //     //     this.privates.circlePercent = circlePercent;
        //     //     this.actions.update();
        //     //     if (circlePercent >= 80 && circlePercent < 100) {
        //     //         this.privates.circlePercent = 90;
        //     //         this.actions.update();
        //     //         clearInterval(timer);
        //     //     }
        //     // }, 500);

        //     this.privates.reInspectionConfigVisible = !reInspectionConfigVisible;
        //     this.actions.update();
        //     this.privates.progressStatus = null;
        //     let taskId = match.params.taskId;
        //     let reCheckCondition = getReCheckCondition(this);
        //     if (
        //         reCheckCondition.reCheckType === Udesk.enums.reInspectionTypes.recheckPoint.id &&
        //         (!reCheckCondition.inspectionPoints || reCheckCondition.inspectionPoints.length === 0)
        //     ) {
        //         Udesk.ui.notify.error(this.locales.components.pages.qualityInspectionList.pleaseSelectRecheckPoints);
        //         return;
        //     }
        //     let params = {
        //         taskId,
        //         reCheckCondition,
        //         callId: this.privates.detail.callId,
        //     };
        //     if (this.privates.reCheckLoading) return;
        //     this.privates.reCheckLoading = true;
        //     this.privates.detail.id = null;
        //     this.actions.update();
        //     postInspectionDataDetailByIdRecheck(params, { segments: { id: this.privates.detail.callId } }).then(
        //         (resp) => {
        //             this.privates.circlePercent = 100;
        //             clearInterval(timer);
        //             setTimeout(() => {
        //                 this.privates.reInspectionModalVisible = false;
        //                 this.actions.update();
        //             }, 500);
        //             this.privates.alreadyDoneCount = 0;
        //             this.privates.progressValue = 0;
        //             this.privates.recheckProcessTotal = resp.data.total;
        //             this.privates.progressStatus = Udesk.enums.recheckStatus.waitForExecution.id;
        //             this.privates.reCheckLoading = false;
        //             this.actions.update();
        //             this.actions.reloadAsyncModel();
        //         },
        //         (reason) => {
        //             this.privates.reInspectionModalVisible = false;
        //             this.actions.update();
        //             Udesk.ui.notify.error(reason.errorMsg);
        //         }
        //     );
        // },
        handlerModalProgressCancel() {
            this.privates.reInspectionModalVisible = false;
            this.actions.update();
        },
        // 结束重新质检
        endReQualityInspection() {
            let { locales } = this;
            let { sdkOptions, match } = this.props;
            let taskId = match.params.taskId;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${taskId}/recheck/stop`,
                sdkOptions
            );
            let params = {
                id: taskId,
            };
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(
                        locales.components.pages.qualityInspectionList.endReInspectionSuccess
                    );
                    this.actions.closeReInspection();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        // 关闭重新质检窗口
        closeReInspection(bool) {
            this.privates.reInspectionModalVisible = false;
            this.actions.update();
            let timerToken = this.privates.timerToken;
            if (timerToken != null) {
                clearInterval(timerToken);
            }
            if (bool && bool === true) {
                this.actions.reloadAsyncModel();
            }
        },
        showHitChange(showHit) {
            this.privates.showHit = showHit ? 1 : 0;
            this.actions.update();
        },
        backToIndexPage() {
            let libraryId = this.props.location.search.split('=')[1];
            let { testSetListFlag } = this.privates;
            let routeOptions = {
                history: this.props.history,
                routeName: this.privates.isWechatQa
                    ? 'wechatQualityInspectionListIndex'
                    : this.privates.isInformationCollect
                    ? 'informationCollectListIndex'
                    : 'qualityInspectionListIndex',
                pathParams: {
                    taskId: this.props.match.params.taskId,
                },
            };
            if (libraryId && libraryId !== 'true') {
                routeOptions = _extend(routeOptions, {
                    routeName: 'caseBaseIndex',
                    state: { libraryId },
                });
            }
            if (testSetListFlag && testSetListFlag === 'true') {
                routeOptions = _extend(routeOptions, { routeName: 'testSetListIndex' });
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        changePages(type, cb) {
            let { sdkOptions, route } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/next/${this.privates.detail.id}/${type}`,
                sdkOptions
            );
            if (route.name === 'testSetListDetail') {
                url = Udesk.business.apiPath.concatApiPath(
                    `inspection-test-set/next/${this.privates.detail.id}/${type}`,
                    sdkOptions
                );
            }
            if (route.name === 'caseBaseDetail') {
                url = Udesk.business.apiPath.concatApiPath(
                    `caseLibraryDatas/next/${this.privates.detail.id}/${type}`,
                    sdkOptions
                );
            }
            if (this.privates.isInformationCollect) {
                url = Udesk.business.apiPath.concatApiPath(
                    `informationDatas/next/${this.privates.detail.id}/${type}`,
                    sdkOptions
                );
            }
            if (timer) {
                clearTimeout(timer);
            }
            this.privates.renderLoading = true;
            this.actions.update();
            timer = setTimeout(() => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        const { nextId } = resp.data;
                        if (nextId) {
                            let { history, match, location } = this.props;
                            let routeOptions = {
                                history: history,
                                routeName: 'qualityInspectionListDetail',
                                pathParams: {
                                    taskId: match.params.taskId,
                                    id: nextId,
                                },
                            };
                            this.privates.pageTransToId = nextId;
                            if (route.name === 'testSetListDetail') {
                                routeOptions.routeName = 'testSetListDetail';
                                routeOptions.queryParams = {
                                    testSetFlag: true,
                                    highLightType: 1,
                                };
                            }
                            if (this.privates.isWechatQa) {
                                routeOptions.routeName = 'wechatQualityInspectionListDetail';
                            }
                            if (route.name === 'caseBaseDetail') {
                                routeOptions.routeName = 'caseBaseDetail';
                                routeOptions.queryParams = {
                                    libraryId: location.search.split('=')[1],
                                };
                            }
                            if (this.privates.isInformationCollect) {
                                routeOptions.routeName = 'informationCollectListDetail';
                            }
                            Udesk.ui.routing.transitionTo(routeOptions);
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        this.privates.renderLoading = false;
                        this.privates.samplingTargetBannerKey = Math.random();
                        this.actions.update();
                        typeof cb === 'function' && cb?.();
                    }
                );
            }, 300);
        },
        qualityCheckScoreCanceled() {
            this.actions.reloadAsyncModel();
        },
        qualityCheckScoreSaved(pointCategoryList, task) {
            this.privates.pointCategoryList = pointCategoryList;
            this.privates.task = task;
            this.actions.update();
        },
        qualityCheckScoreSubmitted(pointCategoryList, task) {
            let { sdkOptions, match } = this.props;
            let { locales } = this;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${task.id}/gradeFile?taskId=${match.params.taskId}`,
                sdkOptions
            );
            this.privates.qualityCheckScoreSaving = true;
            this.actions.update();
            Udesk.ajax.put(url).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.fileSuccessFormat,
                            locales.components.pages.components.qualityCheckScore.actualScore
                        )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.privates.resetStatus = !this.privates.resetStatus;
                    this.privates.handMarkMapList = {};
                    this.actions.update();
                    this.actions.reloadAsyncModel();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.fileFailedFormat,
                                locales.components.pages.components.qualityCheckScore.actualScore
                            )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.actions.update();
                }
            );
        },
        onUpdate() {
            this.actions.reloadAsyncModel({ id: this.props.match.params.id });
        },
        testSetload() {
            let { sdkOptions, match } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspection-test-set/${match.params.id}?taskId=${match.params.taskId}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.testSetList = resp.data;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        //相关记录查看更多
        parentLoadMore(value) {
            let { relatedDatas, detail } = this.privates;
            let { sdkOptions } = this.props;
            let relatedData = Object.assign({ id: detail.callId, pageSize: 10 }, value);
            let relatedDataUrl = Udesk.business.apiPath.concatApiPath(
                `data/related-data`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                this.privates.loadMoreXhr = Udesk.ajax.post(relatedDataUrl, relatedData);
                this.privates.loadMoreXhr.then(
                    (resp) => {
                        resolve(resp);
                        let list = [...relatedDatas, ...resp.data];
                        this.privates.relatedDatas = list;
                        this.privates.storages.relatedDatasPaging = resp.paging;
                        this.privates.relatedLoading = false;
                        this.privates.loadMoreXhr = null;
                        this.actions.update();
                    },
                    (reason) => {
                        if (reason.statusText !== 'abort') {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                        this.privates.relatedLoading = false;
                        this.privates.loadMoreXhr = null;
                        reject(reason);
                    }
                );
            });
        },
        cancel() {
            this.privates.resetStatus = !this.privates.resetStatus;
            this.actions.reloadAsyncModel({ id: this.props.match.params.id });
        },
        handMarkMap(value) {
            this.privates.handMarkMapList = value;
            this.actions.update();
        },
        activeChange(flag) {
            let { sdkOptions } = this.props;
            let activeValue = null;
            let { task } = this.privates;
            if (flag === 'true') {
                this.privates.activeFlag = 'true';
                activeValue = '1';
            } else if (flag === 'false') {
                this.privates.activeFlag = 'false';
                activeValue = '2';
            }

            let url1 = Udesk.business.apiPath.concatApiPath(
                `call/goodOrBad/${task.callId}?goodBadFlag=${activeValue}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url1).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.evaluteVisibe = false;
                        this.privates.evaluateFlag = true;
                        this.actions.save();
                        this.actions.update();
                    },
                    (reason) => {
                        reject(reason);
                    }
                );
            });
        },
        evaluateSave() {
            this.privates.evaluteVisibe = false;
            this.privates.evaluateFlag = true;
            this.actions.update();
            this.actions.save();
        },
        cancelSave() {
            this.privates.evaluteVisibe = false;
            this.actions.update();
        },
        save() {
            let { locales } = this;
            let { sdkOptions, match } = this.props;
            let { pointCategoryList, task, handMarkMapList, evaluateFlag } = this.privates;
            let pointDataList = getChangedPointDataList(pointCategoryList);
            let validateResult = validateItemScores(pointDataList);
            if (!validateResult) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.labels.saveSpotCheck
                    )
                );
            }
            if (!evaluateFlag && this.privates.enabledEvaluate) {
                this.privates.evaluteVisibe = true;
                this.actions.update();
                return;
            }
            let arr = _cloneDeep(pointDataList);
            arr.forEach((item) => {
                delete item.handMarkHighlightSeqMap;
            });
            let params = {
                taskId: match.params.taskId,
                flowType: FLOWTYPE,
                inspectionId: task.id,
                itemScores: arr,
                richRemark: task.richRemark,
                remark: task.remark,
            };

            let list = pointDataList.map((item) => {
                if (handMarkMapList[item.pointId]) {
                    let markIndices = [];
                    if (handMarkMapList[item.pointId] && handMarkMapList[item.pointId].length > 0) {
                        markIndices = handMarkMapList[item.pointId].map((item) => item.id);
                    }
                    return {
                        pointId: item.pointId,

                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        gradeChooseHit: item.gradeChooseHit,
                        markType: MARKTYPE,
                        markIndices,
                    };
                } else {
                    let markIndices = null;
                    if (item.handMarkHighlightSeqMap && item.handMarkHighlightSeqMap[1]) {
                        markIndices = item.handMarkHighlightSeqMap[1];
                    }
                    return {
                        pointId: item.pointId,

                        gradeChooseHit: item.gradeChooseHit,
                        pointType: item.type,
                        pointGradeType: item.gradeType,
                        markType: MARKTYPE,
                        markIndices,
                    };
                }
            });
            let pointFlag = true;
            for (let i = 0; i < list.length; i++) {
                if (list[i].pointType === Udesk.enums.pointTypes.machineLearning.id) {
                    if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitSub.id
                    ) {
                        if (list[i].gradeChooseHit === 1) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 0) {
                            list[i].markIndices = null;
                        }
                    } else if (
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotAdd.id ||
                        list[i].pointGradeType === Udesk.enums.strategyTypes.hitNotSub.id
                    ) {
                        if (list[i].gradeChooseHit === 0) {
                            if (!list[i].markIndices || list[i].markIndices.length === 0) {
                                pointFlag = false;
                                break;
                            }
                        } else if (list[i].gradeChooseHit === 1) {
                            list[i].markIndices = null;
                        }
                    }
                }
            }
            if (!pointFlag) {
                return Udesk.ui.notify.error(this.locales.labels.study);
            }
            let url = Udesk.business.apiPath.concatApiPath(`inspectionData/fastSample`, sdkOptions);
            let url1 = Udesk.business.apiPath.concatApiPath(`smartPointMarkResults`, sdkOptions);
            this.privates.qualityCheckScoreSaving = true;
            this.actions.update();
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, params).then(
                    (resp) => {
                        // saveRemark(task, this).then(() => {
                        //     resolve(resp);
                        // }).catch((reason) => {
                        //     reject(reason);
                        // });
                        resolve(resp);
                    },
                    (reason) => {
                        reject(reason);
                    }
                );
            })
                .then((resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locales.components.pages.components.qualityCheckScore.actualScore
                        )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    if (resp.data) {
                        let data = {
                            flowId: resp.data.id,
                            flowType: resp.data.flowType,
                            inspectId: resp.data.inspectionId,
                        };
                        let lists = list.map((item) => {
                            return {
                                ...item,
                                isHit: item.gradeChooseHit,
                                flowId: resp.data.id,
                                flowType: resp.data.flowType,
                                callId: resp.data.callId,
                                inspectId: resp.data.inspectionId,
                            };
                        });
                        data.smartPointMarks = lists;
                        new Promise((resolve, reject) => {
                            Udesk.ajax.post(url1, data).then(
                                (resp) => {
                                    resolve(resp);
                                    Udesk.ui.notify.success(this.locales.labels.studySuccess);
                                    this.privates.pointCategoryList = [];
                                    this.privates.handMarkMapList = {};
                                    this.privates.pointChildrenPerson = {};
                                    this.privates.pointChildrenComputer = {};
                                    this.actions.update();
                                },
                                (reason) => {
                                    reject(reason);
                                    Udesk.ui.notify.error(this.locales.labels.studyError);
                                }
                            );
                        }).then(() => {
                            Udesk.data.init.sampleNotToNext
                                ? this.actions.reloadAsyncModel()
                                : this.actions.changePages(2, this.actions.reloadAsyncModel);
                        });
                    }
                    this.privates.resetStatus = !this.privates.resetStatus;
                    this.privates.handMarkMapList = {};
                    this.actions.update();
                    // this.actions.reloadAsyncModel();
                })
                .catch((reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locales.components.pages.components.qualityCheckScore.actualScore
                            )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.actions.update();
                });
        },
        openSubmitModal() {
            confirm({
                title: this.locales.components.pages.qualityInspectionList.detail.fileConfirm,
                content: this.locales.components.pages.qualityInspectionList.detail.fileConfirmDesc,
                onOk: this.actions.submit,
            });
        },
        submit() {
            let { task } = this.privates;
            let { sdkOptions, match } = this.props;
            let { locales } = this;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${task.id}/gradeFile?taskId=${match.params.taskId}`,
                sdkOptions
            );

            this.privates.qualityCheckScoreSaving = true;
            this.actions.update();
            Udesk.ajax.put(url).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.fileSuccessFormat,
                            locales.components.pages.components.qualityCheckScore.actualScore
                        )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.privates.resetStatus = !this.privates.resetStatus;
                    this.privates.handMarkMapList = {};
                    this.actions.update();
                    this.actions.reloadAsyncModel();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.fileFailedFormat,
                                locales.components.pages.components.qualityCheckScore.actualScore
                            )
                    );
                    this.privates.qualityCheckScoreSaving = false;
                    this.actions.update();
                }
            );
            if (task.evaluateDto && task.evaluateDto.handValue) {
                let url1 = Udesk.business.apiPath.concatApiPath(
                    `call/goodOrBad/${task.callId}?goodBadFlag=${task.evaluateDto.handValue}`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.get(url1).then(
                        (resp) => {
                            resolve(resp);
                        },
                        (reason) => {
                            reject(reason);
                        }
                    );
                });
            }
        },
        evaluateChange(value) {
            this.privates.evaluateFlag = value;
            this.actions.update();
        },
        changeDialogRemarkList(newRemark) {
            const changeDialogRemarkList = (newDialogRemarkList) => {
                this.privates.dialogRemarkList = newDialogRemarkList.filter((i) => i.remark);
                if (this.privates.related) {
                    this.privates.callDetailsList.some((detail) => {
                        if (detail.callId === parseInt(this.privates.activeCallId, 10)) {
                            detail.remarkList = this.privates.dialogRemarkList;
                        }
                        return detail.callId === parseInt(this.privates.activeCallId, 10);
                    });
                }
                this.actions.update();
            };
            let { index, remark, id, type } = newRemark;
            if (id) {
                //更改逻辑
                let url = Udesk.business.apiPath.concatApiPath(
                    `inspectionRemarks/${id}`,
                    this.props.sdkOptions
                );
                let params = {
                    id: parseInt(id, 10),
                    index,
                    remark,
                    type,
                };
                return Udesk.ajax.put(url, params).then(
                    (resp) => {
                        let { dialogRemarkList } = this.privates;
                        dialogRemarkList.forEach((item) => {
                            if (item.index === index) {
                                item.remark = remark;
                            }
                        });
                        changeDialogRemarkList(dialogRemarkList.filter((i) => i.remark));
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            } else {
                //新增备注
                let url = Udesk.business.apiPath.concatApiPath(
                    `inspectionRemarks`,
                    this.props.sdkOptions
                );
                let params = {
                    dataId: parseInt(this.props.match.params.id, 10),
                    index,
                    type,
                    remark,
                    callId: this.privates.detail.callId,
                };
                return Udesk.ajax.post(url, params).then(
                    (resp) => {
                        let { data } = resp;
                        let { dialogRemarkList } = this.privates;
                        changeDialogRemarkList([...dialogRemarkList, data]);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            }
        },
        querryChangeList(smartTags) {
            // this.privates.smartTags = smartTags;
            // this.actions.update();
            let { sdkOptions, match } = this.props;
            let id = match.params.id;
            let taskId = match.params.taskId;
            let changeListUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${id}/changeList?taskId=${taskId}`,
                sdkOptions
            );
            Udesk.ajax.get(changeListUrl).then(
                (resp) => {
                    this.privates.changeList = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        querrySmartTags() {
            let { sdkOptions } = this.props;
            let smartTagsUrl = Udesk.business.apiPath.concatApiPath(
                `callTags/${this.privates.activeCallId}/tagsOfCall`,
                sdkOptions
            );
            Udesk.ajax.get(smartTagsUrl).then(
                (resp) => {
                    this.privates.smartTags = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        changeCallDetail(activeCallId) {
            this.privates.activeCallId = `${activeCallId}`;
            let activeCallDetail = this.privates.callDetailsList.find(
                (d) => d.callId === parseInt(activeCallId, 10)
            );
            if (!activeCallDetail) return;
            this.privates.detail = { ...this.privates.detail, ...activeCallDetail };
            this.privates.task = this.privates.detail;
            this.privates.dialogRemarkList = activeCallDetail.remarkList || [];
            this.actions.update();
            this.actions.querrySmartTags();
        },
        requestRelatedRecords() {
            this.privates.relatedRecordsLoading = true;
            this.actions.update();
            let { relatedDatasPaging } = this.privates.storages;
            let { sdkOptions } = this.props;
            let relatedData = {
                id: this.privates.detail.callId,
                pageNum: relatedDatasPaging.pageNum,
                pageSize: relatedDatasPaging.pageSize,
            };
            let relatedDataUrl = Udesk.business.apiPath.concatApiPath(
                `data/related-data`,
                sdkOptions
            );
            this.privates.xhr = Udesk.ajax.post(relatedDataUrl, relatedData);
            this.privates.xhr.then(
                (resp) => {
                    this.privates.relatedDatas = resp.data;
                    this.privates.storages.relatedDatasPaging = resp.paging;
                    this.privates.relatedRecordsLoading = false;
                    this.privates.xhr = null;
                    this.actions.update();
                },
                (reason) => {
                    if (reason.statusText !== 'abort') {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                    this.privates.relatedRecordsLoading = false;
                    this.privates.xhr = null;
                    this.actions.update();
                }
            );
        },
        trainRecords(params) {
            const { sdkOptions } = this.props;
            const caseBaseUrl = Udesk.business.apiPath.concatApiPath(`trainRecords`, sdkOptions);
            return Udesk.ajax
                .post(caseBaseUrl, { ...params, callId: this.privates.activeCallId })
                .then(
                    (resp) => {
                        Udesk.ui.notify.success(
                            this.locales.business.notifyMessage.correctionSuccess
                        );
                        let newDialogSentenceList =
                            this.privates.detail.dialogRecords.dialogSentenceList.map(
                                (item, index) => {
                                    if (index === params.sentenceIndex) {
                                        return {
                                            ...item,
                                            text: params.newContent,
                                        };
                                    }
                                    return item;
                                }
                            );
                        this.privates.detail = {
                            ...this.privates.detail,
                            dialogRecords: {
                                dialogSentenceList: newDialogSentenceList,
                            },
                        };
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        },
        redoSmartTags() {
            postSmartTagsRedo({
                callIds: [this.privates.activeCallId],
                systemModule: getSystemModule(),
            }).then((resp) => {
                this.actions.onUpdate();
            });
        },
    };

    //#region 生命周期
    // parseProps({ props, prevProps }) {
    //     if (props.match.params.id !== prevProps.match.params.id) {
    //         this.actions.reloadAsyncModel({ id: props.match.params.id });
    //     }
    // }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.privates.storages.relatedDatasPaging = { ...RelatedDatasPaging };
            this.privates.relatedDatas = [];
            this.privates.wrapDom.current.scrollTop = 0;
            this.privates.xhr && this.privates.xhr.abort && this.privates.xhr.abort();
            this.privates.loadMoreXhr &&
                this.privates.loadMoreXhr.abort &&
                this.privates.loadMoreXhr.abort();
            this.actions.reloadAsyncModel({ id: this.props.match.params.id });
        }
    }

    init() {
        let { sdkOptions } = this.props;
        this.privates.isWechatQa = this.props.route.name === 'wechatQualityInspectionListDetail';
        this.privates.isTicket =
            sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.ticket.id;
        this.privates.isInformationCollect =
            sdkOptions.props.task.taskType === Udesk.enums.taskType.informationCollection.id;

        //初始化筛选条件
        let searchTemplate =
            this.props.location.state ||
            sdkOptions.props.defaultSearchTemplate.find(
                (template) => template.templateType === sdkOptions.props.task.inspectDataSource
            );

        this.privates.storages.conditionList = searchTemplate.conditionList;
        this.privates.storages.customJudgeLogic = searchTemplate.customJudgeLogic;
        this.privates.storages.judgeStrategy = searchTemplate.judgeStrategy;
        this.actions.update();
    }
    componentDidMount() {
        // if (this.props.location && this.props.location.search) {
        //     let testSetFlag = Object.assign({}, this.props.match.params, Object.fromEntries(new URLSearchParams(this.props.location.search))).testSetFlag;
        //     if (testSetFlag === 'true') {
        //         this.privates.testSetListFlag = testSetFlag;
        //         this.actions.testSetload();
        //     }
        // }

        let { props } = this;
        this.privates.storages.taskId = this.props.match.params.taskId;
        let {
            sdkOptions: {
                props: { task, callCustom, imCustom },
            },
        } = props;
        if (props.location.state && props.location.state.type) {
            this.privates.stateParamsType = props.location.state.type;
        }
        this.actions.update();
        let inspectSearchDefaultShowFields = null;

        if (
            task &&
            (task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id)
        ) {
            if (callCustom) {
                if (callCustom.inspectSearchDefaultShowFields) {
                    inspectSearchDefaultShowFields = callCustom.inspectSearchDefaultShowFields;
                }
            }
        } else if (
            task &&
            (task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
                task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
        ) {
            if (imCustom) {
                if (imCustom.inspectSearchDefaultShowFields) {
                    inspectSearchDefaultShowFields = imCustom.inspectSearchDefaultShowFields;
                }
            }
        }
        this.privates.inspectSearchDefaultShowFields = inspectSearchDefaultShowFields;
        getSearchTemplateNoComplianceByTaskId({
            segments: {
                taskId: this.props.match.params.taskId,
            },
        }).then((resp) => {
            this.privates.fastFilterConditions = (resp.data || []).map((i) => ({
                ...i,
                conditionList: i.conditionList.map((c) => ({
                    ...c,
                    field: {
                        ...c.field,
                        statusKey: c.field?.id,
                    },
                })),
            }));
            this.actions.update();
        });
    }
    componentWillUnmount() {
        let timerToken = this.privates.timerToken;
        if (timerToken != null) {
            clearInterval(timerToken);
        }
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'detailWithRelateData') {
            this.privates.pointChildrenComputer = [];
            this.privates.pointChildrenPerson = [];
            this.privates.relatedLoading = false;

            if (asyncResult && asyncResult.detail && asyncResult.detail.data) {
                //人工评价
                if (
                    asyncResult.detail.data.evaluateDto &&
                    asyncResult.detail.data.evaluateDto.handValue
                ) {
                    this.privates.evaluateFlag = true;
                }
                //inspectionData接口返回的所有数据
                // this.privates.detail = asyncResult.detail.data;
                const {
                    relatedInspectionDataExtension = {},
                    // related,
                    ...detail
                } = asyncResult.detail.data;
                const { related } = detail;
                this.privates.related = Boolean(related);
                const callDetailsList = Object.values(relatedInspectionDataExtension);
                if (related) {
                    this.privates.callDetailsList = callDetailsList;
                } else {
                    this.privates.callDetailsList = [asyncResult.detail.data];
                }
                if (related && detail.relatedIndexPointTag) {
                    const relatedIndexPointTag = Array.isArray(detail.relatedIndexPointTag)
                        ? detail.relatedIndexPointTag
                        : [detail.relatedIndexPointTag];
                    const relatedIndexPointTagMap = relatedIndexPointTag.reduce((prev, cur) => {
                        prev[cur.callId] = cur.indexPointTag;
                        return prev;
                    }, {});
                    this.privates.relatedIndexPointTagMap = relatedIndexPointTagMap;
                }
                let firstCallDetail = this.privates.callDetailsList[0] || {};
                this.privates.activeCallId = `${firstCallDetail.callId}`;
                //单句备注
                this.privates.dialogRemarkList = firstCallDetail.remarkList || [];
                this.privates.detail = { ...detail, ...firstCallDetail };
                if (this.privates.isInformationCollect) {
                    this.privates.detail.pointCategoryList = [
                        {
                            children: [
                                {
                                    pointDataList: this.privates.detail.ruleList.map((item) => {
                                        item.parentId = item.categoryId;
                                        item.pointId = item.id;
                                        return item;
                                    }),
                                },
                            ],
                        },
                    ];
                }
                this.privates.task = this.privates.detail;
                //质检点（一级分类）列表
                this.privates.pointCategoryList = this.privates.detail.pointCategoryList;
                if (this.privates.pointCategoryList && this.privates.pointCategoryList.length > 0) {
                    this.privates.pointCategoryList.forEach((item) => {
                        //一级分类下有二级分类
                        if (item.children) {
                            item.children.forEach((items) => {
                                //二级分类下有质检点
                                if (items.pointDataList) {
                                    items.pointDataList.forEach((itemss) => {
                                        //质检点有人工标记的高亮（？不确定）
                                        if (itemss.handMarkHighlightSeqMap) {
                                            if (itemss.handMarkHighlightSeqMap[1]) {
                                                itemss.handMarkHighlightSeqMap[1].forEach(
                                                    (itemsss) => {
                                                        if (
                                                            this.privates.pointChildrenPerson[
                                                                itemsss
                                                            ]
                                                        ) {
                                                            this.privates.pointChildrenPerson[
                                                                itemsss
                                                            ].push({
                                                                id: itemss.pointId,
                                                                name: itemss.name,
                                                            });
                                                        } else {
                                                            this.privates.pointChildrenPerson = {
                                                                ...this.privates
                                                                    .pointChildrenPerson,
                                                                [itemsss]: [
                                                                    {
                                                                        id: itemss.pointId,
                                                                        name: itemss.name,
                                                                    },
                                                                ],
                                                            };
                                                        }
                                                    }
                                                );
                                            }
                                            if (itemss.handMarkHighlightSeqMap[2]) {
                                                itemss.handMarkHighlightSeqMap[2].forEach(
                                                    (itemsss) => {
                                                        if (
                                                            this.privates.pointChildrenComputer[
                                                                itemsss
                                                            ]
                                                        ) {
                                                            this.privates.pointChildrenComputer[
                                                                itemsss
                                                            ].push({
                                                                id: itemss.pointId,
                                                                name: itemss.name,
                                                            });
                                                        } else {
                                                            this.privates.pointChildrenComputer = {
                                                                ...this.privates
                                                                    .pointChildrenComputer,
                                                                [itemsss]: [
                                                                    {
                                                                        id: itemss.pointId,
                                                                        name: itemss.name,
                                                                    },
                                                                ],
                                                            };
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                this.actions.update();
            }
            // if (asyncResult && asyncResult.relateData && asyncResult.relateData.data) {
            //     this.privates.relatedDatas = asyncResult.relateData.data;
            //     this.privates.storages.relatedDatasPaging = asyncResult.relateData.paging;
            //     this.actions.update();
            // }
        } else if (asyncKey === 'changeList') {
            if (asyncResult && asyncResult.data) {
                this.privates.changeList = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'caseBase') {
            if (asyncResult && asyncResult.data) {
                this.privates.caseBase = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'testSetList') {
            if (asyncResult && asyncResult.data) {
                this.privates.testSetList = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'checkPoints') {
            console.log('asyncResult', asyncResult);
            if (asyncResult) {
                let reInspectionPoints = asyncResult;
                reInspectionPoints = reInspectionPoints.filter(
                    (current) => current.children && current.children.length > 0
                );
                for (let i = 0; i < reInspectionPoints.length; i++) {
                    let firstCategory = reInspectionPoints[i];
                    if (firstCategory.children && firstCategory.children.length > 0) {
                        for (let j = 0; j < firstCategory.children.length; j++) {
                            let secondCategory = firstCategory.children[j];
                            secondCategory.points = secondCategory.points.filter(
                                (item) =>
                                    item.type !== Udesk.enums.pointTypes.manual.id ||
                                    (item.type === Udesk.enums.pointTypes.manual.id &&
                                        item.intelligentSecondType)
                            );
                            for (let index = 0; index < secondCategory.points.length; index++) {
                                secondCategory.points[index]._checked = true;
                            }
                        }
                    }
                }
                this.privates.reInspectionPoints = reInspectionPoints;
                this.actions.update();
            }
        }
        if (asyncModel.sysConfig) {
            this.privates.enabledEvaluate = asyncModel.sysConfig.enabledEvaluate;
            this.actions.update();
        }
        this.privates.renderLoading = false;
        this.actions.update();
    }
    //#endregion
}

function getChangedPointDataList(pointCategoryList) {
    let pointDataList = [];
    pointCategoryList.forEach((firstCategory) => {
        let secondCategorys = firstCategory.children || [];
        secondCategorys.forEach((secondCategory) => {
            if (secondCategory.pointDataList) {
                pointDataList = pointDataList.concat(secondCategory.pointDataList);
            }
        });
    });
    if (pointDataList && pointDataList.length > 0) {
        pointDataList = pointDataList.filter((point) => {
            if (point.gradeChooseOption === '') point.gradeChooseOption = null;
            // return point.isChanged;
            return true;
        });
    }
    return pointDataList;
}
function scrollToPoint(pointId) {
    // quality-check-talk-point-5656
    const containers = document.getElementsByClassName('quality-check-score-talk-body-content');
    if (containers && containers[0]) {
        // const container = containers[0];

        const hitPoint = document.getElementById(`quality-check-talk-point-${pointId}`);
        hitPoint?.scrollIntoViewIfNeeded?.();
        hitPoint.classList.add('highlight');
        setTimeout(() => {
            hitPoint.classList.remove('highlight');
        }, 2000);
    }
}
function validateItemScores(items) {
    let validateResult = true;
    if (items) {
        if (items.length === 0) {
            validateResult = true;
        }
        if (items.length > 0) {
            items.forEach((item) => {
                if (item.naChecked !== NACHECKED) {
                    if (item.type !== Udesk.enums.pointTypes.machineLearning.id) {
                        if (
                            item.gradeType === Udesk.enums.pointGradeTypes.radio.id ||
                            item.gradeType === Udesk.enums.pointGradeTypes.foctors.id
                        ) {
                            if (item.gradeChooseHit == null) {
                                validateResult = false;
                                scrollToPoint(item.pointId);
                            }
                        } else if (item.gradeType === Udesk.enums.pointGradeTypes.input.id) {
                            if (item.gradeInput == null || item.gradeInput === '') {
                                validateResult = false;
                                scrollToPoint(item.pointId);
                            }
                        } else if (item.gradeType === Udesk.enums.pointGradeTypes.select.id) {
                            if (item.pointHitValue == null || item.pointHitValue === '') {
                                validateResult = false;
                                scrollToPoint(item.pointId);
                            }
                        }
                    } else {
                        if (item.gradeChooseHit == null) {
                            validateResult = false;
                            scrollToPoint(item.pointId);
                        }
                    }
                }
            });
        }
        return validateResult;
    }
    return validateResult;
}

function getReCheckCondition(that) {
    let { reInspectionType, reInspectionPoints } = that.privates;
    if (reInspectionType === Udesk.enums.reInspectionTypes.recalcScore.id) {
        return { reCheckType: reInspectionType };
    } else {
        let inspectionPointCategories = [];
        let inspectionPoints = [];
        for (let i = 0; i < reInspectionPoints.length; i++) {
            let firstCategory = reInspectionPoints[i];
            for (let j = 0; j < firstCategory.children.length; j++) {
                let secondCategory = firstCategory.children[j];
                for (let index = 0; index < secondCategory.points.length; index++) {
                    let point = secondCategory.points[index];
                    if (point._checked) {
                        inspectionPoints.push(point.id);
                        if (!inspectionPointCategories.includes(firstCategory.id))
                            inspectionPointCategories.push(firstCategory.id);
                        if (!inspectionPointCategories.includes(secondCategory.id))
                            inspectionPointCategories.push(secondCategory.id);
                    }
                }
            }
        }
        return {
            reCheckType: reInspectionType,
            inspectionPointCategories,
            inspectionPoints,
        };
    }
}

// function saveRemark(task, that) {
//     if (!task.remarkChanged) {
//         return Promise.resolve();
//     }
//     let { sdkOptions } = that.props;
//     let url = Udesk.business.apiPath.concatApiPath(`inspectionData/${task.id}/gradeRemark`, sdkOptions);
//     let params = { remark: task.remark, richRemark: task.richRemark };
//     return new Promise((resolve, reject) => {
//         Udesk.ajax.put(url, params).then(
//             (resp) => {
//                 resolve(resp);
//             },
//             (reason) => {
//                 reject(reason);
//             }
//         );
//     });
// }

export default QualityInspectionListDetailComponent;
