import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import { getCurrentRouteName } from 'Udesk/system/subApp';

class trainingCenter extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { route, location, sdkOptions } = this.props;

        let { locales } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        //&& Udesk.data.init.user.hasFeature("smart:tag:root")

        // if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('training:fast:root')) {
        // }
        navLists.push({
            text: locales.components.pages.clientCenter.clientManage.title,
            pathName: getCurrentRouteName('clientManage'),
            isActive: (match, location) => {
                return location.pathname.includes('/manage');
            }
        });
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("smart:personal:root")) {
            navLists.push({
                text: locales.components.pages.customerPortrait.title,
                pathName: getCurrentRouteName("customerPortraitIndex"),
                isActive: (match, location) => {
                    return location.pathname.includes("/customer-portrait");
                }
            });
        }

        if (sdkOptions.props.landingPath != null && this.isRedirect(route.path, location.pathname)) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = null;
            if (navLists[0].pathName) {
                toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                    routeName: navLists[0].pathName
                });
            } else {
                toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                    routeName: navLists[0].children[0].pathName
                });
            }

            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className='semantic-intelligence'>
                    <LayoutMenu {...this.props} navLists={navLists} menuTitle={locales.menu.tasks.clientCenter.text} />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(trainingCenter);
