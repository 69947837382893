import widgets, { initWidgets, destroy as destroyWidgets } from './widgets';
import notify from './notify';

export default {
    notify,
    widgets,
};

export function init() {
    this.notify.init();
    initWidgets.call(this.widgets);
}

export function destroy() {
    this.notify.destroy();
    destroyWidgets.call(this.widgets);
}