// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetCompanyGeneralSettingssByIdParam,
    BaseResponseCompanyGeneralSettingsFoundResponse,
    PutCompanyGeneralSettingssByIdParam,
    PutCompanyGeneralSettingssByIdBody,
    BaseResponseVoid,
    DeleteCompanyGeneralSettingssByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags company-general-settings-controller
 * @link [GET] /companyGeneralSettingss/{id}
 * @param id
 */
export function getCompanyGeneralSettingssById(
    options: ApiOptions<GetCompanyGeneralSettingssByIdParam, never> & {
        segments: GetCompanyGeneralSettingssByIdParam;
    }
): Promise<BaseResponseCompanyGeneralSettingsFoundResponse> {
    return request<never, GetCompanyGeneralSettingssByIdParam, never>(
        '/companyGeneralSettingss/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getCompanyGeneralSettingssById' }
    );
}

/**
 * update
 *
 * @export
 * @tags company-general-settings-controller
 * @link [PUT] /companyGeneralSettingss/{id}
 * @param id
 */
export function putCompanyGeneralSettingssById(
    data: PutCompanyGeneralSettingssByIdBody,
    options: ApiOptions<PutCompanyGeneralSettingssByIdParam, never> & {
        segments: PutCompanyGeneralSettingssByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutCompanyGeneralSettingssByIdBody, PutCompanyGeneralSettingssByIdParam, never>(
        '/companyGeneralSettingss/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putCompanyGeneralSettingssById' }
    );
}

/**
 * deleteById
 *
 * @export
 * @tags company-general-settings-controller
 * @link [DELETE] /companyGeneralSettingss/{id}
 * @param id
 */
export function deleteCompanyGeneralSettingssById(
    options: ApiOptions<DeleteCompanyGeneralSettingssByIdParam, never> & {
        segments: DeleteCompanyGeneralSettingssByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteCompanyGeneralSettingssByIdParam, never>(
        '/companyGeneralSettingss/{id}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteCompanyGeneralSettingssById' }
    );
}

export const meta = [
    {
        tags: ['company-general-settings-controller'],
        path: '/companyGeneralSettingss/{id}',
        method: 'get',
    },
    {
        tags: ['company-general-settings-controller'],
        path: '/companyGeneralSettingss/{id}',
        method: 'put',
    },
    {
        tags: ['company-general-settings-controller'],
        path: '/companyGeneralSettingss/{id}',
        method: 'delete',
    },
];
