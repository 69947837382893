import React from 'react';
import Udesk from 'Udesk';
import { getCurrentRouteName } from 'Udesk/system/subApp';

class uploadDownComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        detail: {}
    };
    //#endregion

    state = {};
    privates = {
        data: {},
        modelFlag: true
    };
    static computes = {};
    actions = {
        resultStatus() {
            let url = `/backend/impExp/recent`;

            new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    resp => {
                        this.privates.data = resp.data;
                        this.actions.update();
                    },
                    reason => {}
                );
            });
        },
        clearImpExp(e) {
            e.preventDefault();
            let url = `/backend/impExp/clear`;
            new Promise((resolve, reject) => {
                Udesk.ajax.put(url).then(
                    resp => {
                        this.actions.resultStatus();
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                        this.actions.update();
                    }
                );
            });
        },
        updoadDownload(type, topId) {
            // 跳转时需要删除下面三项，否则有页面污染
            this.props.sdkOptions.props.biTaskId = null;
            this.props.sdkOptions.props.task = null;
            this.props.sdkOptions.props.basicInfoFields = null;

            const routeOptions = {
                history: this.props.history
            };
            if (topId != null) {
                routeOptions.queryParams = { topId };
            }
            if (type === 1) {
                routeOptions.routeName = getCurrentRouteName('taskCenterDownloadList');
            } else {
                routeOptions.routeName = getCurrentRouteName('taskCenterUploadList');
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {}
    componentDidMount() {
        if (this.privates.timer) {
            this.actions.resultStatus();
        } else {
            this.actions.resultStatus();
            this.privates.timer = setInterval(() => {
                this.actions.resultStatus();
            }, 3000);
        }
    }
    componentDidUpdate(prevProps, preState) {
        if (this.props.show !== prevProps.show) {
            if (this.props.show) {
                if (this.privates.timer) {
                    this.actions.resultStatus();
                } else {
                    this.actions.resultStatus();
                    this.privates.timer = setInterval(() => {
                        this.actions.resultStatus();
                    }, 3000);
                }
            } else {
                clearInterval(this.privates.timer);
                this.privates.timer = null;
            }
        }
    }
    componentWillUnmount() {
        clearInterval(this.privates.timer);
        this.privates.timer = null;
    }
    //#endregion
}

export default uploadDownComponent;
