export default {
  lang: {
    num: 'Pääluokka',
    total: 'Artikla/Yhteensä',
    strip: 'Artikla',
    more: 'Lisää.',
    chosen: 'Valittu',
    cancel: 'Poista valinta',
    bulkOperations: 'Erän toiminta',
    import: 'Tuo',
  },
};
