import React, {useCallback, useMemo, useState} from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import PageLeftTable from './components/pageLeftTable';
import PageRightTable from './components/pageRightTable';
import Locales from 'UdeskLocales';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { createComponent } from 'src/util/component';
import { isDefined, isNotEmpty } from 'src/util/core';
import './index.scss'; 

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const { history } = props;
    const request = useRequest();
    const {
        leftCurrent, leftData, leftLoading, updateLeftData,
        selectedRowKey, setSelectedRowKey,
        leftPageKeyword, setLeftPageKeyword,
        leftPagePageNum, setLeftPagePageNum,
    } = useLeftData(props);
    const {
        rightPagePagination, setRightPagePagination,
        rightFilterData, setRightData
    } = useRightData(props);

    return (
        <Page
            pageClassName='task-center-audit-index'
            pageBodyClassName='task-center-audit-body'
            title={/* 人工审核 */Locales['current'].pages.coach.taskCenter.audit.index.manualReview}  
        >
            <div>
                <PageLeftTable {
                    ...{
                        locales,
                        data: leftData,
                        loading: leftLoading,
                        selectedRowKey, setSelectedRowKey, 
                        leftPageKeyword, setLeftPageKeyword,
                        leftPagePageNum, setLeftPagePageNum,
                    }
                }/>
                <PageRightTable {
                    ...{
                        request,
                        locales,
                        history,
                        selectedRowKey, leftCurrent, updateLeftData,
                        rightFilterData, setRightData,
                        rightPagePagination, setRightPagePagination,
                    }
                }/>
            </div>
        </Page>        
    );
});

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};

export const useLeftData = (props: any) => {
    const {
        storages: { selectedRowKey, leftPageKeyword, leftPagePageNum },
        saveStorages
    } = props;
    const request = useRequest();
    const [leftLoading, setLeftLoading] = useState(false);
    const [leftData, setLeftData] = useState<any[]>([]);

    const leftCurrent = useMemo(() => {
        if (isDefined(selectedRowKey) && isNotEmpty(leftData)) {
            return leftData.find(item => item.id === selectedRowKey);
        }
        return null;
    }, [leftData, selectedRowKey]);

    const updateLeftData = useCallback((callback) => {
        setLeftLoading(true);
        request(`/intelligentPartner/review/task/findAll`).then(
            resp => {
                const handle = () => {
                    setLeftData(resp.data.map(item => ({
                        ...item,
                        key: item.id
                    })));
                };
                if (callback) {
                    callback(handle);
                } else {
                    handle();
                }
            }
        ).finally(() => {
            setLeftLoading(false);
        });
    }, [request]);

    useLiveEffect(updateLeftData, [
        updateLeftData
    ]);

    const setSelectedRowKey = useCallback((selectedRowKey) => {
        saveStorages((storages) => {
            return {
                ...storages, selectedRowKey,
            };
        });
    }, [
        saveStorages,
    ]);

    const setLeftPageKeyword = useCallback((leftPageKeyword) => {
        saveStorages((storages) => {
            return {
                ...storages, leftPageKeyword,
            };
        });
    }, [
        saveStorages,
    ]);

    const setLeftPagePageNum = useCallback((leftPagePageNum) => {
        saveStorages((storages) => {
            return {
                ...storages, leftPagePageNum,
            };
        });
    }, [
        saveStorages,
    ]);

    return {
        leftCurrent, leftData, leftLoading, updateLeftData,
        selectedRowKey, setSelectedRowKey,
        leftPageKeyword, setLeftPageKeyword,
        leftPagePageNum, setLeftPagePageNum,
    };
};

export const useRightData = (props: any) => {
    const {
        storages: { rightPagePagination, rightFilterData },
        updateStorages
    } = props;

    const setRightPagePagination = useCallback((rightPagePagination) => {
        updateStorages({
            rightPagePagination, 
        });
    }, [
        updateStorages,
    ]);

    const setRightData = useCallback((rightFilterData, rightPagePagination) => {
        updateStorages(false, {
            rightFilterData, 
            rightPagePagination, 
        });
    }, [
        updateStorages,
    ]);

    return {
        rightPagePagination, setRightPagePagination,
        rightFilterData, setRightData
    };
};


export default createComponent(
    Template,
    {
        selectedRowKey: null as number | null,
        leftPageKeyword: '',
        leftPagePageNum: Udesk.config.paging.defaultPageNumber,

        rightPagePagination: defaultPagination,
        rightFilterData: undefined,
    },
    [
        /^\/coach\/task-center\/audit\/*/i,
        /^\/coach\/task-center\/audit-detail\/*/i,
    ]
);