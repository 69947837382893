import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    //删除并记录 删除元素
    const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, removed);
    return result;
};
// 插入排序
const insert = (list, insertObj, endIndex) => {
    const result = Array.from(list);
    //删除并记录 删除元素
    // const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, insertObj);
    return result;
};

export default class TasksCheckPointDetailComponent extends React.Component {

    privates = {
        processStatus: 2,
        vesionFlag: true,//判断老任务的质检点type显示,true为新，false为旧
        type: 4,
        gradeType: 1,
        name: '',
        remark: '',
        // ruleType: 1,//1话术2交互3特征4信息
        operatorType: 1,
        keyWordsList: [],
        testingMode: 1,//1检测全部关键词，2检测任意关键词
        rolesMenu: [],
        operatorMenu: [],
        matchTimeMenu: [],
        predeterminedScore: 1,
        ruleTypeList: [
            { key: 1, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.words },
            { key: 2, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.interaction },
            { key: 3, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.feature },
            { key: 4, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.information },
        ],
        ruleList: [{
            conditionType: 1,//1话术2交互3特征4信息
            taskConditionId: null,
            orderId: 1,
            operatorType: 11,
            idCode: 'R1',
            operatorList: [{
                applyConditionHitType: 1,
                applyOperatorScope: {
                    key: 'all',
                    id: 1
                },
                type: 11,
                operator: "all",
                applyRole: 'all',
            }],
            applyConditionId: null,
            applyConditionIdCode: null,
            operator: "all",
            applyRole: 'all',
            applyScope: null,
            similarityScore: null,
            differWordNumber: null,
            wordPerMinite: null,
            maxWordPerMinite: null,
            grabIntervalTime: null,
            grabMaxSpeakTime: null,
            intervalSecond: null,
            keyword: "",
            semanticSentence: null,
            applyConditionScope: null,
            applyConditionHitType: 1,
            // applyOperatorScope: {
            //     key: 'all',
            //     id: 1
            // },
            param: {},
        },],
        scoreType: 1,
        naChecked: 0,
        naPreviousFormula: '',
        naScoreType: 1,
        heightLightList: [],
        tarskDel: false,
        conditionListFilter: [],
        logicList: [
            {
                id: "logicList-1",
                content: UdeskLocales.current.components.common.customFilter.and,
                value: 'and',
                flag: true,
            },
            {
                id: "logicList-2",
                content: UdeskLocales.current.components.common.customFilter.or,
                value: 'or',
                flag: true,
            },
            {
                id: "logicList-3",
                content: UdeskLocales.current.components.common.customFilter.not,
                value: 'not',
                flag: true,
            },
            {
                id: "logicList-4",
                content: "(",
                value: '(',
                flag: true,
            },
            {
                id: "logicList-5",
                content: ")",
                value: ')',
                flag: true,
            },
            {
                id: "logicList-6",
                content: UdeskLocales.current.components.common.customFilter.and,
                value: '&&',
                flag: false,
            },
            {
                id: "logicList-7",
                content: UdeskLocales.current.components.common.customFilter.or,
                value: '||',
                flag: false,
            },
            {
                id: "logicList-8",
                content: UdeskLocales.current.components.common.customFilter.not,
                value: '!',
                flag: false,
            },
        ],
        items: [],
        itemsCount: 0,
        operatorLogic: '',
        operatorMultipleLogicList: [
            {
                formula: '',
                // score: '',
                // evaluation: ''
            }
        ],
        operatorMultipleLogicitems: [[]],
        operatorMultipleLogicitemsCount: [0],
        operatorMultipleLogic: ['']
    };
    state = {
        operatorTypesOfWords: [],
        operatorTypesOfInteraction: [],
        operatorTypesOfFeature: [],
        operatorTypesOfInformation: [],
        //以下为旧页面
        conditionLogic: '',
        relateConditionList: [],
        selectedCategory: {},
        interactiveRelateConditionList: [],
        scoreType: [],
        foctorsData: [],
        foctorsValue: '',
        foctorsHit: 0,
        foctorsFlag: false,
        foctorsNoneValue: {}

    };
    //#region Computed properties
    static computes = {
        isEditing: [
            'privates.model.inspectionPoint',
            function ({ props, state, privates, locales }) {
                return (
                    privates.model && privates.model.inspectionPoint.id > 0
                );
            }
        ],
        gradeTypes: [
            'privates.type',
            function ({ props, state, privates, locales }) {
                if (!privates.model) return [];

                let type = privates.type;
                let gradeTypes = Udesk.enums.gradeTypes.filter(item => item !== null);

                if (type === Udesk.enums.scoreType.aiScore.id) {
                    gradeTypes = gradeTypes.filter(item => {
                        return (
                            item.id !== Udesk.enums.gradeTypes.input.id &&
                            item.id !== Udesk.enums.gradeTypes.multipleFactors.id
                        );
                    });
                } else if (type === Udesk.enums.scoreType.interactiveDetection.id) {
                    gradeTypes = gradeTypes.filter(item => {
                        return (
                            item.id === Udesk.enums.gradeTypes.yesOrNo.id ||
                            item.id === Udesk.enums.gradeTypes.multipleFactors.id
                        );
                    });
                } else if (type === Udesk.enums.scoreType.manualScore.id) {
                    gradeTypes = gradeTypes.filter(item => {
                        return item.id !== Udesk.enums.gradeTypes.multipleFactors.id;
                    });
                }

                return gradeTypes;
            }
        ]
    };
    //#endregion    

    actions = {
        getConditionList(gearOptionList) {
            this.privates.ruleList = gearOptionList;
            this.actions.getOperatorList(this.privates.ruleList);
        },
        transitionToCheckPointIndex() {
            let taskId = this.privates.model.taskId;
            if (this.privates.processStatus === -1) {
                let routeOptions = {
                    history: this.props.history,
                    routeName: 'tasksCheckPointIndex',
                    pathParams: {
                        taskId: taskId
                    }
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            } else {
                let routeOptions = {
                    history: this.props.history,
                    routeName: 'tasksCheckPointCreateEntry',
                    pathParams: {
                        taskId: taskId
                    }
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        handleScoreChange(value) {
            this.privates.scoreType = value;
            this.actions.update();
        },
        handleScoreInput(value) {
            this.privates.predeterminedScore = value;
            this.actions.update();
        },
        onNAChange(e) {
            if (e.target.checked) {
                this.privates.naChecked = 1;
            } else {
                this.privates.naChecked = 0;
            }
            this.actions.update();
        },
        naPreviousFormulaChange(e) {
            this.privates.naPreviousFormula = e.target.value;
            this.actions.update();
        },
        customJudgeLogicChange(e) {
            let reg = /[\u4e00-\u9fa5]/g;
            this.privates.operatorLogic = e.target.value.replace(reg, '');
            this.privates.items = [];
            this.actions.update();
        },
        operatorMultipleLogicChange(index, e) {
            let reg = /[\u4e00-\u9fa5]/g;
            this.privates.operatorMultipleLogicList[index].formula = e.target.value.replace(reg, '');
            this.privates.operatorMultipleLogicitems[index] = [];
            this.actions.update();
        },
        operatorMultipleFractionChange(index, value) {
            this.privates.operatorMultipleLogicList[index].score = value;
            this.actions.update();
        },
        operatorMultipleAssessChange(index, e) {
            this.privates.operatorMultipleLogicList[index].evaluation = e.target.value;
            this.actions.update();
        },
        operatorMultipleDelete(index) {
            this.privates.operatorMultipleLogicList.splice(index, 1);
            this.privates.operatorMultipleLogicitems.splice(index, 1);
            this.privates.operatorMultipleLogicitemsCount.splice(index, 1);
            this.actions.update();
        },
        operatorMultipleAdd() {
            this.privates.operatorMultipleLogicList.push(
                {
                    formula: '',
                    score: '',
                    evaluation: ''
                }
            );
            this.privates.operatorMultipleLogicitems.push([]);
            this.privates.operatorMultipleLogicitemsCount.push(0);
            this.actions.update();
        },
        heightLightChange(checkedValues) {
            this.privates.heightLightList = checkedValues;
            this.actions.update();
        },
        onNotCatchPreRuleChange(e) {
            this.privates.naScoreType = e.target.value;
            this.actions.update();
        },
        commit() {
            // let task = this.props.form.getFieldsValue();
            this.privates.ruleList.forEach(item => {
                item.codeId = item.idCode;
            });
            //flag：要素问答，一问一答，信息实体param、多重逻辑评分评价的校验标志位
            let flag = true;
            this.privates.operatorMultipleLogicList.forEach(item => {
                if (this.privates.gradeType > 3 && (!item.evaluation || item.score == null)) {
                    flag = false;
                    Udesk.ui.notify.error(UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.multipleEmpty);
                }
                item.judgeStrategy = this.privates.gradeType > 3 ? 3 : this.privates.gradeType;
                if (typeof item.score === 'number' && item.score >= 0) {
                    item.score = item.score.toString();
                }
                if (this.privates.gradeType === 3) {
                    item.formula = this.privates.operatorLogic;
                }
            });
            //若多重逻辑评分评价条件填写不全，则不向下执行
            if (!flag) return;
            //生成高亮的relateConditionList字段
            let relateConditionList = this.privates.ruleList.map(rule => {
                return {
                    idCode: rule.idCode,
                    highlight: 0
                };
            });
            this.privates.heightLightList.forEach(value => {
                relateConditionList.forEach(item => {
                    if (value === item.idCode) {
                        item.highlight = 1;
                    }
                });
            });
            let ruleListCopy = JSON.parse(JSON.stringify(this.privates.ruleList));
            ruleListCopy.forEach(item => {
                if (item.operatorType === Udesk.enums.operatorTypes.element.id) {
                    if (!item.operatorList[0].param.mulElementId || !(item.operatorList[0].param.hit === 0 || item.operatorList[0].param.hit === 1)) {
                        flag = false;
                    }
                }
                if (item.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id) {
                    if (!item.operatorList[0].param.interactiveRule || !item.operatorList[0].param.interactiveCategoryId || !item.operatorList[0].param.interactiveCategoryName) {
                        flag = false;
                    }
                }
                if (item.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id) {
                    if (!item.operatorList[0].param.operator || !(item.operatorList[0].param.expectedValueObject || item.operatorList[0].param.expectedValue) || !item.operatorList[0].param.entity) {
                        flag = false;
                    }
                }
                item.type = item.operatorType;
                item.operatorLogic = '1';
                // if(item.operatorList[0].param && Object.keys(item.operatorList[0].param).length == 0){
                //     item.operatorList[0].param = null;
                // }
            });
            if (!flag) {
                Udesk.ui.notify.error(UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.operatorEmpty);
                return;
            }
            if (this.privates.naChecked && !this.privates.naPreviousFormula) {
                Udesk.ui.notify.error(UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.preRuleEmpty);
                return;
            }
            ruleListCopy.taskId = this.privates.model.taskId;

            let params = {
                taskId: this.privates.model.taskId,
                name: this.privates.name,
                gradeType: this.privates.gradeType > 3 ? 3 : 1,
                remark: this.privates.remark,
                type: this.privates.type,
                categoryId: this.privates.model.categoryId,
                predeterminedScore: this.privates.predeterminedScore,
                scoreType: this.privates.scoreType,
                naChecked: this.privates.naChecked,
                naPreviousFormula: this.privates.naPreviousFormula,
                naScoreType: this.privates.naScoreType,
                ruleList: this.privates.operatorMultipleLogicList,
                relateConditionList: relateConditionList,
                // conditionList: {
                //     operatorList: this.privates.ruleList,
                //     // applyConditionId: preOperatorId
                // }
                conditionList: ruleListCopy,
            };
            if (this.privates.computes.isEditing) {
                this.actions.editPoint(params);
            } else {
                this.actions.creatPoint(params);
            }

        },
        onFormValuesChange(changedValues, allValues) {
            this.privates.remark = allValues.desc;
            this.privates.name = allValues.tagName;
            this.privates.type = allValues.type;
            if (this.privates.type === 2) {
                this.privates.model.inspectionPoint.type = allValues.type;
                this.privates.model.inspectionPoint.gradeType = Udesk.enums.gradeTypes.yesOrNo.id;
            }
            if (this.privates.type === 1 || this.privates.type === 3) {
                this.privates.model.inspectionPoint.type = this.privates.type;
                this.privates.model.inspectionPoint.gradeType = Udesk.enums.gradeTypes.yesOrNo.id;
            }
            if (this.privates.type === 4) {
                if (!this.privates.heightLightList) {
                    this.privates.heightLightList = [];
                }
            }
            this.actions.update();
        },
        onGradeTypeChange(e) {
            this.privates.gradeType = e.target.value;
            this.actions.resetConditions();
            this.privates.ruleList && this.actions.getOperatorList(this.privates.ruleList);
            this.actions.update();
        },
        onDragStart(option) {
            if (option.draggableId.indexOf('drag') !== -1) {
                this.privates.tarskDel = true;
                this.actions.update();
            }
        },
        onDragEnd(result) {
            let { itemsCount } = this.privates;
            if (!result.destination) {
                if (result.draggableId.indexOf('drag') !== -1) {
                    let list = this.privates.items.filter(current => current.id !== result.draggableId);
                    this.privates.items = list;
                    let str = '';
                    this.privates.items.forEach(item => {
                        item && (str += item.value);
                    });
                    this.privates.operatorLogic = str;
                    this.privates.tarskDel = false;
                    this.actions.update();
                }
                return;
            }
            if (result.draggableId.indexOf('drag') !== -1) {
                const items = reorder(
                    this.privates.items,
                    result.source.index,
                    result.destination.index
                );
                let str = '';
                items.forEach(item => {
                    item && (str += item.value);
                });
                this.privates.operatorLogic = str;
                this.privates.items = items;
                this.privates.tarskDel = false;
                this.actions.update();
            } else {
                if (result.draggableId.indexOf('-') !== 1) {
                    let drag = result.draggableId.split('-')[0];
                    let obj = this.privates[drag].find(current => current.id === result.draggableId);
                    if (obj) {
                        let data = {
                            id: `drag-${itemsCount + 1}`,
                            content: obj.content,
                            value: obj.value,
                            prevId: result.draggableId,
                            prevClass: drag
                        };
                        const items = insert(
                            this.privates.items,
                            data,
                            result.destination.index
                        );
                        let str = '';
                        items.forEach(item => {
                            item && (str += item.value);
                        });
                        this.privates.operatorLogic = str;
                        this.privates.itemsCount = itemsCount + 1;
                        this.privates.items = items;
                        this.actions.update();
                    }
                }
            }
        },
        onDragEndMultiple(result) {
            let { operatorMultipleLogicitemsCount } = this.privates;
            let itemIndex = result.destination && parseInt(result.destination.droppableId.substr(13), 10);
            let itemIndexDelete = result.source && parseInt(result.source.droppableId.substr(13), 10);
            if (!result.destination) {
                if (result.draggableId.indexOf('drag') !== -1) {
                    let list = this.privates.operatorMultipleLogicitems[itemIndexDelete].filter(current => current && current.id !== result.draggableId);
                    this.privates.operatorMultipleLogicitems[itemIndexDelete] = list;
                    let str = '';
                    this.privates.operatorMultipleLogicitems[itemIndexDelete].forEach(item => {
                        item && (str += item.value);
                    });
                    this.privates.operatorMultipleLogicList[itemIndexDelete].formula = str;
                    this.privates.tarskDel = false;
                    this.actions.update();
                }
                return;
            }
            if (result.draggableId.indexOf('drag') !== -1) {
                const items = reorder(
                    this.privates.operatorMultipleLogicitems[itemIndex],
                    result.source.index,
                    result.destination.index
                );
                let str = '';
                items.forEach(item => {
                    item && (str += item.value);
                });
                this.privates.operatorMultipleLogicList[itemIndex].formula = str;
                this.privates.operatorMultipleLogicitems[itemIndex] = items;
                this.privates.tarskDel = false;
                this.actions.update();
            } else {
                if (result.draggableId.indexOf('-') !== 1) {

                    let drag = result.draggableId.split('-')[0];
                    let obj = this.privates[drag].find(current => current.id === result.draggableId);
                    if (obj) {
                        let data = {
                            id: `drag-${itemIndex}-${operatorMultipleLogicitemsCount[itemIndex] + 1}`,
                            content: obj.content,
                            value: obj.value,
                            prevId: result.draggableId,
                            prevClass: drag
                        };
                        const items = insert(
                            this.privates.operatorMultipleLogicitems[itemIndex],
                            data,
                            result.destination.index
                        );
                        let str = '';
                        items.forEach(item => {
                            item && (str += item.value);
                        });
                        this.privates.operatorMultipleLogicList[itemIndex].formula = str;
                        this.privates.operatorMultipleLogicitemsCount[itemIndex] = operatorMultipleLogicitemsCount[itemIndex] + 1;
                        this.privates.operatorMultipleLogicitems[itemIndex] = items;
                        this.actions.update();
                    }
                }
            }
        },
        getOperatorList(operatorList) {
            this.privates.operatorList = operatorList;
            this.privates.conditionListFilter = operatorList.map((item, index) => {
                return {
                    id: `conditionListFilter-${item.idCode}`,
                    content: item.idCode,
                    value: item.idCode
                };
            });
            let list = this.privates.items.filter(current => current.prevId !== `conditionListFilter-${operatorList.length}`);
            this.privates.items = list.map((item, index) => {
                return {
                    ...item,
                    id: `drag-${index}`
                };
            });
            this.actions.update();
        },
        resetConditions() {
            this.privates.operatorMultipleLogicList = [
                {
                    formula: '',
                    // score: '',
                    // evaluation: ''
                }
            ];
            this.privates.items = [];
            this.privates.itemsCount = 0;
            this.privates.operatorMultipleLogicitems = [[]];
            this.privates.operatorLogic = '';
            this.privates.operatorMultipleLogicitemsCount = [0];
            this.privates.operatorMultipleLogic = [''];
        },
        onStrategyTypeChange(e) {
            this.privates.gradeType = e.target.value;
            this.actions.update();
        },
        handleMachineLearningScoreChange(value) {
            this.privates.scoreType = value;
            if (this.privates.scoreType === Udesk.enums.calculateScoreTypes.add.id) {
                this.privates.gradeType = Udesk.enums.strategyTypes.hitAdd.id;
            } else {
                this.privates.gradeType = Udesk.enums.strategyTypes.hitSub.id;
            }
            this.actions.update();
        },
        machineLearningCommit() {
            let params = {
                taskId: this.privates.model.taskId,
                name: this.privates.name,
                gradeType: this.privates.gradeType,
                remark: this.privates.remark,
                type: this.privates.type,
                categoryId: this.privates.model.categoryId,
                predeterminedScore: this.privates.predeterminedScore,
                scoreType: this.privates.scoreType,
            };
            if (this.privates.computes.isEditing) {
                this.actions.editPoint(params);
            } else {
                this.actions.creatPoint(params);
            }
        },
        //#region 以下为旧页面
        onValueChanged(obj, key, e) {
            let value = e;
            if (key === 'highlight') {
                value = e.target.checked;
            } else if (e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.update();
        },

        save() {
            let inspectionPoint = this.privates.model.inspectionPoint;
            let taskId = this.privates.model.taskId;
            let categoryId = this.privates.model.categoryId;
            let params = {
                taskId: taskId,
                categoryId: categoryId
            };
            let inspectFlag = true;
            Object.assign(params, inspectionPoint);
            params.name = this.privates.name;
            params.remark = this.privates.remark;

            // 准备规则高亮数据
            let relateConditionList = [];
            if (inspectionPoint.type === Udesk.enums.scoreType.aiScore.id) {
                if (!checkHighlightArray(this)) {
                    return Udesk.ui.notify.error(
                        UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.checkHighlightArrayError
                    );
                }

                if (this.state.relateConditionList && this.state.relateConditionList.length > 0) {
                    this.state.relateConditionList.forEach(item => {
                        relateConditionList.push({
                            idCode: item.idCode,
                            highlight: item.highlight ? 1 : 0
                        });
                    });
                }
            } else if (inspectionPoint.type === Udesk.enums.scoreType.interactiveDetection.id) {
                let interactiveRelateConditionList = this.state.interactiveRelateConditionList;

                if (interactiveRelateConditionList && interactiveRelateConditionList.length > 0) {
                    interactiveRelateConditionList.forEach(item => {
                        relateConditionList.push({
                            idCode: '' + item,
                            highlight: 1
                        });
                    });
                }
            }
            params.relateConditionList = relateConditionList;

            // 分值必填
            if (params.predeterminedScore == null || params.predeterminedScore === '') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.predeterminedScore
                    )
                );
            }

            // 准备交互分类
            let selectedCategory = this.state.selectedCategory;
            params.interactiveCategory = {
                id: selectedCategory.id,
                name: selectedCategory.name
            };

            // 准备规则数据
            let type = this.privates.model.inspectionPoint.type;
            let gradeType = this.privates.model.inspectionPoint.gradeType;
            let conditionLogic = this.state.conditionLogic;
            if (type === Udesk.enums.scoreType.aiScore.id && gradeType === Udesk.enums.gradeTypes.yesOrNo.id) {
                let ruleList = [
                    {
                        formula: conditionLogic
                    }
                ];
                params.ruleList = ruleList;
            }
            if (type === Udesk.enums.scoreType.interactiveDetection.id && gradeType === Udesk.enums.gradeTypes.multipleFactors.id) {
                let foctorsValue = this.state.foctorsValue;
                let foctorsHit = this.state.foctorsHit;
                if (foctorsValue !== '') {
                    let ruleList = [
                        {
                            mulElementId: foctorsValue,
                            hit: foctorsHit
                        }
                    ];
                    params.ruleList = ruleList;
                    delete params.interactiveRule;
                } else {
                    inspectFlag = false;
                }
            }
            if (type === Udesk.enums.scoreType.interactiveDetection.id && gradeType === Udesk.enums.gradeTypes.yesOrNo.id) {
                params.ruleList = [];
            }
            //手动评分新增NA类型
            if (type === Udesk.enums.scoreType.manualScore.id) {
                params.naScoreType = this.privates.naScoreType;
            }
            params.type = this.privates.type;
            if (inspectFlag) {
                if (this.privates.computes.isEditing) {
                    this.actions.editPoint(params);
                } else {
                    this.actions.creatPoint(params);
                }
            } else {
                Udesk.ui.notify.error(
                    UdeskLocales.current.pages.tasks.manage.template.checkPoint.detail.foctorsErrorNone
                );
            }
        },

        creatPoint(params) {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionPoints', this.props.sdkOptions);
            Udesk.ajax.post(url, params).then(
                resp => {
                    Udesk.ui.notify.success(
                        `${UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.name}${UdeskLocales.current.business.info.creatSuccess}`
                    );
                    this.actions.transitionToCheckPointIndex();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        `${UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.name}${UdeskLocales.current.business.info.creatError}`
                    );
                }
            );
        },

        editPoint(params) {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionPoints/' + this.privates.model.inspectionPoint.id + '?taskId=' + this.privates.model.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, params).then(
                resp => {
                    Udesk.ui.notify.success(
                        `${UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.name}${UdeskLocales.current.business.info.editSuccess}`
                    );
                    this.actions.transitionToCheckPointIndex();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        `${UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.name}${UdeskLocales.current.business.info.editError}`
                    );
                }
            );
        },

        generateHighlight() {
            this.setState({
                relateConditionList: generateHighlightArray(this)
            });
        },

        changeScoreType(value) {
            this.privates.model.inspectionPoint.type = value;
            this.privates.model.inspectionPoint.gradeType = Udesk.enums.gradeTypes.yesOrNo.id;
            this.actions.update();
        },

        getGearOptionListOld(gearOptionList) {
            this.privates.model.inspectionPoint.ruleList = gearOptionList;
            this.actions.update();
        },

        // transitionToCheckPointIndex() {
        //     let taskId = this.privates.model.taskId;
        //     let routeOptions = {
        //         history: this.props.history,
        //         routeName: 'tasksCheckPointIndex',
        //         pathParams: {
        //             taskId: taskId
        //         }
        //     };
        //     Udesk.ui.routing.transitionTo(routeOptions);
        // },

        changePointNaChecked(e) {
            if (e.target.checked) {
                this.privates.model.inspectionPoint.naChecked = 1;
            } else {
                this.privates.model.inspectionPoint.naChecked = 0;
            }

            this.actions.update();
        },

        switchNode(value, title) {
            this.setState({
                selectedCategory: { id: value, name: title[0] }
            });
        },
        //#endregion
    };

    //#region Life Cycle
    componentDidMount() {
        // const { props } = this;
        // let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        // new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         '/inspectionTasks/' + params.taskId,
        //         props.sdkOptions
        //     );
        //     Udesk.ajax.get(url, { type: 'associate' })
        //         .then(
        //             resp => {
        //                 this.privates.processStatus = resp.data.processStatus;
        //                 resolve(resp.data);
        //             },
        //             reason => {
        //                 Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.screeningCondition));
        //                 reject(reason);
        //             }
        //         );
        // });

        // let task = props.sdkOptions.props.task;

        // //以下为旧页面
        // let scoreType = Udesk.enums.scoreType.filter(item => item !== null);

        // if (
        //     task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id ||
        //     task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id
        // ) {
        //     scoreType = scoreType.filter(type => {
        //         return (
        //             type.id !== Udesk.enums.scoreType.manualScore.id &&
        //             type.id !== Udesk.enums.scoreType.interactiveDetection.id
        //         );
        //     });
        // }
        // this.setState({
        //     scoreType: scoreType
        // });

        // 初始化交互分类，默认选中全部
        let interactiveCategory = this.privates.model && this.privates.model.inspectionPoint.interactiveCategory;
        if (interactiveCategory && interactiveCategory.id && interactiveCategory.name) {
            this.setState({
                selectedCategory: interactiveCategory
            });
        } else {
            let categories = this.privates.model && this.privates.model.categories;
            if (categories && categories.length > 0) {
                this.setState({
                    selectedCategory: { id: categories[0].value, name: categories[0].title }
                });
            }
        }

        // 准备规则数据
        let ruleList = this.privates.model && this.privates.model.inspectionPoint.ruleList;
        if (!ruleList) {
            ruleList = [];
        }

        // 初始化智能评价、是否符合时的规则数据
        let type = this.privates.model && this.privates.model.inspectionPoint.type;
        let gradeType = this.privates.model && this.privates.model.inspectionPoint.gradeType;
        let conditionRuleList = ruleList.filter(item => item.formula != null);
        if (type === Udesk.enums.scoreType.aiScore.id && gradeType === Udesk.enums.gradeTypes.yesOrNo.id) {
            if (conditionRuleList && conditionRuleList.length > 0) {
                this.setState({
                    conditionLogic: conditionRuleList[0].formula
                });
            }
        }

        // 初始化规则高亮数据
        let relateConditionList = this.privates.model && this.privates.model.inspectionPoint.relateConditionList;
        let interactiveRelateConditionList = [];
        if (type === Udesk.enums.scoreType.interactiveDetection.id) {
            if (relateConditionList && relateConditionList.length > 0) {
                relateConditionList.forEach(item => {
                    if (item.highlight) {
                        interactiveRelateConditionList.push(parseInt(item.idCode, 10));
                    }
                });
                this.setState({
                    interactiveRelateConditionList: interactiveRelateConditionList
                });
            }
        } else {
            if (relateConditionList && relateConditionList.length > 0) {
                this.setState({
                    relateConditionList: relateConditionList
                });
            }
        }

        // 初始化交互检测、多要素的规则，默认选中第一个要素
        let foctors = this.privates.model && this.privates.model.foctorsData;
        this.setState({
            foctorsData: foctors
        });
        let foctorsRuleList = ruleList.filter(item => item.mulElementId != null);
        let selectedFoctor = {};
        if (foctorsRuleList.length > 0) {
            selectedFoctor.foctorsValue = foctorsRuleList[0].mulElementId;
            selectedFoctor.foctorsHit = foctorsRuleList[0].hit;
            this.setState(selectedFoctor);
        } else {
            if (foctors && foctors.length > 0) {
                selectedFoctor.foctorsValue = foctors[0].id;
                selectedFoctor.foctorsHit = 0;
                this.setState(selectedFoctor);
            }
        }
        // 回显无效要素
        if (selectedFoctor.foctorsValue) {
            let foctorsObj = foctors.find(item => item.id === selectedFoctor.foctorsValue);
            if (!foctorsObj && foctorsRuleList && foctorsRuleList.length > 0) {
                this.setState({
                    foctorsFlag: true,
                    foctorsNoneValue: {
                        id: foctorsRuleList[0].mulElementId,
                        name: foctorsRuleList[0].name
                    }
                });
            }
        }
    }
    onModelResolved(model) {
        let data = null;
        //判断是新建模板 还是编辑
        if (model && model.template) {
            data = model.template;
        } else if (model && model.inspectionPoint) {
            data = model.inspectionPoint;
        }
        this.privates.scoreType = data.scoreType;
        this.privates.type = data.type;
        this.privates.vesionFlag = data.type === 1 || data.type === 3 ? false : true;
        this.privates.name = data.name;
        this.privates.remark = data.remark;
        this.privates.naChecked = data.naChecked;
        this.privates.naPreviousFormula = data.naPreviousFormula;
        this.privates.naScoreType = data.naScoreType || Udesk.enums.naScoreType.average.id;
        this.privates.predeterminedScore = data.predeterminedScore || 1;
        this.privates.heightLightList = (data.relateConditionList
            && data.relateConditionList.length > 0
            && data.relateConditionList.filter(item => item.highlight).map(item => {
                return item.idCode;
            })) || [];
        if (data.type === Udesk.enums.pointTypes.smartRules.id) {
            if (!data.conditionList) {
                this.privates.ruleList = [{
                    conditionType: 1,//1话术2交互3特征4信息
                    taskConditionId: null,
                    orderId: 1,
                    operatorType: 11,
                    idCode: 'R1',
                    operatorList: [{
                        applyConditionHitType: 1,
                        applyOperatorScope: {
                            key: 'all',
                            id: 1
                        },
                        type: 11,
                        operator: "all",
                        applyRole: 'all',
                    }],
                    applyConditionId: null,
                    applyConditionIdCode: null,
                    operator: "all",
                    applyRole: 'all',
                    applyScope: null,
                    similarityScore: null,
                    differWordNumber: null,
                    wordPerMinite: null,
                    maxWordPerMinite: null,
                    grabIntervalTime: null,
                    grabMaxSpeakTime: null,
                    intervalSecond: null,
                    keyword: "",
                    semanticSentence: null,
                    applyConditionScope: null,
                    applyConditionHitType: 1,
                    // applyOperatorScope: {
                    //     key: 'all',
                    //     id: 1
                    // },
                    param: {},
                },];
            } else {
                this.privates.ruleList = JSON.parse(JSON.stringify(data.conditionList));
            }
            data.conditionList && data.conditionList.forEach((condition, index) => {
                if (condition.operatorList) {
                    this.privates.ruleList[index].operatorList = condition.operatorList;
                } else if (condition.taskOperatorList) {
                    this.privates.ruleList[index].operatorList = condition.taskOperatorList;
                }
            });
            //新建有模板/编辑
            if (data.gradeType && data.ruleList) {
                //31对应多重算子
                this.privates.gradeType = data.gradeType === 1 && data.ruleList ? data.ruleList[0].judgeStrategy : 31;
                this.privates.ruleList && this.actions.getOperatorList(this.privates.ruleList);
                if (data.conditionList && data.gradeType === 1 && data.ruleList && data.ruleList[0].judgeStrategy === 3) {
                    this.privates.operatorLogic = data.ruleList[0].formula;
                    this.privates.items = [];
                    let list = data.ruleList[0].formula.split(/(or|and|not|&&|\|\||!|\(|\))/);
                    this.privates.itemsCount = list.length;
                    list.forEach((item, index) => {
                        item = item.trim();
                        if (item && item.length > 0) {
                            let obj = this.privates.logicList.find(current => current.value === item);
                            let objs = this.privates.conditionListFilter.find(current => current.value === item);
                            if (obj) {
                                this.privates.items = [
                                    ...this.privates.items,
                                    {
                                        id: `drag-${index}`,
                                        content: obj.content,
                                        value: obj.value,
                                        prevId: obj.id,
                                        prevClass: obj.id.split('-')[0],
                                    }
                                ];
                            } else if (objs) {
                                this.privates.items = [
                                    ...this.privates.items,
                                    {
                                        id: `drag-${index}`,
                                        content: objs.content,
                                        value: objs.value,
                                        prevId: objs.id,
                                        prevClass: objs.id.split('-')[0],
                                    }
                                ];
                            }
                        }
                    });
                }
                if (data.conditionList && data.gradeType === 3) {
                    this.privates.operatorMultipleLogicList = data.ruleList;
                    this.privates.operatorMultipleLogicitems = [[]];
                    data.ruleList.forEach((rule, index) => {
                        this.privates.operatorMultipleLogicitems[index] = [];
                        let list = rule.formula.split(/(or|and|not|&&|\|\||!|\(|\))/);
                        // operatorMultipleLogicitems: [[]],
                        // operatorMultipleLogicitemsCount: [0],
                        // operatorMultipleLogic: ['']
                        this.privates.operatorMultipleLogicitemsCount[index] = list.length;
                        list.forEach((item, indexInner) => {
                            item = item.trim();
                            if (item && item.length > 0) {
                                let obj = this.privates.logicList.find(current => current.value === item);
                                let objs = this.privates.conditionListFilter.find(current => current.value === item);
                                if (obj) {
                                    this.privates.operatorMultipleLogicitems[index] = [
                                        ...this.privates.operatorMultipleLogicitems[index],
                                        {
                                            // id: `drag-${index}`,
                                            id: `drag-${index}-${indexInner + 1}`,
                                            content: obj.content,
                                            value: obj.value,
                                            prevId: obj.id,
                                            prevClass: obj.id.split('-')[0],
                                        }
                                    ];
                                    // this.privates.operatorMultipleLogicitems.push([]);
                                } else if (objs) {
                                    this.privates.operatorMultipleLogicitems[index] = [
                                        ...this.privates.operatorMultipleLogicitems[index],
                                        {
                                            id: `drag-${index}-${indexInner + 1}`,
                                            content: objs.content,
                                            value: objs.value,
                                            prevId: objs.id,
                                            prevClass: objs.id.split('-')[0],
                                        }
                                    ];
                                    // this.privates.operatorMultipleLogicitems.push([]);
                                }
                            }
                        });
                    });
                }
            }
        }
        if (data.type === Udesk.enums.pointTypes.machineLearning.id) {
            //自学习
            this.privates.gradeType = data.gradeType;
        }
        if (data.type === Udesk.enums.pointTypes.manual.id) {
            //手动评分
            this.privates.model.inspectionPoint.predeterminedScore = data.predeterminedScore;
            this.privates.model.inspectionPoint.scoreType = data.scoreType;
            this.privates.model.inspectionPoint.gradeType = data.gradeType;
        }
    }
    //#endregion
}

function generateHighlightArray(that) {
    let conditionLogic = that.state.conditionLogic;
    let gradeType = that.privates.model.inspectionPoint.gradeType;

    if (gradeType === Udesk.enums.gradeTypes.level.id) {
        conditionLogic = '';
        let ruleList = that.privates.model.inspectionPoint.ruleList;

        if (ruleList && ruleList.length > 0) {
            ruleList.forEach((rule, index) => {
                if (rule.formula) {
                    if (index === 0) {
                        conditionLogic += '(' + rule.formula + ')';
                    } else {
                        conditionLogic += '&&(' + rule.formula + ')';
                    }
                }
            });
        }
    }

    if (conditionLogic == null || conditionLogic.trim() === '') {
        return [];
    }

    let highlightArr = [];
    let allDimensions = conditionLogic.split(/(&&|\|\||!|\(|\))/);
    let inspectionConditions = that.privates.model.inspectionConditions;

    for (var i = 0; i < allDimensions.length; i++) {
        let dimension = allDimensions[i];

        if (dimension !== '!' && dimension !== '||' && dimension !== '&&' && dimension !== '(' && dimension !== ')') {
            let items = dimension.split(/(>|=|<)/);
            let conditions = inspectionConditions.find(ic => ic.idCode === items[0]);

            if (!conditions) {
                continue;
            }

            if (!highlightArr.find(highlight => highlight.idCode === items[0])) {
                highlightArr.push({
                    idCode: items[0],
                    highlight: false
                });
            }
        }
    }

    return highlightArr;
}

function checkHighlightArray(that) {
    let preHighlightArray = that.state.relateConditionList
        ? that.state.relateConditionList.map(item => {
            return item.idCode;
        })
        : [];
    let currentHighlightArray = generateHighlightArray(that)
        ? generateHighlightArray(that).map(item => {
            return item.idCode;
        })
        : [];
    return preHighlightArray.sort().join() === currentHighlightArray.sort().join();
}
