import React, { useState, useEffect, useRef } from 'react';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Icon, Input, Tag } from 'udesk-ui';
import { postIntelligentPartnerTags } from 'src/api/intelligentPartnerTags';
import UdeskLocales from 'UdeskLocales';
// import './style.scss';

export const AddTag = React.memo((props: any) => {
    const { groupId, onSubmit } = props;
    // const locales = Locales['current'];
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<any>(null);
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputConfirm = (submit) => {
        // if (inputValue && tags.indexOf(inputValue) === -1) {
        //     setTags([...tags, inputValue]);
        // }
        if (submit) {
            postIntelligentPartnerTags({
                groupId,
                tagName: inputValue,
            }).then(() => {
                setInputValue('');
                setInputVisible(false);
                onSubmit();
            });
        } else {
            setInputVisible(false);
        }
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    return (
        <React.Fragment>
            {inputVisible && (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={{
                        width: 78,
                        margin: 4,
                        verticalAlign: 'top',
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm.bind(null, false)}
                    onPressEnter={handleInputConfirm.bind(null, true)}
                />
            )}
            {!inputVisible && (
                <Tag className="site-tag-plus" onClick={showInput}>
                    <Icon type="PlusOutlined" antdIcon={true} />{/* 新建标签 */}{UdeskLocales['current'].pages.coach.customerTagsManagement.components.addTag.index.newLabel}</Tag>
            )}
        </React.Fragment>
    );
});
