import React, { useImperativeHandle } from 'react';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const NarratorWrap = styled.div`
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 2px 8px;
    color: rgba(0, 0, 0, 0.45);
`;

export const Narrator = React.memo(
    React.forwardRef((props: any, ref) => {
        useImperativeHandle(ref, () => {
            return {};
        });
        return <NarratorWrap>{`${/* 旁白: */UdeskLocales['current'].components.coach.dialogue.components.narrator.narrator}${props.content}`}</NarratorWrap>;
    })
);
