import React from 'react';
import { Col, Row } from 'udesk-ui';
import styled from 'styled-components';
import { ColProps } from 'udesk-ui/lib/col';

export const Container = ({ children }) => {
    return <Row gutter={[12, 8]}>{children}</Row>;
};

type FixedWidth = {
    width: number | 'auto';
};
type PercentWidth = {
    widthRatio: number;
};
type ItemProps = PercentWidth | FixedWidth;

const ItemWrap = styled.div`
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
    background-color: #fff;
`;

export const Item: React.FC<ItemProps> = (props) => {
    const { children } = props;
    const colProps: ColProps = {};
    if (Object.prototype.hasOwnProperty.call(props, 'widthRatio')) {
        const { widthRatio } = props as PercentWidth;
        colProps.span = 24 * widthRatio;
    }
    if (Object.prototype.hasOwnProperty.call(props, 'width')) {
        const { width } = props as FixedWidth;
        colProps.flex = width === 'auto' ? width : `0 1 ${width}px`;
    }
    return (
        <Col {...colProps} style={{ overflowX: 'hidden' }}>
            <ItemWrap>{children}</ItemWrap>
        </Col>
    );
};
