import Udesk from 'Udesk';
import SdkLoaderBase from '../../../common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';

class BusinessStatisticsComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: "data-analysis-business-statistics",
        pageTitle: UdeskLocales.current.components.pages.dataAnalysis.businessStatistics
    };
}

export default Udesk.react.udeskify(BusinessStatisticsComponent);