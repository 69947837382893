import Raven from "raven-js";

const NODE_ENV = process.env.NODE_ENV;
const REACT_APP_SENTRY_DSN_PUBLIC_PRODUCTION = process.env.REACT_APP_SENTRY_DSN_PUBLIC_PRODUCTION;
const REACT_APP_SENTRY_DSN_PUBLIC_DEVELOPMENT = process.env.REACT_APP_SENTRY_DSN_PUBLIC_DEVELOPMENT;
const SENTRY_DEBUG = (process.env.REACT_APP_SENTRY_DEBUG === "true");
const RELEASE_VERSION = process.env.REACT_APP_RELEASE_VERSION;
const USER_ID_FIELD = process.env.REACT_APP_SENTRY_USER_ID_FIELD;
const USER_NAME_FIELD = process.env.REACT_APP_SENTRY_USER_NAME_FIELD;
const USER_EMAIL_FIELD = process.env.REACT_APP_SENTRY_USER_EMAIL_FIELD;
const IGNORE_CLIENT_VISIBLE_EXCEPTIONS = (process.env.REACT_APP_SENTRY_IGNORE_CLIENT_VISIBLE_EXCEPTIONS === "true");
const IGNORE_RESPONSE_CODES = JSON.parse(process.env.REACT_APP_SENTRY_IGNORE_RESPONSE_CODES || "[]");
const MAX_RESPONSE_TEXT_LENGTH = parseInt(process.env.REACT_APP_SENTRY_MAX_RESPONSE_TEXT_LENGTH, 10) || 0;
const ENVIRONMENT_DOMAINS = {
    production: [],
    staging: ["s-*.udesk.cn", "*.udeskt1.com", "*.udeskt2.com", "*.udeskt3.com","s-*.udeskb1.com"],
    development: ["*.ud.com", "local-*.udesk.cn", "*.local.udesk.cn"]
};
let ENV = NODE_ENV;
let environmentKeys = Object.keys(ENVIRONMENT_DOMAINS);
domains: for (let index = 0; index < environmentKeys.length; index++) {
    let environment = environmentKeys[index];
    let domains = ENVIRONMENT_DOMAINS[environment];
    if (domains != null && domains.length > 0) {
        for (let domainsIndex = 0; domainsIndex < domains.length; domainsIndex++) {
            let domainRule = domains[domainsIndex];
            if (typeof domainRule === "string") {
                let regExp = new RegExp("^" + domainRule.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$&").replace("\\*", ".*") + "$", "i");
                if (regExp.test(window.location.hostname)) {
                    ENV = environment;
                    break domains;
                }
            }
        }
    }
}

class ReactSentry {
    constructor() {
        this._initialized = false;
    }

    init(options) {
        if (this._initialized) {
            throw new Error("Should not call ReactSentry's init method twice.");
        }

        let {
            getUser,
            getExtraContext
        } = options;
        if (getUser && typeof getUser !== "function") {
            throw new Error(`options.getUser must be function.`);
        }
        if (getExtraContext && typeof getExtraContext !== "function") {
            throw new Error(`options.getExtraContext must be function.`);
        }
        this._options = options;

        try {
            let dsn = (ENV === "production" ? REACT_APP_SENTRY_DSN_PUBLIC_PRODUCTION : REACT_APP_SENTRY_DSN_PUBLIC_DEVELOPMENT);
            Raven.config(dsn, {
                environment: ENV,
                debug: (SENTRY_DEBUG === "true"),
                // whitelistUrls: [new RegExp(RegExp.escape(process.env.REACT_APP_STATIC_LINK), "i")],
                release: RELEASE_VERSION,
            });
            Raven.install();

            this._initialized = true;
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.warn("Error during `sentry` initialization: " + err.message, err);
        }
    }
    ready() {
        try {
            let user = {};
            if (this._options.getUser) {
                user = this._options.getUser() || {};
            }
            Raven.setUserContext(Object.assign({
                /* Sentry reserved names */
                id: user[USER_ID_FIELD],
                username: user[USER_NAME_FIELD],
                email: user[USER_EMAIL_FIELD],
                // ip_address: ""
            }, user));

            // Set tags context
            let envTagsContext = {
                environment: ENV
            };
            let tagsContext = {};
            if (this._options.getTagsContext && typeof this._options.getTagsContext === "function") {
                tagsContext = this._options.getTagsContext() || {};
            }
            Raven.setTagsContext(Object.assign(envTagsContext, tagsContext, {
                hostname: window.location.hostname
            }));

            // Set extra context
            let extraContext = {};
            if (this._options.getExtraContext) {
                extraContext = this._options.getExtraContext() || {};
            }
            Raven.setExtraContext(extraContext);

            Raven.setDataCallback(function (data) {
                // Executes before `shouldSendCallback`!
                // do something to data
                data.server_name = window.location.hostname;
                if (data.tags) {
                    data.tags["message"] = data.message;
                    data.tags["create.time"] = formatDate(new Date());
                }
                return data;
            });

            let context = this;
            Raven.setShouldSendCallback(function (data) {
                if (context._options.getShouldSendCallback && typeof context._options.getShouldSendCallback === "function") {
                    if (!context._options.getShouldSendCallback()) {
                        return false;
                    }
                }
                if (data != null && data.extra != null && data.extra.__isAjaxResponse === true) {
                    let extra = data.extra;
                    delete extra.__isAjaxResponse;
                    if (IGNORE_CLIENT_VISIBLE_EXCEPTIONS && extra.response && extra.response.visible === true) {
                        return false;
                    }
                    if (IGNORE_RESPONSE_CODES && IGNORE_RESPONSE_CODES.length > 0 && IGNORE_RESPONSE_CODES.includes(extra.statusCode)) {
                        return false;
                    }
                }
                return true;
            });

            this._enableGlobalErrorCatching();
        }
        catch (err) {
            this.captureException(err);
        }
    }
    captureException(error) {
        if (this._initialized) {
            Raven.captureException(...arguments);
        }
        else {
            throw error;
        }
    }
    captureMessage(message) {
        if (this._initialized) {
            Raven.captureMessage(...arguments);
        }
        else {
            throw new Error(message);
        }
    }
    _enableGlobalErrorCatching() {
        if (window.jQuery) {
            let that = this;
            jQuery(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
                let requestData = ajaxSettings.data;
                if (!(/^GET$/i).test(ajaxSettings.type)) {
                    /* eslint-disable no-empty */
                    try {
                        requestData = JSON.parse(ajaxSettings.data);
                    } catch (err) { }
                    /* eslint-enable no-empty */
                }
                let requestPath = ajaxSettings.url.replace(/\?.*/, "");
                let options = {
                    extra: Object.assign({}, {
                        __isAjaxResponse: true,
                        url: ajaxSettings.url,
                        method: ajaxSettings.type,
                        dataType: ajaxSettings.dataType,
                        contentType: ajaxSettings.contentType,
                        data: requestData,
                        statusCode: jqXHR.status,
                        error: thrownError || jqXHR.statusText,
                        response: null,
                        readyState: jqXHR.readyState,
                        async: ajaxSettings.async,
                        processData: ajaxSettings.processData,
                        global: ajaxSettings.global,
                        crossDomain: ajaxSettings.crossDomain,
                        headers: jqXHR.getAllResponseHeaders()
                    }),
                    tags: Object.assign({}, that._getCaptureTags(jqXHR), {
                        "ajax.url": requestPath,
                        "ajax.code": jqXHR.status
                    }),
                    fingerprint: [(window.location.protocol + "//" + window.location.host + window.location.pathname), requestPath, ajaxSettings.type, jqXHR.status],
                };

                let shrinkResponseText = (responseText) => {
                    if (MAX_RESPONSE_TEXT_LENGTH > 0) {
                        return responseText.substring(0, MAX_RESPONSE_TEXT_LENGTH);
                    } else {
                        return responseText;
                    }
                };

                if (typeof ajaxSettings.dataType === "string" && ajaxSettings.dataType.match(/^json$/i)) {
                    try {
                        options.extra.response = getResponseJson(jqXHR);
                    } catch (err) {
                        options.extra.response = shrinkResponseText(jqXHR.responseText);
                    }
                } else {
                    options.extra.response = shrinkResponseText(jqXHR.responseText);
                }

                that.captureMessage(jqXHR.errorMsg || jqXHR._serverMsg || that._extractMessage(jqXHR), options);
            });
        }
    }

    _extractMessage(reason) {
        const defaultMessage = "Unhandled Promise error detected";
        /* eslint-disable indent */
        switch (typeof reason) {
            case 'string':
                return reason;
            case 'object':
                return reason.message || defaultMessage;
            default:
                return defaultMessage;
        }
        /* eslint-enable indent */
    }
    _getCaptureTags(error) {
        let tags = {};
        if (isJqXHR(error)) {
            let jqXHR = error;
            if (jqXHR.status === 0 && jqXHR.readyState === 0) {
                tags["error.from"] = "client";
                if (jqXHR.statusText === "abort") {
                    tags["error.client.ajax.reason"] = "abort";
                } else {
                    tags["error.client.ajax.reason"] = "Maybe bad network";
                }
            } else {
                tags["error.from"] = "server";
                let response = getResponseJson(jqXHR, false);
                if (response != null && response.visible === true) {
                    tags["error.server.exception.type"] = "validation";
                } else {
                    tags["error.server.exception.type"] = "exception";
                }
            }
        } else {
            tags["error.from"] = "client";
        }
        return tags;
    }
}

function isJqXHR(jqXHR) {
    return (typeof jqXHR.then === "function" && typeof jqXHR.success === "function" && typeof jqXHR.fail === "function");
}

function getResponseJson(jqXHR, throwError = true) {
    if (throwError === true) {
        return jqXHR.responseJSON || JSON.parse(jqXHR.responseText);
    } else {
        try {
            return jqXHR.responseJSON || JSON.parse(jqXHR.responseText);
        } catch (err) {
            return null;
        }
    }
}

function formatDate(date) {
    let year = padNumber(date.getFullYear());
    let month = padNumber(date.getMonth() + 1);
    let day = padNumber(date.getDate());
    let hour = padNumber(date.getHours());
    let minute = padNumber(date.getMinutes());
    let second = padNumber(date.getSeconds());
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

function padNumber(value) {
    if (value < 10) {
        return "0" + value;
    } else {
        return value + "";
    }
}

const r = new ReactSentry();

export default r;