import UploadCredentialBaseClass from "./base";
const UPLOAD_DATA_SYMBOL = Symbol("[UploadData]");

class UploadObsCredentialClass extends UploadCredentialBaseClass {
    constructor(uploadData) {
        super();
        this[UPLOAD_DATA_SYMBOL] = uploadData;
    }
    abort() {
        if (this[UPLOAD_DATA_SYMBOL] != null && this[UPLOAD_DATA_SYMBOL].xhr != null) {
            if (typeof this[UPLOAD_DATA_SYMBOL].xhr.abort === "function") {
                this[UPLOAD_DATA_SYMBOL].xhr.abort();
            }
        }
    }
}

export default UploadObsCredentialClass;