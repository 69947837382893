import Udesk from 'Udesk';
import InteractiveRecognitionDetailComponent from "./component";

class InteractiveRecognitionDetailRoute extends InteractiveRecognitionDetailComponent {
    model() {
        let { match, sdkOptions } = this.props;

        let detailPromise;
        if (match.params.id) {
            let url = Udesk.business.apiPath.concatApiPath(
                `interactiveDatas/${match.params.id}`,
                sdkOptions
            );
            detailPromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        } else {
            detailPromise = new Promise((resolve, reject) => {
                resolve({
                    type: Udesk.enums.interactiveQuestionTypes.standard.id,
                    similarQList: [],
                    exceptSimilarQList: [],
                    similarAList: [],
                    wrongAList: [],
                    targetType: Udesk.enums.targetTypes.all.id,
                    status: Udesk.enums.activeFlags.effiective.id
                });
            });
        }

        let url1 = Udesk.business.apiPath.concatApiPath(
            `interactiveCategories/initTree`,
            sdkOptions
        );
        let categoriesPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url1).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        //信息实体

        return {
            categories: categoriesPromise,
            detail: detailPromise,
        };
    }

    parseModel(model) {
        if (model.detail) {
            model.detail.similarQList = model.detail.similarQList || [];
            model.detail.exceptSimilarQList = model.detail.exceptSimilarQList || [];
            model.detail.similarAList = model.detail.similarAList || [];
            model.detail.wrongAList = model.detail.wrongAList || [];
        }
        return model;
    }


}

export default InteractiveRecognitionDetailRoute;
