export default {
  lang: {
    num: 'Die',
    total: 'Streifen/insgesamt',
    strip: 'Artikel',
    more: 'Mehr',
    chosen: 'Ausgewählt',
    cancel: 'Abbrechen der Auswahl',
    bulkOperations: 'Stapel betrieb',
    import: 'Importieren',
  },
};
