// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetSupervisionInspectionByCallIdParam,
    BaseResponseSupervisionInspectionResult,
} from '../../types';

/**
 * findByCallId
 *
 * @export
 * @tags supervision-category-inspection-result-controller
 * @link [GET] /supervisionInspection/{callId}
 * @param callId
 */
export function getSupervisionInspectionByCallId(
    options: ApiOptions<GetSupervisionInspectionByCallIdParam, never> & {
        segments: GetSupervisionInspectionByCallIdParam;
    }
): Promise<BaseResponseSupervisionInspectionResult> {
    return request<never, GetSupervisionInspectionByCallIdParam, never>(
        '/supervisionInspection/{callId}',
        { ...options, method: 'get', __$requestCalleeName: 'getSupervisionInspectionByCallId' }
    );
}

export const meta = [
    {
        tags: ['supervision-category-inspection-result-controller'],
        path: '/supervisionInspection/{callId}',
        method: 'get',
    },
];
