// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostFieldReviewDataSearchTemplateBody,
    BaseResponseListFieldFoundAdvanceWrapperResponse,
} from '../../../types';

/**
 * 销售赋能-筛选器字段
 *
 * @export
 * @tags 字段定义
 * @link [POST] /field/reviewData/search-template
 */
export function postFieldReviewDataSearchTemplate(
    data: PostFieldReviewDataSearchTemplateBody
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse>;
export function postFieldReviewDataSearchTemplate(
    data: PostFieldReviewDataSearchTemplateBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse>;
export function postFieldReviewDataSearchTemplate(
    data: PostFieldReviewDataSearchTemplateBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse> {
    return request<PostFieldReviewDataSearchTemplateBody, never, never>(
        '/field/reviewData/search-template',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postFieldReviewDataSearchTemplate',
        }
    );
}

export const meta = [
    { tags: ['字段定义'], path: '/field/reviewData/search-template', method: 'post' },
];
