import React, { useState, useCallback } from 'react';
import Locales from 'UdeskLocales';
import { Table, Space, Button, Icon, Popconfirm } from 'udesk-ui';
import './index.scss'; 
import { useLiveEffect } from 'src/util/hook';

type propsType = {
    request: any;
    subjectId: number;
}

const DelBtn = (props: any) => {
    const {onDelete, locales} = props;
    return (
        <Popconfirm
            title={locales.labels.deleteContent}
            onConfirm={onDelete}
            onCancel={(e) => e?.stopPropagation()}
            okText={locales.labels.confirm}
            placement='bottom'
            cancelText={locales.labels.cancel}
        >
            <Button 
                type="primary"
                size="small"
                shape="circle"
                icon={<Icon type='MinusOutlined' antdIcon={true}/>} />
        </Popconfirm>
    );
};

export default React.memo((props: propsType) => {
    const locales = Locales['current'];
    const {request, subjectId} = props;
    const [wordsLoading, setWordsLoading] = useState(false);
    const [phrasesLoading, setPhrasesLoading] = useState(false);
    const [wordDataSource, setWordDataSource] = useState<any>();
    const [sentenceDataSource, setSentenceDataSource] = useState<any>();
    const [wordColumns] = useState([
        {
            title: /* 热词 */locales.pages.semanticIntelligence.clusteringAnalysis.detail.ranking.hotWords,
            dataIndex: 'hotWords',
            key: 'hotWords',
        },
        {
            title: /* 语料数 */locales.pages.semanticIntelligence.clusteringAnalysis.detail.ranking.numberOfCorpora,
            dataIndex: 'keywordFreq',
            width: 100,
            key: 'keywordFreq',
        },
        {
            key: 'action',
            width: 50,
            render: (_, record) => (
                <DelBtn locales={locales} onDelete={() => delClickHandle(record.id, record.mainId, 'words')} /> 
            ),
        }
    ]);
    const [sentenceColumns] = useState([
        {
            title: /* 说话人 */locales.pages.semanticIntelligence.clusteringAnalysis.detail.ranking.speaker,
            dataIndex: 'speaker',
            width: 90,
            key: 'speaker',
            render: (_, record) => (
                record.role === 0 ? (
                    <span className="user-type">{/* 坐 */}{locales.pages.semanticIntelligence.clusteringAnalysis.detail.ranking.sit}</span>
                ) : (
                    <span className="user-type customer">{/* 客 */}{locales.pages.semanticIntelligence.clusteringAnalysis.detail.ranking.passenger}</span>
                )
            ),
        },
        {
            title: /* 热句 */locales.pages.semanticIntelligence.clusteringAnalysis.detail.ranking.hotSentence,
            dataIndex: 'hotPhrases',
            key: 'hotPhrases',
        },
        {
            key: 'action',
            width: 50,
            render: (_, record) => (
                <DelBtn locales={locales} onDelete={() => delClickHandle(record.id, record.mainId, 'phrases')} /> 
            ),
        }
    ]);

    const delClickHandle = (id, subjectId, type) => {
        request(`review/cluster/hot/${type}/${id}`, {} ,'del').then(() => {
            if (type === 'words') {
                requestWords(subjectId);
            } else {
                requestPhrases(subjectId);
            }
        });
    };

    const requestWords = useCallback((subjectId) => {
        setWordsLoading(true);
        request(
            'review/cluster/hot/words',  {subjectId}
        ).then(
            resp => setWordDataSource(resp.data)
        ).finally(
            () => setWordsLoading(false)
        );
    }, [
        request
    ]);

    const requestPhrases = useCallback((subjectId) => {
        setPhrasesLoading(true);
        request(
            'review/cluster/hot/phrases',  {subjectId}
        ).then(
            resp => setSentenceDataSource(resp.data)
        ).finally(
            () => setPhrasesLoading(false)
        );
    }, [
        request
    ]);

    useLiveEffect(() => {
        if (subjectId) {
            requestWords(subjectId);
            requestPhrases(subjectId);  
        }
    }, [subjectId]);

    return (
        <div className='clustering-analysis-ranking'>
            <div className='detail-title'>{/* 语料详情 */}{locales.pages.semanticIntelligence.clusteringAnalysis.detail.ranking.corpusDetails}</div>
            <Space>
                <Table 
                    loading={wordsLoading} 
                    columns={wordColumns}
                    dataSource={wordDataSource}
                    pagination={false}
                    scroll={{ y: 'calc(100% -50px)' }}
                />
                <Table 
                    loading={phrasesLoading}
                    columns={sentenceColumns}
                    dataSource={sentenceDataSource}
                    pagination={false}
                    scroll={{ y: 'calc(100% -50px)' }}
                />
            </Space>
        </div>

    );
});
