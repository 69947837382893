import React, { FC, memo, useMemo } from 'react';
import { DatePicker, Select, Input, InputNumber } from 'udesk-ui';

const UI = {
    Input,
    TextArea: Input.TextArea,
    InputNumber,
    Select,
    DatePicker,
};

export type TemplateProps = {
    type?: string;
} & Record<string, any>;

const Template: FC<TemplateProps> = memo((props) => {
    const { type, ...fieldProps } = props;

    const Component = useMemo(() => UI[type || 'Input'], [type]);

    return <Component {...fieldProps} />;
});

export default Template;
