// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { BaseResponseDataCallAnalysisOrgOptionResponse } from '../../../../types';

/**
 * organizations
 *
 * @export
 * @tags 团队分析
 * @link [GET] /review/call/analysis/organizations
 */
export function getReviewCallAnalysisOrganizations(): Promise<BaseResponseDataCallAnalysisOrgOptionResponse>;
export function getReviewCallAnalysisOrganizations(
    options: ApiOptions<never, never>
): Promise<BaseResponseDataCallAnalysisOrgOptionResponse>;
export function getReviewCallAnalysisOrganizations(
    options?: ApiOptions<never, never>
): Promise<BaseResponseDataCallAnalysisOrgOptionResponse> {
    return request<never, never, never>('/review/call/analysis/organizations', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallAnalysisOrganizations',
    });
}

export const meta = [
    { tags: ['团队分析'], path: '/review/call/analysis/organizations', method: 'get' },
];
