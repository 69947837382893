import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import {
    Menu,
    Popover,
    Space,
    Input,
    // Tag,
    Button,
    Dropdown,
    Badge,
} from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const ComplaintStatusAndDetail = React.memo((props) => {
    const { complaintInfo, localesReview } = props;
    const color =
        complaintInfo.complainStatus === Udesk.enums.complainStatus.success.id
            ? '#0FBA26'
            : complaintInfo.complainStatus === Udesk.enums.complainStatus.fail.id
            ? '#FB5127'
            : 'rgba(0, 0, 0, 0.25)';
    return (
        <>
            {complaintInfo.firstComplaintInfo ? (
                <ComplaintStatusAndDetail
                    complaintInfo={complaintInfo.firstComplaintInfo}
                    localesReview={localesReview}
                />
            ) : null}
            <div className="appeal-history-component-detail-item-content">
                <div style={{ display: 'flex', overflow: 'hidden', flex: 1 }}>
                    <div className="appeal-history-component-detail-item-content-label">{`${localesReview.reason}:`}</div>
                    <div
                        className="appeal-history-component-detail-item-content-value"
                        title={complaintInfo.complaint}
                    >
                        {complaintInfo.complaint}
                    </div>
                </div>
                <Space size={0}>
                    <Badge color={color} />
                    <div>
                        {Udesk.enums.complainStatus.getName(complaintInfo.complainStatus || 1)}
                    </div>
                </Space>
            </div>
        </>
    );
});

export const ReviewComponent = React.memo((props) => {
    const locales = UdeskLocales['current'];

    const { visible, complaintInfo, localesReview, showActionsBtn } = props;
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [rejectReason, setRejectReason] = useState('');

    useEffect(() => {
        if (props.isTaskChanged) {
            setRejectReason(
                /* 流程质检任务发生变更，请重新申诉。 */ UdeskLocales['current'].components
                    .qualityScore.components.reviewComponent
                    .theProcessQualityInspectionTaskHasChangedPleaseAppealAgain
            );
        } else {
            setRejectReason('');
        }
    }, [props.isTaskChanged]);

    if (!visible) return null;

    const onVisibleChange = (visible) => {
        setPopoverVisible(true);
    };
    const onInput = (e) => {
        setRejectReason(e.target.value);
    };
    const onSubmit = () => {
        setPopoverVisible(false);
        props.onReject?.(rejectReason);
    };
    const passComplain = () => {
        props.onResolve?.();
    };

    const content = (
        <div style={{ width: 300 }}>
            <Input.TextArea
                value={rejectReason}
                onChange={onInput}
                autoSize={{ minRows: 3, maxRows: 3 }}
                maxCount={200}
                maxLength={200}
                showCount={true}
                placeholder={locales.fix.rejectReasonPlaceHolder}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
                <Button size="small" onClick={() => setPopoverVisible(false)}>
                    {locales.labels.cancel}
                </Button>
                <Button size="small" type="primary" onClick={onSubmit}>
                    {locales.labels.submit}
                </Button>
            </div>
        </div>
    );
    const menu = (
        <Menu>
            <Menu.Item>
                <Button type="text" onClick={() => setPopoverVisible(true)}>
                    {complaintInfo.complainStatus === Udesk.enums.complainStatus.success.id
                        ? locales.fix.reject
                        : locales.fix.changeRejectReason}
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button style={{ width: '100%' }} type="text" onClick={passComplain}>
                    {locales.fix.pass}
                </Button>
            </Menu.Item>
        </Menu>
    );
    return (
        <React.Fragment>
            {visible && (
                <div style={{ padding: '0 8px 8px' }}>
                    <ComplaintStatusAndDetail
                        complaintInfo={complaintInfo}
                        localesReview={localesReview}
                    />
                    {complaintInfo.rejection && (
                        <div className="appeal-history-component-detail-item-content">
                            <div style={{ display: 'flex', overflow: 'hidden' }}>
                                <div className="appeal-history-component-detail-item-content-label">{`${localesReview.rejection}:`}</div>
                                <div
                                    className="appeal-history-component-detail-item-content-value"
                                    title={complaintInfo.rejection}
                                >
                                    {complaintInfo.rejection}
                                </div>
                            </div>
                        </div>
                    )}
                    {showActionsBtn && (
                        <div style={{ textAlign: 'right', marginTop: 8 }}>
                            <Popover visible={popoverVisible} trigger="click" content={content}>
                                <Space>
                                    {(!complaintInfo.complainStatus ||
                                        [
                                            Udesk.enums.complainStatus.default.id,
                                            Udesk.enums.complainStatus.canceled.id,
                                        ].includes(complaintInfo.complainStatus)) && (
                                        <React.Fragment>
                                            <Button
                                                size="small"
                                                danger
                                                onClick={onVisibleChange}
                                                disabled={
                                                    Udesk.enums.complainStatus.canceled.id ===
                                                    complaintInfo.complainStatus
                                                }
                                            >
                                                {locales.fix.reject}
                                            </Button>
                                            <Button
                                                onClick={passComplain}
                                                size="small"
                                                type="primary"
                                                disabled={
                                                    Udesk.enums.complainStatus.canceled.id ===
                                                    complaintInfo.complainStatus
                                                }
                                            >
                                                {locales.fix.pass}
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {complaintInfo.complainStatus &&
                                        complaintInfo.complainStatus !==
                                            Udesk.enums.complainStatus.default.id && (
                                            <React.Fragment>
                                                <Dropdown
                                                    getPopupContainer={(triggerNode) => triggerNode}
                                                    trigger={['click']}
                                                    overlay={menu}
                                                    placement="topCenter"
                                                    disabled={
                                                        Udesk.enums.complainStatus.canceled.id ===
                                                        complaintInfo.complainStatus
                                                    }
                                                >
                                                    <Button size="small" type="primary">
                                                        {locales.fix.revise}
                                                    </Button>
                                                </Dropdown>
                                            </React.Fragment>
                                        )}
                                </Space>
                            </Popover>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
});
