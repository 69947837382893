// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewCallCustomerMessageFindDetailByConditionQuery,
    DataCallAnalysisDetail,
} from '../../../../../types';

/**
 * findDetailByCondition
 *
 * @export
 * @tags 联络看板
 * @link [GET] /review/call/customer/message/findDetailByCondition
 */
export function getReviewCallCustomerMessageFindDetailByCondition(
    options: ApiOptions<never, GetReviewCallCustomerMessageFindDetailByConditionQuery> & {
        params: GetReviewCallCustomerMessageFindDetailByConditionQuery;
    }
): Promise<DataCallAnalysisDetail> {
    return request<never, never, GetReviewCallCustomerMessageFindDetailByConditionQuery>(
        '/review/call/customer/message/findDetailByCondition',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewCallCustomerMessageFindDetailByCondition',
        }
    );
}

export const meta = [
    {
        tags: ['联络看板'],
        path: '/review/call/customer/message/findDetailByCondition',
        method: 'get',
    },
];
