import React from 'react';
import { postMessageLogsExport } from 'src/api/message-logs/export';
import Udesk from 'Udesk';
import { Button } from 'udesk-ui';
import { getSystemModule } from 'Udesk/system/subApp';

class SystemLogComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
        startTime: moment().format('YYYY-MM-DD') + ' 00:00:00',
        endTime: moment().format('YYYY-MM-DD') + ' 23:59:59',
        messageType: Udesk.enums.messageType.all.id,
        messagePushType: Udesk.enums.messagePushType.map((i) => i.id),
        dataSource: [],
        visible: false,
        total: 0,
        pageNum: 1,
        pageSize: 20,
        detailData: {},
        loading: false,
        modelLoading: false,
        messageNameKeyword: '',
    };
    privates = {
        typeOptList: Udesk.enums.messageType,
        channelOptList: Udesk.enums.messagePushType,
        columns: [],
        detailMapKeys: ['messageTime', 'messageName', 'messageType', 'pushType', 'sendTarget', 'content'],
        queryParams: {},
    };

    actions = {
        selectTime([startTime, endTime]) {
            this.setState({
                startTime,
                endTime,
            });
        },
        selectType(value) {
            if (value === Udesk.enums.messageType.timing.id) {
                this.privates.channelOptList = Udesk.enums.messagePushType.filter(
                    (item) => item.id === Udesk.enums.messagePushType.email.id
                );
                this.setState({
                    messageType: value,
                    messagePushType: [Udesk.enums.messagePushType.email.id],
                });
            } else {
                this.privates.channelOptList = Udesk.enums.messagePushType;
                this.setState({
                    messageType: value,
                });
            }
        },
        selectChannel(value) {
            this.setState({
                messagePushType: value,
            });
        },
        search() {
            this.setState(
                {
                    pageNum: 1,
                },
                () => {
                    this.actions.getQueryParams();
                    this.actions.reloadAsyncModel();
                }
            );
        },
        openDeatil(rowItem) {
            this.setState({
                visible: true,
                loading: true,
            });
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`message-logs/${rowItem.id}`, sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    let data = resp.data;
                    data.pushType = Udesk.enums.messagePushType.getName(data.pushType);
                    data.messageType = Udesk.enums.messageType.getName(data.messageType);
                    this.setState({
                        loading: false,
                        detailData: data || {},
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        onClose() {
            this.setState({
                visible: false,
            });
        },
        onShowSizeChange(current, pageSize) {
            this.setState({
                pageNum: current,
                pageSize,
            });
            // this.actions.reloadAsyncModel({ pageNum: current, pageSize });
        },
        pageChange(page, pageSize) {
            this.setState(
                {
                    pageNum: page,
                    pageSize,
                },
                () => {
                    this.actions.reloadAsyncModel({ pageNum: page, pageSize });
                }
            );
        },
        onMessageNameKeywordChange(e) {
            this.setState({
                messageNameKeyword: e.target.value,
            });
        },
        getQueryParams() {
            const { startTime, endTime, pageNum, pageSize, messageType, messagePushType, messageNameKeyword } = this.state;
            this.privates.queryParams = {
                startTime,
                endTime,
                pageNum,
                pageSize,
                messageType,
                messagePushTypeList: messagePushType,
                messageName: messageNameKeyword,
                systemModule: getSystemModule(),
            };
            this.actions.update();
        },
        export() {
            postMessageLogsExport(this.privates.queryParams).then(resp => {
                Udesk.ui.notify.success(this.locales.labels.exportSuccess);
            });
        },
    };

    //#region Life Cycle
    componentDidMount() {
        this.privates.columns = getColums(this.actions, this.locales);
        this.actions.getQueryParams();
        this.actions.update();
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        if (asyncModel && asyncModel.messageLogs) {
            this.setState({
                dataSource: asyncModel.messageLogs.data,
                modelLoading: false,
            });
            if (asyncModel.messageLogs.paging) {
                this.setState({
                    total: asyncModel.messageLogs.paging.total,
                    pageNum: asyncModel.messageLogs.paging.pageNum,
                    pageSize: asyncModel.messageLogs.paging.pageSize,
                });
            }
        }
    }
    componentWillUnmount() {}
    //#endregion
}

function getColums(actions, locales) {
    let colums = [
        {
            title: locales.labels.messageName,
            dataIndex: 'messageName',
        },
        {
            title: locales.labels.messageTime,
            dataIndex: 'messageTime',
        },
        {
            title: locales.labels.messageType,
            dataIndex: 'messageType',
            render: (text, record) => {
                return <div>{Udesk.enums.messageType.getName(text)}</div>;
            },
        },
        {
            title: locales.labels.pushType,
            dataIndex: 'pushType',
            render: (text, record) => {
                return <div>{Udesk.enums.messagePushType.getName(text)}</div>;
            },
        },
        // {
        //     title: '消息状态',
        //     dataIndex: "status",
        // },
        {
            title: locales.labels.action,
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <div>
                        <Button size='small' type='link' onClick={actions.openDeatil.params(record)}>
                            {locales.labels.seeDetail}
                        </Button>
                    </div>
                );
            },
        },
    ];
    return colums;
}

export default SystemLogComponent;
