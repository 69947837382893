import React, { useState, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import {
    Modal,
    FormBuilder,
    Radio,
    Tag,
    Input,
    Tooltip,
    Icon,
    Space,
    Select,
    Spin,
    TreeSelect,
} from 'udesk-ui';
// import './style.scss';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import UdeskList from 'Component/common/udesk-list';
import { postSupervisionRuleConfigs } from 'src/api/supervisionRuleConfigs';
import { getSupervisionRuleConfigsById } from 'src/api/supervisionRuleConfigs/{id}';
import { SupervisionRuleConfigFoundResponse } from 'src/api/types';
import { getReviewCallAnalysisOrganizations } from 'src/api/review/call/analysis/organizations';
import { TreeMenu } from 'udesk_gm_ui';
import { getSupervisionRuleDetails } from 'src/api/supervisionRuleDetails';
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import { RadioChangeEvent } from 'udesk-ui/lib/radio';
import { getSmartTagsCategoryWithTagsByFuncType } from 'src/api/smartTags/categoryWithTags/{funcType}';

const DEFAULT_COLOR = '#108ee9';

const EffectiveTime = (props) => {
    const locales = Locales['current'];

    const [validityPeriod, setValidityPeriod] = useState<1 | 2 | 3>(props.value);
    return (
        <div>
            <Radio.Group
                onChange={(e) => {
                    setValidityPeriod(e.target.value);
                    props.onChange(e.target.value);
                }}
                value={validityPeriod}
                options={[
                    {
                        label: /* 永久有效 */ locales.components.gong.supervisionClassificationModal
                            .index.permanentlyValid,
                        value: 1,
                    },
                    {
                        label: /* 永久无效 */ locales.components.gong.supervisionClassificationModal
                            .index.permanentlyInvalid,
                        value: 2,
                    },
                    {
                        label: /* 自定义有效期 */ locales.components.gong
                            .supervisionClassificationModal.index.customValidity,
                        value: 3,
                    },
                ]}
                style={{
                    height: 32,
                    verticalAlign: 'sub',
                }}
            />
            {validityPeriod === 3 && (
                <div style={{ marginTop: 8 }}>
                    <FormBuilder.Item
                        label={
                            /* 有效期 */ locales.components.gong.supervisionClassificationModal
                                .index.termOfValidity
                        }
                        name="cusEffectTime"
                    >
                        <UdeskDatePicker></UdeskDatePicker>
                    </FormBuilder.Item>
                </div>
            )}
        </div>
    );
};
type InitValuesType = SupervisionRuleConfigFoundResponse;

type valueType = {
    enableSupervisionRating: number;
    levelList: InitValuesType['levelList'];
};
const Rating = (props: { onChange: (value: valueType) => void; value: valueType }) => {
    const locales = Locales['current'];
    const { enableSupervisionRating: status, levelList } = props.value;

    const [tags, setTags] = useState(levelList ?? []);
    const [enableSupervisionRating, setEnableSupervisionRating] = useState(status);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');

    const inputRef = useRef<any>(null);
    const editInputRef = useRef<any>(null);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const handleChangeStatus = (e: RadioChangeEvent) => {
        setEnableSupervisionRating(e.target.value);
    };

    const handleClose = (removedTag: string) => {
        const newTags = tags!.filter((tag) => tag.itemValue !== removedTag);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags!.every((i) => i.itemValue !== inputValue)) {
            setTags([...tags!, { itemValue: inputValue, color: DEFAULT_COLOR }]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags!];
        newTags[editInputIndex].itemValue = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };

    useEffect(() => {
        props.onChange?.({
            enableSupervisionRating,
            levelList: tags,
        });
    }, [tags, enableSupervisionRating]);

    return (
        <>
            <Radio.Group
                value={enableSupervisionRating}
                onChange={handleChangeStatus}
                size="small"
                buttonStyle="solid"
            >
                <Radio.Button value={1}>
                    {/* 开启 */}
                    {locales.components.gong.supervisionClassificationModal.index.open}
                </Radio.Button>
                <Radio.Button value={0}>
                    {/* 关闭 */}
                    {locales.components.gong.supervisionClassificationModal.index.close}
                </Radio.Button>
            </Radio.Group>
            <div
                style={{
                    padding: '30px 0 20px 0',
                }}
                className={enableSupervisionRating ? '' : 'hidden'}
            >
                {tags!.map((tag, index) => {
                    if (editInputIndex === index) {
                        return (
                            <Input
                                ref={editInputRef}
                                key={tag.itemValue}
                                size="small"
                                className="tag-input"
                                value={editInputValue}
                                onChange={handleEditInputChange}
                                onBlur={handleEditInputConfirm}
                                onPressEnter={handleEditInputConfirm}
                            />
                        );
                    }

                    const isLongTag = (tag?.itemValue ?? '').length > 20;

                    const tagElem = (
                        <ColorPicker
                            color={tag.color ?? DEFAULT_COLOR}
                            onChange={({ color }) => {
                                let newTags = tags ? [...tags] : [];
                                newTags[index].color = color;
                                setTags(newTags);
                            }}
                        >
                            <Tag
                                className="edit-tag"
                                key={tag.itemValue}
                                closable={true}
                                color={tag.color ?? DEFAULT_COLOR}
                                onClose={() => handleClose(tag?.itemValue ?? '')}
                            >
                                <span
                                    onDoubleClick={(e) => {
                                        if (index !== 0) {
                                            setEditInputIndex(index);
                                            setEditInputValue(tag?.itemValue ?? '');
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {isLongTag
                                        ? `${(tag?.itemValue ?? '').slice(0, 20)}...`
                                        : tag.itemValue}
                                </span>
                            </Tag>
                        </ColorPicker>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag.itemValue}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
                {inputVisible && (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        className="tag-input"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag className="site-tag-plus" onClick={showInput}>
                        <Icon type="PlusOutlined" antdIcon={true} />
                        {/* 新建评级 */}
                        {locales.components.gong.supervisionClassificationModal.index.newRating}
                    </Tag>
                )}
            </div>
        </>
    );
};

const RuleItem = (porps) => {
    const { dataSource, ratingList, value, onChange } = porps;
    const locales = Locales['current'];

    return (
        <Space>
            <div>
                {/* 当会话 */}
                {locales.components.gong.supervisionClassificationModal.index.whenConversation}
            </div>
            <Select
                style={{ width: 86 }}
                options={[
                    {
                        value: 1,
                        label: /* 命中 */ locales.components.gong.supervisionClassificationModal
                            .index.hit,
                    },
                    {
                        value: 0,
                        label: /* 未命中 */ locales.components.gong.supervisionClassificationModal
                            .index.miss,
                    },
                ]}
                value={value.hitFlag}
                onChange={(v) => {
                    onChange?.({ ...value, hitFlag: v });
                }}
            ></Select>
            <Select
                value={value.ruleIds ?? []}
                style={{ width: 190 }}
                maxTagCount="responsive"
                maxTagTextLength={8}
                mode="multiple"
                options={dataSource.map((i) => ({ value: i.id, label: i.name }))}
                onChange={(v) => {
                    onChange?.({ ...value, ruleIds: v });
                }}
            ></Select>
            <div>
                {/* 时 */}
                {locales.components.gong.supervisionClassificationModal.index.time}
            </div>
            <div>
                {/* 评级为 */}
                {locales.components.gong.supervisionClassificationModal.index.rated}
            </div>
            <Select
                onChange={(v) => {
                    onChange?.({ ...value, ruleName: v });
                }}
                style={{ width: 110 }}
                value={value.ruleName}
                options={ratingList.map((i) => ({ value: i.itemValue, label: i.itemValue }))}
            ></Select>
        </Space>
    );
};

const Rule = (props) => {
    const { value = [], onChange, dataSource, ratingList } = props;

    const onItemChange = (index, v) => {
        let newValue = [...value];
        newValue[index] = v;
        onChange(newValue);
    };

    return (
        <UdeskList
            deleteItem={(index) => {
                let newValue = [...value];
                newValue[index] = null;
                onChange?.(newValue.filter(Boolean));
            }}
            addItem={() => {
                onChange?.([...value, {}]);
            }}
        >
            {value.map((item, index) => {
                return (
                    <RuleItem
                        value={item}
                        onChange={onItemChange.bind(null, index)}
                        dataSource={dataSource}
                        ratingList={ratingList}
                    ></RuleItem>
                );
            })}
        </UdeskList>
    );
};
const locales = Locales['current'];

const DEFAULT_VALUE = {
    levelData: {
        enableSupervisionRating: 1,
        levelList: [
            {
                itemValue:
                    /* 合格 */ locales.components.gong.supervisionClassificationModal.index
                        .qualified,
                color: DEFAULT_COLOR,
            },
            {
                itemValue:
                    /* 不合格 */ locales.components.gong.supervisionClassificationModal.index
                        .unqualified,
                color: DEFAULT_COLOR,
            },
        ],
    },
    effectRule: 1,
};

const CategoryPrefix = 'category-';
const SmartTagPrefix = 'point-';
const UserPrefix = 'user-';
const OrganizationPrefix = 'organization-';

const formatSmartCategoryValue = (
    values: String[] | undefined,
    ...prefixList: [[string, string], [string, string]]
) => {
    const [[attr0], [attr1]] = prefixList;
    const map = {
        [attr0]: [] as string[],
        [attr1]: [] as string[],
    };

    if (values) {
        values.forEach((item: String) => {
            prefixList.forEach(([attr, prefix]) => {
                if (item.indexOf(prefix) === 0) {
                    map[attr].push(item.replace(prefix, ''));
                }
            });
        });
    }
    return {
        [attr0]: map[attr0].join(),
        [attr1]: map[attr1].join(),
    };
};

const SupervisionClassificationModal = React.memo((props: any) => {
    const { visible, onCancel, currentClassificationId, onSuccess } = props;
    const locales = Locales['current'];
    const [enableSupervisionRating, setEnableSupervisionRating] = useState();
    const [ratingList, setRatingList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState<InitValuesType>(DEFAULT_VALUE);
    const [organization, setOrganization] = useState<any[]>([]);
    const [smartTagData, setSmartTagData] = useState<any[]>([]);
    const [currentClassificationRules, setCurrentClassificationRules] = useState<any[]>([]);
    const [formInstance] = FormBuilder.useForm();
    const ChannelTypeOptions = [
        {
            label: /* 电话通话 */ locales.components.gong.supervisionClassificationModal.index
                .telephoneCall,
            value: 1,
        },
        {
            label: /* 企微通话 */ locales.components.gong.supervisionClassificationModal.index
                .enterpriseWeChatCall,
            value: 14,
        },
        {
            label: /* 企微会话 */ locales.components.gong.supervisionClassificationModal.index
                .enterpriseMicroConversation,
            value: 13,
        },
    ];
    const formConfig: FormProps = {
        onFinish: (values) => {
            const { target, cusEffectTime, smartTag, channelType, levelData, ...others } = values;
            let params: any = {
                id: currentClassificationId,
                channelType: channelType.join(),
                ...formatSmartCategoryValue(
                    smartTag,
                    ['smartCategory', CategoryPrefix],
                    ['smartTags', SmartTagPrefix]
                ),
                ...formatSmartCategoryValue(
                    target,
                    ['userId', UserPrefix],
                    ['organizationId', OrganizationPrefix]
                ),
                ...levelData,
                ...others,
            };
            if (Array.isArray(cusEffectTime)) {
                params.effectStartTime = cusEffectTime[0];
                params.effectEndTime = cusEffectTime[1];
            }
            postSupervisionRuleConfigs(params, {
                successMsg:
                    /* 修改成功 */ locales.components.gong.supervisionClassificationModal.index
                        .modifiedSuccessfully,
            }).then(() => {
                setInitialValues(DEFAULT_VALUE);
                onSuccess?.();
                // onCancel?.();
            });
        },
        customComponents: { EffectiveTime, Rating, Rule },
        initialValues,
        fields: [
            {
                label: /* 分类名称 */ locales.components.gong.supervisionClassificationModal.index
                    .classificationName,
                name: 'categoryName',
                type: 'Input',
                props: {
                    placeholder: locales.labels.pleaseEnter,
                },
            },
            {
                label: /* 监督对象 */ locales.components.gong.supervisionClassificationModal.index
                    .supervisionObject,
                name: 'target',
                type: 'TreeSelect',
                props: {
                    treeData: organization,
                    treeCheckable: true,
                    showCheckedStrategy: TreeSelect.SHOW_PARENT,
                    treeDefaultExpandAll: true,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: /* 会话场景 */ locales.components.gong.supervisionClassificationModal.index
                    .conversationScenario,
                name: 'smartTag',
                type: 'TreeSelect',
                props: {
                    treeData: smartTagData,
                    treeCheckable: true,
                    treeNodeFilterProp: 'name',
                    showCheckedStrategy: TreeSelect.SHOW_PARENT,
                    treeDefaultExpandAll: true,
                },
                rules: [
                    {
                        required: true,
                    },
                ],
            },
            {
                label: /* 监督会话渠道 */ locales.components.gong.supervisionClassificationModal
                    .index.monitorConversationChannels,
                name: 'channelType',
                type: 'Select',
                props: {
                    mode: 'multiple',
                    options: ChannelTypeOptions,
                    placeholder: locales.labels.pleaseSelect,
                },
            },
            {
                label: /* 生效时间 */ locales.components.gong.supervisionClassificationModal.index
                    .entryintoforceTime,
                name: 'effectRule',
                type: 'EffectiveTime',
            },
            {
                label: /* 监督项评级 */ locales.components.gong.supervisionClassificationModal.index
                    .supervisionItemRating,
                name: 'levelData',
                type: 'Rating',
                props: {
                    onChange: (value) => {
                        setEnableSupervisionRating(value.enableSupervisionRating);
                        setRatingList(value?.levelList);
                    },
                },
                rules: [
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            const supervisionRuleModelList = getFieldValue(
                                'supervisionRuleModelList'
                            );
                            const levelList = value.levelList;
                            const pass = supervisionRuleModelList?.every((r) => {
                                return levelList.some((l) => l.itemValue === r.ruleName);
                            });
                            if (!supervisionRuleModelList || pass) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    /* 请检查监督项评级是否正确 */ locales.components.gong.supervisionClassificationModal.index.pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect
                                )
                            );
                        },
                    }),
                ],
            },
            {
                label: /* 评级规则 */ locales.components.gong.supervisionClassificationModal.index
                    .ratingRules,
                name: 'supervisionRuleModelList',
                type: 'Rule',
                props: {
                    ratingList,
                    dataSource: currentClassificationRules,
                },
            },
        ].filter((item) => {
            if (!enableSupervisionRating) {
                if (item.name === 'supervisionRuleModelList') {
                    return false;
                }
            }
            return true;
        }),
        footerRender: false,
        labelCol: {
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            sm: {
                span: 18,
            },
        },
    };

    useEffect(() => {
        if (!currentClassificationId) return;
        setLoading(true);

        Promise.all([
            getSupervisionRuleConfigsById({
                segments: {
                    id: currentClassificationId,
                },
            }),
            getSupervisionRuleDetails({
                params: {
                    categoryId: currentClassificationId,
                },
            }),
            getSmartTagsCategoryWithTagsByFuncType({
                segments: {
                    funcType: 'REVIEW_CALL',
                },
            }),
        ])
            .then(([requestInitialValues, requestClassificationRules, requestSmartTag]) => {
                let res: any = requestInitialValues.data ?? {};
                // res.levelList = Array.isArray(res.levelList) ? res.levelList.map((i) => i.itemValue) : undefined;
                const formatId = (
                    attr: string,
                    prefix: string,
                    list: any[],
                    callback: Function = (id: string) => prefix + id.trim()
                ) => {
                    const value = res[attr];
                    if (value) {
                        Array.prototype.push.apply(list, res[attr].split(',').map(callback));
                    }
                    return list;
                };

                Object.keys(DEFAULT_VALUE).forEach((k) => {
                    if (!res[k]) {
                        res[k] = DEFAULT_VALUE[k];
                    }
                });
                // 初始化监督对象
                res.target = [];
                formatId('organizationId', OrganizationPrefix, res.target);
                formatId('userId', UserPrefix, res.target);
                //初始化会话场景
                res.smartTag = [];
                if (!(res.smartTags || res.smartCategory)) {
                    res.smartCategory = (requestSmartTag?.data ?? []).map((item) => item.id).join();
                }
                formatId('smartTags', SmartTagPrefix, res.smartTag);
                formatId('smartCategory', CategoryPrefix, res.smartTag);

                //初始化会话渠道
                res.channelType = formatId('channelType', '', [], (id) => +id);

                if (res.effectRule === 3) {
                    res.cusEffectTime = [res.effectStartTime, res.effectEndTime];
                }
                if (!res.supervisionRuleModelList) {
                    const allIds = requestClassificationRules.data?.map((i) => i.id) ?? [];
                    res.supervisionRuleModelList = [
                        {
                            hitFlag: 1,
                            ruleIds: allIds,
                            ruleName:
                                /* 合格 */ locales.components.gong.supervisionClassificationModal
                                    .index.qualified,
                        },
                        {
                            hitFlag: 0,
                            ruleIds: allIds,
                            ruleName:
                                /* 不合格 */ locales.components.gong.supervisionClassificationModal
                                    .index.unqualified,
                        },
                    ];
                } else {
                    const levelList = res.levelList;
                    res.supervisionRuleModelList = res.supervisionRuleModelList.map((i) => {
                        const { levelId, ...other } = i;
                        return {
                            ...other,
                            ruleName: levelList.find((i) => i.id === levelId)?.itemValue ?? '',
                        };
                    });
                }

                if (typeof res.enableSupervisionRating === 'number') {
                    res.levelData.enableSupervisionRating = res.enableSupervisionRating;
                }

                if (Array.isArray(res.levelList)) {
                    res.levelData.levelList = res.levelList;
                }

                setInitialValues(res as InitValuesType);
                setCurrentClassificationRules(requestClassificationRules.data ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentClassificationId]);

    useEffect(() => {
        formInstance.resetFields();
    }, [initialValues]);

    useEffect(() => {
        getReviewCallAnalysisOrganizations().then((resp) => {
            const data = TreeMenu.formatTreeDataFromArrayToTree(
                resp.data?.organizations
                    ?.map((i) => ({ ...i, title: i.name, key: `${OrganizationPrefix}${i.id!}` }))
                    ?.concat(
                        resp.data?.users?.map((i) => ({
                            ...i,
                            parentId: i.organizationId,
                            title: i.realname,
                            key: `${UserPrefix}${i.id!}`,
                        })) ?? []
                    ),
                0,
                'parentId'
            );
            setOrganization(data);
        });
        getSmartTagsCategoryWithTagsByFuncType({
            segments: {
                funcType: 'REVIEW_CALL',
            },
        }).then((resp) => {
            setSmartTagData(
                (resp.data ?? []).map((i) => ({
                    ...i,
                    title: i.categoryName,
                    key: `${CategoryPrefix}${i.id}`,
                    children: Array.isArray(i.smartTagList)
                        ? i.smartTagList.map((p) => ({
                              ...p,
                              key: `${SmartTagPrefix}${p.id}`,
                              title: p.tagName,
                          }))
                        : undefined,
                }))
            );
        });
    }, []);
    return (
        <Modal
            title={
                /* 监督项评级规则设置 */ locales.components.gong.supervisionClassificationModal
                    .index.settingOfRatingRulesForSupervisionItems
            }
            visible={visible}
            onCancel={onCancel}
            onOk={formInstance.submit}
            width={850}
            wrapClassName="supervision-detail-modal-wrap"
        >
            <Spin spinning={loading}>
                <FormBuilder {...formConfig} form={formInstance} />
            </Spin>
        </Modal>
    );
});

class Component extends React.Component {
    render() {
        return <SupervisionClassificationModal {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
