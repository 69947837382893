// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetSeekHelpsStatusQuery, BaseResponseListSeekHelpFoundResponse } from '../../types';

/**
 * findByStatus
 *
 * @export
 * @tags seek-help-controller
 * @link [GET] /seekHelps/status
 */
export function getSeekHelpsStatus(
    options: ApiOptions<never, GetSeekHelpsStatusQuery> & { params: GetSeekHelpsStatusQuery }
): Promise<BaseResponseListSeekHelpFoundResponse> {
    return request<never, never, GetSeekHelpsStatusQuery>('/seekHelps/status', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSeekHelpsStatus',
    });
}

export const meta = [{ tags: ['seek-help-controller'], path: '/seekHelps/status', method: 'get' }];
