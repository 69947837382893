// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { PostAsrFilterConfigSaveListBody, BaseResponseVoid } from '../../../types';

/**
 * saveList
 *
 * @export
 * @tags asr-filter-config-controller
 * @link [POST] /asr-filter-config/save/list
 */
export function postAsrFilterConfigSaveList(
    data: PostAsrFilterConfigSaveListBody
): Promise<BaseResponseVoid>;
export function postAsrFilterConfigSaveList(
    data: PostAsrFilterConfigSaveListBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postAsrFilterConfigSaveList(
    data: PostAsrFilterConfigSaveListBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostAsrFilterConfigSaveListBody, never, never>('/asr-filter-config/save/list', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postAsrFilterConfigSaveList',
    });
}

export const meta = [
    {
        tags: ['asr-filter-config-controller'],
        path: '/asr-filter-config/save/list',
        method: 'post',
    },
];
