import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import { nodeHeight, nodeWidth } from './const/node';
import { useAppState } from './hooks/useFlowState';

// import { NodeResizer } from '@reactflow/node-resizer';

// import '@reactflow/node-resizer/dist/style.css';
const judgeNodeBorder = '2px solid #1a6eff';
const dialogNodeBorder = '2px solid #f3aa18';
const dialogNodeBackground = 'linear-gradient(90deg, #f3aa18 0.01%, rgba(242, 170, 24, 0.45) 100%)';
const judgeNodeBackground = 'linear-gradient(90deg, #1a6eff 0%, rgba(26, 110, 255, 0.45) 100%)';

const NodeCompWrap = styled.div<any>`
    /* background: white; */
    /* border: 2px solid #000; */
    border-radius: 3px;
    > .react-flow__handle {
        opacity: 0;
        width: 1px !important;
        height: 1px !important;
        min-width: 1px !important;
        min-height: 1px !important;
    }
    > .react-flow__handle-top {
        top: 0;
    }
    > .react-flow__handle-bottom {
        bottom: 0;
    }

    &.BRANCH {
        outline: ${(props) =>
        props.selected
            ? props.preNodeType === 'DIALOG_NODE'
                ? dialogNodeBorder
                : judgeNodeBorder
            : undefined};
        background: ${(props) =>
        props.preNodeType === 'DIALOG_NODE' ? dialogNodeBackground : judgeNodeBackground};
    }
    &.JUDGE_NODE {
        outline: ${(props) => (props.selected ? judgeNodeBorder : undefined)};
        background: ${judgeNodeBackground};
    }
    &.START {
        outline: ${(props) => (props.selected ? '2px solid #1acc8c' : undefined)};
        background: linear-gradient(90deg, #1acc8c 0%, rgba(24, 204, 138, 0.45) 100%);
    }
    &.DIALOG_NODE {
        outline: ${(props) => (props.selected ? dialogNodeBorder : undefined)};
        background: ${dialogNodeBackground};
    }

    .edge-connector-wrap {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        overflow: hidden;
    }
    .edge-connector {
        position: absolute;
        top: 0;
        bottom: auto !important;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: none !important;
        border: 4px solid transparent;
        border-radius: 0 !important;
        transform: none;
        cursor: inherit !important;
        border-radius: 3px;
        &:hover {
            border-image: radial-gradient(
                    circle,
                    rgba(63, 251, 217, 0.6306897759103641) 0%,
                    rgba(70, 181, 252, 1) 100%
                )
                1 !important;
            cursor: crosshair !important;
        }
    }
    .edge-connector-inner {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        .edge-connector-inner-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-weight: bold;
            font-size: 12px;
            font-family: monospace;
            text-align: center;
        }
    }
    .node-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
    }
`;
export const ResizableNode = memo<any>((props) => {
    // const { connecting } = useFlowContext();
    const { id, data, selected } = props;
    const { connecting } = useAppState();

    const isTarget = connecting.sourceId && connecting.sourceId !== id;
    // const isJudgeNode = 'JUDGE_NODE' === data.type;
    // const isBranch = 'BRANCH' === data.type;
    // const isDialogNode = 'DIALOG_NODE' === data.type;
    // const isTarget = false;

    const getHandleType = (isTarget) => {
        return isTarget ? 'target' : 'source';
    };
    return (
        // <>
        //     {data.type !== 'START' && <Handle type="target" position={Position.Top} />}
        //     <NodeWrap selected={selected} className={data.type}>
        //         <div className="node-title" style={{ padding: 10 }}>
        //             {data.name}
        //         </div>
        //     </NodeWrap>
        //     <Handle type="source" position={Position.Bottom} />
        // </>
        <NodeCompWrap
            style={{ width: nodeWidth, height: nodeHeight }}
            selected={selected}
            className={data.type}
            preNodeType={data.preNodeType}
        >
            {Array.isArray(data.handle) &&
                data.handle.map((h) => {
                    const isHandleTarget = h.target === id;
                    return (
                        <Handle
                            type={getHandleType(isHandleTarget)}
                            position={isHandleTarget ? Position.Top : Position.Bottom}
                        />
                    );
                })}
            <Handle type="target" position={Position.Top} />
            <div className="edge-connector-wrap">
                <Handle
                    type={getHandleType(isTarget)}
                    id="connector-handle"
                    position={Position.Bottom}
                    className="edge-connector"
                    style={{
                        zIndex: isTarget ? 3 : 1,
                    }}
                />
                <div className="drag-me edge-connector-inner">
                    <div className="edge-connector-inner-content">
                        <div className="node-title">{data.name}</div>
                    </div>
                </div>
            </div>
            <Handle type="source" position={Position.Bottom} />
        </NodeCompWrap>
    );
});
