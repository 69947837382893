import React from 'react';
import Udesk from 'Udesk';

class SmartWordsLibraryComponent extends React.Component {
    isRedirect(currentRoute, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentRoute.name,
            pathParams: this.props.match.params
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }

    //#region Life Cycle
    componentDidMount() {

    }
    componentWillUnmount() {

    }

    //#endregion
}

export default SmartWordsLibraryComponent;