import { createAction, createReducer, combineReducers, bindActionCreators } from "@reduxjs/toolkit";
import { sagaMiddleware } from './store';
import * as sagaEffects from 'redux-saga/effects';
import { put, call } from 'redux-saga/effects';
import { WrongTypeError } from "udesk-web-toolkits/src/udesk/error";
import { STATIC_REDUX_KEY_SYMBOL, INSTANCE_REDUX_KEY_SYMBOL } from "./symbols";
import { store } from "./store";

let StoreReducers = {};
export const SYSTEM_REDUCER_SAVE_STATES = "[saveStates]";
export const SAVED_STATES_REDUX_STORE_FIELD_NAME = "[savedStates]";
/** 声明一些系统级别reducers，这些与常规reducer一样，可以被业务组件调用 */
const SYSTEM_REDUCERS = {};
/** 声明一些内部系统级别reducers，这些仅系统内部可见，业务组件不可访问 */
const INTERNAL_SYSTEM_REDUCERS = {
    [SYSTEM_REDUCER_SAVE_STATES]: (state, action, meta) => ({
        ...state,
        [SAVED_STATES_REDUX_STORE_FIELD_NAME]: action.payload
    }),
};
if (process.env.NODE_ENV === 'development') {
    window.StoreReducers = StoreReducers;
}
/**
 * 注册组件全局级别的reducers，redux状态与本组件的所有实例共享。
 * 并动态生成组件的actions，这样其他组件可以调用actions，来修改本组件所有实例的redux状态。
 *
 * @param {*} componentClass 要注册的组件类，reducers放在reduxReducers静态属性下
 */
export function registerGlobalReducers(componentClass) {
    if (!componentClass.reduxReducers) {
        return;
    }
    // I want to check the reduxKey no matter the component has reduxActions or not. Treat it as a global rule.
    validateComponentClass(componentClass);
    let globalReduxKey = componentClass.generateReduxKey();
    if (isRegistered(globalReduxKey)) {
        return;
    }

    let displayName = `component ${componentClass.displayName || componentClass.name}`;
    let composedReducer = generateReducers({
        reduxKeyPrefix: globalReduxKey,
        reduxReducers: componentClass.reduxReducers,
        reduxActions: componentClass.reduxActions,
        sagaConfig: componentClass.saga,
        defaultState: componentClass.defaultState,
        displayName: displayName,
        isStaticRegister: true
    });
    StoreReducers[globalReduxKey] = composedReducer;
    store.replaceReducer(combineReducers(StoreReducers));
    watchSaga({
        sagaConfig: componentClass.saga,
        reduxKeyPrefix: globalReduxKey,
        dynamicActionName: true,
        displayName: displayName
    });
}

/**
 * 注册组件实例级别的reducers，redux状态都存储在独立的容器内，与其他组件隔离的。
 * 并动态生成组件的actions，这样其他组件可以调用actions，来修改本组件的redux状态。
 *
 * 注意：组件的状态管理有两种存储模式，一种是全局共享的，即本组件的所有实例共享一份reducers、actions及state。
 * 另外一种是实例级别的，即组件实例独享自己的reducers、actions、state，与其他实例是隔离的。
 * 默认情况下，组件的状态是全局共享的，如果要切换为独享模式，需要在渲染组件实例时传入`reduxKey` props，
 * 这样状态便存储在reduxKey独立的命名空间下了。
 *
 * @param {*} componentClass 要注册的组件类，reducers放在reduxReducers静态属性下
 * @param {string} fullReduxKey 当前组件实例在store中存储的key
 */
export function registerInstanceReducers(componentClass, fullReduxKey) {
    if (!componentClass.reduxReducers) {
        return;
    }
    validateComponentClass(componentClass);

    // If the instance redux key already exists, it should be the static storage key and should not create a reducer again.
    function replaceReducer() {
        StoreReducers[instanceReduxKey].isReplaced = true;
        store.replaceReducer(combineReducers(StoreReducers));
    }
    let instanceReduxKey = fullReduxKey;
    if (isRegistered(instanceReduxKey)) {
        let composedReducer = StoreReducers[instanceReduxKey];
        if (!composedReducer.isReplaced) {
            replaceReducer();
            return;
        }
    }

    let displayName = `component ${componentClass.displayName || componentClass.name}`;
    let composedReducer = generateReducers({
        reduxKeyPrefix: instanceReduxKey,
        reduxReducers: componentClass.reduxReducers,
        reduxActions: componentClass.reduxActions,
        sagaConfig: componentClass.saga,
        defaultState: componentClass.defaultState,
        displayName: displayName,
        isStaticRegister: false
    });
    StoreReducers[instanceReduxKey] = composedReducer;
    replaceReducer();
}

/**
 * 直接注册redux，自己手动指定所有参数，拥有最大的灵活性
 *
 * @param {*} uniqueReduxKey Redux store中的唯一主键，用来存储状态，必须全局唯一
 * @param {*} reduxReducers 要注册的reducers
 * @param {*} reduxActions 自定义actions
 * @param {*} sagaConfig saga监听配置对象
 * @param {*} defaultState redux store中的默认状态对象
 * @param {*} displayName 类的显示名称
 */
export function register({ uniqueReduxKey, reduxReducers, reduxActions, sagaConfig, defaultState, displayName }) {
    if (!uniqueReduxKey) {
        throw new Error("uniqueReduxKey can't be null or empty.");
    }
    if (isRegistered(uniqueReduxKey)) {
        throw new Error(`The redux reducers of ${displayName} was already registered or duplicated with another one.`);
    }

    // 检查是否存在重复的action，仅对没有reduxKey前缀的宿主检查。
    // 因为对于有前缀的类来说，action都会加上唯一的前缀，即便是两个类使用相同的action，但加上前缀后是不会重复的。
    if (!uniqueReduxKey && process.env.NODE_ENV === 'development') {
        for (let actionType of Object.keys(reduxReducers)) {
            for (let [key, reducer] of Object.entries(StoreReducers)) {
                if (!reducer.hasReduxKeyPrefix) {
                    let otherActionTypes = Object.keys(reducer.boundActions);
                    if (otherActionTypes.includes(actionType)) {
                        throw new Error(`Redux action name collision was detected!
The source object is ${displayName}, the source action is reduxReducers.${actionType}.
It was conflicted with ${reducer.displayName || key}.`);
                    }
                }
            }
        }
    }

    let composedReducer = generateReducers({
        reduxKeyPrefix: "",
        reduxReducers: reduxReducers,
        reduxActions: reduxActions,
        sagaConfig: sagaConfig,
        defaultState: defaultState,
        displayName: displayName,
        isStaticRegister: true
    });
    StoreReducers[uniqueReduxKey] = composedReducer;
    store.replaceReducer(combineReducers(StoreReducers));
    watchSaga({
        sagaConfig: sagaConfig,
        reduxKeyPrefix: uniqueReduxKey,
        dynamicActionName: false,
        displayName: displayName
    });
}

/**
 * 销毁组件在store中存储的state对象。
 *
 * @param {React.Component} component 要销毁reducer的组件实例，会自动从组件实例中提取用来存储state的`fullReduxKey`
 * 注意：如果组件实例传入了`reduxKey` props的话，`fullReduxKey`是实例级别，即有区别于其他组件实例的；
 * 否则将是与本组件的所有实例所全局共享的，移除的话，会影响所有实例。
 */
export function destroyReduxState(component) {
    validateComponentClass(component.constructor);
    const fullReduxKey = component[INSTANCE_REDUX_KEY_SYMBOL];
    let reducer = StoreReducers[fullReduxKey];
    if (reducer) {
        delete StoreReducers[fullReduxKey];
        store.replaceReducer(combineReducers(StoreReducers));
    }
}

/**
 * 获取组件自身以及依赖的组件的state，并返回到props中。可以认为是对react-redux的`mapStateToProps`的功能增强。
 *
 * @export
 * @param {*} componentClass 当前执行组件的类
 * @param {string} fullReduxKey 当前组件实例在store中存储的key
 * @param {*} storeState redux store的state对象
 * @param {*} ownProps 组件实例自身的props
 * @returns 所有state合并后的对象
 */
export function mapStateToProps(componentClass, fullReduxKey, storeState, ownProps) {
    let ownState = null;
    if (isRegistered(fullReduxKey)) {
        ownState = getState(fullReduxKey) || {};
    }
    else {
        /* 注意，这是组件第一次渲染时的补偿操作。因为组件在第一次渲染时，会先于constructor调用mapStateToProps！
        而此时reducer并没有注册到store中，从storeState中也获取不到组件的defaultState。所以我们会手动补偿，
        手动获取组件的defaultState返回，避免第一次渲染缺少redux state的情况。*/
        ownState = componentClass.defaultState || {};
    }

    // 解析reduxWatcher。可以watch组件类的state，也可以watch一个key的state，watcher也可以添加多个。
    let watcherStates = [];
    if (componentClass.reduxWatcher) {
        let watcherItems = parseWatchers(componentClass.reduxWatcher);
        for (const item of watcherItems) {
            let state = storeState[item.fullReduxKey];
            if (state) {
                watcherStates.push({
                    watcher: item.watcher,
                    fullReduxKey: item.fullReduxKey,
                    state,
                });
            }
        }
    }

    // 如果声明了自定义mergeState方法，则调用自定义方法，把所有的监听项以及对应state的对象数组全部传递过去，
    // 由此方法返回最终合并后的state
    let mergedState = {};
    if (typeof componentClass.mergeState === "function") {
        mergedState = componentClass.mergeState(watcherStates, ownState) || {};
    }
    else {
        // 否则默认使用浅拷贝合并所有state，再传递给组件props。
        for (let item of watcherStates) {
            Object.assign(mergedState, item.state);
        }
        Object.assign(mergedState, ownState);
    }

    // 如果声明了自己的mapStateToProps，则执行自定义的mapStateToProps方法，这样业务端可以自己控制map哪些state到自己的props中。
    if (typeof componentClass.mapStateToProps === "function") {
        return componentClass.mapStateToProps({
            state: mergedState,
            ownProps
        });
    }
    else {
        // 如果没有声明自定义的mapStateToProps方法，则使用默认规则，把所有state合并到一起，全部绑定到props上。
        return mergedState;
    }
}

/**
 * 获取组件自身以及依赖的组件的dispatch actions，并返回到props中。可以认为是对react-redux的`mapDispatchToProps`的功能增强。
 *
 * @export
 * @param {*} componentClass 当前执行组件的类
 * @param {string} fullReduxKey 当前组件实例在store中存储的key
 * @param {*} storeState redux store的state对象
 * @param {*} ownProps 组件实例自身的props
 * @returns 所有state合并后的对象
 */
export function mapDispatchToProps(componentClass, fullReduxKey, dispatch, ownProps) {
    let ownDispatch = null;
    if (isRegistered(fullReduxKey)) {
        let reducer = StoreReducers[fullReduxKey];
        if (reducer) {
            ownDispatch = { ...reducer.boundActions };
        }
    }
    else {
        /* 注意，这是组件第一次渲染时的补偿操作。因为组件在第一次渲染时，会先于constructor调用mapDispatchToProps！
        而此时reducer并没有注册到store中，从storeState中也获取不到组件的defaultState。所以我们会手动补偿，
        手动获取组件的defaultState返回，避免第一次渲染缺少redux state的情况。*/
        let composedReducer = generateReducers({
            reduxKeyPrefix: fullReduxKey,
            reduxReducers: componentClass.reduxReducers,
            reduxActions: componentClass.reduxActions,
            sagaConfig: componentClass.saga,
            defaultState: componentClass.defaultState,
            displayName: `component \`${componentClass.displayName || componentClass.name}\``,
            isStaticRegister: false
        });
        StoreReducers[fullReduxKey] = composedReducer;
        composedReducer.isReplaced = false;
        ownDispatch = composedReducer.boundActions;
    }

    // 解析reduxWatcher。可以watch组件类的state，也可以watch一个key的state，watcher也可以添加多个。
    let watcherDispatches = [];
    if (componentClass.reduxWatcher) {
        let watcherItems = parseWatchers(componentClass.reduxWatcher);
        for (const item of watcherItems) {
            let reducer = StoreReducers[item.fullReduxKey];
            if (reducer) {
                watcherDispatches.push({
                    watcher: item.watcher,
                    fullReduxKey: item.fullReduxKey,
                    dispatch: reducer.boundActions,
                });
            }
        }
    }


    // 如果声明了自定义mergeDispatch方法，则调用自定义方法，把所有的监听项以及对应dispatch的对象数组全部传递过去，
    // 由此方法返回最终合并后的dispatches
    let mergedDispatches = {};
    if (typeof componentClass.mergeDispatch === "function") {
        mergedDispatches = componentClass.mergeDispatch(watcherDispatches, ownDispatch) || {};
    }
    else {
        // 否则默认使用浅拷贝合并所有dispatches，再传递给组件props。
        for (let item of watcherDispatches) {
            Object.assign(mergedDispatches, item.dispatch);
        }
        Object.assign(mergedDispatches, ownDispatch);
    }

    if (typeof componentClass.mapDispatchToProps === "function") {
        return componentClass.mapDispatchToProps({
            actions: mergedDispatches,
            dispatch,
            ownProps
        });
    }
    else {
        return mergedDispatches;
    }
}

/**
 * 合并state、dispatch和自己的props，默认实现是三者合并到一起，传递给props，但可以自己控制如何进行自定义的合并。
 *
 * @param {*} stateProps 从redux中获取的state
 * @param {*} dispatchProps 定义的actions方法
 * @param {*} ownProps 自身的props
 * @returns 合并后的对象，会展开后传递到组件的props中
 */
export function mergeProps(componentClass, fullReduxKey, stateProps, dispatchProps, ownProps) {
    if (typeof componentClass.mergeProps === "function") {
        return componentClass.mergeProps({ stateProps, dispatchProps, ownProps });
    }
    else {
        return { ...ownProps, ...stateProps, ...dispatchProps };
    }
}

/**
 * 获取store中指定key下的state对象。仅限内部调用。
 * @param {string} fullReduxKey store中的key
 * @returns state对象
 */
export function getState(fullReduxKey) {
    return store.getState()[fullReduxKey];
}

function watchSaga({ sagaConfig, reduxKeyPrefix, dynamicActionName, displayName }) {
    if (sagaConfig) {
        let reducer = StoreReducers[reduxKeyPrefix];
        // 使用saga有两种形式：
        // 第一种是专业模式，使用watchSaga特殊方法，自己初始化saga监听。
        //   这种模式的好处是，灵活性高，自己掌控全局，自己完全控制saga来做任何自己想做的事情，但底层框架不提供任何语法糖，也是开发起来最麻烦的。
        // 第二种是简易模式，声明式的使用方式，底层库提供很多语法糖，最大程度简化saga的使用门槛。
        //   这种模式的好处是，开发简单，底层提供很多语法糖，底层自动为action添加前缀，自动实现独立命名空间下的state，不会与其他容器的state串，
        //   但仅提供最常见的有限的saga功能支持，如果要进行复杂流程控制的化，需要使用watchSaga模式。
        // 专业模式
        if (typeof sagaConfig.watchSaga === "function") {
            let sagaContext = {
                actions: null
            };
            if (reducer) {
                sagaContext.actions = reducer.boundActions;
            }
            sagaMiddleware.run(sagaConfig.watchSaga.bind(sagaConfig), sagaContext);
        }
        else {
            // 简单模式
            let sagaContext = {
                context: sagaConfig,
                actions: null
            };
            if (reducer) {
                sagaContext.actions = reducer.boundActions;
            }

            // saga监听项的处理函数，由于eslint不允许for循环内使用匿名方法，所以才提到外面来了。
            let getSagaHandler = function ({ saga, fullReduxKey, successAction, errorAction, trigger }) {
                return function* (action) {
                    try {
                        // 自动发送call effect，调用一个方法返回一个异步promise
                        const data = yield call(function () {
                            let state = getState(fullReduxKey);
                            return saga.apply(sagaConfig, [action, { state }]);
                        });
                        if (successAction) {
                            // 如果成功了，就调用put effect，dispatch successAction，触发successAction对应的reducer，更新state值。
                            yield put({
                                type: successAction,
                                payload: data,
                                error: false,
                                meta: {
                                    trigger
                                }
                            });
                        }
                    }
                    catch (error) {
                        if (errorAction) {
                            // 如果成功了，就调用put effect，dispatch errorAction，触发errorAction对应的reducer，更新state值。
                            yield put({
                                type: errorAction,
                                payload: error,
                                error: true,
                                meta: {
                                    trigger
                                }
                            });
                        }
                    }

                };
            };

            let effects = [];
            const sagaTypes = ["takeEvery", "takeLatest", "takeLeading"];
            for (const type of sagaTypes) {
                if (sagaConfig[type]) {
                    for (const [key, value] of Object.entries(sagaConfig[type])) {
                        // 调一下声明式的方法，返回值就是我们进行后续saga处理的相关参数。
                        // 我们仅封装一个Promise的场景，即自动调用返回值的saga方法，预期这个方法返回一个Promise，
                        // 然后在Promise成功时，调用successAction对应的reducer，如果失败，则调用errorAction对应的reducer。
                        const { saga, successAction, errorAction } = value.bind(sagaConfig)(sagaContext);
                        if (typeof saga !== "function") {
                            throw new Error(`The return value of saga.${key} which belongs to ${displayName} is not valid. The \`saga\` field must be function.`);
                        }
                        if (!successAction) {
                            throw new Error(`The return value of saga.${key} which belongs to ${displayName} is not valid. The \`successAction\` must have a value.`);

                        }

                        //先把effect对象收集起来，最后在sagaMiddleware.run方法中批量一次执行
                        const actionType = (dynamicActionName ? getFullActionType(reduxKeyPrefix, key) : key);
                        effects.push(sagaEffects[type](actionType, getSagaHandler({
                            saga,
                            fullReduxKey: reduxKeyPrefix,
                            successAction: (dynamicActionName ? getFullActionType(reduxKeyPrefix, successAction) : successAction),
                            errorAction: (dynamicActionName ? getFullActionType(reduxKeyPrefix, errorAction) : errorAction),
                            trigger: key
                        })));
                    }
                }
            }

            if (effects.length > 0) {
                // 注册一个方法，运行这些effect
                sagaMiddleware.run(function* () {
                    for (const effect of effects) {
                        yield effect;
                    }
                });
            }
        }
    }
}

function getSagaActions(sagaConfig, fullReduxKey) {
    let sagaActions = {};
    if (sagaConfig && typeof sagaConfig.watchSaga !== "function") {
        // 语法糖。让saga自动监听这些对象里的actions
        const sagaTypes = ["takeEvery", "takeLatest", "takeLeading"];
        for (let sagaType of sagaTypes) {
            let actionMap = sagaConfig[sagaType];
            if (actionMap) {
                for (let key of Object.keys(actionMap)) {
                    let actionType = getFullActionType(fullReduxKey, key);
                    let actionCreator = createAction(actionType, function (contextAsFakePayload, payload, meta) {
                        return {
                            payload,
                            meta
                        };
                    });
                    sagaActions[key] = actionCreator;
                }
            }
        }
    }
    return sagaActions;
}

/**
 * 获取某个组件的常规reducers，不包含系统级别的reducers，可以在其他组件中调用以修改该组件的全局状态。
 *
 * @param {*} componentClass 要获取的组件类，注意不是组件实例
 * @param {string} reduxKey 如果要获取实例级别的actions的话，需要指定某个组件实例的`reduxKey`属性值
 * @returns {object} 组件的redux actions。如果存在的话，返回actions对象，否则返回一个空对象
 */
export function getNormalReducers(componentClass, reduxKey) {
    validateComponentClass(componentClass);
    if (reduxKey && typeof reduxKey !== "string") {
        throw new WrongTypeError("reduxKey", "string");
    }
    const fullReduxKey = componentClass.generateReduxKey(reduxKey);
    let reducer = StoreReducers[fullReduxKey];
    if (reducer) {
        return reducer.boundActions;
    }
    else {
        return {};
    }
}

/**
 * 获取某个组件的系统级reducers，一般来说仅限系统内部调用。
 *
 * @param {*} componentClass 要获取的组件类，注意不是组件实例
 * @param {string} reduxKey 如果要获取实例级别的actions的话，需要指定某个组件实例的`reduxKey`属性值
 * @returns {object} 组件的redux actions。如果存在的话，返回actions对象，否则返回一个空对象
 */
export function getInternalSystemReducers(componentClass, reduxKey) {
    validateComponentClass(componentClass);
    if (reduxKey && typeof reduxKey !== "string") {
        throw new WrongTypeError("reduxKey", "string");
    }
    const fullReduxKey = componentClass.generateReduxKey(reduxKey);
    let reducer = StoreReducers[fullReduxKey];
    if (reducer) {
        return reducer.internalSystemActions;
    }
    else {
        return {};
    }
}

function validateComponentClass(componentClass) {
    let staticReduxKey = componentClass[STATIC_REDUX_KEY_SYMBOL];
    // 组件类的全局reduxKey必须不能为空。
    if (!staticReduxKey) {
        throw new Error(`The ReduxKey is NOT provided by component \`${componentClass.displayName || componentClass.name}\`. Do you forget [udeskify] the component?`);
    }
    // 组件类的全局reduxKey必须为字符串。
    if (typeof staticReduxKey !== "string") {
        throw new Error(`The ReduxKey of component \`${componentClass.displayName || componentClass.name}\` must be string.`);
    }
    // 组件类必须存在generateReduxKey方法。
    if (typeof componentClass.generateReduxKey !== "function") {
        throw new Error(`The generateReduxKey method of component \`${componentClass.displayName || componentClass.name}\` is missing. Do you forget [udeskify] the component?`);
    }
}

function generateReducers({ reduxKeyPrefix, reduxReducers, reduxActions, sagaConfig, defaultState, displayName, isStaticRegister }) {
    let reducers = reduxReducers;
    let actionReducersMap = {};
    let reducerActions = {};
    // 生成reduxReducers
    for (let [key, reducer] of Object.entries(reducers).concat(Object.entries(SYSTEM_REDUCERS).concat(Object.entries(INTERNAL_SYSTEM_REDUCERS)))) {
        let isFunction = (typeof reducer === "function");
        let isValidArray = (Array.isArray(reducer) && reducer.length <= 3 && reducer.every(f => typeof f === "function"));
        if (!isFunction && !isValidArray) {
            throw new Error(`The reduxReducers["${key}"] of ${displayName} must be function or an array that contains no more than 3 functions.`);
        }

        // Three standard fields (payload, meta and error) of an action object.
        // See the spec of flux actions, https://github.com/redux-utilities/flux-standard-action#actions
        let prepareAction = null;
        if (isValidArray) {
            let handlers = reducer;
            reducer = handlers[0];
            prepareAction = handlers[1];
        }

        // 如果正在生成实例级别reducer的话，自动跳过静态的key。静态key只能被注册为全局级别。
        let isStaticKey = key.startsWith("static\u0020");
        if (isStaticKey && !isStaticRegister) {
            continue;
        }

        // 为reducerMap的每个key自动创建actionCreator，action type会自动添加reduxKeyPrefix前缀，
        // 以保证多个组件类之间，即便是相同的action name也会生成不同的action，不会串
        let actionType = null;
        if (isStaticKey) {
            actionType = key.replace("static\u0020", "");
        }
        else {
            actionType = getFullActionType(reduxKeyPrefix, key);
        }
        let actionCreator = createAction(actionType, prepareAction);
        // 顺便把actionCreator存储在reducerActions上
        reducerActions[key] = actionCreator;
        actionReducersMap[actionType] = reducer;
    }
    let composedReducer = createReducer(defaultState || {}, actionReducersMap);
    composedReducer.hasReduxKeyPrefix = !!reduxKeyPrefix;
    composedReducer.displayName = displayName;

    // 生成reducerActions
    let extraActions = {};
    let externalActionContext = {
        actions: null
    };
    let internalSystemReducerNames = Object.keys(INTERNAL_SYSTEM_REDUCERS);
    let extraActionMap = {};
    // 添加saga actions，如果存在saga配置的话.
    if (isStaticRegister) {
        Object.assign(extraActionMap, getSagaActions(sagaConfig, reduxKeyPrefix));
    }
    // 添加自定义action，支持普通的actionCreator和thunk actions，这些自定义action也会被传递给组件的props，方便在组件内调用。
    if (reduxActions) {
        Object.assign(extraActionMap, reduxActions);
    }
    // 对自定义action进行适当的处理，以符合标准的actionCreator（通过createAction方法创建的）标准。
    for (let [key, actionCreator] of Object.entries(extraActionMap)) {
        if (typeof actionCreator === "function" && !internalSystemReducerNames.includes(key)) {
            //
            let boundActionCreator = actionCreator.bind(externalActionContext, externalActionContext);
            let fullType = getFullActionType(reduxKeyPrefix, key);
            // 增加type属性，重写toString方法以返回type。
            boundActionCreator.type = fullType;
            boundActionCreator.toString = (function (type) {
                return () => type;
            })(fullType);
            extraActions[key] = boundActionCreator;
        }
    }

    // 批量绑定actionCreators，每个方法都自动跟store的dispatch方法绑定，实现调用方法自动触发dispatch的效果。
    let originalActions = { ...extraActions, ...reducerActions };
    let boundActions = bindActionCreators(originalActions, store.dispatch);
    for (let [key, value] of Object.entries(boundActions)) {
        let originalAction = originalActions[key];
        if (originalAction) {
            value.type = originalAction.type;
            value.match = originalAction.match;
            value.toString = originalAction.toString;
        }
    }

    // 把internalSystemActions挑拣出来
    composedReducer.internalSystemActions = Object.keys(boundActions)
        .filter(key => internalSystemReducerNames.includes(key))
        .reduce((obj, key) => {
            obj[key] = boundActions[key];
            return obj;
        }, {});
    // 除了internalSystemActions之外的方法，都放在boundActions中，这些actions就是被传给组件props的那些方法，
    // 另外这些actions还被传递给自定义thunk和saga的action方法，以方便在异步过程中调用其他actions。
    composedReducer.boundActions = Object.keys(boundActions)
        .filter(key => !internalSystemReducerNames.includes(key))
        .reduce((obj, key) => {
            obj[key] = boundActions[key];
            return obj;
        }, {});

    externalActionContext.actions = boundActions;
    return composedReducer;
}

function getFullActionType(fullReduxKey, actionType) {
    if (fullReduxKey) {
        return `${fullReduxKey}\u0020${actionType}`;
    }
    else {
        return actionType;
    }
}

/**
 * 检查指定的`fullReduxKey`是否已经注册过了。
 *
 * @param {string} fullReduxKey 组件的完整redux key
 * @returns 如果已经注册过了，返回`true`，否则`false`
 */
function isRegistered(fullReduxKey) {
    return !!StoreReducers[fullReduxKey];
}

function parseWatchers(reduxWatcherConfig) {
    let watchedReduxKeys = [];
    // 数组
    if (Array.isArray(reduxWatcherConfig)) {
        for (let watcher of reduxWatcherConfig) {
            const watcherItem = parseOneWatcher(watcher);
            if (watcherItem) {
                watchedReduxKeys.push(watcherItem);
            }
        }
    }
    else {
        // 单个
        const watcherItem = parseOneWatcher(reduxWatcherConfig);
        if (watcherItem) {
            watchedReduxKeys.push(watcherItem);
        }
    }
    return watchedReduxKeys;
}

function parseOneWatcher(watcher) {
    if (watcher) {
        // 直接监听一个fullReduxKey，一般用在model类的redux场景下。
        if (typeof watcher === "string") {
            return {
                watcher,
                fullReduxKey: watcher
            };
        }
        // 使用对象形式。可以制定uniqueReduxKey和component+reduxKey两种形式，但前者的优先级更高。
        // 这种情况下，与直接使用字符串形式watch的效果是一样的。
        else if (watcher.uniqueReduxKey) {
            return {
                watcher,
                fullReduxKey: watcher.uniqueReduxKey
            };
        }
        // 使用对象形式。component字段必须是一个组件类，而且是被udeskify后的组件，reduxKey字段是可选的。
        // 可以监听组件的全局级别以及实例级别。
        // 如果reduxKey不传的话，则监听组件的全局级别，否则监听组件类的实例级别，reduxKey则是某个组件实例的reduxKey属性值。
        else if (watcher.component) {
            validateReducerWatcherComponent(watcher.component, watcher.reduxKey);
            return {
                watcher,
                fullReduxKey: watcher.component.generateReduxKey(watcher.reduxKey)
            };
        }
        // 直接使用组件类监听。
        else if (typeof watcher.generateReduxKey === "function") {
            return {
                watcher,
                fullReduxKey: watcher.generateReduxKey()
            };
        }
    }
    else {
        return null;
    }
}

function validateReducerWatcherComponent(componentClass, reduxKey) {
    if (typeof componentClass.generateReduxKey !== "function") {
        throw new Error(`The given redux watcher is invalid! Static method 'generateReduxKey' was not found from the component class.`);
    }
    if (reduxKey && typeof reduxKey !== "string") {
        throw new Error(`The \`reduxKey\` of given redux watcher is wrong! It must be a string.`);
    }
}