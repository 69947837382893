import React from 'react';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Tabs } from 'udesk-ui';
// import './style.scss';
// import Locales from 'UdeskLocales';

// const { confirm } = Modal;

const Template = React.memo((props: any) => {
    const { savedTemplates, activeTemplatId } = props;
    const onChange = (activeKey) => {
        props.onChange?.(Number(activeKey));
    };
    // const onEdit = (targetKey, action) => {
    //     if (action !== 'remove') return;
    //     // api/businessAnalysisConditionConfigs/{id}
    //     confirm({
    //         icon: <Icon type='ExclamationCircleOutlined' antdIcon={true} />,
    //         content: /* 数据删除后将无法恢复，是否确认删除？ */Locales['current'].pages.intelligentAnalysis.analysisReport.components.list.index.theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt,
    //         onOk() {
    //             onDelete?.(targetKey);
    //         },
    //     });
    // };

    return (
        <Tabs type='editable-card' hideAdd={true} activeKey={`${activeTemplatId}`} onChange={onChange}>
            {savedTemplates.map((tab) => {
                return <Tabs.TabPane tab={tab.name} key={tab.id} closable={false}></Tabs.TabPane>;
            })}
        </Tabs>
    );
});

export default Template;
