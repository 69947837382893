export default {
    required: /\S+/,
    number: /^\d+$/,
    numberNullable: /^(\d+)?$/,
    floatNumber: /^\d+\.?\d*$/,
    floatNumberNullable: /^(\d+\.?\d*)?$/,
    floatNumberHasMinusNullable: /^(-?\d+\.?\d*)?$/,
    mobile: /^1[0-9]{10}$/,
    date: /^\d{4}-\d{1,2}-\d{1,2}$/, //验证YYYY-MM-DD格式的日期
    mobileNullable: /^(1[0-9]{10})?$/,
    telephone: /^(0\d{2,3})(\d{7,8})|400\d{7}|100\d{2}$/, //固话格式，－可加可不加
    mobileOrTelephone: /(^1[0-9]{10}$)|(^(0\d{2,3})(\d{7,8})|400\d{7}|100\d{2}$)/,
    telephoneNullable: /^((0\d{2,3})(\d{7,8})|400\d{7}|100\d{2})?$/,
    email: /^[\w][\w-]*(\.[\w-]+)*@([\w]([\w-]*[\w])?\.)+[\w]([\w]*[\w])?$/,
    emailNullable: /^(([a-zA-Z0-9-]+[_|.]?)*[a-zA-Z0-9-]+@([a-zA-Z0-9-]+[_|.]?)*[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4})+)?$/,
    currency: /^([1-9][\d]{0,7}|0)(\.[\d]{1,2})?$/,
    currencyNullable: /^(([1-9][\d]{0,7}|0)(\.[\d]{1,2})?)?$/,
    styleTag: /<style(([\s\S])*?)<\/style>/g,
    scriptTag: /<script(([\s\S])*?)<\/script>/g,
};
