// @ts-nocheck

import { request } from '../../../../../../../context';
import type { ApiOptions } from '../../../../../../../context';
import type {
    PostReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeIdParam,
    PostReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeIdBody,
    BaseResponseListstring,
} from '../../../../../../../types';

/**
 * ppt单节点上传ppt
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [POST] /review/intelligentPartner/node/pptConfig/upload/{flowId}/{nodeId}
 * @param flowId
 * @param nodeId
 */
export function postReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeId(
    data: PostReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeIdBody,
    options: ApiOptions<
        PostReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeIdParam,
        never
    > & { segments: PostReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeIdParam }
): Promise<BaseResponseListstring> {
    return request<
        PostReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeIdBody,
        PostReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeIdParam,
        never
    >('/review/intelligentPartner/node/pptConfig/upload/{flowId}/{nodeId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewIntelligentPartnerNodePptConfigUploadByFlowIdByNodeId',
    });
}

export const meta = [
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/upload/{flowId}/{nodeId}',
        method: 'post',
    },
];
