import { loginRedirect } from "src/util/loginRedirect";

function initAjaxPrefilter() {
    $.ajaxPrefilter('json', function (options, originalOptions, jsXHR) {
        // options.xhrFields = {
        //     withCredentials: true,
        // };
        jsXHR.fail(function (jsXhr, textStatus, errorThrown) {
            switch (jsXhr.status) {
                case 401:
                    loginRedirect();
                    break;
                default:
                    break;
            }
        });
    });
}

export default initAjaxPrefilter;
