import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import TasksCheckPointDetailComponent from './component-new';

export default class TasksCheckPointEditRoute extends TasksCheckPointDetailComponent {
    model() {
        // const { props } = this;
        // let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));

        // let modelPromise = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         '/inspectionPoints/' + params.id + '?taskId=' + params.taskId,
        //         props.sdkOptions
        //     );
        //     Udesk.ajax.get(url).then(
        //         resp => {
        //             resolve(resp.data);
        //         },
        //         reason => {
        //             Udesk.ui.notify.error(
        //                 reason.errorMsg ||
        //                 Udesk.utils.string.format(
        //                     UdeskLocales.current.business.info.loadError,
        //                     UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.name
        //                 )
        //             );
        //             reject(reason);
        //         }
        //     );
        // });
        const url = Udesk.business.apiPath.concatApiPath(`/inspectionPoints/module/${this.props.match.params.moduleVersionId}/${this.props.match.params.taskId}`, this.props.sdkOptions);
        let modelPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        Udesk.utils.string.format(
                            UdeskLocales.current.business.info.loadError,
                            UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.name
                        )
                    );
                    reject(reason);
                }
            );
        });
        // let categoriesPromise = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath('/interactiveCategories/initTree', props.sdkOptions);
        //     Udesk.ajax.get(url).then(
        //         resp => {
        //             resolve(getTreeData(resp.data));
        //         },
        //         reason => {
        //             reject(reason);
        //         }
        //     );
        // });

        // let foctorsPromise = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath('/interactiveDatas/multiElements', props.sdkOptions);
        //     Udesk.ajax.get(url).then(
        //         resp => {
        //             resolve(resp.data);
        //         },
        //         reason => {
        //             reject(reason);
        //         }
        //     );
        // });
        // let statusPromise = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         "/inspectionTasks/" + params.taskId,
        //         this.props.sdkOptions
        //     );
        //     Udesk.ajax.get(url)
        //         .then(
        //             resp => {
        //                 resolve(resp.data);
        //             },
        //             reason => {
        //                 reject(reason);
        //             }
        //         );
        // });
        // let conditionPromise = Promise.resolve({ applyRole: Udesk.enums.applyRoles.all.id });
        // let taskVersionPromise = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         `/inspectionTasks/${params.taskId}/version`,
        //         props.sdkOptions
        //     );
        //     Udesk.ajax.get(url)
        //         .then(
        //             resp => {
        //                 resolve(resp.data);
        //             },
        //             reason => {
        //                 Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
        //                 reject(reason);
        //             }
        //         );
        // });

        return {
            // taskId: params.taskId,
            // categoryId: params.categoryId,
            inspectionPoint: modelPromise,
            // categories: categoriesPromise,
            // foctorsData: foctorsPromise,
            //task: props.sdkOptions.props.task,
            // inspectionConditions: props.sdkOptions.props.inspectionConditions,
            // status: statusPromise,
            // basicInfoFields: props.sdkOptions.props.basicInfoFields,
            // condition: conditionPromise,
            // taskVersion: taskVersionPromise,

        };
    }
}

// function getTreeData(tree) {
//     return tree.map(item => {
//         return { key: item.id, value: item.id, title: item.name, children: getTreeData(item.children) };
//     });
// }