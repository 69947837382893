import Udesk from "Udesk";
import IndexManagementComponent from "./component";

class IndexManangementRoute extends IndexManagementComponent {
    asyncModel() {
        let { sdkOptions } = this.props;

        let params = {
            pageNum: this.privates.paging.pageNum,
            pageSize: this.privates.paging.pageSize,
            keyWord: this.privates.keyWord,
            dataSource: this.privates.dataSource,
        };
        let urlTree = Udesk.business.apiPath.concatApiPath(`agentGroups`, sdkOptions);
        let initTree = new Promise((resolve, reject) => {
            Udesk.ajax.get(urlTree).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        }).then((initTreeData) => {

            if (this.privates.initTreeValue) {
                params.groupId = this.privates.initTreeValue;
            } else {
                if (initTreeData.data && initTreeData.data.length > 0) {
                    params.groupId = initTreeData.data[0].id;
                    this.privates.initTreeValue = initTreeData.data[0].id;
                    this.actions.update();
                }
            }
            let url = Udesk.business.apiPath.concatApiPath(`agentPictureIndicators`, sdkOptions);
            return new Promise((resolve, reject) => {
                Udesk.ajax.get(url, params).then(
                    resp => {
                        resolve({ initData: initTreeData, listData: resp });
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        });

        return {
            initTree,
        };
    }
    parseAsyncModel(asyncModel) {
        return asyncModel;
    }
}
export default IndexManangementRoute;
