// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PutReviewIntelligentPartnerNodePromptConfigByIdParam,
    PutReviewIntelligentPartnerNodePromptConfigByIdBody,
    BaseResponseIntelligentPartnerDialogNodePromptConfigFoundResponse,
    DeleteReviewIntelligentPartnerNodePromptConfigByIdParam,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * update
 *
 * @export
 * @tags 智能陪练-AI对话提示语节点配置
 * @link [PUT] /review/intelligentPartner/node/promptConfig/{id}
 * @param id
 */
export function putReviewIntelligentPartnerNodePromptConfigById(
    data: PutReviewIntelligentPartnerNodePromptConfigByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerNodePromptConfigByIdParam, never> & {
        segments: PutReviewIntelligentPartnerNodePromptConfigByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodePromptConfigFoundResponse> {
    return request<
        PutReviewIntelligentPartnerNodePromptConfigByIdBody,
        PutReviewIntelligentPartnerNodePromptConfigByIdParam,
        never
    >('/review/intelligentPartner/node/promptConfig/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerNodePromptConfigById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 智能陪练-AI对话提示语节点配置
 * @link [DELETE] /review/intelligentPartner/node/promptConfig/{id}
 * @param id
 */
export function deleteReviewIntelligentPartnerNodePromptConfigById(
    options: ApiOptions<DeleteReviewIntelligentPartnerNodePromptConfigByIdParam, never> & {
        segments: DeleteReviewIntelligentPartnerNodePromptConfigByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReviewIntelligentPartnerNodePromptConfigByIdParam, never>(
        '/review/intelligentPartner/node/promptConfig/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerNodePromptConfigById',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-AI对话提示语节点配置'],
        path: '/review/intelligentPartner/node/promptConfig/{id}',
        method: 'put',
    },
    {
        tags: ['智能陪练-AI对话提示语节点配置'],
        path: '/review/intelligentPartner/node/promptConfig/{id}',
        method: 'delete',
    },
];
