import React from 'react';
import UdeskLocales from 'UdeskLocales';
import { List, Space, Button, Empty } from 'udesk-ui';
import UdeskPagination from 'Component/common/udesk-pagination';
import CallListItem from '../home-page/common/call-list-item';
// import { ArrowDownSOutlined, ArrowUpSOutlined } from '@udesk/icons';
import styled from 'styled-components';
import { ReInspect } from 'Component/pages/components/gong/ReInspect';
import { isOperator } from 'src/util/isOperator';
const locales = UdeskLocales['current'];
const TriangleDomBottom = styled.span`
    width: 0px;
    height: 0px;
    display: inline-block;
    border: 4px solid;
    // border-color: rgba(0, 0, 0, .35) transparent transparent;
    border-color: ${(props) =>
        !(props.color === 2)
            ? 'rgba(0, 0, 0, .35) transparent transparent'
            : 'rgba(26, 110, 255, 1) transparent transparent'};
    position: absolute;
    margin-top: 2px;
    top: 0;
    left: 0;
`;
const TriangleDomUp = styled.span`
    width: 0px;
    height: 0px;
    display: inline-block;
    border: 4px solid;
    border-color: ${(props) =>
        !(props.color === 1)
            ? 'transparent transparent rgba(0, 0, 0, .35)'
            : 'transparent transparent rgba(26, 110, 255, 1)'};
    // border-color: transparent transparent rgba(0, 0, 0, .35);
    position: absolute;
    bottom: 0;
    left: 0;
`;
function FilterItem(props) {
    // const [defaultTimes, setDefaultTimes] = useState(props.defaultTimes ?? 1);
    // const [defaultDurtion, setDefaultDurtion] = useState(props.defaultDurtion ?? 1);
    // const [searchCondition, setSearchCondition] = useState({});
    // const [/* tabToggleOrder */, setTabToggleOrder] = useState(0);
    // const [preTabType, setPreTabType] = useState('time');


    const { tabToggle, page, pageChanged, tablePanelList, handlerTabs, onReInspect  } = props;

    const handlerOpenDetail = (item) => {
        props.jumpToPage && props.jumpToPage(item, page);
    };

    // const TriangleWrap = styled.span`
    //     position: relative;
    // `;

    return (
        <List
            header={
                <div className="common-call-list-item-header">
                    <Space>
                        <span>{locales.pages.gong.common.filterItem.sort}</span>
                        {tablePanelList.length >= 0 &&
                            tablePanelList.map((item) => {
                                return (
                                    <Button
                                        onClick={handlerTabs.bind(null, item.key)}
                                        className={
                                            tabToggle === item.key
                                                ? 'normal-btn-link common-list-sort-tab-btn'
                                                : 'normal-btn-link'
                                        }
                                        type="link"
                                    >
                                        {item.label}
                                        <span
                                            style={{
                                                position: 'relative',
                                                top: '-6px',
                                                left: '2px',
                                            }}
                                        >
                                            <TriangleDomUp color={item.ordersType} />
                                            <TriangleDomBottom color={item.ordersType} />
                                        </span>
                                    </Button>
                                );
                            })}
                    </Space>
                    {isOperator() && (
                        <ReInspect
                            onReInspect={onReInspect}
                        />
                    )}
                </div>
            }
            pagination={false}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={locales.labels.noValue}
                    />
                ),
            }}
            loading={props.loading}
            footer={<UdeskPagination {...page} showSizeChanger={false} onChange={pageChanged} />}
            dataSource={props.listData}
            renderItem={(item: any) => (
                <List.Item
                    key={item.dataId}
                    onClick={handlerOpenDetail.bind(null, item)}
                    className="common-call-list-item-content"
                >
                    <CallListItem {...item} key={item.dataId}></CallListItem>
                    <Space size={16} direction="vertical" style={{ width: '100%' }}>
                        {item.keywords &&
                            item.keywords.map((record, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        {idx < 3 || item.isMoreData ? (
                                            <Space
                                                key={record.index}
                                                size={22}
                                                className="call-space"
                                            >
                                                <div className="all-cal-filter-time">
                                                    <Button
                                                        className="time-btn"
                                                        size="small"
                                                        shape="round"
                                                        type="primary"
                                                    >
                                                        {record.begin_time.split(' ')[1]}
                                                    </Button>
                                                </div>
                                                <p className="filter-content">
                                                    {record.speaker_name
                                                        ? `[${record.speaker_name}]`
                                                        : ''}
                                                    <span
                                                        className="search-key-word-hight-light"
                                                        dangerouslySetInnerHTML={{
                                                            __html: record.text,
                                                        }}
                                                    ></span>
                                                </p>
                                            </Space>
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                        {item.keywords && item.keywords.length > 3 && !item.isMoreData ? (
                            <Space size={22} className="call-space">
                                <div className="all-cal-filter-time">
                                    <Button onClick={props.handlerViewMore.bind(null, item)} type="link">
                                        {locales.pages.gong.common.filterItem.seeMore}
                                    </Button>
                                </div>
                            </Space>
                        ) : null}
                    </Space>
                </List.Item>
            )}
        />
    );
}

// class Component extends React.Component {
//     render() {
//         return <FilterItem {...this.props} />;
//     }
// }
export default React.memo(FilterItem);
