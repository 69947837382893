import Udesk from "Udesk";
import SemanticTagsDetailComponent from "./component";
// import _isEmpty from 'lodash-es/isEmpty';
import _filter from 'lodash-es/filter';
// import _find from 'lodash-es/find';
import _size from 'lodash-es/size';
class SemanticTagsDetailRoute extends SemanticTagsDetailComponent {
    asyncModel() {
        let { match, sdkOptions } = this.props;
        let listmap = {};

        if (match.params.id) {
            let url1 = Udesk.business.apiPath.concatApiPath(
                `semantic-tag/${match.params.id}`,
                sdkOptions
            );
            listmap = new Promise((resolve, reject) => {
                Udesk.ajax.get(url1).then(
                    resp => {
                        resolve(resp);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            // .then(dataList => {
            //     let data = {
            //         tagId: null,
            //         searchMinScore: 60,
            //         searchMaxScore: 80,
            //         pageNum: 1,
            //         pageSize: 10
            //     };
            //     if (dataList && !_isEmpty(dataList.tags)) {
            //         let field = _find(
            //             dataList.tags,
            //             current => current.standardFlag === 1
            //         );
            //         data.tagId = field.id;
            //         this.privates.tagId = data.tagId;
            //     }
            //     //训练查询
            //     let url4 = Udesk.business.apiPath.concatApiPath(
            //         `semantic-tag/similar-sentences`,
            //         sdkOptions
            //     );
            //     return new Promise((resolve, reject) => {
            //         Udesk.ajax.post(url4, data).then(
            //             resp => {
            //                 resolve({
            //                     listmap: dataList,
            //                     trainList: resp.data
            //                 });
            //                 if (resp.paging && resp.data) {
            //                     this.privates.paging.pageNum = resp.paging.pageNum + 1;
            //                 }
            //             },
            //             reason => {
            //                 Udesk.ui.notify.error(reason.errorMsg);
            //                 reject(reason);
            //             }
            //         );
            //     });
            // });
        } else {
            listmap = new Promise((resolve, reject) => {
                resolve({
                    data: {
                        tags: [],
                        recallWords: [],
                        excludeKeyword: [],
                    }
                });
            });
        }
        let url2 = Udesk.business.apiPath.concatApiPath(
            `semantic-tag/rule-field`,
            sdkOptions
        );
        let ruleField = new Promise((resolve, reject) => {
            Udesk.ajax.get(url2).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        //信息实体
        let url3 = Udesk.business.apiPath.concatApiPath(
            // `information-entity/showlist?systemType=${Udesk.data.init.company.systemType}`,
            `information-entity/showlist`,
            sdkOptions
        );
        let informationEntityshowlist = new Promise((resolve, reject) => {
            Udesk.ajax.get(url3).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let url4 = Udesk.business.apiPath.concatApiPath(
            `conceptWords/showList`,
            sdkOptions
        );
        let conceptWordList = new Promise((resolve, reject) => {
            Udesk.ajax.get(url4).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            listmap,
            ruleField,
            informationEntityshowlist,
            conceptWordList
        };
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === "listmap" && asyncResult.data) {
            this.privates.id = asyncResult.data.id;
            this.privates.title = asyncResult.data.title??'';
            this.privates.effectiveness = asyncResult.data.activeFlag??Udesk.enums.activeFlags.effiective.id;
            this.privates.standardSentences = asyncResult.data.standardContent??'';
            this.privates.type = asyncResult.data.type??null;
            this.privates.editEnable = asyncResult.data.editEnable;
            
            if (asyncResult.data.tags && asyncResult.data.tags.length > 0) {
                this.privates.similaSentences = asyncResult.data.tags.map(
                    (item, index) => {
                        return {
                            ...item,
                            order: index
                        };
                    }
                );
                this.privates.similaSentencesCount = _size(this.privates.similaSentences) + 1;
            }else{
                this.privates.similaSentences = [];
                this.privates.similaSentencesCount = 0;
            }
            if (asyncResult.data.recallWords) {
                this.privates.recallKeyWord = asyncResult.data.recallWords.map(
                    (item, index) => {
                        return {
                            id: index,
                            text: item
                        };
                    }
                );
                this.privates.recallKeyWordCount = _size(asyncResult.data.recallWords);
            }
            if (asyncResult.data.excludeKeyword) {
                this.privates.excludeKeyword = asyncResult.data.excludeKeyword.map(
                    (item, index) => {
                        return {
                            id: index,
                            text: item
                        };
                    }
                );
                this.privates.excludeKeywordCount = _size(asyncResult.data.excludeKeyword);
            }
            if (
                asyncResult.data.customRules &&
                asyncResult.data.customRules.length > 0
            ) {
                this.privates.customRules = _filter(
                    asyncResult.data.customRules,
                    current => current.fieldType !== "fe"
                ).map((item, index) => {
                    return {
                        ...item,
                        id: `customRules_${index}`,
                        name: item.label ? item.label : item.keyWords ? item.keyWords : null
                    };
                });
                this.privates.customRules = [
                    ...this.privates.customRules,
                    {
                        id: 'none'
                    }
                ];
                this.privates.rulelistValue = this.privates.customRules;
            }else{
                this.privates.rulelistValue = [];
                this.privates.customRules = [{ id: 'none' }];
            }
            if (asyncResult.data.recallRegular) {
                this.privates.recallRegular = asyncResult.data.recallRegular;
            }else{
                this.privates.recallRegular = undefined;
            }
            if (asyncResult.data.excludeRegular) {
                this.privates.excludeRegular = asyncResult.data.excludeRegular;
            }else{
                this.privates.excludeRegular = undefined;
            }
            if (asyncResult.data.highLevelConfig) {
                this.privates.highLevelConfig = asyncResult.data.highLevelConfig;
            }else{
                this.privates.highLevelConfig = {
                    crossSentence: {
                        on: 0,
                        crossLength: 1,
                        differentRoleLength: 0,
                        maxCrossLength: 100,
                    },
                    duplicate: {
                        on: 0,
                        threshold: 80,
                    },
                    similarityDegreeThreshold: {
                        threshold: 80,
                    },
                };
            }
            // if (asyncResult.trainList) {
            //     this.privates.transList = asyncResult.trainList;
            // }
            this.actions.update();
            this.props.form.resetFields();
        } else if (asyncKey === "ruleField") {
            let list1 = _filter(asyncResult, current => current.fieldType !== "fe");
            let list2 = _filter(asyncResult, current => current.fieldType === "fe");
            this.privates.ruleFields = list1.map((item, index) => {
                return {
                    ...item,
                    id: `ruleFields_${index}`
                };
            });
            this.privates.ruleFieldsStartEnd = list2;
            this.actions.update();
        } else if (asyncKey === "informationEntityshowlist") {
            this.privates.informationEntityshowlist = asyncResult.map(
                (item, index) => {
                    return {
                        color: "#1890ff",
                        fieldType: "entity",
                        subtype: "entity",
                        label: item.label,
                        defaultValue: [],
                        fieldName: null,
                        keyWords: "",
                        apiName: item.apiName,
                        id: `entity_${index}`
                    };
                }
            );
            this.actions.update();
        } else if (asyncKey === 'conceptWordList') {
            this.privates.conceptWordList = asyncResult.map(
                (item) => {
                    return {
                        color: "#1890ff",
                        fieldType: "wordBag",
                        subtype: "wordBag",
                        label: item.name,
                        defaultValue: [],
                        fieldName: null,
                        keyWords: item.id,
                        id: `concept_${item.id}`
                    };
                }
            );
        }
    }
}

export default SemanticTagsDetailRoute;
