import React from 'react';

export default function Header(props) {
    let {
        headerComponent,
        headerComponentAttrs,
        header,
    } = props;
    if (headerComponent) {
        return (
            <div class="filter-header">
                <headerComponent runtimeAttributes={headerComponentAttrs} />
            </div>
        );
    }
    else {
        if (header) {
            return (
                <div class="filter-header">
                    {header}
                </div>
            );
        }
        else {
            return null;
        }
    }
}