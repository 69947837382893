//xuyao zeng jia id
import React from 'react';
import Component from './base';
import Udesk from '../../udesk/index';

export default class Template extends Component {
    render() {
        let {
            props,
            privates,
            actions
        } = this;
        let {
            node,
            addIconClass,
            editIconClass,
            deleteIconClass,
            draggable,
        } = props;

        return (
            <If condition={node}>
                <If condition={!node.states.actions.add || node.states.actions.add.visible}>
                    <a href="#" className="node-action-item add-node" onClick={actions.add.params(node)}><i class={`${addIconClass} action-icon`}></i></a>
                </If>
                <If condition={!node.states.actions.edit || node.states.actions.edit.visible}>
                    <a href="#" className="node-action-item edit-node" onClick={actions.edit.params(node)}><i class={`${editIconClass} action-icon`}></i></a>
                </If>
                <If condition={!node.states.actions.delete || node.states.actions.delete.visible}>
                    <a href="#" className="node-action-item delete-node" onClick={actions.delete.params(node)}><i class={`${deleteIconClass} action-icon`}></i></a>
                </If>
            </If>
        );
    }
}