// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PutInspectionDataRecheckByRecordIdStatusParam,
    BaseResponseVoid,
} from '../../../../types';

/**
 * changeViewStatus
 *
 * @export
 * @tags 质检工作台
 * @link [PUT] /inspectionData/recheck/{recordId}/status
 * @param recordId
 */
export function putInspectionDataRecheckByRecordIdStatus(
    options: ApiOptions<PutInspectionDataRecheckByRecordIdStatusParam, never> & {
        segments: PutInspectionDataRecheckByRecordIdStatusParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PutInspectionDataRecheckByRecordIdStatusParam, never>(
        '/inspectionData/recheck/{recordId}/status',
        {
            ...options,
            method: 'put',
            __$requestCalleeName: 'putInspectionDataRecheckByRecordIdStatus',
        }
    );
}

export const meta = [
    { tags: ['质检工作台'], path: '/inspectionData/recheck/{recordId}/status', method: 'put' },
];
