import React, { useState, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Divider, Button, Table, Popconfirm, message, Tag, Icon } from 'udesk-ui';
import {useRequest, useLiveEffect} from '../util';
import './index.scss';

const DelBtn = (props) => {
    const {onDelete} = props;
    const locales = Locales.current;
    return (
        <Popconfirm
            title={locales.labels.deleteContent}
            onConfirm={onDelete}
            onCancel={(e) => e.stopPropagation()}
            okText={locales.labels.confirm}
            placement='bottom'
            cancelText={locales.labels.cancel}
        >
            <Button
                type='text'
                size='small'
                icon={<Icon type='DeleteOutlined' antdIcon={true} />}
            />
        </Popconfirm>
    );
};

function GroupTestList(props) {
    const locales = Locales.current;
    const {selectedRowKey, setSelectedRowKey}= props;
    const [data, setData] = useState([]);
    const request = useRequest();

    useLiveEffect(() => {
        let url = `review/call/analysis/groupTest`;
        request(url).then((r) => {
            let flag = true;
            const list = (r.data || []).map(item=> {
                if (item.id === selectedRowKey) {
                    flag = false;
                }
                return {
                    key: item.id,
                    monitorStatus: item.monitorStatus,
                    name: item.monitorName
                };
            });
            if (flag && list.length > 0) {
                setSelectedRowKey(list[0].key);
            }
            setData(list);
        });
    }, [selectedRowKey]);

    const delHandle = (id) => {
        let itemIndex = 0;
        let result = [];
        let url = `review/call/analysis/groupTest/${id}`;
        request(url, {}, 'del').then((r) => {
            message.success(/* 删除成功 */locales.pages.gong.groupTest.list.template.deletionSucceeded);
            setData((list) => {
                result = list.filter((item, index) => {
                    if (item.key === id) {
                        itemIndex = index;
                    }
                    return item.key !== id;
                });
                return result;
            });
            setSelectedRowKey((key) => {
                let nextKey = key;
                if (key === id) {
                    if (itemIndex > 0) {
                        itemIndex--;
                    }
                    nextKey = result.length > 0 ? result[itemIndex].key : undefined;
                }
                return nextKey;
            });
        });
    };

    const renderCell = useCallback((text, record) => {
        return (
            <div className='group-test-item'>
                <span onClick={() => setSelectedRowKey(record.key)}>{record.name}</span>
                <Tag color='default'>{['', /* 未开始 */locales.pages.gong.groupTest.list.template.notStarted ,/* 进行中 */locales.pages.gong.groupTest.list.template.haveInHand, /* 已结束 */locales.pages.gong.groupTest.list.template.closed][record.monitorStatus]}</Tag>
                <div className="toolkit-button">
                    <Button
                        onClick={() => {
                            Udesk.ui.routing.transitionTo({
                                history: props.history,
                                routeName: 'groupTestDetailEdit',
                                pathParams: { id: record.key }
                            });
                        }}
                        type='text'
                        size='small'
                        icon={<Icon type='EditOutlined' antdIcon={true} />}
                    />
                    <DelBtn onDelete={() => delHandle(record.key)} /> 
                </div>
            </div>
        );
    }, []);

    const columnTitle = useMemo(() => {
        return ( 
            <Button 
                style={{
                    padding: '4px 15px',
                }} 
                type="primary" 
                icon={<Icon type='PlusOutlined' antdIcon={true} />}
                onClick = {
                    () => {
                        Udesk.ui.routing.transitionTo({
                            history: props.history,
                            routeName: 'groupTestDetail'
                        });
                    }
                } 
            >
                {/* 新建分组测试 */locales.pages.gong.groupTest.list.template.newGroupTest}
            </Button>
        );
    }, [props.history]);

    return (
        <div className='udesk-qa-group-test-list'>
            <Table
                scroll={{
                    x: false,
                    y: '100%'
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    columnTitle: columnTitle,
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    columnWidth: 1,
                    pageSize: data.length,
                    total: data.length,
                    hideOnSinglePage: true,
                }} 
                columns={[]}
                dataSource={data}
            />
        </div>
    );
}

function GroupTestIndex(props) {
    const {selectedRowKey, setSelectedRowKey} = props;

    return (
        <Page
            pageBodyClassName='group-test-page-body'
            title={Locales.current.components.pages.dataAnalysis.groupTest}
            padding={true}
        >
            <GroupTestList
                selectedRowKey={selectedRowKey} 
                setSelectedRowKey={setSelectedRowKey} 
                {...props}
            />
            <Divider type="vertical" />
            <SubRoutes
                childProps={{
                    selectedRowKey
                }}
                route={props.routes.find((r) => r.name === 'groupTestAnalysis')}
            />
        </Page>
    );
}


export default class Template extends React.Component {
    privates = {
        storages: {
            selectedRowKey: null,
        },
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'sessionStorage',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/analysis-enable\/group-test\//i,
            ],
        },
    };
    actions = {
        setSelectedRowKey(key){
            if (typeof key === 'function') {
                this.privates.storages = {
                    selectedRowKey: key(this.privates.storages.selectedRowKey)
                };
            } else {
                this.privates.storages = { 
                    selectedRowKey: key
                };
            }
            this.actions.update();
        },
    };
    render() {
        return <GroupTestIndex 
            {...this.props}
            setSelectedRowKey={this.actions.setSelectedRowKey} 
            selectedRowKey={this.privates.storages.selectedRowKey}
        />;
    }
}

