export default {
  labels: {
    clear: "クリア",
    refresh: "更新",
    nextStep: "次へ",
    prevStep: "一歩進む",
    closeDriver: "ガイドを終了します",
    complete: "完了",
    viewStatistics: "統計の表示",
    colon: "：",
    splitter: "/",
    remark: "備考",
    correction: "誤り訂正",
    save: "保存",
    reset: "リセット",
    increase: "追加",
    preview: "プレビュー",
    unit: "条",
    cancel: "キャンセル",
    all: "すべて",
    searchPlaceHolder: "キーワードを入力して検索します",
    greaterThan: "より大きい",
    lessThan: "未満",
    second: "秒",
    description: "説明",
    column: "列",
    pleaseChoice: "選択してください",
    filter: "スクリーニング",
    saveAndSubmit: "保存して送信します",
    submit: "送信",
    appeal: "申し立て",
    review: "再確認",
    choose: "選択",
    manage: "管理",
    delete: "削除",
    add: "新規追加",
    create: "新規作成",
    edit: "編集",
    name: "名称",
    describe: "説明",
    next: "次へ",
    percent: "%",
    export: "エクスポート",
    exportAll: "すべてエクスポート",
    confirm: "確定",
    back: "戻る",
    versionUpdate:
      "私たちのサイトは新しいバージョンを発表したばかりです正常に使用できるようにするには、現在の作業をすぐに保存し、ページを更新する (ブラウザの更新ボタンをクリックするか、F5キーを押す) 必要があります。ご協力ありがとうございました",
    more: "もっと",
    selected: "選択済",
    deduct: "バックル",
    maxScore: "最も多い",
    scoreUnit: "分",
    pointUnit: "個",
    comma: "，",
    view: "表示",
    batchingImport: "インポート",
    deleteContent: "削除しますか? 削除後は復元できません",
    import: "インポート",
    uploadFile: "ファイルのアップロード",
    downloadFile: "ファイルをエクスポート",
    upload: "アップロード",
    click: "クリック",
    downloadTemplate: "テンプレートのダウンロード",
    fillInTheForm: "書式で記入する",
    UploadExcelfile: "Excelをアップロードして一括インポートを完了します",
    uploadIdentical:
      "API名が存在する場合、インポート時に、システム内の既存のデータが更新されます",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "適用",
    leftBracket: "(",
    rightBracket: ")",
    question: "問題",
    answer: "答え",
    content: "コンテンツ",
    search: "検索",
    query: "照会",
    cancelEdit: "編集をキャンセルします",
    noValue: "データなし",
    selectAll: "全選択",
    changeAGroup: "グループを変える",
    arrow: " ---> ",
    saveReview: "再確認提出",
    saveSpotCheck: "抽出検査の提出",
    rapidSampling: "高速抽出検査",
    file: "アーカイブ",
    download: "ダウンロード",
    lastPage: "前のページ",
    nextPage: "次のページ",
    batchActions: "一括操作",
    cancelDistribution: "割り当てのキャンセル",
    againDistribution: "再分配",
    deleteRecord: "記録の削除",
    oneOptionOpertor: "自由品質検査員",
    allOptionOpertor: "すべての品質検査員",
    distributionInfoFirstOne: "正しいことを確認します",
    distributionInfoFirstTwo:
      "レコードを再割り当てしますか? 分配先を選択してください",
    distributionInfoSecondOne: "キャンセルすることを確認します",
    distributionInfoSecondTwo:
      "条記録の分配者情報ですか?キャンセル後は未分配の再確認記録になります。",
    distributionInfoSecondThree:
      "条記録の分配者情報は、キャンセルすると未分配の抽出検査記録になる.",
    distributionInfoThirdOne: "選択した記録には",
    distributionInfoThirdTwo:
      "再確認された記録は、再確認された記録は処理されません。",
    distributionInfoThirdThree:
      "条抽出検査された記録は、抽出検査された記録は処理されない.",
    exportError: "エクスポートに失敗しました",
    exportSuccess: "エクスポートに成功しました",
    deleteRecordInfo:
      "{0} 件のレコードを削除しますか? 削除後はデータの復元ができなくなります",
    pleaseSetFilter: "フィルタ条件を選択してください",
    noCountAfterFilter:
      "エクスポートデータがありません。フィルタ条件を再設定してください。",
    redo: "再実行",
    batchMove: "一括移動",
    websiteName: "スマート品質検査",
    salesEnable: "販売エンパワーメント",
    systemName: "スマート品質検査",
    initSystemError:
      "ネットワーク異常またはページの初期化に失敗しました。カスタマーサービスに連絡してください。",
    day: "日",
    hour: "時間",
    minute: "分",
    questionMark: "？",
    personalSettings: "個人設定",
    logout: "ログアウト",
    ok: "確定",
    saveAs: "名前を付けて保存",
    pleaseEnter: "入力してください",
    executeMessage: {
      executeSuccess: "{0}条の実行に成功しました",
      executeError: "{0} 件の実行に失敗しました",
    },
    field: "フィールド",
    operator: "演算子",
    value: "値",
    placeholder: "選択してください",
    pleaseSelect: "選択してください",
    totalScore: "総得点",
    hyphen: "-",
    semicolon: "；",
    wfm: "スマートクラス",
    user: "ユーザー",
    none: "なし",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "保存しました! 関係する従業員がページを更新すると有効になります",
    saveAsName: "{0}(コピー)",
    systemInformation: "システム資料の表示",
    billingCenter: "課金センター",
    warningMessage:
      "余剰割当が不足しているので、できるだけ早くチャージしてください",
    fileTypeHolder:
      "ファイル形式が間違っています。{0} 形式のファイルをアップロードしてください。",
    dataNo: "データなし",
    move: "移動",
    study: "マークされていない自己学習品質検査ポイントがあります",
    studySuccess: "マークアップに成功しました",
    studyError: "マークアップに失敗しました",
    editType: "分類ラベルの編集",
    copyed: "クリップボードにコピー済み",
    clickToCopy: "クリックしてコピー",
    addFilterConditions: "フィルタ条件の追加",
    filterConditions: "フィルタ条件",
    messageTime: "メッセージ時間",
    messageType: "メッセージタイプ",
    pushType: "メッセージチャネル",
    messageContent: "メッセージの内容",
    messageName: "メッセージ名",
    messageStatus: "メッセージステータス",
    configuration: "設定情報",
    sendTarget: "送信先",
    guideUrl: "ブートURL",
    noticeDetail: "メッセージの詳細",
    action: "操作",
    seeDetail: "詳細を見る",
    theDay: "{0}日",
    theLastDayOfMonth: "毎月の最終日",
    ignore: "無視する",
    seeNow: "今すぐ見る",
    exportConfirmText: {
      export: "合計 {0} 件のデータをエクスポートしますか?",
      exportAll: "すべてをエクスポートしますか?",
    },
    samrTagAdd: "ラベルを追加",
    deletePopconfirmContent: "削除しますか?",
    TheLastThirtyDays: "直近30日間",
    addNumber: "プラス",
    subNumber: "マイナス",
    addStrategy: "ポリシーの追加",
    minutes: "分",
    status: "有効化ステータス",
    pushConfig: "プッシュ設定",
    category: "分類",
    and: "と",
    interval: "間隔",
    switchOn: "オン",
    switchOff: "閉じる",
    batchDel: "一括削除",
    exportTxt: "TXTテキストのエクスポート",
    playSpeed: "倍速",
    messageLogBtn: "トリガーされた通知の通知/会話を表示します",
    dataLink: "データリンク",
    yes: "はい",
    no: "いいえ",
    sentenceUnit: "文",
    appealDetail: "苦情記録の詳細",
    detail: "詳細",
    default: "デフォルト",
    customer: "お客様",
    agent: "カスタマーサービス",
    sort: "並べ替え",
    applyNow: "応用",
    index: "シリアルナンバー",
    allColumns: "追加できる列",
    selectedColumns: "追加された列",
    syn: "同期",
    saveAndNext: "次の条を保存して作成する",
    viewReference: "参照の表示",
    referenceDetail: "詳細を引用する",
    goToQaList: "品質検査に行く",
    goToCheck: "抽出検査に行く",
    businessCard: "名刺",
    weapp: "アプレット",
    redpacket: "レッドバッグ",
    externalRedpacket: "赤いバッグを交換する",
    sphfeed: "ビデオ番号",
    exportTaskCreated: "エクスポートタスク作成成功",
    plzGoTo: "にジャンプしてください",
    importTaskCreated: "インポートタスク作成成功",
    taskCreated: "タスク作成成功",
    reply: "返信",
    replied: "お返事しました",
    release: "リリース",
    expandMore: "その他の {0} 件の返信を展開します。",
    comment: "コメント",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "少なくとも1つ {0} を持ってください",
      canNotBeEmptyFormat: "{0} は必須項目です",
      repeatErrorTipFormat: "{0} に重複があります",
      getInformationFailedFormat:
        "{0} 情報の取得に失敗しました。ネットワーク接続を確認するか、後で再試行してください",
      deleteSuccessFormat: "{0} の削除に成功しました。",
      deleteFailedFormat: "{0} の削除に失敗しました。後でやり直してください。",
      saveSuccessFormat: "{0}保存に成功しました。",
      saveFailedFormat:
        "{0}保存に失敗しました。しばらくしてからやり直してください。",
      fileSuccessFormat: "{0}ファイリングせいこう成功です",
      fileFailedFormat:
        "{0} のアーカイブに失敗しました。しばらくしてからやり直してください。",
      exportSuccessFormat: "{0} エクスポート成功",
      exportFailedFormat:
        "{0} のエクスポートに失敗しました。しばらくしてからやり直してください。",
      submitSuccessFormat: "{0} 送信に成功しました。",
      submitFailedFormat: "{0} の送信に失敗しました。後でやり直してください。",
      confirmSuccessFormat: "{0} 確認に成功しました。",
      confirmFailedFormat: "{0} の確認に失敗しました。後で再試行してください。",
      pleaseUploadFile: "先にファイルをアップロードしてください!",
      importFileSuccess: "ファイルのインポートに成功しました!",
      importFildFailed: "ファイルのインポートに失敗しました!",
      deleteSuccess: "削除に成功しました!",
      deleteFailed: "削除に失敗しました。後でやり直してください",
      illegalTitle: "権限不足",
      illegalMessage:
        "次の手順の一部または全部の権限がありません。管理者に連絡して設定し、okボタンをクリックすると起動を終了します。",
      illegalMessageCurrent:
        "現在の手順の一部または全部の権限がありません。管理者に連絡して設定し、okボタンをクリックすると起動を終了します。",
      skipMessage:
        "この手順を一時的にスキップする場合は、「スキップ」をクリックしてください",
      errorMsg500:
        "サーバー要求に失敗しました。操作または更新を確認して再試行してください",
      errorMsg404: "Ooops... お探しのページがなくなりました",
      errorMsg400:
        "サーバー要求に失敗しました。データを確認してから再試行してください",
      pageOverFlow:
        "現在は10000件のみの表示をサポートしています。超過部分はフィルタ条件を設定して確認してください。",
      isHandAddSmartTag: "手動で追加したスマートラベルです",
      defaultGradeMustAtLast: "デフォルトの評価は最後にする必要があります",
      AutomaticAllocationInspectorsIsNull:
        "基本情報ページに入って品質検査員を追加してください",
      timePickerTips:
        "通常の使用に影響を与えないように、23:00 ~ 2:00はシステムメンテナンス時間で、定時タスクは実行されません。",
      batchDeleteInfo:
        "{0}バーのさくじょ削除にせいこう成功、{1}バーのさくじょ削除にしっぱい失敗したばあい場合、そのデータがすでにしよう使用されていることがげんいん原因です。",
      batchDeleteTips: "{0} 件のデータをチェックして、削除しますか?",
      correctionSuccess: "誤り訂正に成功しました",
    },
    pages: {
      qualityInspectionForm: {
        list: { IsMaxFormNumberError: "品質検査フォームを最大10件追加" },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "指標項目 {0} の合計は必ず100になります。",
          banProjectMaxErrorTip: "禁止項目の追加は最大2000件です",
          deadlyProjectMaxErrorTip: "致命的な項目は最大500個追加されます",
        },
      },
    },
    info: {
      creatSuccess: "作成に成功しました",
      creatError: "作成に失敗しました",
      editSuccess: "修正に成功しました",
      editError: "修正に失敗しました",
      deleteSuccess: "削除に成功しました",
      addSuccess: "新規追加に成功しました",
      deleteError: "削除に失敗しました",
      conditionError: "オペレータ論理式エラー",
      customJudgeLogic: "カスタム条件の組み合わせ論理式エラー",
      exportError: "エクスポートに失敗しました",
      searchError: "スクリーニングに失敗しました",
      loadError: "{0} ロードに失敗しました",
      saveSuccess: "保存に成功しました",
      saveError: "保存に失敗しました",
      fileSuccess: "アーカイブに成功しました",
      fileError: "アーカイブに失敗しました",
      cannotBeNull: "{0} は必須項目です!",
      pleaseSearchAndSelect: "キーワードを入力して検索してください",
      clickAndToggle: "選択をクリックして切り替えます",
      deleteWarning: "{0} 削除は復元できません。続行しますか?",
      getOssAuthFailed:
        "OSSライセンスの取得に失敗しました。ネットワーク接続を確認するか、後で再試行してください。",
      getInsightAuthFailed:
        "Insightライセンスの取得に失敗しました。ネットワーク接続を確認するか、後で再試行してください。",
      pictureUploadFailed: "画像のアップロードに失敗しました",
      pictureUploadFailedSize:
        "画像サイズが制限を超えました。画像のアップロードに失敗しました。",
      wecomTagsSyncInfo: "最近の更新:{0} 、同期 {1} 件の情報",
      collectSuccess: "コレクションに成功しました",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "レポートデータの取得に失敗しました" },
      waveAudio: {
        noWaveMessage:
          "あなたの経験を維持するために、 {0}分以上の録音は波形を表示しないので、直接再生できます",
      },
      customFilter: {
        del: "削除",
        edit: "編集",
        new: "カスタムフィルタ",
        apply: "応用",
        cancel: "キャンセル",
        onlyApply: "適用のみ",
        ohterSave: "名前を付けて保存",
        save: "保存",
        customFilter: "よく使う選別",
        reset: "リセット",
        holderKeyWord: "キーワードを入力してください",
        customFilterRule: "論理ルールをフィルタするには:",
        customFilterName: "フィルタ名:",
        holderCustomFilterName: "フィルタ名を入力してください",
        manualConfiguration: "手動設定",
        holderCustomRule: "カスタムロジック条件を入力してください",
        example: "例",
        dragConfiguration: "ドラッグ設定",
        condition: "条件",
        operation: "演算",
        infoDel: "コンテンツをエリア外にドラッグして削除します",
        toConfigure: "設定",
        holderCustomFilterNameLength: "フィルタ名の最大長さは10です",
        customFilterNameNone: "フィルタ名は必須項目です",
        and: "と",
        or: "または",
        not: "非",
        delSuccess: "削除に成功しました",
        saveSuccess: "保存に成功しました",
        updateSuccess: "修正に成功しました",
        customFilterCondition: "フィルタ条件",
        conditionRule: "条件ルール",
        none: "なし",
        filterPlaceholder: "キーワードを入力してください",
      },
      labelTabs: {
        addThis: "ここに追加",
        goThis: "ここに移動",
        saveGo: "保存してトレーニングする",
      },
      datePicker: {
        today: "今日は",
        yesterday: "昨日",
        theDayBeforeYesterday: "おととい",
        thisWeek: "今週",
        lastWeek: "先週",
        thisMonth: "今月",
        lastMonth: "先月",
        lastSevenDay: "最近7日間",
        lastThirtyDay: "直近30日間",
        thisQuarter: "今四半期",
        lastQuarter: "前四半期",
        thisYear: "今年は",
        lastYear: "昨年",
        lastYearFromToday: "最近の一年",
      },
      pagination: { page: "合計 {0}条、 {1} ページ" },
      wechatRecord: {
        loadMoreBtn: {
          top: "より前のメッセージをロードします",
          bottom: "より多くのメッセージをロード",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "品質検査タスク",
        informationCollectTasksManageTitle: "収集タスク",
        customerServiceCenterTitle: "コールセンター",
        informationTitle: "データセンター",
        intelligentAnalysisTitle: "スマート分析",
        semanticIntelligence: "意味知能",
        trainingCenterTitle: "トレーニングセンター",
        adminTitle: "システム管理",
      },
      qualityCheckingTask: {
        title: "通話品質検査",
        index: {
          tipOne:
            "通話品質検査は従業員のコールセンターの仕事の質を評価するために使用され、自分の業務に合わせて適切な品質検査フォームを設定することができます",
          tipTwo:
            "品質検査タスクに適切なルール (スクリーニング、サンプリングなど) を設定できます。品質検査員が評価を行った後、コールは品質検査結果を見て、さらに訴えることができる",
          addTaskTitle: "品質検査タスクの追加",
          deleteTipTitle: "品質検査タスクの削除",
          deleteTipContent:
            "品質検査タスクは削除後は回復できません。削除してもいいですか?",
          checkingTask: "品質検査タスク",
          checkingTaskList: "品質検査タスクリスト",
          formList: "フォームリスト",
          viewHelpDocuments: "ヘルプドキュメントの表示",
        },
        manage: {
          taskSetting: {
            title: "タスク設定",
            form: "フォーム",
            basicInfo: "基本情報",
            visibleRange: "可視範囲",
            visibleRangeTip:
              "選択したコール、コールグループはこの品質検査任務を見ることができ、また権限の設定に合わせて、この任務コールリーダー、コール、品質検査員などにすることができる",
            automaticSampling: "自動サンプリング",
            automaticSamplingTip:
              "自動サンプリングのルールを設定して、設定したルールに従って定期的に品質検査タスクを追加します。たとえば、毎週先週の5% の通話を抽出します",
            samplingRate: "サンプリング頻度",
            samplingTime: "サンプリング時間",
            samplingRange: "サンプリング範囲",
            samplingRule: "サンプリングルール",
            fillterCondition: "フィルタ条件",
            workflow: "品質検査ワークフロー",
            appealsTime: "苦情を許可する時間",
            appealsTimeTipOne: "採点提出後",
            appealsTimeTipTwo: "日内",
            appealsTimeTipThree:
              "苦情が許可された時間を超えた場合、品質検査ステータスを記録して自動的に「確認済」に変更されます",
            auditTime: "処理時間を審査する",
            auditTimeTipOne: "申し立て後",
            auditTimeTipTwo: "日内",
            auditTimeTipThree:
              "審査処理時間を超え、記録は元の評価を維持し、品質検査ステータスは自動的に「確認済」に変更された",
            noAppealsMode: "苦情なしモード",
            appealsMode: "苦情を許可するモデル",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "カスタマーサービス",
            customerServicesGroup: "カスタマーサービス",
            surveyList: "評価リスト",
            customFields: "カスタムフィールド",
            fieldConfig: "フィールド設定",
            from: "から",
            owner: "担当者",
            inspectorAndReviewerAuthority: "品質検査再確認権限",
            inspector: "品質検査員",
            reviewer: "審査員",
            inspectorTip: "品質検査員はこの任務を評価することができる",
            reviewerTip: "審査員はこの任務を評価することができる",
            dataStatisticsAuthority: "データ統計権限",
          },
          samplingRecords: {
            title: "サンプリング記録",
            index: {},
            manualSampling: {
              title: "サンプリング",
              fillterCondition: "フィルタ条件",
              samplingRule: "サンプリングルール",
              agent: "カスタマーサービス",
              type: "タイプ",
              result: "結果",
              evaluate: "評価",
              relayNumber: "中継番号",
              source: "ソース",
              task: "タスク",
              queueState: "順番待ちステータス",
              callHanger: "通話切断側",
              queueTime: "順番待ち時間",
              talkTime: "通話時間",
              bellTime: "ベルを鳴らす時間",
              recordCountTipOne: "合計",
              recordCountTipTwo: "件の記録",
              customerServices: "カスタマーサービス",
              customerServicesGroup: "カスタマーサービス",
              surveyList: "評価リスト",
              customFields: "カスタムフィールド",
              fieldConfig: "フィールド設定",
              defaultFields: "デフォルトフィールド",
            },
          },
          taskList: {
            title: "タスクリスト",
            index: {
              batchDelete: "一括削除",
              batchSubmit: "一括提出",
              batchConfirm: "一括確認",
              itemFields: "評価項目フィールド",
              categoryFields: "二次分類フィールド",
              forbidsAndDeadlies: "禁止項目と致命項目",
              scoreFieldList: "評価項目フィールドリスト",
              customerName: "顧客名",
              cellphone: "電話",
              email: "メールアドレス",
              organization: "会社",
              description: "説明",
              tags: "ラベル",
              taskUserRole: "現在のタスクユーザーロール",
            },
            workBench: {
              title: "詳細を記録する",
              checkForm: "品質検査フォーム",
              waitEvaluate: "評価待ち",
              alreadyEvaluate: "評価済み",
              waitSubmit: "提出待ち",
              alreadySubmit: "送信済み",
              waitConfirm: "確認待ち",
              alreadyConfirm: "確認済み",
              waitReview: "再確認待ち",
              alreadyReview: "再確認済み",
              alreadyDone: "完了しました",
              comment: "評価ノート",
              totalScore: "総得点",
              score: "分",
              remark: "備考",
              changeRecords: "記録の変更",
              subject: "テーマ",
              secondLevelScore: "二次評価",
              deadlyList: "致命的な項目",
              forbidList: "禁止項目",
              addToCheckTask: "品質検査タスクに追加",
              submitCheckRemark: "品質検査評価を提出しました",
              changeCheckRemark: "品質検査評価を修正しました",
              doneCheckRemark: "品質検査評価を完了しました",
              submitCheckReview: "品質検査再確認を提出しました",
              submitCheckAppeal: "品質検査の申し立てを提出しました。",
              saveFlowSuccess: "評価に成功しました。",
              saveFlowFailed: "評価に失敗しました。後でやり直してください。",
              FormDetail: "フォームの詳細",
              callDetail: "通話記録の詳細",
              sampleCalllogDetail: "詳細を記録する",
              appealReasonTip: "苦情理由を入力してください (必須)",
              appeal: "申し立て",
              appealReason: "訴えの理由",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} 品質検査フォーム",
        basicInformationTitle: "基本情報",
        indicatorsProjectTitle: "指標項目",
        specialProjectTitle: "特別項目",
        totalScore: "総得点",
        indicatorsName: "指標名称",
        score: "スコア",
        singleScore: "個別分割",
        weight: "重み",
        maxDeductScore: "最大減点",
        add: "追加",
        detail: "フォームの詳細",
        list: {
          title: "品質検査フォーム",
          tipOne:
            "品質検査フォームの設定は品質検査の最初のステップで、会社の業務に合わせて最適な品質検査フォームを設定できます。",
          tipTwo:
            "直接評価、重み評価、減点制など多くのフォームをサポートしています。ルールを設定してから、システムに追加することをお勧めします。",
        },
        basicInformation: {
          formTemplate: "フォームテンプレート",
          directScore: "直接評価",
          weightedScore: "重み評価",
          deductScore: "減点",
          directScoreSecondLevel: "直接評価-二級",
          weightedScoreSecondLevel: "重み評価-二次",
          deductScoreSecondLevel: "減点-二級",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "禁止項目",
          banProjectTip:
            "品質検査の採点時にチェックすることができ、違反品と見なすことも、実際の点数に影響を与えないコメントの補充と見なすこともできる。",
          deadlyProject: "致命的な項目",
          deadlyProjectTip:
            "致命的な項目は品質検査の採点時にチェックすることができます。致命的な項目をチェックすると、その品質検査タスクは直接0点を採点します。",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "通話品質検査",
          entiretyReportTitle: "全体レポート",
          serviceGroupReportTitle: "カスタマーサービスグループのレポート",
          serviceReportTitle: "カスタマーサービスのレポート",
          entiretyReport: {
            pandect: "総覧",
            contrast: "比較",
            trend: "トレンド",
            analyze: "分析",
            totalScore: "総得点",
            banProject: "禁止項目",
            deadlyProject: "致命的な項目",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "品質検査結果リスト",
          taskSelectPlaceholder: "品質検査タスクの選択",
          agentSelectPlaceholder: "カスタマーサービスの選択",
          refresh: "更新",
          all: "すべて",
          unread: "未読",
          inComplaint: "申し立て中",
          reviewed: "再確認済み",
          totalScore: "総評",
          detail: "品質検査の詳細",
          audioText: "録音テキスト",
          callInfo: "通話情報",
          ticketInfo: "ワークオーダー情報",
          wechatInfo: "セッション情報",
          dialogText: "会話テキスト",
          dialogInfo: "会話情報",
          customer: "お客様",
          agent: "カスタマーサービス",
          none: "品質検査データはありません",
          appealSuccess: "訴えが成功しました",
          appealFailed: "訴えに失敗しました。後でやり直してください。",
          pointListIsNull: "クレーム品質検査ポイントは空白にできません!",
          pleaseSelectPoint: "品質検査ポイントを選択してください!",
          unsupportedFormat: "このフォーマットはサポートされていません",
          shardingInformation: "以下はスライス {0} です。",
          smartTags: "スマートラベル",
          businessRecords: "業務記録",
          selectedPointsWarning:
            "クレームする品質検査項目をチェックし、クレーム項目に関連文を指定して「提出」をクリックしてください。",
          applyReason: "苦情理由 (最大50字)",
          chatMessageTypes: {
            all: "すべて",
            text: "テキスト",
            image: "画像",
            agree: "会話内容に同意する",
            video: "動画",
            voice: "音声",
            file: "ファイル",
            emotion: "表情",
            link: "リンク",
            chatrecord: "チャット記録",
            mixed: "混合メッセージ",
            default: "メッセージ未サポート",
            deleteFriend: "友達を削除する",
            redpacket: "レッドバッグ",
            card: "名刺",
            disAgree: "会話の内容に同意しない",
            weapp: "アプレット",
            externalRedpacket: "赤いバッグを交換する",
            meetingVoicevCall: "通話メッセージ",
            uRL: "Webページ",
            replyTimeout: "タイムアウト返信",
            sphfeed: "ビデオ番号",
            chatRecordTitle: "チャット記録",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "総得点",
            actualScore: "評価",
            actualGrade: "評価",
            appeal: "申し立て",
            remark: "マニュアルノート",
            none: "品質検査詳細データはありません",
            pointEdit: "品質検査ポイント編集分類",
          },
          changeList: {
            title: "記録の変更",
            user: "ユーザー",
            point: "品質検査ポイント",
          },
          relatedRecord: {
            title: "関連記録",
            qualityList: "品質検査タスク",
            none: "なし",
            check: "表示",
            loadMore: "もっと",
          },
        },
      },
      grade: {
        title: "品質検査評価",
        subTitle:
          "品質検査評価は品質検査の採点が完了した後に品質検査対象を段階的に評価するために使用され、例えば、60点以下で不合格、60-85点で良好、80点以上で優秀に配置することができる",
        list: {
          up: "上に移動",
          down: "下に移動",
          del: "削除",
          case: "ケースライブラリへの自動加入",
          orderCase: "デフォルトのケースライブラリ",
          info: "デフォルトの評価にはマッチ条件がありません。削除できません。上記のいずれかの評価を満たさなければ、デフォルトの評価を取得します。",
          add: "評価を追加",
          save: "保存",
          close: "キャンセル",
          max: "品質検査の評価は最大20本まで設定できます。",
          name: "名前なし",
          success: "保存に成功しました",
        },
      },
      qualityInspectionList: {
        title: "品質検査リスト",
        fastFilter: "品質検査違反",
        fastFilterTip: "減点制の命中は違反で、加点制の未命中は違反です。",
        titleWechat: "分析リスト",
        titleCollect: "収集リスト",
        status: "ステータスの分析",
        reQualityInspectionConfig: "再品質検査タスクオプション",
        pleaseSelectRecheckPoints:
          "再計算が必要な品質検査ポイントを選択してください",
        beginReQualityInspection: "実行を開始する",
        cancelReQualityInspection: "タスクのキャンセル",
        reQualityInspection: "品質検査のやり直し",
        reQualityInspectionWechat: "再分析",
        reQualityInspectionUnderWay: "再品質検査タスク中… は...",
        reQualityInspectionUnderWayTip:
          "ウィンドウを閉じてもページを離れてもタスクの進行には影響しません",
        reQualityInspectionDone: "再品質検査が完了しました",
        reQualityInspectionDoneTip:
          "再品質検査が正常に完了しました。確認ボタンをクリックしてください。",
        endReQualityInspection: "タスクの終了",
        alreadyDoneTip: "{0}じょう条がかんせい完成しました",
        totalTip: "合計 {0} 条",
        endReInspectionSuccess: "再品質検査タスクは終了しました",
        closeWindow: "ウィンドウを閉じる",
        endReInspectionFailed:
          "再品質検査タスクに失敗しました。後で再試行してください。",
        detail: {
          title: "品質検査の詳細",
          wechatTitle: "詳細を分析する",
          titleCollect: "詳細を収集する",
          callIndex: "関連通話",
          textIndex: "関連会話",
          fileConfirm: "アーカイブしますか?",
          fileConfirmDesc:
            "アーカイブ後は品質検査スコアを編集できません。アーカイブするかどうか確認してください。",
        },
        sysCloumnsContal: "システム列フィールド管理",
        personCloumnsContal: "個人列フィールド管理",
      },
      spotCheckList: {
        title: "抽出検査リスト",
        onlyMyTip: "私の抽出検査だけを見てください",
        plInspector: "抽出検査員を選択してください",
        allInspector: "すべてを見る",
        seInspector: "指定の抽出検査員を見る",
        myInspector: "私だけを見てください",
        noInspector: "未分配の抽出検査員を確認する",
        detail: { title: "抽出検査の詳細" },
      },
      reviewList: {
        title: "再確認リスト",
        onlyMyTip: "自分の再確認のみを確認します",
        plInspector: "審査員を選択してください",
        allInspector: "すべてを見る",
        seInspector: "指定された審査員を見る",
        myInspector: "私だけを見てください",
        noInspector: "未割り当ての審査員を確認する",
        detail: { title: "詳細を再確認する" },
      },
      caseBaseList: {
        title: "ケースライブラリ",
        del: "削除",
        delTitle: "削除後、すべてのコンテンツは保存されません",
        cancel: "キャンセル",
        actions: "操作",
        seeCaseBase: "事例を見る",
        seeDetail: "詳細を見る",
        edit: "編集",
        save: "保存",
        addCaseBase: "ケースライブラリの新規追加",
        export: "エクスポート",
        ok: "確定",
        tip: "品質検査リストの典型的な通話と会話データは、ケースライブラリに保存して学習と共有を容易にすることができる",
        detail: {
          choiceCaseBase: "ケースを選択してください",
          delCaseBase: "削除事例",
          addCaseBase: "ケースの追加",
          successed: "保存に成功しました",
          info: "編集中の内容を保存またはキャンセルしてください",
          caseType: "追加するケースライブラリを選択してください",
        },
      },
      testSetList: {
        title: "品質検査テストセット",
        tip: "品質検査員が抽出検査したデータは品質検査テストセットに保存され、抽出検査後に照合されたいくつかの機械検査ミス項目に対して品質検査条件と品質検査ルールの配置を最適化するのに便利である",
        refresh: "更新",
        reQualityInspectionUnderWay: "タスクを再評価しています… は...",
        reQualityInspectionUnderWayTip:
          "ウィンドウを閉じてもページを離れてもタスクの進行には影響しません",
        reQualityInspectionDone: "再評価が完了しました",
        reQualityInspectionDoneTip:
          "再評価が正常に完了しました。確認ボタンをクリックしてください。",
        endReQualityInspection: "タスクの終了",
        alreadyDoneTip: "{0}じょう条がかんせい完成しました",
        totalTip: "合計 {0} 条",
        endReInspectionSuccess: "タスクの再評価が終了しました",
        closeWindow: "ウィンドウを閉じる",
        endReInspectionFailed:
          "タスクの再評価に失敗しました。後で再試行してください",
        recheckPoint: "品質検査ポイントの点数と精度を再計算する",
        recheckPointTitle: "オプションの再評価",
        delete: {
          reQualityInspectionUnderWay: "タスクを一括削除中...",
          reQualityInspectionUnderWayTip:
            "ウィンドウを閉じてもページを離れてもタスクの進行には影響しません",
          reQualityInspectionDone: "一括削除タスク完了",
          reQualityInspectionDoneTip:
            "一括削除が完了しました。確認ボタンをクリックしてください。",
          endReQualityInspection: "タスクの終了",
          alreadyDoneTip: "{0}じょう条がかんせい完成しました",
          totalTip: "合計 {0} 条",
          endReInspectionSuccess: "一括削除タスクが終了しました",
          closeWindow: "ウィンドウを閉じる",
          endReInspectionFailed:
            "タスクの一括削除に失敗しました。後で再試行してください",
        },
        reultList: {
          assessmentTime: "前回の評価時間",
          assessmentAll: "合計評価",
          strip: "条",
          spot: "ポイント",
          deviation: "偏差",
          accuracyRate: "正確率",
          info: "評価結果がありません。評価してください。",
          onlyDeviationInfo: "偏差記録のみ表示",
          againAssessment: "見直し",
          screen: "スクリーニング",
          batchDelete: "一括削除",
          matching: "マッチ",
          ok: "はい",
          noCancel: "いいえ",
        },
      },
      smartWordsLibrary: {
        title: "スマート辞書",
        tip1: "同義語: 意味ラベルの訓練量を下げるために、同義語の類似文は訓練を繰り返す必要はない",
        tip2: "専門名詞: 識別が不正確な企業名、製品名、業界語彙などの専門名詞に対して、転写最適化、自然言語理解最適化を行い、識別精度、分析精度を高める",
        tip3: "注目語: 語雲分析で注目したい語に注目し、AI分析のホットスポットが現在の業務に合うように支援する",
        tip4: "シールドワード: ワード雲分析で注目したくないワードをシールドし、AI分析のホットスポットが現在の業務に合うように支援する",
        tip5: "単語重み: 意味ラベルの認識を誤り訂正最適化するために使用され、単語の文章中の影響重みを修正することで、必要に応じて特定の場面での意味認識効果を最適化する",
        tip6: "コーパスデータ: それぞれのビジネスシーンに対する大きな段落テキストを入力し、転記誤り訂正とASRトレーニングに使用し、ASR転記精度を高める",
        upload: {
          uploadSynonymWords: "類語のインポート",
          uploadProfessionalWords: "専門名詞を導入する",
          uploadAttentionWords: "注目ワードのインポート",
          uploadIgnoreWords: "無視語のインポート",
          uploadWordsWeight: "ウェイトワードのインポート",
          uploadTypo: "誤字語彙のインポート",
          uploadCorpusData: "音声データのインポート",
          uploadCorpusContent: "データ内容のインポート",
          firstUploadStep:
            "1.テンプレートをダウンロードし、テンプレート形式で記入する",
          downloadTemplateMessage: "テンプレートのダウンロード:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2.インポートボタンをクリックし、テンプレート形式に合ったEXCElファイルをアップロードします",
          secondUploadCorpusDataStep:
            "2.インポートボタンをクリックし、テンプレート形式に合ったTXTファイルをアップロードします",
          postUploadDataFail: "アップロードデータの送信に失敗しました",
          pleaseUploadTemplate:
            "EXCEl/JSON/TXTファイルをアップロードしてください",
          templateNeedJSONOrXLSX:
            "ファイルテンプレートはJSONまたはxlsx形式のみです",
          templateNeedTxt:
            "コーパスデータファイルのテンプレートはTXTファイルのみです",
          uploadSuccess: "インポート成功",
          uploadFailed: "インポートに失敗しました",
          downloadError: "ダウンロードに失敗しました",
          downLoadTxtTitle: "音声内容.txt",
        },
        navTabNames: {
          synonymWords: "同義語",
          professionalWords: "専門名詞",
          attentionWords: "注目ワード",
          ignoreWords: "無視語",
          wordsWeight: "単語の重み",
          corpusData: "コーパスデータ",
          typoLibrary: "誤字詞集",
        },
        synonymWords: {
          createSynonymWords: "同義語の新規作成",
          editSynonymWords: "類義語の編集",
          addSynonymWords: "同義語を追加",
          pleaseEnterStandardWords: "標準語を入力してください",
          synonymWordsExists:
            "この類語はすでに存在しているので、追加できません",
          synonymWordsCanNotNull: "追加したシノニムは必須項目です",
          pleaseAddSynonymWords: "同義語を追加してください",
          addSuccess: "新規追加に成功しました",
          editSuccess: "修正に成功しました",
          deleteSuccess: "削除に成功しました",
          deleteError: "削除に失敗しました",
          required: "*",
        },
        professionalWords: {
          create: "新規専門名詞",
          edit: "専門名詞編集",
          addTargetWords: "置換語を追加",
          targetWordsExists:
            "この置換語はすでに存在しているので、追加できません",
          targetWordsCanNotNull: "追加した置換語は空白にできません",
          pleaseAddTargetWords: "置換語を追加してください",
          pleaseEnter: "専門名詞を入力してください",
          wordsExists: "この専門名詞はすでに存在している",
          createSuccess: "追加に成功しました",
          editSuccess: "修正に成功しました",
          deleteSuccess: "削除に成功しました",
          deleteError: "削除に失敗しました",
          saveError: "保存に失敗しました",
          addTargetWordsMaxNumberError: "置換語は最大100個追加できます",
          addHomophonyWordsMaxNumberError:
            "シールドワードは最大100個追加できます",
        },
        attentionWords: {
          create: "注目ワードの新規作成",
          edit: "注目ワード編集",
          pleaseEnter: "注目ワードを入力してください",
          wordsExists: "この注目語はすでに存在している",
          createSuccess: "追加に成功しました",
          editSuccess: "修正に成功しました",
          deleteSuccess: "削除に成功しました",
          deleteError: "削除に失敗しました",
        },
        ignoreWords: {
          create: "新規無視語",
          edit: "単語編集を無視する",
          pleaseEnter: "無視語を入力してください",
          wordsExists: "この無視語はすでに存在しています",
          createSuccess: "追加に成功しました",
          editSuccess: "修正に成功しました",
          deleteSuccess: "削除に成功しました",
          deleteError: "削除に失敗しました",
        },
        wordsWeight: {
          createWordsWeight: "新規重み語",
          editWordsWeight: "重み語を編集する",
          addWordsWeight: "重み語を追加する",
          pleaseEnterWeightWords: "重み語を入力してください",
          pleaseEnterWeight: "重みを入力してください",
          weightNeedInZeroToHundred: "重みは0 ~ 100の間",
          synonymWordsExists: "この重み語はすでに存在しています",
          weightNeedNumber: "重みには整数フォーマットが必要です",
          standardWeightInformation:
            "基準重みは50で、重みが大きいほど分析に影響力が強い",
          createSuccess: "追加に成功しました",
          editSuccess: "修正に成功しました",
          deleteSuccess: "削除に成功しました",
          deleteError: "削除に失敗しました",
        },
        corpusData: {
          createCorpusData: "新しい資料データ",
          editCorpusData: "音声データの編集",
          addCorpusData: "コーパスデータを追加",
          pleaseEnterCorpusDescription: "資料の説明を入力してください",
          pleaseEnterCorpusContent: "英語の内容を入力してください",
          synonymWordsExists: "この資料データはすでに存在しています",
          CorpusContentWordsOverSetting: "内容の文字数統計が20000字を超え",
          download: "ダウンロード",
          upload: "アップロード",
          see: "表示",
          canNotSave:
            "インポート機能を使用してください。使用しないと保存できません",
          createSuccess: "追加に成功しました",
          editSuccess: "修正に成功しました",
          deleteSuccess: "削除に成功しました",
          deleteError: "削除に失敗しました",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "正しい言葉",
            wrongWord: "誤詞",
            wrongWordCount: "誤字数",
            wrongWordTip: "Enterキーを押して追加します",
            status: "有効性",
          },
          create: "新しい誤字の詞集",
          edit: "誤字語彙集を編集する",
          tip: "誤字の単語集は「誤字検出」の効果を最適化するために使用されます。例えば、「返金」が「キャンセル」に誤って送信された場合、この機能でよく見られる誤字と正しい字を設定できます。注意保存後翌日有効となる。",
          onlyChinese: "中国語の入力のみサポートしています",
          wrongWordLengthErr: "各誤詞の長さは2 ~ 10文字",
        },
      },
      semanticTags: {
        title: "意味ラベル",
        current: "現在の意味ラベル",
        reference1: "品質検査タスク: {0} - {1}",
        reference2: "スマートラベル: {0}",
        reference3: "データ前処理: {0}",
        reference4: "ナレッジベース: {0}",
        index: { import: "意味ラベルのインポート" },
        detail: {
          new: "新しい意味ラベル",
          edit: "意味ラベルの編集",
          name: "名称",
          standardSentences: "標準文",
          effectiveness: "有効性",
          similarSentences: "類似文",
          tip: "# # を使用してエンティティAPIを呼び出すことをサポートしています。例: あなたの携帯電話番号は # sys_phone # ですか?",
          excludeSimilarSentences: "類似文を除外する",
          intelligentRecommend: "スマートおすすめ類似コンテンツ",
          fastTraining: "クイックトレーニング:",
          qualify: "正則",
          recallKeyWord: "リコールキーワード",
          excludeKeywords: "キーワードの除外",
          excludeKeywordsInfo1:
            "除外キーワード、正則はすべての論理より優先して有効で、除外に命中すればこの意味ラベルに命中しません。",
          keyWordInfo1:
            "リコールキーワードは一行ごとに複数記入をサポートしています。英語のカンマで区切り、複数記入した場合、ロジックはすべてヒットします。",
          keyWordInfo2:
            "リコールのキーワードは複数の行を記入することをサポートしています。複数の行を記入する時、ロジックはヒットのいずれでも成立します。",
          recallRegular: "正則をリコールする",
          excludeRegular: "正規を除外する",
          similaSentences: "類似文を除外する",
          simim: "類似度",
          keyWordInfo3:
            "類似文を除外することはすべての論理より優先的に有効で、類似文を除外するとその意味ラベルはヒットせず、デフォルトの類似度は80% で、類似度が大きいほど類似度が類似している。",
          keyWordInfo4:
            "ルールは正規に似ており、デフォルトでは開始と終了が存在します。中括弧で使用する必要はありません。たとえば、 [XXXX以外]",
          keyWordInfo5:
            "高度なモジュールの機能は、通常、操作に詳しい専門家のために用意された実験機能で、どのように操作すべきか分からない場合は、このセクションを変更しないことをお勧めします。",
          keyWordInfo6:
            "文を越えて検出する機能は、意味ラベルが文を越えて検出する能力を与えている。",
          keyWordInfo7: "最大同一ロール連続の",
          keyWordInfo8: "異なる役割を無視する文の長さは以下です",
          keyWordInfo9: "文字の文は文を越えて検査します。",
          keyWordInfo10: "最長の文を越えた限界文の長さは以下である",
          keyWordInfo11: "類似 % 以上を除去する",
          keyWordInfo12: "類似度は以上である",
          keyWordInfo13:
            "% の類似文は類似マッチされます (デフォルトの類似度は80%)",
          keyWordInfo14:
            "類似した重さは文の類似度に基づいてマッチングし、類似しすぎる文を除去して検査効率を高めることを提案する。",
          keyWord: "キーワード",
          entity: "実体",
          ruleField: "概念語",
          customRule: "話術ルール設計",
          fanhua: "似たような質問",
          test: "テスト",
          testOk: "ヒットテスト",
          senior: "詳細設定",
          seniorList: "文をまたぐ検査",
          up: "有効化",
          down: "閉じる",
          ju: "文",
          oneWord: "文字",
          simimNo: "重さに似ている",
          seniorField: "類似度しきい値",
          save: "保存",
          cancel: "キャンセル",
          specialElements: "特殊要素",
          logicRule: "ロジックルール",
          every: "複数回マッチ",
          recallOk: "ヒットリコール語:",
          recallNo: "ヒットしていないリコール語",
          ruleOk: "ヒットリコール正則",
          ruleNo: "正規にヒットしていません",
          rularOk: "ヒットルール",
          rularNo: "ミスルール",
          infoOk: "ヒット標準文または類似文:",
          infoNo: "ヒットしていない標準文または類似文",
          fieldOk: "ヒット除外類似文:",
          fieldNo: "ミスマッチ排除類似文",
          excludeRuleNo: "不正除外正規",
          excludeRuleOk: "ヒット除外正規",
          excludeKeywordNo: "ミスミス除外キーワード",
          excludeKeywordOk: "ヒット除外キーワード",
          update: "更新に成功しました",
          fanhuaList: "汎化ページに提出されました。",
          wordList:
            "トレーニングリストが空白のため、汎化リストに提出できません",
          start: "スタート",
          end: "終了",
          keyWordInfo15:
            "汎化リストに類似フィールドが存在する (高度構成は類似した再制約)",
          saveSuccess: "保存に成功しました",
          errInfo: "未入力のフィールドがあるか正しく入力されていません",
        },
        table: {
          deepTraining: "ディープトレーニング {0} ホイール",
          fastTraining: "クイックトレーニング",
        },
      },
      intelligentCollection: {
        title: "スマート収集",
        classification: "グループリスト",
        new: "新規収集",
        table: {
          name: "フィールド名",
          props: "顧客属性",
          type: "フィールドタイプ",
          desc: "フィールドの説明",
        },
        detail: {
          isCustomerProps: "顧客属性に保存",
          customerPropsHelper:
            "保存後、収集した情報はお客様情報に更新されます。保存しない場合、顧客情報に更新されません",
          name: "フィールド名",
          type: "フィールドタイプ",
          text: "テキスト",
          desc: "フィールドの説明",
          descPlaceHolder: "このフィールドの情報収集の用途を説明します",
          condition: "条件",
          logic: "ルール",
          method: "採集方式",
          message: "情報の内容",
          range: "抽出範囲",
          entity: "抽出対象物",
          base: "基本情報",
          collect: "情報収集",
        },
      },
      intelligentTags: {
        title: "スマートラベル",
        sessionLabelTitle: "会話シーン",
        keyEventsTitle: "キーイベント",
        customerLabelTitle: "顧客ラベル",
        addClassification: "ラベル分類を新規作成します",
        defaultCategoryName: "デフォルト分類",
        index: {
          createTag: "新規ラベル",
          createEvent: "新規作成イベント",
          editTagClassification: "ラベル分類の編集",
          selectTagClassification: "ラベルの分類を選択します",
          editTagGrounp: "グループの編集",
          callTimeError:
            "選択した通話時間/会話開始時間の範囲は1ヶ月 (31日) を超えることはできません",
          callRecordCountError:
            "記録件数は0です。通話・会話時間を選択し直してください",
          selectItmeCountError:
            "一括操作を実行するスマートラベルを選択してください",
          redoSuccess:
            "再実行に成功しました。タスクセンターで確認してください。",
          redoError: "再実行に失敗しました。後で再試行してください",
          timeError: "照会期間は1ヶ月 (31日) を超えることはできません",
          categoryName: "分類名称",
          categoryColor: "分類色",
          newTagClassification: "新規ラベル分類",
          newEventClassification: "分類の新規作成",
          tagClassification: "ラベル分類",
          eventClassification: "イベント分類",
        },
        detail: {
          new: "新規スマートラベル",
          edit: "スマートラベルの編集",
          tagName: "名称",
          tagDesc: "説明",
          category: "分類",
          tagStatus: "有効性",
          operator: "オペレータ",
          rule: "ルール",
          labelClassAtion: "ラベルタイプ",
          labelClassAtionTip1: "タグ付け: ルールに基づくタグ付け",
          labelClassAtionTip2:
            "カテゴリータグ: モデルトレーニングに基づくタグ付け",
          group: "グループ分け",
          checkLabel: "分類を選択",
          warn: "アラート",
          warnTips:
            "アラートをオンにすると、セッションがこの重要なイベント・ルールを満たしているときに、指定されたユーザーにメッセージが表示され、to-doでアラートを受け取ることができます",
          warnError: "アラーム指定者を選択してください",
        },
        subTitle: {
          common: "スマートセッションラベル",
          wecom: "企業マイクロラベル",
        },
      },
      customerPortrait: {
        title: "顧客画像",
        index: { import: "顧客画像のインポート" },
        searchPlaceHolder: "顧客ID、ニックネーム、電話番号",
        callCount: "通話数:",
        callDuration: "総通話時間:",
        dialogCount: "総会話件数:",
        keyWordsStatistics: "キーワード統計 (Top 20 & 注目ワード)",
        keyWordsCount: "({0})",
        businessTag: "業務ラベル",
        keyWordsCloudImg: "キーワード雲図",
        customerEmotionTrend: "顧客感情の変化傾向",
        notFoundCustomer: "関連する顧客が検索されていません",
        noKeyWords: "関連キーワードが見つかりません",
        noBusinessTags: "関連業務ラベルが見つかりません",
        getKeyWordsStatisticsDataError: "顧客キーワード統計照会に失敗しました",
        getBusinessTagsStatisticsDataError: "顧客業務ラベル照会に失敗しました",
        personCustome: "顧客情報を照会していません",
        notimeInfo: "感情情報が見つかりません",
        timeList: "日付範囲",
        operator: "スマートラベル",
        placeHolder: "選択してください",
        placeOperator: "スマートラベルを選択してください",
        search: "照会",
        reset: "リセット",
        searchHolder: "検索",
        call: "通話",
        text: "会話",
      },
      sentimentAnalysis: { title: "世論発見" },
      saleSentimentAnalysis: { title: "世論分析" },
      dataPreprocess: {
        title: "データ前処理",
        index: {
          createData: "新規データ",
          selectDataSource: "データソースの選択",
          selectStatus: "有効性を選択する",
          callTimeError:
            "選択した通話時間/会話開始時間の範囲は1ヶ月 (31日) を超えることはできません",
          callRecordCountError:
            "記録件数は0です。通話・会話時間を選択し直してください",
          selectItmeCountError: "一括操作を実行するデータを選択してください",
          redoSuccess:
            "再実行に成功しました。タスクセンターで確認してください。",
          redoError: "再実行に失敗しました。後で再試行してください",
        },
        detail: {
          new: "新規データ",
          edit: "データの編集",
          name: "名称",
          desc: "説明",
          dataSource: "データソース",
          status: "有効性",
          entity: "抽出対象物",
          operator: "オペレータ",
          rule: "ルール",
        },
      },
      connectedByConversation: {
        title: "会話を通じて関連付ける",
        list: {
          title: "会話関連機能",
          info: "会話関連機能は、デフォルトのルールに従って、異なる通話と会話を関連付けることができます",
          rule: "会話関連ルール",
          source: "データソースを選択してください",
          classification: "分類を選択してください",
          choose: "選択してください",
          input: "入力してください",
          save: "保存",
          cancel: "キャンセル",
          fieldwei: "ビット",
          fieldNot: "未入力フィールドがあります",
          fiedldlengthNo: "フィールドの長さが制限を超えています",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip: "キーワードは複数設定できます。カンマで区切ります。",
          applyRole: "役割の検出",
          preOperator: "前置きオペレータ",
          applyOperatorScope: "検査範囲",
          addOperator: "オペレータを追加する",
          noPreOperator: "なし",
          from: "第",
          to: "文から第まで",
          end: "文",
          testRegular: "テスト正規表現",
          test: "テスト",
          matchSuccess: "マッチ成功",
          matchError: "マッチに失敗しました",
          querySementicTags: "意味ラベルを選択します",
          reQuerySementicTags: "セマンティックラベルの再選択",
          sementicTag: "意味ラベル",
          standardSentence: "標準文",
          similarity: "類似度",
          actions: "操作",
          noFitTag: "適切なラベルが見つかりませんでした",
          addSemanticTag: "新しい意味ラベルを追加します",
          pleaseEnterRegular: "正規表現を入力してください",
          pleaseEnterRegularText: "検証が必要な内容を入力してください",
          newAssociateComponentTip:
            "値が数値型の場合は、以前のバージョンの機能を使用します",
        },
        filterManage: {
          noValueTip: "データなし",
          allCall: "すべての通話",
          allDialogue: "すべての会話",
          allWechat: "すべてのセッション",
          getFiltersListFailed:
            "フィルタリストの取得に失敗しました。後で再試行してください",
          getFilterDetailFailed:
            "フィルタの詳細の取得に失敗しました。後で再試行してください",
          getAllCountFailed:
            "通話/会話の合計件数の取得に失敗しました。後で再試行してください",
        },
        listManage: {
          searchPlaceHolder: "キーワードを入力して検索してください",
          filterConditions: "フィルタ条件",
          records: "記録",
          getRecordsFailed:
            "記録の取得に失敗しました。後でやり直してください。",
        },
        miniListManage: {
          getRecordsFailed:
            "記録の取得に失敗しました。後でやり直してください。",
        },
        applyTemplates: {
          title: "適用品質検査テンプレート",
          collectionTitle: "収集テンプレートの適用",
        },
        recordDetail: {
          showWaveAudio: "録音を表示",
          getDetailFailed: "詳細の取得に失敗しました。後で再試行してください。",
          getSmartTagsFailed:
            "スマートタグの取得に失敗しました。後で再試行してください",
          getTemplatesFailed:
            "適用テンプレートの取得に失敗しました。後で再試行してください",
        },
        changeList: {
          sampleStatus: "抽出検査状態",
          sampleAssignInspector: "抽出検査員を分配する",
          reviewAssignInspector: "審査員を分配する",
          read: "既読",
          distributivePerson: "分配者",
          totalScore: "総得点",
        },
        qualityCheckScore: {
          totalScore: "総得点",
          actualScore: "得点",
          actualGrade: "評価",
          appeal: "申し立て",
          remark: "備考",
          manualRemark: "マニュアルノート",
          none: "品質検査詳細データはありません",
          interactiveTip:
            "インタラクティブ識別を使用して、お客様のFAQの使用状況を検出します",
          interactiveIsEmptyTip:
            "インタラクティブ認識内容が検出されませんでした",
        },
        semanticMark: {
          semanticContent: "付け文",
          markedSimilarTitel: "類似意味ラベル",
          markedExcludeTitle: "類似意味ラベルを除外しました",
          taskTagTitle: "タスク関連意味ラベル",
          similarTagTitle: "類似意味ラベルを識別する (類似度>40% 、Top 20)",
          delete: "削除",
          markSimilar: "マークが似ています",
          markExclude: "類似を除外する",
          none: "なし",
        },
        selectCallTime: {
          callTime: "通話時間/セッション開始時間",
          callTimeRemind:
            "過去1年間に1か月 (31日) 以下の会話履歴を最大で選択できます",
          callRecordCount:
            "通話/かいわ会話きろく記録のごうけい合計{0}をせんたく選択しました。",
          getCallRecordError:
            "記録の取得に失敗しました。後でやり直してください。",
        },
      },
      informationEntities: {
        title: "情報エンティティ",
        index: { import: "情報エンティティのインポート" },
        detail: {
          new: "情報エンティティの新規作成",
          edit: "情報エンティティの編集",
          name: "名称",
          apiName: "API名",
          activeFlag: "有効性",
          remark: "説明",
          logicExp: "実体正則",
          sentenceList: "実体の内容",
          tip: "エンティティの内容は、スペースで区切られた複数の入力をサポートしています。",
          remarkLengthError: "説明の長さは255を超えることはできません",
          nameLengthError: "名称の長さは40を超えることはできません",
          entityDuplicateError: "対象物の内容が重複しています",
        },
      },
      interactiveRecognition: {
        title: "インタラクティブ識別",
        classify: "分類",
        addClassify: "分類を追加",
        editClassify: "分類の編集",
        index: { import: "インタラクティブ認識のインポート" },
        detail: {
          new: "新規スマートインタラクション",
          edit: "スマートインタラクションの編集",
          type: "タイプ",
          standardQuestion: "標準問題",
          similarQList: "類似問題",
          exceptSimilarQList: "類似問題を排除する",
          category: "分類",
          targetType: "問題検出目標",
          status: "有効性",
          standardAnswer: "標準的な答え",
          similarAList: "似たような答え",
          wrongAList: "間違った答え",
        },
      },
      businessAnalysis: {
        title: "業務分析",
        tip1: "業務トレンド分析: 縦軸は通対話数、つまり条件を満たす通対話の合計数、横軸は時間",
        tip2: "関連性分析: 複数の指標の関連性状況を分析し、マウスの懸濁表示関連性データ状況、データ量、関連性、重複率",
        tip3: "転化分析: 複数の指標の間で相互の転化関係を形成することができます。転化の判定基準は同じ通話/対話で、二つの異なる指標に命中すれば、この二つの指標には転化関係があると考えられます。",
      },
      knowledgeBase: {
        title: "知識リポジトリ",
        list: {
          add: "サブ分類の追加",
          delete: "分類の削除",
          categoryName: "分類名称",
          createSuccess: "サブカテゴリの作成に成功しました",
          keyWord: "キーワード",
        },
        detail: {
          type: "タイプ",
          status: "ステータス",
          classification: "分類",
          standardQuestion: "標準問題",
          answerDetectionRange: "解答検査範囲",
          standardAnswer: "標準的な答え",
        },
      },
      callManage: {
        title: "通話録音フィルタ",
        listTitle: "通話管理",
        detailTitle: "通話詳細",
      },
      dialogueManage: {
        title: "会話テキストフィルタ",
        listTitle: "会話管理",
        detailTitle: "会話の詳細",
      },
      weChatManage: {
        title: "企業微信会話フィルタ",
        listTitle: "企業のwechatセッション管理",
        detailTitle: "企業のwechatセッションの詳細",
      },
      speechRecognition: {
        title: "音声認識",
        asr: "音声認識",
        asrRemind:
          "このスイッチをオフにすると、音声テキスト変換機能と機械品質検査機能が無効になります",
        asrConfig: "ASRフィルタリング",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "有効化すると、IMのASR変換が実行されます",
        asrConfigRemind:
          "有効にすると、条件に適合した通話のみASRコンテンツが実行されます",
        correction: "スマート誤り訂正",
        correctionRemind:
          "有効にすると「 <a href = '/site/semantic-integrated/smart-words-library/professional-words' target =' _ blank'> スマート辞書-専門名詞 </a> 」が使用されますの内容を強制エラー訂正変換します。",
        correctionConfig: "誤り訂正フィルタ",
        advancedSettings: "詳細設定",
        advancedSettingsTip:
          "指定したデータに対して指定したASRモデルを選択します。",
        mandarinASRModel: "標準語ASRモデル",
        cantoneseASRModel: "広東語ASRモデル",
        englishASRModel: "英語ASRモデル",
        correctionConfigRemind:
          "有効化すると、下记の条件を満たす通话のみがエラー订正されます",
        customJudgeLogicIsNull: "カスタムロジックは必須項目です",
        redoCorrection: "エラー訂正の再実行",
        callTime: "通話時間を選択します",
        callTimeRemind: "過去1年間に1ヶ月 (31日) 以下の通話記録を選択できます",
        callRecordCount:
          "通話りれき履歴のごうけい合計{0}をせんたく選択しました",
        redoCorrectionSubmit: "実行を提出する",
        callTimeError:
          "選択した通話時間の範囲は1ヶ月 (31日) を超えることはできません",
        callRecordCountError:
          "通話記録件数は0です。通話時間を選択し直してください",
        redoCorrectionSuccess:
          "再誤り訂正に成功しました。タスクセンターで確認してください。",
        redoCorrectionError:
          "再誤り訂正に失敗しました。後で再試行してください。",
      },
      role: {
        title: "ロール管理",
        create: "ロールの新規作成",
        detail: {
          title: "ロール",
          new: "ロールの新規作成",
          view: "ロールの表示",
          edit: "ロールの編集",
          navTabNames: {
            basicInfo: "メニュー権限",
            taskPermissions: "品質検査タスク権限",
            dataPermissions: "データ権限",
          },
          basicInfo: { roleName: "名称", permissions: "権限" },
          taskPermissions: {
            allTask: "すべての品質検査タスク",
            myCreatedTask: "私が作成した品質検査タスク",
          },
          dataPermissions: {
            dataPermissionsConfig: "データ権限設定",
            tips: "このロールは、選択した分類の下のデータを表示できます",
            select: "分類を選択",
            selectPlaceholder: "データの可視権限が必要な分類を選択してください",
          },
        },
      },
      dataMigration: {
        title: "データ移行",
        dataUpload: "データ移転",
        dataDowload: "データ移転",
        error: "エクスポートする品質検査タスクを選択してください",
        list: {
          info: "移転する品質検査タスクを選択してください",
          listInfo:
            "品質検査タスクに関連するデータ (例えば、知能辞書、意味ラベル、情報実体、知能対話など) を移転するかどうかを選択してください。",
          noInfo:
            "他の品質検査以外のデータを移転するかどうかを選択してください",
          ownInfo: "転出タスクのみ",
          everyInfo: "転出タスク関連",
          allInfo: "所有の移転",
          callFilter: "通話フィルタ",
          talkFilter: "会話フィルタ",
          smartTag: "スマートラベル",
        },
        upload: {
          info: "1.アップロードボタンをクリックし、フォーマットに合ったJSONファイルをアップロードします",
          uploadSuccess: "アップロードに成功しました",
          uploadError: "アップロードに失敗しました",
        },
      },
      dataAnalysis: {
        businessStatistics: "業務統計",
        qualityEffect: "品質検査の健康度",
        qualityAnalysis: "品質検査分析",
        generalInspecitionReport: "共通レポート",
        fullAmountWork: "品質検査員の仕事量",
        intelligentTagsAnalysis: "ラベル分析",
        saleIntelligentTagsAnalysis: "スマートラベル分析",
        generalInspecitionReportTip:
          "データ総量: 現在制限されている時間とタスク範囲内、通話と会話データの総量を集計し、その中で知能品質検査データ数 & パーセント、人工抽出検査データ数 & パーセント、クレーム量再検査率と時間傾向図",
        groupTest: "グループ分けテスト",
      },
      uploadDownloadList: {
        title: "タスクセンター",
        tabs: {
          upload: "アップロードインポート",
          download: "エクスポートのダウンロード",
          taskCenter: "タスク実行",
        },
      },
      uploadDownloadModel: {
        title: "アップロードしてダウンロードします",
        list: {
          clear: "クリア",
          success: "成功",
          fail: "失敗",
          hand: "進行中",
          noInfo: "データがありません",
        },
      },
      billingCenter: {
        title: "課金センター",
        export: "エクスポート",
        limit: "リマインダー限度額",
        tips: "課金結果は四捨五入によってずれるので、参考にしてください",
        dashboard: {
          remainingQuota: "現在の余剰クォータ",
          usedQuota: "累計使用済クォータ",
          voiceQuality: "音声品質検査:",
          voice: "音声:",
          textQuality: "テキスト品質検査:",
          text: "テキスト:",
          hour: "時間",
          tenThousandHour: "万時間",
          day: "日",
          cutoff: "締め切り",
          warning:
            "(余剰クォータが不足しているので、できるだけ早くチャージしてください)",
          isClosed: "(オフ状態)",
        },
        table: {
          quotaRecord: "クォータ記録",
          timeFilter: "日付を選択:",
          search: "照会",
          reset: "リセット",
          id: "シリアルナンバー",
          type: "タイプ",
          qualityType: "品質検査タイプ",
          quotaChange: "変動",
          surplus: "残高",
          changeTime: "時間",
          voiceQuality: "音声品質検査",
          textQuality: "テキスト品質検査",
          sizeChangeText: "ページごとに表示",
          used: "を使う",
          give: "付与",
          deduction: "控除",
          callTime: "通話時間",
          count: "処理データ量",
          page: "合計 {0}条、 {1} ページ",
        },
        limitModal: {
          call: "音声品質検査のクォータが",
          callFix: "時間リマインダー",
          dialogue: "テキスト品質検査の残り時間は未満です",
          dialogueFix: "日に注意する",
        },
      },
      messageCenter: {
        replyTitle: "私の",
        answerTitle: "@ 私の",
        shareTitle: "私の",
      },
      columnsManage: { title: "品質検査フィールド管理" },
      recordUpload: {
        list: {
          task: "品質検査タスク",
          user: "オペレータ",
          uploadTime: "アップロード時間",
          endTime: "終了時間",
          total: "アップロード総量",
          success: "品質検査に成功",
          failed: "品質検査に失敗しました",
          progress: "品質検査の進捗",
          inProgress: "進行中",
          finished: "完了しました",
        },
        steps: {
          uploading: "録音ファイルのアップロード",
          baseInfo: "基本情報を記入する",
          start: "品質検査を開始します",
        },
        uploadComponent: {
          dragger: "ドラッグしてファイルを追加する",
          or: "または",
          selectFile: "ファイルを選択",
          des: "Wav、mp3形式に対応し、サンプリングレートが8000Hz、16ビットのファイル",
          limit1: "一度に最大アップロード",
          limit2: "個のファイル。各ファイルサイズはを超えません",
          uploading: "アップロード中",
          finished: "アップロード完了",
          success: "成功",
          failure: "失敗",
        },
        baseInfo: {
          task: "品質検査タスクの選択",
          type: "録音形式を選択します",
          agentSide: "カスタマーサービスのルート",
          excel: "随行データをアップロードする",
          checkFileInfo: "ファイル情報の検証",
          excelFileError: "随行データをアップロードしてください",
          checking: "検証中….",
          reCheck: "再検証",
          success: "検証に成功しました",
          excelUoloadFail: "随行データのアップロードに失敗しました",
          tips: {
            tip1: "1.ecxelの録音ファイル名はアップロードした録音名と一致し、重複してはいけません。",
            tip2: "2.システムは録音ファイル名、通話IDで再検査し、重複すると品質検査に失敗する",
            tip3: "3.ダウンロードしたexcel録音テンプレートは、フォント色が赤のものが必須フィールドです",
            tip4: "4.時間タイプのフィールド形式は、yyyy- mm-ddhh: mm:ss、たとえば2021-04-20 17:36:30です",
          },
        },
        title: "ローカル録音アップロード",
        checkTitle: "録音品質検査",
        start: "品質検査を開始します",
        disabledTips: "録音ファイルをチェックしてから品質検査を行ってください",
        tips: "同名の録音ファイルは品質検査結果と基本情報をカバーする",
        tips1: "ファイルをここにドラッグするか、アップロードをクリックします",
        tips2: "サポート",
        tips3: "フォーマット",
        type: "wav、mp3",
        tips4: "一度に最大アップロード",
        tips5: "個のファイル。各ファイルサイズはを超えません",
        tipsOverSize:
          "現在キューがいっぱいです。キューからファイルを削除してからアップロードしてください",
        size: "500M",
        count: "20",
        uploaded: "録音ファイルをアップロードしました",
        uploading: "({0}%)",
        uploadFinished: "(アップロード完了)",
        bottomTitle: "録音情報収集:",
        recordType: "録音形式を選択:",
        double: "デュアルチャンネル通話録音",
        single: "シングルチャンネル通話録音",
        recordBaseInfo: "録音の基本情報をアップロードするには:",
        addBtn: "ファイルを追加",
        downloadTemplate: "テンプレートのダウンロード.excel",
        checkRecord: "録音情報の検証",
        checking: "検証中….",
        reCheck: "再検証",
        checkAll: "全選択",
        cancelCheckAll: "全選択をキャンセル",
        delete: "削除",
        deleteLotSize: "一括削除",
        agentChannel: "カスタマーサービスのルート:",
        left: "左チャンネル",
        right: "右通路",
        donotKonw: "わかりません",
        mission: "品質検査タスクの選択:",
        uploadFail: "アップロードに失敗しました",
        repeat: "重複ファイル",
        overCount: "ファイル数が制限を超えています",
        overSize: "ファイルサイズが制限を超えています",
        uoloadFail: "クラウドへのアップロードに失敗しました",
        formatError: "フォーマットエラー",
        seccess: "検証に成功しました",
        fail: "検証に失敗しました",
        recordChecking: "録音品質検査中です。しばらくお待ちください… は...",
        recordCheckSuccess: "録音品質検査完了",
        recordCheckFail:
          "録音の品質検査に失敗しました。録音が重複しているか無効であるかを確認してください",
        backToIndex: "アップロードリストに戻る",
        recordCheckIsStarted:
          "現在、品質検査タスクが実行中です。現在のタスクが終了したら、新しいタスクを開始してください",
      },
      customerServicePortrait: {
        title: "カスタマーサービス画像",
        tip1: "文長分布律: 文長の分布状況は、文長の字数に応じて、段階的に統計を行い、顧客サービスだけを計算する",
        tip2: "語速分布律: 語速の分布状況は、語速(字/毎分) に応じて段階的に統計し、コールだけを計算する",
        tip3: "応答時間分布律: 応答時間の分布状況は、コールが顧客に応答する状況だけを計算する",
        tip4: "品質検査コンプライアンス: 品質検査ポイントのコンプライアンス率",
        tip5: "品質検査タスク平均点: 品質検査タスク平均点",
        contol: {
          date: "日付を選択:",
          today: "今日は",
          thisMonth: "今月",
          lastMonth: "先月",
          thisQuarter: "今四半期",
          lastQuarter: "前四半期",
          thisYear: "今年は",
          lastYear: "昨年",
          customer: "カスタマーサービス:",
          search: "照会",
          reset: "リセット",
          export: "エクスポート",
          indicator: "指標管理",
          selectPlaceholder: "比較したいカスタマーサービスを選択してください",
          warning: "最大5名のカスタマーサービスを選択して比較します。",
          warning2: "少なくとも1名のお客様を選んで問い合わせてください",
          dataWarning:
            "開始日から366日後のデータのみの照会をサポートしています",
          seccuss: "記録のエクスポートに成功しました",
        },
        echarts: { distributionLaw: "分布律", count: "回数", bout: "次" },
        portrait: {
          ability: "顧客サービス能力次元",
          option: "設定",
          abilityOption: "能力次元の設定",
          placeholder: "入力能力次元キーワード検索",
          save: "保存",
          warning:
            "少なくとも3つの指標を選択して配置してください。指標数は10項目まで選択できます。",
        },
        label: { title: "カスタマーサービス業務ラベル" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "カスタマーサービスのランキング",
        select: {
          date: "日付を選択:",
          today: "今日は",
          thisMonth: "今月",
          lastMonth: "先月",
          thisQuarter: "今四半期",
          lastQuarter: "前四半期",
          thisYear: "今年は",
          lastYear: "昨年",
          group: "カスタマーサービスグループ:",
          groupPlaceholder: "カスタマーサービスグループを選択してください",
          customer: "カスタマーサービス:",
          customerPlaceholder: "カスタマーサービスを選択してください",
          search: "照会",
          reset: "リセット",
          compared: "比較",
          export: "エクスポート",
          column: "列",
          warning: "最大5名のカスタマーサービスを選択して比較します。",
          warning2: "少なくとも一人のお客様を選んでください",
          dataWarning:
            "開始日から366日後のデータのみの照会をサポートしています",
          selected: "選択されたお客様:",
          seccuss: "記録のエクスポートに成功しました",
        },
        compared: {
          title: "カスタマーサービスの選択",
          return: "キャンセル",
          compared: "比較",
          tips: "2 ~ 5名のお客様を選択して比較してください",
          warning: "2 ~ 5名のお客様のみ比較をサポートしています",
        },
        transfer: { leftTitle: "リスト項目", rightTitle: "展示項目" },
        table: {
          page: "合計 {0}条、 {1} ページ",
          actionTitle: "操作",
          action: "表示",
        },
      },
      conceptWord: {
        title: "概念語",
        new: "新規概念語",
        control: {
          search: "検索:",
          searchPlaceholder: "名前またはキーワード検索",
          commit: "照会",
          reset: "リセット",
          import: "インポート",
          export: "エクスポート",
          seccuss: "記録のエクスポートに成功しました",
        },
        modal: { title: "概念語のインポート" },
        table: {
          sizeChangeText: "ページごとに表示",
          page: "合計 {0}条、 {1} ページ",
          edit: "編集",
          delete: "削除",
          request: "削除後は復元できません。削除を確認しますか?",
          cancel: "キャンセル",
          title: {
            conceptWord: "概念語",
            describeWordsNum: "記述語数",
            updateTime: "更新時間",
            status: "有効性",
            effective: "有効",
            invalid: "無効",
            action: "操作",
          },
        },
        detail: {
          title: { new: "新規概念語", edit: "概念語を編集する" },
          name: "コンセプトワード名称",
          namePlaceholder: "概念語名を入力してください",
          effectiveness: "有効性",
          effective: "有効",
          invalid: "無効",
          description: "説明語:",
          descriptionPlaceholder: "説明語を入力してください",
          add: "追加",
          repeat: "重複コンテンツ",
          empty: "説明語は空白にできません",
          recommendEmpty:
            "これ以上推奨記述語はありません。手動で入力して追加してください。",
          recommend: "スマート推奨記述語",
          cancel: "キャンセル",
          change: "グループを変える",
          descriptionSelected: "説明語を追加しました:",
          save: "保存",
          success: "概念語の保存に成功しました",
          warning: "概念語名称は必須項目です",
          warning2: "少なくとも1つの記述語を追加してください",
          submitBtnHandle: {
            title:
              "チェックされた記述語が追加されていない場合、追加されていない記述語は保存されます",
            content:
              "「Ok」をクリックすると、概念語のホームページに戻ります。続行しますか?",
          },
        },
      },
      smartTools: {
        btn: "スマートツール",
        title: {
          similarWords: "関連語発見",
          search: "詞に従う",
          semanticTags: "意味ラベル",
        },
        sentenceSearch: {
          placeholder:
            "キーワードを入力して検索してください。複数のキーワードをサポートしています。",
          more: "全文を見る",
          pageUp: "前のページ",
          pageDown: "次のページ",
          total: "合計 {0} 件の記録",
        },
        similarDiscover: {
          placeholder: "キーワードを入力してください",
          copyed: "コピー済み",
          none: "関連する単語がありません。他のキーワードを試してください。",
        },
        smartTags: {
          searchPlaceHolder: "キーワードまたは文を入力してください",
          createSmartTag: "新規作成",
          createClusteringSmartTag: "{0} 件のデータで新規作成",
          addFromKeyword: "検索内容を類似文に追加します",
          hit: "ヒット",
          similar: "似ています",
          name: "意味ラベル名",
          namePlaceHolder: "セマンティックラベル名を入力してください",
          recallWords: "リコールワード",
          recallWordsPlaceHolder: "リコールキーワードを入力してください",
          recallRegular: "正則をリコールする",
          recallRegularPlaceHolder: "リコールの正則を入力してください",
          rules: "ルール",
          standardSentences: "標準文",
          standardSentencesPlaceHolder: "標準文を入力してください",
          similaSentences: "類似文",
          similaSentencesPlaceHolder: "類似文を入力してください",
          notSaveError: "セマンティックタグを保存してから検索してください",
          add: "追加",
          newPage: "新しいページで開く",
          querryError:
            "汎化リストに類似フィールドが存在する (高度構成は類似した再制約)",
          detailTitle: "意味ラベルの詳細",
        },
      },
      trainingCenter: {
        title: "トレーニングセンター",
        edit: "編集",
        delete: "削除",
        seleteAll: "全選択",
        cancelCheckAll: "全選択をキャンセル",
        add: "追加",
        remove: "削除",
        save: "保存",
        cancel: "キャンセル",
        fastTraining: {
          title: "クイックトレーニング",
          titleDes:
            "迅速な訓練は意味ラベルの急速な汎化が20の類似文に達するのを助けることを目的としている",
          semanticTags: "意味ラベル",
          semanticTagsPlaceholder: "フローキーワード検索を入力してください",
          standardSentence: "標準文:{0}",
          similarSentencesPlaceholder: "類似文を入力して追加または検索します",
          similarSentencesAdd: "追加",
          similarSentencesSearch: "検索",
          similarSentences: "類似文を追加しました",
          trainingCount: "トレーニング数:{0}",
          similarSentencesRecommend: "おすすめの類似文",
          noResp:
            "関連する意味ラベルがありません。キーワードを変更してください。",
          similarSentencesModalTitle: "類似文検索",
          moreInfo: "コンテキストの表示",
          moreInfoBtn: "コンテキスト",
          modalSearchType: {
            all: "すべて",
            customer: "カスタマーサービス",
            client: "お客様",
          },
          repetWarning:
            "同じ類似文が存在します。繰り返し追加しないでください。",
          repetRemoveTip:
            "類似文に重複した内容が保存され、自動的に削除されました",
        },
        trainingHistory: {
          title: "トレーニング履歴",
          select: {
            date: "時間を選択:",
            today: "今日は",
            thisMonth: "今月",
            lastMonth: "先月",
            thisQuarter: "今四半期",
            lastQuarter: "前四半期",
            thisYear: "今年は",
            lastYear: "昨年",
            search: "照会",
            reset: "リセット",
          },
          table: {
            time: "時間",
            user: "オペレータ",
            tag: "意味ラベル",
            similarCount: "類似文表示数量",
            deleteCount: "文の数を削除します",
            page: "合計 {0}条、 {1} ページ",
          },
        },
        classifiedTraining: {
          title: "分類トレーニング",
          tagsGrounp: "ラベルグループ",
          placeHolder: "選択してください",
          cellTime: "時間を選択します",
          search: "照会",
          reset: "リセット",
          classifiedFlag: "分類訓練の状況",
          num: "数値",
          gas: "パーセント",
          noData: "未分類トレーニング",
          noDataHeight: "未分類データが高いため、トレーニングが必要です。",
          noDataSmall:
            "未分類データが高いので、トレーニングを行うことをお勧めします",
          noDataDays: "前回実行 {0} 日前",
          training: "トレーニング",
          start: "スタート",
          addClass: "分類を増やす",
          custom: "高度なフィルタリング",
          marked: "ラベル付け済みデータ正誤",
          markedHeight:
            "すでに {0} 日間、正誤が行われていません。正誤を提案します。",
          markedSmall:
            "すでに {0} 日は正誤が行われていません。早急に正誤が必要です。",
          mark: "勘違い",
          dataCheck: "分類データ検証済み",
          dataCheckSmall: "K-Lの分散度は0.5を超え、訓練を提案する",
          dataCheckHeight: "K-Lの分散度は0.8より高く、訓練が必要である",
          check: "検証",
          history: "ラベル履歴",
          historyTime:
            "毎日の寸法と修正は午前2時に自動的にモデルトレーニングを実行します",
          today: "今日のマーク",
          article: "条",
          none: "なし",
          historyCheck: "履歴合計マークアップ",
          look: "記録を見る",
          historyluate: "履歴データ再計算",
          startluate: "クレーン計算",
          classData: "分類データ",
          classStatus: "トレーニングの進捗",
          next: "次条",
          prev: "前の条",
          finish: "トレーニング完了",
          stop: "トレーニングを終了する",
          classDetail: "トレーニングの詳細",
          trainingNone: "トレーニング可能なスマートラベルはありません",
        },
        classifiedTrainingHistory: {
          title: "トレーニング履歴",
          time: "時間を選択:",
          tag: "ラベルタイプ:",
          user: "オペレーター:",
          data: "データソースを選択:",
          search: "照会",
          searchBar: "検索:",
          searchBarPlaceholder: "通話ID/会話IDを入力して検索してください",
          reset: "リセット",
          export: "エクスポート",
          all: "すべて",
          select: {
            today: "今日は",
            thisMonth: "今月",
            lastMonth: "先月",
            thisQuarter: "今四半期",
            lastQuarter: "前四半期",
            thisYear: "今年は",
            lastYear: "昨年",
            search: "照会",
            reset: "リセット",
          },
          table: {
            user: "オペレーター",
            date: "操作時間",
            data: "データソース",
            id: "通話ID/会話ID",
            tagType: "スマートラベルグループ",
            tagName: "ラベル名",
            state: "ステータス",
            sizeChangeText: "ページごとに表示",
            page: "合計 {0}条、 {1} ページ",
            call: "通話",
            dialogue: "会話",
          },
        },
        clusteringAnnotation: {
          title: "クラスタラベル",
          left: { title: "クラスタリング結果" },
          right: {
            title: "クラスタリング文",
            checkAll: "全選択本ページ",
            tagging: "マークアップ",
            detail: "表示",
            meaningless: "無視する",
            meaninglessTips:
              "無視されたクラスタ結果は後で表示されなくなります。無視しますか?",
            cancel: "キャンセル",
          },
        },
      },
      clientCenter: {
        clientManage: { title: "顧客管理", tabTitle: "コールセンターのお客様" },
        wechatClientManage: {
          title: "企業のマイクロクライアント",
          table: { id: "顧客ID", name: "顧客ニックネーム", tag: "顧客ラベル" },
        },
      },
      collectionRule: {
        title: "収集ルール",
        form: {
          info: "スマートに情報を収集する",
          infoPlaceholder: "分類を選択してください",
        },
        table: {
          name: "フィールド名",
          props: "顧客属性",
          type: "フィールドタイプ",
          desc: "フィールドの説明",
        },
      },
      dataAdd: {
        title: "データ追加",
        types: { auto: "自動追加", manual: "手動追加" },
      },
      wechatStaff: {
        table: {
          name: "氏名",
          part: "所属部署",
          phone: "携帯番号",
          email: "メールアドレス",
          wechartId: "企業wechat ID",
          status: "在職状態",
        },
        info: "まずデータを同期する部門を選択してください",
        synSuccess: "同期に成功しました",
        init: "初期化",
        syn: "同期",
      },
      appealHistory: {
        appealTime: "申し立て時間",
        cancelTime: "キャンセル時間",
        reviewTime: "再確認時間",
        inspector: "品質検査員",
        result: "申し立て結果",
        complainResult:
          "全部で {0} 個の品質検査項目を訴えて、成功して {1} 個を訴えて、 {2} 個を却下します。",
        reason: "訴えの原因",
        rejection: "却下理由",
        cancel: "キャンセル",
        tip: "苦情を取り消しますか?",
      },
      appealSdk: {
        time: "通話時間",
        externalIdCall: "通話記録ID",
        externalIdText: "会話記録ID",
        customerNickName: "顧客ニックネーム",
        readStatus: "ステータスの参照",
        status: "申し立てステータス",
        isManual: "人工品質検査ですか",
        complainButtonInfo:
          "{0} 回の訴えを許可します。すでに {1} 回訴えました。残りの {2} 回を訴えられます。 {3} 前に訴えることができます。",
        submit: "申し立てを提出する",
        complain: "申し立て",
        allComplain: "申し立てすべて",
        pendingSubmit: "提出待ち",
        taskIds: "品質検査タスク",
        graded: "評価",
        complainSuccess: "申し立て提出成功",
        appealSdkIndex: { title: "スマート品質検査結果" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "フィルタリスト情報の取得に失敗しました。ネットワーク接続を確認するか、後で再試行してください",
      columns: "列",
      filter: "スクリーニング",
      noSearchResult: "記録が見つかりません",
      pleaseSearch: "フィルタを選択して検索してみてください",
      sift: "スクリーニング",
      create: "新規作成",
      search: "検索",
      createTaskTitle: "タスクの新規作成",
      title: "タイトル",
      deadline: "締め切り時間",
      principal: "担当者",
      enqueueTime: "入隊時間",
      computeError: "計算に失敗しました。しばらくお待ちください",
      sum: "合計",
      max: "最大値",
      min: "最小値",
      avg: "平均値",
      count: "カウント",
      maxDate: "最大値 (最新)",
      minDate: "最小値 (最も古い)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Insightリンク先の取得に失敗しました。後で再試行してください",
      },
      template: {
        learningReminder: "学習リマインダー",
        subscriptionDynamics: "購読動態",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "タスク管理", chaneg: "切り替え" },
        qualityCheckTemplate: {
          text: "品質検査テンプレート",
          collectText: "収集テンプレート",
          condition: { text: "品質検査条件" },
          checkPoint: { text: "ルール設定" },
          automaticAdd: { text: "プロセス自動化" },
          manualAdd: { text: "手動追加" },
          roleConfig: { text: "ロール設定" },
          basicInfo: { text: "基本情報" },
          smartWordsLibrary: { text: "スマート辞書" },
          grade: { text: "品質検査評価" },
          collectionRule: { text: "収集ルール" },
          addData: { text: "データを追加" },
          collectionList: { text: "収集リスト" },
        },
        qualityCheckWorkbench: {
          text: "品質検査作業台",
          wechatText: "分析作業台",
          informationCollectText: "収集作業台",
          qualityInspectionList: {
            text: "品質検査リスト",
            wechatText: "分析リスト",
            informationCollectText: "収集リスト",
          },
          condition: { text: "品質検査リスト (廃棄)" },
          reviewList: { text: "再確認リスト" },
          spotCheckList: { text: "抽出検査リスト" },
          caseBaseList: { text: "ケースライブラリ" },
          testSetList: { text: "品質検査テストセット" },
        },
        statisticsAndReports: {
          text: "統計とレポート",
          wholeReport: { text: "全体レポート" },
          groupReport: { text: "カスタマーサービスグループのレポート" },
          agentReport: { text: "カスタマーサービスのレポート" },
          customerServiceExecution: { text: "カスタマーサービス分析" },
          analysisOverview: { text: "分析の概要" },
          inspectionItemsAnalysis: { text: "品質検査項目分析" },
        },
        wechatQa: {
          text: "企業のwechat品質検査",
          task: { text: "タスクの分析" },
          baseInfo: { text: "基本情報" },
          checkPoint: { text: "解析モデル配置" },
          setting: { text: "企業微信分析配置" },
          automaticAdd: { text: "プロセス自動化" },
          manualAdd: { text: "手動追加" },
        },
        clientCenter: { text: "コールセンター" },
      },
      conditionDictionaries: {
        title: "すべての条件",
        searchDefaultText: "IDまたは名前を入力してください",
        id: "ID",
        name: "名称",
        conditionDetailedShow: "展開",
        conditionDetailedHide: "収納",
        remark: "説明:",
        checkRole: "ロール:",
        operator: "オペレータ:",
        operatorLogic: "ロジック:",
      },
      audioTextView: {
        customer: "お客様",
        agent: "カスタマーサービス",
        unsupportedFormat: "このフォーマットはサポートされていません",
      },
      checkPointClassifications: {
        add: "分類の新規追加",
        name: "名称",
        enterToSave: "Enterキーを押して保存します",
        highestScore: "最高得点上限",
        minimumScore: "最低得点下限",
        defaultMinScore: "デフォルトは0ポイントです",
        minimumScoreTitle: "得点下限",
        beginScore: "総合評価値",
        beginScoreTitle: "開始評価値",
        scoreSetTip:
          "最高得点上限は各得点の合計より小さいので、次の項目を選択できます",
        ignoreExcessSpillover: "オーバーフローを無視する",
        automaticWeightCalculation: "自動重み計算",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "フィールド" },
    checkRange: {
      numberTipOne: "第",
      numberTipTwo: "文",
      numberTipThree: "文を第一にする",
      conditionLimit: "条件制限",
      roleLimit: "ロール制限",
      contextLimit: "文の制限",
      notZero: "文の制限は0の入力をサポートしていません。",
      startMustLessThanEnd: "開始文位置は終了文位置より小さい必要があります",
    },
    waveAudio: {
      play: "再生",
      pause: "一時停止",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "入力してください",
      pleaseEnterRegular: "正規表現を入力してください",
      pleaseEnterGrammar: "文法規則を入力してください",
      pleaseEnterTestText: "検証が必要な内容を入力してください",
      pleaseEnterLoudness: "ラウドネスデシベルを入力してください",
      pleaseEnterRangeAbility: "変化幅を入力してください",
      afterHit: "ヒット後のチェック",
      pleaseEnterContext: "参照文を入力してください",
      wordNumber: "文字数の差",
      intervalSecond: "時間間隔がより大きい",
      minute: "毎分",
      wordUnit: "文字",
      test: "テスト",
      testRegular: "テスト正規表現",
      testGrammar: "テスト文法",
      testGrammarError: "文法テストに失敗しました",
      regularError: "正規表現が間違っています。再入力してください。",
      pleaseEnterSemanticSentence: "意味検査が必要な文を入力してください",
      pleaseEnterAssociate:
        "右側の説明を参照して、随行データ式を構築してください",
      querySemanticTags: "意味ラベルを選択します",
      reQuerySemanticTags: "セマンティックラベルの再選択",
      semanticTags: "意味ラベル",
      standardSentence: "標準文",
      semanticName: "意味ラベル",
      sentence: "標準文",
      similarity: "類似度",
      actions: "操作",
      tipOne: "適切なラベルが見つかりませんでした",
      tipTwo: "新しい意味ラベルを追加します",
      notLessThan: "より小さくない",
      notGreaterThan: "より大きくない",
      ignoreLessThan: "無視は以下です",
      wordUnitSet: "字の文",
      speedWordNotNull: "語速検出の最小文字数と最大文字数は空白にできません",
      speedWordMinValueLessThanMaxValue:
        "語速検査の最低文字数は最大文字数を超えてはいけません。",
      speechGrabbingIntervalTip: "強奪間隔> =",
      speechLengthTip: "言葉の長さを奪う> =",
      msUnit: "ms",
      sUnit: "秒",
      grabIntervalNotNull: "強奪間隔は必須項目です",
      grabLengthNotNull: "言葉を奪う文の長さは空白にできない",
      pleaseSelectEntity: "情報エンティティを選択してください",
      pleaseSelectOperator: "操作を選択してください",
      pleaseSelectBasicInfo: "基礎情報を選択してください",
      pleaseEnterFixedValue: "固定値を入力してください",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "マッチ成功",
      matchError: "マッチに失敗しました",
      mindetectionStatement: "最小検出文",
      conceptWordholder: "概念語を選択してください",
      elementHolder: "要素を入力してください",
      associateMedol: { tabs: { data: "データ", operator: "オペレータ" } },
      repet: "重複",
      bout: "次",
      ignore: "無視は以下です",
      ignoreAffix: "字の文",
      associateData: "随行データ",
      pretreatmentData: "前処理データ",
      reviewTaskData: "タスクデータの再確認",
      taskData: "品質検査タスクデータ",
      businessData: "業務記録",
      functionOperator: "関数オペレータ",
      operation: "演算子",
      notAnyMatch: "問題がない場合:",
      times: "出現回数",
      newAssociateDataTip:
        "値が数値型の場合は、以前のバージョンの機能を使用します",
    },
    conditionGather: {
      cycle: "周期",
      rlue: "ルール",
      delete: "削除",
      sampling: "抽出",
      item: "条",
      percent: "%",
      samplingSame: "コールごとの抽出数は同じです",
      samplingRecheck: "抽出した項目の重複抽出を許可します",
      dateUnit: "番号",
      samplingTip:
        "異なるルールで同じ通話をカバーしても重複して抽出されず、抽出された未検査の記録は再抽出されない。",
      add: "ルールの新規追加",
    },
    ruleGather: {
      title: "ルール",
      screeningCondition: "フィルタ条件",
      addRule: "ルールの新規追加",
      inspector: "品質検査員",
      ruleTip:
        "ルール優先の分配パターンは、上から下へ順番に分配され、重複分配されず、ルール内または任意のルール項目にマッチしない場合はランダムに分配されます",
      moveUp: "上に移動",
      moveDown: "下に移動",
      top: "トップに置く",
    },
    pushRulesGather: {
      title: "ルール",
      delete: "削除",
      newPush: "プッシュの新規追加",
      newRule: "ルールの新規追加",
      sampling: "抽出",
      item: "条",
      percent: "%",
      samplingSame: "コールごとの抽出数は同じです",
      dateUnit: "番号",
      screeningCondition: "フィルタ条件",
      email: "メールアドレス",
      emailPlaceholder: "メールアドレスを入力し、カンマで分割します",
      updateType: "更新タイプ",
      pushDetail: "プッシュ詳細",
      pushUrl: "プッシュURL",
      pushWay: "プッシュタイプ",
      pushMethod: "プッシュ方式",
      pushContentType: "データフォーマット",
      pushData: "プッシュデータ",
      additionalData: "追加データ",
      inspectionType: "品質検査タイプ",
      all: "すべて",
    },
    taskAdditionalData: {
      newData: "データの追加",
      delete: "削除",
      placeholder: "名前を入力してください",
      dataPlaceholder: "内容を入力してください",
    },
    UdeskQaReact: {
      loadSdkError: "SDKのロードに失敗しました。後で再試行してください。",
    },
    gearOptionList: { evaluation: "評価" },
    tableComponentTransfer: { leftTitle: "リスト項目", rightTitle: "展示項目" },
    kmTinymce: {
      link: "リンクの挿入/編集",
      modalTitle: "リンクを挿入します",
      href: "住所",
      text: "表示文字",
      title: "タイトル",
      target: "開く方法",
      none: "なし",
      newWindow: "新しいウィンドウで開きます",
      buttonStyle: "ボタンスタイル",
      close: "閉じる",
      open: "オン",
      answerMessage: "ジャンプ/メッセージ送信",
      answerMessageTitle: "メッセージ送信ボタン",
      answerMessageText: "表示文字",
      answerMessageContent: "メッセージの内容",
      answerMessageButtonStyle: "ボタンスタイル",
      answerMessageTips:
        "注意: メッセージ送信ボタンはwebチャネルのみをサポートしています。慎重に設定してください。",
      answerSwitchStaff: "ジャンプ/手動への移行",
      answerSwitchStaffTitle: "手動ボタン",
      answerSwitchStaffText: "表示文字",
      answerSwitchStaffRule: "ボタンルール",
      answerSwitchStaffButtonStyle: "ボタンスタイル",
      answerSwitchStaffTips:
        "注意: 手動変換ボタンはwebチャネルのみをサポートしています。慎重に設定してください。",
      textButton: "文字ボタン",
      borderButton: "枠付きボタン",
      message: "メッセージを送信",
      switchStaff: "人工転換",
      messageError: "ボタンの内容は10文字を超えることはできません",
      messageIsEmptyError: "ボタンの内容は必須項目です",
      okText: "確定",
      cancelText: "キャンセル",
      video: "動画",
      uploadVideo: "動画をアップロード",
      uploadText: "MP4形式のみ対応、サイズは20m以下",
      videoEmptyOrUploading: "動画が空またはアップロード中です",
      customEmoji: "表情",
    },
    massageConfiguration: {
      pushTarget: "目標を通知する",
      customizePushTarget: "プッシュターゲットのカスタマイズ",
      sysUser: "現在のシステムユーザー",
      sysRole: "現在のシステムロール",
      emailPushTarget: "送信先",
      targetEmailsPlaceholder:
        "メールアドレスを入力し、英語のカンマで区切ります",
      subject: "メール名",
      SDKID: "SDK識別ID",
      content: "お知らせ内容",
      sdkPushType: "プッシュプラン",
      sdkPushURL: "プッシュURL",
      pushData: "プッシュデータ",
      sdkPushWay: "プッシュ方式",
      dataType: "データフォーマット",
      additionalData: "追加データ",
      name: "メッセージ名",
      filter: "フィルタ条件",
      triggerType: "トリガー機構",
      pushCycle: "定時周波数設定",
      pushContent: "報告内容",
      qualityList: "品質検査結果リスト",
      designated: "指定者",
      messageType: "メッセージタイプ",
    },
    taskCard: {
      template: {
        cancelCollection: "コレクションをキャンセルします",
        collection: "コレクション",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "標準文:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "コレクション",
          calls: "つの通話",
          createdBy: "作成者:",
          createdOn: "作成時間:",
        },
        fileItem: {
          uIProvidesIcon: "Ui提供アイコン",
          minute: "分",
          callClip: "通話クリップ",
          start: "スタート",
          End: "終了",
          employees: "スタッフ:",
          Customer: "お客様:",
          sessionTime: "セッション時間:",
          reasonForRecommendation: "推奨理由:",
          addedBy: "追加者:",
          addTime: "時間を追加:",
        },
        modalAddFile: {
          caseTitle: "ケースタイトル",
          addSomeClipsToTheCaseLibrary:
            "一部のフラグメントをケースライブラリに追加します",
          addToCaseBase: "ケースライブラリに追加",
          storedIn: "に保存",
          pleaseSelect: "選択してください",
          reasonsForRecommendation: "推奨理由",
          pleaseEnter: "入力してください",
        },
      },
      shareModal: {
        index: {
          customer: "お客様:",
          employees: "スタッフ:",
          timeliness: "時効性:",
          recordingLinkValidWithinDays: "日内録音リンク有効",
          sharingInstructions: "説明を共有します",
          shareSuccess: "シェア成功!",
          cancel: "キャンセル",
          share: "シェア",
          copySucceeded: "コピー成功!",
          copyShareLink: "シェアリンクをコピー",
          shareWithColleagues: "同僚にシェア",
          shareWithCustomers: "お客様にシェア",
          staff: "スタッフ",
          selectColleagues: "同僚を選ぶ",
          pleaseSelectColleaguesToShare: "共有する同僚を選択してください",
          pleaseEnterTheSharingDescription: "シェアの説明を入力してください",
          taskName: "タスク:",
          recordingShareValidWithinDays: "日内シェアリンクが有効です",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "このファイルが見つかりません!",
          sale: "販売:",
          customer: "お客様:",
          topicDetection: "話題テスト:",
          keyEvents: "重要なイベント:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "同僚を選ぶ",
          helpContent: "ヘルプ内容",
          visibleRange: "可視範囲",
          seekCounseling: "指導を求める",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "アドバイスを求める", concatText: "向" },
      },
      customerInsight: {
        index: {
          customerInformation: "顧客情報",
          customerInsight: "顧客インサイト",
          customerLabel: "顧客ラベル",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "話術のオススメ",
          objectionHandlingScripts: "異議処理の話",
          productSellingPointScripts: "製品のセールスポイント",
          knowledgePointRecommendation: "知識ポイントのオススメ",
          relatedKnowledgePoints: "関連知識点",
          recommendedCases: "推奨事例",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "話術のオススメ",
          caseRecommendation: "ケース推奨",
          hit: "ヒット",
          miss: "ヒットしません",
          supervisionItem: "監督項目",
          implementation: "実行状況",
          reInspection: "品質検査のやり直し",
          supervisionItemSettings: "監視項目の設定",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "電話通話",
          enterpriseWeChatCall: "企業のマイクロ通話",
          enterpriseMicroConversation: "企業マイクロセッション",
          open: "オン",
          close: "閉じる",
          conversationScenario: "会話シーン",
          monitorConversationChannels: "会話チャネルを監視する",
          permanentlyValid: "永久有効",
          permanentlyInvalid: "永久無効",
          customValidity: "カスタム有効期間",
          termOfValidity: "有効期限",
          newRating: "新規評価",
          whenConversation: "セッション",
          hit: "ヒット",
          miss: "ヒットしません",
          time: "時",
          rated: "評価は",
          qualified: "合格",
          unqualified: "不合格",
          modifiedSuccessfully: "修正に成功しました",
          classificationName: "分類名称",
          supervisionObject: "監督対象",
          entryintoforceTime: "発効日",
          supervisionItemRating: "監督項目の評価",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "監督項目の評価が正しいかチェックしてください",
          ratingRules: "格付けルール",
          settingOfRatingRulesForSupervisionItems: "監督項目評価ルール設定",
        },
      },
      reInspect: {
        index: {
          keyEvents: "キーイベント",
          supervisionItem: "監督項目",
          conversationScenario: "会話シーン",
          customerLabel: "顧客ラベル",
          reDetectionRules: "ルールの再検出",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "録音をダウンロードする",
          downloadText: "テキストのダウンロード",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "画像をアップロードする",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "サイズが2048kb以下の画像を選択してください",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "または",
            and: "と",
            wrong: "非",
            matchTo: "にマッチします",
            rule: "ルール",
            satisfactionOperator: "演算子を満たす",
            customerLabel: "顧客ラベル",
            keyEvents: "キーイベント",
            knowledgePoints: "知識ポイント",
            sessionLabel: "セッションラベル",
            supervisionItem: "監督項目",
          },
          linkExplorer: { linkInTheText: "文中のリンク:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound: "カスタムコンポーネントが見つかりません:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "カスタム" },
      dropdownMonit: {
        updateSucceeded: "更新に成功しました",
        areYouSureToDelete: "本当に削除しますか?",
        custom: "カスタム",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "現在のレポート設定を保存 === フォーム",
        saveCurrentReportConfiguration: "現在のレポート構成を保存します",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "一般的な設定名を入力してください。最大長さは20です",
        preservation: "保存",
      },
      template: {
        saveCurrentReportConfiguration: "現在のレポート構成を保存します",
        updateCurrentReportConfiguration: "現在のレポート構成を更新します",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "ワークオーダー情報",
        workOrder: "ワークオーダー",
        triggerScenario: "トリガーシーン",
        associatedCallConversation: "関連通話/会話",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "当面通/対話テキストでテストする",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "第", day: "日" },
      businessSummary: {
        normal: {
          equalTo: "等しい",
          notEqualTo: "等しくない",
          contain: "を含む",
          excluding: "含まない",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827: "私は私です。違う花火です。",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi、私はロボット品質検査の警告通知で、10月17日にグループに追加された",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827: "私は私です。違う花火です。",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi、私はロボット品質検査の警告通知で、10月17日にグループに追加された",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "コミュニケーションテーマ",
          conversationSummary: "会話のまとめ",
          serviceSuggestions: "サービスの提案",
        },
        template: { smartTags: "スマートラベル" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "詳細についてはクリックしてください" },
      schema: {
        textRobot: "テキストロボット",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "訓練師の護衛、文書q & a、タスク式対話、表q & a、画像q & a",
        capitalRetentionRobot: "留資ロボット",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "豊富な追及と投資戦略、高い擬人度の意思疎通、高い投資率、手がかりの効率的な自動転化を保障する",
        outboundRobot: "発信ロボット",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "オーダー完了訪問、サービス満足度調査、運送次元故障警告提示、注文出荷通知、電気商活動通知、候補者予約は、業務の自動化、業務効率の向上を助ける",
        incomingRobot: "着信ロボット",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "製造オーダー、注文などの業務システムを柔軟に統合し、プロセスの自動化を実現する音声意味モデルのカスタマイズ認識は、業務効果がすぐに現れる実体 (携帯電話番号、注文金額、注文時間、住所、…) 識別し、複雑な業務を迅速にサポートします",
        intelligentQualityInspection: "スマート品質検査",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "全量品質検査、適時高効率、精度85%",
        intelligentSessionAnalysis: "スマートセッション分析",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "業務のショートボードを洞察し、お客様の声に耳を傾け、マーケティングの転換を高める",
        enterpriseWechatConversationAnalysis: "企業のwechatセッション分析",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "潜在的なビジネスチャンス、リスクを効率的に識別し、マーケティング転化率を20% 向上させる",
        assistant: "座席助手",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "座席助手は初心者の顧客サービスを保障し、熟練者の顧客サービスの通話品質を向上させることができる。すべての座席をあなたの切り札にします",
        digitalPeople: "デジタル人",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          "「対面」擬人化コミュニケーションは、音声、文字、ビデオ、タッチマルチモードの相互作用をサポートし、没入型コミュニケーション体験を実現し、ブランドイメージとユーザーサービス体験を向上させる",
        aIKnowledgeZhongtaiEnterpriseSearch: "AI知識ミドル・企業検索",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "知識検索、知識共有、ドキュメント検索、データベースドッキング検索",
        aIKnowledgeCenterKCsKnowledgeBase: "AI知識ミドル・KCS知識リポジトリ",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "知識の全ライフサイクル管理を提供し、ロボットとIM知識ベースを統一的に維持し、第三者知識ベースとの連携をサポートし、知識テンプレート、原子知識などの強力な機能がより便利な知識管理体験をもたらします。",
        aIKnowledgeCenterKnowledgeMap: "AI知識ミドル・知識マップ",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "ワンストップ画像構築は企業の知識を融合し、知識の細部を大図で示し、ドッキング能力をカスタマイズした体験を作る",
        modularDevelopmentPlatformOriginalEngineASR:
          "モジュール開発プラットフォーム・原心エンジン・ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "エンドツーエンドのアルゴリズムを採用し、業務のカスタマイズ化の効果体験を実現し、自然シーン認識率は90% を超え、パーソナライズされた語彙認識は97% を超えた",
        modularDevelopmentPlatformIntelligentMarketing:
          "モジュール開発プラットフォーム・スマートマーケティング",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "深さ学習アルゴリズムを利用して、ユーザー画像を結合し、商品の正確な推薦を実現し、販売転換を高める",
        modularDevelopmentPlatformUserPortrait:
          "モジュール開発プラットフォーム・ユーザー画像",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "ユーザーデータと会話データに基づいてユーザー画像を正確に構築し、顧客の特徴を十分に説明する",
        modularDevelopmentPlatformIntelligentRecommendation:
          "モジュール開発プラットフォーム・スマート推奨",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "ユーザー画像と製品画像に基づいて、興味のある製品を自動的にユーザーに推薦し、千人千面のパーソナライズ推薦を実現する",
        modularDevelopmentPlatformNLPPAAS:
          "モジュール開発プラットフォーム・NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "高精度なNLP基礎能力を提供し、語、文、文章などの粒度の異なる基礎能力をカバーする",
      },
    },
    qualityScore: {
      index: {
        show: "展示:",
        displayBySecondaryClassification: "二級別に展示する",
        showByRule: "ルール別表示",
        pleaseEnterClassificationruleName: "分類・ルール名称を入力してください",
        clickCopy: "クリックしてコピー",
        hitDetailsOfOperatorsInRules: "ルール内オペレータヒット詳細:",
        hit: "ヒット",
        miss: "ヒットしません",
        filter: "フィルタ:",
        clickToViewDetails: "クリックして詳細を見る",
      },
      components: {
        flowNodeList: {
          branch: "分",
          pleaseSelect: "選択してください",
          viewTheCompleteProcess: "完全なプロセスを表示します",
          thereIsCurrentlyNoProcessAvailable: "フローなし",
        },
        pointItem: { xiangHit: "アイテムヒット" },
        flowNodeListModal: { index: { completeProcess: "完全なプロセス" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "プロセス品質検査タスクに変更が発生しました。再クレームしてください。",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "このデータは定期的にクリアされました",
        subject: "テーマ:",
        description: "説明:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "品質検査タスクに追加" },
        },
      },
    },
    relatedDetail: { index: { associatedCallConversation: "関連通話/会話" } },
    samplingTarget: {
      index: {
        time: "時",
        samplingQuantityIsLessThan: "抽出検査の数量が",
        alertWhen: "時に警告を行う",
        everyday: "毎日",
        weekly: "毎週",
        monthly: "毎月",
        samplingInspectionTargetSavedSuccessfully:
          "抽出検査目標の保存に成功しました",
        personnel: "人員",
        eachCustomerService: "カスタマーサービスごと",
        cycle: "周期",
        samplingInspectionTarget: "抽出検査目標",
        strip: "条",
        alertSettings: "アラート設定",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "カスタマーサービス",
        complete: "完了",
        Objective: "/ターゲット",
        everyday: "毎日",
        weekly: "毎週",
        monthly: "毎月",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "グループチャット交流率",
        maximumResponseIntervalTime: "応答の最大間隔時間",
        numberOfPreemptiveCalls: "強奪回数",
        numberOfMessages: "メッセージ件数",
        strip: "条",
        proportionOfMessages: "メッセージ件数の割合",
        speechPercentage: "話す割合",
        longestCustomerTalk: "最も長い顧客の発言",
        numberOfIndepthDiscussions: "踏み込んだ討論回数",
        second: "次",
        maximumDurationOfContinuousSpeech: "最長持続発話時間",
        questionFrequency: "質問頻度",
        customer: "お客様",
        keyEvents: "重要なイベント:",
        opportunity: {
          index: {
            generationFailed: "生成に失敗しました",
            generateOpportunityPoints: "チャンスポイントを生成する",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "生成に失敗しました",
            generateSummary: "まとめの生成",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "本当に削除しますか?",
        add: "追加",
        opportunityPoints: "チャンスポイント",
        conversationSummary: "会話のまとめ",
        cancel: "キャンセル",
        determine: "確定",
        edit: "編集",
        establish: "作成",
        conversationalInsights: "会話インサイト",
        keyEvents: "キーイベント",
        numKeyEvents: "つの重要なイベント",
        questionAnalysis: "質問分析",
        find: "発見",
        questions: "次の質問",
        conversationAction: "会話アクション",
        customer: "お客様",
        sale: "販売",
        sessionLabel: "会話シーン",
        employeeQuestions: "従業員の質問",
        customerQuestions: "お客様からの質問",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "お客様",
        customerService: "カスタマーサービス",
        replyTo: "に返信します",
        addressee: "宛先:",
      },
    },
    listManage: {
      template: {
        reIdentification: "再識別",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "再認識は転写時間を消費しますので、慎重に操作してください。",
        determine: "確定",
        executionSuccessful: "実行に成功しました",
        addToQualityInspectionTask: "品質検査タスクに追加",
        batchOperation: "一括操作",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "追加に成功しました",
        addToQualityInspectionTask: "品質検査タスクに追加",
        qualityInspectionTask: "品質検査タスク",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "役割の検出",
        detectionRange: "検査範囲",
        sentence: "文",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "このデータは定期的にクリアされました",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "業務システムに戻る" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "問題抽出",
        businessSummary: "業務のまとめ",
        keyWord: "キーワード",
      },
      components: {
        problemExtraction: {
          no: "いいえ",
          customerServiceDidNotAnswer: "カスタマーサービス未解答",
          customerServiceHasAnswered: "カスタマーサービスが回答しました",
          problemUnresolved: "問題が解決されていません",
          theProblemHasBeenResolved: "問題解決済み",
          customerIssue: "お客様の問題:",
          customerServiceAnswer: "カスタマーサービスの解答:",
          reExtractQuestions: "問題を再抽出する",
          clickToExtractQuestions: "クリックして問題を抽出します",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "画像名称",
            backgroundDescription: "背景の説明",
            customerLabel: "顧客ラベル",
            labelManagement: "ラベル管理",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "新規作成" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "設定を問い詰める" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "質問を増やす",
              remove: "削除",
              addQuestioning: "質問を追加する",
              section: "第",
              secondInquiry: "次の質問",
              questioningScore: "得点を問い詰める",
            },
          },
        },
        index: {
          accordingToRules: "ルールに従う",
          byScore: "得点別",
          whenMissRule: "ルールにヒットしていない場合",
          whenConductingQuestioning: "時に、問い詰める",
          pleaseSelectARule: "ルールを選択してください",
          ruleCannotBeEmpty: "ルールは必須項目です",
          currentScriptScore: "当话术得点 ≦",
          pleaseEnterTheScore: "得点を入力してください",
          scoreCannotBeEmpty: "得点は必須項目です",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "ナレーション:" },
          pPT: { slide: "スライド" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "話術コメント",
              script: "話術",
              semantics: "セマンティック",
              violatingWords: "違反語",
              speechSpeed: "話の速さ",
              emotion: "感情",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "話術コメント",
              regenerate: "再生成",
              generate: "生成",
              hitSituation: "ヒット状況",
              noSemanticTagsMatched: "セマンティックラベルにマッチしません",
              matchToSemanticTags: "セマンティックラベルにマッチ",
              appealToBeSubmitted: "申し立ては提出待ちです",
              ratingHasBeenReviewed: "評価は再確認済みです",
              ratingAppealInProgress: "評価クレーム中",
              expressionScore: "得点を表現する",
              moodParticlesTimes: "語気語、 {0} 回",
              changeTheModalParticlesTo: "語気語を",
              second: "次",
              interrogation: "問い詰める",
              questioningWithoutScoring: "点数を問わない",
              branch: "分",
              matchingRate: "マッチング率",
              ratingAppeal: "評価申立て",
              changeTo: "に変更",
              wordPerMinute: "ワード毎分",
              completeScript: "話が完全である",
              incompleteScript: "話が不完全です",
              semanticHit: "セマンティックヒット",
              semanticMisses: "セマンティックがヒットしません",
              scriptViolation: "話術違反",
              modified: "修正済",
              notChanged: "修正されていません",
              keyWord: "キーワード",
              semanticLabels: "意味ラベル",
              semanticMatchingRate: "意味マッチング率",
              violatingWords: "違反語「",
              delete: "削除",
              unmatched: "マッチしていません",
              matching: "マッチ",
              matchTo: "にマッチします",
              notMatchedTo: "マッチしていません",
              scriptScore: "話の得点",
              standardScript: "標準話術",
              emotionalScore: "感情スコア",
              speechSpeedScore: "スピードスコア",
              viewResolution: "解析の表示",
              nodeConfiguration: "ノード設定",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "本題の回答時間がタイムアウトし、自動的に回答内容を提出する",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "本題の回答時間がタイムアウトし、自動的に回答内容を提出する",
          },
          correctBtn: {
            saveSuccessful: "保存に成功しました",
            errorCorrection: "誤り訂正",
            cancel: "キャンセル",
            preserve: "保存",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "得点ルール",
            cancel: "キャンセル",
            determine: "確定",
          },
          scoringRuleItem: {
            whole: "すべて",
            arbitrarily: "任意",
            pleaseEnterARuleName: "ルール名を入力してください",
            standardScript: "標準話術",
            if: "もし",
            and: "かつ",
            or: "または",
            thenScore: "得点",
            pleaseEnterAScore: "点数を入力してください",
            bonusPoints: "プラスポイント",
            minusPoints: "減点",
            hit: "ヒット",
            misses: "ヒットしません",
            keyWord: "キーワード",
            semantics: "セマンティック",
          },
          title: {
            ruleValidation: "ルールチェック",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "得点ルール (現在の話の総得点は",
            points: "分)",
            editRules: "編集ルール",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "ラベルなし" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "コミュニケーション目標",
              scoringCriteria: "評価基準",
            },
          },
        },
        index: {
          generating: "生成中",
          generateDialogueFramework: "会話フレームワークの生成",
          theme: "テーマ",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "提示語",
            generatePromptLanguage: "提示語を生成する",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "コミュニケーションステージ",
          communicationObjectives: "コミュニケーション目標",
          scoringCriteria: "評価基準",
          suggestionsForImprovement: "改善の提案",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "フロースコア",
            contentIntegrity: "コンテンツの完全性:",
            NumberOfCompletedProcesses: "% 、完了プロセス数",
            keyHitPointsTotal: "ポイントヒット: 合計",
            notReached: "個が達していません",
            accurateFollowupAccumulationOfStandardScripts:
              "正確なフォロー: 標準的な話術の蓄積",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "この字は、学習者がヒットした文字数を合わせて読みます。",
            aWord: "文字",
            languageNormsYes: "言語規範: あり",
            emotionsAppearing: "感情: 出現",
            speechSpeedSpeakingTooFast: "言葉速: 言葉速が速すぎる",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "語気語: 語気語が多すぎる",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "無視ラベルに追加",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "スマートタグを再実行しますか?",
        reExecute: "再実行",
      },
      component: { successfullyAdded: "追加に成功しました" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "すべての通知をオフにする",
        reQualityInspectionTask: "再品質検査タスク",
        requalityInspection: "品質検査のやり直し",
        waiting: "待っています",
        viewDetails: "詳細を見る",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "検査時間:" } },
        },
        template: { responseOpeningSentence: "応答開始文" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "生成に失敗しました", generate: "生成" },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "データなし" } },
      },
      index: { regenerate: "再生成" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "本当に削除しますか?",
        operationSuccessful: "操作に成功しました",
      },
      template: { delete: "削除" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "問題を選択してください!",
        similarQuestionRecommendation: "似たようにオススメします",
        selectAllOnPage: "当ページを全て選択",
        cancel: "キャンセル",
        preserve: "保存",
        changeBatch: "ロット変更",
      },
    },
    listControl: { index: { columnControl: "列コントロール" } },
    loginUserAvatar: { index: { switch: "切り替え" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "リアルタイムでデータを追加する場合、品質検査タスクの実行にはまだ時間がかかります。具体的な時間は品質検査データ量の大きさによって、数分から2時間まで異なる。",
      },
    },
    bEditor: {
      index: {
        delete: "削除",
        cancel: "キャンセル",
        confirm: "確定",
        insert: "挿入",
        width: "幅",
        height: "高さ",
        clearContent: "コンテンツをクリア",
        revoked: "キャンセル",
        redo: "やり直し",
        fontSize: "サイズ",
        rowHeight: "行高",
        wordSpacing: "字の間隔",
        paragraphIndent: "段落インデント",
        increaseIndent: "インデントを増やす",
        decreaseIndent: "インデントを減らす",
        border: "ボーダー",
        color: "カラー",
        textColor: "テキストの色",
        backgroundColor: "背景色",
        temporaryColor: "一時的な色",
        bold: "太くする",
        italic: "斜体",
        underline: "下線",
        strikethrough: "打ち消し線",
        font: "フォント",
        textAlignment: "テキストの配置",
        left: "左揃え",
        centered: "センタリング",
        right: "右に居る",
        bothEnds: "両端",
        leftFloat: "左フロート",
        rightFloat: "右フロート",
        subrupt: "上付き文字",
        subscript: "下付き文字",
        clearStyle: "スタイルをクリアします",
        title: "タイトル",
        regular: "通常",
        orderedList: "順序付きリスト",
        unorderedList: "順不同リスト",
        reference: "引用",
        code: "コード",
        link: "リンク",
        clearLink: "リンクをクリア",
        horizontalLine: "水平線",
        media: "メディア",
        mediaLibrary: "メディアライブラリ",
        smallExpression: "小さな表情",
        fullScreen: "全画面",
        exitFullScreen: "全画面を終了する",
        enterLinkText: "リンクテキストの入力",
        enterLinkAddress: "リンク先を入力",
        enterTheLinkAddressAndPressEnter: "リンク先を入力してenterします",
        openInNewWindow: "新しいウィンドウで開きます",
        removeLink: "リンクを削除",
        playingAudioFiles: "オーディオファイルの再生",
        playVideoFiles: "動画ファイルの再生",
        embeddedMedia: "組み込みメディア",
        image: "画像",
        video: "動画",
        audio: "オーディオ",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "名称",
        description: "説明",
        type: "タイプ",
        status: "ステータス",
        actions: "操作",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "タスク名",
        description: "説明",
        form: "品質検査フォーム",
        actions: "操作",
        createUser: "作成者",
      },
      samplingRecords: {
        createTime: "時間",
        type: "タイプ",
        operateUser: "オペレータ",
        number: "数量追加",
        filterConditionList: "フィルタ条件",
        sampleRuleStr: "サンプリング条件",
        timeGreaterThan: "時間がより大きい",
        timeLessThan: "時間が未満です",
      },
    },
    customFilters: {
      createdAt: "通話時間",
      customerId: "お客様",
      agentId: "カスタマーサービス",
      userGroupId: "カスタマーサービス",
      state: "ステータス",
      lastEvaluateUserId: "最終採点者",
      submitTime: "最終提出時間",
      totalScore: "総得点",
      formItemForbidIdList: "禁止項目",
      formItemDeadlyIdList: "致命的な項目",
    },
    samplingInspection: {
      callTime: "通話時間",
      dialogTime: "会話時間",
      originator: "分配者",
      createTime: "時間の作成",
      updateTime: "更新時間",
      status: "フローステータス",
      score: "スコア",
      actions: "操作",
      reviewer: "審査員",
      samplingOfficer: "抽出検査員",
      id: "通話記録ID",
      time: "通話時間",
      phone: "お客様の電話番号",
      staff: "カスタマーサービス",
      inspectionStatus: "品質検査ステータス",
      samplingStatus: "抽出検査状態",
      inspectionGrade: "評価",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "標準語",
        synonymWords: "同義語",
        synonymWordsNumber: "同義語数",
        useFrequency: "使用頻度",
        effectiveness: "有効性",
        operation: "操作",
      },
      common: {
        useFrequency: "使用頻度",
        effectiveness: "有効性",
        operation: "操作",
      },
      professionalWords: {
        professionalWords: "専門名詞",
        useFrequency: "使用統計",
        homophonic: "ホットワード (同音置換)",
        target: "名詞の置き換え",
        targetWords: "置換語",
        homophonyWords: "同音シールドワード",
        operation: "操作",
      },
      attentionWords: "注目ワード",
      ignoreWords: "無視語",
      wordsWeight: {
        selfDefineWeightWords: "カスタムウェイト語",
        weightWords: "重み語",
        weight: "重み",
        useFrequency: "使用頻度",
        effectiveness: "有効性",
        operation: "操作",
      },
      corpusData: {
        corpusDescription: "資料の説明",
        updateTime: "更新時間",
        wordsCount: "語数",
        effectiveness: "有効性",
        operation: "操作",
        corpusContent: "コンテンツ",
      },
    },
    semanticTags: {
      title: "意味ラベル",
      tags: "標準文",
      orgName: "所属組織",
      tagCount: "トレーニング数",
      usingCount: "参照数",
      activeFlag: "有効性",
      suggestion: "トレーニングのアドバイス",
      actions: "操作",
    },
    intelligentTags: {
      tagName: "名称",
      tagDesc: "説明",
      tagRefCnt: "タグ数",
      categoryName: "ラベル分類",
      eventName: "イベント分類",
      tagStatus: "有効性",
      actions: "操作",
      tagType: "ラベルタイプ",
    },
    dataPreprocess: {
      name: "名称",
      desc: "説明",
      dataSource: "データソースの制限",
      type: "タイプ",
      status: "有効性",
      actions: "操作",
    },
    informationEntities: {
      name: "名称",
      apiName: "API名",
      type: "タイプ",
      remark: "説明",
      activeFlag: "有効性",
      actions: "操作",
    },
    interactiveRecognition: {
      questionAndAnswer: "標準質問 & 回答",
      type: "タイプ",
      status: "ステータス",
      actions: "操作",
      question: "問題",
    },
    role: { id: "ID", name: "名称", actions: "操作" },
    uploadDownload: {
      taskName: "ファイル名",
      createTime: "日付",
      size: "大きさ",
      createUserName: "オペレーター",
      status: "ステータス",
      result: "結果",
      actions: "操作",
    },
    taskCenterList: {
      name: "タスク内容",
      createTime: "提出時間",
      startTime: "開始時間",
      endTime: "終了時間",
      createUserName: "オペレーター",
      status: "ステータス",
      result: "結果",
      actions: "操作",
    },
    customerInformation: {
      title: "カスタマーサービス情報",
      data: "異常データのみを表示",
      list: {
        agentNo: "スタッフ番号/カスタマーサービスID",
        agentName: "カスタマーサービス",
        agentTel: "電話",
        agentEmail: "メールアドレス",
        updateTime: "更新時間",
        actions: "操作",
      },
      upload: {
        second:
          "2.インポートボタンをクリックし、テンプレート形式に合ったEXCElファイルをアップロードします",
      },
    },
    IndexManagement: {
      title: "指標管理",
      list: {
        indicatorName: "名称",
        indicatorDesc: "説明",
        activity: "有効性",
        inspectDataSource: "データソース",
        sysType: "タイプ",
        agentGroups: "カスタマーサービス",
        actions: "操作",
      },
      detail: {
        nameEmpty: "名称は必須項目です",
        descript: "説明",
        descriptEmpty: "説明は必須項目です",
        active: "有効性",
        activeEmpty: "有効性は必須項目です",
        dataSource: "データソース",
        dataSourceEmpty: "データソースは必須項目です",
        grounp: "カスタマーサービス",
        grounpEmpty: "カスタマーサービスグループは必須項目です",
        grounpHolder: "カスタマーサービスグループを選択してください",
        type: "採点方法",
        typeNone: "採点方法は必須項目です",
        base: "基礎分",
        baseNone: "基礎分は必須項目です",
        positive: "プラス要因",
        positiveNone: "プラス要因は必須項目です",
        customAdd: "フィルタを追加",
        negative: "マイナス要因",
        negativeNone: "マイナス要素は空白にできません",
        customOK: "フィルタ条件:",
        judgeStrategy: "条件ルール:",
        other: "その他の要因",
        otherAdd: "追加要因",
        agent: "元のデータ",
        section: "区間数",
        sectionNone: "区間数量は必須項目です",
        step: "区間ステップ",
        stepNone: "区間ステップ",
        save: "保存",
        cancel: "キャンセル",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "すべて",
      staff: "本人のみ",
      department: "当部門及び以下",
    },
    gongStatisticsDate: { week: "週", month: "月", season: "シーズン" },
    gongCoachTaskStatus: {
      wait: "指導待ち",
      already: "指導済み",
      ignore: "無視済み",
    },
    callRangeStatus: {
      all: "すべての通話",
      sale: "従業員が開始",
      customer: "顧客が開始",
    },
    todoListType: {
      later: "後で聞いてください",
      help: "カウンセリングヘルプ",
      contact: "後ほど連絡します",
      send: "資料を送る",
      warn: "リスク警告",
    },
    frequencyTypes: { everyDay: "毎日", everyWeek: "毎週", everyMonth: "毎月" },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "未分類データトレーニング",
        markedDataCorrigenda: "ラベル付け済みデータ正誤",
        classifiedDataCheck: "分類データ検証済み",
        markedHistoryRecord: "ラベル履歴",
        historyDataRecalculate: "履歴データ再計算",
      },
      dataExtractMode: {
        random: "完全にランダム",
        intelligentRecommend: "スマート推奨",
        custom: "カスタム",
        specifiedClass: "分類指定",
      },
      smartRatesList: {
        markRate: "表示率",
        corrigendaRate: "勘違い率",
        kl: "K-L分散度",
        signalNoiseRate: "信号対雑音比",
      },
    },
    visibleRangeTypes: {
      all: "すべて",
      customerGroup: "カスタマーサービス",
      userDefined: "カスタム",
    },
    samplingRateTypes: { everyDay: "毎日抽出する", everyWeek: "毎週抽出する" },
    taskRemindTypes: {
      confirm: "品質検査タスク記録を確認する必要がある場合は、通知を送信します",
      appeal: "品質検査タスク記録がクレームを開始したときに、通知を送信します",
    },
    weekDays: {
      monday: "月曜日",
      tuesday: "火曜日",
      wednesday: "水曜日",
      thursday: "木曜日",
      friday: "金曜日",
      saturday: "土曜日",
      sunday: "日曜日",
    },
    reportDateTypes: { dailyReport: "日レポート", weeklyReport: "週レポート" },
    samplingRangeDayTypes: { today: "今日は", yesterday: "昨日" },
    samplingRangeWeekTypes: { thisWeek: "今週", lastWeek: "先週" },
    samplingRuleTypes: {
      proportion: "スケールサンプリング",
      random: "ランダムサンプリング",
    },
    taskStatusTypes: {
      waitEvaluate: "評価待ち",
      waitSubmit: "提出待ち",
      waitConfirm: "確認待ち",
      waitReview: "再確認待ち",
      completed: "完了しました",
      all: "すべて",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "直接評価",
        weightedScore: "重み評価",
        deductScore: "減点",
        directScoreSecondLevel: "直接評価-二級",
        weightedScoreSecondLevel: "重み評価-二次",
        deductScoreSecondLevel: "減点-二級",
      },
    },
    fieldOperators: {
      is: "等しい",
      not: "等しくない",
      contains: "を含む",
      notContains: "含まない",
      not_contains: "含まない",
      containsAny: "任意を含む",
      contains_any: "任意を含む",
      is_any: "任意を含む",
      not_any: "いずれも含まない",
      notContainsAny: "いずれも含まない",
      not_contains_any: "いずれも含まない",
      containsAll: "すべてを含む",
      contains_all: "すべてを含む",
      notContainsAll: "すべてを含まない",
      nont_contains_all: "すべてを含まない",
      isNull: "空白です",
      is_null: "空白です",
      isNotNull: "空白にしない",
      is_not_null: "空白にしない",
      greaterThan: "より大きい",
      greater_than: "より大きい",
      greaterThanEq: "以上等しい",
      greater_than_eq: "以上等しい",
      lessThan: "未満",
      less_than: "未満",
      lessThanEq: "以下",
      less_than_eq: "以下",
      between: "介在",
      notBetween: "介在しない",
      not_between: "介在しない",
      relative: "等しい",
      set: "に設定します",
      belongs: "に属する",
      notBelongs: "所属しない",
      not_belongs: "等しくない",
      today: "今日は",
      yesterday: "昨日",
      the_day_before_yesterday: "おととい",
      tomorrow: "明日",
      next_seven_day: "今後7日間",
      last_seven_day: "最近の7日間",
      this_week: "今週",
      next_week: "来週",
      last_week: "先週",
      this_month: "今月",
      next_month: "来月",
      last_month: "先月",
      this_year: "今年は",
      next_year: "来年",
      last_year: "昨年",
      exact_match: "正確なマッチ",
      add_value: "新規付加価値",
      remove_value: "値の削除",
      set_null: "空にする",
      isAny: "任意等しい",
      notAny: "任意に等しくない",
      belongTo: "に属する",
      notBelongTo: "所属しない",
      hasAny: "任意を含む",
      notHasAny: "いずれも含まない",
      prefixContains: "先頭が等しい",
      prefixNotContains: "先頭が等しくない",
      suffixContains: "終わりは等しい",
      suffixNotContains: "終わりが等しくない",
      nextSevenDay: "今後7日間",
      lastSevenDay: "最近7日間",
      thisWeek: "今週",
      nextWeek: "来週",
      lastWeek: "先週",
      thisMonth: "今月",
      nextMonth: "来月",
      lastMonth: "先月",
      thisYear: "今年は",
      nextYear: "来年",
      lastYear: "昨年",
      after: "より遅い (当日を含む)",
      before: "以前 (当日を含む)",
      allMatching: "すべてマッチ",
      allNotMatching: "すべてがマッチしません",
      hit: "ヒット",
      not_hit: "ヒットしません",
    },
    fieldsOperators: {
      is: "等しい",
      is_null: "同時に空白です",
      head_equal: "先頭が等しい",
      tail_equal: "終わりは等しい",
      is_not_null: "同時に空白ではありません",
      not: "等しくない",
      diff_equal: "違いは等しい",
      diff_greater_than: "差より大きい",
      diff_greater_than_eq: "差以上等しい",
      diff_less_than: "差がより小さい",
      diff_less_than_eq: "差は以下です",
      hour_equal: "同時",
      day_equal: "同じ日",
      week_equal: "同一周",
      month_equal: "同月",
      year_equal: "同年",
      day: "日",
      minute: "分",
      hour: "時間",
      part_equal: "部分は等しい",
    },
    samplingColumnCaptions: {
      created_at: "通話時間",
      agent_id: "カスタマーサービス",
      get_direction: "通話タイプ",
      call_result: "通話結果",
      survey: "評価",
      derived_from_id: "ソース",
      quit_queue_why: "順番待ちステータス",
      queuing_duration: "順番待ち時間",
      talking_seconds: "通話時間",
      ringing_seconds: "ベルの時間",
      hangup_by: "通話切断側",
    },
    callTypes: {
      callIn: "呼び出し",
      callOut: "呼出",
      threeParty: "(三方)",
      consultation: "(お問い合わせ)",
      insert: "(強いプラグ)",
      monitor: "(傍受)",
      transfer: "(転送)",
      intercept: "(ブロック)",
      transferOutside: "(外部ケーブルの転送)",
      threePartyOutside: "(三方外線)",
      consultingOutside: "(コンサルティング外線)",
    },
    callResults: {
      staffAnswer: "カスタマーサービスの応対",
      staffNotAnswer: "カスタマーサービス未受付",
      customerAnswer: "顧客応対",
      customerNotAnswer: "顧客未接続",
      phoneBusy: "電話線",
      phoneOffline: "電話オフライン",
      customerSpeedHangUp: "客先速掛",
      customerHangUp: "お客様のオンフック",
      queueTimeout: "順番待ちタイムアウト",
      queueGiveUp: "待ち行列を放棄する",
      outlineAnswer: "外線応対",
      outlineNotAnswer: "外線未接続",
      noChooseQueue: "キューが選択されていません",
    },
    defaultSurveys: { noEvaluation: "未評価", noNeedToEvaluate: "評価不要" },
    queueStates: {
      queueSuccess: "順番待ちに成功",
      queueTimeout: "順番待ちタイムアウト",
      queueGiveUp: "待ち行列を放棄する",
      noStaffOnline: "カスタマーサービスなしオンライン",
    },
    callHangers: {
      customer: "お客様",
      staff: "カスタマーサービス",
      outline: "外線",
    },
    workFlowTypes: {
      noAppealsMode: "苦情なしモード",
      appealsMode: "苦情を許可するモデル",
    },
    fieldCategories: {
      standardCondition: "標準スクリーニング条件",
      customerCondition: "ユーザー定義のフィルタ条件",
    },
    sampleTypes: { automatic: "自動サンプリング", manual: "手動サンプリング" },
    defaultFields: {
      createdAt: "通話時間",
      agentId: "カスタマーサービス",
      getDirection: "通話タイプ",
      callResult: "通話結果",
      survey: "評価",
      derivedFrom: "ソース",
      quitQueueWhy: "順番待ちステータス",
      queuingDuration: "順番待ち時間",
      talkingSeconds: "通話時間",
      ringingSeconds: "ベルの時間",
      hangupBy: "通話切断側",
    },
    conditionFieldCategories: {
      fixedField: "固定フィールド",
      customField: "カスタムフィールド",
      secondLevelScore: "二次分類",
      formItemScore: "スコア項目",
      forbids: "禁止項目",
      deadlies: "致命的な項目",
    },
    columnFieldCategories: {
      agent: "カスタマーサービス",
      callLog: "通話記録",
      businessFields: "業務情報",
      customer: "お客様",
      customerCustomFields: "顧客カスタマイズ",
      source: "ソース",
      queue: "キュー",
      fixed: "固定",
      secondLevelScore: "二次分類",
      formItemScore: "スコア項目",
      forbids: "禁止項目",
      deadlies: "致命的な項目",
    },
    taskListDefaultFields: {
      startTime: "通話時間",
      name: "カスタマーサービス",
      state: "ステータス",
      totalScore: "総得点",
      formItemForbidList: "禁止項目",
      formItemDeadlyList: "致命的な項目",
      comment: "評価ノート",
      inspector: "採点者",
      review: "審査員",
    },
    evaluationStates: {
      initial: "初期化",
      waitEvaluate: "評価待ち",
      evaluated: "提出待ち",
      waitConfirm: "確認待ち",
      waitCheck: "再確認待ち",
      complete: "完了しました",
    },
    workBenchInfoTypes: {
      callInfo: "通話情報",
      businessInfo: "業務情報",
      customerInfo: "顧客情報",
    },
    evaluationEventStates: {
      create: "作成",
      valuate: "評価",
      edit: "編集",
      submit: "送信",
      confirm: "確認",
      appeal: "申し立て",
      checkConfirm: "再確認",
      check: "再確認",
    },
    formItemTypes: {
      direct: "直接評価",
      weight: "重み",
      deduct: "減点",
      forbid: "禁止項目",
      deadly: "致命的な項目",
    },
    appealCheckCallTypes: {
      callIn: "呼び出し",
      callBack: "双方向ダイヤルバック",
      callOut: "外呼ストレートダイヤル",
      autoCall: "自動アウトバウンド",
    },
    appealCheckCallStatus: {
      waitReview: "再確認待ち",
      reviewed: "再確認済み",
      filed: "アーカイブ済み",
    },
    samplingStatus: {
      all: "すべて",
      unchecked: "抽出未検査",
      checked: "抽出検査済み",
    },
    inspectionStatus: {
      all: "すべて",
      unread: "未読",
      readed: "既読",
      appeal: "申し立て",
      review: "再確認",
      filed: "アーカイブ済み",
    },
    gradeChooseHits: { yes: "はい", no: "いいえ" },
    appealCheckStatus: {
      all: "全ステータス",
      unread: "未読",
      inComplaint: "申し立て中",
      reviewed: "再確認済み",
      readed: "既読",
      filed: "アーカイブ済み",
      spotchecked: "抽出検査済み",
    },
    flowStatus: {
      unread: "未読",
      alreadyRead: "既読",
      complaining: "申し立て中",
      reviewed: "再確認済み",
      sampling: "抽出未検査",
      samplingCompleted: "抽出検査済み",
      complainCancel: "キャンセル済",
    },
    complainStatus: {
      success: "成功",
      fail: "失敗",
      default: "申し立て中",
      canceled: "キャンセル済",
    },
    reviewInspectionStatus: {
      inComplaint: "申し立て中",
      reviewed: "再確認済み",
    },
    gradeChangeTypes: {
      edit: "編集",
      check: "再確認",
      file: "アーカイブ",
      remark: "備考",
      complain: "申し立て",
      review: "再確認",
      reviewEvaluation: "再確認評価",
      sample: "抽出検査",
      sampleEvaluation: "抽出検査の評価",
      sampleSubmit: "抽出検査の提出",
      sampleAssign: "抽出検査分配",
      reviewAssign: "再確認分配",
      read: "既読",
      reviewConfirm: "再確認",
      sampleConfirm: "抽出検査の確認",
      caseBase: "ケースグループに追加",
      sampleCancel: "抽出検査の分配キャンセル",
      reviewCancel: "再確認して分配をキャンセルします",
      sampleAgain: "抽出検査の再分配",
      reviewAgain: "再分配を再確認する",
      btachDelete: "抽出検査記録の削除",
      rapidSampling: "高速抽出検査",
      reInspection: "品質検査のやり直し",
      smartTagsChange: "スマートラベル変更",
      cancelAppeal: "申し立てを取り消す",
    },
    appealTypes: {
      transliterationError: "転写エラー",
      discriminationError: "判別エラー",
      otherError: "その他のエラー",
    },
    inspectDataSources: {
      voiceCall: "音声通話",
      textDialogue: "テキスト会話",
      realTimeVoiceCall: "リアルタイム音声通話",
      realTimeTextDialogue: "リアルタイムテキスト会話",
      wechatDialogue: "企業マイクロセッション",
      taobao: "電子商取引テキスト会話",
      ticket: "ワークオーダー",
      wechatRadio: "企業マイクロ音声",
    },
    inspectTaskType: {
      all: "すべて",
      common: "通常品質検査",
      relate: "関連品質検査",
    },
    inspectApproveType: {
      all: "すべて",
      resolve: "審査通過",
      resolving: "審査中",
      reject: "審査不合格",
    },
    dataSourceFlags: {
      all: "制限なし",
      voiceCall: "通話",
      textDialogue: "会話",
    },
    smartWordsLibrary: { effective: "有効", invalid: "無効" },
    activeFlags: { effiective: "有効", invalid: "無効" },
    labelTypes: {
      dimensionLabel: "ラベルを付ける",
      classificationLabel: "分類ラベル",
    },
    pointTypes: {
      automatic: "自動評価",
      manual: "手動評価",
      interactiveDetection: "インタラクティブ検出",
      smartRules: "スマートルール",
      machineLearning: "自己学習モード",
      intelligentModel: "スマートモデル",
    },
    pointGradeTypes: {
      radio: "単一選択",
      input: "入力",
      select: "選択",
      all: "すべてを満たす",
      any: "任意を満たす",
      customize: "カスタム",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "キーワードマッチ",
      question: "疑問文",
      regular: "正規表現",
      context: "コンテキスト重複",
      semantics: "意味マッチング",
      word: "文字数類似文",
      dialogue: "会話時間間隔",
      speed: "音声速度検出",
      grabDetection: "強奪検査",
      semanticTags: "意味ラベル",
    },
    applyRoles: {
      all: "すべて",
      customer: "お客様",
      agent: "カスタマーサービス",
      robot: "ロボット",
    },
    saleApplyRoles: { all: "すべて", customer: "お客様", agent: "スタッフ" },
    applyOperatorScopes: {
      all: "全文",
      preCondition: "前置き条件",
      scope: "指定範囲",
    },
    keywordScopes: {
      all: "すべてのキーワードを検出",
      any: "任意のキーワードを検出",
    },
    preOperatorHitTypes: {
      first: "初回マッチ",
      every: "毎回マッチする",
      last: "最後のマッチ",
      any: "いずれかのマッチ",
      none: "マッチしていません",
    },
    operatorRuleTypes: {
      all: "すべてを満たす",
      any: "任意を満たす",
      custom: "カスタムロジック",
    },
    informationEntityTypes: {
      system: "システム",
      custom: "カスタム",
      moduleCompany: "モジュール",
    },
    organizationTypes: { null: "なし", moduleCompany: "モジュール" },
    customType: {
      smartTag: "抽出対象物",
      scriptTimes: "話術計",
      dataCategory: "データ分類",
    },
    interactiveQuestionTypes: {
      standard: "標準q & a",
      multiElement: "多要素q & a",
      guideMultiRound: "ガイド式マルチホイール相互作用",
    },
    targetTypes: {
      all: "すべて",
      customerService: "カスタマーサービス",
      customer: "お客様",
    },
    interactiveTypes: {
      similarQList: "類似問題",
      exceptSimilarQList: "類似問題を排除する",
      similarAList: "似たような答え",
      wrongAList: "間違った答え",
    },
    filterTypes: {
      call: "通話録音",
      dialogue: "会話テキスト",
      wechat: "企業wechat",
      wechatAll: "企業のwechatのすべての会話",
      ticket: "ワークオーダー・セッション",
      taobao: "Ecセッション",
      wechatRadio: "企業マイクロ音声",
    },
    fieldTypes: {
      keyword: "キーワード",
      agent: "カスタマーサービス",
      agentGroup: "カスタマーサービス",
      sigleListbox: "単一選択",
      multiListbox: "複数回答",
      text: "単一行テキスト",
      textArea: "複数行のテキスト",
      dateTime: "時間",
      number: "数字",
      voice: "",
      customer: "お客様",
      fieldWithLink: "リンク付きフィールド",
      percentage: "パーセント",
      intelligentClassification: "スマート分類",
      testSetList: "テストセットの動的フィールド",
      inspector: "品質検査員",
    },
    saveTypes: { save: "保存" },
    knowledgeType: { oneToOne: "一問一答" },
    updateTypes: { update: "アップデート", saveAs: "名前を付けて保存" },
    recommendItemActionTypes: {
      similar: "似ています",
      exclude: "除外",
      ignore: "無視する",
    },
    gradeStatus: {
      unread: "未読",
      alreadyReview: "再確認済み",
      alreadyFiled: "アーカイブ済み",
      waitReview: "再確認待ち",
      alreadyRead: "既読",
    },
    qualityCheckDetailTypes: { review: "再確認", spotCheck: "抽出検査" },
    sampleAndReviewStatus: {
      init: "未抽出",
      unread: "未読",
      alreadyRead: "既読",
      complaining: "申し立て中",
      reviewed: "再確認済み",
      sampling: "抽出未検査",
      samplingCompleted: "抽出検査済み",
    },
    interactiveAnswerTypes: {
      noAnswer: "答えが見つかりません",
      answer: "答えが検出されました",
      wrongAnswer: "間違った答えが検出されました",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "問題が検出されませんでした",
      question: "問題が検出されました",
      eliminateQuestion: "トラブルシューティングの問題が検出されました",
    },
    isManual: { yes: "人工品質検査済み", no: "人工品質検査をしていません" },
    readStatus: { yes: "既読", no: "未読" },
    reInspectionType: {
      recalcScore: "スコア再計算のみ (評価を含む)",
      recheckPoint: "品質検査ポイントとスコアの再計算",
    },
    common: {
      eCommerceReview: "Ecレビュー",
      socialMediaComments: "ソーシャルメディアのコメント",
      customer: "お客様",
      customerService: "カスタマーサービス",
      robot: "ロボット",
      customerInformation: "顧客情報",
      workOrderFields: "作業指示フィールド",
      intelligentConversationAnalysis: "スマートセッション分析",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "業務のショートボードを洞察し、お客様の声に耳を傾け、マーケティングの転換を高める",
      intelligentAccompanyingPractice: "スマートトレーナー",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "ビジネスのリアルなシーンをシミュレートする没入型AIトレーナーを構築し、金メダルの話術を全員が習得し、会話技術が急速に向上した",
      intelligentQualityInspection: "スマート品質検査",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "全量品質検査、適時高効率、精度85% 以上",
      salesEmpowerment: "販売エンパワーメント",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AIは販売管理を再構築し、コミュニケーションプロセスのデジタル化、可視化を実現し、販売の各部分を透明的に管理し、コミュニケーションの各段階を知恵で洞察し、業務問題を細分化して診断し、完璧に販売経験を復刻する",
      algorithmPlatform: "アルゴリズムプラットフォーム",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "自己研原心エンジンの音声意味に基づいて、ASR、NLPモデルをカスタマイズし、業務シーンデータを通じ、システムの自己学習を実現し、使えば使うほど知能的で、使えば使うほど正確な目的を達成する",
      generalSettings: "共通設定",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "データ権限、従業員の役割を統一的に設定管理する",
      callLog: "通話記録",
      intelligentRules: "スマートルール",
      businessRecords: "業務記録",
      customFilter: {
        call: "音声通話",
        text: "テキスト会話",
        smartTags: "スマートラベル",
        pretreatment: "前処理",
        smartTagGroup: "スマートラベルセット",
        currency: "共通データ",
        wechat: "企業wechat",
        spotCheck: "抽出検査",
        personaField: "顧客リスト",
        ticket: "ワークオーダー・セッション",
        taobao: "Ecセッション",
        wechatRadio: "企業マイクロ音声",
        reviewList: "再確認",
      },
      talkerRecognitionType: {
        API: "API識別トラックロール",
        text: "テキスト認識",
      },
      operatorTypes: {
        keyword: "キーワードマッチ",
        question: "疑問文",
        regular: "正規表現",
        context: "コンテキスト重複",
        semantics: "意味マッチング",
        word: "文字数類似文",
        dialogue: "会話時間間隔",
        speed: "音声速度検出",
        grabDetection: "強奪検査",
        semanticTags: "意味ラベル",
        keywordStrict: "キーワードマッチング (厳格)",
        link: "リンクフィールド",
        entityInfoDetection: "情報エンティティの検出",
        basicInfoDetection: "基礎情報検出",
        volume: "ボリューム検出",
        emotion: "感情分析",
        grammar: "文法規則",
        sentenceLength: "文長検出",
        conceptWord: "概念語",
        questionsAndAnswers: "ナレッジベース解答(旧)",
        element: "要素q & a",
        silent: "応答タイムアウト",
        associate: "随行データ",
        repeat: "重複送信",
        timeInterval: "間隔時間",
        typoDetection: "誤字検出",
        silentOld: "サイレント",
        knowledge: "知識解答",
        wecomRemark: "顧客企業のマイクロコメント",
        customerAddTime: "顧客追加時間",
      },
      keywordOperators: {
        all: "すべてマッチ",
        allNot: "すべてがマッチしません",
        any: "任意マッチ",
      },
      applyRoles: {
        all: "すべて",
        customer: "お客様",
        agent: "カスタマーサービス",
      },
      sentenceLength: {
        less: "未満",
        greater: "より大きい",
        equal: "等しい",
        greater_or_equal: "以上等しい",
        less_or_equal: "以下",
      },
      dialogueApplyScopes: {
        differentRoles: "異なるロール間",
        agent: "カスタマーサービス",
        customer: "お客様",
        agentResponse: "カスタマーサービス応答",
        customerResponse: "顧客応答",
        customerNoResponse: "顧客が応答しない",
      },
      applyConditionHitTypes: {
        first: "初回ヒット",
        every: "毎回のヒット",
        last: "最後のヒット",
      },
      applyConditionScopes: {
        current: "現在",
        before: "前",
        after: "後",
        beforeAll: "前にすべて",
        afterAll: "その後すべて",
        around: "近く",
      },
      voiceDemoTypes: {
        singleChannel: "シングルチャンネル通話録音",
        dualChannel: "デュアルチャンネル通話録音",
      },
      sendStatus: {
        sending: "発送中",
        arrive: "配達済み",
        fail: "送信に失敗しました",
        off_sending: "オフラインで未読を送信する",
        off_arrive: "既読",
        rollback: "撤回済み",
      },
      collectionMethod: { assignment: "直接代入", entity: "実体抽出" },
      systemTypes: { qa: "スマート品質検査", wfm: "スマートクラス" },
      entityOperators: {
        equals: "情報に等しい",
        notEquals: "情報に等しくない",
        contains: "情報を含む",
        notContains: "情報は含まれていません",
        equalsFix: "固定値に等しい",
        notEqualsFix: "固定値に等しくない",
        containsFix: "固定値を含む",
        notContainsFix: "固定値を含まない",
      },
      basicInfoOperators: {
        equals: "等しい",
        notEquals: "等しくない",
        contains: "を含む",
        notContains: "含まない",
        isNull: "空白です",
        isNotNull: "空白にしない",
      },
      volumeDetectionWays: {
        loudness: "ラウドネスデシベル",
        rangeAbilitySelf: "変化幅 (本人より上文)",
        rangeAbilityOth: "変化幅 (比較上の文)",
        rangeAbility3: "変化幅 (本人文より)",
      },
      volumeDetectionOperators: {
        is: "等しい",
        not: "等しくない",
        greaterThan: "より大きい",
        greaterThanEq: "以上等しい",
        lessThan: "未満",
        lessThanEq: "以下",
      },
      sentimentTypes: {
        positive: "ポジティブな感情",
        negative: "マイナス感情",
        neutral: "中性感情",
        thankful: "感謝",
        happy: "うれしい",
        complaining: "文句を言う",
        angry: "怒っています",
        post: "正面",
        negat: "マイナス",
        neut: "中性",
      },
      emotionOperators: { is: "適合", not: "該当しません" },
      propTypes: { system: "システム", user: "カスタム" },
      valueTypes: { text: "テキスト", number: "数字" },
    },
    tasks: {
      allowCustomGrievance: "カスタムクレームを許可する理由",
      businessSummary: "業務のまとめ",
      theDayBeforeYesterday: "おととい",
      assessActionTypes: { reassess: "見直し" },
      scoreType: {
        aiScore: "スマート評価",
        manualScore: "手動評価",
        interactiveDetection: "インタラクティブ検出",
        smartRules: "スマートルール",
        machineLearning: "自己学習モード",
      },
      inspectType: {
        inspectTrue: "にチェック",
        inspectFalse: "チェックされていません",
      },
      operatorType: {
        keyword: "キーワードマッチ",
        notKeyword: "キーワードがマッチしません",
      },
      applyRole: {
        all: "すべて",
        customer: "お客様",
        agent: "カスタマーサービス",
      },
      applyScope: { all: "すべて", specific: "指定文", scope: "指定範囲" },
      judgeStrategy: {
        all: "すべての条件を満たす",
        arbitrarily: "任意の条件を満たす",
        custom: "カスタムロジック",
      },
      checkPointStatus: {
        waitFor: "再確認待ち",
        notDeductScore: "減点しない",
        deductScore: "減点",
      },
      applyOptions: { hit: "条件ヒット", notHit: "指定条件なし" },
      gradeTypes: {
        yesOrNo: "合っているかどうか",
        input: "手動入力",
        level: "段階別評価",
        multipleFactors: "多要素",
      },
      gradeChooseHits: { yes: "はい", no: "いいえ" },
      gradeChangeTypes: {
        edit: "編集",
        check: "再確認",
        file: "アーカイブ",
        remark: "備考",
        complain: "申し立て",
        review: "再確認",
        reviewEvaluation: "再確認評価",
        sample: "抽出検査",
        sampleEvaluation: "抽出検査の評価",
        sampleSubmit: "抽出検査の提出",
        sampleAssign: "抽出検査分配",
        reviewAssign: "再確認分配",
        read: "既読",
        reviewConfirm: "再確認",
        sampleConfirm: "抽出検査の確認",
      },
      gradeStatus: {
        unread: "未読",
        alreadyReview: "再確認済み",
        alreadyFild: "アーカイブ済み",
        waitReview: "再確認待ち",
        alreadyRead: "既読",
      },
      samplingFrequencyTypes: {
        everyDay: "毎日",
        everyWeek: "毎週",
        everyMonth: "毎月",
      },
      samplingEveryDayTimeTypes: { yesterday: "昨日", today: "今日は" },
      samplingEveryWeekTimeTypes: { thisWeek: "今週", lastWeek: "先週" },
      samplingEveryMonthTimeTypes: { thisMonth: "今月", lastMonth: "先月" },
      samplingTypes: {
        random: "ランダムサンプリング",
        proportional: "スケールサンプリング",
      },
      weekdays: {
        Monday: "月曜日",
        Tuesday: "火曜日",
        Wednesday: "水曜日",
        Thursday: "木曜日",
        Friday: "金曜日",
        Saturday: "土曜日",
        Sunday: "日曜日",
      },
      distributeTypes: { rule: "ルール優先配分", average: "平均ランダム分配" },
      notMatchAnyRuleTypes: {
        random: "ランダム平均分配",
        assign: "指定者に割り当てます",
        ignore: "割り当てない",
      },
      inspectorTypes: { all: "すべての品質検査員", some: "指定品質検査員" },
      pushFrequencyTypes: {
        everyDay: "毎日",
        everyWeek: "毎週",
        everyMonth: "毎月",
        inspectionUpdate: "品質検査の更新",
        timelyInspection: "リアルタイム品質検査",
      },
      pushObjectTypes: {
        email: "指定メールアドレス",
        staff: "所属カスタマーサービス",
      },
      reviewOptions: {
        reviewResult: "再確認時に品質検査結果を表示します",
        reviewRecord: "再確認時に関連記録を表示する",
        reviewAgentHidden: "再確認時にカスタマーサービス情報を非表示にする",
        samplingResult: "抽出検査時に品質検査結果を表示する",
        samplingRecord: "抽出検査時に関連記録を表示する",
        sampleAgentHidden: "抽出検査時にお客様の情報を隠す",
        checkExportAddCallInfoIphone: "リストのエクスポート時に通話記録を添付",
        sampleExportAddCallInfoIphone: "リストのエクスポート時に通話記録を添付",
        checkExportAddCallInfoText: "リストのエクスポート時に会話記録を添付",
        sampleExportAddCallInfoText: "リストのエクスポート時に会話記録を添付",
        inspectionExportAddCallInfoIphone:
          "リストをエクスポートするときに通話記録を添付します (一度に最大10000件までエクスポート可能)",
        inspectionExportAddCallInfoText:
          "リストのエクスポート時に会話記録を添付します (一度に最大10000件までエクスポート可能)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "品質検査の詳細で迅速に抽出検査を実行できます",
        fastSampleInSampleDetail:
          "抽出検査の詳細で抽出検査を迅速に実行できるようにします",
        fastSampleInReviewDetail:
          "詳細を再確認して、迅速に抽出検査を実行できます。",
      },
      permitCustomReasonList: {
        permitCustomReason: "カスタム申し立てを許可する理由",
      },
      autoMateTasks: {
        autoLoad: "自動追加",
        sample: "自動抽出検査",
        assign: "自動分配",
        pushes: "自動プッシュ",
        assess: "自動評価",
        check: "苦情再検査",
        case: "ケースライブラリ同期",
      },
      qualityCheckDetailTypes: { review: "再確認", spotCheck: "抽出検査" },
      appealTypes: {
        transliterationError: "転写エラー",
        discriminationError: "判別エラー",
        otherError: "その他のエラー",
      },
      inspectDataSources: {
        voiceCall: "音声通話",
        textDialogue: "テキスト会話",
        realTimeVoiceCall: "リアルタイム音声通話",
        realTimeTextDialogue: "リアルタイムテキスト会話",
      },
      pointTypes: {
        automatic: "自動評価",
        manual: "手動評価",
        interactiveDetection: "インタラクティブ検出",
      },
      pointGradeTypes: { radio: "単一選択", input: "入力", select: "選択" },
      sampleAndReviewStatus: {
        init: "未抽出",
        unread: "未読",
        alreadyRead: "既読",
        complaining: "申し立て中",
        reviewed: "再確認済み",
        sampling: "抽出未検査",
        samplingCompleted: "抽出検査済み",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "オーバーフローを無視する",
        automaticWeightCalculation: "自動重み計算",
      },
      calculateScoreTypes: { add: "プラスポイント", sub: "減点" },
      naChecked: { check: "許可検査にはこの項目は含まれていません。" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "比例で分配する",
        allRight: "すべて正確に得点を与える",
        anyRight: "任意の正確な得点",
      },
      noHitQuestionResults: {
        true: "結果は「はい」です",
        false: "結果は「いいえ」です",
      },
      repetRules: { same: "内容はまったく同じ", similar: "意味が似ている" },
      relateConditions: {
        highlightQuestion: "ハイライトの問題",
        highlightAnswer: "答えを強調する",
        hightlightFoctors: "ハイライト要素",
      },
      interactiveQuestionTypes: {
        question: "問題が検出されました",
        eliminateQuestion: "トラブルシューティングの問題が検出されました",
      },
      interactiveAnswerTypes: {
        noAnswer: "答えが見つかりません",
        answer: "答えが検出されました",
        wrongAnswer: "間違った答えが検出されました",
      },
      inspectionUpdateTypes: {
        autoInspection: "自動品質検査",
        autoInspectionWechat: "自動分析",
        artificialSampling: "人工抽出検査",
        artificialReview: "手動再確認",
        manualModification: "高速抽出検査",
        timelyInspection: "リアルタイム品質検査",
        samplingAssign: "抽出検査分配",
        artificialComplain: "申し立て提出",
        fastSampling: "迅速な抽出検査の提出",
        recheckInspection: "品質検査のやり直し",
        recheckInspectionWechat: "再分析",
      },
      timelyInspectionTypes: { timelyInspection: "リアルタイム品質検査" },
      pushActionTypes: {
        email: "メール",
        interfacePush: "インターフェースプッシュ",
      },
      pushDetailWays: {
        riskAlert: "リスクリマインダー",
        subscribe: "購読メッセージ",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "通話/会話メッセージ",
        inspectionResults: "品質検査結果",
      },
      subscribePushDataTypes: {
        dialogueMessage: "通話/会話メッセージ",
        textContent: "通話/会話テキストの内容",
        inspectionResults: "品質検査結果",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "グループ内スコア平均",
        addOrSubtract: "直接加減分",
        noChange: "加減しない",
      },
      defaultScore: {
        empty: "空白です",
        add: "プラスポイント",
        notAdd: "加点しない",
        sub: "減点",
        notSub: "減点しない",
        na: "N/A",
        input: "手動入力",
        choose: "選択",
      },
      strategyTypes: {
        hitAdd: "ヒットプラス",
        hitNotAdd: "命中は加点しない",
        hitSub: "命中減点",
        hitNotSub: "命中不減点",
      },
    },
    download: {
      exportStatus: {
        prepare: "準備中",
        process: "進行中",
        faild: "エクスポートに失敗しました",
        success: "成功",
      },
    },
    messageType: {
      all: "すべて",
      monitor: "リアルタイムモニタリング",
      trigger: "トリガー通知",
      timing: "定時報告",
    },
    messagePushType: {
      all: "すべて",
      system: "システムメッセージ",
      sdk: "メッセージSDK",
      push: "インターフェースプッシュ",
      email: "メール",
      udesk: "Udesk通知",
      udeskWhite: "カスタマーサービスシステムの通知",
      weChat: "企業マイクロニュース",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "抽出検査員を分配する",
      sampleInspector: "抽出検査員",
      assignReviewer: "審査員を分配する",
      reviewer: "審査員",
      taskInspector: "任務品質検査員",
    },
    sdkPushType: {
      webHook: "標準webhook",
      dingDing: "ホッチキスロボット",
      weChat: "企業のwechatロボット",
      customer: "カスタムJsonコンテンツ",
    },
    inspectionRemarksType: {
      inspection: "品質検査",
      check: "抽出検査",
      review: "再確認",
    },
    segmentationStrategyType: {
      count: "メッセージ数",
      spacing: "メッセージ間隔",
    },
    segmentationStrategy: { byTime: "定時分割", customize: "組み合わせ分割" },
    inspectType: {
      commonInspect: "通常品質検査",
      associateInspect: "関連品質検査",
    },
    typoDetectionOperators: { less: "未満", greater: "より大きい" },
    autoAddTypes: { realTimeAdd: "リアルタイムで追加", timingAdd: "定時追加" },
    messageTypes: {
      text: "テキストメッセージ",
      markdown: "Markdownメッセージ",
    },
    inServiceStatus: {
      activated: "アクティブ",
      disabled: "無効",
      inactivated: "アクティブ化されていません",
      resigned: "企業を脱退する",
    },
    roles: { agent: "スタッフ", customer: "お客様" },
    commentVisibleAuthority: {
      all: "すべての人に見える",
      self: "自分だけが見える",
      designation: "指定された人が表示されます",
    },
    appealCheckingTask: {
      eCommerceReview: "Ecレビュー",
      socialMediaComments: "ソーシャルメディアのコメント",
      largeModel: "ビッグモデル",
      customModel: "カスタムモデル",
      universalModel: "共通モデル",
      enterpriseWeChatText: "企業マイクロテキスト",
      generalQualityInspection: "普通品質検査",
      processQualityInspection: "プロセス品質検査",
      voiceCopy: "音声再生",
      beEqualTo: "等しい",
      notEqualTo: "等しくない",
      canAppeal: "申し立てができます",
      noAppeal: "訴えてはいけない",
    },
    wechat: {
      redEnvelopes: "レッドバッグ",
      text: "テキスト",
      picture: "画像",
      voice: "音声",
      voiceCall: "音声通話",
      video: "動画",
      businessCard: "名刺",
      position: "位置",
      expression: "表情",
      link: "リンク",
      applet: "アプレット",
      chatRecord: "チャット記録",
      mixedMessage: "混合メッセージ",
      file: "ファイル",
    },
    gong: {
      sessionActivity: "会話活動",
      conversationAction: "会話アクション",
      riskSemantics: "リスクセマンティック",
      daysNotFollowedUp: "未フォローアップ日数",
      waysideData: "随行データ",
      notStarted: "開始していません",
      completed: "完了しました",
      haveInHand: "進行中",
      unpublished: "未公開",
      published: "公開済み",
      knowledgePoints: "知識ポイント",
      technologicalProcess: "流れ",
      notMarked: "マークされていません",
      inAnnotation: "マークアップ中",
      annotated: "マークアップ済み",
    },
    qualityChecingTask: {
      appealCanceled: "申し立てキャンセル",
      complaintExpired: "期限が切れたと訴える",
      incomingCall: "呼び出し",
      exhale: "呼出",
      incomingCallthirdParty: "着信 (三者)",
      incomingCallconsultation: "着信 (問い合わせ)",
      incomingCallforcedInsertion: "着信 (強いプラグ)",
      incomingCallmonitoring: "着信 (傍受)",
      incomingCalltransfer: "着信 (転送)",
      incomingCallinterception: "着信 (ブロック)",
      incomingCalltransferToExternalLine: "着信 (外部ケーブルの転送)",
      incomingCallthirdPartyExternalLine: "着信 (三方外線)",
      incomingCallexternalLine: "着信 (外線にお問い合わせ)",
      outgoingCallthirdParty: "呼出(三者)",
      outgoingCalltransfer: "呼出 (転送)",
      outgoingCallforcedInsertion: "呼出 (強いプラグ)",
      outgoingCallmonitoring: "呼出 (傍受)",
      outgoingCallinterception: "呼出 (ブロック)",
      outgoingCalltransferToExternalLine: "呼出 (外線接続)",
      outgoingCallthreePartyExternalLine: "呼出(三方外線)",
      customerServiceAnswer: "カスタマーサービスの応対",
      customerServiceMissed: "カスタマーサービス未受付",
      customerAnswer: "顧客応対",
      customerMissed: "顧客未接続",
      theTelephoneIsBusy: "電話線",
      phoneOffline: "電話オフライン",
      customerQuickHanging: "客先速掛",
      clientHangsUp: "お客様のオンフック",
      queueTimeout: "順番待ちタイムアウト",
      giveUpQueuing: "待ち行列を放棄する",
      outsideLineAnswering: "外線応対",
      externalLineIsNotConnected: "外線未接続",
      noQueueSelected: "キューが選択されていません",
      notEvaluated: "未評価",
      noEvaluationRequired: "評価不要",
      queuingSucceeded: "順番待ちに成功",
      noCustomerServiceOnline: "カスタマーサービスなしオンライン",
      customer: "お客様",
      customerService: "カスタマーサービス",
      outsideLines: "外線",
    },
    sessionActivity: {
      single: "シングル平均通話時間",
      all: "総通話量",
      allTime: "通話総時間",
    },
    sessionAction: {
      salesProportion: "販売会話比率",
      salesLongest: "平均最長販売発言",
      customerLongest: "平均最長顧客発言",
      discussNumber: "踏み込んだ討論回数",
      frequency: "質問頻度",
    },
    monitorCriteria: { all: "前後", before: "前", after: "後" },
    monitorTimeCycle: { day: "日", week: "週", month: "月" },
    monitorGroupTestType: {
      ab: "2つのグループの比較",
      a: "グループA",
      b: "グループB",
    },
    fields: {
      department: "部門",
      customerTags: "顧客ラベル",
      member: "メンバー",
      time: "時間",
      cascade: "カスケード",
      anyMatch: "任意マッチ",
      keyWord: "キーワード",
      keyEvents: "キーイベント",
    },
    semanticIntelligence: {
      staff: "スタッフ",
      customer: "お客様",
      employeesAndCustomers: "従業員と顧客",
      notStarted: "開始していません",
      haveInHand: "進行中",
      completed: "完了しました",
      callRecording: "通話録音",
      enterpriseWeChatCall: "企業のマイクロ通話",
      enterpriseWeChatText: "企業マイクロテキスト",
      discourseMiningForObjectionHandling: "異議処理術の発掘",
      productSellingPointScriptsMining: "製品のセールスポイント",
      conversationScenario: "会話シーン",
      topicOfConversation: "話題",
      keyEvents: "キーイベント",
      customerLabel: "顧客ラベル",
      includeAny: "任意を含む",
      includeAll: "すべてを含む",
      and: "かつ",
      or: "または",
      question: "疑問文",
      nonInterrogativeSentence: "非疑問文",
    },
    coach: {
      notPassed: "不合格",
      analogTelephone: "アナログ電話",
      enterScenarioInformation: "シーン情報を入力します",
      generateDialogueFramework: "会話フレームワークの生成",
      generatePromptLanguage: "提示語を生成する",
      aIGeneratedDialogue: "AI生成会話",
      system: "システム",
      custom: "カスタム",
      flow: "流れ",
      script: "話術",
      express: "表現",
      emotion: "感情",
      rateAccordingToCourseConfiguration: "コース別に評価を設定します",
      intelligentModelRating: "スマートモデルの評価",
      allData: "全データ",
      dataFromThisDepartment: "本部門データ",
      multidepartmentalData: "複数部門データ",
      underReview: "審査中",
      no: "いいえ",
      yes: "はい",
      automaticRating: "自動評価",
      auditRating: "評価を審査する",
      reRating: "再評価",
      inAppeal: "申し立て中",
      reviewed: "再確認済み",
      notViewed: "確認していません",
      viewed: "確認済み",
      uncorrected: "未修正",
      corrected: "修正済",
      practice: "練習",
      examination: "試験",
      other: "その他",
      notMeetingStandards: "基準に達していません",
      meetingStandards: "基準達成",
      excellent: "優秀",
      invalid: "失効",
      notStarted: "開始していません",
      haveInHand: "進行中",
      ended: "終了しました",
      completed: "完了しました",
      hangInTheAir: "未完了",
      passed: "通過済み",
      voiceConversation: "音声会話",
      textDialogue: "テキスト会話",
      slidePresentation: "スライドスピーチ",
      unpublished: "未公開",
      published: "公開済み",
      narration: "ナレーション",
      studentSpeaking: "学習者が話す",
      robotTalk: "ロボットが話す",
      knowledgeBaseQA: "知識リポジトリのq & a",
      slide: "スライド",
      negativeEmotions: "感情的なマイナス方向",
      emotionallyNormal: "気分が正常",
      incompleteScript: "話が不完全です",
      completeScript: "話が完全である",
      normalSpeechSpeed: "話のスピードは正常です",
      speakTooFast: "言葉の速さが速すぎる",
      speakTooSlowly: "言葉の速さが遅すぎる",
      whole: "すべて",
      singlePage: "単一ページ",
      qA: "一問一答",
      situationalDialogue: "情景対話",
      misses: "ヒットしません",
      hit: "ヒット",
      sequentialQA: "順番問答",
      randomQA: "ランダムq & a",
      mastered: "把握済み",
      notMastered: "未把握",
    },
    algorithm: {
      salesEmpowerment: "販売エンパワーメント",
      enterpriseWeChatVoice: "企業のwechat音声",
      enterpriseWeChatText: "企業のwechatテキスト",
      waiting: "待っています",
      extracting: "抽出中",
      success: "成功",
      fail: "失敗",
      intelligentQualityInspection: "スマート品質検査",
      textDialogue: "テキスト会話",
      voiceCall: "音声通話",
      smartTags: "スマートラベル",
      satisfactionEvaluation: "満足度評価",
      score: "評価",
      grade: "評価",
      customerServiceTeam: "カスタマーサービス",
      conversationAnalysis: "セッション分析",
    },
    operatorListGather: {
      businessSummary: "業務のまとめ",
      firstSentence: "最初の文",
      lastSentence: "最後の文",
      allTime: "すべての時間",
      nonBusinessWorkingHours: "非業務勤務時間",
      businessWorkingHours: "業務勤務時間",
    },
    callDialogueManage: {
      eCommerceReview: "Ecレビュー",
      socialMediaComments: "ソーシャルメディアのコメント",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "ログイン",
        email: "メールアドレス",
        password: "パスワード",
        forgetPwd: "パスワードを忘れました",
        emailCannotBeEmpty: "メールアドレスは空白にできません。",
        passwordCannotByEmpty: "パスワードは空白にできません。",
        mustChangeLogin:
          "パスワードをリセットする必要があります。管理者に連絡してパスワードをリセットしてください。",
        dismissionLogin: "申し訳ありませんが、退職しました。",
        forbiddenLogin: "申し訳ありませんが、アカウントは無効になっています。",
        loginError:
          "ログインに失敗しました。メールアドレスまたはパスワードが間違っています。",
        loginPrompt: "システムにログイン",
        rememberMe: "私を覚えておいてください",
      },
      logout: {
        logoutError:
          "ログアウトに失敗しました。ネットワーク接続を確認するか、後で再試行してください。",
      },
      resetPassword: {
        forgetPwd: "パスワードを忘れました",
        inputEmailPrompt:
          "下にアカウントのメールアドレスを入力します。パスワードをリセットするメールと説明をお送りします。",
        sendEmailSuccess:
          "パスワードを修正して手紙が記入したメールアドレスに送信されたことを確認します。すぐに確認してください。",
        sendEmailFailed:
          "メールボックスの送信に失敗しました。ネットワーク接続を確認するか、後で再試行してください。",
        setNewPassword: "新しいパスワードを設定します",
        passwordCannotEmpty: "パスワードは空白にできません",
        passwordFormWrong: "パスワードのフォーマットが正しくありません",
        confirmPasswordWrong: "2回入力したパスワードが等しくありません。",
        passwordResetSuccess: "パスワードのリセットに成功しました。",
        passwordResetFailed:
          "パスワードのリセットに失敗しました。ネットワーク接続を確認するか、後で再試行してください。",
        linkTimeover:
          "パスワードリセットのリンクが期限切れです。再試行してください。",
      },
      form: { title: "CASEフォームの表示" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "メールアドレス",
          emailFormatError:
            "メールアドレスのフォーマットが間違っています。再入力してください。",
          forgotPassword: "パスワードを忘れましたか?",
          describe:
            "下にアカウントのメールアドレスを入力します。パスワードをリセットするメールと説明をお送りします。",
          send: "送信",
          emailCannotBeEmpty: "メールアドレスは必須項目です",
          resetPasswordExpire:
            "パスワードリセットのリンクが期限切れです。再試行してください。",
        },
        resetPassword: {
          setPassword: "新しいパスワードを設定します",
          newPassword: "新しいパスワード",
          repeatPassword: "重複パスワード",
          resetPassword: "パスワードのリセット",
          passwordCannotBeEmpty: "新しいパスワードは必須項目です。",
          confirmPasswordCannotBeEmpty: "重複パスワードは必須項目です。",
          passwordsNotMatch: "2回パスワードが一致しません!",
          passwordFormWrong:
            "パスワードは中国語を持つことができません。しかも個数は6-14桁です。",
          linkExpiration:
            "パスワードリセットのリンクが期限切れです。再試行してください。",
        },
      },
      errors: {
        haveNoPermissionWarningText: "このページの表示権限がありません",
      },
    },
    downloadCenter: {
      name: "ダウンロードセンター",
      saveTime: "(デフォルトで30日間保存)",
      headerTitle: "ファイルのダウンロード",
      download: "ダウンロード",
      downloadPromp: "ダウンロードタスクが送信されました。",
      viewProgress: "進捗の確認",
    },
    uploadCenter: { name: "アップロードセンター" },
    tasks: {
      index: {
        name: "名称",
        remark: "説明",
        qualityCheckTasksTitle: "作業台",
        informationCollection: "収集タスク",
        qualityCheckTasks: "品質検査タスク",
        wechatQaTasks: "タスクの分析",
        moduleName: "テンプレートを使用した新規作成",
        creatNewQualityCheckTask: "タスクの新規作成",
        generalInspectionList: "共通品質検査報告書",
        editQualityCheckTask: "品質検査タスクの編集",
        creatNewQualityCheckTaskError: "品質検査タスクの新規作成に失敗しました",
        editQualityCheckTaskError: "品質検査タスクの編集に失敗しました",
        type: "フォームタイプ",
        singleScore: "単一点数",
        singleScoreNullError: "個別スコアは必須項目です",
        inspector: "品質検査員",
        inspectDataSource: "データソース",
        taskName: "タスク名",
        inspectionType: "品質検査タイプ",
      },
      manage: {
        screeningCondition: "フィルタ条件",
        template: {
          condition: {
            index: { name: "条件", existingConditions: "既存の条件" },
            detail: {
              id: "ID",
              name: "条件名称",
              remark: "説明",
              checkRange: "検査範囲",
              operator: "オペレータ",
              operatorLogic: "オペレータロジック",
            },
          },
          checkPointTest: {
            title: "ルールテスト",
            running: "ルールテスト中です。しばらくお待ちください… は...",
            clearSuccess: "模擬会話クリア成功",
            mock: "模擬会話",
            save: "選択した会話を保存します",
            saveAll: "会話をすべて保存",
            saveSuccess: "会話保存成功",
            close: "脱退",
            delete: "選択を削除",
            clear: "クリア",
            customer: "お客様",
            agent: "カスタマーサービス",
            test: "テスト",
            request:
              "模擬会話を空にすると元に戻すことはできません。空にすることを確認しますか?",
            deletePopconfirmContent: "選択した会話を削除しますか?",
          },
          checkPoint: {
            index: {
              name: "ルール設定",
              wechatTitle: "解析モデル配置",
              existingCheckPoints: "品質検査ルールがあります",
              currentCheckPointScore: "現在の品質検査ルールのスコアは",
              currentCheckPointScoreWechat: "現在の分析モデルのスコアは",
              currentCheckPointWeight: "現在の品質検査ルールの重みの合計は",
              weightTotalScoreAndItemScore: "総得点: 100点個別点:",
              template: "品質検査ルールテンプレート",
              import: "品質検査ルールテンプレートのインポート",
              getTemplateError:
                "品質検査ルールテンプレートの取得に失敗しました",
              tabs: { template: "テンプレート", demo: "サンプル" },
              customize: "空白のテンプレート",
              move: "ドラッグして分類とソートを調整します",
              classification: "品質検査の分類",
              classificationWeChat: "モデル分類",
              first: "一次分類",
              second: "二次分類",
              new: "品質検査ルールの新規作成",
              weChatNew: "分析モデルの追加",
              createWeChat: "分析モデルの新規作成",
              empty: "品質検査ルールはありません",
              wechatEmpty: "分析モデルはありません",
              newSecond: "二次分類の新規作成",
              newFirst: "新しい一次分類",
              checkPoint: "品質検査ルール",
              checkPointWeChat: "解析モデル",
            },
            detail: {
              name: "名称",
              remark: "説明",
              type: "タイプ",
              deduction: "減点",
              rule: "ルール",
              highlighted: "ハイライト",
              gradeType: "採点方法",
              interactiveCategory: "インタラクティブ分類",
              predeterminedScore: "スコア",
              weight: "重み",
              na: "N/A",
              default: "デフォルトスコア",
              preRule: "前置条件ルール",
              analysisHighlighted: "解析ルール",
              categorySelectPrompt: "分類を選択してください",
              checkHighlightArrayError:
                "ルールとハイライトが一致しません。ルール解析ボタンをもう一度クリックしてください。",
              foctorsHolder: "要素を選択してください",
              foctorsErrorNone:
                "複数要素ルールが空白です。複数要素タイプを追加してください",
              naError:
                "デフォルトスコアがN/Aの場合は、この項目を閉じることはできません",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/Aは、この品質検査ルールの前提条件を設定し、条件を満たした場合に検出し、条件を満たしていない場合にはこのルールが適用されない場合は、このルールを検出しない\n前置条件記入説明: "&&" は「和」、"||" は「または」、「!」「非」を表し、例「 (R1 || R2) && R3」を入力します',
                applicationMessageType: "適用メッセージタイプ",
                selectAll: "すべて選択",
                highlightTags: "ハイライトラベル:",
                ruleType: "ルールタイプ",
                dataSource: "データソース",
                whole: "すべて",
                enterpriseMicroConversation: "企業マイクロセッション",
                enterpriseAndMicroCommunication: "企業のマイクロ通話",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "判断ノード",
                    dialogueNode: "会話ノード",
                    editNodes: "ノードの編集",
                  },
                  nodeConfig: {
                    nodeName: "ノード名",
                    nodeRules: "ノードルール",
                    scoringLogic: "採点ロジック",
                    score: "スコア:",
                    rule: "ルール",
                    multiBranchLogic: "複数分岐ロジック",
                    highlight: "ハイライト",
                    submit: "送信",
                  },
                  ruleList: {
                    branch: "ブランチ",
                    branchName: "分岐名:",
                    addRules: "追加ルール",
                  },
                  sidebar: { node: "ノード" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "自動整理",
                      canvasAdaptation: "キャンバス適応",
                      copy: "コピー",
                      delete: "削除",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "判断ノードの子ノードは接続先とはならない",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "開始ノードは接続先にはできません",
                      startingNodeCannotConnectMultipleNodes:
                        "開始ノードは複数のノードに接続できません",
                      nodeCannotBeConnectedToItself:
                        "ノードは自分と接続できません",
                      startNode: "開始ノード",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "全選択をキャンセル",
                    selectAll: "全選択",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "合っているかどうか",
                    gradedEvaluation: "段階別評価",
                  },
                },
              },
            },
            configuration: {
              title: "品質検査ルール",
              wechatTitle: "解析モデル",
              neme: "名称",
              errorMessage:
                "削除に失敗しました。少なくとも一つの条件が必要です",
              desc: "説明",
              status: "ステータス",
              yes: "有効",
              no: "無効",
              namePlaceHolder: "ラベル名を入力してください",
              desPlaceHolder: "説明を入力してください",
              nameEmpty: "名称は必須項目です",
              desEmpty: "説明は必須項目です",
              type: "タイプ",
              ruler: "スマートルール",
              machineLearning: "自己学習モード",
              autoScore: "スマートスコア (旧)",
              manualScore: "手動評価",
              interaction: "インタラクティブ検出 (旧)",
              typeTips: {
                model:
                  "スマートモデル: 事前に設定された品質検査システムで、メンテナンスを構成する必要がなく、サービスに存在する問題を迅速に品質検査することができる。このモデルは一般的で、ビジネスの要求を満たさない場合は「スマートルール」または「手動評価」を使用してください",
                rule: "スマートルール: ルールAIに基づいて自動的に評価されます",
                manual: "手動評価: 人工品質検査で、手動で採点します",
              },
              model: "モデル:",
              ruleType: {
                words: "話術",
                interaction: "インタラクティブ",
                feature: "特徴",
                information: "情報",
              },
              testingMode: {
                all: "すべてのキーワードを検出",
                any: "任意のキーワードを検出",
              },
              operatorTypesOfInteraction: {
                question: "一問一答",
                element: "要素回答",
                repet: "コンテキスト重複",
                snatch: "言葉を奪う",
                silent: "サイレント",
              },
              role: "検出ロール:",
              preposition: "前置きオペレータ:",
              range: "検査範囲:",
              from: "第",
              to: "文から第まで",
              end: "文",
              hasOldSilentTip:
                "こんにちは。精度を上げるために、「スマートルール-インタラクティブ-サイレント」が「応答タイムアウト」にアップグレードされました。使用ルールを更新することをお勧めします",
              changeToOld: "旧バージョンに切り替える",
              changeToNew: "新しいバージョンに切り替える",
              logic: {
                logic: "採点ロジック",
                naTip:
                  "N/Aは、この品質検査ルールの前提条件を設定し、条件を満たしている場合は検出し、条件を満たしていない場合はこのルールが適用されない場合は、このルールを検出しません",
                score: "スコア:",
                na: "N/A：",
                naDes: "許可検査にはこの項目は含まれていません。",
                preRule: "事前条件ルール:",
                notCatchPreRule: "前置条件を満たさない場合:",
                naChecked: "N/Aにヒットした場合:",
                preRuleEmpty: "事前条件ルールは必須項目です",
                operatorEmpty: "オペレータの必要条件は必須項目です",
                multipleEmpty: "複数の論理スコアに必要な条件は必須項目です",
                average: "グループ内スコア平均",
                addOrSubtract: "直接加減分",
                noChange: "加減しない",
                rule: "ルール:",
                all: "すべてのオペレータを満たす",
                any: "任意のオペレータを満たす",
                customize: "カスタムオペレータロジック",
                multiple: "複数の論理スコア",
                heightLight: "ハイライト:",
                machineLearningTip:
                  "自己学習モデルは現在、単文話術にのみ有効で、複雑な話術は知能ルールを使用してください",
                strategy: "ポリシー:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "承認提出に成功しました",
            },
            components: {
              pageHeaderExtra: {
                test: "テスト",
                formal: "正式",
                submit: "送信",
              },
              createModal: { index: { myRules: "マイルール" } },
            },
          },
          automaticAdd: {
            index: {
              name: "プロセス自動化",
              screeningCondition: "フィルタ条件",
              aiScreeningCondition: "スマートスクリーニング条件",
              rule: "ルール",
              automaticAddition: "自動追加",
              automaticAdditionTip:
                "自動リアルタイムまたは定時にデータをタスクに追加してAI品質検査を行う",
              autoAdd: {
                autoAddType: "自動追加方式",
                addFrequency: "頻度の追加",
                add: "追加",
              },
              automaticSamplingInspection: "自動抽出検査",
              automaticSamplingInspectionTip:
                "人工品質検査が必要なデータを自動的に抽出する。例えば、毎日昨日のランダムな10% のデータを抽出して人工的に抽出する",
              everyAgentCheckLimit: "カスタマーサービスごとの抽出上限",
              automaticAllocation: "自動分配",
              automaticAllocationTip:
                "抽出検査分配: 抽出したデータは自動的に品質検査員に分配され、例えば、毎日昨日のランダムな10% のデータを抽出して人工的に抽出検査を行い、平均ランダムにこの任務に割り当てられた2人の品質検査員",
              automaticAllocationTip2:
                "再確認分配: クレームされたデータは自動的に品質検査員の再確認に割り当てられます。例えば、毎日のクレームのデータはランダムにこのタスクの2人の品質検査員に再確認されます。",
              automaticPush: "自動プッシュ",
              automaticPushTip:
                "トリガー通知: 品質検査時に通知/警告を行います。例えば、カスタマーサービスで致命的なエラーが発生した場合、企業のマイクログループに通知メッセージを送ります。",
              automaticPushTip2:
                "定時報告: 品質検査結果をメールで定時報告書に送る。例えば、毎日9:00に昨日の人工抽出検査の品質検査結果をコール担当者のメールアドレスに送る",
              automaticAssess: "自動評価",
              automaticAssessTip:
                "定時に人工抽出検査結果をAI品質検査結果と比較して精度評価を行う。例えば、毎日8:00に昨日抽出したデータを精度評価します",
              reexaminationComplaint: "苦情再検査",
              spoktReviewDetail: "業務設定",
              spoktReviewDetailTip:
                "品質検査リスト、抽出検査リスト、クレーム業務、再確認リスト、備考などの機能設定",
              qaDetailAction: "品質検査設定",
              spoktDetailAction: "抽出検査設定",
              reviewDetailAction: "再確認設定",
              appealAction: "クレーム設定",
              remarkTemplate: "備考テンプレート",
              automaticArchiving: "自動アーカイブ",
              conditionalRules: "条件ルール",
              qualityInspectionConditions: "品質検査条件",
              unopened: "一時的にオンになっていません",
              sampleInspectionDistributionModel: "抽出検査分配",
              reviewAllocationModel: "再確認分配",
              distributionModelTitle: "分配ポリシー:",
              pushRules: "プッシュルール",
              push: "プッシュ",
              assessRules: "評価ルール",
              on: "on",
              off: "off",
              updateStatusSuccess: "ステータスの変更に成功しました!",
              updateStatusFailed:
                "ステータスの変更に失敗しました。後でやり直してください!",
              sampleNumberOrPercent: "サンプリング件数または割合",
              inspectorList: "指定品質検査員",
              numberOfSubmission: "申請回数の提出を許可する",
              rewivewDays: "日",
              TimeOfSubmission: "申請提出の有効期間",
              rewivewSecond: "次",
              switchOn: "オン",
              switchOff: "閉じる",
              notMatchAnyRule: "任意のルールにマッチしない場合:",
              appealOptionsList: {
                hiddenInspector: "非表示品質検査員の情報を訴える",
                hiddenHit: "苦情ページはハイライトヒットをサポートしています",
              },
              caseLibrary: "ケースライブラリ同期",
              caseLibrarySyncTip:
                "QAシステムが追加したケースをコールシステムに同期する",
              addCase: "同期を追加",
              caseLibraryId: "ケースライブラリ",
              caseLibraryIdTip: "QAシステムのケースライブラリグループ",
              udeskCaseLibraryIdTip:
                "カスタマーサービスシステムのケースグループ",
              syncHis: "同期履歴ケース",
              otherSettings: "その他の設定",
              udeskCaseLibraryId: "プッシュケースグループ",
              url: "プッシュURL",
              urlTip:
                "http:// またはhttps:// で始まるインタフェースアドレスを入力してください",
            },
            templateNew: {
              secondaryReview: "二次再確認",
              numberOfAppealsAllowedToBeSubmitted: "苦情の提出を許可する回数",
              validityOfSubmissionOfAppeal: "申立ての有効期間",
              validityOfAppealReview: "再確認の有効期間を訴える",
              hours: "時間",
              businessSummary: "ビジネスの小結",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "顧客サービスごとに下限を抽出",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "ビジネスの状況に応じて生成次元をカスタマイズできる大きなモデルによってビジネスの小結みが自動的に生成されます。",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "一次審査員のみの分配戦略",
              secondaryCompounding: "二次複合:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "二次審査員は平均分配のみをサポートしています",
              secondLevelReviewer: "二次審査員:",
              samplingInspectionTarget: "抽出検査目標",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "人工抽出検査を達成すべき目標を設定し、目標が達成されていないか、超過した場合に提示する。例えば、顧客サービスごとに毎月100件の抽出検査を完了し、80件を下回る場合は、品質検査、抽出検査ページを提示する。",
            },
            component: {
              verificationFailed: "検証に失敗しました",
              pleaseAddASecondLevelQualityInspector:
                "二級品質検査員を追加してください。",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "提示語",
                    fieldName: "フィールド名",
                    fieldDescription: "フィールドの説明",
                    generateContent: "コンテンツの生成",
                    enabled: "有効化しますか",
                    generationMethod: "生成方式",
                    automaticGeneration: "自動生成",
                    manualTrigger: "手動トリガー",
                    filterCondition: "フィルタ条件",
                    return: "戻る",
                    preserve: "保存",
                  },
                  components: {
                    training: { index: { generateResults: "結果を生成する" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "限定されない",
                    limitedScope: "限定範囲",
                    add: "追加",
                    thereIsCurrentlyNoDataAvailable: "データなし",
                  },
                },
              },
              index: {
                fieldName: "フィールド名",
                fieldDescription: "フィールドの説明",
                nothing: "なし",
                generationMethod: "生成方式",
                generateManually: "手動生成",
                automaticGeneration: "自動生成",
                generateContent: "コンテンツの生成",
                notQualified: "限定されない",
                filterCondition: "フィルタ条件",
                enabled: "有効化しますか",
                type: "タイプ",
                system: "システム",
                custom: "カスタム",
                operation: "操作",
                edit: "編集",
                areYouSureToDeleteIt: "本当に削除しますか?",
                delete: "削除",
                addField: "フィールドを追加",
              },
            },
          },
          roleConfig: {
            index: {
              name: "ロール設定",
              roleConfigTip:
                "インポートした通話データがモノラルの場合、通話者分離処理の識別ルールを設定するために使用します。ルールに適合したのはカスタマーサービスの役割です。",
              operator: "オペレータ",
              operatorLogic: "オペレータロジック",
            },
          },
          manualAdd: {
            index: {
              name: "手動追加",
              tip: "条件を手動で設定してプレビューデータをフィルタし、品質検査タスクに迅速に追加するために使用します",
              screeningCondition: "フィルタ条件で追加",
              aiScreeningCondition: "スマートスクリーニング条件",
              rule: "ルール",
              createTime: "時間を追加",
              filterTitle: "コンテンツをフィルタリングする",
              filterRule: "フィルタ条件",
              startTime: "開始時間",
              endTime: "終了時間",
              records: "記録のインポート",
              createUserName: "オペレーター",
              successCount: "追加に成功しました",
              conditionRule: "条件ルール:",
              preview: "プレビュー",
              previewData:
                "現在、フィルタ条件{0}条を満たしています。タスク {1}条に参加しました。タスク {2}条に参加できます。タスク {3}条に参加できません。",
              failCount: "追加に失敗しました",
            },
          },
          basicInfo: {
            index: {
              title: "基本情報",
              name: "名称",
              taskName: "タスク名",
              taskNamePlaceholder: "タスク名を入力してください",
              requiredName: "タスク名は必須項目です",
              taskRemarkPlaceholder: "タスクの説明を入力してください",
              requiredRemark: "タスク記述は必須項目です",
              remark: "説明",
              taskRemark: "タスクの説明",
              source: "データソース",
              benchmarkScore: "基準分",
              benchmarkScorePlaceholder: "基準点を入力してください",
              scoreLowerLimit: "最低得点下限",
              scoreUpperLimit: "最高得点上限",
              inspectType: "品質検査タイプ",
              nlpModel: "NLPモデル",
              commonInspect: "通常品質検査",
              associateInspect: "関連品質検査",
              associateCycle: "関連周期",
              associateRegulation: "関連ルール",
              judgeStrategy: "実行ロジック",
              rule: "ルール",
              commonTip:
                "通常の品質検査: シングルパス、会話に適用し、シングルコミュニケーションの内容を品質検査する",
              associateTip:
                "関連品質検査: 複数回の通、会話に適用し、複数回のコミュニケーションの内容を関連付けて品質検査を行う",
              cycleTip:
                "関連する複数の通信、会話がある場合、関連期間内のデータはこのタスクの品質検査に入ります。",
              scoreLowerLimitPlaceholder: "最低得点下限を入力してください",
              scoreUpperLimitPlaceholder: "最高得点上限を入力してください",
              call: "音声通話",
              dialogue: "テキスト会話",
              user: "品質検査員",
              wechatUser: "オペレーター",
              noDesignated: "指定しない",
              type: "フォームタイプ",
              singleScore: "単一点数",
              conduct:
                "まず以下の情報を充実させてください。完成したら「次へ」をクリックしてください。",
              conduct2:
                "品質検査ルールと品質検査ポイントの設定を完了してください。設定が完了している場合は、「次へ」をクリックするか、ガイドを終了できます",
              conduct3:
                "品質検査評価は品質検査ポイントとその評価状況に基づいて評価する構成で、異なる品質検査結果レベルを定義できます。評価に必要がない場合は、この手順を直接スキップできます設定が完了したら、「次へ」をクリックするか、ガイドを終了できます。",
              conduct4:
                "自動追加場所で、本品質検査タスクが検査すべきデータ特性に基づいて、タスクに自動的に導入されるデータ特性を設定することができます。一時的にデータを自動的に追加する必要がない場合は「スキップ」をクリックできます。設定が完了したら、「次へ」をクリックできます",
              conduct5:
                "モノラル録音を使用している場合は、必ずこれを設定して「次へ」をクリックしてください。コールのみが使用するトークを使用して、コールと顧客の異なる軌道を識別してください.2チャンネル録音を使用している場合、または設定が完了している場合は、スキップをクリックできます",
              conduct6:
                "手動で機能を追加することで、システムにある会話データを導入して品質検査を行うことも、手動でタスクを追加することもできます。直接「完了」をクリックしてください",
              helper: "ヘルプドキュメントの表示",
              quit: "ガイドを終了します",
              next: "次へ",
              complete: "完了",
              skip: "スキップ",
              cancel: "キャンセル",
              delete: "削除",
              save: "保存",
            },
            template: {
              samplingInspector: "抽出検査員",
              sessionType: "セッションタイプ",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "品質検査作業台",
              search: "スクリーニング",
              export: "エクスポート",
              condition: "条件",
              logic: "ロジック",
              reQualityInspection: "品質検査のやり直し",
              reQualityInspectionUnderWay: "再品質検査タスク中… は...",
              reQualityInspectionUnderWayTip:
                "ウィンドウを閉じてもページを離れてもタスクの進行には影響しません",
              reQualityInspectionDone: "再品質検査が完了しました",
              reQualityInspectionDoneTip:
                "再品質検査が正常に完了しました。確認ボタンをクリックしてください。",
              endReQualityInspection: "タスクの終了",
              alreadyDoneTip: "完了しました",
              totalTip: "合計",
              unit: "条",
              endReInspectionSuccess: "再品質検査タスクは終了しました",
              closeWindow: "ウィンドウを閉じる",
              endReInspectionFailed:
                "再品質検査タスクに失敗しました。後で再試行してください。",
            },
            detail: {
              name: "品質検査の詳細",
              reviewName: "詳細を再確認する",
              spotCheckName: "抽出検査の詳細",
              audioText: "録音テキスト",
              callInfo: "通話情報",
              satisfactionEvaluation: "満足度評価:",
              queueTime: "順番待ち時間:",
              ringingTime: "呼び出し時間:",
              callTime: "通話イベント:",
              hangUpIdentity: "通話切断者:",
              location: "帰属地:",
              source: "ソース:",
              agent: "カスタマーサービス:",
              deviceType: "設備タイプ:",
              callType: "通話タイプ:",
              phoneNumber: "中継番号:",
              task: "タスク:",
              commentsTip: "品質検査コメントを入力してください",
              lastPage: "前のページ",
              nextPage: "次のページ",
              score: "品質検査評価",
              review: "再確認",
              file: "アーカイブ",
              changeList: "記録の変更",
              fullMarks: "満点",
              most: "最も多い",
              saveReview: "再確認提出",
              saveSpotCheck: "抽出検査の提出",
              point: "品質検査ポイント",
              sampleAssignInspector: "抽出検査員を分配する",
              reviewAssignInspector: "審査員を分配する",
              sampleStatus: "抽出検査状態",
              read: "既読",
              user: "ユーザー",
              confirmer: "確認者",
              voiceDownloadError:
                "音声ダウンロードに失敗しました。後でやり直してください。",
              smartTags: "スマートラベル",
              interactiveTip:
                "インタラクティブ識別を使用して、お客様のFAQの使用状況を検出します",
              interactiveIsEmptyTip:
                "インタラクティブ認識内容が検出されませんでした",
              businessRecords: "業務記録",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "企業のwechatグループチャット分析",
            },
          },
        },
      },
      cards: {
        health: "品質検査の健康度",
        accuracy: "発見承認率",
        sessionsTotal: "セッション総数",
        enable: "有効化",
        disable: "無効",
        none: "まだありません",
        call: "通話",
        dialogue: "会話",
        taobao: "電気商",
        wecom: "会話",
        ticket: "ワークオーダー",
      },
      component: {
        backup: "(バックアップ)",
        taskSaveAsSucceeded: "タスクの名前を付けて保存成功",
        copyContent: "コンテンツのコピー",
        essentialInformation: "基本情報",
        ruleConfiguration: "ルール設定",
        basicInformationQualityInspector: "基本情報-品質検査員",
        qualityInspectionRating: "品質検査評価",
        roleConfiguration: "ロール設定",
        autoAddConfiguration: "設定の自動追加",
        autoPushConfiguration: "自動プッシュ設定",
        automaticSamplingConfiguration: "自動抽出検査設定",
        automaticAllocationConfiguration: "自動割り当て設定",
        businessConfiguration: "業務設定",
        caseBaseConfiguration: "ケースライブラリ",
      },
      template: {
        cancel: "キャンセル",
        complete: "完了",
        sort: "並べ替え",
        ruleApproval: "ルール承認",
        enterpriseMicroConversationAnalysisTask:
          "企業マイクロセッション分析タスク",
        enterpriseAndMicroVoiceAnalysisTask: "企業のマイクロ音声分析タスク",
        saveTaskAs: "タスクを名前を付けて保存します",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "ルールの詳細" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "修正に成功しました",
            automaticApproval: "自動承認",
          },
          pageHeaderExtra: {
            modificationSucceeded: "修正に成功しました",
            test: "テスト",
            formal: "正式",
            adopt: "通過",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "本当に却下しますか?",
              pleaseEnterTheRejectionReason: "却下理由を入力してください",
              reject: "却下",
            },
          },
          table: {
            index: {
              modificationSucceeded: "修正に成功しました",
              taskName: "タスク名",
              dataSource: "データソース",
              qualityInspectionType: "品質検査タイプ",
              state: "ステータス",
              updateTime: "更新時間",
              updatedBy: "更新者",
              approver: "承認者",
              reasonForRejection: "却下理由",
              operation: "操作",
              details: "詳細",
              adopt: "通過",
              whole: "すべて",
              approvalStatus: "承認ステータス",
            },
          },
        },
      },
    },
    dashboard: {
      title: "概要",
      hi: "やあ、",
      standardEdition: "標準版",
      call: "音声品質検査残高",
      text: "テキスト品質検査の有効期間",
      collectionTasks: "収集した品質検査タスク",
      noCollection: "コレクションの品質検査タスクはありません",
      goToCollecte: "コレクションに行く",
      yesterdayOverView: "昨日の業務統計",
      yesterdayAgent: "昨日のカスタマーサービスのランキング",
      yesterdayInspWork: "昨日の品質検査員の仕事状況",
      operationalStatistics: {
        title: "業務統計",
        qualityTesting: "品質検査",
        spotCheck: "抽出検査",
        appeal: "申し立て",
        chartTitles: {
          qualityTestingTrend: "品質検査量トレンド",
          qualityTestingAnalysis: "品質検査タスク状況",
          spotCheckTrend: "抽出検査の傾向",
          spotCheckAnalysis: "抽出検査タスク状況",
          appealTrend: "訴えの傾向",
          appealAnalysis: "任務状況を訴える",
        },
        titles: {
          qualityDataTend: "品質検査データの分布",
          qualityCover: "品質検査のカバー状況",
          qualityAverageTime: "平均品質検査待ち時間",
          qualityCoverTend: "品質検査のカバー傾向",
          handCover: "手動処理状況",
          checkCover: "抽出検査カバー率",
          resAverageTime: "平均処理時間",
          handTend: "手動処理の傾向",
          dataTend: "データタスクの分布",
        },
        qualityAverage: "品質検査の平均点",
        scoreTrend: "品質検査評価トレンド",
        qualityAverageTime: "平均品質検査待ち時間",
        callCoverage: "音声品質検査カバー率",
        textCoverage: "テキスト品質検査カバー率",
        checkCount: "抽出検査データ量",
        siphonCount: "データ量の抽出",
        reviewCount: "再確認データ量",
        appealCount: "クレームデータ量",
        checkRate: "抽出検査の実行割合",
        reviewRate: "申立て再確認割合",
        PieTitle: {
          totalCount: "通話総数",
          totalCall: "音声通話数",
          totalIm: "テキスト会話数",
          inspectionCall: "音声品質検査量",
          inspectionIm: "テキスト品質検査量",
          checkCall: "音声抽出検査量",
          unCheckCall: "音声未抽出量",
          checkIm: "テキスト抽出量",
          unCheckIm: "テキスト未抽出量",
          reviewCall: "音声再確認量",
          reviewIm: "テキスト再確認量",
          unReviewCall: "音声未再確認量",
          unReviewIm: "テキスト未再確認量",
          unInspectionCall: "音声未品質検査量",
          unInspectionIm: "テキストは品質検査していません。",
        },
        check_rate: "抽出検査カバー率",
        voice_check_rate: "音声抽出検査カバー率",
        text_check_rate: "テキスト抽出検査被覆率",
        sample_execute_time: "平均抽出処理時間",
        review_execute_time: "平均クレーム処理時間",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "品質検査ルール看板",
          agent: "カスタマーサービスランキング",
        },
        tableTips: {
          conditions:
            "<Div> 品質検査ルール違反、コンプライアンスの会話総数をソートします。 <Br/>\n品質検査項目: 品質検査ポイントの名称<br />\n違反通対話総数: この品質検査項目で違反した通対話総数 <br />\n違反占有率: 違反占有率 = 違反通会話総数/通会話総数 </div>",
          agent:
            "<Div> 座席の平均スコアを並べ替える <br />\nコール名: コール名br />\nコールグループ: コールグループ (コール情報内の組織情報) br />\n評価の会話総数: このコールの品質検査総会話数br />\n品質検査平均得点: 当該顧客サービスの品質検査平均得点 (得点合計/通会話総数)</div>",
        },
        tips: {
          total: "品質検査タスクに入る通/会話の総数",
          agentTotal: "品質検査タスクに入った品質検査コールの総数",
          avg: "品質検査評価の平均点",
          violation:
            "すべての通会話品質検査が品質検査項目の違反にヒットした回数を合計する",
          compliance:
            "会話による品質検査中に品質検査項目のコンプライアンスにヒットした回数を合計する",
          check: "人工抽出検査の総数",
          appeal: "会話総数で提出した苦情の総数",
          review: "会話総数の中で再確認を提出した総数",
        },
        subTips: {
          violation: "違反率: 違反総回数/品質検査項目総回数 * 100%",
          compliance:
            "コンプライアンス率: コンプライアンス総回数/品質検査総回数 * 100%",
          check: "抽出検査被覆率:人工抽出検査総数/品質検査総量 * 100%",
          appeal: "提訴率: 提訴総数/品質検査総量 * 100%",
          review: "再確認率: 再確認総数/品質検査総量 * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "システム設定",
          systemName: "システム名",
          speakerRecognize: "話者識別",
          massage: "メッセージ通知",
          evaluate: "人工認知評価",
          evaluateTip:
            "オンにして品質検査の詳細で高速抽出検査を選択すると、手動評価を求めるメッセージが表示されます",
          speakerRecognizeTip:
            "APIを介して第三者の通話録音にアクセスするには、左チャンネルが顧客か顧客かを示す必要がある",
          speakerRecognizeTip2:
            "シングルチャンネル録音はテキスト認識で、役割は顧客サービスの役割を定義する",
          enabledImSendStatusFilter: "テキスト品質検査メッセージフィルタ",
          enabledImSendStatusFilterTips:
            "品質検査のオペレータ話術の検査内容には、状態が「撤回済み」、「送信失敗」、「オフラインメッセージ未読」のメッセージは含まれていない",
          lang: "システム言語設定",
          zh: "簡体字中国語",
          en: "English",
          tw: "繁体字中国語",
        },
        template: {
          largeModelSettings: "ビッグモデル設定",
          indonesian: "インドネシア語",
          vietnamese: "ベトナム語",
          portuguese: "ポルトガル語",
          malay: "マレー语",
          french: "フランス語",
          spanish: "スペイン語",
          japanese: "日本語",
          thai: "タイ語",
          blockSystemSendingMessages: "シールドシステムはメッセージを送信する",
          textDataDesensitization: "テキストデータ脱感作",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "スイッチをオンにすると、脱感作フィールドのドロップダウンリスト (複数選択対応) が表示され、脱感作フィールド: 人名、携帯電話番号、住所",
          desensitizingField: "非感作フィールド",
          name: "人名",
          cellphoneNumber: "携帯番号",
          address: "住所",
          dataCleaning: "データクリーニング",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "設置後、品質検査通の会話、企業のマイクロレターの会話データは定期的に整理され、回復できないので、慎重に操作してください",
          newCleaningRules: "クリーンアップルールの新規追加",
          qualityInspectionRuleApproval: "品質検査ルールの承認",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "オンにすると、品質検査ルールの修正が承認されてから有効になります",
          maskOfflineMessageUnread: "オフラインメッセージ未読をブロックする",
          privacyNumberProtection: "プライバシー番号保護",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            "オンにすると、通話中の「お客様の電話」フィールドが脱感作され、1881 ******** と表示されます",
          shieldingWithdrawn: "シールドが撤回されました",
          maskSendingFailed: "ブロック送信に失敗しました",
        },
        component: {
          conversation: "通話",
          dialogue: "会話",
          weCom: "企業wechat",
          day: "日",
          week: "週",
          month: "月",
          year: "年",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "範囲を整理する",
            dataCleaningTimeSetting: "データクリーニング時間の設定",
            afterCleanUpTheData: "後、データをクリーンアップします",
            dataAfterComplaintCompletion: "苦情完了後のデータ",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "実行に成功しました",
          saveSuccessful: "保存に成功しました",
          speechRecognition: "音声認識",
          reExecuteErrorCorrection: "エラー訂正の再実行",
          confirmExecution: "実行の確認",
          voiceDetection: "音声検出",
          noiseDetectionPowerLevel: "ノイズ検出powerLevel ≦",
          pleaseEnterTheThreshold: "しきい値を入力してください",
          volumeDetectionPowerLevel: "音量検出powerLevel ≧",
          reset: "リセット",
          preserve: "保存",
        },
        coachTemplate: {
          french: "フランス語",
          spanish: "スペイン語",
          japanese: "日本語",
          thai: "タイ語",
          loginPopupWindow: "ログイン弾倉",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "オンにすると、スマートトレーナーシステムに入るときにポップアップ表示が行われます",
          title: "タイトル",
          text: "本文",
          checkBoxContent: "チェックボックスの内容",
          informedConsentForm: "インフォームドコンセント書",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "コミュニケーションと話術のスキルと専門知識の応用を向上させるために、いつでもどこでも練習し、リアルタイムのフィードバックを受けることができます。このプラットフォームを通じて、以下の情報を収集します。",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "本人はすでに本インフォームドコンセント書を完全に読んで十分に理解しており、本インフォームドコンセント書に記載されている目的と方法に基づいて私の敏感な個人情報を処理することに同意したと厳粛に宣言した。",
        },
      },
      systemIntegration: {
        title: "システム設定",
        ccIntegrationTips:
          "スマート品質検査ドッキングUdeskコールセンター説明書",
        unauthorized: "許可されていません",
        authorized: "許可済み",
        udeskDomain: "カスタマーサービスシステムのドメイン名",
        udeskToken: "カスタマーサービスシステムのキー",
        udeskAdminEmail: "カスタマーサービスシステム管理者アカウント",
        password: "管理者アカウントパスワード",
        applyAuth: "権限付与",
        cancelAuth: "認可の取り消し",
        reApplyAuth: "再授権",
        keyTips:
          "コールシステムのバックグラウンド管理-シングルサインオン中のAPI認証Token",
      },
      customerField: {
        title: "顧客フィールド",
        table: {
          propName: "フィールド名",
          propDesc: "フィールドの説明",
          valueType: "フィールドタイプ",
          propType: "作成方法",
          createTime: "時間の作成",
          canNotEdit: "システムフィールドは編集をサポートしていません",
        },
        edit: "フィールドの編集",
        new: "新規フィールド",
      },
      wechatDocking: {
        title: "企業のマイクロドッキング",
        formLabels: {
          companyId: "企業ID",
          communicationKey: "通信キー",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "イベントコールバックURL",
          applicationName: "アプリ名",
          applicationId: "WebアプリケーションID",
          applicationSecret: "Secretを適用する",
        },
        formTitle: {
          initialForm: "初期設定",
          synchronizeForm: "アドレス帳同期",
          settingForm: "アプリ設定",
        },
        qrCodeUrlTip:
          "管理者は下の二次元コードをスキャンして代理開発アプリケーションの紐付けを行ってください。",
      },
      taobao: {
        formLabels: {
          name: "店舗名",
          account: "アカウント",
          password: "パスワード",
          confirm: "パスワードの確認",
          authorization: "権限付与",
          switchOn: "オン",
          switchOff: "閉じる",
        },
        tableLabels: {
          name: "店舗名",
          account: "アカウント",
          authorization: "権限付与",
          updateUser: "最近の更新者",
          updateTime: "直近の更新時間",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "微豊二級ドメイン名",
          secretKeyTip:
            "テナントの秘密鍵は、微豊システムのバックグラウンドシステムのドッキング先で取得する",
        },
      },
      user: {
        index: {
          name: "スタッフ管理",
          existingUsers: "従業員がいます",
          deleteSuccess: "削除に成功しました",
          deleteFailed: "削除に失敗しました",
        },
        detail: {
          accountPrompt: "メールアドレスを入力してください",
          resetPassword: "パスワードのリセット",
          passwordPrompt:
            "パスワード (6-14桁のアルファベットと数字の組み合わせ)",
          originPasswordPrompt: "初期パスワードを入力してください",
          resumePasswordPrompt: "パスワードを再入力してください",
          passwordFormWrong:
            "6-14桁のアルファベットと数字を组み合わせたパスワードを入力してください!",
          resumePasswordWrong: "2回パスワードが一致しません!",
          suppertImageType: "Jpg,gif,pngに対応、最大500Kb",
          namePrompt: "名前を入力してください",
          nickNamePrompt: "ニックネームを入力してください",
          telephonePrompt: "電話番号を入力してください",
          roleSelectedPrompt: "ロールを選択してください",
          agentIdPrompt: "スタッフ番号を入力してください",
          disabled: "無効",
          enabled: "有効化",
          reloadBowerInfo:
            "ユーザー情報が変更されました。ブラウザを再更新する必要がありますか?",
          saveUserError:
            "ユーザー情報の保存に失敗しました。後で再試行してください。",
          resetPasswordSuccess: "パスワードのリセットに成功しました",
          resetPasswordFailed:
            "パスワードのリセットに失敗しました。後でやり直してください。",
        },
      },
      userGroups: {
        index: {
          name: "スタッフグループ管理",
          existingUserGroups: "スタッフグループがあります",
        },
        detail: {},
      },
      role: {
        index: { name: "ロール管理", existingRoles: "すでにロールがあります" },
        edit: {
          editRole: "ロールの編集",
          createRole: "ロールの作成",
          basicInfo: "基本情報",
          taskPermissions: "タスク権限",
          existingTasks: "既存のタスク",
        },
      },
      anomalousData: {
        title: "異常データ",
        list: {
          selectHolder: "データソースを選択してください",
          businessTime: "業務時間",
          businessId: "業務Id",
          export: "エクスポート",
        },
      },
      templateComponent: {
        list: {
          title: "テンプレート市場",
          name: "テンプレート名",
          searchHolder: "照会する内容を入力してください",
          update: "更新状況",
          sort: "ソート方法",
          moduleType: "テンプレートタイプ",
          moduleSourceType: "適用タイプ",
          fetchNum: "取得回数",
          newest: "最近の更新",
          usedMost: "最も多く取得",
          call: "通話",
          dialogue: "会話",
          waiting: "審査待ち",
          cancel: "投稿をキャンセル",
          success: "審査通過、公開済み",
          failed: "審査却下",
          no: "アップグレード不可",
          ok: "アップグレードできます",
          all: "すべて",
          delete: "削除に成功しました",
          downOk: "商品削除に成功しました",
          withdraw: "撤回に成功しました",
          updateOk: "アップグレードに成功しました",
          get: "取得",
          width: "撤回",
          getOk: "取得に成功しました",
          orgName: "所属組織",
          nowVersion: "現在のバージョン",
          lastedVersion: "最新バージョン",
          appStatus: "適用ステータス",
          actions: "操作",
          down: "下枠",
          updateTop: "アップグレード",
          check: "表示",
          deleteWhen: "削除しますか?",
          yes: "はい",
          noo: "いいえ",
          deleteOk: "削除",
          permissible: "許可",
          noPermissible: "許さない",
          openUp: "オープン",
          openDown: "開放しない",
          task: "品質検査タスク",
          point: "品質検査ポイント",
          tag: "意味ラベル",
          muti: "多要素",
          inter: "一問一答",
          info: "情報エンティティ",
          concept: "概念語",
          detail: "モジュールの詳細",
          infomation: "基本情報",
          detailConfig: "詳細設定",
          twoEdit: "二次編集",
          mustUpdate: "今すぐアップグレード",
          descition: "テンプレートの説明",
          descitionSize: "テンプレートの説明は250文字を超えることはできません",
          stencilsDetail: "テンプレートの詳細",
          updatedRecords: "記録の更新",
          version: "バージョン番号",
          updateDescription: "更新説明",
          myModule: "マイテンプレート",
          addModule: "新規モジュール",
          publishVersion: "リリース",
          earliestVersion: "最も古いバージョン",
          edit: "編集",
          add: "新規追加",
          configNo: "モジュール設定の詳細は必須項目です",
          updateNo: "更新説明は必須項目です",
          editSuccess: "修正に成功しました",
          saveSuccess: "保存に成功しました",
          newer: "アップデート",
          sumbit: "送信",
          cancelName: "キャンセル",
          nameRequired: "モジュール名は必須フィールドです",
          nameSize: "モジュール名は16文字を超えることはできません",
          publishStatus: "公開ステータス",
          submitUpdate: "更新の送信",
          updateVersion: "更新バージョン",
          updateExplain: "更新手順",
        },
      },
      customDataSource: {
        amazonEcommerce: "アマゾンの電気商",
        youtubeVideo: "Youtube動画",
        amazon: {
          component: {
            queuing: "列に並ぶ",
            notStarted: "開始していません",
            inProgress: "進行中",
            success: "成功",
            failed: "失敗",
            successfullyCreated: "作成に成功しました",
            editedSuccessfully: "編集に成功しました",
            operationSucceeded: "操作に成功しました",
            deletedSuccessfully: "削除に成功しました",
            link: "リンク",
            grabState: "キャプチャステータス",
            updateTime: "更新時間",
            grab: "スナップ",
            edit: "編集",
          },
          template: {
            amazonEcommerce: "アマゾンの電気商",
            thisProduct: "本品",
            competition: "競争品",
            new: "新規作成",
            edit: "編集",
            link: "リンク",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube動画",
            thisProduct: "本品",
            competition: "競争品",
            new: "新規作成",
            edit: "編集",
            link: "リンク",
          },
          component: {
            queuing: "列に並ぶ",
            notStarted: "開始していません",
            inProgress: "進行中",
            success: "成功",
            failed: "失敗",
            successfullyCreated: "作成に成功しました",
            editedSuccessfully: "編集に成功しました",
            operationSucceeded: "操作に成功しました",
            deletedSuccessfully: "削除に成功しました",
            youTubeVideoLink: "YouTube動画リンク",
            grabState: "キャプチャステータス",
            updateTime: "更新時間",
            grab: "スナップ",
            edit: "編集",
          },
        },
      },
    },
    demo: {
      title: "スマート品質検査",
      template: "品質検査テンプレートの選択",
      radio: "録音形式を選択します",
      uploadAudio: "録音をアップロードする",
      uploadAudioTip:
        "アップロード録音はwavとmp3形式のみをサポートし、サイズは1g以下です",
      uploadText: "テキストをアップロードする",
      uploadTextTip:
        "アップロードしたテキストのサイズは、カスタム形式とtxt形式のみをサポートしています。サイズは10m以下で、テキストをアップロードした後、ASRを使用して録音ファイルを解析しなくなります。",
      uploadSuccess: "録音ファイルのアップロードに成功しました",
      uploadFailed: "録音ファイルのアップロードに失敗しました",
      startQualityInspection: "品質検査を開始します",
      qualityInspectionStarted: "品質検査中です",
      downloadText: "テキストダウンロード",
      checking: "品質検査の進捗",
      checkingTip: "数分かかると予想されますので、しばらくお待ちください",
      noCheckContent: "品質検査の結果はまだありません",
      noTextDownload: "品質検査テキストなしでダウンロードできます!",
      pleaseCheckParams:
        "テンプレート、音声タイプ、録音ファイルのアップロードを選択するか確認してください",
      inspectionFailed: "品質検査に失敗しました。後でやり直してください。",
    },
    wechatQa: {
      baseInfo: { title: "基本情報" },
      conversationSetting: {
        title: "セッション設定",
        segmentationStrategy: "分割ポリシー",
        rule: "ポリシー",
        time: "分割時間",
        daily: "毎日",
        passScore: "セッション合格点",
        ruleLogic: "実行ロジック",
      },
      message: {
        title: "プッシュイベント",
        table: {
          index: "シリアルナンバー",
          name: "名称",
          type: "プッシュ方式",
          desc: "説明",
          status: "ステータス",
        },
        subTitle: "イベントリスト",
      },
      checkPoint: {
        title: "解析モデル",
        groups: "モデルグループ分け",
        newClassification: "グループの新規作成",
        editClassification: "グループの編集",
        benchmarkScore: "基準分",
        pointCount: "現在の分類での共有分析モデル",
        openPointCount: "有効数量は",
        closedPointCount: "使用停止数量は",
        scoreLowerLimit: "最低得点下限",
        scoreUpperLimit: "最高得点上限",
        classificationName: "グループ名",
        classificationNamePlaceHolder: "グループ名を入力してください",
        classificationRemark: "グループの説明",
        classificationRemarkPlaceHolder: "グループの説明を入力してください",
      },
      chartRecord: {
        title: "企業wechatセッション",
        table: {
          id: "セッションID",
          type: "セッションタイプ",
          startTime: "セッション開始時間",
          endTime: "セッションの最近の終了時間",
          groupId: "グループID",
          client: "お客様",
          customer: "カスタマーサービス",
          averageScore: "平均品質検査評価",
        },
        detail: {
          title: "企業のwechatセッションの詳細",
          inspectionInfo: "情報を分析する",
          chartInfo: "セッション情報",
          tasks: "タスクの分析",
          avgScore: "分析平均点",
          qualified: "合格",
          unqualified: "不合格",
          taskTitle: "分析テンプレートの適用",
          info: "リアルタイムで情報を分析する",
          today: "今日のニュース",
          history: "履歴メッセージ",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname:
                  "備考/ニックネームを入力してください",
                pleaseEnterTheGroupChatName:
                  "グループチャット名を入力してください",
              },
            },
          },
          template: { whole: "すべて" },
        },
      },
      realTimeAnalysis: {
        title: "リアルタイム分析",
        table: {
          name: "シーン名",
          count: "分析モデル数",
          baseScore: "基準分",
          passScore: "合格点",
          maxScore: "最高得点上限",
          minScore: "最低得点下限",
          status: "ステータス",
          actions: "操作",
        },
        detail: {
          title: "リアルタイムでシーンを分析",
          form: {
            name: "シーン名",
            prevCondition: "シーントリガー条件",
            prevConditionToolTip:
              "条件が空白の場合、このシーンはデフォルトで解析されます",
            prevConditionError:
              "シーントリガー条件を解析モデルと一致させることはできません",
            scoreInputPlaceholder: "スコアを入力",
            baseScore: "基準分",
            status: "ステータス",
            passScore: "合格点",
            maxScore: "最高点上限",
            minScore: "最低下限",
            analysisModel: "解析モデル",
            message: "トリガー通知",
            pushType: "プッシュ方式",
            createAnalysisModel: "モデルを追加",
            scoreUpperLimitError:
              "最低得点下限は最高得点上限を超えることはできません",
            table: { name: "モデル名", rule: "ルール", score: "スコア" },
            pushConfigSwitchTip1: "このシーンが現れると",
            pushConfigSwitchTip2: "次以上の場合、プッシュ通知",
          },
        },
      },
      smartCustomerTag: {
        title: "スマート顧客ラベル",
        table: {
          name: "ルール名",
          tags: "企業のマイクロカスタマーラベル",
          status: "有効化ステータス",
          createUser: "作成者",
          createTime: "時間の作成",
        },
        detail: {
          name: "ルール名",
          condition: "ルールの設定",
          logic: "ロジック",
          status: "有効化ステータス",
          tags: "ラベルを選択",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "顧客タグ数" },
        },
        messageFiltering: {
          template: {
            edit: "編集",
            messageContent: "メッセージの内容",
            messageTypeID: "メッセージタイプID",
            messageTypeDescription: "メッセージタイプの説明",
            messageFiltering: "メッセージフィルタ",
            suitableForFilteringDataWithoutQualityInspection:
              "品質検査を必要としないデータのフィルタリングに適しています",
            create: "作成",
            messageDescription: "メッセージの説明",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "通話時間",
          sessionType: "セッションタイプ",
          whole: "すべて",
          staff: "スタッフ",
          customer: "お客様",
          callKeywords: "通話キーワード",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails: "企業のマイクロ音声通話の詳細",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "セッションアーカイブ",
        voiceCall: "音声通話",
        noMoreVoiceCalls: "これ以上音声通話はありません",
        enterpriseWechatVoiceCall: "企業のwechat音声通話",
        enterpriseWechatConversationDetails: "企業のwechatセッションの詳細",
        previous: "前の条",
        next: "次条",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "企業マイクロセッション分析タスク",
          enterpriseAndMicroVoiceAnalysisTask: "企業のマイクロ音声分析タスク",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat: "企業のwechatグループチャット分析",
      },
      wechatQaSettingEntry: { messageFiltering: "メッセージフィルタ" },
    },
    businessAnalysis: {
      keyword: "レポート名",
      createUser: "作成者",
      createTime: "時間の作成",
      updateTime: "更新時間",
    },
    semanticIntelligence: {
      applicationMessageType: "適用メッセージタイプ",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "設定を充実させてください",
              generatePromptLanguage: "提示語を生成する",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "合っているかどうか",
              gradedEvaluation: "段階別評価",
            },
          },
          training: {
            index: {
              pleaseEnter: "入力してください",
              pleaseGeneratePrompt: "提示語を生成してください",
              pleaseSelectADialogue: "会話を選択してください",
              training: "トレーニング",
              dataType: "データ型:",
              voiceCall: "音声通話",
              textDialogue: "テキスト会話",
              generate: "生成",
              qualityInspectionResults: "品質検査結果:",
              hit: "ヒット話術:",
            },
          },
        },
        detail: {
          index: {
            modelName: "モデル名",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "モデル名を入力してください。例えば、カスタマーサービスに何かがない場合がありますか?",
            modelDescription: "モデルの説明",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "検査が必要な内容を詳しく説明して、モデルの理解を容易にすることができます。例えば、お客様に何もしない状況があるかどうか、つまりお客様の質問に対するお客様からの回答があるかどうかしかし、お客様の問題は、お客様の回答に関係なく、処理されていない、または回答されていない",
            validity: "有効性",
            effective: "有効",
            invalid: "無効",
            type: "タイプ",
            hintLanguage: "提示語",
            editIntelligentModel: "スマートモデルの編集",
            createANewIntelligentModel: "スマートモデルの新規作成",
          },
        },
        index: {
          modelName: "モデル名",
          modelDescription: "モデルの説明",
          type: "タイプ",
          whetherItMeetsTheRequirements: "合っているかどうか",
          gradedEvaluation: "段階別評価",
          validity: "有効性",
          intelligentModel: "スマートモデル",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "主観的な品質検査に適しています。",
        },
      },
      clusterAnalysis: "クラスタ分析",
      speechMining: "話術の発掘",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "APIインタフェースを介した情報エンティティの一括インポートをサポートしています。このAPI名は保存後に編集をサポートしていません",
        },
        component: { nothing: "なし" },
      },
      knowledgeBase: {
        index: {
          classification: "分類",
          import: "インポート",
          importKnowledgeBase: "知識リポジトリのインポート",
          moveKnowledgePointsInBatch: "知識ポイントの一括移動",
          moveTo: "に移動:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "一括移動が必要な知識点を選択してください",
        },
        detail: {
          index: { intrasentence: "文の中", afterProblem: "問題の後" },
        },
      },
      semanticTags: { component: { nothing: "なし" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "敏感語",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "敏感語: 敏感語は業務の関心語を配置することができます。",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "新しい敏感な言葉",
            editSensitiveWords: "敏感な言葉を編集する",
            sensitiveWords: "敏感語",
          },
          template: {
            sensitiveWords: "敏感語",
            sensitiveWordsCannotBeEmpty: "敏感語は空白にできません",
            pleaseEnterSensitiveWordsSeparatedBy:
              "敏感語を入力してください。複数の敏感語の間は '、' で区切られています。",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "応用シーンを選択してください",
            keyWord: "キーワード",
            pleaseEnterKeywords: "キーワードを入力してください",
            keywordLengthWithinCharacters: "キーワードの長さは40文字以内です。",
            dataSources: "データソース",
            pleaseSelectADataSource: "データソースを選択してください",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "現在のクラスタは完了しました。再編集すると新しいクラスタデータが生成されます。完了したクラスタデータは上書きされません。確認してください。",
            savingSucceeded: "保存に成功しました",
            clusteringStart: "クラスタリング開始",
            clustering: "クラスタリング",
            edit: "編集",
            newlyBuild: "新規作成",
            clusterName: "クラスタ名",
            pleaseEnterAName: "名前を入力してください",
            withinCharactersOfTheName: "名前の長さは40文字以内です",
            speaker: "話し手",
            pleaseSelectASpeaker: "話し手を選んでください",
            sessionDate: "セッション日",
            pleaseEnterTheSessionDate: "セッション日を入力してください",
            selectUpToMonthOfHistoricalSessionData:
              "最大1ヶ月の履歴セッションデータを選択します",
            automaticallyClusterDailyNewData:
              "自動クラスタは毎日データを追加します",
            conversationChannel: "会話チャネル",
            pleaseSelectAConversationChannel: "会話チャネルを選択してください",
            applicationScenario: "応用シーン",
            clusteringRules: "クラスタリングルール",
            incompleteClusteringRuleSettings:
              "クラスタリングルールの設定が不完全です",
            startClustering: "クラスタリングを開始",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "品質検査タスクを選択してください",
              beEqualTo: "等しい",
            },
          },
        },
        detail: {
          index: {
            theme: "テーマ",
            numberOfCorpora: "語数",
            corpusCoverage: "コーパスカバー",
            clusterDetails: "クラスタの詳細",
          },
          ranking: {
            hotWords: "ホットワード",
            numberOfCorpora: "語数",
            speaker: "話し手",
            sit: "座る",
            passenger: "客",
            hotSentence: "熱い文",
            corpusDetails: "資料の詳細",
          },
        },
        index: {
          delete: "削除",
          deletionSucceeded: "削除に成功しました",
          clusterName: "クラスタ名",
          speaker: "話し手",
          creationTime: "時間の作成",
          state: "ステータス",
          operation: "操作",
          see: "表示",
          edit: "編集",
          clusterAnalysis: "クラスタ分析",
          newCluster: "新規クラスタリング",
          clusteringStatus: "クラスタリングステータス",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "キーワードを入力して異議を検索する",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "話術庫",
                recommendedScripts: "推奨話術",
                common: "共",
                strip: "条",
                scriptFavorites: "話題のお気に入り",
              },
              conditionPostfix: {
                detectRoles: "役割の検出",
                detectionRange: "検査範囲",
                sentence: "文",
              },
              customerFilter: {
                scriptFiltering: "話術フィルタリング",
                cancel: "キャンセル",
              },
              index: {
                viewDetails: "詳細を見る",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "本当に捨てますか? 捨てると回復できません。",
                abandonment: "捨てる",
                deletionSucceeded: "削除に成功しました",
                passenger: "客",
                sit: "座る",
                adopted: "採用済",
                determine: "確定",
                cancel: "キャンセル",
                adopt: "採用",
              },
              pageRightFrom: {
                savingSucceeded: "保存に成功しました",
                pleaseEnterTheScriptContent: "話術の内容を入力してください",
                pleaseSelectAFavoriteGroup:
                  "お気に入りグループを選択してください",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary: "異議処理トークライブラリ",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "採用術を選択してください",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "異議処理術のお気に入り",
            },
            pageRightTable: {
              likeCancel: "いいねキャンセル",
              likeSuccessfully: "いいねをして成功する",
              adoptScripts: "話術を採用する",
              operation: "操作",
              viewConversationDetails: "会話の詳細を見る",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "製品のセールスポイント",
              },
              pageRightTable: { keyPointsOfScript: "話のポイント" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "異議処理術のお気に入り",
              newGroup: "グループの新規作成",
              editGroup: "グループの編集",
              addGroup: "グループを追加",
              savingSucceeded: "保存に成功しました",
              groupName: "グループ名",
              pleaseEnterTheGroupName: "グループ名を入力してください",
              groupNameWithinCharacters: "グループ名の長さは20文字以内です",
              deletionSucceeded: "削除に成功しました",
            },
            pageRightTable: {
              configDrawer: {
                index: { scriptRecommendationSettings: "話術推奨設定" },
                rightForm: {
                  savingSucceeded: "保存に成功しました",
                  intelligentRecommendation: "スマート推奨",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "採用した優秀な話術を自動的に異議のような会話のサイドバーに表示し、スタッフは会話の詳細ページで直接推薦話術を見て勉強することができる",
                  objectionsIncludedByDefault: "デフォルトに含まれる異議",
                  addRecommendationTriggerSemantics:
                    "推奨トリガの意味を追加します",
                  remindEmployeesToView: "従業員に表示を促す",
                  shareWithMoreEmployees: "より多くのスタッフにシェア",
                  allHands: "全社員",
                },
                smartToolbar: { selectSemanticTag: "意味ラベルを選択します" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "現在のグループの下のすべてのデータレコードをエクスポートしますか?",
                newScript: "新しい話術",
                editingScript: "話術を編集する",
                savingSucceeded: "保存に成功しました",
                objection: "異議",
                pleaseEnterAScriptObjection: "スピーチの異議を入力してください",
                speechTechnique: "話術",
                pleaseEnterTheScriptContent: "話術の内容を入力してください",
                grouping: "グループ分け",
                pleaseSelectAFavoriteGroup:
                  "お気に入りグループを選択してください",
                adoptScripts: "話術を採用する",
                passenger: "客",
                sit: "座る",
                numberOfViews: "確認回数",
                useful: "役に立った",
                operation: "操作",
                edit: "編集",
                delete: "削除",
                deletionSucceeded: "削除に成功しました",
                scriptRecommendationSettings: "話術推奨設定",
              },
            },
          },
        },
        index: {
          scriptLibrary: "話術庫",
          scriptMining: "話術の発掘",
          objectionHandlingScripts: "異議処理の話",
          productSellingPointScripts: "製品のセールスポイント",
        },
        sellingPoint: {
          index: { newCluster: "新規クラスタリング" },
          components: {
            callDetailModal: {
              sessionDetails: "セッションの詳細",
              cancel: "キャンセル",
              determine: "確定",
              choice: "選択",
            },
            pageCenterTable: { scriptOverview: "話の概況" },
            pageLeftTable: {
              enterSearchKeywords: "検索キーワードを入力",
              keyWord: "キーワード",
            },
            pageRightTable: {
              index: {
                viewDetails: "詳細を見る",
                scriptLibrary: "話術庫",
                scriptRefinement: "話術の精製",
                adoptScript: "話術を採用する",
              },
              tagsEdit: { keyPointsOfScript: "話のポイント" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "製品のセールスポイント",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "デフォルトに含まれるセールスポイント",
                  },
                },
                index: {
                  keyWord: "キーワード",
                  pleaseSelectAKeyword: "キーワードを選択してください",
                  keyPointsOfScript: "話のポイント",
                  pleaseInputTheKeyPointsOfTheScript:
                    "話術のポイントを入力してください",
                  viewDetails: "詳細を見る",
                  pleaseChooseToAdoptScript: "採用術を選択してください",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "マッチングロジック",
            nLPMatching: "NLPマッチ",
            strictlyMatchByWord: "単語ごとに厳密にマッチする",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "メッセージタイプ名",
          messageTypeID: "メッセージタイプID",
          messageTypeDescription: "メッセージタイプの説明",
          applicationMessageType: "適用メッセージタイプ",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "異なるチャネルデータにアクセスして品質検査を行うのに適しており、チャネル標識に基づいて分類する必要がある",
        },
        detail: {
          index: {
            messageTypeName: "メッセージタイプ名",
            pleaseEnterAMessageTypeName: "メッセージタイプ名を入力してください",
            messageTypeDescription: "メッセージタイプの説明",
            describeYourMessageTypeInDetail:
              "メッセージタイプを詳しく説明できます",
            editMessageType: "メッセージタイプの編集",
            newMessageType: "新規メッセージタイプ",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "チームメンバーを選択してください",
        existingFilter: "既存のフィルタ",
        pleaseSelect: "選択してください",
        filterCondition: "フィルタ条件",
        areYouSureToDeleteTheCurrentFilter: "このフィルターを削除しますか?",
        sessionDate: "セッション日",
        staff: "スタッフ",
        customer: "お客様",
        pleaseEnterCustomerName: "顧客名を入力してください",
        keyWord: "キーワード",
        whole: "すべて",
        pleaseEnterSessionKeywords: "会話キーワードを入力してください",
        sessionDuration: "セッション時間",
        sessionLabel: "会話シーン",
        pleaseSelectASessionLabel: "会話シーンを選択してください",
        commentTopic: "話題を論評する",
        pleaseSelectACommentTopic: "コメントの話題を選択してください",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "現在のフィルタを上書きしますか?",
        overlayFilter: "カバーフィルター",
        NewFilter: "新規フィルタ",
        newFilter: "新規フィルタ",
        filterName: "フィルタ名",
        pleaseEnter: "入力してください",
        allCall: "すべての通話",
        percentage: "パーセント",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "複数のキーワードの検索をサポートしています。",
          callRecordID: "通話記録ID",
          pleaseFillInTheCallRecordID: "通話記録IDを記入してください",
          minute: "分",
          second: "秒",
          whole: "すべて",
          callRecording: "通話録音",
          enterpriseWechat: "企業wechat",
          conversationChannel: "会話チャネル",
          pleaseSelectASessionChannel: "会話チャネルを選択してください",
          learningStatus: "学習ステータス",
          pleaseSelectLearningStatus: "学習ステータスを選択してください",
          notLearned: "未学習",
          learned: "学習済み",
          Minutes: "分",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "フィルタ条件が空白のため、新規フィルタに対応していません!",
          filterAddedSuccessfully: "フィルタの新規追加に成功しました!",
          failedToAddFilter: "フィルタの新規追加に失敗しました!",
          filterDeletedSuccessfully: "フィルタ削除成功!",
          filterDeletionFailed: "フィルタの削除に失敗しました!",
          filterOverwriteSucceeded: "フィルタカバー成功!",
          filterOverwriteFailed: "フィルターのカバーに失敗しました!",
          theme: "テーマ",
          pleaseSelectATheme: "テーマを選択してください",
          keyEvents: "キーイベント",
          pleaseSelectAKeyEvent: "キーイベントを選択してください",
        },
        filterItem: {
          startRetesting: "再検査を開始します",
          time: "時間",
          talkTime: "通話時間",
          keyEvents: "キーイベント",
          totalImplementationRateOfSupervisionItems: "監督項目の総執行率",
          sort: "并び替え:",
          seeMore: "もっと見る",
        },
        filterStatistics: {
          conversation: "通話",
          weChat: "Wechat",
          quantity: "数量",
        },
      },
      caseBase: {
        index: {
          copyTo: "にコピー",
          pleaseSelectTheLocationToMove: "移動する場所を選択してください!",
          folderCopiedSuccessfully: "フォルダのコピーに成功しました!",
          cancelCollectionSuccessfully: "お気に入りのキャンセルに成功しました!",
          collectionSucceeded: "コレクション成功!",
          starCaseBase: "星標ケースライブラリ",
          move: "移動",
          rename: "名前の変更",
          delete: "削除",
          folderMovedSuccessfully: "フォルダ移動成功!",
          fileMovedSuccessfully: "ファイル移動成功!",
          fileMoveFailed: "ファイル移動に失敗しました!",
          folderAddedSuccessfully: "フォルダの追加に成功しました!",
          folderRenameSucceeded: "フォルダの名前を変更しました",
          fileRenameSucceeded: "ファイルの名前を変更しました",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "フォルダを削除すると元に戻れません。削除してもよろしいですか?",
          deleteSucceeded: "削除に成功しました!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "ファイルを削除すると元に戻れません。削除してもよろしいですか?",
          share: "共有",
          folders: "個のフォルダ、",
          calls: "つの通話",
          newFolder: "フォルダの新規追加",
          modifyFolder: "フォルダの修正",
          title: "タイトル",
          pleaseEnter: "入力してください",
          selectMoveLocation: "移動位置を選択",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "学習リマインダー",
          subscriptionDynamics: "購読動態",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "あなたが購読しているセッション (ID",
            thereAreDynamicUpdates: "動的更新があります",
            newCommentsReplies: "コメント/返信の新規追加",
            strip: "条",
            newHelp: "新たに助けを求める",
            goAndWatch: "見物人に行く",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "コメントに返信しました",
            replySucceeded: "返信できました!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "コメントの中で私を @ しました。",
            replySucceeded: "返信できました!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "あなたの通話を共有します",
            shareCasesForYou: "事例を共有します",
          },
        },
        study: {
          index: {
            learned: "学習済み",
            ignored: "無視済み",
            study: "学習",
            ignore: "無視する",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "インポート成功",
          account: "アカウント",
          password: "パスワード",
          cover: "カバー",
          skip: "スキップ",
          import: "インポート",
          importEmployees: "スタッフのインポート",
          importPrompt: "インポートのヒント",
          confirm: "確定",
          theDataContainsDuplicateAccountsOrNames:
            "このデータにはアカウントまたは名前の重複があります。",
          allCoverage: "すべてカバー",
          skipAll: "すべてスキップ",
          headPortrait: "顔写真",
          fullName: "氏名",
          nickname: "ニックネーム",
          excellentStaff: "優秀な従業員",
          yes: "はい",
          no: "いいえ",
          department: "部門",
          accountNumberemail: "アカウント (メールアドレス)",
          telephone: "電話",
          jobNumber: "スタッフ番号",
          role: "ロール",
          pleaseEnterANameOrPhoneSearch:
            "メールアドレス、名前、電話検索を入力してください",
          person: "人)",
          AddEmployee: "スタッフの追加",
        },
        edit: {
          jobNo: "スタッフ番号",
          udeskCustomerServiceSystemIdentity:
            "Udeskカスタマーサービスシステムのid",
          enterpriseWechatIdentity: "企業のwechat id",
          inputID: "表示を入力します",
          newIdentityBinding: "アイデンティティバインディングの新規追加",
          identityBinding: "アイデンティティの紐付け",
          avatarGreaterThan500kb: "アバターが500Kbを超える",
          uploadSuccessful: "アップロードに成功しました",
          upload: "アップロード",
          supportJPGGIFAndPNGUpTo500kb: "Jpg,gif,pngに対応、最大500Kb",
          yes: "はい",
          no: "いいえ",
          accountNumber: "アカウント",
          pleaseEnterTheAccountNumber: "アカウントを入力してください",
          initialPassword: "初期パスワード",
          pleaseInputAPassword: "パスワードを入力してください",
          confirmPassword: "パスワードの確認",
          thePasswordsEnteredTwiceAreDifferent:
            "2回入力するパスワードが違います!",
          headPortrait: "顔写真",
          fullName: "氏名",
          pleaseEnterYourName: "名前を入力してください",
          nickname: "ニックネーム",
          department: "部門",
          excellentStaff: "優秀な従業員",
          sessionIdentity: "セッションid",
          markTheSessionRecordAsBelongingToTheEmployee:
            "セッション記録はこの従業員に帰属します。",
          telephone: "電話",
          jobNumber: "スタッフ番号",
          role: "ロール",
          pleaseSelectARole: "ロールを選択してください",
          preservation: "保存",
          cancel: "キャンセル",
          addedSuccessfully: "追加に成功しました!",
          modificationSucceeded: "修正に成功しました!",
          newEmployees: "スタッフの新規追加",
          editEmployee: "編集スタッフ",
        },
        detail: {
          viewDetails: "詳細を見る",
          accountNumber: "アカウント",
          headPortrait: "顔写真",
          fullName: "氏名",
          nickname: "ニックネーム",
          department: "部門",
          excellentStaff: "優秀な従業員",
          yes: "はい",
          no: "いいえ",
          sessionIdentity: "セッションid",
          markTheSessionRecordAsBelongingToTheEmployee:
            "セッション記録はこの従業員に帰属します。",
          telephone: "電話",
          jobNumber: "スタッフ番号",
          role: "ロール",
        },
      },
      roleManage: {
        list: {
          menu: "メニュー",
          functionalCategory: "機能クラス",
          functionalSubclass: "機能クラス",
          deletedSuccessfully: "削除に成功しました",
          NewRole: "ロールの新規作成",
          role: "ロール",
          dataPermissions: "データ権限:",
          edit: "編集",
          areYouSureYouWantToDeleteTheRole: "ロールの削除を確定します",
          determine: "確定",
          cancel: "キャンセル",
          delete: "削除",
          applicationPermission: "適用権限",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "自分の会話データしか見ることができない",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "当部門と部下部門の会話データを確認できます。",
          menu: "メニュー",
          functionalCategory: "機能クラス",
          functionalSubclass: "機能クラス",
          successfullyAdded: "新規追加に成功しました",
          modifiedSuccessfully: "修正に成功しました",
          edit: "編集",
          cancel: "キャンセル",
          confirm: "確認",
          roleName: "ロール名",
          pleaseEnterTheRoleName: "ロール名を入力してください",
          dataAuthority: "データ権限",
          youCanViewCompanyWideSessionData:
            "全社のセッションデータを確認できます",
          applicationPermission: "適用権限",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "会話フロー",
          generateProcess: "フローの生成",
          addProcess: "フローの追加",
          cancel: "キャンセル",
          complete: "完了",
          edit: "編集",
          theSettingWillBeSuccessfulLater: "設定は後で見ます。成功しました。",
          previous: "前の条",
          next: "次条",
          debuggingMode: "デバッグモード",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "開通後のメッセージが重要なイベント、監視項目、顧客ラベルのルールまたは知識ポイントルールと一致すると、メッセージが一致したルール名と知識ベースの知識ポイント名が表示されます。",
          seeLater: "後で見ます",
          determine: "確定",
          conversationalInsights: "会話インサイト",
          customerInsight: "顧客インサイト",
          knowledgePointRecommendation: "知識ポイントのオススメ",
          comment: "コメント",
          sessionQuality: "セッション品質",
          setToListenLaterSuccessfully: "設定は後で聞きます。",
          listenLater: "後で聞いてください",
          seekCounselingSuccess: "カウンセリング成功を求めて!",
          altogether: "共有",
          appearsAt: "ここに現れます",
          commentsPublishedSuccessfully: "コメントに成功しました",
          callRecording: "通話録音",
          addedSuccessfully: "追加に成功しました",
          shareWithColleagues: "同僚にシェア",
          shareWithCustomers: "お客様にシェア",
          resumptionDetails: "再棚卸の詳細",
          addToCaseBase: "ケースライブラリに追加",
          shareCases: "事例をシェア",
          seekCounseling: "指導を求める",
          sessionRecord: "セッション記録",
          businessRecord: "業務記録",
          keywordSearch: "キーワード検索",
          whole: "すべて",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "コメントを入力してください他の人に通知するには @ を入力し、トピックを追加するには # を入力します",
          subscriptionSession: "セッションを購読する",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "購読後、新しい助け、コメント、返信があれば通知を受けます。",
        },
      },
      coachTask: {
        index: {
          employeeName: "スタッフ氏名",
          customerName: "顧客名",
          source: "ソース",
          viewSessionRecords: "セッション記録の表示",
          coachingStatus: "カウンセリングステータス",
          helpTime: "助けを求める時間",
          counselingTime: "カウンセリング時間",
          helpContent: "ヘルプ内容",
          coachingOperation: "指導操作",
          areYouSureYouWantToIgnoreIt: "本当に無視しますか?",
          determine: "確定",
          cancel: "キャンセル",
          ignoreHelp: "助けを求めることを無視する",
          coaching: "カウンセリングを行います",
          viewCoaching: "指導を見る",
          areYouSureToReturnToCounseling: "本当に指導待ちに戻りますか?",
          returnToPendingCoaching: "指導待ちに戻る",
          tutor: "チューター",
          helpSeekingStatus: "助けを求める状態",
          recentCoachingTime: "最近の指導時間",
          operation: "操作",
          ignoreSuccess: "無視成功!",
          recoverySucceeded: "回復に成功!",
          successfulCoaching: "カウンセリング成功!",
          myMission: "マイタスク",
          selectCoachingStatus: "指導ステータスの選択",
          whole: "すべて",
          iAskedForHelp: "助けを求めました",
          signOut: "脱退",
          submit: "送信",
          helpPerson: "助けを求める人",
          customer: "お客様",
          toBeCoached: "指導待ち",
          counselingSuggestions: "指導アドバイス",
          pleaseEnterCoachingSuggestions:
            "カウンセリングのアドバイスを入力してください",
        },
      },
      coachTaskCenter: {
        title: "タスクセンター",
        list: {
          taskName: "タスク名",
          lessonGroup: "コース分類",
          lessonName: "所属コース",
          taskType: "タスクタイプ",
          taskStatus: "タスクのステータス",
          taskExecutionInfo: "実行状況",
          totalCount: "実行すべき人数",
          executeCount: "実行済人数",
          executeRate: "実行率",
          completeCount: "完了人数",
          completeRate: "完了率",
          commitCount: "基準達成人数",
          commitRate: "達成率",
          excellentRate: "優秀率",
          taskTime: "タスク時間",
          option: "操作",
          edit: "編集",
          taskDetail: "タスクデータ",
          delete: "削除",
          new: "新規リリース",
          taskTypeAll: "すべて",
          taskTypePractice: "練習",
          taskTypeTest: "試験",
          taskStatusNotStarted: "開始していません",
          taskStatusRunning: "進行中",
          taskStatusEnd: "終了しました",
          taskStatusInvalid: "有効ではありません",
          unlimitedTime: "時間制限なし",
          to: "に",
          deleteConfirm:
            "削除後、このタスクの下にあるすべての学習記録がクリアされます。削除しますか?",
          deleteSuccess: "タスク削除成功",
        },
        edit: {
          addTask: "新規リリースタスク",
          editTask: "公開タスクの編集",
          publishSuccess: "公開に成功しました",
          publishFailed: "公開に失敗しました",
          updateSuccess: "更新に成功しました",
          updateFailed: "更新に失敗しました",
          cannotBeNull: "空白にできません",
          explain: "説明",
          taskDescExtra: "(ここの内容は {0} の入り口に展示されます)",
          taskTargetObj: "公開オブジェクト",
          isEnable: "有効ステータス",
          dialogueMode: "会話モード",
          voice: "音声",
          text: "テキスト",
          userPortrait: "ユーザー画像",
          difficultyLevel: "難易度",
          limitDuration: "時間制限",
          tipLabel: "話術のヒント",
          tipTooltip: "学習者に参考トークを確認することを許可しますか?",
          limitTimes: "試験回数",
          time: "時間",
          gradingStandart: "評価基準",
          gradingStandartDescAffix: "(現在の会話フローは",
          gradingStandartDescSuffix: "分、話術 {0}分、情緒 {1}分、語速{2}分)",
          completeStandart: "タスク完了要求",
          byTimes: "回数別",
          byScore: "得点別",
          completeByTimes: "練習回数",
          times: "次",
          score: "分",
          anyTimes: "何度でも",
          getScore: "得点",
          offLimitDuration: "時間制限なし",
          onLimitDuration: "期限が長い",
          commitScore: "基準を満たし、得点",
          excellentScore: "優秀な基準、得点",
          offLimitTime: "時間制限なし",
          onLimitTime: "時間限定",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "やあ、" },
          callDynamic: {
            today: "今日",
            yesterday: "昨日",
            sessions: "個の会話",
          },
          todoList: {
            strip: "条",
            theSessionIsAddedTolistenLater:
              "会話が「あとで聞く」に追加されました",
            askYouForHelp: "あなたに助けを求める",
            iHopeToContactYouLater: "後で連絡したい",
            lookingForwardToSendingTheInformationLater: "後で資料を送りたい",
            dialogueRisk: "会話にリスク点がある",
            tutored: "指導済み",
            goToCounseling: "カウンセリングに行く",
            contacted: "連絡済み",
            hasBeenSent: "送信済み",
            areYouSureYouWantToIgnoreIt: "本当に無視しますか?",
            determine: "確定",
            cancel: "キャンセル",
            ignore: "無視する",
            checked: "確認済み",
          },
          callListItem: {
            strip: "条",
            learned: "学習済み",
            keyEvents: "キーイベント",
            totalImplementationRateOfSupervisionItems: "監督項目の総執行率",
          },
        },
        index: {
          operationSucceeded: "操作に成功しました!",
          sessionDynamics: "会話の動き",
          my: "私の",
          department: "部門",
          company: "会社",
          toDoList: "To-do",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "有効通話量 (個)",
          bestPractices: "ベストプラクティス",
          averageDurationOfASingleCallminutes: "シングル平均通話時間 (分)",
          totalCallVolumePCs: "総通話量 (個)",
          totalTalkTimehours: "通話総時間 (時間)",
          speakingPercentagepercentage: "話す割合 (パーセント)",
          maximumContinuousSpeakingTimeminutes: "最長持続発話時間 (分)",
          numberOfIndepthDiscussionsPCs: "踏み込んだ討論回数 (個)",
          maximumCustomerTalkminutes: "最長顧客発言 (分)",
          questionFrequencyPCs: "質問頻度 (個)",
          averageValue: "平均値",
          selectDate: "日付を選択します",
          personnel: "人員",
          pleaseSelect: "選択してください",
          callRange: "通話範囲",
          conversationActivity: "会話活動",
          conversationAction: "会話アクション",
          keyEvents: "キーイベント",
          callRecord: "通話記録",
          filter: "フィルタ:",
          conversation: "通話",
          minute: "分",
          sale: "スタッフ",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "1月",
            numberOfCalls: "通話数",
            accountInformation: "アカウント情報",
            month: "月",
            today: "今日は",
            customerDetails: "顧客詳細",
            searchKeywordsInCommunicationContent:
              "コミュニケーションの内容の中でキーワードを検索します。",
            allMembers: "全メンバー",
            reset: "リセット",
            query: "照会",
            allTrip: "全旅程展示",
            contactOverview: "連絡概要",
            lastContact: "前回の連絡",
            nextContact: "次回の連絡",
            previous: "前の条",
            next: "次条",
            viewDetails: "詳細を見る",
            contactDynamics: "連絡動態",
            lastYear: "最近の一年",
            lastCall: "最近の通話",
            hotTopicsForDiscussion: "話題のホットスポット",
            totalInTheLastYear: "最近の一年間は全部で",
            callTimes: "回の通話",
          },
        },
        list: {
          index: {
            risk: "リスク",
            have: "あり",
            nothing: "なし",
            channel: "チャネル",
            callRecording: "通話録音",
            enterpriseWeChatConversation: "企業マイクロセッション",
            enterpriseWeChatCall: "企業のマイクロ通話",
            followUpSuggestions: "フォローアップの提案",
            customer: "カスタマーセンター",
            followUp: "フォローアップ状況",
            personInCharge: "担当者",
            recentFollowup: "最近フォローします",
            lastContactTime: "最近の連絡時間",
            operation: "操作",
            see: "表示",
            exceed: "超過",
            noActivityForMoreThanDays: "日にはイベントがありません",
            lately: "最近",
            risksOccurredInTheLastDays: "日内にリスク意味が現れる",
            savedSuccessfully: "保存に成功しました",
            today: "今日は",
            allDepartments: "すべての部門",
            allMembers: "全メンバー",
            customerName: "顧客名",
            reset: "リセット",
            query: "照会",
            customers: "お客様",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "従業員と顧客との連絡状況を示す。その中でピンクの泡は顧客のメッセージで、青は従業員のメッセージで、発言が大きいほど気泡が大きくなる",
            riskAllocation: "リスク設定",
            preservation: "保存",
            pleaseEnter: "入力してください",
            noActivityForDays: "日にはイベントがありません",
            riskSemanticsWithinDays: "日内にリスク意味が現れる",
            addRiskSemantics: "リスクセマンティックの追加",
            semanticTags: "意味ラベル:",
            standardSentence: "標準文:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "新しい条件を追加" } },
              },
              index: {
                meetAllOfTheFollowingConditions: "次の条件を全て満たす",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "スクリーニング",
                pleaseSelectFilteringCriteria: "フィルタ条件を選択してください",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "ビジネスチャンス段階の変更",
              theCurrentStageIs: "現在の段階は【",
              ConfirmToChangeItTo: "】に変更し、【",
              IsIt: "】ですか?",
              StageCoStay: "】ステージ共滞在",
              day: "日",
              start: "スタート",
              end: "終了",
              orderInput: "送り状",
              restart: "再起動",
              pleaseConfigureTheSalesStageInformation:
                "販売段階情報を設定してください",
              reasonForDocumentInput: "送り状の原因:",
              completeOrderInput: "入力完了",
              pleaseSelectTheReason: "原因を選択してください",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "管理者のアドバイス:" },
              index: {
                sessionDetails: "セッションの詳細",
                customerDynamics: "顧客動態",
                riskPoint: "リスクポイント",
                followUpSuggestions: "フォローアップの提案",
              },
              sessionDetails: {
                common: "共",
                currentlyViewingThe: "現在は第",
                sessions: "個の会話",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "現在の段階は滞在しています",
              day: "日",
            },
          },
          business: {
            index: { reEnableOpportunity: "ビジネスチャンスを再度有効にする" },
          },
          customerTag: { index: { label: "ラベル" } },
          moreCustomer: {
            index: {
              essentialInformation: "基本情報",
              cancel: "キャンセル",
              confirm: "確認",
              customer: "お客様",
              currentFollowupStage: "現在のフォローアップ段階",
              telephone: "電話",
              followUpDays: "フォローアップ日数",
              day: "日",
              remarks: "備考",
              channelPreference: "チャネルの好み",
              hello: "こんにちは",
              customFields: "カスタムフィールド",
              opportunityAmount: "ビジネスチャンス金額",
              focus: "注目点",
              customerInformation: "顧客情報",
              viewMoreCustomerInformation: "顧客情報をもっと見る",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "重要なイベント:" },
                row1: {
                  customerDynamics: "顧客動態",
                  common: "共",
                  barDynamic: "バーの動き",
                },
                row2: {
                  riskPoint: "リスクポイント",
                  common: "共",
                  risks: "条リスク",
                },
                row3: {
                  followUpSuggestions: "フォローアップの提案",
                  intelligentRecommendation: "スマート推奨",
                  strip: "条",
                  managersSuggestATotalOf: "管理者提案共",
                  recommendedByManagers: "管理者推奨",
                  creationTime: "時間の作成",
                  administratorSuggestions: "管理者の提案",
                  suggestionsForNewManagers:
                    "管理者の新規追加に関するアドバイス",
                },
              },
            },
            index: { typeTime: "タイプ/時間" },
          },
          userInfo: {
            index: {
              personInCharge: "担当者",
              recentFollower: "最近フォローします",
              startTime: "初めての接触時間",
              followUpDays: "フォローアップ日数",
              day: "日",
              remarks: "備考",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "チームメンバー",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "メンバーは他の人の会話の総数を勉強します。会話の詳細を調べて、録音をクリックして放送したことがあります。",
          totalNumberOfStudies: "学習総数",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "メンバーが他人の会話に対してコメント、返信を開始した総数 (重複)",
          totalNumberOfInteractions: "相互作用の総数",
          learnTheSumOfConversationCallDuration: "学習した会話通話時間合計",
          lengthOfStudy: "学習時間",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "メンバーが他人の会話に助けを求める総数 (再)",
          totalNumberOfRequests: "助けを求める総数",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "メンバーが他人の助けを求めて指導する総数 (重い)",
          totalCounseling: "カウンセリング総数",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "メンバーが他人のセッションをケースライブラリに追加した総数 (重複)",
          addCase: "ケースの追加",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "メンバーが他人の会話を購読した総数 (重複)",
          subscribe: "購読",
          totalNumberOfSessionsCommunicatedByMembers:
            "メンバーがコミュニケーションするセッションの総数",
          totalSessions: "セッション総数",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "メンバーは自分の会話の総数を回復します。会話の詳細を調べて、録音をクリックして再生したことがあります。",
          totalNumberOfDoubleDisk: "再ディスク総数",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "再ディスク総数/セッション総数 * 100%",
          proportionOfDoubleDisk: "再棚卸比率",
          summationOfConversationCallDurationOfDuplicateDisk:
            "再ディスクされた会話通話時間の合計",
          resumptionDuration: "再読み込み時間",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "メンバーが自分の会話に対して助けを求める総数 (重い)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "メンバーの会話が他人のコメント (回答を含む) を受け取った総数",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "メンバーのセッションが他の人によってケースライブラリに追加された総数 (重複)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "メンバーの会話が他人に購読された総数 (重さを除く)",
          askForHelpInTheLastMonth: "最近一ヶ月間助けを求めています。",
          lastTimeForHelp: "最近の助けを求める時間",
          operation: "操作",
          viewAllSessionsInTheLastMonth:
            "最近の1か月のすべてのセッションを表示します",
          allDepartments: "すべての部門",
          reset: "リセット",
          query: "照会",
          altogether: "全部で",
          secondSession: "次のセッション",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "現在のテスト項目:",
            supervisionItem: "監督項目",
            keyEvents: "キーイベント",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "テストする監視項目または重要なイベントにチェックを入れ、模擬会話に通話内容があることを確認してください",
        },
      },
      executionAnalysis: { index: { executiveForceAnalysis: "実行力分析" } },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "フロー1",
              technologicalProcess: "流れ",
              newProcess: "新規作成フロー",
            },
          },
          index: {
            upload: "アップロード",
            preservation: "保存",
            newlyBuild: "新規作成",
            cancel: "キャンセル",
            name: "名称",
            describe: "説明",
            type: "タイプ",
            classification: "分類",
            content: "コンテンツ",
            enclosure: "添付ファイル",
            knowledgePointAssociation: "知識ポイント関連",
            open: "オン",
            close: "閉じる",
            operator: "オペレータ",
            logic: "ロジック",
            see: "表示",
            edit: "編集",
          },
        },
        index: {
          name: "名称",
          describe: "説明",
          createdBy: "作成者",
          type: "タイプ",
          lastUpdated: "直近の更新時間",
          numberOfReferences: "参照回数",
          operation: "操作",
          see: "表示",
          edit: "編集",
          delete: "削除",
          categoryDeletedSuccessfully: "分類削除に成功しました",
          classificationOfKnowledgePoints: "知識ポイントの分類",
          newlyBuild: "新規作成",
          knowledgeBase: "知識リポジトリ",
          knowledgePoints: "知識ポイント",
          technologicalProcess: "流れ",
          keyword: "キーワード",
          batchExport: "一括エクスポート",
          batchDelete: "一括削除",
          importProcess: "インポートの流れ",
          importKnowledgePoints: "知識ポイントのインポート",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "知識ポイントが知識ベースに存在しなければ直接知識ポイントを追加し、存在すればスキップし、更新しない",
          editKnowledgePointClassification: "知識ポイントの分類を編集します",
          newKnowledgePointClassification: "新規知識ポイント分類",
          categoryEditingSucceeded: "分類編集に成功しました",
          newClassificationSucceeded: "分類の新規作成に成功しました",
          classificationName: "分類名称",
        },
      },
      monitoringReport: { index: { executiveSupervision: "実行力監督" } },
      topicCallout: {
        index: {
          whole: "すべて",
          date: "日付",
          dimensionStatus: "寸法ステータス",
          callRecordID: "通話記録ID",
          callRecordIDMustBeNumeric: "通話記録IDは数字である必要があります",
          strip: "条",
          topicAnnotation: "話題表記",
          annotated: "マークアップ済み",
          stripNotMarked: "条、未表示",
          barInDimension: "条、表示中",
          customer: "お客様",
          sale: "販売",
          common: "共",
          sessions: "件の会話",
          currentTopic: "現在の話題:",
          staff: "スタッフ",
          topicOfConversation: "話題",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "操作に成功しました。寸法がリセットされました",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "リセット後、本通セッションのすべてのマークアップ記録がクリアされます。確認してください",
          resetAll: "すべてリセット",
          pleaseSelectTheTopicToBeMarked:
            "マークするトピックを選択してください",
          pleaseTickTheSentenceToBeMarked:
            "記入する文にチェックを入れてください",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "操作に成功しました。表示が有効になりました。",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "今回のマークアップの範囲には他の話題のメッセージが含まれています。「ok」をクリックするとカバーされます。",
          confirmDimensions: "寸法の確認",
          talkTime: "通話時間",
          callID: "通話id",
        },
      },
      topicList: {
        index: {
          callID: "通話ID",
          latestMarkedTime: "最新のマークアップ時間",
          topicOfConversation: "話題",
          numberOfSentences: "文の数",
          sentence: "センテンス",
          operation: "操作",
          see: "表示",
          delete: "削除",
          edit: "編集",
          newSubTopic: "サブトピックの新規作成",
          move: "移動",
          whole: "すべて",
          topicDeletedSuccessfully: "トピックの削除に成功しました",
          newlyBuild: "新規作成",
          topicList: "話題リスト",
          callIDMustBeNumeric: "通話IDは数字である必要があります",
          leadInTopic: "話題のインポート",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "分類名称が重複している場合、元の話題分類をカバーしません。項目は直接スキップされ、インポートされません。",
          mobileClassification: "移動分類",
          editTopic: "話題を編集する",
          newTopic: "新規トピック",
          topicName: "話題名",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "修正に成功しました",
            createdSuccessfully: "作成に成功しました",
            modificationFailed: "修正に失敗しました",
            creationFailed: "作成に失敗しました",
            monitoringName: "モニタリング名称",
            upToCharacters: "最大40文字まで",
            pleaseEnterAName: "名前を入力してください",
            monitoringIndicators: "モニタリング指標",
            sessionActivity: "会話活動",
            conversationAction: "会話アクション",
            keyEvents: "キーイベント",
            supervisionItems: "監督項目",
            monitoringMembers: "モニタリングメンバー",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "指導、訓練または管理介入を受けるグループ",
            groupA: "グループA",
            pleaseSelectGroupAData: "グループAのデータを選択してください",
            thereAreDuplicateUsersInGroupB:
              "Bグループに重複しているユーザーが存在します",
            groupARemarks: "Aグループ備考",
            controlGroupGroupComparedWithGroupA:
              "対照群、A群と比較したグループ",
            groupB: "グループB",
            pleaseSelectGroupBData: "グループBデータを選択してください",
            thereAreDuplicateUsersInGroupA:
              "グループAに重複しているユーザーが存在します",
            groupBRemarks: "グループB備考",
            monitoringPeriod: "モニタリングサイクル",
            startNode: "開始ノード",
            monitoringScope: "モニタリング範囲",
            pleaseSelectMonitoringConditions:
              "モニタリング条件を選択してください",
            pleaseEnterANumber: "数字を入力してください",
            pleaseSelectATimePeriod: "期間を選択してください",
            preservation: "保存",
            cancel: "キャンセル",
          },
        },
        list: {
          template: {
            deletionSucceeded: "削除に成功しました",
            notStarted: "開始していません",
            haveInHand: "進行中",
            closed: "終了しました",
            newGroupTest: "新規グループ分けテスト",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "販売段階",
        communicationStrategy1: "コミュニケーション戦略",
        riskAllocation: "リスク設定",
        businessConfiguration: "業務設定",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "異議処理の話",
                  productSellingPointScript: "製品のセールスポイント",
                  caseGrouping: "ケースグループ分け",
                  pleaseSelectExcellentCases: "優秀なケースを選択してください",
                  pleaseSelectKnowledgePoints: "知識点を選択してください",
                  associativeLinks: "関連リンク",
                  jumpTo: "にジャンプします",
                  jumpType: "ジャンプタイプ",
                  knowledgePoints: "知識ポイント",
                  excellentCases: "優秀事例",
                  excellentScript: "優れた話術",
                  pleaseSelectASingleKnowledgePoint:
                    "個別の知識点を選択してください",
                },
              },
            },
            index: {
              name: "名称",
              pushText: "プッシュテキスト",
              enableStatus: "有効化ステータス",
              triggerRules: "トリガールール",
              communicationStrategy: "コミュニケーション戦略",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping:
                "コミュニケーションポリシーグループ",
              name: "名称",
              pushText: "プッシュテキスト",
              effectiveness: "有効性",
              openSuccessfully: "オンに成功しました",
              closeSuccessfully: "クローズに成功しました",
              operationFailed: "操作に失敗しました",
              operation: "操作",
              edit: "編集",
              delete: "削除",
              newlyBuild: "新規作成",
              communicationStrategy: "コミュニケーション戦略",
              editGroup: "グループの編集",
              groupName: "グループ名",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "すべてのセッション",
                  recentDays: "直近日数",
                  recentSessions: "最近の会話",
                  scopeOfApplication: "適用範囲:",
                  day: "日",
                  through: "パス",
                },
              },
            },
            index: {
              describe: "説明",
              applicableCustomers: "適用顧客",
              enableStatus: "有効化ステータス",
              triggerRules: "トリガールール",
              riskRules: "リスクルール",
            },
          },
          list: {
            index: {
              riskAllocation: "リスク設定",
              keyWord: "キーワード",
              newRiskRule: "リスクルールの新規作成",
              riskRuleName: "リスクルール名称",
              describe: "説明",
              state: "ステータス",
              openSuccessfully: "オンに成功しました",
              closeSuccessfully: "クローズに成功しました",
              operationFailed: "操作に失敗しました",
              operation: "操作",
              edit: "編集",
              delete: "削除",
              deletionSucceeded: "削除に成功しました",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "送り状" },
              endReasonList: {
                addEndReason: "終了原因の追加",
                enterEndReason: "入力終了原因",
              },
              stageList: {
                keyEvents: "キーイベント",
                waysideData: "随行データ",
                includeAny: "任意を含む",
                includeAll: "すべてを含む",
                pleaseEnterTheStageName: "段階名を入力してください",
                setStageRules: "段階ルールの設定",
                stageName: "段階名称",
                operator: "オペレータ",
                logic: "ロジック",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "時間タイプフィールドの入力形式は2022-12-31 00:00:00です",
              requestFailedPleaseTryAgainLater:
                "リクエストに失敗しました。しばらくしてからやり直してください",
              salesStage: "販売段階",
              essentialInformation: "基本情報",
              name: "名称",
              applicableDepartments: "適用部門",
              applicableToOthers: "適用その他",
              manualChangePhase: "手動変更段階",
              enableStatus: "有効化ステータス",
              describe: "説明",
              opportunityStageSettings: "ビジネスチャンス段階設定",
              stageSettings: "段階設定",
              aliasSettings: "別名設定",
              closeOpportunity: "ビジネスチャンスを終わらせる",
              newSuccessfully: "新規作成に成功しました",
              newFailed: "新規作成に失敗しました",
              savingSucceeded: "保存に成功しました",
              saveFailed: "保存に失敗しました",
            },
          },
          list: {
            index: {
              salesStage: "販売段階",
              keyWord: "キーワード",
              newSalesStage: "新規販売段階",
              salesStageName: "販売段階名称",
              describe: "説明",
              state: "ステータス",
              openSuccessfully: "オンに成功しました",
              closeSuccessfully: "クローズに成功しました",
              operationFailed: "操作に失敗しました",
              operation: "操作",
              edit: "編集",
              delete: "削除",
              deletionSucceeded: "削除に成功しました",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "ビッグモデル生成",
            conversationInsights: "会話インサイト",
          },
          sessionsInsight: {
            components: {
              autoGenerate: { index: { automaticGeneration: "自動生成" } },
              hooks: {
                conversationSummary: "会話のまとめ",
                conversationProcess: "会話フロー",
                opportunityPoints: "チャンスポイント",
              },
              prompt: {
                index: {
                  edit: "編集",
                  restoreDefault: "デフォルトに戻す",
                  cancel: "キャンセル",
                  confirm: "確認",
                },
              },
            },
            index: {
              saveSuccessful: "保存に成功しました",
              hintLanguage: "提示語",
              preserve: "保存",
              cancel: "キャンセル",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "ビッグモデル生成",
        salesStage: "販売段階",
        communicationStrategy: "コミュニケーション戦略",
        riskAllocation: "リスク設定",
        businessConfiguration: "業務設定",
      },
      salesLearningCenter: {
        knowledgeBase: "知識リポジトリ",
        speechCorpus: "話術庫",
        learningCenter: "学習センター",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "顧客情報" } },
        },
        index: { otherInformation: "その他の情報" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "インポート" } } },
        hooks: {
          index: {
            all: "すべて",
            intelligentModel: "スマートモデル",
            smartRules: "スマートルール",
            manualScoring: "手動評価",
            enable: "有効化",
            notEnabled: "有効化されていません",
            voiceCall: "音声通話",
            textDialogue: "テキスト会話",
            ecommerceTextSession: "電子商取引テキスト会話",
            workOrder: "ワークオーダー",
          },
        },
        index: {
          ruleName: "ルール名",
          ruleType: "ルールタイプ",
          pleaseSelect: "選択してください",
          ruleStatus: "ルールステータス",
          dataSourceType: "データソースタイプ",
          voiceCall: "音声通話",
          textDialogue: "テキスト会話",
          ecommerceTextSession: "電子商取引テキスト会話",
          workOrder: "ワークオーダー",
          status: "ステータス",
          operation: "操作",
          edit: "編集",
          ruleTemplate: "ルールテンプレート",
        },
      },
      analysisReportTitle: "分析レポート",
      ruleTest: "ルールテスト",
      intelligentTags: {
        labelDisplayQuantity: "ラベル表示数量",
        unlimited: "制限なし",
        ignoringTagLibraries: "タグライブラリを無視する",
        automaticallyGenerateLabels: "ラベルの自動生成",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "オンにすると、大モデルに基づいてセッションに自動的にラベルが生成され、生成されたラベルはデフォルトで「未分類」に分類されます",
        defaultValidity: "デフォルトの有効性",
        supervisionItem: "監督項目",
        customerLabel: "顧客ラベル",
        newSupervisionItemRule: "監督項目の新規作成ルール",
        editSupervisionItemRule: "監督項目ルールの編集",
        newCustomerLabel: "新規顧客ラベル",
        editCustomerLabel: "顧客ラベルの編集",
        newSessionLabel: "セッションラベルの新規作成",
        editSessionLabel: "セッションラベルの編集",
        newKeyEvent: "新規作成キーイベント",
        editKeyEvents: "重要イベントの編集",
        detail: {
          template: { nameIsARequiredField: "名前は必須フィールドです" },
          formItemComponent: {
            largeModelExtensionLabel: "大型モデル拡張ラベル",
            sessionRecordID: "セッション記録id",
            sessionTime: "セッション時間",
            smartTags: "スマートラベル",
            viewDetails: "詳細を見る",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "削除後、このセッション記録のデータはクリアされます。削除を確認しますか?",
            tagData: "タグデータ",
            batchDeletion: "一括削除",
          },
        },
        template: {
          advancedFiltering: "高度なフィルタリング",
          dataType: "データ型",
          filterCriteria: "フィルタ条件",
          edit: "編集",
          createANewSubcategory: "新規サブ分類",
          delete: "削除",
          nameLengthCannotExceed: "名称の長さは40を超えることはできません",
        },
        component: {
          modelExpansion: "モデル拡張",
          enable: "有効化",
          notEnabled: "有効化されていません",
          standardScreening: "標準スクリーニング",
          advancedFiltering: "高度なフィルタリング",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "キーワード",
              sessionLabel: "セッションラベル",
              keyEvents: "キーイベント",
              matchAny: "任意にマッチさせる",
              matchAll: "すべてマッチさせる",
              beEqualTo: "等しい",
              includeAll: "すべてを含む",
              includeAny: "任意を含む",
              advancedConditions: "高度な条件",
              smartTag: "スマートラベル",
              smartTagI: "スマートラベル1",
              smartTagII: "スマートラベル2",
            },
            template: {
              pleaseSelectSmartTag: "スマートラベルを選択してください",
              pleaseSelectASessionLabel: "会話タブを選択してください",
              pleaseSelectAKeyEvent: "キーイベントを選択してください",
              pleaseSelectAdvancedCondition: "高度な条件を選択してください",
              newAdvancedFilter: "新規詳細フィルタ",
              moveUp: "上に移動",
              moveDown: "下に移動",
              moveLeft: "左に移動",
              shiftRight: "右シフト",
            },
          },
          component: {
            businessAnalysispng: "業務分析.png",
            savedSuccessfully: "保存に成功しました",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "永久有効",
            permanentlyInvalid: "永久無効",
            customValidity: "カスタム有効期間",
            termOfValidity: "有効期限",
            name: "名称",
            explain: "説明",
            state: "ステータス",
            classification: "分類",
            smartRecommendation: "スマート推奨",
            preconditions: "前置き条件",
            SetPreconditionsForThisSupervisionItem:
              "1.この監督項目の前提条件を設定する",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              "前置き条件に該当する場合、すなわち「監督項目を実行すべき」であれば、当該監督項目を検査する。",
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "前置き条件を満たさない場合、当該監督項目は検査されない。",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2.前提条件を設定しない、つまりデフォルトですべてのセッションが検出される",
            have: "あり",
            nothing: "なし",
            preconditionRule: "前置条件ルール",
            preconditionLogic: "前提条件ロジック",
          },
          recommendation: {
            caseGrouping: "ケースグループ分け",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "従業員が実行できない場合、優秀な話題と優秀なケースをスマートに推薦することができる",
            smartRecommendation: "スマート推奨",
            open: "オン",
            close: "閉じる",
            whenThisSupervisionItem: "当この監督項目",
            implement: "実行",
            unexecuted: "実行されていません",
            when: "時:",
            recommendedKnowledgePoints: "推奨知識ポイント",
            caseRecommendation: "ケース推奨",
          },
        },
        index: {
          determine: "確定",
          sort: "並べ替え",
          sortSucceeded: "ソートに成功しました",
          supervisionItemClassification: "監督項目の分類",
          pressEnterToCreateANew: "Enterキーを押して新規作成します",
          nameCannotBeEmpty: "名称は必須項目です",
          newlyBuild: "新規作成",
          name: "名称",
          explain: "説明",
          validStatus: "有効ステータス",
          permanentlyValid: "永久有効",
          permanentlyInvalid: "永久無効",
          operation: "操作",
          edit: "編集",
          batchDelete: "一括削除",
          newSupervisionItem: "監督項目の新規作成",
          keyWord: "キーワード",
          delete: "削除",
          deleteSucceeded: "削除に成功しました",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "データは削除後は復元できません。削除してよろしいですか?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "スマートラベル",
              beEqualTo: "等しい",
              includeAll: "すべてを含む",
              includeAny: "任意を含む",
              matchAny: "任意にマッチさせる",
              matchAll: "すべてマッチさせる",
              smartTagI: "スマートラベル1",
              smartTagII: "スマートラベル2",
            },
            template: {
              pleaseSelectASmartTag: "スマートラベルを選択してください",
              pleaseSelectAdvancedConditions: "高度な条件を選択してください",
              newAdvancedFilter: "新規詳細フィルタ",
              rename: "名前の変更",
              moveUp: "上に移動",
              moveDown: "下に移動",
              shiftLeft: "左に移動",
              shiftRight: "右シフト",
            },
          },
        },
        template: {
          delete: "削除",
          labelFiltering: "ラベルフィルタ:",
          satisfyAny: "任意を満たす",
          meetAll: "すべてを満たす",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "二次分類ラベル対話数 ≧ 一次分類で、一つの会話に複数のラベルが含まれる場合があります。",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "環比変化計算ロジック :( 選択した時間帯の会話量-すぐ隣の前の時間帯の会話量)/前の時間帯の会話量",
          proportionOfPrimaryClassification: "一次分類の比率状況",
          saveAs: "名前を付けて保存",
          cover: "カバー",
          preservation: "保存",
          qualityInspectionTask: "分析タスク:",
          query: "照会",
          changeFromMonthToMonth: "環比変化状況",
          tagRanking: "ラベルランキング",
          proportion: "シェア状況",
        },
        component: {
          numberOfConversations: "会話数",
          operation: "操作",
          businessAnalysispng: "業務分析.png",
          savingSucceeded: "保存に成功しました",
          deletionSucceeded: "削除に成功しました",
          sequence: "シーケンス",
          firstLevelClassification: "一次分類",
          ringRatioChange: "環比変化",
          classClassification: "分類",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "ドキュメントとその抽出内容を削除しますか?",
            addKnowledgeBase: "知識リポジトリの追加",
            uploadDocument: "ドキュメントのアップロード",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Word、pdf、ppt、excel、txt形式のファイルに対応",
            taskName: "タスク名",
            extractConversationDate: "会話日を抽出する",
            pleaseSelectAConversationDate: "会話日を選択してください",
            dataSource: "データソース",
            dataType: "データ型",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "エクスポートに成功しました",
              problem: "問題",
              answer: "答え",
              addToKnowledgeBase: "知識リポジトリに追加",
              pleaseConfirmWhetherToDeleteIt: "削除するか確認してください",
            },
            pageRightFrom: {
              addToKnowledgeBase: "知識リポジトリに追加",
              selectClassification: "分類を選択",
              pleaseSelectAKnowledgeBaseClassification:
                "知識リポジトリの分類を選択してください",
            },
          },
        },
        index: {
          intelligentExtraction: "スマート抽出",
          documentExtraction: "ドキュメント抽出",
          dialogueExtraction: "会話抽出",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "操作に成功しました",
          labelIgnored: "ラベルを無視しました",
          add: "追加",
          areYouSureToDeleteIt: "本当に削除しますか?",
          batchFilterLabels: "一括フィルターラベル",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "スマート品質検査",
        savedSuccessfully: "保存に成功しました",
        logoSettings: "ロゴ設定",
        default: "デフォルト",
        custom: "カスタム",
        title: "タイトル",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "システムに展示されているタイトル名は、6文字以内を推奨します。",
        logoImageUpload: "ロゴ画像アップロード",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "サイズが2048kb以下の画像を選択してください",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Logoの閲覧効果を保証するために、画像をアップロードして提案します。",
        Size5050Pixels: "1.サイズ: 50*50ピクセル;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2.フォーマット: 透明な背景の画像、pngまたはjpg、ロゴグラフィックは白を使用します",
        browserLabelIcon: "ブラウザのラベルアイコン",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "ラベルの閲覧効果を保証するために、画像をアップロードすることをお勧めします",
        Size3232Pixels: "1.サイズ: 32*32ピクセル;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2.フォーマット: 透明な背景の画像、pngまたはjpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "品質検査が進行中です。お待ちください… は...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "2015年には、わが国の不動産は生存の肝心な時になって、大量の家は誰も興味を持っておらず、庶民は購入したくない。杭州は一時的に8000を割って、開発者が出荷して、深センはまだ2w-3w前後で、第一線の都市の家も危機に瀕していた",
        xiaoAn: "アンちゃん",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Store store store loadこれらのメモリバリアはjsrメモリバリアと呼ばれ、jvmレベルで、オペレーティングシステムの基盤で実現する方式は2: 1はロックバス、2はメモリバリアのメタ語である例えば、sfance (書き込みバリア) mf ence(全バリア) ifence (読み取りバリア)。Hotspots仮想マシンの基盤は、lock addl、lockの空の操作、つまりロックバスというアセンブリ文を採用しています。これは、一部のオペレーティングシステムがメモリバリア語をサポートしていないためです。",
        xiaobei: "小北",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1.肉を何度か漬けてみて、酒を入れて炒めた料理は、肉が腐ったように、酸っぱい臭い! これから肉を漬けて酒を入れない",
        xiaodong: "小東",
        xiaoxi: "西さん",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3.肉を何度か漬けてみて、酒を入れて炒めた料理は、肉が腐ったように、酸っぱい臭い! これから肉を漬けて酒を入れない",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Eコマースのコメント管理",
        socialMediaReviewManagement: "ソーシャルメディアのコメント管理",
        customDataSource: "カスタムデータ元",
        qualityInspectionResults: "品質検査結果",
        accountManagement: "アカウント管理",
        employeeProfile: "スタッフ画像",
        intelligentAnalysis: "スマート分析",
        customKanban: "カスタム看板",
        dataScreening: "データ概要",
        learningRecord: "学習記録",
        dataAnalysis: "データ分析",
        scoringManagement: "評価管理",
        businessConfiguration: "業務設定",
        manualReview: "手動再確認",
        taskList: "タスクリスト",
        videoAnalysis: "ビデオ分析",
        overview: "総覧",
        personalSignage: "個人看板",
        learningCenter: "学習センター",
        wrongQuestionSet: "間違いセット",
        abilityImprovement: "能力向上",
        close: "閉じる",
        switchingSystemApplications: "システムアプリケーションの切り替え",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "マウスをここに移動すると、アプリケーションの切り替えメニューが呼び出され、メニュー項目をクリックして異なるアプリケーションにジャンプします",
        teamAnalysis: "チーム分析",
        semanticIntelligence: "意味知能",
        permissionManagement: "権限管理",
        dataConnection: "データドッキング",
        courseManagement: "コース管理",
        labelManagement: "ラベル管理",
        curriculum: "コースセンター",
        courseConfiguration: "コース設定",
        task: "マイタスク",
        historicalLearningData: "履歴",
        taskCenter: "タスク管理",
        knowledgeBase: "知識リポジトリ",
        ecommerceSessionManagement: "Ecセッション管理",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "アプリ切り替え",
          descriptionOfIntelligentQualityInspectionSystem:
            "スマート品質検査システムの説明",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "電子商取引会話フィルタ",
        allEcommerceSessions: "すべてのecセッション",
        ecommerceSessionManagement: "Ecセッション管理",
      },
    },
    analysisEnable: {
      bestPractices: "ベストプラクティス",
      behaviorAnalysis: "行動分析",
      teamAnalysis: "チーム分析",
      executiveSupervision: "実行力監督",
      executiveForceAnalysis: "実行力分析",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "評価:",
          startTime: "開始時間",
          workOrderNo: "ワークオーダー番号",
          customerServiceHasReadNotRead: "カスタマーサービス既読/未読",
          canYouAppeal: "申し立て可能ですか",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "企業マイクロノート",
          addFriendTime: "友達の時間を追加します",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "再品質検査の実行を開始します",
          callID: "通話ID",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "新機能! 「クイック抽出」ボタンをクリックすると、送信が成功すると自動的に次のページにジャンプしますよ ~~~",
        },
      },
      template: {
        deleteRecord: "記録の削除",
        areYouSureToDeleteIt: "本当に削除しますか?",
        confirm: "確定",
        reIdentification: "再識別",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "再認識は転写時間を消費しますので、慎重に操作してください。",
        determine: "確定",
        executionSuccessful: "実行に成功しました",
      },
      component: {
        pleaseSelectData: "データを選択してください",
        operationSuccessful: "操作に成功しました",
        startPerformingReQualityInspection: "再品質検査の実行を開始します",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "アップロードファイル名",
        analysisTask: "タスクの分析",
        analyzeProgress: "進捗の分析",
        analysisSucceeded: "分析に成功しました",
        analysisFailed: "分析に失敗しました",
        localDataUpload: "ローカルデータのアップロード",
        recordingUpload: "録音アップロード",
        textUpload: "テキストのアップロード",
        uploadTime: "アップロード時間",
        taskName: "タスク名",
        operator: "オペレータ",
        qualityInspectionProgress: "品質検査の進捗",
        whole: "すべて",
        haveInHand: "進行中",
        completed: "完了しました",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "現在のフィルタ条件で完了したすべてのデータレコードをエクスポートしますか?",
        upload: "アップロード",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "名称を記入する",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "今回インポートしたデータのタイプ/集合/目的などの名前を入力します",
            default: "デフォルト",
            jDCOM: "京東",
            dataType: "データ型",
            selectAnalysisTask: "分析タスクの選択",
            uploadFiles: "ファイルのアップロード",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1.excelテンプレートをダウンロードし、書式で記入します",
            template: "テンプレート",
            SupportUpTo10000PiecesOfData: "2.2最大10000件のデータをサポート",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "京東がエクスポートした会話データのアップロードをサポートし、 .txtファイル、ファイルサイズは最大100mをサポートします",
            alongTheWayDataTemplate: "随行データテンプレート",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "テキストの品質検査中です。しばらくお待ちください。",
            textQualityInspectionCompleted: "テキスト品質検査完了",
          },
        },
        template: {
          startAnalysis: "分析を開始する",
          pleaseUploadTheFile: "ファイルをアップロードしてください",
          localDataUpload: "ローカルデータのアップロード",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "新機能! 「再確認送信」ボタンをクリックすると、送信が成功すると自動的に次のページにジャンプします。~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "品質検査タスク権限",
            edit: "編集",
            iParticipated: "私が参加した",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "このロールは、選択した部門のデータを表示できます",
            dataPermissions: "データ権限",
            selectDepartment: "部門の選択",
            edit: "編集",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "再品質検査の実行を開始します",
          callID: "通話ID",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "新機能! 「抽出検査提出」と「高速抽出検査」ボタンをクリックすると、提出が成功すると自動的に次のページにジャンプします。~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "ルール" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "ワークオーダー管理",
          workOrderDetails: "ワークオーダーの詳細",
        },
      },
      template: {
        workOrderFilter: "製造オーダーフィルタ",
        allWorkOrders: "全ワークオーダー",
        workOrderManagement: "ワークオーダー管理",
      },
    },
    trainingCenter: {
      topicAnnotation: "話題表記",
      topicList: "話題リスト",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "ロールタイプ",
              keyWord: "キーワード",
              pleaseEnterKeywordSearch: "キーワードを入力して検索してください",
              query: "照会",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "この機能は一時的に開放されていません。必要な場合はシステム管理者に連絡してください。",
          pleaseSelectAQuestion: "問題を選択してください!",
          recommendedSimilarSentencesLargeModel: "推奨類似文 (大モデル)",
          selectAll: "全選択",
          add: "追加",
          changeBatch: "ロット変更",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "業務勤務時間",
      localDataUpload: "ローカルデータのアップロード",
      assembleMenuListAccordingToCurrentUserPermissions:
        "現在のユーザー権限に基づいて、メニューリストを組み立てる",
      sessionSettings: "セッション設定",
      logoSettings: "ロゴ設定",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails: "お帰りなさい詳細を入力してください",
        mailbox: "メールアドレス",
        password: "パスワード",
        signIn: "ログイン",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "現在のIPはログインできません",
          welcomeToLogin: "ログインへようこそ",
          pursuingExcellenceAndLeadingChange: "卓越を追求し、変革をリードする",
          intelligentPlatform: "スマートプラットフォーム",
        },
        virtualLogin: { index: { pleaseSelect: "選択してください" } },
      },
      oauth: {
        illegalCall: "違法呼出",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "お帰りなさい下のボタンをクリックして認証プラットフォームにジャンプします。",
        loggingIn: "ログイン中...",
        unifiedIdentityAuthenticationLogin: "統一身分認証ログイン",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "ルールテスト: 一部のルールは、音声速度の音量、随行データなどのルールテストを使用できません",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "もっと知りたい",
          enteringTheSystem: "システムに入る",
        },
      },
      index: {
        cancel: "キャンセル",
        enteringTheSystem: "システムに入る",
        close: "閉じる",
        enterSystemApplication: "システムアプリケーションに入る",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          "「システムに入る」をクリックして、有効になっているシステムアプリケーションにジャンプします。",
      },
    },
    permissionManagement: {
      employeeManagement: "スタッフ管理",
      roleManagement: "ロール管理",
      permissionManagement: "権限管理",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "等しい",
            contain: "を含む",
            doesNotContain: "含まない",
            applicationSystem: "アプリケーションシステム",
            intelligentQualityInspection:
              "スマート品質検査/スマートセッション分析",
            salesEmpowerment: "販売エンパワーメント",
            dataSourceType: "データソースタイプ",
            filterCriteria: "フィルタ条件",
          },
        },
        index: {
          dataConnection: "データドッキング",
          savingSucceeded: "保存に成功しました",
          dataIsolation: "データ隔離",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "オンにすると、各アプリケーションシステムが通話、企業のマイクロ通信セッションにアクセスするデータ範囲を設定できます。スイッチがオンになっていない場合、各システムは全量データにアクセスします",
          accessRules: "アクセスルール",
          preservation: "保存",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "アプリ名",
            accessRights: "アクセス権限",
            inAppPermissionSettings: "アプリ内権限設定",
            setUp: "設定",
            applicationAccess: "アプリのアクセス権",
            newRole: "ロールの新規追加",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "セッションの詳細" } },
      messageNotification: {
        index: {
          sessionDetails: "セッションの詳細",
          caseDetails: "ケース詳細",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "従業員が見える",
            newCourse: "新規コース",
            editCourse: "コースの編集",
            courseName: "コース名",
            courseIntroduction: "コースの紹介",
            courseType: "コースタイプ",
            courseClassification: "コース分類",
            courseCover: "コース表纸",
            learningContent: "学習内容",
            uploadLearningMaterials: "学習資料をアップロードする",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "テスト",
              preservation: "保存",
              cancel: "キャンセル",
              editNodeName: "ノード名の編集",
              determine: "確定",
              individual: "個",
              of: ",共",
              branch: "分",
            },
            configDrawerComponents: {
              pPTParsing: "PPT解析中….",
              uploadSuccessful: "アップロードに成功しました",
              uploadAttachments: "添付ファイルのアップロード",
              pPTParsingPleaseWait: "PPT解析中です。お待ちください",
            },
            customerPortraitConfigDrawer: {
              cancel: "キャンセル",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "削除後は復元できません。この画像を削除してもいいですか?",
              delete: "削除",
              determine: "確定",
              customerPortrait: "顧客画像",
              bindSelectedCharacterPortrait: "選択したロール画像の紐付け",
              customerProfileDetails: "顧客画像の詳細",
              createANewCustomerProfile: "新規顧客画像",
            },
            flow: {
              sidebar: { node: "ノード" },
              useControlButtons: {
                cancellingPleaseWait: "キャンセル中です。お待ちください… は...",
                redoInProgressPleaseWait:
                  "やり直し中です。しばらくお待ちください… は...",
                automaticOrganization: "自動整理",
                canvasAdaptation: "キャンバス適応",
                delete: "削除",
              },
              useFlow: {
                nodeCannotBeConnectedToItself: "ノードは自分と接続できません",
              },
            },
            globalConfigDrawer: {
              cancel: "キャンセル",
              determine: "確定",
              globalConfiguration: "グローバル構成",
              sessionDetection: "セッション検出",
              reciprocalRule: "インタラクティブルール",
              robotVoice: "ロボット音色",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "学習者ノードのタイムアウトが設定に応答していません",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "学習者が許可されていない最大サイレント時間 (s)",
              promptScriptForFailureToAnswerOverTime:
                "タイムアウトしても答えが出ません。",
              addScript: "話術を追加する",
            },
            robotTimbreForm: {
              aixianatural: "艾夏(自然)",
              zhishaChat: "知莎 (チャット)",
              zhiqiangentle: "知倩(温和)",
              zhimiEmotion: "知米 (感情)",
              aiShuoNatural: "艾碩 (自然)",
              zhishuoChat: "知碩 (チャット)",
              aiHaoAffinity: "アイホ (親和)",
              zhixiangEmotion: "知祥 (感情)",
              robotVoice: "ロボット音色",
              schoolboy: "男子",
              girlStudent: "女の子",
              volume: "ボリューム",
              tone: "トーン",
              speechSpeed: "話の速さ",
              test: "テスト",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "こんにちは、私はスマートトレーニングコーチで、あなたとのコミュニケーションを楽しみにしています",
            },
            sessionDetectionForm: {
              expressionDetection: "表現検査",
              excessiveDetectionOfModalParticles: "語気語過多検出",
              theNumberOfRepetitionsIs: "繰り返し回数は",
              minus: "次の時、減ります",
              appear: "出現",
              subtract: "次、マイナス",
              branch: "分",
              addRules: "追加ルール",
              includingNegativeAngryComplaining: "(マイナス、怒る、愚痴を含む)",
              scorePointsDisplay: "得点ポイント表示",
              reminderOfSpeakingTooFasttooSlow:
                "言葉の速さが速すぎる/遅すぎる注意",
              scriptViolationReminder: "話術違反の注意",
              emotionalReminder: "感情リマインダー",
              speechSpeedDetection: "音声速度検出",
              normalSpeechSpeedScore: "言葉速正常得点",
              speakTooFast: "言葉の速さが速すぎる",
              everyMinuteGreaterThan: "毎分を超える",
              aWordAppears: "この字が現れます",
              speakTooSlowly: "言葉の速さが遅すぎる",
              lessThanPerMinute: "毎分未満",
              ignoreLessThan: "無視は以下です",
              aSentenceOfWords: "字の文",
              emotionalDetection: "感情検出",
              emotionalNormalScore: "感情正常得点",
              negativeEmotions: "マイナス感情",
              illegalWordDetection: "違反語の検査",
              scriptViolationConfiguration: "話術違反の配置",
              realTimePromptOnTheFrontEnd: "フロントエンドのリアルタイムヒント",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "知識リポジトリのq & a",
                  qAMethod: "Q & a方式",
                  numberOfQuestionsAndAnswers: "質疑応答数",
                  scoringRules: "得点ルール",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "いずれかの質疑応答は正しいです。",
                  branch: "分",
                  successfullySaved: "保存に成功しました",
                },
                pptNode: {
                  verificationPassed: "合格を検証する",
                  upload: "アップロード",
                  supportPptPdfFormatFiles: "Ppt、pdf形式のファイルに対応",
                  uploadSlide: "スライドをアップロード",
                  assessmentMethod: "審査方式",
                  associateSlide: "関連スライド",
                  standardScript: "標準話術",
                  successfullySaved: "保存に成功しました",
                },
                robotNode: {
                  robotScript: "ロボットの話",
                  randomScript: "ランダム話術",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "似た意味の話を選ぶと、ロボットはランダムにその中の一つを言います。",
                  customerPortrait: "顧客画像",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "お客様の画像が一致した場合、ロボットはこの話をします",
                  setAsDefaultScript: "デフォルトの話術に設定します",
                  yes: "はい",
                  no: "いいえ",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "ロボットがユーザータグにヒットしなかったときにこの話をする",
                  successfullySaved: "保存に成功しました",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "このブランチノードにはデフォルトのトークがあります。デフォルトのトークを現在のトークに置き換えますか?",
                },
                startNode: {
                  paragon: "ナレーション",
                  successfullySaved: "保存に成功しました",
                },
                userNode: {
                  verificationPassed: "合格を検証する",
                  standardScript: "標準話術",
                  successfullySaved: "保存に成功しました",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "設定を充実させてください",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "会話の例を入力してください",
                  dialogueExample: "会話の例",
                  generateConversationExamples: "セッションの生成例",
                  mattersNeedingAttention: "注意事項",
                  pleaseEnterThePromptLanguage: "提示語を入力してください",
                },
                aiGenSceneNode: {
                  dialogueScenario: "会話シーン",
                  simulateCharacter: "シミュレーションロール",
                  accompanyingTrainees: "コーチ相手",
                  selectFromTemplateLibrary: "テンプレートライブラリから選択",
                  templateLibrary: "テンプレートライブラリ",
                  role: "ロール",
                  scene: "シーン",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "スライドテストウィンドウ【",
              clearContent: "コンテンツをクリア",
              close: "閉じる",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI生成プロセストーク",
                scriptStyle: "話術スタイル",
                theMostConversationRounds: "会話の順番が一番多いです。",
                copyConversation: "会話のコピー",
                application: "応用",
                regenerate: "再生成",
                startGenerating: "生成を開始する",
                pleaseGenerateAProcess: "フローを生成してください",
                copySuccessful: "コピー成功",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "営業担当",
                    customerServicePersonnel: "カスタマーサービス担当者",
                    storeCounter: "店舗コーナー",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "全文スピーチ術を生成する",
                speaker: "講演者",
                scriptStyle: "話術スタイル",
                copyConversation: "会話のコピー",
                application: "応用",
                regenerate: "再生成",
                startGenerating: "生成を開始する",
                pleaseGenerateAProcess: "フローを生成してください",
                operationSuccessful: "操作に成功しました",
                copySuccessful: "コピー成功",
              },
            },
            aiTextArea: { index: { scriptPolishing: "話術の潤色" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "話は空白にできません!",
              copySuccessful: "コピー成功",
              cancel: "キャンセル",
              copy: "コピー",
              replaceTheOriginalText: "原文を置き換えます",
              aiGeneration: "Ai生成",
              aiPolishing: "Ai潤色",
              currentStyle: "現在のスタイル",
              rigorous: "厳格",
              mild: "温和",
              lively: "活発",
              simplicity: "シンプル",
              pleaseEnterTheSpeakerRole: "話者ロールを入力してください",
              startGenerating: "生成を開始する",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "話は空白にできません!",
              copySuccessful: "コピー成功",
              aiRewrite: "Ai書き換え",
              cancel: "キャンセル",
              copy: "コピー",
              replaceTheOriginalText: "原文を置き換えます",
              currentStyle: "現在のスタイル",
              rigorous: "厳格",
              mild: "温和",
              lively: "活発",
              simplicity: "シンプル",
              pleaseEnterTheSpeakerRole: "話者ロールを入力してください",
              startGenerating: "生成を開始する",
            },
          },
          index: {
            verificationPassed: "検証に合格しました",
            ruleValidation: "ルール検証",
            aIGeneration: "AI生成",
            generateFullTextSpeechScript: "全文スピーチ術を生成する",
            editCourse: "コースの編集",
            importConversation: "会話のインポート",
            uploadExcelToCompleteDialogueImport:
              "Excelをアップロードして会話のインポートを完了します",
            editBasicInformation: "基本情報の編集",
            test: "テスト",
            release: "リリース",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "新規ラベル" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "削除後は復元できません。このタグを削除してもよろしいですか?",
              delete: "削除",
              cancel: "キャンセル",
            },
          },
        },
        index: {
          labelManagement: "ラベル管理",
          newGroup: "グループの新規作成",
          edit: "編集",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "削除後は復元できません。このタググループを削除しますか?",
          delete: "削除",
          cancel: "キャンセル",
          editGroup: "グループの編集",
          labelGroupName: "ラベルグループ名",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "カメラを無効にしました",
              noCameraDevicesFound: "カメラ機器が見つかりません",
            },
          },
          cue: {
            index: {
              regenerate: "再生成",
              generate: "生成",
              referenceScript: "参考話術",
              mattersNeedingAttention: "注意事項",
              examFrequency: "試験回数",
              section: "第",
              timestotal: "回/共",
              second: "次",
              requirementsForPassingTheExam: "試験合格要求",
              examScore: "試験得点 ≧",
              branch: "分",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "画像なし",
              customerBackground: "顧客の背景",
              customerLabel: "顧客ラベル",
            },
          },
          flow: {
            index: {
              processReminder: "フローヒント (",
              score: "得点",
              TotalScore: "/総得点",
            },
          },
          index: {
            taskName: "タスク名",
            taskStatus: "タスクのステータス",
            whole: "すべて",
            completionStatus: "完了ステータス",
            courseClassification: "コース分類",
            practiceMode: "練習モード",
            examMode: "試験モード",
            practiceTime: "練習時間",
          },
          ppt: {
            index: {
              nextPage: "次のページ",
              tips: "ヒント",
              continueWithCurrentPage: "現在のページを続ける",
              goToTheNextPage: "次のページに進みます",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "次のページに入ると、前のページに戻ることはできません。現在のページの説明が完全であることを確認してからジャンプしてください",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "次のページをクリックすると、このヒントが表示されません",
            },
            list: { index: { slide: "スライド" } },
          },
          record: {
            index: {
              unableToRecord: "録音できません:",
              creatingARecorderService: "Recorderサービスの作成",
              turnOffTheRecorderService: "レコーダーサービスをオフにする",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "現在のクライアントはASR webソケットサーバに接続されています",
              afterProcessingTheLastFrameRestoreTheState:
                "最後のフレームを処理した後、ステータスを復元します",
              aSRConnectionClosed: "ASR接続が閉じられました… は...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "録音は一時停止し、処理済みですが、キャッシュにはまだデータがあります",
              foundASRChannelClosedRecreateWebsocketLink:
                "ASRチャネルが閉じていることがわかりました。webソケットリンクを再作成します",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "ASRチャネルがオンになっていないことがわかりました。webソケットリンクを再作成します",
            },
            ppt: { hook: { index: { serverError: "サーバーエラー" } } },
            text: {
              index: {
                pleaseEnter: "入力してください",
                sendOut: "送信",
                startPracticing: "練習を始める",
                startTheExam: "試験を始める",
              },
            },
            util: {
              recorded: "録画済",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16ビットのpcm SendFrameSizeは2の整数倍である必要があります",
              pCMSamplingRateShouldNotOccur:
                "Pcmサンプリングレートは発生しないでください",
              andTheRequiredSamplingRate: "必要なサンプリングレート",
              atypism: "一致しない",
              errorsThatShouldNotOccur: "発生すべきでないエラー:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> 順序が乱れたデータは、要求が高くなければ直接廃棄できます。',
              flower: "花",
              TransferStopped: ": 転送を停止しました",
            },
            video: { index: { camera: "カメラ" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "現在のクライアントはASR webソケットサーバに接続されています",
                    aSRConnectionClosed: "ASR接続が閉じられました… は...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "ASRチャネルが閉じていることがわかりました。webソケットリンクを再作成します",
                    unableToRecord: "録音できません:",
                    aSRChannelClosedReopened:
                      "ASRチャネルが閉じています。再度開きます。",
                    theASRChannelWasNotCreatedStartingNow:
                      "ASRチャネルは作成されていません。今から作成します。",
                    useEffectCallbackToClearTheASRChannel:
                      "ASRチャネルをクリアします。",
                    creatingARecorderService: "Recorderサービスの作成",
                    turnOffTheRecorderService: "レコーダーサービスをオフにする",
                  },
                  util: {
                    recorded: "録画済",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16ビットのpcm SendFrameSizeは2の整数倍である必要があります",
                    pCMSamplingRateShouldNotOccur:
                      "Pcmサンプリングレートは発生しないでください",
                    andTheRequiredSamplingRate: "必要なサンプリングレート",
                    atypism: "一致しない",
                    errorsThatShouldNotOccur: "発生すべきでないエラー:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> 順序が乱れたデータは、要求が高くなければ直接廃棄できます。',
                    flower: "花",
                    TransferStopped: ": 転送を停止しました",
                  },
                },
                index: { failInSend: "送信に失敗しました" },
              },
              index: {
                startRecording: "録音を開始する",
                endRecording: "録音を終了する",
                startPracticing: "練習を始める",
                startTheExam: "試験を始める",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(現在の試験の残り回数は",
              totalScriptScore: "話の総得点:",
              totalEmotionalScore: "感情総得点:",
              totalScoreOfExpression: "表現総得点:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "現在のタスクの評価結果は手動で審査する必要があります。審査が完了すると最終成績が通知されますので、しばらくお待ちください",
              areYouSureToSubmit: "提出を確認しますか?",
              theExerciseHasEndedAreYouSureToSubmit:
                "練習が終わりました。提出を確認しますか?",
              theExamHasEndedAreYouSureToSubmit:
                "試験が終わりました。提出を確認しますか。",
              completed: "完了しました",
              page: "ページ",
              pageCount: "総ページ数",
              topic: "問題",
              totalNumberOfQuestions: "総問題数",
              totalScore: "総得点",
              branch: "分",
              accumulatedCompletionProgress: "累計完了進捗",
              accumulatedPracticeDuration: "累積練習時間",
              lastPauseExerciseResults: "前回の一時停止-練習結果",
              exerciseInstructions: "練習説明",
              examInstructions: "試験の説明",
              halfwayPausePracticeResults: "途中一時停止-練習結果",
              tips: "ヒント",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "脱退後、この練習は成績を計上しません。脱退を確認しますか?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "試験の途中で退出することはできませんが、提出を確認していますか?",
              pleaseOperateWithCaution: "次は慎重に操作してください)",
              restart: "再開",
              returnToLearning: "学習に戻る",
              continuePracticing: "練習を続ける",
              continuingTheExam: "試験を続ける",
              return: "戻る",
              notSubmittedTemporarily: "提出しない",
              iGotIt: "わかりました",
              confirmExit: "終了の確認",
              confirmSubmission: "提出の確認",
              signOut: "脱退",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "一時停止",
              submit: "送信",
              remainingTimeOfThisQuestion: "本題の残り時間",
              SecondsLeftUntilTheEndTime: "終了まで残り10秒",
              remainingTime: "残り時間",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "ユーザーの音声コンテンツが検出されませんでした。もう一度お試しください。",
          },
          wave: {
            index: {
              continueRecording: "録音を続ける",
              submit: "送信",
              endRecording: "録音を終了する",
              suspendRecording: "録音を一時停止する",
              startPracticing: "練習を始める",
              startTheExam: "試験を始める",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "会話モード",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "騒音が大きすぎます。静かな環境に切り替えてください。",
              normalNoiseDetection: "ノイズ検出が正常",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "音量が小さすぎます。音量を大きくしてください。",
              volumeDetectionIsNormal: "音量検査は正常です",
              noiseDetection: "ノイズ検出",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "静かにして、3s環境音を録音してください。",
              endRecording: "録音を終了する",
              startRecording: "録音を開始する",
              volumeDetection: "ボリューム検出",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                "「今、ボリュームテストをします」を通常の音量で朗読してください",
              playLastDetectedAudio: "前回の検査音声を再生します。",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "開始前にご要望をよくお読みください",
              return: "戻る",
              iGotIt: "わかりました",
              timeLimitForSingleQuestionAnswering: "単題回答時間制限",
              totalTimeLimit: "総時間制限",
              unlimitedTime: "制限なし",
              stillNeedToCompleteTheTaskInTotal: "総完了タスクはまだ必要です",
              practice: "練習",
              second: "次",
              taskScore: "タスク一次得点 ≧",
              branch: "分",
              or: "または",
              examScore: "試験得点 ≧",
              remainingExamTimes: "残りの試験回数",
              totalScore: "総得点",
              Script: "(話術",
              scoreEmotion: "感情を分ける",
              fractionExpression: "分表现",
              points: "分)",
              standardScore: "基準達成ポイント",
              excellentScore: "優秀点",
              exerciseInstructions: "練習説明",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "解答がうまくいくように、まず設備検査を完了してください",
              camera: "カメラ",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "動画内で自分を見ることができます。カメラが正常であることを意味します。",
              microphone: "マイク",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "マイクに向かって話してください。波形模様が見えたら、マイクが正常であることを意味します。",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "空",
                  copyTo: "にコピー",
                  classification: "分類",
                  rename: "名前の変更",
                  newlyBuild: "新規作成",
                  categoryParent: "上位分類",
                  moveTo: "に移動します",
                  name: "名称",
                },
              },
              util: {
                rename: "名前の変更",
                moveTo: "に移動します",
                copyTo: "にコピー",
                delete: "削除",
                courseClassification: "コース分類",
                newlyBuild: "新規作成",
              },
            },
          },
          edit: { index: { curriculum: "コース" } },
          index: {
            learn: "学ぶ",
            practice: "練習する",
            examination: "試験",
            curriculum: "コース",
            courseIntroduction: "コース紹介:",
            createdOn: "作成時間:",
            published: "公開済み",
            tasks: "個のタスク",
            unpublishedTasks: "タスクが公開されていません",
            task: "タスク",
            operation: "操作",
            learningMaterials: "学習資料",
            goPractice: "練習に行く",
            publishTask: "タスクの公開",
            taskData: "タスクデータ",
            edit: "編集",
            copy: "コピー",
            successfullyCopied: "コピー成功",
            delete: "削除",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "削除後、このコースの下にあるすべてのタスクデータがクリアされます。コースを削除してよろしいですか?",
            courseName: "コース名",
            creationTime: "時間の作成",
            releaseStatus: "公開ステータス",
            whole: "すべて",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "音声が検出されません",
              voice: "音声",
              text: "テキスト",
              referenceScript: "参考話術:",
              clickOnTheRightSideToStartRecording:
                "右側をクリックして録音を開始します",
              sendOut: "送信",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "現在のクライアントはASR webソケットサーバに接続されています",
                afterProcessingTheLastFrameRestoreTheState:
                  "最後のフレームを処理した後、ステータスを復元します",
                aSRConnectionClosed: "ASR接続が閉じられました… は...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "録音は一時停止し、処理済みですが、キャッシュにはまだデータがあります",
                foundASRChannelClosedRecreateWebsocketLink:
                  "ASRチャネルが閉じていることがわかりました。webソケットリンクを再作成します",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "ASRチャネルがオンになっていないことがわかりました。webソケットリンクを再作成します",
                unableToRecord: "録音できません:",
                creatingARecorderService: "Recorderサービスの作成",
                turnOffTheRecorderService: "レコーダーサービスをオフにする",
              },
              util: {
                recorded: "録画済",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16ビットのpcm SendFrameSizeは2の整数倍である必要があります",
                pCMSamplingRateShouldNotOccur:
                  "Pcmサンプリングレートは発生しないでください",
                andTheRequiredSamplingRate: "必要なサンプリングレート",
                atypism: "一致しない",
                errorsThatShouldNotOccur: "発生すべきでないエラー:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> 順序が乱れたデータは、要求が高くなければ直接廃棄できます。',
                flower: "花",
                TransferStopped: ": 転送を停止しました",
              },
            },
            finishTest: {
              index: {
                endTest: "テストを終了する",
                testResult: "テスト結果",
                returnToCanvas: "キャンバスに戻る",
                retest: "再テスト",
              },
            },
          },
          index: {
            testFromCurrentNode: "現在のノードからテストする",
            listening: "収音中….",
            canvasTesting: "キャンバステスト",
            endOfProcess: "フロー終了",
            restart: "再開",
            start: "スタート",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "格納解析",
                  expandParsing: "解析を展開する",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "語気が多すぎる",
                  second: "次",
                  ratingOverview: "評価概要",
                  totalScore: "総得点",
                  section: "第",
                  name: "名前",
                  of: "(共",
                  people: "人)",
                  scriptScore: "話の得点",
                  common: "共",
                  keyPoints: "個のポイント、",
                  notReached: "個が達していません",
                  have: "あり",
                  violationOfRegulations: "処理違反",
                  emotionalScore: "感情スコア",
                  appear: "出現",
                  secondaryNegativeEmotions: "次のマイナス感情",
                  expressionScore: "得点を表現する",
                  speakTooFast: "言葉の速さが速すぎる",
                  times: "次、",
                  speakTooSlowly: "言葉の速さが遅すぎる",
                  average: "平均",
                  wordminute: "文字/分",
                },
              },
              score: {
                index: {
                  studentNodeName: "学習者ノード名",
                  keyPointName: "要点名称",
                  slideNodeName: "スライドノード名",
                  dialogue: "会話",
                  mainPoints: "ポイント",
                  slide: "スライド",
                  detectionResult: "検査結果",
                  satisfy: "満足",
                  dissatisfaction: "満足しない",
                  score: "得点",
                  script: "話術",
                  semantics: "セマンティック",
                  scriptViolation: "話術違反",
                  emotion: "感情",
                  expression: "表現",
                },
              },
              taskTitle: { index: { branch: "分" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "今回",
                  lastTime: "前回",
                  comprehensiveEvaluation: "総合評価",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "マージン-left: 8px"> ・標準話術累積',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "この字は、学習者がヒットした文字数を合わせて読みます。",
                  aWordspan: "文字 </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    '回 </span><br /><span style = "マージン-left: 8px"> ・語速が遅すぎる',
                  timesspan: "回 </span>",
                  tooManyModalParticles:
                    '<Span style = "マージン-left: 8px"> ・語気語が多すぎる',
                  complete: "内容完全",
                  keyHit: "ポイントヒット",
                  languageNorm: "言語仕様",
                  fluentExpression: "流暢な表現",
                  emotionallyPositive: "感情的に積極的である",
                  radarChart: "レーダーチャート",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "マージン-left: 8px"> ・完全フロー数',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "マージン-left: 8px"> ・ヒット要点数',
                  spanStylemarginLeftpxYes:
                    '<Span style = "マージン-left: 8px"> ・あり',
                  dealingWithIllegalWordsspan: "処違反語 </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "マージン-left: 8px"> ・スピードが速すぎる',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '回 </span><br /><span style = "マージン-left: 8px"> ・語気語が多すぎます。',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "マージン-left: 8px"> ・マイナス感情',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "評価記録",
                  firstRating: "初回評価",
                  totalScore: "総得点",
                  changeTo: "に変更",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "コミュニケーションステージ",
                  communicationObjectives: "コミュニケーション目標",
                  scoringCriteria: "評価基準",
                  suggestionsForImprovement: "改善の提案",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "残り回数:",
                  second: "次",
                  doItAgain: "もう一度",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "ブラウザは音声再生をサポートしていません。",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "話術のコメントと提案",
                  regenerate: "再生成",
                  generate: "生成",
                  generationFailed: "生成に失敗しました",
                },
              },
              resultModal: {
                index: { return: "戻る", viewDetails: "詳細を見る" },
              },
            },
            index: {
              reRatingSuccessful: "再評価に成功しました",
              reRatingFailed: "再評価に失敗しました",
              downloadRecording: "録音をダウンロードする",
              downloadText: "テキストのダウンロード",
              download: "ダウンロード",
              reRating: "再評価",
              submitARatingAppeal: "評価申立てを提出する",
              reviewSubmission: "再確認提出",
              saveChanges: "修正を保存する",
              previousSentence: "前の句",
              nextSentence: "次の文",
              score: "評価",
              scoringSituation: "得点状況",
              dialogueAnalysis: "対話解析",
            },
          },
          index: {
            ratingAppealInProgress: "評価クレーム中",
            operation: "操作",
            wrongQuestionSet: "間違いセット",
            ratingUpdate: "評価更新",
            viewDetails: "詳細を見る",
            curriculum: "コース",
            taskName: "タスク名",
            taskType: "タスクタイプ",
            score: "得点",
            scoringResults: "スコア結果",
            time: "時間",
            courseClassification: "コース分類",
            whole: "すべて",
            learningTime: "学習時間",
            historicalLearningData: "履歴学習データ",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "練習を一時停止する",
              lastProgress: "前回の進捗",
              continuePracticing: "練習を続ける",
              practice: "練習",
              startPracticing: "練習を始める",
              historicalData: "履歴データ",
              courseIntroduction: "コース紹介:",
              exerciseDifficulty: "練習の難しさ:",
              practiceCompletionRequirements: "練習完了要求:",
              practiceFrequency: "練習回数 ≧",
              second: "次",
              anyScore: "いずれかの得点 ≧",
              branch: "分",
              or: "または",
              taskList: "タスクリスト",
              practiceTime: "練習時間:",
              to: "に",
              unlimitedTime: "時間制限なし",
              completionStatus: "完了ステータス",
              numberOfExercises: "練習回数",
              maximumScore: "最高点",
              operation: "操作",
              enteringLearning: "学習に入る",
              historicalLearningData: "履歴学習データ",
              wrongQuestionSet: "間違いセット",
            },
          },
          index: {
            practiceTasks: "練習タスク",
            examTasks: "試験任務",
            task: "タスク",
          },
          learn: {
            index: {
              courseName: "コース名",
              courseIntroduction: "コースの紹介",
              learningContent: "学習内容",
              downloadLearningMaterials: "学習資料のダウンロード",
              startPracticing: "練習を始める",
            },
          },
          test: {
            index: {
              taskList: "タスクリスト",
              courseIntroduction: "コース紹介:",
              examDifficulty: "試験の難しさ:",
              requirementsForPassingTheExam: "試験合格要求:",
              examScore: "試験得点 ≧",
              branch: "分",
              examTime: "試験時間:",
              to: "に",
              unlimitedTime: "時間制限なし",
              completionStatus: "完了ステータス",
              completionTimes: "完了回数",
              maximumScore: "最高点",
              operation: "操作",
              immediateExam: "即試験",
              history: "履歴",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "参考話術" } },
              flow: {
                index: {
                  wrongQuestionItem: "エラー項目 (",
                  score: "得点",
                  TotalScore: "/総得点",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "サーバーエラー" } },
                  index: {
                    startRecording: "録音を開始する",
                    endRecording: "録音を終了する",
                    startPracticing: "練習を始める",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "入力してください",
                    sendOut: "送信",
                    startPracticing: "練習を始める",
                  },
                },
                voice: {
                  index: {
                    startRecording: "録音を開始する",
                    endRecording: "録音を終了する",
                    startPracticing: "練習を始める",
                  },
                  hook: { index: { failInSend: "送信に失敗しました" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "今回の間違いは得点を練習します。",
                  tips: "ヒント",
                  areYouSureToEnd: "終了を確認しますか?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "再度練習しても間違いがない",
                  continue: "続ける",
                  returnToTheSetOfIncorrectQuestions: "エラーセットに戻る",
                  end: "終了",
                  wrongQuestionItem: "エラー項目",
                  numberOfExercisesThisTime: "今回の練習回数",
                  masteringTheSituation: "状況を把握する",
                  mastered: "把握済み",
                  notMastered: "未把握",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "再度練習します。",
                  nextQuestion: "次の問題",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "間違った問題の練習",
                endExercise: "練習を終える",
              },
            },
            text: {
              index: {
                mispracticeExercises: "間違った問題の練習",
                endExercise: "練習を終える",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "間違った問題の練習",
                endExercise: "練習を終える",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "話術を見る",
                  section: "第",
                  secondaryDiscourseTechnique: "次の話",
                },
              },
              tableItem: {
                count: { second: "次" },
                scoreRuleList: {
                  noRelevantInformationFound: "関連情報が見つかりません",
                  recallWords: "リコールワード",
                  branch: "分",
                  answerIncorrectly: "答えが間違っています",
                  second: "次",
                  wrongAnswerRate: "回答率",
                },
                standardScript: {
                  standardScript: "標準話術",
                  myScript: "私の話術",
                },
                status: { mastered: "把握済み", notMastered: "未把握" },
                index: {
                  totalNumberOfQuestionsAnswered: "合計解答回数",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "間違った問題が練習された回数",
                },
              },
            },
            index: {
              wrongQuestionList: "間違いリスト",
              wrongQuestionSet: "間違いセット",
              mispracticeExercises: "間違った問題の練習",
              taskName: "タスク名",
              staff: "スタッフ",
              masteringTheSituation: "状況を把握する",
              whole: "すべて",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "練習回数 ≧",
              second: "次",
              or: "または",
              excellentScoreAtAnyOneTime: "いずれか一回得点優秀 (≧",
              points: "分)",
              examScore: "試験得点 ≧",
              branch: "分",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "ランダム平均分配",
              assignToDesignatedPersonnel: "指定者に割り当てます",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "試験回数を増やして成功する",
              failedToIncreaseTheNumberOfExams: "試験回数を増やして失敗する",
              increaseTheNumberOfExams: "試験回数を増やす",
              selectObjects: "対象を選択",
            },
          },
          executionFilter: { index: { participationRate: "参加率" } },
          intelligentReview: {
            index: {
              overallReview: "全体的なコメント",
              pleaseEnterTheReviewCriterianotMandatory:
                "評価基準を入力してください (必須ではありません)",
              commentaryOnSingleSentenceRhetoric: "単文話術のコメント",
              realTimeReview: "リアルタイム評価",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "試験平均点",
            averageExamDurationmin: "平均試験時間 (min)",
            accumulatedPracticeDurationmin: "累積練習時間 (min)",
            accumulatedPracticeTimes: "累積練習回数",
            totalScore: "総得点",
            average: "平均点",
            total: "総数",
            keyHitCount: "ポイントヒット回数",
            practiceAverageScore: "練習平均点",
            averagePracticeDurationmin: "平均練習時間 (min)",
            topOfTheHighestScore: "最高点Top3",
            branch: "分",
            errorProneItemTop: "間違いやすいTop5",
            hitRate: "ヒット率",
            hitRateOfKeyPointsInScript: "話のポイントの命中率",
            averageScriptScore: "話術の平均得点",
            sortByProcess: "フローによるソート",
            sortByScore: "スコア順",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "このページの平均点/このページの総得点",
            averageScoreOfThisItemtotalScoreOfThisItem: "当該平均点/当該総得点",
            details: "詳細",
            viewRules: "ルールの表示",
            standardScript: "標準話術",
          },
          scoreDetailList: {
            section: "第",
            second: "次",
            practice: "練習",
            examination: "試験",
            notMeetingStandards: "基準に達していません",
            meetingStandards: "基準達成",
            excellent: "優秀",
            frequency: "回数",
            fraction: "スコア",
            result: "結果",
            operation: "操作",
            viewDetails: "詳細を見る",
          },
          index: {
            common: "共",
            people: "人",
            staff: "スタッフ",
            notMeetingStandards: "基準に達していません",
            meetingStandards: "基準達成",
            excellent: "優秀",
            notStarted: "開始していません",
            haveInHand: "進行中",
            completed: "完了しました",
            passed: "通過済み",
            hangInTheAir: "未完了",
            failed: "不合格",
            unlimitedTime: "時間制限なし",
            to: "に",
            maximumScoringCriteria: "最高得点基準",
            completionStatus: "完了ステータス",
            employeeName: "スタッフ氏名",
            frequency: "回数",
            practice: "練習",
            examination: "試験",
            average: "平均点",
            speechCraftAvgScore: "話術の平均得点",
            emotionAvgScore: "感情平均得点",
            speedAvgScore: "平均得点を表現する",
            flowAvgScore: "フロー平均得点",
            practiceCostTime: "積算時間",
            participateCommitRate: "達成率",
            participateExcellentRate: "優秀率",
            duration: "時間",
            score: "得点",
            scoringResults: "スコア結果",
            flowScore: "フロースコア",
            craftScore: "話の得点",
            emotionScore: "感情スコア",
            speedScore: "得点を表現する",
            contentIntegrity: "内容の完全性",
            pointHitRate: "ポイントヒット率",
            emotionNegativeRate: "感情マイナス比率",
            paraPhaSiaRate: "言葉速異常比率",
            toneOvermuchRate: "語気語が多すぎる",
            maximumScore: "最高点",
            highestScoreResult: "最高得点の結果",
            state: "ステータス",
            averageDurationmin: "平均時間 (min)",
            lately: "最近",
            time: "時間",
            operation: "操作",
            viewDetails: "詳細を見る",
            wrongQuestionSet: "間違いセット",
            taskName: "タスク名",
            completeRequirements: "要求を完了する",
            publishTo: "公開オブジェクト",
            taskTime: "タスク時間",
            fractionalComposition: "スコア構成",
            totalScore: "総得点",
            branch: "分",
            Script: "(話術",
            scoreEmotion: "感情を分ける",
            fractionExpression: "分表现",
            points: "分)",
            implementationRate: "参加率",
            numberOfExecutors: "参加者数",
            numberOfPeopleNotExecuted: "未参加人数",
            completionRate: "完了率",
            numberOfCompletedPersonnel: "完了人数",
            numberOfUnfinishedPersonnel: "未完了人数",
            complianceRate: "達成率",
            numberOfQualifiedPersonnel: "基準達成人数",
            numberOfNonCompliantIndividuals: "基準に達していない人数",
            excellentRate: "優秀率",
            participateCompleteRate: "参加完了率",
            numberOfOutstandingIndividuals: "優秀な人数",
            numberOfNonOutstandingIndividuals: "非優秀人数",
            export: "エクスポート",
            taskData: "タスクデータ",
            resultAnalysis: "結果分析",
            scoreDetails: "スコアの詳細-",
            dataOverview: "データの概要",
            taskDetail: "タスク明細",
            scriptAnalysis: "話術分析",
            flowAnalysis: "フロー分析",
            expressionAnalysis: "表現分析",
            emotionAnalysis: "感情分析",
            byTime: "時間次元ビュー",
            byNumber: "回数次元ビュー",
            personData: "人員別統計",
            numberData: "回別に明細を記録する",
            count: "回数別",
            person: "人数別",
            reachTimes: "接触回数",
            outOfReachTimes: "未接触回数",
            seeOutOfReach: "クリックして未接触を確認します。",
            accumulative: "累計",
            script: "話術",
            times: "次",
            sentence: "文",
            expressionAverage: "表現平均点",
            modalAnalysis: "語気語分析",
            total: "共",
            muchModalAnalysis: "語気が多すぎる",
            normal: "正常",
            speedAnalysis: "語速分析",
            speedDistribution: "語速分布",
            speakingSpeed: "話の速さ",
            normalSpeak: "通常のスピード",
            tooFastSpeak: "速すぎる",
            tooSlowlySpeak: "言葉の速さが遅い",
            violationWord: "違反語",
            violationWordAnalysis: "違反ワード分析",
            noData: "まだありません",
            emotionalScore: "感情スコア",
            emotionalDistribution: "情緒分布",
            negativeEmotion: "マイナス感情",
            normalEmotion: "正常な感情",
            getDetail: "詳細を見る",
            barPattern: "棒グラフ表示",
            timeDimension: "時間緯度ビュー",
            timesDimension: "回数次元ビュー",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "平均点",
                  averageScoreOfScript: "話の平均点",
                  averageEmotionalScore: "気分平均点",
                  expressionAverageScore: "平均得点を表現する",
                  processAverageScore: "プロセス平均点",
                  accumulatedDuration: "累積時間",
                  accumulatedTimes: "積算回数",
                  participationComplianceRate: "参加達成率",
                  participationRateForAchievingExcellence: "参加率",
                  branch: "分",
                  numberOfPeople: "人数",
                  averageScoreOfExpression: "表現平均点",
                  people: "人",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: { index: { negativeEmotions: "マイナス感情" } },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "話術の平均得点",
                  branch: "分",
                },
                useSummaryDetail: { keyHitRate: "ポイントヒット率" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "スマートコメント",
            pleaseImproveTheConfiguration: "設定を充実させてください",
            examResults: "試験結果",
            showTheResultsAfterEachExam: "試験が終わるたびに結果を展示する",
            showTheResultsAfterTheLastExam:
              "最後の試験が終わったら結果を見せます。",
            doNotDisplayResults: "結果を表示しない",
            resultDisplay: "結果表示",
            score: "得点",
            scoringResults: "スコア結果",
            allowViewingScoreDetails: "スコアの詳細を見ることができます",
            viewCanvasConfiguration: "キャンバス設定の表示",
            synchronizationFailedProcessNodeHasChanged:
              "同期に失敗しました。フローノードに変更がありました。",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "同期に成功しました。「更新コースの公開」をクリックしてください",
            retrievedTheLatestCanvasConfiguration:
              "最新のキャンバス設定が取得されました",
            synchronizeCanvasConfiguration: "キャンバス構成の同期",
            scoringMethod: "採点方法",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "分、流れ {0}分、話術 {1}分、情緒 {2}分、語速{3}分)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "優秀な基準は基準を超える必要があります。",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "評価基準は0% 以上、100% 以下を満たす必要があります",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "評価基準は0点以上、 {0} 点以下を満たす必要があります。",
            doRatingsRequireManualReview:
              "評価は手動で審査する必要がありますか?",
            allocationStrategy: "ポリシーの割り当て",
            pleaseSelectTheAssignedPersonnel: "配属者を選択してください",
            pleaseSelectADesignatedPerson: "指定者を選択してください",
            timeLimitForSingleQuestionAnswering: "単題回答時間制限",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "新しい追加スタッフにデフォルトで公開されています",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "再評価は、最新のキャンバスのスコアルールに基づいてマッチングされ、現在のタスクには手動で再確認されたスコアデータが存在し、スコアをカバーするかどうかを示します",
            notCovered: "カバーしない",
            cover: "カバー",
            participationSituation: "参加状況",
            numberOfParticipants: "参加者数",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "このタスクで選択した練習対象者数合計",
            sumOfTheNumberOfParticipantsInTheTask: "参加したタスクの人数合計",
            numberOfCompletedPersonnel: "完了人数",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "タスク完了要求を満たす人数合計",
            numberOfQualifiedPersonnel: "基準達成人数",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "任務の得点を満たす人数の合計",
            excellentPersonnel: "優秀な人数",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "任務得点を満たす優秀な人数の合計",
            participationRate: "参加率",
            numberOfParticipantsNumberOfParticipants: "参加者数/参加者数",
            numberOfCompletedNumberOfParticipants: "完了人数/参加人数",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "基準達成人数/参加人数",
            excellentNumbernumberOfParticipants: "優秀人数/参加人数",
            participationCompletionRate: "参加完了率",
            numberOfCompletedParticipated: "完了人数/参加人数",
            participationComplianceRate: "参加達成率",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "基準達成人数/参加人数",
            participationRateForAchievingExcellence: "参加率",
            accumulatedDuration: "累積時間",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "学習者がタスクに参加した時間の合計",
            averageDuration: "平均時間",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "受講者がタスクに参加した時間の合計/総参加回数",
            accumulatedTimes: "累積回数",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "学習者がタスクに参加した回数の合計",
            averageScore: "平均点",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "このタスクに参加した人の平均得点",
            maximumScore: "最高点",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "このタスクに参加した人の最高点",
            averageScoreOfScript: "話の平均点",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "学習者の話術得点合計/総参加回数",
            totalScoreOfScript: "話術総得点",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "この任務で設定した話の総得点",
            processAverageScore: "プロセス平均点",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "学習者プロセススコア合計/総参加回数",
            totalProcessScore: "フロー総得点",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "このタスクで設定されたプロセスの総得点",
            averageEmotionalScore: "気分平均点",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "学習者の感情スコア合計/総参加回数",
            totalEmotionalScore: "感情総得点",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "このタスクで設定された感情の総得点",
            averageScoreOfExpression: "表現平均点",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "学習者は得点合計/総参加回数を表現する",
            totalScoreOfExpression: "総得点を表現する",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "このタスクで設定した表現総得点",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "再評価の実行を開始するには、タスクセンターにジャンプして進捗状況を確認します",
            reRating: "再評価",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "再評価とは、最新のキャンバスのスコアルールで一致し、現在のスコア結果を上書きすることを示しています",
            releaseTime: "リリース時間",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "審査済",
              pendingReview: "審査待ち",
              enterKeywordSearch: "キーワードを入力して検索します",
            },
            pageRightTable: {
              index: {
                employeeName: "スタッフ氏名",
                systemRating: "システム評価",
                scoringResults: "スコア結果",
                duration: "時間",
                time: "時間",
                state: "ステータス",
                whetherToModifyTheRating: "評価を修正しますか",
                finalScore: "最終得点",
                reviewedBy: "審査人",
                operation: "操作",
                viewScoreDetails: "得点の詳細を見る",
                viewStatus: "ステータスの表示",
                whole: "すべて",
                correctionStatus: "修正ステータス",
                completeReview: "審査を完了する",
                tips: "ヒント",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "受講者の最終成績は現在の最終審査結果に基づいていますので、確認してください",
              },
            },
          },
          index: { manualReview: "手動審査" },
        },
        review: {
          index: {
            employeeName: "スタッフ氏名",
            score: "得点",
            scoringResults: "スコア結果",
            duration: "時間",
            taskName: "タスク名",
            numberOfAppealScripts: "訴える話の数",
            appealTime: "申し立て時間",
            processStatus: "フローステータス",
            scoreAfterReview: "再確認後の得点",
            reviewedBy: "再確認者",
            operation: "操作",
            viewScoreStatus: "得点状況を見る",
            whole: "すべて",
            manualReview: "手動再確認",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "練習タスクデータ",
            implementationRate: "実行率",
            numberOfExecutors: "実行人数",
            numberOfPeopleNotExecuted: "未実行人数",
            completionRate: "完了率",
            numberOfCompletedPersonnel: "完了人数",
            numberOfUnfinishedPersonnel: "未完了人数",
            complianceRate: "達成率",
            numberOfQualifiedPersonnel: "基準達成人数",
            numberOfNonCompliantIndividuals: "基準に達していない人数",
            excellentRate: "優秀率",
            numberOfOutstandingIndividuals: "優秀な人数",
            numberOfNonOutstandingIndividuals: "非優秀人数",
            examTaskData: "試験タスクデータ",
            published: "公開済み",
            courses: "個のコース",
            courseData: "コースデータ",
            practiceTasks: "練習タスク",
            examTasks: "試験任務",
          },
          practiceSummary: {
            practiceSummary: "練習のまとめ",
            numberOfPracticeTasks: "練習タスク数",
            numberOfExercises: "練習回数",
            accumulatedPracticeDuration: "累積練習時間",
            complianceRate: "達成率",
            excellentRate: "優秀率",
            ranking: "ランキング",
          },
          rankingList: {
            numberOfExercises: "練習回数",
            exerciseDuration: "練習時間",
            complianceRate: "達成率",
            excellentRate: "優秀率",
            diligenceChart: "勤勉リスト",
            excellentList: "優秀なランキング",
            ranking: "ランキング",
            fullName: "氏名",
            i: "わたし",
          },
          taskCard: {
            end: "締め切り:",
            noDeadline: "締切日なし",
            second: "次",
            branch: "分",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "タスクなし",
            completed: "完了しました",
          },
          filter: { employeeDepartment: "スタッフ部門" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "エクスポートに成功しました。タスクセンターで確認してください。",
              export: "エクスポート",
            },
          },
          filterConfig: {
            dropdown: { custom: "カスタム" },
            modal: {
              updateSuccessful: "更新に成功しました",
              successfullySaved: "保存に成功しました",
              setAsACommonReport: "よく使うレポートに設定",
              updateCurrentReport: "現在のレポートの更新",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "平均点",
                  averageScoreOfScript: "話術の平均得点",
                  averageEmotionalScore: "感情平均得点",
                  expressionAverageScore: "平均得点を表現する",
                  processAverageScore: "フロー平均得点",
                  branch: "分",
                },
              },
              comprehensiveEvaluation: { const: { data: "データ" } },
              doneStatus: {
                index: {
                  practice: "練習",
                  examination: "試験",
                  executed: "実行済",
                  unexecuted: "実行されていません",
                  accumulatedExamDuration: "累積試験時間",
                  examFrequency: "試験回数",
                },
              },
              filter: {
                index: {
                  practice: "練習",
                  examination: "試験",
                  pleaseCompleteTheRequiredFields:
                    "必須項目を充実させてください",
                  staff: "スタッフ",
                  taskType: "タスクタイプ",
                  pleaseSelect: "選択してください",
                  taskName: "タスク名",
                },
              },
            },
            index: {
              completionStatus: "完了状況",
              comprehensiveEvaluation: "総合評価",
              abilityGrowthTrend: "能力成長傾向",
              errorProneAnalysis: "誤りやすい点分析",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "アクティブ人数",
            accumulatedPracticeDuration: "累積練習時間",
            averagePracticeDuration: "平均練習時間",
            people: "人",
            numberOfPracticeTasks: "練習タスク数",
            numberOfExercises: "練習回数",
            exerciseDuration: "練習時間",
            second: "次",
            avgScore: "平均点",
            speechCraftAvgScore: "話術の平均得点",
            emotionAvgScore: "感情平均得点",
            speedAvgScore: "平均得点を表現する",
            flowAvgScore: "フロー平均得点",
            practiceCostTime: "積算時間",
            totalPracticeTimes: "積算回数",
            participateCommitRate: "参加達成率",
            participateExcellentRate: "参加率",
          },
        },
        index: {
          employeeProfile: "スタッフ画像",
          overview: "総覧",
          personalSignage: "個人看板",
          ongoingExercises: "進行中の練習",
          ongoingExams: "進行中の試験",
          practiceTaskProgress: "練習タスクの進捗",
          examTaskProgress: "試験タスクの進捗",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "概念語を選択してください",
              sessionDetectionSettings: "セッション検出設定",
              successfullySaved: "保存に成功しました",
              abnormalSpeechSpeed: "スピード異常",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "カスタム語速検査基準をサポートし、結果的に語速が正常、語速が速すぎ、語速が遅すぎると判断される。言葉速異常 (早すぎる/遅すぎる) が発生すると、採点ルールで減点されます",
              speechSpeedTooFastGreaterThanEveryMinute:
                "語速が速すぎる: 毎分以上",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "この字は、語速が遅すぎる: 毎分未満",
              charactersIgnoringLessThan: "文字は無視してより小さいです。",
              aSentenceOfWords: "字の文",
              excessiveModalParticles: "語気語が多すぎる",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "カスタム語気語をサポートし、語気語のルールを設定します。語気が多すぎると、採点ルールで減点されます",
              theNumberOfRepetitionsIs: "繰り返し回数は",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "次の時、語気語が多すぎると判定します。",
              definitionOfSensitiveViolations: "機密違反の定義",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "カスタム違反語辞書をサポートし、1つの文に違反語が発生していないかどうかを検出します。違反語が出た場合、採点ルールで減点されます",
              violationWords: "違反語:",
              emotionalDetection: "感情検出",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "システム感情モデル対話術に基づいて感情検査を行うと、結果的に感情が正常で、感情がマイナス (マイナス、怒り、苦情を含む) と判断される。感情がマイナスになると、採点ルールで減点されます",
              preserve: "保存",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "評価次元",
              ratingPoints: "採点ポイント",
              explain: "説明",
              type: "タイプ",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "ランダムデータ" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "マージン-left: 8px"> ・標準話術累積',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "この字は、学習者がヒットした文字数を合わせて読みます。",
                  aWordspan: "文字 </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    '回 </span><br /><span style = "マージン-left: 8px"> ・語速が遅すぎる',
                  timesspan: "回 </span>",
                  tooManyModalParticles:
                    '<Span style = "マージン-left: 8px"> ・語気語が多すぎる',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "スコアは" },
                  item: {
                    violationOnceReduced: "違反1回減",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "话すスピードの异常 (话すスピードが速すぎる、话すスピードが遅すぎるを含む) を1回减らす",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "語気語が多すぎて1回減った",
                    oneNegativeDecreaseInEmotions: "感情マイナス1回マイナス",
                    branch: "分",
                  },
                  wrap: { name: "名称", preserve: "保存" },
                },
              },
              index: {
                complete: "内容完全",
                keyHit: "ポイントヒット",
                accurateFollowupReading: "正確にフォローします",
                languageNorm: "言語仕様",
                speechSpeed: "話の速さ",
                modalParticles: "語気語",
                emotionallyPositive: "感情的に積極的である",
                scoringModel: "評価モデル",
                successfullyModified: "修正に成功しました",
                newSuccessfullyCreated: "新規作成に成功しました",
              },
            },
            index: {
              scoringModel: "評価モデル",
              newModel: "新規モデル",
              model: "モデル",
              type: "タイプ",
              operation: "操作",
              preview: "プレビュー",
              edit: "編集",
            },
          },
          index: {
            scoringModel: "評価モデル",
            ratingDimension: "評価次元",
            sessionDetectionSettings: "セッション検出設定",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "選択してください",
                hit: "ヒット",
                misses: "ヒットしません",
              },
            },
          },
          index: {
            duration: "時間",
            processScore: "フロースコア",
            branch: "分",
            contentCompleteness: "内容の完全性",
            scriptScore: "話の得点",
            keyHitRate: "ポイントヒット率",
            emotionalScore: "感情スコア",
            negativeProportionOfEmotions: "感情マイナス比率",
            expressionScore: "得点を表現する",
            theProportionOfAbnormalSpeechSpeed: "言葉速異常比率",
            excessiveProportionOfModalParticles: "語気語が多すぎる",
            learningRecordData: "学習記録データ",
            staff: "スタッフ",
            curriculum: "コース",
            mainPoints: "ポイント",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "テーブル",
                        lineChart: "折れ線グラフ",
                        barChart: "ヒストグラム",
                        pieChart: "円グラフ",
                      },
                      index: { switchingTypes: "切り替えタイプ" },
                    },
                  },
                  index: {
                    saveSuccessful: "保存に成功しました",
                    editChart: "グラフの編集",
                    chartName: "グラフ名",
                    deleteSuccessful: "削除に成功しました",
                    refresh: "更新",
                    export: "エクスポート",
                    areYouSureToDeleteIt: "本当に削除しますか?",
                    delete: "削除",
                    viewFilteringCriteria: "フィルタ条件の表示",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "エクスポートに成功しました。タスクセンターで確認してください。",
              },
            },
            header: {
              index: {
                saveSuccessful: "保存に成功しました",
                deleteSuccessful: "削除に成功しました",
                editReport: "レポートの編集",
                reportName: "レポート名",
                refresh: "更新",
                areYouSureToDeleteIt: "本当に削除しますか?",
                delete: "削除",
                exportToExcel: "エクセルをエクスポート",
                exportSnapshot: "スナップショットのエクスポート",
                export: "エクスポート",
                authority: "権限",
                permissionSettings: "権限設定",
                reportVisiblePermissions: "レポートの表示権限",
                visibleToEveryone: "全員に見える",
                onlyVisibleToOneself: "自分だけが見える",
                designatedPersonnelVisible: "指定された人が表示されます",
                pleaseSelect: "選択してください",
                reportDataPermissions: "レポートデータ権限",
                restrictedByUserPermissions: "ユーザー権限に制限されています",
                visiblePeopleAreTheSame: "見える人は同じです",
              },
            },
            titleFooter: {
              index: {
                addReport: "レポートの新規追加",
                reportName: "レポート名",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "エクスポートに成功しました。タスクセンターで確認してください。",
            dataQueryInProgress: "データ照会中….",
            customKanban: "カスタム看板",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "テーブル",
                lineChart: "折れ線グラフ",
                barChart: "ヒストグラム",
                pieChart: "円グラフ",
              },
              index: {
                successfullyAdded: "追加に成功しました",
                viewSQL: "Sqlの表示",
                export: "エクスポート",
                addToReport: "レポートに追加",
              },
            },
            refer: {
              hooks: {
                data: "データ",
                interrogativeSentence: "質問文",
                historicalQuery: "履歴照会",
              },
              index: { copySuccessful: "コピー成功", copy: "コピー" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "基準に達していません",
                        meetingStandards: "基準達成",
                        excellent: "優秀",
                        notStartedYet: "開始していません",
                        inProgress: "進行中",
                        completed: "完了しました",
                        incomplete: "未完了",
                      },
                    },
                  },
                  const: {
                    taskName: "タスク名",
                    department: "部門",
                    studentName: "学習者の氏名",
                    scoringResults: "スコア結果",
                    taskCompletionStatus: "タスク完了ステータス",
                  },
                  index: { pleaseSelectADimension: "次元を選択してください" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "@ を入力すると、弾枠を開いて次元選択ができます",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "問い合わせ文を入力して直接問い合わせてください",
                query: "照会",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "エクスポートに成功しました。タスクセンターで確認してください。",
            dataQueryInProgress: "データ照会中….",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "キーワード検索",
                previous: "前の",
                next: "次へ",
                theObjectThatTriggersTheScrollingEventIs:
                  "ロールイベントをトリガーするオブジェクトは",
                userScrolling: "ユーザースクロール",
              },
            },
            summary: {
              index: {
                reminder: "提示語:",
                edit: "編集",
                name: "名称",
                hintLanguage: "提示語",
              },
            },
            viewpoint: { index: { viewpointExtraction: "観点抽出" } },
            outline: {
              index: {
                numberOfKeywords: "キーワード回数",
                speaker: "話し手",
                speechDuration: "話す時間が長い",
                proportionOfSpeaking: "話す割合",
                maximumSpeechDuration: "最長発言時間",
                numberOfQuestions: "質問数",
                averageSpeechSpeed: "平均語速",
                conversationalActions: "会話アクション",
                contentSummary: "コンテンツの概要",
                generateSummary: "サマリーの生成",
                keywordCloud: "キーワード語雲",
              },
            },
          },
          index: {
            outline: "概要",
            viewpoint: "観点",
            contentSummary: "コンテンツの概要",
            viewpointExtraction: "観点抽出",
            summaryOfKeyPoints: "要点まとめ",
            videoAnalysis: "ビデオ分析",
          },
        },
        list: {
          index: {
            delete: "削除",
            uploadSuccessful: "アップロードに成功しました",
            videoList: "動画リスト",
            uploadVideo: "動画をアップロード",
            videoName: "ビデオ名",
            uploadTime: "アップロード時間",
            endTime: "終了時間",
            analyzeProgress: "進捗の分析",
            haveInHand: "進行中",
            completed: "完了しました",
            fail: "失敗",
            toBegin: "開始待ち",
            operation: "操作",
            see: "表示",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "勤務時間の追加" },
        },
      },
      index: {
        businessWorkingHours: "業務勤務時間",
        newlyBuild: "新規作成",
        name: "名称",
        describe: "説明",
        operation: "操作",
        edit: "編集",
        delete: "削除",
        editBusinessWorkingHours: "業務勤務時間の編集",
        newBusinessWorkingHours: "新規業務勤務時間",
        workingHours: "勤務時間",
      },
    },
    algorithmSemanticIntelligence: { intelligentExtraction: "スマート抽出" },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "本当にキャンセルしますか? キャンセル後は復元できません",
        },
      },
    },
    accountManagement: {
      securitySetting: "セキュリティ設定",
      accountManagement: "アカウント管理",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "に",
            switchToIPAddressMode: "Ipアドレスモードに切り替える",
            switchToIPSegmentMode: "IPセグメントモードに切り替える",
          },
        },
      },
      index: {
        saveSuccessful: "保存に成功しました",
        pleaseImproveTheConfiguration: "設定を充実させてください",
        securitySetting: "セキュリティ設定",
        employeeLoginIPRestrictions: "スタッフログインIP制限",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "従業員がログインできるIP範囲を制限し、範囲を超えてログインできない。Webサイトのみが有効で、モバイルappは制限されず、管理者は制限されない。",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "新規追加または変更する場合は、エクストラネットIPセグメント (同じCセグメント) または固定エクストラネットIPアドレスを入力してください。",
        allowLoginIPRange: "ログイン可能なIP範囲",
        preserve: "保存",
      },
    },
    coachAdminEntry: { speechRecognition: "音声認識" },
    billingCenter: {
      template: {
        details: "詳細",
        languageType: "言語タイプ",
        aSREngine: "ASRエンジン",
        voiceQualityQuota: "音声品質検査クォータ",
        realtimeData: "リアルタイムデータ",
        calculatedByDay: "日ごとに計算する",
      },
      component: {
        aSRName: "ASR名",
        language: "言語",
        remainingQuota: "余剰クォータ",
        hours: "時間",
        usedQuota: "使用済みクォータ",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "操作保存成功!",
        deletedSuccessfully: "削除に成功しました!",
        pleaseSelectLanguage: "言語を選択してください",
        pleaseConfigureRules: "ルールを設定してください",
        updateSuccessful: "更新に成功しました!",
        operationSuccessful: "操作に成功しました!",
      },
      template: {
        aSRLanguage: "ASR言語",
        pleaseSelect: "選択してください",
        model: "モデル",
        pleaseSelectLanguage: "言語を選択してください",
        delete: "削除",
        newASRModel: "ASRモデルの新規作成",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "競争品分析" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Eコマースフィルタ",
        allComments: "すべてのコメント",
        ecommerceReviewManagement: "Eコマースのコメント管理",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "ソーシャルメディアのコメントフィルタ",
        allComments: "すべてのコメント",
        socialMediaReviewManagement: "ソーシャルメディアのコメント管理",
      },
    },
    conversationAnalysisDataAnalysis: { competitionAnalysis: "競争品分析" },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "顔写真",
        realname: "氏名",
        nickname: "ニックネーム",
        name: "氏名",
        email: "アカウント (メールアドレス)",
        mobile: "電話",
        agentId: "スタッフ番号",
        position: "ポスト",
        role: "ロール",
        actions: "操作",
        createUser: "スタッフの新規追加",
        editUser: "編集スタッフ",
        account: "アカウント",
        originPassword: "初期パスワード",
        newPassword: "新しいパスワード",
        resumePassword: "パスワードの確認",
        userGroup: "スタッフグループ",
        authorizationGroup: "権限グループ",
        resetPassword: "パスワードのリセット",
      },
      userGroups: {
        id: "ID",
        name: "名称",
        userCount: "従業員数",
        actions: "操作",
        createUserGroup: "スタッフグループの新規追加",
        editUserGroup: "スタッフグループの編集",
        member: "メンバー",
      },
      role: {
        id: "ID",
        name: "名称",
        actions: "操作",
        all: "すべて",
        taskName: "タスク",
        readTask: "タスクの確認",
        manageTemplate: "テンプレート管理",
        manualInspection: "人工再検査",
        readResultAll: "結果の確認",
        readResultPersonal: "結果確認 (個人)",
        readProcess: "品質検査プロセスの確認",
        readReport: "統計とレポート",
      },
    },
    download: {
      downloadCenter: {
        createTime: "日付",
        taskName: "名称",
        size: "大きさ",
        status: "ステータス",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "名称",
            remark: "説明",
            actions: "操作",
          },
          checkPoint: {
            name: "名称",
            remark: "説明",
            type: "タイプ",
            gradeType: "評価方式",
            conditionLogic: "ルール",
            predeterminedScore: "スコア",
            weight: "重み",
            status: "ステータス",
            actions: "操作",
            switchOn: "有効化",
            switchOff: "無効",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "時間",
            customer: "お客様",
            customerPhone: "お客様の電話番号",
            callType: "通話タイプ",
            source: "ソース",
            voiceRecording: "通話録音",
            graded: "評価",
            openingGreeting: "オープニング挨拶",
          },
          checkPointList: {
            name: "品質検査ポイント",
            type: "タイプ",
            deductScore: "スコア",
            isHit: "ヒット状況",
            status: "再確認",
            finalDeductScore: "減点の確認",
            hit: "ヒット",
            notHit: "ヒットしません",
          },
        },
      },
    },
    custom: { keywords: "会話キーワード" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "システム設定" },
      systemIntegration: {
        text: "システムインテグレーション",
        subTitle: {
          udesk: "Udeskカスタマーサービスシステム",
          udeskWhite: "カスタマーサービスシステム",
          wechat: "企業wechat",
          taobao: "タオバオ千牛",
          weifeng: "微豊",
        },
      },
      columnsManage: { text: "品質検査フィールド管理" },
      customerField: { text: "顧客フィールド" },
      speechRecognition: { text: "音声認識" },
      user: { text: "スタッフ管理" },
      userGroups: { text: "スタッフグループ管理" },
      role: { text: "ロール管理" },
      dataMigration: { text: "データ移行" },
      taskCenter: { text: "タスクセンター" },
      recordUpload: { text: "ローカル録音アップロード" },
      anomalousData: { text: "異常データ" },
      systemLog: { text: "メッセージログ" },
      msgCenter: { text: "メッセージセンター" },
      wechatDocking: { text: "企業のマイクロドッキング" },
    },
    tasks: {
      homePage: { text: "トップページ" },
      workbench: { text: "作業台" },
      semanticIntelligence: { text: "意味知能" },
      intelligentAnalysis: { text: "スマート分析" },
      intelligentLabel: { text: "スマートラベル" },
      admin: { text: "管理" },
      callManage: { text: "通話管理" },
      dialogueManage: { text: "会話管理" },
      ticketManage: { text: "ワークオーダー管理" },
      dataAnalysis: { text: "データ分析" },
      customerService: { text: "コールセンター" },
      clientCenter: { text: "カスタマーセンター" },
      insight: { text: "Insight" },
      trainingCenter: { text: "トレーニングセンター" },
      templates: { text: "テンプレート管理" },
      wechatQaTasks: { text: "企業微信工作台" },
      wechatQaSetting: { text: "企業微信分析配置" },
      wechatQaChartRecord: { text: "企業wechatセッション" },
      wechatStaffCenter: { text: "企業マイクロ従業員センター" },
      callAnalysis: { text: "セッション分析" },
      caseBaseFolder: { text: "ケースライブラリ" },
      coachTask: { text: "カウンセリングタスク" },
      teamAnalysis: { text: "チーム分析" },
      customer: { text: "カスタマーセンター" },
      analysisEnable: { text: "分析エンパワーメント" },
      analysisHelp: { text: "ヘルプ分析" },
      analysisStudy: { text: "学習分析" },
      analysisReview: { text: "再ディスク分析" },
      analysisTags: { text: "スマートラベル分析" },
      analysisSentiment: { text: "世論分析" },
      analysisBusiness: { text: "業務分析" },
      groupTest: { text: "グループ分けテスト" },
    },
    navBar: {
      billingCenter: "ヘルプセンター",
      uploadDownload: "アップロードダウンロードセンター",
      setting: "システム設定",
    },
  },
  fix: {
    selectType: "タイプを選択:",
    all: "すべて",
    call: "通話",
    text: "会話",
    wechat: "企業wechatセッション",
    businessTrendAnalysis: "一次分類業務トレンド分析",
    noEchart: "グラフにはデータがありません",
    checkPointisRequired: "ルール設定は必須項目です",
    voiceDownLoad: "録音をダウンロードする",
    exportTxt: "TXTテキストのエクスポート",
    exportWord: "Wordテキストのエクスポート",
    sureReRole: "話し手のロールを反転することを確認しますか?",
    sureRecover: "再誤り訂正を確認しますか?",
    sureReAsr: "再認識しますか?",
    reRole: "反転話者",
    recover: "誤り訂正をやり直す",
    reAsr: "再識別",
    recordingOperation: "録音操作",
    onlySave30Day: "(30日間のみ保持)",
    appealError: "品質検査点を訴えてから提出してください",
    exportToTaskCenter:
      "アップロードダウンロードセンターに正常にエクスポートされました",
    caseBaseLimit: "ケースライブラリは50を超えることはできません",
    unnamed: "名前なし",
    wordSpeed: "字/分",
    hit: "ヒット",
    checkPoint: "品質検査ポイント",
    realTimeAdditionTip1:
      "リアルタイム追加: データが品質検査システムに入ったときにすぐに品質検査を開始します",
    realTimeAdditionTip2:
      "定時追加: 定時にデータを自動的に追加して品質検査を行う",
    null: "なし",
    collcetedProps: "収集フィールド",
    editingNameNow: "編集中の名前があります",
    upToFourLevels: "分類最大4級",
    default: "デフォルト",
    nameCantBeNull: "名称は必須項目です",
    editingNow: "編集中の操作があります",
    nameError: "名称が間違っています",
    nameMaxLength8: "名前の最大長さは8です",
    callTime: "通話時間",
    sentimentValue: "顧客感情値",
    agentSentimentValue: "カスタマーサービスのセンチメント値",
    sentimentAnalysis: "感情分析",
    associatedData: "随行データ",
    notifyTheOwner: "グループオーナーへの通知",
    notifyTheEmployee: "従業員本人に通知する",
    notifyTheOwnerTip:
      "会話タイプがグループチャットの場合、グループオーナーに通知されます",
    customNotifyEmployee: "カスタム通知スタッフ",
    customNotifyEmployeeTip:
      "トリガー人員が従業員であれば、メッセージが所属する従業員本人に通知する",
    clickToLearnMore: "詳細についてはクリックしてください",
    repeatMarkingTip:
      "この記録は品質検査ポイントにマークされているので、重複してマークする必要はありません",
    successfulEvaluation: "評価に成功しました",
    evaluationFailed: "評価に失敗しました",
    qualityInspectionScore: "品質検査評価",
    semanticTags: "意味ラベル",
    smartTags: "スマートラベル",
    collect: "情報収集",
    appealRecord: "苦情記録",
    satisfy: "満足",
    notSatisfy: "不満",
    appealPlaceHolder: "苦情の原因を入力してください",
    giveUp: "あきらめ",
    rejectReasonPlaceHolder: "却下理由を入力してください",
    reject: "却下",
    changeRejectReason: "却下原因の修正",
    pass: "通過",
    revise: "修正",
    machineEvaluation: "機械評価",
    poor: "差",
    excellent: "はい",
    moderate: "普通",
    mustMarked: "この品質検査ポイントは必ず表示してください。",
    pleaseChoose: "選択してください",
    manualEvaluation: "人工評価",
    qualityInspectionTemplate: "適用品質検査テンプレート",
    collectionTemplate: "収集テンプレートの適用",
    keywordsWordCloud: "キーワード語雲",
    editCategoryLabel: "分類ラベルの編集",
    justCall: "通話だけを見る",
    justConversation: "会話だけを見る",
    batchOperation: "一括操作",
    customerServicesGroup: "カスタマーサービス",
    joinGroup: "グループに参加",
    removeGroup: "グループからの移動",
    enterInformationEntity: "情報本体の内容を入力してください",
    enterNotBeNull: "入力は必須項目です",
    operationGroup: "操作グループ",
    customerServiceSelected: "選択済みカスタマーサービス",
    callAndConversation: "通話/会話",
    conversation: "会話",
    system: "システム",
    customize: "カスタム",
    effective: "有効",
    invalid: "無効",
    successfulAndToTaskCenter:
      "実行に成功しました。タスクセンターで確認してください。",
    recalculationFailed: "再計算に失敗しました",
    selectRecalculatedIndicator: "再計算する指標を選択してください",
    source: "データソースを選択してください",
    placeholder: "キーワードを入力してください",
    cumulative: "積算タイプ",
    average: "平均型",
    distribution: "分布律",
    originalDataCannotEmpty: "元のデータは必須項目です",
    inspection: "品質検査",
    check: "抽出検査",
    review: "再確認",
    draftSavedSuccessfully: "下書き保存成功",
    optimization: "最適化",
    agentName: "カスタマーサービス名",
    allTasks: "すべての品質検査タスク",
    datePickerSelect: "日付を選択します",
    callSelect: "会話を選択",
    taskSelect: "タスクの選択",
    genReport: "レポートの生成",
    dataSource: "データソース",
    callsTotal: "通話総量",
    intelligentQualityInspection: "スマート品質検査",
    percentage: "比率",
    artificialSampling: "人工抽出検査",
    appealNumber: "提訴量",
    totalConversation: "会話総量",
    samplingReviewRate: "再検査率",
    data: "データ",
    inspectionResults: "品質検査結果",
    inspected: "品質検査済み",
    totalScore: "総得点",
    learnMore: "もっと見る",
    inspectionEffect: "品質検査効果",
    allTotal: "総量",
    manualInspection: "人工再検査",
    artificialReview: "手動再確認",
    approvalAmountAndRate: "承認量 & 承認率",
    customerScore: "カスタマーサービスの得点",
    inspectionRecommendations: "品質検査の提案",
    saveDraft: "下書きを保存する",
    historicalAdvice: "履歴提案",
    advicePlaceHolder: "提案を入力してください… は...",
    upload: "ファイルアップロード",
    customAdd: "フィルタを追加",
    advancedConditions: "高度な条件",
    keyWord: "キーワード",
    containsAll: "すべてを含む",
    containsAny: "任意を含む",
    matchAny: "任意にマッチさせる",
    matchAll: "すべてマッチさせる",
    smartTagsOne: "スマートラベル1",
    smartTagsTwo: "スマートラベル2",
    season: "シーズン",
    month: "月",
    week: "週",
    day: "日",
    hour: "時",
    notMoreThan366: "時間は366日を超えることはできません",
    propsCannotBeNull: "未入力のフィールドがあります",
    other: "その他",
    correlationCoefficient: "関連係数",
    repetitionRate: "繰り返し率",
    configurationItemsMoreThan20: "設定項目を最大20個追加",
    conversionAnalysis: "転化分析",
    correlationAnalysis: "関連性分析",
    successfulExportAndToTaskCenter:
      "エクスポートに成功しました。タスクセンターで確認してください。",
    typeSelect: "タイプを選択",
    exportExcel: "エクセルをエクスポート",
    exportPng: "Pngをエクスポート",
    noDataChart: "グラフにはデータがありません",
    placeOperator: "スマートラベルを選択してください",
    placeAdvancedConditions: "高度な条件を選択してください",
    createAdvancedConditions: "新規詳細フィルタ",
    moveUp: "上に移動",
    moveDown: "下に移動",
    moveLeft: "左に移動",
    moveRight: "右シフト",
    statusChangeSuccess: "ステータス修正に成功しました",
    editSuccess: "修正に成功しました",
    saveSuccess: "保存に成功しました",
    firstPage: "トップページ",
    lastPage: "最後のページ",
    customerInfo: "顧客情報",
    type: "タイプ",
    NumberTypeErrorMessage:
      "数字タイプのフィールド情報の内容には、数字または「.」のみを含めることができます",
    taskStartPrompt: "タスク開始のヒント",
    startConfirm: "確認ボタンをクリックして品質検査タスクをオンにしてください",
    continueToSubmitWithoutEvaluation: "評価を行わずに提出を継続する",
    rejectTip:
      "変更操作が検出されなかったので、すべての苦情を直接却下したことを確認していますか?",
    unmarkedSelfLearningTip:
      "マークされていない自己学習品質検査ポイントがあります",
    markedSuccessfully: "マークアップに成功しました",
    markingFailed: "マークアップに失敗しました",
    exceedingLength: "長さを超えています",
    uncategorized: "分類されていません",
    addSuccess: "新規追加に成功しました",
    deleteSuccess: "削除に成功しました",
    featureName: "要素名称",
    informationEntity: "情報エンティティ",
    standardAnswer: "標準回答",
    keyElementAnswer: "重要な要素の回答",
    generalStatement: "一般陳述 (問い合わせ不要)",
    generalQuestionsAndConfirmation: "一般的な疑問と確認",
    specialQuestionsAndAnswers: "特別な質問と回答",
    agent: "カスタマーサービス",
    customer: "お客様",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "重要要素の意味ラベルと情報エンティティを同時に空欄にすることはできません",
    standardSentence: "標準文",
    acquaintance: "知り合い度",
    smartUePlaceholder: "スマートインタラクション名を入力してください",
    typeSelectPlaceholder: "タイプを選択してください",
    keyElement: "重要な要素",
    reQuerySementicTags: "セマンティックラベルの再選択",
    querySementicTags: "意味ラベルを選択します",
    QATestForm: "Q & aテスト形式",
    selectNotBeNull: "選択は空白にできません",
    featureDetectionTarget: "要素検出ターゲット",
    categorized: "分類",
    effectiveness: "有効性",
    hideAdvancedSettings: "詳細設定を非表示にする",
    showAdvancedSettings: "詳細設定を表示",
    generalStatementOnly: "一般陳述",
    generalQuestions: "一般的な疑問",
    affirmativeExpression: "肯定表現",
    negativeIntention: "意図を否定する",
    searchSementicTags: "意味ラベルの照会",
    choiceQuestion: "選択型疑問文",
    selectiveAnswer: "選択型回答",
    sementicTagsPlaceholder: "意味ラベルの内容を入力してください",
    openSpecialQuestion: "開放型特別疑問",
    openAnswer: "オープン型回答",
    customQA: "カスタムq & a",
    questions: "質問法",
    dataPlaceholder: "内容を入力してください",
    reply: "回答",
    notFindSuitableLabel: "適切なラベルが見つかりません",
    addSemanticTag: "新しい意味ラベルを追加します",
    intelligentInteraction: "インテリジェントインタラクティブ",
    matching: "マッチ",
    bout: "次",
    keywordMatch: "キーワードマッチ",
    addFilterConditions: "フィルタ条件の追加",
    buttonStartCollectionTask:
      "「Ok」ボタンをクリックして収集タスクをオンにしてください",
    buttonStartTask:
      "「Ok」ボタンをクリックして品質検査タスクをオンにしてください",
    ruleConfigurationIsRequired: "ルール設定は必須項目です",
    asrRunning: "ASR実行",
    errorCorrectionExecution: "誤り訂正実行",
    emotionalVolumeRecognition: "感情ボリューム認識",
    dataPreprocessing: "データ前処理",
    inspectionExecution: "品質検査実行",
    businessId: "業務Id",
    businessTime: "業務時間",
    createTime: "時間の作成",
    abnormalReason: "異常の原因",
    singleChat: "チャット",
    groupChat: "グループチャット",
    taskContent: "タスク内容",
    submissionTime: "提出時間",
    startTime: "開始時間",
    endTime: "終了時間",
    runningTime: "運行に時間がかかります",
    totalAmountOfData: "総データ量",
    resultsOfThe: "実行結果",
    operator: "オペレーター",
    taskDetails: "タスクの詳細",
    executiveFunction: "実行機能",
    qualityCheckTasks: "品質検査タスク",
    implementationDetails: "実行の詳細",
    conditionalRules: "条件ルール",
    smartCondition: "スマート条件",
    goThis: "ここに移動",
    pleaseMarkClassification: "分類を記入してください",
    advancedScreening: "高度なフィルタリング",
    noDataToManipulate: "操作できるデータがありません",
    concept: "概念語",
    checkPoint1: "ルール設定",
    requiredFieldsCannotEmpty: "必須項目は必須項目です",
    rule: "ルール",
    afterFixUnit: "文字",
    unmarkedSelfLearningPointsTip:
      "マークされていない自己学習品質検査ポイントがあります",
    onlyReservedFor7Days: "(7日間のみ保持)",
    downloadError: "ダウンロードに失敗しました",
    pause: "一時停止",
    continue: "続ける",
    recalcScore: "スコア再計算のみ (評価を含む)",
    recalculatePointsAndScores: "品質検査ポイントとスコアの再計算",
    recalculatePointsAndScoresWithEmtry:
      "品質検査ポイントとスコアの再計算 (品質検査ポイントが空白)",
    inProgress: "進行中",
    toBeExecuted: "実行待ち",
    executeImmediately: "今すぐ実行する",
    specialElements: "特殊要素",
    logicRule: "ロジックルール",
    every: "複数回マッチ",
    hitRecall: "ヒットリコールワード",
    notHitRecall: "ヒットしていないリコール語",
    hitRecallRegular: "ヒットリコール正則",
    notHitRecallRegular: "正規にヒットしていません",
    hitRule: "ヒットルール",
    notHitRule: "ミスルール",
    hitSimilarSentence: "命中類似文",
    notHitSimilarSentence: "類似文に命中しない",
    hitExcludeSimilarSentences: "命中除外類似文",
    notHitExcludeSimilarSentences: "ミスマッチ排除類似文",
    updateSuccess: "更新に成功しました",
    qualify: "正則",
    recallKeyWord: "リコールキーワード",
    grammar: "文法規則",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "有効性の変更に失敗しました。再試行してください",
    postAutoMateTasksCaseByTaskId:
      "ケースライブラリ同期設定の保存に失敗しました",
    getAutoMateTasksCaseByTaskId: "ケースライブラリ同期設定取得に失敗しました",
    postInspectionRemarkModelConfigs: "備考テンプレートの保存に失敗しました",
    putInspectionRemarkModelConfigsById: "備考テンプレートの保存に失敗しました",
    getInspectionRemarkModelConfigsById:
      "備考テンプレートのデータ取得に失敗しました",
    postKnowledgeDatas:
      "ナレッジベースの新規作成に失敗しました。再試行してください。",
    putKnowledgeDatasById:
      "ナレッジベースの修正に失敗しました。再試行してください。",
    getLibraryFiles: "価格クリップリストの取得に失敗しました",
    getLibraryCalls: "ファイルの取得に失敗しました",
    getLibraryFilesSearch: "検索に失敗しました",
    putLibraryFilesMoveById: "価格クリップの移動に失敗しました",
    putLibraryCallsById: "ファイルの名前変更に失敗しました!",
    postLibraryFiles: "フォルダの追加に失敗しました",
    putLibraryFilesById: "フォルダの名前変更に失敗しました",
    deleteLibraryFilesById: "削除に失敗しました",
    deleteLibraryCallsById: "削除に失敗しました",
    postLibraryCalls: "追加に失敗しました",
    getVoiceUploadResultById: "アップロード結果のエクスポートに失敗しました",
  },
};
