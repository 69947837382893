import React from 'react';
import Udesk from '../../udesk/index';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

class CheckBox extends React.Component {
    actions = {
        containerClicked(event) {
            let {
                isStopPropagation,
            } = this.props;

            if (isStopPropagation) {
                event.stopPropagation();
            }
        },
        optionCheckChanged(option) {
            let {
                valueField,
                value,
                singleCheckBox
            } = this.props;
            if (singleCheckBox) {
                value = !value;
            } else {
                if (!(value instanceof Array)) {
                    value = [];
                }
                if (value.length === 0) {
                    value = [option[valueField]];
                } else {
                    let isAlreadySelect = value.some((item) => item === option[valueField]);
                    if (isAlreadySelect) {
                        value = value.filter((item) => {
                            return item !== option[valueField];
                        });
                    } else {
                        value = value.concat([option[valueField]]);
                    }
                }
            }
            this.props.onChanged(value);
        },
        getOptionDisabled(option) {
            let disabled = this.props.disabled;
            if (disabled) {
                return true;
            }
            let {
                disabledField
            } = this.props;
            if (option[disabledField] != null) {
                return option[disabledField];
            }
            return false;
        }
    }

    checkOptionIsSelected(option, value) {
        let {
            valueField,
            singleCheckBox
        } = this.props;
        if (value == null) {
            return false;
        }
        if (singleCheckBox) {
            if (value) {
                return true;
            }
        } else {
            if (value instanceof Array) {
                return value.some((item) => option[valueField] === item);
            }
        }
        return false;
    }

    render() {
        let {
            value,
            nameField,
            options,
            containerClassNames,
            itemClassNames,
            disabled,
            descriptionField
        } = this.props;
        return (
            <div className={ClassNames("component-udesk-react-check-box", { [`${containerClassNames}`]: containerClassNames })} onClick={this.actions.containerClicked}>
                <For each="option" index="index" of={options}>
                    <With isChecked={this.checkOptionIsSelected(option, value)}>
                        <label key={`check-box-item-${index}`} className={(itemClassNames == null ? `check-box-item` : `check-box-item ${itemClassNames}`)}>
                            <input type="checkbox" className={(!disabled ? `check-box-input` : `check-box-input input-disabled`)} disabled={this.actions.getOptionDisabled(option)} name={this.radioName} checked={isChecked} onChange={this.actions.optionCheckChanged.params(option).stopPropagation()} />
                            <i className="check-box-decorate"></i>
                            {option[nameField]}
                            <If condition={option[descriptionField] != null}>
                                <span className="check-box-item-description">
                                    {option[descriptionField]}
                                </span>
                            </If>
                        </label>
                    </With>
                </For>
            </div>
        );
    }
}

CheckBox.propTypes = {
    options: PropTypes.array,
    valueField: PropTypes.string,
    nameField: PropTypes.string,
    disabledField: PropTypes.string,
    descriptionField: PropTypes.string,
    disabled: PropTypes.bool,
    containerClassNames: PropTypes.string,
    itemClassNames: PropTypes.string,
    singleCheckBox: PropTypes.bool,
    onChanged: PropTypes.func,
    isStopPropagation: PropTypes.bool
};

CheckBox.defaultProps = {
    value: null,
    options: [],
    valueField: "id",
    nameField: "name",
    disabledField: "disabled",
    descriptionField: "description",
    disabled: false,
    containerClassNames: "",
    itemClassNames: "",
    singleCheckBox: false,
    onChanged: () => { },
    isStopPropagation: false,
};

export default Udesk.react.udeskify(CheckBox);