// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerNodeScoreRuleQuery,
    BaseResponseListIntelligentPartnerDialogNodeScoreRuleFoundResponse,
    PostReviewIntelligentPartnerNodeScoreRuleBody,
    PutReviewIntelligentPartnerNodeScoreRuleBody,
    DeleteReviewIntelligentPartnerNodeScoreRuleBody,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 根据节点配置id查询得分规则
 *
 * @export
 * @tags 智能陪练-对话阶段得分规则
 * @link [GET] /review/intelligentPartner/node/scoreRule
 */
export function getReviewIntelligentPartnerNodeScoreRule(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodeScoreRuleQuery> & {
        params: GetReviewIntelligentPartnerNodeScoreRuleQuery;
    }
): Promise<BaseResponseListIntelligentPartnerDialogNodeScoreRuleFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerNodeScoreRuleQuery>(
        '/review/intelligentPartner/node/scoreRule',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeScoreRule',
        }
    );
}

/**
 * 保存得分规则
 *
 * @export
 * @tags 智能陪练-对话阶段得分规则
 * @link [POST] /review/intelligentPartner/node/scoreRule
 */
export function postReviewIntelligentPartnerNodeScoreRule(
    data: PostReviewIntelligentPartnerNodeScoreRuleBody
): Promise<BaseResponseListIntelligentPartnerDialogNodeScoreRuleFoundResponse>;
export function postReviewIntelligentPartnerNodeScoreRule(
    data: PostReviewIntelligentPartnerNodeScoreRuleBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogNodeScoreRuleFoundResponse>;
export function postReviewIntelligentPartnerNodeScoreRule(
    data: PostReviewIntelligentPartnerNodeScoreRuleBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogNodeScoreRuleFoundResponse> {
    return request<PostReviewIntelligentPartnerNodeScoreRuleBody, never, never>(
        '/review/intelligentPartner/node/scoreRule',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeScoreRule',
        }
    );
}

/**
 * 批量修改得分规则
 *
 * @export
 * @tags 智能陪练-对话阶段得分规则
 * @link [PUT] /review/intelligentPartner/node/scoreRule
 */
export function putReviewIntelligentPartnerNodeScoreRule(
    data: PutReviewIntelligentPartnerNodeScoreRuleBody
): Promise<BaseResponseListIntelligentPartnerDialogNodeScoreRuleFoundResponse>;
export function putReviewIntelligentPartnerNodeScoreRule(
    data: PutReviewIntelligentPartnerNodeScoreRuleBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogNodeScoreRuleFoundResponse>;
export function putReviewIntelligentPartnerNodeScoreRule(
    data: PutReviewIntelligentPartnerNodeScoreRuleBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogNodeScoreRuleFoundResponse> {
    return request<PutReviewIntelligentPartnerNodeScoreRuleBody, never, never>(
        '/review/intelligentPartner/node/scoreRule',
        {
            ...options,
            data,
            method: 'put',
            __$requestCalleeName: 'putReviewIntelligentPartnerNodeScoreRule',
        }
    );
}

/**
 * 批量删除得分规则
 *
 * @export
 * @tags 智能陪练-对话阶段得分规则
 * @link [DELETE] /review/intelligentPartner/node/scoreRule
 */
export function deleteReviewIntelligentPartnerNodeScoreRule(
    data: DeleteReviewIntelligentPartnerNodeScoreRuleBody
): Promise<BaseResponseVoid>;
export function deleteReviewIntelligentPartnerNodeScoreRule(
    data: DeleteReviewIntelligentPartnerNodeScoreRuleBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function deleteReviewIntelligentPartnerNodeScoreRule(
    data: DeleteReviewIntelligentPartnerNodeScoreRuleBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<DeleteReviewIntelligentPartnerNodeScoreRuleBody, never, never>(
        '/review/intelligentPartner/node/scoreRule',
        {
            ...options,
            data,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerNodeScoreRule',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话阶段得分规则'],
        path: '/review/intelligentPartner/node/scoreRule',
        method: 'get',
    },
    {
        tags: ['智能陪练-对话阶段得分规则'],
        path: '/review/intelligentPartner/node/scoreRule',
        method: 'post',
    },
    {
        tags: ['智能陪练-对话阶段得分规则'],
        path: '/review/intelligentPartner/node/scoreRule',
        method: 'put',
    },
    {
        tags: ['智能陪练-对话阶段得分规则'],
        path: '/review/intelligentPartner/node/scoreRule',
        method: 'delete',
    },
];
