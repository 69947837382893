// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostReviewCallTestsTestBody,
    BaseResponseDebugModeTagMatchResult,
} from '../../../types';

/**
 * 开始测试
 *
 * @export
 * @tags 销售赋能规则测试
 * @link [POST] /review/callTests/test
 */
export function postReviewCallTestsTest(
    data: PostReviewCallTestsTestBody
): Promise<BaseResponseDebugModeTagMatchResult>;
export function postReviewCallTestsTest(
    data: PostReviewCallTestsTestBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseDebugModeTagMatchResult>;
export function postReviewCallTestsTest(
    data: PostReviewCallTestsTestBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseDebugModeTagMatchResult> {
    return request<PostReviewCallTestsTestBody, never, never>('/review/callTests/test', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewCallTestsTest',
    });
}

export const meta = [
    { tags: ['销售赋能规则测试'], path: '/review/callTests/test', method: 'post' },
];
