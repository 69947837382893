import React, { useEffect, useState } from 'react';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';
import styled from 'styled-components';
import { Text } from './Text';
import { inspect, isNumber } from 'src/util/core';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import { ScoreTitle } from '../ScoreTitle';
import { getIntelligentPartnerScoringPoints } from 'src/api/intelligentPartner/scoring/points';

const Title = styled.div`
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    margin-top: 24px;

    > div {
        display: flex;
    }

    .over-view-title {
        margin-right: 12px;
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
    }
    .over-view-title-total {
        color: rgba(0, 0, 0, 0.45);
    }

    .over-view-title-grade {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;

        > span:nth-child(1) {
            > span {
                color: #1a6eff;
                margin: 0 4px;
            }
        }
        > span:nth-child(2) {
            color: rgba(0, 0, 0, 0.45);
            margin-left: 6px;
        }
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;

    .over-view-content-detail {
        flex: 1;
        margin-right: 16px;

        > div {
            padding: 12px;
            background: #f5f6f8;
            border-radius: 4px;
            word-break: keep-all;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;

            + div {
                margin-top: 12px;
            }
        }

        .over-view-content-detail-title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;

            .score {
                color: #555;

                > span:nth-child(1) {
                    font-size: 18px;
                    color: #1a6eff;
                }
            }
        }
    }
`;

export const OverView = React.memo((props: any) => {
    const { modelRuleList, rank, total, scoreOverView } = props;
    const {
        speechCraftScore = {},
        emotionScore = {},
        speedScore = {},
        flowScore = {},
    } = scoreOverView || {};

    const [idTypeMap, setIdTypeMap] = useState<{ [key: string]: number } | {}>({});

    useEffect(() => {
        getIntelligentPartnerScoringPoints().then((resp) => {
            console.log('1', resp.data);
            setIdTypeMap(
                resp.data?.reduce((p, c) => {
                    p[c.pointType!] = c.pointType; // 这里先改成type：type
                    return p;
                }, {}) ?? {}
            );
        });
    }, []);

    if (!modelRuleList) return null;

    const showMap = modelRuleList.reduce((p, c) => {
        p[c.dimension] = true;
        return p;
    }, {});
    return (
        <div className="over-view">
            <Title>
                <div>
                    <div className="over-view-title">
                        {/* 评分总览 */}
                        {
                            UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                .components.overView.index.ratingOverview
                        }
                    </div>
                    <div className="over-view-title-total">{`${
                        /* 总分 */ UdeskLocales['current'].pages.coach.learningCenter.record.detail
                            .components.overView.index.totalScore
                    }${scoreOverView?.totalScore ?? '-'}`}</div>
                </div>
                {isNumber(total, () => (
                    <div className="over-view-title-grade">
                        <span>
                            {/* 第 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .components.overView.index.section
                            }
                            <span>{rank}</span>
                            {/* 名 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .components.overView.index.name
                            }
                        </span>
                        <span>
                            {/* (共 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .components.overView.index.of
                            }
                            {total}
                            {/* 人) */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .components.overView.index.people
                            }
                        </span>
                    </div>
                ))}
            </Title>
            <Content>
                <div className="over-view-content-detail">
                    {inspect(showMap[Udesk.enums.dimension.flow.id], () => {
                        const { totalFlows, finishFlows } = flowScore || {};
                        return (
                            <div>
                                <ScoreTitle title={/* 流程得分 */UdeskLocales['current'].components.coach.score.overview.index.processScore} {...flowScore} />
                                <Text>
                                    {[
                                        /* 内容完整性： */UdeskLocales['current'].components.coach.score.overview.index.contentIntegrity,
                                        {
                                            children: get(
                                                isNaN(finishFlows / totalFlows)
                                                    ? 0
                                                    : (finishFlows / totalFlows) * 100
                                            ),
                                            className: 'font-black',
                                        },
                                        /* %，完成流程数 */UdeskLocales['current'].components.coach.score.overview.index.NumberOfCompletedProcesses,
                                        {
                                            children: `${get(finishFlows)} / ${get(totalFlows)}`,
                                            className: 'font-black',
                                        },
                                    ]}
                                </Text>
                            </div>
                        );
                    })}
                    {inspect(showMap[Udesk.enums.dimension.word.id], () => {
                        const {
                            totalMainPoints,
                            notCommitMainPoints,
                            violations,
                            totalWordNum,
                            commitWordNum,
                        } = speechCraftScore || {};
                        return (
                            <div>
                                <ScoreTitle
                                    title={
                                        /* 话术得分 */ UdeskLocales['current'].pages.coach
                                            .learningCenter.record.detail.components.overView.index
                                            .scriptScore
                                    }
                                    {...speechCraftScore}
                                />
                                <Text>
                                    {[
                                        {
                                            pointType: [2, 3],
                                            component: () => (
                                                <div>
                                                    <Text>
                                                        {[
                                                            /* 要点命中：共 */UdeskLocales['current'].components.coach.score.overview.index.keyHitPointsTotal,
                                                            {
                                                                children: get(totalMainPoints),
                                                                className: 'font-black',
                                                            },
                                                            /* 个要点， */ UdeskLocales['current']
                                                                .pages.coach.learningCenter.record
                                                                .detail.components.overView.index
                                                                .keyPoints,
                                                            {
                                                                children: get(notCommitMainPoints),
                                                                className: 'font-red',
                                                            },
                                                            /* 个未达到 */UdeskLocales['current'].components.coach.score.overview.index.notReached,
                                                        ]}
                                                    </Text>
                                                </div>
                                            ),
                                        },
                                        {
                                            pointType: 4,
                                            component: () => (
                                                <div>
                                                    <Text>
                                                        {[
                                                            /* 跟读准确：标准话术累积 */UdeskLocales['current'].components.coach.score.overview.index.accurateFollowupAccumulationOfStandardScripts,
                                                            {
                                                                children: get(totalWordNum),
                                                                className: 'font-black',
                                                            },
                                                            /* 个字，学员跟读命中的字数共 */UdeskLocales['current'].components.coach.score.overview.index.theTotalNumberOfWordsHitByTheStudentAfterReading,
                                                            {
                                                                children: get(commitWordNum),
                                                                className: 'font-black',
                                                            },
                                                            /* 个字 */UdeskLocales['current'].components.coach.score.overview.index.aWord,
                                                        ]}
                                                    </Text>
                                                </div>
                                            ),
                                        },
                                        {
                                            pointType: 5,
                                            component: () => (
                                                <div>
                                                    <Text>
                                                        {[
                                                            /* 语言规范：有 */UdeskLocales['current'].components.coach.score.overview.index.languageNormsYes,
                                                            {
                                                                children: get(violations),
                                                                className: 'font-red',
                                                            },
                                                            /* 处违规 */ UdeskLocales['current']
                                                                .pages.coach.learningCenter.record
                                                                .detail.components.overView.index
                                                                .violationOfRegulations,
                                                        ]}
                                                    </Text>
                                                </div>
                                            ),
                                        },
                                    ].filter((i) =>
                                        modelRuleList.find((m) =>
                                            Array.isArray(i.pointType)
                                                ? i.pointType.includes(idTypeMap[m.pointType])
                                                : idTypeMap[m.pointType] === i.pointType
                                        )
                                    )}
                                </Text>
                            </div>
                        );
                    })}
                    {inspect(showMap[Udesk.enums.dimension.emotion.id], () => {
                        const { negativeEmotions } = emotionScore || {};
                        return (
                            <div>
                                <ScoreTitle
                                    title={
                                        /* 情绪得分 */ UdeskLocales['current'].pages.coach
                                            .learningCenter.record.detail.components.overView.index
                                            .emotionalScore
                                    }
                                    {...emotionScore}
                                />
                                <Text>
                                    {[
                                        /* 情绪：出现 */UdeskLocales['current'].components.coach.score.overview.index.emotionsAppearing,
                                        {
                                            children: get(negativeEmotions),
                                            className: 'font-red',
                                        },
                                        /* 次负面情绪 */ UdeskLocales['current'].pages.coach
                                            .learningCenter.record.detail.components.overView.index
                                            .secondaryNegativeEmotions,
                                    ]}
                                </Text>
                            </div>
                        );
                    })}
                    {inspect(showMap[Udesk.enums.dimension.deliver.id], () => {
                        const {
                            speedFastTimes,
                            speedSlowTimes,
                            averageSpeed,
                            modalParticleTooMuchTimes,
                        } = speedScore || {};
                        return (
                            <div>
                                <ScoreTitle
                                    title={
                                        /* 表达得分 */ UdeskLocales['current'].pages.coach
                                            .learningCenter.record.detail.components.overView.index
                                            .expressionScore
                                    }
                                    {...speedScore}
                                />
                                <Text>
                                    {[
                                        {
                                            pointType: 6,
                                            component: () => (
                                                <div>
                                                    <Text>
                                                        {[
                                                            /* 语速：语速过快 */UdeskLocales['current'].components.coach.score.overview.index.speechSpeedSpeakingTooFast,
                                                            {
                                                                children: get(speedFastTimes),
                                                                className: 'font-black',
                                                            },
                                                            /* 次， */ UdeskLocales['current'].pages
                                                                .coach.learningCenter.record.detail
                                                                .components.overView.index.times,
                                                            /* 语速过慢 */ UdeskLocales['current']
                                                                .pages.coach.learningCenter.record
                                                                .detail.components.overView.index
                                                                .speakTooSlowly,
                                                            {
                                                                children: get(speedSlowTimes),
                                                                className: 'font-red',
                                                            },
                                                            /* 次 */ UdeskLocales['current'].pages
                                                                .coach.learningCenter.record.detail
                                                                .components.overView.index.second,
                                                            '，',
                                                            /* 平均 */ UdeskLocales['current'].pages
                                                                .coach.learningCenter.record.detail
                                                                .components.overView.index.average,
                                                            {
                                                                children: get(averageSpeed),
                                                                className: 'font-black',
                                                            },
                                                            /* 字/分钟 */ UdeskLocales['current']
                                                                .pages.coach.learningCenter.record
                                                                .detail.components.overView.index
                                                                .wordminute,
                                                        ]}
                                                    </Text>
                                                </div>
                                            ),
                                        },
                                        {
                                            pointType: 7,
                                            component: () => (
                                                <div>
                                                    <Text>
                                                        {[
                                                            /* 语气词：语气词过多出现 */UdeskLocales['current'].components.coach.score.overview.index.modalParticlesExcessiveOccurrenceOfModalParticles,
                                                            {
                                                                children:
                                                                    get(modalParticleTooMuchTimes),
                                                                className:
                                                                    modalParticleTooMuchTimes || 0
                                                                        ? 'font-red'
                                                                        : '',
                                                            },
                                                            /* 次 */ UdeskLocales['current'].pages
                                                                .coach.learningCenter.record.detail
                                                                .components.overView.index.second,
                                                        ]}
                                                    </Text>
                                                </div>
                                            ),
                                        },
                                    ].filter((i) =>
                                        modelRuleList.find((m) => m.pointType === i.pointType)
                                    )}
                                </Text>
                            </div>
                        );
                    })}
                </div>
            </Content>
        </div>
    );
});

function get(value: number) {
    return typeof value === 'number' ? value.toFixed(0) : '-';
}
