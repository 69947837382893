// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListUserFoundResponse } from '../../types';

/**
 * findAll
 *
 * @export
 * @tags 用户
 * @link [GET] /users/findAll
 */
export function getUsersFindAll(): Promise<BaseResponseListUserFoundResponse>;
export function getUsersFindAll(
    options: ApiOptions<never, never>
): Promise<BaseResponseListUserFoundResponse>;
export function getUsersFindAll(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListUserFoundResponse> {
    return request<never, never, never>('/users/findAll', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getUsersFindAll',
    });
}

export const meta = [{ tags: ['用户'], path: '/users/findAll', method: 'get' }];
