import React, { useMemo } from 'react';
import { Modal } from 'udesk-ui';
import { useFlowModalProvider } from './Context';
import ReactFlow, { useNodesState, useEdgesState } from 'reactflow';
import 'reactflow/dist/style.css';
import { useDagreGraph } from 'src/pages/tasks/manage/template/check-point/detail/components/Flow/hooks/useDagreGraph';
import { nodeTypes } from './Nodes';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

export { useFlowNodeListModal } from './useFlowNodeListModal';

// const judgeNodeBorder = '2px solid #1a6eff';
// const dialogNodeBorder = '2px solid #f3aa18';
const dialogNodeBackground = 'linear-gradient(90deg, #f3aa18 0.01%, rgba(242, 170, 24, 0.45) 100%)';
const judgeNodeBackground = 'linear-gradient(90deg, #1a6eff 0%, rgba(26, 110, 255, 0.45) 100%)';

const Wrap = styled.div`
    height: 500px;
    .react-flow__attribution {
        display: none;
    }
`;

const FlowNodeListModal = React.memo((props: any) => {
    const { ...modalProps } = props;
    const { getLayoutedElements } = useDagreGraph();

    const {
        canvasNodeMatchResultList,
        showNodeIndexMap,
        onNodeChange,
        // nodeBranchesMap,
        indexNodeMap,
    } = useFlowModalProvider();
    const defaultEdges = useMemo(() => {
        return (
            canvasNodeMatchResultList?.reduce((res, node) => {
                if (Array.isArray(node.nextIndexList)) {
                    node.nextIndexList.forEach((next) => {
                        res.push({
                            id: `e${node.nodeIndex}-${next}`,
                            source: `${node.nodeIndex}`,
                            target: `${next}`,
                            type: 'smoothstep',
                        });
                    });
                }
                return res;
            }, []) ?? []
        );
    }, [canvasNodeMatchResultList]);
    const defaultNodes = useMemo(() => {
        const nodeTypeMap = canvasNodeMatchResultList.reduce((res, c) => {
            res[c.nodeIndex] = c.nodeType;
            return res;
        }, {});

        const getNodeColor = (nodeType, preIndex) => {
            if (
                nodeType === 'DIALOG_NODE' ||
                (nodeType === 'BRANCH' && nodeTypeMap[preIndex] === 'DIALOG_NODE')
            ) {
                return dialogNodeBackground;
            }
            if (
                nodeType === 'JUDGE_NODE' ||
                (nodeType === 'BRANCH' && nodeTypeMap[preIndex] === 'JUDGE_NODE')
            ) {
                return judgeNodeBackground;
            }
            return 'none';
        };
        const nodes =
            canvasNodeMatchResultList?.map((node) => {
                const {
                    nodeType,
                    preIndexList: [preIndex],
                } = node;
                return {
                    id: `${node.nodeIndex}`,
                    data: {
                        // 这里只传不会变的属性
                        name: node.name,
                        color: getNodeColor(nodeType, preIndex),
                        nodeType: node.nodeType,
                        preIndexList: node.preIndexList,
                        nodeId: node.nodeId,
                    },
                    position: { x: 250, y: 0 },
                    type: 'cusNode',
                };
            }) ?? [];
        const { nodes: layoutedNodes } = getLayoutedElements(nodes, defaultEdges);

        return layoutedNodes;
    }, [canvasNodeMatchResultList, defaultEdges]);

    const [nodes, , onNodesChange] = useNodesState(defaultNodes);
    const [edges, , onEdgesChange] = useEdgesState(defaultEdges);

    const onNodeClick = (node) => {
        const nodeIndex = node.id * 1;
        const decidedNodesList = new Set<any>(); // 所有确定状态的节点的合集，包括点击节点和它上游的节点们
        const newCanvasNodeMatchResultList = canvasNodeMatchResultList.map((undecidedNode) => {
            if (undecidedNode.nodeIndex === nodeIndex) {
                // 改变点击节点的isHit状态
                const decidedNode = { ...undecidedNode, isHit: !undecidedNode.isHit };
                // 收集点击节点到decidedNodesList
                decidedNodesList.add(decidedNode);

                // 收集点击节点的上游的节点到decidedNodesList
                const stack = [].concat(decidedNode.preIndexList);
                while (stack.length) {
                    const current = stack.pop();
                    const prevNode = indexNodeMap[current!];
                    if (prevNode) {
                        decidedNodesList.add(prevNode);
                        Array.prototype.push.apply(stack, prevNode.preIndexList);
                    }
                }
                return decidedNode;
            }

            return undecidedNode;
        });

        // 重置所有下游节点和兄弟节点
        newCanvasNodeMatchResultList.forEach((node) => {
            if (!decidedNodesList.has(node)) {
                node.isHit = false;
            }
        });

        onNodeChange(newCanvasNodeMatchResultList);
    };

    console.log(nodes, edges);

    return (
        <Modal
            {...modalProps}
            title={
                /* 完整流程 */ UdeskLocales['current'].components.qualityScore.components
                    .flowNodeListModal.index.completeProcess
            }
            width={1000}
        >
            <Wrap>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    snapToGrid
                    fitView
                    attributionPosition="top-right"
                    nodeTypes={nodeTypes}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    nodesFocusable={false}
                    edgesFocusable={false}
                    onNodeClick={(e, node) => {
                        if (!showNodeIndexMap[node.id]) return;

                        onNodeClick(node);
                    }}
                ></ReactFlow>
            </Wrap>
        </Modal>
    );
});

export { FlowNodeListModal };
