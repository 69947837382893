// demo
import DemoIndex from '../../pages/demo/index';

// 质检申诉模块
// import AppealCheckingTaskParent from 'Component/pages/appeal-checking-task';
// import AppealCheckingTask from 'Component/pages/appeal-checking-task/sdk/index.tsx';

// 主界面
import Home from '../../pages/home/index';

// 质检评级
import GradeIndex from 'Component/pages/grade-task/index';
import GradeCreateIndex from 'Component/pages/grade-task/index';

// 质检首页概览
import Dashboard from '../../pages/dashboard/index';
// 质检工作台

import TasksIndex from '../../pages/tasks/index';

import ApproveTaskEntry from '../../pages/tasks/approve';
import ApproveTasksCheckPointIndex from '../../pages/tasks/approve/check-point';
import ApproveTasksCheckPointView from '../../pages/tasks/approve/check-point/detail';

// 质检管理
import TasksManageEntry from '../../pages/tasks/manage/tasks-manage-entry';
import TasksManageCreateEntry from '../../pages/tasks/manage/tasks-manage-create-entry';
import InformationCollectionEntry from '../../pages/tasks/manage/information-collection-entry';
import InformationCollectionCreateEntry from '../../pages/tasks/manage/information-collection-create-entry';
// 质检基本信息
import TasksBasicInfoIndex from '../../pages/tasks/manage/template/basic-info/index';
import TasksBasicInfoCreateIndex from '../../pages/tasks/manage/template/basic-info/index';
import InformationCollectionBasicInfoIndex from '../../pages/tasks/manage/template/basic-info/index';
import InformationCollectionBasicInfoCreateIndex from '../../pages/tasks/manage/template/basic-info/index';

// 质检条件
import TasksConditionEntry from '../../pages/tasks/manage/template/condition-entry';
import TasksConditionIndex from '../../pages/tasks/manage/template/condition/index';
import TasksConditionNew from '../../pages/tasks/manage/template/condition/new/index';
import TasksConditionEdit from '../../pages/tasks/manage/template/condition/edit/index';
// 质检点
import TasksCheckPointEntry from '../../pages/tasks/manage/template/check-point-entry';
import TasksCheckPointIndex from '../../pages/tasks/manage/template/check-point/index';
import TasksCheckPointNew from '../../pages/tasks/manage/template/check-point/new/index';
import TasksCheckPointEdit from '../../pages/tasks/manage/template/check-point/edit/index';

import TasksCheckPointCreateEntry from '../../pages/tasks/manage/template/check-point-create-entry';
import TasksCheckPointCreateIndex from '../../pages/tasks/manage/template/check-point/index';
import TasksCheckPointCreateNew from '../../pages/tasks/manage/template/check-point/new/index';
import TasksCheckPointCreateEdit from '../../pages/tasks/manage/template/check-point/edit/index';
// 规则测试
import TasksCheckPointTestIndex from 'Component/pages/check-point-test';

//采集规则
import InformationCollectPointCreateIndex from 'Component/pages/data-collection/collect-point/index';
import InformationCollectPointIndex from 'Component/pages/data-collection/collect-point/index';

//数据添加
import InformationCollectDataAddIndex from 'Component/pages/data-collection/data-add/index';

// 质检流程自动化
import TasksAutomaticAddIndex from '../../pages/tasks/manage/template/automatic-add/index';
// 质检手动添加
import TasksManualAddIndex from '../../pages/tasks/manage/template/manual-add/index';
// 质检角色配置
import TasksRoleConfigIndex from '../../pages/tasks/manage/template/role-config/index';

// 质检列表
import QualityInspectionList from 'Component/pages/quality-inspection-list';
import QualityInspectionListIndex from 'Component/pages/quality-inspection-list/index';
import QualityInspectionListDetail from 'Component/pages/quality-inspection-list/detail';
import InformationCollectList from 'Component/pages/quality-inspection-list';
import InformationCollectListIndex from 'Component/pages/quality-inspection-list/index';
import InformationCollectListDetail from 'Component/pages/quality-inspection-list/detail';

// 抽检功能
import SpotCheckList from 'Component/pages/spot-check-list';
import SpotCheckListIndex from 'Component/pages/spot-check-list/index';
import SpotCheckListDetail from 'Component/pages/spot-check-list/detail';

// 复核功能
import ReviewList from 'Component/pages/review-list';
import ReviewListIndex from 'Component/pages/review-list/index';
import ReviewListDetail from 'Component/pages/review-list/detail';
//案例库
import CaseBase from 'Component/pages/case-base';
import CaseBaseIndex from 'Component/pages/case-base/index';
import CaseBaseDetail from 'Component/pages/quality-inspection-list/detail';
// 质检测试集功能
import TestSetList from 'Component/pages/test-set-list';
import TestSetListIndex from 'Component/pages/test-set-list/index';
import TestSetListDetail from 'Component/pages/quality-inspection-list/detail';
// import ReviewListIndex from "Component/pages/review-list/index";

// 质检报表
import TasksWholeReport from '../../pages/tasks/manage/reports/whole-report/index';
import TasksAgentReport from '../../pages/tasks/manage/reports/agent-report/index';
import GroupChatReport from '../../pages/tasks/manage/reports/group-chat-analysis/index';
import AnalysisOverview from '../../pages/tasks/manage/reports/analysis-overview/index';
import InspectionItemsAnalysis from '../../pages/tasks/manage/reports/inspection-items-analysis/index';
import CustomerServiceExecution from '../../pages/tasks/manage/reports/customer-service-execution/index';

// 语义智能-1、智能词库
import SmartWordLibrary from 'Component/pages/semantic-intelligence/smart-words-library';
import AttentionWords from 'Component/pages/semantic-intelligence/smart-words-library/attention-words';
import IgnoreWords from 'Component/pages/semantic-intelligence/smart-words-library/ignore-words';
import ProfessionalWords from 'Component/pages/semantic-intelligence/smart-words-library/professional-words';
import SynonymlWords from 'Component/pages/semantic-intelligence/smart-words-library/synonym-words';
import SensitiveWords from 'Component/pages/semantic-intelligence/smart-words-library/sensitive-words';
import WordsWeight from 'Component/pages/semantic-intelligence/smart-words-library/words-weight';
import CorpusData from 'Component/pages/semantic-intelligence/smart-words-library/corpus-data';
import TypoLibrary from 'Component/pages/semantic-intelligence/smart-words-library/typo-library';

// 语义智能-2、语义标签
import SemanticIntelligence from 'Component/pages/semantic-intelligence';
import SemanticTags from 'Component/pages/semantic-intelligence/semantic-tags';
import SemanticTagsIndex from 'Component/pages/semantic-intelligence/semantic-tags/index';
import SemanticTagsDetail from 'Component/pages/semantic-intelligence/semantic-tags/detail';

// 语义智能-3、信息实体
import InformationEntities from 'Component/pages/semantic-intelligence/information-entities';
import InformationEntitiesIndex from 'Component/pages/semantic-intelligence/information-entities/index';
import InformationEntitiesDetail from 'Component/pages/semantic-intelligence/information-entities/detail';

// 语义智能-4、交互识别
import InteractiveRecognition from 'Component/pages/semantic-intelligence/interactive-recognition';
import InteractiveRecognitionIndex from 'Component/pages/semantic-intelligence/interactive-recognition/index';
import InteractiveRecognitionDetail from 'Component/pages/semantic-intelligence/interactive-recognition/detail';

// 知识库
import KnowledgeBase from 'Component/pages/semantic-intelligence/knowledge-base';
import KnowledgeBaseIndex from 'Component/pages/semantic-intelligence/knowledge-base/index';
import KnowledgeBaseDetail from 'Component/pages/semantic-intelligence/knowledge-base/detail';

// 智能模型
import IntelligentModel from 'Component/pages/semantic-intelligence/intelligent-model';
import IntelligentModelIndex from 'Component/pages/semantic-intelligence/intelligent-model/index';
import IntelligentModelDetail from 'Component/pages/semantic-intelligence/intelligent-model/detail';

// 应用消息类型
import ApplicationMessage from 'Component/pages/semantic-intelligence/application-message';
import ApplicationMessageIndex from 'Component/pages/semantic-intelligence/application-message/index';
import ApplicationMessageDetail from 'Component/pages/semantic-intelligence/application-message/detail';

//语义智能-6、概念词
import ConceptWord from 'Component/pages/semantic-intelligence/concept-word';
import ConceptWordIndex from 'Component/pages/semantic-intelligence/concept-word/index';
import ConceptWordDetail from 'Component/pages/semantic-intelligence/concept-word/detail';

// 智能分析-智能标签
import IntelligentAnalysis from 'Component/pages/intelligent-analysis';
import IntelligentTags from 'Component/pages/intelligent-analysis/intelligent-tags';
import SmartConversationIntelligentTags from 'Component/pages/intelligent-analysis/smart-conversation-intelligent-tags';
import IntelligentTagsIndex from 'Component/pages/intelligent-analysis/intelligent-tags/index';
import IntelligentTagsDetail from 'Component/pages/intelligent-analysis/intelligent-tags/detail';
import WechatIntelligentTags from 'Component/pages/intelligent-analysis/wechat-intelligent-tags/index.tsx';
import SessionLabel from 'Component/pages/intelligent-analysis/smart-conversation-intelligent-tags';
import KeyEvents from 'Component/pages/intelligent-analysis/smart-conversation-intelligent-tags';
import CustomerLabel from 'Component/pages/intelligent-analysis/smart-conversation-intelligent-tags';
import IgnoreLabel from 'Component/pages/intelligent-analysis/ignore-label';

import SupervisionEnter from 'Component/pages/intelligent-analysis/supervision-enter';
import SupervisionList from 'Component/pages/intelligent-analysis/supervision/index';
import SupervisionDetail from 'Component/pages/intelligent-analysis/supervision/detail/index';
import SupervisionCreate from 'Component/pages/intelligent-analysis/supervision/detail/index';
import SessionLabelTagsIndex from 'Component/pages/intelligent-analysis/intelligent-tags/index';
import SessionLabelTagsDetail from 'Component/pages/intelligent-analysis/intelligent-tags/detail';
import CustomerLabelTagsIndex from 'Component/pages/intelligent-analysis/intelligent-tags/index';
import CustomerLabelTagsDetail from 'Component/pages/intelligent-analysis/intelligent-tags/detail';
import KeyEventsTagsIndex from 'Component/pages/intelligent-analysis/intelligent-tags/index';
import KeyEventsTagsDetail from 'Component/pages/intelligent-analysis/intelligent-tags/detail';

import CustomerPortrait from 'Component/pages/intelligent-analysis/customer-portrait';
import CustomerPortraitIndex from 'Component/pages/intelligent-analysis/customer-portrait/index';
//舆情发现
import SentimentAnalysis from 'Component/pages/intelligent-analysis/sentiment-analysis';
import SentimentAnalysisIndex from 'Component/pages/intelligent-analysis/sentiment-analysis/index';
//通对话关联
import ConnectedByConversation from 'Component/pages/intelligent-analysis/connected-by-conversation';
import ConnectedByConversationIndex from 'Component/pages/intelligent-analysis/connected-by-conversation/index';
//业务分析
import BusinessAnalysis from 'Component/pages/intelligent-analysis/business-analysis.js';
import BusinessAnalysisCreate from 'Component/pages/intelligent-analysis/business-analysis/detail/index';
import BusinessAnalysisList from 'Component/pages/intelligent-analysis/business-analysis/index.tsx';
import BusinessAnalysisDetail from 'Component/pages/intelligent-analysis/business-analysis/detail/index';
// 分析报表
import AnalysisReport from 'Component/pages/intelligent-analysis/analysis-report';
//数据预处理
import DataPreprocess from 'Component/pages/intelligent-analysis/data-preprocess';
import DataPreprocessIndex from 'Component/pages/intelligent-analysis/data-preprocess/index';
import DataPreprocessDetail from 'Component/pages/intelligent-analysis/data-preprocess/detail';

//分类训练
import ClassifiedTraining from 'Component/pages/training-center/classified-training';
import ClassifiedTrainingIndex from 'Component/pages/training-center/classified-training/index';
import ClassifiedTrainingDetail from 'Component/pages/training-center/classified-training/detail/index';
// 通话管理
import CallManage from 'Component/pages/call-manage';
import CallManageIndex from 'Component/pages/call-manage/index';
import CallManageView from 'Component/pages/call-manage/detail';

// 对话管理
import DialogueManage from 'Component/pages/dialogue-manage';
import DialogueManageIndex from 'Component/pages/dialogue-manage/index';
import DialogueManageView from 'Component/pages/dialogue-manage/detail';

// 工单管理
import TicketManage from 'Component/pages/ticket-manage';
import TicketManageIndex from 'Component/pages/ticket-manage/index';
import TicketManageView from 'Component/pages/ticket-manage/detail';

// 对话管理
import ECommerceManage from 'Component/pages/e-commerce-manage';
import ECommerceManageIndex from 'Component/pages/e-commerce-manage/index';
import ECommerceManageView from 'Component/pages/e-commerce-manage/detail';

// 系统管理
import AdminEntry from '../../pages/admin-entry';
// 系统设置
import AdminSystemIndex from '../../pages/admin/system/index';
import SessionSetings from 'Component/pages/wechat-qa/setting/conversation-setting/index';

// 用户管理
import AdminUserEntry from '../../pages/admin/user-entry';
import AdminUserIndex from '../../pages/admin/user/index';
import AdminUserView from '../../pages/admin/user/view/index';
import AdminUserNew from '../../pages/admin/user/new/index';
import AdminUserEdit from '../../pages/admin/user/edit/index';
// 用户组管理
import AdminUserGroupsEntry from '../../pages/admin/user-groups-entry';
import AdminUserGroupsIndex from '../../pages/admin/user-groups/index';
import AdminUserGroupsView from '../../pages/admin/user-groups/view/index';
import AdminUserGroupsNew from '../../pages/admin/user-groups/new/index';
import AdminUserGroupsEdit from '../../pages/admin/user-groups/edit/index';
// 语音识别
import SpeechRecognitionIndex from 'Component/pages/speech-recognition/index';
//消息日志
import SystemLogIndex from 'Component/pages/sys-log/index';

// 角色管理
import Role from 'Component/pages/role';
import RoleIndex from 'Component/pages/role/index';
import RoleDetail from 'Component/pages/role/detail';
import RoleDetailBasicInfo from 'Component/pages/role/detail/basic-info';
import RoleDetailTaskPermissions from 'Component/pages/role/detail/task-permissions';
import RoleDetailDataPermissions from 'Component/pages/role/detail/data-permissions';

// 数据迁移
import DataMigrationIndex from 'Component/pages/data-migration/index';

// 数据分析
import DataAnalysis from 'Component/pages/data-analysis';
import BusinessStatistics from 'Component/pages/data-analysis/business-statistics';
import BusinessStatisticsIndex from 'Component/pages/data-analysis/business-statistics/index';
import QualityEffect from 'Component/pages/data-analysis/quality-effect';
import QualityEffectIndex from 'Component/pages/data-analysis/quality-effect/index';
import QualityAnalysis from 'Component/pages/data-analysis/quality-analysis';
import QualityAnalysisIndex from 'Component/pages/data-analysis/quality-analysis/index';
import GeneralInspectionReport from 'Component/pages/data-analysis/general-inspection-report';
import GeneralInspectionReportIndex from 'Component/pages/data-analysis/general-inspection-report/index';
import FullAmountWork from 'Component/pages/data-analysis/full-amount-work';
import FullAmountWorkIndex from 'Component/pages/data-analysis/full-amount-work/index';
import IntelligentTagsAnalysis from 'Component/pages/data-analysis/intelligent-tags-analysis/index';

//任务中心
import TaskCenter from 'Component/pages/task-center';
import TaskCenterDownloadList from 'Component/pages/task-center/download-list/index';
import TaskCenterUploadList from 'Component/pages/task-center/upload-list/index';
import TaskCenterTaskList from 'Component/pages/task-center/task-list/index';
//客服中心
import CustomerService from 'Component/pages/customer-service';
import CustomerServiceInformation from 'Component/pages/customer-service/customer-information';
import CustomerServiceInformationIndex from 'Component/pages/customer-service/customer-information/index';
import CustomerServicePortraitIndex from 'Component/pages/customer-service/customer-portrait/index';
import CustomerServiceRankListIndex from 'Component/pages/customer-service/customer-ranklist/index';

import CustomerServiceIndexManagement from 'Component/pages/customer-service/index-management';
import CustomerServiceIndexManagementIndex from 'Component/pages/customer-service/index-management/index';
import CustomerServiceIndexManagementDetail from 'Component/pages/customer-service/index-management/detail';
import CustomerServiceIndexManagementDetailNew from 'Component/pages/customer-service/index-management/detail/index';
import CustomerServiceIndexManagementDetailEdit from 'Component/pages/customer-service/index-management/detail/index';
//计费中心
import BillingCenterIndex from 'Component/pages/billing-center/index';
//系统集成
import SystemIntegration from 'Component/pages/system-integration/index';
import SystemIntegrationCCpass from 'Component/pages/system-integration/cc-pass/index';
//企微对接
import WechatDocking from 'Component/pages/system-integration/wechat-docking/index.tsx';
//淘宝对接
import TaobaoDocking from 'Component/pages/system-integration/taobao/index.tsx';
import WeifengDocking from 'Component/pages/system-integration/weifeng-docking/index.tsx';

//本地上传
import RecordUploadDetail from 'Component/pages/record-upload/detail';
import RecordUploadIndex from 'Component/pages/record-upload/index';
//异常数据
import AnomalousData from '../../pages/anomalous-data';
import AnomalousDataIndex from '../../pages/anomalous-data/index';
//模板组件
import TemplateComponent from '../../pages/admin/template-components';
import TemplateComponentList from '../../pages/admin/template-components/template-components-list';
import TemplateComponentListIndex from '../../pages/admin/template-components/template-components-list/index';
import TemplateComponentListDetail from '../../pages/admin/template-components/template-components-list/detail';
import TemplateComponentMy from '../../pages/admin/template-components/template-components-my';
import TemplateComponentMyIndex from '../../pages/admin/template-components/template-components-my/index';
import TemplateComponentMyDetail from '../../pages/admin/template-components/template-components-my/detail';
// 自定义数据源
import CustomDataSource from '../../pages/admin/custom-data-source';
import AmazonList from '../../pages/admin/custom-data-source/amazon';
import AmazonListIndex from '../../pages/admin/custom-data-source/amazon/index';
import YoutubeList from '../../pages/admin/custom-data-source/youtube';
import YoutubeListIndex from '../../pages/admin/custom-data-source/youtube/index';

//训练中心
import TrainingCenter from 'Component/pages/training-center';
import FastTrainingIndex from 'Component/pages/training-center/fast-training';
import TrainingHistoryIndex from 'Component/pages/training-center/training-history';
//分类训练-训练历史
import ClassifiedTrainingHistoryIndex from 'Component/pages/training-center/classified-training-history/index';
//聚类标注
import ClusteringAnnotationIndex from 'Component/pages/training-center/clustering-annotation';

//模组跳转页面
import TemplateTestTaskInfo from 'Component/pages/template-test/basic-info/index';
import TemplateTestTaskPointInfo from 'Component/pages/template-test/check-point/index';
import TemplateTestInterInfo from 'Component/pages/template-test/interactive-recognition/index';
import TemplateTestTagsInfo from 'Component/pages/template-test/semantic-tags/index';
import TemplateTestEntitiesInfo from 'Component/pages/template-test/information-entities/index';
import TemplateTestWordInfo from 'Component/pages/template-test/concept-word/index';

// 企业微信质检工作台
import WechatQaTasksIndex from '../../pages/tasks/index';

// 企业微信质检-任务管理
import WechatQaTaskCreateEntry from 'Component/pages/wechat-qa/wechat-qa-task-manager-entry';
import WechatQaTaskManagerEntry from 'Component/pages/wechat-qa/wechat-qa-task-manager-entry';
import WechatQaBaseInfo from '../../pages/tasks/manage/template/basic-info/index';
import WechatQaCreateBaseInfo from '../../pages/tasks/manage/template/basic-info/index';
import WechatQaCheckPointEntry from '../../pages/tasks/manage/template/check-point-entry';
import WechatQaCheckPointIndex from '../../pages/tasks/manage/template/check-point/index';
import WechatQaCheckPointNew from '../../pages/tasks/manage/template/check-point/new/index';
import WechatQaCheckPointEdit from '../../pages/tasks/manage/template/check-point/edit/index';
// 企业微信质检-质检流程自动化
import WechatQaAutomaticAddIndex from '../../pages/tasks/manage/template/automatic-add/index';
// 企业微信质检-质检手动添加
import WechatQaManualAddIndex from '../../pages/tasks/manage/template/manual-add/index';
// 企业微信质检-质检列表
import WechatQualityInspectionList from 'Component/pages/quality-inspection-list';
import WechatQualityInspectionListIndex from 'Component/pages/quality-inspection-list/index';
import WechatQualityInspectionListDetail from 'Component/pages/quality-inspection-list/detail';
//企业微信质检-整体报表
import WechatQualityInspectionWholeReport from '../../pages/tasks/manage/reports/whole-report/index';

// 企业微信质检-配置
import WechatQaSettingEntry from 'Component/pages/wechat-qa/wechat-qa-setting-entry';
import WechatQaConversationSetting from 'Component/pages/wechat-qa/setting/conversation-setting';
import WechatQaCheckPointSetting from 'Component/pages/wechat-qa/setting/check-point';
import WechatQaCheckPointSettingNew from '../../pages/tasks/manage/template/check-point/new/index';
import WechatQaCheckPointSettingEdit from '../../pages/tasks/manage/template/check-point/edit/index';
import WechatQaMessagePushSetting from 'Component/pages/wechat-qa/setting/message/index';
// 智能客户标签
import WechatQaCustomerLabel from 'Component/pages/wechat-qa/setting/wechat-qa-customer-label.js';
import WechatQaCustomerLabelIndex from 'Component/pages/wechat-qa/setting/wechat-qa-customer-label/index';
import WechatQaCustomerLabelDetail from 'Component/pages/wechat-qa/setting/wechat-qa-customer-label/detail/index';
import WechatQaCustomerLabelNew from 'Component/pages/wechat-qa/setting/wechat-qa-customer-label/detail/index';

//企业微信-实时分析
import WechatQaRealTimeAnalysis from 'Component/pages/wechat-qa/setting/real-time-analysis.js';
import WechatQaRealTimeAnalysisIndex from 'Component/pages/wechat-qa/setting/real-time-analysis/index';
import WechatQaRealTimeAnalysisDetail from 'Component/pages/wechat-qa/setting/real-time-analysis/detail/index';
import WechatQaRealTimeAnalysisNew from 'Component/pages/wechat-qa/setting/real-time-analysis/detail/index';

// 企业微信-消息过滤
import WechatQaMessageFiltering from 'Component/pages/wechat-qa/setting/message-filtering.js';
import WechatQaMessageFilteringIndex from 'Component/pages/wechat-qa/setting/message-filtering/index';

// 企业微信会话
import WechatQaChartRecordEntry from 'Component/pages/wechat-qa/wechat-qa-chart-record-entry';
import WechatQaChartRecord from 'Component/pages/wechat-qa/chart-record';
import WechatQaChartRecordDetail from 'Component/pages/wechat-qa/chart-record/detail';
import WechatQaChartRecordAudioList from 'Component/pages/wechat-qa/chart-audio-record/index';
import WechatQaChartRecordAudioEntry from 'Component/pages/wechat-qa/chart-audio-record';
import WechatQaChartRecordAudioDetail from 'Component/pages/wechat-qa/chart-audio-record/detail/index';
// 企微员工中心
import WechatQaStaffCenterEntry from 'Component/pages/wechat-qa/wechat-qa-chart-staff-center';
import WechatQaStaffCenter from 'Component/pages/wechat-qa/staff-center';

//客户中心
import ClientCenterEntry from 'Component/pages/client-center';
import ClientManage from 'Component/pages/client-center/client-manage';
import ClientManageRoot from 'Component/pages/client-center/index';
import ClientManageWechat from 'Component/pages/client-center/wechat-client-manage';

//客户字段
import CustomerField from 'Component/pages/customer-field';
// logo设置
import LogoSite from 'Component/pages/logo-site';
// 智能采集
import IntelligentCollectionEntry from 'Component/pages/intelligent-analysis/intelligent-collection';
import IntelligentCollectionList from 'Component/pages/intelligent-analysis/intelligent-collection/index';
import IntelligentCollectionNew from 'Component/pages/intelligent-analysis/intelligent-collection/detail/index';
import IntelligentCollectionEdit from 'Component/pages/intelligent-analysis/intelligent-collection/detail/index';

//404
import PageNotFound from '../../pages/404';
//sdk 测试页面
import SdkTest from 'Component/pages/sdk-test';
import TestComponent from 'Component/pages/sdk-test/component-index.tsx';

import WechatNotificationAnalysis from 'Component/pages/wechat-notification-analysis';
import WechatMessageNotification from 'Component/pages/wechat-notification-analysis/message-notification';
import WechatConversationalAnalysis from 'Component/pages/wechat-notification-analysis/conversational-analysis';
import BusinessWorkingHours from 'Component/pages/BusinessWorkingHours';

// 电商评论管理
import ECommentManage from 'Component/pages/e-comment-manage';
import ECommentManageIndex from 'Component/pages/e-comment-manage/index';
import ECommentManageDetail from 'Component/pages/e-comment-manage/detail/index';

// 社媒评论管理
import MCommentManage from 'Component/pages/m-comment-manage';
import MCommentManageIndex from 'Component/pages/m-comment-manage/index';
import MCommentManageDetail from 'Component/pages/m-comment-manage/detail/index';

// 质检结果
import QaResult from '../../pages/qa-result/index.tsx';
// 规则模版
import RuleTemplate from 'Component/pages/intelligent-analysis/rule-template.js';
import RuleTemplateList from 'Component/pages/intelligent-analysis/rule-template/index';
import RuleTemplateTaskManagerEntry from 'Component/pages/intelligent-analysis/rule-template-task-manager-entry';
import RuleTemplateNew from '../../pages/tasks/manage/template/check-point/new/index';
import RuleTemplateEdit from '../../pages/tasks/manage/template/check-point/edit/index';

export const qaRouteComponentByRouteNameMappings = {
    //sdk 测试页面
    sdkTest: {
        component: SdkTest,
    },
    //组件 测试页面
    testComponent: {
        component: TestComponent,
    },
    // demo
    demoIndex: {
        component: DemoIndex,
    },
    // 质检申诉模块
    // appealCheckingTaskParent: {
    //     component: AppealCheckingTaskParent,
    // },
    // appealCheckingTask: {
    //     component: AppealCheckingTask,
    // },
    // 企微会话分析消息通知
    wechatNotificationAnalysis: {
        component: WechatNotificationAnalysis,
    },
    wechatMessageNotification: {
        component: WechatMessageNotification,
    },
    wechatConversationalAnalysis: {
        component: WechatConversationalAnalysis,
    },

    // 主界面
    home: {
        component: Home,
    },

    // 质检评级
    gradeIndex: {
        component: GradeIndex,
    },
    gradeCreateIndex: {
        component: GradeCreateIndex,
    },
    // 质检首页概览
    dashboard: {
        component: Dashboard,
    },
    // 质检工作台
    tasksIndex: {
        component: TasksIndex,
    },
    //审批任务详情
    approveTaskEntry: {
        component: ApproveTaskEntry,
    },
    approveTasksCheckPointIndex: {
        component: ApproveTasksCheckPointIndex,
    },
    approveTasksCheckPointView: {
        component: ApproveTasksCheckPointView,
    },

    // 质检管理
    tasksManageEntry: {
        component: TasksManageEntry,
    },
    tasksManageCreateEntry: {
        component: TasksManageCreateEntry,
    },
    informationCollectionEntry: {
        component: InformationCollectionEntry,
    },
    informationCollectionCreateEntry: {
        component: InformationCollectionCreateEntry,
    },
    // 质检基本信息
    tasksBasicInfoIndex: {
        component: TasksBasicInfoIndex,
    },
    tasksBasicInfoCreateIndex: {
        component: TasksBasicInfoCreateIndex,
    },
    informationCollectionIndex: {
        component: InformationCollectionBasicInfoIndex,
    },
    informationCollectionBasicInfoCreateIndex: {
        component: InformationCollectionBasicInfoCreateIndex,
    },
    tasksBasicInfoDidCreateIndex: {
        component: TasksBasicInfoCreateIndex,
    },
    // 质检条件
    tasksConditionEntry: {
        component: TasksConditionEntry,
    },
    tasksConditionIndex: {
        component: TasksConditionIndex,
    },
    tasksConditionNew: {
        component: TasksConditionNew,
    },
    tasksConditionEdit: {
        component: TasksConditionEdit,
    },
    // 质检点
    tasksCheckPointCreateEntry: {
        component: TasksCheckPointCreateEntry,
    },
    tasksCheckPointCreateIndex: {
        component: TasksCheckPointCreateIndex,
    },
    informationCollectPointCreateIndex: {
        component: InformationCollectPointCreateIndex,
    },
    tasksCheckPointTestIndex: {
        component: TasksCheckPointTestIndex,
    },
    informationCollectPointIndex: {
        component: InformationCollectPointIndex,
    },
    tasksCheckPointCreateNew: {
        component: TasksCheckPointCreateNew,
    },
    tasksCheckPointCreateEdit: {
        component: TasksCheckPointCreateEdit,
    },
    tasksCheckPointEntry: {
        component: TasksCheckPointEntry,
    },
    tasksCheckPointIndex: {
        component: TasksCheckPointIndex,
    },
    tasksCheckPointNew: {
        component: TasksCheckPointNew,
    },
    tasksCheckPointEdit: {
        component: TasksCheckPointEdit,
    },
    tasksCheckPointView: {
        component: TasksCheckPointEdit,
    },
    // 质检流程自动化
    tasksAutomaticAddIndex: {
        component: TasksAutomaticAddIndex,
    },
    tasksAutomaticAddCreateIndex: {
        component: TasksAutomaticAddIndex,
    },
    //数据添加
    informationCollectDataAddCreateIndex: {
        component: InformationCollectDataAddIndex,
    },
    informationCollectDataAddIndex: {
        component: InformationCollectDataAddIndex,
    },
    informationCollectDataAddAuto: {
        component: TasksAutomaticAddIndex,
    },
    informationCollectDataAddManual: {
        component: TasksManualAddIndex,
    },
    // 质检手动添加
    tasksManualAddIndex: {
        component: TasksManualAddIndex,
    },
    tasksManualAddCreateIndex: {
        component: TasksManualAddIndex,
    },
    // 质检角色配置
    tasksRoleConfigIndex: {
        component: TasksRoleConfigIndex,
    },
    tasksRoleConfigCreateIndex: {
        component: TasksRoleConfigIndex,
    },

    // 质检列表
    qualityInspectionList: {
        component: QualityInspectionList,
    },
    qualityInspectionListIndex: {
        component: QualityInspectionListIndex,
    },
    qualityInspectionListDetail: {
        component: QualityInspectionListDetail,
    },
    informationCollectList: {
        component: InformationCollectList,
    },
    informationCollectListIndex: {
        component: InformationCollectListIndex,
    },
    informationCollectListDetail: {
        component: InformationCollectListDetail,
    },

    // 抽检功能
    spotCheckList: {
        component: SpotCheckList,
    },
    spotCheckListIndex: {
        component: SpotCheckListIndex,
    },
    spotCheckListDetail: {
        component: SpotCheckListDetail,
    },

    // 复核功能
    reviewList: {
        component: ReviewList,
    },
    reviewListIndex: {
        component: ReviewListIndex,
    },
    reviewListDetail: {
        component: ReviewListDetail,
    },
    //案例库
    caseBase: {
        component: CaseBase,
    },
    caseBaseIndex: {
        component: CaseBaseIndex,
    },
    caseBaseDetail: {
        component: CaseBaseDetail,
    },

    //质检测试集
    testSetList: {
        component: TestSetList,
    },
    testSetListIndex: {
        component: TestSetListIndex,
    },
    testSetListDetail: {
        component: TestSetListDetail,
    },

    // 质检报表
    tasksWholeReport: {
        component: TasksWholeReport,
    },
    tasksAgentReport: {
        component: TasksAgentReport,
    },
    wechatTasksAgentReport: {
        component: TasksAgentReport,
    },
    groupChatAnalysis: {
        component: GroupChatReport,
    },
    analysisOverview: {
        component: AnalysisOverview,
    },
    customerServiceExecution: {
        component: CustomerServiceExecution,
    },
    inspectionItemsAnalysis: {
        component: InspectionItemsAnalysis,
    },
    wecomInspectionItemsAnalysis: {
        component: InspectionItemsAnalysis,
    },
    // 语义智能-1、智能词库
    smartWordsLibrary: {
        component: SmartWordLibrary,
    },
    smartWordsLibraryAttentionWords: {
        component: AttentionWords,
    },
    smartWordsLibraryProfessionalWords: {
        component: ProfessionalWords,
    },
    smartWordsLibrarySynonymWords: {
        component: SynonymlWords,
    },
    smartWordsLibrarySensitiveWords: {
        component: SensitiveWords,
    },
    smartWordsLibraryWordsWeight: {
        component: WordsWeight,
    },
    smartWordsLibraryIgnoreWords: {
        component: IgnoreWords,
    },
    smartWordsLibraryCorpusData: {
        component: CorpusData,
    },
    smartWordsLibraryTypoLibrary: {
        component: TypoLibrary,
    },
    // 语义智能-2、语义标签
    semanticIntelligence: {
        component: SemanticIntelligence,
    },
    semanticTags: {
        component: SemanticTags,
    },
    semanticTagsIndex: {
        component: SemanticTagsIndex,
    },
    semanticTagsNew: {
        component: SemanticTagsDetail,
    },
    semanticTagsEdit: {
        component: SemanticTagsDetail,
    },

    // 智能分析-1、智能标签
    intelligentAnalysis: {
        component: IntelligentAnalysis,
    },
    intelligentTags: {
        component: IntelligentTags,
    },
    ignoreIntelligentTags: {
        component: IgnoreLabel,
    },
    smartConversationIntelligentTags: {
        component: SmartConversationIntelligentTags,
    },
    intelligentTagsIndex: {
        component: IntelligentTagsIndex,
    },
    intelligentTagsNew: {
        component: IntelligentTagsDetail,
    },
    intelligentTagsEdit: {
        component: IntelligentTagsDetail,
    },
    wechatIntelligentTags: {
        component: WechatIntelligentTags,
    },
    sessionLabel: {
        component: SessionLabel,
    },
    sessionLabelTagsIndex: {
        component: SessionLabelTagsIndex,
    },
    sessionLabelTagsNew: {
        component: SessionLabelTagsDetail,
    },
    sessionLabelTagsEdit: {
        component: SessionLabelTagsDetail,
    },
    customerLabel: {
        component: CustomerLabel,
    },
    customerLabelTagsIndex: {
        component: CustomerLabelTagsIndex,
    },
    customerLabelTagsNew: {
        component: CustomerLabelTagsDetail,
    },
    customerLabelTagsEdit: {
        component: CustomerLabelTagsDetail,
    },
    keyEvents: {
        component: KeyEvents,
    },
    supervision: {
        component: SupervisionEnter,
    },
    supervisionList: {
        component: SupervisionList,
    },
    supervisionDetail: {
        component: SupervisionDetail,
    },
    supervisionCreate: {
        component: SupervisionCreate,
    },
    keyEventsTagsIndex: {
        component: KeyEventsTagsIndex,
    },
    keyEventsTagsNew: {
        component: KeyEventsTagsDetail,
    },
    keyEventsTagsEdit: {
        component: KeyEventsTagsDetail,
    },
    //语义智能-3、信息实体
    informationEntities: {
        component: InformationEntities,
    },
    informationEntitiesIndex: {
        component: InformationEntitiesIndex,
    },
    informationEntitiesNew: {
        component: InformationEntitiesDetail,
    },
    informationEntitiesEdit: {
        component: InformationEntitiesDetail,
    },

    //语义智能-4、交互识别
    interactiveRecognition: {
        component: InteractiveRecognition,
    },
    interactiveRecognitionIndex: {
        component: InteractiveRecognitionIndex,
    },
    interactiveRecognitionNew: {
        component: InteractiveRecognitionDetail,
    },
    interactiveRecognitionEdit: {
        component: InteractiveRecognitionDetail,
    },
    knowledgeBase: {
        component: KnowledgeBase,
    },
    knowledgeBaseIndex: {
        component: KnowledgeBaseIndex,
    },
    knowledgeBaseNew: {
        component: KnowledgeBaseDetail,
    },
    knowledgeBaseEdit: {
        component: KnowledgeBaseDetail,
    },
    intelligentModel: {
        component: IntelligentModel,
    },
    intelligentModelIndex: {
        component: IntelligentModelIndex,
    },
    intelligentModelNew: {
        component: IntelligentModelDetail,
    },
    intelligentModelEdit: {
        component: IntelligentModelDetail,
    },
    applicationMessage: {
        component: ApplicationMessage,
    },
    applicationMessageIndex: {
        component: ApplicationMessageIndex,
    },
    applicationMessageNew: {
        component: ApplicationMessageDetail,
    },
    applicationMessageEdit: {
        component: ApplicationMessageDetail,
    },
    //语义智能-5、业务分析
    businessAnalysis: {
        component: BusinessAnalysis,
    },
    businessAnalysisList: {
        component: BusinessAnalysisList,
    },
    businessAnalysisCreate: {
        component: BusinessAnalysisCreate,
    },
    businessAnalysisDetail: {
        component: BusinessAnalysisDetail,
    },
    // 分析报表
    analysisReport: {
        component: AnalysisReport,
    },
    //语义智能-6、概念词
    conceptWord: {
        component: ConceptWord,
    },
    conceptWordIndex: {
        component: ConceptWordIndex,
    },
    conceptWordNew: {
        component: ConceptWordDetail,
    },
    conceptWordEdit: {
        component: ConceptWordDetail,
    },

    // 客户画像
    customerPortrait: {
        component: CustomerPortrait,
    },
    customerPortraitIndex: {
        component: CustomerPortraitIndex,
    },

    // 舆情发现
    sentimentAnalysis: {
        component: SentimentAnalysis,
    },
    sentimentAnalysisIndex: {
        component: SentimentAnalysisIndex,
    },
    // 通对话关联
    connectedByConversationIndex: {
        component: ConnectedByConversationIndex,
    },
    connectedByConversation: {
        component: ConnectedByConversation,
    },
    //分类训练
    classifiedTraining: {
        component: ClassifiedTraining,
    },
    classifiedTrainingIndex: {
        component: ClassifiedTrainingIndex,
    },
    classifiedTrainingDetail: {
        component: ClassifiedTrainingDetail,
    },
    // 数据预处理
    dataPreprocess: {
        component: DataPreprocess,
    },
    dataPreprocessIndex: {
        component: DataPreprocessIndex,
    },
    dataPreprocessNew: {
        component: DataPreprocessDetail,
    },
    dataPreprocessEdit: {
        component: DataPreprocessDetail,
    },

    // 通话管理
    callManage: {
        component: CallManage,
    },
    callManageIndex: {
        component: CallManageIndex,
    },
    callManageView: {
        component: CallManageView,
    },

    // 对话管理
    dialogueManage: {
        component: DialogueManage,
    },
    dialogueManageIndex: {
        component: DialogueManageIndex,
    },
    dialogueManageView: {
        component: DialogueManageView,
    },

    // 工单管理
    ticketManage: {
        component: TicketManage,
    },
    ticketManageIndex: {
        component: TicketManageIndex,
    },
    ticketManageView: {
        component: TicketManageView,
    },
    // 对话管理
    eCommerceManage: {
        component: ECommerceManage,
    },
    eCommerceManageIndex: {
        component: ECommerceManageIndex,
    },
    eCommerceManageView: {
        component: ECommerceManageView,
    },

    // 系统管理
    adminEntry: {
        component: AdminEntry,
    },
    // 会话设置
    sessionSetings: {
        component: SessionSetings,
    },
    // 系统设置
    adminSystemIndex: {
        component: AdminSystemIndex,
    },
    // 用户管理
    adminUserEntry: {
        component: AdminUserEntry,
    },
    adminUserIndex: {
        component: AdminUserIndex,
    },
    adminUserView: {
        component: AdminUserView,
    },
    adminUserNew: {
        component: AdminUserNew,
    },
    adminUserEdit: {
        component: AdminUserEdit,
    },
    // 用户组管理
    adminUserGroupsEntry: {
        component: AdminUserGroupsEntry,
    },
    adminUserGroupsIndex: {
        component: AdminUserGroupsIndex,
    },
    adminUserGroupsView: {
        component: AdminUserGroupsView,
    },
    adminUserGroupsNew: {
        component: AdminUserGroupsNew,
    },
    adminUserGroupsEdit: {
        component: AdminUserGroupsEdit,
    },
    // 语音识别
    speechRecognitionIndex: {
        component: SpeechRecognitionIndex,
    },
    //消息日志
    systemLogIndex: {
        component: SystemLogIndex,
    },
    // 角色管理
    role: {
        component: Role,
    },
    roleIndex: {
        component: RoleIndex,
    },
    roleNew: {
        component: RoleDetail,
    },
    roleNewBasicInfo: {
        component: RoleDetailBasicInfo,
    },
    roleView: {
        component: RoleDetail,
    },
    roleViewBasicInfo: {
        component: RoleDetailBasicInfo,
    },
    roleViewTaskPermissions: {
        component: RoleDetailTaskPermissions,
    },
    roleViewDataPermissions: {
        component: RoleDetailDataPermissions,
    },
    roleEdit: {
        component: RoleDetail,
    },
    roleEditBasicInfo: {
        component: RoleDetailBasicInfo,
    },
    roleEditTaskPermissions: {
        component: RoleDetailTaskPermissions,
    },
    roleEditDataPermissions: {
        component: RoleDetailDataPermissions,
    },
    dataMigration: {
        component: DataMigrationIndex,
    },
    //异常数据
    anomalousData: {
        component: AnomalousData,
    },
    anomalousDataIndex: {
        component: AnomalousDataIndex,
    },
    //模板组件
    templateComponent: {
        component: TemplateComponent,
    },
    templateComponentList: {
        component: TemplateComponentList,
    },
    templateComponentListIndex: {
        component: TemplateComponentListIndex,
    },
    templateComponentListDetail: {
        component: TemplateComponentListDetail,
    },
    templateComponentMy: {
        component: TemplateComponentMy,
    },
    templateComponentMyIndex: {
        component: TemplateComponentMyIndex,
    },
    templateComponentMyDetailEdit: {
        component: TemplateComponentMyDetail,
    },
    templateComponentMyDetailNew: {
        component: TemplateComponentMyDetail,
    },
    // 自定义数据源
    customDataSource: {
        component: CustomDataSource,
    },
    amazonList: {
        component: AmazonList,
    },
    amazonListIndex: {
        component: AmazonListIndex,
    },
    youtubeList: {
        component: YoutubeList,
    },
    youtubeListIndex: {
        component: YoutubeListIndex,
    },
    // 数据分析
    dataAnalysis: {
        component: DataAnalysis,
    },
    businessStatistics: {
        component: BusinessStatistics,
    },
    businessStatisticsIndex: {
        component: BusinessStatisticsIndex,
    },
    qualityEffect: {
        component: QualityEffect,
    },
    qualityEffectIndex: {
        component: QualityEffectIndex,
    },
    qualityAnalysis: {
        component: QualityAnalysis,
    },
    qualityAnalysisIndex: {
        component: QualityAnalysisIndex,
    },
    generalInspectionReport: {
        component: GeneralInspectionReport,
    },
    generalInspectionReportIndex: {
        component: GeneralInspectionReportIndex,
    },
    fullAmountWork: {
        component: FullAmountWork,
    },
    fullAmountWorkIndex: {
        component: FullAmountWorkIndex,
    },
    taskCenter: {
        component: TaskCenter,
    },
    taskCenterDownloadList: {
        component: TaskCenterDownloadList,
    },
    taskCenterUploadList: {
        component: TaskCenterUploadList,
    },
    taskCenterTaskList: {
        component: TaskCenterTaskList,
    },
    customerService: {
        component: CustomerService,
    },
    customerServiceInformation: {
        component: CustomerServiceInformation,
    },
    customerServiceInformationIndex: {
        component: CustomerServiceInformationIndex,
    },
    //客服画像
    customerServicePortraitIndex: {
        component: CustomerServicePortraitIndex,
    },
    //RankList
    customerServiceRankListIndex: {
        component: CustomerServiceRankListIndex,
    },
    customerServiceIndexManagement: {
        component: CustomerServiceIndexManagement,
    },
    customerServiceIndexManagementIndex: {
        component: CustomerServiceIndexManagementIndex,
    },
    customerServiceIndexManagementDetail: {
        component: CustomerServiceIndexManagementDetail,
    },
    customerServiceIndexManagementDetailNew: {
        component: CustomerServiceIndexManagementDetailNew,
    },
    customerServiceIndexManagementDetailEdit: {
        component: CustomerServiceIndexManagementDetailEdit,
    },
    //计费中心
    billingCenterIndex: {
        component: BillingCenterIndex,
    },
    systemIntegration: {
        component: SystemIntegration,
    },
    systemIntegrationCCpass: {
        component: SystemIntegrationCCpass,
    },
    //本地上传
    recordUploadDetail: {
        component: RecordUploadDetail,
    },
    recordUploadDetailIm: {
        component: RecordUploadDetail,
    },
    recordUploadIndex: {
        component: RecordUploadIndex,
    },
    trainingCenter: {
        component: TrainingCenter,
    },
    fastTrainingIndex: {
        component: FastTrainingIndex,
    },
    trainingHistoryIndex: {
        component: TrainingHistoryIndex,
    },
    classifiedTrainingHistoryIndex: {
        component: ClassifiedTrainingHistoryIndex,
    },
    clusteringAnnotationIndex: {
        component: ClusteringAnnotationIndex,
    },
    //模组跳转
    templateTestTaskInfo: {
        component: TemplateTestTaskInfo,
    },
    templateTestTaskPointInfo: {
        component: TemplateTestTaskPointInfo,
    },
    templateTestInterInfo: {
        component: TemplateTestInterInfo,
    },
    templateTestTagsInfo: {
        component: TemplateTestTagsInfo,
    },
    templateTestEntitiesInfo: {
        component: TemplateTestEntitiesInfo,
    },
    templateTestWordInfo: {
        component: TemplateTestWordInfo,
    },
    // 企业微信质检工作台
    wechatQaTasksIndex: {
        component: WechatQaTasksIndex,
    },
    wechatQaTaskManagerEntry: {
        component: WechatQaTaskManagerEntry,
    },
    wechatQaTaskCreateEntry: {
        component: WechatQaTaskCreateEntry,
    },
    wechatQaBaseInfo: {
        component: WechatQaBaseInfo,
    },
    wechatQaCreateBaseInfo: {
        component: WechatQaCreateBaseInfo,
    },
    wechatQaCheckPointEntry: {
        component: WechatQaCheckPointEntry,
    },
    wechatQaCheckPointIndex: {
        component: WechatQaCheckPointIndex,
    },
    wechatQaCheckPointNew: {
        component: WechatQaCheckPointNew,
    },
    wechatQaCheckPointEdit: {
        component: WechatQaCheckPointEdit,
    },
    wechatQaConversationSetting: {
        component: WechatQaConversationSetting,
    },
    wechatQaCheckPointSetting: {
        component: WechatQaCheckPointSetting,
    },
    wechatQaSettingEntry: {
        component: WechatQaSettingEntry,
    },
    wechatQaChartRecordEntry: {
        component: WechatQaChartRecordEntry,
    },
    wechatQaChartRecord: {
        component: WechatQaChartRecord,
    },
    wechatQaStaffCenterEntry: {
        component: WechatQaStaffCenterEntry,
    },

    wechatQaStaffCenter: {
        component: WechatQaStaffCenter,
    },
    wechatQaChartRecordDetail: {
        component: WechatQaChartRecordDetail,
    },
    wechatQaChartRecordAudioList: {
        component: WechatQaChartRecordAudioList,
    },
    wechatQaChartRecordAudioEntry: {
        component: WechatQaChartRecordAudioEntry,
    },
    wechatQaChartRecordAudioDetail: {
        component: WechatQaChartRecordAudioDetail,
    },
    clientCenterEntry: {
        component: ClientCenterEntry,
    },
    clientManage: {
        component: ClientManage,
    },
    clientManageRoot: {
        component: ClientManageRoot,
    },
    clientManageWechat: {
        component: ClientManageWechat,
    },
    wechatQaCheckPointSettingEdit: {
        component: WechatQaCheckPointSettingEdit,
    },
    wechatQaCheckPointSettingNew: {
        component: WechatQaCheckPointSettingNew,
    },
    wechatQaMessagePushSetting: {
        component: WechatQaMessagePushSetting,
    },
    wechatQaCustomerLabel: {
        component: WechatQaCustomerLabel,
    },
    wechatQaCustomerLabelIndex: {
        component: WechatQaCustomerLabelIndex,
    },
    wechatQaCustomerLabelDetail: {
        component: WechatQaCustomerLabelDetail,
    },
    wechatQaCustomerLabelNew: {
        component: WechatQaCustomerLabelNew,
    },
    wechatQaRealTimeAnalysis: {
        component: WechatQaRealTimeAnalysis,
    },
    wechatQaRealTimeAnalysisDetail: {
        component: WechatQaRealTimeAnalysisDetail,
    },
    wechatQaRealTimeAnalysisNew: {
        component: WechatQaRealTimeAnalysisNew,
    },
    wechatQaRealTimeAnalysisIndex: {
        component: WechatQaRealTimeAnalysisIndex,
    },

    wechatQaMessageFiltering: {
        component: WechatQaMessageFiltering,
    },
    wechatQaMessageFilteringIndex: {
        component: WechatQaMessageFilteringIndex,
    },

    wechatQaAutomaticAddIndex: {
        component: WechatQaAutomaticAddIndex,
    },
    wechatQaManualAddIndex: {
        component: WechatQaManualAddIndex,
    },
    wechatQualityInspectionList: {
        component: WechatQualityInspectionList,
    },
    wechatQualityInspectionListIndex: {
        component: WechatQualityInspectionListIndex,
    },
    wechatQualityInspectionListDetail: {
        component: WechatQualityInspectionListDetail,
    },
    wechatSpotCheckList: {
        component: SpotCheckList,
    },
    wechatSpotCheckListIndex: {
        component: SpotCheckListIndex,
    },
    wechatSpotCheckListDetail: {
        component: SpotCheckListDetail,
    },
    wechatReviewList: {
        component: ReviewList,
    },
    wechatReviewListIndex: {
        component: ReviewListIndex,
    },
    wechatReviewListDetail: {
        component: ReviewListDetail,
    },
    customerField: {
        component: CustomerField,
    },
    logoSite: {
        component: LogoSite,
    },
    wechatDocking: {
        component: WechatDocking,
    },
    taobaoDocking: {
        component: TaobaoDocking,
    },
    weifengDocking: {
        component: WeifengDocking,
    },
    wechatQualityInspectionWholeReport: {
        component: WechatQualityInspectionWholeReport,
    },
    intelligentTagsAnalysis: {
        component: IntelligentTagsAnalysis,
    },
    intelligentCollectionEntry: {
        component: IntelligentCollectionEntry,
    },
    intelligentCollectionList: {
        component: IntelligentCollectionList,
    },
    intelligentCollectionEdit: {
        component: IntelligentCollectionEdit,
    },
    intelligentCollectionNew: {
        component: IntelligentCollectionNew,
    },
    qaPageNotFound: {
        component: PageNotFound,
    },
    businessWorkingHours: {
        component: BusinessWorkingHours,
    },

    // 质检结果
    qaResult: {
        component: QaResult,
    },

    // 电商评论管理
    eCommentManage: {
        component: ECommentManage,
    },
    eCommentManageIndex: {
        component: ECommentManageIndex,
    },
    eCommentManageDetail: {
        component: ECommentManageDetail,
    },

    // 社媒评论管理
    mCommentManage: {
        component: MCommentManage,
    },
    mCommentManageIndex: {
        component: MCommentManageIndex,
    },
    mCommentManageDetail: {
        component: MCommentManageDetail,
    },

    ruleTemplate: {
        component: RuleTemplate,
    },
    ruleTemplateList: {
        component: RuleTemplateList,
    },
    ruleTemplateTaskManagerEntry: {
        component: RuleTemplateTaskManagerEntry,
    },
    ruleTemplateNew: {
        component: RuleTemplateNew,
    },
    ruleTemplateEdit: {
        component: RuleTemplateEdit,
    },
};
