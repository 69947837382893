// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostOperatorLogsSaveStudyLogBody,
    BaseResponseOperatorLogFoundResponse,
} from '../../types';

/**
 * saveStudyLog
 *
 * @export
 * @tags operator-log-controller
 * @link [POST] /operatorLogs/saveStudyLog
 */
export function postOperatorLogsSaveStudyLog(
    data: PostOperatorLogsSaveStudyLogBody
): Promise<BaseResponseOperatorLogFoundResponse>;
export function postOperatorLogsSaveStudyLog(
    data: PostOperatorLogsSaveStudyLogBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseOperatorLogFoundResponse>;
export function postOperatorLogsSaveStudyLog(
    data: PostOperatorLogsSaveStudyLogBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseOperatorLogFoundResponse> {
    return request<PostOperatorLogsSaveStudyLogBody, never, never>('/operatorLogs/saveStudyLog', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postOperatorLogsSaveStudyLog',
    });
}

export const meta = [
    { tags: ['operator-log-controller'], path: '/operatorLogs/saveStudyLog', method: 'post' },
];
