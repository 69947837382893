const audioWorkerHandle = function() {

};

class Worker {
    constructor(handle) {
        const body = handle.toString().trim().match(/^function\s*\w*\s*\([\w\s,]*\)\s*{([\w\W]*?)}$/)[1];
        const url = window.URL.createObjectURL(new window.Blob([body], {
            type: "text/javascript"
        }));
        this.worker = new window.Worker(url);
    }
    postMessage(data) {
        this.worker.postMessage(data);
    }
    onmessage(handle) {
        this.worker.onmessage = handle;
    }
    destory() {
        this.worker.terminate();
    }
}

export class AudioWorker extends Worker {
    constructor() {
        super(audioWorkerHandle);
    }
}