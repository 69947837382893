/*
 * @Author: 唐毓斌
 * @Description: 自定义卡片
 */
import React, { CSSProperties, ReactNode } from 'react';
import './index.less';

interface CustomCardProps {
    containerStyle?: CSSProperties;
    contentStyle?: CSSProperties;
    contentClassName?: string;
    title: ReactNode | string;
    extra?: ReactNode;
    content: ReactNode;
}

const CustomCard: React.FC<CustomCardProps> = (props) => {
    const { title, extra, content, containerStyle } = props;
    return (
        <div className="custom-card-container" style={containerStyle}>
            <div className="custom-card-header">
                <div className="custom-card-header-icon" />
                <div className="custom-card-header-title">{title}</div>
                {extra}
            </div>
            <div style={{
                overflow: 'auto',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {content}
            </div>
            {/* <div className={contentClassName} style={contentStyle}>
                {content}
            </div> */}
        </div>
    );
};

export default CustomCard;
