import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default class TasksConditionIndexComponent extends React.Component {
    //#region state
    state = {
        columns: [],
    };
    //#endregion

    //#region actions
    actions = {
        delete(id) {
            let onConfirm = () => {
                let url = Udesk.business.apiPath.concatApiPath(
                    '/inspectionConditions/' + id + '?taskId=' + this.privates.model.taskId,
                    this.props.sdkOptions
                );
                Udesk.ajax.del(url).then(
                    resp => {
                        Udesk.ui.notify.success(
                            `${UdeskLocales.current.pages.tasks.manage.template.condition.index.name}${UdeskLocales.current.business.info.deleteSuccess}`
                        );
                        this.actions.reloadModel();
                    },
                    reason => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                `${UdeskLocales.current.pages.tasks.manage.template.condition.index.name}${UdeskLocales.current.business.info.deleteError}`
                        );
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: Udesk.utils.string.format(
                    UdeskLocales.current.business.info.deleteWarning,
                    UdeskLocales.current.pages.tasks.manage.template.condition.index.name
                ),
                onOk: onConfirm
            });
        }
    }; //
    //#endregion

    //#region Life Cycle
    componentDidMount() {
        getColumns(this);
    }
    //#endregion
}

function getColumns(that) {
    let {
        actions,
        locales,
        privates,
        props
    } = that;
    let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
    let columns = [
        {
            name: 'idCode',
            width: '25%'
        },
        {
            name: 'name',
            width: '25%'
        },
        {
            name: 'remark',
            width: '25%'
        },
        {
            name: 'actions',
            width: '25%',
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return (
                    <div>
                        <If condition={hasFeature && hasFeature('task:template:condition:edit')}>
                            <NavLink
                                to={`/site/tasks/manage/${privates.model.task.id}/template/condition/edit/${item.id}`}
                                className={'action-btn'}>
                                {locales.labels.edit}
                            </NavLink>
                        </If>
                        <If condition={hasFeature && hasFeature('task:template:condition:delete')}>
                            <button
                                style={{marginLeft: "5px"}}
                                className="action-btn delete-btn"
                                onClick={actions.delete.params(item.id)}>
                                {locales.labels.delete}
                            </button>
                        </If>
                    </div>
                );
            }
        }
    ];
    UdeskLocales.translate(
        columns,
        'name',
        'caption',
        UdeskLocales.current.columns.tasks.manage.template.condition
    );
    that.setState({
        columns
    });
}