import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash-es/debounce';
import { getKnowledgeCategorysInitTree } from 'src/api/knowledgeCategorys/initTree';
import { getCallServiceSummaryFields } from 'src/api/callServiceSummaryFields';
// import _isEmpty from 'lodash-es/isEmpty';
export default class CheckPointOperatorListGatherComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        conditionItem: {},
        operatorTypes: [],
        onChange: null,
        entityUrl: '',
        conceptWordUrl: '',
        entities: [],
        entityFields: [],
        disabled: false,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        conditionItem: PropTypes.object,
        operatorTypes: PropTypes.array,
        onChange: PropTypes.func,
        entityUrl: PropTypes.string,
        conceptWordUrl: PropTypes.string,
        entities: PropTypes.array,
        entityFields: PropTypes.array,
        disabled: PropTypes.bool,
    };
    //#endregion
    state = {
        semanticSentence: null,
        semanticTags: [],
        columns: [],
    };
    privates = {
        operatorItem: {},
        selectValue: '',
        selectInputValue: '',
        selectOptionList: {
            conceptWord: [],
            element: [],
            entityInfoDetection: [],
            businessSummary: [],
        },
        treeSelectOptionList: [],
        treeSelectOptionListKnowledge: [],
        interactiveRule: 2,
        operatorList: [],
        regularText: null,
        intreeFlag: true,
        intreeFlag2: true,
        elementFlag: true,
        templateFlag: true,
        intreeFlag3: true,
    };

    actions = {
        changeOperatorItemType(operatorItem, value) {
            Object.keys(operatorItem.operatorList[0]).forEach((key) => {
                if (
                    key !== '_id' &&
                    key !== 'operatorType' &&
                    key !== 'taskId' &&
                    key !== 'type' &&
                    key !== 'id' &&
                    key !== 'idCode' &&
                    key !== 'operator' &&
                    key !== 'conditionType' &&
                    key !== 'applyRole' &&
                    key !== 'applyConditionHitType' &&
                    key !== 'applyOperatorScope'
                ) {
                    delete operatorItem.operatorList[0][key];
                }
            });
            operatorItem.operatorList[0].type = value;
            operatorItem.operatorType = value;

            operatorItem.applyTimeType = Udesk.enums.applyTimeTypes.all.id;
            operatorItem.applyTimeId = null;

            if (operatorItem.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id) {
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.entityInfoDetection.id,
                };
                this.actions.getInformationEntity();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.volume.id) {
                //音量检测
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.volume.id,
                    operator: Udesk.enums.volumeDetectionOperators.greaterThan.id,
                    detectionWay: Udesk.enums.volumeDetectionWays.loudness.id,
                    detectionValue: 60,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.emotion.id) {
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.emotion.id,
                    operator: Udesk.enums.emotionOperators.is.id,
                    sentiment: Udesk.enums.sentimentTypes.positive.id,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.sentenceLength.id) {
                //句长检测
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.sentenceLength.id,
                    compareType: Udesk.enums.sentenceLength.less.id,
                    wordsNumber: 2,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.semanticTags.id) {
                operatorItem.semanticTag = {};
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.wecomRemark.id) {
                operatorItem.semanticTag = {};
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.conceptWord.id) {
                // 概念词
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.conceptWord.id,
                    compareType: Udesk.enums.conceptWordOperators.any.id,
                };
                // this.actions.getConceptWords();
            } else if (
                operatorItem.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id
            ) {
                //一问一答
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.questionsAndAnswers.id,
                    interactiveRule: Udesk.enums.interactiveRules.allRight.id,
                    noHitQuestionResult: Udesk.enums.noHitQuestionResults.false.id,
                };
                this.actions.getInteractionInitTree();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.knowledge.id) {
                //知识解答
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.knowledge.id,
                    interactiveRule: Udesk.enums.interactiveRules.allRight.id,
                    noHitQuestionResult: Udesk.enums.noHitQuestionResults.false.id,
                };
                this.actions.getInteractionInitTree();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.element.id) {
                //要素问答
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.element.id,
                };
                this.actions.getMultiElements();
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.associate.id) {
                //随路字段
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.associate.id,
                    version: 1,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.businessSummary.id) {
                // 业务小结字段
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.businessSummary.id,
                    inspectTaskId: this.props.taskId,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.repeat.id) {
                //重复话术
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.repeat.id,
                    repeatType: Udesk.enums.repeatRules.same.id,
                    repeatTimes: 2,
                    wordsNum: 2,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.timeInterval.id) {
                //间隔时长
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.timeInterval.id,
                    compareType: Udesk.enums.timeIntervalCompareTypes.greater_than.id,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.customerAddTime.id) {
                //客户添加时间
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.customerAddTime.id,
                    compareType: Udesk.enums.customerAddTimeCompareTypes.is.id,
                    interval: 7,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.typoDetection.id) {
                //错别字检测
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.typoDetection.id,
                    typoCounts: 3,
                    operator: Udesk.enums.typoDetectionOperators.greater.id,
                };
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.speed.id) {
                //语速检测
                operatorItem.operatorList[0].maxWordPerMinite = 250;
                operatorItem.operatorList[0].wordPerMinite = 80;
                operatorItem.operatorList[0].keyword = '4';
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.grabDetection.id) {
                //抢话检测
                operatorItem.operatorList[0].grabIntervalTime = 3000;
                operatorItem.operatorList[0].grabMaxSpeakTime = 3000;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.agentAnswer.id) {
                operatorItem.operatorList[0].param = {
                    type: Udesk.enums.operatorTypes.agentAnswer.id,
                    intervalType: Udesk.enums.dialogueApplyScopes.agentResponse.id,
                    intervalTime: 20,
                };
            }

            this.actions.handleOperatorItemChange();
        },
        changeOperatorItem(operatorItem, key, value, label, extra) {
            //区分是否是概念词
            if (operatorItem.operatorType === Udesk.enums.operatorTypes.conceptWord.id) {
                if (key === 'compareType') {
                    operatorItem.operatorList[0].param[key] = value;
                }
                // if(key === 'conceptWord'){
                //     let conceptWordList= this.privates.selectOptionList.conceptWord;
                //     operatorItem.operatorList[0].param[key] = conceptWordList.find(item => item.id === value);
                // }
            } else if (
                operatorItem.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id
            ) {
                //区分一问一答
                if (key === 'interactiveCategory') {
                    operatorItem.operatorList[0].param.interactiveCategoryId = value;
                    operatorItem.operatorList[0].param.interactiveCategoryName = label[0];
                    operatorItem.operatorList[0].keyword = value;
                }
                if (key === 'interactiveRule') {
                    operatorItem.operatorList[0].param.interactiveRule = value.target.value;
                }
                if (key === 'noHitQuestionResult') {
                    operatorItem.operatorList[0].param.noHitQuestionResult = value.target.value;
                }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.knowledge.id) {
                //区分知识解答
                if (key === 'interactiveCategory') {
                    operatorItem.operatorList[0].param.interactiveCategoryId = value;
                    operatorItem.operatorList[0].param.interactiveCategoryName = label[0];
                    operatorItem.operatorList[0].keyword = value;
                }
                if (key === 'interactiveRule') {
                    operatorItem.operatorList[0].param.interactiveRule = value.target.value;
                }
                if (key === 'noHitQuestionResult') {
                    operatorItem.operatorList[0].param.noHitQuestionResult = value.target.value;
                }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.element.id) {
                //区分要素问答
                if (key === 'compareType') {
                    operatorItem.operatorList[0].param.hit = value;
                }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.sentenceLength.id) {
                //区分句长检测
                if (key === 'compareType') {
                    operatorItem.operatorList[0].param[key] = value;
                }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.volume.id) {
                //区分音量检测
                operatorItem.operatorList[0].param[key] = value;
                if (key === 'detectionWay') {
                    if (
                        value === Udesk.enums.volumeDetectionWays.rangeAbilitySelf.id ||
                        value === Udesk.enums.volumeDetectionWays.rangeAbilityOth.id
                    ) {
                        operatorItem.operatorList[0].param.detectionValue = 40;
                    } else if (value === Udesk.enums.volumeDetectionWays.loudness.id) {
                        operatorItem.operatorList[0].param.detectionValue = 60;
                    }
                }
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.emotion.id) {
                //区分情绪分析
                operatorItem.operatorList[0].param[key] = value;
            } else if (
                operatorItem.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id
            ) {
                //区分信息实体
                operatorItem.operatorList[0].param[key] = value;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.repeat.id) {
                //区分重复话术
                operatorItem.operatorList[0].param[key] = value.target.value;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.timeInterval.id) {
                //间隔时长
                operatorItem.operatorList[0].param[key] = value;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.customerAddTime.id) {
                //客户添加时间
                operatorItem.operatorList[0].param[key] = value;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.typoDetection.id) {
                //错别字
                operatorItem.operatorList[0].param[key] = value;
            } else if (operatorItem.operatorType === Udesk.enums.operatorTypes.agentAnswer.id) {
                operatorItem.operatorList[0].param[key] = value;
            } else {
                operatorItem.operatorList[0][key] = value;
            }
            this.actions.handleOperatorItemChange();
        },
        handleOperatorItemChange() {
            this.actions.update();
            this.trigger('onChange', this.privates.operatorItem);
        },
        changeInputValue(operatorItem, key, value) {
            if (value instanceof Object) {
                if (key === 'expectedValue' || key === 'inputContent') {
                    operatorItem.operatorList[0].param[key] = value.target.value;
                } else {
                    operatorItem.operatorList[0][key] = value.target.value;
                }
            } else {
                if (
                    key === 'similarity' ||
                    key === 'repeatTimes' ||
                    key === 'wordsNum' ||
                    key === 'inputContent' ||
                    key === 'intervalTime'
                ) {
                    operatorItem.operatorList[0].param[key] = value;
                } else if (key === 'keyword') {
                    operatorItem.operatorList[0][key] = `${value}`;
                } else {
                    operatorItem.operatorList[0][key] = value;
                }
            }
            if (key === 'semanticSentence') {
                this.setState({
                    semanticSentence: operatorItem.operatorList[0][key],
                });
            }
            this.actions.handleOperatorItemChange();
        },
        changeInputValueInParam(operatorItem, key, value) {
            if (value instanceof Object) {
                operatorItem.operatorList[0].param[key] = value.target.value;
            } else {
                operatorItem.operatorList[0].param[key] = value;
            }
            this.actions.handleOperatorItemChange();
        },
        changeSemanticTagsModalVisible(operatorItem) {
            this.props.SmartToolBarRef.current.actions.open(
                this.actions.seleteSemanticNameBySider,
                operatorItem.operatorList[0].keyword
            );
            this.props.SmartToolBarRef.current.actions.seleteTag('3');
            if (!this.props.SmartToolBarRef.current.privates.isFront) {
                this.props.SmartToolBarRef.current.actions.backToIndex();
            }
        },
        seleteSemanticNameBySider(tag) {
            let operatorItem = this.privates.operatorItem;
            operatorItem.operatorList[0].keyword = tag.id;
            let standard = tag.tags.find((item) => item.standardFlag === 1);
            if (standard) {
                operatorItem.operatorList[0].semanticId = standard.id;
            }
            operatorItem.operatorList[0].semanticTag = {
                groupId: tag.id,
                key: tag.id,
                semanticName: tag.title,
                sentence: standard ? standard.content : '',
            };
            this.actions.handleOperatorItemChange();
        },
        changeTimeIntervalSemanticTagsModalVisible(operatorItem, key) {
            let keyword;
            if (!Array.isArray(operatorItem.operatorList[0].keyword)) {
                operatorItem.operatorList[0].keyword = (
                    operatorItem.operatorList[0].keyword ?? ''
                ).split(',');
            }
            if (key === 'semanticTagStart') {
                keyword = operatorItem.operatorList[0].keyword[0];
            }
            if (key === 'semanticTagEnd') {
                keyword = operatorItem.operatorList[0].keyword[1];
            }
            operatorItem.operatorList[0].keyword = operatorItem.operatorList[0].keyword.join(',');
            this.props.SmartToolBarRef.current.actions.open(
                this.actions.seleteTimeIntervalSemanticNameBySider.bind(this, key),
                keyword
            );
            this.props.SmartToolBarRef.current.actions.seleteTag('3');
            if (!this.props.SmartToolBarRef.current.privates.isFront) {
                this.props.SmartToolBarRef.current.actions.backToIndex();
            }
        },
        seleteTimeIntervalSemanticNameBySider(key, tag) {
            let operatorItem = this.privates.operatorItem;
            if (!Array.isArray(operatorItem.operatorList[0].keyword)) {
                operatorItem.operatorList[0].keyword = (
                    operatorItem.operatorList[0].keyword ?? ''
                ).split(',');
            }
            if (key === 'semanticTagStart') {
                operatorItem.operatorList[0].keyword[0] = tag.id;
            }
            if (key === 'semanticTagEnd') {
                operatorItem.operatorList[0].keyword[1] = tag.id;
            }
            let standard = tag.tags.find((item) => item.standardFlag === 1);

            operatorItem.operatorList[0][key] = {
                groupId: tag.id,
                key: tag.id,
                semanticName: tag.title,
                sentence: standard ? standard.content : '',
            };
            operatorItem.operatorList[0].keyword = operatorItem.operatorList[0].keyword.join(',');
            this.actions.handleOperatorItemChange();
        },
        changeSemanticSentence(e) {
            let operatorItem = this.state.operatorItem;
            this.setState({
                semanticSentence: e.target.value,
            });
            operatorItem.semanticSentence = e.target.value;
        },
        querySemanticTags(semanticSentence) {
            if (semanticSentence == null || semanticSentence.trim() === '') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.components.operatorListGather.querySemanticTags
                    )
                );
            }

            let url = Udesk.business.apiPath.concatApiPath(
                '/smartTags/findSemanticList',
                this.props.sdkOptions
            );
            let params = {
                keyword: semanticSentence,
            };
            Udesk.ajax.get(url, params).then(
                (resp) => {
                    if (resp && resp.data) {
                        let data = [];
                        resp.data.forEach((item) => {
                            data.push({
                                key: item.semanticId,
                                semanticName: item.semanticName,
                                sentence: item.sentence,
                                similarity: item.similarity,
                                groupId: item.groupId,
                            });
                        });
                        this.setState({
                            semanticTags: data,
                        });
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        handleSelectChange(operatorItem, key, value) {
            operatorItem.operatorList[0].param[key] = value;
            this.actions.handleOperatorItemChange();
        },
        handleSelectSearch(type, value) {
            this.privates.selectInputValue = value;
            if (type === 'entity') {
                _debounce(this.actions.getInformationEntity, 500)();
            } else if (type === 'expectedValueObject') {
                return;
            }
        },
        conceptWordChanged(operatorItem, value) {
            operatorItem.operatorList[0].param.conceptWord = value;
            operatorItem.operatorList[0].keyword = value != null ? value.id.toString() : null;
            this.actions.handleOperatorItemChange();
        },
        getInformationEntity() {
            let { props } = this;
            let url = Udesk.business.apiPath.concatApiPath(
                `/information-entity/list`,
                props.sdkOptions
            );
            // Udesk.ajax.post(url, this.privates.selectInputValue ? { keyword: this.privates.selectInputValue, activeFlag: 1, systemType: Udesk.data.init.company.systemType } : {}).then(
            Udesk.ajax
                .post(
                    url,
                    this.privates.selectInputValue
                        ? { keyword: this.privates.selectInputValue, activeFlag: 1 }
                        : {}
                )
                .then(
                    (resp) => {
                        this.privates.selectOptionList.entityInfoDetection = resp.data;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        },
        // getConceptWords(){
        //     let {props} = this;
        //     let url = Udesk.business.apiPath.concatApiPath(`/conceptWords/findAll`, props.sdkOptions);
        //     Udesk.ajax.post(url, this.privates.selectInputValue?{keyword: this.privates.selectInputValue}:{}).then(
        //         resp => {
        //             this.privates.selectOptionList.conceptWord = resp.data;
        //             this.actions.update();
        //         },
        //         reason => {
        //             Udesk.ui.notify.error(reason.errorMsg);
        //         }
        //     );
        // },
        getInteractionInitTree() {
            let { props } = this;
            let url = Udesk.business.apiPath.concatApiPath(
                '/interactiveCategories/initTree',
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.privates.treeSelectOptionList = getTreeData(resp.data);
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getKnowledgeInitTree() {
            getKnowledgeCategorysInitTree().then(
                (resp) => {
                    this.privates.treeSelectOptionListKnowledge = getTreeData(resp.data);
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getMultiElements() {
            let { props } = this;
            let url = Udesk.business.apiPath.concatApiPath(
                '/interactiveDatas/multiElements',
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.privates.selectOptionList.element = resp.data;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },

        getBusinessSummary() {
            this.props.taskId &&
                getCallServiceSummaryFields({
                    params: {
                        taskId: this.props.taskId,
                    },
                }).then((res) => {
                    this.privates.selectOptionList.businessSummary = (res.data || []).map((d) => {
                        return {
                            label: d.name,
                            value: d.id,
                        };
                    });
                    this.actions.update();
                });
        },

        validateGrabLength(operatorItem, attribute, value) {
            if (value < 1) {
                operatorItem[attribute] = 1;
                this.actions.handleOperatorItemChange();
            }

            if (
                operatorItem.operatorList[0][attribute] == null ||
                operatorItem.operatorList[0][attribute] === ''
            ) {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.grabLengthNotNull
                );
            }
        },
        validateGrabInterval(operatorItem, attribute, value) {
            if (
                operatorItem.operatorList[0][attribute] == null ||
                operatorItem.operatorList[0][attribute] === ''
            ) {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.grabIntervalNotNull
                );
            }
        },
        validateMinValue(operatorItem, attribute, value) {
            if (value < 0) {
                operatorItem[attribute] = 0;
                this.actions.handleOperatorItemChange();
            }
        },
        validateSpeedValue(operatorItem, attribute, value) {
            let changed = false;
            if (value < 0) {
                operatorItem.operatorList[0][attribute] = 0;
                changed = true;
            }

            if (
                (operatorItem.operatorList[0].wordPerMinite == null ||
                    operatorItem.operatorList[0].wordPerMinite === '') &&
                (operatorItem.operatorList[0].maxWordPerMinite == null ||
                    operatorItem.operatorList[0].maxWordPerMinite === '')
            ) {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.operatorListGather.speedWordNotNull
                );
            }

            if (
                operatorItem.operatorList[0].wordPerMinite &&
                operatorItem.operatorList[0].maxWordPerMinite
            ) {
                if (
                    parseInt(operatorItem.operatorList[0].wordPerMinite, 10) >
                    parseInt(operatorItem.operatorList[0].maxWordPerMinite, 10)
                ) {
                    operatorItem.operatorList[0][attribute] = 0;
                    changed = true;
                    return Udesk.ui.notify.error(
                        UdeskLocales.current.components.operatorListGather
                            .speedWordMinValueLessThanMaxValue
                    );
                }
            }

            if (changed) {
                this.actions.handleOperatorItemChange();
            }
        },
        validateVolumDetectionValue(operatorItem, value) {
            let changed = false;
            if (
                operatorItem.operatorList[0].param.detectionWay ===
                Udesk.enums.volumeDetectionWays.loudness.id
            ) {
                if (value < 0) {
                    operatorItem.operatorList[0].param.detectionValue = 0;
                    changed = true;
                }
                if (value > 200) {
                    operatorItem.operatorList[0].param.detectionValue = 200;
                    changed = true;
                }
            } else {
                if (value < -1000) {
                    operatorItem.operatorList[0].param.detectionValue = -1000;
                    changed = true;
                }
                if (value > 1000) {
                    operatorItem.operatorList[0].param.detectionValue = 1000;
                    changed = true;
                }
            }
            if (changed) {
                this.actions.handleOperatorItemChange();
            }
        },
        applyToCondition(item) {
            let operatorItem = this.privates.operatorItem;
            operatorItem.keyword = item.groupId;
            operatorItem.semanticId = item.key;

            if (!operatorItem.semanticTag) {
                operatorItem.semanticTag = {};
            }

            operatorItem.semanticTag.semanticName = item.semanticName;
            operatorItem.semanticTag.sentence = item.sentence;
            this.actions.handleOperatorItemChange();
        },
        openEditTagWindow(item) {
            let url = `/site/semantic-intelligence/semantic-tags/edit/${item.groupId}`;
            if (this.props.sdkOptions.props.domain) {
                url = this.props.sdkOptions.props.domain + url;
            }
            Udesk.utils.web.openWindow(url, '_blank');
        },
        seleteSemanticName(selectedRowKeys, selectedRows) {
            let operatorItem = this.privates.operatorItem;
            operatorItem.operatorList[0].keyword = selectedRows[0].groupId;
            operatorItem.operatorList[0].semanticId = selectedRows[0].key;

            // if (!operatorItem.semanticTag) {
            //     operatorItem.semanticTag = {};
            // }

            operatorItem.operatorList[0].semanticTag = selectedRows[0];
            // operatorItem.semanticTag.sentence = selectedRows[0].sentence;
            this.actions.handleOperatorItemChange();
        },
        openCreateTagWindow() {
            let url = '/site/semantic-intelligence/semantic-tags/new';
            if (this.props.sdkOptions.props.domain) {
                url = this.props.sdkOptions.props.domain + url;
            }
            Udesk.utils.web.openWindow(url, '_blank');
        },
        regularTestChange(e) {
            this.privates.regularText = e.target.value;
            this.actions.update();
        },
        regularTest(operator) {
            let { regularText } = this.privates;
            let regular = operator.keyword;
            if (regular == null || regular.trim() === '') {
                return Udesk.ui.notify.error(
                    this.locales.components.pages.components.operatorListGather.pleaseEnterRegular
                );
            }
            if (regularText == null || regularText.trim() === '') {
                return Udesk.ui.notify.error(
                    this.locales.components.pages.components.operatorListGather
                        .pleaseEnterRegularText
                );
            }
            let reg = new RegExp(regular);
            let testResult = reg.test(regularText);
            this.privates.testResult = testResult;
            this.actions.update();
        },
    };
    parseProps({ props, prevProps, state, privates, isInitial }) {
        if (privates.operatorItem.operatorType === 13) {
            this.actions.getInformationEntity();
        }
        let operatorItem = JSON.parse(JSON.stringify(props.conditionItem));

        if (!operatorItem.operatorType) {
            operatorItem.operatorType = 0;
        }
        // if (privates.operatorItem.operatorType === 12) {
        //     this.actions.getInteractionInitTree();
        // }
        // if (props.conditionItem && props.conditionItem.operatorList[0].type !== prevProps.conditionItem.operatorList[0].type) {
        if (
            props.conditionItem.operatorList[0].type ===
                Udesk.enums.operatorTypes.questionsAndAnswers.id &&
            privates.intreeFlag
        ) {
            privates.intreeFlag = false;
            this.actions.getInteractionInitTree();
        }
        if (
            props.conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.knowledge.id &&
            privates.intreeFlag2
        ) {
            privates.intreeFlag2 = false;
            this.actions.getKnowledgeInitTree();
        }
        if (
            props.conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.element.id &&
            privates.elementFlag
        ) {
            privates.elementFlag = false;
            this.actions.getMultiElements();
        }
        if (
            props.conditionItem.operatorList[0].type === Udesk.enums.operatorTypes.associate.id &&
            privates.templateFlag
        ) {
            privates.templateFlag = false;
        }
        if (
            props.conditionItem.operatorList[0].type ===
                Udesk.enums.operatorTypes.businessSummary.id &&
            privates.intreeFlag3
        ) {
            privates.intreeFlag3 = false;
            this.actions.getBusinessSummary();
        }
        // }
        return {
            operatorItem: operatorItem,
        };
    }
    init() {
        // this.actions.getInteractionInitTree();
        // this.actions.getMultiElements();
        // this.actions.getAssociate();
        // this.actions.getAssociateOperator();
    }
}

function getTreeData(tree) {
    return tree.map((item) => {
        return {
            key: item.id,
            value: item.id,
            title: item.name,
            children: getTreeData(item.children),
        };
    });
}
// function getColumns(that, operatorItem) {
//     let {
//         actions,
//         locales
//     } = that;
//     let columns = [
//         {
//             title: UdeskLocales.current.components.operatorListGather.semanticName,
//             dataIndex: 'semanticName',
//             width: 120,
//         },
//         {
//             title: UdeskLocales.current.components.operatorListGather.sentence,
//             dataIndex: 'sentence',
//         },
//         {
//             title: UdeskLocales.current.components.operatorListGather.similarity,
//             dataIndex: 'similarity',
//             width: 80,
//         },
//         {
//             title: UdeskLocales.current.components.operatorListGather.actions,
//             dataIndex: 'actions',
//             key: 'x',
//             width: 60,
//             render: (text, record) => {
//                 return (
//                     <div>
//                         <button
//                             className="action-btn delete-btn"
//                             onClick={actions.openEditTagWindow.params(record)}
//                         >
//                             {locales.labels.edit}
//                         </button>
//                         {/* <button
//                             className="action-btn delete-btn"
//                             style={{marginLeft: "5px"}}
//                             onClick={actions.applyToCondition.params(record)}
//                         >
//                             {locales.labels.apply}
//                         </button> */}
//                     </div>
//                 );
//             },
//         },
//     ];
//     that.setState({
//         columns
//     });
// }
