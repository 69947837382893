function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
/**
 * 用于格式化扁平数据到 treeData 数据类型
 *
 * @param {TreeNode[]} [data=[]] 后端接口返回的扁平化数据
 * @param {(string | number | null)} treeRootParentId 根节点的parentId的值，看接口返回的具体值
 * @param {string} [parentIdPropsName='pid'] 表示节点的parentId的字段名
 * @param {string} [idPropsName='id'] 表示节点的id的字段名
 * @return {*}  {TreeMenuProps['treeData']} TreeMenu组件能接收的treeData值
 */
export default function formatTreeDataFromArrayToTree() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var treeRootParentId = arguments.length > 1 ? arguments[1] : undefined;
  var parentIdPropsName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'pid';
  var idPropsName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'id';
  var idMapping = new Map();
  data.forEach(function (el, i) {
    el.isLeaf = true;
    idMapping.set(el[idPropsName], i);
  });
  var root = {
    children: []
  };
  data.forEach(function (el) {
    // 判断根节点
    if (el[parentIdPropsName] === treeRootParentId) {
      root.children.push(el);
    }
    // 用映射表找到父元素
    var parentEl = data[idMapping.get(el[parentIdPropsName])];
    // 把当前元素添加到父元素的`children`数组中
    if (parentEl) {
      parentEl.children = [].concat(_toConsumableArray(parentEl.children || []), [el]);
      parentEl.isLeaf = false;
    }
  });
  return root.children;
}