/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Udesk from '../../udesk';
import locales from '../../udesk/locales/index';
import ReactTreeNode from '../react-tree-node';
import ReactTreeNodeText from '../react-tree-node-text';
import ReactTreeNodeActions from '../react-tree-node-actions';
import ReactTreeActions from '../react-tree-actions';

const DEFAULT_TREE_STATES = {
    actions: {}
};
const CHILD_TREE_CLASSNAME = "child-tree";
const REACT_TREE_CLASSNAME = "react-tree";

class ReactTree extends React.Component {
    //#region defaultProps
    static defaultProps = {
        nodes: [],
        isTreeModeData: true,
        className: "",
        name: "",
        nodeKeyField: "id",
        nodeNameField: "name",
        isShowNodeNum: false,
        nodeNumField: "num",
        childNodesField: "items",
        nodeParentKeyField: "parentId",
        selectedNodes: [],
        selectionMode: "keys",
        theme: "default",
        checkable: false,
        clickable: false,
        showNodeDescriptorIcon: false,
        recursivelyCollapse: false,
        enableHierarchyCascade: true,
        autoCheckUpStrategy: "any",
        autoUncheckUpStrategy: "all",
        autoTriggerActiveNodeChangedEvent: true,
        enableTreeActions: false,
        enableNodeActions: false,
        autoSelectFirstNode: true,
        getTreeBehaviors: null,
        getNodeBehaviors: null,
        getNodeDescriptorIcon: null,
        draggable: false,
        enableSorting: false,
        componentClassName: "ember-tree-dragging",

        nodeComponent: ReactTreeNode,
        nodeTextComponent: ReactTreeNodeText,
        nodeActionsComponent: ReactTreeNodeActions,
        treeActionsComponent: ReactTreeActions,
        nodeCheckBoxComponent: null,
        treeActionsAttrs: null,
        expandedIconClass: "fa fa-caret-down",
        collapsedIconClass: "fa fa-caret-right",
        treeAddIconClass: "fa fa-plus-circle",
        addIconClass: "fa fa-plus-circle",
        editIconClass: "fa fa-edit",
        deleteIconClass: "fa fa-remove",
        dragIconClass: "iconfont icon-three-poles",
        sortText: "",
        sortIconClass: null,
        confirmSortText: "",
        confirmSortIconClass: null,

        enableSort: true,
        enableMove: false,
        enableSortingRollback: true,
        enableMovingRollback: true,
        getDropPositionClass: null,
        isChildNodesSortingLocked: null,
        isNodeMovable: true, //是否可移动
        isMovingTargetNode: false, //成为移动目标节点
        isDragAutoExpaned: false,
        isChildTree: false,
        isNormalizedTreeNodes: false,

        // isChildNodesSortingLocked: function(node) {},
        // isNodeMovable: function(node) {},
        // isMovingTargetNode: function(targetNode, sortingNode) {},

        // tree events
        onNodeClicked: null,
        onExpanded: null,
        onCollapsed: null,
        onActiveNodeChanged: null,
        onTreeAction: null,
        onNodeAction: null,
        onAdding: null,
        onAdded: null,
        onEditing: null,
        onEdited: null,
        onDeleting: null,
        onDeleted: null,
        onSorted: null,
        onMoved: null,
        onSort: null,
        onSortingModeEntered: null,
        onSortingSaved: null,
        onDragLeaved: null,
        onDragOvered: null,

        // onSorted: { targetNode、fromPosition、toPosition、parentNode}

        node: null,

    }
    static propTypes = {
        nodes: PropTypes.array,
        isTreeModeData: PropTypes.boolean,
        className: PropTypes.string,
        name: PropTypes.string,
        nodeKeyField: PropTypes.string,
        nodeNameField: PropTypes.string,
        isShowNodeNum: PropTypes.boolean,
        nodeNumField: PropTypes.string,
        childNodesField: PropTypes.string,
        nodeParentKeyField: PropTypes.string,
        selectedNodes: PropTypes.array,
        selectionMode: PropTypes.string,
        theme: PropTypes.string,
        checkable: PropTypes.boolean,
        clickable: PropTypes.boolean,
        showNodeDescriptorIcon: PropTypes.boolean,
        recursivelyCollapse: PropTypes.boolean,
        enableHierarchyCascade: PropTypes.boolean,
        autoCheckUpStrategy: PropTypes.string,
        autoUncheckUpStrategy: PropTypes.string,
        autoTriggerActiveNodeChangedEvent: PropTypes.boolean,
        enableTreeActions: PropTypes.boolean,
        enableNodeActions: PropTypes.boolean,
        autoSelectFirstNode: PropTypes.boolean,
        draggable: PropTypes.boolean,
        enableSorting: PropTypes.boolean,
        componentClassName: PropTypes.string,
        getTreeBehaviors: PropTypes.func,
        getNodeBehaviors: PropTypes.func,
        getNodeDescriptorIcon: PropTypes.func,

        nodeComponent: PropTypes.object,
        nodeTextComponent: PropTypes.object,
        nodeActionsComponent: PropTypes.object,
        treeActionsComponent: PropTypes.object,
        nodeCheckBoxComponent: PropTypes.object,
        treeActionsAttrs: PropTypes.object,
        expandedIconClass: PropTypes.string,
        collapsedIconClass: PropTypes.string,
        treeAddIconClass: PropTypes.string,
        addIconClass: PropTypes.string,
        editIconClass: PropTypes.string,
        deleteIconClass: PropTypes.string,
        dragIconClass: PropTypes.string,
        sortText: PropTypes.func,
        sortIconClass: PropTypes.string,
        confirmSortText: PropTypes.func,
        confirmSortIconClass: PropTypes.string,

        enableSort: PropTypes.boolean,
        enableMove: PropTypes.boolean,
        enableSortingRollback: PropTypes.boolean,
        enableMovingRollback: PropTypes.boolean,
        getDropPositionClass: PropTypes.func,
        isChildNodesSortingLocked: PropTypes.func,
        isNodeMovable: PropTypes.boolean, //是否可移动
        isMovingTargetNode: PropTypes.boolean, //成为移动目标节点
        isDragAutoExpaned: PropTypes.boolean,
        isChildTree: PropTypes.boolean,
        isNormalizedTreeNodes: PropTypes.boolean,
        // tree events
        onNodeClicked: PropTypes.func,
        onExpanded: PropTypes.func,
        onCollapsed: PropTypes.func,
        onActiveNodeChanged: PropTypes.func,
        onTreeAction: PropTypes.func,
        onNodeAction: PropTypes.func,
        onAdding: PropTypes.func,
        onAdded: PropTypes.func,
        onEditing: PropTypes.func,
        onEdited: PropTypes.func,
        onDeleting: PropTypes.func,
        onDeleted: PropTypes.func,
        onSorted: PropTypes.func,
        onMoved: PropTypes.func,
        onSort: PropTypes.func,
        onSortingModeEntered: PropTypes.func,
        onSortingSaved: PropTypes.func,
        onDragLeaved: PropTypes.func,
        onDragOvered: PropTypes.func,

        // onSorted: { targetNode、fromPosition、toPosition、parentNode}

        node: PropTypes.object,
    }
    //#endregion

    state = {

    }

    privates = {
        fullNodes: null,
        oldNodes: null,
        oldSelectedNodes: null,
        dragNode: null,
        isChildTree: this.props.isChildTree,
        isNormalizedTreeNodes: this.props.isNormalizedTreeNodes,
        treeStates: Udesk.utils.object.deepCopy({}, DEFAULT_TREE_STATES),
        dropPosition: null,
        selectedNodes: this.props.selectedNodes,
        draggable: this.props.draggable,
        sortText: "",
        confirmSortText: ""
    }

    // _isChildTree: false,
    // _isNormalizedTreeNodes: false,
    // _treeStates: Udesk.utils.object.deepCopy({}, DEFAULT_TREE_STATES),

    static computes = {
        _theme: ["privates.isChildTree", function ({ props, state, privates, locales }) {
            let {
                isChildTree
            } = privates;
            if (isChildTree) {
                return "";
            } else {
                return props.theme;
            }
        }],
        _className: ["props.className", "privates.isChildTree", "privates.computes._theme", function ({ props, state, privates, locales }) {
            let {
                className
            } = props;
            let {
                isChildTree,
                "computes": computes
            } = privates;
            let computeClassName = "";
            if (className) {
                computeClassName += (" " + className);
            }
            if (isChildTree) {
                computeClassName += (" " + CHILD_TREE_CLASSNAME);
            } else {
                computeClassName += (" " + REACT_TREE_CLASSNAME);
            }
            if (computes && computes._theme) {
                computeClassName += (" " + computes._theme);
            }
            return computeClassName || "";
        }]
    }

    init() {
        let {
            getTreeBehaviors,
            node,
            isChildTree
        } = this.props;
        this.privates.sortText = this.props.sortText || this.locales.components.reactTree.sortText;
        this.privates.confirmSortText = this.props.confirmSortText || this.locales.components.reactTree.applyLabel;
        if (isChildTree) {
            this.privates.fullNodes = node;
        } else {
            let fullNodes = convertNewNodes.call(this, this.props, this.privates);
            this.privates.fullNodes = fullNodes;
        }
        // this.privates.className=this.props.className + 
        if (typeof getTreeBehaviors === "function") {
            let treeBehaviors = getTreeBehaviors();
            if (treeBehaviors) {
                treeBehaviors = Udesk.utils.object.deepCopy({}, DEFAULT_TREE_STATES, treeBehaviors);
                this.privates.treeStates = treeBehaviors;
                //xinjia
            }
        }
        this.actions.update();
    }
    parseProps({ props, prevProps, state, privates, isInitial }) {
        let {
            nodes,
            node,
            selectedNodes,
        } = props;
        let {
            oldNodes,
            oldSelectedNodes,
            isChildTree
        } = privates;

        if (props.sortText !== prevProps.sortText) {
            this.privates.sortText = this.locales.components.reactTree.sortText;
        }
        if (props.confirmSortText !== prevProps.confirmSortText) {
            this.privates.confirmSortText = this.locales.components.reactTree.applyLabel;
        }
        if (isChildTree) {
            if (props.node !== prevProps.node) {
                this.privates.fullNodes = node;
            }
        } else if (props.nodes !== prevProps.nodes || props.selectedNodes !== prevProps.selectedNodes) {
            this.privates.oldNodes = nodes;
            this.privates.oldSelectedNodes = selectedNodes;

            if (nodes !== oldNodes || selectedNodes !== oldSelectedNodes) {
                let fullNodes = convertNewNodes.call(this, props, privates);
                this.privates.fullNodes = fullNodes;
            }
        }
        this.actions.update();
    }

    actions = {
        onNodeExpanded(node) {
            Udesk.utils.object.set(node.states, "expanded", true);
            this.actions.update();
            this.trigger("onExpanded", node.data);
        },
        onNodeCollapsed(node) {
            let {
                recursivelyCollapse
            } = this.props;
            if (recursivelyCollapse) {
                collapseNodesRecursively(node);
            } else {
                collapseNode(node);
            }
            this.actions.update();
            this.trigger("onCollapsed", node.data);
        },
        onNodeClicked(node) {
            switchNode(node, this);
            this.trigger("onNodeClicked", node.data);
        },
        onNodeChecked(node) {
            let {
                props,
            } = this;
            let {
                nodeKeyField,
                selectedNodes,
                selectionMode,
                autoCheckUpStrategy,
                enableHierarchyCascade
            } = props;

            Udesk.utils.object.set(node.states, "checked", true);
            addSelection(node, selectedNodes, selectionMode, nodeKeyField);

            if (enableHierarchyCascade) {
                // Make sure the selected not is not a root node.
                let parentNode = node.parent;
                while (parentNode) {
                    let needAutoCheckParent = false;
                    switch (autoCheckUpStrategy) {
                        case "any":
                            needAutoCheckParent = true;
                            break;
                        case "all":
                            // Check whether all other siblings are checked. If yes, check the parents recursively.
                            let isAllOthersChecked = true;
                            let siblingNodes = parentNode.nodes;
                            for (let i = 0; i < siblingNodes.length; i++) {
                                let item = siblingNodes[i];
                                if (!item.states.checked) {
                                    isAllOthersChecked = false;
                                    break;
                                }
                            }
                            needAutoCheckParent = isAllOthersChecked;
                            break;
                        default:
                            break;
                    }

                    if (needAutoCheckParent) {
                        Udesk.utils.object.set(parentNode.states, "checked", true);
                        addSelection(parentNode, selectedNodes, selectionMode, nodeKeyField);
                    }
                    parentNode = parentNode.parent;
                }
                this.trigger("onParentNodeChanged");

                traverseTree(node, (childNode) => {
                    Udesk.utils.object.set(childNode.states, "checked", true);
                    addSelection(childNode, selectedNodes, selectionMode, nodeKeyField);
                });
            }

            this.actions.update();
            this.trigger("onChecked", node.data);
        },
        onNodeUnchecked(node) {
            let {
                props,
            } = this;
            let {
                nodeKeyField,
                selectedNodes,
                selectionMode,
                autoUncheckUpStrategy,
                enableHierarchyCascade
            } = props;

            Udesk.utils.object.set(node.states, "checked", false);
            removeSelection(node, selectedNodes, selectionMode, nodeKeyField);

            if (enableHierarchyCascade) {
                // Make sure the selected not is not a root node.
                if (node.parent) {
                    let parentNode = node.parent;
                    while (parentNode) {
                        let needAutoUncheckParent = false;
                        switch (autoUncheckUpStrategy) {
                            case "any":
                                needAutoUncheckParent = true;
                                break;
                            case "all":
                                let siblingNodes = parentNode.nodes;
                                // Check whether all other siblings are checked. If yes, check the parents recursively.
                                let isAllOthersUnchecked = true;
                                for (let i = 0; i < siblingNodes.length; i++) {
                                    let item = siblingNodes[i];
                                    if (item.states.checked) {
                                        isAllOthersUnchecked = false;
                                        break;
                                    }
                                }
                                needAutoUncheckParent = isAllOthersUnchecked;
                                break;
                            default:
                                break;
                        }
                        if (needAutoUncheckParent) {
                            Udesk.utils.object.set(parentNode.states, "checked", false);
                            removeSelection(parentNode, selectedNodes, selectionMode, nodeKeyField);
                        }
                        parentNode = parentNode.parent;
                    }
                    this.trigger("onParentNodeChanged");
                }

                traverseTree(node, (childNode) => {
                    Udesk.utils.object.set(childNode.states, "checked", false);
                    removeSelection(childNode, selectedNodes, selectionMode, nodeKeyField);
                });
            }

            this.actions.update();
            this.trigger("onUnchecked", node.data);
        },
        updateParent() {
            this.actions.update();
            this.trigger("onParentNodeChanged");
        },
        onTreeAction(command, args) {
            switch (command) {
                case "add":
                    this.actions.onAdding(null, args);
                    break;
                default:
                    this.trigger("onTreeAction", command, args);
                    break;
            }
        },
        onNodeAction(command, node, args) {
            switch (command) {
                case "add":
                    this.actions.onAdding(node, args);
                    break;
                case "edit":
                    this.actions.onEditing(node, args);
                    break;
                case "delete":
                    this.actions.onDeleting(node, args);
                    break;
                default:
                    this.trigger("onNodeAction", command, node.data, args);
                    break;
            }
        },
        onAdding(parentNode, args = undefined) {
            let that = this;
            let {
                props,
                privates
            } = this;
            let {
                onAdding
            } = props;

            if (onAdding && typeof onAdding === "function") {
                let treeParentNodeData = null;
                if (parentNode && parentNode.data) {
                    treeParentNodeData = parentNode.data;
                }
                let promise = onAdding(treeParentNodeData, args);
                if (promise === false) {
                    return;
                } else {
                    promise.then(function (data) {
                        //xinjia
                        // addNode.bind(that);
                        // let addNode = that.get("addNode");
                        let newNodeData = (data.node || data);

                        let parentNodeData = null;
                        if (parentNode) {
                            parentNodeData = parentNode.data;
                        } else if (data.parentNode != null) {
                            parentNodeData = data.parentNode;
                        }

                        if (typeof addNode === "function") {
                            // addNode.bind(that, newNodeData, parentNodeData); //bind返回一个新的函数，需要时再调用
                            addNode.call(that, newNodeData, parentNodeData); //立即执行addNode方法
                        }
                    }, function (reason) {

                    }).catch(function (reason) {
                        Udesk.logger.error(reason);
                    });
                }
            }
        },
        onEditing(node, args) {
            let that = this;
            let {
                props,
                privates
            } = this;
            let {
                onEditing
            } = props;
            if (onEditing && typeof onEditing === "function") {
                let promise = onEditing(node.data, args);
                if (promise === false) {
                    return;
                } else {
                    promise.then(function (data) {
                        that.trigger("onEdited", data, node.data, args);
                        setProperties(node.data, data);
                        that.actions.update();
                    }, function (reason) {

                    }).catch(function (reason) {
                        Udesk.logger.error(reason);
                    });
                }
            }
        },
        onDeleting(node, args) {
            let that = this;
            let {
                props,
                privates
            } = this;
            let {
                onDeleting
            } = props;
            if (onDeleting && typeof onDeleting === "function") {
                let promise = onDeleting(node.data, args);
                if (promise === false) {
                    return;
                } else if (promise === true) {
                    removeNode(node, this);
                } else {
                    promise.then(function (data) {
                        removeNode(node, that);
                    }, function (reason) {

                    }).catch(function (reason) {
                        Udesk.logger.error(reason);
                    });
                }
            } else {
                removeNode(node, this);
            }
        },
        onSort() {
            this.privates.draggable = true;
            this.actions.update();
            this.trigger("onSortingModeEntered");
        },
        onSortingSaved() {
            let {
                props,
                privates
            } = this;
            let {
                childNodesField
            } = props;
            let {
                "fullNodes.nodes": nodes
            } = privates;
            this.privates.draggable = false;
            let rootNode = copyNodeData(null, childNodesField);
            let originDataTree = treeWithOriginData(nodes, rootNode[childNodesField], childNodesField);
            this.trigger("onSortingSaved", originDataTree);
        },
        onDragStart(event, node, ...rest) {
            let {
                props,
                privates
            } = this;
            let {
                isChildTree
            } = privates;

            if (isChildTree) {
                this.trigger("onDragStart", event, node, ...rest);
            } else {
                let {
                    isNodeMovable,
                    enableSort,
                    enableMove
                } = props;

                if (!enableSort && !enableMove) {
                    return;
                }

                if (typeof isNodeMovable === "function") {
                    isNodeMovable = isNodeMovable(node.data);
                }

                if (isNodeMovable) {
                    this.privates.dragNode = node;
                    this.actions.update();
                    // this.set("states.dragNode", node);
                } else {
                    return false;
                }
            }
        },
        onDrag(event) {

        },
        onDragOvered(event, node, dropPosition, ...rest) {
            let {
                props,
                privates
            } = this;
            let {
                isChildTree
            } = privates;

            if (isChildTree) {
                this.trigger("onDragOvered", event, node, dropPosition, ...rest);
            } else {
                let {
                    getDragOveredEnable,
                    nodeParentKeyField,
                    enableSort,
                    enableMove,
                    isMovingTargetNode,
                    nodeKeyField,
                    isDragAutoExpaned
                } = props;
                let {
                    "states.dragNode": startDragNode
                } = privates;

                let noAllowed = !node || !startDragNode || (Udesk.utils.object.get(node.data, nodeKeyField) === Udesk.utils.object.get(startDragNode.data, nodeKeyField)) ||
                    Udesk.utils.object.get(node.data, nodeParentKeyField) === Udesk.utils.object.get(startDragNode.data, nodeKeyField) ||
                    (!enableSort && !enableMove);

                if (noAllowed) {
                    return;
                }

                let targetNode = node.data;
                let targetDropPosition = dropPosition;
                let isSameParentNode = false;

                if (node.parent) {
                    isSameParentNode = node.parent && (Udesk.utils.object.get(node.data, nodeParentKeyField) === Udesk.utils.object.get(startDragNode.data, nodeParentKeyField));
                } else {
                    isSameParentNode = (node.parent == null && startDragNode.parent == null);
                }

                let isDragOvered = false;

                if (dropPosition === DROP_POSITION.bottom && node.nodes && node.nodes.length > 0 && node.states.expanded) {
                    isSameParentNode = false;
                    targetNode = node.nodes[0].data;
                    targetDropPosition = -1;
                }

                if (isSameParentNode) {
                    if (enableMove && dropPosition === DROP_POSITION.inner) {
                        isDragOvered = true;
                    }
                    if (enableSort && (dropPosition === DROP_POSITION.top || dropPosition === DROP_POSITION.bottom)) {
                        isDragOvered = true;
                        if (dropPosition === DROP_POSITION.bottom && node.nodes && node.nodes.length > 0 && node.states.expanded) {
                            isDragOvered = false;
                        }
                    }
                } else {
                    if (enableMove) {
                        isDragOvered = true;
                    }
                }

                if (typeof isMovingTargetNode === "function") {
                    isDragOvered = isMovingTargetNode({ targetNode, sortingNode: startDragNode.data, dropPosition: targetDropPosition });
                }

                if (isDragOvered) {
                    Udesk.utils.object.set(node, "states.dragOvering", true);

                    Udesk.utils.object.set(node, "states.dropPosition", dropPosition);
                    if (isDragAutoExpaned && node && node.nodes && node.nodes.length > 0 && dropPosition === DROP_POSITION.bottom) {
                        Udesk.utils.object.set(node.states, "expanded", true);
                    }
                    this.privates.dropPosition = dropPosition;
                    // this.set("states.dropPosition", dropPosition);
                }
            }
        },
        onDragEnter(event, node, ...rest) {
            let {
                props,
                privates
            } = this;
            let {
                isChildTree
            } = privates;
            if (isChildTree) {
                this.trigger("onDragEnter", event, node, ...rest);
            } else {

            }
        },
        onDragLeaved(event, node) {
            Udesk.utils.object.set(node, "states.dropPosition", null);
            Udesk.utils.object.set(node, "states.dragOvering", false);
        },
        onDrop(event, endDragNode, ...rest) {
            let {
                props,
                privates
            } = this;
            let {
                isChildTree
            } = privates;
            if (isChildTree) {
                this.trigger("onDrop", event, endDragNode, ...rest);
            } else {
                let {
                    nodeKeyField,
                    onMoved,
                    onSorted
                } = props;
                let {
                    "states.fullNodes": fullNodes,
                    "states.dragNode": dragNode,
                    dropPosition
                } = privates;

                Udesk.utils.object.set(endDragNode, "states.dropPosition", null);
                if (!Udesk.utils.object.get(endDragNode, "states.dragOvering")) {
                    return;
                }

                Udesk.utils.object.set(endDragNode, "states.dragOvering", false);

                if (dragNode == null) {
                    return;
                }
                let that = this;

                if (fullNodes && fullNodes.nodes && fullNodes.nodes.length > 0) {
                    let rootNode = fullNodes.nodes[0].root;
                    let startDragNode = findNodes(rootNode, item => Udesk.utils.object.get(item.data, nodeKeyField) === Udesk.utils.object.get(dragNode.data, nodeKeyField))[0];
                    let startDragParentNode = startDragNode.parent || rootNode;
                    let endDragParentNode = endDragNode.parent || rootNode;
                    let fromPosition = startDragParentNode.nodes.findIndex(node => {
                        return (Udesk.utils.object.get(node.data, nodeKeyField) === Udesk.utils.object.get(startDragNode.data, nodeKeyField));
                    });
                    let toPosition = endDragParentNode.nodes.findIndex(node => {
                        return (Udesk.utils.object.get(node.data, nodeKeyField) === Udesk.utils.object.get(endDragNode.data, nodeKeyField));
                    });
                    let isSameParentNode = startDragParentNode.nodes.find(node => Udesk.utils.object.get(node.data, nodeKeyField) === Udesk.utils.object.get(endDragNode.data, nodeKeyField));

                    if (isSameParentNode) {
                        //same parent node
                        if (dropPosition === 0) {
                            if (endDragParentNode) {
                                endDragParentNode = endDragNode;
                                toPosition = 0;
                                if (endDragNode.nodes && endDragNode.nodes.length) {
                                    toPosition = endDragNode.nodes.length;
                                }
                            }
                            movedTreeNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode, endDragNode, toPosition, dropPosition });
                        } else {
                            if (dropPosition === 1) {
                                if (endDragNode.nodes && endDragNode.nodes.length > 0 && endDragNode.states.expanded) {
                                    toPosition = 0;
                                    movedTreeNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode: endDragNode, endDragNode, toPosition, dropPosition });
                                } else {
                                    toPosition += 1;
                                    sortedNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode, endDragNode, toPosition, dropPosition });
                                }
                            } else if (dropPosition === -1) {
                                sortedNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode, endDragNode, toPosition, dropPosition });
                            }
                        }
                    } else {
                        if (dropPosition === 0) {
                            toPosition = (endDragNode.nodes && endDragNode.nodes.length) || 0;
                            endDragParentNode = endDragNode;
                        } else {
                            if (dropPosition === 1) {
                                if (endDragNode.nodes && endDragNode.nodes.length > 0 && endDragNode.states.expanded) {
                                    toPosition = 0;
                                    endDragParentNode = endDragNode;
                                } else {
                                    toPosition += 1;
                                }
                            } else if (dropPosition === -1) { }
                        }

                        movedTreeNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode, endDragNode, toPosition, dropPosition });
                    }
                }


                // let nodeKeyField = this.get("nodeKeyField");
                // Ember.set(endDragNode, "states.dragOvering", false);

                // if (startDragNode) {
                //     let startNodeIndex = parentNode.nodes.findIndex((node, index) => {
                //         // jshint eqeqeq:false
                //         return (Ember.get(node.data, nodeKeyField) === Ember.get(startDragNode.data, nodeKeyField));
                //     });
                //     let endNodeIndex = parentNode.nodes.findIndex((node, index) => {
                //         // jshint eqeqeq:false
                //         return (Ember.get(node.data, nodeKeyField) === Ember.get(endDragNode.data, nodeKeyField));

                //     });
                //     if (Ember.get(startDragNode.parent.data, nodeKeyField) === Ember.get(endDragNode.parent.data, nodeKeyField)) {
                //         parentNode.nodes.splice(startNodeIndex, 1);
                //         parentNode.nodes.splice(endNodeIndex, 0, startDragNode);
                //     }
                //     Ember.set(parentNode, "nodes", parentNode.nodes.concat([]));
                //     this.sendAction("onSorted", this.get("nodes"), parentNode, startDragNode, endDragNode);
                // }
                //
            }
        },
    }
}

function convertNewNodes(props, privates) {
    let {
        nodes,
        childNodesField,
        isTreeModeData,
        nodeKeyField,
        nodeParentKeyField,
        selectedNodes,
        selectionMode,
        getNodeBehaviors,
        getNodeDescriptorIcon,
        autoSelectFirstNode,
        autoTriggerActiveNodeChangedEvent
    } = props;
    let {
        isNormalizedTreeNodes
    } = privates;

    if (!Array.isArray(nodes)) {
        nodes = [nodes];
    }
    if (!selectedNodes) {
        selectedNodes = [];
        this.privates.selectedNodes = selectedNodes;
    }

    if (!isNormalizedTreeNodes) {
        let rootNode = createRootNode();
        let newNodes = [];

        if (isTreeModeData) {
            normalizeNodes(rootNode, null, nodes, rootNode.nodes, childNodesField);
            newNodes = rootNode.nodes;
        } else {
            structurizeTreeNodes(rootNode, nodes, nodeKeyField, nodeParentKeyField);
            newNodes = rootNode.nodes;
        }

        if (newNodes.length > 0) {
            if (selectedNodes && selectedNodes.length > 0) {
                traverseTree(newNodes[0].root, (node) => {
                    let selected = !!selectedNodes.find((item) => {
                        return compareSelectionItem(item, node, selectionMode, nodeKeyField);
                    });
                    node.states.checked = selected;
                });
            }
            if (typeof getNodeBehaviors === "function") {
                let activeNodeCounter = 0;
                traverseTree(newNodes[0].root, (node) => {
                    let nodeBehaviors = getNodeBehaviors(node.data);
                    if (nodeBehaviors) {
                        // Udesk.utils.object.deepCopy({}, node.states, nodeBehaviors);
                        Object.assign(node.states, nodeBehaviors);
                        if (nodeBehaviors.active && autoTriggerActiveNodeChangedEvent) {
                            activeNodeCounter++;
                            if (activeNodeCounter === 1) {
                                //Only fire once.
                                if (autoSelectFirstNode) {
                                    this.trigger("onActiveNodeChanged", node.data, null);
                                }
                            }
                        }
                    }
                });
            }
            if (typeof getNodeDescriptorIcon === "function") {
                let activeNodeCounter = 0;
                traverseTree(newNodes[0].root, (node) => {
                    let nodeBehaviors = getNodeDescriptorIcon(node.data);
                    if (nodeBehaviors) {
                        Udesk.utils.object.deepCopy({}, node.states, nodeBehaviors);
                        if (nodeBehaviors.active && autoTriggerActiveNodeChangedEvent) {
                            activeNodeCounter++;
                            if (activeNodeCounter === 1) {
                                //Only fire once.
                                if (autoSelectFirstNode) {
                                    this.trigger("onActiveNodeChanged", node.data, null);
                                }
                            }
                        }
                    }
                });
            }
        }
        return rootNode;
    }
    return nodes;
}

function structurizeTreeNodes(rootNode, nodes, keyField, parentKeyField) {
    let rootNodes = nodes.filter((item) => {
        /* jshint eqeqeq:false */
        return !item[parentKeyField] || item[parentKeyField] == item[keyField];
    });
    let newNodes = [];
    for (let node of rootNodes) {
        newNodes.push(buildSubTreeRecursively(rootNode, null, node, nodes, keyField, parentKeyField));
    }
    rootNode.nodes = newNodes;
}

function buildSubTreeRecursively(rootNode, parentNode, node, originalNodes, keyField, parentKeyField) { // jshint ignore:line
    let newNode = createNewNode(node, parentNode, rootNode);

    if (parentNode) {
        parentNode.nodes.push(newNode);
    }

    let chidNodes = originalNodes.filter((obj) => {
        /* jshint eqeqeq:false */
        return obj[parentKeyField] == node[keyField] && obj[parentKeyField] != obj[keyField];
    });
    if (chidNodes.length > 0) {
        for (let child of chidNodes) {
            buildSubTreeRecursively(rootNode, newNode, child, originalNodes, keyField, parentKeyField);
        }
    }
    return newNode;
}

function normalizeNodes(rootNode, parentNode, nodes, newNodes, childNodesField) {
    for (let i = 0; i < nodes.length; i++) {
        let node = nodes[i];
        let newNode = createNewNode(node, parentNode, rootNode);
        newNodes.push(newNode);

        let childNodes = node[childNodesField];
        if (childNodes) {
            normalizeNodes(rootNode, newNode, childNodes, newNode.nodes, childNodesField);
        }
    }
}

function createNewNode(originalNode, parentNode, rootNode, getNodeBehaviors) {
    let newNode = {
        data: originalNode,
        states: {
            expanded: false,
            active: false,
            checked: false,
            checkable: true,
            disabled: false,
            clickable: true,
            dragable: true,
            actions: {
                visible: true
            }
        },
        nodes: [],
        parent: parentNode,
        root: rootNode
    };
    if (typeof getNodeBehaviors === "function") {
        let nodeBehaviors = getNodeBehaviors(newNode.data);
        if (nodeBehaviors) {
            nodeBehaviors = Udesk.utils.object.deepCopy({}, newNode.states, nodeBehaviors);
            setProperties(newNode.states, nodeBehaviors);
        }
    }
    return newNode;
}

function createRootNode() {
    return createNewNode(null, null, null);
}

function compareSelectionItem(selectionItem, node, selectionMode, nodeKeyField) {
    /* jshint eqeqeq:false */
    if (selectionMode === "keys") {
        return (selectionItem == Udesk.utils.object.get(node.data, nodeKeyField));
    } else {
        return (selectionItem && (selectionItem === node.data || Udesk.utils.object.get(selectionItem, nodeKeyField) == Udesk.utils.object.get(node.data, nodeKeyField)));
    }
}

function addSelection(newNode, selectedNodes, selectionMode, nodeKeyField) {
    let existed = false;
    for (let i = 0; i < selectedNodes.length; i++) {
        let value = selectedNodes[i];
        if (compareSelectionItem(value, newNode, selectionMode, nodeKeyField)) {
            existed = true;
            break;
        }
    }
    if (!existed) {
        let selectionItemValue = newNode.data;
        if (selectionMode === "keys") {
            selectionItemValue = Udesk.utils.object.get(newNode.data, nodeKeyField);
        }
        selectedNodes.push(selectionItemValue);
    }
}

function removeSelection(newNode, selectedNodes, selectionMode, nodeKeyField) {
    for (let i = selectedNodes.length - 1; i >= 0; i--) {
        let value = selectedNodes[i];
        if (compareSelectionItem(value, newNode, selectionMode, nodeKeyField)) {
            selectedNodes.splice(i, 1);
        }
    }
}

function collapseNodesRecursively(node) {
    collapseNode(node);
    if (node.nodes) {
        for (let i = 0; i < node.nodes.length; i++) {
            collapseNodesRecursively(node.nodes[i]);
        }
    }
}

function expandNodesRecursively(node) {
    expandNode.bind(node);
    if (node.nodes) {
        for (let i = 0; i < node.nodes.length; i++) {
            expandNodesRecursively.bind(node.nodes[i]);
        }
    }
}

function expandNode(node) {
    node.states.expanded = true;
    // Ember.set(node.states, "expanded", true);
}

function collapseNode(node) {
    node.states.expanded = false;
}


function findNodes(root, matcher) {
    let matchedNodes = [];
    traverseTree(root, (node) => {
        if (matcher(node)) {
            matchedNodes.push(node);
        }
    });
    return matchedNodes;
}

function traverseTree(root, callback) {
    for (let node of root.nodes) {
        callback(node);
        if (node.nodes) {
            traverseTree(node, callback);
        }
    }
}

function removeNode(targetNode, owner) {
    let parentNode = targetNode.parent;
    if (!parentNode) {
        parentNode = targetNode.root;
    }
    if (parentNode) {
        let index = parentNode.nodes.indexOf(targetNode);
        if (index !== -1) {
            parentNode.nodes.splice(index, 1);

            Udesk.utils.object.set(parentNode, "nodes", parentNode.nodes.concat([]));
            //xinjia
            owner.actions.update();
            // owner.notifyPropertyChange("states.fullNodes.nodes");

            owner.trigger("onDeleted", targetNode.data);
            if (targetNode.states.active) {
                if (parentNode.nodes[index]) {
                    switchNode(parentNode.nodes[index], owner);
                } else if (parentNode.nodes[index - 1]) {
                    switchNode(parentNode.nodes[index - 1], owner);
                } else {
                    switchNode(parentNode, owner);
                }
            }
        }
    }
}

function switchNode(targetNode, owner) {
    let previousNode = findNodes(targetNode.root, item => item.states.active)[0];
    if (targetNode !== previousNode) {
        if (previousNode) {
            Udesk.utils.object.set(previousNode.states, "active", false);
        }

        Udesk.utils.object.set(targetNode.states, "active", true);
        owner.trigger("onActiveNodeChanged", targetNode.data, (previousNode ? previousNode.data : null));
        owner.actions.update();
    }
}

function copyNodeData(node, childNodesField) {
    let nodeData = node ? Udesk.utils.object.deepCopy({}, node.data) : {};
    nodeData[childNodesField] = [];
    return nodeData;
}

function treeWithOriginData(nodes, newNodes, childNodesField) {
    for (let i = 0; i < nodes.length; i++) {
        let node = nodes[i];
        let newNode = copyNodeData(node, childNodesField);
        newNodes.push(newNode);

        let childNodes = node.nodes;
        if (childNodes) {
            treeWithOriginData(childNodes, newNode[childNodesField], childNodesField);
        }
    }
}

function movedTreeNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode, endDragNode, toPosition, dropPosition } = {}) {
    let {
        props,
        privates
    } = that;
    let {
        onMoved,
        enableMovingRollback,
        nodeKeyField,
        getNodeBehaviors
    } = props;
    if (onMoved && typeof onMoved === "function") {
        let promise = onMoved({
            targetNode: startDragNode.data,
            fromParentNode: startDragParentNode.data,
            fromPosition,
            toParentNode: endDragParentNode.data,
            toEndNode: endDragNode.data,
            toPosition,
            dropPosition
        });
        if (promise === false) {
            return;
        } else if (promise === true) {
            handleMoveTreeNode(...arguments);
        } else if (promise && typeof promise.then === 'function') {
            handleMoveTreeNode(...arguments);
            promise.then(function (data) {
                // update node
                let rootNode = startDragNode.root;
                let newParentNode = endDragParentNode;
                if (dropPosition === 0) {
                    newParentNode = endDragNode;
                }
                let newNode = createNewNode(data, newParentNode, rootNode, getNodeBehaviors);
                if (startDragNode.nodes && startDragNode.nodes.length > 0) {
                    newNode.nodes = [].concat(startDragNode.nodes);
                }
                let changedTargetNode = findNodes(rootNode, item => Udesk.utils.object.get(item.data, nodeKeyField) === Udesk.utils.object.get(newNode.data, nodeKeyField))[0];
                setProperties(changedTargetNode, newNode);

            }, function (reason) { }).catch(function (reason) {
                if (enableMovingRollback) {
                    startDragParentNode.nodes.splice(fromPosition, 0, startDragNode);
                    if (dropPosition === 0) {
                        removeObject(endDragNode.nodes, startDragNode);
                        // endDragNode.nodes.removeObject(startDragNode);
                    } else {
                        endDragParentNode.nodes.splice(toPosition + 1, 1);
                    }
                }
                Udesk.logger.error(reason);
            });
        } else {
            handleMoveTreeNode(...arguments);
        }
    } else {
        handleMoveTreeNode(...arguments);
    }
    that.actions.update();
}

function handleMoveTreeNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode, endDragNode, toPosition, dropPosition } = {}) {
    startDragParentNode.nodes.splice(fromPosition, 1);

    if (dropPosition === 0) {
        endDragNode.nodes.push(startDragNode);
    } else {
        endDragParentNode.nodes.splice(toPosition, 0, startDragNode);
    }

    Udesk.utils.object.set(startDragParentNode, "nodes", startDragParentNode.nodes.concat([]));
    Udesk.utils.object.set(endDragParentNode, "nodes", endDragParentNode.nodes.concat([]));
}

function sortedNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode, endDragNode, toPosition, dropPosition } = {}) {
    let onSorted = that.get("onSorted");
    let enableSortingRollback = that.get("enableSortingRollback");
    if (onSorted && typeof onSorted === "function") {
        let promise = onSorted({
            targetNode: startDragNode.data,
            toEndNode: endDragNode.data,
            fromPosition,
            parentNode: endDragParentNode.data,
            toPosition,
            dropPosition
        });
        if (promise === false) {
            return;
        } else if (promise === true) {
            handleSortTreeNode(...arguments);
        } else if (promise && typeof promise.then === 'function') {
            handleSortTreeNode(...arguments);
            promise.then(function (data) {
                //update node

            }, function (reason) {

            }).catch(function (reason) {
                if (enableSortingRollback) {
                    endDragParentNode.nodes.splice(toPosition, 1);
                    if (fromPosition < toPosition) {
                        toPosition += 1;
                    }
                    startDragParentNode.nodes.splice(fromPosition, 0, startDragNode);
                }
                Udesk.logger.error(reason);
            });
        } else {
            handleSortTreeNode(...arguments);
        }
    } else {
        handleSortTreeNode(...arguments);
    }
}

function handleSortTreeNode(that, { startDragParentNode, startDragNode, fromPosition, endDragParentNode, endDragNode, toPosition, dropPosition } = {}) {
    startDragParentNode.nodes.splice(fromPosition, 1);
    if (fromPosition < toPosition) {
        toPosition -= 1;
    }
    endDragParentNode.nodes.splice(toPosition, 0, startDragNode);
    Udesk.utils.object.set(startDragParentNode, "nodes", startDragParentNode.nodes.concat([]));
    Udesk.utils.object.set(endDragParentNode, "nodes", endDragParentNode.nodes.concat([]));
}

// //没看到哪里使用的
// function expandAll() {
//     let {
//         privates
//     } = this;
//     if (!privates.fullNodes) {
//         return false;
//     }
//     let nodes = privates.fullNodes.nodes;
//     if (nodes && nodes.length > 0) {
//         nodes.forEach(node => {
//             expandNodesRecursively(node);
//         })
//     }
// }
// //没看到哪里使用的
// function collapseAll() {
//     let {
//         privates
//     } = this;
//     if (!privates.fullNodes) {
//         return false;
//     }
//     let nodes = privates.fullNodes.nodes;
//     if (nodes && nodes.length > 0) {
//         nodes.forEach(node => {
//             collapseNodesRecursively(node);
//         })
//     }
// }

function addNode(nodeData, parentNodeData) {
    if (nodeData == null) {
        return;
    }
    let {
        privates,
        props,
    } = this;
    let {
        fullNodes
    } = privates;
    let {
        nodeKeyField,
        getNodeBehaviors
    } = props;
    // let fullNodes = this.get("states.fullNodes");
    // let nodeKeyField = this.get("nodeKeyField");

    let addNewNode = null;
    let newNode = null;
    let isAddingRoot = false;
    let newParentNode = fullNodes;

    if (fullNodes && fullNodes.nodes && fullNodes.nodes.length > 0) {
        let nodes = fullNodes.nodes;
        newParentNode = nodes[0].root;
        addNewNode = findNodes(nodes[0].root, item => Udesk.utils.object.get(item.data, nodeKeyField) === Udesk.utils.object.get(nodeData, nodeKeyField))[0];
        if (parentNodeData != null) {
            if (Udesk.utils.object.get(parentNodeData, nodeKeyField) != null) {
                newParentNode = findNodes(nodes[0].root, item => Udesk.utils.object.get(item.data, nodeKeyField) === Udesk.utils.object.get(parentNodeData, nodeKeyField))[0];
            } else {
                newParentNode = findNodes(nodes[0].root, item => Udesk.utils.object.get(item.data, nodeKeyField) === parentNodeData)[0];
            }
            isAddingRoot = true;
            newNode = createNewNode(nodeData, newParentNode, newParentNode.root, getNodeBehaviors);
        } else {
            isAddingRoot = true;
            newNode = createNewNode(nodeData, null, newParentNode, getNodeBehaviors);
        }

        if (newNode == null) {
            return;
        }
    } else {
        isAddingRoot = true;
        newNode = createNewNode(nodeData, null, newParentNode, getNodeBehaviors);
    }

    if (addNewNode == null) {
        addNewNode = newNode;
    } else {
        addNewNode = {
            data: newNode.data,
            nodes: addNewNode.nodes,
            parent: newNode.parent,
            root: newNode.root,
            states: newNode.states,
        };
    }

    if (!Array.isArray(newParentNode.nodes)) {
        newParentNode.nodes = [];
    }

    newParentNode.nodes.push(addNewNode);
    Udesk.utils.object.set(newParentNode, "nodes", newParentNode.nodes.concat([]));

    if (isAddingRoot) {
        // this.notifyPropertyChange("states.fullNodes.nodes");
        // xinjia
        this.actions.update();
    } else if (!newParentNode.states.expanded) {
        Udesk.utils.object.set(newParentNode.states, "expanded", true);
    }

    this.trigger("onAdded", addNewNode.data, newParentNode.data);
}

// function deleteNode(nodeData) {
//     if (nodeData == null) {
//         return;
//     }

//     let nodes = this.get("states.fullNodes.nodes");
//     let nodeKeyField = this.get("nodeKeyField");
//     let nodeParentKeyField = this.get("nodeParentKeyField");
//     let newNode = null;

//     if (Ember.get(nodeData, nodeKeyField) != null && Ember.get(nodeData, nodeParentKeyField) != null) {
//         newNode = findNodes(nodes[0].root, item => Ember.get(item.data, nodeKeyField) === Ember.get(nodeData, nodeKeyField) && Ember.get(item.data, nodeParentKeyField) === Ember.get(nodeData, nodeParentKeyField))[0];
//     } else if (Ember.get(nodeData, nodeKeyField) != null) {
//         newNode = findNodes(nodes[0].root, item => Ember.get(item.data, nodeKeyField) === Ember.get(nodeData, nodeKeyField))[0];
//     } else {
//         newNode = findNodes(nodes[0].root, item => Ember.get(item.data, nodeKeyField) === nodeData && Ember.get(item.data, nodeParentKeyField) === Ember.get(nodeData, nodeParentKeyField))[0];
//     }
//     if (newNode) {
//         removeNode(newNode, this);
//     }
// }

// function dragOver() {
//     return false;
// }

// function updateNodeBehavior(nodeData, behaviors) {
//     let {
//         "states.fullNodes": fullNodes,
//         nodeKeyField,
//     } = this.getProperties(
//         "states.fullNodes",
//         "nodeKeyField",
//     );

//     if (fullNodes && fullNodes.nodes && fullNodes.nodes.length > 0) {
//         let nodes = fullNodes.nodes;
//         let nodeRoot = (nodes && nodes[0]) ? nodes[0].root : null;
//         if (!nodeRoot) {
//             return;
//         }
//         let targetNode = findNodes(nodeRoot, item => Ember.get(item.data, nodeKeyField) === Ember.get(nodeData, nodeKeyField))[0];
//         if (targetNode) {
//             let states = Ember.copy(targetNode.states, true);
//             Ember.set(targetNode, "states", $.extend({}, states, behaviors));
//         }
//     }
// }

function setProperties(obj, data) {
    if (typeof data !== "object") {
        return obj;
    }
    for (var i in data) {
        if (Object.prototype.hasOwnProperty.call(data,i)) {
            // Udesk.utils.object.set(obj, i, Udesk.utils.object.deepCopy(data[i]));
            Udesk.utils.object.set(obj, i, data[i]);
        }
    }
    return obj;
}

function removeObject(objectArray, obj) {
    let index = objectArray.indexOf(obj);
    if (index !== -1) {
        return objectArray.splice(index, 1);
    }
    return objectArray;
}

export default ReactTree;