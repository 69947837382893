import React from 'react';
import PropTypes from 'prop-types';
import Udesk from 'Udesk';
import { getSystemModule } from 'Udesk/system/subApp';

export default class SmartWordsLibraryUploadModalComponent extends React.Component {
    static defaultProps = {
        visible: false, //是否显示对话框
        title: '', //标题
        onCancel: null, //关闭modal回调
        headerClassName: ''
    };
    static propTypes = {
        visible: PropTypes.bool,
        title: PropTypes.string,
        onCancel: PropTypes.func,
        headerClassName: PropTypes.string
    };
    privates = {};
    actions = {
        upload(e) {
            // 不需要使用this.privates.uploadingFile
            let { locales } = this;
            if (e.target.files && e.target.files.length === 0) {
                return;
            }
            let targetFile = e.target.files[0];
            let fileName = fileType(targetFile.name);
            if (!fileName) {
                return;
            } else if (fileName !== 'json') {
                return Udesk.ui.notify.error(Udesk.utils.string.format(locales.labels.fileTypeHolder, 'json'));
            }
            let id = new Date().getTime();

            // 获取远程服务器权限
            Udesk.app.fileStorage.upload(targetFile, {
                token: new Promise((resolve, reject) => {
                    Udesk.business
                        .getServerAuthorization({
                            type: 4
                        })
                        .then(
                            function(resp) {
                                // resolve(Object.assign(resp, {
                                //     sourceType: 4,
                                //     objectId: 0
                                // }));
                                resolve(resp);
                            },
                            function(reason) {
                                reject(reason);
                            }
                        );
                }),
                successCallback: this.actions.uploadFileSuccess.bind(this, id),
                errorCallback: this.actions.uploadFileError.bind(this, id)
            });
        },
        uploadFileSuccess(id, file, fileData, options) {
            let { sdkOptions } = this.props;

            // let {
            //     boardName
            // } = this.props;

            // let type = Udesk.enums.smartWordsLibraryMenus.get(boardName).id;

            let url = Udesk.business.apiPath.concatApiPath(`inspectionTasks/import`, sdkOptions);

            let params = {
                keyName: fileData.key,
                fileName: file.name,
                fileSize: file.size,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(this.locales.components.pages.dataMigration.upload.uploadSuccess);
                    this.trigger('onCancel');
                    this.trigger('makeListRefresh');
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.components.pages.dataMigration.upload.postUploadDataFail
                    );
                }
            );
        },
        uploadFileError(id, file, fileData, options) {}
    };
}
function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
