let speechRecognitionSwitchType = [
    { id: 1, key: 'asr' },
    { id: 2, key: 'correction' },
];

let speechRecognitionSecondaryType = [
    { id: 1, key: 'onOff' },
    { id: 2, key: 'config' },
    { id: 4, key: 'asrModel' },
    { id: 5, key: 'canAsrModel' },
    { id: 6, key: 'enAsrModel' },
];

let nlpModelLanguages = [
    { id: 0, key: 'chinese' },
    { id: 1, key: 'english' },
    { id: 2, key: 'japanese' },
];

let asrDataFilterSecondaryType = [
    { id: 3, key: 'default' }, // ASR默认语言配置
    { id: 4, key: 'switch' }, // 高级配置开关
    { id: 5, key: 'filter' }, // 高级配置
];

export default {
    speechRecognitionSwitchType,
    speechRecognitionSecondaryType,
    nlpModelLanguages,
    asrDataFilterSecondaryType,
};
