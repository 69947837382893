import Page from 'Component/common/udesk-qa-page-layout';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Udesk from 'Udesk';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';
import ExecutionFilter from '../components/ExecutionFilter';
import CategoryCourseTreeSelect from 'Component/pages/components/coach/CategoryCourseTreeSelect';
import { postIntelligentPartnerTaskInfosStatisticsFindAll } from 'src/api/intelligentPartnerTaskInfos/statistics/findAll';
import { Form, Tooltip, Typography, Modal, Radio } from 'udesk-ui';
import { deleteIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { useTaskCenterPageInfoCache } from '../components/cache';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import { useTableBtn } from 'src/util/hook/table';
import { getIntelligentPartnerTaskInfosByTaskIdReScoreHasChecked } from 'src/api/intelligentPartnerTaskInfos/{taskId}/reScore/hasChecked';
import { getIntelligentPartnerTaskInfosByTaskIdReScoring } from 'src/api/intelligentPartnerTaskInfos/{taskId}/reScoring';
import { getSystemModule } from 'Udesk/system/subApp';
import './index.less';
import { beautifulTime } from 'src/util/moment';
import { HelpOutlined } from '@udesk/icons';
import { linkTo } from 'src/util/link';
import { postIntelligentPartnerTaskInfosStatisticsFindAllExport } from 'src/api/intelligentPartnerTaskInfos/statistics/findAll/export';
import { isBoolean, isDefined } from 'src/util/core';

const DEFAULT_PAGE = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const CoverStatus = {
    NotCover: 0,
    Cover: 1,
};

const createRenderFunction = (unit = '') => {
    return (data) => {
        if (isDefined(data)) {
            return `${data + unit}`;
        } else {
            return '--';
        }
    };
};

const TaskCenter: React.FC<any> = (props) => {
    const { history } = props;
    const [filterForm] = Form.useForm();
    const [cache, saveCache] = useTaskCenterPageInfoCache();
    const [pagination, setPagination] = useState<any>(cache?.pagination || DEFAULT_PAGE);
    const [dataSource, setDataSource] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const queryParams = new URLSearchParams(props.location.search);
    const lessonId = parseInt(queryParams.get('lessonId') || '') || undefined;
    const { submitFilterForm } = useTableBtn('task-center-list-body');
    const [exportParams, setExportParams] = useState<any>();
    const [showColumnKeys, setShowColumnKeys] = useState<string[]>([
        'taskName',
        'totalCount',
        'executeCount',
        'completeCount',
        'commitCount',
        'excellentCount',
        'executeRate',
        'completeRate',
        'commitRate',
        'excellentRate',
        'participateCompleteRate',
        'participateCommitRate',
        'participateExcellentRate',
        'accumulatedCostTime',
        'avgCostTime',
        'accumulatedTimes',
        'avgScore',
        'highestScore',
        'craftAvgScore',
        'craftTotalScore',
        'flowAvgScore',
        'flowTotalScore',
        'emotionAvgScore',
        'emotionTotalScore',
        'speedAvgScore',
        'speedTotalScore',
        'taskType',
        'lessonName',
        'taskTime',
        'taskStatus',
        'publishTime',
        'actions',
    ]);
    const { ...courseTreeSelectProps } = CategoryCourseTreeSelect.useTreeSelect({ isAdmin: true });

    useEffect(() => {
        const $body = $('.task-center-list-body .udesk-qa-ui-common-filter').on(
            'click',
            '.udesk-qa-ui-btn-default',
            (event, isTree) => {
                saveCache({
                    filter: {},
                    pagination: DEFAULT_PAGE,
                });
                if (!isTree) {
                    submitFilterForm();
                }
                return !!isTree;
            }
        );

        return () => {
            $body.off('click');
        };
    }, [submitFilterForm, saveCache]);

    const TaskStatusOptions = useMemo(() => {
        return [
            // {
            //     label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskTypeAll,
            //     value: 4,
            // },
            {
                label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskStatusNotStarted,
                value: 1,
            },
            {
                label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskStatusRunning,
                value: 2,
            },
            {
                label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskStatusEnd,
                value: 3,
            },
            {
                label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskStatusInvalid,
                value: 0,
            },
        ];
    }, []);

    const TaskTypeOptions = useMemo(() => {
        return [
            {
                label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskTypePractice,
                value: 1,
            },
            {
                label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskTypeTest,
                value: 2,
            },
            // {
            //     label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskTypeAll,
            //     value: 3,
            // },
        ];
    }, []);

    const onEdit = useCallback(
        (id: any) => {
            const routeName = 'coachTaskCenterEdit';
            const pathParams = {
                id,
            };
            Udesk.ui.routing.transitionTo({
                history,
                routeName,
                pathParams,
            });
        },
        [history]
    );

    const onShow = useCallback(
        (id: any) => {
            const routeName = 'coachLearningCenterDetailOverview';
            const pathParams = {
                id,
            };
            Udesk.ui.routing.transitionTo({
                history,
                routeName,
                pathParams,
            });
        },
        [history]
    );

    const isCover = useRef(CoverStatus.NotCover);

    const onRegrade = (item: any) => {
        getIntelligentPartnerTaskInfosByTaskIdReScoreHasChecked({
            segments: { taskId: item.id },
        }).then((res) => {
            if (isBoolean(res.data)) {
                const hasManual = res.data;

                Modal.confirm({
                    title: hasManual
                        ? /* 重新评分表示将按照最新画布的得分规则进行匹配，当前任务存在人工已复核的评分数据，是否要覆盖分数 */ UdeskLocales[
                              'current'
                          ].pages.coach.taskCenter.list.index
                              .reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores
                        : /* 重新评分表示将按照最新画布的得分规则进行匹配，会覆盖当前得分结果 */ UdeskLocales[
                              'current'
                          ].pages.coach.taskCenter.list.index
                              .reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults,
                    content: hasManual ? (
                        <Radio.Group
                            onChange={(v) => {
                                isCover.current = v.target.value;
                            }}
                            defaultValue={CoverStatus.NotCover}
                        >
                            <Radio value={CoverStatus.NotCover}>
                                {/* 不覆盖 */}
                                {
                                    UdeskLocales['current'].pages.coach.taskCenter.list.index
                                        .notCovered
                                }
                            </Radio>
                            <Radio value={CoverStatus.Cover}>
                                {/* 覆盖 */}
                                {UdeskLocales['current'].pages.coach.taskCenter.list.index.cover}
                            </Radio>
                        </Radio.Group>
                    ) : null,
                    okText: UdeskLocales['current'].labels.confirm,
                    onOk: () => {
                        getIntelligentPartnerTaskInfosByTaskIdReScoring({
                            segments: { taskId: item.id },
                            params: {
                                systemModule: getSystemModule(),
                                coverCheckRecord: isCover.current,
                            },
                        })
                            .then(() => {
                                Udesk.ui.notify.success(
                                    /* 开始执行重新评分，可跳转至任务中心查看进度 */ UdeskLocales[
                                        'current'
                                    ].pages.coach.taskCenter.list.index
                                        .startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress
                                );
                            })
                            .finally(() => {
                                isCover.current = CoverStatus.NotCover;
                            });
                    },
                    onCancel: () => {
                        isCover.current = CoverStatus.NotCover;
                    },
                });
            }
        });
    };

    const onDelete = (id: any) => {
        deleteIntelligentPartnerTaskInfosById({
            segments: { id },
            successMsg: UdeskLocales['current'].pages.gong.coachTaskCenter.list.deleteSuccess,
        }).then(() => {
            (
                document.getElementsByClassName('udesk-qa-ui-btn udesk-qa-ui-btn-primary')[0] as any
            )?.click?.();
        });
    };

    const onTableRequest = (params) => {
        saveCache(params);
        setLoading(true);

        const reqData: any = {
            pageNum: params.pagination.current, // 页数
            pageSize: params.pagination.pageSize, // 每页条数
            taskName: params.filter?.taskName, // 任务名称
            lessonIds: params.filter?.lessonIds, // 课程对应的ID数组
            taskTypes: params.filter?.taskTypes, // 任务类型数组 1-练习 2-考试 3-全部
            taskStatuses: params.filter?.taskStatuses, // 任务状态数组 0-失效 1-未开始 2-进行中 3-已结束 4-全部
            executeType: params.filter?.execetionInfo?.executeType, // 执行情况筛选，执行类型 1-执行率 2-完成率 3-达标率 4-优秀率
            executeOperate: params.filter?.execetionInfo?.executeOperate, // 执行情况筛选 操作符 eq - 等于 more_than_eq - 大于等于 less_than_eq 小于等于
            executeValue: params.filter?.execetionInfo?.executeValue, // 执行情况筛选 达标率等数值
        };

        if (reqData.lessonIds) {
            reqData.courseCategoryIds = reqData.lessonIds?.categories || [];
            reqData.lessonIds = reqData.lessonIds?.courses || [];
        }

        if (lessonId) {
            reqData.lessonIds = [lessonId];
        }
        setExportParams({
            ...reqData,
            exportColumns: showColumnKeys.filter((key) => key !== 'actions'),
        });

        postIntelligentPartnerTaskInfosStatisticsFindAll(reqData)
            .then((resp) => {
                setDataSource(
                    (resp?.data || []).map((item) => {
                        let taskTimeVal =
                            UdeskLocales['current'].pages.gong.coachTaskCenter.list.unlimitedTime;
                        if (item.taskStartTime && item.taskEndTime) {
                            taskTimeVal = `${moment(item.taskStartTime).format(
                                'YYYY-MM-DD HH:mm'
                            )} ${
                                UdeskLocales['current'].pages.gong.coachTaskCenter.list.to
                            } ${moment(item.taskEndTime).format('YYYY-MM-DD HH:mm')}`;
                        }
                        return {
                            ...item,
                            taskTime: taskTimeVal,
                        };
                    })
                );
                setPagination({
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum || 1,
                    pageSize: resp.paging?.pageSize,
                });
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const filter: CommonFilterProps = useMemo(() => {
        return {
            form: filterForm,
            customComponents: {
                ExecutionFilter,
                CategoryCourseTreeSelect,
            },
            fields: [
                {
                    name: 'taskName',
                    label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskName,
                    type: 'Input',
                    props: {
                        placeholder: UdeskLocales['current'].labels.pleaseEnter,
                        style: { width: 200 },
                    },
                },
                {
                    name: 'lessonIds',
                    label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.lessonName,
                    type: 'CategoryCourseTreeSelect',
                    props: {
                        ...courseTreeSelectProps,
                        multiple: true,
                        maxTagCount: 1,
                        style: {
                            width: 200,
                        },
                    },
                },
                {
                    name: 'taskTypes',
                    label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskType,
                    type: 'Select',
                    props: {
                        placeholder: UdeskLocales['current'].labels.pleaseSelect,
                        options: TaskTypeOptions,
                        style: { width: 200 },
                        mode: 'multiple',
                        // maxTagCount: 2,
                        allowClear: true,
                        showArrow: true,
                        // showSearch: true,
                    },
                },
                {
                    name: 'taskStatuses',
                    label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskStatus,
                    type: 'Select',
                    props: {
                        options: TaskStatusOptions,
                        style: { width: 200 },
                        mode: 'multiple',
                        // maxTagCount: 2,
                        allowClear: true,
                        showArrow: true,
                        placeholder: UdeskLocales['current'].labels.pleaseSelect,
                    },
                },
                {
                    name: 'execetionInfo',
                    label: /* 参与情况 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                        .participationSituation,
                    type: 'ExecutionFilter',
                },
            ].filter((item) => {
                // 课程页面来的数据将不再显示前一个筛选项
                if (lessonId) {
                    return item.name !== 'lessonIds';
                }
                return true;
            }) as CommonFilterProps['fields'],
            initialValues: {
                ...(cache?.filter || {}),
            },
        };
    }, [
        filterForm,
        courseTreeSelectProps,
        TaskTypeOptions,
        TaskStatusOptions,
        cache?.filter,
        lessonId,
    ]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                key: 'taskName',
                dataIndex: 'taskName',
                title: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskName,
                width: 160,
                fixed: 'left',
                render: (text, _) => (
                    <Typography.Paragraph ellipsis={{ rows: 2, tooltip: text }}>
                        {text}
                    </Typography.Paragraph>
                ),
            },
            {
                key: 'totalCount',
                dataIndex: 'totalCount',
                width: 130,
                title: (
                    <TitleTooltip
                        title={
                            /* 应参与人数 */ UdeskLocales['current'].pages.coach.taskCenter.list
                                .index.numberOfParticipants
                        }
                        tooltips={
                            /* 此任务选择的练习对象人数求和 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumOfTheNumberOfPracticeSubjectsSelectedForThisTask
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'executeCount',
                dataIndex: 'executeCount',
                width: 110,
                title: (
                    <TitleTooltip
                        title={
                            /* 参与人数 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .numberOfParticipants
                        }
                        tooltips={
                            /* 已参与任务的人数求和 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.sumOfTheNumberOfParticipantsInTheTask
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'completeCount',
                dataIndex: 'completeCount',
                width: 110,
                title: (
                    <TitleTooltip
                        title={
                            /* 完成人数 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .numberOfCompletedPersonnel
                        }
                        tooltips={
                            /* 满足任务完成要求的人数求和 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'commitCount',
                dataIndex: 'commitCount',
                width: 110,
                title: (
                    <TitleTooltip
                        title={
                            /* 达标人数 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .numberOfQualifiedPersonnel
                        }
                        tooltips={
                            /* 满足任务得分达标的人数求和 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'excellentCount',
                dataIndex: 'excellentCount',
                width: 110,
                title: (
                    <TitleTooltip
                        title={
                            /* 优秀人数 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .excellentPersonnel
                        }
                        tooltips={
                            /* 满足任务得分优秀的人数求和 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.sumUpTheNumberOfPeopleWithExcellentTaskScores
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'executeRate',
                dataIndex: 'executeRate',
                width: 100,
                title: (
                    <TitleTooltip
                        title={
                            /* 参与率 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .participationRate
                        }
                        tooltips={
                            /* 已应参人数 / 应参与人数 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.numberOfParticipantsNumberOfParticipants
                        }
                    />
                ),
                render: createRenderFunction('%'),
            },
            {
                key: 'completeRate',
                dataIndex: 'completeRate',
                width: 100,
                title: (
                    <TitleTooltip
                        title={UdeskLocales['current'].pages.gong.coachTaskCenter.list.completeRate}
                        tooltips={
                            /* 完成人数 / 应参与人数 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.numberOfCompletedNumberOfParticipants
                        }
                    />
                ),
                render: createRenderFunction('%'),
            },
            {
                key: 'commitRate',
                dataIndex: 'commitRate',
                width: 100,
                title: (
                    <TitleTooltip
                        title={UdeskLocales['current'].pages.gong.coachTaskCenter.list.commitRate}
                        tooltips={
                            /* 达标人数 / 应参与人数 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate
                        }
                    />
                ),
                render: createRenderFunction('%'),
            },
            {
                key: 'excellentRate',
                dataIndex: 'excellentRate',
                width: 100,
                title: (
                    <TitleTooltip
                        title={
                            UdeskLocales['current'].pages.gong.coachTaskCenter.list.excellentRate
                        }
                        tooltips={
                            /* 优秀人数 / 应参与人数 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.excellentNumbernumberOfParticipants
                        }
                    />
                ),
                render: createRenderFunction('%'),
            },
            {
                key: 'participateCompleteRate',
                dataIndex: 'participateCompleteRate',
                width: 130,
                title: (
                    <TitleTooltip
                        title={
                            /* 参与完成率 */ UdeskLocales['current'].pages.coach.taskCenter.list
                                .index.participationCompletionRate
                        }
                        tooltips={
                            /* 完成人数 / 参与人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                .list.index.numberOfCompletedParticipated
                        }
                    />
                ),
                render: createRenderFunction('%'),
            },
            {
                key: 'participateCommitRate',
                dataIndex: 'participateCommitRate',
                width: 130,
                title: (
                    <TitleTooltip
                        title={
                            /* 参与达标率 */ UdeskLocales['current'].pages.coach.taskCenter.list
                                .index.participationComplianceRate
                        }
                        tooltips={
                            /* 达标人数 / 参与人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                .list.index.numberOfQualifiedPersonnelnumberOfParticipants
                        }
                    />
                ),
                render: createRenderFunction('%'),
            },
            {
                key: 'participateExcellentRate',
                dataIndex: 'participateExcellentRate',
                width: 130,
                title: (
                    <TitleTooltip
                        title={
                            /* 参与达优率 */ UdeskLocales['current'].pages.coach.taskCenter.list
                                .index.participationRateForAchievingExcellence
                        }
                        tooltips={
                            /* 优秀人数 / 参与人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                .list.index.excellentNumbernumberOfParticipants
                        }
                    />
                ),
                render: createRenderFunction('%'),
            },
            {
                key: 'accumulatedCostTime',
                dataIndex: 'accumulatedCostTime',
                width: 110,
                title: (
                    <TitleTooltip
                        title={
                            /* 累积时长 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .accumulatedDuration
                        }
                        tooltips={
                            /* 学员参与任务时长求和 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumOfTheDurationOfStudentParticipationInTheTask
                        }
                    />
                ),
                render(time) {
                    return time ? beautifulTime((time || 0) * 60, true) : '--';
                },
            },
            {
                key: 'avgCostTime',
                dataIndex: 'avgCostTime',
                width: 110,
                title: (
                    <TitleTooltip
                        title={
                            /* 平均时长 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .averageDuration
                        }
                        tooltips={
                            /* 学员参与任务时长求和/总参与次数 */ UdeskLocales['current'].pages
                                .coach.taskCenter.list.index
                                .sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes
                        }
                    />
                ),
                render(time) {
                    return time ? beautifulTime((time || 0) * 60, true) : '--';
                },
            },
            {
                key: 'accumulatedTimes',
                dataIndex: 'accumulatedTimes',
                width: 110,
                title: (
                    <TitleTooltip
                        title={
                            /* 累积次数 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .accumulatedTimes
                        }
                        tooltips={
                            /* 学员参与任务次数求和 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumOfTheNumberOfTimesStudentsParticipateInTasks
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'avgScore',
                dataIndex: 'avgScore',
                width: 100,
                title: (
                    <TitleTooltip
                        title={
                            /* 平均分 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .averageScore
                        }
                        tooltips={
                            /* 参与该任务人员的平均得分 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .theAverageScoreOfThePersonnelParticipatingInThisTask
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'highestScore',
                dataIndex: 'highestScore',
                width: 100,
                title: (
                    <TitleTooltip
                        title={
                            /* 最高分 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .maximumScore
                        }
                        tooltips={
                            /* 参与该任务人员的最高分 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .theHighestScoreForThePersonnelInvolvedInThisTask
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'craftAvgScore',
                dataIndex: 'craftAvgScore',
                width: 130,
                title: (
                    <TitleTooltip
                        title={
                            /* 话术平均分 */ UdeskLocales['current'].pages.coach.taskCenter.list
                                .index.averageScoreOfScript
                        }
                        tooltips={
                            /* 学员话术得分求和/总参与次数 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumOfTraineeScriptScorestotalParticipationTimes
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'craftTotalScore',
                dataIndex: 'craftTotalScore',
                width: 120,
                title: (
                    <TitleTooltip
                        title={
                            /* 话术总分 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .totalScoreOfScript
                        }
                        tooltips={
                            /* 该任务下设置的话术总分 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.theTotalScoreOfTheScriptSetUnderThisTask
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'flowAvgScore',
                dataIndex: 'flowAvgScore',
                width: 120,
                title: (
                    <TitleTooltip
                        title={
                            /* 流程平均分 */ UdeskLocales['current'].pages.coach.taskCenter.list
                                .index.processAverageScore
                        }
                        tooltips={
                            /* 学员流程得分求和/总参与次数 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumOfStudentProcessScoresTotalParticipationTimes
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'flowTotalScore',
                dataIndex: 'flowTotalScore',
                width: 120,
                title: (
                    <TitleTooltip
                        title={
                            /* 流程总分 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .totalProcessScore
                        }
                        tooltips={
                            /* 该任务下设置的流程总分 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.theTotalScoreOfTheProcessesSetUnderThisTask
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'emotionAvgScore',
                dataIndex: 'emotionAvgScore',
                width: 130,
                title: (
                    <TitleTooltip
                        title={
                            /* 情绪平均分 */ UdeskLocales['current'].pages.coach.taskCenter.list
                                .index.averageEmotionalScore
                        }
                        tooltips={
                            /* 学员情绪得分求和/总参与次数 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumOfStudentEmotionalScoresTotalParticipationTimes
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'emotionTotalScore',
                dataIndex: 'emotionTotalScore',
                width: 120,
                title: (
                    <TitleTooltip
                        title={
                            /* 情绪总分 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .totalEmotionalScore
                        }
                        tooltips={
                            /* 该任务下设置的情绪总分 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.theTotalScoreOfEmotionsSetUnderThisTask
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'speedAvgScore',
                dataIndex: 'speedAvgScore',
                width: 130,
                title: (
                    <TitleTooltip
                        title={
                            /* 表达平均分 */ UdeskLocales['current'].pages.coach.taskCenter.list
                                .index.averageScoreOfExpression
                        }
                        tooltips={
                            /* 学员表达得分求和/总参与次数 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index
                                .sumOfStudentExpressionScorestotalParticipationTimes
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'speedTotalScore',
                dataIndex: 'speedTotalScore',
                width: 120,
                title: (
                    <TitleTooltip
                        title={
                            /* 表达总分 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                                .totalScoreOfExpression
                        }
                        tooltips={
                            /* 该任务下设置的表达总分 */ UdeskLocales['current'].pages.coach
                                .taskCenter.list.index.theTotalScoreOfExpressionsSetUnderThisTask
                        }
                    />
                ),
                render: createRenderFunction(),
            },
            {
                key: 'taskType',
                dataIndex: 'taskType',
                title: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskType,
                render: (text) => TaskTypeOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'lessonName',
                dataIndex: 'lessonName',
                title: UdeskLocales['current'].pages.gong.coachTaskCenter.list.lessonName,
            },
            {
                key: 'taskTime',
                dataIndex: 'taskTime',
                title: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskTime,
                width: 170,
            },
            {
                key: 'taskStatus',
                dataIndex: 'taskStatus',
                title: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskStatus,
                render: (text, _) => TaskStatusOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'publishTime',
                dataIndex: 'publishTime',
                title: /* 发布时间 */ UdeskLocales['current'].pages.coach.taskCenter.list.index
                    .releaseTime,
                width: 170,
            },
            {
                key: 'actions',
                renderType: 'actions',
                title: UdeskLocales['current'].pages.gong.coachTaskCenter.list.option,
                buttons: [
                    {
                        label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.edit,
                        onClick: (item) => onEdit(item.id),
                    },
                    {
                        label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.taskDetail,
                        onClick: (item) => onShow(item.id),
                    },
                    {
                        label: /* 重新评分 */ UdeskLocales['current'].pages.coach.taskCenter.list
                            .index.reRating,
                        // disabled: (item) => {
                        //     return !(!item.isReScoring && item.taskStatus === TaskStatusOptions[2].value);
                        // },
                        onClick: (item) => onRegrade(item),
                    },
                    {
                        label: UdeskLocales['current'].pages.gong.coachTaskCenter.list.delete,
                        danger: true,
                        onClick: (item) => onDelete(item.id),
                        confirm: {
                            title: UdeskLocales['current'].pages.gong.coachTaskCenter.list
                                .deleteConfirm,
                        },
                    },
                ],
            },
        ];

        return {
            rowKey: 'id',
            columns: [
                ...showColumnKeys.map((key) => columns.find((item) => item.key === key)),
                ...columns.filter((item) => !showColumnKeys.includes(item.key as any)),
            ] as any[],
            dataSource,
            pagination,
            loading,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource, pagination, loading, onEdit, onShow, showColumnKeys]);

    // useEffect(() => {
    //     // return () => {
    //     //     const detailUrl = '/coach/task-center/detail';
    //     //     if (history.location.pathname.indexOf(detailUrl) === -1) {
    //     //         clearCache();
    //     //     }
    //     // };
    // }, [cache, filterForm]);

    const columnsControlEvent = useCallback((showKeys) => {
        setShowColumnKeys(showKeys);
    }, []);

    return (
        <Page
            pageBodyClassName={'task-center-list-body'}
            title={UdeskLocales['current'].pages.gong.coachTaskCenter.title}
            backGroundGhost={true}
        >
            <StandardizeTable.RequestTable
                onRequest={onTableRequest}
                filter={filter}
                table={tableProps}
                columnsControl={
                    {
                        showColumnKeys,
                        onChange: columnsControlEvent,
                    } as any
                }
                toolBarRightButtons={[
                    {
                        label: UdeskLocales['current'].pages.gong.konwledgeBase.index.newlyBuild,
                        onClick: () => onEdit(0),
                        mainBtn: true,
                    },
                ]}
                exportBtn={{
                    onClick: () => {
                        return postIntelligentPartnerTaskInfosStatisticsFindAllExport({
                            ...exportParams,
                            systemModule: getSystemModule(),
                        });
                    },
                    goToDownloadCenter: () => {
                        linkTo(props.history, 'coachSystemTaskCenterDownloadList');
                    },
                }}
            />
        </Page>
    );
};

export default hookProps(TaskCenter);

const TitleTooltip = React.memo<{ title: string; tooltips: string }>((props) => {
    const { title, tooltips } = props;
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
                title={title}
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100px',
                    display: 'inline-block',
                }}
            >
                {title}
            </span>
            <Tooltip placement="top" title={tooltips}>
                <HelpOutlined />
            </Tooltip>
        </div>
    );
});
