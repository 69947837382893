// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostSemanticTagRecommendBody, BaseResponseListstring } from '../../types';

/**
 * getRecommendSimilarQuestionList
 *
 * @export
 * @tags semantic-tag-controller
 * @link [POST] /semantic-tag/recommend
 */
export function postSemanticTagRecommend(
    data: PostSemanticTagRecommendBody
): Promise<BaseResponseListstring>;
export function postSemanticTagRecommend(
    data: PostSemanticTagRecommendBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListstring>;
export function postSemanticTagRecommend(
    data: PostSemanticTagRecommendBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListstring> {
    return request<PostSemanticTagRecommendBody, never, never>('/semantic-tag/recommend', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSemanticTagRecommend',
    });
}

export const meta = [
    { tags: ['semantic-tag-controller'], path: '/semantic-tag/recommend', method: 'post' },
];
