
import UdeskLocales from 'UdeskLocales';export const ReferType = {
    Data: 'data',
    Question: 'question',
    History: 'history',
};

type UseChartProps = {};

export const useRefer = (props?: UseChartProps) => {
    const referTypeList = [
        {
            type: ReferType.Data,
            name: /* 数据 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.refer.hooks.data,
        },
        {
            type: ReferType.Question,
            name: /* 问句 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.refer.hooks.interrogativeSentence,
        },
        {
            type: ReferType.History,
            name: /* 历史查询 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.refer.hooks.historicalQuery,
        },
    ];

    return {
        referTypeList,
    };
};
