import React, { useState, useMemo, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Icon, Input, Menu, Checkbox, Modal, Badge, Tooltip, Empty, Spin } from 'udesk-ui';
import styled from 'styled-components';
import './style.scss';
import { CommonFilter, TreeMenu } from 'udesk_gm_ui';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { CommonFilterInstance, CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import UdeskPagination from 'Component/common/udesk-pagination';
import moment from 'moment';
import { postSubjectMarkCallsSearch } from 'src/api/subjectMarkCalls/search';
import { getSubjectMarkCallsDataCallDetailById } from 'src/api/subjectMarkCalls/dataCall/detail/{id}';
import { postSubjectMarkCalls } from 'src/api/subjectMarkCalls';
import { TreeMenuProps } from 'udesk_gm_ui/es/tree-menu/types';
// import { getSubjectMarkFilesSearch } from 'src/api/subjectMarkFiles/search';
import { getSubjectMarkFiles } from 'src/api/subjectMarkFiles';
import { deleteSubjectMarkCallsRestByCallId } from 'src/api/subjectMarkCalls/rest/{callId}';

const locales = Locales['current'];

const COLOR_LIST = [
    '#CDDDFD',
    '#CDF3E4',
    '#FCEBB9',
    '#F8D0CB',
    '#D3EEF9',
    '#DECFEA',
    '#FFE0C7',
    '#BBDEDE',
    '#FFE0ED',
    '#5B8FF9',
    '#5AD8A6',
    '#F6BD16',
    '#E86452',
    '#6DC8EC',
    '#945FB9',
    '#FF9845',
    '#1E9493',
    '#FF99C3',
];
const SUBJECT_MARK_STATUS_OPTIONS = [
    { label: /* 全部 */ locales.pages.gong.topicCallout.index.whole, value: null },
    ...Udesk.enums.subjectMarkStatus.map((i) => ({ label: i.name, value: i.id })),
];
const SUBJECT_MARK_STATUS_MAP: { [key: string]: 'success' | 'default' | 'warning' } = {
    [Udesk.enums.subjectMarkStatus.before.id]: 'default',
    [Udesk.enums.subjectMarkStatus.workInProgress.id]: 'warning',
    [Udesk.enums.subjectMarkStatus.done.id]: 'success',
};
const FIELDS: CommonFilterProps['fields'] = [
    {
        type: 'UdeskDatePicker' as any,
        name: `call_time`,
        label: `${/* 日期 */ locales.pages.gong.topicCallout.index.date}`,
        // props: {
        //     allowClear: false,
        // },
    },
    {
        type: 'Select',
        name: `subject_mark_status`,
        label: `${/* 标注状态 */ locales.pages.gong.topicCallout.index.dimensionStatus}`,
        props: {
            options: SUBJECT_MARK_STATUS_OPTIONS,
            style: {
                width: 150,
            },
            placeholder: locales.labels.pleaseSelect,
        },
    },
    {
        type: 'Input',
        name: `call_id`,
        label: `${/* 通话记录ID */ locales.pages.gong.topicCallout.index.callRecordID}`,
        rules: [
            () => ({
                validator(_, value) {
                    if (!value || !isNaN(Number(value))) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error(
                            /* 通话记录ID必须为数字 */ locales.pages.gong.topicCallout.index.callRecordIDMustBeNumeric
                        )
                    );
                },
            }),
        ],
        props: {
            placeholder: locales.labels.pleaseEnter,
        },
    },
];

const INITIAL_VALUES = {
    call_time: [
        moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00',
        moment().format('YYYY-MM-DD') + ' 23:59:59',
    ],
    subject_mark_status: null,
    call_id: '',
};

const ContentWrap = styled.div`
    margin-top: 16px;
    padding: 16px;
    background-color: #fff;
    flex: 1;
    overflow: hidden;
    display: flex;
`;

const ContentLeft = styled.div`
    width: 286px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    .udesk-qa-ui-menu {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        .udesk-qa-ui-menu-item {
            height: auto;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
`;
const ContentMiddle = styled.div`
    flex: 1;
    overflow: auto;
    height: 100%;
`;
const ContentRight = styled.div`
    width: 258px;
    padding-left: 16px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    .udesk-qa-ui-tree-menu {
        flex: 1;
        margin-top: 8px;
    }
    .udesk-qa-ui-tree-icon__customize {
        display: flex !important;
        align-items: center;
    }
    .udesk-qa-ui-btn-primary {
        width: 100%;
    }
`;
const DialogSentenceItem = styled.div`
    display: flex;
    padding-top: 10px;
    padding-left: 31px;
    position: relative;
    background-color: ${(props) => (props.bgColor ? '#e8f4ff' : props.topicColor || '#fff')};
    ${(props) =>
        props.bgColor
            ? 'background-image: repeating-linear-gradient(60deg,hsla(0,0%,100%,0.5),hsla(0,0%,100%,0.5) 15px,transparent 0,transparent 30px);'
            : ''}
    .nickname {
        color: #646a73;
    }
    .content {
        color: #1f2329;
        word-break: break-word;
        padding-right: 8px;
    }
`;
const ChannelTypeIcon = ({ type, call_duration, total_time, imCount = 0 }) => {
    // alert(type)
    switch (type) {
        case 2: // 企微通话
        case 0: // 电话
            return (
                <>
                    <Icon
                        type={
                            type === 0 ? 'tonghuaguanli' : 'a-maikefengshengyinluyinluzhishengyin1'
                        }
                        style={{ fontSize: '30px', color: 'rgba(0, 0, 0, 0.1)' }}
                    ></Icon>
                    <span className="time-durtion">
                        {call_duration
                            ? parseInt(call_duration ?? 0) / 60 <= 1
                                ? parseInt(call_duration ?? 0) + 's'
                                : ((parseInt(call_duration ?? 0) || 0) / 60).toFixed(0) + 'm'
                            : (parseInt(total_time ?? 0) || 0) / 60 <= 1
                            ? parseInt(total_time ?? 0).toFixed(0) + 's'
                            : ((parseInt(total_time ?? 0) || 0) / 60).toFixed(0) + 'm'}
                    </span>
                </>
            );
        case 1:
            // 企业
            return (
                <>
                    <Icon
                        type="xingzhuang"
                        style={{ fontSize: '30px', color: 'rgba(0, 0, 0, 0.1)' }}
                    ></Icon>
                    <span className="time-durtion">
                        {imCount}
                        {/* 条 */}
                        {locales.pages.gong.topicCallout.index.strip}
                    </span>
                </>
            );

        default:
            return null;
    }
};
const DialogSentenceListItem = styled.div`
    display: flex;
    width: 100%;
    .left-filter-list-item {
        width: 156px;
        overflow: hidden;
        div {
            line-height: 22px;
            height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .left-filter-list-item-right {
        flex: 1;
        text-align: center;
    }
`;
const ChannelTypeIconWrap = styled.div`
    width: 64px;
    margin-right: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f6f8;
    border: 1px solid #f0f2f5;
    border-radius: 2px;
    .anticon {
        margin-left: 0;
        margin-right: 0;
    }
    & > .time-durtion {
        margin-left: 0 !important;
        background: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
        padding: 0 4px;
    }
`;
const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 10,
        total: 0,
    });
    const [callList, setCallList] = useState<any[]>([]);
    const [selectCallDetailId, setSelectCallDetailId] = useState<any>(null);
    const [detailInfo, setDetailInfo] = useState<any>({});
    const [dialogSentenceList, setDialogSentenceList] = useState<any[]>([]);
    // const [dialogFieldValueMap, setDialogFieldValueMap] = useState<any>({});
    const [treeData, setTreeData] = useState<any[]>([]);
    const [selectedKey, setSelectedKey] = useState<any>(null);
    const [topicColorMap, setTopicColorMap] = useState<any>({});
    const [topicNameMap, setTopicNameMap] = useState<any>({});
    const [highlightKeyword, setHighlightKeyword] = useState<string>('');
    const [filterValues, setFilterValues] = useState<any>(() => {
        const queryParams = new URLSearchParams(props.location.search);
        let callId = queryParams.get('callId');
        let res = { ...INITIAL_VALUES };
        if (callId) {
            res.call_id = callId;
            res.call_time = [null, null] as any;
        }
        return res;
    });
    const filterRef = useRef<CommonFilterInstance>(null);
    const firstMount = useRef<boolean>(true);
    const contentMiddleRef = useRef<any>(null);
    const prevCheckedIndex = useRef<number | null>(null);
    const prevCheckedStatus = useRef<boolean | null>(null);
    const callDetailInfoKeyMap = useRef<{ [key: string]: string }>({});
    const [undecidedIndexList, setUndecidedIndexList] = useState<number[]>([]);
    const [contentLoading, setContentLoading] = useState<boolean>(false);

    const requestCallList = (params) => {
        return postSubjectMarkCallsSearch(params).then((resp) => {
            // const { pageNum = 1, pageSize = 20, total = 0 } = resp.data ?? {};
            setCallList(resp.data?.fieldDataList ?? []);
            setDetailInfo({
                markNumber: resp.data?.markNumber ?? 0,
                markingNumber: resp.data?.markingNumber ?? 0,
                notMarkNumber: resp.data?.notMarkNumber ?? 0,
            });
            resp.data?.showFieldList?.forEach((item) => {
                if (
                    typeof item.name === 'string' &&
                    [
                        'customer_nick_name',
                        'call_time',
                        'agent',
                        'call_duration',
                        'total_time',
                    ].includes(item.name)
                ) {
                    callDetailInfoKeyMap.current[item.name] = item.id ?? '';
                }
            });
            setPaging({ ...paging, ...resp.paging });
            return resp.data;
        });
    };

    useEffect(() => {
        filterRef.current?.formInstance.setFieldsValue(filterValues);
        requestCallList({
            ...buildFilterValue(filterValues),
            ...paging,
        }).then((data) => {
            if (Array.isArray(data?.fieldDataList) && data?.fieldDataList.length) {
                selectCallDetail({
                    key: data?.fieldDataList?.[0].dataId,
                });
            }
        });
    }, []);

    const selectCallDetail = ({ key }) => {
        setContentLoading(true);
        setUndecidedIndexList([]);
        getSubjectMarkCallsDataCallDetailById({
            segments: {
                id: key,
            },
        })
            .then((resp) => {
                setDialogSentenceList(
                    (resp.data?.dialogSentenceList ?? []).map((item, i) => ({ ...item, index: i }))
                );
                setSelectCallDetailId(Number(key));
            })
            .finally(() => {
                setContentLoading(false);
            });
        // getSubjectMarkCalls({
        //     params: {

        //     }
        // })
    };
    useEffect(() => {
        if (!firstMount.current || contentLoading) return;
        const queryParams = new URLSearchParams(props.location.search);
        let fileId = queryParams.get('fileId');
        const firstIndex = dialogSentenceList.findIndex((i) => `${i.fileId}` === fileId);
        if (firstIndex >= 0) {
            contentMiddleRef.current
                .getElementsByClassName('dialogSentence-item')
                ?.[firstIndex].scrollIntoViewIfNeeded();
        }
        if (dialogSentenceList.length) {
            firstMount.current = false;
        }
    }, [dialogSentenceList, contentLoading]);
    const requestClassification: () => Promise<any[]> = () => {
        // const walker = (data) => {
        //     data.forEach((d) => {
        //         if (!d.isLeaf) {
        //             d.disabled = true;
        //             walker(d.children);
        //         }
        //     });
        // };
        return getSubjectMarkFiles().then((resp) => {
            const { data: respData = [] } = resp;
            const formatRespData = [...respData].map((i, index) => ({
                ...i,
                title: i.name,
                key: `${i.id}`,
                color: COLOR_LIST[index % 18],
            }));
            const colorMap = formatRespData.reduce((prev, cur) => {
                prev[cur.id!] = cur.color;
                return prev;
            }, {});
            const nameMap = formatRespData.reduce((prev, cur) => {
                prev[cur.id!] = cur.name;
                return prev;
            }, {});
            setTopicColorMap(colorMap);
            setTopicNameMap(nameMap);
            const data = TreeMenu.formatTreeDataFromArrayToTree(
                [...formatRespData, { key: '0', parentIds: '', title: '' }],
                0,
                'parentId'
            );
            // walker(data);
            return data;
        });
    };
    useEffect(() => {
        requestClassification().then((data) => {
            setTreeData(data);
        });
    }, []);
    const classificationProps: TreeMenuProps = useMemo(() => {
        return {
            treeData,
            onSelect: (key, info) => {
                setSelectedKey(Number(key[0]));
            },
            selectedKeys: [`${selectedKey}`],
            highlightKeyword,
            icon: (nodeProps) => {
                return (
                    <div
                        style={{
                            height: 16,
                            width: 16,
                            borderRadius: '50%',
                            backgroundColor: (nodeProps as any).color,
                        }}
                    ></div>
                );
            },
        };
    }, [treeData, selectedKey, highlightKeyword]);
    return (
        <Page
            pageBodyClassName="sale-topic-callout-page-index"
            title={/* 话题标注 */ locales.pages.gong.topicCallout.index.topicAnnotation}
            backGroundGhost={true}
        >
            <CommonFilter
                onSearch={(value) => {
                    setFilterValues(value);
                    requestCallList({
                        ...buildFilterValue(value),
                        ...paging,
                        pageNum: 1,
                    }).then((data) => {
                        if (Array.isArray(data?.fieldDataList) && data?.fieldDataList.length) {
                            selectCallDetail({
                                key: data?.fieldDataList?.[0].dataId,
                            });
                        } else {
                            setDialogSentenceList([]);
                        }
                    });
                }}
                fields={FIELDS}
                initialValues={INITIAL_VALUES}
                customComponents={{ UdeskDatePicker }}
                ref={filterRef}
            ></CommonFilter>
            <ContentWrap>
                <ContentLeft>
                    <div style={{ borderRight: '1px solid #f0f0f0', paddingBottom: 8 }}>
                        <div>
                            {paging.total
                                ? `${/* 已标注 */ locales.pages.gong.topicCallout.index.annotated}${
                                      detailInfo.markNumber
                                  }${
                                      /* 条，未标注 */ locales.pages.gong.topicCallout.index
                                          .stripNotMarked
                                  }${detailInfo.notMarkNumber}${
                                      /* 条，标注中 */ locales.pages.gong.topicCallout.index
                                          .barInDimension
                                  }${detailInfo.markingNumber}${
                                      /* 条 */ locales.pages.gong.topicCallout.index.strip
                                  }`
                                : ''}
                        </div>
                    </div>
                    {callList.length ? (
                        <Menu
                            selectedKeys={[`${selectCallDetailId}`]}
                            onSelect={selectCallDetail}
                            mode="inline"
                        >
                            {callList.map((item, index) => {
                                return (
                                    <Menu.Item key={item.dataId}>
                                        <DialogSentenceListItem>
                                            <ChannelTypeIconWrap>
                                                <ChannelTypeIcon
                                                    type={item.channelType}
                                                    call_duration={
                                                        item.fieldValueMap[
                                                            callDetailInfoKeyMap.current
                                                                .call_duration
                                                        ]
                                                    }
                                                    total_time={
                                                        item.fieldValueMap[
                                                            callDetailInfoKeyMap.current.total_time
                                                        ]
                                                    }
                                                    imCount={item.imCount}
                                                />
                                            </ChannelTypeIconWrap>
                                            <div
                                                className="left-filter-list-item"
                                                title={item.dataId}
                                            >
                                                <div>
                                                    {`${
                                                        item.fieldValueMap[
                                                            callDetailInfoKeyMap.current
                                                                .customer_nick_name
                                                        ] ??
                                                        /* 客户 */ locales.pages.gong.topicCallout
                                                            .index.customer
                                                    } | ${
                                                        item.fieldValueMap[
                                                            callDetailInfoKeyMap.current.agent
                                                        ] ??
                                                        /* 销售 */ locales.pages.gong.topicCallout
                                                            .index.sale
                                                    }`}
                                                </div>
                                                <div>
                                                    {
                                                        item.fieldValueMap[
                                                            callDetailInfoKeyMap.current.call_time
                                                        ]
                                                    }
                                                </div>
                                                <div>{item.dataId}</div>
                                            </div>
                                            <div className="left-filter-list-item-right">
                                                <Tooltip
                                                    placement="topLeft"
                                                    title={Udesk.enums.subjectMarkStatus.getName(
                                                        item.subjectMarkStatus
                                                    )}
                                                >
                                                    <Badge
                                                        status={
                                                            SUBJECT_MARK_STATUS_MAP[
                                                                item.subjectMarkStatus
                                                            ]
                                                        }
                                                    />
                                                </Tooltip>
                                            </div>
                                        </DialogSentenceListItem>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginTop: 8 }}>
                            {paging.total
                                ? `${/* 共 */ locales.pages.gong.topicCallout.index.common}${
                                      paging.total
                                  }${/* 条会话 */ locales.pages.gong.topicCallout.index.sessions}`
                                : ''}
                        </div>
                        {Boolean(paging.total) && (
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                <UdeskPagination
                                    current={paging.pageNum}
                                    pageSize={paging.pageSize}
                                    total={paging.total}
                                    onChange={(pageNum: number, pageSize?: number) => {
                                        requestCallList({
                                            ...buildFilterValue(filterValues),
                                            ...paging,
                                            pageNum,
                                        });
                                    }}
                                    simple={true}
                                />
                            </div>
                        )}
                    </div>
                </ContentLeft>
                <ContentMiddle ref={contentMiddleRef}>
                    <Spin spinning={contentLoading}>
                        {dialogSentenceList?.length ? (
                            dialogSentenceList.map((item, index) => {
                                return (
                                    <DialogSentenceItem
                                        className="dialogSentence-item"
                                        bgColor={undecidedIndexList.includes(index)}
                                        topicColor={
                                            item.fileId ? topicColorMap[item.fileId] : false
                                        }
                                        title={
                                            item.fileId && !undecidedIndexList.includes(index)
                                                ? `${
                                                      /* 当前话题： */ locales.pages.gong
                                                          .topicCallout.index.currentTopic
                                                  }${topicNameMap[item.fileId]}`
                                                : undefined
                                        }
                                    >
                                        <Checkbox
                                            style={{
                                                position: 'absolute',
                                                top: '16px',
                                                left: '5px',
                                            }}
                                            checked={undecidedIndexList.includes(index)}
                                            onChange={(e) => {
                                                if (
                                                    e.nativeEvent.shiftKey &&
                                                    prevCheckedIndex.current !== null
                                                ) {
                                                    const prevIndex: number =
                                                        prevCheckedIndex.current;
                                                    const batchCount =
                                                        Math.max(prevIndex, index) -
                                                        Math.min(prevIndex, index) +
                                                        1;
                                                    const batchedIndexList = new Array(batchCount)
                                                        .fill(0)
                                                        .map((_, i) =>
                                                            prevIndex > index
                                                                ? index + i
                                                                : prevIndex + i
                                                        );
                                                    if (prevCheckedStatus.current === true) {
                                                        setUndecidedIndexList((prev) => {
                                                            return Array.from(
                                                                new Set([
                                                                    ...prev,
                                                                    ...batchedIndexList,
                                                                ])
                                                            );
                                                        });
                                                    } else {
                                                        setUndecidedIndexList((prev) => {
                                                            return prev.filter(
                                                                (i) => !batchedIndexList.includes(i)
                                                            );
                                                        });
                                                    }
                                                }
                                                if (!e.nativeEvent.shiftKey) {
                                                    if (e.target.checked) {
                                                        setUndecidedIndexList((prev) => [
                                                            ...prev,
                                                            index,
                                                        ]);
                                                    } else {
                                                        setUndecidedIndexList((prev) =>
                                                            prev.filter((i) => i !== index)
                                                        );
                                                    }
                                                }
                                                prevCheckedIndex.current = index;
                                                prevCheckedStatus.current = e.target.checked;
                                            }}
                                        />
                                        <div
                                            className="avatar"
                                            style={
                                                item.avatar
                                                    ? { backgroundImage: `url('${item.avatar}')` }
                                                    : {}
                                            }
                                        >
                                            {!item.avatar && (
                                                <Icon
                                                    style={{ fontSize: '32px' }}
                                                    type={
                                                        item.role === 'agent' ? 'agent' : 'customer'
                                                    }
                                                />
                                            )}
                                        </div>
                                        <div>
                                            <div className="nickname">
                                                {item.role === 'agent'
                                                    ? /* 员工 */ locales.pages.gong.topicCallout
                                                          .index.staff
                                                    : /* 客户 */ locales.pages.gong.topicCallout
                                                          .index.customer}
                                            </div>
                                            <div className="content">{item.text}</div>
                                        </div>
                                    </DialogSentenceItem>
                                );
                            })
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </Spin>
                </ContentMiddle>
                <ContentRight>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: 8,
                            }}
                        >
                            <div>
                                {/* 话题 */}
                                {locales.pages.gong.topicCallout.index.topicOfConversation}
                            </div>
                        </div>
                        <Input.Search
                            onChange={(e) => {
                                if (!e.target.value) {
                                    requestClassification().then((data) => {
                                        setHighlightKeyword('');
                                        setTreeData(data);
                                    });
                                }
                            }}
                            onSearch={(value) => {
                                setHighlightKeyword(value);
                                // getSubjectMarkFilesSearch({
                                //     params: {
                                //         name: value,
                                //     },
                                // })
                                //     .then((resp) => {
                                //         const { data: respData = [] } = resp;
                                //         const data = TreeMenu.formatTreeDataFromArrayToTree(
                                //             [...respData, { id: 0, parentIds: '' }].map((i) => ({
                                //                 ...i,
                                //                 title: i.name,
                                //                 key: `${i.id}`,
                                //             })),
                                //             0,
                                //             'parentId'
                                //         );
                                //         return data;
                                //     })
                                //     .then((data) => {
                                //         setTreeData(data);
                                //         setSelectedKey(`${data?.[0]?.id}` ?? null);
                                //     });
                            }}
                        />
                    </div>
                    <TreeMenu {...classificationProps} />
                    <Button
                        style={{ marginBottom: 8 }}
                        onClick={() => {
                            const request = () => {
                                deleteSubjectMarkCallsRestByCallId({
                                    segments: {
                                        callId: Number(selectCallDetailId),
                                    },
                                    successMsg:
                                        /* 操作成功，标注已重置 */ locales.pages.gong.topicCallout
                                            .index
                                            .theOperationIsSuccessfulAndTheDimensionHasBeenReset,
                                }).then(() => {
                                    setUndecidedIndexList([]);
                                    prevCheckedStatus.current = null;
                                    prevCheckedIndex.current = null;
                                    selectCallDetail({ key: selectCallDetailId });
                                });
                            };
                            Modal.confirm({
                                onOk: request,
                                content:
                                    /* 重置后将清空本通会话的所有标注记录，请确认 */ locales.pages
                                        .gong.topicCallout.index
                                        .afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm,
                            });
                        }}
                    >
                        {/* 全部重置 */}
                        {locales.pages.gong.topicCallout.index.resetAll}
                    </Button>
                    <Button
                        type="primary"
                        disabled={!selectedKey || !undecidedIndexList.length}
                        tooltip={
                            !selectedKey
                                ? /* 请选择要标注到的话题 */ locales.pages.gong.topicCallout.index
                                      .pleaseSelectTheTopicToBeMarked
                                : !undecidedIndexList.length
                                ? /* 请勾选要标注的句子 */ locales.pages.gong.topicCallout.index
                                      .pleaseTickTheSentenceToBeMarked
                                : undefined
                        }
                        onClick={() => {
                            let params = {
                                callId: Number(selectCallDetailId),
                                cover: 0,
                                subjectMarkCallDialogList: dialogSentenceList
                                    .map((item, i) => ({ text: item.text, index: item.index }))
                                    .filter((item) => undecidedIndexList.includes(item.index)),
                                fileId: selectedKey,
                            };
                            const request = (params) => {
                                return postSubjectMarkCalls(params, {
                                    successMsg:
                                        /* 操作成功，标注已生效 */ locales.pages.gong.topicCallout
                                            .index
                                            .theOperationIsSuccessfulAndTheAnnotationHasTakenEffect,
                                }).then(() => {
                                    setUndecidedIndexList([]);
                                    prevCheckedStatus.current = null;
                                    prevCheckedIndex.current = null;
                                    selectCallDetail({ key: selectCallDetailId });
                                });
                            };
                            if (
                                dialogSentenceList
                                    .filter((i) => typeof i.fileId === 'number')
                                    .some((i) => undecidedIndexList.includes(i.index))
                            ) {
                                Modal.confirm({
                                    onOk: () => request({ ...params, cover: 1 }),
                                    content:
                                        /* 本次标注的范围包含其他话题的消息，点击确定将进行覆盖 */ locales
                                            .pages.gong.topicCallout.index
                                            .theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem,
                                });
                                return;
                            }
                            request(params).then(() => {
                                const dialogSentenceListCopy = dialogSentenceList.map((i) => {
                                    let ret = { ...i };
                                    if (undecidedIndexList.includes(i.index)) {
                                        ret.fileId = selectedKey;
                                    }
                                    return ret;
                                });
                                if (
                                    dialogSentenceListCopy.every(
                                        (i) => typeof i.fileId === 'number'
                                    )
                                ) {
                                    const index = callList.findIndex(
                                        (item) => item.dataId === Number(selectCallDetailId)
                                    );
                                    requestCallList({
                                        ...buildFilterValue(filterValues),
                                        ...paging,
                                    }).then((data) => {
                                        if (
                                            Array.isArray(data?.fieldDataList) &&
                                            data?.fieldDataList.length
                                        ) {
                                            if (index >= 0 && data?.fieldDataList[index]) {
                                                selectCallDetail({
                                                    key: data?.fieldDataList[index].dataId,
                                                });
                                            }
                                        }
                                    });
                                }
                            });
                        }}
                    >
                        {/* 确认标注 */}
                        {locales.pages.gong.topicCallout.index.confirmDimensions}
                    </Button>
                </ContentRight>
            </ContentWrap>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

function buildFilterValue(values) {
    const locales = Locales['current'];
    const conditionList = Object.keys(values)
        .filter((k) => values[k] || typeof values[k] === 'number')
        .map((key) => {
            if (key === 'call_time') {
                if (!values[key][0] || !values[key][1]) {
                    return null;
                }
                return {
                    operator: 'between',
                    value: values[key],
                    field: {
                        id: 'a_399',
                        label: /* 通话时间 */ locales.pages.gong.topicCallout.index.talkTime,
                        dataSourceType: 9,
                        fieldName: 'd1',
                        fieldType: 3,
                        name: 'call_time',
                    },
                };
            }
            if (key === 'subject_mark_status') {
                return {
                    operator: 'is',
                    value: values[key],
                    field: {
                        id: 'a_420',
                        label: /* 标注状态 */ locales.pages.gong.topicCallout.index.dimensionStatus,
                        dataSourceType: 9,
                        fieldName: 'subject_mark_status',
                        fieldType: 2,
                        name: 'subject_mark_status',
                    },
                };
            }
            if (key === 'call_id') {
                return {
                    operator: 'is',
                    value: values[key],
                    field: {
                        id: 'a_420',
                        label: /* 通话id */ locales.pages.gong.topicCallout.index.callID,
                        dataSourceType: 9,
                        fieldName: 'id',
                        fieldType: 2,
                        name: 'call_id',
                    },
                };
            }
            return null;
        })
        .filter(Boolean);
    return {
        conditionList,
        judgeStrategy: 1,
    };
}
