export default {
  lang: {
    num: 'No.',
    total: 'Artikel/jumlah',
    strip: 'Artikel',
    more: 'Lagi',
    chosen: 'Dipilih',
    cancel: 'Batalkan pilihan',
    bulkOperations: 'Operasi kumpulan',
    import: 'Import',
  },
};
