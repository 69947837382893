import React from "react";
import Udesk from "Udesk";
export default class LayoutMenuComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        navLists: []
    };
    static propTypes = {
       
    };
    privates = {
        layoutSiderRef: React.createRef(),
        collapsed: false
    };
    //#endregion
    actions = {
        transitionToPage(item) {
            const navItem = item.item.props._navitem;
            if (navItem) {
                const routeOptions = {
                    history: this.props.history,
                    routeName: navItem.pathName,
                    pathParams: navItem.pathParams
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        siderActionsChange(collapsed, type) {
            this.privates.collapsed = collapsed;
            this.actions.update();
        },
        navlistMap(data) {
            let { location } = this.props;
            let defautSelected = [];
            if (data && data.length > 0) {
                data.forEach(item => {
                    if (!item.pathName) {
                        if (item.children && item.children.length > 0) {
                            let obj = item.children.find(current => current.isActive(null, location));
                            if (obj) {
                                defautSelected.push(obj.pathName);

                            }
                        }
                    } else {
                        if (item.isActive(null, location)) {
                            defautSelected.push(item.pathName);
                        }

                    }
                });
            }
            if (defautSelected) {
                return defautSelected;
            } else {
                return;
            }
        },
        openMap(data) {
            let list = [];
            if (data && data.length > 0) {
                data.forEach((item, index) => {
                    if (item.children && item.children.length > 0) {
                        list.push(`group-${index}`);
                    }
                });
            }
            return list;
        }
    };
    //#region Life Cycle
    componentWillUnmount() { }
    //#endregion
}
