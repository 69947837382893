import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import TasksCheckPointDetailTemplate from '../detail/template-new';

export default class TasksCheckPointEditRoute extends TasksCheckPointDetailTemplate {
    model() {
        const { props, privates } = this;
        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );
        if (privates.isWechatQa) {
            params.taskId = 0;
        }
        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionPoints/' + params.id + '?taskId=' + params.taskId,
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.checkPoint.index
                                    .name
                            )
                    );
                    reject(reason);
                }
            );
        });

        let categoriesPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/interactiveCategories/initTree',
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(getTreeData(resp.data));
                },
                (reason) => {
                    reject(reason);
                }
            );
        });

        let foctorsPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/interactiveDatas/multiElements',
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    reject(reason);
                }
            );
        });
        let statusPromise = params.taskId
            ? new Promise((resolve, reject) => {
                  let url = Udesk.business.apiPath.concatApiPath(
                      '/inspectionTasks/' + params.taskId,
                      this.props.sdkOptions
                  );
                  Udesk.ajax.get(url).then(
                      (resp) => {
                          resolve(resp.data);
                      },
                      (reason) => {
                          reject(reason);
                      }
                  );
              })
            : null;
        let conditionPromise = Promise.resolve({ applyRole: Udesk.enums.applyRoles.all.id });
        let taskVersionPromise = params.taskId
            ? new Promise((resolve, reject) => {
                  let url = Udesk.business.apiPath.concatApiPath(
                      `/inspectionTasks/${params.taskId}/version`,
                      props.sdkOptions
                  );
                  Udesk.ajax.get(url).then(
                      (resp) => {
                          resolve(resp.data);
                      },
                      (reason) => {
                          Udesk.ui.notify.error(
                              reason.errorMsg ||
                                  Udesk.utils.string.format(
                                      UdeskLocales.current.business.info.loadError,
                                      UdeskLocales.current.pages.tasks.manage.template.basicInfo
                                          .index.title
                                  )
                          );
                          reject(reason);
                      }
                  );
              })
            : null;

        return {
            taskId: params.taskId,
            categoryId: params.categoryId,
            inspectionPoint: modelPromise,
            categories: categoriesPromise,
            foctorsData: foctorsPromise,
            task: props.sdkOptions.props.task,
            inspectionConditions: props.sdkOptions.props.inspectionConditions,
            status: statusPromise,
            basicInfoFields: props.sdkOptions.props.basicInfoFields,
            condition: conditionPromise,
            taskVersion: taskVersionPromise,
        };
    }
}

function getTreeData(tree) {
    return tree.map((item) => {
        return {
            key: item.id,
            value: item.id,
            title: item.name,
            children: getTreeData(item.children),
        };
    });
}
