import React from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import './index.less';
import UdeskLocales from 'UdeskLocales';

const Template = React.memo((props: any) => {
    const {match} = props;

    return (
        <Page
            pageClassName='learning-center-record'
            pageBodyClassName='learning-center-record-body'
            backGroundGhost={true}
            title={/* 课程 */UdeskLocales['current'].pages.coach.learningCenter.course.edit.index.curriculum}
        >
            {match.params?.id}
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);