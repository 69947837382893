import Template from "Component/pages/components/custom-filter-modal/template";
import Udesk from 'Udesk';

class Component extends Template {
    "[actions]" () {
        return {
            // 编辑
            handleConditionSave() {
                const { privates, actions, trigger, props, customSaveAjax, locales } = this as any;
                let { 
                    customName, 
                    nameCount, 
                    customId, 
                    conditionList, 
                    customJudgeLogic, 
                    judgeStrategy,
                } = privates;
                customJudgeLogic = actions.getCustom(customJudgeLogic);
                let list = conditionList.map((item) => {
                    let formmatItem = {...item};
                    if(formmatItem.operator === "between" && Array.isArray(formmatItem.value)){
                        formmatItem.value = `${formmatItem.value[0]} 00:00:00,${formmatItem.value[1]} 23:59:59`;
                    }
                    const { statusKey, ...field } = formmatItem.field;
                    if (statusKey) {
                        field.id = field.statusKey = statusKey;
                    }
                    delete formmatItem.optionValueList;
                    return {
                        ...formmatItem,
                        field,
                    };
                });
                let data: any = {
                    templateType: props.type,
                    name: customName,
                    conditionList: list,
                    customJudgeLogic,
                    judgeStrategy,
                };
                const successHandle = (resp, resolve) => {
                    Udesk.ui.notify.success(locales.components.common.customFilter.updateSuccess);
                    if (props.applyAction) {
                        props.applyAction(
                            list, 
                            customJudgeLogic, 
                            judgeStrategy, 
                            customId, 
                            customName,
                        );
                    }
                    actions.getCustomFilterList();
                    trigger.call(this, 'onConditionFilterSaved', true, resp.data);
                    resolve(resp);
                };
                const errorHandle = (reason, reject) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        locales.components.pages.components.listManage.getRecordsFailed
                    );
                    reject(reason);
                };
                //抽检列表改为高级筛选器优化加入
                if(props.querryType === 'spotCheckList'){
                    data.templateType = 66;
                    let { taskId } = props;
                    if (taskId) {
                        data.taskInclude = taskId;
                    }
                }
                //客户管理列表高级筛选器
                if(props.querryType === 'personaField'){
                    data.templateType = 88;
                }
                if (customName) {
                    if (nameCount > 10) {
                        Udesk.ui.notify.error(locales.components.common.customFilter.holderCustomFilterNameLength);
                    } else {
                        if (customId) {
                            data.id = privates.customId;
                        }
                        let { sdkOptions } = props;
                        let url = Udesk.business.apiPath.concatApiPath(`search-template/save`, sdkOptions);
                        let method = Udesk.enums.requestMethods.create.name;
                        if (data.id) {
                            url = Udesk.business.apiPath.concatApiPath(`search-template/update`, sdkOptions);
                            method = Udesk.enums.requestMethods.update.name;
                        } else {
                            url = Udesk.business.apiPath.concatApiPath(`search-template/save`, sdkOptions);
                            method = Udesk.enums.requestMethods.create.name;
                        }
                        if (customSaveAjax) {
                            new Promise((resolve, reject) => {
                                customSaveAjax(data, method).then(
                                    (resp) => successHandle(resp, resolve),
                                    (reason) => errorHandle(reason, reject)
                                );
                            });
                        } else {
                            new Promise((resolve, reject) => {
                                Udesk.ajax(url, data, method).then(
                                    (resp) => successHandle(resp, resolve),
                                    (reason) => errorHandle(reason, reject)
                                );
                            });
                        }
                    }
                } else {
                    Udesk.ui.notify.error(locales.components.common.customFilter.customFilterNameNone);
                }
            },
            // 仅使用
            handleConditionApply() {
                const { privates, actions, trigger, props } = this as any;
                let { conditionList, customJudgeLogic, judgeStrategy, customName, customId } = privates;
                customJudgeLogic = actions.getCustom(customJudgeLogic);
                let list =
                    conditionList.map((item) => {
                        let formmatItem = {...item};
                        if(item.operator === "between" && Array.isArray(item.value)){
                            formmatItem.value = `${formmatItem.value[0]} 00:00:00,${formmatItem.value[1]} 23:59:59`;
                        }
                        if (item.field.statusKey) {
                            return {
                                ...formmatItem,
                                field: {
                                    ...item.field,
                                    id: item.field.statusKey,
                                },
                            };
                        } else {
                            return formmatItem;
                        }
                    }) || [];
                if (props.applyAction) {
                    props.applyAction(
                        list, 
                        customJudgeLogic, 
                        judgeStrategy, 
                        customId, 
                        customName,
                    );
                }
                trigger.call(this, 'handleConditionCancelWithoutClearData');
            },
            // 应用
            handleConditionApplyField() {
                const {privates, actions, trigger, props} = this as any;
                let { customeFilterField, fieldList } = privates;
                privates.conditionList = actions.getQueryConditionListField(customeFilterField.conditionList, fieldList);
                privates.judgeStrategy = customeFilterField.judgeStrategy;
                privates.customJudgeLogic = customeFilterField.customJudgeLogic;
                privates.customJudgeLogic = actions.getCustom(privates.customJudgeLogic);
                let list =
                    privates.conditionList.map((item) => {
                        const { statusKey, ...field } = item.field;
                        if (statusKey) {
                            field.id = field.statusKey = statusKey;
                        }
                        return {
                            ...item,
                            field,
                        };
                    }) || [];
                if (props.applyAction) {
                    props.applyAction(
                        list, 
                        privates.customJudgeLogic, 
                        privates.judgeStrategy, 
                        customeFilterField.id,
                        customeFilterField.name
                    );
                }
                trigger.call(this, 'handleConditionCancelWithoutClearData');
                actions.update();
            },
            menuKeysChange(list) {
                const {privates, actions} = this as any;
                let { customFilterList } = privates;
                privates.customeFilterFieldKey = [`${list.key}`];
                privates.customeFilterField = customFilterList.find(
                    (current) => current.id === parseInt(list.key, 10)
                );
                privates.conditionList = privates.customeFilterField.conditionList;
                privates.customJudgeLogic = privates.customeFilterField.customJudgeLogic;
                privates.judgeStrategy = privates.customeFilterField.judgeStrategy;
                privates.customName = privates.customeFilterField.name;
                privates.customId = privates.customeFilterField.id;
                actions.update();
            },
        };
    }
}

export default Udesk.react.udeskify(Component);