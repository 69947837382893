import React from 'react';
import PropTypes from 'prop-types';
import Udesk from 'Udesk';
import BraftEditor from 'braft-editor';
class MassagePushConfigurationComponent extends React.Component {
    //#region Default Props
    static propTypes = {
        type: PropTypes.number,
        onPushTypesChange: PropTypes.func,
    };
    static defaultProps = {
        type: Udesk.enums.messageType.trigger.id,
        onPushTypesChange: null,
    };
    //#endregion

    state = {};
    privates = {
        messagePushType: Udesk.enums.messagePushType.filter((item) => Boolean(item.id)),
        // tinymcePath: '/static/vendor/tinymce/tinymce.min.js',
        inputValue: '',
        checkedTypesOpened: null,
        targets: [],
        showCustomize: false,
        textAreaRef: React.createRef(),
        associateDivRef: React.createRef(),
        showAssociateTimer: null,
        associateActiveTab: 'data',
        operatorList: [],
        associateList: [],
        associateOperatorList: [],
        associateOperatorListSource: [],
        associateListSource: [],
        associateListSearchValue: '',
        associateOperatorIconList: [
            {
                subType: 0,
                icon: 'hanshu',
            },
            {
                subType: 1,
                icon: 'jia',
            },
            {
                subType: 2,
                icon: 'jian',
            },
            {
                subType: 3,
                icon: 'cheng',
            },
            {
                subType: 4,
                icon: 'chu',
            },
            {
                subType: 21,
                icon: 'dengyu',
            },
            {
                subType: 22,
                icon: 'dayu',
            },
            {
                subType: 23,
                icon: 'dayudengyu',
            },
            {
                subType: 24,
                icon: 'xiaoyu',
            },
            {
                subType: 25,
                icon: 'xiaoyudengyu',
            },
            {
                subType: 51,
                icon: 'yu',
            },
            {
                subType: 52,
                icon: 'huo',
            },
            {
                subType: 53,
                icon: 'fei',
            },
        ],
    };
    static computes = {};
    actions = {
        onCheckedTypesChange(id, e) {
            let checked = e.target.checked;
            e.stopPropagation && e.stopPropagation();
            let { checkedTypes: newCheckedTypes = [] } = this.props;
            if (checked) {
                newCheckedTypes.push(id);
            } else {
                newCheckedTypes = newCheckedTypes.filter((item) => item !== id);
            }
            this.props.onPushTypesChange && this.props.onPushTypesChange(newCheckedTypes);
        },
        onTinymceChange(actionType, value) {
            let inputValue = value;
            if (value.target) {
                inputValue = value.target.value;
            }
            let newPushConf = this.actions.setActionDetailsValue('content', inputValue, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        onInputChange(actionType, key, e) {
            let inputValue = e;
            if (e.target) {
                inputValue = e.target.value;
            }
            let newPushConf = this.actions.setActionDetailsValue(key, inputValue, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        onAdditionalDataChange(id, key, actionType, e) {
            let inputValue = e;
            let { pushConf } = this.props;
            let newPushConf = { ...pushConf };
            if (e.target) {
                inputValue = e.target.value;
            }
            newPushConf.actionDetails.additionalDataList.forEach((item) => {
                if (item._id === id) {
                    item[key] = inputValue;
                }
            });
            this.trigger('onPushConfItemChange', newPushConf);
        },
        addAdditionalData() {
            let { pushConf } = this.props;
            let newPushConf = { ...pushConf };
            newPushConf.actionDetails.additionalDataList.push({
                _id: Udesk.utils.string.uuid(),
                key: '',
                value: '',
            });
            this.trigger('onPushConfItemChange', newPushConf);
        },
        deleteAdditionalData(index, item) {
            let id = item.childId;
            let { pushConf } = this.props;
            let newPushConf = { ...pushConf };
            let additionalDataList = newPushConf.actionDetails.additionalDataList.filter(
                (item) => item._id !== id
            );
            newPushConf.actionDetails.additionalDataList = additionalDataList;
            this.trigger('onPushConfItemChange', newPushConf);
        },
        setActionDetailsValue(key, value, actionType) {
            let { pushConf } = this.props;
            let newPushConf = { ...pushConf };
            if (!newPushConf.actionDetails) {
                newPushConf.actionDetails = {};
            }
            newPushConf.actionDetails[key] = value;
            return newPushConf;
        },
        onTargetsChange(actionType, checkedValue) {
            let newPushConf = this.actions.setActionDetailsValue('pushTargetList', checkedValue, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        onMessageTypeChange(actionType, e) {
            let type = e.target.value;
            let newPushConf = this.actions.setActionDetailsValue('messageType', type, actionType);
            let content = '';
            if (content && isJSON(content)) {
                content = JSON.stringify(JSON.parse(content), null, 2);
            }
            newPushConf = this.actions.setActionDetailsValue('content', content, actionType);
            newPushConf = this.actions.setActionDetailsValue('url', '', actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        handleMarkdownEditorChange (actionType, { html, text }) {
            let newPushConf = this.actions.setActionDetailsValue('content', text, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        onSdkPushTypeChange(actionType, e) {
            let type = e.target.value;
            let newPushConf = this.actions.setActionDetailsValue('type', type, actionType);
            let content = '';
            if (type === Udesk.enums.sdkPushType.webHook.id) {
                newPushConf = this.actions.setActionDetailsValue('additionalDataList', [], actionType);
            }
            if (type === Udesk.enums.sdkPushType.dingDing.id) {
                const demoText = /* 我就是我, 是不一样的烟火@156xxxx8827 */this.locales.components.massagePushConfiguration.interfacePush.component.iAmMeItsADifferentFireworksxxxx8827;
                content = `
                {
                    "msgtype": "text", 
                    "text": {
                        "content": "${demoText}"
                    }, 
                    "at": {
                        "atMobiles": [
                            "156xxxx8827", 
                            "189xxxx8325"
                        ], 
                        "isAtAll": false
                    }
                }`;
            }
            if (type === Udesk.enums.sdkPushType.weChat.id) {
                const demoText = /* Hi，我是机器人质检预警消息通知，由于10月17日添加到群 */this.locales.components.massagePushConfiguration.interfacePush.component.hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober;
                content = `
                {
                    "msgtype": "text",
                    "text": {
                        "content": "${demoText}"
                    }
                }`;
            }
            if (type === Udesk.enums.sdkPushType.customer.id) {
                content = `
                {
                    "key": "value"
                }`;
            }
            if (content && isJSON(content)) {
                content = JSON.stringify(JSON.parse(content), null, 2);
            }
            newPushConf = this.actions.setActionDetailsValue('content', content, actionType);
            newPushConf = this.actions.setActionDetailsValue('url', '', actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        onSelectChange(type, actionType, value) {
            let newPushConf = this.actions.setActionDetailsValue(type, value, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        handleEditorChange(actionType, editorState) {
            // let newPushConf = this.actions.setActionDetailsValue('content', editorState.toHTML(), actionType);
            let { pushConf } = this.props;
            let newPushConf = { ...pushConf };
            if (!newPushConf.actionDetails) {
                newPushConf.actionDetails = {};
            }
            newPushConf.actionDetails._editorState = editorState;
            newPushConf.actionDetails.content = editorState.toHTML();
            this.trigger('onPushConfItemChange', newPushConf);
        },
        showCustomize(pushConf, e) {
            this.privates.showCustomize = e.target.checked;
            if (!e.target.checked) {
                this.actions.onSelectChange('pushUserList', pushConf.actionType, []);
                this.actions.onSelectChange('pushRoleList', pushConf.actionType, []);
            }
            this.actions.update();
        },
        onPushObjectTypeChange(actionType, e) {
            let checked = e.target.checked;
            let checkedValue = Udesk.enums.pushObjectTypes.staff.id;
            if (checked) {
                checkedValue = Udesk.enums.pushObjectTypes.staff.id;
            } else {
                checkedValue = Udesk.enums.pushObjectTypes.email.id;
            }
            let newPushConf = this.actions.setActionDetailsValue('pushTargetType', checkedValue, actionType);
            this.trigger('onPushConfItemChange', newPushConf);
        },
        pushConfContentChange(pushConf){
            this.trigger('onPushConfItemChange', pushConf);
        },
    };

    //#region Life Cycle
    init() {
        this.privates.autoAddPushTargets = Udesk.enums.autoAddPushTarget.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        this.privates.sdkPushType = Udesk.enums.sdkPushType.map((item) => ({ label: item.name, value: item.id }));
        if(Array.isArray(this.props.pushTypeFilter)){
            this.privates.sdkPushType = this.privates.sdkPushType.filter(i => this.props.pushTypeFilter.includes(i.value));
        }
    }
    parseProps({ props, prevProps }) {
        let res = {};
        // let checkedTypesOpened = props.pushConf.filter(config => config._isOpen).map(config => config.actionType);
        // res.checkedTypesOpened = checkedTypesOpened;
        res.pushConf = Udesk.utils.object.deepCopy(props.pushConf);
        if(res.pushConf.actionDetails && !res.pushConf.actionDetails._editorState){
            res.pushConf.actionDetails._editorState = BraftEditor.createEditorState(res.pushConf.actionDetails.content || null);
        }
        if((res.pushConf.actionDetails && res.pushConf.actionDetails.pushRoleList && res.pushConf.actionDetails.pushRoleList.length) || (res.pushConf.actionDetails && res.pushConf.actionDetails.pushUserList && res.pushConf.actionDetails.pushUserList.length)){
            res.showCustomize = true;
        }
        return res;
    }
    //#endregion
}
function isJSON(str) {
    if (
        /^[\],:{}\s]*$/.test(
            str
                .replace(/\\["\\/bfnrtu]/g, '@')
                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][ -]?\d+)?/g, ']')
                .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
        )
    ) {
        return true;
    } else {
        return false;
    }
}
export default MassagePushConfigurationComponent;
