import { udeskify, isUdeskified, attachActionBehaviors } from './udeskify';
import context, { init as initContext } from './context';
import * as redux from './redux';

export default {
    udeskify,
    isUdeskified,
    context,
    bindAction,
    redux,
};
export {
    init,
    udeskify,
    isUdeskified,
    context,
    bindAction
};

function init() {
    initContext.call(this.context);
}

function bindAction(action) {
    let wrappedAction = function () {
        return action.apply(this, arguments);
    };
    return attachActionBehaviors(action, wrappedAction, action.owner);
}
