import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Result, Button, Icon, Progress } from 'udesk-ui';
import './style.scss';
export default function ConversationSetting(props) {
    const locales = Locales['current'];

    const [progress, setProgress] = useState(0);
    const [refersh, needRefersh] = useState(1);
    useEffect(() => {
        let { sdkOptions, id } = props;
        if(!id) return;
        //查进度
        let url = Udesk.business.apiPath.concatApiPath(`voice-upload/progress/${id}`, sdkOptions);
        Udesk.ajax.get(url).then(
            (resp) => {
                //-1是没有任务进行中，0～100是有任务在进行
                if (resp?.data?.progress >= 0) {
                    setProgress(resp.data.progress);
                    if(resp.data.progress < 100){
                        setTimeout(() => {
                            needRefersh(refersh + 1);
                        }, 5000);
                    }
                }
                // setProgress(progress + 7);
                // if (progress + 7 < 100) {
                //     setTimeout(() => {
                //         needRefersh(refersh + 1);
                //     }, 5000);
                // }
            },
            (reason) => {
                Udesk.ui.notify.error(reason.message);
            }
        );
    }, [refersh]);

    const recordCheckingText = props.type === 'im' ? /* 文本质检中 */locales.pages.recordUpload.detail.result.template.textUnderInspection : locales.components.pages.recordUpload.recordChecking;
    const recordCheckSuccessText = props.type === 'im' ? /* 文本质检完成 */locales.pages.recordUpload.detail.result.template.textQualityInspectionCompleted : locales.components.pages.recordUpload.recordCheckSuccess;
    return (
        <div className='record-upload-result-component'>
            <Result
                title={
                    progress < 100
                        ? recordCheckingText
                        : recordCheckSuccessText
                }
                icon={
                    progress < 100 ? (
                        <React.Fragment>
                            {/* <Icon type='yinpinwenjian' style={{position: 'absolute', fontSize: '48px', color: '#1b6dff'}} /> */}
                            <Icon classname='search-icon' style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '48px' }} type='ic-search-sousuo'></Icon>
                        </React.Fragment>
                    ) : (
                        <Icon style={{ color: '#0FBA26', fontSize: '48px' }} type='ic-right-circle-duigou1'></Icon>
                    )
                }
                extra={
                    <React.Fragment>
                        {progress < 100 && props.type !== 'im' &&  (
                            <div>
                                <Progress percent={progress} showInfo={false} status='active' strokeWidth={16} />
                                <div style={{ margin: '16px auto 40px' }}>{`${progress}%`}</div>
                            </div>
                        )}
                        <Button onClick={props.backToIndex}>{locales.components.pages.recordUpload.backToIndex}</Button>
                    </React.Fragment>
                }
            />
        </div>
    );
}
