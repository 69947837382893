import React from 'react';
import PropTypes from 'prop-types';
import Udesk from '../../udesk/index';

class ReactSelect extends React.Component {
    static computes = {
        fullClassNames: function ({ props, state, privates, locales }) {
            let {
                classNames
            } = props;
            let fullClassNames = ["component-react-select"];
            if (classNames != null) {
                fullClassNames.push(classNames);
            }
            return fullClassNames.join(" ");
        },
        nullSelectPlaceholder: function ({ props, state, privates, locales }) {
            let {
                nullSelectPlaceholder
            } = props;
            if (nullSelectPlaceholder == null || nullSelectPlaceholder === "") {
                nullSelectPlaceholder = locales.labels.pleaseSelect;
            }
            return nullSelectPlaceholder;
        },
        dataSource: function ({ props, state, privates, locales }) {
            let {
                dataScource
            } = props;
            if (!Array.isArray(dataScource) && !(dataScource instanceof Array)) {
                dataScource = [];
            }
            return dataScource;
        },
    }

    actions = {
        onInnerChanged(e) {
            this.props.onChanged(e.target.value);
        }
    }

    render() {
        let {
            value,
            defaultValue,
            valueField,
            nameField,
            disabled,
            hasNullSelect,
            nullSelectValue,
        } = this.props;
        let {
            privates: { computes },
        } = this;
        return (
            <select className={computes.fullClassNames} disabled={disabled} defaultValue={defaultValue} value={value} onChange={this.actions.onInnerChanged}>
                <If condition={hasNullSelect}>
                    <option value={nullSelectValue}>{computes.nullSelectPlaceholder}</option>
                </If>
                <For each="item" index="tagOptionIndex" of={computes.dataSource}>
                    <option key={`react-select-item-${tagOptionIndex}`} value={item[valueField]}>{item[nameField]}</option>
                </For>
            </select>
        );
    }
}

ReactSelect.propTypes = {
    languageCode: PropTypes.string,
    classNames: PropTypes.string,
    dataScource: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    valueField: PropTypes.string,
    defaultValue: PropTypes.string,
    nameField: PropTypes.string,
    disabled: PropTypes.bool,
    hasNullSelect: PropTypes.bool,
    nullSelectValue: PropTypes.string,
    nullSelectPlaceholder: PropTypes.string,
    onChanged: PropTypes.func,
};

ReactSelect.defaultProps = {
    languageCode: "",
    classNames: '',
    value: undefined,
    defaultValue:undefined,
    dataScource: [],
    valueField: "id",
    nameField: "name",
    disabled: false,
    hasNullSelect: true,
    nullSelectValue: "",
    nullSelectPlaceholder: "",
    onChanged: undefined,
};

export default Udesk.react.udeskify(ReactSelect);
