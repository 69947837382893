import Udesk from 'Udesk';
import React from 'react';
import PropTypes from 'prop-types';

export default class GearOptionListComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        valueField: 'value',
        evaluationField: 'evaluation',
        formulaField: 'formula',
        gearOptions: null,
        scoreType: Udesk.enums.calculateScoreTypes.add.id,
        type: Udesk.enums.scoreType.manualScore.id,
        onChange: null,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        valueField: PropTypes.string,
        evaluationField: PropTypes.string,
        formulaField: PropTypes.string,
        gearOptions: PropTypes.array,
        scoreType: PropTypes.any,
        type: PropTypes.any,
        onChange: PropTypes.func,
    };
    //#endregion

    //#region state
    state = {
        gearOptions: [],
        gearOptionsIndex: 0,
    };
    //#endregion

    //#region Life Cycle
    componentDidMount() {
        let gearOptions = this.props.gearOptions;
        if (!gearOptions || gearOptions.length === 0) {
            gearOptions = [{}];
        }
        this.setState({
            gearOptions: gearOptions.map((item, index) => ({ _id: index, ...item })),
            gearOptionsIndex:
                gearOptions.length && gearOptions[gearOptions.length - 1].id
                    ? gearOptions[gearOptions.length - 1].id * 1 + 1
                    : gearOptions.length,
        });
    }
    //#endregion

    //#region actions
    actions = {
        addNewGearOption() {
            const { gearOptions, gearOptionsIndex } = this.state;
            gearOptions.push({ _id: gearOptionsIndex });
            this.setState({
                gearOptions: [].concat(gearOptions),
                gearOptionsIndex: gearOptionsIndex + 1,
            });
        },

        deleteGearOption(gearOption) {
            let gearOptions = this.state.gearOptions;
            let existIndex = gearOptions.indexOf(gearOption);

            if (existIndex !== -1) {
                gearOptions.splice(existIndex, 1);
            }
            this.setState(
                (state) => ({
                    gearOptions: state.gearOptions.filter((item) => item !== gearOption),
                }),
                () => {
                    this.trigger('onChange', gearOptions);
                }
            );
        },

        changeGearOptionValue(gearOption, field, e) {
            gearOption[field] = e.target.value;
            let gearOptions = this.state.gearOptions;
            this.setState({}, () => {
                this.trigger('onChange', gearOptions);
            });
        },
    };
    //#endregion
}
