import React from 'react';
import Udesk from 'Udesk';
import { Modal, Radio, Checkbox, Row, Col, Space, Button } from 'udesk-ui';
import ReInspectionModelComponent from './component';

export default class ReInspectionModelTemplate extends ReInspectionModelComponent {
    render() {
        let { actions, locales } = this;
        let {reInspectionTypes} = this.privates;
        let { reInspectionConfigVisible, allPoints, reInspectionPoints, reInspectionType, onCancel, onOk, title, okButtonLoading = false } = this.props;
        return (
            <React.Fragment>
                <Modal width={600} wrapClassName='re-inspection-modal-component-wrap' visible={reInspectionConfigVisible} closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right" onCancel={onCancel} footer={null} maskClosable={false} title={title}>
                    <div className="re-inspection-modal-config-content">
                        <Radio.Group onChange={actions.reInspectionTypeChanged} value={reInspectionType} style={{marginBottom: 8}}>
                            {
                                reInspectionTypes.map(type => {
                                    return (
                                        <React.Fragment>
                                            <Radio value={type.id} style={{ display: type.key === "recheckPoint" ? "inline-block" : "block" }}>{this.props.type==='test-set-list'?locales.components.pages.testSetList.recheckPoint:type.name}</Radio>
                                            <If condition={type.key === "recheckPoint"}>
                                                <Checkbox disabled={reInspectionType !== Udesk.enums.reInspectionTypes.recheckPoint.id} checked={allPoints} onChange={actions.allPointsChecked}>
                                                    {locales.labels.selectAll}
                                                </Checkbox>
                                            </If>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </Radio.Group>
                        <div style={{ height: 300, overflowY: 'auto' }}>
                            <For each="firstCategory" index="index" of={reInspectionPoints}>
                                <div className="re-inspection-modal-config-content-points-category" title={firstCategory.name}>
                                    {firstCategory.name}
                                </div>
                                <For each="secondCategory" index="index" of={firstCategory.children}>
                                    <If condition={secondCategory.points && secondCategory.points.length}>
                                        <div className="re-inspection-modal-config-content-points-category" title={secondCategory.name}>
                                            {secondCategory.name}
                                        </div>
                                        <Row>
                                            <For each="point" index="index" of={secondCategory.points}>
                                                <Col span={6}>
                                                    <div title={point.name}>
                                                        <Checkbox style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} disabled={reInspectionType !== Udesk.enums.reInspectionTypes.recheckPoint.id} checked={point._checked} onChange={actions.pointChecked.params(point)}>
                                                            {point.name}
                                                        </Checkbox>
                                                    </div>
                                                </Col>
                                            </For>
                                        </Row>
                                    </If>
                                </For>
                            </For>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Space>
                            <Button onClick={onOk} loading={okButtonLoading}>
                                {locales.components.pages.qualityInspectionList.beginReQualityInspection}
                            </Button>
                            <Button onClick={onCancel}>
                                {locales.components.pages.qualityInspectionList.cancelReQualityInspection}
                            </Button>
                        </Space>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
