// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostIntelligentPartnerTaskFinishRecordsAdminExportBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 管理员端查询学员的历史记录
 *
 * @export
 * @tags 智能陪练-历史���习数据
 * @link [POST] /intelligentPartnerTaskFinishRecords/admin/export
 */
export function postIntelligentPartnerTaskFinishRecordsAdminExport(
    data: PostIntelligentPartnerTaskFinishRecordsAdminExportBody
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerTaskFinishRecordsAdminExport(
    data: PostIntelligentPartnerTaskFinishRecordsAdminExportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerTaskFinishRecordsAdminExport(
    data: PostIntelligentPartnerTaskFinishRecordsAdminExportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentPartnerTaskFinishRecordsAdminExportBody, never, never>(
        '/intelligentPartnerTaskFinishRecords/admin/export',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerTaskFinishRecordsAdminExport',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-历史���习数据'],
        path: '/intelligentPartnerTaskFinishRecords/admin/export',
        method: 'post',
    },
];
