// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { PostLocalInspectRecordUploadBody, BaseResponseVoid } from '../../../../types';

/**
 * 上传质检任务
 *
 * @export
 * @tags local-upload-inspect-controller
 * @link [POST] /local/inspect/record/upload
 */
export function postLocalInspectRecordUpload(
    data: PostLocalInspectRecordUploadBody
): Promise<BaseResponseVoid>;
export function postLocalInspectRecordUpload(
    data: PostLocalInspectRecordUploadBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postLocalInspectRecordUpload(
    data: PostLocalInspectRecordUploadBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostLocalInspectRecordUploadBody, never, never>('/local/inspect/record/upload', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postLocalInspectRecordUpload',
    });
}

export const meta = [
    {
        tags: ['local-upload-inspect-controller'],
        path: '/local/inspect/record/upload',
        method: 'post',
    },
];
