import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Udesk from '../../udesk/index';

class ReactTreeNodeActions extends React.Component {
    //#region defaultProps
    static defaultProps = {
        node: {},
        addIconClass: "fa fa-plus-circle",
        editIconClass: "fa fa-edit",
        deleteIconClass: "fa fa-remove",
        draggable: false,
        onNodeAction: null,
    }
    static propTypes = {
        node: PropTypes.object,
        addIconClass: PropTypes.string,
        editIconClass: PropTypes.string,
        deleteIconClass: PropTypes.string,
        draggable: PropTypes.bool,
        onNodeAction: PropTypes.func,
    }
    //#endregion

    actions = {
        add(parentNode, args) {
            this.trigger("onNodeAction", "add", parentNode, args);
        },
        edit(node, args) {
            this.trigger("onNodeAction", "edit", node, args);
        },
        delete(node, args) {
            this.trigger("onNodeAction", "delete", node, args);
        }
    }
}

export default ReactTreeNodeActions;