import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { FormBuilder, Row, Col, Space, Button } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import ImgUpload from 'Component/common/img-upload';
import { getCompanysSysConfig, putCompanysSysConfig } from 'src/api/companys/sys/config';
import { SysConfigResponse } from 'src/api/types';
// import './style.scss';

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const [form] = FormBuilder.useForm();

    const [btnLoading, setBtnLoading] = useState(true);
    const [initialValues, setInitialValues] = useState<Pick<SysConfigResponse, 'logo' | 'icon' | 'name' | 'logoType'>>({
        logoType: 0,
        name: locales.pages.logoSite.index.intelligentQualityInspection,
    });
    const onFinish = (values) => {
        putCompanysSysConfig(Object.keys(values).reduce((prev, cur) => {
            if(values[cur] || typeof values[cur] === 'number'){
                prev[cur] = values[cur];
            }
            return prev;
        }, {} as typeof values), {
            successMsg: locales.pages.logoSite.index.savedSuccessfully,
        }).then(() => {
            Udesk.data.buildCompany({company: values});
            window.location.reload();
        });
    };
    const config: FormProps = {
        initialValues: initialValues,
        customComponents: { ImgUpload },
        fields: [
            {
                name: 'logoType',
                label: locales.pages.logoSite.index.logoSettings,
                type: 'RadioGroup',
                props: {
                    options: [
                        { label: locales.pages.logoSite.index.default, value: 0 },
                        { label: locales.pages.logoSite.index.custom, value: 1 },
                    ],
                },
                rules: [
                    {
                        required: true,
                    },
                ],
                effect: {
                    visible: {
                        1: ['name', 'logo', 'icon'],
                    },
                },
            },
            {
                name: 'name',
                label: locales.pages.logoSite.index.title,
                type: 'Input',
                props: {
                    placeholder: locales.pages.logoSite.index.theTitleOfTheSystemDisplayShouldBeWithinWords,
                    showCount: true,
                    maxCount: 16,
                    style: {
                        width: 250,
                    },
                },
                rules: [
                    {
                        max: 16,
                    },
                    {
                        required: true,
                    },
                ],
            },
            {
                name: 'logo',
                label: locales.pages.logoSite.index.logoImageUpload,
                type: 'ImgUpload',
                addonAfter: (
                    <div style={{ color: 'rgba(0 ,0, 0, .65)' }}>
                        <div>{locales.pages.logoSite.index.pleaseSelectAPictureWithASizeOfNoMoreThankb}</div>
                        <div>{locales.pages.logoSite.index.inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions}</div>
                        <div>{locales.pages.logoSite.index.Size5050Pixels}</div>
                        <div>{locales.pages.logoSite.index.FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite}</div>
                    </div>
                ),
            },
            {
                name: 'icon',
                label: locales.pages.logoSite.index.browserLabelIcon,
                type: 'ImgUpload',
                addonAfter: (
                    <div style={{ color: 'rgba(0 ,0, 0, .65)' }}>
                        <div>{locales.pages.logoSite.index.pleaseSelectAPictureWithASizeOfNoMoreThankb}</div>
                        <div>{locales.pages.logoSite.index.toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions}</div>
                        <div>{locales.pages.logoSite.index.Size3232Pixels}</div>
                        <div>{locales.pages.logoSite.index.FormatPictureWithTransparentBackgroundPNGOrJpg}</div>
                    </div>
                ),
            },
        ],
        footerRender: false,
        onFinish: onFinish,
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };
    useEffect(() => {
        setBtnLoading(true);
        getCompanysSysConfig().then((resp) => {
            const { logo, icon, name, logoType } = resp.data || {};
            setInitialValues({ logo, icon, name, logoType });
        }).finally(() => {
            setBtnLoading(false);
        });
    }, []);
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    return (
        <Page loading={btnLoading} pageBodyClassName='udesk-qa-logo-site-page-index' title={locales.pages.logoSite.index.logoSettings} padding={true}>
            <FormBuilder form={form} {...config}></FormBuilder>
            <Row>
                <Col offset={4}>
                    <Space>
                        <Button loading={btnLoading && { delay: 200 }} onClick={() => form.resetFields()}>
                            {locales.labels.cancel}
                        </Button>
                        <Button loading={btnLoading && { delay: 200 }} type='primary' onClick={() => form.submit()}>
                            {locales.labels.save}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
