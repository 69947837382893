import providers from "./providers";

export default {
    log,
    info,
    warn,
    error,
    providers
};

function log(message) {
    // eslint-disable-next-line
    console.log(message);
    callProviders(this, message, "log");
}

function info(message) {
    // eslint-disable-next-line
    console.info(message);
    callProviders(this, message, "info");
}

function warn(message) {
    // eslint-disable-next-line
    console.warn(message);
    callProviders(this, message, "warn");
}

function error(message) {
    // eslint-disable-next-line
    console.error(message);
    callProviders(this, message, "error");
}

function callProviders(owner, message, level) {
    for (let [, logger] of Object.entries(owner.providers)) {
        if (typeof logger[level] === "function") {
            try {
                logger[level](message);
            } catch (err) {
                // 容错性处理，如果有的话，则执行，如果没有此方法，也不报错
            }
        }
    }
}