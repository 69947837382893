import React from 'react';
import Udesk from 'Udesk';
import { Icon } from 'udesk-ui';
import Push from 'push.js';
import { parseInt } from 'lodash-es';
// import { NavLink } from 'react-router-dom';

// let errorTimer = null;
class Component extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        evtSource: null,
        notification: null,
        elementId: null,
        headerHeight: 16,
        startTime: 0,
        disable: false,
        agentEmail: '',
        agentId: '',
        type: null, //通对话类型
        id: null, //通对话id
        visible: true,
        notificationNotReadedList: [],
        initializeProperties: null,
        isError: true,
        systemExtraParams: '',
    };

    actions = {
        onHelpMenuBarMouseDown(event) {
            //只允许鼠标左键拖动
            if (event.button !== 0) return;
            this.privates.startTime = event.timeStamp;
            let isMove = false;
            let helpBoxDom = document.querySelector('.udesk-qa-massage-sdk-new');
            // let tooltipDom = document.querySelector(".udesk-qa-massage-sdk-tooltip");
            let tooltipDomList = this.privates.notificationNotReadedList.map((item) =>
                document.querySelector(`#udesk-qa-massage-sdk-tooltip-${item.id}`)
            );
            let windowH = document.documentElement.clientHeight;
            let baseY = event.clientY;
            let baseT = helpBoxDom.offsetTop;
            let headerHeight = this.privates.headerHeight;
            let that = this;
            function Move(event) {
                let moveY = event.clientY - baseY;
                let resaultT = baseT + moveY;

                if (Math.abs(moveY) > 4) {
                    isMove = true;
                }
                if (resaultT < headerHeight) {
                    resaultT = headerHeight;
                }
                if (resaultT > windowH - 90) {
                    resaultT = windowH - 90;
                }
                helpBoxDom.style.top = resaultT + 'px';
                // tooltipDom.style.top = resaultT + 17 + "px";
                for (let i = 0; i < tooltipDomList.length; i++) {
                    if (resaultT > windowH / 2) {
                        tooltipDomList[i].style.top = resaultT + 17 - 88 * i + 'px';
                    } else {
                        tooltipDomList[i].style.top = resaultT + 17 + 88 * i + 'px';
                    }
                }
                that.privates.notificationNotReadedList.forEach((item, index) => {
                    if (resaultT > windowH / 2) {
                        item.top = resaultT + 17 - 88 * index + 'px';
                    } else {
                        item.top = resaultT + 17 + 88 * index + 'px';
                    }
                });
                return false; //解决拖动选择文字的BUG
            }

            function Up(event) {
                $(document).off('mousemove');
                $(document).off('mouseup');
                if (isMove) {
                    let top = helpBoxDom.style.top;
                    window.localStorage.setItem('udesk_qa_massage_sdk_top', top);
                    that.actions.update();
                } else {
                    event.stopPropagation();
                }
            }
            $(document).mousemove(Move);
            $(document).mouseup(Up);
        },
        onSortMouseUp(e) {
            if (this.privates.startTime) {
                // let diffTime = e.timeStamp - this.privates.startTime;
                // diffTime < 150 && this.actions.open(); //小于150就执行单击操作
                this.privates.startTime = 0;
            }
        },
        setAgentProperties({
            beginTime,
            endTime,
            agentEmailList,
            agentNoList,
            dataCallIdList,
            judgeStrategy,
            disable,
            userId,
        }) {
            let { sdkOptions } = this.props;
            if (disable) {
                this.privates.disable = disable;
                this.privates.evtSource && this.actions.linkClose();
                this.actions.update();
                return;
            } else {
                this.privates.disable = disable;
                this.actions.update();
            }
            if (!this.privates.evtSource) {
                if (
                    (userId || this.props.userId) &&
                    (!sdkOptions.methods ||
                        (sdkOptions.methods && !sdkOptions.methods.initializeProperties))
                ) {
                    //userId不对外暴露
                    //有userId 无initializeProperties => 走系统消息
                    this.privates.systemExtraParams = `${
                        userId || this.props.userId
                    }/${Udesk.utils.string.uuid()}`;
                    let extraUrl = `system/${this.privates.systemExtraParams}`;
                    this.privates.evtSource = this.actions.createEventSource(extraUrl);
                } else if (
                    !userId &&
                    sdkOptions.methods &&
                    sdkOptions.methods.initializeProperties
                ) {
                    //无userId 有initializeProperties => 走SDK
                    this.actions.linkStart(
                        beginTime,
                        endTime,
                        agentEmailList,
                        agentNoList,
                        dataCallIdList,
                        judgeStrategy
                    );
                }
            } else {
                this.actions.changeFliterConditions(
                    beginTime,
                    endTime,
                    agentEmailList,
                    agentNoList,
                    dataCallIdList,
                    judgeStrategy
                );
            }
        },
        createEventSource(extraUrl) {
            let { sdkOptions } = this.props;
            this.privates.evtSource && this.actions.linkClose();
            let url = sdkOptions.props.domain + `/sse/sse/${extraUrl}`;
            // 创建长连接
            let evtSource = new EventSource(url);
            this.privates.disable = false;
            this.actions.update();
            setHeight();
            evtSource.addEventListener('message', (resp) => {
                if (resp && resp.data) {
                    let data = resp.data;
                    let content = '';
                    let title = '';
                    let dataLink = '';
                    let id = Udesk.utils.string.uuid();
                    if (typeof data === 'string') {
                        data = JSON.parse(data);
                    }
                    if (typeof data === 'object') {
                        content = data.message ? data.message.content : '';
                        title = data.message ? data.message.title : '';
                        dataLink = data.message ? data.message.dataLink : '';
                    }
                    if (Push.Permission.has()) {
                        this.privates.notification = Push.create(title, {
                            body: content,
                            icon: '/static/images/qa-massage.svg',
                            timeout: 8000,
                            onClick: function () {
                                data.message.dataLink && window.open(data.message.dataLink);
                                this.close();
                            },
                        });
                    } else {
                        let windowH = document.documentElement.clientHeight;
                        let sdkH = parseInt(
                            window.localStorage.getItem('udesk_qa_massage_sdk_top'),
                            10
                        );
                        this.privates.notificationNotReadedList.length &&
                            this.privates.notificationNotReadedList.forEach((item) => {
                                if (sdkH <= windowH / 2) {
                                    item.top = parseInt(item.top, 10) + 88 + 'px';
                                } else {
                                    item.top = parseInt(item.top, 10) - 88 + 'px';
                                }
                            });
                        this.privates.notificationNotReadedList.unshift({
                            ...data,
                            title: (
                                <div style={{ display: 'flex' }}>
                                    <div className="udesk-qa-massage-sdk-tooltip-content">
                                        {title}
                                    </div>
                                    <Icon
                                        antdIcon={true}
                                        type="CloseOutlined"
                                        onClick={this.actions.closeMassage.params(id)}
                                    ></Icon>
                                </div>
                            ),
                            content: (
                                <div
                                    title={content}
                                    className="udesk-qa-massage-sdk-tooltip-content"
                                >
                                    {content}
                                </div>
                            ),
                            id,
                            visible: true,
                            top: sdkH + 19 + 'px',
                            dataLink,
                        });
                        let timer = setTimeout(() => {
                            this.privates.notificationNotReadedList.forEach((item) => {
                                if (item.id === id) {
                                    item.visible = false;
                                    clearTimeout(item.timer);
                                }
                            });
                            this.privates.notificationNotReadedList =
                                this.privates.notificationNotReadedList.filter(
                                    (item) => item.id !== id
                                );
                            this.actions.update();
                        }, 10 * 1000);
                        this.privates.notificationNotReadedList.forEach((item) => {
                            if (item.id === id) {
                                item.timer = timer;
                            }
                        });
                        this.actions.update();
                    }
                }
            });
            // evtSource.addEventListener('error', (e) => {
            //     errorTimer = setTimeout(() => {
            //         this.privates.isError = true;
            //         this.actions.update();
            //     }, 15 * 1000);
            // });
            evtSource.addEventListener('open', (e) => {
                // if(errorTimer){
                //     clearTimeout(errorTimer);
                //     errorTimer = null;
                // }
                this.privates.isError = false;
                this.actions.update();
            });
            return evtSource;
        },
        postFliterConditions(
            beginTime,
            endTime,
            agentEmailList,
            agentNoList,
            dataCallIdList,
            judgeStrategy
        ) {
            let { sdkOptions } = this.props;
            let { initializeProperties } = this.privates;
            if (initializeProperties) {
                let url =
                    sdkOptions.props.domain +
                    `/sse/sse/filter/${initializeProperties.subdomain}/${initializeProperties.clientId}`;
                let params = {
                    beginTime,
                    endTime,
                    agentEmailList,
                    agentNoList,
                    dataCallIdList,
                    judgeStrategy,
                };
                for (var key in params) {
                    if (
                        !params[key] ||
                        (params[key] && Array.isArray(params[key] && params[key].length === 0))
                    ) {
                        delete params[key];
                    }
                }
                return Udesk.ajax.post(url, params);
            }
        },
        //SDK走linkStart 先设置过滤条件再创建外部url建立连接
        async linkStart(
            beginTime,
            endTime,
            agentEmailList,
            agentNoList,
            dataCallIdList,
            judgeStrategy
        ) {
            let { initializeProperties } = this.privates;
            setHeight();
            let extraUrl = `sdk/connect/${initializeProperties.subdomain}/${
                initializeProperties.clientId
            }/${initializeProperties.sdkIds.join()}`;
            await this.actions.postFliterConditions(
                beginTime,
                endTime,
                agentEmailList,
                agentNoList,
                dataCallIdList,
                judgeStrategy
            );
            let evtSource = this.actions.createEventSource(extraUrl);
            this.privates.evtSource = evtSource;
        },
        closeMassage(id) {
            let windowH = document.documentElement.clientHeight;
            let sdkH = parseInt(window.localStorage.getItem('udesk_qa_massage_sdk_top'), 10);
            this.privates.notificationNotReadedList.forEach((item) => {
                if (item.id === id) {
                    item.visible = false;
                    if (item.timer) {
                        clearTimeout(item.timer);
                    }
                }
            });
            this.actions.update();
            setTimeout(() => {
                this.privates.notificationNotReadedList =
                    this.privates.notificationNotReadedList.filter((item) => item.id !== id);
                this.privates.notificationNotReadedList.forEach((item, index) => {
                    if (index === 0) {
                        item.top = sdkH + 19 + 'px';
                    } else {
                        if (sdkH <= windowH / 2) {
                            item.top = sdkH + 19 + 88 * index + 'px';
                        } else {
                            item.top = sdkH + 19 - 88 * index + 'px';
                        }
                    }
                });
                this.actions.update();
            }, 200);
        },
        changeFliterConditions(
            beginTime,
            endTime,
            agentEmailList,
            agentNoList,
            dataCallIdList,
            judgeStrategy
        ) {
            this.actions.postFliterConditions(
                beginTime,
                endTime,
                agentEmailList,
                agentNoList,
                dataCallIdList,
                judgeStrategy
            );
        },
        linkClose() {
            this.privates.evtSource.close &&
                typeof this.privates.evtSource.close === 'function' &&
                this.privates.evtSource.close();
            let { sdkOptions } = this.props;
            let { initializeProperties } = this.privates;
            if (initializeProperties) {
                let extraUrl = `sdk/close/${initializeProperties.subdomain}/${
                    initializeProperties.clientId
                }/${initializeProperties.sdkIds.join()}`;
                let url = sdkOptions.props.domain + `/sse/sse/${extraUrl}`;
                Udesk.ajax.get(url).then(
                    (resp) => {
                        this.privates.evtSource = null;
                    },
                    (reason) => {}
                );
            } else {
                let extraUrl = `system/close/${this.privates.systemExtraParams}`;
                let url = sdkOptions.props.domain + `/sse/sse/${extraUrl}`;
                Udesk.ajax.get(url);
                this.privates.evtSource = null;
            }
        },
        onlineHandle() {
            this.privates.isError = false;
            this.actions.update();
        },
        offlineHandle() {
            this.privates.isError = true;
            this.actions.update();
        },
    };
    //#region Life Cycle
    componentDidMount() {
        let { sdkOptions } = this.props;
        if (sdkOptions.methods && sdkOptions.methods.initializeProperties) {
            this.privates.initializeProperties = sdkOptions.methods.initializeProperties();
            if (this.privates.initializeProperties) {
                if (!this.privates.initializeProperties.subdomain) {
                    throw new Error('subdomain is not found');
                }
                if (
                    !this.privates.initializeProperties.sdkIds ||
                    (this.privates.initializeProperties.sdkIds &&
                        !Array.isArray(this.privates.initializeProperties.sdkIds))
                ) {
                    throw new Error('sdkIds is not found or it is not Array');
                }
                this.privates.initializeProperties.clientId = Udesk.utils.string.uuid();
            } else {
                throw new Error("initializeProperties's return does not meet the requirements");
            }
        }
        //取缓存的meau_bar top值
        setHeight();
        // 窗口变化时，当超出屏幕高度，修改menu_bar的top
        $(window).on(`resize.smart-tools-sider-bar-${this.privates.elementId}`, function () {
            let windowH = document.documentElement.clientHeight;
            let helpBoxDom = document.querySelector('.udesk-qa-massage-sdk-new');
            // let tooltipDom = document.querySelector(".udesk-qa-massage-sdk-tooltip");
            if (helpBoxDom) {
                if (helpBoxDom.offsetTop > windowH - helpBoxDom.offsetHeight) {
                    helpBoxDom.style.top = windowH - helpBoxDom.offsetHeight - 10 + 'px';
                    // tooltipDom.style.top = windowH - helpBoxDom.offsetHeight + 7 + "px";
                }
            }
        });
        window.addEventListener('online', this.actions.onlineHandle);
        window.addEventListener('offline', this.actions.offlineHandle);
    }
    componentWillUnmount() {
        Push.clear();
        window.$(document).off(`click.smart-tools-sider-bar-${this.privates.elementId}`);
        window.$(document).off(`resize.smart-tools-sider-bar-${this.privates.elementId}`);
        this.privates.elementId = null;
        window.removeEventListener('online', this.actions.onlineHandle);
        window.removeEventListener('offline', this.actions.offlineHandle);
    }
    //#endregion
}

export default Component;

function setHeight() {
    //取缓存的meau_bar top值
    let udesk_qa_massage_sdk_top = window.localStorage.getItem('udesk_qa_massage_sdk_top');
    if (document.querySelector('.udesk-qa-massage-sdk-new')) {
        if (udesk_qa_massage_sdk_top) {
            document.querySelector('.udesk-qa-massage-sdk-new').style.top =
                udesk_qa_massage_sdk_top;
            // document.querySelector(".udesk-qa-massage-sdk-tooltip").style.top = parseInt(udesk_qa_massage_sdk_top, 10) + 17 + "px";
        } else {
            let windowH = document.documentElement.clientHeight;
            document.querySelector('.udesk-qa-massage-sdk-new').style.top = windowH / 2 + 'px';
            window.localStorage.setItem('udesk_qa_massage_sdk_top', windowH / 2 + 'px');
        }
    }
}
