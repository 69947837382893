import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { produce, setAutoFreeze } from 'immer';
import moment from 'moment';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import InspectionDetail from 'Component/pages/components/inspection-detail';
import { getReviewCallTests, postReviewCallTests, deleteReviewCallTests } from 'src/api/review/callTests';
import type { CallTestCreateRequest, ReviewCallTestParam, ReviewCallTestWrapper } from 'src/api/types';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Input, Space, Popconfirm } from 'udesk-ui';
import './style.scss';
import { postReviewCallTestsTest } from 'src/api/review/callTests/test';
import { TestSelect } from './components/test-select-component';

setAutoFreeze(false);

const INSPECT_DATA_SOURCE = 2;

const Template = React.memo((props: any) => {
    const locales = Locales['current'];

    const [detail, setDetail] = useState<ReviewCallTestWrapper>({});
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [testInput, setTestInput] = useState('');
    const [checkedIds, setCheckedIds] = useState<(number | string)[]>([]);
    const [loading, setLoading] = useState(false);
    const { value: reviewCallTestsTestParams, ...testSelectProps } = TestSelect.useTestSelect();
    // const [popconfirmVisible, setPopconfirmVisible] = useState(false);
    const inspectionDetailRef = React.useRef<any>(null);

    const sendMessage = (sender) => {
        if (!testInput) return;

        const inspectDataSource = INSPECT_DATA_SOURCE;

        setDetail(
            produce((draft) => {
                if (!draft.dialogRecords) {
                    draft.dialogRecords = {
                        dialogSentenceList: [],
                    };
                }
                (draft.dialogRecords as any).dialogSentenceList.push(
                    createNewMessage({ sender, content: testInput, inspectDataSource })
                );
            })
        );
        setTestInput('');
    };
    const saveDialogRecords = () => {
        postReviewCallTests({
            agentChannel: 0,
            bindingTaskId: -1,
            imLogInfos:
                (detail?.dialogRecords as any)?.dialogSentenceList?.filter(
                    (i) => !showCheckbox || checkedIds.includes(i.id)
                ) ?? [],
            inspectDataSource: INSPECT_DATA_SOURCE,
            type: 1, //* 1 用户保存的文本, 2 直接测试的文本
        }).then((resp) => {
            Udesk.ui.notify.success(locales.pages.tasks.manage.template.checkPointTest.saveSuccess);
        });
    };
    const onMessageItemCheckboxClick = useCallback((checkedIds) => {
        setCheckedIds(checkedIds);
    }, []);
    const deleteMessageItems = () => {
        if (showCheckbox) {
            setDetail(
                produce((draft) => {
                    if (!draft.dialogRecords) {
                        draft.dialogRecords = {
                            dialogSentenceList: [],
                        };
                    }
                    (draft.dialogRecords as any).dialogSentenceList = (
                        draft.dialogRecords as any
                    ).dialogSentenceList.filter((i) => !checkedIds.includes(i.id));
                })
            );
        } else {
            deleteReviewCallTests().then(() => {
                Udesk.ui.notify.success(locales.pages.tasks.manage.template.checkPointTest.clearSuccess);
                setDetail(
                    produce((draft) => {
                        if (!draft.dialogRecords) {
                            draft.dialogRecords = {
                                dialogSentenceList: [],
                            };
                        }
                        (draft.dialogRecords as any).dialogSentenceList = [];
                    })
                );
            });
        }
    };

    const requestDetail = () => {
        setLoading(true);
        getReviewCallTests()
            .then((reps) => {
                reps.data && setDetail(reps.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const handleVisibleChange = (visible) => {
    //     if (!visible) {
    //         setPopconfirmVisible(visible);
    //         return;
    //     }
    //     if (showCheckbox) {
    //         deleteMessageItems(); // next step
    //     } else {
    //         setPopconfirmVisible(visible); // show the popconfirm
    //     }
    // };

    const startTest = () => {
        let params: CallTestCreateRequest = {
            agentChannel: 0,
            bindingTaskId: -1,
            inspectDataSource: INSPECT_DATA_SOURCE,
            type: 2, //* 1 用户保存的文本, 2 直接测试的文本
        };
        if (Udesk.business.task.isTextDialogue(INSPECT_DATA_SOURCE)) {
            params.imLogInfos = (detail?.dialogRecords as any)?.dialogSentenceList ?? [];
        } else {
            params.sentences = (detail?.dialogRecords as any)?.dialogSentenceList ?? [];
        }
        postReviewCallTests(params).then((resp) => {
            const { currentType } = reviewCallTestsTestParams;
            const { checkedKeys, halfCheckedKeys } = reviewCallTestsTestParams[currentType];
            const categoryIds = [...checkedKeys, ...halfCheckedKeys]
                .filter((i) => i.includes('category-'))
                .map((i) => Number(i.split('-')[1]));
            const pointIds = checkedKeys.filter((i) => i.includes('point-')).map((i) => Number(i.split('-')[1]));
            let params: ReviewCallTestParam = {
                type: currentType,
                categoryIds,
            };
            if (currentType === 'REVIEW_KEY_EVENT') {
                params.smartTagIds = pointIds;
            } else {
                params.pointIds = pointIds;
            }
            return postReviewCallTestsTest(params).then((resp) => {
                const debugModeTagMatchResult = resp.data;
                setDetail(
                    produce((draft) => {
                        if (!draft.dialogRecords) {
                            draft.dialogRecords = {
                                dialogSentenceList: [],
                            };
                        }
                        (draft.dialogRecords as any).dialogSentenceList.forEach((element) => {
                            element.debugInfo = [];
                        });
                        const dialogSentenceList = (draft.dialogRecords as any).dialogSentenceList;
                        if (debugModeTagMatchResult) {
                            Object.keys(debugModeTagMatchResult).forEach((key) => {
                                const value = debugModeTagMatchResult?.[key];
                                if (Array.isArray(value)) {
                                    value.forEach((item) => {
                                        item.sentencesIndices.forEach((index) => {
                                            if (dialogSentenceList[index - 1]) {
                                                if (!dialogSentenceList[index - 1].debugInfo) {
                                                    dialogSentenceList[index - 1].debugInfo = [];
                                                }
                                                dialogSentenceList[index - 1].debugInfo.push({
                                                    ...item,
                                                    debugInfoType: key,
                                                });
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    })
                );
            });
        });
    };
    useEffect(() => {
        requestDetail();
    }, []);

    useEffect(() => {
        const index = ((detail?.dialogRecords as any)?.dialogSentenceList?.length ?? 0) - 1;
        scrollTo(index);
        function scrollTo(index) {
            const elements = document.getElementsByClassName('item');
            if (elements?.length) {
                if (elements[index]) {
                    (elements[index] as any)?.scrollIntoViewIfNeeded?.();
                } else {
                    setTimeout(() => {
                        scrollTo(index);
                    }, 200);
                }
            }
        }
    }, [(detail?.dialogRecords as any)?.dialogSentenceList?.length]);

    const checkBtnDisabled = useMemo(() => {
        const { currentType } = reviewCallTestsTestParams;
        const { checkedKeys, halfCheckedKeys } = reviewCallTestsTestParams[currentType];
        const categoryIds = [...checkedKeys, ...halfCheckedKeys]
            .filter((i) => i.includes('category-'))
            .map((i) => Number(i.split('-')[1]));
        const pointIds = checkedKeys.filter((i) => i.includes('point-')).map((i) => Number(i.split('-')[1]));
        return (
            ((detail?.dialogRecords as any)?.dialogSentenceList?.length ?? 0) === 0 ||
            (categoryIds.length === 0 && pointIds.length === 0)
        );
    }, [reviewCallTestsTestParams, (detail?.dialogRecords as any)?.dialogSentenceList]);
    return (
        <Page
            pageBodyClassName='gong-check-point-test-page-index'
            title={locales.pages.tasks.manage.template.checkPointTest.title}
            padding={true}
            loading={loading}
            loadingTip={locales.pages.tasks.manage.template.checkPointTest.running}
        >
            <div style={{ display: 'flex', overflow: 'hidden' }}>
                <InspectionDetail
                    ref={inspectionDetailRef}
                    rerenderOptimization={true}
                    // id={match.params.id}
                    taskId={-1}
                    detail={detail}
                    isTest={true}
                    showQualityCheckScoreTalk={false}
                    showCheckbox={showCheckbox}
                    onMessageItemCheckboxClick={onMessageItemCheckboxClick}
                    isDebug={true}
                    renderHeader={
                        <div className='audio-call-content-info-control'>
                            <div>{locales.pages.tasks.manage.template.checkPointTest.mock}</div>
                            <Space>
                                <Button type='link' size='small' onClick={saveDialogRecords}>
                                    {
                                        locales.pages.tasks.manage.template.checkPointTest[
                                            showCheckbox ? 'save' : 'saveAll'
                                        ]
                                    }
                                </Button>
                                <Button type='link' size='small' onClick={() => setShowCheckbox((prev) => !prev)}>
                                    {showCheckbox
                                        ? locales.pages.tasks.manage.template.checkPointTest.close
                                        : locales.labels.edit}
                                </Button>
                                <Popconfirm
                                    title={
                                        locales.pages.tasks.manage.template.checkPointTest[
                                            !showCheckbox ? 'request' : 'deletePopconfirmContent'
                                        ]
                                    }
                                    // visible={popconfirmVisible}
                                    // onVisibleChange={handleVisibleChange}
                                    onConfirm={deleteMessageItems}
                                    // onCancel={() => setPopconfirmVisible(false)}
                                    okText={
                                        showCheckbox
                                            ? locales.labels.delete
                                            : locales.pages.tasks.manage.template.checkPointTest.clear
                                    }
                                    cancelText={locales.labels.cancel}
                                >
                                    <Button type='link' size='small'>
                                        {showCheckbox
                                            ? locales.pages.tasks.manage.template.checkPointTest.delete
                                            : locales.pages.tasks.manage.template.checkPointTest.clear}
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </div>
                    }
                />
                <div className='gong-check-point-test-page-right'>
                    <TestSelect {...testSelectProps} />
                </div>
            </div>
            <div className='check-point-test-page-index-foot'>
                <div className='check-point-test-page-index-foot-left'>
                    <Input value={testInput} onChange={(e) => setTestInput(e.target.value)} style={{ flex: 1 }} />
                    <Button onClick={sendMessage.bind(null, 'customer')} disabled={!testInput}>
                        {locales.pages.tasks.manage.template.checkPointTest.customer}
                    </Button>
                    <Button onClick={sendMessage.bind(null, 'agent')} disabled={!testInput}>
                        {locales.pages.tasks.manage.template.checkPointTest.agent}
                    </Button>
                </div>
                <div className='check-point-test-page-index-foot-right'>
                    <Button
                        tooltip={checkBtnDisabled ? /* 请勾选要测试的监督项或关键事件，并确保模拟对话中有通话内容 */locales.pages.gong.checkPointTest.index.pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue : ''}
                        type='primary'
                        onClick={startTest}
                        disabled={checkBtnDisabled}
                        block
                    >
                        {locales.pages.tasks.manage.template.checkPointTest.test}
                    </Button>
                </div>
            </div>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

function createNewMessage({ sender, content, inspectDataSource }) {
    if (inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id) {
        return createImMessage({ sender, content });
    } else {
        return createCallMessage({ sender, content });
    }
}
function createCallMessage({ sender, content }) {
    return {
        averageSpeakSpeed: 0,
        emotionValue: -1,
        endSec: -5.368,
        fromSec: -5.368,
        maskText: null,
        role: sender,
        sentiment: null,
        speechRate: -1,
        text: content,
        volume: null,
    };
}
function createImMessage({ sender, content }) {
    return {
        content: JSON.stringify({
            type: 'message',
            version: 1,
            platform: 'web',
            data: {
                content,
                filename: '',
                filesize: '',
                fileext: '',
            },
            filename: '',
            filesize: '',
            fileext: '',
            no_need_save: true,
            seq_num: 1,
        }),
        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        id: Udesk.utils.string.uuid(),
        sender,
    };
}
