import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Udesk from '../../../udesk/index';
import Locales from '../../../udesk/locales';
import ReactEcharts from '../../react-echarts';
import ContainerWidth from '../container-width';
import { BubbleLoader } from 'react-css-loaders';
import CheckBox from '../../check-box';
import ReactTable from '../../react-table';

const ORIGINAL = '_original';
const TOOTIP = '_tootip';

const DIMENSION_VALUE_SEPARATOR = '-';
const DEFAULT_INTERVAL_SECONDS = 1;

const DEFAULT_CHART_CONFIG_OPTIONS = {
    width: undefined,
    height: undefined,
    theme: 'theme-udesk',

    yTitle: null,
    showLegend: true,
    title: undefined,
    subTitle: undefined,
    seriesLabelContentMode: 'recordValue',
    showSeriesLabels: false,
    seriesLabelPosition: null,
    showExtraPercentInLabels: false,
    reverseBarSeries: true,
    barMaxWidth: 30,
    showTooltips: true,
    legendLimit: undefined,
    isStacked: false,
    gaugeMaxValue: null,
    gaugeAxisPrecision: 0,
    enableDataZoom: false,
    dataZoomMinLimit: 0,
    customColor: [],
    textStyle: null,
    autoTruncateXLabelConfig: {
        autoTruncateLabel: false,
        truncateCharacter: '**',
        labelTruncateLength: 11,
        labelTruncateMode: 'middle',
    },
    toolboxConfig: 'right',
    legendType: 'scroll',
    legendTop: null,
    legendLeft: null,
    legendRight: null,
    legendBottom: null,
    legendOrient: 'horizontal',
    tooltipEnterable: false,
    tooltipConfine: false,
    tooltipBackgroundColor: '#fff',
    tooltipPadding: 20,
    tooltipBorderColor: '#E3E3E3',
    tooltipBorderWidth: 1,
    tooltipTextFontSize: 12,
    tooltipTextColor: '#333',
    tooltipTextLineHeight: 22,
    tooltipAxisPointerType: 'shadow',
    extraCssText: `max-width:100%;overflow: hidden;white-space:normal;word-wrap:wrap;word-break:break-all;overflow-wrap:break-word;`,
    //grid options
    gridTop: 10,
    gridLeft: 10,
    gridRight: 10,
    gridBottom: 30,
    gridContainLabel: true,
    pieLikeCenter: ['50%', '50%'],
    pieLikeRaduis: [0, '80%'],
    seriesLabelEmphasisOptions: null,
    mapName: 'china',
    mapUrls: null,
    mapSeriesRoam: false,
    chartOptions: undefined,
    formatXLabel: undefined,
    formatYLabel: undefined,
    formatTooltip: undefined,
    formatSeriesLabel: undefined,
    formatSeriesLabelValue: undefined,
    //events
};

class SetIntervalClass {
    constructor(intervalSeconds) {
        this._timeoutToken = null;
    }
    setInterval(callback, intervalSeconds) {
        if (typeof callback !== 'function') {
            throw new Error('callback is must be function.');
        }
        if (intervalSeconds == null || typeof intervalSeconds !== 'number' || intervalSeconds <= 0) {
            throw new Error('intervalSeconds is not valid.');
        }
        let context = this;
        if (this._timeoutToken) {
            this.clear();
        }
        this._timeoutToken = setTimeout(function () {
            callback();
            context.setInterval(callback, intervalSeconds);
        }, intervalSeconds * 1000);
    }

    clear() {
        clearTimeout(this._timeoutToken);
        this._timeoutToken = null;
    }
}

/**
 * ## reportEchartsComponent
 * - 显示数据源的功能，控制属性如下(2019.10)：
 *  - enableDataView 是否显示数据源，默认false
 *  - enableDataView4External 外部控制是否显示数据源，默认false
 *  - defaultShowDataView 默认进入图形后直接显示数据源，默认false
 *  - dataViewTableMaxHeight 数据源表格的最大高度，最好自己算一下，默认300
 *  - dataViewTableSortable  数据源表格是否可以排序，默认false
 *  - dataViewTableAscSort(columnData, sort) 升序方法 return compareFn
 *  - dataViewTableDescSort(columnData, sort) 降序方法 return compareFn
 *  - 切换按钮配置(预留的修改接口，当前环境中可以修改子类的defaultProps进行初始化)
 *  - toggleBtnClassName 转换button的类名
 *  - toggleBtnInfoClassName button提示信息的类名
 *  - toggleBtnToTableInfo toTable的提示信息
 *  - toggleBtnToTableIcon toTable的Icon
 *  - toggleBtnToChartInfo toChart的提示信息
 *  - toggleBtnToChartIcon toChart的Icon
 *  - onDataViewToggle  监听切换数据源的方法 function
 */
class ReportEchartsComponent extends React.Component {
    constructor(props) {
        super(props);
        if (Udesk.react.isUdeskified) {
            // throw new Error();
        }

        this.state = {
            title: '',
            errorMessage: '',
        };

        this.pendingRequest = null;
        this.isFullfiled = false;
        this.isSuccess = false;
        this.modelError = null;
        this._defaultShowDataView = props.defaultShowDataView;
    }

    '[privates]'() {
        return {
            containerWidth: null,
            intervalInstance: null,
            showDataView: this._defaultShowDataView,
            model: {
                values: null,
                info: null,
                noConfigReport: false,
            },
            dataViewTableItemsCache: [],
        };
    }

    // "[modelProps]"() {
    //     return ["chartType", "chartDataUrl", "chartDataParams"];
    // }
    modelProps = ['chartType', 'chartDataUrl', 'chartDataParams'];

    callMethod(methodName, ...args) {
        return (this.props[methodName] || this[methodName]).apply(this, args);
    }
    getRequestUrl() {
        return this.props.chartDataUrl;
    }
    getRequestParams() {
        return this.props.chartDataParams;
    }
    getRequestPromise() {
        let that = this;
        let dataUrl = this.callMethod('getRequestUrl');
        let params = this.callMethod('getRequestParams');
        if (dataUrl === '' || dataUrl == null) {
            throw new Error(`dataUrl is no valid.`);
        }
        if (!params) {
            params = {};
        }

        return new Promise((resolve, reject) => {
            Udesk.ajax.post(dataUrl, params).then(
                (resp) => {
                    if (that.props.chartType.id === Udesk.enums.echartTypes.customScatterPie.id) {
                        //散点图随机排序
                        let rows = resp.data.rows;
                        if (rows && Array.isArray(rows) && rows.length > 1) {
                            let firstRow = rows[0];
                            let surplusRows = rows.filter((row, index) => {
                                return index !== 0;
                            });
                            surplusRows.sort(randomsort);
                            resp.data.rows = [firstRow].concat(surplusRows);
                        }
                    }
                    resolve({
                        values: resp.data,
                        info: resp.data ? resp.data.info : null,
                        noConfigReport: resp.data.info ? resp.data.info.noConfig : false,
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || that.locales.business.getReportDataFailed);
                    reject(reason);
                }
            );
        });
    }
    model() {
        return this.callMethod('getRequestPromise');
    }
    parseModel(model) {
        if (model == null) {
            model = {};
        }
        let { values } = model;
        let { hasFieldGroups } = this.props;

        let selectedIndicators = [];
        let fieldGroups = [];

        if (hasFieldGroups) {
            let { model: oldModel } = this.privates;

            fieldGroups = getFieldGroupLists(values, oldModel.fieldGroups);

            selectedIndicators = getSelectedIndicators(fieldGroups);
            model.fieldGroups = fieldGroups;
            model.selectedIndicators = selectedIndicators;
        }
        model.chartData = getChartData(this, values, selectedIndicators, fieldGroups);
        return model;
    }
    getXLabel({ row, columns, indicators, dimensions }) {
        let dimensionNames = [];
        dimensions.forEach((dimension) => {
            let fieldKey = dimension.fieldKey;
            if (row[fieldKey]) {
                dimensionNames.push(row[fieldKey]);
            }
        });

        return dimensionNames.join(DIMENSION_VALUE_SEPARATOR);
    }
    getOriginalXLabel({ row, columns, indicators, dimensions }) {
        let originalDimensionNames = [];
        dimensions.forEach((dimension) => {
            let fieldKey = dimension.fieldKey;
            let fieldKeyOriginal = fieldKey + ORIGINAL;
            if (row[fieldKeyOriginal]) {
                originalDimensionNames.push(row[fieldKeyOriginal]);
            }
        });
        return originalDimensionNames.join(DIMENSION_VALUE_SEPARATOR);
    }

    componentDidMount() {
        let that = this;
        let { intervalSeconds, isTimeRefresh } = this.props;
        if (isTimeRefresh) {
            let intervalInstance = new SetIntervalClass();
            intervalInstance.setInterval(function () {
                if (!that.isDestroyed) {
                    that.actions.reloadModel();
                }
            }, intervalSeconds);
            this.privates.intervalInstance = intervalInstance;
        }
        this.isDidMount = true;
    }

    componentWillUnmount() {
        if (this.privates.intervalInstance) {
            this.privates.intervalInstance.clear();
        }
        this.privates.intervalInstance = null;
    }

    changeShowDataView() {
        this.privates.showDataView = !this.privates.showDataView;
        this.privates.model.chartData.tableItems = Udesk.utils.object.deepCopy(
            [],
            this.privates.dataViewTableItemsCache
        );
        if (!this.privates.showDataView) {
            this.privates.model.chartData.tableColumns.forEach((item) => {
                item.sortDirection = Udesk.enums.sortDirection.none;
            });
        }
        this.actions.update();
    }

    changeShowDataView4External() {
        this.changeShowDataView();
    }

    '[actions]'() {
        return {
            containerWidthChanged(width) {
                let prevWidth = this.privates.containerWidth;
                if (prevWidth !== width) {
                    this.privates.containerWidth = width;
                    if (
                        this.reactEchartsInstance &&
                        this.reactEchartsInstance.privates &&
                        this.reactEchartsInstance.privates.echartsAPI
                    ) {
                        this.reactEchartsInstance.privates.echartsAPI.resize();
                    }
                }
            },
            groupValueChanged(groupItem, index, values) {
                if (values && values.length === 0) {
                    Udesk.ui.notify.warn(this.locales.components.common.trendPart.noLessThanOne);
                    return;
                }
                if (!this.privates.model || !this.privates.model.fieldGroups) {
                    return;
                }

                let { fieldGroups, values: data } = this.privates.model;
                let targetFieldGroup = fieldGroups[index];

                if (targetFieldGroup) {
                    if (!groupItem.states.isChecked) {
                        fieldGroups.forEach((fieldGroup, ind) => {
                            if (index === ind) {
                                if (values && values.length > 0) {
                                    fieldGroup.values = [].concat(values);
                                } else {
                                    let firstGroupItem = fieldGroup.groupLists[0];
                                    fieldGroup.values = [firstGroupItem.fieldKey];
                                }
                                fieldGroup.states.isChecked = true;
                            } else {
                                fieldGroup.values = [];
                                fieldGroup.states.isChecked = false;
                            }
                        });
                    } else if (values == null) {
                        return;
                    } else {
                        targetFieldGroup.values = [].concat(values);
                    }
                    let selectedIndicators = getSelectedIndicators(fieldGroups);
                    this.privates.model.fieldGroups = [].concat(fieldGroups);
                    this.privates.model.selectedIndicators = [].concat(selectedIndicators);

                    let chartData = getChartData(this, data, selectedIndicators, fieldGroups);

                    this.privates.model.chartData = chartData;
                    this.actions.update();
                }
            },
            onSeriesClicked() {
                this.trigger('onSeriesClicked', ...arguments);
            },
            // 显示数据源
            showDataViewTableHandle() {
                this.changeShowDataView();
                // 向外界传出切换数据的事件
                if (this.props.onDataViewToggle) {
                    this.props.onDataViewToggle({
                        type: 'toggleDataView',
                        showDataView: this.privates.showDataView,
                    });
                }
            },
            dataViewTableSortHandle(columnData, sort) {
                const sortType = sort.name;
                /* eslint-disable */
                switch (sortType) {
                    case 'ascending':
                        let ascendingSortCompareFn = (a, b) => a[columnData.name] - b[columnData.name];
                        if (this.props.dataViewTableAscSort) {
                            ascendingSortCompareFn = this.props.dataViewTableAscSort(columnData, sort);
                        }
                        this.privates.model.chartData.tableItems.sort(ascendingSortCompareFn);
                        break;
                    case 'descending':
                        let descendingSortCompareFn = (a, b) => b[columnData.name] - a[columnData.name];
                        if (this.props.dataViewTableDescSort) {
                            descendingSortCompareFn = this.props.dataViewTableDescSort(columnData, sort);
                        }
                        this.privates.model.chartData.tableItems.sort(descendingSortCompareFn);
                        break;
                    case 'none':
                        this.privates.model.chartData.tableItems = this.privates.dataViewTableItemsCache;
                        break;
                    default:
                        break;
                }
                /* eslint-enable */
                this.actions.update();
            },
        };
    }

    render() {
        let {
            classNames,
            isFullfiled,
            isSuccess,
            chartType,
            showChartTitle,
            chartTitle,
            showChartExceedSameIndustry,
            chartConfigOptions: outConfigOptions,
            loadingColor,
            loadingSize,
            loadingDuration,
            language,
            noReportDataText,
            recordsCountLimit,
            isRealTime,
            isNeedLoader,
            GroupContainerComponent,
            hasFieldGroups,
            formatTooltip,
            showNoDataTip,
            enableDataView, // 显示原始数据
            enableDataView4External,
            dataViewTableMaxHeight,
            toggleBtnClassName,
            toggleBtnInfoClassName,
            toggleBtnToTableInfo,
            toggleBtnToTableIcon,
            toggleBtnToChartInfo,
            toggleBtnToChartIcon,
            dataViewTableSortable,
            loadingComponent: LoadingComponent,
            mapUrls,
        } = this.props;

        let { actions, privates } = this;

        let exceedSameIndustryTipsOne = Locales.get('components.reportEcharts.exceedSameIndustryTipsOne', language);
        let exceedSameIndustryTipsTwo = Locales.get('components.reportEcharts.exceedSameIndustryTipsTwo', language);

        let newChartConfigOptions = Object.assign({}, DEFAULT_CHART_CONFIG_OPTIONS);
        if (outConfigOptions != null) {
            Object.assign(newChartConfigOptions, outConfigOptions);
        }
        let { formatTooltip: customFormatTooltip, ...other } = newChartConfigOptions;
        formatTooltip = formatTooltip || customFormatTooltip;

        // mapUrls补充到配置项中
        if (!other.mapUrls) {
            other.mapUrls = mapUrls;
        }

        let noDataText = noReportDataText || Locales.get('components.reportEcharts.noReportValue', language);
        chartTitle = chartTitle || (privates.model && privates.model.info && privates.model.info.title);

        const chartData = privates.model.chartData || {};

        const toggleBtnConfig = {
            className: toggleBtnClassName,
            infoClassName: toggleBtnInfoClassName,
            toTable: {
                info: toggleBtnToTableInfo,
                icon: toggleBtnToTableIcon,
            },
            toChart: {
                info: toggleBtnToChartInfo,
                icon: toggleBtnToChartIcon,
            },
        };

        return (
            <div className={`report-echarts ${classNames}`}>
                <If condition={showChartTitle && chartTitle}>
                    <div className='report-echarts-header'>{chartTitle}</div>
                </If>
                {/* echarts 图形 */}
                <div
                    className={ClassNames(
                        'report-echarts-main',
                        { 'report-echarts-container-no-header': !showChartTitle || !chartTitle },
                        { 'has-field-group': hasFieldGroups }
                    )}
                >
                    <ContainerWidth onWidthCalculated={actions.containerWidthChanged} />
                    <Choose>
                        <When condition={isNeedLoader && !isFullfiled && !isSuccess}>
                            <If condition={LoadingComponent}>
                                <LoadingComponent color={loadingColor} duration={loadingDuration} size={loadingSize} />
                            </If>
                            <If condition={!LoadingComponent}>
                                <BubbleLoader color={loadingColor} duration={loadingDuration} size={loadingSize} />
                            </If>
                        </When>
                        <When condition={!isNeedLoader || (isFullfiled && isSuccess)}>
                            <If condition={this.props.children}>{this.props.children}</If>
                            <Choose>
                                <When
                                    condition={
                                        !privates.showDataView &&
                                        privates.model &&
                                        privates.model.chartData &&
                                        privates.model.chartData.items &&
                                        privates.model.chartData.items.length > 0
                                    }
                                >
                                    {/* 图形 */}
                                    <ReactEcharts
                                        ref={(reactEcharts) => (this.reactEchartsInstance = reactEcharts)}
                                        xLabelField='xLabel'
                                        isRealTime={isRealTime}
                                        type={chartType}
                                        records={privates.model.chartData.items}
                                        seriesColumns={privates.model.chartData.columns}
                                        recordsCountLimit={recordsCountLimit}
                                        onSeriesClicked={actions.onSeriesClicked}
                                        formatTooltip={formatTooltip}
                                        {...other}
                                    />
                                </When>
                                <When condition={privates.showDataView && (enableDataView || enableDataView4External)}>
                                    {/* table */}
                                    <div className={'data-view-table'}>
                                        <ReactTable
                                            showHorizontalScrollbar={true}
                                            verticalScrollbar={false}
                                            freezeHeader={true}
                                            sortable={dataViewTableSortable}
                                            onSorted={this.actions.dataViewTableSortHandle}
                                            maxHeight={dataViewTableMaxHeight}
                                            columns={chartData.tableColumns || []}
                                            items={chartData.tableItems || []}
                                        />
                                    </div>
                                </When>
                                <Otherwise>
                                    <If condition={showNoDataTip}>
                                        <p className='prompt'>{noDataText}</p>
                                    </If>
                                </Otherwise>
                            </Choose>
                        </When>
                    </Choose>
                    <If condition={enableDataView}>
                        <div
                            className={`toggle-button ${toggleBtnConfig.className ? toggleBtnConfig.className : ''}`}
                            onClick={this.actions.showDataViewTableHandle}
                        >
                            <If condition={!privates.showDataView}>
                                {/* 点击切换至数据表格 */}
                                <div
                                    className={`toggle-btn-info ${
                                        toggleBtnConfig.infoClassName ? toggleBtnConfig.infoClassName : ''
                                    }`}
                                >
                                    <If condition={toggleBtnConfig.toTable && toggleBtnConfig.toTable.info}>
                                        {toggleBtnConfig.toTable.info}
                                    </If>
                                </div>
                                <If condition={toggleBtnConfig.toTable && toggleBtnConfig.toTable.icon}>
                                    {toggleBtnConfig.toTable.icon()}
                                </If>
                            </If>
                            <If condition={privates.showDataView}>
                                {/* 点击切换至数据图形 */}
                                <div
                                    className={`toggle-btn-info ${
                                        toggleBtnConfig.infoClassName ? toggleBtnConfig.infoClassName : ''
                                    }`}
                                >
                                    <If condition={toggleBtnConfig.toChart && toggleBtnConfig.toChart.info}>
                                        {toggleBtnConfig.toChart.info}
                                    </If>
                                </div>
                                <If condition={toggleBtnConfig.toChart && toggleBtnConfig.toChart.icon}>
                                    {toggleBtnConfig.toChart.icon()}
                                </If>
                            </If>
                        </div>
                    </If>
                </div>
                {/* 字段筛选器 */}
                <If
                    condition={
                        hasFieldGroups &&
                        privates.model &&
                        privates.model.fieldGroups &&
                        privates.model.fieldGroups.length > 0
                    }
                >
                    <Choose>
                        <When condition={GroupContainerComponent}>
                            <GroupContainerComponent
                                fieldGroups={privates.model.fieldGroups}
                                groupSwitched={actions.groupValueChanged}
                                groupValueChanged={actions.groupValueChanged}
                            />
                        </When>
                        <Otherwise>
                            <div className='trend-group-box'>
                                <ul className='trend-group-container'>
                                    <For each='groupItem' index='index' of={privates.model.fieldGroups}>
                                        <li
                                            className={`group-item ${
                                                groupItem.states.isChecked ? `group-item-active` : ``
                                            }`}
                                            key={`group-item-${index}`}
                                            onClick={actions.groupValueChanged.params(groupItem, index, null)}
                                        >
                                            <CheckBox
                                                options={groupItem.groupLists}
                                                value={groupItem.values}
                                                itemClassNames='group-item-content-item'
                                                nameField='fieldTitle'
                                                valueField='fieldKey'
                                                isStopPropagation={true}
                                                onChanged={actions.groupValueChanged.params(groupItem, index)}
                                            />
                                        </li>
                                    </For>
                                </ul>
                            </div>
                        </Otherwise>
                    </Choose>
                </If>
                <If condition={showChartExceedSameIndustry && chartData.exceedName}>
                    <div className='report-echart-tips'>
                        <span>{exceedSameIndustryTipsOne}</span>
                        <span className='report-echart-tips-num'> {chartData.exceedName} </span>
                        <span>{exceedSameIndustryTipsTwo}</span>
                    </div>
                </If>
            </div>
        );
    }
}

ReportEchartsComponent.propTypes = {
    classNames: PropTypes.string,
    isRealTime: PropTypes.bool,
    chartTitle: PropTypes.string,
    showChartTitle: PropTypes.bool,
    showChartExceedSameIndustry: PropTypes.bool,
    loadingColor: PropTypes.string,
    loadingSize: PropTypes.number,
    loadingDuration: PropTypes.number,
    isNeedLoader: PropTypes.bool,
    formatTooltip: PropTypes.func,
    showNoDataTip: PropTypes.bool,
    onDataViewToggle: PropTypes.func,
    dataViewTableMaxHeight: PropTypes.number,
    dataViewTableSortable: PropTypes.bool,
};

ReportEchartsComponent.defaultProps = {
    classNames: '',
    chartType: null,
    showChartTitle: true,
    chartTitle: '',
    showChartExceedSameIndustry: false,
    showNoDataTip: true,
    chartConfigOptions: {},
    noReportDataText: '',
    language: 'zh-cn',
    isNeedLoader: false,
    GroupContainerComponent: '',
    hasFieldGroups: false,
    isReverseData: false,
    sdkOptions: {},
    stacticParams: null,
    chartDataUrl: '',
    chartDataParams: null,
    isTimeRefresh: false,
    intervalSeconds: DEFAULT_INTERVAL_SECONDS, //刷新频率
    data: null,
    recordsCountLimit: null,
    isRealTime: false,
    isFullfiled: false,
    isSuccess: false,
    loadingColor: '#BEC2C1',
    loadingSize: 12,
    loadingDuration: 1.4,
    formatTooltip: null,

    dateRange: {},
    ids: [],
    chartId: '',
    dateFieldValue: '',
    isAddTime: false,

    // 显示原始数据
    enableDataView: false, // 显示原始数据
    enableDataView4External: false, // 由外部控制是否显示原始数据；如果由外部控制，则此组件内不显示右下的切换图标; 配合使用： changeShowDataView4External()
    defaultShowDataView: false, // 默认是否显示表格
    dataViewTableMaxHeight: 300, // 表格的最大高度，必须从外面传入，使用者应该计算好，要不然表格和图形切换的时候就有可能跳跳跳跳跳跳 O(∩_∩)O哈哈~
    toggleBtnClassName: undefined, // 转换button的类名
    toggleBtnInfoClassName: undefined, // button提示信息的类名
    toggleBtnToTableInfo: 'toTable', // toTable的提示信息
    toggleBtnToTableIcon: () => 'toggle', // toTable的Icon
    toggleBtnToChartInfo: 'toChart', // toChart的提示信息
    toggleBtnToChartIcon: () => 'toggle', //
    onDataViewToggle: undefined, // toChart的Icon
    dataViewTableSortable: false, // 数据源表格是否可以排序
    dataViewTableAscSort: undefined, // 升序方法
    dataViewTableDescSort: undefined, // 降序方法
};

//转化表格列数据
function buildTableColumnsData(props, isUpdate = true) {
    let { reportData, orderTypeEnum, orderField, selectedFields, hasStackedHeaderGroups } = props;

    let allColumns = [];
    let stackedHeaderGroups = [];

    if (reportData) {
        let currentOrderField = undefined;
        let orderTypeEnums = undefined;
        if (orderField && orderTypeEnum) {
            currentOrderField = orderField;
            orderTypeEnums = orderTypeEnum;
        }

        if (reportData.header && reportData.header.dimensions && reportData.header.dimensions.length > 0) {
            let dimensions = reportData.header.dimensions;
            dimensions.forEach((dimensionItem) => {
                let visible = true;
                let sortDirection = null;
                if (selectedFields && selectedFields.length > 0) {
                    visible = selectedFields.some((fieldKey) => dimensionItem.fieldKey === fieldKey);
                }
                if (orderTypeEnums && currentOrderField && currentOrderField === dimensionItem.fieldKey) {
                    sortDirection = orderTypeEnums;
                }

                let hasIsShowPropery =
                    Object.prototype.hasOwnProperty.call(dimensionItem, 'isShow') && 'isShow' in dimensionItem;
                //isShow 为后端进行隐藏字段
                if (hasIsShowPropery) {
                    visible = dimensionItem.isShow;
                }

                let dimensionColumns = {
                    name: `${dimensionItem.fieldKey}`,
                    caption: dimensionItem.fieldTitle,
                    visible,
                    sortable: false,
                    sortDirection,
                    get: function (item, column) {
                        let fieldKey = column.name;
                        let fieldKeyOriginal = `${fieldKey}_original`;

                        let itemValue = 0;
                        if (item != null) {
                            if (item[fieldKey] != null) {
                                itemValue = item[fieldKey];
                            } else if (item[fieldKeyOriginal] != null) {
                                itemValue = item[fieldKeyOriginal];
                            }
                        }
                        if (itemValue == null) {
                            itemValue = 0;
                        }
                        return itemValue;
                    },
                };
                if (hasStackedHeaderGroups) {
                    stackedHeaderGroups = getStackedHeaderGroups(stackedHeaderGroups, dimensionItem);
                }
                allColumns.push(dimensionColumns);
            });
        }

        if (reportData.header && reportData.header.indicators && reportData.header.indicators.length > 0) {
            let indicators = reportData.header.indicators;
            indicators.forEach((item) => {
                let visible = true;
                let sortDirection = null;

                if (selectedFields && selectedFields.length > 0) {
                    visible = selectedFields.some((fieldKey) => item.fieldKey === fieldKey);
                }
                if (orderTypeEnums && currentOrderField && currentOrderField === item.fieldKey) {
                    sortDirection = orderTypeEnums;
                }
                let indicatorColumns = {
                    name: `${item.fieldKey}`,
                    caption: item.fieldTitle,
                    visible,
                    sortable: true,
                    sortDirection,
                    get: function (item, column) {
                        let fieldKey = column.name;
                        let fieldKeyOriginal = `${fieldKey}_original`;

                        let itemValue = 0;
                        if (item != null) {
                            if (item[fieldKey] != null) {
                                itemValue = item[fieldKey];
                            } else if (item[fieldKeyOriginal] != null) {
                                itemValue = item[fieldKeyOriginal];
                            }
                        }
                        if (itemValue == null) {
                            itemValue = 0;
                        }
                        return itemValue;
                    },
                    hasTemplate: false,
                    getYieldContent: function (name, item, index) {
                        return '';
                    },
                };
                if (hasStackedHeaderGroups) {
                    stackedHeaderGroups = getStackedHeaderGroups(stackedHeaderGroups, item);
                }
                allColumns.push(indicatorColumns);
            });
        }
    }

    if (isUpdate) {
        this.setState({
            tableColumns: allColumns,
            stackedHeaderGroups,
        });
    } else {
        return {
            cloumns: allColumns,
        };
    }
}

function getStackedHeaderGroups(stackedHeaderGroups, fieldItem) {
    let group = fieldItem.group;
    if (group != null) {
        let targetHeaderGroupsItem = stackedHeaderGroups.find((groupItem) => groupItem.text === group);
        if (targetHeaderGroupsItem != null) {
            targetHeaderGroupsItem.columns.push(fieldItem.fieldKey);
        } else {
            let stackedHeaderGroupsItem = {
                text: group,
                stackedHeaderClass: 'stacked-header-cell-item',
                columns: [fieldItem.fieldKey],
                groups: [],
            };
            stackedHeaderGroups.push(stackedHeaderGroupsItem);
        }
    }
    return stackedHeaderGroups;
}

function getChartData(that, values, selectedIndicators = [], fieldGroups = []) {
    let { language, isReverseData, enableDataView, enableDataView4External } = that.props;

    let items = [];
    let columns = [];
    let tableColumns = [];
    let tableItems = [];
    let exceedName = '';

    if (values) {
        if (isReverseData) {
            if (values.rows && values.rows.length > 0) {
                values = getReverseData(values, fieldGroups, language);
            }
            selectedIndicators = [];
        }

        let dimensions = [];
        if (values.header && values.header.dimensions) {
            dimensions = values.header.dimensions;
        }

        let indicators = [];
        if (values.header && values.header.indicators && values.header.indicators) {
            indicators = values.header.indicators;
        }

        if (selectedIndicators.length > 0) {
            indicators = selectedIndicators
                .map((fieldKey) => indicators.find((indicator) => fieldKey === indicator.fieldKey))
                .filter(Boolean);
        }

        let rows = [];
        if (values && values.rows) {
            rows = values.rows;
        }

        // 处理因为地图nameMap映射后，导致tooltip中获取不到当前悬浮位置name问题
        const chartRows = JSON.parse(JSON.stringify(rows));

        if (indicators && indicators.length > 0) {
            // 有维度
            if (dimensions && dimensions.length > 0) {
                indicators.forEach((indicator) => {
                    columns.push({
                        field: indicator.fieldKey,
                        name: indicator.fieldTitle || '',
                    });
                });

                // 有维度，有指标。维度作为x轴，指标作为series。
                chartRows.forEach((row) => {
                    let item = {};
                    item.originRow = row;
                    item.xLabel = that.callMethod('getXLabel', { row, columns, indicators, dimensions });
                    if (that.props.getOriginalXLabel || that.getOriginalXLabel) {
                        item.xLabelOriginal = that.callMethod('getOriginalXLabel', {
                            row,
                            columns,
                            indicators,
                            dimensions,
                        });
                    } else {
                        item.xLabelOriginal = { ...item.xLabel };
                    }
                    indicators.forEach((indicator) => {
                        let fieldKey = indicator.fieldKey;
                        let fieldKeyOriginal = fieldKey + ORIGINAL;
                        item[fieldKey] = {
                            value: row[fieldKey],
                            originalValue: row[fieldKeyOriginal] || Number(row[fieldKey]) || 0,
                        };
                        if (Object.prototype.hasOwnProperty.call(row, `${fieldKey}${TOOTIP}`)) {
                            let tootipValue = row[`${fieldKey}${TOOTIP}`] || row[`${fieldKey}${TOOTIP}${ORIGINAL}`];
                            item[fieldKey].tootipValue = tootipValue;
                        }
                    });
                    items.push(item);
                });
            } else {
                // 无维度，只有指标。
                // 创建一个虚拟的维度，以指标字段名为值，列名（即legend名）为固定字符串“多指标”（如果只有一个指标时，取指标字段名）。
                let row = {};
                if (chartRows && chartRows.length > 0) {
                    row = rows[0];
                }

                let columnName = Locales.get('reportEcharts.multipleIndicatorName', language);
                if (indicators.length === 1) {
                    columnName = indicators[0].fieldTitle;
                }

                let columnField = indicators[0].fieldKey;
                columns.push({
                    field: columnField,
                    name: columnName,
                });

                indicators.forEach((indicator, index) => {
                    let fieldKey = indicator.fieldKey;
                    let fieldKeyOriginal = fieldKey + ORIGINAL;
                    let item = {};
                    item.originRow = row;
                    item.xLabel = indicator.fieldTitle;
                    item.xLabelOriginal = indicator.fieldTitle;
                    item[columnField] = {
                        value: row[fieldKey],
                        originalValue: row[fieldKeyOriginal] || Number(row[fieldKey]) || 0,
                    };
                    if (row && Object.prototype.hasOwnProperty.call(row, `${fieldKey}${TOOTIP}`)) {
                        let tootipValue = row[`${fieldKey}${TOOTIP}`] || row[`${fieldKey}${TOOTIP}${ORIGINAL}`];
                        item[fieldKey].tootipValue = tootipValue;
                    }
                    if (that.props.showChartExceedSameIndustry) {
                        if (index === 0) {
                            items.push(item);
                        }
                    } else {
                        items.push(item);
                    }
                });
            }
        } else {
            // keep the chart alive (give him some default values)
        }

        if (enableDataView || enableDataView4External) {
            // 字段筛选
            // deep-copy
            const valuesCopy = Udesk.utils.object.deepCopy({}, values);
            valuesCopy.header.indicators = indicators;

            tableColumns = buildTableColumnsData(
                {
                    reportData: valuesCopy,
                },
                false
            ).cloumns;
            tableItems = valuesCopy.rows;
            that.privates.dataViewTableItemsCache = Udesk.utils.object.deepCopy([], tableItems);
        }

        let exceedField = indicators[1] ? indicators[1].fieldKey : '';
        exceedName = rows[0] ? rows[0][exceedField] : '';
    }

    return {
        columns,
        items,
        tableColumns,
        tableItems,
        exceedName,
    };
}

function getFieldGroupLists(values, groups = []) {
    if (groups == null || !Array.isArray(groups)) {
        groups = [];
    }

    let groupNames = null;
    if (values && values.header) {
        let indicators = values.header.indicators;
        if (indicators && indicators.length > 0) {
            groupNames = [];
            indicators.forEach((list) => {
                let isChecked = groupNames[0] === list.group || groupNames.length === 0 ? true : false;
                let matchIndex = groupNames.findIndex((name) => name === list.group);
                if (matchIndex > -1) {
                    groups[matchIndex].groupLists.push(list);
                } else {
                    groupNames.push(list.group);

                    let targetGroup = groups.find((group) => group.groupName === list.group);
                    if (targetGroup) {
                        targetGroup.groupLists = [list];
                        if (targetGroup.states.isChecked) {
                            targetGroup.values = [list.fieldKey];
                        }
                    } else {
                        let groupData = {
                            groupName: list.group,
                            groupLists: [list],
                            values: isChecked ? [list.fieldKey] : [],
                            states: {
                                isChecked: isChecked,
                            },
                        };
                        groups.push(groupData);
                    }
                }
            });
        }
    }

    groupNames = null;
    return groups;
}

function getSelectedIndicators(groups) {
    let selectedIndicators = [];
    for (let group of groups) {
        if (group.states.isChecked) {
            if (group.groupLists && group.groupLists.length > 0) {
                selectedIndicators = group.groupLists.reduce((arr, item) => {
                    let isExisted = group.values.some((value) => value === item.fieldKey);
                    if (isExisted) {
                        arr.push(item.fieldKey);
                    }
                    return arr;
                }, []);
                break;
            }
        }
    }
    return selectedIndicators;
}

function getReverseData(data, fieldGroups = [], language) {
    let indicators = data.rows.map((row) => {
        let fieldKey = row[data.header.dimensions[0].fieldKey];
        let fieldTitle = row[data.header.dimensions[0].fieldKey];
        return {
            fieldKey,
            fieldTitle,
        };
    });

    let selectedIndicators = data.header.indicators;
    if (fieldGroups && fieldGroups.length > 0) {
        let selectedFieldGroups = fieldGroups.filter((fieldGroup) => {
            return fieldGroup.states.isChecked;
        });
        let groupLists = [];
        let selectedGroupListsValues = [];
        if (selectedFieldGroups.length) {
            groupLists = selectedFieldGroups[0].groupLists;
            selectedGroupListsValues = selectedFieldGroups[0].values;
        }
        selectedIndicators = groupLists.filter((group) => {
            return selectedGroupListsValues.some((selectedGroupListsValue) => {
                return group.fieldKey === selectedGroupListsValue;
            });
        });
    }

    let rows = selectedIndicators.map((selectedIndicator) => {
        let row = {};
        //第一个key及value;
        row['_reverseDimensionsFieldKey'] = selectedIndicator.fieldTitle;
        //剩余的key及value;

        let indicatorFieldKey = selectedIndicator.fieldKey;
        let dimensionFieldKey = data.header.dimensions[0].fieldKey;

        let rowItemKeys = Object.keys(data.rows[0]);
        let keyPrefixData = null;
        if (rowItemKeys && rowItemKeys.length > 0) {
            keyPrefixData = rowItemKeys.reduce((arr, currentKey) => {
                let keyItem = null;
                if (currentKey.startsWith(indicatorFieldKey)) {
                    keyItem = {
                        pos: 'start',
                        prefix: currentKey.replace(indicatorFieldKey, ''),
                    };
                } else if (currentKey.endsWith(indicatorFieldKey)) {
                    keyItem = {
                        pos: 'end',
                        prefix: currentKey.replace(indicatorFieldKey, ''),
                    };
                }
                if (keyItem != null) {
                    arr.push(keyItem);
                }
                return arr;
            }, []);
        }

        data.rows.forEach((oldRow) => {
            if (keyPrefixData && keyPrefixData.length > 0) {
                let oldRawDimensionFieldKey = oldRow[dimensionFieldKey];
                let oldRawIndicatorFieldKey = indicatorFieldKey;
                keyPrefixData.forEach((prefixItem) => {
                    let prefix = prefixItem.prefix;
                    if (prefixItem && prefix) {
                        if (prefixItem.pos === 'start') {
                            oldRawDimensionFieldKey = oldRawDimensionFieldKey + prefix;
                            oldRawIndicatorFieldKey = `${indicatorFieldKey}${prefix}`;
                        } else if (prefixItem.pos === 'end') {
                            oldRawDimensionFieldKey = prefix + oldRawDimensionFieldKey;
                            oldRawIndicatorFieldKey = `${prefix}${indicatorFieldKey}`;
                        } else {
                            oldRawDimensionFieldKey = oldRow[dimensionFieldKey];
                            oldRawIndicatorFieldKey = indicatorFieldKey;
                        }
                    } else {
                        oldRawDimensionFieldKey = oldRow[dimensionFieldKey];
                        oldRawIndicatorFieldKey = indicatorFieldKey;
                    }
                    if (oldRawDimensionFieldKey && oldRawIndicatorFieldKey) {
                        row[oldRawDimensionFieldKey] = oldRow[oldRawIndicatorFieldKey];
                    }
                });
            } else {
                row[oldRow[dimensionFieldKey]] = oldRow[indicatorFieldKey];
            }
        });
        return row;
    });
    let reverseData = {
        header: {
            dimensions: [
                {
                    fieldKey: '_reverseDimensionsFieldKey',
                    fieldTitle: Locales.get('components.reportEcharts.noReportValue', language),
                },
            ],
            indicators,
        },
        rows,
    };
    return reverseData;
}

function randomsort(a, b) {
    return Math.random() > 0.5 ? -1 : 1;
}

export { getChartData, getReverseData };
export default ReportEchartsComponent;
