import {
    filterXSS
} from '../../../udesk/ref-npm-modules/plugins/utils.web.safe-html';
import { installPlugin, globalInstall } from '../../plugin-base';
import { getDefaultWhiteList } from '../../../udesk/ui/widgets/xss';

const XSS_CONFIG_SYMBOL = Symbol("_xss_config");
const PLUGIN_PATH = "utils.web";
const PLUGIN = {
    safeHtml
};
export default function install(udesk) {
    PLUGIN[XSS_CONFIG_SYMBOL] = udesk.ui.widgets.xss;
    installPlugin(udesk, PLUGIN_PATH, PLUGIN);
}
globalInstall(PLUGIN_PATH, PLUGIN);

function safeHtml(content, whiteList) {
    /*
     * xss is a module used to filter input from users to prevent XSS attacks.
     * For more details, refer to https://github.com/leizongmin/js-xss
     */
    let xssAwayHtml = filterXSS(content, {
        whiteList: whiteList || (PLUGIN[XSS_CONFIG_SYMBOL] && PLUGIN[XSS_CONFIG_SYMBOL].whiteLists.default) || getDefaultWhiteList()
    });
    return xssAwayHtml;
}