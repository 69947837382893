import React, { FC, memo } from 'react';
import { List, Modal, Button as UdeskButton } from 'udesk-ui';
import { postVirtualLogin } from 'src/api/virtual/login';
import { RelationCompany } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';

type TemplateProps = {
    visible?: boolean;
    relationCompanyList?: RelationCompany[];
    onCancel?: () => void;
    closable?: boolean;
    maskClosable?: boolean;
};

const Template: FC<TemplateProps> = memo((props) => {
    const {
        visible,
        relationCompanyList,
        onCancel,
        closable = false,
        maskClosable = false,
    } = props;

    const params = Object.fromEntries(new URLSearchParams(window.location.search));

    const entry = (email: string) => {
        const refer = params.refer;
        const userName = window.localStorage.getItem('userName');

        if (refer && userName === email) {
            window.location.href = refer;
        } else {
            window.location.href = '/entry-page';
        }
    };

    const virtualLogin = (isLoginCompany?: boolean, email?: string) => {
        if (email) {
            if (isLoginCompany) {
                entry(email);
            } else {
                postVirtualLogin({
                    email,
                }).then(() => {
                    entry(email);
                });
            }
        }
    };

    return (
        <Modal
            visible={visible}
            title={
                /* 请选择 */ UdeskLocales['current'].pages.login.components.virtualLogin.index
                    .pleaseSelect
            }
            footer={null}
            onCancel={() => onCancel?.()}
            closable={closable}
            maskClosable={maskClosable}
        >
            <List
                bordered
                dataSource={relationCompanyList}
                renderItem={(item) => (
                    <List.Item>
                        <UdeskButton
                            type="link"
                            onClick={() =>
                                virtualLogin(item.isLoginCompany, item.virtualAdminAccount)
                            }
                            disabled={item.virtualAdminAccount === Udesk.data.init?.user?.email}
                        >
                            {item.name}
                        </UdeskButton>
                    </List.Item>
                )}
            />
        </Modal>
    );
});

export default Template;
