import React from 'react';
import UdeskTableComponent from './component';
import { Button, Transfer, Popover, Checkbox, Icon, Empty } from 'udesk-ui';
import AutoSizeTable from '../auto-size-table';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import './style.scss';

const DragHandle = sortableHandle(() => (
    <div className='icon-unordered-list'>
        <Icon type='list-check' />
    </div>
));
const SortableItem = sortableElement(({ item, checked, onItemSelect, context }) => {
    let onCheck = (e, item) => {
        onItemSelect(item.key, e.target.checked);
    };
    return (
        <li
            className='udesk-qa-table-component-columns-transfer-item'
            title={item.title}
            style={{ zIndex: '1100', display: 'flex' }}
        >
            <Checkbox checked={checked} style={{ height: 30, lineHeight: '30px' }} onChange={(e) => onCheck(e, item)}>
                {item.title}
            </Checkbox>
            <div style={{ display: context ? 'none' : 'block' }}>
                <DragHandle />
            </div>
        </li>
    );
});
const SortableContainer = sortableContainer(({ children }) => {
    return <ul className='transfer-list'>{children}</ul>;
});

export default class UdeskTableTemplate extends UdeskTableComponent {
    render() {
        let { state, props, privates, actions } = this;
        return (
            <div className='udesk-qa-table-component-with-columns-transfer'>
                <Popover
                    placement='bottomRight'
                    visible={privates.visible}
                    trigger='click'
                    autoAdjustOverflow={false}
                    // onVisibleChange={props.type === 'inner'?actions.columnsControl:null}
                    onVisibleChange={actions.columnsControl}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    overlayClassName={`udesk-qa-table-component-columns-transfer-popover ${privates.transfer.overlayClassName} ${props.cloumnsContalPopClassName}`}
                    content={
                        <Transfer
                            onChange={actions.onTransferChange}
                            // onSelectChange={actions.handleTransferSelectChange}
                            onSearch={actions.handleTransferSearch}
                            {...privates.transfer}
                            dataSource={props.columns.filter(c => props.originVersion ? (props.originColumns || []).includes(c.key) : true)}
                            targetKeys={privates.targetKeys}
                        >
                            {({ direction, filteredItems, onItemSelect, selectedKeys: listSelectedKeys }) => {
                                if (direction === 'right') {
                                    return privates.columnsAfterSearch && privates.columnsAfterSearch.length ? (
                                        <SortableContainer onSortEnd={actions.onSortEnd} useDragHandle>
                                            {privates.columnsAfterSearch &&
                                                privates.columnsAfterSearch.map((item, index) => (
                                                    <SortableItem
                                                        key={`item-${item.key}`}
                                                        onItemSelect={onItemSelect}
                                                        index={index}
                                                        item={item}
                                                        context={state.isHidden}
                                                        checked={listSelectedKeys.includes(item.key)}
                                                    />
                                                ))}
                                        </SortableContainer>
                                    ) : (
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    );
                                }
                            }}
                        </Transfer>
                    }
                >
                    <If condition={props.type === 'outer' || props.type == null}>
                        <Button
                            style={{
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                color: 'transparent',
                                border: 0,
                                height: 1,
                                width: 0,
                                padding: 0,
                                right: 0,
                                top: -15,
                            }}
                        ></Button>
                    </If>
                    <If condition={props.type === 'inner'}>
                        <Button
                            style={{
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                color: 'transparent',
                                border: 0,
                                height: 1,
                                width: 1,
                                padding: 0,
                                right: 0,
                                top: -15,
                            }}
                        ></Button>
                    </If>
                </Popover>
                <div className='udesk-qa-table-component'>
                    <AutoSizeTable
                        // scroll={{ x: 'max-content' }}
                        {...props}
                        scroll={{x: privates.targetItems.reduce((total, current) => total + current.width, 0)}}
                        pagination={props.pagination === false ? false : { ...privates.pagination, ...props.pagination }}
                        columns={privates.targetItems}
                        dataSource={props.dataSource}
                    />
                </div>
            </div>
        );
    }
}
