import React, { useEffect, useMemo, useState } from 'react';

const Template: React.FC = (props: any) => {
    const {children, ...otherDivProps} = props;
    const [text, setText] = useState<string>(children);

    const util = useMemo(() => {
        return new Util({
            time:50,
            closeFirstAnimate: true,
            onBefore: setText,
            onProgress (text: string) {
                setText(value => value + text);
                this();
            }
        });
    }, []);

    useEffect(() => {
        util.update(children);
    }, [
        children, 
        util
    ]);

    return (
        <div {...otherDivProps}>
            <span>{text}</span>
        </div>
    );
};

export default Template;

class Util {
    text = '';
    list = new Array<string>();
    time = 100;
    isRunning = false;
    closeAnimate = false;

    constructor(config: any) {
        const {
            closeFirstAnimate,
            value,
            time,
            onBefore,
            onProgress
        } = config;
        if (value) {
            this.list = value.split('');
        }
        this.onBefore = onBefore;
        this.onProgress = onProgress;
        this.time = time;
        this.closeAnimate = closeFirstAnimate;
    }

    onBefore (args: string) {}
    onProgress (args: string) {}

    next () {
        const item = this.list.shift();
        const {
            onProgress, time, next
        } = this;

        if (item) {
            this.isRunning = true;
            new Promise((resolve) => {
                setTimeout(
                    () => {
                        onProgress.call(resolve, item);
                    }, 
                    time
                );
            }).then(next.bind(this));
        } else {
            this.isRunning = false;
        }
    }

    add (value: string) {
        if (value) {
            this.list = this.list.concat(value.split(''));
        }
        this.start();
    }

    update(value: string) {
        if (this.closeAnimate) {
            this.closeAnimate = false;
            this.onBefore(this.text = value);
        } else {
            const oldText = value.slice(0, this.text.length);
            const newText = value.slice(this.text.length);
            // 更新原有字符
            this.onBefore(oldText);     
            if (newText) {
                // 追加新字符
                this.add(newText);
            }
            this.text = value;
        }
    }

    start () {
        !this.isRunning && this.next();
    }
}