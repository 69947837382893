import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { TreeSelect } from 'udesk-ui';
import { getOrganizations } from 'src/api/organizations';
import UdeskLocales from 'UdeskLocales';

const locales = UdeskLocales['current'];

type TemplateProps = {
    value?: any;
    onChange?: (v: any) => void;
    disabled?: boolean;
    /** 外部传入的 treeData */
    outerTreeData?: any[];
    /** 变更外部传入的 treeData */
    setOuterTreeData?: (d: any[]) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange, disabled, outerTreeData, setOuterTreeData } = props;

    const [organizationList, setOrganizationList] = useState<any[]>([]);

    const getDepartmentList = useCallback(() => {
        const params = {
            pageSize: 10000,
        };
        getOrganizations({ params }).then((res) => {
            let treeData = transformArrayToTree(res.data ?? []);
            setOrganizationList(treeData);
            setOuterTreeData?.(treeData);
        });
    }, [setOuterTreeData]);

    useEffect(() => {
        getDepartmentList();
    }, [getDepartmentList]);

    return (
        <TreeSelect
            value={value}
            onChange={onChange}
            showSearch
            placeholder={locales.pages.gong.saleClientCenter.list.index.allDepartments}
            allowClear
            multiple
            treeDefaultExpandAll
            treeNodeFilterProp="name"
            treeData={outerTreeData ?? organizationList}
            style={{ width: 160 }}
            disabled={disabled}
        />
    );
};

export default memo(Template);

// 树结构数据处理
const transformArrayToTree = (arr: any = []) => {
    if (!arr || !arr.length) return [];
    const sortedArr = arr
        .map((i: any) => ({
            ...i,
            title: i.name,
            key: i.id,
            parentId: i.parentId * 1,
        }))
        .sort((a, b) => a.parentId - b.parentId);
    let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
    let nodeMap = {};
    sortedArr.forEach((item) => {
        nodeMap[item.id] = item;
        if (nodeMap[item.parentId]) {
            if (Array.isArray(nodeMap[item.parentId].children)) {
                nodeMap[item.parentId].children.push(item);
            } else {
                nodeMap[item.parentId].children = [item];
            }
        }
    });
    return [...root];
};
