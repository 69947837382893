import React from 'react';
import UdeskMenuBarSdkComponent from './component';
import SdkLoader from 'udesk-react/src/components/sdk-loader';

export default class UdeskMenuBarSdkTemplate extends UdeskMenuBarSdkComponent {
    render() {
        let { actions, locales, state } = this;
        return (
            <div>
                <If condition={state.menuBarSdkJsUrl}>
                    <div id="udesk-qa-result-container" className="udesk-case-react">
                        <SdkLoader
                            releaseVersionUrl={state.releaseVersionUrl}
                            jsUrl={state.menuBarSdkJsUrl}
                            cssUrl={state.menuBarSdkCssUrl}
                            outputGlobalNamespace="UdeskMenuBarSdk"
                            loadErrorMessage={locales.components.UdeskQaReact.loadSdkError}
                            onLoaded={actions.onLoaded}
                            onDestroying={actions.onDestroying}
                            // onCreated={action(this.actions.mutator('', true))}
                            // onDestroyed={action(this.actions.mutator('', true))}
                        />
                    </div>
                </If>
            </div>
        );
    }
}
