import Udesk from 'Udesk';
import React from 'react';
// import Udesk from 'Udesk';
// import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
// import ReactModal from 'udesk-react/src/components/react-modal';
import './style.scss';
import InformationEntitiesRoute from './route';
import { PageHeader, Button, Space, Input, Select } from 'udesk-ui';
import ExportButton from 'Component/common/export-button';
import AutoSizeTable from 'Component/common/auto-size-table';
import BatchDeleteButton from 'Component/common/batch-delete-btn';
import ImportModal from 'Component/common/import-modal';
import { enableQa } from 'Udesk/business';

const { Option } = Select;

export default class InformationEntitiesTemplate extends InformationEntitiesRoute {
    render() {
        // let {
        //     sdkOptions
        // } = this.props;
        let { columns, pageNum, pageSize, total } = this.state;
        let { isUploadVisible, file, loading, selectedItems, moduleType } = this.privates;
        let { actions, privates, locales } = this;
        return (
            // <div className="information-entities-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className='udesk-qa-web-page-header'
                    title={locales.components.pages.informationEntities.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root information-entities-index-page'>
                        <div className='information-entities-index-page-body'>
                            <If
                                condition={
                                    Udesk.data.init.user && Udesk.data.init.user.hasFeature('semantic:imformation:view')
                                }
                            >
                                <div className='information-entities-index-page-body-search-container qa-react-page-body-search-container'>
                                    <Space>
                                        <Input.Search
                                            defaultValue={privates.keyword}
                                            placeholder={locales.labels.searchPlaceHolder}
                                            // onKeyUp={actions.searchItems}
                                            onSearch={actions.searchItems}
                                            onChange={actions.mutator('privates.keyword', (e) => e.target.value)}
                                        />
                                        {enableQa(
                                            <div>
                                                {locales.fields.semanticTags.orgName}:
                                                <Select value={moduleType} onChange={actions.moduleTypeChange} style={{ width: 120 }}>
                                                    <Option value={null}>{locales.labels.all}</Option>
                                                    {Udesk.enums.organizationTypes.map(item => {
                                                        return <Option value={item.id}>{item.name}</Option>;
                                                    })}
                                                </Select>
                                            </div>
                                        )}
                                        <Button type='primary' onClick={actions.searchItems}>
                                            {locales.components.pages.conceptWord.control.commit}
                                        </Button>
                                    </Space>
                                    <div className='qa-react-page-body-search-container-right-part pull-right'>
                                        <Space>
                                            <BatchDeleteButton
                                                deleteItemsCount={selectedItems.length}
                                                // failedCount={0}
                                                onDelete={actions.batchDelete}
                                            />
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature('semantic:imformation:add')
                                                }
                                            >
                                                <Button onClick={actions.createItem}>{locales.labels.create}</Button>
                                            </If>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature('semantic:imformation:import')
                                                }
                                            >
                                                <Button onClick={actions.changeUploadVisible}>
                                                    {locales.labels.import}
                                                </Button>
                                            </If>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature('semantic:imformation:export')
                                                }
                                            >
                                                <ExportButton
                                                    disabled={loading}
                                                    exportDataCount={selectedItems.length ? selectedItems.length : total}
                                                    hasFilterCondition={true}
                                                    onClick={actions.export}
                                                />
                                            </If>
                                        </Space>
                                    </div>
                                </div>
                                <div className='information-entities-index-page-body-content-container qa-react-page-body-content-container'>
                                    <AutoSizeTable
                                        loading={loading}
                                        subtractedHeight={146}
                                        columns={columns}
                                        pagination={false}
                                        dataSource={privates.dataSource || []}
                                        rowSelection={{
                                            type: 'checkbox',
                                            onSelect: actions.onCheckChange,
                                            onSelectAll: actions.onSelectAll,
                                            selectedRowKeys: selectedItems.map((i) => i.id),
                                        }}
                                    />
                                    {/* <ReactPager
                                            pageNum={pageNum}
                                            pageSize={pageSize}
                                            total={total}
                                            languageCode={sdkOptions.props.language}
                                            pageChanged={actions.pageChanged}
                                        /> */}
                                    <UdeskPagination
                                        current={pageNum}
                                        pageSize={pageSize}
                                        total={total}
                                        onChange={actions.pageChanged}
                                    />
                                </div>
                            </If>
                            <ImportModal
                                onCancel={actions.changeUploadVisible}
                                onOk={actions.importInformationEntities}
                                visible={isUploadVisible}
                                uploadFile={actions.uploadFile}
                                downloadTemplate={actions.downloadTemplate}
                                title={locales.components.pages.informationEntities.index.import}
                                file={file}
                            />
                            {/* <ReactModal
                                visible={isUploadVisible}
                                title={locales.components.pages.informationEntities.index.import}
                                closeIconClass='udesk-qc-iconfont icon-qc-quxiao pull-right'
                                onCancel={actions.changeUploadVisible}
                                onOk={actions.importInformationEntities}
                                headerClassName='udesk-qa-modal-default-header'
                                footerClassName='udesk-qa-modal-default-footer'
                            >
                                <div className='udesk-qa-modal-body-content'>
                                    <div className='form-group'>
                                        <label>
                                            {locales.labels.uploadFile}
                                            {locales.labels.colon}
                                        </label>
                                        <div className='upload-file-button-container'>
                                            <input
                                                type='file'
                                                className='upload-file-inpiut'
                                                onChange={actions.uploadFile}
                                            />
                                            <button type='button' className='upload-file-button btn btn-sm btn-primary'>
                                                {locales.labels.upload}
                                            </button>
                                        </div>
                                        <If condition={file && file.name}>
                                            <span className='upload-file-name'>{file.name}</span>
                                        </If>
                                    </div>
                                    <div className='form-group'>
                                        {locales.labels.numOne}
                                        {locales.labels.colon}&nbsp;&nbsp;
                                        {locales.labels.click}&nbsp;&nbsp;
                                        <span className='upload-file-template' onClick={actions.downloadTemplate}>
                                            {locales.labels.downloadTemplate}
                                        </span>
                                        &nbsp;&nbsp;
                                        {locales.labels.fillInTheForm}
                                    </div>
                                    <div className='form-group'>
                                        {locales.labels.numTwo}
                                        {locales.labels.colon}&nbsp;&nbsp;
                                        {locales.labels.UploadExcelfile}
                                    </div>
                                    <div className='form-group'>
                                        {locales.labels.numThree}
                                        {locales.labels.colon}&nbsp;&nbsp;
                                        {locales.labels.uploadIdentical}
                                    </div>
                                </div>
                            </ReactModal> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
