import string from './string';
import math from './math';
import object from './object';
import validators from './validators';
import transformers from './transformers';
import web from './web';
import date from './date';
import array from './array';
import valuePicker from "./value-picker";
import promise from "./promise";
import dom from "./dom";

export default {
    string,
    math,
    object,
    validators,
    transformers,
    web,
    date,
    array,
    valuePicker,
    promise,
    dom,
};