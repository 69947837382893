import React, { useImperativeHandle } from 'react';
import styled from 'styled-components';
import Avatar from './Avatar';
import { IntelligentPartnerSentence } from 'src/api/types';
import { PlayBtn } from './PlayBtn';
import { FormatDate } from './UserNode';

const RobotNodeWrap = styled.div`
    display: flex;
    justify-content: ${props => props.align ==='right' ? 'flex-end' : 'flex-start'};

    .udesk-coach-user-node-left {
        /* flex: 1; */
        max-width: 70%;
        margin: ${props => props.align ==='right' ? '0 12px 0 0' : '0 0 0 12px'};

        .udesk-coach-user-node-title {
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 8px;
            text-align: ${props => props.align ==='right' ? 'end' : 'start'};
        }
        .udesk-coach-user-node-content {
            background: #ffffff;
            border-radius: 0px 4px 4px 4px;
            padding: 10px 12px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 22px;
        }
    }

    .udesk-qa-ui-avatar {
        width: 36px;
        height: 36px;
    }
`;

type RobotNodeProps = IntelligentPartnerSentence & { index: number; align: 'right' | 'left' };

export const RobotNode = React.memo(
    React.forwardRef((props: RobotNodeProps, ref) => {
        const {align} = props;

        useImperativeHandle(ref, () => {
            return {};
        });

        return (
            <RobotNodeWrap align={align}>
                {
                    align === 'left' ? <Avatar align={align}/> : null
                }
                <div className="udesk-coach-user-node-left">
                    <div className="udesk-coach-user-node-title">
                        <FormatDate startSecond={props.startSecond} />
                    </div>
                    <div className="udesk-coach-user-node-content">
                        <PlayBtn url={props.ossKey} position={align} />
                        {props.content}
                    </div>
                </div>
                {
                    align === 'right' ?<Avatar align={align}/> : null
                }
            </RobotNodeWrap>
        );
    })
);
