import React from 'react';
// import Udesk from 'Udesk';
// import ReactSelect from 'udesk-react/src/components/react-select';
import ReviewListComponent from './component';
import { Select, PageHeader, Button, Space } from 'udesk-ui';
import ExportButton from 'Component/common/export-button';
import _size from 'lodash-es/size';
import _filter from 'lodash-es/filter';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import ReactModal from 'udesk-react/src/components/react-modal';
// import ClassNames from 'classnames';
import './style.scss';
import UdeskTable from 'Component/common/udesk-table';
// import UdeskDatePicker from 'Component/common/udesk-date-picker';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import CustomFilterBtn from 'Component/pages/components/custom-filter-button';

export default class ReviewListTemplate extends ReviewListComponent {
    render() {
        let { sdkOptions, match } = this.props;
        let { pageSize, total, pageNum } = this.state;
        let {
            selectedItems,
            checkVisible,
            // autoComponentBlurFlag,
            tableRef,
            loading,
            selectedRowKeys,
            filterVisible,
            type,
            customId,
            customName,
        } = this.privates;
        // let { startTime, endTime, reviewStatus, selectRewCheckValue, seeUserList, userTwoValue } =
        //     this.privates.storages;
        let {
            // startTime,
            // endTime,
            // sampleStatus,
            // seeUserList,
            // selectSpotCheckValue,
            // userTwoValue,
            conditionList,
            judgeStrategy,
            customJudgeLogic,
        } = this.privates.storages;
        let { actions, locales } = this;
        let { _columns, _dataList } = this.privates.computes;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader className='udesk-qa-web-page-header' title={locales.components.pages.reviewList.title} />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root review-list-page'>
                        <If
                            condition={
                                hasFeature &&
                                (hasFeature('task:work:review:view:self') ||
                                    hasFeature('task:work:review:view:none') ||
                                    hasFeature('task:work:review:view'))
                            }
                        >
                            <div className='review-list-body-search'>
                                {/* <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: 10 }}>
                                        <UdeskDatePicker
                                            disabled={loading}
                                            style={{ width: 246 }}
                                            allowClear={false}
                                            onChange={actions.dateChanged}
                                            value={[startTime, endTime]}
                                        />
                                    </div>
                                    <ReactSelect
                                        disabled={loading}
                                        classNames='udesk-qc-react-select'
                                        value={reviewStatus}
                                        dataScource={Udesk.enums.reviewInspectionStatus}
                                        hasNullSelect={true}
                                        onChanged={actions.getComplainStatus}
                                    />
                                    <Select
                                        disabled={loading}
                                        value={selectRewCheckValue}
                                        style={{ width: 140, marginRight: '10px' }}
                                        onChange={actions.selectTwoChange}
                                    >
                                        <If condition={hasFeature && hasFeature('task:work:review:view')}>
                                            <Select.Option value={1}>
                                                {locales.components.pages.reviewList.allInspector}
                                            </Select.Option>
                                        </If>
                                        <If condition={hasFeature && hasFeature('task:work:review:view')}>
                                            <Select.Option value={5}>
                                                {locales.components.pages.reviewList.seInspector}
                                            </Select.Option>
                                        </If>
                                        <If condition={hasFeature && hasFeature('task:work:review:view:self')}>
                                            <Select.Option value={2}>
                                                {locales.components.pages.reviewList.myInspector}
                                            </Select.Option>
                                        </If>
                                        <If condition={hasFeature && hasFeature('task:work:review:view:none')}>
                                            <Select.Option value={3}>
                                                {locales.components.pages.reviewList.noInspector}
                                            </Select.Option>
                                        </If>
                                    </Select>
                                    {seeUserList && (
                                        <div
                                            style={{ width: 200 }}
                                            className={ClassNames('review-list-body-auto-component', {
                                                'review-list-body-auto-component-blur': autoComponentBlurFlag,
                                            })}
                                        >
                                            <AutoComplete
                                                disabled={loading}
                                                isMulti={true}
                                                value={userTwoValue}
                                                loadOptions={actions.loadUserOptions}
                                                onChanged={actions.onUserListTwoChanged}
                                                onFocused={actions.autoComponentFocus}
                                                onBlurred={actions.autoComponentBlur}
                                                enableEmptySearch={true}
                                            />
                                        </div>
                                    )}
                                </div> */}
                                <div style={{marginRight: 8}}>
                                    <CustomFilterBtn
                                        onClick={actions.changeCusFilterVisible}
                                        judgeStrategy={judgeStrategy}
                                        conditionList={conditionList}
                                        customJudgeLogic={customJudgeLogic}
                                    />
                                </div>
                                <If
                                    condition={
                                        hasFeature &&
                                        (hasFeature('task:work:review:cancel') ||
                                            hasFeature('task:work:review:reassigned'))
                                    }
                                >
                                    <div className='qa-react-page-body-search-container-right-part pull-right'>
                                        <Space>
                                            <Select
                                                disabled={loading}
                                                defaultValue={locales.labels.batchActions}
                                                value={this.privates.selectValue || locales.labels.batchActions}
                                                style={{ minWidth: 120 }}
                                                onChange={actions.selecthandleChange}
                                            >
                                                <If condition={hasFeature && hasFeature('task:work:review:cancel')}>
                                                    <Select.Option value='cancel'>
                                                        {locales.labels.cancelDistribution}
                                                    </Select.Option>
                                                </If>
                                                <If condition={hasFeature && hasFeature('task:work:review:reassigned')}>
                                                    <Select.Option value='again'>
                                                        {locales.labels.againDistribution}
                                                    </Select.Option>
                                                </If>
                                            </Select>
                                            <If condition={hasFeature && hasFeature('task:work:review:export')}>
                                                <ExportButton
                                                    disabled={loading}
                                                    exportDataCount={total}
                                                    hasFilterCondition={true}
                                                    onClick={actions.export}
                                                />
                                            </If>
                                            <Button onClick={actions.onPopoverVisible}>
                                                {locales.components.pages.customerServiceRankList.select.column}
                                            </Button>
                                        </Space>
                                    </div>
                                </If>
                            </div>
                            <div className='review-list-body-list'>
                                {/* <ReactTable ref={tableRef} columns={_columns} items={privates.asyncModel.data.fieldDataList} checkable={hasFeature && (hasFeature("task:work:review:cancel") || hasFeature("task:work:review:reassigned")) ? true : false} onSelectionChanged={actions.onCheckChange} selectedItems={_concat([], selectedItems)} enableChooseColumns={true} onColumnsChanged={actions.onColumnsChange} cacheKey="tasks.manage.workbench.review-list.index" itemIdentityField="dataId" rowClick={actions.viewItemDetail} />
                                    <ReactPager pageSize={pageSize} total={total} pageNum={pageNum} languageCode={sdkOptions.props.language} pageChanged={actions.pageChanged} /> */}
                                <UdeskTable
                                    cacheKey={`tasks.manage.workbench.review-list.index.taskId-${match.params.taskId}`}
                                    // scroll={{ x: _size(_columns) * 180, y: 'calc(100vh - 64px - 58px - 64px - 165px)' }}
                                    ref={tableRef}
                                    subtractedHeight={176}
                                    loading={loading}
                                    columns={_columns}
                                    dataSource={_dataList}
                                    rowKey={(item) => item.id}
                                    rowSelection={{ selectedRowKeys, onChange: actions.onCheckChange }}
                                    onRow={(record) => {
                                        return { onClick: actions.viewItemDetail.params(record) };
                                    }}
                                    pagination={{
                                        onChange: actions.pageChanged,
                                        total: total,
                                        pageSize: pageSize,
                                        current: pageNum,
                                        onShowSizeChange: actions.pageSizeChange,
                                    }}
                                ></UdeskTable>
                            </div>
                        </If>
                        <CustomFilterModal
                            visible={filterVisible}
                            cancel={actions.cancel}
                            applyAction={actions.handleConditionApply}
                            onConditionFilterSaved={actions.onConditionFilterSaved}
                            judgeStrategy={judgeStrategy}
                            customJudgeLogic={customJudgeLogic}
                            customName={customName}
                            customId={customId}
                            conditionList={conditionList}
                            type={type}
                            taskId={this.props.match.params.taskId}
                            taskName={this.props.sdkOptions.props.task.name}
                            filterList={['f_8', 'r_1', 'r_2', 'r_3']}
                            querryType='reviewList'
                            filterTaskData={true}
                        />
                        <ReactModal
                            cancelText={locales.labels.cancel}
                            okText={locales.labels.save}
                            visible={checkVisible}
                            title={
                                this.privates.selectStatus
                                    ? locales.labels.againDistribution
                                    : locales.labels.cancelDistribution
                            }
                            closeIconClass='udesk-qc-iconfont icon-qc-quxiao pull-right'
                            onCancel={actions.changeFilterVisible}
                            onOk={actions.handleConditionFilter}
                            headerClassName='udesk-qa-modal-default-header'
                            footerClassName='udesk-qa-modal-default-footer'
                        >
                            {this.privates.selectStatus ? (
                                <div>
                                    <div style={{ marginBottom: '10px' }}>{`${locales.labels.distributionInfoFirstOne}${
                                        selectedItems && _size(selectedItems)
                                    }${locales.labels.distributionInfoFirstTwo}`}</div>
                                    <Select
                                        defaultValue={2}
                                        style={{ width: 120, marginRight: '10px' }}
                                        onChange={actions.selectResAssignendChange}
                                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                    >
                                        <Select.Option value={1}>{locales.labels.allOptionOpertor}</Select.Option>
                                        <Select.Option value={2}>{locales.labels.oneOptionOpertor}</Select.Option>
                                    </Select>
                                    {this.privates.userStatusAgain && (
                                        <div style={{ width: '200px', display: 'inline-block' }}>
                                            <AutoComplete
                                                isMulti={true}
                                                value={this.privates.userValue}
                                                loadOptions={actions.loadUserOptions}
                                                onChanged={actions.onUserChanged}
                                                enableEmptySearch={true}
                                            />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {locales.labels.distributionInfoSecondOne}
                                    {selectedItems && _size(selectedItems)}
                                    {locales.labels.distributionInfoSecondTwo}
                                </div>
                            )}
                            <div style={{ marginTop: '10px' }}>
                                {locales.labels.distributionInfoThirdOne}
                                {selectedItems &&
                                    _size(
                                        _filter(
                                            selectedItems,
                                            (item) => item.af_7 === locales.enums.reviewInspectionStatus.reviewed
                                        )
                                    )}
                                {locales.labels.distributionInfoThirdTwo}
                            </div>
                        </ReactModal>
                    </div>
                </div>
            </div>
        );
    }
}
