import React, { useState, useCallback, useRef, useImperativeHandle } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import ImportModal from 'Component/common/import-modal';
import isEqual from 'lodash-es/isEqual';
import { useStorageProps } from 'src/util/component';
import { Button, DatePicker, Form, Icon, Input, Modal, Popconfirm, Table, Tag } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { inspect, isDefined } from 'src/util/core';
import { EllipsisSpan } from 'src/util/tag';
import { TabKey } from '../index';
import { getSystemModule } from 'Udesk/system/subApp';
import {
    getIntelligentExtractTasks,
    postIntelligentExtractTasks,
} from 'src/api/intelligentExtractTasks';
import { EnumSelect } from 'Component/common/enum-component';
import { getDateTimeString } from 'src/util/moment';
import { deleteIntelligentExtractTasksById } from 'src/api/intelligentExtractTasks/{id}';
import './index.scss';

const pageSize = Udesk.config.paging.defaultPageSize;

export default React.memo((props: any) => {
    const { updateStorages } = props;
    const [tabKey] = useStorageProps(props, 'tabKey', TabKey.Text);
    const [total, setTotal] = useState(0);
    const [tableDataSource, setTableDataSource] = useState<any[]>([]);
    const [pageNum, setPageNum] = useStorageProps(props, 'leftPagePageNum');
    const [selectedRow, setSelectedRow] = useStorageProps(props, 'leftPageSelectedRow');
    const { current: locales } = useRef(Locales['current']);

    const requestSuccessHandle = useCallback(
        (resp, api, [{ params }]) => {
            setTableDataSource(
                resp.data.map((item) => ({
                    ...item,
                    key: item.id,
                }))
            );
            setTotal(resp.paging.total);
            setPageNum(params.pageNum);
            setSelectedRow((selectedRow) => {
                if (isDefined(selectedRow) && selectedRow.taskType === params.taskType) {
                    return {
                        ...selectedRow,
                    };
                }
                return resp.data?.find((item) => item.taskStatus === 3) || resp.data?.[0] || null;
            });
        },
        [setPageNum, setSelectedRow]
    );

    const {
        data,
        run: request,
        loading,
        refresh,
        mutate,
    } = useRequest({
        onSuccess: requestSuccessHandle,
    });

    const { run: deleteRequest, loading: deleteLoading } = useRequest({
        onSuccess: useCallback(
            (resp, api, [{ segments }]) => {
                Udesk.ui.notify.success(/* 删除成功 */ locales.business.info.deleteSuccess);
                setSelectedRow((selectedRow) => {
                    if (selectedRow?.id === segments?.id) {
                        return null;
                    }
                    return selectedRow;
                });
                refresh();
            },
            [refresh, setSelectedRow]
        ),
    });

    const delHandle = useCallback(
        (record) => {
            deleteRequest(deleteIntelligentExtractTasksById, {
                segments: record,
            });
        },
        [deleteRequest]
    );

    const renderCell = useCallback(
        (text, record) => {
            const color = ['', 'default', 'processing', 'success', 'error'][record.taskStatus];

            return (
                <div
                    className="table-row-item"
                    onClick={() => {
                        updateStorages({
                            leftPageSelectedRow: record,
                            rightPagePagination: {
                                current: 1,
                                total: 0,
                            },
                        });
                    }}
                >
                    <span>{record.taskName}</span>
                    <div className="toolkit-button">
                        <Tag color={color}>
                            {Udesk.enums.intelligentExtractorsTaskStatus.getName(record.taskStatus)}
                        </Tag>
                        <Popconfirm
                            title={
                                /* 确认删除文档及其抽取内容？ */ Locales['current'].pages
                                    .intelligentAnalysis.intelligentExtractors.components
                                    .pageLeftTable
                                    .areYouSureToDeleteTheDocumentAndItsExtractedContent
                            }
                            okText={locales.labels.confirm}
                            cancelText={locales.labels.cancel}
                            onConfirm={() => delHandle(record)}
                        >
                            <Button
                                onClick={(event) => event.stopPropagation()}
                                danger
                                type="link"
                                size="small"
                                icon={<Icon type="delete"></Icon>}
                            />
                        </Popconfirm>
                    </div>
                </div>
            );
        },
        [delHandle, updateStorages]
    );

    const requestTableSource = useCallback(
        (pageNum = 1, taskType) => {
            request(getIntelligentExtractTasks, {
                params: {
                    taskType,
                    pageNum,
                    pageSize,
                },
            });
        },
        [request]
    );

    const onPageNumChange = useCallback(
        (pageNum) => {
            setPageNum(pageNum);
            requestTableSource(pageNum, +tabKey);
        },
        [tabKey, setPageNum, requestTableSource]
    );

    useLiveEffect(() => requestTableSource(1, +tabKey), [tabKey]);

    const { run: request2 } = useRequest({
        onSuccess(resp, api, params) {
            inspect(!isEqual(resp.data, data), () => {
                mutate(resp.data);
                requestSuccessHandle(resp, api, params);
            });
        },
    });

    useLiveEffect(() => {
        const timer = setInterval(() => {
            request2(getIntelligentExtractTasks, {
                params: {
                    taskType: +tabKey,
                    pageNum,
                    pageSize,
                },
            });
        }, 10000);
        return () => {
            clearInterval(timer);
        };
    }, [tabKey, pageNum]);

    return (
        <div className="intelligent-extractors-page-left">
            <Table
                loading={loading || deleteLoading}
                scroll={{
                    y: 'calc(100% - 56px)',
                }}
                rowSelection={{
                    selectedRowKeys: selectedRow ? [selectedRow.id] : [],
                    columnTitle: <Title taskType={tabKey} onAddAfter={requestTableSource} />,
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    current: pageNum,
                    pageSize,
                    total,
                    simple: true,
                    onChange: onPageNumChange,
                    hideOnSinglePage: true,
                }}
                columns={[]}
                dataSource={tableDataSource}
            />
        </div>
    );
});

const Title = React.memo<any>((props) => {
    const { current: locales } = useRef(Locales['current']);
    const { taskType, onAddAfter } = props;
    const modalRef = useRef<RefType>(null);

    const successCallback = useCallback(() => {
        onAddAfter(1, +taskType);
    }, [onAddAfter, taskType]);

    return (
        <div
            style={{
                padding: '0 8px',
                justifyContent: 'space-between',
                display: 'flex',
            }}
        >
            <EllipsisSpan>{locales.pages.home.index.taskList}</EllipsisSpan>
            <Button
                tooltip={
                    taskType === TabKey.Call
                        ? /* 添加知识库 */ Locales['current'].pages.intelligentAnalysis
                              .intelligentExtractors.components.pageLeftTable.addKnowledgeBase
                        : /* 上传文档 */ Locales['current'].pages.intelligentAnalysis
                              .intelligentExtractors.components.pageLeftTable.uploadDocument
                }
                onClick={() => modalRef.current?.open()}
                type="link"
                size="small"
                icon={<Icon antdIcon={true} type={'PlusOutlined'} />}
            />
            {inspect(
                taskType === TabKey.Call,
                () => (
                    <SaveModal
                        key={TabKey.Call}
                        ref={modalRef}
                        taskType={+taskType}
                        successCallback={successCallback}
                    />
                ),
                () => (
                    <UploadModal
                        key={TabKey.Text}
                        ref={modalRef}
                        taskType={+taskType}
                        successCallback={successCallback}
                    />
                )
            )}
        </div>
    );
});

export type RefType = {
    open: () => void;
};

const UploadModal = React.forwardRef<RefType, any>((props: any, ref) => {
    const { successCallback, taskType } = props;

    useImperativeHandle(ref, () => ({
        open() {
            showImportModal();
        },
    }));

    const {
        fileRef,
        handleOnCancel,
        handleOnOk,
        isUploadVisible,
        uploadFile,
        file,
        clearFile,
        showImportModal,
    } = ImportModal.useImportModal({
        isShowImportSuccessNotification: false,
        accept: ['ppt', 'pptx', 'txt', 'doc', 'docx', 'xls', 'xlsx', 'pdf'],
        request: postIntelligentExtractTasks,
        formatParams: useCallback(
            (file, uploadData) => ({
                taskType,
                ossKey: uploadData.key,
                systemModule: getSystemModule(),
            }),
            [taskType]
        ),
    });

    return (
        <ImportModal
            fileRef={fileRef}
            onCancel={() => {
                clearFile();
                handleOnCancel();
            }}
            onOk={() => handleOnOk().then(successCallback).then(clearFile)}
            visible={isUploadVisible}
            uploadFile={uploadFile}
            title={
                /* 上传文档 */ Locales['current'].pages.intelligentAnalysis.intelligentExtractors
                    .components.pageLeftTable.uploadDocument
            }
            file={file}
        >
            <div className="form-group">
                {[
                    '（',
                    /* 支持word、pdf、ppt、excel、txt格式文件 */ Locales['current'].pages
                        .intelligentAnalysis.intelligentExtractors.components.pageLeftTable
                        .supportsWordPDFPowerPointExcelAndTxtFormatFiles,
                    '）',
                ].join('')}
            </div>
        </ImportModal>
    );
});

const initialValues = {
    dataTime: [],
    dataSource: Udesk.enums.intelligentExtractorsDataSource.qa.id,
    dataType: Udesk.enums.intelligentExtractorsDataType.call.id,
};

const SaveModal = React.forwardRef<RefType, any>((props: any, ref) => {
    const { successCallback, taskType } = props;
    const { current: locales } = useRef(Locales['current']);
    const [form] = Form.useForm();
    const { run: request, loading } = useRequest({
        onSuccess: useCallback(() => {
            Udesk.ui.notify.success(/* 保存成功 */ locales.business.info.saveSuccess);
            setVisible(false);
            successCallback();
        }, []),
    });
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            form.resetFields();
            setVisible(true);
        },
    }));

    const submitHandle = useCallback(
        (values) => {
            const params = {
                taskName: values.taskName,
                taskType,
                taskConditionObj: {
                    startTime: getDateTimeString(values.dataTime[0]),
                    endTime: getDateTimeString(values.dataTime[1]),
                    dataSource: values.dataSource,
                    dataType: values.dataType,
                },
            };

            request(postIntelligentExtractTasks, params);
        },
        [request, taskType]
    );

    return (
        <Modal
            title={
                /* 添加知识库 */ Locales['current'].pages.intelligentAnalysis.intelligentExtractors
                    .components.pageLeftTable.addKnowledgeBase
            }
            centered
            visible={visible}
            confirmLoading={loading}
            onOk={() => form.submit()}
            onCancel={() => setVisible(false)}
            forceRender
        >
            <div className="page-right-form">
                <Form
                    form={form}
                    onFinish={submitHandle}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 17 }}
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="taskName"
                        label={
                            /* 任务名称 */ Locales['current'].pages.intelligentAnalysis
                                .intelligentExtractors.components.pageLeftTable.taskName
                        }
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="dataTime"
                        label={
                            /* 抽取对话日期 */ Locales['current'].pages.intelligentAnalysis
                                .intelligentExtractors.components.pageLeftTable
                                .extractConversationDate
                        }
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (value) {
                                        if (value[0] && value[1]) {
                                            return Promise.resolve();
                                        }
                                    }
                                    return Promise.reject(
                                        /* 请选择对话日期 */ Locales['current'].pages
                                            .intelligentAnalysis.intelligentExtractors.components
                                            .pageLeftTable.pleaseSelectAConversationDate
                                    );
                                },
                            },
                        ]}
                    >
                        <DatePicker.RangePicker showTime />
                    </Form.Item>
                    <Form.Item
                        name="dataSource"
                        label={
                            /* 数据源 */ Locales['current'].pages.intelligentAnalysis
                                .intelligentExtractors.components.pageLeftTable.dataSource
                        }
                    >
                        <EnumSelect enumKey="intelligentExtractorsDataSource" />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(pre, cur) => pre?.dataSource !== cur?.dataSource}
                    >
                        {({ getFieldsValue }) => {
                            const { dataSource } = getFieldsValue() || {};

                            const filter =
                                dataSource === Udesk.enums.intelligentExtractorsDataSource.qa.id
                                    ? undefined
                                    : (item) => item.key !== 'text';

                            return (
                                <Form.Item
                                    name="dataType"
                                    label={
                                        /* 数据类型 */ Locales['current'].pages.intelligentAnalysis
                                            .intelligentExtractors.components.pageLeftTable.dataType
                                    }
                                >
                                    <EnumSelect
                                        enumKey="intelligentExtractorsDataType"
                                        filter={filter}
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});
