import Locales from '../locales/index';

let formTemplateTypes = [
    { id: 1, name: () => Locales.current.enums.qualityInspectionForm.formTemplateTypes.directScore, key: "directScore" },
    { id: 2, name: () => Locales.current.enums.qualityInspectionForm.formTemplateTypes.weightedScore, key: "weightedScore" },
    { id: 3, name: () => Locales.current.enums.qualityInspectionForm.formTemplateTypes.deductScore, key: "deductScore" },
    { id: 4, name: () => Locales.current.enums.qualityInspectionForm.formTemplateTypes.directScoreSecondLevel, key: "directScoreSecondLevel" },
    { id: 5, name: () => Locales.current.enums.qualityInspectionForm.formTemplateTypes.weightedScoreSecondLevel, key: "weightedScoreSecondLevel" },
    { id: 6, name: () => Locales.current.enums.qualityInspectionForm.formTemplateTypes.deductScoreSecondLevel, key: "deductScoreSecondLevel" }
];
 
export default {
    formTemplateTypes,
};
