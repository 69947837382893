// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetMallConfigsQuery,
    BaseResponseListMallConfigFoundResponse,
    PostMallConfigsBody,
    BaseResponseMallConfigFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags mall-config-controller
 * @link [GET] /mallConfigs
 */
export function getMallConfigs(): Promise<BaseResponseListMallConfigFoundResponse>;
export function getMallConfigs(
    options: ApiOptions<never, GetMallConfigsQuery>
): Promise<BaseResponseListMallConfigFoundResponse>;
export function getMallConfigs(
    options?: ApiOptions<never, GetMallConfigsQuery>
): Promise<BaseResponseListMallConfigFoundResponse> {
    return request<never, never, GetMallConfigsQuery>('/mallConfigs', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getMallConfigs',
    });
}

/**
 * save
 *
 * @export
 * @tags mall-config-controller
 * @link [POST] /mallConfigs
 */
export function postMallConfigs(
    data: PostMallConfigsBody
): Promise<BaseResponseMallConfigFoundResponse>;
export function postMallConfigs(
    data: PostMallConfigsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseMallConfigFoundResponse>;
export function postMallConfigs(
    data: PostMallConfigsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseMallConfigFoundResponse> {
    return request<PostMallConfigsBody, never, never>('/mallConfigs', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postMallConfigs',
    });
}

export const meta = [
    { tags: ['mall-config-controller'], path: '/mallConfigs', method: 'get' },
    { tags: ['mall-config-controller'], path: '/mallConfigs', method: 'post' },
];
