import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import TaskRoleConfigComponent from "./component";

export default class TaskRoleConfigRoute extends TaskRoleConfigComponent {
    model() {
        let { props, locales } = this;
        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );
        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionTasks/roleSetting/" + params.taskId,
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        Udesk.utils.string.format(
                            UdeskLocales.current.business.info.loadError,
                            UdeskLocales.current.pages.tasks.manage.template.roleConfig.index.name
                        )
                    );
                    reject(reason);
                }
            );
        });
        // let inspectionConditionsPromise = new Promise((resolve, reject) => {
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         "/inspectionConditions",
        //         props.sdkOptions
        //     );
        //     Udesk.ajax.get(url, params)
        //         .then(
        //             resp => {
        //                 resolve(resp.data);
        //             },
        //             reason => {
        //                 Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.condition.index.title));
        //                 reject(reason);
        //             }
        //         );
        // });
        let processStatusPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionTasks/" + params.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });
        let fields = [
            {
                id: 1,
                label: locales.fix.keywordMatch,
                apiName: "is",
                fieldType: 10
            }
        ];
        return {
            condition: modelPromise,
            // inspectionConditions: inspectionConditionsPromise,
            fields: fields,
            task: props.sdkOptions.props.task,
            taskId: params.taskId,
            basicInfoFields: props.sdkOptions.props.basicInfoFields,
            processCreateStatus: processStatusPromise
        };
    }
}
