export const WordStyle = {
    strictness: 1,
    mild: 2,
    lively: 3,
    simple: 4,
};

export const useWordStyle = () => {
    const options = [
        {
            label: '严谨',
            value: WordStyle.strictness,
        },
        {
            label: '温和',
            value: WordStyle.mild,
        },
        {
            label: '活泼',
            value: WordStyle.lively,
        },
        {
            label: '简约',
            value: WordStyle.simple,
        },
    ];

    return {
        options,
    };
};
