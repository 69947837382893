import React from 'react';
import Udesk from 'Udesk';
import { Button } from 'udesk-ui';
import ModalMonit from './modal-monit';
import DropdownMonit from './dropdown-monit';
import SdkLoaderBase from '../../../common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';
import moment from 'moment';
import { postSupervisionItemReportConfigs } from 'src/api/supervisionItemReportConfigs';
import { putSupervisionItemReportConfigsById } from 'src/api/supervisionItemReportConfigs/{id}';

const getDefaultMonit = () => {
    const startDefault = moment().add(-30, 'day').format('YYYY-MM-DD 00:00:00');
    const endDefault = moment().format('YYYY-MM-DD 23:59:59');
    return {
        condition: {
        //选择日期
            statAt: [startDefault, endDefault],
            // 监督项
            point_id: '-1',
            // 人员
            organization_id: '-1',
            // 人员
            agent_id: '-1',
            // 通话渠道
            inspect_data_source: 1,
            // 通话范围
            call_type: -1,
        },
        defaultFlag: 0,
        id: 'default',
        name: UdeskLocales['current'].components.sdkLoaderBase.component.custom,
        showFields: undefined,
    };
};

function extend(object: Object, attrs: Object) {
    Reflect.ownKeys(attrs).forEach((key) => {
        Reflect.set(object, key, attrs[key]);
    });
    return object;
}

function fire(this: any, fn: Function, ...args: any[]) {
    if (fn) {
        return fn.apply(this, args);
    }
}

class QualityAnalysisComponent extends SdkLoaderBase {
    privates: any = {
        renderContainerId: "data-analysis-quality-analysis",
        pageTitle: /* 执行力监督 */UdeskLocales['current'].pages.gong.monitoringReport.index.executiveSupervision,
        defaultCurrent: getDefaultMonit(),
    };
    dropDown: React.RefObject<any>;
    constructor(props: any) {
        super(props);
        const me = this;
        me.state = { 
            current: null, 
            isMonit: false, 
            title: UdeskLocales['current'].components.sdkLoaderBase.component.custom, 
            isMonitText: true 
        };
        this.dropDown = React.createRef();
        const getRenderToOptions = me.actions.getRenderToOptions;

        extend(me.actions, {
            getRenderToOptions: () => {
                const options = getRenderToOptions.call(this);
                // 这里重新定义sdk传入的方法
                extend(options.methods, {
                    initQaRequest: () => {
                        const {sdkOptions} = this.props;
                        return (url, params, method= 'get') => {
                            const httpUrl = Udesk.business.apiPath.concatApiPath(
                                url,
                                sdkOptions,
                                sdkOptions.props.domain
                            );
                            return Udesk.ajax[method](httpUrl, params);
                        };
                    },
                    setInit: (initHandle: Function) => {
                        extend(this.actions, {
                            initHandle
                        });
                        if (this.state.current) {
                            initHandle(this.state.current);
                        }
                    },
                    addEvent(events) {
                        Reflect.ownKeys(events).forEach(key => {
                            me.actions[key] = events[key];
                        });
                    }
                });
                return options;
            }
        });
        this.onMonitCancel = this.onMonitCancel.bind(this);
        this.onMonitOpen = this.onMonitOpen.bind(this);
        this.onMonitOk = this.onMonitOk.bind(this);
        this.dropClick = this.dropClick.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
        this.renderExtra = this.renderExtra.bind(this);
    }
    onMonitOk (name) {
        const param = this.actions.getConfig?.();
        const dropDown = this.dropDown.current;
        if (param) {
            param.name = name;
            if (this.state.current?.id !== 'default') {
                param.id = this.state.current.id;
            }
            if (param.showFields) {
                param.showFields = JSON.stringify(param.showFields);
            }

            if (param.id) {
                putSupervisionItemReportConfigsById(
                    param,
                    {
                        segments: {
                            id: param.id,
                        },
                    }
                ).then(() => {
                    dropDown.updateItem(param);
                    Udesk.ui.notify.success(UdeskLocales['current'].components.sdkLoaderBase.dropdownMonit.updateSucceeded);
                });
            } else {
                postSupervisionItemReportConfigs(param).then((res) => {
                    dropDown.newItem(res.data);
                    this.dropClick(res.data);
                    this.onMonitCancel();
                });
            }
        }
    }
    onMonitCancel () {
        this.setState({
            isMonit: false
        });
    }
    onMonitOpen () {
        const current = this.state.current;
        if (current.id === 'default') {
            this.setState({
                isMonit: true
            });
        } else {
            this.onMonitOk(current.name);
        }
    }
    dropClick (current) {
        if (this.state.current !== current) {
            this.setState({
                current
            });
            fire(this.actions.initHandle, current);
        }
    }
    renderTitle() {
        return (
            <DropdownMonit locales={UdeskLocales['current']} ref={this.dropDown} defaultCurrent={this.privates.defaultCurrent} dropClick={this.dropClick} />
        );
    }
    renderExtra() {
        return (
            <>
                <Button type='primary' onClick={this.onMonitOpen}>
                    {
                        this.state.current?.id === 'default' 
                            ? /* 保存当前报表配置 */UdeskLocales['current'].components.sdkLoaderBase.template.saveCurrentReportConfiguration 
                            : /* 更新当前报表配置 */UdeskLocales['current'].components.sdkLoaderBase.template.updateCurrentReportConfiguration
                    }
                </Button>
                <ModalMonit
                    locales={UdeskLocales['current']}
                    current={this.state.current}
                    visible={this.state.isMonit}
                    handleCancel={this.onMonitCancel}
                    handleOk={this.onMonitOk}
                />
            </>

        );
    }
}

export default Udesk.react.udeskify(QualityAnalysisComponent);
