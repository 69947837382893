// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    BaseResponseIntelligentPartnerSpeechDetection,
    PutCompanysIntelligentPartnerSpeechDetectionBody,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 陪练-语音检测配置查询
 *
 * @export
 * @tags 租户接口
 * @link [GET] /companys/intelligent/partner/speech/detection
 */
export function getCompanysIntelligentPartnerSpeechDetection(): Promise<BaseResponseIntelligentPartnerSpeechDetection>;
export function getCompanysIntelligentPartnerSpeechDetection(
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerSpeechDetection>;
export function getCompanysIntelligentPartnerSpeechDetection(
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerSpeechDetection> {
    return request<never, never, never>('/companys/intelligent/partner/speech/detection', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCompanysIntelligentPartnerSpeechDetection',
    });
}

/**
 * 陪练-语音检测配置修改
 *
 * @export
 * @tags 租户接口
 * @link [PUT] /companys/intelligent/partner/speech/detection
 */
export function putCompanysIntelligentPartnerSpeechDetection(
    data: PutCompanysIntelligentPartnerSpeechDetectionBody
): Promise<BaseResponseVoid>;
export function putCompanysIntelligentPartnerSpeechDetection(
    data: PutCompanysIntelligentPartnerSpeechDetectionBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putCompanysIntelligentPartnerSpeechDetection(
    data: PutCompanysIntelligentPartnerSpeechDetectionBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutCompanysIntelligentPartnerSpeechDetectionBody, never, never>(
        '/companys/intelligent/partner/speech/detection',
        {
            ...options,
            data,
            method: 'put',
            __$requestCalleeName: 'putCompanysIntelligentPartnerSpeechDetection',
        }
    );
}

export const meta = [
    { tags: ['租户接口'], path: '/companys/intelligent/partner/speech/detection', method: 'get' },
    { tags: ['租户接口'], path: '/companys/intelligent/partner/speech/detection', method: 'put' },
];
