import Locales from '../locales/index';

let inspectionStatus = [
    { id: 1, key: "all", name: () => Locales.current.enums.inspectionStatus.all },
    { id: 2, key: "unread", name: () => Locales.current.enums.inspectionStatus.unread },
    { id: 3, key: "readed", name: () => Locales.current.enums.inspectionStatus.readed },
    { id: 4, key: "appeal", name: () => Locales.current.enums.inspectionStatus.appeal },
    { id: 5, key: "review", name: () => Locales.current.enums.inspectionStatus.review },
    { id: 6, key: "filed", name: () => Locales.current.enums.inspectionStatus.filed },
];

let samplingStatus = [
    { id: 5, key: "unchecked", name: () => Locales.current.enums.samplingStatus.unchecked },
    { id: 6, key: "checked", name: () => Locales.current.enums.samplingStatus.checked },
];

export default {
    inspectionStatus,
    samplingStatus,
};
