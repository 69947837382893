import UdeskError from './udesk-error';

export default class InvalidArgumentError extends UdeskError {
    constructor(argName, options) {
        let { message, fileName, lineNumber } = options || {};
        let innerMessage = message;
        if (!innerMessage) {
            if (argName) {
                innerMessage = `The argument \`${argName}\` is not valid`;
            } else {
                innerMessage = "The argument is not valid";
            }
        }
        super(innerMessage, fileName, lineNumber);
    }
}