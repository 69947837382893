// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostIntelligentPartnerAiAnalysisQueryFormBody,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 新增自定义报表
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [POST] /intelligentPartner/ai/analysis/query/form
 */
export function postIntelligentPartnerAiAnalysisQueryForm(
    data: PostIntelligentPartnerAiAnalysisQueryFormBody
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerAiAnalysisQueryForm(
    data: PostIntelligentPartnerAiAnalysisQueryFormBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerAiAnalysisQueryForm(
    data: PostIntelligentPartnerAiAnalysisQueryFormBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentPartnerAiAnalysisQueryFormBody, never, never>(
        '/intelligentPartner/ai/analysis/query/form',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerAiAnalysisQueryForm',
        }
    );
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/query/form',
        method: 'post',
    },
];
