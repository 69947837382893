
import React, { useCallback, useEffect } from 'react';
import Udesk from 'Udesk';
import TagsEdit from './tagsEdit';
import UdeskList from 'Component/common/udesk-list';
import RichText from 'Component/pages/components/rich-text-component';
import { Form, Input, Space } from 'udesk-ui';
import { inspect } from 'src/util/core';

export default React.memo((props: any, ref) => {
    const [form] = Form.useForm();
    const {
        data: formData,
        successCallback,
        request,
        scenario = 1,
        locales,
    }= props;

    const isEdit = formData?.isEdit;

    const submitHandle = useCallback((values) => {
        const params = { ...values, scenario };
  
        request('/review/words/accept/sellingPoint', params, 'post').then(
            resp => {
                Udesk.ui.notify.success(/* 保存成功 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.pageRightFrom.savingSucceeded);
                formData.accept = 1;
                successCallback();
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [
        locales,
        formData, 
        request, 
        scenario, 
        successCallback
    ]);

    useEffect(() => {
        formData.form = form;
        form.setFieldsValue({
            callId: formData.callId,
            clusterWordId: formData.id,
            subjectId: formData.mainId,
            keyword: formData.keyword,
            clusterId: formData.clusterId,
            dataSource: formData.dataSource,
            inspectDataSource: formData.inspectDataSource,
            answer: formData.answer,
            wordsList: [].concat(formData.wordsList || []),
            wordsPointsList: [].concat(formData.wordsPointsList || []),
        });
    }, [
        form,
        formData
    ]);

    return (
        <div className='page-right-form'>
            <Form 
                form={form} 
                onFinish={submitHandle}    
            >
                <Form.Item name='callId' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='clusterWordId' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='subjectId' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='keyword' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='clusterId' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='dataSource' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='inspectDataSource' hidden={true}>
                    <Input />
                </Form.Item>
                {
                    inspect(isEdit, () => (
                        <Form.Item name='wordsList'>
                            <Words isEdit={isEdit} locales={locales}/>
                        </Form.Item>
                    ), () => (
                        <Form.Item name='answer'>
                            <RichText isView={!isEdit} />
                        </Form.Item>
                    ))
                }
                <Form.Item name='wordsPointsList'>
                    <TagsEdit isEdit={isEdit}/>
                </Form.Item>
            </Form>
        </div>
    );
});

export const Words = React.memo((props: { 
    isEdit: boolean;
    value?: string[]; 
    onChange?: (data: string[]) => void;
    locales: any;
}) => {
    const { isEdit, onChange, value: data = [], locales } = props;

    const addItem = useCallback(() => {
        const temp = [
            ...data,
            '',
        ];
        onChange?.(temp);
    }, [
        data, 
        onChange
    ]);

    const delItem = useCallback((index: number) => {
        const temp = [...data];
        temp.splice(index, 1);
        onChange?.(temp);
    }, [
        data, 
        onChange
    ]);

    const updateItem = useCallback((value, index) => {
        onChange?.(data.map((item, dataIndex) => {
            if (dataIndex === index) {
                return value;
            }
            return item;
        }));
    }, [
        data,
        onChange
    ]);

    return (
        <div className={data && data.length === 1 ? 'hidden-delete-button': ''}>
            {
                inspect(isEdit, () => (
                    <UdeskList addBtnText={/* 新增话术 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.newScript} addItem={addItem} deleteItem={delItem} addBtnDisabled={data.length > 9999}>
                        {data.map((value, index) => (
                            <RichText className={value && value.length > 0 ? 'ok' : ''} isView={!isEdit} value={value} onChange={(value) => {
                                updateItem(value, index);
                            }}/>
                        ))}
                    </UdeskList>
                ), () =>(
                    <Space direction='vertical'>
                        {data.map((value) => (
                            <RichText isView={!isEdit} value={value} />
                        ))}
                    </Space>
                ))
            }
        </div>
    );
});
