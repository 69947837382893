import date from './date';
import regex from './regex';
import paging from './paging';
import env from './env';

export default {
    date,
    regex,
    paging,
    env,
};
