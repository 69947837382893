
import { getSystemModule } from 'Udesk/system/subApp';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { postIntelligentExtractTasksResultAddKnowledgeBatch } from 'src/api/intelligentExtractTasks/result/addKnowledge/batch';
import { getKnowledgeCategorysInitTree } from 'src/api/knowledgeCategorys/initTree';
import { posterity } from 'src/util/core';
import { useRequest } from 'src/util/hook';
import { Form, Input, Modal, TreeSelect } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

export type RefType = {
    open: (ids: number[], isBatch: boolean) => void;
}

export default React.forwardRef<RefType, any>((props: any, ref) => {
    const [form] = Form.useForm();
    const {
        successCallback,
    }= props;
    const tempDataRef = useRef<any>();

    const {run, data: treeData} = useRequest({
        onSuccess: useCallback((resp) => {
            posterity(
                resp.data, 
                (item) => {
                    item.value = item.id;
                    item.title = item.name;
                }, 
                (item) => item.children
            );
        }, [])
    });

    const {run: saveRun, loading} = useRequest({
        onSuccess: useCallback(() => {
            setVisible(false);
            successCallback(tempDataRef.current);
        }, [
            successCallback
        ])
    });

    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        open(ids, isBatch) {
            form.setFieldsValue({
                extractResultIdList: ids,
                knowledgeCategoryIds: []
            });
            tempDataRef.current = {
                ids, isBatch
            };
            setVisible(true);
        }
    }));

    const submitHandle = useCallback((values) => {
        saveRun(postIntelligentExtractTasksResultAddKnowledgeBatch, {
            ...values, systemModule: getSystemModule()
        });
    }, [saveRun]);

    useEffect(() => {
        run(getKnowledgeCategorysInitTree);
    }, [run]);

    return (
        <Modal
            title={/* 添加到知识库 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.pageRightFrom.addToKnowledgeBase}
            centered
            visible={visible}
            onOk={() => form.submit()}
            onCancel={() => setVisible(false)}
            okButtonProps={{
                loading
            }}
        >
            <div className='page-right-form'>
                <Form 
                    form={form} 
                    onFinish={submitHandle}    
                    labelCol={{span: 6}}
                    wrapperCol={{span: 15}}
                >
                    <Form.Item name='extractResultIdList' hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='knowledgeCategoryIds'
                        label={/* 选择分类 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.pageRightFrom.selectClassification}
                        rules={[
                            { required: true, message: /* 请选择知识库分类 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.pageRightFrom.pleaseSelectAKnowledgeBaseClassification },
                        ]}
                    >
                        <TreeSelect 
                            treeDefaultExpandAll
                            placeholder={/* 请选择知识库分类 */UdeskLocales['current'].pages.intelligentAnalysis.intelligentExtractors.components.pageRightTable.pageRightFrom.pleaseSelectAKnowledgeBaseClassification}
                            allowClear
                            multiple
                            showSearch
                            treeNodeFilterProp={'title'}
                            treeData={treeData}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
});