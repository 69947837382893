import {useEffect, useCallback, useMemo, useState, useContext} from 'react';
import {fire, getPropByPath, isArray, isPlainObject, isString, isUndefined, setPropValue} from '../core';
import Udesk from 'Udesk';
import debounce from "lodash-es/debounce";
import cloneDeep from "lodash-es/cloneDeep";

export { useRequest } from './request';

export const useUdeskContext = () => {
    return useContext(Udesk.react.context.UdeskProvider._context);
};

export const useSdkOptions = () => {
    const context = useContext(Udesk.react.context.UdeskProvider._context);
    if (context) {
        return context.props.sdkOptions;
    }
};

export const useLiveEffect = (event, deps) => {
    useEffect(
        () => {
            const status = { isLive: true };
            const callback = fire(event, (callback) => {
                status.isLive && fire(callback);
            });
            return () => {
                delete status.isLive;
                fire(callback);
            };
        }, 
        deps
    );
};

export const useVideoDemoRequest = (sdkOptions) => {
    let company = Udesk.data.init.company;

    return useCallback((url, params, method = 'get') => {
        let urlPath = url;
        if (sdkOptions) {
            urlPath = Udesk.business.apiPath.concatApiPath(
                url,
                sdkOptions
            );
        }
        return Udesk.ajax[method.toLocaleLowerCase()](urlPath, Object.assign({}, params, { companyId: company.id }));
    }, [sdkOptions, company]);
};

export const useDebounce = (fn, deps, wait = 300, options = {trailing: true}) => {
    return useMemo(() => {
        return debounce(fn, wait, options);
    }, deps);
};

/**
 * 使用被useState包装的值中的某一个属性值，创建一个新的被useState包装的值，解决渲染问题
 * 为了React的prop不可修改的原则，请慎用
 * 
 * @param {*} prop 被useSate包装的值 
 * @param {*} attrName prop 中的某一个属性值
 * @param {*} getDefaultValue 默认值, 如果是对象，需要useMemo或useCallback进行封装
 * @param {*} isAutoUpdateProp 是否自动更新prop中的值，默认自动更新
 * @returns 
 */
export const useStateForPropsAttribute = (prop, attrName, getDefaultValue, isAutoUpdateProp = true) => {
    const [data, setData] = useState(fire(getDefaultValue));

    const setValue = useCallback((data) => {
        let value = data;
        if (typeof data === 'function') {
            if (prop) {
                setData(value = data(isUndefined(prop[attrName]) ? fire(getDefaultValue) : prop[attrName]));
            } else {
                setData(cache => value = data(cache));
            }
        } else {
            setData(value);
        }
        if (prop && isAutoUpdateProp) {
            prop[attrName] = value;
        }
    }, [
        prop, 
        isAutoUpdateProp, 
        attrName, 
        getDefaultValue
    ]);

    useEffect(() => {
        if (prop) {
            setData(prop[attrName] = prop[attrName] || fire(getDefaultValue));
        } else {
            setData(fire(getDefaultValue));
        }
    }, [
        attrName, 
        getDefaultValue, 
        prop
    ]);

    return [data, setValue];
};

export const useDataPath = (dataSource, isClone = true) => {
    const [data, setData] = useState();

    const createData = useCallback((callback) => {
        let temp = data;

        if (isArray(data)) {
            temp = [...data];
        } else if (isPlainObject(data)) {
            temp = {...data};
        }

        fire(callback, temp);

        return temp;
    }, [data]);

    const getValue = useCallback((path) => getPropByPath(data, path), [data]);

    /**
     * 通过属性路径给data的属性赋值
     * 
     * @param {*} path {path: sting; value: any} | [{path: sting; value: any}] | string
     * @param {*} value any
     * 
     * @returns 返回新的data
     */
    const setValue = useCallback((path, value = undefined) => {
        let list = [];
        if (isPlainObject(path)) {
            list.push(path);
        }
        if (isString(path)) {
            list.push({
                path, value
            });
        }

        const temp = createData((temp) => {
            list.forEach(item => {
                setPropValue(temp, item.path, item.value);
            });
        });
        setData(temp);

        return temp;
    }, [
        createData
    ]);

    useEffect(() => {
        setData(isClone ? cloneDeep(dataSource) : dataSource);
    }, [
        dataSource, 
        isClone
    ]);

    return {
        data, setData, 
        getPropValue: getValue, setPropValue: setValue
    };
};
