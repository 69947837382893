let _menuListMap = new Map();
let _menuListTree = [];

function init(originalData) {
    if (originalData) {
        originalData.sort(sortItem);
        _menuListMap = new Map();
        _menuListTree = [];
        let rootItems = originalData.filter((item) => !item.parentId || item.parentId === item.id);
        for (let item of rootItems) {
            _menuListTree.push(buildSubTreeRecursively(null, item, originalData));
        }
        if (_menuListTree.length > 0) {
            this.root = _menuListTree;

            let featureCodeMappings = {};
            if (originalData) {
                for (let i = 0; i < originalData.length; i++) {
                    let feature = originalData[i];
                    featureCodeMappings[feature.permission] = true;
                }
            }
            if (!Object.prototype.hasOwnProperty.call(this, 'hasFeature')) {
                Object.defineProperty(this, 'hasFeature', {
                    value: function (featureCode) {
                        return Object.prototype.hasOwnProperty.call(
                            featureCodeMappings,
                            featureCode
                        );
                    },
                    enumerable: false,
                    configurable: false,
                    writable: false,
                });
            }
        }
    }
}

function buildSubTreeRecursively(parentItem, node, originalArray) {
    if (parentItem) {
        parentItem.childItems.push(node);
    }
    _menuListMap.set(node.id, node);

    node.childItems = [];
    let childItems = originalArray.filter(
        (obj) => obj.parentId === node.id && obj.parentId !== obj.id
    );
    if (childItems.length > 0) {
        for (let child of childItems) {
            buildSubTreeRecursively(node, child, originalArray);
        }
    }
    return node;
}

function sortItem(a, b) {
    return a.orders - b.orders;
}

function clear() {
    this.root = undefined;
}

export default {
    init,
    clear,
    root: undefined,
};
