import React, { FC, memo, useState } from 'react';
import { Modal, Radio } from 'udesk-ui';
// import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';
import './style.scss';
import { useRequest } from 'ahooks';
import { getInspectionPointTemplates } from 'src/api/inspectionPointTemplates';
import { getInspectionModulesByTypeDataSourceByDataSource } from 'src/api/inspectionModules/{type}/dataSource/{dataSource}';
import _map from 'lodash-es/map';
import { RadioChangeEvent } from 'antd';
import { getPointTemplatePointsMyByInspectDataSource } from 'src/api/pointTemplate/points/my/{inspectDataSource}';

type TemplateProps = {
    visible?: boolean;
    onCancel?: () => void;
    onTemplateClick?: (item: any, templateValue: string) => void;
    dataSource?: number;
    showCustomRuleTemplate?: boolean;
};

const Template: FC<TemplateProps> = memo((props) => {
    const { visible, onCancel, onTemplateClick, dataSource, showCustomRuleTemplate } = props;

    const [templateValue, setTemplateValue] = useState('a');
    const [templateList, setTemplateList] = useState<any[]>([]);
    const [checkData, setCheckData] = useState<any[]>([]);
    const [checkModuleData, setCheckModuleData] = useState<any[]>([]);
    const [customRuleTemplate, setCustomRuleTemplate] = useState<any[]>([]);

    useRequest(() => getInspectionPointTemplates({ params: { dataSource: dataSource! } }), {
        ready: !!dataSource,
        refreshDeps: [dataSource],
        onSuccess: (res) => {
            const data = [
                {
                    name: UdeskLocales['current'].pages.tasks.manage.template.checkPoint.index
                        .customize,
                },
                ...(res.data || []),
            ];

            setCheckData(data);
            setTemplateList(data);
            setTemplateValue('a');
        },
    });

    useRequest(
        () =>
            getInspectionModulesByTypeDataSourceByDataSource({
                segments: { dataSource: dataSource!, type: 2 },
            }),
        {
            ready: !!dataSource,
            onSuccess: (res) => {
                let checkModuleData = [];
                _map(res.data || {}, (item, key) => {
                    checkModuleData = checkModuleData.concat(item);
                });
                setCheckModuleData(checkModuleData);
            },
        }
    );

    useRequest(
        () =>
            getPointTemplatePointsMyByInspectDataSource({
                segments: { inspectDataSource: dataSource! },
            }),
        {
            ready: !!dataSource,
            onSuccess: (res) => {
                setCustomRuleTemplate(res.data || []);
            },
        }
    );

    const onRadioChange = (e: RadioChangeEvent) => {
        setTemplateValue(e.target.value);
        if (e.target.value === 'a') {
            setTemplateList(checkData);
        }
        if (e.target.value === 'b') {
            setTemplateList(checkModuleData);
        }
        if (e.target.value === 'c') {
            setTemplateList(customRuleTemplate);
        }
    };

    return (
        <Modal
            visible={visible}
            title={UdeskLocales['current'].pages.tasks.manage.template.checkPoint.index.template}
            onCancel={onCancel}
            footer={null}
            width={768}
            wrapClassName="check-point-index-create-modal"
        >
            <div className="check-point-index-create-modal-body">
                <div className="check-point-index-create-modal-tabs">
                    <Radio.Group
                        defaultValue="a"
                        buttonStyle="solid"
                        onChange={onRadioChange}
                        value={templateValue}
                    >
                        <Radio.Button value="a">
                            {
                                UdeskLocales['current'].pages.tasks.manage.template.checkPoint.index
                                    .tabs.template
                            }
                        </Radio.Button>

                        {showCustomRuleTemplate ? (
                            <Radio.Button value="c">{/* 我的规则 */}{UdeskLocales['current'].pages.tasks.manage.template.checkPoint.components.createModal.index.myRules}</Radio.Button>
                        ) : null}

                        <Radio.Button value="b">
                            {
                                UdeskLocales['current'].pages.tasks.manage.template.checkPoint.index
                                    .tabs.demo
                            }
                        </Radio.Button>
                    </Radio.Group>
                </div>

                <div className="check-point-index-create-modal-templates">
                    {templateList?.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                onClick={() => onTemplateClick?.(item, templateValue)}
                                className="check-point-index-create-modal-templates-item"
                            >
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <span className="udesk-qa-react-web-iconfont">&#xe6cd;</span>
                                </div>
                                <div className="check-point-index-create-modal-templates-item-name">
                                    {item.name}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
});

export default Template;
