import Udesk from 'udesk-web-toolkits/src/udesk';
import config from "./config";
import ui, { initUI, destroy as destroyUI } from './ui';
import enums from './enums';
import utils from './utils';
import react, { init as initReact } from './react';
import 'udesk-web-toolkits/src/styles/fonts/iconfont.css';
import 'udesk-web-toolkits/src/styles/index.css';
import './styles/index.css';

let bakUdeskEnums = Udesk.enums;
let bakUdeskInit = Udesk.init;
delete Udesk.enums;
let ReactUdesk = Udesk.utils.object.deepCopy(Udesk, {
    config,
    ui,
    utils
});
Udesk.enums = bakUdeskEnums;
Udesk.react = react;
ReactUdesk._triggerInitEvent = function () {
    return true;
};

Object.assign(ReactUdesk, {
    init,
    enums: Object.assign(bakUdeskEnums, enums)
});

if (process.env.ENABLE_DEBUG_INFO === 'true') {
    window.UdeskDebug = ReactUdesk;
}

export default ReactUdesk;


function init({
    enableEnumNameCache = true
} = {}) {
    bakUdeskInit.call(ReactUdesk, arguments[0]);
    ReactUdesk.enums._rebuild({
        enableNameCache: enableEnumNameCache
    });
    initUI.call(this.ui);
    initReact.call(this.react);
    this.trigger("init");
    this.on("destroy", () => {
        destroyUI.call(this.ui);
    });
}

// 在React项目中不需要setLocale方法，因为对ReactUdesk的预期不是默认以APP的形式运行的，而应更多考虑作为SDK的形式运行。
// 这意味着需要尽量要减少“单例”的行为，包括语言设置。如果在UI widgets的defaults中包含语言资源，则应该每次调用defaults方法，
// 传入languageCode，每次生成一个新的options。
// function setLocale(languageCode) {

// }
