import Udesk from 'Udesk';
import SdkLoaderBase from '../../../common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';

class SentimentAnalysisComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: "data-analysis-sentiment-analysis",
        pageTitle: UdeskLocales.current.components.pages.sentimentAnalysis.title,
    };
}

export default Udesk.react.udeskify(SentimentAnalysisComponent);