// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PutInspectionRuleApprovesByIdParam,
    PutInspectionRuleApprovesByIdBody,
    BaseResponseInspectionRuleApproveFoundResponse,
} from '../../../types';

/**
 * 进行审批操作(审批通过, 驳回)
 *
 * @export
 * @tags 质检规则审核
 * @link [PUT] /inspectionRule/approves/{id}
 * @param id
 */
export function putInspectionRuleApprovesById(
    data: PutInspectionRuleApprovesByIdBody,
    options: ApiOptions<PutInspectionRuleApprovesByIdParam, never> & {
        segments: PutInspectionRuleApprovesByIdParam;
    }
): Promise<BaseResponseInspectionRuleApproveFoundResponse> {
    return request<PutInspectionRuleApprovesByIdBody, PutInspectionRuleApprovesByIdParam, never>(
        '/inspectionRule/approves/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putInspectionRuleApprovesById' }
    );
}

export const meta = [
    { tags: ['质检规则审核'], path: '/inspectionRule/approves/{id}', method: 'put' },
];
