// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    PostBusinessAnalysisConditionConfigsBody,
    BaseResponseBusinessAnalysisConditionConfigFoundResponse,
} from '../types';

/**
 * save
 *
 * @export
 * @tags business-analysis-condition-config-controller
 * @link [POST] /businessAnalysisConditionConfigs
 */
export function postBusinessAnalysisConditionConfigs(
    data: PostBusinessAnalysisConditionConfigsBody
): Promise<BaseResponseBusinessAnalysisConditionConfigFoundResponse>;
export function postBusinessAnalysisConditionConfigs(
    data: PostBusinessAnalysisConditionConfigsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisConditionConfigFoundResponse>;
export function postBusinessAnalysisConditionConfigs(
    data: PostBusinessAnalysisConditionConfigsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisConditionConfigFoundResponse> {
    return request<PostBusinessAnalysisConditionConfigsBody, never, never>(
        '/businessAnalysisConditionConfigs',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postBusinessAnalysisConditionConfigs',
        }
    );
}

export const meta = [
    {
        tags: ['business-analysis-condition-config-controller'],
        path: '/businessAnalysisConditionConfigs',
        method: 'post',
    },
];
