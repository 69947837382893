// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerDialogFlowConfigQuery,
    BaseResponseIntelligentPartnerDialogFlowGlobalConfigFoundResponse,
    PostReviewIntelligentPartnerDialogFlowConfigBody,
} from '../../../../types';

/**
 * 查询对话流程全局配置
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [GET] /review/intelligentPartner/dialogFlow/config
 */
export function getReviewIntelligentPartnerDialogFlowConfig(
    options: ApiOptions<never, GetReviewIntelligentPartnerDialogFlowConfigQuery> & {
        params: GetReviewIntelligentPartnerDialogFlowConfigQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogFlowGlobalConfigFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerDialogFlowConfigQuery>(
        '/review/intelligentPartner/dialogFlow/config',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerDialogFlowConfig',
        }
    );
}

/**
 * 保存对话流程全局配置
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/dialogFlow/config
 */
export function postReviewIntelligentPartnerDialogFlowConfig(
    data: PostReviewIntelligentPartnerDialogFlowConfigBody
): Promise<BaseResponseIntelligentPartnerDialogFlowGlobalConfigFoundResponse>;
export function postReviewIntelligentPartnerDialogFlowConfig(
    data: PostReviewIntelligentPartnerDialogFlowConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogFlowGlobalConfigFoundResponse>;
export function postReviewIntelligentPartnerDialogFlowConfig(
    data: PostReviewIntelligentPartnerDialogFlowConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogFlowGlobalConfigFoundResponse> {
    return request<PostReviewIntelligentPartnerDialogFlowConfigBody, never, never>(
        '/review/intelligentPartner/dialogFlow/config',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerDialogFlowConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/dialogFlow/config',
        method: 'get',
    },
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/dialogFlow/config',
        method: 'post',
    },
];
