import React, { useState, useCallback, useImperativeHandle, useEffect } from 'react';
import { Button, Table, Popconfirm, message, Input, Form, Modal } from 'udesk-ui';
import { DeleteOutlined , EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLiveEffect, useRequest } from 'src/util/hook';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const DelBtn = (props: {
    locales: any;
    onDelete: () => void;
}) => {
    const {onDelete, locales} = props;
    return (
        <Popconfirm
            title={locales.labels.deleteContent}
            onConfirm={onDelete}
            onCancel={(e) => e?.stopPropagation()}
            okText={locales.labels.confirm}
            placement='bottom'
            cancelText={locales.labels.cancel}
        >
            <Button
                type='text'
                size='small'
                icon={<DeleteOutlined />}
            />
        </Popconfirm>
    );
};

const ColumnHeader = React.memo((props: {
    locales: any;
    openModel: Function;
}) => {
    const {openModel, locales} = props;

    const clickHandle = useCallback(() => openModel(), [openModel]);

    return ( 
        <div className='column-header'>
            <h1>{/* 异议处理话术库 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.dissent.favorite.components.pageLeftTable.objectionHandlingScriptLibrary}</h1>
            <Button icon={<PlusOutlined />} onClick={clickHandle} type='link'>{/* 新建分组 */}{locales.pages.semanticIntelligence.wordsMining.favorite.components.pageLeftTable.newGroup}</Button>
        </div>
    );
});

const EditModal = React.forwardRef((props: any, ref) => {
    const {locales, request, scenario, onSuccess} = props;
    const [form] = Form.useForm();
    const [title, setTitle] = useState('');
    const [visible, setVisible] = useState(false);

    const open = useCallback((item) => {
        if (item) {
            item.key = item.id;
            form.setFieldsValue(item);
        } else {
            form.setFieldsValue({
                key: undefined,
                scenario,
                name: '',
            });
        }
        setTitle(item ? /* 编辑分组 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageLeftTable.editGroup : /* 添加分组 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageLeftTable.addGroup);
        setVisible(true);
    }, [
        locales,
        scenario,
        form
    ]);

    const channelHandle = useCallback(() => setVisible(false), []);

    const submitHandle = useCallback(({key, ...values}) => {
        const url = `review/words/favorite/${key ? key : scenario}`;
        const method = key ? 'put' : 'post';
        const params = {
            id: key,
            ...values
        };
        request(url, params, method).then((r) => {
            onSuccess(method === 'post' ? r.data : params);
            channelHandle();
            message.success(/* 保存成功 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageLeftTable.savingSucceeded);
        });
    }, [
        locales,
        channelHandle, 
        onSuccess, 
        request, 
        scenario
    ]);

    useImperativeHandle(ref, () => ({
        open
    }));

    return (
        <Modal
            title={title}
            visible={visible}
            onOk={form.submit}
            onCancel={channelHandle}
        >
            <Form form={form} onFinish={submitHandle}>
                <Form.Item name='key' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='scenario' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name='name'
                    label={/* 分组名称 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageLeftTable.groupName}
                    rules={[
                        { required: true, message: /* 请输入分组名称 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageLeftTable.pleaseEnterTheGroupName },
                        {
                            pattern: /^.{1,20}$/,
                            message: /* 分组名称长度20字符内 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageLeftTable.groupNameWithinCharacters
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
});

export default React.memo((props: any) => {
    const {
        scenario = 1,
        data,
        setData,
        selectedRowKey, 
        setSelectedRowKey, 
        locales
    }= props;
    const [loading, setLoading] = useState(false);
    const request = useRequest();
    const modalRef = React.useRef<any>();
    
    const openModel = useCallback(item => modalRef.current.open(item), []);
   
    const delHandle = useCallback((id) => {
        let itemIndex = 0;
        const result = [] as any[];
        const url = `review/words/favorite/${id}`;

        request(url, {}, 'del').then(() => {
            message.success(/* 删除成功 */locales.pages.semanticIntelligence.wordsMining.favorite.components.pageLeftTable.deletionSucceeded);
            setData((list) => list.filter((item, index) => {
                if (item.id === id) {
                    itemIndex = index;
                }
                return item.id !== id;
            }));
            setSelectedRowKey((key) => {
                let nextKey = key;
                if (key === id) {
                    if (itemIndex > 0) {
                        itemIndex--;
                    }
                    nextKey = result.length > 0 ? result[itemIndex].id : undefined;
                }
                return nextKey;
            });
        });
    }, [
        locales, 
        request, 
        setData, 
        setSelectedRowKey
    ]);

    const renderCell = useCallback((text, record) => {
        return (
            <div className='table-row-item'>
                <span onClick={() => setSelectedRowKey(record.id)}>{record.name}</span>
                <div className="toolkit-button">
                    <Button
                        onClick={() => {
                            openModel(record);
                        }}
                        type='text'
                        size='small'
                        icon={<EditOutlined />}
                    />
                    <DelBtn locales={locales} onDelete={() => delHandle(record.id)} /> 
                </div>
            </div>
        );
    }, [delHandle, locales, openModel, setSelectedRowKey]);


    const saveSuccessHandle = useCallback((data) => {
        setData((list) => {
            let isExist = false;
            list.forEach((item) => {
                if (item.id === data.id) {
                    isExist = !!Object.assign(item, data);
                }
            });
            if (isExist) {
                return [...list];
            }
            return [...list, data];
        });
    }, [setData]);

    useLiveEffect((success) => {
        setLoading(true);
        const url = `/review/words/favorite/${scenario}`;
        request(url, {
            pageNum: 1,
            pageSize: 99999,
        }).then(({data}) => {
            success(() => {
                data.forEach(item => item.key = item.id);
                setData(data);
            });
        }).finally(() => {
            setLoading(false);
        });
    }, [scenario]);

    useEffect(() => {
        if (!selectedRowKey) {
            if (data.length > 0) {
                setSelectedRowKey(data[0].id);
            }
        }
    }, [
        data, 
        selectedRowKey, 
        setSelectedRowKey
    ]);

    return (
        <div className='words-mining-favorite-page-left dissent'>
            <Table
                loading={loading}
                scroll={{
                    y: '100%'
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    columnTitle: (
                        <ColumnHeader locales={locales} openModel={openModel} />
                    ),
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    pageSize: 99999,
                    total: data.length,
                    hideOnSinglePage: true,
                }} 
                columns={[]}
                dataSource={data}
            />
            <EditModal 
                scenario={scenario}
                locales={locales}
                ref={modalRef}
                request={request}
                onSuccess={saveSuccessHandle} />
        </div>
    );
});
