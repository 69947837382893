// @ts-nocheck

import type { RequestResponse } from 'umi-request';
import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { GetOauthByDomainLoginQuery, GetOauthByDomainLoginParam } from '../../../types';

/**
 * oauthLogin
 *
 * @export
 * @tags oauth-controller
 * @link [GET] /oauth/{domain}/login
 * @param domain
 */
export function getOauthByDomainLogin(
    options: ApiOptions<GetOauthByDomainLoginParam, GetOauthByDomainLoginQuery> & {
        segments: GetOauthByDomainLoginParam;
    } & { params: GetOauthByDomainLoginQuery }
): Promise<RequestResponse<void>> {
    return request<never, GetOauthByDomainLoginParam, GetOauthByDomainLoginQuery>(
        '/oauth/{domain}/login',
        { ...options, method: 'get', __$requestCalleeName: 'getOauthByDomainLogin' }
    );
}

export const meta = [{ tags: ['oauth-controller'], path: '/oauth/{domain}/login', method: 'get' }];
