export default {
    getApiBasePath,
    concatApiPath,
    getStaticBasePath,
};

function getApiBasePath(domain) {
    return (`${domain}/api`);
}

function getVideoBasePath(domain) {
    return (`${domain}/video-demo`);
}

function getSseBasePath(domain) {
    return (`${domain}/sse`);
}

function getBackendBasePath(domain, isSdk = false) {
    return (isSdk ? `${domain}/api` : `${domain}/backend`);
}

function getStaticBasePath(domain, pathPrefix) {
    return (`${domain}/${pathPrefix}`);
}

function concatApiPath(apiSubPath, sdkOptions, domain = null, isSdk = false) {
    if (domain == null) {
        domain = sdkOptions.props.domain;
        // demo页面
        // domain = "//qa-react.local.udesk.cn";
    }
    // 复核列表、抽检列表
    // domain = "//qastar.ud.com";
    if (!apiSubPath.startsWith("/")) {
        apiSubPath = "/" + apiSubPath;
    }
    let url = getBackendBasePath(domain, isSdk) + apiSubPath;
    if (sdkOptions.props.isAppealCheck) {
        url = getApiBasePath(domain) + apiSubPath;
    }
    if (sdkOptions.props.isSse) {
        url = getSseBasePath(domain) + apiSubPath;
    }
    if (sdkOptions.props.isVideo) {
        url = getVideoBasePath(domain) + apiSubPath;
    }
    let query = "";
    if (sdkOptions.props.isAppealCheck) {
        if (url.indexOf("?") > -1) {
            query = `&source=${encodeURIComponent("QA")}`;
        } else {
            query = `?source=${encodeURIComponent("QA")}`;
        }
        if (sdkOptions.props) {
            let {
                agent,
                "app_id": appid,
                signature,
                subdomain,
                timestamp,
                type,
                // queryString
            } = sdkOptions.props;
            if (appid) {
                query += `&appid=${appid}`;
            }
            if (subdomain) {
                query += `&subdomain=${subdomain}`;
            }
            if (type) {
                query += `&type=${type}`;
            }
            if (signature) {
                query += `&signature=${signature}`;
            }
            if (agent) {
                query += `&agent=${agent}`;
            }
            if (timestamp) {
                query += `&timestamp=${timestamp}`;
            }
        // if (queryString) {
        //     query += `&${queryString}`;
        // }
        }
    }

    return (url + query);
}