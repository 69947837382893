// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewCallKeyEventByIdRecommendParam,
    BaseResponseKeyEventFoundResultResponse,
} from '../../../../../types';

/**
 * findRecommend
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/keyEvent/{id}/recommend
 * @param id
 */
export function getReviewCallKeyEventByIdRecommend(
    options: ApiOptions<GetReviewCallKeyEventByIdRecommendParam, never> & {
        segments: GetReviewCallKeyEventByIdRecommendParam;
    }
): Promise<BaseResponseKeyEventFoundResultResponse> {
    return request<never, GetReviewCallKeyEventByIdRecommendParam, never>(
        '/review/call/keyEvent/{id}/recommend',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewCallKeyEventByIdRecommend' }
    );
}

export const meta = [
    { tags: ['通话记录'], path: '/review/call/keyEvent/{id}/recommend', method: 'get' },
];
