import React from 'react';
// import Udesk from 'Udesk';
import { Icon, Tabs } from 'udesk-ui';
import QualityCheckScoreTalkComponent from './component';
import './style.scss';
// import { useDrop } from 'react-dnd';
import SemanticMarkInfo from '../semantic-mark-info';
import AppealHistory from '../appeal-history';
// import AppealHandling from '../appeal-handling';
import SmartTags from '../smart-tags';
import CollectionComponent from '../collection-component';
import QualityScore from '../quality-score';
import { Keywords } from './components/Keywords/index';
import BusinessSummary from '../business-summary';
import UdeskLocales from 'UdeskLocales';
import { hasFeature } from 'src/util/permit';
import ProblemExtraction from './components/ProblemExtraction';
import { inspect } from 'src/util/core';

const { TabPane } = Tabs;

const getTabTiles = (props) => {
    const { UdeskLocales } = props;

    const TAB_TITLES = [
        {
            title: UdeskLocales?.['current'].fix.qualityInspectionScore,
            icon: 'zhijianpingfen',
            key: 'score',
        },
        {
            title: UdeskLocales?.['current'].fix.semanticTags,
            icon: 'yuyibiaoqian',
            key: 'semanticTags',
        },
        {
            title: UdeskLocales?.['current'].fix.smartTags,
            icon: 'zhinengbiaoqian',
            key: 'intelligentTags',
        },
        {
            title: UdeskLocales?.['current'].fix.collect,
            icon: 'zhijianpingfen',
            key: 'collection',
        },
        {
            title: UdeskLocales?.['current'].fix.appealRecord,
            icon: 'zhinengbiaoqian',
            key: 'appealHistory',
        },
        {
            title: /* 关键词 */ UdeskLocales?.['current'].components.qualityCheckScoreTalk.template
                .keyWord,
            icon: 'zhinengbiaoqian',
            key: 'keywords',
        },
        {
            title: /* 问题抽取 */ UdeskLocales?.['current'].components.qualityCheckScoreTalk
                .template.problemExtraction,
            icon: 'zhinengbiaoqian',
            key: 'extraction',
        },
        {
            title: /* 业务小结 */ UdeskLocales?.['current'].components.qualityCheckScoreTalk
                .template.businessSummary,
            icon: 'zhinengbiaoqian',
            key: 'businessSummary',
        },
    ];

    return {
        TAB_TITLES,
    };
};

const TabTitle = (props) => {
    const { title, icon } = props;
    return (
        <div className="tab-title-render" id={`udesk-qa-second-element-introduction-${icon}`}>
            <div>
                <Icon type={icon} antdIcon={true} />
            </div>
            <div
                style={{
                    width: '100%',
                    overflowWrap: 'break-word',
                    whiteSpace: 'break-spaces',
                }}
            >
                {title}
            </div>
        </div>
    );
};

// const ManualEveluation = React.memo((props) => {
//     const { activeFlag, activeChange } = props;
//     return (
//         <div className='quality-check-score-talk-header-eveluation-person'>
//             <Space>
//                 <div className='quality-check-score-talk-header-eveluation-person-item' onClick={activeChange.bind(null, 'true')}>
//                     <Icon
//                         className={ClassNames('quality-check-score-talk-header-icon', {
//                             'quality-check-score-talk-header-xiao': activeFlag === 'true',
//                         })}
//                         type='xiaolian'
//                     />
//                     <div>{locales.fix.satisfy}</div>
//                 </div>
//                 <div className='quality-check-score-talk-header-eveluation-person-item' onClick={activeChange.bind(null, 'false')}>
//                     <Icon
//                         className={ClassNames('quality-check-score-talk-header-icon', {
//                             'quality-check-score-talk-header-ku': activeFlag === 'false',
//                         })}
//                         type='kulian'

//                     />
//                     <div>{locales.fix.notSatisfy}</div>
//                 </div>
//             </Space>
//         </div>
//     );
// });

const DynamicTabs = React.memo((props) => {
    const { children, onChangeActiveTab, showKeys = [], ...originTabsProps } = props;
    // const [activeKey, setActiveKey] = useState(TAB_TITLES[0].key);
    // useEffect(() => {
    //     setActiveKey(TAB_TITLES[4].key);
    // }, [isReview]);

    const { TAB_TITLES } = getTabTiles({ UdeskLocales });

    return (
        <Tabs
            {...originTabsProps}
            // activeKey={activeKey}
            onChange={(key) => onChangeActiveTab(key === TAB_TITLES[6].key)}
        >
            {children.filter(
                (child) => child && Array.isArray(showKeys) && showKeys.includes(child?.key)
            )}
        </Tabs>
    );
});

// const Remark = (props) => {
//     const {value, onChange } = props;
//     return (
//         <div className='quality-check-score-talk-remark'>
//             <div className='quality-check-score-remark-content'>
//                 <Input.TextArea
//                     style={{width: 350}}
//                     autoSize={{ minRows: 6, maxRows: 12 }}
//                     className='quality-check-score-remark-textarea'
//                     value={value}
//                     onChange={onChange}
//                 />
//             </div>
//         </div>
//     );
// };
export default class QualityCheckScoreTalkTemplate extends QualityCheckScoreTalkComponent {
    render() {
        let {
            type,
            testSetList,
            testSetListFlag,
            dropChed,
            isWechatQa,
            isInformationCollect,
            showAppealButton,
            isReview,
            complaintHistory,
            appealSdkOptions,
            refershHistory,
            refreshList,
            isTest,
            onChangeActiveTab,
            isTaskChanged,
        } = this.props;
        let {
            task,
            pointCategoryList,
            visible,
            dropValue,
            prointSet,
            // pointFlag,
            scoreTalkRef,
            menuSelectId,
            // activeFlag,
            // macValue,
            xhr,
            showKeys,
            isPopoverVisible,
            complainPointIdMap,
        } = this.privates;
        let { actions, locales, props } = this;

        const appealHistory = locales.components.pages.appealHistory;

        const { TAB_TITLES } = getTabTiles({ UdeskLocales });

        return (
            <div
                className="quality-check-score-talk"
                ref={scoreTalkRef}
                // onMouseLeave={actions.drawerVisibleTrue.params(false)}
            >
                {!isInformationCollect && (
                    <div className="quality-check-score-talk-header">
                        <div style={{ lineHeight: '32px' }}>
                            <div style={{ marginRight: '24px', display: 'inline-block' }}>
                                {locales.components.pages.components.qualityCheckScore.actualScore}
                                {locales.labels.colon}
                                <span>{task.score !== null ? task.score : `--`}</span>/
                                {actions.formatTotalScore(task)}
                            </div>
                            <If condition={!isWechatQa}>
                                <div style={{ display: 'inline-block' }}>
                                    {
                                        locales.components.pages.components.qualityCheckScore
                                            .actualGrade
                                    }
                                    {locales.labels.colon}
                                    <span style={{ color: '#11A848' }}>{task.inspectionGrade}</span>
                                </div>
                            </If>
                        </div>
                        <div className="quality-check-score-talk-header-evaluation">
                            {/* {task && task.evaluateDto && task.evaluateDto.machineValue && (
                                    <span
                                        style={{
                                            display:
                                                scoreTalkRef.current && scoreTalkRef.current.clientWidth > 312
                                                    ? 'inline-block'
                                                    : 'none',
                                        }}
                                    >
                                        {locales.fix.machineEvaluation}：
                                    </span>
                                )} */}

                            {/* {task && task.evaluateDto && task.evaluateDto.machineValue && (
                                    <div className='qutality-check-score-talk-header-evaluation-choose'>
                                        <div
                                            className='qutality-check-score-talk-header-evaluation-choose-arrows'
                                            style={{
                                                left:
                                                    macValue > 50
                                                        ? scoreTalkRef.current && scoreTalkRef.current.clientWidth > 312
                                                            ? macValue - 40
                                                            : macValue / 2 - 40
                                                        : scoreTalkRef.current && scoreTalkRef.current.clientWidth > 312
                                                            ? macValue - 5
                                                            : macValue / 2 - 5,
                                            }}
                                        >
                                            {macValue > 50 && (
                                                <span
                                                    style={{ textAlign: 'right' }}
                                                    className='qutality-check-score-talk-header-evaluation-choose-scroe'
                                                >
                                                    {macValue}
                                                </span>
                                            )}
                                            <Icon type='ic-arrow-down-s-xiasanjiao' />
                                            {macValue <= 50 && (
                                                <span className='qutality-check-score-talk-header-evaluation-choose-scroe'>
                                                    {macValue}
                                                </span>
                                            )}
                                        </div>
                                        <div
                                            className='qutality-check-score-talk-header-evaluation-choose-list'
                                            style={{
                                                width:
                                                    scoreTalkRef.current && scoreTalkRef.current.clientWidth > 312
                                                        ? '100px'
                                                        : '50px',
                                            }}
                                        ></div>
                                        <div className='qutality-check-score-talk-header-evaluation-choose-title'>
                                            <span>{locales.fix.poor}</span>
                                            <span>{locales.fix.moderate}</span>
                                            <span>{locales.fix.excellent}</span>
                                        </div>
                                    </div>
                                )} */}
                            {/* <Popover
                                    content={
                                        <Remark
                                            value={this.props.caseBaseFlag ? this.privates.remark : task.remark}
                                            onChange={actions.changeTaskRemark}
                                        />
                                    }
                                    placement='topRight'
                                    title={locales.components.pages.components.qualityCheckScore.manualRemark}
                                    trigger='click'
                                >
                                    <Button id='udesk-qa-first-element-introduction' type='text' size='small' style={{color: '#1A6EFF'}} icon={<Icon type='beizhu' />}></Button>
                                </Popover> */}
                            {/* <If condition={!isWechatQa && !showAppealButton && !isTest}>
                                    <Popover
                                        content={<ManualEveluation activeFlag={activeFlag} activeChange={actions.activeChange} />}
                                        title={locales.fix.manualEvaluation}
                                        trigger='click'
                                        placement='topRight'
                                    >
                                        <Button type='text' size='small' style={{color: '#1A6EFF'}} icon={<Icon type='pingjia' />}></Button>
                                    </Popover>
                                </If> */}
                        </div>
                    </div>
                )}
                <DynamicTabs
                    tabPosition="left"
                    showKeys={showKeys}
                    onChangeActiveTab={onChangeActiveTab}
                    isReview={isReview}
                >
                    <TabPane tab={<TabTitle {...TAB_TITLES[0]} />} key={TAB_TITLES[0].key}>
                        <QualityScore
                            {...{
                                isReview,
                                pointCategoryList,
                                visible,
                                locales,
                                actions,
                                menuSelectId,
                                complainPointIdMap,
                                testSetListFlag,
                                dropChed,
                                dropValue,
                                showAppealButton,
                                task,
                                testSetList,
                                xhr,
                                prointSet,
                                isTest,
                                type,
                                isPopoverVisible,
                                appealHistory,
                                isTaskChanged,
                                props,
                            }}
                        />
                    </TabPane>
                    <TabPane tab={<TabTitle {...TAB_TITLES[1]} />} key={TAB_TITLES[1].key}>
                        <SemanticMarkInfo content={props.content} taskId={props.taskId} />
                    </TabPane>
                    <TabPane tab={<TabTitle {...TAB_TITLES[2]} />} key={TAB_TITLES[2].key}>
                        <SmartTags
                            disabled={props.disabledSmartTagsChange}
                            smartTags={props.smartTags || []}
                            smartTagsTitle={
                                locales.components.pages.appealChekingTask.list.smartTags
                            }
                            treeListVisible={props.treeListVisible}
                            callId={props.callId}
                            onSmartTagsChange={props.onSmartTagsChange}
                        />
                    </TabPane>
                    <TabPane tab={<TabTitle {...TAB_TITLES[3]} />} key={TAB_TITLES[3].key}>
                        <CollectionComponent
                            pointCategoryList={pointCategoryList}
                            hitCheckPointSelected={actions.hitCheckPointSelected}
                        />
                    </TabPane>
                    <TabPane tab={<TabTitle {...TAB_TITLES[4]} />} key={TAB_TITLES[4].key}>
                        <AppealHistory
                            refreshList={refreshList}
                            taskId={props.taskId}
                            history={complaintHistory}
                            sdkOptions={appealSdkOptions}
                            refershHistory={refershHistory}
                        />
                    </TabPane>
                    <TabPane tab={<TabTitle {...TAB_TITLES[5]} />} key={TAB_TITLES[5].key}>
                        <Keywords callId={props.callId} />
                    </TabPane>
                    {hasFeature('sys:largeAi:question:extract', () => (
                        <TabPane tab={<TabTitle {...TAB_TITLES[6]} />} key={TAB_TITLES[6].key}>
                            {inspect(props.callId, () => (
                                <ProblemExtraction callId={props.callId} />
                            ))}
                        </TabPane>
                    ))}
                    {/* <TabPane tab={<TabTitle {...TAB_TITLES[5]} />} key={TAB_TITLES[5].key}>
                        <AppealHandling complaintInfoHashMap={props.detail.complaintInfoHashMap} pointCategoryList={pointCategoryList} />
                    </TabPane> */}
                    {hasFeature('sys:largeAi:is:sessionSummary', () => (
                        <TabPane tab={<TabTitle {...TAB_TITLES[7]} />} key={TAB_TITLES[7].key}>
                            <BusinessSummary callId={props.callId} taskId={props.taskId} />
                        </TabPane>
                    ))}
                </DynamicTabs>
            </div>
        );
    }
}
