import React, { useState, useEffect, useImperativeHandle, useCallback } from 'react';
import Udesk from 'Udesk';
import { Icon, Input, Dropdown, Modal, Button, Space } from 'udesk-ui';
import { getSupervisionItemReportConfigs } from 'src/api/supervisionItemReportConfigs';
import {
    deleteSupervisionItemReportConfigsById,
    putSupervisionItemReportConfigsById,
} from 'src/api/supervisionItemReportConfigs/{id}';
import './style.scss';

const { confirm } = Modal;

type PropsType = {
    locales: any;
    defaultCurrent: any;
    dropClick: (item: any) => void;
}

export default React.forwardRef((props: PropsType, ref) => {
    const { dropClick, defaultCurrent, locales } = props;
    const [current, setCurrent] =useState<any>();
    const [values, setValues] = useState<any>();
    const [dropList, setDropList] = useState<any[]>([]);
    const [dropBakeList, setDropBakeList] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        newItem,
        updateItem,
    }));

    const setIsEdit = useCallback((e, item, isEdit = true) => {
        item.isEdit = isEdit;
        setDropList([...dropList]);
    }, [
        dropList
    ]);

    const dropInputChange = useCallback((e, item) => {
        item.name = e.target.value;
        setDropList([...dropList]);
    }, [
        dropList
    ]);

    const newItem = useCallback((item) => {
        setDropList([...dropList, item]);
        setDropBakeList([...dropBakeList, item]);
        setCurrent(item);
        setValues(item.name);
    }, [
        dropBakeList, 
        dropList
    ]);

    const updateItem = useCallback((item) => {
        const old:any = dropBakeList.find(obj => obj.id === item.id);
        if (old) {
            Object.assign(old, {
                ...item,
                isEdit: false,
            });
            setDropList([...dropList]);
        } 
    }, [
        dropBakeList, 
        dropList
    ]);

    const dropItemClick = useCallback((e, item) => {
        setCurrent(item);
        setValues(item.name);
        setVisible(false);
        dropClick(item);
    }, [
        dropClick
    ]);

    const setDefaultCurrent = useCallback((list) => {
        if (current) {
            const id = current.id;
            const isExit = list.some((item) => item.id === id);
            if (!isExit) {
                if (list.length > 0) {
                    dropItemClick(null, list[0]);
                }
            }
        } else {
            if (list.length > 0) {
                dropItemClick(null, list[0]);
            }
        }
    }, [
        current, 
        dropItemClick
    ]);

    const onSave = useCallback((e, item) => {
        const params = {
            ...item
        };
        delete params.isEdit;
        putSupervisionItemReportConfigsById(
            params,
            {
                segments: {
                    id: item.id,
                },
            }
        ).then(() => {
            updateItem(item);
            setValues(item.name);
            Udesk.ui.notify.success(`${/* 更新成功 */locales.components.sdkLoaderBase.dropdownMonit.updateSucceeded}`);
        });
    }, [
        locales.components.sdkLoaderBase.dropdownMonit.updateSucceeded, 
        updateItem
    ]);

    const onDel = useCallback((e, item) => {
        confirm({
            title: /* 确定删除吗？ */locales.components.sdkLoaderBase.dropdownMonit.areYouSureToDelete,
            zIndex: 9999,
            onOk() {
                deleteSupervisionItemReportConfigsById({
                    segments: {
                        id: item.id,
                    },
                }).then(() => {
                    const newDropList = dropList.filter(obj => obj.id !== item.id);
                    setDropList(newDropList);
                    setDropBakeList(dropBakeList.filter(obj => obj.id !== item.id));
                    setDefaultCurrent(newDropList);
                });
            }
        });
    }, [
        dropBakeList, 
        dropList, 
        locales.components.sdkLoaderBase.dropdownMonit.areYouSureToDelete, 
        setDefaultCurrent
    ]);

    const handleVisibleChange = useCallback((visible) => {
        setVisible(visible);
    }, []);

    const dropVisible = useCallback((e) => {
        e.preventDefault();
        setVisible(true);
    }, []);

    const searchInputChange = useCallback((e) => {
        if (e.target.value) {
            let result = dropBakeList.filter((list) => {
                return list.name.indexOf(e.target.value) > -1;
            });
            setDropList([...result]);
        } else {
            setDropList(dropBakeList);
        }
    }, [
        dropBakeList
    ]);

    useEffect(() => {
        let result = [defaultCurrent];
        getSupervisionItemReportConfigs().then((res) => {
            result = result.concat(res.data);
        }).finally(() => {
            setDropBakeList(result);
            setDropList(result);
        });
    }, [
        defaultCurrent
    ]);

    useEffect(() => {
        setDefaultCurrent(dropBakeList);
    }, [
        dropBakeList, 
        setDefaultCurrent
    ]);

    return (
        <Dropdown
            onVisibleChange={handleVisibleChange}
            visible={visible}
            className='page-header-monit-dropdown beautiful-scrollbar'
            overlayClassName='page-header-monit-dropdown-overlay'
            overlay={
                <>
                    <Input.Search allowClear placeholder={locales.labels.searchPlaceHolder} onChange={(e) => searchInputChange(e)} />
                    <ul className='drop-list-ul'>
                        {dropList.length > 0 &&
                            dropList.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <div className='name'>
                                            {item.isEdit ? (
                                                <Input value={item.name} onChange={(e) => dropInputChange(e, item)} />
                                            ) : (
                                                <span
                                                    className='tit-overs'
                                                    title={item.name}
                                                    onClick={(e) => dropItemClick(e, item)}
                                                >
                                                    {item.name}
                                                </span>
                                            )}
                                        </div>
                                        <div
                                            className='tools'
                                            style={{
                                                display: `${item.id === 'default' ? 'none' : 'block'}`,
                                                right: item.isEdit ? 16 : 4,
                                            }}
                                        >
                                            {item.isEdit ? (
                                                <Space>
                                                    <Button
                                                        type='primary'
                                                        size='small'
                                                        onClick={(e) => onSave(e, item)}
                                                    >
                                                        {/* 确定 */locales.labels.confirm}
                                                    </Button>
                                                    <Button size='small' onClick={(e) => setIsEdit(e, item, false)}>
                                                        {/* 取消 */locales.labels.cancel}
                                                    </Button>
                                                </Space>
                                            ) : (
                                                <>
                                                    <Button
                                                        type='text'
                                                        size='small'
                                                        onClick={(e) => setIsEdit(e, item)}
                                                        icon={<Icon type='ic-edit-bianji1' />}
                                                    />
                                                    <Button
                                                        type='text'
                                                        size='small'
                                                        onClick={(e) => onDel(e, item)}
                                                        icon={<Icon type='ic-delete-shanchu1' />}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </>
            }
            trigger={['click']}
        >
            <Button type='link' className='udesk-ui-dropdown-link' title={values} onClick={(e) => dropVisible(e)}>
                <Space>
                    <span className='tit-overs' style={{ maxWidth: 210 }}>
                        {values}
                    </span>
                    <Icon type='zhankai1'></Icon>
                </Space>
            </Button>
        </Dropdown>
    );
});
