import React from 'react';
import EventedClass from 'udesk-web-toolkits/src/udesk/evented-class';

let DEFAULT_PROPS = {
    language: "zh-cn",
    udeskTheme: ""
};
let DEFAULT_OPTIONS = {
    localesManager: null
};

class UdeskContext extends EventedClass {
    constructor(props = {}, context = {}) {
        super(...arguments);
        // Make sure default props is always merged.
        this.props = Object.assign((this.props || {}), DEFAULT_PROPS, this.props, props);
        this.options = Object.assign((this.options || {}), DEFAULT_OPTIONS, this.options, context);
    }
    props = { ...DEFAULT_PROPS }
    options = { ...DEFAULT_OPTIONS }

    updateProps(partialProps) {
        if (partialProps != null && this.props != null) {
            Object.assign(this.props, partialProps);
        }
        this.trigger("changed", [this]);
    }
    updateOptions(partialOptions) {
        if (partialOptions != null && this.options != null) {
            Object.assign(this.options, partialOptions);
        }
        this.trigger("changed", [this]);
    }
    clone() {
        return new UdeskContext(Object.assign({}, this.props), Object.assign({}, this.options));
    }
}

let UdeskContextProvider = null;
let UdeskContextConsumer = null;
let DefaultValue = new UdeskContext();

let Exports = {
    UdeskContext,
    enableDefaultContext: true
};

Object.defineProperties(Exports, {
    defaults: {
        get() {
            return DefaultValue;
        },
        set(value) {
            DefaultValue = value;
            UdeskContextProvider = null;
            UdeskContextConsumer = null;
        },
        enumerable: true,
        configurable: true,
    },
});

function init() {
    Object.defineProperties(this, {
        UdeskProvider: {
            enumerable: true,
            configurable: true,
            get() {
                if (UdeskContextProvider == null) {
                    ensureContext.call(this);
                }
                return UdeskContextProvider;
            }
        },
        UdeskConsumer: {
            enumerable: true,
            configurable: true,
            get() {
                if (UdeskContextConsumer == null) {
                    ensureContext.call(this);
                }
                return UdeskContextConsumer;
            }
        }
    });
}

function ensureContext() {
    if (UdeskContextProvider == null || UdeskContextConsumer == null) {
        let {
            Provider,
            Consumer
        } = React.createContext(this.enableDefaultContext ? this.defaults : undefined);
        UdeskContextProvider = Provider;
        UdeskContextConsumer = Consumer;
    }
}

export default Exports;
export {
    init,
    UdeskContext,
};