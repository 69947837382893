// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetCommunicateStrategyByIdParam,
    BaseResponseCommunicateStrategyFoundResponse,
    PutCommunicateStrategyByIdParam,
    PutCommunicateStrategyByIdBody,
    BaseResponseVoid,
    DeleteCommunicateStrategyByIdParam,
} from '../../types';

/**
 * 获取沟通策略详情-单个
 *
 * @export
 * @tags 沟通策略设置
 * @link [GET] /communicateStrategy/{id}
 * @param id
 */
export function getCommunicateStrategyById(
    options: ApiOptions<GetCommunicateStrategyByIdParam, never> & {
        segments: GetCommunicateStrategyByIdParam;
    }
): Promise<BaseResponseCommunicateStrategyFoundResponse> {
    return request<never, GetCommunicateStrategyByIdParam, never>('/communicateStrategy/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCommunicateStrategyById',
    });
}

/**
 * 更新沟通策略详情
 *
 * @export
 * @tags 沟通策略设置
 * @link [PUT] /communicateStrategy/{id}
 * @param id
 */
export function putCommunicateStrategyById(
    data: PutCommunicateStrategyByIdBody,
    options: ApiOptions<PutCommunicateStrategyByIdParam, never> & {
        segments: PutCommunicateStrategyByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutCommunicateStrategyByIdBody, PutCommunicateStrategyByIdParam, never>(
        '/communicateStrategy/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putCommunicateStrategyById' }
    );
}

/**
 * 删除沟通策略详情
 *
 * @export
 * @tags 沟通策略设置
 * @link [DELETE] /communicateStrategy/{id}
 * @param id
 */
export function deleteCommunicateStrategyById(
    options: ApiOptions<DeleteCommunicateStrategyByIdParam, never> & {
        segments: DeleteCommunicateStrategyByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteCommunicateStrategyByIdParam, never>('/communicateStrategy/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteCommunicateStrategyById',
    });
}

export const meta = [
    { tags: ['沟通策略设置'], path: '/communicateStrategy/{id}', method: 'get' },
    { tags: ['沟通策略设置'], path: '/communicateStrategy/{id}', method: 'put' },
    { tags: ['沟通策略设置'], path: '/communicateStrategy/{id}', method: 'delete' },
];
