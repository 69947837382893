var locales = {
  labels: {
    clear: "清空",
    refresh: "刷新",
    nextStep: "下一步",
    prevStep: "上一步",
    closeDriver: "退出引导",
    complete: "完成",
    viewStatistics: "查看统计",
    colon: "：",
    splitter: "/",
    remark: "备注",
    correction: "纠错",
    save: "保存",
    reset: "重置",
    increase: "添加",
    preview: "预览",
    unit: "条",
    cancel: "取消",
    all: "全部",
    searchPlaceHolder: "输入关键字查询",
    greaterThan: "大于",
    lessThan: "小于",
    second: "秒",
    description: "描述",
    column: "列",
    pleaseChoice: "请选择",
    filter: "筛选",
    saveAndSubmit: "保存并提交",
    submit: "提交",
    appeal: "申诉",
    review: "复核",
    choose: "选择",
    manage: "管理",
    delete: "删除",
    add: "新增",
    create: "新建",
    edit: "编辑",
    name: "名称",
    describe: "描述",
    next: "下一步",
    percent: "%",
    export: "导出",
    exportAll: "全部导出",
    confirm: "确定",
    back: "返回",
    versionUpdate:
      "很高兴的通知您，我们网站刚刚发布了新版本！为了保证您的正常使用，我们需要您立即保存当前的工作，然后刷新页面（点击浏览器的刷新按钮，或者按下F5键），以让我们的新版本生效。非常感谢您的配合！",
    more: "更多",
    selected: "已选",
    deduct: "扣",
    maxScore: "最多",
    scoreUnit: "分",
    pointUnit: "个",
    comma: "，",
    view: "查看",
    batchingImport: "导入",
    deleteContent: "您确认要删除吗？删除后将不能再恢复",
    import: "导入",
    uploadFile: "上传文件",
    downloadFile: "导出文件",
    upload: "上传",
    click: "点击",
    downloadTemplate: "下载模板",
    fillInTheForm: "按格式填写",
    UploadExcelfile: "上传Excel完成批量导入",
    uploadIdentical: "如果API名称存在，导入时，会对系统中已有的数据进行更新",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "适用",
    leftBracket: "(",
    rightBracket: ")",
    question: "问题",
    answer: "答案",
    content: "内容",
    search: "搜索",
    query: "查询",
    cancelEdit: "取消编辑",
    noValue: "暂无数据",
    selectAll: "全选",
    changeAGroup: "换一组",
    arrow: " ---> ",
    saveReview: "复核提交",
    saveSpotCheck: "抽检提交",
    rapidSampling: "快速抽检",
    file: "归档",
    download: "下载",
    lastPage: "上一页",
    nextPage: "下一页",
    batchActions: "批量操作",
    cancelDistribution: "取消分配",
    againDistribution: "重新分配",
    deleteRecord: "删除记录",
    oneOptionOpertor: "自选质检员",
    allOptionOpertor: "所有质检员",
    distributionInfoFirstOne: "确认要对",
    distributionInfoFirstTwo: "条记录重新分配吗?请选择分配目标",
    distributionInfoSecondOne: "确定要取消",
    distributionInfoSecondTwo:
      "条记录的分配人信息吗,取消后将变为未分配的复核记录.",
    distributionInfoSecondThree:
      "条记录的分配人信息吗,取消后将变为未分配的抽检记录.",
    distributionInfoThirdOne: "所选记录中有",
    distributionInfoThirdTwo: "条已复核的记录,已复核记录将不会被处理.",
    distributionInfoThirdThree: "条已抽检的记录,已抽检记录将不会被处理.",
    exportError: "导出失败",
    exportSuccess: "导出成功",
    deleteRecordInfo: "确认要删除{0}条记录吗?删除后数据将不能恢复",
    pleaseSetFilter: "请选择筛选条件",
    noCountAfterFilter: "暂无导出数据，请重新设置筛选条件",
    redo: "重新执行",
    batchMove: "批量移动",
    websiteName: "智能质检",
    salesEnable: "销售赋能",
    systemName: "智能质检",
    initSystemError: "网络异常或页面初始化失败，请联系客服",
    day: "天",
    hour: "小时",
    minute: "分",
    questionMark: "？",
    personalSettings: "个人设置",
    logout: "注销",
    ok: "确定",
    saveAs: "另存为",
    pleaseEnter: "请输入",
    executeMessage: {
      executeSuccess: "成功执行{0}条",
      executeError: "{0}条执行失败",
    },
    field: "字段",
    operator: "运算符",
    value: "值",
    placeholder: "请选择",
    pleaseSelect: "请选择",
    totalScore: "总分",
    hyphen: "-",
    semicolon: "；",
    wfm: "智能排班",
    user: "用户",
    none: "无",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload: "保存成功！相关员工刷新页面后生效",
    saveAsName: "{0}(副本)",
    systemInformation: "查看系统资料",
    billingCenter: "计费中心",
    warningMessage: "剩余配额不足，请尽快充值",
    fileTypeHolder: "文件格式有误，请上传{0}格式文件",
    dataNo: "暂无数据",
    move: "移动",
    study: "您有未标记的自学习质检点",
    studySuccess: "标注成功",
    studyError: "标注失败",
    editType: "编辑分类标签",
    copyed: "已复制到剪贴板",
    clickToCopy: "点击复制",
    addFilterConditions: "添加筛选条件",
    filterConditions: "筛选条件",
    messageTime: "消息时间",
    messageType: "消息类型",
    pushType: "消息渠道",
    messageContent: "消息内容",
    messageName: "消息名称",
    messageStatus: "消息状态",
    configuration: "配置信息",
    sendTarget: "发送目标",
    guideUrl: "引导URL",
    noticeDetail: "消息详情",
    action: "操作",
    seeDetail: "查看详情",
    theDay: "{0}日",
    theLastDayOfMonth: "每月的最后一天",
    ignore: "忽略",
    seeNow: "立即查看",
    exportConfirmText: {
      export: "总共{0}条数据，确认导出?",
      exportAll: "确认导出全部内容?",
    },
    samrTagAdd: "添加标签",
    deletePopconfirmContent: "您确认要删除吗？",
    TheLastThirtyDays: "最近30天",
    addNumber: "加",
    subNumber: "减",
    addStrategy: "添加策略",
    minutes: "分钟",
    status: "启用状态",
    pushConfig: "推送配置",
    category: "分类",
    and: "与",
    interval: "间隔",
    switchOn: "开启",
    switchOff: "关闭",
    batchDel: "批量删除",
    exportTxt: "导出TXT文本",
    playSpeed: "倍速",
    messageLogBtn: "查看触发通知的通/对话",
    dataLink: "数据链接",
    yes: "是",
    no: "否",
    sentenceUnit: "句",
    appealDetail: "申诉记录详情",
    detail: "详情",
    default: "默认",
    customer: "客户",
    agent: "客服",
    sort: "排序",
    applyNow: "应用",
    index: "序号",
    allColumns: "可添加的列",
    selectedColumns: "已添加的列",
    syn: "同步",
    saveAndNext: "保存并创建下一条",
    viewReference: "查看引用",
    referenceDetail: "引用详情",
    goToQaList: "去质检",
    goToCheck: "去抽检",
    businessCard: "名片",
    weapp: "小程序",
    redpacket: "红包",
    externalRedpacket: "互通红包",
    sphfeed: "视频号",
    exportTaskCreated: "导出任务创建成功",
    plzGoTo: "请跳转至",
    importTaskCreated: "导入任务创建成功",
    taskCreated: "任务创建成功",
    reply: "回复",
    replied: "回复了",
    release: "发布",
    expandMore: "展开其他 {0} 条回复",
    comment: "评论",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "请至少有一个一个{0}",
      canNotBeEmptyFormat: "{0}不能为空",
      repeatErrorTipFormat: "{0}有重复",
      getInformationFailedFormat: "获取{0}信息失败，请检查网络连接或稍后再试",
      deleteSuccessFormat: "{0}删除成功。",
      deleteFailedFormat: "{0}删除失败，请稍后再试。",
      saveSuccessFormat: "{0}保存成功。",
      saveFailedFormat: "{0}保存失败，请稍后再试。",
      fileSuccessFormat: "{0}归档成功。",
      fileFailedFormat: "{0}归档失败，请稍后再试。",
      exportSuccessFormat: "{0}导出成功",
      exportFailedFormat: "{0}导出失败，请稍后再试。",
      submitSuccessFormat: "{0}提交成功。",
      submitFailedFormat: "{0}提交失败，请稍后重试。",
      confirmSuccessFormat: "{0}确认成功。",
      confirmFailedFormat: "{0}确认失败，请稍后重试。",
      pleaseUploadFile: "请先上传文件！",
      importFileSuccess: "文件导入成功！",
      importFildFailed: "文件导入失败！",
      deleteSuccess: "删除成功！",
      deleteFailed: "删除失败，请稍后重试！",
      illegalTitle: "权限不足",
      illegalMessage:
        "您没有下一步骤的部分或全部权限，请联系管理员配置，点击确定按钮将退出引导",
      illegalMessageCurrent:
        "您没有当前步骤的部分或全部权限，请联系管理员配置，点击确定按钮将退出引导",
      skipMessage: "如果您想暂时跳过此步，请点击跳过",
      errorMsg500: "服务器请求失败，请检查您的操作或刷新重试",
      errorMsg404: "Ooops...您找的页面不见了",
      errorMsg400: "服务器请求失败，请检查您的数据后重试",
      pageOverFlow: "当前仅支持显示10000条，超出部分请设置筛选条件查看",
      isHandAddSmartTag: "是手动添加的智能标签",
      defaultGradeMustAtLast: "默认评级必须置于最末尾",
      AutomaticAllocationInspectorsIsNull: "请进入基本信息页面添加质检员",
      timePickerTips:
        "为了不影响您的正常使用，23：00~2：00为系统维护时间，不执行定时任务。",
      batchDeleteInfo: "成功删除{0}条，失败{1}条，失败原因为该数据已经被使用。",
      batchDeleteTips: "共勾选{0}条数据，确认删除?",
      correctionSuccess: "纠错成功",
    },
    pages: {
      qualityInspectionForm: {
        list: { IsMaxFormNumberError: "最多添加10个质检表单" },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "指标项目{0}之和加起来必须为100",
          banProjectMaxErrorTip: "禁止项最多添加2000个",
          deadlyProjectMaxErrorTip: "致命项最多添加500个",
        },
      },
    },
    info: {
      creatSuccess: "创建成功",
      creatError: "创建失败",
      editSuccess: "修改成功",
      editError: "修改失败",
      deleteSuccess: "删除成功",
      addSuccess: "新增成功",
      deleteError: "删除失败",
      conditionError: "算子逻辑表达式错误",
      customJudgeLogic: "自定义条件组合逻辑表达式错误",
      exportError: "导出失败",
      searchError: "筛选失败",
      loadError: "{0}加载失败",
      saveSuccess: "保存成功",
      saveError: "保存失败",
      fileSuccess: "归档成功",
      fileError: "归档失败",
      cannotBeNull: "{0} 不能为空!",
      pleaseSearchAndSelect: "请输入关键字搜索",
      clickAndToggle: "点击选择切换",
      deleteWarning: "{0}删除将无法恢复，您确定要继续么？",
      getOssAuthFailed: "获取OSS授权失败，请检查网络连接或稍后再试。",
      getInsightAuthFailed: "获取Insight授权失败，请检查网络连接或稍后再试。",
      pictureUploadFailed: "图片上传失败",
      pictureUploadFailedSize: "图片大小超过限制，图片上传失败",
      wecomTagsSyncInfo: "最近更新：{0} ，同步{1}条信息",
      collectSuccess: "收藏成功",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "获取报表数据失败" },
      waveAudio: {
        noWaveMessage:
          "为了维护您的体验，超过{0}分钟的录音暂不显示波形，您可以直接播放",
      },
      customFilter: {
        del: "删除",
        edit: "编辑",
        new: "自定义筛选",
        apply: "应用",
        cancel: "取消",
        onlyApply: "仅适用",
        ohterSave: "另存为",
        save: "保存",
        customFilter: "常用筛选",
        reset: "重置",
        holderKeyWord: "请输入关键词",
        customFilterRule: "筛选逻辑规则：",
        customFilterName: "筛选器名称：",
        holderCustomFilterName: "请输入筛选器名称",
        manualConfiguration: "手动配置",
        holderCustomRule: "请输入自定义逻辑条件",
        example: "示例",
        dragConfiguration: "拖拽配置",
        condition: "条件",
        operation: "运算",
        infoDel: "内容拖出区域外删除",
        toConfigure: "配置",
        holderCustomFilterNameLength: "筛选器名称最大长度为10",
        customFilterNameNone: "筛选器名称不能为空",
        and: "和",
        or: "或",
        not: "非",
        delSuccess: "删除成功",
        saveSuccess: "保存成功",
        updateSuccess: "修改成功",
        customFilterCondition: "筛选条件",
        conditionRule: "条件规则",
        none: "无",
        filterPlaceholder: "请输入关键词",
      },
      labelTabs: {
        addThis: "添加到此",
        goThis: "移动到此",
        saveGo: "保存并训练",
      },
      datePicker: {
        today: "今天",
        yesterday: "昨天",
        theDayBeforeYesterday: "前天",
        thisWeek: "本周",
        lastWeek: "上周",
        thisMonth: "本月",
        lastMonth: "上个月",
        lastSevenDay: "最近7天",
        lastThirtyDay: "最近30天",
        thisQuarter: "本季度",
        lastQuarter: "上个季度",
        thisYear: "今年",
        lastYear: "去年",
        lastYearFromToday: "最近一年",
      },
      pagination: { page: "共{0}条，{1}页" },
      wechatRecord: {
        loadMoreBtn: { top: "加载更早的消息", bottom: "加载更多消息" },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "质检任务",
        informationCollectTasksManageTitle: "采集任务",
        customerServiceCenterTitle: "客服中心",
        informationTitle: "数据中心",
        intelligentAnalysisTitle: "智能分析",
        semanticIntelligence: "语义智能",
        trainingCenterTitle: "训练中心",
        adminTitle: "系统管理",
      },
      qualityCheckingTask: {
        title: "通话质检",
        index: {
          tipOne:
            "通话质检用于评价员工的呼叫中心工作质量，您可以结合自己的业务，设置合适的质检表单",
          tipTwo:
            "可以为质检任务设定合适的规则，包括筛选、抽样等。质检员进行评分后，客服可以查看质检结果并进一步申诉,",
          addTaskTitle: "添加质检任务",
          deleteTipTitle: "删除质检任务",
          deleteTipContent: "质检任务删除后不可恢复，确认删除吗？",
          checkingTask: "质检任务",
          checkingTaskList: "质检任务列表",
          formList: "表单列表",
          viewHelpDocuments: "查看帮助文档",
        },
        manage: {
          taskSetting: {
            title: "任务设置",
            form: "表单",
            basicInfo: "基本信息",
            visibleRange: "可见范围",
            visibleRangeTip:
              "选择的客服、客服组可以查看该质检任务，另外可以结合权限设置，为这个任务客服组长、客服、质检员等",
            automaticSampling: "自动抽样",
            automaticSamplingTip:
              "您可以设置自动抽样的规则，这样系统将按照您设置的规则定期添加质检任务，例如每周一抽取上周5%的通话",
            samplingRate: "抽样频率",
            samplingTime: "抽样时间",
            samplingRange: "抽样范围",
            samplingRule: "抽样规则",
            fillterCondition: "筛选条件",
            workflow: "质检工作流程",
            appealsTime: "允许申诉时间",
            appealsTimeTipOne: "评分提交后",
            appealsTimeTipTwo: "天内",
            appealsTimeTipThree:
              "超过允许申诉时间，记录质检状态自动修改为“已确认”",
            auditTime: "审核处理时间",
            auditTimeTipOne: "提出申诉后",
            auditTimeTipTwo: "天内",
            auditTimeTipThree:
              "超过审核处理时间，记录维持原评分，质检状态自动修改为“已确认”",
            noAppealsMode: "无申诉模式",
            appealsMode: "允许申诉模式",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "客服",
            customerServicesGroup: "客服组",
            surveyList: "评价列表",
            customFields: "自定义字段",
            fieldConfig: "字段配置",
            from: "来自",
            owner: "负责人",
            inspectorAndReviewerAuthority: "质检复核权限",
            inspector: "质检员",
            reviewer: "复核员",
            inspectorTip: "质检员可以对该任务进行评分",
            reviewerTip: "复核员可以对该任务进行评分",
            dataStatisticsAuthority: "数据统计权限",
          },
          samplingRecords: {
            title: "抽样记录",
            index: {},
            manualSampling: {
              title: "抽样",
              fillterCondition: "筛选条件",
              samplingRule: "抽样规则",
              agent: "客服",
              type: "类型",
              result: "结果",
              evaluate: "评价",
              relayNumber: "中继号",
              source: "来源",
              task: "任务",
              queueState: "排队状态",
              callHanger: "通话挂断方",
              queueTime: "排队耗时",
              talkTime: "通话时长",
              bellTime: "响铃时长",
              recordCountTipOne: "共计 ",
              recordCountTipTwo: " 条记录",
              customerServices: "客服",
              customerServicesGroup: "客服组",
              surveyList: "评价列表",
              customFields: "自定义字段",
              fieldConfig: "字段配置",
              defaultFields: "默认字段",
            },
          },
          taskList: {
            title: "任务列表",
            index: {
              batchDelete: "批量删除",
              batchSubmit: "批量提交",
              batchConfirm: "批量确认",
              itemFields: "评分项目字段",
              categoryFields: "二级分类字段",
              forbidsAndDeadlies: "禁止项和致命项",
              scoreFieldList: "评分项字段列表",
              customerName: "客户名称",
              cellphone: "电话",
              email: "邮箱",
              organization: "公司",
              description: "描述",
              tags: "标签",
              taskUserRole: "当前任务用户角色",
            },
            workBench: {
              title: "记录详情",
              checkForm: "质检表单",
              waitEvaluate: "待评价",
              alreadyEvaluate: "已评价",
              waitSubmit: "待提交",
              alreadySubmit: "已提交",
              waitConfirm: "待确认",
              alreadyConfirm: "已确认",
              waitReview: "待复核",
              alreadyReview: "已复核",
              alreadyDone: "已完成",
              comment: "评价备注",
              totalScore: "总分",
              score: "分",
              remark: "备注",
              changeRecords: "变更记录",
              subject: "主题",
              secondLevelScore: "二级评分",
              deadlyList: "致命项",
              forbidList: "禁止项",
              addToCheckTask: "添加到质检任务",
              submitCheckRemark: "提交了质检评价",
              changeCheckRemark: "修改了质检评价",
              doneCheckRemark: "完成了质检评价",
              submitCheckReview: "提交了质检复核",
              submitCheckAppeal: "提交了质检申诉",
              saveFlowSuccess: "评价成功。",
              saveFlowFailed: "评价失败，请稍后重试。",
              FormDetail: "表单详情",
              callDetail: "通话记录详情",
              sampleCalllogDetail: "记录详情",
              appealReasonTip: "请输入申诉理由（必填）",
              appeal: "申诉",
              appealReason: "申诉理由",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0}质检表单",
        basicInformationTitle: "基本信息",
        indicatorsProjectTitle: "指标项目",
        specialProjectTitle: "特殊项",
        totalScore: "总分",
        indicatorsName: "指标名称",
        score: "分数",
        singleScore: "单项分",
        weight: "权重",
        maxDeductScore: "最多扣分",
        add: "增加",
        detail: "表单详情",
        list: {
          title: "质检表单",
          tipOne:
            "配置质检表单是质检工作的第一步，您可以结合公司的业务，配置最适合的质检表单。",
          tipTwo:
            "我们支持直接评分、权重评分、扣分制等多种表单。建议您设置好规则后，再添加到系统。",
        },
        basicInformation: {
          formTemplate: "表单模板",
          directScore: "直接评分",
          weightedScore: "权重评分",
          deductScore: "扣分",
          directScoreSecondLevel: "直接评分-二级",
          weightedScoreSecondLevel: "权重评分-二级",
          deductScoreSecondLevel: "扣分-二级",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "禁止项",
          banProjectTip:
            "可以用于在质检评分的时候勾选，可以视作违规件，也可以视为备注的补充，不影响实际分数。",
          deadlyProject: "致命项",
          deadlyProjectTip:
            "致命项可以用于在质检评分的时候勾选，如果勾选了致命项，则该条质检任务直接评分0分",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "通话质检",
          entiretyReportTitle: "整体报表",
          serviceGroupReportTitle: "客服组报表",
          serviceReportTitle: "客服报表",
          entiretyReport: {
            pandect: "总览",
            contrast: "对比",
            trend: "趋势",
            analyze: "分析",
            totalScore: "总分",
            banProject: "禁止项",
            deadlyProject: "致命项",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "质检结果列表",
          taskSelectPlaceholder: "选择质检任务",
          agentSelectPlaceholder: "选择客服",
          refresh: "刷新",
          all: "全部",
          unread: "未读",
          inComplaint: "申诉中",
          reviewed: "已复核",
          totalScore: "总评",
          detail: "质检详情",
          audioText: "录音文本",
          callInfo: "通话信息",
          ticketInfo: "工单信息",
          wechatInfo: "会话信息",
          dialogText: "对话文本",
          dialogInfo: "对话信息",
          customer: "客户",
          agent: "客服",
          none: "暂无质检数据",
          appealSuccess: "申诉成功！",
          appealFailed: "申诉失败，请稍后重试！",
          pointListIsNull: "申诉质检点不能为空！",
          pleaseSelectPoint: "请选择质检点！",
          unsupportedFormat: "暂不支持该格式",
          shardingInformation: "以下为分片{0}",
          smartTags: "智能标签",
          businessRecords: "业务记录",
          selectedPointsWarning:
            "请勾选要申诉的质检项，分别为申诉项指定相关语句，然后点击提交。",
          applyReason: "申诉理由（最多50字）",
          chatMessageTypes: {
            all: "全部",
            text: "文本",
            image: "图片",
            agree: "同意会话内容",
            video: "视频",
            voice: "语音",
            file: "文件",
            emotion: "表情",
            link: "链接",
            chatrecord: "聊天记录",
            mixed: "混合消息",
            default: "未支持消息",
            deleteFriend: "删除好友",
            redpacket: "红包",
            card: "名片",
            disAgree: "不同意会话内容",
            weapp: "小程序",
            externalRedpacket: "互通红包",
            meetingVoicevCall: "通话消息",
            uRL: "网页",
            replyTimeout: "超时回复",
            sphfeed: "视频号",
            chatRecordTitle: "聊天记录",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "总分",
            actualScore: "评分",
            actualGrade: "评级",
            appeal: "申诉",
            remark: "人工备注",
            none: "暂无质检详情数据",
            pointEdit: "质检点编辑分类",
          },
          changeList: { title: "变更记录", user: "用户", point: "质检点" },
          relatedRecord: {
            title: "相关记录",
            qualityList: "质检任务",
            none: "无",
            check: "查看",
            loadMore: "加载更多",
          },
        },
      },
      grade: {
        title: "质检评级",
        subTitle:
          "质检评级用于质检打分完成后对质检对象进行分档评级，例如可配置低于60分为不合格，60-85分为良好，高于80分为优秀",
        list: {
          up: "上移",
          down: "下移",
          del: "删除",
          case: "自动加入案例库",
          orderCase: "默认案例库",
          info: "默认评级无匹配条件，不可删除，若不符合上述任何评级，则获得默认评级",
          add: "添加评级",
          save: "保存",
          close: "取消",
          max: "质检评级最多可配置20条",
          name: "未命名",
          success: "保存成功",
        },
      },
      qualityInspectionList: {
        title: "质检列表",
        fastFilter: "质检违规",
        fastFilterTip: "减分制命中为违规，加分制未命中为违规",
        titleWechat: "分析列表",
        titleCollect: "采集列表",
        status: "分析状态",
        reQualityInspectionConfig: "重新质检任务选项",
        pleaseSelectRecheckPoints: "请选择需要重新核算的质检点",
        beginReQualityInspection: "开始执行",
        cancelReQualityInspection: "取消任务",
        reQualityInspection: "重新质检",
        reQualityInspectionWechat: "重新分析",
        reQualityInspectionUnderWay: "重新质检任务中...",
        reQualityInspectionUnderWayTip: "关闭窗口后离开页面不会影响任务进行",
        reQualityInspectionDone: "重新质检已完成",
        reQualityInspectionDoneTip: "现已成功完成重新质检，请点击确认按钮",
        endReQualityInspection: "终止任务",
        alreadyDoneTip: "已完成 {0} 条",
        totalTip: "合计 {0} 条",
        endReInspectionSuccess: "重新质检任务已终止",
        closeWindow: "关闭窗口",
        endReInspectionFailed: "重新质检任务失败，请稍后重试!",
        detail: {
          title: "质检详情",
          wechatTitle: "分析详情",
          titleCollect: "采集详情",
          callIndex: "关联通话",
          textIndex: "关联对话",
          fileConfirm: "确认归档吗？",
          fileConfirmDesc: "归档后将无法编辑质检得分，请确认是否归档",
        },
        sysCloumnsContal: "系统列字段管理",
        personCloumnsContal: "个人列字段管理",
      },
      spotCheckList: {
        title: "抽检列表",
        onlyMyTip: "仅查看我的抽检",
        plInspector: "请选择抽检员",
        allInspector: "查看全部",
        seInspector: "查看指定抽检员",
        myInspector: "仅查看我的",
        noInspector: "查看未分配抽检员",
        detail: { title: "抽检详情" },
      },
      reviewList: {
        title: "复核列表",
        onlyMyTip: "仅查看我的复核",
        plInspector: "请选择复核员",
        allInspector: "查看全部",
        seInspector: "查看指定复核员",
        myInspector: "仅查看我的",
        noInspector: "查看未分配复核员",
        detail: { title: "复核详情" },
      },
      caseBaseList: {
        title: "案例库",
        del: "删除",
        delTitle: "删除后所有内容不会被保存",
        cancel: "取消",
        actions: "操作",
        seeCaseBase: "查看案例",
        seeDetail: "查看详情",
        edit: "编辑",
        save: "保存",
        addCaseBase: "新增案例库",
        export: "导出",
        ok: "确定",
        tip: "质检列表中典型的通话和对话数据，可以保存至案例库方便学习和分享",
        detail: {
          choiceCaseBase: "请选择案例",
          delCaseBase: "删除案例",
          addCaseBase: "添加案例",
          successed: "保存成功",
          info: "请先保存或取消正在编辑的内容",
          caseType: "请选择要加入的案例库",
        },
      },
      testSetList: {
        title: "质检测试集",
        tip: "质检员抽检的数据会保存在质检测试集中，方便针对抽检后比对出来的一些机检错误项去优化质检条件和质检规则的配置",
        refresh: "刷新",
        reQualityInspectionUnderWay: "重新评估任务中...",
        reQualityInspectionUnderWayTip: "关闭窗口后离开页面不会影响任务进行",
        reQualityInspectionDone: "重新评估已完成",
        reQualityInspectionDoneTip: "现已成功完成重新评估，请点击确认按钮",
        endReQualityInspection: "终止任务",
        alreadyDoneTip: "已完成 {0} 条",
        totalTip: "合计 {0} 条",
        endReInspectionSuccess: "重新评估任务已终止",
        closeWindow: "关闭窗口",
        endReInspectionFailed: "重新评估任务失败，请稍后重试!",
        recheckPoint: "重新核算质检点分数与准确率",
        recheckPointTitle: "重新评估选项",
        delete: {
          reQualityInspectionUnderWay: "批量删除任务中...",
          reQualityInspectionUnderWayTip: "关闭窗口后离开页面不会影响任务进行",
          reQualityInspectionDone: "批量删除任务已完成",
          reQualityInspectionDoneTip: "现已成功完成批量删除，请点击确认按钮",
          endReQualityInspection: "终止任务",
          alreadyDoneTip: "已完成 {0} 条",
          totalTip: "合计 {0} 条",
          endReInspectionSuccess: "批量删除任务已终止",
          closeWindow: "关闭窗口",
          endReInspectionFailed: "批量删除任务失败，请稍后重试!",
        },
        reultList: {
          assessmentTime: "上次评估时间",
          assessmentAll: "合计评估",
          strip: "条",
          spot: "点",
          deviation: "偏差",
          accuracyRate: "准确率",
          info: "无评估结果，请评估",
          onlyDeviationInfo: "仅查看偏差记录",
          againAssessment: "重新评估",
          screen: "筛选",
          batchDelete: "批量移除",
          matching: "匹配",
          ok: "是",
          noCancel: "否",
        },
      },
      smartWordsLibrary: {
        title: "智能词库",
        tip1: "同义词：用于降低语义标签的训练量，同义词的相似句无需重复训练",
        tip2: "专业名词：针对识别不准确的企业名称、产品名、行业词汇等专有名词进行转写优化、自然语言理解优化，提升识别准确率、分析准确率",
        tip3: "关注词：对词云分析中想关注的词进行关注，帮助AI分析热点符合当前业务",
        tip4: "屏蔽词：对词云分析中不想关注的词进行屏蔽，帮助AI分析热点符合当前业务",
        tip5: "词权重：用于对语义标签的识别进行纠错优化，通过修改词在句中的影响权重，根据需要优化特定场景下的语义识别效果",
        tip6: "语料数据：录入针对各自业务场景的大段落文本，用于转写纠错和ASR训练，提高ASR转写准确率",
        upload: {
          uploadSynonymWords: "导入同义词",
          uploadProfessionalWords: "导入专业名词",
          uploadAttentionWords: "导入关注词",
          uploadIgnoreWords: "导入忽略词",
          uploadWordsWeight: "导入权重词",
          uploadTypo: "导入错别字词集",
          uploadCorpusData: "导入语料数据",
          uploadCorpusContent: "导入语料数据内容",
          firstUploadStep: "1.下载模板,按模板格式填写",
          downloadTemplateMessage: "下载模板:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep: "2.点击导入按钮,上传符合模板格式的EXCEl文件",
          secondUploadCorpusDataStep:
            "2.点击导入按钮,上传符合模板格式的TXT文件",
          postUploadDataFail: "提交上传数据失败",
          pleaseUploadTemplate: "请上传EXCEl/JSON/TXT文件",
          templateNeedJSONOrXLSX: "文件模板只能为JSON或xlsx格式",
          templateNeedTxt: "语料数据文件模板只能为TXT文件",
          uploadSuccess: "导入成功",
          uploadFailed: "导入失败",
          downloadError: "下载失败",
          downLoadTxtTitle: "语料内容.txt",
        },
        navTabNames: {
          synonymWords: "同义词",
          professionalWords: "专业名词",
          attentionWords: "关注词",
          ignoreWords: "忽略词",
          wordsWeight: "词权重",
          corpusData: "语料数据",
          typoLibrary: "错别字词集",
        },
        synonymWords: {
          createSynonymWords: "新建同义词",
          editSynonymWords: "编辑同义词",
          addSynonymWords: "添加同义词",
          pleaseEnterStandardWords: "请输入标准词",
          synonymWordsExists: "该同义词已存在,无法添加",
          synonymWordsCanNotNull: "添加的同义词不能为空",
          pleaseAddSynonymWords: "请添加同义词",
          addSuccess: "新增成功",
          editSuccess: "修改成功",
          deleteSuccess: "删除成功",
          deleteError: "删除失败",
          required: "*",
        },
        professionalWords: {
          create: "新建专业名词",
          edit: "专业名词编辑",
          addTargetWords: "添加替换词",
          targetWordsExists: "该替换词已存在,无法添加",
          targetWordsCanNotNull: "添加的替换词不能为空",
          pleaseAddTargetWords: "请添加替换词",
          pleaseEnter: "请输入专业名词",
          wordsExists: "该专业名词已存在",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "删除成功",
          deleteError: "删除失败",
          saveError: "保存失败",
          addTargetWordsMaxNumberError: "替换词最多可以添加100个",
          addHomophonyWordsMaxNumberError: "屏蔽词最多可以添加100个",
        },
        attentionWords: {
          create: "新建关注词",
          edit: "关注词编辑",
          pleaseEnter: "请输入关注词",
          wordsExists: "该关注词已存在",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "删除成功",
          deleteError: "删除失败",
        },
        ignoreWords: {
          create: "新建忽略词",
          edit: "忽略词编辑",
          pleaseEnter: "请输入忽略词",
          wordsExists: "该忽略词已存在",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "删除成功",
          deleteError: "删除失败",
        },
        wordsWeight: {
          createWordsWeight: "新建权重词",
          editWordsWeight: "编辑权重词",
          addWordsWeight: "添加权重词",
          pleaseEnterWeightWords: "请输入权重词",
          pleaseEnterWeight: "请输入权重",
          weightNeedInZeroToHundred: "权重需要在0~100之间",
          synonymWordsExists: "该权重词已存在",
          weightNeedNumber: "权重需要整数格式",
          standardWeightInformation: "基准权重为50,权重越大在分析中影响力越强",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "删除成功",
          deleteError: "删除失败",
        },
        corpusData: {
          createCorpusData: "新建语料数据",
          editCorpusData: "编辑语料数据",
          addCorpusData: "添加语料数据",
          pleaseEnterCorpusDescription: "请输入语料描述",
          pleaseEnterCorpusContent: "请输入语料内容",
          synonymWordsExists: "该语料数据已存在",
          CorpusContentWordsOverSetting: "内容字数统计超过20000字,",
          download: "下载",
          upload: "上传",
          see: "查看",
          canNotSave: "请使用导入功能,否则无法保存",
          createSuccess: "添加成功",
          editSuccess: "修改成功",
          deleteSuccess: "删除成功",
          deleteError: "删除失败",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "正确词",
            wrongWord: "错词",
            wrongWordCount: "错词个数",
            wrongWordTip: "按下回车键添加",
            status: "有效性",
          },
          create: "新建错别字词集",
          edit: "编辑错别字词集",
          tip: "错别字词集用于优化“错别字检测”的效果，例如”退款”误发送为“退宽”，则可以通过此功能设置常见的错别字以及正确的字，注意保存后次日生效。",
          onlyChinese: "只支持输入中文词语",
          wrongWordLengthErr: "每个错词长度需在2~10个字",
        },
      },
      semanticTags: {
        title: "语义标签",
        current: "当前语义标签",
        reference1: "质检任务: {0} - {1}",
        reference2: "智能标签: {0}",
        reference3: "数据预处理: {0}",
        reference4: "知识库: {0}",
        index: { import: "导入语义标签" },
        detail: {
          new: "新建语义标签",
          edit: "编辑语义标签",
          name: "名称",
          standardSentences: "标准句",
          effectiveness: "有效性",
          similarSentences: "相似句",
          tip: "支持使用##调用实体API，示例：您的手机号是#sys_phone#吗？",
          excludeSimilarSentences: "排除相似句",
          intelligentRecommend: "智能推荐相似内容",
          fastTraining: "快速训练: ",
          qualify: "正则",
          recallKeyWord: "召回关键词",
          excludeKeywords: "排除关键词",
          excludeKeywordsInfo1:
            "排除关键词、正则优先于所有逻辑生效，若命中排除则不会命中该语义标签",
          keyWordInfo1:
            "召回关键词每行支持填写多个，用英文逗号分隔，填写多个时逻辑为全部命中。",
          keyWordInfo2:
            "召回关键词支持填写多行，填写多行时逻辑为命中任意均可成立。",
          recallRegular: "召回正则",
          excludeRegular: "排除正则",
          similaSentences: "排除相似句",
          simim: "相似度",
          keyWordInfo3:
            "排除相似句优先于所有逻辑生效，若命中排除相似句则不会命中该语义标签，默认相似度80%，相似度越大越相似。",
          keyWordInfo4:
            "规则类似正则，默认存在开始和结束。非必须配合中括号使用，例如[非XXXX]",
          keyWordInfo5:
            "高级模块中的功能通常是为熟悉操作的专家准备的实验功能，如果您并不能确定您应如何操作，建议您不要改动此部分。",
          keyWordInfo6: "跨句检测功能赋予了语义标签跨越句子进行检测的能力。",
          keyWordInfo7: "最多跨越同一角色连续的",
          keyWordInfo8: "忽略不同角色句长不大于",
          keyWordInfo9: "个字的句子进行跨句检测",
          keyWordInfo10: "最长跨句极限句长不大于",
          keyWordInfo11: "去除相似百分比不小于",
          keyWordInfo12: "相似度不低于",
          keyWordInfo13: "%的相似句会被相似匹配（默认相似度80%）",
          keyWordInfo14:
            "相似去重会依据句子的相似度进行匹配，建议去除过于相似的句子以提升检测效率。",
          keyWord: "关键词",
          entity: "实体",
          ruleField: "概念词",
          customRule: "话术规则设计",
          fanhua: "相似问",
          test: "测试",
          testOk: "命中测试",
          senior: "高级设置",
          seniorList: "跨句检测",
          up: "启用",
          down: "关闭",
          ju: "句",
          oneWord: "个字",
          simimNo: "相似去重",
          seniorField: "相似度阈值",
          save: "保存",
          cancel: "取消",
          specialElements: "特殊要素",
          logicRule: "逻辑规则",
          every: "多次匹配",
          recallOk: "命中召回词：",
          recallNo: "未命中召回词",
          ruleOk: "命中召回正则",
          ruleNo: "未命中正则",
          rularOk: "命中规则",
          rularNo: "未命中规则",
          infoOk: "命中标准句或相似句：",
          infoNo: "未命中标准句或相似句",
          fieldOk: "命中排除相似句：",
          fieldNo: "未命中排除相似句",
          excludeRuleNo: "未命中排除正则",
          excludeRuleOk: "命中排除正则",
          excludeKeywordNo: "未命中排除关键词",
          excludeKeywordOk: "命中排除关键词",
          update: "更新成功",
          fanhuaList: "已提交到泛化页签相似句列表",
          wordList: "训练列表为空，无法提交到泛化列表",
          start: "开始",
          end: "结束",
          keyWordInfo15: "泛化列表中存在相似字段（高级配置相似去重约束）",
          saveSuccess: "保存成功",
          errInfo: "有字段未填写或填写不正确",
        },
        table: { deepTraining: "深度训练{0}轮", fastTraining: "快速训练" },
      },
      intelligentCollection: {
        title: "智能采集",
        classification: "分组列表",
        new: "新建采集",
        table: {
          name: "字段名称",
          props: "客户属性",
          type: "字段类型",
          desc: "字段描述",
        },
        detail: {
          isCustomerProps: "保存至客户属性",
          customerPropsHelper:
            "保存后，采集的信息会更新至客户信息。不保存，则不会更新至客户信息",
          name: "字段名称",
          type: "字段类型",
          text: "文本",
          desc: "字段描述",
          descPlaceHolder: "描述该字段采集信息的用处",
          condition: "条件",
          logic: "规则",
          method: "采集方式",
          message: "信息内容",
          range: "抽取范围",
          entity: "抽取实体",
          base: "基本信息",
          collect: "采集信息",
        },
      },
      intelligentTags: {
        title: "智能标签",
        sessionLabelTitle: "会话场景",
        keyEventsTitle: "关键事件",
        customerLabelTitle: "客户标签",
        addClassification: "新建一个标签分类",
        defaultCategoryName: "默认分类",
        index: {
          createTag: "新建标签",
          createEvent: "新建事件",
          editTagClassification: "编辑标签分类",
          selectTagClassification: "选择标签分类",
          editTagGrounp: "编辑分组",
          callTimeError:
            "选择的通话时间/对话开始时间范围不能超过一个月（31天）",
          callRecordCountError: "记录条数为0，请重新选择通话/对话时间",
          selectItmeCountError: "请选择需要执行批量操作的智能标签",
          redoSuccess: "重新执行成功,请到任务中心中查看",
          redoError: "重新执行失败，请稍后重试",
          timeError: "查询时间不能超过一个月（31天）",
          categoryName: "分类名称",
          categoryColor: "分类颜色",
          newTagClassification: "新建标签分类",
          newEventClassification: "新建分类",
          tagClassification: "标签分类",
          eventClassification: "事件分类",
        },
        detail: {
          new: "新建智能标签",
          edit: "编辑智能标签",
          tagName: "名称",
          tagDesc: "说明",
          category: "分类",
          tagStatus: "有效性",
          operator: "算子",
          rule: "规则",
          labelClassAtion: "标签类型",
          labelClassAtionTip1: "标注标签：基于规则进行标签标记",
          labelClassAtionTip2: "分类标签：基于模型训练进行标签标记",
          group: "分组",
          checkLabel: "选择分类",
          warn: "预警",
          warnTips:
            "开启预警后，当会话满足此关键事件规则时，会提示到指定人员，可在待办事项处收到提醒",
          warnError: "请选择预警指定人员",
        },
        subTitle: { common: "智能会话标签", wecom: "企微标签" },
      },
      customerPortrait: {
        title: "客户画像",
        index: { import: "导入客户画像" },
        searchPlaceHolder: "客户ID、昵称或电话",
        callCount: "通对话数:",
        callDuration: "总通话时长:",
        dialogCount: "总对话条数:",
        keyWordsStatistics: "关键词统计（Top 20 & 关注词）",
        keyWordsCount: "（{0}）",
        businessTag: "业务标签",
        keyWordsCloudImg: "关键词云图",
        customerEmotionTrend: "客户情绪变化趋势",
        notFoundCustomer: "未检索到相关客户",
        noKeyWords: "未查询到相关关键词",
        noBusinessTags: "未查询到相关业务标签",
        getKeyWordsStatisticsDataError: "客户关键词统计查询失败",
        getBusinessTagsStatisticsDataError: "客户业务标签查询失败",
        personCustome: "未查询客户信息",
        notimeInfo: "未查询到情绪信息",
        timeList: "日期范围",
        operator: "智能标签",
        placeHolder: "请选择",
        placeOperator: "请选择智能标签",
        search: "查询",
        reset: "重置",
        searchHolder: "搜索",
        call: "通话",
        text: "对话",
      },
      sentimentAnalysis: { title: "舆情发现" },
      saleSentimentAnalysis: { title: "舆情分析" },
      dataPreprocess: {
        title: "数据预处理",
        index: {
          createData: "新建数据",
          selectDataSource: "选择数据源",
          selectStatus: "选择有效性",
          callTimeError:
            "选择的通话时间/对话开始时间范围不能超过一个月（31天）",
          callRecordCountError: "记录条数为0，请重新选择通话/对话时间",
          selectItmeCountError: "请选择需要执行批量操作的数据",
          redoSuccess: "重新执行成功,请到任务中心中查看",
          redoError: "重新执行失败，请稍后重试",
        },
        detail: {
          new: "新建数据",
          edit: "编辑数据",
          name: "名称",
          desc: "说明",
          dataSource: "数据源",
          status: "有效性",
          entity: "抽取实体",
          operator: "算子",
          rule: "规则",
        },
      },
      connectedByConversation: {
        title: "通对话关联",
        list: {
          title: "通对话关联功能",
          info: "通对话关联功能可按照预设规则，将不同的通话与对话关联起来",
          rule: "通对话关联规则",
          source: "请选择数据源",
          classification: "请选择分类",
          choose: "请选择",
          input: "请输入",
          save: "保存",
          cancel: "取消",
          fieldwei: "位",
          fieldNot: "有未填写字段",
          fiedldlengthNo: "字段长度超过限制",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip: "关键词可设置多个，使用逗号分隔",
          applyRole: "检测角色",
          preOperator: "前置算子",
          applyOperatorScope: "检测范围",
          addOperator: "添加算子",
          noPreOperator: "无",
          from: "第",
          to: "句至第",
          end: "句",
          testRegular: "测试正则表达式",
          test: "测试",
          matchSuccess: "匹配成功",
          matchError: "匹配失败",
          querySementicTags: "选择语义标签",
          reQuerySementicTags: "重新选择语义标签",
          sementicTag: "语义标签",
          standardSentence: "标准句",
          similarity: "相似度",
          actions: "操作",
          noFitTag: "没有找到合适的标签，",
          addSemanticTag: "添加一个新的语义标签",
          pleaseEnterRegular: "请输入正则表达式",
          pleaseEnterRegularText: "请输入需要验证的内容",
          newAssociateComponentTip: "如果值为数字类型，请使用旧版功能",
        },
        filterManage: {
          noValueTip: "暂无数据",
          allCall: "全部通话",
          allDialogue: "全部对话",
          allWechat: "全部会话",
          getFiltersListFailed: "获取过滤器列表失败，请稍后重试！",
          getFilterDetailFailed: "获取过滤器详情失败，请稍后重试！",
          getAllCountFailed: "获取通话/对话总条数失败，请稍后重试！",
        },
        listManage: {
          searchPlaceHolder: "请输入关键字搜索",
          filterConditions: "筛选条件",
          records: "记录",
          getRecordsFailed: "获取记录失败，请稍后重试！",
        },
        miniListManage: { getRecordsFailed: "获取记录失败，请稍后重试！" },
        applyTemplates: {
          title: "适用质检模板",
          collectionTitle: "适用采集模板",
        },
        recordDetail: {
          showWaveAudio: "显示录音",
          getDetailFailed: "获取详情失败，请稍后重试！",
          getSmartTagsFailed: "获取智能标签失败，请稍后重试！",
          getTemplatesFailed: "获取适用模板失败，请稍后重试！",
        },
        changeList: {
          sampleStatus: "抽检状态",
          sampleAssignInspector: "分配抽检员",
          reviewAssignInspector: "分配复核员",
          read: "已读",
          distributivePerson: "分配人",
          totalScore: "总分",
        },
        qualityCheckScore: {
          totalScore: "总分",
          actualScore: "得分",
          actualGrade: "评级",
          appeal: "申诉",
          remark: "备注",
          manualRemark: "人工备注",
          none: "暂无质检详情数据",
          interactiveTip: "使用交互识别对客服FAQ的使用情况进行检测",
          interactiveIsEmptyTip: "未检测到交互识别内容",
        },
        semanticMark: {
          semanticContent: "标注句",
          markedSimilarTitel: "已标相似语义标签",
          markedExcludeTitle: "已标排除相似语义标签",
          taskTagTitle: "任务相关语义标签",
          similarTagTitle: "识别相似语义标签（相似度>40%，Top 20）",
          delete: "删除",
          markSimilar: "标记相似",
          markExclude: "排除相似",
          none: "无",
        },
        selectCallTime: {
          callTime: "通话时间/会话开始时间",
          callTimeRemind:
            "您最多可以选择最近一年内单次不超过一个月（31天）的通对话记录",
          callRecordCount: "您已选择通话/对话记录共计{0}条",
          getCallRecordError: "获取记录失败，请稍后重试",
        },
      },
      informationEntities: {
        title: "信息实体",
        index: { import: "导入信息实体" },
        detail: {
          new: "新建信息实体",
          edit: "编辑信息实体",
          name: "名称",
          apiName: "API名称",
          activeFlag: "有效性",
          remark: "描述",
          logicExp: "实体正则",
          sentenceList: "实体内容",
          tip: "实体内容支持输入多个，以空格分隔，为同一实体的同义词条",
          remarkLengthError: "描述长度不能超过255",
          nameLengthError: "名称长度不能超过40",
          entityDuplicateError: "实体内容重复",
        },
      },
      interactiveRecognition: {
        title: "交互识别",
        classify: "分类",
        addClassify: "添加分类",
        editClassify: "编辑分类",
        index: { import: "导入交互识别" },
        detail: {
          new: "新建智能交互",
          edit: "编辑智能交互",
          type: "类型",
          standardQuestion: "标准问题",
          similarQList: "相似问题",
          exceptSimilarQList: "排除相似问题",
          category: "分类",
          targetType: "问题检测目标",
          status: "有效性",
          standardAnswer: "标准答案",
          similarAList: "相似答案",
          wrongAList: "错误答案",
        },
      },
      businessAnalysis: {
        title: "业务分析",
        tip1: "业务趋势分析：纵坐标为通对话数，即满足条件的通对话共计多少条，横坐标为时间",
        tip2: "相关性分析：分析多个指标的关联情况，鼠标悬浮显示相关性数据情况,数据量,相关性,重复率",
        tip3: "转化分析：分析多指标间可形成相互的转化关系，转化的判定标准为同一条通话/对话，若能命中两个不同的指标，则可认为这两个指标存在转化关系",
      },
      knowledgeBase: {
        title: "知识库",
        list: {
          add: "添加子分类",
          delete: "删除分类",
          categoryName: "分类名称",
          createSuccess: "子分类创建成功",
          keyWord: "关键词",
        },
        detail: {
          type: "类型",
          status: "状态",
          classification: "分类",
          standardQuestion: "标准问题",
          answerDetectionRange: "答案检测范围",
          standardAnswer: "标准答案",
        },
      },
      callManage: {
        title: "通话录音筛选器",
        listTitle: "通话管理",
        detailTitle: "通话详情",
      },
      dialogueManage: {
        title: "对话文本筛选器",
        listTitle: "对话管理",
        detailTitle: "对话详情",
      },
      weChatManage: {
        title: "企业微信会话筛选器",
        listTitle: "企业微信会话管理",
        detailTitle: "企业微信会话详情",
      },
      speechRecognition: {
        title: "语音识别",
        asr: "语音识别",
        asrRemind: "关闭此开关会导致语音转文本功能以及机器质检功能失效",
        asrConfig: "ASR过滤",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "启用后，会执行IM的ASR转写",
        asrConfigRemind: "启用后，仅符合条件的通话会执行ASR内容",
        correction: "智能纠错",
        correctionRemind:
          "启用后，会使用“<a href='/site/semantic-intelligence/smart-words-library/professional-words' target='_blank'>智能词库-专业名词</a>”中的内容进行强制纠错转换",
        correctionConfig: "纠错过滤",
        advancedSettings: "高级设置",
        advancedSettingsTip: "为指定数据选择指定的ASR模型。",
        mandarinASRModel: "普通话ASR模型",
        cantoneseASRModel: "粤语ASR模型",
        englishASRModel: "英语ASR模型",
        correctionConfigRemind: "启用后，仅符合下述条件的通话会进行纠错",
        customJudgeLogicIsNull: "自定义逻辑不能为空",
        redoCorrection: "重新执行纠错",
        callTime: "选择通话时间",
        callTimeRemind:
          "您最多可以选择最近一年内单次不超过一个月（31天）的通话记录",
        callRecordCount: "您已选择通话记录共计{0}条",
        redoCorrectionSubmit: "提交执行",
        callTimeError: "选择的通话时间范围不能超过一个月（31天）",
        callRecordCountError: "通话记录条数为0，请重新选择通话时间",
        redoCorrectionSuccess: "重新纠错成功,请到任务中心中查看",
        redoCorrectionError: "重新纠错失败，请稍后重试",
      },
      role: {
        title: "角色管理",
        create: "新建角色",
        detail: {
          title: "角色",
          new: "新建角色",
          view: "查看角色",
          edit: "编辑角色",
          navTabNames: {
            basicInfo: "菜单权限",
            taskPermissions: "质检任务权限",
            dataPermissions: "数据权限",
          },
          basicInfo: { roleName: "名称", permissions: "权限" },
          taskPermissions: {
            allTask: "全部质检任务",
            myCreatedTask: "我创建的质检任务",
          },
          dataPermissions: {
            dataPermissionsConfig: "数据权限配置",
            tips: "该角色能够看见所选分类下的数据",
            select: "选择分类",
            selectPlaceholder: "请选择需要数据可见权限的分类",
          },
        },
      },
      dataMigration: {
        title: "数据迁移",
        dataUpload: "数据迁入",
        dataDowload: "数据迁出",
        error: "请选择要导出的质检任务",
        list: {
          info: "请选择要迁出的质检任务",
          listInfo:
            "请选择是否要迁出质检任务的相关数据（例如智能词库、语义标签、信息实体、智能交互等）",
          noInfo: "请选择是否要迁出其他非质检数据",
          ownInfo: "仅迁出任务",
          everyInfo: "迁出任务相关",
          allInfo: "迁出所有",
          callFilter: "通话过滤器",
          talkFilter: "对话过滤器",
          smartTag: "智能标签",
        },
        upload: {
          info: "1.点击上传按钮，上传符合格式的JSON文件",
          uploadSuccess: "上传成功",
          uploadError: "上传失败",
        },
      },
      dataAnalysis: {
        businessStatistics: "业务统计",
        qualityEffect: "质检健康度",
        qualityAnalysis: "质检分析",
        generalInspecitionReport: "通用报告",
        fullAmountWork: "质检员工作量",
        intelligentTagsAnalysis: "标签分析",
        saleIntelligentTagsAnalysis: "智能标签分析",
        generalInspecitionReportTip:
          "数据总量：统计当前限制的时间和任务范围内，通话和对话数据总量，其中智能质检数据数量&百分比，人工抽检数据数量&百分比，申诉量，抽复检率以及时间趋势图",
        groupTest: "分组测试",
      },
      uploadDownloadList: {
        title: "任务中心",
        tabs: {
          upload: "上传导入",
          download: "下载导出",
          taskCenter: "任务执行",
        },
      },
      uploadDownloadModel: {
        title: "上传下载",
        list: {
          clear: "清空",
          success: "成功",
          fail: "失败",
          hand: "进行中",
          noInfo: "没有数据",
        },
      },
      billingCenter: {
        title: "计费中心",
        export: "导出",
        limit: "提醒限额",
        tips: "计费结果会因四舍五入有所偏差，仅供参考",
        dashboard: {
          remainingQuota: "当前剩余配额",
          usedQuota: "累计已用配额",
          voiceQuality: "语音质检：",
          voice: "语音：",
          textQuality: "文本质检：",
          text: "文本：",
          hour: "小时",
          tenThousandHour: "万小时",
          day: "天",
          cutoff: "截止",
          warning: "（剩余配额不足，请尽快充值）",
          isClosed: "（关闭状态）",
        },
        table: {
          quotaRecord: "配额记录",
          timeFilter: "选择日期：",
          search: "查询",
          reset: "重置",
          id: "流水号",
          type: "类型",
          qualityType: "质检类型",
          quotaChange: "变动",
          surplus: "余额",
          changeTime: "时间",
          voiceQuality: "语音质检",
          textQuality: "文本质检",
          sizeChangeText: "每页显示",
          used: "使用",
          give: "授予",
          deduction: "扣除",
          callTime: "通话时间",
          count: "处理数据量",
          page: "共{0}条，{1}页",
        },
        limitModal: {
          call: "语音质检配额低于",
          callFix: "小时进行提醒",
          dialogue: "文本质检剩余时间小于",
          dialogueFix: "天进行提醒",
        },
      },
      messageCenter: {
        replyTitle: "回复我的",
        answerTitle: "@我的",
        shareTitle: "分享我的",
      },
      columnsManage: { title: "质检字段管理" },
      recordUpload: {
        list: {
          task: "质检任务",
          user: "操作人",
          uploadTime: "上传时间",
          endTime: "结束时间",
          total: "上传总量",
          success: "质检成功",
          failed: "质检失败",
          progress: "质检进度",
          inProgress: "进行中",
          finished: "已完成",
        },
        steps: {
          uploading: "上传录音文件",
          baseInfo: "填写基本信息",
          start: "开始质检",
        },
        uploadComponent: {
          dragger: "拖拽添加文件",
          or: "或",
          selectFile: "选择文件",
          des: "支持wav、mp3格式，采样率为8000Hz，16位的文件",
          limit1: "一次最多上传",
          limit2: "个文件，每个文件大小不超过",
          uploading: "上传中",
          finished: "上传完成",
          success: "成功",
          failure: "失败",
        },
        baseInfo: {
          task: "选择质检任务",
          type: "选择录音形式",
          agentSide: "客服所在通道",
          excel: "上传随路数据",
          checkFileInfo: "校验文件信息",
          excelFileError: "请上传随路数据",
          checking: "校验中...",
          reCheck: "重新校验",
          success: "校验成功",
          excelUoloadFail: "随路数据上传失败",
          tips: {
            tip1: "1.ecxel中录音文件名称需要与上传的录音名称一致，且不能重名",
            tip2: "2.系统以录音文件名称、通话ID查重，重复会导致质检失败",
            tip3: "3.下载的excel录音模版，字体颜色为红色的是必填字段",
            tip4: "4.时间类型的字段格式为:yyyy-mm-dd hh:mm:ss，例如2021-04-20 17:36:30",
          },
        },
        title: "本地录音上传",
        checkTitle: "录音质检",
        start: "开始质检",
        disabledTips: "请先校验录音文件再进行质检",
        tips: "同名录音文件覆盖质检结果和基本信息",
        tips1: "将文件拖拽到此，或点击上传",
        tips2: "支持",
        tips3: "格式",
        type: "wav、mp3",
        tips4: "一次最多上传",
        tips5: "个文件，每个文件大小不超过",
        tipsOverSize: "当前队列已满，请先移除队列中的文件再上传",
        size: "500M",
        count: "20",
        uploaded: "已上传录音文件",
        uploading: "（{0}%）",
        uploadFinished: "（上传完成）",
        bottomTitle: "录音信息采集：",
        recordType: "选择录音形式：",
        double: "双通道通话录音",
        single: "单通道通话录音",
        recordBaseInfo: "上传录音基本信息：",
        addBtn: "添加文件",
        downloadTemplate: "模板下载.excel",
        checkRecord: "校验录音信息",
        checking: "校验中...",
        reCheck: "重新校验",
        checkAll: "全选",
        cancelCheckAll: "取消全选",
        delete: "删除",
        deleteLotSize: "批量删除",
        agentChannel: "客服所在通道：",
        left: "左通道",
        right: "右通道",
        donotKonw: "不知道",
        mission: "选择质检任务：",
        uploadFail: "上传失败",
        repeat: "重复文件",
        overCount: "文件数量超出限制",
        overSize: "文件大小超出限制",
        uoloadFail: "上传至云端失败",
        formatError: "格式错误",
        seccess: "校验成功",
        fail: "校验失败",
        recordChecking: "录音质检中，请稍后...",
        recordCheckSuccess: "录音质检完成",
        recordCheckFail: "录音质检失败，请检查录音是否重复或无效",
        backToIndex: "返回上传列表",
        recordCheckIsStarted:
          "当前有质检任务正在执行，请在当前任务结束后，开始新的任务",
      },
      customerServicePortrait: {
        title: "客服画像",
        tip1: "句长分布律：句长的分布情况,按照句长字数,分段进行统计,只计算客服的",
        tip2: "语速分布律：语速的分布情况,按照语速(字/每分钟),分段进行统计,只计算客服的",
        tip3: "响应时间分布律：响应时间的分布情况,只计算客服响应客户的情况",
        tip4: "质检合规：质检点的合规率",
        tip5: "质检任务平均分：质检任务平均分",
        contol: {
          date: "选择日期：",
          today: "今天",
          thisMonth: "本月",
          lastMonth: "上个月",
          thisQuarter: "本季度",
          lastQuarter: "上个季度",
          thisYear: "今年",
          lastYear: "去年",
          customer: "客服：",
          search: "查询",
          reset: "重置",
          export: "导出",
          indicator: "指标管理",
          selectPlaceholder: "请选择需要对比的客服",
          warning: "最多选择5名客服进行对比",
          warning2: "请至少选择1名客服进行查询",
          dataWarning: "最多只支持查询从开始日期后366天的数据",
          seccuss: "记录导出成功",
        },
        echarts: { distributionLaw: "分布律", count: "次数", bout: "次" },
        portrait: {
          ability: "客服能力维度",
          option: "配置",
          abilityOption: "配置能力维度",
          placeholder: "输入能力维度关键词搜索",
          save: "保存",
          warning: "请至少选择3项指标进行配置，指标数至多可选择10项",
        },
        label: { title: "客服业务标签" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "客服排名",
        select: {
          date: "选择日期：",
          today: "今天",
          thisMonth: "本月",
          lastMonth: "上个月",
          thisQuarter: "本季度",
          lastQuarter: "上个季度",
          thisYear: "今年",
          lastYear: "去年",
          group: "客服组：",
          groupPlaceholder: "请选择客服组",
          customer: "客服：",
          customerPlaceholder: "请选择客服",
          search: "查询",
          reset: "重置",
          compared: "对比",
          export: "导出",
          column: "列",
          warning: "最多选择5名客服进行对比",
          warning2: "请至少选择一名客服",
          dataWarning: "最多只支持查询从开始日期后366天的数据",
          selected: "已选客服：",
          seccuss: "记录导出成功",
        },
        compared: {
          title: "选择客服",
          return: "取消",
          compared: "对比",
          tips: "请选择2-5名客服进行对比",
          warning: "仅支持2-5名客服进行对比",
        },
        transfer: { leftTitle: "列表项", rightTitle: "展示项" },
        table: { page: "共{0}条，{1}页", actionTitle: "操作", action: "查看" },
      },
      conceptWord: {
        title: "概念词",
        new: "新建概念词",
        control: {
          search: "搜索：",
          searchPlaceholder: "名称或关键字搜索",
          commit: "查询",
          reset: "重置",
          import: "导入",
          export: "导出",
          seccuss: "记录导出成功",
        },
        modal: { title: "导入概念词" },
        table: {
          sizeChangeText: "每页显示",
          page: "共{0}条，{1}页",
          edit: "编辑",
          delete: "删除",
          request: "删除后将不可恢复，是否确认删除？",
          cancel: "取消",
          title: {
            conceptWord: "概念词",
            describeWordsNum: "描述词数",
            updateTime: "更新时间",
            status: "有效性",
            effective: "有效",
            invalid: "无效",
            action: "操作",
          },
        },
        detail: {
          title: { new: "新建概念词", edit: "编辑概念词" },
          name: "概念词名称",
          namePlaceholder: "请输入概念词名称",
          effectiveness: "有效性",
          effective: "有效",
          invalid: "无效",
          description: "描述词 : ",
          descriptionPlaceholder: "请输入描述词",
          add: "添加",
          repeat: "重复内容",
          empty: "描述词不能为空",
          recommendEmpty: "暂无更多推荐描述词，请手动输入添加",
          recommend: "智能推荐描述词",
          cancel: "取消",
          change: "换一组",
          descriptionSelected: "已添加描述词 :",
          save: "保存",
          success: "概念词保存成功",
          warning: "概念词名称不能为空",
          warning2: "请至少添加1个描述词",
          submitBtnHandle: {
            title: "有勾选的描述词未添加，保存将放弃未添加的描述词",
            content: "点击确定将回到概念词首页，确认继续吗？",
          },
        },
      },
      smartTools: {
        btn: "智能工具",
        title: {
          similarWords: "相关词发现",
          search: "按词索句",
          semanticTags: "语义标签",
        },
        sentenceSearch: {
          placeholder: "请输入关键词查找，支持多个关键词",
          more: "查看全文",
          pageUp: "上一页",
          pageDown: "下一页",
          total: "共 {0} 条记录",
        },
        similarDiscover: {
          placeholder: "请输入关键词",
          copyed: "已复制",
          none: "暂无相关词语，请尝试其他关键词",
        },
        smartTags: {
          searchPlaceHolder: "请输入关键词或句子",
          createSmartTag: "新建",
          createClusteringSmartTag: " 用 {0} 条数据新建",
          addFromKeyword: "添加搜索内容至相似句",
          hit: "命中",
          similar: "相似",
          name: "语义标签名称",
          namePlaceHolder: "请输入语义标签名称",
          recallWords: "召回词",
          recallWordsPlaceHolder: "请输入召回关键词",
          recallRegular: "召回正则",
          recallRegularPlaceHolder: "请输入召回正则",
          rules: "规则",
          standardSentences: "标准句",
          standardSentencesPlaceHolder: "请输入标准句",
          similaSentences: "相似句",
          similaSentencesPlaceHolder: "请输入相似句",
          notSaveError: "请先保存语义标签，再进行查找",
          add: "添加",
          newPage: "在新页面打开",
          querryError: "泛化列表中存在相似字段（高级配置相似去重约束）",
          detailTitle: "语义标签详情",
        },
      },
      trainingCenter: {
        title: "训练中心",
        edit: "编辑",
        delete: "删除",
        seleteAll: "全选",
        cancelCheckAll: "取消全选",
        add: "添加",
        remove: "移除",
        save: "保存",
        cancel: "取消",
        fastTraining: {
          title: "快速训练",
          titleDes: "快速训练旨在帮助语义标签快速泛化到达20个相似句",
          semanticTags: "语义标签",
          semanticTagsPlaceholder: "请输入流程关键字搜索",
          standardSentence: "标准句：{0}",
          similarSentencesPlaceholder: "输入相似句添加或搜索",
          similarSentencesAdd: "添加",
          similarSentencesSearch: "搜索",
          similarSentences: "已添加相似句",
          trainingCount: "训练数：{0}",
          similarSentencesRecommend: "推荐相似句",
          noResp: "暂无相关语义标签，请更换关键词",
          similarSentencesModalTitle: "相似句搜索",
          moreInfo: "查看上下文",
          moreInfoBtn: "上下文",
          modalSearchType: { all: "全部", customer: "客服", client: "客户" },
          repetWarning: "已存在相同的相似句，请勿重复添加",
          repetRemoveTip: "保存相似句中存在重复内容，已自动去重",
        },
        trainingHistory: {
          title: "训练历史",
          select: {
            date: "选择时间：",
            today: "今天",
            thisMonth: "本月",
            lastMonth: "上个月",
            thisQuarter: "本季度",
            lastQuarter: "上个季度",
            thisYear: "今年",
            lastYear: "去年",
            search: "查询",
            reset: "重置",
          },
          table: {
            time: "时间",
            user: "操作人",
            tag: "语义标签",
            similarCount: "相似句标识数量",
            deleteCount: "移除句数量",
            page: "共{0}条，{1}页",
          },
        },
        classifiedTraining: {
          title: "分类训练",
          tagsGrounp: "标签分组",
          placeHolder: "请选择",
          cellTime: "选择时间",
          search: "查询",
          reset: "重置",
          classifiedFlag: "分类训练情况",
          num: "数值",
          gas: "百分比",
          noData: "未分类训练",
          noDataHeight: "未分类数据占比较高,急需进行训练",
          noDataSmall: "未分类数据占比较高,建议进行训练",
          noDataDays: "上次执行{0}天前",
          training: "训练",
          start: "开始",
          addClass: "增加分类",
          custom: "高级筛选",
          marked: "已标注数据勘误",
          markedHeight: "已经{0}天未进行勘误,建议进行勘误",
          markedSmall: "已经{0}天未进行勘误,急需进行勘误",
          mark: "勘误",
          dataCheck: "已分类数据校验",
          dataCheckSmall: "K-L散度高于0.5，建议训练",
          dataCheckHeight: "K-L散度高于0.8，急需训练",
          check: "校验",
          history: "标注历史记录",
          historyTime: "每日标注与修改会在凌晨2:00自动执行模型训练",
          today: "今日标注",
          article: "条",
          none: "无",
          historyCheck: "历史合计标注",
          look: "查看记录",
          historyluate: "历史数据重算",
          startluate: "发起重算",
          classData: "分类数据",
          classStatus: "训练进度",
          next: "下一条",
          prev: "上一条",
          finish: "完成训练",
          stop: "结束训练",
          classDetail: "训练详情",
          trainingNone: "暂无可训练的智能标签",
        },
        classifiedTrainingHistory: {
          title: "训练历史",
          time: "选择时间：",
          tag: "标签类型：",
          user: "操作员：",
          data: "选择数据源：",
          search: "查询",
          searchBar: "搜索：",
          searchBarPlaceholder: "请输入通话ID/对话 ID 搜索",
          reset: "重置",
          export: "导出",
          all: "全部",
          select: {
            today: "今天",
            thisMonth: "本月",
            lastMonth: "上个月",
            thisQuarter: "本季度",
            lastQuarter: "上个季度",
            thisYear: "今年",
            lastYear: "去年",
            search: "查询",
            reset: "重置",
          },
          table: {
            user: "操作员",
            date: "操作时间",
            data: "数据源",
            id: "通话ID/对话ID",
            tagType: "智能标签分组",
            tagName: "标签名称",
            state: "状态",
            sizeChangeText: "每页显示",
            page: "共{0}条，{1}页",
            call: "通话",
            dialogue: "对话",
          },
        },
        clusteringAnnotation: {
          title: "聚类标注",
          left: { title: "聚类结果" },
          right: {
            title: "聚类句子",
            checkAll: "全选本页",
            tagging: "标注",
            detail: "查看",
            meaningless: "忽略",
            meaninglessTips: "已忽略的聚类结果后续将不再显示，您确认要忽略吗？",
            cancel: "取消",
          },
        },
      },
      clientCenter: {
        clientManage: { title: "客户管理", tabTitle: "客服中心客户" },
        wechatClientManage: {
          title: "企微客户",
          table: { id: "客户ID", name: "客户昵称", tag: "客户标签" },
        },
      },
      collectionRule: {
        title: "采集规则",
        form: { info: "智能采集信息", infoPlaceholder: "请选择分类" },
        table: {
          name: "字段名称",
          props: "客户属性",
          type: "字段类型",
          desc: "字段描述",
        },
      },
      dataAdd: {
        title: "数据添加",
        types: { auto: "自动添加", manual: "手动添加" },
      },
      wechatStaff: {
        table: {
          name: "姓名",
          part: "所属部门",
          phone: "手机号",
          email: "邮箱",
          wechartId: "企业微信ID",
          status: "在职状态",
        },
        info: "请先选择需要同步数据的部门",
        synSuccess: "同步成功",
        init: "初始化",
        syn: "同步",
      },
      appealHistory: {
        appealTime: "申诉时间",
        cancelTime: "撤销时间",
        reviewTime: "复核时间",
        inspector: "质检员",
        result: "申诉结果",
        complainResult: "一共申诉 {0} 个质检项，成功申诉 {1} 个，驳回 {2} 个",
        reason: "申诉原因",
        rejection: "驳回原因",
        cancel: "撤销",
        tip: "确认要撤销申诉吗？",
      },
      appealSdk: {
        time: "通对话时间",
        externalIdCall: "通话记录ID",
        externalIdText: "对话记录ID",
        customerNickName: "客户昵称",
        readStatus: "查阅状态",
        status: "申诉状态",
        isManual: "是否人工质检",
        complainButtonInfo:
          "允许申诉 {0} 次，已申诉 {1} 次，可申诉剩余 {2} 次。{3} 前可申诉",
        submit: "提交申诉",
        complain: "申诉",
        allComplain: "申诉全部",
        pendingSubmit: "待提交",
        taskIds: "质检任务",
        graded: "评分",
        complainSuccess: "申诉提交成功",
        appealSdkIndex: { title: "智能质检结果" },
      },
    },
    lookupRecords: {
      getFiltersListError: "获取过滤器列表信息失败，请检查网络连接或稍后再试！",
      columns: "列",
      filter: "筛选",
      noSearchResult: "没有找到记录",
      pleaseSearch: "请选择一个过滤器，并尝试搜索",
      sift: "筛选",
      create: "新建",
      search: "搜索",
      createTaskTitle: "新建任务",
      title: "标题",
      deadline: "截止时间",
      principal: "负责人",
      enqueueTime: "入队时间",
      computeError: "计算失败，请稍后!",
      sum: "求和",
      max: "最大值",
      min: "最小值",
      avg: "平均值",
      count: "计数",
      maxDate: "最大值（最新）",
      minDate: "最小值（最旧）",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError: "获取Insight链接地址失败，请稍后重试！",
      },
      template: {
        learningReminder: "学习提醒",
        subscriptionDynamics: "订阅动态",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "任务管理", chaneg: "切换" },
        qualityCheckTemplate: {
          text: "质检模板",
          collectText: "采集模板",
          condition: { text: "质检条件" },
          checkPoint: { text: "规则配置" },
          automaticAdd: { text: "流程自动化" },
          manualAdd: { text: "手动添加" },
          roleConfig: { text: "角色配置" },
          basicInfo: { text: "基本信息" },
          smartWordsLibrary: { text: "智能词库" },
          grade: { text: "质检评级" },
          collectionRule: { text: "采集规则" },
          addData: { text: "添加数据" },
          collectionList: { text: "采集列表" },
        },
        qualityCheckWorkbench: {
          text: "质检工作台",
          wechatText: "分析工作台",
          informationCollectText: "采集工作台",
          qualityInspectionList: {
            text: "质检列表",
            wechatText: "分析列表",
            informationCollectText: "采集列表",
          },
          condition: { text: "质检列表(已废弃)" },
          reviewList: { text: "复核列表" },
          spotCheckList: { text: "抽检列表" },
          caseBaseList: { text: "案例库" },
          testSetList: { text: "质检测试集" },
        },
        statisticsAndReports: {
          text: "统计与报表",
          wholeReport: { text: "整体报表" },
          groupReport: { text: "客服组报表" },
          agentReport: { text: "客服报表" },
          customerServiceExecution: { text: "客服分析" },
          analysisOverview: { text: "分析概览" },
          inspectionItemsAnalysis: { text: "质检项分析" },
        },
        wechatQa: {
          text: "企业微信质检",
          task: { text: "分析任务" },
          baseInfo: { text: "基本信息" },
          checkPoint: { text: "分析模型配置" },
          setting: { text: "企业微信分析配置" },
          automaticAdd: { text: "流程自动化" },
          manualAdd: { text: "手动添加" },
        },
        clientCenter: { text: "客服中心" },
      },
      conditionDictionaries: {
        title: "所有条件",
        searchDefaultText: "请输入ID或名称",
        id: "ID",
        name: "名称",
        conditionDetailedShow: "展开",
        conditionDetailedHide: "收起",
        remark: "描述:",
        checkRole: "角色:",
        operator: "算子:",
        operatorLogic: "逻辑:",
      },
      audioTextView: {
        customer: "客户",
        agent: "客服",
        unsupportedFormat: "暂不支持该格式",
      },
      checkPointClassifications: {
        add: "新增分类",
        name: "名称",
        enterToSave: "按回车键保存",
        highestScore: "最高得分上限",
        minimumScore: "最低得分下限",
        defaultMinScore: "默认为0分",
        minimumScoreTitle: "得分下限",
        beginScore: "总体起评分值",
        beginScoreTitle: "起评分值",
        scoreSetTip: "最高得分上限小于各项分值总和，您可以选择：",
        ignoreExcessSpillover: "忽略超额溢出",
        automaticWeightCalculation: "自动权重核算",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "字段" },
    checkRange: {
      numberTipOne: "第",
      numberTipTwo: "句",
      numberTipThree: "句 到 第",
      conditionLimit: "条件限制",
      roleLimit: "角色限制",
      contextLimit: "句子限制",
      notZero: "句子限制不支持输入0",
      startMustLessThanEnd: "起始句位置必须小于结束句位置",
    },
    waveAudio: {
      play: "播放",
      pause: "暂停",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "请输入",
      pleaseEnterRegular: "请输入正则表达式",
      pleaseEnterGrammar: "请输入文法规则",
      pleaseEnterTestText: "请输入需要验证的内容",
      pleaseEnterLoudness: "请输入响度分贝",
      pleaseEnterRangeAbility: "请输入变化幅度",
      afterHit: "命中后检查",
      pleaseEnterContext: "请输入参考语句",
      wordNumber: "字数差距",
      intervalSecond: "时间间隔大于",
      minute: "每分钟",
      wordUnit: "个字",
      test: "测试",
      testRegular: "测试正则表达式",
      testGrammar: "测试文法",
      testGrammarError: "文法测试失败",
      regularError: "正则表达式错误，请重新输入",
      pleaseEnterSemanticSentence: "请输入需要进行语义检测的句子",
      pleaseEnterAssociate: "请参考右侧说明，以构建随路数据表达式",
      querySemanticTags: "选择语义标签",
      reQuerySemanticTags: "重新选择语义标签",
      semanticTags: "语义标签",
      standardSentence: "标准句",
      semanticName: "语义标签",
      sentence: "标准句",
      similarity: "相似度",
      actions: "操作",
      tipOne: "没找到合适的标签，",
      tipTwo: "添加一个新的语义标签",
      notLessThan: "不小于",
      notGreaterThan: "不大于",
      ignoreLessThan: "忽略小于",
      wordUnitSet: "字的句子",
      speedWordNotNull: "语速检测最少字数和最多字数不能都为空",
      speedWordMinValueLessThanMaxValue: "语速检测最少字数不能大于最多字数",
      speechGrabbingIntervalTip: "抢话间隔 >= ",
      speechLengthTip: "抢话句长 >= ",
      msUnit: "ms",
      sUnit: "秒",
      grabIntervalNotNull: "抢话间隔不能为空",
      grabLengthNotNull: "抢话句长不能为空",
      pleaseSelectEntity: "请选择信息实体",
      pleaseSelectOperator: "请选择操作",
      pleaseSelectBasicInfo: "请选择基础信息",
      pleaseEnterFixedValue: "请输入固定值",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "匹配成功",
      matchError: "匹配失败",
      mindetectionStatement: "最小检测语句",
      conceptWordholder: "请选择概念词",
      elementHolder: "请输入一个要素",
      associateMedol: { tabs: { data: "数据", operator: "操作符" } },
      repet: "重复",
      bout: "次",
      ignore: "忽略小于",
      ignoreAffix: "字的句子",
      associateData: "随路数据",
      pretreatmentData: "预处理数据",
      reviewTaskData: "复核任务数据",
      taskData: "质检任务数据",
      businessData: "业务记录",
      functionOperator: "函数操作符",
      operation: "运算操作符",
      notAnyMatch: "未命中任何问题时：",
      times: "出现次数",
      newAssociateDataTip: "如果值为数字类型，请使用旧版功能",
    },
    conditionGather: {
      cycle: "周期",
      rlue: "规则",
      delete: "移除",
      sampling: "抽取",
      item: "条",
      percent: "%",
      samplingSame: "每客服抽取数量相同",
      samplingRecheck: "允许重复抽取已抽检条目",
      dateUnit: "号",
      samplingTip:
        "不同规则覆盖相同通话不会重复抽取，已抽取未检查的记录不会被再次抽取。",
      add: "新增规则",
    },
    ruleGather: {
      title: "规则",
      screeningCondition: "筛选条件",
      addRule: "新增规则",
      inspector: "质检员",
      ruleTip:
        "规则优先的分配模式，从上向下依次分配，不重复分配，规则内或不匹配任意规则项的将随机分配",
      moveUp: "上移",
      moveDown: "下移",
      top: "置顶",
    },
    pushRulesGather: {
      title: "规则",
      delete: "移除",
      newPush: "新增推送",
      newRule: "新增规则",
      sampling: "抽取",
      item: "条",
      percent: "%",
      samplingSame: "每客服抽取数量相同",
      dateUnit: "号",
      screeningCondition: "筛选条件",
      email: "邮箱",
      emailPlaceholder: "输入邮箱，用逗号分割",
      updateType: "更新类型",
      pushDetail: "推送详情",
      pushUrl: "推送URL",
      pushWay: "推送类型",
      pushMethod: "推送方式",
      pushContentType: "数据格式",
      pushData: "推送数据",
      additionalData: "附加数据",
      inspectionType: "质检类型",
      all: "全部",
    },
    taskAdditionalData: {
      newData: "新增数据",
      delete: "移除",
      placeholder: "请输入名称",
      dataPlaceholder: "请输入内容",
    },
    UdeskQaReact: { loadSdkError: "SDK加载失败，请稍后重试!" },
    gearOptionList: { evaluation: "评价" },
    tableComponentTransfer: { leftTitle: "列表项", rightTitle: "展示项" },
    kmTinymce: {
      link: "插入/编辑链接",
      modalTitle: "插入链接",
      href: "地址",
      text: "显示文字",
      title: "标题",
      target: "打开方式",
      none: "无",
      newWindow: "在新窗口打开",
      buttonStyle: "按钮样式",
      close: "关闭",
      open: "开启",
      answerMessage: "跳转/发送消息",
      answerMessageTitle: "发送消息按钮",
      answerMessageText: "显示文字",
      answerMessageContent: "消息内容",
      answerMessageButtonStyle: "按钮样式",
      answerMessageTips: "注：发送消息按钮仅支持web渠道，请谨慎配置",
      answerSwitchStaff: "跳转/转人工",
      answerSwitchStaffTitle: "转人工按钮",
      answerSwitchStaffText: "显示文字",
      answerSwitchStaffRule: "按钮规则",
      answerSwitchStaffButtonStyle: "按钮样式",
      answerSwitchStaffTips: "注：转人工按钮仅支持web渠道，请谨慎配置",
      textButton: "文字按钮",
      borderButton: "带框按钮",
      message: "发送消息",
      switchStaff: "转人工",
      messageError: "按钮内容不能超过10个字",
      messageIsEmptyError: "按钮内容不能为空",
      okText: "确定",
      cancelText: "取消",
      video: "视频",
      uploadVideo: "上传视频",
      uploadText: "仅支持MP4格式，大小不超过20M",
      videoEmptyOrUploading: "视频为空或上传中",
      customEmoji: "表情",
    },
    massageConfiguration: {
      pushTarget: "通知目标",
      customizePushTarget: "自定义推送目标",
      sysUser: "当前系统用户",
      sysRole: "当前系统角色",
      emailPushTarget: "发送目标",
      targetEmailsPlaceholder: "请输入邮箱，并使用英文逗号分隔",
      subject: "邮件名称",
      SDKID: "SDK识别ID",
      content: "通知内容",
      sdkPushType: "推送方案",
      sdkPushURL: "推送URL",
      pushData: "推送数据",
      sdkPushWay: "推送方式",
      dataType: "数据格式",
      additionalData: "附加数据",
      name: "消息名称",
      filter: "筛选条件",
      triggerType: "触发机制",
      pushCycle: "定时定频",
      pushContent: "报告内容",
      qualityList: "质检结果列表",
      designated: "指定人员",
      messageType: "消息类型",
    },
    taskCard: {
      template: { cancelCollection: "取消收藏", collection: "收藏" },
    },
    semanticTagsSelect: { index: { standardSentence: "标准句:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "收藏",
          calls: "个通话",
          createdBy: "创建人：",
          createdOn: "创建时间：",
        },
        fileItem: {
          uIProvidesIcon: "ui提供icon",
          minute: "分钟",
          callClip: "通话片段",
          start: "开始",
          End: "结束",
          employees: "员工：",
          Customer: "客户：",
          sessionTime: "会话时间：",
          reasonForRecommendation: "推荐理由：",
          addedBy: "添加者：",
          addTime: "添加时间：",
        },
        modalAddFile: {
          caseTitle: "案例标题",
          addSomeClipsToTheCaseLibrary: "添加部分片段至案例库",
          addToCaseBase: "添加到案例库",
          storedIn: "存储于",
          pleaseSelect: "请选择",
          reasonsForRecommendation: "推荐理由",
          pleaseEnter: "请输入",
        },
      },
      shareModal: {
        index: {
          customer: "客户：",
          employees: "员工：",
          timeliness: "时效性：",
          recordingLinkValidWithinDays: "天内录音链接有效",
          sharingInstructions: "分享说明：",
          shareSuccess: "分享成功！",
          cancel: "取消",
          share: "分享",
          copySucceeded: "复制成功！",
          copyShareLink: "复制分享链接",
          shareWithColleagues: "分享给同事",
          shareWithCustomers: "分享给客户",
          staff: "员工",
          selectColleagues: "选择同事",
          pleaseSelectColleaguesToShare: "请选择要分享的同事",
          pleaseEnterTheSharingDescription: "请输入分享说明",
          taskName: "任务：",
          recordingShareValidWithinDays: "天内分享链接有效",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "无法找到该文件！",
          sale: "销售：",
          customer: "客户：",
          topicDetection: "话题检测：",
          keyEvents: "关键事件：",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "选择同事",
          helpContent: "求助内容",
          visibleRange: "可见范围",
          seekCounseling: "寻求辅导",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "寻求建议", concatText: "向" },
      },
      customerInsight: {
        index: {
          customerInformation: "客户信息",
          customerInsight: "客户洞察",
          customerLabel: "客户标签",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "话术推荐",
          objectionHandlingScripts: "异议处理话术",
          productSellingPointScripts: "产品卖点话术",
          knowledgePointRecommendation: "知识点推荐",
          relatedKnowledgePoints: "关联知识点",
          recommendedCases: "推荐案例",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "话术推荐",
          caseRecommendation: "案例推荐",
          hit: "命中",
          miss: "未命中",
          supervisionItem: "监督项",
          implementation: "执行情况",
          reInspection: "重新质检",
          supervisionItemSettings: "监督项设置",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "电话通话",
          enterpriseWeChatCall: "企微通话",
          enterpriseMicroConversation: "企微会话",
          open: "开启",
          close: "关闭",
          conversationScenario: "会话场景",
          monitorConversationChannels: "监督会话渠道",
          permanentlyValid: "永久有效",
          permanentlyInvalid: "永久无效",
          customValidity: "自定义有效期",
          termOfValidity: "有效期",
          newRating: "新建评级",
          whenConversation: "当会话",
          hit: "命中",
          miss: "未命中",
          time: "时",
          rated: "评级为",
          qualified: "合格",
          unqualified: "不合格",
          modifiedSuccessfully: "修改成功",
          classificationName: "分类名称",
          supervisionObject: "监督对象",
          entryintoforceTime: "生效时间",
          supervisionItemRating: "监督项评级",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "请检查监督项评级是否正确",
          ratingRules: "评级规则",
          settingOfRatingRulesForSupervisionItems: "监督项评级规则设置",
        },
      },
      reInspect: {
        index: {
          keyEvents: "关键事件",
          supervisionItem: "监督项",
          conversationScenario: "会话场景",
          customerLabel: "客户标签",
          reDetectionRules: "重新检测规则",
        },
      },
      audioPlayerBar: {
        index: { downloadRecording: "下载录音", downloadText: "下载文本" },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "上传图片",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "请选择大小不超过2048kb的图片",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "或",
            and: "和",
            wrong: "非",
            matchTo: "匹配到",
            rule: "规则",
            satisfactionOperator: "满足算子",
            customerLabel: "客户标签",
            keyEvents: "关键事件",
            knowledgePoints: "知识点",
            sessionLabel: "会话标签",
            supervisionItem: "监督项",
          },
          linkExplorer: { linkInTheText: "文中链接:" },
        },
      },
    },
    createForm: { field: { customComponentNotFound: "未找到自定义组件：" } },
    sdkLoaderBase: {
      component: { custom: "自定义" },
      dropdownMonit: {
        updateSucceeded: "更新成功",
        areYouSureToDelete: "确定删除吗？",
        custom: "自定义",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm: "保存当前报表配置===表单",
        saveCurrentReportConfiguration: "保存当前报表配置",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "请输入常用设置名称，最大长度为20!",
        preservation: "保存",
      },
      template: {
        saveCurrentReportConfiguration: "保存当前报表配置",
        updateCurrentReportConfiguration: "更新当前报表配置",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "工单信息",
        workOrder: "工单",
        triggerScenario: "触发场景",
        associatedCallConversation: "关联通话/对话",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText: "以当前通/对话文本进行测试",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "第", day: "天" },
      businessSummary: {
        normal: {
          equalTo: "等于",
          notEqualTo: "不等于",
          contain: "包含",
          excluding: "不包含",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "我就是我, 是不一样的烟火@156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi，我是机器人质检预警消息通知，由于10月17日添加到群",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "我就是我, 是不一样的烟火@156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi，我是机器人质检预警消息通知，由于10月17日添加到群",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "沟通主题",
          conversationSummary: "会话小结",
          serviceSuggestions: "服务建议",
        },
        template: { smartTags: "智能标签" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "点击了解更多" },
      schema: {
        textRobot: "文本机器人",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "训练师护航、文档问答、任务式对话、表格问答、图谱问答",
        capitalRetentionRobot: "留资机器人",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "丰富的追问留资策略，高拟人度沟通，保障高留资率、线索高效自动转化",
        outboundRobot: "外呼机器人",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "工单完结回访、服务满意度调研、运维故障警告提示、订单发货提醒、电商活动通知、候选人预约，助力业务自动化、业务效率提升",
        incomingRobot: "呼入机器人",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "灵活集成工单、订单等业务系统，实现流程自动化；语音语义模型定制化识别，业务效果立竿见影；实体（手机号、订单金额、订单时间、地址、……）识别，快速支持复杂业务",
        intelligentQualityInspection: "智能质检",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "全量质检，及时高效，准确率85%",
        intelligentSessionAnalysis: "智能会话分析",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "洞察业务短板，聆听客户心声，提升营销转化",
        enterpriseWechatConversationAnalysis: "企业微信会话分析",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "高效识别潜在商机、风险，营销转化率提升20%",
        assistant: "坐席助手",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "坐席助手能保障新手客服完成服务，提升熟手客服通话质量。让每一个坐席都是您的王牌",
        digitalPeople: "数字人",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          "“面对面”拟人化交流沟通，支持声音、文字、视频、触控多模态交互，实现沉浸式交流体验，提升品牌形象及用户服务体验",
        aIKnowledgeZhongtaiEnterpriseSearch: "AI知识中台·企业搜索",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "知识搜索，知识共享，文档搜索，数据库对接搜索",
        aIKnowledgeCenterKCsKnowledgeBase: "AI知识中台·KCS知识库",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "提供知识的全生命周期管理，机器人和IM知识库统一维护，支持对接第三方知识库，知识模板、原子知识等强大功能带来更加便捷的知识管理体验",
        aIKnowledgeCenterKnowledgeMap: "AI知识中台·知识图谱",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "一站式图谱构建融合企业知识，大图展示知识细节，对接能力打造定制化体验",
        modularDevelopmentPlatformOriginalEngineASR:
          "模块化开发平台·原心引擎·ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "采用端到端算法，实现业务定制化的效果体验，自然场景识别率超过90%，个性化词汇识别超过97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "模块化开发平台·智能营销",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "利用深度学习算法，结合用户画像，实现商品的精准推荐，提高销售转化",
        modularDevelopmentPlatformUserPortrait: "模块化开发平台·用户画像",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "根据用户数据和对话数据精准构建用户画像，充分描述客户特征",
        modularDevelopmentPlatformIntelligentRecommendation:
          "模块化开发平台·智能推荐",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "根据用户画像和产品画像，自动给用户推荐可能感兴趣的产品，实现千人千面的个性化推荐",
        modularDevelopmentPlatformNLPPAAS: "模块化开发平台·NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "提供高准确率的NLP基础能力，涵盖词、句子、文章等不同粒度的底层能力",
      },
    },
    qualityScore: {
      index: {
        show: "展示：",
        displayBySecondaryClassification: "按二级分类展示",
        showByRule: "按规则展示",
        pleaseEnterClassificationruleName: "请输入分类/规则名称",
        clickCopy: "点击复制",
        hitDetailsOfOperatorsInRules: "规则内算子命中详情：",
        hit: "命中",
        miss: "未命中",
        filter: "筛选:",
        clickToViewDetails: "点击查看详细信息",
      },
      components: {
        flowNodeList: {
          branch: "分",
          pleaseSelect: "请选择",
          viewTheCompleteProcess: "查看完整流程",
          thereIsCurrentlyNoProcessAvailable: "暂无流程",
        },
        pointItem: { xiangHit: "项命中" },
        flowNodeListModal: { index: { completeProcess: "完整流程" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "流程质检任务发生变更，请重新申诉。",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "该数据已被定期清除",
        subject: "主题：",
        description: "描述：",
      },
      components: {
        addToTask: { index: { addToQualityInspectionTask: "添加至质检任务" } },
      },
    },
    relatedDetail: { index: { associatedCallConversation: "关联通话/对话" } },
    samplingTarget: {
      index: {
        time: "时",
        samplingQuantityIsLessThan: "抽检数量低于",
        alertWhen: "时进行预警",
        everyday: "每日",
        weekly: "每周",
        monthly: "每月",
        samplingInspectionTargetSavedSuccessfully: "抽检目标保存成功",
        personnel: "人员",
        eachCustomerService: "每位客服",
        cycle: "周期",
        samplingInspectionTarget: "抽检目标",
        strip: "条",
        alertSettings: "预警设置",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "客服",
        complete: "完成",
        Objective: "/ 目标",
        everyday: "每日",
        weekly: "每周",
        monthly: "每月",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "群聊互动率",
        maximumResponseIntervalTime: "应答最长间隔时间",
        numberOfPreemptiveCalls: "抢话次数",
        numberOfMessages: "消息条数",
        strip: "条",
        proportionOfMessages: "消息条数占比",
        speechPercentage: "说话占比",
        longestCustomerTalk: "最长客户发言",
        numberOfIndepthDiscussions: "深入讨论次数",
        second: "次",
        maximumDurationOfContinuousSpeech: "最长持续说话时长",
        questionFrequency: "提问频次",
        customer: "客户",
        keyEvents: "关键事件：",
        opportunity: {
          index: {
            generationFailed: "生成失败",
            generateOpportunityPoints: "生成机会点",
          },
        },
        sessionSummary: {
          index: { generationFailed: "生成失败", generateSummary: "生成小结" },
        },
      },
      index: {
        areYouSureToDeleteIt: "确定删除吗？",
        add: "添加",
        opportunityPoints: "机会点",
        conversationSummary: "会话小结",
        cancel: "取消",
        determine: "确定",
        edit: "编辑",
        establish: "创建",
        conversationalInsights: "会话洞察",
        keyEvents: "关键事件",
        numKeyEvents: "个关键事件",
        questionAnalysis: "提问分析",
        find: "发现",
        questions: "次提问",
        conversationAction: "会话动作",
        customer: "客户",
        sale: "销售",
        sessionLabel: "会话场景",
        employeeQuestions: "员工提问",
        customerQuestions: "客户提问",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "客户",
        customerService: "客服",
        replyTo: "回复至",
        addressee: "收件人:",
      },
    },
    listManage: {
      template: {
        reIdentification: "重新识别",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "重新识别会消耗转写时长，请谨慎操作",
        determine: "确定",
        executionSuccessful: "执行成功",
        addToQualityInspectionTask: "添加至质检任务",
        batchOperation: "批量操作",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "添加成功",
        addToQualityInspectionTask: "添加至质检任务",
        qualityInspectionTask: "质检任务",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "检测角色",
        detectionRange: "检测范围",
        sentence: "句",
      },
    },
    inspectionDetail: {
      template: { theDataHasBeenClearedPeriodically: "该数据已被定期清除" },
    },
    backToSubSystem: { index: { returnToBusinessSystem: "返回业务系统" } },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "问题抽取",
        businessSummary: "业务小结",
        keyWord: "关键词",
      },
      components: {
        problemExtraction: {
          no: "否",
          customerServiceDidNotAnswer: "客服未解答",
          customerServiceHasAnswered: "客服已解答",
          problemUnresolved: "问题未解决",
          theProblemHasBeenResolved: "问题已解决",
          customerIssue: "客户问题：",
          customerServiceAnswer: "客服解答：",
          reExtractQuestions: "重新抽取问题",
          clickToExtractQuestions: "点击抽取问题",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "画像名称",
            backgroundDescription: "背景描述",
            customerLabel: "客户标签",
            labelManagement: "标签管理",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "新建" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "追问设置" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "增加追问",
              remove: "移除",
              addQuestioning: "添加追问",
              section: "第",
              secondInquiry: "次追问",
              questioningScore: "追问得分",
            },
          },
        },
        index: {
          accordingToRules: "按规则",
          byScore: "按得分",
          whenMissRule: "当未命中规则",
          whenConductingQuestioning: "时，进行追问",
          pleaseSelectARule: "请选择规则",
          ruleCannotBeEmpty: "规则不能为空",
          currentScriptScore: "当话术得分≤",
          pleaseEnterTheScore: "请输入得分",
          scoreCannotBeEmpty: "得分不能为空",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "旁白:" },
          pPT: { slide: "幻灯片" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "话术点评",
              script: "话术",
              semantics: "语义",
              violatingWords: "违规词",
              speechSpeed: "语速",
              emotion: "情绪",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "话术点评",
              regenerate: "重新生成",
              generate: "生成",
              hitSituation: "命中情况",
              noSemanticTagsMatched: "未匹配到语义标签",
              matchToSemanticTags: "匹配到语义标签",
              appealToBeSubmitted: "申诉待提交",
              ratingHasBeenReviewed: "评分已复核",
              ratingAppealInProgress: "评分申诉中",
              expressionScore: "表达得分",
              moodParticlesTimes: "语气词, {0}次",
              changeTheModalParticlesTo: "语气词改为",
              second: "次",
              interrogation: "追问",
              questioningWithoutScoring: "追问不计分",
              branch: "分",
              matchingRate: "匹配率",
              ratingAppeal: "评分申诉",
              changeTo: "改为",
              wordPerMinute: "字每分钟",
              completeScript: "话术完整",
              incompleteScript: "话术不完整",
              semanticHit: "语义命中",
              semanticMisses: "语义未命中",
              scriptViolation: "话术违规",
              modified: "已修改",
              notChanged: "未修改",
              keyWord: "关键词",
              semanticLabels: "语义标签",
              semanticMatchingRate: "语义匹配率",
              violatingWords: "违规词“",
              delete: "删除",
              unmatched: "未匹配",
              matching: "匹配",
              matchTo: "匹配到",
              notMatchedTo: "未匹配到",
              scriptScore: "话术得分",
              standardScript: "标准话术",
              emotionalScore: "情感得分",
              speechSpeedScore: "语速得分",
              viewResolution: "查看解析",
              nodeConfiguration: "节点配置",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "本题作答时间超时，自动提交作答内容",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "本题作答时间超时，自动提交作答内容",
          },
          correctBtn: {
            saveSuccessful: "保存成功",
            errorCorrection: "纠错",
            cancel: "取消",
            preserve: "保存",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "得分规则",
            cancel: "取消",
            determine: "确定",
          },
          scoringRuleItem: {
            whole: "全部",
            arbitrarily: "任意",
            pleaseEnterARuleName: "请输入规则名称",
            standardScript: "标准话术",
            if: "如果",
            and: "且",
            or: "或",
            thenScore: "则得分",
            pleaseEnterAScore: "请输入分值",
            bonusPoints: "加分",
            minusPoints: "减分",
            hit: "命中",
            misses: "未命中",
            keyWord: "关键词",
            semantics: "语义",
          },
          title: {
            ruleValidation: "规则校验",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "得分规则（当前话术总得分为",
            points: "分 ）",
            editRules: "编辑规则",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "暂无标签" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "沟通目标",
              scoringCriteria: "评分标准",
            },
          },
        },
        index: {
          generating: "生成中",
          generateDialogueFramework: "生成对话框架",
          theme: "主题",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "提示语",
            generatePromptLanguage: "生成提示语",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "沟通阶段",
          communicationObjectives: "沟通目标",
          scoringCriteria: "评分标准",
          suggestionsForImprovement: "提升建议",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "流程得分",
            contentIntegrity: "内容完整性：",
            NumberOfCompletedProcesses: "%，完成流程数",
            keyHitPointsTotal: "要点命中：共",
            notReached: "个未达到",
            accurateFollowupAccumulationOfStandardScripts:
              "跟读准确：标准话术累积",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "个字，学员跟读命中的字数共",
            aWord: "个字",
            languageNormsYes: "语言规范：有",
            emotionsAppearing: "情绪：出现",
            speechSpeedSpeakingTooFast: "语速：语速过快",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "语气词：语气词过多出现",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "添加至忽略标签",
        areYouSureYouWantToReExecuteTheSmartLabel: "确定要重新执行智能标签吗？",
        reExecute: "重新执行",
      },
      component: { successfullyAdded: "添加成功" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "关闭所有通知",
        reQualityInspectionTask: "重新质检任务",
        requalityInspection: "重新质检",
        waiting: "等待中",
        viewDetails: "查看详情",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "检测时间：" } },
        },
        template: { responseOpeningSentence: "应答起始句" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "生成失败", generate: "生成" },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "暂无数据" } },
      },
      index: { regenerate: "重新生成" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "确定删除吗？",
        operationSuccessful: "操作成功",
      },
      template: { delete: "删除" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "请选择问题！",
        similarQuestionRecommendation: "相似问推荐",
        selectAllOnPage: "当页全选",
        cancel: "取消",
        preserve: "保存",
        changeBatch: "换一批",
      },
    },
    listControl: { index: { columnControl: "列控制" } },
    loginUserAvatar: { index: { switch: "切换" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "实时添加数据时，质检任务执行仍需耗时。具体时间取决于质检数据量大小，在几分钟到2小时不等。",
      },
    },
    bEditor: {
      index: {
        delete: "删除",
        cancel: "取消",
        confirm: "确定",
        insert: "插入",
        width: "宽度",
        height: "高度",
        clearContent: "清除内容",
        revoked: "撤销",
        redo: "重做",
        fontSize: "字号",
        rowHeight: "行高",
        wordSpacing: "字间距",
        paragraphIndent: "段落缩进",
        increaseIndent: "增加缩进",
        decreaseIndent: "减少缩进",
        border: "边框",
        color: "颜色",
        textColor: "文字颜色",
        backgroundColor: "背景颜色",
        temporaryColor: "临时颜色",
        bold: "加粗",
        italic: "斜体",
        underline: "下划线",
        strikethrough: "删除线",
        font: "字体",
        textAlignment: "文本对齐",
        left: "居左",
        centered: "居中",
        right: "居右",
        bothEnds: "两端",
        leftFloat: "左浮动",
        rightFloat: "右浮动",
        subrupt: "上标",
        subscript: "下标",
        clearStyle: "清除样式",
        title: "标题",
        regular: "常规",
        orderedList: "有序列表",
        unorderedList: "无序列表",
        reference: "引用",
        code: "代码",
        link: "链接",
        clearLink: "清除链接",
        horizontalLine: "水平线",
        media: "媒体",
        mediaLibrary: "媒体库",
        smallExpression: "小表情",
        fullScreen: "全屏",
        exitFullScreen: "退出全屏",
        enterLinkText: "输入链接文字",
        enterLinkAddress: "输入链接地址",
        enterTheLinkAddressAndPressEnter: "输入链接地址并回车",
        openInNewWindow: "在新窗口打开",
        removeLink: "移除链接",
        playingAudioFiles: "播放音频文件",
        playVideoFiles: "播放视频文件",
        embeddedMedia: "嵌入式媒体",
        image: "图像",
        video: "视频",
        audio: "音频",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "名称",
        description: "描述",
        type: "类型",
        status: "状态",
        actions: "操作",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "任务名称",
        description: "描述",
        form: "质检表单",
        actions: "操作",
        createUser: "创建人",
      },
      samplingRecords: {
        createTime: "时间",
        type: "类型",
        operateUser: "操作人",
        number: "添加数量",
        filterConditionList: "筛选条件",
        sampleRuleStr: "抽样条件",
        timeGreaterThan: "时间大于",
        timeLessThan: "时间小于",
      },
    },
    customFilters: {
      createdAt: "通话时间",
      customerId: "客户",
      agentId: "客服",
      userGroupId: "客服组",
      state: "状态",
      lastEvaluateUserId: "最后评分人",
      submitTime: "最后提交时间",
      totalScore: "总分",
      formItemForbidIdList: "禁止项",
      formItemDeadlyIdList: "致命项",
    },
    samplingInspection: {
      callTime: "通话时间",
      dialogTime: "对话时间",
      originator: "分配人",
      createTime: "创建时间",
      updateTime: "更新时间",
      status: "流程状态",
      score: "分数",
      actions: "操作",
      reviewer: "复核员",
      samplingOfficer: "抽检员",
      id: "通话记录ID",
      time: "通话时间",
      phone: "客户电话",
      staff: "客服",
      inspectionStatus: "质检状态",
      samplingStatus: "抽检状态",
      inspectionGrade: "评级",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "标准词",
        synonymWords: "同义词",
        synonymWordsNumber: "同义词数",
        useFrequency: "使用频率",
        effectiveness: "有效性",
        operation: "操作",
      },
      common: {
        useFrequency: "使用频率",
        effectiveness: "有效性",
        operation: "操作",
      },
      professionalWords: {
        professionalWords: "专业名词",
        useFrequency: "使用统计",
        homophonic: "热词（同音替换）",
        target: "名词替换",
        targetWords: "替换词",
        homophonyWords: "同音屏蔽词",
        operation: "操作",
      },
      attentionWords: "关注词",
      ignoreWords: "忽略词",
      wordsWeight: {
        selfDefineWeightWords: "自定义权重词",
        weightWords: "权重词",
        weight: "权重",
        useFrequency: "使用频率",
        effectiveness: "有效性",
        operation: "操作",
      },
      corpusData: {
        corpusDescription: "语料描述",
        updateTime: "更新时间",
        wordsCount: "词数",
        effectiveness: "有效性",
        operation: "操作",
        corpusContent: "内容",
      },
    },
    semanticTags: {
      title: "语义标签",
      tags: "标准句",
      orgName: "所属组织",
      tagCount: "训练数",
      usingCount: "引用数",
      activeFlag: "有效性",
      suggestion: "训练建议",
      actions: "操作",
    },
    intelligentTags: {
      tagName: "名称",
      tagDesc: "说明",
      tagRefCnt: "标记数",
      categoryName: "标签分类",
      eventName: "事件分类",
      tagStatus: "有效性",
      actions: "操作",
      tagType: "标签类型",
    },
    dataPreprocess: {
      name: "名称",
      desc: "说明",
      dataSource: "数据源限制",
      type: "类型",
      status: "有效性",
      actions: "操作",
    },
    informationEntities: {
      name: "名称",
      apiName: "API名称",
      type: "类型",
      remark: "描述",
      activeFlag: "有效性",
      actions: "操作",
    },
    interactiveRecognition: {
      questionAndAnswer: "标准问题&答案",
      type: "类型",
      status: "状态",
      actions: "操作",
      question: "问题",
    },
    role: { id: "ID", name: "名称", actions: "操作" },
    uploadDownload: {
      taskName: "文件名称",
      createTime: "日期",
      size: "大小",
      createUserName: "操作员",
      status: "状态",
      result: "结果",
      actions: "操作",
    },
    taskCenterList: {
      name: "任务内容",
      createTime: "提交时间",
      startTime: "开始时间",
      endTime: "结束时间",
      createUserName: "操作员",
      status: "状态",
      result: "结果",
      actions: "操作",
    },
    customerInformation: {
      title: "客服信息",
      data: "仅查看异常数据",
      list: {
        agentNo: "工号/客服ID",
        agentName: "客服",
        agentTel: "电话",
        agentEmail: "邮箱",
        updateTime: "更新时间",
        actions: "操作",
      },
      upload: { second: "2.点击导入按钮,上传符合模板格式的EXCEl文件" },
    },
    IndexManagement: {
      title: "指标管理",
      list: {
        indicatorName: "名称",
        indicatorDesc: "描述",
        activity: "有效性",
        inspectDataSource: "数据源",
        sysType: "类型",
        agentGroups: "客服组",
        actions: "操作",
      },
      detail: {
        nameEmpty: "名称不能为空",
        descript: "描述",
        descriptEmpty: "描述不能为空",
        active: "有效性",
        activeEmpty: "有效性不能为空",
        dataSource: "数据源",
        dataSourceEmpty: "数据源不能为空",
        grounp: "客服组",
        grounpEmpty: "客服组不能为空",
        grounpHolder: "请选择客服组",
        type: "评分方式",
        typeNone: "评分方式不能为空",
        base: "基础分",
        baseNone: "基础分不能为空",
        positive: "正面因素",
        positiveNone: "正面因素不能为空",
        customAdd: "添加筛选",
        negative: "负面因素",
        negativeNone: "负面因素不能为空",
        customOK: "筛选条件：",
        judgeStrategy: "条件规则：",
        other: "其他因素",
        otherAdd: "添加因素",
        agent: "原始数据",
        section: "区间数量",
        sectionNone: "区间数量不能为空",
        step: "区间步长",
        stepNone: "区间步长",
        save: "保存",
        cancel: "取消",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "全部",
      staff: "仅本人",
      department: "本部门及以下",
    },
    gongStatisticsDate: { week: "周", month: "月", season: "季" },
    gongCoachTaskStatus: {
      wait: "待辅导",
      already: "已辅导",
      ignore: "已忽略",
    },
    callRangeStatus: {
      all: "全部通话",
      sale: "员工发起",
      customer: "客户发起",
    },
    todoListType: {
      later: "稍后听",
      help: "辅导求助",
      contact: "稍后联系",
      send: "发送资料",
      warn: "风险预警",
    },
    frequencyTypes: { everyDay: "每日", everyWeek: "每周", everyMonth: "每月" },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "未分类数据训练",
        markedDataCorrigenda: "已标注数据勘误",
        classifiedDataCheck: "已分类数据校验",
        markedHistoryRecord: "标注历史记录",
        historyDataRecalculate: "历史数据重算",
      },
      dataExtractMode: {
        random: "完全随机",
        intelligentRecommend: "智能推荐",
        custom: "自定义",
        specifiedClass: "指定分类",
      },
      smartRatesList: {
        markRate: "标注率",
        corrigendaRate: "勘误率",
        kl: "K-L散度",
        signalNoiseRate: "信噪比",
      },
    },
    visibleRangeTypes: {
      all: "全部",
      customerGroup: "客服组",
      userDefined: "自定义",
    },
    samplingRateTypes: { everyDay: "每天抽取", everyWeek: "每周抽取" },
    taskRemindTypes: {
      confirm: "质检任务记录需要确认时，发送提醒",
      appeal: "质检任务记录发起申诉时，发送提醒",
    },
    weekDays: {
      monday: "星期一",
      tuesday: "星期二",
      wednesday: "星期三",
      thursday: "星期四",
      friday: "星期五",
      saturday: "星期六",
      sunday: "星期日",
    },
    reportDateTypes: { dailyReport: "日报表", weeklyReport: "周报表" },
    samplingRangeDayTypes: { today: "今天", yesterday: "昨天" },
    samplingRangeWeekTypes: { thisWeek: "本周", lastWeek: "上周" },
    samplingRuleTypes: { proportion: "比例抽样", random: "随机抽样" },
    taskStatusTypes: {
      waitEvaluate: "待评价",
      waitSubmit: "待提交",
      waitConfirm: "待确认",
      waitReview: "待复核",
      completed: "已完成",
      all: "全部",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "直接评分",
        weightedScore: "权重评分",
        deductScore: "扣分",
        directScoreSecondLevel: "直接评分-二级",
        weightedScoreSecondLevel: "权重评分-二级",
        deductScoreSecondLevel: "扣分-二级",
      },
    },
    fieldOperators: {
      is: "等于",
      not: "不等于",
      contains: "包含",
      notContains: "不包含",
      not_contains: "不包含",
      containsAny: "包含任意",
      contains_any: "包含任意",
      is_any: "包含任意",
      not_any: "不包含任意",
      notContainsAny: "不包含任意",
      not_contains_any: "不包含任意",
      containsAll: "包含所有",
      contains_all: "包含所有",
      notContainsAll: "不包含所有",
      nont_contains_all: "不包含所有",
      isNull: "为空",
      is_null: "为空",
      isNotNull: "不为空",
      is_not_null: "不为空",
      greaterThan: "大于",
      greater_than: "大于",
      greaterThanEq: "大于等于",
      greater_than_eq: "大于等于",
      lessThan: "小于",
      less_than: "小于",
      lessThanEq: "小于等于",
      less_than_eq: "小于等于",
      between: "介于",
      notBetween: "不介于",
      not_between: "不介于",
      relative: "等于",
      set: "设置为",
      belongs: "属于",
      notBelongs: "不属于",
      not_belongs: "不等于",
      today: "今天",
      yesterday: "昨天",
      the_day_before_yesterday: "前天",
      tomorrow: "明天",
      next_seven_day: "今后七天",
      last_seven_day: "最近七天",
      this_week: "本周",
      next_week: "下周",
      last_week: "上周",
      this_month: "本月",
      next_month: "下月",
      last_month: "上月",
      this_year: "今年",
      next_year: "明年",
      last_year: "去年",
      exact_match: "精确匹配",
      add_value: "新增值",
      remove_value: "删除值",
      set_null: "置空",
      isAny: "任意等于",
      notAny: "任意不等于",
      belongTo: "属于",
      notBelongTo: "不属于",
      hasAny: "包含任意",
      notHasAny: "不包含任意",
      prefixContains: "开头等于",
      prefixNotContains: "开头不等于",
      suffixContains: "结尾等于",
      suffixNotContains: "结尾不等于",
      nextSevenDay: "今后7天",
      lastSevenDay: "最近7天",
      thisWeek: "本周",
      nextWeek: "下周",
      lastWeek: "上周",
      thisMonth: "本月",
      nextMonth: "下月",
      lastMonth: "上月",
      thisYear: "今年",
      nextYear: "明年",
      lastYear: "去年",
      after: "晚于（包含当天）",
      before: "早于（包含当天）",
      allMatching: "全部匹配",
      allNotMatching: "全部不匹配",
      hit: "命中",
      not_hit: "未命中",
    },
    fieldsOperators: {
      is: "等于",
      is_null: "同时为空",
      head_equal: "开头等于",
      tail_equal: "结尾等于",
      is_not_null: "同时不为空",
      not: "不等于",
      diff_equal: "相差等于",
      diff_greater_than: "相差大于",
      diff_greater_than_eq: "相差大于等于",
      diff_less_than: "相差小于",
      diff_less_than_eq: "相差小于等于",
      hour_equal: "同小时",
      day_equal: "同天",
      week_equal: "同周",
      month_equal: "同月",
      year_equal: "同年",
      day: "天",
      minute: "分钟",
      hour: "小时",
      part_equal: "部分等于",
    },
    samplingColumnCaptions: {
      created_at: "通话时间",
      agent_id: "客服",
      get_direction: "通话类型",
      call_result: "通话结果",
      survey: "评价",
      derived_from_id: "来源",
      quit_queue_why: "排队状态",
      queuing_duration: "排队耗时",
      talking_seconds: "通话时长",
      ringing_seconds: "响铃时间",
      hangup_by: "通话挂断方",
    },
    callTypes: {
      callIn: "呼入",
      callOut: "呼出",
      threeParty: "（三方）",
      consultation: "（咨询）",
      insert: "（强插）",
      monitor: "（监听）",
      transfer: "（转接）",
      intercept: "（拦截）",
      transferOutside: "（转接外线）",
      threePartyOutside: "（三方外线）",
      consultingOutside: "（咨询外线）",
    },
    callResults: {
      staffAnswer: "客服接听",
      staffNotAnswer: "客服未接",
      customerAnswer: "客户接听",
      customerNotAnswer: "客户未接",
      phoneBusy: "话机占线",
      phoneOffline: "话机离线",
      customerSpeedHangUp: "客户速挂",
      customerHangUp: "客户挂机",
      queueTimeout: "排队超时",
      queueGiveUp: "放弃排队",
      outlineAnswer: "外线接听",
      outlineNotAnswer: "外线未接",
      noChooseQueue: "未选择队列",
    },
    defaultSurveys: { noEvaluation: "未评价", noNeedToEvaluate: "无需评价" },
    queueStates: {
      queueSuccess: "排队成功",
      queueTimeout: "排队超时",
      queueGiveUp: "放弃排队",
      noStaffOnline: "无客服在线",
    },
    callHangers: { customer: "客户", staff: "客服", outline: "外线" },
    workFlowTypes: { noAppealsMode: "无申诉模式", appealsMode: "允许申诉模式" },
    fieldCategories: {
      standardCondition: "标准筛选条件",
      customerCondition: "用户自定义筛选条件",
    },
    sampleTypes: { automatic: "自动抽样", manual: "手动抽样" },
    defaultFields: {
      createdAt: "通话时间",
      agentId: "客服",
      getDirection: "通话类型",
      callResult: "通话结果",
      survey: "评价",
      derivedFrom: "来源",
      quitQueueWhy: "排队状态",
      queuingDuration: "排队耗时",
      talkingSeconds: "通话时长",
      ringingSeconds: "响铃时间",
      hangupBy: "通话挂断方",
    },
    conditionFieldCategories: {
      fixedField: "固定字段",
      customField: "自定义字段",
      secondLevelScore: "二级分类",
      formItemScore: "分值项",
      forbids: "禁止项",
      deadlies: "致命项",
    },
    columnFieldCategories: {
      agent: "客服",
      callLog: "通话记录",
      businessFields: "业务信息",
      customer: "客户",
      customerCustomFields: "客户自定义",
      source: "来源",
      queue: "队列",
      fixed: "固定",
      secondLevelScore: "二级分类",
      formItemScore: "分值项",
      forbids: "禁止项",
      deadlies: "致命项",
    },
    taskListDefaultFields: {
      startTime: "通话时间",
      name: "客服",
      state: "状态",
      totalScore: "总分",
      formItemForbidList: "禁止项",
      formItemDeadlyList: "致命项",
      comment: "评价备注",
      inspector: "评分人",
      review: "复核员",
    },
    evaluationStates: {
      initial: "初始化",
      waitEvaluate: "待评价",
      evaluated: "待提交",
      waitConfirm: "待确认",
      waitCheck: "待复核",
      complete: "已完成",
    },
    workBenchInfoTypes: {
      callInfo: "通话信息",
      businessInfo: "业务信息",
      customerInfo: "客户信息",
    },
    evaluationEventStates: {
      create: "创建",
      valuate: "评价",
      edit: "编辑",
      submit: "提交",
      confirm: "确认",
      appeal: "申诉",
      checkConfirm: "复核确认",
      check: "复核",
    },
    formItemTypes: {
      direct: "直接评分",
      weight: "权重",
      deduct: "扣分",
      forbid: "禁止项",
      deadly: "致命项",
    },
    appealCheckCallTypes: {
      callIn: "呼入",
      callBack: "双向回拨",
      callOut: "外呼直拨",
      autoCall: "自动外呼",
    },
    appealCheckCallStatus: {
      waitReview: "待复核",
      reviewed: "已复核",
      filed: "已归档",
    },
    samplingStatus: { all: "全部", unchecked: "抽取未检", checked: "已抽检" },
    inspectionStatus: {
      all: "全部",
      unread: "未读",
      readed: "已读",
      appeal: "申诉",
      review: "复核",
      filed: "已归档",
    },
    gradeChooseHits: { yes: "是", no: "否" },
    appealCheckStatus: {
      all: "全部状态",
      unread: "未读",
      inComplaint: "申诉中",
      reviewed: "已复核",
      readed: "已读",
      filed: "已归档",
      spotchecked: "已抽检",
    },
    flowStatus: {
      unread: "未读",
      alreadyRead: "已读",
      complaining: "申诉中",
      reviewed: "已复核",
      sampling: "抽取未检",
      samplingCompleted: "已抽检",
      complainCancel: "已撤销",
    },
    complainStatus: {
      success: "成功",
      fail: "失败",
      default: "申诉中",
      canceled: "已撤销",
    },
    reviewInspectionStatus: { inComplaint: "申诉中", reviewed: "已复核" },
    gradeChangeTypes: {
      edit: "编辑",
      check: "复核",
      file: "归档",
      remark: "备注",
      complain: "申诉",
      review: "复核",
      reviewEvaluation: "复核评价",
      sample: "抽检",
      sampleEvaluation: "抽检评价",
      sampleSubmit: "抽检提交",
      sampleAssign: "抽检分配",
      reviewAssign: "复核分配",
      read: "已读",
      reviewConfirm: "复核确认",
      sampleConfirm: "抽检确认",
      caseBase: "添加至案例组",
      sampleCancel: "抽检取消分配",
      reviewCancel: "复核取消分配",
      sampleAgain: "抽检重新分配",
      reviewAgain: "复核重新分配",
      btachDelete: "删除抽检记录",
      rapidSampling: "快速抽检",
      reInspection: "重新质检",
      smartTagsChange: "智能标签变更",
      cancelAppeal: "撤销申诉",
    },
    appealTypes: {
      transliterationError: "转写错误",
      discriminationError: "判别错误",
      otherError: "其他错误",
    },
    inspectDataSources: {
      voiceCall: "语音通话",
      textDialogue: "文本对话",
      realTimeVoiceCall: "实时语音通话",
      realTimeTextDialogue: "实时文本对话",
      wechatDialogue: "企微会话",
      taobao: "电商文本对话",
      ticket: "工单",
      wechatRadio: "企微语音",
    },
    inspectTaskType: { all: "全部", common: "常规质检", relate: "关联质检" },
    inspectApproveType: {
      all: "全部",
      resolve: "审核通过",
      resolving: "审核中",
      reject: "审核未通过",
    },
    dataSourceFlags: { all: "不限制", voiceCall: "通话", textDialogue: "对话" },
    smartWordsLibrary: { effective: "有效", invalid: "无效" },
    activeFlags: { effiective: "有效", invalid: "无效" },
    labelTypes: { dimensionLabel: "标注标签", classificationLabel: "分类标签" },
    pointTypes: {
      automatic: "自动评价",
      manual: "手动评价",
      interactiveDetection: "交互检测",
      smartRules: "智能规则",
      machineLearning: "自学习模式",
      intelligentModel: "智能模型",
    },
    pointGradeTypes: {
      radio: "单选",
      input: "输入",
      select: "选择",
      all: "满足全部",
      any: "满足任意",
      customize: "自定义",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "关键词匹配",
      question: "疑问句",
      regular: "正则表达式",
      context: "上下文重复",
      semantics: "语义匹配",
      word: "字数相似句",
      dialogue: "对话时间间隔",
      speed: "语速检测",
      grabDetection: "抢话检测",
      semanticTags: "语义标签",
    },
    applyRoles: {
      all: "全部",
      customer: "客户",
      agent: "客服",
      robot: "机器人",
    },
    saleApplyRoles: { all: "全部", customer: "客户", agent: "员工" },
    applyOperatorScopes: {
      all: "全文",
      preCondition: "前置条件",
      scope: "指定范围",
    },
    keywordScopes: { all: "检测全部关键词", any: "检测任意关键词" },
    preOperatorHitTypes: {
      first: "首次匹配",
      every: "每次匹配",
      last: "最后一次匹配",
      any: "任意一次匹配",
      none: "未匹配",
    },
    operatorRuleTypes: {
      all: "满足所有",
      any: "满足任意",
      custom: "自定义逻辑",
    },
    informationEntityTypes: {
      system: "系统",
      custom: "自定义",
      moduleCompany: "模组",
    },
    organizationTypes: { null: "无", moduleCompany: "模组" },
    customType: {
      smartTag: "抽取实体",
      scriptTimes: "话术计次",
      dataCategory: "数据分类",
    },
    interactiveQuestionTypes: {
      standard: "标准问答",
      multiElement: "多要素问答",
      guideMultiRound: "引导式多轮交互",
    },
    targetTypes: { all: "全部", customerService: "客服", customer: "客户" },
    interactiveTypes: {
      similarQList: "相似问题",
      exceptSimilarQList: "排除相似问题",
      similarAList: "相似答案",
      wrongAList: "错误答案",
    },
    filterTypes: {
      call: "通话录音",
      dialogue: "对话文本",
      wechat: "企业微信",
      wechatAll: "企业微信全部对话",
      ticket: "工单会话",
      taobao: "电商会话",
      wechatRadio: "企微语音",
    },
    fieldTypes: {
      keyword: "关键词",
      agent: "客服",
      agentGroup: "客服组",
      sigleListbox: "单选",
      multiListbox: "多选",
      text: "单行文本",
      textArea: "多行文本",
      dateTime: "时间",
      number: "数字",
      voice: "",
      customer: "客户",
      fieldWithLink: "带链接字段",
      percentage: "百分比",
      intelligentClassification: "智能分类",
      testSetList: "测试集动态字段",
      inspector: "质检员",
    },
    saveTypes: { save: "保存" },
    knowledgeType: { oneToOne: "一问一答" },
    updateTypes: { update: "更新", saveAs: "另存为" },
    recommendItemActionTypes: {
      similar: "相似",
      exclude: "排除",
      ignore: "忽略",
    },
    gradeStatus: {
      unread: "未读",
      alreadyReview: "已复核",
      alreadyFiled: "已归档",
      waitReview: "待复核",
      alreadyRead: "已读",
    },
    qualityCheckDetailTypes: { review: "复核", spotCheck: "抽检" },
    sampleAndReviewStatus: {
      init: "未抽取",
      unread: "未读",
      alreadyRead: "已读",
      complaining: "申诉中",
      reviewed: "已复核",
      sampling: "抽取未检",
      samplingCompleted: "已抽检",
    },
    interactiveAnswerTypes: {
      noAnswer: "未检测到答案",
      answer: "检测到答案 ",
      wrongAnswer: "检测到错误答案",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "未检测到问题",
      question: "检测到问题",
      eliminateQuestion: "检测到排除问题",
    },
    isManual: { yes: "已人工质检", no: "未人工质检" },
    readStatus: { yes: "已读", no: "未读" },
    reInspectionType: {
      recalcScore: "仅重新核算分数（含评级）",
      recheckPoint: "重新核算质检点与分数",
    },
    common: {
      eCommerceReview: "电商评论",
      socialMediaComments: "社媒评论",
      customer: "客户",
      customerService: "客服",
      robot: "机器人",
      customerInformation: "客户信息",
      workOrderFields: "工单字段",
      intelligentConversationAnalysis: "智能会话分析",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "洞察业务短板，聆听客户心声，提升营销转化",
      intelligentAccompanyingPractice: "智能陪练",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "打造模拟业务真实场景的沉浸式AI陪练，实现金牌话术全员掌握，对话技巧快速提升",
      intelligentQualityInspection: "智能质检",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "全量质检，及时高效，准确率85%以上",
      salesEmpowerment: "销售赋能",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI重塑销售管理，实现沟通过程数字化、可视化，透明管理销售每一环，智慧洞察沟通每一步，精细化诊断业务问题，完美复刻销冠经验",
      algorithmPlatform: "算法平台",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "基于自研原心引擎语音语义，ASR、NLP模型定制，打通业务场景数据，实现系统自学习，达到越用越智能，越用越准确的目的",
      generalSettings: "通用设置",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "对数据权限、员工角色进行统一设置管理",
      callLog: "通话记录",
      intelligentRules: "智能规则",
      businessRecords: "业务记录",
      customFilter: {
        call: "语音通话",
        text: "文本对话",
        smartTags: "智能标签",
        pretreatment: "预处理",
        smartTagGroup: "智能标签组",
        currency: "通用数据",
        wechat: "企业微信",
        spotCheck: "抽检",
        personaField: "客户列表",
        ticket: "工单会话",
        taobao: "电商会话",
        wechatRadio: "企微语音",
        reviewList: "复核",
      },
      talkerRecognitionType: { API: "API标识轨道角色", text: "文本识别" },
      operatorTypes: {
        keyword: "关键词匹配",
        question: "疑问句",
        regular: "正则表达式",
        context: "上下文重复",
        semantics: "语义匹配",
        word: "字数相似句",
        dialogue: "对话时间间隔",
        speed: "语速检测",
        grabDetection: "抢话检测",
        semanticTags: "语义标签",
        keywordStrict: "关键词匹配(严格)",
        link: "链接字段",
        entityInfoDetection: "信息实体检测",
        basicInfoDetection: "基础信息检测",
        volume: "音量检测",
        emotion: "情绪分析",
        grammar: "文法规则",
        sentenceLength: "句长检测",
        conceptWord: "概念词",
        questionsAndAnswers: "知识库解答（旧）",
        element: "要素问答",
        silent: "响应超时",
        associate: "随路数据",
        repeat: "重复发送",
        timeInterval: "间隔时长",
        typoDetection: "错别字检测",
        silentOld: "静默",
        knowledge: "知识解答",
        wecomRemark: "客户企微备注",
        customerAddTime: "客户添加时间",
      },
      keywordOperators: {
        all: "全部匹配",
        allNot: "全部不匹配",
        any: "任意匹配",
      },
      applyRoles: { all: "全部", customer: "客户", agent: "客服" },
      sentenceLength: {
        less: "小于",
        greater: "大于",
        equal: "等于",
        greater_or_equal: "大于等于",
        less_or_equal: "小于等于",
      },
      dialogueApplyScopes: {
        differentRoles: "不同角色之间",
        agent: "客服",
        customer: "客户",
        agentResponse: "客服应答",
        customerResponse: "客户应答",
        customerNoResponse: "客户无响应",
      },
      applyConditionHitTypes: {
        first: "第一次命中",
        every: "每一次命中",
        last: "最后一次命中",
      },
      applyConditionScopes: {
        current: "当前",
        before: "前",
        after: "后",
        beforeAll: "之前全部",
        afterAll: "之后全部",
        around: "附近",
      },
      voiceDemoTypes: {
        singleChannel: "单通道通话录音",
        dualChannel: "双通道通话录音",
      },
      sendStatus: {
        sending: "发送中",
        arrive: "已送达",
        fail: "发送失败",
        off_sending: "离线发送未读",
        off_arrive: "已读",
        rollback: "已撤回",
      },
      collectionMethod: { assignment: "直接赋值", entity: "实体抽取" },
      systemTypes: { qa: "智能质检", wfm: "智能排班" },
      entityOperators: {
        equals: "等于信息",
        notEquals: "不等于信息",
        contains: "包含信息",
        notContains: "不包含信息",
        equalsFix: "等于固定值",
        notEqualsFix: "不等于固定值",
        containsFix: "包含固定值",
        notContainsFix: "不包含固定值",
      },
      basicInfoOperators: {
        equals: "等于",
        notEquals: "不等于",
        contains: "包含",
        notContains: "不包含",
        isNull: "为空",
        isNotNull: "不为空",
      },
      volumeDetectionWays: {
        loudness: "响度分贝",
        rangeAbilitySelf: "变化幅度(比本人上句)",
        rangeAbilityOth: "变化幅度(比对方上句)",
        rangeAbility3: "变化幅度(比本人本句)",
      },
      volumeDetectionOperators: {
        is: "等于",
        not: "不等于",
        greaterThan: "大于",
        greaterThanEq: "大于等于",
        lessThan: "小于",
        lessThanEq: "小于等于",
      },
      sentimentTypes: {
        positive: "正面情绪",
        negative: "负面情绪",
        neutral: "中性情绪",
        thankful: "感谢",
        happy: "高兴",
        complaining: "抱怨",
        angry: "生气",
        post: "正面",
        negat: "负面",
        neut: "中性",
      },
      emotionOperators: { is: "符合", not: "不符合" },
      propTypes: { system: "系统", user: "自定义" },
      valueTypes: { text: "文本", number: "数字" },
    },
    tasks: {
      allowCustomGrievance: "允许自定义申诉理由",
      businessSummary: "业务小结",
      theDayBeforeYesterday: "前天",
      assessActionTypes: { reassess: "重新评估" },
      scoreType: {
        aiScore: "智能评价",
        manualScore: "手动评价",
        interactiveDetection: "交互检测",
        smartRules: "智能规则",
        machineLearning: "自学习模式",
      },
      inspectType: { inspectTrue: "检查到", inspectFalse: "未检查到" },
      operatorType: { keyword: "关键词匹配", notKeyword: "关键词不匹配" },
      applyRole: { all: "全部", customer: "客户", agent: "客服" },
      applyScope: { all: "全部", specific: "指定句", scope: "指定范围" },
      judgeStrategy: {
        all: "满足所有条件",
        arbitrarily: "满足任意条件",
        custom: "自定义逻辑",
      },
      checkPointStatus: {
        waitFor: "等待复核",
        notDeductScore: "不扣分",
        deductScore: "扣分",
      },
      applyOptions: { hit: "条件命中", notHit: "无指定条件" },
      gradeTypes: {
        yesOrNo: "是否符合",
        input: "手动输入",
        level: "分档评价",
        multipleFactors: "多要素",
      },
      gradeChooseHits: { yes: "是", no: "否" },
      gradeChangeTypes: {
        edit: "编辑",
        check: "复核",
        file: "归档",
        remark: "备注",
        complain: "申诉",
        review: "复核",
        reviewEvaluation: "复核评价",
        sample: "抽检",
        sampleEvaluation: "抽检评价",
        sampleSubmit: "抽检提交",
        sampleAssign: "抽检分配",
        reviewAssign: "复核分配",
        read: "已读",
        reviewConfirm: "复核确认",
        sampleConfirm: "抽检确认",
      },
      gradeStatus: {
        unread: "未读",
        alreadyReview: "已复核",
        alreadyFild: "已归档",
        waitReview: "待复核",
        alreadyRead: "已读",
      },
      samplingFrequencyTypes: {
        everyDay: "每日",
        everyWeek: "每周",
        everyMonth: "每月",
      },
      samplingEveryDayTimeTypes: { yesterday: "昨天", today: "今天" },
      samplingEveryWeekTimeTypes: { thisWeek: "本周", lastWeek: "上周" },
      samplingEveryMonthTimeTypes: { thisMonth: "本月", lastMonth: "上月" },
      samplingTypes: { random: "随机抽样", proportional: "比例抽样" },
      weekdays: {
        Monday: "周一",
        Tuesday: "周二",
        Wednesday: "周三",
        Thursday: "周四",
        Friday: "周五",
        Saturday: "周六",
        Sunday: "周日",
      },
      distributeTypes: { rule: "规则优先分配", average: "平均随机分配" },
      notMatchAnyRuleTypes: {
        random: "随机平均分配",
        assign: "分配给指定人员",
        ignore: "不分配",
      },
      inspectorTypes: { all: "全部质检员", some: "指定质检员" },
      pushFrequencyTypes: {
        everyDay: "每日",
        everyWeek: "每周",
        everyMonth: "每月",
        inspectionUpdate: "质检更新",
        timelyInspection: "实时质检",
      },
      pushObjectTypes: { email: "指定邮箱", staff: "所属客服" },
      reviewOptions: {
        reviewResult: "复核时显示质检结果",
        reviewRecord: "复核时显示相关记录",
        reviewAgentHidden: "复核时隐藏客服信息",
        samplingResult: "抽检时显示质检结果",
        samplingRecord: "抽检时显示相关记录",
        sampleAgentHidden: "抽检时隐藏客服信息",
        checkExportAddCallInfoIphone: "导出列表时附加通话记录",
        sampleExportAddCallInfoIphone: "导出列表时附加通话记录",
        checkExportAddCallInfoText: "导出列表时附加对话记录",
        sampleExportAddCallInfoText: "导出列表时附加对话记录",
        inspectionExportAddCallInfoIphone:
          "导出列表时附加通话记录(每次最多可导出10000条)",
        inspectionExportAddCallInfoText:
          "导出列表时附加对话记录(每次最多可导出10000条)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail: "允许在质检详情快速执行抽检",
        fastSampleInSampleDetail: "允许在抽检详情快速执行抽检",
        fastSampleInReviewDetail: "允许在复核详情快速执行抽检",
      },
      permitCustomReasonList: { permitCustomReason: "允许自定义申述理由" },
      autoMateTasks: {
        autoLoad: "自动添加",
        sample: "自动抽检",
        assign: "自动分配",
        pushes: "自动推送",
        assess: "自动评估",
        check: "申诉复检",
        case: "案例库同步",
      },
      qualityCheckDetailTypes: { review: "复核", spotCheck: "抽检" },
      appealTypes: {
        transliterationError: "转写错误",
        discriminationError: "判别错误",
        otherError: "其他错误",
      },
      inspectDataSources: {
        voiceCall: "语音通话",
        textDialogue: "文本对话",
        realTimeVoiceCall: "实时语音通话",
        realTimeTextDialogue: "实时文本对话",
      },
      pointTypes: {
        automatic: "自动评价",
        manual: "手动评价",
        interactiveDetection: "交互检测",
      },
      pointGradeTypes: { radio: "单选", input: "输入", select: "选择" },
      sampleAndReviewStatus: {
        init: "未抽取",
        unread: "未读",
        alreadyRead: "已读",
        complaining: "申诉中",
        reviewed: "已复核",
        sampling: "抽取未检",
        samplingCompleted: "已抽检",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "忽略超额溢出",
        automaticWeightCalculation: "自动权重核算",
      },
      calculateScoreTypes: { add: "加分", sub: "减分" },
      naChecked: { check: "允许检测中不涉及此项" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "按比例给分",
        allRight: "全部正确给分",
        anyRight: "任意正确给分",
      },
      noHitQuestionResults: { true: "结果为“是”", false: "结果为“否”" },
      repetRules: { same: "内容完全相同", similar: "语义相似" },
      relateConditions: {
        highlightQuestion: "高亮问题",
        highlightAnswer: "高亮答案",
        hightlightFoctors: "高亮要素",
      },
      interactiveQuestionTypes: {
        question: "检测到问题",
        eliminateQuestion: "检测到排除问题",
      },
      interactiveAnswerTypes: {
        noAnswer: "未检测到答案",
        answer: "检测到答案 ",
        wrongAnswer: "检测到错误答案",
      },
      inspectionUpdateTypes: {
        autoInspection: "自动质检",
        autoInspectionWechat: "自动分析",
        artificialSampling: "人工抽检",
        artificialReview: "人工复核",
        manualModification: "快速抽检",
        timelyInspection: "实时质检",
        samplingAssign: "抽检分配",
        artificialComplain: "申诉提交",
        fastSampling: "快速抽检提交",
        recheckInspection: "重新质检",
        recheckInspectionWechat: "重新分析",
      },
      timelyInspectionTypes: { timelyInspection: "实时质检" },
      pushActionTypes: { email: "邮件", interfacePush: "接口推送" },
      pushDetailWays: { riskAlert: "风险提醒", subscribe: "订阅消息" },
      riskAlertPushDataTypes: {
        dialogueMessage: "通话/对话消息",
        inspectionResults: "质检结果",
      },
      subscribePushDataTypes: {
        dialogueMessage: "通话/对话消息",
        textContent: "通话/对话文本内容",
        inspectionResults: "质检结果",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "组内分数平均",
        addOrSubtract: "直接加减分",
        noChange: "不加减分",
      },
      defaultScore: {
        empty: "为空",
        add: "加分",
        notAdd: "不加分",
        sub: "减分",
        notSub: "不减分",
        na: "N/A",
        input: "手动输入",
        choose: "选择",
      },
      strategyTypes: {
        hitAdd: "命中加分",
        hitNotAdd: "命中不加分",
        hitSub: "命中减分",
        hitNotSub: "命中不减分",
      },
    },
    download: {
      exportStatus: {
        prepare: "准备中",
        process: "进行中",
        faild: "导出失败",
        success: "成功",
      },
    },
    messageType: {
      all: "全部",
      monitor: "即时监测",
      trigger: "触发通知",
      timing: "定时报告",
    },
    messagePushType: {
      all: "全部",
      system: "系统消息",
      sdk: "消息SDK",
      push: "接口推送",
      email: "邮件",
      udesk: "udesk通知",
      udeskWhite: "客服系统通知",
      weChat: "企微消息",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "分配抽检员",
      sampleInspector: "抽检员",
      assignReviewer: "分配复核员",
      reviewer: "复核员",
      taskInspector: "任务质检员",
    },
    sdkPushType: {
      webHook: "标准webhook",
      dingDing: "钉钉机器人",
      weChat: "企业微信机器人",
      customer: "自定义Json内容",
    },
    inspectionRemarksType: {
      inspection: "质检",
      check: "抽检",
      review: "复核",
    },
    segmentationStrategyType: { count: "消息数量", spacing: "消息间隔" },
    segmentationStrategy: { byTime: "定时切分", customize: "组合切分" },
    inspectType: { commonInspect: "常规质检", associateInspect: "关联质检" },
    typoDetectionOperators: { less: "小于", greater: "大于" },
    autoAddTypes: { realTimeAdd: "实时添加", timingAdd: "定时添加" },
    messageTypes: { text: "文本消息", markdown: "Markdown消息" },
    inServiceStatus: {
      activated: "已激活",
      disabled: "已禁用",
      inactivated: "未激活",
      resigned: "退出企业",
    },
    roles: { agent: "员工", customer: "客户" },
    commentVisibleAuthority: {
      all: "对所有人可见",
      self: "仅自己可见",
      designation: "指定人可见",
    },
    appealCheckingTask: {
      eCommerceReview: "电商评论",
      socialMediaComments: "社媒评论",
      largeModel: "大模型",
      customModel: "自定义模型",
      universalModel: "通用模型",
      enterpriseWeChatText: "企微文本",
      generalQualityInspection: "普通质检",
      processQualityInspection: "流程质检",
      voiceCopy: "语音复盘",
      beEqualTo: "等于",
      notEqualTo: "不等于",
      canAppeal: "可以申诉",
      noAppeal: "不可申诉",
    },
    wechat: {
      redEnvelopes: "红包",
      text: "文本",
      picture: "图片",
      voice: "语音",
      voiceCall: "语音通话",
      video: "视频",
      businessCard: "名片",
      position: "位置",
      expression: "表情",
      link: "链接",
      applet: "小程序",
      chatRecord: "聊天记录",
      mixedMessage: "混合消息",
      file: "文件",
    },
    gong: {
      sessionActivity: "会话活动",
      conversationAction: "会话动作",
      riskSemantics: "风险语义",
      daysNotFollowedUp: "未跟进天数",
      waysideData: "随路数据",
      notStarted: "未开始",
      completed: "已完成",
      haveInHand: "进行中",
      unpublished: "未发布",
      published: "已发布",
      knowledgePoints: "知识点",
      technologicalProcess: "流程",
      notMarked: "未标注",
      inAnnotation: "标注中",
      annotated: "已标注",
    },
    qualityChecingTask: {
      appealCanceled: "申诉取消",
      complaintExpired: "申诉过期",
      incomingCall: "呼入",
      exhale: "呼出",
      incomingCallthirdParty: "呼入（三方）",
      incomingCallconsultation: "呼入（咨询）",
      incomingCallforcedInsertion: "呼入（强插）",
      incomingCallmonitoring: "呼入（监听）",
      incomingCalltransfer: "呼入（转接）",
      incomingCallinterception: "呼入（拦截）",
      incomingCalltransferToExternalLine: "呼入（转接外线）",
      incomingCallthirdPartyExternalLine: "呼入（三方外线）",
      incomingCallexternalLine: "呼入（咨询外线）",
      outgoingCallthirdParty: "呼出（三方）",
      outgoingCalltransfer: "呼出（转接）",
      outgoingCallforcedInsertion: "呼出（强插）",
      outgoingCallmonitoring: "呼出（监听）",
      outgoingCallinterception: "呼出（拦截）",
      outgoingCalltransferToExternalLine: "呼出（转接外线）",
      outgoingCallthreePartyExternalLine: "呼出（三方外线）",
      customerServiceAnswer: "客服接听",
      customerServiceMissed: "客服未接",
      customerAnswer: "客户接听",
      customerMissed: "客户未接",
      theTelephoneIsBusy: "话机占线",
      phoneOffline: "话机离线",
      customerQuickHanging: "客户速挂",
      clientHangsUp: "客户挂机",
      queueTimeout: "排队超时",
      giveUpQueuing: "放弃排队",
      outsideLineAnswering: "外线接听",
      externalLineIsNotConnected: "外线未接",
      noQueueSelected: "未选择队列",
      notEvaluated: "未评价",
      noEvaluationRequired: "无需评价",
      queuingSucceeded: "排队成功",
      noCustomerServiceOnline: "无客服在线",
      customer: "客户",
      customerService: "客服",
      outsideLines: "外线",
    },
    sessionActivity: {
      single: "单次平均通话时间",
      all: "总通话量",
      allTime: "通话总时长",
    },
    sessionAction: {
      salesProportion: "销售说话占比",
      salesLongest: "平均最长销售发言",
      customerLongest: "平均最长客户发言",
      discussNumber: "深入讨论次数",
      frequency: "提问频次",
    },
    monitorCriteria: { all: "前后", before: "前", after: "后" },
    monitorTimeCycle: { day: "天", week: "周", month: "月" },
    monitorGroupTestType: { ab: "俩组对比", a: "A组", b: "B组" },
    fields: {
      department: "部门",
      customerTags: "客户标签",
      member: "成员",
      time: "时间",
      cascade: "级联",
      anyMatch: "任意匹配",
      keyWord: "关键词",
      keyEvents: "关键事件",
    },
    semanticIntelligence: {
      staff: "员工",
      customer: "客户",
      employeesAndCustomers: "员工和客户",
      notStarted: "未开始",
      haveInHand: "进行中",
      completed: "已完成",
      callRecording: "通话录音",
      enterpriseWeChatCall: "企微通话",
      enterpriseWeChatText: "企微文本",
      discourseMiningForObjectionHandling: "异议处理话术挖掘",
      productSellingPointScriptsMining: "产品卖点话术挖掘",
      conversationScenario: "会话场景",
      topicOfConversation: "话题",
      keyEvents: "关键事件",
      customerLabel: "客户标签",
      includeAny: "包含任意",
      includeAll: "包含全部",
      and: "且",
      or: "或",
      question: "疑问句",
      nonInterrogativeSentence: "非疑问句",
    },
    coach: {
      notPassed: "未通过",
      analogTelephone: "模拟电话",
      enterScenarioInformation: "输入情景信息",
      generateDialogueFramework: "生成对话框架",
      generatePromptLanguage: "生成提示语",
      aIGeneratedDialogue: "AI生成对话",
      system: "系统",
      custom: "自定义",
      flow: "流程",
      script: "话术",
      express: "表达",
      emotion: "情绪",
      rateAccordingToCourseConfiguration: "按照课程配置评分",
      intelligentModelRating: "智能模型评分",
      allData: "全部数据",
      dataFromThisDepartment: "本部门数据",
      multidepartmentalData: "多部门数据",
      underReview: "审核中",
      no: "否",
      yes: "是",
      automaticRating: "自动评分",
      auditRating: "审核评分",
      reRating: "重新评分",
      inAppeal: "申诉中",
      reviewed: "已复核",
      notViewed: "未查看",
      viewed: "已查看",
      uncorrected: "未修正",
      corrected: "已修正",
      practice: "练习",
      examination: "考试",
      other: "其他",
      notMeetingStandards: "未达标",
      meetingStandards: "达标",
      excellent: "优秀",
      invalid: "失效",
      notStarted: "未开始",
      haveInHand: "进行中",
      ended: "已结束",
      completed: "已完成",
      hangInTheAir: "未完成",
      passed: "已通过",
      voiceConversation: "语音对话",
      textDialogue: "文本对话",
      slidePresentation: "幻灯片演讲",
      unpublished: "未发布",
      published: "已发布",
      narration: "旁白",
      studentSpeaking: "学员说话",
      robotTalk: "机器人说话",
      knowledgeBaseQA: "知识库问答",
      slide: "幻灯片",
      negativeEmotions: "情绪负向",
      emotionallyNormal: "情绪正常",
      incompleteScript: "话术不完整",
      completeScript: "话术完整",
      normalSpeechSpeed: "语速正常",
      speakTooFast: "语速过快",
      speakTooSlowly: "语速过慢",
      whole: "全部",
      singlePage: "单页",
      qA: "一问一答",
      situationalDialogue: "情景对话",
      misses: "未命中",
      hit: "命中",
      sequentialQA: "顺序问答",
      randomQA: "随机问答",
      mastered: "已掌握",
      notMastered: "未掌握",
    },
    algorithm: {
      salesEmpowerment: "销售赋能",
      enterpriseWeChatVoice: "企业微信语音",
      enterpriseWeChatText: "企业微信文本",
      waiting: "等待中",
      extracting: "抽取中",
      success: "成功",
      fail: "失败",
      intelligentQualityInspection: "智能质检",
      textDialogue: "文本对话",
      voiceCall: "语音通话",
      smartTags: "智能标签",
      satisfactionEvaluation: "满意度评价",
      score: "评分",
      grade: "评级",
      customerServiceTeam: "客服组",
      conversationAnalysis: "会话分析",
    },
    operatorListGather: {
      businessSummary: "业务小结",
      firstSentence: "首句",
      lastSentence: "末句",
      allTime: "全部时间",
      nonBusinessWorkingHours: "非业务工作时间",
      businessWorkingHours: "业务工作时间",
    },
    callDialogueManage: {
      eCommerceReview: "电商评论",
      socialMediaComments: "社媒评论",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "登录",
        email: "邮箱",
        password: "密码",
        forgetPwd: "忘记密码",
        emailCannotBeEmpty: "邮箱不得为空。",
        passwordCannotByEmpty: "密码不得为空。",
        mustChangeLogin: "对不起，您的密码必须重置，请联系管理员重置密码。",
        dismissionLogin: "对不起，您已经离职。",
        forbiddenLogin: "对不起，您的账户是禁用状态。",
        loginError: "登录失败，邮箱或密码错误。",
        loginPrompt: "登录到系统",
        rememberMe: "记住我",
      },
      logout: { logoutError: "注销失败，请查看网络连接或稍后再试。" },
      resetPassword: {
        forgetPwd: "忘记密码",
        inputEmailPrompt:
          "在下方输入你的帐号邮箱，我们将向您发送重置密码的电子邮件和说明。",
        sendEmailSuccess:
          "修改密码确认信函已经成功发送至您填写的邮箱中，请及时查收。",
        sendEmailFailed: "邮箱发送失败，请检查网络连接或稍后再试。",
        setNewPassword: "设置您的新密码",
        passwordCannotEmpty: "密码不得为空",
        passwordFormWrong: "密码格式不正确",
        confirmPasswordWrong: "两次输入的密码不相等。",
        passwordResetSuccess: "密码重置成功。",
        passwordResetFailed: "密码重置失败，请检查网络连接或稍后再试。",
        linkTimeover: "重置密码链接到期，请重试",
      },
      form: { title: "CASE表单展示" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "邮箱",
          emailFormatError: "邮箱格式错误，请重新输入！",
          forgotPassword: "忘记密码？",
          describe:
            "在下方输入你的帐号邮箱，我们将向您发送重置密码的电子邮件和说明。",
          send: "发送",
          emailCannotBeEmpty: "邮箱不能为空",
          resetPasswordExpire: "重置密码链接到期，请重试",
        },
        resetPassword: {
          setPassword: "设置您的新密码",
          newPassword: "新密码",
          repeatPassword: "重复密码",
          resetPassword: "重置密码",
          passwordCannotBeEmpty: "新密码不能为空。",
          confirmPasswordCannotBeEmpty: "重复密码不能为空。",
          passwordsNotMatch: "两次密码不一致！",
          passwordFormWrong: "密码不能带有中文，并且个数在6-14位！",
          linkExpiration: "重置密码链接到期，请重试",
        },
      },
      errors: { haveNoPermissionWarningText: "您没有此页面的查看权限" },
    },
    downloadCenter: {
      name: "下载中心",
      saveTime: "(默认保存30天)",
      headerTitle: "下载文件",
      download: "下载",
      downloadPromp: "下载任务已提交，请前往",
      viewProgress: "查看进度",
    },
    uploadCenter: { name: "上传中心" },
    tasks: {
      index: {
        name: "名称",
        remark: "描述",
        qualityCheckTasksTitle: "工作台",
        informationCollection: "采集任务",
        qualityCheckTasks: "质检任务",
        wechatQaTasks: "分析任务",
        moduleName: "使用模板新建",
        creatNewQualityCheckTask: "新建任务",
        generalInspectionList: "通用质检报告",
        editQualityCheckTask: "编辑质检任务",
        creatNewQualityCheckTaskError: "新建质检任务失败",
        editQualityCheckTaskError: "编辑质检任务失败",
        type: "表单类型",
        singleScore: "单项分数",
        singleScoreNullError: "单项分数不能为空",
        inspector: "质检员",
        inspectDataSource: "数据源",
        taskName: "任务名称",
        inspectionType: "质检类型",
      },
      manage: {
        screeningCondition: "筛选条件",
        template: {
          condition: {
            index: { name: "条件", existingConditions: "已有条件" },
            detail: {
              id: "ID",
              name: "条件名称",
              remark: "描述",
              checkRange: "检测范围",
              operator: "算子",
              operatorLogic: "算子逻辑",
            },
          },
          checkPointTest: {
            title: "规则测试",
            running: "规则测试中，请稍后...",
            clearSuccess: "模拟对话清空成功",
            mock: "模拟对话",
            save: "保存选中对话",
            saveAll: "保存全部对话",
            saveSuccess: "对话保存成功",
            close: "退出",
            delete: "删除选中",
            clear: "清空",
            customer: "客户",
            agent: "客服",
            test: "测试",
            request: "模拟对话清空后将不可恢复，是否确认清空？",
            deletePopconfirmContent: "是否确认删除选中对话？",
          },
          checkPoint: {
            index: {
              name: "规则配置",
              wechatTitle: "分析模型配置",
              existingCheckPoints: "已有质检规则",
              currentCheckPointScore: "当前质检规则分值为",
              currentCheckPointScoreWechat: "当前分析模型分值为",
              currentCheckPointWeight: "当前质检规则权重总和为",
              weightTotalScoreAndItemScore: "总分：100分 单项分：",
              template: "质检规则模板",
              import: "导入质检规则模板",
              getTemplateError: "质检规则模板获取失败",
              tabs: { template: "模板", demo: "样例" },
              customize: "空白模板",
              move: "拖拽以调整分类和排序",
              classification: "质检分类",
              classificationWeChat: "模型分类",
              first: "一级分类",
              second: "二级分类",
              new: "新建质检规则",
              weChatNew: "添加分析模型",
              createWeChat: "新建分析模型",
              empty: "暂无质检规则，",
              wechatEmpty: "暂无分析模型，",
              newSecond: "新建二级分类",
              newFirst: "新建一级分类",
              checkPoint: "质检规则",
              checkPointWeChat: "分析模型",
            },
            detail: {
              name: "名称",
              remark: "描述",
              type: "类型",
              deduction: "扣分",
              rule: "规则",
              highlighted: "高亮",
              gradeType: "评分方式",
              interactiveCategory: "交互分类",
              predeterminedScore: "分值",
              weight: "权重",
              na: "N/A",
              default: "默认得分",
              preRule: "前置条件规则",
              analysisHighlighted: "解析规则",
              categorySelectPrompt: "请选择分类",
              checkHighlightArrayError:
                "规则与高亮不匹配，请重新点击解析规则按钮",
              foctorsHolder: "请选择要素",
              foctorsErrorNone: "多要素规则为空，请新增多要素类型",
              naError: "默认得分为N/A时无法关闭该项",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A即Not Applicable，为该条质检规则设置前置条件，符合条件时检测，不符合条件时即该规则不适用，则不检测该条规则;\n前置条件填写说明："&&”表示“和”，“||”表示“或”，“!”表示“非”，填写示例“(R1||R2)&&R3"',
                applicationMessageType: "应用消息类型",
                selectAll: "全选",
                highlightTags: "高亮标签：",
                ruleType: "规则类型",
                dataSource: "数据源",
                whole: "全部",
                enterpriseMicroConversation: "企微会话",
                enterpriseAndMicroCommunication: "企微通话",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "判断节点",
                    dialogueNode: "对话节点",
                    editNodes: "编辑节点",
                  },
                  nodeConfig: {
                    nodeName: "节点名称",
                    nodeRules: "节点规则",
                    scoringLogic: "评分逻辑",
                    score: "分值：",
                    rule: "规则",
                    multiBranchLogic: "多分支逻辑",
                    highlight: "高亮",
                    submit: "提交",
                  },
                  ruleList: {
                    branch: "分支",
                    branchName: "分支名称:",
                    addRules: "增加规则",
                  },
                  sidebar: { node: "节点" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "自动整理",
                      canvasAdaptation: "画布自适应",
                      copy: "复制",
                      delete: "删除",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "判断节点的子节点不能作为连接目标",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "开始节点不能作为连接目标",
                      startingNodeCannotConnectMultipleNodes:
                        "开始节点不能连接多个节点",
                      nodeCannotBeConnectedToItself: "节点不能与自己相连接",
                      startNode: "开始节点",
                    },
                  },
                },
                msgTypesSelect: {
                  index: { cancelSelectAll: "取消全选", selectAll: "全选" },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "是否符合",
                    gradedEvaluation: "分档评价",
                  },
                },
              },
            },
            configuration: {
              title: "质检规则",
              wechatTitle: "分析模型",
              neme: "名称",
              errorMessage: "删除失败，至少要有一个条件",
              desc: "描述",
              status: "状态",
              yes: "有效",
              no: "无效",
              namePlaceHolder: "请输入标签名称",
              desPlaceHolder: "请输入描述",
              nameEmpty: "名称不能为空",
              desEmpty: "描述不能为空",
              type: "类型",
              ruler: "智能规则",
              machineLearning: "自学习模式",
              autoScore: "智能评分（旧）",
              manualScore: "手动评分",
              interaction: "交互检测（旧）",
              typeTips: {
                model:
                  "智能模型：是预设的一套质检体系，无需配置维护，能够快速质检出服务中存在的问题。该模型较通用，如不满足业务诉求请使用“智能规则”或”手动评分",
                rule: "智能规则：即基于规则AI自动进行评分",
                manual: "手动评分：即人工质检，由人工手动打分",
              },
              model: "模型：",
              ruleType: {
                words: "话术",
                interaction: "交互",
                feature: "特征",
                information: "信息",
              },
              testingMode: { all: "检测全部关键词", any: "检测任意关键词" },
              operatorTypesOfInteraction: {
                question: "一问一答",
                element: "要素回答",
                repet: "上下文重复",
                snatch: "抢话",
                silent: "静默",
              },
              role: "检测角色：",
              preposition: "前置算子：",
              range: "检测范围：",
              from: "第",
              to: "句至第",
              end: "句",
              hasOldSilentTip:
                "您好，为提升准确率，“智能规则-交互-静默”已升级为“响应超时”，建议更新使用规则！",
              changeToOld: "切换至旧版",
              changeToNew: "切换至新版",
              logic: {
                logic: "评分逻辑",
                naTip:
                  "N/A即Not Applicable，为该条质检规则设置前置条件，符合条件时检测，不符合条件时即该规则不适用，则不检测该条规则",
                score: "分值：",
                na: "N/A：",
                naDes: "允许检测中不涉及此项",
                preRule: "前置条件规则：",
                notCatchPreRule: "不满足前置条件时：",
                naChecked: "命中N/A时：",
                preRuleEmpty: "前置条件规则不能为空",
                operatorEmpty: "算子中必要条件不能为空",
                multipleEmpty: "多重逻辑评分中必要条件不能为空",
                average: "组内分数平均",
                addOrSubtract: "直接加减分",
                noChange: "不加减分",
                rule: "规则：",
                all: "满足所有算子",
                any: "满足任意算子",
                customize: "自定义算子逻辑",
                multiple: "多重逻辑评分",
                heightLight: "高亮：",
                machineLearningTip:
                  "自学习模式目前仅针对单句话术生效，复杂话术请使用智能规则",
                strategy: "策略：",
              },
            },
            component: { approvalSubmittedSuccessfully: "审批提交成功" },
            components: {
              pageHeaderExtra: { test: "测试", formal: "正式", submit: "提交" },
              createModal: { index: { myRules: "我的规则" } },
            },
          },
          automaticAdd: {
            index: {
              name: "流程自动化",
              screeningCondition: "筛选条件",
              aiScreeningCondition: "智能筛选条件",
              rule: "规则",
              automaticAddition: "自动添加",
              automaticAdditionTip:
                "自动实时或者定时将数据添加至任务进行AI质检",
              autoAdd: {
                autoAddType: "自动添加方式",
                addFrequency: "添加频率",
                add: "添加",
              },
              automaticSamplingInspection: "自动抽检",
              automaticSamplingInspectionTip:
                "自动抽取需要人工质检的数据，例如，每日抽取昨日随机10%的数据进行人工抽检",
              everyAgentCheckLimit: "每客服抽取上限",
              automaticAllocation: "自动分配",
              automaticAllocationTip:
                "抽检分配：抽取的数据自动分配给质检员，例如，每日抽取昨日随机10%的数据进行人工抽检，平均随机分配给此任务的2位质检员",
              automaticAllocationTip2:
                "复核分配：申诉的数据自动分配给质检员复核，例如，每日申诉的数据平均随机分配给此任务的2位质检员进行复核",
              automaticPush: "自动推送",
              automaticPushTip:
                "触发通知：质检时进行通知/告警，例如，当客服出现致命错误时，给企微群内发送通知消息",
              automaticPushTip2:
                "定时报告：将质检结果以邮件形式发送定时报告，例如，每日9：00将昨日人工抽检的质检结果发送至客服主管邮箱",
              automaticAssess: "自动评估",
              automaticAssessTip:
                "定时将人工抽检结果对比AI质检结果进行准确率评估。例如，每日8：00将昨日抽检的数据进行准确率评估",
              reexaminationComplaint: "申诉复检",
              spoktReviewDetail: "业务配置",
              spoktReviewDetailTip:
                "对质检列表、抽检列表、申诉业务、复核列表、备注等功能配置",
              qaDetailAction: "质检配置",
              spoktDetailAction: "抽检配置",
              reviewDetailAction: "复核配置",
              appealAction: "申诉配置",
              remarkTemplate: "备注模板",
              automaticArchiving: "自动归档",
              conditionalRules: "条件规则",
              qualityInspectionConditions: "质检条件",
              unopened: "暂未开启",
              sampleInspectionDistributionModel: "抽检分配",
              reviewAllocationModel: "复核分配",
              distributionModelTitle: "分配策略：",
              pushRules: "推送规则",
              push: "推送",
              assessRules: "评估规则",
              on: "on",
              off: "off",
              updateStatusSuccess: "状态更改成功！",
              updateStatusFailed: "状态更改失败，请稍后重试！",
              sampleNumberOrPercent: "抽样条数或比例",
              inspectorList: "指定质检员",
              numberOfSubmission: "允许提交申述次数",
              rewivewDays: "天",
              TimeOfSubmission: "提交申述有效期",
              rewivewSecond: "次",
              switchOn: "开启",
              switchOff: "关闭",
              notMatchAnyRule: "不匹配任意规则时：",
              appealOptionsList: {
                hiddenInspector: "申诉隐藏质检员信息",
                hiddenHit: "申诉页面支持高亮定位命中",
              },
              caseLibrary: "案例库同步",
              caseLibrarySyncTip: "将QA系统添加的案例同步至客服系统",
              addCase: "增加同步",
              caseLibraryId: "案例库",
              caseLibraryIdTip: "QA系统的案例库分组",
              udeskCaseLibraryIdTip: "客服系统的案例分组",
              syncHis: "同步历史案例",
              otherSettings: "其他设置",
              udeskCaseLibraryId: "推送案例组",
              url: "推送URL",
              urlTip: "请输入以http://或https://开头的接口地址",
            },
            templateNew: {
              secondaryReview: "二级复核",
              numberOfAppealsAllowedToBeSubmitted: "允许提交申诉次数",
              validityOfSubmissionOfAppeal: "提交申诉有效期",
              validityOfAppealReview: "申诉复核有效期",
              hours: "小时",
              businessSummary: "业务小结",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "每客服抽取下限",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "由大模型自动生成业务小结，根据业务情况可自定义生成维度",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "仅一级复核员分配策略",
              secondaryCompounding: "二级复合：",
              secondLevelReviewersOnlySupportAverageDistribution:
                "二级复核员仅支持平均分配",
              secondLevelReviewer: "二级复核员：",
              samplingInspectionTarget: "抽检目标",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "设定人工抽检应完成的目标，目标未达成或者超过时给出提示，例如每客服每月应完成抽检100条，低于80条时，质检、抽检页面进行提示。",
            },
            component: {
              verificationFailed: "校验失败",
              pleaseAddASecondLevelQualityInspector: "请添加二级质检员",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "提示语",
                    fieldName: "字段名称",
                    fieldDescription: "字段描述",
                    generateContent: "生成内容",
                    enabled: "是否启用",
                    generationMethod: "生成方式",
                    automaticGeneration: "自动生成",
                    manualTrigger: "手动触发",
                    filterCondition: "筛选条件",
                    return: "返回",
                    preserve: "保存",
                  },
                  components: {
                    training: { index: { generateResults: "生成结果" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "不限定",
                    limitedScope: "限定范围",
                    add: "添加",
                    thereIsCurrentlyNoDataAvailable: "暂无数据",
                  },
                },
              },
              index: {
                fieldName: "字段名称",
                fieldDescription: "字段描述",
                nothing: "无",
                generationMethod: "生成方式",
                generateManually: "手动生成",
                automaticGeneration: "自动生成",
                generateContent: "生成内容",
                notQualified: "不限定",
                filterCondition: "筛选条件",
                enabled: "是否启用",
                type: "类型",
                system: "系统",
                custom: "自定义",
                operation: "操作",
                edit: "编辑",
                areYouSureToDeleteIt: "确定删除吗？",
                delete: "删除",
                addField: "增加字段",
              },
            },
          },
          roleConfig: {
            index: {
              name: "角色配置",
              roleConfigTip:
                "用于对导入的通话数据为单声道时，为话者分离处理配置识别规则。符合规则的为客服角色",
              operator: "算子",
              operatorLogic: "算子逻辑",
            },
          },
          manualAdd: {
            index: {
              name: "手动添加",
              tip: "用于通过手动配置条件筛选预览数据以及快速将其加入所处质检任务",
              screeningCondition: "按照筛选条件添加",
              aiScreeningCondition: "智能筛选条件",
              rule: "规则",
              createTime: "添加时间",
              filterTitle: "筛选内容",
              filterRule: "筛选条件",
              startTime: "开始时间",
              endTime: "结束时间",
              records: "导入记录",
              createUserName: "操作人员",
              successCount: "添加成功",
              conditionRule: "条件规则：",
              preview: "预览",
              previewData:
                "当前符合筛选条件{0}条，已加入任务{1}条，可加入任务{2}条，不可加入任务{3}条",
              failCount: "添加失败",
            },
          },
          basicInfo: {
            index: {
              title: "基本信息",
              name: "名称",
              taskName: "任务名称",
              taskNamePlaceholder: "请输入任务名称",
              requiredName: "任务名称不能为空",
              taskRemarkPlaceholder: "请输入任务描述",
              requiredRemark: "任务描述不能为空",
              remark: "描述",
              taskRemark: "任务描述",
              source: "数据源",
              benchmarkScore: "基准分",
              benchmarkScorePlaceholder: "请输入基准分",
              scoreLowerLimit: "最低得分下限",
              scoreUpperLimit: "最高得分上限",
              inspectType: "质检类型",
              nlpModel: "NLP模型",
              commonInspect: "常规质检",
              associateInspect: "关联质检",
              associateCycle: "关联周期",
              associateRegulation: "关联规则",
              judgeStrategy: "执行逻辑",
              rule: "规则",
              commonTip:
                "常规质检：适用于单次通、对话，将单次沟通的内容进行质检",
              associateTip:
                "关联质检：适用于多次的通、对话，将多次沟通的内容关联起来进行质检",
              cycleTip:
                "如果关联的多个通、对话，符合关联周期内的数据进入该任务质检",
              scoreLowerLimitPlaceholder: "请输入最低得分下限",
              scoreUpperLimitPlaceholder: "请输入最高得分上限",
              call: "语音通话",
              dialogue: "文本对话",
              user: "质检员",
              wechatUser: "操作员",
              noDesignated: "不指定",
              type: "表单类型",
              singleScore: "单项分数",
              conduct: "请先完善下列信息，完成后请点击下一步",
              conduct2:
                "请完成质检规则与质检点的配置,若您已完成配置,您可以点击下一步或退出引导",
              conduct3:
                "质检评级是依据质检点与其评分情况进行评级的配置,您可以定义不同的质检结果级别,若您对评级没有需求您可以直接跳过此步骤,若您已完成配置,您可以点击下一步或退出引导",
              conduct4:
                "您可以在自动添加位置按照本质检任务应当检查的数据特性,对将会被自动引入任务的数据特性进行配置,若您暂时不需要自动添加数据,可以点击跳过,若您已完成配置,您可以点击下一步",
              conduct5:
                "若您使用的是单声道录音,请您务必配置此项并点击下一步,请使用仅客服会使用的话术帮助系统识别出客服与客户的不同轨道.若您使用双声道录音或已完成配置,您可以点击跳过",
              conduct6:
                "您可以通过手动添加功能,引入系统里已有的通对话数据进行质检,或您已提交手动添加任务,请您直接点击完成",
              helper: "查看帮助文档",
              quit: "退出引导",
              next: "下一步",
              complete: "完成",
              skip: "跳过",
              cancel: "取消",
              delete: "删除",
              save: "保存",
            },
            template: { samplingInspector: "抽检员", sessionType: "会话类型" },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "质检工作台",
              search: "筛选",
              export: "导出",
              condition: "条件",
              logic: "逻辑",
              reQualityInspection: "重新质检",
              reQualityInspectionUnderWay: "重新质检任务中...",
              reQualityInspectionUnderWayTip:
                "关闭窗口后离开页面不会影响任务进行",
              reQualityInspectionDone: "重新质检已完成",
              reQualityInspectionDoneTip:
                "现已成功完成重新质检，请点击确认按钮",
              endReQualityInspection: "终止任务",
              alreadyDoneTip: "已完成",
              totalTip: "合计",
              unit: "条",
              endReInspectionSuccess: "重新质检任务已终止",
              closeWindow: "关闭窗口",
              endReInspectionFailed: "重新质检任务失败，请稍后重试!",
            },
            detail: {
              name: "质检详情",
              reviewName: "复核详情",
              spotCheckName: "抽检详情",
              audioText: "录音文本",
              callInfo: "通话信息",
              satisfactionEvaluation: "满意度评价：",
              queueTime: "排队耗时：",
              ringingTime: "振铃时间：",
              callTime: "通话事件：",
              hangUpIdentity: "通话挂断方：",
              location: "归属地：",
              source: "来源：",
              agent: "客服：",
              deviceType: "设备类型：",
              callType: "通话类型：",
              phoneNumber: "中继号：",
              task: "任务：",
              commentsTip: "请输入质检评语",
              lastPage: "上一页",
              nextPage: "下一页",
              score: "质检评分",
              review: "复核",
              file: "归档",
              changeList: "变更记录",
              fullMarks: "满分",
              most: "最多",
              saveReview: "复核提交",
              saveSpotCheck: "抽检提交",
              point: "质检点",
              sampleAssignInspector: "分配抽检员",
              reviewAssignInspector: "分配复核员",
              sampleStatus: "抽检状态",
              read: "已读",
              user: "用户",
              confirmer: "确认人",
              voiceDownloadError: "语音下载失败，请稍后重试！",
              smartTags: "智能标签",
              interactiveTip: "使用交互识别对客服FAQ的使用情况进行检测",
              interactiveIsEmptyTip: "未检测到交互识别内容",
              businessRecords: "业务记录",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: { analysisOfEnterpriseWeChatGroupChat: "企业微信群聊分析" },
          },
        },
      },
      cards: {
        health: "质检健康度",
        accuracy: "发现核准率",
        sessionsTotal: "会话总数",
        enable: "启用",
        disable: "禁用",
        none: "暂无",
        call: "通话",
        dialogue: "对话",
        taobao: "电商",
        wecom: "会话",
        ticket: "工单",
      },
      component: {
        backup: "(备份)",
        taskSaveAsSucceeded: "任务另存为成功",
        copyContent: "复制内容",
        essentialInformation: "基本信息",
        ruleConfiguration: "规则配置",
        basicInformationQualityInspector: "基本信息-质检员",
        qualityInspectionRating: "质检评级",
        roleConfiguration: "角色配置",
        autoAddConfiguration: "自动添加配置",
        autoPushConfiguration: "自动推送配置",
        automaticSamplingConfiguration: "自动抽检配置",
        automaticAllocationConfiguration: "自动分配配置",
        businessConfiguration: "业务配置",
        caseBaseConfiguration: "案例库",
      },
      template: {
        cancel: "取消",
        complete: "完成",
        sort: "排序",
        ruleApproval: "规则审批",
        enterpriseMicroConversationAnalysisTask: "企微会话分析任务",
        enterpriseAndMicroVoiceAnalysisTask: "企微语音分析任务",
        saveTaskAs: "任务另存为",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "规则详情" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "修改成功",
            automaticApproval: "自动审批",
          },
          pageHeaderExtra: {
            modificationSucceeded: "修改成功",
            test: "测试",
            formal: "正式",
            adopt: "通过",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "确定驳回吗",
              pleaseEnterTheRejectionReason: "请输入驳回原因",
              reject: "驳回",
            },
          },
          table: {
            index: {
              modificationSucceeded: "修改成功",
              taskName: "任务名称",
              dataSource: "数据源",
              qualityInspectionType: "质检类型",
              state: "状态",
              updateTime: "更新时间",
              updatedBy: "更新人",
              approver: "审批人",
              reasonForRejection: "驳回原因",
              operation: "操作",
              details: "详情",
              adopt: "通过",
              whole: "全部",
              approvalStatus: "审批状态",
            },
          },
        },
      },
    },
    dashboard: {
      title: "概览",
      hi: "嗨，",
      standardEdition: "标准版",
      call: "语音质检余额",
      text: "文本质检有效期",
      collectionTasks: "收藏的质检任务",
      noCollection: "暂无收藏的质检任务，",
      goToCollecte: "去收藏",
      yesterdayOverView: "昨日业务统计",
      yesterdayAgent: "昨日客服排行榜",
      yesterdayInspWork: "昨日质检员工作情况",
      operationalStatistics: {
        title: "业务统计",
        qualityTesting: "质检",
        spotCheck: "抽检",
        appeal: "申诉",
        chartTitles: {
          qualityTestingTrend: "质检量趋势",
          qualityTestingAnalysis: "质检任务情况",
          spotCheckTrend: "抽检趋势",
          spotCheckAnalysis: "抽检任务情况",
          appealTrend: "申诉趋势",
          appealAnalysis: "申诉任务情况",
        },
        titles: {
          qualityDataTend: "质检数据分布",
          qualityCover: "质检覆盖情况",
          qualityAverageTime: "平均质检等待时长",
          qualityCoverTend: "质检覆盖趋势",
          handCover: "人工处理情况",
          checkCover: "抽检覆盖率",
          resAverageTime: "平均处理时长",
          handTend: "人工处理趋势",
          dataTend: "数据任务分布",
        },
        qualityAverage: "质检平均分",
        scoreTrend: "质检评分趋势",
        qualityAverageTime: "平均质检等待时长",
        callCoverage: "语音质检覆盖率",
        textCoverage: "文本质检覆盖率",
        checkCount: "抽检数据量",
        siphonCount: "抽取数据量",
        reviewCount: "复核数据量",
        appealCount: "申诉数据量",
        checkRate: "抽检执行比例",
        reviewRate: "申诉复核比例",
        PieTitle: {
          totalCount: "通对话总数",
          totalCall: "语音通话数",
          totalIm: "文本对话数",
          inspectionCall: "语音质检量",
          inspectionIm: "文本质检量",
          checkCall: "语音抽检量",
          unCheckCall: "语音未抽检量",
          checkIm: "文本抽检量",
          unCheckIm: "文本未抽检量",
          reviewCall: "语音复核量",
          reviewIm: "文本复核量",
          unReviewCall: "语音未复核量",
          unReviewIm: "文本未复核量",
          unInspectionCall: "语音未质检量",
          unInspectionIm: "文本未质检量",
        },
        check_rate: "抽检覆盖率",
        voice_check_rate: "语音抽检覆盖率",
        text_check_rate: "文本抽检覆盖率",
        sample_execute_time: "平均抽检处理时长",
        review_execute_time: "平均申诉处理时长",
      },
      analysisOverview: {
        tableTitle: { conditions: "质检规则看板", agent: "客服排行榜" },
        tableTips: {
          conditions:
            "<div>对质检规则违规、合规的通对话总数进行排序。<br />\n                                        质检项：质检点的名称<br />\n                                        违规通对话总数：在该质检项上违规的通对话总数<br />\n                                        违规占比：违规占比=违规通对话总数/通对话总数</div>",
          agent:
            "<div>对坐席的平均评分进行排序<br />\n                                        客服名称：客服的名称<br />\n                                        客服组：客服所在客服组（客服信息内的组织信息）<br />\n                                        评分的通对话总数：该客服的质检总通对话数<br />\n                                        质检平均得分：该客服的质检平均得分（得分求和/通对话总数）</div>",
        },
        tips: {
          total: "进入质检任务的通/对话总数",
          agentTotal: "进入质检任务的质检客服总数",
          avg: "质检评分的平均分",
          violation: "所有的通对话质检命中质检项违规的次数求和",
          compliance: "通对话质检过程中命中质检项合规的次数求和",
          check: "人工抽检的总数",
          appeal: "通对话总数中提交过申诉的总数",
          review: "通对话总数中提交过复核的总数",
        },
        subTips: {
          violation: "违规率：违规总次数/质检项总次数*100%",
          compliance: "合规率：合规总次数/质检项总次数*100%",
          check: "抽检覆盖率：人工抽检总数/质检总量*100%",
          appeal: "申诉率：申诉总数/质检总量*100%",
          review: "复核率：复核总数/质检总量*100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "系统设置",
          systemName: "系统名称",
          speakerRecognize: "话者识别",
          massage: "消息通知",
          evaluate: "人工认知评价",
          evaluateTip: "开启后在质检详情中选择快速抽检会提示进行人工评价",
          speakerRecognizeTip:
            "通过API接入第三方的通话录音需要标识左声道是客服还是客户",
          speakerRecognizeTip2:
            "单通道录音通过文本识别，角色配置定义客服的角色",
          enabledImSendStatusFilter: "文本质检消息过滤",
          enabledImSendStatusFilterTips:
            "质检的算子话术的检测内容不包含状态为【已撤回】、【发送失败】、【离线消息未读】的消息",
          lang: "系统语言设置",
          zh: "简体中文",
          en: "English",
          tw: "繁体中文",
        },
        template: {
          largeModelSettings: "大模型设置",
          indonesian: "印尼语",
          vietnamese: "越南语",
          portuguese: "葡萄牙语",
          malay: "马来语",
          french: "法语",
          spanish: "西班牙语",
          japanese: "日语",
          thai: "泰语",
          blockSystemSendingMessages: "屏蔽系统发送消息",
          textDataDesensitization: "文本数据脱敏",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "开关开启则展示脱敏字段下拉列表（支持多选），脱敏字段：人名、手机号、地址",
          desensitizingField: "脱敏字段",
          name: "人名",
          cellphoneNumber: "手机号",
          address: "地址",
          dataCleaning: "数据清理",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "设置后，质检通对话、企业微信会话数据将定期被清理，且不可恢复，请谨慎操作",
          newCleaningRules: "新增清理规则",
          qualityInspectionRuleApproval: "质检规则审批",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "开启后，质检规则修改需要被审批通过后才能生效",
          maskOfflineMessageUnread: "屏蔽离线消息未读",
          privacyNumberProtection: "隐私号码保护",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            "开启后对通话中”客户电话”字段进行脱敏，显示为1881*******",
          shieldingWithdrawn: "屏蔽已撤回",
          maskSendingFailed: "屏蔽发送失败",
        },
        component: {
          conversation: "通话",
          dialogue: "对话",
          weCom: "企业微信",
          day: "日",
          week: "周",
          month: "月",
          year: "年",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "清理范围",
            dataCleaningTimeSetting: "数据清理时间设置",
            afterCleanUpTheData: "后，清理数据",
            dataAfterComplaintCompletion: "申诉完成后数据",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "执行成功",
          saveSuccessful: "保存成功",
          speechRecognition: "语音识别",
          reExecuteErrorCorrection: "重新执行纠错",
          confirmExecution: "确认执行",
          voiceDetection: "语音检测",
          noiseDetectionPowerLevel: "噪音检测 powerLevel ≤",
          pleaseEnterTheThreshold: "请输入阈值",
          volumeDetectionPowerLevel: "音量检测 powerLevel ≥",
          reset: "重置",
          preserve: "保存",
        },
        coachTemplate: {
          french: "法语",
          spanish: "西班牙语",
          japanese: "日语",
          thai: "泰语",
          loginPopupWindow: "登录弹窗",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "开启后会在进入智能陪练系统时进行弹窗提示",
          title: "标题",
          text: "正文",
          checkBoxContent: "勾选框内容",
          informedConsentForm: "知情同意书",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "为帮助您提高沟通和话术技能以及专业知识的应用，允许您随时随地进行练习并接受实时反馈，我们将通过本平台，收集您的以下信息：",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "本人已经完整阅读并充分理解本知情同意书，并郑重声明同意根据本知情同意书所述的目的和方式处理我的敏感个人信息",
        },
      },
      systemIntegration: {
        title: "系统设置",
        ccIntegrationTips: "智能质检对接Udesk呼叫中心说明文档",
        unauthorized: "未授权",
        authorized: "已授权",
        udeskDomain: "客服系统域名",
        udeskToken: "客服系统密钥",
        udeskAdminEmail: "客服系统管理员帐号",
        password: "管理员帐号密码",
        applyAuth: "授权",
        cancelAuth: "取消授权",
        reApplyAuth: "重新授权",
        keyTips: "客服系统后台管理-单点登录中的API鉴权Token",
      },
      customerField: {
        title: "客户字段",
        table: {
          propName: "字段名称",
          propDesc: "字段描述",
          valueType: "字段类型",
          propType: "创建方式",
          createTime: "创建时间",
          canNotEdit: "系统字段不支持编辑",
        },
        edit: "编辑字段",
        new: "新建字段",
      },
      wechatDocking: {
        title: "企微对接",
        formLabels: {
          companyId: "企业ID",
          communicationKey: "通讯密钥",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "事件回调URL",
          applicationName: "应用名称",
          applicationId: "网页应用ID",
          applicationSecret: "应用secret",
        },
        formTitle: {
          initialForm: "初始设置",
          synchronizeForm: "通讯录同步",
          settingForm: "应用设置",
        },
        qrCodeUrlTip: "请管理员扫描下方二维码进行代开发应用绑定",
      },
      taobao: {
        formLabels: {
          name: "门店名称",
          account: "账号",
          password: "密码",
          confirm: "确认密码",
          authorization: "授权",
          switchOn: "开启",
          switchOff: "关闭",
        },
        tableLabels: {
          name: "门店名称",
          account: "账号",
          authorization: "授权",
          updateUser: "最近更新人",
          updateTime: "最近更新时间",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "微丰二级域名",
          secretKeyTip: "租户秘钥，在微丰系统后台系统对接处获取",
        },
      },
      user: {
        index: {
          name: "员工管理",
          existingUsers: "已有员工",
          deleteSuccess: "删除成功",
          deleteFailed: "删除失败",
        },
        detail: {
          accountPrompt: "请输入邮箱",
          resetPassword: "重置密码",
          passwordPrompt: "密码（6-14位字母与数字组合）",
          originPasswordPrompt: "请输入初始密码",
          resumePasswordPrompt: "请重新输入密码",
          passwordFormWrong: "请输入6-14位字母与数字组合的密码！",
          resumePasswordWrong: "两次密码不一致！",
          suppertImageType: "支持jpg、gif、 png ，最大 500Kb",
          namePrompt: "请输入姓名",
          nickNamePrompt: "请输入昵称",
          telephonePrompt: "请输入电话号码",
          roleSelectedPrompt: "请选择角色",
          agentIdPrompt: "请输入工号",
          disabled: "禁用",
          enabled: "启用",
          reloadBowerInfo: "用户信息变更，需要重新刷新浏览器吗？",
          saveUserError: "用户信息保存失败，请稍后重试",
          resetPasswordSuccess: "密码重置成功",
          resetPasswordFailed: "密码重置失败，请稍后重试",
        },
      },
      userGroups: {
        index: { name: "员工组管理", existingUserGroups: "已有员工组" },
        detail: {},
      },
      role: {
        index: { name: "角色管理", existingRoles: "已有角色" },
        edit: {
          editRole: "编辑角色",
          createRole: "创建角色",
          basicInfo: "基本信息",
          taskPermissions: "任务权限",
          existingTasks: "已有任务",
        },
      },
      anomalousData: {
        title: "异常数据",
        list: {
          selectHolder: "请选择数据源",
          businessTime: "业务时间",
          businessId: "业务Id",
          export: "导出",
        },
      },
      templateComponent: {
        list: {
          title: "模板市场",
          name: "模板名称",
          searchHolder: "请输入要查询的内容",
          update: "更新情况",
          sort: "排序方式",
          moduleType: "模板类型",
          moduleSourceType: "适用类型",
          fetchNum: "获取次数",
          newest: "最近更新",
          usedMost: "获取最多",
          call: "通话",
          dialogue: "对话",
          waiting: "待审核",
          cancel: "取消发布",
          success: "审核通过，已发布",
          failed: "审核驳回",
          no: "不可升级",
          ok: "可以升级",
          all: "全部",
          delete: "删除成功",
          downOk: "下架成功",
          withdraw: "撤回成功",
          updateOk: "升级成功",
          get: "获取",
          width: "撤回",
          getOk: "获取成功",
          orgName: "所属组织",
          nowVersion: "当前版本",
          lastedVersion: "最新版本",
          appStatus: "应用状态",
          actions: "操作",
          down: "下架",
          updateTop: "升级",
          check: "查看",
          deleteWhen: "确认删除吗？",
          yes: "是",
          noo: "否",
          deleteOk: "删除",
          permissible: "允许",
          noPermissible: "不允许",
          openUp: "开放",
          openDown: "不开放",
          task: "质检任务",
          point: "质检点",
          tag: "语义标签",
          muti: "多要素",
          inter: "一问一答",
          info: "信息实体",
          concept: "概念词",
          detail: "模组详情",
          infomation: "基本信息",
          detailConfig: "详情配置",
          twoEdit: "二次编辑",
          mustUpdate: "立即升级",
          descition: "模板描述",
          descitionSize: "模板描述不能超过250个字",
          stencilsDetail: "模板详情",
          updatedRecords: "更新记录",
          version: "版本号",
          updateDescription: "更新描述",
          myModule: "我的模板",
          addModule: "新建模组",
          publishVersion: "发布版本",
          earliestVersion: "最早版本",
          edit: "编辑",
          add: "新增",
          configNo: "模组配置详情不能为空",
          updateNo: "更新说明不能为空",
          editSuccess: "修改成功",
          saveSuccess: "保存成功",
          newer: "更新",
          sumbit: "提交",
          cancelName: "取消",
          nameRequired: "模组名称是必填字段",
          nameSize: "模组名称不能超过16个字",
          publishStatus: "发布状态",
          submitUpdate: "提交更新",
          updateVersion: "更新版本",
          updateExplain: "更新说明",
        },
      },
      customDataSource: {
        amazonEcommerce: "亚马逊电商",
        youtubeVideo: "Youtube视频",
        amazon: {
          component: {
            queuing: "排队中",
            notStarted: "未开始",
            inProgress: "进行中",
            success: "成功",
            failed: "失败",
            successfullyCreated: "创建成功",
            editedSuccessfully: "编辑成功",
            operationSucceeded: "操作成功",
            deletedSuccessfully: "删除成功",
            link: "链接",
            grabState: "抓取状态",
            updateTime: "更新时间",
            grab: "抓取",
            edit: "编辑",
          },
          template: {
            amazonEcommerce: "亚马逊电商",
            thisProduct: "本品",
            competition: "竞品",
            new: "新建",
            edit: "编辑",
            link: "链接",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube视频",
            thisProduct: "本品",
            competition: "竞品",
            new: "新建",
            edit: "编辑",
            link: "链接",
          },
          component: {
            queuing: "排队中",
            notStarted: "未开始",
            inProgress: "进行中",
            success: "成功",
            failed: "失败",
            successfullyCreated: "创建成功",
            editedSuccessfully: "编辑成功",
            operationSucceeded: "操作成功",
            deletedSuccessfully: "删除成功",
            youTubeVideoLink: "YouTube视频链接",
            grabState: "抓取状态",
            updateTime: "更新时间",
            grab: "抓取",
            edit: "编辑",
          },
        },
      },
    },
    demo: {
      title: "智能质检",
      template: "选择质检模板",
      radio: "选择录音形式",
      uploadAudio: "上传录音",
      uploadAudioTip: "上传录音仅支持wav和mp3格式，大小不超过1G",
      uploadText: "上传文本",
      uploadTextTip:
        "上传文本仅支持srt和txt格式，大小不超过10M，上传文本后将不再使用ASR解析录音文件",
      uploadSuccess: "录音文件上传成功",
      uploadFailed: "录音文件上传失败",
      startQualityInspection: "开始质检",
      qualityInspectionStarted: "正在质检中",
      downloadText: "文本下载",
      checking: "质检进度",
      checkingTip: "预计需要数分钟，请耐心等待",
      noCheckContent: "尚无质检结果",
      noTextDownload: "暂无质检文本可下载!",
      pleaseCheckParams: "请检查是否选择模板、语音类型和上传录音文件",
      inspectionFailed: "质检失败，请稍后重试!",
    },
    wechatQa: {
      baseInfo: { title: "基本信息" },
      conversationSetting: {
        title: "会话设置",
        segmentationStrategy: "切分策略",
        rule: "策略",
        time: "切分时间",
        daily: "每天",
        passScore: "会话合格分",
        ruleLogic: "执行逻辑",
      },
      message: {
        title: "推送事件",
        table: {
          index: "序号",
          name: "名称",
          type: "推送方式",
          desc: "描述",
          status: "状态",
        },
        subTitle: "事件列表",
      },
      checkPoint: {
        title: "分析模型",
        groups: "模型分组",
        newClassification: "新建分组",
        editClassification: "编辑分组",
        benchmarkScore: "基准分",
        pointCount: "当前分类下共有分析模型",
        openPointCount: "启用数量为",
        closedPointCount: "停用数量为",
        scoreLowerLimit: "最低得分下限",
        scoreUpperLimit: "最高得分上限",
        classificationName: "分组名称",
        classificationNamePlaceHolder: "请输入分组名称",
        classificationRemark: "分组描述",
        classificationRemarkPlaceHolder: "请输入分组描述",
      },
      chartRecord: {
        title: "企业微信会话",
        table: {
          id: "会话ID",
          type: "会话类型",
          startTime: "会话开始时间",
          endTime: "会话最近结束时间",
          groupId: "群ID",
          client: "客户",
          customer: "客服",
          averageScore: "平均质检评分",
        },
        detail: {
          title: "企业微信会话详情",
          inspectionInfo: "分析信息",
          chartInfo: "会话信息",
          tasks: "分析任务",
          avgScore: "分析平均分",
          qualified: "合格",
          unqualified: "不合格",
          taskTitle: "适用分析模板",
          info: "实时分析信息",
          today: "今日消息",
          history: "历史消息",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "请输入备注/昵称",
                pleaseEnterTheGroupChatName: "请输入群聊名称",
              },
            },
          },
          template: { whole: "全部" },
        },
      },
      realTimeAnalysis: {
        title: "实时分析",
        table: {
          name: "场景名称",
          count: "分析模型数量",
          baseScore: "基准分",
          passScore: "合格分",
          maxScore: "最高得分上限",
          minScore: "最低得分下限",
          status: "状态",
          actions: "操作",
        },
        detail: {
          title: "实时分析场景",
          form: {
            name: "场景名称",
            prevCondition: "场景触发条件",
            prevConditionToolTip: "若条件为空，则默认对此场景进行分析",
            prevConditionError: "场景触发条件不能与分析模型重合",
            scoreInputPlaceholder: "输入分数",
            baseScore: "基准分",
            status: "状态",
            passScore: "合格分",
            maxScore: "最高分上限",
            minScore: "最低分下限",
            analysisModel: "分析模型",
            message: "触发通知",
            pushType: "推送方式",
            createAnalysisModel: "添加模型",
            scoreUpperLimitError: "最低分下限不能大于最高分上限",
            table: { name: "模型名称", rule: "规则", score: "分值" },
            pushConfigSwitchTip1: "当该场景出现",
            pushConfigSwitchTip2: "次及以上时，推送通知",
          },
        },
      },
      smartCustomerTag: {
        title: "智能客户标签",
        table: {
          name: "规则名称",
          tags: "企微客户标签",
          status: "启用状态",
          createUser: "创建人",
          createTime: "创建时间",
        },
        detail: {
          name: "规则名称",
          condition: "设置规则",
          logic: "逻辑",
          status: "启用状态",
          tags: "选择标签",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "客户标记数量" },
        },
        messageFiltering: {
          template: {
            edit: "编辑",
            messageContent: "消息内容",
            messageTypeID: "消息类型ID",
            messageTypeDescription: "消息类型描述",
            messageFiltering: "消息过滤",
            suitableForFilteringDataWithoutQualityInspection:
              "适用于过滤无需质检的数据",
            create: "创建",
            messageDescription: "消息描述",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "通话时间",
          sessionType: "会话类型",
          whole: "全部",
          staff: "员工",
          customer: "客户",
          callKeywords: "通话关键词",
        },
        detail: {
          template: { enterpriseMicroVoiceCallDetails: "企微语音通话详情" },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "会话存档",
        voiceCall: "语音通话",
        noMoreVoiceCalls: "暂无更多语音通话",
        enterpriseWechatVoiceCall: "企业微信语音通话",
        enterpriseWechatConversationDetails: "企业微信会话详情",
        previous: "上一条",
        next: "下一条",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask: "企微会话分析任务",
          enterpriseAndMicroVoiceAnalysisTask: "企微语音分析任务",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat: "企业微信群聊分析",
      },
      wechatQaSettingEntry: { messageFiltering: "消息过滤" },
    },
    businessAnalysis: {
      keyword: "报表名称",
      createUser: "创建人",
      createTime: "创建时间",
      updateTime: "更新时间",
    },
    semanticIntelligence: {
      applicationMessageType: "应用消息类型",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "请完善配置",
              generatePromptLanguage: "生成提示语",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "是否符合",
              gradedEvaluation: "分档评价",
            },
          },
          training: {
            index: {
              pleaseEnter: "请输入",
              pleaseGeneratePrompt: "请生成提示语",
              pleaseSelectADialogue: "请选择对话",
              training: "训练",
              dataType: "数据类型：",
              voiceCall: "语音通话",
              textDialogue: "文本对话",
              generate: "生成",
              qualityInspectionResults: "质检结果：",
              hit: "命中话术：",
            },
          },
        },
        detail: {
          index: {
            modelName: "模型名称",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "请输入模型名称，例如：客服是否存在无作为的情况",
            modelDescription: "模型描述",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "可详细描述您需要检测的内容，便于模型理解，如：客服是否存在无作为的情况，即针对客户的提问客服有回复，但客户问题通篇没有得到处理或回复内容与客人回复无关",
            validity: "有效性",
            effective: "有效",
            invalid: "无效",
            type: "类型",
            hintLanguage: "提示语",
            editIntelligentModel: "编辑智能模型",
            createANewIntelligentModel: "新建智能模型",
          },
        },
        index: {
          modelName: "模型名称",
          modelDescription: "模型描述",
          type: "类型",
          whetherItMeetsTheRequirements: "是否符合",
          gradedEvaluation: "分档评价",
          validity: "有效性",
          intelligentModel: "智能模型",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "适用于主观项质检（如质检服务态度是否热情、服务是否耐心等场景）",
        },
      },
      clusterAnalysis: "聚类分析",
      speechMining: "话术挖掘",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "支持通过API接口批量导入信息实体，该API名称保存后不支持编辑",
        },
        component: { nothing: "无" },
      },
      knowledgeBase: {
        index: {
          classification: "分类",
          import: "导入",
          importKnowledgeBase: "导入知识库",
          moveKnowledgePointsInBatch: "批量移动知识点",
          moveTo: "移动到：",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "请选择需要批量移动的知识点",
        },
        detail: { index: { intrasentence: "句内", afterProblem: "问题后" } },
      },
      semanticTags: { component: { nothing: "无" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "敏感词",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "敏感词：敏感词可配置业务关注词，用于舆情发现敏感词词云",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "新建敏感词",
            editSensitiveWords: "编辑敏感词",
            sensitiveWords: "敏感词",
          },
          template: {
            sensitiveWords: "敏感词",
            sensitiveWordsCannotBeEmpty: "敏感词不能为空",
            pleaseEnterSensitiveWordsSeparatedBy:
              "请输入敏感词，多个敏感词中间用‘,’隔开",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "请选择应用场景",
            keyWord: "关键词",
            pleaseEnterKeywords: "请输入关键词",
            keywordLengthWithinCharacters: "关键词长度40字符内",
            dataSources: "数据来源",
            pleaseSelectADataSource: "请选择数据来源",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "当前聚类已完成，重新编辑将产生一条新的聚类数据，不会覆盖已完成的聚类数据，请确认",
            savingSucceeded: "保存成功",
            clusteringStart: "聚类开始",
            clustering: "聚类",
            edit: "编辑",
            newlyBuild: "新建",
            clusterName: "聚类名称",
            pleaseEnterAName: "请输入名称",
            withinCharactersOfTheName: "名称长度40字符内",
            speaker: "说话人",
            pleaseSelectASpeaker: "请选择说话人",
            sessionDate: "会话日期",
            pleaseEnterTheSessionDate: "请输入会话日期",
            selectUpToMonthOfHistoricalSessionData:
              "最多选择1个月的历史会话数据",
            automaticallyClusterDailyNewData: "自动聚类每日新增数据",
            conversationChannel: "会话渠道",
            pleaseSelectAConversationChannel: "请选择会话渠道",
            applicationScenario: "应用场景",
            clusteringRules: "聚类规则",
            incompleteClusteringRuleSettings: "聚类规则设置不完整",
            startClustering: "开始聚类",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask: "请选择质检任务",
              beEqualTo: "等于",
            },
          },
        },
        detail: {
          index: {
            theme: "主题",
            numberOfCorpora: "语料数",
            corpusCoverage: "语料覆盖",
            clusterDetails: "聚类详情",
          },
          ranking: {
            hotWords: "热词",
            numberOfCorpora: "语料数",
            speaker: "说话人",
            sit: "坐",
            passenger: "客",
            hotSentence: "热句",
            corpusDetails: "语料详情",
          },
        },
        index: {
          delete: "删除",
          deletionSucceeded: "删除成功",
          clusterName: "聚类名称",
          speaker: "说话人",
          creationTime: "创建时间",
          state: "状态",
          operation: "操作",
          see: "查看",
          edit: "编辑",
          clusterAnalysis: "聚类分析",
          newCluster: "新建聚类",
          clusteringStatus: "聚类状态",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections: "输入关键词搜索异议",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "话术库",
                recommendedScripts: "推荐话术",
                common: "共",
                strip: "条",
                scriptFavorites: "话术收藏夹",
              },
              conditionPostfix: {
                detectRoles: "检测角色",
                detectionRange: "检测范围",
                sentence: "句",
              },
              customerFilter: { scriptFiltering: "话术过滤", cancel: "取消" },
              index: {
                viewDetails: "查看详情",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "您确认要舍弃吗？舍弃后将不能再恢复",
                abandonment: "舍弃",
                deletionSucceeded: "删除成功",
                passenger: "客",
                sit: "坐",
                adopted: "已采纳",
                determine: "确定",
                cancel: "取消",
                adopt: "采纳",
              },
              pageRightFrom: {
                savingSucceeded: "保存成功",
                pleaseEnterTheScriptContent: "请输入话术内容",
                pleaseSelectAFavoriteGroup: "请选择收藏夹分组",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary: "异议处理话术库",
              },
              pageRightTable: {
                index: { pleaseChooseToAdoptScript: "请选择采纳话术" },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "异议处理话术收藏夹",
            },
            pageRightTable: {
              likeCancel: "点赞取消",
              likeSuccessfully: "点赞成功",
              adoptScripts: "采纳话术",
              operation: "操作",
              viewConversationDetails: "查看对话详情",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "产品卖点话术库",
              },
              pageRightTable: { keyPointsOfScript: "话术要点" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "异议处理话术收藏夹",
              newGroup: "新建分组",
              editGroup: "编辑分组",
              addGroup: "添加分组",
              savingSucceeded: "保存成功",
              groupName: "分组名称",
              pleaseEnterTheGroupName: "请输入分组名称",
              groupNameWithinCharacters: "分组名称长度20字符内",
              deletionSucceeded: "删除成功",
            },
            pageRightTable: {
              configDrawer: {
                index: { scriptRecommendationSettings: "话术推荐设置" },
                rightForm: {
                  savingSucceeded: "保存成功",
                  intelligentRecommendation: "智能推荐",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "将已采纳的优秀话术自动显示在相似异议的会话侧边栏，员工在会话复盘详情页可直接查看推荐话术进行学习",
                  objectionsIncludedByDefault: "默认包含的异议",
                  addRecommendationTriggerSemantics: "增加推荐触发语义",
                  remindEmployeesToView: "提醒员工查看",
                  shareWithMoreEmployees: "分享给更多员工",
                  allHands: "全体员工",
                },
                smartToolbar: { selectSemanticTag: "选择语义标签" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "是否导出当前分组下所有的数据记录？",
                newScript: "新建话术",
                editingScript: "编辑话术",
                savingSucceeded: "保存成功",
                objection: "异议",
                pleaseEnterAScriptObjection: "请输入话术异议",
                speechTechnique: "话术",
                pleaseEnterTheScriptContent: "请输入话术内容",
                grouping: "分组",
                pleaseSelectAFavoriteGroup: "请选择收藏夹分组",
                adoptScripts: "采纳话术",
                passenger: "客",
                sit: "坐",
                numberOfViews: "查看次数",
                useful: "有用",
                operation: "操作",
                edit: "编辑",
                delete: "删除",
                deletionSucceeded: "删除成功",
                scriptRecommendationSettings: "话术推荐设置",
              },
            },
          },
        },
        index: {
          scriptLibrary: "话术库",
          scriptMining: "话术挖掘",
          objectionHandlingScripts: "异议处理话术",
          productSellingPointScripts: "产品卖点话术",
        },
        sellingPoint: {
          index: { newCluster: "新建聚类" },
          components: {
            callDetailModal: {
              sessionDetails: "会话详情",
              cancel: "取消",
              determine: "确定",
              choice: "选择",
            },
            pageCenterTable: { scriptOverview: "话术概况" },
            pageLeftTable: {
              enterSearchKeywords: "输入搜索关键词",
              keyWord: "关键词",
            },
            pageRightTable: {
              index: {
                viewDetails: "查看详情",
                scriptLibrary: "话术库",
                scriptRefinement: "话术提炼",
                adoptScript: "采纳话术",
              },
              tagsEdit: { keyPointsOfScript: "话术要点" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "产品卖点话术库",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: { defaultIncludedSellingPoints: "默认包含的卖点" },
                },
                index: {
                  keyWord: "关键词",
                  pleaseSelectAKeyword: "请选择关键词",
                  keyPointsOfScript: "话术要点",
                  pleaseInputTheKeyPointsOfTheScript: "请输入话术要点",
                  viewDetails: "查看详情",
                  pleaseChooseToAdoptScript: "请选择采纳话术",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "匹配逻辑",
            nLPMatching: "NLP匹配",
            strictlyMatchByWord: "按词严格匹配",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "消息类型名称",
          messageTypeID: "消息类型ID",
          messageTypeDescription: "消息类型描述",
          applicationMessageType: "应用消息类型",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "适用于接入不同渠道数据进行质检，需依据渠道标识进行分类",
        },
        detail: {
          index: {
            messageTypeName: "消息类型名称",
            pleaseEnterAMessageTypeName: "请输入消息类型名称",
            messageTypeDescription: "消息类型描述",
            describeYourMessageTypeInDetail: "可详细描述您的消息类型",
            editMessageType: "编辑消息类型",
            newMessageType: "新建消息类型",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "请选择团队成员",
        existingFilter: "已有过滤器",
        pleaseSelect: "请选择",
        filterCondition: "过滤条件",
        areYouSureToDeleteTheCurrentFilter: "是否确认删除当前过滤器？",
        sessionDate: "会话日期",
        staff: "员工",
        customer: "客户",
        pleaseEnterCustomerName: "请输入客户名称",
        keyWord: "关键词",
        whole: "全部",
        pleaseEnterSessionKeywords: "请输入会话关键词",
        sessionDuration: "会话时长",
        sessionLabel: "会话场景",
        pleaseSelectASessionLabel: "请选择会话场景",
        commentTopic: "评论话题",
        pleaseSelectACommentTopic: "请选择评论话题",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "是否确认覆盖当前过滤器？",
        overlayFilter: "覆盖过滤器",
        NewFilter: "+ 新建过滤器",
        newFilter: "新建过滤器",
        filterName: "过滤器名称",
        pleaseEnter: "请输入",
        allCall: "所有通话",
        percentage: "百分比",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "支持搜索多个关键词，“或”关系的用逗号（中英文）隔开，“且”关系的用顿号隔开，如：",
          callRecordID: "通话记录ID",
          pleaseFillInTheCallRecordID: "请填写通话记录ID",
          minute: "分钟",
          second: "秒",
          whole: "全部",
          callRecording: "通话录音",
          enterpriseWechat: "企业微信",
          conversationChannel: "会话渠道",
          pleaseSelectASessionChannel: "请选择会话渠道",
          learningStatus: "学习状态",
          pleaseSelectLearningStatus: "请选择学习状态",
          notLearned: "未学习",
          learned: "已学习",
          Minutes: "分钟",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "过滤条件为空，不支持新建过滤器！",
          filterAddedSuccessfully: "过滤器新增成功！",
          failedToAddFilter: "过滤器新增失败！",
          filterDeletedSuccessfully: "过滤器删除成功！",
          filterDeletionFailed: "过滤器删除失败！",
          filterOverwriteSucceeded: "过滤器覆盖成功！",
          filterOverwriteFailed: "过滤器覆盖失败！",
          theme: "主题",
          pleaseSelectATheme: "请选择主题",
          keyEvents: "关键事件",
          pleaseSelectAKeyEvent: "请选择关键事件",
        },
        filterItem: {
          startRetesting: "开始重新检测",
          time: "时间",
          talkTime: "通话时长",
          keyEvents: "关键事件",
          totalImplementationRateOfSupervisionItems: "监督项总执行率",
          sort: "排序：",
          seeMore: "查看更多",
        },
        filterStatistics: {
          conversation: "通话",
          weChat: "微信",
          quantity: "数量",
        },
      },
      caseBase: {
        index: {
          copyTo: "复制到",
          pleaseSelectTheLocationToMove: "请选择移动的位置！",
          folderCopiedSuccessfully: "文件夹复制成功！",
          cancelCollectionSuccessfully: "取消收藏成功！",
          collectionSucceeded: "收藏成功！",
          starCaseBase: "星标案例库",
          move: "移动",
          rename: "重命名",
          delete: "删除",
          folderMovedSuccessfully: "文件夹移动成功！",
          fileMovedSuccessfully: "文件移动成功！",
          fileMoveFailed: "文件移动失败！",
          folderAddedSuccessfully: "添加文件夹成功！",
          folderRenameSucceeded: "文件夹重命名成功！",
          fileRenameSucceeded: "文件重命名成功！",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "删除文件夹后将无法找回，您确认删除吗？",
          deleteSucceeded: "删除成功！",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "删除文件后将无法找回，您确认删除吗？",
          share: "共有",
          folders: "个文件夹，",
          calls: "个通话",
          newFolder: "新增文件夹",
          modifyFolder: "修改文件夹",
          title: "标题",
          pleaseEnter: "请输入",
          selectMoveLocation: "选择移动位置",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "学习提醒",
          subscriptionDynamics: "订阅动态",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "你订阅的会话（ID",
            thereAreDynamicUpdates: "有动态更新啦",
            newCommentsReplies: "新增评论/回复",
            strip: "条",
            newHelp: "新增求助",
            goAndWatch: "去围观",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "回复了我的评论",
            replySucceeded: "回复成功！",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "在评论中@了我",
            replySucceeded: "回复成功！",
          },
        },
        share: {
          index: {
            shareCallsForYou: "为你通话分享",
            shareCasesForYou: "为你分享案例",
          },
        },
        study: {
          index: {
            learned: "已学习",
            ignored: "已忽略",
            study: "学习",
            ignore: "忽略",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "导入成功",
          account: "账号",
          password: "密码",
          cover: "覆盖",
          skip: "跳过",
          import: "导入",
          importEmployees: "导入员工",
          importPrompt: "导入提示",
          confirm: "确定",
          theDataContainsDuplicateAccountsOrNames: "条数据存在账号或姓名重复",
          allCoverage: "全部覆盖",
          skipAll: "全部跳过",
          headPortrait: "头像",
          fullName: "姓名",
          nickname: "昵称",
          excellentStaff: "优秀员工",
          yes: "是",
          no: "否",
          department: "部门",
          accountNumberemail: "账号（邮箱）",
          telephone: "电话",
          jobNumber: "工号",
          role: "角色",
          pleaseEnterANameOrPhoneSearch: "请输入邮箱、姓名或电话搜索",
          person: "人）",
          AddEmployee: "+ 添加员工",
        },
        edit: {
          jobNo: "工号",
          udeskCustomerServiceSystemIdentity: "Udesk客服系统身份",
          enterpriseWechatIdentity: "企业微信身份",
          inputID: "输入标识",
          newIdentityBinding: "新增身份绑定",
          identityBinding: "身份绑定",
          avatarGreaterThan500kb: "头像大于500Kb",
          uploadSuccessful: "上传成功",
          upload: "上传",
          supportJPGGIFAndPNGUpTo500kb: "支持jpg、gif、png，最大500Kb",
          yes: "是",
          no: "否",
          accountNumber: "账号",
          pleaseEnterTheAccountNumber: "请输入账号",
          initialPassword: "初始密码",
          pleaseInputAPassword: "请输入密码",
          confirmPassword: "确认密码",
          thePasswordsEnteredTwiceAreDifferent: "两次输入的密码不相同!",
          headPortrait: "头像",
          fullName: "姓名",
          pleaseEnterYourName: "请输入姓名",
          nickname: "昵称",
          department: "部门",
          excellentStaff: "优秀员工",
          sessionIdentity: "会话身份标识",
          markTheSessionRecordAsBelongingToTheEmployee:
            "标记会话记录归属于该员工",
          telephone: "电话",
          jobNumber: "工号",
          role: "角色",
          pleaseSelectARole: "请选择角色",
          preservation: "保存",
          cancel: "取消",
          addedSuccessfully: "添加成功！",
          modificationSucceeded: "修改成功！",
          newEmployees: "新增员工",
          editEmployee: "编辑员工",
        },
        detail: {
          viewDetails: "查看详情",
          accountNumber: "账号",
          headPortrait: "头像",
          fullName: "姓名",
          nickname: "昵称",
          department: "部门",
          excellentStaff: "优秀员工",
          yes: "是",
          no: "否",
          sessionIdentity: "会话身份标识",
          markTheSessionRecordAsBelongingToTheEmployee:
            "标记会话记录归属于该员工",
          telephone: "电话",
          jobNumber: "工号",
          role: "角色",
        },
      },
      roleManage: {
        list: {
          menu: "菜单",
          functionalCategory: "功能大类",
          functionalSubclass: "功能小类",
          deletedSuccessfully: "删除成功",
          NewRole: "+ 新建角色",
          role: "角色",
          dataPermissions: "数据权限：",
          edit: "编辑",
          areYouSureYouWantToDeleteTheRole: "确定删除角色",
          determine: "确定",
          cancel: "取消",
          delete: "删除",
          applicationPermission: "应用权限",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData: "只能看到自己的会话数据",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "可以查看本部门及下属部门的会话数据",
          menu: "菜单",
          functionalCategory: "功能大类",
          functionalSubclass: "功能小类",
          successfullyAdded: "新增成功",
          modifiedSuccessfully: "修改成功",
          edit: "编辑",
          cancel: "取消",
          confirm: "确认",
          roleName: "角色名称",
          pleaseEnterTheRoleName: "请输入角色名称",
          dataAuthority: "数据权限",
          youCanViewCompanyWideSessionData: "可以查看全公司的会话数据",
          applicationPermission: "应用权限",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "会话流程",
          generateProcess: "生成流程",
          addProcess: "添加流程",
          cancel: "取消",
          complete: "完成",
          edit: "编辑",
          theSettingWillBeSuccessfulLater: "设置稍后看成功！",
          previous: "上一条",
          next: "下一条",
          debuggingMode: "调试模式",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "开通后消息如果匹配关键事件、监督项、客户标签的规则或者知识点规则， 则会显示消息匹配到的规则名称，以及知识库的知识点名称。",
          seeLater: "稍后看",
          determine: "确定",
          conversationalInsights: "会话洞察",
          customerInsight: "客户洞察",
          knowledgePointRecommendation: "知识点推荐",
          comment: "评论",
          sessionQuality: "会话质量",
          setToListenLaterSuccessfully: "设置稍后听成功！",
          listenLater: "稍后听",
          seekCounselingSuccess: "寻求辅导成功！",
          altogether: "一共有",
          appearsAt: "处出现 '",
          commentsPublishedSuccessfully: "评论发表成功",
          callRecording: "通话录音",
          addedSuccessfully: "添加成功",
          shareWithColleagues: "分享给同事",
          shareWithCustomers: "分享给客户",
          resumptionDetails: "复盘详情",
          addToCaseBase: "添加至案例库",
          shareCases: "分享案例",
          seekCounseling: "寻求辅导",
          sessionRecord: "会话记录",
          businessRecord: "业务记录",
          keywordSearch: "关键词搜索",
          whole: "全部",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "请输入评论，输入@以通知其他人，输入#以添加话题",
          subscriptionSession: "订阅会话",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "订阅后，当有新的求助、评论、回复会收到通知提醒",
        },
      },
      coachTask: {
        index: {
          employeeName: "员工姓名",
          customerName: "客户姓名",
          source: "来源",
          viewSessionRecords: "查看会话记录",
          coachingStatus: "辅导状态",
          helpTime: "求助时间",
          counselingTime: "辅导时间",
          helpContent: "求助内容",
          coachingOperation: "辅导操作",
          areYouSureYouWantToIgnoreIt: "确定忽略吗？",
          determine: "确定",
          cancel: "取消",
          ignoreHelp: "忽略求助",
          coaching: "进行辅导",
          viewCoaching: "查看辅导",
          areYouSureToReturnToCounseling: "确定恢复至待辅导吗？",
          returnToPendingCoaching: "恢复至待辅导",
          tutor: "辅导人",
          helpSeekingStatus: "求助状态",
          recentCoachingTime: "最近辅导时间",
          operation: "操作",
          ignoreSuccess: "忽略成功！",
          recoverySucceeded: "恢复成功！",
          successfulCoaching: "辅导成功！",
          myMission: "我的任务",
          selectCoachingStatus: "选择辅导状态",
          whole: "全部",
          iAskedForHelp: "我求助的",
          signOut: "退出",
          submit: "提交",
          helpPerson: "求助人",
          customer: "客户",
          toBeCoached: "待辅导",
          counselingSuggestions: "辅导建议",
          pleaseEnterCoachingSuggestions: "请输入辅导建议",
        },
      },
      coachTaskCenter: {
        title: "任务中心",
        list: {
          taskName: "任务名称",
          lessonGroup: "课程分类",
          lessonName: "所属课程",
          taskType: "任务类型",
          taskStatus: "任务状态",
          taskExecutionInfo: "执行情况",
          totalCount: "应执行人数",
          executeCount: "已执行人数",
          executeRate: "执行率",
          completeCount: "完成人数",
          completeRate: "完成率",
          commitCount: "达标人数",
          commitRate: "达标率",
          excellentRate: "达优率",
          taskTime: "任务时间",
          option: "操作",
          edit: "编辑",
          taskDetail: "任务数据",
          delete: "删除",
          new: "新建发布",
          taskTypeAll: "全部",
          taskTypePractice: "练习",
          taskTypeTest: "考试",
          taskStatusNotStarted: "未开始",
          taskStatusRunning: "进行中",
          taskStatusEnd: "已结束",
          taskStatusInvalid: "未生效",
          unlimitedTime: "不限时间",
          to: "至",
          deleteConfirm: "删除后此任务下所有学习记录将被清空，是否确认删除？",
          deleteSuccess: "任务删除成功",
        },
        edit: {
          addTask: "新建发布任务",
          editTask: "编辑发布任务",
          publishSuccess: "发布成功",
          publishFailed: "发布失败",
          updateSuccess: "更新成功",
          updateFailed: "更新失败",
          cannotBeNull: "不能为空",
          explain: "说明",
          taskDescExtra: "（此处内容将在{0}入口处展示）",
          taskTargetObj: "发布对象",
          isEnable: "生效状态",
          dialogueMode: "对话模式",
          voice: "语音",
          text: "文本",
          userPortrait: "用户画像",
          difficultyLevel: "难度",
          limitDuration: "时长限制",
          tipLabel: "话术提示",
          tipTooltip: "是否允许学员查看参考话术",
          limitTimes: "考试次数",
          time: "时间",
          gradingStandart: "评分标准",
          gradingStandartDescAffix: "（当前对话流程总分为",
          gradingStandartDescSuffix: "分，话术{0}分，情绪{1}分，语速{2}分）",
          completeStandart: "任务完成要求",
          byTimes: "按次数",
          byScore: "按得分",
          completeByTimes: "练习次数",
          times: "次",
          score: "分",
          anyTimes: "任意一次",
          getScore: "得分",
          offLimitDuration: "不限时长",
          onLimitDuration: "限定时长",
          commitScore: "达标标准，得分",
          excellentScore: "优秀标准，得分",
          offLimitTime: "不限时间",
          onLimitTime: "限定时间",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "嗨，" },
          callDynamic: { today: "今日", yesterday: "昨日", sessions: "个会话" },
          todoList: {
            strip: "条",
            theSessionIsAddedTolistenLater: "会话被加入“稍后听”",
            askYouForHelp: "向你求助",
            iHopeToContactYouLater: "期望稍后联系",
            lookingForwardToSendingTheInformationLater: "期望稍后发送资料",
            dialogueRisk: "会话存在风险点",
            tutored: "已辅导",
            goToCounseling: "去辅导",
            contacted: "已联系",
            hasBeenSent: "已发送",
            areYouSureYouWantToIgnoreIt: "确定忽略吗？",
            determine: "确定",
            cancel: "取消",
            ignore: "忽略",
            checked: "已查看",
          },
          callListItem: {
            strip: "条",
            learned: "已学习",
            keyEvents: "关键事件",
            totalImplementationRateOfSupervisionItems: "监督项总执行率",
          },
        },
        index: {
          operationSucceeded: "操作成功！",
          sessionDynamics: "会话动态",
          my: "我的",
          department: "部门",
          company: "公司",
          toDoList: "待办事项",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "有效通话量（个）",
          bestPractices: "最佳实践",
          averageDurationOfASingleCallminutes: "单次平均通话时长（分钟）",
          totalCallVolumePCs: "总通话量（个）",
          totalTalkTimehours: "通话总时长（小时）",
          speakingPercentagepercentage: "说话占比（百分比）",
          maximumContinuousSpeakingTimeminutes: "最长持续说话时长（分钟）",
          numberOfIndepthDiscussionsPCs: "深入讨论次数（个）",
          maximumCustomerTalkminutes: "最长客户发言（分钟）",
          questionFrequencyPCs: "提问频次（个）",
          averageValue: "平均值",
          selectDate: "选择日期",
          personnel: "人员",
          pleaseSelect: "请选择",
          callRange: "通话范围",
          conversationActivity: "会话活动",
          conversationAction: "会话动作",
          keyEvents: "关键事件",
          callRecord: "通话记录",
          filter: "筛选：",
          conversation: "通话",
          minute: "分钟",
          sale: "员工",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "1月",
            numberOfCalls: "通话数",
            accountInformation: "账户信息",
            month: "月",
            today: "今天",
            customerDetails: "客户详情",
            searchKeywordsInCommunicationContent: "沟通内容中检索关键词",
            allMembers: "所有成员",
            reset: "重置",
            query: "查询",
            allTrip: "全旅程展示",
            contactOverview: "联络概览",
            lastContact: "上次联络",
            nextContact: "下次联络",
            previous: "上一条",
            next: "下一条",
            viewDetails: "查看详情",
            contactDynamics: "联络动态",
            lastYear: "最近一年",
            lastCall: "最近一次通话",
            hotTopicsForDiscussion: "讨论话题热点",
            totalInTheLastYear: "最近一年一共",
            callTimes: "次通话",
          },
        },
        list: {
          index: {
            risk: "风险",
            have: "有",
            nothing: "无",
            channel: "渠道",
            callRecording: "通话录音",
            enterpriseWeChatConversation: "企微会话",
            enterpriseWeChatCall: "企微通话",
            followUpSuggestions: "跟进建议",
            customer: "客户中心",
            followUp: "跟进情况",
            personInCharge: "负责人",
            recentFollowup: "最近跟进人",
            lastContactTime: "最近一次联系时间",
            operation: "操作",
            see: "查看",
            exceed: "超过",
            noActivityForMoreThanDays: "天没有活动",
            lately: "最近",
            risksOccurredInTheLastDays: "天内出现风险语义",
            savedSuccessfully: "保存成功",
            today: "今天",
            allDepartments: "所有部门",
            allMembers: "所有成员",
            customerName: "客户名称",
            reset: "重置",
            query: "查询",
            customers: "个客户",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "展示员工与客户的联络情况，其中粉色泡泡为客户消息，蓝色为员工消息，发言占比越大，气泡越大",
            riskAllocation: "风险配置",
            preservation: "保存",
            pleaseEnter: "请输入",
            noActivityForDays: "天没有活动",
            riskSemanticsWithinDays: "天内出现风险语义",
            addRiskSemantics: "添加风险语义",
            semanticTags: "语义标签：",
            standardSentence: "标准句：",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "添加新条件" } },
              },
              index: { meetAllOfTheFollowingConditions: "满足以下全部条件" },
            },
            advancedFilterButton: {
              index: {
                screen: "筛选",
                pleaseSelectFilteringCriteria: "请选择筛选条件",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "变更商机阶段",
              theCurrentStageIs: "当前阶段为【",
              ConfirmToChangeItTo: "】，确定将其变更为【",
              IsIt: "】吗？",
              StageCoStay: "】 阶段共停留",
              day: "天",
              start: "开始",
              end: "结束",
              orderInput: "输单",
              restart: "重新启动",
              pleaseConfigureTheSalesStageInformation: "请配置销售阶段信息",
              reasonForDocumentInput: "输单原因：",
              completeOrderInput: "完成输单",
              pleaseSelectTheReason: "请选择原因",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "管理者建议：" },
              index: {
                sessionDetails: "会话详情",
                customerDynamics: "客户动态",
                riskPoint: "风险点",
                followUpSuggestions: "跟进建议",
              },
              sessionDetails: {
                common: "共",
                currentlyViewingThe: "当前查看第",
                sessions: "个会话",
              },
            },
          },
          businessTitle: {
            index: { theCurrentStageHasStayed: "当前阶段已停留", day: "天" },
          },
          business: { index: { reEnableOpportunity: "重新启用商机" } },
          customerTag: { index: { label: "标签" } },
          moreCustomer: {
            index: {
              essentialInformation: "基本信息",
              cancel: "取消",
              confirm: "确认",
              customer: "客户",
              currentFollowupStage: "当前跟进阶段",
              telephone: "电话",
              followUpDays: "跟进天数",
              day: "天",
              remarks: "备注",
              channelPreference: "渠道偏好",
              hello: "你好",
              customFields: "自定义字段",
              opportunityAmount: "商机金额",
              focus: "关注点",
              customerInformation: "客户信息",
              viewMoreCustomerInformation: "查看更多客户信息",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "关键事件：" },
                row1: {
                  customerDynamics: "客户动态",
                  common: "共",
                  barDynamic: "条动态",
                },
                row2: { riskPoint: "风险点", common: "共", risks: "条风险" },
                row3: {
                  followUpSuggestions: "跟进建议",
                  intelligentRecommendation: "智能推荐",
                  strip: "条",
                  managersSuggestATotalOf: "管理者建议 共",
                  recommendedByManagers: "管理者推荐",
                  creationTime: "创建时间",
                  administratorSuggestions: "管理员建议",
                  suggestionsForNewManagers: "新增管理者建议",
                },
              },
            },
            index: { typeTime: "类型/时间" },
          },
          userInfo: {
            index: {
              personInCharge: "负责人",
              recentFollower: "最近跟进人",
              startTime: "首次触达时间",
              followUpDays: "跟进天数",
              day: "天",
              remarks: "备注",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "团队成员",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "成员学习他人会话的总数（去重），查阅会话详情且点击过录音播放视为学习一次",
          totalNumberOfStudies: "学习总数",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "成员对他人会话发起评论、回复的总数（去重）",
          totalNumberOfInteractions: "互动总数",
          learnTheSumOfConversationCallDuration: "学习的会话通话时长求和",
          lengthOfStudy: "学习时长",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "成员对他人的会话发起求助的总数（去重）",
          totalNumberOfRequests: "求助总数",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "成员对他人的求助进行辅导的总数（去重）",
          totalCounseling: "辅导总数",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "成员对他人的会话添加到案例库的总数（去重）",
          addCase: "添加案例",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "成员对他人的会话进行订阅的总数（去重）",
          subscribe: "订阅",
          totalNumberOfSessionsCommunicatedByMembers: "成员沟通的会话总数",
          totalSessions: "会话总数",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "成员复盘自己会话的总数（去重），查阅会话详情且点击过录音播放视为复盘一次",
          totalNumberOfDoubleDisk: "复盘总数",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "复盘总数/会话总数*100%",
          proportionOfDoubleDisk: "复盘占比",
          summationOfConversationCallDurationOfDuplicateDisk:
            "复盘的会话通话时长求和",
          resumptionDuration: "复盘时长",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "成员对自己的会话发起求助的总数（去重）",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "成员的会话收到他人评论（包括回复）的总数",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "成员的会话被他人添加到案例库的总数（去重）",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "成员的会话被他人进行订阅的总数（去重）",
          askForHelpInTheLastMonth: "最近一个月求助",
          lastTimeForHelp: "最近一次求助时间",
          operation: "操作",
          viewAllSessionsInTheLastMonth: "查看最近一个月的所有会话",
          allDepartments: "所有部门",
          reset: "重置",
          query: "查询",
          altogether: "一共",
          secondSession: "次会话",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "当前测试项目:",
            supervisionItem: "监督项",
            keyEvents: "关键事件",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "请勾选要测试的监督项或关键事件，并确保模拟对话中有通话内容",
        },
      },
      executionAnalysis: { index: { executiveForceAnalysis: "执行力分析" } },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "流程1",
              technologicalProcess: "流程",
              newProcess: "新建流程",
            },
          },
          index: {
            upload: "上传",
            preservation: "保存",
            newlyBuild: "新建",
            cancel: "取消",
            name: "名称",
            describe: "描述",
            type: "类型",
            classification: "分类",
            content: "内容",
            enclosure: "附件",
            knowledgePointAssociation: "知识点关联",
            open: "开启",
            close: "关闭",
            operator: "算子",
            logic: "逻辑",
            see: "查看",
            edit: "编辑",
          },
        },
        index: {
          name: "名称",
          describe: "描述",
          createdBy: "创建人",
          type: "类型",
          lastUpdated: "最近更新时间",
          numberOfReferences: "引用次数",
          operation: "操作",
          see: "查看",
          edit: "编辑",
          delete: "删除",
          categoryDeletedSuccessfully: "分类删除成功",
          classificationOfKnowledgePoints: "知识点分类",
          newlyBuild: "新建",
          knowledgeBase: "知识库",
          knowledgePoints: "知识点",
          technologicalProcess: "流程",
          keyword: "关键字",
          batchExport: "批量导出",
          batchDelete: "批量删除",
          importProcess: "导入流程",
          importKnowledgePoints: "导入知识点",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "若知识点在知识库中不存在则直接新增知识点，若存在则跳过，不做更新",
          editKnowledgePointClassification: "编辑知识点分类",
          newKnowledgePointClassification: "新建知识点分类",
          categoryEditingSucceeded: "分类编辑成功",
          newClassificationSucceeded: "新建分类成功",
          classificationName: "分类名称",
        },
      },
      monitoringReport: { index: { executiveSupervision: "执行力监督" } },
      topicCallout: {
        index: {
          whole: "全部",
          date: "日期",
          dimensionStatus: "标注状态",
          callRecordID: "通话记录ID",
          callRecordIDMustBeNumeric: "通话记录ID必须为数字",
          strip: "条",
          topicAnnotation: "话题标注",
          annotated: "已标注",
          stripNotMarked: "条，未标注",
          barInDimension: "条，标注中",
          customer: "客户",
          sale: "销售",
          common: "共",
          sessions: "条会话",
          currentTopic: "当前话题：",
          staff: "员工",
          topicOfConversation: "话题",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "操作成功，标注已重置",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "重置后将清空本通会话的所有标注记录，请确认",
          resetAll: "全部重置",
          pleaseSelectTheTopicToBeMarked: "请选择要标注到的话题",
          pleaseTickTheSentenceToBeMarked: "请勾选要标注的句子",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "操作成功，标注已生效",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "本次标注的范围包含其他话题的消息，点击确定将进行覆盖",
          confirmDimensions: "确认标注",
          talkTime: "通话时间",
          callID: "通话id",
        },
      },
      topicList: {
        index: {
          callID: "通话ID",
          latestMarkedTime: "最新标注时间",
          topicOfConversation: "话题",
          numberOfSentences: "句子数量",
          sentence: "句子",
          operation: "操作",
          see: "查看",
          delete: "删除",
          edit: "编辑",
          newSubTopic: "新建子话题",
          move: "移动",
          whole: "全部",
          topicDeletedSuccessfully: "删除话题成功",
          newlyBuild: "新建",
          topicList: "话题列表",
          callIDMustBeNumeric: "通话ID必须为数字",
          leadInTopic: "导入话题",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "分类名称重复时，不会覆盖原有的话题分类，该项会被直接跳过，不会导入",
          mobileClassification: "移动分类",
          editTopic: "编辑话题",
          newTopic: "新建话题",
          topicName: "话题名称",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "修改成功",
            createdSuccessfully: "创建成功",
            modificationFailed: "修改失败",
            creationFailed: "创建失败",
            monitoringName: "监测名称",
            upToCharacters: "最多40个字符",
            pleaseEnterAName: "请输入名称",
            monitoringIndicators: "监测指标",
            sessionActivity: "会话活动",
            conversationAction: "会话动作",
            keyEvents: "关键事件",
            supervisionItems: "监督项",
            monitoringMembers: "监测成员",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "接受指导、培训或管理干预的组别",
            groupA: "A组",
            pleaseSelectGroupAData: "请选择A组数据",
            thereAreDuplicateUsersInGroupB: "与B组中存在重复的用户",
            groupARemarks: "A组备注",
            controlGroupGroupComparedWithGroupA: "对照组，与A组进行对比的组别",
            groupB: "B组",
            pleaseSelectGroupBData: "请选择B组数据",
            thereAreDuplicateUsersInGroupA: "与A组中存在重复的用户",
            groupBRemarks: "B组备注",
            monitoringPeriod: "监测周期",
            startNode: "开始节点",
            monitoringScope: "监测范围",
            pleaseSelectMonitoringConditions: "请选择监测条件",
            pleaseEnterANumber: "请输入数字",
            pleaseSelectATimePeriod: "请选择时间周期",
            preservation: "保存",
            cancel: "取消",
          },
        },
        list: {
          template: {
            deletionSucceeded: "删除成功",
            notStarted: "未开始",
            haveInHand: "进行中",
            closed: "已结束",
            newGroupTest: "新建分组测试",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "销售阶段",
        communicationStrategy1: "沟通策略",
        riskAllocation: "风险配置",
        businessConfiguration: "业务配置",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "异议处理话术",
                  productSellingPointScript: "产品卖点话术",
                  caseGrouping: "案例分组",
                  pleaseSelectExcellentCases: "请选择优秀案例",
                  pleaseSelectKnowledgePoints: "请选择知识点",
                  associativeLinks: "关联链接",
                  jumpTo: "跳转至",
                  jumpType: "跳转类型",
                  knowledgePoints: "知识点",
                  excellentCases: "优秀案例",
                  excellentScript: "优秀话术",
                  pleaseSelectASingleKnowledgePoint: "请选择单个知识点",
                },
              },
            },
            index: {
              name: "名称",
              pushText: "推送文字",
              enableStatus: "启用状态",
              triggerRules: "触发规则",
              communicationStrategy: "沟通策略",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "沟通策略分组",
              name: "名称",
              pushText: "推送文字",
              effectiveness: "有效性",
              openSuccessfully: "开启成功",
              closeSuccessfully: "关闭成功",
              operationFailed: "操作失败",
              operation: "操作",
              edit: "编辑",
              delete: "删除",
              newlyBuild: "新建",
              communicationStrategy: "沟通策略",
              editGroup: "编辑分组",
              groupName: "分组名称",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "所有会话",
                  recentDays: "最近天数",
                  recentSessions: "最近会话",
                  scopeOfApplication: "适用范围：",
                  day: "天",
                  through: "通",
                },
              },
            },
            index: {
              describe: "描述",
              applicableCustomers: "适用客户",
              enableStatus: "启用状态",
              triggerRules: "触发规则",
              riskRules: "风险规则",
            },
          },
          list: {
            index: {
              riskAllocation: "风险配置",
              keyWord: "关键词",
              newRiskRule: "新建风险规则",
              riskRuleName: "风险规则名称",
              describe: "描述",
              state: "状态",
              openSuccessfully: "开启成功",
              closeSuccessfully: "关闭成功",
              operationFailed: "操作失败",
              operation: "操作",
              edit: "编辑",
              delete: "删除",
              deletionSucceeded: "删除成功",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "输单" },
              endReasonList: {
                addEndReason: "添加结束原因",
                enterEndReason: "输入结束原因",
              },
              stageList: {
                keyEvents: "关键事件",
                waysideData: "随路数据",
                includeAny: "包含任意",
                includeAll: "包含全部",
                pleaseEnterTheStageName: "请输入阶段名称",
                setStageRules: "设置阶段规则",
                stageName: "阶段名称",
                operator: "算子",
                logic: "逻辑",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "时间类型字段输入格式为：2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater: "请求失败，请稍后再试",
              salesStage: "销售阶段",
              essentialInformation: "基本信息",
              name: "名称",
              applicableDepartments: "适用部门",
              applicableToOthers: "适用其他",
              manualChangePhase: "手动变更阶段",
              enableStatus: "启用状态",
              describe: "描述",
              opportunityStageSettings: "商机阶段设置",
              stageSettings: "阶段设置",
              aliasSettings: "别名设置",
              closeOpportunity: "结束商机",
              newSuccessfully: "新建成功",
              newFailed: "新建失败",
              savingSucceeded: "保存成功",
              saveFailed: "保存失败",
            },
          },
          list: {
            index: {
              salesStage: "销售阶段",
              keyWord: "关键词",
              newSalesStage: "新建销售阶段",
              salesStageName: "销售阶段名称",
              describe: "描述",
              state: "状态",
              openSuccessfully: "开启成功",
              closeSuccessfully: "关闭成功",
              operationFailed: "操作失败",
              operation: "操作",
              edit: "编辑",
              delete: "删除",
              deletionSucceeded: "删除成功",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "大模型生成",
            conversationInsights: "会话洞察",
          },
          sessionsInsight: {
            components: {
              autoGenerate: { index: { automaticGeneration: "自动生成" } },
              hooks: {
                conversationSummary: "会话小结",
                conversationProcess: "会话流程",
                opportunityPoints: "机会点",
              },
              prompt: {
                index: {
                  edit: "编辑",
                  restoreDefault: "恢复默认",
                  cancel: "取消",
                  confirm: "确认",
                },
              },
            },
            index: {
              saveSuccessful: "保存成功",
              hintLanguage: "提示语",
              preserve: "保存",
              cancel: "取消",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "大模型生成",
        salesStage: "销售阶段",
        communicationStrategy: "沟通策略",
        riskAllocation: "风险配置",
        businessConfiguration: "业务配置",
      },
      salesLearningCenter: {
        knowledgeBase: "知识库",
        speechCorpus: "话术库",
        learningCenter: "学习中心",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "客户信息" } },
        },
        index: { otherInformation: "其他信息" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "导入" } } },
        hooks: {
          index: {
            all: "全部",
            intelligentModel: "智能模型",
            smartRules: "智能规则",
            manualScoring: "手动评分",
            enable: "启用",
            notEnabled: "未启用",
            voiceCall: "语音通话",
            textDialogue: "文本对话",
            ecommerceTextSession: "电商文本会话",
            workOrder: "工单",
          },
        },
        index: {
          ruleName: "规则名称",
          ruleType: "规则类型",
          pleaseSelect: "请选择",
          ruleStatus: "规则状态",
          dataSourceType: "数据源类型",
          voiceCall: "语音通话",
          textDialogue: "文本对话",
          ecommerceTextSession: "电商文本会话",
          workOrder: "工单",
          status: "状态",
          operation: "操作",
          edit: "编辑",
          ruleTemplate: "规则模版",
        },
      },
      analysisReportTitle: "分析报表",
      ruleTest: "规则测试",
      intelligentTags: {
        labelDisplayQuantity: "标签显示数量",
        unlimited: "不限",
        ignoringTagLibraries: "忽略标签库",
        automaticallyGenerateLabels: "自动生成标签",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "开启后，基于大模型对会话自动生成标签，生成的标签默认归类到“未分类”下",
        defaultValidity: "默认有效性",
        supervisionItem: "监督项",
        customerLabel: "客户标签",
        newSupervisionItemRule: "新建监督项规则",
        editSupervisionItemRule: "编辑监督项规则",
        newCustomerLabel: "新建客户标签",
        editCustomerLabel: "编辑客户标签",
        newSessionLabel: "新建会话标签",
        editSessionLabel: "编辑会话标签",
        newKeyEvent: "新建关键事件",
        editKeyEvents: "编辑关键事件",
        detail: {
          template: { nameIsARequiredField: "名称是必填字段" },
          formItemComponent: {
            largeModelExtensionLabel: "大模型扩充标签",
            sessionRecordID: "会话记录id",
            sessionTime: "会话时间",
            smartTags: "智能标签",
            viewDetails: "查看详情",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "删除后此会话记录的数据将会被清空，是否确认删除？",
            tagData: "标记数据",
            batchDeletion: "批量删除",
          },
        },
        template: {
          advancedFiltering: "高级筛选",
          dataType: "数据类型",
          filterCriteria: "筛选条件",
          edit: "编辑",
          createANewSubcategory: "新建子分类",
          delete: "删除",
          nameLengthCannotExceed: "名称长度不能超过40",
        },
        component: {
          modelExpansion: "模型扩充",
          enable: "启用",
          notEnabled: "未启用",
          standardScreening: "标准筛选",
          advancedFiltering: "高级筛选",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "关键词",
              sessionLabel: "会话标签",
              keyEvents: "关键事件",
              matchAny: "匹配任意",
              matchAll: "匹配全部",
              beEqualTo: "等于",
              includeAll: "包含全部",
              includeAny: "包含任意",
              advancedConditions: "高级条件",
              smartTag: "智能标签",
              smartTagI: "智能标签一",
              smartTagII: "智能标签二",
            },
            template: {
              pleaseSelectSmartTag: "请选择智能标签",
              pleaseSelectASessionLabel: "请选择会话标签",
              pleaseSelectAKeyEvent: "请选择关键事件",
              pleaseSelectAdvancedCondition: "请选择高级条件",
              newAdvancedFilter: "新建高级筛选",
              moveUp: "上移",
              moveDown: "下移",
              moveLeft: "左移",
              shiftRight: "右移",
            },
          },
          component: {
            businessAnalysispng: "业务分析.png",
            savedSuccessfully: "保存成功",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "永久有效",
            permanentlyInvalid: "永久无效",
            customValidity: "自定义有效期",
            termOfValidity: "有效期",
            name: "名称",
            explain: "说明",
            state: "状态",
            classification: "分类",
            smartRecommendation: "智能推荐",
            preconditions: "前置条件",
            SetPreconditionsForThisSupervisionItem:
              "1.为该条监督项设置前置条件：",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              "符合前置条件时，即为“应执行监督项”，则对该条监督项进行检测；",
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "不符合前置条件时，则不检测该条监督项；",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2.不设置前置条件，即默认对所有会话进行检测",
            have: "有",
            nothing: "无",
            preconditionRule: "前置条件规则",
            preconditionLogic: "前置条件逻辑",
          },
          recommendation: {
            caseGrouping: "案例分组",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "当员工执行不到位时，可智能推荐优秀话术和优秀案例",
            smartRecommendation: "智能推荐",
            open: "开启",
            close: "关闭",
            whenThisSupervisionItem: "当此监督项",
            implement: "执行",
            unexecuted: "未执行",
            when: "  时：",
            recommendedKnowledgePoints: "推荐知识点",
            caseRecommendation: "案例推荐",
          },
        },
        index: {
          determine: "确定",
          sort: "排序",
          sortSucceeded: "排序成功",
          supervisionItemClassification: "监督项分类",
          pressEnterToCreateANew: "按回车新建",
          nameCannotBeEmpty: "名称不能为空",
          newlyBuild: "新建",
          name: "名称",
          explain: "说明",
          validStatus: "有效状态",
          permanentlyValid: "永久有效",
          permanentlyInvalid: "永久无效",
          operation: "操作",
          edit: "编辑",
          batchDelete: "批量删除",
          newSupervisionItem: "新建监督项",
          keyWord: "关键词",
          delete: "删除",
          deleteSucceeded: "删除成功",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "数据删除后将无法恢复，是否确认删除？",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "智能标签",
              beEqualTo: "等于",
              includeAll: "包含全部",
              includeAny: "包含任意",
              matchAny: "匹配任意",
              matchAll: "匹配全部",
              smartTagI: "智能标签一",
              smartTagII: "智能标签二",
            },
            template: {
              pleaseSelectASmartTag: "请选择智能标签",
              pleaseSelectAdvancedConditions: "请选择高级条件",
              newAdvancedFilter: "新建高级筛选",
              rename: "重命名",
              moveUp: "上移",
              moveDown: "下移",
              shiftLeft: "左移",
              shiftRight: "右移",
            },
          },
        },
        template: {
          delete: "删除",
          labelFiltering: "标签筛选：",
          satisfyAny: "满足任意",
          meetAll: "满足全部",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "二级分类标签对话数≥一级分类，一个会话可能包含多个标签",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "环比变化计算逻辑：（所选时间段对话量-紧挨着的上个时间段的对话量）/上个时间段的对话量",
          proportionOfPrimaryClassification: "一级分类占比情况",
          saveAs: "另存为",
          cover: "覆盖",
          preservation: "保存",
          qualityInspectionTask: "分析任务：",
          query: "查询",
          changeFromMonthToMonth: "环比变化情况",
          tagRanking: "标签排行",
          proportion: "占比情况",
        },
        component: {
          numberOfConversations: "对话数",
          operation: "操作",
          businessAnalysispng: "业务分析.png",
          savingSucceeded: "保存成功",
          deletionSucceeded: "删除成功",
          sequence: "序列",
          firstLevelClassification: "一级分类",
          ringRatioChange: "环比变化",
          classClassification: "级分类",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "确认删除文档及其抽取内容？",
            addKnowledgeBase: "添加知识库",
            uploadDocument: "上传文档",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "支持word、pdf、ppt、excel、txt格式文件",
            taskName: "任务名称",
            extractConversationDate: "抽取对话日期",
            pleaseSelectAConversationDate: "请选择对话日期",
            dataSource: "数据源",
            dataType: "数据类型",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "导出成功",
              problem: "问题",
              answer: "答案",
              addToKnowledgeBase: "添加到知识库",
              pleaseConfirmWhetherToDeleteIt: "请确定是否删除",
            },
            pageRightFrom: {
              addToKnowledgeBase: "添加到知识库",
              selectClassification: "选择分类",
              pleaseSelectAKnowledgeBaseClassification: "请选择知识库分类",
            },
          },
        },
        index: {
          intelligentExtraction: "智能抽取",
          documentExtraction: "文档抽取",
          dialogueExtraction: "对话抽取",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "操作成功",
          labelIgnored: "已忽略标签",
          add: "添加",
          areYouSureToDeleteIt: "确定删除吗？",
          batchFilterLabels: "批量过滤标签",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "智能质检",
        savedSuccessfully: "保存成功",
        logoSettings: "logo设置",
        default: "默认",
        custom: "自定义",
        title: "标题",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "系统展示的标题名称，建议6字以内。",
        logoImageUpload: "logo图片上传",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "请选择大小不超过2048kb的图片",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "为保证logo浏览效果，上传图片建议：",
        Size5050Pixels: "1.尺寸：50*50像素；",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2.格式：透明背景的图片，png或jpg，logo图形使用白色",
        browserLabelIcon: "浏览器标签图标",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "为保证标签浏览效果，上传图片建议：",
        Size3232Pixels: "1.尺寸：32*32像素；",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2.格式：透明背景的图片，png或jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater: "质检进行中，请稍后...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "2015年那会，我国房地产到了生存的关键时刻，大量的房子无人问津，老百姓不愿意购买。杭州一度跌破8000，有开发商砸盘出货，深圳当时还在2w-3w左右盘整，一线城市的房子也岌岌可危",
        xiaoAn: "小安",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload这些内存屏障叫jsr内存屏障，是jvm层面的，在操作系统底层实现方式有二：1是锁总线，二是内存屏障元语，如sfance(写屏障)mfence(全屏障)ifence(读屏障)。hotspots虚拟机底层采用的是一条汇编语句：lock addl，lock一个空操作，即为锁总线，这么做是因为部分操作系统不支持内存屏障元语。",
        xiaobei: "小北",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. 试着腌过几次肉，加料酒后炒出来的菜，肉和馊了似的，一股酸臭味！从此腌肉再不放料酒",
        xiaodong: "小东",
        xiaoxi: "小西",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. 试着腌过几次肉，加料酒后炒出来的菜，肉和馊了似的，一股酸臭味！从此腌肉再不放料酒",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "电商评论管理",
        socialMediaReviewManagement: "社媒评论管理",
        customDataSource: "自定义数据源",
        qualityInspectionResults: "质检结果",
        accountManagement: "账户管理",
        employeeProfile: "员工画像",
        intelligentAnalysis: "智能分析",
        customKanban: "自定义看板",
        dataScreening: "数据总览",
        learningRecord: "学习记录",
        dataAnalysis: "数据分析",
        scoringManagement: "评分管理",
        businessConfiguration: "业务配置",
        manualReview: "人工复核",
        taskList: "任务列表",
        videoAnalysis: "视频分析",
        overview: "总览",
        personalSignage: "个人看板",
        learningCenter: "学习中心",
        wrongQuestionSet: "错题集",
        abilityImprovement: "能力提升",
        close: "关闭",
        switchingSystemApplications: "切换系统应用",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "鼠标移动至此处可唤出应用切换菜单，点击菜单项跳转不同应用",
        teamAnalysis: "团队分析",
        semanticIntelligence: "语义智能",
        permissionManagement: "权限管理",
        dataConnection: "数据对接",
        courseManagement: "课程管理",
        labelManagement: "标签管理",
        curriculum: "课程中心",
        courseConfiguration: "课程配置",
        task: "我的任务",
        historicalLearningData: "历史记录",
        taskCenter: "任务管理",
        knowledgeBase: "知识库",
        ecommerceSessionManagement: "电商会话管理",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "应用切换",
          descriptionOfIntelligentQualityInspectionSystem: "智能质检系统描述",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "电商会话筛选器",
        allEcommerceSessions: "全部电商会话",
        ecommerceSessionManagement: "电商会话管理",
      },
    },
    analysisEnable: {
      bestPractices: "最佳实践",
      behaviorAnalysis: "行为分析",
      teamAnalysis: "团队分析",
      executiveSupervision: "执行力监督",
      executiveForceAnalysis: "执行力分析",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "评级:",
          startTime: "开始时间",
          workOrderNo: "工单编号",
          customerServiceHasReadNotRead: "客服已读/未读",
          canYouAppeal: "是否可申诉",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "企微备注",
          addFriendTime: "添加好友时间",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "开始执行重新质检",
          callID: "通话ID",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "新功能！点击 ‘快速抽检’ 按钮，提交成功后会自动跳转到下一页啦~~~",
        },
      },
      template: {
        deleteRecord: "删除记录",
        areYouSureToDeleteIt: "确定删除吗？",
        confirm: "确定",
        reIdentification: "重新识别",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "重新识别会消耗转写时长，请谨慎操作",
        determine: "确定",
        executionSuccessful: "执行成功",
      },
      component: {
        pleaseSelectData: "请选择数据",
        operationSuccessful: "操作成功",
        startPerformingReQualityInspection: "开始执行重新质检",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "上传文件名称",
        analysisTask: "分析任务",
        analyzeProgress: "分析进度",
        analysisSucceeded: "分析成功",
        analysisFailed: "分析失败",
        localDataUpload: "本地数据上传",
        recordingUpload: "录音上传",
        textUpload: "文本上传",
        uploadTime: "上传时间",
        taskName: "任务名称",
        operator: "操作人",
        qualityInspectionProgress: "质检进度",
        whole: "全部",
        haveInHand: "进行中",
        completed: "已完成",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "是否导出当前筛选条件下所有已完成的数据记录？",
        upload: "上传",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "填写名称",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "填写名称，如本次导入数据的类型/集合/目的",
            default: "默认",
            jDCOM: "京东",
            dataType: "数据类型",
            selectAnalysisTask: "选择分析任务",
            uploadFiles: "上传文件",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1.下载excel模板，按格式填写：",
            template: "模板",
            SupportUpTo10000PiecesOfData: "2.最大支持10000条数据",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "支持京东导出的对话数据上传，.txt文件，文件大小最大支持100M",
            alongTheWayDataTemplate: "随路数据模板",
          },
        },
        result: {
          template: {
            textUnderInspection: "文本质检中，请耐心等待",
            textQualityInspectionCompleted: "文本质检完成",
          },
        },
        template: {
          startAnalysis: "开始分析",
          pleaseUploadTheFile: "请上传文件",
          localDataUpload: "本地数据上传",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "新功能！点击 ‘复核提交’ 按钮，提交成功后会自动跳转到下一页啦~~~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "质检任务权限",
            edit: "编辑",
            iParticipated: "我参与的",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "该角色能够看见所选部门下的数据",
            dataPermissions: "数据权限",
            selectDepartment: "选择部门",
            edit: "编辑",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "开始执行重新质检",
          callID: "通话ID",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "新功能！点击 ‘抽检提交’和‘快速抽检’ 按钮，提交成功后会自动跳转到下一页啦~~~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "规则" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "工单管理",
          workOrderDetails: "工单详情",
        },
      },
      template: {
        workOrderFilter: "工单筛选器",
        allWorkOrders: "全部工单",
        workOrderManagement: "工单管理",
      },
    },
    trainingCenter: {
      topicAnnotation: "话题标注",
      topicList: "话题列表",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "角色类型",
              keyWord: "关键词",
              pleaseEnterKeywordSearch: "请输入关键词搜索",
              query: "查询",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "暂未开放此功能，如需要请联系系统管理员",
          pleaseSelectAQuestion: "请选择问题！",
          recommendedSimilarSentencesLargeModel: "推荐相似句(大模型)",
          selectAll: "全选",
          add: "添加",
          changeBatch: "换一批",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "业务工作时间",
      localDataUpload: "本地数据上传",
      assembleMenuListAccordingToCurrentUserPermissions:
        "根据当前用户权限，组装菜单列表",
      sessionSettings: "会话设置",
      logoSettings: "logo设置",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails: "欢迎回来！请输入您的详细信息",
        mailbox: "邮箱",
        password: "密码",
        signIn: "登录",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "当前IP不允许登陆",
          welcomeToLogin: "欢迎登录",
          pursuingExcellenceAndLeadingChange: "追求卓越，引领变革",
          intelligentPlatform: "智能平台",
        },
        virtualLogin: { index: { pleaseSelect: "请选择" } },
      },
      oauth: {
        illegalCall: "违法调用",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "欢迎回来！点击下方按钮跳转身份认证平台",
        loggingIn: "登录中...",
        unifiedIdentityAuthenticationLogin: "统一身份认证登录",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "规则测试：部分规则不能使用规则测试，如语速音量、随路数据等",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "了解更多",
          enteringTheSystem: "进入系统",
        },
      },
      index: {
        cancel: "取消",
        enteringTheSystem: "进入系统",
        close: "关闭",
        enterSystemApplication: "进入系统应用",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          "点击 “进入系统” 跳转至已启用的系统应用",
      },
    },
    permissionManagement: {
      employeeManagement: "员工管理",
      roleManagement: "角色管理",
      permissionManagement: "权限管理",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "等于",
            contain: "包含",
            doesNotContain: "不包含",
            applicationSystem: "应用系统",
            intelligentQualityInspection: "智能质检/智能会话分析",
            salesEmpowerment: "销售赋能",
            dataSourceType: "数据源类型",
            filterCriteria: "筛选条件",
          },
        },
        index: {
          dataConnection: "数据对接",
          savingSucceeded: "保存成功",
          dataIsolation: "数据隔离",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "开启后，可设置各应用系统接入通话、企业微信会话的数据范围。开关不开启，则各个系统都接入全量数据",
          accessRules: "接入规则",
          preservation: "保存",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "应用名称",
            accessRights: "访问权限",
            inAppPermissionSettings: "应用内权限设置",
            setUp: "设置",
            applicationAccess: "应用访问权限",
            newRole: "新增角色",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "会话详情" } },
      messageNotification: {
        index: { sessionDetails: "会话详情", caseDetails: "案例详情" },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "可见员工",
            newCourse: "新建课程",
            editCourse: "编辑课程",
            courseName: "课程名称",
            courseIntroduction: "课程介绍",
            courseType: "课程类型",
            courseClassification: "课程分类",
            courseCover: "课程封面",
            learningContent: "学习内容",
            uploadLearningMaterials: "上传学习资料",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "测试",
              preservation: "保存",
              cancel: "取消",
              editNodeName: "编辑节点名称",
              determine: "确定",
              individual: "个",
              of: ", 共",
              branch: "分",
            },
            configDrawerComponents: {
              pPTParsing: "PPT解析中...",
              uploadSuccessful: "上传成功",
              uploadAttachments: "上传附件",
              pPTParsingPleaseWait: "PPT解析中，请稍后",
            },
            customerPortraitConfigDrawer: {
              cancel: "取消",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "删除后无法恢复，确定要删除此画像吗？",
              delete: "删除",
              determine: "确定",
              customerPortrait: "客户画像",
              bindSelectedCharacterPortrait: "绑定选中角色画像",
              customerProfileDetails: "客户画像详情",
              createANewCustomerProfile: "新建客户画像",
            },
            flow: {
              sidebar: { node: "节点" },
              useControlButtons: {
                cancellingPleaseWait: "撤销中，请稍后...",
                redoInProgressPleaseWait: "重做中，请稍后...",
                automaticOrganization: "自动整理",
                canvasAdaptation: "画布自适应",
                delete: "删除",
              },
              useFlow: {
                nodeCannotBeConnectedToItself: "节点不能与自己相连接",
              },
            },
            globalConfigDrawer: {
              cancel: "取消",
              determine: "确定",
              globalConfiguration: "全局配置",
              sessionDetection: "会话检测",
              reciprocalRule: "交互规则",
              robotVoice: "机器人音色",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "学员节点超时未作答设置",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "学员未作答允许的最大静默时间（s）",
              promptScriptForFailureToAnswerOverTime: "超时未作答提示话术",
              addScript: "添加话术",
            },
            robotTimbreForm: {
              aixianatural: "艾夏（自然）",
              zhishaChat: "知莎（聊天）",
              zhiqiangentle: "知倩（温和）",
              zhimiEmotion: "知米（情感）",
              aiShuoNatural: "艾硕（自然）",
              zhishuoChat: "知硕（聊天）",
              aiHaoAffinity: "艾浩（亲和）",
              zhixiangEmotion: "知祥（情感）",
              robotVoice: "机器人音色",
              schoolboy: "男生",
              girlStudent: "女生",
              volume: "音量",
              tone: "音调",
              speechSpeed: "语速",
              test: "测试",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "您好，我是智能培训教练，期待与你的交流",
            },
            sessionDetectionForm: {
              expressionDetection: "表达检测",
              excessiveDetectionOfModalParticles: "语气词过多检测",
              theNumberOfRepetitionsIs: "重复次数为",
              minus: "次时，减",
              appear: "出现",
              subtract: "次，减",
              branch: "分",
              addRules: "增加规则",
              includingNegativeAngryComplaining: "(包括负面、生气、抱怨)",
              scorePointsDisplay: "得分要点显示",
              reminderOfSpeakingTooFasttooSlow: "语速过快/过慢提醒",
              scriptViolationReminder: "话术违规提醒",
              emotionalReminder: "情绪提醒",
              speechSpeedDetection: "语速检测",
              normalSpeechSpeedScore: "语速正常得分",
              speakTooFast: "语速过快",
              everyMinuteGreaterThan: "每分钟大于",
              aWordAppears: "个字，出现",
              speakTooSlowly: "语速过慢",
              lessThanPerMinute: "每分钟小于",
              ignoreLessThan: "忽略小于",
              aSentenceOfWords: "字的句子",
              emotionalDetection: "情绪检测",
              emotionalNormalScore: "情绪正常得分",
              negativeEmotions: "负向情绪",
              illegalWordDetection: "违规词检测",
              scriptViolationConfiguration: "话术违规配置",
              realTimePromptOnTheFrontEnd: "前端实时提示",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "知识库问答",
                  qAMethod: "问答方式",
                  numberOfQuestionsAndAnswers: "问答数量",
                  scoringRules: "得分规则",
                  ifAnyQuestionAndAnswerIsCorrect: "任意一个问答正确，得",
                  branch: "分",
                  successfullySaved: "保存成功",
                },
                pptNode: {
                  verificationPassed: "校验通过",
                  upload: "上传",
                  supportPptPdfFormatFiles: "支持ppt、pdf格式文件",
                  uploadSlide: "上传幻灯片",
                  assessmentMethod: "考核方式",
                  associateSlide: "关联幻灯片",
                  standardScript: "标准话术",
                  successfullySaved: "保存成功",
                },
                robotNode: {
                  robotScript: "机器人话术",
                  randomScript: "随机话术",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "选择相似语义的话术，机器人将随机说出其中一句",
                  customerPortrait: "客户画像",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "当客户画像一致时，机器人说出此话术",
                  setAsDefaultScript: "设为默认话术",
                  yes: "是",
                  no: "否",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "当机器人未命中任何用户标签时出此话术",
                  successfullySaved: "保存成功",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "此分支节点已设有默认话术，是否将默认话说替换为当前话术？",
                },
                startNode: {
                  paragon: "旁白话术",
                  successfullySaved: "保存成功",
                },
                userNode: {
                  verificationPassed: "校验通过",
                  standardScript: "标准话术",
                  successfullySaved: "保存成功",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "请完善配置",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "请输入对话示例",
                  dialogueExample: "对话示例",
                  generateConversationExamples: "生成对话示例",
                  mattersNeedingAttention: "注意事项",
                  pleaseEnterThePromptLanguage: "请输入提示语",
                },
                aiGenSceneNode: {
                  dialogueScenario: "对话场景",
                  simulateCharacter: "模拟角色",
                  accompanyingTrainees: "陪练对象",
                  selectFromTemplateLibrary: "从模板库中选择",
                  templateLibrary: "模板库",
                  role: "角色",
                  scene: "场景",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "幻灯片测试窗口 【",
              clearContent: "清除内容",
              close: "关闭",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI生成流程话术",
                scriptStyle: "话术风格",
                theMostConversationRounds: "对话轮次最多",
                copyConversation: "复制对话",
                application: "应用",
                regenerate: "重新生成",
                startGenerating: "开始生成",
                pleaseGenerateAProcess: "请生成流程",
                copySuccessful: "复制成功",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "销售代表",
                    customerServicePersonnel: "客服人员",
                    storeCounter: "门店专柜",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "生成全文演讲话术",
                speaker: "演讲者",
                scriptStyle: "话术风格",
                copyConversation: "复制对话",
                application: "应用",
                regenerate: "重新生成",
                startGenerating: "开始生成",
                pleaseGenerateAProcess: "请生成流程",
                operationSuccessful: "操作成功",
                copySuccessful: "复制成功",
              },
            },
            aiTextArea: { index: { scriptPolishing: "话术润色" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "话术不能为空！",
              copySuccessful: "复制成功",
              cancel: "取消",
              copy: "复制",
              replaceTheOriginalText: "替换原文",
              aiGeneration: "Ai生成",
              aiPolishing: "Ai润色",
              currentStyle: "当前风格",
              rigorous: "严谨",
              mild: "温和",
              lively: "活泼",
              simplicity: "简约",
              pleaseEnterTheSpeakerRole: "请输入说话者角色",
              startGenerating: "开始生成",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "话术不能为空！",
              copySuccessful: "复制成功",
              aiRewrite: "Ai重写",
              cancel: "取消",
              copy: "复制",
              replaceTheOriginalText: "替换原文",
              currentStyle: "当前风格",
              rigorous: "严谨",
              mild: "温和",
              lively: "活泼",
              simplicity: "简约",
              pleaseEnterTheSpeakerRole: "请输入说话者角色",
              startGenerating: "开始生成",
            },
          },
          index: {
            verificationPassed: "校验通过",
            ruleValidation: "规则校验",
            aIGeneration: "AI生成",
            generateFullTextSpeechScript: "生成全文演讲话术",
            editCourse: "编辑课程",
            importConversation: "导入对话",
            uploadExcelToCompleteDialogueImport: "上传Excel完成对话导入",
            editBasicInformation: "编辑基本信息",
            test: "测试",
            release: "发布",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "新建标签" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "删除后无法恢复，确定要删除此标签吗？",
              delete: "删除",
              cancel: "取消",
            },
          },
        },
        index: {
          labelManagement: "标签管理",
          newGroup: "新建分组",
          edit: "编辑",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "删除后无法恢复，确定要删除此标签组吗？",
          delete: "删除",
          cancel: "取消",
          editGroup: "编辑分组",
          labelGroupName: "标签分组名称",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "禁用了摄像头",
              noCameraDevicesFound: "没有找到摄像头设备",
            },
          },
          cue: {
            index: {
              regenerate: "重新生成",
              generate: "生成",
              referenceScript: "参考话术",
              mattersNeedingAttention: "注意事项",
              examFrequency: "考试次数",
              section: "第",
              timestotal: "次 / 共",
              second: "次",
              requirementsForPassingTheExam: "考试通过要求",
              examScore: "考试得分 ≥",
              branch: "分",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "暂无画像",
              customerBackground: "客户背景",
              customerLabel: "客户标签",
            },
          },
          flow: {
            index: {
              processReminder: "流程提示 （",
              score: "得分",
              TotalScore: "/总分",
            },
          },
          index: {
            taskName: "任务名称",
            taskStatus: "任务状态",
            whole: "全部",
            completionStatus: "完成状态",
            courseClassification: "课程分类",
            practiceMode: "练习模式",
            examMode: "考试模式",
            practiceTime: "练习时间",
          },
          ppt: {
            index: {
              nextPage: "下一页",
              tips: "提示",
              continueWithCurrentPage: "继续当前页",
              goToTheNextPage: "进入下一页",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "进入下一页后将不能再返回上一页，请确认当前页讲解完整后再跳转",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "点击下一页将不在弹出此提示",
            },
            list: { index: { slide: "幻灯片" } },
          },
          record: {
            index: {
              unableToRecord: "无法录音:",
              creatingARecorderService: "创建Recorder服务",
              turnOffTheRecorderService: "关闭Recorder服务",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "当前客户端已经连接到ASR Websocket服务器",
              afterProcessingTheLastFrameRestoreTheState:
                "处理完最后一帧后， 将状态恢复",
              aSRConnectionClosed: "ASR连接已关闭...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "录音暂停，并且已处理完，但是缓存中仍然存在数据",
              foundASRChannelClosedRecreateWebsocketLink:
                "发现ASR通道关闭，重新创建Websocket链接",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "发现ASR通道未开启，重新创建Websocket链接",
            },
            ppt: { hook: { index: { serverError: "服务器错误" } } },
            text: {
              index: {
                pleaseEnter: "请输入",
                sendOut: "发送",
                startPracticing: "开始练习",
                startTheExam: "开始考试",
              },
            },
            util: {
              recorded: "已录制",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16位pcm SendFrameSize 必须为2的整数倍",
              pCMSamplingRateShouldNotOccur: "不应该出现pcm采样率",
              andTheRequiredSamplingRate: "和需要的采样率",
              atypism: "不一致",
              errorsThatShouldNotOccur: "不应该出现的错误:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<span style="color:red">顺序错乱的数据，如果要求不高可以直接丢弃</span>',
              flower: "花",
              TransferStopped: ":已停止传输",
            },
            video: { index: { camera: "摄像头" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "当前客户端已经连接到ASR Websocket服务器",
                    aSRConnectionClosed: "ASR连接已关闭...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "发现ASR通道关闭，重新创建Websocket链接",
                    unableToRecord: "无法录音:",
                    aSRChannelClosedReopened: "ASR通道关闭，重新打开",
                    theASRChannelWasNotCreatedStartingNow:
                      "ASR通道未创建，现在开始创建",
                    useEffectCallbackToClearTheASRChannel:
                      "useEffect 回调，进行清除ASR通道",
                    creatingARecorderService: "创建Recorder服务",
                    turnOffTheRecorderService: "关闭Recorder服务",
                  },
                  util: {
                    recorded: "已录制",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16位pcm SendFrameSize 必须为2的整数倍",
                    pCMSamplingRateShouldNotOccur: "不应该出现pcm采样率",
                    andTheRequiredSamplingRate: "和需要的采样率",
                    atypism: "不一致",
                    errorsThatShouldNotOccur: "不应该出现的错误:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<span style="color:red">顺序错乱的数据，如果要求不高可以直接丢弃</span>',
                    flower: "花",
                    TransferStopped: ":已停止传输",
                  },
                },
                index: { failInSend: "发送失败" },
              },
              index: {
                startRecording: "开始录音",
                endRecording: "结束录音",
                startPracticing: "开始练习",
                startTheExam: "开始考试",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "（当前考试剩余次数为",
              totalScriptScore: "话术总分:",
              totalEmotionalScore: "情绪总分:",
              totalScoreOfExpression: "表达总分:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "当前任务评分结果需人工审核，审核完成将通知您最终成绩，请耐心等待",
              areYouSureToSubmit: "是否确认提交？",
              theExerciseHasEndedAreYouSureToSubmit:
                "练习已结束，是否确认提交？",
              theExamHasEndedAreYouSureToSubmit: "考试已结束，是否确认提交？",
              completed: "已完成",
              page: "页",
              pageCount: "总页数",
              topic: "题",
              totalNumberOfQuestions: "总题数",
              totalScore: "总分",
              branch: "分",
              accumulatedCompletionProgress: "累计完成进度",
              accumulatedPracticeDuration: "累计练习时长",
              lastPauseExerciseResults: "上次暂停-练习结果",
              exerciseInstructions: "练习说明",
              examInstructions: "考试说明",
              halfwayPausePracticeResults: "中途暂停-练习结果",
              tips: "提示",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "退出后此练习不计入成绩， 是否确认退出？",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "考试中途不允许退出，是否确认提交？",
              pleaseOperateWithCaution: "次，请谨慎操作）",
              restart: "重新开始",
              returnToLearning: "返回学习",
              continuePracticing: "继续练习",
              continuingTheExam: "继续考试",
              return: "返回",
              notSubmittedTemporarily: "暂不提交",
              iGotIt: "我知道了",
              confirmExit: "确认退出",
              confirmSubmission: "确认提交",
              signOut: "退出",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "暂停",
              submit: "提交",
              remainingTimeOfThisQuestion: "本题剩余时间",
              SecondsLeftUntilTheEndTime: "距离结束时间还剩10秒",
              remainingTime: "剩余时间",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "未检测到用户语音内容, 请重新尝试",
          },
          wave: {
            index: {
              continueRecording: "继续录音",
              submit: "提交",
              endRecording: "结束录音",
              suspendRecording: "暂停录音",
              startPracticing: "开始练习",
              startTheExam: "开始考试",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "对话模式",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "噪音过大，请切换安静环境",
              normalNoiseDetection: "噪音检测正常",
              theVolumeIsTooLowPleaseIncreaseTheVolume: "音量过小，请增大音量",
              volumeDetectionIsNormal: "音量检测正常",
              noiseDetection: "噪音检测",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "请保持安静，录制3s环境音",
              endRecording: "结束录音",
              startRecording: "开始录音",
              volumeDetection: "音量检测",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                "请用正常音量朗读“现在进行音量测试”",
              playLastDetectedAudio: "播放上次检测音频",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "开始前请您认真阅读一下要求",
              return: "返回",
              iGotIt: "我知道了",
              timeLimitForSingleQuestionAnswering: "单题作答时长限制",
              totalTimeLimit: "总限时",
              unlimitedTime: "不限时",
              stillNeedToCompleteTheTaskInTotal: "总完成任务还需",
              practice: "练习",
              second: "次",
              taskScore: "任务一次得分≥",
              branch: "分",
              or: "或",
              examScore: "考试得分≥",
              remainingExamTimes: "剩余考试次数",
              totalScore: "总分",
              Script: "（话术",
              scoreEmotion: "分+情绪",
              fractionExpression: "分+表达",
              points: "分）",
              standardScore: "达标分",
              excellentScore: "优秀分",
              exerciseInstructions: "练习说明",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "为了答题顺利，请先完成设备检测：",
              camera: "摄像头",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "能在视频内看见自己，意味着摄像头正常",
              microphone: "麦克风",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "请对着麦克风说话，看到波形图样意味着麦克风正常",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "空",
                  copyTo: "复制到",
                  classification: "分类",
                  rename: "重命名",
                  newlyBuild: "新建",
                  categoryParent: "上级分类",
                  moveTo: "移动到",
                  name: "名称",
                },
              },
              util: {
                rename: "重命名",
                moveTo: "移动到",
                copyTo: "复制到",
                delete: "删除",
                courseClassification: "课程分类",
                newlyBuild: "新建",
              },
            },
          },
          edit: { index: { curriculum: "课程" } },
          index: {
            learn: "学",
            practice: "练",
            examination: "考",
            curriculum: "课程",
            courseIntroduction: "课程介绍：",
            createdOn: "创建时间：",
            published: "已发布",
            tasks: "个任务",
            unpublishedTasks: "未发布任务",
            task: "任务",
            operation: "操作",
            learningMaterials: "学习资料",
            goPractice: "去练习",
            publishTask: "发布任务",
            taskData: "任务数据",
            edit: "编辑",
            copy: "复制",
            successfullyCopied: "复制成功",
            delete: "删除",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "删除后此课程下的所有任务数据都将被清空，是否确认删除课程？",
            courseName: "课程名称",
            creationTime: "创建时间",
            releaseStatus: "发布状态",
            whole: "全部",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "未检测到声音",
              voice: "语音",
              text: "文本",
              referenceScript: "参考话术：",
              clickOnTheRightSideToStartRecording: "点击右侧开始录音",
              sendOut: "发送",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "当前客户端已经连接到ASR Websocket服务器",
                afterProcessingTheLastFrameRestoreTheState:
                  "处理完最后一帧后， 将状态恢复",
                aSRConnectionClosed: "ASR连接已关闭...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "录音暂停，并且已处理完，但是缓存中仍然存在数据",
                foundASRChannelClosedRecreateWebsocketLink:
                  "发现ASR通道关闭，重新创建Websocket链接",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "发现ASR通道未开启，重新创建Websocket链接",
                unableToRecord: "无法录音:",
                creatingARecorderService: "创建Recorder服务",
                turnOffTheRecorderService: "关闭Recorder服务",
              },
              util: {
                recorded: "已录制",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16位pcm SendFrameSize 必须为2的整数倍",
                pCMSamplingRateShouldNotOccur: "不应该出现pcm采样率",
                andTheRequiredSamplingRate: "和需要的采样率",
                atypism: "不一致",
                errorsThatShouldNotOccur: "不应该出现的错误:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<span style="color:red">顺序错乱的数据，如果要求不高可以直接丢弃</span>',
                flower: "花",
                TransferStopped: ":已停止传输",
              },
            },
            finishTest: {
              index: {
                endTest: "结束测试",
                testResult: "测试结果",
                returnToCanvas: "返回画布",
                retest: "重新测试",
              },
            },
          },
          index: {
            testFromCurrentNode: "从当前节点测试",
            listening: "收音中...",
            canvasTesting: "画布测试",
            endOfProcess: "流程结束",
            restart: "重新开始",
            start: "开始",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "收起解析",
                  expandParsing: "展开解析",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "， 语气词过多",
                  second: "次",
                  ratingOverview: "评分总览",
                  totalScore: "总分",
                  section: "第",
                  name: "名",
                  of: "(共",
                  people: "人)",
                  scriptScore: "话术得分",
                  common: "共",
                  keyPoints: "个要点，",
                  notReached: "个未达到，",
                  have: "有",
                  violationOfRegulations: "处违规",
                  emotionalScore: "情绪得分",
                  appear: "出现",
                  secondaryNegativeEmotions: "次负面情绪",
                  expressionScore: "表达得分",
                  speakTooFast: "语速过快",
                  times: "次，",
                  speakTooSlowly: "语速过慢",
                  average: "平均",
                  wordminute: "字/分钟",
                },
              },
              score: {
                index: {
                  studentNodeName: "学员节点名称",
                  keyPointName: "要点名称",
                  slideNodeName: "幻灯片节点名称",
                  dialogue: "对话",
                  mainPoints: "要点",
                  slide: "幻灯片",
                  detectionResult: "检测结果",
                  satisfy: "满足",
                  dissatisfaction: "不满足",
                  score: "得分",
                  script: "话术",
                  semantics: "语义",
                  scriptViolation: "话术违规",
                  emotion: "情绪",
                  expression: "表达",
                },
              },
              taskTitle: { index: { branch: "分" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "本次",
                  lastTime: "上次",
                  comprehensiveEvaluation: "综合评价",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<span style="margin-left: 8px">· 标准话术累积',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "个字，学员跟读命中的字数共",
                  aWordspan: "个字</span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    '次</span><br /><span style="margin-left: 8px">· 语速过慢',
                  timesspan: "次</span>",
                  tooManyModalParticles:
                    '<span style="margin-left: 8px">· 语气词过多',
                  complete: "内容完整",
                  keyHit: "要点命中",
                  languageNorm: "语言规范",
                  fluentExpression: "表达流畅",
                  emotionallyPositive: "情感积极",
                  radarChart: "雷达图",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<span style="margin-left: 8px">· 完整流程数',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<span style="margin-left: 8px">· 命中要点数',
                  spanStylemarginLeftpxYes:
                    '<span style="margin-left: 8px">· 有',
                  dealingWithIllegalWordsspan: "处违规词</span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<span style="margin-left: 8px">· 语速过快',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '次</span><br /><span style="margin-left: 8px">· 语气词过多',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<span style="margin-left: 8px">· 负面情绪',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "评分记录",
                  firstRating: "首次评分",
                  totalScore: "总分",
                  changeTo: "变更为",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "沟通阶段",
                  communicationObjectives: "沟通目标",
                  scoringCriteria: "评分标准",
                  suggestionsForImprovement: "提升建议",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "剩余次数：",
                  second: "次",
                  doItAgain: "再来一次",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "浏览器不支持音频播放。",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "话术点评与建议",
                  regenerate: "重新生成",
                  generate: "生成",
                  generationFailed: "生成失败",
                },
              },
              resultModal: {
                index: { return: "返回", viewDetails: "查看详情" },
              },
            },
            index: {
              reRatingSuccessful: "重新评分成功",
              reRatingFailed: "重新评分失败",
              downloadRecording: "下载录音",
              downloadText: "下载文本",
              download: "下载",
              reRating: "重新评分",
              submitARatingAppeal: "提交评分申诉",
              reviewSubmission: "复核提交",
              saveChanges: "保存修改",
              previousSentence: "上一句",
              nextSentence: "下一句",
              score: "评分",
              scoringSituation: "得分情况",
              dialogueAnalysis: "对话解析",
            },
          },
          index: {
            ratingAppealInProgress: "评分申诉中",
            operation: "操作",
            wrongQuestionSet: "错题集",
            ratingUpdate: "评分更新",
            viewDetails: "查看详情",
            curriculum: "课程",
            taskName: "任务名称",
            taskType: "任务类型",
            score: "得分",
            scoringResults: "得分结果",
            time: "时间",
            courseClassification: "课程分类",
            whole: "全部",
            learningTime: "学习时间",
            historicalLearningData: "历史学习数据",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "练习暂停",
              lastProgress: "上次进度",
              continuePracticing: "继续练习",
              practice: "练习",
              startPracticing: "开始练习",
              historicalData: "历史数据",
              courseIntroduction: "课程介绍：",
              exerciseDifficulty: "练习难度：",
              practiceCompletionRequirements: "练习完成要求：",
              practiceFrequency: "练习次数≥",
              second: "次",
              anyScore: "任意一次得分≥",
              branch: "分",
              or: "或",
              taskList: "任务列表",
              practiceTime: "练习时间：",
              to: "至",
              unlimitedTime: "不限时间",
              completionStatus: "完成状态",
              numberOfExercises: "练习次数",
              maximumScore: "最高分",
              operation: "操作",
              enteringLearning: "进入学习",
              historicalLearningData: "历史学习数据",
              wrongQuestionSet: "错题集",
            },
          },
          index: {
            practiceTasks: "练习任务",
            examTasks: "考试任务",
            task: "任务",
          },
          learn: {
            index: {
              courseName: "课程名称",
              courseIntroduction: "课程介绍",
              learningContent: "学习内容",
              downloadLearningMaterials: "学习资料下载",
              startPracticing: "开始练习",
            },
          },
          test: {
            index: {
              taskList: "任务列表",
              courseIntroduction: "课程介绍：",
              examDifficulty: "考试难度：",
              requirementsForPassingTheExam: "考试通过要求：",
              examScore: "考试得分≥",
              branch: "分",
              examTime: "考试时间：",
              to: "至",
              unlimitedTime: "不限时间",
              completionStatus: "完成状态",
              completionTimes: "完成次数",
              maximumScore: "最高分",
              operation: "操作",
              immediateExam: "立即考试",
              history: "历史记录",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "参考话术" } },
              flow: {
                index: {
                  wrongQuestionItem: "错题项 （",
                  score: "得分",
                  TotalScore: "/总分",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "服务器错误" } },
                  index: {
                    startRecording: "开始录音",
                    endRecording: "结束录音",
                    startPracticing: "开始练习",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "请输入",
                    sendOut: "发送",
                    startPracticing: "开始练习",
                  },
                },
                voice: {
                  index: {
                    startRecording: "开始录音",
                    endRecording: "结束录音",
                    startPracticing: "开始练习",
                  },
                  hook: { index: { failInSend: "发送失败" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions: "本次错题练习得分",
                  tips: "提示",
                  areYouSureToEnd: "是否确认结束？",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "再次练习未掌握错题",
                  continue: "继续",
                  returnToTheSetOfIncorrectQuestions: "返回错题集",
                  end: "结束",
                  wrongQuestionItem: "错题项",
                  numberOfExercisesThisTime: "本次练习次数",
                  masteringTheSituation: "掌握情况",
                  mastered: "已掌握",
                  notMastered: "未掌握",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "再次练习本题",
                  nextQuestion: "下一题",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "错题练习",
                endExercise: "结束练习",
              },
            },
            text: {
              index: {
                mispracticeExercises: "错题练习",
                endExercise: "结束练习",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "错题练习",
                endExercise: "结束练习",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "查看话术",
                  section: "第",
                  secondaryDiscourseTechnique: "次话术",
                },
              },
              tableItem: {
                count: { second: "次" },
                scoreRuleList: {
                  noRelevantInformationFound: "未查询到相关信息",
                  recallWords: "召回词",
                  branch: "分",
                  answerIncorrectly: "答错",
                  second: "次",
                  wrongAnswerRate: "答错率",
                },
                standardScript: {
                  standardScript: "标准话术",
                  myScript: "我的话术",
                },
                status: { mastered: "已掌握", notMastered: "未掌握" },
                index: {
                  totalNumberOfQuestionsAnswered: "共计答题次数",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "错题已练习次数",
                },
              },
            },
            index: {
              wrongQuestionList: "错题列表",
              wrongQuestionSet: "错题集",
              mispracticeExercises: "错题练习",
              taskName: "任务名称",
              staff: "员工",
              masteringTheSituation: "掌握情况",
              whole: "全部",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "练习次数≥",
              second: "次",
              or: "或",
              excellentScoreAtAnyOneTime: "任意一次得分优秀(≥",
              points: "分)",
              examScore: "考试得分≥",
              branch: "分",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "随机平均分配",
              assignToDesignatedPersonnel: "分配给指定人员",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams: "增加考试次数成功",
              failedToIncreaseTheNumberOfExams: "增加考试次数失败",
              increaseTheNumberOfExams: "增加考试次数",
              selectObjects: "选择对象",
            },
          },
          executionFilter: { index: { participationRate: "参与率" } },
          intelligentReview: {
            index: {
              overallReview: "整体点评",
              pleaseEnterTheReviewCriterianotMandatory:
                "请输入点评标准（非必填）",
              commentaryOnSingleSentenceRhetoric: "单句话术点评",
              realTimeReview: "实时点评",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "考试平均分",
            averageExamDurationmin: "平均考试时长（min）",
            accumulatedPracticeDurationmin: "累计练习时长（min）",
            accumulatedPracticeTimes: "累计练习次数",
            totalScore: "总分",
            average: "平均分",
            total: "总数",
            keyHitCount: "要点命中次数",
            practiceAverageScore: "练习平均分",
            averagePracticeDurationmin: "平均练习时长（min）",
            topOfTheHighestScore: "最高分Top3",
            branch: "分",
            errorProneItemTop: "易错项Top5",
            hitRate: "命中率",
            hitRateOfKeyPointsInScript: "话术要点命中率",
            averageScriptScore: "话术平均得分",
            sortByProcess: "按流程排序",
            sortByScore: "按得分排序",
            averageScoreOfThisPagetotalScoreOfThisPage: "该页平均分/该页总分",
            averageScoreOfThisItemtotalScoreOfThisItem: "该项平均分/该项总分",
            details: "详情",
            viewRules: "查看规则",
            standardScript: "标准话术",
          },
          scoreDetailList: {
            section: "第",
            second: "次",
            practice: "练习",
            examination: "考试",
            notMeetingStandards: "未达标",
            meetingStandards: "达标",
            excellent: "优秀",
            frequency: "次数",
            fraction: "分数",
            result: "结果",
            operation: "操作",
            viewDetails: "查看详情",
          },
          index: {
            common: "共",
            people: "人",
            staff: "员工",
            notMeetingStandards: "未达标",
            meetingStandards: "达标",
            excellent: "优秀",
            notStarted: "未开始",
            haveInHand: "进行中",
            completed: "已完成",
            passed: "已通过",
            hangInTheAir: "未完成",
            failed: "未通过",
            unlimitedTime: "不限时间",
            to: "至",
            maximumScoringCriteria: "最高得分标准",
            completionStatus: "完成状态",
            employeeName: "员工姓名",
            frequency: "次数",
            practice: "练习",
            examination: "考试",
            average: "平均分",
            speechCraftAvgScore: "话术平均得分",
            emotionAvgScore: "情绪平均得分",
            speedAvgScore: "表达平均得分",
            flowAvgScore: "流程平均得分",
            practiceCostTime: "累计时长",
            participateCommitRate: "达标率",
            participateExcellentRate: "达优率",
            duration: "时长",
            score: "得分",
            scoringResults: "得分结果",
            flowScore: "流程得分",
            craftScore: "话术得分",
            emotionScore: "情绪得分",
            speedScore: "表达得分",
            contentIntegrity: "内容完整度",
            pointHitRate: "要点命中率",
            emotionNegativeRate: "情绪负向占比",
            paraPhaSiaRate: "语速异常占比",
            toneOvermuchRate: "语气词过多占比",
            maximumScore: "最高分",
            highestScoreResult: "最高得分结果",
            state: "状态",
            averageDurationmin: "平均时长(min)",
            lately: "最近",
            time: "时间",
            operation: "操作",
            viewDetails: "查看详情",
            wrongQuestionSet: "错题集",
            taskName: "任务名称",
            completeRequirements: "完成要求",
            publishTo: "发布对象",
            taskTime: "任务时间",
            fractionalComposition: "分数构成",
            totalScore: "总分",
            branch: "分",
            Script: "（话术",
            scoreEmotion: "分+情绪",
            fractionExpression: "分+表达",
            points: "分）",
            implementationRate: "参与率",
            numberOfExecutors: "参与人数",
            numberOfPeopleNotExecuted: "未参与人数",
            completionRate: "完成率",
            numberOfCompletedPersonnel: "完成人数",
            numberOfUnfinishedPersonnel: "未完成人数",
            complianceRate: "达标率",
            numberOfQualifiedPersonnel: "达标人数",
            numberOfNonCompliantIndividuals: "未达标人数",
            excellentRate: "达优率",
            participateCompleteRate: "参与完成率",
            numberOfOutstandingIndividuals: "优秀人数",
            numberOfNonOutstandingIndividuals: "非优秀人数",
            export: "导出",
            taskData: "任务数据",
            resultAnalysis: "结果分析",
            scoreDetails: "得分详情 -",
            dataOverview: "数据概览",
            taskDetail: "任务明细",
            scriptAnalysis: "话术分析",
            flowAnalysis: "流程分析",
            expressionAnalysis: "表达分析",
            emotionAnalysis: "情绪分析",
            byTime: "时间维度视图",
            byNumber: "次数维度视图",
            personData: "按人员统计",
            numberData: "按次记录明细",
            count: "按次数",
            person: "按人数",
            reachTimes: "触达次数",
            outOfReachTimes: "未触达次数",
            seeOutOfReach: "点击查看未触达",
            accumulative: "累计",
            script: "话术",
            times: " 次",
            sentence: " 句",
            expressionAverage: "表达平均分",
            modalAnalysis: "语气词分析",
            total: "共 ",
            muchModalAnalysis: "存在语气词过多 ",
            normal: "正常 ",
            speedAnalysis: "语速分析",
            speedDistribution: "语速分布",
            speakingSpeed: "说话语速",
            normalSpeak: "正常语速 ",
            tooFastSpeak: "过快语速 ",
            tooSlowlySpeak: "过慢语速 ",
            violationWord: "违规词",
            violationWordAnalysis: "违规词分析",
            noData: "暂无",
            emotionalScore: "情绪得分",
            emotionalDistribution: "情绪分布",
            negativeEmotion: "负向情绪 ",
            normalEmotion: "正常情绪 ",
            getDetail: "查看详情",
            barPattern: "条形图视图",
            timeDimension: "时间纬度视图",
            timesDimension: "次数维度视图",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "平均分",
                  averageScoreOfScript: "话术平均分",
                  averageEmotionalScore: "情绪平均分",
                  expressionAverageScore: "表达平均得分",
                  processAverageScore: "流程平均分",
                  accumulatedDuration: "累积时长",
                  accumulatedTimes: "累计次数",
                  participationComplianceRate: "参与达标率",
                  participationRateForAchievingExcellence: "参与达优率",
                  branch: "分",
                  numberOfPeople: "人数",
                  averageScoreOfExpression: "表达平均分",
                  people: "人",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: { index: { negativeEmotions: "负向情绪" } },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "话术平均得分",
                  branch: "分",
                },
                useSummaryDetail: { keyHitRate: "要点命中率" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "智能点评",
            pleaseImproveTheConfiguration: "请完善配置",
            examResults: "考试结果",
            showTheResultsAfterEachExam: "每次考试完展示结果",
            showTheResultsAfterTheLastExam: "最后一次考试完展示结果",
            doNotDisplayResults: "不展示结果",
            resultDisplay: "结果展示",
            score: "得分",
            scoringResults: "得分结果",
            allowViewingScoreDetails: "允许查看得分详情",
            viewCanvasConfiguration: "查看画布配置",
            synchronizationFailedProcessNodeHasChanged:
              "同步失败，流程节点已产生变更",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "同步成功，请点击发布更新课程",
            retrievedTheLatestCanvasConfiguration: "已拉取最新画布配置",
            synchronizeCanvasConfiguration: "同步画布配置",
            scoringMethod: "评分方式",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "分，流程{0}分，话术{1}分，情绪{2}分，语速{3}分）",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "优秀标准需高于达标标准",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "评分标准需要满足大于0%，小于等于100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "评分标准需要满足大于0分，小于等于{0}分",
            doRatingsRequireManualReview: "评分是否需人工审核",
            allocationStrategy: "分配策略",
            pleaseSelectTheAssignedPersonnel: "请选择分配人员",
            pleaseSelectADesignatedPerson: "请选择指定人员",
            timeLimitForSingleQuestionAnswering: "单题作答时长限制",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault: "默认发布给新添加员工",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "重新评分表示将按照最新画布的得分规则进行匹配，当前任务存在人工已复核的评分数据，是否要覆盖分数",
            notCovered: "不覆盖",
            cover: "覆盖",
            participationSituation: "参与情况",
            numberOfParticipants: "参与人数",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "此任务选择的练习对象人数求和",
            sumOfTheNumberOfParticipantsInTheTask: "已参与任务的人数求和",
            numberOfCompletedPersonnel: "完成人数",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "满足任务完成要求的人数求和",
            numberOfQualifiedPersonnel: "达标人数",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "满足任务得分达标的人数求和",
            excellentPersonnel: "优秀人数",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "满足任务得分优秀的人数求和",
            participationRate: "参与率",
            numberOfParticipantsNumberOfParticipants: "已应参人数 / 应参与人数",
            numberOfCompletedNumberOfParticipants: "完成人数 / 应参与人数",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "达标人数 / 应参与人数",
            excellentNumbernumberOfParticipants: "优秀人数 / 参与人数",
            participationCompletionRate: "参与完成率",
            numberOfCompletedParticipated: "完成人数 / 参与人数",
            participationComplianceRate: "参与达标率",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "达标人数 / 参与人数",
            participationRateForAchievingExcellence: "参与达优率",
            accumulatedDuration: "累积时长",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "学员参与任务时长求和",
            averageDuration: "平均时长",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "学员参与任务时长求和/总参与次数",
            accumulatedTimes: "累积次数",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "学员参与任务次数求和",
            averageScore: "平均分",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "参与该任务人员的平均得分",
            maximumScore: "最高分",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "参与该任务人员的最高分",
            averageScoreOfScript: "话术平均分",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "学员话术得分求和/总参与次数",
            totalScoreOfScript: "话术总分",
            theTotalScoreOfTheScriptSetUnderThisTask: "该任务下设置的话术总分",
            processAverageScore: "流程平均分",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "学员流程得分求和/总参与次数",
            totalProcessScore: "流程总分",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "该任务下设置的流程总分",
            averageEmotionalScore: "情绪平均分",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "学员情绪得分求和/总参与次数",
            totalEmotionalScore: "情绪总分",
            theTotalScoreOfEmotionsSetUnderThisTask: "该任务下设置的情绪总分",
            averageScoreOfExpression: "表达平均分",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "学员表达得分求和/总参与次数",
            totalScoreOfExpression: "表达总分",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "该任务下设置的表达总分",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "开始执行重新评分，可跳转至任务中心查看进度",
            reRating: "重新评分",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "重新评分表示将按照最新画布的得分规则进行匹配，会覆盖当前得分结果",
            releaseTime: "发布时间",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "已审核",
              pendingReview: "待审核",
              enterKeywordSearch: "输入关键词搜索",
            },
            pageRightTable: {
              index: {
                employeeName: "员工姓名",
                systemRating: "系统评分",
                scoringResults: "得分结果",
                duration: "时长",
                time: "时间",
                state: "状态",
                whetherToModifyTheRating: "是否修正评分",
                finalScore: "最终得分",
                reviewedBy: "审核人",
                operation: "操作",
                viewScoreDetails: "查看得分详情",
                viewStatus: "查看状态",
                whole: "全部",
                correctionStatus: "修正状态",
                completeReview: "完成审核",
                tips: "提示",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "学员最终成绩依当前最终审核结果为准，请确认",
              },
            },
          },
          index: { manualReview: "人工审核" },
        },
        review: {
          index: {
            employeeName: "员工姓名",
            score: "得分",
            scoringResults: "得分结果",
            duration: "时长",
            taskName: "任务名称",
            numberOfAppealScripts: "申诉话术数量",
            appealTime: "申诉时间",
            processStatus: "流程状态",
            scoreAfterReview: "复核后得分",
            reviewedBy: "复核人",
            operation: "操作",
            viewScoreStatus: "查看得分情况",
            whole: "全部",
            manualReview: "人工复核",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "练习任务数据",
            implementationRate: "执行率",
            numberOfExecutors: "执行人数",
            numberOfPeopleNotExecuted: "未执行人数",
            completionRate: "完成率",
            numberOfCompletedPersonnel: "完成人数",
            numberOfUnfinishedPersonnel: "未完成人数",
            complianceRate: "达标率",
            numberOfQualifiedPersonnel: "达标人数",
            numberOfNonCompliantIndividuals: "未达标人数",
            excellentRate: "达优率",
            numberOfOutstandingIndividuals: "优秀人数",
            numberOfNonOutstandingIndividuals: "非优秀人数",
            examTaskData: "考试任务数据",
            published: "已发布",
            courses: "个课程",
            courseData: "课程数据",
            practiceTasks: "练习任务",
            examTasks: "考试任务",
          },
          practiceSummary: {
            practiceSummary: "练习小结",
            numberOfPracticeTasks: "练习任务数",
            numberOfExercises: "练习次数",
            accumulatedPracticeDuration: "累计练习时长",
            complianceRate: "达标率",
            excellentRate: "达优率",
            ranking: "排行",
          },
          rankingList: {
            numberOfExercises: "练习次数",
            exerciseDuration: "练习时长",
            complianceRate: "达标率",
            excellentRate: "达优率",
            diligenceChart: "勤奋榜",
            excellentList: "优秀榜",
            ranking: "排名",
            fullName: "姓名",
            i: "我",
          },
          taskCard: {
            end: "截止：",
            noDeadline: "无截止日期",
            second: "次",
            branch: "分",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "暂无任务",
            completed: "已完成",
          },
          filter: { employeeDepartment: "员工部门" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "导出成功，请前往任务中心查看",
              export: "导出",
            },
          },
          filterConfig: {
            dropdown: { custom: "自定义" },
            modal: {
              updateSuccessful: "更新成功",
              successfullySaved: "保存成功",
              setAsACommonReport: "设置为常用报表",
              updateCurrentReport: "更新当前报表",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "平均分",
                  averageScoreOfScript: "话术平均得分",
                  averageEmotionalScore: "情绪平均得分",
                  expressionAverageScore: "表达平均得分",
                  processAverageScore: "流程平均得分",
                  branch: "分",
                },
              },
              comprehensiveEvaluation: { const: { data: "数据" } },
              doneStatus: {
                index: {
                  practice: "练习",
                  examination: "考试",
                  executed: "已执行",
                  unexecuted: "未执行",
                  accumulatedExamDuration: "累计考试时长",
                  examFrequency: "考试次数",
                },
              },
              filter: {
                index: {
                  practice: "练习",
                  examination: "考试",
                  pleaseCompleteTheRequiredFields: "请完善必填项",
                  staff: "员工",
                  taskType: "任务类型",
                  pleaseSelect: "请选择",
                  taskName: "任务名称",
                },
              },
            },
            index: {
              completionStatus: "完成情况",
              comprehensiveEvaluation: "综合评价",
              abilityGrowthTrend: "能力成长趋势",
              errorProneAnalysis: "易错点分析",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "活跃人数",
            accumulatedPracticeDuration: "累计练习时长",
            averagePracticeDuration: "平均练习时长",
            people: "人",
            numberOfPracticeTasks: "练习任务数",
            numberOfExercises: "练习次数",
            exerciseDuration: "练习时长",
            second: "次",
            avgScore: "平均分",
            speechCraftAvgScore: "话术平均得分",
            emotionAvgScore: "情绪平均得分",
            speedAvgScore: "表达平均得分",
            flowAvgScore: "流程平均得分",
            practiceCostTime: "累计时长",
            totalPracticeTimes: "累计次数",
            participateCommitRate: "参与达标率",
            participateExcellentRate: "参与达优率",
          },
        },
        index: {
          employeeProfile: "员工画像",
          overview: "总览",
          personalSignage: "个人看板",
          ongoingExercises: "进行中的练习",
          ongoingExams: "进行中的考试",
          practiceTaskProgress: "练习任务进度",
          examTaskProgress: "考试任务进度",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "请选择概念词",
              sessionDetectionSettings: "会话检测设置",
              successfullySaved: "保存成功",
              abnormalSpeechSpeed: "语速异常",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "支持自定义语速检测标准，结果会判断为语速正常、语速过快、语速过慢。当出现语速异常（过快/过慢），会按评分规则进行减分",
              speechSpeedTooFastGreaterThanEveryMinute: "语速过快：每分钟大于",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "个字，语速过慢: 每分钟小于",
              charactersIgnoringLessThan: "个字，忽略小于",
              aSentenceOfWords: "字的句子",
              excessiveModalParticles: "语气词过多",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "支持自定义语气词，并设定语气词过多规则。当出现语气词过多时，会按评分规则进行减分",
              theNumberOfRepetitionsIs: "重复次数为",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "次时，判定为语气词过多",
              definitionOfSensitiveViolations: "敏感违规定义",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "支持自定义违规词词库，检测一句话中是否出现违规词。当出现违规词时，会按评分规则进行减分",
              violationWords: "违规词：",
              emotionalDetection: "情绪检测",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "按系统情绪模型对话术进行情绪检测，结果会判断为情绪正常、情绪负向 (包括负面、生气、抱怨)。当出现情绪负向时，会按评分规则进行减分",
              preserve: "保存",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "评分维度",
              ratingPoints: "评分点",
              explain: "说明",
              type: "类型",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "随机数据" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<span style="margin-left: 8px">· 标准话术累积',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "个字，学员跟读命中的字数共",
                  aWordspan: "个字</span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    '次</span><br /><span style="margin-left: 8px">· 语速过慢',
                  timesspan: "次</span>",
                  tooManyModalParticles:
                    '<span style="margin-left: 8px">· 语气词过多',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "分值为" },
                  item: {
                    violationOnceReduced: "违规1次减",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "语速异常（包括语速过快、语速过慢）1次减",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "语气词过多1次减",
                    oneNegativeDecreaseInEmotions: "情绪负向1次减",
                    branch: "分",
                  },
                  wrap: { name: "名称", preserve: "保存" },
                },
              },
              index: {
                complete: "内容完整",
                keyHit: "要点命中",
                accurateFollowupReading: "跟读准确",
                languageNorm: "语言规范",
                speechSpeed: "语速",
                modalParticles: "语气词",
                emotionallyPositive: "情感积极",
                scoringModel: "评分模型",
                successfullyModified: "修改成功",
                newSuccessfullyCreated: "新建成功",
              },
            },
            index: {
              scoringModel: "评分模型",
              newModel: "新建模型",
              model: "模型",
              type: "类型",
              operation: "操作",
              preview: "预览",
              edit: "编辑",
            },
          },
          index: {
            scoringModel: "评分模型",
            ratingDimension: "评分维度",
            sessionDetectionSettings: "会话检测设置",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: { pleaseSelect: "请选择", hit: "命中", misses: "未命中" },
            },
          },
          index: {
            duration: "时长",
            processScore: "流程得分",
            branch: "分",
            contentCompleteness: "内容完整度",
            scriptScore: "话术得分",
            keyHitRate: "要点命中率",
            emotionalScore: "情绪得分",
            negativeProportionOfEmotions: "情绪负向占比",
            expressionScore: "表达得分",
            theProportionOfAbnormalSpeechSpeed: "语速异常占比",
            excessiveProportionOfModalParticles: "语气词过多占比",
            learningRecordData: "学习记录数据",
            staff: "员工",
            curriculum: "课程",
            mainPoints: "要点",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "表格",
                        lineChart: "折线图",
                        barChart: "柱状图",
                        pieChart: "饼图",
                      },
                      index: { switchingTypes: "切换类型" },
                    },
                  },
                  index: {
                    saveSuccessful: "保存成功",
                    editChart: "编辑图表",
                    chartName: "图表名称",
                    deleteSuccessful: "删除成功",
                    refresh: "刷新",
                    export: "导出",
                    areYouSureToDeleteIt: "确定删除吗？",
                    delete: "删除",
                    viewFilteringCriteria: "查看筛选条件",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "导出成功，请前往任务中心查看",
              },
            },
            header: {
              index: {
                saveSuccessful: "保存成功",
                deleteSuccessful: "删除成功",
                editReport: "编辑报表",
                reportName: "报表名称",
                refresh: "刷新",
                areYouSureToDeleteIt: "确定删除吗？",
                delete: "删除",
                exportToExcel: "导出Excel",
                exportSnapshot: "导出快照",
                export: "导出",
                authority: "权限",
                permissionSettings: "权限设置",
                reportVisiblePermissions: "报表可见权限",
                visibleToEveryone: "所有人可见",
                onlyVisibleToOneself: "仅自己可见",
                designatedPersonnelVisible: "指定人员可见",
                pleaseSelect: "请选择",
                reportDataPermissions: "报表数据权限",
                restrictedByUserPermissions: "受限于用户权限",
                visiblePeopleAreTheSame: "可见人相同",
              },
            },
            titleFooter: {
              index: { addReport: "新增报表", reportName: "报表名称" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "导出成功，请前往任务中心查看",
            dataQueryInProgress: "数据查询中...",
            customKanban: "自定义看板",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "表格",
                lineChart: "折线图",
                barChart: "柱状图",
                pieChart: "饼图",
              },
              index: {
                successfullyAdded: "添加成功",
                viewSQL: "查看sql",
                export: "导出",
                addToReport: "添加到报表",
              },
            },
            refer: {
              hooks: {
                data: "数据",
                interrogativeSentence: "问句",
                historicalQuery: "历史查询",
              },
              index: { copySuccessful: "复制成功", copy: "复制" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "未达标",
                        meetingStandards: "达标",
                        excellent: "优秀",
                        notStartedYet: "未开始",
                        inProgress: "进行中",
                        completed: "已完成",
                        incomplete: "未完成",
                      },
                    },
                  },
                  const: {
                    taskName: "任务名称",
                    department: "部门",
                    studentName: "学员姓名",
                    scoringResults: "得分结果",
                    taskCompletionStatus: "任务完成状态",
                  },
                  index: { pleaseSelectADimension: "请选择维度" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "输入@可打开弹框进行维度选择",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "请输入问询语句直接进行查询",
                query: "查询",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "导出成功，请前往任务中心查看",
            dataQueryInProgress: "数据查询中...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "关键词搜索",
                previous: "上一个",
                next: "下一个",
                theObjectThatTriggersTheScrollingEventIs:
                  "触发滚动事件的对象是",
                userScrolling: "用户滚动",
              },
            },
            summary: {
              index: {
                reminder: "提示语：",
                edit: "编辑",
                name: "名称",
                hintLanguage: "提示语",
              },
            },
            viewpoint: { index: { viewpointExtraction: "观点提取" } },
            outline: {
              index: {
                numberOfKeywords: "关键词次数",
                speaker: "说话人",
                speechDuration: "说话时长",
                proportionOfSpeaking: "说话占比",
                maximumSpeechDuration: "最长发言时长",
                numberOfQuestions: "提问数量",
                averageSpeechSpeed: "平均语速",
                conversationalActions: "会话动作",
                contentSummary: "内容摘要",
                generateSummary: "生成摘要",
                keywordCloud: "关键词词云",
              },
            },
          },
          index: {
            outline: "概要",
            viewpoint: "观点",
            contentSummary: "内容摘要",
            viewpointExtraction: "观点提取",
            summaryOfKeyPoints: "要点总结",
            videoAnalysis: "视频分析",
          },
        },
        list: {
          index: {
            delete: "删除",
            uploadSuccessful: "上传成功",
            videoList: "视频列表",
            uploadVideo: "上传视频",
            videoName: "视频名称",
            uploadTime: "上传时间",
            endTime: "结束时间",
            analyzeProgress: "分析进度",
            haveInHand: "进行中",
            completed: "已完成",
            fail: "失败",
            toBegin: "待开始",
            operation: "操作",
            see: "查看",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "添加工作时间" },
        },
      },
      index: {
        businessWorkingHours: "业务工作时间",
        newlyBuild: "新建",
        name: "名称",
        describe: "描述",
        operation: "操作",
        edit: "编辑",
        delete: "删除",
        editBusinessWorkingHours: "编辑业务工作时间",
        newBusinessWorkingHours: "新建业务工作时间",
        workingHours: "工作时间",
      },
    },
    algorithmSemanticIntelligence: { intelligentExtraction: "智能抽取" },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "您确认要取消吗？取消后将不能再恢复",
        },
      },
    },
    accountManagement: {
      securitySetting: "安全设置",
      accountManagement: "账户管理",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "至",
            switchToIPAddressMode: "切换为IP地址模式",
            switchToIPSegmentMode: "切换为IP段模式",
          },
        },
      },
      index: {
        saveSuccessful: "保存成功",
        pleaseImproveTheConfiguration: "请完善配置",
        securitySetting: "安全设置",
        employeeLoginIPRestrictions: "员工登陆IP限制",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "限制员工可登录的IP范围，超出范围不允许登陆。仅对网站生效，移动app不受限制，管理员不受限制。",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "新增或修改时，请填写外网IP段（同一C段）或某个固定外网IP地址。",
        allowLoginIPRange: "允许登陆的IP范围",
        preserve: "保存",
      },
    },
    coachAdminEntry: { speechRecognition: "语音识别" },
    billingCenter: {
      template: {
        details: "详情",
        languageType: "语言类型",
        aSREngine: "ASR引擎",
        voiceQualityQuota: "语音质检配额",
        realtimeData: "实时数据",
        calculatedByDay: "按天计算",
      },
      component: {
        aSRName: "ASR名称",
        language: "语言",
        remainingQuota: "剩余配额",
        hours: "小时",
        usedQuota: "已用配额",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "操作保存成功！",
        deletedSuccessfully: "删除成功！",
        pleaseSelectLanguage: "请选择语言",
        pleaseConfigureRules: "请配置规则",
        updateSuccessful: "更新成功！",
        operationSuccessful: "操作成功！",
      },
      template: {
        aSRLanguage: "ASR语言",
        pleaseSelect: "请选择",
        model: "模型",
        pleaseSelectLanguage: "请选择语言",
        delete: "删除",
        newASRModel: "新建ASR模型",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "竞品分析" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "电商评论筛选器",
        allComments: "全部评论",
        ecommerceReviewManagement: "电商评论管理",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "社媒评论筛选器",
        allComments: "全部评论",
        socialMediaReviewManagement: "社媒评论管理",
      },
    },
    conversationAnalysisDataAnalysis: { competitionAnalysis: "竞品分析" },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "头像",
        realname: "姓名",
        nickname: "昵称",
        name: "姓名",
        email: "账号（邮箱）",
        mobile: "电话",
        agentId: "工号",
        position: "岗位",
        role: "角色",
        actions: "操作",
        createUser: "新增员工",
        editUser: "编辑员工",
        account: "账号",
        originPassword: "初始密码",
        newPassword: "新密码",
        resumePassword: "确认密码",
        userGroup: "员工组",
        authorizationGroup: "权限组",
        resetPassword: "重置密码",
      },
      userGroups: {
        id: "ID",
        name: "名称",
        userCount: "员工数",
        actions: "操作",
        createUserGroup: "新增员工组",
        editUserGroup: "编辑员工组",
        member: "成员",
      },
      role: {
        id: "ID",
        name: "名称",
        actions: "操作",
        all: "全部",
        taskName: "任务",
        readTask: "任务查看",
        manageTemplate: "模板管理",
        manualInspection: "人工复检",
        readResultAll: "结果查看",
        readResultPersonal: "结果查看（个人）",
        readProcess: "质检进程查看",
        readReport: "统计与报表",
      },
    },
    download: {
      downloadCenter: {
        createTime: "日期",
        taskName: "名称",
        size: "大小",
        status: "状态",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "名称",
            remark: "描述",
            actions: "操作",
          },
          checkPoint: {
            name: "名称",
            remark: "描述",
            type: "类型",
            gradeType: "评价方式",
            conditionLogic: "规则",
            predeterminedScore: "分值",
            weight: "权重",
            status: "状态",
            actions: "操作",
            switchOn: "启用",
            switchOff: "停用",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "时间",
            customer: "客户",
            customerPhone: "客户电话",
            callType: "通话类型",
            source: "来源",
            voiceRecording: "通话录音",
            graded: "评分",
            openingGreeting: "开场问候",
          },
          checkPointList: {
            name: "质检点",
            type: "类型",
            deductScore: "分数",
            isHit: "命中情况",
            status: "复核",
            finalDeductScore: "确认扣分",
            hit: "命中",
            notHit: "未命中",
          },
        },
      },
    },
    custom: { keywords: "对话关键词" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "系统设置" },
      systemIntegration: {
        text: "系统集成",
        subTitle: {
          udesk: "Udesk客服系统",
          udeskWhite: "客服系统",
          wechat: "企业微信",
          taobao: "淘宝千牛",
          weifeng: "微丰",
        },
      },
      columnsManage: { text: "质检字段管理" },
      customerField: { text: "客户字段" },
      speechRecognition: { text: "语音识别" },
      user: { text: "员工管理" },
      userGroups: { text: "员工组管理" },
      role: { text: "角色管理" },
      dataMigration: { text: "数据迁移" },
      taskCenter: { text: "任务中心" },
      recordUpload: { text: "本地录音上传" },
      anomalousData: { text: "异常数据" },
      systemLog: { text: "消息日志" },
      msgCenter: { text: "消息中心" },
      wechatDocking: { text: "企微对接" },
    },
    tasks: {
      homePage: { text: "首页" },
      workbench: { text: "工作台" },
      semanticIntelligence: { text: "语义智能" },
      intelligentAnalysis: { text: "智能分析" },
      intelligentLabel: { text: "智能标签" },
      admin: { text: "管理" },
      callManage: { text: "通话管理" },
      dialogueManage: { text: "对话管理" },
      ticketManage: { text: "工单管理" },
      dataAnalysis: { text: "数据分析" },
      customerService: { text: "客服中心" },
      clientCenter: { text: "客户中心" },
      insight: { text: "Insight" },
      trainingCenter: { text: "训练中心" },
      templates: { text: "模板管理" },
      wechatQaTasks: { text: "企业微信工作台" },
      wechatQaSetting: { text: "企业微信分析配置" },
      wechatQaChartRecord: { text: "企业微信会话" },
      wechatStaffCenter: { text: "企微员工中心" },
      callAnalysis: { text: "会话分析" },
      caseBaseFolder: { text: "案例库" },
      coachTask: { text: "辅导任务" },
      teamAnalysis: { text: "团队分析" },
      customer: { text: "客户中心" },
      analysisEnable: { text: "分析赋能" },
      analysisHelp: { text: "求助分析" },
      analysisStudy: { text: "学习分析" },
      analysisReview: { text: "复盘分析" },
      analysisTags: { text: "智能标签分析" },
      analysisSentiment: { text: "舆情分析" },
      analysisBusiness: { text: "业务分析" },
      groupTest: { text: "分组测试" },
    },
    navBar: {
      billingCenter: "帮助中心",
      uploadDownload: "上传下载中心",
      setting: "系统设置",
    },
  },
  fix: {
    selectType: "选择类型：",
    all: "全部",
    call: "通话",
    text: "对话",
    wechat: "企业微信会话",
    businessTrendAnalysis: "一级分类业务趋势分析",
    noEchart: "图表暂无数据",
    checkPointisRequired: "规则配置为必填项",
    voiceDownLoad: "下载录音",
    exportTxt: "导出TXT文本",
    exportWord: "导出word文本",
    sureReRole: "确认反转说话人的角色吗？",
    sureRecover: "确认重新纠错吗？",
    sureReAsr: "确认重新识别吗？",
    reRole: "反转说话人",
    recover: "重新纠错",
    reAsr: "重新识别",
    recordingOperation: "录音操作",
    onlySave30Day: "（仅保留30天）",
    appealError: "请先对质检点进行申诉再提交",
    exportToTaskCenter: "已成功导出到上传下载中心",
    caseBaseLimit: "案例库不能超过50个",
    unnamed: "未命名",
    wordSpeed: "字/分",
    hit: "命中",
    checkPoint: "质检点",
    realTimeAdditionTip1: "实时添加：数据进入质检系统时立即开始质检",
    realTimeAdditionTip2: "定时添加：定时自动添加数据进行质检",
    null: "无",
    collcetedProps: "采集字段",
    editingNameNow: "有名称正在编辑",
    upToFourLevels: "分类最多四级",
    default: "默认",
    nameCantBeNull: "名称不能为空",
    editingNow: "有操作正在编辑",
    nameError: "名称有误",
    nameMaxLength8: "名称最大长度为8",
    callTime: "通话时间",
    sentimentValue: "客户情绪值",
    agentSentimentValue: "客服情绪值",
    sentimentAnalysis: "情绪分析",
    associatedData: "随路数据",
    notifyTheOwner: "通知群主",
    notifyTheEmployee: "通知员工本人",
    notifyTheOwnerTip: "当会话类型为群聊，才会通知群主",
    customNotifyEmployee: "自定义通知员工",
    customNotifyEmployeeTip: "触发人员为员工，则通知消息归属的员工本人",
    clickToLearnMore: "点击了解更多",
    repeatMarkingTip: "该条记录已被该质检点标注，无需重复标注",
    successfulEvaluation: "评价成功",
    evaluationFailed: "评价失败",
    qualityInspectionScore: "质检评分",
    semanticTags: "语义标签",
    smartTags: "智能标签",
    collect: "采集信息",
    appealRecord: "申诉记录",
    satisfy: "满意",
    notSatisfy: "不满意",
    appealPlaceHolder: "请输入申诉原因",
    giveUp: "放弃",
    rejectReasonPlaceHolder: "请输入驳回理由",
    reject: "驳回",
    changeRejectReason: "修改驳回原因",
    pass: "通过",
    revise: "修改",
    machineEvaluation: "机器评价",
    poor: "差",
    excellent: "好",
    moderate: "一般",
    mustMarked: "该质检点必须进行标注",
    pleaseChoose: "请选择",
    manualEvaluation: "人工评价",
    qualityInspectionTemplate: "适用质检模板",
    collectionTemplate: "适用采集模板",
    keywordsWordCloud: "关键词词云",
    editCategoryLabel: "编辑分类标签",
    justCall: "仅看通话",
    justConversation: "仅看对话",
    batchOperation: "批量操作",
    customerServicesGroup: "客服组",
    joinGroup: "加入组",
    removeGroup: "移出组",
    enterInformationEntity: "请输入信息实体内容",
    enterNotBeNull: "输入不能为空",
    operationGroup: "操作组",
    customerServiceSelected: "已选客服",
    callAndConversation: "通话/对话",
    conversation: "对话",
    system: "系统",
    customize: "自定义",
    effective: "有效",
    invalid: "无效",
    successfulAndToTaskCenter: "执行成功，请到任务中心查看",
    recalculationFailed: "重算失败",
    selectRecalculatedIndicator: "请选择要重算的指标",
    source: "请选择数据源",
    placeholder: "请输入关键词",
    cumulative: "累计型",
    average: "平均型",
    distribution: "分布律",
    originalDataCannotEmpty: "原始数据不能为空",
    inspection: "质检",
    check: "抽检",
    review: "复核",
    draftSavedSuccessfully: "草稿保存成功",
    optimization: "优化",
    agentName: "客服名称",
    allTasks: "全部质检任务",
    datePickerSelect: "选择日期",
    callSelect: "选择对话",
    taskSelect: "选择任务",
    genReport: "生成报告",
    dataSource: "数据来源",
    callsTotal: "通话总量",
    intelligentQualityInspection: "智能质检",
    percentage: "占比",
    artificialSampling: "人工抽检",
    appealNumber: "申诉量",
    totalConversation: "对话总量",
    samplingReviewRate: "抽复检率",
    data: "数据",
    inspectionResults: "质检结果",
    inspected: "已质检",
    totalScore: "总分",
    learnMore: "查看更多",
    inspectionEffect: "质检效果",
    allTotal: "总量",
    manualInspection: "人工复检",
    artificialReview: "人工复核",
    approvalAmountAndRate: "核准量&核准率",
    customerScore: "客服得分",
    inspectionRecommendations: "质检建议",
    saveDraft: "保存草稿",
    historicalAdvice: "历史建议",
    advicePlaceHolder: "请输入建议...",
    upload: "文件上传",
    customAdd: "添加筛选",
    advancedConditions: "高级条件",
    keyWord: "关键词",
    containsAll: "包含全部",
    containsAny: "包含任意",
    matchAny: "匹配任意",
    matchAll: "匹配全部",
    smartTagsOne: "智能标签一",
    smartTagsTwo: "智能标签二",
    season: "季",
    month: "月",
    week: "周",
    day: "天",
    hour: "时",
    notMoreThan366: "时间不能超过366天",
    propsCannotBeNull: "有字段未填写",
    other: "其他",
    correlationCoefficient: "相关系数",
    repetitionRate: "重复率",
    configurationItemsMoreThan20: "配置项最多添加20个",
    conversionAnalysis: "转化分析",
    correlationAnalysis: "相关性分析",
    successfulExportAndToTaskCenter: "导出成功，请前往任务中心查看",
    typeSelect: "选择类型",
    exportExcel: "导出excel",
    exportPng: "导出png",
    noDataChart: "图表暂无数据",
    placeOperator: "请选择智能标签",
    placeAdvancedConditions: "请选择高级条件",
    createAdvancedConditions: "新建高级筛选",
    moveUp: "上移",
    moveDown: "下移",
    moveLeft: "左移",
    moveRight: "右移",
    statusChangeSuccess: "状态修改成功",
    editSuccess: "修改成功",
    saveSuccess: "保存成功",
    firstPage: "首页",
    lastPage: "尾页",
    customerInfo: "客户信息",
    type: "类型",
    NumberTypeErrorMessage: "数字类型的字段信息内容只能包含数字或“.”",
    taskStartPrompt: "任务开启提示",
    startConfirm: "请点击确认按钮开启质检任务",
    continueToSubmitWithoutEvaluation: "不进行评价继续提交",
    rejectTip: "没有检测到变更操作，您确认直接驳回所有申诉吗？",
    unmarkedSelfLearningTip: "您有未标记的自学习质检点",
    markedSuccessfully: "标注成功",
    markingFailed: "标注失败",
    exceedingLength: "超出长度",
    uncategorized: "未分类",
    addSuccess: "新增成功",
    deleteSuccess: "删除成功",
    featureName: "要素名称",
    informationEntity: "信息实体",
    standardAnswer: "标准回答",
    keyElementAnswer: "关键要素回答",
    generalStatement: "一般陈述（无需询问）",
    generalQuestionsAndConfirmation: "一般疑问以及确认",
    specialQuestionsAndAnswers: "特殊疑问以及回答",
    agent: "客服",
    customer: "客户",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "关键要素 语义标签和信息实体不能同时为空",
    standardSentence: "标准句",
    acquaintance: "相识度",
    smartUePlaceholder: "请输入智能交互名称",
    typeSelectPlaceholder: "请选择类型",
    keyElement: "关键要素",
    reQuerySementicTags: "重新选择语义标签",
    querySementicTags: "选择语义标签",
    QATestForm: "问答检测形式",
    selectNotBeNull: "选择不能为空",
    featureDetectionTarget: "要素检测目标",
    categorized: "分类",
    effectiveness: "有效性",
    hideAdvancedSettings: "隐藏高级设置",
    showAdvancedSettings: "显示高级设置",
    generalStatementOnly: "一般陈述",
    generalQuestions: "一般疑问",
    affirmativeExpression: "肯定表达",
    negativeIntention: "否定意图",
    searchSementicTags: "查询语义标签",
    choiceQuestion: "选择型疑问句",
    selectiveAnswer: "选择型回答",
    sementicTagsPlaceholder: "请输入语义标签内容",
    openSpecialQuestion: "开放型特殊疑问",
    openAnswer: "开放型回答",
    customQA: "自定义问答",
    questions: "问法",
    dataPlaceholder: "请输入内容",
    reply: "回答",
    notFindSuitableLabel: "没找到合适的标签",
    addSemanticTag: "添加一个新的语义标签",
    intelligentInteraction: "智能交互",
    matching: "匹配",
    bout: "次",
    keywordMatch: "关键词匹配",
    addFilterConditions: "添加筛选条件",
    buttonStartCollectionTask: "请点击确定按钮开启采集任务",
    buttonStartTask: "请点击确定按钮开启质检任务",
    ruleConfigurationIsRequired: "规则配置为必填项",
    asrRunning: "ASR执行",
    errorCorrectionExecution: "纠错执行",
    emotionalVolumeRecognition: "情绪音量识别",
    dataPreprocessing: "数据预处理",
    inspectionExecution: "质检执行",
    businessId: "业务Id",
    businessTime: "业务时间",
    createTime: "创建时间",
    abnormalReason: "异常原因",
    singleChat: "单聊",
    groupChat: "群聊",
    taskContent: "任务内容",
    submissionTime: "提交时间",
    startTime: "开始时间",
    endTime: "结束时间",
    runningTime: "运行耗时",
    totalAmountOfData: "总数据量",
    resultsOfThe: "执行结果",
    operator: "操作员",
    taskDetails: "任务详情",
    executiveFunction: "执行功能",
    qualityCheckTasks: "质检任务",
    implementationDetails: "执行详情",
    conditionalRules: "条件规则",
    smartCondition: "智能条件",
    goThis: "移动到此",
    pleaseMarkClassification: "请标注分类",
    advancedScreening: "高级筛选",
    noDataToManipulate: "没有可以操作的数据",
    concept: "概念词",
    checkPoint1: "规则配置",
    requiredFieldsCannotEmpty: "必填项不能为空",
    rule: "规则",
    afterFixUnit: "个字",
    unmarkedSelfLearningPointsTip: "您有未标记的自学习质检点",
    onlyReservedFor7Days: "（仅保留7天）",
    downloadError: "下载失败",
    pause: "暂停",
    continue: "继续",
    recalcScore: "仅重新核算分数（含评级）",
    recalculatePointsAndScores: "重新核算质检点和分数",
    recalculatePointsAndScoresWithEmtry: "重新核算质检点和分数（质检点为空）",
    inProgress: "进行中",
    toBeExecuted: "待执行",
    executeImmediately: "立即执行",
    specialElements: "特殊要素",
    logicRule: "逻辑规则",
    every: "多次匹配",
    hitRecall: "命中召回词",
    notHitRecall: "未命中召回词",
    hitRecallRegular: "命中召回正则",
    notHitRecallRegular: "未命中正则",
    hitRule: "命中规则",
    notHitRule: "未命中规则",
    hitSimilarSentence: "命中相似句",
    notHitSimilarSentence: "未命中相似句",
    hitExcludeSimilarSentences: "命中排除相似句",
    notHitExcludeSimilarSentences: "未命中排除相似句",
    updateSuccess: "更新成功",
    qualify: "正则",
    recallKeyWord: "召回关键词",
    grammar: "文法规则",
  },
  api: {
    putTypoDatasByIdStatusByStatus: "有效性变更失败，请重试",
    postAutoMateTasksCaseByTaskId: "案例库同步配置保存失败",
    getAutoMateTasksCaseByTaskId: "案例库同步配置获取失败",
    postInspectionRemarkModelConfigs: "备注模板保存失败",
    putInspectionRemarkModelConfigsById: "备注模板保存失败",
    getInspectionRemarkModelConfigsById: "获取备注模板数据失败",
    postKnowledgeDatas: "知识库新建失败，请重试",
    putKnowledgeDatasById: "知识库修改失败，请重试",
    getLibraryFiles: "获取文价夹列表失败",
    getLibraryCalls: "获取文件失败",
    getLibraryFilesSearch: "搜索失败",
    putLibraryFilesMoveById: "问价夹移动失败",
    putLibraryCallsById: "文件重命名失败！",
    postLibraryFiles: "添加文件夹失败",
    putLibraryFilesById: "文件夹重命名失败",
    deleteLibraryFilesById: "删除失败",
    deleteLibraryCallsById: "删除失败",
    postLibraryCalls: "添加失败",
    getVoiceUploadResultById: "上传结果导出失败",
  },
};
export default locales;
