import EventedClass from '../../../evented-class';

class MessageChannelServerClass extends EventedClass {
    constructor() {
        super();

        this.debug = false;
        this._started = false;
        this._messageEventHandler = messageEventHandler.bind(this);
    }

    start() {
        if (!this._started) {
            this._started = true;
            window.addEventListener("message", this._messageEventHandler);
        }
    }

    initClient(sourceId) {
        if (typeof sourceId !== "string") {
            return;
        }
        let iframeDomElement = document.querySelector(`[data-sdk-source-id='${sourceId}']`);
        if (iframeDomElement) {
            let data = {};
            data.sourceId = sourceId;
            data.debug = this.debug;
            data.actionName = "init";
            iframeDomElement.contentWindow.postMessage(data, "*");
        }
    }

    stop() {
        this._started = false;
        window.removeEventListener("message", this._messageEventHandler);
    }

    _isAcceptableMessage(messageBody) {
        return true;
    }

    _onMessageCallback(messageBody) {
        let isValidMessage = (messageBody != null &&
            typeof messageBody.sourceId === "string" &&
            messageBody.sourceId.length > 0 &&
            typeof messageBody.actionName === "string" &&
            messageBody.actionName.length > 0
        );
        if (!isValidMessage) {
            return;
        }

        if (this._isAcceptableMessage(messageBody)) {
            if (this.debug) {
                window.console.log(`[server][${messageBody.sourceId}]: ${messageBody.actionName}`, messageBody);
            }
            let data = [];
            data.push(messageBody);
            this.trigger("onActionFired", messageBody.sourceId, data);
        }
    }

    _postActionMessage({ actionName, actionData, sourceId }) {
        let iframeDomElement = document.querySelector(`[data-sdk-source-id='${sourceId}']`);
        if (iframeDomElement) {
            iframeDomElement.contentWindow.postMessage(arguments[0], "*");
        }
    }

    _parseEventName(eventName) {
        let result = {
            eventName: eventName,
            namespace: ""
        };
        let namespaceIndex = eventName.indexOf(".");
        if (namespaceIndex !== -1) {
            result.eventName = eventName.substr(0, namespaceIndex);
            result.namespace = eventName.substr(namespaceIndex + 1);
        }
        return result;
    }
}

function messageEventHandler(e) {
    let messageBody = e.data;
    this._onMessageCallback(messageBody);
}

export default MessageChannelServerClass;