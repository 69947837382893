import Locales from '../locales/index';

let reviewInspectionStatus = [
    // { id: 1, key: "all", name: () => Locales.current.enums.inspectionStatus.all },
    { id: 3, key: "inComplaint", name: () => Locales.current.enums.reviewInspectionStatus.inComplaint },
    { id: 4, key: "reviewed", name: () => Locales.current.enums.reviewInspectionStatus.reviewed }
];


export default {
    reviewInspectionStatus
};
