import React, { useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Button, Tabs } from 'udesk-ui';
import Locales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { useEffect } from 'react';
import './wechat-qa-chart-record-entry.scss';
import { getWeComDataCallByIdByType } from 'src/api/weComDataCall/{id}/{type}';

const { TabPane } = Tabs;

function CollectionDataAdd(props) {
    const locales = Locales['current'];
    const ADD_TYPES = [
        {
            pathName: 'wechatQaChartRecordDetail',
            key: 'text',
            text: /* 会话存档 */ locales.pages.wechatQa.wechatQaChartRecordEntry.sessionArchiving,
            id: 'text',
        },
        {
            pathName: 'wechatQaChartRecordAudioEntry',
            key: 'audio',
            text: /* 语音通话 */ locales.pages.wechatQa.wechatQaChartRecordEntry.voiceCall,
            id: 'audio',
            hidden: !Udesk.data.init?.company.enabledWeComVoiceInspect,
        },
    ];

    const [activeKey, setActiveKey] = useState<'text' | 'audio'>('text');
    const [isDetail, setIsDetail] = useState<boolean>(false);
    const onAddTypeChange = (activeKey) => {
        setActiveKey(activeKey);
        const tab: any = ADD_TYPES.find((t) => t.key === activeKey) ?? {};
        const routeOptions = {
            history: props.history,
            routeName: tab.pathName,
            pathParams: { taskId: props.match.params.taskId },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        const currentPath = ADD_TYPES.find((item) => props.history.location.pathname.indexOf(item.id) >= 0);
        if (currentPath) {
            setActiveKey(currentPath.key as any);
        }
        setIsDetail(props.history.location.pathname.includes('chart-record/audio/detail/'));
    }, [props.history.location]);

    const changeDetail = (type) => {
        const paths = props.history.location.pathname.split('/');
        if (!paths.length) return;
        const id = paths[paths.length - 1];

        getWeComDataCallByIdByType({
            segments: {
                type,
                id,
            },
        }).then((resp) => {
            if (!resp.data) {
                Udesk.ui.notify.error(
                    /* 暂无更多语音通话 */ locales.pages.wechatQa.wechatQaChartRecordEntry.noMoreVoiceCalls
                );
                return;
            }
            const routeOptions = {
                history: props.history,
                routeName: 'wechatQaChartRecordAudioDetail',
                pathParams: {
                    id: resp.data,
                },
                state: {
                    keyword: props.location.state?.keyword??''
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        });
    };

    return (
        <Page
            pageBodyClassName='udesk-qa-wechat-qa-chart-record-index'
            title={
                activeKey === 'audio'
                    ? /* 企业微信语音通话 */ locales.pages.wechatQa.wechatQaChartRecordEntry.enterpriseWechatVoiceCall
                    : /* 企业微信会话详情 */ locales.pages.wechatQa.wechatQaChartRecordEntry
                        .enterpriseWechatConversationDetails
            }
            padding={0}
            footer={
                !isDetail ? (
                    <Tabs activeKey={activeKey} onChange={onAddTypeChange}>
                        {props.routes.map((item, i) => {
                            const tab: any = ADD_TYPES.find((t) => t.pathName === item.name) ?? {};
                            if (tab.hidden) return null;
                            return <TabPane tab={tab.text} key={tab.key}></TabPane>;
                        })}
                    </Tabs>
                ) : undefined
            }
            onBack={
                isDetail
                    ? () => {
                        const routeOptions = {
                            history: props.history,
                            routeName: 'wechatQaChartRecordAudioList',
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    }
                    : undefined
            }
            extra={
                isDetail
                    ? [
                        <Button onClick={changeDetail.bind(null, 1)}>
                            {/* 上一条 */}
                            {locales.pages.wechatQa.wechatQaChartRecordEntry.previous}
                        </Button>,
                        <Button onClick={changeDetail.bind(null, 0)}>
                            {/* 下一条 */}
                            {locales.pages.wechatQa.wechatQaChartRecordEntry.next}
                        </Button>,
                    ]
                    : undefined
            }
        >
            <SubRoutes
                route={props.routes.find((r) => r.name === ADD_TYPES.find((i) => i.key === activeKey)!.pathName)}
            />
        </Page>
    );
}

const Template = React.memo(CollectionDataAdd);

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
