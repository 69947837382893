// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerTaskInfosByTaskIdReScoreHasCheckedParam,
    BaseResponseboolean,
} from '../../../../types';

/**
 * 查询重新评分的任务是否存在复核记录
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/{taskId}/reScore/hasChecked
 * @param taskId
 */
export function getIntelligentPartnerTaskInfosByTaskIdReScoreHasChecked(
    options: ApiOptions<GetIntelligentPartnerTaskInfosByTaskIdReScoreHasCheckedParam, never> & {
        segments: GetIntelligentPartnerTaskInfosByTaskIdReScoreHasCheckedParam;
    }
): Promise<BaseResponseboolean> {
    return request<never, GetIntelligentPartnerTaskInfosByTaskIdReScoreHasCheckedParam, never>(
        '/intelligentPartnerTaskInfos/{taskId}/reScore/hasChecked',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerTaskInfosByTaskIdReScoreHasChecked',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/{taskId}/reScore/hasChecked',
        method: 'get',
    },
];
