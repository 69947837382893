import SDKBaseClass, { SDK_EVENTS_SYMBOL } from './sdk-base-class';

import {
    NotImplementedError
} from '../../error';


export default class DomSdkBaseClass extends SDKBaseClass {
    constructor() {
        super(...arguments);
        this.renderTo = this.renderTo.bind(this, this[SDK_EVENTS_SYMBOL]);
    }
    renderTo(sdkEvents, containerDomElement) {
        throw new NotImplementedError(`${this.constructor.name}.renderTo`);
    }
}