import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Button, Form, Space, Icon, Descriptions } from 'udesk-ui';
import { FormProps } from 'udesk-ui/lib/form';
import { DescriptionsProps } from 'udesk-ui/lib/descriptions';
import Field, { TemplateProps as FieldProps } from '../field';
import UdeskLocales from 'UdeskLocales';

export type TemplateProps = {
    formConfig?: Partial<Omit<FormProps, 'onFinish' | 'form'>>;
    descriptionsConfig?: Partial<DescriptionsProps>;
    fieldList?: {
        id?: string;
        name?: string;
        value?: any;
        readonly?: boolean;
        formItemProps?: FieldProps['formItemProps'];
        textProps?: FieldProps['textProps'];
        fieldProps?: FieldProps['fieldProps'];
    }[];
    canEdit?: boolean;
    onSave?: (v: any) => Promise<any>;
};

const Template: FC<TemplateProps> = (props) => {
    const { fieldList, formConfig, descriptionsConfig, canEdit, onSave } = props;

    const [form] = Form.useForm();

    useEffect(() => {
        const values = fieldList?.reduce((pre, cur) => {
            if (cur.id) {
                return {
                    ...pre,
                    [cur.id]: cur.value,
                };
            } else {
                return pre;
            }
        }, {});

        form.setFieldsValue(values);
    }, [fieldList, form]);

    const [isEdit, setIsEdit] = useState(false);

    const showForm = useCallback(() => {
        setIsEdit(true);
    }, []);

    const hiddenForm = useCallback(() => {
        setIsEdit(false);
    }, []);

    const submitHandle = useCallback(
        (values) => {
            onSave?.(values).then(() => {
                setIsEdit(false);
            });
        },
        [onSave]
    );

    return (
        <Form {...formConfig} onFinish={submitHandle} form={form}>
            <Descriptions
                {...descriptionsConfig}
                extra={
                    canEdit ? (
                        !isEdit ? (
                            <Button
                                type="link"
                                icon={<Icon antdIcon={true} type="EditOutlined" />}
                                onClick={showForm}
                            />
                        ) : (
                            <Space>
                                <Button onClick={hiddenForm}>
                                    {/* 取消 */}
                                    {
                                        UdeskLocales['current'].pages.gong.saleClientCenter
                                            .components.moreCustomer.index.cancel
                                    }
                                </Button>
                                <Button htmlType="submit" type="primary">
                                    {/* 确认 */}
                                    {
                                        UdeskLocales['current'].pages.gong.saleClientCenter
                                            .components.moreCustomer.index.confirm
                                    }
                                </Button>
                            </Space>
                        )
                    ) : null
                }
            >
                {fieldList?.map((f) => {
                    return (
                        <Descriptions.Item label={f.name}>
                            <Field
                                isEdit={!f.readonly && isEdit}
                                formItemProps={{
                                    ...f.formItemProps,
                                    noStyle: true,
                                    name: f.id,
                                }}
                                textProps={f.textProps}
                                fieldProps={f.fieldProps}
                            />
                        </Descriptions.Item>
                    );
                })}
            </Descriptions>
        </Form>
    );
};

export default memo(Template);
