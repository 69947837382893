// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetLibraryCallsQuery,
    BaseResponseListLibraryCallFoundResponse,
    PostLibraryCallsBody,
    BaseResponseLibraryCallFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 案例库通话记录
 * @link [GET] /libraryCalls
 */
export function getLibraryCalls(
    options: ApiOptions<never, GetLibraryCallsQuery> & { params: GetLibraryCallsQuery }
): Promise<BaseResponseListLibraryCallFoundResponse> {
    return request<never, never, GetLibraryCallsQuery>('/libraryCalls', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getLibraryCalls',
    });
}

/**
 * save
 *
 * @export
 * @tags 案例库通话记录
 * @link [POST] /libraryCalls
 */
export function postLibraryCalls(
    data: PostLibraryCallsBody
): Promise<BaseResponseLibraryCallFoundResponse>;
export function postLibraryCalls(
    data: PostLibraryCallsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseLibraryCallFoundResponse>;
export function postLibraryCalls(
    data: PostLibraryCallsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseLibraryCallFoundResponse> {
    return request<PostLibraryCallsBody, never, never>('/libraryCalls', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postLibraryCalls',
    });
}

export const meta = [
    { tags: ['案例库通话记录'], path: '/libraryCalls', method: 'get' },
    { tags: ['案例库通话记录'], path: '/libraryCalls', method: 'post' },
];
