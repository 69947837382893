import Udesk from 'Udesk';
import DataPreprocessDetailComponent from './component';

class DataPreprocessDetailRoute extends DataPreprocessDetailComponent {
    model() {
        let { match, sdkOptions } = this.props;

        let dataPreprocessPromise;
        if (match.params.id) {
            this.privates.id = match.params.id;
            let url = Udesk.business.apiPath.concatApiPath(`preprocessRules/${match.params.id}`, sdkOptions);
            dataPreprocessPromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        } else {
            dataPreprocessPromise = new Promise((resolve, reject) => {
                resolve({});
            });
        }

        return {
            dataPreprocess: dataPreprocessPromise,
        };
    }

    onModelResolved(asyncModel) {
        if (asyncModel.dataPreprocess) {
            this.privates.id = asyncModel.dataPreprocess.id;
            this.privates.name = asyncModel.dataPreprocess.ruleName;
            this.privates.desc = asyncModel.dataPreprocess.ruleDesc;
            if (asyncModel.dataPreprocess.dataSource != null)
                this.privates.dataSource = asyncModel.dataPreprocess.dataSource;
            if (asyncModel.dataPreprocess.ruleStatus != null)
                this.privates.status = asyncModel.dataPreprocess.ruleStatus;
            this.privates.entity = asyncModel.dataPreprocess.informationEntity;
            this.privates.operatorLogic = asyncModel.dataPreprocess.operatorLogic;
            if (asyncModel.dataPreprocess.conditionList && asyncModel.dataPreprocess.conditionList.length > 0) {
                this.privates.conditionList = asyncModel.dataPreprocess.conditionList;
                this.privates.conditionList.forEach((condition, index) => {
                    if (condition.operatorList) {
                        this.privates.conditionList[index].operatorList = condition.operatorList;
                    } else if (condition.taskOperatorList) {
                        this.privates.conditionList[index].operatorList = condition.taskOperatorList;
                    }
                });
            }
            if (asyncModel.dataPreprocess.ruleList && asyncModel.dataPreprocess.ruleList.length > 0) {
                this.privates.compositeType = asyncModel.dataPreprocess.ruleList[0].judgeStrategy;
                this.privates.ruleList = asyncModel.dataPreprocess.ruleList;
                this.privates.ruleList && this.actions.getOperatorList(this.privates.ruleList);
                this.privates.operatorLogic = asyncModel.dataPreprocess.ruleList[0].formula;
                if (this.privates.compositeType === 3) {
                    this.privates.operatorLogic = this.privates.ruleList[0].formula;
                    this.privates.items = [];
                    let list = this.privates.ruleList[0].formula.split(/(or|and|not|&&|\|\||!|\(|\))/);
                    this.privates.itemsCount = list.length;
                    list.forEach((item, index) => {
                        item = item.trim();
                        if (item && item.length > 0) {
                            let obj = this.privates.logicList.find((current) => current.value === item);
                            let objs = this.privates.conditionListFilter.find((current) => current.value === item);
                            if (obj) {
                                this.privates.items = [
                                    ...this.privates.items,
                                    {
                                        id: `drag-${index}`,
                                        content: obj.content,
                                        value: obj.value,
                                        prevId: obj.id,
                                        prevClass: obj.id.split('-')[0],
                                    },
                                ];
                            } else if (objs) {
                                this.privates.items = [
                                    ...this.privates.items,
                                    {
                                        id: `drag-${index}`,
                                        content: objs.content,
                                        value: objs.value,
                                        prevId: objs.id,
                                        prevClass: objs.id.split('-')[0],
                                    },
                                ];
                            }
                        }
                    });
                }
            }
            if (asyncModel.dataPreprocess.compositeType != null) {
                this.privates.compositeType = asyncModel.dataPreprocess.compositeType;
            }
            if (asyncModel.dataPreprocess.ruleType != null) {
                this.privates.ruleType = asyncModel.dataPreprocess.ruleType;
            }
            if (asyncModel.dataPreprocess.ruleSecondaryType != null) {
                this.privates.ruleSecondaryType = asyncModel.dataPreprocess.ruleSecondaryType;
            }
            this.actions.update();
        }
    }
}

export default DataPreprocessDetailRoute;
