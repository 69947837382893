// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostFieldDataSearchTemplateBody,
    BaseResponseListFieldFoundAdvanceWrapperResponse,
} from '../../../types';

/**
 * 筛选器字段
 *
 * @export
 * @tags 字段定义
 * @link [POST] /field/data/search-template
 */
export function postFieldDataSearchTemplate(
    data: PostFieldDataSearchTemplateBody
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse>;
export function postFieldDataSearchTemplate(
    data: PostFieldDataSearchTemplateBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse>;
export function postFieldDataSearchTemplate(
    data: PostFieldDataSearchTemplateBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListFieldFoundAdvanceWrapperResponse> {
    return request<PostFieldDataSearchTemplateBody, never, never>('/field/data/search-template', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postFieldDataSearchTemplate',
    });
}

export const meta = [{ tags: ['字段定义'], path: '/field/data/search-template', method: 'post' }];
