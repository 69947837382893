import React, { useMemo, FC } from 'react';
import { Table, Input, Spin } from 'udesk-ui';
import { ListCell } from 'Component/pages/components/coach/AiGenScore/components/ListCell';
import { IntelligentPartnerScorePageDetails } from 'src/api/types';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div``;
const UpperWrapper = styled.div`
    margin-bottom: 16px;
`;
const BelowWrapper = styled.div``;
const BelowWrapperLabel = styled.div`
    margin-bottom: 5px;
`;

type AiGenScoreProps = {
    tableHeight?: number;
    data?: IntelligentPartnerScorePageDetails;
    loading?: boolean;
};

export const AiGenScore: FC<AiGenScoreProps> = (props) => {
    const { tableHeight, data, loading = false } = props;

    const columns = useMemo(
        () => [
            {
                title: /* 沟通阶段 */ UdeskLocales['current'].pages.coach.learningCenter.record
                    .detail.components.aiGenScore.index.communicationStage,
                key: 'phaseName',
                dataIndex: 'phaseName',
                width: '20%',
            },
            {
                title: /* 沟通目标 */ UdeskLocales['current'].pages.coach.learningCenter.record
                    .detail.components.aiGenScore.index.communicationObjectives,
                key: 'communicationTargets',
                dataIndex: 'communicationTargets',
                width: '25%',
                render: (text) => {
                    return <ListCell content={text} showIsHit={false} />;
                },
            },
            {
                title: /* 评分标准 */ UdeskLocales['current'].pages.coach.learningCenter.record
                    .detail.components.aiGenScore.index.scoringCriteria,
                key: 'evaluationStandards',
                dataIndex: 'evaluationStandards',
                render: (text, record) => {
                    return <ListCell content={text} showIsHit={true} />;
                },
            },
        ],
        []
    );

    return (
        <Wrapper>
            <Spin spinning={loading}>
                <UpperWrapper>
                    <Table
                        columns={columns}
                        dataSource={data?.aiScoreDetail || []}
                        pagination={false}
                        scroll={{ y: tableHeight || 300 }}
                    />
                </UpperWrapper>

                <BelowWrapper>
                    <BelowWrapperLabel>
                        {/* 提升建议 */}
                        {
                            UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                .components.aiGenScore.index.suggestionsForImprovement
                        }
                    </BelowWrapperLabel>
                    <Input.TextArea
                        value={data?.suggest}
                        rows={4}
                        style={{ resize: 'none' }}
                        readOnly
                    />
                </BelowWrapper>
            </Spin>
        </Wrapper>
    );
};
