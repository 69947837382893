import Udesk from 'Udesk';
import Component from './template';
import React from 'react';

const UComponent = Udesk.react.udeskify(Component);

const InspectionDetail = React.forwardRef((props: any, ref) => {
    return <UComponent {...props} ref={ref} />;
});

export default React.memo(InspectionDetail);
