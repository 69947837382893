// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerDialogFlowSnapshotQuery,
    BaseResponseIntelligentPartnerDialogFlowFoundResponse,
} from '../../../../types';

/**
 * 查询课程快照对话流程
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [GET] /review/intelligentPartner/dialogFlow/snapshot
 */
export function getReviewIntelligentPartnerDialogFlowSnapshot(
    options: ApiOptions<never, GetReviewIntelligentPartnerDialogFlowSnapshotQuery> & {
        params: GetReviewIntelligentPartnerDialogFlowSnapshotQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogFlowFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerDialogFlowSnapshotQuery>(
        '/review/intelligentPartner/dialogFlow/snapshot',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerDialogFlowSnapshot',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/dialogFlow/snapshot',
        method: 'get',
    },
];
