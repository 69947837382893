import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import { Table, Button, Space, Spin, TreeSelect, Modal, Icon } from 'udesk-ui';

import Locales from 'UdeskLocales';

const AnalysisModel = (props) => {
    const locales = Locales['current'];
    const formLabels = locales.pages.wechatQa.realTimeAnalysis.detail.form;

    const { value, onChange, /* prevPointId */ } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [isInitial, setIsInitial] = useState(true);
    const [selectedPointsId, setSelectedPointsId] = useState(value??[]);
    const [loading, setLoading] = useState(false);
    const [hasPoint, setHasPoint] = useState(true);
    const [inspectionPointGroups, setInspectionPointGroups] = useState([]);
    const [allPoints, setAllPoints] = useState<any[]>([]);
    const openModel = () => {
        setSelectedPointsId(value);
        setModalVisible(true);
    };
    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, []);
    const batchSave = useCallback(() => {
        onChange?.(selectedPointsId);
        setModalVisible(false);
    }, [selectedPointsId]);
    const goToWechatCheckPointSetting = (groupId) => {
        let url = `/site/wechat-qa/setting/check-point/index`;
        if (props.sdkOptions.props.domain) {
            url = props.sdkOptions.props.domain + url;
        }
        if (groupId !== undefined) {
            url += `?groupId=${groupId}`;
        }
        Udesk.utils.web.openWindow(url, '_blank');
    };
    const goToWechatCheckPointDetail = (item) => {
        let url = `/site/wechat-qa/setting/check-point/${item.groupId}/${item.id}/edit`;
        if (props.sdkOptions.props.domain) {
            url = props.sdkOptions.props.domain + url;
        }
        Udesk.utils.web.openWindow(url, '_blank');
    };
    const onTreeSelect = (value) => {
        setSelectedPointsId(value);
    };
    const deleteItem = (item) => {
        let newValue = value.filter((i) => i !== item.id);
        onChange?.(newValue);
    };

    const columns = useMemo(() => {
        return [
            {
                title: formLabels.table.name,
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                ellipsis: true,
            },
            {
                title: formLabels.table.rule,
                dataIndex: 'rules',
                key: 'rules',
                width: '30%',
                ellipsis: true,
                render(text, item, index) {
                    
                    let context = getConditionLogic(item);
                    return (
                        <div title={context} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {context}
                        </div>
                    );
                },
            },
            {
                title: formLabels.table.score,
                dataIndex: 'score',
                key: 'score',
                width: '20%',
                render(text, item, index) {
                    return (
                        <div>
                            {item.scoreType === Udesk.enums.calculateScoreTypes.add.id && locales.labels.plusSign}
                            {item.scoreType === Udesk.enums.calculateScoreTypes.sub.id && locales.labels.minusSign}
                            {item.predeterminedScore}
                        </div>
                    );
                },
            },
            {
                title: locales.labels.action,
                dataIndex: 'action',
                key: 'action',
                render(text, item, index) {
                    return (
                        <div style={{ marginLeft: '-8px' }}>
                            <Space>
                                <Button
                                    type='link'
                                    size='small'
                                    onClick={goToWechatCheckPointDetail.bind(null, item)}
                                >
                                    {locales.labels.edit}
                                </Button>
                                {/* <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={deleteItem.bind(null, item)}
                                    onCancel={(e) => e!.stopPropagation()}
                                    okText={locales.labels.confirm}
                                    placement='topRight'
                                    cancelText={locales.labels.cancel}
                                ></Popconfirm> */}
                                <Button danger type='link' size='small' onClick={deleteItem.bind(null, item)}>
                                    {locales.labels.delete}
                                </Button>
                            </Space>
                        </div>
                    );
                },
            },
        ];
    }, [value]);
    useEffect(() => {
        if (!isInitial && !modalVisible) return;
        setIsInitial(false);
        let url = Udesk.business.apiPath.concatApiPath(
            `inspectionPointGroups/weComTimely?pageNum=1&pageSize=1000`,
            props.sdkOptions
        );
        setLoading(true);
        const getInspectionPointGroups = async () => {
            try {
                
                const inspectionPointGroups = await Udesk.ajax.get(url);
                let points: any[] = [];
                let groups = Array.isArray(inspectionPointGroups.data)
                    ? inspectionPointGroups.data.map((group) => {
                        points = Array.isArray(group.points) ? [...points, ...group.points] : points;
                        return {
                            title: group.name,
                            value: group.id,
                            children: group.points?.map((point: any) => {
                                // let isChecked = selectedPointsId.some((i: any) => i === point.id);
                                return {
                                    title: point.name,
                                    value: point.id,
                                    // disabled: prevPointId === point.id,
                                    groupId: point.groupId,
                                };
                            })??[],
                        };
                    })
                    : [];
                let hasPoint = groups.length !== 0 && groups.some((group) => group.children?.length);
                groups.forEach((group) => {
                    if (group.children.every((c) => c.disabled)) {
                        group.disabled = true;
                    }
                });
                
                setInspectionPointGroups(groups);
                setHasPoint(hasPoint);
                setLoading(false);
                setAllPoints(points);
            } catch (err: any) {
                Udesk.ui.notify.error(err.errorMsg);
                setLoading(false);
            }
        };
        getInspectionPointGroups();
    }, [modalVisible]);
    return (
        <div>
            {Array.isArray(value) && Boolean(value.length) && (
                <Table
                    columns={columns}
                    size='small'
                    dataSource={allPoints.filter((point) => value.includes(point.id))}
                    pagination={false}
                    style={{ marginBottom: 8 }}
                />
            )}
            <Button icon={<Icon type='tianjia1'></Icon>} onClick={openModel}>
                {formLabels.createAnalysisModel}
            </Button>
            <Modal
                visible={modalVisible}
                title={locales.pages.tasks.manage.template.checkPoint.index.weChatNew}
                onCancel={closeModal}
                onOk={batchSave}
                okButtonProps={{
                    disabled: !selectedPointsId.length,
                }}
                width={768}
                maskClosable={false}
            >
                <Spin spinning={loading}>
                    <Space>
                        <div>
                            {
                                locales.pages.tasks.manage.template.checkPoint.index[
                                    hasPoint ? 'checkPointWeChat' : 'wechatEmpty'
                                ]
                            }
                        </div>
                        {hasPoint ? (
                            <TreeSelect
                                multiple
                                treeCheckable
                                treeDefaultExpandAll
                                style={{ width: 655 }}
                                value={selectedPointsId}
                                treeData={inspectionPointGroups}
                                onChange={onTreeSelect}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            ></TreeSelect>
                        ) : (
                            <Button type='link' style={{ paddingLeft: 0 }} onClick={goToWechatCheckPointSetting}>
                                {locales.pages.tasks.manage.template.checkPoint.index.createWeChat}
                            </Button>
                        )}
                    </Space>
                </Spin>
            </Modal>
        </div>
    );
};

export default AnalysisModel;


function getConditionLogic(item) {
    let conditionLogic = '';
    if(!item.rules) return conditionLogic;
    let ruleList = JSON.parse(item.rules);
    if (item && (item.type === Udesk.enums.scoreType.aiScore.id || item.type === Udesk.enums.scoreType.smartRules.id)) {
        if (ruleList && ruleList.length > 0) {
            ruleList.forEach((rule, index) => {
                if (index === ruleList.length - 1) {
                    conditionLogic += rule.formula;
                } else {
                    conditionLogic += rule.formula + ' ; ';
                }
            });
        }
    } else if (item && item.type === Udesk.enums.scoreType.interactiveDetection.id) {
        if (ruleList && ruleList.length > 0) {
            ruleList.forEach((rule, index) => {
                if (rule.name) {
                    conditionLogic += rule.name;
                }
            });
        }
    }

    return conditionLogic;
}