export const isJuSiHong = (subdomain?: string) => {
    return subdomain === 'Joyce' || subdomain === 'cxqi';
};

export const LoginStatusCode = {
    /** 单个租户 */
    Single: 100,
    /** 多个租户 */
    Multi: 101,
};
