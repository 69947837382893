
import DomSdkBaseClass from 'udesk-web-toolkits/src/udesk/app/sdk/dom-sdk-base-class';
import { transitionTo } from '../ui/routing';

class ReactDomSdkBaseClass extends DomSdkBaseClass {
    // eslint-disable-next-line no-useless-constructor
    constructor(options) {
        super(options);
    }

    getHistory() {
        throw new Error(`
            Wrong configuration in 'sdk/react-dom-sdk-base-class.js' method 'getHistory'.
            The history is null'
        `);
    }
    transitionTo({ routeName, pathParams, queryParams } = {}) {
        let history = this.getHistory();
        if (history) {
            let routeOptions = {
                history,
            };
            if (routeName) {
                routeOptions.routeName = routeName;
            } else {
                throw new Error(`
            Wrong configuration in 'sdk/react-dom-sdk-base-class.js' method 'transitionTo'.
            The routeName is null'`);
            }
            if (pathParams) {
                routeOptions.pathParams = pathParams;
            }
            if (queryParams) {
                routeOptions.queryParams = queryParams;
            }

            if (typeof transitionTo === "function") {
                transitionTo(routeOptions);
            }
        }
    }
}

export default ReactDomSdkBaseClass;

export {
    ReactDomSdkBaseClass
};