import ConceptWordDetailComponent from './component';
import Udesk from 'Udesk';

class ConceptWordDetailRoute extends ConceptWordDetailComponent {
    model(changedParams) {
        let { match, sdkOptions } = this.props;
        if(match.params.id) {
            this.privates.paramsId = match.params.id;
            this.actions.update();
            let url = Udesk.business.apiPath.concatApiPath(`conceptWords/${match.params.id}`, sdkOptions);
            let conceptWordPromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    resp => {
                        resolve(resp);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );    
            });
            return {
                conceptWord: conceptWordPromise
            };
        }else {
            this.privates.isFold = true;
            this.actions.update();
            return {};
        }

    }
}

export default ConceptWordDetailRoute;
