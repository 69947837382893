export default {
    distinct,
    unique
};

export function unique(array) {
    if (array == null || !Array.isArray(array)) {
        return array;
    }
    return Array.from(new Set(array));
}

export function distinct(array) {
    return unique(array);
}
