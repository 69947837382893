import {
    InvalidArgumentError
} from '../../../error';
import SetIntervalClass from "./set-interval-class";
import storage from "./storage";

const versionMonitorOptionsSymbol = Symbol(`version-moniton-options-symbol`);
const versionMonitorTimerSymbol = Symbol(`version-moniton-timer-symbol`);
const DEFAULT_OPTIONS = {
    versionPath: "",
    intervalSeconds: 60
};
const VERSION_KEY_MAPPINGS = {
    version: "a",
    isRollback: "b"
};

class VersionMonitorClass {
    constructor(options) {
        if (options == null) {
            options = {};
        }
        this[versionMonitorOptionsSymbol] = Object.assign({}, DEFAULT_OPTIONS, options);
        this[versionMonitorTimerSymbol] = new SetIntervalClass();
    }

    get currentIntervalSeconds() {
        if (this[versionMonitorOptionsSymbol] == null) {
            return null;
        }
        return this[versionMonitorOptionsSymbol].intervalSeconds;
    }

    changeOptions(options) {
        if (options == null) {
            options = {};
        }
        if (this[versionMonitorOptionsSymbol] == null) {
            this[versionMonitorOptionsSymbol] = {};
        }
        let currentIntervalSecounds = this[versionMonitorOptionsSymbol].intervalSeconds;
        Object.assign(this[versionMonitorOptionsSymbol], options);
        if (options.intervalSeconds != null && options.intervalSeconds !== currentIntervalSecounds) {
            this.reset();
        }
    }

    start() {
        if (this[versionMonitorOptionsSymbol] == null) {
            return;
        }
        let intervalSeconds = this[versionMonitorOptionsSymbol].intervalSeconds;
        if (intervalSeconds != null && typeof intervalSeconds !== "number") {
            throw new InvalidArgumentError(`options.intervalSeconds`);
        }
        let versionPath = this[versionMonitorOptionsSymbol].versionPath;
        if (versionPath == null || versionPath === "") {
            throw new InvalidArgumentError(`options.versionPath`);
        }
        if (this[versionMonitorTimerSymbol]) {
            this[versionMonitorTimerSymbol].clear();
            this[versionMonitorTimerSymbol].setInterval(function () {
                let resolveVersion = function (versionText) {
                    let version = versionText;
                    if (version != null) {
                        if (typeof version === "string") {
                            if (versionText.indexOf("<") > -1) {
                                return;
                            }
                            let versionSplitArray = version.split("|");
                            storage.set({
                                version: version,
                                isVersionRollback: versionSplitArray[1] != null ? versionSplitArray[1] : 0
                            });
                        } else if (typeof version === "object") {
                            storage.set({
                                version: version[VERSION_KEY_MAPPINGS.version],
                                isVersionRollback: version[VERSION_KEY_MAPPINGS.isRollback]
                            });
                        }
                    }
                };
                if (window.$ && window.$.get) {
                    window.$.get(`${versionPath}?v=${new Date().getTime()}`).then(function (resp) {
                        resolveVersion(resp);
                    }, function (reason) {

                    });
                }
                else if (typeof window.fetch === "function") {
                    fetch(`${versionPath}?v=${new Date().getTime()}`)
                        .then(function (response) {
                            return response.text();
                        })
                        .then(function (versionText) {
                            resolveVersion(versionText);
                        });
                }
            }, intervalSeconds);
        }
    }

    reset() {
        if (this[versionMonitorTimerSymbol]) {
            this[versionMonitorTimerSymbol].clear();
            this.start();
        }
    }

    stop() {
        if (this[versionMonitorTimerSymbol]) {
            this[versionMonitorTimerSymbol].clear();
        }
    }

    destroy() {
        this.stop();
        this[versionMonitorOptionsSymbol] = null;
        this[versionMonitorTimerSymbol] = null;
    }
}



export default VersionMonitorClass;