const qaRoutesGlobal = [
    {
        path: '/test/sdk',
        name: 'sdkTest',
    },
    // demo
    {
        path: '/test/demo',
        name: 'demoIndex',
    },

    // 质检申诉SDK
    // {
    //     path: '/entry/analysis/qa',
    //     name: 'appealCheckingTaskParent',
    //     routes: [
    //         {
    //             path: '/quality-checking-task/index',
    //             name: 'appealCheckingTask',
    //         },
    //     ],
    // },
    // 企微会话
    {
        path: '/wechat',
        name: 'wechatNotificationAnalysis',
        routes: [
            {
                path: '/message-notification',
                name: 'wechatMessageNotification',
            },
            {
                path: '/conversational-analysis',
                name: 'wechatConversationalAnalysis',
            },
        ],
    },
    //模组跨租户显示详情
    // <! -- start -- >
    //质检详情
    {
        path: '/test/task/:taskId/basic-info/:moduleVersionId/index',
        name: 'templateTestTaskInfo',
    },
    //质检点详情
    {
        path: '/test/task/:taskId/check-point/:moduleVersionId/index',
        name: 'templateTestTaskPointInfo',
    },
    //智能交互一问一答
    // {
    //     path: '/test/task/:taskId/interactive-recognition/:moduleVersionId/index',
    //     name: 'templateTestInterInfo'
    // },
    //多要素
    {
        path: '/test/task/:taskId/interactive-recognition/:moduleVersionId/:categoryId/index',
        name: 'templateTestInterInfo',
    },
    //语义标签
    {
        path: '/test/task/:taskId/semantic-tags/:moduleVersionId/index',
        name: 'templateTestTagsInfo',
    },
    //信息实体
    {
        path: '/test/task/:taskId/information-entities/:moduleVersionId/index',
        name: 'templateTestEntitiesInfo',
    },
    //概念词
    {
        path: '/test/task/:taskId/concept-word/:moduleVersionId/index',
        name: 'templateTestWordInfo',
    },
    // <! -- end -- >

    // 质检系统
    {
        path: '/site',
        name: 'home',
        routes: [
            // 质检首页概览
            {
                path: '/dashboard',
                authCode: 'task:root',
                name: 'dashboard',
            },
            {
                path: '/component-test/index',
                name: 'testComponent',
            },
            // 质检工作台
            {
                path: '/tasks/index',
                authCode: 'task:root',
                name: 'tasksIndex',
            },
            // 智能采集工作台新建流程
            {
                path: '/information-collection-create/manage/:taskId',
                name: 'informationCollectionCreateEntry',
                routes: [
                    // 智能采集基本信息新建
                    {
                        path: '/template/new',
                        name: 'informationCollectionBasicInfoCreateIndex',
                    },
                    {
                        path: '/template/collection-point/index',
                        name: 'informationCollectPointCreateIndex',
                    },
                    {
                        path: '/template/data-add/index',
                        name: 'informationCollectDataAddCreateIndex',
                    },
                ],
            },
            // 智能采集工作台
            {
                path: '/information-collection/manage/:taskId',
                name: 'informationCollectionEntry',
                routes: [
                    // 智能采集基本信息
                    {
                        path: '/template/basic-info/index',
                        name: 'informationCollectionIndex',
                    },
                    {
                        path: '/template/collection-point/index',
                        name: 'informationCollectPointIndex',
                    },
                    {
                        path: '/template/data-add',
                        name: 'informationCollectDataAddIndex',
                        routes: [
                            {
                                path: '/auto',
                                name: 'informationCollectDataAddAuto',
                            },
                            {
                                path: '/manual',
                                name: 'informationCollectDataAddManual',
                            },
                        ],
                    },
                    // 采集列表
                    {
                        path: '/workbench/information-collect-list',
                        // authCode: 'task:work:inpectionList:root',
                        name: 'informationCollectList',
                        routes: [
                            {
                                path: '/index',
                                name: 'informationCollectListIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'informationCollectListDetail',
                            },
                        ],
                    },
                ],
            },
            // 质检任务新建流程
            {
                path: '/tasks/create-tasks/:taskId',
                name: 'tasksManageCreateEntry',
                routes: [
                    {
                        path: '/template/new',
                        name: 'tasksBasicInfoCreateIndex',
                    },
                    {
                        path: '/template/check-point',
                        name: 'tasksCheckPointCreateEntry',
                        routes: [
                            {
                                path: '/index',
                                name: 'tasksCheckPointCreateIndex',
                            },
                            {
                                path: '/:categoryId/new',
                                name: 'tasksCheckPointCreateNew',
                            },
                            {
                                path: '/:categoryId/edit/:id',
                                name: 'tasksCheckPointCreateEdit',
                            },
                        ],
                    },
                    {
                        path: '/template/grade/index',
                        name: 'gradeCreateIndex',
                    },
                    {
                        path: '/template/automatic-add/index',
                        name: 'tasksAutomaticAddCreateIndex',
                    },
                    {
                        path: '/template/manual-add/index',
                        name: 'tasksManualAddCreateIndex',
                    },
                    {
                        path: '/template/role-config/index',
                        name: 'tasksRoleConfigCreateIndex',
                    },
                ],
            },
            {
                path: '/tasks/approve/:taskId/template/check-point',
                name: 'approveTaskEntry',
                routes: [
                    {
                        path: '/:approveId/:approveStatus/index',
                        name: 'approveTasksCheckPointIndex',
                    },
                    {
                        path: '/:approveId/:approveStatus/:categoryId/view/:id',
                        name: 'approveTasksCheckPointView',
                    },
                ],
            },
            // 质检管理
            {
                path: '/tasks/manage/:taskId',
                name: 'tasksManageEntry',
                routes: [
                    // 质检基本信息
                    {
                        path: '/template/basic-info/index',
                        authCode: 'task:template:info:root',
                        name: 'tasksBasicInfoIndex',
                    },
                    // 质检条件
                    {
                        path: '/template/condition',
                        authCode: 'task:template:condition:root',
                        name: 'tasksConditionEntry',
                        routes: [
                            {
                                path: '/index',
                                name: 'tasksConditionIndex',
                            },
                            {
                                path: '/new',
                                name: 'tasksConditionNew',
                            },
                            {
                                path: '/edit/:id',
                                name: 'tasksConditionEdit',
                            },
                        ],
                    },
                    //规则测试
                    {
                        path: '/template/test-check-point/index',
                        name: 'tasksCheckPointTestIndex',
                    },
                    // 质检评级
                    {
                        path: '/template/grade/index',
                        authCode: 'task:template:grade:root',
                        name: 'gradeIndex',
                    },
                    // 质检点
                    {
                        path: '/template/check-point',
                        authCode: 'task:template:point:root',
                        name: 'tasksCheckPointEntry',
                        routes: [
                            {
                                path: '/index',
                                name: 'tasksCheckPointIndex',
                            },
                            {
                                path: '/:categoryId/new',
                                name: 'tasksCheckPointNew',
                            },
                            {
                                path: '/:categoryId/edit/:id',
                                authCode: 'task:template:point:edit',
                                name: 'tasksCheckPointEdit',
                            },
                            {
                                path: '/:categoryId/view/:id',
                                name: 'tasksCheckPointView',
                            },
                        ],
                    },
                    // 质检流程自动化
                    {
                        path: '/template/automatic-add/index',
                        authCode: 'task:template:auto:root',
                        name: 'tasksAutomaticAddIndex',
                    },
                    // 质检手动添加
                    {
                        path: '/template/manual-add/index',
                        authCode: 'task:template:manual:root',
                        name: 'tasksManualAddIndex',
                    },
                    // 质检角色配置
                    {
                        path: '/template/role-config/index',
                        authCode: 'task:template:roleConfig:root',
                        name: 'tasksRoleConfigIndex',
                    },
                    // 质检列表
                    {
                        path: '/workbench/quality-inspection-list',
                        authCode: 'task:work:inpectionList:root',
                        name: 'qualityInspectionList',
                        routes: [
                            {
                                path: '/index',
                                name: 'qualityInspectionListIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'qualityInspectionListDetail',
                            },
                        ],
                    },
                    // 抽检列表
                    {
                        path: '/workbench/spot-check-list',
                        authCode: 'task:work:sample:root',
                        name: 'spotCheckList',
                        routes: [
                            {
                                path: '/index',
                                name: 'spotCheckListIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'spotCheckListDetail',
                            },
                        ],
                    },
                    // 复核列表
                    {
                        path: '/workbench/review-list',
                        authCode: 'task:work:review:root',
                        name: 'reviewList',
                        routes: [
                            {
                                path: '/index',
                                name: 'reviewListIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'reviewListDetail',
                            },
                        ],
                    },
                    // 案例库
                    {
                        path: '/workbench/case-base',
                        authCode: 'task:work:caseLibrary:root',
                        name: 'caseBase',
                        routes: [
                            {
                                path: '/index',
                                name: 'caseBaseIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'caseBaseDetail',
                            },
                        ],
                    },
                    // 质检测试集
                    {
                        path: '/workbench/test-set-list',
                        authCode: 'task:work:testset:root',
                        name: 'testSetList',
                        routes: [
                            {
                                path: '/index',
                                name: 'testSetListIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'testSetListDetail',
                            },
                        ],
                    },
                    // 整体报表
                    {
                        path: '/reports/whole-report',
                        authCode: 'task:report:all:root',
                        name: 'tasksWholeReport',
                    },
                    // 客服报表
                    {
                        path: '/reports/agent-report',
                        authCode: 'task:report:agent:root',
                        name: 'tasksAgentReport',
                    },
                    {
                        path: '/reports/analysis-overview',
                        authCode: 'task:report:business-analysis:root',
                        name: 'analysisOverview',
                    },
                    {
                        path: '/reports/customer-service-execution',
                        authCode: 'task:report:agent-analysis:root',
                        name: 'customerServiceExecution',
                    },
                    {
                        path: '/reports/inspection-items-analysis',
                        authCode: 'task:report:inspection:root',
                        name: 'inspectionItemsAnalysis',
                    },
                ],
            },
            // 智能分析
            {
                path: '/intelligent-analysis',
                authCode: 'smart:root',
                name: 'intelligentAnalysis',
                routes: [
                    // 规则模版
                    {
                        path: '/rule-template',
                        name: 'ruleTemplate',
                        routes: [
                            {
                                path: '/list/index',
                                name: 'ruleTemplateList',
                            },
                            {
                                path: '/task/manager/:taskId',
                                name: 'ruleTemplateTaskManagerEntry',
                                routes: [
                                    {
                                        path: '/:categoryId/edit/:id',
                                        name: 'ruleTemplateEdit',
                                    },
                                    {
                                        path: '/:categoryId/new',
                                        name: 'ruleTemplateNew',
                                    },
                                ],
                            },
                        ],
                    },
                    //智能采集
                    {
                        path: '/intelligent-collection',
                        name: 'intelligentCollectionEntry',
                        authCode: 'smart:information:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'intelligentCollectionList',
                            },
                            {
                                path: '/:groupId/new',
                                authCode: 'smart:information:add',
                                name: 'intelligentCollectionNew',
                            },
                            {
                                path: '/:groupId/edit/:id',
                                name: 'intelligentCollectionEdit',
                            },
                        ],
                    },
                    // 智能标签
                    {
                        path: '/intelligent-tags',
                        name: 'intelligentTags',
                        authCode: 'smart:tag:root',
                        routes: [
                            {
                                path: '/smart-conversation',
                                name: 'smartConversationIntelligentTags',
                                routes: [
                                    {
                                        path: '/index',
                                        name: 'intelligentTagsIndex',
                                    },
                                    {
                                        path: '/new',
                                        authCode: 'smart:tag:add',
                                        name: 'intelligentTagsNew',
                                    },
                                    {
                                        path: '/edit/:id',
                                        authCode: 'smart:tag:edit',
                                        name: 'intelligentTagsEdit',
                                    },
                                ],
                            },
                            {
                                path: '/wechat/index',
                                authCode: 'smart:wecomTag:root',
                                name: 'wechatIntelligentTags',
                            },
                            {
                                path: '/ignore-label/index',
                                // authCode: 'smart:wecomTag:root',
                                name: 'ignoreIntelligentTags',
                            },
                            // {
                            //     path: '/session-label',
                            //     authCode: 'smart:tag:root',
                            //     name: 'sessionLabel',
                            //     routes: [
                            //         {
                            //             path: '/index',
                            //             name: 'sessionLabelTagsIndex',
                            //         },
                            //         {
                            //             path: '/new',
                            //             authCode: 'smart:tag:add',
                            //             name: 'sessionLabelTagsNew',
                            //         },
                            //         {
                            //             path: '/edit/:id',
                            //             authCode: 'smart:tag:edit',
                            //             name: 'sessionLabelTagsEdit',
                            //         },
                            //     ],
                            // },
                            // {
                            //     path: '/key-events',
                            //     authCode: 'smart:tag:root',
                            //     name: 'keyEvents',
                            //     routes: [
                            //         {
                            //             path: '/index',
                            //             name: 'keyEventsTagsIndex',
                            //         },
                            //         {
                            //             path: '/new',
                            //             authCode: 'smart:tag:add',
                            //             name: 'keyEventsTagsNew',
                            //         },
                            //         {
                            //             path: '/edit/:id',
                            //             authCode: 'smart:tag:edit',
                            //             name: 'keyEventsTagsEdit',
                            //         },
                            //     ],
                            // },
                            // {
                            //     path: '/customer-label',
                            //     authCode: 'customer:tag:root',
                            //     name: 'customerLabel',
                            //     routes: [
                            //         {
                            //             path: '/index',
                            //             name: 'customerLabelTagsIndex',
                            //         },
                            //         {
                            //             path: '/new',
                            //             authCode: 'smart:tag:add',
                            //             name: 'customerLabelTagsNew',
                            //         },
                            //         {
                            //             path: '/edit/:id',
                            //             authCode: 'smart:tag:edit',
                            //             name: 'customerLabelTagsEdit',
                            //         },
                            //     ],
                            // },
                            // {
                            //     path: '/supervision',
                            //     // authCode: 'smart:tag:root',
                            //     name: 'supervision',
                            //     routes: [
                            //         {
                            //             path: '/index',
                            //             name: 'supervisionList',
                            //         },
                            //         {
                            //             path: '/new',
                            //             name: 'supervisionCreate',
                            //         },
                            //         {
                            //             path: '/edit/:id',
                            //             name: 'supervisionDetail',
                            //         },
                            //     ],
                            // },
                        ],
                    },
                    // {
                    //     path: '/supervision-test',
                    //     name: 'supervisionTest',
                    //     authCode: 'smart:tag:test',
                    // },
                    // 舆情发现
                    {
                        path: '/public-sentiment',
                        name: 'sentimentAnalysis',
                        authCode: 'smart:discovery:root',
                        routes: [
                            {
                                path: '/analysis',
                                name: 'sentimentAnalysisIndex',
                            },
                        ],
                    },
                    // 数据预处理
                    {
                        path: '/data-preprocess',
                        name: 'dataPreprocess',
                        authCode: 'datapre:rule:root',
                        routes: [
                            {
                                path: '/index',
                                authCode: 'datapre:rule:view',
                                name: 'dataPreprocessIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'datapre:rule:save',
                                name: 'dataPreprocessNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'datapre:rule:update',
                                name: 'dataPreprocessEdit',
                            },
                        ],
                    },
                    // 通对话关联
                    {
                        path: '/connected-by-conversation',
                        name: 'connectedByConversation',
                        authCode: 'smart:discovery:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'connectedByConversationIndex',
                            },
                        ],
                    },
                    // 业务分析
                    {
                        path: '/business-analysis',
                        name: 'businessAnalysis',
                        authCode: 'smart:analysis:root',
                        routes: [
                            {
                                path: '/list/index',
                                name: 'businessAnalysisList',
                            },
                            {
                                path: '/detail/:id',
                                name: 'businessAnalysisDetail',
                            },
                            {
                                path: '/create',
                                name: 'businessAnalysisCreate',
                            },
                        ],
                    },
                    // 分析报表
                    {
                        path: '/analysis-report',
                        name: 'analysisReport',
                        // authCode: 'smart:analysis:root',
                    },
                ],
            },
            // 语义智能
            {
                path: '/semantic-intelligence',
                authCode: 'semantic:root',
                name: 'semanticIntelligence',
                routes: [
                    // 智能词库
                    {
                        path: '/smart-words-library',
                        name: 'smartWordsLibrary',
                        authCode: 'semantic:corpora:root',
                        routes: [
                            {
                                path: '/synonym-words',
                                name: 'smartWordsLibrarySynonymWords',
                            },
                            {
                                path: '/sensitive-words',
                                name: 'smartWordsLibrarySensitiveWords',
                            },
                            {
                                path: '/professional-words',
                                name: 'smartWordsLibraryProfessionalWords',
                            },
                            {
                                path: '/attention-words',
                                name: 'smartWordsLibraryAttentionWords',
                            },
                            {
                                path: '/ignore-words',
                                name: 'smartWordsLibraryIgnoreWords',
                            },
                            {
                                path: '/words-weight',
                                name: 'smartWordsLibraryWordsWeight',
                            },
                            {
                                path: '/corpus-data',
                                name: 'smartWordsLibraryCorpusData',
                            },
                            {
                                path: '/typo-library',
                                name: 'smartWordsLibraryTypoLibrary',
                            },
                        ],
                    },
                    // 概念词
                    {
                        path: '/concept-word',
                        name: 'conceptWord',
                        authCode: 'semantic:conceptWords:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'conceptWordIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:conceptWords:add',
                                name: 'conceptWordNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:conceptWords:edit',
                                name: 'conceptWordEdit',
                            },
                        ],
                    },
                    // 语义标签
                    {
                        path: '/semantic-tags',
                        name: 'semanticTags',
                        authCode: 'semantic:semanticTag:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'semanticTagsIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:semanticTag:add',
                                name: 'semanticTagsNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:semanticTag:edit',
                                name: 'semanticTagsEdit',
                            },
                        ],
                    },
                    // 信息实体
                    {
                        path: '/information-entities',
                        name: 'informationEntities',
                        authCode: 'semantic:imformation:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'informationEntitiesIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:imformation:add',
                                name: 'informationEntitiesNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:imformation:edit',
                                name: 'informationEntitiesEdit',
                            },
                        ],
                    },
                    // 交互识别
                    {
                        path: '/interactive-recognition',
                        name: 'interactiveRecognition',
                        authCode: 'semantic:interactive:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'interactiveRecognitionIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:interactive:add',
                                name: 'interactiveRecognitionNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:interactive:edit',
                                name: 'interactiveRecognitionEdit',
                            },
                        ],
                    },
                    {
                        path: '/knowledge-base',
                        name: 'knowledgeBase',
                        authCode: 'semantic:knowledgeData:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'knowledgeBaseIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:knowledgeData:add',
                                name: 'knowledgeBaseNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:knowledgeData:edit',
                                name: 'knowledgeBaseEdit',
                            },
                        ],
                    },
                    // 智能模型
                    {
                        path: '/intelligent-model',
                        name: 'intelligentModel',
                        authCode: 'semantic:smartModel:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'intelligentModelIndex',
                            },
                            {
                                path: '/new',
                                // authCode: 'semantic:knowledgeData:add',
                                name: 'intelligentModelNew',
                            },
                            {
                                path: '/edit/:id',
                                // authCode: 'semantic:knowledgeData:edit',
                                name: 'intelligentModelEdit',
                            },
                        ],
                    },
                    // 应用消息类型
                    {
                        path: '/application-message',
                        name: 'applicationMessage',
                        authCode: 'semantic:smartModel:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'applicationMessageIndex',
                            },
                            {
                                path: '/new',
                                // authCode: 'semantic:knowledgeData:add',
                                name: 'applicationMessageNew',
                            },
                            {
                                path: '/edit/:id',
                                // authCode: 'semantic:knowledgeData:edit',
                                name: 'applicationMessageEdit',
                            },
                        ],
                    },
                ],
            },
            // 通话管理
            {
                path: '/call-manage',
                authCode: 'call:root',
                name: 'callManage',
                routes: [
                    {
                        path: '/index',
                        name: 'callManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'callManageView',
                    },
                ],
            },
            // 对话管理
            {
                path: '/dialogue-manage',
                authCode: 'im:root',
                name: 'dialogueManage',
                routes: [
                    {
                        path: '/index',
                        name: 'dialogueManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'dialogueManageView',
                    },
                ],
            },
            // 工单管理
            {
                path: '/ticket-manage',
                authCode: 'ticket:root',
                name: 'ticketManage',
                routes: [
                    {
                        path: '/index',
                        name: 'ticketManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'ticketManageView',
                    },
                ],
            },
            // 电商会话管理
            {
                path: '/e-commerce-manage',
                authCode: 'mallcall:root',
                name: 'eCommerceManage',
                routes: [
                    {
                        path: '/index',
                        name: 'eCommerceManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'eCommerceManageView',
                    },
                ],
            },
            // 数据分析
            {
                path: '/data-analysis',
                authCode: 'data:analysis:root',
                name: 'dataAnalysis',
                routes: [
                    // 业务统计
                    {
                        path: '/business-statistics',
                        name: 'businessStatistics',
                        authCode: 'biz:statistics:root',
                        routes: [
                            {
                                path: '/business-statistics-index',
                                name: 'businessStatisticsIndex',
                            },
                        ],
                    },
                    // 质检效果
                    {
                        path: '/quality-effect',
                        name: 'qualityEffect',
                        authCode: 'inspection:effect:root',
                        routes: [
                            {
                                path: '/quality-effect-index',
                                name: 'qualityEffectIndex',
                            },
                        ],
                    },
                    // 质检分析
                    {
                        path: '/quality-analysis',
                        name: 'qualityAnalysis',
                        authCode: 'inspection:analysis:root',
                        routes: [
                            {
                                path: '/quality-analysis-index',
                                name: 'qualityAnalysisIndex',
                            },
                        ],
                    },
                    // 通用报告
                    {
                        path: '/general-inspection-report',
                        name: 'generalInspectionReport',
                        authCode: 'inspection:universalReport:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'generalInspectionReportIndex',
                            },
                        ],
                    },
                    //全量工作报表
                    {
                        path: '/full-amount-work',
                        name: 'fullAmountWork',
                        authCode: 'inspection:inspectorWorkload:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'fullAmountWorkIndex',
                            },
                        ],
                    },
                    // 标签分析
                    {
                        path: '/intelligent-tags-analysis/index',
                        name: 'intelligentTagsAnalysis',
                        // authCode: 'inspection:inspectorWorkload:root',
                    },
                ],
            },
            // 客服中心
            {
                path: '/customer-service',
                authCode: 'agent:root',
                name: 'customerService',
                routes: [
                    // 客服信息
                    {
                        path: '/customer-information',
                        authCode: 'agent:info:root',
                        name: 'customerServiceInformation',
                        routes: [
                            {
                                path: '/index',
                                name: 'customerServiceInformationIndex',
                            },
                        ],
                    },
                    // 客服排名
                    {
                        path: '/customer-ranklist/index',
                        authCode: 'agent:ranklist:root',
                        name: 'customerServiceRankListIndex',
                    },
                    // 客服画像
                    {
                        path: '/customer-service-portrait/index',
                        authCode: 'agent:picture:root',
                        name: 'customerServicePortraitIndex',
                    },
                    // 指标管理
                    {
                        path: '/index-management',
                        name: 'customerServiceIndexManagement',
                        authCode: 'agent:indicator:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'customerServiceIndexManagementIndex',
                            },
                            {
                                path: '/detail',
                                name: 'customerServiceIndexManagementDetail',
                                routes: [
                                    {
                                        path: '/new',
                                        name: 'customerServiceIndexManagementDetailNew',
                                    },
                                    {
                                        path: '/edit/:id',
                                        name: 'customerServiceIndexManagementDetailEdit',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            // 训练中心
            {
                path: '/training-center',
                name: 'trainingCenter',
                authCode: 'training:root',
                routes: [
                    // 快速训练
                    {
                        path: '/fast-training',
                        name: 'fastTrainingIndex',
                        authCode: 'training:fast:root',
                    },
                    // 训练历史
                    {
                        path: '/training-history',
                        name: 'trainingHistoryIndex',
                        authCode: 'training:history:root',
                    },
                    // 分类训练
                    {
                        path: '/classified-training',
                        name: 'classifiedTraining',
                        authCode: 'training:categoryTrain:train',
                        routes: [
                            {
                                path: '/index',
                                name: 'classifiedTrainingIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'classifiedTrainingDetail',
                            },
                        ],
                    },
                    // 分类训练历史
                    {
                        path: '/classified-history-training',
                        name: 'classifiedTrainingHistoryIndex',
                        authCode: 'training:categoryTrain:trainHistory',
                    },
                    // 聚类标注
                    {
                        path: '/clustering-annotation-index',
                        name: 'clusteringAnnotationIndex',
                        authCode: 'training:clusterMark:root',
                    },
                    // {
                    //     path: '/topic-index',
                    //     name: 'topicList',
                    //     authCode: 'training:subjectList:root',
                    // },
                    // {
                    //     path: '/topic-callout',
                    //     name: 'topicCallout',
                    //     authCode: 'training:subjectMark:root',
                    // },
                ],
            },
            //模板组件
            {
                path: '/template-component',
                name: 'templateComponent',
                authCode: 'sys:module:root',
                routes: [
                    {
                        path: '/template-component-list',
                        name: 'templateComponentList',
                        authCode: 'sys:module:list:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'templateComponentListIndex',
                            },
                            {
                                path: '/detail/:id',
                                authCode: 'sys:module:list:edit',
                                name: 'templateComponentListDetail',
                            },
                        ],
                    },
                    {
                        path: '/template-component-my',
                        name: 'templateComponentMy',
                        authCode: 'sys:module:my:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'templateComponentMyIndex',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'sys:module:my:edit',
                                name: 'templateComponentMyDetailEdit',
                            },
                            {
                                path: '/new',
                                authCode: 'sys:module:my:add',
                                name: 'templateComponentMyDetailNew',
                            },
                        ],
                    },
                ],
            },
            // 系统设置
            {
                path: '/admin',
                authCode: 'sys:setting:root',
                name: 'adminEntry',
                routes: [
                    // 系统设置
                    {
                        path: '/system/index',
                        name: 'adminSystemIndex',
                        authCode: 'sys:info:root',
                    },
                    // // 会话设置-----需要后端给authcode
                    // {
                    //     path: '/session/index',
                    //     name: 'sessionSetings',
                    //     // authCode: 'sys:review:wecom:root',
                    // },

                    // 语音识别
                    {
                        path: '/speech-recognition/index',
                        name: 'speechRecognitionIndex',
                        authCode: 'sys:voice:root',
                    },
                    // 消息日志
                    {
                        path: '/system-log/index',
                        name: 'systemLogIndex',
                        authCode: 'sys:message:root',
                    },
                    // // 消息中心
                    // {
                    //     path: '/message-center',
                    //     name: 'messageCenter',
                    //     authCode: 'sys:review:msgbox:root',
                    //     routes: [
                    //         {
                    //             path: '/reply',
                    //             name: 'adminMsgCenterReply',
                    //         },
                    //         {
                    //             path: '/answer',
                    //             name: 'adminMsgCenterAnswer',
                    //         },
                    //         {
                    //             path: '/share',
                    //             name: 'adminMsgCenterShare',
                    //         },
                    //         {
                    //             path: '/subscription',
                    //             name: 'adminMsgCenterSubscription',
                    //         },
                    //         {
                    //             path: '/study',
                    //             name: 'adminMsgCenterStudy',
                    //         },
                    //     ],
                    // },
                    // // gong 员工管理
                    // {
                    //     path: '/medicine-user',
                    //     name: 'adminGongUser',
                    //     routes: [
                    //         {
                    //             path: '/list',
                    //             name: 'adminGongUserList',
                    //         },
                    //         {
                    //             path: '/edit/:id',
                    //             name: 'adminGongUserEdit',
                    //         },
                    //         {
                    //             path: '/detail/:id',
                    //             name: 'adminGongUserDetail',
                    //         },
                    //     ],
                    // },
                    // // gong 角色管理
                    // {
                    //     path: '/medicine-role',
                    //     name: 'adminGongRole',
                    //     routes: [
                    //         {
                    //             path: '/list',
                    //             name: 'adminGongRoleList',
                    //         },
                    //         {
                    //             path: '/edit/:id',
                    //             name: 'adminGongRoleEdit',
                    //         },
                    //     ],
                    // },
                    // 员工管理
                    {
                        path: '/user',
                        name: 'adminUserEntry',
                        authCode: 'sys:user:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'adminUserIndex',
                            },
                            {
                                path: '/view/:id',
                                name: 'adminUserView',
                                authCode: 'sys:user:view',
                            },
                            {
                                path: '/new',
                                name: 'adminUserNew',
                                authCode: 'sys:user:add',
                            },
                            {
                                path: '/edit/:id',
                                name: 'adminUserEdit',
                                authCode: 'sys:user:edit',
                            },
                        ],
                    },
                    // 员工组管理
                    {
                        path: '/user-groups',
                        name: 'adminUserGroupsEntry',
                        authCode: 'sys:group:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'adminUserGroupsIndex',
                            },
                            {
                                path: '/view/:id',
                                name: 'adminUserGroupsView',
                                authCode: 'sys:group:view',
                            },
                            {
                                path: '/new',
                                name: 'adminUserGroupsNew',
                                authCode: 'sys:group:add',
                            },
                            {
                                path: '/edit/:id',
                                name: 'adminUserGroupsEdit',
                                authCode: 'sys:group:edit',
                            },
                        ],
                    },
                    // 角色管理
                    {
                        path: '/role',
                        name: 'role',
                        authCode: 'sys:role:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'roleIndex',
                            },
                            {
                                path: '/new',
                                name: 'roleNew',
                                authCode: 'sys:role:add',
                                routes: [
                                    {
                                        path: '/index',
                                        name: 'roleNewBasicInfo',
                                    },
                                ],
                            },
                            {
                                path: '/view/:id',
                                name: 'roleView',
                                authCode: 'sys:role:view',
                                routes: [
                                    {
                                        path: '/basic-info',
                                        name: 'roleViewBasicInfo',
                                    },
                                    {
                                        path: '/task-permissions',
                                        name: 'roleViewTaskPermissions',
                                    },
                                    {
                                        path: '/data-permissions',
                                        name: 'roleViewDataPermissions',
                                    },
                                ],
                            },
                            {
                                path: '/edit/:id',
                                name: 'roleEdit',
                                authCode: 'sys:role:edit',
                                routes: [
                                    {
                                        path: '/basic-info',
                                        name: 'roleEditBasicInfo',
                                    },
                                    {
                                        path: '/task-permissions',
                                        name: 'roleEditTaskPermissions',
                                    },
                                    {
                                        path: '/data-permissions',
                                        name: 'roleEditDataPermissions',
                                    },
                                ],
                            },
                        ],
                    },
                    // 数据迁移
                    {
                        path: '/data-migration/index',
                        name: 'dataMigration',
                        authCode: 'sys:dataTransfer:root',
                    },
                    // 任务中心
                    {
                        path: '/task-center',
                        name: 'taskCenter',
                        authCode: 'sys:impExp:root',
                        routes: [
                            {
                                path: '/download-list',
                                name: 'taskCenterDownloadList',
                            },
                            {
                                path: '/upload-list',
                                name: 'taskCenterUploadList',
                            },
                            {
                                path: '/task-list',
                                name: 'taskCenterTaskList',
                            },
                        ],
                    },
                    // 计费中心
                    {
                        path: '/billing-center',
                        name: 'billingCenterIndex',
                        authCode: 'hc:charge:root',
                    },
                    // 业务工作时间
                    {
                        path: '/business-working-hours',
                        name: 'businessWorkingHours',
                        authCode: 'sys:work:time',
                    },
                    // 系统集成
                    {
                        path: '/integration',
                        name: 'systemIntegration',
                        routes: [
                            {
                                path: '/cc-pass',
                                name: 'systemIntegrationCCpass',
                            },
                            //企微对接
                            {
                                path: '/wecom',
                                name: 'wechatDocking',
                            },
                            {
                                path: '/taobao',
                                name: 'taobaoDocking',
                            },
                            //微丰对接
                            {
                                path: '/weifeng',
                                name: 'weifengDocking',
                            },
                        ],
                        // authCode: 'hc:charge:root',
                    },
                    //客户字段
                    {
                        path: '/customer-field',
                        name: 'customerField',
                        authCode: 'sys:customer:field',
                    },
                    // 本地录音上传
                    {
                        path: '/record-upload/index',
                        name: 'recordUploadIndex',
                        authCode: 'sys:voiceUpload:root',
                    },
                    {
                        path: '/record-upload/upload-call',
                        name: 'recordUploadDetail',
                        authCode: 'sys:voiceUpload:root',
                    },
                    {
                        path: '/record-upload/upload-im',
                        name: 'recordUploadDetailIm',
                        authCode: 'sys:voiceUpload:root',
                    },
                    {
                        path: '/anomalous-data',
                        name: 'anomalousData',
                        authCode: 'sys:dataAbnormal:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'anomalousDataIndex',
                            },
                        ],
                    },
                    //logo设置
                    {
                        path: '/logo-site',
                        name: 'logoSite',
                        // authCode: 'sys:customer:field',
                    },
                ],
            },
            // 企业微信质检工作台
            {
                path: '/wechat-qa/tasks/index',
                authCode: 'task:root',
                name: 'wechatQaTasksIndex',
            },
            // 企业微信质检
            {
                path: '/wechat-qa/task/manager/:taskId',
                name: 'wechatQaTaskManagerEntry',
                routes: [
                    // 质检基本信息
                    {
                        path: '/basic-info/index',
                        name: 'wechatQaBaseInfo',
                    },
                    // 规则配置
                    {
                        path: '/template/check-point',
                        name: 'wechatQaCheckPointEntry',
                        routes: [
                            {
                                path: '/index',
                                name: 'wechatQaCheckPointIndex',
                            },
                        ],
                    },
                    {
                        path: '/automatic-add/index',
                        name: 'wechatQaAutomaticAddIndex',
                    },
                    {
                        path: '/manual-add/index',
                        name: 'wechatQaManualAddIndex',
                    },
                    // 质检列表
                    {
                        path: '/quality-inspection-list',
                        name: 'wechatQualityInspectionList',
                        routes: [
                            {
                                path: '/index',
                                name: 'wechatQualityInspectionListIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'wechatQualityInspectionListDetail',
                            },
                        ],
                    },
                    {
                        path: '/spot-check-list',
                        name: 'wechatSpotCheckList',
                        routes: [
                            {
                                path: '/index',
                                name: 'wechatSpotCheckListIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'wechatSpotCheckListDetail',
                            },
                        ],
                    },
                    {
                        path: '/review-list',
                        name: 'wechatReviewList',
                        routes: [
                            {
                                path: '/index',
                                name: 'wechatReviewListIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'wechatReviewListDetail',
                            },
                        ],
                    },
                    // 整体报表
                    {
                        path: '/reports/whole-report',
                        name: 'wechatQualityInspectionWholeReport',
                    },
                    {
                        path: '/reports/agent-report',
                        name: 'wechatTasksAgentReport',
                    },
                    {
                        path: '/reports/group-chat-analysis',
                        name: 'groupChatAnalysis',
                    },
                    {
                        path: '/reports/inspection-items-analysis',
                        name: 'wecomInspectionItemsAnalysis',
                    },
                ],
            },
            //创建企业微信质检任务
            {
                path: '/wechat-qa/task/create/:taskId',
                name: 'wechatQaTaskCreateEntry',
                routes: [
                    // 质检基本信息
                    {
                        path: '/basic-info/index',
                        name: 'wechatQaCreateBaseInfo',
                    },
                ],
            },
            // 企业微信质检配置
            {
                path: '/wechat-qa/setting',
                name: 'wechatQaSettingEntry',
                routes: [
                    // 会话切分
                    {
                        path: '/conversation-setting/index',
                        name: 'wechatQaConversationSetting',
                    },
                    // 质检点
                    {
                        path: '/check-point/index',
                        name: 'wechatQaCheckPointSetting',
                    },
                    // 质检点新建
                    {
                        path: '/check-point/:groupId/new',
                        name: 'wechatQaCheckPointSettingNew',
                    },
                    // 质检点编辑
                    {
                        path: '/check-point/:groupId/:id/edit',
                        name: 'wechatQaCheckPointSettingEdit',
                    },
                    // 消息推送配置
                    {
                        path: '/message',
                        name: 'wechatQaMessagePushSetting',
                    },
                    // 实时分析
                    {
                        path: '/real-time-analysis',
                        name: 'wechatQaRealTimeAnalysis',
                        routes: [
                            {
                                path: '/index',
                                name: 'wechatQaRealTimeAnalysisIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'wechatQaRealTimeAnalysisDetail',
                            },
                            {
                                path: '/new',
                                name: 'wechatQaRealTimeAnalysisNew',
                            },
                        ],
                    },
                    // 智能客户标签
                    {
                        path: '/customer-tag',
                        name: 'wechatQaCustomerLabel',
                        routes: [
                            {
                                path: '/index',
                                name: 'wechatQaCustomerLabelIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'wechatQaCustomerLabelDetail',
                            },
                            {
                                path: '/create',
                                name: 'wechatQaCustomerLabelNew',
                            },
                        ],
                    },
                    // 消息过滤
                    {
                        path: '/msg-filtering',
                        name: 'wechatQaMessageFiltering',
                        routes: [
                            {
                                path: '/index',
                                name: 'wechatQaMessageFilteringIndex',
                            },
                        ],
                    },
                ],
            },
            // 企业微信会话
            {
                path: '/wechat-qa/chart-record',
                name: 'wechatQaChartRecordEntry',
                routes: [
                    // 企业微信会话列表
                    // {
                    //     path: '/index',
                    //     name: 'wechatQaChartRecord'
                    // },
                    {
                        path: '/index',
                        name: 'wechatQaChartRecordDetail',
                    },
                    {
                        path: '/audio',
                        name: 'wechatQaChartRecordAudioEntry',
                        routes: [
                            {
                                path: '/index',
                                name: 'wechatQaChartRecordAudioList',
                            },
                            {
                                path: '/detail/:id',
                                name: 'wechatQaChartRecordAudioDetail',
                            },
                        ],
                    },
                ],
            },
            // 企微员工中心
            {
                path: '/wechat-qa/staff-center',
                name: 'wechatQaStaffCenterEntry',
                routes: [
                    // 企微员工中心列表
                    {
                        path: '/index',
                        name: 'wechatQaStaffCenter',
                    },
                ],
            },
            // // 复盘分析-首页
            // {
            //     path: '/home-page',
            //     name: 'homePage',
            // },
            // // 复盘分析-gong
            // {
            //     path: '/call-analysis',
            //     name: 'callAnalysis',
            //     routes: [
            //         {
            //             path: '/call-list',
            //             name: 'callAnalysisList',
            //         },
            //         {
            //             path: '/call-detail/:callId',
            //             name: 'gongCallDetail',
            //         },
            //     ],
            // },
            // // 复盘分析-案例库
            // {
            //     path: '/file-case-base',
            //     name: 'fileCaseBase',
            //     routes: [
            //         {
            //             path: '/folder-list',
            //             name: 'caseBaseFolderList',
            //         },
            //     ],
            // },
            // // 复盘分析-辅导任务
            // {
            //     path: '/coach-task',
            //     name: 'coachTask',
            //     authCode: 'help:root',
            //     routes: [
            //         {
            //             path: '/list',
            //             name: 'coachTaskList',
            //         },
            //     ],
            // },
            // // 复盘分析-团队分析
            // // {
            // //     path: '/team-analysis',
            // //     name: 'teamAnalysis',
            // //     authCode: 'group:root',
            // // },
            // // 复盘分析-分析赋能
            // {
            //     path: '/analysis-enable',
            //     name: 'analysisEnable',
            //     routes: [
            //         {
            //             path: '/monitoring-report',
            //             name: 'monitoringReport',
            //             // authCode: 'supervision:root',
            //         },
            //         {
            //             path: '/team-analysis',
            //             name: 'teamAnalysis',
            //             authCode: 'group:root',
            //         },
            //         {
            //             path: '/study',
            //             name: 'analysisEnableStudy',
            //         },
            //         {
            //             path: '/help',
            //             name: 'analysisEnableHelp',
            //         },
            //         {
            //             path: '/replay',
            //             name: 'analysisEnableReplay',
            //         },
            //         {
            //             path: '/intellect-tags',
            //             name: 'analysisEnableIntellectTags',
            //         },
            //         {
            //             path: '/sentiment',
            //             name: 'analysisEnableSentiment',
            //         },
            //         {
            //             path: '/execution-analysis',
            //             name: 'executionAnalysis',
            //             authCode: 'analysis:supervision:board',
            //         },
            //         {
            //             path: '/business',
            //             name: 'analysisEnableBusiness',
            //             routes: [
            //                 {
            //                     path: '/list',
            //                     name: 'analysisBusinessAnalysisList',
            //                 },
            //                 {
            //                     path: '/detail/:id',
            //                     name: 'analysisBusinessAnalysisDetail',
            //                 },
            //                 {
            //                     path: '/create',
            //                     name: 'analysisBusinessAnalysisCreate',
            //                 },
            //             ],
            //         },
            //         // 分组测试
            //         {
            //             path: '/group-test',
            //             name: 'groupTest',
            //             routes: [
            //                 {
            //                     path: '/index',
            //                     name: 'groupTestList',
            //                     routes: [
            //                         {
            //                             path: '/analysis',
            //                             name: 'groupTestAnalysis',
            //                         },
            //                     ]
            //                 },

            //                 {
            //                     path: '/add',
            //                     name: 'groupTestDetail',
            //                 },
            //                 {
            //                     path: '/edit/:id',
            //                     name: 'groupTestDetailEdit',
            //                 },
            //             ],
            //         },
            //     ],
            // },
            //客户中心
            {
                path: '/client-center',
                name: 'clientCenterEntry',
                authCode: 'customer:root',
                routes: [
                    {
                        path: '/manage',
                        name: 'clientManageRoot',
                        routes: [
                            {
                                path: '/index',
                                name: 'clientManage',
                            },
                            {
                                path: '/wechat-index',
                                name: 'clientManageWechat',
                            },
                        ],
                    },
                    // 客户画像
                    {
                        path: '/customer-portrait',
                        name: 'customerPortrait',
                        authCode: 'smart:personal:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'customerPortraitIndex',
                            },
                        ],
                    },
                ],
            },
            // //销售赋能 客户
            // {
            //     path: '/sale-client-center',
            //     name: 'saleClientCenterEntry',
            //     routes: [
            //         {
            //             path: '/list',
            //             name: 'saleClientList',
            //         },
            //         {
            //             path: '/detail/:id',
            //             name: 'saleClientDetail',
            //         },
            //     ],
            // },
            // //销售赋能 知识库
            // {
            //     path: '/sale-knowledge-base',
            //     name: 'gongKnowledgeBase',
            //     authCode: 'sys:knowledge:root',
            //     routes: [
            //         {
            //             path: '/list',
            //             authCode: 'sys:knowledgePoint:root',
            //             name: 'gongKnowledgeBaseList',
            //         },
            //         {
            //             path: '/detail/:id',
            //             authCode: 'sys:knowledgePoint:edit',
            //             name: 'gongKnowledgeBaseDetail',
            //         },
            //         {
            //             path: '/view/:id',
            //             authCode: 'sys:knowledgePoint:view',
            //             name: 'gongKnowledgeBaseView',
            //         },
            //         {
            //             path: '/create',
            //             authCode: 'sys:knowledgePoint:add',
            //             name: 'gongKnowledgeBaseCreate',
            //         },
            //     ],
            // },

            // 质检结果
            {
                path: '/qa-result',
                authCode: 'task:root',
                name: 'qaResult',
            },

            // 电商评论管理
            {
                path: '/e-comment-manage',
                authCode: 'call:root',
                name: 'eCommentManage',
                routes: [
                    {
                        path: '/index',
                        name: 'eCommentManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'eCommentManageDetail',
                    },
                ],
            },
            // 社媒评论管理
            {
                path: '/m-comment-manage',
                authCode: 'call:root',
                name: 'mCommentManage',
                routes: [
                    {
                        path: '/index',
                        name: 'mCommentManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'mCommentManageDetail',
                    },
                ],
            },

            //404
            {
                path: '/404',
                name: 'qaPageNotFound',
            },
        ],
    },
];

export { qaRoutesGlobal };
