import {
    iziToast
} from '../../../udesk/ref-npm-modules/plugins/ui.notify.izitoast';
import { NotifyPluginClass } from '../../../udesk/ui/notify';
import { installPlugin, globalInstall } from '../../plugin-base';

//#region IziToastServiceClass
class IziToastServiceClass extends NotifyPluginClass {
    constructor() {
        super();
        this.on("onDefaultsChanged", (defaults) => {
            iziToast.settings(defaults || {});
        });
    }
    show(type, options) {
        let defaults = this.defaults || {};
        let settings = Object.assign({}, (defaults.themeSettings && defaults.themeSettings[type]) || {});

        let featureClasses = [];
        if (defaults.drag) {
            featureClasses.push("draggable");
        } else {
            featureClasses.push("non-draggable");
        }
        let widgetOptions = {
            message: options.message,
            class: featureClasses.join(" ")
        };

        if (options.duration !== undefined) {
            widgetOptions.timeout = options.duration;
        }
        settings = Object.assign(settings, widgetOptions);
        iziToast.show(settings);
    }

    destroy() {
        iziToast.destroy();
    }

    getDefaults() {
        // for more details about options, see http://izitoast.marcelodolce.com
        return {
            id: null,
            class: '',
            title: '',
            titleColor: '',
            titleSize: '',
            titleLineHeight: '',
            message: '',
            messageColor: '',
            messageSize: '',
            messageLineHeight: '',
            backgroundColor: '',
            theme: 'default', // dark
            color: '', // blue, red, green, yellow
            icon: '',
            iconText: '',
            iconColor: '',
            iconUrl: null,
            image: '',
            imageWidth: 50,
            maxWidth: null,
            zindex: null,
            layout: 1,
            balloon: false,
            close: true,
            closeOnEscape: false,
            closeOnClick: false,
            displayMode: 0, // 0-multiple, 1-once, 2-replace
            position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            target: '',
            targetFirst: true,
            timeout: 1500,
            rtl: false,
            animateInside: true,
            drag: false,
            pauseOnHover: true,
            resetOnHover: false,
            progressBar: true,
            progressBarColor: '',
            progressBarEasing: 'linear',
            overlay: false,
            overlayClose: false,
            overlayColor: 'rgba(0, 0, 0, 0.6)',
            // bounceInLeft, bounceInRight, bounceInUp, bounceInDown, fadeIn, fadeInDown, fadeInUp, fadeInLeft, fadeInRight or flipInX.
            transitionIn: 'fadeInDown',
            // fadeOut, fadeOutUp, fadeOutDown, fadeOutLeft, fadeOutRight, flipOutX
            transitionOut: 'fadeOutUp',
            transitionInMobile: 'fadeInDown',
            transitionOutMobile: 'fadeOutUp',
            buttons: {},
            inputs: {},
            onOpening: function () { },
            onOpened: function () { },
            onClosing: function () { },
            onClosed: function () { },
            themeSettings: {
                info: {
                    color: "blue",
                    icon: "udesk-web-toolkits-iconfont icon-udesk-web-toolkits-bell"
                },
                success: {
                    color: "green",
                    icon: "udesk-web-toolkits-iconfont icon-udesk-web-toolkits-check",
                },
                warn: {
                    color: "yellow",
                    icon: "udesk-web-toolkits-iconfont icon-udesk-web-toolkits-warning",
                },
                error: {
                    color: "red",
                    icon: "udesk-web-toolkits-iconfont icon-udesk-web-toolkits-ban",
                }
            },
        };
    }
}
//#endregion

const PLUGIN_PATH = "ui.notify.plugins.iziToast";
const PLUGIN = new IziToastServiceClass();

export default function install(udesk) {
    iziToast.settings(PLUGIN.defaults || {});
    installPlugin(udesk, PLUGIN_PATH, PLUGIN);
}
globalInstall(PLUGIN_PATH, PLUGIN);


