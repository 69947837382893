import RealTimeAnalyzer from '../components/RealTimeAnalyzer';
import React from 'react';
import Udesk from 'Udesk';
import { ScoreDetail } from '../components/ScoreDetail';
import { isDefined } from 'src/util/core';

const ScoreDetailComponent = (props: {
    traineeWordsResult: any;
    align: 'left' | 'right';
    [key: string]: any;
}) => {
    const {
        isRta, // 如果存在，这表示为实时解析组件
        traineeWordsResult,
        ...componentProps // 固定的俩个值
    } = props;

    if (isDefined(isRta)) {
        return <RealTimeAnalyzer {...props}></RealTimeAnalyzer>;
    } else {
        // 默认为得分解析
        if (traineeWordsResult) {
            return <ScoreDetail {...componentProps} data={traineeWordsResult} />;
        }

        return null;
    }
};

export const channelNodeTypeMap = {
    [Udesk.enums.nodeTypes.userNode.id]: ScoreDetailComponent,
    [Udesk.enums.nodeTypes.knowledgeNode.id]: ScoreDetailComponent,
    [Udesk.enums.nodeTypes.aiGenDialogueNode.id]: ScoreDetailComponent,
};

const Noop = () => null;
export const getChannelBottomRender = (nodeType: string | number) =>
    nodeType ? channelNodeTypeMap[nodeType] ?? Noop : Noop;
