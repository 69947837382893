// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetKnowledgeBaseSearchQuery, BaseResponseList知识点分类相应结果 } from '../../types';

/**
 * 知识库分类及其列表模糊查询
 *
 * @export
 * @tags 知识库分类接口
 * @link [GET] /knowledgeBase/search
 */
export function getKnowledgeBaseSearch(): Promise<BaseResponseList知识点分类相应结果>;
export function getKnowledgeBaseSearch(
    options: ApiOptions<never, GetKnowledgeBaseSearchQuery>
): Promise<BaseResponseList知识点分类相应结果>;
export function getKnowledgeBaseSearch(
    options?: ApiOptions<never, GetKnowledgeBaseSearchQuery>
): Promise<BaseResponseList知识点分类相应结果> {
    return request<never, never, GetKnowledgeBaseSearchQuery>('/knowledgeBase/search', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getKnowledgeBaseSearch',
    });
}

export const meta = [{ tags: ['知识库分类接口'], path: '/knowledgeBase/search', method: 'get' }];
