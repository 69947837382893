// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostSmartTagsRedoBody, BaseResponseVoid } from '../../types';

/**
 * saveSmartTagManualLoad
 *
 * @export
 * @tags smart-tag-controller
 * @link [POST] /smartTags/redo
 */
export function postSmartTagsRedo(data: PostSmartTagsRedoBody): Promise<BaseResponseVoid>;
export function postSmartTagsRedo(
    data: PostSmartTagsRedoBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postSmartTagsRedo(
    data: PostSmartTagsRedoBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostSmartTagsRedoBody, never, never>('/smartTags/redo', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSmartTagsRedo',
    });
}

export const meta = [{ tags: ['smart-tag-controller'], path: '/smartTags/redo', method: 'post' }];
