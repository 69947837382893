import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import { Empty } from 'udesk-ui';
import styled from 'styled-components';
import { postIntelligentPartnerHomePageStudentPictureWrongPoint } from 'src/api/intelligentPartner/homePage/student/picture/wrongPoint';
import { FallibleWords } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    overflow: auto;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

type TemplateProps = {
    params?: any;
};

const Template: React.FC<TemplateProps> = (props) => {
    const { params } = props;

    const [words, setWords] = useState<FallibleWords[]>();

    useRequest(() => postIntelligentPartnerHomePageStudentPictureWrongPoint(params), {
        ready: !!params?.userId && !!params?.taskId && !!params?.startTime && !!params?.endTime,
        refreshDeps: [params],
        onSuccess: (resp) => {
            setWords(resp.data || []);
        },
    });

    return (
        <Wrapper>
            {words?.length ? (
                (words || []).slice(0, 10).map((item, index) => (
                    <Item key={index}>
                        <span>{`Top${index + 1}`}</span>

                        <span
                            title={item.ruleName}
                            style={{
                                marginLeft: '8px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                flex: 1,
                            }}
                        >
                            {item.ruleName}
                        </span>

                        <span style={{ marginLeft: 10 }}>{`${
                            /* 命中率 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .resultAnalysis.hitRate
                        }`}</span>

                        <span style={{ marginLeft: 0, color: 'red' }}>
                            {((item.hitRatio || 0) * 100).toFixed(2) + '%'}
                        </span>
                    </Item>
                ))
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Wrapper>
    );
};

export default Template;
