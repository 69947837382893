import common from './common';
import apiPath from './apiPath';
import report from './report';
import permission from './permission';
import smartWordsMethods from './smart-words-methods';
import { getServerAuthorization } from './getServerAuthorization';
import oss from './oss';
import fields from './fields';
import table from './table';
import scoreCompute from './scoreCompute';
import sdkAjax from './sdkAjax';
import emoji from './emoji';
import task from './task';
import goToListManager from './goToListManager';

let business = {
    common,
    apiPath,
    report,
    permission,
    smartWordsMethods,
    getServerAuthorization,
    oss,
    fields,
    table,
    scoreCompute,
    sdkAjax,
    emoji,
    task,
    goToListManager,
};

export default Object.assign({}, business);
export * from './system';