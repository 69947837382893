import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Button, Empty, Input, Space, Spin } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';

const Wrap = styled.div`
    overflow: auto;
    scroll-behavior: smooth;
    margin-top: 8px;
`;

const ItemWrap = styled.div`
    display: flex;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    background-color: ${(props) => (props.highlight ? 'aliceblue' : 'transparent')};
    cursor: pointer;

    .udesk-qa-ui-avatar {
        margin-top: 4px;
        margin-left: 4px;
    }
    .content-wrap {
        margin: 0 8px;
        flex: 1;

        .title {
            display: flex;
            justify-content: space-between;
        }
        .content {
            padding: 8px;
            background-color: #f2f2f2;
            border-radius: 3px;
            em[_udeskhighlight] {
                background-color: #ffe59f;
                &.current {
                    background-color: #ff8552;
                }
            }
        }
    }
`;

const Item = (props) => {
    const { highlight, onItemClick, ...item } = props;
    const ref = useRef<any>();
    useEffect(() => {
        if (highlight) {
            ref.current?.scrollIntoViewIfNeeded(false);
        }
    }, [highlight]);

    return (
        <ItemWrap highlight={highlight} ref={ref} onClick={onItemClick.bind(null, item)} className='udesk-qa-video-analysis-dialogue-item'>
            <Avatar>{props.speaker}</Avatar>
            <div className="content-wrap">
                <div className="title">
                    <div>{props.speaker}</div>
                    <div>{`${formatTime(props.fromSec)} - ${formatTime(props.endSec)}`}</div>
                </div>
                <div className="content">{props.text}</div>
            </div>
        </ItemWrap>
    );
};

export const Dialogue = (props) => {
    const { data, loading, highlightIndex, onItemClick } = props;
    const ref = useRef<HTMLDivElement>();

    const [keyword, setKeyword] = useState('');
    const [keywordHighlightIndex, setKeywordHighlightIndex] = useState(0);
    const [keywordHighlightCount, setKeywordHighlightCount] = useState(0);

    useEffect(() => {
        const cb = (event) => {
            const isUserScroll = event.target === ref.current;
            console.log(
                `${
                    /* 触发滚动事件的对象是 */ UdeskLocales['current'].pages.conversationAnalysis
                        .videoAnalysis.detail.components.dialogue.index
                        .theObjectThatTriggersTheScrollingEventIs
                }${
                    isUserScroll
                        ? /* 用户滚动 */ UdeskLocales['current'].pages.conversationAnalysis
                            .videoAnalysis.detail.components.dialogue.index.userScrolling
                        : 'scrollIntoViewIfNeeded'
                }`
            );
        };
        ref.current?.addEventListener('scroll', cb);

        return () => {
            ref.current?.removeEventListener('scroll', cb);
        };
    }, []);

    const onSearch = (value) => {
        setKeyword(value);
        highlightKeyword(ref, value);
        setKeywordHighlightCount(data.reduce((prev, cur) => {
            const reg = new RegExp(value, 'g');
            const res = cur.text.match(reg);
            if(res) {
                return prev + res.length;
            }
            return prev;
        }, 0));
        requestIdleCallback(() => {
            gotoOtherHighlight(0);
        });

        // setTimeout(() => {
            
        // }, 200);
    };

    const gotoOtherHighlight = (step) => {

        setKeywordHighlightIndex((prev) => {
            if(step === 0) return 0;
            let newIndex = prev + step;
            if (step > 0) {
                let elements: any = ref.current?.querySelectorAll('em[_udeskhighlight]');
                const length = elements?.length;
                elements = null;
                if (length) {
                    newIndex = Math.min(newIndex, length - 1);
                }
            } else {
                newIndex = Math.max(newIndex, 0);
            }
            return newIndex;
        });
    };
    useEffect(() => {
        if(!keyword) return;
        let elements: any = ref.current?.querySelectorAll('em[_udeskhighlight]');
        if(!elements || !elements.length) return;
        console.log('elements:: ', elements);
        
        const current = ref.current?.getElementsByClassName('current');
        if (current?.length) {
            current[0].className = current[0].className.replace('current', '');
        }
        elements[keywordHighlightIndex].className = elements[keywordHighlightIndex].className + 'current';

        elements[keywordHighlightIndex]?.scrollIntoViewIfNeeded?.();
    }, [keywordHighlightIndex, keyword]);

    return (
        <div>
            <Space>
                <Input.Search placeholder={/* 关键词搜索 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.dialogue.index.keywordSearch} onSearch={onSearch} />
                {Boolean(keyword) && (
                    <>
                        <Button
                            type="link"
                            onClick={gotoOtherHighlight.bind(null, -1)}
                            size="small"
                            disabled={keywordHighlightIndex <= 0}
                        >{/* 上一个 */}{UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.dialogue.index.previous}</Button>
                        <Button
                            type="link"
                            onClick={gotoOtherHighlight.bind(null, 1)}
                            size="small"
                            disabled={keywordHighlightIndex >= keywordHighlightCount - 1}
                        >{/* 下一个 */}{UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.components.dialogue.index.next}</Button>
                    </>
                )}
            </Space>
            <Wrap ref={ref}>
                {!Array.isArray(data) ? (
                    <Spin spinning={loading}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Spin>
                ) : (
                    data.map((item, index) => {
                        return (
                            <Item
                                {...item}
                                highlight={highlightIndex === index}
                                onItemClick={onItemClick}
                                // keyword={keyword}
                            />
                        );
                    })
                )}
            </Wrap>
        </div>
    );
};

function formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const remainingSeconds = (seconds - hours * 3600 - minutes * 60).toFixed(2);
    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

function highlightKeyword(ref, searchContent) {
    if (searchContent === "") return;

    const keywords = searchContent.split(/ +/);
    const dialogueDOM = ref.current;
    const keywordsElems = dialogueDOM.getElementsByClassName('udesk-qa-video-analysis-dialogue-item');
    for (let i = 0; i < keywordsElems.length; i++) {
        Udesk.utils.dom.clearHighlight(keywordsElems[i]);
        keywords.forEach(item => {
            Udesk.utils.dom.highlightText(keywordsElems[i], item);
        });
    }
}