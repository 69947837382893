import React, { useCallback } from 'react';
import { Template } from './normal';
import './style.scss';

export default React.memo((props) => {
    const { locales, operatorItem, changeInputValue, options } = props;

    const changeHandle = useCallback(
        (v) => {
            return changeInputValue(operatorItem, 'param', { target: { value: v } });
        },
        [changeInputValue, operatorItem]
    );

    return (
        <Template
            {...{
                locales,
                associateList: options || [],
                value: operatorItem.operatorList[0].param,
                onChange: changeHandle,
            }}
        />
    );
});
