import {
    InvalidArgumentError,
    ArgumentNullError,
    WrongTypeError
} from '../error';
import { get, deepCopy } from '../utils/object';

const DEFAULT_LANGUAGE_SYMBOL = Symbol("[DefaultLanguage]");
const CURRENT_SYMBOL = Symbol("[Current]");
const CURRENT_LANGUAGE_SYMBOL = Symbol("[CurrentLanguage]");
const LOCALES_MAP_SYMBOL = Symbol("[LocalesMap]");
const MERGED_LOCALES_MAP_SYMBOL = Symbol("[MergedLocalesMap]");
const INCREMENTAL_KEYNAME = "[INCREMENTAL]";

export default class LocalesManagerClass {
    constructor(localesMap, defaultLanguage, extraLocal) {
        //验证数据结构：参数是个对象，每一个value必须也是objecgt
        if (localesMap == null) {
            throw new ArgumentNullError(`${this.constructor.name}.localesMap`);
        }
        if (typeof localesMap !== "object") {
            throw new InvalidArgumentError(`${this.constructor.name}.localesMap`);
        }
        for (let [languageName, locales] of Object.entries(localesMap)) {
            if (languageName != null) {
                if (languageName !== languageName.toUpperCase()) {
                    throw new InvalidArgumentError(null, {
                        message: `The key of localesMap must be in UPPER case, but actual value is '${languageName}'`
                    });
                }
                if (locales == null) {
                    throw new ArgumentNullError(`UdeskLocales["${languageName}"]`);
                }
                if (typeof locales !== "object") {
                    throw new InvalidArgumentError(`UdeskLocales["${languageName}"]`);
                }
            }
        }
        //验证每一个value，递归地把整个对象平铺展开为数组
        let localeKeysMap = {};
        for (let languageName in localesMap) {
            if (Object.prototype.hasOwnProperty.call(localesMap,languageName)) {
                if (!Object.prototype.hasOwnProperty.call(localeKeysMap,languageName)) {
                    localeKeysMap[languageName] = getKeysRecursively(localesMap[languageName]);
                }
            }
        }

        // 验证所有语言的数组必须：长度相同; 对数组进行排序，排序后的所有数组每一个索引位置值均相同；
        let lastLocaleKeysLength = null;
        let lastLocaleKeys = [];
        for (let languageName in localeKeysMap) {
            if (Object.prototype.hasOwnProperty.call(localeKeysMap,languageName)) {
                if (lastLocaleKeysLength == null) {
                    lastLocaleKeysLength = localeKeysMap[languageName].length;
                    lastLocaleKeys = localeKeysMap[languageName];
                } else {
                    if (localeKeysMap[languageName].length !== lastLocaleKeysLength) {
                        let differentKeys = lastLocaleKeys.filter(langItem => {
                            return !localeKeysMap[languageName].includes(langItem);
                        }).join(",");
                        throw new InvalidArgumentError(`UdeskLocales["${languageName}"]`, {
                            message: `The length of locale keys in UdeskLocales["${languageName}"] is different from others. There are the different keys:${differentKeys}.`
                        });
                    }
                }
                localeKeysMap[languageName] = localeKeysMap[languageName].sort();
            }
        }

        let localesRepeatMapKeys = Object.keys(localeKeysMap);
        let firstLocaleKeys = localeKeysMap[localesRepeatMapKeys[0]];
        for (let languageName in localeKeysMap) {
            if (Object.prototype.hasOwnProperty.call(localeKeysMap,languageName)) {
                if (languageName !== localesRepeatMapKeys[0]) {
                    let localeKeys = localeKeysMap[languageName];
                    for (let index = 0; index < firstLocaleKeys.length; index++) {
                        if (firstLocaleKeys[index] !== localeKeys[index]) {
                            throw new Error(
                                `The locale key \`${localeKeys[index]}\` in UdeskLocales["${languageName}"] doesn't match with UdeskLocales["${localesRepeatMapKeys[0]}"]. Please double checked if the locale key is wrong added (or misspelled) in ${languageName} or is missing in ${localesRepeatMapKeys[0]}.`);
                        }
                    }
                }
            }
        }
        if (Object.prototype.hasOwnProperty.call(localesMap,INCREMENTAL_KEYNAME)) {
            delete localesMap[INCREMENTAL_KEYNAME]
        }

        this[LOCALES_MAP_SYMBOL] = localesMap;
        this[MERGED_LOCALES_MAP_SYMBOL] = {};
        if (defaultLanguage == null) {
            defaultLanguage = Object.keys(localesMap)[0];
        }
        this[DEFAULT_LANGUAGE_SYMBOL] = defaultLanguage;
        this.setLanguage(defaultLanguage);
        this.merge(this);
    }

    get current() {
        return this[CURRENT_SYMBOL];
    }

    get currentLanguage() {
        return this[CURRENT_LANGUAGE_SYMBOL];
    }

    get(key, languageCode) {
        if (key == null || key === "") {
            throw new InvalidArgumentError(`${key}`);
        }
        let locales = this.getLocales(languageCode);
        if (locales == null) {
            locales = this.getLocales(this.currentLanguage);
        }
        if (locales) {
            return get(locales, key) || "";
        }
        return "";
    }

    getLocales(languageCode) {
        languageCode = normalizeLanguage(languageCode);
        if (Object.prototype.hasOwnProperty.call(this[MERGED_LOCALES_MAP_SYMBOL],languageCode)) {
            return this[MERGED_LOCALES_MAP_SYMBOL][languageCode];
        } else {
            return null;
        }
    }

    setLanguage(languageCode) {
        languageCode = normalizeLanguage(languageCode);
        if (!Object.prototype.hasOwnProperty.call(this[MERGED_LOCALES_MAP_SYMBOL],languageCode)) {
            languageCode = this[DEFAULT_LANGUAGE_SYMBOL];
        }
        this[CURRENT_LANGUAGE_SYMBOL] = languageCode;
        this[CURRENT_SYMBOL] = this[MERGED_LOCALES_MAP_SYMBOL][languageCode];
    }

    /**
     * Translate the content of all `key` fields and saved the translated text to `outputField`.
     * @author LiFengbao <shijistar@hotmail.com>
     * @example <caption>Here's an example of how to translate field of multiple objects.</caption>
     *   let sourceObjs = [{
     *     name: "userName",
     *   }, {
     *     name: "mobile",
     *   }];
     *   
     *   let dictionary = {
     *     userName: "姓名",
     *     mobile: "手机"
     *   };
     *   
     *   translate(sourceObjs, "name", "displayText", dictionary);
     *   
     *   // sourceObjs:
     *   // [{
     *   //    name: "userName",
     *   //    displayText: "姓名"
     *   //  }, {
     *   //    name: "mobile",
     *   //    displayText: "手机"
     *   // }]
     * @param  {Array|Object} sourceObj - The source array or object (all child fields as array items) to translate.
     * @param  {String} sourceField - The field name to read content of translation sources, for instance `name` in above example.
     * @param  {String} outputField - The field name to save translation result, for instance `displayText` in above example.
     * @param  {Object} langDictionary - The dictionary that used to look up translation entries.
     * @returns {Array|Object} Returns the exactly same object of `sourceObj`.
     */
    translate(sourceObj, sourceField, outputField, langDictionary) {
        let objs = [];
        if (sourceObj instanceof Array) {
            objs = sourceObj;
        } else if (typeof sourceObj === "object") {
            let isAllChildObjects = false;
            let childObjs = [];
            for (let key in sourceObj) {
                if (Object.prototype.hasOwnProperty.call(sourceObj,key)) {
                    let childObj = sourceObj[key];
                    if (typeof childObj === "object") {
                        childObjs.push(childObj);
                    } else {
                        isAllChildObjects = false;
                        break;
                    }
                }
            }
            if (isAllChildObjects) {
                objs = childObjs;
            } else {
                objs = [sourceObj];
            }
        } else {
            objs = [sourceObj];
        }
        for (let i = 0; i < objs.length; i++) {
            let obj = objs[i];
            translateSingle(obj, sourceField, outputField, langDictionary);
        }
        return sourceObj;
    }

    merge(...localesManagers) {
        if (localesManagers && localesManagers.length > 0) {
            for (let manager of localesManagers) {
                if (manager) {
                    if (!(manager instanceof LocalesManagerClass)) {
                        throw new WrongTypeError("localesManagers", LocalesManagerClass.name);
                    }
                    this[MERGED_LOCALES_MAP_SYMBOL] = deepCopy(manager[LOCALES_MAP_SYMBOL], this[MERGED_LOCALES_MAP_SYMBOL]);
                }
            }
            this.setLanguage(this.currentLanguage);
        }
    }
}

function normalizeLanguage(language) {
    if (language == null) {
        return "";
    }
    if (typeof language !== "string") {
        language = language.toString();
    }
    return language.toUpperCase();
}

function getKeysRecursively(localObject) {
    let objectKeyArray = [];
    for (let key in localObject) {
        if (Object.prototype.hasOwnProperty.call(localObject,key)) {
            if (typeof localObject[key] === "object") {
                let childObjectKeyArray = getKeysRecursively(localObject[key]);
                if (childObjectKeyArray.length > 0) {
                    childObjectKeyArray.forEach((childObjectKey) => {
                        objectKeyArray.push(`${key}.${childObjectKey}`);
                    });
                }
            } else {
                objectKeyArray.push(key);
            }
        }
    }
    return objectKeyArray;
}

function translateSingle(obj, sourceField, outputField, langDictionary) {
    let resourceKey = obj[sourceField];
    if (!obj[outputField]) {
        let translation = get(langDictionary, resourceKey);
        if (translation) {
            obj[outputField] = translation;
        }
    }
}