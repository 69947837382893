import React from 'react';
import Udesk from 'Udesk';

// const UNITS_LIST = ['K', 'M', 'G'];

export default class SynonymWordsComponent extends React.Component {
    // #region privates
    privates = {
        isSynonymWordsModalShow: false,
        synonymWordsModalTitle: '',
        uploadModalTitle: '',
        synonymWords: [],
        standardWords: '',
        effectiveness: '1',
        newSynonymWords: '',
        synonymWordsId: '',
        newSynonymWordsIsNull: false,
        pleaseAddSynonymWords: false,
        smartWordsLibraryListInstance: null,
        synonymWordsExists: false
    };
    //#endregion 

    // #region computes
    static computes = {};
    //#endregion

    // #region cycles
    init() {

        let columns = this.getColumns();

        this.privates.columns = columns;

        // this.actions.update();
    }
    componentWillUnmount() {
        this.privates.smartWordsLibraryListInstance = null;
    }
    //#endregion

    // #region actions
    actions = {
        changeSynonymWords(e) {
            this.privates.newSynonymWords = e.target.value.replace(/\s+/g, '');
            let {
                newSynonymWords,
                synonymWords
            } = this.privates;
            if (synonymWords !== null && synonymWords.length > 0) {
                this.privates.newSynonymWordsIsNull = false;
                this.privates.pleaseAddSynonymWords = false;
                this.privates.synonymWordsExists = false;
                if (newSynonymWords && synonymWords.indexOf(newSynonymWords) !== -1) {
                    this.privates.synonymWordsExists = true;
                }

            }
            this.actions.update();
        },
        changeSynonymWordsEffectiveness(value) {
            this.privates.effectiveness = value;
            this.actions.update();
        },
        pressEnter(e) {
            if (e.keyCode === 13 || e.which === 13 || e.charCode === 13) {
                this.actions.addOneSynonymWords();
            }
            return;
        },
        addOneSynonymWords() {

            let {
                newSynonymWords,
            } = this.privates;

            if (!newSynonymWords || newSynonymWords.trim().length === 0) {
                this.privates.newSynonymWordsIsNull = true;
                this.privates.pleaseAddSynonymWords = false;
                this.privates.synonymWordsExists = false;
                this.actions.update();
                return;
            }
            if (newSynonymWords && this.privates.synonymWords.indexOf(newSynonymWords) !== -1) {
                this.privates.synonymWordsExists = true;
                this.privates.newSynonymWordsIsNull = false;
                this.privates.pleaseAddSynonymWords = false;
                this.actions.update();
                return;
            }

            this.privates.synonymWords.push(newSynonymWords);
            this.privates.pleaseAddSynonymWords = false;
            this.privates.newSynonymWordsIsNull = false;
            this.privates.synonymWordsExists = false;
            this.privates.newSynonymWords = '';
            this.actions.update();
        },
        deleteOneSynonymWords(index) {
            this.privates.synonymWords.splice(index, 1);

            let {
                synonymWords
            } = this.privates;

            if (synonymWords.length === 0) {
                this.privates.synonymWordsExists = false;
                this.privates.pleaseAddSynonymWords = true;
                this.privates.newSynonymWordsIsNull = false;
            }

            this.actions.update();
        },
        createSynonymWords() {
            let {
                locales
            } = this;
            this.privates.isSynonymWordsModalShow = true;
            this.privates.synonymWordsModalTitle = /* 新建敏感词 */locales.pages.semanticIntelligence.smartWordsLibrary.sensitiveWords.component.newSensitiveWord;
            this.actions.update();
        },
        editSynonymWords(id) {
            let {
                locales
            } = this;

            let {
                sdkOptions
            } = this.props;

            let url = Udesk.business.apiPath.concatApiPath(`/corpora/synonym/${id}`, sdkOptions);

            Udesk.ajax.get(url).then(
                (resp, textStatus, jqXHR) => {
                    let responseItem = $.extend(true, {}, resp.data);
                    this.privates.isSynonymWordsModalShow = true;
                    this.privates.synonymWordsModalTitle = /* 编辑敏感词 */locales.pages.semanticIntelligence.smartWordsLibrary.sensitiveWords.component.editSensitiveWords;
                    this.privates.standardWords = responseItem.standardWords;
                    this.privates.synonymWords = responseItem.synonymWords === null ? [] : responseItem.synonymWords;
                    this.privates.effectiveness = responseItem.effectiveness;
                    this.privates.synonymWordsId = responseItem.id;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.smartWordsLibrary.professionalWords.deleteErr);
                });
        },
        closeSynonymWordsModal() {
            this.privates.newSynonymWords = "";
            this.privates.synonymWords = [];
            this.privates.standardWords = '';
            this.privates.effectiveness = '1';
            this.privates.isSynonymWordsModalShow = false;
            this.privates.synonymWordsId = '';
            this.privates.newSynonymWordsIsNull = false;
            this.privates.pleaseAddSynonymWords = false;
            this.privates.synonymWordsExists = false;
            this.actions.update();
        },
        deleteSynonymWords(id) {

            let {
                sdkOptions
            } = this.props;

            let {
                smartWordsLibraryListInstance
            } = this.privates;

            let url = Udesk.business.apiPath.concatApiPath(`corpora/${id}`, sdkOptions);

            Udesk.ui.alerts.warning({
                content: this.locales.labels.deleteContent,
                onOk: () => {
                    Udesk.ajax.del(
                        url, sdkOptions
                    ).then(
                        (resp, textStatus, jqXHR) => {
                            Udesk.ui.notify.success(this.locales.components.pages.smartWordsLibrary.synonymWords.deleteSuccess);
                            smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.smartWordsLibrary.synonymWords.deleteError);
                        }
                    );
                }
            });
        },
        saveSynonymWords(e) {

            e.preventDefault();

            let isEdit = false;

            if (this.privates.synonymWordsId) {
                isEdit = true;
            }

            let {
                synonymWords,
                // pleaseAddSynonymWords,
                // newSynonymWordsIsNull,
                // synonymWordsExists
            } = this.privates;

            let {
                sdkOptions
            } = this.props;

            let params = null;

            if (synonymWords === null || synonymWords.length === 0) {
                this.privates.pleaseAddSynonymWords = true;
                this.privates.synonymWordsExists = false;
                this.privates.newSynonymWordsIsNull = false;
                this.actions.update();
            }

            this.props.form.validateFields((err, values) => {
                if (!err) {

                    params = Object.assign({}, { corporaType: 7 }, values);

                    let { locales } = this;

                    let {
                        smartWordsLibraryListInstance
                    } = this.privates;

                    let title = isEdit ? locales.components.pages.smartWordsLibrary.synonymWords.editSuccess : locales.components.pages.smartWordsLibrary.synonymWords.addSuccess;

                    let requestType = isEdit ? 'put' : 'post';

                    let url = '';

                    let id = this.privates.synonymWordsId;
                    // 发送请求,以后联调时去掉注释
                    if (isEdit) {
                        url = Udesk.business.apiPath.concatApiPath(`corpora/common/${id}`, sdkOptions);
                    } else {
                        url = Udesk.business.apiPath.concatApiPath(`corpora/sensitive`, sdkOptions);
                    }

                    let that = this;

                    Udesk.ajax[requestType](url, params).then(
                        (resp, textStatus, jqXHR) => {
                            that.actions.closeSynonymWordsModal();
                            Udesk.ui.notify.success(title);
                            smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || that.locales.components.pages.smartWordsLibrary.synonymWords.deleteErr);
                        });
                } else {
                    return;
                }
            });
        }
    };
    //#endregion

    // #region public methods used "this":
    getColumns() {
        let {
            locales
        } = this;
        let columns = [

            {
                name: "corporaName",
                width: "10%",
                caption: /* 敏感词 */locales.pages.semanticIntelligence.smartWordsLibrary.sensitiveWords.component.sensitiveWords
            },

            // {
            //     name: "useFrequency",
            //     width: "10%",
            //     caption: locales.fields.smartWordsLibrary.synonymWords.useFrequency,
            //     hasTemplate: true,
            //     getYieldContent: (name, item, index) => {
            //         return (
            //             <span>{Udesk.business.smartWordsMethods.getFormatFrequency(item.useFrequency, UNITS_LIST)}</span>
            //         );
            //     }
            // },

            {
                name: "corporaStatus",
                width: "10%",
                caption: locales.fields.smartWordsLibrary.synonymWords.effectiveness,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    let targetItem = Udesk.enums.wordsEffectiveness.get(item.corporaStatus);
                    return (<span> {targetItem?.name}</span>
                    );
                }
            },

            {
                name: "operation",
                width: "15%",
                caption: locales.fields.smartWordsLibrary.synonymWords.operation,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return (
                        <div className="smart-words-library-synonym-words-operation" >
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:edit")}>
                                <span className="smart-words-library-operation-edit"
                                    title={locales.labels.edit}
                                    onClick={this.actions.editSynonymWords.params(item.id)}>
                                    {locales.labels.edit}
                                </span>
                            </If>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:delete")}>
                                <span
                                    className="smart-words-library-operation-delete"
                                    title={locales.labels.delete}
                                    onClick={this.actions.deleteSynonymWords.params(item.id)}> {locales.labels.delete}
                                </span>
                            </If>
                        </div>
                    );
                }
            }
        ];
        return columns;
    }
}