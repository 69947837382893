import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';

function TeamCommon(props) {
    let { tabData, teamBestData } = props;
    const [chartOptions] = useState({
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                let str =
                    params[0].name +
                    '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                    params[0].value +
                    '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                    0;
                return str;
            },
        },
        xAxis: {
            type: 'category',
            data: [],
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false,
            },
            splitNumber: 5,
        },
        series: [
            {
                data: [],
                type: 'line',
                symbol: 'none',
                lineStyle: {
                    color: '#128FE9',
                },
                markLine: {},
            },
        ],
    });
    useEffect(() => {
        let timer: any = null;
        const pageBodyOnresize = document.querySelector('.udesk-qa-web-home-page-sider');
        let newTabData: any = [];
        tabData.forEach((item, idx) => {
            item.labelName = [];
            item.callData = [];
            item.stats.forEach(record => {
                item.labelName.push(record.date);
                item.callData.push(record.sum);
            });
            echarts.init(document.getElementById(item.domId)).setOption({
                ...chartOptions,
                xAxis: {
                    ...chartOptions.xAxis,
                    data: item.labelName,
                },
                series: [
                    {
                        ...chartOptions.series[0],
                        data: item.callData,
                        markLine: {
                            symbol: 'none',
                            data: [
                                {
                                    yAxis: teamBestData?.[idx]?.sum??0,
                                    lineStyle: {
                                        color: '#F59A23',
                                        type: 'solid',
                                        width: 2,
                                    },
                                    label: {
                                        formatter: function (params) {
                                            return '';
                                        },
                                    },
                                },
                            ],
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let str =
                            params[0].name +
                            '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                            params[0].value +
                            '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                            teamBestData?.[idx]?.sum??0;
                        return str;
                    },
                },
            });
            newTabData.push(echarts.init(document.getElementById(item.domId)));
        });

        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                timer = setTimeout(() => {
                    newTabData.forEach((item) => {
                        item.resize();
                    });
                }, 200);
            });
        });
        if (pageBodyOnresize) {
            resizeObserver.observe(pageBodyOnresize);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [chartOptions, teamBestData]);
    return (
        <React.Fragment>
            {tabData.map((item) => {
                return (
                    <div key={item.id}>
                        <h3 style={{ fontSize: 20, fontWeight: 700 }}>{item.label}</h3>
                        <div id={item.domId} style={{ width: '100%', height: '400px' }}></div>
                    </div>
                );
            })}
        </React.Fragment>
    );
}

export default TeamCommon;
