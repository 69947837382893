import Udesk from 'Udesk';
import RoleDetailBasicInfoComponent from './component';

class RoleDetailBasicInfoRoute extends RoleDetailBasicInfoComponent {
    model() {
        let {
            match,
            sdkOptions
        } = this.props;

        let rolePromise;
        if (match.params.id) {
            let url = Udesk.business.apiPath.concatApiPath(`roles/${match.params.id}`, sdkOptions);
            rolePromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        } else {
            rolePromise = new Promise((resolve, reject) => {
                resolve(null);
            });
        }

        return ({
            role: rolePromise
        });
    }

    parseModel(model) {
        if (model.role) {
            this.privates.id = model.role.id;
            this.privates.name = model.role.name;
            this.privates.selectedMenuList = model.role.menuList;
            this.actions.update();
        }
    }
}

export default RoleDetailBasicInfoRoute;
