import Udesk from 'Udesk';
import SdkLoaderBase from 'Component/common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';

class AgentReportComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: "tasks-reports-agent-report",
        pageTitle: UdeskLocales.current.components.tasks.subMenu.statisticsAndReports.agentReport.text
    };
}

export default Udesk.react.udeskify(AgentReportComponent);