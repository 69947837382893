// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerTaskInfosUserFindAllQuery,
    BaseResponseListIntelligentPartnerTaskInfoFoundResponse,
} from '../../../types';

/**
 * 学员端-获取发布给自己的所有任务
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/user/findAll
 */
export function getIntelligentPartnerTaskInfosUserFindAll(): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse>;
export function getIntelligentPartnerTaskInfosUserFindAll(
    options: ApiOptions<never, GetIntelligentPartnerTaskInfosUserFindAllQuery>
): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse>;
export function getIntelligentPartnerTaskInfosUserFindAll(
    options?: ApiOptions<never, GetIntelligentPartnerTaskInfosUserFindAllQuery>
): Promise<BaseResponseListIntelligentPartnerTaskInfoFoundResponse> {
    return request<never, never, GetIntelligentPartnerTaskInfosUserFindAllQuery>(
        '/intelligentPartnerTaskInfos/user/findAll',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerTaskInfosUserFindAll',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/user/findAll',
        method: 'get',
    },
];
