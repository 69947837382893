import React from 'react';
import { Empty, Tag } from 'udesk-ui';
import CustomerFields from 'Component/pages/gong/customer-fields';
import UdeskLocales from 'UdeskLocales';
import './style.scss';

const CustomerInsight = (props) => {
    const { tags, fieldList } = props;

    return (
        <div className="customer-insight-tab-content">
            <div className="customer-insight-title">
                {/* 客户洞察 */}
                {UdeskLocales['current'].components.gong.customerInsight.index.customerInsight}
            </div>

            <div className="customer-insight-card">
                <div>
                    {/* 客户标签 */}
                    {UdeskLocales['current'].components.gong.customerInsight.index.customerLabel}
                </div>
                <div>
                    {tags?.length > 0 ? (
                        tags?.map((tag) => (
                            <Tag
                                key={tag.id}
                                style={{
                                    marginBottom: 4,
                                    background: tag.categoryColor + '1a',
                                    color: tag.categoryColor,
                                    maxWidth: '100%',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                color={tag.categoryColor}
                                title={tag.tagName}
                            >
                                {tag.tagName}
                            </Tag>
                        ))
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>

                <CustomerFields
                    hiddenBase
                    // baseFieldsProps={{
                    //     customerId,
                    //     customerName,
                    //     stageStatus,
                    //     phoneNumber,
                    //     followUpDays,
                    //     remark,
                    //     descriptionsConfig: {
                    //         title: <div style={{ fontSize: 14, fontWeight: 'bold' }}>客户信息</div>,
                    //         column: 1,
                    //         bordered: true,
                    //         size: 'small',
                    //     },
                    // }}
                    otherFieldsProps={{
                        fieldList,
                        descriptionsConfig: {
                            title: (
                                <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                                    {/* 客户信息 */}
                                    {
                                        UdeskLocales['current'].components.gong.customerInsight
                                            .index.customerInformation
                                    }
                                </div>
                            ),
                            column: 1,
                            bordered: true,
                            size: 'small',
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default React.memo(CustomerInsight);
