import { rebuild as rebuildEnums } from 'udesk-web-toolkits/src/udesk/enums';
import common from './common';
import qualityCheckingTask from './quality-checing-task';
import qualityInspectionForm from './quality-inspection-form';
import qualityInspectionStatistical from './quality-inspection-statistical';
import fields from './fields';
import features from './features';
import appealCheckingTask from './appeal-checking-task';
import spotCheckList from './spot-check-list';
import reviewList from './review-list';
import synonymWordsEffectiveness from './smart-words-library';
import speechRecognition from './speech-recognition';
import semanticIntelligence from './semantic-intelligence';
import operatorListGather from './operator-list-gather';
import callDialogueManage from './call-dialogue-manage';
import listManage from './list-manage';
import intelligentRecommend from './intelligent-recommend';
import dataPreprocess from './data-preprocess';
import connectedByConversation from './connected-by-conversation';
import tasks from './tasks';
import user from './user';
import customerField from './customer-field';
import classifiedTraining from './classified-training';
import templateComponentList from './template-component-list';
import wechat from './wechat';
import knowledgeBase from './konwledge-base';
import gong from './gong';
import coach from './coach';
import algorithm from './algorithm';

let enumsContainer = Object.assign(
    {},
    common,
    qualityCheckingTask,
    qualityInspectionForm,
    qualityInspectionStatistical,
    fields,
    features,
    appealCheckingTask,
    spotCheckList,
    reviewList,
    synonymWordsEffectiveness,
    speechRecognition,
    semanticIntelligence,
    operatorListGather,
    callDialogueManage,
    listManage,
    intelligentRecommend,
    dataPreprocess,
    connectedByConversation,
    tasks,
    user,
    classifiedTraining,
    templateComponentList,
    customerField,
    wechat,
    knowledgeBase,
    gong,
    coach,
    algorithm,
);

rebuildEnums.call(enumsContainer);
export default enumsContainer;