import React, { useState, useCallback } from 'react';
import Udesk from 'Udesk';
import { useStorageProps } from 'src/util/component';
import { Table, Tag } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { isDefined } from 'src/util/core';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

const pageSize = Udesk.config.paging.defaultPageSize;

export default React.memo((props: any) => { 
    const {updateStorages} = props;  
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tableDataSource, setTableDataSource] = useState<any[]>([]);
    const [leftPageSelectedRow] = useStorageProps(props, 'leftPageSelectedRow');
    const [pageNum, setPageNum] = useStorageProps(props, 'centerPagePageNum');
    const [selectedRow, setSelectedRow] = useStorageProps(props, 'centerPageSelectedRow');
    const request = useRequest();

    const renderCell = useCallback((text, record) => {
        return (
            <div 
                className='table-row-item'
                onClick={() => {
                    updateStorages(false, {
                        centerPageSelectedRow: record,
                        rightPagePagination(paging) {
                            return {
                                current: 1,
                                pageSize: paging.pageSize,
                                total: 0,
                            };
                        }
                    });
                }}
            >
                <span>{record.question}</span>
                <div className="toolkit-button">
                    <Tag>{record.questionCount}</Tag>
                </div>
            </div>
        );
    }, [updateStorages]);

    const requestTableSource = useCallback((pageNum = 1) => {
        if (leftPageSelectedRow?.id) {
            let url = `/review/cluster/clusterResult/${leftPageSelectedRow.id}`;
            let params = {
                pageNum,
                pageSize,
            };
            setLoading(true);
            request(url, params).then(
                resp => {
                    setTableDataSource(resp.data.map(item => ({
                        ...item,
                        key: item.id
                    })));
                    setTotal(resp.paging.total);
                    setPageNum(pageNum);
                    setSelectedRow((selectedRow: any) => {
                        return isDefined(selectedRow, () => selectedRow, () => resp.data[0]);
                    });
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                setLoading(false);
            });
        }
    }, [
        leftPageSelectedRow?.id, 
        request, 
        setSelectedRow,
        setPageNum,
    ]);

    const onPageNumChange = useCallback((pageNum) => {
        setPageNum(pageNum);
        requestTableSource(pageNum);
    }, [
        setPageNum,
        requestTableSource
    ]);

    useLiveEffect(() => requestTableSource(pageNum), [requestTableSource]);

    return (
        <div className='selling-point-dissent-page-center'>
            <div>
                <div>{/* 话术概况 */}{UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.pageCenterTable.scriptOverview}</div>
            </div>
            <div>
                <Table
                    loading={loading}
                    scroll={{
                        y: 'calc(100% - 56px)'
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedRow ? [selectedRow.id] : [],
                        columnTitle: <div style={{padding: '0 8px'}} className='ellipsis'>{leftPageSelectedRow?.name}</div>,
                        type: 'radio',
                        renderCell,
                    }}
                    pagination={{
                        current: pageNum,
                        pageSize,
                        total,
                        simple : true,
                        onChange: onPageNumChange,
                        hideOnSinglePage: true,
                    }} 
                    columns={[]}
                    dataSource={tableDataSource}
                />
            </div>
        </div>
    );
});
