import moment from 'moment';

export const array2string = (value, separator = ',') => {
    if (value && value.length > 0) {
        return value.join(separator);
    }
};

export const string2array = (value, separator = ',') => {
    if (value) {
        return value.split(separator);
    }
    return [];
};

export const string2IntArray = (value, separator = ',') => {
    if (value) {
        return value.split(separator).map(id => +id);
    }
    return [];
};

export const string2moment = (value, format = 'YYYY-MM-DD 00:00:00') => {
    if (value) {
        return moment(value, format);
    }
};

export const moment2string = (value, format = 'YYYY-MM-DD 00:00:00') => {
    if (value) {
        return value.format(format);
    }
};
