import React from 'react';
import Udesk from 'Udesk';
import FastTrainingIndexRoute from './route';
import { PageHeader, Input, Button, Checkbox, Row, Col, Modal, Spin, Empty } from 'udesk-ui';
import UdeskPagination from 'Component/common/udesk-pagination';
import SimilarQuestionRecommend from '../similar-question-recommend';
import './style.scss';

const { Search } = Input;

export default class FastTrainingIndexTemplate extends FastTrainingIndexRoute {
    render() {
        let {
            semanticTagsInputVlaue,
            semanticTagsTotal,
            semanticTagsPageNum,
            semanticTagsPageSize,
            semanticTagsList,
            activeSemanticTag,
            standardSentence,
            standardSentenceEditFlag,
            standardSentenceEditInputShow,
            standardSentenceRef,
            similarSentencesAddInputValue,
            similarSentencesModalVisible,
            similarSearchCheckedList,
            similarSearchPlainOptions,
            similarSentenceSearchPageNum,
            similarSentenceSearchTotal,
            similarSentenceSearchPageSize,
            similarSentenceSearchMoreFlag,
            showMoreVisity,
            showMoreList,
            customer,
            // modalSearchType,
            similarSentenceList,
            similarSentenceEditFlag,
            similarSentenceEditInputShow,
            similarRecommendPlainOptions,
            similarRecommendCheckedList,
            indeterminate,
            checkAllSimilarRecommend,
            similarSentenceRecommendEditFlag,
            similarSentenceRecommendEditInputShow,
            similarSentencesModalSearchInputValue,
            similarRecommendPageNum,
            similarRecommendTotal,
            similarRecommendPageSize,
            loading,
            systemSentencesLoading,
        } = this.privates;
        let { actions, locales } = this;

        return (
            // <div className="fast-training-index-page">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.trainingCenter.fastTraining.title}
                    subTitle={locales.components.pages.trainingCenter.fastTraining.titleDes}
                />
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root fast-training-index-page">
                        <div className="fast-training-index-page-body-left">
                            <div>
                                <div className="fast-training-index-page-body-left-tltle">
                                    {
                                        locales.components.pages.trainingCenter.fastTraining
                                            .semanticTags
                                    }
                                </div>
                                <div className="fast-training-index-page-body-left-content">
                                    <div className="fast-training-index-page-body-left-content-input">
                                        <Search
                                            placeholder={
                                                locales.components.pages.trainingCenter.fastTraining
                                                    .semanticTagsPlaceholder
                                            }
                                            onChange={actions.semanticTagsInput}
                                            onSearch={actions.semanticTagsSearch}
                                            onPressEnter={actions.semanticTagsSearch}
                                            value={semanticTagsInputVlaue}
                                        />
                                    </div>
                                    <div className="fast-training-index-page-body-left-content-list">
                                        <If condition={semanticTagsList.length !== 0}>
                                            {semanticTagsList.map((semanticTag) => {
                                                return (
                                                    <div
                                                        onClick={actions.selectSemanticTag.params(
                                                            semanticTag
                                                        )}
                                                        title={semanticTag.title}
                                                        className={`fast-training-index-page-body-left-content-list-item${
                                                            activeSemanticTag &&
                                                            semanticTag.id === activeSemanticTag.id
                                                                ? ' active'
                                                                : ''
                                                        }`}
                                                    >
                                                        <span className="fast-training-index-page-body-left-content-list-item-title">
                                                            {semanticTag.title}
                                                        </span>
                                                        <span
                                                            className="udesk-qa-react-web-iconfont"
                                                            style={{
                                                                marginLeft: 10,
                                                                color: 'rgba(240,154,0,1)',
                                                                display:
                                                                    semanticTag.needSuggest &&
                                                                    semanticTag.needSuggest === 1
                                                                        ? 'block'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            &#xe702;
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </If>
                                        <If condition={semanticTagsList.length === 0}>
                                            {/* else */}
                                            <div style={{ textAlign: 'center' }}>
                                                {
                                                    locales.components.pages.trainingCenter
                                                        .fastTraining.noResp
                                                }
                                            </div>
                                        </If>
                                    </div>
                                </div>
                            </div>
                            <UdeskPagination
                                current={semanticTagsPageNum}
                                total={semanticTagsTotal}
                                pageSize={semanticTagsPageSize}
                                hideOnSinglePage={true}
                                simple={true}
                                onChange={actions.pageNumChange.params('tags')}
                            />
                        </div>
                        <div className="fast-training-index-page-body-right">
                            <div className="fast-training-index-page-body-right-similar-add-bar">
                                <div style={{ height: '100%', overflowY: 'hidden' }}>
                                    <div
                                        className="fast-training-index-page-body-right-similar-add-bar-standard-sentence"
                                        key={standardSentence}
                                        onMouseOver={actions.showEdit.params(1, 'standard')}
                                        onMouseLeave={actions.showEdit.params(0, 'standard')}
                                        style={{
                                            display: standardSentenceEditInputShow
                                                ? 'none'
                                                : 'flex',
                                        }}
                                    >
                                        <div
                                            style={{ width: '100%' }}
                                            title={standardSentence.content}
                                        >
                                            {Udesk.utils.string.format(
                                                locales.components.pages.trainingCenter.fastTraining
                                                    .standardSentence,
                                                standardSentence.content
                                            )}
                                        </div>
                                        <span
                                            style={{
                                                display: standardSentenceEditFlag ? 'flex' : 'none',
                                                color: 'rgba(27,109,255,1)',
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                                width: 32,
                                            }}
                                            onClick={actions.editSentence.params(1, 'standard')}
                                        >
                                            {locales.components.pages.trainingCenter.edit}
                                        </span>
                                    </div>
                                    <div
                                        className="fast-training-index-page-body-right-similar-add-bar-standard-sentence"
                                        style={{
                                            display: standardSentenceEditInputShow
                                                ? 'flex'
                                                : 'none',
                                        }}
                                    >
                                        <Input
                                            ref={standardSentenceRef}
                                            value={standardSentence.content}
                                            onChange={actions.saveNewSentence.params(
                                                'standard',
                                                null
                                            )}
                                            onBlur={actions.editSentence.params(0, 'standard')}
                                        ></Input>
                                    </div>
                                    <div className="fast-training-index-page-body-right-similar-add-bar-standard-sentence-input">
                                        <Search
                                            placeholder={
                                                locales.components.pages.trainingCenter.fastTraining
                                                    .similarSentencesPlaceholder
                                            }
                                            enterButton={
                                                locales.components.pages.trainingCenter.fastTraining
                                                    .similarSentencesAdd
                                            }
                                            size="large"
                                            onSearch={actions.similarSentencesAdd}
                                            value={similarSentencesAddInputValue}
                                            onChange={actions.similarSentencesAddInputValueChange}
                                            onPressEnter={actions.similarSentencesAddByEnter.params(
                                                similarSentencesAddInputValue
                                            )}
                                        />
                                        <Button
                                            onClick={actions.similarSentencesSearch}
                                            style={{ marginLeft: '8px' }}
                                        >
                                            {
                                                locales.components.pages.trainingCenter.fastTraining
                                                    .similarSentencesSearch
                                            }
                                        </Button>
                                        <Modal
                                            title={
                                                locales.components.pages.trainingCenter.fastTraining
                                                    .similarSentencesModalTitle
                                            }
                                            visible={similarSentencesModalVisible}
                                            className="fast-training-similar-sentences-modal"
                                            onOk={actions.similarSentencesModalOk}
                                            onCancel={actions.similarSentencesModalCancel}
                                            okText={locales.components.pages.trainingCenter.add}
                                        >
                                            {/* <Select value={modalSearchType} style={{ width: 60 }} onChange={actions.modalSearchTypeChange}>
                                            <Option value={1}>{locales.components.pages.trainingCenter.fastTraining.modalSearchType.all}</Option>
                                            <Option value={2}>{locales.components.pages.trainingCenter.fastTraining.modalSearchType.customer}</Option>
                                            <Option value={3}>{locales.components.pages.trainingCenter.fastTraining.modalSearchType.client}</Option>
                                        </Select> */}
                                            <div>
                                                <Search
                                                    placeholder={
                                                        locales.components.pages.trainingCenter
                                                            .fastTraining
                                                            .similarSentencesPlaceholder
                                                    }
                                                    onSearch={actions.similarSentencesModalSearch}
                                                    value={similarSentencesModalSearchInputValue}
                                                    onChange={
                                                        actions.similarSentencesModalSearchInputValueChange
                                                    }
                                                    onPressEnter={actions.similarSentencesModalSearchByEnter.params(
                                                        similarSentencesModalSearchInputValue
                                                    )}
                                                    style={{ width: 440, fontSize: 14 }}
                                                />
                                                <Spin
                                                    spinning={loading}
                                                    wrapperClassName="fast-training-loading"
                                                >
                                                    <If
                                                        condition={
                                                            similarSearchPlainOptions.length !== 0
                                                        }
                                                    >
                                                        <Checkbox.Group
                                                            style={{ width: '100%', marginTop: 16 }}
                                                            onChange={actions.similarSearchCheckboxOnChange.params(
                                                                similarSentenceSearchPageNum
                                                            )}
                                                            value={
                                                                similarSearchCheckedList[
                                                                    similarSentenceSearchPageNum
                                                                ]
                                                            }
                                                        >
                                                            <Row>
                                                                <For
                                                                    each="plainOption"
                                                                    index="index"
                                                                    of={similarSearchPlainOptions}
                                                                >
                                                                    <Col span={24}>
                                                                        <div
                                                                            key={plainOption.id}
                                                                            title={
                                                                                plainOption.content
                                                                            }
                                                                            onMouseOver={actions.showEdit.params(
                                                                                1,
                                                                                'search',
                                                                                index
                                                                            )}
                                                                            onMouseLeave={actions.showEdit.params(
                                                                                0,
                                                                                'search',
                                                                                index
                                                                            )}
                                                                            style={{
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <Checkbox
                                                                                value={
                                                                                    plainOption.content
                                                                                }
                                                                                key={
                                                                                    plainOption.callId
                                                                                }
                                                                                style={{
                                                                                    overflow:
                                                                                        'hidden',
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                    textOverflow:
                                                                                        'ellipsis',
                                                                                    marginBottom: 8,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    plainOption.content
                                                                                }
                                                                            </Checkbox>
                                                                            <span
                                                                                style={{
                                                                                    display:
                                                                                        similarSentenceSearchMoreFlag[
                                                                                            index
                                                                                        ]
                                                                                            ? 'flex'
                                                                                            : 'none',
                                                                                    color: 'rgba(27,109,255,1)',
                                                                                    cursor: 'pointer',
                                                                                    userSelect:
                                                                                        'none',
                                                                                    marginLeft:
                                                                                        '8px',
                                                                                }}
                                                                                onClick={actions.showMore.params(
                                                                                    plainOption
                                                                                )}
                                                                                class="udesk-qa-react-web-iconfont"
                                                                            >
                                                                                &#xe6ed;
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                </For>
                                                            </Row>
                                                        </Checkbox.Group>
                                                    </If>
                                                    <If
                                                        condition={
                                                            similarSearchPlainOptions.length === 0
                                                        }
                                                    >
                                                        <Empty
                                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                        />
                                                    </If>
                                                </Spin>
                                            </div>
                                            {Boolean(similarSentenceSearchTotal) && (
                                                <UdeskPagination
                                                    current={similarSentenceSearchPageNum}
                                                    total={similarSentenceSearchTotal}
                                                    pageSize={similarSentenceSearchPageSize}
                                                    hideOnSinglePage={true}
                                                    showSizeChanger={false}
                                                    onChange={actions.pageNumChange.params(
                                                        'search'
                                                    )}
                                                    showTotal={() => null}
                                                />
                                            )}
                                            <div
                                                className="fast-training-index-page-body-more-info"
                                                style={{
                                                    display:
                                                        similarSentencesModalVisible &&
                                                        showMoreVisity
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                            >
                                                <div className="fast-training-index-page-body-more-info-title">
                                                    <div>
                                                        {
                                                            locales.components.pages.trainingCenter
                                                                .fastTraining.moreInfo
                                                        }
                                                    </div>
                                                    <span
                                                        className="udesk-qa-react-web-iconfont fast-training-index-page-body-more-info-cancel"
                                                        onClick={actions.closeShowMore}
                                                    >
                                                        &#xe644;
                                                    </span>
                                                </div>
                                                <div className="fast-training-index-page-body-more-info-content">
                                                    {showMoreList.length !== 0 &&
                                                        showMoreList.map((item) => {
                                                            if (item.channel_id === customer) {
                                                                return (
                                                                    <div className="fast-training-index-page-body-more-info-content-item-customer">
                                                                        <div className="fast-training-index-page-body-more-info-content-item-customer-left">
                                                                            <div className="fast-training-index-page-body-more-info-content-item-customer-left-content">
                                                                                {item.text}
                                                                            </div>
                                                                            <div className="fast-training-index-page-body-more-info-content-item-customer-left-time">
                                                                                {getAudioItemTime(
                                                                                    item.begin_time,
                                                                                    item.end_time
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="fast-training-index-page-body-more-info-content-item-customer-rigth">
                                                                            <span className="udesk-qa-react-web-iconfont">
                                                                                &#xe63b;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div className="fast-training-index-page-body-more-info-content-item-user">
                                                                        <div className="fast-training-index-page-body-more-info-content-item-user-left">
                                                                            <span className="udesk-qa-react-web-iconfont">
                                                                                &#xe6e0;
                                                                            </span>
                                                                        </div>
                                                                        <div className="fast-training-index-page-body-more-info-content-item-user-rigth">
                                                                            <div className="fast-training-index-page-body-more-info-content-item-user-rigth-content">
                                                                                {item.text}
                                                                            </div>
                                                                            <div className="fast-training-index-page-body-more-info-content-item-user-rigth-time">
                                                                                {getAudioItemTime(
                                                                                    item.begin_time,
                                                                                    item.end_time
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                    <div className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-list">
                                        <div className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-title">
                                            <div className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-title-left">
                                                {
                                                    locales.components.pages.trainingCenter
                                                        .fastTraining.similarSentences
                                                }
                                            </div>
                                            <div className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-title-right">
                                                {Udesk.utils.string.format(
                                                    locales.components.pages.trainingCenter
                                                        .fastTraining.trainingCount,
                                                    similarSentenceList.length + 1
                                                )}
                                            </div>
                                        </div>
                                        <div className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-title-left-content">
                                            {similarSentenceList.length !== 0 &&
                                                similarSentenceList.map(
                                                    (similarSentence, index) => {
                                                        return (
                                                            <React.Fragment>
                                                                <div
                                                                    className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-item"
                                                                    key={
                                                                        similarSentence.id ||
                                                                        similarSentence.content
                                                                    }
                                                                    onMouseOver={actions.showEdit.params(
                                                                        1,
                                                                        'similar',
                                                                        index
                                                                    )}
                                                                    onMouseLeave={actions.showEdit.params(
                                                                        0,
                                                                        'similar',
                                                                        index
                                                                    )}
                                                                    style={{
                                                                        display:
                                                                            similarSentenceEditInputShow[
                                                                                index
                                                                            ]
                                                                                ? 'none'
                                                                                : 'flex',
                                                                    }}
                                                                >
                                                                    <div
                                                                        title={
                                                                            similarSentence.content
                                                                        }
                                                                        style={{ flex: 1 }}
                                                                    >
                                                                        {similarSentence.content}
                                                                    </div>
                                                                    <span
                                                                        style={{
                                                                            display:
                                                                                similarSentenceEditFlag[
                                                                                    index
                                                                                ]
                                                                                    ? 'flex'
                                                                                    : 'none',
                                                                            width: 73,
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: 'rgba(27,109,255,1)',
                                                                                cursor: 'pointer',
                                                                                userSelect: 'none',
                                                                            }}
                                                                            onClick={actions.editSentence.params(
                                                                                1,
                                                                                'similar',
                                                                                index
                                                                            )}
                                                                        >
                                                                            {
                                                                                locales.components
                                                                                    .pages
                                                                                    .trainingCenter
                                                                                    .edit
                                                                            }
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                color: 'rgba(27,109,255,1)',
                                                                                cursor: 'pointer',
                                                                                userSelect: 'none',
                                                                                marginLeft: '8px',
                                                                            }}
                                                                            onClick={actions.deleteSentence.params(
                                                                                similarSentence,
                                                                                index
                                                                            )}
                                                                        >
                                                                            {
                                                                                locales.components
                                                                                    .pages
                                                                                    .trainingCenter
                                                                                    .delete
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            similarSentenceEditInputShow[
                                                                                index
                                                                            ]
                                                                                ? 'flex'
                                                                                : 'none',
                                                                    }}
                                                                    className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-item"
                                                                >
                                                                    <Input
                                                                        ref={actions.setSimilarSentenceRefs.params(
                                                                            index
                                                                        )}
                                                                        value={
                                                                            similarSentence.content
                                                                        }
                                                                        onChange={actions.saveNewSentence.params(
                                                                            'similar',
                                                                            index
                                                                        )}
                                                                        onBlur={actions.editSentence.params(
                                                                            0,
                                                                            'similar',
                                                                            index
                                                                        )}
                                                                    ></Input>
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ margin: '0 auto' }}>
                                    <Button onClick={actions.cancel} style={{ marginLeft: '8px' }}>
                                        {locales.components.pages.trainingCenter.cancel}
                                    </Button>
                                    <Button
                                        onClick={actions.save}
                                        type="primary"
                                        style={{ marginLeft: '8px' }}
                                    >
                                        {locales.components.pages.trainingCenter.save}
                                    </Button>
                                </div>
                            </div>
                            <div className="fast-training-index-page-body-right-similar-recommend-bar">
                                <Spin
                                    spinning={systemSentencesLoading}
                                    wrapperClassName="system-sentence-loading"
                                >
                                    <div className="fast-training-index-page-body-right-similar-recommend-bar-inner">
                                        <div
                                            style={{
                                                maxHeight: 'calc(100% - 48px)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1,
                                            }}
                                        >
                                            <div className="fast-training-index-page-body-right-similar-recommend-bar-title">
                                                {
                                                    locales.components.pages.trainingCenter
                                                        .fastTraining.similarSentencesRecommend
                                                }
                                                <div>
                                                    <span
                                                        className="select-all"
                                                        style={{ color: 'rgba(27,109,255,1)' }}
                                                    >
                                                        <Checkbox
                                                            indeterminate={indeterminate}
                                                            onChange={actions.oncheckAllSimilarRecommendChange.params(
                                                                similarRecommendPageNum
                                                            )}
                                                            checked={
                                                                checkAllSimilarRecommend[
                                                                    similarRecommendPageNum
                                                                ]
                                                            }
                                                        >
                                                            <If
                                                                condition={
                                                                    !checkAllSimilarRecommend[
                                                                        similarRecommendPageNum
                                                                    ]
                                                                }
                                                            >
                                                                {
                                                                    locales.components.pages
                                                                        .trainingCenter.seleteAll
                                                                }
                                                            </If>
                                                            {/* else */}
                                                            <If
                                                                condition={
                                                                    checkAllSimilarRecommend[
                                                                        similarRecommendPageNum
                                                                    ]
                                                                }
                                                            >
                                                                {
                                                                    locales.components.pages
                                                                        .trainingCenter
                                                                        .cancelCheckAll
                                                                }
                                                            </If>
                                                        </Checkbox>
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: 'rgba(27,109,255,1)',
                                                            marginLeft: 14,
                                                        }}
                                                        onClick={actions.addSimilarFromRecommend}
                                                    >
                                                        {
                                                            locales.components.pages.trainingCenter
                                                                .add
                                                        }
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: 'rgba(27,109,255,1)',
                                                            marginLeft: 14,
                                                            marginRight: 9,
                                                        }}
                                                        onClick={actions.removeSimilarFromRecommend}
                                                    >
                                                        {
                                                            locales.components.pages.trainingCenter
                                                                .remove
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="fast-training-index-page-body-right-similar-recommend-bar-checkbox">
                                                <Checkbox.Group
                                                    style={{ width: '100%' }}
                                                    onChange={actions.similarRecommendCheckboxOnChange.params(
                                                        similarRecommendPageNum
                                                    )}
                                                    value={
                                                        similarRecommendCheckedList[
                                                            similarRecommendPageNum
                                                        ]
                                                    }
                                                >
                                                    <Row style={{ width: '100%' }}>
                                                        <For
                                                            each="plainOption"
                                                            index="index"
                                                            of={similarRecommendPlainOptions}
                                                        >
                                                            <Col span={24}>
                                                                <div
                                                                    className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-recommend-list-item"
                                                                    key={plainOption.id}
                                                                    title={plainOption.content}
                                                                    onMouseOver={actions.showEdit.params(
                                                                        1,
                                                                        'recommend',
                                                                        index
                                                                    )}
                                                                    onMouseLeave={actions.showEdit.params(
                                                                        0,
                                                                        'recommend',
                                                                        index
                                                                    )}
                                                                    style={{
                                                                        display:
                                                                            similarSentenceRecommendEditInputShow[
                                                                                index
                                                                            ]
                                                                                ? 'none'
                                                                                : 'flex',
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        value={plainOption.content}
                                                                        key={plainOption.id}
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'nowrap',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                            marginBottom: 8,
                                                                        }}
                                                                    >
                                                                        {plainOption.content}
                                                                    </Checkbox>
                                                                    <span
                                                                        style={{
                                                                            display:
                                                                                similarSentenceRecommendEditFlag[
                                                                                    index
                                                                                ]
                                                                                    ? 'flex'
                                                                                    : 'none',
                                                                            width: 82,
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: 'rgba(27,109,255,1)',
                                                                                cursor: 'pointer',
                                                                                userSelect: 'none',
                                                                                with: 39,
                                                                            }}
                                                                            onClick={actions.editSentence.params(
                                                                                1,
                                                                                'recommend',
                                                                                index
                                                                            )}
                                                                        >
                                                                            {
                                                                                locales.components
                                                                                    .pages
                                                                                    .trainingCenter
                                                                                    .edit
                                                                            }
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                color: 'rgba(27,109,255,1)',
                                                                                cursor: 'pointer',
                                                                                userSelect: 'none',
                                                                                marginLeft: '8px',
                                                                                with: 52,
                                                                            }}
                                                                            onClick={actions.showMore.params(
                                                                                plainOption
                                                                            )}
                                                                        >
                                                                            {
                                                                                locales.components
                                                                                    .pages
                                                                                    .trainingCenter
                                                                                    .fastTraining
                                                                                    .moreInfoBtn
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            similarSentenceRecommendEditInputShow[
                                                                                index
                                                                            ]
                                                                                ? 'flex'
                                                                                : 'none',
                                                                    }}
                                                                    className="fast-training-index-page-body-right-similar-add-bar-similar-sentence-recommend-list-item"
                                                                >
                                                                    <Input
                                                                        ref={actions.setSimilarSentenceRecommendRefs.params(
                                                                            index
                                                                        )}
                                                                        value={plainOption.content}
                                                                        onChange={actions.saveNewSentence.params(
                                                                            'recommend',
                                                                            index
                                                                        )}
                                                                        onBlur={actions.editSentence.params(
                                                                            0,
                                                                            'recommend',
                                                                            index
                                                                        )}
                                                                    ></Input>
                                                                </div>
                                                            </Col>
                                                        </For>
                                                    </Row>
                                                </Checkbox.Group>
                                            </div>
                                            <div
                                                className="fast-training-index-page-body-more-info"
                                                style={{
                                                    display:
                                                        !similarSentencesModalVisible &&
                                                        showMoreVisity
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                            >
                                                <div className="fast-training-index-page-body-more-info-title">
                                                    <div>
                                                        {
                                                            locales.components.pages.trainingCenter
                                                                .fastTraining.moreInfo
                                                        }
                                                    </div>
                                                    <span
                                                        className="udesk-qa-react-web-iconfont fast-training-index-page-body-more-info-cancel"
                                                        onClick={actions.closeShowMore}
                                                    >
                                                        &#xe644;
                                                    </span>
                                                </div>
                                                <div className="fast-training-index-page-body-more-info-content">
                                                    {showMoreList.length !== 0 &&
                                                        showMoreList.map((item) => {
                                                            if (item.channel_id === customer) {
                                                                return (
                                                                    <div className="fast-training-index-page-body-more-info-content-item-customer">
                                                                        <div className="fast-training-index-page-body-more-info-content-item-customer-left">
                                                                            <div className="fast-training-index-page-body-more-info-content-item-customer-left-content">
                                                                                {item.text}
                                                                            </div>
                                                                            <div className="fast-training-index-page-body-more-info-content-item-customer-left-time">
                                                                                {getAudioItemTime(
                                                                                    item.begin_time,
                                                                                    item.end_time
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="fast-training-index-page-body-more-info-content-item-customer-rigth">
                                                                            <span className="udesk-qa-react-web-iconfont">
                                                                                &#xe63b;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div className="fast-training-index-page-body-more-info-content-item-user">
                                                                        <div className="fast-training-index-page-body-more-info-content-item-user-left">
                                                                            <span className="udesk-qa-react-web-iconfont">
                                                                                &#xe6e0;
                                                                            </span>
                                                                        </div>
                                                                        <div className="fast-training-index-page-body-more-info-content-item-user-rigth">
                                                                            <div className="fast-training-index-page-body-more-info-content-item-user-rigth-content">
                                                                                {item.text}
                                                                            </div>
                                                                            <div className="fast-training-index-page-body-more-info-content-item-user-rigth-time">
                                                                                {getAudioItemTime(
                                                                                    item.begin_time,
                                                                                    item.end_time
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fast-training-index-page-body-right-similar-recommend-bar-footer">
                                            <UdeskPagination
                                                current={similarRecommendPageNum}
                                                total={similarRecommendTotal}
                                                pageSize={similarRecommendPageSize}
                                                // hideOnSinglePage={true}
                                                onChange={actions.pageNumChange.params('recommend')}
                                                showTotal={() => null}
                                            />
                                        </div>
                                    </div>
                                </Spin>

                                <div className="fast-training-index-page-body-right-similar-recommend-bar-gpt">
                                    <SimilarQuestionRecommend
                                        content={standardSentence.content}
                                        onSave={actions.similaSentencesRecommendGPTAdd}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function getAudioItemTime(startPoint, endPoint) {
    let start = Udesk.utils.date.formatDurationWithMillisecond(startPoint);
    let end = Udesk.utils.date.formatDurationWithMillisecond(endPoint);
    return `${start} - ${end}`;
}
