import { useState } from 'react';
import { useRequest } from 'ahooks';
import { postIntelligentPartnerHomePageStudentPictureAbility } from 'src/api/intelligentPartner/homePage/student/picture/ability';
import { getTimeEchartOptions } from './utils';

type UseTemplateProps = {
    params?: any;
};

export const useTemplate = (props: UseTemplateProps) => {
    const { params } = props;

    const [echartOptions, setEchartOptions] = useState<any>(null);

    useRequest(
        () =>
            postIntelligentPartnerHomePageStudentPictureAbility({
                ...params,
                startTime: params.startTime?.split?.(' ')?.[0],
                endTime: params.endTime?.split?.(' ')?.[0],
            }),
        {
            ready: !!params?.userId && !!params?.taskId && !!params?.startTime && !!params?.endTime,
            refreshDeps: [params],
            onSuccess: (resp) => {
                const avgScore: number[] = []; // 平均分
                const speechCraftAvgScore: number[] = []; // 话术平均得分
                const emotionAvgScore: number[] = []; // 情绪平均得分
                const speedAvgScore: number[] = []; // 表达平均得分
                const flowAvgScore: number[] = []; // 流程平均得分
                const practiceCostTime: number[] = []; // 累计时长
                const totalPracticeTimes: number[] = []; // 累计次数
                const participateCommitRate: number[] = []; // 参与达标率
                const participateExcellentRate: number[] = []; // 参与达优率

                const xAxis: string[] = []; // X轴数据
                const taskTrend = resp.data ?? [];

                taskTrend.forEach((t) => {
                    xAxis.push(t.time!);

                    avgScore.push(t.avgScore ?? 0);
                    speechCraftAvgScore.push(t.craftAvgScore ?? 0);
                    emotionAvgScore.push(t.emotionAvgScore ?? 0);
                    speedAvgScore.push(t.speedAvgScore ?? 0);
                    flowAvgScore.push(t.flowAvgScore ?? 0);
                    // practiceCostTime.push(t.practiceCostTime ?? 0);
                    // totalPracticeTimes.push(t.totalPracticeTimes ?? 0);
                    // participateCommitRate.push(t.participateCommitRate ?? 0);
                    // participateExcellentRate.push(t.participateExcellentRate ?? 0);
                });

                setEchartOptions(
                    getTimeEchartOptions({
                        avgScore,
                        speechCraftAvgScore,
                        emotionAvgScore,
                        speedAvgScore,
                        flowAvgScore,
                        totalPracticeTimes,
                        practiceCostTime,
                        participateCommitRate,
                        participateExcellentRate,
                        xAxis,
                    })
                );
            },
        }
    );

    return {
        echartOptions,
    };
};
