import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Form, Icon, Input, InputNumber, Space } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import './style.scss';

interface DragComponentProps {
    value?: any[];
    onChange?: (newRuleList: any[]) => void;
    conditions: any[];
    multiBranch: boolean;
    scoreType?: 1 | 2;
}
const grid = 8;

const Wrap = styled.div`
    width: 100%;
    margin-bottom: 8px;
    .udesk-qa-ui-space {
        width: 100%;
        .udesk-qa-ui-space-item {
            &:nth-child(2) {
                flex: 1;
            }
            .udesk-qa-ui-row {
                margin-bottom: 0;
            }
        }
    }
`;
const getListStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});
const getListDropStyle = () => ({
    background: '#fff',
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    minHeight: '32px',
    boxSizing: 'border-box',
    margin: 0,
    padding: '0px 8px',
    color: 'rgba(0, 0, 0, 0.85)',
    fontsize: '14px',
    border: '1px solid #d9d9d9',
    borderRadius: '2px',
    alignItems: 'center',
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 8px',
    minWidth: '32px',
    height: '24px',
    color: 'rgba(0,0,0,0.65)',
    borderRadius: '3px',
    margin: `0 ${grid}px 0 0 `,
    textAlign: 'center',
    lineHeight: '24px',
    // 拖拽的时候背景变化
    background: isDragging ? 'lightgreen' : 'rgba(232,244,255,1)',
    marginBottom: '3px',
    ...draggableStyle,
});

const Item = (props) => {
    const { droppableId } = props;
    const { value: customJudgeLogic = '', logicList, conditionListFilter } = props;
    const [items, setItems] = useState<any>([]);

    useEffect(() => {
        let splitedItems = splitStringToArray(customJudgeLogic);

        let newItems = splitedItems.map((item) => {
            let logicIndex = logicList.findIndex((l) => l.value === item);
            if (logicIndex >= 0) {
                let logic = logicList[logicIndex];
                return { ...logic, outputId: 'output-' + logic.value + '-' + Math.random() };
            } else {
                let itemObj = conditionListFilter.find((i) => i.idCode === item);

                if (!itemObj) {
                    return null;
                }
                return { ...itemObj, outputId: 'output-' + itemObj.idCode + '-' + Math.random() };
            }
        });
        newItems = newItems.filter(Boolean);

        setItems(newItems);
    }, [customJudgeLogic, conditionListFilter]);
    return (
        <Droppable droppableId={droppableId} direction="horizontal">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListDropStyle()}
                >
                    {items.map((item, index) => {
                        // let isDeleted =
                        //     item.prevClass === 'conditionListFilter' &&
                        //     !conditionListFilter.some((condition) => condition.id === item.prevId);
                        // let isExtraDeleted = extraDeleted(item);
                        return (
                            <Draggable
                                key={item.outputId}
                                draggableId={item.outputId}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                        // className={
                                        //     isDeleted || isExtraDeleted ? 'deleted-item' : ''
                                        // }
                                    >
                                        {item.idCode || item.content}
                                    </div>
                                )}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export const RuleList: React.FC<DragComponentProps> = (props) => {
    const locales = UdeskLocales['current'];
    const logicList = [
        {
            inputId: 'logicList-1',
            content: locales.components.common.customFilter.and,
            value: 'and',
            flag: true,
        },
        {
            inputId: 'logicList-2',
            content: locales.components.common.customFilter.or,
            value: 'or',
            flag: true,
        },
        {
            inputId: 'logicList-3',
            content: locales.components.common.customFilter.not,
            value: 'not',
            flag: true,
        },
        {
            inputId: 'logicList-4',
            content: '(',
            value: '(',
            flag: true,
        },
        {
            inputId: 'logicList-5',
            content: ')',
            value: ')',
            flag: true,
        },
        {
            inputId: 'logicList-6',
            content: locales.components.common.customFilter.and,
            value: '&&',
            flag: false,
        },
        {
            inputId: 'logicList-7',
            content: locales.components.common.customFilter.or,
            value: '||',
            flag: false,
        },
        {
            inputId: 'logicList-8',
            content: locales.components.common.customFilter.not,
            value: '!',
            flag: false,
        },
    ];
    const { value: ruleList = [], onChange, multiBranch, scoreType } = props;

    const [tarskDel, setTarskDel] = useState(false);
    const [conditionListFilter, setConditionListFilter] = useState<any>([]);

    const onDragEnd = (result) => {
        let itemType = result.draggableId.split('-')[0];
        let dragingItem, dragingItemStr;
        //这个地方不判断拖拽的项是结果的情况，放到下面判断
        //拖拽的是条件
        if (itemType === 'input') {
            dragingItem = conditionListFilter.find((c) => c.inputId === result.draggableId);
            if (!dragingItem) return;
            dragingItemStr = conditionListFilter
                .find((l) => l.inputId === result.draggableId)
                ?.inputId?.replace('input-', '');
        }
        //拖拽的是运算符
        else if (itemType === 'logicList') {
            dragingItem = logicList.find((l) => l.inputId === result.draggableId);
            if (!dragingItem) return;
            dragingItemStr = dragingItem.value;
        }

        //没拽入结果的情况
        if (!result.destination) {
            if (result.draggableId.indexOf('output') !== -1) {
                const index = parseInt(result.source.droppableId.replace('configuration', ''), 10);
                const rule = ruleList[index];
                let newCustomJudgeLogicArr = splitStringToArray(rule.formula);
                newCustomJudgeLogicArr.splice(result.source.index, 1);
                rule.formula = newCustomJudgeLogicArr.join(' ');
                onChange?.([...ruleList]);
                setTarskDel(false);
            }
            return;
        }
        const index = parseInt(result.destination.droppableId.replace('configuration', ''), 10);
        const rule = ruleList[index];
        if (!rule) {
            return;
        }
        //在结果中互换情况
        if (result.draggableId.indexOf('output') !== -1) {
            let newCustomJudgeLogicArr = splitStringToArray(rule.formula);
            const [movedStr] = newCustomJudgeLogicArr.splice(result.source.index, 1);
            newCustomJudgeLogicArr.splice(result.destination.index, 0, movedStr);
            rule.formula = newCustomJudgeLogicArr.join(' ');
            onChange?.([...ruleList]);
            setTarskDel(false);
        }
        //拽进结果的情况
        else if (
            result.draggableId.indexOf('input') !== -1 ||
            result.draggableId.indexOf('logicList') !== -1
        ) {
            let newCustomJudgeLogicArr = splitStringToArray(rule.formula);
            newCustomJudgeLogicArr.splice(result.destination.index, 0, dragingItemStr);
            rule.formula = newCustomJudgeLogicArr.join(' ');
            onChange?.([...ruleList]);
            setTarskDel(false);
        }
    };
    const onDragStart = (option) => {
        if (option.draggableId.indexOf('output') !== -1) {
            setTarskDel((prev) => {
                return true;
            });
        }
    };
    // const extraDeleted = (target) => {
    //     if (!target.prevId) {
    //         let res: any[] = [];
    //         conditionListFilter.forEach((item) => {
    //             if (target.inputId.indexOf('logicList') !== -1) {
    //                 // res.push(target.content.includes(item.content));
    //                 res.push(true);
    //             } else {
    //                 res.push(target.inputId === item.inputId);
    //             }
    //         });
    //         return !res.some((e) => e);
    //     }
    //     return false;
    // };
    useEffect(() => {
        let newConditionListFilter =
            props.conditions?.map((c) => ({
                ...c,
                content: c.label,
                inputId: `input-${c.idCode}`,
            })) ?? [];
        setConditionListFilter(newConditionListFilter);
    }, [props.conditions]);

    return (
        <Form.List name="ruleList">
            {(fields, { remove, add, move }) => {
                return (
                    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                        <div className="data-preprocess-custom">
                            <div className="data-preprocess-custom-drap-title">
                                {locales.components.common.customFilter.condition}：
                            </div>
                            <div className="data-preprocess-cutom-drap-info">
                                <Droppable
                                    droppableId="droppables"
                                    direction="horizontal"
                                    isDropDisabled={true}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle()}
                                        >
                                            {conditionListFilter.map((item, index) => (
                                                <Draggable
                                                    key={item.inputId}
                                                    draggableId={item.inputId}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            {item.idCode}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                        <div className="data-preprocess-custom">
                            <div className="data-preprocess-custom-drap-title">
                                {locales.components.common.customFilter.condition}：
                            </div>
                            <div className="data-preprocess-cutom-drap-info">
                                <Droppable
                                    droppableId="droppabless"
                                    direction="horizontal"
                                    isDropDisabled={true}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle()}
                                        >
                                            {logicList
                                                .filter((item) => item.flag)
                                                .map((item, index) => (
                                                    <Draggable
                                                        key={item.inputId}
                                                        draggableId={item.inputId}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                {item.content}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                        <div className="data-preprocess-custom">
                            {tarskDel && (
                                <div className="index-manmagement-custom-drag-tarsk">
                                    {locales.components.common.customFilter.infoDel}
                                </div>
                            )}
                            <div className="data-preprocess-custom-drap-title">
                                {multiBranch
                                    ? /* 分支 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.ruleList.branch
                                    : locales.components.common.customFilter.toConfigure}
                                ：
                            </div>
                            <div className="data-preprocess-cutom-drap-info">
                                {fields.map((field, index) => (
                                    <Wrap>
                                        <Space>
                                            <div>{multiBranch ? `${index + 1}:` : null}</div>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'formula']}
                                                fieldKey={[field.fieldKey, 'formula']}
                                            >
                                                <Item
                                                    droppableId={`configuration${index}`}
                                                    logicList={logicList}
                                                    conditionListFilter={conditionListFilter}
                                                />
                                            </Form.Item>
                                            {!!scoreType && (
                                                <Space>
                                                    <div>
                                                        {Udesk.enums.calculateScoreTypes.getName(
                                                            scoreType
                                                        )}
                                                    </div>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'score']}
                                                        fieldKey={[field.fieldKey, 'score']}
                                                    >
                                                        <InputNumber min={0} />
                                                    </Form.Item>
                                                </Space>
                                            )}
                                            {multiBranch && (
                                                <React.Fragment>
                                                    <div>{/* 分支名称: */}{UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.ruleList.branchName}</div>
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'name']}
                                                        fieldKey={[field.fieldKey, 'name']}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </React.Fragment>
                                            )}
                                            {multiBranch && (
                                                <Button
                                                    onClick={() => {
                                                        remove(index);
                                                    }}
                                                    type="text"
                                                    size="small"
                                                    style={{ color: 'red' }}
                                                    icon={
                                                        <Icon
                                                            type="MinusCircleOutlined"
                                                            antdIcon={true}
                                                        />
                                                    }
                                                ></Button>
                                            )}
                                        </Space>
                                    </Wrap>
                                ))}
                                {multiBranch && (
                                    <div>
                                        <Button
                                            onClick={() => add({ formula: '' })}
                                            type="link"
                                            size="small"
                                        >{/* 增加规则 */}{UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.ruleList.addRules}</Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </DragDropContext>
                );
            }}
        </Form.List>
    );
};

function splitStringToArray(string) {
    return (
        string
            ?.split(/(\s|or|and|not|&&|\|\||!|\(|\))/)
            .filter(Boolean)
            .map((i) => i.trim()) ?? []
    ).filter(Boolean);
}
