import React, { FC } from 'react';
import styled from 'styled-components';

const PptPreviewWrap = styled.div`
    padding: 8px;
    background: #fafafa;
    width: 140px;
    img {
        width: 100%;
    }
`;

type PPTImageProps = {
    value?: string;
};

export const PptPreview: FC<PPTImageProps> = (props) => {
    return (
        <PptPreviewWrap>
            <img src={props.value} alt="" style={{ height: 73, objectFit: 'contain' }} />
        </PptPreviewWrap>
    );
};

export default PptPreview;
