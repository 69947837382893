import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { ConfigProvider, Icon, message } from 'udesk-ui';
import { APPEAL_LOCALES_MAP } from 'src/common/locales';

import DomSdkBaseClass from 'udesk-react/src/udesk/sdk/react-dom-sdk-base-class';
import ReactSentry from '../../../../lib/react-sentry';
import $ from 'jquery';
import moment from 'moment';
import '../../../../common/moment';

import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
// import UdeskQaWebRoutesComponent from './routes';
import SdkComponent from '../sdk/index';
// import routesGlobal from './routes-global';
import CustomNotifyPluginClass from '../../../../common/ui/custom-notify.js';

import initializers from '../../../../initializers';
import 'udesk-react/src/udesk-plugin-installers/utils/web/safe-html';
import 'udesk-react/src/udesk-plugin-installers/ui/notify/iziToast';
import '../../../../styles/index.scss';
import '../../../../styles/qa-form.css';
// import '@alifd/next/lib/form/index.scss';
// import '@alifd/next/lib/input/index.scss';
// import '@alifd/next/lib/select/index.scss';

import './sdk-style.scss';
// import './udesk-qastar-ember.css';
!window.$ && (window.$ = $);

// import './vendor/index';

if (process.env.NODE_ENV === 'development') {
    window._UDQ = Udesk;
    window._UDL = Locales;
}

message.config({ prefixCls: 'udesk-qa-ui-message' });

const ENABLE_SENTRY = process.env.REACT_APP_ENABLE_SENTRY === 'true';
let SENTRY_INITIALIZED = false;

// let CURRENT_VERSION = null;

let customNotifyPluginInstance = new CustomNotifyPluginClass();
// if (process.env.NODE_ENV !== 'development') {
//     Udesk.ui.notify.plugins = {
//         udeskQAWeb: customNotifyPluginInstance
//     };
//     Udesk.ui.notify.setPlugin('udeskQAWeb');
// }

Udesk.init({});
if (ENABLE_SENTRY) {
    Udesk.on('onModelPromiseCatched', (error) => {
        ReactSentry.captureException(error);
    });
}
if (ENABLE_SENTRY) {
    Udesk.on('onModelPromiseCatched', (error) => {
        ReactSentry.captureException(error);
    });
}
initializers.run();

class UdeskQaSdkClass extends DomSdkBaseClass {
    constructor(options) {
        if (options.methods != null && options.methods.reloadCallback != null) {
            options.reloadCallback = options.methods.reloadCallback;
            delete options.methods.reloadCallback;
        }
        super(options);
        // this.rootRouterInstance = React.createRef();
        this.udeskCrmComponentElement = null;
        // Locales.setLanguage(options.props.language);
        Locales.setLanguage('ZH-CN'); // 目前仅支持中文
        this.context = Udesk.react.context.defaults.clone();
        // this.context.props.language = this.options.props.language;
        this.context.props.language = 'ZH-CN'; // 目前仅支持中文
        moment.locale('zh-cn'); // 目前仅支持中文
        if (this.options.props) {
            if (!this.options.props.domain) {
                this.options.props.domain = window.location.origin;
            }

            if (
                this.options.props.appealCheckStatus &&
                this.options.props.readStatus === undefined &&
                this.options.props.status === undefined &&
                this.options.props.isManual === undefined
            ) {
                // 0:全部状态、1:未读、2:已复核、4:申诉中、5:已读、6:已抽检
                switch (this.options.props.appealCheckStatus) {
                    case 1: {
                        this.options.props.readStatus = Udesk.enums.readStatus.no.id;
                        break;
                    }
                    case 5: {
                        this.options.props.readStatus = Udesk.enums.readStatus.yes.id;
                        break;
                    }
                    case 2: {
                        this.options.props.status = Udesk.enums.appealCheckStatus.reviewed.id;
                        break;
                    }
                    case 4: {
                        this.options.props.status = Udesk.enums.appealCheckStatus.inComplaint.id;
                        break;
                    }
                    case 6: {
                        this.options.props.isManual = Udesk.enums.isManual.yes.id;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            if (this.options.props.containerHeight) {
                this.containerHeight = this.options.props.containerHeight;
            }
        }
        this.context.props.sdkOptions = this.options;

        // if(!this.options.props && this.options.methods){
        //     if(this.options.methods.getToken && typeof(this.options.methods.getToken) === 'function'){
        //         let token = async function (){
        //             return await this.options.methods.getToken();
        //         };
        //         this.context.props.sdkOptions.props = token();
        //     }else{
        //         throw new Error('getToken is not found or it is not a function');
        //     }
        // }

        // //判断sdk，走api
        if (!this.context.props.sdkOptions.props) {
            this.context.props.sdkOptions.props = {};
        }
        this.context.props.sdkOptions.props.isProd = true;
        this.context.props.sdkOptions.props.isAppealCheck = true;
        this.context.props.sdkOptions.props.pathPrefix = 'static';
        // this.context.props.sdkOptions.props.language = 'ZH-CN';

        this.context.options.localesManager = Locales;
        customNotifyPluginInstance.updateDefaults(this.context.props.sdkOptions.methods);
        Icon.createFromIconfontCN({
            // scriptUrl: '//at.alicdn.com/t/font_1142533_gwyidv9e80h.js',  // 测试时可以使用线上路径，最终需要下载到本地
            scriptUrl: this.context.props.sdkOptions.props.domain + '/static/iconfont/iconfont.js', // icon图标存储位置: public/static/iconfont
            prefixCls: 'icon-udesk-qa-react-web',
        });

        if (!SENTRY_INITIALIZED && DomSdkBaseClass.enableSentry && ENABLE_SENTRY) {
            SENTRY_INITIALIZED = true;
            ReactSentry.init({
                getUser: function () {
                    let getUserForSentry = DomSdkBaseClass.getUserForSentry;
                    if (getUserForSentry !== null && getUserForSentry === '') {
                        return getUserForSentry();
                    }
                    return {};
                },
                getTagsContext: () => {
                    let getTagsContextForSentry = DomSdkBaseClass.getTagsContextForSentry;
                    if (getTagsContextForSentry !== null) {
                        return getTagsContextForSentry();
                    }
                    return {};
                },
                // getShouldSendCallback: () => {
                //     let pathname = window.location.pathname;
                //     for (let router of routesGlobal) {
                //         if (pathname.indexOf(router.path) !== -1) {
                //             return true;
                //         }
                //     }
                //     return false;
                // }
            });
        }
    }

    context = null;
    containerHeight = '100%';

    static get sdkName() {
        return 'Udesk-QA';
    }

    getVerssionPath() {
        return `${this.options.props.releaseVersionUrl}`;
    }
    // getHistory() {
    //     let rootRouterInstance = this.rootRouterInstance.current;
    //     return rootRouterInstance.getHistory();
    // }
    renderTo(sdkEvents, container) {
        this.isDestroyed = false;
        let udeskQaComponentElement = (this.udeskCrmComponentElement =
            document.createElement('div'));
        if (container == null) {
            container = document.body;
        }
        this.udeskQaComponentElement = udeskQaComponentElement;
        this.udeskQaComponentElement.setAttribute('id', 'udeskQaSdkInstance');
        this.udeskQaComponentElement.style.cssText = `width:100%;height:${this.containerHeight};`;
        container.appendChild(udeskQaComponentElement);

        if (ENABLE_SENTRY) {
            ReactSentry.ready();
        }

        let udeskUiLocale = APPEAL_LOCALES_MAP['ZH-CN'].antd;
        const langCode = this.context.props.sdkOptions.props?.language?.toUpperCase() || 'ZH-CN';
        if (Object.prototype.hasOwnProperty.call(APPEAL_LOCALES_MAP, langCode)) {
            udeskQaComponentElement.setAttribute('lang', langCode);
            Locales.setLanguage(APPEAL_LOCALES_MAP[langCode].language);
            this.context.props.language = APPEAL_LOCALES_MAP[langCode].language;
            udeskUiLocale = APPEAL_LOCALES_MAP[langCode].antd;
            moment.locale(APPEAL_LOCALES_MAP[langCode].moment);
        }

        render(
            <ConfigProvider locale={udeskUiLocale} prefixCls="udesk-qa-ui">
                <div className="udesk-qa-web-route-entry">
                    <div className="quality-checking-task">
                        <Udesk.react.context.UdeskProvider value={this.context}>
                            <SdkComponent
                                // udeskContext={this.context}
                                sdkEvents={sdkEvents}
                                sdkOptions={this.context.props.sdkOptions}
                                // ref={this.rootRouterInstance}
                            />
                        </Udesk.react.context.UdeskProvider>
                    </div>
                </div>
            </ConfigProvider>,
            this.udeskQaComponentElement
        );
    }
    destroy() {
        if (this.isDestroyed) {
            return;
        }
        unmountComponentAtNode(this.udeskCrmComponentElement);
        this.udeskCrmComponentElement = null;
        this.isDestroyed = true;
        super.destroy();
        Udesk.business.sdkAjax.resetToken();
    }
}

UdeskQaSdkClass.initGlobalNameSpace(UdeskQaSdkClass, {
    defaultNamespace: 'UdeskQa',
});
UdeskQaSdkClass.enableVersionmOnitoring = false;

export default UdeskQaSdkClass;
