export default {
  labels: {
    clear: "Kosong",
    refresh: "Segarkan semula",
    nextStep: "Langkah seterusnya",
    prevStep: "Langkah sebelumnya",
    closeDriver: "Keluar dari panduan",
    complete: "Selesai",
    viewStatistics: "Lihat statistik",
    colon: "：",
    splitter: "/",
    remark: "Catatan",
    correction: "Pembetulan ralat",
    save: "Simpan",
    reset: "Tetapkan semula",
    increase: "Tambah",
    preview: "Pratonton",
    unit: "Artikel",
    cancel: "Batalkan",
    all: "Semua",
    searchPlaceHolder: "Masukkan pertanyaan kata kunci",
    greaterThan: "Lebih besar daripada",
    lessThan: "Kurang daripada",
    second: "Kedua",
    description: "Penerangan",
    column: "Lajur",
    pleaseChoice: "Sila pilih",
    filter: "Penapis",
    saveAndSubmit: "Simpan dan hantar",
    submit: "Hantar",
    appeal: "Rayuan",
    review: "Kaji semula",
    choose: "Pilihan",
    manage: "Pengurusan",
    delete: "Padamkan",
    add: "Tambah",
    create: "Baru",
    edit: "Edit",
    name: "Nama",
    describe: "Penerangan",
    next: "Langkah seterusnya",
    percent: "%",
    export: "Eksport",
    exportAll: "Eksport semua",
    confirm: "OK",
    back: "Kembali",
    versionUpdate:
      "Saya dengan senang hati memberitahu anda bahawa laman web kami baru sahaja mengeluarkan versi baru! Untuk memastikan penggunaan normal anda, kami memerlukan anda untuk menyimpan kerja semasa dengan segera, dan kemudian memuat semula halaman (klik butang muat semula penyemak imbas, atau tekan butang F5) untuk menjadikan versi baru kami berkesan. Terima kasih banyak atas kerjasama anda!",
    more: "Lagi",
    selected: "Dipilih",
    deduct: "Kancing",
    maxScore: "Paling banyak",
    scoreUnit: "Mata",
    pointUnit: "Seorang",
    comma: "，",
    view: "Lihat",
    batchingImport: "Import",
    deleteContent:
      "Adakah anda mengesahkan bahawa anda mahu memadam? Tidak dapat dipulihkan selepas penghapusan",
    import: "Import",
    uploadFile: "Muat naik fail",
    downloadFile: "Eksport fail",
    upload: "Muat naik",
    click: "Klik",
    downloadTemplate: "Muat turun templat",
    fillInTheForm: "Isi mengikut format",
    UploadExcelfile: "Muat naik Excel untuk menyelesaikan import kumpulan",
    uploadIdentical:
      "Sekiranya nama API ada, data yang ada dalam sistem akan dikemas kini semasa mengimport",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Terpakai",
    leftBracket: "(",
    rightBracket: ")",
    question: "Soalan",
    answer: "Jawapan",
    content: "Kandungan",
    search: "Cari",
    query: "Pertanyaan",
    cancelEdit: "Batalkan suntingan",
    noValue: "Tiada data",
    selectAll: "Semua pilihan",
    changeAGroup: "Tukar kumpulan",
    arrow: " ---> ",
    saveReview: "Hantar semakan",
    saveSpotCheck: "Penyerahan pensampelan",
    rapidSampling: "Pensampelan pantas",
    file: "Arkib",
    download: "Muat turun",
    lastPage: "Sebelumnya",
    nextPage: "Seterusnya",
    batchActions: "Operasi kumpulan",
    cancelDistribution: "Membatalkan pengedaran",
    againDistribution: "Pengagihan semula",
    deleteRecord: "Padamkan rekod",
    oneOptionOpertor: "Pemeriksa kualiti pilihan",
    allOptionOpertor: "Semua pemeriksa kualiti",
    distributionInfoFirstOne: "Sahkan untuk betul",
    distributionInfoFirstTwo:
      "Adakah rekod diperuntukkan semula? Sila pilih sasaran yang ditetapkan",
    distributionInfoSecondOne: "Sahkan untuk membatalkan",
    distributionInfoSecondTwo:
      "Adakah maklumat penugasan yang direkodkan? Selepas pembatalan, ia akan menjadi rekod semakan yang tidak diperuntukkan.",
    distributionInfoSecondThree:
      "Adakah maklumat penugasan yang direkodkan? Setelah pembatalan, ia akan menjadi rekod persampelan yang tidak diperuntukkan.",
    distributionInfoThirdOne: "Dalam rekod yang dipilih",
    distributionInfoThirdTwo: "Rekod yang telah disemak tidak akan diproses.",
    distributionInfoThirdThree:
      "Rekod yang telah diambil sampelnya, dan rekod yang diambil sampelnya tidak akan diproses.",
    exportError: "Eksport gagal",
    exportSuccess: "Eksport berjaya",
    deleteRecordInfo:
      "Adakah anda mengesahkan bahawa anda ingin menghapus rekod {0}? Data tidak dapat dipulihkan setelah dipadam",
    pleaseSetFilter: "Sila pilih penapis",
    noCountAfterFilter:
      "Tidak ada data eksport, sila tetapkan semula syarat penapis",
    redo: "Pelaksanaan semula",
    batchMove: "Bergerak secara pukal",
    websiteName: "Pemeriksaan kualiti pintar",
    salesEnable: "Pemberdayaan jualan",
    systemName: "Pemeriksaan kualiti pintar",
    initSystemError:
      "Pengecualian rangkaian atau kegagalan inisialisasi halaman, sila hubungi khidmat pelanggan",
    day: "Hari",
    hour: "Jam",
    minute: "Mata",
    questionMark: "？",
    personalSettings: "Tetapan peribadi",
    logout: "Log keluar",
    ok: "OK",
    saveAs: "Simpan sebagai",
    pleaseEnter: "Sila masukkan",
    executeMessage: {
      executeSuccess: "Berjaya melaksanakan artikel {0}",
      executeError: "{0} Artikel gagal dilaksanakan",
    },
    field: "Bidang",
    operator: "Pengendali",
    value: "Nilai",
    placeholder: "Sila pilih",
    pleaseSelect: "Sila pilih",
    totalScore: "Jumlah markah",
    hyphen: "-",
    semicolon: "；",
    wfm: "Jadual pintar",
    user: "Pengguna",
    none: "Tiada",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Simpan berjaya! Berlaku setelah pekerja yang berkaitan memuat semula halaman",
    saveAsName: "{0}(salinan)",
    systemInformation: "Lihat profil sistem",
    billingCenter: "Pusat bil",
    warningMessage:
      "Kuota selebihnya tidak mencukupi, sila isi semula secepat mungkin",
    fileTypeHolder: "Format fail salah, sila muat naik fail format {0}",
    dataNo: "Tiada data",
    move: "Mudah alih",
    study:
      "Anda mempunyai titik pemeriksaan kualiti pembelajaran kendiri yang tidak bertanda",
    studySuccess: "Berjaya ditandakan",
    studyError: "Gagal menandakan",
    editType: "Edit tag kategori",
    copyed: "Telah disalin ke papan keratan",
    clickToCopy: "Klik untuk menyalin",
    addFilterConditions: "Tambah penapis",
    filterConditions: "Tapis keadaan",
    messageTime: "Masa mesej",
    messageType: "Jenis Mesej",
    pushType: "Saluran mesej",
    messageContent: "Kandungan mesej",
    messageName: "Nama mesej",
    messageStatus: "Status mesej",
    configuration: "Maklumat Konfigurasi",
    sendTarget: "Hantar sasaran",
    guideUrl: "URL but",
    noticeDetail: "Maklumat mesej",
    action: "Operasi",
    seeDetail: "Lihat butiran",
    theDay: "{0} hari",
    theLastDayOfMonth: "Hari terakhir setiap bulan",
    ignore: "Abaikan",
    seeNow: "Lihat sekarang",
    exportConfirmText: {
      export: "Sebanyak {0} data, sahkan eksport?",
      exportAll: "Sahkan untuk mengeksport semua kandungan?",
    },
    samrTagAdd: "Tambah label",
    deletePopconfirmContent:
      "Adakah anda mengesahkan bahawa anda mahu memadam?",
    TheLastThirtyDays: "30 hari terakhir",
    addNumber: "Tambah",
    subNumber: "Tolak",
    addStrategy: "Tambah Strategi",
    minutes: "Minit",
    status: "Aktifkan status",
    pushConfig: "Tolak konfigurasi",
    category: "Pengelasan",
    and: "Dengan",
    interval: "Selang",
    switchOn: "Buka",
    switchOff: "Tutup",
    batchDel: "Padam kumpulan",
    exportTxt: "Eksport teks TXT",
    playSpeed: "Kelajuan berganda",
    messageLogBtn: "Lihat komunikasi/perbualan yang mencetuskan pemberitahuan",
    dataLink: "Pautan data",
    yes: "Ya",
    no: "Tidak",
    sentenceUnit: "Ayat",
    appealDetail: "Perincian rekod rayuan",
    detail: "Butiran",
    default: "Lalai",
    customer: "Pelanggan",
    agent: "Perkhidmatan Pelanggan",
    sort: "Susun",
    applyNow: "Permohonan",
    index: "Nombor siri",
    allColumns: "Lajur yang boleh ditambah",
    selectedColumns: "Lajur ditambah",
    syn: "Penyegerakan",
    saveAndNext: "Simpan dan buat yang seterusnya",
    viewReference: "Lihat petikan",
    referenceDetail: "Perincian petikan",
    goToQaList: "Pergi ke pemeriksaan kualiti",
    goToCheck: "Pergi ke persampelan",
    businessCard: "Kad perniagaan",
    weapp: "Program kecil",
    redpacket: "Sampul merah",
    externalRedpacket: "Sampul merah yang boleh ditukar",
    sphfeed: "Nombor video",
    exportTaskCreated: "Misi eksport berjaya dibuat",
    plzGoTo: "Sila lompat ke",
    importTaskCreated: "Misi import berjaya dibuat",
    taskCreated: "Penciptaan misi yang berjaya",
    reply: "Balas",
    replied: "Dibalas",
    release: "Pelepasan",
    expandMore: "Kembangkan balasan lain {0}",
    comment: "Komen",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Sila mempunyai sekurang-kurangnya satu {0}",
      canNotBeEmptyFormat: "{0} Tidak boleh kosong",
      repeatErrorTipFormat: "{0} Terdapat pendua",
      getInformationFailedFormat:
        "Gagal mendapatkan maklumat {0}, sila periksa sambungan rangkaian atau cuba lagi kemudian",
      deleteSuccessFormat: "{0} Penghapusan berjaya.",
      deleteFailedFormat: "{0} Penghapusan gagal, sila cuba lagi kemudian.",
      saveSuccessFormat: "{0} berjaya disimpan.",
      saveFailedFormat: "{0} Simpan gagal, sila cuba lagi kemudian.",
      fileSuccessFormat: "{0} Arkib berjaya.",
      fileFailedFormat: "{0} Pengarkiban gagal, sila cuba lagi kemudian.",
      exportSuccessFormat: "{0} Eksport berjaya",
      exportFailedFormat: "{0} Eksport gagal, sila cuba lagi kemudian.",
      submitSuccessFormat: "{0} Penyerahan berjaya.",
      submitFailedFormat: "{0} Penyerahan gagal, sila cuba lagi kemudian.",
      confirmSuccessFormat: "{0} Sahkan kejayaan.",
      confirmFailedFormat: "{0} Sahkan kegagalan, sila cuba lagi kemudian.",
      pleaseUploadFile: "Sila muat naik fail terlebih dahulu!",
      importFileSuccess: "Import fail berjaya!",
      importFildFailed: "Import fail gagal!",
      deleteSuccess: "Penghapusan berjaya!",
      deleteFailed: "Penghapusan gagal, sila cuba lagi kemudian!",
      illegalTitle: "Kebenaran tidak mencukupi",
      illegalMessage:
        "Anda tidak mempunyai sebahagian atau kebenaran penuh untuk langkah seterusnya, sila hubungi konfigurasi pentadbir, klik butang OK untuk keluar dari boot",
      illegalMessageCurrent:
        "Anda tidak mempunyai izin sebahagian atau penuh untuk langkah semasa, sila hubungi konfigurasi pentadbir, klik butang OK untuk keluar dari boot",
      skipMessage:
        "Sekiranya anda ingin melangkau langkah ini buat sementara waktu, sila klik Langkau",
      errorMsg500:
        "Permintaan pelayan gagal, sila periksa operasi anda atau muat semula dan cuba lagi",
      errorMsg404: "Ooops... halaman yang anda cari hilang",
      errorMsg400:
        "Permintaan pelayan gagal, sila periksa data anda dan cuba lagi",
      pageOverFlow:
        "Pada masa ini hanya menyokong 10,000 paparan, sila tetapkan kriteria penapis untuk melihat lebihan",
      isHandAddSmartTag: "Adakah label pintar yang ditambahkan secara manual",
      defaultGradeMustAtLast: "Penarafan lalai mesti diletakkan di hujung",
      AutomaticAllocationInspectorsIsNull:
        "Sila masukkan halaman maklumat asas untuk menambah pemeriksa kualiti",
      timePickerTips:
        "Agar tidak mempengaruhi penggunaan biasa anda, 23:00 ~ 2:00 adalah masa penyelenggaraan sistem, dan tugas yang dijadualkan tidak dilakukan.",
      batchDeleteInfo:
        "Berjaya menghapus item {0}, gagal item {1}, sebab kegagalan adalah bahawa data telah digunakan.",
      batchDeleteTips:
        "Periksa sejumlah {0} data untuk mengesahkan penghapusan?",
      correctionSuccess: "Pembetulan ralat yang berjaya",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError: "Tambah sehingga 10 borang pemeriksaan kualiti",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "Jumlah item penunjuk {0} mesti menambah hingga 100",
          banProjectMaxErrorTip: "Item yang dilarang menambah hingga 2000",
          deadlyProjectMaxErrorTip: "Item maut ditambah sehingga 500",
        },
      },
    },
    info: {
      creatSuccess: "Dicipta dengan jayanya",
      creatError: "Gagal mencipta",
      editSuccess: "Pengubahsuaian berjaya",
      editError: "Pengubahsuaian gagal",
      deleteSuccess: "Penghapusan berjaya",
      addSuccess: "Menambah kejayaan",
      deleteError: "Gagal memadam",
      conditionError: "Kesalahan ungkapan logik pengendali",
      customJudgeLogic: "Kesalahan ungkapan logik gabungan keadaan tersuai",
      exportError: "Eksport gagal",
      searchError: "Pemeriksaan gagal",
      loadError: "{0} Gagal memuatkan",
      saveSuccess: "Simpan dengan jayanya",
      saveError: "Simpan gagal",
      fileSuccess: "Arkib yang berjaya",
      fileError: "Arkib gagal",
      cannotBeNull: "{0} Tidak boleh kosong!",
      pleaseSearchAndSelect: "Sila masukkan kata kunci untuk mencari",
      clickAndToggle: "Klik untuk memilih untuk menukar",
      deleteWarning:
        "{0} Penghapusan tidak akan dipulihkan, adakah anda pasti mahu meneruskannya?",
      getOssAuthFailed:
        "Gagal mendapatkan kebenaran OSS, periksa sambungan rangkaian atau cuba lagi kemudian.",
      getInsightAuthFailed:
        "Gagal mendapatkan kebenaran Insight, periksa sambungan rangkaian atau cuba lagi kemudian.",
      pictureUploadFailed: "Muat naik gambar gagal",
      pictureUploadFailedSize:
        "Saiz gambar melebihi had, muat naik gambar gagal",
      wecomTagsSyncInfo: "Kemas kini terakhir:{0}, menyegerakkan {1} maklumat",
      collectSuccess: "Koleksi yang berjaya",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "Gagal mendapatkan data laporan" },
      waveAudio: {
        noWaveMessage:
          "Untuk mengekalkan pengalaman anda, rakaman lebih dari {0} minit tidak menunjukkan bentuk gelombang buat masa ini, anda boleh memainkannya secara langsung",
      },
      customFilter: {
        del: "Padamkan",
        edit: "Edit",
        new: "Penapis tersuai",
        apply: "Permohonan",
        cancel: "Batalkan",
        onlyApply: "Hanya terpakai",
        ohterSave: "Simpan sebagai",
        save: "Simpan",
        customFilter: "Penapis biasa",
        reset: "Tetapkan semula",
        holderKeyWord: "Sila masukkan kata kunci",
        customFilterRule: "Peraturan logik saringan:",
        customFilterName: "Nama penapis:",
        holderCustomFilterName: "Sila masukkan nama penapis",
        manualConfiguration: "Konfigurasi manual",
        holderCustomRule: "Sila masukkan keadaan logik tersuai",
        example: "Contoh",
        dragConfiguration: "Seret konfigurasi",
        condition: "Keadaan",
        operation: "Operasi",
        infoDel: "Kandungan diseret keluar dari kawasan untuk dihapuskan",
        toConfigure: "Konfigurasi",
        holderCustomFilterNameLength: "Panjang maksimum nama penapis ialah 10",
        customFilterNameNone: "Nama penapis tidak boleh kosong",
        and: "Dan",
        or: "Atau",
        not: "Bukan",
        delSuccess: "Penghapusan berjaya",
        saveSuccess: "Simpan dengan jayanya",
        updateSuccess: "Pengubahsuaian berjaya",
        customFilterCondition: "Tapis keadaan",
        conditionRule: "Peraturan keadaan",
        none: "Tiada",
        filterPlaceholder: "Sila masukkan kata kunci",
      },
      labelTabs: {
        addThis: "Tambah ke sini",
        goThis: "Bergerak ke sini",
        saveGo: "Simpan dan latihan",
      },
      datePicker: {
        today: "Hari ini",
        yesterday: "Semalam",
        theDayBeforeYesterday: "Sehari sebelum semalam",
        thisWeek: "Minggu ini",
        lastWeek: "Minggu lepas",
        thisMonth: "Bulan ini",
        lastMonth: "Bulan lepas",
        lastSevenDay: "7 hari terakhir",
        lastThirtyDay: "30 hari terakhir",
        thisQuarter: "Suku ini",
        lastQuarter: "Suku lalu",
        thisYear: "Tahun ini",
        lastYear: "Tahun lepas",
        lastYearFromToday: "Tahun lalu",
      },
      pagination: { page: "Sebanyak {0} item, halaman {1}" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Muatkan mesej yang lebih awal",
          bottom: "Muatkan lebih banyak mesej",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Tugas pemeriksaan kualiti",
        informationCollectTasksManageTitle: "Tugas pengumpulan",
        customerServiceCenterTitle: "Pusat Khidmat Pelanggan",
        informationTitle: "Pusat Data",
        intelligentAnalysisTitle: "Analisis pintar",
        semanticIntelligence: "Kecerdasan Semantik",
        trainingCenterTitle: "Pusat Latihan",
        adminTitle: "Pengurusan sistem",
      },
      qualityCheckingTask: {
        title: "Pemeriksaan kualiti panggilan",
        index: {
          tipOne:
            "Pemeriksaan kualiti panggilan digunakan untuk menilai kualiti kerja pusat panggilan pekerja. Anda boleh menggabungkan perniagaan anda sendiri untuk menetapkan borang pemeriksaan kualiti yang sesuai",
          tipTwo:
            "Peraturan yang sesuai dapat ditetapkan untuk tugas pemeriksaan kualiti, termasuk pemeriksaan, pengambilan sampel, dll. Setelah pemeriksa kualiti menjaringkan gol, perkhidmatan pelanggan dapat memeriksa hasil pemeriksaan kualiti dan mengajukan banding lebih lanjut.",
          addTaskTitle: "Tambah tugas pemeriksaan kualiti",
          deleteTipTitle: "Padamkan tugas pemeriksaan kualiti",
          deleteTipContent:
            "Tugas pemeriksaan kualiti tidak dapat dipulihkan setelah dihapus, adakah anda mengesahkan penghapusan?",
          checkingTask: "Tugas pemeriksaan kualiti",
          checkingTaskList: "Senarai tugas pemeriksaan kualiti",
          formList: "Senarai borang",
          viewHelpDocuments: "Lihat dokumen bantuan",
        },
        manage: {
          taskSetting: {
            title: "Tetapan tugas",
            form: "Borang",
            basicInfo: "Maklumat asas",
            visibleRange: "Julat yang boleh dilihat",
            visibleRangeTip:
              "Kumpulan perkhidmatan pelanggan dan perkhidmatan pelanggan yang dipilih dapat melihat tugas pemeriksaan kualiti, dan dapat digabungkan dengan pengaturan izin untuk memberikan tugas ini sebagai ketua pasukan perkhidmatan pelanggan, layanan pelanggan, pemeriksa kualiti, dll.",
            automaticSampling: "Persampelan automatik",
            automaticSamplingTip:
              "Anda boleh menetapkan peraturan persampelan automatik, sehingga sistem akan menambahkan tugas pemeriksaan kualiti secara berkala sesuai dengan peraturan yang Anda tetapkan, seperti memilih 5% panggilan minggu lalu setiap hari Isnin",
            samplingRate: "Kekerapan pensampelan",
            samplingTime: "Masa pensampelan",
            samplingRange: "Julat persampelan",
            samplingRule: "Peraturan pensampelan",
            fillterCondition: "Tapis keadaan",
            workflow: "Aliran kerja pemeriksaan kualiti",
            appealsTime: "Masa yang dibenarkan untuk membuat rayuan",
            appealsTimeTipOne: "Selepas skor dihantar",
            appealsTimeTipTwo: "Dalam beberapa hari",
            appealsTimeTipThree:
              'Setelah masa yang dibenarkan untuk membuat rayuan, status pemeriksaan kualiti yang direkodkan akan diubah secara automatik menjadi "disahkan"',
            auditTime: "Masa pemprosesan audit",
            auditTimeTipOne: "Selepas memfailkan aduan",
            auditTimeTipTwo: "Dalam beberapa hari",
            auditTimeTipThree:
              'Melebihi masa pemprosesan audit, rekod mengekalkan skor asal, dan status pemeriksaan kualiti diubah secara automatik menjadi "disahkan"',
            noAppealsMode: "Tiada mod rayuan",
            appealsMode: "Benarkan mod rayuan",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Perkhidmatan Pelanggan",
            customerServicesGroup: "Kumpulan Perkhidmatan Pelanggan",
            surveyList: "Senarai ulasan",
            customFields: "Medan tersuai",
            fieldConfig: "Konfigurasi medan",
            from: "Dari",
            owner: "Orang yang bertanggungjawab",
            inspectorAndReviewerAuthority:
              "Pihak berkuasa semakan pemeriksaan kualiti",
            inspector: "Pemeriksa kualiti",
            reviewer: "Pengulas",
            inspectorTip: "Pemeriksa kualiti dapat menilai tugas",
            reviewerTip: "Tugasan boleh dinilai oleh pengulas",
            dataStatisticsAuthority: "Kebenaran statistik data",
          },
          samplingRecords: {
            title: "Rekod persampelan",
            index: {},
            manualSampling: {
              title: "Persampelan",
              fillterCondition: "Tapis keadaan",
              samplingRule: "Peraturan pensampelan",
              agent: "Perkhidmatan Pelanggan",
              type: "Jenis",
              result: "Keputusan",
              evaluate: "Penilaian",
              relayNumber: "Nombor geganti",
              source: "Sumber",
              task: "Misi",
              queueState: "Status beratur",
              callHanger: "Pihak tutup panggilan",
              queueTime: "Beratur memakan masa",
              talkTime: "Tempoh panggilan",
              bellTime: "Panjang loceng",
              recordCountTipOne: "Jumlah",
              recordCountTipTwo: "Rekod",
              customerServices: "Perkhidmatan Pelanggan",
              customerServicesGroup: "Kumpulan Perkhidmatan Pelanggan",
              surveyList: "Senarai ulasan",
              customFields: "Medan tersuai",
              fieldConfig: "Konfigurasi medan",
              defaultFields: "Medan lalai",
            },
          },
          taskList: {
            title: "Senarai tugas",
            index: {
              batchDelete: "Padam kumpulan",
              batchSubmit: "Penyerahan pukal",
              batchConfirm: "Pengesahan pukal",
              itemFields: "Menjaringkan bidang projek",
              categoryFields: "Bidang klasifikasi sekunder",
              forbidsAndDeadlies: "Larangan dan barang maut",
              scoreFieldList: "Senarai medan item penilaian",
              customerName: "Nama pelanggan",
              cellphone: "Telefon",
              email: "Peti mel",
              organization: "Syarikat",
              description: "Penerangan",
              tags: "Label",
              taskUserRole: "Peranan pengguna tugas semasa",
            },
            workBench: {
              title: "Maklumat rekod",
              checkForm: "Borang pemeriksaan kualiti",
              waitEvaluate: "Untuk dinilai",
              alreadyEvaluate: "Telah dinilai",
              waitSubmit: "Untuk dihantar",
              alreadySubmit: "Dihantar",
              waitConfirm: "Untuk disahkan",
              alreadyConfirm: "Disahkan",
              waitReview: "Untuk disemak",
              alreadyReview: "Telah dikaji semula",
              alreadyDone: "Selesai",
              comment: "Catatan penilaian",
              totalScore: "Jumlah markah",
              score: "Mata",
              remark: "Catatan",
              changeRecords: "Tukar rekod",
              subject: "Tema",
              secondLevelScore: "Peringkat Tahap 2",
              deadlyList: "Barang maut",
              forbidList: "Item yang dilarang",
              addToCheckTask: "Tambahkan ke tugas pemeriksaan kualiti",
              submitCheckRemark: "Mengemukakan penilaian pemeriksaan kualiti",
              changeCheckRemark:
                "Penilaian pemeriksaan kualiti yang disemak semula",
              doneCheckRemark: "Selesai penilaian pemeriksaan kualiti",
              submitCheckReview: "Dihantar untuk semakan pemeriksaan kualiti",
              submitCheckAppeal: "Mengemukakan aduan pemeriksaan kualiti",
              saveFlowSuccess: "Penilaian berjaya.",
              saveFlowFailed: "Penilaian gagal, sila cuba lagi kemudian.",
              FormDetail: "Perincian borang",
              callDetail: "Perincian sejarah panggilan",
              sampleCalllogDetail: "Maklumat rekod",
              appealReasonTip: "Sila masukkan alasan rayuan (diperlukan)",
              appeal: "Rayuan",
              appealReason: "Sebab-sebab rayuan",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Borang pemeriksaan kualiti",
        basicInformationTitle: "Maklumat asas",
        indicatorsProjectTitle: "Projek penunjuk",
        specialProjectTitle: "Item khas",
        totalScore: "Jumlah markah",
        indicatorsName: "Nama penunjuk",
        score: "Pecahan",
        singleScore: "Mata tunggal",
        weight: "Berat badan",
        maxDeductScore: "Potongan maksimum",
        add: "Meningkat",
        detail: "Perincian borang",
        list: {
          title: "Borang pemeriksaan kualiti",
          tipOne:
            "Konfigurasi borang pemeriksaan kualiti adalah langkah pertama dalam kerja pemeriksaan kualiti. Anda boleh mengkonfigurasi borang pemeriksaan kualiti yang paling sesuai bersama dengan perniagaan syarikat.",
          tipTwo:
            "Kami menyokong pelbagai bentuk seperti pemarkahan langsung, pemarkahan berat badan, dan sistem pemotongan. Sebaiknya anda menetapkan peraturan sebelum menambahkannya ke sistem.",
        },
        basicInformation: {
          formTemplate: "Templat borang",
          directScore: "Skor langsung",
          weightedScore: "Skor berat",
          deductScore: "Potongan mata",
          directScoreSecondLevel: "Skor Langsung-Tahap 2",
          weightedScoreSecondLevel: "Skor Berat-Tahap 2",
          deductScoreSecondLevel: "Potongan-Tahap 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Item yang dilarang",
          banProjectTip:
            "Ia dapat digunakan untuk memeriksa skor pemeriksaan kualiti, dan dapat dianggap sebagai pelanggaran atau tambahan untuk pernyataan, tanpa mempengaruhi skor sebenarnya.",
          deadlyProject: "Barang maut",
          deadlyProjectTip:
            "Item maut boleh digunakan untuk memeriksa skor pemeriksaan kualiti. Sekiranya item maut diperiksa, tugas pemeriksaan kualiti akan mendapat 0 mata secara langsung",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Pemeriksaan kualiti panggilan",
          entiretyReportTitle: "Laporan keseluruhan",
          serviceGroupReportTitle: "Laporan kumpulan perkhidmatan pelanggan",
          serviceReportTitle: "Laporan Perkhidmatan Pelanggan",
          entiretyReport: {
            pandect: "Gambaran keseluruhan",
            contrast: "Kontras",
            trend: "Trend",
            analyze: "Analisis",
            totalScore: "Jumlah markah",
            banProject: "Item yang dilarang",
            deadlyProject: "Barang maut",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Senarai keputusan pemeriksaan kualiti",
          taskSelectPlaceholder: "Pilih tugas pemeriksaan kualiti",
          agentSelectPlaceholder: "Pilih Perkhidmatan Pelanggan",
          refresh: "Segarkan semula",
          all: "Semua",
          unread: "Belum dibaca",
          inComplaint: "Dalam rayuan",
          reviewed: "Telah dikaji semula",
          totalScore: "Komen umum",
          detail: "Perincian pemeriksaan kualiti",
          audioText: "Rakaman Teks",
          callInfo: "Maklumat panggilan",
          ticketInfo: "Maklumat pesanan kerja",
          wechatInfo: "Maklumat Sesi",
          dialogText: "Teks perbualan",
          dialogInfo: "Maklumat perbualan",
          customer: "Pelanggan",
          agent: "Perkhidmatan Pelanggan",
          none: "Tiada data pemeriksaan kualiti",
          appealSuccess: "Rayuan berjaya!",
          appealFailed: "Rayuan gagal, sila cuba lagi kemudian!",
          pointListIsNull:
            "Titik pemeriksaan kualiti rayuan tidak boleh kosong!",
          pleaseSelectPoint: "Sila pilih titik pemeriksaan kualiti!",
          unsupportedFormat: "Format ini tidak disokong buat sementara waktu",
          shardingInformation: "Berikut adalah pecahan {0}",
          smartTags: "Label pintar",
          businessRecords: "Rekod perniagaan",
          selectedPointsWarning:
            "Sila periksa item pemeriksaan kualiti yang akan diajukan, tentukan ayat yang relevan untuk item aduan, dan kemudian klik Kirim.",
          applyReason: "Sebab rayuan (sehingga 50 patah perkataan)",
          chatMessageTypes: {
            all: "Semua",
            text: "Teks",
            image: "Gambar",
            agree: "Setuju dengan kandungan sesi",
            video: "Video",
            voice: "Suara",
            file: "Fail",
            emotion: "Ungkapan",
            link: "Pautan",
            chatrecord: "Sejarah sembang",
            mixed: "Mesej bercampur",
            default: "Mesej tidak disokong",
            deleteFriend: "Padamkan rakan",
            redpacket: "Sampul merah",
            card: "Kad perniagaan",
            disAgree: "Tidak bersetuju dengan kandungan sesi",
            weapp: "Program kecil",
            externalRedpacket: "Sampul merah yang boleh ditukar",
            meetingVoicevCall: "Mesej panggilan",
            uRL: "Laman web",
            replyTimeout: "Balasan tamat masa",
            sphfeed: "Nombor video",
            chatRecordTitle: "Sejarah sembang",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Jumlah markah",
            actualScore: "Penilaian",
            actualGrade: "Penilaian",
            appeal: "Rayuan",
            remark: "Catatan manual",
            none: "Tiada data terperinci pemeriksaan kualiti",
            pointEdit: "Penyuntingan dan klasifikasi titik pemeriksaan kualiti",
          },
          changeList: {
            title: "Tukar rekod",
            user: "Pengguna",
            point: "Titik pemeriksaan kualiti",
          },
          relatedRecord: {
            title: "Rekod berkaitan",
            qualityList: "Tugas pemeriksaan kualiti",
            none: "Tiada",
            check: "Lihat",
            loadMore: "Memuatkan lebih banyak",
          },
        },
      },
      grade: {
        title: "Penilaian pemeriksaan kualiti",
        subTitle:
          "Peringkat pemeriksaan kualiti digunakan untuk menilai objek pemeriksaan kualiti setelah skor pemeriksaan kualiti selesai. Contohnya, ia dapat dikonfigurasi dengan kurang dari 60 mata untuk tidak memenuhi syarat, 60-85 mata untuk baik, dan lebih tinggi daripada 80 mata untuk sangat baik.",
        list: {
          up: "Gerakkan ke atas",
          down: "Bergerak ke bawah",
          del: "Padamkan",
          case: "Secara automatik menyertai perpustakaan kes",
          orderCase: "Perpustakaan kes lalai",
          info: "Penilaian lalai tidak mempunyai syarat yang sepadan dan tidak dapat dihapus. Sekiranya tidak memenuhi penilaian di atas, penilaian lalai akan diperoleh",
          add: "Tambah penilaian",
          save: "Simpan",
          close: "Batalkan",
          max: "Hingga 20 penilaian pemeriksaan kualiti dapat dikonfigurasi",
          name: "Tidak dinamakan",
          success: "Simpan dengan jayanya",
        },
      },
      qualityInspectionList: {
        title: "Senarai pemeriksaan kualiti",
        fastFilter: "Pelanggaran pemeriksaan kualiti",
        fastFilterTip:
          "Sistem pengurangan mata adalah pelanggaran, dan sistem bonus adalah pelanggaran",
        titleWechat: "Senarai analisis",
        titleCollect: "Senarai koleksi",
        status: "Keadaan analisis",
        reQualityInspectionConfig: "Periksa semula pilihan tugas",
        pleaseSelectRecheckPoints:
          "Sila pilih titik pemeriksaan kualiti yang perlu dikira semula",
        beginReQualityInspection: "Mulakan pelaksanaan",
        cancelReQualityInspection: "Membatalkan misi",
        reQualityInspection: "Pemeriksaan semula",
        reQualityInspectionWechat: "Analisis semula",
        reQualityInspectionUnderWay: "Tugas pemeriksaan semula...",
        reQualityInspectionUnderWayTip:
          "Meninggalkan halaman setelah menutup tetingkap tidak akan mempengaruhi tugas",
        reQualityInspectionDone: "Pemeriksaan semula selesai",
        reQualityInspectionDoneTip:
          "Pemeriksaan semula telah berjaya diselesaikan, sila klik butang sahkan",
        endReQualityInspection: "Menamatkan misi",
        alreadyDoneTip: "Selesai {0}",
        totalTip: "Jumlah {0} item",
        endReInspectionSuccess: "Tugas pemeriksaan semula telah ditamatkan",
        closeWindow: "Tutup tetingkap",
        endReInspectionFailed:
          "Tugas pemeriksaan kualiti semula gagal, sila cuba lagi kemudian!",
        detail: {
          title: "Perincian pemeriksaan kualiti",
          wechatTitle: "Perincian analisis",
          titleCollect: "Kumpulkan butiran",
          callIndex: "Panggilan berkaitan",
          textIndex: "Dialog persatuan",
          fileConfirm: "Sahkan arkib?",
          fileConfirmDesc:
            "Skor pemeriksaan kualiti tidak dapat diedit setelah diarkibkan, sila sahkan sama ada diarkibkan",
        },
        sysCloumnsContal: "Pengurusan bidang lajur sistem",
        personCloumnsContal: "Pengurusan bidang lajur peribadi",
      },
      spotCheckList: {
        title: "Senarai sampel",
        onlyMyTip: "Lihat sahaja sampel saya",
        plInspector: "Sila pilih pemeriksa",
        allInspector: "Lihat semua",
        seInspector: "Lihat pemeriksa yang ditetapkan",
        myInspector: "Lihat sahaja saya",
        noInspector: "Periksa pemeriksa yang tidak ditugaskan",
        detail: { title: "Perincian persampelan" },
      },
      reviewList: {
        title: "Senarai ulasan",
        onlyMyTip: "Lihat sahaja ulasan saya",
        plInspector: "Sila pilih pengulas",
        allInspector: "Lihat semua",
        seInspector: "Lihat pengulas yang dilantik",
        myInspector: "Lihat sahaja saya",
        noInspector: "Lihat pengulas yang tidak diperuntukkan",
        detail: { title: "Semak butiran" },
      },
      caseBaseList: {
        title: "Perpustakaan kes",
        del: "Padamkan",
        delTitle: "Semua kandungan tidak akan disimpan selepas penghapusan",
        cancel: "Batalkan",
        actions: "Operasi",
        seeCaseBase: "Lihat kes",
        seeDetail: "Lihat butiran",
        edit: "Edit",
        save: "Simpan",
        addCaseBase: "Menambah perpustakaan kes",
        export: "Eksport",
        ok: "OK",
        tip: "Data panggilan dan dialog khas dalam senarai pemeriksaan kualiti dapat disimpan di perpustakaan kes untuk memudahkan pembelajaran dan perkongsian",
        detail: {
          choiceCaseBase: "Sila pilih kes",
          delCaseBase: "Padam kes",
          addCaseBase: "Tambah Kes",
          successed: "Simpan dengan jayanya",
          info: "Sila simpan atau batalkan kandungan yang diedit terlebih dahulu",
          caseType: "Sila pilih perpustakaan kes yang ingin anda sertai",
        },
      },
      testSetList: {
        title: "Set ujian pemeriksaan kualiti",
        tip: "Data pemeriksaan rawak pemeriksa kualiti akan disimpan dalam ujian pemeriksaan kualiti, yang memudahkan pengoptimuman syarat pemeriksaan kualiti dan konfigurasi peraturan pemeriksaan kualiti untuk beberapa kesalahan pemeriksaan mesin yang dibandingkan setelah pemeriksaan rawak.",
        refresh: "Segarkan semula",
        reQualityInspectionUnderWay: "Menilai semula tugas...",
        reQualityInspectionUnderWayTip:
          "Meninggalkan halaman setelah menutup tetingkap tidak akan mempengaruhi tugas",
        reQualityInspectionDone: "Penilaian semula selesai",
        reQualityInspectionDoneTip:
          "Penilaian semula telah berjaya diselesaikan, sila klik butang sahkan",
        endReQualityInspection: "Menamatkan misi",
        alreadyDoneTip: "Selesai {0}",
        totalTip: "Jumlah {0} item",
        endReInspectionSuccess: "Tugas penilaian semula telah ditamatkan",
        closeWindow: "Tutup tetingkap",
        endReInspectionFailed:
          "Menilai semula tugas gagal, sila cuba lagi kemudian!",
        recheckPoint:
          "Kira semula skor dan ketepatan titik pemeriksaan kualiti",
        recheckPointTitle: "Menilai semula pilihan",
        delete: {
          reQualityInspectionUnderWay: "Tugas penghapusan kumpulan...",
          reQualityInspectionUnderWayTip:
            "Meninggalkan halaman setelah menutup tetingkap tidak akan mempengaruhi tugas",
          reQualityInspectionDone: "Tugas penghapusan kumpulan selesai",
          reQualityInspectionDoneTip:
            "Penghapusan kumpulan telah berjaya diselesaikan, sila klik butang sahkan",
          endReQualityInspection: "Menamatkan misi",
          alreadyDoneTip: "Selesai {0}",
          totalTip: "Jumlah {0} item",
          endReInspectionSuccess: "Tugas penghapusan kumpulan telah ditamatkan",
          closeWindow: "Tutup tetingkap",
          endReInspectionFailed:
            "Tugas penghapusan kumpulan gagal, sila cuba lagi kemudian!",
        },
        reultList: {
          assessmentTime: "Masa penilaian terakhir",
          assessmentAll: "Jumlah penilaian",
          strip: "Artikel",
          spot: "Titik",
          deviation: "Penyimpangan",
          accuracyRate: "Kadar ketepatan",
          info: "Tiada hasil penilaian, sila menilai",
          onlyDeviationInfo: "Lihat sahaja rekod sisihan",
          againAssessment: "Penilaian semula",
          screen: "Penapis",
          batchDelete: "Penyingkiran pukal",
          matching: "Perlawanan",
          ok: "Ya",
          noCancel: "Tidak",
        },
      },
      smartWordsLibrary: {
        title: "Tesaurus pintar",
        tip1: "Sinonim: digunakan untuk mengurangkan jumlah latihan label semantik, ayat serupa sinonim tidak perlu diulang latihan",
        tip2: "Syarat profesional: pengoptimuman transkripsi dan pengoptimuman pemahaman bahasa semula jadi untuk mengenal pasti nama syarikat yang tidak tepat, nama produk, perbendaharaan kata industri dan kata nama lain yang tepat untuk meningkatkan ketepatan pengiktirafan dan ketepatan analisis",
        tip3: "Kata-kata perhatian: perhatikan kata-kata yang ingin anda perhatikan dalam analisis awan kata, dan bantu titik panas analisis AI sesuai dengan perniagaan semasa",
        tip4: "Kata-kata yang disekat: Sekat kata-kata yang tidak ingin anda perhatikan dalam analisis awan kata, dan bantu AI menganalisis titik panas sesuai dengan perniagaan semasa",
        tip5: "Berat kata: digunakan untuk membetulkan dan mengoptimumkan pengecaman label semantik. Dengan mengubah berat pengaruh kata dalam ayat, mengoptimumkan kesan pengecaman semantik dalam senario tertentu mengikut keperluan",
        tip6: "Data korpus: Masukkan teks perenggan besar untuk senario perniagaan masing-masing, digunakan untuk pembetulan kesalahan transkripsi dan latihan ASR untuk meningkatkan ketepatan transkripsi ASR",
        upload: {
          uploadSynonymWords: "Import sinonim",
          uploadProfessionalWords: "Import istilah profesional",
          uploadAttentionWords: "Import perkataan yang mengikuti",
          uploadIgnoreWords: "Mengimport kata-kata yang diabaikan",
          uploadWordsWeight: "Kata berat import",
          uploadTypo: "Import set perkataan typo",
          uploadCorpusData: "Import data korpus",
          uploadCorpusContent: "Import kandungan data korpus",
          firstUploadStep:
            "1. Muat turun templat dan isi mengikut format templat",
          downloadTemplateMessage: "Muat turun templat:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Klik butang import untuk memuat naik fail EXCEl yang sesuai dengan format templat",
          secondUploadCorpusDataStep:
            "2. Klik butang import untuk memuat naik fail TXT yang sesuai dengan format templat",
          postUploadDataFail: "Gagal menghantar data muat naik",
          pleaseUploadTemplate: "Sila muat naik fail EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "Templat fail hanya boleh dalam format JSON atau xlsx",
          templateNeedTxt:
            "Templat fail data korpus hanya boleh menjadi fail TXT",
          uploadSuccess: "Import berjaya",
          uploadFailed: "Import gagal",
          downloadError: "Muat turun gagal",
          downLoadTxtTitle: "Kandungan kort.txt",
        },
        navTabNames: {
          synonymWords: "Sinonim",
          professionalWords: "Istilah profesional",
          attentionWords: "Ikut perkataan",
          ignoreWords: "Abaikan perkataan",
          wordsWeight: "Berat perkataan",
          corpusData: "Data korpus",
          typoLibrary: "Set perkataan typo",
        },
        synonymWords: {
          createSynonymWords: "Sinonim baru",
          editSynonymWords: "Edit Sinonim",
          addSynonymWords: "Tambah sinonim",
          pleaseEnterStandardWords: "Sila masukkan perkataan standard",
          synonymWordsExists: "Sinonim ini sudah ada dan tidak boleh ditambah",
          synonymWordsCanNotNull: "Sinonim yang ditambah tidak boleh kosong",
          pleaseAddSynonymWords: "Sila tambah sinonim",
          addSuccess: "Menambah kejayaan",
          editSuccess: "Pengubahsuaian berjaya",
          deleteSuccess: "Penghapusan berjaya",
          deleteError: "Gagal memadam",
          required: "*",
        },
        professionalWords: {
          create: "Istilah profesional baru",
          edit: "Penyunting istilah profesional",
          addTargetWords: "Tambah perkataan pengganti",
          targetWordsExists: "Pengganti sudah ada dan tidak dapat ditambahkan",
          targetWordsCanNotNull: "Kata ganti yang ditambah tidak boleh kosong",
          pleaseAddTargetWords: "Sila tambah perkataan pengganti",
          pleaseEnter: "Sila masukkan istilah profesional",
          wordsExists: "Istilah profesional ini sudah ada",
          createSuccess: "Tambah kejayaan",
          editSuccess: "Pengubahsuaian berjaya",
          deleteSuccess: "Penghapusan berjaya",
          deleteError: "Gagal memadam",
          saveError: "Simpan gagal",
          addTargetWordsMaxNumberError: "Boleh menambah hingga 100 kata ganti",
          addHomophonyWordsMaxNumberError:
            "Sehingga 100 perkataan yang disekat dapat ditambahkan",
        },
        attentionWords: {
          create: "Kata-kata perhatian baru",
          edit: "Ikuti penyuntingan perkataan",
          pleaseEnter: "Sila masukkan perkataan yang mengikuti",
          wordsExists: "Kata fokus sudah ada",
          createSuccess: "Tambah kejayaan",
          editSuccess: "Pengubahsuaian berjaya",
          deleteSuccess: "Penghapusan berjaya",
          deleteError: "Gagal memadam",
        },
        ignoreWords: {
          create: "Abaikan perkataan baru",
          edit: "Mengabaikan penyuntingan perkataan",
          pleaseEnter: "Sila masukkan perkataan yang diabaikan",
          wordsExists: "Abaikan sudah ada",
          createSuccess: "Tambah kejayaan",
          editSuccess: "Pengubahsuaian berjaya",
          deleteSuccess: "Penghapusan berjaya",
          deleteError: "Gagal memadam",
        },
        wordsWeight: {
          createWordsWeight: "Kata berat baru",
          editWordsWeight: "Edit perkataan berat",
          addWordsWeight: "Tambah perkataan berat",
          pleaseEnterWeightWords: "Sila masukkan kata berat",
          pleaseEnterWeight: "Sila masukkan berat badan",
          weightNeedInZeroToHundred: "Berat perlu 0 ~ Antara 100",
          synonymWordsExists: "Perkataan berat ini sudah ada",
          weightNeedNumber: "Berat memerlukan format integer",
          standardWeightInformation:
            "Berat rujukan adalah 50, semakin besar berat, semakin kuat pengaruh dalam analisis",
          createSuccess: "Tambah kejayaan",
          editSuccess: "Pengubahsuaian berjaya",
          deleteSuccess: "Penghapusan berjaya",
          deleteError: "Gagal memadam",
        },
        corpusData: {
          createCorpusData: "Data korpus baru",
          editCorpusData: "Edit data korpus",
          addCorpusData: "Tambah data korpus",
          pleaseEnterCorpusDescription: "Sila masukkan keterangan korpus",
          pleaseEnterCorpusContent: "Sila masukkan kandungan korpus",
          synonymWordsExists: "Data korpus sudah ada",
          CorpusContentWordsOverSetting:
            "Jumlah perkataan dalam kandungan melebihi 20,000 perkataan,",
          download: "Muat turun",
          upload: "Muat naik",
          see: "Lihat",
          canNotSave:
            "Sila gunakan fungsi import, jika tidak, ia tidak dapat disimpan",
          createSuccess: "Tambah kejayaan",
          editSuccess: "Pengubahsuaian berjaya",
          deleteSuccess: "Penghapusan berjaya",
          deleteError: "Gagal memadam",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Perkataan yang betul",
            wrongWord: "Perkataan yang salah",
            wrongWordCount: "Bilangan perkataan yang salah",
            wrongWordTip: "Tekan Enter untuk menambah",
            status: "Keberkesanan",
          },
          create: "Set perkataan typo baru",
          edit: "Edit set perkataan typo",
          tip: 'Set perkataan typo digunakan untuk mengoptimumkan kesan "pengesanan typo". Contohnya, jika "pengembalian wang" dihantar secara tidak sengaja ke "retret", anda boleh menetapkan typo biasa dan kata yang betul melalui fungsi ini, dan perhatikan ia akan berlaku pada keesokan harinya setelah menyimpan.',
          onlyChinese: "Hanya menyokong input perkataan Cina",
          wrongWordLengthErr:
            "Panjang setiap perkataan yang salah mestilah 2 ~ 10 perkataan",
        },
      },
      semanticTags: {
        title: "Label semantik",
        current: "Label semantik semasa",
        reference1: "Tugas pemeriksaan kualiti: {0} - {1}",
        reference2: "Tag pintar: {0}",
        reference3: "Pemprosesan data: {0}",
        reference4: "Pangkalan Pengetahuan: {0}",
        index: { import: "Import label semantik" },
        detail: {
          new: "Label semantik baru",
          edit: "Edit tag semantik",
          name: "Nama",
          standardSentences: "Ayat standard",
          effectiveness: "Keberkesanan",
          similarSentences: "Ayat yang serupa",
          tip: "Menyokong penggunaan # # untuk memanggil API fizikal, contoh: Adakah nombor telefon bimbit anda # sys_phone #?",
          excludeSimilarSentences: "Tidak termasuk ayat yang serupa",
          intelligentRecommend: "Cadangan pintar kandungan serupa",
          fastTraining: "Latihan pantas:",
          qualify: "Biasa",
          recallKeyWord: "Ingat kata kunci",
          excludeKeywords: "Tidak termasuk kata kunci",
          excludeKeywordsInfo1:
            "Kata kunci pengecualian, keutamaan biasa daripada semua logik berlaku, jika klik dikecualikan, label semantik tidak akan terkena",
          keyWordInfo1:
            "Setiap baris kata kunci ingat menyokong mengisi beberapa, dipisahkan dengan koma Inggeris, dan logiknya adalah semua hits ketika mengisi beberapa.",
          keyWordInfo2:
            "Kata kunci penarikan balik menyokong mengisi beberapa baris, dan logiknya adalah bahawa ia dapat dibuat ketika mengisi beberapa baris.",
          recallRegular: "Ingat semula biasa",
          excludeRegular: "Tidak termasuk peraturan biasa",
          similaSentences: "Tidak termasuk ayat yang serupa",
          simim: "Kesamaan",
          keyWordInfo3:
            "Tidak termasuk ayat yang serupa mempunyai keutamaan daripada semua logik. Sekiranya anda memukul ayat yang serupa, anda tidak akan memukul label semantik. Kesamaan lalai adalah 80%. Semakin besar kesamaan, semakin serupa.",
          keyWordInfo4:
            "Peraturannya serupa dengan peraturan biasa, permulaan dan akhir wujud secara lalai. Tidak perlu digunakan dengan tanda kurung persegi, seperti [bukan XXXX]",
          keyWordInfo5:
            "Fungsi dalam modul lanjutan biasanya merupakan fungsi eksperimen untuk pakar yang biasa dengan operasi tersebut. Sekiranya anda tidak pasti apa yang harus anda lakukan, disarankan agar anda tidak mengubah bahagian ini.",
          keyWordInfo6:
            "Fungsi pengesanan ayat silang memberikan label semantik kemampuan untuk mengesan ayat silang.",
          keyWordInfo7: "Hingga watak yang sama berterusan",
          keyWordInfo8: "Abaikan peranan yang berbeza",
          keyWordInfo9: "Pengesanan ayat silang",
          keyWordInfo10:
            "Had ayat silang terpanjang tidak lebih panjang daripada",
          keyWordInfo11:
            "Keluarkan peratusan yang serupa tidak kurang daripada",
          keyWordInfo12: "Kesamaan tidak kurang daripada",
          keyWordInfo13:
            "% Kalimat serupa akan dipadankan dengan persamaan (kesamaan lalai adalah 80%)",
          keyWordInfo14:
            "Penyingkiran persamaan akan sesuai dengan persamaan ayat. Sebaiknya hapus ayat yang terlalu serupa untuk meningkatkan kecekapan pengesanan.",
          keyWord: "Kata kunci",
          entity: "Entiti",
          ruleField: "Perkataan konsep",
          customRule: "Reka bentuk peraturan pertuturan",
          fanhua: "Soalan serupa",
          test: "Ujian",
          testOk: "Ujian hit",
          senior: "Tetapan lanjutan",
          seniorList: "Pengesanan ayat silang",
          up: "Aktifkan",
          down: "Tutup",
          ju: "Ayat",
          oneWord: "Perkataan",
          simimNo: "Sama dengan berat badan",
          seniorField: "Ambang kesamaan",
          save: "Simpan",
          cancel: "Batalkan",
          specialElements: "Elemen Khas",
          logicRule: "Peraturan logik",
          every: "Pelbagai perlawanan",
          recallOk: "Kata penarikan balik hit:",
          recallNo: "Kata-kata penarikan balik yang tidak terpukul",
          ruleOk: "Ingat semula biasa",
          ruleNo: "Tidak berjaya",
          rularOk: "Peraturan hit",
          rularNo: "Peraturan yang tidak berjaya",
          infoOk: "Hit ayat standard atau ayat yang serupa:",
          infoNo: "Kalimat standard yang tidak berjaya atau ayat yang serupa",
          fieldOk: "Hit tidak termasuk ayat yang serupa:",
          fieldNo: "Tidak terlepas ayat yang serupa",
          excludeRuleNo: "Tidak terlepas pengecualian biasa",
          excludeRuleOk: "Hit tidak termasuk biasa",
          excludeKeywordNo: "Kata kunci pengecualian yang tidak berjaya",
          excludeKeywordOk: "Kata kunci pengecualian hit",
          update: "Kemas kini berjaya",
          fanhuaList:
            "Dihantar ke halaman umum untuk menandatangani senarai ayat yang serupa",
          wordList:
            "Senarai latihan kosong dan tidak dapat dihantar ke senarai umum",
          start: "Mula",
          end: "Tamat",
          keyWordInfo15:
            "Terdapat bidang yang serupa dalam senarai umum (konfigurasi lanjutan serupa dengan penghapusan semula)",
          saveSuccess: "Simpan dengan jayanya",
          errInfo:
            "Terdapat bidang yang tidak diisi atau tidak diisi dengan betul",
        },
        table: {
          deepTraining: "Latihan mendalam {0} roda",
          fastTraining: "Latihan pantas",
        },
      },
      intelligentCollection: {
        title: "Koleksi pintar",
        classification: "Senarai kumpulan",
        new: "Koleksi baru",
        table: {
          name: "Nama bidang",
          props: "Sifat pelanggan",
          type: "Jenis bidang",
          desc: "Penerangan bidang",
        },
        detail: {
          isCustomerProps: "Simpan kepada sifat pelanggan",
          customerPropsHelper:
            "Setelah disimpan, maklumat yang dikumpulkan akan dikemas kini kepada maklumat pelanggan. Sekiranya tidak disimpan, ia tidak akan dikemas kini ke maklumat pelanggan",
          name: "Nama bidang",
          type: "Jenis bidang",
          text: "Teks",
          desc: "Penerangan bidang",
          descPlaceHolder:
            "Terangkan kegunaan medan untuk mengumpulkan maklumat",
          condition: "Keadaan",
          logic: "Peraturan",
          method: "Kaedah pengumpulan",
          message: "Kandungan maklumat",
          range: "Julat pengekstrakan",
          entity: "Ekstrak entiti",
          base: "Maklumat asas",
          collect: "Pengumpulan maklumat",
        },
      },
      intelligentTags: {
        title: "Label pintar",
        sessionLabelTitle: "Adegan sesi",
        keyEventsTitle: "Peristiwa kritikal",
        customerLabelTitle: "Label pelanggan",
        addClassification: "Buat kategori label baru",
        defaultCategoryName: "Pengelasan lalai",
        index: {
          createTag: "Label baru",
          createEvent: "Acara baru",
          editTagClassification: "Edit klasifikasi label",
          selectTagClassification: "Pilih kategori label",
          editTagGrounp: "Edit kumpulan",
          callTimeError:
            "Tempoh masa panggilan/permulaan perbualan yang dipilih tidak boleh melebihi satu bulan (31 hari)",
          callRecordCountError:
            "Jumlah rekod adalah 0, sila pilih semula waktu panggilan/perbualan",
          selectItmeCountError:
            "Sila pilih label pintar yang perlu melakukan operasi kumpulan",
          redoSuccess:
            "Pelaksanaan semula berjaya, sila periksa di pusat tugas",
          redoError: "Pelaksanaan semula gagal, sila cuba lagi kemudian",
          timeError:
            "Masa pertanyaan tidak boleh melebihi satu bulan (31 hari)",
          categoryName: "Nama kategori",
          categoryColor: "Warna klasifikasi",
          newTagClassification: "Pengelasan label baru",
          newEventClassification: "Pengelasan baru",
          tagClassification: "Pengelasan label",
          eventClassification: "Pengelasan acara",
        },
        detail: {
          new: "Label pintar baru",
          edit: "Edit tag pintar",
          tagName: "Nama",
          tagDesc: "Penerangan",
          category: "Pengelasan",
          tagStatus: "Keberkesanan",
          operator: "Pengendali",
          rule: "Peraturan",
          labelClassAtion: "Jenis label",
          labelClassAtionTip1: "Pelabelan: pelabelan berdasarkan peraturan",
          labelClassAtionTip2:
            "Tag kategori: penandaan label berdasarkan latihan model",
          group: "Kumpulan",
          checkLabel: "Pilih kategori",
          warn: "Amaran awal",
          warnTips:
            "Setelah amaran awal dihidupkan, ketika sesi memenuhi peraturan acara penting ini, personel yang ditunjuk akan diminta, dan peringatan dapat diterima di item yang harus dilakukan",
          warnError: "Sila pilih pegawai amaran yang ditentukan",
        },
        subTitle: {
          common: "Label sesi pintar",
          wecom: "Label Mikro Perusahaan",
        },
      },
      customerPortrait: {
        title: "Potret pelanggan",
        index: { import: "Import potret pelanggan" },
        searchPlaceHolder: "ID pelanggan, nama samaran atau telefon",
        callCount: "Bilangan dialog:",
        callDuration: "Jumlah masa panggilan:",
        dialogCount: "Jumlah perbualan:",
        keyWordsStatistics:
          "Statistik kata kunci (20 teratas & kata-kata berikut)",
        keyWordsCount: "({0})",
        businessTag: "Label perniagaan",
        keyWordsCloudImg: "Kata kunci gambar awan",
        customerEmotionTrend: "Trend perubahan mood pelanggan",
        notFoundCustomer: "Pelanggan yang berkaitan tidak diambil",
        noKeyWords: "Kata kunci yang berkaitan tidak dijumpai",
        noBusinessTags: "Label perniagaan yang berkaitan tidak dijumpai",
        getKeyWordsStatisticsDataError:
          "Pertanyaan statistik kata kunci pelanggan gagal",
        getBusinessTagsStatisticsDataError:
          "Pertanyaan label perniagaan pelanggan gagal",
        personCustome: "Maklumat pelanggan tidak ditanyakan",
        notimeInfo: "Maklumat emosi tidak dijumpai",
        timeList: "Julat tarikh",
        operator: "Label pintar",
        placeHolder: "Sila pilih",
        placeOperator: "Sila pilih label pintar",
        search: "Pertanyaan",
        reset: "Tetapkan semula",
        searchHolder: "Cari",
        call: "Panggilan",
        text: "Dialog",
      },
      sentimentAnalysis: { title: "Penemuan pendapat umum" },
      saleSentimentAnalysis: { title: "Analisis pendapat umum" },
      dataPreprocess: {
        title: "Pra pemprosesan data",
        index: {
          createData: "Data baru",
          selectDataSource: "Pilih Sumber Data",
          selectStatus: "Keberkesanan Pilihan",
          callTimeError:
            "Tempoh masa panggilan/permulaan perbualan yang dipilih tidak boleh melebihi satu bulan (31 hari)",
          callRecordCountError:
            "Jumlah rekod adalah 0, sila pilih semula waktu panggilan/perbualan",
          selectItmeCountError:
            "Sila pilih data yang anda perlukan untuk melakukan operasi kumpulan",
          redoSuccess:
            "Pelaksanaan semula berjaya, sila periksa di pusat tugas",
          redoError: "Pelaksanaan semula gagal, sila cuba lagi kemudian",
        },
        detail: {
          new: "Data baru",
          edit: "Edit data",
          name: "Nama",
          desc: "Penerangan",
          dataSource: "Sumber data",
          status: "Keberkesanan",
          entity: "Ekstrak entiti",
          operator: "Pengendali",
          rule: "Peraturan",
        },
      },
      connectedByConversation: {
        title: "Melalui persatuan dialog",
        list: {
          title: "Melalui fungsi persatuan dialog",
          info: "Fungsi persatuan dialog dapat menghubungkan panggilan dan dialog yang berbeza mengikut peraturan yang telah ditetapkan",
          rule: "Peraturan persatuan dialog",
          source: "Sila pilih sumber data",
          classification: "Sila pilih kategori",
          choose: "Sila pilih",
          input: "Sila masukkan",
          save: "Simpan",
          cancel: "Batalkan",
          fieldwei: "Bit",
          fieldNot: "Terdapat medan yang tidak diisi",
          fiedldlengthNo: "Panjang medan melebihi had",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Beberapa kata kunci boleh ditetapkan, dipisahkan dengan koma",
          applyRole: "Peranan pengesanan",
          preOperator: "Pengendali depan",
          applyOperatorScope: "Julat pengesanan",
          addOperator: "Tambah operator",
          noPreOperator: "Tiada",
          from: "No.",
          to: "Ayat ke",
          end: "Ayat",
          testRegular: "Uji ungkapan biasa",
          test: "Ujian",
          matchSuccess: "Perlawanan berjaya",
          matchError: "Perlawanan gagal",
          querySementicTags: "Pilih label semantik",
          reQuerySementicTags: "Pilih semula label semantik",
          sementicTag: "Label semantik",
          standardSentence: "Ayat standard",
          similarity: "Kesamaan",
          actions: "Operasi",
          noFitTag: "Tidak menemui label yang sesuai,",
          addSemanticTag: "Tambah label semantik baru",
          pleaseEnterRegular: "Sila masukkan ungkapan biasa",
          pleaseEnterRegularText: "Sila masukkan kandungan yang perlu disahkan",
          newAssociateComponentTip:
            "Jika nilai adalah jenis nombor, gunakan ciri lama",
        },
        filterManage: {
          noValueTip: "Tiada data",
          allCall: "Semua panggilan",
          allDialogue: "Semua perbualan",
          allWechat: "Semua sesi",
          getFiltersListFailed:
            "Gagal mendapatkan senarai penapis, cuba lagi kemudian!",
          getFilterDetailFailed:
            "Gagal mendapatkan butiran penapis, sila cuba lagi kemudian!",
          getAllCountFailed:
            "Gagal mendapatkan jumlah panggilan/perbualan, sila cuba lagi kemudian!",
        },
        listManage: {
          searchPlaceHolder: "Sila masukkan kata kunci untuk mencari",
          filterConditions: "Tapis keadaan",
          records: "Rekod",
          getRecordsFailed: "Gagal mendapatkan rekod, sila cuba lagi kemudian!",
        },
        miniListManage: {
          getRecordsFailed: "Gagal mendapatkan rekod, sila cuba lagi kemudian!",
        },
        applyTemplates: {
          title: "Templat pemeriksaan kualiti yang berkenaan",
          collectionTitle: "Templat pengumpulan yang berkenaan",
        },
        recordDetail: {
          showWaveAudio: "Tunjukkan rakaman",
          getDetailFailed:
            "Gagal mendapatkan maklumat lanjut, sila cuba lagi kemudian!",
          getSmartTagsFailed:
            "Gagal mendapatkan tag pintar, sila cuba lagi kemudian!",
          getTemplatesFailed:
            "Gagal mendapatkan templat yang berkenaan, sila cuba lagi kemudian!",
        },
        changeList: {
          sampleStatus: "Status persampelan",
          sampleAssignInspector: "Tugaskan pemeriksa rawak",
          reviewAssignInspector: "Tugaskan pengulas",
          read: "Telah dibaca",
          distributivePerson: "Orang yang ditugaskan",
          totalScore: "Jumlah markah",
        },
        qualityCheckScore: {
          totalScore: "Jumlah markah",
          actualScore: "Skor",
          actualGrade: "Penilaian",
          appeal: "Rayuan",
          remark: "Catatan",
          manualRemark: "Catatan manual",
          none: "Tiada data terperinci pemeriksaan kualiti",
          interactiveTip:
            "Gunakan pengenalan interaktif untuk mengesan penggunaan FAQ perkhidmatan pelanggan",
          interactiveIsEmptyTip:
            "Kandungan pengenalan interaktif tidak dikesan",
        },
        semanticMark: {
          semanticContent: "Tandakan ayat",
          markedSimilarTitel: "Label semantik yang serupa telah ditandakan",
          markedExcludeTitle: "Label semantik yang serupa telah ditandakan",
          taskTagTitle: "Tag semantik berkaitan tugas",
          similarTagTitle:
            "Kenal pasti label semantik yang serupa (Kesamaan> 40%, 20 Teratas)",
          delete: "Padamkan",
          markSimilar: "Tanda serupa",
          markExclude: "Tidak termasuk persamaan",
          none: "Tiada",
        },
        selectCallTime: {
          callTime: "Masa bercakap/masa mula sesi",
          callTimeRemind:
            "Anda boleh memilih rekod perbualan yang tidak lebih dari satu bulan (31 hari) pada tahun lalu",
          callRecordCount:
            "Anda telah memilih jumlah rekod panggilan/perbualan {0}",
          getCallRecordError:
            "Gagal mendapatkan rekod, sila cuba lagi kemudian",
        },
      },
      informationEntities: {
        title: "Entiti Maklumat",
        index: { import: "Import entiti maklumat" },
        detail: {
          new: "Entiti maklumat baru",
          edit: "Edit entiti maklumat",
          name: "Nama",
          apiName: "Nama API",
          activeFlag: "Keberkesanan",
          remark: "Penerangan",
          logicExp: "Pepejal fizikal",
          sentenceList: "Kandungan fizikal",
          tip: "Kandungan entiti menyokong input berganda, dipisahkan oleh ruang, dan merupakan sinonim untuk entiti yang sama",
          remarkLengthError: "Panjang keterangan tidak boleh melebihi 255",
          nameLengthError: "Panjang nama tidak boleh melebihi 40",
          entityDuplicateError: "Pengulangan kandungan fizikal",
        },
      },
      interactiveRecognition: {
        title: "Pengiktirafan interaktif",
        classify: "Pengelasan",
        addClassify: "Tambah kategori",
        editClassify: "Edit kategori",
        index: { import: "Pengenalan interaktif import" },
        detail: {
          new: "Interaksi pintar baru",
          edit: "Edit Interaksi Pintar",
          type: "Jenis",
          standardQuestion: "Isu standard",
          similarQList: "Soalan serupa",
          exceptSimilarQList: "Tidak termasuk masalah yang serupa",
          category: "Pengelasan",
          targetType: "Sasaran pengesanan masalah",
          status: "Keberkesanan",
          standardAnswer: "Jawapan standard",
          similarAList: "Jawapan yang serupa",
          wrongAList: "Jawapan yang salah",
        },
      },
      businessAnalysis: {
        title: "Analisis perniagaan",
        tip1: "Analisis trend perniagaan: Bilangan dialog biasa dalam koordinat menegak, iaitu berapa jumlah dialog biasa yang memenuhi syarat, dan abses adalah masa",
        tip2: "Analisis korelasi: Menganalisis korelasi beberapa indikator, data korelasi paparan tetikus, jumlah data, korelasi, kadar pengulangan",
        tip3: "Analisis transformasi: Analisis beberapa petunjuk dapat membentuk hubungan transformasi bersama. Kriteria untuk penukaran adalah panggilan/dialog yang sama. Sekiranya dua petunjuk yang berbeza dapat dipukul, dapat dianggap bahawa kedua-dua indikator ini mempunyai hubungan transformasi",
      },
      knowledgeBase: {
        title: "Pangkalan pengetahuan",
        list: {
          add: "Tambah subkategori",
          delete: "Padamkan kategori",
          categoryName: "Nama kategori",
          createSuccess: "Sub-kategori berjaya dibuat",
          keyWord: "Kata kunci",
        },
        detail: {
          type: "Jenis",
          status: "Status",
          classification: "Pengelasan",
          standardQuestion: "Isu standard",
          answerDetectionRange: "Julat pengesanan jawapan",
          standardAnswer: "Jawapan standard",
        },
      },
      callManage: {
        title: "Penapis rakaman panggilan",
        listTitle: "Pengurusan Panggilan",
        detailTitle: "Maklumat panggilan",
      },
      dialogueManage: {
        title: "Penapis teks dialog",
        listTitle: "Pengurusan perbualan",
        detailTitle: "Perincian perbualan",
      },
      weChatManage: {
        title: "Penapis Sesi WeChat Perusahaan",
        listTitle: "Pengurusan perbualan WeChat perusahaan",
        detailTitle: "Perincian perbualan WeChat perusahaan",
      },
      speechRecognition: {
        title: "Pengiktirafan pertuturan",
        asr: "Pengiktirafan pertuturan",
        asrRemind:
          "Mematikan suis ini akan menyebabkan kegagalan fungsi suara ke teks dan fungsi pemeriksaan kualiti mesin",
        asrConfig: "Penapisan ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Setelah diaktifkan, transkripsi ASR IM akan dilakukan",
        asrConfigRemind:
          "Apabila diaktifkan, hanya panggilan yang layak akan melaksanakan kandungan ASR",
        correction: "Pembetulan ralat pintar",
        correctionRemind:
          "Setelah diaktifkan, kandungan dalam \"<a hTribe = '/laman web/semantic-intelligence/smart-words-frary/profesional-words' target =' _ blank'> kata nama profesional leksikon pintar </a>\" akan digunakan untuk penukaran pembetulan ralat wajib",
        correctionConfig: "Penapisan pembetulan ralat",
        advancedSettings: "Tetapan lanjutan",
        advancedSettingsTip:
          "Pilih model ASR yang ditentukan untuk data yang ditentukan.",
        mandarinASRModel: "Model ASR Mandarin",
        cantoneseASRModel: "Model ASR Kantonis",
        englishASRModel: "Model ASR Bahasa Inggeris",
        correctionConfigRemind:
          "Setelah diaktifkan, hanya panggilan yang memenuhi syarat berikut akan diperbaiki",
        customJudgeLogicIsNull: "Logik tersuai tidak boleh kosong",
        redoCorrection: "Laksanakan semula pembetulan ralat",
        callTime: "Pilih masa panggilan",
        callTimeRemind:
          "Anda boleh memilih log panggilan yang tidak lebih daripada satu bulan (31 hari) dalam setahun yang lalu",
        callRecordCount: "Anda telah memilih sejumlah {0} rekod panggilan",
        redoCorrectionSubmit: "Hantar untuk pelaksanaan",
        callTimeError:
          "Tempoh panggilan yang dipilih tidak boleh melebihi satu bulan (31 hari)",
        callRecordCountError:
          "Jumlah rekod panggilan adalah 0, sila pilih semula waktu panggilan",
        redoCorrectionSuccess:
          "Pembetulan ralat berjaya, sila periksa di pusat tugas",
        redoCorrectionError: "Pembetulan semula gagal, sila cuba lagi kemudian",
      },
      role: {
        title: "Pengurusan peranan",
        create: "Watak baru",
        detail: {
          title: "Peranan",
          new: "Watak baru",
          view: "Lihat peranan",
          edit: "Peranan editor",
          navTabNames: {
            basicInfo: "Kebenaran menu",
            taskPermissions: "Kebenaran tugas pemeriksaan kualiti",
            dataPermissions: "Kebenaran data",
          },
          basicInfo: { roleName: "Nama", permissions: "Kebenaran" },
          taskPermissions: {
            allTask: "Semua tugas pemeriksaan kualiti",
            myCreatedTask: "Tugas pemeriksaan kualiti yang saya buat",
          },
          dataPermissions: {
            dataPermissionsConfig: "Konfigurasi kebenaran data",
            tips: "Karakter dapat melihat data di bawah kategori yang dipilih",
            select: "Pilih kategori",
            selectPlaceholder:
              "Sila pilih kategori yang memerlukan kebenaran data yang dapat dilihat",
          },
        },
      },
      dataMigration: {
        title: "Penghijrahan data",
        dataUpload: "Data bergerak",
        dataDowload: "Pemindahan data",
        error: "Sila pilih tugas pemeriksaan kualiti untuk dieksport",
        list: {
          info: "Sila pilih tugas pemeriksaan kualiti untuk dipindahkan",
          listInfo:
            "Sila pilih sama ada untuk keluar dari data yang relevan dari tugas pemeriksaan kualiti (seperti tesaurus pintar, tag semantik, entiti maklumat, interaksi pintar, dll.)",
          noInfo:
            "Sila pilih sama ada untuk keluar dari data pemeriksaan bukan kualiti yang lain",
          ownInfo: "Hanya keluar dari misi",
          everyInfo: "Berpindah dari tugas yang berkaitan",
          allInfo: "Keluar dari semua",
          callFilter: "Penapis Panggilan",
          talkFilter: "Penapis perbualan",
          smartTag: "Label pintar",
        },
        upload: {
          info: "1. Klik butang muat naik untuk memuat naik fail JSON yang sesuai dengan format",
          uploadSuccess: "Muat naik berjaya",
          uploadError: "Muat naik gagal",
        },
      },
      dataAnalysis: {
        businessStatistics: "Statistik Perniagaan",
        qualityEffect: "Kesihatan pemeriksaan kualiti",
        qualityAnalysis: "Analisis pemeriksaan kualiti",
        generalInspecitionReport: "Laporan am",
        fullAmountWork: "Beban kerja pemeriksa kualiti",
        intelligentTagsAnalysis: "Analisis label",
        saleIntelligentTagsAnalysis: "Analisis Label Pintar",
        generalInspecitionReportTip:
          "Jumlah data: hitung jumlah data panggilan dan perbualan dalam had masa dan tugas semasa, termasuk jumlah & peratusan data pemeriksaan kualiti pintar, jumlah & peratusan data pemeriksaan rawak manual, jumlah aduan, kadar pemeriksaan rawak dan carta trend masa",
        groupTest: "Ujian kumpulan",
      },
      uploadDownloadList: {
        title: "Pusat Misi",
        tabs: {
          upload: "Muat naik dan import",
          download: "Muat turun dan eksport",
          taskCenter: "Pelaksanaan tugas",
        },
      },
      uploadDownloadModel: {
        title: "Muat naik dan muat turun",
        list: {
          clear: "Kosong",
          success: "Kejayaan",
          fail: "Kegagalan",
          hand: "Dalam proses",
          noInfo: "Tiada data",
        },
      },
      billingCenter: {
        title: "Pusat bil",
        export: "Eksport",
        limit: "Had peringatan",
        tips: "Hasil penagihan akan berat sebelah kerana pembundaran, untuk rujukan sahaja",
        dashboard: {
          remainingQuota: "Kuota baki semasa",
          usedQuota: "Kuota terkumpul yang digunakan",
          voiceQuality: "Pemeriksaan kualiti suara:",
          voice: "Suara:",
          textQuality: "Pemeriksaan kualiti teks:",
          text: "Teks:",
          hour: "Jam",
          tenThousandHour: "Sepuluh ribu jam",
          day: "Hari",
          cutoff: "Tamat",
          warning:
            "(Kuota selebihnya tidak mencukupi, sila isi semula secepat mungkin)",
          isClosed: "(Status tutup)",
        },
        table: {
          quotaRecord: "Rekod kuota",
          timeFilter: "Pilih tarikh:",
          search: "Pertanyaan",
          reset: "Tetapkan semula",
          id: "Nombor siri",
          type: "Jenis",
          qualityType: "Jenis pemeriksaan kualiti",
          quotaChange: "Perubahan",
          surplus: "Baki",
          changeTime: "Masa",
          voiceQuality: "Pemeriksaan kualiti suara",
          textQuality: "Pemeriksaan teks",
          sizeChangeText: "Paparan setiap halaman",
          used: "Gunakan",
          give: "Diberikan",
          deduction: "Potongan",
          callTime: "Masa bercakap",
          count: "Memproses jumlah data",
          page: "Sebanyak {0} item, halaman {1}",
        },
        limitModal: {
          call: "Kuota pemeriksaan kualiti suara lebih rendah daripada",
          callFix: "Jam untuk mengingatkan",
          dialogue: "Baki masa pemeriksaan kualiti teks kurang dari",
          dialogueFix: "Hari untuk mengingatkan",
        },
      },
      messageCenter: {
        replyTitle: "Balas saya",
        answerTitle: "@ Saya",
        shareTitle: "Kongsi saya",
      },
      columnsManage: { title: "Pengurusan bidang pemeriksaan kualiti" },
      recordUpload: {
        list: {
          task: "Tugas pemeriksaan kualiti",
          user: "Pengendali",
          uploadTime: "Masa muat naik",
          endTime: "Masa tamat",
          total: "Jumlah muat naik",
          success: "Pemeriksaan kualiti yang berjaya",
          failed: "Pemeriksaan kualiti gagal",
          progress: "Kemajuan pemeriksaan kualiti",
          inProgress: "Dalam proses",
          finished: "Selesai",
        },
        steps: {
          uploading: "Muat naik fail rakaman",
          baseInfo: "Isi maklumat asas",
          start: "Mulakan pemeriksaan kualiti",
        },
        uploadComponent: {
          dragger: "Seret untuk menambah fail",
          or: "Atau",
          selectFile: "Pilih fail",
          des: "Menyokong format wav, mp3, kadar persampelan 8000Hz, fail 16-bit",
          limit1: "Muat naik paling banyak sekali",
          limit2: "Fail, setiap ukuran fail tidak melebihi",
          uploading: "Muat naik",
          finished: "Muat naik selesai",
          success: "Kejayaan",
          failure: "Kegagalan",
        },
        baseInfo: {
          task: "Pilih tugas pemeriksaan kualiti",
          type: "Pilih format rakaman",
          agentSide: "Saluran perkhidmatan pelanggan",
          excel: "Muat naik data di sepanjang jalan",
          checkFileInfo: "Periksa maklumat fail",
          excelFileError: "Sila muat naik data dalam perjalanan",
          checking: "Dalam pengesahan...",
          reCheck: "Periksa semula",
          success: "Pengesahan yang berjaya",
          excelUoloadFail: "Muat naik data di jalan gagal",
          tips: {
            tip1: "1. Nama fail rakaman dalam ecxel mesti sama dengan nama rakaman yang dimuat naik, dan nama tidak boleh sama",
            tip2: "2. Sistem memeriksa semula nama fail yang dirakam dan ID panggilan. Pendua akan menyebabkan kegagalan pemeriksaan kualiti",
            tip3: "3. Templat rakaman excel yang dimuat turun, warna fon merah adalah medan yang diperlukan",
            tip4: "4. Format medan untuk jenis masa ialah: yyyy-mm-dd hh:mm:ss, sebagai contoh, 2021-04-20 17:36:30",
          },
        },
        title: "Muat naik rakaman tempatan",
        checkTitle: "Pemeriksaan kualiti rakaman",
        start: "Mulakan pemeriksaan kualiti",
        disabledTips:
          "Sila periksa fail rakaman sebelum melakukan pemeriksaan kualiti",
        tips: "Fail rakaman dengan nama yang sama merangkumi hasil pemeriksaan kualiti dan maklumat asas",
        tips1: "Seret fail di sini, atau klik muat naik",
        tips2: "Sokongan",
        tips3: "Format",
        type: "wav、mp3",
        tips4: "Muat naik paling banyak sekali",
        tips5: "Fail, setiap ukuran fail tidak melebihi",
        tipsOverSize:
          "Baris semasa penuh, sila hapus fail dalam barisan sebelum memuat naik",
        size: "500M",
        count: "20",
        uploaded: "Fail rakaman telah dimuat naik",
        uploading: "({0}%)",
        uploadFinished: "(Muat naik selesai)",
        bottomTitle: "Pengumpulan maklumat rakaman:",
        recordType: "Pilih borang rakaman:",
        double: "Rakaman panggilan dua saluran",
        single: "Rakaman panggilan saluran tunggal",
        recordBaseInfo: "Muat naik maklumat asas rakaman:",
        addBtn: "Tambah fail",
        downloadTemplate: "Muat turun templat.excel",
        checkRecord: "Periksa maklumat rakaman",
        checking: "Dalam pengesahan...",
        reCheck: "Periksa semula",
        checkAll: "Semua pilihan",
        cancelCheckAll: "Batalkan semua pilihan",
        delete: "Padamkan",
        deleteLotSize: "Padam kumpulan",
        agentChannel: "Saluran perkhidmatan pelanggan:",
        left: "Saluran kiri",
        right: "Saluran kanan",
        donotKonw: "Tidak tahu",
        mission: "Pilih tugas pemeriksaan kualiti:",
        uploadFail: "Muat naik gagal",
        repeat: "Fail pendua",
        overCount: "Bilangan fail melebihi had",
        overSize: "Saiz fail melebihi had",
        uoloadFail: "Muat naik ke awan gagal",
        formatError: "Ralat format",
        seccess: "Pengesahan yang berjaya",
        fail: "Pengesahan gagal",
        recordChecking: "Pemeriksaan kualiti rakaman, sila tunggu...",
        recordCheckSuccess: "Pemeriksaan kualiti rakaman selesai",
        recordCheckFail:
          "Pemeriksaan kualiti rakaman gagal, sila periksa sama ada rakaman itu berulang atau tidak sah",
        backToIndex: "Kembali ke senarai muat naik",
        recordCheckIsStarted:
          "Tugas pemeriksaan kualiti sedang dilaksanakan, sila mulakan tugas baru setelah tugas semasa selesai",
      },
      customerServicePortrait: {
        title: "Potret Perkhidmatan Pelanggan",
        tip1: "Undang-undang pengedaran panjang ayat: sebaran panjang ayat dikira mengikut jumlah perkataan panjang ayat, dan hanya perkhidmatan pelanggan yang dikira",
        tip2: "Undang-undang taburan kelajuan pertuturan: sebaran kelajuan pertuturan, menurut kelajuan pertuturan (kata/minit), statistik dilakukan dalam beberapa bahagian, dan hanya perkhidmatan pelanggan yang dikira",
        tip3: "Undang-undang pengedaran masa tindak balas: pembahagian masa tindak balas, hanya status tindak balas pelanggan terhadap pelanggan yang dikira",
        tip4: "Pematuhan pemeriksaan kualiti: kadar pematuhan titik pemeriksaan kualiti",
        tip5: "Skor purata tugas pemeriksaan kualiti: skor purata tugas pemeriksaan kualiti",
        contol: {
          date: "Pilih tarikh:",
          today: "Hari ini",
          thisMonth: "Bulan ini",
          lastMonth: "Bulan lepas",
          thisQuarter: "Suku ini",
          lastQuarter: "Suku lalu",
          thisYear: "Tahun ini",
          lastYear: "Tahun lepas",
          customer: "Perkhidmatan Pelanggan:",
          search: "Pertanyaan",
          reset: "Tetapkan semula",
          export: "Eksport",
          indicator: "Pengurusan penunjuk",
          selectPlaceholder:
            "Sila pilih perkhidmatan pelanggan yang perlu dibandingkan",
          warning: "Pilih sehingga 5 perkhidmatan pelanggan untuk perbandingan",
          warning2:
            "Sila pilih sekurang-kurangnya 1 perkhidmatan pelanggan untuk pertanyaan",
          dataWarning:
            "Hanya menyokong data pertanyaan sehingga 366 hari selepas tarikh mula",
          seccuss: "Eksport rekod berjaya",
        },
        echarts: {
          distributionLaw: "Undang-undang pengedaran",
          count: "Bilangan kali",
          bout: "Kali",
        },
        portrait: {
          ability: "Dimensi keupayaan perkhidmatan pelanggan",
          option: "Konfigurasi",
          abilityOption: "Dimensi keupayaan konfigurasi",
          placeholder: "Pencarian kata kunci dimensi keupayaan input",
          save: "Simpan",
          warning:
            "Sila pilih sekurang-kurangnya 3 petunjuk untuk konfigurasi, dan anda boleh memilih sebanyak 10 petunjuk",
        },
        label: { title: "Label perniagaan perkhidmatan pelanggan" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Kedudukan Perkhidmatan Pelanggan",
        select: {
          date: "Pilih tarikh:",
          today: "Hari ini",
          thisMonth: "Bulan ini",
          lastMonth: "Bulan lepas",
          thisQuarter: "Suku ini",
          lastQuarter: "Suku lalu",
          thisYear: "Tahun ini",
          lastYear: "Tahun lepas",
          group: "Kumpulan Perkhidmatan Pelanggan:",
          groupPlaceholder: "Sila pilih kumpulan perkhidmatan pelanggan",
          customer: "Perkhidmatan Pelanggan:",
          customerPlaceholder: "Sila pilih perkhidmatan pelanggan",
          search: "Pertanyaan",
          reset: "Tetapkan semula",
          compared: "Kontras",
          export: "Eksport",
          column: "Lajur",
          warning: "Pilih sehingga 5 perkhidmatan pelanggan untuk perbandingan",
          warning2: "Sila pilih sekurang-kurangnya satu perkhidmatan pelanggan",
          dataWarning:
            "Hanya menyokong data pertanyaan sehingga 366 hari selepas tarikh mula",
          selected: "Perkhidmatan pelanggan terpilih:",
          seccuss: "Eksport rekod berjaya",
        },
        compared: {
          title: "Pilih Perkhidmatan Pelanggan",
          return: "Batalkan",
          compared: "Kontras",
          tips: "Sila pilih 2-5 perkhidmatan pelanggan untuk perbandingan",
          warning:
            "Hanya menyokong 2-5 perkhidmatan pelanggan untuk perbandingan",
        },
        transfer: { leftTitle: "Senarai item", rightTitle: "Item paparan" },
        table: {
          page: "Sebanyak {0} item, halaman {1}",
          actionTitle: "Operasi",
          action: "Lihat",
        },
      },
      conceptWord: {
        title: "Perkataan konsep",
        new: "Perkataan konsep baru",
        control: {
          search: "Carian:",
          searchPlaceholder: "Carian Nama atau Kata Kunci",
          commit: "Pertanyaan",
          reset: "Tetapkan semula",
          import: "Import",
          export: "Eksport",
          seccuss: "Eksport rekod berjaya",
        },
        modal: { title: "Perkataan konsep import" },
        table: {
          sizeChangeText: "Paparan setiap halaman",
          page: "Sebanyak {0} item, halaman {1}",
          edit: "Edit",
          delete: "Padamkan",
          request:
            "Selepas penghapusan, ia tidak akan dipulihkan. Adakah anda mengesahkan penghapusan?",
          cancel: "Batalkan",
          title: {
            conceptWord: "Perkataan konsep",
            describeWordsNum: "Bilangan perkataan penerangan",
            updateTime: "Masa kemas kini",
            status: "Keberkesanan",
            effective: "Berkesan",
            invalid: "Tidak sah",
            action: "Operasi",
          },
        },
        detail: {
          title: {
            new: "Perkataan konsep baru",
            edit: "Edit perkataan konsep",
          },
          name: "Nama perkataan konsep",
          namePlaceholder: "Sila masukkan nama konsep",
          effectiveness: "Keberkesanan",
          effective: "Berkesan",
          invalid: "Tidak sah",
          description: "Penerangan:",
          descriptionPlaceholder: "Sila masukkan keterangan",
          add: "Tambah",
          repeat: "Kandungan pendua",
          empty: "Huraian tidak boleh kosong",
          recommendEmpty:
            "Tidak ada keterangan yang lebih disyorkan, sila masukkan dan tambahkan secara manual",
          recommend: "Penerangan cadangan pintar",
          cancel: "Batalkan",
          change: "Tukar kumpulan",
          descriptionSelected: "Huraian telah ditambah:",
          save: "Simpan",
          success: "Kata konsep berjaya disimpan",
          warning: "Nama kata konsep tidak boleh kosong",
          warning2: "Sila tambah sekurang-kurangnya 1 penerangan",
          submitBtnHandle: {
            title:
              "Huraian yang diperiksa belum ditambahkan, simpan keterangan yang tidak ditambahkan",
            content:
              "Klik OK untuk kembali ke halaman utama kata konsep, mengesahkan penerusan?",
          },
        },
      },
      smartTools: {
        btn: "Alat pintar",
        title: {
          similarWords: "Penemuan perkataan yang berkaitan",
          search: "Mengikut perkataan",
          semanticTags: "Label semantik",
        },
        sentenceSearch: {
          placeholder:
            "Sila masukkan kata kunci untuk mencari, menyokong pelbagai kata kunci",
          more: "Lihat teks penuh",
          pageUp: "Sebelumnya",
          pageDown: "Seterusnya",
          total: "Sebanyak {0} rekod",
        },
        similarDiscover: {
          placeholder: "Sila masukkan kata kunci",
          copyed: "Salin",
          none: "Tidak ada perkataan yang berkaitan, sila cuba kata kunci lain",
        },
        smartTags: {
          searchPlaceHolder: "Sila masukkan kata kunci atau ayat",
          createSmartTag: "Baru",
          createClusteringSmartTag: "Buat yang baru dengan data {0}",
          addFromKeyword: "Tambahkan kandungan carian ke ayat yang serupa",
          hit: "Hit",
          similar: "Sama",
          name: "Nama label semantik",
          namePlaceHolder: "Sila masukkan nama label semantik",
          recallWords: "Ingat perkataan",
          recallWordsPlaceHolder: "Sila masukkan kata kunci yang ditarik balik",
          recallRegular: "Ingat semula biasa",
          recallRegularPlaceHolder: "Sila masukkan peraturan penarikan balik",
          rules: "Peraturan",
          standardSentences: "Ayat standard",
          standardSentencesPlaceHolder: "Sila masukkan ayat standard",
          similaSentences: "Ayat yang serupa",
          similaSentencesPlaceHolder: "Sila masukkan ayat yang serupa",
          notSaveError:
            "Sila simpan label semantik terlebih dahulu, dan kemudian cari",
          add: "Tambah",
          newPage: "Buka di halaman baru",
          querryError:
            "Terdapat bidang yang serupa dalam senarai umum (konfigurasi lanjutan serupa dengan penghapusan semula)",
          detailTitle: "Perincian label semantik",
        },
      },
      trainingCenter: {
        title: "Pusat Latihan",
        edit: "Edit",
        delete: "Padamkan",
        seleteAll: "Semua pilihan",
        cancelCheckAll: "Batalkan semua pilihan",
        add: "Tambah",
        remove: "Keluarkan",
        save: "Simpan",
        cancel: "Batalkan",
        fastTraining: {
          title: "Latihan pantas",
          titleDes:
            "Latihan pantas bertujuan untuk membantu tag semantik dengan cepat digeneralisasikan untuk mencapai 20 ayat yang serupa",
          semanticTags: "Label semantik",
          semanticTagsPlaceholder:
            "Sila masukkan kata kunci proses untuk mencari",
          standardSentence: "Kalimat standard:{0}",
          similarSentencesPlaceholder:
            "Masukkan ayat yang serupa untuk menambah atau mencari",
          similarSentencesAdd: "Tambah",
          similarSentencesSearch: "Cari",
          similarSentences: "Menambah ayat yang serupa",
          trainingCount: "Bilangan latihan:{0}",
          similarSentencesRecommend: "Mengesyorkan ayat yang serupa",
          noResp: "Tidak ada tag semantik yang berkaitan, sila ubah kata kunci",
          similarSentencesModalTitle: "Pencarian ayat yang serupa",
          moreInfo: "Lihat Konteks",
          moreInfoBtn: "Konteks",
          modalSearchType: {
            all: "Semua",
            customer: "Perkhidmatan Pelanggan",
            client: "Pelanggan",
          },
          repetWarning:
            "Kalimat serupa yang sama sudah ada, jangan tambah lagi",
          repetRemoveTip:
            "Simpan kandungan pendua dalam ayat yang serupa, yang telah dikeluarkan secara automatik",
        },
        trainingHistory: {
          title: "Sejarah latihan",
          select: {
            date: "Pilih masa:",
            today: "Hari ini",
            thisMonth: "Bulan ini",
            lastMonth: "Bulan lepas",
            thisQuarter: "Suku ini",
            lastQuarter: "Suku lalu",
            thisYear: "Tahun ini",
            lastYear: "Tahun lepas",
            search: "Pertanyaan",
            reset: "Tetapkan semula",
          },
          table: {
            time: "Masa",
            user: "Pengendali",
            tag: "Label semantik",
            similarCount: "Bilangan ayat yang serupa",
            deleteCount: "Keluarkan bilangan ayat",
            page: "Sebanyak {0} item, halaman {1}",
          },
        },
        classifiedTraining: {
          title: "Latihan klasifikasi",
          tagsGrounp: "Kumpulan label",
          placeHolder: "Sila pilih",
          cellTime: "Pilih masa",
          search: "Pertanyaan",
          reset: "Tetapkan semula",
          classifiedFlag: "Keadaan latihan yang dikelaskan",
          num: "Nilai",
          gas: "Peratusan",
          noData: "Latihan yang tidak dikategorikan",
          noDataHeight:
            "Data yang tidak dikategorikan menyumbang bahagian yang agak tinggi, dan latihan sangat diperlukan",
          noDataSmall:
            "Data yang tidak dikategorikan menyumbang bahagian yang agak tinggi, disarankan untuk berlatih",
          noDataDays: "Pelaksanaan terakhir {0} hari yang lalu",
          training: "Latihan",
          start: "Mula",
          addClass: "Tingkatkan klasifikasi",
          custom: "Penapis lanjutan",
          marked: "Errata data ditandakan",
          markedHeight:
            "Tidak ada pembetulan selama {0} hari, dan pembetulan disyorkan",
          markedSmall:
            "Tidak ada errata selama {0} hari, dan errata sangat diperlukan",
          mark: "Errata",
          dataCheck: "Pengesahan data yang dikelaskan",
          dataCheckSmall:
            "Penyebaran K-L lebih tinggi daripada 0.5, latihan disyorkan",
          dataCheckHeight:
            "Penyebaran K-L lebih tinggi daripada 0.8, latihan sangat diperlukan",
          check: "Pengesahan",
          history: "Tandakan sejarah",
          historyTime:
            "Penandaan dan pengubahsuaian harian akan melaksanakan latihan model secara automatik pada jam 2:00 pagi",
          today: "Tandakan hari ini",
          article: "Artikel",
          none: "Tiada",
          historyCheck: "Jumlah anotasi sejarah",
          look: "Lihat rekod",
          historyluate: "Pengiraan semula data sejarah",
          startluate: "Pengiraan mengangkat rambut",
          classData: "Data klasifikasi",
          classStatus: "Kemajuan latihan",
          next: "Seterusnya",
          prev: "Sebelumnya",
          finish: "Melengkapkan latihan",
          stop: "Tamat latihan",
          classDetail: "Perincian latihan",
          trainingNone: "Label pintar yang tidak terlatih",
        },
        classifiedTrainingHistory: {
          title: "Sejarah latihan",
          time: "Pilih masa:",
          tag: "Jenis label:",
          user: "Pengendali:",
          data: "Pilih Sumber Data:",
          search: "Pertanyaan",
          searchBar: "Carian:",
          searchBarPlaceholder:
            "Sila masukkan ID panggilan/ID perbualan untuk mencari",
          reset: "Tetapkan semula",
          export: "Eksport",
          all: "Semua",
          select: {
            today: "Hari ini",
            thisMonth: "Bulan ini",
            lastMonth: "Bulan lepas",
            thisQuarter: "Suku ini",
            lastQuarter: "Suku lalu",
            thisYear: "Tahun ini",
            lastYear: "Tahun lepas",
            search: "Pertanyaan",
            reset: "Tetapkan semula",
          },
          table: {
            user: "Pengendali",
            date: "Masa operasi",
            data: "Sumber data",
            id: "ID Panggilan/ID Perbualan",
            tagType: "Kumpulan label pintar",
            tagName: "Nama label",
            state: "Status",
            sizeChangeText: "Paparan setiap halaman",
            page: "Sebanyak {0} item, halaman {1}",
            call: "Panggilan",
            dialogue: "Dialog",
          },
        },
        clusteringAnnotation: {
          title: "Pelabelan kluster",
          left: { title: "Keputusan pengelompokan" },
          right: {
            title: "Ayat pengelompokan",
            checkAll: "Pilih semua halaman ini",
            tagging: "Tandakan",
            detail: "Lihat",
            meaningless: "Abaikan",
            meaninglessTips:
              "Hasil pengelompokan yang diabaikan tidak akan dipaparkan lagi pada masa akan datang. Adakah anda pasti ingin mengabaikannya?",
            cancel: "Batalkan",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Pengurusan Pelanggan",
          tabTitle: "Pelanggan Pusat Khidmat Pelanggan",
        },
        wechatClientManage: {
          title: "Pelanggan perusahaan dan mikro",
          table: {
            id: "ID Pelanggan",
            name: "Nama samaran pelanggan",
            tag: "Label pelanggan",
          },
        },
      },
      collectionRule: {
        title: "Peraturan pengumpulan",
        form: {
          info: "Pengumpulan maklumat pintar",
          infoPlaceholder: "Sila pilih kategori",
        },
        table: {
          name: "Nama bidang",
          props: "Sifat pelanggan",
          type: "Jenis bidang",
          desc: "Penerangan bidang",
        },
      },
      dataAdd: {
        title: "Penambahan data",
        types: {
          auto: "Tambah secara automatik",
          manual: "Tambah secara manual",
        },
      },
      wechatStaff: {
        table: {
          name: "Nama",
          part: "Jabatan gabungan",
          phone: "Nombor telefon",
          email: "Peti mel",
          wechartId: "ID WeChat Korporat",
          status: "Status pekerjaan",
        },
        info: "Sila pilih jabatan yang perlu menyegerakkan data terlebih dahulu",
        synSuccess: "Kejayaan penyegerakan",
        init: "Permulaan",
        syn: "Penyegerakan",
      },
      appealHistory: {
        appealTime: "Masa rayuan",
        cancelTime: "Masa pembatalan",
        reviewTime: "Masa semakan",
        inspector: "Pemeriksa kualiti",
        result: "Hasil rayuan",
        complainResult:
          "Sebanyak {0} item pemeriksaan kualiti diajukan, {1} berjaya diajukan banding, dan {2} ditolak",
        reason: "Sebab rayuan",
        rejection: "Sebab penolakan",
        cancel: "Dibatalkan",
        tip: "Adakah anda mengesahkan bahawa anda ingin membatalkan rayuan?",
      },
      appealSdk: {
        time: "Melalui masa dialog",
        externalIdCall: "ID rekod panggilan",
        externalIdText: "ID rakaman perbualan",
        customerNickName: "Nama samaran pelanggan",
        readStatus: "Status rujukan",
        status: "Status rayuan",
        isManual: "Sama ada pemeriksaan kualiti manual",
        complainButtonInfo:
          "Rayuan dibenarkan {0} kali, {1} kali telah diajukan, dan selebihnya {2} kali dapat diajukan. Boleh membuat rayuan sebelum {3}",
        submit: "Mengemukakan aduan",
        complain: "Rayuan",
        allComplain: "Rayuan semua",
        pendingSubmit: "Untuk dihantar",
        taskIds: "Tugas pemeriksaan kualiti",
        graded: "Penilaian",
        complainSuccess: "Penyerahan rayuan yang berjaya",
        appealSdkIndex: { title: "Hasil pemeriksaan kualiti pintar" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Gagal mendapatkan maklumat senarai penapis, periksa sambungan rangkaian atau cuba lagi kemudian!",
      columns: "Lajur",
      filter: "Penapis",
      noSearchResult: "Tiada rekod dijumpai",
      pleaseSearch: "Sila pilih penapis dan cuba cari",
      sift: "Penapis",
      create: "Baru",
      search: "Cari",
      createTaskTitle: "Misi baru",
      title: "Tajuk",
      deadline: "Tarikh akhir",
      principal: "Orang yang bertanggungjawab",
      enqueueTime: "Masa untuk menyertai pasukan",
      computeError: "Pengiraan gagal, sila tunggu!",
      sum: "Mencari keamanan",
      max: "Maksimum",
      min: "Minimum",
      avg: "Purata",
      count: "Kiraan",
      maxDate: "Maksimum (terkini)",
      minDate: "Minimum (tertua)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Gagal mendapatkan alamat pautan Insight, sila cuba lagi kemudian!",
      },
      template: {
        learningReminder: "Peringatan pembelajaran",
        subscriptionDynamics: "Langgan berita",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Pengurusan Tugas", chaneg: "Tukar" },
        qualityCheckTemplate: {
          text: "Templat pemeriksaan kualiti",
          collectText: "Templat koleksi",
          condition: { text: "Keadaan pemeriksaan kualiti" },
          checkPoint: { text: "Konfigurasi peraturan" },
          automaticAdd: { text: "Automasi Proses" },
          manualAdd: { text: "Tambah secara manual" },
          roleConfig: { text: "Konfigurasi peranan" },
          basicInfo: { text: "Maklumat asas" },
          smartWordsLibrary: { text: "Tesaurus pintar" },
          grade: { text: "Penilaian pemeriksaan kualiti" },
          collectionRule: { text: "Peraturan pengumpulan" },
          addData: { text: "Tambah data" },
          collectionList: { text: "Senarai koleksi" },
        },
        qualityCheckWorkbench: {
          text: "Meja kerja pemeriksaan kualiti",
          wechatText: "Meja kerja analisis",
          informationCollectText: "Meja pengumpulan",
          qualityInspectionList: {
            text: "Senarai pemeriksaan kualiti",
            wechatText: "Senarai analisis",
            informationCollectText: "Senarai koleksi",
          },
          condition: { text: "Senarai pemeriksaan kualiti (ditinggalkan)" },
          reviewList: { text: "Senarai ulasan" },
          spotCheckList: { text: "Senarai sampel" },
          caseBaseList: { text: "Perpustakaan kes" },
          testSetList: { text: "Set ujian pemeriksaan kualiti" },
        },
        statisticsAndReports: {
          text: "Statistik dan laporan",
          wholeReport: { text: "Laporan keseluruhan" },
          groupReport: { text: "Laporan kumpulan perkhidmatan pelanggan" },
          agentReport: { text: "Laporan Perkhidmatan Pelanggan" },
          customerServiceExecution: { text: "Analisis Perkhidmatan Pelanggan" },
          analysisOverview: { text: "Gambaran keseluruhan analisis" },
          inspectionItemsAnalysis: {
            text: "Analisis item pemeriksaan kualiti",
          },
        },
        wechatQa: {
          text: "Pemeriksaan Kualiti WeChat Perusahaan",
          task: { text: "Tugas analisis" },
          baseInfo: { text: "Maklumat asas" },
          checkPoint: { text: "Konfigurasi model analisis" },
          setting: { text: "Konfigurasi analisis WeChat perusahaan" },
          automaticAdd: { text: "Automasi Proses" },
          manualAdd: { text: "Tambah secara manual" },
        },
        clientCenter: { text: "Pusat Khidmat Pelanggan" },
      },
      conditionDictionaries: {
        title: "Semua syarat",
        searchDefaultText: "Sila masukkan ID atau nama",
        id: "ID",
        name: "Nama",
        conditionDetailedShow: "Kembangkan",
        conditionDetailedHide: "Simpanan",
        remark: "Penerangan:",
        checkRole: "Peranan:",
        operator: "Pengendali:",
        operatorLogic: "Logik:",
      },
      audioTextView: {
        customer: "Pelanggan",
        agent: "Perkhidmatan Pelanggan",
        unsupportedFormat: "Format ini tidak disokong buat sementara waktu",
      },
      checkPointClassifications: {
        add: "Kategori baru",
        name: "Nama",
        enterToSave: "Tekan Enter untuk menyimpan",
        highestScore: "Skor maksimum",
        minimumScore: "Had skor minimum",
        defaultMinScore: "Lalai adalah 0 mata",
        minimumScoreTitle: "Had skor yang lebih rendah",
        beginScore: "Nilai skor keseluruhan",
        beginScoreTitle: "Nilai skor permulaan",
        scoreSetTip:
          "Skor maksimum kurang dari jumlah skor, anda boleh memilih:",
        ignoreExcessSpillover: "Abaikan limpahan berlebihan",
        automaticWeightCalculation: "Perakaunan berat automatik",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Bidang" },
    checkRange: {
      numberTipOne: "No.",
      numberTipTwo: "Ayat",
      numberTipThree: "Kalimat ke",
      conditionLimit: "Sekatan bersyarat",
      roleLimit: "Had peranan",
      contextLimit: "Sekatan ayat",
      notZero: "Sekatan ayat tidak menyokong input 0",
      startMustLessThanEnd:
        "Kedudukan ayat permulaan mestilah lebih kecil daripada kedudukan ayat akhir",
    },
    waveAudio: {
      play: "Main",
      pause: "Penggantungan",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Sila masukkan",
      pleaseEnterRegular: "Sila masukkan ungkapan biasa",
      pleaseEnterGrammar: "Sila masukkan peraturan tatabahasa",
      pleaseEnterTestText: "Sila masukkan kandungan yang perlu disahkan",
      pleaseEnterLoudness: "Sila masukkan desibel kekuatan",
      pleaseEnterRangeAbility: "Sila masukkan julat perubahan",
      afterHit: "Periksa selepas hit",
      pleaseEnterContext: "Sila masukkan penyataan rujukan",
      wordNumber: "Jurang perkataan",
      intervalSecond: "Selang masa lebih besar daripada",
      minute: "Setiap minit",
      wordUnit: "Perkataan",
      test: "Ujian",
      testRegular: "Uji ungkapan biasa",
      testGrammar: "Ujian tatabahasa",
      testGrammarError: "Ujian tatabahasa gagal",
      regularError: "Kesalahan ungkapan biasa, sila masukkan semula",
      pleaseEnterSemanticSentence:
        "Sila masukkan ayat yang memerlukan ujian semantik",
      pleaseEnterAssociate:
        "Sila rujuk arahan di sebelah kanan untuk membina ungkapan data di sepanjang jalan",
      querySemanticTags: "Pilih label semantik",
      reQuerySemanticTags: "Pilih semula label semantik",
      semanticTags: "Label semantik",
      standardSentence: "Ayat standard",
      semanticName: "Label semantik",
      sentence: "Ayat standard",
      similarity: "Kesamaan",
      actions: "Operasi",
      tipOne: "Tidak menemui label yang sesuai,",
      tipTwo: "Tambah label semantik baru",
      notLessThan: "Tidak kurang daripada",
      notGreaterThan: "Tidak lebih besar daripada",
      ignoreLessThan: "Abaikan kurang daripada",
      wordUnitSet: "Perkataan ayat",
      speedWordNotNull:
        "Jumlah perkataan paling sedikit dan jumlah perkataan yang paling banyak tidak boleh kosong",
      speedWordMinValueLessThanMaxValue:
        "Pengesanan kelajuan pertuturan minimum tidak boleh lebih besar daripada jumlah kata maksimum",
      speechGrabbingIntervalTip: "Selang bercakap> =",
      speechLengthTip: "Kata-kata panjang> =",
      msUnit: "ms",
      sUnit: "Kedua",
      grabIntervalNotNull: "Selang perbualan tidak boleh kosong",
      grabLengthNotNull: "Kalimat yang panjang tidak boleh kosong",
      pleaseSelectEntity: "Sila pilih entiti maklumat",
      pleaseSelectOperator: "Sila pilih operasi",
      pleaseSelectBasicInfo: "Sila pilih maklumat asas",
      pleaseEnterFixedValue: "Sila masukkan nilai tetap",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Perlawanan berjaya",
      matchError: "Perlawanan gagal",
      mindetectionStatement: "Penyataan pengesanan minimum",
      conceptWordholder: "Sila pilih kata konsep",
      elementHolder: "Sila masukkan elemen",
      associateMedol: { tabs: { data: "Data", operator: "Pengendali" } },
      repet: "Ulangi",
      bout: "Kali",
      ignore: "Abaikan kurang daripada",
      ignoreAffix: "Perkataan ayat",
      associateData: "Data dalam perjalanan",
      pretreatmentData: "Data pra-pemprosesan",
      reviewTaskData: "Kaji data tugas",
      taskData: "Data tugas pemeriksaan kualiti",
      businessData: "Rekod perniagaan",
      functionOperator: "Pengendali fungsi",
      operation: "Pengendali operasi",
      notAnyMatch: "Apabila gagal mengatasi sebarang masalah:",
      times: "Bilangan penampilan",
      newAssociateDataTip: "Jika nilai adalah jenis nombor, gunakan ciri lama",
    },
    conditionGather: {
      cycle: "Kitaran",
      rlue: "Peraturan",
      delete: "Keluarkan",
      sampling: "Ekstrak",
      item: "Artikel",
      percent: "%",
      samplingSame: "Jumlah yang sama untuk setiap perkhidmatan pelanggan",
      samplingRecheck:
        "Benarkan pengambilan semula item yang telah diambil sampel",
      dateUnit: "Nombor",
      samplingTip:
        "Peraturan yang berbeza merangkumi panggilan yang sama dan tidak akan diekstraksi berulang kali, dan rekod yang tidak diperiksa tidak akan diekstraksi lagi.",
      add: "Peraturan baru",
    },
    ruleGather: {
      title: "Peraturan",
      screeningCondition: "Tapis keadaan",
      addRule: "Peraturan baru",
      inspector: "Pemeriksa kualiti",
      ruleTip:
        "Mod pengedaran keutamaan peraturan, diedarkan secara berurutan dari atas ke bawah, tanpa pengedaran berulang, peruntukan rawak dalam peraturan atau tidak sesuai dengan mana-mana item peraturan akan diberikan secara rawak",
      moveUp: "Gerakkan ke atas",
      moveDown: "Bergerak ke bawah",
      top: "Atas",
    },
    pushRulesGather: {
      title: "Peraturan",
      delete: "Keluarkan",
      newPush: "Tolak baru",
      newRule: "Peraturan baru",
      sampling: "Ekstrak",
      item: "Artikel",
      percent: "%",
      samplingSame: "Jumlah yang sama untuk setiap perkhidmatan pelanggan",
      dateUnit: "Nombor",
      screeningCondition: "Tapis keadaan",
      email: "Peti mel",
      emailPlaceholder: "Masukkan peti mel dan bahagikan dengan koma",
      updateType: "Jenis kemas kini",
      pushDetail: "Tolak butiran",
      pushUrl: "Tolak URL",
      pushWay: "Jenis tolak",
      pushMethod: "Kaedah tolak",
      pushContentType: "Format data",
      pushData: "Tolak data",
      additionalData: "Data tambahan",
      inspectionType: "Jenis pemeriksaan kualiti",
      all: "Semua",
    },
    taskAdditionalData: {
      newData: "Menambah data",
      delete: "Keluarkan",
      placeholder: "Sila masukkan nama",
      dataPlaceholder: "Sila masukkan kandungan",
    },
    UdeskQaReact: {
      loadSdkError: "Pemuatan SDK gagal, sila cuba lagi kemudian!",
    },
    gearOptionList: { evaluation: "Penilaian" },
    tableComponentTransfer: {
      leftTitle: "Senarai item",
      rightTitle: "Item paparan",
    },
    kmTinymce: {
      link: "Masukkan/edit pautan",
      modalTitle: "Masukkan pautan",
      href: "Alamat",
      text: "Teks paparan",
      title: "Tajuk",
      target: "Kaedah pembukaan",
      none: "Tiada",
      newWindow: "Buka dalam tetingkap baru",
      buttonStyle: "Gaya butang",
      close: "Tutup",
      open: "Buka",
      answerMessage: "Lompat/hantar mesej",
      answerMessageTitle: "Hantar butang mesej",
      answerMessageText: "Teks paparan",
      answerMessageContent: "Kandungan mesej",
      answerMessageButtonStyle: "Gaya butang",
      answerMessageTips:
        "Catatan: Butang mesej hanya menyokong saluran web, sila konfigurasikan dengan teliti",
      answerSwitchStaff: "Lompat/putar manual",
      answerSwitchStaffTitle: "Pergi ke butang manual",
      answerSwitchStaffText: "Teks paparan",
      answerSwitchStaffRule: "Peraturan butang",
      answerSwitchStaffButtonStyle: "Gaya butang",
      answerSwitchStaffTips:
        "Catatan: Butang manual hanya menyokong saluran web, sila konfigurasikan dengan teliti",
      textButton: "Butang teks",
      borderButton: "Butang dengan bingkai",
      message: "Hantar mesej",
      switchStaff: "Pindahkan manual",
      messageError: "Kandungan butang tidak boleh melebihi 10 perkataan",
      messageIsEmptyError: "Kandungan butang tidak boleh kosong",
      okText: "OK",
      cancelText: "Batalkan",
      video: "Video",
      uploadVideo: "Muat naik video",
      uploadText: "Menyokong format MP4 sahaja, saiz tidak melebihi 20M",
      videoEmptyOrUploading: "Video kosong atau dimuat naik",
      customEmoji: "Ungkapan",
    },
    massageConfiguration: {
      pushTarget: "Objektif pemberitahuan",
      customizePushTarget: "Sasaran tolak tersuai",
      sysUser: "Pengguna sistem semasa",
      sysRole: "Peranan sistem semasa",
      emailPushTarget: "Hantar sasaran",
      targetEmailsPlaceholder:
        "Sila masukkan e-mel anda dan gunakan koma Inggeris untuk memisahkannya",
      subject: "Nama mel",
      SDKID: "ID pengenalan SDK",
      content: "Kandungan pemberitahuan",
      sdkPushType: "Pelan tolak",
      sdkPushURL: "Tolak URL",
      pushData: "Tolak data",
      sdkPushWay: "Kaedah tolak",
      dataType: "Format data",
      additionalData: "Data tambahan",
      name: "Nama mesej",
      filter: "Tapis keadaan",
      triggerType: "Mekanisme pencetus",
      pushCycle: "Kekerapan tetap masa",
      pushContent: "Kandungan laporan",
      qualityList: "Senarai keputusan pemeriksaan kualiti",
      designated: "Kakitangan yang ditetapkan",
      messageType: "Jenis Mesej",
    },
    taskCard: {
      template: { cancelCollection: "Batalkan koleksi", collection: "Koleksi" },
    },
    semanticTagsSelect: { index: { standardSentence: "Kalimat standard:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Koleksi",
          calls: "Panggilan",
          createdBy: "Pengasas:",
          createdOn: "Buat masa:",
        },
        fileItem: {
          uIProvidesIcon: "Ui menyediakan ikon",
          minute: "Minit",
          callClip: "Klip panggilan",
          start: "Mula",
          End: "Tamat",
          employees: "Pekerja:",
          Customer: "Pelanggan:",
          sessionTime: "Masa sesi:",
          reasonForRecommendation: "Sebab yang disyorkan:",
          addedBy: "Tambah oleh:",
          addTime: "Tambah masa:",
        },
        modalAddFile: {
          caseTitle: "Tajuk kes",
          addSomeClipsToTheCaseLibrary:
            "Tambahkan beberapa serpihan ke perpustakaan kes",
          addToCaseBase: "Tambah ke Perpustakaan Kes",
          storedIn: "Disimpan di",
          pleaseSelect: "Sila pilih",
          reasonsForRecommendation: "Sebab yang disyorkan",
          pleaseEnter: "Sila masukkan",
        },
      },
      shareModal: {
        index: {
          customer: "Pelanggan:",
          employees: "Pekerja:",
          timeliness: "Ketepatan masa:",
          recordingLinkValidWithinDays:
            "Pautan rakaman sah dalam beberapa hari",
          sharingInstructions: "Berkongsi arahan:",
          shareSuccess: "Berkongsi kejayaan!",
          cancel: "Batalkan",
          share: "Berkongsi",
          copySucceeded: "Salin berjaya!",
          copyShareLink: "Salin pautan perkongsian",
          shareWithColleagues: "Berkongsi dengan rakan sekerja",
          shareWithCustomers: "Kongsi kepada pelanggan",
          staff: "Pekerja",
          selectColleagues: "Pilih rakan sekerja",
          pleaseSelectColleaguesToShare:
            "Sila pilih rakan sekerja untuk berkongsi",
          pleaseEnterTheSharingDescription: "Sila masukkan arahan perkongsian",
          taskName: "Tugas:",
          recordingShareValidWithinDays:
            "Kongsi pautan yang sah dalam beberapa hari",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Fail tidak dapat dijumpai!",
          sale: "Jualan:",
          customer: "Pelanggan:",
          topicDetection: "Pengesanan topik:",
          keyEvents: "Peristiwa utama:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Pilih rakan sekerja",
          helpContent: "Kandungan bantuan",
          visibleRange: "Julat yang boleh dilihat",
          seekCounseling: "Dapatkan kaunseling",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Dapatkan nasihat", concatText: "Ke" },
      },
      customerInsight: {
        index: {
          customerInformation: "Maklumat pelanggan",
          customerInsight: "Wawasan pelanggan",
          customerLabel: "Label pelanggan",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Cadangan bercakap",
          objectionHandlingScripts: "Pengendalian bantahan",
          productSellingPointScripts: "Kemahiran menjual produk",
          knowledgePointRecommendation: "Cadangan mata pengetahuan",
          relatedKnowledgePoints: "Titik pengetahuan berkaitan",
          recommendedCases: "Kes yang disyorkan",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Cadangan bercakap",
          caseRecommendation: "Cadangan kes",
          hit: "Hit",
          miss: "Tidak berjaya",
          supervisionItem: "Barang pengawasan",
          implementation: "Pelaksanaan",
          reInspection: "Pemeriksaan semula",
          supervisionItemSettings: "Tetapan item pengawasan",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Panggilan telefon",
          enterpriseWeChatCall: "Komunikasi Mikro Perusahaan",
          enterpriseMicroConversation: "Perbualan Mikro Perusahaan",
          open: "Buka",
          close: "Tutup",
          conversationScenario: "Adegan sesi",
          monitorConversationChannels: "Saluran sesi penyeliaan",
          permanentlyValid: "Berkesan secara kekal",
          permanentlyInvalid: "Tidak sah secara kekal",
          customValidity: "Tempoh sah tersuai",
          termOfValidity: "Tempoh sah",
          newRating: "Penilaian baru",
          whenConversation: "Semasa sesi",
          hit: "Hit",
          miss: "Tidak berjaya",
          time: "Masa",
          rated: "Penarafan adalah",
          qualified: "Berkelayakan",
          unqualified: "Tidak berkelayakan",
          modifiedSuccessfully: "Pengubahsuaian berjaya",
          classificationName: "Nama kategori",
          supervisionObject: "Objek pengawasan",
          entryintoforceTime: "Masa berkuat kuasa",
          supervisionItemRating: "Penilaian item penyeliaan",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Sila periksa sama ada penilaian item pengawasan betul",
          ratingRules: "Peraturan penarafan",
          settingOfRatingRulesForSupervisionItems:
            "Tetapan peraturan penilaian item penyeliaan",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Peristiwa kritikal",
          supervisionItem: "Barang pengawasan",
          conversationScenario: "Adegan sesi",
          customerLabel: "Label pelanggan",
          reDetectionRules: "Mengesan semula peraturan",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Muat turun rakaman",
          downloadText: "Muat turun teks",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Muat naik gambar",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Sila pilih gambar yang tidak melebihi saiz 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Atau",
            and: "Dan",
            wrong: "Bukan",
            matchTo: "Padankan ke",
            rule: "Peraturan",
            satisfactionOperator: "Pengendali yang memuaskan",
            customerLabel: "Label pelanggan",
            keyEvents: "Peristiwa kritikal",
            knowledgePoints: "Titik pengetahuan",
            sessionLabel: "Label sesi",
            supervisionItem: "Barang pengawasan",
          },
          linkExplorer: { linkInTheText: "Pautan dalam teks:" },
        },
      },
    },
    createForm: {
      field: { customComponentNotFound: "Komponen tersuai tidak dijumpai:" },
    },
    sdkLoaderBase: {
      component: { custom: "Adat" },
      dropdownMonit: {
        updateSucceeded: "Kemas kini berjaya",
        areYouSureToDelete: "Adakah anda pasti akan menghapusnya?",
        custom: "Adat",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Simpan konfigurasi laporan semasa === Borang",
        saveCurrentReportConfiguration: "Simpan konfigurasi laporan semasa",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Sila masukkan nama tetapan biasa, panjang maksimum ialah 20!",
        preservation: "Simpan",
      },
      template: {
        saveCurrentReportConfiguration: "Simpan konfigurasi laporan semasa",
        updateCurrentReportConfiguration:
          "Kemas kini konfigurasi laporan semasa",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Maklumat pesanan kerja",
        workOrder: "Perintah kerja",
        triggerScenario: "Adegan pencetus",
        associatedCallConversation: "Panggilan/perbualan yang berkaitan",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Uji dengan teks lulus/dialog semasa",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "No.", day: "Hari" },
      businessSummary: {
        normal: {
          equalTo: "Sama dengan",
          notEqualTo: "Tidak sama dengan",
          contain: "Mengandungi",
          excluding: "Tidak mengandungi",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Saya adalah saya, bunga api yang berbeza @ 156xxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hai, saya adalah pemberitahuan amaran pemeriksaan kualiti robot, kerana ditambahkan ke kumpulan pada 17 Oktober",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Saya adalah saya, bunga api yang berbeza @ 156xxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hai, saya adalah pemberitahuan amaran pemeriksaan kualiti robot, kerana ditambahkan ke kumpulan pada 17 Oktober",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Tema komunikasi",
          conversationSummary: "Ringkasan sesi",
          serviceSuggestions: "Nasihat perkhidmatan",
        },
        template: { smartTags: "Label pintar" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Klik untuk mengetahui lebih lanjut" },
      schema: {
        textRobot: "Robot teks",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Pengawal pelatih, soal jawab dokumen, dialog tugas, soal jawab borang, soal jawab grafik",
        capitalRetentionRobot: "Robot pengekalan",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Strategi pengekalan susulan yang kaya, komunikasi antropomorfik yang tinggi, memastikan kadar pengekalan yang tinggi, dan penukaran petunjuk yang cekap dan automatik",
        outboundRobot: "Robot keluar",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Kunjungan kembali ke akhir pesanan kerja, tinjauan kepuasan perkhidmatan, peringatan kegagalan operasi dan penyelenggaraan, peringatan penghantaran pesanan, pemberitahuan aktiviti e-dagang, janji temu calon, membantu automasi perniagaan dan peningkatan kecekapan perniagaan",
        incomingRobot: "Hembuskan ke dalam robot",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Penyatuan sistem perniagaan yang fleksibel seperti pesanan kerja dan pesanan untuk mewujudkan automasi proses; pengenalan model semantik suara yang disesuaikan, kesan perniagaan segera; entiti (nombor telefon bimbit, jumlah pesanan, masa pesanan, alamat,...) Mengenal pasti, menyokong perniagaan yang kompleks dengan cepat",
        intelligentQualityInspection: "Pemeriksaan kualiti pintar",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Pemeriksaan kualiti penuh, tepat pada masanya dan cekap, kadar ketepatan 85%",
        intelligentSessionAnalysis: "Analisis Sesi Pintar",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Wawasan mengenai kekurangan perniagaan, dengarkan suara pelanggan, dan tingkatkan transformasi pemasaran",
        enterpriseWechatConversationAnalysis:
          "Analisis perbualan WeChat perusahaan",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Mengenal pasti peluang dan risiko perniagaan yang berpotensi dengan cekap, dan meningkatkan kadar penukaran pemasaran sebanyak 20%",
        assistant: "Pembantu tempat duduk",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Pembantu ejen dapat memastikan bahawa perkhidmatan pelanggan pemula menyelesaikan perkhidmatan dan meningkatkan kualiti panggilan perkhidmatan pelanggan yang mahir. Biarkan setiap tempat duduk menjadi kad truf anda",
        digitalPeople: "Orang digital",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          'Komunikasi antropomorfik "tatap muka", menyokong interaksi multi-modal suara, teks, video, dan sentuhan, mewujudkan pengalaman komunikasi yang mendalam, meningkatkan imej jenama dan pengalaman perkhidmatan pengguna',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Pengetahuan AI di Taiwan · Pencarian Perusahaan",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Pencarian pengetahuan, perkongsian pengetahuan, carian dokumen, carian dok pangkalan data",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Pengetahuan AI Zhongtai · Pangkalan Pengetahuan KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Menyediakan pengurusan kitaran hidup penuh pengetahuan, penyelenggaraan robot dan pangkalan pengetahuan IM yang disatukan, sokongan untuk menghubungkan pangkalan pengetahuan pihak ketiga, templat pengetahuan, pengetahuan atom dan fungsi kuat lain untuk membawa pengalaman pengurusan pengetahuan yang lebih mudah",
        aIKnowledgeCenterKnowledgeMap:
          "Pusat Pengetahuan AI · Atlas Pengetahuan",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "Pembinaan peta sehenti mengintegrasikan pengetahuan korporat, gambar besar memaparkan perincian pengetahuan, dan kemampuan dok untuk mencipta pengalaman yang disesuaikan",
        modularDevelopmentPlatformOriginalEngineASR:
          "Platform Pembangunan Modular · Enjin Asal · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Menggunakan algoritma end-to-end untuk mencapai pengalaman kesan penyesuaian perniagaan, kadar pengecaman pemandangan semula jadi melebihi 90%, dan pengecaman perbendaharaan kata yang diperibadikan melebihi 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Platform pembangunan modular · Pemasaran pintar",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Gunakan algoritma pembelajaran mendalam, digabungkan dengan potret pengguna, untuk mencapai cadangan produk yang tepat dan meningkatkan penukaran penjualan",
        modularDevelopmentPlatformUserPortrait:
          "Platform pembangunan modular · Potret pengguna",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Bina potret pengguna dengan tepat berdasarkan data pengguna dan data dialog, dan terangkan sepenuhnya ciri pelanggan",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Platform pembangunan modular · Cadangan pintar",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Mengikut potret pengguna dan potret produk, secara automatik mengesyorkan produk yang mungkin diminati oleh pengguna, dan menyedari cadangan yang diperibadikan oleh ribuan orang",
        modularDevelopmentPlatformNLPPAAS:
          "Platform Pembangunan Modular · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Berikan kebolehan asas NLP yang tepat, merangkumi kebolehan asas dengan butiran yang berbeza seperti kata, ayat, dan artikel",
      },
    },
    qualityScore: {
      index: {
        show: "Tunjukkan:",
        displayBySecondaryClassification:
          "Paparan mengikut klasifikasi sekunder",
        showByRule: "Tunjukkan mengikut peraturan",
        pleaseEnterClassificationruleName:
          "Sila masukkan nama kategori/peraturan",
        clickCopy: "Klik untuk menyalin",
        hitDetailsOfOperatorsInRules:
          "Pengendali dalam peraturan memukul perincian:",
        hit: "Hit",
        miss: "Tidak berjaya",
        filter: "Penapis:",
        clickToViewDetails: "Klik untuk maklumat terperinci",
      },
      components: {
        flowNodeList: {
          branch: "Mata",
          pleaseSelect: "Sila pilih",
          viewTheCompleteProcess: "Lihat proses lengkap",
          thereIsCurrentlyNoProcessAvailable: "Tiada proses",
        },
        pointItem: { xiangHit: "Hit item" },
        flowNodeListModal: { index: { completeProcess: "Proses lengkap" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Sekiranya tugas pemeriksaan kualiti proses berubah, mohon banding lagi.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Data telah dibersihkan secara berkala",
        subject: "Subjek:",
        description: "Penerangan:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask:
              "Tambahkan ke tugas pemeriksaan kualiti",
          },
        },
      },
    },
    relatedDetail: {
      index: {
        associatedCallConversation: "Panggilan/perbualan yang berkaitan",
      },
    },
    samplingTarget: {
      index: {
        time: "Masa",
        samplingQuantityIsLessThan:
          "Jumlah pemeriksaan rawak lebih rendah daripada",
        alertWhen: "Amaran awal",
        everyday: "Setiap hari",
        weekly: "Setiap minggu",
        monthly: "Setiap bulan",
        samplingInspectionTargetSavedSuccessfully:
          "Sasaran persampelan berjaya disimpan",
        personnel: "Kakitangan",
        eachCustomerService: "Setiap perkhidmatan pelanggan",
        cycle: "Kitaran",
        samplingInspectionTarget: "Sasaran persampelan",
        strip: "Artikel",
        alertSettings: "Tetapan amaran awal",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Perkhidmatan Pelanggan",
        complete: "Selesai",
        Objective: "/Matlamat",
        everyday: "Setiap hari",
        weekly: "Setiap minggu",
        monthly: "Setiap bulan",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Kadar interaksi sembang kumpulan",
        maximumResponseIntervalTime: "Selang tindak balas terpanjang",
        numberOfPreemptiveCalls: "Bilangan perbualan",
        numberOfMessages: "Bilangan mesej",
        strip: "Artikel",
        proportionOfMessages: "Bahagian mesej",
        speechPercentage: "Bercakap",
        longestCustomerTalk: "Pelanggan terpanjang bercakap",
        numberOfIndepthDiscussions: "Bilangan perbincangan mendalam",
        second: "Kali",
        maximumDurationOfContinuousSpeech:
          "Tempoh bercakap berterusan yang paling lama",
        questionFrequency: "Kekerapan soalan",
        customer: "Pelanggan",
        keyEvents: "Peristiwa utama:",
        opportunity: {
          index: {
            generationFailed: "Kegagalan penjanaan",
            generateOpportunityPoints: "Menjana titik peluang",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Kegagalan penjanaan",
            generateSummary: "Menjana ringkasan",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Adakah anda pasti akan menghapusnya?",
        add: "Tambah",
        opportunityPoints: "Titik peluang",
        conversationSummary: "Ringkasan sesi",
        cancel: "Batalkan",
        determine: "OK",
        edit: "Edit",
        establish: "Buat",
        conversationalInsights: "Wawasan sesi",
        keyEvents: "Peristiwa kritikal",
        numKeyEvents: "Acara utama",
        questionAnalysis: "Analisis soalan",
        find: "Cari",
        questions: "Soalan",
        conversationAction: "Tindakan sesi",
        customer: "Pelanggan",
        sale: "Jualan",
        sessionLabel: "Adegan sesi",
        employeeQuestions: "Soalan pekerja",
        customerQuestions: "Soalan pelanggan",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Pelanggan",
        customerService: "Perkhidmatan Pelanggan",
        replyTo: "Balas ke",
        addressee: "Penerima:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Pengiktirafan semula",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Pengiktirafan semula akan memakan masa transkripsi, sila berhati-hati",
        determine: "OK",
        executionSuccessful: "Pelaksanaan yang berjaya",
        addToQualityInspectionTask: "Tambahkan ke tugas pemeriksaan kualiti",
        batchOperation: "Operasi kumpulan",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Tambah kejayaan",
        addToQualityInspectionTask: "Tambahkan ke tugas pemeriksaan kualiti",
        qualityInspectionTask: "Tugas pemeriksaan kualiti",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Peranan pengesanan",
        detectionRange: "Julat pengesanan",
        sentence: "Ayat",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Data telah dibersihkan secara berkala",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Kembali ke sistem perniagaan" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Masalah pengekstrakan",
        businessSummary: "Ringkasan perniagaan",
        keyWord: "Kata kunci",
      },
      components: {
        problemExtraction: {
          no: "Tidak",
          customerServiceDidNotAnswer: "Perkhidmatan pelanggan tidak dijawab",
          customerServiceHasAnswered: "Perkhidmatan pelanggan telah menjawab",
          problemUnresolved: "Masalah yang tidak dapat diselesaikan",
          theProblemHasBeenResolved: "Masalah diselesaikan",
          customerIssue: "Soalan pelanggan:",
          customerServiceAnswer: "Jawapan perkhidmatan pelanggan:",
          reExtractQuestions: "Masalah pengekstrakan semula",
          clickToExtractQuestions: "Klik untuk mengekstrak soalan",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Nama potret",
            backgroundDescription: "Penerangan latar belakang",
            customerLabel: "Label pelanggan",
            labelManagement: "Pengurusan Label",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Baru" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Tetapan susulan" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Meningkatkan soalan susulan",
              remove: "Keluarkan",
              addQuestioning: "Tambah soalan",
              section: "No.",
              secondInquiry: "Soalan kedua",
              questioningScore: "Skor susulan",
            },
          },
        },
        index: {
          accordingToRules: "Mengikut peraturan",
          byScore: "Tekan skor",
          whenMissRule: "Apabila peraturan gagal",
          whenConductingQuestioning: "Semasa menyoal",
          pleaseSelectARule: "Sila pilih peraturan",
          ruleCannotBeEmpty: "Peraturan tidak boleh kosong",
          currentScriptScore: "Apabila skor bercakap ≤",
          pleaseEnterTheScore: "Sila masukkan skor",
          scoreCannotBeEmpty: "Skor tidak boleh kosong",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Pencerita:" },
          pPT: { slide: "Slaid" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Komen Bercakap",
              script: "Bercakap",
              semantics: "Semantik",
              violatingWords: "Perkataan pelanggaran",
              speechSpeed: "Kelajuan bercakap",
              emotion: "Emosi",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Komen Bercakap",
              regenerate: "Menjana semula",
              generate: "Menjana",
              hitSituation: "Keadaan hit",
              noSemanticTagsMatched: "Tidak sepadan dengan label semantik",
              matchToSemanticTags: "Padankan dengan label semantik",
              appealToBeSubmitted: "Rayuan akan dihantar",
              ratingHasBeenReviewed: "Skor telah dikaji",
              ratingAppealInProgress: "Rayuan pemarkahan",
              expressionScore: "Skor ekspresi",
              moodParticlesTimes: "Perkataan udara, {0} kali",
              changeTheModalParticlesTo: "Perkataan udara berubah menjadi",
              second: "Kali",
              interrogation: "Soalan",
              questioningWithoutScoring: "Tidak mendapat markah",
              branch: "Mata",
              matchingRate: "Kadar padanan",
              ratingAppeal: "Rayuan skor",
              changeTo: "Tukar kepada",
              wordPerMinute: "Perkataan setiap minit",
              completeScript: "Kemahiran bercakap lengkap",
              incompleteScript: "Kemahiran bercakap tidak lengkap",
              semanticHit: "Hit semantik",
              semanticMisses: "Semantik gagal",
              scriptViolation: "Pelanggaran kata-kata",
              modified: "Diubah suai",
              notChanged: "Tidak diubah suai",
              keyWord: "Kata kunci",
              semanticLabels: "Label semantik",
              semanticMatchingRate: "Kadar padanan semantik",
              violatingWords: 'Perkataan pelanggaran"',
              delete: "Padamkan",
              unmatched: "Tidak sepadan",
              matching: "Perlawanan",
              matchTo: "Padankan ke",
              notMatchedTo: "Tidak sepadan dengan",
              scriptScore: "Skor perkataan",
              standardScript: "Kemahiran bercakap standard",
              emotionalScore: "Skor emosi",
              speechSpeedScore: "Skor kadar pertuturan",
              viewResolution: "Lihat analisis",
              nodeConfiguration: "Konfigurasi nod",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Masa jawapan untuk soalan ini sudah tamat, dan isi jawapan dihantar secara automatik",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Masa jawapan untuk soalan ini sudah tamat, dan isi jawapan dihantar secara automatik",
          },
          correctBtn: {
            saveSuccessful: "Simpan dengan jayanya",
            errorCorrection: "Pembetulan ralat",
            cancel: "Batalkan",
            preserve: "Simpan",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Peraturan pemarkahan",
            cancel: "Batalkan",
            determine: "OK",
          },
          scoringRuleItem: {
            whole: "Semua",
            arbitrarily: "Mana-mana",
            pleaseEnterARuleName: "Sila masukkan nama peraturan",
            standardScript: "Kemahiran bercakap standard",
            if: "Jika",
            and: "Dan",
            or: "Atau",
            thenScore: "Markah",
            pleaseEnterAScore: "Sila masukkan skor",
            bonusPoints: "Mata bonus",
            minusPoints: "Kurangkan mata",
            hit: "Hit",
            misses: "Tidak berjaya",
            keyWord: "Kata kunci",
            semantics: "Semantik",
          },
          title: {
            ruleValidation: "Pengesahan peraturan",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Peraturan pemarkahan (jumlah skor semasa adalah",
            points: "Mata)",
            editRules: "Peraturan penyuntingan",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "Tiada label" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Matlamat komunikasi",
              scoringCriteria: "Kriteria pemarkahan",
            },
          },
        },
        index: {
          generating: "Dihasilkan",
          generateDialogueFramework: "Menjana rangka dialog",
          theme: "Tema",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Peringatan",
            generatePromptLanguage: "Menjana arahan",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Fasa komunikasi",
          communicationObjectives: "Matlamat komunikasi",
          scoringCriteria: "Kriteria pemarkahan",
          suggestionsForImprovement: "Cadangan promosi",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Skor proses",
            contentIntegrity: "Integriti kandungan:",
            NumberOfCompletedProcesses: "%, Bilangan proses yang diselesaikan",
            keyHitPointsTotal: "Hit utama: jumlah",
            notReached: "Tidak tercapai",
            accurateFollowupAccumulationOfStandardScripts:
              "Bacaan susulan yang tepat: pengumpulan pertuturan standard",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Perkataan, pelajar dan jumlah perkataan dalam bacaan",
            aWord: "Perkataan",
            languageNormsYes: "Kod bahasa: Ya",
            emotionsAppearing: "Emosi: muncul",
            speechSpeedSpeakingTooFast:
              "Kelajuan bercakap: kelajuan bercakap terlalu cepat",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Perkataan udara: terlalu banyak perkataan udara muncul",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Tambah ke label Abaikan",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Adakah anda pasti mahu melaksanakan semula tag pintar?",
        reExecute: "Pelaksanaan semula",
      },
      component: { successfullyAdded: "Tambah kejayaan" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Tutup semua pemberitahuan",
        reQualityInspectionTask: "Tugas pemeriksaan semula",
        requalityInspection: "Pemeriksaan semula",
        waiting: "Menunggu",
        viewDetails: "Lihat butiran",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Masa ujian:" } },
        },
        template: { responseOpeningSentence: "Kalimat permulaan jawapan" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Kegagalan penjanaan",
            generate: "Menjana",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Tiada data" } },
      },
      index: { regenerate: "Menjana semula" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Adakah anda pasti akan menghapusnya?",
        operationSuccessful: "Operasi yang berjaya",
      },
      template: { delete: "Padamkan" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Sila pilih soalan!",
        similarQuestionRecommendation: "Cadangan soalan serupa",
        selectAllOnPage: "Pilih semua halaman semasa",
        cancel: "Batalkan",
        preserve: "Simpan",
        changeBatch: "Tukar kumpulan",
      },
    },
    listControl: { index: { columnControl: "Kawalan lajur" } },
    loginUserAvatar: { index: { switch: "Tukar" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Semasa menambahkan data dalam masa nyata, pelaksanaan tugas pemeriksaan kualiti masih memerlukan waktu yang lama. Waktu tertentu bergantung pada jumlah data pemeriksaan kualiti, mulai dari beberapa minit hingga 2 jam.",
      },
    },
    bEditor: {
      index: {
        delete: "Padamkan",
        cancel: "Batalkan",
        confirm: "OK",
        insert: "Masukkan",
        width: "Lebar",
        height: "Ketinggian",
        clearContent: "Kosongkan kandungan",
        revoked: "Dibatalkan",
        redo: "Buat semula",
        fontSize: "Saiz fon",
        rowHeight: "Garis tinggi",
        wordSpacing: "Jarak perkataan",
        paragraphIndent: "Lekukan perenggan",
        increaseIndent: "Meningkatkan lekukan",
        decreaseIndent: "Kurangkan lekukan",
        border: "Sempadan",
        color: "Warna",
        textColor: "Warna teks",
        backgroundColor: "Warna latar belakang",
        temporaryColor: "Warna sementara",
        bold: "Tebal",
        italic: "Condong",
        underline: "Garis bawah",
        strikethrough: "Padamkan garisan",
        font: "Fon",
        textAlignment: "Penjajaran teks",
        left: "Kiri",
        centered: "Berpusat",
        right: "Betul",
        bothEnds: "Kedua-dua hujung",
        leftFloat: "Terapung ke kiri",
        rightFloat: "Terapung ke kanan",
        subrupt: "Superskrip",
        subscript: "Langganan",
        clearStyle: "Kosongkan gaya",
        title: "Tajuk",
        regular: "Rutin",
        orderedList: "Senarai pesanan",
        unorderedList: "Senarai tidak teratur",
        reference: "Petikan",
        code: "Kod",
        link: "Pautan",
        clearLink: "Kosongkan pautan",
        horizontalLine: "Garisan mendatar",
        media: "Media",
        mediaLibrary: "Perpustakaan media",
        smallExpression: "Ungkapan kecil",
        fullScreen: "Skrin penuh",
        exitFullScreen: "Keluar dari skrin penuh",
        enterLinkText: "Masukkan teks pautan",
        enterLinkAddress: "Masukkan alamat pautan",
        enterTheLinkAddressAndPressEnter:
          "Masukkan alamat pautan dan tekan Enter",
        openInNewWindow: "Buka dalam tetingkap baru",
        removeLink: "Keluarkan pautan",
        playingAudioFiles: "Mainkan fail audio",
        playVideoFiles: "Mainkan fail video",
        embeddedMedia: "Media terbenam",
        image: "Imej",
        video: "Video",
        audio: "Audio",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Nama",
        description: "Penerangan",
        type: "Jenis",
        status: "Status",
        actions: "Operasi",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Nama tugas",
        description: "Penerangan",
        form: "Borang pemeriksaan kualiti",
        actions: "Operasi",
        createUser: "Pengasas",
      },
      samplingRecords: {
        createTime: "Masa",
        type: "Jenis",
        operateUser: "Pengendali",
        number: "Tambah kuantiti",
        filterConditionList: "Tapis keadaan",
        sampleRuleStr: "Keadaan persampelan",
        timeGreaterThan: "Masa lebih besar daripada",
        timeLessThan: "Masa kurang daripada",
      },
    },
    customFilters: {
      createdAt: "Masa bercakap",
      customerId: "Pelanggan",
      agentId: "Perkhidmatan Pelanggan",
      userGroupId: "Kumpulan Perkhidmatan Pelanggan",
      state: "Status",
      lastEvaluateUserId: "Pemarkahan terakhir",
      submitTime: "Masa penghantaran terakhir",
      totalScore: "Jumlah markah",
      formItemForbidIdList: "Item yang dilarang",
      formItemDeadlyIdList: "Barang maut",
    },
    samplingInspection: {
      callTime: "Masa bercakap",
      dialogTime: "Masa perbualan",
      originator: "Orang yang ditugaskan",
      createTime: "Buat masa",
      updateTime: "Masa kemas kini",
      status: "Status proses",
      score: "Pecahan",
      actions: "Operasi",
      reviewer: "Pengulas",
      samplingOfficer: "Persampelan",
      id: "ID rekod panggilan",
      time: "Masa bercakap",
      phone: "Telefon pelanggan",
      staff: "Perkhidmatan Pelanggan",
      inspectionStatus: "Status pemeriksaan kualiti",
      samplingStatus: "Status persampelan",
      inspectionGrade: "Penilaian",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Perkataan standard",
        synonymWords: "Sinonim",
        synonymWordsNumber: "Sinonim",
        useFrequency: "Kekerapan penggunaan",
        effectiveness: "Keberkesanan",
        operation: "Operasi",
      },
      common: {
        useFrequency: "Kekerapan penggunaan",
        effectiveness: "Keberkesanan",
        operation: "Operasi",
      },
      professionalWords: {
        professionalWords: "Istilah profesional",
        useFrequency: "Statistik penggunaan",
        homophonic: "Kata-kata panas (penggantian homofon)",
        target: "Penggantian kata nama",
        targetWords: "Perkataan pengganti",
        homophonyWords: "Kata pelindung homofon",
        operation: "Operasi",
      },
      attentionWords: "Ikut perkataan",
      ignoreWords: "Abaikan perkataan",
      wordsWeight: {
        selfDefineWeightWords: "Kata berat tersuai",
        weightWords: "Perkataan berat",
        weight: "Berat badan",
        useFrequency: "Kekerapan penggunaan",
        effectiveness: "Keberkesanan",
        operation: "Operasi",
      },
      corpusData: {
        corpusDescription: "Penerangan korpus",
        updateTime: "Masa kemas kini",
        wordsCount: "Bilangan perkataan",
        effectiveness: "Keberkesanan",
        operation: "Operasi",
        corpusContent: "Kandungan",
      },
    },
    semanticTags: {
      title: "Label semantik",
      tags: "Ayat standard",
      orgName: "Organisasi gabungan",
      tagCount: "Bilangan latihan",
      usingCount: "Bilangan rujukan",
      activeFlag: "Keberkesanan",
      suggestion: "Nasihat latihan",
      actions: "Operasi",
    },
    intelligentTags: {
      tagName: "Nama",
      tagDesc: "Penerangan",
      tagRefCnt: "Bilangan tanda",
      categoryName: "Pengelasan label",
      eventName: "Pengelasan acara",
      tagStatus: "Keberkesanan",
      actions: "Operasi",
      tagType: "Jenis label",
    },
    dataPreprocess: {
      name: "Nama",
      desc: "Penerangan",
      dataSource: "Had sumber data",
      type: "Jenis",
      status: "Keberkesanan",
      actions: "Operasi",
    },
    informationEntities: {
      name: "Nama",
      apiName: "Nama API",
      type: "Jenis",
      remark: "Penerangan",
      activeFlag: "Keberkesanan",
      actions: "Operasi",
    },
    interactiveRecognition: {
      questionAndAnswer: "Soalan & Jawapan Standard",
      type: "Jenis",
      status: "Status",
      actions: "Operasi",
      question: "Soalan",
    },
    role: { id: "ID", name: "Nama", actions: "Operasi" },
    uploadDownload: {
      taskName: "Nama fail",
      createTime: "Tarikh",
      size: "Saiz",
      createUserName: "Pengendali",
      status: "Status",
      result: "Keputusan",
      actions: "Operasi",
    },
    taskCenterList: {
      name: "Kandungan tugas",
      createTime: "Masa penghantaran",
      startTime: "Masa mula",
      endTime: "Masa tamat",
      createUserName: "Pengendali",
      status: "Status",
      result: "Keputusan",
      actions: "Operasi",
    },
    customerInformation: {
      title: "Maklumat Perkhidmatan Pelanggan",
      data: "Lihat data pengecualian sahaja",
      list: {
        agentNo: "Nombor Kerja/ID Perkhidmatan Pelanggan",
        agentName: "Perkhidmatan Pelanggan",
        agentTel: "Telefon",
        agentEmail: "Peti mel",
        updateTime: "Masa kemas kini",
        actions: "Operasi",
      },
      upload: {
        second:
          "2. Klik butang import untuk memuat naik fail EXCEl yang sesuai dengan format templat",
      },
    },
    IndexManagement: {
      title: "Pengurusan penunjuk",
      list: {
        indicatorName: "Nama",
        indicatorDesc: "Penerangan",
        activity: "Keberkesanan",
        inspectDataSource: "Sumber data",
        sysType: "Jenis",
        agentGroups: "Kumpulan Perkhidmatan Pelanggan",
        actions: "Operasi",
      },
      detail: {
        nameEmpty: "Nama tidak boleh kosong",
        descript: "Penerangan",
        descriptEmpty: "Penerangan tidak boleh kosong",
        active: "Keberkesanan",
        activeEmpty: "Kesahan tidak boleh kosong",
        dataSource: "Sumber data",
        dataSourceEmpty: "Sumber data tidak boleh kosong",
        grounp: "Kumpulan Perkhidmatan Pelanggan",
        grounpEmpty: "Kumpulan perkhidmatan pelanggan tidak boleh kosong",
        grounpHolder: "Sila pilih kumpulan perkhidmatan pelanggan",
        type: "Kaedah pemarkahan",
        typeNone: "Kaedah pemarkahan tidak boleh kosong",
        base: "Mata asas",
        baseNone: "Skor asas tidak boleh kosong",
        positive: "Faktor positif",
        positiveNone: "Faktor positif tidak boleh kosong",
        customAdd: "Tambah penapis",
        negative: "Faktor negatif",
        negativeNone: "Faktor negatif tidak boleh kosong",
        customOK: "Tapis mengikut:",
        judgeStrategy: "Peraturan keadaan:",
        other: "Faktor lain",
        otherAdd: "Tambah faktor",
        agent: "Data mentah",
        section: "Bilangan selang",
        sectionNone: "Bilangan selang tidak boleh kosong",
        step: "Panjang langkah selang",
        stepNone: "Panjang langkah selang",
        save: "Simpan",
        cancel: "Batalkan",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Semua",
      staff: "Hanya saya",
      department: "Jabatan ini dan di bawah",
    },
    gongStatisticsDate: { week: "Minggu", month: "Bulan", season: "Musim" },
    gongCoachTaskStatus: {
      wait: "Menunggu kaunseling",
      already: "Telah diajar",
      ignore: "Tidak diendahkan",
    },
    callRangeStatus: {
      all: "Semua panggilan",
      sale: "Permulaan pekerja",
      customer: "Permulaan pelanggan",
    },
    todoListType: {
      later: "Dengar kemudian",
      help: "Kaunseling untuk pertolongan",
      contact: "Hubungi kemudian",
      send: "Hantar maklumat",
      warn: "Amaran risiko",
    },
    frequencyTypes: {
      everyDay: "Setiap hari",
      everyWeek: "Setiap minggu",
      everyMonth: "Setiap bulan",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Latihan data yang tidak dikategorikan",
        markedDataCorrigenda: "Errata data ditandakan",
        classifiedDataCheck: "Pengesahan data yang dikelaskan",
        markedHistoryRecord: "Tandakan sejarah",
        historyDataRecalculate: "Pengiraan semula data sejarah",
      },
      dataExtractMode: {
        random: "Sepenuhnya rawak",
        intelligentRecommend: "Cadangan pintar",
        custom: "Adat",
        specifiedClass: "Kategori yang ditentukan",
      },
      smartRatesList: {
        markRate: "Kadar pelabelan",
        corrigendaRate: "Kadar errata",
        kl: "Penyebaran K-L",
        signalNoiseRate: "Nisbah isyarat-ke-bising",
      },
    },
    visibleRangeTypes: {
      all: "Semua",
      customerGroup: "Kumpulan Perkhidmatan Pelanggan",
      userDefined: "Adat",
    },
    samplingRateTypes: {
      everyDay: "Ekstrak setiap hari",
      everyWeek: "Ekstrak mingguan",
    },
    taskRemindTypes: {
      confirm:
        "Hantar peringatan apabila rekod tugas pemeriksaan kualiti perlu disahkan",
      appeal:
        "Rekod tugas pemeriksaan kualiti Semasa memulakan rayuan, hantarkan peringatan",
    },
    weekDays: {
      monday: "Isnin",
      tuesday: "Selasa",
      wednesday: "Rabu",
      thursday: "Khamis",
      friday: "Jumaat",
      saturday: "Sabtu",
      sunday: "Ahad",
    },
    reportDateTypes: {
      dailyReport: "Laporan harian",
      weeklyReport: "Laporan mingguan",
    },
    samplingRangeDayTypes: { today: "Hari ini", yesterday: "Semalam" },
    samplingRangeWeekTypes: {
      thisWeek: "Minggu ini",
      lastWeek: "Minggu lepas",
    },
    samplingRuleTypes: {
      proportion: "Persampelan berkadar",
      random: "Persampelan rawak",
    },
    taskStatusTypes: {
      waitEvaluate: "Untuk dinilai",
      waitSubmit: "Untuk dihantar",
      waitConfirm: "Untuk disahkan",
      waitReview: "Untuk disemak",
      completed: "Selesai",
      all: "Semua",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Skor langsung",
        weightedScore: "Skor berat",
        deductScore: "Potongan mata",
        directScoreSecondLevel: "Skor Langsung-Tahap 2",
        weightedScoreSecondLevel: "Skor Berat-Tahap 2",
        deductScoreSecondLevel: "Potongan-Tahap 2",
      },
    },
    fieldOperators: {
      is: "Sama dengan",
      not: "Tidak sama dengan",
      contains: "Mengandungi",
      notContains: "Tidak mengandungi",
      not_contains: "Tidak mengandungi",
      containsAny: "Mengandungi sewenang-wenangnya",
      contains_any: "Mengandungi sewenang-wenangnya",
      is_any: "Mengandungi sewenang-wenangnya",
      not_any: "Tidak mengandungi sewenang-wenangnya",
      notContainsAny: "Tidak mengandungi sewenang-wenangnya",
      not_contains_any: "Tidak mengandungi sewenang-wenangnya",
      containsAll: "Mengandungi semua",
      contains_all: "Mengandungi semua",
      notContainsAll: "Tidak mengandungi semua",
      nont_contains_all: "Tidak mengandungi semua",
      isNull: "Kosong",
      is_null: "Kosong",
      isNotNull: "Tidak kosong",
      is_not_null: "Tidak kosong",
      greaterThan: "Lebih besar daripada",
      greater_than: "Lebih besar daripada",
      greaterThanEq: "Lebih besar daripada atau sama",
      greater_than_eq: "Lebih besar daripada atau sama",
      lessThan: "Kurang daripada",
      less_than: "Kurang daripada",
      lessThanEq: "Kurang daripada atau sama",
      less_than_eq: "Kurang daripada atau sama",
      between: "Antara",
      notBetween: "Tidak antara",
      not_between: "Tidak antara",
      relative: "Sama dengan",
      set: "Tetapkan ke",
      belongs: "Milik",
      notBelongs: "Bukan milik",
      not_belongs: "Tidak sama dengan",
      today: "Hari ini",
      yesterday: "Semalam",
      the_day_before_yesterday: "Sehari sebelum semalam",
      tomorrow: "Esok",
      next_seven_day: "Tujuh hari akan datang",
      last_seven_day: "Tujuh hari terakhir",
      this_week: "Minggu ini",
      next_week: "Minggu depan",
      last_week: "Minggu lepas",
      this_month: "Bulan ini",
      next_month: "Bulan depan",
      last_month: "Bulan lalu",
      this_year: "Tahun ini",
      next_year: "Tahun depan",
      last_year: "Tahun lepas",
      exact_match: "Perlawanan tepat",
      add_value: "Nilai tambah baru",
      remove_value: "Padam nilai",
      set_null: "Kosongkan",
      isAny: "Mana-mana sama dengan",
      notAny: "Sewenang-wenangnya tidak sama dengan",
      belongTo: "Milik",
      notBelongTo: "Bukan milik",
      hasAny: "Mengandungi sewenang-wenangnya",
      notHasAny: "Tidak mengandungi sewenang-wenangnya",
      prefixContains: "Permulaan sama dengan",
      prefixNotContains: "Permulaan tidak sama dengan",
      suffixContains: "Akhir adalah sama dengan",
      suffixNotContains: "Akhir tidak sama dengan",
      nextSevenDay: "7 hari akan datang",
      lastSevenDay: "7 hari terakhir",
      thisWeek: "Minggu ini",
      nextWeek: "Minggu depan",
      lastWeek: "Minggu lepas",
      thisMonth: "Bulan ini",
      nextMonth: "Bulan depan",
      lastMonth: "Bulan lalu",
      thisYear: "Tahun ini",
      nextYear: "Tahun depan",
      lastYear: "Tahun lepas",
      after: "Lewat (termasuk hari)",
      before: "Lebih awal daripada (termasuk hari)",
      allMatching: "Semua perlawanan",
      allNotMatching: "Semua tidak sepadan",
      hit: "Hit",
      not_hit: "Tidak berjaya",
    },
    fieldsOperators: {
      is: "Sama dengan",
      is_null: "Kosong pada masa yang sama",
      head_equal: "Permulaan sama dengan",
      tail_equal: "Akhir adalah sama dengan",
      is_not_null: "Tidak kosong pada masa yang sama",
      not: "Tidak sama dengan",
      diff_equal: "Perbezaan sama dengan",
      diff_greater_than: "Perbezaan lebih besar daripada",
      diff_greater_than_eq: "Perbezaannya lebih besar daripada atau sama",
      diff_less_than: "Perbezaannya kurang daripada",
      diff_less_than_eq: "Perbezaannya kurang daripada atau sama dengan",
      hour_equal: "Jam yang sama",
      day_equal: "Pada hari yang sama",
      week_equal: "Minggu yang sama",
      month_equal: "Bulan yang sama",
      year_equal: "Tahun yang sama",
      day: "Hari",
      minute: "Minit",
      hour: "Jam",
      part_equal: "Bahagian sama dengan",
    },
    samplingColumnCaptions: {
      created_at: "Masa bercakap",
      agent_id: "Perkhidmatan Pelanggan",
      get_direction: "Jenis Panggilan",
      call_result: "Keputusan Panggilan",
      survey: "Penilaian",
      derived_from_id: "Sumber",
      quit_queue_why: "Status beratur",
      queuing_duration: "Beratur memakan masa",
      talking_seconds: "Tempoh panggilan",
      ringing_seconds: "Masa loceng",
      hangup_by: "Pihak tutup panggilan",
    },
    callTypes: {
      callIn: "Panggilan masuk",
      callOut: "Panggilan keluar",
      threeParty: "(Tiga pihak)",
      consultation: "(Perundingan)",
      insert: "(Palam kuat)",
      monitor: "(Pemantauan)",
      transfer: "(Pemindahan)",
      intercept: "(Memintas)",
      transferOutside: "(Pambungkan ke garis luar)",
      threePartyOutside: "(Garis luar tiga arah)",
      consultingOutside: "(Rujuk talian luar)",
    },
    callResults: {
      staffAnswer: "Jawapan perkhidmatan pelanggan",
      staffNotAnswer: "Perkhidmatan pelanggan tidak dijawab",
      customerAnswer: "Pelanggan menjawab",
      customerNotAnswer: "Pelanggan tidak dijawab",
      phoneBusy: "Telefon sibuk",
      phoneOffline: "Telefon luar talian",
      customerSpeedHangUp: "Pelanggan cepat",
      customerHangUp: "Pelanggan menutup",
      queueTimeout: "Beratur lebih masa",
      queueGiveUp: "Berhenti beratur",
      outlineAnswer: "Jawapan luar",
      outlineNotAnswer: "Garis luar tidak disambungkan",
      noChooseQueue: "Baris tidak dipilih",
    },
    defaultSurveys: {
      noEvaluation: "Tidak dinilai",
      noNeedToEvaluate: "Tiada penilaian yang diperlukan",
    },
    queueStates: {
      queueSuccess: "Berjaya dalam barisan",
      queueTimeout: "Beratur lebih masa",
      queueGiveUp: "Berhenti beratur",
      noStaffOnline: "Tiada perkhidmatan pelanggan dalam talian",
    },
    callHangers: {
      customer: "Pelanggan",
      staff: "Perkhidmatan Pelanggan",
      outline: "Luar",
    },
    workFlowTypes: {
      noAppealsMode: "Tiada mod rayuan",
      appealsMode: "Benarkan mod rayuan",
    },
    fieldCategories: {
      standardCondition: "Keadaan penapis standard",
      customerCondition: "Penapis yang ditentukan pengguna",
    },
    sampleTypes: {
      automatic: "Persampelan automatik",
      manual: "Persampelan manual",
    },
    defaultFields: {
      createdAt: "Masa bercakap",
      agentId: "Perkhidmatan Pelanggan",
      getDirection: "Jenis Panggilan",
      callResult: "Keputusan Panggilan",
      survey: "Penilaian",
      derivedFrom: "Sumber",
      quitQueueWhy: "Status beratur",
      queuingDuration: "Beratur memakan masa",
      talkingSeconds: "Tempoh panggilan",
      ringingSeconds: "Masa loceng",
      hangupBy: "Pihak tutup panggilan",
    },
    conditionFieldCategories: {
      fixedField: "Bidang tetap",
      customField: "Medan tersuai",
      secondLevelScore: "Pengelasan sekunder",
      formItemScore: "Item skor",
      forbids: "Item yang dilarang",
      deadlies: "Barang maut",
    },
    columnFieldCategories: {
      agent: "Perkhidmatan Pelanggan",
      callLog: "Rekod panggilan",
      businessFields: "Maklumat perniagaan",
      customer: "Pelanggan",
      customerCustomFields: "Penyesuaian pelanggan",
      source: "Sumber",
      queue: "Barisan",
      fixed: "Tetap",
      secondLevelScore: "Pengelasan sekunder",
      formItemScore: "Item skor",
      forbids: "Item yang dilarang",
      deadlies: "Barang maut",
    },
    taskListDefaultFields: {
      startTime: "Masa bercakap",
      name: "Perkhidmatan Pelanggan",
      state: "Status",
      totalScore: "Jumlah markah",
      formItemForbidList: "Item yang dilarang",
      formItemDeadlyList: "Barang maut",
      comment: "Catatan penilaian",
      inspector: "Pemarkahan",
      review: "Pengulas",
    },
    evaluationStates: {
      initial: "Permulaan",
      waitEvaluate: "Untuk dinilai",
      evaluated: "Untuk dihantar",
      waitConfirm: "Untuk disahkan",
      waitCheck: "Untuk disemak",
      complete: "Selesai",
    },
    workBenchInfoTypes: {
      callInfo: "Maklumat panggilan",
      businessInfo: "Maklumat perniagaan",
      customerInfo: "Maklumat pelanggan",
    },
    evaluationEventStates: {
      create: "Buat",
      valuate: "Penilaian",
      edit: "Edit",
      submit: "Hantar",
      confirm: "Sahkan",
      appeal: "Rayuan",
      checkConfirm: "Pengesahan semakan",
      check: "Kaji semula",
    },
    formItemTypes: {
      direct: "Skor langsung",
      weight: "Berat badan",
      deduct: "Potongan mata",
      forbid: "Item yang dilarang",
      deadly: "Barang maut",
    },
    appealCheckCallTypes: {
      callIn: "Panggilan masuk",
      callBack: "Panggilan balik dua hala",
      callOut: "Panggilan terus",
      autoCall: "Panggilan keluar automatik",
    },
    appealCheckCallStatus: {
      waitReview: "Untuk disemak",
      reviewed: "Telah dikaji semula",
      filed: "Dimfailkan",
    },
    samplingStatus: {
      all: "Semua",
      unchecked: "Pengekstrakan tidak diperiksa",
      checked: "Telah diambil sampel",
    },
    inspectionStatus: {
      all: "Semua",
      unread: "Belum dibaca",
      readed: "Telah dibaca",
      appeal: "Rayuan",
      review: "Kaji semula",
      filed: "Dimfailkan",
    },
    gradeChooseHits: { yes: "Ya", no: "Tidak" },
    appealCheckStatus: {
      all: "Semua negeri",
      unread: "Belum dibaca",
      inComplaint: "Dalam rayuan",
      reviewed: "Telah dikaji semula",
      readed: "Telah dibaca",
      filed: "Dimfailkan",
      spotchecked: "Telah diambil sampel",
    },
    flowStatus: {
      unread: "Belum dibaca",
      alreadyRead: "Telah dibaca",
      complaining: "Dalam rayuan",
      reviewed: "Telah dikaji semula",
      sampling: "Pengekstrakan tidak diperiksa",
      samplingCompleted: "Telah diambil sampel",
      complainCancel: "Dibatalkan",
    },
    complainStatus: {
      success: "Kejayaan",
      fail: "Kegagalan",
      default: "Dalam rayuan",
      canceled: "Dibatalkan",
    },
    reviewInspectionStatus: {
      inComplaint: "Dalam rayuan",
      reviewed: "Telah dikaji semula",
    },
    gradeChangeTypes: {
      edit: "Edit",
      check: "Kaji semula",
      file: "Arkib",
      remark: "Catatan",
      complain: "Rayuan",
      review: "Kaji semula",
      reviewEvaluation: "Ulasan dan penilaian",
      sample: "Persampelan",
      sampleEvaluation: "Penilaian pensampelan",
      sampleSubmit: "Penyerahan pensampelan",
      sampleAssign: "Persampelan dan pengedaran",
      reviewAssign: "Kaji semula peruntukan",
      read: "Telah dibaca",
      reviewConfirm: "Pengesahan semakan",
      sampleConfirm: "Pengesahan pengambilan sampel",
      caseBase: "Tambah ke Kumpulan Kes",
      sampleCancel: "Pensampelan membatalkan pengedaran",
      reviewCancel: "Semak dan batalkan pengedaran",
      sampleAgain: "Peruntukkan semula pemeriksaan rawak",
      reviewAgain: "Kaji semula pengagihan semula",
      btachDelete: "Padamkan rekod persampelan",
      rapidSampling: "Pensampelan pantas",
      reInspection: "Pemeriksaan semula",
      smartTagsChange: "Perubahan label pintar",
      cancelAppeal: "Tarik balik rayuan",
    },
    appealTypes: {
      transliterationError: "Kesalahan transkripsi",
      discriminationError: "Kesalahan diskriminasi",
      otherError: "Kesilapan lain",
    },
    inspectDataSources: {
      voiceCall: "Panggilan suara",
      textDialogue: "Perbualan teks",
      realTimeVoiceCall: "Panggilan suara masa nyata",
      realTimeTextDialogue: "Perbualan teks masa nyata",
      wechatDialogue: "Perbualan Mikro Perusahaan",
      taobao: "Dialog teks e-dagang",
      ticket: "Perintah kerja",
      wechatRadio: "Suara Mikro Perusahaan",
    },
    inspectTaskType: {
      all: "Semua",
      common: "Pemeriksaan kualiti biasa",
      relate: "Pemeriksaan kualiti yang berkaitan",
    },
    inspectApproveType: {
      all: "Semua",
      resolve: "Diluluskan",
      resolving: "Dalam semakan",
      reject: "Ulasan gagal",
    },
    dataSourceFlags: {
      all: "Tidak terhad",
      voiceCall: "Panggilan",
      textDialogue: "Dialog",
    },
    smartWordsLibrary: { effective: "Berkesan", invalid: "Tidak sah" },
    activeFlags: { effiective: "Berkesan", invalid: "Tidak sah" },
    labelTypes: {
      dimensionLabel: "Label label",
      classificationLabel: "Tag klasifikasi",
    },
    pointTypes: {
      automatic: "Penilaian automatik",
      manual: "Penilaian manual",
      interactiveDetection: "Pengesanan interaksi",
      smartRules: "Peraturan pintar",
      machineLearning: "Mod pembelajaran kendiri",
      intelligentModel: "Model pintar",
    },
    pointGradeTypes: {
      radio: "Pilihan tunggal",
      input: "Masukkan",
      select: "Pilihan",
      all: "Memenuhi semua",
      any: "Memenuhi mana-mana",
      customize: "Adat",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Padanan kata kunci",
      question: "Soalan",
      regular: "Ungkapan biasa",
      context: "Ulangi konteks",
      semantics: "Padankan Semantik",
      word: "Perkataan serupa",
      dialogue: "Selang masa dialog",
      speed: "Pengesanan kadar pertuturan",
      grabDetection: "Pengesanan bercakap",
      semanticTags: "Label semantik",
    },
    applyRoles: {
      all: "Semua",
      customer: "Pelanggan",
      agent: "Perkhidmatan Pelanggan",
      robot: "Robot",
    },
    saleApplyRoles: { all: "Semua", customer: "Pelanggan", agent: "Pekerja" },
    applyOperatorScopes: {
      all: "Teks penuh",
      preCondition: "Pra-syarat",
      scope: "Julat yang ditentukan",
    },
    keywordScopes: {
      all: "Mengesan semua kata kunci",
      any: "Mengesan sebarang kata kunci",
    },
    preOperatorHitTypes: {
      first: "Perlawanan pertama",
      every: "Setiap perlawanan",
      last: "Perlawanan terakhir",
      any: "Sebarang perlawanan",
      none: "Tidak sepadan",
    },
    operatorRuleTypes: {
      all: "Memenuhi semua",
      any: "Memenuhi mana-mana",
      custom: "Logik tersuai",
    },
    informationEntityTypes: {
      system: "Sistem",
      custom: "Adat",
      moduleCompany: "Modul",
    },
    organizationTypes: { null: "Tiada", moduleCompany: "Modul" },
    customType: {
      smartTag: "Ekstrak entiti",
      scriptTimes: "Pengiraan perkataan",
      dataCategory: "Pengelasan data",
    },
    interactiveQuestionTypes: {
      standard: "Soal Jawab Standard",
      multiElement: "Soalan dan jawapan pelbagai elemen",
      guideMultiRound: "Interaksi berbilang roda berpandu",
    },
    targetTypes: {
      all: "Semua",
      customerService: "Perkhidmatan Pelanggan",
      customer: "Pelanggan",
    },
    interactiveTypes: {
      similarQList: "Soalan serupa",
      exceptSimilarQList: "Tidak termasuk masalah yang serupa",
      similarAList: "Jawapan yang serupa",
      wrongAList: "Jawapan yang salah",
    },
    filterTypes: {
      call: "Rakaman panggilan",
      dialogue: "Teks perbualan",
      wechat: "WeChat Korporat",
      wechatAll: "Semua perbualan WeChat korporat",
      ticket: "Sesi pesanan kerja",
      taobao: "Perbualan e-dagang",
      wechatRadio: "Suara Mikro Perusahaan",
    },
    fieldTypes: {
      keyword: "Kata kunci",
      agent: "Perkhidmatan Pelanggan",
      agentGroup: "Kumpulan Perkhidmatan Pelanggan",
      sigleListbox: "Pilihan tunggal",
      multiListbox: "Pelbagai pilihan",
      text: "Teks baris tunggal",
      textArea: "Teks berbilang baris",
      dateTime: "Masa",
      number: "Nombor",
      voice: "",
      customer: "Pelanggan",
      fieldWithLink: "Medan dengan pautan",
      percentage: "Peratusan",
      intelligentClassification: "Pengelasan pintar",
      testSetList: "Medan dinamik set ujian",
      inspector: "Pemeriksa kualiti",
    },
    saveTypes: { save: "Simpan" },
    knowledgeType: { oneToOne: "Satu soalan dan satu jawapan" },
    updateTypes: { update: "Kemas kini", saveAs: "Simpan sebagai" },
    recommendItemActionTypes: {
      similar: "Sama",
      exclude: "Pengecualian",
      ignore: "Abaikan",
    },
    gradeStatus: {
      unread: "Belum dibaca",
      alreadyReview: "Telah dikaji semula",
      alreadyFiled: "Dimfailkan",
      waitReview: "Untuk disemak",
      alreadyRead: "Telah dibaca",
    },
    qualityCheckDetailTypes: {
      review: "Kaji semula",
      spotCheck: "Persampelan",
    },
    sampleAndReviewStatus: {
      init: "Tidak diekstrak",
      unread: "Belum dibaca",
      alreadyRead: "Telah dibaca",
      complaining: "Dalam rayuan",
      reviewed: "Telah dikaji semula",
      sampling: "Pengekstrakan tidak diperiksa",
      samplingCompleted: "Telah diambil sampel",
    },
    interactiveAnswerTypes: {
      noAnswer: "Tiada jawapan yang dikesan",
      answer: "Jawapan dikesan",
      wrongAnswer: "Jawapan yang salah dikesan",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Masalah tidak dikesan",
      question: "Masalah dikesan",
      eliminateQuestion: "Masalah penyelesaian masalah dikesan",
    },
    isManual: {
      yes: "Pemeriksaan kualiti manual",
      no: "Tiada pemeriksaan kualiti manual",
    },
    readStatus: { yes: "Telah dibaca", no: "Belum dibaca" },
    reInspectionType: {
      recalcScore: "Hanya mengira semula skor (termasuk penilaian)",
      recheckPoint: "Kira semula titik dan skor pemeriksaan kualiti",
    },
    common: {
      eCommerceReview: "Ulasan e-dagang",
      socialMediaComments: "Ulasan media sosial",
      customer: "Pelanggan",
      customerService: "Perkhidmatan Pelanggan",
      robot: "Robot",
      customerInformation: "Maklumat pelanggan",
      workOrderFields: "Medan pesanan kerja",
      intelligentConversationAnalysis: "Analisis Sesi Pintar",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Wawasan mengenai kekurangan perniagaan, dengarkan suara pelanggan, dan tingkatkan transformasi pemasaran",
      intelligentAccompanyingPractice: "Latihan pintar",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Buat latihan AI yang mendalam yang mensimulasikan pemandangan sebenar perniagaan, menyedari penguasaan kemahiran pingat emas, dan peningkatan kemahiran dialog yang cepat",
      intelligentQualityInspection: "Pemeriksaan kualiti pintar",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Pemeriksaan kualiti penuh, tepat pada masanya dan cekap, dengan kadar ketepatan lebih dari 85%",
      salesEmpowerment: "Pemberdayaan jualan",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI membentuk semula pengurusan penjualan, menyedari pendigitalan dan visualisasi proses komunikasi, pengurusan yang telus dari setiap pautan penjualan, wawasan pintar untuk berkomunikasi setiap langkah, diagnosis masalah perniagaan yang disempurnakan, dan ukiran semula pengalaman penjualan yang sempurna",
      algorithmPlatform: "Platform algoritma",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Berdasarkan semantik suara mesin inti asli yang dikembangkan sendiri, penyesuaian model ASR dan NLP, membuka data senario perniagaan, mewujudkan pembelajaran kendiri sistem, dan mencapai tujuan menjadi lebih pintar dan lebih tepat",
      generalSettings: "Tetapan umum",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Pengaturan dan pengurusan hak data dan peranan pekerja yang bersatu",
      callLog: "Rekod panggilan",
      intelligentRules: "Peraturan pintar",
      businessRecords: "Rekod perniagaan",
      customFilter: {
        call: "Panggilan suara",
        text: "Perbualan teks",
        smartTags: "Label pintar",
        pretreatment: "Pra-rawatan",
        smartTagGroup: "Kumpulan Label Pintar",
        currency: "Data am",
        wechat: "WeChat Korporat",
        spotCheck: "Persampelan",
        personaField: "Senarai Pelanggan",
        ticket: "Sesi pesanan kerja",
        taobao: "Perbualan e-dagang",
        wechatRadio: "Suara Mikro Perusahaan",
        reviewList: "Kaji semula",
      },
      talkerRecognitionType: {
        API: "API mengenal pasti peranan trek",
        text: "Pengiktirafan teks",
      },
      operatorTypes: {
        keyword: "Padanan kata kunci",
        question: "Soalan",
        regular: "Ungkapan biasa",
        context: "Ulangi konteks",
        semantics: "Padankan Semantik",
        word: "Perkataan serupa",
        dialogue: "Selang masa dialog",
        speed: "Pengesanan kadar pertuturan",
        grabDetection: "Pengesanan bercakap",
        semanticTags: "Label semantik",
        keywordStrict: "Pencocokan kata kunci (ketat)",
        link: "Bidang pautan",
        entityInfoDetection: "Pengesanan entiti maklumat",
        basicInfoDetection: "Pengesanan maklumat asas",
        volume: "Pengesanan kelantangan",
        emotion: "Analisis Emosi",
        grammar: "Peraturan tatabahasa",
        sentenceLength: "Pengesanan panjang ayat",
        conceptWord: "Perkataan konsep",
        questionsAndAnswers: "Jawapan asas pengetahuan (lama)",
        element: "Soalan dan Jawapan Elemen",
        silent: "Masa tamat tindak balas",
        associate: "Data dalam perjalanan",
        repeat: "Hantar berulang kali",
        timeInterval: "Tempoh selang",
        typoDetection: "Pengesanan kesalahan ketik",
        silentOld: "Senyap",
        knowledge: "Jawapan pengetahuan",
        wecomRemark: "Nota Mikro Pelanggan",
        customerAddTime: "Pelanggan menambah masa",
      },
      keywordOperators: {
        all: "Semua perlawanan",
        allNot: "Semua tidak sepadan",
        any: "Perlawanan sewenang-wenangnya",
      },
      applyRoles: {
        all: "Semua",
        customer: "Pelanggan",
        agent: "Perkhidmatan Pelanggan",
      },
      sentenceLength: {
        less: "Kurang daripada",
        greater: "Lebih besar daripada",
        equal: "Sama dengan",
        greater_or_equal: "Lebih besar daripada atau sama",
        less_or_equal: "Kurang daripada atau sama",
      },
      dialogueApplyScopes: {
        differentRoles: "Antara watak yang berbeza",
        agent: "Perkhidmatan Pelanggan",
        customer: "Pelanggan",
        agentResponse: "Jawapan perkhidmatan pelanggan",
        customerResponse: "Jawapan pelanggan",
        customerNoResponse: "Pelanggan tidak bertindak balas",
      },
      applyConditionHitTypes: {
        first: "Hit pertama",
        every: "Setiap hit",
        last: "Hit terakhir",
      },
      applyConditionScopes: {
        current: "Semasa",
        before: "Sebelum",
        after: "Selepas",
        beforeAll: "Semua sebelum ini",
        afterAll: "Selepas semua",
        around: "Berdekatan",
      },
      voiceDemoTypes: {
        singleChannel: "Rakaman panggilan saluran tunggal",
        dualChannel: "Rakaman panggilan dua saluran",
      },
      sendStatus: {
        sending: "Hantar",
        arrive: "Dihantar",
        fail: "Gagal menghantar",
        off_sending: "Hantar luar talian yang belum dibaca",
        off_arrive: "Telah dibaca",
        rollback: "Telah ditarik balik",
      },
      collectionMethod: {
        assignment: "Tugasan langsung",
        entity: "Pengekstrakan fizikal",
      },
      systemTypes: { qa: "Pemeriksaan kualiti pintar", wfm: "Jadual pintar" },
      entityOperators: {
        equals: "Sama dengan maklumat",
        notEquals: "Tidak sama dengan maklumat",
        contains: "Mengandungi maklumat",
        notContains: "Tidak mengandungi maklumat",
        equalsFix: "Sama dengan nilai tetap",
        notEqualsFix: "Tidak sama dengan nilai tetap",
        containsFix: "Mengandungi nilai tetap",
        notContainsFix: "Tidak mengandungi nilai tetap",
      },
      basicInfoOperators: {
        equals: "Sama dengan",
        notEquals: "Tidak sama dengan",
        contains: "Mengandungi",
        notContains: "Tidak mengandungi",
        isNull: "Kosong",
        isNotNull: "Tidak kosong",
      },
      volumeDetectionWays: {
        loudness: "Desibel kuat",
        rangeAbilitySelf: "Julat perubahan (daripada ayat sebelumnya)",
        rangeAbilityOth: "Julat perubahan (ayat sebelumnya)",
        rangeAbility3: "Julat perubahan (daripada ayat orang ini)",
      },
      volumeDetectionOperators: {
        is: "Sama dengan",
        not: "Tidak sama dengan",
        greaterThan: "Lebih besar daripada",
        greaterThanEq: "Lebih besar daripada atau sama",
        lessThan: "Kurang daripada",
        lessThanEq: "Kurang daripada atau sama",
      },
      sentimentTypes: {
        positive: "Emosi positif",
        negative: "Emosi negatif",
        neutral: "Emosi neutral",
        thankful: "Terima kasih",
        happy: "Gembira",
        complaining: "Mengeluh",
        angry: "Marah",
        post: "Positif",
        negat: "Negatif",
        neut: "Neutral",
      },
      emotionOperators: { is: "Memenuhi", not: "Tidak sesuai" },
      propTypes: { system: "Sistem", user: "Adat" },
      valueTypes: { text: "Teks", number: "Nombor" },
    },
    tasks: {
      allowCustomGrievance: "Benarkan alasan khusus untuk membuat rayuan",
      businessSummary: "Ringkasan perniagaan",
      theDayBeforeYesterday: "Sehari sebelum semalam",
      assessActionTypes: { reassess: "Penilaian semula" },
      scoreType: {
        aiScore: "Penilaian pintar",
        manualScore: "Penilaian manual",
        interactiveDetection: "Pengesanan interaksi",
        smartRules: "Peraturan pintar",
        machineLearning: "Mod pembelajaran kendiri",
      },
      inspectType: {
        inspectTrue: "Periksa ke",
        inspectFalse: "Tidak diperiksa",
      },
      operatorType: {
        keyword: "Padanan kata kunci",
        notKeyword: "Kata kunci tidak sepadan",
      },
      applyRole: {
        all: "Semua",
        customer: "Pelanggan",
        agent: "Perkhidmatan Pelanggan",
      },
      applyScope: {
        all: "Semua",
        specific: "Tentukan ayat",
        scope: "Julat yang ditentukan",
      },
      judgeStrategy: {
        all: "Memenuhi semua syarat",
        arbitrarily: "Memenuhi sebarang syarat",
        custom: "Logik tersuai",
      },
      checkPointStatus: {
        waitFor: "Menunggu semakan",
        notDeductScore: "Tiada pemotongan",
        deductScore: "Potongan mata",
      },
      applyOptions: {
        hit: "Hit bersyarat",
        notHit: "Tiada syarat yang ditentukan",
      },
      gradeTypes: {
        yesOrNo: "Sama ada memenuhi",
        input: "Input manual",
        level: "Penilaian fail",
        multipleFactors: "Pelbagai elemen",
      },
      gradeChooseHits: { yes: "Ya", no: "Tidak" },
      gradeChangeTypes: {
        edit: "Edit",
        check: "Kaji semula",
        file: "Arkib",
        remark: "Catatan",
        complain: "Rayuan",
        review: "Kaji semula",
        reviewEvaluation: "Ulasan dan penilaian",
        sample: "Persampelan",
        sampleEvaluation: "Penilaian pensampelan",
        sampleSubmit: "Penyerahan pensampelan",
        sampleAssign: "Persampelan dan pengedaran",
        reviewAssign: "Kaji semula peruntukan",
        read: "Telah dibaca",
        reviewConfirm: "Pengesahan semakan",
        sampleConfirm: "Pengesahan pengambilan sampel",
      },
      gradeStatus: {
        unread: "Belum dibaca",
        alreadyReview: "Telah dikaji semula",
        alreadyFild: "Dimfailkan",
        waitReview: "Untuk disemak",
        alreadyRead: "Telah dibaca",
      },
      samplingFrequencyTypes: {
        everyDay: "Setiap hari",
        everyWeek: "Setiap minggu",
        everyMonth: "Setiap bulan",
      },
      samplingEveryDayTimeTypes: { yesterday: "Semalam", today: "Hari ini" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Minggu ini",
        lastWeek: "Minggu lepas",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Bulan ini",
        lastMonth: "Bulan lalu",
      },
      samplingTypes: {
        random: "Persampelan rawak",
        proportional: "Persampelan berkadar",
      },
      weekdays: {
        Monday: "Isnin",
        Tuesday: "Selasa",
        Wednesday: "Rabu",
        Thursday: "Khamis",
        Friday: "Jumaat",
        Saturday: "Sabtu",
        Sunday: "Ahad",
      },
      distributeTypes: {
        rule: "Peruntukan keutamaan peraturan",
        average: "Pengagihan rawak purata",
      },
      notMatchAnyRuleTypes: {
        random: "Pengagihan purata rawak",
        assign: "Ditugaskan kepada kakitangan yang ditetapkan",
        ignore: "Tidak diperuntukkan",
      },
      inspectorTypes: {
        all: "Semua pemeriksa kualiti",
        some: "Pemeriksa kualiti yang ditetapkan",
      },
      pushFrequencyTypes: {
        everyDay: "Setiap hari",
        everyWeek: "Setiap minggu",
        everyMonth: "Setiap bulan",
        inspectionUpdate: "Kemas kini pemeriksaan kualiti",
        timelyInspection: "Pemeriksaan kualiti masa nyata",
      },
      pushObjectTypes: {
        email: "Peti mel yang ditentukan",
        staff: "Perkhidmatan pelanggan",
      },
      reviewOptions: {
        reviewResult: "Paparkan hasil pemeriksaan kualiti semasa semakan",
        reviewRecord: "Paparkan rekod yang berkaitan semasa semakan",
        reviewAgentHidden:
          "Sembunyikan maklumat perkhidmatan pelanggan semasa semakan",
        samplingResult:
          "Paparkan hasil pemeriksaan kualiti semasa pemeriksaan rawak",
        samplingRecord: "Paparkan rekod yang relevan semasa pemeriksaan rawak",
        sampleAgentHidden:
          "Sembunyikan maklumat perkhidmatan pelanggan semasa pemeriksaan rawak",
        checkExportAddCallInfoIphone:
          "Lampirkan rekod panggilan semasa mengeksport senarai",
        sampleExportAddCallInfoIphone:
          "Lampirkan rekod panggilan semasa mengeksport senarai",
        checkExportAddCallInfoText:
          "Tambahkan rekod perbualan semasa mengeksport senarai",
        sampleExportAddCallInfoText:
          "Tambahkan rekod perbualan semasa mengeksport senarai",
        inspectionExportAddCallInfoIphone:
          "Lampirkan catatan panggilan semasa mengeksport senarai (sehingga 10,000 dapat dieksport setiap kali)",
        inspectionExportAddCallInfoText:
          "Tambahkan rekod dialog semasa mengeksport senarai (sehingga 10,000 dapat dieksport setiap kali)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Benarkan pemeriksaan rawak dengan cepat dalam perincian pemeriksaan kualiti",
        fastSampleInSampleDetail:
          "Benarkan pemeriksaan rawak dilakukan dengan cepat dalam perincian pemeriksaan rawak",
        fastSampleInReviewDetail:
          "Benarkan pemeriksaan rawak dengan cepat dalam perincian tinjauan",
      },
      permitCustomReasonList: { permitCustomReason: "Benarkan alasan khusus" },
      autoMateTasks: {
        autoLoad: "Tambah secara automatik",
        sample: "Pensampelan automatik",
        assign: "Peruntukan automatik",
        pushes: "Tolak automatik",
        assess: "Penilaian automatik",
        check: "Ulasan rayuan",
        case: "Penyegerakan perpustakaan kes",
      },
      qualityCheckDetailTypes: {
        review: "Kaji semula",
        spotCheck: "Persampelan",
      },
      appealTypes: {
        transliterationError: "Kesalahan transkripsi",
        discriminationError: "Kesalahan diskriminasi",
        otherError: "Kesilapan lain",
      },
      inspectDataSources: {
        voiceCall: "Panggilan suara",
        textDialogue: "Perbualan teks",
        realTimeVoiceCall: "Panggilan suara masa nyata",
        realTimeTextDialogue: "Perbualan teks masa nyata",
      },
      pointTypes: {
        automatic: "Penilaian automatik",
        manual: "Penilaian manual",
        interactiveDetection: "Pengesanan interaksi",
      },
      pointGradeTypes: {
        radio: "Pilihan tunggal",
        input: "Masukkan",
        select: "Pilihan",
      },
      sampleAndReviewStatus: {
        init: "Tidak diekstrak",
        unread: "Belum dibaca",
        alreadyRead: "Telah dibaca",
        complaining: "Dalam rayuan",
        reviewed: "Telah dikaji semula",
        sampling: "Pengekstrakan tidak diperiksa",
        samplingCompleted: "Telah diambil sampel",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Abaikan limpahan berlebihan",
        automaticWeightCalculation: "Perakaunan berat automatik",
      },
      calculateScoreTypes: { add: "Mata bonus", sub: "Kurangkan mata" },
      naChecked: { check: "Benarkan item ini tidak terlibat dalam pengesanan" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Beri mata secara berkadar",
        allRight: "Berikan semua mata dengan betul",
        anyRight: "Berikan mata dengan betul",
      },
      noHitQuestionResults: {
        true: 'Hasilnya adalah "ya"',
        false: 'Hasilnya adalah "Tidak"',
      },
      repetRules: {
        same: "Kandungan yang sama",
        similar: "Semantik yang serupa",
      },
      relateConditions: {
        highlightQuestion: "Masalah sorotan",
        highlightAnswer: "Menyerlahkan jawapan",
        hightlightFoctors: "Elemen sorotan",
      },
      interactiveQuestionTypes: {
        question: "Masalah dikesan",
        eliminateQuestion: "Masalah penyelesaian masalah dikesan",
      },
      interactiveAnswerTypes: {
        noAnswer: "Tiada jawapan yang dikesan",
        answer: "Jawapan dikesan",
        wrongAnswer: "Jawapan yang salah dikesan",
      },
      inspectionUpdateTypes: {
        autoInspection: "Pemeriksaan kualiti automatik",
        autoInspectionWechat: "Analisis automatik",
        artificialSampling: "Pemeriksaan manual",
        artificialReview: "Kajian manual",
        manualModification: "Pensampelan pantas",
        timelyInspection: "Pemeriksaan kualiti masa nyata",
        samplingAssign: "Persampelan dan pengedaran",
        artificialComplain: "Penyerahan rayuan",
        fastSampling: "Penyerahan persampelan cepat",
        recheckInspection: "Pemeriksaan semula",
        recheckInspectionWechat: "Analisis semula",
      },
      timelyInspectionTypes: {
        timelyInspection: "Pemeriksaan kualiti masa nyata",
      },
      pushActionTypes: { email: "Mel", interfacePush: "Tolak antara muka" },
      pushDetailWays: {
        riskAlert: "Peringatan risiko",
        subscribe: "Langgan mesej",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Mesej panggilan/perbualan",
        inspectionResults: "Hasil pemeriksaan kualiti",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Mesej panggilan/perbualan",
        textContent: "Kandungan teks panggilan/perbualan",
        inspectionResults: "Hasil pemeriksaan kualiti",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Purata pecahan dalam kumpulan",
        addOrSubtract: "Menambah dan mengurangkan mata secara langsung",
        noChange: "Tiada mata tambah atau tolak",
      },
      defaultScore: {
        empty: "Kosong",
        add: "Mata bonus",
        notAdd: "Tiada mata tambahan",
        sub: "Kurangkan mata",
        notSub: "Tiada pengurangan mata",
        na: "N/A",
        input: "Input manual",
        choose: "Pilihan",
      },
      strategyTypes: {
        hitAdd: "Mata bonus hit",
        hitNotAdd: "Tiada mata tambahan",
        hitSub: "Hit dan tolak mata",
        hitNotSub: "Hit tanpa kehilangan mata",
      },
    },
    download: {
      exportStatus: {
        prepare: "Bersedia",
        process: "Dalam proses",
        faild: "Eksport gagal",
        success: "Kejayaan",
      },
    },
    messageType: {
      all: "Semua",
      monitor: "Pemantauan segera",
      trigger: "Pemberitahuan pencetus",
      timing: "Laporan masa",
    },
    messagePushType: {
      all: "Semua",
      system: "Mesej sistem",
      sdk: "Mesej SDK",
      push: "Tolak antara muka",
      email: "Mel",
      udesk: "Notis udesk",
      udeskWhite: "Pemberitahuan sistem perkhidmatan pelanggan",
      weChat: "Berita Mikro Perusahaan",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Tugaskan pemeriksa rawak",
      sampleInspector: "Persampelan",
      assignReviewer: "Tugaskan pengulas",
      reviewer: "Pengulas",
      taskInspector: "Pemeriksa kualiti tugas",
    },
    sdkPushType: {
      webHook: "Webhook standard",
      dingDing: "Robot kuku",
      weChat: "Robot WeChat Enterprise",
      customer: "Kandungan Json tersuai",
    },
    inspectionRemarksType: {
      inspection: "Pemeriksaan kualiti",
      check: "Persampelan",
      review: "Kaji semula",
    },
    segmentationStrategyType: {
      count: "Bilangan mesej",
      spacing: "Selang mesej",
    },
    segmentationStrategy: {
      byTime: "Pemecut masa",
      customize: "Pemecut gabungan",
    },
    inspectType: {
      commonInspect: "Pemeriksaan kualiti biasa",
      associateInspect: "Pemeriksaan kualiti yang berkaitan",
    },
    typoDetectionOperators: {
      less: "Kurang daripada",
      greater: "Lebih besar daripada",
    },
    autoAddTypes: {
      realTimeAdd: "Tambah dalam masa nyata",
      timingAdd: "Tambah masa",
    },
    messageTypes: { text: "Mesej teks", markdown: "Mesej Markdown" },
    inServiceStatus: {
      activated: "Diaktifkan",
      disabled: "Dilumpuhkan",
      inactivated: "Tidak diaktifkan",
      resigned: "Keluar dari perniagaan",
    },
    roles: { agent: "Pekerja", customer: "Pelanggan" },
    commentVisibleAuthority: {
      all: "Boleh dilihat oleh semua orang",
      self: "Hanya kelihatan sendiri",
      designation: "Orang yang ditentukan dapat dilihat",
    },
    appealCheckingTask: {
      eCommerceReview: "Ulasan e-dagang",
      socialMediaComments: "Ulasan media sosial",
      largeModel: "Model besar",
      customModel: "Model tersuai",
      universalModel: "Model am",
      enterpriseWeChatText: "Teks mikro perusahaan",
      generalQualityInspection: "Pemeriksaan kualiti am",
      processQualityInspection: "Pemeriksaan kualiti proses",
      voiceCopy: "Pemulihan suara",
      beEqualTo: "Sama dengan",
      notEqualTo: "Tidak sama dengan",
      canAppeal: "Boleh membuat rayuan",
      noAppeal: "Tidak boleh membuat rayuan",
    },
    wechat: {
      redEnvelopes: "Sampul merah",
      text: "Teks",
      picture: "Gambar",
      voice: "Suara",
      voiceCall: "Panggilan suara",
      video: "Video",
      businessCard: "Kad perniagaan",
      position: "Lokasi",
      expression: "Ungkapan",
      link: "Pautan",
      applet: "Program kecil",
      chatRecord: "Sejarah sembang",
      mixedMessage: "Mesej bercampur",
      file: "Fail",
    },
    gong: {
      sessionActivity: "Aktiviti sesi",
      conversationAction: "Tindakan sesi",
      riskSemantics: "Semantik Risiko",
      daysNotFollowedUp: "Bilangan hari tidak diikuti",
      waysideData: "Data dalam perjalanan",
      notStarted: "Tidak bermula",
      completed: "Selesai",
      haveInHand: "Dalam proses",
      unpublished: "Tidak diterbitkan",
      published: "Dikeluarkan",
      knowledgePoints: "Titik pengetahuan",
      technologicalProcess: "Proses",
      notMarked: "Tidak ditandakan",
      inAnnotation: "Tandakan",
      annotated: "Ditandai",
    },
    qualityChecingTask: {
      appealCanceled: "Pembatalan rayuan",
      complaintExpired: "Rayuan tamat tempoh",
      incomingCall: "Panggilan masuk",
      exhale: "Panggilan keluar",
      incomingCallthirdParty: "Panggilan masuk (tiga arah)",
      incomingCallconsultation: "Panggilan masuk (perundingan)",
      incomingCallforcedInsertion: "Panggilan masuk (palam kuat)",
      incomingCallmonitoring: "Panggilan masuk (monitor)",
      incomingCalltransfer: "Panggilan masuk (pemindahan)",
      incomingCallinterception: "Panggilan masuk (memintas)",
      incomingCalltransferToExternalLine:
        "Panggilan masuk (beralih ke talian luar)",
      incomingCallthirdPartyExternalLine:
        "Panggilan masuk (garis luar tiga arah)",
      incomingCallexternalLine: "Panggilan masuk (garis luar perundingan)",
      outgoingCallthirdParty: "Panggilan keluar (tiga arah)",
      outgoingCalltransfer: "Panggilan keluar (pemindahan)",
      outgoingCallforcedInsertion: "Panggilan keluar (palam kuat)",
      outgoingCallmonitoring: "Panggilan keluar (monitor)",
      outgoingCallinterception: "Panggilan keluar (memintas)",
      outgoingCalltransferToExternalLine:
        "Panggilan keluar (pindah ke talian luar)",
      outgoingCallthreePartyExternalLine:
        "Panggilan keluar (garis luar tiga arah)",
      customerServiceAnswer: "Jawapan perkhidmatan pelanggan",
      customerServiceMissed: "Perkhidmatan pelanggan tidak dijawab",
      customerAnswer: "Pelanggan menjawab",
      customerMissed: "Pelanggan tidak dijawab",
      theTelephoneIsBusy: "Telefon sibuk",
      phoneOffline: "Telefon luar talian",
      customerQuickHanging: "Pelanggan cepat",
      clientHangsUp: "Pelanggan menutup",
      queueTimeout: "Beratur lebih masa",
      giveUpQueuing: "Berhenti beratur",
      outsideLineAnswering: "Jawapan luar",
      externalLineIsNotConnected: "Garis luar tidak disambungkan",
      noQueueSelected: "Baris tidak dipilih",
      notEvaluated: "Tidak dinilai",
      noEvaluationRequired: "Tiada penilaian yang diperlukan",
      queuingSucceeded: "Berjaya dalam barisan",
      noCustomerServiceOnline: "Tiada perkhidmatan pelanggan dalam talian",
      customer: "Pelanggan",
      customerService: "Perkhidmatan Pelanggan",
      outsideLines: "Luar",
    },
    sessionActivity: {
      single: "Masa bercakap purata tunggal",
      all: "Jumlah panggilan",
      allTime: "Jumlah masa panggilan",
    },
    sessionAction: {
      salesProportion: "Jualan bercakap",
      salesLongest: "Rata-rata ucapan jualan terpanjang",
      customerLongest: "Rata-rata pelanggan terpanjang bercakap",
      discussNumber: "Bilangan perbincangan mendalam",
      frequency: "Kekerapan soalan",
    },
    monitorCriteria: {
      all: "Sebelum dan selepas",
      before: "Sebelum",
      after: "Selepas",
    },
    monitorTimeCycle: { day: "Hari", week: "Minggu", month: "Bulan" },
    monitorGroupTestType: {
      ab: "Perbandingan dua kumpulan",
      a: "Kumpulan A",
      b: "Kumpulan B",
    },
    fields: {
      department: "Jabatan",
      customerTags: "Label pelanggan",
      member: "Ahli",
      time: "Masa",
      cascade: "Gred",
      anyMatch: "Perlawanan sewenang-wenangnya",
      keyWord: "Kata kunci",
      keyEvents: "Peristiwa kritikal",
    },
    semanticIntelligence: {
      staff: "Pekerja",
      customer: "Pelanggan",
      employeesAndCustomers: "Pekerja dan pelanggan",
      notStarted: "Tidak bermula",
      haveInHand: "Dalam proses",
      completed: "Selesai",
      callRecording: "Rakaman panggilan",
      enterpriseWeChatCall: "Komunikasi Mikro Perusahaan",
      enterpriseWeChatText: "Teks mikro perusahaan",
      discourseMiningForObjectionHandling:
        "Perlombongan kemahiran menangani bantahan",
      productSellingPointScriptsMining: "Perlombongan kemahiran menjual produk",
      conversationScenario: "Adegan sesi",
      topicOfConversation: "Topik",
      keyEvents: "Peristiwa kritikal",
      customerLabel: "Label pelanggan",
      includeAny: "Mengandungi sewenang-wenangnya",
      includeAll: "Mengandungi semua",
      and: "Dan",
      or: "Atau",
      question: "Soalan",
      nonInterrogativeSentence: "Kalimat yang tidak dipersoalkan",
    },
    coach: {
      notPassed: "Tidak lulus",
      analogTelephone: "Telefon analog",
      enterScenarioInformation: "Masukkan maklumat senario",
      generateDialogueFramework: "Menjana rangka dialog",
      generatePromptLanguage: "Menjana arahan",
      aIGeneratedDialogue: "AI menjana perbualan",
      system: "Sistem",
      custom: "Adat",
      flow: "Proses",
      script: "Bercakap",
      express: "Ungkapan",
      emotion: "Emosi",
      rateAccordingToCourseConfiguration: "Skor mengikut konfigurasi kursus",
      intelligentModelRating: "Penilaian model pintar",
      allData: "Semua data",
      dataFromThisDepartment: "Data jabatan ini",
      multidepartmentalData: "Data pelbagai sektor",
      underReview: "Dalam semakan",
      no: "Tidak",
      yes: "Ya",
      automaticRating: "Skor automatik",
      auditRating: "Skor audit",
      reRating: "Penilaian semula",
      inAppeal: "Dalam rayuan",
      reviewed: "Telah dikaji semula",
      notViewed: "Tidak dilihat",
      viewed: "Dilihat",
      uncorrected: "Tidak diperbetulkan",
      corrected: "Dibetulkan",
      practice: "Amalan",
      examination: "Peperiksaan",
      other: "Lain-lain",
      notMeetingStandards: "Tidak sesuai",
      meetingStandards: "Mencapai standard",
      excellent: "Cemerlang",
      invalid: "Kegagalan",
      notStarted: "Tidak bermula",
      haveInHand: "Dalam proses",
      ended: "Tamat",
      completed: "Selesai",
      hangInTheAir: "Belum selesai",
      passed: "Lulus",
      voiceConversation: "Perbualan suara",
      textDialogue: "Perbualan teks",
      slidePresentation: "Ucapan slaid",
      unpublished: "Tidak diterbitkan",
      published: "Dikeluarkan",
      narration: "Pencerita",
      studentSpeaking: "Pelajar bercakap",
      robotTalk: "Robot bercakap",
      knowledgeBaseQA: "Soalan dan Jawapan Pangkalan Pengetahuan",
      slide: "Slaid",
      negativeEmotions: "Emosi negatif",
      emotionallyNormal: "Emosi normal",
      incompleteScript: "Kemahiran bercakap tidak lengkap",
      completeScript: "Kemahiran bercakap lengkap",
      normalSpeechSpeed: "Kelajuan bercakap adalah normal",
      speakTooFast: "Bersuara terlalu pantas",
      speakTooSlowly: "Kelajuan bercakap terlalu perlahan",
      whole: "Semua",
      singlePage: "Halaman tunggal",
      qA: "Satu soalan dan satu jawapan",
      situationalDialogue: "Dialog situasi",
      misses: "Tidak berjaya",
      hit: "Hit",
      sequentialQA: "Soalan dan jawapan pesanan",
      randomQA: "Soal Jawab Rawak",
      mastered: "Menguasai",
      notMastered: "Tidak dikuasai",
    },
    algorithm: {
      salesEmpowerment: "Pemberdayaan jualan",
      enterpriseWeChatVoice: "Suara WeChat Korporat",
      enterpriseWeChatText: "Teks WeChat Korporat",
      waiting: "Menunggu",
      extracting: "Dalam pengekstrakan",
      success: "Kejayaan",
      fail: "Kegagalan",
      intelligentQualityInspection: "Pemeriksaan kualiti pintar",
      textDialogue: "Perbualan teks",
      voiceCall: "Panggilan suara",
      smartTags: "Label pintar",
      satisfactionEvaluation: "Penilaian kepuasan",
      score: "Penilaian",
      grade: "Penilaian",
      customerServiceTeam: "Kumpulan Perkhidmatan Pelanggan",
      conversationAnalysis: "Analisis Sesi",
    },
    operatorListGather: {
      businessSummary: "Ringkasan perniagaan",
      firstSentence: "Ayat pertama",
      lastSentence: "Ayat terakhir",
      allTime: "Semua masa",
      nonBusinessWorkingHours: "Waktu kerja bukan perniagaan",
      businessWorkingHours: "Waktu kerja perniagaan",
    },
    callDialogueManage: {
      eCommerceReview: "Ulasan e-dagang",
      socialMediaComments: "Ulasan media sosial",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Log masuk",
        email: "Peti mel",
        password: "Kata laluan",
        forgetPwd: "Lupa kata laluan",
        emailCannotBeEmpty: "Peti mel tidak boleh kosong.",
        passwordCannotByEmpty: "Kata laluan tidak boleh kosong.",
        mustChangeLogin:
          "Maaf, kata laluan anda mesti diset semula, hubungi pentadbir untuk menetapkan semula kata laluan.",
        dismissionLogin: "Maaf, anda telah meninggalkan pekerjaan anda.",
        forbiddenLogin: "Maaf, akaun anda dilumpuhkan.",
        loginError: "Log masuk gagal, peti mel atau kata laluan salah.",
        loginPrompt: "Log masuk ke sistem",
        rememberMe: "Ingat saya",
      },
      logout: {
        logoutError:
          "Log keluar gagal, lihat sambungan rangkaian atau cuba lagi kemudian.",
      },
      resetPassword: {
        forgetPwd: "Lupa kata laluan",
        inputEmailPrompt:
          "Masukkan alamat e-mel akaun anda di bawah, dan kami akan menghantar e-mel dan arahan untuk menetapkan semula kata laluan anda.",
        sendEmailSuccess:
          "Surat pengesahan kata laluan telah berjaya dihantar ke peti surat yang anda isi, sila periksa tepat pada waktunya.",
        sendEmailFailed:
          "Sekiranya penghantaran e-mel gagal, sila periksa sambungan rangkaian atau cuba lagi kemudian.",
        setNewPassword: "Tetapkan Kata Laluan Baru Anda",
        passwordCannotEmpty: "Kata laluan tidak boleh kosong",
        passwordFormWrong: "Kata laluan tidak diformat dengan betul",
        confirmPasswordWrong:
          "Kata laluan yang dimasukkan dua kali tidak sama.",
        passwordResetSuccess: "Kata laluan berjaya diset semula.",
        passwordResetFailed:
          "Sekiranya penetapan semula kata laluan gagal, periksa sambungan rangkaian atau cuba lagi kemudian.",
        linkTimeover:
          "Tetapkan semula pautan kata laluan tamat, sila cuba lagi",
      },
      form: { title: "Paparan borang CASE" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Peti mel",
          emailFormatError: "Format peti mel salah, sila masukkan semula!",
          forgotPassword: "Lupa kata laluan anda?",
          describe:
            "Masukkan alamat e-mel akaun anda di bawah, dan kami akan menghantar e-mel dan arahan untuk menetapkan semula kata laluan anda.",
          send: "Hantar",
          emailCannotBeEmpty: "Peti mel tidak boleh kosong",
          resetPasswordExpire:
            "Tetapkan semula pautan kata laluan tamat, sila cuba lagi",
        },
        resetPassword: {
          setPassword: "Tetapkan Kata Laluan Baru Anda",
          newPassword: "Kata laluan baru",
          repeatPassword: "Kata laluan berulang",
          resetPassword: "Tetapkan semula kata laluan",
          passwordCannotBeEmpty: "Kata laluan baru tidak boleh kosong.",
          confirmPasswordCannotBeEmpty:
            "Kata laluan pendua tidak boleh kosong.",
          passwordsNotMatch: "Kata laluan tidak konsisten dua kali!",
          passwordFormWrong:
            "Kata laluan tidak boleh mengandungi bahasa Cina, dan jumlahnya adalah 6-14!",
          linkExpiration:
            "Tetapkan semula pautan kata laluan tamat, sila cuba lagi",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Anda tidak mempunyai kebenaran untuk melihat halaman ini",
      },
    },
    downloadCenter: {
      name: "Pusat Muat turun",
      saveTime: "(Disimpan secara lalai selama 30 hari)",
      headerTitle: "Muat turun fail",
      download: "Muat turun",
      downloadPromp: "Tugas muat turun telah dihantar, sila pergi ke",
      viewProgress: "Lihat kemajuan",
    },
    uploadCenter: { name: "Pusat Muat Naik" },
    tasks: {
      index: {
        name: "Nama",
        remark: "Penerangan",
        qualityCheckTasksTitle: "Meja kerja",
        informationCollection: "Tugas pengumpulan",
        qualityCheckTasks: "Tugas pemeriksaan kualiti",
        wechatQaTasks: "Tugas analisis",
        moduleName: "Gunakan templat untuk membuat yang baru",
        creatNewQualityCheckTask: "Misi baru",
        generalInspectionList: "Laporan pemeriksaan kualiti am",
        editQualityCheckTask: "Edit tugas pemeriksaan kualiti",
        creatNewQualityCheckTaskError: "Tugas pemeriksaan kualiti baru gagal",
        editQualityCheckTaskError: "Edit kegagalan tugas pemeriksaan kualiti",
        type: "Jenis Borang",
        singleScore: "Skor tunggal",
        singleScoreNullError: "Skor tunggal tidak boleh kosong",
        inspector: "Pemeriksa kualiti",
        inspectDataSource: "Sumber data",
        taskName: "Nama tugas",
        inspectionType: "Jenis pemeriksaan kualiti",
      },
      manage: {
        screeningCondition: "Tapis keadaan",
        template: {
          condition: {
            index: { name: "Keadaan", existingConditions: "Syarat sedia ada" },
            detail: {
              id: "ID",
              name: "Nama keadaan",
              remark: "Penerangan",
              checkRange: "Julat pengesanan",
              operator: "Pengendali",
              operatorLogic: "Logik pengendali",
            },
          },
          checkPointTest: {
            title: "Ujian peraturan",
            running: "Dalam ujian peraturan, sila tunggu...",
            clearSuccess: "Dialog simulasi berjaya dikosongkan",
            mock: "Dialog simulasi",
            save: "Simpan dialog yang dipilih",
            saveAll: "Simpan semua perbualan",
            saveSuccess: "Dialog berjaya disimpan",
            close: "Keluar",
            delete: "Padamkan yang dipilih",
            clear: "Kosong",
            customer: "Pelanggan",
            agent: "Perkhidmatan Pelanggan",
            test: "Ujian",
            request:
              "Dialog simulasi tidak dapat dipulihkan setelah dikosongkan. Adakah disahkan bahawa ia dikosongkan?",
            deletePopconfirmContent:
              "Adakah anda mengesahkan untuk menghapus dialog yang dipilih?",
          },
          checkPoint: {
            index: {
              name: "Konfigurasi peraturan",
              wechatTitle: "Konfigurasi model analisis",
              existingCheckPoints: "Peraturan pemeriksaan kualiti yang ada",
              currentCheckPointScore:
                "Skor peraturan pemeriksaan kualiti semasa adalah",
              currentCheckPointScoreWechat: "Skor model analisis semasa adalah",
              currentCheckPointWeight:
                "Jumlah berat peraturan pemeriksaan kualiti semasa adalah",
              weightTotalScoreAndItemScore:
                "Jumlah skor: 100 mata Mata individu:",
              template: "Templat peraturan pemeriksaan kualiti",
              import: "Import templat peraturan pemeriksaan kualiti",
              getTemplateError:
                "Gagal mendapatkan templat peraturan pemeriksaan kualiti",
              tabs: { template: "Templat", demo: "Contoh" },
              customize: "Templat kosong",
              move: "Seret untuk menyesuaikan klasifikasi dan penyortiran",
              classification: "Pengelasan pemeriksaan kualiti",
              classificationWeChat: "Pengelasan model",
              first: "Pengelasan peringkat pertama",
              second: "Pengelasan sekunder",
              new: "Peraturan pemeriksaan kualiti baru",
              weChatNew: "Menambah model analisis",
              createWeChat: "Model analisis baru",
              empty: "Tidak ada peraturan pemeriksaan kualiti,",
              wechatEmpty: "Tiada model analisis,",
              newSecond: "Klasifikasi sekunder baru",
              newFirst: "Pengelasan tahap pertama yang baru",
              checkPoint: "Peraturan pemeriksaan kualiti",
              checkPointWeChat: "Model analisis",
            },
            detail: {
              name: "Nama",
              remark: "Penerangan",
              type: "Jenis",
              deduction: "Potongan mata",
              rule: "Peraturan",
              highlighted: "Sorotan",
              gradeType: "Kaedah pemarkahan",
              interactiveCategory: "Pengelasan interaktif",
              predeterminedScore: "Skor",
              weight: "Berat badan",
              na: "N/A",
              default: "Skor lalai",
              preRule: "Peraturan pra-syarat",
              analysisHighlighted: "Peraturan penghuraian",
              categorySelectPrompt: "Sila pilih kategori",
              checkHighlightArrayError:
                "Peraturan tidak sesuai dengan sorotan, sila klik butang peraturan penghuraian sekali lagi",
              foctorsHolder: "Sila pilih elemen",
              foctorsErrorNone:
                "Peraturan pelbagai elemen kosong, sila tambahkan jenis pelbagai elemen",
              naError: "Item tidak dapat ditutup apabila skor lalai adalah N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A bermaksud Tidak Aplikasi, menetapkan prasyarat untuk peraturan pemeriksaan kualiti, dan menguji ketika syarat dipenuhi. Apabila syarat tidak dipenuhi, peraturan tidak berlaku, dan peraturan tidak diuji;\nArahan pengisian pra-syarat: "& &" bermaksud "dan", "| |" bermaksud "atau", "!" Bermakna "bukan", isi contoh "(R1 | | R2)& & R3"',
                applicationMessageType: "Jenis Mesej Aplikasi",
                selectAll: "Semua pilihan",
                highlightTags: "Menonjlahkan label:",
                ruleType: "Jenis peraturan",
                dataSource: "Sumber data",
                whole: "Semua",
                enterpriseMicroConversation: "Perbualan Mikro Perusahaan",
                enterpriseAndMicroCommunication: "Komunikasi Mikro Perusahaan",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Menentukan nod",
                    dialogueNode: "Nod dialog",
                    editNodes: "Edit nod",
                  },
                  nodeConfig: {
                    nodeName: "Nama nod",
                    nodeRules: "Peraturan nod",
                    scoringLogic: "Logik pemarkahan",
                    score: "Skor:",
                    rule: "Peraturan",
                    multiBranchLogic: "Logik pelbagai cawangan",
                    highlight: "Sorotan",
                    submit: "Hantar",
                  },
                  ruleList: {
                    branch: "Cawangan",
                    branchName: "Nama cawangan:",
                    addRules: "Meningkatkan peraturan",
                  },
                  sidebar: { node: "Nod" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Penamat automatik",
                      canvasAdaptation: "Penyesuaian kanvas",
                      copy: "Salin",
                      delete: "Padamkan",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Tentukan bahawa sub-nod nod tidak boleh digunakan sebagai sasaran sambungan",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Nod permulaan tidak boleh menjadi sasaran sambungan",
                      startingNodeCannotConnectMultipleNodes:
                        "Nod permulaan tidak dapat menghubungkan beberapa nod",
                      nodeCannotBeConnectedToItself:
                        "Nod tidak dapat dihubungkan dengan dirinya sendiri",
                      startNode: "Mula nod",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Batalkan semua pilihan",
                    selectAll: "Semua pilihan",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Sama ada memenuhi",
                    gradedEvaluation: "Penilaian fail",
                  },
                },
              },
            },
            configuration: {
              title: "Peraturan pemeriksaan kualiti",
              wechatTitle: "Model analisis",
              neme: "Nama",
              errorMessage: "Penghapusan gagal, sekurang-kurangnya satu syarat",
              desc: "Penerangan",
              status: "Status",
              yes: "Berkesan",
              no: "Tidak sah",
              namePlaceHolder: "Sila masukkan nama label",
              desPlaceHolder: "Sila masukkan keterangan",
              nameEmpty: "Nama tidak boleh kosong",
              desEmpty: "Penerangan tidak boleh kosong",
              type: "Jenis",
              ruler: "Peraturan pintar",
              machineLearning: "Mod pembelajaran kendiri",
              autoScore: "Skor pintar (lama)",
              manualScore: "Penilaian manual",
              interaction: "Pengesanan interaksi (lama)",
              typeTips: {
                model:
                  'Model pintar: Ini adalah sistem pemeriksaan kualiti yang telah ditetapkan, tanpa konfigurasi dan penyelenggaraan, dan dapat dengan cepat melihat masalah dalam perkhidmatan. Model ini lebih umum, jika tidak memenuhi keperluan perniagaan, sila gunakan "peraturan pintar" atau "skor manual',
                rule: "Peraturan pintar: iaitu penilaian automatik berdasarkan peraturan AI",
                manual:
                  "Pemarkahan manual: pemeriksaan kualiti manual, skor manual",
              },
              model: "Model:",
              ruleType: {
                words: "Bercakap",
                interaction: "Interaksi",
                feature: "Ciri-ciri",
                information: "Maklumat",
              },
              testingMode: {
                all: "Mengesan semua kata kunci",
                any: "Mengesan sebarang kata kunci",
              },
              operatorTypesOfInteraction: {
                question: "Satu soalan dan satu jawapan",
                element: "Elemen jawapan",
                repet: "Ulangi konteks",
                snatch: "Bercakap",
                silent: "Senyap",
              },
              role: "Peranan pengesanan:",
              preposition: "Pengendali pra-operasi:",
              range: "Julat pengesanan:",
              from: "No.",
              to: "Ayat ke",
              end: "Ayat",
              hasOldSilentTip:
                'Halo, untuk meningkatkan ketepatan, "Peraturan Pintar-Interaksi-Senyap" telah ditingkatkan menjadi "Waktu Tanggapan", disarankan untuk mengemas kini peraturan penggunaan!',
              changeToOld: "Tukar ke versi lama",
              changeToNew: "Tukar ke versi baru",
              logic: {
                logic: "Logik pemarkahan",
                naTip:
                  "N/A bermaksud Tidak Aplikasi, tetapkan prasyarat untuk peraturan pemeriksaan kualiti, dan uji ketika syarat dipenuhi. Jika syarat tidak dipenuhi, peraturan tidak berlaku, dan peraturan tidak diuji.",
                score: "Skor:",
                na: "N/A：",
                naDes: "Benarkan item ini tidak terlibat dalam pengesanan",
                preRule: "Peraturan pra-syarat:",
                notCatchPreRule: "Apabila prasyarat tidak dipenuhi:",
                naChecked: "Apabila memukul N/A:",
                preRuleEmpty: "Peraturan prasyarat tidak boleh kosong",
                operatorEmpty:
                  "Syarat yang diperlukan dalam pengendali tidak boleh kosong",
                multipleEmpty:
                  "Syarat yang diperlukan dalam skor logik berganda tidak boleh kosong",
                average: "Purata pecahan dalam kumpulan",
                addOrSubtract: "Menambah dan mengurangkan mata secara langsung",
                noChange: "Tiada mata tambah atau tolak",
                rule: "Peraturan:",
                all: "Memenuhi semua pengendali",
                any: "Memenuhi operator sewenang-wenangnya",
                customize: "Logik pengendali tersuai",
                multiple: "Skor logik pelbagai",
                heightLight: "Sorotan:",
                machineLearningTip:
                  "Mod pembelajaran kendiri pada masa ini hanya berlaku untuk bahasa ayat tunggal. Sila gunakan peraturan pintar untuk bahasa yang kompleks.",
                strategy: "Strategi:",
              },
            },
            component: {
              approvalSubmittedSuccessfully:
                "Kelulusan dan penyerahan yang berjaya",
            },
            components: {
              pageHeaderExtra: {
                test: "Ujian",
                formal: "Secara rasmi",
                submit: "Hantar",
              },
              createModal: { index: { myRules: "Peraturan saya" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Automasi Proses",
              screeningCondition: "Tapis keadaan",
              aiScreeningCondition: "Keadaan penapis pintar",
              rule: "Peraturan",
              automaticAddition: "Tambah secara automatik",
              automaticAdditionTip:
                "Tambahkan data secara automatik ke tugas dalam masa nyata atau masa untuk pemeriksaan kualiti AI",
              autoAdd: {
                autoAddType: "Kaedah penambahan automatik",
                addFrequency: "Tambah kekerapan",
                add: "Tambah",
              },
              automaticSamplingInspection: "Pensampelan automatik",
              automaticSamplingInspectionTip:
                "Ekstrak data secara automatik yang memerlukan pemeriksaan kualiti manual, misalnya, pilih 10% data rawak semalam untuk pemeriksaan rawak manual setiap hari",
              everyAgentCheckLimit:
                "Had atas untuk setiap perkhidmatan pelanggan",
              automaticAllocation: "Peruntukan automatik",
              automaticAllocationTip:
                "Pensampelan dan pengedaran: Data yang diekstrak secara automatik diedarkan kepada pemeriksa kualiti. Contohnya, 10% data rawak semalam diambil setiap hari untuk pemeriksaan rawak manual, dan rata-rata 2 pemeriksa kualiti ditugaskan secara rawak untuk tugas ini.",
              automaticAllocationTip2:
                "Tugasan tinjauan: Data rayuan secara automatik diperuntukkan kepada pemeriksa kualiti untuk disemak. Contohnya, data rayuan harian diperuntukkan secara rawak kepada 2 pemeriksa kualiti tugas ini untuk disemak.",
              automaticPush: "Tolak automatik",
              automaticPushTip:
                "Pemberitahuan pencetus: Pemberitahuan/penggera semasa pemeriksaan kualiti. Contohnya, apabila perkhidmatan pelanggan mempunyai kesalahan fatal, hantarkan mesej pemberitahuan ke kumpulan mikro syarikat",
              automaticPushTip2:
                "Laporan berkala: Kirim laporan berkala mengenai hasil pemeriksaan kualiti melalui e-mel, misalnya, hantarkan hasil pemeriksaan kualiti pemeriksaan rawak manual semalam ke peti surat penyelia perkhidmatan pelanggan pada pukul 9:00 setiap hari",
              automaticAssess: "Penilaian automatik",
              automaticAssessTip:
                "Secara berkala, hasil pemeriksaan rawak manual dibandingkan dengan hasil pemeriksaan kualiti AI untuk penilaian ketepatan. Sebagai contoh, pada pukul 8:00 setiap hari, data yang diambil sampelnya semalam dinilai tepat",
              reexaminationComplaint: "Ulasan rayuan",
              spoktReviewDetail: "Konfigurasi perniagaan",
              spoktReviewDetailTip:
                "Konfigurasi fungsional untuk senarai pemeriksaan kualiti, senarai pemeriksaan rawak, perkhidmatan rayuan, senarai ulasan, komen, dll.",
              qaDetailAction: "Konfigurasi pemeriksaan kualiti",
              spoktDetailAction: "Konfigurasi pensampelan",
              reviewDetailAction: "Semak konfigurasi",
              appealAction: "Konfigurasi rayuan",
              remarkTemplate: "Templat komen",
              automaticArchiving: "Arkib automatik",
              conditionalRules: "Peraturan keadaan",
              qualityInspectionConditions: "Keadaan pemeriksaan kualiti",
              unopened: "Belum dibuka",
              sampleInspectionDistributionModel: "Persampelan dan pengedaran",
              reviewAllocationModel: "Kaji semula peruntukan",
              distributionModelTitle: "Strategi peruntukan:",
              pushRules: "Tolak peraturan",
              push: "Tolak",
              assessRules: "Peraturan penilaian",
              on: "on",
              off: "off",
              updateStatusSuccess: "Perubahan status berjaya!",
              updateStatusFailed:
                "Perubahan status gagal, sila cuba lagi kemudian!",
              sampleNumberOrPercent: "Bilangan atau perkadaran sampel",
              inspectorList: "Pemeriksa kualiti yang ditetapkan",
              numberOfSubmission: "Bilangan aduan yang dibenarkan",
              rewivewDays: "Hari",
              TimeOfSubmission: "Tempoh sah penyerahan",
              rewivewSecond: "Kali",
              switchOn: "Buka",
              switchOff: "Tutup",
              notMatchAnyRule:
                "Apabila tidak sepadan dengan peraturan sewenang-wenangnya:",
              appealOptionsList: {
                hiddenInspector:
                  "Rayuan menyembunyikan maklumat pemeriksa kualiti",
                hiddenHit: "Halaman rayuan menyokong sorotan",
              },
              caseLibrary: "Penyegerakan perpustakaan kes",
              caseLibrarySyncTip:
                "Segerakkan kes yang ditambahkan ke sistem QA ke sistem perkhidmatan pelanggan",
              addCase: "Meningkatkan penyegerakan",
              caseLibraryId: "Perpustakaan kes",
              caseLibraryIdTip: "Kumpulan perpustakaan kes sistem QA",
              udeskCaseLibraryIdTip:
                "Kumpulan kes sistem perkhidmatan pelanggan",
              syncHis: "Kes sejarah segerak",
              otherSettings: "Tetapan lain",
              udeskCaseLibraryId: "Tolak kumpulan kes",
              url: "Tolak URL",
              urlTip:
                "Sila masukkan alamat antara muka yang bermula dengan http:// atau https://",
            },
            templateNew: {
              secondaryReview: "Kajian sekunder",
              numberOfAppealsAllowedToBeSubmitted: "Bilangan aduan dibenarkan",
              validityOfSubmissionOfAppeal: "Tempoh sah mengemukakan rayuan",
              validityOfAppealReview: "Tempoh sah semakan rayuan",
              hours: "Jam",
              businessSummary: "Ringkasan perniagaan",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Had bawah setiap perkhidmatan pelanggan",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Ringkasan perkhidmatan penjanaan automatik oleh model besar, dimensi penjanaan dapat disesuaikan mengikut keadaan perniagaan",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Hanya strategi peruntukan pengulas tahap pertama",
              secondaryCompounding: "Komposit sekunder:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Juruaudit sekunder hanya menyokong pengagihan purata",
              secondLevelReviewer: "Juruaudit Tahap 2:",
              samplingInspectionTarget: "Sasaran persampelan",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Tetapkan sasaran yang harus diselesaikan untuk pemeriksaan rawak manual, dan berikan petunjuk apabila sasaran tidak tercapai atau dilampaui. Contohnya, setiap perkhidmatan pelanggan harus menyelesaikan 100 pemeriksaan rawak setiap bulan, dan apabila jumlahnya kurang dari 80, halaman pemeriksaan dan pemeriksaan kualiti akan meminta.",
            },
            component: {
              verificationFailed: "Pengesahan gagal",
              pleaseAddASecondLevelQualityInspector:
                "Sila tambah pemeriksa kualiti tahap kedua",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Peringatan",
                    fieldName: "Nama bidang",
                    fieldDescription: "Penerangan bidang",
                    generateContent: "Menjana kandungan",
                    enabled: "Sama ada untuk mengaktifkan",
                    generationMethod: "Kaedah penjanaan",
                    automaticGeneration: "Dihasilkan secara automatik",
                    manualTrigger: "Pencetus manual",
                    filterCondition: "Tapis keadaan",
                    return: "Kembali",
                    preserve: "Simpan",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Menjana Keputusan" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Tidak terhad",
                    limitedScope: "Julat terhad",
                    add: "Tambah",
                    thereIsCurrentlyNoDataAvailable: "Tiada data",
                  },
                },
              },
              index: {
                fieldName: "Nama bidang",
                fieldDescription: "Penerangan bidang",
                nothing: "Tiada",
                generationMethod: "Kaedah penjanaan",
                generateManually: "Dihasilkan secara manual",
                automaticGeneration: "Dihasilkan secara automatik",
                generateContent: "Menjana kandungan",
                notQualified: "Tidak terhad",
                filterCondition: "Tapis keadaan",
                enabled: "Sama ada untuk mengaktifkan",
                type: "Jenis",
                system: "Sistem",
                custom: "Adat",
                operation: "Operasi",
                edit: "Edit",
                areYouSureToDeleteIt: "Adakah anda pasti akan menghapusnya?",
                delete: "Padamkan",
                addField: "Meningkatkan medan",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Konfigurasi peranan",
              roleConfigTip:
                "Ia digunakan untuk memisahkan dan mengkonfigurasi peraturan pengenalan untuk pemanggil apabila data panggilan yang diimport adalah mono. Pematuhan dengan peraturan adalah peranan perkhidmatan pelanggan",
              operator: "Pengendali",
              operatorLogic: "Logik pengendali",
            },
          },
          manualAdd: {
            index: {
              name: "Tambah secara manual",
              tip: "Digunakan untuk menyaring data pratonton dengan mengkonfigurasi keadaan secara manual dan menambahkannya dengan cepat ke tugas pemeriksaan kualiti",
              screeningCondition: "Tambah mengikut kriteria penapis",
              aiScreeningCondition: "Keadaan penapis pintar",
              rule: "Peraturan",
              createTime: "Tambah masa",
              filterTitle: "Menapis kandungan",
              filterRule: "Tapis keadaan",
              startTime: "Masa mula",
              endTime: "Masa tamat",
              records: "Import rekod",
              createUserName: "Pengendali",
              successCount: "Tambah kejayaan",
              conditionRule: "Peraturan keadaan:",
              preview: "Pratonton",
              previewData:
                "Saat ini memenuhi kriteria penyaringan {0}, tugas {1} telah ditambahkan, tugas {2} dapat ditambahkan, tugas {3} tidak dapat ditambahkan",
              failCount: "Gagal menambah",
            },
          },
          basicInfo: {
            index: {
              title: "Maklumat asas",
              name: "Nama",
              taskName: "Nama tugas",
              taskNamePlaceholder: "Sila masukkan nama tugas",
              requiredName: "Nama tugas tidak boleh kosong",
              taskRemarkPlaceholder: "Sila masukkan keterangan tugas",
              requiredRemark: "Penerangan tugas tidak boleh kosong",
              remark: "Penerangan",
              taskRemark: "Penerangan tugas",
              source: "Sumber data",
              benchmarkScore: "Skor asas",
              benchmarkScorePlaceholder: "Sila masukkan mata asas",
              scoreLowerLimit: "Had skor minimum",
              scoreUpperLimit: "Skor maksimum",
              inspectType: "Jenis pemeriksaan kualiti",
              nlpModel: "Model NLP",
              commonInspect: "Pemeriksaan kualiti biasa",
              associateInspect: "Pemeriksaan kualiti yang berkaitan",
              associateCycle: "Kitaran persatuan",
              associateRegulation: "Peraturan persatuan",
              judgeStrategy: "Logik pelaksanaan",
              rule: "Peraturan",
              commonTip:
                "Pemeriksaan kualiti rutin: sesuai untuk komunikasi tunggal, dialog, dan pemeriksaan kualiti kandungan komunikasi tunggal",
              associateTip:
                "Pemeriksaan kualiti yang berkaitan: sesuai untuk pelbagai komunikasi dan dialog, dan menghubungkan kandungan pelbagai komunikasi untuk pemeriksaan kualiti",
              cycleTip:
                "Sekiranya banyak komunikasi dan dialog yang berkaitan sesuai dengan data dalam kitaran korelasi, masukkan pemeriksaan kualiti tugas",
              scoreLowerLimitPlaceholder: "Sila masukkan had skor minimum",
              scoreUpperLimitPlaceholder: "Sila masukkan had skor maksimum",
              call: "Panggilan suara",
              dialogue: "Perbualan teks",
              user: "Pemeriksa kualiti",
              wechatUser: "Pengendali",
              noDesignated: "Tidak dinyatakan",
              type: "Jenis Borang",
              singleScore: "Skor tunggal",
              conduct:
                "Sila lengkapkan maklumat berikut terlebih dahulu, sila klik Seterusnya setelah selesai",
              conduct2:
                "Lengkapkan peraturan pemeriksaan kualiti dan konfigurasi titik pemeriksaan kualiti. Sekiranya anda telah menyelesaikan konfigurasi, anda boleh mengklik Seterusnya atau keluar dari panduan",
              conduct3:
                "Penilaian pemeriksaan kualiti adalah konfigurasi penilaian berdasarkan titik pemeriksaan kualiti dan penilaian mereka. Anda boleh menentukan tahap hasil pemeriksaan kualiti yang berbeza. Sekiranya anda tidak memerlukan penilaian, anda boleh melangkau langkah ini secara langsung. Sekiranya anda telah menyelesaikan konfigurasi, anda boleh mengklik Seterusnya atau keluar dari panduan",
              conduct4:
                "Anda boleh mengkonfigurasi ciri data yang akan diperkenalkan secara automatik sesuai dengan ciri data yang harus diperiksa oleh tugas pemeriksaan kualiti ini di lokasi penambahan automatik. Sekiranya anda tidak perlu menambahkan data secara automatik buat masa ini, anda boleh mengklik untuk melangkau. Sekiranya anda telah menyelesaikan konfigurasi, Anda boleh mengklik Seterusnya",
              conduct5:
                "Sekiranya anda menggunakan rakaman mono, pastikan untuk mengkonfigurasi ini dan klik Seterusnya, sila gunakan perkhidmatan pelanggan sahaja yang akan digunakan untuk membantu sistem mengenal pasti trek perkhidmatan pelanggan dan pelanggan yang berbeza. Sekiranya anda menggunakan rakaman dua saluran atau melengkapkan konfigurasi, anda boleh mengklik untuk melangkau",
              conduct6:
                "Anda boleh menggunakan fungsi penambahan manual untuk memperkenalkan data komunikasi yang ada dalam sistem untuk pemeriksaan kualiti, atau anda telah menyerahkan tugas penambahan manual, sila klik Lengkapkan",
              helper: "Lihat dokumen bantuan",
              quit: "Keluar dari panduan",
              next: "Langkah seterusnya",
              complete: "Selesai",
              skip: "Langkau",
              cancel: "Batalkan",
              delete: "Padamkan",
              save: "Simpan",
            },
            template: {
              samplingInspector: "Persampelan",
              sessionType: "Jenis sesi",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Meja kerja pemeriksaan kualiti",
              search: "Penapis",
              export: "Eksport",
              condition: "Keadaan",
              logic: "Logik",
              reQualityInspection: "Pemeriksaan semula",
              reQualityInspectionUnderWay: "Tugas pemeriksaan semula...",
              reQualityInspectionUnderWayTip:
                "Meninggalkan halaman setelah menutup tetingkap tidak akan mempengaruhi tugas",
              reQualityInspectionDone: "Pemeriksaan semula selesai",
              reQualityInspectionDoneTip:
                "Pemeriksaan semula telah berjaya diselesaikan, sila klik butang sahkan",
              endReQualityInspection: "Menamatkan misi",
              alreadyDoneTip: "Selesai",
              totalTip: "Jumlah",
              unit: "Artikel",
              endReInspectionSuccess:
                "Tugas pemeriksaan semula telah ditamatkan",
              closeWindow: "Tutup tetingkap",
              endReInspectionFailed:
                "Tugas pemeriksaan kualiti semula gagal, sila cuba lagi kemudian!",
            },
            detail: {
              name: "Perincian pemeriksaan kualiti",
              reviewName: "Semak butiran",
              spotCheckName: "Perincian persampelan",
              audioText: "Rakaman Teks",
              callInfo: "Maklumat panggilan",
              satisfactionEvaluation: "Penilaian kepuasan:",
              queueTime: "Beratur memakan masa:",
              ringingTime: "Masa deringan:",
              callTime: "Acara panggilan:",
              hangUpIdentity: "Pihak panggilan:",
              location: "Atribusi:",
              source: "Sumber:",
              agent: "Perkhidmatan Pelanggan:",
              deviceType: "Jenis peranti:",
              callType: "Jenis panggilan:",
              phoneNumber: "Nombor geganti:",
              task: "Tugas:",
              commentsTip: "Sila masukkan komen pemeriksaan kualiti",
              lastPage: "Sebelumnya",
              nextPage: "Seterusnya",
              score: "Skor pemeriksaan kualiti",
              review: "Kaji semula",
              file: "Arkib",
              changeList: "Tukar rekod",
              fullMarks: "Markah penuh",
              most: "Paling banyak",
              saveReview: "Hantar semakan",
              saveSpotCheck: "Penyerahan pensampelan",
              point: "Titik pemeriksaan kualiti",
              sampleAssignInspector: "Tugaskan pemeriksa rawak",
              reviewAssignInspector: "Tugaskan pengulas",
              sampleStatus: "Status persampelan",
              read: "Telah dibaca",
              user: "Pengguna",
              confirmer: "Orang yang disahkan",
              voiceDownloadError:
                "Muat turun suara gagal, sila cuba lagi kemudian!",
              smartTags: "Label pintar",
              interactiveTip:
                "Gunakan pengenalan interaktif untuk mengesan penggunaan FAQ perkhidmatan pelanggan",
              interactiveIsEmptyTip:
                "Kandungan pengenalan interaktif tidak dikesan",
              businessRecords: "Rekod perniagaan",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Analisis sembang kumpulan WeChat perusahaan",
            },
          },
        },
      },
      cards: {
        health: "Kesihatan pemeriksaan kualiti",
        accuracy: "Cari kadar kelulusan",
        sessionsTotal: "Jumlah sesi",
        enable: "Aktifkan",
        disable: "Lumpuhkan",
        none: "Tiada",
        call: "Panggilan",
        dialogue: "Dialog",
        taobao: "E-dagang",
        wecom: "Sesi",
        ticket: "Perintah kerja",
      },
      component: {
        backup: "(Sandaran)",
        taskSaveAsSucceeded: "Tugas disimpan sebagai kejayaan",
        copyContent: "Salin kandungan",
        essentialInformation: "Maklumat asas",
        ruleConfiguration: "Konfigurasi peraturan",
        basicInformationQualityInspector: "Pemeriksa Kualiti Maklumat Asas",
        qualityInspectionRating: "Penilaian pemeriksaan kualiti",
        roleConfiguration: "Konfigurasi peranan",
        autoAddConfiguration: "Menambah konfigurasi secara automatik",
        autoPushConfiguration: "Konfigurasi tolak automatik",
        automaticSamplingConfiguration: "Konfigurasi pensampelan automatik",
        automaticAllocationConfiguration: "Konfigurasi peruntukan automatik",
        businessConfiguration: "Konfigurasi perniagaan",
        caseBaseConfiguration: "Perpustakaan kes",
      },
      template: {
        cancel: "Batalkan",
        complete: "Selesai",
        sort: "Susun",
        ruleApproval: "Kelulusan peraturan",
        enterpriseMicroConversationAnalysisTask:
          "Tugas analisis sesi mikro perusahaan",
        enterpriseAndMicroVoiceAnalysisTask:
          "Tugas analisis suara mikro perusahaan",
        saveTaskAs: "Simpan tugas sebagai",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Perincian peraturan" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Pengubahsuaian berjaya",
            automaticApproval: "Kelulusan automatik",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Pengubahsuaian berjaya",
            test: "Ujian",
            formal: "Secara rasmi",
            adopt: "Melalui",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Adakah anda pasti akan menolaknya?",
              pleaseEnterTheRejectionReason: "Sila masukkan alasan penolakan",
              reject: "Tolak",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Pengubahsuaian berjaya",
              taskName: "Nama tugas",
              dataSource: "Sumber data",
              qualityInspectionType: "Jenis pemeriksaan kualiti",
              state: "Status",
              updateTime: "Masa kemas kini",
              updatedBy: "Kemas kini orang",
              approver: "Orang yang diluluskan",
              reasonForRejection: "Sebab penolakan",
              operation: "Operasi",
              details: "Butiran",
              adopt: "Melalui",
              whole: "Semua",
              approvalStatus: "Status kelulusan",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Gambaran keseluruhan",
      hi: "Hai,",
      standardEdition: "Edisi Standard",
      call: "Baki pemeriksaan kualiti suara",
      text: "Tempoh sah pemeriksaan kualiti teks",
      collectionTasks: "Koleksi tugas pemeriksaan kualiti",
      noCollection: "Tidak ada tugas pemeriksaan kualiti koleksi,",
      goToCollecte: "Pergi ke koleksi",
      yesterdayOverView: "Statistik perniagaan semalam",
      yesterdayAgent: "Kedudukan perkhidmatan pelanggan semalam",
      yesterdayInspWork: "Kerja pemeriksa kualiti semalam",
      operationalStatistics: {
        title: "Statistik Perniagaan",
        qualityTesting: "Pemeriksaan kualiti",
        spotCheck: "Persampelan",
        appeal: "Rayuan",
        chartTitles: {
          qualityTestingTrend: "Trend pemeriksaan kualiti",
          qualityTestingAnalysis: "Tugas pemeriksaan kualiti",
          spotCheckTrend: "Trend persampelan",
          spotCheckAnalysis: "Tugas pemeriksaan rawak",
          appealTrend: "Trend rayuan",
          appealAnalysis: "Status tugas rayuan",
        },
        titles: {
          qualityDataTend: "Pengedaran data pemeriksaan kualiti",
          qualityCover: "Liputan pemeriksaan kualiti",
          qualityAverageTime: "Purata masa menunggu pemeriksaan kualiti",
          qualityCoverTend: "Trend liputan pemeriksaan kualiti",
          handCover: "Pengendalian manual",
          checkCover: "Kadar liputan persampelan",
          resAverageTime: "Tempoh pemprosesan purata",
          handTend: "Trend pemprosesan manual",
          dataTend: "Pengagihan tugas data",
        },
        qualityAverage: "Skor purata pemeriksaan kualiti",
        scoreTrend: "Trend pemarkahan pemeriksaan kualiti",
        qualityAverageTime: "Purata masa menunggu pemeriksaan kualiti",
        callCoverage: "Liputan pemeriksaan kualiti suara",
        textCoverage: "Liputan pemeriksaan kualiti teks",
        checkCount: "Persampelan jumlah data",
        siphonCount: "Ekstrak data",
        reviewCount: "Kaji semula jumlah data",
        appealCount: "Jumlah data rayuan",
        checkRate: "Nisbah pelaksanaan persampelan",
        reviewRate: "Nisbah semakan rayuan",
        PieTitle: {
          totalCount: "Jumlah dialog",
          totalCall: "Bilangan panggilan suara",
          totalIm: "Bilangan perbualan teks",
          inspectionCall: "Kuantiti pemeriksaan kualiti suara",
          inspectionIm: "Kuantiti pemeriksaan kualiti teks",
          checkCall: "Persampelan suara",
          unCheckCall: "Suara tidak diambil sampel",
          checkIm: "Persampelan teks",
          unCheckIm: "Teks tidak diambil sampel",
          reviewCall: "Ulasan suara",
          reviewIm: "Jumlah semakan teks",
          unReviewCall: "Jumlah suara yang tidak disemak",
          unReviewIm: "Jumlah teks yang tidak disemak",
          unInspectionCall: "Kualiti suara tidak diperiksa",
          unInspectionIm: "Teks tidak diperiksa",
        },
        check_rate: "Kadar liputan persampelan",
        voice_check_rate: "Liputan persampelan suara",
        text_check_rate: "Kadar liputan persampelan teks",
        sample_execute_time: "Purata masa pemprosesan pensampelan",
        review_execute_time: "Purata masa pemprosesan aduan",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Papan peraturan pemeriksaan kualiti",
          agent: "Kedudukan Perkhidmatan Pelanggan",
        },
        tableTips: {
          conditions:
            "<Div> Susun jumlah dialog umum mengenai pelanggaran dan pematuhan peraturan pemeriksaan kualiti. <Br/>\nItem pemeriksaan kualiti: nama pusat pemeriksaan kualiti <br />\nJumlah dialog haram: Jumlah dialog haram pada item pemeriksaan kualiti <br />\nBahagian pelanggaran: bahagian pelanggaran = jumlah dialog pelanggaran/jumlah dialog </div>",
          agent:
            "<Div> Menyusun penilaian purata bagi tempat duduk <br />\nNama perkhidmatan pelanggan: Nama perkhidmatan pelanggan <br />\nKumpulan perkhidmatan pelanggan: Kumpulan perkhidmatan pelanggan (maklumat organisasi dalam maklumat perkhidmatan pelanggan)<br />\nJumlah dialog yang dinilai: Jumlah dialog pemeriksaan kualiti perkhidmatan pelanggan <br />\nSkor purata pemeriksaan kualiti: Skor purata pemeriksaan kualiti perkhidmatan pelanggan ini (jumlah skor/jumlah perbualan)</div>",
        },
        tips: {
          total:
            "Jumlah komunikasi/dialog untuk memasuki tugas pemeriksaan kualiti",
          agentTotal:
            "Jumlah perkhidmatan pelanggan pemeriksaan kualiti yang memasuki tugas pemeriksaan kualiti",
          avg: "Skor purata skor pemeriksaan kualiti",
          violation:
            "Jumlah pelanggaran dalam semua pelanggaran pemeriksaan kualiti",
          compliance:
            "Jumlah pematuhan item pemeriksaan kualiti semasa proses pemeriksaan kualiti dialog",
          check: "Jumlah pemeriksaan rawak manual",
          appeal: "Jumlah aduan yang dikemukakan dalam jumlah dialog",
          review: "Jumlah keseluruhan dialog yang dikemukakan untuk disemak",
        },
        subTips: {
          violation:
            "Kadar pelanggaran: jumlah pelanggaran/jumlah item pemeriksaan kualiti * 100%",
          compliance:
            "Kadar pematuhan: jumlah masa pematuhan/jumlah masa pemeriksaan kualiti * 100%",
          check:
            "Kadar liputan persampelan: jumlah pemeriksaan rawak manual/jumlah pemeriksaan kualiti * 100%",
          appeal:
            "Kadar aduan: jumlah rayuan/jumlah pemeriksaan kualiti * 100%",
          review:
            "Kadar tinjauan: jumlah tinjauan/jumlah pemeriksaan kualiti * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Tetapan sistem",
          systemName: "Nama sistem",
          speakerRecognize: "Pengiktirafan Pembicara",
          massage: "Pemberitahuan mesej",
          evaluate: "Penilaian kognitif buatan",
          evaluateTip:
            "Setelah dibuka, pilih persampelan cepat dalam perincian pemeriksaan kualiti dan akan meminta penilaian manual",
          speakerRecognizeTip:
            "Untuk mengakses rakaman panggilan pihak ketiga melalui API, perlu mengenal pasti sama ada saluran kiri adalah perkhidmatan pelanggan atau pelanggan",
          speakerRecognizeTip2:
            "Rakaman saluran tunggal dikenali oleh teks, dan konfigurasi peranan menentukan peranan perkhidmatan pelanggan",
          enabledImSendStatusFilter: "Penapisan mesej pemeriksaan kualiti teks",
          enabledImSendStatusFilterTips:
            "Kandungan ujian aritmetik pemeriksaan kualiti tidak mengandungi mesej dengan status [ditarik balik], [gagal menghantar], dan [mesej luar talian belum dibaca]",
          lang: "Tetapan bahasa sistem",
          zh: "Bahasa Cina Ringkas",
          en: "English",
          tw: "Cina Tradisional",
        },
        template: {
          largeModelSettings: "Tetapan model besar",
          indonesian: "Bahasa Indonesia",
          vietnamese: "Vietnam",
          portuguese: "Bahasa Portugis",
          malay: "Bahasa Melayu",
          french: "Bahasa Perancis",
          spanish: "Bahasa Sepanyol",
          japanese: "Bahasa Jepun",
          thai: "Bahasa Thai",
          blockSystemSendingMessages: "Sistem perisai menghantar mesej",
          textDataDesensitization: "Data teks desensitisasi",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Buka suis untuk memaparkan senarai drop-down medan desensitisasi (menyokong pelbagai pilihan), medan desensitisasi: nama, nombor telefon bimbit, alamat",
          desensitizingField: "Medan desensitisasi",
          name: "Nama orang",
          cellphoneNumber: "Nombor telefon",
          address: "Alamat",
          dataCleaning: "Pembersihan data",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Setelah menetapkan, data perbualan pemeriksaan kualiti dan perbualan WeChat korporat akan dibersihkan secara berkala dan tidak dapat dipulihkan. Harap berhati-hati",
          newCleaningRules: "Peraturan pembersihan baru",
          qualityInspectionRuleApproval:
            "Kelulusan peraturan pemeriksaan kualiti",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Setelah dibuka, pengubahsuaian peraturan pemeriksaan kualiti perlu disetujui sebelum dapat berlaku",
          maskOfflineMessageUnread: "Sekat mesej luar talian yang belum dibaca",
          privacyNumberProtection: "Perlindungan nombor privasi",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Setelah dibuka, medan "Telefon Pelanggan" disensitiviti dan dipaparkan sebagai 1881 *******',
          shieldingWithdrawn: "Perisai telah ditarik balik",
          maskSendingFailed: "Gagal menyekat penghantaran",
        },
        component: {
          conversation: "Panggilan",
          dialogue: "Dialog",
          weCom: "WeChat Korporat",
          day: "Hari",
          week: "Minggu",
          month: "Bulan",
          year: "Tahun",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Julat pembersihan",
            dataCleaningTimeSetting: "Tetapan masa pembersihan data",
            afterCleanUpTheData: "Selepas itu, bersihkan data",
            dataAfterComplaintCompletion: "Data selepas rayuan selesai",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Pelaksanaan yang berjaya",
          saveSuccessful: "Simpan dengan jayanya",
          speechRecognition: "Pengiktirafan pertuturan",
          reExecuteErrorCorrection: "Laksanakan semula pembetulan ralat",
          confirmExecution: "Sahkan pelaksanaan",
          voiceDetection: "Pengesanan suara",
          noiseDetectionPowerLevel: "Tahap powerpengesanan bunyi ≤",
          pleaseEnterTheThreshold: "Sila masukkan ambang",
          volumeDetectionPowerLevel: "Tahap pengesanan kelantangan ≥",
          reset: "Tetapkan semula",
          preserve: "Simpan",
        },
        coachTemplate: {
          french: "Bahasa Perancis",
          spanish: "Bahasa Sepanyol",
          japanese: "Bahasa Jepun",
          thai: "Bahasa Thai",
          loginPopupWindow: "Tetingkap pop timbul",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Setelah dibuka, tetingkap pop timbul akan diminta ketika memasuki sistem sparring pintar",
          title: "Tajuk",
          text: "Badan",
          checkBoxContent: "Periksa kandungan kotak",
          informedConsentForm: "Persetujuan yang dimaklumkan",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Untuk membantu anda meningkatkan kemahiran komunikasi dan pertuturan serta penggunaan pengetahuan profesional, yang membolehkan anda berlatih kapan saja, di mana sahaja dan menerima maklum balas masa nyata, kami akan mengumpulkan maklumat berikut melalui platform ini:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Saya telah membaca dan memahami sepenuhnya borang persetujuan yang dimaklumkan ini, dan dengan sesungguhnya menyatakan bahawa saya bersetuju untuk memproses maklumat peribadi saya yang sensitif mengikut tujuan dan kaedah yang dijelaskan dalam borang persetujuan yang dimaklumkan ini",
        },
      },
      systemIntegration: {
        title: "Tetapan sistem",
        ccIntegrationTips:
          "Dokumen arahan pusat panggilan Udesk dok pemeriksaan kualiti pintar",
        unauthorized: "Tidak dibenarkan",
        authorized: "Dibenarkan",
        udeskDomain: "Nama domain sistem perkhidmatan pelanggan",
        udeskToken: "Kunci sistem perkhidmatan pelanggan",
        udeskAdminEmail: "Akaun pentadbir sistem perkhidmatan pelanggan",
        password: "Kata laluan akaun pentadbir",
        applyAuth: "Kebenaran",
        cancelAuth: "Membatalkan kebenaran",
        reApplyAuth: "Kebenaran semula",
        keyTips:
          "Pengurusan latar belakang sistem perkhidmatan pelanggan-pengesahan API Token dalam log masuk tunggal",
      },
      customerField: {
        title: "Bidang pelanggan",
        table: {
          propName: "Nama bidang",
          propDesc: "Penerangan bidang",
          valueType: "Jenis bidang",
          propType: "Kaedah penciptaan",
          createTime: "Buat masa",
          canNotEdit: "Medan sistem tidak menyokong penyuntingan",
        },
        edit: "Edit bidang",
        new: "Medan baru",
      },
      wechatDocking: {
        title: "Pemasangan perusahaan dan mikro",
        formLabels: {
          companyId: "ID Korporat",
          communicationKey: "Kunci komunikasi",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL panggilan balik acara",
          applicationName: "Nama permohonan",
          applicationId: "ID aplikasi web",
          applicationSecret: "Aplikasi secret",
        },
        formTitle: {
          initialForm: "Tetapan awal",
          synchronizeForm: "Penyegerakan buku alamat",
          settingForm: "Tetapan aplikasi",
        },
        qrCodeUrlTip:
          "Sila imbas kod QR di bawah untuk mengikat aplikasi pembangunan",
      },
      taobao: {
        formLabels: {
          name: "Nama kedai",
          account: "Akaun",
          password: "Kata laluan",
          confirm: "Sahkan kata laluan",
          authorization: "Kebenaran",
          switchOn: "Buka",
          switchOff: "Tutup",
        },
        tableLabels: {
          name: "Nama kedai",
          account: "Akaun",
          authorization: "Kebenaran",
          updateUser: "Orang yang baru dikemas kini",
          updateTime: "Masa kemas kini terkini",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Nama domain tahap kedua Weifeng",
          secretKeyTip:
            "Kunci rahsia penyewa, dapatkan di dok sistem back-end sistem Weifeng",
        },
      },
      user: {
        index: {
          name: "Pengurusan pekerja",
          existingUsers: "Pekerja yang ada",
          deleteSuccess: "Penghapusan berjaya",
          deleteFailed: "Gagal memadam",
        },
        detail: {
          accountPrompt: "Sila masukkan e-mel",
          resetPassword: "Tetapkan semula kata laluan",
          passwordPrompt: "Kata laluan (gabungan huruf dan nombor 6-14 digit)",
          originPasswordPrompt: "Sila masukkan kata laluan awal",
          resumePasswordPrompt: "Sila masukkan semula kata laluan",
          passwordFormWrong:
            "Sila masukkan kata laluan untuk gabungan 6-14 huruf dan nombor!",
          resumePasswordWrong: "Kata laluan tidak konsisten dua kali!",
          suppertImageType: "Menyokong jpg, gif, png, maksimum 500Kb",
          namePrompt: "Sila masukkan nama anda",
          nickNamePrompt: "Sila masukkan nama samaran",
          telephonePrompt: "Sila masukkan nombor telefon",
          roleSelectedPrompt: "Sila pilih peranan",
          agentIdPrompt: "Sila masukkan nombor kerja",
          disabled: "Lumpuhkan",
          enabled: "Aktifkan",
          reloadBowerInfo:
            "Perubahan maklumat pengguna, adakah saya perlu memuat semula penyemak imbas?",
          saveUserError:
            "Maklumat pengguna gagal disimpan, sila cuba lagi kemudian",
          resetPasswordSuccess: "Kata laluan berjaya diset semula",
          resetPasswordFailed:
            "Kata laluan gagal menetapkan semula, sila cuba lagi kemudian",
        },
      },
      userGroups: {
        index: {
          name: "Pengurusan Kumpulan Kakitangan",
          existingUserGroups: "Kumpulan pekerja sedia ada",
        },
        detail: {},
      },
      role: {
        index: {
          name: "Pengurusan peranan",
          existingRoles: "Peranan yang ada",
        },
        edit: {
          editRole: "Peranan editor",
          createRole: "Mewujudkan Peranan",
          basicInfo: "Maklumat asas",
          taskPermissions: "Kebenaran tugas",
          existingTasks: "Tugas yang ada",
        },
      },
      anomalousData: {
        title: "Data tidak normal",
        list: {
          selectHolder: "Sila pilih sumber data",
          businessTime: "Masa perniagaan",
          businessId: "Perniagaan Is",
          export: "Eksport",
        },
      },
      templateComponent: {
        list: {
          title: "Pasaran templat",
          name: "Nama templat",
          searchHolder: "Sila masukkan kandungan yang ingin anda tanyakan",
          update: "Kemas kini",
          sort: "Kaedah menyusun",
          moduleType: "Jenis Templat",
          moduleSourceType: "Jenis yang berkenaan",
          fetchNum: "Dapatkan bilangan kali",
          newest: "Baru dikemas kini",
          usedMost: "Dapatkan paling banyak",
          call: "Panggilan",
          dialogue: "Dialog",
          waiting: "Untuk disemak",
          cancel: "Batalkan pelepasan",
          success: "Diluluskan dan dikeluarkan",
          failed: "Penolakan audit",
          no: "Tidak boleh ditingkatkan",
          ok: "Boleh ditingkatkan",
          all: "Semua",
          delete: "Penghapusan berjaya",
          downOk: "Berjaya dikeluarkan",
          withdraw: "Pengeluaran berjaya",
          updateOk: "Peningkatan yang berjaya",
          get: "Dapatkan",
          width: "Ditarik balik",
          getOk: "Dapatkan kejayaan",
          orgName: "Organisasi gabungan",
          nowVersion: "Versi semasa",
          lastedVersion: "Versi terkini",
          appStatus: "Status permohonan",
          actions: "Operasi",
          down: "Di luar rak",
          updateTop: "Naik taraf",
          check: "Lihat",
          deleteWhen: "Sahkan penghapusan?",
          yes: "Ya",
          noo: "Tidak",
          deleteOk: "Padamkan",
          permissible: "Dibenarkan",
          noPermissible: "Tidak dibenarkan",
          openUp: "Terbuka",
          openDown: "Tidak terbuka",
          task: "Tugas pemeriksaan kualiti",
          point: "Titik pemeriksaan kualiti",
          tag: "Label semantik",
          muti: "Pelbagai elemen",
          inter: "Satu soalan dan satu jawapan",
          info: "Entiti Maklumat",
          concept: "Perkataan konsep",
          detail: "Perincian modul",
          infomation: "Maklumat asas",
          detailConfig: "Konfigurasi terperinci",
          twoEdit: "Penyuntingan sekunder",
          mustUpdate: "Naik taraf sekarang",
          descition: "Penerangan templat",
          descitionSize:
            "Huraian templat tidak boleh melebihi 250 patah perkataan",
          stencilsDetail: "Perincian templat",
          updatedRecords: "Kemas kini rekod",
          version: "Nombor versi",
          updateDescription: "Kemas kini keterangan",
          myModule: "Templat saya",
          addModule: "Modul baru",
          publishVersion: "Versi pelepasan",
          earliestVersion: "Versi terawal",
          edit: "Edit",
          add: "Tambah",
          configNo: "Perincian konfigurasi modul tidak boleh kosong",
          updateNo: "Arahan kemas kini tidak boleh kosong",
          editSuccess: "Pengubahsuaian berjaya",
          saveSuccess: "Simpan dengan jayanya",
          newer: "Kemas kini",
          sumbit: "Hantar",
          cancelName: "Batalkan",
          nameRequired: "Nama modul adalah medan yang diperlukan",
          nameSize: "Nama modul tidak boleh melebihi 16 perkataan",
          publishStatus: "Status pelepasan",
          submitUpdate: "Hantar kemas kini",
          updateVersion: "Kemas kini versi",
          updateExplain: "Arahan kemas kini",
        },
      },
      customDataSource: {
        amazonEcommerce: "E-dagang Amazon",
        youtubeVideo: "Youtube video",
        amazon: {
          component: {
            queuing: "Beratur",
            notStarted: "Tidak bermula",
            inProgress: "Dalam proses",
            success: "Kejayaan",
            failed: "Kegagalan",
            successfullyCreated: "Dicipta dengan jayanya",
            editedSuccessfully: "Penyuntingan yang berjaya",
            operationSucceeded: "Operasi yang berjaya",
            deletedSuccessfully: "Penghapusan berjaya",
            link: "Pautan",
            grabState: "Status merangkak",
            updateTime: "Masa kemas kini",
            grab: "Merangkak",
            edit: "Edit",
          },
          template: {
            amazonEcommerce: "E-dagang Amazon",
            thisProduct: "Produk ini",
            competition: "Produk bersaing",
            new: "Baru",
            edit: "Edit",
            link: "Pautan",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube video",
            thisProduct: "Produk ini",
            competition: "Produk bersaing",
            new: "Baru",
            edit: "Edit",
            link: "Pautan",
          },
          component: {
            queuing: "Beratur",
            notStarted: "Tidak bermula",
            inProgress: "Dalam proses",
            success: "Kejayaan",
            failed: "Kegagalan",
            successfullyCreated: "Dicipta dengan jayanya",
            editedSuccessfully: "Penyuntingan yang berjaya",
            operationSucceeded: "Operasi yang berjaya",
            deletedSuccessfully: "Penghapusan berjaya",
            youTubeVideoLink: "Pautan Video YouTube",
            grabState: "Status merangkak",
            updateTime: "Masa kemas kini",
            grab: "Merangkak",
            edit: "Edit",
          },
        },
      },
    },
    demo: {
      title: "Pemeriksaan kualiti pintar",
      template: "Pilih templat pemeriksaan kualiti",
      radio: "Pilih format rakaman",
      uploadAudio: "Muat naik rakaman",
      uploadAudioTip:
        "Rakaman muat naik hanya menyokong format wav dan mp3, saiz tidak melebihi 1G",
      uploadText: "Muat naik teks",
      uploadTextTip:
        "Memuat naik teks hanya menyokong format srt dan txt, ukurannya tidak melebihi 10M, setelah memuat naik teks, ASR tidak lagi digunakan untuk menguraikan fail rakaman",
      uploadSuccess: "Fail rakaman berjaya dimuat naik",
      uploadFailed: "Muat naik fail rakaman gagal",
      startQualityInspection: "Mulakan pemeriksaan kualiti",
      qualityInspectionStarted: "Dalam pemeriksaan kualiti",
      downloadText: "Muat turun teks",
      checking: "Kemajuan pemeriksaan kualiti",
      checkingTip: "Ia dijangka mengambil masa beberapa minit, harap bersabar",
      noCheckContent: "Tiada keputusan pemeriksaan kualiti",
      noTextDownload: "Tiada teks pemeriksaan kualiti untuk dimuat turun!",
      pleaseCheckParams:
        "Sila periksa sama ada memilih templat, jenis suara dan memuat naik fail rakaman",
      inspectionFailed: "Pemeriksaan kualiti gagal, sila cuba lagi kemudian!",
    },
    wechatQa: {
      baseInfo: { title: "Maklumat asas" },
      conversationSetting: {
        title: "Tetapan sesi",
        segmentationStrategy: "Strategi segmentasi",
        rule: "Strategi",
        time: "Masa pembahagian",
        daily: "Setiap hari",
        passScore: "Mata kelayakan sesi",
        ruleLogic: "Logik pelaksanaan",
      },
      message: {
        title: "Tolak acara",
        table: {
          index: "Nombor siri",
          name: "Nama",
          type: "Kaedah tolak",
          desc: "Penerangan",
          status: "Status",
        },
        subTitle: "Senarai acara",
      },
      checkPoint: {
        title: "Model analisis",
        groups: "Kumpulan model",
        newClassification: "Kumpulan baru",
        editClassification: "Edit kumpulan",
        benchmarkScore: "Skor asas",
        pointCount: "Model analisis bersama di bawah kategori semasa",
        openPointCount: "Bilangan yang diaktifkan adalah",
        closedPointCount: "Bilangan yang dinyahaktifkan adalah",
        scoreLowerLimit: "Had skor minimum",
        scoreUpperLimit: "Skor maksimum",
        classificationName: "Nama kumpulan",
        classificationNamePlaceHolder: "Sila masukkan nama kumpulan",
        classificationRemark: "Penerangan kumpulan",
        classificationRemarkPlaceHolder: "Sila masukkan keterangan kumpulan",
      },
      chartRecord: {
        title: "Perbualan WeChat Korporat",
        table: {
          id: "ID Sesi",
          type: "Jenis sesi",
          startTime: "Masa permulaan sesi",
          endTime: "Sesi terakhir berakhir",
          groupId: "ID kumpulan",
          client: "Pelanggan",
          customer: "Perkhidmatan Pelanggan",
          averageScore: "Skor pemeriksaan kualiti purata",
        },
        detail: {
          title: "Perincian perbualan WeChat perusahaan",
          inspectionInfo: "Menganalisis maklumat",
          chartInfo: "Maklumat Sesi",
          tasks: "Tugas analisis",
          avgScore: "Analisis purata",
          qualified: "Berkelayakan",
          unqualified: "Tidak berkelayakan",
          taskTitle: "Templat analisis yang berkenaan",
          info: "Analisis maklumat dalam masa nyata",
          today: "Berita hari ini",
          history: "Berita Sejarah",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname:
                  "Sila masukkan nota/nama panggilan",
                pleaseEnterTheGroupChatName:
                  "Sila masukkan nama sembang kumpulan",
              },
            },
          },
          template: { whole: "Semua" },
        },
      },
      realTimeAnalysis: {
        title: "Analisis masa nyata",
        table: {
          name: "Nama pemandangan",
          count: "Bilangan model analisis",
          baseScore: "Skor asas",
          passScore: "Mata yang layak",
          maxScore: "Skor maksimum",
          minScore: "Had skor minimum",
          status: "Status",
          actions: "Operasi",
        },
        detail: {
          title: "Adegan analisis masa nyata",
          form: {
            name: "Nama pemandangan",
            prevCondition: "Keadaan pencetus pemandangan",
            prevConditionToolTip:
              "Sekiranya keadaan kosong, senario ini dianalisis secara lalai",
            prevConditionError:
              "Keadaan pencetus pemandangan tidak boleh bertepatan dengan model analisis",
            scoreInputPlaceholder: "Masukkan pecahan",
            baseScore: "Skor asas",
            status: "Status",
            passScore: "Mata yang layak",
            maxScore: "Had atas skor maksimum",
            minScore: "Had minimum",
            analysisModel: "Model analisis",
            message: "Pemberitahuan pencetus",
            pushType: "Kaedah tolak",
            createAnalysisModel: "Tambah model",
            scoreUpperLimitError:
              "Had bawah skor minimum tidak boleh lebih besar daripada had atas skor maksimum",
            table: { name: "Nama model", rule: "Peraturan", score: "Skor" },
            pushConfigSwitchTip1: "Apabila pemandangan itu muncul",
            pushConfigSwitchTip2: "Kali dan ke atas, tekan pemberitahuan",
          },
        },
      },
      smartCustomerTag: {
        title: "Label pelanggan pintar",
        table: {
          name: "Nama peraturan",
          tags: "Label pelanggan perusahaan dan mikro",
          status: "Aktifkan status",
          createUser: "Pengasas",
          createTime: "Buat masa",
        },
        detail: {
          name: "Nama peraturan",
          condition: "Tetapkan peraturan",
          logic: "Logik",
          status: "Aktifkan status",
          tags: "Pilih label",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: {
            numberOfCustomerTags: "Bilangan pelanggan yang ditandakan",
          },
        },
        messageFiltering: {
          template: {
            edit: "Edit",
            messageContent: "Kandungan mesej",
            messageTypeID: "ID Jenis Mesej",
            messageTypeDescription: "Penerangan jenis mesej",
            messageFiltering: "Penapisan mesej",
            suitableForFilteringDataWithoutQualityInspection:
              "Sesuai untuk menyaring data tanpa pemeriksaan kualiti",
            create: "Buat",
            messageDescription: "Penerangan mesej",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Masa bercakap",
          sessionType: "Jenis sesi",
          whole: "Semua",
          staff: "Pekerja",
          customer: "Pelanggan",
          callKeywords: "Kata kunci panggilan",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Perincian panggilan suara mikro perusahaan",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Arkib sesi",
        voiceCall: "Panggilan suara",
        noMoreVoiceCalls: "Tiada lagi panggilan suara",
        enterpriseWechatVoiceCall: "Panggilan suara Enterprise WeChat",
        enterpriseWechatConversationDetails:
          "Perincian perbualan WeChat perusahaan",
        previous: "Sebelumnya",
        next: "Seterusnya",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Tugas analisis sesi mikro perusahaan",
          enterpriseAndMicroVoiceAnalysisTask:
            "Tugas analisis suara mikro perusahaan",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Analisis sembang kumpulan WeChat perusahaan",
      },
      wechatQaSettingEntry: { messageFiltering: "Penapisan mesej" },
    },
    businessAnalysis: {
      keyword: "Nama laporan",
      createUser: "Pengasas",
      createTime: "Buat masa",
      updateTime: "Masa kemas kini",
    },
    semanticIntelligence: {
      applicationMessageType: "Jenis Mesej Aplikasi",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "Sila lengkapi konfigurasi",
              generatePromptLanguage: "Menjana arahan",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Sama ada memenuhi",
              gradedEvaluation: "Penilaian fail",
            },
          },
          training: {
            index: {
              pleaseEnter: "Sila masukkan",
              pleaseGeneratePrompt: "Sila buat arahan",
              pleaseSelectADialogue: "Sila pilih perbualan",
              training: "Latihan",
              dataType: "Jenis data:",
              voiceCall: "Panggilan suara",
              textDialogue: "Perbualan teks",
              generate: "Menjana",
              qualityInspectionResults: "Hasil pemeriksaan kualiti:",
              hit: "Kemahiran memukul:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Nama model",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Sila masukkan nama model, misalnya: adakah perkhidmatan pelanggan tidak aktif",
            modelDescription: "Penerangan model",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Ia dapat menerangkan secara terperinci apa yang perlu anda uji untuk memudahkan pemahaman model, seperti: apakah layanan pelanggan tidak aktif, iaitu, layanan pelanggan telah menjawab pertanyaan pelanggan, tetapi pertanyaan pelanggan belum diproses di seluruh artikel atau isi balasan tidak ada kaitan dengan balasan pelanggan",
            validity: "Keberkesanan",
            effective: "Berkesan",
            invalid: "Tidak sah",
            type: "Jenis",
            hintLanguage: "Peringatan",
            editIntelligentModel: "Edit model pintar",
            createANewIntelligentModel: "Model pintar baru",
          },
        },
        index: {
          modelName: "Nama model",
          modelDescription: "Penerangan model",
          type: "Jenis",
          whetherItMeetsTheRequirements: "Sama ada memenuhi",
          gradedEvaluation: "Penilaian fail",
          validity: "Keberkesanan",
          intelligentModel: "Model pintar",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Berlaku untuk pemeriksaan kualiti subjektif (seperti sama ada sikap perkhidmatan pemeriksaan kualiti bersemangat, sama ada perkhidmatan itu sabar, dll.)",
        },
      },
      clusterAnalysis: "Analisis kluster",
      speechMining: "Perlombongan perkataan",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Menyokong import kumpulan entiti maklumat melalui antara muka API, nama API tidak menyokong penyuntingan setelah disimpan",
        },
        component: { nothing: "Tiada" },
      },
      knowledgeBase: {
        index: {
          classification: "Pengelasan",
          import: "Import",
          importKnowledgeBase: "Import pangkalan pengetahuan",
          moveKnowledgePointsInBatch: "Titik pengetahuan bergerak secara pukal",
          moveTo: "Pindah ke:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Sila pilih titik pengetahuan yang perlu dipindahkan secara berkumpulan",
        },
        detail: {
          index: {
            intrasentence: "Dalam ayat",
            afterProblem: "Selepas soalan",
          },
        },
      },
      semanticTags: { component: { nothing: "Tiada" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Perkataan sensitif",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Kata-kata sensitif: kata-kata sensitif dapat dikonfigurasi dengan kata-kata yang menjadi perhatian perniagaan, digunakan untuk mencari kata-kata sensitif dalam pendapat umum",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Perkataan sensitif baru",
            editSensitiveWords: "Edit kata-kata sensitif",
            sensitiveWords: "Perkataan sensitif",
          },
          template: {
            sensitiveWords: "Perkataan sensitif",
            sensitiveWordsCannotBeEmpty:
              "Kata-kata sensitif tidak boleh kosong",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Sila masukkan kata-kata sensitif, gunakan ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "Sila pilih senario aplikasi",
            keyWord: "Kata kunci",
            pleaseEnterKeywords: "Sila masukkan kata kunci",
            keywordLengthWithinCharacters: "Panjang kata kunci dalam 40 aksara",
            dataSources: "Sumber data",
            pleaseSelectADataSource: "Sila pilih sumber data",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Pengumpulan semasa telah selesai, penyuntingan semula akan menghasilkan data pengelompokan baru, yang tidak akan menimpa data pengelompokan yang lengkap, sila sahkan",
            savingSucceeded: "Simpan dengan jayanya",
            clusteringStart: "Permulaan kluster",
            clustering: "Pengelompokan",
            edit: "Edit",
            newlyBuild: "Baru",
            clusterName: "Nama kluster",
            pleaseEnterAName: "Sila masukkan nama",
            withinCharactersOfTheName: "Panjang nama dalam 40 aksara",
            speaker: "Penceramah",
            pleaseSelectASpeaker: "Sila pilih penceramah",
            sessionDate: "Tarikh sesi",
            pleaseEnterTheSessionDate: "Sila masukkan tarikh sesi",
            selectUpToMonthOfHistoricalSessionData:
              "Pilih data sesi sejarah sehingga 1 bulan",
            automaticallyClusterDailyNewData:
              "Pengumpulan automatik menambah data setiap hari",
            conversationChannel: "Saluran perbualan",
            pleaseSelectAConversationChannel: "Sila pilih saluran perbualan",
            applicationScenario: "Senario permohonan",
            clusteringRules: "Peraturan pengelompokan",
            incompleteClusteringRuleSettings:
              "Peraturan pengelompokan tidak lengkap",
            startClustering: "Mulakan pengelompokan",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Sila pilih tugas pemeriksaan kualiti",
              beEqualTo: "Sama dengan",
            },
          },
        },
        detail: {
          index: {
            theme: "Tema",
            numberOfCorpora: "Nombor korpus",
            corpusCoverage: "Liputan korpus",
            clusterDetails: "Perincian kluster",
          },
          ranking: {
            hotWords: "Perkataan panas",
            numberOfCorpora: "Nombor korpus",
            speaker: "Penceramah",
            sit: "Duduk",
            passenger: "Tetamu",
            hotSentence: "Ayat panas",
            corpusDetails: "Perincian korpus",
          },
        },
        index: {
          delete: "Padamkan",
          deletionSucceeded: "Penghapusan berjaya",
          clusterName: "Nama kluster",
          speaker: "Penceramah",
          creationTime: "Buat masa",
          state: "Status",
          operation: "Operasi",
          see: "Lihat",
          edit: "Edit",
          clusterAnalysis: "Analisis kluster",
          newCluster: "Pengumpulan baru",
          clusteringStatus: "Keadaan kluster",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Masukkan kata kunci untuk mencari bantahan",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Perpustakaan bercakap",
                recommendedScripts: "Kata-kata yang disyorkan",
                common: "Jumlah",
                strip: "Artikel",
                scriptFavorites: "Kegemaran perkataan",
              },
              conditionPostfix: {
                detectRoles: "Peranan pengesanan",
                detectionRange: "Julat pengesanan",
                sentence: "Ayat",
              },
              customerFilter: {
                scriptFiltering: "Penapisan ucapan",
                cancel: "Batalkan",
              },
              index: {
                viewDetails: "Lihat butiran",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Adakah anda pasti menyerah? Tidak dapat dipulihkan setelah ditinggalkan",
                abandonment: "Menyerah",
                deletionSucceeded: "Penghapusan berjaya",
                passenger: "Tetamu",
                sit: "Duduk",
                adopted: "Diadopsi",
                determine: "OK",
                cancel: "Batalkan",
                adopt: "Diguna pakai",
              },
              pageRightFrom: {
                savingSucceeded: "Simpan dengan jayanya",
                pleaseEnterTheScriptContent:
                  "Sila masukkan kandungan pertuturan",
                pleaseSelectAFavoriteGroup: "Sila pilih kumpulan kegemaran",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Perpustakaan pengendalian bantahan",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript:
                    "Sila pilih untuk menggunakan perkataan",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Kegemaran pengendalian bantahan",
            },
            pageRightTable: {
              likeCancel: "Suka membatalkan",
              likeSuccessfully: "Suka berjaya",
              adoptScripts: "Mengamalkan perkataan",
              operation: "Operasi",
              viewConversationDetails: "Lihat perincian perbualan",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Perpustakaan kemahiran menjual produk",
              },
              pageRightTable: { keyPointsOfScript: "Perkara utama" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Kegemaran pengendalian bantahan",
              newGroup: "Kumpulan baru",
              editGroup: "Edit kumpulan",
              addGroup: "Tambah kumpulan",
              savingSucceeded: "Simpan dengan jayanya",
              groupName: "Nama kumpulan",
              pleaseEnterTheGroupName: "Sila masukkan nama kumpulan",
              groupNameWithinCharacters:
                "Panjang nama kumpulan dalam 20 aksara",
              deletionSucceeded: "Penghapusan berjaya",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings: "Tetapan yang disyorkan",
                },
                rightForm: {
                  savingSucceeded: "Simpan dengan jayanya",
                  intelligentRecommendation: "Cadangan pintar",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Kemahiran pertuturan yang sangat baik yang diterima secara automatik dipaparkan di bar sisi perbualan dengan keberatan yang serupa, dan pekerja dapat melihat kemahiran pertuturan yang disyorkan secara langsung di halaman perincian tinjauan perbualan untuk belajar",
                  objectionsIncludedByDefault:
                    "Bantahan yang disertakan secara lalai",
                  addRecommendationTriggerSemantics:
                    "Tingkatkan semantik pencetus cadangan",
                  remindEmployeesToView: "Ingatkan pekerja untuk melihat",
                  shareWithMoreEmployees: "Kongsi dengan lebih ramai pekerja",
                  allHands: "Semua pekerja",
                },
                smartToolbar: { selectSemanticTag: "Pilih label semantik" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Adakah mengeksport semua rekod data dalam kumpulan semasa?",
                newScript: "Kemahiran baru",
                editingScript: "Edit perkataan",
                savingSucceeded: "Simpan dengan jayanya",
                objection: "Bantahan",
                pleaseEnterAScriptObjection: "Sila masukkan bantahan",
                speechTechnique: "Bercakap",
                pleaseEnterTheScriptContent:
                  "Sila masukkan kandungan pertuturan",
                grouping: "Kumpulan",
                pleaseSelectAFavoriteGroup: "Sila pilih kumpulan kegemaran",
                adoptScripts: "Mengamalkan perkataan",
                passenger: "Tetamu",
                sit: "Duduk",
                numberOfViews: "Bilangan pandangan",
                useful: "Berguna",
                operation: "Operasi",
                edit: "Edit",
                delete: "Padamkan",
                deletionSucceeded: "Penghapusan berjaya",
                scriptRecommendationSettings: "Tetapan yang disyorkan",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Perpustakaan bercakap",
          scriptMining: "Perlombongan perkataan",
          objectionHandlingScripts: "Pengendalian bantahan",
          productSellingPointScripts: "Kemahiran menjual produk",
        },
        sellingPoint: {
          index: { newCluster: "Pengumpulan baru" },
          components: {
            callDetailModal: {
              sessionDetails: "Perincian sesi",
              cancel: "Batalkan",
              determine: "OK",
              choice: "Pilihan",
            },
            pageCenterTable: {
              scriptOverview: "Gambaran Keseluruhan Kemahiran Bercakap",
            },
            pageLeftTable: {
              enterSearchKeywords: "Masukkan kata kunci carian",
              keyWord: "Kata kunci",
            },
            pageRightTable: {
              index: {
                viewDetails: "Lihat butiran",
                scriptLibrary: "Perpustakaan bercakap",
                scriptRefinement: "Penyempurnaan perkataan",
                adoptScript: "Mengamalkan perkataan",
              },
              tagsEdit: { keyPointsOfScript: "Perkara utama" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Perpustakaan kemahiran menjual produk",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Titik penjualan termasuk secara lalai",
                  },
                },
                index: {
                  keyWord: "Kata kunci",
                  pleaseSelectAKeyword: "Sila pilih kata kunci",
                  keyPointsOfScript: "Perkara utama",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Sila masukkan perkara utama",
                  viewDetails: "Lihat butiran",
                  pleaseChooseToAdoptScript:
                    "Sila pilih untuk menggunakan perkataan",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Logik yang sepadan",
            nLPMatching: "Perlawanan NLP",
            strictlyMatchByWord: "Padankan dengan ketat mengikut kata-kata",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Nama jenis mesej",
          messageTypeID: "ID Jenis Mesej",
          messageTypeDescription: "Penerangan jenis mesej",
          applicationMessageType: "Jenis Mesej Aplikasi",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Sangat sesuai untuk mengakses data saluran yang berlainan untuk pemeriksaan kualiti, dan perlu diklasifikasikan mengikut pengenalan saluran",
        },
        detail: {
          index: {
            messageTypeName: "Nama jenis mesej",
            pleaseEnterAMessageTypeName: "Sila masukkan nama jenis mesej",
            messageTypeDescription: "Penerangan jenis mesej",
            describeYourMessageTypeInDetail:
              "Boleh menerangkan jenis mesej anda secara terperinci",
            editMessageType: "Edit jenis mesej",
            newMessageType: "Jenis mesej baru",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Sila pilih ahli pasukan",
        existingFilter: "Penapis sedia ada",
        pleaseSelect: "Sila pilih",
        filterCondition: "Keadaan penapis",
        areYouSureToDeleteTheCurrentFilter:
          "Adakah anda mengesahkan untuk menghapus penapis semasa?",
        sessionDate: "Tarikh sesi",
        staff: "Pekerja",
        customer: "Pelanggan",
        pleaseEnterCustomerName: "Sila masukkan nama pelanggan",
        keyWord: "Kata kunci",
        whole: "Semua",
        pleaseEnterSessionKeywords: "Sila masukkan kata kunci sesi",
        sessionDuration: "Tempoh sesi",
        sessionLabel: "Adegan sesi",
        pleaseSelectASessionLabel: "Sila pilih pemandangan sesi",
        commentTopic: "Topik komen",
        pleaseSelectACommentTopic: "Sila pilih topik komen",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Adakah ia disahkan untuk menutup penapis semasa?",
        overlayFilter: "Meliputi penapis",
        NewFilter: "Penapis baru",
        newFilter: "Penapis baru",
        filterName: "Nama penapis",
        pleaseEnter: "Sila masukkan",
        allCall: "Semua panggilan",
        percentage: "Peratusan",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'Menyokong carian untuk beberapa kata kunci, hubungan "atau" dipisahkan dengan koma (Cina dan Inggeris), dan hubungan "dan" dipisahkan dengan koma, seperti:',
          callRecordID: "ID rekod panggilan",
          pleaseFillInTheCallRecordID: "Sila isi ID sejarah panggilan",
          minute: "Minit",
          second: "Kedua",
          whole: "Semua",
          callRecording: "Rakaman panggilan",
          enterpriseWechat: "WeChat Korporat",
          conversationChannel: "Saluran perbualan",
          pleaseSelectASessionChannel: "Sila pilih saluran perbualan",
          learningStatus: "Status pembelajaran",
          pleaseSelectLearningStatus: "Sila pilih status pembelajaran",
          notLearned: "Tidak belajar",
          learned: "Belajar",
          Minutes: "Minit",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Keadaan penapis kosong dan penapis baru tidak disokong!",
          filterAddedSuccessfully: "Penapis berjaya ditambahkan!",
          failedToAddFilter: "Penapis baru gagal!",
          filterDeletedSuccessfully: "Penapis berjaya dipadamkan!",
          filterDeletionFailed: "Penapis gagal dipadamkan!",
          filterOverwriteSucceeded: "Liputan penapis berjaya!",
          filterOverwriteFailed: "Liputan penapis gagal!",
          theme: "Tema",
          pleaseSelectATheme: "Sila pilih tema",
          keyEvents: "Peristiwa kritikal",
          pleaseSelectAKeyEvent: "Sila pilih acara utama",
        },
        filterItem: {
          startRetesting: "Mula mengesan semula",
          time: "Masa",
          talkTime: "Tempoh panggilan",
          keyEvents: "Peristiwa kritikal",
          totalImplementationRateOfSupervisionItems:
            "Jumlah kadar pelaksanaan item pengawasan",
          sort: "Susun:",
          seeMore: "Lihat lagi",
        },
        filterStatistics: {
          conversation: "Panggilan",
          weChat: "WeChat",
          quantity: "Kuantiti",
        },
      },
      caseBase: {
        index: {
          copyTo: "Salin ke",
          pleaseSelectTheLocationToMove: "Sila pilih lokasi untuk bergerak!",
          folderCopiedSuccessfully: "Folder berjaya disalin!",
          cancelCollectionSuccessfully: "Batalkan koleksi dengan jayanya!",
          collectionSucceeded: "Koleksi berjaya!",
          starCaseBase: "Perpustakaan Kes Bintang",
          move: "Mudah alih",
          rename: "Dinamakan semula",
          delete: "Padamkan",
          folderMovedSuccessfully: "Folder berjaya dipindahkan!",
          fileMovedSuccessfully: "Fail berjaya dipindahkan!",
          fileMoveFailed: "Fail gagal bergerak!",
          folderAddedSuccessfully: "Menambah folder berjaya!",
          folderRenameSucceeded: "Folder berjaya dinamakan semula!",
          fileRenameSucceeded: "Fail berjaya dinamakan semula!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Setelah menghapus folder, ia tidak akan diambil. Adakah anda mengesahkan penghapusan?",
          deleteSucceeded: "Penghapusan berjaya!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Fail tidak dapat diambil setelah dihapus, adakah anda mengesahkan penghapusan?",
          share: "Jumlah",
          folders: "Folder,",
          calls: "Panggilan",
          newFolder: "Menambah folder",
          modifyFolder: "Ubah suai folder",
          title: "Tajuk",
          pleaseEnter: "Sila masukkan",
          selectMoveLocation: "Pilih kedudukan bergerak",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Peringatan pembelajaran",
          subscriptionDynamics: "Langgan berita",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "Sesi yang anda langgan (ID",
            thereAreDynamicUpdates: "Terdapat kemas kini dinamik",
            newCommentsReplies: "Tambah komen/balasan",
            strip: "Artikel",
            newHelp: "Bantuan baru",
            goAndWatch: "Pergi menonton",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Balas komen saya",
            replySucceeded: "Balasan berjaya!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "Dalam komen @ me",
            replySucceeded: "Balasan berjaya!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Kongsi untuk panggilan anda",
            shareCasesForYou: "Kongsi kes untuk anda",
          },
        },
        study: {
          index: {
            learned: "Belajar",
            ignored: "Tidak diendahkan",
            study: "Belajar",
            ignore: "Abaikan",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Import berjaya",
          account: "Akaun",
          password: "Kata laluan",
          cover: "Liputan",
          skip: "Langkau",
          import: "Import",
          importEmployees: "Import pekerja",
          importPrompt: "Petua import",
          confirm: "OK",
          theDataContainsDuplicateAccountsOrNames:
            "Data mempunyai pendua akaun atau nama",
          allCoverage: "Liputan penuh",
          skipAll: "Langkau semua",
          headPortrait: "Avatar",
          fullName: "Nama",
          nickname: "Nama Panggilan",
          excellentStaff: "Kakitangan yang cemerlang",
          yes: "Ya",
          no: "Tidak",
          department: "Jabatan",
          accountNumberemail: "Akaun (e-mel)",
          telephone: "Telefon",
          jobNumber: "Nombor kerja",
          role: "Peranan",
          pleaseEnterANameOrPhoneSearch:
            "Sila masukkan e-mel, nama atau carian telefon",
          person: "Orang)",
          AddEmployee: "Tambah pekerja",
        },
        edit: {
          jobNo: "Nombor kerja",
          udeskCustomerServiceSystemIdentity:
            "Identiti sistem perkhidmatan pelanggan Udesk",
          enterpriseWechatIdentity: "Identiti WeChat Korporat",
          inputID: "Masukkan logo",
          newIdentityBinding: "Menambah pengikatan identiti",
          identityBinding: "Pengikatan identiti",
          avatarGreaterThan500kb: "Avatar lebih besar daripada 500Kb",
          uploadSuccessful: "Muat naik berjaya",
          upload: "Muat naik",
          supportJPGGIFAndPNGUpTo500kb:
            "Menyokong jpg, gif, png, maksimum 500Kb",
          yes: "Ya",
          no: "Tidak",
          accountNumber: "Akaun",
          pleaseEnterTheAccountNumber: "Sila masukkan akaun",
          initialPassword: "Kata laluan awal",
          pleaseInputAPassword: "Sila masukkan kata laluan",
          confirmPassword: "Sahkan kata laluan",
          thePasswordsEnteredTwiceAreDifferent:
            "Kata laluan yang dimasukkan dua kali berbeza!",
          headPortrait: "Avatar",
          fullName: "Nama",
          pleaseEnterYourName: "Sila masukkan nama anda",
          nickname: "Nama Panggilan",
          department: "Jabatan",
          excellentStaff: "Kakitangan yang cemerlang",
          sessionIdentity: "Pengenalan sesi",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Rekod sesi penanda milik pekerja",
          telephone: "Telefon",
          jobNumber: "Nombor kerja",
          role: "Peranan",
          pleaseSelectARole: "Sila pilih peranan",
          preservation: "Simpan",
          cancel: "Batalkan",
          addedSuccessfully: "Tambah kejayaan!",
          modificationSucceeded: "Pengubahsuaian berjaya!",
          newEmployees: "Pekerja baru",
          editEmployee: "Kakitangan editor",
        },
        detail: {
          viewDetails: "Lihat butiran",
          accountNumber: "Akaun",
          headPortrait: "Avatar",
          fullName: "Nama",
          nickname: "Nama Panggilan",
          department: "Jabatan",
          excellentStaff: "Kakitangan yang cemerlang",
          yes: "Ya",
          no: "Tidak",
          sessionIdentity: "Pengenalan sesi",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Rekod sesi penanda milik pekerja",
          telephone: "Telefon",
          jobNumber: "Nombor kerja",
          role: "Peranan",
        },
      },
      roleManage: {
        list: {
          menu: "Menu",
          functionalCategory: "Kategori berfungsi",
          functionalSubclass: "Kategori berfungsi",
          deletedSuccessfully: "Penghapusan berjaya",
          NewRole: "Watak baru",
          role: "Peranan",
          dataPermissions: "Kebenaran data:",
          edit: "Edit",
          areYouSureYouWantToDeleteTheRole: "Tentukan untuk memadam peranan",
          determine: "OK",
          cancel: "Batalkan",
          delete: "Padamkan",
          applicationPermission: "Kebenaran permohonan",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Hanya dapat melihat data sesi anda sendiri",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Anda boleh melihat data sesi jabatan ini dan jabatan bawahan",
          menu: "Menu",
          functionalCategory: "Kategori berfungsi",
          functionalSubclass: "Kategori berfungsi",
          successfullyAdded: "Menambah kejayaan",
          modifiedSuccessfully: "Pengubahsuaian berjaya",
          edit: "Edit",
          cancel: "Batalkan",
          confirm: "Sahkan",
          roleName: "Nama watak",
          pleaseEnterTheRoleName: "Sila masukkan nama watak",
          dataAuthority: "Kebenaran data",
          youCanViewCompanyWideSessionData:
            "Boleh melihat data sesi seluruh syarikat",
          applicationPermission: "Kebenaran permohonan",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Proses sesi",
          generateProcess: "Proses penjanaan",
          addProcess: "Tambah proses",
          cancel: "Batalkan",
          complete: "Selesai",
          edit: "Edit",
          theSettingWillBeSuccessfulLater:
            "Tetapkan untuk melihat kejayaan kemudian!",
          previous: "Sebelumnya",
          next: "Seterusnya",
          debuggingMode: "Mod penyahpepijatan",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Setelah pengaktifan, jika mesej sesuai dengan peristiwa penting, item pengawasan, peraturan tag pelanggan atau peraturan poin pengetahuan, nama peraturan yang sesuai dengan pesan dan nama poin pengetahuan dari pangkalan pengetahuan akan ditampilkan.",
          seeLater: "Lihat kemudian",
          determine: "OK",
          conversationalInsights: "Wawasan sesi",
          customerInsight: "Wawasan pelanggan",
          knowledgePointRecommendation: "Cadangan mata pengetahuan",
          comment: "Komen",
          sessionQuality: "Kualiti sesi",
          setToListenLaterSuccessfully:
            "Tetapkan untuk mendengar dengan jayanya kemudian!",
          listenLater: "Dengar kemudian",
          seekCounselingSuccess: "Dapatkan bimbingan yang berjaya!",
          altogether: "Jumlah",
          appearsAt: "Muncul di mana'",
          commentsPublishedSuccessfully: "Komen berjaya diterbitkan",
          callRecording: "Rakaman panggilan",
          addedSuccessfully: "Tambah kejayaan",
          shareWithColleagues: "Berkongsi dengan rakan sekerja",
          shareWithCustomers: "Kongsi kepada pelanggan",
          resumptionDetails: "Maklumat semula",
          addToCaseBase: "Tambahkan ke perpustakaan kes",
          shareCases: "Berkongsi kes",
          seekCounseling: "Dapatkan kaunseling",
          sessionRecord: "Rekod sesi",
          businessRecord: "Rekod perniagaan",
          keywordSearch: "Carian kata kunci",
          whole: "Semua",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Sila masukkan komen, masukkan @ untuk memberitahu orang lain, masukkan # untuk menambah topik",
          subscriptionSession: "Sesi langganan",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Setelah melanggan, apabila ada bantuan, komen, dan balasan baru, anda akan menerima peringatan pemberitahuan",
        },
      },
      coachTask: {
        index: {
          employeeName: "Nama pekerja",
          customerName: "Nama pelanggan",
          source: "Sumber",
          viewSessionRecords: "Lihat rekod sesi",
          coachingStatus: "Status kaunseling",
          helpTime: "Masa bantuan",
          counselingTime: "Masa kaunseling",
          helpContent: "Kandungan bantuan",
          coachingOperation: "Operasi kaunseling",
          areYouSureYouWantToIgnoreIt: "Adakah anda pasti mengabaikannya?",
          determine: "OK",
          cancel: "Batalkan",
          ignoreHelp: "Abaikan pertolongan",
          coaching: "Kaunseling",
          viewCoaching: "Lihat kaunseling",
          areYouSureToReturnToCounseling:
            "Adakah anda pasti akan kembali ke kaunseling?",
          returnToPendingCoaching: "Kembali ke kaunseling",
          tutor: "Kaunselor",
          helpSeekingStatus: "Status bantuan",
          recentCoachingTime: "Masa kaunseling terkini",
          operation: "Operasi",
          ignoreSuccess: "Abaikan kejayaan!",
          recoverySucceeded: "Pemulihan berjaya!",
          successfulCoaching: "Kaunseling berjaya!",
          myMission: "Misi saya",
          selectCoachingStatus: "Pilih status kaunseling",
          whole: "Semua",
          iAskedForHelp: "Saya meminta pertolongan",
          signOut: "Keluar",
          submit: "Hantar",
          helpPerson: "Meminta pertolongan",
          customer: "Pelanggan",
          toBeCoached: "Menunggu kaunseling",
          counselingSuggestions: "Nasihat kaunseling",
          pleaseEnterCoachingSuggestions: "Sila masukkan nasihat kaunseling",
        },
      },
      coachTaskCenter: {
        title: "Pusat Misi",
        list: {
          taskName: "Nama tugas",
          lessonGroup: "Pengelasan Kursus",
          lessonName: "Kursus yang dimiliki",
          taskType: "Jenis Tugas",
          taskStatus: "Status misi",
          taskExecutionInfo: "Pelaksanaan",
          totalCount: "Bilangan orang yang perlu dilaksanakan",
          executeCount: "Bilangan orang yang dilaksanakan",
          executeRate: "Kadar pelaksanaan",
          completeCount: "Bilangan siap",
          completeRate: "Kadar penyelesaian",
          commitCount: "Bilangan orang yang memenuhi standard",
          commitRate: "Kadar pematuhan",
          excellentRate: "Kadar yang sangat baik",
          taskTime: "Masa misi",
          option: "Operasi",
          edit: "Edit",
          taskDetail: "Data tugas",
          delete: "Padamkan",
          new: "Baru dikeluarkan",
          taskTypeAll: "Semua",
          taskTypePractice: "Amalan",
          taskTypeTest: "Peperiksaan",
          taskStatusNotStarted: "Tidak bermula",
          taskStatusRunning: "Dalam proses",
          taskStatusEnd: "Tamat",
          taskStatusInvalid: "Tidak berkesan",
          unlimitedTime: "Masa tanpa had",
          to: "Ke",
          deleteConfirm:
            "Selepas penghapusan, semua rekod pembelajaran di bawah tugas ini akan dihapus. Adakah anda mengesahkan penghapusan?",
          deleteSuccess: "Penghapusan tugas yang berjaya",
        },
        edit: {
          addTask: "Tugas pelepasan baru",
          editTask: "Edit tugas pelepasan",
          publishSuccess: "Pelepasan yang berjaya",
          publishFailed: "Gagal melepaskan",
          updateSuccess: "Kemas kini berjaya",
          updateFailed: "Kemas kini gagal",
          cannotBeNull: "Tidak boleh kosong",
          explain: "Penerangan",
          taskDescExtra:
            "(Kandungan di sini akan dipaparkan di pintu masuk {0})",
          taskTargetObj: "Terbitkan objek",
          isEnable: "Status berkesan",
          dialogueMode: "Mod dialog",
          voice: "Suara",
          text: "Teks",
          userPortrait: "Potret pengguna",
          difficultyLevel: "Kesukaran",
          limitDuration: "Had masa",
          tipLabel: "Petua bercakap",
          tipTooltip: "Adakah pelajar dibenarkan melihat kata-kata rujukan",
          limitTimes: "Bilangan peperiksaan",
          time: "Masa",
          gradingStandart: "Kriteria pemarkahan",
          gradingStandartDescAffix: "(Jumlah skor proses dialog semasa adalah",
          gradingStandartDescSuffix:
            "Mata, kemahiran bercakap {0} mata, emosi {1} mata, kelajuan bercakap {2} mata)",
          completeStandart: "Keperluan penyelesaian tugas",
          byTimes: "Tekan bilangan kali",
          byScore: "Tekan skor",
          completeByTimes: "Bilangan latihan",
          times: "Kali",
          score: "Mata",
          anyTimes: "Bila-bila masa",
          getScore: "Skor",
          offLimitDuration: "Panjang tanpa had",
          onLimitDuration: "Masa terhad",
          commitScore: "Piawaian pematuhan, skor",
          excellentScore: "Standard cemerlang, skor",
          offLimitTime: "Masa tanpa had",
          onLimitTime: "Masa terhad",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Hai," },
          callDynamic: {
            today: "Hari ini",
            yesterday: "Semalam",
            sessions: "Sesi",
          },
          todoList: {
            strip: "Artikel",
            theSessionIsAddedTolistenLater:
              'Sesi ditambahkan ke "Dengarkan kemudian"',
            askYouForHelp: "Meminta bantuan anda",
            iHopeToContactYouLater: "Berharap untuk menghubungi kemudian",
            lookingForwardToSendingTheInformationLater:
              "Berharap untuk menghantar maklumat kemudian",
            dialogueRisk: "Sesi mempunyai titik risiko",
            tutored: "Telah diajar",
            goToCounseling: "Pergi ke kaunseling",
            contacted: "Dihubungi",
            hasBeenSent: "Dihantar",
            areYouSureYouWantToIgnoreIt: "Adakah anda pasti mengabaikannya?",
            determine: "OK",
            cancel: "Batalkan",
            ignore: "Abaikan",
            checked: "Dilihat",
          },
          callListItem: {
            strip: "Artikel",
            learned: "Belajar",
            keyEvents: "Peristiwa kritikal",
            totalImplementationRateOfSupervisionItems:
              "Jumlah kadar pelaksanaan item pengawasan",
          },
        },
        index: {
          operationSucceeded: "Operasi berjaya!",
          sessionDynamics: "Dinamik sesi",
          my: "Milik saya",
          department: "Jabatan",
          company: "Syarikat",
          toDoList: "Perkara yang perlu dilakukan",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Jumlah panggilan berkesan (a)",
          bestPractices: "Amalan Terbaik",
          averageDurationOfASingleCallminutes:
            "Tempoh purata panggilan tunggal (minit)",
          totalCallVolumePCs: "Jumlah panggilan (a)",
          totalTalkTimehours: "Jumlah masa panggilan (jam)",
          speakingPercentagepercentage: "Bercakap (peratusan)",
          maximumContinuousSpeakingTimeminutes:
            "Tempoh bercakap berterusan terpanjang (minit)",
          numberOfIndepthDiscussionsPCs: "Jumlah perbincangan mendalam (a)",
          maximumCustomerTalkminutes: "Pelanggan terpanjang bercakap (minit)",
          questionFrequencyPCs: "Kekerapan soalan (a)",
          averageValue: "Purata",
          selectDate: "Pilih tarikh",
          personnel: "Kakitangan",
          pleaseSelect: "Sila pilih",
          callRange: "Julat panggilan",
          conversationActivity: "Aktiviti sesi",
          conversationAction: "Tindakan sesi",
          keyEvents: "Peristiwa kritikal",
          callRecord: "Rekod panggilan",
          filter: "Penapis:",
          conversation: "Panggilan",
          minute: "Minit",
          sale: "Pekerja",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Januari",
            numberOfCalls: "Bilangan panggilan",
            accountInformation: "Maklumat Akaun",
            month: "Bulan",
            today: "Hari ini",
            customerDetails: "Butiran Pelanggan",
            searchKeywordsInCommunicationContent:
              "Cari kata kunci dalam kandungan komunikasi",
            allMembers: "Semua ahli",
            reset: "Tetapkan semula",
            query: "Pertanyaan",
            allTrip: "Paparan perjalanan penuh",
            contactOverview: "Gambaran keseluruhan hubungan",
            lastContact: "Kenalan terakhir",
            nextContact: "Hubungi lain kali",
            previous: "Sebelumnya",
            next: "Seterusnya",
            viewDetails: "Lihat butiran",
            contactDynamics: "Berita hubungan",
            lastYear: "Tahun lalu",
            lastCall: "Panggilan terakhir",
            hotTopicsForDiscussion: "Topik perbincangan",
            totalInTheLastYear: "Sebanyak pada tahun lalu",
            callTimes: "Panggilan",
          },
        },
        list: {
          index: {
            risk: "Risiko",
            have: "Mempunyai",
            nothing: "Tiada",
            channel: "Saluran",
            callRecording: "Rakaman panggilan",
            enterpriseWeChatConversation: "Perbualan Mikro Perusahaan",
            enterpriseWeChatCall: "Komunikasi Mikro Perusahaan",
            followUpSuggestions: "Cadangan susulan",
            customer: "Pusat Pelanggan",
            followUp: "Keadaan susulan",
            personInCharge: "Orang yang bertanggungjawab",
            recentFollowup: "Baru-baru ini mengikuti orang",
            lastContactTime: "Masa hubungan terakhir",
            operation: "Operasi",
            see: "Lihat",
            exceed: "Lebih daripada",
            noActivityForMoreThanDays: "Tiada aktiviti hari",
            lately: "Baru-baru ini",
            risksOccurredInTheLastDays:
              "Semantik risiko muncul dalam beberapa hari",
            savedSuccessfully: "Simpan dengan jayanya",
            today: "Hari ini",
            allDepartments: "Semua jabatan",
            allMembers: "Semua ahli",
            customerName: "Nama pelanggan",
            reset: "Tetapkan semula",
            query: "Pertanyaan",
            customers: "Pelanggan",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Tunjukkan hubungan antara pekerja dan pelanggan. Gelembung merah jambu adalah mesej pelanggan dan biru adalah mesej pekerja. Semakin besar bahagian ucapan, semakin besar gelembung.",
            riskAllocation: "Peruntukan risiko",
            preservation: "Simpan",
            pleaseEnter: "Sila masukkan",
            noActivityForDays: "Tiada aktiviti hari",
            riskSemanticsWithinDays:
              "Semantik risiko muncul dalam beberapa hari",
            addRiskSemantics: "Menambah Semantik Risiko",
            semanticTags: "Tag semantik:",
            standardSentence: "Kalimat standard:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "Tambah syarat baru" } },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Memenuhi semua syarat berikut",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Penapis",
                pleaseSelectFilteringCriteria: "Sila pilih penapis",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Tukar tahap peluang perniagaan",
              theCurrentStageIs: "Peringkat semasa adalah 【",
              ConfirmToChangeItTo: "】, Sahkan untuk menukarnya menjadi [",
              IsIt: "] Adakah?",
              StageCoStay: "】 Tahap tinggal bersama",
              day: "Hari",
              start: "Mula",
              end: "Tamat",
              orderInput: "Pesanan kalah",
              restart: "Mulakan semula",
              pleaseConfigureTheSalesStageInformation:
                "Sila konfigurasikan maklumat peringkat penjualan",
              reasonForDocumentInput: "Sebab kehilangan pesanan:",
              completeOrderInput: "Lengkapkan pesanan yang hilang",
              pleaseSelectTheReason: "Sila pilih sebab",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "Cadangan pengurus:" },
              index: {
                sessionDetails: "Perincian sesi",
                customerDynamics: "Berita Pelanggan",
                riskPoint: "Titik risiko",
                followUpSuggestions: "Cadangan susulan",
              },
              sessionDetails: {
                common: "Jumlah",
                currentlyViewingThe: "Sedang melihat",
                sessions: "Sesi",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Tahap semasa telah berhenti",
              day: "Hari",
            },
          },
          business: {
            index: {
              reEnableOpportunity: "Aktifkan semula peluang perniagaan",
            },
          },
          customerTag: { index: { label: "Label" } },
          moreCustomer: {
            index: {
              essentialInformation: "Maklumat asas",
              cancel: "Batalkan",
              confirm: "Sahkan",
              customer: "Pelanggan",
              currentFollowupStage: "Fasa susulan semasa",
              telephone: "Telefon",
              followUpDays: "Hari susulan",
              day: "Hari",
              remarks: "Catatan",
              channelPreference: "Pilihan saluran",
              hello: "Hello",
              customFields: "Medan tersuai",
              opportunityAmount: "Jumlah peluang perniagaan",
              focus: "Kebimbangan",
              customerInformation: "Maklumat pelanggan",
              viewMoreCustomerInformation:
                "Lihat lebih banyak maklumat pelanggan",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Peristiwa utama:" },
                row1: {
                  customerDynamics: "Berita Pelanggan",
                  common: "Jumlah",
                  barDynamic: "Artikel dinamik",
                },
                row2: {
                  riskPoint: "Titik risiko",
                  common: "Jumlah",
                  risks: "Risiko Artikel",
                },
                row3: {
                  followUpSuggestions: "Cadangan susulan",
                  intelligentRecommendation: "Cadangan pintar",
                  strip: "Artikel",
                  managersSuggestATotalOf: "Pengurus mencadangkan",
                  recommendedByManagers: "Disyorkan oleh pengurus",
                  creationTime: "Buat masa",
                  administratorSuggestions: "Cadangan pentadbir",
                  suggestionsForNewManagers: "Cadangan pengurus baru",
                },
              },
            },
            index: { typeTime: "Jenis/Masa" },
          },
          userInfo: {
            index: {
              personInCharge: "Orang yang bertanggungjawab",
              recentFollower: "Baru-baru ini mengikuti orang",
              startTime: "Masa sentuhan pertama",
              followUpDays: "Hari susulan",
              day: "Hari",
              remarks: "Catatan",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Ahli pasukan",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Jumlah perbualan orang lain yang dipelajari oleh ahli (tidak dilebih lanjut), melihat perincian perbualan dan mengklik rakaman untuk dimainkan dianggap sebagai satu kajian",
          totalNumberOfStudies: "Jumlah kajian",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Jumlah komen dan balasan yang dibuat oleh ahli pada perbualan orang lain (de-penimbang)",
          totalNumberOfInteractions: "Jumlah interaksi",
          learnTheSumOfConversationCallDuration:
            "Tempoh perbualan pembelajaran",
          lengthOfStudy: "Tempoh pembelajaran",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Jumlah permintaan pertolongan yang dimulakan oleh ahli untuk perbualan orang lain (membuang)",
          totalNumberOfRequests: "Jumlah bantuan",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Jumlah kaunseling yang dilakukan oleh ahli untuk mendapatkan bantuan orang lain (tidak lagi)",
          totalCounseling: "Jumlah tunjuk ajar",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Jumlah perbualan ahli dengan orang lain yang ditambahkan ke perpustakaan kes (de-penimbang)",
          addCase: "Tambah Kes",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Jumlah langganan ahli untuk sesi orang lain (de-penekanan)",
          subscribe: "Langgan",
          totalNumberOfSessionsCommunicatedByMembers:
            "Jumlah sesi di mana ahli berkomunikasi",
          totalSessions: "Jumlah sesi",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Jumlah perbualan ahli yang dikaji semula (tidak dilebih lanjut), periksa perincian sesi dan klik pada rakaman untuk dimainkan sebagai tinjauan sekali",
          totalNumberOfDoubleDisk: "Jumlah tinjauan",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Jumlah ulangan/jumlah sesi * 100%",
          proportionOfDoubleDisk: "Bahagian penyambungan semula",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Jumlah masa panggilan perbualan",
          resumptionDuration: "Tempoh penyambungan semula",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Jumlah permintaan pertolongan yang dimulakan oleh ahli untuk perbualan mereka (de-penimbang)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Jumlah komen (termasuk balasan) yang diterima oleh ahli sesi",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Jumlah perbualan ahli yang ditambahkan ke perpustakaan kes oleh orang lain (de-penimbang)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Jumlah perbualan ahli yang dilanggan oleh orang lain (de-penekanan)",
          askForHelpInTheLastMonth: "Minta pertolongan pada bulan lalu",
          lastTimeForHelp: "Masa bantuan terakhir",
          operation: "Operasi",
          viewAllSessionsInTheLastMonth: "Lihat semua sesi bulan lepas",
          allDepartments: "Semua jabatan",
          reset: "Tetapkan semula",
          query: "Pertanyaan",
          altogether: "Jumlah",
          secondSession: "Sesi",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Projek ujian semasa:",
            supervisionItem: "Barang pengawasan",
            keyEvents: "Peristiwa kritikal",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Sila periksa item pengawasan atau acara utama yang akan diuji, dan pastikan terdapat kandungan panggilan dalam perbualan simulasi",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Analisis pelaksanaan" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Proses 1",
              technologicalProcess: "Proses",
              newProcess: "Proses baru",
            },
          },
          index: {
            upload: "Muat naik",
            preservation: "Simpan",
            newlyBuild: "Baru",
            cancel: "Batalkan",
            name: "Nama",
            describe: "Penerangan",
            type: "Jenis",
            classification: "Pengelasan",
            content: "Kandungan",
            enclosure: "Aksesori",
            knowledgePointAssociation: "Titik pengetahuan berkaitan",
            open: "Buka",
            close: "Tutup",
            operator: "Pengendali",
            logic: "Logik",
            see: "Lihat",
            edit: "Edit",
          },
        },
        index: {
          name: "Nama",
          describe: "Penerangan",
          createdBy: "Pengasas",
          type: "Jenis",
          lastUpdated: "Masa kemas kini terkini",
          numberOfReferences: "Bilangan petikan",
          operation: "Operasi",
          see: "Lihat",
          edit: "Edit",
          delete: "Padamkan",
          categoryDeletedSuccessfully: "Penghapusan kategori berjaya",
          classificationOfKnowledgePoints: "Pengelasan titik pengetahuan",
          newlyBuild: "Baru",
          knowledgeBase: "Pangkalan pengetahuan",
          knowledgePoints: "Titik pengetahuan",
          technologicalProcess: "Proses",
          keyword: "Kata kunci",
          batchExport: "Eksport kumpulan",
          batchDelete: "Padam kumpulan",
          importProcess: "Proses import",
          importKnowledgePoints: "Import mata pengetahuan",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Sekiranya titik pengetahuan tidak ada di pangkalan pengetahuan, tambahkan titik pengetahuan secara langsung, jika ada, langkau dan jangan kemas kini",
          editKnowledgePointClassification: "Edit klasifikasi mata pengetahuan",
          newKnowledgePointClassification: "Pengelasan titik pengetahuan baru",
          categoryEditingSucceeded: "Penyuntingan kategori berjaya",
          newClassificationSucceeded: "Klasifikasi baru berjaya",
          classificationName: "Nama kategori",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Penyeliaan pelaksanaan" },
      },
      topicCallout: {
        index: {
          whole: "Semua",
          date: "Tarikh",
          dimensionStatus: "Status pelabelan",
          callRecordID: "ID rekod panggilan",
          callRecordIDMustBeNumeric: "ID rekod panggilan mestilah digital",
          strip: "Artikel",
          topicAnnotation: "Anotasi topik",
          annotated: "Ditandai",
          stripNotMarked: "Artikel, tidak ditandakan",
          barInDimension: "Artikel, ditandakan",
          customer: "Pelanggan",
          sale: "Jualan",
          common: "Jumlah",
          sessions: "Sesi",
          currentTopic: "Topik semasa:",
          staff: "Pekerja",
          topicOfConversation: "Topik",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Operasi berjaya, label telah diset semula",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Selepas menetapkan semula, semua rekod pelabelan sesi ini akan dikosongkan, sila sahkan",
          resetAll: "Tetapkan semula semua",
          pleaseSelectTheTopicToBeMarked:
            "Sila pilih topik yang akan ditandakan",
          pleaseTickTheSentenceToBeMarked:
            "Sila periksa ayat yang akan ditandakan",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Operasi berjaya, pelabelan telah berlaku",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Skop yang ditandakan kali ini mengandungi mesej mengenai topik lain, klik OK akan menimpa",
          confirmDimensions: "Tanda pengesahan",
          talkTime: "Masa bercakap",
          callID: "Panggilan id",
        },
      },
      topicList: {
        index: {
          callID: "ID Panggilan",
          latestMarkedTime: "Masa pelabelan terkini",
          topicOfConversation: "Topik",
          numberOfSentences: "Bilangan ayat",
          sentence: "Ayat",
          operation: "Operasi",
          see: "Lihat",
          delete: "Padamkan",
          edit: "Edit",
          newSubTopic: "Sub-topik baru",
          move: "Mudah alih",
          whole: "Semua",
          topicDeletedSuccessfully: "Padamkan topik dengan jayanya",
          newlyBuild: "Baru",
          topicList: "Senarai topik",
          callIDMustBeNumeric: "ID panggilan mestilah digital",
          leadInTopic: "Import topik",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Apabila nama kategori diulang, kategori topik asal tidak akan ditimpa, item ini akan dilangkau secara langsung dan tidak akan diimport",
          mobileClassification: "Pengelasan mudah alih",
          editTopic: "Edit topik",
          newTopic: "Topik baru",
          topicName: "Nama topik",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Pengubahsuaian berjaya",
            createdSuccessfully: "Dicipta dengan jayanya",
            modificationFailed: "Pengubahsuaian gagal",
            creationFailed: "Gagal mencipta",
            monitoringName: "Nama pemantauan",
            upToCharacters: "Maksimum 40 aksara",
            pleaseEnterAName: "Sila masukkan nama",
            monitoringIndicators: "Petunjuk pemantauan",
            sessionActivity: "Aktiviti sesi",
            conversationAction: "Tindakan sesi",
            keyEvents: "Peristiwa kritikal",
            supervisionItems: "Barang pengawasan",
            monitoringMembers: "Memantau ahli",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Kumpulan yang menerima bimbingan, latihan atau campur tangan pengurusan",
            groupA: "Kumpulan A",
            pleaseSelectGroupAData: "Sila pilih kumpulan data",
            thereAreDuplicateUsersInGroupB:
              "Pengguna yang mempunyai pendua dengan kumpulan B",
            groupARemarks: "Catatan Kumpulan A",
            controlGroupGroupComparedWithGroupA:
              "Kumpulan kawalan, kumpulan yang dibandingkan dengan kumpulan A",
            groupB: "Kumpulan B",
            pleaseSelectGroupBData: "Sila pilih data kumpulan B",
            thereAreDuplicateUsersInGroupA:
              "Pengguna yang mempunyai pendua dengan kumpulan A",
            groupBRemarks: "Catatan Kumpulan B",
            monitoringPeriod: "Kitaran pemantauan",
            startNode: "Mula nod",
            monitoringScope: "Julat pemantauan",
            pleaseSelectMonitoringConditions: "Sila pilih keadaan pemantauan",
            pleaseEnterANumber: "Sila masukkan nombor",
            pleaseSelectATimePeriod: "Sila pilih tempoh masa",
            preservation: "Simpan",
            cancel: "Batalkan",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Penghapusan berjaya",
            notStarted: "Tidak bermula",
            haveInHand: "Dalam proses",
            closed: "Tamat",
            newGroupTest: "Ujian kumpulan baru",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Peringkat jualan",
        communicationStrategy1: "Strategi komunikasi",
        riskAllocation: "Peruntukan risiko",
        businessConfiguration: "Konfigurasi perniagaan",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Pengendalian bantahan",
                  productSellingPointScript: "Kemahiran menjual produk",
                  caseGrouping: "Kumpulan kes",
                  pleaseSelectExcellentCases: "Sila pilih kes yang sangat baik",
                  pleaseSelectKnowledgePoints: "Sila pilih titik pengetahuan",
                  associativeLinks: "Pautan bersekutu",
                  jumpTo: "Lompat ke",
                  jumpType: "Jenis lompat",
                  knowledgePoints: "Titik pengetahuan",
                  excellentCases: "Kes yang sangat baik",
                  excellentScript: "Kemahiran bercakap yang sangat baik",
                  pleaseSelectASingleKnowledgePoint:
                    "Sila pilih satu titik pengetahuan",
                },
              },
            },
            index: {
              name: "Nama",
              pushText: "Tolak teks",
              enableStatus: "Aktifkan status",
              triggerRules: "Peraturan pencetus",
              communicationStrategy: "Strategi komunikasi",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "Kumpulan strategi komunikasi",
              name: "Nama",
              pushText: "Tolak teks",
              effectiveness: "Keberkesanan",
              openSuccessfully: "Mulakan kejayaan",
              closeSuccessfully: "Penutupan berjaya",
              operationFailed: "Operasi gagal",
              operation: "Operasi",
              edit: "Edit",
              delete: "Padamkan",
              newlyBuild: "Baru",
              communicationStrategy: "Strategi komunikasi",
              editGroup: "Edit kumpulan",
              groupName: "Nama kumpulan",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Semua sesi",
                  recentDays: "Bilangan hari yang paling terkini",
                  recentSessions: "Sesi terkini",
                  scopeOfApplication: "Skop permohonan:",
                  day: "Hari",
                  through: "Melalui",
                },
              },
            },
            index: {
              describe: "Penerangan",
              applicableCustomers: "Pelanggan yang berkenaan",
              enableStatus: "Aktifkan status",
              triggerRules: "Peraturan pencetus",
              riskRules: "Peraturan risiko",
            },
          },
          list: {
            index: {
              riskAllocation: "Peruntukan risiko",
              keyWord: "Kata kunci",
              newRiskRule: "Peraturan Risiko Baru",
              riskRuleName: "Nama peraturan risiko",
              describe: "Penerangan",
              state: "Status",
              openSuccessfully: "Mulakan kejayaan",
              closeSuccessfully: "Penutupan berjaya",
              operationFailed: "Operasi gagal",
              operation: "Operasi",
              edit: "Edit",
              delete: "Padamkan",
              deletionSucceeded: "Penghapusan berjaya",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Pesanan kalah" },
              endReasonList: {
                addEndReason: "Tambah sebab akhir",
                enterEndReason: "Sebab akhir input",
              },
              stageList: {
                keyEvents: "Peristiwa kritikal",
                waysideData: "Data dalam perjalanan",
                includeAny: "Mengandungi sewenang-wenangnya",
                includeAll: "Mengandungi semua",
                pleaseEnterTheStageName: "Sila masukkan nama pentas",
                setStageRules: "Tetapkan peraturan peringkat",
                stageName: "Nama pentas",
                operator: "Pengendali",
                logic: "Logik",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Format input medan jenis masa adalah: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Permintaan gagal, sila cuba lagi kemudian",
              salesStage: "Peringkat jualan",
              essentialInformation: "Maklumat asas",
              name: "Nama",
              applicableDepartments: "Jabatan yang berkenaan",
              applicableToOthers: "Berlaku untuk yang lain",
              manualChangePhase: "Fasa perubahan manual",
              enableStatus: "Aktifkan status",
              describe: "Penerangan",
              opportunityStageSettings: "Tetapan fasa peluang perniagaan",
              stageSettings: "Tetapan peringkat",
              aliasSettings: "Tetapan alias",
              closeOpportunity: "Tamatkan peluang perniagaan",
              newSuccessfully: "Berjaya dibina",
              newFailed: "Gagal membuat yang baru",
              savingSucceeded: "Simpan dengan jayanya",
              saveFailed: "Simpan gagal",
            },
          },
          list: {
            index: {
              salesStage: "Peringkat jualan",
              keyWord: "Kata kunci",
              newSalesStage: "Tahap penjualan baru",
              salesStageName: "Nama peringkat jualan",
              describe: "Penerangan",
              state: "Status",
              openSuccessfully: "Mulakan kejayaan",
              closeSuccessfully: "Penutupan berjaya",
              operationFailed: "Operasi gagal",
              operation: "Operasi",
              edit: "Edit",
              delete: "Padamkan",
              deletionSucceeded: "Penghapusan berjaya",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Penjanaan model besar",
            conversationInsights: "Wawasan sesi",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Dihasilkan secara automatik" },
              },
              hooks: {
                conversationSummary: "Ringkasan sesi",
                conversationProcess: "Proses sesi",
                opportunityPoints: "Titik peluang",
              },
              prompt: {
                index: {
                  edit: "Edit",
                  restoreDefault: "Pulihkan lalai",
                  cancel: "Batalkan",
                  confirm: "Sahkan",
                },
              },
            },
            index: {
              saveSuccessful: "Simpan dengan jayanya",
              hintLanguage: "Peringatan",
              preserve: "Simpan",
              cancel: "Batalkan",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Penjanaan model besar",
        salesStage: "Peringkat jualan",
        communicationStrategy: "Strategi komunikasi",
        riskAllocation: "Peruntukan risiko",
        businessConfiguration: "Konfigurasi perniagaan",
      },
      salesLearningCenter: {
        knowledgeBase: "Pangkalan pengetahuan",
        speechCorpus: "Perpustakaan bercakap",
        learningCenter: "Pusat Pembelajaran",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "Maklumat pelanggan" } },
        },
        index: { otherInformation: "Maklumat tambahan" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Import" } } },
        hooks: {
          index: {
            all: "Semua",
            intelligentModel: "Model pintar",
            smartRules: "Peraturan pintar",
            manualScoring: "Penilaian manual",
            enable: "Aktifkan",
            notEnabled: "Tidak diaktifkan",
            voiceCall: "Panggilan suara",
            textDialogue: "Perbualan teks",
            ecommerceTextSession: "Perbualan teks e-dagang",
            workOrder: "Perintah kerja",
          },
        },
        index: {
          ruleName: "Nama peraturan",
          ruleType: "Jenis peraturan",
          pleaseSelect: "Sila pilih",
          ruleStatus: "Peraturan keadaan",
          dataSourceType: "Jenis Sumber Data",
          voiceCall: "Panggilan suara",
          textDialogue: "Perbualan teks",
          ecommerceTextSession: "Perbualan teks e-dagang",
          workOrder: "Perintah kerja",
          status: "Status",
          operation: "Operasi",
          edit: "Edit",
          ruleTemplate: "Templat peraturan",
        },
      },
      analysisReportTitle: "Laporan analisis",
      ruleTest: "Ujian peraturan",
      intelligentTags: {
        labelDisplayQuantity: "Bilangan paparan label",
        unlimited: "Tidak terhad",
        ignoringTagLibraries: "Abaikan perpustakaan tag",
        automaticallyGenerateLabels: "Hasilkan label secara automatik",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'Setelah dibuka, label dihasilkan secara automatik untuk sesi berdasarkan model besar, dan label yang dihasilkan diklasifikasikan ke dalam "Tidak dikategorikan" secara lalai',
        defaultValidity: "Kesahan lalai",
        supervisionItem: "Barang pengawasan",
        customerLabel: "Label pelanggan",
        newSupervisionItemRule: "Peraturan item pengawasan baru",
        editSupervisionItemRule: "Edit peraturan item penyeliaan",
        newCustomerLabel: "Label pelanggan baru",
        editCustomerLabel: "Edit label pelanggan",
        newSessionLabel: "Label sesi baru",
        editSessionLabel: "Edit label sesi",
        newKeyEvent: "Buat acara kritikal baru",
        editKeyEvents: "Edit peristiwa utama",
        detail: {
          template: {
            nameIsARequiredField: "Nama adalah medan yang diperlukan",
          },
          formItemComponent: {
            largeModelExtensionLabel: "Label pengembangan model besar",
            sessionRecordID: "Rakaman sesi id",
            sessionTime: "Masa sesi",
            smartTags: "Label pintar",
            viewDetails: "Lihat butiran",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Selepas penghapusan, data yang dirakam dalam sesi ini akan dihapus. Adakah anda mengesahkan penghapusan?",
            tagData: "Data penanda",
            batchDeletion: "Padam kumpulan",
          },
        },
        template: {
          advancedFiltering: "Penapis lanjutan",
          dataType: "Jenis data",
          filterCriteria: "Tapis keadaan",
          edit: "Edit",
          createANewSubcategory: "Sub-kategori baru",
          delete: "Padamkan",
          nameLengthCannotExceed: "Panjang nama tidak boleh melebihi 40",
        },
        component: {
          modelExpansion: "Pengembangan model",
          enable: "Aktifkan",
          notEnabled: "Tidak diaktifkan",
          standardScreening: "Pemeriksaan standard",
          advancedFiltering: "Penapis lanjutan",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Kata kunci",
              sessionLabel: "Label sesi",
              keyEvents: "Peristiwa kritikal",
              matchAny: "Padankan mana-mana",
              matchAll: "Padankan semua",
              beEqualTo: "Sama dengan",
              includeAll: "Mengandungi semua",
              includeAny: "Mengandungi sewenang-wenangnya",
              advancedConditions: "Keadaan lanjutan",
              smartTag: "Label pintar",
              smartTagI: "Label pintar satu",
              smartTagII: "Label pintar dua",
            },
            template: {
              pleaseSelectSmartTag: "Sila pilih label pintar",
              pleaseSelectASessionLabel: "Sila pilih label sesi",
              pleaseSelectAKeyEvent: "Sila pilih acara utama",
              pleaseSelectAdvancedCondition: "Sila pilih syarat lanjutan",
              newAdvancedFilter: "Penapis lanjutan baru",
              moveUp: "Gerakkan ke atas",
              moveDown: "Bergerak ke bawah",
              moveLeft: "Gerakkan ke kiri",
              shiftRight: "Gerakkan ke kanan",
            },
          },
          component: {
            businessAnalysispng: "Analisis perniagaan. png",
            savedSuccessfully: "Simpan dengan jayanya",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Berkesan secara kekal",
            permanentlyInvalid: "Tidak sah secara kekal",
            customValidity: "Tempoh sah tersuai",
            termOfValidity: "Tempoh sah",
            name: "Nama",
            explain: "Penerangan",
            state: "Status",
            classification: "Pengelasan",
            smartRecommendation: "Cadangan pintar",
            preconditions: "Pra-syarat",
            SetPreconditionsForThisSupervisionItem:
              "1. Tetapkan prasyarat untuk item pengawasan:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Apabila prasyarat dipenuhi, itu adalah "item pengawasan yang akan dilaksanakan", dan item pengawasan akan diuji;',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Apabila prasyarat tidak dipenuhi, item pengawasan tidak akan diuji;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Tidak ada prasyarat yang ditetapkan, iaitu semua sesi akan diuji secara lalai",
            have: "Mempunyai",
            nothing: "Tiada",
            preconditionRule: "Peraturan pra-syarat",
            preconditionLogic: "Logik prasyarat",
          },
          recommendation: {
            caseGrouping: "Kumpulan kes",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Apabila pekerja tidak berada di tempat, mereka dapat mengesyorkan kemahiran dan kes yang sangat baik",
            smartRecommendation: "Cadangan pintar",
            open: "Buka",
            close: "Tutup",
            whenThisSupervisionItem: "Apabila item pengawasan ini",
            implement: "Pelaksanaan",
            unexecuted: "Tidak dilaksanakan",
            when: "Masa:",
            recommendedKnowledgePoints: "Mata pengetahuan yang disyorkan",
            caseRecommendation: "Cadangan kes",
          },
        },
        index: {
          determine: "OK",
          sort: "Susun",
          sortSucceeded: "Berjaya menyusun",
          supervisionItemClassification: "Pengelasan item penyeliaan",
          pressEnterToCreateANew: "Tekan Enter untuk membuat yang baru",
          nameCannotBeEmpty: "Nama tidak boleh kosong",
          newlyBuild: "Baru",
          name: "Nama",
          explain: "Penerangan",
          validStatus: "Status berkesan",
          permanentlyValid: "Berkesan secara kekal",
          permanentlyInvalid: "Tidak sah secara kekal",
          operation: "Operasi",
          edit: "Edit",
          batchDelete: "Padam kumpulan",
          newSupervisionItem: "Item pengawasan baru",
          keyWord: "Kata kunci",
          delete: "Padamkan",
          deleteSucceeded: "Penghapusan berjaya",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Setelah data dihapus, data tidak dapat dipulihkan. Adakah anda mengesahkan penghapusan?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Label pintar",
              beEqualTo: "Sama dengan",
              includeAll: "Mengandungi semua",
              includeAny: "Mengandungi sewenang-wenangnya",
              matchAny: "Padankan mana-mana",
              matchAll: "Padankan semua",
              smartTagI: "Label pintar satu",
              smartTagII: "Label pintar dua",
            },
            template: {
              pleaseSelectASmartTag: "Sila pilih label pintar",
              pleaseSelectAdvancedConditions: "Sila pilih syarat lanjutan",
              newAdvancedFilter: "Penapis lanjutan baru",
              rename: "Dinamakan semula",
              moveUp: "Gerakkan ke atas",
              moveDown: "Bergerak ke bawah",
              shiftLeft: "Gerakkan ke kiri",
              shiftRight: "Gerakkan ke kanan",
            },
          },
        },
        template: {
          delete: "Padamkan",
          labelFiltering: "Penapis label:",
          satisfyAny: "Memenuhi mana-mana",
          meetAll: "Memenuhi semua",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Bilangan dialog tag klasifikasi sekunder ≥ Klasifikasi tahap pertama, sesi mungkin mengandungi banyak tag",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logik pengiraan perubahan rantai: (jumlah dialog dalam jangka masa yang dipilih-jumlah dialog dalam jangka masa sebelumnya di sebelah)/jumlah dialog dalam jangka masa sebelumnya",
          proportionOfPrimaryClassification:
            "Bahagian klasifikasi peringkat pertama",
          saveAs: "Simpan sebagai",
          cover: "Liputan",
          preservation: "Simpan",
          qualityInspectionTask: "Tugas analisis:",
          query: "Pertanyaan",
          changeFromMonthToMonth: "Perubahan rantai",
          tagRanking: "Kedudukan label",
          proportion: "Nisbah",
        },
        component: {
          numberOfConversations: "Bilangan perbualan",
          operation: "Operasi",
          businessAnalysispng: "Analisis perniagaan. png",
          savingSucceeded: "Simpan dengan jayanya",
          deletionSucceeded: "Penghapusan berjaya",
          sequence: "Urutan",
          firstLevelClassification: "Pengelasan peringkat pertama",
          ringRatioChange: "Perubahan rantai",
          classClassification: "Pengelasan gred",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Sahkan untuk menghapus dokumen dan kandungannya yang diekstrak?",
            addKnowledgeBase: "Tambah Pangkalan Pengetahuan",
            uploadDocument: "Muat naik dokumen",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Menyokong fail format perkataan, pdf, ppt, excel, txt",
            taskName: "Nama tugas",
            extractConversationDate: "Tarikh perbualan",
            pleaseSelectAConversationDate: "Sila pilih tarikh perbualan",
            dataSource: "Sumber data",
            dataType: "Jenis data",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Eksport berjaya",
              problem: "Soalan",
              answer: "Jawapan",
              addToKnowledgeBase: "Tambah ke Pangkalan Pengetahuan",
              pleaseConfirmWhetherToDeleteIt: "Sila pastikan untuk memadam",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Tambah ke Pangkalan Pengetahuan",
              selectClassification: "Pilih kategori",
              pleaseSelectAKnowledgeBaseClassification:
                "Sila pilih klasifikasi asas pengetahuan",
            },
          },
        },
        index: {
          intelligentExtraction: "Pengekstrakan pintar",
          documentExtraction: "Pengekstrakan dokumen",
          dialogueExtraction: "Pengekstrakan dialog",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operasi yang berjaya",
          labelIgnored: "Label tidak dihiraukan",
          add: "Tambah",
          areYouSureToDeleteIt: "Adakah anda pasti akan menghapusnya?",
          batchFilterLabels: "Label penapis kumpulan",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Pemeriksaan kualiti pintar",
        savedSuccessfully: "Simpan dengan jayanya",
        logoSettings: "Tetapan logo",
        default: "Lalai",
        custom: "Adat",
        title: "Tajuk",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Nama tajuk yang dipaparkan oleh sistem disyorkan dalam 6 perkataan.",
        logoImageUpload: "Muat naik gambar logo",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Sila pilih gambar yang tidak melebihi saiz 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Untuk memastikan kesan penyemakan logo, cadangan memuat naik gambar:",
        Size5050Pixels: "1. Saiz: 50*50 piksel;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Format: gambar latar belakang telus, png atau jpg, grafik logo menggunakan putih",
        browserLabelIcon: "Ikon label penyemak imbas",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Untuk memastikan kesan penyemakan tag, cadangan memuat naik gambar:",
        Size3232Pixels: "1. Saiz: 32*32 piksel;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Format: gambar latar belakang telus, png atau jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Pemeriksaan kualiti sedang dijalankan, sila tunggu...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "Pada tahun 2015, harta tanah China telah mencapai saat kritikal untuk bertahan hidup. Sebilangan besar rumah tidak dijaga dan orang tidak mahu membelinya. Hangzhou pernah jatuh di bawah 8.000, dan beberapa pemaju menghancurkan pasaran untuk penghantaran. Shenzhen masih menyatukan sekitar 2w-3w pada masa itu, dan rumah-rumah di bandar-bandar tingkat pertama juga dipertaruhkan.",
        xiaoAn: "Xiaoan",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore toreload Penghalang memori ini disebut penghalang memori jsr, yang berada di tingkat jvm. Terdapat dua kaedah pelaksanaan di bahagian bawah sistem operasi: 1 adalah bas terkunci, dan yang lain adalah mfance (penghalang tulis). Penghalang). Lapisan bawah mesin maya hotspots menggunakan pernyataan pemasangan: kunci addl, kunci operasi kosong, iaitu bas terkunci, ini kerana beberapa sistem operasi tidak menyokong meta penghalang memori.",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Cuba perap daging beberapa kali, dan sayur-sayuran yang digoreng setelah minum, dagingnya busuk, dengan bau masam! Sejak itu, daging yang disembuhkan tidak akan meletakkan wain memasak",
        xiaodong: "Xiaodong",
        xiaoxi: "Xiaoxi",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Cuba perap daging beberapa kali, dan sayur-sayuran yang digoreng setelah minum, dagingnya busuk, dengan bau masam! Sejak itu, daging yang disembuhkan tidak akan meletakkan wain memasak",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Pengurusan ulasan e-dagang",
        socialMediaReviewManagement: "Pengurusan komen media sosial",
        customDataSource: "Asal data tersuai",
        qualityInspectionResults: "Hasil pemeriksaan kualiti",
        accountManagement: "Pengurusan Akaun",
        employeeProfile: "Potret pekerja",
        intelligentAnalysis: "Analisis pintar",
        customKanban: "Kanban tersuai",
        dataScreening: "Gambaran keseluruhan data",
        learningRecord: "Rekod pembelajaran",
        dataAnalysis: "Analisis data",
        scoringManagement: "Pengurusan pemarkahan",
        businessConfiguration: "Konfigurasi perniagaan",
        manualReview: "Kajian manual",
        taskList: "Senarai tugas",
        videoAnalysis: "Analisis Video",
        overview: "Gambaran keseluruhan",
        personalSignage: "Papan tanda peribadi",
        learningCenter: "Pusat Pembelajaran",
        wrongQuestionSet: "Set soalan yang salah",
        abilityImprovement: "Peningkatan keupayaan",
        close: "Tutup",
        switchingSystemApplications: "Tukar aplikasi sistem",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Gerakkan tetikus ke sini untuk memanggil menu beralih aplikasi, klik item menu untuk melompat ke aplikasi yang berbeza",
        teamAnalysis: "Analisis pasukan",
        semanticIntelligence: "Kecerdasan Semantik",
        permissionManagement: "Pengurusan kebenaran",
        dataConnection: "Data dok",
        courseManagement: "Pengurusan Kursus",
        labelManagement: "Pengurusan Label",
        curriculum: "Pusat Kursus",
        courseConfiguration: "Konfigurasi kursus",
        task: "Misi saya",
        historicalLearningData: "Rekod sejarah",
        taskCenter: "Pengurusan Tugas",
        knowledgeBase: "Pangkalan pengetahuan",
        ecommerceSessionManagement: "Pengurusan sesi e-dagang",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Tukar aplikasi",
          descriptionOfIntelligentQualityInspectionSystem:
            "Penerangan sistem pemeriksaan kualiti pintar",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Penapis sesi e-dagang",
        allEcommerceSessions: "Semua perbualan e-dagang",
        ecommerceSessionManagement: "Pengurusan sesi e-dagang",
      },
    },
    analysisEnable: {
      bestPractices: "Amalan Terbaik",
      behaviorAnalysis: "Analisis tingkah laku",
      teamAnalysis: "Analisis pasukan",
      executiveSupervision: "Penyeliaan pelaksanaan",
      executiveForceAnalysis: "Analisis pelaksanaan",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Penilaian:",
          startTime: "Masa mula",
          workOrderNo: "Nombor pesanan kerja",
          customerServiceHasReadNotRead:
            "Perkhidmatan pelanggan telah dibaca/belum dibaca",
          canYouAppeal: "Bolehkah saya membuat rayuan",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Nota Mikro Perusahaan",
          addFriendTime: "Tambah masa rakan",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Mulakan pemeriksaan semula",
          callID: "ID Panggilan",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Ciri baru! Klik butang 'Pemeriksaan Pantas Pantas, dan secara automatik akan melompat ke halaman seterusnya setelah penyerahan berjaya ~~~",
        },
      },
      template: {
        deleteRecord: "Padamkan rekod",
        areYouSureToDeleteIt: "Adakah anda pasti akan menghapusnya?",
        confirm: "OK",
        reIdentification: "Pengiktirafan semula",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Pengiktirafan semula akan memakan masa transkripsi, sila berhati-hati",
        determine: "OK",
        executionSuccessful: "Pelaksanaan yang berjaya",
      },
      component: {
        pleaseSelectData: "Sila pilih data",
        operationSuccessful: "Operasi yang berjaya",
        startPerformingReQualityInspection: "Mulakan pemeriksaan semula",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Muat naik nama fail",
        analysisTask: "Tugas analisis",
        analyzeProgress: "Kemajuan analisis",
        analysisSucceeded: "Analisis yang berjaya",
        analysisFailed: "Analisis gagal",
        localDataUpload: "Muat naik data tempatan",
        recordingUpload: "Muat naik rakaman",
        textUpload: "Muat naik teks",
        uploadTime: "Masa muat naik",
        taskName: "Nama tugas",
        operator: "Pengendali",
        qualityInspectionProgress: "Kemajuan pemeriksaan kualiti",
        whole: "Semua",
        haveInHand: "Dalam proses",
        completed: "Selesai",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Adakah anda mengeksport semua rekod data yang lengkap dalam keadaan penapis semasa?",
        upload: "Muat naik",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Isi nama",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Isi nama, seperti jenis/koleksi/tujuan data yang diimport kali ini",
            default: "Lalai",
            jDCOM: "Jingdong",
            dataType: "Jenis data",
            selectAnalysisTask: "Pilih tugas analisis",
            uploadFiles: "Muat naik fail",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Muat turun templat excel dan isi mengikut format:",
            template: "Templat",
            SupportUpTo10000PiecesOfData: "2. Menyokong sehingga 10,000 data",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Sokong muat naik data dialog yang dieksport oleh JD.com, fail. txt, ukuran fail menyokong hingga 100M",
            alongTheWayDataTemplate: "Templat data dalam perjalanan",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Teks dalam pemeriksaan kualiti, harap bersabar",
            textQualityInspectionCompleted: "Pemeriksaan teks selesai",
          },
        },
        template: {
          startAnalysis: "Mulakan analisis",
          pleaseUploadTheFile: "Sila muat naik fail",
          localDataUpload: "Muat naik data tempatan",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "Ciri baru! Klik butang 'Kaji semula penyerahan', setelah penyerahan berjaya, secara automatik akan melompat ke halaman seterusnya ~~~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Kebenaran tugas pemeriksaan kualiti",
            edit: "Edit",
            iParticipated: "Saya mengambil bahagian",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Peranan dapat melihat data di bawah jabatan yang dipilih",
            dataPermissions: "Kebenaran data",
            selectDepartment: "Pilih jabatan",
            edit: "Edit",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Mulakan pemeriksaan semula",
          callID: "ID Panggilan",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Ciri baru! Klik butang 'Penyerahan Pensampelan Talian' dan 'Pensampelan Pantas, 'secara automatik akan melompat ke halaman seterusnya setelah penyerahan berjaya ~~~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Peraturan" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Pengurusan pesanan kerja",
          workOrderDetails: "Perincian pesanan kerja",
        },
      },
      template: {
        workOrderFilter: "Penapis pesanan kerja",
        allWorkOrders: "Semua pesanan kerja",
        workOrderManagement: "Pengurusan pesanan kerja",
      },
    },
    trainingCenter: {
      topicAnnotation: "Anotasi topik",
      topicList: "Senarai topik",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Jenis peranan",
              keyWord: "Kata kunci",
              pleaseEnterKeywordSearch:
                "Sila masukkan kata kunci untuk mencari",
              query: "Pertanyaan",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Fungsi ini belum dibuka, sila hubungi pentadbir sistem jika perlu",
          pleaseSelectAQuestion: "Sila pilih soalan!",
          recommendedSimilarSentencesLargeModel:
            "Mengesyorkan ayat yang serupa (model besar)",
          selectAll: "Semua pilihan",
          add: "Tambah",
          changeBatch: "Tukar kumpulan",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Waktu kerja perniagaan",
      localDataUpload: "Muat naik data tempatan",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Pasang senarai menu mengikut kebenaran pengguna semasa",
      sessionSettings: "Tetapan sesi",
      logoSettings: "Tetapan logo",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Selamat kembali! Sila masukkan butiran anda",
        mailbox: "Peti mel",
        password: "Kata laluan",
        signIn: "Log masuk",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin:
            "IP semasa tidak membenarkan log masuk",
          welcomeToLogin: "Selamat datang untuk log masuk",
          pursuingExcellenceAndLeadingChange:
            "Kejar kecemerlangan dan pimpin perubahan",
          intelligentPlatform: "Platform pintar",
        },
        virtualLogin: { index: { pleaseSelect: "Sila pilih" } },
      },
      oauth: {
        illegalCall: "Panggilan haram",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Selamat kembali! Klik butang di bawah untuk melompat ke platform pengesahan identiti",
        loggingIn: "Log masuk...",
        unifiedIdentityAuthenticationLogin:
          "Log masuk pengesahan identiti bersatu",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Ujian peraturan: Beberapa peraturan tidak dapat menggunakan ujian peraturan, seperti kelajuan dan kelantangan, data di sepanjang jalan, dll.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Ketahui lebih lanjut",
          enteringTheSystem: "Masukkan sistem",
        },
      },
      index: {
        cancel: "Batalkan",
        enteringTheSystem: "Masukkan sistem",
        close: "Tutup",
        enterSystemApplication: "Masukkan aplikasi sistem",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Klik "Masukkan Sistem" untuk melompat ke aplikasi sistem yang diaktifkan',
      },
    },
    permissionManagement: {
      employeeManagement: "Pengurusan pekerja",
      roleManagement: "Pengurusan peranan",
      permissionManagement: "Pengurusan kebenaran",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Sama dengan",
            contain: "Mengandungi",
            doesNotContain: "Tidak mengandungi",
            applicationSystem: "Sistem aplikasi",
            intelligentQualityInspection:
              "Pemeriksaan kualiti pintar/analisis sesi pintar",
            salesEmpowerment: "Pemberdayaan jualan",
            dataSourceType: "Jenis Sumber Data",
            filterCriteria: "Tapis keadaan",
          },
        },
        index: {
          dataConnection: "Data dok",
          savingSucceeded: "Simpan dengan jayanya",
          dataIsolation: "Pengasingan data",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Setelah dibuka, julat data setiap panggilan akses sistem aplikasi dan perbualan WeChat perusahaan dapat diatur. Sekiranya suis tidak dihidupkan, setiap sistem akan disambungkan ke jumlah data penuh",
          accessRules: "Peraturan akses",
          preservation: "Simpan",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Nama permohonan",
            accessRights: "Kebenaran Akses",
            inAppPermissionSettings: "Tetapan kebenaran dalam aplikasi",
            setUp: "Tetapan",
            applicationAccess: "Kebenaran Akses Aplikasi",
            newRole: "Peranan baru",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "Perincian sesi" } },
      messageNotification: {
        index: {
          sessionDetails: "Perincian sesi",
          caseDetails: "Perincian kes",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Pekerja yang kelihatan",
            newCourse: "Kursus baru",
            editCourse: "Kursus penyuntingan",
            courseName: "Nama kursus",
            courseIntroduction: "Pengenalan kursus",
            courseType: "Jenis Kursus",
            courseClassification: "Pengelasan Kursus",
            courseCover: "Penutup kursus",
            learningContent: "Kandungan pembelajaran",
            uploadLearningMaterials: "Muat naik bahan pembelajaran",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Ujian",
              preservation: "Simpan",
              cancel: "Batalkan",
              editNodeName: "Edit nama nod",
              determine: "OK",
              individual: "Seorang",
              of: ", Jumlah",
              branch: "Mata",
            },
            configDrawerComponents: {
              pPTParsing: "Analisis PPT...",
              uploadSuccessful: "Muat naik berjaya",
              uploadAttachments: "Muat naik lampiran",
              pPTParsingPleaseWait: "Analisis PPT, sila tunggu",
            },
            customerPortraitConfigDrawer: {
              cancel: "Batalkan",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Tidak dapat dipulihkan selepas penghapusan, adakah anda pasti mahu menghapus potret ini?",
              delete: "Padamkan",
              determine: "OK",
              customerPortrait: "Potret pelanggan",
              bindSelectedCharacterPortrait:
                "Mengikat potret watak yang dipilih",
              customerProfileDetails: "Perincian potret pelanggan",
              createANewCustomerProfile: "Potret pelanggan baru",
            },
            flow: {
              sidebar: { node: "Nod" },
              useControlButtons: {
                cancellingPleaseWait: "Pembatalan, sila tunggu...",
                redoInProgressPleaseWait: "Buat semula, sila tunggu...",
                automaticOrganization: "Penamat automatik",
                canvasAdaptation: "Penyesuaian kanvas",
                delete: "Padamkan",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Nod tidak dapat dihubungkan dengan dirinya sendiri",
              },
            },
            globalConfigDrawer: {
              cancel: "Batalkan",
              determine: "OK",
              globalConfiguration: "Konfigurasi global",
              sessionDetection: "Pengesanan sesi",
              reciprocalRule: "Peraturan interaksi",
              robotVoice: "Bunyi robot",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Node pelajar tamat dan tidak ada tetapan jawapan",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Masa senyap maksimum yang dibenarkan oleh pelajar tanpa menjawab (s)",
              promptScriptForFailureToAnswerOverTime:
                "Kata-kata segera yang tidak dijawab",
              addScript: "Tambah perkataan",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (semula jadi)",
              zhishaChat: "Zhisha (sembang)",
              zhiqiangentle: "Zhiqian (lembut)",
              zhimiEmotion: "Zhimi (emosi)",
              aiShuoNatural: "Ai Shuo (semula jadi)",
              zhishuoChat: "Zhishuo (sembang)",
              aiHaoAffinity: "Ai Hao (pertalian)",
              zhixiangEmotion: "Zhixiang (emosi)",
              robotVoice: "Bunyi robot",
              schoolboy: "Budak lelaki",
              girlStudent: "Gadis",
              volume: "Kelantangan",
              tone: "Nada",
              speechSpeed: "Kelajuan bercakap",
              test: "Ujian",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Helo, saya adalah jurulatih latihan pintar dan berharap dapat berkomunikasi dengan anda",
            },
            sessionDetectionForm: {
              expressionDetection: "Ujian ekspresi",
              excessiveDetectionOfModalParticles:
                "Pengesanan terlalu banyak perkataan gas",
              theNumberOfRepetitionsIs: "Bilangan pengulangan adalah",
              minus: "Kali kedua, tolak",
              appear: "Muncul",
              subtract: "Kali, tolak",
              branch: "Mata",
              addRules: "Meningkatkan peraturan",
              includingNegativeAngryComplaining:
                "(Termasuk negatif, marah, mengeluh)",
              scorePointsDisplay: "Titik skor menunjukkan",
              reminderOfSpeakingTooFasttooSlow:
                "Peringatan bercakap terlalu cepat/terlalu perlahan",
              scriptViolationReminder: "Peringatan pelanggaran",
              emotionalReminder: "Peringatan emosi",
              speechSpeedDetection: "Pengesanan kadar pertuturan",
              normalSpeechSpeedScore: "Skor kadar pertuturan biasa",
              speakTooFast: "Bersuara terlalu pantas",
              everyMinuteGreaterThan: "Lebih besar daripada seminit",
              aWordAppears: "Perkataan, muncul",
              speakTooSlowly: "Kelajuan bercakap terlalu perlahan",
              lessThanPerMinute: "Kurang daripada seminit",
              ignoreLessThan: "Abaikan kurang daripada",
              aSentenceOfWords: "Perkataan ayat",
              emotionalDetection: "Pengesanan emosi",
              emotionalNormalScore: "Skor emosi normal",
              negativeEmotions: "Emosi negatif",
              illegalWordDetection: "Pengesanan kata pelanggaran",
              scriptViolationConfiguration:
                "Konfigurasi pelanggaran pertuturan",
              realTimePromptOnTheFrontEnd: "Petua masa nyata di bahagian depan",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Soalan dan Jawapan Pangkalan Pengetahuan",
                  qAMethod: "Kaedah Soal Jawab",
                  numberOfQuestionsAndAnswers: "Bilangan soalan dan jawapan",
                  scoringRules: "Peraturan pemarkahan",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Sebarang soalan dan jawapan betul, jadi",
                  branch: "Mata",
                  successfullySaved: "Simpan dengan jayanya",
                },
                pptNode: {
                  verificationPassed: "Lulus pengesahan",
                  upload: "Muat naik",
                  supportPptPdfFormatFiles: "Menyokong fail format ppt, pdf",
                  uploadSlide: "Muat naik slaid",
                  assessmentMethod: "Kaedah penilaian",
                  associateSlide: "Slaid bersekutu",
                  standardScript: "Kemahiran bercakap standard",
                  successfullySaved: "Simpan dengan jayanya",
                },
                robotNode: {
                  robotScript: "Kata-kata robot",
                  randomScript: "Kata-kata rawak",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Pilih ucapan semantik yang serupa, dan robot akan mengatakan salah satu ayat secara rawak",
                  customerPortrait: "Potret pelanggan",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Apabila potret pelanggan konsisten, robot mengatakan ini",
                  setAsDefaultScript: "Tetapkan sebagai perkataan lalai",
                  yes: "Ya",
                  no: "Tidak",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Ini berlaku apabila robot gagal memukul tag pengguna",
                  successfullySaved: "Simpan dengan jayanya",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Node cawangan ini sudah mempunyai kemahiran pertuturan lalai, adakah anda mengganti pertuturan lalai dengan kemahiran pertuturan semasa?",
                },
                startNode: {
                  paragon: "Narasi",
                  successfullySaved: "Simpan dengan jayanya",
                },
                userNode: {
                  verificationPassed: "Lulus pengesahan",
                  standardScript: "Kemahiran bercakap standard",
                  successfullySaved: "Simpan dengan jayanya",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "Sila lengkapi konfigurasi",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Sila masukkan contoh perbualan",
                  dialogueExample: "Contoh perbualan",
                  generateConversationExamples: "Menjana contoh perbualan",
                  mattersNeedingAttention:
                    "Perkara yang perlu diberi perhatian",
                  pleaseEnterThePromptLanguage: "Sila masukkan arahan",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Adegan dialog",
                  simulateCharacter: "Peranan simulasi",
                  accompanyingTrainees: "Objek tanding",
                  selectFromTemplateLibrary: "Pilih dari perpustakaan templat",
                  templateLibrary: "Perpustakaan templat",
                  role: "Peranan",
                  scene: "Adegan",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Tetingkap ujian slaid 【",
              clearContent: "Kosongkan kandungan",
              close: "Tutup",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "Proses penjanaan AI",
                scriptStyle: "Gaya bercakap",
                theMostConversationRounds: "Pusingan dialog paling banyak",
                copyConversation: "Salin perbualan",
                application: "Permohonan",
                regenerate: "Menjana semula",
                startGenerating: "Mula menjana",
                pleaseGenerateAProcess: "Sila buat proses",
                copySuccessful: "Salin berjaya",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Wakil jualan",
                    customerServicePersonnel:
                      "Kakitangan perkhidmatan pelanggan",
                    storeCounter: "Kaunter kedai",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "Hasilkan ucapan teks penuh",
                speaker: "Penceramah",
                scriptStyle: "Gaya bercakap",
                copyConversation: "Salin perbualan",
                application: "Permohonan",
                regenerate: "Menjana semula",
                startGenerating: "Mula menjana",
                pleaseGenerateAProcess: "Sila buat proses",
                operationSuccessful: "Operasi yang berjaya",
                copySuccessful: "Salin berjaya",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Kemahiran bercakap" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Kemahiran bercakap tidak boleh kosong!",
              copySuccessful: "Salin berjaya",
              cancel: "Batalkan",
              copy: "Salin",
              replaceTheOriginalText: "Gantikan teks asal",
              aiGeneration: "Penjanaan Ai",
              aiPolishing: "Ai menggilap",
              currentStyle: "Gaya Semasa",
              rigorous: "Ketat",
              mild: "Lembut",
              lively: "Meriah",
              simplicity: "Mudah",
              pleaseEnterTheSpeakerRole: "Sila masukkan peranan pembicara",
              startGenerating: "Mula menjana",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Kemahiran bercakap tidak boleh kosong!",
              copySuccessful: "Salin berjaya",
              aiRewrite: "Ai menulis semula",
              cancel: "Batalkan",
              copy: "Salin",
              replaceTheOriginalText: "Gantikan teks asal",
              currentStyle: "Gaya Semasa",
              rigorous: "Ketat",
              mild: "Lembut",
              lively: "Meriah",
              simplicity: "Mudah",
              pleaseEnterTheSpeakerRole: "Sila masukkan peranan pembicara",
              startGenerating: "Mula menjana",
            },
          },
          index: {
            verificationPassed: "Lulus pengesahan",
            ruleValidation: "Pengesahan peraturan",
            aIGeneration: "Penjanaan AI",
            generateFullTextSpeechScript: "Hasilkan ucapan teks penuh",
            editCourse: "Kursus penyuntingan",
            importConversation: "Import perbualan",
            uploadExcelToCompleteDialogueImport:
              "Muat naik Excel untuk menyelesaikan import dialog",
            editBasicInformation: "Edit maklumat asas",
            test: "Ujian",
            release: "Pelepasan",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Label baru" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Tidak dapat dipulihkan selepas penghapusan, adakah anda pasti mahu menghapus label ini?",
              delete: "Padamkan",
              cancel: "Batalkan",
            },
          },
        },
        index: {
          labelManagement: "Pengurusan Label",
          newGroup: "Kumpulan baru",
          edit: "Edit",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Tidak dapat dipulihkan selepas penghapusan. Adakah anda pasti mahu menghapus kumpulan tab ini?",
          delete: "Padamkan",
          cancel: "Batalkan",
          editGroup: "Edit kumpulan",
          labelGroupName: "Nama kumpulan label",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Melumpuhkan kamera",
              noCameraDevicesFound: "Tidak menemui peranti kamera",
            },
          },
          cue: {
            index: {
              regenerate: "Menjana semula",
              generate: "Menjana",
              referenceScript: "Kata-kata rujukan",
              mattersNeedingAttention: "Perkara yang perlu diberi perhatian",
              examFrequency: "Bilangan peperiksaan",
              section: "No.",
              timestotal: "Kali/jumlah",
              second: "Kali",
              requirementsForPassingTheExam: "Syarat lulus peperiksaan",
              examScore: "Skor peperiksaan ≥",
              branch: "Mata",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Tiada potret",
              customerBackground: "Latar belakang pelanggan",
              customerLabel: "Label pelanggan",
            },
          },
          flow: {
            index: {
              processReminder: "Permintaan proses (",
              score: "Skor",
              TotalScore: "/Jumlah skor",
            },
          },
          index: {
            taskName: "Nama tugas",
            taskStatus: "Status misi",
            whole: "Semua",
            completionStatus: "Status siap",
            courseClassification: "Pengelasan Kursus",
            practiceMode: "Mod amalan",
            examMode: "Mod peperiksaan",
            practiceTime: "Masa latihan",
          },
          ppt: {
            index: {
              nextPage: "Seterusnya",
              tips: "Petua",
              continueWithCurrentPage: "Teruskan halaman semasa",
              goToTheNextPage: "Pergi ke halaman seterusnya",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Setelah memasuki halaman seterusnya, anda tidak akan dapat kembali ke halaman sebelumnya, sila sahkan bahawa halaman semasa sudah lengkap sebelum melompat",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Klik halaman seterusnya tidak akan muncul arahan ini",
            },
            list: { index: { slide: "Slaid" } },
          },
          record: {
            index: {
              unableToRecord: "Tidak dapat merakam:",
              creatingARecorderService: "Mewujudkan Perkhidmatan Perakam",
              turnOffTheRecorderService: "Tutup Perkhidmatan Perakam",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Pelanggan semasa disambungkan ke pelayan Websocket ASR",
              afterProcessingTheLastFrameRestoreTheState:
                "Setelah memproses bingkai terakhir, kembalikan keadaan",
              aSRConnectionClosed: "Sambungan ASR ditutup...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Rakaman dijeda dan diproses, tetapi data masih ada di cache",
              foundASRChannelClosedRecreateWebsocketLink:
                "Cari saluran ASR ditutup, buat semula pautan Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Didapati bahawa saluran ASR tidak dihidupkan, buat semula pautan Websocket",
            },
            ppt: { hook: { index: { serverError: "Ralat pelayan" } } },
            text: {
              index: {
                pleaseEnter: "Sila masukkan",
                sendOut: "Hantar",
                startPracticing: "Mula berlatih",
                startTheExam: "Mulakan peperiksaan",
              },
            },
            util: {
              recorded: "Dirakam",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 bit pcm SendFrameSize mesti menjadi gandaan bilangan bulat 2",
              pCMSamplingRateShouldNotOccur:
                "Kadar persampelan pcm tidak boleh berlaku",
              andTheRequiredSamplingRate:
                "Dan kadar persampelan yang diperlukan",
              atypism: "Tidak konsisten",
              errorsThatShouldNotOccur:
                "Kesilapan yang tidak sepatutnya berlaku:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> Data yang tidak teratur, jika syaratnya tidak tinggi, anda boleh membuangnya secara langsung </span>',
              flower: "Bunga",
              TransferStopped: ": Penghantaran dihentikan",
            },
            video: { index: { camera: "Kamera" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Pelanggan semasa disambungkan ke pelayan Websocket ASR",
                    aSRConnectionClosed: "Sambungan ASR ditutup...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Cari saluran ASR ditutup, buat semula pautan Websocket",
                    unableToRecord: "Tidak dapat merakam:",
                    aSRChannelClosedReopened:
                      "Saluran ASR ditutup, dibuka semula",
                    theASRChannelWasNotCreatedStartingNow:
                      "Saluran ASR tidak dibuat, kini mula dibuat",
                    useEffectCallbackToClearTheASRChannel:
                      "Panggilan balik useEffect untuk membersihkan saluran ASR",
                    creatingARecorderService: "Mewujudkan Perkhidmatan Perakam",
                    turnOffTheRecorderService: "Tutup Perkhidmatan Perakam",
                  },
                  util: {
                    recorded: "Dirakam",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 bit pcm SendFrameSize mesti menjadi gandaan bilangan bulat 2",
                    pCMSamplingRateShouldNotOccur:
                      "Kadar persampelan pcm tidak boleh berlaku",
                    andTheRequiredSamplingRate:
                      "Dan kadar persampelan yang diperlukan",
                    atypism: "Tidak konsisten",
                    errorsThatShouldNotOccur:
                      "Kesilapan yang tidak sepatutnya berlaku:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> Data yang tidak teratur, jika syaratnya tidak tinggi, anda boleh membuangnya secara langsung </span>',
                    flower: "Bunga",
                    TransferStopped: ": Penghantaran dihentikan",
                  },
                },
                index: { failInSend: "Gagal menghantar" },
              },
              index: {
                startRecording: "Mula merakam",
                endRecording: "Tamat rakaman",
                startPracticing: "Mula berlatih",
                startTheExam: "Mulakan peperiksaan",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Baki peperiksaan semasa adalah",
              totalScriptScore: "Jumlah skor:",
              totalEmotionalScore: "Jumlah skor emosi:",
              totalScoreOfExpression: "Nyatakan jumlah skor:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Hasil pemarkahan tugas semasa perlu disemak secara manual. Hasil akhir akan diberitahu setelah semakan selesai. Harap bersabar",
              areYouSureToSubmit: "Adakah anda mengesahkan penyerahan?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Latihan telah berakhir, adakah anda mengesahkan penyerahannya?",
              theExamHasEndedAreYouSureToSubmit:
                "Peperiksaan telah berakhir, adakah anda mengesahkan penyerahannya?",
              completed: "Selesai",
              page: "Halaman",
              pageCount: "Jumlah halaman",
              topic: "Soalan",
              totalNumberOfQuestions: "Jumlah soalan",
              totalScore: "Jumlah markah",
              branch: "Mata",
              accumulatedCompletionProgress: "Kemajuan penyelesaian kumulatif",
              accumulatedPracticeDuration: "Tempoh latihan kumulatif",
              lastPauseExerciseResults: "Hasil latihan jeda terakhir",
              exerciseInstructions: "Arahan latihan",
              examInstructions: "Arahan peperiksaan",
              halfwayPausePracticeResults: "Hasil latihan jeda pertengahan",
              tips: "Petua",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Selepas keluar, latihan ini tidak dikira dalam gred. Adakah anda mengesahkan keluar?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Tidak dibenarkan menarik diri semasa peperiksaan, adakah anda mengesahkan penyerahannya?",
              pleaseOperateWithCaution: "Kedua, sila berhati-hati)",
              restart: "Mulakan semula",
              returnToLearning: "Kembali untuk belajar",
              continuePracticing: "Teruskan amalan",
              continuingTheExam: "Teruskan peperiksaan",
              return: "Kembali",
              notSubmittedTemporarily: "Tidak dihantar",
              iGotIt: "Saya tahu",
              confirmExit: "Sahkan keluar",
              confirmSubmission: "Sahkan penyerahan",
              signOut: "Keluar",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Penggantungan",
              submit: "Hantar",
              remainingTimeOfThisQuestion: "Masa yang tinggal dalam soalan ini",
              SecondsLeftUntilTheEndTime: "Tinggal 10 saat lagi sebelum tamat",
              remainingTime: "Masa yang tinggal",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Kandungan suara pengguna tidak dikesan, sila cuba lagi",
          },
          wave: {
            index: {
              continueRecording: "Teruskan rakaman",
              submit: "Hantar",
              endRecording: "Tamat rakaman",
              suspendRecording: "Jeda rakaman",
              startPracticing: "Mula berlatih",
              startTheExam: "Mulakan peperiksaan",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Mod dialog",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Bunyi terlalu kuat, sila beralih ke persekitaran yang tenang",
              normalNoiseDetection: "Pengesanan bunyi biasa",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Kelantangan terlalu kecil, sila tingkatkan kelantangan",
              volumeDetectionIsNormal: "Pengesanan kelantangan adalah normal",
              noiseDetection: "Pengesanan bunyi bising",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Harap diam dan rakam bunyi persekitaran 3s",
              endRecording: "Tamat rakaman",
              startRecording: "Mula merakam",
              volumeDetection: "Pengesanan kelantangan",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Sila baca dengan kuat pada kelantangan biasa "Sekarang lakukan ujian kelantangan"',
              playLastDetectedAudio: "Mainkan audio yang dikesan terakhir",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Sila baca syarat dengan teliti sebelum memulakan",
              return: "Kembali",
              iGotIt: "Saya tahu",
              timeLimitForSingleQuestionAnswering:
                "Had masa untuk menjawab soalan tunggal",
              totalTimeLimit: "Jumlah masa terhad",
              unlimitedTime: "Masa tidak terhad",
              stillNeedToCompleteTheTaskInTotal: "Sentiasa menyelesaikan tugas",
              practice: "Amalan",
              second: "Kali",
              taskScore: "Skor tugas sekali ≥",
              branch: "Mata",
              or: "Atau",
              examScore: "Skor peperiksaan ≥",
              remainingExamTimes: "Baki bilangan peperiksaan",
              totalScore: "Jumlah markah",
              Script: "(Bercakap",
              scoreEmotion: "Emosi",
              fractionExpression: "Sub-ungkapan",
              points: "Mata)",
              standardScore: "Mata pematuhan",
              excellentScore: "Mata cemerlang",
              exerciseInstructions: "Arahan latihan",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Untuk menjawab soalan dengan lancar, sila lengkapkan pemeriksaan peralatan terlebih dahulu:",
              camera: "Kamera",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Mampu melihat diri anda dalam video bermaksud bahawa kamera adalah normal",
              microphone: "Mikrofon",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Sila bercakap dengan mikrofon, melihat corak gelombang bermaksud mikrofon normal",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Kosong",
                  copyTo: "Salin ke",
                  classification: "Pengelasan",
                  rename: "Dinamakan semula",
                  newlyBuild: "Baru",
                  categoryParent: "Pengelasan unggul",
                  moveTo: "Bergerak ke",
                  name: "Nama",
                },
              },
              util: {
                rename: "Dinamakan semula",
                moveTo: "Bergerak ke",
                copyTo: "Salin ke",
                delete: "Padamkan",
                courseClassification: "Pengelasan Kursus",
                newlyBuild: "Baru",
              },
            },
          },
          edit: { index: { curriculum: "Kursus" } },
          index: {
            learn: "Belajar",
            practice: "Latihan",
            examination: "Ujian",
            curriculum: "Kursus",
            courseIntroduction: "Pengenalan kursus:",
            createdOn: "Buat masa:",
            published: "Dikeluarkan",
            tasks: "Tugas",
            unpublishedTasks: "Misi tidak diterbitkan",
            task: "Misi",
            operation: "Operasi",
            learningMaterials: "Bahan pembelajaran",
            goPractice: "Pergi berlatih",
            publishTask: "Hantar tugas",
            taskData: "Data tugas",
            edit: "Edit",
            copy: "Salin",
            successfullyCopied: "Salin berjaya",
            delete: "Padamkan",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Selepas penghapusan, semua data tugas di bawah kursus ini akan dihapus. Adakah anda mengesahkan penghapusan kursus?",
            courseName: "Nama kursus",
            creationTime: "Buat masa",
            releaseStatus: "Status pelepasan",
            whole: "Semua",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Tiada bunyi yang dikesan",
              voice: "Suara",
              text: "Teks",
              referenceScript: "Kata-kata rujukan:",
              clickOnTheRightSideToStartRecording:
                "Klik di sebelah kanan untuk memulakan rakaman",
              sendOut: "Hantar",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Pelanggan semasa disambungkan ke pelayan Websocket ASR",
                afterProcessingTheLastFrameRestoreTheState:
                  "Setelah memproses bingkai terakhir, kembalikan keadaan",
                aSRConnectionClosed: "Sambungan ASR ditutup...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Rakaman dijeda dan diproses, tetapi data masih ada di cache",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Cari saluran ASR ditutup, buat semula pautan Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Didapati bahawa saluran ASR tidak dihidupkan, buat semula pautan Websocket",
                unableToRecord: "Tidak dapat merakam:",
                creatingARecorderService: "Mewujudkan Perkhidmatan Perakam",
                turnOffTheRecorderService: "Tutup Perkhidmatan Perakam",
              },
              util: {
                recorded: "Dirakam",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 bit pcm SendFrameSize mesti menjadi gandaan bilangan bulat 2",
                pCMSamplingRateShouldNotOccur:
                  "Kadar persampelan pcm tidak boleh berlaku",
                andTheRequiredSamplingRate:
                  "Dan kadar persampelan yang diperlukan",
                atypism: "Tidak konsisten",
                errorsThatShouldNotOccur:
                  "Kesilapan yang tidak sepatutnya berlaku:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> Data yang tidak teratur, jika syaratnya tidak tinggi, anda boleh membuangnya secara langsung </span>',
                flower: "Bunga",
                TransferStopped: ": Penghantaran dihentikan",
              },
            },
            finishTest: {
              index: {
                endTest: "Tamat ujian",
                testResult: "Keputusan ujian",
                returnToCanvas: "Kembali ke kanvas",
                retest: "Ujian semula",
              },
            },
          },
          index: {
            testFromCurrentNode: "Ujian dari nod semasa",
            listening: "Dalam radio...",
            canvasTesting: "Ujian kanvas",
            endOfProcess: "Akhir proses",
            restart: "Mulakan semula",
            start: "Mula",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Selesaikan analisis",
                  expandParsing: "Kembangkan analisis",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: ", Perkataan udara yang berlebihan",
                  second: "Kali",
                  ratingOverview: "Gambaran keseluruhan penilaian",
                  totalScore: "Jumlah markah",
                  section: "No.",
                  name: "Nama",
                  of: "(Jumlah",
                  people: "Orang)",
                  scriptScore: "Skor perkataan",
                  common: "Jumlah",
                  keyPoints: "Perkara utama,",
                  notReached: "Tidak tercapai,",
                  have: "Mempunyai",
                  violationOfRegulations: "Pelanggaran",
                  emotionalScore: "Skor emosi",
                  appear: "Muncul",
                  secondaryNegativeEmotions: "Emosi negatif",
                  expressionScore: "Skor ekspresi",
                  speakTooFast: "Bersuara terlalu pantas",
                  times: "Kali,",
                  speakTooSlowly: "Kelajuan bercakap terlalu perlahan",
                  average: "Purata",
                  wordminute: "Perkataan/minit",
                },
              },
              score: {
                index: {
                  studentNodeName: "Nama nod pelajar",
                  keyPointName: "Nama mata",
                  slideNodeName: "Nama nod slaid",
                  dialogue: "Dialog",
                  mainPoints: "Perkara utama",
                  slide: "Slaid",
                  detectionResult: "Keputusan ujian",
                  satisfy: "Bertemu",
                  dissatisfaction: "Tidak berpuas hati",
                  score: "Skor",
                  script: "Bercakap",
                  semantics: "Semantik",
                  scriptViolation: "Pelanggaran kata-kata",
                  emotion: "Emosi",
                  expression: "Ungkapan",
                },
              },
              taskTitle: { index: { branch: "Mata" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Kali ini",
                  lastTime: "Kali terakhir",
                  comprehensiveEvaluation: "Penilaian komprehensif",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-kiri: 8px">· Pengumpulan ucapan standard',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Perkataan, pelajar dan jumlah perkataan dalam bacaan",
                  aWordspan: "Perkataan </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Kali </span><br /><span style = "margin-kiri: 8px">· bercakap terlalu perlahan',
                  timesspan: "Kali </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-kiri: 8px">· Terlalu banyak perkataan',
                  complete: "Kandungan lengkap",
                  keyHit: "Hit mata",
                  languageNorm: "Spesifikasi bahasa",
                  fluentExpression: "Ekspresi lancar",
                  emotionallyPositive: "Emosi positif",
                  radarChart: "Gambar rajah radar",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-kiri: 8px">· Bilangan proses lengkap',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-kiri: 8px">· Hit mata',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-kiri: 8px">· Ya',
                  dealingWithIllegalWordsspan: "Pelanggaran </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-kiri: 8px">· Kelajuan bercakap terlalu cepat',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Times </span><br /><span style = "margin-kiri: 8px">· Terlalu banyak perkataan',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-kiri: 8px">· Emosi negatif',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Rekod pemarkahan",
                  firstRating: "Penilaian pertama",
                  totalScore: "Jumlah markah",
                  changeTo: "Tukar kepada",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Fasa komunikasi",
                  communicationObjectives: "Matlamat komunikasi",
                  scoringCriteria: "Kriteria pemarkahan",
                  suggestionsForImprovement: "Cadangan promosi",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Bilangan baki:",
                  second: "Kali",
                  doItAgain: "Sekali lagi",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Pelayar tidak menyokong main balik audio.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Komen dan cadangan",
                  regenerate: "Menjana semula",
                  generate: "Menjana",
                  generationFailed: "Kegagalan penjanaan",
                },
              },
              resultModal: {
                index: { return: "Kembali", viewDetails: "Lihat butiran" },
              },
            },
            index: {
              reRatingSuccessful: "Skor semula berjaya",
              reRatingFailed: "Gagal menilai semula",
              downloadRecording: "Muat turun rakaman",
              downloadText: "Muat turun teks",
              download: "Muat turun",
              reRating: "Penilaian semula",
              submitARatingAppeal: "Hantar rayuan pemarkahan",
              reviewSubmission: "Hantar semakan",
              saveChanges: "Simpan pengubahsuaian",
              previousSentence: "Kalimat sebelumnya",
              nextSentence: "Kalimat seterusnya",
              score: "Penilaian",
              scoringSituation: "Skor",
              dialogueAnalysis: "Analisis dialog",
            },
          },
          index: {
            ratingAppealInProgress: "Rayuan pemarkahan",
            operation: "Operasi",
            wrongQuestionSet: "Set soalan yang salah",
            ratingUpdate: "Kemas kini skor",
            viewDetails: "Lihat butiran",
            curriculum: "Kursus",
            taskName: "Nama tugas",
            taskType: "Jenis Tugas",
            score: "Skor",
            scoringResults: "Keputusan skor",
            time: "Masa",
            courseClassification: "Pengelasan Kursus",
            whole: "Semua",
            learningTime: "Masa belajar",
            historicalLearningData: "Data pembelajaran sejarah",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Latihan berhenti sebentar",
              lastProgress: "Kemajuan terakhir",
              continuePracticing: "Teruskan amalan",
              practice: "Amalan",
              startPracticing: "Mula berlatih",
              historicalData: "Data sejarah",
              courseIntroduction: "Pengenalan kursus:",
              exerciseDifficulty: "Kesukaran latihan:",
              practiceCompletionRequirements:
                "Latihan untuk melengkapkan keperluan:",
              practiceFrequency: "Bilangan latihan ≥",
              second: "Kali",
              anyScore: "Sebarang skor ≥",
              branch: "Mata",
              or: "Atau",
              taskList: "Senarai tugas",
              practiceTime: "Masa latihan:",
              to: "Ke",
              unlimitedTime: "Masa tanpa had",
              completionStatus: "Status siap",
              numberOfExercises: "Bilangan latihan",
              maximumScore: "Skor tertinggi",
              operation: "Operasi",
              enteringLearning: "Masukkan pembelajaran",
              historicalLearningData: "Data pembelajaran sejarah",
              wrongQuestionSet: "Set soalan yang salah",
            },
          },
          index: {
            practiceTasks: "Tugas amalan",
            examTasks: "Tugas peperiksaan",
            task: "Misi",
          },
          learn: {
            index: {
              courseName: "Nama kursus",
              courseIntroduction: "Pengenalan kursus",
              learningContent: "Kandungan pembelajaran",
              downloadLearningMaterials: "Muat turun bahan pembelajaran",
              startPracticing: "Mula berlatih",
            },
          },
          test: {
            index: {
              taskList: "Senarai tugas",
              courseIntroduction: "Pengenalan kursus:",
              examDifficulty: "Kesukaran peperiksaan:",
              requirementsForPassingTheExam: "Syarat lulus peperiksaan:",
              examScore: "Skor peperiksaan ≥",
              branch: "Mata",
              examTime: "Masa peperiksaan:",
              to: "Ke",
              unlimitedTime: "Masa tanpa had",
              completionStatus: "Status siap",
              completionTimes: "Bilangan penyelesaian",
              maximumScore: "Skor tertinggi",
              operation: "Operasi",
              immediateExam: "Peperiksaan sekarang",
              history: "Rekod sejarah",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Kata-kata rujukan" } },
              flow: {
                index: {
                  wrongQuestionItem: "Item soalan yang salah (",
                  score: "Skor",
                  TotalScore: "/Jumlah skor",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Ralat pelayan" } },
                  index: {
                    startRecording: "Mula merakam",
                    endRecording: "Tamat rakaman",
                    startPracticing: "Mula berlatih",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Sila masukkan",
                    sendOut: "Hantar",
                    startPracticing: "Mula berlatih",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Mula merakam",
                    endRecording: "Tamat rakaman",
                    startPracticing: "Mula berlatih",
                  },
                  hook: { index: { failInSend: "Gagal menghantar" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Skor latihan untuk soalan yang salah ini",
                  tips: "Petua",
                  areYouSureToEnd: "Adakah akhir pengesahan?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Berlatih lagi tanpa menguasai soalan yang salah",
                  continue: "Teruskan",
                  returnToTheSetOfIncorrectQuestions:
                    "Kembali ke set soalan yang salah",
                  end: "Tamat",
                  wrongQuestionItem: "Item soalan yang salah",
                  numberOfExercisesThisTime: "Bilangan latihan ini",
                  masteringTheSituation: "Menguasai keadaan",
                  mastered: "Menguasai",
                  notMastered: "Tidak dikuasai",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Berlatih soalan ini lagi",
                  nextQuestion: "Soalan seterusnya",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Latihan soalan yang salah",
                endExercise: "Tamat latihan",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Latihan soalan yang salah",
                endExercise: "Tamat latihan",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Latihan soalan yang salah",
                endExercise: "Tamat latihan",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Lihat perkataan",
                  section: "No.",
                  secondaryDiscourseTechnique: "Kata kedua",
                },
              },
              tableItem: {
                count: { second: "Kali" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Maklumat berkaitan tidak dijumpai",
                  recallWords: "Ingat perkataan",
                  branch: "Mata",
                  answerIncorrectly: "Jawapan yang salah",
                  second: "Kali",
                  wrongAnswerRate: "Kadar jawapan yang salah",
                },
                standardScript: {
                  standardScript: "Kemahiran bercakap standard",
                  myScript: "Perkataan saya",
                },
                status: {
                  mastered: "Menguasai",
                  notMastered: "Tidak dikuasai",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "Jumlah jawapan",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Bilangan soalan yang salah telah dipraktikkan",
                },
              },
            },
            index: {
              wrongQuestionList: "Senarai soalan yang salah",
              wrongQuestionSet: "Set soalan yang salah",
              mispracticeExercises: "Latihan soalan yang salah",
              taskName: "Nama tugas",
              staff: "Pekerja",
              masteringTheSituation: "Menguasai keadaan",
              whole: "Semua",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Bilangan latihan ≥",
              second: "Kali",
              or: "Atau",
              excellentScoreAtAnyOneTime:
                "Skor cemerlang pada bila-bila masa (≥",
              points: "Mata)",
              examScore: "Skor peperiksaan ≥",
              branch: "Mata",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Pengagihan purata rawak",
              assignToDesignatedPersonnel:
                "Ditugaskan kepada kakitangan yang ditetapkan",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Meningkatkan bilangan peperiksaan untuk berjaya",
              failedToIncreaseTheNumberOfExams:
                "Meningkatkan bilangan peperiksaan gagal",
              increaseTheNumberOfExams: "Meningkatkan bilangan peperiksaan",
              selectObjects: "Pilih objek",
            },
          },
          executionFilter: { index: { participationRate: "Kadar Penyertaan" } },
          intelligentReview: {
            index: {
              overallReview: "Ulasan keseluruhan",
              pleaseEnterTheReviewCriterianotMandatory:
                "Sila masukkan standard komen (tidak diperlukan)",
              commentaryOnSingleSentenceRhetoric: "Komen ayat tunggal",
              realTimeReview: "Ulasan masa nyata",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Purata skor peperiksaan",
            averageExamDurationmin: "Purata tempoh peperiksaan (min)",
            accumulatedPracticeDurationmin: "Tempoh latihan kumulatif (min)",
            accumulatedPracticeTimes: "Jumlah latihan terkumpul",
            totalScore: "Jumlah markah",
            average: "Skor purata",
            total: "Jumlah",
            keyHitCount: "Bilangan hits",
            practiceAverageScore: "Amalkan skor purata",
            averagePracticeDurationmin: "Purata tempoh latihan (min)",
            topOfTheHighestScore: "Skor tertinggi Top3",
            branch: "Mata",
            errorProneItemTop: "Item ralat Top5",
            hitRate: "Kadar hit",
            hitRateOfKeyPointsInScript: "Kadar hit",
            averageScriptScore: "Skor purata",
            sortByProcess: "Isih mengikut proses",
            sortByScore: "Susun mengikut skor",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Skor purata halaman ini/jumlah skor halaman ini",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Skor purata item/jumlah skor item",
            details: "Butiran",
            viewRules: "Lihat peraturan",
            standardScript: "Kemahiran bercakap standard",
          },
          scoreDetailList: {
            section: "No.",
            second: "Kali",
            practice: "Amalan",
            examination: "Peperiksaan",
            notMeetingStandards: "Tidak sesuai",
            meetingStandards: "Mencapai standard",
            excellent: "Cemerlang",
            frequency: "Bilangan kali",
            fraction: "Pecahan",
            result: "Keputusan",
            operation: "Operasi",
            viewDetails: "Lihat butiran",
          },
          index: {
            common: "Jumlah",
            people: "Orang",
            staff: "Pekerja",
            notMeetingStandards: "Tidak sesuai",
            meetingStandards: "Mencapai standard",
            excellent: "Cemerlang",
            notStarted: "Tidak bermula",
            haveInHand: "Dalam proses",
            completed: "Selesai",
            passed: "Lulus",
            hangInTheAir: "Belum selesai",
            failed: "Tidak lulus",
            unlimitedTime: "Masa tanpa had",
            to: "Ke",
            maximumScoringCriteria: "Standard skor tertinggi",
            completionStatus: "Status siap",
            employeeName: "Nama pekerja",
            frequency: "Bilangan kali",
            practice: "Amalan",
            examination: "Peperiksaan",
            average: "Skor purata",
            speechCraftAvgScore: "Skor purata",
            emotionAvgScore: "Skor Purata Emosi",
            speedAvgScore: "Menyatakan skor purata",
            flowAvgScore: "Skor purata proses",
            practiceCostTime: "Tempoh kumulatif",
            participateCommitRate: "Kadar pematuhan",
            participateExcellentRate: "Kadar yang sangat baik",
            duration: "Tempoh",
            score: "Skor",
            scoringResults: "Keputusan skor",
            flowScore: "Skor proses",
            craftScore: "Skor perkataan",
            emotionScore: "Skor emosi",
            speedScore: "Skor ekspresi",
            contentIntegrity: "Integriti kandungan",
            pointHitRate: "Titik hit",
            emotionNegativeRate: "Emosi negatif",
            paraPhaSiaRate: "Kadar pertuturan yang tidak normal",
            toneOvermuchRate: "Terlalu banyak perkataan gas",
            maximumScore: "Skor tertinggi",
            highestScoreResult: "Keputusan Skor Tertinggi",
            state: "Status",
            averageDurationmin: "Purata Tempoh (min)",
            lately: "Baru-baru ini",
            time: "Masa",
            operation: "Operasi",
            viewDetails: "Lihat butiran",
            wrongQuestionSet: "Set soalan yang salah",
            taskName: "Nama tugas",
            completeRequirements: "Keperluan lengkap",
            publishTo: "Terbitkan objek",
            taskTime: "Masa misi",
            fractionalComposition: "Komposisi pecahan",
            totalScore: "Jumlah markah",
            branch: "Mata",
            Script: "(Bercakap",
            scoreEmotion: "Emosi",
            fractionExpression: "Sub-ungkapan",
            points: "Mata)",
            implementationRate: "Kadar Penyertaan",
            numberOfExecutors: "Bilangan peserta",
            numberOfPeopleNotExecuted:
              "Bilangan orang yang tidak mengambil bahagian",
            completionRate: "Kadar penyelesaian",
            numberOfCompletedPersonnel: "Bilangan siap",
            numberOfUnfinishedPersonnel: "Bilangan orang yang belum selesai",
            complianceRate: "Kadar pematuhan",
            numberOfQualifiedPersonnel: "Bilangan orang yang memenuhi standard",
            numberOfNonCompliantIndividuals:
              "Bilangan orang yang tidak memenuhi standard",
            excellentRate: "Kadar yang sangat baik",
            participateCompleteRate: "Kadar penyelesaian penyertaan",
            numberOfOutstandingIndividuals: "Bilangan cemerlang",
            numberOfNonOutstandingIndividuals:
              "Bilangan orang yang tidak cemerlang",
            export: "Eksport",
            taskData: "Data tugas",
            resultAnalysis: "Analisis Keputusan",
            scoreDetails: "Perincian skor-",
            dataOverview: "Gambaran keseluruhan data",
            taskDetail: "Perincian tugas",
            scriptAnalysis: "Analisis perkataan",
            flowAnalysis: "Analisis proses",
            expressionAnalysis: "Analisis ungkapan",
            emotionAnalysis: "Analisis Emosi",
            byTime: "Pandangan dimensi masa",
            byNumber: "Pandangan dimensi masa",
            personData: "Statistik mengikut kakitangan",
            numberData: "Catat butiran mengikut masa",
            count: "Tekan bilangan kali",
            person: "Mengikut bilangan orang",
            reachTimes: "Bilangan sentuhan",
            outOfReachTimes: "Bilangan yang tidak dapat dicapai",
            seeOutOfReach: "Klik untuk melihat",
            accumulative: "Kumulatif",
            script: "Bercakap",
            times: "Kali",
            sentence: "Ayat",
            expressionAverage: "Menyatakan skor purata",
            modalAnalysis: "Analisis kata-kata gas",
            total: "Jumlah",
            muchModalAnalysis: "Terdapat terlalu banyak perkataan gas",
            normal: "Biasa",
            speedAnalysis: "Analisis kadar pertuturan",
            speedDistribution: "Taburan kadar pertuturan",
            speakingSpeed: "Bercakap dengan pantas",
            normalSpeak: "Kelajuan pertuturan biasa",
            tooFastSpeak: "Cepat bercakap",
            tooSlowlySpeak: "Kelajuan bercakap yang terlalu perlahan",
            violationWord: "Perkataan pelanggaran",
            violationWordAnalysis: "Analisis pelanggaran",
            noData: "Tiada",
            emotionalScore: "Skor emosi",
            emotionalDistribution: "Pengagihan emosi",
            negativeEmotion: "Emosi negatif",
            normalEmotion: "Emosi normal",
            getDetail: "Lihat butiran",
            barPattern: "Paparan carta bar",
            timeDimension: "Pandangan latitud masa",
            timesDimension: "Pandangan dimensi masa",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Skor purata",
                  averageScoreOfScript: "Skor purata",
                  averageEmotionalScore: "Purata emosi",
                  expressionAverageScore: "Menyatakan skor purata",
                  processAverageScore: "Skor purata proses",
                  accumulatedDuration: "Tempoh pengumpulan",
                  accumulatedTimes: "Bilangan kumulatif",
                  participationComplianceRate: "Kadar pematuhan penyertaan",
                  participationRateForAchievingExcellence: "Penyertaan",
                  branch: "Mata",
                  numberOfPeople: "Bilangan orang",
                  averageScoreOfExpression: "Menyatakan skor purata",
                  people: "Orang",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Emosi negatif" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Skor purata",
                  branch: "Mata",
                },
                useSummaryDetail: { keyHitRate: "Titik hit" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Ulasan pintar",
            pleaseImproveTheConfiguration: "Sila lengkapi konfigurasi",
            examResults: "Keputusan peperiksaan",
            showTheResultsAfterEachExam:
              "Tunjukkan hasilnya selepas setiap peperiksaan",
            showTheResultsAfterTheLastExam:
              "Tunjukkan hasilnya selepas peperiksaan terakhir",
            doNotDisplayResults: "Tidak menunjukkan keputusan",
            resultDisplay: "Paparan hasil",
            score: "Skor",
            scoringResults: "Keputusan skor",
            allowViewingScoreDetails: "Benarkan untuk melihat butiran skor",
            viewCanvasConfiguration: "Lihat konfigurasi kanvas",
            synchronizationFailedProcessNodeHasChanged:
              "Penyegerakan gagal, nod proses telah berubah",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Penyegerakan berjaya, sila klik untuk menghantar kursus yang dikemas kini",
            retrievedTheLatestCanvasConfiguration:
              "Telah menarik konfigurasi kanvas terkini",
            synchronizeCanvasConfiguration: "Konfigurasi kanvas segerak",
            scoringMethod: "Kaedah pemarkahan",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Mata, proses {0} mata, kemahiran bercakap {1} mata, emosi {2} mata, kelajuan bercakap {3} mata)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Piawaian yang sangat baik mesti lebih tinggi daripada standard pematuhan",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Kriteria pemarkahan perlu memenuhi lebih besar daripada 0%, kurang dari atau sama dengan 100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Kriteria pemarkahan perlu memenuhi lebih besar daripada 0 mata, kurang daripada atau sama dengan {0} mata",
            doRatingsRequireManualReview:
              "Adakah pemarkahan memerlukan tinjauan manual",
            allocationStrategy: "Strategi peruntukan",
            pleaseSelectTheAssignedPersonnel:
              "Sila pilih kakitangan yang ditugaskan",
            pleaseSelectADesignatedPerson: "Sila pilih orang yang ditentukan",
            timeLimitForSingleQuestionAnswering:
              "Had masa untuk menjawab soalan tunggal",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Dihantar secara lalai kepada pekerja baru",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Penggredan semula bermaksud bahawa ia akan dipadankan sesuai dengan peraturan pemarkahan kanvas terbaru. Tugas semasa mempunyai data pemarkahan yang telah dikaji secara manual.",
            notCovered: "Tidak dilindungi",
            cover: "Liputan",
            participationSituation: "Penyertaan",
            numberOfParticipants: "Bilangan peserta",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Jumlah objek latihan yang dipilih untuk tugas ini",
            sumOfTheNumberOfParticipantsInTheTask:
              "Jumlah orang yang telah mengambil bahagian dalam tugas tersebut",
            numberOfCompletedPersonnel: "Bilangan siap",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Bilangan orang yang memenuhi syarat penyelesaian tugas",
            numberOfQualifiedPersonnel: "Bilangan orang yang memenuhi standard",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Memenuhi bilangan orang yang memenuhi skor tugas",
            excellentPersonnel: "Bilangan cemerlang",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Temui bilangan orang dengan skor tugas yang sangat baik",
            participationRate: "Kadar Penyertaan",
            numberOfParticipantsNumberOfParticipants:
              "Bilangan peserta/bilangan peserta",
            numberOfCompletedNumberOfParticipants:
              "Bilangan siap/bilangan peserta",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Bilangan pematuhan/bilangan peserta",
            excellentNumbernumberOfParticipants:
              "Bilangan Cemerlang/Bilangan Peserta",
            participationCompletionRate: "Kadar penyelesaian penyertaan",
            numberOfCompletedParticipated: "Bilangan siap/bilangan peserta",
            participationComplianceRate: "Kadar pematuhan penyertaan",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Bilangan pematuhan/bilangan peserta",
            participationRateForAchievingExcellence: "Penyertaan",
            accumulatedDuration: "Tempoh pengumpulan",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Peserta mengambil bahagian dalam tugas untuk mencari masa yang lama",
            averageDuration: "Tempoh Purata",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Pelatih mengambil bahagian dalam tugas dan jumlah masa penyertaan",
            accumulatedTimes: "Bilangan kumulatif",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Bilangan peserta yang mengambil bahagian dalam tugas",
            averageScore: "Skor purata",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Skor purata orang yang terlibat dalam misi ini",
            maximumScore: "Skor tertinggi",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Skor tertinggi untuk orang yang terlibat dalam misi ini",
            averageScoreOfScript: "Skor purata",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Jumlah skor kemahiran bercakap pelajar/jumlah penyertaan",
            totalScoreOfScript: "Jumlah skor",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Tetapkan jumlah skor perkataan di bawah tugas ini",
            processAverageScore: "Skor purata proses",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Jumlah skor proses pelajar/jumlah penyertaan",
            totalProcessScore: "Jumlah skor proses",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Jumlah skor proses yang ditetapkan di bawah tugas ini",
            averageEmotionalScore: "Purata emosi",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Skor emosi pelajar/jumlah penyertaan",
            totalEmotionalScore: "Jumlah skor emosi",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Jumlah skor emosi yang ditetapkan di bawah tugas ini",
            averageScoreOfExpression: "Menyatakan skor purata",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Skor ekspresi pelajar/jumlah penyertaan",
            totalScoreOfExpression: "Nyatakan jumlah skor",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Jumlah skor ungkapan yang ditetapkan di bawah tugas ini",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Mula melakukan penilaian semula, anda boleh melompat ke pusat tugas untuk melihat kemajuannya",
            reRating: "Penilaian semula",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Menjaringkan semula bermaksud bahawa ia akan dipadankan mengikut peraturan pemarkahan kanvas terkini dan akan merangkumi hasil pemarkahan semasa",
            releaseTime: "Masa pelepasan",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Disemak",
              pendingReview: "Untuk disemak",
              enterKeywordSearch: "Masukkan carian kata kunci",
            },
            pageRightTable: {
              index: {
                employeeName: "Nama pekerja",
                systemRating: "Skor sistem",
                scoringResults: "Keputusan skor",
                duration: "Tempoh",
                time: "Masa",
                state: "Status",
                whetherToModifyTheRating: "Sama ada untuk membetulkan skor",
                finalScore: "Skor akhir",
                reviewedBy: "Pengulas",
                operation: "Operasi",
                viewScoreDetails: "Lihat butiran skor",
                viewStatus: "Lihat status",
                whole: "Semua",
                correctionStatus: "Status pembetulan",
                completeReview: "Semakan lengkap",
                tips: "Petua",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Markah akhir pelajar tertakluk pada keputusan semakan akhir semasa, sila sahkan",
              },
            },
          },
          index: { manualReview: "Audit manual" },
        },
        review: {
          index: {
            employeeName: "Nama pekerja",
            score: "Skor",
            scoringResults: "Keputusan skor",
            duration: "Tempoh",
            taskName: "Nama tugas",
            numberOfAppealScripts: "Bilangan aduan",
            appealTime: "Masa rayuan",
            processStatus: "Status proses",
            scoreAfterReview: "Skor selepas semakan",
            reviewedBy: "Pengulas",
            operation: "Operasi",
            viewScoreStatus: "Lihat skor",
            whole: "Semua",
            manualReview: "Kajian manual",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Mempraktikkan data tugas",
            implementationRate: "Kadar pelaksanaan",
            numberOfExecutors: "Bilangan eksekutif",
            numberOfPeopleNotExecuted: "Bilangan orang yang tidak dilaksanakan",
            completionRate: "Kadar penyelesaian",
            numberOfCompletedPersonnel: "Bilangan siap",
            numberOfUnfinishedPersonnel: "Bilangan orang yang belum selesai",
            complianceRate: "Kadar pematuhan",
            numberOfQualifiedPersonnel: "Bilangan orang yang memenuhi standard",
            numberOfNonCompliantIndividuals:
              "Bilangan orang yang tidak memenuhi standard",
            excellentRate: "Kadar yang sangat baik",
            numberOfOutstandingIndividuals: "Bilangan cemerlang",
            numberOfNonOutstandingIndividuals:
              "Bilangan orang yang tidak cemerlang",
            examTaskData: "Data tugas peperiksaan",
            published: "Dikeluarkan",
            courses: "Kursus",
            courseData: "Data kursus",
            practiceTasks: "Tugas amalan",
            examTasks: "Tugas peperiksaan",
          },
          practiceSummary: {
            practiceSummary: "Ringkasan latihan",
            numberOfPracticeTasks: "Amalkan bilangan tugas",
            numberOfExercises: "Bilangan latihan",
            accumulatedPracticeDuration: "Tempoh latihan kumulatif",
            complianceRate: "Kadar pematuhan",
            excellentRate: "Kadar yang sangat baik",
            ranking: "Kedudukan",
          },
          rankingList: {
            numberOfExercises: "Bilangan latihan",
            exerciseDuration: "Tempoh latihan",
            complianceRate: "Kadar pematuhan",
            excellentRate: "Kadar yang sangat baik",
            diligenceChart: "Senarai rajin",
            excellentList: "Senarai Cemerlang",
            ranking: "Kedudukan",
            fullName: "Nama",
            i: "Saya",
          },
          taskCard: {
            end: "Tarikh akhir:",
            noDeadline: "Tiada tarikh akhir",
            second: "Kali",
            branch: "Mata",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Tiada misi",
            completed: "Selesai",
          },
          filter: { employeeDepartment: "Jabatan kakitangan" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Eksport berjaya, sila pergi ke pusat tugas untuk melihat",
              export: "Eksport",
            },
          },
          filterConfig: {
            dropdown: { custom: "Adat" },
            modal: {
              updateSuccessful: "Kemas kini berjaya",
              successfullySaved: "Simpan dengan jayanya",
              setAsACommonReport: "Tetapkan sebagai laporan biasa",
              updateCurrentReport: "Kemas kini laporan semasa",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Skor purata",
                  averageScoreOfScript: "Skor purata",
                  averageEmotionalScore: "Skor Purata Emosi",
                  expressionAverageScore: "Menyatakan skor purata",
                  processAverageScore: "Skor purata proses",
                  branch: "Mata",
                },
              },
              comprehensiveEvaluation: { const: { data: "Data" } },
              doneStatus: {
                index: {
                  practice: "Amalan",
                  examination: "Peperiksaan",
                  executed: "Dilaksanakan",
                  unexecuted: "Tidak dilaksanakan",
                  accumulatedExamDuration: "Tempoh peperiksaan kumulatif",
                  examFrequency: "Bilangan peperiksaan",
                },
              },
              filter: {
                index: {
                  practice: "Amalan",
                  examination: "Peperiksaan",
                  pleaseCompleteTheRequiredFields:
                    "Sila perbaiki item yang diperlukan",
                  staff: "Pekerja",
                  taskType: "Jenis Tugas",
                  pleaseSelect: "Sila pilih",
                  taskName: "Nama tugas",
                },
              },
            },
            index: {
              completionStatus: "Keadaan selesai",
              comprehensiveEvaluation: "Penilaian komprehensif",
              abilityGrowthTrend: "Trend pertumbuhan keupayaan",
              errorProneAnalysis: "Analisis ralat",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Bilangan aktif",
            accumulatedPracticeDuration: "Tempoh latihan kumulatif",
            averagePracticeDuration: "Tempoh latihan purata",
            people: "Orang",
            numberOfPracticeTasks: "Amalkan bilangan tugas",
            numberOfExercises: "Bilangan latihan",
            exerciseDuration: "Tempoh latihan",
            second: "Kali",
            avgScore: "Skor purata",
            speechCraftAvgScore: "Skor purata",
            emotionAvgScore: "Skor Purata Emosi",
            speedAvgScore: "Menyatakan skor purata",
            flowAvgScore: "Skor purata proses",
            practiceCostTime: "Tempoh kumulatif",
            totalPracticeTimes: "Bilangan kumulatif",
            participateCommitRate: "Kadar pematuhan penyertaan",
            participateExcellentRate: "Penyertaan",
          },
        },
        index: {
          employeeProfile: "Potret pekerja",
          overview: "Gambaran keseluruhan",
          personalSignage: "Papan tanda peribadi",
          ongoingExercises: "Latihan yang sedang dijalankan",
          ongoingExams: "Peperiksaan sedang dijalankan",
          practiceTaskProgress: "Amalkan kemajuan tugas",
          examTaskProgress: "Kemajuan tugas peperiksaan",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Sila pilih kata konsep",
              sessionDetectionSettings: "Tetapan pengesanan sesi",
              successfullySaved: "Simpan dengan jayanya",
              abnormalSpeechSpeed: "Kelajuan pertuturan yang tidak normal",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Menyokong standard pengesanan kelajuan pertuturan tersuai, hasilnya akan dinilai sebagai kelajuan pertuturan normal, kelajuan pertuturan terlalu cepat, dan kelajuan pertuturan terlalu perlahan. Apabila terdapat kelajuan pertuturan yang tidak normal (terlalu cepat/terlalu lambat), mata akan dikurangkan mengikut peraturan pemarkahan",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Kelajuan bercakap terlalu cepat: lebih besar daripada seminit",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Perkataan, kelajuan bercakap terlalu perlahan: kurang dari seminit",
              charactersIgnoringLessThan: "Perkataan, abaikan kurang daripada",
              aSentenceOfWords: "Perkataan ayat",
              excessiveModalParticles: "Terlalu banyak perkataan gas",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Menyokong kata-kata gas tersuai dan menetapkan peraturan untuk terlalu banyak kata-kata gas. Apabila terdapat terlalu banyak perkataan, mata akan dikurangkan mengikut peraturan pemarkahan",
              theNumberOfRepetitionsIs: "Bilangan pengulangan adalah",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "Kali kedua, dinilai terlalu banyak perkataan",
              definitionOfSensitiveViolations: "Definisi pelanggaran sensitif",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Sokong tesaurus kata pelanggaran tersuai, dan periksa sama ada kata pelanggaran muncul dalam satu ayat. Apabila kata pelanggaran muncul, mata akan dikurangkan mengikut peraturan pemarkahan",
              violationWords: "Perkataan pelanggaran:",
              emotionalDetection: "Pengesanan emosi",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Ujian emosi dilakukan mengikut dialog model emosi sistem, dan hasilnya akan dinilai sebagai emosi normal dan emosi negatif (termasuk negatif, marah, dan mengeluh). Apabila emosi negatif, mata akan dikurangkan mengikut peraturan pemarkahan",
              preserve: "Simpan",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Dimensi pemarkahan",
              ratingPoints: "Mata penilaian",
              explain: "Penerangan",
              type: "Jenis",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Data rawak" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-kiri: 8px">· Pengumpulan ucapan standard',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Perkataan, pelajar dan jumlah perkataan dalam bacaan",
                  aWordspan: "Perkataan </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Kali </span><br /><span style = "margin-kiri: 8px">· bercakap terlalu perlahan',
                  timesspan: "Kali </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-kiri: 8px">· Terlalu banyak perkataan',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Markah adalah" },
                  item: {
                    violationOnceReduced: "Pelanggaran 1 tolak",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Kelajuan pertuturan yang tidak normal (termasuk kelajuan pertuturan yang terlalu cepat dan kelajuan pertuturan yang terlalu perlahan) 1 kali tolak",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Perkataan udara yang berlebihan 1 tolak",
                    oneNegativeDecreaseInEmotions: "Emosi negatif 1 tolak",
                    branch: "Mata",
                  },
                  wrap: { name: "Nama", preserve: "Simpan" },
                },
              },
              index: {
                complete: "Kandungan lengkap",
                keyHit: "Hit mata",
                accurateFollowupReading: "Bacaan yang tepat",
                languageNorm: "Spesifikasi bahasa",
                speechSpeed: "Kelajuan bercakap",
                modalParticles: "Perkataan gas",
                emotionallyPositive: "Emosi positif",
                scoringModel: "Model pemarkahan",
                successfullyModified: "Pengubahsuaian berjaya",
                newSuccessfullyCreated: "Berjaya dibina",
              },
            },
            index: {
              scoringModel: "Model pemarkahan",
              newModel: "Model baru",
              model: "Model",
              type: "Jenis",
              operation: "Operasi",
              preview: "Pratonton",
              edit: "Edit",
            },
          },
          index: {
            scoringModel: "Model pemarkahan",
            ratingDimension: "Dimensi pemarkahan",
            sessionDetectionSettings: "Tetapan pengesanan sesi",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Sila pilih",
                hit: "Hit",
                misses: "Tidak berjaya",
              },
            },
          },
          index: {
            duration: "Tempoh",
            processScore: "Skor proses",
            branch: "Mata",
            contentCompleteness: "Integriti kandungan",
            scriptScore: "Skor perkataan",
            keyHitRate: "Titik hit",
            emotionalScore: "Skor emosi",
            negativeProportionOfEmotions: "Emosi negatif",
            expressionScore: "Skor ekspresi",
            theProportionOfAbnormalSpeechSpeed:
              "Kadar pertuturan yang tidak normal",
            excessiveProportionOfModalParticles: "Terlalu banyak perkataan gas",
            learningRecordData: "Belajar untuk merakam data",
            staff: "Pekerja",
            curriculum: "Kursus",
            mainPoints: "Perkara utama",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Borang",
                        lineChart: "Carta garis",
                        barChart: "Histogram",
                        pieChart: "Gambar pai",
                      },
                      index: { switchingTypes: "Tukar jenis" },
                    },
                  },
                  index: {
                    saveSuccessful: "Simpan dengan jayanya",
                    editChart: "Edit Carta",
                    chartName: "Nama carta",
                    deleteSuccessful: "Penghapusan berjaya",
                    refresh: "Segarkan semula",
                    export: "Eksport",
                    areYouSureToDeleteIt:
                      "Adakah anda pasti akan menghapusnya?",
                    delete: "Padamkan",
                    viewFilteringCriteria: "Lihat penapis",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Eksport berjaya, sila pergi ke pusat tugas untuk melihat",
              },
            },
            header: {
              index: {
                saveSuccessful: "Simpan dengan jayanya",
                deleteSuccessful: "Penghapusan berjaya",
                editReport: "Edit laporan",
                reportName: "Nama laporan",
                refresh: "Segarkan semula",
                areYouSureToDeleteIt: "Adakah anda pasti akan menghapusnya?",
                delete: "Padamkan",
                exportToExcel: "Eksport Excel",
                exportSnapshot: "Gambar eksport",
                export: "Eksport",
                authority: "Kebenaran",
                permissionSettings: "Tetapan kebenaran",
                reportVisiblePermissions:
                  "Kebenaran laporan yang dapat dilihat",
                visibleToEveryone: "Semua boleh dilihat",
                onlyVisibleToOneself: "Hanya kelihatan sendiri",
                designatedPersonnelVisible:
                  "Kakitangan yang ditentukan dapat dilihat",
                pleaseSelect: "Sila pilih",
                reportDataPermissions: "Kebenaran data laporan",
                restrictedByUserPermissions: "Terhad kepada kebenaran pengguna",
                visiblePeopleAreTheSame:
                  "Dapat dilihat bahawa orang adalah sama",
              },
            },
            titleFooter: {
              index: {
                addReport: "Tambah laporan",
                reportName: "Nama laporan",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Eksport berjaya, sila pergi ke pusat tugas untuk melihat",
            dataQueryInProgress: "Pertanyaan data...",
            customKanban: "Kanban tersuai",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Borang",
                lineChart: "Carta garis",
                barChart: "Histogram",
                pieChart: "Gambar pai",
              },
              index: {
                successfullyAdded: "Tambah kejayaan",
                viewSQL: "Lihat sql",
                export: "Eksport",
                addToReport: "Tambah ke laporan",
              },
            },
            refer: {
              hooks: {
                data: "Data",
                interrogativeSentence: "Soalan",
                historicalQuery: "Pertanyaan sejarah",
              },
              index: { copySuccessful: "Salin berjaya", copy: "Salin" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Tidak sesuai",
                        meetingStandards: "Mencapai standard",
                        excellent: "Cemerlang",
                        notStartedYet: "Tidak bermula",
                        inProgress: "Dalam proses",
                        completed: "Selesai",
                        incomplete: "Belum selesai",
                      },
                    },
                  },
                  const: {
                    taskName: "Nama tugas",
                    department: "Jabatan",
                    studentName: "Nama pelajar",
                    scoringResults: "Keputusan skor",
                    taskCompletionStatus: "Status penyelesaian tugas",
                  },
                  index: { pleaseSelectADimension: "Sila pilih dimensi" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Masukkan @ untuk membuka kotak peluru untuk pemilihan dimensi",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Sila masukkan pernyataan pertanyaan untuk membuat pertanyaan secara langsung",
                query: "Pertanyaan",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Eksport berjaya, sila pergi ke pusat tugas untuk melihat",
            dataQueryInProgress: "Pertanyaan data...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Carian kata kunci",
                previous: "Sebelumnya",
                next: "Seterusnya",
                theObjectThatTriggersTheScrollingEventIs:
                  "Objek yang mencetuskan peristiwa bergulir adalah",
                userScrolling: "Tatal pengguna",
              },
            },
            summary: {
              index: {
                reminder: "Peringatan:",
                edit: "Edit",
                name: "Nama",
                hintLanguage: "Peringatan",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Pengekstrakan pendapat" },
            },
            outline: {
              index: {
                numberOfKeywords: "Bilangan kata kunci",
                speaker: "Penceramah",
                speechDuration: "Tempoh bercakap",
                proportionOfSpeaking: "Bercakap",
                maximumSpeechDuration: "Masa bercakap terpanjang",
                numberOfQuestions: "Bilangan soalan",
                averageSpeechSpeed: "Purata kadar pertuturan",
                conversationalActions: "Tindakan sesi",
                contentSummary: "Ringkasan kandungan",
                generateSummary: "Menjana ringkasan",
                keywordCloud: "Kata kunci awan kata kunci",
              },
            },
          },
          index: {
            outline: "Ringkasan",
            viewpoint: "Pandangan",
            contentSummary: "Ringkasan kandungan",
            viewpointExtraction: "Pengekstrakan pendapat",
            summaryOfKeyPoints: "Ringkasan perkara utama",
            videoAnalysis: "Analisis Video",
          },
        },
        list: {
          index: {
            delete: "Padamkan",
            uploadSuccessful: "Muat naik berjaya",
            videoList: "Senarai video",
            uploadVideo: "Muat naik video",
            videoName: "Nama video",
            uploadTime: "Masa muat naik",
            endTime: "Masa tamat",
            analyzeProgress: "Kemajuan analisis",
            haveInHand: "Dalam proses",
            completed: "Selesai",
            fail: "Kegagalan",
            toBegin: "Tunggu untuk memulakan",
            operation: "Operasi",
            see: "Lihat",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Tambah masa kerja" },
        },
      },
      index: {
        businessWorkingHours: "Waktu kerja perniagaan",
        newlyBuild: "Baru",
        name: "Nama",
        describe: "Penerangan",
        operation: "Operasi",
        edit: "Edit",
        delete: "Padamkan",
        editBusinessWorkingHours: "Edit waktu kerja perniagaan",
        newBusinessWorkingHours: "Waktu kerja perniagaan baru",
        workingHours: "Masa kerja",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Pengekstrakan pintar",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Adakah anda mengesahkan bahawa anda mahu membatalkan? Tidak dapat dipulihkan selepas pembatalan",
        },
      },
    },
    accountManagement: {
      securitySetting: "Tetapan Keselamatan",
      accountManagement: "Pengurusan Akaun",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Ke",
            switchToIPAddressMode: "Beralih ke mod alamat IP",
            switchToIPSegmentMode: "Beralih ke mod segmen IP",
          },
        },
      },
      index: {
        saveSuccessful: "Simpan dengan jayanya",
        pleaseImproveTheConfiguration: "Sila lengkapi konfigurasi",
        securitySetting: "Tetapan Keselamatan",
        employeeLoginIPRestrictions: "Sekatan IP pendaratan pekerja",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Hadkan skop IP yang boleh dilog masuk oleh pekerja, dan log masuk tidak dibenarkan di luar julat. Hanya laman web yang berkesan, aplikasi mudah alih tidak terhad, dan pentadbir tidak terhad.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Semasa menambah atau mengubah suai, sila isi segmen IP rangkaian luaran (segmen C yang sama) atau alamat IP rangkaian luaran tetap.",
        allowLoginIPRange: "Julat IP yang dibenarkan masuk",
        preserve: "Simpan",
      },
    },
    coachAdminEntry: { speechRecognition: "Pengiktirafan pertuturan" },
    billingCenter: {
      template: {
        details: "Butiran",
        languageType: "Jenis Bahasa",
        aSREngine: "Enjin ASR",
        voiceQualityQuota: "Kuota pemeriksaan kualiti suara",
        realtimeData: "Data masa nyata",
        calculatedByDay: "Dikira mengikut hari",
      },
      component: {
        aSRName: "Nama ASR",
        language: "Bahasa",
        remainingQuota: "Kuota baki",
        hours: "Jam",
        usedQuota: "Kuota yang digunakan",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Operasi berjaya disimpan!",
        deletedSuccessfully: "Penghapusan berjaya!",
        pleaseSelectLanguage: "Sila pilih bahasa",
        pleaseConfigureRules: "Sila konfigurasikan peraturan",
        updateSuccessful: "Kemas kini berjaya!",
        operationSuccessful: "Operasi berjaya!",
      },
      template: {
        aSRLanguage: "Bahasa ASR",
        pleaseSelect: "Sila pilih",
        model: "Model",
        pleaseSelectLanguage: "Sila pilih bahasa",
        delete: "Padamkan",
        newASRModel: "Model ASR baru",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Analisis produk yang kompetitif" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Penapis ulasan e-dagang",
        allComments: "Semua komen",
        ecommerceReviewManagement: "Pengurusan ulasan e-dagang",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Penapis komen media sosial",
        allComments: "Semua komen",
        socialMediaReviewManagement: "Pengurusan komen media sosial",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Analisis produk yang kompetitif",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Nama",
        nickname: "Nama Panggilan",
        name: "Nama",
        email: "Akaun (e-mel)",
        mobile: "Telefon",
        agentId: "Nombor kerja",
        position: "Jawatan",
        role: "Peranan",
        actions: "Operasi",
        createUser: "Pekerja baru",
        editUser: "Kakitangan editor",
        account: "Akaun",
        originPassword: "Kata laluan awal",
        newPassword: "Kata laluan baru",
        resumePassword: "Sahkan kata laluan",
        userGroup: "Kumpulan kakitangan",
        authorizationGroup: "Kumpulan kebenaran",
        resetPassword: "Tetapkan semula kata laluan",
      },
      userGroups: {
        id: "ID",
        name: "Nama",
        userCount: "Bilangan pekerja",
        actions: "Operasi",
        createUserGroup: "Kumpulan pekerja baru",
        editUserGroup: "Kumpulan kakitangan editorial",
        member: "Ahli",
      },
      role: {
        id: "ID",
        name: "Nama",
        actions: "Operasi",
        all: "Semua",
        taskName: "Misi",
        readTask: "Paparan tugas",
        manageTemplate: "Pengurusan Templat",
        manualInspection: "Pemeriksaan semula manual",
        readResultAll: "Paparan Keputusan",
        readResultPersonal: "Paparan hasil (peribadi)",
        readProcess: "Paparan proses pemeriksaan kualiti",
        readReport: "Statistik dan laporan",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Tarikh",
        taskName: "Nama",
        size: "Saiz",
        status: "Status",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Nama",
            remark: "Penerangan",
            actions: "Operasi",
          },
          checkPoint: {
            name: "Nama",
            remark: "Penerangan",
            type: "Jenis",
            gradeType: "Kaedah penilaian",
            conditionLogic: "Peraturan",
            predeterminedScore: "Skor",
            weight: "Berat badan",
            status: "Status",
            actions: "Operasi",
            switchOn: "Aktifkan",
            switchOff: "Nyahaktifkan",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Masa",
            customer: "Pelanggan",
            customerPhone: "Telefon pelanggan",
            callType: "Jenis Panggilan",
            source: "Sumber",
            voiceRecording: "Rakaman panggilan",
            graded: "Penilaian",
            openingGreeting: "Ucapan pembukaan",
          },
          checkPointList: {
            name: "Titik pemeriksaan kualiti",
            type: "Jenis",
            deductScore: "Pecahan",
            isHit: "Keadaan hit",
            status: "Kaji semula",
            finalDeductScore: "Sahkan pemotongan",
            hit: "Hit",
            notHit: "Tidak berjaya",
          },
        },
      },
    },
    custom: { keywords: "Kata kunci dialog" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Tetapan sistem" },
      systemIntegration: {
        text: "Integrasi sistem",
        subTitle: {
          udesk: "Sistem perkhidmatan pelanggan Udesk",
          udeskWhite: "Sistem Perkhidmatan Pelanggan",
          wechat: "WeChat Korporat",
          taobao: "Taobao Qianniu",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Pengurusan bidang pemeriksaan kualiti" },
      customerField: { text: "Bidang pelanggan" },
      speechRecognition: { text: "Pengiktirafan pertuturan" },
      user: { text: "Pengurusan pekerja" },
      userGroups: { text: "Pengurusan Kumpulan Kakitangan" },
      role: { text: "Pengurusan peranan" },
      dataMigration: { text: "Penghijrahan data" },
      taskCenter: { text: "Pusat Misi" },
      recordUpload: { text: "Muat naik rakaman tempatan" },
      anomalousData: { text: "Data tidak normal" },
      systemLog: { text: "Log mesej" },
      msgCenter: { text: "Pusat Mesej" },
      wechatDocking: { text: "Pemasangan perusahaan dan mikro" },
    },
    tasks: {
      homePage: { text: "Rumah" },
      workbench: { text: "Meja kerja" },
      semanticIntelligence: { text: "Kecerdasan Semantik" },
      intelligentAnalysis: { text: "Analisis pintar" },
      intelligentLabel: { text: "Label pintar" },
      admin: { text: "Pengurusan" },
      callManage: { text: "Pengurusan Panggilan" },
      dialogueManage: { text: "Pengurusan perbualan" },
      ticketManage: { text: "Pengurusan pesanan kerja" },
      dataAnalysis: { text: "Analisis data" },
      customerService: { text: "Pusat Khidmat Pelanggan" },
      clientCenter: { text: "Pusat Pelanggan" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Pusat Latihan" },
      templates: { text: "Pengurusan Templat" },
      wechatQaTasks: { text: "Meja Kerja Enterprise WeChat" },
      wechatQaSetting: { text: "Konfigurasi analisis WeChat perusahaan" },
      wechatQaChartRecord: { text: "Perbualan WeChat Korporat" },
      wechatStaffCenter: { text: "Pusat Kakitangan Perusahaan dan Mikro" },
      callAnalysis: { text: "Analisis Sesi" },
      caseBaseFolder: { text: "Perpustakaan kes" },
      coachTask: { text: "Tugas kaunseling" },
      teamAnalysis: { text: "Analisis pasukan" },
      customer: { text: "Pusat Pelanggan" },
      analysisEnable: { text: "Pemerkasaan analisis" },
      analysisHelp: { text: "Analisis bantuan" },
      analysisStudy: { text: "Analisis pembelajaran" },
      analysisReview: { text: "Analisis semula" },
      analysisTags: { text: "Analisis Label Pintar" },
      analysisSentiment: { text: "Analisis pendapat umum" },
      analysisBusiness: { text: "Analisis perniagaan" },
      groupTest: { text: "Ujian kumpulan" },
    },
    navBar: {
      billingCenter: "Pusat Bantuan",
      uploadDownload: "Muat naik pusat muat turun",
      setting: "Tetapan sistem",
    },
  },
  fix: {
    selectType: "Pilih jenis:",
    all: "Semua",
    call: "Panggilan",
    text: "Dialog",
    wechat: "Perbualan WeChat Korporat",
    businessTrendAnalysis:
      "Analisis trend perniagaan klasifikasi tahap pertama",
    noEchart: "Tiada data pada carta",
    checkPointisRequired: "Konfigurasi peraturan diperlukan",
    voiceDownLoad: "Muat turun rakaman",
    exportTxt: "Eksport teks TXT",
    exportWord: "Eksport teks perkataan",
    sureReRole: "Adakah anda mengesahkan pembalikan peranan pembicara?",
    sureRecover: "Sahkan pembetulan semula?",
    sureReAsr: "Sahkan pengiktirafan semula?",
    reRole: "Pembalikan pembicara",
    recover: "Pembetulan semula",
    reAsr: "Pengiktirafan semula",
    recordingOperation: "Operasi rakaman",
    onlySave30Day: "(Hanya tinggal selama 30 hari)",
    appealError: "Sila buat rayuan ke pusat pemeriksaan sebelum menghantar",
    exportToTaskCenter:
      "Telah berjaya dieksport ke pusat muat naik dan muat turun",
    caseBaseLimit: "Perpustakaan kes tidak boleh melebihi 50",
    unnamed: "Tidak dinamakan",
    wordSpeed: "Perkataan/mata",
    hit: "Hit",
    checkPoint: "Titik pemeriksaan kualiti",
    realTimeAdditionTip1:
      "Penambahan masa nyata: pemeriksaan kualiti bermula dengan segera apabila data memasuki sistem pemeriksaan kualiti",
    realTimeAdditionTip2:
      "Penambahan masa: masa secara automatik menambah data untuk pemeriksaan kualiti",
    null: "Tiada",
    collcetedProps: "Medan pemerolehan",
    editingNameNow: "Nama sedang diedit",
    upToFourLevels: "Hingga empat tahap klasifikasi",
    default: "Lalai",
    nameCantBeNull: "Nama tidak boleh kosong",
    editingNow: "Operasi sedang diedit",
    nameError: "Nama itu salah",
    nameMaxLength8: "Panjang maksimum nama ialah 8",
    callTime: "Masa bercakap",
    sentimentValue: "Nilai emosi pelanggan",
    agentSentimentValue: "Nilai mood perkhidmatan pelanggan",
    sentimentAnalysis: "Analisis Emosi",
    associatedData: "Data dalam perjalanan",
    notifyTheOwner: "Beritahu pemilik kumpulan",
    notifyTheEmployee: "Maklumkan kepada pekerja itu sendiri",
    notifyTheOwnerTip:
      "Apabila jenis sesi adalah sembang kumpulan, pemilik kumpulan akan diberitahu",
    customNotifyEmployee: "Custom memberitahu pekerja",
    customNotifyEmployeeTip:
      "Sekiranya orang yang mencetuskan adalah pekerja, pekerja yang menjadi milik mesej akan diberitahu",
    clickToLearnMore: "Klik untuk mengetahui lebih lanjut",
    repeatMarkingTip:
      "Rekod ini telah ditandakan oleh titik pemeriksaan kualiti, tidak perlu mengulang label",
    successfulEvaluation: "Penilaian kejayaan",
    evaluationFailed: "Penilaian gagal",
    qualityInspectionScore: "Skor pemeriksaan kualiti",
    semanticTags: "Label semantik",
    smartTags: "Label pintar",
    collect: "Pengumpulan maklumat",
    appealRecord: "Rekod rayuan",
    satisfy: "Berpuas hati",
    notSatisfy: "Tidak berpuas hati",
    appealPlaceHolder: "Sila masukkan alasan rayuan",
    giveUp: "Menyerah",
    rejectReasonPlaceHolder: "Sila masukkan alasan penolakan",
    reject: "Tolak",
    changeRejectReason: "Ubah sebab penolakan",
    pass: "Melalui",
    revise: "Ubah suai",
    machineEvaluation: "Penilaian Mesin",
    poor: "Buruk",
    excellent: "Baik",
    moderate: "Am",
    mustMarked: "Titik pemeriksaan mesti ditandakan",
    pleaseChoose: "Sila pilih",
    manualEvaluation: "Penilaian manual",
    qualityInspectionTemplate: "Templat pemeriksaan kualiti yang berkenaan",
    collectionTemplate: "Templat pengumpulan yang berkenaan",
    keywordsWordCloud: "Kata kunci awan kata kunci",
    editCategoryLabel: "Edit tag kategori",
    justCall: "Lihat sahaja panggilan",
    justConversation: "Lihat sahaja perbualan",
    batchOperation: "Operasi kumpulan",
    customerServicesGroup: "Kumpulan Perkhidmatan Pelanggan",
    joinGroup: "Sertai kumpulan",
    removeGroup: "Keluar dari kumpulan",
    enterInformationEntity: "Sila masukkan kandungan entiti maklumat",
    enterNotBeNull: "Input tidak boleh kosong",
    operationGroup: "Kumpulan operasi",
    customerServiceSelected: "Perkhidmatan pelanggan terpilih",
    callAndConversation: "Panggilan/perbualan",
    conversation: "Dialog",
    system: "Sistem",
    customize: "Adat",
    effective: "Berkesan",
    invalid: "Tidak sah",
    successfulAndToTaskCenter:
      "Sekiranya pelaksanaannya berjaya, sila pergi ke pusat tugas untuk melihat",
    recalculationFailed: "Gagal mengira semula",
    selectRecalculatedIndicator: "Sila pilih petunjuk yang akan dikira semula",
    source: "Sila pilih sumber data",
    placeholder: "Sila masukkan kata kunci",
    cumulative: "Jenis kumulatif",
    average: "Jenis purata",
    distribution: "Undang-undang pengedaran",
    originalDataCannotEmpty: "Data asal tidak boleh kosong",
    inspection: "Pemeriksaan kualiti",
    check: "Persampelan",
    review: "Kaji semula",
    draftSavedSuccessfully: "Draf berjaya disimpan",
    optimization: "Pengoptimuman",
    agentName: "Nama perkhidmatan pelanggan",
    allTasks: "Semua tugas pemeriksaan kualiti",
    datePickerSelect: "Pilih tarikh",
    callSelect: "Pilih perbualan",
    taskSelect: "Pilih tugas",
    genReport: "Menjana laporan",
    dataSource: "Sumber data",
    callsTotal: "Jumlah panggilan",
    intelligentQualityInspection: "Pemeriksaan kualiti pintar",
    percentage: "Peratusan",
    artificialSampling: "Pemeriksaan manual",
    appealNumber: "Keluhan",
    totalConversation: "Jumlah perbualan",
    samplingReviewRate: "Kadar pemeriksaan rawak",
    data: "Data",
    inspectionResults: "Hasil pemeriksaan kualiti",
    inspected: "Pemeriksaan kualiti",
    totalScore: "Jumlah markah",
    learnMore: "Lihat lagi",
    inspectionEffect: "Kesan pemeriksaan kualiti",
    allTotal: "Jumlah",
    manualInspection: "Pemeriksaan semula manual",
    artificialReview: "Kajian manual",
    approvalAmountAndRate: "Jumlah kelulusan & kadar kelulusan",
    customerScore: "Skor perkhidmatan pelanggan",
    inspectionRecommendations: "Cadangan pemeriksaan kualiti",
    saveDraft: "Simpan draf",
    historicalAdvice: "Nasihat sejarah",
    advicePlaceHolder: "Sila masukkan cadangan...",
    upload: "Muat naik fail",
    customAdd: "Tambah penapis",
    advancedConditions: "Keadaan lanjutan",
    keyWord: "Kata kunci",
    containsAll: "Mengandungi semua",
    containsAny: "Mengandungi sewenang-wenangnya",
    matchAny: "Padankan mana-mana",
    matchAll: "Padankan semua",
    smartTagsOne: "Label pintar satu",
    smartTagsTwo: "Label pintar dua",
    season: "Musim",
    month: "Bulan",
    week: "Minggu",
    day: "Hari",
    hour: "Masa",
    notMoreThan366: "Masa tidak boleh melebihi 366 hari",
    propsCannotBeNull: "Terdapat medan yang tidak diisi",
    other: "Lain-lain",
    correlationCoefficient: "Pekali korelasi",
    repetitionRate: "Kadar pengulangan",
    configurationItemsMoreThan20: "Hingga 20 item konfigurasi ditambahkan",
    conversionAnalysis: "Analisis penukaran",
    correlationAnalysis: "Analisis korelasi",
    successfulExportAndToTaskCenter:
      "Eksport berjaya, sila pergi ke pusat tugas untuk melihat",
    typeSelect: "Pilih jenis",
    exportExcel: "Eksport excel",
    exportPng: "Eksport png",
    noDataChart: "Tiada data pada carta",
    placeOperator: "Sila pilih label pintar",
    placeAdvancedConditions: "Sila pilih syarat lanjutan",
    createAdvancedConditions: "Penapis lanjutan baru",
    moveUp: "Gerakkan ke atas",
    moveDown: "Bergerak ke bawah",
    moveLeft: "Gerakkan ke kiri",
    moveRight: "Gerakkan ke kanan",
    statusChangeSuccess: "Pengubahsuaian status berjaya",
    editSuccess: "Pengubahsuaian berjaya",
    saveSuccess: "Simpan dengan jayanya",
    firstPage: "Rumah",
    lastPage: "Halaman terakhir",
    customerInfo: "Maklumat pelanggan",
    type: "Jenis",
    NumberTypeErrorMessage:
      'Kandungan maklumat medan jenis nombor hanya boleh mengandungi nombor atau "."',
    taskStartPrompt: "Petua pembukaan tugas",
    startConfirm:
      "Sila klik butang sahkan untuk memulakan tugas pemeriksaan kualiti",
    continueToSubmitWithoutEvaluation: "Terus hantar tanpa penilaian",
    rejectTip:
      "Operasi perubahan tidak dikesan. Adakah anda mengesahkan bahawa semua aduan ditolak secara langsung?",
    unmarkedSelfLearningTip:
      "Anda mempunyai titik pemeriksaan kualiti pembelajaran kendiri yang tidak bertanda",
    markedSuccessfully: "Berjaya ditandakan",
    markingFailed: "Gagal menandakan",
    exceedingLength: "Di luar panjang",
    uncategorized: "Tidak dikategorikan",
    addSuccess: "Menambah kejayaan",
    deleteSuccess: "Penghapusan berjaya",
    featureName: "Nama elemen",
    informationEntity: "Entiti Maklumat",
    standardAnswer: "Jawapan standard",
    keyElementAnswer: "Elemen Utama Jawapan",
    generalStatement: "Kenyataan umum (tidak perlu bertanya)",
    generalQuestionsAndConfirmation: "Soalan dan pengesahan umum",
    specialQuestionsAndAnswers: "Soalan dan jawapan khas",
    agent: "Perkhidmatan Pelanggan",
    customer: "Pelanggan",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Label semantik elemen utama dan entiti maklumat tidak boleh kosong pada masa yang sama",
    standardSentence: "Ayat standard",
    acquaintance: "Kenalan",
    smartUePlaceholder: "Sila masukkan nama interaksi pintar",
    typeSelectPlaceholder: "Sila pilih jenis",
    keyElement: "Elemen Utama",
    reQuerySementicTags: "Pilih semula label semantik",
    querySementicTags: "Pilih label semantik",
    QATestForm: "Format ujian Soal Jawab",
    selectNotBeNull: "Pilihan tidak boleh kosong",
    featureDetectionTarget: "Sasaran pengesanan elemen",
    categorized: "Pengelasan",
    effectiveness: "Keberkesanan",
    hideAdvancedSettings: "Sembunyikan tetapan lanjutan",
    showAdvancedSettings: "Tunjukkan tetapan lanjutan",
    generalStatementOnly: "Kenyataan umum",
    generalQuestions: "Soalan am",
    affirmativeExpression: "Ungkapan pasti",
    negativeIntention: "Niat negatif",
    searchSementicTags: "Pertanyaan tag semantik",
    choiceQuestion: "Soalan pilihan",
    selectiveAnswer: "Jawapan pilihan",
    sementicTagsPlaceholder: "Sila masukkan kandungan label semantik",
    openSpecialQuestion: "Soalan khas terbuka",
    openAnswer: "Jawapan terbuka",
    customQA: "Soalan dan jawapan tersuai",
    questions: "Soalan",
    dataPlaceholder: "Sila masukkan kandungan",
    reply: "Jawapan",
    notFindSuitableLabel: "Tidak menemui label yang sesuai",
    addSemanticTag: "Tambah label semantik baru",
    intelligentInteraction: "Interaksi pintar",
    matching: "Perlawanan",
    bout: "Kali",
    keywordMatch: "Padanan kata kunci",
    addFilterConditions: "Tambah penapis",
    buttonStartCollectionTask:
      "Sila klik butang OK untuk membuka tugas pengumpulan",
    buttonStartTask:
      "Sila klik butang OK untuk memulakan tugas pemeriksaan kualiti",
    ruleConfigurationIsRequired: "Konfigurasi peraturan diperlukan",
    asrRunning: "Pelaksanaan ASR",
    errorCorrectionExecution: "Pelaksanaan pembetulan ralat",
    emotionalVolumeRecognition: "Pengiktirafan kelantangan emosi",
    dataPreprocessing: "Pra pemprosesan data",
    inspectionExecution: "Pelaksanaan pemeriksaan kualiti",
    businessId: "Perniagaan Is",
    businessTime: "Masa perniagaan",
    createTime: "Buat masa",
    abnormalReason: "Punca yang tidak normal",
    singleChat: "Sembang tunggal",
    groupChat: "Sembang kumpulan",
    taskContent: "Kandungan tugas",
    submissionTime: "Masa penghantaran",
    startTime: "Masa mula",
    endTime: "Masa tamat",
    runningTime: "Operasi memakan masa",
    totalAmountOfData: "Jumlah data",
    resultsOfThe: "Hasil pelaksanaan",
    operator: "Pengendali",
    taskDetails: "Perincian tugas",
    executiveFunction: "Fungsi pelaksanaan",
    qualityCheckTasks: "Tugas pemeriksaan kualiti",
    implementationDetails: "Butiran pelaksanaan",
    conditionalRules: "Peraturan keadaan",
    smartCondition: "Keadaan pintar",
    goThis: "Bergerak ke sini",
    pleaseMarkClassification: "Sila tandakan kategori",
    advancedScreening: "Penapis lanjutan",
    noDataToManipulate: "Tiada data yang boleh dikendalikan",
    concept: "Perkataan konsep",
    checkPoint1: "Konfigurasi peraturan",
    requiredFieldsCannotEmpty: "Item yang diperlukan tidak boleh kosong",
    rule: "Peraturan",
    afterFixUnit: "Perkataan",
    unmarkedSelfLearningPointsTip:
      "Anda mempunyai titik pemeriksaan kualiti pembelajaran kendiri yang tidak bertanda",
    onlyReservedFor7Days: "(Hanya 7 hari)",
    downloadError: "Muat turun gagal",
    pause: "Penggantungan",
    continue: "Teruskan",
    recalcScore: "Hanya mengira semula skor (termasuk penilaian)",
    recalculatePointsAndScores:
      "Kira semula titik dan skor pemeriksaan kualiti",
    recalculatePointsAndScoresWithEmtry:
      "Kira semula titik dan skor pemeriksaan kualiti (titik pemeriksaan kualiti kosong)",
    inProgress: "Dalam proses",
    toBeExecuted: "Untuk dilaksanakan",
    executeImmediately: "Laksanakan sekarang",
    specialElements: "Elemen Khas",
    logicRule: "Peraturan logik",
    every: "Pelbagai perlawanan",
    hitRecall: "Kata ingat hit",
    notHitRecall: "Kata-kata penarikan balik yang tidak terpukul",
    hitRecallRegular: "Ingat semula biasa",
    notHitRecallRegular: "Tidak berjaya",
    hitRule: "Peraturan hit",
    notHitRule: "Peraturan yang tidak berjaya",
    hitSimilarSentence: "Hit ayat yang serupa",
    notHitSimilarSentence: "Kalimat serupa yang tidak berjaya",
    hitExcludeSimilarSentences: "Hit untuk mengecualikan ayat yang serupa",
    notHitExcludeSimilarSentences: "Tidak terlepas ayat yang serupa",
    updateSuccess: "Kemas kini berjaya",
    qualify: "Biasa",
    recallKeyWord: "Ingat kata kunci",
    grammar: "Peraturan tatabahasa",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Perubahan keberkesanan gagal, sila cuba lagi",
    postAutoMateTasksCaseByTaskId:
      "Penyimpanan konfigurasi penyegerakan perpustakaan kes gagal",
    getAutoMateTasksCaseByTaskId:
      "Gagal konfigurasi penyegerakan perpustakaan kes",
    postInspectionRemarkModelConfigs: "Templat komen gagal disimpan",
    putInspectionRemarkModelConfigsById: "Templat komen gagal disimpan",
    getInspectionRemarkModelConfigsById: "Gagal mendapatkan data templat komen",
    postKnowledgeDatas: "Pangkalan pengetahuan gagal dibina, sila cuba lagi",
    putKnowledgeDatasById:
      "Pangkalan pengetahuan gagal diubah suai, sila cuba lagi",
    getLibraryFiles: "Gagal mendapatkan senarai folder harga",
    getLibraryCalls: "Gagal mendapatkan fail",
    getLibraryFilesSearch: "Carian gagal",
    putLibraryFilesMoveById: "Gagal memindahkan folder harga",
    putLibraryCallsById: "Nama fail gagal!",
    postLibraryFiles: "Gagal menambah folder",
    putLibraryFilesById: "Kegagalan menamakan semula folder",
    deleteLibraryFilesById: "Gagal memadam",
    deleteLibraryCallsById: "Gagal memadam",
    postLibraryCalls: "Gagal menambah",
    getVoiceUploadResultById: "Eksport hasil muat naik gagal",
  },
};
