import React from 'react';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from "react-redux";
// Avoid importing `udesk` of this project! Import `toolkits` instead.
import ToolkitsUdesk from 'udesk-web-toolkits/src/udesk';
import EventedClass from 'udesk-web-toolkits/src/udesk/evented-class';
import ToolkitLocalesManager from 'udesk-web-toolkits/src/udesk/locales/locales-manager';
import UdeskLocales from '../locales';
import enums from '../enums';
import { STATIC_REDUX_KEY_SYMBOL, INSTANCE_REDUX_KEY_SYMBOL } from "./redux/symbols";
import ACTION_BEHAVIOR_DESCRIPTORS from './action-behaviors';
import LoadingAnimationComponent from '../../components/loading-animation';
import Context, { UdeskContext } from './context';
import { get, set } from 'udesk-web-toolkits/src/udesk/utils/object';
import { unique } from 'udesk-web-toolkits/src/udesk/utils/array';
import {
    registerInstanceReducers, registerGlobalReducers,
    destroyReduxState, mapStateToProps,
    mapDispatchToProps, mergeProps,
    getInternalSystemReducers, SYSTEM_REDUCER_SAVE_STATES,
    SAVED_STATES_REDUX_STORE_FIELD_NAME, getState
} from "./redux/reducer";

const SKIP_PROPERTY_NAMES = ["length", "name", "prototype", "caller", "arguments", "defaultProps", "propTypes"];
const UDESKIFIED_FLAG_SYMBOL = Symbol("[Udeskified]");
const OWNER_SYMBOL = Symbol("[$INTERNAL_USE_ONLY$_YOU_SHOULD_NEVER_READ_OR_WRITE_TO_IT]");
const IS_INITIAL_SYMBOL = Symbol("[IsInitial]");
const IS_SET_STATE_CALLING_SYMBOL = Symbol("[IsSetStateCalling]");
const PENDING_UPDATERS_SYMBOL = Symbol("[PendingUpdaters]");
const IS_MODEL_RELOADING_SYMBOL = Symbol("[IsModelReloading]");
const MODEL_RELOADING_ARGS_SYMBOL = Symbol("[ModelReloadingArgs]");
const CANCEL_ACTION_SYMBOL = Symbol("[CancelAction]");
const TRIGGER_EVENT_SYMBOL = Symbol("[TriggerEvent]");
const COMPUTE_DEPEND_KEYS_SYMBOL = Symbol("[ComputeDependKeys]");
const OBSERVER_DEPEND_KEYS_SYMBOL = Symbol("[ObserverDependKeys]");
const MEMOIZED_PRIVATES_KEYS_SYMBOL = Symbol("[MemoizedPrivatesKeys]");
const MEMOIZED_STATE_KEYS_SYMBOL = Symbol("[MemoizedStateKeys]");
const COMPUTES_EXECUTION_PLAN_SYMBOL = Symbol("[ComputesExecutionPlan]");
const OBSERVER_EXECUTION_PLAN_SYMBOL = Symbol("[ObserverExecutionPlan]");
const MEMORY_STORAGE_SYMBOL = Symbol("[MemoryStorage]");
const REDUX_CONNECT_HOC_SYMBOL = Symbol("[ReduxConnectHOC]");
const REDUX_ENABLED_SYMBOL = Symbol("[ReduxEnabled]");

const COMPATIBLE_REACT_VERSION = "16.3.0";
const STATIC_MERGEABLE_FIELDS = ["computes", "observers"];
const STATIC_CONCAT_FIELDS = [];
const INSTANCE_MERGEABLE_FIELDS = ["state", "privates", "actions"];
const INSTANCE_CONCAT_FIELDS = ["modelProps", "asyncModelProps"];
const STORAGE_WAY_TYPES = ["memory", "sessionStorage", "localStorage"];
let GlobalLifeCyclePatchConfig = null;
let StaticReduxKeyId = 1;
let ElementIdSeed = 0;

const COMPONENT_DEFAULT_PROPS = {
    language: undefined,
    rerenderOptimization: false,
    onCreated: undefined,
    onDestroyed: undefined,
    modelLoadingAnimation: true,
    modelLoadingAnimationProps: {
        align: "center",
        verticalAlign: "middle"
    },
    showModelError: false,
    modelErrorDisplayMode: enums.modelErrorDisplayMode.alert,
    customizeModelErrorTemplate: null,
    reduxKey: "",
    keepReduxState: undefined
};
const COMPONENT_PROP_TYPES = {
    language: PropTypes.string,
    rerenderOptimization: PropTypes.bool,
    onCreated: PropTypes.func,
    onDestroyed: PropTypes.func,
    modelLoadingAnimation: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.func,
    ]),
    modelLoadingAnimationProps: PropTypes.shape({
        align: PropTypes.oneOf(["left", "center", "right"]),
        verticalAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
    }),
    showModelError: PropTypes.bool,
    modelErrorDisplayMode: PropTypes.oneOf(Array.from(enums.modelErrorDisplayMode)),
    customizeModelErrorTemplate: PropTypes.func,
    reduxKey: PropTypes.string,
    keepReduxState: PropTypes.bool
};

export default udeskify;

export {
    udeskify,
    isUdeskified,
    attachActionBehaviors
};

// #region Component interface

/*
class UdeskComponent {
    props = {
        language: String(undefined),
        rerenderOptimization: Boolean(false),
        onCreated(instance) { },
        onDestroyed() { },
        modelLoadingAnimation: Boolean(true),
        modelLoadingAnimationProps: {
            align: String("center"),
            verticalAlign: String("middle")
        },
        showModelError: Boolean(true),
        modelErrorDisplayMode: enums.modelErrorDisplayMode.alert,
        reduxKey: string,
        customizeModelErrorTemplate: func(null),
    }
    state = {}
    SAVED_STATE_KEYS = []
    privates = {
        computes: Object({}),
        observers: Object({}),
        storages: Object({}),  // 默认用于页面状态存储的属性，打开enableStorage之后，该属性会自动被保存
        dirtyProps: {
            //dataId: true
        },
        model: Object(undefined),
        modelInitialized: Boolean(false),
        modelFulfilled: Boolean(false),
        modelResolved: Boolean(false),
        modelError: Object(null),
        modelErrorMsg: String(null),
        asyncModel: Object(undefined),
        asyncModelInitialized: Boolean(false),
        asyncModelFulfilled: Boolean(false),
        asyncModelResolved: Boolean(false),
        asyncModelError: Object(null),
        asyncModelErrorMsg: String(null),
    }

    elementId = String,
    domRef = ReactRef,
    // -- saved storage --
    enableStorage = Boolean(false)          // 是否开启页面状态存储机制，默认为false
    _storageKey: String,                    // 页面状态存储的key，格式为[Storages]:route:_storageKey（路由级组件）或者[Storages]:component:_storageKey（普通组件），如果不设置则使用默认的路由名称（路由级组件）或者组件名称（普通组件）作为_storageKey
    // 页面状态存储策略，配置方案1，最简单的策略
    storageStrategies = String              // 可以填写 memory/sessionStorage/localStorage，只会将privates.storages保存到对应的存储位置
    // 页面状态存储策略，配置方案2，推荐
    storageStrategies = {
        storageWay: String,                 // 存储位置，可以填写 memory/sessionStorage/localStorage
        extraStorages: [String],            // 除了privates.storages会自动保存之外，其他要保存的内容需要在此设置，目前仅支持前缀“state.”与“privates.”，例如“state.pageSize”
        resetStrategies: {                  // 页面状态清除策略，如果不配置，将不会进行主动清除操作，大多数情况只需设置其中一项。需要输入有效的url匹配正则表达式，例如/^\/site\/tasks\/manage\/\d+\/workbench\/spot-check-list\/detail\/\d+/i
            transitionToRoutes: [RegExp],   // 跳转到这些页面将会主动清除之前保存的页面状态
            notTransitionToRoutes:[RegExp], // 跳转到这些页面将不会主动清除之前保存的页面状态，注意一旦设置了该列表，就表示跳转其他任何页面均会清除之前保存的页面状态
        },
        // resetStrategies: function(targetUrl) {return boolean}, // 页面状态清除策略，也可以定制一个函数，返回结果true表示重置，该函数调用时this会绑定组件实例，参数为跳转目标url
        restoreStrategies: function(storages) {return boolean},   // 页面状态恢复策略，定制一个函数，返回结果true表示可以恢复数据，否则不进行恢复，该函数调用时this会绑定组件实例，参数为storage对象
    }
    // 页面状态存储策略，配置方案3，可以给每个属性单独配置存储位置，方案1的一个升级版
    storageStrategies = {
        "state.pageSize": "memory",
        startTime: "sessionStorage",        // 注意不指定前缀，则默认为privates.storages中的属性
        endTime: "sessionStorage",
        sampleStatus: "localStorage",
    }
    // 页面状态存储策略，配置方案4，可以给每个属性单独配置存储策略，方案2的一个升级版
    storageStrategies = {
        "state.pageSize": {
            storageWay: "sessionStorage",
            resetStrategies: {
                transitionToRoutes: [],
                notTransitionToRoutes: []
            },
            // resetStrategies: function(),
            restoreStrategies: function(),
        },
        sampleStatus: {                     // 注意不指定前缀，则默认为privates.storages中的属性
            storageWay: "sessionStorage",
            resetStrategies: {
                transitionToRoutes: [],
                notTransitionToRoutes: []
            },
            // resetStrategies: function(),
            restoreStrategies: function(),
        },
    }

    keepReduxState = Boolean(undefined)
    memoizedPrivates = {}
    memoizedStates = {}
    isPrivateEqual(name, value1, value2) { return value1 === value2; }
    isStateValueEqual(name, value1, value2) { return value1 === value2; }
    static computesUsedActions = [String("actionName1")]
    static observerUsedActions = [String("actionName2")]
    static computes = {
        value1: function ({ props, state, privates, locales }) {
            return {};
        },
        value2: ["prop1", "prop2", function ({ props, state, privates, locales }) {

        }]
    }
    static observers = {
        onValue1Changed: ["prop1", "prop2", function ({ props, state, privates, locales }) {
            // Do something...
        }]
    }

    // -- Life cycles --
    init() { }
    model() { }
    asyncModel() { }
    parseProps({props, prevProps, state, privates, isInitial}) { }
    getPrivatesFromProps({props, prevProps, state, privates, isInitial}) { }
    getStateFromProps({props, prevProps, state, privates, isInitial}) { }

    init() { }
    isPropEqual(name, value1, value2) { return value1 === value2; }
    model() { }
    parseModel(model) { }
    onModelResolved(model) { }
    onModelRejected(modelError) { }
    modelProps = [String("propName1")]
    clearModelOnReloading = Boolean(false)
    showModelReloadAnimation = Boolean(false)
    asyncModel() { }
    parseAsyncModel(asyncModel, {
        isMainPromise = Boolean(true),
        isFirstResolve = Boolean(true),
        isLastAsync = Boolean(true),
        asyncKey = String("key"),
        asyncResult = Object({})
    }) { }
    onAsyncModelResolved(asyncModel) { }
    onAsyncModelRejected(asyncModelError) { }
    autoLoad = Boolean(true)
    asyncModelProps = [String("propName1")]
    clearAsyncModelOnReloading = Boolean(false)

    on(eventName, callback) { }
    off(eventName = null) { }
    trigger(eventName, ...args) { }
    invoke(actionName, ...args) { }
    get locales() { }
    getLocale(key, languageCode) { }
    isDestroyed = Boolean(false)

    actions = {
        mutator(fieldName, parser = null, shouldForceUpdate) { },
        inputMutator(fieldName, parser = null, shouldForceUpdate) { },
        pendingState(updater) { },
        commitState() { },
        update() { },
        noop() { },
        reloadModel() { },
        reloadAsyncModel() { },
        promiseWaiting(promise) { },
        promiseResolved(result) { },
        promiseCatch(reason) { },
    }
}
 */

/*
    Share v3
    rerenderOptimization
    common 文件夹 -> udesk
 */

// #endregion

// #region 异步数据加载
let parentAsyncCase = false;
let childrenAsyncReloadListner = [];
// #endregion

//#region udeskify
function udeskify(componentClass, asyncCase = false) {
    if (typeof componentClass !== "function") {
        throw new Error(`The argument 'ComponentClass' of ${udeskify.name} must be class.`);
    }
    if (comparableVersion(React.version) < comparableVersion(COMPATIBLE_REACT_VERSION)) {
        throw new Error(`The React version doesn't satisfy the minimum requirements of the 'udeskify' module. The minimum compatible version is ${COMPATIBLE_REACT_VERSION}, however you have version ${React.version} installed. Please update your react to a newer version.`);
    }

    componentClass[MEMORY_STORAGE_SYMBOL] = {};
    class UdeskComponent extends componentClass {
        static displayName = (componentClass.displayName || componentClass.name || UdeskComponent.name);

        constructor(props) {
            super(...arguments);
            this["[DebugName]"] = componentClass.name || UdeskComponent.name;

            if (asyncCase) {
                this["parentAsyncCase"] = true;
                parentAsyncCase = true;
                childrenAsyncReloadListner = [];
            }
            if (!asyncCase && parentAsyncCase) { // 控制子组件刷新
                this["asyncCase"] = true;
                childrenAsyncReloadListner.push(() => {
                    if (typeof this.init === "function") {
                        this.init();
                    }
                    if (typeof this.parseProps === "function") {
                        const partialPrivates = this.parseProps({
                            props: this.props,
                            prevProps: this.props,
                            state: this.state,
                            privates: this.privates,
                            isInitial: false,
                        });
                        Object.assign(this.privates, partialPrivates);
                    }
                    this.componentDidMount();
                    if (this.isComMounted) {
                        this.forceUpdate();
                    }
                });
            }

            udeskifyInstance(this, props);
            if (typeof this.init === "function") {
                this.init();
            }

            backwardCompatibility(this, componentClass);
        }
    }

    // Extend some static methods
    extendClass(componentClass);

    let finalNode = React.forwardRef(function forwardRef(props, ref) {
        if (Context.UdeskConsumer == null) {
            throw new Error("Forgot to call `Udesk.init` before application starts?");
        }
        forwardRef.displayName = UdeskComponent.displayName;

        return <Context.UdeskConsumer>{
            udeskContext => {
                let newContextApiSupported = (comparableVersion(React.version) >= comparableVersion("16.6.0"));
                if (udeskContext == null && !newContextApiSupported) {
                    // If react version is less than 16.6.0, always ask for a context provider outside the whole application.
                    throw new Error(`[Anti Pattern] No context provider was found! Please always wrap everything inside Udesk.react.context.UdeskConsumer component, and pass an instance of Udesk.react.context.UdeskContext to \`value\` props.`);
                }
                if (udeskContext && !(udeskContext instanceof UdeskContext)) {
                    throw new Error(`The value passed to Udesk.react.context.UdeskConsumer component is invalid! It must be an instance of Udesk.react.context.UdeskContext.`);
                }

                if (ref == null) {
                    ref = React.createRef();
                }

                if (componentClass[REDUX_ENABLED_SYMBOL]) {
                    let ConnectedComponent = connectRedux(componentClass, UdeskComponent);
                    return <ReactReduxContext.Consumer>
                        {reduxContext =>
                            <ConnectedComponent {...((udeskContext || {}).props || {})} dispatch={reduxContext.store.dispatch} $UdeskContext$={udeskContext} {...props} ref={ref} />
                        }
                    </ReactReduxContext.Consumer>;
                }
                else {
                    return <UdeskComponent {...((udeskContext || {}).props || {})} $UdeskContext$={udeskContext} {...props} ref={ref} />;
                }
            }
        }
        </Context.UdeskConsumer>;
    });

    finalNode.ComponentClass = componentClass;
    finalNode.UdeskComponentClass = UdeskComponent;
    finalNode.name = UdeskComponent.displayName;

    let copiedStaticProperties = Object.getOwnPropertyNames(componentClass).filter(n => !SKIP_PROPERTY_NAMES.includes(n));
    for (let name of copiedStaticProperties) {
        finalNode[name] = componentClass[name];
    }
    let copiedStaticSymbols = Object.getOwnPropertySymbols(componentClass);
    for (let symbol of copiedStaticSymbols) {
        finalNode[symbol] = componentClass[symbol];
    }

    return finalNode;
}

function udeskifyInstance(instance, props) {
    if (instance[UDESKIFIED_FLAG_SYMBOL]) {
        // Theoretically this will never happen, because this method is called in constructor.
        return;
    }
    else {
        Object.defineProperty(instance, UDESKIFIED_FLAG_SYMBOL, {
            value: true,
            writable: false,
            enumerable: false,
            configurable: false
        });
    }

    defineDefaultValues(instance, {
        state: {},
        privates: {},
        memoizedPrivates: {},
        memoizedStates: {},
        actions: {},
        elementId: `cid-${ElementIdSeed++}`,
        domRef: React.createRef(),
        isComMounted: false,
        isDestroyed: false,
        modelProps: [],
        clearModelOnReloading: false,
        showModelReloadAnimation: false,
        autoLoad: true,
        asyncModelProps: [],
        clearAsyncModelOnReloading: false,
        getModelErrorMsg: getModelErrorMsg,
        isPropEqual(name, value1, value2) {
            return value1 === value2;
        },
        isPrivateEqual(name, value1, value2) {
            return value1 === value2;
        },
        isStateValueEqual(name, value1, value2) {
            return value1 === value2;
        },
    });
    defineDefaultValues(instance.state, {
        [OWNER_SYMBOL]: instance,
    });
    defineDefaultValues(instance.privates, {
        computes: {},
        [IS_INITIAL_SYMBOL]: true,
        [IS_SET_STATE_CALLING_SYMBOL]: false,
        [PENDING_UPDATERS_SYMBOL]: [],
        [IS_MODEL_RELOADING_SYMBOL]: false,
        [MODEL_RELOADING_ARGS_SYMBOL]: [],
        dirtyProps: getDefaultDirtyProps(instance),
    });

    defineDefaultValues(instance.privates, {
        model: undefined,
        modelInitialized: false,
        modelFulfilled: false,
        modelResolved: false,
        modelError: null,
        modelErrorMsg: null,
        asyncModel: undefined,
        asyncModelInitialized: false,
        asyncModelFulfilled: false,
        asyncModelResolved: false,
        asyncModelError: null,
        asyncModelErrorMsg: null,
        isAsyncModelReloadDelayed: false,
        componentDidMountDelayed: false,
    });

    mergeInstanceFields(instance);
    restoreReduxSavedStates(instance);

    // Add extra instance methods
    addSystemMethods(instance);

    // Build actions
    addSystemActions(instance);
    bindActionsContext(instance);

    // Protect internal fields
    makeReadonly(instance, ["privates", "privates.dirtyProps", "actions", "modelProps", "asyncModelProps"]);

    // Patch current lifecycles such as render, didComponentUpdate, etc.
    patchLifeCycles(instance);
    overrideFrameworkMethods(instance);
    initInstanceRedux(instance);

    // Add `event` behaviors to component
    EventedClass.injectTo(instance, (propName, value) => {
        return {
            [propName === "trigger" ? TRIGGER_EVENT_SYMBOL : propName]: value
        };
    });

    // Calculate computed properties for the fist render.
    calculateComputes.call(instance, instance.constructor, {
        props,
        state: instance.state,
    });

    registerEvents(instance);

    instance.trigger("onCreated", instance);
}

function comparableVersion(reactVersion) {
    return reactVersion.split(".").map(v => v.padStart(4, "0")).join(".");
}

function connectRedux(componentClass, UdeskComponent) {
    if (componentClass[REDUX_CONNECT_HOC_SYMBOL] == null) {
        let connectOptions = {
            ...(componentClass.connectOptions || {}),
            forwardRef: true
        };
        let mapStateToPropsForConnect = (state, ownProps) => {
            let fullReduxKey = componentClass.generateReduxKey(ownProps.reduxKey);
            return mapStateToProps.apply(this, [componentClass, fullReduxKey, state, ownProps]);
        };
        let mapDispatchToPropsForConnect = (dispatch, ownProps) => {
            let fullReduxKey = componentClass.generateReduxKey(ownProps.reduxKey);
            return mapDispatchToProps.apply(this, [componentClass, fullReduxKey, dispatch, ownProps]);
        };
        let mergePropsForConnect = (stateProps, dispatchProps, ownProps) => {
            let fullReduxKey = componentClass.generateReduxKey(ownProps.reduxKey);
            return mergeProps.apply(this, [componentClass, fullReduxKey, stateProps, dispatchProps, ownProps]);
        };
        componentClass[REDUX_CONNECT_HOC_SYMBOL] = connect(mapStateToPropsForConnect, mapDispatchToPropsForConnect, mergePropsForConnect, connectOptions)(UdeskComponent);
    }
    return componentClass[REDUX_CONNECT_HOC_SYMBOL];
}

/**
 * 支持老版本的生命周期
 *
 * @param {*} instance 组件实例
 * @param {*} ComponentClass 组件类
 */
function backwardCompatibility(instance, ComponentClass) {
    if (instance.componentWillReceiveProps) {
        delete ComponentClass.getDerivedStateFromProps;
        patchLifeCycle(instance, "componentWillReceiveProps", function (originalLifeCycle, args) {
            let [
                nextProps,
            ] = args;
            let prevState = instance.state;
            return beforePropsChangedHook(instance, ComponentClass, nextProps, prevState, originalLifeCycle);
        });
    }
}

function handleChildrenAsyncReload() {
    if (childrenAsyncReloadListner) {
        setTimeout(() => {
            childrenAsyncReloadListner.forEach(handler => {
                if (typeof handler === 'function') {
                    handler();
                }
            });
        }, 300);
    }
}

function defineDefaultValues(obj, defaultValues, descriptors) {
    for (let key of Object.keys(defaultValues).concat(Object.getOwnPropertySymbols(defaultValues))) {
        if (obj[key] == null) {
            let descriptor = Object.getOwnPropertyDescriptor(defaultValues, key);
            if (descriptor) {
                Object.defineProperty(obj, key, descriptor);
            }
            else {
                obj[key] = defaultValues[key];
            }
        }
    }
    if (descriptors) {
        for (let key of Object.keys(descriptors).concat(Object.getOwnPropertySymbols(descriptors))) {
            if (obj[key] == null) {
                Object.defineProperty(obj, key, descriptors[key]);
            }
        }
    }
}

function getDefaultDirtyProps(instance) {
    let dirtyProps = {};
    let propNames = getDirtyPropNames(instance);
    if (propNames && propNames.length > 0) {
        for (let name of propNames) {
            dirtyProps[name] = false;
        }
    }
    return dirtyProps;
}

function getDirtyPropNames(instance) {
    let propNames = Object.keys(instance.props).concat(Object.keys((instance.props.match || {}).params || {}).map(k => "match.params." + k));
    return propNames;
}

function makeReadonly(obj, propNames) {
    if (obj == null || propNames == null) {
        return;
    }
    if (!Array.isArray(propNames)) {
        propNames = [propNames];
    }
    for (let propName of propNames) {
        let currentObj = obj;
        let lastIndexOfDot = propName.lastIndexOf(".");
        if (lastIndexOfDot !== -1) {
            currentObj = get(currentObj, propName.substr(0, lastIndexOfDot));
            propName = propName.substr(lastIndexOfDot + 1);
        }
        if (currentObj == null) {
            continue;
        }

        let descriptor = Object.getOwnPropertyDescriptor(currentObj, propName);
        if (descriptor && Object.prototype.hasOwnProperty.call(descriptor, "value") && descriptor.writable && descriptor.configurable) {
            Object.defineProperty(currentObj, propName, {
                writable: false,
                enumerable: descriptor.enumerable,
                configurable: false,
                value: descriptor.value
            });
        }
    }
}

function mergeInstanceFields(instance) {
    //Validate whether any field was incorrectly set as a method.
    let functionFields = INSTANCE_MERGEABLE_FIELDS.concat(INSTANCE_CONCAT_FIELDS).filter(f => typeof instance[f] === "function");
    if (functionFields.length > 0) {
        throw new Error(`The following instance fields are incorrectly set as functions: ${instance.constructor.name} -> ${functionFields.join(", ")}. If you wanted to declare an instance field in a base class, you have to do it in this way,
        "[${functionFields[0]}]"() {
            return Object();
        }`);
    }

    let basePrototypes = [];
    let basePrototype = instance;
    while (typeof basePrototype === "object" && basePrototype.constructor !== Object) {
        basePrototypes.push(basePrototype);
        basePrototype = Object.getPrototypeOf(basePrototype);
    }

    let reversedPrototypes = basePrototypes.reverse();
    for (let field of INSTANCE_MERGEABLE_FIELDS) {
        let overrideValues = reversedPrototypes.map(t => t[`[${field}]`] && t[`[${field}]`]()).concat(instance[field]).filter(Boolean);
        if (overrideValues.length > 0) {
            if (overrideValues.some(v => Object.prototype.toString(v) === "[object Object]")) {
                instance[field] = Object.assign(instance[field] || {}, ...overrideValues);
            }
            else {
                instance[field] = overrideValues[overrideValues.length - 1];
            }
        }
    }
    for (let field of INSTANCE_CONCAT_FIELDS) {
        instance[field] = unique(basePrototypes.map(t => t[`[${field}]`] && t[`[${field}]`]()).concat(instance[field]).filter(Boolean));
    }
}

function mergeStaticFields(ComponentClass) {
    //Validate whether any field was incorrectly set as a method.
    let functionFields = STATIC_MERGEABLE_FIELDS.concat(STATIC_CONCAT_FIELDS).filter(f => typeof ComponentClass[f] === "function");
    if (functionFields.length > 0) {
        throw new Error(`The following static fields are incorrectly set as functions: ${ComponentClass.name} -> ${functionFields.join(", ")}. If you wanted to declare a static field in a base class, you have to do it in this way,
        "[${functionFields[0]}]"() {
            return Object();
        }`);
    }

    let baseTypes = [];
    let baseType = ComponentClass;
    while (typeof baseType === "function" && baseType !== Function.prototype) {
        baseTypes.push(baseType);
        baseType = Object.getPrototypeOf(baseType);
    }

    let reversedBaseTypes = baseTypes.reverse();
    for (let field of STATIC_MERGEABLE_FIELDS) {
        let overrideValues = reversedBaseTypes.map(t => t[field]).filter(Boolean);
        if (overrideValues.length > 0) {
            ComponentClass[field] = Object.assign({}, ...overrideValues);
        }
    }
    for (let field of STATIC_CONCAT_FIELDS) {
        ComponentClass[field] = unique(baseTypes.map(t => t[field]).filter(Boolean));
    }
}

function restoreReduxSavedStates(instance) {
    if (instance.constructor[REDUX_ENABLED_SYMBOL]) {
        let reduxState = getState(instance.constructor.generateReduxKey(null));
        if (reduxState && reduxState[SAVED_STATES_REDUX_STORE_FIELD_NAME]) {
            let savedStates = reduxState[SAVED_STATES_REDUX_STORE_FIELD_NAME];
            for (let key of Object.keys(savedStates)) {
                set(instance, key, savedStates[key]);
            }
        }
    }
}

function buildComputeDependKeys(ComponentClass) {
    let { dependKeys, memoizedPrivatesKeys, memoizedStateKeys } = buildDependKeys(ComponentClass, "computes");
    ComponentClass[COMPUTE_DEPEND_KEYS_SYMBOL] = dependKeys;
    ComponentClass[MEMOIZED_PRIVATES_KEYS_SYMBOL] = unique([...memoizedPrivatesKeys, ...(ComponentClass[MEMOIZED_PRIVATES_KEYS_SYMBOL] || [])]);
    ComponentClass[MEMOIZED_STATE_KEYS_SYMBOL] = unique([...memoizedStateKeys, ...(ComponentClass[MEMOIZED_STATE_KEYS_SYMBOL] || [])]);
}

function buildObserverDependKeys(ComponentClass) {
    let { dependKeys, memoizedPrivatesKeys, memoizedStateKeys } = buildDependKeys(ComponentClass, "observers");
    ComponentClass[OBSERVER_DEPEND_KEYS_SYMBOL] = dependKeys;
    ComponentClass[MEMOIZED_PRIVATES_KEYS_SYMBOL] = unique([...memoizedPrivatesKeys, ...(ComponentClass[MEMOIZED_PRIVATES_KEYS_SYMBOL] || [])]);
    ComponentClass[MEMOIZED_STATE_KEYS_SYMBOL] = unique([...memoizedStateKeys, ...(ComponentClass[MEMOIZED_STATE_KEYS_SYMBOL] || [])]);
}

function buildDependKeys(ComponentClass, type) {
    let dependKeysMap = {};
    let memoizedPrivatesKeys = [];
    let memoizedStateKeys = [];
    if (ComponentClass[type]) {
        for (let [key, expression] of Object.entries(ComponentClass[type])) {
            if (Array.isArray(expression)) {
                if (expression.length === 0) {
                    throw new Error(`Computed expression ${ComponentClass.name}.${key} must have a callback method and it must be at the last position.`);
                }
                if (typeof expression[expression.length - 1] !== "function") {
                    throw new Error(`The last item of computed expression ${ComponentClass.name}.${key} must be a callback method.`);
                }
                if (expression.length > 1) {
                    let dependKeys = expression.slice(0, expression.length - 1);
                    if (dependKeys.some(e => typeof e !== "string")) {
                        throw new Error(`Computed expression ${ComponentClass.name}.${key} must have only string dependencies.`);
                    }
                    let allDependKeys = getDependKeysRecursively(key, [key], ComponentClass, type);
                    allDependKeys = unique(allDependKeys);
                    if (!allDependKeys.some(k => k === "*")) {
                        // Exclude the dependencies which contain "*", because the dependency is changed every time.
                        dependKeysMap[key] = unique(allDependKeys); //Distinct
                    }
                    memoizedPrivatesKeys.push(...allDependKeys.filter(k => k.startsWith("privates.")));
                    memoizedStateKeys.push(...allDependKeys.filter(k => k.startsWith("state.")));
                }
            }
            else if (typeof expression === "function") {
                // Valid compute expression. Do nothing.
            }
            else {
                throw new Error(`Computed expression ${ComponentClass.name}.${key} is invalid. Only function and array (function with dependencies) are supported.`);
            }
        }
    }
    return { dependKeys: dependKeysMap, memoizedPrivatesKeys, memoizedStateKeys };
}

function getDependKeysRecursively(key, parentKeys, ComponentClass, type) {
    let keys = [];
    if (ComponentClass[type] && Object.prototype.hasOwnProperty.call(ComponentClass[type], key)) {
        let expression = ComponentClass[type][key];
        if (Array.isArray(expression)) {
            if (expression.length > 1) {
                let dependKeys = expression.slice(0, expression.length - 1);
                for (let dependKey of dependKeys) {
                    let sameIndex = parentKeys.indexOf(dependKey);
                    if (sameIndex !== -1) {
                        parentKeys[sameIndex] = `(${parentKeys[sameIndex]})`;
                        throw new Error(`Computed expression circular reference detected! The circulation path is: ${ComponentClass.name}.${type} -> ${[...parentKeys, `(${dependKey})`].join(" -> ")}.`);
                    }
                    keys.push(...getDependKeysRecursively(dependKey, [...parentKeys, dependKey], ComponentClass));
                }
            }
            else {
                // The expression has no dependency, it will never be changed.
                // So the key can be ignored.
            }
        }
        else if (typeof expression === "function") {
            keys.push("*");
        }
    }
    else {
        if (key.startsWith("props.") || key.startsWith("state.") || key.startsWith("privates.")) {
            keys.push(key);
        }
        else {
            keys.push(`props.${key}`);
        }
    }
    return keys;
}

function buildComputeExecutionPlan(ComponentClass) {
    let executionPlan = buildExecutionPlan(ComponentClass, "computes");
    ComponentClass[COMPUTES_EXECUTION_PLAN_SYMBOL] = executionPlan;
}

function buildObserverExecutionPlan(ComponentClass) {
    let executionPlan = buildExecutionPlan(ComponentClass, "observers");
    ComponentClass[OBSERVER_EXECUTION_PLAN_SYMBOL] = executionPlan;
}

function buildExecutionPlan(ComponentClass, type) {
    let executionPlan = {
        isolated: [],
        once: [],
        cached: [],
    };
    if (ComponentClass[type]) {
        let rootDescriptor = {
            name: "",
            parent: null,
            level: 0,
            children: [],
        };
        let descriptorsMap = new Map(Object.keys(ComponentClass[type]).map(k => {
            return [k, {
                name: k,
                parent: rootDescriptor,
                isolated: true,
                once: false,
                level: 1,
                children: [],
            }];
        }));
        for (let [key, expression] of Object.entries(ComponentClass[type])) {
            loadDependencyDescriptorRecursively(ComponentClass, type, key, expression, rootDescriptor, descriptorsMap);
        }
        let allDescriptors = Array.from(descriptorsMap.values());
        // 使用数组形式声明，但没有添加任何依赖项的，认为只需要执行一次，不会失效
        executionPlan.once = allDescriptors.filter(d => d.once).map(d => d.name);
        // 直接使用function形式的，每次都会重新执行，切优先级最高
        executionPlan.isolated = allDescriptors.filter(d => d.isolated).map(d => d.name);
        // 使用数组形式声明，且添加了依赖项的
        executionPlan.cached = allDescriptors.filter(d => !d.isolated && !d.once);
        executionPlan.cached.sort((a, b) => {
            return b.level - a.level;
        });
        executionPlan.cached = executionPlan.cached.map(d => d.name);
    }
    return executionPlan;
}

function loadDependencyDescriptorRecursively(ComponentClass, type, key, expression, parentDescriptor, descriptorsMap) {
    let descriptor = descriptorsMap.get(key);
    if (descriptor == null) {
        return;
    }
    if (descriptor.level <= parentDescriptor.level) {
        let index = descriptor.parent.children.indexOf(descriptor);
        if (index !== -1) {
            descriptor.parent.children.splice(index, 1);
        }
        descriptor.parent = parentDescriptor;
        descriptor.level = parentDescriptor.level + 1;
    }
    let recursion = false;
    if (Array.isArray(expression)) {
        descriptor.isolated = false;
        if (expression.length > 1) {
            recursion = true;
        }
        else {
            // 使用数组形式声明，但没有添加任何依赖项的，认为只需要执行一次，不会失效
            descriptor.once = true;
        }
    }
    else if (typeof expression === "function") {
        // 直接使用function形式的，每次都会重新执行，切优先级最高
        descriptor.isolated = true;
    }
    else {
        return;
    }
    if (parentDescriptor.children.indexOf(descriptor) === -1) {
        parentDescriptor.children.push(descriptor);
    }
    if (recursion) {
        let dependKeys = expression.slice(0, expression.length - 1).filter(k => Object.prototype.hasOwnProperty.call(ComponentClass[type], k));
        for (let dependKey of dependKeys) {
            loadDependencyDescriptorRecursively(ComponentClass, type, dependKey, ComponentClass[type][dependKey], descriptor, descriptorsMap);
        }
    }
}

function addSystemMethods(instance) {
    Object.defineProperties(instance, {
        trigger: {
            writable: false,
            enumerable: true,
            configurable: false,
            value(eventName, ...args) {
                let ownerEventHandler = this[eventName];
                let ownerCallbackResult = undefined;
                if (ownerEventHandler != null && typeof ownerEventHandler === "function") {
                    ownerCallbackResult = ownerEventHandler.apply(this, args);
                }

                let eventHandler = this.props[eventName];
                let eventContext = this.props[eventName + "Context"];
                let callbackResult = undefined;
                if (eventHandler != null && typeof eventHandler === "function") {
                    callbackResult = eventHandler.apply(eventContext, args);
                }
                if (callbackResult == null && ownerCallbackResult != null) {
                    callbackResult = ownerCallbackResult;
                }

                this[TRIGGER_EVENT_SYMBOL](eventName, args);

                return callbackResult;
            }
        },
        udeskContext: {
            enumerable: true,
            configurable: false,
            get() {
                // As of react 16.6.0, the new Context api was introduced.
                // We can declare component context by `static contextType`.
                // So we can support both, render prop (in case of older react version) prior to contextType.
                return this.props.$UdeskContext$ || (this.context instanceof UdeskContext ? this.context : null);
            }
        },
        locales: {
            enumerable: true,
            configurable: false,
            get() {
                // We don't need a cache of current locales here.
                // Because the time-complexity is almost the same as old way `Locales.get()`
                let localesManager = UdeskLocales;
                let context = this.udeskContext;
                if (context && context.options && context.options.localesManager) {
                    if (!(context.options.localesManager instanceof ToolkitLocalesManager)) {
                        throw new Error(`Udesk.react.context.defaults.localesManager must be instance of LocalesManager class in udesk-web-toolkits.`);
                    }
                    localesManager = context.options.localesManager;
                }
                return localesManager.getLocales(this.props.language) || localesManager.current;
            }
        },
        getLocale: {
            writable: false,
            enumerable: true,
            configurable: false,
            value(key, languageCode) {
                if (languageCode == null) {
                    languageCode = this.props.language;
                }
                return UdeskLocales.get(key, languageCode);
            }
        },
        invoke: {
            writable: false,
            enumerable: true,
            configurable: false,
            value(actionName, ...args) {
                if (this.actions == null) {
                    return;
                }
                let action = this.actions[actionName];
                if (action != null && typeof action === "function") {
                    return action.apply(this, args);
                }
                else {
                    return undefined;
                }
            }
        },
    });
}

function addSystemActions(component) {
    Object.defineProperties(component.actions, {
        mutator: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(fieldName, parser, forceUpdate) {
                if (forceUpdate === undefined && typeof parser === "boolean") {
                    forceUpdate = parser;
                    parser = null;
                }
                return (value, ...args) => {
                    if (fieldName == null) {
                        fieldName = "";
                    }
                    if (typeof fieldName !== "function") {
                        fieldName = fieldName.toString();
                    }

                    if (typeof parser === "function") {
                        value = parser.apply(this, [value, ...args]);
                    }
                    if (fieldName.startsWith("state.")) {
                        let updater = {};
                        set(updater, fieldName.replace(/^state\./, ""), value);
                        this.setState(updater);
                    }
                    else {
                        set(this, fieldName, value);
                        if (forceUpdate) {
                            this.actions.update();
                        }
                    }
                };
            }
        },
        inputMutator: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(fieldName, parser, forceUpdate) {
                return (e, ...args) => {
                    let value = e;
                    if (e.target && e.currentTarget && e.stopPropagation) {
                        value = e.target.value;
                    }
                    return this.actions.mutator(fieldName, parser, forceUpdate)(value, ...args);
                };
            }
        },
        update: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(callback) {
                this.setState({}, callback);
            }
        },
        pendingState: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(updater, callback) {
                if (updater) {
                    if (this.privates[PENDING_UPDATERS_SYMBOL] == null) {
                        this.privates[PENDING_UPDATERS_SYMBOL] = [];
                    }
                    this.privates[PENDING_UPDATERS_SYMBOL].push([updater, callback]);
                }
            }
        },
        commitState: {
            writable: false,
            enumerable: true,
            configurable: true,
            value() {
                let hasFunction = false;
                let pending = {};
                for (let [updater, callback] of this.privates[PENDING_UPDATERS_SYMBOL]) {
                    if (typeof updater === "function") {
                        hasFunction = true;
                        this.setState(pending);
                        pending = {};
                        this.setState(updater, callback);
                    }
                    else {
                        pending = Object.assign(pending, updater);
                    }
                }
                if (!hasFunction) {
                    this.setState(pending);
                }
                this.privates[PENDING_UPDATERS_SYMBOL] = [];
            }
        },
        noop: {
            writable: false,
            enumerable: true,
            configurable: true,
            value() { }
        },
        reloadModel: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(...args) {
                // If the reloadModel method was called during the model is loading, just ignore it.
                // It doesn't make sense reloadModel while the model is still loading,
                // it automatically renders once the model is fulfilled.
                if (this.privates.modelFulfilled) {
                    if (this.clearModelOnReloading) {
                        resetModelAll(this);
                    } else {
                        resetModelStatus(this);
                    }
                    this.privates.modelInitialized = false;
                    this.privates[IS_MODEL_RELOADING_SYMBOL] = true;
                    this.privates[MODEL_RELOADING_ARGS_SYMBOL] = args;
                    this.forceUpdate();
                }
            }
        },
        reloadAsyncModel: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(...args) {
                if (this.clearAsyncModelOnReloading) {
                    resetAsyncModelAll(this);
                } else {
                    resetAsyncModelStatus(this);
                }
                startLoadingAsyncModel.call(this, true, args);
            }
        },
        // 异步模式加载数据
        // 相应的组件内实现方法要更改！反劫持udeskify数据加载逻辑
        reloadAsyncData: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(...args) {
                // init
                if (typeof this.init === 'function') {
                    this.init();
                }
                // sync
                resetModelStatus(this);
                this.privates[MODEL_RELOADING_ARGS_SYMBOL] = args;
                startLoadingModel.call(this, true, []);

                // async
                resetAsyncModelStatus(this);
                startLoadingAsyncModel.call(this, true, args);

                this.forceUpdate(() => {
                    handleChildrenAsyncReload();
                });
            }
        },
        promiseWaiting: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(promise) {
                return {
                    status: "waiting"
                };
            }
        },
        promiseResolved: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(result) {
                return {
                    status: "resolved",
                    result
                };
            }
        },
        promiseCatch: {
            writable: false,
            enumerable: true,
            configurable: true,
            value(reason) {
                return {
                    status: "error",
                    reason
                };
            }
        },
    });

    // Declare methods with the same name as behaviors, for easier use.
    for (let descriptor of ACTION_BEHAVIOR_DESCRIPTORS) {
        if (!Object.prototype.hasOwnProperty.call(component.actions, descriptor.name)) {
            component.actions[descriptor.name] = function getBehavior() {
                return component.actions[descriptor.name][descriptor.name].apply(this, arguments);
            };
        }
    }
}

function bindActionsContext(component) {
    if (component && component.actions) {
        for (let [key, action] of Object.entries(component.actions)) {
            if (typeof action === "function") {
                let boundAction = action.bind(component);
                attachActionBehaviors(boundAction, component);
                Object.defineProperty(component.actions, key, {
                    writable: false,
                    enumerable: true,
                    configurable: true,
                    value: boundAction
                });

            }
        }
    }
}

function calculateComputes(ComponentClass, context) {
    if (ComponentClass[COMPUTES_EXECUTION_PLAN_SYMBOL] == null) {
        throw new Error(`The computed expressions execution plan of ${ComponentClass.name} is not generated which is abnormal. The execution plan must be generated before calculating, please check!`);
    }
    const executionPlan = ComponentClass[COMPUTES_EXECUTION_PLAN_SYMBOL];
    const dependKeys = ComponentClass[COMPUTE_DEPEND_KEYS_SYMBOL];
    performExecutionPlan.call(this, ComponentClass, "computes", executionPlan, dependKeys, context, calculateOneComputeExpression);
}

function calculateObservers(ComponentClass, context) {
    if (ComponentClass[OBSERVER_EXECUTION_PLAN_SYMBOL] == null) {
        throw new Error(`The observers execution plan of ${ComponentClass.name} is not generated which is abnormal. The execution plan must be generated before calculating, please check!`);
    }
    const executionPlan = ComponentClass[OBSERVER_EXECUTION_PLAN_SYMBOL];
    const dependKeys = ComponentClass[OBSERVER_DEPEND_KEYS_SYMBOL];
    performExecutionPlan.call(this, ComponentClass, "observers", executionPlan, dependKeys, context, calculateOneObserver);
}

function performExecutionPlan(ComponentClass, type, executionPlan, dependKeysMap, { props, state }, performHandler, {
    skipInitial = false,
    skipIsolated = false,
    skipOnce = false,
    skipCached = false
} = {}) {
    if (ComponentClass[type]) {
        let {
            privates,
            locales
        } = this;
        let isInitial = this.privates[IS_INITIAL_SYMBOL];
        let targetKeys = [];
        if (!skipInitial && isInitial) {
            // 第一次渲染，需要提前计算一遍计算属性
            if (!skipOnce) {
                // 先计算使用了依赖项的方式声明，但没有添加任何依赖项的，这种只会执行一次，再也不会发生变化
                targetKeys.push(...executionPlan.once);
            }
            // 先计算纯function形式的，即没有任何依赖项，优先执行
            if (!skipIsolated) {
                targetKeys.push(...executionPlan.isolated);
            }
            // 再计算有依赖项的
            if (!skipCached) {
                targetKeys.push(...executionPlan.cached);
            }
        }
        else {
            // Always executes isolated first.
            if (!skipIsolated) {
                targetKeys.push(...executionPlan.isolated);
            }
            if (!skipCached) {
                for (let key of executionPlan.cached) {
                    let expression = ComponentClass[type][key];
                    if (Array.isArray(expression)) {
                        let dependKeys = dependKeysMap[key];
                        if (dependKeys) {
                            for (let dependKey of dependKeys) {
                                // 支持依赖项是一个props
                                if (dependKey.startsWith("props.")) {
                                    let realKey = dependKey.replace("props.", "");
                                    if (this.privates.dirtyProps[realKey]) {
                                        targetKeys.push(key);
                                        break;
                                    }
                                }
                                // 支持依赖项是privates
                                else if (dependKey.startsWith("privates.")) {
                                    let realKey = dependKey.replace("privates.", "");
                                    if (!this.isPrivateEqual(realKey, get(this.privates, realKey), this.memoizedPrivates[realKey])) {
                                        targetKeys.push(key);
                                        break;
                                    }
                                }
                                // 支持依赖项是state
                                else if (dependKey.startsWith("state.")) {
                                    let realKey = dependKey.replace("state.", "");
                                    if (!this.isStateValueEqual(realKey, get(this.state, realKey), this.memoizedStates[realKey])) {
                                        targetKeys.push(key);
                                        break;
                                    }
                                }
                            }
                        }
                        else {
                            // Don't re-run [once] expressions again, because they have been ran at the very first.
                        }
                    }
                    else if (typeof expression === "function") {
                        targetKeys.push(key);
                    }
                }
            }
        }

        let privatesWithoutComputes = Object.assign({}, privates);
        delete privatesWithoutComputes.computes;
        for (let key of targetKeys) {
            let expression = ComponentClass[type][key];
            let callback = expression;
            let executionPrivates = privates;
            if (Array.isArray(expression)) {
                callback = expression[expression.length - 1];
            }
            else if (typeof expression === "function") {
                executionPrivates = privatesWithoutComputes;
            }
            else {
                continue;
            }
            performHandler.call(this, key, callback, props, state, executionPrivates, locales);
        }
    }
}

function calculateOneComputeExpression(key, callback, props, state, privates, locales) {
    // Always use `undefined` as context, no matter whether `this` has a value or not.
    // We don't want users rely on the component context, instead we pass all that possible to use.
    let actions = {};
    if (Array.isArray(this.constructor.computesUsedActions)) {
        for (let actionName of this.constructor.computesUsedActions) {
            if (Object.prototype.hasOwnProperty.call(this.actions, actionName)) {
                actions[actionName] = this.actions[actionName];
            }
        }
    }
    this.privates.computes[key] = callback.call(undefined, {
        props,
        state,
        privates,
        actions,
        locales,
        NEVER_USE_THIS_UNLESS_YOU_REALLY_KNOW_WHAT_YOU_ARE_DOING: this
    });
}

function calculateOneObserver(key, callback, props, state, privates, locales) {
    // Always use `undefined` as context, no matter whether `this` has a value or not.
    // We don't want users rely on the component context, instead we pass all that possible to use.
    let actions = {};
    if (Array.isArray(this.constructor.observerUsedActions)) {
        for (let actionName of this.constructor.observerUsedActions) {
            if (Object.prototype.hasOwnProperty.call(this.actions, actionName)) {
                actions[actionName] = this.actions[actionName];
            }
        }
    }
    callback.call(undefined, {
        props,
        state,
        privates,
        actions,
        locales,
        NEVER_USE_THIS_UNLESS_YOU_REALLY_KNOW_WHAT_YOU_ARE_DOING: this
    });
}


function attachActionBehaviors(targetMethod, context) {
    for (let descriptor of ACTION_BEHAVIOR_DESCRIPTORS) {
        targetMethod[descriptor.name] = (function (behaviorDescriptor) {
            return function getBehavior(...declareArgs) {
                let actionBehavior = function actionBehavior(...runtimeArgs) {
                    targetMethod.apply(context, runtimeArgs);
                    behaviorDescriptor.handler.apply(context, declareArgs.concat(runtimeArgs));
                };
                attachActionBehaviors(actionBehavior, context);
                return actionBehavior;
            };
        })(descriptor);
    }

    targetMethod.bind = function bind() {
        let bound = Function.prototype.bind.apply(this, arguments);
        attachActionBehaviors(bound, context);
        return bound;
    };

    targetMethod.params = function params() {
        return this.bind(context, ...arguments);
    };
    return targetMethod;
}

function patchLifeCycles(component) {
    let patches = getLifeCyclePatches();
    let overrideLifeCycleNames = unique([...Object.keys(patches.before), ...Object.keys(patches.handler), ...Object.keys(patches.after)]);
    for (const name of overrideLifeCycleNames) {
        Object.defineProperty(component, name, {
            writable: false,
            enumerable: true,
            configurable: true,
            value: (function (lifeCycleName) {
                let beforeHandler = patches.before[lifeCycleName];
                let afterHandler = patches.after[lifeCycleName];
                let lifeCycle = component[lifeCycleName] || patches.handler[lifeCycleName];
                return function (...args) {
                    if (beforeHandler != null) {
                        let result = beforeHandler.apply(component, args);
                        if (result === CANCEL_ACTION_SYMBOL) {
                            return;
                        }
                        else if (Array.isArray(result) && result.length === 2 && result[0] === CANCEL_ACTION_SYMBOL) {
                            return result[1];
                        }
                    }

                    const noResultFlag = {};
                    let actionResult = noResultFlag;
                    if (lifeCycle != null) {
                        try {
                            actionResult = lifeCycle.apply(component, args);
                        } catch (e) {
                            window.console.error(e);
                            actionResult = noResultFlag;
                        }
                    }
                    if (afterHandler != null) {
                        let afterInputResult = undefined;
                        if (actionResult !== noResultFlag) {
                            afterInputResult = actionResult;
                        }
                        let afterOutputResult = afterHandler.apply(component, [args, afterInputResult]);
                        if (afterOutputResult !== undefined) {
                            actionResult = afterOutputResult;
                        }
                    }
                    if (actionResult === noResultFlag) {
                        return undefined;
                    }
                    else {
                        return actionResult;
                    }
                };
            })(name),
        });
    }
}

function getLifeCyclePatches() {
    if (GlobalLifeCyclePatchConfig == null) {
        GlobalLifeCyclePatchConfig = {
            before: {
                componentDidMount() {
                    this.isComMounted = true;
                    this.privates[IS_INITIAL_SYMBOL] = false;
                    if (!this.privates.modelFulfilled) {
                        this.privates.componentDidMountDelayed = true;
                        return [CANCEL_ACTION_SYMBOL, null];
                    }

                    /* 注意，此时注册reducer其实已经晚了！
                    当组件第一次创建之前，需要调用mapStateToProps，先从redux中获取组件的props，这个时机甚至要早于组件的构造函数！
                    所以我们在这个方法中即便注册了reducer了，但其实在第一次调用mapStateToProps时，并不会获取到组件的defaultState。
                    因此，我们在reducer/mapStateToProps方法中做了补偿，如果发现reducer还没有注册，则会自动获取组件类的静态defaultState，
                    确保组件的第一次渲染时，便会把state传递到props中。再后面的事情，就是reducer自己接管了。
                    不过有人可能会问，componentDidMount这个生命周期肯定晚啊，放到组件的构造函数中是不是就可以了？有两个问题，第一个就是上面已经
                    解释了，即便是放到构造函数中也不能比mapStateToProps更早。第二个问题是，react的异步渲染机制，如果父组件setState，React都会创建
                    创建一个新的子组件实例。按理说，应该是先销毁老组件并从store中移除实例state，再渲染新组件，store中再次重建为默认值，这样的时序应该
                    比较合理。在v15之前是这样的，但从v16之后，componentWillUnmount事件变成异步的了，所以他会跑到新组件的构造函数甚至render之后！
                    React框架只保证新组件的componentDidMount事件在老组件的componentWillUnmount之后。所以，注册新的reducer的方法只能延迟到
                    componentDidMount事件，可以参考https://github.com/facebook/react/issues/11106
                    */
                    let fullReduxKey = this[INSTANCE_REDUX_KEY_SYMBOL];
                    registerInstanceReducers(this.constructor, fullReduxKey);
                },
                render() {
                    let modelInitialized = this.privates.modelInitialized;
                    this.privates.modelInitialized = true;

                    // If `asyncModel` is not a promise, then set its value synchronously.
                    // So don't have to wait another render.
                    if (!this.privates.asyncModelInitialized && typeof this.asyncModel !== "function") {
                        startLoadingAsyncModel.call(this, false, []);
                    }

                    if (modelInitialized) {
                        if (this.privates.modelFulfilled) {
                            let propsWithDefaults = getDefaultPropsValues(this);
                            if (!this.privates.modelResolved) {
                                if (propsWithDefaults.showModelError) {
                                    let animationProps = propsWithDefaults.modelLoadingAnimationProps;

                                    let modelError = propsWithDefaults.customizeModelErrorTemplate && typeof propsWithDefaults.customizeModelErrorTemplate === 'function' ?
                                        propsWithDefaults.customizeModelErrorTemplate.call(this) :
                                        <ModelError errorMsg={this.privates.modelErrorMsg} animationProps={animationProps} />;

                                    if (propsWithDefaults.modelErrorDisplayMode === enums.modelErrorDisplayMode.alert) {
                                        ToolkitsUdesk.ui.notify.error(this.privates.modelErrorMsg);
                                        return [CANCEL_ACTION_SYMBOL, null];
                                    }
                                    else if (propsWithDefaults.modelErrorDisplayMode === enums.modelErrorDisplayMode.inline) {
                                        return [CANCEL_ACTION_SYMBOL, modelError];
                                    }
                                    else if (propsWithDefaults.modelErrorDisplayMode === enums.modelErrorDisplayMode.both) {
                                        ToolkitsUdesk.ui.notify.error(this.privates.modelErrorMsg);
                                        return [CANCEL_ACTION_SYMBOL, modelError];
                                    }
                                    else {
                                        // Return nothing as long as the model promise is not fulfilled.
                                        return [CANCEL_ACTION_SYMBOL, null];
                                    }
                                }
                                else {
                                    // Return nothing as long as the model promise is not fulfilled.
                                    return [CANCEL_ACTION_SYMBOL, null];
                                }
                            }
                            else {
                                //continue rendering
                            }
                        }
                        else {
                            if (this.privates[IS_MODEL_RELOADING_SYMBOL] && !this.props.clearModelOnReloading) {
                                return undefined;
                            }
                            else {
                                // Return nothing as long as the model promise is not fulfilled.
                                return [CANCEL_ACTION_SYMBOL, null];
                            }
                        }
                    }
                    else {
                        return startLoadingModel.call(this, false, []);
                    }
                },
                componentWillUnmount() {
                    this.isComMounted = false;
                    this.isDestroyed = true;
                    this.state[OWNER_SYMBOL] = null;
                    if (this.constructor[REDUX_ENABLED_SYMBOL]) {
                        let keepReduxState = false;
                        if (this.props.keepReduxState !== undefined) {
                            keepReduxState = this.props.keepReduxState;
                        }
                        else if (this.keepReduxState !== undefined) {
                            keepReduxState = this.keepReduxState;
                        }
                        else if (this.constructor.keepReduxState !== undefined) {
                            keepReduxState = this.constructor.keepReduxState;
                        }
                        else {
                            keepReduxState = !this.props.reduxKey;
                        }
                        if (!keepReduxState) {
                            destroyReduxState(this);
                        }
                    }
                    this.off();
                },
            },
            handler: {
                shouldComponentUpdate(nextProps, nextState) {
                    if (nextProps.rerenderOptimization) {
                        let propNames = getDirtyPropNames(this);
                        let hasDirtyProps = false;
                        if (propNames && propNames.length > 0) {
                            for (let name of propNames) {
                                if (this.privates.dirtyProps[name]) {
                                    hasDirtyProps = true;
                                    break;
                                }
                            }
                        }
                        return this.privates[IS_SET_STATE_CALLING_SYMBOL] || hasDirtyProps;
                    }
                    else {
                        return true;
                    }
                }
            },
            after: {
                init() {
                    restoreStorage(this);
                },
                componentDidMount([...args]) {
                    this.isComMounted = true;
                    if (this.privates.modelFulfilled) {
                        if (!this.privates.asyncModelInitialized && this.autoLoad) {
                            startLoadingAsyncModel.call(this, false, []);
                        }
                    }

                    if (this.props.history && window.udeskApmRumMonitor && window.udeskApmRumMonitor.enabled) {
                        if (this.$private$_apmRumRenderSpan) {
                            this.$private$_apmRumRenderSpan.end();
                            delete this.$private$_apmRumRenderSpan;
                        }
                    }

                    serializeStorage(this);
                },
                // render([...args], actionResult) {

                // },
                componentDidUpdate([prevProps, prevState, snapshot]) {
                    this.privates[IS_INITIAL_SYMBOL] = false;

                    calculateObservers.call(this, this.constructor, {
                        props: this.props,
                        state: this.state,
                    });

                    let modelProps = this.modelProps;
                    if (modelProps && modelProps.length > 0) {
                        let needReload = modelProps.some(dep => this.privates.dirtyProps[dep]);
                        if (needReload) {
                            this.actions.reloadModel(true);
                        }
                    }

                    let asyncModelProps = this.asyncModelProps;
                    if (asyncModelProps && asyncModelProps.length > 0) {
                        let needReload = asyncModelProps.some(dep => this.privates.dirtyProps[dep]);
                        if (needReload && this.autoLoad) {
                            if (this.privates.modelResolved) {
                                this.actions.reloadAsyncModel(true);
                            }
                            else {
                                this.privates.isAsyncModelReloadDelayed = true;
                            }
                        }
                    }

                    // Reset isSetStateCalling flag.
                    this.privates[IS_SET_STATE_CALLING_SYMBOL] = false;

                    // Reset dirtyProps
                    Object.assign(this.privates.dirtyProps, getDefaultDirtyProps(this));

                    // Memoize privates which are used in computed expressions.
                    for (let key of this.constructor[MEMOIZED_PRIVATES_KEYS_SYMBOL]) {
                        this.memoizedPrivates[key] = this.privates[key];
                    }
                    for (let key of this.constructor[MEMOIZED_STATE_KEYS_SYMBOL]) {
                        this.memoizedStates[key] = this.state[key];
                    }

                    // 把SavedStates保存到redux中
                    if (this.constructor[REDUX_ENABLED_SYMBOL]) {
                        if (this.constructor.SAVED_STATE_KEYS && this.constructor.SAVED_STATE_KEYS.length > 0) {
                            let savedStates = {};
                            let oldSavedStates = (getState(this.constructor.generateReduxKey(null)) || {})[SAVED_STATES_REDUX_STORE_FIELD_NAME] || {};
                            let isChanged = false;
                            for (let key of this.constructor.SAVED_STATE_KEYS) {
                                let value = get(this, key);
                                let oldValue = oldSavedStates[key];
                                savedStates[key] = get(this, key);
                                if (value !== oldValue) {
                                    isChanged = true;
                                }
                            }
                            if (isChanged) {
                                let saveStatesReducer = getInternalSystemReducers(this.constructor, null)[SYSTEM_REDUCER_SAVE_STATES];
                                if (saveStatesReducer) {
                                    saveStatesReducer(savedStates);
                                }
                            }
                        }
                    }

                    serializeStorage(this);
                },
                componentWillUnmount([...args]) {
                    if (this["parentAsyncCase"]) {
                        this["parentAsyncCase"] = false;
                        parentAsyncCase = false;
                        childrenAsyncReloadListner = [];
                    }
                    this["asyncCase"] = false;
                    this.isComMounted = false;
                    resetAsyncModelAll(this);
                    serializeStorage(this);
                    this.trigger("onDestroyed");
                    this.off();
                    this.memoizedPrivates = {};
                    this.memoizedStates = {};
                },
            }
        };
    }
    return GlobalLifeCyclePatchConfig;
}

function overrideFrameworkMethods(component) {
    let bakSetState = component.setState;
    Object.defineProperties(component, {
        setState: {
            writable: false,
            enumerable: true,
            configurable: true,
            value: function () {
                component.privates[IS_SET_STATE_CALLING_SYMBOL] = true;
                return bakSetState.apply(component, arguments);
            }
        }
    });
}

function initInstanceRedux(instance) {
    if (instance.constructor[REDUX_ENABLED_SYMBOL]) {
        let fullReduxKey = instance.constructor.generateReduxKey(instance.props.reduxKey);
        instance[INSTANCE_REDUX_KEY_SYMBOL] = fullReduxKey;
    }
}

function registerEvents(component) {
    if (component.props.history && window.udeskApmRumMonitor && window.udeskApmRumMonitor.enabled) {
        component.on("onModelResolved.apm-rum-monitor", () => {
            if (component.props.history && window.udeskApmRumMonitor && window.udeskApmRumMonitor.enabled) {
                if (component.$private$_apmRumModelSpan != null) {
                    component.$private$_apmRumModelSpan.end();
                    delete component.$private$_apmRumModelSpan;
                }
                let currentTransaction = window.udeskApmRumMonitor.getCurrentApmRumTransaction();
                if (currentTransaction) {
                    let routeName = getRouteName(component);
                    component.$private$_apmRumRenderSpan = currentTransaction.startSpan(`render: ${routeName}`);
                }
            }
        });
    }

}

function resetModelAll(component) {
    component.privates.model = undefined;
    resetModelStatus(component);
}

function resetModelStatus(component) {
    component.privates.modelFulfilled = false;
    component.privates.modelResolved = false;
    component.privates.modelError = null;
    component.privates.modelErrorMsg = null;
}

function startLoadingModel() {
    let isModelReloading = this.privates[IS_MODEL_RELOADING_SYMBOL];
    let modelReloadingArgs = this.privates[MODEL_RELOADING_ARGS_SYMBOL];
    if (typeof this.model === "function") {
        if (this.props.history && window.udeskApmRumMonitor && window.udeskApmRumMonitor.enabled) {
            let routeName = getRouteName(this);
            let currentTransaction = window.udeskApmRumMonitor.getCurrentApmRumTransaction();
            if (currentTransaction) {
                this.$private$_apmRumModelSpan = currentTransaction.startSpan(`model: ${routeName}`);
            }
        }
        let modelResult = this.model.apply(this, modelReloadingArgs);
        if (modelResult != null) {
            let isDeferred = false;
            let promises = [];
            let pendingResult = {};
            let isSingleMainPromise = false;
            if (isPromiseCompatible(modelResult)) {
                isDeferred = true;
                isSingleMainPromise = true;
                promises.push({
                    key: "",
                    promise: modelResult
                });
            }
            else if (typeof modelResult === "object") {
                if (Object.values(modelResult).some(v => isPromiseCompatible(v))) {
                    isDeferred = true;
                    for (let [key, value] of Object.entries(modelResult)) {
                        if (isPromiseCompatible(value)) {
                            promises.push({
                                key: key,
                                promise: value
                            });
                        }
                        else {
                            pendingResult[key] = value;
                        }
                    }
                }
            }
            if (isDeferred) {
                Promise.all(promises.map(i => i.promise)).then((promiseResults) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    let result = null;
                    if (isSingleMainPromise) {
                        result = promiseResults[0];
                    }
                    else {
                        for (let i = 0; i < promises.length; i++) {
                            const item = promises[i];
                            if (i < promiseResults.length) {
                                pendingResult[item.key] = promiseResults[i];
                            }
                        }
                        result = pendingResult;
                    }

                    this.privates.modelFulfilled = true;
                    this.privates.modelResolved = true;
                    if (typeof this.parseModel === "function") {
                        this.privates.model = this.parseModel(result);
                        if (this.privates.model == null && process.env.NODE_ENV === "development") {
                            // eslint-disable-next-line
                            console.warn(`The return value of \`parseModel\` is ${this.privates.model}! Are you forgetting to return the parsed value?`);
                        }
                    }
                    else {
                        this.privates.model = result;
                    }
                    this.privates.modelError = null;
                    this.privates.modelErrorMsg = null;
                    this.trigger("onModelResolved", this.privates.model, {
                        isReloading: isModelReloading,
                        args: modelReloadingArgs
                    });
                    if (!this.privates.asyncModelInitialized && this.autoLoad) {
                        startLoadingAsyncModel.call(this, false, []);
                    }
                    else if (this.privates.isAsyncModelReloadDelayed) {
                        this.actions.reloadAsyncModel(true);
                    }
                    this.forceUpdate(() => {
                        if (this.privates.componentDidMountDelayed) {
                            this.privates.componentDidMountDelayed = false;
                            this.componentDidMount();
                        }
                    });
                }, (reason) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    this.privates.modelFulfilled = true;
                    this.privates.modelResolved = false;
                    this.privates.modelError = reason;
                    this.privates.modelErrorMsg = this.getModelErrorMsg(reason);
                    if (reason instanceof Error) {
                        try {
                            this.trigger("onModelErrorCatched", reason, {
                                isReloading: isModelReloading,
                                args: modelReloadingArgs
                            });
                        }
                        finally {
                            ToolkitsUdesk.trigger("onModelPromiseCatched", null, [
                                reason,
                                {
                                    source: this
                                }
                            ]);
                        }
                    }
                    this.trigger("onModelRejected", this.privates.modelError, {
                        isReloading: isModelReloading,
                        args: modelReloadingArgs
                    });
                    this.forceUpdate(() => {
                        if (this.privates.componentDidMountDelayed) {
                            this.privates.componentDidMountDelayed = false;
                            this.componentDidMount();
                        }
                    });
                }).finally(() => {
                    this.privates[IS_MODEL_RELOADING_SYMBOL] = false;
                    this.privates[MODEL_RELOADING_ARGS_SYMBOL] = [];
                });

                // Render loading animation when needed.
                let propsWithDefaults = getDefaultPropsValues(this);
                let modelLoadingAnimation = propsWithDefaults.modelLoadingAnimation;
                if (modelLoadingAnimation && (!isModelReloading || this.showModelReloadAnimation)) {
                    let animationProps = propsWithDefaults.modelLoadingAnimationProps;
                    if (modelLoadingAnimation === true) {
                        return [CANCEL_ACTION_SYMBOL, (
                            <LoadingAnimation component={LoadingAnimationComponent} animationProps={animationProps} />
                        )];
                    }
                    else if (typeof modelLoadingAnimation === "string") {
                        return [CANCEL_ACTION_SYMBOL, (
                            <p className={["component-model-loading-text", animationProps.align, animationProps.verticalAlign].filter(Boolean).join(" ")}>
                                <p className="text-content">
                                    {modelLoadingAnimation}
                                </p>
                            </p>)
                        ];
                    }
                    else if (typeof modelLoadingAnimation === "function" || modelLoadingAnimation instanceof React.Component) {
                        return [CANCEL_ACTION_SYMBOL, (
                            <LoadingAnimation component={modelLoadingAnimation} animationProps={animationProps} />
                        )];
                    }
                }
                else if (isModelReloading) {
                    // No need cancel render, just continue render for the first load.
                    return undefined;
                }
                else {
                    // Cancel the render result, and return nothing when `modelLoadingAnimation` is false.
                    return [CANCEL_ACTION_SYMBOL, null];
                }
            }
            else {
                this.privates.model = modelResult;
                this.privates.modelFulfilled = true;
                this.privates.modelResolved = true;
                this.privates.modelError = null;
                this.privates.modelErrorMsg = null;
                this.trigger("onModelResolved", this.privates.model, {
                    isReloading: isModelReloading,
                    args: modelReloadingArgs
                });

                this.privates[IS_MODEL_RELOADING_SYMBOL] = false;
                this.privates[MODEL_RELOADING_ARGS_SYMBOL] = [];

                // No need cancel render, just continue render for the first load.
                return undefined;
            }
        }
        else {
            this.privates.model = modelResult;
            this.privates.modelFulfilled = true;
            this.privates.modelResolved = true;
            this.privates.modelError = null;
            this.privates.modelErrorMsg = null;
            // No need cancel render, just continue render for the first load.
            return undefined;
        }
    }
    else {
        this.privates.model = this.model;
        this.privates.modelFulfilled = true;
        this.privates.modelResolved = true;
        this.privates.modelError = null;
        this.privates.modelErrorMsg = null;

        this.privates[IS_MODEL_RELOADING_SYMBOL] = false;
        this.privates[MODEL_RELOADING_ARGS_SYMBOL] = [];

        // No need cancel render, just continue render for the first load.
        return undefined;
    }
}

function isPromiseCompatible(possiblePromise) {
    return possiblePromise && (possiblePromise instanceof Promise || typeof possiblePromise.then === "function");
}

function startLoadingAsyncModel(isReloading, [...args]) {
    this.privates.asyncModelInitialized = true;
    if (typeof this.asyncModel === "function") {
        let asyncModelResult = this.asyncModel.apply(this, args);
        if (asyncModelResult != null) {
            let isDeferred = false;
            let promises = [];
            let pendingResult = {};
            let isSingleMainPromise = false;
            if (isPromiseCompatible(asyncModelResult)) {
                isDeferred = true;
                isSingleMainPromise = true;
                promises.push({
                    key: "",
                    promise: asyncModelResult
                });
            }
            else if (typeof asyncModelResult === "object") {
                if (Object.values(asyncModelResult).some(v => isPromiseCompatible(v))) {
                    isDeferred = true;
                    for (let [key, value] of Object.entries(asyncModelResult)) {
                        if (isPromiseCompatible(value)) {
                            promises.push({
                                key: key,
                                promise: value
                            });
                        }
                        else {
                            pendingResult[key] = value;
                        }
                    }
                }
            }
            if (isDeferred) {
                this.privates.asyncModel = pendingResult;
                let resolvedCounter = 0;
                for (let i = 0; i < promises.length; i++) {
                    let { key, promise } = promises[i];
                    // disable rule `no-loop-func` temporarily.
                    // eslint-disable-next-line
                    promise.then((resp) => {
                        resolvedCounter++;
                        if (this.isDestroyed) {
                            return;
                        }

                        this.privates.asyncModelFulfilled = true;
                        this.privates.asyncModelResolved = true;

                        let result = null;
                        if (isSingleMainPromise) {
                            result = resp;
                        }
                        else {
                            result = { ...this.privates.asyncModel, [key]: resp };
                        }
                        let parseOptions = {
                            isMainPromise: isSingleMainPromise,
                            isFirstAsync: (resolvedCounter === 1),
                            isLastAsync: (resolvedCounter === promises.length),
                            asyncKey: key,
                            asyncResult: resp,
                        };
                        this.privates.asyncModel = innerParseAsyncModel.call(this, result, parseOptions);
                        if (this.privates.asyncModel == null && process.env.NODE_ENV === "development") {
                            // eslint-disable-next-line
                            console.warn("The return value of `parseAsyncModel` is null! Are you forgetting to return the parsed value?");
                        }
                        // No need to set asyncModelError in success callback.
                        // this.privates.asyncModelError = null;
                        // this.privates.asyncModelErrorMsg = null;

                        this.trigger("onAsyncModelResolved", this.privates.asyncModel, parseOptions);
                        this.forceUpdate();
                        // disable rule `no-loop-func` temporarily.
                        // eslint-disable-next-line
                    }, (reason) => {
                        resolvedCounter++;
                        if (this.isDestroyed) {
                            return;
                        }
                        this.privates.asyncModelFulfilled = true;

                        // No need to set asyncModelResolved in error callback. If any of promises successes,
                        // asyncModelResolved should keep true all the time; otherwise the default value `false`
                        // will keep no changed all the time.
                        // this.privates.asyncModelResolved = false;

                        this.privates.asyncModelError = reason;
                        this.privates.asyncModelErrorMsg = this.getModelErrorMsg(reason);
                        if (!isSingleMainPromise) {
                            this.privates.asyncModel[key + "Error"] = this.privates.asyncModelError;
                            this.privates.asyncModel[key + "ErrorMsg"] = this.privates.asyncModelErrorMsg;

                            let isLastAsync = (resolvedCounter === promises.length);
                            if (isLastAsync) {
                                let parseOptions = {
                                    isMainPromise: false,
                                    isFirstAsync: false,
                                    isLastAsync: true,
                                    asyncKey: key,
                                    asyncResult: null,
                                };
                                let result = { ...this.privates.asyncModel };
                                this.privates.asyncModel = innerParseAsyncModel.call(this, result, parseOptions);
                            }
                        }

                        this.trigger("onAsyncModelRejected", reason, key);
                        this.forceUpdate();
                    });
                }
            }
            else {
                this.privates.asyncModel = innerParseAsyncModel.call(this, asyncModelResult);
                this.privates.asyncModelFulfilled = true;
                this.privates.asyncModelResolved = true;
                this.privates.asyncModelError = null;
                this.privates.asyncModelErrorMsg = null;
                this.forceUpdate();
            }
        }
    }
    else {
        this.privates.asyncModel = this.asyncModel;
        this.privates.asyncModelFulfilled = true;
        this.privates.asyncModelResolved = true;
        this.privates.asyncModelError = null;
        this.privates.asyncModelErrorMsg = null;
        if (isReloading) {
            this.forceUpdate();
        }
    }
}

function innerParseAsyncModel(result, ...args) {
    if (typeof this.parseAsyncModel === "function") {
        return this.parseAsyncModel(...arguments);
    }
    else {
        return result;
    }
}

function resetAsyncModelAll(component) {
    component.privates.asyncModel = undefined;
    resetAsyncModelStatus(component);
}

function resetAsyncModelStatus(component) {
    component.privates.asyncModelFulfilled = false;
    component.privates.asyncModelResolved = false;
    component.privates.asyncModelError = null;
    component.privates.asyncModelErrorMsg = null;
}

function getModelErrorMsg(modelError) {
    if (modelError instanceof Error) {
        return this.locales.labels.loadModelError;
    }
    else if (modelError) {
        return modelError.errorMsg || modelError.message || this.locales.labels.loadModelError;
    }
    return this.locales.labels.loadModelError;
}

function getDefaultPropsValues(instance) {
    return Object.assign({}, COMPONENT_DEFAULT_PROPS, instance.props);
}

function LoadingAnimation(props) {
    let {
        component: Component,
        animationProps
    } = props;
    return (
        <div className="component-model-loading-animation">
            <div className="loading-animation-container">
                <Component {...(animationProps || {})} />
            </div>
        </div>
    );
}

function ModelError(props) {
    let {
        errorMsg,
        animationProps
    } = props;
    return (<div className={["component-model-error", animationProps.align, animationProps.verticalAlign].filter(Boolean).join(" ")}>
        <p className="text-content">
            {errorMsg}
        </p>
    </div>);
}

function extendClass(componentClass) {
    if (componentClass[UDESKIFIED_FLAG_SYMBOL] == null) {
        Object.defineProperty(componentClass, UDESKIFIED_FLAG_SYMBOL, {
            value: true,
            writable: false,
            enumerable: false,
            configurable: false
        });

        createContext(componentClass);

        mergeStaticFields(componentClass);

        defineDefaultValues(componentClass, {
            SAVED_STATE_KEYS: [],
        });
        buildComputeDependKeys(componentClass);
        buildComputeExecutionPlan(componentClass);
        buildObserverDependKeys(componentClass);
        buildObserverExecutionPlan(componentClass);

        makeReadonly(componentClass, ["computes", "observers"]);

        if (componentClass.propTypes == null) {
            componentClass.propTypes = {};
        }
        if (componentClass.defaultProps == null) {
            componentClass.defaultProps = {};
        }
        defineDefaultValues(componentClass.propTypes, COMPONENT_PROP_TYPES);
        defineDefaultValues(componentClass.defaultProps, COMPONENT_DEFAULT_PROPS);
        patchLifeCycle(componentClass, "getDerivedStateFromProps", function (originalLifeCycle, args) {
            let [
                nextProps,
                prevState,
            ] = args;
            let owner = prevState[OWNER_SYMBOL];
            return beforePropsChangedHook(owner, componentClass, nextProps, prevState, originalLifeCycle);
        });

        initClassReduxFeatures(componentClass);
    }
}

function createContext(ComponentClass) {
    ComponentClass.contextType = React.createContext(Context.enableDefaultContext ? Context.defaults : undefined);
}

function beforePropsChangedHook(instance, ComponentClass, nextProps, prevState, originalLifeCycle) {
    let isInitial = instance.privates[IS_INITIAL_SYMBOL];
    let propNames = getDirtyPropNames(instance);
    let hasDirtyProps = false;
    if (propNames && propNames.length > 0) {
        for (let name of propNames) {
            let isDirty = !isInitial && !instance.isPropEqual(name, get(nextProps, name), get(instance.props, name));
            instance.privates.dirtyProps[name] = isDirty;
            hasDirtyProps |= isDirty;
        }
    }
    // Call the original life cycle method.
    let newState = prevState;
    if (originalLifeCycle) {
        newState = originalLifeCycle.apply(ComponentClass, [nextProps, prevState]);
    }
    // Call parseProps life cycle.
    let getPrivatesFromProps = instance.getPrivatesFromProps || instance.parseProps;
    if (typeof getPrivatesFromProps === "function") {
        if (isInitial || hasDirtyProps) {
            let partialPrivates = getPrivatesFromProps.call(instance, {
                props: nextProps,
                prevProps: instance.props,
                state: newState,
                privates: instance.privates,
                isInitial: isInitial,
            });
            Object.assign(instance.privates, partialPrivates);
        }
    }
    if (typeof instance.getStateFromProps === "function") {
        let derivedStates = instance.getStateFromProps({
            props: nextProps,
            prevProps: instance.props,
            state: newState,
            privates: instance.privates,
            isInitial: isInitial,
        });
        Object.assign(newState, derivedStates);
    }
    // Calculate computes
    calculateComputes.call(instance, ComponentClass, {
        props: nextProps,
        state: newState,
    });
    return newState;
}

function patchLifeCycle(context, lifeCycleName, callback) {
    let bakHandler = context[lifeCycleName];
    context[lifeCycleName] = function (...args) {
        return callback.apply(this, [(typeof bakHandler === "function" ? bakHandler : null), args]);
    };
}

function initClassReduxFeatures(componentClass) {
    let reduxEnabled = !!componentClass.reduxReducers;
    if (reduxEnabled) {
        componentClass[REDUX_ENABLED_SYMBOL] = true;
    }
    if (reduxEnabled) {
        // Add static redux key for the class
        let classReduxKey = `${(StaticReduxKeyId++).toString().padStart(4, "0")} Class:${componentClass.name}`;
        // Generate full redux key for store.
        componentClass.generateReduxKey = (instanceReduxKey) => {
            return classReduxKey + ` ReduxKey:${instanceReduxKey || "GLOBAL"}`;
        };
        componentClass[STATIC_REDUX_KEY_SYMBOL] = componentClass.generateReduxKey();
        registerGlobalReducers(componentClass);
    }
}

function restoreStorage(component) {
    let {
        enableStorage,
        _storageKey,
        storageStrategies,
    } = component;
    if (!enableStorage || !storageStrategies) return;

    validateStorageKey(_storageKey, `storageStrategies._storageKey in ${component["[DebugName]"]}`);
    let storageKey = getStorageKey(component.props.route, component["[DebugName]"], _storageKey);
    if (typeof storageStrategies === "string" || (typeof storageStrategies === "object" && typeof storageStrategies.storageWay === "string")) {
        let strategy = parseStrategy(storageStrategies,
            `storageStrategies in ${component["[DebugName]"]}`,
            `storageStrategies.extraStorages in ${component["[DebugName]"]}`,
            `storageStrategies.restoreStrategies in ${component["[DebugName]"]}`,
            `storageStrategies.resetStrategies in ${component["[DebugName]"]}`);

        if (!needRestoreStorage(component, storageKey, strategy)) {
            return;
        }

        if (strategy.storageWay === "memory") {
            let content = component.constructor[MEMORY_STORAGE_SYMBOL];
            if (content.storages) component.privates.storages = content.storages;
            if (content.state) {
                for (let [key, value] of Object.entries(content.state)) {
                    component.state[key] = value;
                }
            }
            if (content.privates) {
                for (let [key, value] of Object.entries(content.privates)) {
                    component.privates[key] = value;
                }
            }
        } else if (strategy.storageWay === "sessionStorage" && ToolkitsUdesk.browser.storage.sessionStorage.available) {
            try {
                let content = ToolkitsUdesk.browser.storage.sessionStorage.getItem(storageKey);
                if (content) {
                    let sessionStorage = JSON.parse(content);
                    if (sessionStorage.storages) component.privates.storages = sessionStorage.storages;
                    if (sessionStorage.state) {
                        for (let [key, value] of Object.entries(sessionStorage.state)) {
                            component.state[key] = value;
                        }
                    }
                    if (sessionStorage.privates) {
                        for (let [key, value] of Object.entries(sessionStorage.privates)) {
                            component.privates[key] = value;
                        }
                    }
                }
            } catch (err) {
                // 如果转化错误，应该默默忽略，而不是报错。因为storage中的数据可能会被用户改动，不完全可靠
            }
        } else if (strategy.storageWay === "localStorage" && ToolkitsUdesk.browser.storage.localStorage.available) {
            try {
                let content = ToolkitsUdesk.browser.storage.localStorage.getItem(storageKey);
                if (content) {
                    let localStorage = JSON.parse(content);
                    if (localStorage.storages) component.privates.storages = localStorage.storages;
                    if (localStorage.state) {
                        for (let [key, value] of Object.entries(localStorage.state)) {
                            component.state[key] = value;
                        }
                    }
                    if (localStorage.privates) {
                        for (let [key, value] of Object.entries(localStorage.privates)) {
                            component.privates[key] = value;
                        }
                    }
                }
            } catch (err) {
                // 如果转化错误，应该默默忽略，而不是报错。因为storage中的数据可能会被用户改动，不完全可靠
            }
        }
    } else if (typeof storageStrategies === "object") {
        let memoryStorageData = {};
        let sessionStorageData = {};
        let localStorageData = {};

        if (component.constructor[MEMORY_STORAGE_SYMBOL]) {
            memoryStorageData = component.constructor[MEMORY_STORAGE_SYMBOL];
        }
        try {
            let content = ToolkitsUdesk.browser.storage.sessionStorage.getItem(storageKey);
            if (content) {
                sessionStorageData = JSON.parse(content);
            }
            content = ToolkitsUdesk.browser.storage.localStorage.getItem(storageKey);
            if (content) {
                localStorageData = JSON.parse(content);
            }
        } catch (err) {
            // 如果转化错误，应该默默忽略，而不是报错。因为storage中的数据可能会被用户改动，不完全可靠
        }
        for (let [key, value] of Object.entries(storageStrategies)) {
            let strategy = parseStrategy(value,
                `storageStrategies in ${component["[DebugName]"]}`,
                `storageStrategies.extraStorages in ${component["[DebugName]"]}`,
                `storageStrategies.restoreStrategies in ${component["[DebugName]"]}`,
                `storageStrategies.resetStrategies in ${component["[DebugName]"]}`);

            if (!needRestoreStorage(component, storageKey, strategy)) return;

            let storageTarget = null;
            if (strategy.storageWay === "memory") {
                storageTarget = memoryStorageData;
            } else if (strategy.storageWay === "sessionStorage") {
                storageTarget = sessionStorageData;
            } else if (strategy.storageWay === "localStorage") {
                storageTarget = localStorageData;
            }

            if (storageTarget != null) {
                if (key.startsWith("state.") || key.startsWith("privates.")) {
                    let stateValue = get(storageTarget, key);
                    if (stateValue) {
                        set(component, key, stateValue);
                    }
                } else {
                    let storagesValue = get(storageTarget, "storages." + key);
                    if (storagesValue) {
                        set(component, "privates.storages." + key, storagesValue);
                    }
                }
            }
        }
    }
}

function serializeStorage(component) {
    let {
        state,
        privates,
        enableStorage,
        _storageKey,
        storageStrategies,
    } = component;
    let {
        storages,
    } = component.privates;
    if (!enableStorage || !storageStrategies) return;

    let copy = {
        state,
        privates,
        storages,
    };
    validateStorageKey(_storageKey, `storageStrategies._storageKey in ${component["[DebugName]"]}`);
    let storageKey = getStorageKey(component.props.route, component["[DebugName]"], _storageKey);
    if (typeof storageStrategies === "string" || (typeof storageStrategies === "object" && typeof storageStrategies.storageWay === "string")) {
        let strategy = parseStrategy(storageStrategies,
            `storageStrategies in ${component["[DebugName]"]}`,
            `storageStrategies.extraStorages in ${component["[DebugName]"]}`,
            `storageStrategies.restoreStrategies in ${component["[DebugName]"]}`,
            `storageStrategies.resetStrategies in ${component["[DebugName]"]}`);

        // 准备存储数据
        let serializedStorage = {
            storages: copy.storages,
        };
        for (let i = 0; i < strategy.extraStorages.length; i++) {
            let key = strategy.extraStorages[i];
            let value = get(copy, key);
            if (value != null) {
                set(serializedStorage, key, value);
            }
        }
        if (needResetStorage(component, strategy, window.location.pathname)) {
            serializedStorage = null;
        }

        // 清空之前的存储
        component.constructor[MEMORY_STORAGE_SYMBOL] = {};
        if (ToolkitsUdesk.browser.storage.sessionStorage.available) {
            ToolkitsUdesk.browser.storage.sessionStorage.removeItem(storageKey);
        }
        if (ToolkitsUdesk.browser.storage.localStorage.available) {
            ToolkitsUdesk.browser.storage.localStorage.removeItem(storageKey);
        }

        // 存储数据
        if (serializedStorage != null) {
            if (strategy.storageWay === "memory") {
                component.constructor[MEMORY_STORAGE_SYMBOL] = serializedStorage;
            } else if (strategy.storageWay === "sessionStorage" && ToolkitsUdesk.browser.storage.sessionStorage.available) {
                ToolkitsUdesk.browser.storage.sessionStorage.setItem(storageKey, JSON.stringify(serializedStorage));
            } else if (strategy.storageWay === "localStorage" && ToolkitsUdesk.browser.storage.localStorage.available) {
                ToolkitsUdesk.browser.storage.localStorage.setItem(storageKey, JSON.stringify(serializedStorage));
            }
        }
    } else if (typeof storageStrategies === "object") {
        let memoryStorageData = {};
        let sessionStorageData = {};
        let localStorageData = {};

        // 准备存储数据
        for (let [key, value] of Object.entries(storageStrategies)) {
            let strategy = parseStrategy(value,
                `storageStrategies in ${component["[DebugName]"]}`,
                `storageStrategies.extraStorages in ${component["[DebugName]"]}`,
                `storageStrategies.restoreStrategies in ${component["[DebugName]"]}`,
                `storageStrategies.resetStrategies in ${component["[DebugName]"]}`);

            let storageTarget = null;
            if (strategy.storageWay === "memory") {
                storageTarget = memoryStorageData;
            } else if (strategy.storageWay === "sessionStorage") {
                storageTarget = sessionStorageData;
            } else if (strategy.storageWay === "localStorage") {
                storageTarget = localStorageData;
            }

            if (storageTarget != null && !needResetStorage(component, strategy, window.location.pathname)) {
                if (key.startsWith("state.") || key.startsWith("privates.")) {
                    let stateValue = get(copy, key);
                    if (stateValue != null) {
                        set(storageTarget, key, stateValue);
                        storageTarget.hasData = true;
                    }
                } else {
                    let storagesValue = get(copy, "storages." + key);
                    if (storagesValue != null) {
                        set(storageTarget, "storages." + key, storagesValue);
                        storageTarget.hasData = true;
                    }
                }
            }
        }

        // 清空之前的存储
        component.constructor[MEMORY_STORAGE_SYMBOL] = {};
        if (ToolkitsUdesk.browser.storage.sessionStorage.available) {
            ToolkitsUdesk.browser.storage.sessionStorage.removeItem(storageKey);
        }
        if (ToolkitsUdesk.browser.storage.localStorage.available) {
            ToolkitsUdesk.browser.storage.localStorage.removeItem(storageKey);
        }

        // 存储数据
        if (memoryStorageData.hasData) {
            delete memoryStorageData.hasData;
            component.constructor[MEMORY_STORAGE_SYMBOL] = memoryStorageData;
        }
        if (sessionStorageData.hasData && ToolkitsUdesk.browser.storage.sessionStorage.available) {
            delete sessionStorageData.hasData;
            ToolkitsUdesk.browser.storage.sessionStorage.setItem(storageKey, JSON.stringify(sessionStorageData));
        }
        if (localStorageData.hasData && ToolkitsUdesk.browser.storage.localStorage.available) {
            delete localStorageData.hasData;
            ToolkitsUdesk.browser.storage.localStorage.setItem(storageKey, JSON.stringify(localStorageData));
        }
    }
}

function getStorageKey(currentRoute, componentName, storageKey) {
    return currentRoute ? "[Storages]:route:" + (storageKey ? storageKey : currentRoute.name) : "[Storages]:component:" + (storageKey ? storageKey : componentName);
}

function parseStrategy(value, storageWayErrorLocation, extraStoragesErrorLocation, restoreStrategiesErrorLocation, resetStrategiesErrorLocation) {
    if (typeof value === "string") {
        validateStorageWay(value, storageWayErrorLocation);
        return {
            storageWay: value,
            extraStorages: [],
            restoreStrategies: function () {
                return true;
            },
            resetStrategies: function () {
                return false;
            },
        };
    } else if (typeof value === "object") {
        validateStorageWay(value.storageWay, storageWayErrorLocation);
        validateExtraStorages(value.extraStorages, extraStoragesErrorLocation);
        validateRestoreStrategy(value.restoreStrategies, restoreStrategiesErrorLocation);
        validateResetStrategy(value.resetStrategies, resetStrategiesErrorLocation);
        return {
            storageWay: value.storageWay,
            extraStorages: value.extraStorages ? value.extraStorages.filter((key) => (key.startsWith("state.") || key.startsWith("privates."))) : [],
            restoreStrategies: value.restoreStrategies,
            resetStrategies: value.resetStrategies,
        };
    } else {
        return {
            storageWay: "memory",
            extraStorages: [],
            restoreStrategies: function () {
                return true;
            },
            resetStrategies: function () {
                return false;
            },
        };
    }
}

function validateStorageWay(storageWay, errorLocation) {
    if (storageWay != null && storageWay !== "sessionStorage" && storageWay !== "localStorage" && storageWay !== "memory") {
        throw new Error(`The property ${errorLocation} is not a valid value. All possible values are: ${STORAGE_WAY_TYPES.join(", ")}.`);
    }
}

function validateStorageKey(storageKey, errorLocation) {
    if (storageKey != null && (typeof storageKey !== "string" || storageKey.trim() === "")) {
        throw new Error(`The property ${errorLocation} must be a not empty string.`);
    }
}

function validateExtraStorages(extraStorages, errorLocation) {
    if (extraStorages) {
        if (!Array.isArray(extraStorages)) {
            throw new Error(`The property ${errorLocation} must be an array.`);
        }
        if (!extraStorages.every(route => typeof route === "string")) {
            throw new Error(`The values in the property ${errorLocation} must be all strings.`);
        }
    }
}

function validateRestoreStrategy(restoreStrategies, errorLocation) {
    if (restoreStrategies != null && typeof restoreStrategies !== "function") {
        throw new Error(`The property ${errorLocation} must be a function with boolean result.`);
    }
}

function validateResetStrategy(resetStrategies, errorLocation) {
    if (resetStrategies != null) {
        if (resetStrategies.transitionToRoutes) {
            if (!Array.isArray(resetStrategies.transitionToRoutes)) {
                throw new Error(`The property ${errorLocation} must be an array.`);
            }
            if (!resetStrategies.transitionToRoutes.every(routeReg => {
                try {
                    new RegExp(routeReg);
                    return true;
                } catch (e) {
                    return false;
                }
            })) {
                throw new Error(`The values in the property ${errorLocation} must be all regular expression.`);
            }
        }
        if (resetStrategies.notTransitionToRoutes) {
            if (!Array.isArray(resetStrategies.notTransitionToRoutes)) {
                throw new Error(`The property ${errorLocation} must be an array.`);
            }
            if (!resetStrategies.notTransitionToRoutes.every(routeReg => {
                try {
                    new RegExp(routeReg);
                    return true;
                } catch (e) {
                    return false;
                }
            })) {
                throw new Error(`The values in the property ${errorLocation} must be all all regular expression.`);
            }
        }
    }
}

function needRestoreStorage(component, storageKey, strategy) {
    let storages = {};
    if (strategy.storageWay === "memory") {
        storages = component.constructor[MEMORY_STORAGE_SYMBOL];
    } else if (strategy.storageWay === "sessionStorage" && ToolkitsUdesk.browser.storage.sessionStorage.available) {
        try {
            storages = ToolkitsUdesk.browser.storage.sessionStorage.getItem(storageKey);
        } catch (err) {
            // 如果转化错误，应该默默忽略，而不是报错。因为storage中的数据可能会被用户改动，不完全可靠
        }
    } else if (strategy.storageWay === "localStorage" && ToolkitsUdesk.browser.storage.localStorage.available) {
        try {
            storages = ToolkitsUdesk.browser.storage.localStorage.getItem(storageKey);
        } catch (err) {
            // 如果转化错误，应该默默忽略，而不是报错。因为storage中的数据可能会被用户改动，不完全可靠
        }
    }

    let result = strategy.restoreStrategies && strategy.restoreStrategies.call(component, storages);
    if (typeof result === "boolean") {
        return result;
    } else {
        return true;
    }
}

function needResetStorage(component, strategy, targetRouteName) {
    if (typeof strategy.resetStrategies === "function") {
        let result = strategy.resetStrategies.call(component, targetRouteName);
        if (typeof result === "boolean") {
            return result;
        } else {
            return false;
        }
    } else {
        return ((strategy.resetStrategies && strategy.resetStrategies.transitionToRoutes && strategy.resetStrategies.transitionToRoutes.length > 0 && strategy.resetStrategies.transitionToRoutes.some(s => new RegExp(s).test(targetRouteName))) ||
            (strategy.resetStrategies && strategy.resetStrategies.notTransitionToRoutes && strategy.resetStrategies.notTransitionToRoutes.length > 0 && strategy.resetStrategies.notTransitionToRoutes.every(s => !(new RegExp(s).test(targetRouteName)))));
    }
}
//#endregion

//#region isUdeskified
function isUdeskified(component) {
    return (component && !!component[UDESKIFIED_FLAG_SYMBOL]);
}
//#endregion

function getRouteName(context) {
    let route = context.props.route;
    if (route && route.path != null && route.path !== "") {
        return route.path.split("/").filter(item => item !== "" && item !== "/" && !(/:/.test(item))).join(".");
    }
    return context.constructor.name;
}
