// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetReviewSubscribesByCallIdParam,
    BaseResponseSubscribeStatusResponse,
    PostReviewSubscribesByCallIdParam,
    BaseResponseVoid,
    DeleteReviewSubscribesByCallIdParam,
} from '../../../types';

/**
 * findById
 *
 * @export
 * @tags subscribe-controller
 * @link [GET] /review/subscribes/{callId}
 * @param callId
 */
export function getReviewSubscribesByCallId(
    options: ApiOptions<GetReviewSubscribesByCallIdParam, never> & {
        segments: GetReviewSubscribesByCallIdParam;
    }
): Promise<BaseResponseSubscribeStatusResponse> {
    return request<never, GetReviewSubscribesByCallIdParam, never>('/review/subscribes/{callId}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewSubscribesByCallId',
    });
}

/**
 * save
 *
 * @export
 * @tags subscribe-controller
 * @link [POST] /review/subscribes/{callId}
 * @param callId
 */
export function postReviewSubscribesByCallId(
    options: ApiOptions<PostReviewSubscribesByCallIdParam, never> & {
        segments: PostReviewSubscribesByCallIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PostReviewSubscribesByCallIdParam, never>('/review/subscribes/{callId}', {
        ...options,
        method: 'post',
        __$requestCalleeName: 'postReviewSubscribesByCallId',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags subscribe-controller
 * @link [DELETE] /review/subscribes/{callId}
 * @param callId
 */
export function deleteReviewSubscribesByCallId(
    options: ApiOptions<DeleteReviewSubscribesByCallIdParam, never> & {
        segments: DeleteReviewSubscribesByCallIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReviewSubscribesByCallIdParam, never>(
        '/review/subscribes/{callId}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteReviewSubscribesByCallId' }
    );
}

export const meta = [
    { tags: ['subscribe-controller'], path: '/review/subscribes/{callId}', method: 'get' },
    { tags: ['subscribe-controller'], path: '/review/subscribes/{callId}', method: 'post' },
    { tags: ['subscribe-controller'], path: '/review/subscribes/{callId}', method: 'delete' },
];
