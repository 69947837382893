// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetSaleSettingBaseInfosQuery,
    BaseResponseListSaleSettingBaseInfoFoundResponse,
    PostSaleSettingBaseInfosBody,
    BaseResponseSaleSettingBaseInfoFoundResponse,
} from '../types';

/**
 * 获取销售配置详情-分页
 *
 * @export
 * @tags 销售赋能-销售阶段配置
 * @link [GET] /saleSettingBaseInfos
 */
export function getSaleSettingBaseInfos(): Promise<BaseResponseListSaleSettingBaseInfoFoundResponse>;
export function getSaleSettingBaseInfos(
    options: ApiOptions<never, GetSaleSettingBaseInfosQuery>
): Promise<BaseResponseListSaleSettingBaseInfoFoundResponse>;
export function getSaleSettingBaseInfos(
    options?: ApiOptions<never, GetSaleSettingBaseInfosQuery>
): Promise<BaseResponseListSaleSettingBaseInfoFoundResponse> {
    return request<never, never, GetSaleSettingBaseInfosQuery>('/saleSettingBaseInfos', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSaleSettingBaseInfos',
    });
}

/**
 * 新增销售配置
 *
 * @export
 * @tags 销售赋能-销售阶段配置
 * @link [POST] /saleSettingBaseInfos
 */
export function postSaleSettingBaseInfos(
    data: PostSaleSettingBaseInfosBody
): Promise<BaseResponseSaleSettingBaseInfoFoundResponse>;
export function postSaleSettingBaseInfos(
    data: PostSaleSettingBaseInfosBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseSaleSettingBaseInfoFoundResponse>;
export function postSaleSettingBaseInfos(
    data: PostSaleSettingBaseInfosBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseSaleSettingBaseInfoFoundResponse> {
    return request<PostSaleSettingBaseInfosBody, never, never>('/saleSettingBaseInfos', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSaleSettingBaseInfos',
    });
}

export const meta = [
    { tags: ['销售赋能-销售阶段配置'], path: '/saleSettingBaseInfos', method: 'get' },
    { tags: ['销售赋能-销售阶段配置'], path: '/saleSettingBaseInfos', method: 'post' },
];
