// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerScoringPointsQuery,
    BaseResponseListIntelligentPartnerScoringPointFoundResponse,
} from '../../../types';

/**
 * 评分维度-查询评分点
 *
 * @export
 * @tags 智能陪练-评分模型
 * @link [GET] /intelligentPartner/scoring/points
 */
export function getIntelligentPartnerScoringPoints(): Promise<BaseResponseListIntelligentPartnerScoringPointFoundResponse>;
export function getIntelligentPartnerScoringPoints(
    options: ApiOptions<never, GetIntelligentPartnerScoringPointsQuery>
): Promise<BaseResponseListIntelligentPartnerScoringPointFoundResponse>;
export function getIntelligentPartnerScoringPoints(
    options?: ApiOptions<never, GetIntelligentPartnerScoringPointsQuery>
): Promise<BaseResponseListIntelligentPartnerScoringPointFoundResponse> {
    return request<never, never, GetIntelligentPartnerScoringPointsQuery>(
        '/intelligentPartner/scoring/points',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentPartnerScoringPoints' }
    );
}

export const meta = [
    { tags: ['智能陪练-评分模型'], path: '/intelligentPartner/scoring/points', method: 'get' },
];
