import Locales from '../locales/index';

let activeFlags = [
    { id: 1, key: "effiective", name: () => Locales.current.enums.activeFlags.effiective },
    { id: 0, key: "invalid", name: () => Locales.current.enums.activeFlags.invalid },
];
let labelTypes = [
    { id: 1, key: "dimensionLabel", name: () => Locales.current.enums.labelTypes.dimensionLabel },
    { id: 2, key: "classificationLabel", name: () => Locales.current.enums.labelTypes.classificationLabel },
];
let informationEntityTypes = [
    { id: 1, key: "system", name: () => Locales.current.enums.informationEntityTypes.system },
    { id: 2, key: "custom", name: () => Locales.current.enums.informationEntityTypes.custom },
    { id: 3, key: "moduleCompany", name: () => Locales.current.enums.informationEntityTypes.moduleCompany },
];
let organizationTypes = [
    { id: 2, key: "null", name: () => Locales.current.enums.organizationTypes.null },
    { id: 3, key: "moduleCompany", name: () => Locales.current.enums.organizationTypes.moduleCompany },
];
let interactiveQuestionTypes = [
    { id: 1, key: "standard", name: () => Locales.current.enums.interactiveQuestionTypes.standard },
    { id: 2, key: "multiElement", name: () => Locales.current.enums.interactiveQuestionTypes.multiElement },
    { id: 3, key: "guideMultiRound", name: () => Locales.current.enums.interactiveQuestionTypes.guideMultiRound },
];

let targetTypes = [
    { id: 0, key: "all", name: () => Locales.current.enums.targetTypes.all },
    { id: 1, key: "customerService", name: () => Locales.current.enums.targetTypes.customerService },
    { id: 2, key: "customer", name: () => Locales.current.enums.targetTypes.customer },
];

let interactiveTypes = [
    { id: 1, key: "similarQList", name: () => Locales.current.enums.interactiveTypes.similarQList },
    { id: 2, key: "exceptSimilarQList", name: () => Locales.current.enums.interactiveTypes.exceptSimilarQList },
    { id: 3, key: "similarAList", name: () => Locales.current.enums.interactiveTypes.similarAList },
    { id: 4, key: "wrongAList", name: () => Locales.current.enums.interactiveTypes.wrongAList },
];

export default {
    activeFlags,
    informationEntityTypes,
    interactiveQuestionTypes,
    targetTypes,
    interactiveTypes,
    labelTypes,
    organizationTypes,
};
