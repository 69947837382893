import React, { useMemo, useEffect, useRef } from 'react';
import { getIntelligentPartnerByRecordIdEvaluation } from 'src/api/intelligentPartner/{recordId}/evaluation';
import styled from 'styled-components';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import { Empty } from 'udesk-ui';
import { useImmer } from 'use-immer';
import useResizeObserver from 'use-resize-observer';
import { useConfig } from './hooks';
import UdeskLocales from 'UdeskLocales';

const Wrap = styled.div`
    margin-top: 24px;
    overflow: hidden;

    > div {
        height: 300px;
    }
`;

const ComprehensiveEvaluation = React.memo((props: any) => {
    const { recordId, scoreOverView } = props;

    const { legend, radarOptionLegend, radarOptionTitle, seriesItemStyle } = useConfig();

    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const echartsRef = useRef<any>(null);

    const [data] = useImmer<any>({
        radarLegend: [],
        indicator: [],
        series: [],
    });
    const [radarOption, setRadarOption] = useImmer<any>(null);
    const radarSeriesColumns = useMemo(() => {
        return [
            {
                name: '',
                type: 'radar',
                data,
            },
        ];
    }, [data]);

    useEffect(() => {
        const token = requestIdleCallback(() => {
            echartsRef.current?.resize?.();
        });
        return () => {
            cancelIdleCallback(token);
        };
    }, [width, height]);
    useEffect(() => {
        Promise.all([
            getIntelligentPartnerByRecordIdEvaluation({
                segments: {
                    recordId,
                },
                params: {
                    isLastTime: 0,
                },
            }),
            getIntelligentPartnerByRecordIdEvaluation({
                segments: {
                    recordId,
                },
                params: {
                    isLastTime: 1,
                },
            }),
        ]).then(([thisTimeData, lastTimeData]) => {
            const [getThisTime, getLastTime] = legend.map((l) => getItem(l.areaStyleColor, l.name));

            const thisTimeEchartData = getEchartDataFromData(
                thisTimeData.data?.scoreDefaultDes,
                thisTimeData.data?.scorePointList,
                scoreOverView
            );
            const lastTimeEchartData = getEchartDataFromData(
                lastTimeData.data?.scoreDefaultDes,
                lastTimeData.data?.scorePointList,
                scoreOverView
            );
            // const indicator = [
            //     {
            //         // 雷达图的指示器，用来指定雷达图中的多个变量（维度）,跟data中 value 对应
            //         name: /* 内容完整 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.complete,
            //         // 指示器名称
            //         max: 100,
            //         // 指示器的最大值，可选，建议设置
            //         //color: '#fff' // 标签特定的颜色。
            //     },
            //     {
            //         name: /* 要点命中 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.keyHit,
            //         max: 100,
            //     },
            //     {
            //         name: /* 语言规范 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.languageNorm,
            //         max: 100,
            //     },
            //     {
            //         name: /* 表达流畅 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.fluentExpression,
            //         max: 100,
            //     },
            //     {
            //         name: /* 情感积极 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.comprehensiveEvaluation.index.emotionallyPositive,
            //         max: 100,
            //     },
            // ];
            const indicator =
                thisTimeData.data?.scorePointList?.map((i) => ({ name: i.name, max: 100 })) ?? [];

            let radarOption = {
                title: radarOptionTitle,
                color: legend.map((i) => i.color),
                legend: radarOptionLegend,
                tooltip: {
                    confine: true,
                    trigger: 'item',
                    formatter: function (params) {
                        let relVal = params.name;
                        let detail = thisTimeEchartData.detail;
                        if (relVal === legend[1].name) {
                            detail = lastTimeEchartData.detail;
                        }
                        relVal += indicator
                            .map((i, index) => {
                                const main =
                                    '<br/><strong>' +
                                    i.name +
                                    ': ' +
                                    params.data.value[index] +
                                    '%</strong>';
                                const sub = detail[index];
                                return main + '<br />' + sub;
                            })
                            .join('');
                        return relVal;
                    },
                },
                radar: [
                    {
                        name: {
                            // (圆外的标签)雷达图每个指示器名称的配置项。
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 14,
                                color: 'rgba(0, 0, 0, .65)',
                            },
                        },
                        nameGap: 15,
                        // 指示器名称和指示器轴的距离。[ default: 15 ]
                        splitNumber: 5,
                        // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
                        shape: 'polygon',
                        // 雷达图绘制类型，支持 'polygon'(多边形) 和 'circle'(圆)。[ default: 'polygon' ]
                        indicator,
                    },
                ],
                series: [
                    {
                        name: /* 雷达图 */ UdeskLocales['current'].pages.coach.learningCenter.record
                            .detail.components.comprehensiveEvaluation.index.radarChart,
                        // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
                        type: 'radar',
                        // 系列类型: 雷达图
                        itemStyle: seriesItemStyle,
                        data: [getThisTime(thisTimeEchartData.res)],
                        zlevel: 2,
                    },
                    {
                        name: /* 雷达图 */ UdeskLocales['current'].pages.coach.learningCenter.record
                            .detail.components.comprehensiveEvaluation.index.radarChart,
                        // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
                        type: 'radar',
                        // 系列类型: 雷达图
                        itemStyle: seriesItemStyle,
                        data: [getLastTime(lastTimeEchartData.res)],
                    },
                ],
            };
            setRadarOption(radarOption);
        });
    }, [recordId, scoreOverView]);
    return (
        <Wrap ref={ref}>
            {radarOption ? (
                <ReactEcharts
                    ref={echartsRef}
                    type="radar"
                    // areaStyle={areaStyle}
                    seriesColumns={radarSeriesColumns}
                    top={100}
                    rerenderOptimization={true}
                    finalizeChartOptions={() => radarOption}
                />
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Wrap>
    );
});

export { ComprehensiveEvaluation };

function getEchartDataFromData(data, scorePointList, scoreOverView) {
    let res: any[] = [];

    if (!data)
        return {
            res,
            detail: [],
        };
    // "totalFlows": 3,// 总流程数，对应type 1-内容完整
    // "finishFlows": 3,// 完成流程数，对应type 1-内容完整
    // "totalPoints": 5,// 总要点数， 对应type 2-要点命中-得分 或 3-要点命中-权重
    // "commitPoints": 3,// 完成要点数，对应type 2-要点命中-得分 或 3-要点命中-权重
    // "totalWordNum": null,// 总的话术字数，对应type 4-跟读准确
    // "commitWordNum": null,// 命中的话术字数，对应type 4-跟读准确
    // "violations": 0,// 违规次数，对应type 5-违规词
    // "violationDeduction": 0,// 违规扣除，对应type 5-违规词
    // "speedFastTimes": 0,// 语速过快次数，对应type 6-语速
    // "speedSlowTimes": 0,// 语速过慢次数，对应type 6-语速
    // "modalParticleTooMuchTimes": 0,// 语气词过多次数，对应type 7-语气词
    // "negativeEmotions": 0 // 消极情绪次数，对应type 8-情绪
    // scorePointList.forEach((point) => {
    //     switch (point.type) {
    //         case 1: {
    //             res.push(data.finishFlows / data.totalFlows);
    //             break;
    //         }
    //         case 3:
    //         case 2: {
    //             res.push(data.commitPoints / data.totalPoints);
    //             break;
    //         }
    //         case 4: {
    //             res.push(data.commitWordNum / data.totalWordNum);
    //             break;
    //         }
    //         case 5: {
    //             res.push(
    //                 Math.max(
    //                     0,
    //                     scoreOverView?.speechCraftScore?.totalScore -
    //                         data.violations * scoreOverView?.speechCraftScore?.violationDeduction
    //                 ) / scoreOverView?.speechCraftScore?.totalScore
    //             );
    //             break;
    //         }
    //         case 6: {
    //             res.push(
    //                 Math.max(
    //                     0,
    //                     scoreOverView?.speedScore?.totalScore -
    //                         data.speedFastTimes -
    //                         data.speedSlowTimes
    //                 ) / scoreOverView?.speedScore?.totalScore
    //             );
    //             break;
    //         }
    //         case 7: {
    //             res.push(
    //                 Math.max(
    //                     0,
    //                     scoreOverView?.speedScore?.totalScore - data.modalParticleTooMuchTimes
    //                 ) / scoreOverView?.speedScore?.totalScore
    //             );
    //             break;
    //         }
    //         case 8: {
    //             res.push(scoreOverView.emotionScore.score / scoreOverView.emotionScore.totalScore);
    //             break;
    //         }
    //     }
    // });
    // res.push(data.finishNodes / data.totalNodes);
    // res.push((10 - data.violations) / 10);
    // res.push(data.expressionScore / data.expressionTotalScore);
    // res.push(data.emotionScore / data.emotionTotalScore);

    res = scorePointList.map((p) => (p.percent === null ? 100 : p.percent) / 100);

    return {
        res: res.map((i) => (isNaN(i) ? 0 : (i * 100) >>> 0)),
        detail: scorePointList.reduce((res, point) => {
            switch (point.type) {
                case 1: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 完整流程数 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .spanStylemarginLeftpxNumberOfCompleteProcesses
                        }${data.finishNodes ?? data.finishFlows ?? '-'}/${
                            data.totalNodes ?? data.totalFlows ?? '-'
                        }</span>`
                    );
                    break;
                }
                case 3:
                case 2: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 命中要点数 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .spanStylemarginLeftpxNumberOfHitPoints
                        }${data.commitPoints ?? '-'}/${data.totalPoints ?? '-'}</span>`
                    );
                    break;
                }
                case 4: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 标准话术累积 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .spanStylemarginLeftpxStandardScriptAccumulation
                        }${data.totalWordNum}${
                            /* 个字，学员跟读命中的字数共 */ UdeskLocales['current'].pages.coach
                                .learningCenter.record.detail.components.comprehensiveEvaluation
                                .index.theTotalNumberOfWordsHitByTheStudentAfterReading
                        }${data.commitWordNum}${
                            /* 个字</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.aWordspan
                        }`
                    );
                    break;
                }
                case 5: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 有 */ UdeskLocales['current'].pages
                                .coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxYes
                        }${data.violations ?? '-'}${
                            /* 处违规词</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index
                                .dealingWithIllegalWordsspan
                        }`
                    );
                    break;
                }
                case 6: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 语速过快 */ UdeskLocales['current']
                                .pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxSpeakTooFast
                        }${data.speedFastTimes ?? '-'}${
                            /* 次</span><br /><span style="margin-left: 8px">· 语速过慢 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .timesspanbrspanStylemarginLeftpxSpeakTooSlowly
                        }${data.speedSlowTimes ?? '-'}${
                            /* 次</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.timesspan
                        }`
                    );
                    break;
                }
                case 7: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 语气词过多 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.tooManyModalParticles
                        }${data.modalParticleTooMuchTimes ?? '-'}${
                            /* 次</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.timesspan
                        }`
                    );
                    break;
                }
                case 8: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 负面情绪 */ UdeskLocales['current']
                                .pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxNegativeEmotions
                        }${data.negativeEmotionTimes ?? data.negativeEmotions ?? '-'}${
                            /* 次</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.timesspan
                        }`
                    );
                    break;
                }
            }
            return res;
        }, []),
    };
}

function getItem(color, name) {
    return (value) => {
        return {
            // name: '本次',
            name,
            // value: [100, 80, 60, 50, 30],
            value,
            symbol: 'circle',
            symbolSize: 5,
            label: {
                normal: {
                    show: true,
                    position: 'top',
                    distance: 5,
                    fontSize: 14,
                    formatter: function (params) {
                        return params.value;
                    },
                },
            },
            lineStyle: {
                normal: {
                    opacity: 0.5,
                },
            },
            areaStyle: {
                // color: 'rgba(51,0,255,0.6)',
                color,
            },
        };
    };
}
