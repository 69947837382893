import React from 'react';
import Udesk from 'Udesk';
import config from '../../../common/config/index';
import _isEmpty from 'lodash-es/isEmpty';
import _toNumber from 'lodash-es/toNumber';
import _find from 'lodash-es/find';
import { getSystemModule } from 'Udesk/system/subApp';

class TestSetListComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    // state = {showModel: false};
    privates = {
        storages: {
            conditionList: [],
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            customJudgeLogic: '',
            pageNum: 1,
            pageSize: 10,
            fieldList: []
        },
        columns: [],
        data: [],
        total: 0,
        judgeStrategy: 1,
        filterVisible: false,
        alreadyDoneCount: 0,
        recheckProcessTotal: 0,
        progressStatus: 1,
        inspectionData: [],
        conditionJson: [],
        manualTaskId: null,
        taskType: null,
        progressValue: '0',
        customFilterList: [],
        customeFilterField: null,
        customeFilterFieldKey: [],
        customDataSourceList: [],
        type: null,
        customId: null,
        customName: '',
        reInspectionConfigVisible: false,
        reInspectionType: Udesk.enums.reInspectionTypes.recheckPoint.id,
        allPoints: true,
        reInspectionPoints: [],
        tableRef: React.createRef(),
        loading: false,
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [/^\/site\/tasks\/manage\/\d+\/workbench\/test-set-list\/detail\/\d+/i]
        }
    };
    static computes = {
        _fields: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                let { showFieldList } = privates.inspectionData;
                if (showFieldList) {
                    showFieldList.map(field => {
                        return field;
                    });
                }

                return Udesk.business.fields.buildConditionFields({
                    fields: showFieldList
                });
            }
        ],
        _fieldValueSettings: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                return config.getFilterComponentSettings();
            }
        ],
        _columns: [
            'privates.inspectionData',
            'privates.testSetData',
            function ({ props, privates, locales }) {
                let { showFieldList, pointColorMap, fieldDataList } = privates.inspectionData;
                let { testSetData } = privates;
                let pointOffsetRates =
                    testSetData && testSetData.everyPointOffsetRates ? testSetData.everyPointOffsetRates : [];
                let columns = [];
                if (showFieldList) {
                    columns = showFieldList.map(item => {
                        let pointOffsetRate = getPointOffsetRate(item.id, pointOffsetRates);
                        return {
                            // name: item.id,
                            title:
                                !_isEmpty(fieldDataList) && pointOffsetRate ? (
                                    <span>
                                        {item.label}
                                        <span style={{ color: '#E03C32' }}>{`(${(
                                            _toNumber(pointOffsetRate.offsetRate) * 100
                                        ).toFixed(2)}%)`}</span>
                                    </span>
                                ) : item.label,
                            key: item.id,
                            dataIndex: item.id,
                            label:
                                !_isEmpty(fieldDataList) && pointOffsetRate
                                    ? `${item.label}(${(_toNumber(pointOffsetRate.offsetRate) * 100).toFixed(2)}%)`
                                    : item.label,
                            width: ['f_8', 'a_166', 'a_164'].includes(item.id) ? 200 : undefined,
                            render: (name, renderItem, index) => {
                                let text = renderItem[item.id] || '--';
                                if (pointColorMap && !_isEmpty(pointColorMap)) {
                                    if (pointColorMap[renderItem.id] && !_isEmpty(pointColorMap[renderItem.id])) {
                                        let obj = _find(pointColorMap[renderItem.id], current => current.pointId === item.id);
                                        if (obj) {
                                            if (obj.color === 1) {
                                                text = <div style={{ color: '#E03C32' }}>{renderItem[item.id]}</div>;
                                            } else if (obj.color === 0) {
                                                text = <div style={{ color: '#36864A' }}>{renderItem[item.id]}</div>;
                                            }
                                        }
                                    }
                                } else {
                                    text = renderItem[item.id];
                                }
                                if (item.id === 'r_1') {
                                    if (text === locales.components.pages.testSetList.reultList.deviation) {
                                        text = <div style={{ color: '#E03C32' }}>{text}</div>;
                                    } else if (text === locales.components.pages.testSetList.reultList.matching) {
                                        text = <div style={{ color: '#36864A' }}>{text}</div>;
                                    }
                                }
                                return text;
                            }
                        };
                    });
                }
                return columns;
            }
        ],
        _dataList: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                let { inspectionData } = privates;
                let fieldValueMaps = [];

                if (inspectionData && inspectionData.fieldDataList && inspectionData.fieldDataList.length > 0) {
                    inspectionData.fieldDataList.forEach(item => {
                        let fieldValueMap = item.fieldValueMap;
                        fieldValueMap.id = item.dataId;

                        fieldValueMaps.push(item.fieldValueMap);
                    });
                }

                return fieldValueMaps;
            }
        ],
        _cacheKey: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                let {
                    sdkOptions: {
                        props: { task }
                    }
                } = props;
                let cacheKey = '';
                if (
                    task &&
                    (task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                        task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id)
                ) {
                    cacheKey = `tasks.manage.workbench.test-set-list.${Udesk.enums.inspectDataSources.voiceCall.key}.company-${Udesk.data.init.company.id}.index`;
                } else if (
                    task &&
                    (task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
                        task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
                ) {
                    cacheKey = `tasks.manage.workbench.test-set-list.${Udesk.enums.inspectDataSources.textDialogue.key}.company-${Udesk.data.init.company.id}.index`;
                }
                return cacheKey;
            }
        ]
    };
    actions = {
        // 分页请求
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        //列排序，暂时不做
        sort() { },

        filterShow() {
            this.privates.filterVisible = true;
            this.actions.update();
        },
        changeFilterVisible() {
            this.privates.filterVisible = false;
            this.actions.update();
        },
        // 筛选自定义逻辑
        changeJudgeStrategy(value) {
            this.privates.storages.judgeStrategy = value;
            if (value === Udesk.enums.operatorRuleTypes.all.id || value === Udesk.enums.operatorRuleTypes.any.id) {
                this.privates.storages.customJudgeLogic = '';
            }
            this.actions.update();
        },
        // 筛选完成发送请求
        handleConditionFilter() {
            let { conditionList, judgeStrategy, customJudgeLogic } = this.privates.storages;
            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
                conditionList: this.actions.getQueryConditionList(conditionList).map(item => {
                    if (item.field.inspectionTaskId) {
                        return {
                            ...item,
                            field: {
                                ...item.field,
                                id: item.field.statusKey
                            }
                        };
                    } else {
                        return item;
                    }
                }),
                judgeStrategy,
                customJudgeLogic
            };

            this.actions.changeFilterVisible();
            this.actions.reloadAsyncModel(params);
        },
        filterContidionChanged(conditions) {
            this.privates.storages.conditionList = conditions;
        },

        // 组织发请求用的筛选数据
        getQueryConditionList(conditionList) {
            let showFieldList = getShowFieldList(this);
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map(condition => {
                        showFieldList.forEach(field => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            } else {
                                if (field.statusKey && condition.field === field.statusKey) {
                                    condition.field = field;
                                }
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        // 重新评估
        reInspection() {
            let { conditionList, judgeStrategy, customJudgeLogic } = this.privates.storages;
            let { sdkOptions, match } = this.props;

            this.privates.progressStatus = null;
            let taskId = match.params.taskId;
            let url = Udesk.business.apiPath.concatApiPath(`inspection-test-set/assessment`, sdkOptions);
            let queryConditionList = this.actions.getQueryConditionList(conditionList).map(item => {
                if (item.field.inspectionTaskId) {
                    return {
                        ...item,
                        field: {
                            ...item.field,
                            id: item.field.statusKey
                        }
                    };
                } else {
                    return item;
                }
            });
            let reCheckCondition = getReCheckCondition(this);
            let params = {
                taskId: taskId,
                conditionList: queryConditionList,
                judgeStrategy,
                customJudgeLogic,
                reCheckCondition,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    this.privates.reInspectionModalVisible = true;
                    this.privates.reInspectionConfigVisible = !this.privates.reInspectionConfigVisible;
                    this.privates.alreadyDoneCount = 0;
                    this.privates.progressValue = 0;
                    this.privates.recheckProcessTotal = resp.data.total;
                    this.privates.progressStatus = Udesk.enums.recheckStatus.waitForExecution.id;
                    this.privates.manualTaskId = resp.data.manualTaskId;
                    this.privates.taskType = resp.data.taskType;
                    this.privates.timerToken = setInterval(() => {
                        this.actions.queryReInspectionProgress(resp.data);
                    }, 5 * 1000);
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        // 结束重新评估
        endReQualityInspection() {
            let { locales } = this;
            let { sdkOptions, match } = this.props;
            let id = match.params.taskId;
            let taskType = this.privates.taskType;
            let url = null;
            if (taskType === 5) {
                url = Udesk.business.apiPath.concatApiPath(`inspection-test-set/${id}/reassessment/stop`, sdkOptions);
            } else if (taskType === 6) {
                url = Udesk.business.apiPath.concatApiPath(`inspection-test-set/${id}/batchRemove/stop`, sdkOptions);
            }
            Udesk.ajax.get(url).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(locales.components.pages.qualityInspectionList.endReInspectionSuccess);
                    this.actions.closeReInspection();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        // 关闭重新评估窗口 || 关闭批量删除窗口
        closeReInspection(value) {
            if (value === 'success') {
                let params = {
                    pageNum: Udesk.config.paging.defaultPageNumber
                };
                this.actions.reloadAsyncModel(params);
            }
            this.privates.reInspectionModalVisible = false;
            this.actions.update();
            let timerToken = this.privates.timerToken;
            if (timerToken != null) {
                clearInterval(timerToken);
            }
        },
        // 查询重新质检进度 || 查询批量删除进度
        queryReInspectionProgress(resp) {
            let { locales } = this;
            let { sdkOptions } = this.props;
            let url = null;
            if (resp.taskType === 5) {
                url = Udesk.business.apiPath.concatApiPath(
                    `inspection-test-set/reassessment/${resp.taskId}/process?manualTaskId=${resp.manualTaskId}`,
                    sdkOptions
                );
            } else {
                url = Udesk.business.apiPath.concatApiPath(
                    `inspection-test-set/batchRemove/${resp.taskId}/process?manualTaskId=${resp.manualTaskId}`,
                    sdkOptions
                );
            }
            Udesk.ajax.get(url).then(
                (resp, textStatus, jqXHR) => {
                    this.privates.recheckProcessTotal = resp.data.total;
                    this.privates.progressStatus = resp.data.status;
                    // 1未开始，2进行中，3成功，4失败
                    if (resp.data.status === Udesk.enums.recheckStatus.waitForExecution.id) {
                        this.privates.progressValue = 0;
                        this.actions.update();
                    }
                    if (resp.data.status === Udesk.enums.recheckStatus.inExecution.id) {
                        let failCount = resp.data.failCount;
                        let successCount = resp.data.successCount;
                        let total = resp.data.total;
                        if (!failCount) {
                            failCount = 0;
                        }
                        if (!successCount) {
                            successCount = 0;
                        }
                        let progressValue = parseFloat((failCount + successCount) / total) * 100;
                        if (progressValue) {
                            progressValue = progressValue.toFixed(2);
                        }
                        this.privates.progressValue = progressValue;
                        this.privates.alreadyDoneCount = failCount + successCount;
                        this.actions.update();
                    }
                    if (resp.data.status === Udesk.enums.recheckStatus.success.id) {
                        let failCount = resp.data.failCount;
                        let successCount = resp.data.successCount;
                        if (!failCount) {
                            failCount = 0;
                        }
                        if (!successCount) {
                            successCount = 0;
                        }
                        this.privates.progressValue = 100;
                        this.privates.alreadyDoneCount = failCount + successCount;
                        let timerToken = this.privates.timerToken;
                        if (timerToken != null) {
                            clearInterval(timerToken);
                        }
                        this.actions.update();
                    }
                    if (resp.data.status === Udesk.enums.recheckStatus.failed.id) {
                        this.actions.closeReInspection();
                        if (resp.data.taskType === 5) {
                            Udesk.ui.notify.error(locales.components.pages.testSetList.endReInspectionFailed);
                        } else if (resp.data.taskType === 6) {
                            Udesk.ui.notify.error(locales.components.pages.testSetList.delete.endReInspectionFailed);
                        }
                    }
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        //查看详情
        detailRoute(item) {
            let routeOptions = {
                history: this.props.history,
                routeName: 'testSetListDetail',
                pathParams: {
                    taskId: this.props.match.params.taskId,
                    id: item.id
                },
                queryParams: {
                    testSetFlag: true,
                    highLightType: '1'
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        //查看偏差记录，通过筛选评估结果偏差项实现
        //目前实现起来逻辑有问题，暂时去掉 QA-550
        checkBoxButton(e) {
            if (e.target.checked) {
                let list = [
                    {
                        field: {
                            id: 'r_1',
                            fieldName: 'inspection_test_set.assessment_result',
                            fieldType: 4
                        },
                        operator: 'is',
                        value: '2'
                    }
                ];
                this.privates.conditionJson = list;
                let params = {
                    pageNum: Udesk.config.paging.defaultPageNumber,
                    conditionList: this.actions.getQueryConditionList(list),
                    judgeStrategy: 1,
                    customJudgeLogic: ''
                };
                this.actions.reloadAsyncModel(params);
                this.actions.update();
            } else {
                let params = {
                    pageNum: Udesk.config.paging.defaultPageNumber,
                    conditionList: [],
                    judgeStrategy: 1,
                    customJudgeLogic: ''
                };
                this.actions.reloadAsyncModel(params);
                this.actions.update();
            }
        },
        //单个删除
        testSetDel(value) {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspection-test-set/${value.id}?taskId=${this.props.match.params.taskId}`,
                sdkOptions
            );
            Udesk.ajax.del(url).then(
                (resp, textStatus, jqXHR) => {
                    let params = {
                        pageNum: Udesk.config.paging.defaultPageNumber
                    };
                    this.actions.reloadAsyncModel(params);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        //批量删除
        batchDeletion() {
            let { conditionList, judgeStrategy, customJudgeLogic } = this.privates.storages;
            let { sdkOptions, match } = this.props;

            this.privates.progressStatus = null;
            let taskId = match.params.taskId;
            let url = Udesk.business.apiPath.concatApiPath(`inspection-test-set/batch-remove`, sdkOptions);
            let queryConditionList = this.actions.getQueryConditionList(conditionList).map(item => {
                if (item.field.inspectionTaskId) {
                    return {
                        ...item,
                        field: {
                            ...item.field,
                            id: item.field.statusKey
                        }
                    };
                } else {
                    return item;
                }
            });
            let params = {
                taskId: taskId,
                conditionList: queryConditionList,
                judgeStrategy,
                customJudgeLogic,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    this.privates.reInspectionModalVisible = true;
                    this.privates.alreadyDoneCount = 0;
                    this.privates.progressValue = 0;
                    this.privates.recheckProcessTotal = resp.data.total;
                    this.privates.progressStatus = Udesk.enums.recheckStatus.waitForExecution.id;
                    this.privates.manualTaskId = resp.data.manualTaskId;
                    this.privates.taskType = resp.data.taskType;
                    this.privates.timerToken = setInterval(() => {
                        this.actions.queryReInspectionProgress(resp.data);
                    }, 5 * 1000);
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },

        handleConditionCancel() {
            this.actions.changeFilterVisible();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy, id, name) {
            this.privates.storages.conditionList = conditionList || [];
            this.privates.storages.customJudgeLogic = customJudgeLogic;
            this.privates.storages.judgeStrategy = judgeStrategy;
            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
                conditionList: conditionList,
                judgeStrategy,
                customJudgeLogic
            };
            this.privates.customName = name;
            this.privates.customId = id;
            this.actions.reloadAsyncModel(params);
            this.actions.changeFilterVisible();
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.conditionList.forEach(item => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach(items => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label + ' ' + obj[item.operator] + ' ' + str.substring(0, str.length - 1)
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <p style={{ marginBottom: '3px' }}>
                        {index + 1}. {item}
                    </p>
                );
            });
        },
        // handleOk(){
        //     let url = Udesk.business.apiPath.concatApiPath(
        //         "/inspectionTasks/" + this.props.match.params.taskId + "/status/1",
        //         this.props.sdkOptions
        //     );
        //     Udesk.ajax.put(url).then(
        //         (resp, textStatus, jqXHR) => {
        //             Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
        //             this.privates.taskStatus = 1;
        //             this.actions.changeReInspectionConfigVisible();
        //         },
        //         reason => {
        //             Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.notifyMessage.submitFailedFormat, ""));
        //         }
        //     );
        //     this.setState({
        //         showModel: false,
        //     });
        // },
        // handleCancel(){
        //     this.setState({
        //         showModel: false,
        //     });
        // },
        changeReInspectionConfigVisible() {
            let {
                reInspectionConfigVisible
            } = this.privates;
            let {
                conditionList,
            } = this.privates.storages;

            if (!reInspectionConfigVisible && conditionList.length === 0) {
                Udesk.ui.notify.error(this.locales.labels.pleaseSetFilter);
                return;
            }
            // if(!this.privates.taskStatus){
            //     this.setState({
            //         showModel: true
            //     });
            //     return ;
            // }
            this.privates.reInspectionConfigVisible = !reInspectionConfigVisible;
            this.actions.update();
        },
        onPointsCheckedChange(points, allPoints) {
            this.privates.reInspectionPoints = points;
            this.privates.allPoints = allPoints;
            this.actions.update();
        },
        onConditionFilterSaved(flag, data) {
            if (data) {
                this.privates.customId = data.id;
            }
        },
        onPopoverVisible(e) {
            this.privates.tableRef.current.triggerColumnsControl(e);
        },
        exportDataList() {
            let { tableRef } = this.privates;
            let { conditionList, judgeStrategy, customJudgeLogic } = this.privates.storages;
            let { sdkOptions, match } = this.props;
            let { _columns } = this.privates.computes;
            let { locales } = this;
            let exportTaskFieldCreateRequestList = [],
                showFieldList = [];
            if (tableRef && tableRef.current) {
                showFieldList = tableRef.current.privates.targetKeys;
            }
            showFieldList.forEach((key) => {
                let currentColum = _columns.find(current => current.key === key);
                if (currentColum) {
                    exportTaskFieldCreateRequestList.push({
                        field: currentColum.key,
                        label: currentColum.label,
                    });
                }
            });
            let url = Udesk.business.apiPath.concatApiPath(`exps/testSet`, sdkOptions);
            let {
                assessmentTime = '',
                assessmentCount = '',
                pointCount = '',
                offsetDataCount = '',
                offsetPointCount = '',
                pointAccuracyRate = 0,
                dataAccuracyRate = 0
            } = this.privates.testSetData;
            let params = {
                docType: 1,
                taskId: match.params.taskId,
                showFieldList: showFieldList,
                exportTaskFieldCreateRequestList: exportTaskFieldCreateRequestList,
                conditionList: this.actions.getQueryConditionList(conditionList).map((item) => {
                    if (item.field.inspectionTaskId) {
                        return {
                            ...item,
                            field: {
                                ...item.field,
                                id: item.field.statusKey,
                            },
                        };
                    } else {
                        return item;
                    }
                }),
                judgeStrategy: judgeStrategy,
                customJudgeLogic: customJudgeLogic,
                assessmentTime,
                assessmentCount,
                pointCount,
                offsetDataCount,
                offsetPointCount,
                pointAccuracyRate: `${(
                    _toNumber(
                        pointAccuracyRate
                    ) * 100
                ).toFixed(2)}%`,
                dataAccuracyRate: `${(
                    _toNumber(
                        dataAccuracyRate
                    ) * 100
                ).toFixed(2)}%`,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.exportSuccessFormat,
                            locales.components.pages.testSetList.title
                        )
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.exportFailedFormat,
                            locales.components.pages.testSetList.title
                        )
                    );
                }
            );

        },
    };
    init() {
        let { sdkOptions } = this.props;
        let type = Udesk.enums.inspectDataSources.voiceCall.id;
        this.privates.type = type;
        if (
            sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
            sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id
        ) {
            type = Udesk.enums.inspectDataSources.voiceCall.id;
            this.privates.type = type;
        } else if (
            sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
            sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id
        ) {
            type = Udesk.enums.inspectDataSources.textDialogue.id;
            this.privates.type = type;
        }
        this.actions.update();
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'inspectionData') {
            if (asyncResult && asyncResult.data) {
                this.privates.inspectionData = asyncResult.data;
                this.privates.loading = false;
                if (asyncModel.inspectionData.paging) {
                    this.privates.storages.pageNum = asyncModel.inspectionData.paging.pageNum;
                    this.privates.storages.pageSize = asyncModel.inspectionData.paging.pageSize;
                    this.privates.total = asyncModel.inspectionData.paging.total;
                }
                this.actions.update();
            }
        } else if (asyncKey === 'testSetPromise') {
            if (asyncResult && asyncResult.data) {
                this.privates.testSetData = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'pointOffset') {
            if (asyncResult && asyncResult.data) {
                this.privates.pointOffset = asyncResult.data;
                this.actions.update();
            }
        } else if (asyncKey === 'inspectionProcess') {
            if (asyncResult.data) {
                this.privates.progressStatus = asyncResult.data.status;
                this.privates.manualTaskId = asyncResult.data.manualTaskId;
                if (
                    asyncResult.data.status === Udesk.enums.recheckStatus.waitForExecution.id ||
                    asyncResult.data.status === Udesk.enums.recheckStatus.inExecution.id
                ) {
                    this.privates.taskType = asyncResult.data.taskType;
                    this.actions.update();
                    let params = {
                        taskId: asyncResult.data.taskId,
                        manualTaskId: asyncResult.data.manualTaskId,
                        taskType: asyncResult.data.taskType
                    };
                    let timerToken = setInterval(() => {
                        this.actions.queryReInspectionProgress(params);
                    }, 5 * 1000);
                    this.privates.timerToken = timerToken;
                    this.privates.reInspectionModalVisible = true;
                }
                this.actions.update();
            }
        } else if (asyncKey === 'batchDelProcess') {
            if (asyncResult.data) {
                this.privates.progressStatus = asyncResult.data.status;
                if (
                    asyncResult.data.status === Udesk.enums.recheckStatus.waitForExecution.id ||
                    asyncResult.data.status === Udesk.enums.recheckStatus.inExecution.id
                ) {
                    this.privates.taskType = asyncResult.data.taskType;
                    this.actions.update();
                    let params = {
                        taskId: asyncResult.data.taskId,
                        manualTaskId: asyncResult.data.manualTaskId,
                        taskType: asyncResult.data.taskType
                    };
                    let timerToken = setInterval(() => {
                        this.actions.queryReInspectionProgress(params);
                    }, 5 * 1000);
                    this.privates.timerToken = timerToken;
                    this.privates.reInspectionModalVisible = true;
                }
                this.actions.update();
            }
        } else if (asyncKey === 'checkPoints') {
            let reInspectionPoints = asyncModel.checkPoints;
            reInspectionPoints = reInspectionPoints.filter(
                current => current.children && current.children.length > 0
            );
            for (let i = 0; i < reInspectionPoints.length; i++) {
                let firstCategory = reInspectionPoints[i];
                if (firstCategory.children && firstCategory.children.length > 0) {
                    for (let j = 0; j < firstCategory.children.length; j++) {
                        let secondCategory = firstCategory.children[j];
                        for (
                            let index = 0;
                            index < secondCategory.points.length;
                            index++
                        ) {
                            secondCategory.points[index]._checked = true;
                        }
                    }
                }
            }
            this.privates.reInspectionPoints = reInspectionPoints;
            this.actions.update();
        }
    }

    //#region Life Cycle
    componentDidMount() { }
    componentWillUnmount() { }
    //#endregion
}
function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter(item => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map(item => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}
function getShowFieldList(that) {
    let { showFieldList } = that.privates;
    let { fieldList } = that.privates.storages;
    let { sdkOptions } = that.props;

    if (showFieldList == null) {
        showFieldList = [];
    }

    if (showFieldList.length === 0) {
        if (fieldList && fieldList && fieldList.length > 0) {
            showFieldList = fieldList;
        } else if (sdkOptions.props.fields && sdkOptions.props.fields.length > 0) {
            showFieldList = sdkOptions.props.fields;
        }
    }

    return showFieldList;
}
function getPointOffsetRate(fieldId, pointOffsetRates) {
    let pointOffsetRate = pointOffsetRates.find(item => {
        return item.pointId === fieldId;
    });
    return pointOffsetRate;
}

function getReCheckCondition(that) {
    let {
        reInspectionType,
        reInspectionPoints,
    } = that.privates;
    if (reInspectionType === Udesk.enums.reInspectionTypes.recalcScore.id) {
        return { reCheckType: reInspectionType };
    } else {
        let inspectionPointCategories = [];
        let inspectionPoints = [];
        for (let i = 0; i < reInspectionPoints.length; i++) {
            let firstCategory = reInspectionPoints[i];
            for (let j = 0; j < firstCategory.children.length; j++) {
                let secondCategory = firstCategory.children[j];
                for (let index = 0; index < secondCategory.points.length; index++) {
                    let point = secondCategory.points[index];
                    if (point._checked) {
                        inspectionPoints.push(point.id);
                        if (!inspectionPointCategories.includes(firstCategory.id)) inspectionPointCategories.push(firstCategory.id);
                        if (!inspectionPointCategories.includes(secondCategory.id)) inspectionPointCategories.push(secondCategory.id);
                    }
                }
            }
        }
        return {
            reCheckType: reInspectionType,
            inspectionPointCategories,
            inspectionPoints,
        };
    }
}
export default TestSetListComponent;
