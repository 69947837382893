import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskLocales from 'UdeskLocales';
import { useFilterData, usePagination } from './hooks';
import { createComponent } from 'src/util/component';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { EnumSelect } from 'Component/common/enum-component';
import { useRequest } from 'src/util/hook';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import { linkTo } from 'src/util/link';
import { beautifulTime, getDateTimeString } from 'src/util/moment';
import { Badge, Button } from 'udesk-ui';
import { HistoricalLearningRenderCountContext } from 'src/pages/home';
import { inspect, isDefined } from 'src/util/core';
import { EllipsisSpan } from 'src/util/tag';
import { useCoachUserTreeDataWithPermit, UserTreeSelect } from 'src/util/hook/user';
import CategoryCourseTreeSelect from 'Component/pages/components/coach/CategoryCourseTreeSelect';
import { TaskSelect } from 'Component/pages/components/coach/task-select';
import { KeyPointHit } from './components/key-point-hit';
import { postIntelligentPartnerTaskFinishRecordsAdmin } from 'src/api/intelligentPartnerTaskFinishRecords/admin/index';
import { postIntelligentPartnerTaskFinishRecordsAdminExport } from 'src/api/intelligentPartnerTaskFinishRecords/admin/export/index';
import { getSystemModule } from 'Udesk/system/subApp';
import './index.less';

const createRenderFunction = (unit) => {
    return (data) => {
        if (isDefined(data)) {
            return `${data + unit}`;
        } else {
            return '--';
        }
    };
};

const Template = React.memo((props: any) => {
    const { saveStorages } = props;
    const [pagination, setPagination] = usePagination(props);
    const [filterData] = useFilterData(props);
    const [tableData, setTableData] = useState<any[]>([]);
    const request = useRequest();
    const [loading, setLoading] = useState(false);
    const queryParams = new URLSearchParams(props.location.search);
    const userIds = queryParams.get('userId');
    const taskId_qs = queryParams.get('taskId');
    const nodeId_qs = queryParams.get('nodeId');
    const ruleId_qs = queryParams.get('ruleId');
    const recordIds_qs = queryParams.get('recordIds')?.split(',') || [];
    const updateHistoricalLearningRenderCount = useContext(HistoricalLearningRenderCountContext);
    const StandardizeTableRef = useRef<any>(null);
    const { treeData } = useCoachUserTreeDataWithPermit();
    const [exportParams, setExportParams] = useState<any>();
    const { ...courseTreeSelectProps } = CategoryCourseTreeSelect.useTreeSelect({ isAdmin: true });

    const getTableSource = (data) => {
        const { filter, pagination, sorter } = data;
        const { time, ...otherFilterValue } = filter || {};
        const [startTime, endTime] = time || [];

        saveStorages((storages) => {
            return {
                ...storages,
                filterData: filter,
                pagination: pagination,
            };
        });

        const params = {
            ...otherFilterValue,
            pageNum: pagination.current || 1,
            pageSize: pagination.pageSize || 20,
        };
        if (sorter.order) {
            params.sortField = sorter.field;
            params.sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        }
        if (startTime) {
            params.startTime = getDateTimeString(startTime);
        }
        if (endTime) {
            params.endTime = getDateTimeString(endTime, 'YYYY-MM-DD HH:mm:59');
        }
        if (params.scoreResult === Udesk.enums.learningTaskScoreType.reviewing.id) {
            params.status = params.scoreResult;
            delete params.scoreResult;
        }
        if (params.userIds) {
            params.userIds = params.userIds?.userIds || [];
        }
        if (params.keyPointHit) {
            params.nodeId = params.keyPointHit.point.nodeId;
            params.nodeId = params.keyPointHit.point.nodeId;
            params.ruleId = params.keyPointHit.point.ruleId;
            params.isHit = params.keyPointHit.isHit;
            delete params.keyPointHit;
        }
        if (params.courseId) {
            params.courseCategoryId = params.courseId?.categories?.[0];
            params.courseId = params.courseId?.courses?.[0];
        }

        setExportParams(params);

        setLoading(true);

        postIntelligentPartnerTaskFinishRecordsAdmin(params)
            .then(
                (resp) => {
                    setTableData(resp.data ?? []);
                    setPagination({
                        total: resp.paging?.total,
                        current: resp.paging?.pageNum,
                        pageSize: resp.paging?.pageSize,
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 员工 */ UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord
                    .index.staff,
                key: 'userName',
                dataIndex: 'userName',
                fixed: 'left',
            },
            {
                title: /* 课程 */ UdeskLocales['current'].pages.coach.learningCenter.record.index
                    .curriculum,
                key: 'lessonName',
                dataIndex: 'lessonName',
                fixed: 'left',
            },
            {
                title: /* 任务名称 */ UdeskLocales['current'].pages.coach.learningCenter.record
                    .index.taskName,
                key: 'taskName',
                dataIndex: 'taskName',
                fixed: 'left',
            },
            {
                title: /* 任务类型 */ UdeskLocales['current'].pages.coach.learningCenter.record
                    .index.taskType,
                key: 'taskType',
                dataIndex: 'taskType',
                render: (name, item, index) => {
                    return Udesk.enums.learningTaskType.getName(name);
                },
            },
            {
                title: /* 得分 */ UdeskLocales['current'].pages.coach.learningCenter.record.index
                    .score,
                key: 'score',
                dataIndex: 'score',
                sorter: true,
                render: (score, item) => {
                    if ([Udesk.enums.learningTaskScoreType.reviewing.id].includes(item.status)) {
                        return Udesk.enums.learningTaskScoreType.getName(item.status);
                    }
                    return score;
                },
            },
            {
                title: /* 得分结果 */ UdeskLocales['current'].pages.coach.learningCenter.record
                    .index.scoringResults,
                key: 'scoreResult',
                dataIndex: 'scoreResult',
                render: (name, item, index) => {
                    if ([Udesk.enums.learningTaskScoreType.reviewing.id].includes(item.status)) {
                        return Udesk.enums.learningTaskScoreType.getName(item.status);
                    }
                    if (typeof name === 'number') {
                        return Udesk.enums.learningTaskScoreType.getName(name);
                    }
                    return '';
                },
            },
            {
                title: /* 时长 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.duration,
                key: 'accumulateCostTime',
                dataIndex: 'accumulateCostTime',
                sorter: true,
                render(time) {
                    return time ? beautifulTime(time, true) : '--';
                },
            },
            {
                title: /* 流程得分 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.processScore,
                key: 'flowScore',
                dataIndex: 'flowScore',
                sorter: true,
                render: createRenderFunction(/* 分 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.branch),
            },
            {
                title: /* 内容完整度 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.contentCompleteness,
                key: 'completeRate',
                dataIndex: 'completeRate',
                sorter: true,
                render: createRenderFunction('%'),
            },
            {
                title: /* 话术得分 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.scriptScore,
                key: 'craftScore',
                dataIndex: 'craftScore',
                sorter: true,
                render: createRenderFunction(/* 分 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.branch),
            },
            {
                title: /* 要点命中率 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.keyHitRate,
                key: 'mainPointsCommitRate',
                dataIndex: 'mainPointsCommitRate',
                sorter: true,
                render: createRenderFunction('%'),
            },
            {
                title: /* 情绪得分 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.emotionalScore,
                key: 'emotionScore',
                dataIndex: 'emotionScore',
                sorter: true,
                render: createRenderFunction(/* 分 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.branch),
            },
            {
                title: /* 情绪负向占比 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.negativeProportionOfEmotions,
                key: 'negativeEmotionRate',
                dataIndex: 'negativeEmotionRate',
                sorter: true,
                render: createRenderFunction('%'),
            },
            {
                title: /* 表达得分 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.expressionScore,
                key: 'speedScore',
                dataIndex: 'speedScore',
                sorter: true,
                render: createRenderFunction(/* 分 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.branch),
            },
            {
                title: /* 语速异常占比 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.theProportionOfAbnormalSpeechSpeed,
                key: 'abnormalSpeedRate',
                dataIndex: 'abnormalSpeedRate',
                sorter: true,
                render: createRenderFunction('%'),
            },
            {
                title: /* 语气词过多占比 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.excessiveProportionOfModalParticles,
                key: 'speedModalParticleTooMuchRate',
                dataIndex: 'speedModalParticleTooMuchRate',
                sorter: true,
                render: createRenderFunction('%'),
            },
            {
                title: /* 时间 */ UdeskLocales['current'].pages.coach.learningCenter.record.index
                    .time,
                key: 'createTime',
                dataIndex: 'createTime',
                sorter: true,
            },
            {
                title: /* 操作 */ UdeskLocales['current'].pages.coach.learningCenter.record.index
                    .operation,
                key: 'id',
                dataIndex: 'id',
                fixed: 'right',
                render: (name, record) => {
                    return (
                        <div className={'task-btns'}>
                            {record.isScoreUpdate === 1 || record.status === 6 ? (
                                <Badge
                                    count={
                                        <div
                                            style={{
                                                color: '#fff',
                                                background: inspect(
                                                    record.isScoreUpdate === 1,
                                                    '#ff4d4f',
                                                    '#ff8c00'
                                                ),
                                                borderRadius: '12px',
                                                padding: '6px 10px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            <span>
                                                <EllipsisSpan>
                                                    {inspect(
                                                        record.isScoreUpdate === 1,
                                                        /* 评分更新 */ UdeskLocales['current'].pages
                                                            .coach.learningCenter.record.index
                                                            .ratingUpdate
                                                    )}
                                                </EllipsisSpan>
                                                <EllipsisSpan>
                                                    {inspect(
                                                        record.status === 6,
                                                        /* 评分申诉中 */ UdeskLocales['current']
                                                            .pages.coach.learningCenter.record.index
                                                            .ratingAppealInProgress
                                                    )}
                                                </EllipsisSpan>
                                            </span>
                                        </div>
                                    }
                                >
                                    <Button
                                        disabled={[
                                            Udesk.enums.learningTaskScoreType.reviewing.id,
                                        ].includes(record.status)}
                                        onClick={() => {
                                            request(
                                                `/intelligentPartnerTaskFinishRecords/${name}/updateScore/status`,
                                                {},
                                                'put'
                                            ).then(
                                                (resp) => {
                                                    updateHistoricalLearningRenderCount(() => {
                                                        linkTo(
                                                            props.history,
                                                            'coachLearningCenterRecordDetail',
                                                            { id: name }
                                                        );
                                                    });
                                                },
                                                (reason) => {
                                                    Udesk.ui.notify.error(reason.errorMsg);
                                                }
                                            );
                                        }}
                                        type={'link'}
                                    >
                                        <span>
                                            {/* 查看详情 */}
                                            {
                                                UdeskLocales['current'].pages.coach.learningCenter
                                                    .record.index.viewDetails
                                            }
                                        </span>
                                    </Button>
                                </Badge>
                            ) : (
                                <Button
                                    disabled={[
                                        Udesk.enums.learningTaskScoreType.reviewing.id,
                                    ].includes(record.status)}
                                    onClick={() => {
                                        linkTo(props.history, 'coachLearningCenterRecordDetail', {
                                            id: name,
                                        });
                                    }}
                                    type={'link'}
                                >
                                    <span>
                                        {/* 查看详情 */}
                                        {
                                            UdeskLocales['current'].pages.coach.learningCenter
                                                .record.index.viewDetails
                                        }
                                    </span>
                                </Button>
                            )}
                        </div>
                    );
                },
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination,
            loading,
        };
    }, [
        tableData,
        pagination,
        loading,
        props.history,
        request,
        updateHistoricalLearningRenderCount,
    ]);

    const [taskId, setTaskId] = useState(filterData?.taskId || taskId_qs);

    const filter = useMemo<CommonFilterProps>(() => {
        return {
            customComponents: {
                EnumSelect,
                UserTreeSelect,
                CategoryCourseTreeSelect,
                TaskSelect,
                KeyPointHit,
            },
            fields: [
                {
                    name: 'userIds',
                    type: 'UserTreeSelect',
                    label: /* 员工 */ UdeskLocales['current'].pages.coach.dataAnalysis
                        .learningRecord.index.staff,
                    props: {
                        width: 250,
                        treeData,
                        multiple: true,
                        showSearch: true,
                        allowClear: true,
                        showArrow: true,
                        maxTagCount: 3,
                        placeholder: UdeskLocales['current'].labels.pleaseSelect,
                        treeDefaultExpandAll: false,
                        treeCheckable: true,
                    },
                },
                {
                    name: 'courseId',
                    label: /* 课程 */ UdeskLocales['current'].pages.coach.dataAnalysis
                        .learningRecord.index.curriculum,
                    type: 'CategoryCourseTreeSelect',
                    props: {
                        ...courseTreeSelectProps,
                        style: {
                            width: 250,
                        },
                    },
                },
                {
                    name: 'taskType',
                    label: /* 任务类型 */ UdeskLocales['current'].pages.coach.learningCenter.record
                        .index.taskType,
                    type: 'EnumSelect',
                    props: {
                        width: 130,
                        enumKey: 'learningTaskType',
                        empty: {
                            label: /* 全部 */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.index.whole,
                        },
                    },
                },
                {
                    name: 'taskId',
                    label: /* 任务名称 */ UdeskLocales['current'].pages.coach.learningCenter.record
                        .index.taskName,
                    type: 'TaskSelect',
                    props: {
                        allowClear: true,
                        showArrow: true,
                        optionFilterProp: 'label',
                        showSearch: true,
                        style: {
                            width: 250,
                        },
                        onChange: (v) => {
                            setTaskId(v);
                            // 重置要点
                            StandardizeTableRef.current?.filterInstance?.formInstance?.setFieldsValue?.(
                                {
                                    keyPointHit: undefined,
                                }
                            );
                        },
                    },
                },
                {
                    name: 'keyPointHit',
                    label: /* 要点 */ UdeskLocales['current'].pages.coach.dataAnalysis
                        .learningRecord.index.mainPoints,
                    type: 'KeyPointHit',
                    props: {
                        taskId,
                    },
                },
                {
                    name: 'scoreResult',
                    label: /* 得分结果 */ UdeskLocales['current'].pages.coach.learningCenter.record
                        .index.scoringResults,
                    type: 'EnumSelect',
                    props: {
                        width: 130,
                        enumKey: 'learningTaskScoreType',
                        empty: {
                            label: /* 全部 */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.index.whole,
                        },
                    },
                },
                {
                    name: 'time',
                    label: /* 学习时间 */ UdeskLocales['current'].pages.coach.learningCenter.record
                        .index.learningTime,
                    type: 'RangePicker',
                    props: {
                        format: 'YYYY-MM-DD HH:mm',
                        showTime: true,
                        style: {
                            width: 340,
                        },
                    },
                },
            ] as CommonFilterProps['fields'],
            initialValues: {
                userIds: {
                    userIds: userIds ? [Number(userIds)] : [],
                },
                taskId: taskId_qs ? Number(taskId_qs) : undefined,
                recordIds: recordIds_qs ? recordIds_qs : undefined,
                keyPointHit: {
                    point: {
                        taskId: taskId_qs ? Number(taskId_qs) : undefined,
                        nodeId: nodeId_qs ? Number(nodeId_qs) : undefined,
                        ruleId: ruleId_qs ? Number(ruleId_qs) : undefined,
                    },
                    isHit: ruleId_qs ? 0 : undefined,
                },
                ...filterData,
            },
            onSearch: (v, o) => {
                setTaskId(v.taskId);
                saveStorages((storages) => {
                    return {
                        ...storages,
                        filterData: {},
                        pagination: {
                            current: 1,
                            pageSize: 20,
                            total: 0,
                        },
                    };
                });
            },
        };
    }, [
        treeData,
        courseTreeSelectProps,
        taskId,
        userIds,
        taskId_qs,
        recordIds_qs,
        nodeId_qs,
        ruleId_qs,
        filterData,
        saveStorages,
    ]);

    useEffect(() => {
        $('.udesk-qa-ui-common-filter').on('click', '.udesk-qa-ui-btn-default', () => {
            return !saveStorages({
                filterData: {},
            });
        });
    }, [saveStorages]);

    return (
        <Page
            pageClassName="learning-center-record"
            pageBodyClassName="learning-center-record-body"
            backGroundGhost={true}
            title={/* 学习记录数据 */UdeskLocales['current'].pages.coach.dataAnalysis.learningRecord.index.learningRecordData}
        >
            <div className="page-content">
                <StandardizeTable.RequestTable
                    onRequest={getTableSource}
                    table={tableProps}
                    filter={filter}
                    ref={StandardizeTableRef}
                    exportBtn={{
                        onClick: () => {
                            return postIntelligentPartnerTaskFinishRecordsAdminExport({
                                ...exportParams,
                                systemModule: getSystemModule(),
                            });
                        },
                        goToDownloadCenter: () => {
                            linkTo(props.history, 'coachSystemTaskCenterDownloadList');
                        },
                    }}
                />
            </div>
        </Page>
    );
});

export default createComponent(Template, undefined, [/^\/coach\/learning-center\/record\/*/i]);
