export default {
  lang: {
    num: '第',
    total: '條/總共',
    strip: '條',
    more: '更多',
    chosen: '已經選擇',
    cancel: '取消選擇',
    bulkOperations: '批量操作',
    import: '導入',
  },
};
