import React from "react";
import Udesk from "Udesk";
import UdeskLocales from "UdeskLocales";
import { Modal } from "udesk-ui";

const { confirm } = Modal;

class ConceptWordDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        paramsId: null,
        conceptWordName: "", //概念词名称
        conceptWordNameLength: 0,
        usrfulRadioValue: 1, //有效性
        conceptWordDescription: "", //用户输入的概念词描述
        conceptWordDescriptionLength: 0,
        conceptWordDescriptionFromCheckbox: [], //用户从checkbox勾选的概念词描述
        descriptionList: [], //已有的概念词描述集合
        isRepeat: false,
        isFold: false,
        isEmpty: false,
        recommendPageNum: 1,
        recommendDescriptionList: [],//推荐的概念词描述集合
        conceptWordNameChangeFlag: false
    };

    actions = {
        backToIndex() {
            let routeOptions = {
                history: this.props.history,
                routeName: "conceptWordIndex"
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        conceptWordNameInputChange(e) {
            let len = this.actions.getStringLengthForInput(e.target.value);
            this.privates.conceptWordNameLength = len;
            this.privates.conceptWordName = e.target.value;
            this.privates.conceptWordNameChangeFlag = true;
            this.actions.update();
        },
        conceptWordNameInputFormatter() {
            this.privates.conceptWordName = this.privates.conceptWordName.trim();
            let { len, index } = this.actions.getStringLength(
                this.privates.conceptWordName
            );
            this.privates.conceptWordNameLength = len;
            this.privates.conceptWordName = this.privates.conceptWordName.slice(
                0,
                index
            );
            this.actions.update();
        },
        usefulRadioChange(e) {
            this.privates.usrfulRadioValue = e.target.value;
            this.actions.update();
        },
        onFormValuesChange(changedValues, allValues) {
            this.privates.conceptWordName = allValues.name;
            this.privates.usrfulRadioValue = allValues.effectiveness;
            let { len } = this.actions.getStringLength(
                this.privates.conceptWordName
            );
            this.privates.conceptWordNameLength = len;
            this.actions.update();   
        },
        conceptWordDescriptionInputChange(e) {
            let len = this.actions.getStringLengthForInput(e.target.value);
            this.privates.conceptWordDescriptionLength = len;
            this.privates.conceptWordDescription = e.target.value;
            this.privates.isRepeat = false;
            this.privates.isEmpty = false;
            this.actions.update();
        },
        conceptWordDescriptionInputFormatter() {
            this.privates.conceptWordDescription = this.privates.conceptWordDescription.trim();
            let { len, index } = this.actions.getStringLength(
                this.privates.conceptWordDescription
            );
            this.privates.conceptWordDescriptionLength = len;
            this.privates.conceptWordDescription = this.privates.conceptWordDescription.slice(
                0,
                index
            );
            this.actions.update();
        },
        addDescription() {
            if (this.privates.conceptWordDescription === "") {
                this.privates.isEmpty = true;
            } else {
                let flag = this.privates.descriptionList.findIndex(
                    description => description === this.privates.conceptWordDescription
                );
                if (flag === -1) {
                    this.privates.descriptionList.push(
                        this.privates.conceptWordDescription
                    );
                    this.privates.conceptWordDescription = "";
                    this.privates.conceptWordDescriptionLength = 0;
                    this.privates.isFold = false;
                    this.privates.recommendPageNum = 1;
                    if (this.privates.conceptWordDescriptionFromCheckbox.length === 0) {
                        this.actions.recommendRefresh();
                    }
                } else {
                    //显示重复提示字样
                    this.privates.isRepeat = true;
                }
            }
            this.actions.update();
        },
        foldRecommendList() {
            this.privates.isFold = !this.privates.isFold;
            this.actions.update();
        },
        checkboxCancel() {
            this.privates.conceptWordDescriptionFromCheckbox = [];
            this.actions.update();
        },
        recommendRefresh() {
            //发送ajax
            // this.privates.recommendDescriptionList = data;
            this.privates.conceptWordDescriptionFromCheckbox = [];
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `conceptWords/findRecommendDescribeWords`,
                sdkOptions
            );
            let params = {
                pageNum: this.privates.recommendPageNum,
                pageSize: 20,
                searchWords: this.privates.descriptionList
            };
            Udesk.ajax.post(url, params).then(
                resp => {
                    this.privates.recommendDescriptionList = resp.data || [];
                    if (this.privates.recommendDescriptionList.length === 0) {
                        this.privates.recommendPageNum = 1;
                    } else {
                        this.privates.recommendPageNum += 1;
                    }
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        addDescriptionFromCheckbox() {
            if (this.privates.conceptWordDescriptionFromCheckbox.length !== 0) {
                this.privates.conceptWordDescriptionFromCheckbox.forEach(item => {
                    let index = this.privates.descriptionList.findIndex(
                        description => description === item
                    );
                    if (index === -1) {
                        this.privates.descriptionList.push(item);
                    }
                });
                this.privates.conceptWordDescriptionFromCheckbox = [];
                //发送ajax 更新推荐词
                this.privates.recommendPageNum = 1;
                this.actions.recommendRefresh();
                this.actions.update();
            }
        },
        recommendDescriptionCheck(checkedValues) {
            this.privates.conceptWordDescriptionFromCheckbox = checkedValues;
            this.actions.update();
        },
        cancelDescriptionSelected(key) {
            let index = this.privates.descriptionList.findIndex(
                description => description === key
            );
            this.privates.descriptionList.splice(index, 1);
            this.actions.update();
        },
        submit() {
            let { sdkOptions } = this.props;
            if(this.actions.getStringLengthForInput(this.privates.conceptWordName)>20){
                return;
            }
            if (this.privates.paramsId) {
                //判断是新增还是编辑 true为编辑
                let url = Udesk.business.apiPath.concatApiPath(
                    `conceptWords/${this.privates.paramsId}`,
                    sdkOptions
                );
                let params = {
                    id: this.privates.paramsId,
                    name: this.privates.conceptWordName,
                    describeWords: this.privates.descriptionList,
                    status: this.privates.usrfulRadioValue
                };
                Udesk.ajax.put(url, params).then(
                    resp => {
                        Udesk.ui.notify.success(
                            UdeskLocales.current.components.pages.conceptWord.detail.success
                        );
                        this.actions.backToIndex();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            } else {
                if (this.privates.conceptWordName) {
                    //判断是否有名称
                    let url = Udesk.business.apiPath.concatApiPath(
                        `conceptWords`,
                        sdkOptions
                    );
                    let params = {
                        name: this.privates.conceptWordName,
                        describeWords: this.privates.descriptionList,
                        status: this.privates.usrfulRadioValue
                    };
                    Udesk.ajax.post(url, params).then(
                        resp => {
                            Udesk.ui.notify.success(
                                UdeskLocales.current.components.pages.conceptWord.detail.success
                            );
                            this.actions.backToIndex();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                    );
                } else {
                    Udesk.ui.notify.error(
                        UdeskLocales.current.components.pages.conceptWord.detail.warning
                    );
                }
            }
        },
        submitBtnHandle() {
            let that = this;
            if (this.privates.descriptionList.length !== 0) {
                if (this.privates.conceptWordDescriptionFromCheckbox.length) {
                    confirm({
                        title:
                            UdeskLocales.current.components.pages.conceptWord.detail
                                .submitBtnHandle.title,
                        content:
                            UdeskLocales.current.components.pages.conceptWord.detail
                                .submitBtnHandle.content,
                        onOk() {
                            that.actions.submit();
                        },
                        onCancel() { }
                    });
                } else {
                    this.actions.submit();
                }
            } else {
                Udesk.ui.notify.error(
                    UdeskLocales.current.components.pages.conceptWord.detail.warning2
                );
            }
        },
        //区分中英文的计算长度
        getStringLength(str) {
            let cardinalIndex = str.split("").findIndex(item => item !== " ");
            let len = 0;
            let index = 0;
            for (let i = 0; i < str.length; i++) {
                if (str.charAt(i).match(/[\u4e00-\u9fa5]/g) != null) {
                    if (cardinalIndex !== -1 && i >= cardinalIndex && len < 19) {
                        len += 2;
                        index += 1;
                    }
                } else {
                    if (cardinalIndex !== -1 && i >= cardinalIndex && len < 20) {
                        len += 1;
                        index += 1;
                    }
                    if (len === 20) {
                        break;
                    }
                }
            }
            return { len, index };
        },
        getStringLengthForInput(str) {
            let cardinalIndex = str.split("").findIndex(item => item !== " ");
            let len = 0;
            for (let i = 0; i < str.length; i++) {
                if (str.charAt(i).match(/[\u4e00-\u9fa5]/g) != null) {
                    if (cardinalIndex !== -1 && i >= cardinalIndex) {
                        len += 2;
                    }
                } else {
                    if (cardinalIndex !== -1 && i >= cardinalIndex) {
                        len += 1;
                    }
                }
            }
            return len;
        }
    };

    //#region Life Cycle
    componentDidMount() { }
    onModelResolved(model) {
        if(model.conceptWord && model.conceptWord.data){
            this.privates.conceptWordName = model.conceptWord.data.name;
            this.privates.usrfulRadioValue = model.conceptWord.data.status;
            this.privates.descriptionList = model.conceptWord.data.describeWords;
            let {len} = this.actions.getStringLength(this.privates.conceptWordName);
            this.privates.conceptWordNameLength = len;        
            this.actions.recommendRefresh();    
        }

    }

    //#endregion
}

export default ConceptWordDetailComponent;
