/*
 * @Author: bin-donnie
 * @Description: 课程选择组件
 */
import React from 'react';
import { useEffect, useState } from 'react';
import { getReviewIntelligentPartnerCourse } from 'src/api/review/intelligentPartner/course';
import { Select } from 'udesk-ui';

const LessonSelect = (props) => {
    return <Select {...props} />;
};

export const useLessonSelect = () => {
    const [allLessons, setAllLessons] = useState<any>([]);

    useEffect(() => {
        getReviewIntelligentPartnerCourse({
            params: { status: 1 },
        }).then((resp) => {
            setAllLessons(
                (resp?.data || []).map((item) => ({ ...item, value: item.id, label: item.name }))
            );
        });
    }, []);

    return { allLessons };
};

export default LessonSelect;
