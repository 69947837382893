import React from 'react';
import ConditionItem, { getDefaultValue } from './conditionItem';
import { Button, Icon, Space } from 'udesk-ui';
import { EnumSelect } from 'Component/common/enum-component';
import './index.scss';

const deepClone = (array) => {
    return array.map(item => {
        return Reflect.ownKeys(item).reduce((value, key) => {
            if (key === 'conditionList') {
                value[key] = deepClone(item[key]);
            } else {
                value[key] = item[key];
            }
            return value;
        }, {});
    });
};

const Node = {
    'And' : 1,
    'Or': 2
};

const getDefaultCondition = (isSales) => ({condition: getDefaultValue(isSales)});

const getParent = (conditionList, list) => {
    for(let index = 0; index < list.length; index++) {
        const item = list[index];
        if (item.conditionList === conditionList) {
            return [item, index, list];
        } else {
            if (item.conditionList) {
                const data = getParent(conditionList, item.conditionList);
                if (data) {
                    return data;
                }
            }
        }
    }
};

const TreeNode = React.memo((props: any) => {
    const { 
        // deep,
        isSales,
        rootValue,
        data: [
            list, 
            index
        ], 
        onChange 
    } = props;
    const data = list[index];

    const changeHandle = (value) => {
        list.splice(index, 1, {
            condition: value
        });
        onChange();
    };

    const typeChangeHandle = (value) => {
        data.logicalOperator = value;
        onChange();
    };

    const addChildClickHandle = () => {
        list.splice(index, 1, {
            logicalOperator: Node.And,
            conditionList: [
                data,
                getDefaultCondition(isSales),
            ],
        });
        onChange();
    };

    const addClickHandle = () => {
        data.conditionList.push(getDefaultCondition(isSales));
        onChange();
    };

    const delClickHandle = () => {
        list.splice(index, 1);
        if (list.length === 1) {
            const parentData = getParent(list, rootValue);
            if (parentData) {
                const [
                    node, 
                    nodeIndex, 
                    parentList
                ] = parentData;
                parentList.splice(nodeIndex, 1, node.conditionList[0]);
            }
        }
        onChange();
    };

    if ([Node.And, Node.Or].includes(data.logicalOperator)) {
        return (
            <div className='rule-node'>
                <Space>
                    <Button 
                        type='primary'
                        size="small"
                        onClick={addClickHandle} 
                        shape="circle"
                        icon={<Icon type='PlusOutlined' antdIcon={true} />} 
                    />
                    <EnumSelect 
                        size="small"
                        value={data.logicalOperator} 
                        onChange={typeChangeHandle} 
                        enumKey="clusteringRulesConditionType" />
                </Space>
            </div>
        );
    } else {
        return (
            <ConditionItem 
                addBtn={
                    <Button 
                        type='primary'
                        size="small"
                        onClick={addChildClickHandle} 
                        shape="circle"
                        icon={<Icon type='PlusOutlined' antdIcon={true} />}
                    />
                }
                delBtn={
                    <Button 
                        size="small"
                        onClick={delClickHandle} 
                        shape="circle"
                        icon={<Icon type='MinusOutlined' antdIcon={true} />} 
                    />
                }
                isSales={isSales}
                selectOptionData={props.selectOptionData}
                value={data.condition} 
                onChange={changeHandle} />
        );
    }
});

const Tree = React.memo((props: any) => {
    const list = props.rootValue ? props.value : deepClone(props.value || []);
    const rootValue = props.rootValue || list;
    const deep = props.deep || 0;
    const { 
        onChange, 
        changeHandle = (value = rootValue) => {
            onChange(value);
        }
    } = props;

    return list.length === 0 && deep === 0 ? (
        <Button 
            type='primary'
            size="small"
            onClick={ () => changeHandle([getDefaultCondition(props.isSales)])} 
            icon={<Icon type='PlusOutlined' antdIcon={true}/>} 
        />
    ) : (
        <ul>
            {list.map((item, index) => (
                <li>
                    <TreeNode 
                        selectOptionData={props.selectOptionData}
                        rootValue={rootValue}
                        data={[list, index]}
                        onChange={changeHandle} 
                        deep={deep}
                        isSales={props.isSales}
                    />
                    {
                        item.conditionList 
                            ? <Tree 
                                selectOptionData={props.selectOptionData}
                                value={item.conditionList}
                                rootValue={rootValue}
                                changeHandle={changeHandle}
                                deep={deep + 1}
                                isSales={props.isSales}
                            /> 
                            : null
                    }
                </li>
            ))}
        </ul>
    );
});

export default Tree;
