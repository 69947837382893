import React, { useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { StandardizeTable } from 'udesk_gm_ui';
import { getIntelligentPartnerScoringPoints } from 'src/api/intelligentPartner/scoring/points';
import { IntelligentPartnerScoringPointFoundResponse } from 'src/api/types';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

const Template = React.memo((props: any) => {
    const [dataSource, setDataSource] = useState<IntelligentPartnerScoringPointFoundResponse[]>([]);
    return (
        <Page pageBodyClassName="udesk-coach-score-dimension" title={/* 评分维度 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreDimension.index.ratingDimension} padding={false}>
            <StandardizeTable.RequestTable
                onRequest={() => {
                    getIntelligentPartnerScoringPoints().then((resp) => {
                        setDataSource(resp.data ?? []);
                    });
                }}
                table={{
                    dataSource,
                    columns: [
                        {
                            title: /* 评分点 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreDimension.index.ratingPoints,
                            key: 'pointName',
                            dataIndex: 'pointName',
                        },
                        {
                            title: /* 说明 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreDimension.index.explain,
                            key: 'description',
                            dataIndex: 'description',
                        },
                        {
                            title: /* 评分维度 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreDimension.index.ratingDimension,
                            key: 'dimension',
                            dataIndex: 'dimension',
                            render(value) {
                                return Udesk.enums.dimension.getName(value);
                            },
                        },
                        {
                            title: /* 类型 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreDimension.index.type,
                            key: 'type',
                            dataIndex: 'type',
                            render(value) {
                                return Udesk.enums.dimensionTypes.getName(value);
                            },
                        },
                    ],
                }}
            />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
