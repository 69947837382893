import React from "react";
import Udesk from 'Udesk';
import _filter from "lodash-es/filter";
import _includes from 'lodash-es/includes';
import isNumber from 'lodash-es/isNumber';
import isString from 'lodash-es/isString';
import isArray from 'lodash-es/isArray';
import Locales from 'UdeskLocales';
import { getSystemModule } from 'Udesk/system/subApp';

class CustomerServiceInformationComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
    };
    //#endregion

    state = { visible: false, modalData: [], deletePopVisible: false };
    privates = {
        columns: [],
        paging: {
            pageNum: 1,
            pageSize: 10
        },
        data: [],
        confirmLoading: false,
        isShowUploadModal: false,
        checkExceptionData: 0,
        keyWord: "",
        ordersList: [],
        selectedItems: [],
        grounpVisible: false,
        grounpDataList: null,
        grounpFields: [],
        grounpSelect: Locales['current'].fix.batchOperation,
        grounpType: "",
        grounpId: '',
        loading: false,
    };

    actions = {
        deletReordClick(){
            this.setState({
                deletePopVisible: true,
            });
        },
        sort(value, e) {
            let status;
            if (e.id === "desc") {
                status = 2;
            } else if (e.id === "asc") {
                status = 1;
            }
            if (value.name === "agentNo") {
                this.privates.ordersList = [
                    {
                        fieldName: "agent_no",
                        ordersType: status
                    }
                ];
            } else {
                this.privates.ordersList = [
                    {
                        fieldName: "update_time",
                        ordersType: status
                    }
                ];
            }
            this.actions.update();
            if (e.id !== "none") {
                this.actions.reloadAsyncModel();
            } else {
                this.privates.ordersList = [];
                this.actions.update();
            }
        },
        pageChanged(pageNum, pageSize) {
            this.privates.paging.pageNum = pageNum;
            this.privates.paging.pageSize = pageSize;
            this.privates.selectedItems = [];
            this.actions.update();
            this.actions.reloadAsyncModel();

        },
        editItem(value) {
            this.setState({
                visible: true,
                modalData: value
            });
        },
        deleteItem(value) {
            let { sdkOptions } = this.props;
            if (
                this.privates.paging.pageNum !== 1 &&
                (this.privates.paging.pageNum - 1) * this.privates.paging.pageSize ===
                this.privates.paging.total - 1
            ) {
                this.privates.paging.pageNum--;
                this.actions.update();
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `agent/delete/${value.id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.del(url).then(
                    resp => {
                        this.actions.reloadAsyncModel();
                        this.privates.selectedItems = [];
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        handleSubmit() {
            let { modalData, visibleStatus } = this.state;
            let { sdkOptions } = this.props;
            this.props.form.validateFields((err, value) => {
                let data = {
                    agentName: value.agentName,
                    agentNo: value.agentNo,
                    agentEmail: value.agentEmail,
                    agentTel: value.agentTel,
                    id: modalData.id,
                    agentGroupIds: value.agentGroups
                };
                let url = Udesk.business.apiPath.concatApiPath(
                    visibleStatus ? `agent/save` : `agent/edit`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(url, data).then(
                        resp => {
                            this.setState({
                                visible: false,
                                visibleStatus: false
                            });
                            this.actions.reloadAsyncModel();
                            this.privates.selectedItems = [];
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            });
        },
        close() {
            this.setState({
                visible: false,
                visibleStatus: false
            });
        },
        showUploadModal() {
            this.privates.isShowUploadModal = true;
            this.actions.update();
        },

        closeUploadModal() {
            this.privates.isShowUploadModal = false;
            this.actions.update();
        },
        refresh() {
            let { total } = this.privates.paging;

            if (
                this.privates.paging.pageNum !== 1 &&
                (this.privates.paging.pageNum - 1) * this.privates.paging.pageSize ===
                total - 1
            ) {
                this.privates.paging.pageNum--;
                this.actions.update();
            }

            this.actions.reloadAsyncModel();
        },
        createCallList() {
            this.setState({
                visible: true,
                visibleStatus: true,
                modalData: []
            });
        },
        exportCallList() {
            let { locales } = this;
            let { sdkOptions } = this.props;

            let url = Udesk.business.apiPath.concatApiPath(
                `agent/export`,
                sdkOptions
            );
            let params = {
                checkExceptionData: this.privates.checkExceptionData,
                keyWord: this.privates.keyWord,
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, params).then(
                resp => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.exportSuccessFormat,
                            locales.components.pages.components.listManage.records
                        )
                    );
                    this.privates.selectedItems = [];
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.exportFailedFormat,
                            locales.components.pages.components.listManage.records
                        )
                    );
                }
            );
        },
        listStatus(e) {
            if (e.target.checked) {
                this.privates.checkExceptionData = 1;
            } else {
                this.privates.checkExceptionData = 0;
            }
            this.privates.paging.pageNum = 1;
            this.actions.update();
            this.actions.reloadAsyncModel();
        },
        onchangeItems(event) {
            event.persist();
            this.privates.keyWord = event.target.value;
            this.actions.update();
        },
        searchItems(e) {
            if (e.keyCode === 13) {
                this.privates.paging.pageNum = 1;
                this.actions.update();
                this.actions.reloadAsyncModel();
            }
            return;
        },
        grounpChange(value) {
            if (value === "add") {
                this.privates.grounpType = "add";
                this.privates.grounpVisible = true;
            } else if (value === "emd") {
                this.privates.grounpType = "emd";
                this.privates.grounpVisible = true;
            }
            this.actions.update();
        },
        onCheckChange(value) {
            this.privates.selectedItems = value;
            let list = [];
            value.forEach(item => {
                item.agentGroups.forEach(items => {
                    if (!_includes(list, items.id)) {
                        list.push(items.id);
                    }
                });
            });
            if (list.length > 5) {
                this.privates.grounpFields = list.slice(0, 5);
            } else {
                this.privates.grounpFields = list;
            }
            this.actions.update();
        },
        grounpSave() {
            let { grounpType, selectedItems, grounpFields } = this.privates;
            let { sdkOptions } = this.props;
            const locales = Locales['current'];

            let data = {
                agentIds: selectedItems.map(item => item.id),
                groupIds: grounpFields
            };
            if (grounpType === "add") {
                let url = Udesk.business.apiPath.concatApiPath(
                    `agentGroups/join-group`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(url, data).then(
                        resp => {
                            resolve(resp);
                            this.privates.grounpSelect = locales.fix.batchOperation;
                            this.privates.selectedItems = [];
                            this.privates.grounpVisible = false;
                            this.actions.grounpFields = [];
                            this.actions.reloadAsyncModel();
                            this.privates.selectedItems = [];
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            } else if (grounpType === "emd") {
                let url = Udesk.business.apiPath.concatApiPath(
                    `agentGroups/remove-group`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(url, data).then(
                        resp => {
                            resolve(resp);
                            this.privates.grounpSelect = locales.fix.batchOperation;
                            this.privates.selectedItems = [];
                            this.actions.grounpFields = [];
                            this.privates.grounpVisible = false;
                            this.actions.reloadAsyncModel();
                            this.privates.selectedItems = [];
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
        grounpCancel() {
            const locales = Locales['current'];

            this.privates.grounpVisible = false;
            this.actions.grounpFields = [];
            this.privates.grounpSelect = locales.fix.batchOperation;
            this.actions.update();
        },
        onChangeGrounp(value) {
            this.privates.grounpFields = value;
            this.actions.update();
        },
        groundpDataMap(data, level) {
            if (data && data.length > 0) {
                data.forEach(item => {
                    item.title = item.agentGroupName;
                    item.value = item.id;
                    item.key = `grounp-${item.id}`;
                    item.level = level;
                    if (item.children) {
                        this.actions.groundpDataMap(item.children, level + 1);
                    }
                });
            }
        },
        grounpFieldDel(value) {
            let { selectedItems } = this.privates;
            this.privates.selectedItems = _filter(
                selectedItems,
                current => current.id !== value.id
            );
            this.actions.update();
        },
        deleteRecordCancel() {
            this.setState({
                deletePopVisible: false,
            });
        },
        deleteRecordOk() {
            const locales = Locales['current'];

            this.setState({
                deletePopVisible: false,
            });
            let { selectedItems } = this.privates;
            let { sdkOptions } = this.props;
            let data = {
                ids: selectedItems.map(item => item.id)
            };
            let url = Udesk.business.apiPath.concatApiPath(
                `agent/delete/batch`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.del(url, data).then(
                    resp => {
                        resolve(resp);
                        Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                        this.privates.grounpSelect = locales.fix.batchOperation;
                        this.privates.selectedItems = [];
                        this.actions.grounpFields = [];
                        this.actions.reloadAsyncModel();
                        this.privates.selectedItems = [];
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        grounpSaveChange(value, id) {
            const locales = Locales['current'];

            if (!id) {
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(
                    `agentGroups`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(url, value).then(
                        resp => {
                            Udesk.ui.notify.success(locales.business.notifyMessage.addSuccess);
                            this.actions.reloadAsyncModel();
                            this.privates.selectedItems = [];
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            } else {
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(
                    `agentGroups/${value.id}`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.put(url, value).then(
                        resp => {
                            Udesk.ui.notify.success(locales.business.notifyMessage.editSuccess);
                            this.actions.reloadAsyncModel();
                            this.privates.selectedItems = [];
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
        groupNodeDelChange(id) {
            let { sdkOptions } = this.props;
            const locales = Locales['current'];

            let url = Udesk.business.apiPath.concatApiPath(
                `agentGroups/${id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.del(url).then(
                    resp => {
                        Udesk.ui.notify.success(locales.business.notifyMessage.deleteSuccess);
                        this.actions.reloadAsyncModel();
                        this.privates.selectedItems = [];
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onSelectSearch(list) {
            let id = '';
            if (isNumber(list) || isString(list)) {
                id = list;
            } else if (isArray(list)) {
                let value = list[0];
                if (value.indexOf('grounp') !== -1) {
                    id = value.split('-')[1];
                }
            }
            this.privates.paging.pageNum = 1;
            this.privates.paging.pageSize = 10;
            this.privates.grounpId = id;
            this.actions.reloadAsyncModel();
        }
    };

    //#region Life Cycle
    componentDidMount() {
        getColumns(this);
    }
    componentWillUnmount() { }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult, asyncKey } = parseOptions;
        if (asyncKey === "grounpData") {
            if (asyncResult.grounpData) {
                let list = asyncResult.grounpData;
                this.actions.groundpDataMap(list, 1);
                this.privates.grounpDataList = list;
            }
            if (asyncResult.listData) {
                if (asyncResult.listData.data) {
                    this.privates.data = asyncResult.listData.data;
                }
                if (asyncResult.listData.paging) {
                    this.privates.paging = asyncResult.listData.paging;
                    let { pageNum, pageSize, total } = this.privates.paging;
                    this.trigger("onPageChanged", pageNum, pageSize, total);
                }
            }
            this.privates.loading = false;
            this.actions.update();
        }
    }
    //#endregion
}
function getColumns(that) {
    let { actions, locales } = that;
    let colums = [
        {
            name: "agentNo",
            width: "20%",

            caption: locales.fields.customerInformation.list.agentNo
        },
        {
            name: "agentName",
            width: "10%",
            sortable: false,
            caption: locales.fields.customerInformation.list.agentName
        },
        {
            name: "agentTel",
            width: "20%",
            sortable: false,
            caption: locales.fields.customerInformation.list.agentTel
        },
        {
            name: "agentEmail",
            width: "20%",
            sortable: false,
            caption: locales.fields.customerInformation.list.agentEmail
        },
        {
            name: "agentGroups",
            width: "20%",
            sortable: false,
            caption: locales.fix.customerServicesGroup,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                if (item.agentGroups && item.agentGroups.length > 0) {
                    let list = item.agentGroups.map(items => {
                        return items.agentGroupName;
                    });
                    let str = list.join(",");
                    return str;
                } else {
                    return "--";
                }
            }
        },
        {
            name: "updateTime",
            width: "20%",

            caption: locales.fields.customerInformation.list.updateTime
        },
        {
            name: "actions",
            width: "20%",
            sortable: false,
            caption: locales.fields.customerInformation.list.actions,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return (
                    <div className="qa-react-page-body-content-table-actions">
                        <If
                            condition={
                                Udesk.data.init.user && Udesk.data.init.user.hasFeature("agent:info:edit")
                            }
                        >
                            <span
                                className="action-btn"
                                title={locales.labels.edit}
                                onClick={actions.editItem.params(item)}
                            >
                                {locales.labels.edit}
                            </span>
                        </If>
                        <If
                            condition={
                                Udesk.data.init.user &&
                                Udesk.data.init.user.hasFeature("agent:info:delete")
                            }
                        >
                            <span
                                className="action-btn delete-btn"
                                title={locales.labels.delete}
                                onClick={actions.deleteItem.params(item)}
                            >
                                {locales.labels.delete}
                            </span>
                        </If>
                    </div>
                );
            }
        }
    ];
    that.privates.columns = colums;
    that.actions.update();
}
export default CustomerServiceInformationComponent;
