// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerByTaskIdPageDetailParam,
    BaseResponseIntelligentPartnerPageDetailFoundResponse,
} from '../../../types';

/**
 * 考试/练习页面详情
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [GET] /intelligentPartner/{taskId}/pageDetail
 * @param taskId
 */
export function getIntelligentPartnerByTaskIdPageDetail(
    options: ApiOptions<GetIntelligentPartnerByTaskIdPageDetailParam, never> & {
        segments: GetIntelligentPartnerByTaskIdPageDetailParam;
    }
): Promise<BaseResponseIntelligentPartnerPageDetailFoundResponse> {
    return request<never, GetIntelligentPartnerByTaskIdPageDetailParam, never>(
        '/intelligentPartner/{taskId}/pageDetail',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerByTaskIdPageDetail',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/{taskId}/pageDetail',
        method: 'get',
    },
];
