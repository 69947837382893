import React from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
// import SubRoutes from 'udesk-react/src/components/sub-routes';
import Udesk from 'Udesk';
import routesGlobal from './routes/routes-global';
import NewVersion from 'udesk-react/src/components/new-version';
import { ENABLE_RELOAD_BUTTON_SYMBOL } from 'udesk-react/src/components/new-version/base.js';
import UdeskQaSdkClass from './index';
import Locales from 'UdeskLocales';
import { routeComponentByRouteNameMappings } from './routes/routeMappings';

let AllRoutes = buildRoutes($.extend(true, [], routesGlobal));
Udesk.ui.routing._initRoutes(AllRoutes);

function buildRoutes(routes, parentPath) {
    if (routes == null || !(routes instanceof Array)) {
        routes = [];
    }
    routes = routes.map((route) => {
        if (route.routes instanceof Array && route.routes.length > 0) {
            route.routes = buildRoutes(route.routes);
        }
        if (Object.prototype.hasOwnProperty.call(routeComponentByRouteNameMappings, route.name)) {
            route.component = routeComponentByRouteNameMappings[route.name].component;
        }
        return route;
    });
    return routes;
}

class App extends React.Component {
    getHistory() {
        return this.props.history;
    }
    render() {
        return (
            <div className='udesk-qa-web-route-entry' style={{ width: '100%', height: '100%' }}>
                {this.props.children}
            </div>
        );
    }
}

const AppRoute = withRouter(Udesk.react.udeskify(App));

class UdeskWebappQaRoutesComponent extends React.Component {
    static defaultProps = {
        udeskContext: null,
    };

    state = {
        // context: Udesk.react.context.defaults
        context: this.props.udeskContext,
        isSdkNewVersion: false,
    };
    privates = {
        newVersionInstance: null,
    };

    actions = {
        reload() {
            UdeskQaSdkClass.reload(
                () => {},
                () => {
                    this.privates.isSdkNewVersion = true;
                    this.privates.newVersionInstance[ENABLE_RELOAD_BUTTON_SYMBOL](false);
                }
            );
        },
    };

    getHistory() {
        return this.appRouteInstance.getHistory();
    }

    componentDidMount() {
        this.state.context.on('changed', (context) => {
            this.setState({
                context: context,
            });
        });
        let sdkEvents = this.props.sdkEvents;
        if (sdkEvents != null) {
            sdkEvents.on('onShowNewVersionTip', () => {
                this.setState({
                    isSdkNewVersion: true,
                });
                if (this.state.context && this.state.context.props.sdkOptions) {
                    let sdkOptions = this.state.context.props.sdkOptions;
                    if (sdkOptions.methods.tip != null && typeof sdkOptions.methods.tip === 'function') {
                        sdkOptions.methods.tip(Locales.get('labels.versionUpdate', sdkOptions.props.language));
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this.privates.newVersionInstance = null;
        if (this.state && this.state.context && typeof this.state.context.off === 'function') {
            this.state.context.off();
        }
        let sdkEvents = this.props.sdkEvents;
        if (sdkEvents != null) {
            sdkEvents.off('onShowNewVersionTip');
        }
    }

    render() {
        let { state } = this;
        return (
            <React.StrictMode>
                <Udesk.react.context.UdeskProvider value={state.context}>
                    <NewVersion
                        ref={(instance) => (this.privates.newVersionInstance = instance)}
                        isVisible={state.isSdkNewVersion}
                        onReload={this.actions.reload}
                    />
                    <Router>
                        <AppRoute onCreated={(instance) => (this.appRouteInstance = instance)}>
                            {AllRoutes.map((routeItem, i) => (
                                <Route
                                    key={i}
                                    path={routeItem.path}
                                    render={(props) => (
                                        <routeItem.component {...props} route={routeItem} routes={routeItem.routes} />
                                    )}
                                />
                                // <SubRoutes key={i} route={routeItem} />
                            ))}
                        </AppRoute>
                    </Router>
                </Udesk.react.context.UdeskProvider>
            </React.StrictMode>
        );
    }
}

export default UdeskWebappQaRoutesComponent;
