import React from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';


class ClassifiedTrainingHistoryIndexComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        startTime: moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00',
        endTime: moment().format('YYYY-MM-DD') + ' 23:59:59',
        columns: [
            {
                title: UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.user,
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.date,
                dataIndex: 'updateTime',
                key: 'updateTime',
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.data,
                dataIndex: 'dataSource',
                key: 'dataSource',
                render: status => {
                    return (
                        <span>
                            {
                                status === 1 ?
                                    UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.call
                                    : UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.dialogue
                            }
                        </span>
                    );
                }
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.id,
                dataIndex: 'callId',
                key: 'callId',
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.tagType,
                dataIndex: 'categoryName',
                key: 'categoryName',
            },
            {
                title: UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.tagName,
                dataIndex: 'tagName',
                key: 'tagName',
            },
        ],
        dataSource: [
            {
                id: 1,
                type: UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.call
            },
            {
                id: 2,
                type: UdeskLocales.current.components.pages.trainingCenter.classifiedTrainingHistory.table.dialogue
            }
        ],
        data: [],
        paging: {
            pageNum: 1,
            pageSize: 10,
            total: 0
        },
        smartTagCategorys: [],
        userKeyWords: '',
        userList: [],
        seletedUserId: [],
        seletedUserItem: [],
        id: '',
        idForSearch: '',
        seletedDataSource: 1,
        seletedCategoryId: [],
    };

    actions = {
        getUserList() {
            let {
                sdkOptions,
                // match
            } = this.props;
            let params = {
                pageSize: 1000,
            };
            let url = Udesk.business.apiPath.concatApiPath(
                this.privates.userKeyWords !== '' && this.privates.userKeyWords !== null ? `/users?keyword=${this.privates.userKeyWords}` : "/users",
                sdkOptions
            );
            Udesk.ajax.get(url, params).then(
                (resp) => {
                    this.privates.userList = resp.data;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg
                    );
                }
            );
        },
        loadUserOptions(value){
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                value ? `/users?keyword=${value}` : "/users",
                sdkOptions
            );
            let params = {
                pageSize: 1000,
            };
            return new Promise((resolve, reject) => {
                Udesk.ajax.get(url, params).then(
                    resp => {
                        resolve(
                            resp.data.map(item => {
                                return {
                                    id: item.id,
                                    name: item.realname,
                                };
                            })
                        );
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        selectTime(dateString) {
            this.privates.startTime = dateString[0];
            this.privates.endTime = dateString[1];
            this.actions.update();
        },
        search() {
            this.actions.searchBarHandle();
        },
        reset() {
            this.privates.startTime = moment().subtract('days', 30).format('YYYY-MM-DD') + ' 23:59:59';
            this.privates.endTime = moment().format('YYYY-MM-DD') + ' 23:59:59';
            this.privates.seletedUserId = [];
            this.privates.seletedDataSource = 1;
            this.privates.seletedCategoryId = [];
            this.privates.userKeyWords = '';
            this.privates.paging.pageNum = 1;
            this.privates.idForSearch = '';
            this.privates.id = '';
            this.actions.update();
            this.actions.reloadAsyncModel();
        },
        pageNumChange(pageNumber) {
            if (pageNumber !== this.privates.paging.pageNum) {
                this.privates.paging.pageNum = pageNumber;
                this.actions.reloadAsyncModel();
                this.actions.update();
            }
        },
        pageSizeChange(current, pageSize) {
            this.privates.paging.pageNum = 1;
            this.privates.paging.pageSize = pageSize;
            this.actions.reloadAsyncModel();
            this.actions.update();
        },
        selectTag(value) {
            this.privates.seletedCategoryId = value;
            this.actions.update();
        },
        selectDataSource(value) {
            this.privates.seletedDataSource = value;
            this.actions.update();
        },
        seleteUserFocus() {
            this.actions.getUserList();
            this.actions.update();
        },
        seleteUser(value) {
            this.privates.seletedUserId = value;
            // this.privates.userList.forEach(item => {
            //     value.forEach(v => {
            //         if(v === item.id){
            //             this.privates.seletedUserItem.push(item);
            //         }
            //     })
            // })
            this.privates.userKeyWords = '';
            this.actions.getUserList();
            this.actions.update();
        },
        inputUserKeyWords(value) {
            this.privates.userKeyWords = value;
            this.actions.getUserList();
            this.actions.update();
        },
        searchBarHandle(value) {
            this.privates.paging.pageNum = 1;
            this.privates.idForSearch = this.privates.id;
            this.actions.reloadAsyncModel();
        },
        searchBarHandleByEnter(e) {
            this.actions.searchBarHandle();
        },
        searchBarInputChange(e) {
            this.privates.id = e.target.value;
            this.actions.update();
        }
    };
    //#region Life Cycle
    componentDidMount() {}
    componentWillUnmount() { }
    //#endregion

}

export default ClassifiedTrainingHistoryIndexComponent;
