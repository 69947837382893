import React from 'react';
import Udesk from 'Udesk';
import _extend from 'lodash-es/extend';
import _find from 'lodash-es/find';
import _isEmpty from 'lodash-es/isEmpty';
import _compact from 'lodash-es/compact';
import _toString from 'lodash-es/toString';
import _size from 'lodash-es/size';
import _includes from 'lodash-es/includes';
class AudioCallContentInfoComponent extends React.Component {
    static defaultProps = {
        width: 340,
        heightList: [],
        highlightSeqList: [],
    };
    privates = {
        highlightSeqList: [],
        highlightSeqIndex: 1,
        appealSentenceList: [],
        audioTextListRef: React.createRef(),
        caseBaseLists: [],
        searchContentList: [],
        pointContentList: 0,
        keywords: '',
        caseBaseFields: [],
        fieldsList: [],
        libraryId: null,
        caseBaseId: null,
        fieldFlag: false,
        remark: '',
        editFlag: true,
        viewCheck: null,
        heightList: [],
        pointChildrenPerson: {},
        pointChildrenComputer: {},
        fieldsId: 'unnamed',
        itemId: null,
        timer: null,
        audioFlag: true,
        talkAudioFlag: false,
        playingRudio: new Audio(),
        type: Udesk.enums.applyRoles.all.id,
    };
    static computes = {
        audioTextItems: ({ props, state, privates, locales }) => {
            let audioTextItems = createAudioTextItems(
                props.sentenceList,
                privates.highlightSeqList,
                privates.highlightSeqIndex,
                privates.highlightGroupList,
                props.inspectDataSource,
                props.pointList,
                props.point,
                privates
            );
            return audioTextItems;
        },
    };
    actions = {
        keywordsChanged(e) {
            this.privates.keywords = e.target.value;
            this.actions.update();
        },
        selectType(value) {
            this.privates.type = value;
            this.actions.update();
        },
        searchContet() {
            let searchContet = this.privates.keywords && this.privates.keywords.trim();
            this.actions.audioStop();
            if (searchContet === '') return;
            // 先清除质检点命中高亮信息
            this.actions.closeHit();
            const keywords = searchContet.split(/ +/);
            let audioTextListDOM = this.privates.audioTextListRef.current;
            let audioTextListElems = audioTextListDOM.getElementsByClassName(
                'audio-call-content-view-custom-text-info-view'
            );
            for (let i = 0; i < audioTextListElems.length; i++) {
                Udesk.utils.dom.clearHighlight(audioTextListElems[i]);
                if (
                    this.privates.computes?.audioTextItems[i]?.data?.role !== this.privates.type &&
                    this.privates.type !== Udesk.enums.applyRoles.all.id
                ) {
                    continue;
                }
                keywords.forEach((item) => {
                    Udesk.utils.dom.highlightText(audioTextListElems[i], item);
                });
            }
            this.privates.searchContentList = audioTextListDOM.getElementsByTagName('em');
            let searchedFirst = audioTextListDOM.getElementsByTagName('em')[0];
            if (searchedFirst) {
                searchedFirst.setAttribute('class', 'audio-text-list-item-text-hit');
                searchedFirst.scrollIntoViewIfNeeded && searchedFirst.scrollIntoViewIfNeeded();
            }
            this.actions.update();
        },
        changeAppealType(item, event) {
            item.data.complainType = event.target.value;
            this.actions.getAppealSentenceList();
        },
        changeAppealReason(item, event) {
            item.data.complainReason = event.target.value;
            this.actions.getAppealSentenceList();
        },
        changeReasonEditStatus(item, status) {
            item.data.reasonEditStatus = status;
            this.actions.getAppealSentenceList();
        },
        getAppealSentenceList() {
            let { audioTextItems } = this.privates.computes;
            let { appealSentenceList } = this.privates;
            appealSentenceList = audioTextItems
                .filter((item) => {
                    return item.data.complainType;
                })
                .map((item) => {
                    return item.data;
                });
            this.trigger('onSelectedSentenceListChanged', appealSentenceList);
        },
        sendCurrentTimeRange(item) {
            this.trigger('onTimeRangeChanged', item);
        },
        markSemantic(content) {
            this.trigger('onMarkSemanticClick', content);
        },
        nextHit(type) {
            let { highlightSeqList } = this.privates;
            if (type === 'up' && this.privates.highlightSeqIndex > 1) {
                this.privates.highlightSeqIndex -= 1;
            }
            if (type === 'down' && this.privates.highlightSeqIndex < highlightSeqList.length) {
                this.privates.highlightSeqIndex += 1;
            }
            this.actions.update();
            this.forceUpdate(() => {
                let audioCallInfoDOM = this.privates.audioTextListRef.current;
                let searchedFirst = audioCallInfoDOM.getElementsByClassName(
                    'audio-text-list-item-text-hit'
                )[0];
                searchedFirst &&
                    searchedFirst.scrollIntoViewIfNeeded &&
                    searchedFirst.scrollIntoViewIfNeeded();
            });
        },
        closeHit() {
            this.privates.highlightSeqList = [];
            this.privates.highlightGroupList = [];
            this.privates.pointContentList = 0;
            this.privates.highlightSeqIndex = 1;
            clearPointHighlight(this);
            this.actions.update();
            this.trigger('audioPlay');
            this.trigger('onCloseHitClick');
        },
        nextSearched(type) {
            let { searchContentList, highlightSeqIndex } = this.privates;
            searchContentList[highlightSeqIndex - 1].removeAttribute('class');
            if (type === 'up' && this.privates.highlightSeqIndex > 1) {
                this.privates.highlightSeqIndex -= 1;
            }
            if (type === 'down' && this.privates.highlightSeqIndex < searchContentList.length) {
                this.privates.highlightSeqIndex += 1;
            }
            this.actions.update();
            let next = searchContentList[this.privates.highlightSeqIndex - 1];
            next.setAttribute('class', 'audio-text-list-item-text-hit');
            next && next.scrollIntoViewIfNeeded && next.scrollIntoViewIfNeeded();
        },
        closeSearched() {
            clearSearchedHighlight(this);
            this.privates.highlightSeqIndex = 1;
            this.privates.searchContentList = [];
            this.privates.keywords = '';
            this.trigger('audioPlay');
            this.actions.update();
        },
        caseBaselist(value) {
            let list = [];
            let { caseBaseFields } = this.privates;
            if (_isEmpty(this.privates.caseBaseFields)) {
                list = [
                    ...caseBaseFields,
                    {
                        id: value.id,
                        caseBaseFlag: value.flag,
                        field: value.inputValue,
                    },
                ];
            } else {
                if (_find(this.privates.caseBaseFields, (current) => current.id === value.id)) {
                    list = this.privates.caseBaseFields.map((item) => {
                        if (item.id === value.id) {
                            item.caseBaseFlag = value.flag;
                            item.field = value.inputValue;
                        }
                        return item;
                    });
                } else {
                    list = [
                        ...caseBaseFields,
                        {
                            id: value.id,
                            caseBaseFlag: value.flag,
                            field: value.inputValue,
                        },
                    ];
                }
            }

            this.privates.caseBaseFields = list;
        },
        inputFlag(value) {
            if (!_isEmpty(this.privates.inputFlagList)) {
                let list = [];
                if (_find(this.privates.inputFlagList, (current) => current.id === value.id)) {
                    list = this.privates.inputFlagList.map((item) => {
                        if (item.id === value.id) {
                            item.flag = value.flag;
                        }
                        return item;
                    });
                } else {
                    list = [...this.privates.inputFlagList, value];
                }
                this.privates.inputFlagList = list;
                if (_find(list, (current) => current.flag === false)) {
                    this.privates.editFlag = false;
                } else {
                    this.privates.editFlag = true;
                }
            } else {
                let list = [value];
                this.privates.inputFlagList = list;
                if (_find(list, (current) => current.flag === false)) {
                    this.privates.editFlag = false;
                } else {
                    this.privates.editFlag = true;
                }
            }
        },
        save() {
            this.actions.audioStop();
            let { caseBaseLists, caseBaseFields, caseBaseId, remark, editFlag, fieldsList } =
                this.privates;
            let { detail } = this.props;
            let { locales } = this;
            if (editFlag) {
                let list = [];
                if (
                    (_isEmpty(caseBaseLists) &&
                        detail.dialogRecords.dialogSentenceList &&
                        detail.inspectDataSource === 1) ||
                    detail.inspectDataSource === 3
                ) {
                    caseBaseLists = detail.dialogRecords.dialogSentenceList.map((item, index) => {
                        return {
                            ...item,
                            id: index + 1,
                        };
                    });
                } else if (
                    (_isEmpty(caseBaseLists) &&
                        detail.dialogRecords.dialogSentenceList &&
                        detail.inspectDataSource === 2) ||
                    detail.inspectDataSource === 4
                ) {
                    caseBaseLists = detail.dialogRecords.dialogSentenceList.map((item, index) => {
                        return {
                            ...item,
                            id: index + 1,
                        };
                    });
                }
                if (
                    !fieldsList.dialogText &&
                    !_isEmpty(caseBaseFields) &&
                    fieldsList.id &&
                    fieldsList.libraryId
                ) {
                    caseBaseFields = caseBaseLists.map((item) => {
                        let fields = _find(caseBaseFields, (current) => current.id === item.id);
                        if (fields && fields.caseBaseFlag) {
                            return {
                                id: item.id,
                                caseBaseFlag: true,
                                field: item.text,
                            };
                        } else if (fields && !fields.caseBaseFlag) {
                            return {
                                id: item.id,
                                caseBaseFlag: false,
                                field: item.text,
                            };
                        } else {
                            return {
                                id: item.id,
                                caseBaseFlag: true,
                                field: item.text,
                            };
                        }
                    });
                    caseBaseFields = _compact(caseBaseFields);
                }
                caseBaseLists.forEach((item) => {
                    let caseBaseIds = _find(caseBaseFields, (current) => {
                        return _toString(current.id) === _toString(item.id);
                    });
                    if (caseBaseIds && caseBaseIds.caseBaseFlag) {
                        if (detail.inspectDataSource === 1 || detail.inspectDataSource === 3) {
                            list = [
                                ...list,
                                {
                                    begin_time: item.fromSec * 1000 || '',
                                    channel_id: item.role === 'agent' ? 1 : 0,
                                    end_time: item.endSec * 1000 || '',
                                    text: caseBaseIds.field,
                                    id: caseBaseIds.id,
                                },
                            ];
                        } else {
                            list = [
                                ...list,
                                {
                                    text: item.content,
                                    createdTime: item.createdTime,
                                    id: item.id,
                                    channel_id: item.role === 'agent' ? 1 : 0,
                                },
                            ];
                        }
                    }
                });
                let data = {
                    dialogText: _size(list) > 0 ? JSON.stringify(list) : null,
                    inspectionDataId: detail.id,
                    libraryId: this.privates.libraryId,
                    remarks: this.props.qcsRef.current.privates.remark || remark,
                };
                if (caseBaseId) {
                    _extend(data, { id: caseBaseId });
                }
                if (this.privates.libraryId) {
                    let { sdkOptions } = this.props;
                    let caseBaseUrl = Udesk.business.apiPath.concatApiPath(
                        `caseLibraryDatas`,
                        sdkOptions
                    );
                    new Promise((resolve, reject) => {
                        Udesk.ajax.post(caseBaseUrl, data).then(
                            (resp) => {
                                resolve(resp);
                                Udesk.ui.notify.success(
                                    locales.components.pages.caseBaseList.detail.successed
                                );
                                this.actions.roadListSelect(this.privates.libraryId);
                                this.trigger('onUpdate');
                            },
                            (reason) => {
                                Udesk.ui.notify.error(reason.errorMsg);
                                reject(reason);
                            }
                        );
                    });
                } else {
                    Udesk.ui.notify.error(locales.components.pages.caseBaseList.detail.caseType);
                }
            } else {
                Udesk.ui.notify.error(locales.components.pages.caseBaseList.detail.info);
            }
        },
        selectChange(value) {
            if (value !== 'unnamed') {
                this.actions.roadListSelect(value);
                this.privates.fieldFlag = true;
            } else {
                this.privates.fieldFlag = false;
            }
            this.privates.fieldsId = value;
            this.actions.update();
        },
        roadListSelect(value) {
            this.privates.libraryId = value;
            let { sdkOptions, detail, id, caseBaseUnRemrks } = this.props;
            let caseBaseUrl = Udesk.business.apiPath.concatApiPath(
                `caseLibraryDatas/caseSearch/${value}/${detail.id ? detail.id : id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(caseBaseUrl).then(
                    (resp) => {
                        resolve(resp);
                        let lists;
                        if (resp.data.dialogText) {
                            lists = JSON.parse(resp.data.dialogText);
                            let list = lists.map((item) => {
                                return { id: item.id, caseBaseFlag: true, field: item.text };
                            });
                            this.privates.caseBaseFields = list;
                        } else {
                            this.privates.caseBaseFields = [];
                        }
                        this.privates.fieldsList = {
                            ...resp.data,
                            dialogText: lists || null,
                        };
                        this.privates.caseBaseId = resp.data.id;
                        caseBaseUnRemrks && caseBaseUnRemrks(resp.data.remarks, true);
                        this.privates.remark = resp.data.remarks;
                        this.privates.caseBaseFlag = value;
                        this.privates.caseBaseLists = [];
                        this.forceUpdate(() => {
                            this.privates.caseBaseFlag = value;
                        });
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            this.actions.update();
        },
        viewCheckd(value) {
            this.privates.viewCheck = value;
            this.trigger('dropChecked', value);
            this.actions.update();
        },
        heightListHit(id, list) {
            let obj = list.find((item) => parseInt(item.id, 10) === parseInt(id, 10));
            if (obj) {
                return obj;
            } else {
                return {};
            }
        },
        checkedChanged(id, list) {
            let obj = list.find((item) => parseInt(item.id, 10) === parseInt(id, 10));
            if (obj) {
                return true;
            } else {
                return false;
            }
        },
        RadioChange(id, list, mark, e) {
            let data = {
                id,
                pointId: mark.id,
                name: mark.name,
                flag: e.target.checked,
            };
            this.props.checkedProps && this.props.checkedProps(data);
        },
        //质检点高亮处理逻辑
        pointHighlight(value) {
            clearTimeout(this.privates.timer);
            this.privates.timer = setTimeout(() => {
                clearPointHighlight(this);
                const { related } = this.props.detail;

                let highlightGroupList = this.privates.highlightGroupList;
                let highlightGroupListCurrent = this.privates.highlightGroupList;

                let audioTextListDOM = this.privates.audioTextListRef.current;
                if (highlightGroupList && highlightGroupList.length > 0) {
                    if (value >= 1 && related && highlightGroupList?.[value - 1]?.callId) {
                        let callIdCurrent = highlightGroupList[value - 1].callId;
                        this.props.changeToHighlightItemsCallDetail?.(callIdCurrent);
                        highlightGroupListCurrent = this.privates.highlightGroupList.map((item) => {
                            if (!related || item.callId === callIdCurrent) {
                                return item;
                            }
                            return {
                                highlightItemList: [{}],
                            };
                        });
                    }
                    let audioTextListElemsView = audioTextListDOM.getElementsByClassName(
                        'audio-call-content-view-custom-text-info-view'
                    );
                    let audioTextListElemsTime = audioTextListDOM.getElementsByClassName(
                        'audio-call-content-view-costom-text-time'
                    );
                    for (let i = highlightGroupListCurrent.length - 1; i >= 0; i--) {
                        for (
                            let j = highlightGroupListCurrent[i].highlightItemList.length - 1;
                            j >= 0;
                            j--
                        ) {
                            //关联质检情况下，过滤非同一通对话/通话的高亮
                            //
                            // if(related && callIdCurrent !== highlightGroupList[i].callId){
                            //     continue;
                            // }
                            if (
                                highlightGroupListCurrent[i].highlightItemList[j].highlightType ===
                                    1 ||
                                !highlightGroupListCurrent[i].highlightItemList[j].highlightType
                            ) {
                                Udesk.utils.dom.highlightTextIndex(
                                    audioTextListElemsView[
                                        highlightGroupListCurrent[i].highlightItemList[j].index - 1
                                    ],
                                    highlightGroupListCurrent[i].highlightItemList[j],
                                    {
                                        className: `audio-text-list-item-text-hit-status audio-text-list-item-text-hit-status_${
                                            i + 1
                                        }`,
                                    }
                                );
                            } else {
                                Udesk.utils.dom.highlightTextIndex(
                                    audioTextListElemsTime[
                                        highlightGroupListCurrent[i].highlightItemList[j].index - 1
                                    ],
                                    highlightGroupListCurrent[i].highlightItemList[j],
                                    {
                                        className: `audio-text-list-item-text-hit-status audio-text-list-item-text-hit-status_${
                                            i + 1
                                        }`,
                                    }
                                );
                            }
                        }
                    }
                    this.privates.pointContentList = highlightGroupList.length;
                    let searchedFirst = audioTextListDOM.querySelectorAll(
                        `em.audio-text-list-item-text-hit-status_${value}`
                    );
                    if (searchedFirst && searchedFirst.length > 0) {
                        for (let i = 0; i < searchedFirst.length; i++) {
                            searchedFirst[i].setAttribute('class', 'audio-text-list-item-text-hit');
                        }
                        searchedFirst[0].scrollIntoViewIfNeeded &&
                            searchedFirst[0].scrollIntoViewIfNeeded();
                    }
                    this.actions.update();
                }
            }, 100);
        },
        nextPointed(type) {
            if (type === 'up' && this.privates.highlightSeqIndex > 1) {
                this.privates.highlightSeqIndex -= 1;
            }
            if (
                type === 'down' &&
                this.privates.highlightSeqIndex < this.privates.pointContentList
            ) {
                this.privates.highlightSeqIndex += 1;
            }
            this.actions.pointHighlight(this.privates.highlightSeqIndex);
            this.actions.update();
        },
        timeChange(value) {
            let { audioFlag, talkAudioFlag } = this.privates;
            if (audioFlag && !talkAudioFlag) {
                this.privates.highlightSeqList = [];
                this.privates.pointContentList = 0;
                this.privates.highlightSeqIndex = 1;
                this.privates.searchContentList = [];
                let { detail } = this.props;
                let { audioTextListRef } = this.privates;
                let scrollItems = document.querySelectorAll('div.audio-call-content-body-list');
                let itemValue = 0;
                if (
                    detail &&
                    detail.dialogRecords &&
                    detail.dialogRecords.dialogSentenceList &&
                    detail.dialogRecords.dialogSentenceList.length > 0
                ) {
                    let list = detail.dialogRecords.dialogSentenceList;
                    for (let i = 0; i < list.length; i++) {
                        if (list[i].endSec >= value) {
                            itemValue = i;
                            this.privates.itemId = i + 1;
                            this.actions.update();
                            break;
                        }
                    }
                }
                audioTextListRef &&
                    audioTextListRef.current &&
                    (audioTextListRef.current.scrollTop =
                        scrollItems[itemValue] && scrollItems[itemValue].offsetTop - 100);
            }
        },
        audioStop() {
            this.privates.audioFlag = false;
            // this.actions.update();
        },
        audioPlay() {
            this.privates.audioFlag = true;
            // this.actions.update();
        },
        playRudio(url) {
            this.privates.playingRudio.pause();
            this.privates.playingRudio.src = url;
            this.privates.playingRudio.play();
        },
        exportTxt() {
            const { sdkOptions, detail, isRecordDetail } = this.props;
            let id = detail.id;
            if (!isRecordDetail) {
                id = detail.callId;
            }
            if (!id) {
                return;
            }
            this.privates.downUrl = Udesk.business.apiPath.concatApiPath(
                `/data/dialog/download/${id}`,
                sdkOptions
            );
            this.actions.update();
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        if (!props.isRecordDetail && props.detail.related) {
            if (this.privates.keywords && props.detail.callId !== prevProps.detail.callId) {
                this.actions.closeSearched();
            }
            if (prevProps.highlightGroupList === props.highlightGroupList) {
                return {};
            }
        }

        let privates = {
            highlightSeqList: props.highlightSeqList || [],
            highlightSeqIndex: 1,
            point: props.point,
            caseBaseFlag: props.caseBaseFlag,
            keywords: props.keywords,
            heightList: props.heightList,
            markFlag: props.MarkFlag,
            pointChildrenPerson: props.pointChildrenPerson,
            pointChildrenComputer: props.pointChildrenComputer,
            testSetListFlag: props.testSetListFlag,
            highlightGroupList: props.highlightGroupList || [],
            waveAudio: props.waveAudio,
            talkAudioFlag: props.talkAudioFlag,
            type:
                Udesk.enums.applyRolesWithRobot.find(
                    (item) => item.keywordType === props.keywordType
                )?.id ?? Udesk.enums.applyRoles.all.id,
        };
        if (props.keywords === '') {
            privates.searchContentList = [];
        }
        // if(prevProps.sentenceList !== props.sentenceList){
        //     privates.highlightSeqIndex = 1;
        // }

        return privates;
    }
    componentDidMount() {
        clearSearchedHighlight(this);
        if (this.privates.keywords && this.privates.keywords !== '') {
            this.actions.searchContet();
        }
        if (this.props.caseLibraryId) {
            let urlData = Object.assign(
                {},
                Object.fromEntries(new URLSearchParams(this.props.caseLibraryId))
            );
            if (urlData.libraryId) {
                this.actions.roadListSelect(urlData.libraryId);
                this.privates.fieldsId = parseInt(urlData.libraryId, 10);
                this.privates.fieldFlag = true;
                this.actions.update();
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.highlightGroupList !== prevProps.highlightGroupList) {
            this.actions.pointHighlight(this.privates.highlightSeqIndex);
        }
        if (this.props.MarkFlag !== prevProps.MarkFlag) {
            this.privates.highlightSeqList = [];
            this.privates.pointContentList = 0;
            this.privates.highlightSeqIndex = 1;
            this.actions.update();
        }
        if (
            this.props.timeValue &&
            this.props.timeValue !== prevProps.timeValue &&
            this.props.timeValue !== 'null'
        ) {
            this.actions.timeChange(this.props.timeValue);
        } else if (
            this.props.timeValue === 'null' &&
            this.props.timeValue !== prevProps.timeValue
        ) {
            this.privates.itemId = null;
            this.actions.update();
        }
        if (this.props.detail !== prevProps.detail) {
            if (this.props.caseLibraryId) {
                let urlData = Object.assign(
                    {},
                    Object.fromEntries(new URLSearchParams(this.props.caseLibraryId))
                );
                if (urlData.libraryId) {
                    this.actions.roadListSelect(urlData.libraryId);
                    this.privates.fieldsId = parseInt(urlData.libraryId, 10);
                    this.privates.fieldFlag = true;
                    this.actions.update();
                }
            }
        }
    }
}
function createAudioTextItems(
    sentenceList,
    highlightSeqList,
    highlightSeqIndex,
    highlightGroupList,
    inspectDataSource,
    pointList,
    point,
    privates
) {
    let markedSentenceList = [];
    if (point && pointList && pointList.length > 0) {
        let targetPoint = pointList.find((item) => {
            return item.pointId === point.pointId;
        });
        if (targetPoint) {
            markedSentenceList = targetPoint.sentenceList;
        }
    }
    let audioTextItems = [];
    if (
        [
            Udesk.enums.inspectDataSources.voiceCall.id,
            Udesk.enums.inspectDataSources.wechatRadio.id,
        ].includes(inspectDataSource)
    ) {
        if (sentenceList && sentenceList.length > 0) {
            sentenceList.forEach((element, index) => {
                let isHit = false;
                let isHitStatus = false;
                if (_isEmpty(highlightGroupList)) {
                    isHit = highlightSeqList[highlightSeqIndex - 1] === index + 1;
                    isHitStatus = _includes(highlightSeqList, index + 1);
                }
                let isComplain = false;
                let targetSentence = null;
                if (markedSentenceList.length > 0) {
                    targetSentence = markedSentenceList.find((markedItem) => {
                        return markedItem.id === index + 1;
                    });
                    if (targetSentence) {
                        isComplain = true;
                    }
                }
                let item = {
                    data: {
                        ...element,
                        id: index + 1,
                        text: element.text,
                        dialogSentenceId: element.id,
                        fromSec: element.fromSec,
                        endSec: element.endSec,
                        role: element.role,
                        complainReason: '',
                        averageSpeakSpeed: element.averageSpeakSpeed,
                    },
                    states: {
                        isHit: isHit,
                        isHitStatus: isHitStatus,
                        isComplain: isComplain,
                        text: element.text,
                        content: {
                            data: {},
                        },
                    },
                };
                if (isComplain) {
                    item.data.complainType = targetSentence.complainType;
                    item.data.complainReason = targetSentence.complainReason;
                    item.data.reasonEditStatus = targetSentence.reasonEditStatus;
                }
                audioTextItems.push(item);
            });
        }
    } else if (
        [
            Udesk.enums.inspectDataSources.wechatDialogue.id,
            Udesk.enums.inspectDataSources.textDialogue.id,
            Udesk.enums.inspectDataSources.ticket.id,
        ].includes(inspectDataSource)
    ) {
        if (sentenceList && sentenceList.length > 0) {
            sentenceList.forEach((element, index) => {
                if (element && element.content) {
                    let isHit = false;
                    let isHitStatus = false;
                    if (_isEmpty(highlightGroupList)) {
                        isHit = highlightSeqList[highlightSeqIndex - 1] === index + 1;
                        isHitStatus = _includes(highlightSeqList, index + 1);
                    }
                    let isComplain = false;
                    let targetSentence = null;
                    if (markedSentenceList.length > 0) {
                        targetSentence = markedSentenceList.find((markedItem) => {
                            return markedItem.id === element.id;
                        });
                        if (targetSentence) {
                            isComplain = true;
                        }
                    }

                    let item = {
                        data: {
                            id: index + 1,
                            content: element.content,
                            dialogSentenceId: element.id,
                            role: element.sender,
                            createdTime: element.created_at,
                            describes: element.describes,
                            complainReason: '',
                            avatar: element.avatar,
                            nickName: element.nickName || element.senderUser,
                            sendStatus: element.send_status,
                        },
                        states: {
                            isHit: isHit,
                            isHitStatus: isHitStatus,
                            content: JSON.parse(element.content),
                        },
                    };
                    if (isComplain) {
                        item.data.complainType = targetSentence.complainType;
                        item.data.complainReason = targetSentence.complainReason;
                        item.data.reasonEditStatus = targetSentence.reasonEditStatus;
                    }
                    if (Udesk.enums.inspectDataSources.ticket.id === inspectDataSource) {
                        item.data = { ...element, ...item.data };
                    }
                    audioTextItems.push(item);
                }
            });
        }
    }
    audioTextItems.forEach((item) => {
        if (item?.data?.content) {
            item.allData = JSON.parse(item.data.content);
        } else {
            item.allData = {};
        }
    });
    return audioTextItems;
}

function clearSearchedHighlight(that) {
    let audioTextListDOM = that.privates.audioTextListRef.current;
    if (audioTextListDOM) {
        let audioTextListElems = audioTextListDOM.getElementsByClassName(
            'audio-call-content-view-custom-text-info-view'
        );
        for (let i = 0; i < audioTextListElems.length; i++) {
            Udesk.utils.dom.clearHighlight(audioTextListElems[i]);
        }
    }
}
function clearPointHighlight(that) {
    let audioTextListDOM = that.privates.audioTextListRef.current;
    if (audioTextListDOM) {
        let audioTextListElems = audioTextListDOM.getElementsByClassName(
            'audio-call-content-view-custom-text-info-view'
        );
        let audioTextListElemsTime = audioTextListDOM.getElementsByClassName(
            'audio-call-content-view-costom-text-time'
        );
        for (let i = 0; i < audioTextListElems.length; i++) {
            Udesk.utils.dom.clearHighlight(audioTextListElems[i]);
        }
        for (let i = 0; i < audioTextListElems.length; i++) {
            Udesk.utils.dom.clearHighlight(audioTextListElemsTime[i]);
        }
    }
}
export default AudioCallContentInfoComponent;
