import Udesk from 'Udesk';
import React from 'react';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';

class dataAnalysis extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let { route, location, sdkOptions } = this.props;

        let { locales } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('biz:statistics:root')) {
            navLists.push({
                text: locales.components.pages.dataAnalysis.businessStatistics,
                pathName: 'businessStatistics',
                isActive: (match, location) => {
                    return location.pathname.includes('/business-statistics');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('inspection:effect:root')) {
            navLists.push({
                text: locales.components.pages.dataAnalysis.qualityEffect,
                pathName: 'qualityEffect',
                isActive: (match, location) => {
                    return location.pathname.includes('/quality-effect');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('inspection:analysis:root')) {
            navLists.push({
                text: locales.components.pages.dataAnalysis.qualityAnalysis,
                pathName: 'qualityAnalysis',
                isActive: (match, location) => {
                    return location.pathname.includes('/quality-analysis');
                },
            });
        }
        if (
            Udesk.data.init.user &&
            Udesk.data.init.user.hasFeature('inspection:universalReport:root')
        ) {
            navLists.push({
                text: locales.components.pages.dataAnalysis.generalInspecitionReport,
                pathName: 'generalInspectionReportIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/general-inspection-report');
                },
            });
        }
        if (
            Udesk.data.init.user &&
            Udesk.data.init.user.hasFeature('inspection:inspectorWorkload:root')
        ) {
            navLists.push({
                text: locales.components.pages.dataAnalysis.fullAmountWork,
                pathName: 'fullAmountWorkIndex',
                isActive: (match, location) => {
                    return location.pathname.includes('/full-amount-work');
                },
            });
        }

        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('label:analysis:root')) {
            navLists.push({
                text: locales.components.pages.dataAnalysis.intelligentTagsAnalysis,
                pathName: 'intelligentTagsAnalysis',
                isActive: (match, location) => {
                    return location.pathname.includes('/intelligent-tags-analysis');
                },
            });
        }

        if (
            sdkOptions.props.landingPath != null &&
            this.isRedirect(route.path, location.pathname)
        ) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="semantic-intelligence">
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={locales.components.pages.menuTitleList.informationTitle}
                    />
                    {/* <div className="semantic-intelligence-nav col-md-2 col-sm-3 col-xs-12">
                        <NavBar navLists={navLists} classNames="semantic-intelligence-nav-container" navItemClassNames="semantic-intelligence-nav-item" activeClassNames="semantic-intelligence-nav-item-active" />
                    </div>
                    <div className="semantic-intelligence-content col-md-10 col-sm-9 col-xs-12">
                        <For each="route" index="i" of={routes}>
                            <If condition={!route.authCode || (route.authCode && Udesk.data.init.user.hasFeature(route.authCode))}>
                                <SubRoutes key={i} route={route} />
                            </If>
                        </For>
                    </div> */}
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(dataAnalysis);
