import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button, FormBuilder } from 'udesk-ui';
// import Locales from 'UdeskLocales';
import { UserTagsSelect } from 'Component/pages/components/coach/UserTagsSelect';
import { useCustomerPortraitConfigContext } from '../Context';
import UdeskLocales from 'UdeskLocales';
/* cover?: string;

customerTags?: IntelligentPartnerTagGroup[];

desc?: string;

flowId?: number;

id?: number;

name?: string; */
const InternalCustomerPortraitDetail = React.memo(
    React.forwardRef((props: any, ref) => {
        // const locales = Locales['current'];
        const { id, ...detail } = props;
        const [form] = FormBuilder.useForm();
        useEffect(() => {
            form.resetFields();
        }, [id]);
        useImperativeHandle(ref, () => {
            return form;
        });
        return (
            <FormBuilder
                form={form}
                initialValues={detail}
                footerRender={false}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                customComponents={{ UserTagsSelect }}
                fields={[
                    {
                        type: 'Input',
                        name: 'name',
                        label: /* 画像名称 */ UdeskLocales['current'].components.coach
                            .customerPortrait.detail.index.portraitName,
                        props: {
                            showCount: true,
                            maxCount: 20,
                            placeholder: UdeskLocales['current'].labels.pleaseEnter,
                        },
                        rules: [
                            {
                                required: true,
                            },
                            {
                                max: 20,
                            },
                        ],
                    },
                    {
                        type: 'TextArea',
                        name: 'desc',
                        label: /* 背景描述 */ UdeskLocales['current'].components.coach
                            .customerPortrait.detail.index.backgroundDescription,
                        props: {
                            // showCount: true,
                            // maxCount: 100,
                            placeholder: UdeskLocales['current'].labels.pleaseEnter,
                        },
                        // rules: [
                        //     {
                        //         max: 100,
                        //     },
                        // ],
                    },
                    {
                        type: 'UserTagsSelect',
                        name: 'customerTags',
                        label: (
                            <div>
                                <span>
                                    {/* 客户标签 */}
                                    {
                                        UdeskLocales['current'].components.coach.customerPortrait
                                            .detail.index.customerLabel
                                    }
                                </span>{' '}
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => {
                                        window.open('/coach/customer-tags-management/index');
                                    }}
                                    style={{ marginLeft: 320 }}
                                >
                                    {/* 标签管理 */}
                                    {
                                        UdeskLocales['current'].components.coach.customerPortrait
                                            .detail.index.labelManagement
                                    }
                                </Button>
                            </div>
                        ),
                    },
                ]}
            />
        );
    })
);

const useCustomerPortraitConfigDetail = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [detail, setDetail] = useState<any>({});
    const {
        requestCustomerPortraitDetail,
        createCustomerPortrait,
        updateCustomerPortrait,
        deleteCustomerPortrait,
    } = useCustomerPortraitConfigContext();
    const requestCustomerPortraitConfigDetail = (currentId) => {
        if (!currentId) {
            setDetail({});
            return;
        }
        setLoading(true);
        requestCustomerPortraitDetail(currentId)
            .then((resp) => {
                setDetail(resp.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        requestCustomerPortraitConfigDetail,
        createCustomerPortrait,
        updateCustomerPortrait: updateCustomerPortrait.bind(null, detail.id),
        deleteCustomerPortrait: deleteCustomerPortrait.bind(null, detail.id),
        detail,
        loading,
    };
};

type CustomerPortraitConfigType = typeof InternalCustomerPortraitDetail & {
    useCustomerPortraitConfigDetail: typeof useCustomerPortraitConfigDetail;
};

const CustomerPortraitConfigDetail: CustomerPortraitConfigType =
    InternalCustomerPortraitDetail as CustomerPortraitConfigType;

CustomerPortraitConfigDetail.useCustomerPortraitConfigDetail = useCustomerPortraitConfigDetail;

export { CustomerPortraitConfigDetail };
