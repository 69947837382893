import React, { useEffect, useState, useMemo } from 'react';
import Udesk from 'Udesk';

import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';

import Locales from 'UdeskLocales';
import { getSystemModule } from 'Udesk/system/subApp';


function ClientManage(props) {
    const locales = Locales['current'];

    const [loading, setLoading] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState<any>({});
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [filterConditions, setFilterConditions] =  useState({
        judgeStrategy: 1,
        conditionList: [],
        customJudgeLogic: ''
    });

    const tableProps = useMemo(() => {
        return {
            columns,
            dataSource,
            cacheKey: 'client-manage-list',
            subtractedHeight: 190,
            onRow: (record) => {
                return {
                    onClick: (event) => {
                        const routeOptions = {
                            history: props.history,
                            routeName: 'customerPortraitIndex',
                            state: {
                                clientId: record.pf_1,
                            },
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    }, // 点击行
                };
            },
        };
    }, [columns, dataSource]);
    const filterProps = useMemo(() => {
        const onApply = ({ conditionList, customJudgeLogic, judgeStrategy, id, name }) => {
            setFilterConditions({
                judgeStrategy,
                conditionList,
                customJudgeLogic,
            });
        };
        return {
            ...filterConditions,
            onApply: onApply,
            type: 9,
            querryType: 'personaField',
        };
    }, [filterConditions]);
    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPageNum(page);
                setPageSize(pageSize);
            }
        };
    }, [pageNum, pageSize, total]);
    const exportBtnProps = useMemo(() => {
        const onExport = (showFieldList) => {
            let { sdkOptions, searchFieldName } = props;
            let exportTaskFieldCreateRequestList =
                data.showFieldList
                    ?.filter((item: any) => showFieldList.includes(item.id))
                    .map((field) => {
                        return {
                            field: field.id,
                            label: field.label,
                        };
                    }) ?? [];
            let url = Udesk.business.apiPath.concatApiPath(`exps/persona`, sdkOptions);
            let params = {
                showFieldList,
                exportTaskFieldCreateRequestList,
                systemModule: getSystemModule(),
                ...filterConditions,
            };
            params[searchFieldName] = '';
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.exportSuccessFormat,
                            locales.components.pages.components.listManage.records
                        )
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.exportFailedFormat,
                                locales.components.pages.components.listManage.records
                            )
                    );
                }
            );
        };
        return {
            onExport: onExport,
        };
    }, [loading, total, filterConditions, data]);
    useEffect(() => {
        let url = Udesk.business.apiPath.concatApiPath(`personaDatas/list-v2`, props.sdkOptions);
        let params = {
            pageNum,
            pageSize,
            ...filterConditions,
            // keyword: keywords
        };
        setLoading(true);
        Udesk.ajax.post(url, params).then(
            (resp) => {
                setLoading(false);
                let fieldValueMaps: any[] = [];
                let data = resp.data;
                setData(data ?? {});
                setPageNum(data.pageNum);
                setPageSize(data.pageSize);
                setTotal(data.total);
                if (data && data.fieldDataList && data.fieldDataList.length > 0) {
                    data.fieldDataList.forEach((item) => {
                        let fieldValueMap = item.fieldValueMap;
                        fieldValueMap.id = item.dataId;
                        fieldValueMaps.push(item.fieldValueMap);
                    });
                }
                let columns: any[] = [];
                function getAllFieldList(props) {
                    let { sdkOptions } = props;

                    let allFieldList = [];

                    if (data && data.showFieldList && data.showFieldList.length > 0) {
                        allFieldList = data.showFieldList;
                    } else if (sdkOptions.props.fields && sdkOptions.props.fields.length > 0) {
                        allFieldList = sdkOptions.props.fields;
                    }

                    return allFieldList;
                }
                let allFieldList = getAllFieldList(props);
                allFieldList.forEach((item: any) => {
                    let column = {
                        dataIndex: item.id,
                        title: item.label,
                        key: item.id,
                    };
                    columns.push(column);
                });
                setColumns(columns);
                setDataSource(fieldValueMaps);
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, [filterConditions, pageNum, pageSize]);
    return (
        <Page
            pageBodyClassName='client-manage-page-index'
        >
            <CommonTablePage
                loading={loading}
                showExport={true}
                showColumsControl={true}
                // table={tableProps}
                // pagination={paginationProps}
                showFilter={true}
                filter={filterProps}
                exportBtn={exportBtnProps}
            >
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
        </Page>
    );
}

export default React.memo(ClientManage);
