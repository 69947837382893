import React from 'react';
import AudioStickersTalkComponent from './component';
import './style.scss';

export default class AudioStickersTalkTemplate extends AudioStickersTalkComponent {
    render() {
        let {
            audioStickerColors
        } = this.privates;
        let {
            _audioStickers
        } = this.privates.computes;

        return (
            <div className="audio-stickers-talk">
                <For each="audioSticker" index="index" of={_audioStickers}>
                    <div key={index} className="audio-sticker-view">
                        <For each="item" index="itemIndex" of={audioSticker.times}>
                            <div key={itemIndex} className="audio-sticker-item" style={getAudioStickerItemStyle(item, index, audioSticker.timeLength, audioStickerColors)}>
                            </div>
                        </For>
                    </div>
                </For>
            </div>
        );
    }
}

function getAudioStickerItemStyle(item, index, time, audioStickerColors) {
    let backgroundColor = audioStickerColors[index % 2];
    return getAudioStickerItemStyleModel(item.startPoint, item.endPoint, time, backgroundColor);
}

function getAudioStickerItemStyleModel(startPoint, endPoint, time, backgroundColor) {
    var start = parseFloat(startPoint);
    var end = parseFloat(endPoint);
    var timeLength = parseFloat(time);
    if (isNaN(start) || isNaN(end) || isNaN(timeLength)) {
        // return String.htmlSafe("width:0%;left:0%");
        return {
            width: "0%",
            left: "0%"
        };
    } else {
        let letfPoint = timeLength <= 0 ? "0%" : (Math.round(start / timeLength * 10000) / 100.00 + "%");
        let width = timeLength <= 0 ? "0%" : (Math.round((end - start) / timeLength * 10000) / 100.00 + "%");
        // return String.htmlSafe(`width:${width};left:${letfPoint};background:${backgroundColor}`);
        return {
            width: width,
            left: letfPoint,
            background: backgroundColor
        };
    }
}