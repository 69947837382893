import Udesk from 'Udesk';
import DataMigrationComponent from './component';

class DataMigrationRoute extends DataMigrationComponent {
    asyncModel() {
        let { sdkOptions } = this.props;

        let url = Udesk.business.apiPath.concatApiPath('inspectionTasks/source/0', sdkOptions);
        let dataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return dataPromise;
    }

    parseAsyncModel(model) {
        return model;
    }
}

export default DataMigrationRoute;
