import React, { useCallback, useMemo, useState } from 'react';
import { Button, Drawer } from 'udesk-ui';
import CustomerFields from 'Component/pages/gong/customer-fields';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import './index.scss';

export default React.memo((props: any) => {
    const {
        locales,
        customerId,
        customerName,
        stageList,
        followUpDays,
        handleStageChange,
        customerInfo,
        handleRemarkConfirm,
        customFieldList,
    } = props;

    const [visible, setVisible] = useState(false);
    const closeHandle = useCallback(() => {
        setVisible(false);
    }, []);
    const openHandle = useCallback(() => {
        setVisible(true);
    }, []);

    const isWin = useMemo(() => {
        if (stageList?.length > 0) {
            return (
                !!stageList[stageList.length - 1]['startTime'] &&
                !!stageList[stageList.length - 1]['endTime']
            );
        }
        return false;
    }, [stageList]);

    const active = useMemo(() => {
        if (isWin) {
            return stageList[stageList.length - 1];
        }
        return stageList.find((item) => !item.endTime);
    }, [isWin, stageList]);

    const stageStatus = useMemo(() => {
        if (active) {
            return active.stageId;
        }
    }, [active]);

    const stageOptions = useMemo(() => {
        return stageList.map((item) => {
            return {
                value: item.stageId,
                label: item.stageName,
            };
        });
    }, [stageList]);

    const onSave = useCallback(
        (values) => {
            const list = [] as any[];

            if (values.stageStatus !== stageStatus) {
                const index = stageOptions.findIndex((item) => item.value === stageStatus);
                if (index > -1) {
                    list.push(handleStageChange(index));
                }
            }
            if (values.remark !== customerInfo?.remark) {
                list.push(handleRemarkConfirm(customerInfo?.remark));
            }

            return Promise.all(list).then(
                () => {
                    Udesk.ui.notify.success(locales.fix.saveSuccess);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        [
            customerInfo?.remark,
            handleRemarkConfirm,
            handleStageChange,
            locales.fix.saveSuccess,
            stageOptions,
            stageStatus,
        ]
    );

    return (
        <>
            <Button type="link" onClick={openHandle}>
                {/* 查看更多客户信息 */}
                {
                    UdeskLocales['current'].pages.gong.saleClientCenter.components.moreCustomer
                        .index.viewMoreCustomerInformation
                }
            </Button>

            <Drawer
                className="business-more-customer"
                placement="right"
                getContainer={false}
                onClose={closeHandle}
                title={
                    /* 客户信息 */ UdeskLocales['current'].pages.gong.saleClientCenter.components
                        .moreCustomer.index.customerInformation
                }
                mask={true}
                width={700}
                zIndex={1001}
                visible={visible}
            >
                <CustomerFields
                    baseFieldsProps={{
                        customerId,
                        customerName,
                        stageStatus,
                        phoneNumber: customerInfo?.phoneNumber,
                        followUpDays,
                        remark: customerInfo?.remark,
                        onSave,
                    }}
                    otherFieldsProps={{
                        fieldList: customFieldList,
                    }}
                />
            </Drawer>
        </>
    );
});
