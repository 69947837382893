import { Input, Select, InputNumber, Radio, TimePicker, Button, Switch, Checkbox, DatePicker, TreeSelect } from 'udesk-ui';
import React/* , { useEffect, useRef, useState } */ from 'react';
import moment from 'moment';

const { TextArea, Password } = Input;

const TimePickerMoment = ({ value: propsValue, onChange, format, ...args }) => {
    const TimePickerOnChange = (time, timeString: string) => {
        onChange(timeString);
    };
    return (
        <TimePicker
            {...args}
            format={format}
            value={propsValue ? moment(propsValue, format) : null}
            onChange={TimePickerOnChange}
        />
    );
};
class ProxyPassword extends React.Component<any, any> {
    static getDerivedStateFromProps(nextProps, prevState) {
        if ('value' in nextProps && nextProps.value !== prevState.value) {
            
            return {
                value: nextProps.value?.replace(/\S/g, '●')??'',
                realValue: nextProps.value??'',
            };
        }
        return null;
    }
    state: any;
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            realValue: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        const props = this.props;
        if (!('value' in props)) {
            this.setState({
                value: e.target.value
            });
        }
        if (props.onChange) {
            let { realValue = '' } = this.state;
            let val = e.target.value;
            let reg = /[0-9a-zA-Z]/g; // 只允许输入字母和数字
            let nDot = /[^●]/g; // 非圆点字符
            let index = -1; // 新输入的字符位置
            let lastChar = ''; // 新输入的字符
            let realArr = realValue.split(''); // 真实密码数组
            let coverArr = val.split(''); // 文本框显示密码数组
            let coverLen = val.length; // 文本框字符串长度
            let realLen = realValue.length; // 真实密码长度
            // 找到新输入的字符及位置
            coverArr.some((el, idx) => {
                index = idx;
                lastChar = val.replace(/[●]/g, '');
                return nDot.test(el);
            });
            

            // 判断输入的字符是否符合规范，不符合的话去掉该字符
            if (lastChar && !reg.test(lastChar)) {
                coverArr.splice(index, 1);
                this.setState({
                    pwdCover: coverArr.join('')
                });
                return;
            }
            if (realLen < coverLen) {
                // 新增字符
                realArr.splice(index, 0, lastChar);
            } else if (coverLen <= realLen && index !== -1) {
                // 替换字符（选取一个或多个字符直接替换）
                realArr.splice(index, realLen - (coverLen - 1), lastChar);
            } else {
                // 删除字符，因为 val 全是 ● ，没有办法匹配，不知道是从末尾还是中间删除的字符，删除了几个，不好对 password 处理，所以可以通过光标的位置和 val 的长度来判断
                let pos = e.target.selectionEnd; // 获取光标位置
                realArr.splice(pos, realLen - coverLen);
            }
            // 将 pwdCover 替换成 ●
            let pword = realArr.join('');
            props.onChange(pword);
        }
    }
    render() {
        const { value } = this.state;
        return (
            <input value={value} onChange={this.handleChange} />
        );
    }
}

const SubmitButton = (props) => {
    return <Button type='primary' children={'提交'} {...props} htmlType='submit' />;
};
const FormSwitch: React.FC<any> = ({ value, ...args }) => {
    return <Switch {...args} checked={value} />;
};
const FormCheckbox: React.FC<any> = ({ value, ...args }) => {
    return <Checkbox {...args} checked={value} />;
};
const defaultConfigMap = {
    Input: Input,
    TextArea: TextArea,
    Select: Select,
    InputNumber: InputNumber,
    Radio: Radio,
    RadioGroup: Radio.Group,
    Checkbox: FormCheckbox,
    CheckboxGroup: Checkbox.Group,
    TimePicker: TimePickerMoment,
    SubmitButton: SubmitButton,
    Button: Button,
    Password: Password,
    ProxyPassword: ProxyPassword,
    Switch: FormSwitch,
    DatePicker: DatePicker,
    RangePicker: DatePicker.RangePicker,
    TreeSelect: TreeSelect,
};

export default defaultConfigMap;
