// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostReviewIntelligentPartnerNodePromptConfigPromptBody,
    BaseResponsestring,
} from '../../../../../types';

/**
 * 根据flowId生成提示语
 *
 * @export
 * @tags 智能陪练-AI对话提示语节点配置
 * @link [POST] /review/intelligentPartner/node/promptConfig/prompt
 */
export function postReviewIntelligentPartnerNodePromptConfigPrompt(
    data: PostReviewIntelligentPartnerNodePromptConfigPromptBody
): Promise<BaseResponsestring>;
export function postReviewIntelligentPartnerNodePromptConfigPrompt(
    data: PostReviewIntelligentPartnerNodePromptConfigPromptBody,
    options: ApiOptions<never, never>
): Promise<BaseResponsestring>;
export function postReviewIntelligentPartnerNodePromptConfigPrompt(
    data: PostReviewIntelligentPartnerNodePromptConfigPromptBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponsestring> {
    return request<PostReviewIntelligentPartnerNodePromptConfigPromptBody, never, never>(
        '/review/intelligentPartner/node/promptConfig/prompt',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodePromptConfigPrompt',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-AI对话提示语节点配置'],
        path: '/review/intelligentPartner/node/promptConfig/prompt',
        method: 'post',
    },
];
