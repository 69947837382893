import Udesk from 'Udesk';
import SemanticTagsComponent from './component';

class SemanticTagsRoute extends SemanticTagsComponent {
    asyncModel(changedParams) {
        let that = this;
        let {
            sdkOptions,
            // match
        } = this.props;
        let {
            pageSize,
            pageNum
        } = this.state;
        let {
            keywords,
            moduleType,
        } = this.privates;

        let params = {
            pageNum,
            pageSize,
            keywords,
            type: moduleType,
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }

        let url = Udesk.business.apiPath.concatApiPath(`semantic-tag/list`, sdkOptions);
        this.privates.loading = true;
        this.actions.update();
        return new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                resp => {
                    this.privates.dataSource = resp?.data?.map(i => ({...i, key: i.id}))??[];
                    this.privates.loading = false;
                    this.actions.update();            
                    if (resp.paging) {
                        that.setState({
                            pageSize: resp.paging.pageSize,
                            pageNum: resp.paging.pageNum,
                            total: resp.paging.total
                        });
                    }
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );

            //#region Default Props
            // let resp = {
            //     "code": 200,
            //     "message": "OK",
            //     "visible": false,
            //     "paging": {
            //         "pageNum": 1,
            //         "pageSize": 20,
            //         "total": 8
            //     },
            //     "data": [
            //         {
            //             "id": 1,
            //             "createUserId": null,
            //             "createUserUsername": null,
            //             "createTime": null,
            //             "updateUserId": null,
            //             "updateUserUsername": null,
            //             "updateTime": null,
            //             "remark": null,
            //             "deleteFlag": 1,
            //             "languageCode": null,
            //             "company": {
            //                 "id": 4,
            //                 "deleteFlag": 0
            //             },
            //             "title": "标签组1",
            //             "tagType": 1,
            //             "usingCount": 10,
            //             "tagCount": 0,
            //             "activeFlag": 1,
            //             "tags": [
            //                 {
            //                     "id": 1,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 14:01:12",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-04 14:40:31",
            //                     "remark": null,
            //                     "deleteFlag": 1,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 1,
            //                     "content": "标签1",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 2,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 14:01:12",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-04 14:40:31",
            //                     "remark": null,
            //                     "deleteFlag": 1,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 1,
            //                     "content": "标签2",
            //                     "order": null,
            //                     "standardFlag": 0
            //                 }
            //             ]
            //         },
            //         {
            //             "id": 2,
            //             "createUserId": null,
            //             "createUserUsername": null,
            //             "createTime": null,
            //             "updateUserId": null,
            //             "updateUserUsername": null,
            //             "updateTime": null,
            //             "remark": null,
            //             "deleteFlag": 0,
            //             "languageCode": null,
            //             "company": {
            //                 "id": 4,
            //                 "deleteFlag": 0
            //             },
            //             "title": "标签组2",
            //             "tagType": 1,
            //             "usingCount": 0,
            //             "tagCount": 0,
            //             "activeFlag": 1,
            //             "tags": [
            //                 {
            //                     "id": 3,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 14:40:53",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-04 14:40:53",
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 2,
            //                     "content": "标签21",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 4,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 14:40:53",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-04 14:40:53",
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 2,
            //                     "content": "标签22",
            //                     "order": null,
            //                     "standardFlag": 0
            //                 },
            //                 {
            //                     "id": 5,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 16:42:16",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": null,
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 2,
            //                     "content": "包含富强民主和谐文明",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 6,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 16:51:05",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": null,
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 2,
            //                     "content": "富强民主,和谐",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 7,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 17:08:58",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": null,
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 2,
            //                     "content": "this is an english dialog.",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 8,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 17:09:31",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": null,
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 2,
            //                     "content": "mybe my mysql cannot support 中文",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 9,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-04 18:10:50",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": null,
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 2,
            //                     "content": "富强, 和谐",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 }
            //             ]
            //         },
            //         {
            //             "id": 3,
            //             "createUserId": null,
            //             "createUserUsername": null,
            //             "createTime": null,
            //             "updateUserId": null,
            //             "updateUserUsername": null,
            //             "updateTime": null,
            //             "remark": null,
            //             "deleteFlag": 0,
            //             "languageCode": null,
            //             "company": {
            //                 "id": 4,
            //                 "deleteFlag": 0
            //             },
            //             "title": "test标签11",
            //             "tagType": 1,
            //             "usingCount": 0,
            //             "tagCount": 0,
            //             "activeFlag": 1,
            //             "tags": []
            //         },
            //         {
            //             "id": 4,
            //             "createUserId": null,
            //             "createUserUsername": null,
            //             "createTime": null,
            //             "updateUserId": null,
            //             "updateUserUsername": null,
            //             "updateTime": null,
            //             "remark": null,
            //             "deleteFlag": 0,
            //             "languageCode": null,
            //             "company": {
            //                 "id": 4,
            //                 "deleteFlag": 0
            //             },
            //             "title": "标签组1",
            //             "tagType": 1,
            //             "usingCount": 0,
            //             "tagCount": 0,
            //             "activeFlag": 1,
            //             "tags": [
            //                 {
            //                     "id": 10,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-11 09:58:14",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-11 09:58:14",
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 4,
            //                     "content": "标签1",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 11,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-11 09:58:14",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-11 09:58:14",
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 4,
            //                     "content": "标签2",
            //                     "order": null,
            //                     "standardFlag": 0
            //                 }
            //             ]
            //         },
            //         {
            //             "id": 5,
            //             "createUserId": null,
            //             "createUserUsername": null,
            //             "createTime": null,
            //             "updateUserId": null,
            //             "updateUserUsername": null,
            //             "updateTime": null,
            //             "remark": null,
            //             "deleteFlag": 1,
            //             "languageCode": null,
            //             "company": {
            //                 "id": 4,
            //                 "deleteFlag": 0
            //             },
            //             "title": "标签组3",
            //             "tagType": null,
            //             "usingCount": 0,
            //             "tagCount": 0,
            //             "activeFlag": 1,
            //             "tags": []
            //         },
            //         {
            //             "id": 6,
            //             "createUserId": null,
            //             "createUserUsername": null,
            //             "createTime": null,
            //             "updateUserId": null,
            //             "updateUserUsername": null,
            //             "updateTime": null,
            //             "remark": null,
            //             "deleteFlag": 0,
            //             "languageCode": null,
            //             "company": {
            //                 "id": 4,
            //                 "deleteFlag": 0
            //             },
            //             "title": "标签组4",
            //             "tagType": null,
            //             "usingCount": 0,
            //             "tagCount": 0,
            //             "activeFlag": 1,
            //             "tags": []
            //         },
            //         {
            //             "id": 7,
            //             "createUserId": null,
            //             "createUserUsername": null,
            //             "createTime": null,
            //             "updateUserId": null,
            //             "updateUserUsername": null,
            //             "updateTime": null,
            //             "remark": null,
            //             "deleteFlag": 0,
            //             "languageCode": null,
            //             "company": {
            //                 "id": 4,
            //                 "deleteFlag": 0
            //             },
            //             "title": "标签组5",
            //             "tagType": 1,
            //             "usingCount": 0,
            //             "tagCount": 0,
            //             "activeFlag": 1,
            //             "tags": [
            //                 {
            //                     "id": 12,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-11 10:59:08",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-11 10:59:08",
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 7,
            //                     "content": "标签51",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 13,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-11 10:59:08",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-11 10:59:08",
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 7,
            //                     "content": "标签52",
            //                     "order": null,
            //                     "standardFlag": 0
            //                 }
            //             ]
            //         },
            //         {
            //             "id": 8,
            //             "createUserId": null,
            //             "createUserUsername": null,
            //             "createTime": null,
            //             "updateUserId": null,
            //             "updateUserUsername": null,
            //             "updateTime": null,
            //             "remark": null,
            //             "deleteFlag": 0,
            //             "languageCode": null,
            //             "company": {
            //                 "id": 4,
            //                 "deleteFlag": 0
            //             },
            //             "title": "标签组6",
            //             "tagType": 1,
            //             "usingCount": 0,
            //             "tagCount": 0,
            //             "activeFlag": 1,
            //             "tags": [
            //                 {
            //                     "id": 14,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-11 11:55:15",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-11 11:55:15",
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 8,
            //                     "content": "标签61",
            //                     "order": null,
            //                     "standardFlag": 1
            //                 },
            //                 {
            //                     "id": 15,
            //                     "createUserId": null,
            //                     "createUserUsername": null,
            //                     "createTime": "2019-06-11 11:55:15",
            //                     "updateUserId": null,
            //                     "updateUserUsername": null,
            //                     "updateTime": "2019-06-11 11:55:15",
            //                     "remark": null,
            //                     "deleteFlag": 0,
            //                     "languageCode": null,
            //                     "company": null,
            //                     "groupId": 8,
            //                     "content": "标签62",
            //                     "order": null,
            //                     "standardFlag": 0
            //                 }
            //             ]
            //         }
            //     ]
            // };
            // if (resp.paging) {
            //     that.setState({
            //         pageSize: resp.paging.pageSize,
            //         pageNum: resp.paging.pageNum,
            //         total: resp.paging.total
            //     });
            // }
            // resolve(resp);
            //#endregion
        });
    }
}

export default SemanticTagsRoute;
