import EventedClass from '../../../evented-class';

class MessageChannelClientClass {
    constructor() {
        this._eventManager = new EventedClass();
        this._sourceId = null;
        this._debug = false;
    }

    init() {
        window.addEventListener("message", (e) => {
            let data = e.data;
            if (data != null) {
                this._onActionMessage(data);
            }
        });
    }

    _sendMessage(actionName, data) {
        let sourceId = this._sourceId;
        let messageData = {
            sourceId: sourceId,
            actionName: actionName,
            actionData: data,
        };
        window.parent.postMessage(messageData, "*");
    }

    /**
     * 注册SDK的事件（由父页面的动作引发）
     *
     * @param {String} eventName 事件名称
     * @param {Function} handler 事件的处理函数。函数签名： function(eventData) {
     *       // {(Object | Array)} eventData 该事件所需的参数
     * }
     */
    on(eventName, handler) {
        this._eventManager.on(eventName, handler);
    }

    /**
     * 移除之前注册的事件处理函数。
     *
     * @param {String} eventName 事件名称
     * @param {Function} handler 要移除的事件处理函数。如果为空，则移除该事件所有的事件处理函数。
     */
    off(eventName, handler) {
        this._eventManager.off(eventName, handler);
    }

    destroy() {

    }

    _isAcceptableMessage(messageBody) {
        return true;
    }

    _processMessage(messageBody) {
        throw new Error("`_processMessage` not implemented!");
    }

    _onActionMessage(messageBody) {
        let isValidMessage = (messageBody != null &&
            typeof messageBody.actionName === "string" &&
            messageBody.actionName.length > 0
        );
        if (!isValidMessage) {
            return;
        }

        let sourceId = messageBody.sourceId;
        if (!this._sourceId && sourceId) {
            this._sourceId = sourceId;
            this._debug = !!messageBody.debug;
            this._eventManager.trigger("Ready");
        }

        if (this._isAcceptableMessage(messageBody)) {
            let actionName = messageBody.actionName;
            if (this._debug) {
                window.console.log(`[client][${this._sourceId}]: ${actionName}`, messageBody);
            }

            this._processMessage(messageBody);
        }
    }
}

export default MessageChannelClientClass;