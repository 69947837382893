// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseMapstringint1 } from '../../types';

/**
 * countUnreadMessages
 *
 * @export
 * @tags message-user-notification-controller
 * @link [GET] /messageUserNotifications/unreadCount
 */
export function getMessageUserNotificationsUnreadCount(): Promise<BaseResponseMapstringint1>;
export function getMessageUserNotificationsUnreadCount(
    options: ApiOptions<never, never>
): Promise<BaseResponseMapstringint1>;
export function getMessageUserNotificationsUnreadCount(
    options?: ApiOptions<never, never>
): Promise<BaseResponseMapstringint1> {
    return request<never, never, never>('/messageUserNotifications/unreadCount', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getMessageUserNotificationsUnreadCount',
    });
}

export const meta = [
    {
        tags: ['message-user-notification-controller'],
        path: '/messageUserNotifications/unreadCount',
        method: 'get',
    },
];
