import React from 'react';
import Udesk from 'Udesk';
// import Udesk from 'Udesk';
import { DatePicker, Icon } from 'udesk-ui';
import SelectCallTimeComponent from './component';
import './style.scss';

const { RangePicker } = DatePicker;

export default class SelectCallTimeTemplate extends SelectCallTimeComponent {
    render() {
        let { recordCount, fieldFlag, showTime, children } = this.props;
        let { callTimeText, callTimeRemindText, callRecordCountText, selectDate } = this.privates;
        let { actions } = this;
        return (
            <div className="select-call-time-container">
                <div className="select-call-time-container-row">
                    <span>{callTimeText}</span>
                    <RangePicker
                        allowClear={false}
                        getPopupContainer={(trigger) => trigger}
                        value={selectDate}
                        style={{ width: showTime ? '320px' : '240px', marginLeft: '10px' }}
                        showTime={showTime ? { format: 'HH:mm' } : undefined}
                        format={showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY/MM/DD'}
                        onChange={actions.dateRangeChanged}
                        disabledDate={disabledDate}
                        getCalendarContainer={getCalendarContainer}
                    />
                </div>
                <div className="select-call-time-container-row">
                    <span>
                        <Icon type="ic-warn-jinggao" className="remind-icon" />
                    </span>
                    <span>{callTimeRemindText}</span>
                </div>
                {children}
                {!fieldFlag && (
                    <div className="select-call-time-container-row">
                        {Udesk.utils.string.format(callRecordCountText, recordCount)}
                    </div>
                )}
            </div>
        );
    }
}

function disabledDate(current) {
    return current && current < moment().subtract(1, 'years');
}

function getCalendarContainer(trigger) {
    return trigger;
}
