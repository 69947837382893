// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetChatDataCallTaskByCallIdQuery,
    GetChatDataCallTaskByCallIdParam,
    BaseResponseChatAsyncServiceFoundResponse,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags chat-async-service-controller
 * @link [GET] /chatDataCallTask/{callId}
 * @param callId
 */
export function getChatDataCallTaskByCallId(
    options: ApiOptions<GetChatDataCallTaskByCallIdParam, GetChatDataCallTaskByCallIdQuery> & {
        segments: GetChatDataCallTaskByCallIdParam;
    } & { params: GetChatDataCallTaskByCallIdQuery }
): Promise<BaseResponseChatAsyncServiceFoundResponse> {
    return request<never, GetChatDataCallTaskByCallIdParam, GetChatDataCallTaskByCallIdQuery>(
        '/chatDataCallTask/{callId}',
        { ...options, method: 'get', __$requestCalleeName: 'getChatDataCallTaskByCallId' }
    );
}

export const meta = [
    { tags: ['chat-async-service-controller'], path: '/chatDataCallTask/{callId}', method: 'get' },
];
