// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetSeekHelpsQuery,
    BaseResponseListSeekHelpFoundResponse,
    PostSeekHelpsBody,
    BaseResponseSeekHelpFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags seek-help-controller
 * @link [GET] /seekHelps
 */
export function getSeekHelps(): Promise<BaseResponseListSeekHelpFoundResponse>;
export function getSeekHelps(
    options: ApiOptions<never, GetSeekHelpsQuery>
): Promise<BaseResponseListSeekHelpFoundResponse>;
export function getSeekHelps(
    options?: ApiOptions<never, GetSeekHelpsQuery>
): Promise<BaseResponseListSeekHelpFoundResponse> {
    return request<never, never, GetSeekHelpsQuery>('/seekHelps', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSeekHelps',
    });
}

/**
 * save
 *
 * @export
 * @tags seek-help-controller
 * @link [POST] /seekHelps
 */
export function postSeekHelps(data: PostSeekHelpsBody): Promise<BaseResponseSeekHelpFoundResponse>;
export function postSeekHelps(
    data: PostSeekHelpsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseSeekHelpFoundResponse>;
export function postSeekHelps(
    data: PostSeekHelpsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseSeekHelpFoundResponse> {
    return request<PostSeekHelpsBody, never, never>('/seekHelps', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSeekHelps',
    });
}

export const meta = [
    { tags: ['seek-help-controller'], path: '/seekHelps', method: 'get' },
    { tags: ['seek-help-controller'], path: '/seekHelps', method: 'post' },
];
