// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostCallServiceSummaryFieldsTrainingBody, BaseResponsestring } from '../../types';

/**
 * training
 *
 * @export
 * @tags call-service-summary-field-controller
 * @link [POST] /callServiceSummaryFields/training
 */
export function postCallServiceSummaryFieldsTraining(
    data: PostCallServiceSummaryFieldsTrainingBody
): Promise<BaseResponsestring>;
export function postCallServiceSummaryFieldsTraining(
    data: PostCallServiceSummaryFieldsTrainingBody,
    options: ApiOptions<never, never>
): Promise<BaseResponsestring>;
export function postCallServiceSummaryFieldsTraining(
    data: PostCallServiceSummaryFieldsTrainingBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponsestring> {
    return request<PostCallServiceSummaryFieldsTrainingBody, never, never>(
        '/callServiceSummaryFields/training',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postCallServiceSummaryFieldsTraining',
        }
    );
}

export const meta = [
    {
        tags: ['call-service-summary-field-controller'],
        path: '/callServiceSummaryFields/training',
        method: 'post',
    },
];
