import Udesk from 'Udesk';
import React from 'react';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import { getRouteNameWithCoach } from 'src/routes/coach/const';
import { CommonPage } from 'udesk_gm_ui';
import UdeskLocales from 'UdeskLocales';

class Component extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { route, location, sdkOptions } = this.props;

        // let { locales } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        navLists.push({
            text: /* 评分模型 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.index.scoringModel,
            pathName: getRouteNameWithCoach('scoreModel'),
            isActive: (match, location) => {
                return location.pathname.includes('/model');
            },
        });
        navLists.push({
            text: /* 评分维度 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.index.ratingDimension,
            pathName: getRouteNameWithCoach('scoreDimension'),
            isActive: (match, location) => {
                return location.pathname.includes('/dimension');
            },
        });
        navLists.push({
            text: /* 会话检测设置 */ UdeskLocales['current'].pages.coach.businessConfiguration
                .scoreManagement.index.sessionDetectionSettings,
            pathName: getRouteNameWithCoach('scoreDimensionIndex'),
            isActive: (match, location) => {
                return location.pathname.includes('/index');
            },
        });

        return (
            <CommonPage.PageInfoMemo>
                {(() => {
                    if (
                        sdkOptions.props.landingPath != null &&
                        this.isRedirect(route.path, location.pathname)
                    ) {
                        let toPath = location.pathname + sdkOptions.props.landingPath;
                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else if (
                        navLists.length > 0 &&
                        this.isRedirect(route.path, location.pathname)
                    ) {
                        let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                            routeName: navLists[0].pathName,
                        });
                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else {
                        return (
                            <div className="semantic-intelligence">
                                <LayoutMenu
                                    {...this.props}
                                    navLists={navLists}
                                    menuTitle={
                                        /* 评分管理 */ UdeskLocales['current'].pages.home.index
                                            .scoringManagement
                                    }
                                />
                            </div>
                        );
                    }
                })()}
            </CommonPage.PageInfoMemo>
        );
    }
}

export default Udesk.react.udeskify(Component);
