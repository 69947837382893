import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import CustomerInfo from '../../../components/customer-info';
import ChatRecordPanel from '../components/record/text';
import TaskCue from '../components/cue';
import TaskMessageModal, { RefType } from '../components/task-message-modal';
import TaskSubmitButton from '../components/task-submit-button';
import TaskFlow from '../components/flow';
import { Button, Col, Row, Space } from 'udesk-ui';
import { useRequest } from 'src/util/hook';
import { useChatRecord } from '../components/record/text/hook';
import { useCancelTask } from '../components/util';
import './index.less';
import UdeskLocales from 'UdeskLocales';
import { useAiGen } from 'src/pages/coach/learning-center/node-config-test/components/hooks/useAiGen';

const Template = React.memo((props: any) => {
    const {
        isShowCue,
        setIsShowCue,
        isStartTask,
        setIsStartTask,
        isLastQuestion,
        taskDetail,
        wrongQuestion,
        wrongQuestionSet,
        nextWrongQuestion,
        refreshWrongQuestion,
        setWrongQuestionIndex,
        history,
    } = props;
    const request = useRequest();
    const {
        referenceWords,
        loadingForSendMessage, // 发送消息接口的loading
        loadingForTaskStart, // 任务开始接口的loading
        isLastNode, // 任务是否是最后一个节点
        list, // 消息列表
        startTaskHandle, // 开始任务
        submitMessageHandle, // 提交一通消息
    } = useChatRecord({
        wrongQuestion,
        request,
    });

    useEffect(() => {
        if (wrongQuestion) {
            startTaskHandle();
        }
    }, [startTaskHandle, wrongQuestion]);

    const taskMessageModalRef = useRef<RefType>(null);

    const cancelTaskHandle = useCancelTask(history, request, wrongQuestionSet);

    const title = useMemo(() => {
        let list = new Array<string>();
        if (taskDetail) {
            list.push(taskDetail.taskName);
        }
        list.push(
            /* 错题练习 */ UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet
                .exercise.text.index.mispracticeExercises
        );

        return list.join('   ');
    }, [taskDetail]);

    const overTaskHandle = useCallback(() => {
        taskMessageModalRef.current?.open(1);
    }, []);

    console.log(taskDetail);
    const { isAiGenCourse, aiImage } = useAiGen({ courseId: taskDetail?.courseId });

    return (
        <Page
            pageClassName="wrong-question-exercise"
            pageBodyClassName="wrong-question-exercise-body call"
            backGroundGhost={true}
            onBack={cancelTaskHandle}
            title={title}
            extra={
                isStartTask && (
                    <Button onClick={overTaskHandle} type="primary">
                        {/* 结束练习 */}
                        {
                            UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet
                                .exercise.text.index.endExercise
                        }
                    </Button>
                )
            }
        >
            <Row gutter={16}>
                {isAiGenCourse ? (
                    <Col span={5}>
                        <CustomerInfo data={aiImage} />
                    </Col>
                ) : taskDetail?.customerPersona ? (
                    <Col span={5}>
                        <CustomerInfo data={taskDetail?.customerPersona} />
                    </Col>
                ) : null}
                <Col span={taskDetail?.customerPersona || isAiGenCourse ? 13 : 18}>
                    <ChatRecordPanel
                        {...{
                            isStartTask,
                            setIsStartTask,
                            wrongQuestion,
                            isLastNode,
                            list,
                            submitMessageHandle,
                            loadingForTaskStart,
                            loadingForSendMessage,
                            setWrongQuestionIndex,
                        }}
                    />
                </Col>
                <Col span={6}>
                    <Space direction={'vertical'} className={'wrong-question-exercise-other-panel'}>
                        <TaskFlow
                            {...{
                                list,
                                isLastNode,
                                isStartTask,
                                wrongQuestion,
                                wrongQuestionSet,
                                setWrongQuestionIndex,
                            }}
                        />
                        <TaskCue
                            referenceWords={referenceWords}
                            isShowCue={isShowCue}
                            setIsShowCue={setIsShowCue}
                        />
                        <TaskSubmitButton
                            isLastQuestion={isLastQuestion}
                            refreshWrongQuestion={refreshWrongQuestion}
                            nextWrongQuestion={nextWrongQuestion}
                            isLastNode={isLastNode}
                        />
                    </Space>
                </Col>
                <Col flex="0" style={{ padding: 0 }}>
                    <TaskMessageModal
                        ref={taskMessageModalRef}
                        setWrongQuestionIndex={setWrongQuestionIndex}
                        cancelTaskHandle={cancelTaskHandle}
                        wrongQuestionSet={wrongQuestionSet}
                    />
                </Col>
            </Row>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
