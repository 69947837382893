// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetSupervisionRuleDetailsRecommendByIdQuery,
    GetSupervisionRuleDetailsRecommendByIdParam,
    BaseResponseInspectionPointFoundResponse,
} from '../../../types';

/**
 * 获取规则推荐案例和知识库列表
 *
 * @export
 * @tags 监督项规则设置API
 * @link [GET] /supervisionRuleDetails/recommend/{id}
 * @param id
 */
export function getSupervisionRuleDetailsRecommendById(
    options: ApiOptions<
        GetSupervisionRuleDetailsRecommendByIdParam,
        GetSupervisionRuleDetailsRecommendByIdQuery
    > & { segments: GetSupervisionRuleDetailsRecommendByIdParam } & {
        params: GetSupervisionRuleDetailsRecommendByIdQuery;
    }
): Promise<BaseResponseInspectionPointFoundResponse> {
    return request<
        never,
        GetSupervisionRuleDetailsRecommendByIdParam,
        GetSupervisionRuleDetailsRecommendByIdQuery
    >('/supervisionRuleDetails/recommend/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSupervisionRuleDetailsRecommendById',
    });
}

export const meta = [
    { tags: ['监督项规则设置API'], path: '/supervisionRuleDetails/recommend/{id}', method: 'get' },
];
