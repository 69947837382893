import React, { useEffect, useState } from 'react';
// import SdkLoader from 'udesk-react/src/components/sdk-loader';
// import SDK from '../appeal-checking-task/sdk';
import SDKClass from '../appeal-checking-task/list/sdk-index';
import moment from 'moment';
// import Udesk from 'Udesk';
import { Button, Spin, Progress } from 'udesk-ui';
import Locales from 'UdeskLocales';

// const jsUrl = 'http://qa.local.udesk.cn/static/sdk/udesk-qa-react-sdk.js';
// const cssUrl = 'http://qa.local.udesk.cn/static/sdk/udesk-qa-react-sdk.css';

const SDKTest = (props) => {
    const dateFormat = 'YYYY/MM/DD';

    useEffect(() => {
        const options = {
            props: {
                domain: window.location.origin,
                isAppealCheck: true,
                // appealStatusDisabled: true,
                // readStatus: 1,
                // appealCheckStatus: 1,
                defaultDateRange: [
                    moment('2021/01/01', dateFormat),
                    moment('2022/01/01', dateFormat),
                ],
                // language: 'cs',
            },
            methods: {
                getToken() {
                    return new Promise((resolve, reject) => {
                        resolve({
                            app_id: 'udesk',
                            // timestamp: 1663126386,
                            // signature: '150d75a62ba7214a8e8cac93639163b2b998a46e',
                            // agent: 'liyongtian@udesk.cn',
                            // timestamp: 1644991083,
                            // signature: '0c5782bc912e95cdfc4067f4d64ef2c2c3b9a329',
                            // agent: 'liyongtian@t2.tryudesk.com',
                            // agent: 'agent@udesk.cn',
                            // agent: 'admin233@udesk.cn',
                            subdomain: 'demo.t2',
                            type: 'SDK',
                            timestamp: 1701746777,
                            signature: '1b6d9f54083ef40908eeb0c62831bd39a2eb66d1',
                            agent: 'fantt@udesk.cn',
                        });
                    });
                },
                tip: (message) => {
                    console.log(message);
                },
                log: (message) => {
                    console.log(message);
                },
            },
        };
        let appInstance = new SDKClass(options);
        appInstance.renderTo(document.getElementById('qa_appeal_web'));
    }, []);

    return (
        <div style={{ height: '100vh' }} id="qa_appeal_web">
            {/* <SDK
                sdkOptions={{
                    props: {
                        domain: window.location.origin,
                        isAppealCheck: true,
                        // appealStatusDisabled: true,
                        // readStatus: 1,
                        // appealCheckStatus: 1,
                        defaultDateRange: [
                            moment('2021/01/01', dateFormat),
                            moment('2022/01/01', dateFormat),
                        ],
                    },
                    methods: {
                        getToken() {
                            return new Promise((resolve, reject) => {
                                resolve({
                                    app_id: 'udesk',
                                    // timestamp: 1663126386,
                                    // signature: '150d75a62ba7214a8e8cac93639163b2b998a46e',
                                    // agent: 'liyongtian@udesk.cn',
                                    // timestamp: 1644991083,
                                    // signature: '0c5782bc912e95cdfc4067f4d64ef2c2c3b9a329',
                                    // agent: 'liyongtian@t2.tryudesk.com',
                                    // agent: 'agent@udesk.cn',
                                    // agent: 'admin233@udesk.cn',
                                    subdomain: 'demo.t2',
                                    type: 'SDK',
                                    timestamp: 1701746777,
                                    signature: '1b6d9f54083ef40908eeb0c62831bd39a2eb66d1',
                                    agent: 'fantt@udesk.cn',
                                });
                            });
                        },
                        tip: (message) => {
                            console.log(message);
                        },
                        log: (message) => {
                            console.log(message);
                        },
                    },
                }}
            ></SDK> */}
        </div>
    );
};

const useMockPercent = (initValue, limit = 100, timeStep = 500) => {
    const [percent, setPercent] = useState(initValue);

    const getNewPercent = (prev) => Math.min(prev + Math.ceil(Math.random() * 10), limit);
    const startMock = () => {
        setPercent(getNewPercent);
    };
    useEffect(() => {
        if (percent === initValue) return;
        if (percent >= limit) return;
        setTimeout(() => {
            setPercent(getNewPercent);
        }, timeStep);
    }, [percent]);

    return [percent, startMock, setPercent];
};

const ExportFunc = (props) => {
    const [spinning, setSpinning] = useState(false);
    const [percent, startMock, setPercent] = useMockPercent(0, 70);
    const locales = Locales['current'];
    const onClick = () => {
        setSpinning(true);
        startMock();
    };
    return (
        <div>
            <SDKTest />
            <Button onClick={onClick}>open Loading</Button>
            <Spin
                spinning={spinning}
                indicator={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Progress
                            type="circle"
                            strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                            percent={percent}
                        />
                        <div>
                            {
                                locales.pages.sdkTest.index
                                    .qualityInspectionInProgressPleaseCheckLater
                            }
                        </div>
                        <Button onClick={() => setPercent(100)}>finish</Button>
                    </div>
                }
            >
                <div>lalala</div>
            </Spin>
        </div>
    );
};

export default ExportFunc;
