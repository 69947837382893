// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerTaskInfosPictureNodesQuery,
    BaseResponseListIntelligentPartnerDialogFlowNode,
} from '../../../types';

/**
 * 查询画像绑定的流程节点
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/picture/nodes
 */
export function getIntelligentPartnerTaskInfosPictureNodes(
    options: ApiOptions<never, GetIntelligentPartnerTaskInfosPictureNodesQuery> & {
        params: GetIntelligentPartnerTaskInfosPictureNodesQuery;
    }
): Promise<BaseResponseListIntelligentPartnerDialogFlowNode> {
    return request<never, never, GetIntelligentPartnerTaskInfosPictureNodesQuery>(
        '/intelligentPartnerTaskInfos/picture/nodes',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerTaskInfosPictureNodes',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/picture/nodes',
        method: 'get',
    },
];
