import React from 'react';
import Udesk from 'Udesk';
import { Button, Icon, Modal } from 'udesk-ui';
import { getSystemModule } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

class ListManageComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        title: '',
        searchPlaceHolder: '',
        searchFieldName: 'keyword',
        type: Udesk.enums.filterTypes.call.id,
        // filter: null,
        editFilterVisible: false,
        selectedFilter: {}
    };
    //#endregion

    state = {};
    privates = {
        paging: {},
        data: {},
        judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        customJudgeLogic: '',
        isSave: null,
        filterName: '',
        updateType: null,
        count: 0,
        timeFlag: true,
        pagess: true,
        timer: null
    };

    static computes = {};
    init() {
        let columns = this.getColumns();

        this.privates.columns = columns;
    }
    actions = {
        asyncCancel(id) {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`impExp/cancel/${id}`, sdkOptions);

            Modal.confirm({
                title: /* 您确认要取消吗？取消后将不能再恢复 */UdeskLocales['current'].pages.taskCenter.downloadList.component.areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain,
                icon: <Icon type='ExclamationCircleOutlined' antdIcon={true} />,
                okText: this.locales.labels.confirm,
                cancelText: this.locales.labels.cancel,
                onOk () {
                    Udesk.ajax.post(url).then(
                        resp => {
                            let {pageNum, pageSize} = this.privates.paging;
                            this.actions.reloadAsyncModel({
                                pageNum, pageSize
                            });
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                    );
                },
            });
        },
        download(e) {
            if (e) {
                window.location = e;
            } else {
                Udesk.ui.notify.error(this.locales.fix.downloadError);
            }
        },
        focusTtile(e) {
        },
        pageChanged(pageNum, pageSize, total) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        resultStatus() {
            let { sdkOptions } = this.props;
            let params = {
                pageNum: this.privates.paging.pageNum,
                pageSize: this.privates.paging.pageSize,
                moduleType: getSystemModule(),
            };

            let url = Udesk.business.apiPath.concatApiPath(`impExp/1`, sdkOptions);
            let count = 0;
            this.privates.timer = setTimeout(() => {
                count = 0;

                new Promise((resolve, reject) => {
                    Udesk.ajax.get(url, params).then(
                        resp => {
                            if (resp.data && resp.data.length > 0) {
                                this.privates.data = resp.data;
                                this.actions.update();
                                resp.data.forEach(item => {
                                    if (item.result.indexOf('%') === -1) {
                                        count++;
                                    }
                                });
                            }

                            this.actions.resultStatus();

                            if (count === this.privates.data.length) {
                                clearTimeout(this.privates.timer);
                            }
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }, 5000);
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {};
    }
    componentDidMount() {
        if (this.props.location.search) {
            let objList = this.props.location.search.split('=');
            let objId = objList[1];
            this.actions.reloadAsyncModel(this.privates.paging, objId);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.location.search &&
            this.props.location.search &&
            prevProps.location.search !== this.props.location.search
        ) {
            let objList = this.props.location.search.split('=');
            let objId = objList[1];
            this.actions.reloadAsyncModel(this.privates.paging, objId);
        }
    }
    componentWillUnmount() {
        clearTimeout(this.privates.timer);
    }

    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult } = parseOptions;
        if (asyncResult) {
            if (asyncResult.data) {
                if(this.privates.timer) {
                    clearTimeout(this.privates.timer);
                }
                this.privates.data = asyncResult.data;
                if (this.privates.data.length > 0) {
                    for (var i = 0; i < this.privates.data.length; i++) {
                        if (this.privates.data[i].result.indexOf('%') !== -1) {
                            if (asyncResult.paging) {
                                this.privates.paging = asyncResult.paging;
                            }
                            return this.actions.resultStatus();
                        } else {
                            clearTimeout(this.privates.timer);
                        }
                    }
                }
            }
            if (asyncResult.paging) {
                this.privates.paging = asyncResult.paging;
                this.privates.pagess = true;
                let { pageNum, pageSize, total } = this.privates.paging;
                this.trigger('onPageChanged', pageNum, pageSize, total);
            }
            this.actions.update();
        }
    }

    //#endregion
    getColumns() {
        let { locales } = this;
        let columns = [
            {
                name: 'taskName',
                caption: locales.fields.uploadDownload.taskName
            },
            {
                name: 'createTime',
                caption: locales.fields.uploadDownload.createTime
            },
            {
                name: 'size',
                caption: locales.fields.uploadDownload.size
            },
            {
                name: 'createUserName',
                caption: locales.fields.uploadDownload.createUserName
            },
            {
                name: 'status',
                caption: locales.fields.uploadDownload.status,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    if (item.status === 4) {
                        return locales.components.pages.uploadDownloadModel.list.fail;
                    } else if (item.status === 5) {
                        return locales.components.pages.uploadDownloadModel.list.success;
                    } else if (item.status === 9) {
                        return locales.labels.cancel;
                    } else {
                        return locales.components.pages.uploadDownloadModel.list.hard;
                    }
                }
            },
            {
                name: 'result',
                caption: locales.fields.uploadDownload.result
            },
            {
                name: 'actions',
                caption: locales.fields.uploadDownload.actions,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    if (item.resultLink) {
                        return (
                            <Button 
                                size='small'
                                type='link' 
                                title={locales.labels.download} 
                                onClick={this.actions.download.params(item.resultLink)}
                            >
                                {locales.labels.download}
                            </Button>
                        );
                    } else {
                        if (![4, 5, 9].includes(item.status)) {
                            return (
                                <Button 
                                    danger
                                    size='small'
                                    type='link'
                                    onClick={() => this.actions.asyncCancel(item.id)} 
                                >
                                    {locales.labels.cancel}
                                </Button>
                            );
                        }
                    }
                }
            }
        ];
        return columns;
    }
}

export default ListManageComponent;
