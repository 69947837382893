// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerNodeRobotConfigQuery,
    BaseResponseIntelligentPartnerDialogNodeRobotConfigFoundResponse,
    PostReviewIntelligentPartnerNodeRobotConfigBody,
} from '../../../../types';

/**
 * 查询节点配置
 *
 * @export
 * @tags 智能陪练-机器人对话节点配置
 * @link [GET] /review/intelligentPartner/node/robotConfig
 */
export function getReviewIntelligentPartnerNodeRobotConfig(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodeRobotConfigQuery> & {
        params: GetReviewIntelligentPartnerNodeRobotConfigQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeRobotConfigFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerNodeRobotConfigQuery>(
        '/review/intelligentPartner/node/robotConfig',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeRobotConfig',
        }
    );
}

/**
 * 保存机器人节点配置
 *
 * @export
 * @tags 智能陪练-机器人对话节点配置
 * @link [POST] /review/intelligentPartner/node/robotConfig
 */
export function postReviewIntelligentPartnerNodeRobotConfig(
    data: PostReviewIntelligentPartnerNodeRobotConfigBody
): Promise<BaseResponseIntelligentPartnerDialogNodeRobotConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeRobotConfig(
    data: PostReviewIntelligentPartnerNodeRobotConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeRobotConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeRobotConfig(
    data: PostReviewIntelligentPartnerNodeRobotConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeRobotConfigFoundResponse> {
    return request<PostReviewIntelligentPartnerNodeRobotConfigBody, never, never>(
        '/review/intelligentPartner/node/robotConfig',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeRobotConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-机器人对话节点配置'],
        path: '/review/intelligentPartner/node/robotConfig',
        method: 'get',
    },
    {
        tags: ['智能陪练-机器人对话节点配置'],
        path: '/review/intelligentPartner/node/robotConfig',
        method: 'post',
    },
];
