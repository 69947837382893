import React, { useState, useCallback, useEffect } from 'react';
import { Badge, Table } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import './index.scss';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        scenario = 2,
        data,
        setData,
        selectedRowKey, 
        setSelectedRowKey, 
    }= props;
    const [loading, setLoading] = useState(false);
    const request = useRequest();
    
    const renderCell = useCallback((text, record) => {
        return (
            <div className='table-row-item'onClick={() => setSelectedRowKey(record.key)}>
                <span>{record.name}</span>
                <div className="toolkit-button">
                    <Badge style={{ backgroundColor: '#f5f5f5' }} count={record.acceptCount || 0} overflowCount={999} />
                </div>
            </div>
        );
    }, [setSelectedRowKey]);

    useLiveEffect((success) => {
        setLoading(true);
        const url = `/review/words/favorite/${scenario}`;
        request(url, {
            pageNum: 1,
            pageSize: 99999,
        }).then(({data}) => {
            success(() => {
                setData(data.map(item => {
                    return {
                        ...item, key: item.clusterId
                    };
                }));
            });
        }).finally(() => {
            setLoading(false);
        });
    }, [scenario]);

    useEffect(() => {
        if (!selectedRowKey) {
            if (data.length > 0) {
                setSelectedRowKey(data[0].key);
            }
        }
    }, [
        data, 
        selectedRowKey, 
        setSelectedRowKey
    ]);

    return (
        <div className='words-mining-favorite-page-left selling-point'>
            <Table
                loading={loading}
                scroll={{
                    y: '100%'
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    columnTitle: <div className='column-header'>{/* 产品卖点话术库 */}{UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.favorite.components.pageLeftTable.productSellingPointScriptLibrary}</div>,
                    type: 'radio',
                    renderCell,
                }}
                pagination={{
                    pageSize: 99999,
                    total: data.length,
                    hideOnSinglePage: true,
                }} 
                columns={[]}
                dataSource={data}
            />
        </div>
    );
});
