import Udesk from '../../../../common';
import SemanticMarkComponent from './component';

class SemanticMarkRoute extends SemanticMarkComponent {
    asyncModel(changedParams) {
        let {
            taskId,
        } = this.props;
        let {
            content,
        } = this.privates;
        this.privates.markedSimilarList = [];
        this.privates.markedExcludeList = [];
        this.privates.similarTagList = [];
        this.privates.taskTagList = [];
        // 已标记的语义标签数据源
        let semanticTagPromise = new Promise((resolve, reject) => {
            let url = "/backend/semantic-tag/similar-tag-group";
            let params = {
                content,
            };
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.listManage.getRecordsFailed);
                    reject(reason);
                }
            );
        });

        // 识别出的相似语义标签数据源
        let similarSemanticTagPromise = new Promise((resolve, reject) => {
            let url = "/backend/semantic-tag/similar-semantic-tag-groups";
            let params = {
                text: content,
            };
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.listManage.getRecordsFailed);
                    reject(reason);
                }
            );
        });

        // 任务相关语义标签数据源
        let taskSemanticTagPromise = new Promise((resolve, reject) => {
            if (taskId == null) {
                return { data: [] };
            }
            let url = `/backend/semantic-tag/semantic-tag-in-task/${taskId}/10`;
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.listManage.getRecordsFailed);
                    reject(reason);
                }
            );
        });

        return ({
            semanticTag: semanticTagPromise,
            similarSemanticTag: similarSemanticTagPromise,
            taskSemanticTag: taskSemanticTagPromise,
        });
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        if (asyncKey === "semanticTag") {
            if (asyncModel.semanticTag && asyncModel.semanticTag.data) {
                let list = asyncModel.semanticTag.data;
                list.forEach(item => {
                    if (item.tag.standardFlag === 0 || item.tag.standardFlag === 1) {
                        this.privates.markedSimilarList.push({
                            groupId: item.id,
                            title: item.title,
                            standard: item.content,
                            standardFlag: item.tag.standardFlag,
                        });
                    } else if (item.tag.standardFlag === 2) {
                        this.privates.markedExcludeList.push({
                            groupId: item.id,
                            title: item.title,
                            standard: item.content,
                        });
                    }
                });
            }
        } else if (asyncKey === "similarSemanticTag") {
            if (asyncModel.similarSemanticTag && asyncModel.similarSemanticTag.data && asyncModel.similarSemanticTag.data.semanticResults) {
                let list = asyncModel.similarSemanticTag.data.semanticResults;
                this.privates.similarTagList = list.map(item => {
                    return {
                        groupId: item.groupId,
                        title: item.semanticName,
                        standard: item.sentence,
                    };
                });
            }
        } else if (asyncKey === "taskSemanticTag") {
            if (asyncModel.taskSemanticTag && asyncModel.taskSemanticTag.data) {
                let list = asyncModel.taskSemanticTag.data;
                this.privates.taskTagList = list.map(item => {
                    let standard = item.tags.filter(tag => {
                        return tag.standardFlag === 1;
                    });
                    return {
                        groupId: item.id,
                        title: item.title,
                        standard: standard.length > 0 ? standard[0].content : "",
                    };
                });
            }
        }
        return asyncModel;
    }
}

export default SemanticMarkRoute;
