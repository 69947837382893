import UdeskLocales from 'UdeskLocales'; // import Locales from '../locales/index';

// 任务类型
const learningTaskType = [
    {
        name: () => /* 练习 */ UdeskLocales['current'].enums.coach.practice,
        key: 'notStarted',
        id: 1,
    },

    {
        name: () => /* 考试 */ UdeskLocales['current'].enums.coach.examination,
        key: 'underWay',
        id: 2,
    },
    {
        name: () => /* 其他 */ UdeskLocales['current'].enums.coach.other,
        key: 'other',
        id: 3,
    },
];

// 得分结果
const learningTaskScoreType = [
    {
        name: () => /* 未达标 */ UdeskLocales['current'].enums.coach.notMeetingStandards,
        key: 'belowStandard',
        id: 0,
        icon: 'substandard',
    },
    {
        name: () => /* 达标 */ UdeskLocales['current'].enums.coach.meetingStandards,
        key: 'aboveStandard',
        id: 1,
        icon: 'standard',
    },
    {
        name: () => /* 优秀 */ UdeskLocales['current'].enums.coach.excellent,
        key: 'outstanding',
        id: 2,
        icon: 'excellence',
    },
    {
        name: () => /* 审核中 */ UdeskLocales['current'].enums.coach.underReview,
        key: 'reviewing',
        id: 5,
        icon: 'substandard',
    },
];

//任务状态
const learningTaskStatusType = [
    {
        name: () => /* 失效 */ UdeskLocales['current'].enums.coach.invalid,
        key: 'loseEfficacy',
        id: 0,
    },
    {
        name: () => /* 未开始 */ UdeskLocales['current'].enums.coach.notStarted,
        key: 'notStarted',
        id: 1,
    },

    {
        name: () => /* 进行中 */ UdeskLocales['current'].enums.coach.haveInHand,
        key: 'underWay',
        id: 2,
    },
    {
        name: () => /* 已结束 */ UdeskLocales['current'].enums.coach.ended,
        key: 'finished',
        id: 3,
    },
];

//任务完成状态-练习
const learningTaskScheduleStatusType = [
    {
        name: () => /* 未开始 */ UdeskLocales['current'].enums.coach.notStarted,
        key: 'notStarted',
        id: 0,
    },
    {
        name: () => /* 进行中 */ UdeskLocales['current'].enums.coach.haveInHand,
        key: 'underWay',
        id: 1,
    },
    {
        name: () => /* 已完成 */ UdeskLocales['current'].enums.coach.completed,
        key: 'completed',
        id: 2,
    },
    {
        name: () => /* 未完成 */ UdeskLocales['current'].enums.coach.hangInTheAir,
        key: 'unfinished',
        id: 3,
    },
];

// 考试任务完成状态
const learningTaskScheduleStatusType2 = [
    {
        name: () => /* 未开始 */ UdeskLocales['current'].enums.coach.notStarted,
        key: 'notStarted',
        id: 0,
    },
    {
        name: () => /* 进行中 */ UdeskLocales['current'].enums.coach.haveInHand,
        key: 'underWay',
        id: 1,
    },
    {
        name: () => /* 已通过 */ UdeskLocales['current'].enums.coach.passed,
        key: 'completed',
        id: 2,
    },
    {
        name: () => /* 未通过 */UdeskLocales['current'].enums.coach.notPassed,
        key: 'unfinished',
        id: 3,
    },
];

// 考试类型
const learningTaskDialogueModeType = [
    {
        name: () => /* 语音对话 */ UdeskLocales['current'].enums.coach.voiceConversation,
        key: 'voice',
        id: 1,
    },
    {
        name: () => /* 模拟电话 */UdeskLocales['current'].enums.coach.analogTelephone,
        key: 'phone',
        id: 4,
    },
    {
        name: () => /* 文本对话 */ UdeskLocales['current'].enums.coach.textDialogue,
        key: 'text',
        id: 2,
    },
    {
        name: () => /* 幻灯片演讲 */ UdeskLocales['current'].enums.coach.slidePresentation,
        key: 'ppt',
        id: 3,
    },
];

// 考试类型
// const learningTaskEvaluationModeType = [
//     {
//         name: '整体',
//         key: 'overall',
//         id: 1,
//     },
//     {
//         name: '单页',
//         key: 'single',
//         id: 2,
//     }
// ];

// 课程状态
const corseStatusType = [
    {
        name: () => /* 未发布 */ UdeskLocales['current'].enums.coach.unpublished,
        key: 'notStarted',
        id: 0,
    },
    {
        name: () => /* 已发布 */ UdeskLocales['current'].enums.coach.published,
        key: 'underWay',
        id: 1,
    },
];

const nodeTypes = [
    {
        id: 1,
        key: 'startNode',
        name: () => /* 旁白 */ UdeskLocales['current'].enums.coach.narration,
        icon: 'xingzhuang2',
    },
    {
        id: 2,
        key: 'userNode',
        name: () => /* 学员说话 */ UdeskLocales['current'].enums.coach.studentSpeaking,
        icon: 'xingzhuang3',
    },
    {
        id: 3,
        key: 'robotNode',
        name: () => /* 机器人说话 */ UdeskLocales['current'].enums.coach.robotTalk,
        icon: 'xingzhuang4',
    },
    {
        id: 4,
        key: 'knowledgeNode',
        name: () => /* 知识库问答 */ UdeskLocales['current'].enums.coach.knowledgeBaseQA,
        icon: 'xingzhuang1',
    },
    {
        id: 5,
        key: 'pptNode',
        name: () => /* 幻灯片 */ UdeskLocales['current'].enums.coach.slide,
        icon: 'ic-computer-diannao',
    },
    // { id: 6, key: 'onePagePPT', name: () => '单页PPT', icon: 'xingzhuang1' },
    {
        id: 7,
        key: 'aiGenSceneNode',
        name: () => /* 输入情景信息 */ UdeskLocales['current'].enums.coach.enterScenarioInformation,
        icon: 'xingzhuang1',
    },
    {
        id: 8,
        key: 'aiGenDialogueNode',
        name: () =>
            /* 生成对话框架 */ UdeskLocales['current'].enums.coach.generateDialogueFramework,
        icon: 'xingzhuang1',
    },
    {
        id: 9,
        key: 'aiGenPromptNode',
        name: () => /* 生成提示语 */ UdeskLocales['current'].enums.coach.generatePromptLanguage,
        icon: 'xingzhuang1',
    },
];

const sentimentValues = [
    {
        id: -1,
        key: 'negative',
        name: () => /* 情绪负向 */ UdeskLocales['current'].enums.coach.negativeEmotions,
    },
    {
        id: 0,
        key: 'neutral',
        name: () => /* 情绪正常 */ UdeskLocales['current'].enums.coach.emotionallyNormal,
    },
    {
        id: 1,
        key: 'positive',
        name: () => /* 情绪正常 */ UdeskLocales['current'].enums.coach.emotionallyNormal,
    },
];

const wordsCompletions = [
    {
        id: 0,
        key: 'notCompletion',
        name: () => /* 话术不完整 */ UdeskLocales['current'].enums.coach.incompleteScript,
    },
    {
        id: 1,
        key: 'completion',
        name: () => /* 话术完整 */ UdeskLocales['current'].enums.coach.completeScript,
    },
];
const speakSpeeds = [
    {
        id: 1,
        key: 'neutral',
        name: () => /* 语速正常 */ UdeskLocales['current'].enums.coach.normalSpeechSpeed,
    },
    {
        id: 2,
        key: 'fast',
        name: () => /* 语速过快 */ UdeskLocales['current'].enums.coach.speakTooFast,
    },
    {
        id: 3,
        key: 'slow',
        name: () => /* 语速过慢 */ UdeskLocales['current'].enums.coach.speakTooSlowly,
    },
];
const pptEvaluationMode = [
    { id: 1, key: 'whole', name: () => /* 整体 */ UdeskLocales['current'].enums.coach.whole },
    {
        id: 2,
        key: 'singlePage',
        name: () => /* 单页 */ UdeskLocales['current'].enums.coach.singlePage,
    },
];
const pptConfigType = [
    { id: 1, key: 'pptConfig' },
    { id: 2, key: 'singlePage' },
];
const lessonType = [
    {
        id: 1,
        key: 'question',
        name: () => /* 一问一答 */ UdeskLocales['current'].enums.coach.qA,
        color: '#1fcd6b',
    },
    {
        id: 3,
        key: 'dialogue',
        name: () => /* 情景对话 */ UdeskLocales['current'].enums.coach.situationalDialogue,
        color: '#0040ff',
    },
    {
        id: 2,
        key: 'ppt',
        name: () => /* 幻灯片 */ UdeskLocales['current'].enums.coach.slide,
        color: '#ff8c00',
    },
    {
        id: 4,
        key: 'aiGen',
        name: () => /* AI生成对话 */ UdeskLocales['current'].enums.coach.aIGeneratedDialogue,
        color: 'rgb(196, 0, 255)',
    },
];
const scoreRuleHit = [
    { id: 0, key: 'noHit', name: () => /* 未命中 */ UdeskLocales['current'].enums.coach.misses },
    { id: 1, key: 'hit', name: () => /* 命中 */ UdeskLocales['current'].enums.coach.hit },
];

const scoreRuleOperator = [
    { id: 1, key: 'add', name: () => '+' },
    { id: 2, key: 'sub', name: () => '-' },
];
const questionTypes = [
    {
        key: 'sequential',
        name: () => /* 顺序问答 */ UdeskLocales['current'].enums.coach.sequentialQA,
        id: 1,
    },
    {
        key: 'random',
        name: () => /* 随机问答 */ UdeskLocales['current'].enums.coach.randomQA,
        id: 2,
    },
];
const questionNumTypes = [
    {
        key: 'all',
        name: () => /* 全部 */ UdeskLocales['current'].enums.coach.whole,
        id: 1,
    },
    {
        key: 'custom',
        name: () => /* 自定义 */ UdeskLocales['current'].enums.coach.custom,
        id: 2,
    },
];

const graspingStates = [
    {
        key: 'grasped',
        name: () => /* 已掌握 */ UdeskLocales['current'].enums.coach.mastered,
        id: 1,
    },
    {
        key: 'unGrasped',
        name: () => /* 未掌握 */ UdeskLocales['current'].enums.coach.notMastered,
        id: 0,
    },
];

const enableManualReviewType = [
    { id: 0, key: 'close', name: () => /* 否 */ UdeskLocales['current'].enums.coach.no },
    { id: 1, key: 'open', name: () => /* 是 */ UdeskLocales['current'].enums.coach.yes },
];

const scoringSourceType = [
    {
        id: 1,
        key: 'autoScoring',
        name: () => /* 自动评分 */ UdeskLocales['current'].enums.coach.automaticRating,
    },
    {
        id: 2,
        key: 'userScoring',
        name: () => /* 审核评分 */ UdeskLocales['current'].enums.coach.auditRating,
    },
    {
        id: 3,
        key: 'ReScoring',
        name: () => /* 重新评分 */ UdeskLocales['current'].enums.coach.reRating,
    },
    {
        id: 4,
        key: 'artificial',
        name: () => /* 人工复核 */ UdeskLocales['current'].fix.artificialReview,
    },
];

const taskCenterReviewStatus = [
    {
        id: 1,
        key: 'complaining',
        name: () => /* 申诉中 */ UdeskLocales['current'].enums.coach.inAppeal,
    },
    {
        id: 2,
        key: 'reviewed',
        name: () => /* 已复核 */ UdeskLocales['current'].enums.coach.reviewed,
    },
];

const taskCenterAuditViewStatus = [
    {
        id: 0,
        key: 'notViewed',
        name: () => /* 未查看 */ UdeskLocales['current'].enums.coach.notViewed,
    },
    { id: 1, key: 'viewed', name: () => /* 已查看 */ UdeskLocales['current'].enums.coach.viewed },
];

const taskCenterAuditChangedStatus = [
    {
        id: 0,
        key: 'unedited',
        name: () => /* 未修正 */ UdeskLocales['current'].enums.coach.uncorrected,
    },
    {
        id: 1,
        key: 'edited',
        name: () => /* 已修正 */ UdeskLocales['current'].enums.coach.corrected,
    },
];

const dataPreprocessType = [
    { id: 1, key: 'all', name: () => /* 全部数据 */ UdeskLocales['current'].enums.coach.allData },
    {
        id: 2,
        key: 'current',
        name: () => /* 本部门数据 */ UdeskLocales['current'].enums.coach.dataFromThisDepartment,
    },
    {
        id: 3,
        key: 'custom',
        name: () => /* 多部门数据 */ UdeskLocales['current'].enums.coach.multidepartmentalData,
    },
];

const scoreStandardUnitType = [
    {
        id: 1,
        key: 'score',
        name: () => UdeskLocales['current'].pages.gong.coachTaskCenter.edit.score,
    },
    { id: 2, key: 'percentage', name: () => '%' },
];

const dimensionTypes = [
    { id: 1, key: 'sys', name: () => /* 系统 */ UdeskLocales['current'].enums.coach.system },
    { id: 2, key: 'cus', name: () => /* 自定义 */ UdeskLocales['current'].enums.coach.custom },
];

const scoringModelTypes = [
    { id: 1, key: 'sys', name: () => /* 系统 */ UdeskLocales['current'].enums.coach.system },
    { id: 2, key: 'cus', name: () => /* 自定义 */ UdeskLocales['current'].enums.coach.custom },
];

const dimension = [
    {
        id: 1,
        key: 'flow',
        name: () => /* 流程 */ UdeskLocales['current'].enums.coach.flow,
        scoreKey: 'flowScore',
    },
    {
        id: 2,
        key: 'word',
        name: () => /* 话术 */ UdeskLocales['current'].enums.coach.script,
        scoreKey: 'wordsScore',
    },
    {
        id: 3,
        key: 'deliver',
        name: () => /* 表达 */ UdeskLocales['current'].enums.coach.express,
        scoreKey: 'expressScore',
    },
    {
        id: 4,
        key: 'emotion',
        name: () => /* 情绪 */ UdeskLocales['current'].enums.coach.emotion,
        scoreKey: 'emotionScore',
    },
];
const scoringMethod = [
    {
        id: 1,
        key: 'task',
        name: () =>
            /* 按照课程配置评分 */ UdeskLocales['current'].enums.coach
                .rateAccordingToCourseConfiguration,
    },
    {
        id: 2,
        key: 'model',
        name: () => /* 智能模型评分 */ UdeskLocales['current'].enums.coach.intelligentModelRating,
    },
];

export default {
    scoreStandardUnitType,
    scoringSourceType,
    scoreRuleOperator,
    scoreRuleHit,
    corseStatusType,
    learningTaskType,
    learningTaskScoreType,
    learningTaskStatusType,
    learningTaskDialogueModeType,
    // learningTaskEvaluationModeType,
    learningTaskScheduleStatusType2,
    learningTaskScheduleStatusType,
    nodeTypes,
    sentimentValues,
    wordsCompletions,
    speakSpeeds,
    pptEvaluationMode,
    pptConfigType,
    lessonType,
    questionTypes,
    questionNumTypes,
    graspingStates,
    enableManualReviewType,
    taskCenterReviewStatus,
    taskCenterAuditViewStatus,
    taskCenterAuditChangedStatus,
    dataPreprocessType,
    dimensionTypes,
    dimension,
    scoringModelTypes,
    scoringMethod,
};
