// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerTestStartQuery,
    BaseResponseIntelligentPartnerTrainingWords,
} from '../../../../types';

/**
 * 开始对话(返回第一个话术)
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [GET] /review/intelligentPartner/test/start
 */
export function getReviewIntelligentPartnerTestStart(
    options: ApiOptions<never, GetReviewIntelligentPartnerTestStartQuery> & {
        params: GetReviewIntelligentPartnerTestStartQuery;
    }
): Promise<BaseResponseIntelligentPartnerTrainingWords> {
    return request<never, never, GetReviewIntelligentPartnerTestStartQuery>(
        '/review/intelligentPartner/test/start',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewIntelligentPartnerTestStart' }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/test/start',
        method: 'get',
    },
];
