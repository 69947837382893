// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetSemanticTagReferenceByIdParam,
    BaseResponseListReferenceTagDTO,
} from '../../../types';

/**
 * getReferenceDetail
 *
 * @export
 * @tags semantic-tag-controller
 * @link [GET] /semantic-tag/reference/{id}
 * @param id
 */
export function getSemanticTagReferenceById(
    options: ApiOptions<GetSemanticTagReferenceByIdParam, never> & {
        segments: GetSemanticTagReferenceByIdParam;
    }
): Promise<BaseResponseListReferenceTagDTO> {
    return request<never, GetSemanticTagReferenceByIdParam, never>('/semantic-tag/reference/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSemanticTagReferenceById',
    });
}

export const meta = [
    { tags: ['semantic-tag-controller'], path: '/semantic-tag/reference/{id}', method: 'get' },
];
