// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetCustomerTagGroupsQuery,
    BaseResponseListCustomerTagGroupFoundResponse,
    PostCustomerTagGroupsBody,
    BaseResponseCustomerTagGroupFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags customer-tag-group-controller
 * @link [GET] /customerTagGroups
 */
export function getCustomerTagGroups(): Promise<BaseResponseListCustomerTagGroupFoundResponse>;
export function getCustomerTagGroups(
    options: ApiOptions<never, GetCustomerTagGroupsQuery>
): Promise<BaseResponseListCustomerTagGroupFoundResponse>;
export function getCustomerTagGroups(
    options?: ApiOptions<never, GetCustomerTagGroupsQuery>
): Promise<BaseResponseListCustomerTagGroupFoundResponse> {
    return request<never, never, GetCustomerTagGroupsQuery>('/customerTagGroups', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCustomerTagGroups',
    });
}

/**
 * save
 *
 * @export
 * @tags customer-tag-group-controller
 * @link [POST] /customerTagGroups
 */
export function postCustomerTagGroups(
    data: PostCustomerTagGroupsBody
): Promise<BaseResponseCustomerTagGroupFoundResponse>;
export function postCustomerTagGroups(
    data: PostCustomerTagGroupsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseCustomerTagGroupFoundResponse>;
export function postCustomerTagGroups(
    data: PostCustomerTagGroupsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseCustomerTagGroupFoundResponse> {
    return request<PostCustomerTagGroupsBody, never, never>('/customerTagGroups', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCustomerTagGroups',
    });
}

export const meta = [
    { tags: ['customer-tag-group-controller'], path: '/customerTagGroups', method: 'get' },
    { tags: ['customer-tag-group-controller'], path: '/customerTagGroups', method: 'post' },
];
