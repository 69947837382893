import React, { FC, useState, useMemo } from 'react';
import {
    Button,
    Space,
    Modal,
    Form,
    Input,
    Popconfirm,
    Dropdown,
    Menu,
    Radio,
    message,
} from 'udesk-ui';
import { useCoachUserTreeDataWithPermit, UserTreeSelect } from 'src/util/hook/user';
import { EditOutlined } from '@udesk/icons';
import styled from 'styled-components';
import { htmlToBlob, saveBlob } from 'src/util/convertToImage';
import { CUSTOM_KANBAN_WRAPPER_ID } from '../../const';
import {
    putIntelligentPartnerAiAnalysisByIdQueryForm,
    deleteIntelligentPartnerAiAnalysisByIdQueryForm,
} from 'src/api/intelligentPartner/ai/analysis/{id}/query/form';
import { IntelligentPartnerAiQueryReportFormFoundResponse } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
`;

const Operation = styled.div`
    margin-bottom: 8px;
`;

type TemplateProps = {
    statements: IntelligentPartnerAiQueryReportFormFoundResponse[];
    activeKey: string;
    getStatements: any;
    getChart: any;
    chartInfo?: IntelligentPartnerAiQueryReportFormFoundResponse;
    onExport: () => void;
    canModify?: boolean;
};

const Template: FC<TemplateProps> = (props) => {
    const { statements, activeKey, getStatements, getChart, chartInfo, onExport, canModify } =
        props;

    const title = useMemo(() => {
        return statements.find((s) => s.id + '' === activeKey)?.name;
    }, [activeKey, statements]);

    const [visible, setVisible] = useState(false);

    const [form] = Form.useForm();

    const onEdit = () => {
        setVisible(true);
        form.setFieldsValue({ name: title });
    };

    const onCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const onOk = () => {
        form.validateFields().then((values) => {
            putIntelligentPartnerAiAnalysisByIdQueryForm(
                { name: values.name },
                { segments: { id: activeKey as any } }
            )
                .then(() => {
                    message.success(/* 保存成功 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.saveSuccessful);
                    getStatements();
                    getChart();
                })
                .finally(() => {
                    onCancel();
                });
        });
    };

    const onRefresh = () => {
        getChart();
    };

    const onDelete = () => {
        deleteIntelligentPartnerAiAnalysisByIdQueryForm({
            segments: { id: activeKey as any },
        }).then(() => {
            message.success(/* 删除成功 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.deleteSuccessful);
            getStatements({ isReset: true });
        });
    };

    const onExportExcel = () => {
        onExport();
    };

    const onExportSnapshot = async () => {
        const html = document.getElementById(CUSTOM_KANBAN_WRAPPER_ID);
        if (html) {
            // 调用函数，取到截图的blob数据，对图片进行处理（保存本地、展示等）
            const blob = await htmlToBlob(html);
            // 下载图片到手机
            saveBlob(blob, `${title}.png`);
        }
    };

    const [authVisible, setAuthVisible] = useState(false);

    const [authForm] = Form.useForm();

    const { treeData } = useCoachUserTreeDataWithPermit();

    const onSetAuth = () => {
        setAuthVisible(true);
        authForm.setFieldsValue({
            dataPermission: chartInfo?.dataPermission || 1,
            visiblePermission: chartInfo?.visiblePermission || 1,
            staff: {
                userIds: chartInfo?.userIds,
                organizationIds: chartInfo?.organizationIds,
            },
        });
    };

    const onAuthCancel = () => {
        setAuthVisible(false);
    };

    const onAuthOk = () => {
        authForm.validateFields().then((values) => {
            putIntelligentPartnerAiAnalysisByIdQueryForm(
                {
                    dataPermission: values.dataPermission,
                    visiblePermission: values.visiblePermission,
                    userIds: values.staff?.userIds,
                    organizationIds: values.staff?.organizationIds,
                },
                { segments: { id: activeKey as any } }
            )
                .then(() => {
                    message.success(/* 保存成功 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.saveSuccessful);
                    getStatements();
                    getChart();
                })
                .finally(() => {
                    onAuthCancel();
                });
        });
    };

    return (
        <Header>
            <Title>
                <Space size="small">
                    <span>{title}</span>
                    {canModify ? (
                        <Button type="link" icon={<EditOutlined />} onClick={onEdit}></Button>
                    ) : null}
                </Space>

                <Modal title={/* 编辑报表 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.editReport} visible={visible} onCancel={onCancel} onOk={onOk}>
                    <Form form={form}>
                        <Form.Item name="name" label={/* 报表名称 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.reportName} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </Title>

            <Operation>
                <Space>
                    <Button type="link" style={{ padding: 0 }} onClick={onRefresh}>{/* 刷新 */}{UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.refresh}</Button>

                    {canModify ? (
                        <Popconfirm title={/* 确定删除吗？ */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.areYouSureToDeleteIt} onConfirm={onDelete}>
                            <Button type="link" style={{ padding: 0 }}>{/* 删除 */}{UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.delete}</Button>
                        </Popconfirm>
                    ) : null}

                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item onClick={onExportExcel}>{/* 导出Excel */}{UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.exportToExcel}</Menu.Item>
                                <Menu.Item onClick={onExportSnapshot}>{/* 导出快照 */}{UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.exportSnapshot}</Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="link" style={{ padding: 0 }}>{/* 导出 */}{UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.export}</Button>
                    </Dropdown>

                    {canModify ? (
                        <Button type="link" style={{ padding: 0 }} onClick={onSetAuth}>{/* 权限 */}{UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.authority}</Button>
                    ) : null}
                </Space>

                <Modal
                    title={/* 权限设置 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.permissionSettings}
                    visible={authVisible}
                    width={600}
                    onCancel={onAuthCancel}
                    onOk={onAuthOk}
                >
                    <Form form={authForm} layout="vertical">
                        <Form.Item label={/* 报表可见权限 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.reportVisiblePermissions}>
                            <Space size="small">
                                <Form.Item name="visiblePermission" noStyle>
                                    <Radio.Group
                                        options={[
                                            {
                                                label: /* 所有人可见 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.visibleToEveryone,
                                                value: 1,
                                            },
                                            {
                                                label: /* 仅自己可见 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.onlyVisibleToOneself,
                                                value: 2,
                                            },
                                            {
                                                label: /* 指定人员可见 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.designatedPersonnelVisible,
                                                value: 3,
                                            },
                                        ]}
                                    />
                                </Form.Item>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(pre, cur) =>
                                        pre.visiblePermission !== cur.visiblePermission
                                    }
                                >
                                    {({ getFieldsValue }) => {
                                        const isDesignated =
                                            getFieldsValue()?.visiblePermission === 3;
                                        return isDesignated ? (
                                            <Form.Item noStyle name="staff">
                                                <UserTreeSelect
                                                    {...{
                                                        width: 200,
                                                        treeData: treeData,
                                                        multiple: true,
                                                        showSearch: true,
                                                        allowClear: true,
                                                        showArrow: true,
                                                        maxTagCount: 1,
                                                        placeholder: /* 请选择 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.pleaseSelect,
                                                        treeDefaultExpandAll: false,
                                                        treeCheckable: true,
                                                    }}
                                                />
                                            </Form.Item>
                                        ) : null;
                                    }}
                                </Form.Item>
                            </Space>
                        </Form.Item>

                        <Form.Item label={/* 报表数据权限 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.reportDataPermissions} name="dataPermission">
                            <Radio.Group
                                options={[
                                    { label: /* 受限于用户权限 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.restrictedByUserPermissions, value: 1 },
                                    { label: /* 可见人相同 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.header.index.visiblePeopleAreTheSame, value: 2 },
                                ]}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </Operation>
        </Header>
    );
};

export default Template;
