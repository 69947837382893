import React from 'react';
import Udesk from 'Udesk';
import { getCurrentRouteName } from 'Udesk/system/subApp';

class DialogueManageIndexComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {

    };
    //#endregion

    state = {

    };
    privates = {
        storages: {
            selectedFilter: {
                conditionList: [],
                customJudgeLogic: null,
                judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            }
        },
        filterVisible: false,
        isUpdate: false,
        filterManageRef: React.createRef(),
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: "sessionStorage",
        extraStorages: [],
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/dialogue-manage\/view/i,
                /^\/conversation-analysis\/dialogue-manage\/view/i,
            ],
        },
    };

    actions = {
        onPageChanged(pageNum, pageSize, total) {
            this.trigger("onPageChanged", pageNum, pageSize, total);
        },
        editFilter(filter) {
            this.actions.onSelectedFilterChanged(filter);
            this.privates.filterVisible = true;
            this.actions.update();
        },
        onEditFilterVisibleChanged(visible) {
            this.privates.filterVisible = visible;
            this.actions.update();
        },
        onViewItemDetail(item, selectedFilter) {
            this.trigger("onSelectedDatumChanged", item);
            this.trigger("onSelectedFilterChanged", selectedFilter);
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName("dialogueManageView"),
                pathParams: {
                    id: item.dataId
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        onSelectedFilterChanged(filter) {
            if (filter) {
                let conditionList = Udesk.utils.object.deepCopy(filter.conditionList);
                if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                    conditionList = conditionList.map(condition => {
                        if (condition.field && condition.field.id) {
                            condition.field = condition.field.id;
                        }
                        return condition;
                    });
                }
                filter.displayConditionList = conditionList;
                this.privates.storages.selectedFilter = filter;
            } else {
                this.privates.storages.selectedFilter = {
                    conditionList: [],
                    customJudgeLogic: null,
                    judgeStrategy: 1
                };
            }
            this.trigger("onSelectedFilterChanged", this.privates.storages.selectedFilter);
            this.actions.update();
        },
        onConditionFilterSaved(options, data) {
            this.privates.isUpdate = !this.privates.isUpdate;
            if(data){
                // this.actions.onSelectedFilterChanged(data);
                let changeSelectFilter = this.privates.filterManageRef.current.actions.changeSelectFilter;
                changeSelectFilter && changeSelectFilter(data);
            }
            this.actions.update();
            // let {
            //     locales
            // } = this;
            // let {
            //     sdkOptions
            // } = this.props;
            // let params = {
            //     templateType: Udesk.enums.filterTypes.dialogue.id,
            //     name: options.filterName,
            //     conditionList: options.conditionList,
            //     customJudgeLogic: options.customJudgeLogic,
            //     judgeStrategy: options.judgeStrategy
            // };
            // if (params.name == null || params.name === "" || params.name.trim() === "") {
            //     return Udesk.ui.notify.error(
            //         Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.labels.name)
            //     );
            // }
            // let url = Udesk.business.apiPath.concatApiPath(`search-template/save`, sdkOptions);
            // let method = Udesk.enums.requestMethods.create.name;
            // if (options.isUpdate) {
            //     url = Udesk.business.apiPath.concatApiPath(`search-template/update`, sdkOptions);
            //     method = Udesk.enums.requestMethods.update.name;
            //     params.id = options.id;
            // }

            // Udesk.ajax(url, params, method).then(
            //     (resp) => {
            //         Udesk.ui.notify.success(Udesk.utils.string.format(locales.business.notifyMessage.saveSuccessFormat, locales.components.pages.dialogueManage.title));
            //         this.privates.isUpdate = true;
            //         this.actions.update();
            //     },
            //     (reason) => {
            //         Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(locales.business.notifyMessage.saveFailedFormat, locales.components.pages.dialogueManage.title));
            //     }
            // );
        }
    };

    //#region Life Cycle
    init() {
        let transitionParams = JSON.parse(localStorage.getItem('maualAddToManage_pamars'));
        let defaultSearchTemplate = this.props.sdkOptions.props.defaultSearchTemplate.find(template => `${template.templateType}` === Udesk.enums.filterTypes.dialogue.id);
        if(defaultSearchTemplate){
            this.privates.storages.selectedFilter = defaultSearchTemplate;
        }
        if(transitionParams){
            this.privates.storages.selectedFilter = transitionParams;
        }
    }
    parseProps({ props, prevProps }) {
        const queryParams = new URLSearchParams(props.location.search);
        let isNewTab = queryParams.get('newTab');
        if(isNewTab){
            let keyword = queryParams.get('keyword');
            this.privates.keyword = keyword;    
        }
    }
    //#endregion
}

export default DialogueManageIndexComponent;