import React, { useEffect, useState, useMemo } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Button, Table, Form, Input, Radio, Checkbox } from 'udesk-ui';
import './style.scss';
import { postRoles } from 'src/api/roles';
import { putRolesById, getRolesById } from 'src/api/roles/{id}';
import { BaseResponseRoleFoundResponse } from 'src/api/types';

const locales = Locales['current'];

const AdminGongRole = React.memo((props: any) => {
    const sysModule = parseInt(props.match.params.sysModule, 10);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [dataMainMap, setDataMainMap] = useState({});
    const [dataSecMap, setDataSecMap] = useState({});
    const [help, setHelp] = useState('');
    const formItemLayout = {
        labelCol: {
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            sm: {
                span: 8,
            },
        },
    };
    const columns = useMemo(() => {
        return [
            {
                title: locales.pages.gong.roleManage.edit.menu,
                dataIndex: 'mainMenu',
                key: 'mainMenu',
                render: (text, record, index) => {
                    let rowSpan = 1;
                    if (index === dataMainMap[record.mainMenu.id][0]) {
                        rowSpan =
                            dataMainMap[record.mainMenu.id]?.[1] -
                            dataMainMap[record.mainMenu.id][0] +
                            1;
                    } else {
                        rowSpan = 0;
                    }
                    return {
                        children: (
                            <Checkbox
                                checked={record.mainMenu.checked}
                                onChange={handlerMainSelect.bind(null, record)}
                            >
                                {record?.mainMenu?.name}
                            </Checkbox>
                        ),
                        props: {
                            rowSpan,
                        },
                    };
                },
            },
            {
                title: locales.pages.gong.roleManage.edit.functionalCategory,
                dataIndex: 'secondMenu',
                key: 'secondMenu',
                render: (text, record, index) => {
                    let rowSpan = 1;
                    if (record.secondMenu) {
                        if (index === dataSecMap[record.secondMenu?.id][0]) {
                            rowSpan =
                                dataSecMap[record.secondMenu?.id]?.[1] -
                                dataSecMap[record.secondMenu?.id][0] +
                                1;
                        } else {
                            rowSpan = 0;
                        }
                    }
                    return {
                        children: record.secondMenu ? (
                            <Checkbox
                                checked={record.secondMenu.checked}
                                onChange={handlerSecSelect.bind(null, record)}
                            >
                                {record?.secondMenu?.name}
                            </Checkbox>
                        ) : null,
                        props: {
                            rowSpan,
                        },
                    };
                },
            },
            {
                title: locales.pages.gong.roleManage.edit.functionalSubclass,
                dataIndex: 'thirdMenu',
                key: 'thirdMenu',
                render: (text, record, index) => {
                    let rowSpan = 1;
                    if (record.secondMenu) {
                        if (index === dataSecMap[record.secondMenu?.id][0]) {
                            rowSpan =
                                dataSecMap[record.secondMenu?.id]?.[1] -
                                dataSecMap[record.secondMenu?.id][0] +
                                1;
                        } else {
                            rowSpan = 0;
                        }
                    }
                    return {
                        children: (
                            <Checkbox.Group
                                value={record?.tirChecked}
                                onChange={handlerTirSelect.bind(null, record)}
                            >
                                {record?.secondMenu?.childItems.map(
                                    (item) =>
                                        item.moduleType === sysModule && (
                                            <Checkbox key={item.id} value={item.id}>
                                                {item.name}
                                            </Checkbox>
                                        )
                                )}
                            </Checkbox.Group>
                        ),
                        props: {
                            rowSpan,
                        },
                    };
                },
            },
        ];
    }, [dataMainMap, dataSecMap]);
    const handlerMainSelect = (item, val) => {
        let dataSourceMid = dataSource.map((record) => {
            if (item?.mainMenu?.id === record?.mainMenu?.id) {
                record.mainMenu.checked = val.target.checked;
                if (record.secondMenu) {
                    record.secondMenu.checked = val.target.checked;
                }
                if (val.target.checked) {
                    record.secondMenu?.childItems?.forEach((sin) => {
                        record?.tirChecked.push(sin.id);
                    });
                } else {
                    record.tirChecked = [];
                }
            }
            return record;
        });
        setDataSource(dataSourceMid);
    };
    const handlerSecSelect = (item, val) => {
        let secSelectData: any = [];
        let dataSourceMid = dataSource.map((record) => {
            if (item?.mainMenu?.id === record?.mainMenu?.id) {
                secSelectData.push(record);
            }
            if (item?.secondMenu?.id === record?.secondMenu?.id) {
                record.secondMenu.checked = val.target.checked;
                if (val.target.checked) {
                    record.secondMenu?.childItems?.forEach((sin) => {
                        record?.tirChecked.push(sin.id);
                    });
                } else {
                    record.tirChecked = [];
                }
            }
            return record;
        });
        let bool = secSelectData.some((record) => record?.secondMenu?.checked === true);
        dataSourceMid = dataSourceMid.map((record) => {
            if (item?.mainMenu?.id === record?.mainMenu?.id) {
                if (bool) {
                    record.mainMenu.checked = true;
                } else {
                    record.mainMenu.checked = false;
                }
            }
            return record;
        });
        setDataSource(dataSourceMid);
    };
    const handlerTirSelect = (item, val) => {
        let secSelectData: any = [];
        let dataSourceMid = dataSource.map((record) => {
            if (item?.mainMenu?.id === record?.mainMenu?.id) {
                secSelectData.push(record);
            }
            if (item?.secondMenu?.id === record?.secondMenu?.id) {
                if (val.length) {
                    record.mainMenu.checked = true;
                    record.secondMenu.checked = true;
                } else {
                    record.secondMenu.checked = false;
                }
                record.tirChecked = val;
            }
            return record;
        });

        let bool = secSelectData.some((record) => record?.secondMenu?.checked === true);
        dataSourceMid = dataSourceMid.map((record) => {
            if (item?.mainMenu?.id === record?.mainMenu?.id) {
                if (bool) {
                    record.mainMenu.checked = true;
                } else {
                    if (record.tirChecked.length) {
                        record.mainMenu.checked = true;
                        record.secondMenu.checked = true;
                    } else {
                        record.mainMenu.checked = false;
                    }
                }
            }
            return record;
        });
        setDataSource(dataSourceMid);
    };
    const handlerSubmit = () => {
        form.validateFields().then((values) => {
            let menuList: any = [];
            dataSource.forEach((item) => {
                if (item.mainMenu.checked) {
                    menuList.push({
                        id: item.mainMenu.id,
                    });
                }
                if (item?.secondMenu?.checked) {
                    menuList.push({
                        id: item.secondMenu.id,
                    });
                }
                if (item?.tirChecked?.length) {
                    item.tirChecked.forEach((record) => {
                        menuList.push({
                            id: record,
                        });
                    });
                }
            });
            const params = {
                ...values,
                menuList,
            };
            if (parseInt(props.match.params.id, 10) < 0) {
                postRoles(params, {
                    successMsg: locales.pages.gong.roleManage.edit.successfullyAdded,
                }).then((res) => {
                    window.history.back();
                });
            } else {
                putRolesById(
                    { ...params, menuModule: sysModule },
                    {
                        segments: { id: parseInt(props.match.params.id, 10) },
                        successMsg: locales.pages.gong.roleManage.edit.modifiedSuccessfully,
                    }
                ).then((res) => {
                    window.history.back();
                });
            }
        });
    };
    const currentRolesData = (id, dataSource) => {
        getRolesById({ segments: { id } }).then((res: BaseResponseRoleFoundResponse) => {
            form.setFieldsValue({
                name: res?.data?.name,
                dataPermissionLevel: res?.data?.dataPermissionLevel,
            });
            setHelp(getDataPermissionLevelHelp(res?.data?.dataPermissionLevel));
            let dataSourceMin = dataSource.map((item) => {
                res.data &&
                    res.data.menuList &&
                    res.data.menuList.forEach((record) => {
                        if (item.mainMenu.id === record?.id) {
                            item.mainMenu.checked = true;
                        }
                        if (item?.secondMenu?.id === record?.id) {
                            item.secondMenu.checked = true;
                        }
                        item?.secondMenu?.childItems?.forEach((data) => {
                            if (data.id === record?.id) {
                                item.tirChecked.push(record.id);
                            }
                        });
                    });
                return item;
            });
            setDataSource(dataSourceMin);
        });
    };
    useEffect(() => {
        let dataSource: any = [];
        let dataMainMap = {};
        let dataSecMap = {};
        Udesk.data.adminMenuList.root.forEach((item, index) => {
            if (item.childItems && item.childItems.length) {
                item.childItems.forEach((record) => {
                    if (record.moduleType === sysModule) {
                        dataSource.push({
                            mainMenu: {
                                ...item,
                                checked: false,
                            },
                            secondMenu: {
                                ...record,
                                checked: false,
                            },
                            tirChecked: [],
                        });
                    }
                });
            } else {
                if (item.moduleType === sysModule) {
                    dataSource.push({
                        mainMenu: {
                            ...item,
                        },
                        secondMenu: null,
                    });
                }
            }
        });
        dataSource.forEach((item, index) => {
            if (dataMainMap[item.mainMenu?.id]) {
                dataMainMap[item.mainMenu?.id][1] = index;
            } else {
                dataMainMap[item.mainMenu?.id] = [index, index];
            }

            if (dataSecMap[item.secondMenu?.id]) {
                dataSecMap[item.secondMenu?.id][1] = index;
            } else {
                dataSecMap[item.secondMenu?.id] = [index, index];
            }
        });
        setDataMainMap(dataMainMap);
        setDataSecMap(dataSecMap);
        setDataSource(dataSource);
        if (parseInt(props.match.params.id, 10) >= 0) {
            currentRolesData(parseInt(props.match.params.id, 10), dataSource);
        }
    }, []);
    return (
        <Page
            pageBodyClassName="page-role-manage-center"
            title={locales.pages.gong.roleManage.edit.edit}
            padding={true}
            onBack={() => window.history.back()}
            extra={[
                <Button onClick={() => window.history.back()}>
                    {locales.pages.gong.roleManage.edit.cancel}
                </Button>,
                <Button type="primary" onClick={handlerSubmit}>
                    {locales.pages.gong.roleManage.edit.confirm}
                </Button>,
            ]}
        >
            <div className="page-role-manage-container">
                <div className="role-frame-content">
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{ dataPermissionLevel: Udesk.enums.roleType.all.id }}
                        {...formItemLayout}
                        style={{ padding: 16, borderBottom: '6px solid #f0f2f5' }}
                    >
                        <Form.Item
                            name="name"
                            className='page-role-form-name-field'
                            label={locales.pages.gong.roleManage.edit.roleName}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        locales.pages.gong.roleManage.edit.pleaseEnterTheRoleName,
                                },
                            ]}
                        >
                            <Input
                                placeholder={
                                    locales.pages.gong.roleManage.edit.pleaseEnterTheRoleName
                                }
                            />
                        </Form.Item>
                        {sysModule === Udesk.enums.sysModuleList.sales.id && (
                            <Form.Item
                                name="dataPermissionLevel"
                                label={locales.pages.gong.roleManage.edit.dataAuthority}
                                help={help}
                            >
                                <Radio.Group
                                    onChange={(e) => {
                                        setHelp(getDataPermissionLevelHelp(e.target.value));
                                    }}
                                >
                                    {Udesk.enums.roleType.map((item) => (
                                        <Radio key={item.id} value={item.id}>
                                            {item.name}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        )}
                    </Form>
                    <div className="role-frame-detail role-frame-edit-detail">
                        <h3>{locales.pages.gong.roleManage.edit.applicationPermission}</h3>
                        <div className="page-role-manage-container level-child role-edit-level">
                            <Table
                                bordered
                                pagination={false}
                                style={{ flex: 1 }}
                                dataSource={dataSource}
                                columns={columns}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongRole {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

function getDataPermissionLevelHelp(dataPermissionLevel) {
    if (!dataPermissionLevel) return '';
    if (dataPermissionLevel === Udesk.enums.roleType.all.id) {
        return locales.pages.gong.roleManage.edit.youCanViewCompanyWideSessionData;
    } else if (dataPermissionLevel === Udesk.enums.roleType.staff.id) {
        return /* 只能看到自己的会话数据 */ locales.pages.gong.roleManage.edit
            .youCanOnlySeeYourOwnSessionData;
    } else {
        return /* 可以查看本部门及下属部门的会话数据 */ locales.pages.gong.roleManage.edit
            .youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments;
    }
}
