import React from 'react';
import { FormBuilder, Input } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const Alias = (props) => {
    return (
        <React.Fragment>
            <FormBuilder.Item name='loseBusinessDesc' label={/* 输单 */UdeskLocales['current'].pages.gong.businessConfiguration.salesStage.detail.components.alias.orderInput}>
                <Input style={{ width: 200 }} />
            </FormBuilder.Item>
        </React.Fragment>
    );
};

export { Alias };
