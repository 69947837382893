import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Form, DatePicker, TreeSelect, Select, Tabs, Row, Col, Card, Space, List } from 'udesk-ui';
import './index.scss';
import * as echarts from 'echarts';
import moment from 'moment';
import TeamCommon from './team-common';
import { getReviewCallAnalysisOrganizations } from 'src/api/review/call/analysis/organizations';
import {
    BaseResponseDataCallAnalysisOrgOptionResponse,
    BaseResponseDataCallAnalysisOrgResultResponse,
    BaseResponseDataCallAnalysisUserResultResponse,
    BaseResponseDataCallAnalysisUserTagResultResponse,
    BaseResponseListDataCallAnalysisOrgTagResponse,
} from 'src/api/types';
import { getReviewCallAnalysisUserById } from 'src/api/review/call/analysis/user/{id}';
import { getReviewCallAnalysisOrganizationById } from 'src/api/review/call/analysis/organization/{id}';
import { getReviewCallAnalysisOrganizationByIdBestPractise } from 'src/api/review/call/analysis/organization/{id}/bestPractise';
import { getReviewCallAnalysisOrganizationByIdKeyEvents } from 'src/api/review/call/analysis/organization/{id}/keyEvents';
import { getReviewCallAnalysisUserByIdKeyEvents } from 'src/api/review/call/analysis/user/{id}/keyEvents';
import { getReviewCallAnalysisOrganizationByIdKeyEventsBestPractise } from 'src/api/review/call/analysis/organization/{id}/keyEvents/bestPractise';

const locales = UdeskLocales['current'];
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const Template = React.memo((props: any) => {
    const startTime = moment(new Date()).subtract(1, 'months');
    const endTime = moment().subtract(1, 'days');
    const defaultTimeRange = [startTime, endTime];
    const callOptions = {
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                let str =
                    params[0].name +
                    '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                    params[0].value +
                    '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                    0;
                return str;
            },
        },
        xAxis: {
            type: 'category',
            data: [],
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false,
            },
            splitNumber: 5,
        },
        series: [
            {
                data: [],
                type: 'line',
                symbol: 'none',
                lineStyle: {
                    color: '#128FE9',
                },
                markLine: {},
            },
        ],
    };
    const [callActive, setCallActive] = useState([
        {
            label: locales.pages.gong.teamAnalysis.index.averageDurationOfASingleCallminutes,
            value: '',
            id: 0,
            key: 'callDurationAverage',
            type: 'mm',
        },
        {
            label: /* 有效通话量（个） */UdeskLocales['current'].pages.gong.teamAnalysis.index.effectiveCallVolumenumber,
            value: '',
            id: 1,
            key: 'effectiveCalls',
            type: 'num',
        },
        {
            label: locales.pages.gong.teamAnalysis.index.totalCallVolumePCs,
            value: '',
            id: 2,
            key: 'numberOfCalls',
            type: 'num',
        },
        {
            label: locales.pages.gong.teamAnalysis.index.totalTalkTimehours,
            value: '',
            id: 3,
            key: 'callDurationTotal',
            type: 'hh',
        },
    ]);
    const [callAction, setCallAction] = useState([
        {
            label: locales.pages.gong.teamAnalysis.index.speakingPercentagepercentage,
            value: '',
            id: 0,
            key: 'agentSpeechPercentage',
            type: 'percentage',
        },
        {
            label: locales.pages.gong.teamAnalysis.index.maximumContinuousSpeakingTimeminutes,
            value: '',
            id: 1,
            key: 'agentLongestSpeechDuration',
            type: 'mm',
        },
        {
            label: locales.pages.gong.teamAnalysis.index.numberOfIndepthDiscussionsPCs,
            value: '',
            id: 2,
            key: 'customerQuestionFrequency',
            type: 'num',
        },
        {
            label: locales.pages.gong.teamAnalysis.index.maximumCustomerTalkminutes,
            value: '',
            id: 3,
            key: 'customerLongestSpeechDuration',
            type: 'mm',
        },
        {
            label: locales.pages.gong.teamAnalysis.index.questionFrequencyPCs,
            value: '',
            id: 4,
            key: 'agentQuestionFrequency',
            type: 'num',
        },
    ]);
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState('active');
    const [treeData, setTreeData] = useState<any[]>([]);
    const [userId, setUserId] = useState<any>();
    const [userList, setUserList] = useState<any[]>([]);
    const [isUser, setIsUser] = useState(false);
    const [selectDate, setSelectDate] = useState<any>(null);
    const [callType, setCallType] = useState('');
    const [statisticalData, setStatisticalData] = useState<any[]>([]);
    const [statisticUserData, setStatisticUserData] = useState<any[]>([]);
    const [averageData, setAverageData] = useState<any>();
    const [idx, setIdx] = useState(0);
    const [callData, setCallData] = useState<any[]>([]);
    const [callActiveOptions, setCallActiveOptions] = useState(callOptions);
    const [callActionOptions, setCallActionOptions] = useState(callOptions);
    const [keyEventOptions, setKeyEventOptions] = useState(callOptions);
    const [callDiscussOptions, setCallDiscussOptions] = useState(callOptions);
    const [callSpeakOptions, setCallSpeakOptions] = useState(callOptions);
    const [teamEventData, setTeamEventData] = useState<any[]>([]);
    const [userEventData, setUserEventData] = useState<any[]>([]);
    const [teamBestData, setTeamBestData] = useState<any[]>([]);
    const [parentId, setParentId] = useState<any>();
    const [filterId, setFilterId] = useState(0);
    const [conversationOptions, setConversationOptions] = useState<any>({
        // tooltip: {
        //     trigger: 'axis',
        // },
        xAxis: {
            show: false,
            type: 'value',
            position: 'top',
            boundaryGap: [0, 0],
        },
        yAxis: {
            type: 'category',
            data: [],
            axisTick: [
                {
                    show: false,
                },
            ],
            axisLine: {
                show: false,
            },
            axisLabel: {
                textStyle: {
                    fontSize: 14,
                },
            },
            triggerEvent: true,
        },
        grid: {
            top: '3%',
            left: '3%',
            right: '4%',
            bottom: '1%',
            containLabel: true,
        },
        series: [
            {
                name: '',
                type: 'bar',
                barMaxWidth: 30,
                data: [],
                markLine: {
                    symbol: 'none',
                    data: [
                        {
                            type: 'average',
                            name: locales.pages.gong.teamAnalysis.index.averageValue,
                            label: {
                                normal: {
                                    color: '#989898',
                                    formatter: function (params) {
                                        return locales.pages.gong.teamAnalysis.index.averageValue + params.value;
                                    },
                                },
                            },
                            lineStyle: {
                                normal: {
                                    color: '#989898',
                                    width: 2,
                                    type: 'solid',
                                },
                            },
                        },
                    ],
                },
                label: {},
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#F2F2F2' },
                            { offset: 1, color: '#F2F2F2' },
                        ]),
                    },
                },
            },
        ],
    });
    // 排序
    const compare = (property) => {
        return function (a, b) {
            let value1 = a[property];
            let value2 = b[property];
            return value2 - value1;
        };
    };
    // 图表数据变换
    const teamDataChange = (idx, statisticalData, averageData, activeKey, isUser) => {
        if (isUser) {
            if (activeKey === 'active') {
                let labelName: any = [];
                let newCallActive: any = [];
                let newCallAction: any = [];
                let keyEvent: any = [];
                statisticalData.forEach((item) => {
                    newCallActive.push(Math.floor((item.indicator[callActive[0].key] / 1000 / 60) * 100) / 100);
                    newCallAction.push(Math.floor(item.indicator[callActive[1].key]));
                    keyEvent.push(Math.floor((item.indicator[callActive[2].key] / 1000 / 60) * 100) / 100);
                    labelName.push(item.date);
                });
                setCallActiveOptions({
                    ...callActiveOptions,
                    xAxis: {
                        ...callActiveOptions.xAxis,
                        data: labelName,
                    },
                    series: [
                        {
                            ...callActiveOptions.series[0],
                            data: newCallActive,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        yAxis: Math.floor((averageData[callActive[0].key] / 1000 / 60)*100)/100,
                                        lineStyle: {
                                            color: '#F59A23',
                                            type: 'solid',
                                            width: 2,
                                        },
                                        label: {
                                            formatter: function (params) {
                                                return '';
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            let str =
                                params[0].name +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                                timeFormatter(callActive[0].type, params[0].value * 60 * 1000) +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                                timeFormatter(callActive[0].type, averageData[callActive[0].key]);
                            return str;
                        },
                    },
                });
                setCallActionOptions({
                    ...callActionOptions,
                    xAxis: {
                        ...callActionOptions.xAxis,
                        data: labelName,
                    },
                    series: [
                        {
                            ...callActionOptions.series[0],
                            data: newCallAction,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        yAxis: Math.floor(averageData[callActive[1].key]),
                                        lineStyle: {
                                            color: '#F59A23',
                                            type: 'solid',
                                            width: 2,
                                        },
                                        label: {
                                            formatter: function (params) {
                                                return '';
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            let str =
                                params[0].name +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                                params[0].value +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                                Math.floor(averageData[callActive[1].key]);
                            return str;
                        },
                    },
                });
                
                setKeyEventOptions({
                    ...keyEventOptions,
                    xAxis: {
                        ...keyEventOptions.xAxis,
                        data: labelName,
                    },
                    series: [
                        {
                            ...keyEventOptions.series[0],
                            data: keyEvent,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        yAxis: Math.floor((averageData[callActive[2].key] / 1000 / 60)*100)/100,
                                        lineStyle: {
                                            color: '#F59A23',
                                            type: 'solid',
                                            width: 2,
                                        },
                                        label: {
                                            formatter: function (params) {
                                                return '';
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            let str =
                                params[0].name +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                                timeFormatter(callActive[2].type, params[0].value * 60 * 1000) +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                                timeFormatter(callActive[2].type, averageData[callActive[2].key]);
                            return str;
                        },
                    },
                });
            } else if (activeKey === 'action') {
                let labelName: any = [];
                let newCallActive: any = [];
                let newCallAction: any = [];
                let keyEvent: any = [];
                let callDiscuss: any = [];
                let callSpeak: any = [];
                statisticalData.forEach((item) => {
                    newCallActive.push(Math.floor(item.indicator?.[callAction[0].key] * 100));
                    newCallAction.push(Math.floor((item.indicator?.[callAction[1].key] / 1000 / 60) * 100) / 100);
                    keyEvent.push(Math.floor(item.indicator?.[callAction[2].key]));
                    callDiscuss.push(Math.floor((item.indicator?.[callAction[3].key] / 1000 / 60) * 100) / 100);
                    callSpeak.push(Math.floor(item.indicator?.[callAction[4].key]));
                    labelName.push(item.date);
                });
                setCallActiveOptions({
                    ...callActiveOptions,
                    xAxis: {
                        ...callActiveOptions.xAxis,
                        data: labelName,
                    },
                    series: [
                        {
                            ...callActiveOptions.series[0],
                            data: newCallActive,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        yAxis: Math.floor(averageData[callAction[0].key] * 100),
                                        lineStyle: {
                                            color: '#F59A23',
                                            type: 'solid',
                                            width: 2,
                                        },
                                        label: {
                                            formatter: function (params) {
                                                return '';
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            let str =
                                params[0].name +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                                timeFormatter(callAction[0].type, params[0].value / 100) +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                                timeFormatter(callAction[0].type, averageData[callAction[0].key]);
                            return str;
                        },
                    },
                });
                setCallActionOptions({
                    ...callActionOptions,
                    xAxis: {
                        ...callActionOptions.xAxis,
                        data: labelName,
                    },
                    series: [
                        {
                            ...callActionOptions.series[0],
                            data: newCallAction,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        yAxis: Math.floor((averageData[callAction[1].key] / 1000 / 60) * 100) / 100,
                                        lineStyle: {
                                            color: '#F59A23',
                                            type: 'solid',
                                            width: 2,
                                        },
                                        label: {
                                            formatter: function (params) {
                                                return '';
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            let str =
                                params[0].name +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                                timeFormatter(callAction[1].type, params[0].value * 60 * 1000) +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                                timeFormatter(callAction[1].type, averageData[callAction[1].key]);
                            return str;
                        },
                    },
                });
                setKeyEventOptions({
                    ...keyEventOptions,
                    xAxis: {
                        ...keyEventOptions.xAxis,
                        data: labelName,
                    },
                    series: [
                        {
                            ...keyEventOptions.series[0],
                            data: keyEvent,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        yAxis: Math.floor(averageData[callAction[2].key]),
                                        lineStyle: {
                                            color: '#F59A23',
                                            type: 'solid',
                                            width: 2,
                                        },
                                        label: {
                                            formatter: function (params) {
                                                return '';
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            let str =
                                params[0].name +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                                params[0].value +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                                Math.floor(averageData[callAction[2].key]);
                            return str;
                        },
                    },
                });
                setCallDiscussOptions({
                    ...callDiscussOptions,
                    xAxis: {
                        ...callDiscussOptions.xAxis,
                        data: labelName,
                    },
                    series: [
                        {
                            ...callDiscussOptions.series[0],
                            data: callDiscuss,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        yAxis: Math.floor((averageData[callAction[3].key] / 1000 / 60) * 100) / 100,
                                        lineStyle: {
                                            color: '#F59A23',
                                            type: 'solid',
                                            width: 2,
                                        },
                                        label: {
                                            formatter: function (params) {
                                                return '';
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            let str =
                                params[0].name +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                                timeFormatter(callAction[3].type, params[0].value * 60 * 1000) +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                                timeFormatter(callAction[3].type, averageData[callAction[3].key]);
                            return str;
                        },
                    },
                });
                setCallSpeakOptions({
                    ...callSpeakOptions,
                    xAxis: {
                        ...callSpeakOptions.xAxis,
                        data: labelName,
                    },
                    series: [
                        {
                            ...callSpeakOptions.series[0],
                            data: callSpeak,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        yAxis: Math.floor(averageData[callAction[4].key]),
                                        lineStyle: {
                                            color: '#F59A23',
                                            type: 'solid',
                                            width: 2,
                                        },
                                        label: {
                                            formatter: function (params) {
                                                return '';
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            let str =
                                params[0].name +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#1A6EFF;"></span>' +
                                params[0].value +
                                '<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#F59A23;"></span>' +
                                Math.floor(averageData[callAction[4].key]);
                            return str;
                        },
                    },
                });
            }
        } else {
            let yAxisData: any = [];
            let seriesData: any = [];
            let middleData: any = [];
            let averageDataArr: any = [];
            statisticalData.forEach((item) => {
                if (activeKey === 'active') {
                    middleData.push({
                        value: item[callActive[idx].key],
                        userId: item.userId,
                        name: item.name,
                    });
                    averageDataArr.push({
                        value: item[callActive[idx].key],
                        userId: item.userId,
                        name: item.name,
                    });
                } else if (activeKey === 'action') {
                    middleData.push({
                        value: item[callAction[idx].key],
                        userId: item.userId,
                        name: item.name,
                    });
                    averageDataArr.push({
                        value: item[callAction[idx].key],
                        userId: item.userId,
                        name: item.name,
                    });
                }
            });
            if (activeKey === 'active') {
                middleData.push({
                    value: averageData?.[callActive[idx].key] || computedAverageData(averageDataArr),
                    name: locales.pages.gong.teamAnalysis.index.bestPractices,
                    userId: null,
                });
            } else if (activeKey === 'action') {
                middleData.push({
                    value: averageData?.[callAction[idx].key],
                    name: locales.pages.gong.teamAnalysis.index.bestPractices,
                    userId: null,
                });
            }
            let newMiddleData = middleData.sort(compare('value'));
            newMiddleData.forEach((item) => {
                yAxisData.push({
                    value: item.name + (item.userId ? `(${item.userId})` : ''),
                    userId: item.userId,
                });
                seriesData.push(item.value);
            });
            setConversationOptions({
                ...conversationOptions,
                yAxis: {
                    ...conversationOptions.yAxis,
                    data: yAxisData,
                },
                series: [
                    {
                        ...conversationOptions.series[0],
                        data: seriesData,
                        markLine: {
                            symbol: 'none',
                            data: [
                                {
                                    type: 'average',
                                    name: locales.pages.gong.teamAnalysis.index.averageValue,
                                    label: {
                                        normal: {
                                            color: '#989898',
                                            formatter: function (params) {
                                                if (activeKey === 'active') {
                                                    return locales.pages.gong.teamAnalysis.index.averageValue + timeFormatter(callActive[idx].type, computedAverageData(averageDataArr));
                                                } else if (activeKey === 'action') {
                                                    return locales.pages.gong.teamAnalysis.index.averageValue + timeFormatter(callAction[idx].type, computedAverageData(averageDataArr));
                                                } else {
                                                    return '';
                                                }
                                            },
                                        },
                                    },
                                    lineStyle: {
                                        normal: {
                                            color: '#989898',
                                            width: 2,
                                            type: 'solid',
                                        },
                                    },
                                },
                            ],
                        },
                        label: {
                            show: true,
                            position: 'right',
                            color: '#989898',
                            fontSize: 14,
                            formatter: (params) => {
                                if (activeKey === 'active') {
                                    return params.value ? timeFormatter(callActive[idx].type, params.value) : '';
                                } else if (activeKey === 'action') {
                                    return params.value ? timeFormatter(callAction[idx].type, params.value) : '';
                                } else {
                                    return '';
                                }
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: (params) => {
                                    if (params.name === locales.pages.gong.teamAnalysis.index.bestPractices) {
                                        return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: '#F59A23' },
                                            { offset: 1, color: '#F59A23' },
                                        ]);
                                    } else {
                                        return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: '#F2F2F2' },
                                            { offset: 1, color: '#F2F2F2' },
                                        ]);
                                    }
                                },
                            },
                        },
                    },
                ],
            });
        }
    };
    // 平均值计算
    const computedAverageData = (data) => {
        if (!data.length) return 0;
        let dataNum: any = [];
        data.forEach(item => {
            dataNum.push(item.value);
        });
        return Math.floor((dataNum.reduce((a, b) => a + b) / dataNum.length) * 100) / 100;
    };
    // tab切换
    const handlerToggle = (key) => {
        setActiveKey(key);
        setFilterId(0);
        if (key === 'event') {
            if (isUser) {
                getKeyEventUserListData(key);
            } else {
                getKeyEventTeamListData(key);
            }
        } else {
            if (isUser) {
                if (!getQueryVariable('id')) {
                    teamDataChange(idx, statisticUserData, averageData, key, isUser);
                    userDataList(
                        userId,
                        callType,
                        selectDate?.[0].format('YYYY-MM-DD'),
                        selectDate?.[1].format('YYYY-MM-DD'),
                        isUser,
                        key,
                    );
                } else {
                    userDataList(
                        userId,
                        callType,
                        selectDate?.[0].format('YYYY-MM-DD'),
                        selectDate?.[1].format('YYYY-MM-DD'),
                        isUser,
                        key,
                        getQueryVariable('parentId')
                    );
                }
            } else {
                teamDataList(
                    userId,
                    callType,
                    selectDate[0].format('YYYY-MM-DD'),
                    selectDate[1].format('YYYY-MM-DD'),
                    null,
                    isUser,
                    key
                );
            }
        }
    };
    // event数据图表处理
    const getEventDataChange = (idx, teamData, averageData, isUser, id?, callType?, startTime?, endTime?) => {
        if (!isUser) {
            const params = {
                callType,
                from: startTime ? startTime : selectDate[0].format('YYYY-MM-DD'),
                to: endTime ? endTime : selectDate[1].format('YYYY-MM-DD'),
            };
            getReviewCallAnalysisOrganizationById({ params, segments: { id: id || userId } }).then((res) => {
                let eventUserList: any = [];
                let averageDataArr: any = [];
                userList.forEach((item) => {
                    if (res.data?.users?.[item.id]) {
                        eventUserList.push({
                            name: item.realname,
                            value: 0,
                            id: item.id,
                        });
                        averageDataArr.push({
                            name: item.realname,
                            value: 0,
                            id: item.id,
                        });
                    }
                });
                eventUserList.forEach((item) => {
                    if (teamData?.[idx]?.users?.[item?.id]) {
                        item.value = teamData[idx].users[item.id];
                    }
                });
                averageDataArr.forEach(item => {
                    if (teamData?.[idx]?.users?.[item?.id]) {
                        item.value = teamData[idx].users[item.id];
                    }
                });
                eventUserList.unshift({
                    name: locales.pages.gong.teamAnalysis.index.bestPractices,
                    value: averageData[idx]?.sum??0,
                    id: null,
                });
                let sortEventData = eventUserList.sort(compare('value'));
                let yAxisData: any = [];
                let seriesData: any = [];
                sortEventData.forEach((item) => {
                    yAxisData.push({
                        value: item.name + (item.id ? `(${item.id})` : ''),
                        userId: item.id,
                    });
                    seriesData.push(item.value);
                });
                setConversationOptions({
                    ...conversationOptions,
                    yAxis: {
                        ...conversationOptions.yAxis,
                        data: yAxisData,
                    },
                    series: [
                        {
                            ...conversationOptions.series[0],
                            data: seriesData,
                            markLine: {
                                symbol: 'none',
                                data: [
                                    {
                                        type: 'average',
                                        name: locales.pages.gong.teamAnalysis.index.averageValue,
                                        label: {
                                            normal: {
                                                color: '#989898',
                                                formatter: function (params) {
                                                    if (activeKey === 'active') {
                                                        return locales.pages.gong.teamAnalysis.index.averageValue + timeFormatter(null, computedAverageData(averageDataArr));
                                                    } else if (activeKey === 'action') {
                                                        return locales.pages.gong.teamAnalysis.index.averageValue + timeFormatter(null, computedAverageData(averageDataArr));
                                                    } else if (activeKey === 'event') {
                                                        return computedAverageData(averageDataArr);
                                                    } else {
                                                        return '';
                                                    }
                                                },
                                            },
                                        },
                                        lineStyle: {
                                            normal: {
                                                color: '#989898',
                                                width: 2,
                                                type: 'solid',
                                            },
                                        },
                                    },
                                ],
                            },
                            label: {
                                show: true,
                                position: 'right',
                                color: '#989898',
                                fontSize: 14,
                                formatter: (params) => {
                                    if (activeKey === 'active') {
                                        return params.value ? timeFormatter(null, params.value) : '';
                                    } else if (activeKey === 'action') {
                                        return params.value ? timeFormatter(null, params.value) : '';
                                    } else if (activeKey === 'event') {
                                        return params.value ? params.value : '';
                                    } else {
                                        return '';
                                    }
                                },
                            },
                            itemStyle: {
                                normal: {
                                    color: (params) => {
                                        if (params.name === locales.pages.gong.teamAnalysis.index.bestPractices) {
                                            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 0, color: '#F59A23' },
                                                { offset: 1, color: '#F59A23' },
                                            ]);
                                        } else {
                                            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 0, color: '#F2F2F2' },
                                                { offset: 1, color: '#F2F2F2' },
                                            ]);
                                        }
                                    },
                                },
                            },
                        },
                    ],
                });
            });
        }
    };
    // 获取关键事件最佳数据
    const getKeyEventBestData = (teamData, key, id?, callType?, startTime?, endTime?, index?) => {
        const params = {
            callType,
            from: startTime ? startTime : selectDate[0].format('YYYY-MM-DD'),
            to: endTime ? endTime : selectDate[1].format('YYYY-MM-DD'),
        };
        getReviewCallAnalysisOrganizationByIdKeyEventsBestPractise({ params, segments: { id: id || userId } }).then(
            (res) => {
                setTeamBestData(res.data ?? []);
                getEventDataChange(index === null || index=== undefined ? idx : index, teamData, res.data, false, id, callType, startTime, endTime);
            }
        );
    };
    // 获取团队关键事件
    const getKeyEventTeamListData = (key, id?, callType?, startTime?, endTime?, index?) => {
        const params = {
            callType,
            from: startTime ? startTime : selectDate[0].format('YYYY-MM-DD'),
            to: endTime ? endTime : selectDate[1].format('YYYY-MM-DD'),
        };
        getReviewCallAnalysisOrganizationByIdKeyEvents({ params, segments: { id: id || userId } }).then(
            (res: BaseResponseListDataCallAnalysisOrgTagResponse) => {
                let result: any = [];
                if (res.data) {
                    result = res.data.map((item, idx) => {
                        return {
                            ...item,
                            value: item.sum,
                            label: item.smartTag?.tagName,
                            id: idx,
                        };
                    });
                }
                setTeamEventData(result);
                getKeyEventBestData(result, key, id, callType, startTime, endTime, index);
            }
        );
    };
    // 获取团队个人关键事件
    const getKeyEventUserListData = (key, id?, callType?, startTime?, endTime?, parent?) => {
        const params = {
            callType,
            from: startTime ? startTime : selectDate[0].format('YYYY-MM-DD'),
            to: endTime ? endTime : selectDate[1].format('YYYY-MM-DD'),
        };
        getReviewCallAnalysisUserByIdKeyEvents({ params, segments: { id: id || userId } }).then(
            (res: BaseResponseDataCallAnalysisUserTagResultResponse) => {
                let result: any = [];
                if (res.data && res.data.tags) {
                    result = res.data.tags.map((item, idx) => {
                        return {
                            ...item,
                            value: item?.stats?.reduce((prev, cur) => {
                                return prev + cur?.sum!;
                            }, 0),
                            label: item.smartTag?.tagName,
                            id: idx,
                            domId: 'myCanvas' + idx,
                        };
                    });
                }
                setUserEventData(result);
                if (res.data) {
                    setCallData(res.data.dataCalls || []);
                }
                // getEventDataChange(idx, result, null, true, id, callType, startTime, endTime);
                getKeyEventBestData(result, key, parent || parentId, callType, startTime, endTime);
            }
        );
    };
    const handlerCardToggle = (id) => {
        setIdx(id);
        if (activeKey === 'event') {
            getEventDataChange(id, teamEventData, teamBestData, isUser, null, parentId);
        } else {
            teamDataChange(id, statisticalData, averageData, activeKey, isUser);
        }
    };
    // 时间转化
    const timeFormatter = (type, time) => {
        if (type === 'percentage') {
            return Math.floor(time * 100) + '%';
        } else if (type === 'mm') {
            let minutes = Math.floor(time / 1000 / 60);
            let seconds = Math.floor((time % (1000 * 60)) / 1000);
            return (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
        } else if (type === 'hh') {
            let hours = Math.floor(time / 1000 / 60 / 60);
            let minutes = Math.floor(time / 1000 / 60 % 60);
            let seconds = Math.floor((time % (1000 * 60)) / 1000);
            return (
                (hours < 10 ? '0' + hours : hours) +
                ':' +
                (minutes < 10 ? '0' + minutes : minutes) +
                ':' +
                (seconds < 10 ? '0' + seconds : seconds)
            );
        } else if (type === 'num') {
            return Math.floor(time);
        } else {
            return time;
        }
    };
    // 人员数据处理
    const transformArrayToTree = (arr: any = []) => {
        if (!arr || !arr.length) return [];
        const sortedArr = arr
            .map((i: any) => ({
                ...i,
                title: i.name,
                key: i.id,
                parentId: i.parentId * 1,
            }))
            .sort((a, b) => a.parentId - b.parentId);
        let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
        let nodeMap = {};
        sortedArr.forEach((item) => {
            nodeMap[item.id] = item;
            if (nodeMap[item.parentId]) {
                if (Array.isArray(nodeMap[item.parentId].children)) {
                    nodeMap[item.parentId].children.push(item);
                } else {
                    nodeMap[item.parentId].children = [item];
                }
            }
        });
        return root;
    };
    // 人员数据
    const getUserAllList = () => {
        getReviewCallAnalysisOrganizations().then((res: BaseResponseDataCallAnalysisOrgOptionResponse) => {
            let userData: any = res.data?.users?.map((item) => {
                return {
                    ...item,
                    name: item.realname,
                    parentId: item.organizationId,
                };
            });
            setUserList(userData);
            let treeData = transformArrayToTree([...userData, ...(res.data?.organizations ?? [])]);
            setTreeData(treeData);
            if (!getQueryVariable('id')) {
                setUserId(res.data?.currentOrganizationId);
                form.setFieldsValue({
                    defalultDate: defaultTimeRange,
                    callType: '',
                    userId: res.data?.currentOrganizationId,
                });
                setSelectDate(defaultTimeRange);
            } else {
                let startDate = moment(Number(getQueryVariable('startDate')));
                let endDate = moment(Number(getQueryVariable('endDate')));
                let callType = getQueryVariable('callType')??'';
                setCallType(callType);
                setSelectDate([startDate, endDate]);
            }

            // teamDataList(
            //     res.data?.currentOrganizationId,
            //     '',
            //     defaultTimeRange?.[0].format('YYYY-MM-DD'),
            //     defaultTimeRange?.[1].format('YYYY-MM-DD'),
            //     userData,
            //     isUser
            // );

            if (getQueryVariable('key') === 'event') {
                let startDate = moment(Number(getQueryVariable('startDate')));
                let endDate = moment(Number(getQueryVariable('endDate')));
                getKeyEventUserListData(getQueryVariable('key'), getQueryVariable('id'), '', startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), getQueryVariable('parentId'));
            } else {
                if (!getQueryVariable('id')) {
                    teamDataList(
                        res.data?.currentOrganizationId,
                        '',
                        defaultTimeRange?.[0].format('YYYY-MM-DD'),
                        defaultTimeRange?.[1].format('YYYY-MM-DD'),
                        userData,
                        isUser
                    );
                } else {
                    let startDate = moment(Number(getQueryVariable('startDate')));
                    let endDate = moment(Number(getQueryVariable('endDate')));
                    let callType = getQueryVariable('callType')??'';
                    userDataList(
                        parseInt(getQueryVariable('id'), 10),
                        callType,
                        startDate.format('YYYY-MM-DD'),
                        endDate.format('YYYY-MM-DD'),
                        true,
                        null,
                        res.data?.currentOrganizationId,
                        userData
                    );
                }
            }
        });
    };
    // 日期
    const handlerDateChange = (time) => {
        setSelectDate(time);
        if (activeKey === 'event') {
            if (isUser) {
                getKeyEventUserListData(
                    activeKey,
                    userId,
                    callType,
                    time[0].format('YYYY-MM-DD'),
                    time[1].format('YYYY-MM-DD')
                );
            } else {
                getKeyEventTeamListData(
                    activeKey,
                    userId,
                    callType,
                    time[0].format('YYYY-MM-DD'),
                    time[1].format('YYYY-MM-DD'),
                    0,
                );
            }
        } else {
            if (isUser) {
                userDataList(
                    userId,
                    callType,
                    time[0].format('YYYY-MM-DD'),
                    time[1].format('YYYY-MM-DD'),
                    isUser,
                    null,
                    parentId,
                );
            } else {
                teamDataList(
                    userId,
                    callType,
                    time[0].format('YYYY-MM-DD'),
                    time[1].format('YYYY-MM-DD'),
                    null,
                    isUser,
                    null,
                    null,
                    0,
                );
            }
        }
    };
    // 人员
    const handlerUserChange = (key, node) => {
        setUserId(key);
        if (node.organizationId) {
            setIsUser(true);
            setParentId(node.parentId);
        } else {
            setIsUser(false);
        }
        if (node.organizationId) {
            if (activeKey === 'event') {
                getKeyEventUserListData(activeKey, key, null, null, null, node.parentId);
            } else {
                userDataList(
                    key,
                    callType,
                    selectDate?.[0].format('YYYY-MM-DD'),
                    selectDate?.[1].format('YYYY-MM-DD'),
                    true,
                    null,
                    node.parentId
                );
            }
        } else {
            if (activeKey === 'event') {
                getKeyEventTeamListData(
                    activeKey,
                    key,
                    callType,
                    selectDate?.[0].format('YYYY-MM-DD'),
                    selectDate?.[1].format('YYYY-MM-DD'),
                    0,
                );
            } else {
                teamDataList(
                    key,
                    callType,
                    selectDate?.[0].format('YYYY-MM-DD'),
                    selectDate?.[1].format('YYYY-MM-DD'),
                    null,
                    false,
                    null, 
                    null,
                    0,
                );
            }
        }
    };
    // 通话范围
    const handlerCallTypeChange = (key) => {
        setCallType(key);
        if (activeKey === 'event') {
            if (isUser) {
                getKeyEventUserListData(activeKey, userId, key);
            } else {
                getKeyEventTeamListData(
                    activeKey,
                    userId,
                    key,
                    selectDate?.[0].format('YYYY-MM-DD'),
                    selectDate?.[1].format('YYYY-MM-DD'),
                    0,
                );
            }
        } else {
            if (isUser) {
                userDataList(
                    userId,
                    key,
                    selectDate?.[0].format('YYYY-MM-DD'),
                    selectDate?.[1].format('YYYY-MM-DD'),
                    isUser,
                    null,
                    parentId,
                );
            } else {
                teamDataList(
                    userId,
                    key,
                    selectDate?.[0].format('YYYY-MM-DD'),
                    selectDate?.[1].format('YYYY-MM-DD'),
                    null,
                    isUser,
                    null,
                    null,
                    0,
                );
            }
        }
    };
    // 平均值
    const getAverageData = (id, callType, from, to, chartData?, isUser?, key?, index?) => {
        const params = {
            callType,
            from,
            to,
        };
        getReviewCallAnalysisOrganizationByIdBestPractise({ params, segments: { id } }).then((res) => {
            setAverageData(res.data?.indicator);
            // 初始化数据并存储
            teamDataChange(index === null || index === undefined ? idx : index, chartData, res.data?.indicator, key || activeKey, isUser);
        });
    };
    // 团队分析的数据
    const teamDataList = (id, callType, from, to, userData?, isUser?, key?, firstId?, idx?) => {
        const params = {
            callType,
            from,
            to,
        };
        getReviewCallAnalysisOrganizationById({ params, segments: { id: firstId || id } }).then(
            (res: BaseResponseDataCallAnalysisOrgResultResponse) => {
                let chartData: any = [];

                if (!firstId) {
                    let userListData = userData ? userData : userList;
                    userListData.forEach((item) => {
                        if (res.data?.users?.[item.id]) {
                            chartData.push({
                                ...res.data?.users?.[item.id],
                                userId: item.id,
                                name: item.realname,
                            });
                        }
                    });
                    setStatisticalData(chartData);
                }
                if (!isUser) {
                    let newCallActive = callActive.map((item) => {
                        return {
                            ...item,
                            value: timeFormatter(item.type, res.data?.indicator?.[item.key]),
                        };
                    });
                    let newCallAction = callAction.map((item) => {
                        return {
                            ...item,
                            value: timeFormatter(item.type, res.data?.indicator?.[item.key]),
                        };
                    });
                    setCallActive(newCallActive);
                    setCallAction(newCallAction);
                }
                if (firstId) {
                    let id = getQueryVariable('id');
                    let departmentId = getQueryVariable('parentId');
                    if (id) {
                        getAverageData(departmentId, callType, from, to, userData, isUser, key, idx);
                    } else {
                        getAverageData(firstId || id, callType, from, to, userData, isUser, key, idx);
                    }
                } else {
                    getAverageData(firstId || id, callType, from, to, chartData, isUser, key, idx);
                }
            }
        );
    };
    // 个人数据分析
    const userDataList = (id, callType, from, to, isUser, key?, firstId?, userData?) => {
        const params = {
            callType,
            from,
            to,
        };
        getReviewCallAnalysisUserById({ params, segments: { id } }).then((res: BaseResponseDataCallAnalysisUserResultResponse) => {
            setCallData(res.data?.dataCalls ?? []);
            setStatisticUserData(res.data?.daily ?? []);
            let newCallActive = callActive.map((item) => {
                return {
                    ...item,
                    value: timeFormatter(item.type, res.data?.indicator?.[item.key]),
                };
            });
            let newCallAction = callAction.map((item) => {
                return {
                    ...item,
                    value: timeFormatter(item.type, res.data?.indicator?.[item.key]),
                };
            });
            setCallActive(newCallActive);
            setCallAction(newCallAction);
            // teamDataChange(idx, res.data?.daily ?? [], averageData, key || activeKey, isUser);
            if (firstId) {
                let newKey = getQueryVariable('key');
                teamDataList(id, callType, from, to, res.data?.daily??[], true, key || newKey, firstId);
            } else {
                teamDataChange(idx, res.data?.daily??[], averageData, key || activeKey, true);
            }
        });
    };
    // 个人数据筛选
    const handlerFilterData = (idx) => {
        setFilterId(idx);
    };
    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return '';
    };
    useEffect(() => {
        let id = getQueryVariable('id');
        let key = getQueryVariable('key');
        let callType = getQueryVariable('callType')??'';
        let startDate = moment(Number(getQueryVariable('startDate')));
        let endDate = moment(Number(getQueryVariable('endDate')));
        let parentId = getQueryVariable('parentId');
        if (id) {
            form.setFieldsValue({
                defalultDate: [startDate, endDate],
                callType,
                userId: parseInt(id, 10),
            });
            setUserId(parseInt(id, 10));
            setActiveKey(key);
            setIsUser(true);
            setParentId(parseInt(parentId, 10));
        }
        getUserAllList();
    }, []);
    useEffect(() => {
        let timer: any = null;
        const pageBodyOnresize = document.querySelector('.udesk-qa-web-home-page-sider');
        let callCanvas,
            sellCanvas,
            questionCanvas,
            mainCanvas,
            myCallChart,
            mySellChart,
            myQuestionChart,
            myChart,
            speakCanvas,
            questionNumCanvas,
            mySpeakCanvas,
            myQuestionNumCanvas;

        if (isUser) {
            if (activeKey === 'active' || activeKey === 'action') {
                callCanvas = document.getElementById('callCanvas');
                sellCanvas = document.getElementById('sellCanvas');
                questionCanvas = document.getElementById('questionCanvas');
                myCallChart = echarts.init(callCanvas);
                mySellChart = echarts.init(sellCanvas);
                myQuestionChart = echarts.init(questionCanvas);
                myCallChart.setOption(callActiveOptions);
                mySellChart.setOption(callActionOptions);
                myQuestionChart.setOption(keyEventOptions);
            }
            if (activeKey === 'action') {
                speakCanvas = document.getElementById('speakCanvas');
                questionNumCanvas = document.getElementById('questionNumCanvas');
                mySpeakCanvas = echarts.init(speakCanvas);
                myQuestionNumCanvas = echarts.init(questionNumCanvas);
                mySpeakCanvas.setOption(callDiscussOptions);
                myQuestionNumCanvas.setOption(callSpeakOptions);
            }
        } else {
            mainCanvas = document.getElementById('mainCanvas');
            myChart = echarts.init(mainCanvas);
            myChart.setOption(conversationOptions);
            myChart.off('click');
            myChart.on('click', 'yAxis.category', (params) => {
                let reg = /(\()\S+(?=\))/g;
                let userData = params.value.match(reg);
                if (userData) {
                    let userId = userData[0].replace('(', '');
                    let paramsParentId = userList.filter(item => item.id === Number(userId));
                    window.open('/sales/analysis-enable/team-analysis?id=' + userId + '&key=' + activeKey + '&callType=' + callType + '&startDate=' + selectDate[0] + '&endDate=' + selectDate[1] + '&parentId=' + paramsParentId[0].parentId);
                }
            });
        }

        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                timer = setTimeout(() => {
                    if (isUser) {
                        if (activeKey === 'active' || activeKey === 'action') {
                            myCallChart.resize();
                            mySellChart.resize();
                            myQuestionChart.resize();
                        }
                        if (activeKey === 'action') {
                            mySpeakCanvas.resize();
                            myQuestionNumCanvas.resize();
                        }
                    } else {
                        myChart.resize();
                    }
                }, 200);
            });
        });
        if (pageBodyOnresize) {
            resizeObserver.observe(pageBodyOnresize);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [
        conversationOptions,
        callActiveOptions,
        callActionOptions,
        keyEventOptions,
        callDiscussOptions,
        callSpeakOptions,
    ]);
    return (
        <Page
            pageBodyClassName='pages-team-analysis-container'
            title={/* 最佳实践 */UdeskLocales['current'].pages.gong.teamAnalysis.index.bestPractices}
            padding={true}
        >
            <Row style={{ width: '100%' }}>
                <Form form={form} layout='inline' style={{ width: '100%', marginBottom: 16 }}>
                    <Col span={7}>
                        <Form.Item label={locales.pages.gong.teamAnalysis.index.selectDate} name='defalultDate'>
                            <RangePicker value={selectDate} style={{ width: '100%' }} onChange={handlerDateChange} />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label={locales.pages.gong.teamAnalysis.index.personnel} name='userId'>
                            <TreeSelect
                                onSelect={handlerUserChange}
                                treeData={treeData}
                                value={userId}
                                showSearch
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder={locales.pages.gong.teamAnalysis.index.pleaseSelect}
                                allowClear
                                treeNodeFilterProp='title'
                                treeDefaultExpandAll
                            ></TreeSelect>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label={locales.pages.gong.teamAnalysis.index.callRange} name='callType'>
                            <Select value={callType} onChange={handlerCallTypeChange} placeholder={locales.pages.gong.teamAnalysis.index.pleaseSelect}>
                                {Udesk.enums.callRangeStatus.map((item) => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Form>
            </Row>

            <Tabs activeKey={activeKey} onChange={handlerToggle}>
                <TabPane tab={locales.pages.gong.teamAnalysis.index.conversationActivity} key='active'>
                    {!isUser && (
                        <TabsModle tabData={callActive} activeKey={activeKey} handlerCardToggle={handlerCardToggle} />
                    )}
                    {isUser && (
                        <TabsModle
                            isClick={true}
                            tabData={callActive}
                            activeKey={activeKey}
                            handlerCardToggle={handlerCardToggle}
                        />
                    )}
                </TabPane>
                <TabPane tab={locales.pages.gong.teamAnalysis.index.conversationAction} key='action'>
                    {!isUser && (
                        <TabsModle tabData={callAction} activeKey={activeKey} handlerCardToggle={handlerCardToggle} />
                    )}
                    {isUser && (
                        <TabsModle
                            isClick={true}
                            tabData={callAction}
                            activeKey={activeKey}
                            handlerCardToggle={handlerCardToggle}
                        />
                    )}
                </TabPane>
                <TabPane tab={locales.pages.gong.teamAnalysis.index.keyEvents} key='event'>
                    {!isUser && !!teamEventData.length && (
                        <TabsModle
                            tabData={teamEventData}
                            activeKey={activeKey}
                            handlerCardToggle={handlerCardToggle}
                        />
                    )}
                    {isUser && (
                        <TabsModle
                            isClick={true}
                            tabData={userEventData}
                            activeKey={activeKey}
                            handlerCardToggle={handlerCardToggle}
                        />
                    )}
                </TabPane>
            </Tabs>
            {isUser && (
                <div className='self-data-show-container'>
                    <div className='self-data-show-chart'>
                        {activeKey === 'active' || activeKey === 'action' ? (
                            <React.Fragment>
                                <h3>{activeKey === 'active' ? callActive[0].label : callAction[0].label}</h3>
                                <div id='callCanvas' style={{ width: '100%', height: '400px' }}></div>
                                <h3>{activeKey === 'active' ? callActive[1].label : callAction[1].label}</h3>
                                <div id='sellCanvas' style={{ width: '100%', height: '400px' }}></div>
                                <h3>{activeKey === 'active' ? callActive[2].label : callAction[2].label}</h3>
                                <div id='questionCanvas' style={{ width: '100%', height: '400px' }}></div>
                            </React.Fragment>
                        ) : null}
                        {activeKey === 'action' ? (
                            <React.Fragment>
                                <h3>{callAction[3].label}</h3>
                                <div id='speakCanvas' style={{ width: '100%', height: '400px' }}></div>
                                <h3>{callAction[4].label}</h3>
                                <div id='questionNumCanvas' style={{ width: '100%', height: '400px' }}></div>
                            </React.Fragment>
                        ) : null}
                        {activeKey === 'event' && !!userEventData.length && (
                            <TeamCommon teamBestData={teamBestData} tabData={userEventData}></TeamCommon>
                        )}
                    </div>
                    <div className='self-data-show-list'>
                        <h3 style={{ marginBottom: 50 }}>{locales.pages.gong.teamAnalysis.index.callRecord}</h3>
                        {activeKey !== 'active' && (
                            <div>
                                <span>{locales.pages.gong.teamAnalysis.index.filter}</span>
                                <Select
                                    onChange={handlerFilterData}
                                    style={{ width: '80%', margin: '16px 0' }}
                                    placeholder=''
                                    value={filterId}
                                >
                                    {activeKey === 'active' &&
                                        callActive.map((item) => (
                                            <Option key={item.id} value={item.id}>
                                                {item.label}
                                            </Option>
                                        ))}
                                    {activeKey === 'action' &&
                                        callAction.map((item) => (
                                            <Option key={item.id} value={item.id}>
                                                {item.label}
                                            </Option>
                                        ))}
                                    {activeKey === 'event' &&
                                        userEventData.map((item) => (
                                            <Option key={item.id} value={item.id}>
                                                {item.label}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        )}
                        <List
                            header={null}
                            footer={null}
                            dataSource={callData}
                            renderItem={(item) => (
                                <List.Item key={item.id} className='common-item'>
                                    {activeKey === 'action' && (
                                        <h3>
                                            {timeFormatter(callAction[filterId].type, item[callAction[filterId].key])}
                                        </h3>
                                    )}
                                    {activeKey === 'event' && (
                                        <h3>{item?.tags?.[userEventData?.[filterId]?.smartTag?.id] ?? 0}</h3>
                                    )}
                                    <div>
                                        <p>{locales.pages.gong.teamAnalysis.index.conversation}{item.id}</p>
                                        <span>{item.callTime}</span>
                                    </div>
                                    <span>{timeFormatter('mm', item.callDuration)}{locales.pages.gong.teamAnalysis.index.minute}</span>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            )}
            {!isUser && (
                <div>
                    <div id='mainCanvas' style={{ width: '100%', height: '466px' }}></div>
                </div>
            )}
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

function TabsModle(props) {
    let { tabData, activeKey, isClick, handlerCardToggle } = props;
    const [tabId, setTabId] = useState(0);
    const handlerToggle = (id) => {
        if (!isClick) {
            setTabId(id);
            handlerCardToggle(id);
        }
    };
    useEffect(() => {
        setTabId(0);
    }, [activeKey]);
    return (
        <Row>
            {tabData.map((item) => {
                return (
                    <Col span={4} key={item.id}>
                        <Card
                            className={`conversation-common ${item.id === tabId && !isClick ? 'active' : ''}`}
                            onClick={handlerToggle.bind(null, item.id)}
                        >
                            <p>{item.label}</p>
                            <h3>{item.value}</h3>
                        </Card>
                    </Col>
                );
            })}
            <Col span={24}></Col>
            {!isClick ? (
                <React.Fragment>
                    <Col span={12}>
                        <Space className='conversation-title'>
                            <h3>
                                {tabData[tabId].label}
                                {tabData[tabId].value}
                            </h3>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Space className='conversation-title'>
                            <span>{locales.pages.gong.teamAnalysis.index.sale}</span>
                            <p></p>
                        </Space>
                    </Col>
                </React.Fragment>
            ) : null}
        </Row>
    );
}
