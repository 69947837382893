import React, { FC, memo } from 'react';
import { useDimensionality } from '../hooks';
import styled from 'styled-components';

const Wrapper = styled.span`
    font-size: 20px;
    font-weight: bold;
`;

type TemplateProps = {
    type?: number;
};

const Template: FC<TemplateProps> = (props) => {
    const { type } = props;

    const { dimension } = useDimensionality({ type });

    return <Wrapper>{dimension}</Wrapper>;
};

export default memo(Template);
