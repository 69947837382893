// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PutWfConfigsByIdParam,
    PutWfConfigsByIdBody,
    BaseResponseVoid,
    DeleteWfConfigsByIdParam,
} from '../../types';

/**
 * update
 *
 * @export
 * @tags wf-config-controller
 * @link [PUT] /wfConfigs/{id}
 * @param id
 */
export function putWfConfigsById(
    data: PutWfConfigsByIdBody,
    options: ApiOptions<PutWfConfigsByIdParam, never> & { segments: PutWfConfigsByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutWfConfigsByIdBody, PutWfConfigsByIdParam, never>('/wfConfigs/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putWfConfigsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags wf-config-controller
 * @link [DELETE] /wfConfigs/{id}
 * @param id
 */
export function deleteWfConfigsById(
    options: ApiOptions<DeleteWfConfigsByIdParam, never> & { segments: DeleteWfConfigsByIdParam }
): Promise<BaseResponseVoid> {
    return request<never, DeleteWfConfigsByIdParam, never>('/wfConfigs/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteWfConfigsById',
    });
}

export const meta = [
    { tags: ['wf-config-controller'], path: '/wfConfigs/{id}', method: 'put' },
    { tags: ['wf-config-controller'], path: '/wfConfigs/{id}', method: 'delete' },
];
