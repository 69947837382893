import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import AdminUserGroupsIndexComponent from './component';

export default class AdminUserGroupsIndexRoute extends AdminUserGroupsIndexComponent {
    model(changedParams) {
        let params = Object.assign(
            {},
            this.props.match.params,
            Object.fromEntries(new URLSearchParams(this.props.location.search))
        );
        if (changedParams) {
            params = Object.assign(params, changedParams);
        }
        let modelPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(Udesk.business.apiPath.concatApiPath(`/userGroup`, this.props.sdkOptions), params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || UdeskLocales.current.business.userGroup.loadUserGroupListError
                    );
                    reject(reason);
                }
            );
        });
        return {
            userGroups: modelPromise
        };
    }
}
