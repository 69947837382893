import Locales from '../locales/index';

let saveTypes = [
    { id: 1, key: 'save', name: () => Locales.current.enums.saveTypes.save }
];

let updateTypes = [
    { id: 1, key: 'update', name: () => Locales.current.enums.updateTypes.update },
    { id: 2, key: 'saveAs', name: () => Locales.current.enums.updateTypes.saveAs }
];

export default {
    saveTypes,
    updateTypes
};