// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PutReviewIntelligentPartnerNodePptConfigBatchBody,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * ppt配置批量更新
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [PUT] /review/intelligentPartner/node/pptConfig/batch
 */
export function putReviewIntelligentPartnerNodePptConfigBatch(
    data: PutReviewIntelligentPartnerNodePptConfigBatchBody
): Promise<BaseResponseVoid>;
export function putReviewIntelligentPartnerNodePptConfigBatch(
    data: PutReviewIntelligentPartnerNodePptConfigBatchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putReviewIntelligentPartnerNodePptConfigBatch(
    data: PutReviewIntelligentPartnerNodePptConfigBatchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutReviewIntelligentPartnerNodePptConfigBatchBody, never, never>(
        '/review/intelligentPartner/node/pptConfig/batch',
        {
            ...options,
            data,
            method: 'put',
            __$requestCalleeName: 'putReviewIntelligentPartnerNodePptConfigBatch',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/batch',
        method: 'put',
    },
];
