import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Button, Space } from 'udesk-ui';
import { FormInstance as CreateFormInstance } from './types/form';
import './style.scss';
import defaultConfigMap from '../../../common/config/form-config';
import type { CreateFromProps } from './types/form';
import useFormContext from './useFormContext';
import Field from './field';
import Locales from 'UdeskLocales';

const { Item } = Form;

const defaultLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const defaultTailLayout = { offset: 8, span: 16 };

const CreateFrom: React.FC<CreateFromProps> = (props, ref) => {
    const locales = Locales['current'];

    const {
        form: userForm,
        // formRef: propsFormRef,
        children,
        fields,
        configMap = {},
        footerRender,
        col = defaultLayout,
        btnsCol = defaultTailLayout,
        onValuesChange: propsOnValuesChange,
        ...rest
    } = props;

    // const [form] = useState(()=>{
    //     return useFormContext(userForm);
    // });
    
    const [form] = useFormContext(userForm);

    // const formRef = useRef<FormInstance>(userForm || form);
    // const [visibleMap, setVisibleMap] = useState({});
    const { getAFormInstance } = form.getInternalHooks();

    useImperativeHandle(ref, () => form);

    const mixedConfigMap = { ...defaultConfigMap, ...configMap };

    const footerBtns =
        footerRender &&
        footerRender.map((item) => {
            let Component = mixedConfigMap[item.type];
            if (!Component) {
                return null;
            }
            return <Component {...(item.props || {})} />;
        });
    const onValuesChange = (changedValues, allValues) => {
        // 
        form.computedEffecties();
        propsOnValuesChange && propsOnValuesChange(changedValues, allValues);
    };
    useEffect(() => {
        form.setFields(fields);
        form.computedEffecties(true);
    }, [fields, form]);
    return (
        <div className='udesk-qa-create-form-container'>
            <Form form={getAFormInstance()} onValuesChange={onValuesChange} {...col} {...rest}>
                {Array.isArray(fields) &&
                    fields.map((field) => {
                        return (
                            <Field
                                field={field}
                                mixedConfigMap={mixedConfigMap}
                                formStore={form}
                            />
                        );
                    })}
                {React.Children.map(children, (item) => item)}
                {footerRender !== false && (
                    <Item wrapperCol={btnsCol}>
                        <Space>
                            {footerBtns || (
                                <React.Fragment>
                                    <Button type='primary' htmlType='submit'>
                                        {locales.labels.save}
                                    </Button>
                                    <Button onClick={() => {form.resetFields();}}>
                                        {locales.labels.cancel}
                                    </Button>
                                </React.Fragment>
                            )}
                        </Space>
                    </Item>
                )}
            </Form>
        </div>
    );
};

const InternalForm = forwardRef<CreateFormInstance, CreateFromProps>(CreateFrom as any) as (
    props: React.PropsWithChildren<CreateFromProps> & { ref?: React.Ref<CreateFormInstance> }
) => React.ReactElement;

type InternalFormType = typeof InternalForm;

interface RefFormType extends InternalFormType {
    useForm: typeof useFormContext;
}


const RefForm: RefFormType = InternalForm as RefFormType;

RefForm.useForm = useFormContext;

export default RefForm;
export type { CreateFromProps, CreateFormInstance };
