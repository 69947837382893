//xuyao zeng jia id
import React from 'react';
import Component from './base';
import Udesk from '../../udesk/index';

export default class Template extends Component {
    render() {
        let {
            props,
            actions,
            locales
        } = this;
        let {
            node,
            isShowNodeNum,
            nameField,
            numField
        } = props;
        let nodeName = Udesk.utils.object.get(node.data, nameField);
        let num = Udesk.utils.object.get(node.data, numField) || 0;
        let nodeNum = `${nodeName}`;
        if (isShowNodeNum) {
            nodeNum = `${nodeName}${locales.labels.leftBracket}${num}${locales.labels.rightBracket}`;
        }
        return (
            <div className="node-name">
                <If condition={nodeName}>
                    <span className="node-name-text" title={nodeNum} onClick={actions.click.params(node)}>
                        {nodeNum}
                    </span>
                </If>
            </div>
        );
    }
}