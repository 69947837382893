// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetCommunicateStrategyQuery,
    BaseResponseListCommunicateStrategyFoundResponse,
    PostCommunicateStrategyBody,
    BaseResponseCommunicateStrategyFoundResponse,
} from '../types';

/**
 * 获取沟通策略详情-分页
 *
 * @export
 * @tags 沟通策略设置
 * @link [GET] /communicateStrategy
 */
export function getCommunicateStrategy(): Promise<BaseResponseListCommunicateStrategyFoundResponse>;
export function getCommunicateStrategy(
    options: ApiOptions<never, GetCommunicateStrategyQuery>
): Promise<BaseResponseListCommunicateStrategyFoundResponse>;
export function getCommunicateStrategy(
    options?: ApiOptions<never, GetCommunicateStrategyQuery>
): Promise<BaseResponseListCommunicateStrategyFoundResponse> {
    return request<never, never, GetCommunicateStrategyQuery>('/communicateStrategy', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCommunicateStrategy',
    });
}

/**
 * 新增沟通策略详情
 *
 * @export
 * @tags 沟通策略设置
 * @link [POST] /communicateStrategy
 */
export function postCommunicateStrategy(
    data: PostCommunicateStrategyBody
): Promise<BaseResponseCommunicateStrategyFoundResponse>;
export function postCommunicateStrategy(
    data: PostCommunicateStrategyBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseCommunicateStrategyFoundResponse>;
export function postCommunicateStrategy(
    data: PostCommunicateStrategyBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseCommunicateStrategyFoundResponse> {
    return request<PostCommunicateStrategyBody, never, never>('/communicateStrategy', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCommunicateStrategy',
    });
}

export const meta = [
    { tags: ['沟通策略设置'], path: '/communicateStrategy', method: 'get' },
    { tags: ['沟通策略设置'], path: '/communicateStrategy', method: 'post' },
];
