// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostInspectionHomePageQueryCheckRateBody,
    BaseResponseHomePageCheckRateResponse,
} from '../../types';

/**
 * queryCheckRate
 *
 * @export
 * @tags inspection-home-page-controller
 * @link [POST] /inspectionHomePage/queryCheckRate
 */
export function postInspectionHomePageQueryCheckRate(
    data: PostInspectionHomePageQueryCheckRateBody
): Promise<BaseResponseHomePageCheckRateResponse>;
export function postInspectionHomePageQueryCheckRate(
    data: PostInspectionHomePageQueryCheckRateBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseHomePageCheckRateResponse>;
export function postInspectionHomePageQueryCheckRate(
    data: PostInspectionHomePageQueryCheckRateBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseHomePageCheckRateResponse> {
    return request<PostInspectionHomePageQueryCheckRateBody, never, never>(
        '/inspectionHomePage/queryCheckRate',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postInspectionHomePageQueryCheckRate',
        }
    );
}

export const meta = [
    {
        tags: ['inspection-home-page-controller'],
        path: '/inspectionHomePage/queryCheckRate',
        method: 'post',
    },
];
