export default {
  lang: {
    export: 'Exporter',
    successfully: "Création réussie de la tâche d'exportation",
    pleaseJumpTo: 'Veuillez sauter à',
    uploadAndDownloadCenter: 'Télécharger le center de téléchargement',
    check: 'Voir',
    exportAll: "Confirmer l'exportation de tout le contenu?",
    total: 'Total',
    confirm: "Données, confirmer l'exportation?",
    error:
      'Le nombre de données exportables est 0, veuillez réessayer après avoir modifié les conditions de filtrage',
  },
};
