import React from 'react';
import Locales from 'UdeskLocales';
import Udesk from 'Udesk';
import { Button, Icon, Space, Upload as UdeskUpload } from 'udesk-ui';
import styled from 'styled-components';
import { UploadFile } from 'src/util/upload';

const Div = styled.div`
    line-height: 30px;
    .udesk-qa-ui-space {
        padding: 0 8px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            .udesk-qa-ui-btn {
                display: block;
                color: red;
            }
        }

        .udesk-qa-ui-btn {
            display: none;
        }
    }
`;

// const MAX_PICTURE_SIZE = 500;
// downLoadPath?: string;

// name?: string;
export const UploadList = (props) => {
    const locales = Locales['current'];

    const { onChange, value } = props;
    const handlerUpload = (file) => {
        // if (file.size / 1024 > MAX_PICTURE_SIZE) {
        //     return Udesk.ui.notify.error(locales.pages.gong.userManage.edit.avatarGreaterThan500kb);
        // }
        UploadFile(file, (file, uploadData) => {
            Udesk.ui.notify.success(locales.pages.gong.userManage.edit.uploadSuccessful);
            
            onChange([...(value || []), { name: file.name, downLoadPath: file.url }]);
        });
        return false;
    };
    return (
        <React.Fragment>
            <Div>
                {(value || []).map((item, index) => (
                    <div>
                        <Space>
                            <div style={{ minWidth: 200 }}>{item.name}</div>
                            <Button
                                type="text"
                                icon={<Icon type="CloseOutlined" antdIcon={true} />}
                                size="small"
                                onClick={() => {
                                    onChange((value || []).filter((_, i) => i !== index));
                                }}
                            ></Button>
                        </Space>
                    </div>
                ))}
            </Div>
            <UdeskUpload showUploadList={false} beforeUpload={handlerUpload}>
                <Button type="link">{locales.pages.gong.userManage.edit.upload}</Button>
            </UdeskUpload>
        </React.Fragment>
    );
};
