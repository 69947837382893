// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetSmartTagsListQuery, BaseResponseListSmartTagFoundResponse } from '../../types';

/**
 * findList
 *
 * @export
 * @tags smart-tag-controller
 * @link [GET] /smartTags/list
 */
export function getSmartTagsList(): Promise<BaseResponseListSmartTagFoundResponse>;
export function getSmartTagsList(
    options: ApiOptions<never, GetSmartTagsListQuery>
): Promise<BaseResponseListSmartTagFoundResponse>;
export function getSmartTagsList(
    options?: ApiOptions<never, GetSmartTagsListQuery>
): Promise<BaseResponseListSmartTagFoundResponse> {
    return request<never, never, GetSmartTagsListQuery>('/smartTags/list', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSmartTagsList',
    });
}

export const meta = [{ tags: ['smart-tag-controller'], path: '/smartTags/list', method: 'get' }];
