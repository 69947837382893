import React, { useEffect, useMemo, useRef, useState } from 'react';
// import Locales from 'UdeskLocales';
import { Tag } from 'udesk-ui';
import styled from 'styled-components';
import { IntelligentPartnerTagGroupFoundResponse } from 'src/api/types';
import { getIntelligentPartnerTagGroups } from 'src/api/intelligentPartnerTagGroups';
import UdeskLocales from 'UdeskLocales';

const Title = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
`;
const Tags = styled.div`
    margin-bottom: 16px;
    .udesk-qa-ui-tag {
        cursor: pointer;
    }
`;
const Div = styled.div`
    background-color: #f5f6f8;
    padding: 12px;
    border-radius: 3px;
`;
interface KnowledgeTreeSelectProps {
    onChange: (value: any) => void;
    value: Array<IntelligentPartnerTagGroupFoundResponse>;
    knowledgeCategoryIdsList: Array<number>;
}

export const UserTagsSelect: React.FC<KnowledgeTreeSelectProps> = (props) => {
    // const locales = Locales['current'];
    const { onChange: propsOnChange, value } = props;
    const [customerTagGroups, setCustomerTagGroups] = useState<
        IntelligentPartnerTagGroupFoundResponse[]
    >([]);
    const [, setLoading] = useState<boolean>(false);

    const wrapDom = useRef(null);

    const selectedTagIds = useMemo(
        () =>
            (value || []).reduce<number[]>((prev, cur) => {
                return prev.concat(cur.tags?.map((t) => t.id!) ?? []);
            }, []),
        [value]
    );
    const queryCustomerTagGroups = async () => {
        setLoading(true);
        try {
            const { data = [] } = await getIntelligentPartnerTagGroups();
            setCustomerTagGroups(data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };
    useEffect(() => {
        queryCustomerTagGroups();
    }, []);
    const onTagClick = (clickedTag) => {
        const selectedTagIdsTemp = selectedTagIds.includes(clickedTag.id)
            ? selectedTagIds.filter((i) => i !== clickedTag.id)
            : [...selectedTagIds, clickedTag.id];
        let ret = customerTagGroups.map((group) => {
            if (group.tags?.some((tag) => selectedTagIdsTemp.includes(tag.id!))) {
                return {
                    ...group,
                    tags: group.tags.filter((tag) => selectedTagIdsTemp.includes(tag.id!)),
                };
            } else {
                return null;
            }
        });
        propsOnChange(ret.filter(Boolean));
    };
    return (
        <Div ref={wrapDom}>
            {customerTagGroups.map((group) => {
                return (
                    <div>
                        <Title>{group.groupName}</Title>
                        <Tags>
                            {group.tags
                                ? group.tags?.map((tag) => {
                                    return (
                                        <Tag
                                            onClick={onTagClick.bind(null, tag)}
                                            color={
                                                selectedTagIds.includes(tag.id!)
                                                    ? 'blue'
                                                    : undefined
                                            }
                                        >
                                            {tag.tagName}
                                        </Tag>
                                    );
                                })
                                : /* 暂无标签 */UdeskLocales['current'].components.coach.userTagsSelect.index.noLabelsCurrentlyAvailable}
                        </Tags>
                    </div>
                );
            })}
        </Div>
    );
};
