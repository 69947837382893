import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Udesk from 'Udesk';
import Page from "Component/common/udesk-qa-page-layout";
import PPTRecordPanel from '../components/record/ppt';
import TaskCue from '../components/cue';
import TaskMessageModal, { RefType } from '../components/task-message-modal';
import TaskSubmitButton from '../components/task-submit-button';
import TaskFlow from '../components/flow';
import PPTThumbnailList from '../../../components/ppt/list';
import { Button, Col, Row, Space, Spin } from "udesk-ui";
import { useRequest } from 'src/util/hook';
import { useChatRecord } from '../components/record/ppt/hook';
import { useFullscreen, usePPTData } from '../../../components/ppt/hook';
import { useCancelTask } from '../components/util';
import './index.less';
import UdeskLocales from 'UdeskLocales';

const Template = React.memo((props: any) => {
    const {
        isShowCue, setIsShowCue,
        isStartTask, setIsStartTask,
        isLastQuestion,
        taskDetail,  
        loadingForTaskDetail,                  
        wrongQuestion,
        wrongQuestionSet, 
        nextWrongQuestion,
        refreshWrongQuestion,
        setWrongQuestionIndex,
        history
    } = props;
    const request = useRequest();
    const [, list, current, index, goIndex] = usePPTData(taskDetail?.pptImageList);
    const [fullscreenContentRef, isFullscreen, requestFullscreen, exitFullscreen] = useFullscreen();
    const {
        referenceWords,
        loadingForSendMessage, // 发送消息接口的loading
        loadingForTaskStart, // 任务开始接口的loading
        isLastNode,
        list: messageList, // 消息列表
        startTaskHandle, // 开始任务
        isOver, // 录音是否结束
        stop, // 录音停止
        startAudioRecord, // 开始录音
    } = useChatRecord({
        wrongQuestion,
        request,
    });

    useEffect(() => {
        if (wrongQuestion) {
            startTaskHandle();
        }
    }, [startTaskHandle, wrongQuestion]);

    const taskMessageModalRef = useRef<RefType>(null);

    const cancelTaskHandle = useCancelTask(history, request, wrongQuestionSet);

    const title = useMemo(() => {
        let list = new Array<string>();
        if (taskDetail) {
            list.push(taskDetail.taskName);
        }
        list.push(/* 错题练习 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.ppt.index.mispracticeExercises);

        return list.join('   ');
    }, [taskDetail]);

    const overTaskHandle = useCallback(() => {
        taskMessageModalRef.current?.open(1);
    }, []);

    const isShowPPTThumbnailList = useMemo(() => {
        return wrongQuestion?.evaluationMode === Udesk.enums.pptEvaluationMode.whole.id && !isFullscreen;
    }, [isFullscreen, wrongQuestion?.evaluationMode]);

    return (
        <Page
            pageClassName='wrong-question-exercise'
            pageBodyClassName='wrong-question-exercise-body ppt'
            backGroundGhost={true}
            onBack={cancelTaskHandle}
            title={title}
            extra={
                isStartTask && <Button onClick={overTaskHandle} type='primary'>{/* 结束练习 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.ppt.index.endExercise}</Button>
            }
        >
            <Spin spinning={loadingForTaskDetail}>
                <div ref={fullscreenContentRef as any} className='fullscreen-content'>
                    <Row gutter={16} wrap={false}>
                        {
                            isShowPPTThumbnailList &&  (
                                <Col flex="327px">
                                    <PPTThumbnailList {...{
                                        list, current, index, goIndex, isStartTask: false
                                    }}/>     
                                </Col>
                            )
                        }
                        <Col flex="auto">
                            <PPTRecordPanel {...{
                                isStartTask, setIsStartTask,
                                wrongQuestion,
                                isFullscreen,
                                requestFullscreen,
                                exitFullscreen,
                                isLastNode,
                                list: messageList,
                                isOver,
                                pause: stop,
                                startAudioRecord,
                                loadingForTaskStart,
                                loadingForSendMessage,
                                setWrongQuestionIndex,
                            }}/>
                        </Col>
                        <Col flex="327px">
                            <Space direction={'vertical'} className={'wrong-question-exercise-other-panel'}>
                                <TaskFlow {...{
                                    messageList,
                                    isLastNode,
                                    isStartTask,
                                    wrongQuestion,
                                    wrongQuestionSet,
                                    setWrongQuestionIndex
                                }}/>
                                <TaskCue referenceWords={referenceWords} isShowCue={isShowCue} setIsShowCue={setIsShowCue}/>
                                <TaskSubmitButton 
                                    isLastQuestion={isLastQuestion}
                                    refreshWrongQuestion={refreshWrongQuestion}
                                    nextWrongQuestion={nextWrongQuestion}
                                    isLastNode={isLastNode} />
                            </Space>
                        </Col>
                        <Col flex="0" style={{padding: 0}}>
                            <TaskMessageModal 
                                ref={taskMessageModalRef} 
                                setWrongQuestionIndex={setWrongQuestionIndex}
                                cancelTaskHandle={cancelTaskHandle}
                                wrongQuestionSet={wrongQuestionSet}
                            />
                        </Col>
                    </Row>
                </div>
            </Spin>  
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
