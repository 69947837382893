// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetKnowledgeCategorysQuery,
    BaseResponseListKnowledgeCategoryFoundResponse,
    PostKnowledgeCategorysBody,
    BaseResponseKnowledgeCategoryFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags knowledge-category-controller
 * @link [GET] /knowledgeCategorys
 */
export function getKnowledgeCategorys(): Promise<BaseResponseListKnowledgeCategoryFoundResponse>;
export function getKnowledgeCategorys(
    options: ApiOptions<never, GetKnowledgeCategorysQuery>
): Promise<BaseResponseListKnowledgeCategoryFoundResponse>;
export function getKnowledgeCategorys(
    options?: ApiOptions<never, GetKnowledgeCategorysQuery>
): Promise<BaseResponseListKnowledgeCategoryFoundResponse> {
    return request<never, never, GetKnowledgeCategorysQuery>('/knowledgeCategorys', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getKnowledgeCategorys',
    });
}

/**
 * save
 *
 * @export
 * @tags knowledge-category-controller
 * @link [POST] /knowledgeCategorys
 */
export function postKnowledgeCategorys(
    data: PostKnowledgeCategorysBody
): Promise<BaseResponseKnowledgeCategoryFoundResponse>;
export function postKnowledgeCategorys(
    data: PostKnowledgeCategorysBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseKnowledgeCategoryFoundResponse>;
export function postKnowledgeCategorys(
    data: PostKnowledgeCategorysBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseKnowledgeCategoryFoundResponse> {
    return request<PostKnowledgeCategorysBody, never, never>('/knowledgeCategorys', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postKnowledgeCategorys',
    });
}

export const meta = [
    { tags: ['knowledge-category-controller'], path: '/knowledgeCategorys', method: 'get' },
    { tags: ['knowledge-category-controller'], path: '/knowledgeCategorys', method: 'post' },
];
