// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetWecomSmartTagsByIdParam,
    BaseResponseWecomSmartTagFoundResponse,
    PutWecomSmartTagsByIdParam,
    PutWecomSmartTagsByIdBody,
    BaseResponseVoid,
    DeleteWecomSmartTagsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags wecom-smart-tag-controller
 * @link [GET] /wecomSmartTags/{id}
 * @param id
 */
export function getWecomSmartTagsById(
    options: ApiOptions<GetWecomSmartTagsByIdParam, never> & {
        segments: GetWecomSmartTagsByIdParam;
    }
): Promise<BaseResponseWecomSmartTagFoundResponse> {
    return request<never, GetWecomSmartTagsByIdParam, never>('/wecomSmartTags/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getWecomSmartTagsById',
    });
}

/**
 * update
 *
 * @export
 * @tags wecom-smart-tag-controller
 * @link [PUT] /wecomSmartTags/{id}
 * @param id
 */
export function putWecomSmartTagsById(
    data: PutWecomSmartTagsByIdBody,
    options: ApiOptions<PutWecomSmartTagsByIdParam, never> & {
        segments: PutWecomSmartTagsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutWecomSmartTagsByIdBody, PutWecomSmartTagsByIdParam, never>(
        '/wecomSmartTags/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putWecomSmartTagsById' }
    );
}

/**
 * deleteById
 *
 * @export
 * @tags wecom-smart-tag-controller
 * @link [DELETE] /wecomSmartTags/{id}
 * @param id
 */
export function deleteWecomSmartTagsById(
    options: ApiOptions<DeleteWecomSmartTagsByIdParam, never> & {
        segments: DeleteWecomSmartTagsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteWecomSmartTagsByIdParam, never>('/wecomSmartTags/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteWecomSmartTagsById',
    });
}

export const meta = [
    { tags: ['wecom-smart-tag-controller'], path: '/wecomSmartTags/{id}', method: 'get' },
    { tags: ['wecom-smart-tag-controller'], path: '/wecomSmartTags/{id}', method: 'put' },
    { tags: ['wecom-smart-tag-controller'], path: '/wecomSmartTags/{id}', method: 'delete' },
];
