import React, { useState, useMemo, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Icon } from 'udesk-ui';
import './style.scss';

interface DataNode {
    title: string;
    key: string;
    isLeaf?: boolean;
    children?: DataNode[];
    icon?: any;
}

const WechatStaff = React.memo((props: any) => {
    const locales = Locales['current'];
    const tableLabels = locales.components.pages.wechatStaff.table;

    const { sdkOptions } = props;
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<string[]>([]);
    // const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const expandedKeys = useRef<string[]>([]);
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [initialed, setInitialed] = useState<boolean>(false);
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });
    const [treeKey, setTreeKey] = useState<string>('1');
    const [treeData, setTreeData] = useState<DataNode[]>([]);

    const tableProps = useMemo(() => {
        const columns = [
            {
                title: tableLabels.name,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: tableLabels.part,
                dataIndex: 'departmentName',
                key: 'departmentName',
            },
            {
                title: tableLabels.phone,
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: tableLabels.email,
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: tableLabels.wechartId,
                dataIndex: 'userId',
                key: 'userId',
            },
            {
                title: tableLabels.status,
                dataIndex: 'status',
                key: 'status',
                render: (name, item, index) => {
                    return Udesk.enums.inServiceStatus.getName(item.status);
                },
            },
        ];
        return {
            columns,
            dataSource,
            subtractedHeight: 136,
        };
    }, [dataSource]);

    const paginationProps = useMemo(() => {
        const { pageNum, pageSize, total } = paging;
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (pageNum, pageSize) => {
                setPaging({
                    ...paging,
                    pageNum,
                    pageSize,
                });
            },
        };
    }, [paging.pageNum, paging.pageSize, paging.total]);

    const treeProps = useMemo(() => {
        const onExpand = (newExpandedKeys) => {
            setTreeData(updateExpandNode(treeData, newExpandedKeys));
            expandedKeys.current = newExpandedKeys;
        };
        const onSelect = (selectedKeys, { selected, selectedNodes, node, event }) => {
            if (selected) {
                setSelectedKey(node.key);
                setPaging({
                    ...paging,
                    pageNum: 1,
                });
            }
        };
        const loadData = ({ key, children }: any) =>
            new Promise<void>((resolve) => {
                if (children) {
                    resolve();
                    return;
                }
                let url = Udesk.business.apiPath.concatApiPath(`departments/${key}`, sdkOptions);
                Udesk.ajax.get(url).then?.(
                    (resp) => {
                        setTreeData((origin) => updateExpandNode(updateTreeData(origin, key, getNode(resp.data?.childDepartment)), expandedKeys.current));
                        resolve();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            });
        return {
            treeData,
            loadData,
            blockNode: true,
            onSelect,
            selectedKeys: [selectedKey],
            onExpand,
            showIcon: true,
        };
    }, [treeData, selectedKey, paging]);
    const searchDepartments = () => {
        let url = Udesk.business.apiPath.concatApiPath(`departments`, sdkOptions);
        setLoading(true);
        Udesk.ajax.get(url).then?.(
            (resp) => {
                if (Array.isArray(resp.data) && resp.data.length > 0) {
                    setTreeData(updateExpandNode(getNode(resp.data), [`${resp.data[0].departmentId}`]));
                    setDefaultExpandedKeys([`${resp.data[0].departmentId}`]);
                    setInitialed(true);
                }
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    };

    const synData = () => {
        if (!selectedKey) {
            Udesk.ui.notify.error(locales.components.pages.wechatStaff.info);
            return;
        }
        let url = Udesk.business.apiPath.concatApiPath(`departments/syndata/${selectedKey}`, sdkOptions);
        setLoading(true);
        Udesk.ajax.get(url).then?.(
            (resp) => {
                Udesk.ui.notify.success(locales.components.pages.wechatStaff.synSuccess);
                searchDepartments();
                setLoading(false);
                setTreeKey(prev => prev + '1');
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    };
    const initData = () => {
        let url = Udesk.business.apiPath.concatApiPath(`departments/init`, sdkOptions);
        setLoading(true);
        Udesk.ajax.get(url).then?.(
            (resp) => {
                Udesk.ui.notify.success(locales.components.pages.wechatStaff.synSuccess);
                searchDepartments();
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    };
    useEffect(() => {
        searchDepartments();
    }, []);
    useEffect(() => {
        // 查员工
        if (!selectedKey) return;
        setTableLoading(true);
        let url = Udesk.business.apiPath.concatApiPath(`departments/employee/${selectedKey}`, sdkOptions);
        Udesk.ajax.get(url, paging).then?.(
            (resp) => {
                setDataSource(resp.data);
                setTableLoading(false);
                setPaging({
                    ...paging,
                    ...resp.paging,
                });
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setTableLoading(false);
            }
        );
    }, [selectedKey, paging.pageNum, paging.pageSize]);
    return (
        <Page
            pageBodyClassName='wechat-staff-center-page-index'
            title={locales.menu.tasks.wechatStaffCenter.text}
            padding={true}
            loading={loading}
        >
            <CommonTablePage
                loading={tableLoading}
                mainButton={
                    <Button loading={loading ? { delay: 200 } : false} type='primary' onClick={initialed ? synData : initData}>
                        {initialed
                            ? locales.components.pages.wechatStaff.syn
                            : locales.components.pages.wechatStaff.init}
                    </Button>
                }
            >
                {defaultExpandedKeys.length > 0 && (
                    <CommonTablePage.Tree
                        defaultExpandedKeys={defaultExpandedKeys}
                        {...treeProps}
                        treeKey={treeKey}
                    ></CommonTablePage.Tree>
                )}
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <WechatStaff {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);

function updateTreeData(list: DataNode[], key: React.Key, children?: DataNode[]): DataNode[] {
    return list.map((node) => {
        if (node.key === key) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });
}
function getNode(data) {
    return Array.isArray(data) ? data.map((i) => ({
        key: `${i.departmentId}`,
        isLeaf: i.leaf,
        title: i.departmentName,
        children: Array.isArray(i.childDepartment) ? getNode(i.childDepartment || []) : undefined,
        employee: i.employee,
        parentId: i.parentId,
        id: i.id,
    })) : undefined;
}

function updateExpandNode(data, expandedKeys) {
    return data.map((i) => ({
        ...i,
        icon: (
            <Icon
                type={expandedKeys.includes(i.key) ? 'ic-folder-open' : 'FolderFilled'}
                style={{ color: '#1A6EFF' }}
                antdIcon={!expandedKeys.includes(i.key)}
            />
        ),
        children: Array.isArray(i.children) ? updateExpandNode(i.children || [], expandedKeys) : undefined,
    }));
}
