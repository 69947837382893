import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Button, Icon, Space, Avatar, Form, DatePicker, Row, Col, Tooltip, TreeSelect } from 'udesk-ui';
import './index.scss';
import moment from 'moment';
import { getOperatorLogsFindStudyAnalysis } from 'src/api/operatorLogs/findStudyAnalysis';
import { getOperatorLogsFindSeekHelpAnalysis } from 'src/api/operatorLogs/findSeekHelpAnalysis';
// import { getUserGroup } from 'src/api/userGroup';
import { getOperatorLogsFindReviewAnalysis } from 'src/api/operatorLogs/findReviewAnalysis';
import { getOrganizations } from 'src/api/organizations';
import AutoSizeTable from 'Component/common/auto-size-table';
import UdeskPagination from 'Component/common/udesk-pagination';

const locales = Locales['current'];
// const { Option } = Select;
const { RangePicker } = DatePicker;
const PAGE_NUM = 1;
const PAGE_SIZE = 10;

const StudyAnalysis = React.memo((props: any) => {
    const startTime = moment(new Date()).subtract(1, 'months').add(1, 'days');
    const endTime = moment();
    const studyColumns = [
        {
            title: locales.pages.gong.studyAnalysis.index.teamMembers,
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
            ellipsis: true,
            width: '20%',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'center' }}>
                        <Avatar size='large' src={record?.headPortraitUrl} style={{ marginRight: 8 }}>
                            {record?.studyOperation?.userName}
                        </Avatar>
                        <h3 style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                            {record?.studyOperation?.userName}
                        </h3>
                    </div>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.totalNumberOfStudies}</span>
                    <Tooltip
                        title={
                            /* 成员学习他人会话的总数（去重），查阅会话详情且点击过录音播放视为学习一次 */ locales.pages
                                .gong.studyAnalysis.index
                                .membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'studyCount',
            key: 'studyCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.studyOperation?.studyCount}</span>
                        <Space size={6}>
                            {record?.studyOperation?.studyCountDifferenceValue > 0 ? (
                                <span className='rise-color'>+{record?.studyOperation?.studyCountDifferenceValue}</span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.studyOperation?.studyCountDifferenceValue}
                                </span>
                            )}
                            {record?.studyOperation?.studyCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.totalNumberOfInteractions}</span>
                    <Tooltip
                        title={
                            /* 成员对他人会话发起评论、回复的总数（去重） */ locales.pages.gong.studyAnalysis.index
                                .theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'commentCount',
            key: 'commentCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.studyOperation?.commentCount}</span>
                        <Space size={6}>
                            {record?.studyOperation?.commentCountDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{record?.studyOperation?.commentCountDifferenceValue}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.studyOperation?.commentCountDifferenceValue}
                                </span>
                            )}
                            {record?.studyOperation?.commentCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.lengthOfStudy}</span>
                    <Tooltip
                        title={
                            /* 学习的会话通话时长求和 */ locales.pages.gong.studyAnalysis.index
                                .learnTheSumOfConversationCallDuration
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'studySubTime',
            key: 'studySubTime',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>
                            {moment.utc(record?.studyOperation?.studySubTime ?? 0).format('HH:mm:ss')}
                        </span>
                        <Space size={6}>
                            {record?.studyOperation?.studySubTimeDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +
                                    {moment
                                        .utc(record?.studyOperation?.studySubTimeDifferenceValue ?? 0)
                                        .format('HH:mm:ss')}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -
                                    {moment
                                        .utc(record?.studyOperation?.studySubTimeDifferenceValue ?? 0)
                                        .format('HH:mm:ss')}
                                </span>
                            )}
                            {record?.studyOperation?.studySubTimeDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.totalNumberOfRequests}</span>
                    <Tooltip
                        title={
                            /* 成员对他人的会话发起求助的总数（去重） */ locales.pages.gong.studyAnalysis.index
                                .totalNumberOfMembersWhoInitiatedTheConversationToOthers
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'seekHelpCount',
            key: 'seekHelpCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.studyOperation?.seekHelpCount}</span>
                        <Space size={6}>
                            {record?.studyOperation?.seekHelpCountDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{record?.studyOperation?.seekHelpCountDifferenceValue}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.studyOperation?.seekHelpCountDifferenceValue}
                                </span>
                            )}
                            {record?.studyOperation?.seekHelpCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.totalCounseling}</span>
                    <Tooltip
                        title={
                            /* 成员对他人的求助进行辅导的总数（去重） */ locales.pages.gong.studyAnalysis.index
                                .totalNumberOfMembersCounselingOthersForHelpdeDuplication
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'tutorCount',
            key: 'tutorCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.studyOperation?.tutorCount}</span>
                        <Space size={6}>
                            {record?.studyOperation?.tutorCountDifferenceValue > 0 ? (
                                <span className='rise-color'>+{record?.studyOperation?.tutorCountDifferenceValue}</span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.studyOperation?.tutorCountDifferenceValue}
                                </span>
                            )}
                            {record?.studyOperation?.tutorCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.addCase}</span>
                    <Tooltip
                        title={
                            /* 成员对他人的会话添加到案例库的总数（去重） */ locales.pages.gong.studyAnalysis.index
                                .totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'caseCount',
            key: 'caseCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.studyOperation?.caseCount}</span>
                        <Space size={6}>
                            {record?.studyOperation?.caseCountDifferenceValue > 0 ? (
                                <span className='rise-color'>+{record?.studyOperation?.caseCountDifferenceValue}</span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.studyOperation?.caseCountDifferenceValue}
                                </span>
                            )}
                            {record?.studyOperation?.caseCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.subscribe}</span>
                    <Tooltip
                        title={
                            /* 成员对他人的会话进行订阅的总数（去重） */ locales.pages.gong.studyAnalysis.index
                                .totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'subCount',
            key: 'subCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.studyOperation?.subCount}</span>
                        <Space size={6}>
                            {record?.studyOperation?.subCountDifferenceValue > 0 ? (
                                <span className='rise-color'>+{record?.studyOperation?.subCountDifferenceValue}</span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.studyOperation?.subCountDifferenceValue}
                                </span>
                            )}
                            {record?.studyOperation?.subCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
    ];
    const replayColumns = [
        {
            title: locales.pages.gong.studyAnalysis.index.teamMembers,
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
            ellipsis: true,
            width: '20%',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'center' }}>
                        <Avatar size='large' src={record?.headPortraitUrl} style={{ marginRight: 8 }}>
                            {record?.reviewOperation?.userName}
                        </Avatar>
                        <h3 style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                            {record?.reviewOperation?.userName}
                        </h3>
                    </div>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.totalSessions}</span>
                    <Tooltip
                        title={
                            /* 成员沟通的会话总数 */ locales.pages.gong.studyAnalysis.index
                                .totalNumberOfSessionsCommunicatedByMembers
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'dataCallCount',
            key: 'dataCallCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.reviewOperation?.dataCallCount}</span>
                        <Space size={6}>
                            {record?.reviewOperation?.dataCallCountDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{record?.reviewOperation?.dataCallCountDifferenceValue}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.reviewOperation?.dataCallCountDifferenceValue}
                                </span>
                            )}
                            {record?.reviewOperation?.dataCallCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.totalNumberOfDoubleDisk}</span>
                    <Tooltip
                        title={
                            /* 成员复盘自己会话的总数（去重），查阅会话详情且点击过录音播放视为复盘一次 */ locales.pages
                                .gong.studyAnalysis.index
                                .theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'reviewCount',
            key: 'reviewCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.reviewOperation?.reviewCount}</span>
                        <Space size={6}>
                            {record?.reviewOperation?.reviewCountDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{record?.reviewOperation?.reviewCountDifferenceValue}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.reviewOperation?.reviewCountDifferenceValue}
                                </span>
                            )}
                            {record?.reviewOperation?.reviewCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.proportionOfDoubleDisk}</span>
                    <Tooltip
                        title={
                            /* 复盘总数/会话总数*100% */ locales.pages.gong.studyAnalysis.index
                                .totalNumberOfDuplicateDiskTotalNumberOfSessions
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'reviewPercent',
            key: 'reviewPercent',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>
                            {((record?.reviewOperation?.reviewPercent ?? 0) * 100).toFixed(2)}%
                        </span>
                        <Space size={6}>
                            {record?.reviewOperation?.reviewPercentDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{((record?.reviewOperation?.reviewPercentDifferenceValue ?? 0) * 100).toFixed(2)}%
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{((record?.reviewOperation?.reviewPercentDifferenceValue ?? 0) * 100).toFixed(2)}%
                                </span>
                            )}
                            {record?.reviewOperation?.reviewPercentDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.resumptionDuration}</span>
                    <Tooltip
                        title={
                            /* 复盘的会话通话时长求和 */ locales.pages.gong.studyAnalysis.index
                                .summationOfConversationCallDurationOfDuplicateDisk
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'longDuring',
            key: 'longDuring',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>
                            {moment.utc(record?.reviewOperation?.longDuring ?? 0).format('HH:mm:ss')}
                        </span>
                        <Space size={6}>
                            {record?.reviewOperation?.longDuringDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +
                                    {moment
                                        .utc(record?.reviewOperation?.longDuringDifferenceValue ?? 0)
                                        .format('HH:mm:ss')}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -
                                    {moment
                                        .utc(record?.reviewOperation?.longDuringDifferenceValue ?? 0)
                                        .format('HH:mm:ss')}
                                </span>
                            )}
                            {record?.reviewOperation?.longDuringDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.totalNumberOfRequests}</span>
                    <Tooltip
                        title={
                            /* 成员对自己的会话发起求助的总数（去重） */ locales.pages.gong.studyAnalysis.index
                                .totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'reviewSeekCount',
            key: 'reviewSeekCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.reviewOperation?.reviewSeekCount}</span>
                        <Space size={6}>
                            {record?.reviewOperation?.reviewSeekCountDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{record?.reviewOperation?.reviewSeekCountDifferenceValue}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.reviewOperation?.reviewSeekCountDifferenceValue}
                                </span>
                            )}
                            {record?.reviewOperation?.reviewSeekCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.totalNumberOfInteractions}</span>
                    <Tooltip
                        title={
                            /* 成员的会话收到他人评论（包括回复）的总数 */ locales.pages.gong.studyAnalysis.index
                                .theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'reviewCommentCount',
            key: 'reviewCommentCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.reviewOperation?.reviewCommentCount}</span>
                        <Space size={6}>
                            {record?.reviewOperation?.reviewCommentCountDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{record?.reviewOperation?.reviewCommentCountDifferenceValue}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.reviewOperation?.reviewCommentCountDifferenceValue}
                                </span>
                            )}
                            {record?.reviewOperation?.reviewCommentCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.addCase}</span>
                    <Tooltip
                        title={
                            /* 成员的会话被他人添加到案例库的总数（去重） */ locales.pages.gong.studyAnalysis.index
                                .theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'reviewCaseCount',
            key: 'reviewCaseCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.reviewOperation?.reviewCaseCount ?? 0}</span>
                        <Space size={6}>
                            {record?.reviewOperation?.reviewCaseCountDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{record?.reviewOperation?.reviewCaseCountDifferenceValue}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.reviewOperation?.reviewCaseCountDifferenceValue}
                                </span>
                            )}
                            {record?.reviewOperation?.reviewCaseCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: (
                <Space>
                    <span>{locales.pages.gong.studyAnalysis.index.subscribe}</span>
                    <Tooltip
                        title={
                            /* 成员的会话被他人进行订阅的总数（去重） */ locales.pages.gong.studyAnalysis.index
                                .theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication
                        }
                    >
                        <Icon style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.25)' }} type='jifei'></Icon>
                    </Tooltip>
                </Space>
            ),
            dataIndex: 'reviewSubCount',
            key: 'reviewSubCount',
            align: 'center',
            render: (text, record) => {
                return (
                    <Space direction='vertical'>
                        <span className='statistical-quantity'>{record?.reviewOperation?.reviewSubCount}</span>
                        <Space size={6}>
                            {record?.reviewOperation?.reviewSubCountDifferenceValue > 0 ? (
                                <span className='rise-color'>
                                    +{record?.reviewOperation?.reviewSubCountDifferenceValue}
                                </span>
                            ) : (
                                <span className='decline-color'>
                                    -{record?.reviewOperation?.reviewSubCountDifferenceValue}
                                </span>
                            )}
                            {record?.reviewOperation?.reviewSubCountDifferenceValue > 0 ? (
                                <Icon classname='rise-color' type='ic-arrow-up-s-shangsanjiao'></Icon>
                            ) : (
                                <Icon classname='decline-color' type='ic-arrow-down-s-xiasanjiao'></Icon>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
    ];
    const helpColumns = [
        {
            title: locales.pages.gong.studyAnalysis.index.teamMembers,
            dataIndex: 'headPortraitUrl',
            key: 'headPortraitUrl',
            align: 'center',
            ellipsis: true,
            width: '20%',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'center' }}>
                        <Avatar size='large' src={record?.headPortraitUrl} style={{ marginRight: 8 }}>
                            {record?.userName}
                        </Avatar>
                        <h3 style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                            {record?.userName}
                        </h3>
                    </div>
                );
            },
        },
        {
            title: locales.pages.gong.studyAnalysis.index.askForHelpInTheLastMonth,
            dataIndex: 'operatorLogs',
            key: 'operatorLogs',
            width: 400,
            render: (text, record) => {
                return (
                    <Space>
                        <div style={{minWidth: 24, textAlign: 'center'}}>{record?.seekHelpSize??'-'}</div>
                        <div className='last-month-data'>
                            <div style={{ height: 22 }}></div>
                            {(
                                Object.values(
                                    (record.operatorLogs ?? []).reduce((p, c) => {
                                        const key = c.createTime?.split(' ')?.[0] ?? 'none';
                                        if (p[key]) {
                                            p[key].push(c);
                                        } else {
                                            p[key] = [c];
                                        }
                                        return p;
                                    }, {})
                                ) as any[]
                            ).map((item) => {
                                let monthDay = moment().diff(moment().subtract(1, 'months'), 'days');
                                let currentDay = moment(item[0].createTime).diff(
                                    moment().subtract(1, 'months'),
                                    'days'
                                );
                                const title = (
                                    <div>
                                        {item.map((i) => {
                                            return <div>{i.createTime + ':' + i.seekHelpContent}</div>;
                                        })}
                                    </div>
                                );
                                return (
                                    <div
                                        className='call-detail-tips'
                                        style={{
                                            left:
                                                ((currentDay + 1) / monthDay) * 100 > 100
                                                    ? 100
                                                    : ((currentDay + 1) / monthDay) * 100 + '%',
                                        }}
                                    >
                                        <Tooltip title={title}>
                                            <Icon style={{ fontSize: 16 }} type='duihuaguanli'></Icon>
                                        </Tooltip>
                                    </div>
                                );
                            })}
                            <div className='last-line-location'></div>
                            <div className='last-month-date'>
                                <p>{moment().subtract(1, 'months').format('MM-DD')}</p>
                                <p>{moment().format('MM-DD')}</p>
                            </div>
                        </div>
                    </Space>
                );
            },
        },
        {
            title: locales.pages.gong.studyAnalysis.index.lastTimeForHelp,
            dataIndex: 'lastSeekHelpTimeStr',
            key: 'lastSeekHelpTimeStr',
            align: 'center',
            ellipsis: true,
        },
        {
            title: locales.pages.gong.studyAnalysis.index.operation,
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            // ellipsis: true,
            render: (text, record) => {
                return (
                    <Button onClick={handlerViewCallList.bind(null, record)} type='link'>
                        {locales.pages.gong.studyAnalysis.index.viewAllSessionsInTheLastMonth}
                    </Button>
                );
            },
        },
    ];
    const [form] = Form.useForm();
    const [columns, setColumns] = useState<any[]>([]);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [userGroup, setUserGroup] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchCondition, setSearchCondition] = useState<any>({
        groupIds: [],
        rangeDate: [startTime, endTime],
    });
    const [callCount, setCallCount] = useState(0);
    const [page, setPage] = useState({
        current: PAGE_NUM,
        pageSize: PAGE_SIZE,
        total: 0,
    });
    const handlerPageChange = (current, pageSize) => {
        setPage({
            ...page,
            current,
            pageSize,
        });
        let routeName = props.route.name;
        if (routeName === 'analysisEnableStudy') {
            getStudyAnalysis(current, pageSize, searchCondition);
        } else if (routeName === 'analysisEnableReplay') {
            getReplayAnalysis(current, pageSize, searchCondition);
        } else {
            getHelpAnalysis(current, pageSize, searchCondition);
        }
    };
    const handlerViewCallList = (item) => {
        const routeOptions = {
            history: props.history,
            routeName: 'callAnalysisList',
            state: {
                filterFormValues: {
                    call_time: [moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                    keywordType: -1,
                    agent: [item.agentId].filter(Boolean),
                },
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const handlerSearch = (values) => {
        setSearchCondition(values);
        let routeName = props.route.name;
        if (routeName === 'analysisEnableStudy') {
            getStudyAnalysis(PAGE_NUM, PAGE_SIZE, values);
        } else if (routeName === 'analysisEnableReplay') {
            getReplayAnalysis(PAGE_NUM, PAGE_SIZE, values);
        } else {
            getHelpAnalysis(PAGE_NUM, PAGE_SIZE, values);
        }
    };
    const getStudyAnalysis = (pageNum, pageSize, values) => {
        setLoading(true);
        const params: any = {
            pageNum,
            pageSize,
            groupIds: values.groupIds,
        };
        if (values.rangeDate && values.rangeDate.length) {
            params.startTime = moment(values.rangeDate[0]).format('YYYY-MM-DD 00:00:00');
            params.endTime = moment(values.rangeDate[1]).format('YYYY-MM-DD 23:59:59');
        }
        getOperatorLogsFindStudyAnalysis({ params })
            .then((res) => {
                setLoading(false);
                setDataSource(getFormatData(res.data));
                if (res.data?.length) {
                    setCallCount(res.data[0]?.allCallCount ?? 0);
                }
                setPage(prev => ({...prev, total: res.paging?.total??0}));
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const getReplayAnalysis = (pageNum, pageSize, values) => {
        setLoading(true);
        const params: any = {
            pageNum,
            pageSize,
            groupIds: values.groupIds,
        };
        if (values.rangeDate && values.rangeDate.length) {
            params.startTime = moment(values.rangeDate[0]).format('YYYY-MM-DD 00:00:00');
            params.endTime = moment(values.rangeDate[1]).format('YYYY-MM-DD 23:59:59');
        }

        getOperatorLogsFindReviewAnalysis({ params })
            .then((res) => {
                setLoading(false);
                setDataSource(getFormatData(res.data));
                setPage(prev => ({...prev, total: res.paging?.total??0}));
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const getHelpAnalysis = (pageNum, pageSize, values) => {
        setLoading(true);
        const params: any = {
            pageNum,
            pageSize,
            groupIds: values.groupIds,
        };
        getOperatorLogsFindSeekHelpAnalysis({ params })
            .then((res) => {
                setLoading(false);
                setDataSource(getFormatData(res.data));
                setPage(prev => ({...prev, total: res.paging?.total??0}));
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const getGroupUserData = () => {
        // const params = {
        //     pageSize: 1000,
        // };
        getOrganizations().then((res) => {
            setUserGroup(transformArrayToTree(res.data ?? []));
        });
    };
    useEffect(() => {
        let routeName = props.route.name;
        getGroupUserData();
        if (routeName === 'analysisEnableStudy') {
            setColumns(studyColumns);
            getStudyAnalysis(page.current, page.pageSize, searchCondition);
        } else if (routeName === 'analysisEnableReplay') {
            setColumns(replayColumns);
            getReplayAnalysis(page.current, page.pageSize, searchCondition);
        } else {
            setColumns(helpColumns);
            getHelpAnalysis(page.current, page.pageSize, searchCondition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.route.name]);
    // 树结构数据处理
    const transformArrayToTree = (arr: any = []) => {
        if (!arr || !arr.length) return [];
        const sortedArr = arr
            .map((i: any) => ({
                ...i,
                title: i.name,
                key: i.id,
                parentId: i.parentId * 1,
            }))
            .sort((a, b) => a.parentId - b.parentId);
        let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
        let nodeMap = {};
        sortedArr.forEach((item) => {
            nodeMap[item.id] = item;
            if (nodeMap[item.parentId]) {
                if (Array.isArray(nodeMap[item.parentId].children)) {
                    nodeMap[item.parentId].children.push(item);
                } else {
                    nodeMap[item.parentId].children = [item];
                }
            }
        });
        return [...root];
    };
    return (
        <Page
            pageBodyClassName='pages-study-analysis-container'
            title={
                props.route.name === 'analysisEnableStudy'
                    ? locales.menu.tasks.analysisStudy.text
                    : props.route.name === 'analysisEnableReplay'
                        ? locales.menu.tasks.analysisReview.text
                        : locales.menu.tasks.analysisHelp.text
            }
            padding={true}
        >
            <div className='search-condition'>
                <Row>
                    <Form
                        form={form}
                        initialValues={{ rangeDate: [startTime, endTime] }}
                        layout='inline'
                        onFinish={handlerSearch}
                        style={{ width: '100%' }}
                    >
                        <Col span={6}>
                            <Form.Item name='groupIds'>
                                <TreeSelect
                                    showSearch
                                    placeholder={locales.pages.gong.studyAnalysis.index.allDepartments}
                                    allowClear
                                    multiple
                                    treeDefaultExpandAll
                                    treeNodeFilterProp='name'
                                    treeData={userGroup}
                                    maxTagCount='responsive'
                                ></TreeSelect>
                                {/* <Select
                                    mode='multiple'
                                    maxTagCount={1}
                                    allowClear
                                    placeholder={locales.pages.gong.studyAnalysis.index.allDepartments}
                                >
                                    {userGroup.map((item) => {
                                        return (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select> */}
                            </Form.Item>
                        </Col>
                        {props.route.name !== 'analysisEnableHelp' && (
                            <Col span={6}>
                                <Form.Item name='rangeDate'>
                                    <RangePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={6}>
                            <Form.Item>
                                <Space>
                                    {props.route.name !== 'analysisEnableHelp' && (
                                        <Button onClick={() => form.resetFields()}>
                                            {locales.pages.gong.studyAnalysis.index.reset}
                                        </Button>
                                    )}
                                    <Button type='primary' htmlType='submit'>
                                        {locales.pages.gong.studyAnalysis.index.query}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Form>
                </Row>
                {props.route.name === 'analysisEnableStudy' && (
                    <h3>
                        {locales.pages.gong.studyAnalysis.index.altogether}
                        {callCount}
                        {locales.pages.gong.studyAnalysis.index.secondSession}
                    </h3>
                )}
            </div>
            <AutoSizeTable
                subtractedHeight={162}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                pagination={false}
            />
            <UdeskPagination
                {...page}
                onChange={handlerPageChange}
            />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <StudyAnalysis {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

function getFormatData(data: any[] | undefined): any[] {
    const res = (data ?? []).map((i) => {
        const res = { ...i };
        if (res.studyOperation) {
            res.studyOperation = Object.keys(res.studyOperation).reduce((prev, key) => {
                prev[key] = res.studyOperation![key] ?? 0;
                return prev;
            }, {});
        }
        if (res.reviewOperation) {
            res.reviewOperation = Object.keys(res.reviewOperation).reduce((prev, key) => {
                prev[key] = res.reviewOperation![key] ?? 0;
                return prev;
            }, {});
        }
        return res;
    });
    return res;
}
