import React, { useState, useEffect, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import {
    PageHeader,
    Menu,
    Icon,
    Space,
    Button,
    Descriptions,
    Popconfirm,
    Switch,
    Modal,
    // DynamicForm as CreateForm,
} from 'udesk-ui';
import CreateForm, { CreateFromProps } from 'Component/common/create-form';
import cusConfigMap from '../../../../../common/config/form-config/cus-component-map';
import AutoSizeTable from 'Component/common/auto-size-table';
import './style.scss';

export default function CheckPoint(props) {
    const locales = Locales['current'];

    const [classificationList, setClassificationList] = useState<any>([]);
    const [selectedClassification, setSelectedClassification] = useState<any>({});
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any>([]);
    const [needRefershRight, setNeedRefersh] = useState<any>('');
    // const [loadingClassificationList, setLoadingClassificationList] = useState<boolean>(false);
    const [loadingPoints, setLoadingPoints] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [taskId /*setTaskId*/] = useState<string>('0'); //企业微信质检 任务id为0
    const [form] = CreateForm.useForm();
    const [visible, setVisible] = useState(false);
    const [currentClassification, setCurrentClassification] = useState<any>({});
    const upCheckPointState = (item, index, checked) => {
        item.status = checked ? 1 : 0;
        let newDataSource = [...dataSource];
        newDataSource[index] = item;
        setDataSource(newDataSource);
        let url = Udesk.business.apiPath.concatApiPath(
            '/inspectionPoints/' + item.id + '/status/' + item.status + '?taskId=' + taskId,
            props.sdkOptions
        );
        Udesk.ajax.put(url).then(
            (resp) => {
                Udesk.ui.notify.success(locales.business.info.editSuccess);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                item.status = checked ? 0 : 1;
                let newDataSource = [...dataSource];
                newDataSource[index] = item;
                setDataSource(newDataSource);
            }
        );
    };
    const deleteItem = (point) => {
        setLoadingDelete(true);
        let url = Udesk.business.apiPath.concatApiPath(
            '/inspectionPoints/' + point.id + '?taskId=' + taskId,
            props.sdkOptions
        );
        Udesk.ajax.del(url).then(
            (resp) => {
                setLoadingDelete(false);
                setNeedRefersh(Udesk.utils.string.uuid());
            },
            (reason) => {
                setLoadingDelete(false);
                Udesk.ui.notify.error(
                    reason.errorMsg ||
                        `${locales.pages.tasks.manage.template.checkPoint.index.name}${locales.business.info.deleteError}`
                );
            }
        );
    };
    const goToPointEdit = (item) => {
        let routeOptions = {
            history: props.history,
            routeName: 'wechatQaCheckPointSettingEdit',
            pathParams: {
                groupId: selectedClassification.id,
                id: item.id,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const getColumns = () => {
        // let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
        let columns = [
            {
                key: 'name',
                dataIndex: 'name',
                title: locales.columns.tasks.manage.template.checkPoint.name,
                width: '10%',
            },
            {
                key: 'remark',
                dataIndex: 'remark',
                title: locales.columns.tasks.manage.template.checkPoint.remark,
                textWrap: 'word-break',
                ellipsis: true,
            },
            {
                key: 'type',
                dataIndex: 'type',
                title: locales.columns.tasks.manage.template.checkPoint.type,
                width: '10%',
                render: (name, item, index) => {
                    return <div>{Udesk.enums.scoreType.getName(item.type)}</div>;
                },
            },
            {
                key: 'gradeType',
                dataIndex: 'gradeType',
                title: locales.columns.tasks.manage.template.checkPoint.gradeType,
                width: '15%',
                render: (name, item, index) => {
                    if (item.type === Udesk.enums.scoreType.machineLearning.id) {
                        return <div>{Udesk.enums.strategyTypes.getName(item.gradeType)}</div>;
                    } else {
                        return <div>{Udesk.enums.gradeTypes.getName(item.gradeType)}</div>;
                    }
                },
            },
            {
                key: 'conditionLogic',
                dataIndex: 'conditionLogic',
                title: locales.columns.tasks.manage.template.checkPoint.conditionLogic,
                width: '15%',
                textWrap: 'word-break',
                ellipsis: true,
                render: (name, item, index) => {
                    let context = getConditionLogic(item);
                    return (
                        <div title={context} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {context}
                        </div>
                    );
                },
            },
            {
                key: 'predeterminedScore',
                dataIndex: 'predeterminedScore',
                title: locales.columns.tasks.manage.template.checkPoint.predeterminedScore,
                width: '10%',
                render: (name, item, index) => {
                    return (
                        <div>
                            {`${
                                item.scoreType === Udesk.enums.calculateScoreTypes.add.id
                                    ? locales.labels.plusSign
                                    : locales.labels.minusSign
                            }${item.predeterminedScore}`}
                        </div>
                    );
                },
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: locales.columns.tasks.manage.template.checkPoint.status,
                width: '10%',
                render: (name, item, index) => {
                    return (
                        <div>
                            <Switch
                                checked={item.status === 1}
                                onChange={upCheckPointState.bind(null, item, index)}
                                size='small'
                            />
                        </div>
                    );
                },
            },
            {
                key: 'actions',
                dataIndex: 'actions',
                title: locales.columns.tasks.manage.template.checkPoint.actions,
                width: '18%',
                align: 'center' as any,
                render: (name, item, index) => {
                    return (
                        <div>
                            <Space>
                                {/* {hasFeature && hasFeature('task:template:point:edit') && ( */}
                                <Button type='link' size='small' onClick={goToPointEdit.bind(null, item)}>
                                    {locales.labels.edit}
                                </Button>
                                {/* )} */}
                                {/* {hasFeature && hasFeature('task:template:point:delete') && ( */}
                                <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={deleteItem.bind(null, item)}
                                    onCancel={(e) => e!.stopPropagation()}
                                    okText={locales.labels.confirm}
                                    okButtonProps={{
                                        loading: loadingDelete,
                                    }}
                                    placement='topRight'
                                    cancelText={locales.labels.cancel}
                                >
                                    <Button danger type='link' size='small'>
                                        {locales.labels.delete}
                                    </Button>
                                </Popconfirm>
                                {/* )} */}
                            </Space>
                        </div>
                    );
                },
            },
        ];
        return columns;
    };
    const deleteClassification = (item) => {
        
        let url = Udesk.business.apiPath.concatApiPath(`inspectionPointGroups/${item.id}`, props.sdkOptions);
        Udesk.ajax.del(url).then(
            (resp) => {
                getInspectionPointGroups();
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const newClassification = () => {
        setCurrentClassification({});
        setVisible(true);
    };
    const editClassification = (item) => {
        setCurrentClassification(item);
        setVisible(true);
        form.setFieldsValue(item);
    };
    const handleOk = useCallback(() => {
        form.submit();
    }, [form]);
    const handleCancel = useCallback(() => {
        setVisible(false);
        form.resetFields();
    }, [form]);
    // const afterClose = () => {
    //     form.resetFields();
    // }
    const onFinish = (values) => {
        
        let params = { ...currentClassification, ...values };
        let query: Promise<any> | null = null;
        setConfirmLoading(true);
        if (currentClassification.id) {
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionPointGroups/${currentClassification.id}`,
                props.sdkOptions
            );
            query = Udesk.ajax.put(url, params);
        } else {
            let url = Udesk.business.apiPath.concatApiPath(`inspectionPointGroups`, props.sdkOptions);
            query = Udesk.ajax.post(url, params);
        }
        query &&
            query.then(
                (resp) => {
                    getInspectionPointGroups();
                    handleCancel();
                    setConfirmLoading(false);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    setConfirmLoading(false);
                }
            );
    };
    const newPoint = () => {
        // wechatQaCheckPointSettingNew
        let routeOptions = {
            history: props.history,
            routeName: 'wechatQaCheckPointSettingNew',
            pathParams: {
                groupId: selectedClassification.id,
            },
            // queryParams: {

            // }
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const config: CreateFromProps = {
        initialValues: {},
        col: {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
        },
        fields: [
            {
                name: 'name',
                label: locales.pages.wechatQa.checkPoint.classificationName,
                field: {
                    type: 'Input',
                    props: {
                        placeholder: locales.pages.wechatQa.checkPoint.classificationNamePlaceHolder,
                    },
                },
                rules: [
                    {
                        required: true,
                        max: 40,
                    },
                ],
            },
            {
                name: 'description',
                label: locales.pages.wechatQa.checkPoint.classificationRemark,
                field: {
                    type: 'TextArea',
                    props: {
                        placeholder: locales.pages.wechatQa.checkPoint.classificationRemarkPlaceHolder,
                        autoSize: { minRows: 2, maxRows: 2 },
                    },
                },
                rules: [
                    {
                        max: 200,
                    },
                ],
            },
            // {
            //     name: 'benchmarkScore',
            //     label: locales.pages.tasks.manage.template.basicInfo.index.benchmarkScore,
            //     field: {
            //         type: 'InputNumber',
            //         props: {
            //             placeholder: locales.business.info.benchmarkScorePlaceholder,
            //             style: {
            //                 width: '100%',
            //             },
            //         },
            //     },
            //     rules: [
            //         {
            //             required: true,
            //         },
            //     ],
            // },
            // {
            //     name: 'scoreUpperLimit',
            //     label: locales.pages.tasks.manage.template.basicInfo.index.scoreUpperLimit,
            //     field: {
            //         type: 'InputNumber',
            //         props: {
            //             placeholder: locales.business.info.scoreUpperLimitPlaceholder,
            //             style: {
            //                 width: '100%',
            //             },
            //         },
            //     },
            // },
            // {
            //     name: 'scoreLowerLimit',
            //     label: locales.pages.tasks.manage.template.basicInfo.index.scoreLowerLimit,
            //     field: {
            //         type: 'InputNumber',
            //         props: {
            //             placeholder: locales.business.info.scoreLowerLimitPlaceholder,
            //             style: {
            //                 width: '100%',
            //             },
            //         },
            //     },
            // },
        ],
        footerRender: false,
        onFinish: onFinish,
        configMap: cusConfigMap,
    };
    const getInspectionPointGroups = async () => {
        let url = Udesk.business.apiPath.concatApiPath(
            `inspectionPointGroups?pageNum=1&pageSize=1000`,
            props.sdkOptions
        );
        try {
            const inspectionPointGroups = await Udesk.ajax.get(url);
            setClassificationList(inspectionPointGroups.data);
            
            if (props.location.search) {
                let query = props.location.search.substring(1);
                
                let pairs = query.split('&');
                
                pairs.forEach((item) => {
                    if (item.indexOf('groupId=') >= 0) {
                        let groupId = item.replace('groupId=', '');
                        
                        setSelectedClassification(
                            inspectionPointGroups.data.find((group) => `${group.id}` === groupId) ?? {}
                        );
                    }
                });
            } else if (inspectionPointGroups.data?.length) {
                setSelectedClassification(inspectionPointGroups.data[0]);
            }
        } catch (err: any) {
            
            Udesk.ui.notify.error(err.errorMsg);
        }
    };
    const onClassificationSelect = ({ item, key, keyPath, selectedKeys, domEvent }: any) => {
        
        let selected = classificationList.find((i) => i.id === parseInt(key, 10));
        setSelectedClassification(selected);
    };
    useEffect(() => {
        getInspectionPointGroups();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!selectedClassification?.id) {
            return;
        }
        setLoadingPoints(true);
        let url = Udesk.business.apiPath.concatApiPath(
            `/inspectionPointGroups/${selectedClassification.id}`,
            props.sdkOptions
        );
        Udesk.ajax.get(url).then(
            (resp) => {
                
                setDataSource(resp.data.points);
                setLoadingPoints(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoadingPoints(false);
            }
        );
    }, [selectedClassification.id, needRefershRight, props.sdkOptions]);
    return (
        <div className='udesk-qa-web-page'>
            <PageHeader className='udesk-qa-web-page-header' title={locales.pages.wechatQa.checkPoint.title} />
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root wechat-qa-tasks-check-point'>
                    <div className='wechat-qa-tasks-check-point-left'>
                        <div style={{ fontWeight: 600 }}>{locales.pages.wechatQa.checkPoint.groups}</div>
                        <Menu
                            selectedKeys={selectedClassification?.id ? [`${selectedClassification.id}`] : undefined}
                            mode='inline'
                            style={{ height: 'calc(100% - 22px)' }}
                            onSelect={onClassificationSelect}
                        >
                            {classificationList.map((item) => {
                                return (
                                    <Menu.Item key={`${item.id}`}>
                                        <div className='wechat-qa-tasks-check-point-classification-item'>
                                            <div
                                                title={item.name}
                                                className='wechat-qa-tasks-check-point-classification-item-title'
                                            >
                                                {item.name}
                                            </div>
                                            <div onClick={(e) => e.stopPropagation()}>
                                                <Space>
                                                    <div onClick={editClassification.bind(null, item)}>
                                                        <Icon type='EditTwoTone' antdIcon={true}></Icon>
                                                    </div>
                                                    <Popconfirm
                                                        onConfirm={deleteClassification.bind(null, item)}
                                                        title={locales.labels.deletePopconfirmContent}
                                                        okText={locales.labels.delete}
                                                        cancelText={locales.labels.cancel}
                                                    >
                                                        <Icon type='DeleteTwoTone' antdIcon={true}></Icon>
                                                    </Popconfirm>
                                                </Space>
                                            </div>
                                        </div>
                                    </Menu.Item>
                                );
                            })}
                            <div className='wechat-qa-tasks-check-point-classification-item'>
                                <Button
                                    style={{ width: '100%' }}
                                    type='text'
                                    onClick={newClassification}
                                    icon={<Icon type='PlusOutlined' antdIcon={true} />}
                                >
                                    {locales.pages.wechatQa.checkPoint.newClassification}
                                </Button>
                            </div>
                        </Menu>
                    </div>
                    <div className='wechat-qa-tasks-check-point-right'>
                        <div className='wechat-qa-tasks-check-point-classification-info'>
                            <PageHeader
                                ghost={false}
                                title={selectedClassification.name}
                                subTitle={selectedClassification.description}
                            >
                                <Descriptions size='small' column={3}>
                                    <Descriptions.Item label={locales.pages.wechatQa.checkPoint.pointCount}>
                                        {dataSource.length}{locales.labels.pointUnit}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={locales.pages.wechatQa.checkPoint.openPointCount}>
                                        {dataSource.filter((i) => i.status).length}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={locales.pages.wechatQa.checkPoint.closedPointCount}>
                                        {dataSource.filter((i) => !i.status).length}
                                    </Descriptions.Item>
                                </Descriptions>
                            </PageHeader>
                            <div className='wechat-qa-tasks-check-point-toolsbar'>
                                <div style={{ fontWeight: 600 }}>{locales.pages.wechatQa.checkPoint.title}</div>
                                <Button type='primary' onClick={newPoint}>
                                    {locales.labels.create}
                                </Button>
                            </div>
                            <AutoSizeTable
                                subtractedHeight={178}
                                loading={loadingPoints}
                                pagination={false}
                                dataSource={dataSource}
                                columns={getColumns()}
                            />
                        </div>
                    </div>
                    <Modal
                        title={
                            currentClassification.title
                                ? locales.pages.wechatQa.checkPoint.editClassification
                                : locales.pages.wechatQa.checkPoint.newClassification
                        }
                        confirmLoading={confirmLoading}
                        visible={visible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        // afterClose={afterClose}
                    >
                        <CreateForm form={form} {...config}></CreateForm>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

function getConditionLogic(item) {
    let conditionLogic = '';

    if (item && (item.type === Udesk.enums.scoreType.aiScore.id || item.type === Udesk.enums.scoreType.smartRules.id)) {
        if (item.ruleList && item.ruleList.length > 0) {
            item.ruleList.forEach((rule, index) => {
                if (index === item.ruleList.length - 1) {
                    conditionLogic += rule.formula;
                } else {
                    conditionLogic += rule.formula + ' ; ';
                }
            });
        }
    } else if (item && item.type === Udesk.enums.scoreType.interactiveDetection.id) {
        if (item.ruleList && item.ruleList.length > 0) {
            item.ruleList.forEach((rule, index) => {
                if (rule.name) {
                    conditionLogic += rule.name;
                }
            });
        }
    }

    return conditionLogic;
}
