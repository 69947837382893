import React, { FC, useState } from 'react';
import { useChart } from './hooks';
import { useRequest } from 'ahooks';
import { Button, Radio, Space, Dropdown, Menu, message } from 'udesk-ui';
import { InformationOutlined } from '@udesk/icons';
import styled from 'styled-components';
import { getIntelligentPartnerAiAnalysisQueryFormFindAll } from 'src/api/intelligentPartner/ai/analysis/query/form/findAll';
import { putIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormId } from 'src/api/intelligentPartner/ai/analysis/{historyId}/query/history/form/{formId}';
import { IntelligentPartnerAiQueryReportFormFoundResponse } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const HeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    width: 100%;
`;

const TitleWrapperDiv = styled.div`
    width: 50%;
    flex: 0 0 50%;
`;

const TitleDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
`;

const TitleLabelDiv = styled.div`
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: bold;
    margin-right: 8px;
`;

const SubTitleDiv = styled.div`
    padding: 8px 16px;
    background-color: #eee;
    border-radius: 4px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

type HeaderProps = {
    pageTitle?: string;
    subTitle?: string;
    chartType?: string;
    onChartTypeChange?: (v: string) => void;
    chartDisable?: boolean;
    pieChartDisabled?: boolean;
    onExport?: () => void;
    exportLoading?: boolean;
    historyId?: number;
    curSql?: string;
};

const Header: FC<HeaderProps> = (props) => {
    const {
        pageTitle,
        subTitle,
        chartType,
        onChartTypeChange,
        chartDisable = false,
        pieChartDisabled = false,
        onExport,
        exportLoading,
        historyId,
        curSql,
    } = props;

    const [showSubTitle, setShowSubTitle] = useState(false);

    const onToggleSubTitle = () => {
        setShowSubTitle((show) => !show);
    };

    const { chartTypeList } = useChart({ chartDisable, pieChartDisabled });

    const onTypeChange = (v) => {
        onChartTypeChange?.(v);
    };

    const onInnerExport = () => {
        onExport?.();
    };

    const [statements, setStatements] = useState<
        IntelligentPartnerAiQueryReportFormFoundResponse[]
    >([]);

    useRequest(getIntelligentPartnerAiAnalysisQueryFormFindAll, {
        onSuccess: (res) => {
            setStatements(res.data || []);
        },
    });

    const onAddToStatement = (formId) => {
        // 这里用 sql 语句来判断是否有当前图标可以添加
        curSql &&
            historyId &&
            putIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormId({
                segments: {
                    formId,
                    historyId,
                },
            }).then(() => {
                message.success(/* 添加成功 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.header.index.successfullyAdded);
            });
    };

    return (
        <HeaderDiv>
            <TitleWrapperDiv>
                <TitleDiv>
                    <TitleLabelDiv title={pageTitle}>{pageTitle}</TitleLabelDiv>
                    {pageTitle ? (
                        <InformationOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={onToggleSubTitle}
                            title={/* 查看sql */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.header.index.viewSQL}
                        />
                    ) : null}
                </TitleDiv>

                {showSubTitle ? <SubTitleDiv title={subTitle}>{subTitle}</SubTitleDiv> : null}
            </TitleWrapperDiv>

            <Space>
                <Radio.Group
                    value={chartType}
                    buttonStyle="solid"
                    size="middle"
                    onChange={(e) => onTypeChange(e.target.value)}
                >
                    {chartTypeList.map((chart) => (
                        <Radio.Button value={chart.type} disabled={chart.disabled}>
                            <span title={chart.name}>{chart.icon}</span>
                        </Radio.Button>
                    ))}
                </Radio.Group>

                <Button
                    type="primary"
                    onClick={onInnerExport}
                    loading={exportLoading}
                    disabled={exportLoading}
                >{/* 导出 */}{UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.header.index.export}</Button>

                <Dropdown
                    overlay={
                        <Menu>
                            {statements.map((s) => {
                                return (
                                    <Menu.Item onClick={() => onAddToStatement(s.id)}>
                                        {s.name}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Button type="primary">{/* 添加到报表 */}{UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.header.index.addToReport}</Button>
                </Dropdown>
            </Space>
        </HeaderDiv>
    );
};

export default Header;
