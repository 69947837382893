import React from 'react';
import UdeskLocales from "UdeskLocales";
import Udesk from 'Udesk';
import LayoutMenu from 'Component/common/layout-menu';
import { Redirect } from 'react-router-dom';

class TasksManageEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: this.props.match.params
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let {
            route,
            location,
            sdkOptions,
            match,
        } = this.props;

        let {
            locales,
            privates,
        } = this;

        let navLists = [];
        let group = {};
        // 根据当前用户权限，组装菜单列表
        const task = sdkOptions.props.task;
        // const hasFeature = task ? task._hasFeature : null;
        let taskId = privates.model.taskId;

        group = {
            text: locales.components.tasks.subMenu.wechatQa.task.text,
            children: []
        };
        if (this.props.route.name === 'wechatQaTaskCreateEntry') {
            group.children.push({
                text: locales.pages.wechatQa.baseInfo.title,
                pathName: "wechatQaCreateBaseInfo",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/basic-info/index");
                }
            });
            navLists.push(group);
        } else {
            group.children.push({
                text: locales.pages.wechatQa.baseInfo.title,
                pathName: "wechatQaBaseInfo",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/basic-info/index");
                }
            });
            group.children.push({
                text: locales.components.tasks.subMenu.wechatQa.checkPoint.text,
                pathName: "wechatQaCheckPointEntry",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/check-point");
                }
            });
            group.children.push({
                text: locales.components.tasks.subMenu.wechatQa.automaticAdd.text,
                pathName: "wechatQaAutomaticAddIndex",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/automatic-add/index");
                }
            });
            group.children.push({
                text: locales.components.tasks.subMenu.wechatQa.manualAdd.text,
                pathName: "wechatQaManualAddIndex",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/manual-add/index");
                }
            });
            navLists.push(group);
            group = {
                text: locales.components.tasks.subMenu.qualityCheckWorkbench.wechatText,
                children: []
            };
            group.children.push({
                text: locales.components.tasks.subMenu.qualityCheckWorkbench.qualityInspectionList.wechatText,
                pathName: "wechatQualityInspectionList",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/quality-inspection-list");
                }
            });
            group.children.push({
                text: locales.components.tasks.subMenu.qualityCheckWorkbench.spotCheckList.text,
                pathName: "wechatSpotCheckList",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/spot-check-list");
                }
            });
            group.children.push({
                text: locales.components.tasks.subMenu.qualityCheckWorkbench.reviewList.text,
                pathName: "wechatReviewList",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/review-list");
                }
            });
            navLists.push(group);
            //报表
            if (task.inspectDataSource !== Udesk.enums.inspectDataSources.wechatRadio.id) {
                group = {
                    text: locales.components.tasks.subMenu.statisticsAndReports.text,
                    children: [],
                };
                
                group.children.push({
                    text: locales.components.tasks.subMenu.statisticsAndReports.inspectionItemsAnalysis.text,
                    pathName: 'wecomInspectionItemsAnalysis',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/inspection-items-analysis');
                    },
                });
                
                group.children.push({
                    text: locales.components.tasks.subMenu.statisticsAndReports.wholeReport.text,
                    pathName: 'wechatQualityInspectionWholeReport',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/whole-report');
                    },
                });

                group.children.push({
                    text: locales.components.tasks.subMenu.statisticsAndReports.agentReport.text,
                    pathName: 'wechatTasksAgentReport',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/agent-report');
                    },
                });
                if(privates.model.task.sessionType === Udesk.enums.chatTypes.groupChat.id * 1) {
                    group.children.push({
                        text: /* 企业微信群聊分析 */UdeskLocales['current'].pages.wechatQa.wechatQaTaskManagerEntry.analysisOfEnterpriseWeChatGroupChat,
                        pathName: 'groupChatAnalysis',
                        pathParams: { taskId },
                        isActive: (match, location) => {
                            return location.pathname.includes('/group-chat-analysis');
                        },
                    });
                }

                navLists.push(group);
            }
        }
        if (this.isRedirect(route, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else if (navLists.length > 0 && this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].children[0].pathName,
                pathParams: match.params
            });
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else {
            return (
                <div className="tasks-manage-entry">
                    <LayoutMenu {...this.props} navLists={navLists} menuTitle={locales.components.tasks.subMenu.wechatQa.task.text} />
                </div>
            );
        }
    }
    model() {
        const { props } = this;
        let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        if (params.taskId === '0') {
            return {};
        }
        let associateFields = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/field/task/' + params.taskId,
                props.sdkOptions
            );
            Udesk.ajax.get(url, { type: 'associate' })
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.screeningCondition));
                        reject(reason);
                    }
                );
        });

        //  任务的数据源
        let taskPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionTasks/' + params.taskId,
                props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });

        // 任务权限数据源
        let taskMenuPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionTasks/menu/' + params.taskId,
                props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });

        // 检查质检点版本
        let taskVersionPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/${params.taskId}/version`,
                props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });

        return {
            taskId: params.taskId,
            associateFields: associateFields,
            task: taskPromise,
            taskMenu: taskMenuPromise,
            taskVersion: taskVersionPromise,
        };
    }
    onModelResolved(model) {
        let task = model.task;
        if (!task) {
            return;
        }
        let features = model.taskMenu;
        let featureCodeMappings = {};
        if (features) {
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                featureCodeMappings[feature.permission] = true;
            }
        }
        if (!Object.prototype.hasOwnProperty.call(task, "_hasFeature")) {
            Object.defineProperty(task, "_hasFeature", {
                value: function (featureCode) {
                    return Object.prototype.hasOwnProperty.call(featureCodeMappings, featureCode);
                },
                enumerable: false,
                configurable: false,
                writable: false
            });
        }
        this.props.sdkOptions.props.biTaskId = parseInt(model.taskId, 10);
        this.props.sdkOptions.props.task = task;
        this.props.sdkOptions.props.basicInfoFields = model.associateFields;
    }
    componentWillUnmount() {
        // 清理掉相关数据，否则会污染其他页面
        this.props.sdkOptions.props.biTaskId = null;
        this.props.sdkOptions.props.task = null;
        this.props.sdkOptions.props.basicInfoFields = null;
    }
}

export default Udesk.react.udeskify(TasksManageEntry);