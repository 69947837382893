import React, { useState } from 'react';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import CustomFilterEdit from 'Component/pages/components/custom-filter-edit';

export const CustomFilter = (props) => {
    const { onChange, type } = props;
    const [filterVisible, setFilterVisible] = useState(false);
    const [customId, setCustomId] = useState<number>();
    const [customName] = useState('');

    const cancelHandle = () => {
        setFilterVisible(false);
    };

    const handleConditionApply = (conditionList, customJudgeLogic, judgeStrategy, id, name) => {
        onChange({
            conditionList,
            customJudgeLogic,
            judgeStrategy,
        });
        cancelHandle();
    };

    const onConditionFilterSaved = (flag, data) => {
        if (data) {
            setCustomId(data.id);
        }
    };

    return (
        <div style={props.wrapperStyle}>
            <CustomFilterModal
                visible={filterVisible}
                cancel={cancelHandle}
                applyAction={handleConditionApply}
                onConditionFilterSaved={onConditionFilterSaved}
                judgeStrategy={props.value?.judgeStrategy ?? 1}
                customJudgeLogic={props.value?.customJudgeLogic}
                customName={customName}
                customId={customId}
                conditionList={props.value?.conditionList ?? []}
                type={type}
                querryType={'autoAdd'}
                filterList={['f_8', 'f_4', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
                filterTaskData={true}
                isAutoHideOperator={true}
            />
            <CustomFilterEdit
                cusBtnProps={{ type: 'default', icon: null }}
                onClick={() => setFilterVisible(true)}
                {...props.value}
                conditionList={props.value?.conditionList || []}
                judgeStrategy={props.value?.judgeStrategy ?? 1}
            />
        </div>
    );
};
