import ArgumentNullError from '../error/argument-null-error';
import WrongTypeError from '../error/wrong-type-error';
import {
    PromiseHash,
} from '../ref-npm-modules';

export default {
    hash: PromiseHash,
    syncable,
};

async function syncable(promise) {
    if (promise == null) {
        throw new ArgumentNullError("promise");
    }
    if (typeof promise.then !== "function") {
        throw new WrongTypeError("promise", "Promise");
    }
    return await promise
        .then(data => ({ data: data }), reason => ({ error: reason }))
        .catch(reason => ({ error: reason }));
}
