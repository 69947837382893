import Locales from "../../locales/index";

export default {
    confirmDefaults: {}
};

export function confirmsDefaults() {
    let modalLocales = Locales.current.ui.widgets.modalConfirm;
    return  {
        info: {
            footer: undefined,
            cancelClass: "btn btn-default",
            okClass: "btn btn-success",
            cancelText: modalLocales.info.cancelText,
            className: "react-alerts react-alerts-info",
            content: modalLocales.info.content,
            iconType: "",
            closable: true,
            closeIconClass: "udesk-react-iconfont icon-udesk-react-close",
            maskClosable: true,
            okText: modalLocales.info.okText,
            okType: "",
            titleTipClass: "",
            title: modalLocales.info.title,
            width: "",
            zIndex: 1050,
            onOk: undefined,
            destroyOnClose: true
        },
        warning: {
            footer: undefined,
            cancelClass: "btn btn-default",
            okClass: "btn btn-success",
            cancelText: modalLocales.warning.cancelText,
            className: "udesk-bi react-alerts react-alerts-warning",
            content: modalLocales.warning.content,
            iconType: "",
            closable: true,
            closeIconClass: "udesk-react-iconfont icon-udesk-react-close",
            maskClosable: true,
            okText: modalLocales.warning.okText,
            okType: "",
            titleTipClass: "",
            title: modalLocales.warning.title,
            width: "",
            zIndex: 1050,
            onCancel: undefined,
            onOk: undefined,
            destroyOnClose: true
        },
        error: {
            footer: undefined,
            cancelClass: "btn btn-default",
            cancelText: modalLocales.error.cancelText,
            className: "react-alerts react-alerts-error",
            content: modalLocales.error.content,
            iconType: "",
            closable: true,
            closeIconClass: "udesk-react-iconfont icon-udesk-react-close",
            maskClosable: true,
            okText: modalLocales.error.okText,
            okClass: "btn btn-success",
            okType: "",
            titleTipClass: "",
            title: modalLocales.error.title,
            width: "",
            zIndex: 1050,
            onCancel: undefined,
            onOk: undefined,
            destroyOnClose: true
        },
        success: {
            footer: undefined,
            cancelClass: "btn btn-default",
            okClass: "btn btn-success",
            cancelText: modalLocales.success.cancelText,
            className: "react-alerts react-alerts-success",
            content: modalLocales.success.content,
            iconType: "",
            closable: true,
            closeIconClass: "udesk-react-iconfont icon-udesk-react-close",
            maskClosable: true,
            okText: modalLocales.success.okText,
            okType: "",
            titleTipClass: "",
            title: modalLocales.success.title,
            width: "",
            zIndex: 1050,
            onCancel: undefined,
            onOk: undefined,
            destroyOnClose: true
        },
        confirm: {
            footer: undefined,
            cancelClass: "btn btn-default",
            okClass: "btn btn-success",
            cancelText: modalLocales.confirm.cancelText,
            className: "react-alerts react-alerts-confirm",
            content: modalLocales.confirm.content,
            iconType: "",
            closable: true,
            closeIconClass: "udesk-react-iconfont icon-udesk-react-close",
            maskClosable: true,
            okText: modalLocales.confirm.okText,
            okType: "",
            titleTipClass: "udesk-react-iconfont icon-udesk-react-bi-help",
            title: modalLocales.confirm.title,
            width: "",
            zIndex: 1050,
            onCancel: undefined,
            onOk: undefined,
            destroyOnClose: true
        },
        deletion: {
            footer: undefined,
            cancelClass: "btn btn-default",
            okClass: "btn btn-success",
            cancelText: modalLocales.deletion.cancelText,
            className: "react-alerts react-alerts-deletion",
            content: modalLocales.deletion.content,
            iconType: "",
            closable: true,
            closeIconClass: "udesk-react-iconfont icon-udesk-react-close",
            maskClosable: true,
            okText: modalLocales.deletion.okText,
            okType: "",
            titleTipClass: "",
            title: modalLocales.deletion.title,
            width: "",
            zIndex: 1050,
            onCancel: undefined,
            onOk: undefined,
            destroyOnClose: true
        }
    };
}
