// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetAiModelsByIdParam,
    BaseResponseAiModelFoundResponse,
    PutAiModelsByIdParam,
    PutAiModelsByIdBody,
    BaseResponseVoid,
    DeleteAiModelsByIdQuery,
    DeleteAiModelsByIdParam,
    BaseResponseAiModelDeleteResponse,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags ai-model-controller
 * @link [GET] /aiModels/{id}
 * @param id
 */
export function getAiModelsById(
    options: ApiOptions<GetAiModelsByIdParam, never> & { segments: GetAiModelsByIdParam }
): Promise<BaseResponseAiModelFoundResponse> {
    return request<never, GetAiModelsByIdParam, never>('/aiModels/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getAiModelsById',
    });
}

/**
 * update
 *
 * @export
 * @tags ai-model-controller
 * @link [PUT] /aiModels/{id}
 * @param id
 */
export function putAiModelsById(
    data: PutAiModelsByIdBody,
    options: ApiOptions<PutAiModelsByIdParam, never> & { segments: PutAiModelsByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutAiModelsByIdBody, PutAiModelsByIdParam, never>('/aiModels/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putAiModelsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags ai-model-controller
 * @link [DELETE] /aiModels/{id}
 * @param id
 */
export function deleteAiModelsById(
    options: ApiOptions<DeleteAiModelsByIdParam, DeleteAiModelsByIdQuery> & {
        segments: DeleteAiModelsByIdParam;
    }
): Promise<BaseResponseAiModelDeleteResponse> {
    return request<never, DeleteAiModelsByIdParam, DeleteAiModelsByIdQuery>('/aiModels/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteAiModelsById',
    });
}

export const meta = [
    { tags: ['ai-model-controller'], path: '/aiModels/{id}', method: 'get' },
    { tags: ['ai-model-controller'], path: '/aiModels/{id}', method: 'put' },
    { tags: ['ai-model-controller'], path: '/aiModels/{id}', method: 'delete' },
];
