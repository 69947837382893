import React from 'react';
import TasksConditionIndexRoute from './route';
import ReactTable from 'udesk-react/src/components/react-table';
import TaskConditionDictionaries from "Component/pages/components/task-condition-dictionaries";
import { NavLink } from 'react-router-dom';
import { PageHeader } from 'udesk-ui';

export default class TasksConditionIndexTemplate extends TasksConditionIndexRoute {
    render() {
        const { locales, props, state, privates } = this;
        const hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
        return (
            <React.Fragment>
                {/* <div className="page sub-page tasks-manage-template-condition-index"> */}
                <div className='udesk-qa-web-page'>
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={locales.pages.tasks.manage.template.condition.index.name}
                    />
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root tasks-manage-template-condition-index' style={{padding: 16}}>
                            <div className="page-content">
                                <div className="x_panel clearfix">
                                    <div className="x_content clearfix">
                                        <If condition={hasFeature && hasFeature('task:template:condition:view')}>
                                            <div className="ember-table-header-bar ember-table-header-bar-striped clearfix">
                                                <div className="pull-left">
                                                    {locales.pages.tasks.manage.template.condition.index.existingConditions}
                                                </div>
                                                <If condition={hasFeature && hasFeature('task:template:condition:add')}>
                                                    <div className="pull-right">
                                                        <NavLink
                                                            to={`/site/tasks/manage/${privates.model.taskId}/template/condition/new`}
                                                            className={'action-link'}>
                                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tianjia1" title={locales.labels.create}></i>
                                                        </NavLink>
                                                    </div>
                                                </If>
                                            </div>
                                            <ReactTable columns={state.columns} items={privates.model.conditionTableModel} />
                                        </If>
                                    </div>
                                </div>
                            </div>
                            <TaskConditionDictionaries items={privates.model.conditionTableModel} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
