// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetReviewIntelligentPartnerNodePptTestLogByNodeIdParam,
    BaseResponseListIntelligentPartnerDialogNodePptTestLogFoundResponse,
} from '../../../../../../types';

/**
 * ppt测试根据nodeId查询测试记录
 *
 * @export
 * @tags intelligent-partner-dialog-node-ppt-test-log-controller
 * @link [GET] /review/intelligentPartner/node/ppt/testLog/{nodeId}
 * @param nodeId
 */
export function getReviewIntelligentPartnerNodePptTestLogByNodeId(
    options: ApiOptions<GetReviewIntelligentPartnerNodePptTestLogByNodeIdParam, never> & {
        segments: GetReviewIntelligentPartnerNodePptTestLogByNodeIdParam;
    }
): Promise<BaseResponseListIntelligentPartnerDialogNodePptTestLogFoundResponse> {
    return request<never, GetReviewIntelligentPartnerNodePptTestLogByNodeIdParam, never>(
        '/review/intelligentPartner/node/ppt/testLog/{nodeId}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodePptTestLogByNodeId',
        }
    );
}

export const meta = [
    {
        tags: ['intelligent-partner-dialog-node-ppt-test-log-controller'],
        path: '/review/intelligentPartner/node/ppt/testLog/{nodeId}',
        method: 'get',
    },
];
