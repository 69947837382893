import React, { useCallback, useRef, useState } from 'react';
import Udesk from 'Udesk';
import { Button, Col, Row, Space } from 'udesk-ui';
import { useRequest } from 'src/util/hook';
import { linkTo } from 'src/util/link';
import { linkToStart } from '../../components/util';
import { getReviewIntelligentPartnerCourseById } from 'src/api/review/intelligentPartner/course/{id}';
import { getIntelligentPartnerByTaskIdPageDetail } from 'src/api/intelligentPartner/{taskId}/pageDetail';
import Page from 'Component/common/udesk-qa-page-layout';
import SafeHtml from 'Component/common/safe-html';
import DeviceModal, { RefType } from '../../components/device-modal';
import UdeskLocales from 'UdeskLocales';
import './index.less';

const Template = React.memo((props: any) => {
    const { history, match } = props;
    const id = match.params.id;
    const [course, setCourse] = useState<any>(null);
    const [taskDetail, setTaskDetail] = useState<any>(null);

    const queryParams = new URLSearchParams(props.location.search);
    const taskId = queryParams.get('taskId');
    const modalRef = useRef<RefType>(null);

    // 获取课程信息
    useRequest({
        manual: !id,
        defaultApi: getReviewIntelligentPartnerCourseById,
        defaultParams: [
            {
                segments: { id },
            },
        ],
        onSuccess(resp) {
            setCourse(resp.data);
        },
    });

    // 获取任务详情信息
    useRequest({
        manual: !taskId,
        defaultApi: getIntelligentPartnerByTaskIdPageDetail,
        defaultParams: [
            {
                segments: { taskId },
            },
        ],
        onSuccess(resp) {
            setTaskDetail(resp.data);
        },
    });

    // // 获取课程信息
    // useLiveEffect((success) => {
    //     if (id) {
    //         request(`/review/intelligentPartner/course/${id}`).then(
    //             resp => {
    //                 success(() => {
    //                     setCourse(resp.data);
    //                 });
    //             }
    //         );
    //     }
    // }, [request, id]);

    // // 获取任务详情信息
    // useLiveEffect((success) => {
    //     if (taskId) {
    //         request(`/intelligentPartner/${taskId}/pageDetail`).then(
    //             resp => {
    //                 success(() => {
    //                     setTaskDetail(resp.data);
    //                 });
    //             }
    //         );
    //     }
    // }, [request, taskId]);

    const cancelTaskHandle = useCallback(() => {
        if (taskId) {
            linkTo(history, 'coachLearningCenterExerciseTask');
        } else {
            linkTo(history, 'coachLearningCenterCourse');
        }
    }, [history, taskId]);

    const startTaskHandle = useCallback(() => {
        if (taskId) {
            modalRef.current?.open({
                task: { ...taskDetail, id: taskId },
                taskScoreDetail: taskDetail.taskScoreDetail,
            });
        } else {
            linkTo(history, 'coachLearningCenterExerciseTask', {}, {}, { lessonId: id });
        }
    }, [taskId, taskDetail, history, id]);

    const modalSubmit = useCallback(() => {
        linkToStart(
            history,
            taskDetail?.taskType,
            taskDetail?.dialogueMode,
            {
                id: taskId,
            },
            {},
            { lessonId: id }
        );
    }, [history, id, taskDetail?.dialogueMode, taskDetail?.taskType, taskId]);

    return (
        <Page
            pageClassName="learning-center-learn"
            pageBodyClassName="learning-center-learn-body"
            backGroundGhost={true}
            onBack={cancelTaskHandle}
            title={course?.name}
        >
            <Space direction="vertical" className="course-info">
                <div className="course-name">
                    <span>
                        {/* 课程名称 */}
                        {
                            UdeskLocales['current'].pages.coach.learningCenter.task.learn.index
                                .courseName
                        }
                    </span>
                    <span>{course?.name}</span>
                </div>
                <div className="course-introduce">
                    <span>
                        {/* 课程介绍 */}
                        {
                            UdeskLocales['current'].pages.coach.learningCenter.task.learn.index
                                .courseIntroduction
                        }
                    </span>
                    <span>{course?.introduce}</span>
                </div>
            </Space>
            <Row gutter={16}>
                <Col span={18}>
                    <div className="course-content">
                        <div>
                            {/* 学习内容 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.task.learn.index
                                    .learningContent
                            }
                        </div>
                        <SafeHtml className={'safe-html-swap'} content={course?.learningContent} />
                    </div>
                </Col>
                <Col span={6}>
                    <div className="course-download">
                        {course?.learningMaterialList?.length > 0 && (
                            <>
                                <div>
                                    {/* 学习资料下载 */}
                                    {
                                        UdeskLocales['current'].pages.coach.learningCenter.task
                                            .learn.index.downloadLearningMaterials
                                    }
                                </div>
                                <Space direction="vertical" size={0} className={'file-link-panel'}>
                                    {course?.learningMaterialList?.map((item) => {
                                        return (
                                            <Button
                                                className="file-link"
                                                type="link"
                                                onClick={() => {
                                                    window.open(item.downLoadPath);
                                                }}
                                            >
                                                {item.name}
                                            </Button>
                                        );
                                    })}
                                </Space>
                            </>
                        )}
                        <DeviceModal ref={modalRef} modalSubmit={modalSubmit}></DeviceModal>
                        <Button
                            disabled={
                                [
                                    Udesk.enums.learningTaskStatusType.loseEfficacy.id,
                                    Udesk.enums.learningTaskStatusType.notStarted.id,
                                    Udesk.enums.learningTaskStatusType.finished.id,
                                ].includes(taskDetail?.taskStatus) // TODO taskDetail没有taskStatus属性，需要后端添加
                            }
                            className="start-task-btn"
                            type="primary"
                            onClick={startTaskHandle}
                        >
                            {/* 开始练习 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.task.learn.index
                                    .startPracticing
                            }
                        </Button>
                    </div>
                </Col>
            </Row>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
