import React from 'react';
import LinkToComponent from './component';
import { NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import ReactTool from '../react-field-tool/base';

export default class LinkToTemplate extends LinkToComponent {
    render() {
        let { actions } = this;
        let {
            textValueField,
            navItemClassNames,
            activeClassNames,
            tooltipValue,
            linkConfig,
            CustomReactTool,
            CustomReactToolExportUrl
        } = this.props;
        let itemOptions = actions.getItemOptions(linkConfig);

        return (
            <If condition={itemOptions.isVisible && itemOptions.routePath}>
                <NavLink
                    exact={itemOptions.exact}
                    strict={itemOptions.strict}
                    to={itemOptions.routePath}
                    className={ClassNames("report-nav-bar-item", { [`${navItemClassNames}`]: navItemClassNames }, { [`has-tooltip`]: itemOptions.hasTooltipPropery })}
                    activeClassName={`report-nav-bar-item-active ${activeClassNames}`}
                    isActive={itemOptions.isActive} onClick={this.actions.onClicked.params(linkConfig)}>
                    {linkConfig[textValueField]}
                    <If condition={itemOptions.hasTooltipPropery && linkConfig[tooltipValue]}>
                        <If condition={!CustomReactTool}>
                            <ReactTool classNames="nav-bar-item-tooltip" tooltipData={linkConfig[tooltipValue]} />
                        </If>
                        <If condition={CustomReactTool}>
                            <CustomReactTool classNames="nav-bar-item-tooltip" tooltipData={linkConfig[tooltipValue]} tooltipExportUrl={CustomReactToolExportUrl} ></CustomReactTool>
                        </If>
                    </If>
                </NavLink>
            </If>
        );
    }
}