import React, { useState, useEffect, useRef } from 'react';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Icon, Input, Popconfirm, Tag as UdeskUiTag } from 'udesk-ui';
import {
    putIntelligentPartnerTagsById,
    deleteIntelligentPartnerTagsById,
} from 'src/api/intelligentPartnerTags/{id}';
import UdeskLocales from 'UdeskLocales';
// import './style.scss';

export const Tag = React.memo((props: any) => {
    const { groupId, children, onSubmit, id } = props;
    // const locales = Locales['current'];
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState(children);
    const inputRef = useRef<any>(null);
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputConfirm = (submit) => {
        // if (inputValue && tags.indexOf(inputValue) === -1) {
        //     setTags([...tags, inputValue]);
        // }
        if (submit) {
            putIntelligentPartnerTagsById(
                {
                    groupId,
                    tagName: inputValue,
                },
                {
                    segments: {
                        id,
                    },
                }
            ).then(() => {
                setInputVisible(false);
                onSubmit();
            });
        } else {
            setInputVisible(false);
        }
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };
    const handleClose = () => {
        deleteIntelligentPartnerTagsById({
            segments: {
                id,
            },
        }).then(() => {
            onSubmit();
        });
    };
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    return (
        <React.Fragment>
            {inputVisible && (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={{
                        width: 78,
                        margin: 4,
                        verticalAlign: 'top',
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm.bind(null, false)}
                    onPressEnter={handleInputConfirm.bind(null, true)}
                />
            )}
            {!inputVisible && (
                <UdeskUiTag
                    className="site-tag-plus"
                    onClick={showInput}
                    closable={true}
                    onClose={(e) => {
                        e.preventDefault();
                    }}
                    closeIcon={
                        <Popconfirm
                            title={/* 删除后无法恢复，确定要删除此标签吗？ */UdeskLocales['current'].pages.coach.customerTagsManagement.components.tag.index.afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel}
                            onConfirm={handleClose}
                            // onCancel={cancel}
                            okText={/* 删除 */UdeskLocales['current'].pages.coach.customerTagsManagement.components.tag.index.delete}
                            cancelText={/* 取消 */UdeskLocales['current'].pages.coach.customerTagsManagement.components.tag.index.cancel}
                        >
                            <Icon type="CloseOutlined" antdIcon={true} />
                        </Popconfirm>
                    }
                >
                    <span
                        onDoubleClick={(e) => {
                            showInput();
                            e.preventDefault();
                        }}
                    >
                        {children}
                    </span>
                </UdeskUiTag>
            )}
        </React.Fragment>
    );
});
