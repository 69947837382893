import { ScoreDefaultDes, ScorePoint } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

export const getEchartDataFromData = (data?: ScoreDefaultDes, scorePointList?: ScorePoint[]) => {
    let res: any[] = [];
    let detail: any[] = [];

    if (!data) {
        return {
            res,
            detail,
        };
    }

    // "totalFlows": 3,// 总流程数，对应type 1-内容完整
    // "finishFlows": 3,// 完成流程数，对应type 1-内容完整
    // "totalPoints": 5,// 总要点数， 对应type 2-要点命中-得分 或 3-要点命中-权重
    // "commitPoints": 3,// 完成要点数，对应type 2-要点命中-得分 或 3-要点命中-权重
    // "totalWordNum": null,// 总的话术字数，对应type 4-跟读准确
    // "commitWordNum": null,// 命中的话术字数，对应type 4-跟读准确
    // "violations": 0,// 违规次数，对应type 5-违规词
    // "violationDeduction": 0,// 违规扣除，对应type 5-违规词
    // "speedFastTimes": 0,// 语速过快次数，对应type 6-语速
    // "speedSlowTimes": 0,// 语速过慢次数，对应type 6-语速
    // "modalParticleTooMuchTimes": 0,// 语气词过多次数，对应type 7-语气词
    // "negativeEmotions": 0 // 消极情绪次数，对应type 8-情绪

    res = (scorePointList?.map((p) => (p.percent ?? 100) / 100) || []).map((i) =>
        isNaN(i) ? 0 : (i * 100) >>> 0
    );

    detail =
        scorePointList?.reduce((res, point) => {
            switch (point.type) {
                case 1: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 完整流程数 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .spanStylemarginLeftpxNumberOfCompleteProcesses
                        }${data.finishFlows ?? '-'}/${data.totalFlows ?? '-'}</span>`
                    );
                    break;
                }
                case 3:
                case 2: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 命中要点数 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .spanStylemarginLeftpxNumberOfHitPoints
                        }${data.commitPoints ?? '-'}/${data.totalPoints ?? '-'}</span>`
                    );
                    break;
                }
                case 4: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 标准话术累积 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .spanStylemarginLeftpxStandardScriptAccumulation
                        }${data.totalWordNum}${
                            /* 个字，学员跟读命中的字数共 */ UdeskLocales['current'].pages.coach
                                .learningCenter.record.detail.components.comprehensiveEvaluation
                                .index.theTotalNumberOfWordsHitByTheStudentAfterReading
                        }${data.commitWordNum}${
                            /* 个字</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.aWordspan
                        }`
                    );
                    break;
                }
                case 5: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 有 */ UdeskLocales['current'].pages
                                .coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxYes
                        }${data.violations ?? '-'}${
                            /* 处违规词</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index
                                .dealingWithIllegalWordsspan
                        }`
                    );
                    break;
                }
                case 6: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 语速过快 */ UdeskLocales['current']
                                .pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxSpeakTooFast
                        }${data.speedFastTimes ?? '-'}${
                            /* 次</span><br /><span style="margin-left: 8px">· 语速过慢 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index
                                .timesspanbrspanStylemarginLeftpxSpeakTooSlowly
                        }${data.speedSlowTimes ?? '-'}${
                            /* 次</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.timesspan
                        }`
                    );
                    break;
                }
                case 7: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 语气词过多 */ UdeskLocales[
                                'current'
                            ].pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.tooManyModalParticles
                        }${data.modalParticleTooMuchTimes ?? '-'}${
                            /* 次</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.timesspan
                        }`
                    );
                    break;
                }
                case 8: {
                    res.push(
                        `${
                            /* <span style="margin-left: 8px">· 负面情绪 */ UdeskLocales['current']
                                .pages.coach.learningCenter.record.detail.components
                                .comprehensiveEvaluation.index.spanStylemarginLeftpxNegativeEmotions
                        }${data.negativeEmotions ?? '-'}${
                            /* 次</span> */ UdeskLocales['current'].pages.coach.learningCenter
                                .record.detail.components.comprehensiveEvaluation.index.timesspan
                        }`
                    );
                    break;
                }
            }
            return res;
        }, [] as any[]) || [];

    return {
        res,
        detail,
    };
};

export const getItem = (color: string, name: string) => {
    return (value: any[]) => {
        return {
            name,
            value,
            symbol: 'circle',
            symbolSize: 5,
            label: {
                normal: {
                    show: true,
                    position: 'top',
                    distance: 5,
                    fontSize: 14,
                    formatter: function (params) {
                        return params.value;
                    },
                },
            },
            lineStyle: {
                normal: {
                    opacity: 0.5,
                },
            },
            areaStyle: {
                color,
            },
        };
    };
};
