import React from 'react';
import Udesk from 'Udesk';
import { Switch, Button, Popconfirm } from 'udesk-ui';
const PAGING = {
    pageSize: 20,
    total: 0,
    pageNum: 1,
};
class TemplateComponentsListComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        paging: {
            ...PAGING,
        },
        targetItems: [],
        tableData: [],
        keywords: '',
        releaseStatus: Udesk.enums.templateShowable.false.id,
        needUpdate: Udesk.enums.templateNeedUpdate.all.id,
        disabled: false,
        order: 'updateTime',
        moduleSourceType: null,
        moduleType: null,
        moduleTypesList: [],
    };

    actions = {
        search() {
            this.actions.reloadAsyncModel();
        },
        updatChange(value) {
            this.privates.needUpdate = value;
            this.actions.update();
        },
        moduleTypeChange(value) {
            this.privates.moduleType = value;
            this.actions.update();
        },
        inputChange(e) {
            this.privates.keywords = e.target.value;
            this.actions.update();
        },
        reset() {
            this.privates.paging = {
                ...PAGING,
            };
            this.privates.keywords = '';
            this.privates.releaseStatus = Udesk.enums.templateShowable.false.id;
            this.privates.needUpdate = Udesk.enums.templateNeedUpdate.all.id;
            this.privates.moduleSourceType = null;
            this.privates.moduleType = null;
            this.actions.update();
        },
        pageNumChange(pageNum) {
            this.privates.paging.pageNum = pageNum;
            this.actions.reloadAsyncModel();
        },
        pageSizeChange(pageNum, pageSize) {
            this.privates.paging.pageNum = pageNum;
            this.privates.paging.pageSize = pageSize;
            this.actions.reloadAsyncModel();
        },
        inputSearch(value) {
            this.privates.keywords = value;
            this.actions.reloadAsyncModel();
        },
        selectChange(value) {
            this.privates.releaseStatus = value;
            this.actions.update();
        },
        goDetail(field) {
            let routeOptions = {
                history: this.props.history,
                routeName: 'templateComponentListDetail',
                pathParams: {
                    id: field.moduleId,
                },
                queryParams: {
                    version: field.lastedVersion ? field.lastedVersion : field.nowVersion,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        delChange(id) {
            let { sdkOptions } = this.props;
            let { locales } = this;
            let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionModules/${id}`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.del(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(locales.pages.admin.templateComponent.list.delete);
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        onlineChange(id) {
            let { sdkOptions } = this.props;
            let { locales } = this;
            let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionModules/${id}/offline`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.put(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        Udesk.ui.notify.success(locales.pages.admin.templateComponent.list.downOk);
                        this.actions.reloadAsyncModel();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        goBack(id) {
            let { sdkOptions } = this.props;
            let { locales } = this;
            if (id) {
                let myDetail = Udesk.business.apiPath.concatApiPath(`inspectionModules/${id}/cancel`, sdkOptions);
                new Promise((resolve, reject) => {
                    Udesk.ajax.put(myDetail).then(
                        (resp) => {
                            resolve(resp);
                            Udesk.ui.notify.success(locales.pages.admin.templateComponent.list.withdraw);
                            this.actions.reloadAsyncModel();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
        getStatus(record, value) {
            this.privates.disabled = true;
            this.actions.update();
            let { sdkOptions } = this.props;
            let { locales } = this;
            let myDetail = Udesk.business.apiPath.concatApiPath(
                `inspectionModules/${record}/status/${value}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.put(myDetail).then(
                    (resp) => {
                        if (value === Udesk.enums.templateModuleStatus.delete.id) {
                            Udesk.ui.notify.success(locales.pages.admin.templateComponent.list.delete);
                        }
                        if (value === Udesk.enums.templateModuleStatus.update.id) {
                            Udesk.ui.notify.success(locales.pages.admin.templateComponent.list.updateOk);
                        }
                        if (value === Udesk.enums.templateModuleStatus.status.id) {
                            Udesk.ui.notify.success(locales.pages.admin.templateComponent.list.getOk);
                        }
                        this.privates.disabled = false;
                        this.actions.reloadAsyncModel();
                        resolve(resp);
                    },
                    (reason) => {
                        this.privates.disabled = false;
                        this.actions.reloadAsyncModel();
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        switchChange(record, checked) {
            this.actions.getStatus(
                record,
                checked ? Udesk.enums.templateModuleStatus.open.id : Udesk.enums.templateModuleStatus.disabled.id
            );
        },
        filterChange(type, e) {
            this.privates[type] = e.target.value;
            this.actions.update();
            if(type === 'order'){
                this.actions.reloadAsyncModel();
            }
        }
    };

    //#region Life Cycle
    componentDidMount() {
        getColumn(this);
        let moduleTypesList = Udesk.enums.templateTypeList.map(i => i);
        moduleTypesList.unshift({name: this.locales.labels.all, id: null});
        this.privates.moduleTypesList = moduleTypesList;
        this.actions.update();
    }
    componentWillUnmount() {}
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'taskData') {
            this.privates.tableData = asyncResult.data;
            this.privates.paging = asyncResult.paging;
            this.actions.update();
        }
    }

    //#endregion
}
function getColumn(that) {
    let hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    let { locales } = that;
    let column = [
        {
            title: locales.pages.admin.templateComponent.list.name,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: locales.pages.admin.templateComponent.list.moduleType,
            dataIndex: 'moduleType',
            key: 'moduleType',
            render: (text, record, index) => {
                return text ? Udesk.enums.templateTypeList.getName(text) : '-';
            }
        },
        {
            title: locales.pages.admin.templateComponent.list.moduleSourceType,
            dataIndex: 'moduleSourceType',
            key: 'moduleSourceType',
            render: (text, record, index) => {
                const allType = `${Udesk.enums.inspectDataSources.voiceCall.name}、${Udesk.enums.inspectDataSources.textDialogue.name}`;
                return typeof text === 'number' ? text === 0 ? allType : Udesk.enums.inspectDataSources.getName(text) : '-';
            }
        },
        {
            title: locales.pages.admin.templateComponent.list.fetchNum,
            dataIndex: 'fetchNum',
            key: 'fetchNum',
        },
        {
            title: locales.pages.admin.templateComponent.list.nowVersion,
            dataIndex: 'nowVersion',
            key: 'nowVersion',
        },
        {
            title: locales.pages.admin.templateComponent.list.lastedVersion,
            dataIndex: 'lastedVersion',
            key: 'lastedVersion',
        },
        {
            title: locales.pages.admin.templateComponent.list.appStatus,
            dataIndex: 'releaseStatus',
            key: 'releaseStatus',
            render: (text, record, index) => {
                if (record.id) {
                    if (record.moduleStatus) {
                        return (
                            <Switch
                                onChange={that.actions.switchChange.params(record.id)}
                                defaultChecked={
                                    record.moduleStatus === Udesk.enums.templateModuleStatus.open.id
                                        ? true
                                        : record.moduleStatus === Udesk.enums.templateModuleStatus.disabled.id && false
                                }
                            />
                        );
                    }
                } else {
                    return (
                        <Button type='link' onClick={that.actions.getStatus.params(record.moduleId, 1)}>
                            {locales.pages.admin.templateComponent.list.get}
                        </Button>
                    );
                }
            },
        },
        {
            title: locales.pages.admin.templateComponent.list.actions,
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record, index) => {
                return (
                    <div>
                        <If condition={hasFeature('sys:module:list:edit')}>
                            {record.online ? (
                                <Button
                                    style={{ paddingLeft: 0 }}
                                    type='link'
                                    onClick={that.actions.onlineChange.params(record.id)}
                                >
                                    {locales.pages.admin.templateComponent.list.down}
                                </Button>
                            ) : null}
                            {record.updateFlag === Udesk.enums.templateModuleStatus.status.id && (
                                <Button
                                    style={{ paddingLeft: 0 }}
                                    type='link'
                                    onClick={that.actions.getStatus.params(record.id, Udesk.enums.templateModuleStatus.update.id)}
                                >
                                    {locales.pages.admin.templateComponent.list.updateTop}
                                </Button>
                            )}
                            <Button
                                style={{
                                    paddingLeft:
                                        !record.online &&
                                        record.updateFlag !== Udesk.enums.templateModuleStatus.status.id &&
                                        0,
                                }}
                                type='link'
                                onClick={that.actions.goDetail.params(record)}
                            >
                                {locales.pages.admin.templateComponent.list.check}
                            </Button>
                            {record.moduleStatus && record.moduleStatus === Udesk.enums.templateModuleStatus.disabled.id ? (
                                <Popconfirm
                                    title={locales.pages.admin.templateComponent.list.deleteWhen}
                                    onConfirm={that.actions.getStatus.params(record.id, Udesk.enums.templateModuleStatus.delete.id)}
                                    onText={locales.pages.admin.templateComponent.list.yes}
                                    cancelText={locales.pages.admin.templateComponent.list.noo}
                                >
                                    <Button type='link'>{locales.pages.admin.templateComponent.list.deleteOk}</Button>
                                </Popconfirm>
                            ) : null}
                        </If>
                    </div>
                );
            },
        },
    ];
    that.privates.targetItems = column;
    that.actions.update();
}

export default TemplateComponentsListComponent;
