import React from 'react';
import Udesk from 'Udesk';
import CallManageIndexRoute from './route';
import FilterManage from 'Component/pages/components//filter-manage';
import ListManage from 'Component/pages/components//list-manage';
import './style.scss';

export default class CallManageIndexTemplate extends CallManageIndexRoute {
    render() {
        let { filterVisible, isUpdate, keyword } = this.privates;
        let { selectedFilter } = this.privates.storages;
        let { actions, locales } = this;
        return (
            // <div className="row qa-react-page call-manage-page">
            <div className='udesk-qa-web-page'>
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root call-manage-page'>
                        <div className='qa-react-page-left-part call-manage-page-left-part col-md-2 col-sm-3 col-xs-12'>
                            <FilterManage
                                ref={this.privates.filterManageRef}
                                type={Udesk.enums.filterTypes.wechatAll.id}
                                filterManageTitle={locales.components.pages.weChatManage.title}
                                onEditFilter={actions.editFilter}
                                allFilterTitle={locales.components.pages.components.filterManage.allWechat}
                                onSelectedFilterChanged={actions.onSelectedFilterChanged}
                                isUpdate={isUpdate}
                            />
                        </div>
                        <div className='qa-react-page-right-part call-manage-page-right-part col-md-10 col-sm-9 col-xs-12'>
                            <ListManage
                                title={locales.components.pages.weChatManage.listTitle}
                                cacheKey='wechat.index'
                                searchPlaceHolder={locales.labels.searchPlaceHolder}
                                type={Udesk.enums.filterTypes.wechatAll.id}
                                editFilterVisible={filterVisible}
                                onEditFilterVisibleChanged={actions.onEditFilterVisibleChanged}
                                onViewItemDetail={actions.onViewItemDetail}
                                onPageChanged={actions.onPageChanged}
                                selectedFilter={selectedFilter}
                                onConditionFilterSaved={actions.onConditionFilterSaved}
                                keyword={keyword}
                                hiddenExportBtn={true}
                                isWechatQa={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
