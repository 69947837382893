// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetInspectionPointSystemDialogsByIdParam,
    BaseResponseInspectionPointSystemDialogFoundResponse,
    PutInspectionPointSystemDialogsByIdParam,
    PutInspectionPointSystemDialogsByIdBody,
    BaseResponseVoid,
    DeleteInspectionPointSystemDialogsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 应用消息类型
 * @link [GET] /inspectionPointSystemDialogs/{id}
 * @param id
 */
export function getInspectionPointSystemDialogsById(
    options: ApiOptions<GetInspectionPointSystemDialogsByIdParam, never> & {
        segments: GetInspectionPointSystemDialogsByIdParam;
    }
): Promise<BaseResponseInspectionPointSystemDialogFoundResponse> {
    return request<never, GetInspectionPointSystemDialogsByIdParam, never>(
        '/inspectionPointSystemDialogs/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionPointSystemDialogsById' }
    );
}

/**
 * update
 *
 * @export
 * @tags 应用消息类型
 * @link [PUT] /inspectionPointSystemDialogs/{id}
 * @param id
 */
export function putInspectionPointSystemDialogsById(
    data: PutInspectionPointSystemDialogsByIdBody,
    options: ApiOptions<PutInspectionPointSystemDialogsByIdParam, never> & {
        segments: PutInspectionPointSystemDialogsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutInspectionPointSystemDialogsByIdBody,
        PutInspectionPointSystemDialogsByIdParam,
        never
    >('/inspectionPointSystemDialogs/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putInspectionPointSystemDialogsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 应用消息类型
 * @link [DELETE] /inspectionPointSystemDialogs/{id}
 * @param id
 */
export function deleteInspectionPointSystemDialogsById(
    options: ApiOptions<DeleteInspectionPointSystemDialogsByIdParam, never> & {
        segments: DeleteInspectionPointSystemDialogsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteInspectionPointSystemDialogsByIdParam, never>(
        '/inspectionPointSystemDialogs/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteInspectionPointSystemDialogsById',
        }
    );
}

export const meta = [
    { tags: ['应用消息类型'], path: '/inspectionPointSystemDialogs/{id}', method: 'get' },
    { tags: ['应用消息类型'], path: '/inspectionPointSystemDialogs/{id}', method: 'put' },
    { tags: ['应用消息类型'], path: '/inspectionPointSystemDialogs/{id}', method: 'delete' },
];
