// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostReviewIntelligentPartnerNodeScoreRuleCheckBody,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 手动校验得分规则
 *
 * @export
 * @tags 智能陪练-对话阶段得分规则
 * @link [POST] /review/intelligentPartner/node/scoreRule/check
 */
export function postReviewIntelligentPartnerNodeScoreRuleCheck(
    data: PostReviewIntelligentPartnerNodeScoreRuleCheckBody
): Promise<BaseResponseVoid>;
export function postReviewIntelligentPartnerNodeScoreRuleCheck(
    data: PostReviewIntelligentPartnerNodeScoreRuleCheckBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postReviewIntelligentPartnerNodeScoreRuleCheck(
    data: PostReviewIntelligentPartnerNodeScoreRuleCheckBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostReviewIntelligentPartnerNodeScoreRuleCheckBody, never, never>(
        '/review/intelligentPartner/node/scoreRule/check',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeScoreRuleCheck',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话阶段得分规则'],
        path: '/review/intelligentPartner/node/scoreRule/check',
        method: 'post',
    },
];
