import React from 'react';
import PropTypes from 'prop-types';
import Udesk from 'Udesk';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';

class MassagePushConfigurationComponent extends React.Component {
    //#region Default Props
    static propTypes = {
        type: PropTypes.number,
        onPushTypesChange: PropTypes.func,
    };
    static defaultProps = {
        type: Udesk.enums.messageType.trigger.id,
        onPushTypesChange: null,
    };
    //#endregion

    state = {

    };
    privates = {
        messagePushType: Udesk.enums.messagePushType.filter(item => Boolean(item.id)),
        // tinymcePath: '/static/vendor/tinymce/tinymce.min.js',
        inputValue: '',
        checkedTypesOpened: null,
        targets: [],
        showCustomize: false,
        showAssociate: false,
        textAreaRef: React.createRef(),
        associateDivRef: React.createRef(),
        showAssociateTimer: null,
        associateActiveTab: 'data',
        operatorList: [],
        associateList: [],
        associateOperatorList: [],
        associateOperatorListSource: [],
        associateListSource: [],
        associateListSearchValue: '',
        associateOperatorIconList: [
            {
                subType: 0,
                icon: 'hanshu',
            },
            {
                subType: 1,
                icon: 'jia',
            },
            {
                subType: 2,
                icon: 'jian',
            },
            {
                subType: 3,
                icon: 'cheng',
            },
            {
                subType: 4,
                icon: 'chu',
            },
            {
                subType: 21,
                icon: 'dengyu',
            },
            {
                subType: 22,
                icon: 'dayu',
            },
            {
                subType: 23,
                icon: 'dayudengyu',
            },
            {
                subType: 24,
                icon: 'xiaoyu',
            },
            {
                subType: 25,
                icon: 'xiaoyudengyu',
            },
            {
                subType: 51,
                icon: 'yu',
            },
            {
                subType: 52,
                icon: 'huo',
            },
            {
                subType: 53,
                icon: 'fei',
            },
        ],
    };
    static computes = {

    };
    actions = {
        onCheckedTypesChange(id, e) {
            let checked = e.target.checked;
            e.stopPropagation && e.stopPropagation();
            let { checkedTypes: newCheckedTypes = [] } = this.props;
            if (checked) {
                newCheckedTypes.push(id);
            } else {
                newCheckedTypes = newCheckedTypes.filter((item) => item !== id);
            }
            this.props.onPushTypesChange && this.props.onPushTypesChange(newCheckedTypes);
        },
        onTinymceChange(actionType, value) {
            let inputValue = value;
            if (value.target) {
                inputValue = value.target.value;
            }
            let newPushConf = this.actions.setActionDetailsValue('content', inputValue, actionType);
            // this.props.onPushConfChange(newPushConf);
            this.trigger('onPushConfChange', newPushConf);
        },
        onInputChange(actionType, key, e) {
            let inputValue = e;
            if (e.target) {
                inputValue = e.target.value;
            }
            let newPushConf = this.actions.setActionDetailsValue(key, inputValue, actionType);
            // this.props.onPushConfChange(newPushConf);
            this.trigger('onPushConfChange', newPushConf);
        },
        onAdditionalDataChange(id, key, actionType, e) {
            let inputValue = e;
            let privates = this.privates;
            if (e.target) {
                inputValue = e.target.value;
            }
            for (let i = 0; i < privates.pushConf.length; i++) {
                if (privates.pushConf[i].actionType === Udesk.enums.messagePushType.interfacePush.id) {
                    privates.pushConf[i].actionDetails.additionalDataList.forEach(item => {
                        if (item._id === id) {
                            item[key] = inputValue;
                        }
                    });
                    break;
                }
            }
            // let newPushConf = this.actions.setActionDetailsValue(key, inputValue, actionType);
            // this.props.onPushConfChange(newPushConf);
            this.trigger('onPushConfChange', privates.pushConf);
        },
        addAdditionalData() {
            let privates = this.privates;
            for (let i = 0; i < privates.pushConf.length; i++) {
                if (privates.pushConf[i].actionType === Udesk.enums.messagePushType.interfacePush.id) {
                    privates.pushConf[i].actionDetails.additionalDataList.push({
                        _id: Udesk.utils.string.uuid(),
                        key: "",
                        value: ""
                    });
                    break;
                }
            }
            // let newPushConf = this.actions.setActionDetailsValue(key, inputValue, actionType);
            // this.props.onPushConfChange(newPushConf);
            this.trigger('onPushConfChange', privates.pushConf);
        },
        deleteAdditionalData(index, item) {
            let id = item.childId;
            let privates = this.privates;
            for (let i = 0; i < privates.pushConf.length; i++) {
                if (privates.pushConf[i].actionType === Udesk.enums.messagePushType.interfacePush.id) {
                    let additionalDataList = privates.pushConf[i].actionDetails.additionalDataList.filter(item => item._id !== id);
                    privates.pushConf[i].actionDetails.additionalDataList = additionalDataList;
                    break;
                }
            }
            this.trigger('onPushConfChange', privates.pushConf);
        },
        onCollapseChange(keys) {
            let { pushConf } = this.props;
            pushConf.forEach(item => {
                let key = item.actionType;
                if (keys.includes(key.toString())) {
                    item._isOpen = true;
                } else {
                    item._isOpen = false;
                }
            });
            this.props.onPushConfChange(pushConf);
        },
        setActionDetailsValue(key, value, actionType) {
            let { pushConf } = this.privates;
            let newPushConf = pushConf.map(item => {
                if (item.actionType === actionType) {
                    if (!item.actionDetails) {
                        item.actionDetails = {};
                    }
                    item.actionDetails[key] = value;
                }
                return {...item};
            });
            return newPushConf;
        },
        onTargetsChange(actionType, checkedValue) {
            let newPushConf = this.actions.setActionDetailsValue('pushTargetList', checkedValue, actionType);
            this.trigger('onPushConfChange', newPushConf);
        },
        onSdkPushTypeChange(actionType, e) {
            let type = e.target.value;
            let newPushConf = this.actions.setActionDetailsValue('type', type, actionType);
            let content = '';
            if (type === Udesk.enums.sdkPushType.dingDing.id) {
                const demoText = /* 我就是我, 是不一样的烟火@156xxxx8827 */this.locales.components.massagePushConfiguration.component.iAmMeItsADifferentFireworksxxxx8827;
                content = `
                {
                    "msgtype": "text", 
                    "text": {
                        "content": "${demoText}"
                    }, 
                    "at": {
                        "atMobiles": [
                            "156xxxx8827", 
                            "189xxxx8325"
                        ], 
                        "isAtAll": false
                    }
                }`;
            }
            if (type === Udesk.enums.sdkPushType.weChat.id) {
                const demoText = /* Hi，我是机器人质检预警消息通知，由于10月17日添加到群 */this.locales.components.massagePushConfiguration.component.hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober;
                content = `
                {
                    "msgtype": "text",
                    "text": {
                        "content": "${demoText}"
                    }
                }`;
            }
            if (type === Udesk.enums.sdkPushType.customer.id) {
                content = `
                {
                    "key": "value"
                }`;
            }
            if (content && isJSON(content)) {
                content = JSON.stringify(JSON.parse(content), null, 2);
            }
            newPushConf = this.actions.setActionDetailsValue('content', content, actionType);
            newPushConf = this.actions.setActionDetailsValue('url', '', actionType);
            this.trigger('onPushConfChange', newPushConf);
        },
        onSelectChange(type, actionType, value) {
            let newPushConf = this.actions.setActionDetailsValue(type, value, actionType);
            this.trigger('onPushConfChange', newPushConf);
        },
        handleEditorChange(actionType, editorState) {
            // let newPushConf = this.actions.setActionDetailsValue('content', editorState.toHTML(), actionType);
            let { pushConf } = this.privates;
            pushConf.forEach(item => {
                if (item.actionType === actionType) {
                    if (!item.actionDetails) {
                        item.actionDetails = {};
                    }
                    item.actionDetails._editorState = editorState;
                    item.actionDetails.content = editorState.toHTML();
                }
            });
            this.trigger('onPushConfChange', pushConf);
        },
        showCustomize(pushConf, e) {
            this.privates.showCustomize = e.target.checked;
            if(!e.target.checked){
                this.actions.onSelectChange('pushUserList', pushConf.actionType, []);
                this.actions.onSelectChange('pushRoleList', pushConf.actionType, []);    
            }
            this.actions.update();
        },
        onPushObjectTypeChange(actionType, e){
            let checked = e.target.checked;
            let checkedValue = Udesk.enums.pushObjectTypes.staff.id;
            if(checked){
                checkedValue = Udesk.enums.pushObjectTypes.staff.id;
            }else {
                checkedValue = Udesk.enums.pushObjectTypes.email.id;
            }
            let newPushConf = this.actions.setActionDetailsValue('pushTargetType', checkedValue, actionType);
            this.trigger('onPushConfChange', newPushConf);
        },
        //随路字段
        showAssociate(type, visible) {
            if (type === 'focus') {
                let that = this;
                clearTimeout(this.privates.showAssociateTimer);
                this.privates.showAssociateTimer = setTimeout(() => {
                    this.privates.showAssociate = !this.privates.showAssociate;
                    that.actions.update();
                }, 100);
                if (this.privates.showAssociate) {
                    clearTimeout(this.privates.showAssociateTimer);
                }
            }
            if (type === 'click') {
                this.privates.showAssociateTimer = setTimeout(() => {
                    this.privates.showAssociate = !this.privates.showAssociate;
                    this.actions.update();
                }, 100);
            }
        },
        associateInputFocus() {
            if (this.privates.associateDivRef.current.firstChild.selectionStart !== this.privates.associateDivRef.current.firstChild.selectionEnd) {
                // let start = this.privates.associateDivRef.current.firstChild.selectionStart;
                this.privates.textAreaRef.current.focus();
                // let that = this;
                // setTimeout(() => {
                //     that.privates.associateDivRef.current.firstChild.selectionStart = start;
                //     that.privates.associateDivRef.current.firstChild.selectionEnd = start;   
                // },100);    
            } else {
                this.privates.textAreaRef.current.focus();
            }
        },
        associateSearchChange(e) {
            e.stopPropagation();
            if (this.privates.associateListSearchValue) {
                this.actions.setAssociateList();
            } else {
                this.privates.associateList = this.privates.associateListSource;
                this.privates.associateOperatorList = this.privates.associateOperatorListSource;
                this.actions.update();
            }
        },
        setAssociateList(value) {
            if (this.privates.associateListSearchValue === '[' || this.privates.associateListSearchValue === ']') {
                this.privates.associateList = this.privates.associateListSource;
            } else {
                this.privates.associateList =
                    this.privates.associateListSource.map(associate => {
                        if (associate.fieldList) {
                            return {
                                type: associate.type,
                                fieldList: associate.fieldList.filter(item => item.label.indexOf(this.privates.associateListSearchValue) !== -1),
                            };
                        }
                        return {
                            type: associate.type,
                            fieldList: [],
                        };
                    });
            }
            this.privates.associateOperatorList =
                this.privates.associateOperatorListSource.map(operatorList => {
                    if (operatorList.list) {
                        return {
                            type: operatorList.type,
                            list: operatorList.list.filter(item => item.view.indexOf(this.privates.associateListSearchValue) !== -1 || item.note.indexOf(this.privates.associateListSearchValue) !== -1),
                        };
                    }
                    return {
                        type: operatorList.type,
                        list: [],
                    };
                });
            this.actions.update();
        },
        associateListSearchChange(e) {
            this.privates.associateListSearchValue = e.target.value;
            if (e.target.value === '') {
                this.privates.associateList = this.privates.associateListSource;
                this.privates.associateOperatorList = this.privates.associateOperatorListSource;
                this.actions.update();
            }
        },
        associateTabsChange(activeKey) {
            this.privates.associateActiveTab = activeKey;
            this.actions.update();
        },
        associateDataClick(actionType, type, item, e) {
            let { pushConf } = this.privates;
            let textAreaDom = this.privates.associateDivRef.current.firstChild;
            //为运算操作符前后添加空格，以区分是否为新增字符
            let arrNew;
            let res;
            pushConf.forEach(items => {
                if (items.actionType === actionType) {
                    if (!items.actionDetails) {
                        items.actionDetails = {};
                    }
                    arrNew = items.actionDetails.content.split('');
                    arrNew.splice(textAreaDom.selectionStart, 0, item);
                    // if (type === 1 && item.length === 1) {
                    //     item = ' ' + item + ' ';
                    // }
                    //删除示例中的形参
                    // if (type === 2) {
                    //     let itemCopy = item.split('');
                    //     itemCopy.splice(item.indexOf('(') + 1, item.lastIndexOf(")") - item.indexOf('(') - 1);
                    //     item = itemCopy.join('');
                    // }
                    items.actionDetails.content = arrNew.join('');
                    res = items.actionDetails.content.length;
                }
            });
            
            let that = this;
            textAreaDom.focus();
            setTimeout(() => {
                // let res = operatorItem.operatorList[0].param.inputContent.length;
                //根据元素长度判断是否新增，新增元素长度恒大于1
                arrNew.forEach((ele, index) => {
                    //新添加的元素是函数操作符(包含()且不是数据)
                    // if (ele.indexOf('(') >= 1 && ele.indexOf('[') === -1 && ele.indexOf(']') === -1) {
                    //     res = index + ele.indexOf('(') + 1;
                    // }
                    //新添加的元素在函数的()中
                    // if (ele.indexOf('(') < 1 && arrNew[index + 1] && arrNew[index + 1].length !== 1) {
                    //     res = index + arrNew[index + 1].length + 1;
                    // }
                    //新添加的元素是数据或运算操作符
                    if (ele && ele.indexOf('(') < 1 && arrNew[index + 1] && arrNew[index].length !== 1) {
                        res = index + arrNew[index].length;
                    }
                });
                //定位光标位置，selectionStart与selectionEnd不同可实现自动选中某区间效果
                that.privates.associateDivRef.current.firstChild.selectionStart = res;
                that.privates.associateDivRef.current.firstChild.selectionEnd = res;
            }, 100);
            //阻止冒泡
            e.stopPropagation();
            
            this.trigger('onPushConfChange', pushConf);
        },
        associateDataClickInRich(pushConf, editorState, content){
            this.actions.handleEditorChange(pushConf.actionType, ContentUtils.insertText(editorState, content));
        },
        getAssociate() {
            let { sdkOptions, taskType } = this.props;
            let fieldUrl = Udesk.business.apiPath.concatApiPath(
                `field/data/search-template`,
                sdkOptions
            );
            let data = {
                sourceList: [taskType],
                typeList: [1, 3, 6, 8]
            };
            Udesk.ajax.post(fieldUrl, data).then(
                resp => {
                    this.privates.associateListSource = resp.data[0].typeList;
                    this.privates.associateListSource.push({
                        type: 'task',
                        fieldList: []
                    });
                    this.privates.associateList = this.privates.associateListSource;
                    this.actions.getTaskAssociate();
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getAssociateOperator() {
            let { sdkOptions } = this.props;
            let fieldUrl = Udesk.business.apiPath.concatApiPath(
                `operator/meta`,
                sdkOptions
            );
            let data = {
                typeList: [1, 2],
            };
            Udesk.ajax.post(fieldUrl, data).then(
                resp => {
                    this.privates.associateOperatorList = resp.data;
                    this.privates.associateOperatorListSource = resp.data;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        getTaskAssociate() {
            let { sdkOptions, taskId } = this.props;
            if(!taskId){
                return;
            }
            let fieldUrl = Udesk.business.apiPath.concatApiPath(
                `field/data/task/${taskId}`,
                sdkOptions
            );
            Udesk.ajax.get(fieldUrl).then(
                resp => {
                    this.privates.associateListSource.forEach(item => {
                        if(item.type === 'task'){
                            item.fieldList = resp.data;
                        }
                    });
                    this.privates.associateList = this.privates.associateListSource;
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        onPushConfItemChange(pushConf){
            let {privates} = this;
            for (let i = 0; i < privates.pushConf.length; i++) {
                if (privates.pushConf[i].actionType === pushConf.actionType) {
                    privates.pushConf[i] = pushConf;
                    break;
                }
            }
            this.actions.update();
            this.trigger('onPushConfChange', privates.pushConf);
        },
    };

    //#region Life Cycle
    init() {
        this.privates.autoAddPushTargets = Udesk.enums.autoAddPushTarget.map(item => ({ label: item.name, value: item.id }));
        this.privates.sdkPushType = Udesk.enums.sdkPushType.map(item => ({ label: item.name, value: item.id }));
    }
    parseProps({ props, prevProps }) {
        let res = {};
        // let checkedTypesOpened = props.pushConf.filter(config => config._isOpen).map(config => config.actionType);
        // res.checkedTypesOpened = checkedTypesOpened;
        res.pushConf = Udesk.utils.object.deepCopy(props.pushConf);
        res.pushConf.forEach(item => {
            if(!item.actionDetails._editorState){
                item.actionDetails._editorState = BraftEditor.createEditorState(item.actionDetails.content || null);
            }
            if((item.actionDetails.pushRoleList && item.actionDetails.pushRoleList.length) || (item.actionDetails.pushUserList && item.actionDetails.pushUserList.length)){
                res.showCustomize = true;
            }
        });
        return res;
    }
    componentDidMount() {
    }
    componentWillUnmount() {

    }
    asyncModel(changedParams) {
        this.actions.getAssociate();
        this.actions.getAssociateOperator();
    }
    //#endregion
}
function isJSON(str) {
    if (/^[\],:{}\s]*$/.test(str.replace(/\\["\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][ -]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        return true;
    } else {
        return false;
    }
}
export default MassagePushConfigurationComponent;