// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetSmartTagsReviewRelatedCallQuery,
    BaseResponseListSmartTagRelatedCall,
    PostSmartTagsReviewRelatedCallBody,
    BaseResponseVoid,
    DeleteSmartTagsReviewRelatedCallBody,
} from '../../../../types';

/**
 * 获取赋能会话场景关联的会话数据
 *
 * @export
 * @tags smart-tag-controller
 * @link [GET] /smartTags/review/related/call
 */
export function getSmartTagsReviewRelatedCall(
    options: ApiOptions<never, GetSmartTagsReviewRelatedCallQuery> & {
        params: GetSmartTagsReviewRelatedCallQuery;
    }
): Promise<BaseResponseListSmartTagRelatedCall> {
    return request<never, never, GetSmartTagsReviewRelatedCallQuery>(
        '/smartTags/review/related/call',
        { ...options, method: 'get', __$requestCalleeName: 'getSmartTagsReviewRelatedCall' }
    );
}

/**
 * 赋能增加对话的会话场景标签
 *
 * @export
 * @tags smart-tag-controller
 * @link [POST] /smartTags/review/related/call
 */
export function postSmartTagsReviewRelatedCall(
    data: PostSmartTagsReviewRelatedCallBody
): Promise<BaseResponseVoid>;
export function postSmartTagsReviewRelatedCall(
    data: PostSmartTagsReviewRelatedCallBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postSmartTagsReviewRelatedCall(
    data: PostSmartTagsReviewRelatedCallBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostSmartTagsReviewRelatedCallBody, never, never>(
        '/smartTags/review/related/call',
        { ...options, data, method: 'post', __$requestCalleeName: 'postSmartTagsReviewRelatedCall' }
    );
}

/**
 * 删除赋能会话场景关联的会话数据
 *
 * @export
 * @tags smart-tag-controller
 * @link [DELETE] /smartTags/review/related/call
 */
export function deleteSmartTagsReviewRelatedCall(
    data: DeleteSmartTagsReviewRelatedCallBody
): Promise<BaseResponseVoid>;
export function deleteSmartTagsReviewRelatedCall(
    data: DeleteSmartTagsReviewRelatedCallBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function deleteSmartTagsReviewRelatedCall(
    data: DeleteSmartTagsReviewRelatedCallBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<DeleteSmartTagsReviewRelatedCallBody, never, never>(
        '/smartTags/review/related/call',
        {
            ...options,
            data,
            method: 'delete',
            __$requestCalleeName: 'deleteSmartTagsReviewRelatedCall',
        }
    );
}

export const meta = [
    { tags: ['smart-tag-controller'], path: '/smartTags/review/related/call', method: 'get' },
    { tags: ['smart-tag-controller'], path: '/smartTags/review/related/call', method: 'post' },
    { tags: ['smart-tag-controller'], path: '/smartTags/review/related/call', method: 'delete' },
];
