import { useState, useEffect } from 'react';
import {
    deleteReviewSubscribesByCallId,
    postReviewSubscribesByCallId,
    getReviewSubscribesByCallId,
} from 'src/api/review/subscribes/{callId}';

const useSubscription = ({ callId }) => {
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(false);

    const onChange = (checked) => {
        setLoading(true);
        let request: typeof deleteReviewSubscribesByCallId | typeof postReviewSubscribesByCallId | null = null;
        if (checked) {
            request = postReviewSubscribesByCallId;
        } else {
            request = deleteReviewSubscribesByCallId;
        }
        request?.({
            segments: {
                callId,
            },
        })
            .then(() => {
                setChecked(checked);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getReviewSubscribesByCallId({
            segments: {
                callId,
            },
        })
            .then((resp) => {
                setChecked(Boolean(resp?.data?.subscribed));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    return [loading, checked, onChange] as [boolean, boolean, (checked: boolean) => void];
};

export default useSubscription;
