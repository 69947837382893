import MultipleSyncFileLoaderClass from 'udesk-web-toolkits/src/udesk/app/service/multiple-sync-file-loader-class';

export default class MultipleSyncFileLoaderBaseClass {
    constructor() {
        this._multipleSyncFileLoaderInstance = new MultipleSyncFileLoaderClass();
    }

    setLoadUrl(url) {
        if (url) {
            this._url = url;
        }
    }

    getFieldLoadStatus() {
        return this._multipleSyncFileLoaderInstance.getFieldLoadStatus(this._url);
    }

    loadAsync(callback) {
        if (this._multipleSyncFileLoaderInstance) {
            this._multipleSyncFileLoaderInstance.loadAsync(this._url, callback);
        }
    }

    removeCallback(callback) {
        if (this._multipleSyncFileLoaderInstance) {
            this._multipleSyncFileLoaderInstance.remove(this._url, callback);
        }
    }

    clear() {
        if (this._multipleSyncFileLoaderInstance) {
            this._multipleSyncFileLoaderInstance.clear();
        }
    }

    destroy() {
        if (this._multipleSyncFileLoaderInstance) {
            this._multipleSyncFileLoaderInstance.destory();
            this._multipleSyncFileLoaderInstance = null;
        }
    }
}
