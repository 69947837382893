// @ts-nocheck

import { request } from '../../../../../../../context';
import type { ApiOptions } from '../../../../../../../context';
import type {
    GetIntelligentPartnerAiAnalysisByIdQueryFormExportQuery,
    GetIntelligentPartnerAiAnalysisByIdQueryFormExportParam,
    BaseResponseVoid,
} from '../../../../../../../types';

/**
 * 自定义报表导出
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [GET] /intelligentPartner/ai/analysis/{id}/query/form/export
 * @param id
 */
export function getIntelligentPartnerAiAnalysisByIdQueryFormExport(
    options: ApiOptions<
        GetIntelligentPartnerAiAnalysisByIdQueryFormExportParam,
        GetIntelligentPartnerAiAnalysisByIdQueryFormExportQuery
    > & { segments: GetIntelligentPartnerAiAnalysisByIdQueryFormExportParam } & {
        params: GetIntelligentPartnerAiAnalysisByIdQueryFormExportQuery;
    }
): Promise<BaseResponseVoid> {
    return request<
        never,
        GetIntelligentPartnerAiAnalysisByIdQueryFormExportParam,
        GetIntelligentPartnerAiAnalysisByIdQueryFormExportQuery
    >('/intelligentPartner/ai/analysis/{id}/query/form/export', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerAiAnalysisByIdQueryFormExport',
    });
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/{id}/query/form/export',
        method: 'get',
    },
];
