// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostIntelligentPartnerAiReferenceWordsByNodeIdQuery,
    PostIntelligentPartnerAiReferenceWordsByNodeIdParam,
    PostIntelligentPartnerAiReferenceWordsByNodeIdBody,
    BaseResponsestring,
} from '../../../../types';

/**
 * ai对话生成参考话术
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [POST] /intelligentPartner/ai/referenceWords/{nodeId}
 * @param nodeId
 */
export function postIntelligentPartnerAiReferenceWordsByNodeId(
    data: PostIntelligentPartnerAiReferenceWordsByNodeIdBody,
    options: ApiOptions<
        PostIntelligentPartnerAiReferenceWordsByNodeIdParam,
        PostIntelligentPartnerAiReferenceWordsByNodeIdQuery
    > & { segments: PostIntelligentPartnerAiReferenceWordsByNodeIdParam }
): Promise<BaseResponsestring> {
    return request<
        PostIntelligentPartnerAiReferenceWordsByNodeIdBody,
        PostIntelligentPartnerAiReferenceWordsByNodeIdParam,
        PostIntelligentPartnerAiReferenceWordsByNodeIdQuery
    >('/intelligentPartner/ai/referenceWords/{nodeId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerAiReferenceWordsByNodeId',
    });
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/ai/referenceWords/{nodeId}',
        method: 'post',
    },
];
