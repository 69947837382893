import React from 'react';
import Udesk from 'Udesk';
import { Item } from '../Container';
import { TaskProgress } from '../TaskProgress';
import styled from 'styled-components';
import { TaskCardList } from '../TaskCard';
import { Avatar } from 'udesk-ui';
import { PracticeSummary } from '../PracticeSummary';
import { RankingList } from '../RankingList';
import { useTasks } from '../../hook/useTasks';
import { linkToStart } from '../../../learning-center/components/util';
import UdeskLocales from 'UdeskLocales';

const Wrap = styled.div`
    padding: 12px 16px 16px;
    background-color: #fff;
    display: flex;
    align-items: ${(props) => props.align};
    justify-content: ${(props) => props.align};
    flex-direction: column;
    height: 100%;
    .ongoing-tasks-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
    }
    .display-center {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const StudentDataCards = (props) => {
    const { company, user } = Udesk.data.init;

    const {
        practiseTasks,
        examinationTasks,
        practiseProgress,
        examinationProgress,
        loading: taskLoading,
    } = useTasks();

    return (
        <>
            <Item widthRatio={1}>
                <Wrap alig="center" style={{ padding: 16, flexDirection: 'row' }}>
                    <Avatar
                        size={48}
                        gap={4}
                        src={user.headPortraitUrl}
                        style={{ marginRight: 12 }}
                    >
                        {user.realname}
                    </Avatar>
                    <div className="header-title">
                        <div className="title">{`hi,${user.realname}`}</div>
                        <div className="company">{`@${company.sysName}`}</div>
                    </div>
                </Wrap>
            </Item>
            <Item widthRatio={1 / 2}>
                <Wrap>
                    <TaskCardList
                        title={
                            /* 进行中的练习 */ UdeskLocales['current'].pages.coach.dashboard.index
                                .ongoingExercises
                        }
                        tasks={practiseTasks}
                        loading={taskLoading}
                        onCardClick={(dialogueMode, taskId) => {
                            linkToStart(
                                props.history,
                                Udesk.enums.learningTaskType.notStarted.id,
                                dialogueMode,
                                {
                                    id: taskId,
                                }
                            );
                        }}
                    />
                    <TaskCardList
                        style={{ marginTop: 12 }}
                        title={
                            /* 进行中的考试 */ UdeskLocales['current'].pages.coach.dashboard.index
                                .ongoingExams
                        }
                        tasks={examinationTasks}
                        loading={taskLoading}
                        onCardClick={(dialogueMode, taskId) => {
                            linkToStart(
                                props.history,
                                Udesk.enums.learningTaskType.underWay.id,
                                dialogueMode,
                                {
                                    id: taskId,
                                }
                            );
                        }}
                    />
                </Wrap>
            </Item>
            <Item widthRatio={1 / 4}>
                <Wrap>
                    <div className="task-progress-title">
                        {/* 练习任务进度 */}
                        {UdeskLocales['current'].pages.coach.dashboard.index.practiceTaskProgress}
                    </div>
                    <div className="display-center">
                        <TaskProgress {...practiseProgress} loading={taskLoading} />
                    </div>
                </Wrap>
            </Item>
            <Item widthRatio={1 / 4}>
                <Wrap>
                    <div className="task-progress-title">
                        {/* 考试任务进度 */}
                        {UdeskLocales['current'].pages.coach.dashboard.index.examTaskProgress}
                    </div>
                    <div className="display-center">
                        <TaskProgress {...examinationProgress} loading={taskLoading} />
                    </div>
                </Wrap>
            </Item>
            <Item width="auto">
                <PracticeSummary isAdmin={false} />
            </Item>
            <Item width={600}>
                <RankingList isAdmin={false} history={props.history} />
            </Item>
        </>
    );
};

export default StudentDataCards;
