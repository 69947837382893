import Udesk from 'Udesk';
import connectedByConversationComponent from './component';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
import _filter from 'lodash-es/filter';
import _size from 'lodash-es/size';
class connectedByConversationRoute extends connectedByConversationComponent {
    asyncModel(changedParams) {
        let { sdkOptions, sourceListAjax } = this.props;
        let url1Data = {
            sourceList: sourceListAjax
        };
        let url1 = Udesk.business.apiPath.concatApiPath(`field/data/sources`, sdkOptions);
        let fieldData = new Promise((resolve, reject) => {
            Udesk.ajax.post(url1, url1Data).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let url2 = Udesk.business.apiPath.concatApiPath(`data-call-related-config`, sdkOptions);
        let dataCallRelatedConfig = new Promise((resolve, reject) => {
            Udesk.ajax.get(url2).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            listData: dataCallRelatedConfig,
            fieldsData: fieldData
        };
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'listData') {
            if (asyncResult.data) {
                let parentNumList = [];
                if (asyncResult.data.filter && !_isEmpty(asyncResult.data.filter.conditionList)) {
                    this.privates.listData = asyncResult.data.filter.conditionList;
                    asyncResult.data.filter.conditionList.forEach(item => {
                        if (!_find(parentNumList, current => current.num === item.parentNum)) {
                            parentNumList = [...parentNumList, { num: item.parentNum }];
                        }
                    });
                    parentNumList = parentNumList.map(item => {
                        let list = _filter(
                            asyncResult.data.filter.conditionList,
                            current => current.parentNum === item.num
                        );
                        return {
                            ...item,
                            childNum: _size(list),
                            source: list[0].source,
                            sourceFrom: list[0].sourceFrom
                        };
                    });
                    this.privates.num = _size(parentNumList);
                    this.privates.parentNumList = parentNumList;
                    this.privates.editOrSaveStates = true;

                    this.privates.dataCallRelatedConfigId = asyncResult.data.id;
                } else {
                    this.privates.editOrSaveStates = false;
                }
                if (asyncResult.data.activeFlag) {
                    this.privates.activeFlag = asyncResult.data.activeFlag;
                } else {
                    this.privates.activeFlag = 0;
                }
            }
            this.actions.update();
        } else if (asyncKey === 'fieldsData') {
            this.privates.fieldsData = asyncResult.data;
            this.actions.update();
        }
    }
}

export default connectedByConversationRoute;
