
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Spin, Modal, Table, Tag } from "udesk-ui";
import { getTaskScoreData } from '../util';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export type RefType = {
    open: (type: 1 | 2 ) => void;
}

export default React.forwardRef<RefType, any>((props, ref) => {
    const {
        cancelTaskHandle, 
        setWrongQuestionIndex,
        visible, 
        wrongQuestionSet
    } = props;
    const [type, setType] = useState(1); 
    const [isVisible, setIsVisible] = useState(visible);
    const dataSource = getTaskScoreData(wrongQuestionSet);
    
    useImperativeHandle(ref, () => {
        return {
            open(type) {
                setType(type);
                setIsVisible(true);
            }
        };
    });

    const title= useMemo(() => {
        if (type === 2) {
            return /* 本次错题练习得分 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.scoreForThisPracticeOfIncorrectQuestions;
        }
        return /* 提示 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.tips;
    }, [type]);

    const taskDesc = useMemo(() => {
        if (type === 2) {
            return <TaskScoreTable dataSource={dataSource}/>;
        }
        return /* 是否确认结束？ */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.areYouSureToEnd;
    }, [dataSource, type]);

    const cancelText = useMemo(() => {
        if (type === 2) {
            const index = dataSource.findIndex(item => !item.grasp);
            if (index >= 0) {
                return /* 再次练习未掌握错题 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.practiceAgainWithoutMasteringTheWrongQuestions;
            }
        }
        return /* 继续 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.continue;
    }, [dataSource, type]);

    const okText = useMemo(() => {
        if (type === 2) {
            return /* 返回错题集 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.returnToTheSetOfIncorrectQuestions;
        }
        return /* 结束 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.end;
    }, [type]);

    const submitHandle = useCallback(() => {
        if (type === 1) {
            if (dataSource.length > 0) {
                setType(2);
            } else {
                cancelTaskHandle();
            }
        }
        if (type === 2) {
            cancelTaskHandle();
        }
    }, [cancelTaskHandle, dataSource.length, type]);

    const cancelHandle = useCallback(() => {
        if (type === 2) {
            const index = dataSource.findIndex(item => !item.grasp);
            if (index >= 0) {
                setWrongQuestionIndex(index);
            }
        }
        setIsVisible(false);
    },[dataSource, setWrongQuestionIndex, type]);

    return (
        <Modal 
            wrapClassName='wrong-question-exercise-message-modal'
            zIndex={9999}
            title={title}
            cancelText={cancelText}
            okText={okText}
            maskClosable={false}
            getContainer={false}
            visible={isVisible} 
            onOk={submitHandle} 
            onCancel={cancelHandle}
        >
            <Spin spinning={!taskDesc} delay={500}>
                {taskDesc}
            </Spin>
        </Modal>
    );
});

const TaskScoreTable = React.memo((props: any) => {
    const columns: any = [
        {
            title: /* 错题项 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.wrongQuestionItem,
            dataIndex: 'name',
            key: 'name',
            render(name, record, index) {
                return `${index + 1}.${name}`;
            },
        },
        {
            title: /* 本次练习次数 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.numberOfExercisesThisTime,
            dataIndex: 'times',
            key: 'times',
        },
        {
            title: /* 掌握情况 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.masteringTheSituation,
            dataIndex: 'grasp',
            key: 'grasp',
            render(grasp) {
                return <Tag color={grasp ? 'success' : 'error'}>{grasp ? /* 已掌握 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.mastered : /* 未掌握 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.taskMessageModal.index.notMastered}</Tag>;
            },
        },
    ];
      
    return (
        <Table columns={columns} dataSource={props.dataSource} pagination={false}/>
    );
});