import React from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { getSystemModule } from 'Udesk/system/subApp';
import { linkTo } from 'src/util/link';
import { isDefined } from 'src/util/core';
// import data from "udesk-web-toolkits/src/udesk/data";

class TaskCenterListComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        title: '',
        searchPlaceHolder: '',
        searchFieldName: 'keyword',
        type: Udesk.enums.filterTypes.call.id,
        // filter: null,
        editFilterVisible: false,
        selectedFilter: {},
    };
    //#endregion

    state = {};
    privates = {
        paging: {},
        data: {},
        judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        customJudgeLogic: '',
        isSave: null,
        filterName: '',
        updateType: null,
        count: 0,
        timeFlag: true,
        pagess: true,
        timer: null,
        lastId: '',
        visible: false,
        taskRef: React.createRef(),
        detailData: [],
    };

    static computes = {};
    init() {
        let columns = this.getColumns();

        this.privates.columns = columns;
    }
    actions = {
        goLink() {
            const { inspectionId, taskId, baseFilter } = this.privates.detailData || {};
            isDefined(
                inspectionId,
                () => {
                    linkTo(this.props.history, 'qualityInspectionListDetail', {
                        taskId,
                        id: inspectionId,
                    });
                },
                () => {
                    linkTo(
                        this.props.history,
                        'qualityInspectionListIndex',
                        {
                            taskId,
                        },
                        baseFilter
                    );
                }
            );
        },
        pageChanged(pageNum, pageSize, total) {
            let { lastId } = this.privates;
            if (pageNum > 1) {
                this.actions.reloadAsyncModel({ pageNum, pageSize, lastId });
            } else {
                this.actions.reloadAsyncModel({ pageNum, pageSize });
            }
        },
        resultStatus() {
            let { sdkOptions } = this.props;
            let params = {
                pageNum: this.privates.paging.pageNum,
                pageSize: this.privates.paging.pageSize,
                moduleType: getSystemModule(),
            };
            if (this.privates.paging.pageNum > 1) {
                params = {
                    ...params,
                    lastId: this.privates.lastId,
                };
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionManualLoads/tasks`,
                sdkOptions
            );
            let count = 0;
            this.privates.timer = setTimeout(() => {
                count = 0;

                new Promise((resolve, reject) => {
                    Udesk.ajax.get(url, params).then(
                        (resp) => {
                            if (resp.data && resp.data.length > 0) {
                                this.privates.data = resp.data;
                                this.privates.data = this.privates.data.map((item) => {
                                    if (
                                        (item.status === 1 ||
                                            item.status === 2 ||
                                            item.status === 7) &&
                                        item.taskType !== 2 &&
                                        item.taskType !== 4 &&
                                        item.taskType !== 10
                                    ) {
                                        return {
                                            ...item,
                                            statusName: Locales['current'].fix.pause,
                                            cancelName: Locales['current'].labels.cancel,
                                        };
                                    } else if (item.status === 3 || item.status === 4) {
                                        return item;
                                    } else if (item.status === 6) {
                                        return {
                                            ...item,
                                            statusName: Locales['current'].fix.continue,
                                            cancelName: Locales['current'].labels.cancel,
                                        };
                                    }
                                    return item;
                                });
                                this.actions.update();
                                resp.data.forEach((item) => {
                                    if (item.status === 1 || item.status === 2) {
                                        count++;
                                    }
                                });
                            }
                            this.actions.resultStatus();

                            if (count === this.privates.data.length) {
                                clearTimeout(this.privates.timer);
                            }
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }, 5000);
        },
        suspengOrContinue(id, name) {
            let { sdkOptions } = this.props;
            let { paging, lastId } = this.privates;
            let { pageNum, pageSize } = paging;
            let data = {};
            if (name === Locales['current'].fix.pause) {
                data = {
                    id,
                    status: 6,
                };
            } else if (name === Locales['current'].fix.continue) {
                data = {
                    id,
                    status: 7,
                };
            } else if (name === Locales['current'].labels.cancel) {
                data = {
                    id,
                    status: 5,
                };
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionManualLoads/task/action`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        if (pageNum > 1) {
                            this.actions.reloadAsyncModel({ pageNum, pageSize, lastId });
                        } else {
                            this.actions.reloadAsyncModel({ pageNum, pageSize });
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        drawerClose() {
            this.privates.visible = false;
            this.actions.update();
        },
        fieldDetail(id) {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionManualLoads/task/${id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                        this.privates.detailData = resp.data;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        this.privates.detailData = [];
                        this.actions.update();
                        reject(reason);
                    }
                );
            });
            this.privates.visible = true;
            this.actions.update();
        },
        fieldsDataChange(data) {
            if (data && data.list && data.list.length > 0) {
                let str = (
                    <div style={{ width: '315px' }}>
                        {data.list.map((item) => {
                            return (
                                <span
                                    title={item.name}
                                    style={{
                                        width: '33.3%',
                                        display: 'inline-block',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    -{item.name}
                                </span>
                            );
                        })}
                    </div>
                );
                return str;
            } else {
                return '';
            }
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            (data?.conditionList || []).forEach((item) => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            this.getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach((items) => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label +
                                ' ' +
                                obj[item.operator] +
                                ' ' +
                                str.substring(0, str.length - 1),
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName ||
                                item.value.agentGroupName ||
                                item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <p style={{ marginBottom: '3px' }}>
                        {index + 1}. {item}
                    </p>
                );
            });
        },
        fieldsFilterChangeCheck(data) {
            if (data.reCheckType === 1) {
                return Locales['current'].fix.recalcScore;
            } else {
                if (data.inspectionPoints && data.inspectionPoints.length > 0) {
                    let str = (
                        <div style={{ width: '315px' }}>
                            <p>{Locales['current'].fix.recalculatePointsAndScores}</p>
                            <p>
                                {data.inspectionPoints.map((item) => {
                                    return (
                                        <span
                                            title={item.name}
                                            style={{
                                                display: 'inline-block',
                                                width: '33.3%',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {' '}
                                            - {item.name}
                                        </span>
                                    );
                                })}
                            </p>
                        </div>
                    );
                    return str;
                } else {
                    return Locales['current'].fix.recalculatePointsAndScoresWithEmtry;
                }
            }
        },
        fieldGo() {
            let { sdkOptions } = this.props;
            let { paging, lastId } = this.privates;
            let { pageNum, pageSize } = paging;
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/doNow`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        if (pageNum > 1) {
                            this.actions.reloadAsyncModel({ pageNum, pageSize, lastId });
                        } else {
                            this.actions.reloadAsyncModel({ pageNum, pageSize });
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        fieldGoYu() {
            let { sdkOptions } = this.props;
            let { paging, lastId } = this.privates;
            let { pageNum, pageSize } = paging;
            let url = Udesk.business.apiPath.concatApiPath(`preprocessRules/doNow`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        if (pageNum > 1) {
                            this.actions.reloadAsyncModel({ pageNum, pageSize, lastId });
                        } else {
                            this.actions.reloadAsyncModel({ pageNum, pageSize });
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {};
    }
    componentDidMount() {
        if (this.props.location.search) {
            let objList = this.props.location.search.split('=');
            let objId = objList[1];
            this.actions.reloadAsyncModel(this.privates.paging, objId);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.location.search &&
            this.props.location.search &&
            prevProps.location.search !== this.props.location.search
        ) {
            let objList = this.props.location.search.split('=');
            let objId = objList[1];
            this.actions.reloadAsyncModel(this.privates.paging, objId);
        }
    }
    componentWillUnmount() {
        clearTimeout(this.privates.timer);
    }

    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult } = parseOptions;
        if (asyncResult) {
            if (this.privates.timer) {
                clearTimeout(this.privates.timer);
            }
            if (asyncResult.data) {
                this.privates.data = asyncResult.data;
                this.privates.data = this.privates.data.map((item) => {
                    if (
                        (item.status === 1 || item.status === 2 || item.status === 7) &&
                        item.taskType !== 2 &&
                        item.taskType !== 4 &&
                        item.taskType !== 10
                    ) {
                        return {
                            ...item,
                            statusName: Locales['current'].fix.pause,
                            cancelName: Locales['current'].labels.cancel,
                        };
                    } else if (item.status === 3 || item.status === 4) {
                        return item;
                    } else if (item.status === 6) {
                        return {
                            ...item,
                            statusName: Locales['current'].fix.continue,
                            cancelName: Locales['current'].labels.cancel,
                        };
                    }
                    return item;
                });
            }
            if (this.privates.data.length > 0) {
                for (var i = 0; i < this.privates.data.length; i++) {
                    if (
                        this.privates.data[i].status === 1 ||
                        this.privates.data[i].status === 2 ||
                        this.privates.data[i].status === 7
                    ) {
                        if (asyncResult.paging) {
                            this.privates.paging = asyncResult.paging;
                            if (
                                this.privates.paging.pageNum === 1 &&
                                this.privates.data.length > 0
                            ) {
                                this.privates.lastId =
                                    this.privates.data[this.privates.data.length - 1].id;
                            }
                        }
                        return this.actions.resultStatus();
                    } else {
                        clearTimeout(this.privates.timer);
                    }
                }
            }
            if (asyncResult.paging) {
                this.privates.paging = asyncResult.paging;
                this.privates.pagess = true;
                let { pageNum, pageSize, total } = this.privates.paging;
                if (pageNum === 1 && this.privates.data.length > 0) {
                    this.privates.lastId = this.privates.data[this.privates.data.length - 1].id;
                }
                this.trigger('onPageChanged', pageNum, pageSize, total);
            }
            this.actions.update();
        }
    }

    //#endregion
    getColumns() {
        let { locales } = this;
        let columns = [
            {
                name: 'name',
                caption: locales.fields.taskCenterList.name,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    if (item && item.name && item.name.length > 20) {
                        return <span title={item.name}>{item.name.substring(0, 19) + '...'}</span>;
                    } else {
                        return item.name;
                    }
                },
            },
            {
                name: 'createTime',
                caption: locales.fields.taskCenterList.createTime,
            },
            {
                name: 'startTime',
                caption: locales.fields.taskCenterList.startTime,
            },
            {
                name: 'endTime',
                caption: locales.fields.taskCenterList.endTime,
            },
            {
                name: 'createUserName',
                caption: locales.fields.taskCenterList.createUserName,
            },
            {
                name: 'status',
                caption: locales.fields.taskCenterList.status,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    if (item.status === 4) {
                        return locales.components.pages.uploadDownloadModel.list.fail;
                    } else if (item.status === 3) {
                        return locales.components.pages.uploadDownloadModel.list.success;
                    } else if (item.status === 2) {
                        return Locales['current'].fix.inProgress;
                    } else if (item.status === 1 || item.status === 7 || item.status === -1) {
                        return Locales['current'].fix.toBeExecuted;
                    } else if (item.status === 6) {
                        return Locales['current'].fix.pause;
                    } else if (item.status === 5) {
                        return locales.labels.cancel;
                    }
                },
            },
            {
                name: 'result',
                caption: locales.fields.taskCenterList.result,
            },
            {
                name: 'actions',
                caption: locales.fields.taskCenterList.actions,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    if (item.status === 3 || item.status === 4 || item.status === 5) {
                        return (
                            <div>
                                <a
                                    title={locales.labels.seeDetail}
                                    onClick={this.actions.fieldDetail.params(item.id)}
                                    className="uploadDownloadHover"
                                >
                                    {locales.labels.seeDetail}
                                </a>
                            </div>
                        );
                    } else if (
                        (item.taskType === 2 || item.taskType === 4 || item.taskType === 10) &&
                        (item.status === 1 || item.status === 2 || item.status === 7)
                    ) {
                        return (
                            <div>
                                <a
                                    title={locales.labels.seeDetail}
                                    onClick={this.actions.fieldDetail.params(item.id)}
                                    className="uploadDownloadHover"
                                >
                                    {locales.labels.seeDetail}
                                </a>
                                {Udesk.data.init.user &&
                                    Udesk.data.init.user.hasFeature('manual:task:edit') && (
                                        <a
                                            title={locales.labels.cancel}
                                            onClick={this.actions.suspengOrContinue.params(
                                                item.id,
                                                locales.labels.cancel
                                            )}
                                            className="uploadDownloadHover"
                                        >
                                            {locales.labels.cancel}
                                        </a>
                                    )}
                            </div>
                        );
                    } else if (
                        (item.taskType !== 2 || item.taskType !== 4 || item.taskType !== 10) &&
                        (item.status === 1 ||
                            item.status === 2 ||
                            item.status === 6 ||
                            item.status === 7)
                    ) {
                        return (
                            <div>
                                <a
                                    title={locales.labels.seeDetail}
                                    onClick={this.actions.fieldDetail.params(item.id)}
                                    className="uploadDownloadHover"
                                >
                                    {locales.labels.seeDetail}
                                </a>
                                {Udesk.data.init.user &&
                                    Udesk.data.init.user.hasFeature('manual:task:edit') && (
                                        <a
                                            onClick={this.actions.suspengOrContinue.params(
                                                item.id,
                                                item.statusName
                                            )}
                                            className="uploadDownloadHover"
                                        >
                                            {item.statusName}
                                        </a>
                                    )}
                                {Udesk.data.init.user &&
                                    Udesk.data.init.user.hasFeature('manual:task:edit') && (
                                        <a
                                            title={locales.labels.cancel}
                                            onClick={this.actions.suspengOrContinue.params(
                                                item.id,
                                                locales.labels.cancel
                                            )}
                                            className="uploadDownloadHover"
                                        >
                                            {locales.labels.cancel}
                                        </a>
                                    )}
                            </div>
                        );
                    } else if (item.taskType === 11 && item.status === -1) {
                        return (
                            <div>
                                <a
                                    title={Locales['current'].fix.executeImmediately}
                                    onClick={this.actions.fieldGo}
                                    className="uploadDownloadHover"
                                >
                                    {Locales['current'].fix.executeImmediately}
                                </a>

                                {Udesk.data.init.user &&
                                    Udesk.data.init.user.hasFeature('manual:task:edit') && (
                                        <a
                                            title={locales.labels.cancel}
                                            onClick={this.actions.suspengOrContinue.params(
                                                item.id,
                                                locales.labels.cancel
                                            )}
                                            className="uploadDownloadHover"
                                        >
                                            {locales.labels.cancel}
                                        </a>
                                    )}
                            </div>
                        );
                    } else if (item.taskType === 8 && item.status === -1) {
                        return (
                            <div>
                                <a
                                    title={Locales['current'].fix.executeImmediately}
                                    onClick={this.actions.fieldGoYu}
                                    className="uploadDownloadHover"
                                >
                                    {Locales['current'].fix.executeImmediately}
                                </a>

                                {Udesk.data.init.user &&
                                    Udesk.data.init.user.hasFeature('manual:task:edit') && (
                                        <a
                                            title={locales.labels.cancel}
                                            onClick={this.actions.suspengOrContinue.params(
                                                item.id,
                                                locales.labels.cancel
                                            )}
                                            className="uploadDownloadHover"
                                        >
                                            {locales.labels.cancel}
                                        </a>
                                    )}
                            </div>
                        );
                    }
                },
            },
        ];
        return columns;
    }
    getOptionValue(id, optionValueList) {
        let value = optionValueList.filter((item) => {
            if (Array.isArray(id)) {
                return id.includes(item.id);
            } else {
                return item.id === id;
            }
        });
        if (value.length > 0) {
            value = value.map((item) => {
                return item.name;
            });
            return value.join(',');
        } else {
            return '';
        }
    }
}

export default TaskCenterListComponent;
