import React from 'react';
import PropTypes from 'prop-types';
import UdeskReact from '../../udesk/react';

let SdkLoadedMap = {};
let SdkLoadedListeners = [];
let BakSdkLoadedCallback = window.__SDK_LOADED_CALLBACK__;

window.__SDK_LOADED_CALLBACK__ = function (sdkClass) {
    if (typeof BakSdkLoadedCallback === "function") {
        BakSdkLoadedCallback.apply(this, arguments);
    }
    for (let i = 0; i < SdkLoadedListeners.length; i++) {
        SdkLoadedListeners[i](sdkClass);
    }
};

class SdkLoader extends React.Component {
    //#region
    static defaultProps = {
        classNames: 'sdk-loader',

        layerParentContainer: "",

        jsUrl: "",
        cssUrl: "",
        outputGlobalNamespace: "",
        releaseVersionUrl: "",
        loadErrorMessage: "",
        showLoadingAnimation: true,

        onLoaded: null,
        onDestroying: null,
    }
    static propTypes = {
        jsUrl: PropTypes.string,
        cssUrl: PropTypes.string,
        outputGlobalNamespace: PropTypes.string,
        releaseVersionUrl: PropTypes.string,
        loadErrorMessage: PropTypes.string,
        showLoadingAnimation: PropTypes.bool,
    }
    //#endregion
    privates = {
        isLoading: false,
        _layerLoadingDom: null,
        _layerLoadingParentNode: null,
    }
    constructor(props) {
        super(props);
        this.sdkLoaderInstance = React.createRef();
        this._layerLoadingDom = null;
        this._layerLoadingParentNode = null;
    }
    static computes = {
        _isLoaded: ["privates.isLoading", "jsUrl", function ({ props, privates }) {
            let {
                jsUrl
            } = props;
            let isLoading = privates.isLoading;

            if (Object.prototype.hasOwnProperty.call(SdkLoadedMap,jsUrl)) {
                let sdkSettings = SdkLoadedMap[jsUrl];
                return sdkSettings.isLoaded;
            } else {
                return !isLoading;
            }
        }]
    }

    load() {
        let that = this;
        let defer = $.Deferred();
        this._load(defer);
        defer.then(function (sdkClass) {
            if (!that.isDestroyed || !that.isDestroying) {
                removeLayer(that);
                that.trigger("onLoaded", sdkClass);
                that.privates.isLoading = false;
                that.actions.update();
            }
        }, function () {
            if (!that.isDestroyed || !that.isDestroying) {
                removeLayer(that);
                that.privates.isLoading = false;
                that.actions.update();
            }
        });
        return defer.promise();
    }

    reload() {
        let {
            jsUrl
        } = this.props;
        if (Object.prototype.hasOwnProperty.call(SdkLoadedMap,jsUrl)) {
            Object.assign(SdkLoadedMap[jsUrl], {
                isLoaded: false
            });
        }

        creatLayer(this);
        return this.load();
    }

    componentDidMount() {
        this.load();
    }
    parseProps({ props, prevProps, state, privates, isInitial }) {
    }
    componentWillUnmount() {
        this.trigger("onDestroying");
        removeLayer(this);
        let {
            jsUrl
        } = this.props;
        if (Object.prototype.hasOwnProperty.call(SdkLoadedMap,jsUrl)) {
            let sdkSettings = SdkLoadedMap[jsUrl];
            let callbacks = sdkSettings.callbacks;
            if (callbacks.length > 0) {
                for (let i = 0; i < callbacks.length; i++) {
                    let sdkCallback = callbacks[i];
                    if (sdkCallback != null && typeof sdkCallback.reject === "function") {
                        sdkCallback.reject();
                    }
                }
                SdkLoadedMap[jsUrl].callbacks = [];
            }
        }
    }

    _load(callback) {
        let {
            jsUrl
        } = this.props;
        if (!Object.prototype.hasOwnProperty.call(SdkLoadedMap,jsUrl)) {
            SdkLoadedMap[jsUrl] = getDefaultSdkSettings();
        }
        let sdkSettings = SdkLoadedMap[jsUrl];
        if (sdkSettings.isLoading) {
            SdkLoadedMap[jsUrl].callbacks.push(callback);
            return;
        }
        let isLoadNewSdk = true;
        if (sdkSettings.isLoaded) {
            if (sdkSettings.version != null && sdkSettings.sdkClass && (sdkSettings.sdkClass.currentVersion == null || sdkSettings.version === sdkSettings.sdkClass.currentVersion)) {
                isLoadNewSdk = false;
            }
        }
        if (!isLoadNewSdk) {
            if (callback && typeof callback.resolve === "function") {
                callback.resolve(sdkSettings.sdkClass);
            }
        } else {
            let {
                outputGlobalNamespace,
                releaseVersionUrl,
                cssUrl,
                loadErrorMessage
            } = this.props;

            if (releaseVersionUrl) {
                Object.assign(SdkLoadedMap[jsUrl], {
                    isLoaded: false,
                    isLoading: true
                });
                SdkLoadedMap[jsUrl].callbacks.push(callback);
                this.privates.isLoading = true;
                this.actions.update();

                $.get(`${releaseVersionUrl}?v=${new Date().getTime()}`).always(function (resp) {
                    let version = typeof resp === "string" ? resp : null;

                    if (!version || version.indexOf("<") > -1) {
                        version = new Date().getTime();
                    }
                    let prevSdkCssElement = null;
                    let prevSdkJsElement = null;
                    if (cssUrl) {
                        prevSdkCssElement = window.$(`link[href^='${cssUrl}']`);
                        let styleDom = document.createElement("link");
                        styleDom.rel = "stylesheet";
                        styleDom.type = "text/css";
                        styleDom.href = `${cssUrl}?v=${version}`;
                        document.getElementsByTagName('head')[0].appendChild(styleDom);
                    }

                    if (jsUrl) {
                        prevSdkJsElement = window.$(`script[src^='${jsUrl}']`);
                        let bakGlobalNamespace = window.__SDK_GLOBAL_NAMESPACE__;
                        window.__SDK_GLOBAL_NAMESPACE__ = outputGlobalNamespace;

                        let script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.async = true;
                        script.src = `${jsUrl}?v=${version || new Date().getTime()}`;

                        //多个时，loadedSdkClass有多个值，（如：bi,km同时是bi或km）,
                        //在执行onload 事件时，结合上下文，它们各自使用自己的，而相对错误的避免掉
                        let loadedSdkClass = null;
                        let sdkLoadedListener = function (sdkClass) {
                            loadedSdkClass = sdkClass;
                        };
                        SdkLoadedListeners.push(sdkLoadedListener);

                        script.onload = function () {
                            if (prevSdkCssElement != null) {
                                window.$(prevSdkCssElement).remove();
                            }
                            if (prevSdkJsElement != null) {
                                window.$(prevSdkJsElement).remove();
                            }
                            let index = SdkLoadedListeners.indexOf(sdkLoadedListener);
                            if (index !== -1) {
                                SdkLoadedListeners.splice(index, 1);
                            }
                            if (SdkLoadedMap[jsUrl].sdkClass != null && typeof SdkLoadedMap[jsUrl].sdkClass.destroy === "function") {
                                SdkLoadedMap[jsUrl].sdkClass.destroy();
                                SdkLoadedMap[jsUrl].sdkClass = null;
                            }
                            Object.assign(SdkLoadedMap[jsUrl], {
                                version,
                                sdkClass: loadedSdkClass,
                                isLoaded: true,
                                isLoading: false
                            });

                            if (bakGlobalNamespace !== undefined) {
                                window.__SDK_GLOBAL_NAMESPACE__ = bakGlobalNamespace;
                            } else {
                                delete window.__SDK_GLOBAL_NAMESPACE__;
                            }
                            let callbacks = SdkLoadedMap[jsUrl].callbacks;
                            if (callbacks.length > 0) {
                                for (let i = 0; i < callbacks.length; i++) {
                                    let sdkCallback = callbacks[i];
                                    if (sdkCallback != null && typeof sdkCallback.resolve === "function") {
                                        sdkCallback.resolve(loadedSdkClass);
                                    }
                                }
                                SdkLoadedMap[jsUrl].callbacks = [];
                            }
                        };

                        //加载失败处理
                        script.onerror = function () {
                            let callbacks = SdkLoadedMap[jsUrl].callbacks;
                            if (callbacks.length > 0) {
                                for (let i = 0; i < callbacks.length; i++) {
                                    let sdkCallback = callbacks[i];
                                    if (sdkCallback != null && typeof sdkCallback.reject === "function") {
                                        sdkCallback.reject();
                                    }
                                }
                                SdkLoadedMap[jsUrl].callbacks = [];
                            }
                            SdkLoadedMap[jsUrl].callbacks = [];
                            Object.assign(SdkLoadedMap[jsUrl], getDefaultSdkSettings());
                            // Udesk.ui.notify.error(`${loadErrorMessage}`);
                        };
                        document.getElementsByTagName('head')[0].appendChild(script);
                    }
                });
            }
        }
    }
    render() {
        let {
            showLoadingAnimation
        } = this.props;

        let {
            computes
        } = this.privates;

        let loadingPsitionTop = "20px";
        return (
            <If condition={showLoadingAnimation && !computes._isLoaded}>
                <div className="loader-container load7" ref={this.sdkLoaderInstance}>
                    <div className="loader" style={{ marginTop: loadingPsitionTop }}>
                    </div>
                </div>
            </If >
        );
    }
}

export default UdeskReact.udeskify(SdkLoader);

function creatLayer(that) {
    let sdkLoaderElement = that.sdkLoaderInstance ? that.sdkLoaderInstance.current : null;
    if (sdkLoaderElement) {
        let layerParentContainer = that.layerParentContainer;
        let layerLoadingDom = document.createElement("div");
        layerLoadingDom.setAttribute("class", "sdk-loader-layer");
        let layerLoadingDomStyle = `
        <style type="text/css">
            .sdk-loader-layer {
                position: absolute;
                top: 0;
                left: 0;
                z-index:1440;
                width: 100%;
                height: 100%;
                background: #000;
                opacity: .1;
            }
        </style>
       `;

        layerLoadingDom.innerHTML = layerLoadingDomStyle;

        let layerLoadingParentNode = sdkLoaderElement.parentNode;

        if (layerParentContainer) {
            if (typeof layerParentContainer === "string") {
                layerLoadingParentNode = document.querySelector(layerParentContainer);
            } else if (isDomElement(layerParentContainer)) {
                layerLoadingParentNode = layerParentContainer;
            } else {
                //
            }
        } else {
            layerLoadingParentNode.style.position = "relative";
        }
        if (layerLoadingParentNode && typeof layerLoadingParentNode.appendChild === "function") {
            layerLoadingParentNode.appendChild(layerLoadingDom);
        }

        that._layerLoadingDom = layerLoadingDom;
        that._layerLoadingParentNode = layerLoadingParentNode;

    }
}

function removeLayer(that) {
    let {
        "_layerLoadingDom": layerLoadingDom,
        "_layerLoadingParentNode": layerLoadingParentNode
    } = that;

    if (layerLoadingDom && layerLoadingDom.parentNode && typeof layerLoadingDom.parentNode.removeChild === "function") {
        layerLoadingDom.parentNode.removeChild(layerLoadingDom);
        that._layerLoadingDom = null;
        layerLoadingDom = null;
    }
}

function isDomElement(element) {
    if (typeof HTMLElement === 'object') {
        return element instanceof HTMLElement;
    } else {
        return (element && typeof element === 'object' && element.nodeType === 1 && typeof element.nodeName === 'string');
    }
}

function getDefaultSdkSettings(){
    return {
        version: null,
        sdkClass: null,
        isLoaded: false,
        isLoading: false,
        callbacks: []
    };
}