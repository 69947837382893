// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PutMessageUserNotificationsReadQuery, BaseResponseVoid } from '../../types';

/**
 * update
 *
 * @export
 * @tags message-user-notification-controller
 * @link [PUT] /messageUserNotifications/read
 */
export function putMessageUserNotificationsRead(
    options: ApiOptions<never, PutMessageUserNotificationsReadQuery> & {
        params: PutMessageUserNotificationsReadQuery;
    }
): Promise<BaseResponseVoid> {
    return request<never, never, PutMessageUserNotificationsReadQuery>(
        '/messageUserNotifications/read',
        { ...options, method: 'put', __$requestCalleeName: 'putMessageUserNotificationsRead' }
    );
}

export const meta = [
    {
        tags: ['message-user-notification-controller'],
        path: '/messageUserNotifications/read',
        method: 'put',
    },
];
