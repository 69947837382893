import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import TasksConditionIndexComponent from './component';

export default class TasksConditionIndexRoute extends TasksConditionIndexComponent {
    model() {
        let { props } = this;
        let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        params.type = 1;
        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionConditions",
                this.props.sdkOptions
            );
            Udesk.ajax.get(url, params)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.condition.index.title));
                        reject(reason);
                    }
                );
        });

        return {
            conditionTableModel: modelPromise,
            task: props.sdkOptions.props.task,
            taskId: params.taskId
        };
    }
}
