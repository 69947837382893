// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetUsersQuery,
    BaseResponseListUserFoundResponse,
    PostUsersBody,
    BaseResponseUserFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 用户
 * @link [GET] /users
 */
export function getUsers(): Promise<BaseResponseListUserFoundResponse>;
export function getUsers(
    options: ApiOptions<never, GetUsersQuery>
): Promise<BaseResponseListUserFoundResponse>;
export function getUsers(
    options?: ApiOptions<never, GetUsersQuery>
): Promise<BaseResponseListUserFoundResponse> {
    return request<never, never, GetUsersQuery>('/users', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getUsers',
    });
}

/**
 * save
 *
 * @export
 * @tags 用户
 * @link [POST] /users
 */
export function postUsers(data: PostUsersBody): Promise<BaseResponseUserFoundResponse>;
export function postUsers(
    data: PostUsersBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseUserFoundResponse>;
export function postUsers(
    data: PostUsersBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseUserFoundResponse> {
    return request<PostUsersBody, never, never>('/users', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postUsers',
    });
}

export const meta = [
    { tags: ['用户'], path: '/users', method: 'get' },
    { tags: ['用户'], path: '/users', method: 'post' },
];
