import {
    NotImplementedError
} from "../../../error";

/*
* options: 
*   tokenData token数据
*   progressCallback 上传进度的回调
*   successCallback  上传成功的回调
*   errorCallback  上传失败的回调¸
*/

export default class UploadBaseAdapterClass {
    loadPathData(files, tokenData) {
        throw new NotImplementedError("loadPathData");
    }
    upload(files, options) {
        throw new NotImplementedError("upload");
    }
    getRequestMethod(files, tokenData) {
        throw new NotImplementedError("getRequestMethod");
    }
    getRequestBody(files, tokenData) {
        throw new NotImplementedError("getRequestBody");
    }
    getRequestHeaders(files, tokenData) {
        throw new NotImplementedError("getRequestHeaders");
    }
}