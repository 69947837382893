import React from 'react';
import Udesk from 'Udesk';
import CaseBaseRoute from './route';
// import ReactTable from "udesk-react/src/components/react-table";
import UdeskTable from 'Component/common/udesk-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import './style.scss';
import {
    Drawer,
    Icon,
    Input,
    Tabs,
    Modal,
    Popconfirm,
    Tooltip,
    Button,
    Space,
    Popover,
} from 'udesk-ui';
import _concat from 'lodash-es/concat';
import _isEmpty from 'lodash-es/isEmpty';
import _isEqual from 'lodash-es/isEqual';
import { PageHeader } from 'udesk-ui';
import XssComponent from 'udesk-react/src/components/xss';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import ExportButton from 'Component/common/export-button';
import SafeHtml from 'Component/common/safe-html';

export default class CaseBaseTemplate extends CaseBaseRoute {
    render() {
        let { locales, privates, actions } = this;
        let { sdkOptions } = this.props;
        const { TabPane } = Tabs;
        let {
            data,
            visible,
            activeKey,
            visibleEdit,
            filteredList,
            filteredItems,
            drawData,
            remarkList,
            loading,
            tableRef,
            filterVisible,
            customName,
            customId,
        } = this.privates;
        let { pageNum, pageSize, conditionList, judgeStrategy, customJudgeLogic, total } =
            this.privates.storages;
        let { _columns, _cacheKey } = this.privates.computes;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        const DragHandle = sortableHandle(() => (
            <div className="icon-unordered-list">
                <Icon type="list-check" />
            </div>
        ));

        const SortableItem = sortableElement(({ item, field }) => {
            return (
                <li className="transfer-item" style={{ zIndex: '1100' }}>
                    <DragHandle />{' '}
                    {!field ? (
                        <span className="case-base-field-list">
                            <span className="case-base-field-list-left">{item.name}</span>
                            <span className="case-base-field-list-right">
                                <span className="icon-unordered-list">
                                    <i
                                        className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-bianji"
                                        onClick={actions.sortEdit.params(item.key, item.name)}
                                    ></i>
                                </span>
                                <If
                                    condition={
                                        hasFeature &&
                                        hasFeature('task:work:caseLibraryCategory:delete')
                                    }
                                >
                                    <span className="icon-unordered-list">
                                        <Popconfirm
                                            title={locales.components.pages.caseBaseList.delTitle}
                                            okText={locales.components.pages.caseBaseList.del}
                                            cancelText={
                                                locales.components.pages.caseBaseList.cancel
                                            }
                                            onConfirm={actions.caseBaseDelete.params(
                                                item.key,
                                                item.id
                                            )}
                                        >
                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-shanchu"></i>
                                        </Popconfirm>
                                    </span>
                                </If>
                            </span>
                        </span>
                    ) : (
                        <span className="case-base-field-list">
                            <span className="case-base-field-list-left">
                                <Input
                                    defaultValue={item.name}
                                    onChange={actions.inputChange.params(item.key)}
                                    style={{ width: '400px' }}
                                />
                            </span>
                            <span className="case-base-field-list-right">
                                <span className="icon-unordered-list">
                                    {' '}
                                    <i
                                        className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-baocun"
                                        onClick={actions.sortSave.params(item.key)}
                                    ></i>
                                </span>
                                <span
                                    className="icon-unordered-list"
                                    onClick={actions.inputClose.params(item.key)}
                                >
                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-quxiao"></i>
                                </span>
                            </span>
                        </span>
                    )}
                </li>
            );
        });

        const SortableContainer = sortableContainer(({ children }) => {
            return <ul className="transfer-list">{children}</ul>;
        });
        let DrawTitle = (
            <div className="case-base-draw-title">
                <div className="draw-title-page-right">
                    <a href="" onClick={actions.qualityDetail.params(this.privates.dataId)}>
                        {locales.components.pages.caseBaseList.seeDetail}
                    </a>
                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-arrow-right-copy-copy-copy"></i>
                </div>
            </div>
        );
        let columns = _columns.length
            ? _concat(_columns, [
                  {
                      key: 'actions',
                      title: locales.components.pages.caseBaseList.actions,
                      render: (name, item, index) => {
                          return (
                              <div style={{ marginLeft: '-8px' }}>
                                  {privates.data.caseLibraryDataMap &&
                                      (privates.data.caseLibraryDataMap[item.dataId] &&
                                      !_isEmpty(
                                          privates.data.caseLibraryDataMap[item.dataId].dialogText
                                      ) ? (
                                          <Button
                                              size="small"
                                              type="link"
                                              onClick={actions.handleClick.params(item)}
                                          >
                                              {locales.components.pages.caseBaseList.seeCaseBase}
                                          </Button>
                                      ) : (
                                          <Button
                                              size="small"
                                              type="link"
                                              onClick={actions.qualityDetail.params(
                                                  this.privates.data.caseLibraryDataMap[
                                                      item.dataId
                                                  ] &&
                                                      this.privates.data.caseLibraryDataMap[
                                                          item.dataId
                                                      ].inspectionDataId
                                              )}
                                          >
                                              {locales.components.pages.caseBaseList.seeDetail}
                                          </Button>
                                      ))}
                                  {hasFeature && hasFeature('task:work:caseLibrary:delete') && (
                                      <Button
                                          size="small"
                                          type="link"
                                          danger
                                          style={{ marginLeft: '10px' }}
                                          onClick={actions.handleClose.params(item)}
                                      >
                                          {locales.components.pages.caseBaseList.del}
                                      </Button>
                                  )}
                              </div>
                          );
                      },
                  },
              ])
            : _columns;

        return (
            <If condition={hasFeature && hasFeature('task:work:caseLibrary:view')}>
                <div className="udesk-qa-web-page">
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={locales.components.pages.caseBaseList.title}
                        subTitle={locales.components.pages.caseBaseList.tip}
                    />
                    <div className="udesk-qa-web-page-body">
                        <div className="udesk-qa-web-page-body-root case-base-page-review-list-body">
                            <Tabs
                                tabBarExtraContent={
                                    hasFeature &&
                                    hasFeature('task:work:caseLibraryCategory:submit') && (
                                        <a href="" onClick={actions.tabBarEdit}>
                                            {locales.components.pages.caseBaseList.edit}
                                        </a>
                                    )
                                }
                                // defaultActiveKey={!_isEmpty(filteredItems) && filteredItems[0].key}
                                activeKey={activeKey}
                                onTabClick={actions.onTabClick}
                            >
                                {filteredItems.map((item) => (
                                    <TabPane tab={item.name} key={item.key}>
                                        <div className="qa-react-list-page-content">
                                            <div style={{ textAlign: 'right', marginBottom: 16 }}>
                                                <Space>
                                                    <Popover
                                                        content={this.actions.queckListMang()}
                                                        placement="bottomRight"
                                                    >
                                                        <Button
                                                            disabled={loading}
                                                            onClick={actions.changeFilterVisible}
                                                        >
                                                            {locales.labels.filter}
                                                        </Button>
                                                    </Popover>
                                                    <If
                                                        condition={
                                                            hasFeature &&
                                                            hasFeature(
                                                                'task:work:caseLibrary:export'
                                                            )
                                                        }
                                                    >
                                                        {/* <Button onClick={actions.export.params(item.key)}>
                                                                    {locales.components.pages.caseBaseList.export}
                                                                </Button> */}
                                                        <ExportButton
                                                            disabled={loading}
                                                            exportDataCount={total}
                                                            hasFilterCondition={
                                                                !!conditionList.length
                                                            }
                                                            onClick={actions.export.params(
                                                                item.key
                                                            )}
                                                        />
                                                    </If>

                                                    <Button onClick={actions.onPopoverVisible}>
                                                        {
                                                            locales.components.pages
                                                                .customerServiceRankList.select
                                                                .column
                                                        }
                                                    </Button>
                                                </Space>
                                            </div>
                                            <UdeskTable
                                                key={item.key}
                                                cacheKey={_cacheKey}
                                                subtractedHeight={212}
                                                ref={tableRef}
                                                loading={loading}
                                                columns={columns}
                                                dataSource={data?.fieldDataList ?? []}
                                                rowKey={(item) => item.id}
                                                needFixedActionColumn={true}
                                                pagination={false}
                                            />
                                        </div>
                                        <div
                                            className="qa-react-list-page-footer"
                                            style={{ marginTop: 16 }}
                                        >
                                            <If
                                                condition={
                                                    !_isEmpty(data) && data.fieldDataList.length > 0
                                                }
                                            >
                                                <UdeskPagination
                                                    current={pageNum}
                                                    pageSize={pageSize}
                                                    total={total || 0}
                                                    onChange={actions.pageChanged}
                                                />
                                            </If>
                                        </div>
                                    </TabPane>
                                ))}
                            </Tabs>
                            <Drawer
                                title={DrawTitle}
                                placement="right"
                                closable={false}
                                getContainer={false}
                                onClose={actions.onClose}
                                mask={true}
                                zIndex={500}
                                visible={visible}
                            >
                                <div className="case-base-page-draw-body-content">
                                    {!_isEmpty(drawData) && !_isEmpty(drawData.voiceUrl) ? (
                                        <div className="case-base-audio">
                                            {' '}
                                            <audio
                                                id="media"
                                                src={drawData.voiceUrl}
                                                preload
                                                key={Date.now()}
                                                controls
                                            ></audio>
                                        </div>
                                    ) : (
                                        <div
                                            className="case-base-audio"
                                            style={{ display: 'none' }}
                                        >
                                            {' '}
                                            <audio
                                                id="media"
                                                src=""
                                                key={Date.now()}
                                                preload
                                                controls
                                            ></audio>
                                        </div>
                                    )}

                                    <div className="case-base-draw-body-talk">
                                        {!_isEmpty(drawData) &&
                                            !_isEmpty(drawData.dialogText) &&
                                            JSON.parse(drawData.dialogText).map((item) => {
                                                if (item.channel_id === 0) {
                                                    return (
                                                        <div className="case-base-draw-body-talk-customer">
                                                            <div className="case-base-draw-body-talk-icon">
                                                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-kehu"></i>
                                                            </div>
                                                            <div className="case-base-draw-body-talk-content">
                                                                {!_isEmpty(drawData.voiceUrl) ? (
                                                                    <div>
                                                                        {getAudioItemTime(
                                                                            item.begin_time / 1000,
                                                                            item.end_time / 1000
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div>{item.createdTime}</div>
                                                                )}
                                                                <div className="case-base-draw-body-talk-info">
                                                                    {!_isEmpty(
                                                                        drawData.voiceUrl
                                                                    ) ? (
                                                                        <XssComponent
                                                                            value={item.text}
                                                                        />
                                                                    ) : (
                                                                        <XssComponent
                                                                            value={
                                                                                item.text &&
                                                                                JSON.parse(
                                                                                    item.text
                                                                                ).data.content
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                {remarkList.find(
                                                                    (i) => i.index === item.id
                                                                ) && (
                                                                    <Tooltip
                                                                        title={
                                                                            remarkList.find(
                                                                                (i) =>
                                                                                    i.index ===
                                                                                    item.id
                                                                            ).remark
                                                                        }
                                                                        getPopupContainer={(
                                                                            triggerNode
                                                                        ) => triggerNode.parentNode}
                                                                    >
                                                                        <div
                                                                            className="message-tools-bar-remark"
                                                                            style={{
                                                                                textAlign: 'start',
                                                                            }}
                                                                        >
                                                                            {
                                                                                remarkList.find(
                                                                                    (i) =>
                                                                                        i.index ===
                                                                                        item.id
                                                                                ).remark
                                                                            }
                                                                        </div>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                } else if (item.channel_id === 1) {
                                                    return (
                                                        <div className="case-base-draw-body-talk-call">
                                                            <div className="case-base-draw-body-talk-content">
                                                                {!_isEmpty(drawData.voiceUrl) ? (
                                                                    <div
                                                                        style={{
                                                                            textAlign: 'right',
                                                                        }}
                                                                    >
                                                                        {getAudioItemTime(
                                                                            item.begin_time / 1000,
                                                                            item.end_time / 1000
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            textAlign: 'right',
                                                                        }}
                                                                    >
                                                                        {item.createdTime}
                                                                    </div>
                                                                )}
                                                                <div className="case-base-draw-body-talk-info">
                                                                    {!_isEmpty(
                                                                        drawData.voiceUrl
                                                                    ) ? (
                                                                        <XssComponent
                                                                            value={item.text}
                                                                        />
                                                                    ) : (
                                                                        <XssComponent
                                                                            value={
                                                                                item.text &&
                                                                                JSON.parse(
                                                                                    item.text
                                                                                ).data.content
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                {remarkList.find(
                                                                    (i) => i.index === item.id
                                                                ) && (
                                                                    <Tooltip
                                                                        title={
                                                                            remarkList.find(
                                                                                (i) =>
                                                                                    i.index ===
                                                                                    item.id
                                                                            ).remark
                                                                        }
                                                                        getPopupContainer={(
                                                                            triggerNode
                                                                        ) => triggerNode.parentNode}
                                                                    >
                                                                        <div
                                                                            className="message-tools-bar-remark"
                                                                            style={{
                                                                                textAlign: 'start',
                                                                            }}
                                                                        >
                                                                            {
                                                                                remarkList.find(
                                                                                    (i) =>
                                                                                        i.index ===
                                                                                        item.id
                                                                                ).remark
                                                                            }
                                                                        </div>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                            <div className="case-base-draw-body-talk-icon">
                                                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-kefu1"></i>
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                    </div>
                                    <div className="case-base-draw-body-remarks">
                                        {!_isEmpty(drawData) && (
                                            <SafeHtml
                                                content={drawData.remarks}
                                                className="case-base-draw-remarks-textarea"
                                            />
                                        )}
                                    </div>
                                </div>
                            </Drawer>
                            <Modal
                                visible={visibleEdit}
                                className="customer-information-modal case-base-modal-sort"
                                title={locales.components.pages.caseBaseList.edit}
                                getContainer={false}
                                onOk={actions.handleOk}
                                onCancel={actions.handleCancel}
                                okText={locales.components.pages.caseBaseList.save}
                                cancelText={locales.components.pages.caseBaseList.cancel}
                                closable
                            >
                                <div>
                                    <div className="case-base-modal-add" onClick={actions.sortAdd}>
                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tianjia"></i>
                                        <span>
                                            {locales.components.pages.caseBaseList.addCaseBase}
                                        </span>
                                    </div>
                                    <SortableContainer onSortEnd={actions.onSortEnd} useDragHandle>
                                        {!_isEmpty(filteredList) &&
                                            filteredList.map((item, index) => {
                                                return (
                                                    _isEqual(item.defaultFlag, 0) && (
                                                        <SortableItem
                                                            key={`item-${item.key}`}
                                                            index={index}
                                                            item={item}
                                                            field={this.privates.fields[item.key]}
                                                        />
                                                    )
                                                );
                                            })}
                                    </SortableContainer>
                                </div>
                            </Modal>
                            <CustomFilterModal
                                visible={filterVisible}
                                cancel={actions.cancel}
                                applyAction={actions.handleConditionApply}
                                onConditionFilterSaved={actions.onConditionFilterSaved}
                                judgeStrategy={judgeStrategy}
                                customJudgeLogic={customJudgeLogic}
                                customName={customName}
                                customId={customId}
                                conditionList={conditionList}
                                type={this.props.sdkOptions.props.task.inspectDataSource}
                                taskId={this.props.match.params.taskId}
                                taskName={this.props.sdkOptions.props.task.name}
                                filterList={['f_8', 'r_1', 'r_2', 'r_3']}
                            />
                        </div>
                    </div>
                </div>
            </If>
        );
    }
}
function getAudioItemTime(startPoint, endPoint) {
    let start = Udesk.utils.date.formatDurationWithMillisecond(startPoint);
    let end = Udesk.utils.date.formatDurationWithMillisecond(endPoint);
    return `${start} - ${end}`;
}
