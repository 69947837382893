import Locales from '../locales/index';
import UdeskLocales from 'UdeskLocales';

const customerDetailType = [
    { id: 'all', key: 'all', name: () => Locales.current.enums.gongRolesTypes.all },
    { id: 'agent', key: 'agent', name: () => Locales.current.enums.roles.agent },
    { id: 'customer', key: 'customer', name: () => Locales.current.enums.roles.customer },
];

const roles = [
    { id: 0, key: 'agent', name: () => Locales.current.enums.roles.agent },
    { id: 1, key: 'customer', name: () => Locales.current.enums.roles.customer },
];

const commentVisibleAuthority = [
    { id: 0, key: 'all', name: () => Locales.current.enums.commentVisibleAuthority.all },
    { id: 1, key: 'self', name: () => Locales.current.enums.commentVisibleAuthority.self },
    {
        id: 2,
        key: 'designation',
        name: () => Locales.current.enums.commentVisibleAuthority.designation,
    },
];

let roleType = [
    { id: 3, key: 'all', name: () => Locales.current.enums.gongRolesTypes.all },
    { id: 1, key: 'staff', name: () => Locales.current.enums.gongRolesTypes.staff },
    { id: 2, key: 'department', name: () => Locales.current.enums.gongRolesTypes.department },
];

let statisticsDate = [
    { id: 1, key: 'week', name: () => Locales.current.enums.gongStatisticsDate.week },
    { id: 2, key: 'month', name: () => Locales.current.enums.gongStatisticsDate.month },
    { id: 3, key: 'season', name: () => Locales.current.enums.gongStatisticsDate.season },
];

let statisticsType = [
    { id: 1, key: 'unit', name: () => '#' },
    { id: 2, key: 'percent', name: () => '%' },
];

let coachTaskStatus = [
    { id: 0, key: 'wait', name: () => Locales.current.enums.gongCoachTaskStatus.wait },
    { id: 1, key: 'already', name: () => Locales.current.enums.gongCoachTaskStatus.already },
    { id: -1, key: 'ignore', name: () => Locales.current.enums.gongCoachTaskStatus.ignore },
];

let callRangeStatus = [
    { id: '', key: 'all', name: () => Locales.current.enums.callRangeStatus.all },
    { id: 1, key: 'sale', name: () => Locales.current.enums.callRangeStatus.sale },
    { id: 2, key: 'customer', name: () => Locales.current.enums.callRangeStatus.customer },
];

let todoListType = [
    { id: 1, key: 'later', name: () => Locales.current.enums.todoListType.later },
    { id: 2, key: 'help', name: () => Locales.current.enums.todoListType.help },
    { id: 3, key: 'contact', name: () => Locales.current.enums.todoListType.contact },
    { id: 4, key: 'send', name: () => Locales.current.enums.todoListType.send },
    { id: 5, key: 'warn', name: () => Locales.current.enums.todoListType.warn },
];

let knowledgeBaseType = [
    {
        id: 1,
        key: 'knowledge',
        name: () => /* 知识点 */ Locales.current.enums.gong.knowledgePoints,
    },
    {
        id: 2,
        key: 'process',
        name: () => /* 流程 */ Locales.current.enums.gong.technologicalProcess,
    },
];

let subjectMarkStatus = [
    { id: 0, key: 'before', name: () => /* 未标注 */ Locales.current.enums.gong.notMarked },
    {
        id: 1,
        key: 'workInProgress',
        name: () => /* 标注中 */ Locales.current.enums.gong.inAnnotation,
    },
    { id: 2, key: 'done', name: () => /* 已标注 */ Locales.current.enums.gong.annotated },
];

const sessionActivity = [
    {
        name: () => Locales.current.enums.sessionActivity.single,
        key: 'single',
        id: 0,
    },
    {
        name: () => Locales.current.enums.sessionActivity.all,
        key: 'all',
        id: 1,
    },
    {
        name: () => Locales.current.enums.sessionActivity.allTime,
        key: 'allTime',
        id: 2,
    },
];

const sessionAction = [
    {
        name: () => Locales.current.enums.sessionAction.salesProportion,
        key: 'salesProportion',
        id: 0,
    },
    {
        name: () => Locales.current.enums.sessionAction.salesLongest,
        key: 'salesLongest',
        id: 1,
    },
    {
        name: () => Locales.current.enums.sessionAction.customerLongest,
        key: 'customerLongest',
        id: 2,
    },
    {
        name: () => Locales.current.enums.sessionAction.discussNumber,
        key: 'discussNumber',
        id: 3,
    },
    {
        name: () => Locales.current.enums.sessionAction.frequency,
        key: 'frequency',
        id: 4,
    },
];

const monitorCriteria = [
    {
        name: () => Locales.current.enums.monitorCriteria.all,
        key: 'all',
        id: 0,
    },
    {
        name: () => Locales.current.enums.monitorCriteria.before,
        key: 'before',
        id: 1,
    },
    {
        name: () => Locales.current.enums.monitorCriteria.after,
        key: 'after',
        id: 2,
    },
];

const monitorTimeCycle = [
    {
        name: () => Locales.current.enums.monitorTimeCycle.day,
        key: 'day',
        id: 0,
    },
    {
        name: () => Locales.current.enums.monitorTimeCycle.week,
        key: 'week',
        id: 1,
    },
    {
        name: () => Locales.current.enums.monitorTimeCycle.month,
        key: 'month',
        id: 2,
    },
];

const monitorGroupTestType = [
    {
        name: () => Locales.current.enums.monitorGroupTestType.ab,
        key: 'ab',
        id: 0,
    },
    {
        name: () => Locales.current.enums.monitorGroupTestType.a,
        key: 'a',
        id: 1,
    },
    {
        name: () => Locales.current.enums.monitorGroupTestType.b,
        key: 'b',
        id: 2,
    },
];

const riskSettingsTypeList = [
    // 1-会话活动, 2-会话动作 3-风险语义 4-未跟进天数 5-随录数据
    {
        name: () => /* 会话活动 */ UdeskLocales['current'].enums.gong.sessionActivity,
        key: 'sessionActivity',
        id: 1,
    },
    {
        name: () => /* 会话动作 */ UdeskLocales['current'].enums.gong.conversationAction,
        key: 'conversationAction',
        id: 2,
    },
    {
        name: () => /* 风险语义 */ UdeskLocales['current'].enums.gong.riskSemantics,
        key: 'riskSemantics',
        id: 3,
    },
    {
        name: () => /* 未跟进天数 */ UdeskLocales['current'].enums.gong.daysNotFollowedUp,
        key: 'daysNotFollowedUp',
        id: 4,
    },
    {
        name: () => /* 随路数据 */ UdeskLocales['current'].enums.gong.waysideData,
        key: 'accompanyingData',
        id: 5,
    },
];

const learningTaskStatusType = [
    {
        name: () => /* 未开始 */ UdeskLocales['current'].enums.gong.notStarted,
        key: 'notStarted',
        id: 1,
    },
    {
        name: () => /* 已完成 */ UdeskLocales['current'].enums.gong.completed,
        key: 'completed',
        id: 3,
    },
    {
        name: () => /* 进行中 */ UdeskLocales['current'].enums.gong.haveInHand,
        key: 'underWay',
        id: 2,
    },
];

const corseStatusType = [
    {
        name: () => /* 未发布 */ UdeskLocales['current'].enums.gong.unpublished,
        key: 'notStarted',
        id: 1,
    },
    {
        name: () => /* 已发布 */ UdeskLocales['current'].enums.gong.published,
        key: 'underWay',
        id: 2,
    },
];

let callAnalysisChannelTypes = [
    {
        id: 0,
        key: 'all',
        name: () => /* 全部 */ Locales.current.pages.gong.common.filterScreen.whole,
    },
    {
        id: 1,
        key: 'voiceRecording',
        name: () => /* 通话录音 */ Locales.current.enums.semanticIntelligence.callRecording,
    },
    {
        id: 13,
        key: 'weChatCall',
        name: () => /* 企微通话 */ Locales.current.enums.semanticIntelligence.enterpriseWeChatCall,
    },
    {
        id: 14,
        key: 'weChatText',
        name: () => /* 企微文本 */ Locales.current.enums.semanticIntelligence.enterpriseWeChatText,
    },
];

export default {
    corseStatusType,
    learningTaskStatusType,
    customerDetailType,
    roleType,
    statisticsDate,
    statisticsType,
    coachTaskStatus,
    roles,
    commentVisibleAuthority,
    callRangeStatus,
    todoListType,
    knowledgeBaseType,
    subjectMarkStatus,
    sessionActivity,
    sessionAction,
    monitorCriteria,
    monitorTimeCycle,
    monitorGroupTestType,
    riskSettingsTypeList,
    callAnalysisChannelTypes,
};
