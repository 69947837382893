export default {
  lang: {
    export: 'Экспорт',
    successfully: 'Успешное создание экспортной задачи',
    pleaseJumpTo: 'Пожалуйста, перейдите на',
    uploadAndDownloadCenter: 'Загрузить центр загрузки',
    check: 'Посмотреть',
    exportAll: 'Подтвердите экспорт всего контента?',
    total: 'Всего',
    confirm: 'Данные, подтвердить экспорт?',
    error:
      'Количество данных, которые можно экспортировать, равно 0, измените условия фильтрации и повторите попытку',
  },
};
