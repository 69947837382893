import Udesk from 'Udesk';
import SpotCheckListDetailComponent from './component';

class SpotCheckListDetailRoute extends SpotCheckListDetailComponent {
    asyncModel(changedParams) {
        let {
            sdkOptions,
            match,
            history,
        } = this.props;
        // let {relatedDatasPaging} = this.privates.storages;
        let id = match.params.id;
        if (changedParams && changedParams.id) {
            id = changedParams.id;
        }
        let taskId = match.params.taskId;
        let inspectionId = null;
        let historySearch = history.location.search;
        if (historySearch) {
            inspectionId = historySearch.split("=")[1];
        }
        if (changedParams && changedParams.inspectionId) {
            inspectionId = changedParams.inspectionId;
        }
        if (id == null || inspectionId == null) {
            return {};
        }
        this.privates.renderLoading = true;
        if (this.privates.detail.id) {
            this.privates.detail = {};
        }
        this.actions.update();

        let detailUrl = Udesk.business.apiPath.concatApiPath(`sample/${id}?taskId=${taskId}`, sdkOptions);
        let detailPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(detailUrl).then(
                (resp) => {
                    resolve({detail: resp});
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        // .then(detailResp=>{
        //     let relatedData = {
        //         id:detailResp.data.callId,
        //         pageNum:relatedDatasPaging.pageNum,
        //         pageSize:relatedDatasPaging.pageSize
        //     };
        //     let relatedDataUrl = Udesk.business.apiPath.concatApiPath(`data/related-data`, sdkOptions);
        //     return new Promise((resolve,reject)=>{
        //         Udesk.ajax.post(relatedDataUrl,relatedData).then(
        //             (resp) => {
        //                 resolve({detail: detailResp, relateData: resp});
        //             },
        //             (reason) => {
        //                 Udesk.ui.notify.error(reason.errorMsg);
        //                 reject(reason);
        //             }
        //         );
        //     });
        // });

        let changeListUrl = Udesk.business.apiPath.concatApiPath(`inspectionData/${inspectionId}/changeList?taskId=${taskId}`, sdkOptions);
        let changeListPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(changeListUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        let smartTagsUrl = Udesk.business.apiPath.concatApiPath(`callTags/${inspectionId}?taskId=${taskId}`, sdkOptions);
        let smartTagsPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(smartTagsUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let caseBaseUrl = Udesk.business.apiPath.concatApiPath(`caseLibraryCategorys/byInspectionId`, sdkOptions);
        let caseBase = new Promise((resolve, reject) => {
            Udesk.ajax.get(caseBaseUrl, { taskId, inspectionId: id, pageNum: 1, pageSize: 51 }).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let sysConfigPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/companys/sys/config', this.props.sdkOptions);
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let checkPointsPromise = new Promise((resolve, reject) => {
            let checkPointsUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${match.params.taskId}/pointList`,
                sdkOptions
            );
            Udesk.ajax.get(checkPointsUrl).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return ({
            detailWithRelateData: detailPromise,
            caseBase:caseBase,
            changeList: changeListPromise,
            smartTags: smartTagsPromise,
            sysConfig: sysConfigPromise,
            checkPoints: checkPointsPromise,
        });
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}

export default SpotCheckListDetailRoute;
