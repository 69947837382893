import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { Button, message, Progress, Space } from "udesk-ui";
import { createDefaultMoment } from 'src/util/moment';
import { isExercise } from '../util';
import { inspect } from 'src/util/core';
import './index.less';

export default React.memo<any>((props) => {
    const {
        nextMessage,
        submitMessage,
        loadingForSuspend,
        isSuspending,
        record,
        pauseTask, 
        taskDetail, 
        isLastNode,
        isStartTask, 
        submitTaskHandle, 
        taskMessageModalRef
    } = props;

    const clickHandle = useCallback(() => {
        taskMessageModalRef.current?.open(3); // 提交确认
    }, [taskMessageModalRef]);

    const isSingle = useMemo(() => taskDetail?.singleLimitDuration > 0, [taskDetail]);

    return (
        <div className='other-toolbar'>
            {
                isSingle ? (
                    isLastNode ? null : (
                        <AnswerRemainingTime {...{
                            singleLimitDuration: taskDetail?.singleLimitDuration,
                            isSuspending,
                            nextMessage,
                            submitMessage
                        }}/>
                    )
                ) : (
                    isLastNode ? null : (
                        <TaskRemainingTime
                            {...{
                                record,
                                taskDetail,
                                isStartTask,
                                isSuspending,
                                submitTaskHandle,
                            }}
                        />
                    )
                )
            }
            {
                isStartTask && (
                    isExercise(taskDetail.taskType, true, false) && !isLastNode ? (
                        <Space>
                            {
                                inspect(
                                    taskDetail?.dialogueMode !== Udesk.enums.learningTaskDialogueModeType.phone.id,
                                    () => <Button loading={loadingForSuspend} onClick={pauseTask} size='large'>{/* 暂停 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskSubmitButton.index.suspend}</Button>
                                )
                            } 
                            <Button onClick={clickHandle} type='primary' size='large'>{/* 提交 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskSubmitButton.index.submit}</Button>
                        </Space>
                    ) : (
                        <Button onClick={clickHandle} type='primary' size='large'>{/* 提交 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskSubmitButton.index.submit}</Button>
                    )
                )
            }
        </div>
    );
});

export const AnswerRemainingTime = React.memo((props: any) => {
    const {
        nextMessage,
        isSuspending,
        submitMessage,
        singleLimitDuration
    } = props;
    const [data, setData] = useState<any>();
    const [seconds, setSeconds] = useState<number>(singleLimitDuration * 60);
    const timeRef = useRef<Moment>();
    const handleRef = useRef<Function>();

    const startClock = useCallback(() => {
        setSeconds(seconds => {
            timeRef.current?.subtract(1, 'seconds');
            return seconds - 1;
        });
    }, []);

    useEffect(() => {
        handleRef.current =  submitMessage;
    }, [submitMessage]);

    useEffect(() => {
        if (singleLimitDuration) {
            setSeconds(singleLimitDuration * 60);
        }
    }, [
        singleLimitDuration
    ]);

    useEffect(() => {
        if (nextMessage) {
            if (singleLimitDuration) {
                setData(nextMessage);
                setSeconds(singleLimitDuration * 60);
            }
        }
    }, [
        singleLimitDuration, 
        nextMessage,
    ]);

    useEffect(() => {
        let timer:any;

        if (!isSuspending && data) {
            if (seconds! > 0) {
                timer = setTimeout(startClock, 1000);
            }
            if (seconds! <= 0) {
                handleRef.current?.(true);
            }
        }
        return () => {
            clearTimeout(timer);
        };
    }, [
        data, 
        seconds, 
        startClock,
        isSuspending
    ]);

    return (
        <div>
            <span>{/* 本题剩余时间 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskSubmitButton.index.remainingTimeOfThisQuestion}</span>
            <span className='answer-remaining-time'>
                <Progress type="circle" percent={seconds * 100 / singleLimitDuration / 60 } format={() => `${seconds}s`} status="normal"/>
            </span>
        </div>
    );
});

/**
 * 这里重新获取任务已开始秒数
 * @param record 任务记录数据（后台）
 * @returns 
 */
export const TaskRemainingTime = React.memo((props: any) => {
    const {record, taskDetail, isStartTask, isSuspending, submitTaskHandle} = props;
    const [seconds, setSeconds] = useState(0);
    const [isInitSeconds, setIsInitSeconds] = useState(false);
    const isVisible = useMemo(() => !!taskDetail?.limitDuration, [taskDetail?.limitDuration]);
    const timeRef = useRef<Moment>();
    const handleRef = useRef<Function>();

    const startClock = useCallback(() => {
        setSeconds(seconds => {
            timeRef.current?.subtract(1, 'seconds');
            return seconds - 1;
        });
    }, []);

    useEffect(() => {
        handleRef.current = submitTaskHandle;
    }, [submitTaskHandle]);

    useEffect(() => {
        if (taskDetail?.limitDuration) {
            if (taskDetail?.limitDuration) {
                let seconds = taskDetail?.limitDuration * 60;
                if (record) {
                    seconds -= Math.ceil(moment().diff(createDefaultMoment(record.dialogueStartTime)) / 1000);
                }
                setSeconds(seconds);
                setIsInitSeconds(true);
                timeRef.current = moment('000000', 'Hmmss').second(seconds);
            }
        }
    }, [
        record, 
        taskDetail?.limitDuration
    ]);

    useEffect(() => {
        let timer:any;
        if (!isSuspending && isStartTask && isVisible && isInitSeconds) {
            if (seconds > 0) {
                timer = setTimeout(startClock, 1000);
            }
            if (seconds === 10) {
                message.warning(/* 距离结束时间还剩10秒 */UdeskLocales['current'].pages.coach.learningCenter.components.taskSubmitButton.index.SecondsLeftUntilTheEndTime);
            }
            if (seconds <= 0) {
                handleRef.current?.();
            }
        }
        return () => {
            clearTimeout(timer);
        };
    }, [
        isSuspending, 
        isInitSeconds, 
        isStartTask, 
        isVisible, 
        seconds, 
        startClock,
    ]);
    
    return (
        <div>
            <span>{/* 剩余时间 */}{UdeskLocales['current'].pages.coach.learningCenter.components.taskSubmitButton.index.remainingTime}</span>
            <span>{isVisible ? timeRef.current?.format('HH:mm:ss') : '--:--:--'}</span>
        </div>
    );
});