// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetKnowledgeDatasQuery,
    BaseResponseListKnowledgeDataFoundResponse,
    PostKnowledgeDatasBody,
    BaseResponseKnowledgeDataFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags knowledge-data-controller
 * @link [GET] /knowledgeDatas
 */
export function getKnowledgeDatas(): Promise<BaseResponseListKnowledgeDataFoundResponse>;
export function getKnowledgeDatas(
    options: ApiOptions<never, GetKnowledgeDatasQuery>
): Promise<BaseResponseListKnowledgeDataFoundResponse>;
export function getKnowledgeDatas(
    options?: ApiOptions<never, GetKnowledgeDatasQuery>
): Promise<BaseResponseListKnowledgeDataFoundResponse> {
    return request<never, never, GetKnowledgeDatasQuery>('/knowledgeDatas', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getKnowledgeDatas',
    });
}

/**
 * save
 *
 * @export
 * @tags knowledge-data-controller
 * @link [POST] /knowledgeDatas
 */
export function postKnowledgeDatas(
    data: PostKnowledgeDatasBody
): Promise<BaseResponseKnowledgeDataFoundResponse>;
export function postKnowledgeDatas(
    data: PostKnowledgeDatasBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseKnowledgeDataFoundResponse>;
export function postKnowledgeDatas(
    data: PostKnowledgeDatasBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseKnowledgeDataFoundResponse> {
    return request<PostKnowledgeDatasBody, never, never>('/knowledgeDatas', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postKnowledgeDatas',
    });
}

export const meta = [
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas', method: 'get' },
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas', method: 'post' },
];
