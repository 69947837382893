import React, { useState, useEffect, useMemo } from 'react';
// import Udesk from 'Udesk';
// import CallTextViewComponent from './component';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'udesk-ui';
import Locales from 'UdeskLocales';

import './style.scss';


const CallTextViewTemplate = (props) => {
    const locales = Locales['current'];

    let { callInfoList = [], fieldValueMap = {} } = props;
    if(!fieldValueMap) {
        fieldValueMap = {};
    }
    const [copied, setCopied] = useState(null);
    const copyText = (id) => {
        setCopied(id);
    };
    useEffect(() => {
        if(copied !== null){
            setTimeout(() => {
                setCopied(null);
            }, 1000);    
        }
    }, [copied]);

    const callInfoListWithCopy = useMemo(() => {
        if(!Array.isArray(callInfoList)) return [];

        return callInfoList.map(i => ({
            ...i,
            onCopy: copyText.bind(null, i.id),
        }));
    }, [callInfoList]);
    return (
        <div className='call-text-list-container'>
            <ul className='call-text-list'>
                {
                    Array.isArray(callInfoListWithCopy) && callInfoListWithCopy.map((item, index) => {

                        const text = getFieldValue(item.id, fieldValueMap);

                        return (
                            <li key={index} className='call-text-list-item'>
                                <span className='call-text-list-item-title' title={item.label}>
                                    {item.label}
                                </span>
                                <span className='call-text-list-item-content'>
                                    <Choose>
                                        <When condition={item.fieldType === 12}>
                                            <With workOrders={getWorkOrders(item, fieldValueMap)}>
                                                <Choose>
                                                    <When condition={workOrders.type === 'string'}>
                                                        {workOrders.value}
                                                    </When>
                                                    <Otherwise>
                                                        <For each='order' index='index' of={workOrders.value}>
                                                            <a
                                                                href={order.url}
                                                                target='_blank'
                                                                rel='noopener'
                                                                className='work-order-number-href'
                                                            >
                                                                {order.number}
                                                            </a>
                                                            <If condition={workOrders.value.length !== index + 1}>
                                                                {locales.labels.comma}
                                                            </If>
                                                        </For>
                                                    </Otherwise>
                                                </Choose>
                                            </With>
                                        </When>
                                        <When condition={checkTextIsUrl(text)}>
                                            {text.split(',').map((url) => (
                                                <a
                                                    target="__blank"
                                                    href={url.trim()}
                                                    style={{ display: 'block' }}
                                                >
                                                    {url.trim()}
                                                </a>
                                            ))}
                                        </When>
                                        <Otherwise>
                                            <CopyToClipboard
                                                text={text}
                                                onCopy={item.onCopy}
                                            >
                                                <Tooltip
                                                    visible={copied === item.id}
                                                    title={locales.labels.copyed}
                                                >
                                                    <div
                                                        className='call-text-list-item-content-with-copy'
                                                    >
                                                        {text}
                                                    </div>
                                                </Tooltip>
                                            </CopyToClipboard>
                                        </Otherwise>
                                    </Choose>
                                </span>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default React.memo(CallTextViewTemplate);

function getFieldValue(id, fieldValueMap) {    
    return fieldValueMap[id];
}

function getWorkOrders(field, fieldValueMap) {
    let valueString = fieldValueMap[field.id];
    let valueArray = [];
    if (valueString) {
        valueArray = valueString.split(',');
    }
    let optionValueList = field.optionValueList;
    if (optionValueList && optionValueList.length > 0) {
        let url = optionValueList[0].name;
        if (url) {
            valueArray = valueArray.map((item) => {
                return {
                    number: item,
                    url: url + item.substring(1),
                };
            });
            return {
                type: 'array',
                value: valueArray,
            };
        }
        return {
            type: 'string',
            value: valueString,
        };
    }
    return {
        type: 'string',
        value: valueString,
    };
}

function checkTextIsUrl(text) {
    const validUrlRegex = /^(https?:\/\/)?[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
    return validUrlRegex.test(text);
}