// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerNodeDialogueFrameworkConfigQuery,
    BaseResponseIntelligentPartnerDialogNodeDialogueFrameworkConfigFoundResponse,
    PostReviewIntelligentPartnerNodeDialogueFrameworkConfigBody,
    PutReviewIntelligentPartnerNodeDialogueFrameworkConfigBody,
} from '../../../../types';

/**
 * 根据nodeId查询对话框架信息
 *
 * @export
 * @tags 智能陪练-AI对话框架节点配置
 * @link [GET] /review/intelligentPartner/node/dialogueFrameworkConfig
 */
export function getReviewIntelligentPartnerNodeDialogueFrameworkConfig(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodeDialogueFrameworkConfigQuery> & {
        params: GetReviewIntelligentPartnerNodeDialogueFrameworkConfigQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeDialogueFrameworkConfigFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerNodeDialogueFrameworkConfigQuery>(
        '/review/intelligentPartner/node/dialogueFrameworkConfig',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeDialogueFrameworkConfig',
        }
    );
}

/**
 * save
 *
 * @export
 * @tags 智能陪练-AI对话框架节点配置
 * @link [POST] /review/intelligentPartner/node/dialogueFrameworkConfig
 */
export function postReviewIntelligentPartnerNodeDialogueFrameworkConfig(
    data: PostReviewIntelligentPartnerNodeDialogueFrameworkConfigBody
): Promise<BaseResponseIntelligentPartnerDialogNodeDialogueFrameworkConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeDialogueFrameworkConfig(
    data: PostReviewIntelligentPartnerNodeDialogueFrameworkConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeDialogueFrameworkConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeDialogueFrameworkConfig(
    data: PostReviewIntelligentPartnerNodeDialogueFrameworkConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeDialogueFrameworkConfigFoundResponse> {
    return request<PostReviewIntelligentPartnerNodeDialogueFrameworkConfigBody, never, never>(
        '/review/intelligentPartner/node/dialogueFrameworkConfig',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeDialogueFrameworkConfig',
        }
    );
}

/**
 * update
 *
 * @export
 * @tags 智能陪练-AI对话框架节点配置
 * @link [PUT] /review/intelligentPartner/node/dialogueFrameworkConfig
 */
export function putReviewIntelligentPartnerNodeDialogueFrameworkConfig(
    data: PutReviewIntelligentPartnerNodeDialogueFrameworkConfigBody
): Promise<BaseResponseIntelligentPartnerDialogNodeDialogueFrameworkConfigFoundResponse>;
export function putReviewIntelligentPartnerNodeDialogueFrameworkConfig(
    data: PutReviewIntelligentPartnerNodeDialogueFrameworkConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeDialogueFrameworkConfigFoundResponse>;
export function putReviewIntelligentPartnerNodeDialogueFrameworkConfig(
    data: PutReviewIntelligentPartnerNodeDialogueFrameworkConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeDialogueFrameworkConfigFoundResponse> {
    return request<PutReviewIntelligentPartnerNodeDialogueFrameworkConfigBody, never, never>(
        '/review/intelligentPartner/node/dialogueFrameworkConfig',
        {
            ...options,
            data,
            method: 'put',
            __$requestCalleeName: 'putReviewIntelligentPartnerNodeDialogueFrameworkConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-AI对话框架节点配置'],
        path: '/review/intelligentPartner/node/dialogueFrameworkConfig',
        method: 'get',
    },
    {
        tags: ['智能陪练-AI对话框架节点配置'],
        path: '/review/intelligentPartner/node/dialogueFrameworkConfig',
        method: 'post',
    },
    {
        tags: ['智能陪练-AI对话框架节点配置'],
        path: '/review/intelligentPartner/node/dialogueFrameworkConfig',
        method: 'put',
    },
];
