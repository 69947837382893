// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetSmartTagsCategoryWithTagsByFuncTypeParam,
    BaseResponseListSmartTagCategory,
} from '../../../types';

/**
 * findCategoryWithTags
 *
 * @export
 * @tags smart-tag-controller
 * @link [GET] /smartTags/categoryWithTags/{funcType}
 * @param funcType
 */
export function getSmartTagsCategoryWithTagsByFuncType(
    options: ApiOptions<GetSmartTagsCategoryWithTagsByFuncTypeParam, never> & {
        segments: GetSmartTagsCategoryWithTagsByFuncTypeParam;
    }
): Promise<BaseResponseListSmartTagCategory> {
    return request<never, GetSmartTagsCategoryWithTagsByFuncTypeParam, never>(
        '/smartTags/categoryWithTags/{funcType}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getSmartTagsCategoryWithTagsByFuncType',
        }
    );
}

export const meta = [
    {
        tags: ['smart-tag-controller'],
        path: '/smartTags/categoryWithTags/{funcType}',
        method: 'get',
    },
];
