export default {
    highlightText,
    clearHighlight,
    highlightTextIndex
};

function highlightText(elem, keyword, options) {
    if (!keyword) return;
    let textNodes = getTextNodesIn(elem, n => {
        let keywordRegExp = new RegExp(keyword);
        return !/^\n\s*$/.test(n.nodeValue) && keywordRegExp.test(n.nodeValue);
    });
    let splitRegExp = new RegExp(`(${keyword})`);
    textNodes.forEach(node => {
        let txtArr = node.nodeValue.split(splitRegExp).filter(txt => txt !== '');
        txtArr.forEach(txt => {
            let newNode;
            if (txt !== keyword) {
                newNode = document.createTextNode(txt);
            } else {
                let tagName = (options && options.tagName) || 'em';
                newNode = document.createElement(tagName);
                newNode.setAttribute("_udeskHighlight", "");
                if (options && options.className) {
                    newNode.className = options.className;
                }
                newNode.appendChild(document.createTextNode(txt));
            }
            node.parentNode.insertBefore(newNode, node);
        });
        node.parentNode.removeChild(node);
    });
}
function highlightTextIndex(elem, item, options) {
    if (!item) return;
    let textNodes = getTextNodesIn(elem);
    let start = item.start, length = item.length;
    let currentStart = 0;
    let txtArr = [];
    let strArr = [];
    for (let i = textNodes.length - 1; i >= 0; i--) {
        if (!item.start && !item.length) {
            txtArr = [{ text: textNodes[i].nodeValue, flag: true }].filter(txt => txt.text !== '');
        } else {
            if (length === 0) return;
            if ((start >= currentStart && start < currentStart + textNodes[i].length) || strArr.length > 0) {
                let currentStr = '';
                if (strArr.length === 0) {
                    let startStr = textNodes[i].nodeValue.slice(0, start - currentStart);
                    let endStr = textNodes[i].nodeValue.slice(start - currentStart + length);
                    currentStr = textNodes[i].nodeValue.substr(start - currentStart, length);
                    txtArr = [{ text: startStr, flag: false }, { text: currentStr, flag: true }, { text: endStr, flag: false }].filter(txt => txt.text !== '');
                } else {
                    let endStr = textNodes[i].nodeValue.slice(length);
                    currentStr = textNodes[i].nodeValue.substr(0, length);
                    txtArr = [{ text: currentStr, flag: true }, { text: endStr, flag: false }].filter(txt => txt.text !== '');
                }
                strArr.push(currentStr);
                length -= currentStr.length;
            }
            currentStart += textNodes[i].length;

        }
        for (let j = 0; j < txtArr.length; j++) {
            let newNode;
            if (!txtArr[j].flag) {
                newNode = document.createTextNode(txtArr[j].text);
            } else {
                let tagName = (options && options.tagName) || 'em';
                newNode = document.createElement(tagName);
                newNode.setAttribute("_udeskHighlight", "");
                if (options && options.className) {
                    newNode.className = options.className;
                }
                newNode.appendChild(document.createTextNode(txtArr[j].text));

            }
            textNodes[i].parentNode.insertBefore(newNode, textNodes[i]);
        }
        if (txtArr && txtArr.length > 0) {
            textNodes[i].parentNode.removeChild(textNodes[i]);
        }

    }

}
function clearHighlight(elem) {
    let emElems = getElementsByAttributeName(elem, "_udeskHighlight");

    for (let i = emElems.length - 1; i >= 0; i--) {
        let emNode = emElems[i];
        let parentNode = emNode.parentNode;
        let newNode = document.createTextNode(emNode.textContent);
        parentNode.insertBefore(newNode, emNode);
        parentNode.removeChild(emNode);
        parentNode.normalize();
    }
}

function getTextNodesIn(elem, opt_fnFilter) {
    let textNodes = [];
    if (elem) {
        for (let nodes = elem.childNodes, i = nodes.length; i--;) {
            let node = nodes[i], nodeType = node.nodeType;
            if (nodeType === 3) {
                if (!opt_fnFilter || opt_fnFilter(node, elem)) {
                    textNodes.push(node);
                }
            } else if (nodeType === 1 || nodeType === 9 || nodeType === 11) {
                textNodes = textNodes.concat(getTextNodesIn(node, opt_fnFilter));
            }
        }
    }
    return textNodes;
}

function getElementsByAttributeName(elem, attributeName) {
    let matchNodes = [];
    if (elem) {
        let nodes = elem.children;
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            if (node.hasAttribute(attributeName)) {
                matchNodes.push(node);
            } else {
                matchNodes = matchNodes.concat(getElementsByAttributeName(node, attributeName));
            }
        }
    }
    return matchNodes;
}