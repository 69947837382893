// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetCallKeywordInfosQuery,
    BaseResponseListCallKeywordInfoFoundResponse,
    PostCallKeywordInfosBody,
    BaseResponseCallKeywordInfoFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags call-keyword-info-controller
 * @link [GET] /callKeywordInfos
 */
export function getCallKeywordInfos(
    options: ApiOptions<never, GetCallKeywordInfosQuery> & { params: GetCallKeywordInfosQuery }
): Promise<BaseResponseListCallKeywordInfoFoundResponse> {
    return request<never, never, GetCallKeywordInfosQuery>('/callKeywordInfos', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCallKeywordInfos',
    });
}

/**
 * save
 *
 * @export
 * @tags call-keyword-info-controller
 * @link [POST] /callKeywordInfos
 */
export function postCallKeywordInfos(
    data: PostCallKeywordInfosBody
): Promise<BaseResponseCallKeywordInfoFoundResponse>;
export function postCallKeywordInfos(
    data: PostCallKeywordInfosBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseCallKeywordInfoFoundResponse>;
export function postCallKeywordInfos(
    data: PostCallKeywordInfosBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseCallKeywordInfoFoundResponse> {
    return request<PostCallKeywordInfosBody, never, never>('/callKeywordInfos', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCallKeywordInfos',
    });
}

export const meta = [
    { tags: ['call-keyword-info-controller'], path: '/callKeywordInfos', method: 'get' },
    { tags: ['call-keyword-info-controller'], path: '/callKeywordInfos', method: 'post' },
];
