// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { PutKnowledgeDatasBatchUpdateBody, BaseResponseVoid } from '../../../types';

/**
 * batchUpdate
 *
 * @export
 * @tags knowledge-data-controller
 * @link [PUT] /knowledgeDatas/batch/update
 */
export function putKnowledgeDatasBatchUpdate(
    data: PutKnowledgeDatasBatchUpdateBody
): Promise<BaseResponseVoid>;
export function putKnowledgeDatasBatchUpdate(
    data: PutKnowledgeDatasBatchUpdateBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putKnowledgeDatasBatchUpdate(
    data: PutKnowledgeDatasBatchUpdateBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutKnowledgeDatasBatchUpdateBody, never, never>('/knowledgeDatas/batch/update', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putKnowledgeDatasBatchUpdate',
    });
}

export const meta = [
    { tags: ['knowledge-data-controller'], path: '/knowledgeDatas/batch/update', method: 'put' },
];
