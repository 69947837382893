// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetToDosQuery,
    BaseResponseListToDoFoundResponse,
    PostToDosBody,
    BaseResponseToDoFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags to-do-controller
 * @link [GET] /toDos
 */
export function getToDos(): Promise<BaseResponseListToDoFoundResponse>;
export function getToDos(
    options: ApiOptions<never, GetToDosQuery>
): Promise<BaseResponseListToDoFoundResponse>;
export function getToDos(
    options?: ApiOptions<never, GetToDosQuery>
): Promise<BaseResponseListToDoFoundResponse> {
    return request<never, never, GetToDosQuery>('/toDos', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getToDos',
    });
}

/**
 * save
 *
 * @export
 * @tags to-do-controller
 * @link [POST] /toDos
 */
export function postToDos(data: PostToDosBody): Promise<BaseResponseToDoFoundResponse>;
export function postToDos(
    data: PostToDosBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseToDoFoundResponse>;
export function postToDos(
    data: PostToDosBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseToDoFoundResponse> {
    return request<PostToDosBody, never, never>('/toDos', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postToDos',
    });
}

export const meta = [
    { tags: ['to-do-controller'], path: '/toDos', method: 'get' },
    { tags: ['to-do-controller'], path: '/toDos', method: 'post' },
];
