import React, { FC, useState } from 'react';
import { Button, Steps, Spin, Input, Space } from 'udesk-ui';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Description, DescValue } from './components/Description';
import { postReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerate } from 'src/api/review/intelligentPartner/node/dialogueFrameworkConfig/generate';
import UdeskLocales from 'UdeskLocales';

const DivWrapper = styled.div``;
const DivHeader = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const DivContent = styled.div`
    min-height: 100px;
`;

type AiGenDialogFrameProps = {
    value?: (DescValue & {
        communicationTheme?: string;
    })[];
    onChange?: (v: AiGenDialogFrameProps['value']) => void;
    courseId?: number;
    nodeId?: number;
};

export const AiGenDialogFrame: FC<AiGenDialogFrameProps> = (props) => {
    const { value, onChange, courseId, nodeId } = props;

    const [spinning, setSpinning] = useState(false);

    const onGenFrame = () => {
        setSpinning(true);
        postReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerate(
            { courseId, nodeId },
            { timeout: 600000 }
        )
            .then((res) => {
                onChange?.(res.data || []);
            })
            .finally(() => {
                setSpinning(false);
            });
    };

    const onDescChange = (v: DescValue, idx: number) => {
        const newValue = value?.map((_v, _idx) => {
            if (_idx === idx) {
                return {
                    ..._v,
                    ...v,
                };
            } else {
                return _v;
            }
        });
        onChange?.(newValue);
    };

    const onAdd = () => {
        onChange?.([...(value || []), {}]);
    };

    const onRemove = (idx: number) => {
        const newValue = (value || []).filter((v, _idx) => _idx !== idx);
        onChange?.(newValue);
    };

    const onThemeChange = (idx: number, val: string) => {
        const newValue = (value || []).map((v, _idx) => {
            if (_idx === idx) {
                return {
                    ...v,
                    communicationTheme: val,
                };
            } else {
                return v;
            }
        });
        onChange?.(newValue);
    };

    return (
        <DivWrapper>
            <Spin
                spinning={spinning}
                tip={
                    /* 生成中 */ UdeskLocales['current'].components.coach.aiGenDialogFrame.index
                        .generating
                }
            >
                <DivHeader>
                    <Button type="link" onClick={onGenFrame}>
                        {/* 生成对话框架 */}
                        {
                            UdeskLocales['current'].components.coach.aiGenDialogFrame.index
                                .generateDialogueFramework
                        }
                    </Button>
                </DivHeader>

                <DivContent>
                    <Steps progressDot current={Infinity} direction="vertical">
                        {value?.map((v, idx) => {
                            return (
                                <Steps.Step
                                    title={
                                        <Space>
                                            <Input
                                                value={
                                                    v?.communicationTheme ||
                                                    /* 主题 */ UdeskLocales['current'].components
                                                        .coach.aiGenDialogFrame.index.theme
                                                }
                                                style={{ width: 380 }}
                                                onChange={(e) => onThemeChange(idx, e.target.value)}
                                            />
                                            <MinusCircleOutlined onClick={() => onRemove(idx)} />
                                        </Space>
                                    }
                                    description={
                                        <Description
                                            value={v}
                                            onChange={(v) => onDescChange(v, idx)}
                                        />
                                    }
                                />
                            );
                        })}
                    </Steps>
                    <PlusCircleOutlined onClick={onAdd} />
                </DivContent>
            </Spin>
        </DivWrapper>
    );
};
