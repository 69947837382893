import React from 'react';
import Udesk from 'Udesk';
import AudioCallInfoComponent from './component';
import './style.scss';
import AudioTextView from '../audio-text-view';
import CallTextView from '../call-text-view';
import BusinessRecordsView from '../business-records-view';
import _isEmpty from 'lodash-es/isEmpty';
import { Tabs, Select, Button } from 'udesk-ui';
import ClassNames from 'classnames';

export default class AudioCallInfoTemplate extends AudioCallInfoComponent {
    render() {
        let {
            detail,
            highlightSeqList,
            inspectDataSource,
            isBusinessRecordsVisible,
            caseBase,
            caseBaseStatus,
            sdkOptions,
            keywords,
            markSemanticVisible,
        } = this.props;
        let {
            activeKey,
            caseBaseFlag
        } = this.privates;
        let {
            actions,
            locales
        } = this;
        let { TabPane } = Tabs;
        let SelectNode = (
            <Select
                value={this.privates.fieldsId || 'unnamed'}
                style={{ width: 120 }}
                onChange={actions.selectChange}
                onDropdownVisibleChange={actions.selectFocus}>
                <Select.Option value='unnamed' disabled='disabled'>{locales.components.pages.caseBaseList.detail.choiceCaseBase}</Select.Option>
                {
                    caseBase && !_isEmpty(caseBase) && caseBase.map(item => {
                        return (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>);
                    })
                }
            </Select>
        );
        let SaveButton = (
            <Button className='audio-case-base-saveButton' onClick={actions.save}>{locales.components.pages.caseBaseList.save}</Button>
        );
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;
        return (
            <div className={ClassNames("case-base-audio-detail audio-call-info-component", { "audio-call-info-component-phone": detail.voiceUrl }, { "audio-call-info-component-text": !detail.voiceUrl })}>
                <div className='audio-call-info-navs'>
                    {/* <span
                        className={['nav-item', activeTab === 'audioText' ? 'active' : null].join(' ')}
                        onClick={actions.changeActiveTab.params('audioText')}>
                        <Choose>
                            <When condition={inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id || inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id}>
                                {locales.components.pages.appealChekingTask.list.dialogText}
                            </When>
                            <Otherwise>
                                {locales.components.pages.appealChekingTask.list.audioText}
                            </Otherwise>
                        </Choose>
                    </span>
                    <span
                        className={['nav-item', activeTab === 'callInfo' ? 'active' : null].join(' ')}
                        onClick={actions.changeActiveTab.params('callInfo')}>
                        <Choose>
                            <When condition={inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id || inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id}>
                                {locales.components.pages.appealChekingTask.list.dialogInfo}
                            </When>
                            <Otherwise>
                                {locales.components.pages.appealChekingTask.list.callInfo}
                            </Otherwise>
                        </Choose>
                    </span>
                    <If condition={isBusinessRecordsVisible}>
                        <span
                            className={['nav-item', activeTab === 'businessRecords' ? 'active' : null].join(' ')}
                            onClick={actions.changeActiveTab.params('businessRecords')}>
                            {locales.components.pages.appealChekingTask.list.businessRecords}
                        </span>
                    </If> */}
                    <Tabs activeKey={activeKey} style={{ width: '100%', paddingLeft: 10 }} onTabClick={actions.onTabClick} tabBarExtraContent={caseBaseStatus && hasFeature && hasFeature("task:work:caseLibrary:submit") && SaveButton}>
                        <TabPane tab={(inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id || inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id) ? locales.components.pages.appealChekingTask.list.dialogText : locales.components.pages.appealChekingTask.list.audioText} key='audioText'>
                            <div className='audio-text-view'>
                                <If
                                    condition={
                                        detail &&
                                        detail.dialogRecords &&
                                        detail.dialogRecords.dialogSentenceList &&
                                        detail.dialogRecords.dialogSentenceList.length > 0
                                    }>
                                    <AudioTextView
                                        key={detail.id}
                                        sentenceList={detail.dialogRecords.dialogSentenceList}
                                        inspectDataSource={inspectDataSource}
                                        onTimeRangeChanged={actions.sendCurrentTimeRange}
                                        onMarkSemanticClick={actions.markSemantic}
                                        onCloseHitClick={actions.closeHit}
                                        highlightSeqList={highlightSeqList}
                                        detailId={detail.id}
                                        keywords={keywords}
                                        markSemanticVisible={markSemanticVisible}
                                    />
                                </If>
                            </div>
                        </TabPane>
                        <TabPane tab={(inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id || inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id) ? locales.components.pages.appealChekingTask.list.dialogInfo : locales.components.pages.appealChekingTask.list.callInfo} key='callInfo'>
                            <div className='audio-text-view'>
                                <If condition={detail && detail.fieldList && detail.fieldList.length > 0}>
                                    <CallTextView
                                        callInfoList={detail.fieldList}
                                        fieldValueMap={detail.fieldValueMap}
                                    />
                                </If>
                            </div>
                        </TabPane>
                        <If condition={isBusinessRecordsVisible}>
                            <TabPane
                                tab={locales.components.pages.appealChekingTask.list.businessRecords}
                                key='businessRecords'>
                                <div className='audio-text-view'>
                                    <If condition={detail.noteInfoExtension}>
                                        <BusinessRecordsView noteInfoExtension={detail.noteInfoExtension} />
                                    </If>
                                </div>
                            </TabPane>
                        </If>
                        <If condition={caseBaseStatus}>
                            <TabPane
                                tab={SelectNode}
                                key='selectNode'>
                                <div className='audio-text-view'>
                                    <If
                                        condition={
                                            detail &&
                                            detail.dialogRecords &&
                                            detail.dialogRecords.dialogSentenceList &&
                                            detail.dialogRecords.dialogSentenceList.length > 0
                                        }>
                                        <AudioTextView
                                            key={detail.id}
                                            sentenceList={detail.dialogRecords.dialogSentenceList}
                                            saveCaseBase={actions.saveCaseBase}
                                            fieldsList={this.privates.fieldsList}
                                            caseBaseFlag={caseBaseFlag}
                                            fieldFlag='true'
                                            inspectDataSource={inspectDataSource}
                                            onTimeRangeChanged={actions.sendCurrentTimeRange}
                                            onMarkSemanticClick={actions.markSemantic}
                                            onCloseHitClick={actions.closeHit}
                                            fieldsId={this.privates.fieldsId}
                                            detailId={detail.id}
                                            keywords={keywords}
                                            inputFlags={actions.inputFlags}
                                            highlightSeqList={highlightSeqList}
                                        />
                                    </If>
                                </div>
                            </TabPane>
                        </If>
                    </Tabs>
                </div>
                {/* <If condition={activeTab === "audioText"}>
                    <div className="audio-text-view">
                        <If condition={detail && detail.dialogRecords && detail.dialogRecords.dialogSentenceList && detail.dialogRecords.dialogSentenceList.length > 0}>
                            <AudioTextView detailId={detail.id} sentenceList={detail.dialogRecords.dialogSentenceList} highlightSeqList={highlightSeqList} inspectDataSource={inspectDataSource} onTimeRangeChanged={actions.sendCurrentTimeRange} onMarkSemanticClick={actions.markSemantic} />
                        </If>
                    </div>
                </If>
                <If condition={activeTab === "callInfo"}>
                    <div className="audio-text-view">
                        <If condition={detail && detail.fieldList && detail.fieldList.length > 0}>
                            <CallTextView callInfoList={detail.fieldList} fieldValueMap={detail.fieldValueMap} />
                        </If>
                    </div>
                </If>
                <If condition={activeTab === "businessRecords"}>
                    <div className="audio-text-view">
                        <If condition={detail.noteInfoExtension}>
                            <BusinessRecordsView noteInfoExtension={detail.noteInfoExtension} />
                        </If>
                    </div>
                </If> */}
            </div>
        );
    }
}
