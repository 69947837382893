// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetReviewIntelligentPartnerCategoryQuery,
    BaseResponseListIntelligentPartnerCourseCategoryFoundResponse,
    PostReviewIntelligentPartnerCategoryBody,
    BaseResponseIntelligentPartnerCourseCategoryFoundResponse,
} from '../../../types';

/**
 * 查询课程分类
 *
 * @export
 * @tags 智能陪练-课程分类
 * @link [GET] /review/intelligentPartner/category
 */
export function getReviewIntelligentPartnerCategory(): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse>;
export function getReviewIntelligentPartnerCategory(
    options: ApiOptions<never, GetReviewIntelligentPartnerCategoryQuery>
): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse>;
export function getReviewIntelligentPartnerCategory(
    options?: ApiOptions<never, GetReviewIntelligentPartnerCategoryQuery>
): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerCategoryQuery>(
        '/review/intelligentPartner/category',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewIntelligentPartnerCategory' }
    );
}

/**
 * 保存课程分类
 *
 * @export
 * @tags 智能陪练-课程分类
 * @link [POST] /review/intelligentPartner/category
 */
export function postReviewIntelligentPartnerCategory(
    data: PostReviewIntelligentPartnerCategoryBody
): Promise<BaseResponseIntelligentPartnerCourseCategoryFoundResponse>;
export function postReviewIntelligentPartnerCategory(
    data: PostReviewIntelligentPartnerCategoryBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerCourseCategoryFoundResponse>;
export function postReviewIntelligentPartnerCategory(
    data: PostReviewIntelligentPartnerCategoryBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerCourseCategoryFoundResponse> {
    return request<PostReviewIntelligentPartnerCategoryBody, never, never>(
        '/review/intelligentPartner/category',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerCategory',
        }
    );
}

export const meta = [
    { tags: ['智能陪练-课程分类'], path: '/review/intelligentPartner/category', method: 'get' },
    { tags: ['智能陪练-课程分类'], path: '/review/intelligentPartner/category', method: 'post' },
];
