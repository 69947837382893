// 统一入口
import AppEntry from '../pages/app-entry';
import { coachRouteComponentByRouteNameMappings } from './coach/routes';
import { salesRouteComponentByRouteNameMappings } from './sales/routes';
import { algorithmRouteComponentByRouteNameMappings } from './algorithm/routes';
import { generalSettingRouteComponentByRouteNameMappings } from './setting/routes';
import { qaRouteComponentByRouteNameMappings } from './qa/routes';
import { conversationAnalysisRouteComponentByRouteNameMappings } from './conversationAnalysis/routes';

// 系统登录
import AuthLogin, { Oauth, OauthCallback } from '../pages/login';
// 系统退出
import AuthLogout from '../pages/logout';
//404
import PageNotFound from '../pages/404';
// 案例分享
import CoachCaseShareOne from '../pages/coach/msg-center/case-share/index';
import TransferStation from '../pages/transfer-station';

export const routeComponentByRouteNameMappings = {
    // 统一入口
    appEntry: {
        component: AppEntry,
    },
    // 系统登录
    authLogin: {
        component: AuthLogin,
    },
    // 系统登录
    oauth: {
        component: Oauth,
    },
    // 系统登录回调
    oauthCallback: {
        component: OauthCallback,
    },
    // 系统退出
    authLogout: {
        component: AuthLogout,
    },
    pageNotFound: {
        component: PageNotFound,
    },
    coachCaseShareOne: {
        component: CoachCaseShareOne,
    },
    transferStation: {
        component: TransferStation,
    },
    ...qaRouteComponentByRouteNameMappings,
    ...salesRouteComponentByRouteNameMappings,
    ...algorithmRouteComponentByRouteNameMappings,
    ...generalSettingRouteComponentByRouteNameMappings,
    ...coachRouteComponentByRouteNameMappings,
    ...conversationAnalysisRouteComponentByRouteNameMappings,
};
