import React, {  } from 'react';
import { Checkbox, Form, Input, InputNumber, Select, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const tailLayout = {
    wrapperCol: { offset: 2, span: 16 },
};
const presetScoreDescMap = {
    5: /* 违规1次减 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.detail.components.model.item.violationOnceReduced,
    6: /* 语速异常（包括语速过快、语速过慢）1次减 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.detail.components.model.item.abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease,
    7: /* 语气词过多1次减 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.detail.components.model.item.excessiveNumberOfModalParticlesReducedOnce,
    8: /* 情绪负向1次减 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.detail.components.model.item.oneNegativeDecreaseInEmotions,
};
export const Item = (props) => {
    const { item = {}, field } = props;
    const { pointType, options } = item;

    return (
        <Form.Item style={{ marginBottom: 0 }} {...tailLayout}>
            <Form.Item name={[field.name, '_enable']} style={{ display: 'inline-block' }} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item
                name={[field.name, 'name']}
                style={{ display: 'inline-block', margin: '0 8px' }}
            >
                <Input style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
                name={[field.name, 'pointId']}
                style={{ display: 'inline-block', marginRight: 8 }}
            >
                <Select style={{ width: 200 }} options={options} />
            </Form.Item>
            {!!presetScoreDescMap[pointType] && (
                <Form.Item style={{ display: 'inline-block' }}>
                    <Space>
                        {presetScoreDescMap[pointType]}
                        <Form.Item name={[field.name, 'presetScore']} style={{ marginBottom: 0 }}>
                            <InputNumber />
                        </Form.Item>{/* 分 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.detail.components.model.item.branch}</Space>
                </Form.Item>
            )}
        </Form.Item>
    );
};
