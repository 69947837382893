import React from 'react';
import _TaskCard from 'Component/pages/components/task-card';
import styled from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const TasksContainerWrap = styled.div`
    display: grid;
    grid-gap: 0px;
    margin: -8px -8px 0;

    @media screen and (min-width: 1650px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (min-width: 1359px) and (max-width: 1649px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: 1069px) and (max-width: 1358px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 779px) and (max-width: 1068px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 778px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
const TaskCard = SortableElement(_TaskCard);

export const TasksContainer = SortableContainer((props) => {
    const { tasks, ...rest } = props;

    return (
        <TasksContainerWrap>
            {(
                tasks?.map((task, index) => (
                    <TaskCard
                        // cardIndex={index}
                        key={task.id}
                        task={task}
                        // index={task.order}
                        index={index}
                        {...rest}
                    />
                ))
            )}
        </TasksContainerWrap>
    );
});
