import React from 'react';
import { Progress, Spin } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

type TaskProgressProps = {
    totalCount: number;
    finishCount: number;
    loading?: boolean;
};

export const TaskProgress: React.FC<TaskProgressProps> = ({ totalCount, finishCount, loading }) => {
    if (loading) {
        return <Spin spinning={loading}></Spin>;
    }
    return (
        <Progress
            type="circle"
            strokeLinecap="butt"
            percent={(finishCount / totalCount) * 100}
            strokeWidth={12}
            format={() =>
                !totalCount ? /* 暂无任务 */UdeskLocales['current'].pages.coach.dashboard.components.taskProgress.thereAreCurrentlyNoTasksAvailable : totalCount === finishCount ? /* 已完成 */UdeskLocales['current'].pages.coach.dashboard.components.taskProgress.completed : `${finishCount} / ${totalCount}`
            }
        />
    );
};
