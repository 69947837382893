const algorithmRoutesGlobal = [
    {
        path: '/algorithm-platform',
        name: 'algorithmHome',
        routes: [
            {
                path: '/semantic-intelligence',
                authCode: 'semantic:root',
                name: 'algorithmSemanticIntelligence',
                routes: [
                    // 智能词库
                    {
                        path: '/smart-words-library',
                        name: 'algorithmSmartWordsLibrary',
                        authCode: 'semantic:corpora:root',
                        routes: [
                            {
                                path: '/synonym-words',
                                name: 'algorithmSmartWordsLibrarySynonymWords',
                            },
                            {
                                path: '/sensitive-words',
                                name: 'algorithmSmartWordsLibrarySensitiveWords',
                            },
                            {
                                path: '/professional-words',
                                name: 'algorithmSmartWordsLibraryProfessionalWords',
                            },
                            {
                                path: '/attention-words',
                                name: 'algorithmSmartWordsLibraryAttentionWords',
                            },
                            {
                                path: '/ignore-words',
                                name: 'algorithmSmartWordsLibraryIgnoreWords',
                            },
                            {
                                path: '/words-weight',
                                name: 'algorithmSmartWordsLibraryWordsWeight',
                            },
                            {
                                path: '/corpus-data',
                                name: 'algorithmSmartWordsLibraryCorpusData',
                            },
                            {
                                path: '/typo-library',
                                name: 'algorithmSmartWordsLibraryTypoLibrary',
                            },
                        ],
                    },
                    // 概念词
                    {
                        path: '/concept-word',
                        name: 'algorithmConceptWord',
                        authCode: 'semantic:conceptWords:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmConceptWordIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:conceptWords:add',
                                name: 'algorithmConceptWordNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:conceptWords:edit',
                                name: 'algorithmConceptWordEdit',
                            },
                        ],
                    },
                    // 语义标签
                    {
                        path: '/semantic-tags',
                        name: 'algorithmSemanticTags',
                        authCode: 'semantic:semanticTag:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmSemanticTagsIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:semanticTag:add',
                                name: 'algorithmSemanticTagsNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:semanticTag:edit',
                                name: 'algorithmSemanticTagsEdit',
                            },
                        ],
                    },
                    // 信息实体
                    {
                        path: '/information-entities',
                        name: 'algorithmInformationEntities',
                        authCode: 'semantic:imformation:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmInformationEntitiesIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:imformation:add',
                                name: 'algorithmInformationEntitiesNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:imformation:edit',
                                name: 'algorithmInformationEntitiesEdit',
                            },
                        ],
                    },
                    // 交互识别
                    {
                        path: '/interactive-recognition',
                        name: 'algorithmInteractiveRecognition',
                        authCode: 'semantic:interactive:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmInteractiveRecognitionIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:interactive:add',
                                name: 'algorithmInteractiveRecognitionNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:interactive:edit',
                                name: 'algorithmInteractiveRecognitionEdit',
                            },
                        ],
                    },
                    {
                        path: '/knowledge-base',
                        name: 'algorithmKnowledgeBase',
                        authCode: 'semantic:knowledgeData:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmKnowledgeBaseIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:knowledgeData:add',
                                name: 'algorithmKnowledgeBaseNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:knowledgeData:edit',
                                name: 'algorithmKnowledgeBaseEdit',
                            },
                        ],
                    },
                    // 数据预处理
                    {
                        path: '/data-preprocess',
                        name: 'algorithmDataPreprocess',
                        authCode: 'datapre:rule:root',
                        routes: [
                            {
                                path: '/index',
                                authCode: 'datapre:rule:view',
                                name: 'algorithmDataPreprocessIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'datapre:rule:save',
                                name: 'algorithmDataPreprocessNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'datapre:rule:update',
                                name: 'algorithmDataPreprocessEdit',
                            },
                        ],
                    },
                    // 智能抽取
                    {
                        path: '/intelligent-extractors',
                        name: 'algorithmIntelligentExtractors',
                        authCode: 'semantic:extract:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmIntelligentExtractorsIndex',
                            },
                        ],
                    },
                ],
            },
            // 训练中心
            {
                path: '/training-center',
                name: 'algorithmTrainingCenter',
                authCode: 'training:root',
                routes: [
                    // 快速训练
                    {
                        path: '/fast-training',
                        name: 'algorithmFastTrainingIndex',
                        authCode: 'training:fast:root',
                    },
                    // 训练历史
                    {
                        path: '/training-history',
                        name: 'algorithmTrainingHistoryIndex',
                        authCode: 'training:history:root',
                    },
                    // 分类训练
                    {
                        path: '/classified-training',
                        name: 'algorithmClassifiedTraining',
                        authCode: 'training:categoryTrain:train',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmClassifiedTrainingIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'algorithmClassifiedTrainingDetail',
                            },
                        ],
                    },
                    // 分类训练历史
                    {
                        path: '/classified-history-training',
                        name: 'algorithmClassifiedTrainingHistoryIndex',
                        authCode: 'training:categoryTrain:trainHistory',
                    },
                    // 聚类标注
                    {
                        path: '/clustering-annotation-index',
                        name: 'algorithmClusteringAnnotationIndex',
                        authCode: 'training:clusterMark:root',
                    },
                    // 话题标注
                    {
                        path: '/topic-index',
                        name: 'algorithmTopicList',
                        authCode: 'training:subjectList:root',
                    },
                    // 话题列表
                    {
                        path: '/topic-callout',
                        name: 'algorithmTopicCallout',
                        authCode: 'training:subjectMark:root',
                    },
                    // 7、聚类分析
                    {
                        path: '/clustering-analysis',
                        name: 'algorithmClusteringAnalysis',
                        authCode: 'semantic:cluster:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'algorithmClusteringAnalysisIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:cluster:add',
                                name: 'algorithmClusteringAnalysisNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:cluster:edit',
                                name: 'algorithmClusteringAnalysisEdit',
                            },
                            {
                                path: '/detail/:id',
                                authCode: 'semantic:cluster:view',
                                name: 'algorithmClusteringAnalysisDetail',
                            },
                        ],
                    },
                    // 8、话术挖掘
                    {
                        path: '/words-mining',
                        name: 'algorithmWordsMining',
                        authCode: 'semantic:WordsMining:root',
                        routes: [
                            {
                                path: '/dissent',
                                name: 'algorithmDissentWordsMining',
                            },
                            {
                                path: '/dissent/favorite',
                                authCode: 'semantic:WordsFavorite:root',
                                name: 'algorithmDissentFavoriteWordsMining',
                            },
                            {
                                path: '/selling-point',
                                name: 'algorithmSellingPointWordsMining',
                            },
                            {
                                path: '/selling-point/favorite',
                                authCode: 'semantic:WordsFavorite:root',
                                name: 'algorithmSellingPointFavoriteWordsMining',
                            },
                        ],
                    },
                ],
            },
            // 系统设置
            {
                path: '/admin',
                authCode: 'sys:setting:root',
                name: 'algorithmAdminEntry',
                routes: [
                    // 系统设置
                    // {
                    //     path: '/system/index',
                    //     name: 'salesAdminSystemIndex',
                    //     authCode: 'sys:info:root',
                    // },
                    // 会话设置
                    // {
                    //     path: '/session/index',
                    //     name: 'sessionSetings',
                    //     // authCode: 'sys:review:wecom:root',
                    // },

                    // 语音识别
                    // {
                    //     path: '/speech-recognition/index',
                    //     name: 'salesSpeechRecognitionIndex',
                    //     authCode: 'sys:voice:root',
                    // },
                    // 消息日志
                    // {
                    //     path: '/system-log/index',
                    //     name: 'systemLogIndex',
                    //     authCode: 'sys:message:root',
                    // },
                    // 消息中心
                    // {
                    //     path: '/message-center',
                    //     name: 'messageCenter',
                    //     authCode: 'sys:review:msgbox:root',
                    //     routes: [
                    //         {
                    //             path: '/reply',
                    //             name: 'adminMsgCenterReply',
                    //         },
                    //         {
                    //             path: '/answer',
                    //             name: 'adminMsgCenterAnswer',
                    //         },
                    //         {
                    //             path: '/share',
                    //             name: 'adminMsgCenterShare',
                    //         },
                    //         {
                    //             path: '/subscription',
                    //             name: 'adminMsgCenterSubscription',
                    //         },
                    //         {
                    //             path: '/study',
                    //             name: 'adminMsgCenterStudy',
                    //         },
                    //     ],
                    // },
                    // gong 员工管理
                    // {
                    //     path: '/medicine-user',
                    //     name: 'adminGongUser',
                    //     routes: [
                    //         {
                    //             path: '/list',
                    //             name: 'adminGongUserList',
                    //         },
                    //         {
                    //             path: '/edit/:id',
                    //             name: 'adminGongUserEdit',
                    //         },
                    //         {
                    //             path: '/detail/:id',
                    //             name: 'adminGongUserDetail',
                    //         },
                    //     ],
                    // },
                    // gong 角色管理
                    // {
                    //     path: '/medicine-role',
                    //     name: 'adminGongRole',
                    //     routes: [
                    //         {
                    //             path: '/list',
                    //             name: 'adminGongRoleList',
                    //         },
                    //         {
                    //             path: '/edit/:id',
                    //             name: 'adminGongRoleEdit',
                    //         },
                    //     ],
                    // },
                    // 任务中心
                    {
                        path: '/task-center',
                        name: 'algorithmTaskCenter',
                        authCode: 'sys:impExp:root',
                        routes: [
                            {
                                path: '/download-list',
                                name: 'algorithmTaskCenterDownloadList',
                            },
                            {
                                path: '/upload-list',
                                name: 'algorithmTaskCenterUploadList',
                            },
                            {
                                path: '/task-list',
                                name: 'algorithmTaskCenterTaskList',
                            },
                        ],
                    },
                    // 计费中心
                    // {
                    //     path: '/billing-center',
                    //     name: 'salesBillingCenterIndex',
                    //     authCode: 'hc:charge:root',
                    // },
                    // // 系统集成
                    // {
                    //     path: '/integration',
                    //     name: 'salesSystemIntegration',
                    //     routes: [
                    //         {
                    //             path: '/cc-pass',
                    //             name: 'salesSystemIntegrationCCpass',
                    //         },
                    //         //企微对接
                    //         {
                    //             path: '/wecom',
                    //             name: 'salesWechatDocking',
                    //         },
                    //         {
                    //             path: '/taobao',
                    //             name: 'salesTaobaoDocking',
                    //         },
                    //         //微丰对接
                    //         {
                    //             path: '/weifeng',
                    //             name: 'salesWeifengDocking',
                    //         },
                    //     ],
                    //     // authCode: 'hc:charge:root',
                    // },
                    //客户字段
                    // {
                    //     path: '/customer-field',
                    //     name: 'customerField',
                    //     authCode: 'sys:customer:field',
                    // },
                    //本地录音上传
                    // {
                    //     path: '/record-upload/index',
                    //     name: 'salesRecordUploadIndex',
                    //     authCode: 'sys:voiceUpload:root',
                    // },
                    // {
                    //     path: '/record-upload/upload-call',
                    //     name: 'salesRecordUploadDetail',
                    //     authCode: 'sys:voiceUpload:root',
                    // },
                    // {
                    //     path: '/record-upload/upload-im',
                    //     name: 'salesRecordUploadDetailIm',
                    //     authCode: 'sys:voiceUpload:root',
                    // },
                    // {
                    //     path: '/anomalous-data',
                    //     name: 'anomalousData',
                    //     authCode: 'sys:dataAbnormal:root',
                    //     routes: [
                    //         {
                    //             path: '/index',
                    //             name: 'anomalousDataIndex',
                    //         },
                    //     ],
                    // },
                    //logo设置
                    // {
                    //     path: '/logo-site',
                    //     name: 'logoSite',
                    //     // authCode: 'sys:customer:field',
                    // },
                ],
            },
        ],
    },
];

export { algorithmRoutesGlobal };
