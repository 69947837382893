import React, { FC, useState } from 'react';
import { Button, Form, Input, Modal, Space, Tabs } from 'udesk-ui';
import { AddOutlined } from '@udesk/icons';
import styled from 'styled-components';
import { postIntelligentPartnerAiAnalysisQueryForm } from 'src/api/intelligentPartner/ai/analysis/query/form';
import { IntelligentPartnerAiQueryReportFormFoundResponse } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const TabsWrapper = styled.div`
    max-width: 500px;
`;

type TemplateProps = {
    statements: IntelligentPartnerAiQueryReportFormFoundResponse[];
    activeKey: any;
    onChange: (k: any) => void;
    getStatements: any;
};

const Template: FC<TemplateProps> = (props) => {
    const { statements, activeKey, onChange, getStatements } = props;

    const onTabChange = (k) => {
        onChange(k);
    };

    const [visible, setVisible] = useState(false);

    const onAdd = () => {
        setVisible(true);
    };

    const onCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const [form] = Form.useForm();

    const onOk = () => {
        form.validateFields().then((values) => {
            postIntelligentPartnerAiAnalysisQueryForm({
                name: values.name,
            })
                .then(() => {
                    getStatements();
                })
                .finally(() => {
                    onCancel();
                });
        });
    };

    return (
        <>
            <Space>
                <TabsWrapper>
                    <Tabs size="small" activeKey={activeKey} onChange={onTabChange}>
                        {statements.map((s) => {
                            return (
                                <Tabs.TabPane
                                    tab={
                                        <Space size="small">
                                            <div>{s.name}</div>
                                        </Space>
                                    }
                                    key={s.id}
                                />
                            );
                        })}
                    </Tabs>
                </TabsWrapper>

                <Button type="link" icon={<AddOutlined />} size="small" onClick={onAdd}>{/* 新增报表 */}{UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.titleFooter.index.addReport}</Button>
            </Space>

            <Modal title={/* 新增报表 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.titleFooter.index.addReport} visible={visible} onCancel={onCancel} onOk={onOk}>
                <Form form={form}>
                    <Form.Item name="name" label={/* 报表名称 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.titleFooter.index.reportName} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Template;
