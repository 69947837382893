import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import SdkLoaderBase from 'Component/common/sdk-loader-base';

class AgentReportComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: "tasks-reports-analysis-overview",
        pageTitle: Locales['current'].components.tasks.subMenu.statisticsAndReports.customerServiceExecution.text
    };
}

export default Udesk.react.udeskify(AgentReportComponent);
