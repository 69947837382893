export default {
  lang: {
    num: 'Première',
    total: 'Article/Total',
    strip: 'Article',
    more: 'Plus',
    chosen: 'Sélectionner',
    cancel: 'Annuler la sélection',
    bulkOperations: 'Opérations par lots',
    import: 'Importer',
  },
};
