import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, notification, Popconfirm, Space, Badge, message } from 'udesk-ui';
import _map from 'lodash-es/map';
import _isEmpty from 'lodash-es/isEmpty';
import { PageHeaderExtra } from './components/pageHeaderExtra';
import { ApproveSwitch } from './components/antComponent';
import { postInspectionRuleApproves } from 'src/api/inspectionRule/approves';
import { getSystemModule } from 'Udesk/system/subApp';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
export default class TasksCheckPointIndexComponent extends React.Component {
    privates = {
        storages: {
            categoryId: null,
            isTest: true,
            isEdit: true,
        },
        notificationFlag: true,
        templateList: [
            {
                name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.customize,
            },
        ],
        isUploadVisible: false,
        file: null,
        uploadData: null,
        taskCheckPointClassificationsRef: React.createRef(),
        templateValue: 'a',
        checkData: [
            {
                name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.customize,
            },
        ],
        checkModuleData: [],
        secondClassActiveKey: {},
        checkPointList: React.createRef(),
        anchorShow: false,
        moveTreeVisible: false,
        dragingNode: null,
        TreeNodeRef: React.createRef(),
        classTree: [],
        confirmLoading: false,
        isWechatQa: false,
        selectedPointsId: [],
        createWechatQaModalConfirmLoading: false,
        enabledInspectRuleApprove: false,
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/tasks\/manage\/\d+\/template\/check-point\/\d+\/view\/\d+/i,
                /^\/site\/tasks\/manage\/\d+\/template\/check-point\/\d+\/edit\/\d+/i,
                /^\/site\/tasks\/manage\/\d+\/template\/check-point\/\d+\/new/i,
            ],
        },
    };

    //#region state
    state = {
        inspectionPoints: [],
        columns: [],
        totalScore: '',
        createModalVisible: false,
        loading: false,
        classifications: [],
        currentClassification: {},
        currentFirstClassification: {},
        currentSecondClassification: {},
        firstClassActiveKey: null,
        secondClassActiveKey: null,
        currentCreateSecondClassId: null,
        createWechatQaModalVisible: false,
        inspectionPointGroups: [],
        hasPoint: true,
        submitLoading: false,
    };
    //#endregion

    //#region actions
    actions = {
        /**
         * 未开启审批将返回Flag，开启审批将判断是否是测试环境,如果是将返回Flag，如果不是将返回def
         */
        iFApproveWrap(flag, def = false) {
            return this.privates.enabledInspectRuleApprove && !this.privates.storages.isEdit
                ? def
                : flag;
        },
        changeVersion(value) {
            this.privates.storages.isTest = value;
            this.privates.storages.isEdit = value;
            getCategories(this);
            // this.actions.update();
        },
        goEditPage(item) {
            const {
                privates,
                actions,
                props: { sdkOptions },
            } = this;
            return sdkOptions.props.task.processStatus === -1
                ? `/site/tasks/manage/${privates.model.taskId}/template/check-point/${
                      item.categoryId
                  }/${actions.iFApproveWrap('edit', 'view')}/${item.id}`
                : `/site/tasks/create-tasks/${privates.model.taskId}/template/check-point/${item.categoryId}/edit/${item.id}`;
        },
        getPageHeaderExtra() {
            const { privates, actions, locales, state } = this;
            return (
                <PageHeaderExtra
                    processStatus={privates.model.status.processStatus}
                    locales={locales}
                    isWechatQa={privates.isWechatQa}
                    enabledInspectRuleApprove={privates.enabledInspectRuleApprove}
                    onImport={actions.changeUploadVisible}
                    value={privates.storages.isTest}
                    onChange={actions.changeVersion}
                    onSubmit={actions.submitApprove}
                    submitLoading={state.submitLoading}
                />
            );
        },
        getCategoriesAjaxParams() {
            return [
                Udesk.business.apiPath.concatApiPath(
                    '/inspectionPointCategories',
                    this.props.sdkOptions
                ),
                {
                    taskId: this.privates.model.taskId,
                    configVersion: this.privates.isWechatQa
                        ? 2
                        : this.privates.enabledInspectRuleApprove
                        ? this.privates.storages.isEdit
                            ? 1
                            : 2
                        : 2,
                },
            ];
        },
        submitApprove() {
            const cache = {};
            const getAllPointId = (list) => {
                if (list && list.length > 0) {
                    list.forEach((item) => {
                        if (item.points) {
                            item.points.forEach((point) => {
                                cache[point.id] = point;
                            });
                        }
                        getAllPointId(item.children);
                    });
                }
            };
            getAllPointId(this.state.classifications);
            this.setState({
                submitLoading: true,
            });
            postInspectionRuleApproves({
                inspectionPointIdList: Reflect.ownKeys(cache),
                taskId: this.privates.model.taskId,
            })
                .then(() => {
                    getCategories(this);
                    message.success(
                        /* 审批提交成功 */ this.locales.pages.tasks.manage.template.checkPoint
                            .component.approvalSubmittedSuccessfully
                    );
                })
                .finally(() => {
                    this.setState({
                        submitLoading: false,
                    });
                });
        },
        goBack() {
            return null;
        },
        getPageTitle() {
            return this.locales.pages.tasks.manage.template.checkPoint.index[
                this.privates.isWechatQa ? 'wechatTitle' : 'name'
            ];
        },
        getConditionLogic(item) {
            let conditionLogic = '';

            if (
                item &&
                (item.type === Udesk.enums.scoreType.aiScore.id ||
                    item.type === Udesk.enums.scoreType.smartRules.id)
            ) {
                if (item.ruleList && item.ruleList.length > 0) {
                    item.ruleList.forEach((rule, index) => {
                        if (index === item.ruleList.length - 1) {
                            conditionLogic += rule.formula;
                        } else {
                            conditionLogic += rule.formula + ' ; ';
                        }
                    });
                }
            } else if (item && item.type === Udesk.enums.scoreType.interactiveDetection.id) {
                if (item.ruleList && item.ruleList.length > 0) {
                    item.ruleList.forEach((rule, index) => {
                        if (rule.name) {
                            conditionLogic += rule.name;
                        }
                    });
                }
            }

            return conditionLogic;
        },
        radioChange(e) {
            if (e.target.value === 'a') {
                this.privates.templateList = this.privates.checkData;
                this.privates.templateValue = e.target.value;
            }
            if (e.target.value === 'b') {
                this.privates.templateList = this.privates.checkModuleData;
                this.privates.templateValue = e.target.value;
            }
            this.actions.update();
        },
        upCheckPointState(item, checked) {
            item.status = checked ? 1 : 0;
            refreshCategoriesScore(this);
            this.actions.update();
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionPoints/' +
                    item.id +
                    '/status/' +
                    item.status +
                    '?taskId=' +
                    this.privates.model.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url).then(
                (resp) => {
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                    refreshCategoriesScore(this);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    item.status = checked ? 0 : 1;
                    refreshCategoriesScore(this);
                    this.actions.update();
                }
            );
        },

        delete(point) {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionPoints/' + point.id + '?taskId=' + this.privates.model.taskId,
                this.props.sdkOptions
            );
            if (this.privates.isWechatQa) {
                url = Udesk.business.apiPath.concatApiPath(
                    '/inspectionPointTaskRelations/' + point.pointTaskRelationId,
                    this.props.sdkOptions
                );
            }
            Udesk.ajax.del(url).then(
                (resp) => {
                    let { classifications } = this.state;
                    classifications.forEach((firstCategory) => {
                        firstCategory.children &&
                            firstCategory.children.length &&
                            firstCategory.children.forEach((secondCategory) => {
                                if (secondCategory.id === point.categoryId) {
                                    let { allPointDataList } = secondCategory;
                                    if (allPointDataList) {
                                        secondCategory.allPointDataList = allPointDataList.filter(
                                            (pointData) => pointData.id !== point.id
                                        );
                                    }
                                }
                            });
                    });
                    this.setState(
                        {
                            classifications,
                        },
                        () => {
                            refreshCategoriesScore(this);
                        }
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            `${UdeskLocales.current.pages.tasks.manage.template.checkPoint.index.name}${UdeskLocales.current.business.info.deleteError}`
                    );
                }
            );
        },

        getCurrentCategoryPoints(category) {
            this.privates.storages.categoryId = category.id;
            this.setState({
                categoryScore: category.score,
            });
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionPointCategories/' +
                    category.id +
                    '/points?taskId=' +
                    this.privates.model.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.setState({
                        inspectionPoints: resp.data,
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },

        close() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'tasksIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sendAjaxToNext() {
            const { props } = this;
            let hasFeature = props.sdkOptions.props.task
                ? props.sdkOptions.props.task._hasFeature
                : null;
            const openNotification = () => {
                const key = `open${Date.now()}`;
                const btn = (
                    <Button
                        type="primary"
                        prefixCls="udesk-qa-ui-btn"
                        size="small"
                        onClick={() => {
                            this.actions.close();
                            notification.close(key);
                        }}
                    >
                        {UdeskLocales.current.labels.ok}
                    </Button>
                );
                notification.open({
                    prefixCls: 'udesk-qa-ui-notification',
                    message: UdeskLocales.current.business.notifyMessage.illegalTitle,
                    description: UdeskLocales.current.business.notifyMessage.illegalMessage,
                    btn,
                    key,
                    onClose: this.actions.close,
                    duration: 0,
                    style: {
                        top: '60px',
                    },
                });
            };
            if (
                (hasFeature && !hasFeature('task:template:grade:edit')) ||
                (hasFeature && !hasFeature('task:template:grade:view'))
            ) {
                openNotification();
            } else {
                let url = Udesk.business.apiPath.concatApiPath(
                    `/inspectionTasks/processStatus/${this.privates.model.taskId}`,
                    this.props.sdkOptions
                );
                Udesk.ajax.put(url, { processStatus: 3 }).then(
                    (resp) => {
                        let routeOptions = {
                            history: this.props.history,
                            routeName: 'gradeCreateIndex',
                            pathParams: {
                                taskId: this.privates.model.taskId,
                            },
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index
                                        .title
                                )
                        );
                    }
                );
            }
        },
        handleNext() {
            let url2 = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/nextCheck/${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            Udesk.ajax.post(url2, { processStatus: 2 }).then(
                (resp) => {
                    if (resp.data) {
                        this.actions.sendAjaxToNext();
                    } else {
                        Udesk.ui.notify.error(this.locales.fix.checkPointisRequired);
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.checkPoint.index
                                    .title
                            )
                    );
                }
            );
        },
        exitBoot() {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, { processStatus: -1 }).then(
                (resp) => {
                    const routeOptions = {
                        history: this.props.history,
                        routeName: 'tasksCheckPointIndex',
                        pathParams: { taskId: this.privates.model.taskId },
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.checkPoint.index
                                    .title
                            )
                    );
                }
            );
        },
        async showCreateModal(id) {
            this.privates.storages.categoryId = id;

            if (this.privates.isWechatQa) {
                let { props } = this;
                this.setState({
                    createWechatQaModalVisible: true,
                    loading: true,
                });
                let url = Udesk.business.apiPath.concatApiPath(
                    `inspectionPointGroups?pageNum=1&pageSize=1000`,
                    props.sdkOptions
                );
                let checkedUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionTasks/${this.privates.model.taskId}/points`,
                    props.sdkOptions
                );
                try {
                    const inspectionPointGroupsAjax = Udesk.ajax.get(url);
                    const checkedAjax = Udesk.ajax.get(checkedUrl);
                    const inspectionPointGroups = await inspectionPointGroupsAjax;
                    const checkedPointIdList = await checkedAjax;
                    let currentClassification = this.state.classifications[0]?.children?.find(
                        (i) => i.id === id
                    );
                    let currentClassificationPoints = [];
                    if (currentClassification) {
                        currentClassificationPoints = currentClassification?.allPointDataList;
                    }

                    let selectedPointsId = [];
                    let groups = Array.isArray(inspectionPointGroups.data)
                        ? inspectionPointGroups.data.map((group) => {
                              return {
                                  title: group.name,
                                  value: group.id,
                                  children: group.points.map((point) => {
                                      let isSelected = currentClassificationPoints.some(
                                          (i) => i.id === point.id
                                      );
                                      let isChecked =
                                          isSelected || checkedPointIdList.data.includes(point.id);
                                      const inspectDataSource =
                                          this.props.sdkOptions.props.task.inspectDataSource;
                                      const notApplicable =
                                          (inspectDataSource ===
                                              Udesk.enums.inspectDataSources.wechatRadio.id &&
                                              point.weComSourceType === 2) ||
                                          (inspectDataSource ===
                                              Udesk.enums.inspectDataSources.wechatDialogue.id &&
                                              point.weComSourceType === 3);
                                      if (isSelected) {
                                          selectedPointsId.push(point.id);
                                      }
                                      return {
                                          title: point.name,
                                          value: point.id,
                                          disabled: isChecked || notApplicable,
                                      };
                                  }),
                              };
                          })
                        : [];
                    let hasPoint =
                        groups.length !== 0 && groups.some((group) => group.children?.length);
                    groups.forEach((group) => {
                        if (group.children.every((c) => c.disabled)) {
                            group.disabled = true;
                        }
                    });
                    this.privates.selectedPointsId = selectedPointsId;
                    this.setState({
                        loading: false,
                        inspectionPointGroups: groups,
                        hasPoint,
                    });
                } catch (err) {
                    Udesk.ui.notify.error(err.errorMsg);
                    this.setState({
                        loading: false,
                    });
                }
            } else {
                this.setState({
                    createModalVisible: true,
                    loading: true,
                });
            }
        },
        batchSave() {
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionPointTaskRelations/batch`,
                this.props.sdkOptions
            );
            let { categoryId } = this.privates.storages;
            this.privates.createWechatQaModalConfirmLoading = true;
            this.actions.update();
            Udesk.ajax
                .post(url, {
                    categoryId,
                    pointIds: this.privates.selectedPointsId,
                    taskId: parseInt(this.privates.model.taskId, 10),
                })
                .then(
                    (resp) => {
                        this.actions.closeCreateModal();
                        this.privates.createWechatQaModalConfirmLoading = false;
                        this.actions.update();
                        getCategories(this);
                    },
                    (reason) => {
                        this.privates.createWechatQaModalConfirmLoading = false;
                        this.actions.update();
                        Udesk.ui.notify.error(
                            reason.errorMsg || UdeskLocales.current.business.info.editError
                        );
                    }
                );
        },
        onTreeSelect(value) {
            this.privates.selectedPointsId = value;
            this.actions.update();
        },
        closeCreateModal() {
            this.setState({
                createModalVisible: false,
                createWechatQaModalVisible: false,
            });
        },
        tranToDetail(template, value) {
            if (value === 'a') {
                let routeName = 'tasksCheckPointCreateNew';
                if (
                    this.privates.model.status.processStatus &&
                    this.privates.model.status.processStatus === -1
                ) {
                    routeName = 'tasksCheckPointNew';
                }
                let queryParams = template && template.id ? { templateId: template.id } : null;
                let routeOptions = {
                    history: this.props.history,
                    routeName: routeName,
                    pathParams: {
                        taskId: this.privates.model.taskId,
                        categoryId: this.privates.storages.categoryId,
                    },
                    queryParams: queryParams,
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
            if (value === 'b') {
                let routeName = 'tasksCheckPointCreateNew';
                if (
                    this.privates.model.status.processStatus &&
                    this.privates.model.status.processStatus === -1
                ) {
                    routeName = 'tasksCheckPointNew';
                }
                let queryParams = template && template.id ? { moduleId: template.id } : null;
                let routeOptions = {
                    history: this.props.history,
                    routeName: routeName,
                    pathParams: {
                        taskId: this.privates.model.taskId,
                        categoryId: this.privates.storages.categoryId,
                    },
                    queryParams: queryParams,
                };
                Udesk.ui.routing.transitionTo(routeOptions);
                // new Promise((resolve, reject) => {
                //     let url = Udesk.business.apiPath.concatApiPath(
                //         `/inspectionModules/moduleDetail/${template.id}`,
                //         this.props.sdkOptions
                //     );
                //     Udesk.ajax.get(url).then(
                //         resp => {
                //             this.setState({
                //                 createModalVisible: false
                //             });
                //             this.actions.getCurrentCategoryPoints({ id: this.privates.storages.categoryId });
                //             resolve(resp.data);
                //         },
                //         reason => {
                //             reject(reason);
                //         }
                //     );
                // });
            }
            if (value === 'c') {
                let routeName = 'tasksCheckPointCreateNew';
                if (
                    this.privates.model.status.processStatus &&
                    this.privates.model.status.processStatus === -1
                ) {
                    routeName = 'tasksCheckPointNew';
                }
                let queryParams = template && template.id ? { myRulrId: template.id } : null;
                let routeOptions = {
                    history: this.props.history,
                    routeName: routeName,
                    pathParams: {
                        taskId: this.privates.model.taskId,
                        categoryId: this.privates.storages.categoryId,
                    },
                    queryParams: queryParams,
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        changeUploadVisible() {
            let { isUploadVisible } = this.privates;
            this.privates.isUploadVisible = !isUploadVisible;
            this.actions.update();
        },
        importTemplate() {
            let { sdkOptions } = this.props;
            let { file, uploadData } = this.privates;
            if (file == null || uploadData == null) {
                return Udesk.ui.notify.error(this.locales.business.notifyMessage.pleaseUploadFile);
            }
            let params = {
                keyName: uploadData.key,
                fileName: file.name,
                systemModule: getSystemModule(),
            };
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionPoints/import?taskId=${this.privates.model.taskId}`,
                sdkOptions
            );
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(this.locales.business.notifyMessage.importFileSuccess);
                    this.actions.changeUploadVisible();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || this.locales.business.notifyMessage.importFildFailed
                    );
                    this.actions.changeUploadVisible();
                }
            );
        },
        uploadFile(e) {
            let { locales } = this;
            let files = e.target.files;

            if (files.length === 0) {
                return;
            }

            // 取消文件大小限制
            // if (files[0] && (files[0].size > 2 * 1024 * 1024)) {
            //     return Udesk.ui.notify.error(UDL.business.notifyMessage.overFileSize);
            // }
            let fileName = fileType(files[0].name);
            if (!fileName) {
                return;
            } else if (fileName !== 'xlsx') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(locales.labels.fileTypeHolder, 'xlsx')
                );
            }
            //type 1语音,2导入,3导出,4语料数据
            Udesk.app.fileStorage.upload(files[0], {
                token: new Promise((resolve, reject) => {
                    Udesk.business
                        .getServerAuthorization({
                            type: 2,
                        })
                        .then(
                            function (resp) {
                                resolve(resp);
                            },
                            function (reason) {
                                reject(reason);
                            }
                        );
                }),
                //progressCallback: progressCallback.bind(this),
                successCallback: this.actions.uploadFileSuccess.bind(this),
                errorCallback: this.actions.uploadFileError.bind(this),
            });
        },
        uploadFileSuccess(file, uploadData) {
            this.privates.file = file;
            this.privates.uploadData = uploadData;
            this.actions.update();
        },
        uploadFileError(file, uploadData) {},
        downloadTemplate() {
            let { sdkOptions } = this.props;
            const lan = Udesk.data.init?.userLanguage;
            let templateLocalUrl = lan
                ? `/import/check-point-template/inspection-point-template_${lan}.xlsx`
                : `/import/check-point-template/inspection-point-template.xlsx`;
            let templateUrl =
                Udesk.business.apiPath.getStaticBasePath(
                    sdkOptions.props.domain,
                    sdkOptions.props.pathPrefix
                ) + templateLocalUrl;
            window.location = templateUrl;
        },
        getCategoryScore(data) {
            let totalScore = {
                _maxScore: 0,
                _minScore: 0,
            };
            if (data && data.length > 0) {
                data.forEach((item) => {
                    if (item._maxScore) {
                        totalScore._maxScore += item._maxScore;
                    }
                    if (item._minScore) {
                        totalScore._minScore += item._minScore;
                    }
                });
            }
            this.setState({
                totalScore: Udesk.business.scoreCompute.formatTotalScore(totalScore),
            });
        },
        addClassification(type, classification, e) {
            if (type === 'second') {
                let { currentSecondClassification } = this.state;
                this.actions.clearSetValues(type, classification);
                let child = {
                    name: null,
                    isEdit: true,
                    scoreUpperLimit: null,
                    scoreLowerLimit: '0',
                    initialScore: '0',
                    _minScore: 0,
                    _maxScore: 0,
                    wayOverUpperLimit: Udesk.enums.wayOverUpperLimits.ignoreExcessSpillover.id,
                };
                currentSecondClassification[classification.id] = child;
                this.setState({
                    currentSecondClassification: { ...currentSecondClassification },
                });
            }
            if (type === 'first') {
                this.actions.clearSetValues(type, classification);
                let { currentFirstClassification } = this.state;
                let id = classification;
                if (typeof classification === 'object') {
                    id = classification.id;
                }

                let newFirstClassification = {
                    name: '',
                    isEdit: true,
                    scoreUpperLimit: null,
                    scoreLowerLimit: '0',
                    initialScore: '0',
                    _minScore: 0,
                    _maxScore: 0,
                    wayOverUpperLimit: Udesk.enums.wayOverUpperLimits.ignoreExcessSpillover.id,
                };
                currentFirstClassification[id] = newFirstClassification;
                this.setState({
                    currentFirstClassification: { ...currentFirstClassification },
                });
            }
        },
        saveClassificationAdd(type, classOrNew) {
            if (type === 'second') {
                let currentClass = null;
                if (classOrNew && classOrNew.id) {
                    currentClass = this.state.currentSecondClassification[classOrNew.id];
                }
                // if (currentClass.scoreLowerLimit == null || currentClass.scoreLowerLimit === '') {
                //     return Udesk.ui.notify.error(
                //         Udesk.utils.string.format(
                //             UdeskLocales.current.business.info.cannotBeNull,
                //             UdeskLocales.current.components.tasks.checkPointClassifications.minimumScoreTitle
                //         )
                //     );
                // }

                // if (currentClass.initialScore == null || currentClass.initialScore === '') {
                //     return Udesk.ui.notify.error(
                //         Udesk.utils.string.format(
                //             UdeskLocales.current.business.info.cannotBeNull,
                //             UdeskLocales.current.components.tasks.checkPointClassifications.beginScoreTitle
                //         )
                //     );
                // }

                if (
                    classOrNew &&
                    classOrNew.id &&
                    !this.state.classifications.some((item) => classOrNew.id === item.id)
                ) {
                    //编辑二级标签
                    let params = {
                        name: currentClass.name,
                        scoreUpperLimit: currentClass.scoreUpperLimit,
                        scoreLowerLimit: currentClass.scoreLowerLimit || 0,
                        initialScore: currentClass.initialScore || 0,
                        wayOverUpperLimit: currentClass.wayOverUpperLimit,
                    };
                    let url = Udesk.business.apiPath.concatApiPath(
                        '/inspectionPointCategories/' +
                            classOrNew.id +
                            '?taskId=' +
                            this.privates.model.taskId,
                        this.props.sdkOptions
                    );
                    Udesk.ajax.put(url, params).then(
                        (resp) => {
                            Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                            classOrNew.isEdit = false;
                            let { classifications } = this.state;
                            for (let i = 0; i < classifications.length; i++) {
                                let firstCategory = classifications[i]; //遍历一级分类
                                if (firstCategory.children && firstCategory.children.length) {
                                    for (let j = 0; j < firstCategory.children.length; j++) {
                                        let secondCategory = firstCategory.children[j]; //遍历二级分类
                                        if (secondCategory.id === classOrNew.id) {
                                            firstCategory.children[j] = {
                                                ...secondCategory,
                                                ...currentClass,
                                            };
                                        }
                                    }
                                }
                            }
                            this.setState(
                                {
                                    classifications,
                                },
                                () => {
                                    refreshCategoriesScore(this);
                                }
                            );
                            this.actions.clearSetValues(type, classOrNew);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg || UdeskLocales.current.business.info.editError
                            );
                        }
                    );
                } else {
                    //新建二级标签
                    let params = {
                        taskId: this.privates.model.taskId,
                        name: currentClass.name,
                        level: 2,
                        parentId: classOrNew.id,
                        scoreUpperLimit: currentClass.scoreUpperLimit,
                        scoreLowerLimit: currentClass.scoreLowerLimit || 0,
                        initialScore: currentClass.initialScore || 0,
                        wayOverUpperLimit: currentClass.wayOverUpperLimit,
                    };
                    let url = Udesk.business.apiPath.concatApiPath(
                        '/inspectionPointCategories',
                        this.props.sdkOptions
                    );
                    Udesk.ajax.post(url, params).then(
                        (resp) => {
                            Udesk.ui.notify.success(UdeskLocales.current.business.info.saveSuccess);
                            currentClass.isEdit = false;
                            let { classifications } = this.state;
                            for (let i = 0; i < classifications.length; i++) {
                                let firstCategory = classifications[i]; //遍历一级分类
                                if (firstCategory.id === resp.data.parentId) {
                                    if (!firstCategory.children) {
                                        firstCategory.children = [];
                                    }
                                    firstCategory.children.push(resp.data);
                                }
                            }
                            this.setState(
                                {
                                    classifications,
                                },
                                () => {
                                    refreshCategoriesScore(this);
                                }
                            );
                            let { secondClassActiveKey } = this.state;
                            secondClassActiveKey[classOrNew.id] = this.state.secondClassActiveKey[
                                classOrNew.id
                            ].filter((item) => item != `addSecond${classOrNew.id}`);
                            secondClassActiveKey[classOrNew.id].push(resp.data.id);
                            this.setState({
                                secondClassActiveKey,
                            });
                            this.actions.clearSetValues(type, classOrNew);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg || UdeskLocales.current.business.info.saveError
                            );
                        }
                    );
                }
            }
            if (type === 'first') {
                let id = null;
                let currentClass = null;
                if (classOrNew === 'new') {
                    id = classOrNew;
                } else {
                    id = classOrNew.id;
                }
                currentClass = this.state.currentFirstClassification[id];

                // if (currentClass.scoreLowerLimit == null || currentClass.scoreLowerLimit === '') {
                //     return Udesk.ui.notify.error(
                //         Udesk.utils.string.format(
                //             UdeskLocales.current.business.info.cannotBeNull,
                //             UdeskLocales.current.components.tasks.checkPointClassifications.minimumScoreTitle
                //         )
                //     );
                // }

                // if (currentClass.initialScore == null || currentClass.initialScore === '') {
                //     return Udesk.ui.notify.error(
                //         Udesk.utils.string.format(
                //             UdeskLocales.current.business.info.cannotBeNull,
                //             UdeskLocales.current.components.tasks.checkPointClassifications.beginScoreTitle
                //         )
                //     );
                // }

                if (currentClass.id) {
                    //编辑一级标签
                    let params = {
                        name: currentClass.name,
                        scoreUpperLimit: currentClass.scoreUpperLimit,
                        scoreLowerLimit: currentClass.scoreLowerLimit || 0,
                        initialScore: currentClass.initialScore || 0,
                        wayOverUpperLimit: currentClass.wayOverUpperLimit,
                    };
                    let url = Udesk.business.apiPath.concatApiPath(
                        '/inspectionPointCategories/' +
                            currentClass.id +
                            '?taskId=' +
                            this.privates.model.taskId,
                        this.props.sdkOptions
                    );
                    Udesk.ajax.put(url, params).then(
                        (resp) => {
                            Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                            currentClass.isEdit = false;
                            let { classifications } = this.state;
                            for (let i = 0; i < classifications.length; i++) {
                                let firstCategory = classifications[i]; //遍历一级分类
                                if (firstCategory.id === classOrNew.id) {
                                    classifications[i] = { ...firstCategory, ...currentClass };
                                }
                            }
                            this.setState(
                                {
                                    classifications,
                                },
                                () => {
                                    refreshCategoriesScore(this);
                                }
                            );
                            this.actions.clearSetValues(type, currentClass);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg || UdeskLocales.current.business.info.editError
                            );
                        }
                    );
                } else {
                    //新建一级标签
                    let params = {
                        taskId: this.privates.model.taskId,
                        name: currentClass.name,
                        level: 1,
                        parentId: null,
                        scoreUpperLimit: currentClass.scoreUpperLimit,
                        scoreLowerLimit: currentClass.scoreLowerLimit || 0,
                        initialScore: currentClass.initialScore || 0,
                        wayOverUpperLimit: currentClass.wayOverUpperLimit,
                    };
                    let url = Udesk.business.apiPath.concatApiPath(
                        '/inspectionPointCategories',
                        this.props.sdkOptions
                    );
                    Udesk.ajax.post(url, params).then(
                        (resp) => {
                            Udesk.ui.notify.success(UdeskLocales.current.business.info.saveSuccess);
                            currentClass.isEdit = false;
                            let { firstClassActiveKey, classifications } = this.state;
                            classifications.push(resp.data);
                            this.setState(
                                {
                                    classifications,
                                },
                                () => {
                                    refreshCategoriesScore(this);
                                }
                            );

                            firstClassActiveKey.push(resp.data.id);
                            this.setState({
                                firstClassActiveKey,
                            });
                            this.actions.clearSetValues(type, classOrNew);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg || UdeskLocales.current.business.info.saveError
                            );
                        }
                    );
                }
            }
        },
        onValueChanged(obj, classId, key, e) {
            let value = e;
            if (e && e.target) {
                value = e.target.value;
            }
            obj[classId][key] = value;
            this.actions.update();
        },
        clearSetValues(type, classification) {
            if (type === 'second') {
                let { currentSecondClassification } = this.state;
                currentSecondClassification[classification.id] = {
                    name: '',
                    isEdit: false,
                    scoreUpperLimit: null,
                    scoreLowerLimit: '0',
                    initialScore: '0',
                    _minScore: 0,
                    _maxScore: 0,
                    wayOverUpperLimit: Udesk.enums.wayOverUpperLimits.ignoreExcessSpillover.id,
                };
                this.setState({
                    currentSecondClassification,
                });
            }
            if (type === 'first') {
                let id = null;
                if (typeof classification === 'string') {
                    id = classification;
                } else {
                    id = classification.id;
                }
                if (this.state.firstClassActiveKey) {
                    let { firstClassActiveKey } = this.state;
                    firstClassActiveKey = this.state.firstClassActiveKey.filter(
                        (item) => item != 'addFirst'
                    );
                    this.setState({
                        firstClassActiveKey,
                    });
                }

                let { currentFirstClassification } = this.state;

                currentFirstClassification[id] = {
                    name: '',
                    isEdit: false,
                    scoreUpperLimit: null,
                    scoreLowerLimit: '0',
                    initialScore: '0',
                    _minScore: 0,
                    _maxScore: 0,
                    wayOverUpperLimit: Udesk.enums.wayOverUpperLimits.ignoreExcessSpillover.id,
                };
                this.setState({
                    currentFirstClassification,
                });
            }
        },
        firstClassCollapseChange(key) {
            this.setState({
                firstClassActiveKey: key,
            });
        },
        secondClassCollapseChange(firstClass, key, e) {
            let { secondClassActiveKey } = this.state;
            secondClassActiveKey[firstClass] = key;
            this.setState({
                secondClassActiveKey,
            });
        },
        anchorChange(e, link) {
            let { firstClassActiveKey, secondClassActiveKey } = this.state;

            let actKeys = link.href.split(/\#|\-/);
            firstClassActiveKey = Array.from(new Set([...firstClassActiveKey, ...actKeys]));
            for (let key in secondClassActiveKey) {
                secondClassActiveKey[key] = Array.from(
                    new Set([...secondClassActiveKey[key], ...actKeys])
                );
            }
            this.setState({
                firstClassActiveKey,
                secondClassActiveKey,
            });
        },
        showAnchor(e) {
            e.stopPropagation();
            this.privates.anchorShow = !this.privates.anchorShow;
            this.actions.update();
        },
        deleteSecondClassification(secondClassification, e) {
            e.stopPropagation();
            if (secondClassification.id) {
                let url = Udesk.business.apiPath.concatApiPath(
                    '/inspectionPointCategories/' +
                        secondClassification.id +
                        '?taskId=' +
                        this.privates.model.taskId,
                    this.props.sdkOptions
                );
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(UdeskLocales.current.business.info.deleteSuccess);
                        let { classifications } = this.state;
                        for (let i = 0; i < classifications.length; i++) {
                            let firstCategory = classifications[i]; //遍历一级分类
                            if (firstCategory.children && firstCategory.children.length) {
                                let secondCategoryList = firstCategory.children.filter(
                                    (secondCategory) =>
                                        secondCategory.id !== secondClassification.id
                                );
                                classifications[i].children = secondCategoryList;
                            }
                        }
                        this.setState(
                            {
                                classifications,
                            },
                            () => {
                                refreshCategoriesScore(this);
                            }
                        );
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg || UdeskLocales.current.business.info.deleteError
                        );
                    }
                );
            }
        },
        deleteFirstClassification(firstClassification, e) {
            e.stopPropagation();
            if (firstClassification.id) {
                let url = Udesk.business.apiPath.concatApiPath(
                    '/inspectionPointCategories/' +
                        firstClassification.id +
                        '?taskId=' +
                        this.privates.model.taskId,
                    this.props.sdkOptions
                );
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(UdeskLocales.current.business.info.deleteSuccess);
                        let classifications = this.state.classifications.filter(
                            (firstCategory) => firstCategory.id !== firstClassification.id
                        );
                        this.setState(
                            {
                                classifications,
                            },
                            () => {
                                refreshCategoriesScore(this);
                            }
                        );
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg || UdeskLocales.current.business.info.deleteError
                        );
                    }
                );
            }
        },
        editSecondClassification(secondClassification, e) {
            e.stopPropagation();
            secondClassification.isEdit = true;
            // if (secondClassification.scoreUpperLimit === null) secondClassification.scoreUpperLimit = '';
            let { currentSecondClassification, secondClassActiveKey } = this.state;
            currentSecondClassification[secondClassification.id] = {
                ...secondClassification,
            };
            if (
                secondClassActiveKey[secondClassification.parentId] &&
                !secondClassActiveKey[secondClassification.parentId].some(
                    (item) => item == secondClassification.id
                )
            ) {
                secondClassActiveKey[secondClassification.parentId].push(secondClassification.id);
            }
            this.setState({
                currentSecondClassification,
                secondClassActiveKey,
            });
        },
        editFirstClassification(firstClassification, e) {
            e.stopPropagation();
            firstClassification.isEdit = true;
            // if (firstClassification.scoreUpperLimit === null) firstClassification.scoreUpperLimit = '';
            let { currentFirstClassification, firstClassActiveKey } = this.state;
            if (
                firstClassActiveKey &&
                !firstClassActiveKey.some((item) => item == firstClassification.id)
            ) {
                firstClassActiveKey.push(firstClassification.id);
            }
            currentFirstClassification[firstClassification.id] = {
                ...firstClassification,
            };
            this.setState({
                currentFirstClassification,
                firstClassActiveKey,
            });
        },
        closeClassificationDetail(type, currentClassification, classOrNew) {
            let id = null;
            if (classOrNew === 'new') {
                id = classOrNew;
                let firstClassActiveKey = this.state.firstClassActiveKey.filter(
                    (item) => item !== 'addFirst'
                );
                this.setState({
                    firstClassActiveKey,
                });
            } else {
                id = classOrNew.id;
            }
            currentClassification[id].isEdit = false;
            if (type === 'first') {
                this.setState({
                    currentFirstClassification: currentClassification,
                });
            }
            if (type === 'second') {
                if (!classOrNew.parentId) {
                    let { secondClassActiveKey } = this.state;
                    secondClassActiveKey[classOrNew.id] = this.state.secondClassActiveKey[
                        classOrNew.id
                    ].filter((item) => item != `addSecond${classOrNew.id}`);
                    this.setState({
                        secondClassActiveKey,
                    });
                }
                this.setState({
                    currentSecondClassification: currentClassification,
                });
            }
        },
        openMoveTree(type) {
            this.privates.moveTreeVisible = type;
            this.actions.update();
        },
        moveCheckPoints() {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionPointCategories/change-order?taskId=${this.privates.model.taskId}`,
                this.props.sdkOptions
            );
            let params = this.privates.classTree;
            this.privates.confirmLoading = true;
            this.actions.update();
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    getCategories(this);
                    this.privates.confirmLoading = false;
                    this.actions.openMoveTree(false);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        onDragEnd(result) {
            // dropped outside the list
            if (!result.destination) {
                return;
            }
            const sourceIndex = result.source.index;
            const destIndex = result.destination.index;
            if (result.type === 'droppableItem') {
                const classTree = reorder(this.privates.classTree, sourceIndex, destIndex);

                this.privates.classTree = classTree;
                this.actions.update();
            } else if (result.type === 'droppableSubItem') {
                const itemSubItemMap = this.privates.classTree.reduce((acc, item) => {
                    acc[item.id] = item.children;
                    return acc;
                }, {});

                const sourceParentId = parseInt(result.source.droppableId);
                const destParentId = parseInt(result.destination.droppableId);

                const sourceSubItems = itemSubItemMap[sourceParentId];
                const destSubItems = itemSubItemMap[destParentId];

                let newItems = [...this.privates.classTree];

                /** In this case subItems are reOrdered inside same Parent */
                if (sourceParentId === destParentId) {
                    const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex);
                    newItems = newItems.map((item) => {
                        if (item.id === sourceParentId) {
                            item.children = reorderedSubItems;
                        }
                        return item;
                    });
                    this.privates.classTree = newItems;
                    this.actions.update();
                } else {
                    let newSourceSubItems = [...sourceSubItems];
                    const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

                    let newDestSubItems = [...destSubItems];
                    newDestSubItems.splice(destIndex, 0, draggedItem);
                    newItems = newItems.map((item) => {
                        if (item.id === sourceParentId) {
                            item.children = newSourceSubItems;
                        } else if (item.id === destParentId) {
                            item.children = newDestSubItems;
                        }
                        return item;
                    });
                    this.privates.classTree = newItems;
                    this.actions.update();
                }
            } else if (result.type === 'point') {
                let { classTree } = this.privates;
                let movedNode;
                classTree.forEach((first) => {
                    first.children.forEach((second) => {
                        if (result.source.droppableId == second.id) {
                            movedNode = second.points.splice(result.source.index, 1);
                        }
                    });
                });
                classTree.forEach((first) => {
                    first.children.forEach((second) => {
                        if (result.destination.droppableId == second.id) {
                            second.points.splice(result.destination.index, 0, ...movedNode);
                        }
                    });
                });
                // this.privates.classTree = classTree;
                // this.actions.update();
            }
        },
        goToWechatCheckPointSetting(groupId, pointId) {
            let { props } = this;
            let url = `/site/wechat-qa/setting/check-point/${groupId}/${pointId}/edit`;
            // if(groupId !== undefined){
            //     url += `?groupId=${groupId}`;
            // }
            if (props.sdkOptions.props.domain) {
                url = props.sdkOptions.props.domain + url;
            }
            Udesk.utils.web.openWindow(url, '_blank');
        },
    };
    onModelResolved(asyncModel) {
        if (asyncModel.checkData && !_isEmpty(asyncModel.checkData)) {
            this.privates.checkData = [
                {
                    name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.index
                        .customize,
                },
                ...asyncModel.checkData,
            ];
            this.privates.templateList = this.privates.checkData;
            this.privates.templateValue = 'a';
        }
        if (asyncModel.checkModuleData && !_isEmpty(asyncModel.checkModuleData)) {
            _map(asyncModel.checkModuleData, (item, key) => {
                this.privates.checkModuleData = this.privates.checkModuleData.concat(item);
            });
        }
        if (asyncModel.sysConfig) {
            this.privates.enabledInspectRuleApprove =
                asyncModel.sysConfig.enabledInspectRuleApprove;
        }
        this.actions.update();
    }
    //#endregi.on

    //#region Life Cycle
    componentDidUpdate() {
        const { props } = this;
        let hasFeature = props.sdkOptions.props.task
            ? props.sdkOptions.props.task._hasFeature
            : null;
        const openNotification = () => {
            const key = `open${Date.now()}`;
            const btn = (
                <Button
                    type="primary"
                    size="small"
                    prefixCls="udesk-qa-ui-btn"
                    onClick={() => {
                        this.actions.close();
                        notification.close(key);
                    }}
                >
                    {UdeskLocales.current.labels.ok}
                </Button>
            );
            notification.open({
                message: UdeskLocales.current.business.notifyMessage.illegalTitle,
                description: UdeskLocales.current.business.notifyMessage.illegalMessageCurrent,
                btn,
                key,
                onClose: this.actions.close,
                duration: 0,
                style: {
                    top: '60px',
                },
                prefixCls: 'udesk-qa-ui-notification',
            });
        };
        if (
            this.privates.notificationFlag &&
            this.privates.model &&
            this.privates.model.status.processStatus === 2 &&
            hasFeature &&
            (!hasFeature('task:template:point:add') || !hasFeature('task:template:point:list'))
        ) {
            openNotification();
            this.privates.notificationFlag = false;
        }
        // let classificationsTree = this.state.classifications.map(item => {
        //     return {
        //         title: item.name,
        //         key: item.id,
        //         children: item.children.map(second => {
        //             return {}
        //         })
        //     }
        // });
    }
    componentDidMount() {
        getColumns(this);
        getCategories(this);
    }
    init() {
        this.privates.isWechatQa = ['wechatQaCheckPointIndex'].includes(this.props.route.name);
    }
    //#endregion
}
function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
function getColumns(that) {
    let { props, privates, actions, locales } = that;
    let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
    let columns = [
        {
            key: 'name',
            dataIndex: 'name',
            title: UdeskLocales.current.columns.tasks.manage.template.checkPoint.name,
            width: '10%',
            render(name, item) {
                if (item.markRed) {
                    return <span style={{ color: 'red' }}>{name}</span>;
                }
                return name;
            },
        },
        {
            key: 'remark',
            dataIndex: 'remark',
            title: UdeskLocales.current.columns.tasks.manage.template.checkPoint.remark,
            width: '20%',
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: UdeskLocales.current.columns.tasks.manage.template.checkPoint.type,
            width: '10%',
            render: (name, item, index) => {
                return (
                    <div>
                        {/** 此处当存在 secondType 或者 intelligentCustomModelId，认为是智能模型类型 */}
                        {item.secondType || item.intelligentCustomModelId
                            ? Udesk.enums.scoreType.intelligentModel.name
                            : Udesk.enums.scoreType.getName(item.type)}
                    </div>
                );
            },
        },
        {
            key: 'gradeType',
            dataIndex: 'gradeType',
            title: UdeskLocales.current.columns.tasks.manage.template.checkPoint.gradeType,
            width: '15%',
            render: (name, item, index) => {
                if (item.type === Udesk.enums.scoreType.machineLearning.id) {
                    return <div>{Udesk.enums.strategyTypes.getName(item.gradeType)}</div>;
                } else {
                    return <div>{Udesk.enums.gradeTypes.getName(item.gradeType)}</div>;
                }
            },
        },
        {
            key: 'conditionLogic',
            dataIndex: 'conditionLogic',
            title: UdeskLocales.current.columns.tasks.manage.template.checkPoint.conditionLogic,
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
            render: (name, item, index) => {
                let context = actions.getConditionLogic(item);
                return (
                    <div title={context} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {context}
                    </div>
                );
            },
        },
        {
            key: 'predeterminedScore',
            dataIndex: 'predeterminedScore',
            title: UdeskLocales.current.columns.tasks.manage.template.checkPoint.predeterminedScore,
            width: '10%',
            render: (name, item, index) => {
                if (item.ruleType === Udesk.enums.ruleTypes.flow.id) return '';

                return (
                    <div>
                        <If condition={item.scoreType === Udesk.enums.calculateScoreTypes.add.id}>
                            {locales.labels.plusSign}
                        </If>
                        <If condition={item.scoreType === Udesk.enums.calculateScoreTypes.sub.id}>
                            {locales.labels.minusSign}
                        </If>
                        {item.predeterminedScore}
                    </div>
                );
            },
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: UdeskLocales.current.columns.tasks.manage.template.checkPoint.status,
            width: '10%',
            render: (name, item, index) => {
                let locals = UdeskLocales.current.columns.tasks.manage.template.checkPoint;
                return (
                    <div>
                        {privates.isWechatQa ? (
                            <Badge
                                status={item.status === 1 ? 'processing' : 'error'}
                                text={item.status === 1 ? locals.switchOn : locals.switchOff}
                            />
                        ) : (
                            <ApproveSwitch
                                isApprove={privates.enabledInspectRuleApprove}
                                isEdit={privates.storages.isEdit}
                                checked={item.status === 1}
                                onChange={actions.upCheckPointState.params(item)}
                                size="small"
                            />
                        )}
                    </div>
                );
            },
        },
        {
            key: 'actions',
            dataIndex: 'actions',
            title: UdeskLocales.current.columns.tasks.manage.template.checkPoint.actions,
            width: '10%',
            render: (name, item, index) => {
                return (
                    <div>
                        <Space>
                            <If
                                condition={
                                    !privates.isWechatQa &&
                                    hasFeature &&
                                    hasFeature('task:template:point:edit')
                                }
                            >
                                <NavLink
                                    to={actions.goEditPage(item)}
                                    className={'action-btn check-point-nav-link'}
                                >
                                    {actions.iFApproveWrap(
                                        locales.labels.edit,
                                        locales.labels.view
                                    )}
                                </NavLink>
                            </If>
                            <If condition={privates.isWechatQa}>
                                <button
                                    onClick={actions.goToWechatCheckPointSetting.bind(
                                        null,
                                        item.groupId,
                                        item.id
                                    )}
                                    className="action-btn check-point-nav-link"
                                >
                                    {locales.labels.view}
                                </button>
                            </If>
                            <If
                                condition={actions.iFApproveWrap(
                                    (hasFeature && hasFeature('task:template:point:delete')) ||
                                        privates.isWechatQa
                                )}
                            >
                                <Popconfirm
                                    title={locales.labels.deleteContent}
                                    onConfirm={actions.delete.params(item)}
                                    onCancel={(e) => e.stopPropagation()}
                                    okText={locales.labels.confirm}
                                    placement="topRight"
                                    cancelText={locales.labels.cancel}
                                >
                                    <button className="action-btn check-point-nav-link">
                                        {locales.labels.delete}
                                    </button>
                                </Popconfirm>
                            </If>
                        </Space>
                    </div>
                );
            },
        },
    ];
    that.setState({
        columns,
    });
}
export function getCategories(that) {
    Udesk.ajax.get(...that.actions.getCategoriesAjaxParams()).then(
        (resp) => {
            let modelData = resp.data;
            // let res = [];
            for (let i = 0; i < modelData.length; i++) {
                let firstCategory = modelData[i]; //遍历一级分类
                firstCategory._minScore = 0;
                firstCategory._maxScore = 0;
                if (firstCategory.children && firstCategory.children.length) {
                    for (let j = 0; j < firstCategory.children.length; j++) {
                        let secondCategory = firstCategory.children[j]; //遍历二级分类
                        if (!secondCategory.points) {
                            secondCategory.points = [];
                        }
                        secondCategory.allPointDataList = [...secondCategory.points];
                        // secondCategory.children = [...data];
                        secondCategory.pointDataList = secondCategory.points.filter(
                            (item) => item.status === 1
                        );
                        Udesk.business.scoreCompute.computeSecondaryCategoryLimitScore(
                            secondCategory
                        );
                        // 累计最大值与最小值
                        firstCategory._minScore += secondCategory._minScore;
                        firstCategory._maxScore += secondCategory._maxScore;
                    }
                }
            }
            if (!that.state.firstClassActiveKey) {
                that.setState({
                    firstClassActiveKey: modelData.map((item) => item.id),
                });
            }
            if (!that.state.secondClassActiveKey) {
                let secondClassActiveKey = {};
                modelData.forEach((item) => {
                    secondClassActiveKey[item.id] =
                        item.children && item.children.length
                            ? item.children.map((child) => child.id)
                            : [];
                });
                that.setState({
                    secondClassActiveKey,
                });
            }
            modelData.forEach((firstCategory) => {
                Udesk.business.scoreCompute.computeFinalLimitScore(firstCategory);
            });
            that.setState({
                classifications: modelData,
            });
            that.privates.classTree = getMoveTree(modelData);
            onCategoriesPromiseResolved(that, modelData);
        },
        (reason) => {}
    );
}
function getMoveTree(modelData) {
    return modelData.map((first) => {
        return {
            id: first.id,
            name: first.name,
            children: first.children
                ? first.children.map((second) => {
                      return {
                          id: second.id,
                          name: second.name,
                          points: second.allPointDataList.map((point) => {
                              return {
                                  id: point.id,
                                  name: point.name,
                                  taskId: point.taskId,
                              };
                          }),
                      };
                  })
                : [],
        };
    });
}
function onCategoriesPromiseResolved(that, modelData) {
    const selectedId = that.privates.storages.categoryId;

    if (modelData && modelData.length > 0) {
        that.actions.getCategoryScore(modelData);

        if (selectedId != null) {
            for (let i = 0; i < modelData.length; i++) {
                let firstCategory = modelData[i];
                if (!firstCategory.children) {
                    firstCategory.children = [];
                }
                for (let j = 0; j < firstCategory.children.length; j++) {
                    let secondCategory = firstCategory.children[j];

                    if (secondCategory.id === selectedId) {
                        that.setState({
                            selectedId: selectedId,
                            classifications: modelData,
                        });
                        return;
                    }
                }
            }
        }

        if (modelData[0] && modelData[0].children && modelData[0].children.length > 0) {
            that.setState({
                selectedId: modelData[0].children[0].id,
                classifications: modelData,
            });
        }
    }
}
function refreshCategoriesScore(that) {
    let { classifications } = that.state;
    for (let i = 0; i < classifications.length; i++) {
        let firstCategory = classifications[i]; //遍历一级分类
        firstCategory._minScore = 0;
        firstCategory._maxScore = 0;
        if (firstCategory.children && firstCategory.children.length) {
            for (let j = 0; j < firstCategory.children.length; j++) {
                let secondCategory = firstCategory.children[j]; //遍历二级分类
                if (!secondCategory.allPointDataList) {
                    secondCategory.allPointDataList = [];
                }
                secondCategory.pointDataList = secondCategory.allPointDataList.filter(
                    (item) => item.status === 1
                );
                Udesk.business.scoreCompute.computeSecondaryCategoryLimitScore(secondCategory);
                firstCategory._minScore += secondCategory._minScore;
                firstCategory._maxScore += secondCategory._maxScore;
            }
        }
    }
    classifications.forEach((firstCategory) => {
        Udesk.business.scoreCompute.computeFinalLimitScore(firstCategory);
    });
    that.actions.getCategoryScore(classifications);
    that.privates.classTree = getMoveTree(classifications);
    that.setState({
        classifications,
    });
}
