// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { GetReviewCallByCallIdDownLoadParam, InputStreamResource } from '../../../../types';

/**
 * 下载会话为TEXT文档
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/{callId}/downLoad
 * @param callId
 */
export function getReviewCallByCallIdDownLoad(
    options: ApiOptions<GetReviewCallByCallIdDownLoadParam, never> & {
        segments: GetReviewCallByCallIdDownLoadParam;
    }
): Promise<InputStreamResource> {
    return request<never, GetReviewCallByCallIdDownLoadParam, never>(
        '/review/call/{callId}/downLoad',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewCallByCallIdDownLoad' }
    );
}

export const meta = [{ tags: ['通话记录'], path: '/review/call/{callId}/downLoad', method: 'get' }];
