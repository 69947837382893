import React from 'react';
import Udesk from 'Udesk';
import PageLeftTable from './components/pageLeftTable';
import PageCenterTable from './components/pageCenterTable';
import PageRightTable from './components/pageRightTable';
import { createComponent } from 'src/util/component';
import './index.scss'; 

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};

const Template = React.memo((props: any) => {
    return (
        <div className='udesk-qa-web-page selling-point-dissent-page'>
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root'>
                    <div>
                        <PageLeftTable {...props}/>
                        <PageCenterTable {...props}/>
                        <PageRightTable {...props}/>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default createComponent(
    Template,
    {
        leftPageSearchText: undefined,
        leftPagePageNum: Udesk.config.paging.defaultPageNumber,
        leftPageSelectedRow: null as any,
        centerPagePageNum: Udesk.config.paging.defaultPageNumber,
        centerPageSelectedRow: null as any,

        rightPagePagination: defaultPagination,
        rightFilterData: undefined,
    },
    [
        /^\/algorithm-platform\/training-center\/words-mining\/*/i,
    ]
);
