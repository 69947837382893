import React, { useCallback, useContext, useEffect } from 'react';
import UdeskLocales from 'UdeskLocales';
import { useCoachOrgTreeDataWithPermit } from 'src/util/hook/user';
import { Button, Form, Space } from 'udesk-ui';
import OrganizationMemberSelect from '../../task-center/components/OrganizationMemberSelect';
import { FilterContext, InitialValuesContext } from '../context';
import UdeskDatePicker from 'Component/common/udesk-date-picker';

export default React.memo<any>((props) => {
    const { treeData } = useCoachOrgTreeDataWithPermit();
    const [form] = Form.useForm();
    const [, setParams] = useContext(FilterContext);
    const [initialValues] = useContext(InitialValuesContext);

    const onFinish = useCallback(
        (values) => {
            setParams({
                organizationIds: values.data?.organizationIds?.join(),
                startDate: values.date[0].split(' ')[0],
                endDate: values.date[1].split(' ')[0],
            });
        },
        [setParams]
    );

    const onReset = useCallback(() => {
        form.resetFields();
        form.submit();
    }, [form]);

    useEffect(() => {
        form.resetFields();
    }, [form, initialValues]);

    return (
        <Form
            form={form}
            layout="inline"
            onFinish={onFinish}
            style={{
                padding: '16px',
                justifyContent: 'space-between',
            }}
            initialValues={initialValues}
        >
            <Space>
                <Form.Item
                    name="data"
                    label={
                        /* 员工部门 */ UdeskLocales['current'].pages.coach.dashboard.components
                            .filter.employeeDepartment
                    }
                >
                    <OrganizationMemberSelect width={300} treeData={treeData} />
                </Form.Item>

                <Form.Item name="date">
                    <UdeskDatePicker allowClear={false} />
                </Form.Item>
            </Space>

            <Form.Item>
                <Space>
                    <Button onClick={onReset}>{UdeskLocales['current'].labels.reset}</Button>
                    <Button type="primary" htmlType="submit">
                        {UdeskLocales['current'].labels.confirm}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
});
