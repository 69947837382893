/*
 * @Author: 唐毓斌
 * @Description: 简单饼图
 */

import React, { useEffect, useRef } from 'react';
import Echarts from 'echarts';
import { Spin } from 'udesk-ui';

interface SimplePieChartProps {
    id: string;
    // width?: number | string;
    loading?: boolean;
    height: number | string;
    title: string;
    chartTitle: string;
    subTitle: string;
    data: { name: string; value?: number }[];
    textFontSize?: number;
    colorArr: string[];
}

const SimplePieChart: React.FC<SimplePieChartProps> = (props) => {
    const {
        id,
        height,
        title,
        subTitle,
        colorArr = ['#0376FB', '#fafafa'],
        data,
        loading,
        textFontSize,
        chartTitle,
    } = props;
    const currentChartRef = useRef<any>(null);
    console.log(textFontSize);
    useEffect(() => {
        if (!currentChartRef.current) {
            currentChartRef.current = Echarts.init(document.getElementById(id));
        }
        if (currentChartRef.current) {
            const option = {
                animation: 'auto',
                color: colorArr,
                // title: {
                //   text: chartTitle,
                //   top: '70',
                //   right: '100',
                //   textStyle: {
                //     lineHeight: 10,
                //     fontSize: 18
                //   },
                // },

                title: {
                    top: 'center',
                    left: 'center',
                    text: title,
                    textStyle: {
                        color: '#0376FB',
                        lineHeight: 10,
                        fontSize: textFontSize || 18,
                    },
                    subtext: subTitle,
                    subtextStyle: {
                        color: '#0376FB',
                    },
                },
                legend: {
                    orient: 'vertical',
                    right: 100,
                    top: 100,
                },
                tooltip: {
                    trigger: 'item',
                },
                graphic: {
                    type: 'text',
                    style: {
                        text: chartTitle,
                        font: '22px Microsoft YaHei',
                        fill: '#333',
                        x: 10,
                        y: 0,
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '80%'],
                        hoverOffset: 2,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        // itemStyle: {
                        //     borderRadius: 10,
                        //     borderColor: '#fff',
                        //     borderWidth: 2,
                        // },
                        label: {
                            show: false,
                            position: 'outer',
                            alignTo: 'none',
                            bleedMargin: 5,
                            formatter: '{name|{b}}',
                            rich: {
                                count: {
                                    fontSize: 10,
                                    color: '#999',
                                },
                            },
                        },
                        data,
                    },
                ],
            };
            currentChartRef.current.setOption(option);
        }
    }, [data, id, subTitle, title]);

    const chartResize = () => {
        currentChartRef.current && currentChartRef.current.resize();
    };

    useEffect(() => {
        window.addEventListener('resize', chartResize);

        return () => {
            window.removeEventListener('resize', chartResize);
        };
    }, []);

    return (
        <Spin spinning={loading}>
            <div
                id={id}
                style={{
                    // width: width,
                    height: height,
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '3px',
                    paddingTop: '30px',
                }}
            />
        </Spin>
    );
};

export default SimplePieChart;
