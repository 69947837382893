import Udesk from 'udesk-react/src/udesk';
import common from '../index';

export default {
    convertFieldType,
    convertInputType,
    convertOperators,
};

function convertFieldType(value) {
    return enumConverter(value, common.enums.fieldTypes);
}

function convertInputType(value) {
    return enumConverter(value, common.enums.inputTypes);
}

function convertOperators(value) {
    return enumArrayConverter(value, common.enums.fieldOperators);
}

function enumConverter(value, enumType) {
    if (value instanceof Udesk.enums.EnumItemClass) {
        return value;
    } else {
        let enumItem = enumType.get(value) || enumType[value];
        return enumItem;
    }
}

function enumArrayConverter(value, enumType) {
    if (value == null) {
        return value;
    }
    let newArray = [];
    for (let item of value) {
        newArray.push(enumConverter(item, enumType));
    }
    return newArray;
}
