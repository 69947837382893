// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetReviewIntelligentPartnerCourseQuery,
    BaseResponseListIntelligentPartnerCourseFoundResponse,
    PostReviewIntelligentPartnerCourseBody,
    BaseResponseIntelligentPartnerCourseFoundResponse,
} from '../../../types';

/**
 * 管理员端课程管理列表
 *
 * @export
 * @tags 智能陪练-课程
 * @link [GET] /review/intelligentPartner/course
 */
export function getReviewIntelligentPartnerCourse(): Promise<BaseResponseListIntelligentPartnerCourseFoundResponse>;
export function getReviewIntelligentPartnerCourse(
    options: ApiOptions<never, GetReviewIntelligentPartnerCourseQuery>
): Promise<BaseResponseListIntelligentPartnerCourseFoundResponse>;
export function getReviewIntelligentPartnerCourse(
    options?: ApiOptions<never, GetReviewIntelligentPartnerCourseQuery>
): Promise<BaseResponseListIntelligentPartnerCourseFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerCourseQuery>(
        '/review/intelligentPartner/course',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewIntelligentPartnerCourse' }
    );
}

/**
 * 保存课程
 *
 * @export
 * @tags 智能陪练-课程
 * @link [POST] /review/intelligentPartner/course
 */
export function postReviewIntelligentPartnerCourse(
    data: PostReviewIntelligentPartnerCourseBody
): Promise<BaseResponseIntelligentPartnerCourseFoundResponse>;
export function postReviewIntelligentPartnerCourse(
    data: PostReviewIntelligentPartnerCourseBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerCourseFoundResponse>;
export function postReviewIntelligentPartnerCourse(
    data: PostReviewIntelligentPartnerCourseBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerCourseFoundResponse> {
    return request<PostReviewIntelligentPartnerCourseBody, never, never>(
        '/review/intelligentPartner/course',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerCourse',
        }
    );
}

export const meta = [
    { tags: ['智能陪练-课程'], path: '/review/intelligentPartner/course', method: 'get' },
    { tags: ['智能陪练-课程'], path: '/review/intelligentPartner/course', method: 'post' },
];
