import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import moment from 'moment';
import Locales from 'UdeskLocales';

class TemplateComponentsMyDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {

    };
    privates = {
        taskData: [],
        fieldCount: [],
        fieldData: [],
        tagData: [],
        infoData: [],
        interData: [],
        conceptData: [],
        multiData: [],
        multiField: {},
        taskField: {},
        tagField: {},
        interField: {},
        conceptField: {},
        infoField: {},
        fieldMap: {},
        formRef: React.createRef(),
        visible: false,
        explain: null,
        name: null,
        description: null,
        editEnable: 1,
        showEnable: 1,
        orgName: null,
        releaseVersion: null,
        version: null,
        releaseStatus: null,
        logList: [],
        updateVersion: null,
        spinLoading: false,
        loading: false
    };

    actions = {
        getField() {
            let { fieldCount } = this.privates;
            let { sdkOptions } = this.props;
            let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionPoints/list/base`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(taskUrl, { taskIdList: fieldCount }).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.fieldData = resp.data;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        inputChanged(e) {
            this.privates.nameCount = e.target.value.length || 0;
            this.actions.update();
        },
        //获取质检任务关联 语义标签、概念词、智能交互等
        getInfo(id) {
            let { sdkOptions } = this.props;
            let {
                infoData,
                conceptData,
                interData,
                tagData,
                fieldData,
                multiData,
            } = this.privates;
            let fieldObj = fieldData.filter((item) => item.taskId === id);
            if (fieldObj) {
                fieldObj.forEach(item => {
                    this.privates.fieldMap[item.id] = true;
                });
            }
            let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionTasks/${id}/module-elements`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.get(taskUrl).then(
                    (resp) => {

                        // this.privates.taskCorrelation = resp.data;
                        if (resp.data && resp.data.length > 0) {
                            let list = resp.data;
                            let multiObj = multiData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.multi.id && item.id === current.id));
                            if (multiObj) {
                                multiObj.forEach(item => {
                                    this.privates.multiField[item.id] = true;
                                });
                            }
                            let conceptObj = conceptData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.concept.id && item.id === current.id));
                            if (conceptObj) {
                                conceptObj.forEach(item => {
                                    this.privates.conceptField[item.id] = true;
                                });
                            }
                            let interObj = interData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.inter.id && item.id === current.id));
                            if (interObj) {
                                interObj.forEach(item => {
                                    this.privates.interField[item.id] = true;
                                });
                            }
                            let tagObj = tagData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.tag.id && item.id === current.id));
                            if (tagObj) {
                                tagObj.forEach(item => {
                                    this.privates.tagField[item.id] = true;
                                });
                            }
                            let infoObj = infoData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.info.id && item.id === current.id));
                            if (infoObj) {
                                infoObj.forEach(item => {
                                    this.privates.infoField[item.id] = true;
                                });
                            }
                            this.actions.update();
                        }
                        resolve(resp);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });

        },
        //获取质检点关联 语义标签、概念词、智能交互等
        getFieldInfo(id) {
            let { sdkOptions } = this.props;
            let {
                conceptData,
                interData,
                tagData,
                multiData,
            } = this.privates;
            let taskUrl = Udesk.business.apiPath.concatApiPath(`inspectionPoints/${id}/module-elements`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.get(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        // this.privates.taskCorrelation = resp.data;
                        if (resp.data && resp.data.length > 0) {
                            let list = resp.data;
                            let multiObj = multiData.filter((item) => list.find(current => current.type === 19 && item.id === current.id));
                            if (multiObj) {
                                multiObj.forEach(item => {
                                    this.privates.multiField[item.id] = true;
                                });
                            }
                            let conceptObj = conceptData.filter((item) => list.find(current => current.type === 20 && item.id === current.id));
                            if (conceptObj) {
                                conceptObj.forEach(item => {
                                    this.privates.conceptField[item.id] = true;
                                });
                            }
                            let interObj = interData.filter((item) => list.find(current => current.type === 12 && item.id === current.id));
                            if (interObj) {
                                interObj.forEach(item => {
                                    this.privates.interField[item.id] = true;
                                });
                            }
                            let tagObj = tagData.filter((item) => list.find(current => current.type === 10 && item.id === current.id));
                            if (tagObj) {
                                tagObj.forEach(item => {
                                    this.privates.tagField[item.id] = true;
                                });
                            }
                            this.actions.update();
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });

        },
        getMultiInfo(id) {
            let { sdkOptions } = this.props;
            let { infoData, conceptData, tagData, } = this.privates;
            let taskUrl = Udesk.business.apiPath.concatApiPath(`interactiveDatas/${id}/module-elements`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.get(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        // this.privates.taskCorrelation = resp.data;
                        if (resp.data && resp.data.length > 0) {
                            let list = resp.data;
                            let infoObj = infoData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.info.id && item.id === current.id));
                            if (infoObj) {
                                infoObj.forEach(item => {
                                    this.privates.infoField[item.id] = true;
                                });
                            }
                            let conceptObj = conceptData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.concept.id && item.id === current.id));
                            if (conceptObj) {
                                conceptObj.forEach(item => {
                                    this.privates.conceptField[item.id] = true;
                                });
                            }
                            let tagObj = tagData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.tag.id && item.id === current.id));
                            if (tagObj) {
                                tagObj.forEach(item => {
                                    this.privates.tagField[item.id] = true;
                                });
                            }
                            this.actions.update();
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });

        },
        getTagInfo(id) {
            let { sdkOptions } = this.props;
            let { infoData, conceptData, } = this.privates;
            let taskUrl = Udesk.business.apiPath.concatApiPath(`semantic-tag/${id}/module-elements`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.get(taskUrl).then(
                    (resp) => {
                        resolve(resp);
                        // this.privates.taskCorrelation = resp.data;
                        if (resp.data && resp.data.length > 0) {
                            let list = resp.data;
                            let infoObj = infoData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.info.id && item.id === current.id));
                            if (infoObj) {
                                infoObj.forEach(item => {
                                    this.privates.infoField[item.id] = true;
                                });
                            }
                            let conceptObj = conceptData.filter((item) => list.find(current => current.type === Udesk.enums.templateTypeList.concept.id && item.id === current.id));
                            if (conceptObj) {
                                conceptObj.forEach(item => {
                                    this.privates.conceptField[item.id] = true;
                                });
                            }
                            this.actions.update();
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });

        },
        onTaskChange(id, e) {
            let { taskField } = this.privates;
            if (taskField[id]) {
                this.privates.taskField[id] = e.target.checked;
            } else {
                this.privates.taskField = {
                    ...taskField,
                    [id]: e.target.checked,
                };
            }
            this.actions.update();
            if (e.target.checked) {
                this.actions.getInfo(id);
                this.actions.update();
            }
        },
        fieldChange(id, e) {
            let { fieldMap } = this.privates;
            if (fieldMap[id]) {
                this.privates.fieldMap[id] = e.target.checked;
            } else {
                this.privates.fieldMap = {
                    ...fieldMap,
                    [id]: e.target.checked,
                };
            }
            if (e.target.checked) {
                this.actions.getFieldInfo(id);
            }
            this.actions.update();
        },
        multiChange(id, e) {
            let { multiField } = this.privates;
            if (multiField[id]) {
                this.privates.multiField[id] = e.target.checked;
            } else {
                this.privates.multiField = {
                    ...multiField,
                    [id]: e.target.checked,
                };
            }
            if (e.target.checked) {
                this.actions.getMultiInfo(id);
            }
            this.actions.update();
        },
        tagChange(id, e) {
            let { tagField } = this.privates;
            if (tagField[id]) {
                this.privates.tagField[id] = e.target.checked;
            } else {
                this.privates.tagField = {
                    ...tagField,
                    [id]: e.target.checked,
                };
            }
            if (e.target.checked) {
                this.actions.getTagInfo(id);
            }
            this.actions.update();
        },
        conceptChange(id, e) {
            let { conceptField } = this.privates;
            if (conceptField[id]) {
                this.privates.conceptField[id] = e.target.checked;
            } else {
                this.privates.conceptField = {
                    ...conceptField,
                    [id]: e.target.checked,
                };
            }
            this.actions.update();
        },
        infoChange(id, e) {
            let { infoField } = this.privates;
            if (infoField[id]) {
                this.privates.infoField[id] = e.target.checked;
            } else {
                this.privates.infoField = {
                    ...infoField,
                    [id]: e.target.checked,
                };
            }
            this.actions.update();
        },
        primarySumbit() {
            this.privates.formRef.current.validateFields().then((value) => {
                this.privates.visible = true;
                this.actions.update();
            });
        },
        interChange(id, e) {
            let { interField } = this.privates;
            if (interField[id]) {
                this.privates.interField[id] = e.target.checked;
            } else {
                this.privates.interField = {
                    ...interField,
                    [id]: e.target.checked,
                };
            }
            this.actions.update();
        },
        save() {
            let { sdkOptions } = this.props;
            let { explain,
                taskData,
                fieldData,
                tagData,
                infoData,
                interData,
                conceptData,
                multiData,
                multiField,
                taskField,
                tagField,
                interField,
                conceptField,
                infoField,
                fieldMap,
            } = this.privates;
            let moduleElementList = [];
            let taskFieldList = taskData.filter(item => taskField[item.id]).map(items => {
                return {
                    ...items,
                    type: Udesk.enums.templateTypeList.task.id
                };
            });
            moduleElementList = moduleElementList.concat(taskFieldList);
            let fieldList = fieldData.filter(item => fieldMap[item.id]).map(items => {
                return {
                    ...items,
                    type: Udesk.enums.templateTypeList.point.id
                };
            });
            moduleElementList = moduleElementList.concat(fieldList);
            let tagList = tagData.filter(item => tagField[item.id]).map(items => {
                return {
                    ...items,
                    type: Udesk.enums.templateTypeList.tag.id
                };
            });
            moduleElementList = moduleElementList.concat(tagList);
            let multiList = multiData.filter(item => multiField[item.id]).map(items => {
                return {
                    ...items,
                    type: Udesk.enums.templateTypeList.multi.id
                };
            });
            moduleElementList = moduleElementList.concat(multiList);
            let interList = interData.filter(item => interField[item.id]).map(items => {
                return {
                    ...items,
                    type: Udesk.enums.templateTypeList.inter.id
                };
            });
            moduleElementList = moduleElementList.concat(interList);
            let conceptList = conceptData.filter(item => conceptField[item.id]).map(items => {
                return {
                    ...items,
                    type: Udesk.enums.templateTypeList.concept.id
                };
            });
            moduleElementList = moduleElementList.concat(conceptList);
            let infoList = infoData.filter(item => infoField[item.id]).map(items => {
                return {
                    ...items,
                    type: Udesk.enums.templateTypeList.info.id
                };
            });
            moduleElementList = moduleElementList.concat(infoList);
            if (_isEmpty(moduleElementList)) {
                return Udesk.ui.notify.error(Locales.current.pages.admin.templateComponent.list.configNo);
            }
            if (!explain || explain.trim() === '') {
                return Udesk.ui.notify.error(Locales.current.pages.admin.templateComponent.list.updateNo);
            }

            this.privates.formRef.current.validateFields().then((value) => {
                this.privates.loading = true;
                this.actions.update();
                let data = value;
                data.moduleElementList = moduleElementList;
                data.updateNote = explain;
                let url = Udesk.business.apiPath.concatApiPath(`inspectionModules/save`, sdkOptions);
                let method = Udesk.enums.requestMethods.create.name;
                if (this.props.match.params.id) {
                    url = Udesk.business.apiPath.concatApiPath(`inspectionModules/${this.props.match.params.id}`, sdkOptions);
                    method = Udesk.enums.requestMethods.create.name;
                    data.id = this.props.match.params.id;
                }
                new Promise((resolve, reject) => {
                    Udesk.ajax(url, data, method).then(
                        (resp) => {
                            resolve(resp);
                            if (data.id) {
                                Udesk.ui.notify.success(Locales.current.pages.admin.templateComponent.list.editSuccess);
                            } else {
                                Udesk.ui.notify.success(Locales.current.pages.admin.templateComponent.list.saveSuccess);
                            }
                            this.privates.visible = false;
                            this.privates.loading = false;
                            this.actions.update();
                            let routeOptions = {
                                history: this.props.history,
                                routeName: 'templateComponentMyIndex',
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                        (reason) => {
                            this.privates.loading = false;
                            this.actions.update();
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            });
        },
        cancel() {
            this.privates.visible = false;
            this.actions.update();
        },
        explainChange(e) {
            this.privates.explain = e.target.value;
        },
        updateField(list, id, flag, type) {
            list.forEach(item => {
                if (item.id === id) {
                    if (flag) {
                        item.updateFlag = 0;
                    } else {
                        item.updateFlag = 1;
                        if (type === Udesk.enums.templateTypeList.task.id) {
                            this.actions.getInfo(id);
                        } else if (type === Udesk.enums.templateTypeList.point.id) {
                            this.actions.getFieldInfo(id);
                        } else if (type === Udesk.enums.templateTypeList.multi.id) {
                            this.actions.getMultiInfo(id);
                        } else if (type === Udesk.enums.templateTypeList.tag.id) {
                            this.actions.getTagInfo(id);
                        }
                    }
                }
            });
            this.actions.update();
        },
        backToIndexPage() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'templateComponentMyIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        goBack() {
            let { sdkOptions, match } = this.props;
            if (match.params.id) {
                let myDetail = Udesk.business.apiPath.concatApiPath(`inspectionModules/${this.props.match.params.id}/cancel`, sdkOptions);
                new Promise((resolve, reject) => {
                    Udesk.ajax.put(myDetail).then(
                        (resp) => {
                            resolve(resp);
                            Udesk.ui.notify.success(Locales.current.pages.admin.templateComponent.list.widthdraw);
                            this.actions.reloadAsyncModel();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
        goInfo(id, type, taskId, categoryId) {
            if (type === Udesk.enums.templateTypeList.task.id) {
                window.open(`/site/tasks/manage/${id}/template/basic-info/index`);
            } else if (type === Udesk.enums.templateTypeList.point.id) {
                window.open(`/site/tasks/manage/${taskId}/template/check-point/${categoryId}/edit/${id}`);
            } else if (type === Udesk.enums.templateTypeList.multi.id) {
                window.open(`/site/semantic-intelligence/interactive-recognition/edit/${id}`);
            } else if (type === Udesk.enums.templateTypeList.tag.id) {
                window.open(`/site/semantic-intelligence/semantic-tags/edit/${id}`);
            } else if (type === Udesk.enums.templateTypeList.concept.id) {
                window.open(`/site/semantic-intelligence/concept-word/edit/${id}`);
            } else if (type === Udesk.enums.templateTypeList.info.id) {
                window.open(`/site/semantic-intelligence/information-entities/edit/${id}`);
            }
        },
        showChange(e) {
            this.privates.showEnable = e.target.value;
            if (e.target.value === 0) {
                this.privates.formRef.current.setFieldsValue({
                    'editEnable': 0,
                }
                );
            }
            this.actions.update();
        },
        geDetail() {
            let { match, sdkOptions } = this.props;
            let urlData = Object.assign({}, Object.fromEntries(new URLSearchParams(this.props.location.search)));
            let myDetail = Udesk.business.apiPath.concatApiPath(
                `inspectionModules/${match.params.id}/${urlData.version}/2`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(myDetail).then(
                    (resp) => {
                        resolve(resp);
                        if (!_isEmpty(resp.data)) {
                            this.privates.name = resp.data.name;
                            this.privates.orgName = resp.data.orgName;
                            this.privates.description = resp.data.description;
                            this.privates.showEnable = resp.data.showEnable || 0;
                            this.privates.editEnable = resp.data.editEnable || 0;
                            this.privates.version = resp.data.version;
                            this.privates.nameCount = (this.privates.name && this.privates.name.length) || 0;
                            if (this.privates.version) {
                                let list = this.privates.version.split('_');
                                let str = list[0] + `_${parseInt(list[1], 10) + 1}`;
                                this.privates.updateVersion = str;
                            }
                            this.privates.releaseVersion = resp.data.releaseVersion;
                            this.privates.releaseStatus = resp.data.releaseStatus;
                            this.privates.logList = resp.data.logList;
                            this.privates.formRef.current && this.privates.formRef.current.setFieldsValue({
                                name: this.privates.name,
                                orgName: this.privates.orgName,
                                description: this.privates.description,
                                showEnable: this.privates.showEnable,
                                editEnable: this.privates.editEnable
                            });
                            if (resp.data.moduleElementList && resp.data.moduleElementList.length > 0) {
                                resp.data.moduleElementList.forEach(item => {
                                    if (item.type === Udesk.enums.templateTypeList.task.id) {
                                        this.privates.taskField = {
                                            ...this.privates.taskField,
                                            [item.id]: true
                                        };
                                        if (this.privates.taskData.find(items => items.id === item.id)) {
                                            this.privates.taskData.forEach(items => {
                                                if (items.id === item.id) {
                                                    items.needUpdate = item.needUpdate;
                                                    items.updateFlag = item.updateFlag;
                                                }
                                            });
                                        }
                                    } else if (item.type ===Udesk.enums.templateTypeList.point.id) {
                                        this.privates.fieldMap = {
                                            ...this.privates.fieldMap,
                                            [item.id]: true
                                        };
                                        if (this.privates.fieldData.find(items => items.id === item.id)) {
                                            this.privates.fieldData.forEach(items => {
                                                if (items.id === item.id) {
                                                    items.needUpdate = item.needUpdate;
                                                    items.updateFlag = item.updateFlag;
                                                }
                                            });
                                        }
                                    } else if (item.type === Udesk.enums.templateTypeList.tag.id) {
                                        this.privates.tagField = {
                                            ...this.privates.tagField,
                                            [item.id]: true
                                        };
                                        if (this.privates.tagData.find(items => items.id === item.id)) {
                                            this.privates.tagData.forEach(items => {
                                                if (items.id === item.id) {
                                                    items.needUpdate = item.needUpdate;
                                                    items.updateFlag = item.updateFlag;
                                                }
                                            });
                                        }
                                    } else if (item.type === Udesk.enums.templateTypeList.multi.id) {
                                        this.privates.multiField = {
                                            ...this.privates.multiField,
                                            [item.id]: true
                                        };
                                        if (this.privates.multiData.find(items => items.id === item.id)) {
                                            this.privates.multiData.forEach(items => {
                                                if (items.id === item.id) {
                                                    items.needUpdate = item.needUpdate;
                                                    items.updateFlag = item.updateFlag;
                                                }
                                            });
                                        }
                                    } else if (item.type === Udesk.enums.templateTypeList.inter.id) {
                                        this.privates.interField = {
                                            ...this.privates.interField,
                                            [item.id]: true
                                        };
                                        if (this.privates.interData.find(items => items.id === item.id)) {
                                            this.privates.interData.forEach(items => {
                                                if (items.id === item.id) {
                                                    items.needUpdate = item.needUpdate;
                                                    items.updateFlag = item.updateFlag;
                                                }
                                            });
                                        }
                                    } else if (item.type === Udesk.enums.templateTypeList.concept.id) {
                                        this.privates.conceptField = {
                                            ...this.privates.conceptField,
                                            [item.id]: true
                                        };
                                        if (this.privates.conceptData.find(items => items.id === item.id)) {
                                            this.privates.conceptData.forEach(items => {
                                                if (items.id === item.id) {
                                                    items.needUpdate = item.needUpdate;
                                                    items.updateFlag = item.updateFlag;
                                                }
                                            });
                                        }
                                    } else if (item.type === Udesk.enums.templateTypeList.info.id) {
                                        this.privates.infoField = {
                                            ...this.privates.infoField,
                                            [item.id]: true
                                        };
                                        if (this.privates.infoData.find(items => items.id === item.id)) {
                                            this.privates.infoData.forEach(items => {
                                                if (items.id === item.id) {
                                                    items.needUpdate = item.needUpdate;
                                                    items.updateFlag = item.updateFlag;
                                                }
                                            });
                                        }
                                    }

                                });
                            }
                        }

                        this.privates.spinLoading = false;
                        this.actions.update();
                    },
                    (reason) => {
                        this.privates.spinLoading = false;
                        this.actions.update();
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        }
    };
    init() {
        this.privates.orgName = Udesk.data.init.company.name;
    }

    //#region Life Cycle
    componentDidMount() {
        let data = moment().format('YYYYMMDD');
        this.privates.updateVersion = `${data}_1`;
        this.actions.update();
    }
    componentWillUnmount() { }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        if (asyncKey === 'taskData') {
            if (asyncResult.data && asyncResult.data.length > 0) {
                asyncResult.data.forEach(item => {
                    if (!this.privates.taskData.find(items => items.id === item.id)) {
                        this.privates.taskData.push(item);
                    }
                });
                this.privates.fieldCount = asyncResult.data.map((item) => item.id);
                this.actions.getField();
            }
        } else if (asyncKey === 'tagData') {
            if (asyncResult.data && asyncResult.data.length > 0) {
                asyncResult.data.forEach(item => {
                    if (!this.privates.tagData.find(items => items.id === item.id)) {
                        this.privates.tagData.push(item);
                    }
                });
            }
        } else if (asyncKey === 'interData') {
            if (asyncResult.data && asyncResult.data.length > 0) {
                asyncResult.data.forEach(item => {
                    if (!this.privates.interData.find(items => items.id === item.id)) {
                        this.privates.interData.push(item);
                    }
                });
            }
        } else if (asyncKey === 'conceptData') {
            if (asyncResult.data && asyncResult.data.length > 0) {
                asyncResult.data.forEach(item => {
                    if (!this.privates.conceptData.find(items => items.id === item.id)) {
                        this.privates.conceptData.push(item);
                    }
                });
            }
        } else if (asyncKey === 'infoData') {
            if (asyncResult.data && asyncResult.data.length > 0) {
                asyncResult.data.forEach(item => {
                    if (!this.privates.infoData.find(items => items.id === item.id)) {
                        this.privates.infoData.push(item);
                    }
                });
            }
        } else if (asyncKey === 'multiData') {
            if (asyncResult.data && asyncResult.data.length > 0) {
                asyncResult.data.forEach(item => {
                    if (!this.privates.multiData.find(items => items.id === item.id)) {
                        this.privates.multiData.push(item);
                    }
                });
            }
        }
        this.actions.update();
    }
    //#endregion
}

export default TemplateComponentsMyDetailComponent;
