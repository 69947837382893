import React, { FC, useMemo, useRef, useState } from 'react';
import { Slider } from 'udesk-ui';
import { PlayCircleFilled, PauseCircleFilled } from '@udesk/icons';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

type AudioProps = {
    src?: string;
    dialogueRef?: any;
    scoreAnalysis?: any[];
};

const Audio: FC<AudioProps> = (props) => {
    const { src, dialogueRef, scoreAnalysis } = props;

    const audioRef = useRef<any>();
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const onCanPlay=(e)=>{
        setDuration(e.target.duration);
    };

    const onEnded = () => {
        setIsPlaying(false);
    };

    const onPlay = () => {
        setIsPlaying(true);
        audioRef.current?.play?.();
    };

    const onPause = () => {
        setIsPlaying(false);
        audioRef.current?.pause?.();
    };

    const [curPos, setCurPos] = useState(0);

    const onTimeChange = (v) => {
        setCurPos(v);
        audioRef.current.currentTime = (v / 100) * duration;
    };

    const [curIdx, setCurIdx] = useState(-1);

    const onTimeUpdate = (e) => {
        const moment = e.target.currentTime;
        setCurPos((moment / duration) * 100);

        scoreAnalysis?.reduce((pre, cur, idx) => {
            const n = (cur.duration || 0) / 1000;
            if (pre + n >= moment && pre < moment) {
                if (idx !== curIdx) {
                    dialogueRef.current?.scrollTo?.(idx);
                    setCurIdx(idx);
                }
            }
            return pre + n;
        }, 0);
    };

    const { curTime, totalTime } = useMemo(() => {
        const totalTime = Udesk.utils.date.formatDurationWithMillisecond(duration);
        const curTime = Udesk.utils.date.formatDurationWithMillisecond((curPos / 100) * duration);

        return {
            totalTime,
            curTime,
        };
    }, [curPos, duration]);

    if (!src) {
        return null;
    }

    return (
        <div style={{ padding: '2px 0px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {isPlaying ? (
                    <PauseCircleFilled onClick={onPause} />
                ) : (
                    <PlayCircleFilled onClick={onPlay} />
                )}

                <Slider
                    tooltipVisible={false}
                    style={{ flex: 1, margin: '0px 14px' }}
                    value={curPos}
                    onChange={onTimeChange}
                />

                <div style={{ color: 'rgba(0, 0, 0, .45)' }}>
                    {curTime}/{totalTime}
                </div>
            </div>

            <audio
                ref={audioRef}
                src={src}
                onTimeUpdate={onTimeUpdate}
                onCanPlay={onCanPlay}
                onEnded={onEnded}
                hidden
            >{/* 浏览器不支持音频播放。 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.audio.index.theBrowserDoesNotSupportAudioPlayback}</audio>
        </div>
    );
};

export default Audio;
