import React from 'react';
import Udesk from 'Udesk';
// import ReactTinymce from 'Component/common/udesk-qa-tinymce';
import MassagePushConfigurationComponent from './component';
import './style.scss';
import { Checkbox, Input, Select } from 'udesk-ui';
// 引入编辑器组件
import BraftEditor from 'braft-editor';
// 引入编辑器样式
import 'braft-editor/dist/index.css';
import { language } from 'src/components/common/b-editor';
import AssociateSelect from '../associate-select';
const braftEditorStyle = {
    border: '1px solid #d9d9d9',
};
export default class MassagePushConfigurationTemplate extends MassagePushConfigurationComponent {
    render() {
        let { privates, props, actions, locales } = this;
        let { pushConf } = privates;
        const extendControls = (pushConf) => [
            {
                key: 'custom-dropdown',
                type: 'dropdown',
                autoHide: true,
                text: locales.enums.common.operatorTypes.associate,
                component: (
                    <div
                        style={{
                            backgroundColor: '#fff',
                            padding: 16,
                            overflow: 'auto',
                            height: 300,
                        }}
                    >
                        <AssociateSelect
                            onClick={
                                props.associateDataClickInRich || actions.associateDataClickInRich
                            }
                            pushConfContentChange={actions.pushConfContentChange}
                            pushConf={pushConf}
                            // associateDivRef={privates.associateDivRef}
                            // textAreaRef={privates.textAreaRef}
                            taskType={props.taskType}
                            taskTypeList={props.taskTypeList}
                            taskId={props.taskId}
                        ></AssociateSelect>
                    </div>
                ),
            },
        ];
        return (
            <React.Fragment>
                {/* 系统消息 */}
                <If
                    condition={
                        parseInt(pushConf.actionType, 10) === Udesk.enums.messagePushType.system.id
                    }
                >
                    <div className="massage-push-configuration-row">
                        <div className="massage-push-configuration-row-label">
                            {locales.components.massageConfiguration.pushTarget}
                        </div>
                        <div className="massage-push-configuration-row-content">
                            <Checkbox.Group
                                options={privates.autoAddPushTargets}
                                value={pushConf.actionDetails.pushTargetList}
                                onChange={actions.onTargetsChange.params(pushConf.actionType)}
                            />
                            <Checkbox
                                checked={privates.showCustomize}
                                onChange={actions.showCustomize.params(pushConf)}
                            >
                                {locales.components.massageConfiguration.customizePushTarget}
                            </Checkbox>
                            <If condition={privates.showCustomize}>
                                <div className="massage-push-configuration-child-row">
                                    <div className="massage-push-configuration-child-row-label">
                                        {locales.components.massageConfiguration.sysUser}
                                    </div>
                                    <div className="massage-push-configuration-child-row-content">
                                        <Select
                                            mode="multiple"
                                            getPopupContainer={(triggerNode) =>
                                                triggerNode.parentElement
                                            }
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            defaultValue={pushConf.actionDetails.pushUserList}
                                            onChange={actions.onSelectChange.params(
                                                'pushUserList',
                                                pushConf.actionType
                                            )}
                                        >
                                            {props.users.map((item) => (
                                                <Select.Option value={item.id}>
                                                    {item.realname}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="massage-push-configuration-child-row">
                                    <div className="massage-push-configuration-child-row-label">
                                        {locales.components.massageConfiguration.sysRole}
                                    </div>
                                    <div className="massage-push-configuration-child-row-content">
                                        <Select
                                            mode="multiple"
                                            getPopupContainer={(triggerNode) =>
                                                triggerNode.parentElement
                                            }
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            defaultValue={pushConf.actionDetails.pushRoleList}
                                            onChange={actions.onSelectChange.params(
                                                'pushRoleList',
                                                pushConf.actionType
                                            )}
                                        >
                                            {props.roles.map((item) => (
                                                <Select.Option value={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </If>
                        </div>
                    </div>
                </If>
                {/* 邮件 */}
                <If
                    condition={[
                        Udesk.enums.messagePushType.email.id,
                        Udesk.enums.messagePushType.udesk.id,
                    ].includes(parseInt(pushConf.actionType, 10))}
                >
                    <div className="massage-push-configuration-row">
                        <div className="massage-push-configuration-row-label">
                            {locales.components.massageConfiguration.emailPushTarget}
                        </div>
                        <div className="massage-push-configuration-row-content">
                            {props.taskType !==
                                Udesk.enums.inspectDataSources.wechatDialogue.id && (
                                <Checkbox
                                    onChange={actions.onPushObjectTypeChange.params(
                                        pushConf.actionType
                                    )}
                                    checked={
                                        pushConf.actionDetails.pushTargetType ===
                                        Udesk.enums.pushObjectTypes.staff.id
                                    }
                                >
                                    {Udesk.enums.pushObjectTypes.staff.name}
                                </Checkbox>
                            )}
                            <div className="massage-push-configuration-child-row">
                                {props.taskType !==
                                    Udesk.enums.inspectDataSources.wechatDialogue.id && (
                                    <div className="massage-push-configuration-child-row-label">
                                        {[Udesk.enums.messagePushType.udesk.id].includes(
                                            parseInt(pushConf.actionType, 10)
                                        )
                                            ? locales.components.massageConfiguration.designated
                                            : Udesk.enums.pushObjectTypes.email.name}
                                    </div>
                                )}
                                <div className="massage-push-configuration-child-row-content">
                                    <Input
                                        defaultValue={pushConf.actionDetails.targetEmails}
                                        onChange={actions.onInputChange.params(
                                            pushConf.actionType,
                                            'targetEmails'
                                        )}
                                        placeholder={
                                            locales.components.massageConfiguration
                                                .targetEmailsPlaceholder
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <If
                        condition={
                            parseInt(pushConf.actionType, 10) ===
                            Udesk.enums.messagePushType.email.id
                        }
                    >
                        <div className="massage-push-configuration-row">
                            <div className="massage-push-configuration-row-label">
                                {locales.components.massageConfiguration.subject}
                            </div>
                            <div className="massage-push-configuration-row-content">
                                <Input
                                    defaultValue={pushConf.actionDetails.subject}
                                    onChange={actions.onInputChange.params(
                                        pushConf.actionType,
                                        'subject'
                                    )}
                                />
                            </div>
                        </div>
                    </If>
                </If>
                {/* sdk */}
                <If
                    condition={
                        parseInt(pushConf.actionType, 10) === Udesk.enums.messagePushType.sdk.id
                    }
                >
                    <div className="massage-push-configuration-row">
                        <div
                            className="massage-push-configuration-row-label"
                            style={{ marginRight: 10 }}
                        >
                            {locales.components.massageConfiguration.SDKID}
                        </div>
                        <div className="massage-push-configuration-row-content">
                            <Input
                                defaultValue={pushConf.actionDetails.sdk}
                                onChange={actions.onInputChange.params(pushConf.actionType, 'sdk')}
                            />
                        </div>
                    </div>
                </If>
                {/* 富文本编辑器 */}
                <If
                    condition={
                        ![
                            Udesk.enums.messagePushType.interfacePush.id,
                            Udesk.enums.messagePushType.udesk.id,
                        ].includes(parseInt(pushConf.actionType, 10))
                    }
                >
                    <div className="massage-push-configuration-row massage-push-configuration-last-row">
                        <div className="massage-push-configuration-row-label">
                            {locales.components.massageConfiguration.content}
                        </div>
                        <div className="massage-push-configuration-row-content">
                            <BraftEditor
                                style={braftEditorStyle}
                                extendControls={extendControls(pushConf)}
                                value={pushConf.actionDetails._editorState}
                                onChange={actions.handleEditorChange.params(pushConf.actionType)}
                                language={language}
                            />
                        </div>
                    </div>
                </If>
            </React.Fragment>
        );
    }
}
