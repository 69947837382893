// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostKnowledgeDatasFindByKeywordWithoutPageBody,
    BaseResponseListKnowledgeDataFoundResponse,
} from '../../types';

/**
 * findAllWithoutPage
 *
 * @export
 * @tags knowledge-data-controller
 * @link [POST] /knowledgeDatas/findByKeywordWithoutPage
 */
export function postKnowledgeDatasFindByKeywordWithoutPage(
    data: PostKnowledgeDatasFindByKeywordWithoutPageBody
): Promise<BaseResponseListKnowledgeDataFoundResponse>;
export function postKnowledgeDatasFindByKeywordWithoutPage(
    data: PostKnowledgeDatasFindByKeywordWithoutPageBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListKnowledgeDataFoundResponse>;
export function postKnowledgeDatasFindByKeywordWithoutPage(
    data: PostKnowledgeDatasFindByKeywordWithoutPageBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListKnowledgeDataFoundResponse> {
    return request<PostKnowledgeDatasFindByKeywordWithoutPageBody, never, never>(
        '/knowledgeDatas/findByKeywordWithoutPage',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postKnowledgeDatasFindByKeywordWithoutPage',
        }
    );
}

export const meta = [
    {
        tags: ['knowledge-data-controller'],
        path: '/knowledgeDatas/findByKeywordWithoutPage',
        method: 'post',
    },
];
