
import React from 'react';
import Context, { UdeskContext } from './context';
import ToolkitLocalesManager from 'udesk-web-toolkits/src/udesk/locales/locales-manager';
import UdeskLocales from '../locales';
const SKIP_PROPERTY_NAMES = ["length", "name", "prototype", "defaultProps", "propTypes"];

function hookProps(componentClass) {
    if (typeof componentClass !== "function") {
        throw new Error(`The argument 'ComponentClass' of ${udeskify.name} must be a class or a function.`);
    }

    let finalNode = React.forwardRef(function forwardRef(props, ref) {
        if (Context.UdeskConsumer == null) {
            throw new Error("Forgot to call `Udesk.init` before application starts?");
        }
        forwardRef.displayName = componentClass.displayName || componentClass.name;

        return <Context.UdeskConsumer>{
            context => {
                if (context && !(context instanceof UdeskContext)) {
                    throw new Error(`The value passed to Udesk.react.context.UdeskConsumer component is invalid! It must be an instance of Udesk.react.context.UdeskContext.`);
                }

                if (ref == null) {
                    ref = React.createRef();
                }

                let localesManager = UdeskLocales;
                if (context && context.options && context.options.localesManager) {
                    if (!(context.options.localesManager instanceof ToolkitLocalesManager)) {
                        throw new Error(`Udesk.react.context.defaults.localesManager must be instance of LocalesManager class in udesk-web-toolkits.`);
                    }
                    localesManager = context.options.localesManager;
                }
                let locales = localesManager.getLocales(props.language || context.props.language) || localesManager.current;

                const ComponentClass = componentClass;
                return <ComponentClass {...((context || {}).props || {})} {...props} locales={locales} ref={ref} />;
            }
        }
        </Context.UdeskConsumer>;
    });

    finalNode.name = componentClass.displayName || componentClass.name;

    // 拷贝静态属性，如果有的话
    let copiedStaticProperties = Object.getOwnPropertyNames(componentClass).filter(n => !SKIP_PROPERTY_NAMES.includes(n));
    for (let name of copiedStaticProperties) {
        finalNode[name] = componentClass[name];
    }
    // 拷贝静态Symbol，如果有的话
    let copiedStaticSymbols = Object.getOwnPropertySymbols(componentClass);
    for (let symbol of copiedStaticSymbols) {
        finalNode[symbol] = componentClass[symbol];
    }

    return finalNode;
}

export { hookProps };
export default hookProps;