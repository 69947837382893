import { Icon, Input, Space, Tag, Tooltip } from 'udesk-ui';
import React, { useEffect, useRef, useState } from 'react';
import { inspect, isDefined } from 'src/util/core';
import UdeskLocales from 'UdeskLocales';

export default React.memo<any>((props) => {
    const {value: tags, onChange, isEdit} = props;
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef<any>(null);
    const editInputRef = useRef<any>(null);

    const value = isDefined(tags, tags, []);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);

    const handleClose = (removedTag: string) => {
        const newTags = value.filter(tag => tag !== removedTag);
        onChange(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && value.indexOf(inputValue) === -1) {
            onChange([...value, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...value];
        newTags[editInputIndex] = editInputValue;
        onChange(newTags);
        setEditInputIndex(-1);
        setInputValue('');
    };

    return (
        <Space wrap={true}>
            {value.map((tag, index) => {
                if (editInputIndex === index) {
                    return (
                        <Input
                            ref={editInputRef}
                            key={tag}
                            size="small"
                            className="tag-input"
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                        />
                    );
                }

                const isLongTag = tag.length > 20;

                const tagElem = (
                    <Tag
                        color="processing"
                        key={tag}
                        closable={isEdit}
                        onClose={() => handleClose(tag)}
                    >
                        <span
                            onDoubleClick={event => {
                                event.preventDefault();
                                inspect(isEdit && index > 0, () => {
                                    setEditInputIndex(index);
                                    setEditInputValue(tag);
                                });
                            }}
                            title={tag.length > 20 ? tag : undefined}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                    </Tag>
                );

                return inspect(isLongTag, () => {
                    return (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    );
                }, () => {
                    return tagElem;
                });
            })}

            {
                inspect(isEdit, () => inspect(inputVisible, () => {
                    return (
                        <Input
                            ref={inputRef}
                            type="text"
                            size="small"
                            className="tag-input"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputConfirm}
                            onPressEnter={handleInputConfirm}
                        />
                    );
                }, () => {
                    return (
                        <Tag 
                            icon={<Icon antdIcon={true} type='PlusOutlined' />} 
                            className="site-tag-plus"
                            onClick={showInput}
                        >
                            <span>{/* 话术要点 */}{UdeskLocales['current'].pages.semanticIntelligence.wordsMining.sellingPoint.components.pageRightTable.tagsEdit.keyPointsOfScript}</span>
                        </Tag>
                    );
                }))
            }
        </Space>
    );
});