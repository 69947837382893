import React, { useState, useMemo, useCallback } from 'react';
import { useMount, useRequest } from 'ahooks';
import { useEnum, useOptions } from './hooks';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import { Button, FormBuilder, Space, Popconfirm, Switch, Dropdown, Menu } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import ImportButton from './components/ImportButton';
import CreateModal from 'src/pages/tasks/manage/template/check-point/components/CreateModal';
import { getPointTemplateTask } from 'src/api/pointTemplate/task';
import { postPointTemplatePoints } from 'src/api/pointTemplate/points';
import { putInspectionPointsByIdStatusByStatus } from 'src/api/inspectionPoints/{id}/status/{status}';
import { deleteInspectionPointsById } from 'src/api/inspectionPoints/{id}';
import {
    InspectionTaskFoundResponse,
    PointTemplateQueryParam,
    InspectionPointFoundResponse,
} from 'src/api/types';

const Template = React.memo((props: any) => {
    // const { sdkOptions } = props;

    const locales = UdeskLocales['current'];

    const { RuleType, Status, DatasourceType } = useEnum();

    const initialValues: PointTemplateQueryParam = {
        pointQueryType: RuleType.All,
    };

    const [searchData, setSearchData] = useState<PointTemplateQueryParam>(initialValues);
    const [loading, setLoading] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<InspectionPointFoundResponse[]>([]);

    const sendRequest = (searchData, pageNum, pageSize) => {
        setLoading(true);
        postPointTemplatePoints(searchData, {
            params: { pageNum, pageSize },
        }).then(
            (resp) => {
                setLoading(false);
                const { data = [], paging } = resp;
                setPageNum(paging?.pageNum ?? 1);
                setPageSize(paging?.pageSize ?? 20);
                setTotal(paging?.total ?? 0);
                setDataSource(data);
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };

    useMount(() => {
        sendRequest(searchData, pageNum, pageSize);
    });

    const search = useCallback(
        (values: PointTemplateQueryParam) => {
            setSearchData(values);
            sendRequest(values, 1, pageSize);
        },
        [pageSize]
    );

    const [form] = FormBuilder.useForm();

    const { RuleTypeOptions, StatusOptions, DatasourceTypeOptions } = useOptions();

    const config: FormProps = {
        customComponents: {},
        initialValues: initialValues,
        fields: [
            {
                name: 'pointName',
                label: /* 规则名称 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                    .index.ruleName,
                type: 'Input',
                props: {
                    placeholder: locales.labels.pleaseEnter,
                },
            },
            {
                name: 'pointQueryType',
                label: /* 规则类型 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                    .index.ruleType,
                type: 'Select',
                props: {
                    style: {
                        width: 200,
                    },
                    placeholder:
                        /* 请选择 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                            .index.pleaseSelect,
                    allowClear: true,
                    options: RuleTypeOptions,
                },
            },
            {
                name: 'pointStatus',
                label: /* 规则状态 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                    .index.ruleStatus,
                type: 'Select',
                props: {
                    style: {
                        width: 200,
                    },
                    placeholder:
                        /* 请选择 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                            .index.pleaseSelect,
                    allowClear: true,
                    options: StatusOptions,
                },
            },
            {
                name: 'inspectDataSource',
                label: /* 数据源类型 */ UdeskLocales['current'].pages.intelligentAnalysis
                    .ruleTemplate.index.dataSourceType,
                type: 'Select',
                props: {
                    style: {
                        width: 200,
                    },
                    placeholder:
                        /* 请选择 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                            .index.pleaseSelect,
                    allowClear: true,
                    options: DatasourceTypeOptions,
                },
            },
        ],
        layout: 'inline',
        footerRender: () => (
            <Button onClick={() => form?.submit?.()} type="primary">
                {locales.labels.query}
            </Button>
        ),
        onFinish: search,
    };

    // const [columns, setColumns] = useState<any[]>([]);

    // const [filterConditions, setFilterConditions] =  useState({
    //     judgeStrategy: 1,
    //     conditionList: [],
    //     customJudgeLogic: ''
    // });

    // const filterProps = useMemo(() => {
    //     const onApply = ({ conditionList, customJudgeLogic, judgeStrategy, id, name }) => {
    //         setFilterConditions({
    //             judgeStrategy,
    //             conditionList,
    //             customJudgeLogic,
    //         });
    //     };
    //     return {
    //         ...filterConditions,
    //         onApply: onApply,
    //         // type: 9,
    //         // querryType: 'personaField',
    //     };
    // }, [filterConditions]);

    // const exportBtnProps = useMemo(() => {
    //     const onExport = (showFieldList) => {
    //     };
    //     return {
    //         onExport: onExport,
    //     };
    // }, []);
    const upCheckPointState = useCallback(
        (item, checked) => {
            putInspectionPointsByIdStatusByStatus({
                params: {
                    taskId: item.taskId,
                },
                segments: {
                    id: item.id,
                    status: checked ? Status.Open : Status.Close,
                },
            })
                .then(() => {
                    Udesk.ui.notify.success(locales.business.info.editSuccess);
                })
                .catch((reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    sendRequest(searchData, pageNum, pageSize);
                });
        },
        [
            Status.Close,
            Status.Open,
            locales.business.info.editSuccess,
            pageNum,
            pageSize,
            searchData,
        ]
    );

    const columns = useMemo<any[]>(() => {
        const deleteItem = async ({ taskId, id }) => {
            await deleteInspectionPointsById({
                params: {
                    taskId,
                },
                segments: {
                    id,
                },
            });
            search(searchData);
        };

        const goToEdit = ({ taskId, id, categoryId }) => {
            const routeOptions = {
                history: props.history,
                routeName: 'ruleTemplateEdit',
                pathParams: {
                    taskId,
                    categoryId,
                    id,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        };

        return [
            {
                key: 'name',
                dataIndex: 'name',
                title: locales.columns.tasks.manage.template.checkPoint.name,
                width: '10%',
                render(name, item) {
                    if (item.markRed) {
                        return <span style={{ color: 'red' }}>{name}</span>;
                    }
                    return name;
                },
            },
            {
                key: 'remark',
                dataIndex: 'remark',
                title: locales.columns.tasks.manage.template.checkPoint.remark,
                width: '15%',
            },
            {
                key: 'type',
                dataIndex: 'type',
                title: locales.columns.tasks.manage.template.checkPoint.type,
                width: '10%',
                render: (name, item, index) => {
                    return (
                        <div>
                            {/** 此处当存在 secondType 或者 intelligentCustomModelId，认为是智能模型类型 */}
                            {item.secondType || item.intelligentCustomModelId
                                ? Udesk.enums.scoreType.intelligentModel.name
                                : Udesk.enums.scoreType.getName(item.type)}
                        </div>
                    );
                },
            },
            {
                title: /* 数据源类型 */ UdeskLocales['current'].pages.intelligentAnalysis
                    .ruleTemplate.index.dataSourceType,
                dataIndex: 'inspectDataSource',
                key: 'inspectDataSource',
                width: '10%',
                render: (value, record) => {
                    switch (value) {
                        case DatasourceType.Voice:
                            return /* 语音通话 */ UdeskLocales['current'].pages.intelligentAnalysis
                                .ruleTemplate.index.voiceCall;

                        case DatasourceType.Text:
                            return /* 文本对话 */ UdeskLocales['current'].pages.intelligentAnalysis
                                .ruleTemplate.index.textDialogue;

                        case DatasourceType.EText:
                            return /* 电商文本会话 */ UdeskLocales['current'].pages
                                .intelligentAnalysis.ruleTemplate.index.ecommerceTextSession;

                        case DatasourceType.Ticket:
                            return /* 工单 */ UdeskLocales['current'].pages.intelligentAnalysis
                                .ruleTemplate.index.workOrder;

                        default:
                            return '-';
                    }
                },
            },
            {
                key: 'gradeType',
                dataIndex: 'gradeType',
                title: locales.columns.tasks.manage.template.checkPoint.gradeType,
                width: '10%',
                render: (name, item, index) => {
                    if (item.type === Udesk.enums.scoreType.machineLearning.id) {
                        return <div>{Udesk.enums.strategyTypes.getName(item.gradeType)}</div>;
                    } else {
                        return <div>{Udesk.enums.gradeTypes.getName(item.gradeType)}</div>;
                    }
                },
            },
            {
                key: 'conditionLogic',
                dataIndex: 'conditionLogic',
                title: locales.columns.tasks.manage.template.checkPoint.conditionLogic,
                width: '10%',
                textWrap: 'word-break',
                ellipsis: true,
                render: (name, item, index) => {
                    let context = getConditionLogic(item);
                    return (
                        <div
                            title={context}
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {context}
                        </div>
                    );
                },
            },
            {
                key: 'predeterminedScore',
                dataIndex: 'predeterminedScore',
                title: locales.columns.tasks.manage.template.checkPoint.predeterminedScore,
                width: '10%',
                render: (name, item, index) => {
                    if (item.ruleType === Udesk.enums.ruleTypes.flow.id) return '';

                    return (
                        <div>
                            {item.scoreType === Udesk.enums.calculateScoreTypes.add.id
                                ? locales.labels.plusSign
                                : null}
                            {item.scoreType === Udesk.enums.calculateScoreTypes.sub.id
                                ? locales.labels.minusSign
                                : null}
                            {item.predeterminedScore}
                        </div>
                    );
                },
            },
            {
                title: /* 状态 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                    .index.status,
                dataIndex: 'status',
                key: 'status',
                width: '10%',
                render: (value, record) => {
                    return (
                        <Switch
                            defaultChecked={value === Status.Open}
                            onChange={(checked) => upCheckPointState(record, checked)}
                        />
                    );
                },
            },
            {
                title: /* 操作 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                    .index.operation,
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                render: (name, item, index) => {
                    return (
                        <Space>
                            <Button onClick={() => goToEdit(item)} size="small" type="link">
                                {/* 编辑 */}
                                {
                                    UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate
                                        .index.edit
                                }
                            </Button>
                            <Popconfirm
                                title={locales.labels.deleteContent}
                                onConfirm={deleteItem.bind(null, item)}
                                okText={locales.labels.confirm}
                                cancelText={locales.labels.cancel}
                            >
                                <Button size="small" type="link" danger>
                                    {locales.labels.delete}
                                </Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            },
        ];
    }, [
        DatasourceType.EText,
        DatasourceType.Text,
        DatasourceType.Ticket,
        DatasourceType.Voice,
        Status.Open,
        locales.columns.tasks.manage.template.checkPoint.conditionLogic,
        locales.columns.tasks.manage.template.checkPoint.gradeType,
        locales.columns.tasks.manage.template.checkPoint.name,
        locales.columns.tasks.manage.template.checkPoint.predeterminedScore,
        locales.columns.tasks.manage.template.checkPoint.remark,
        locales.columns.tasks.manage.template.checkPoint.type,
        locales.labels.cancel,
        locales.labels.confirm,
        locales.labels.delete,
        locales.labels.deleteContent,
        locales.labels.minusSign,
        locales.labels.plusSign,
        props.history,
        search,
        searchData,
        upCheckPointState,
    ]);

    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
        };
    }, [columns, dataSource]);

    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPageNum(page);
                setPageSize(pageSize);
                sendRequest(searchData, page, pageSize);
            },
        };
    }, [pageNum, pageSize, total, searchData]);

    const [tasks, setTasks] = useState<InspectionTaskFoundResponse[]>([]);

    useRequest(getPointTemplateTask, {
        onSuccess: (res) => {
            setTasks(res.data || []);
        },
    });

    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [inspectDataSource, setInspectDataSource] = useState<number>(
        Udesk.enums.inspectDataSources.voiceCall.id
    );

    const onCreate = (inspectDataSource: number) => {
        setCreateModalVisible(true);
        setInspectDataSource(inspectDataSource);
    };

    const onCloseCreateModal = () => {
        setCreateModalVisible(false);
    };

    const goToCreate = (template, value) => {
        if (value === 'a') {
            let routeName = 'ruleTemplateNew';
            let queryParams = template && template.id ? { templateId: template.id } : null;
            let routeOptions = {
                history: props.history,
                routeName: routeName,
                pathParams: {
                    taskId: tasks.find((task) => task.inspectDataSource === inspectDataSource)?.id,
                    categoryId: tasks.find((task) => task.inspectDataSource === inspectDataSource)
                        ?.pointTemplateSecondCategoryId,
                },
                queryParams: queryParams,
            };
            if (props.route.name === 'ruleTemplateList') {
                routeOptions.routeName = 'ruleTemplateNew';
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        }
        if (value === 'b') {
            let routeName = 'ruleTemplateNew';
            let queryParams = template && template.id ? { moduleId: template.id } : null;
            let routeOptions = {
                history: props.history,
                routeName: routeName,
                pathParams: {
                    taskId: tasks.find((task) => task.inspectDataSource === inspectDataSource)?.id,
                    categoryId: tasks.find((task) => task.inspectDataSource === inspectDataSource)
                        ?.pointTemplateSecondCategoryId,
                },
                queryParams: queryParams,
            };
            if (props.route.name === 'ruleTemplateList') {
                routeOptions.routeName = 'ruleTemplateNew';
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        }
    };

    return (
        <Page
            pageBodyClassName="xxx-page-index"
            title={
                /* 规则模版 */ UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.index
                    .ruleTemplate
            }
            padding={true}
        >
            <CommonTablePage
                loading={loading}
                tableHeaderLeftRender={<FormBuilder {...config} form={form} />}
                mainButton={
                    <Space>
                        <Dropdown
                            overlay={
                                <Menu>
                                    {DatasourceTypeOptions.map((item) => {
                                        return (
                                            <Menu.Item onClick={() => onCreate(item.value)}>
                                                {item.label}
                                            </Menu.Item>
                                        );
                                    })}
                                </Menu>
                            }
                        >
                            <Button type="primary">{locales.labels.create}</Button>
                        </Dropdown>

                        <ImportButton sdkOptions={props.sdkOptions} />
                    </Space>
                }
                // showExport={true}
                // showColumsControl={true}
                // showFilter={true}
                // filter={filterProps}
                // exportBtn={exportBtnProps}
            >
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>

            <CreateModal
                visible={createModalVisible}
                onCancel={onCloseCreateModal}
                onTemplateClick={(item, templateValue) => goToCreate(item, templateValue)}
                dataSource={inspectDataSource}
            />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);

const getConditionLogic = (item) => {
    let conditionLogic = '';

    if (
        item &&
        (item.type === Udesk.enums.scoreType.aiScore.id ||
            item.type === Udesk.enums.scoreType.smartRules.id)
    ) {
        if (item.ruleList && item.ruleList.length > 0) {
            item.ruleList.forEach((rule, index) => {
                if (index === item.ruleList.length - 1) {
                    conditionLogic += rule.formula;
                } else {
                    conditionLogic += rule.formula + ' ; ';
                }
            });
        }
    } else if (item && item.type === Udesk.enums.scoreType.interactiveDetection.id) {
        if (item.ruleList && item.ruleList.length > 0) {
            item.ruleList.forEach((rule, index) => {
                if (rule.name) {
                    conditionLogic += rule.name;
                }
            });
        }
    }

    return conditionLogic;
};
