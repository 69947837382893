// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetSyncTaskInfoByTypeParam, BaseResponseSyncTaskInfoDTO } from '../../types';

/**
 * 查询
 *
 * @export
 * @tags 同步任务信息
 * @link [GET] /syncTaskInfo/{type}
 * @param type
 */
export function getSyncTaskInfoByType(
    options: ApiOptions<GetSyncTaskInfoByTypeParam, never> & {
        segments: GetSyncTaskInfoByTypeParam;
    }
): Promise<BaseResponseSyncTaskInfoDTO> {
    return request<never, GetSyncTaskInfoByTypeParam, never>('/syncTaskInfo/{type}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSyncTaskInfoByType',
    });
}

export const meta = [{ tags: ['同步任务信息'], path: '/syncTaskInfo/{type}', method: 'get' }];
