// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetSearchTemplateNoComplianceByTaskIdParam,
    BaseResponseListSearchTemplateDTO,
} from '../../../types';

/**
 * 本周违规筛选
 *
 * @export
 * @tags 筛选器
 * @link [GET] /search-template/noCompliance/{taskId}
 * @param taskId
 */
export function getSearchTemplateNoComplianceByTaskId(
    options: ApiOptions<GetSearchTemplateNoComplianceByTaskIdParam, never> & {
        segments: GetSearchTemplateNoComplianceByTaskIdParam;
    }
): Promise<BaseResponseListSearchTemplateDTO> {
    return request<never, GetSearchTemplateNoComplianceByTaskIdParam, never>(
        '/search-template/noCompliance/{taskId}',
        { ...options, method: 'get', __$requestCalleeName: 'getSearchTemplateNoComplianceByTaskId' }
    );
}

export const meta = [
    { tags: ['筛选器'], path: '/search-template/noCompliance/{taskId}', method: 'get' },
];
