// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostCallServiceSummaryFieldsGeneratePromptBody,
    BaseResponsestring,
} from '../../types';

/**
 * generatePrompt
 *
 * @export
 * @tags call-service-summary-field-controller
 * @link [POST] /callServiceSummaryFields/generatePrompt
 */
export function postCallServiceSummaryFieldsGeneratePrompt(
    data: PostCallServiceSummaryFieldsGeneratePromptBody
): Promise<BaseResponsestring>;
export function postCallServiceSummaryFieldsGeneratePrompt(
    data: PostCallServiceSummaryFieldsGeneratePromptBody,
    options: ApiOptions<never, never>
): Promise<BaseResponsestring>;
export function postCallServiceSummaryFieldsGeneratePrompt(
    data: PostCallServiceSummaryFieldsGeneratePromptBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponsestring> {
    return request<PostCallServiceSummaryFieldsGeneratePromptBody, never, never>(
        '/callServiceSummaryFields/generatePrompt',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postCallServiceSummaryFieldsGeneratePrompt',
        }
    );
}

export const meta = [
    {
        tags: ['call-service-summary-field-controller'],
        path: '/callServiceSummaryFields/generatePrompt',
        method: 'post',
    },
];
