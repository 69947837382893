import React from 'react';
import Udesk from 'Udesk';
import _size from 'lodash-es/size';
import _cloneDeep from 'lodash-es/cloneDeep';
import _compact from 'lodash-es/compact';
import _extend from 'lodash-es/extend';
import _isEqual from 'lodash-es/isEqual';
import _concat from 'lodash-es/concat';
import _includes from 'lodash-es/includes';
import _isEmpty from 'lodash-es/isEmpty';
import _toString from 'lodash-es/toString';
import { Button, notification } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

class GradeComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        paging: {
            pageNum: 1,
            pageSize: 10,
        },
        conditionList: [],
        conditionJson: {},
        inspectionData: [],
        judgeStrategy: {},
        panelNum: 1,
        panelList: [],
        panelChangeList: [],
        heightMemo: '',
        customJudgeLogic: [],
        errorPanelList: [],
        selectOptionList: [],
        customFilterList: [],
        customeFilterField: null,
        customeFilterFieldKey: [],
        customDataSourceList: [],
        judgeStrategyList: 1,
        customJudgeLogicList: '',
        processStatus: {
            processStatus: -1,
        },
        customId: null,
        customName: '',
        loading: false,
        dragStart: false,
        draggableContinaRef: React.createRef(),
    };
    static computes = {
        // _fields: [
        //     'privates.inspectionData',
        //     function ({ props, privates, locales }) {
        //         let { inspectionData } = privates;
        //         inspectionData.map(field => {
        //             return field;
        //         });
        //         return Udesk.business.fields.buildConditionFields({
        //             fields: inspectionData
        //         });
        //     }
        // ],
        // _fieldValueSettings: [
        //     'privates.inspectionData',
        //     function ({ props, privates, locales }) {
        //         return config.getFilterComponentSettings();
        //     }
        // ]
    };
    actions = {
        filterContidionChanged(keyIds, value) {
            let { conditionJson } = this.privates;
            conditionJson[keyIds] = value;
            this.actions.update();
        },
        changeJudgeStrategy(value, e) {
            this.privates.judgeStrategy[value] = e;
            let { panelList } = this.privates;

            panelList.map((item) => {
                if (item.keyIds === value) {
                    item.judgeStrategy = e;
                    if (e !== 3) {
                        item.customJudgeLogic = '';
                    }
                }
                return item;
            });
            this.actions.update();
        },
        customJudgeLogic(value, e) {
            let { panelList } = this.privates;
            panelList.map((item) => {
                if (item.keyIds === value) {
                    item.customJudgeLogic = e.target.value;
                }
                return item;
            });
            this.actions.update();
        },
        panelNodeAdd() {
            let { panelList, panelChangeList, judgeStrategy, customJudgeLogic } = this.privates;
            let { match } = this.props;
            if (_size(panelList) > 20) {
                Udesk.ui.notify.error(this.locales.components.pages.grade.list.max);
            } else {
                this.privates.panelNum++;
                var data = {
                    autoInCaseLibrary: 0,
                    caseLibraryId: this.privates.selectOptionList[0].id,
                    conditionJson: null,
                    customJudgeLogic: '',
                    defaultGrade: 0,
                    gradeName: this.locales.components.pages.grade.list.name,
                    judgeStrategy: 1,
                    keyIds: `panel${this.privates.panelNum}`,
                    orderIndex: this.privates.panelNum,
                    taskId: match.params.taskId,
                };
                let list = _cloneDeep(this.privates.panelList);
                delete list[list.length - 1];
                list = _compact(list);
                this.privates.panelList = [...list, data, panelList[panelList.length - 1]];
                this.privates.panelChangeList = [...panelChangeList, `panel${this.privates.panelNum}`];
                this.privates.judgeStrategy = _extend(judgeStrategy, { [`panel${this.privates.panelNum}`]: 1 });
                this.privates.customJudgeLogic = _extend(customJudgeLogic, { [`panel${this.privates.panelNum}`]: '' });
                this.actions.update();
            }
        },
        panelDel(value, e) {
            e.preventDefault();
            e.stopPropagation();
            let { panelList } = this.privates;
            let { sdkOptions, match } = this.props;
            let taskId = match.params.taskId;
            if (value.id) {
                let url = Udesk.business.apiPath.concatApiPath(
                    `inspectionGradeRules/${value.id}?taskId=${taskId}`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    Udesk.ajax.del(url).then(
                        (resp) => {
                            resolve(resp);
                            let list = [];
                            panelList.forEach((item) => {
                                if (!_isEqual(item, value)) {
                                    list = [...list, item];
                                }
                            });
                            this.privates.panelList = list;
                            this.actions.update();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            } else {
                let list = [];
                panelList.forEach((item) => {
                    if (!_isEqual(item, value)) {
                        list = [...list, item];
                    }
                });
                this.privates.panelList = list;
                this.actions.update();
            }
        },
        moveUpward(value, e) {
            e.preventDefault();
            e.stopPropagation();
            let { panelList } = this.privates;
            let list = _concat([], panelList);
            if (_includes(panelList, value) && panelList.indexOf(value) !== 0) {
                let items = list.splice(panelList.indexOf(value), 1);

                list.splice(panelList.indexOf(value) - 1, 0, items[0]);
            }
            this.privates.panelList = list;
            this.actions.update();
        },
        moveDown(value, e) {
            e.preventDefault();
            e.stopPropagation();
            let { panelList } = this.privates;
            let list = _concat([], panelList);
            if (_includes(panelList, value) && panelList.indexOf(value) !== panelList.length - 2) {
                let items = list.splice(panelList.indexOf(value), 1);

                list.splice(panelList.indexOf(value) + 1, 0, items[0]);
            }
            this.privates.panelList = list;
            this.actions.update();
        },
        onBeforeCapture(){
            this.privates.heightMemo = window.getComputedStyle(this.privates.draggableContinaRef.current).height;
        },
        onDragStart(){
            this.privates.dragStart = true;
            this.actions.update();
        },
        onDragEnd(result) {
            // dropped outside the list
            this.privates.dragStart = false;
            this.privates.heightMemo = '';

            this.actions.update();
            if (!result.destination) {
                return;
            }
            if(result.destination && result.destination.index === this.privates.panelList.length - 1){
                Udesk.ui.notify.error(this.locales.business.notifyMessage.defaultGradeMustAtLast);
                return;
            }
            
            let moveItem = this.privates.panelList[result.source.index];
            this.privates.panelList[result.source.index] = this.privates.panelList[result.destination.index];
            this.privates.panelList[result.destination.index] = moveItem;
            this.actions.update();
        },
        panelChange(value) {
            this.privates.heightMemo = '';
            this.privates.panelChangeList = value;
            this.actions.update();
        },
        onInputClick(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        InputChange(value, e) {
            let list = this.privates.panelList.map((item) => {
                if (item.keyIds === value.keyIds) {
                    return {
                        ...item,
                        gradeName: e.target.value,
                    };
                } else {
                    return item;
                }
            });
            this.privates.panelList = list;
            this.actions.update();
        },
        panelClose() {
            this.actions.reloadModel();
            this.privates.panelChangeList = [];
        },
        panelSave() {
            let { conditionJson, panelList } = this.privates;
            let { sdkOptions } = this.props;
            this.privates.loading = true;
            this.actions.update();
            for (let key in conditionJson) {
                if (!_isEmpty(conditionJson[key])) {
                    let lists = panelList.map((items) => {
                        if (_toString(items.keyIds) === key) {
                            items.conditionJson = JSON.stringify(conditionJson[key]);
                        }
                        return items;
                    });
                    this.privates.panelList = lists;
                    this.actions.update();
                } else {
                    let list = panelList.map((items) => {
                        if (_toString(items.keyIds) === key) {
                            items.conditionJson = null;
                        }
                        return items;
                    });
                    this.privates.panelList = list;
                    this.actions.update();
                }
            }
            let lists = _cloneDeep(panelList);
            lists.map((item, index) => {
                delete item.keyIds;
                item.orderIndex = _size(lists) - 1 - index;
                if(item.isFake){
                    delete item.id;
                }
                return item;
            });
            let url = Udesk.business.apiPath.concatApiPath(`inspectionGradeRules/save`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, lists).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.loading = false;
                        this.actions.update();
                        Udesk.ui.notify.success(this.locales.components.pages.grade.list.success);
                        this.actions.reloadModel();
                        this.privates.panelChangeList = [];
                    },
                    (reason) => {
                        this.privates.loading = false;
                        this.actions.update();
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        checkBoxChange(value, e) {
            let check;
            if (e.target.checked === true) {
                check = 1;
            } else {
                check = 0;
            }
            let list = this.privates.panelList.map((item) => {
                if (item.keyIds === value) {
                    return {
                        ...item,
                        autoInCaseLibrary: check,
                    };
                } else {
                    return item;
                }
            });
            this.privates.panelList = list;
            this.actions.update();
        },
        selectChange(value, e) {
            let list = this.privates.panelList.map((item) => {
                if (item.keyIds === value) {
                    return {
                        ...item,
                        caseLibraryId: e,
                    };
                } else {
                    return item;
                }
            });
            this.privates.panelList = list;
            this.actions.update();
        },
        handleConditionCancel() {
            this.privates.isFilterVisible = false;
            this.actions.update();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy, id, name) {
            let { editKey, panelList } = this.privates;
            this.privates.conditionJson[editKey] = conditionList;
            this.privates.judgeStrategy[editKey] = judgeStrategy;
            panelList.map((item) => {
                if (item.keyIds === editKey) {
                    item.judgeStrategy = judgeStrategy;
                    if (judgeStrategy !== 3) {
                        item.customJudgeLogic = '';
                    } else {
                        item.customJudgeLogic = customJudgeLogic;
                    }
                }
                return item;
            });
            this.privates.customJudgeLogic[editKey] = customJudgeLogic;
            this.privates.customId = null;
            this.privates.customName = null;
            if(id){
                this.privates.customId = id;
                this.privates.customName = name;
            }
            this.privates.isFilterVisible = false;
            this.actions.update();
        },
        onConditionFilterSaved(flag, data){
            if(data){
                this.privates.customId = data.id;
                this.privates.customName = data.name;
            }
        },
        customListSearch(value) {
            this.actions.getCustomFilterList(value);
        },
        getCustomFilterList(value) {
            let { sdkOptions } = this.props;
            let data = {
                type: this.privates.type,
            };
            if (value) {
                data.name = value;
            }
            let url = Udesk.business.apiPath.concatApiPath(`search-template/advanced`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.customFilterList = resp.data;
                        for (let i = 0; i < resp.data.length; i++) {
                            if (resp.data[i].status !== 0) {
                                this.privates.customeFilterField = resp.data[i];
                                break;
                            } else {
                                this.privates.customeFilterField = {};
                            }
                        }
                        this.actions.fieldsFilterChange(this.privates.customeFilterField);
                        this.privates.customeFilterFieldKey = !_isEmpty(this.privates.customeFilterField)
                            ? [`${this.privates.customeFilterField.id}`]
                            : [];
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg || this.locales.components.pages.components.listManage.getRecordsFailed
                        );
                        reject(reason);
                    }
                );
            });
        },
        editFilterOhter(key, list, radioValue, customInfo) {
            this.privates.isFilterVisible = true;
            this.privates.editKey = key;
            this.privates.judgeStrategyList = radioValue || 1;
            this.privates.customJudgeLogicList = customInfo || '';
            this.privates.conditionList = list || [];
            this.actions.update();
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.conditionList.forEach((item) => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach((items) => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label + ' ' + obj[item.operator] + ' ' + str.substring(0, str.length - 1),
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <p style={{ marginBottom: '3px' }}>
                        {index + 1}. {item}
                    </p>
                );
            });
        },
        close() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'tasksIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sendAjaxToNext() {

            const { props } = this;
            let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
            const openNotification = () => {
                const key = `open${Date.now()}`;
                const btn = (
                    <Button
                        type='primary'
                        prefixCls='udesk-qa-ui-btn'
                        size='small'
                        onClick={() => {
                            this.actions.close();
                            notification.close(key);
                        }}
                    >
                        {UdeskLocales.current.labels.ok}
                    </Button>
                );
                notification.open({
                    prefixCls: 'udesk-qa-ui-notification',
                    message: UdeskLocales.current.business.notifyMessage.illegalTitle,
                    description: UdeskLocales.current.business.notifyMessage.illegalMessage,
                    btn,
                    key,
                    onClose: this.actions.close,
                    duration: 0,
                    style: {
                        top: '60px',
                    },
                });
            };
            if (
                (hasFeature && !hasFeature('task:template:auto:view')) ||
                (hasFeature && !hasFeature('task:template:auto:edit'))
            ) {
                openNotification();
            } else {
                let url = Udesk.business.apiPath.concatApiPath(
                    `/inspectionTasks/processStatus/${this.privates.processStatus.id}`,
                    this.props.sdkOptions
                );
                Udesk.ajax.put(url, { processStatus: 4 }).then(
                    (resp) => {
                        let routeOptions = {
                            history: this.props.history,
                            routeName: 'tasksAutomaticAddCreateIndex',
                            pathParams: {
                                taskId: this.privates.processStatus.id,
                            },
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            }
        },
        handleNext() {
            let url2 = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/nextCheck/${this.privates.processStatus.id}`,
                this.props.sdkOptions
            );
            Udesk.ajax.post(url2, { processStatus: 3 }).then(
                (resp) => {
                    if (resp.data) {
                        this.actions.sendAjaxToNext();
                    } else {
                        Udesk.ui.notify.error(UdeskLocales.current.business.notifyMessage.skipMessage);
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        exitBoot() {
            let { match } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/processStatus/${match.params.taskId}`,
                this.props.sdkOptions
            );
            Udesk.ajax.put(url, { processStatus: -1 }).then(
                (resp) => {
                    const routeOptions = {
                        history: this.props.history,
                        routeName: 'gradeIndex',
                        pathParams: { taskId: this.privates.processStatus.id },
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
    };
}
function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter((item) => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map((item) => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}
export default GradeComponent;
