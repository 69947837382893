export default {
  lang: {
    export: 'تصدير',
    successfully: 'تم إنشاء مهمة التصدير بنجاح',
    pleaseJumpTo: 'الرجاء القفز إلى',
    uploadAndDownloadCenter: 'تحميل مركز التحميل',
    check: 'عرض',
    exportAll: 'تأكيد تصدير المحتوى الكامل ؟',
    total: 'المجموع',
    confirm: 'شريط من البيانات ، تأكيد التصدير ؟',
    error: 'عدد البيانات القابلة للتصدير هو 0 ، يرجى تغيير شروط التصفية والمحاولة مرة أخرى',
  },
};
