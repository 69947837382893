import React from "react";
import { Button, Icon, Input, Spin, Tooltip } from "udesk-ui";
import { useDebounce, useRequest } from "src/util/hook";
import './index.scss';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const { value, onChange, autoSize = { minRows: 4, maxRows: 8 }, ...textareaProps } = props;
    const {run, loading} = useRequest({
        onSuccess(resp) {
            onChange(resp.data);
        }
    });

    const clickHandle = useDebounce(() => {
        run('/review/intelligentPartner/node/wordsPolish', {words: value}, 'post');
    }, [value]);

    return (
        <Spin size="small" spinning={loading}>
            <div className="udesk-coach-web-ai-textarea">
                <Input.TextArea
                    value={value}
                    onChange={onChange}
                    autoSize={autoSize}
                    {...textareaProps}
                />
                <div>
                    <Tooltip mouseEnterDelay={1} color={'blue'} placement="left" title={/* 话术润色 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiTextArea.index.scriptPolishing}>
                        <Button onClick={clickHandle} icon={<Icon type="zhinengtuijian" />} type="link" />
                    </Tooltip>
                </div>
            </div>
        </Spin>
    );
});