import Udesk from 'Udesk';
import ListManageComponent from './component';
import { getSystemModule } from 'Udesk/system/subApp';

class uploadListRoute extends ListManageComponent {
    asyncModel(changedParams, topId) {
        let { sdkOptions } = this.props;
        let params = {
            pageNum: 1,
            pageSize: 20,
            moduleType: getSystemModule(),
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }
        if (topId) Object.assign(params, { clickId: topId });
        let url = Udesk.business.apiPath.concatApiPath(`impExp/5`, sdkOptions);

        return new Promise((resolve, reject) => {
            Udesk.ajax.get(url, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    }
    parseAsyncModel(asyncModel) {
        return asyncModel;
    }
}

export default uploadListRoute;
