import React, { useState } from 'react';
import Udesk from 'Udesk';
import UdeskPagination from 'Component/common/udesk-pagination';
import Locales from 'UdeskLocales';
import { Empty, Avatar, Button, Space } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { goFavoriteLink } from 'Component/pages/semantic-intelligence/words-mining/favorite-view';
import '../style.scss';

function Template(props) {
    const locales = Locales['current'];
    const {updateStudyCount} = props;
    const request = useRequest();
    const [page, setPage] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [listData, setListData] = useState<any[]>([]);
    const pageChanged = (pageNum, pageSize) => {
        setPage({
            ...page,
            current: pageNum,
            pageSize,
        });
    };

    const getShareData = (pageNum, pageSize, success?) => {
        request('/review/studyRemind', {
            pageNum,
            pageSize
        }).then(
            resp => {
                const callback = () => {
                    setListData(resp.data ?? []);
                    setPage({
                        current: resp.paging?.pageNum || page.current,
                        pageSize: resp.paging?.pageSize || page.pageSize,
                        total: resp.paging?.total || page.total,
                    });
                };
                if (success) {
                    success(callback);
                } else {
                    callback();
                }
            }
        );
    };

    const delHandle = (record) => {
        request(`/review/studyRemind/ignore/${record.id}`, {}, 'put').then(
            resp => {
                record.studyStatus = 3;
                setListData((list) => [...list]);
                updateStudyCount();
            }
        );
    };

    const openHandler = (record) => {
        request(`review/studyRemind/study/${record.id}`, {}, 'put').then(
            resp => {
                if (record.clusterId) {
                    goFavoriteLink(record.clusterId, Udesk.enums.applicationScenarios.sellingPoints.id);
                }
                if (record.groupId) {
                    goFavoriteLink(record.groupId);
                }
                setListData((list) => list.map(item => {
                    if (item.id === record.id) {
                        item.studyStatus = 2;
                    }
                    return item;
                }));
                updateStudyCount();
            }
        );
    };

    useLiveEffect((success) => {
        getShareData(page.current, page.pageSize, success);
    }, [
        page.current,
        page.pageSize
    ]);

    return (
        <React.Fragment>
            {listData.length ? (
                listData.map((item) => {
                    return (
                        <div key={item.id} className='page-qa-message-center-notice-item study'>
                            <div className={`comment-display-item`}>
                                <div className='avatar'>
                                    <Avatar size={40} src={''}>
                                        {item.content}
                                    </Avatar>
                                </div>
                                <div className='comment-display-item-body'>
                                    <div className='content' title={item.content}>
                                        {item.content}
                                    </div>
                                    
                                    <Space className='child-comments'>
                                        <Button 
                                            onClick={() => openHandler(item)} 
                                            size='small' 
                                            type='link'
                                        >
                                            { 
                                                item.studyStatus === 2 
                                                    ? /* 已学习 */locales.pages.gong.msgCenter.study.index.learned 
                                                    : locales.pages.gong.msgCenter.study.index.study 
                                            }
                                        </Button>
                                        <Button 
                                            disabled={item.studyStatus !== 1} 
                                            onClick={() => delHandle(item)} 
                                            size='small' 
                                            type='link' 
                                            danger
                                        >
                                            { 
                                                item.studyStatus === 3 
                                                    ? /* 已忽略 */locales.pages.gong.msgCenter.study.index.ignored 
                                                    : locales.pages.gong.msgCenter.study.index.ignore
                                            }
                                        </Button>
                                        <span className='create-time'>{item.createTime}</span>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={locales.labels.noValue} />
            )}
            {page.total ? <UdeskPagination style={{marginTop: 20}} {...page} showSizeChanger={false} onChange={pageChanged} /> : null}
        </React.Fragment>
    );
}
class Component extends React.Component {
    render() {
        return (
            <Template {...this.props} />
        );
    }
}

export default Udesk.react.udeskify(Component);
