import InputBaseClass from './input-base-class';

export default class DataSourceBaseClass extends InputBaseClass {
    
}

DataSourceBaseClass.defaultProps = Object.assign(InputBaseClass.defaultProps ,{
    dataSourceValueFeild: "valueField",
    dataSourceNameField: "nameField",
    dataSourceSearchField: "searchField",
    allClearField: "allowClear",

    DefaultValueField: "id",
    DefaultNameField: "name",
    DefaultSearchField: "name",
    DefaultOutputValueMode: "value",
});