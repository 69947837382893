export default {
  lang: {
    confirm: {
      title:
        'Confirmez-vous que vous souhaitez supprimer? Ne sera plus restauré après la suppression',
      okText: 'Supprimer',
      cancelText: 'Annulation',
    },
  },
};
