// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    BaseResponseReviewCallTestWrapper,
    PostReviewCallTestsBody,
    BaseResponseVoid,
} from '../../types';

/**
 * findReviewCall
 *
 * @export
 * @tags review-call-test-controller
 * @link [GET] /review/callTests
 */
export function getReviewCallTests(): Promise<BaseResponseReviewCallTestWrapper>;
export function getReviewCallTests(
    options: ApiOptions<never, never>
): Promise<BaseResponseReviewCallTestWrapper>;
export function getReviewCallTests(
    options?: ApiOptions<never, never>
): Promise<BaseResponseReviewCallTestWrapper> {
    return request<never, never, never>('/review/callTests', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallTests',
    });
}

/**
 * 保存测试对话
 *
 * @export
 * @tags 销售赋能规则测试
 * @link [POST] /review/callTests
 */
export function postReviewCallTests(data: PostReviewCallTestsBody): Promise<BaseResponseVoid>;
export function postReviewCallTests(
    data: PostReviewCallTestsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postReviewCallTests(
    data: PostReviewCallTestsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostReviewCallTestsBody, never, never>('/review/callTests', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewCallTests',
    });
}

/**
 * 清空测试对话
 *
 * @export
 * @tags 销售赋能规则测试
 * @link [DELETE] /review/callTests
 */
export function deleteReviewCallTests(): Promise<BaseResponseVoid>;
export function deleteReviewCallTests(options: ApiOptions<never, never>): Promise<BaseResponseVoid>;
export function deleteReviewCallTests(
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<never, never, never>('/review/callTests', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteReviewCallTests',
    });
}

export const meta = [
    { tags: ['review-call-test-controller'], path: '/review/callTests', method: 'get' },
    { tags: ['销售赋能规则测试'], path: '/review/callTests', method: 'post' },
    { tags: ['销售赋能规则测试'], path: '/review/callTests', method: 'delete' },
];
