import React, { useMemo } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Checkbox, Select } from 'udesk-ui';
// import styled from 'styled-components';
// import './style.scss';
import TaskGearOptionList from 'Component/pages/components/task-gear-option-list';
import TaskConditionDictionaries from 'Component/pages/components/task-condition-dictionaries';

const locales = Locales['current'];

// const Wrap = styled.div`
//     display: flex;
// `;

type AutomaticPointProps = {
    type: number;
    onGradeTypeChange?: (e: any) => void;
    onScoreTypeChange?: (e: any) => void;
    onPredeterminedScoreChange?: (e: any) => void;
    onNaCheckedChange?: (e: any) => void;
    onNaPreviousFormulaChange?: (e: any) => void;
    inspectionPoint: any;
    getGearOptionListOld: (gearOptionList: any) => void;
    conditionLogic: string;
    onConditionLogicChange?: (e: any) => void;
    relateConditionList: any[];
    onGenerateHighlightClick: () => void;
    onHighlightChange: (e: any) => void;
    onSave: (e: any) => void;
    inspectionConditions: any[];
};

export const AutomaticPoint = React.memo((props: AutomaticPointProps) => {
    const {
        type,
        onGradeTypeChange,
        onScoreTypeChange,
        onPredeterminedScoreChange,
        onNaCheckedChange,
        onNaPreviousFormulaChange,
        inspectionPoint,
        getGearOptionListOld,
        conditionLogic,
        onConditionLogicChange,
        relateConditionList,
        onGenerateHighlightClick,
        onHighlightChange,
        onSave,
        inspectionConditions,
    } = props;
    const gradeTypes = useMemo(() => {
        if (!type) return [];

        let res = Udesk.enums.gradeTypes.filter((item) => item !== null);

        if (type === Udesk.enums.scoreType.aiScore.id) {
            res = res.filter((item) => {
                return (
                    item.id !== Udesk.enums.gradeTypes.input.id &&
                    item.id !== Udesk.enums.gradeTypes.multipleFactors.id
                );
            });
        } else if (type === Udesk.enums.scoreType.interactiveDetection.id) {
            res = res.filter((item) => {
                return (
                    item.id === Udesk.enums.gradeTypes.yesOrNo.id ||
                    item.id === Udesk.enums.gradeTypes.multipleFactors.id
                );
            });
        } else if (type === Udesk.enums.scoreType.manualScore.id) {
            res = res.filter((item) => {
                return item.id !== Udesk.enums.gradeTypes.multipleFactors.id;
            });
        }

        return res;
    }, [type]);
    return (
        <React.Fragment>
            <form className="form-horizontal form-label-left">
                <div className="form-group">
                    <label
                        htmlFor="checkPoint_gradeType"
                        style={{ padding: '0 15px' }}
                        className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                    >
                        <span className="required">*</span>
                        {locales.pages.tasks.manage.template.checkPoint.detail.gradeType}
                    </label>
                    <div className="select-container col-lg-7 col-md-7 col-xs-12 col-12">
                        <Select
                            value={inspectionPoint.gradeType}
                            className="form-control form-control-score-type"
                            onChange={onGradeTypeChange}
                        >
                            {gradeTypes.map((item) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="form-group">
                    <label
                        style={{ padding: '0 15px' }}
                        className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                        htmlFor="checkPoint_deduction"
                    >
                        <span className="required">*</span>
                        {locales.pages.tasks.manage.template.checkPoint.detail.predeterminedScore}
                    </label>
                    <div className="col-lg-2 col-md-3 col-xs-12 col-12">
                        <Select
                            value={inspectionPoint.scoreType}
                            className="form-control form-control-score-type"
                            onChange={onScoreTypeChange}
                        >
                            {Udesk.enums.calculateScoreTypes.map((item) => {
                                return (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-12 col-12">
                        <input
                            type="number"
                            id="checkPoint_deduction"
                            className="form-control input-sm-wide deduction-input"
                            value={inspectionPoint.predeterminedScore}
                            onChange={onPredeterminedScoreChange}
                        />
                        <span>{locales.labels.minute}</span>
                    </div>
                </div>
                <div className="form-group">
                    <label
                        style={{ padding: '0 15px' }}
                        className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                        htmlFor="checkPoint_na"
                    >
                        {locales.pages.tasks.manage.template.checkPoint.detail.na}
                    </label>
                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                        <Checkbox
                            checked={Boolean(inspectionPoint.naChecked)}
                            onChange={onNaCheckedChange}
                        >
                            {locales.enums.tasks.naChecked.check}
                        </Checkbox>
                        {/* 智能评分 */}
                        {(type === Udesk.enums.scoreType.aiScore.id ||
                            inspectionPoint.gradeType ===
                                Udesk.enums.gradeTypes.multipleFactors.id) && (
                            <div>
                                <span className="check-point-na-previous-formula-title">
                                    {locales.pages.tasks.manage.template.checkPoint.detail.preRule}
                                </span>
                                <input
                                    type="text"
                                    className="form-control check-point-na-previous-formula"
                                    value={inspectionPoint.naPreviousFormula}
                                    onChange={onNaPreviousFormulaChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {inspectionPoint.gradeType === Udesk.enums.gradeTypes.level.id && (
                    <div className="form-group">
                        <label
                            style={{ padding: '0 15px' }}
                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                            htmlFor="checkPoint_na"
                        >
                            {locales.pages.tasks.manage.template.checkPoint.detail.rule}
                        </label>
                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                            <TaskGearOptionList
                                gearOptions={inspectionPoint.ruleList}
                                onChange={getGearOptionListOld}
                                scoreType={inspectionPoint.scoreType}
                                valueField="score"
                                evaluationField="evaluation"
                                formulaField="formula"
                                type={type}
                            />
                        </div>
                    </div>
                )}
                {/* 智能评分 */}
                {type === Udesk.enums.scoreType.aiScore.id &&
                    inspectionPoint.gradeType === Udesk.enums.gradeTypes.yesOrNo.id && (
                    <div className="form-group">
                        <label
                            style={{ padding: '0 15px' }}
                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                            htmlFor="checkPoint_rule"
                        >
                            <span className="required">*</span>
                            {locales.pages.tasks.manage.template.checkPoint.detail.rule}
                        </label>
                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                            <textarea
                                id="checkPoint_rule"
                                className="form-control rule-input"
                                maxLength={255}
                                rows={5}
                                value={conditionLogic}
                                onChange={onConditionLogicChange}
                            />
                        </div>
                    </div>
                )}
                {/* 智能评分 */}
                {type === Udesk.enums.scoreType.aiScore.id && (
                    <div className="form-group">
                        <label
                            style={{ padding: '0 15px' }}
                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                            htmlFor="checkPoint_highlighted"
                        >
                            <span className="required">*</span>
                            {locales.pages.tasks.manage.template.checkPoint.detail.highlighted}
                        </label>
                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                            <button
                                type="button"
                                className="btn btn-xs btn-default"
                                onClick={onGenerateHighlightClick}
                            >
                                {
                                    locales.pages.tasks.manage.template.checkPoint.detail
                                        .analysisHighlighted
                                }
                            </button>
                            {relateConditionList.map((item, index) => (
                                <label key={item.idCode} htmlFor={`${index}`}>
                                    <input
                                        id={`${index}`}
                                        type="checkbox"
                                        checked={item.highlight}
                                        className="high-light-check-box"
                                        onChange={onHighlightChange.bind(null, item, 'highlight')}
                                    />
                                    {item.idCode}
                                </label>
                            ))}
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                        <button type="button" className="btn btn-success" onClick={onSave}>
                            {locales.labels.save}
                        </button>
                    </div>
                </div>
            </form>
            <TaskConditionDictionaries items={inspectionConditions} />
        </React.Fragment>
    );
});

// 原代码
// <form className="form-horizontal form-label-left">
//     <div className="form-group">
//         <label
//             htmlFor="checkPoint_gradeType"
//             style={{ padding: "0 15px" }}
//             className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
//             <span className="required">*</span>
//             {locales.pages.tasks.manage.template.checkPoint.detail.gradeType}
//         </label>
//         <div className="select-container col-lg-7 col-md-7 col-xs-12 col-12">
//             <Select
//                 value={privates.model.inspectionPoint.gradeType}
//                 className="form-control form-control-score-type"
//                 onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'gradeType')}>
//                 <For each="item" index="index" of={privates.computes.gradeTypes}>
//                     <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
//                 </For>
//             </Select>
//         </div>
//     </div>
//     <div className="form-group">
//         <label
//             style={{ padding: "0 15px" }}
//             className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
//             htmlFor="checkPoint_deduction">
//             <span className="required">*</span>
//             {locales.pages.tasks.manage.template.checkPoint.detail.predeterminedScore}
//         </label>
//         <div className="col-lg-2 col-md-3 col-xs-12 col-12">
//             <Select
//                 value={privates.model.inspectionPoint.scoreType}
//                 className="form-control form-control-score-type"
//                 onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'scoreType')}>
//                 <For each="item" index="index" of={Udesk.enums.calculateScoreTypes}>
//                     <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
//                 </For>
//             </Select>
//         </div>
//         <div className="col-lg-3 col-md-4 col-xs-12 col-12">
//             <input
//                 type="number"
//                 id="checkPoint_deduction"
//                 className="form-control input-sm-wide deduction-input"
//                 value={privates.model.inspectionPoint.predeterminedScore}
//                 onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'predeterminedScore')}
//             />
//             <span>{locales.labels.minute}</span>
//         </div>
//     </div>
//     <div className="form-group">
//         <label
//             style={{ padding: "0 15px" }}
//             className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
//             htmlFor="checkPoint_na">
//             {locales.pages.tasks.manage.template.checkPoint.detail.na}
//         </label>
//         <div className="col-lg-7 col-md-7 col-xs-12 col-12">
//             <Checkbox checked={privates.model.inspectionPoint.naChecked} onChange={actions.changePointNaChecked}>
//                 {locales.enums.tasks.naChecked.check}
//             </Checkbox>
//             {/* 智能评分 */}
//             <If condition={privates.type === Udesk.enums.scoreType.aiScore.id ||
//                 privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.multipleFactors.id}>
//                 <div>
//                     <span className="check-point-na-previous-formula-title">
//                         {locales.pages.tasks.manage.template.checkPoint.detail.preRule}
//                     </span>
//                     <input
//                         type="text"
//                         className="form-control check-point-na-previous-formula"
//                         value={privates.model.inspectionPoint.naPreviousFormula}
//                         onChange={actions.onValueChanged.params(privates.model.inspectionPoint, 'naPreviousFormula')}
//                     />
//                 </div>
//             </If>
//         </div>
//     </div>
//     <If condition={privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.level.id}>
//         <div className="form-group">
//             <label
//                 style={{ padding: "0 15px" }}
//                 className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
//                 htmlFor="checkPoint_na">
//                 {locales.pages.tasks.manage.template.checkPoint.detail.rule}
//             </label>
//             <div className="col-lg-7 col-md-7 col-xs-12 col-12">
//                 <TaskGearOptionList
//                     gearOptions={privates.model.inspectionPoint.ruleList}
//                     onChange={actions.getGearOptionListOld}
//                     scoreType={privates.model.inspectionPoint.scoreType}
//                     valueField="score"
//                     evaluationField="evaluation"
//                     formulaField="formula"
//                     type={privates.type}
//                 />
//             </div>
//         </div>
//     </If>
//     {/* 智能评分 */}
//     <If condition={privates.type === Udesk.enums.scoreType.aiScore.id &&
//         privates.model.inspectionPoint.gradeType === Udesk.enums.gradeTypes.yesOrNo.id}>
//         <div className="form-group">
//             <label
//                 style={{ padding: "0 15px" }}
//                 className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
//                 htmlFor="checkPoint_rule">
//                 <span className="required">*</span>
//                 {locales.pages.tasks.manage.template.checkPoint.detail.rule}
//             </label>
//             <div className="col-lg-7 col-md-7 col-xs-12 col-12">
//                 <textarea
//                     id="checkPoint_rule"
//                     className="form-control rule-input"
//                     maxLength="255"
//                     rows="5"
//                     value={state.conditionLogic}
//                     onChange={actions.onValueChanged.params(state, 'conditionLogic')}
//                 />
//             </div>
//         </div>
//     </If>
//     {/* 智能评分 */}
//     <If condition={privates.type === Udesk.enums.scoreType.aiScore.id}>
//         <div className="form-group">
//             <label
//                 style={{ padding: "0 15px" }}
//                 className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
//                 htmlFor="checkPoint_highlighted">
//                 <span className="required">*</span>
//                 {locales.pages.tasks.manage.template.checkPoint.detail.highlighted}
//             </label>
//             <div className="col-lg-7 col-md-7 col-xs-12 col-12">
//                 <button
//                     type="button"
//                     className="btn btn-xs btn-default"
//                     onClick={actions.generateHighlight}>
//                     {locales.pages.tasks.manage.template.checkPoint.detail.analysisHighlighted}
//                 </button>
//                 <For each="item" index="index" of={state.relateConditionList}>
//                     <label key={item.idCode} htmlFor={index}>
//                         <input
//                             id={index}
//                             type="checkbox"
//                             checked={item.highlight}
//                             className="high-light-check-box"
//                             onChange={actions.onValueChanged.params(item, 'highlight')}
//                         />
//                         {item.idCode}
//                     </label>
//                 </For>
//             </div>
//         </div>
//     </If>
//     <div className="form-group">
//         <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
//             <button type="button" className="btn btn-success" onClick={actions.save}>
//                 {locales.labels.save}
//             </button>
//         </div>
//     </div>
// </form>
// <TaskConditionDictionaries items={privates.model.inspectionConditions} />
