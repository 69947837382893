import React from 'react';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import LayoutMenu from 'Component/common/layout-menu';
import { Redirect } from 'react-router-dom';

class TasksManageCreateEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: this.props.match.params,
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }
    privates = {
        processStatus: 1,
    };
    render() {
        let { route, location, sdkOptions, match } = this.props;

        let { locales, privates } = this;

        // 根据当前用户权限，组装菜单列表
        const task = sdkOptions.props.task;

        let taskId = privates.model && privates.model.taskId ? privates.model.taskId : 0;
        let navLists = [];
        let group = {
            text: locales.components.tasks.subMenu.qualityCheckTemplate.text,
            children: [
                {
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.basicInfo.text,
                    pathName: 'tasksBasicInfoCreateIndex',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('template/new');
                    },
                },
                {
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.checkPoint.text,
                    pathName: 'tasksCheckPointCreateEntry',
                    pathParams: { taskId },
                    disabled: privates.processStatus < 2,
                    isActive: (match, location) => {
                        return location.pathname.includes('/check-point');
                    },
                },
                {
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.grade.text,
                    pathName: 'gradeCreateIndex',
                    pathParams: { taskId },
                    disabled: privates.processStatus < 3,
                    isActive: (match, location) => {
                        return location.pathname.includes('/grade');
                    },
                },
                {
                    text: locales.components.tasks.subMenu.qualityCheckTemplate.automaticAdd.text,
                    pathName: 'tasksAutomaticAddCreateIndex',
                    pathParams: { taskId },
                    disabled: privates.processStatus < 4,
                    isActive: (match, location) => {
                        return location.pathname.includes('/automatic-add');
                    },
                },
            ],
        };

        if (
            task &&
            !Udesk.business.task.inspectDataSourcesIncludedIn(
                ['textDialogue', 'realTimeVoiceCall', 'realTimeTextDialogue', 'taobao', 'ticket'],
                task.inspectDataSource
            )
        ) {
            group.children.push({
                text: locales.components.tasks.subMenu.qualityCheckTemplate.roleConfig.text,
                pathName: 'tasksRoleConfigCreateIndex',
                pathParams: { taskId },
                disabled: privates.processStatus < 5,
                isActive: (match, location) => {
                    return location.pathname.includes('/role-config');
                },
            });
        }
        //非关联任务
        if (task && !task.related) {
            group.children.push({
                text: locales.components.tasks.subMenu.qualityCheckTemplate.manualAdd.text,
                pathName: 'tasksManualAddCreateIndex',
                pathParams: { taskId },
                disabled: privates.processStatus < 6,
                isActive: (match, location) => {
                    return location.pathname.includes('/manual-add');
                },
            });
        }

        navLists.push(group);

        if (this.isRedirect(route, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].children[0].pathName,
                pathParams: match.params,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className='tasks-manage-entry'>
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={locales.components.pages.menuTitleList.tasksManageTitle}
                    />
                </div>
            );
        }
    }

    model() {
        const { props } = this;
        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );

        if (params.taskId !== '0') {
            // 任务的数据源
            let taskPromise = new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + params.taskId, props.sdkOptions);
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                        );
                        reject(reason);
                    }
                );
            });

            // 任务权限数据源
            let taskMenuPromise = new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath(
                    '/inspectionTasks/menu/' + params.taskId,
                    props.sdkOptions
                );
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                                )
                        );
                        reject(reason);
                    }
                );
            });

            let associateFields = new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath('/field/task/' + params.taskId, props.sdkOptions);
                Udesk.ajax.get(url, { type: 'associate' }).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.screeningCondition
                                )
                        );
                        reject(reason);
                    }
                );
            });

            // 任务状态
            let processStatusPromise = new Promise((resolve, reject) => {
                let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + params.taskId, props.sdkOptions);
                Udesk.ajax.get(url, { type: 'associate' }).then(
                    (resp) => {
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                Udesk.utils.string.format(
                                    UdeskLocales.current.business.info.loadError,
                                    UdeskLocales.current.pages.tasks.manage.screeningCondition
                                )
                        );
                        reject(reason);
                    }
                );
            });

            return {
                taskId: params.taskId,
                associateFields: associateFields,
                task: taskPromise,
                taskMenu: taskMenuPromise,
                processStatus: processStatusPromise,
            };
        }

        // 新创建任务没有相关数据
        return {};
    }
    onModelResolved(model) {
        if (model.task) {
            let task = model.task;
            let features = model.taskMenu;
            let featureCodeMappings = {};
            if (features) {
                for (let i = 0; i < features.length; i++) {
                    let feature = features[i];
                    featureCodeMappings[feature.permission] = true;
                }
            }
            if (!Object.prototype.hasOwnProperty.call(task, '_hasFeature')) {
                Object.defineProperty(task, '_hasFeature', {
                    value: function (featureCode) {
                        return Object.prototype.hasOwnProperty.call(featureCodeMappings, featureCode);
                    },
                    enumerable: false,
                    configurable: false,
                    writable: false,
                });
            }

            this.privates.processStatus = model.processStatus.processStatus;
            this.props.sdkOptions.props.biTaskId = parseInt(model.taskId, 10);
            this.props.sdkOptions.props.task = task;
            this.props.sdkOptions.props.basicInfoFields = model.associateFields;
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.actions.reloadModel();
        }
    }
    componentWillUnmount() {
        // 清理掉相关数据，否则会污染其他页面
        this.props.sdkOptions.props.biTaskId = null;
        this.props.sdkOptions.props.task = null;
        this.props.sdkOptions.props.basicInfoFields = null;
    }
}

export default Udesk.react.udeskify(TasksManageCreateEntry);
