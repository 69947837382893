// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListSmartTagIgnoreFoundResponse } from '../../types';

/**
 * findAll
 *
 * @export
 * @tags smart-tag-ignore-controller
 * @link [GET] /smartTagIgnores/all
 */
export function getSmartTagIgnoresAll(): Promise<BaseResponseListSmartTagIgnoreFoundResponse>;
export function getSmartTagIgnoresAll(
    options: ApiOptions<never, never>
): Promise<BaseResponseListSmartTagIgnoreFoundResponse>;
export function getSmartTagIgnoresAll(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListSmartTagIgnoreFoundResponse> {
    return request<never, never, never>('/smartTagIgnores/all', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSmartTagIgnoresAll',
    });
}

export const meta = [
    { tags: ['smart-tag-ignore-controller'], path: '/smartTagIgnores/all', method: 'get' },
];
