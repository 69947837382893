// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    PostReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowIdParam,
    PostReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowIdBody,
    BaseResponsestring,
} from '../../../../../../types';

/**
 * ppt生成单页演讲话术
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [POST] /review/intelligentPartner/node/pptConfig/singleAiWords/{flowId}
 * @param flowId
 */
export function postReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowId(
    data: PostReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowIdBody,
    options: ApiOptions<
        PostReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowIdParam,
        never
    > & { segments: PostReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowIdParam }
): Promise<BaseResponsestring> {
    return request<
        PostReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowIdBody,
        PostReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowIdParam,
        never
    >('/review/intelligentPartner/node/pptConfig/singleAiWords/{flowId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewIntelligentPartnerNodePptConfigSingleAiWordsByFlowId',
    });
}

export const meta = [
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/singleAiWords/{flowId}',
        method: 'post',
    },
];
