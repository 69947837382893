import PropTypes from "prop-types";
import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import React from "react";
import { putInspectionTasksByIdCollectByCollect } from "src/api/inspectionTasks/{id}/collect/{collect}";
export default class TaskCardComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        task: null,
        // charts: null,
        editCallback: null,
        // saveAsCallback: null,
        fulfilled: false,
    }; //
    //#endregion

    //#region propTypes
    static propTypes = {
        // charts: PropTypes.array,
        task: PropTypes.object,
        editCallback: PropTypes.func,
        // saveAsCallback: PropTypes.func,
        fulfilled: PropTypes.bool,
    }; //
    //#endregion

    state = {
        taskStatus: false,
    };
    privates = {
        columnHeight: [],
        loading: false,
        hadRendered: false,
        collectType: 0,
    };

    //#region actions
    actions = {
        getTaskNameFirstWord(name) {
            return name.slice(0, 1);
        },

        upTaskState(state) {
            this.setState({ taskStatus: state }, () => {
                let checked = state ? 1 : 0;
                let url = Udesk.business.apiPath.concatApiPath(
                    "/inspectionTasks/" + this.props.task.id + "/status/" + checked,
                    this.props.sdkOptions
                );
                Udesk.ajax.put(url).then(
                    (resp, textStatus, jqXHR) => {
                        Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.notifyMessage.submitFailedFormat, ""));
                        this.setState({ taskStatus: !state });
                    }
                );
            });
        },
        openSaveAsModel() {
            this.trigger("openSaveAsModel", this.props.task);
        },
        // saveAs() {
        //     clearTimeout(this.privates.timer);
        //     this.privates.loading = true;
        //     this.actions.update();
        //     this.trigger("saveAsCallback", this.props.task);
        //     this.privates.timer = setTimeout(() => {
        //         this.privates.loading = false;
        //         this.actions.update();
        //     }, 1000);
        // },
        collect(item) {
            putInspectionTasksByIdCollectByCollect({
                segments: {
                    collect: this.privates.collectType ? 0 : 1,
                    id: item.id,
                },
                successMsg: this.privates.collectType ? undefined : UdeskLocales.current.business.info.collectSuccess
            }).then(() => {
                this.privates.collectType = this.privates.collectType ? 0 : 1;
                this.actions.update();
                this.props.onCollectTypeChange?.();
            });
        },
        // editTask() {
        //     this.trigger("editCallback", this.props.task);
        // },

        turnToTaskManage() {
            const { task, history, isWechatQa, isCollection } = this.props;
            const routeOptions = { history, pathParams: { taskId: task.id } };
            if(isWechatQa){
                routeOptions.routeName = 'wechatQaBaseInfo';
            }else if(isCollection){
                routeOptions.routeName = 'informationCollectionIndex';
            }else{
                if (task.processStatus === -1 || task.processStatus == null) {
                    routeOptions.routeName = 'tasksManageEntry';
                }
                if (task.processStatus === 2) {
                    routeOptions.routeName = 'tasksCheckPointCreateIndex';
                }
                if (task.processStatus === 3) {
                    routeOptions.routeName = 'gradeCreateIndex';
                }
                if (task.processStatus === 4) {
                    routeOptions.routeName = 'tasksAutomaticAddCreateIndex';
                }
                if (task.processStatus === 5) {
                    routeOptions.routeName = 'tasksRoleConfigCreateIndex';
                }
                if (task.processStatus === 6) {
                    routeOptions.routeName = 'tasksManualAddCreateIndex';
                }    
            }
            if (routeOptions.routeName) {
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        turnToQaList() {
            const { task, history, isWechatQa, isCollection } = this.props;
            const routeOptions = { history, pathParams: { taskId: task.id } };
            if(isWechatQa){
                routeOptions.routeName = 'wechatQualityInspectionListIndex';
            }else if(isCollection){
                routeOptions.routeName = 'informationCollectListIndex';
            }else{
                if (task.processStatus === -1 || task.processStatus == null) {
                    routeOptions.routeName = 'qualityInspectionListIndex';
                }   
            }
            if (routeOptions.routeName) {
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
        turnToCheckList() {
            const { task, history, isWechatQa, isCollection } = this.props;
            const routeOptions = { history, pathParams: { taskId: task.id } };
            if(!isWechatQa && !isCollection){
                if (task.processStatus === -1 || task.processStatus == null) {
                    routeOptions.routeName = 'spotCheckListIndex';
                }   
            }
            if (routeOptions.routeName) {
                Udesk.ui.routing.transitionTo(routeOptions);
            }
        },
    };
    //#endregion


    init() {
        let { state, props } = this;
        state.taskStatus = props.task.status === 1;
    }
    componentDidUpdate(prevProps) {
        let { props } = this;
        // props.task.
        let chart = [];
        if ('chart' in props.task && prevProps !== props) {
            chart = props.task.chart.barCharts ? [...props.task.chart.barCharts] : [];
            let max = Math.max(...chart);
            chart.forEach((item, index) => {
                this.privates.columnHeight[index] = (item / max) * 48;
            });
            this.actions.update();
        }
    }
    componentDidMount() {
        const { props } = this;
        this.privates.collectType = props.task.collectType;
        this.actions.update();
    }
}
