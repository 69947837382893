// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostReplayCommentsShowAllQuery,
    PostReplayCommentsShowAllBody,
    BaseResponseListReplayCommentFoundResponse,
} from '../../types';

/**
 * findAll
 *
 * @export
 * @tags 会话评论
 * @link [POST] /replayComments/showAll
 */
export function postReplayCommentsShowAll(
    data: PostReplayCommentsShowAllBody
): Promise<BaseResponseListReplayCommentFoundResponse>;
export function postReplayCommentsShowAll(
    data: PostReplayCommentsShowAllBody,
    options: ApiOptions<never, PostReplayCommentsShowAllQuery>
): Promise<BaseResponseListReplayCommentFoundResponse>;
export function postReplayCommentsShowAll(
    data: PostReplayCommentsShowAllBody,
    options?: ApiOptions<never, PostReplayCommentsShowAllQuery>
): Promise<BaseResponseListReplayCommentFoundResponse> {
    return request<PostReplayCommentsShowAllBody, never, PostReplayCommentsShowAllQuery>(
        '/replayComments/showAll',
        { ...options, data, method: 'post', __$requestCalleeName: 'postReplayCommentsShowAll' }
    );
}

export const meta = [{ tags: ['会话评论'], path: '/replayComments/showAll', method: 'post' }];
