// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    BaseResponseSysConfigResponse,
    PutCompanysSysConfigBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 获取系统设置
 *
 * @export
 * @tags 租户接口
 * @link [GET] /companys/sys/config
 */
export function getCompanysSysConfig(): Promise<BaseResponseSysConfigResponse>;
export function getCompanysSysConfig(
    options: ApiOptions<never, never>
): Promise<BaseResponseSysConfigResponse>;
export function getCompanysSysConfig(
    options?: ApiOptions<never, never>
): Promise<BaseResponseSysConfigResponse> {
    return request<never, never, never>('/companys/sys/config', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCompanysSysConfig',
    });
}

/**
 * 更新系统设置接口
 *
 * @export
 * @tags 租户接口
 * @link [PUT] /companys/sys/config
 */
export function putCompanysSysConfig(data: PutCompanysSysConfigBody): Promise<BaseResponseVoid>;
export function putCompanysSysConfig(
    data: PutCompanysSysConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putCompanysSysConfig(
    data: PutCompanysSysConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutCompanysSysConfigBody, never, never>('/companys/sys/config', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putCompanysSysConfig',
    });
}

export const meta = [
    { tags: ['租户接口'], path: '/companys/sys/config', method: 'get' },
    { tags: ['租户接口'], path: '/companys/sys/config', method: 'put' },
];
