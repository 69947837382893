import React from 'react';
import CustomFilter from './customerFilter';
import { Button, Space } from 'udesk-ui';
import { linkTo } from 'src/util/link';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { hasFeature } from 'src/util/permit';
import { EllipsisSpan } from 'src/util/tag';
import '../index.scss';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        history,
        locales,
        total,
        selectedRowKey,
        subjectName,
        judgeStrategy,
        conditionList,
        customJudgeLogic,
        setJudgeStrategy,
        setConditionList,
        setCustomJudgeLogic,
        onFilterComplete,
    }= props;

    const linkFavorite = () => {
        linkTo(history, getCurrentRouteName('dissentFavoriteWordsMining'));
    };

    return ( 
        <div className='column-header'>
            <Space>
                <div className='column-header-title'>
                    <EllipsisSpan>{subjectName ? `”${subjectName}“` : ''}</EllipsisSpan>
                    <span>{/* 推荐话术 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.columnHeader.recommendedScripts}</span>
                    <span>{`${/* 共 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.columnHeader.common}${total}${/* 条 */locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.columnHeader.strip}`}</span>
                </div>
                {
                    selectedRowKey ? (
                        <CustomFilter {...{
                            history,
                            locales,
                            selectedRowKey,
                            judgeStrategy,
                            conditionList,
                            customJudgeLogic,
                            setJudgeStrategy,
                            setConditionList,
                            setCustomJudgeLogic,
                            onFilterComplete,
                        }}/>
                    ) : null
                }
            </Space>
            {
                hasFeature("semantic:WordsFavorite:root", () => <Button onClick={linkFavorite} type='primary'>{/* 话术库 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.columnHeader.scriptLibrary}</Button>)
            }
        </div>
    );
});
