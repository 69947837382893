import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { isQaSubApp } from 'Udesk/system/subApp';

export default class AdminUserGroupsIndexComponent extends React.Component {
    //#region state
    state = {
        columns: [],
    };
    //#endregion

    //#region actions
    actions = {
        pageChanged(pageNum, pageSize) {
            this.actions.reloadModel({ pageNum, pageSize });
        },
        delete(id) {
            let onConfirm = () => {
                let url = Udesk.business.apiPath.concatApiPath('/userGroup/' + id, this.props.sdkOptions);
                Udesk.ajax.del(url).then(
                    resp => {
                        this.actions.reloadModel();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            };

            Udesk.ui.alerts.confirm({
                content: UdeskLocales.current.labels.deleteContent,
                onOk: onConfirm
            });
        }
    };
    //#endregion

    //#region Life Cycle
    componentDidMount() {
        getColumns(this);
    }
    //#endregion
}

function getColumns(that) {
    const {
        actions,
        locales
    } = that;
    const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
    const columns = [
        {
            name: 'id'
        },
        {
            name: 'name'
        },
        {
            name: 'userCount'
        },
        {
            name: 'actions',
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return (
                    <div>
                        <If condition={hasFeature && hasFeature('sys:group:view')}>
                            <NavLink
                                to={`/${isQaSubApp() ? 'site' : 'conversation-analysis'}/admin/user-groups/view/${item.id}`}
                                style={{marginRight: '5px'}}
                                className={'action-btn'}>
                                {locales.labels.view}
                            </NavLink>
                        </If>
                        <If condition={hasFeature && hasFeature('sys:group:edit')}>
                            <NavLink
                                to={`/${isQaSubApp() ? 'site' : 'conversation-analysis'}/admin/user-groups/edit/${item.id}`}
                                style={{marginRight: '5px'}}
                                className={'action-btn'}>
                                {locales.labels.edit}
                            </NavLink>
                        </If>
                        <If condition={hasFeature && hasFeature('sys:group:delete')}>
                            <button
                                className="action-btn delete-btn"
                                onClick={actions.delete.params(item.id)}>
                                {locales.labels.delete}
                            </button>
                        </If>
                    </div>
                );
            }
        }
    ];
    UdeskLocales.translate(columns, 'name', 'caption', UdeskLocales.current.columns.admin.userGroups);
    that.setState({
        columns
    });
}