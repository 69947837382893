import React from 'react';
import SemanticMarkRoute from './route';
import { Form, Input } from '@alifd/next';
import './style.scss';

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        fixedSpan: 3
    },
    wrapperCol: {
        span: 18
    }
};

export default class SemanticMarkTemplate extends SemanticMarkRoute {
    render() {
        let {
            taskId,
        } = this.props;
        let {
            content,
            markedSimilarList,
            markedExcludeList,
            similarTagList,
            taskTagList,
        } = this.privates;
        let {
            actions,
            locales
        } = this;

        return (
            <div className="semantic-mark-component-info">
                <div className="semantic-mark-component-body">
                    <Form style={{ width: '100%' }} {...formItemLayout}>
                        <FormItem label={locales.components.pages.components.semanticMark.semanticContent}>
                            <Input name="content" value={content} onChange={actions.changeContent} onKeyPress={actions.pressEnter} onBlur={actions.submit} />
                        </FormItem>
                    </Form>
                    <div className="semantic-mark-component-content">
                        <div className="semantic-mark-component-content-title">
                            {locales.components.pages.components.semanticMark.markedSimilarTitel}
                        </div>
                        <For each="tag" index="index" of={markedSimilarList}>
                            <div className="semantic-mark-component-content-row">
                                <div className="semantic-mark-component-content-row-tag" title={tag.title}>
                                    {tag.title}
                                </div>
                                <div className="semantic-mark-component-content-row-standard" title={tag.standard}>
                                    {tag.standard}
                                </div>
                                <If condition={tag.standardFlag === 0}>
                                    <div className="semantic-mark-component-content-row-action">
                                        <span className="semantic-mark-action" onClick={actions.delete.params(false, tag.groupId)}>
                                            {locales.components.pages.components.semanticMark.delete}
                                        </span>
                                    </div>
                                </If>
                            </div>
                        </For>
                        <If condition={markedSimilarList.length === 0}>
                            <div className="semantic-mark-component-content-row">
                                {locales.components.pages.components.semanticMark.none}
                            </div>
                        </If>
                        <div className="semantic-mark-component-content-title">
                            {locales.components.pages.components.semanticMark.markedExcludeTitle}
                        </div>
                        <For each="tag" index="index" of={markedExcludeList}>
                            <div className="semantic-mark-component-content-row">
                                <div className="semantic-mark-component-content-row-tag" title={tag.title}>
                                    {tag.title}
                                </div>
                                <div className="semantic-mark-component-content-row-standard" title={tag.standard}>
                                    {tag.standard}
                                </div>
                                <div className="semantic-mark-component-content-row-action">
                                    <span className="semantic-mark-action" onClick={actions.delete.params(true, tag.groupId)}>
                                        {locales.components.pages.components.semanticMark.delete}
                                    </span>
                                </div>
                            </div>
                        </For>
                        <If condition={markedExcludeList.length === 0}>
                            <div className="semantic-mark-component-content-row">
                                {locales.components.pages.components.semanticMark.none}
                            </div>
                        </If>
                        <If condition={taskId}>
                            <div className="semantic-mark-component-content-title">
                                {locales.components.pages.components.semanticMark.taskTagTitle}
                            </div>
                            <For each="tag" index="index" of={taskTagList}>
                                <div className="semantic-mark-component-content-row">
                                    <div className="semantic-mark-component-content-row-tag" title={tag.title}>
                                        {tag.title}
                                    </div>
                                    <div className="semantic-mark-component-content-row-standard" title={tag.standard}>
                                        {tag.standard}
                                    </div>
                                    <div className="semantic-mark-component-content-row-action">
                                        <span className="semantic-mark-action" onClick={actions.mark.params(false, tag.groupId)}>
                                            {locales.components.pages.components.semanticMark.markSimilar}
                                        </span>
                                        <span className="semantic-mark-action" onClick={actions.mark.params(true, tag.groupId)}>
                                            {locales.components.pages.components.semanticMark.markExclude}
                                        </span>
                                    </div>
                                </div>
                            </For>
                            <If condition={taskTagList.length === 0}>
                                <div className="semantic-mark-component-content-row">
                                    {locales.components.pages.components.semanticMark.none}
                                </div>
                            </If>
                        </If>
                        <div className="semantic-mark-component-content-title">
                            {locales.components.pages.components.semanticMark.similarTagTitle}
                        </div>
                        <For each="tag" index="index" of={similarTagList}>
                            <div className="semantic-mark-component-content-row">
                                <div className="semantic-mark-component-content-row-tag" title={tag.title}>
                                    {tag.title}
                                </div>
                                <div className="semantic-mark-component-content-row-standard" title={tag.standard}>
                                    {tag.standard}
                                </div>
                                <div className="semantic-mark-component-content-row-action">
                                    <span className="semantic-mark-action" onClick={actions.mark.params(false, tag.groupId)}>
                                        {locales.components.pages.components.semanticMark.markSimilar}
                                    </span>
                                    <span className="semantic-mark-action" onClick={actions.mark.params(true, tag.groupId)}>
                                        {locales.components.pages.components.semanticMark.markExclude}
                                    </span>
                                </div>
                            </div>
                        </For>
                        <If condition={similarTagList.length === 0}>
                            <div className="semantic-mark-component-content-row">
                                {locales.components.pages.components.semanticMark.none}
                            </div>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}

