import React, { FC, memo, useEffect, useState } from 'react';
import { useDimensionality } from '../hooks';
import { Button, Input } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

type WrapperProps = {
    isEdit?: boolean;
};

const Wrapper = styled.div`
    padding: ${(props: WrapperProps) => (props.isEdit ? '12px' : '')};
    background-color: ${(props: WrapperProps) => (props.isEdit ? '#f8f8f8' : '')}; ;
`;

const Content = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
`;

const Operation = styled.div`
    text-align: right;
`;

type TemplateProps = {
    type?: number;
    value?: string;
    onChange?: (v?: string) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { type, value, onChange } = props;

    const { defaultPrompt } = useDimensionality({ type });

    const [temp, setTemp] = useState<string>();

    useEffect(() => {
        setTemp(value);
    }, [value]);

    const [isEdit, setIsEdit] = useState(false);

    const onEdit = () => {
        setIsEdit(true);
    };

    const onPromptChange = (v: string) => {
        setTemp(v);
    };

    const onRestore = () => {
        setTemp(defaultPrompt);
    };

    const onCancel = () => {
        setIsEdit(false);
    };

    const onConfirm = () => {
        onChange?.(temp);
        setIsEdit(false);
    };

    return (
        <Wrapper isEdit={isEdit}>
            {!isEdit ? (
                <Content>
                    <div>{value}</div>
                    <Button type="link" onClick={() => onEdit()}>{/* 编辑 */}{UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.components.prompt.index.edit}</Button>
                </Content>
            ) : (
                <>
                    <Input.TextArea
                        value={temp}
                        onChange={(e) => onPromptChange(e.target.value)}
                        autoSize
                    />

                    <Operation>
                        <Button type="text" onClick={() => onRestore()}>{/* 恢复默认 */}{UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.components.prompt.index.restoreDefault}</Button>
                        <Button type="text" onClick={() => onCancel()}>{/* 取消 */}{UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.components.prompt.index.cancel}</Button>
                        <Button type="link" onClick={() => onConfirm()}>{/* 确认 */}{UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.sessionsInsight.components.prompt.index.confirm}</Button>
                    </Operation>
                </>
            )}
        </Wrapper>
    );
};

export default memo(Template);
