// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerHomePageFilterQuery,
    BaseResponseListIntelligentPartnerHomePageFilterFoundResponse,
    PostIntelligentPartnerHomePageFilterBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 首页筛选器列表
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [GET] /intelligentPartner/homePage/filter
 */
export function getIntelligentPartnerHomePageFilter(): Promise<BaseResponseListIntelligentPartnerHomePageFilterFoundResponse>;
export function getIntelligentPartnerHomePageFilter(
    options: ApiOptions<never, GetIntelligentPartnerHomePageFilterQuery>
): Promise<BaseResponseListIntelligentPartnerHomePageFilterFoundResponse>;
export function getIntelligentPartnerHomePageFilter(
    options?: ApiOptions<never, GetIntelligentPartnerHomePageFilterQuery>
): Promise<BaseResponseListIntelligentPartnerHomePageFilterFoundResponse> {
    return request<never, never, GetIntelligentPartnerHomePageFilterQuery>(
        '/intelligentPartner/homePage/filter',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentPartnerHomePageFilter' }
    );
}

/**
 * 保存首页筛选器
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [POST] /intelligentPartner/homePage/filter
 */
export function postIntelligentPartnerHomePageFilter(
    data: PostIntelligentPartnerHomePageFilterBody
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerHomePageFilter(
    data: PostIntelligentPartnerHomePageFilterBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerHomePageFilter(
    data: PostIntelligentPartnerHomePageFilterBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentPartnerHomePageFilterBody, never, never>(
        '/intelligentPartner/homePage/filter',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerHomePageFilter',
        }
    );
}

export const meta = [
    { tags: ['智能陪练首页接口'], path: '/intelligentPartner/homePage/filter', method: 'get' },
    { tags: ['智能陪练首页接口'], path: '/intelligentPartner/homePage/filter', method: 'post' },
];
