// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostUploadRecordsResultsExportBody, BaseResponseVoid } from '../../types';

/**
 * export
 *
 * @export
 * @tags upload-records-result-controller
 * @link [POST] /uploadRecordsResults/export
 */
export function postUploadRecordsResultsExport(
    data: PostUploadRecordsResultsExportBody
): Promise<BaseResponseVoid>;
export function postUploadRecordsResultsExport(
    data: PostUploadRecordsResultsExportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postUploadRecordsResultsExport(
    data: PostUploadRecordsResultsExportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostUploadRecordsResultsExportBody, never, never>(
        '/uploadRecordsResults/export',
        { ...options, data, method: 'post', __$requestCalleeName: 'postUploadRecordsResultsExport' }
    );
}

export const meta = [
    {
        tags: ['upload-records-result-controller'],
        path: '/uploadRecordsResults/export',
        method: 'post',
    },
];
