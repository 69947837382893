import Udesk from 'Udesk';
import BillingCenterIndexComponent from './component';

class BillingCenterIndexRoute extends BillingCenterIndexComponent {
    asyncModel(changedParams) {
        let { sdkOptions } = this.props;
        let { pageSize, pageNum, startTime, endTime } = this.state;
        // let {} = this.privates;

        let params1 = {
            startTime,
            endTime,
            pageNum,
            pageSize,
        };

        if (changedParams != null) {
            Object.assign(params1, changedParams);
        }

        let url1 = Udesk.business.apiPath.concatApiPath(`account/charges`, sdkOptions);
        let billingTablePromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url1, params1).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        let url2 = Udesk.business.apiPath.concatApiPath(`account/charge`, sdkOptions);
        let billingDataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url2).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let url3 = Udesk.business.apiPath.concatApiPath(`account/charge/asr`, sdkOptions);
        let billingDetailPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url3).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        return {
            billingTable: billingTablePromise,
            billingData: billingDataPromise,
            billingDetail: billingDetailPromise,
        };
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        if (asyncKey === 'billingTable') {
            if (asyncModel.billingTable) {
                if (asyncModel.billingTable.data) {
                    let dataClone = JSON.parse(JSON.stringify(asyncModel.billingTable.data));
                    dataClone.map((data) => {
                        if (data.flowType === 1) {
                            data.children = [];
                        }
                        return data;
                    });
                    this.setState({
                        data: dataClone,
                    });
                }
                if (asyncModel.billingTable.paging) {
                    this.setState({
                        pageNum: asyncModel.billingTable.paging.pageNum,
                        pageSize: asyncModel.billingTable.paging.pageSize,
                        total: asyncModel.billingTable.paging.total,
                    });
                }
            }
        } else if (asyncKey === 'billingData') {
            if (asyncModel.billingData) {
                if (asyncModel.billingData.data) {
                    let deadlineSecond =
                        asyncModel.billingData.data.deadline.replace(/-/g, '/') + ' 00:00:00';
                    deadlineSecond = new Date(deadlineSecond).getTime();
                    let surplusSecond = (
                        (deadlineSecond - new Date().getTime()) /
                        86400000
                    ).toFixed(1);
                    let res =
                        surplusSecond > (asyncModel.billingData.data.imReminderDay || 15)
                            ? false
                            : true;
                    this.setState({
                        surplus: asyncModel.billingData.data.balance ?? '0',
                        deadline: asyncModel.billingData.data.deadline ?? '-',
                        callUse: asyncModel.billingData.data.callUse ?? '0',
                        textUse: asyncModel.billingData.data.textUse ?? '0',
                        callLimit: asyncModel.billingData.data.callReminderHour || 50,
                        dialogueLimit: asyncModel.billingData.data.imReminderDay || 15,
                        isWarning: res,
                        enabledCallInspect: asyncModel.billingData.data.enabledCallInspect,
                        enabledImInspect: asyncModel.billingData.data.enabledImInspect,
                        callWarning: asyncModel.billingData.data.balance * 1 < 50,
                    });
                }
            }
        } else if (asyncKey === 'billingDetail') {
            // console.log('billingDetail', asyncModel.billingDetail);
            // let arr = asyncModel.billingDetail.data;
            // arr = arr.concat(asyncModel.billingDetail.data);
            // arr = arr.concat(asyncModel.billingDetail.data);
            this.setState({
                detailDataSource: asyncModel.billingDetail.data || [],
                // detailDataSource: arr,
            });
        }
    }
}

export default BillingCenterIndexRoute;
