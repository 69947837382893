import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Select } from 'udesk-ui';
import { getSmartTagsList } from 'src/api/smartTags/list';
import UdeskLocales from 'UdeskLocales';

const locales = UdeskLocales['current'];

type TemplateProps = {
    value?: any;
    onChange?: (v: any) => void;
    disabled?: boolean;
    outerOptions?: any[];
    setOuterOptions?: (o: any[]) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange, disabled, outerOptions, setOuterOptions } = props;

    const [customerLabelList, setCustomerLabelList] = useState<any[]>([]);

    const getCustomerTagDataList = useCallback(() => {
        getSmartTagsList({
            params: {
                funcType: 'CUSTOMER_TAG',
            },
        }).then((res) => {
            setCustomerLabelList(res.data ?? []);
            setOuterOptions?.(res.data ?? []);
        });
    }, [setOuterOptions]);

    useEffect(() => {
        getCustomerTagDataList();
    }, [getCustomerTagDataList]);

    return (
        <Select
            showSearch
            showArrow
            allowClear
            mode="multiple"
            filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={locales.components.pages.intelligentTags.customerLabelTitle}
            style={{ width: 160 }}
            value={value}
            onChange={onChange}
            disabled={disabled}
        >
            {(outerOptions ?? customerLabelList).map((item) => {
                return (
                    <Select.Option key={item.id} value={item.id.split('_').reverse()[0]}>
                        {item.tagName}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default memo(Template);
