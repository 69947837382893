import React, { useCallback, useMemo, useRef } from 'react';
import Udesk from 'Udesk';
import { isDefined } from 'src/util/core';
import { Timeline } from "udesk-ui";
import { getGraspSituation, getScore } from '../util';
import { useLiveEffect } from 'src/util/hook';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        isLastNode,
        isStartTask,
        wrongQuestion,
        wrongQuestionSet,
        setWrongQuestionIndex
    } = props;
    const divRef = useRef<HTMLDivElement>(null);
    const index = useMemo(() => wrongQuestionSet?.findIndex(item => item === wrongQuestion), [wrongQuestion, wrongQuestionSet]);

    const color = useCallback((item) => {
        const {messageList} = item;
        const isGrasp = getGraspSituation(messageList);
        if (item === wrongQuestion && !isLastNode) {
            return 'blue';
        }
        if (isDefined(isGrasp)) {
            return isGrasp ? 'green' : 'red';
        }
        return 'gray';
    }, [isLastNode, wrongQuestion]);

    const clickHandle = useCallback((index) => {
        return ()=> {
            if (isStartTask) {
                setWrongQuestionIndex(index);
            }
        };
    }, [isStartTask, setWrongQuestionIndex]);

    const getName = useCallback((item) => {
        if (item.evaluationMode === Udesk.enums.pptEvaluationMode.whole.id) {
            return item.mistakeRuleDetailList?.[0]?.ruleName;
        }
        return item.nodeName;
    }, []);

    useLiveEffect(() => {
        setTimeout(() => {
            const $content =  divRef.current;
            if ($content) {
                if (index >= 0) {
                    $('li:eq('+ index +')', $content).get(0)?.scrollIntoView({
                        block: 'center',
                        inline:'start',
                        behavior: 'smooth',
                    });
                }
            }
        }, 200);
    }, [index]);

    return (
        <div className='flow-panel' ref={divRef}>
            <div className='flow-title'>
                <div><span>{/* 错题项 （ */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.flow.index.wrongQuestionItem}{index + 1}/{wrongQuestionSet?.length || 0}）</span></div>
                <span>
                    <span>{/* 得分 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.flow.index.score}</span><span>{/* /总分 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.flow.index.TotalScore}</span>
                </span>
            </div>
            <Timeline className='flow-list'>
                {
                    wrongQuestionSet.map((item, indexInList) => (
                        <Timeline.Item color={color(item)}>
                            <div className='flow-item' onClick={clickHandle(indexInList)}>
                                <div className={index === indexInList ? 'active' : ''}>{getName(item)}</div>
                                <div className={'score'}>
                                    {
                                        getScore(item.messageList, (score, totalScore) => {
                                            return <><span>{score}</span><span>/{totalScore}</span></>;
                                        })
                                    }
                                </div>
                            </div>
                        </Timeline.Item>
                    ))
                }
            </Timeline>
        </div>
    );
});

