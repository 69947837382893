// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetCallServiceSummaryFieldsQuery,
    BaseResponseListCallServiceSummaryFieldFoundResponse,
    PostCallServiceSummaryFieldsBody,
    BaseResponseVoid,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags call-service-summary-field-controller
 * @link [GET] /callServiceSummaryFields
 */
export function getCallServiceSummaryFields(
    options: ApiOptions<never, GetCallServiceSummaryFieldsQuery> & {
        params: GetCallServiceSummaryFieldsQuery;
    }
): Promise<BaseResponseListCallServiceSummaryFieldFoundResponse> {
    return request<never, never, GetCallServiceSummaryFieldsQuery>('/callServiceSummaryFields', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCallServiceSummaryFields',
    });
}

/**
 * save
 *
 * @export
 * @tags call-service-summary-field-controller
 * @link [POST] /callServiceSummaryFields
 */
export function postCallServiceSummaryFields(
    data: PostCallServiceSummaryFieldsBody
): Promise<BaseResponseVoid>;
export function postCallServiceSummaryFields(
    data: PostCallServiceSummaryFieldsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postCallServiceSummaryFields(
    data: PostCallServiceSummaryFieldsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostCallServiceSummaryFieldsBody, never, never>('/callServiceSummaryFields', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCallServiceSummaryFields',
    });
}

export const meta = [
    {
        tags: ['call-service-summary-field-controller'],
        path: '/callServiceSummaryFields',
        method: 'get',
    },
    {
        tags: ['call-service-summary-field-controller'],
        path: '/callServiceSummaryFields',
        method: 'post',
    },
];
