import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';
import {TasksManageEntry} from '../manage/tasks-manage-entry';

class ApproveTasksEntry extends TasksManageEntry {
    render() {
        let { route,routes, location, sdkOptions } = this.props;
        const hasFeature = (sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null) || (Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null);

        if (this.isRedirect(route, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className='approve-tasks-entry'>
                    <For each='route' index='i' of={routes}>
                        <If
                            condition={
                                !route.authCode || (route.authCode && hasFeature && hasFeature(route.authCode))
                            }>
                            <SubRoutes key={i} route={route} />
                        </If>
                    </For>
                </div>
            );
        }
    }
}
export default Udesk.react.udeskify(ApproveTasksEntry);
