// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { DeleteCallTestsByTaskIdParam, BaseResponseVoid } from '../../types';

/**
 * 清空测试对话
 *
 * @export
 * @tags 质检规则测试
 * @link [DELETE] /callTests/{taskId}
 * @param taskId
 */
export function deleteCallTestsByTaskId(
    options: ApiOptions<DeleteCallTestsByTaskIdParam, never> & {
        segments: DeleteCallTestsByTaskIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteCallTestsByTaskIdParam, never>('/callTests/{taskId}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteCallTestsByTaskId',
    });
}

export const meta = [{ tags: ['质检规则测试'], path: '/callTests/{taskId}', method: 'delete' }];
