// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostIntelligentPartnerCustomerPersonasBindFlowBody,
    BaseResponseVoid,
} from '../../types';

/**
 * 绑定流程
 *
 * @export
 * @tags 智能陪练-客户画像配置
 * @link [POST] /intelligentPartnerCustomerPersonas/bindFlow
 */
export function postIntelligentPartnerCustomerPersonasBindFlow(
    data: PostIntelligentPartnerCustomerPersonasBindFlowBody
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerCustomerPersonasBindFlow(
    data: PostIntelligentPartnerCustomerPersonasBindFlowBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentPartnerCustomerPersonasBindFlow(
    data: PostIntelligentPartnerCustomerPersonasBindFlowBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentPartnerCustomerPersonasBindFlowBody, never, never>(
        '/intelligentPartnerCustomerPersonas/bindFlow',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerCustomerPersonasBindFlow',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-客户画像配置'],
        path: '/intelligentPartnerCustomerPersonas/bindFlow',
        method: 'post',
    },
];
