import React, { useEffect, useMemo, useState } from 'react';
import { Space, Radio, Tree, Empty } from 'udesk-ui';
import { getSupervisionRuleConfigsFindWithDetail } from 'src/api/supervisionRuleConfigs/findWithDetail';
import { getSmartTagsCategoryWithTagsByFuncType } from 'src/api/smartTags/categoryWithTags/{funcType}';
import Locales from 'UdeskLocales';

const SUPERVISION = 'SUPERVISION';
const REVIEW_KEY_EVENT = 'REVIEW_KEY_EVENT';

const CheckBoxTree = (props) => {
    const { treeData, visible, onChange } = props;

    return (
        <div
            style={{
                display: visible ? 'block' : 'none',
                width: '100%',
            }}
        >
            {treeData.length ? (
                <Tree onCheck={onChange} treeData={treeData} blockNode checkable defaultExpandAll />
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </div>
    );
};

type TestSelectProps = {
    onCheck?: (newValue: string[], halfCheckedKeys: string[]) => void;
    onRadioChange?: (e: any) => void;
    activeRadioValue?: typeof SUPERVISION | typeof REVIEW_KEY_EVENT;
};

type TestSelectComponentType = React.NamedExoticComponent<TestSelectProps> & {
    useTestSelect: typeof useTestSelect;
};

const TestSelect = React.memo<TestSelectProps>((props) => {
    const locales = Locales['current'];

    const { activeRadioValue, onRadioChange, onCheck } = props;
    const [supervisionData, setSupervisionData] = useState<any[]>([]);
    const [keyEventData, setKeyEventData] = useState<any[]>([]);
    useEffect(() => {
        getSupervisionRuleConfigsFindWithDetail().then((resp) => {
            setSupervisionData(
                (resp.data ?? []).map((i) => ({
                    ...i,
                    title: i.categoryName,
                    key: `category-${i.id}`,
                    children: Array.isArray(i.pointList)
                        ? i.pointList.map((p) => ({
                            ...p,
                            key: `point-${p.id}`,
                            title: p.name,
                        }))
                        : undefined,
                }))
            );
        });
        getSmartTagsCategoryWithTagsByFuncType({
            segments: {
                funcType: REVIEW_KEY_EVENT,
            },
        }).then((resp) => {
            setKeyEventData(
                (resp.data ?? []).map((i) => ({
                    ...i,
                    title: i.categoryName,
                    key: `category-${i.id}`,
                    children: Array.isArray(i.smartTagList)
                        ? i.smartTagList.map((p) => ({
                            ...p,
                            key: `point-${p.id}`,
                            title: p.tagName,
                        }))
                        : undefined,
                }))
            );
        });
    }, []);

    return (
        <React.Fragment>
            <div className='title'>
                <Space>
                    <div>{/* 当前测试项目: */}{locales.pages.gong.checkPointTest.components.testSelectComponent.currentTestItems}</div>
                    <Radio.Group value={activeRadioValue} onChange={onRadioChange} buttonStyle='solid'>
                        <Radio.Button value={SUPERVISION}>{/* 监督项 */}{locales.pages.gong.checkPointTest.components.testSelectComponent.supervisionItem}</Radio.Button>
                        <Radio.Button value={REVIEW_KEY_EVENT}>{/* 关键事件 */}{locales.pages.gong.checkPointTest.components.testSelectComponent.keyEvents}</Radio.Button>
                    </Radio.Group>
                </Space>
            </div>
            <div style={{ flex: 1, marginBottom: 8, marginTop: 8, display: 'flex', overflow: 'auto' }}>
                <CheckBoxTree
                    key={SUPERVISION}
                    treeData={supervisionData}
                    visible={activeRadioValue === SUPERVISION}
                    onChange={(checkedKeys, { halfCheckedKeys }) => {
                        onCheck?.(checkedKeys, halfCheckedKeys);
                    }}
                />
                <CheckBoxTree
                    key={REVIEW_KEY_EVENT}
                    treeData={keyEventData}
                    visible={activeRadioValue === REVIEW_KEY_EVENT}
                    onChange={(checkedKeys, { halfCheckedKeys }) => {
                        onCheck?.(checkedKeys, halfCheckedKeys);
                    }}
                />
            </div>
        </React.Fragment>
    );
}) as TestSelectComponentType;

function useTestSelect() {
    const [activeRadioValue, setActiveRadioValue] = useState<'SUPERVISION' | 'REVIEW_KEY_EVENT'>('SUPERVISION');
    const [supervisionValue, setSupervisionValue] = useState<string[]>([]);
    const [reviewKeyEvent, setReviewKeyEvent] = useState<string[]>([]);
    const [supervisionHalfCheckedKeys, setSupervisionHalfCheckedKeys] = useState<string[]>([]);
    const [reviewKeyEventHalfCheckedKeys, setReviewKeyEventHalfCheckedKeys] = useState<string[]>([]);
    const onCheck = (newValue, halfCheckedKeys) => {
        if (activeRadioValue === SUPERVISION) {
            setSupervisionValue(newValue);
            setSupervisionHalfCheckedKeys(halfCheckedKeys);
        } else {
            setReviewKeyEvent(newValue);
            setReviewKeyEventHalfCheckedKeys(halfCheckedKeys);
        }
    };
    const onRadioChange = (e) => {
        setActiveRadioValue(e.target.value);
    };
    const value = useMemo(() => {
        return {
            currentType: activeRadioValue,
            REVIEW_KEY_EVENT: {
                checkedKeys: reviewKeyEvent,
                halfCheckedKeys: reviewKeyEventHalfCheckedKeys,
            },
            SUPERVISION: {
                checkedKeys: supervisionValue,
                halfCheckedKeys: supervisionHalfCheckedKeys,
            },
        };
    }, [activeRadioValue, supervisionValue, reviewKeyEvent, supervisionHalfCheckedKeys, reviewKeyEventHalfCheckedKeys]);
    return {
        onCheck,
        onRadioChange,
        activeRadioValue,
        value,
    };
}

TestSelect.useTestSelect = useTestSelect;

export { TestSelect };
