import React from 'react';
import UdeskPagination from 'Component/common/udesk-pagination';
import ClassNames from 'classnames';
import MiniListManageComponent from './component';
import './style.scss';

export default class MiniListManageTemplate extends MiniListManageComponent {
    render() {
        let {
            // sdkOptions,
            manageTitle
        } = this.props;
        let {
            data,
            paging,
            selectedDatum
        } = this.privates;
        let {
            actions,
            locales
        } = this;
        return (
            <div className="mini-list-manage-component-container">

                <div className='layout-second-level-header'>
                    <span className='layout-second-level-header-title'>
                        {manageTitle}
                    </span>
                </div>
                <div className="mini-list-manage-component-body">
                    <Choose>
                        <When condition={data && data.fieldDataList && data.fieldDataList.length > 0}>
                            <ul className="mini-list-manage-component-body-has-value">
                                <For each="datum" index="index" of={data.fieldDataList}>
                                    <li key={index} className={ClassNames("mini-list-manage-component-item", { "active": selectedDatum.dataId === datum.dataId })} onClick={actions.changeSelectedDatum.params(datum)}>
                                        <For each="field" index="fieldIndex" of={data.showFieldList}>
                                            <p key={fieldIndex} className="mini-list-manage-component-item-field">
                                                {field.label}
                                                {locales.labels.colon}
                                                {datum.fieldValueMap[field.id]}
                                            </p>
                                        </For>
                                    </li>
                                </For>
                            </ul>
                        </When>
                        <Otherwise>
                            <div className="mini-list-manage-component-body-no-value">
                                {locales.components.pages.components.filterManage.noValueTip}
                            </div>
                        </Otherwise>
                    </Choose>
                </div>
                <div className="mini-list-manage-component-footer">
                    <If condition={data && data.fieldDataList && data.fieldDataList.length > 0}>
                        {/* <ReactPager
                            enablePageSize={false}
                            showTotal={true}
                            showTotalPages={false}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={0}
                            pageSize={paging.pageSize}
                            total={paging.total}
                            pageNum={paging.pageNum}
                            languageCode={sdkOptions.props.language}
                            pageChanged={actions.pageChanged}
                        /> */}
                        <UdeskPagination
                            current={paging.pageNum}
                            simple={true}
                            showSizeChanger={false}
                            pageSize={paging.pageSize}
                            total={paging.total}
                            onChange={actions.pageChanged}
                        />
                    </If>
                </div>
            </div>
        );
    }
}

