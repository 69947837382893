import Locales from '../locales/index';
import UdeskLocales from 'UdeskLocales';

let filterTypes = [
    { id: '1', key: 'call', name: () => Locales.current.enums.filterTypes.call },
    { id: '2', key: 'dialogue', name: () => Locales.current.enums.filterTypes.dialogue },
    { id: '5', key: 'wechat', name: () => Locales.current.enums.filterTypes.wechat },
    { id: '6', key: 'wechatAll', name: () => Locales.current.enums.filterTypes.wechatAll },
    { id: '10', key: 'ticket', name: () => Locales.current.enums.filterTypes.ticket },
    { id: '8', key: 'taobao', name: () => Locales.current.enums.filterTypes.taobao },
    { id: '12', key: 'wechatRadio', name: () => Locales.current.enums.filterTypes.wechatRadio },
    { id: '15', key: 'eComment', name: () => /* 电商评论 */UdeskLocales['current'].enums.callDialogueManage.eCommerceReview },
    { id: '16', key: 'mComment', name: () => /* 社媒评论 */UdeskLocales['current'].enums.callDialogueManage.socialMediaComments },
];

export default {
    filterTypes,
};
