import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs, Drawer } from 'udesk-ui';
import { StandardizeTable } from 'udesk_gm_ui';
import ScoreDetailList from './components/ScoreDetailList';
import { postIntelligentPartnerTaskInfosStatisticsCountById } from 'src/api/intelligentPartnerTaskInfos/statistics/count/{id}';
import { postIntelligentPartnerTaskInfosStatisticsById } from 'src/api/intelligentPartnerTaskInfos/statistics/{id}';
import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import moment from 'moment';
import { CommonFilterProps } from 'udesk_gm_ui/es/common-filter';
import { KeyPointHit } from './components/key-point-hit';
import OrganizationMemberSelect from '../../../components/OrganizationMemberSelect';
import UdeskLocales from 'UdeskLocales';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { getDateTimeString } from 'src/util/moment';
import { useRequest } from 'src/util/hook';
import { linkTo } from 'src/util/link';
import { useCoachUserTreeDataWithPermit } from 'src/util/hook/user';
import { getSystemModule } from 'Udesk/system/subApp';

const DEFAULT_PAGE = {
    current: 1,
    pageSize: 20,
    total: 0,
};

function formatTableNulItem(originText: any, showText?: any) {
    if (originText == null) {
        return '-';
    }
    return showText || originText;
}

const Template = React.memo((props: any) => {
    const request = useRequest();
    const { match } = props;
    const taskId = match?.params?.id || '';
    const [taskInfo, setTaskInfo] = useState<any>();
    const locales = UdeskLocales['current'];
    const [loading, setLoading] = useState(false);
    const [tempFilter, setTempFilter] = useState<any>({});
    const [exportUrl, setExportUrl] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [pagination, setPagination] = useState<any>(DEFAULT_PAGE);
    const [selectedSubItem, setSelectedSubItem] = useState<any>(null);
    const [negativeEmotionsFlag, setNegativeEmotionsFlag] = useState<number>();
    const [currentTab, setCurrentTab] = useState('');

    const { treeData } = useCoachUserTreeDataWithPermit();

    const init = useCallback(() => {
        const id = match?.params?.id || '0';
        if (id !== '0') {
            setLoading(true);
            getIntelligentPartnerTaskInfosById({
                segments: { id },
            })
                .then((resp) => {
                    let values: any = {
                        ...resp?.data,
                    };
                    //画像
                    values.imageIdInfo = {
                        status: 2,
                    };
                    if (values.imageId != null) {
                        values.imageIdInfo.status = 1;
                        values.imageIdInfo.selectedItemId = values.imageId;
                    }
                    // 时长限制
                    values.limitDurationInfo = {
                        status: 1,
                    };
                    if (values.limitDuration != null) {
                        values.limitDurationInfo.status = 2;
                        values.limitDurationInfo.inputValue = values.limitDuration;
                    }
                    // 时间
                    values.taskTimeInfo = {
                        status: 1,
                        viewText:
                            /* 不限时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .index.unlimitedTime,
                    };
                    if (values.taskStartTime && values.taskStartTime) {
                        values.taskTimeInfo.status = 2;
                        values.taskTimeInfo.viewText =
                            moment(values.taskStartTime).format('YYYY-MM-DD HH:mm') +
                            /* 至 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .to +
                            moment(values.taskEndTime).format('YYYY-MM-DD HH:mm');
                    }
                    // 评分标准
                    values.gradingStandartInfo = {
                        commitScore: values.commitScore,
                        excellentScore: values.excellentScore,
                    };
                    // 完成要求
                    values.completeStandartInfo = {};
                    if (values.completeByTimes != null) {
                        values.completeStandartInfo.timesStatus = true;
                        values.completeStandartInfo.completeByTimes = values.completeByTimes;
                    }
                    if (values.completeByScore != null) {
                        values.completeStandartInfo.scoreStatus = true;
                        values.completeStandartInfo.completeByScore = values.completeByScore;
                    }
                    setTaskInfo(values);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        init();
    }, []);

    const onTabChange = (activeKey) => {
        setCurrentTab(activeKey);
        if (activeKey == 'personData') {
            onTableRequest(tempFilter);
        } else if (activeKey == 'numberData') {
            onTableRequestCount(tempFilter);
        }
        exportOperate(activeKey);
    };

    const onTableRequest = (params) => {
        // console.log('request:params', params);
        setLoading(true);
        const { filter } = params;
        const { time, keyPointHit } = filter || {};
        const [startTime, endTime] = time || [];
        if (startTime) {
            params.startTime = getDateTimeString(startTime, 'YYYY-MM-DD');
        }
        if (endTime) {
            params.endTime = getDateTimeString(endTime, 'YYYY-MM-DD');
        }
        const reqData: any = {
            startTime: params.startTime,
            endTime: params.endTime,
            pageNum: params.pagination?.current || 1, // 页数
            pageSize: params.pagination?.pageSize || 20, // 每页条数
            userIds: params.filter?.taskTargetObj?.userIds || [], // 用户ID数组
            organizationIds: params.filter?.taskTargetObj?.organizationIds || [], //
            outComes: params.filter?.outComes, // 最高得分结果数组 0-未达标 1-达标 2-优秀
            completeStates: params.filter?.complteStates, // 完成状态 0-未开始 1-进行中 2-已完成/已通过 3-未完成/未通过
        };
        if (keyPointHit) {
            reqData.isHit = keyPointHit.isHit;
        }
        if (params.sorter?.order) {
            reqData.sortField = params.sorter.columnKey; // exercise_times 练习次数 , highest_score 最高得分, average_score 平均得分 ,average_take_time 平均耗时 , recent_exercise_time 最近练习时间
            reqData.sortOrder = params.sorter.order === 'ascend' ? 1 : 2; // 1-升序 2-降序
        }
        setTempFilter(reqData);
        postIntelligentPartnerTaskInfosStatisticsById(reqData, {
            segments: { id: match?.params?.id },
        })
            .then((resp) => {
                setDataSource(resp?.data || []);
                setPagination({
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum || 1,
                    pageSize: resp.paging?.pageSize,
                });
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    const onTableRequestCount = (params) => {
        // console.log('request:params', params);
        setLoading(true);
        const { filter } = params;
        const { time, keyPointHit } = filter || {};
        const [startTime, endTime] = time || [];
        if (startTime) {
            params.startTime = getDateTimeString(startTime, 'YYYY-MM-DD');
        }
        if (endTime) {
            params.endTime = getDateTimeString(endTime, 'YYYY-MM-DD');
        }
        const reqData: any = {
            negativeEmotionsFlag: negativeEmotionsFlag,
            startTime: params.startTime,
            endTime: params.endTime,
            pageNum: params.pagination?.current || 1, // 页数
            pageSize: params.pagination?.pageSize || 20, // 每页条数
            userIds: params.filter?.taskTargetObj?.userIds || [], // 用户ID数组
            organizationIds: params.filter?.taskTargetObj?.organizationIds || [], //
            outComes: params.filter?.outComes, // 最高得分结果数组 0-未达标 1-达标 2-优秀
            completeStates: params.filter?.complteStates, // 完成状态 0-未开始 1-进行中 2-已完成/已通过 3-未完成/未通过
        };
        if (keyPointHit) {
            reqData.isHit = keyPointHit.isHit;
            reqData.ruleId = keyPointHit.point.ruleId;
            reqData.nodeId = keyPointHit.point.nodeId;
        }
        if (params.sorter?.order) {
            reqData.sortField = params.sorter.columnKey; // exercise_times 练习次数 , highest_score 最高得分, average_score 平均得分 ,average_take_time 平均耗时 , recent_exercise_time 最近练习时间
            reqData.sortOrder = params.sorter.order === 'ascend' ? 1 : 2; // 1-升序 2-降序
        }
        let taskId = match?.params?.id || '';
        setTempFilter(reqData);
        postIntelligentPartnerTaskInfosStatisticsCountById(reqData, { segments: { id: taskId } })
            // request('/intelligentPartnerTaskInfos/statistics/count/' + taskId , reqData, 'post')
            // postIntelligentPartnerTaskInfosStatisticsById(reqData, {
            //     segments: { id: match?.params?.id },
            // })
            .then((resp) => {
                setDataSource(resp?.data || []);
                setPagination({
                    total: resp.paging?.total,
                    current: resp.paging?.pageNum || 1,
                    pageSize: resp.paging?.pageSize,
                });
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const exportOperate = (type) => {
        let url: string = '';
        if (type == 'personData') {
            url = `/intelligentPartnerTaskInfos/statistics/${taskId}/export`;
        } else {
            url = `/intelligentPartnerTaskInfos/statistics/count/${taskId}/export`;
        }
        setExportUrl(url);
    };

    const OutComeOptions = useMemo(() => {
        return [
            {
                label: /* 未达标 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .notMeetingStandards,
                value: 0,
            },
            {
                label: /* 达标 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .meetingStandards,
                value: 1,
            },
            {
                label: /* 优秀 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .excellent,
                value: 2,
            },
        ];
    }, []);
    const CompleteStateOptions = useMemo(() => {
        return [
            {
                label: /* 未开始 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .notStarted,
                value: 0,
            },
            {
                label: /* 进行中 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .haveInHand,
                value: 1,
            },
            {
                label:
                    taskInfo?.taskType === 1
                        ? /* 已完成 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .completed
                        : /* 已通过 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .passed,
                value: 2,
            },
            {
                label:
                    taskInfo?.taskType === 1
                        ? /* 未完成 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .hangInTheAir
                        : /* 未通过 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .failed,
                value: 3,
            },
        ];
    }, [taskInfo]);

    const filter: CommonFilterProps = useMemo(() => {
        return {
            customComponents: {
                OrganizationMemberSelect,
                KeyPointHit,
            },
            fields: [
                {
                    name: 'taskTargetObj',
                    label: /* 员工 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                        .staff,
                    type: 'OrganizationMemberSelect',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        width: 200,
                        treeData,
                    },
                },
                {
                    name: 'outComes',
                    label: /* 最高得分标准 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                        .index.maximumScoringCriteria,
                    type: 'Select',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        options: OutComeOptions,
                        style: { width: 200 },
                        mode: 'multiple',
                        maxTagCount: 2,
                        allowClear: true,
                        showArrow: true,
                    },
                },
                {
                    name: 'complteStates',
                    label: /* 完成状态 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                        .index.completionStatus,
                    type: 'Select',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        options: CompleteStateOptions,
                        style: { width: 200 },
                        mode: 'multiple',
                        maxTagCount: 2,
                        allowClear: true,
                        showArrow: true,
                    },
                },
                {
                    name: 'time',
                    label: /* 时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                        .time,
                    type: 'RangePicker',
                    props: {
                        format: 'YYYY-MM-DD',
                        showTime: true,
                        style: {
                            width: 340,
                        },
                    },
                },
            ] as CommonFilterProps['fields'],
        };
    }, [CompleteStateOptions, OutComeOptions, locales.labels.pleaseSelect, treeData]);

    const onShowItemDetail = (item) => {
        setSelectedSubItem(item);
    };
    const onShowItemDetailCount = (item) => {
        const from = window.location.pathname;
        const url = `/coach/learning-center/record/detail/${item.id}?from=${from}`;
        window.open(url);
    };

    const filterCount: CommonFilterProps = useMemo(() => {
        return {
            customComponents: {
                OrganizationMemberSelect,
                KeyPointHit,
            },
            fields: [
                {
                    name: 'taskTargetObj',
                    label: /* 员工 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                        .staff,
                    type: 'OrganizationMemberSelect',
                    props: {
                        placeholder: locales.labels.pleaseSelect,
                        width: 200,
                        treeData,
                    },
                },
                {
                    name: 'keyPointHit',
                    label: /* 要点 */ UdeskLocales['current'].pages.coach.dataAnalysis
                        .learningRecord.index.mainPoints,
                    type: 'KeyPointHit',
                    props: {
                        taskId: match?.params?.id,
                    },
                },
                {
                    name: 'time',
                    label: /* 时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                        .time,
                    type: 'RangePicker',
                    props: {
                        format: 'YYYY-MM-DD',
                        showTime: true,
                        style: {
                            width: 340,
                        },
                    },
                },
            ] as CommonFilterProps['fields'],
        };
    }, [CompleteStateOptions, OutComeOptions, locales.labels.pleaseSelect, treeData]);

    const tablePropsCount = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                key: 'userName',
                dataIndex: 'userName',
                title: /* 员工姓名 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .employeeName,
                width: 160,
                fixed: 'left',
            },
            {
                key: 'score',
                dataIndex: 'score',
                title: /* 得分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index.score,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'scoringResults',
                dataIndex: 'scoreResult',
                title: /* 得分结果 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .scoringResults,
                render: (text) => {
                    if (text == 0) {
                        return /* 未达标 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                            .index.notMeetingStandards;
                    } else if (text == 1) {
                        return /* 达标 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                            .index.meetingStandards;
                    } else if (text == 2) {
                        return /* 优秀 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                            .index.excellent;
                    }
                    return '';
                },
                sorter: true,
            },
            {
                key: 'exercise_times',
                dataIndex: 'accumulateCostTime',
                title: `${
                    taskInfo?.taskType === 1
                        ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .practice
                        : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .examination
                }${
                    /* 时长 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index.duration
                }`,
                sorter: true,
            },
            {
                key: 'exercise_times',
                dataIndex: 'dialogueStartTime',
                title: `${
                    taskInfo?.taskType === 1
                        ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .practice
                        : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .examination
                }${/* 时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index.time}`,
                sorter: true,
            },

            {
                key: 'flowScore',
                dataIndex: 'flowScore',
                title: /* 流程得分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .flowScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'craftScore',
                dataIndex: 'craftScore',
                title: /* 话术得分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .craftScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'emotionScore',
                dataIndex: 'emotionScore',
                title: /* 情绪得分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .emotionScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'speedScore',
                dataIndex: 'speedScore',
                title: /* 表达得分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .speedScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'contentIntegrity',
                dataIndex: 'contentIntegrity',
                title: /* 内容完整度 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .contentIntegrity,
                render: (text) =>
                    formatTableNulItem(text) !== '-'
                        ? formatTableNulItem(text) + '%'
                        : formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'pointHitRate',
                dataIndex: 'pointHitRate',
                title: /* 要点命中率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .pointHitRate,
                render: (text) =>
                    formatTableNulItem(text) !== '-'
                        ? formatTableNulItem(text) + '%'
                        : formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'emotionNegativeRate',
                dataIndex: 'emotionNegativeRate',
                title: /* 情绪负向占比 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                    .index.emotionNegativeRate,
                render: (text) =>
                    formatTableNulItem(text) !== '-'
                        ? formatTableNulItem(text) + '%'
                        : formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'paraPhaSiaRate',
                dataIndex: 'paraPhaSiaRate',
                title: /* 语速异常占比 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                    .index.paraPhaSiaRate,
                render: (text) =>
                    formatTableNulItem(text) !== '-'
                        ? formatTableNulItem(text) + '%'
                        : formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'toneOvermuchRate',
                dataIndex: 'toneOvermuchRate',
                title: /* 语气词过多占比 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                    .index.toneOvermuchRate,
                render: (text) =>
                    formatTableNulItem(text) !== '-'
                        ? formatTableNulItem(text) + '%'
                        : formatTableNulItem(text),
                sorter: true,
            },

            {
                key: 'actions',
                renderType: 'actions',
                title: /* 操作 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .operation,
                buttons: [
                    {
                        label: /* 查看详情 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                            .index.viewDetails,
                        onClick: (item) => onShowItemDetailCount(item),
                    },
                    {
                        label: /* 错题集 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                            .index.wrongQuestionSet,
                        onClick: (item) => {
                            const url = `/coach/learning-center/wrong-question-set/index?taskId=${item.taskId}&userId=${item.userId}`;
                            window.open(url);
                        },
                    },
                ],
            },
        ];
        return {
            columns,
            dataSource,
            pagination,
            loading,
            scroll: { x: 'max-content', y: false },
        } as any;
    }, [dataSource, loading, pagination, taskInfo?.taskType]);

    const tablePropsPerson = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                key: 'userName',
                dataIndex: 'userName',
                title: /* 员工姓名 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .employeeName,
                width: 160,
                fixed: 'left',
            },
            {
                key: 'exercise_times',
                dataIndex: 'exerciseTimes',
                title: `${
                    taskInfo?.taskType === 1
                        ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .practice
                        : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .examination
                }${
                    /* 次数 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index.frequency
                }`,
                sorter: true,
            },
            {
                key: 'average_score',
                dataIndex: 'averageScore',
                title: /* 平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .average,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },

            {
                key: 'speechCraftAvgScore',
                dataIndex: 'speechCraftAvgScore',
                title: /* 话术平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .speechCraftAvgScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'flowAvgScore',
                dataIndex: 'flowAvgScore',
                title: /* 流程平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .flowAvgScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'emotionAvgScore',
                dataIndex: 'emotionAvgScore',
                title: /* 情绪平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .emotionAvgScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'speedAvgScore',
                dataIndex: 'speedAvgScore',
                title: /* 表达平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .speedAvgScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'participateCommitRate',
                dataIndex: 'participateCommitRate',
                title: /* 达标率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .participateCommitRate,
                // render: (text) => formatTableNulItem(text),
                render: (text) =>
                    formatTableNulItem(text) !== '-'
                        ? formatTableNulItem(text) + '%'
                        : formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'participateExcellentRate',
                dataIndex: 'participateExcellentRate',
                title: /* 达优率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .participateExcellentRate,
                // render: (text) => formatTableNulItem(text),
                render: (text) =>
                    formatTableNulItem(text) !== '-'
                        ? formatTableNulItem(text) + '%'
                        : formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'highest_score',
                dataIndex: 'highestScore',
                title: /* 最高分 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .maximumScore,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'outCome',
                dataIndex: 'outCome',
                title: /* 最高得分结果 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                    .index.highestScoreResult,
                render: (text) => OutComeOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'completeState',
                dataIndex: 'completeState',
                title: /* 状态 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index.state,
                render: (text) => CompleteStateOptions.find((item) => item.value === text)?.label,
            },
            {
                key: 'average_take_time',
                dataIndex: 'averageTakeTime',
                title: /* 平均时长(min) */ UdeskLocales['current'].pages.coach.taskCenter.detail
                    .index.averageDurationmin,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'recent_exercise_time',
                dataIndex: 'recentExerciseTime',
                title: `${
                    /* 最近 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index.lately
                }${
                    taskInfo?.taskType === 1
                        ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .practice
                        : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                              .examination
                }${/* 时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index.time}`,
                render: (text) => formatTableNulItem(text),
                sorter: true,
            },
            {
                key: 'actions',
                renderType: 'actions',
                title: /* 操作 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                    .operation,
                buttons: [
                    {
                        label: /* 查看详情 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                            .index.viewDetails,
                        onClick: (item) => onShowItemDetail(item),
                    },
                    {
                        label: /* 错题集 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                            .index.wrongQuestionSet,
                        onClick: (item) => {
                            const url = `/coach/learning-center/wrong-question-set/index?taskId=${item.taskId}&userId=${item.userId}`;
                            window.open(url);
                        },
                    },
                ],
            },
        ];
        return {
            columns,
            dataSource,
            pagination,
            loading,
            scroll: { x: 'max-content', y: false },
        } as any;
    }, [CompleteStateOptions, OutComeOptions, dataSource, loading, pagination, taskInfo?.taskType]);

    useEffect(() => {
        let urlTab = props?.location?.search.split('=')[1] || '';
        // console.log('match',props.location.search,urlTab);
        if (urlTab && urlTab == 'numberData') {
            onTabChange('numberData');
            setNegativeEmotionsFlag(1);
        } else {
            onTabChange('personData');
        }
    }, []);

    return (
        <Page pageBodyClassName="udesk-coach-web-customer-tags-management-index" padding={true}>
            <Tabs
                style={{ padding: '4px 16px', backgroundColor: '#fff' }}
                activeKey={currentTab}
                // onChange={(activeKey) => setCurrentTab(activeKey)}
                onChange={onTabChange}
            >
                <Tabs.TabPane
                    tab={
                        /* 按人员统计 */
                        UdeskLocales['current'].pages.coach.taskCenter.detail.index.personData
                    }
                    key="personData"
                >
                    <StandardizeTable.RequestTable
                        onRequest={onTableRequest}
                        filter={filter}
                        table={tablePropsPerson}
                        exportBtn={{
                            onClick: () => {
                                return request(
                                    exportUrl,
                                    {
                                        systemModule: getSystemModule(),
                                        ...tempFilter,
                                    },
                                    'post'
                                );
                            },
                            goToDownloadCenter: () => {
                                linkTo(props.history, 'coachSystemTaskCenterDownloadList');
                            },
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        /* 按次记录明细 */
                        UdeskLocales['current'].pages.coach.taskCenter.detail.index.numberData
                    }
                    key="numberData"
                >
                    <StandardizeTable.RequestTable
                        onRequest={onTableRequestCount}
                        filter={filterCount}
                        table={tablePropsCount}
                        exportBtn={{
                            onClick: () => {
                                return request(
                                    exportUrl,
                                    {
                                        systemModule: getSystemModule(),
                                        ...tempFilter,
                                    },
                                    'post'
                                );
                            },
                            goToDownloadCenter: () => {
                                linkTo(props.history, 'coachSystemTaskCenterDownloadList');
                            },
                        }}
                    />
                </Tabs.TabPane>
            </Tabs>
            <Drawer
                title={`${
                    /* 得分详情 - */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                        .scoreDetails
                }${selectedSubItem?.userName}`}
                width={500}
                visible={!!selectedSubItem}
                onClose={() => setSelectedSubItem(null)}
            >
                <ScoreDetailList
                    taskType={taskInfo?.taskType}
                    subTaskItem={selectedSubItem}
                    history={props.history}
                />
            </Drawer>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
