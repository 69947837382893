import Udesk from 'Udesk';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_DATE_TIME_FORMAT, getDateTimeString } from 'src/util/moment';
import { createMessage } from '../../../util';
import { usePassThroughParams } from '../../..';
import { useAudioRecorder } from '../..';
import { useDebounce } from 'src/util/hook';
import {
    useAudioPlay,
    useSuspend,
    useStartSecond,
    useTaskFlow,
    useAnswerTimeout,
} from '../../hook';
import { fire, isBoolean } from 'src/util/core';
import { getCustomMessageList } from '../../ppt/hook';
import moment from 'moment';
import { postIntelligentPartnerAiReferenceWordsByNodeId } from 'src/api/intelligentPartner/ai/referenceWords/{nodeId}';
import UdeskLocales from 'UdeskLocales';

const updatePassThroughParams = (passThroughParams, item) => {
    $.extend(passThroughParams, {
        bindNodeId: item.bindNodeId, // 节点ID ，后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionClosely: item.questionClosely, // 是否未追问 0: 否 1: 是,后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionCloselyWordsIndex: item.questionCloselyWordsIndex, // 追问索引句子, 后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionIdList: item.questionIdList, // 已经回答的知识库节点的问答id, 后续交互作为参数传入，如果返回多个，取最后一个节点的
        hasAnswerFinishKnowledge: item.hasAnswerFinishKnowledge, // 知识库节点是否已经回答完毕// 后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionCloselyId: item.questionCloselyId, // 追问增加的id
    });
};

export const useChatRecord = (props: any) => {
    const [audioRef, audioPlayHandle, audioEndedHandle] = useAudioPlay();
    const { request, task, record, setRecord, aiGenSceneNodeId } = props;
    const {
        list: recordList,
        startRecord,
        pauseRecord,
        uploadFile,
        pointOfFirstEntry,
    } = useAudioRecorder(task);
    const [isStartTask, setIsStartTask] = useState<boolean>(false);
    const [isLastNode, setIsLastNode] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [list, setList] = useState<any[]>([]);
    const [getPassThroughParams, setPassThroughParams, passThroughParams] = usePassThroughParams();
    const [initStartSecond, newStartSecond] = useStartSecond();
    const [globalConfig, setGlobalConfig] = useState<any>(null);
    const [referenceWords, setReferenceWords] = useState('');

    const [loadingForTaskStart, setLoadingForTaskStart] = useState(false);
    const [loadingForSendMessage, setLoadingForSendMessage] = useState(false);
    const { flowIndex, flowData, getFlowData } = useTaskFlow(request, task, record);

    const {
        suspendDetail,
        isSuspend,
        isSuspending,
        isLoadSuspendInfo,
        loadingForSuspend,
        loadingForRestart,
        pauseTask,
        continueTask,
        restartTask,
    } = useSuspend(request, task, record, setRecord);

    const startAudioRecordRef = useRef<any>();

    useAnswerTimeout(globalConfig, data, isSuspending);

    /**
     * 针对单题限时使用，我们在没有交互前会自动生成一个缓存数据，此数据不在list中
     * 只有在语音对话开始录音或者文本对话发送消息时才会被添加到列表中去
     */
    const createNextMessage = useCallback((isLastNode, callback?) => {
        let data: any = undefined;
        if (!isLastNode) {
            data = createMessage({
                isSync: 0,
            });
            data.isNotInList = true;
            data.isTimeout = false;
        }
        setData(data);
        fire(callback, data);
    }, []);

    const autoStartAudioRecord = useDebounce(
        (isLastNode, isTimeout) => {
            createNextMessage(isLastNode, (data) => {
                if (task?.dialogueMode === Udesk.enums.learningTaskDialogueModeType.phone.id) {
                    setTimeout(() => startAudioRecordRef.current?.(isTimeout, data), 100);
                }
            });
        },
        [createNextMessage, task?.dialogueMode]
    );

    const stopAudioRecord = useDebounce(() => {
        pauseRecord();
    }, [pauseRecord]);

    const updateDataInfo = useCallback((data, info) => {
        setList((list) => {
            return list.map((item) => {
                if (item.id === data.id) {
                    return $.extend(true, {}, item, info);
                }

                return item;
            });
        });
    }, []);

    // 这个
    // 在失败的情况下，再次点击录音按钮，实际上是重新提交发送，并没有再次录音标识用来指明 sendMessage 是否成功
    const [sendFail, setSendFail] = useState(false);

    const sendMessage = useDebounce(
        (data: any, fileInfo) => {
            const aiDialogContentList = (data.newList || []).slice(
                0,
                (data.newList || []).length - 1
            );

            const params = getPassThroughParams({
                taskId: task?.id, // 任务ID
                dialogueMode: task?.dialogueMode, // 对话类型 1-语音 2-文本 3-ppt
                words: fileInfo?.text, // 用户说的话，如果是语音，需转化为文字
                speakDuration: fileInfo?.duration, // 说话时长 毫秒
                speakTime: data.createDate, // 说话时间
                ossKey: fileInfo?.url, // 语音上传的地址
                isTimeout: data.isTimeout, // 是否超时
                aiDialogContentList,
            });

            request(`/intelligentPartner/${record?.id}/interactive`, params, 'post')
                .then(
                    (resp) => {
                        const { nextNodeList, traineeWordsResult, nodeId, realTimeReview } =
                            resp.data;
                        const newPassThroughParams = {
                            flowId: passThroughParams.flowId, // 对话流程ID，后续交互需要作为参数传入
                        };
                        const audioUrlList = new Array<string>();

                        let isLastNode = false;
                        let newList = [];
                        if (nextNodeList) {
                            newList = nextNodeList.map((item, index) => {
                                // 如果发现机器人对话为最后一个阶段，将自动触发后续的逻辑
                                isLastNode = !!item.isLastNode;
                                // 更新透传参数
                                if (index === nextNodeList.length - 1) {
                                    updatePassThroughParams(newPassThroughParams, item);
                                }
                                // 记录语音链接，为自动播放做准备
                                if (item.ossKey) {
                                    audioUrlList.push(item.ossKey);
                                }

                                return createMessage({
                                    channel: 1,
                                    nodeType: item.nodeType,
                                    content: item.words,
                                    ossKey: item.ossKey,
                                    startSecond: newStartSecond(),
                                });
                            });
                        } else {
                            isLastNode = true;
                        }
                        setList((list) => {
                            if (traineeWordsResult) {
                                list[list.length - 1]['traineeWordsResult'] = traineeWordsResult;
                            }
                            if (realTimeReview) {
                                list[list.length - 1]['realTimeReview'] = realTimeReview;
                            }

                            list[list.length - 1]['nodeId'] = nodeId;
                            return new Array<any>().concat(list, newList);
                        });
                        // 如果存在语音文件，将进行播放
                        if (audioUrlList.length > 0) {
                            audioPlayHandle(...audioUrlList);
                        }
                        if (isLastNode) {
                            setIsLastNode(isLastNode);
                        }
                        autoStartAudioRecord(isLastNode, false);
                        setReferenceWords(resp.data?.referenceWords || ''); // 重置推荐话术
                        setPassThroughParams(newPassThroughParams);
                        updateDataInfo(data, {
                            isSync: 2,
                        });
                        getFlowData();
                        setSendFail(false);
                    },
                    (reason) => {
                        updateDataInfo(data, {
                            isSync: 0,
                        });
                        Udesk.ui.notify.error(reason.errorMsg || /* 发送失败 */UdeskLocales['current'].pages.coach.learningCenter.components.record.voice.hook.index.failInSend);
                        setSendFail(true);
                    }
                )
                .finally(() => {
                    // 这里给一个缓冲
                    setTimeout(() => {
                        setLoadingForSendMessage(false);
                    }, 1000);
                });
        },
        [task?.id, task?.dialogueMode, request, record?.id, audioPlayHandle, autoStartAudioRecord]
    );

    // 文件是否已经暂停，并且已经完成了ASR转译及上传
    const isOver = useMemo(() => {
        console.log('recordList', recordList);
        return recordList.every((item) => {
            if (item.isAsrOver) {
                if (item.text) {
                    return item.isFileUploadOver;
                }
                return true;
            }
            return false;
        });
    }, [recordList]);

    const startAudioRecord = (startAudioRecordRef.current = useDebounce(
        (event, nextMessage?) => {
            const isTimeout = isBoolean(event) ? event : false;
            const message = nextMessage || data;

            if (message) {
                const isNotInList = message.isNotInList;

                message.startSecond = newStartSecond();
                message.isTimeout = isTimeout;

                const newList = [...list];
                if (isNotInList) {
                    setList(() => {
                        const index = newList.findIndex((item) => item.id === message.id);

                        if (index > -1) {
                            newList.splice(index, 1, message);
                        } else {
                            newList.push(message);
                        }
                        return newList;
                    });
                }

                if (isTimeout) {
                    if (isNotInList) {
                        setLoadingForSendMessage(true);
                        sendMessage(
                            { ...message, newList },
                            {
                                text: '',
                                duration: (task?.singleLimitDuration || 0) * 60000,
                                url: '',
                            }
                        );
                    } else {
                        stopAudioRecord();
                    }
                } else {
                    message.isNotInList = false; // 只有开始录音后才认为此消息为管控状态

                    // 在失败的情况下，再次点击录音按钮，实际上是重新提交发送，不再次录音，以免产生多余的录音记录
                    if (sendFail) {
                        coreHandler();
                        return;
                    }

                    startRecord();
                }
            }
        },
        [startRecord, task, data, sendMessage, stopAudioRecord, newStartSecond, sendFail, list]
    ));

    const coreHandler = useCallback(() => {
        const isReady = (isSync, recorder) => {
            return isSync === 0 && recorder.isAsrOver && recorder.isFileUploadOver;
        };
        const isUploadReady = (isSync, recorder) => {
            return isSync === 0 && recorder.isAsrOver && recorder.isFileUploadOver === false;
        };

        if (recordList.length > 0) {
            setList((list) => {
                const newList = [...list];
                getCustomMessageList(list).forEach((item, index) => {
                    const recorder = recordList[index];
                    if (recorder) {
                        item.ossKey = recorder.url || '/';
                        item.content = recorder.text;

                        if (isUploadReady(item.isSync, recorder)) {
                            setLoadingForSendMessage(true);
                            uploadFile(recorder, () => {
                                setLoadingForSendMessage(false);
                            });
                        }

                        if (isReady(item.isSync, recorder)) {
                            setLoadingForSendMessage(true);
                            item.isSync = 1; // 更新状态为正在发送消息中
                            sendMessage({ ...item, newList }, recorder);
                        }
                    }
                });

                return newList;
            });
        }
    }, [recordList, sendMessage, uploadFile]);

    useEffect(() => {
        coreHandler();
    }, [coreHandler]);

    // 开始任务
    const startTaskHandle = useCallback(
        (callback) => {
            if (task) {
                setLoadingForTaskStart(true);
                if (isSuspend) {
                    continueTask((data) => {
                        const { dialogue, lastNodeDetail, globalConfig } = data;
                        const list = new Array<any>();
                        if (dialogue) {
                            list.splice(
                                0,
                                0,
                                ...dialogue.map((item) => {
                                    const {
                                        channel,
                                        nodeType,
                                        content,
                                        ossKey,
                                        startSecond,
                                        traineeWordsResult,
                                        isTimeout,
                                        realTimeReview,
                                    } = item;
                                    const message = createMessage({
                                        channel,
                                        nodeType,
                                        content,
                                        ossKey,
                                        startSecond,
                                    });
                                    message.isTimeout = isTimeout;
                                    if (traineeWordsResult) {
                                        message.isSync = 2;
                                        message.isNotInList = true;
                                        message.traineeWordsResult = {
                                            resultAnalysis: traineeWordsResult,
                                        };
                                    }
                                    if (realTimeReview) {
                                        message.isSync = 2;
                                        message.isNotInList = true;
                                        message.realTimeReview = realTimeReview;
                                    }
                                    return message;
                                })
                            );
                        }
                        setList(list);
                        if (lastNodeDetail) {
                            setPassThroughParams(lastNodeDetail);
                            setReferenceWords(lastNodeDetail.nextProcessNodeWords || ''); // 重置推荐话术
                        }
                        if (globalConfig) {
                            setGlobalConfig(globalConfig);
                        }
                        setIsStartTask(true); //这里标记开始录音
                        getFlowData();
                        let start;
                        setRecord((record) => {
                            const { id, costTime } = record;
                            start = moment().subtract('seconds', costTime);
                            return {
                                id,
                                dialogueStartTime: start.format(DEFAULT_DATE_TIME_FORMAT),
                            };
                        });
                        if (start) {
                            initStartSecond(start.toDate().getTime()); // 初始化任务开始时间
                        }
                        autoStartAudioRecord(false, false);
                    }).finally(() => {
                        setLoadingForTaskStart(false);
                    });
                } else {
                    request(
                        '/intelligentPartner/start',
                        {
                            taskId: task.id,
                            taskType: task.taskType,
                            dialogueMode: Udesk.enums.learningTaskDialogueModeType.voice.id,
                            time: getDateTimeString(),
                            lessonId: task.courseId,
                        },
                        'post'
                    )
                        .then(
                            (resp) => {
                                const globalConfig = resp.data?.trainingWords?.globalConfig;
                                const nextNodeList = resp.data?.trainingWords?.nextNodeList;
                                const passThroughParams = {
                                    flowId: resp.data?.flowId, // 对话流程ID，后续交互需要作为参数传入
                                };
                                const audioUrlList = new Array<string>();

                                if (globalConfig) {
                                    setGlobalConfig(globalConfig);
                                }
                                if (nextNodeList) {
                                    const list = nextNodeList.map((item, index) => {
                                        // 更新透传参数
                                        if (index === nextNodeList.length - 1) {
                                            updatePassThroughParams(passThroughParams, item);
                                        }
                                        // 记录语音链接，为自动播放做准备
                                        if (item.ossKey) {
                                            audioUrlList.push(item.ossKey);
                                        }

                                        return createMessage({
                                            channel: 1,
                                            nodeType: item.nodeType,
                                            content: item.words,
                                            ossKey: item.ossKey,
                                            startSecond: 0,
                                        });
                                    });

                                    setList(list);
                                }
                                // 如果存在语音文件，将进行播放
                                if (audioUrlList.length > 0) {
                                    audioPlayHandle(...audioUrlList);
                                }
                                setReferenceWords(resp.data?.trainingWords?.referenceWords || ''); // 重置推荐话术
                                setIsStartTask(true); //这里标记开始录音
                                setPassThroughParams(passThroughParams);
                                setRecord(resp.data?.record);
                                initStartSecond(); // 初始化任务开始时间
                                autoStartAudioRecord(false, false);
                                fire(callback);
                            },
                            (reason) => {
                                Udesk.ui.notify.error(reason.errorMsg);
                            }
                        )
                        .finally(() => {
                            setLoadingForTaskStart(false);
                        });
                }
            }
        },
        [
            audioPlayHandle,
            autoStartAudioRecord,
            continueTask,
            getFlowData,
            initStartSecond,
            isSuspend,
            request,
            setPassThroughParams,
            setRecord,
            task,
        ]
    );

    const [onGenReferLoading, setOnGenReferLoading] = useState(false);

    const onGenRefer = (regen: boolean = false) => {
        setOnGenReferLoading(true);
        if (aiGenSceneNodeId) {
            postIntelligentPartnerAiReferenceWordsByNodeId(
                {
                    aiDialogContentList: list,
                },
                { segments: { nodeId: aiGenSceneNodeId } }
            )
                .then((res) => {
                    setReferenceWords(res.data || '');
                })
                .finally(() => {
                    setOnGenReferLoading(false);
                });
        }
    };

    return {
        nextMessage: data,
        flowIndex,
        flowData,
        globalConfig,
        referenceWords,
        onGenRefer,
        onGenReferLoading,
        loadingForSendMessage,
        loadingForTaskStart, // 任务开始的loading
        passThroughParams,
        record,
        isStartTask,
        setIsStartTask,
        isLastNode,
        isOver,
        pause: stopAudioRecord,
        list,
        audioRef,
        audioEndedHandle,
        startTaskHandle,
        startAudioRecord,

        suspendDetail,
        isSuspend,
        isSuspending,
        isLoadSuspendInfo,
        loadingForSuspend,
        loadingForRestart,
        pauseTask,
        continueTask,
        restartTask,

        pointOfFirstEntry,
    };
};
