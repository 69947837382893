import React, { FC, memo } from 'react';
import { Button, Card, Checkbox, Icon, Popover } from 'udesk-ui';
import { SortableContainer, SortableElement, SortableHandle, SortEnd } from 'react-sortable-hoc';
import { TableShowFieldsFoundResponse } from 'src/api/types';
import { FieldDisplay } from './hook';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const SortWrapper = styled.div``;

const Item = styled.div`
    padding: 2px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1100;
`;

const SortContainer = SortableContainer(({ children }) => {
    return <SortWrapper>{children}</SortWrapper>;
});

const SortItem = SortableElement(
    ({
        title,
        checked,
        checkboxDisabled,
        onCheckChange,
    }: {
        title?: any;
        checked?: boolean;
        checkboxDisabled?: boolean;
        onCheckChange?: (checked: boolean) => void;
    }) => {
        return (
            <Item>
                <Checkbox
                    checked={checked}
                    disabled={checkboxDisabled}
                    onChange={(e) => onCheckChange?.(e.target.checked)}
                >
                    {title}
                </Checkbox>

                <DragHandle dragDisabled={checkboxDisabled} />
            </Item>
        );
    }
);

const DragHandle = SortableHandle(({ dragDisabled }: { dragDisabled?: boolean }) => (
    <Icon type="list-check" style={{ cursor: dragDisabled ? 'not-allowed' : 'grab' }} />
));

type TemplateProps = {
    fields?: TableShowFieldsFoundResponse[];
    onShowChange?: (fieldCode: string, checked: boolean) => void;
    onSortEnd?: (sort: SortEnd) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { fields, onShowChange, onSortEnd } = props;

    return (
        <Popover
            placement="topRight"
            trigger={['click']}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            content={
                <Card bodyStyle={{ width: 240, padding: 8, maxHeight: 400, overflowY: 'auto' }}>
                    <SortContainer useDragHandle onSortEnd={onSortEnd}>
                        {fields?.map((f, idx) => {
                            return (
                                <SortItem
                                    key={f.fieldCode}
                                    index={idx}
                                    title={f.fieldName}
                                    checked={f.isShow === FieldDisplay.SHOW}
                                    disabled={false}
                                    checkboxDisabled={false}
                                    onCheckChange={(checked) =>
                                        onShowChange?.(f.fieldCode!, checked)
                                    }
                                />
                            );
                        })}
                    </SortContainer>
                </Card>
            }
        >
            <Button>{/* 列控制 */}{UdeskLocales['current'].components.listControl.index.columnControl}</Button>
        </Popover>
    );
};

export default memo(Template);
