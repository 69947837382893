import React from 'react';
import PropTypes from 'prop-types';
const DEFAULT_ANIMATION = "line-scale-pulse-out-rapid";

class LoadingAnimation extends React.Component {
    state = {
        isTimeout: false
    }
    privates = {
        delayTimeoutToken: null
    }
    DefaultAnimationName = DEFAULT_ANIMATION
    componentDidMount() {
        this.privates.delayTimeoutToken = setTimeout(() => {
            this.setState({
                isTimeout: true
            });
        }, this.props.delay);
    }
    componentWillUnmount() {
        clearTimeout(this.privates.delayTimeoutToken);
    }
}

LoadingAnimation.propTypes = {
    classNames: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"]),
    verticalAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
    type: PropTypes.oneOf([
        "indicator",
        "moving-balls",
        "pulse",
        "line-scale-pulse-out",
        "line-scale-pulse-out-rapid",
        "ball-scale-multiple",
        "ball-scale-ripple-multiple",
        "line-spin-fade-loader"]),
    delay: PropTypes.number,
};

LoadingAnimation.defaultProps = {
    classNames: "",
    align: "center",
    verticalAlign: "middle",
    type: DEFAULT_ANIMATION,
    delay: 1000
};

export default LoadingAnimation;