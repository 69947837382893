export default {
  lang: {
    num: 'ลำดับที่',
    total: 'บทความ/ทั้งหมด',
    strip: 'แถบ',
    more: 'มากกว่า',
    chosen: 'เลือกแล้ว',
    cancel: 'ยกเลิกการเลือก',
    bulkOperations: 'การดำเนินการแบทช์',
    import: 'นำเข้า',
  },
};
