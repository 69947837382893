import React from 'react';
import Udesk from 'Udesk';
import TemplateComponentsMyDetailRoute from './route';
import { PageHeader, Radio, Input, Spin, Divider, Modal, Anchor, Button, Icon, Space, Descriptions, Row, Col, Checkbox, Form } from 'udesk-ui';
import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import ClassNames from 'classnames';
import Locales from 'UdeskLocales';
const { TextArea } = Input;
const { Link } = Anchor;
const InfoItem = ({ list = [] }) => {
    let fieldList = [
        { type: Udesk.enums.templateTypeList.task.id, name: Locales.current.pages.admin.templateComponent.list.task, list: [] },
        { type: Udesk.enums.templateTypeList.point.id, name: Locales.current.pages.admin.templateComponent.list.point, list: [] },
        { type: Udesk.enums.templateTypeList.tag.id, name: Locales.current.pages.admin.templateComponent.list.tag, list: [] },
        { type: Udesk.enums.templateTypeList.concept.id, name: Locales.current.pages.admin.templateComponent.list.concept, list: [] },
        { type: Udesk.enums.templateTypeList.info.id, name: Locales.current.pages.admin.templateComponent.list.info, list: [] },
        { type: Udesk.enums.templateTypeList.inter.id, name: Locales.current.pages.admin.templateComponent.list.inter, list: [] },
        { type: Udesk.enums.templateTypeList.multi.id, name: Locales.current.pages.admin.templateComponent.list.muti, list: [] }
    ];
    list.forEach(item => {
        for (let i = 0; i < fieldList.length; i++) {
            if (fieldList[i].type === item.moduleElementType) {
                fieldList[i].list.push(item);
                break;
            }
        }
    });
    return (
        <div>
            {
                fieldList.filter(item => !_isEmpty(item.list)).map(item => {
                    return (
                        <div className='template-components-list-page-detail-updateList-item' key={item.type}>
                            <div className='template-components-list-page-detail-updateList-item-field'>{item.name}：</div>
                            <div className='template-components-list-page-detail-updateList-item-info'>
                                {
                                    !_isEmpty(item.list.filter(items => items.changeType === Udesk.enums.templateTypeStatus.newer.id)) &&
                                    <div className='template-components-list-page-detail-list'>
                                        <div className='template-components-list-page-detail-list-field'>{Locales.current.pages.admin.templateComponent.list.newer}：</div>
                                        <div className='template-components-list-page-detail-list-field'>
                                            {
                                                item.list.filter(items => items.changeType === Udesk.enums.templateTypeStatus.newer.id).map(items => {
                                                    return (
                                                        <Button disabled key={items.id} type='link'>{items.name}</Button>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    !_isEmpty(item.list.filter(items => items.changeType ===  Udesk.enums.templateTypeStatus.add.id)) &&
                                    <div className='template-components-list-page-detail-list'>
                                        <div className='template-components-list-page-detail-list-field'>{Locales.current.pages.admin.templateComponent.list.add}：</div>
                                        <div className='template-components-list-page-detail-list-field'>
                                            {
                                                item.list.filter(items => items.changeType ===  Udesk.enums.templateTypeStatus.add.id).map(items => {
                                                    return (
                                                        <Button disabled key={items.id} type='link'>{items.name}</Button>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    !_isEmpty(item.list.filter(items => items.changeType ===  Udesk.enums.templateTypeStatus.delete.id)) &&
                                    <div className='template-components-list-page-detail-list'>
                                        <div className='template-components-list-page-detail-list-field'>{Locales.current.pages.admin.templateComponent.list.deleteOk}：</div>
                                        <div className='template-components-list-page-detail-list-field'>
                                            {
                                                item.list.filter(items => items.changeType ===  Udesk.enums.templateTypeStatus.delete.id).map(items => {
                                                    return (
                                                        <Button disabled key={items.id} type='link'>{items.name}</Button>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>


    );
};
export default class TemplateComponentsMyDetailTemplate extends TemplateComponentsMyDetailRoute {
    render() {
        let { taskData,
            fieldData,
            tagData,
            interData,
            conceptData,
            infoData,
            tagField,
            infoField,
            conceptField,
            interField,
            fieldMap,
            multiField,
            multiData,
            taskField,
            formRef,
            visible,
            name,
            description,
            editEnable,
            showEnable,
            orgName,
            releaseVersion,
            version,
            releaseStatus,
            logList,
            updateVersion,
            spinLoading,
            loading
        } = this.privates;
        let { actions } = this;
        return (
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    onBack={actions.backToIndexPage}
                    title={this.props.match.params.id ? Locales.current.pages.admin.templateComponent.list.edit : Locales.current.pages.admin.templateComponent.list.add}
                    extra={
                        <Space>
                            <Button type='primary' onClick={actions.primarySumbit}>{Locales.current.pages.admin.templateComponent.list.sumbit}</Button>
                            <Button onClick={actions.backToIndexPage}>{Locales.current.pages.admin.templateComponent.list.cancelName}</Button>
                        </Space>
                    }
                />
                <div className='udesk-qa-web-page-body'>
                    <Spin spinning={spinLoading}>
                        <div className='udesk-qa-web-page-body-root template-components-list-page-detail'>
                            <div className="template-components-list-page-detail-header">
                                <div className='template-components-list-detail-descrt-title'>
                                    <span>{Locales.current.pages.admin.templateComponent.list.infomation}</span>
                                    {/* <Switch defaultChecked /> */}
                                </div>
                                <Form ref={formRef} layout='inline'
                                    initialValues={{
                                        'name': name,
                                        'description': description,
                                        'editEnable': editEnable,
                                        'showEnable': showEnable
                                    }}
                                >
                                    <Descriptions size='small' style={{ marginTop: '16px' }}>
                                        <Descriptions.Item className="template-components-list-detail-input-item" label={Locales.current.pages.admin.templateComponent.list.name} >
                                            <Form.Item name='name' style={{ width: '100%' }} rules={[{ required: true, message: Locales.current.pages.admin.templateComponent.list.nameRequired }, { max: 16, message: Locales.current.pages.admin.templateComponent.list.nameSize }]}>
                                                <Input style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.detailConfig}>
                                            <Form.Item name='showEnable'>
                                                <Radio.Group onChange={actions.showChange}>
                                                    <Radio value={1}>{Locales.current.pages.admin.templateComponent.list.openUp}</Radio>
                                                    <Radio value={0}>{Locales.current.pages.admin.templateComponent.list.openDown}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.nowVersion}>{version}</Descriptions.Item>
                                        <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.orgName}>
                                            {orgName}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.twoEdit}>
                                            <Form.Item name='editEnable'>
                                                <Radio.Group>
                                                    <Radio disabled={!showEnable} value={1}>{Locales.current.pages.admin.templateComponent.list.permissible}</Radio>
                                                    <Radio value={0}>{Locales.current.pages.admin.templateComponent.list.noPermissible}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.lastedVersion}>{releaseVersion}</Descriptions.Item>
                                        <Descriptions.Item className="template-components-list-detail-descrt-item" label={Locales.current.pages.admin.templateComponent.list.descition} span={1}>
                                            <Form.Item name='description' rules={[{ 'max': 250, message: Locales.current.pages.admin.templateComponent.list.descitionSize }]} >
                                                <Input.TextArea />
                                            </Form.Item>
                                        </Descriptions.Item>
                                        {
                                            this.props.match.params.id && <Descriptions.Item label={Locales.current.pages.admin.templateComponent.list.publishStatus}>
                                                <Space>
                                                    <span>
                                                        {
                                                            releaseStatus && Udesk.enums.templateComponentList.find(item => item.id === releaseStatus).name
                                                        }
                                                    </span>
                                                    {
                                                        releaseStatus === 1 && <Button type='link' onClick={actions.goBack}>{Locales.current.pages.admin.templateComponent.list.width}</Button>
                                                    }
                                                </Space>
                                            </Descriptions.Item>
                                        }
                                    </Descriptions>
                                </Form>
                            </div>
                            <div className="template-components-list-page-detail-body">
                                <div className='template-components-list-detail-descrt-title'>
                                    <span>{Locales.current.pages.admin.templateComponent.list.stencilsDetail}</span>
                                </div>
                                <div className='template-components-list-page-detail-body-content'>
                                    <Row>
                                        <Col span={6}>
                                            <div className='template-components-list-page-detail-body-list'>
                                                <Anchor
                                                    showInkInFixed={false}
                                                    affix={true}
                                                    getContainer={() => document.getElementsByClassName('template-components-list-page-detail-body-list')[0]}
                                                    onChange={actions.anchorChange}
                                                >
                                                    <Link title={<div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.task}</div>} />
                                                </Anchor>

                                                {
                                                    !_isEmpty(taskData) && taskData.map(item => {
                                                        return (
                                                            <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': taskField[item.id] })}>
                                                                <Checkbox className='text-slice-name' title={item.name} checked={taskField[item.id]} onChange={actions.onTaskChange.params(item.id)}>{item.name}</Checkbox>
                                                                <div className='template-components-list-page-detail-body-content-color'>
                                                                    <Space>
                                                                        {
                                                                            item.needUpdate ? <Icon type="shengji" className={ClassNames({ 'template-components-list-page-detail-body-content-color': item.updateFlag })} onClick={actions.updateField.params(taskData, item.id, item.updateFlag, 1)} /> : null
                                                                        }
                                                                        <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item.id, 1)} />
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <div className='template-components-list-page-detail-body-list'>
                                                <Anchor
                                                    showInkInFixed={false}
                                                    affix={true}
                                                    getContainer={() => document.getElementsByClassName('template-components-list-page-detail-body-list')[1]}
                                                    onChange={actions.anchorChange}
                                                >
                                                    <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.point}</div>
                                                </Anchor>

                                                {
                                                    !_isEmpty(fieldData) && fieldData.map(item => {
                                                        return (
                                                            <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': fieldMap[item.id] })}>
                                                                <Checkbox className='text-slice-name' title={item.name} checked={fieldMap[item.id]} onChange={actions.fieldChange.params(item.id)}>{item.name}</Checkbox>
                                                                <div className='template-components-list-page-detail-body-content-color'>
                                                                    <Space>
                                                                        {
                                                                            item.needUpdate ? <Icon type="shengji" className={ClassNames({ 'template-components-list-page-detail-body-content-color': item.updateFlag })} onClick={actions.updateField.params(fieldData, item.id, item.updateFlag, 2)} /> : null
                                                                        }
                                                                        <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item.id, 2, item.taskId, item.parentCategoryId)} />
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <div className='template-components-list-page-detail-body-list'>

                                                <Anchor
                                                    showInkInFixed={false}
                                                    affix={true}
                                                    getContainer={() => document.getElementsByClassName('template-components-list-page-detail-body-list')[2]}
                                                    onChange={actions.anchorChange}
                                                >
                                                    <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.muti}</div>
                                                </Anchor>
                                                {
                                                    !_isEmpty(multiData) && multiData.map(item => {
                                                        return (
                                                            <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': multiField[item.id] })}>
                                                                <Checkbox className='text-slice-name' title={item.name} checked={multiField[item.id]} onChange={actions.multiChange.params(item.id)}>{item.name}</Checkbox>
                                                                <div className='template-components-list-page-detail-body-content-color'>
                                                                    <Space>
                                                                        {
                                                                            item.needUpdate ? <Icon type="shengji" className={ClassNames({ 'template-components-list-page-detail-body-content-color': item.updateFlag })} onClick={actions.updateField.params(multiData, item.id, item.updateFlag, 19)} /> : null
                                                                        }
                                                                        <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item.id, 19)} />
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <Anchor
                                                    showInkInFixed={false}
                                                    affix={true}
                                                    getContainer={() => document.getElementsByClassName('template-components-list-page-detail-body-list')[2]}
                                                    onChange={actions.anchorChange}
                                                >
                                                    <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.inter}</div>
                                                </Anchor>

                                                {
                                                    !_isEmpty(interData) && interData.map(item => {
                                                        return (
                                                            <div key={item.id} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': interField[item.id] })}>
                                                                <Checkbox className='text-slice-name' title={item.name} checked={interField[item.id]} onChange={actions.interChange.params(item.id)}>{item.name}</Checkbox>
                                                                <div className='template-components-list-page-detail-body-content-color'>
                                                                    <Space>
                                                                        {
                                                                            item.needUpdate ? <Icon type="shengji" className={ClassNames({ 'template-components-list-page-detail-body-content-color': item.updateFlag })} onClick={actions.updateField.params(interData, item.id, item.updateFlag)} /> : null
                                                                        }
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>

                                        </Col>
                                        <Col span={6}>
                                            <div className='template-components-list-page-detail-body-list'>
                                                <Anchor
                                                    showInkInFixed={false}
                                                    affix={true}
                                                    getContainer={() => document.getElementsByClassName('template-components-list-page-detail-body-list')[3]}
                                                    onChange={actions.anchorChange}
                                                >
                                                    <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.tag}</div>
                                                </Anchor>
                                                {
                                                    !_isEmpty(tagData) && tagData.map(item => {
                                                        return (
                                                            <div key={`tag-${item.id}`} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': tagField[item.id] })}>
                                                                <Checkbox className='text-slice-name' title={item.title} checked={tagField[item.id]} onChange={actions.tagChange.params(item.id)}>{item.title}</Checkbox>
                                                                <div className='template-components-list-page-detail-body-content-color'>
                                                                    <Space>
                                                                        {
                                                                            item.needUpdate ? <Icon type="shengji" className={ClassNames({ 'template-components-list-page-detail-body-content-color': item.updateFlag })} onClick={actions.updateField.params(tagData, item.id, item.updateFlag, 10)} /> : null
                                                                        }
                                                                        <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item.id, 10)} />
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <Anchor
                                                    showInkInFixed={false}
                                                    affix={true}
                                                    getContainer={() => document.getElementsByClassName('template-components-list-page-detail-body-list')[3]}
                                                    onChange={actions.anchorChange}
                                                >
                                                    <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.concept}</div>
                                                </Anchor>

                                                {
                                                    !_isEmpty(conceptData) && conceptData.map(item => {
                                                        return (
                                                            <div key={`cont-${item.id}`} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': conceptField[item.id] })}>
                                                                <Checkbox className='text-slice-name' title={item.name} checked={conceptField[item.id]} onChange={actions.conceptChange.params(item.id)}>{item.name}</Checkbox>
                                                                <div className='template-components-list-page-detail-body-content-color'>
                                                                    <Space>
                                                                        {
                                                                            item.needUpdate ? <Icon type="shengji" className={ClassNames({ 'template-components-list-page-detail-body-content-color': item.updateFlag })} onClick={actions.updateField.params(conceptData, item.id, item.updateFlag)} /> : null
                                                                        }
                                                                        <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item.id, 20)} />
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <Anchor
                                                    showInkInFixed={false}
                                                    affix={true}
                                                    getContainer={() => document.getElementsByClassName('template-components-list-page-detail-body-list')[3]}
                                                    onChange={actions.anchorChange}
                                                >
                                                    <div className="template-components-list-page-detail-body-list-header">{Locales.current.pages.admin.templateComponent.list.info}</div>
                                                </Anchor>

                                                {
                                                    !_isEmpty(infoData) && infoData.map(item => {
                                                        return (
                                                            <div key={`info-${item.id}`} className={ClassNames('template-components-list-page-detail-body-list-content', { 'template-components-list-page-detail-body-list-content-checked': infoField[item.id] })}>
                                                                <Checkbox className='text-slice-name' title={item.name} checked={infoField[item.id]} onChange={actions.infoChange.params(item.id)}>{item.name}</Checkbox>
                                                                <div className='template-components-list-page-detail-body-content-color'>
                                                                    <Space>
                                                                        {
                                                                            item.needUpdate ? <Icon type="shengji" className={ClassNames({ 'template-components-list-page-detail-body-content-color': item.updateFlag })} onClick={actions.updateField.params(infoData, item.id, item.updateFlag)} /> : null
                                                                        }
                                                                        <Icon type="ic-log-line-beizhu" onClick={actions.goInfo.params(item.id, 13)} />
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="template-components-list-page-detail-body">
                                <div className='template-components-list-detail-descrt-title'>
                                    <span>{Locales.current.pages.admin.templateComponent.list.updatedReocrds}</span>
                                </div>
                                <div className='template-components-list-page-detail-body-content'>
                                    {
                                        !_isEmpty(logList) && logList.map(item => {
                                            return (
                                                <div className='template-components-list-page-detail-updateList'>
                                                    <div className='template-components-list-page-detail-updateList-item'>
                                                        <span className='template-components-list-page-detail-updateList-item-field'>{Locales.current.pages.admin.templateComponent.list.version}：</span>
                                                        <span>{item.version}</span>
                                                    </div>
                                                    <div className='template-components-list-page-detail-updateList-item'>
                                                        <span className='template-components-list-page-detail-updateList-item-field'>{Locales.current.pages.admin.templateComponent.list.updateDescription}：</span>
                                                        <span>{item.updateNote}</span>
                                                    </div>
                                                    {
                                                        !_isEmpty(item.itemList) && <InfoItem list={item.itemList} />
                                                    }
                                                    <Divider />
                                                </div>
                                            );
                                        })
                                    }

                                </div>
                            </div>
                            <Modal
                                visible={visible}
                                title={Locales.current.pages.admin.templateComponent.list.submitUpdate}
                                onText={Locales.current.pages.admin.templateComponent.list.submit}
                                onOk={actions.save}
                                onCancel={actions.cancel}
                                confirmLoading={loading}
                            >
                                <div>
                                    <div>{Locales.current.pages.admin.templateComponent.list.updateVersion}：{updateVersion}</div>
                                    <div>
                                        <span>{Locales.current.pages.admin.templateComponent.list.updateExplain}：</span>
                                        <TextArea onChange={actions.explainChange} />
                                    </div>

                                </div>
                            </Modal>
                        </div>

                    </Spin>

                </div>

            </div>
        );
    }
}
