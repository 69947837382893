export default {
  lang: {
    export: 'Eksport',
    successfully: 'Pomyślnie utworzone zadanie eksportu',
    pleaseJumpTo: 'Proszę skoczyć',
    uploadAndDownloadCenter: 'Przesyłanie i pobieranie Center',
    check: 'Widok',
    exportAll: 'Czy chcesz wyeksportować całą zawartość?',
    total: 'Ogółem',
    confirm: 'Dane, potwierdzić eksport?',
    error: 'Ilość danych eksportowych wynosi 0, zmień kryteria filtrowania i spróbuj ponownie',
  },
};
