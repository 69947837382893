import React, { FC } from 'react';
import { CorrectBtn, useCorrectBtn } from './CorrectBtn';
import Text from './Text';
import { PlayBtn } from './PlayBtn';
import styled from 'styled-components';
import { IntelligentPartnerSentence } from 'src/api/types';

const ContentWrapper = styled.div`
    background: ${(props) => (props.align === 'right' ? '#1a6eff' : '#fff')};
    border-radius: 4px 0px 4px 4px;
    padding: 10px 12px;
    color: ${(props) => (props.align === 'right' ? '#fff' : 'rgba(0, 0, 0, 0.85)')};
    font-size: 14px;
    line-height: 22px;
    display: inline-flex;
    float: right;
    position: relative;
`;

const CorrectBtnWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: ${(props) => (props.align === 'right' ? '-60px' : 'inherit')};
    right: ${(props) => (props.align === 'right' ? 'inherit' : '-60px')};
    transform: translateY(-50%);
`;

type ChatBubbleProps = IntelligentPartnerSentence & {
    recordId?: number;
    align: 'right' | 'left';
    showCorrect?: boolean;
    [key: string]: unknown;
};

const ChatBubble: FC<ChatBubbleProps> = (props) => {
    const { content, ossKey, align, showCorrect, recordId, id, ...restProps } = props;

    const { contentText, ...correctBtnProps } = useCorrectBtn({
        content,
        recordId,
        sentenceId: id,
    });

    return (
        <ContentWrapper className="udesk-coach-user-node-content" align={align} {...restProps}>
            {align === 'right' ? (
                <>
                    <Text>{contentText}</Text>
                    <PlayBtn url={ossKey} position={align} />
                </>
            ) : (
                <>
                    <PlayBtn url={ossKey} position={align} />
                    <Text>{contentText}</Text>
                </>
            )}

            {showCorrect && ossKey ? (
                <CorrectBtnWrapper align={align}>
                    <CorrectBtn {...correctBtnProps} />
                </CorrectBtnWrapper>
            ) : null}
        </ContentWrapper>
    );
};

export default ChatBubble;
