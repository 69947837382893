import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';

/* 
!---------------------------------------------------------------
!             react-redux, redux-thunk, redux-saga
!---------------------------------------------------------------

* [react-redux]
https://react-redux.js.org/introduction/quick-start
* [redux-thunk]
https://github.com/reduxjs/redux-thunk#motivation
* [redux-saga]
https://redux-saga.js.org/


【redux典型用途】
    1. 共享全局数据
    2. 组件间传值、交互
    3. 保存状态


【目的】
    1. 以react-redux为核心，
        a)【简化】(声明式、自动化)
        b)【增强】
    2. 遵循最佳实践(redux-toolkit)
        文档：https://redux-toolkit.js.org/introduction/quick-start
    3. 规范开发模型
    


!---------【与组件集成】----------
?) 自动connect组件
    声明reduxReducers：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#6
    自动connect：file:///home/user/Documents/udesk_react/src/udesk/react/udeskify.js#427


?) 详解reduxReducers
*   1. actions和reducers一起声明(combineReducer)，不用分开维护
        示例代码：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#6
        redux reducer示例：https://redux.js.org/basics/reducers/#handling-more-actions
        自定义prepareAction？
        reducer值使用数组，包含两个方法，第一个是reducer方法，第二个是prepareAction方法。
        扩展知识：redux action示例：https://redux.js.org/basics/actions#source-code
*   2. 自动与全局store绑定
        redux示例：https://redux.js.org/api/combinereducers
*   3. 不用自己判断action.type
        redux reducer示例：https://redux.js.org/basics/reducers/#handling-more-actions


?) 支持connect所有参数
*   1. mapStateToProps
*   2. mapDispatchToProps 
        都包含哪些dispatch actions？参见reduxActions
*   3. mergeProps
        react-redux文档：https://react-redux.js.org/api/connect#connect
*   4. connectOptions
        options文档：https://react-redux.js.org/api/connect#options-object
        forwardRef强制为true


?) 扩展属性
*   1. defaultState
        组件的默认state对象，强烈建议使用Object。
        示例：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#12
*   2. reduxActions
        一些额外的常规action（仅派发action），以及thunk actions
        自动与store、dispatch绑定
        示例：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#34
        静态action
        组件props都接收到哪些actions？
            reduxActions + reducerActions
        createAction文档：https://redux-toolkit.js.org/api/createaction/
        扩展知识，Flux标准Actions规范：https://github.com/redux-utilities/flux-standard-action
*   3. reduxWatcher
        【重要】监听其他组件（支持数组），获取其state和actions
        组件监听示例：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-detail/redux.js#20
*   4. mergeState
        自身的state与watcher的state(s)的自定义合并策略，默认使用Object.assign合并。
        file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#27
        file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-detail/redux.js#14
*   5. mergeDispatch
        自身的dispatch(actions)与watcher的dispatch(s)的自定义合并策略，默认使用Object.assign合并。
        file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#30
    6. 每个组件能接收props.dispatch，没有store


?) redux-toolkit最佳实践
*   1. 自动集成redux devTools
        文档：https://redux-toolkit.js.org/api/configureStore
*   2. 自动集成默认Middleware
        开发模式：
            thunk
            redux-immutable-state-invariant（检查state是否被修改？不允许修改state）
            serializable-state-invariant-middleware（检查state和action type是否可序列化？）
                store state需要可序列化：https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
                action需要可序列化：https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants
        生产模式：
            thunk
        文档：https://redux-toolkit.js.org/api/getDefaultMiddleware
*   3. createReducer自动集成`immer`
        reducer示例：https://redux-toolkit.js.org/api/createreducer/#direct-state-mutation
        immer文档：https://immerjs.github.io/immer/docs/introduction
        Performance：https://immerjs.github.io/immer/docs/performance
        再看一组例子：
            immer：file:///home/user/Documents/udesk_react/src/app/models/users/reducer.js#22
            非immer：file:///home/user/Documents/udesk_react/src/app/models/users/reducer.js#6
*   4. createSelector


?) 组件状态容器级别
*   1. 全局级别
        状态容器是全局唯一的，组件的所有实例共享一个状态容器。默认都是全局级别，redux默认思想也都是全局性的。
        演示全局容器：http://localhost:4006/redux/user-list
*   2. 实例级别
        演示实例级别容器：http://localhost:4006/redux/user-list
        为什么需要实例级别容器？
            一个组件在多个页面使用，但有各自的状态。
            示例1：http://udesk.udesk.cn/entry/analysis/ticket/udesk-bi/new/report/agent-report/all-ticket
            示例2：http://udesk.udesk.cn/entry/analysis/ticket/udesk-bi/new/report/agent-group-report/all-ticket
        如何使用？
            很简单，设置props.reduxKey，即可有自己独有的容器。自己负责reduxKey的唯一性。
            reduxKey设置示例：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/template.jsx#26
*   3. 命名空间机制
        状态容器是按命名空间隔离的
        dispatch actions也是按命名空间隔离的，不同实例之间的actions不会串
*   4. state容器自动销毁
        1). 默认行为：全局级别永远不销毁，实例级别自动销毁
        2). 随组件willUnmount生命周期自动销毁
        3). 有例外情况？
            可以设置keepReduxState来打破默认行为。三种级别，优先级递减：
            1. props.keepReduxState
            2. this.keepReduxState
            3. static keepReduxState
            keepReduxState设置示例：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/template.jsx#26
*   5. reduxWatcher，支持全局级别与实例级别
        file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#49
    6. 静态action

?) redux-thunk
*   1. 支持异步action
        thunk文档：https://github.com/reduxjs/redux-thunk#motivation
*   2. 在reduxActions中声明
        特点：返回一个function
        在function中异步触发reducer-action
        示例代码：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#38


?) redux-saga
    1. 支持异步action
    2. 强大的异步调度能力
        1) 串行化
        2) 并行化
        3) cancel
        4) 防抖
        5）节流
        6) folk
        saga文档：https://redux-saga.js.org/
    3. 集成方式：
        1) “语法糖”
        2) 默认三种saga注册方法
        3) 提供基于Promise模型的异步处理支持
        示例代码：file:///home/user/Documents/udesk_react/src/app/models/users/saga.js#3
    4. 只有全局级别，不支持实例级别
        saga不提供移除机制
    5. 演示saga搜索效果
    6. 静态action
        示例代码：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/redux.js#10


?) redux-model模型
    1. 回归redux初衷（非react-redux初衷）
    2. 更大的灵活度
        1. 自己指定容器key
        2. 支持用redux更传统的方式使用
    3. 只有全局级别，不支持实例级别
    4. 支持其他组件通过uniqueKey来引用
    5. 


TODO -------------
TODO 保存页面状态
TODO -------------
*   1. SAVED_STATE_KEYS
        声明式指定要保存状态的key数组
        示例代码：file:///home/user/Documents/udesk_react/src/app/pages/redux-demo/user-list/component.js#6
*   2. 与storageStrategies配合（详细api待定）
        file:///home/user/Documents/udesk_react/src/udesk/react/udeskify.js#132


?) 【目的】
    1. 以react-redux为核心，
        a)【简化】(声明式、自动化)
        b)【增强】
    2. 遵循最佳实践(redux-toolkit)
        文档：https://redux-toolkit.js.org/introduction/quick-start
    3. 规范开发模型
        1) 状态容器隔离，防止污染，防止篡改
        2) action隔离，防止虫洞
        3) 支持watch，引用关系明确。没有watch的容器，获取不到状态
    4. 提高开发效率，实现为最佳实践，未来能整体扩展，保持最佳实践不走形
    

缺点：
1. 是否有值不可靠，需要谨慎判断是否为空
2. 缺乏回调
3. 除非系统启动时自动加载，否则全局数据，每个使用者都需要请求，且缺乏回调。
4. 全局数据适合离线应用、electron、浏览器等场景
    
    extra:
    * 提高开发效率，实现为最佳实践，未来能整体扩展，保持最佳实践不走形
    * hot loading: src/app/index.js
    * 
*/

const defaultMiddleware = getDefaultMiddleware();
const sagaMiddleware = createSagaMiddleware();

/* 为什么使用一个空的reducer？
在这里只为了起到占位符的作用，因为store要求必须传入一个reducer。
因为我们的reducers都是动态加载的，每次加载后都会调用replaceReducer替换成新的的reducer。
所以第一次传入的reducer不起任何作用。 */
const noop = (state, action) => state;
const store = configureStore({
    reducer: noop,
    preloadedState: {},
    middleware: [...defaultMiddleware, sagaMiddleware],
    devTools: true,
    enhancers: [],
});
// store.subscribe(function () {
//     window.console.log(store.getState());
// });

export default store;
export {
    store,
    sagaMiddleware
};