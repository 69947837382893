import React, { useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { isNotEmpty } from 'src/util/core';
import { Button, Drawer, Icon, Timeline } from 'udesk-ui';
import './index.less';

export default React.memo((props: any) => {
    const {data} = props;
    const [visible, setVisible] = useState(false);

    return isNotEmpty(data, () => (
        <>
            <Drawer
                mask={false}
                maskClosable={false}
                visible={visible}
                width={480}
                height="100%"
                getContainer={false}
                onClose={() => setVisible(false)}
                bodyStyle={{
                    padding: 45
                }}
                title={/* 评分记录 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.scoreRecord.index.scoringRecords}
            >
                <Timeline>
                    {
                        data.map((item, index) => {
                            return (
                                <Timeline.Item>
                                    <div className='udesk-qa-react-score-record-item'>
                                        <span>
                                            <Icon 
                                                type={
                                                    [
                                                        Udesk.enums.scoringSourceType.artificial.id,
                                                        Udesk.enums.scoringSourceType.userScoring.id
                                                    ].includes(item.scoreType) ? 'UserOutlined' : 'LaptopOutlined'
                                                } 
                                                antdIcon={true} 
                                            />
                                        </span>
                                        <div>
                                            <div>
                                                <span>{item.scoreUserName}</span>
                                                <span>{index === data.length - 1 ? /* 首次评分 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.scoreRecord.index.firstRating : Udesk.enums.scoringSourceType.getName(item.scoreType)}</span>
                                                <span>{/* 总分 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.scoreRecord.index.totalScore}{index === data.length - 1 ? '' : /* 变更为 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.scoreRecord.index.changeTo}</span>
                                                <span color='blue'>{item.score}</span>
                                            </div>
                                            <span>
                                                {index === data.length - 1 ? item.createTime : item.updateTime}
                                            </span>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            );
                        })
                    }
                </Timeline>
            </Drawer>
            <Button type='link' onClick={() => setVisible(true)}>{/* 评分记录 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.scoreRecord.index.scoringRecords}</Button>
        </>
    )) as any;
});
