import React, { forwardRef } from 'react';
// import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { TicketTextInfoWarp, TicketTextInfoContent, TicketTextInfoContentHeader, RoleTag } from './components';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Space } from 'udesk-ui';
// import './style.scss';
import XssComponent from 'udesk-react/src/components/xss';
import { replaceLineBreak, replaceLink } from 'src/util/text-replace';

const Template = forwardRef((props: any, ref: any) => {
    const locales = Locales['current'];
    const { item, isLastItem, content, itemKey, titleRef } = props;
    const { data = {} } = item;
    return (
        <TicketTextInfoWarp className='audio-call-content-view-custom-text-info-view' key={itemKey}>
            <TicketTextInfoContent isLastItem={isLastItem}>
                <TicketTextInfoContentHeader>
                    <Space>
                        <div style={{ fontWeight: 600 }}>{data.sendUser}</div>
                        <RoleTag sender={data.sender}>{data.sender === 1 ? /* 客户 */locales.components.ticketTextInfo.index.customer : /* 客服 */locales.components.ticketTextInfo.index.customerService}</RoleTag>
                    </Space>
                    {/* <div className='desc'>2021-12-28  19:57:02 回复至Dora Cardenas  收件人：doura.h.cardenas@gmail.com</div>     */}
                    <div className='desc' ref={titleRef}>{`${data.createdTime}${/* 回复至 */locales.components.ticketTextInfo.index.replyTo}${data.recUser} `}</div>
                    <div className='desc'>{`${/* 收件人: */locales.components.ticketTextInfo.index.addressee}${data.recEmail}`}</div>
                </TicketTextInfoContentHeader>
                {/* <div ref={ref}>{content}</div> */}
                <div ref={ref}>
                    <XssComponent
                        value={replaceLineBreak(replaceLink(content))}
                    />
                </div>
            </TicketTextInfoContent>
        </TicketTextInfoWarp>
    );
});
export default React.memo(Template);
