// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostPointTemplatePointsQuery,
    PostPointTemplatePointsBody,
    BaseResponseListInspectionPointFoundResponse,
} from '../../types';

/**
 * 查询质检模板规则列表
 *
 * @export
 * @tags 智能分析-规则模板
 * @link [POST] /pointTemplate/points
 */
export function postPointTemplatePoints(
    data: PostPointTemplatePointsBody
): Promise<BaseResponseListInspectionPointFoundResponse>;
export function postPointTemplatePoints(
    data: PostPointTemplatePointsBody,
    options: ApiOptions<never, PostPointTemplatePointsQuery>
): Promise<BaseResponseListInspectionPointFoundResponse>;
export function postPointTemplatePoints(
    data: PostPointTemplatePointsBody,
    options?: ApiOptions<never, PostPointTemplatePointsQuery>
): Promise<BaseResponseListInspectionPointFoundResponse> {
    return request<PostPointTemplatePointsBody, never, PostPointTemplatePointsQuery>(
        '/pointTemplate/points',
        { ...options, data, method: 'post', __$requestCalleeName: 'postPointTemplatePoints' }
    );
}

export const meta = [
    { tags: ['智能分析-规则模板'], path: '/pointTemplate/points', method: 'post' },
];
