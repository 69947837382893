// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostIntelligentPartnerHomePageStudentPictureWrongPointBody,
    BaseResponseListFallibleWords,
} from '../../../../../types';

/**
 * 员工画像-易错点
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [POST] /intelligentPartner/homePage/student/picture/wrongPoint
 */
export function postIntelligentPartnerHomePageStudentPictureWrongPoint(
    data: PostIntelligentPartnerHomePageStudentPictureWrongPointBody
): Promise<BaseResponseListFallibleWords>;
export function postIntelligentPartnerHomePageStudentPictureWrongPoint(
    data: PostIntelligentPartnerHomePageStudentPictureWrongPointBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListFallibleWords>;
export function postIntelligentPartnerHomePageStudentPictureWrongPoint(
    data: PostIntelligentPartnerHomePageStudentPictureWrongPointBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListFallibleWords> {
    return request<PostIntelligentPartnerHomePageStudentPictureWrongPointBody, never, never>(
        '/intelligentPartner/homePage/student/picture/wrongPoint',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerHomePageStudentPictureWrongPoint',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/student/picture/wrongPoint',
        method: 'post',
    },
];
