import React, { useCallback, useEffect, useRef, useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskLocales from 'UdeskLocales';
import { CommonPage, StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import { EnumSelect } from 'Component/common/enum-component';
import { TableItem } from './components/TableItem';
import { CheckScript, useCheckScript } from './components/CheckScript';
import { WrongQuestionSetProvider } from './context';
import { getIntelligentPartnerMistakeCollections } from 'src/api/intelligentPartnerMistakeCollections';
import { IntelligentPartnerMistakeCollectionFoundResponse } from 'src/api/types';
import { Button, Select } from 'udesk-ui';
import { linkTo } from 'src/util/link';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { getIntelligentPartnerTaskInfosOrganizations } from 'src/api/intelligentPartnerTaskInfos/organizations';
import { getIntelligentPartnerTaskInfosUserFindAll } from 'src/api/intelligentPartnerTaskInfos/user/findAll';
import './style.scss';

const default_pagination = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const Template = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { pageInfo = {}, storePageInfo } = CommonPage.usePageStateMemo();
    // const [mounted, setMounted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<
        IntelligentPartnerMistakeCollectionFoundResponse[]
    >([]);
    const [selectedData, setSelectedData] = useState<any[]>([]);
    const [taskIds, setTaskIds] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [initialValues, setInitialValues] = useState<any>({});

    const tableInstance = useRef<any>(null);

    const { open, ...checkScriptProps } = useCheckScript();
    const showDetail = (record) => {
        open(record.id);
    };
    const tableProps: StandardizeTableProps['table'] = {
        columns: [
            {
                title: /* 错题列表 */ UdeskLocales['current'].pages.coach.learningCenter
                    .wrongQuestionSet.list.index.wrongQuestionList,
                key: 'item',
                width: '100%',
                ellipsis: true,
                render: (value: any, record: any, index: number) => {
                    return (
                        <WrongQuestionSetProvider value={record}>
                            <TableItem
                                order={index + 1}
                                showDetail={showDetail.bind(null, record)}
                            />
                        </WrongQuestionSetProvider>
                    );
                },
            },
        ],
        rowSelectionEnhance: {
            columnWidth: '48px',
            onSelectedRowKeysChange: (keys: any[], items: any[]) => {
                setSelectedData((prev) => {
                    const cache = prev.reduce((p, c) => {
                        p[c.id] = c;
                        return p;
                    }, {});

                    return items.map((i) => {
                        return cache[i.id] ?? i;
                    });
                });
            },
        },
        dataSource,
        pagination: {
            current: pageInfo.pagination?.current ?? default_pagination.current,
            pageSize: pageInfo.pagination?.pageSize ?? default_pagination.pageSize,
            // pageSize: 3,
            total: pageInfo.pagination?.total ?? 0,
        },
        loading,
    };

    const onRequest = (params) => {
        // setMounted(true);
        setLoading(true);
        getIntelligentPartnerMistakeCollections({
            params: {
                ...params.filter,
                pageNum: params.pagination.current || params.pagination.defaultCurrent,
                pageSize: params.pagination.pageSize || params.pagination.defaultPageSize,
            },
        })
            .then((resp) => {
                storePageInfo({
                    ...params,
                    pagination: Object.assign({}, params.pagination, resp.paging),
                });
                setDataSource(resp.data || []);
                // setDataSource(mockData);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const queryParams = new URLSearchParams(props.location.search);
    const [taskId, setTaskId] = useState<any>(
        parseInt(queryParams.get('taskId') as string) || pageInfo?.filter?.taskId
    );
    const [userId, setUserId] = useState<any>(
        parseInt(queryParams.get('userId') as string) || undefined
    );

    useEffect(() => {
        getIntelligentPartnerTaskInfosUserFindAll({
            params: {
                userId: userId || Udesk.data.init.user.id,
            },
        }).then((resp) => {
            setTaskIds(
                resp.data?.map((i) => ({
                    label: i.taskName,
                    value: i.id,
                })) ?? []
            );
            if (Array.isArray(resp.data) && resp.data.length) {
                setTaskId((id) => {
                    if (id) {
                        if (resp.data?.some((item) => item.id === id)) {
                            return id;
                        }
                    }
                    return resp.data?.[0].id;
                });
            } else {
                setTaskId(undefined);
            }
        });
    }, [userId]);

    useEffect(() => {
        getIntelligentPartnerTaskInfosOrganizations().then((resp) => {
            setUsers(resp.data?.users?.map((i) => ({ value: i.id, label: i.realname })) ?? []);
        });
    }, []);

    useEffect(() => {
        tableInstance.current?.filterInstance.formInstance.setFieldsValue({
            taskId,
            userId,
        });
        if (taskId && !initialValues.taskId) {
            setInitialValues({
                ...pageInfo.filter,
                taskId,
                userId,
            });
            tableInstance.current?.filterInstance.formInstance.submit();
        }
    }, [taskId]);

    return (
        <Page
            pageBodyClassName="udesk-qa-web-course-wrong-question-set-list"
            title={
                /* 错题集 */ UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet
                    .list.index.wrongQuestionSet
            }
            // padding={true}
            backGroundGhost={true}
            extra={
                <Button
                    type="primary"
                    disabled={!selectedData.length}
                    onClick={() => {
                        linkTo(
                            props.history,
                            getCurrentRouteName('wrongQuestionSetExercise'),
                            {},
                            {
                                selectedData,
                            }
                        );
                    }}
                >
                    {/* 错题练习 */}
                    {
                        UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.list
                            .index.mispracticeExercises
                    }
                </Button>
            }
        >
            <StandardizeTable.RequestTable
                ref={tableInstance}
                onRequest={onRequest}
                autoRequestOnMount={false}
                table={tableProps}
                filter={{
                    customComponents: {
                        EnumSelect,
                        Select2,
                    },
                    initialValues,
                    fields: [
                        {
                            name: 'taskId',
                            type: 'Select2' as any,
                            label: /* 任务名称 */ UdeskLocales['current'].pages.coach.learningCenter
                                .wrongQuestionSet.list.index.taskName,
                            props: {
                                style: {
                                    width: 250,
                                },
                                onChangeAfter: setTaskId,
                                options: taskIds,
                                optionFilterProp: 'label',
                                showSearch: true,
                            },
                        },
                        {
                            name: 'userId',
                            type: 'Select2' as any,
                            label: /* 员工 */ UdeskLocales['current'].pages.coach.learningCenter
                                .wrongQuestionSet.list.index.staff,
                            forceHidden: !userId,
                            props: {
                                style: {
                                    width: 250,
                                },
                                onChangeAfter: setUserId,
                                options: users,
                                optionFilterProp: 'label',
                            },
                        },
                        {
                            name: 'isAbsorb',
                            label: /* 掌握情况 */ UdeskLocales['current'].pages.coach.learningCenter
                                .wrongQuestionSet.list.index.masteringTheSituation,
                            type: 'EnumSelect' as any,
                            props: {
                                width: 120,
                                enumKey: 'graspingStates',
                                empty: {
                                    label: /* 全部 */ UdeskLocales['current'].pages.coach
                                        .learningCenter.wrongQuestionSet.list.index.whole,
                                },
                            },
                        },
                    ],
                }}
            />
            <CheckScript {...checkScriptProps} />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

const Select2 = React.memo<any>((props) => {
    const { onChangeAfter, onChange, ...other } = props;

    const changeHandle = useCallback(
        (...args) => {
            onChange(...args);
            onChangeAfter(...args);
        },
        [onChange, onChangeAfter]
    );

    return <Select {...other} onChange={changeHandle} />;
});
