import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
import _concat from 'lodash-es/concat';
import UdeskLocales from 'UdeskLocales';
import { getSearchTemplateNoComplianceByTaskId } from 'src/api/search-template/noCompliance/{taskId}';
import { getSystemModule } from 'Udesk/system/subApp';
import { message } from 'udesk-ui';
import { postInspectionDataBatchDelete } from 'src/api/inspectionData/batch/delete';
const DEFAULT_PAGE_SIZE = 10;

class QualityInspectionListIndexComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
        showModel: false,
    };
    privates = {
        storages: {
            taskId: '',
            pageNum: Udesk.config.paging.defaultPageNumber,
            pageSize: DEFAULT_PAGE_SIZE,
            conditionList: [],
            fieldList: [],
            judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
            customJudgeLogic: '',
            keywords: '',
        },
        inspectionData: null,
        total: null,
        filterVisible: false,
        reInspectionModalVisible: false,
        timerToken: null,
        progressValue: '0',
        progressStatus: 1,
        alreadyDoneCount: 0,
        recheckProcessTotal: 0,
        showFieldList: [],
        tableRef: React.createRef(),
        tableDOMRef: React.createRef(),
        reInspectionConfigVisible: false,
        reInspectionType: Udesk.enums.reInspectionTypes.recalcScore.id,
        allPoints: true,
        reInspectionPoints: [],
        currentKeywords: '',
        customFilterList: [],
        customeFilterField: null,
        customeFilterFieldKey: [],
        customDataSourceList: [],

        type: null,
        customId: null,
        customName: '',

        taskStatus: 0,
        selectedRowKeys: [],
        inspectSearchDefaultShowFields: null,
        isWechatQa: false,
        reCheckLoading: false,
        isInformationCollect: false,
        fastFilterConditions: [],
        currentFastFilterConditionIndex: -1,
        columnsManageModalVisible: false,
        originColumnsBack: false,
        asyncModalRequestBack: false,
        loading: false,
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/tasks\/manage\/\d+\/workbench\/quality-inspection-list\/detail\/\d+/i,
                /^\/site\/wechat-qa\/task\/manager\/\d+\/quality-inspection-list\/detail\/\d+/i,
                /^\/site\/information-collection\/manage\/\d+\/workbench\/information-collect-list\/detail\/\d+/i,
            ],
        },
        restoreStrategies: function (value) {
            return (
                value && value.storages && this.props.match.params.taskId === value.storages.taskId
            );
        },
    };

    static computes = {
        _cacheKey: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                let {
                    sdkOptions: {
                        props: { task },
                    },
                } = props;
                let cacheKey = '';
                if (
                    task &&
                    (task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                        task.inspectDataSource ===
                            Udesk.enums.inspectDataSources.realTimeVoiceCall.id)
                ) {
                    cacheKey = `tasks.manage.workbench.quality-inspection-list.${Udesk.enums.inspectDataSources.voiceCall.key}.company-${Udesk.data.init.company.id}.taskId-${task.id}.index`;
                } else if (
                    task &&
                    (task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
                        task.inspectDataSource ===
                            Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
                ) {
                    cacheKey = `tasks.manage.workbench.quality-inspection-list.${Udesk.enums.inspectDataSources.textDialogue.key}.company-${Udesk.data.init.company.id}.taskId-${task.id}.index`;
                } else if (
                    task &&
                    task.inspectDataSource === Udesk.enums.inspectDataSources.taobao.id
                ) {
                    cacheKey = `tasks.manage.workbench.quality-inspection-list.${Udesk.enums.inspectDataSources.taobao.key}.company-${Udesk.data.init.company.id}.taskId-${task.id}.index`;
                }
                return cacheKey;
            },
        ],
        _columns: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                // let {
                //     sdkOptions: {
                //         props: {
                //             task,
                //             callCustom,
                //             imCustom
                //         }
                //     }
                // } = props;
                let columns = [];
                // let inspectSearchDefaultShowFields, orderFields;
                let allFieldList = getAllFieldList(props, privates);

                // if (task && (task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id)) {
                //     if (callCustom) {
                //         if (callCustom.inspectSearchDefaultShowFields) {
                //             inspectSearchDefaultShowFields = callCustom.inspectSearchDefaultShowFields;
                //         }
                //         if (callCustom.inspectSearchOrderFields) {
                //             orderFields = callCustom.inspectSearchOrderFields;
                //         }
                //     }
                // } else if (task && (task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id || task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id)) {
                //     if (imCustom) {
                //         if (imCustom.inspectSearchDefaultShowFields) {
                //             inspectSearchDefaultShowFields = imCustom.inspectSearchDefaultShowFields;
                //         }
                //         if (imCustom.inspectSearchOrderFields) {
                //             orderFields = imCustom.inspectSearchOrderFields;
                //         }
                //     }
                // }

                allFieldList.forEach((item) => {
                    // if (inspectSearchDefaultShowFields && inspectSearchDefaultShowFields instanceof Array && inspectSearchDefaultShowFields.length > 0) {
                    //     let existField = inspectSearchDefaultShowFields.indexOf(item.id);
                    //     checked = (existField === -1) ? false : true;
                    // }
                    // if (orderFields && orderFields instanceof Array && orderFields.length > 0) {
                    //     let existField = orderFields.indexOf(item.id);
                    //     sortable = (existField === -1) ? false : true;
                    // }
                    // sortable = false;  //QA-215,本任务暂时屏蔽掉排序功能
                    if (item.fieldName !== 'inspection_remarks') {
                        let column = {
                            dataIndex: item.id,
                            title: item.label,
                            key: item.id,
                            render: (name, items, index) => {
                                if (name) {
                                    if (name.length > 20) {
                                        let text = name.substring(0, 20) + '...';
                                        return <div title={name}>{text}</div>;
                                    } else {
                                        return <div title={name}>{name}</div>;
                                    }
                                } else {
                                    return name;
                                }
                            },
                        };
                        if (item.fieldName === 'status' && privates.isWechatQa) {
                            column.title = locales.components.pages.qualityInspectionList.status;
                        }
                        columns.push(column);
                    }
                });
                if (allFieldList && !_isEmpty(allFieldList)) {
                    let remarkObj = _find(
                        allFieldList,
                        (current) => current.fieldName === 'inspection_remarks'
                    );
                    if (remarkObj) {
                        let column = {
                            dataIndex: remarkObj.id,
                            title: remarkObj.label,
                            key: remarkObj.id,
                            render: (name, item, index) => {
                                if (name) {
                                    if (name.length > 20) {
                                        let text = name.substring(0, 20) + '...';
                                        return <div title={name}>{text}</div>;
                                    } else {
                                        return <div title={name}>{name}</div>;
                                    }
                                } else {
                                    return name;
                                }
                            },
                        };
                        columns = _concat(columns, column);
                    }
                    // 构建对话关键词列
                    const keywordsFieldName = '_keywords';
                    const column = {
                        dataIndex: keywordsFieldName,
                        title: locales.columns.custom.keywords,
                        key: keywordsFieldName,
                        render: (name, item, index) => {
                            const fieldValue = item[keywordsFieldName];
                            if (fieldValue) {
                                const list = JSON.parse(fieldValue);
                                if (list.length > 0) {
                                    return list.map((value) => {
                                        if (value.length > 20) {
                                            let text = value.substring(0, 20) + '...';
                                            return (
                                                <div
                                                    className="udesk-custom-columns-keywords"
                                                    title={value}
                                                >
                                                    {text}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="udesk-custom-columns-keywords">
                                                    {value}
                                                </div>
                                            );
                                        }
                                    });
                                } else {
                                    return '-';
                                }
                            } else {
                                return '-';
                            }
                        },
                    };
                    // if(!privates.isWechatQa){
                    //     columns = _concat(columns, column);
                    // }
                    columns = _concat(columns, column);
                }
                return columns;
            },
        ],
        _dataList: [
            'privates.inspectionData',
            function ({ props, privates, locales }) {
                let { inspectionData } = privates;
                let fieldValueMaps = [];

                if (
                    inspectionData &&
                    inspectionData.fieldDataList &&
                    inspectionData.fieldDataList.length > 0
                ) {
                    inspectionData.fieldDataList.forEach((item) => {
                        let fieldValueMap = item.fieldValueMap;
                        fieldValueMap.id = item.dataId;
                        fieldValueMaps.push(item.fieldValueMap);
                    });
                }

                return fieldValueMaps;
            },
        ],
        // _fields: ["privates.inspectionData", function ({ props, privates, locales }) {
        //     let allFieldList = getAllFieldList(props, privates);
        //     let list = [];
        //     allFieldList.forEach(field => {
        //         if (field.fieldName !== 'inspection_remarks') {
        //             list = [
        //                 ...list,
        //                 field
        //             ];
        //         }
        //     });

        //     return Udesk.business.fields.buildConditionFields({
        //         fields: list
        //     });
        // }],
        // _fieldValueSettings: ["privates.inspectionData", function ({ props, privates, locales }) {
        //     return config.getFilterComponentSettings();
        // }]
    };

    actions = {
        batchDelete() {
            console.log(this.privates.selectedRowKeys);
            if (!this.privates.selectedRowKeys.length) {
                message.warning(/* 请选择数据 */UdeskLocales['current'].pages.qualityInspectionList.component.pleaseSelectData);
                return;
            }
            postInspectionDataBatchDelete({
                inspectDataIds: this.privates.selectedRowKeys,
                systemModule: getSystemModule(),
            }).then(() => {
                message.success(/* 操作成功 */UdeskLocales['current'].pages.qualityInspectionList.component.operationSuccessful);
                this.actions.reloadAsyncModel({
                    pageSize: DEFAULT_PAGE_SIZE,
                    pageNum: Udesk.config.paging.defaultPageNumber,
                });
            });
        },
        onSelectChange(newSelectedRowKeys) {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
            this.privates.selectedRowKeys = newSelectedRowKeys;
            this.actions.update();
        },
        keywordsChanged(event) {
            this.privates.storages.keywords = event.target.value;
            this.actions.update();
        },
        searchItems() {
            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
            };
            this.actions.reloadAsyncModel(params);
        },
        // 分页请求
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        pageSizeChange(current, pageSize) {
            this.actions.reloadAsyncModel({
                pageSize,
                pageNum: Udesk.config.paging.defaultPageNumber,
            });
        },
        // 筛选弹框
        changeFilterVisible() {
            let { filterVisible } = this.privates;
            this.privates.filterVisible = !filterVisible;
            this.actions.update();
        },
        // 筛选条件
        filterContidionChanged(conditions) {
            this.privates.storages.conditionList = conditions;
        },
        // 筛选自定义逻辑
        changeJudgeStrategy(value) {
            this.privates.storages.judgeStrategy = value;
            if (
                value === Udesk.enums.operatorRuleTypes.all.id ||
                value === Udesk.enums.operatorRuleTypes.any.id
            ) {
                this.privates.storages.customJudgeLogic = '';
            }
            this.actions.update();
        },
        // 筛选完成发送请求
        handleConditionFilter() {
            let { conditionList, judgeStrategy, customJudgeLogic } = this.privates.storages;

            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
                conditionList: this.actions.getQueryConditionList(conditionList).map((item) => {
                    if (item.field.inspectionTaskId) {
                        return {
                            ...item,
                            field: {
                                ...item.field,
                                id: item.field.statusKey,
                            },
                        };
                    } else {
                        return item;
                    }
                }),
                judgeStrategy,
                customJudgeLogic,
            };

            this.actions.changeFilterVisible();
            this.actions.reloadAsyncModel(params);
        },
        // 导出数据
        exportDataList() {
            let { tableRef, isInformationCollect } = this.privates;
            let { conditionList, judgeStrategy, customJudgeLogic, keywords } =
                this.privates.storages;
            let { sdkOptions, match } = this.props;
            let { _columns } = this.privates.computes;
            let { locales } = this;
            let exportTaskFieldCreateRequestList = [],
                showFieldList = [];
            if (tableRef && tableRef.current) {
                showFieldList = tableRef.current.privates.targetKeys;
            }
            // showFieldList = _columns.filter((current) => tableRef.current.privates.targetKeys.includes(current.key) && current.key !== "_keywords").map((item) => {
            //     return item.key;
            // });
            // exportTaskFieldCreateRequestList = _columns.filter((current) => tableRef.current.privates.targetKeys.includes(current.key)).map((item) => {
            //     return {
            //         field: item.key,
            //         label: item.title,
            //     };
            // });

            showFieldList.forEach((key) => {
                let currentColum = _columns.find((current) => current.key === key);
                if (currentColum) {
                    exportTaskFieldCreateRequestList.push({
                        field: currentColum.key,
                        label: currentColum.title,
                    });
                }
            });
            let url = Udesk.business.apiPath.concatApiPath(
                isInformationCollect ? `exps/informationData` : `exps/inspectionData`,
                sdkOptions
            );
            let params = {
                docType: 1,
                taskId: match.params.taskId,
                showFieldList: showFieldList,
                exportTaskFieldCreateRequestList: exportTaskFieldCreateRequestList,
                conditionList: this.actions.getQueryConditionList(conditionList).map((item) => {
                    if (item.field.inspectionTaskId) {
                        return {
                            ...item,
                            field: {
                                ...item.field,
                                id: item.field.statusKey,
                            },
                        };
                    } else {
                        return item;
                    }
                }),
                judgeStrategy: judgeStrategy,
                customJudgeLogic: customJudgeLogic,
                keyword: keywords,
                systemModule: getSystemModule(),
            };

            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.exportSuccessFormat,
                            locales.components.pages.qualityInspectionList.title
                        )
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.exportFailedFormat,
                                locales.components.pages.qualityInspectionList.title
                            )
                    );
                }
            );
        },
        // 组织发请求用的筛选数据
        getQueryConditionList(conditionList) {
            let allFieldList = getAllFieldListFilter(this.props, this.privates);
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map((condition) => {
                        allFieldList.forEach((field) => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            } else {
                                if (field.statusKey && condition.field === field.statusKey) {
                                    condition.field = field;
                                }
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        // 改变显示列
        columnsChange(items) {
            let showFieldList = [];
            if (items && items instanceof Array && items.length > 0) {
                let fields = getAllFieldList(this.props, this.privates);
                showFieldList = items.map((item) => {
                    let existField = fields.find((field) => {
                        return item.name === field.id;
                    });
                    return existField;
                });
            }
            this.privates.showFieldList = showFieldList;
            this.actions.update();
        },
        // 跳转详情
        gotoDetail(item) {
            this.props.sdkOptions.props._keywords = this.privates.storages.keywords;
            let routeOptions = {
                history: this.props.history,
                routeName: this.privates.isWechatQa
                    ? 'wechatQualityInspectionListDetail'
                    : this.privates.isInformationCollect
                    ? 'informationCollectListDetail'
                    : 'qualityInspectionListDetail',
                pathParams: {
                    taskId: this.props.match.params.taskId,
                    id: item.id,
                },
                state: {
                    ...this.privates.storages,
                    type: 'only',
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        // 重新质检设置窗口
        changeReInspectionConfigVisible() {
            let { reInspectionConfigVisible } = this.privates;
            let { conditionList } = this.privates.storages;

            if (!reInspectionConfigVisible && conditionList.length === 0) {
                Udesk.ui.notify.error(this.locales.labels.pleaseSetFilter);
                return;
            }
            if (!this.privates.taskStatus) {
                this.setState({
                    showModel: true,
                });
                return;
            }
            this.privates.reInspectionConfigVisible = !reInspectionConfigVisible;
            this.actions.update();
        },
        //开启任务状态
        handleOk() {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionTasks/' + this.props.match.params.taskId + '/status/1',
                this.props.sdkOptions
            );
            Udesk.ajax.put(url).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                    this.privates.taskStatus = 1;
                    this.actions.changeReInspectionConfigVisible();
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.notifyMessage.submitFailedFormat,
                                ''
                            )
                    );
                }
            );
            this.setState({
                showModel: false,
            });
        },
        handleCancel() {
            this.setState({
                showModel: false,
            });
        },
        // 重新质检
        reInspection() {
            let { reInspectionConfigVisible } = this.privates;
            let { conditionList, judgeStrategy, customJudgeLogic, keywords } =
                this.privates.storages;
            let { sdkOptions, match } = this.props;

            this.privates.reInspectionConfigVisible = !reInspectionConfigVisible;
            this.actions.update();
            this.privates.progressStatus = null;
            let taskId = match.params.taskId;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${taskId}/recheck`,
                sdkOptions
            );
            let reCheckCondition = getReCheckCondition(this);
            if (
                reCheckCondition.reCheckType === Udesk.enums.reInspectionTypes.recheckPoint.id &&
                (!reCheckCondition.inspectionPoints ||
                    reCheckCondition.inspectionPoints.length === 0)
            ) {
                Udesk.ui.notify.error(
                    this.locales.components.pages.qualityInspectionList.pleaseSelectRecheckPoints
                );
                return;
            }
            let params = {
                taskId,
                reCheckCondition,
                conditionList: conditionList,
                judgeStrategy,
                customJudgeLogic,
                keyword: keywords,
                systemModule: getSystemModule(),
            };
            if (this.privates.reCheckLoading) return;
            this.privates.reCheckLoading = true;
            this.actions.update();
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    this.privates.reInspectionModalVisible = true;
                    this.privates.alreadyDoneCount = 0;
                    this.privates.progressValue = 0;
                    this.privates.recheckProcessTotal = resp.data.total;
                    this.privates.progressStatus = Udesk.enums.recheckStatus.waitForExecution.id;
                    // this.privates.timerToken = setInterval(() => {
                    //     this.actions.queryReInspectionProgress(resp.data);
                    // }, 5 * 1000);
                    Udesk.ui.notify.success(
                        /* 开始执行重新质检 */ UdeskLocales['current'].pages.qualityInspectionList
                            .component.startPerformingReQualityInspection
                    );
                    this.privates.reCheckLoading = false;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.reCheckLoading = false;
                    this.actions.update();
                }
            );
        },
        // 结束重新质检
        endReQualityInspection() {
            let { locales } = this;
            let { sdkOptions, match } = this.props;
            let taskId = match.params.taskId;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${taskId}/recheck/stop`,
                sdkOptions
            );
            let params = {
                id: taskId,
            };
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    Udesk.ui.notify.success(
                        locales.components.pages.qualityInspectionList.endReInspectionSuccess
                    );
                    this.actions.closeReInspection();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        // 关闭重新质检窗口
        closeReInspection() {
            this.privates.reInspectionModalVisible = false;
            this.actions.update();
            let timerToken = this.privates.timerToken;
            if (timerToken != null) {
                clearInterval(timerToken);
            }
        },
        // 查询重新质检进度
        queryReInspectionProgress(resp) {
            let { locales } = this;
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${resp.taskId}/recheck/process`,
                sdkOptions
            );
            let params = {
                id: resp.taskId,
                manualTaskId: resp.manualTaskId,
            };
            Udesk.ajax.post(url, params).then(
                (resp, textStatus, jqXHR) => {
                    this.privates.recheckProcessTotal = resp.data.total;
                    this.privates.progressStatus = resp.data.status;
                    // 1未开始，2进行中，3成功，4失败
                    if (resp.data.status === Udesk.enums.recheckStatus.waitForExecution.id) {
                        this.privates.progressValue = 0;
                        this.actions.update();
                    }
                    if (resp.data.status === Udesk.enums.recheckStatus.inExecution.id) {
                        let failCount = resp.data.failCount;
                        let successCount = resp.data.successCount;
                        let total = resp.data.total;
                        if (!failCount) {
                            failCount = 0;
                        }
                        if (!successCount) {
                            successCount = 0;
                        }
                        let progressValue = parseFloat((failCount + successCount) / total) * 100;
                        if (progressValue) {
                            progressValue = progressValue.toFixed(2);
                        }
                        this.privates.progressValue = progressValue;
                        this.privates.alreadyDoneCount = failCount + successCount;
                        this.actions.update();
                    }
                    if (resp.data.status === Udesk.enums.recheckStatus.success.id) {
                        let failCount = resp.data.failCount;
                        let successCount = resp.data.successCount;
                        if (!failCount) {
                            failCount = 0;
                        }
                        if (!successCount) {
                            successCount = 0;
                        }
                        this.privates.progressValue = 100;
                        this.privates.alreadyDoneCount = failCount + successCount;
                        let timerToken = this.privates.timerToken;
                        if (timerToken != null) {
                            clearInterval(timerToken);
                        }
                        this.actions.update();
                    }
                    if (resp.data.status === Udesk.enums.recheckStatus.failed.id) {
                        this.actions.closeReInspection();
                        Udesk.ui.notify.error(
                            locales.components.pages.qualityInspectionList.endReInspectionFailed
                        );
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        reInspectionTypeChanged(reInspectionType) {
            this.privates.reInspectionType = reInspectionType;
            this.actions.update();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy, id, name) {
            this.privates.storages.conditionList = conditionList;
            this.privates.storages.customJudgeLogic = customJudgeLogic;
            this.privates.storages.judgeStrategy = judgeStrategy;
            this.privates.customName = name;
            this.privates.customId = id;

            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
                conditionList: conditionList,
                judgeStrategy,
                customJudgeLogic,
            };
            this.privates.filterVisible = false;
            this.actions.reloadAsyncModel(params);
        },
        getCustomFilterList(value) {
            let { sdkOptions } = this.props;
            let data = {
                type: this.privates.type,
            };
            if (value) {
                data.name = value;
            }
            let url = Udesk.business.apiPath.concatApiPath(`search-template/advanced`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        resolve(resp);
                        this.privates.customFilterList = resp.data;
                        for (let i = 0; i < resp.data.length; i++) {
                            if (resp.data[i].status !== 0) {
                                this.privates.customeFilterField = resp.data[i];
                                break;
                            } else {
                                this.privates.customeFilterField = {};
                            }
                        }
                        this.privates.customeFilterFieldKey = !_isEmpty(
                            this.privates.customeFilterField
                        )
                            ? [`${this.privates.customeFilterField.id}`]
                            : [];
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                this.locales.components.pages.components.listManage.getRecordsFailed
                        );
                        reject(reason);
                    }
                );
            });
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.conditionList.forEach((item) => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach((items) => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label +
                                ' ' +
                                obj[item.operator] +
                                ' ' +
                                str.substring(0, str.length - 1),
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName ||
                                item.value.agentGroupName ||
                                item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <p style={{ marginBottom: '3px' }}>
                        {index + 1}. {item}
                    </p>
                );
            });
        },
        queckListMang() {
            let { locales } = this;
            let { conditionList, judgeStrategy, customJudgeLogic } = this.privates.storages;
            return (
                <div className="custom-filter-info-type">
                    <div className="index-management-custom-filter-Info">
                        <div>
                            {locales.components.common.customFilter.customFilterCondition}：
                            {_isEmpty(conditionList) && locales.components.common.customFilter.none}
                        </div>
                        <div>
                            {!_isEmpty(conditionList) &&
                                this.actions.fieldsFilterChange({
                                    conditionList:
                                        this.actions.getQueryConditionList(conditionList),
                                })}
                        </div>
                    </div>
                    <div>
                        {locales.components.common.customFilter.conditionRule}：
                        <span>
                            {judgeStrategy !== 3 &&
                                Udesk.enums.operatorRuleTypes.find(
                                    (current) =>
                                        current.id ===
                                        (judgeStrategy || Udesk.enums.operatorRuleTypes.all.id)
                                ).name}
                            {judgeStrategy === 3 && customJudgeLogic}
                        </span>
                    </div>
                </div>
            );
        },
        onPointsCheckedChange(points, allPoints) {
            this.privates.reInspectionPoints = points;
            this.privates.allPoints = allPoints;
            this.actions.update();
        },
        cancel() {
            this.privates.filterVisible = false;
            this.actions.update();
        },
        onPopoverVisible(e) {
            this.privates.tableRef.current.triggerColumnsControl(e);
        },
        onConditionFilterSaved(flag, data) {
            if (data) {
                this.privates.customId = data.id;
            }
        },
        onSelectFastFilterCondition(filterCondition, index) {
            const { conditionList, customJudgeLogic, judgeStrategy } = filterCondition;
            this.privates.currentFastFilterConditionIndex = index;
            this.actions.update();
            this.actions.handleConditionApply(conditionList, customJudgeLogic, judgeStrategy);
        },
        onColumnsManageModalVisible() {
            this.privates.columnsManageModalVisible = true;
            this.actions.update();
        },
        onOriginColumnsBack() {
            this.privates.originColumnsBack = true;
            if (this.privates.asyncModalRequestBack) {
                this.privates.loading = false;
            }
            this.actions.update();
        },
        onColumnsManageModalCancel() {
            this.privates.columnsManageModalVisible = false;
            this.actions.update();
        },
        onApply() {
            this.actions.reloadAsyncModel();
        },
        // selectType(value) {
        //     this.privates.storages.type = value;
        //     this.actions.update();
        // },
    };
    init() {
        let { sdkOptions } = this.props;
        let type = Udesk.business.task.formatInspectDataSource(
            sdkOptions.props.task.inspectDataSource
        );
        if (
            [
                Udesk.enums.inspectDataSources.wechatDialogue.id,
                Udesk.enums.inspectDataSources.wechatRadio.id,
            ].includes(type)
        ) {
            this.privates.isWechatQa = true;
        }

        this.privates.isInformationCollect =
            sdkOptions.props.task.taskType === Udesk.enums.taskType.informationCollection.id;
        // if (
        //     sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
        //     sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id
        // ) {
        //     type = Udesk.enums.inspectDataSources.voiceCall.id;
        // } else if (
        //     sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
        //     sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id
        // ) {
        //     type = Udesk.enums.inspectDataSources.textDialogue.id;
        // } else if (
        //     sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.wechatDialogue.id
        // ) {
        //     type = Udesk.enums.inspectDataSources.wechatDialogue.id;
        // }
        this.privates.type = type;
        //初始化筛选条件
        let searchTemplate =
            this.props.location.state ||
            sdkOptions.props.defaultSearchTemplate.find(
                (template) => template.templateType === sdkOptions.props.task.inspectDataSource
            );
        this.privates.storages.conditionList = searchTemplate.conditionList;
        this.privates.storages.customJudgeLogic = searchTemplate.customJudgeLogic;
        this.privates.storages.judgeStrategy = searchTemplate.judgeStrategy;
        this.actions.update();
    }
    //#region Life Cycle
    componentDidMount() {
        let { props } = this;
        this.privates.storages.taskId = this.props.match.params.taskId;
        highlightKeyword(this);
        let {
            sdkOptions: {
                props: { task, callCustom, imCustom },
            },
        } = props;
        let inspectSearchDefaultShowFields = null;

        if (
            task &&
            (task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id)
        ) {
            if (callCustom) {
                if (callCustom.inspectSearchDefaultShowFields) {
                    inspectSearchDefaultShowFields = callCustom.inspectSearchDefaultShowFields;
                }
            }
        } else if (
            task &&
            (task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
                task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
        ) {
            if (imCustom) {
                if (imCustom.inspectSearchDefaultShowFields) {
                    inspectSearchDefaultShowFields = imCustom.inspectSearchDefaultShowFields;
                }
            }
        }
        this.privates.inspectSearchDefaultShowFields = inspectSearchDefaultShowFields;
        getSearchTemplateNoComplianceByTaskId({
            segments: {
                taskId: this.props.match.params.taskId,
            },
        }).then((resp) => {
            this.privates.fastFilterConditions = (resp.data || []).map((i) => ({
                ...i,
                conditionList: i.conditionList.map((c) => ({
                    ...c,
                    field: {
                        ...c.field,
                        statusKey: c.field?.id,
                    },
                })),
            }));
            this.actions.update();
        });
    }
    componentDidUpdate(prevProps) {
        const {
            props: {
                location: { state },
            },
        } = this;
        const {
            location: { state: prevState },
        } = prevProps;
        if (state !== prevState) {
            this.privates.storages.conditionList = state.conditionList;
            this.privates.storages.customJudgeLogic = state.customJudgeLogic;
            this.privates.storages.judgeStrategy = state.judgeStrategy;
            this.actions.reloadAsyncModel();
        }
        highlightKeyword(this);
    }
    componentWillUnmount() {
        let timerToken = this.privates.timerToken;
        if (timerToken != null) {
            clearInterval(timerToken);
        }
    }

    //#endregion
}
function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter((item) => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map((item) => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}
function getAllFieldListFilter(props, privates) {
    let { fieldList } = privates.storages;
    let { sdkOptions } = props;

    let allFieldList = [];

    if (fieldList && fieldList.length > 0) {
        allFieldList = fieldList;
    } else if (sdkOptions.props.fields && sdkOptions.props.fields.length > 0) {
        allFieldList = sdkOptions.props.fields;
    }

    return allFieldList;
}
function getAllFieldList(props, privates) {
    let { inspectionData, isWechatQa, isInformationCollect } = privates;
    let { sdkOptions } = props;

    let allFieldList = [];
    let taskFieldList = [];
    if (isWechatQa || isInformationCollect) {
        taskFieldList = inspectionData?.showFieldList || [];
    } else {
        taskFieldList = inspectionData?.taskFieldList || [];
    }

    if (taskFieldList.length > 0) {
        allFieldList = taskFieldList.filter(Boolean);
    } else if (sdkOptions.props.fields && sdkOptions.props.fields.length > 0) {
        allFieldList = sdkOptions.props.fields.filter(Boolean);
    }

    return allFieldList;
}

function getReCheckCondition(that) {
    let { reInspectionType, reInspectionPoints } = that.privates;
    if (reInspectionType === Udesk.enums.reInspectionTypes.recalcScore.id) {
        return { reCheckType: reInspectionType };
    } else {
        let inspectionPointCategories = [];
        let inspectionPoints = [];
        for (let i = 0; i < reInspectionPoints.length; i++) {
            let firstCategory = reInspectionPoints[i];
            for (let j = 0; j < firstCategory.children.length; j++) {
                let secondCategory = firstCategory.children[j];
                for (let index = 0; index < secondCategory.points.length; index++) {
                    let point = secondCategory.points[index];
                    if (point._checked) {
                        inspectionPoints.push(point.id);
                        if (!inspectionPointCategories.includes(firstCategory.id))
                            inspectionPointCategories.push(firstCategory.id);
                        if (!inspectionPointCategories.includes(secondCategory.id))
                            inspectionPointCategories.push(secondCategory.id);
                    }
                }
            }
        }
        return {
            reCheckType: reInspectionType,
            inspectionPointCategories,
            inspectionPoints,
        };
    }
}

function highlightKeyword(that) {
    const searchContet = that.privates.storages.keywords.trim();
    if (searchContet === '' || that.privates.currentKeywords !== searchContet) return;

    const keywords = searchContet.split(/ +/);
    const tableDOM = that.privates.tableDOMRef.current;
    const keywordsElems = tableDOM.getElementsByClassName('udesk-custom-columns-keywords');
    for (let i = 0; i < keywordsElems.length; i++) {
        Udesk.utils.dom.clearHighlight(keywordsElems[i]);
        keywords.forEach((item) => {
            Udesk.utils.dom.highlightText(keywordsElems[i], item);
        });
    }
}

export default QualityInspectionListIndexComponent;
