import React from 'react';
import { Button, Icon } from 'udesk-ui';
import Locales from 'UdeskLocales';
import './style.scss';

interface LoadMoreProps {
    position: 'top' | 'bottom';
    onClick: () => void;
    loading?: boolean;
}

const getIcon = (position: LoadMoreProps['position']) => {
    return position === 'bottom' ? 'DoubleRightOutlined' : 'DoubleLeftOutlined';
};

const LoadMore: React.FC<LoadMoreProps> = ({ position, onClick, loading }) => {
    const locales = Locales['current'];

    return (
        <Button
            // className='load-more'
            icon={<Icon type={getIcon(position)} antdIcon={true} rotate={90} />}
            type='link'
            size='small'
            onClick={onClick}
            loading={loading}
        >
            {locales.components.common.wechatRecord.loadMoreBtn[position]}
        </Button>
    );
};

export default LoadMore;
