import Udesk from 'Udesk';
import CaseBaseComponent from "./component";
// import _isEmpty from "lodash-es/isEmpty";
import _toString from "lodash-es/toString";
class CaseBaseRoute extends CaseBaseComponent {
    asyncModel() {
        let { sdkOptions, match } = this.props;

        let params = {
            pageNum: 1,
            pageSize: 51,
            taskId: match.params.taskId
        };

        let url1 = Udesk.business.apiPath.concatApiPath(
            `caseLibraryCategorys`,
            sdkOptions
        );

        return new Promise((resolve, reject) => {
            Udesk.ajax.get(url1, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    }
    onAsyncModelResolved(asyncModel) {
        if (asyncModel.data) {
            this.privates.filteredItems = asyncModel.data;
            let list = this.privates.filteredItems.map(item => {
                return {
                    ...item,
                    key: _toString(item.id)
                };
            });
            this.privates.filteredItems = list;
            // if (this.privates.rloadFlag) {
            //     !_isEmpty(list) && (this.privates.activeKey = list[0].key);
            // }
            if(!this.privates.activeKey){
                this.privates.activeKey = list[0].key;
            }
            this.actions.reloadList(this.privates.activeKey);
            this.actions.update();
        }
        return asyncModel;
    }
}

export default CaseBaseRoute;
