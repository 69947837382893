import React, { useCallback, useEffect } from 'react';
import Udesk from 'Udesk';
import { Form, Select, Input } from 'udesk-ui';

export default React.memo((props: any, ref) => {
    const [form] = Form.useForm();
    const {
        data: formData,
        groupDataSource,
        successCallback,
        request,
        scenario = 1,
        locales,
    } = props;

    const submitHandle = useCallback(
        (values) => {
            const { customerWord, seatsWord, ...params } = values;
            params.customerWord = [customerWord];
            params.seatsWord = [seatsWord];
            params.scenario = scenario;

            request('/review/words/accept', params, 'post').then(
                (resp) => {
                    Udesk.ui.notify.success(
                        /* 保存成功 */ locales.pages.semanticIntelligence.wordsMining.dissent
                            .components.pageRightTable.pageRightFrom.savingSucceeded
                    );
                    formData.accept = 1;
                    formData.answer = seatsWord;
                    successCallback();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        [locales, formData, request, scenario, successCallback]
    );

    useEffect(() => {
        formData.form = form;
        form.setFieldsValue({
            callId: formData.callId,
            clusterWordId: formData.id,
            subjectId: formData.mainId,
            dataSource: formData.dataSource,
            inspectDataSource: formData.inspectDataSource,
            customerWord: formData.question,
            seatsWord: formData.answer,
        });
    }, [form, formData]);

    return (
        <div className="page-right-form">
            <Form
                form={form}
                onFinish={submitHandle}
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
            >
                <Form.Item name="callId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="dataSource" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="inspectDataSource" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="clusterWordId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="subjectId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="user-type customer">
                            {/* 客 */}
                            {
                                locales.pages.semanticIntelligence.wordsMining.dissent.components
                                    .pageRightTable.index.passenger
                            }
                        </span>
                    }
                    name="customerWord"
                    rules={[
                        {
                            required: true,
                            message:
                                /* 请输入话术内容 */ locales.pages.semanticIntelligence.wordsMining
                                    .dissent.components.pageRightTable.pageRightFrom
                                    .pleaseEnterTheScriptContent,
                        },
                    ]}
                    required={false}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="user-type">
                            {/* 坐 */}
                            {
                                locales.pages.semanticIntelligence.wordsMining.dissent.components
                                    .pageRightTable.index.sit
                            }
                        </span>
                    }
                    name="seatsWord"
                    rules={[
                        {
                            required: true,
                            message:
                                /* 请输入话术内容 */ locales.pages.semanticIntelligence.wordsMining
                                    .dissent.components.pageRightTable.pageRightFrom
                                    .pleaseEnterTheScriptContent,
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value) {
                                    for (let item of value) {
                                        if (!item) {
                                            return Promise.reject(
                                                /* 请输入话术内容 */ locales.pages
                                                    .semanticIntelligence.wordsMining.dissent
                                                    .components.pageRightTable.pageRightFrom
                                                    .pleaseEnterTheScriptContent
                                            );
                                        }
                                    }
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                    required={false}
                >
                    <Input.TextArea rows={6} maxLength={2000} maxCount={2000} showCount={true} />
                </Form.Item>
                <Form.Item
                    name="groupId"
                    rules={[
                        {
                            required: true,
                            message:
                                /* 请选择收藏夹分组 */ locales.pages.semanticIntelligence
                                    .wordsMining.dissent.components.pageRightTable.pageRightFrom
                                    .pleaseSelectAFavoriteGroup,
                        },
                    ]}
                    wrapperCol={{ offset: 2 }}
                >
                    <Select
                        placeholder={
                            /* 请选择收藏夹分组 */ locales.pages.semanticIntelligence.wordsMining
                                .dissent.components.pageRightTable.pageRightFrom
                                .pleaseSelectAFavoriteGroup
                        }
                        showSearch={true}
                        optionFilterProp="label"
                        options={groupDataSource.map((item) => ({
                            label: item.name,
                            value: item.id,
                        }))}
                    />
                </Form.Item>
            </Form>
        </div>
    );
});
