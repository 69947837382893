import React from 'react';
import UdeskPagination from 'Component/common/udesk-pagination';
// import ClassNames from 'classnames';
import FilterManageComponent from './component';
import { Menu, Space, Button, Icon, Popconfirm } from 'udesk-ui';
import Locales from 'UdeskLocales';
import './style.scss';

const MenuItem = (props) => {
    const locales = Locales['current'];

    const { item, index, onClassificationItemEdit, onClassificationItemDelete } = props;
    return (
        <div className='classification-item ellipsis'>
            <div className='classification-item-content ellipsis'>
                <div className='classification-title ellipsis'>{item.name}</div>
                {item.description && (
                    <div className='classification-description ellipsis' title={item.description}>
                        {item.description}
                    </div>
                )}
            </div>
            <div className='classification-actions'>
                <Space>
                    {item.canEdit !== false && typeof onClassificationItemEdit === 'function' && (
                        <Button
                            onClick={onClassificationItemEdit.bind(null, item, index)}
                            type='text'
                            size='small'
                            tooltip={locales.labels.edit}
                            icon={<Icon type='EditTwoTone' antdIcon={true}></Icon>}
                        ></Button>
                    )}
                    {item.canDelete !== false && typeof onClassificationItemDelete === 'function' && (
                        <Popconfirm
                            onConfirm={onClassificationItemDelete.bind(null, item, index)}
                            title={locales.labels.deletePopconfirmContent}
                            okText={locales.labels.delete}
                            cancelText={locales.labels.cancel}
                        >
                            <Button
                                type='text'
                                tooltip={locales.labels.delete}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                size='small'
                                icon={<Icon type='DeleteTwoTone' antdIcon={true}></Icon>}
                            ></Button>
                        </Popconfirm>
                    )}
                </Space>
            </div>
        </div>
    );
};
const MenuItemContent = React.memo(MenuItem);

export default class FilterManageTemplate extends FilterManageComponent {
    render() {
        let {
            // sdkOptions,
            filterManageTitle,
            allFilterTitle,
        } = this.props;
        let { filters, /*isActionsVisible, totalCount,*/ defaultSearchTemplate } = this.privates;
        let { paging, selectedFilter } = this.privates.storages;
        let { actions, /*locales*/ } = this;
        return (
            <div className='filter-manage-component-container'>
                <div className='filter-manage-component-header'>
                    <span className='filter-manage-component-header-title pull-left'>{filterManageTitle}</span>
                </div>
                <div className='filter-manage-component-body'>
                    {/* <ul className='filter-manage-component-body-has-value'>
                        <li
                            className={ClassNames('filter-manage-item', { active: selectedFilter.id == null })}
                            onClick={actions.changeSelectFilter.params(null, false)}
                        >
                            <span className='filter-manage-item-name pull-left' title={allFilterTitle}>
                                {allFilterTitle}
                            </span>
                            <span className='filter-manage-item-count pull-right'>{totalCount}</span>
                        </li>
                        <li
                            className={ClassNames('filter-manage-item', {
                                active: selectedFilter.id === defaultSearchTemplate.id,
                            })}
                            onClick={actions.changeSelectFilter.params(defaultSearchTemplate, true)}
                        >
                            <span className='filter-manage-item-name pull-left' title={defaultSearchTemplate.name}>
                                {defaultSearchTemplate.name}
                            </span>
                        </li>
                        <If condition={filters && filters.length > 0}>
                            <For each='filter' index='index' of={filters}>
                                <li
                                    key={index}
                                    className={ClassNames('filter-manage-item', {
                                        active: selectedFilter.id === filter.id,
                                    })}
                                    onClick={actions.changeSelectFilter.params(filter, false)}
                                >
                                    <span className='filter-manage-item-name pull-left' title={filter.name}>
                                        {filter.name}
                                    </span>
                                    <If condition={isActionsVisible}>
                                        <span
                                            className='filter-manage-item-action pull-right'
                                            onClick={actions.deleteFilter.params(filter)}
                                        >
                                            {locales.labels.delete}
                                        </span>
                                        <span
                                            className='filter-manage-item-action pull-right'
                                            onClick={actions.editFilter.params(filter)}
                                        >
                                            {locales.labels.edit}
                                        </span>
                                    </If>
                                    <span className='filter-manage-item-count pull-right'>{filter.dataCount}</span>
                                </li>
                            </For>
                        </If>
                    </ul> */}
                    <Menu
                        onSelect={({ item, key, keyPath, selectedKeys, domEvent }) => {
                            if (key === 'all') {
                                actions.changeSelectFilter(null, false);
                            } else if (key === `${defaultSearchTemplate.id}`) {
                                actions.changeSelectFilter(defaultSearchTemplate, true);
                            } else {
                                const item = filters.find((i) => `${i.id}` === key);                                
                                if (item) {
                                    actions.changeSelectFilter(item, false);
                                }
                            }
                        }}
                        selectedKeys={[(`${selectedFilter.id || 'all'}`)]}
                        mode='inline'
                        style={{ width: 240 }}
                    >
                        <Menu.Item key={'all'} title={allFilterTitle}>
                            <MenuItemContent
                                item={{
                                    name: allFilterTitle,
                                    id: null,
                                }}
                                // index={index}
                                // onClassificationItemEdit={onClassificationItemEdit}
                                // onClassificationItemDelete={onClassificationItemDelete}
                            />
                        </Menu.Item>
                        <Menu.Item key={defaultSearchTemplate.id} title={defaultSearchTemplate.name}>
                            <MenuItemContent
                                item={defaultSearchTemplate}
                                // index={index}
                                // onClassificationItemEdit={onClassificationItemEdit}
                                // onClassificationItemDelete={onClassificationItemDelete}
                            />
                        </Menu.Item>
                        {Array.isArray(filters) &&
                            filters.map((filter, index) => {
                                return (
                                    <Menu.Item key={filter.id} title={filter.name}>
                                        <MenuItemContent
                                            item={filter}
                                            index={index}
                                            onClassificationItemEdit={actions.editFilter.params(filter)}
                                            onClassificationItemDelete={actions.deleteFilter.params(filter)}
                                        />
                                    </Menu.Item>
                                );
                            })}
                    </Menu>
                </div>
                <div className='filter-manage-component-footer'>
                    <If condition={filters && filters.length > 0}>
                        {/* <ReactPager
                            enablePageSize={false}
                            showTotal={true}
                            showTotalPages={false}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={0}
                            pageSize={paging.pageSize}
                            total={paging.total}
                            pageNum={paging.pageNum}
                            languageCode={sdkOptions.props.language}
                            pageChanged={actions.pageChanged}
                        /> */}
                        <UdeskPagination
                            current={paging.pageNum}
                            pageSize={paging.pageSize}
                            total={paging.total}
                            simple={true}
                            showSizeChanger={false}
                            onChange={actions.pageChanged}
                        />
                    </If>
                </div>
            </div>
        );
    }
}
