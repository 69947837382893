import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';

class TemplateComponentsListDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        taskData: [],
        fieldCount: [],
        fieldData: [],
        tagData: [],
        infoData: [],
        interData: [],
        conceptData: [],
        multiData: [],
        multiField: {},
        taskField: {},
        tagField: {},
        interField: {},
        conceptField: {},
        infoField: {},
        fieldMap: {},
        moduleStatus: null,
        modulePluginId: null,
        updateFlag: null
    };

    actions = {
        backToIndexPage() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'templateComponentListIndex',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        goInfo(item, type) {
            if (type === Udesk.enums.templateTypeList.task.id) {
                window.open(`/test/task/${item.id}/basic-info/${this.privates.moduleVersionId}/index`);
            } else if (type === Udesk.enums.templateTypeList.point.id) {
                window.open(`/test/task/${item.id}/check-point/${this.privates.moduleVersionId}/index`);
            } else if (type === Udesk.enums.templateTypeList.multi.id) {
                window.open(`/test/task/${item.id}/interactive-recognition/${this.privates.moduleVersionId}/${item.categoryId}/index`);
            } else if (type === Udesk.enums.templateTypeList.tag.id) {
                window.open(`/test/task/${item.id}/semantic-tags/${this.privates.moduleVersionId}/index`);
            } else if (type === Udesk.enums.templateTypeList.info.id) {
                window.open(`/test/task/${item.id}/information-entities/${this.privates.moduleVersionId}/index`);
            } else if (type === Udesk.enums.templateTypeList.concept.id) {
                window.open(`/test/task/${item.id}/concept-word/${this.privates.moduleVersionId}/index`);
            }
            // else if (type === 12) {
            //     window.open(`/test/task/${item.id}/interactive-recognition/${this.privates.moduleVersionId}/${12}/index`);
            // }
        },
        switchChange(checked) {
            if (checked) {
                this.privates.moduleStatus = Udesk.enums.templateModuleStatus.open.id;
            } else {
                this.privates.moduleStatus = Udesk.enums.templateModuleStatus.disabled.id;
            }
            let { sdkOptions } = this.props;
            let myDetail = Udesk.business.apiPath.concatApiPath(
                `inspectionModules/${this.privates.modulePluginId}/status/${this.privates.moduleStatus}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.put(myDetail).then(
                    (resp) => {
                        // Udesk.ui.notify.error(reason.errorMsg);
                        resolve(resp);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );

            });
        },
        getStatus() {
            let { sdkOptions } = this.props;
            let myDetail = Udesk.business.apiPath.concatApiPath(
                `inspectionModules/${this.privates.modulePluginId}/status/${Udesk.enums.templateModuleStatus.update.id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.put(myDetail).then(
                    (resp) => {
                        this.actions.reloadAsyncModel();
                        resolve(resp);
                    },
                    (reason) => {
                        this.actions.reloadAsyncModel();
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );

            });

        },
    };

    //#region Life Cycle
    componentDidMount() { }
    componentWillUnmount() { }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncKey, asyncResult } = parseOptions;
        let { locales } = this;
        if (asyncKey === 'myDetailData') {
            this.privates.taskData = [];
            this.privates.fieldData = [];
            this.privates.tagData = [];
            this.privates.multiData = [];
            this.privates.interData = [];
            this.privates.conceptData = [];
            this.privates.infoData = [];
            if (!_isEmpty(asyncResult.data)) {
                this.privates.name = asyncResult.data.name;
                this.privates.orgName = asyncResult.data.orgName;
                this.privates.description = asyncResult.data.description;
                this.privates.showEnable = asyncResult.data.showEnable === Udesk.enums.templateShowable.true.id ? locales.pages.admin.templateComponent.list.openUp : asyncResult.data.showEnable === Udesk.enums.templateShowable.false.id && locales.pages.admin.templateComponent.list.openDown;
                this.privates.editEnable = asyncResult.data.editEnable === Udesk.enums.templateShowable.true.id ? locales.pages.admin.templateComponent.list.permissible : asyncResult.data.editEnable === Udesk.enums.templateShowable.false.id && locales.pages.admin.templateComponent.list.noPermissible;
                this.privates.version = asyncResult.data.version;
                this.privates.releaseVersion = asyncResult.data.releaseVersion;
                this.privates.releaseStatus = asyncResult.data.releaseStatus;
                this.privates.modulePluginId = asyncResult.data.modulePluginId;
                this.privates.moduleStatus = asyncResult.data.moduleStatus;
                this.privates.logList = asyncResult.data.logList;
                this.privates.moduleVersionId = asyncResult.data.moduleVersionId;
                this.privates.updateFlag = asyncResult.data.updateFlag;
                if (asyncResult.data.moduleElementList && asyncResult.data.moduleElementList.length > 0) {
                    asyncResult.data.moduleElementList.forEach(item => {
                        if (item.type === Udesk.enums.templateTypeList.task.id) {
                            this.privates.taskData.push(item);
                        } else if (item.type === Udesk.enums.templateTypeList.point.id) {
                            this.privates.fieldData.push(item);
                        } else if (item.type === Udesk.enums.templateTypeList.tag.id) {
                            this.privates.tagData.push(item);
                        } else if (item.type === Udesk.enums.templateTypeList.multi.id) {
                            this.privates.multiData.push(item);
                        } else if (item.type === Udesk.enums.templateTypeList.inter.id) {
                            this.privates.interData.push(item);
                        } else if (item.type === Udesk.enums.templateTypeList.concept.id) {
                            this.privates.conceptData.push(item);
                        } else if (item.type === Udesk.enums.templateTypeList.info.id) {
                            this.privates.infoData.push(item);
                        }

                    });
                }
            }
        }
        this.actions.update();
    }
    //#endregion
}

export default TemplateComponentsListDetailComponent;
