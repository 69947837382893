import SmartToolsIndexComponent from './component';

class SmartToolsIndexRoute extends SmartToolsIndexComponent {
    asyncModel(changedParams) {
        // let {} = this.state;
        // let {} = this.privates;

        return ({});
    }

}

export default SmartToolsIndexRoute;
