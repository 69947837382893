import React, { FC, memo } from 'react';
import CustomerTagSelect from 'Component/pages/gong/customer-tag-select';

/**
 * @des
 * 这里，custom-filters 组件在 onChange 的时候会触发自定义的 fieldValue 组件重新加载，导致有请求的组件重新请求 options 而出现值的闪烁现象
 * 保存一个临时 options，让 options 无感知更新，使得重新加载的组件尽量没有闪烁
 */
let data: any = undefined;

const setData = (d: any) => {
    data = d;
};

type TemplateProps = {
    value?: any;
    onChanged?: (v: any) => void;
    attributes?: Record<string, unknown>;
    disabled?: boolean;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChanged, attributes, disabled } = props;

    return (
        <CustomerTagSelect
            {...attributes}
            disabled={disabled}
            value={value ?? undefined}
            onChange={onChanged}
            outerOptions={data}
            setOuterOptions={setData}
        />
    );
};

export default memo(Template);
