import Udesk from 'Udesk';
import React from 'react';
import TaskAssessRulesGatherComponent from './component';
import {  Select, TimePicker, Space, Icon, Tooltip } from 'udesk-ui';
import './style.scss';
import FilterModalEdit from '../../../../pages/tasks/manage/template/automatic-add/filter-modal';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
const Hours = Array.from(Array(24), (v, k) => k);
let getFiltedHours = () => Hours.filter(i => !(i > 1 && i < 23));

export default class TaskAssessRulesGatherTemplate extends TaskAssessRulesGatherComponent {
    render() {
        let { actions, locales, props, state, privates } = this;
        return (
            <div className="task-assess-rules-gather-component">
                <For each="rule" index="index" of={privates.rules}>
                    <If condition={rule}>
                        <div key={rule._id} className="assess-rules-item">
                            <div>
                                {locales.components.pushRulesGather.title + (index + 1) + '：'}
                            </div>

                            <div style={{ padding: 24, backgroundColor: 'rgba(0,0,0,0.03)', width: 598 }}>
                                <Space>
                                    <span>{locales.components.conditionGather.cycle}<Tooltip placement="top" title={locales.business.notifyMessage.timePickerTips}><Icon antdIcon={true} style={{margin: '0 4px'}} type='QuestionCircleOutlined' /></Tooltip>：</span>
                                    <Select
                                        disabled={props.disabled}
                                        value={rule.assessmentConf && rule.assessmentConf.assessmentCycle}
                                        className="assess-rules-gather-component-sampling-frequency"
                                        onChange={actions.onValueChanged.params(rule.assessmentConf, 'assessmentCycle')}>
                                        <For each="item" index="index" of={Udesk.enums.assessFrequencyTypes}>
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        </For>
                                    </Select>
                                    <div className="assess-rules-gather-component-sampling-time-container">
                                        <Space>
                                            <Choose>
                                                <When condition={rule.assessmentConf && rule.assessmentConf.assessmentCycle === Udesk.enums.assessFrequencyTypes.everyWeek.id}>
                                                    <Select
                                                        disabled={props.disabled}
                                                        value={rule.assessmentConf.dayOfWeek}
                                                        onChange={actions.onValueChanged.params(rule.assessmentConf, 'dayOfWeek')}
                                                        className="assess-rules-gather-component-sampling-weekday">
                                                        <For each="item" index="index" of={Udesk.enums.assessFrequencyWeekDays}>
                                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                        </For>
                                                    </Select>
                                                </When>
                                                <When condition={rule.assessmentConf && rule.assessmentConf.assessmentCycle === Udesk.enums.assessFrequencyTypes.everyMonth.id}>
                                                    <Select
                                                        disabled={props.disabled}
                                                        value={rule.assessmentConf.dayOfMonth}
                                                        onChange={actions.onValueChanged.params(rule.assessmentConf, 'dayOfMonth')}
                                                        className="assess-rules-gather-component-sampling-weekday">
                                                        <For each="item" index="index" of={props.monthDays}>
                                                            <Select.Option key={index} value={item}>
                                                                {item}
                                                                {locales.components.pushRulesGather.dateUnit}
                                                            </Select.Option>
                                                        </For>
                                                    </Select>
                                                </When>
                                            </Choose>
                                            <TimePicker
                                                id={'task-assess-rules-gather-' + rule._id}
                                                disabled={props.disabled}
                                                disabledHours={getFiltedHours}
                                                defaultValue={moment(rule.assessmentConf && rule.assessmentConf.executeTime, 'HH:mm:ss')}
                                                format={'HH:mm'}
                                                allowClear={false}
                                                onChange={actions.changeTime.params(rule)}
                                            />
                                        </Space>
                                    </div>
                                    {/* <div className="assess-rules-gather-component-filter-container">
                                    <Button
                                        onClick={actions.changeBaseFilterVisible.params(rule)}>
                                        {locales.components.pushRulesGather.screeningCondition}
                                    </Button>
                                </div> */}
                                    <div className="assess-rules-gather-component-action-type">
                                        <Select
                                            disabled={props.disabled}
                                            value={rule.assessmentConf && rule.assessmentConf.actionType}
                                            onChange={actions.onValueChanged.params(rule.assessmentConf, 'actionType')}>
                                            <For each="item" index="index" of={Udesk.enums.assessActionTypes}>
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            </For>
                                        </Select>
                                    </div>
                                </Space>
                                {
                                    rule.baseFilter && rule.baseFilter.conditionList && rule.baseFilter.conditionList.length ?
                                        <FilterModalEdit
                                            conditionJson={privates.conditionJson[index]}
                                            judgeStrategy={rule.baseFilter.judgeStrategy}
                                            customJudgeLogic={rule.baseFilter.customJudgeLogic}
                                            conditionList={[...rule.baseFilter.conditionList]}
                                            conditionJsonInfo={privates.conditionJsonInfo[index]}
                                            editFilterOhter={actions.editFilterOhter.params(rule)}
                                            fieldsFilterChange={actions.fieldsFilterChange}
                                            disabled={props.disabled}
                                        /> :
                                        <div
                                            className='rule-item-rule-filter-btn'
                                            onClick={actions.changeBaseFilterVisible.params(rule)}>
                                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1" style={{ marginRight: 4 }}></i>
                                            <span>
                                                {locales.components.ruleGather.screeningCondition}
                                            </span>
                                        </div>
                                }
                            </div>
                            <If condition={!props.disabled}>
                                <div
                                    className="assess-rules-gather-component-delete-icon-container"
                                    onClick={actions.deleteRule.params(index)}>
                                    <i style={{ marginRight: 4 }} className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-delete_minus"></i>
                                    {locales.components.pushRulesGather.delete}
                                </div>
                            </If>
                        </div>
                    </If>
                </For>
                <div className="assess-rules-gather-component-add-icon-container">
                    <If condition={!props.disabled}>
                        <span className="assess-rules-gather-component-add-icon" onClick={actions.addRule}>
                            <i style={{ marginRight: 4 }} className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1"></i>
                            {locales.components.pushRulesGather.newRule}
                        </span>
                    </If>
                </div>
                <CustomFilterModal
                    visible={state.baseFilterVisible}
                    cancel={actions.handleConditionCancel}
                    applyAction={actions.handleConditionApply}
                    //条件规则类型
                    judgeStrategy={state.currentRule.baseFilter.judgeStrategy}
                    //自定义规则
                    customJudgeLogic={state.currentRule.baseFilter.customJudgeLogic}
                    //添加的条件
                    conditionList={state.currentRule.baseFilter.conditionList}
                    customName={privates.customName}
                    customId={privates.customId}
                    type={props.type}
                    taskId={props.taskId}
                    taskName={props.taskName}
                    filterList={['f_8', 'f_4', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
                />
                {/* <ReactModal
                    headerClassName="udesk-qa-modal-default-header"
                    title={locales.pages.tasks.manage.template.automaticAdd.index.screeningCondition}
                    visible={state.baseFilterVisible}
                    onCancel={actions.cancelConditionListChanged}
                    footer={false}
                >
                    <div className="bs-modal-base">
                        <div className="content-box">
                            <div className="con-body form-horizontal form-label-left">
                                <CustomFilters
                                    showConditionNumber={true}
                                    allowEmptyConditions={true}
                                    conditions={state.conditionList}
                                    fields={privates.computes._fields}
                                    fieldValueSettings={privates.computes._fieldValueSettings}
                                    theme="bootstrap"
                                    onChanged={actions.conditionListChanged}
                                    fieldSelectorClass="form-control input-sm"
                                    operatorSelectorClass="form-control input-sm"
                                    languageCode={props.sdkOptions.props.language}
                                    fieldNameField="key"
                                    fieldKeyField="key"
                                    fieldTextField="label"
                                    disabled={props.disabled}
                                />
                                <Radio.Group
                                    value={state.judgeStrategy}
                                    disabled={props.disabled}
                                    onChange={actions.onStateChanged.params('judgeStrategy')}>
                                    <For each="item" index="index" of={Udesk.enums.judgeStrategy}>
                                        <Radio key={item.id} value={item.id}>{item.name}</Radio>
                                    </For>
                                </Radio.Group>
                                <If condition={state.judgeStrategy === Udesk.enums.judgeStrategy.custom.id}>
                                    <Input
                                        style={{ margin: '10px 0px' }}
                                        maxLength="255"
                                        value={state.customJudgeLogic}
                                        disabled={props.disabled}
                                        onChange={actions.onStateChanged.params('customJudgeLogic')}
                                    />
                                </If>
                                <div className="form-group action" style={{ textAlign: 'end' }}>
                                    <Space>
                                        <Button
                                            onClick={actions.cancelConditionListChanged}>
                                            {locales.labels.cancel}
                                        </Button>
                                        <If condition={!props.disabled}>
                                            <Button
                                                className="btn primary pull-right save-position-btn"
                                                onClick={actions.confirmConditionListChanged}>
                                                {locales.labels.ok}
                                            </Button>
                                        </If>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactModal> */}
            </div>
        );
    }
}
