import React from 'react';
import Udesk from 'Udesk';
import SpeechRecognitionRoute from './route';
import { Icon, Switch, Button, PageHeader, Select } from 'udesk-ui';
import SelectCallTimer from '../components/select-call-time';
import './style.scss';
import XssComponent from 'udesk-react/src/components/xss';
import FilterModalEdit from '../../../pages/tasks/manage/template/automatic-add/filter-modal';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import FilterModalEditNew from 'Component/pages/components/custom-filter-edit';
import template from '../components/call-text-view/template';
import UdeskLocales from 'UdeskLocales';

export default class SpeechRecognitionTemplate extends SpeechRecognitionRoute {
    render() {
        let { locales, actions, state, privates } = this;
        let {
            asrSwitch,
            asrConfigSwitch,
            asrConfig,
            correctionSwitch,
            correctionConfigSwitch,
            correctionConfig,
            redoCorrectionIds,
            callDateRef,
            // imAsrConfigSwitch,
            // asrModelOrder,
            asrModelSwitch,
            asrLanguageList,
            canSelectAsrLanguage,
            asrModelNew,
        } = this.privates;

        return (
            // <div className="speech-recognition-page">
            <div className="udesk-qa-web-page">
                <If
                    condition={
                        Udesk.data.init.company.enabledCallInspect === 1 ||
                        Udesk.data.init.company.enabledCallInspect === 3
                    }
                >
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={locales.components.pages.speechRecognition.title}
                    />
                    <div className="udesk-qa-web-page-body">
                        <div className="udesk-qa-web-page-body-root speech-recognition-page">
                            <div className="speech-recognition-page-body qa-react-page-body">
                                <If
                                    condition={
                                        Udesk.data.init.user &&
                                        Udesk.data.init.user.hasFeature('sys:voice:view')
                                    }
                                >
                                    <div className="speech-recognition-page-body-title-row">
                                        <div className="speech-recognition-page-body-title">
                                            {locales.components.pages.speechRecognition.asr}
                                        </div>
                                        <div className="speech-recognition-page-body-content">
                                            <Switch
                                                disabled={
                                                    !(
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'sys:voice:edit'
                                                        )
                                                    )
                                                }
                                                checked={asrSwitch}
                                                onChange={actions.onSwitchChanged.params(
                                                    Udesk.enums.speechRecognitionSwitchType.asr.id
                                                )}
                                            />
                                            <span>
                                                <Icon
                                                    type="ic-warn-jinggao"
                                                    className="remind-icon"
                                                />
                                            </span>
                                            <span>
                                                {
                                                    locales.components.pages.speechRecognition
                                                        .asrRemind
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="speech-recognition-page-body-title-row">
                                        <div className="speech-recognition-page-body-title">
                                            {locales.components.pages.speechRecognition.correction}
                                        </div>
                                        <div className="speech-recognition-page-body-content">
                                            <Switch
                                                disabled={
                                                    !(
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'sys:voice:edit'
                                                        )
                                                    )
                                                }
                                                checked={correctionSwitch}
                                                onChange={actions.onSwitchChanged.params(
                                                    Udesk.enums.speechRecognitionSwitchType
                                                        .correction.id
                                                )}
                                            />
                                            <span>
                                                <Icon
                                                    type="ic-warn-jinggao"
                                                    className="remind-icon"
                                                />
                                            </span>
                                            <XssComponent
                                                value={
                                                    locales.components.pages.speechRecognition
                                                        .correctionRemind
                                                }
                                            />
                                        </div>
                                    </div>
                                    <If condition={correctionSwitch}>
                                        <div className="speech-recognition-page-body-config-row">
                                            <div className="speech-recognition-page-body-config-title">
                                                {
                                                    locales.components.pages.speechRecognition
                                                        .redoCorrection
                                                }
                                            </div>
                                            <div className="speech-recognition-page-body-config">
                                                <SelectCallTimer
                                                    ref={callDateRef}
                                                    onDateChanged={actions.onDateChanged}
                                                    recordCount={redoCorrectionIds.length}
                                                    callTimeText={
                                                        locales.components.pages.speechRecognition
                                                            .callTime
                                                    }
                                                    callTimeRemindText={
                                                        locales.components.pages.speechRecognition
                                                            .callTimeRemind
                                                    }
                                                    callRecordCountText={
                                                        locales.components.pages.speechRecognition
                                                            .callRecordCount
                                                    }
                                                ></SelectCallTimer>
                                                <div className="button-list">
                                                    <If
                                                        condition={
                                                            Udesk.data.init.user &&
                                                            Udesk.data.init.user.hasFeature(
                                                                'sys:voice:edit'
                                                            )
                                                        }
                                                    >
                                                        <Button
                                                            type="primary"
                                                            size="small"
                                                            onClick={actions.redoCorrection}
                                                        >
                                                            {
                                                                locales.components.pages
                                                                    .speechRecognition
                                                                    .redoCorrectionSubmit
                                                            }
                                                        </Button>
                                                    </If>
                                                </div>
                                            </div>
                                        </div>
                                    </If>
                                    <div className="speech-recognition-page-body-title-row">
                                        <div className="speech-recognition-page-body-title">
                                            {
                                                locales.components.pages.speechRecognition
                                                    .correctionConfig
                                            }
                                        </div>
                                        <div className="speech-recognition-page-body-content">
                                            <Switch
                                                disabled={
                                                    !(
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'sys:voice:edit'
                                                        )
                                                    )
                                                }
                                                checked={correctionConfigSwitch}
                                                onChange={actions.onConfigSwitchChanged.params(
                                                    Udesk.enums.speechRecognitionSwitchType
                                                        .correction.id
                                                )}
                                            />
                                            <span>
                                                <Icon
                                                    type="ic-warn-jinggao"
                                                    className="remind-icon"
                                                />
                                            </span>
                                            <span>
                                                {
                                                    locales.components.pages.speechRecognition
                                                        .correctionConfigRemind
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <If condition={correctionConfigSwitch}>
                                        <div className="speech-recognition-page-body-config-row">
                                            <div className="speech-recognition-page-body-config-title"></div>
                                            <div className="speech-recognition-page-body-config">
                                                <div
                                                    style={{
                                                        maxHeight: '350px',
                                                        overflowY: 'auto',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    {correctionConfig.conditionList &&
                                                    correctionConfig.conditionList.length ? (
                                                        <FilterModalEdit
                                                            conditionJson={
                                                                privates.conditionJson
                                                                    .correctionConfig
                                                            }
                                                            judgeStrategy={
                                                                correctionConfig.judgeStrategy
                                                            }
                                                            customJudgeLogic={
                                                                correctionConfig.customJudgeLogic
                                                            }
                                                            conditionList={[
                                                                ...correctionConfig.conditionList,
                                                            ]}
                                                            conditionJsonInfo={
                                                                privates.conditionJsonInfo
                                                                    .correctionConfig
                                                            }
                                                            editFilterOhter={actions.editFilterOhter.params(
                                                                correctionConfig,
                                                                'correctionConfig'
                                                            )}
                                                            fieldsFilterChange={
                                                                actions.fieldsFilterChange
                                                            }
                                                            disabled={
                                                                !(
                                                                    Udesk.data.init.user &&
                                                                    Udesk.data.init.user.hasFeature(
                                                                        'sys:voice:edit'
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            className="rule-item-rule-filter-btn"
                                                            onClick={actions.changeBaseFilterVisible.params(
                                                                {
                                                                    ...correctionConfig,
                                                                    _type: 'correctionConfig',
                                                                }
                                                            )}
                                                        >
                                                            <i
                                                                className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1"
                                                                style={{ marginRight: 4 }}
                                                            ></i>
                                                            <span>
                                                                {
                                                                    locales.components.ruleGather
                                                                        .screeningCondition
                                                                }
                                                            </span>
                                                        </div>
                                                    )}
                                                    {/* <CustomFilters
                                                        disabled={!(Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:voice:edit"))}
                                                        ref={instance => this.privates.correctionFiltersInstance = instance}
                                                        allowEmptyConditions={true}
                                                        conditions={correctionConfig.conditionList}
                                                        fields={_fields}
                                                        fieldValueSettings={_fieldValueSettings}
                                                        theme="bootstrap"
                                                        onChanged={actions.filterContidionChanged.params(Udesk.enums.speechRecognitionSwitchType.correction.id)}
                                                        fieldSelectorClass="form-control input-sm"
                                                        operatorSelectorClass="form-control input-sm"
                                                        languageCode={sdkOptions.props.language}
                                                        fieldNameField="key"
                                                        fieldKeyField="key"
                                                        fieldTextField="label"
                                                        showConditionNumber={true}
                                                    />*/}
                                                </div>

                                                {/*<RadioBtn disabled={!(Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:voice:edit"))} classNames="customer-filters-judgeStrategy-radios" itemClassNames="customer-filters-judgeStrategy-radio-item" options={Udesk.enums.operatorRuleTypes} value={correctionConfig.judgeStrategy} onChanged={actions.changeJudgeStrategy.params(Udesk.enums.speechRecognitionSwitchType.correction.id)} />
                                                <If condition={correctionConfig.judgeStrategy === Udesk.enums.operatorRuleTypes.custom.id}>
                                                    <input type="text" disabled={!(Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:voice:edit"))} className="form-control" style={{ width: 300 }} defaultValue={correctionConfig.customJudgeLogic} onChange={actions.mutator("privates.correctionConfig.customJudgeLogic", e => e.target.value)} />
                                                </If> */}
                                                <div className="button-list">
                                                    <If
                                                        condition={
                                                            Udesk.data.init.user &&
                                                            Udesk.data.init.user.hasFeature(
                                                                'sys:voice:edit'
                                                            )
                                                        }
                                                    >
                                                        <Button
                                                            type="primary"
                                                            size="small"
                                                            onClick={actions.saveConfig.params(
                                                                Udesk.enums
                                                                    .speechRecognitionSwitchType
                                                                    .correction.id
                                                            )}
                                                        >
                                                            {locales.labels.save}
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            onClick={actions.resetConfig.params(
                                                                Udesk.enums
                                                                    .speechRecognitionSwitchType
                                                                    .correction.id
                                                            )}
                                                        >
                                                            {locales.labels.reset}
                                                        </Button>
                                                    </If>
                                                </div>
                                            </div>
                                        </div>
                                    </If>
                                    <div className="speech-recognition-page-body-title-row">
                                        <div className="speech-recognition-page-body-title">
                                            {locales.components.pages.speechRecognition.asrConfig}
                                        </div>
                                        <div className="speech-recognition-page-body-content">
                                            <Switch
                                                disabled={
                                                    !(
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'sys:voice:edit'
                                                        )
                                                    )
                                                }
                                                checked={asrConfigSwitch}
                                                onChange={actions.onConfigSwitchChanged.params(
                                                    Udesk.enums.speechRecognitionSwitchType.asr.id
                                                )}
                                            />
                                            <span>
                                                <Icon
                                                    type="ic-warn-jinggao"
                                                    className="remind-icon"
                                                />
                                            </span>
                                            <span>
                                                {
                                                    locales.components.pages.speechRecognition
                                                        .asrConfigRemind
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <If condition={asrConfigSwitch}>
                                        <div className="speech-recognition-page-body-config-row">
                                            <div className="speech-recognition-page-body-config-title"></div>
                                            <div className="speech-recognition-page-body-config">
                                                <div
                                                    style={{
                                                        maxHeight: '350px',
                                                        overflowY: 'auto',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    {asrConfig.conditionList &&
                                                    asrConfig.conditionList.length ? (
                                                        <FilterModalEdit
                                                            conditionJson={
                                                                privates.conditionJson.asrConfig
                                                            }
                                                            judgeStrategy={asrConfig.judgeStrategy}
                                                            customJudgeLogic={
                                                                asrConfig.customJudgeLogic
                                                            }
                                                            conditionList={[
                                                                ...asrConfig.conditionList,
                                                            ]}
                                                            conditionJsonInfo={
                                                                privates.conditionJsonInfo.asrConfig
                                                            }
                                                            editFilterOhter={actions.editFilterOhter.params(
                                                                asrConfig,
                                                                'asrConfig'
                                                            )}
                                                            fieldsFilterChange={
                                                                actions.fieldsFilterChange
                                                            }
                                                            disabled={
                                                                !(
                                                                    Udesk.data.init.user &&
                                                                    Udesk.data.init.user.hasFeature(
                                                                        'sys:voice:edit'
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            className="rule-item-rule-filter-btn"
                                                            onClick={actions.changeBaseFilterVisible.params(
                                                                { ...asrConfig, _type: 'asrConfig' }
                                                            )}
                                                        >
                                                            <i
                                                                className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1"
                                                                style={{ marginRight: 4 }}
                                                            ></i>
                                                            <span>
                                                                {
                                                                    locales.components.ruleGather
                                                                        .screeningCondition
                                                                }
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="button-list">
                                                    <If
                                                        condition={
                                                            Udesk.data.init.user &&
                                                            Udesk.data.init.user.hasFeature(
                                                                'sys:voice:edit'
                                                            )
                                                        }
                                                    >
                                                        <Button
                                                            type="primary"
                                                            size="small"
                                                            onClick={actions.saveConfig.params(
                                                                Udesk.enums
                                                                    .speechRecognitionSwitchType.asr
                                                                    .id
                                                            )}
                                                        >
                                                            {locales.labels.save}
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            onClick={actions.resetConfig.params(
                                                                Udesk.enums
                                                                    .speechRecognitionSwitchType.asr
                                                                    .id
                                                            )}
                                                        >
                                                            {locales.labels.reset}
                                                        </Button>
                                                    </If>
                                                </div>
                                            </div>
                                        </div>
                                    </If>
                                    <div className="speech-recognition-page-body-title-row">
                                        <div className="speech-recognition-page-body-title">{/* ASR语言 */}{UdeskLocales['current'].pages.speechRecognition.template.aSRLanguage}</div>
                                        <div className="speech-recognition-page-body-content">
                                            <Select
                                                value={
                                                    privates.defaultAsrLanguage ||
                                                    asrLanguageList[0]['value']
                                                }
                                                onChange={actions.defaultAsrLanguageChange}
                                                style={{ width: '150px' }}
                                                placeholder={/* 请选择 */UdeskLocales['current'].pages.speechRecognition.template.pleaseSelect}
                                            >
                                                <For each="item" index="index" of={asrLanguageList}>
                                                    <Select.Option
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.desc}
                                                    </Select.Option>
                                                </For>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="speech-recognition-page-body-title-row">
                                        <div className="speech-recognition-page-body-title">
                                            {
                                                locales.components.pages.speechRecognition
                                                    .advancedSettings
                                            }
                                        </div>
                                        <div className="speech-recognition-page-body-content">
                                            <Switch
                                                disabled={
                                                    // true
                                                    !(
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'sys:voice:edit'
                                                        )
                                                    )
                                                }
                                                checked={asrModelSwitch}
                                                onChange={actions.onAsrSwitchChanged.params(
                                                    Udesk.enums.speechRecognitionSwitchType.asr.id
                                                )}
                                            />
                                            <span>
                                                <Icon
                                                    type="ic-warn-jinggao"
                                                    className="remind-icon"
                                                />
                                            </span>
                                            <span>
                                                {
                                                    locales.components.pages.speechRecognition
                                                        .advancedSettingsTip
                                                }
                                            </span>
                                        </div>
                                    </div>

                                    <If condition={asrModelSwitch}>
                                        {/* {JSON.stringify(asrModelOrder)}
                                        <br />
                                        {JSON.stringify(this.privates.asrModelMap)}
                                        <br /> */}
                                        {/* {JSON.stringify(this.privates.asrModelNew)} */}
                                        {asrModelNew.length > 0 &&
                                            asrModelNew.map((item, index) => {
                                                // const params = this.privates.asrModelMap[item];
                                                // const filter = this.privates.asrModel[item];
                                                const params = item.languageType || '';
                                                const filter = item;
                                                console.log(
                                                    'template::asrModelOrder',
                                                    params,
                                                    filter
                                                );
                                                return (
                                                    <div className="speech-recognition-page-body-config-row">
                                                        <div className="speech-recognition-page-body-config-title"></div>
                                                        <div className="speech-recognition-page-body-config">
                                                            <div>{/* 模型 */}{UdeskLocales['current'].pages.speechRecognition.template.model}{index + 1}</div>
                                                            <div className="speech-recognition-page-body-config-item">
                                                                <br />
                                                                <Select
                                                                    value={item.languageType}
                                                                    placeholder={/* 请选择语言 */UdeskLocales['current'].pages.speechRecognition.template.pleaseSelectLanguage}
                                                                    style={{ width: '200px' }}
                                                                    onChange={actions.asrLanguageChange.bind(
                                                                        null,
                                                                        index
                                                                    )}
                                                                >
                                                                    <For
                                                                        each="template"
                                                                        index="index"
                                                                        of={canSelectAsrLanguage}
                                                                    >
                                                                        <Select.Option
                                                                            disabled={
                                                                                template.disabled ||
                                                                                false
                                                                            }
                                                                            key={template.value}
                                                                            value={template.value}
                                                                        >
                                                                            {template.desc}
                                                                        </Select.Option>
                                                                    </For>
                                                                </Select>
                                                                <div
                                                                    style={{
                                                                        maxHeight: '350px',
                                                                        overflowY: 'auto',
                                                                        marginBottom: '5px',
                                                                    }}
                                                                >
                                                                    <FilterModalEditNew
                                                                        judgeStrategy={
                                                                            filter.judgeStrategy
                                                                        }
                                                                        customJudgeLogic={
                                                                            filter.customJudgeLogic
                                                                        }
                                                                        conditionList={[
                                                                            ...(filter.conditionList ||
                                                                                []),
                                                                        ]}
                                                                        onClick={actions.onNewFilterModalEditClick.params(
                                                                            params,
                                                                            filter,
                                                                            index
                                                                        )}
                                                                        disabled={
                                                                            !(
                                                                                Udesk.data.init
                                                                                    .user &&
                                                                                Udesk.data.init.user.hasFeature(
                                                                                    'sys:voice:edit'
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="button-list">
                                                                    <If
                                                                        condition={
                                                                            Udesk.data.init.user &&
                                                                            Udesk.data.init.user.hasFeature(
                                                                                'sys:voice:edit'
                                                                            )
                                                                        }
                                                                    >
                                                                        <Button
                                                                            type="primary"
                                                                            size="small"
                                                                            onClick={actions.saveAsrModelConfig.params(
                                                                                params,
                                                                                item,
                                                                                index
                                                                            )}
                                                                        >
                                                                            {locales.labels.save}
                                                                        </Button>
                                                                        {/* <Button
                                                                    size='small'
                                                                    onClick={actions.resetConfig.params(params)}
                                                                >
                                                                    {locales.labels.reset}
                                                                </Button> */}
                                                                    </If>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="speech-recognition-page-body-config-item-delete-btn">
                                                            <Button
                                                                onClick={actions.deleteModel.bind(
                                                                    null,
                                                                    index
                                                                )}
                                                                type="link"
                                                            >{/* 删除 */}{UdeskLocales['current'].pages.speechRecognition.template.delete}</Button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        <div style={{ marginLeft: '100px' }}>
                                            {asrModelNew.length < canSelectAsrLanguage.length && (
                                                <Button type="link" onClick={actions.addModel}>
                                                    <i className="udesk-qa-list-btn-icon udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1"></i>{/* 新建ASR模型 */}{UdeskLocales['current'].pages.speechRecognition.template.newASRModel}</Button>
                                            )}
                                        </div>
                                    </If>
                                    {/* <If condition={imAsrConfigSwitch}>
                                        <div className="speech-recognition-page-body-title-row">
                                            <div className="speech-recognition-page-body-title">
                                                {locales.components.pages.speechRecognition.imAsrConfig}
                                            </div>
                                            <div className="speech-recognition-page-body-content">
                                                <Switch disabled={!(Udesk.data.init.user && Udesk.data.init.user.hasFeature("sys:voice:edit"))} checked={asrConfigSwitch} onChange={actions.onConfigSwitchChanged.params(Udesk.enums.speechRecognitionSwitchType.asr.id)} />
                                                <span><Icon type="ic-warn-jinggao" className="remind-icon" /></span>
                                                <span>{locales.components.pages.speechRecognition.imAsrConfigRemind}</span>
                                            </div>
                                        </div>
                                    </If> */}
                                </If>
                            </div>
                        </div>
                        <CustomFilterModal
                            visible={state.baseFilterVisible}
                            cancel={actions.handleConditionCancel}
                            applyAction={actions.handleConditionApply.params(
                                state.currentRule._type
                            )}
                            //条件规则类型
                            judgeStrategy={state.currentRule.judgeStrategy}
                            //自定义规则
                            customJudgeLogic={state.currentRule.customJudgeLogic}
                            //添加的条件
                            conditionList={state.currentRule.conditionList}
                            customName={privates.customName}
                            customId={privates.customId}
                            type={privates.type}
                            // taskId={props.taskId}
                            // taskName={props.taskName}
                            querryType={'speechRecognition'}
                            filterList={['f_8', 'f_4', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
                        />
                    </div>
                </If>
            </div>
        );
    }
}
