import React from 'react';
import Udesk from 'Udesk';
import AdminSystemIndexRoute from './route';
import { PageHeader, Button, Select, Input, Switch, Icon, Tooltip } from 'udesk-ui';
import { isCoach } from 'Udesk/system/subApp';
import { inspect } from 'src/util/core';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

export default class AdminSystemIndexTemplate extends AdminSystemIndexRoute {
    render() {
        let { actions, locales, privates } = this;
        return (
            // <div className="page admin-system-index">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.pages.admin.system.index.name}
                />
                <div className="udesk-qa-web-page-body">
                    <div
                        className="udesk-qa-web-page-body-root qa-page-admin-systom-index"
                        style={{ padding: 16 }}
                    >
                        <div className="page-content">
                            <div className="x_panel clearfix">
                                <div className="x_content clearfix">
                                    <div className="col-lg-9 col-md-9 col-xs-12 col-12">
                                        <If
                                            condition={
                                                Udesk.data.init.user &&
                                                Udesk.data.init.user.hasFeature('sys:info:view')
                                            }
                                        >
                                            <form className="form-horizontal form-label-left">
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                        htmlFor="system-name"
                                                    >
                                                        {
                                                            locales.pages.admin.system.index
                                                                .systemName
                                                        }
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <input
                                                            type="text"
                                                            id="system-name"
                                                            className="form-control"
                                                            value={privates.model.sysConfig.sysName}
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                            onChange={actions.onValueChanged.params(
                                                                privates.model.sysConfig,
                                                                'sysName'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                        style={{ padding: '0 15px' }}
                                                    >
                                                        {locales.pages.admin.system.index.lang}
                                                    </label>
                                                    <div
                                                        className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                        style={{ height: 30, lineHeight: '30px' }}
                                                    >
                                                        <Select
                                                            defaultValue={
                                                                Udesk.data.init.userLanguage
                                                            }
                                                            disabled={
                                                                !Udesk.data.init.user ||
                                                                !Udesk.data.init.user.hasFeature(
                                                                    'sys:info:edit'
                                                                )
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'zh',
                                                                    label: locales.pages.admin
                                                                        .system.index.zh,
                                                                },
                                                                {
                                                                    value: 'en',
                                                                    label: locales.pages.admin
                                                                        .system.index.en,
                                                                },
                                                                {
                                                                    value: 'tw',
                                                                    label: locales.pages.admin
                                                                        .system.index.tw,
                                                                },
                                                                {
                                                                    value: 'fr',
                                                                    label: /* 法语 */UdeskLocales['current'].pages.admin.system.coachTemplate.french,
                                                                },
                                                                {
                                                                    value: 'es',
                                                                    label: /* 西班牙语 */UdeskLocales['current'].pages.admin.system.coachTemplate.spanish,
                                                                },
                                                                {
                                                                    value: 'ja',
                                                                    label: /* 日语 */UdeskLocales['current'].pages.admin.system.coachTemplate.japanese,
                                                                },
                                                                {
                                                                    value: 'th',
                                                                    label: /* 泰语 */UdeskLocales['current'].pages.admin.system.coachTemplate.thai,
                                                                },
                                                            ]}
                                                            onChange={actions.onUserLanguageChange}
                                                            style={{
                                                                width: 200,
                                                            }}
                                                        ></Select>
                                                    </div>
                                                </div>
                                                {inspect(isCoach(), () => (
                                                    <React.Fragment>
                                                        <div className="form-group">
                                                            <label
                                                                className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                                style={{ padding: '0 15px' }}
                                                            >
                                                                {
                                                                    /* 登录弹窗 */ UdeskLocales[
                                                                        'current'
                                                                    ].pages.admin.system
                                                                        .coachTemplate
                                                                        .loginPopupWindow
                                                                }
                                                                <Tooltip
                                                                    title={
                                                                        /* 开启后会在进入智能陪练系统时进行弹窗提示 */ UdeskLocales[
                                                                            'current'
                                                                        ].pages.admin.system
                                                                            .coachTemplate
                                                                            .afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem
                                                                    }
                                                                >
                                                                    <Icon
                                                                        type="QuestionCircleOutlined"
                                                                        antdIcon={true}
                                                                    ></Icon>
                                                                </Tooltip>
                                                            </label>
                                                            <div
                                                                className="col-lg-7 col-md-7 col-xs-12 col-12"
                                                                style={{
                                                                    height: 30,
                                                                    lineHeight: '30px',
                                                                }}
                                                            >
                                                                <Switch
                                                                    checked={
                                                                        !!privates.model.sysConfig
                                                                            .enabledLoginBulletBox
                                                                    }
                                                                    disabled={
                                                                        !Udesk.data.init.user ||
                                                                        !Udesk.data.init.user.hasFeature(
                                                                            'sys:info:edit'
                                                                        )
                                                                    }
                                                                    onChange={actions.onSwitchValueChangedWithEffect.params(
                                                                        actions.onSwitchValueChanged,
                                                                        undefined,
                                                                        privates.model.sysConfig,
                                                                        'enabledLoginBulletBox'
                                                                    )}
                                                                ></Switch>
                                                            </div>
                                                        </div>
                                                        {!!privates.model.sysConfig
                                                            .enabledLoginBulletBox && (
                                                            <>
                                                                <div className="form-group">
                                                                    <label
                                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                                        style={{
                                                                            padding: '0 15px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            /* 标题 */ UdeskLocales[
                                                                                'current'
                                                                            ].pages.admin.system
                                                                                .coachTemplate.title
                                                                        }
                                                                    </label>
                                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                                        <Input
                                                                            placeholder={
                                                                                DEFAULT_TITLE
                                                                            }
                                                                            defaultValue={
                                                                                privates.model
                                                                                    .sysConfig
                                                                                    .loginBulletBox
                                                                                    .title
                                                                            }
                                                                            onChange={actions.onValueChanged.params(
                                                                                privates.model
                                                                                    .sysConfig
                                                                                    .loginBulletBox,
                                                                                'title'
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                                        style={{
                                                                            padding: '0 15px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            /* 正文 */ UdeskLocales[
                                                                                'current'
                                                                            ].pages.admin.system
                                                                                .coachTemplate.text
                                                                        }
                                                                    </label>
                                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                                        <Input.TextArea
                                                                            placeholder={
                                                                                DEFAULT_CONTENT
                                                                            }
                                                                            autoSize={{
                                                                                minRows: 5,
                                                                            }}
                                                                            defaultValue={
                                                                                privates.model
                                                                                    .sysConfig
                                                                                    .loginBulletBox
                                                                                    .text
                                                                            }
                                                                            onChange={actions.onValueChanged.params(
                                                                                privates.model
                                                                                    .sysConfig
                                                                                    .loginBulletBox,
                                                                                'text'
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        className="control-label control-label-right col-lg-3 col-md-4 col-xs-12 col-12"
                                                                        style={{
                                                                            padding: '0 15px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            /* 勾选框内容 */ UdeskLocales[
                                                                                'current'
                                                                            ].pages.admin.system
                                                                                .coachTemplate
                                                                                .checkBoxContent
                                                                        }
                                                                    </label>
                                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                                        <Input.TextArea
                                                                            placeholder={
                                                                                DEFAULT_CHECKED_CONTENT
                                                                            }
                                                                            autoSize={{
                                                                                minRows: 3,
                                                                            }}
                                                                            defaultValue={
                                                                                privates.model
                                                                                    .sysConfig
                                                                                    .loginBulletBox
                                                                                    .checkBoxContent
                                                                            }
                                                                            onChange={actions.onValueChanged.params(
                                                                                privates.model
                                                                                    .sysConfig
                                                                                    .loginBulletBox,
                                                                                'checkBoxContent'
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                                <If
                                                    condition={
                                                        Udesk.data.init.user &&
                                                        Udesk.data.init.user.hasFeature(
                                                            'sys:info:edit'
                                                        )
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                                            <Button
                                                                type="primary"
                                                                onClick={actions.save}
                                                            >
                                                                {locales.labels.save}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </If>
                                            </form>
                                        </If>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const DEFAULT_TITLE =
    /* 知情同意书 */ UdeskLocales['current'].pages.admin.system.coachTemplate.informedConsentForm;
export const DEFAULT_CONTENT = `${
    /* 为帮助您提高沟通和话术技能以及专业知识的应用，允许您随时随地进行练习并接受实时反馈，我们将通过本平台，收集您的以下信息： */ UdeskLocales[
        'current'
    ].pages.admin.system.coachTemplate
        .toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform
}`;
export const DEFAULT_CHECKED_CONTENT =
    /* 本人已经完整阅读并充分理解本知情同意书，并郑重声明同意根据本知情同意书所述的目的和方式处理我的敏感个人信息 */ UdeskLocales[
        'current'
    ].pages.admin.system.coachTemplate
        .iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm;
