import React from 'react';
import Udesk from 'Udesk';
import { getCurrentRouteName } from 'Udesk/system/subApp';

class RoleDetailBasicInfoComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        id: this.props.match.params.id,
        name: "",
        selectedMenuList: [],
    };

    actions = {
        backToIndex() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName("roleIndex"),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        changeRoleName({ target: { value } }) {
            this.privates.name = value;
            this.actions.update();
        },
        saveRole(submitContent) {
            let {
                locales,
            } = this;
            let {
                sdkOptions
            } = this.props;
            let {
                id,
                name,
                selectedMenuList,
            } = this.privates;
            if (selectedMenuList) {
                selectedMenuList = selectedMenuList.map((item) => {
                    return {
                        id: item.id
                    };
                });
            }

            if (submitContent && (submitContent.roleName == null || submitContent.roleName.trim() === "")) {
                return Udesk.ui.notify.error(Udesk.utils.string.format(locales.business.notifyMessage.canNotBeEmptyFormat, locales.components.pages.role.detail.basicInfo.roleName));
            }
            let params = {
                name,
                menuList: selectedMenuList,
                menuModule: Udesk.enums.sysModuleList.qa.id,
            };

            let url = Udesk.business.apiPath.concatApiPath(`roles`, sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            if (id) {
                url = Udesk.business.apiPath.concatApiPath(`roles/${id}`, sdkOptions);
                method = Udesk.enums.requestMethods.update.name;
            }

            Udesk.ajax(url, params, method).then(
                (resp) => {
                    Udesk.ui.notify.success(Udesk.utils.string.format(locales.business.notifyMessage.saveSuccessFormat, locales.components.pages.role.detail.title));
                    this.actions.backToIndex();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(locales.business.notifyMessage.saveFailedFormat, locales.components.pages.role.detail.title));
                }
            );
        },
        getNodeBehaviors() {
            let {
                route,
            } = this.props;
            return {
                expanded: true,
                disabled: route.name === "roleViewBasicInfo",
            };
        },
    };

    //#region Life Cycle
    componentDidMount() { }
    componentWillUnmount() { }
    //#endregion
}

export default RoleDetailBasicInfoComponent;