// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { BaseResponseListIntelligentPartnerHomePageTaskDetailResponse } from '../../../../types';

/**
 * 学员端任务详情接口
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [GET] /intelligentPartner/homePage/student/taskDetail
 */
export function getIntelligentPartnerHomePageStudentTaskDetail(): Promise<BaseResponseListIntelligentPartnerHomePageTaskDetailResponse>;
export function getIntelligentPartnerHomePageStudentTaskDetail(
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerHomePageTaskDetailResponse>;
export function getIntelligentPartnerHomePageStudentTaskDetail(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerHomePageTaskDetailResponse> {
    return request<never, never, never>('/intelligentPartner/homePage/student/taskDetail', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerHomePageStudentTaskDetail',
    });
}

export const meta = [
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/student/taskDetail',
        method: 'get',
    },
];
