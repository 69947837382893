import React, { useMemo, useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Button, Space, Popconfirm, Tabs, Select, Form, Modal, Input, Avatar } from 'udesk-ui';
import './style.scss';
import {
    BaseResponseListSeekHelpFoundResponse,
    BaseResponseListTaskHelpFoundResponse,
    GetSeekHelpsStatusQuery,
    PutTaskHelpsByIdBody,
} from 'src/api/types';
import { getSeekHelpsStatus } from 'src/api/seekHelps/status';
import { getTaskHelpsStatus } from 'src/api/taskHelps/status';
import { putTaskHelpsById } from 'src/api/taskHelps/{id}';
import { getTaskHelpsFindBySeekHelpIdById } from 'src/api/taskHelps/findBySeekHelpId/{id}';

interface RequestParams extends PutTaskHelpsByIdBody {
    createTime?: string;
    headPicUrl?: string;
    callLocation?: string;
}

const locales = Locales['current'];
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const STASTUS = 2;

const Template = React.memo((props: any) => {
    const formItemLayout = {
        wrapperCol: { sm: { span: 4 } },
    };
    const modalFormItemLayout = {
        labelCol: { sm: { span: 4 } },
        wrapperCol: { sm: { span: 20 } },
    };
    const columns = [
        {
            title: locales.pages.gong.coachTask.index.employeeName,
            dataIndex: 'helpUserName',
        },
        {
            title: locales.pages.gong.coachTask.index.customerName,
            dataIndex: 'customerName',
        },
        {
            title: locales.pages.gong.coachTask.index.source,
            dataIndex: 'source',
            render: (text, record) => {
                return (
                    <Button type='link' onClick={handlerViewCallDetail.bind(null, record)}>{locales.pages.gong.coachTask.index.viewSessionRecords}</Button>
                );
            },
        },
        {
            title: locales.pages.gong.coachTask.index.coachingStatus,
            dataIndex: 'helpStatus',
            render: (text, record) => {
                return (
                    <span
                        className={
                            Udesk.enums.coachTaskStatus.already.id === record.helpStatus
                                ? 'already'
                                : Udesk.enums.coachTaskStatus.ignore.id === record.helpStatus
                                    ? 'ignore'
                                    : ''
                        }
                    >
                        {Udesk.enums.coachTaskStatus.filter((item) => item.id === record.helpStatus)[0]?.name}
                    </span>
                );
            },
        },
        {
            title: locales.pages.gong.coachTask.index.helpTime,
            dataIndex: 'createTime',
        },
        {
            title: locales.pages.gong.coachTask.index.counselingTime,
            dataIndex: 'tutorTime',
        },
        {
            title: locales.pages.gong.coachTask.index.helpContent,
            dataIndex: 'seekContent',
        },
        {
            title: locales.pages.gong.coachTask.index.coachingOperation,
            dataIndex: 'action5',
            width: 180,
            render: (text, record) => (
                <Space size='middle'>
                    {record.helpStatus === Udesk.enums.coachTaskStatus.wait.id && (
                        <Popconfirm
                            title={locales.pages.gong.coachTask.index.areYouSureYouWantToIgnoreIt}
                            okText={locales.pages.gong.coachTask.index.determine}
                            cancelText={locales.pages.gong.coachTask.index.cancel}
                            onConfirm={handlerView.bind(null, record, 'ignore')}
                        >
                            <Button type='link'>{locales.pages.gong.coachTask.index.ignoreHelp}</Button>
                        </Popconfirm>
                    )}
                    {record.helpStatus === Udesk.enums.coachTaskStatus.wait.id && (
                        <Button type='link' onClick={handlerView.bind(null, record, 'coach')}>{locales.pages.gong.coachTask.index.coaching}</Button>
                    )}
                    {record.helpStatus === Udesk.enums.coachTaskStatus.already.id && (
                        <Button type='link' onClick={handlerView.bind(null, record, 'view')}>{locales.pages.gong.coachTask.index.viewCoaching}</Button>
                    )}
                    {record.helpStatus === Udesk.enums.coachTaskStatus.ignore.id && (
                        <Popconfirm
                            title={locales.pages.gong.coachTask.index.areYouSureToReturnToCounseling}
                            okText={locales.pages.gong.coachTask.index.determine}
                            cancelText={locales.pages.gong.coachTask.index.cancel}
                            onConfirm={handlerView.bind(null, record, 'wait')}
                        >
                            <Button type='link'>{locales.pages.gong.coachTask.index.returnToPendingCoaching}</Button>
                        </Popconfirm>
                    )}
                </Space>
            ),
        },
    ];
    const helpColumns = [
        {
            title: locales.pages.gong.coachTask.index.tutor,
            dataIndex: 'tutorNames',
        },
        {
            title: locales.pages.gong.coachTask.index.customerName,
            dataIndex: 'customerName',
        },
        {
            title: locales.pages.gong.coachTask.index.source,
            dataIndex: 'source',
            render: (text, record) => {
                return (
                    <Button type='link' onClick={handlerViewCallDetail.bind(null, record)}>{locales.pages.gong.coachTask.index.viewSessionRecords}</Button>
                );
            },
        },
        {
            title: locales.pages.gong.coachTask.index.helpSeekingStatus,
            dataIndex: 'helpStatus',
            render: (text, record) => {
                return (
                    <span
                        className={
                            Udesk.enums.coachTaskStatus.already.id === record.helpStatus
                                ? 'already'
                                : Udesk.enums.coachTaskStatus.ignore.id === record.helpStatus
                                    ? 'ignore'
                                    : ''
                        }
                    >
                        {Udesk.enums.coachTaskStatus.filter((item) => item.id === record.helpStatus)[0]?.name}
                    </span>
                );
            },
        },
        {
            title: locales.pages.gong.coachTask.index.helpTime,
            dataIndex: 'createTime',
        },
        {
            title: locales.pages.gong.coachTask.index.recentCoachingTime,
            dataIndex: 'lastHelpTime',
        },
        {
            title: locales.pages.gong.coachTask.index.helpContent,
            dataIndex: 'content',
        },
        {
            title: locales.pages.gong.coachTask.index.operation,
            dataIndex: 'action6',
            render: (text, record) => (
                <Space size='middle'>
                    {record.helpStatus === Udesk.enums.coachTaskStatus.already.id && (
                        <Button type='link' onClick={handlerView.bind(null, record, 'helpView')}>{locales.pages.gong.coachTask.index.viewCoaching}</Button>
                    )}
                </Space>
            ),
        },
    ];
    const [form] = Form.useForm();
    const [actionsType, setActionsType] = useState('');
    const [modalBool, setModalBool] = useState(false);
    const [page, setPage] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [tabsKey, setTabsKey] = useState(props.location?.state?.tabsKey??'task');
    const [currentData, setCurrentData] = useState<RequestParams>({});
    const [status, setStatus] = useState(STASTUS);
    const [coachOptions, setCoachOptions] = useState<any[]>([]);
    // tabs切换
    const handlerChangeTabs = (key) => {
        const newPage = {
            pageNum: 1,
            pageSize: 20,
            total: 0,
        };
        setPage(newPage);
        setDataSource([]);
        setTabsKey(key);
        setStatus(STASTUS);
        if (key === 'task') {
            getMyTaskData(STASTUS, newPage);
        } else {
            getMyHelpData(STASTUS, newPage);
        }
    };
    // 分页
    const paginationProps = useMemo(() => {
        return {
            current: page.pageNum,
            pageSize: page.pageSize,
            total: page.total,
            onChange: (pageNum, pageSize) => {
                setPage({
                    ...page,
                    pageNum,
                    pageSize,
                });
                const newPage = {
                    ...page,
                    pageNum,
                    pageSize,
                };
                if (tabsKey === 'task') {
                    getMyTaskData(STASTUS, newPage);
                } else {
                    getMyHelpData(STASTUS, newPage);
                }
            },
        };
    }, [page]);
    // actions
    const handlerView = (record, type) => {
        setCurrentData(record);
        setActionsType(type);
        if (type === 'coach' || type === 'view') {
            setModalBool(true);
        } else if (type === 'helpView') {
            setModalBool(true);
            getTaskHelpsFindBySeekHelpIdById({ segments: { id: record.id } }).then(
                (res: BaseResponseListTaskHelpFoundResponse) => {
                    setCoachOptions(res.data ?? []);
                }
            );
        } else if (type === 'ignore') {
            const params: PutTaskHelpsByIdBody = {
                ...record,
                helpStatus: Udesk.enums.coachTaskStatus.ignore.id,
            };
            putTaskHelpsById(params, { segments: { id: record.id }, successMsg: locales.pages.gong.coachTask.index.ignoreSuccess }).then((res) => {
                getMyTaskData(status, page);
            });
        } else if (type === 'wait') {
            const params: PutTaskHelpsByIdBody = {
                ...record,
                helpStatus: Udesk.enums.coachTaskStatus.wait.id,
            };
            putTaskHelpsById(params, { segments: { id: record.id }, successMsg: locales.pages.gong.coachTask.index.recoverySucceeded }).then((res) => {
                getMyTaskData(status, page);
            });
        }
    };
    // 条件筛选
    const handlerFilter = (key) => {
        setStatus(key);
        if (tabsKey === 'task') {
            getMyTaskData(key, page);
        } else {
            getMyHelpData(key, page);
        }
    };
    // 辅导建议提交
    const handleModalOk = () => {
        form.validateFields().then((values) => {
            const params: PutTaskHelpsByIdBody = {
                ...currentData,
                helpStatus: Udesk.enums.coachTaskStatus.already.id,
                content: values.content,
            };
            putTaskHelpsById(params, { segments: { id: currentData?.id! }, successMsg: locales.pages.gong.coachTask.index.successfulCoaching }).then((res) => {
                getMyTaskData(status, page);
                setModalBool(false);
            });
        });
    };
    // 关闭modal
    const handleModalCancel = () => {
        setModalBool(false);
    };
    // 获取列表数据
    const getMyTaskData = (status, page) => {
        const params = {
            status: status === undefined ? STASTUS : status,
            pageNum: page.pageNum,
            pageSize: page.pageSize,
        };
        getTaskHelpsStatus({ params }).then((res: BaseResponseListTaskHelpFoundResponse) => {
            setDataSource(res.data ?? []);
            setPage({
                pageNum: res.paging?.pageNum ?? page.pageNum,
                pageSize: res.paging?.pageSize ?? page.pageSize,
                total: res.paging?.total ?? page.total,
            });
        });
    };
    const getMyHelpData = (status, page) => {
        const params: GetSeekHelpsStatusQuery = {
            status: status === undefined ? STASTUS : status,
            pageNum: page.pageNum,
            pageSize: page.pageSize,
        };
        getSeekHelpsStatus({ params }).then((res: BaseResponseListSeekHelpFoundResponse) => {
            setDataSource(res.data ?? []);
            setPage({
                pageNum: res.paging?.pageNum ?? page.pageNum,
                pageSize: res.paging?.pageSize ?? page.pageSize,
                total: res.paging?.total ?? page.total,
            });
        });
    };
    // 查看会话记录
    const handlerViewCallDetail = (record) => {
        const routeOptions = {
            history: props.history,
            routeName: 'gongCallDetail',
            pathParams: { callId: record.sessionId },
            state: {
                type: 'coach',
                tabsKey,
            }
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        if (tabsKey === 'task') {
            getMyTaskData(status, page);
        } else {
            getMyHelpData(status, page);
        }
    }, []);
    return (
        <Page pageBodyClassName='pages-coach-task-container' title={locales.menu.tasks.coachTask.text} padding={true}>
            <Tabs activeKey={tabsKey} type='card' onChange={handlerChangeTabs}>
                <TabPane tab={locales.pages.gong.coachTask.index.myMission} key='task'>
                    <Form {...formItemLayout}>
                        <Form.Item label={locales.pages.gong.coachTask.index.selectCoachingStatus} name='coachStatus'>
                            <Select allowClear placeholder={locales.pages.gong.coachTask.index.whole} onChange={handlerFilter.bind(null)}>
                                {Udesk.enums.coachTaskStatus.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Form>
                    <CommonTablePage key='task'>
                        <CommonTablePage.Table columns={columns} dataSource={dataSource}></CommonTablePage.Table>
                        <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
                    </CommonTablePage>
                </TabPane>
                <TabPane tab={locales.pages.gong.coachTask.index.iAskedForHelp} key='help'>
                    <Form {...formItemLayout}>
                        <Form.Item label={locales.pages.gong.coachTask.index.selectCoachingStatus} name='helpStatus'>
                            <Select allowClear placeholder={locales.pages.gong.coachTask.index.whole} onChange={handlerFilter.bind(null)}>
                                {Udesk.enums.coachTaskStatus.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Form>
                    <CommonTablePage key='help'>
                        <CommonTablePage.Table columns={helpColumns} dataSource={dataSource}></CommonTablePage.Table>
                        <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
                    </CommonTablePage>
                </TabPane>
            </Tabs>
            <Modal
                title={actionsType === 'view' || actionsType === 'helpView' ? locales.pages.gong.coachTask.index.viewCoaching : locales.pages.gong.coachTask.index.coaching}
                visible={modalBool}
                onCancel={handleModalCancel}
                footer={
                    actionsType === 'view' || actionsType === 'helpView' ? (
                        <Button onClick={handleModalCancel} type='primary'>{locales.pages.gong.coachTask.index.signOut}</Button>
                    ) : (
                        <Space>
                            <Button onClick={handleModalCancel}>{locales.pages.gong.coachTask.index.cancel}</Button>
                            <Button onClick={handleModalOk} type='primary'>{locales.pages.gong.coachTask.index.submit}</Button>
                        </Space>
                    )
                }
            >
                <Form form={form} {...modalFormItemLayout}>
                    {(actionsType === 'coach' || actionsType === 'view') && (
                        <Form.Item label={locales.pages.gong.coachTask.index.helpPerson}>
                            <Space size='small'>
                                <Avatar src={currentData?.headPicUrl} />
                                <span>
                                    {currentData?.helpUserName}
                                    <span style={{ color: '#FB5127', marginLeft: 16 }}>
                                        {currentData?.callLocation || '00:00:00'}
                                    </span>
                                </span>
                            </Space>
                        </Form.Item>
                    )}
                    {actionsType === 'helpView' ? (
                        <Form.Item label={locales.pages.gong.coachTask.index.customer}>
                            <Space size='small'>
                                <span>
                                    {currentData?.customerName || '--'}
                                    <span style={{ color: '#FB5127', marginLeft: 16 }}>
                                        {currentData?.callLocation || '00:00:00'}
                                    </span>
                                </span>
                            </Space>
                        </Form.Item>
                    ) : (
                        <Form.Item label={locales.pages.gong.coachTask.index.customer}>{currentData?.customerName || '--'}</Form.Item>
                    )}
                    <Form.Item label={locales.pages.gong.coachTask.index.helpContent}>
                        {actionsType === 'helpView' ? currentData?.content : currentData?.seekContent}
                    </Form.Item>
                    {actionsType === 'helpView' && (
                        <Form.Item label={locales.pages.gong.coachTask.index.toBeCoached}>
                            <Space direction='vertical'>
                                {coachOptions
                                    .filter((item) => item.helpStatus === Udesk.enums.coachTaskStatus.wait.id)
                                    .map((record) => {
                                        return (
                                            <Space size='small'>
                                                <Avatar src={record.headPicUrl} />
                                                <span>{record.userName}</span>
                                            </Space>
                                        );
                                    })}
                            </Space>
                        </Form.Item>
                    )}
                    {actionsType === 'coach' && (
                        <Form.Item
                            label={locales.pages.gong.coachTask.index.counselingSuggestions}
                            name='content'
                            rules={[
                                {
                                    required: true,
                                    message: locales.pages.gong.coachTask.index.pleaseEnterCoachingSuggestions,
                                },
                            ]}
                        >
                            <TextArea maxLength={1024} maxCount={1024} showCount={true} placeholder={locales.pages.gong.coachTask.index.pleaseEnterCoachingSuggestions} rows={2} />
                        </Form.Item>
                    )}
                    {actionsType === 'view' && <Form.Item label={locales.pages.gong.coachTask.index.counselingSuggestions}>{currentData?.content}</Form.Item>}
                    {actionsType === 'helpView' && (
                        <Form.Item label={locales.pages.gong.coachTask.index.counselingSuggestions}>
                            <Space direction='vertical'>
                                {coachOptions
                                    .filter(
                                        (record) =>
                                            record.helpStatus === Udesk.enums.coachTaskStatus.already.id ||
                                            record.helpStatus === Udesk.enums.coachTaskStatus.ignore.id
                                    )
                                    .map((item) => {
                                        return (
                                            <Space size='small'>
                                                <Avatar src={item.headPicUrl} />
                                                <span style={{maxWidth: 200, display: 'flex', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{item.userName}</span>
                                                <span>
                                                    {item.helpStatus === Udesk.enums.coachTaskStatus.already.id
                                                        ? item.content
                                                        : ''}
                                                </span>
                                                {item.helpStatus === Udesk.enums.coachTaskStatus.ignore.id ? (
                                                    <span style={{color: '#FB5127'}}>{locales.pages.gong.coachTask.index.ignoreHelp}</span>
                                                ) : null}
                                            </Space>
                                        );
                                    })}
                            </Space>
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
