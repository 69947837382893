const generalSettingRoutesGlobal = [
    {
        path: '/general-setting',
        name: 'generalSettingHome',
        routes: [
            // 权限管理
            {
                path: '/permission-management',
                name: 'generalSettingPermissionManagement',
                routes: [
                    {
                        path: '/employee',
                        name: 'generalSettingEmployeeManagement',
                        routes: [
                            {
                                path: '/list',
                                name: 'generalSettingUserList',
                            },
                            {
                                path: '/edit/:id',
                                name: 'generalSettingUserEdit',
                            },
                            {
                                path: '/detail/:id',
                                name: 'generalSettingUserDetail',
                            },
                        ],
                    },
                    {
                        path: '/role',
                        name: 'generalSettingRoleManagement',
                        routes: [
                            {
                                path: '/list',
                                name: 'generalSettingRoleList',
                            },
                            {
                                path: '/sysModule/:sysModule/detail/:id',
                                name: 'generalSettingRoleDetail',
                                routes: [
                                    {
                                        path: '/application',
                                        name: 'generalSettingRoleView',
                                    },
                                    {
                                        path: '/data',
                                        name: 'generalSettingRoleViewDataPermissions',
                                    },
                                    {
                                        path: '/task',
                                        name: 'generalSettingRoleViewTaskPermissions',
                                    },
                                ],
                            },
                            {
                                path: '/sysModule/:sysModule/edit/:id',
                                name: 'generalSettingRoleEditEntry',
                                routes: [
                                    {
                                        path: '/application',
                                        name: 'generalSettingRoleEdit',
                                    },
                                    {
                                        path: '/data',
                                        name: 'generalSettingRoleEditDataPermissions',
                                    },
                                    {
                                        path: '/task',
                                        name: 'generalSettingRoleEditTaskPermissions',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            // 数据对接
            {
                path: '/docking',
                name: 'generalSettingDataDocking',
            },
            // 账户管理
            {
                path: '/account-management',
                name: 'generalSettingAccountManagement',
                routes: [
                    // 安全设置
                    {
                        path: '/security-settings',
                        name: 'generalSettingSecuritySettings',
                    },
                ],
            },
            //404
            {
                path: '/404',
                name: 'generalSettingPageNotFound',
            },
        ],
    },
];

export { generalSettingRoutesGlobal };
