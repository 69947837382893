import React from 'react';
import Udesk from 'Udesk';
import { getCurrentRouteName } from 'Udesk/system/subApp';

class RoleDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {
    };
    privates = {
        id: this.props.match.params.id,
    };

    isRedirect(currentRoute, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentRoute.name,
            pathParams: this.props.match.params
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }

    actions = {
        backToIndex() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName("roleIndex"),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
    };

    //#region Life Cycle
    componentDidMount() {}
    componentWillUnmount() {}
    //#endregion
}

export default RoleDetailComponent;