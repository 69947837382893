import React, { FC, useState } from 'react';
import { Button, Modal } from 'udesk-ui';
import { AiGenScore } from 'Component/pages/components/coach/AiGenScore';
import { postReviewIntelligentPartnerAiFinish } from 'src/api/review/intelligentPartner/ai/finish';
import { AiDialogFinishResultResponse } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

type FinishTestProps = {
    reset?: () => void;
    dialogueSentenceList?: any[];
    flowId?: any;
    recordId?: any;
};

export const FinishTest: FC<FinishTestProps> = (props) => {
    const { reset, dialogueSentenceList, flowId, recordId } = props;

    const [visible, setVisible] = useState(false);
    const [result, setResult] = useState<AiDialogFinishResultResponse>();
    const [loading, setLoading] = useState(false);

    const onShowResult = () => {
        setVisible(true);
        setLoading(true);
        postReviewIntelligentPartnerAiFinish({
            aiDialogContentList: dialogueSentenceList,
            flowId,
            recordId,
        })
            .then((res) => {
                setResult(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const onCancel = () => {
        setVisible(false);
    };
    const onReset = () => {
        setVisible(false);
        reset?.();
    };

    return (
        <>
            <Button type="primary" onClick={onShowResult}>{/* 结束测试 */}{UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.finishTest.index.endTest}</Button>

            <Modal
                title={/* 测试结果 */UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.finishTest.index.testResult}
                visible={visible}
                width={1000}
                onCancel={onCancel}
                footer={[
                    <Button type="primary" onClick={() => window.history.back()}>{/* 返回画布 */}{UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.finishTest.index.returnToCanvas}</Button>,
                    <Button type="primary" onClick={onReset}>{/* 重新测试 */}{UdeskLocales['current'].pages.coach.learningCenter.nodeConfigTest.components.finishTest.index.retest}</Button>,
                ]}
                maskClosable={false}
                closable={false}
            >
                <AiGenScore data={result} loading={loading} />
            </Modal>
        </>
    );
};
