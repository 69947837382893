import React from 'react';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';
import { Tag } from 'udesk-ui';

export const ScoringRulesAbbreviatedList = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { value } = props;

    return (
        <div>
            {value.map((item) => (
                <Tag color={item.operator === 1 ? 'blue' : 'red'} style={{ marginBottom: 4 }}>{`${
                    item.name
                } ${item.operator === 1 ? '+' : '-'}${item.score}`}</Tag>
            ))}
        </div>
    );
});
