import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { LoginStatusCode } from './const';

export default class LoginComponent extends React.Component {
    //#region state
    state = {
        email: null,
        password: null,
        rememberMe: false,
        errorMsg: '',
        loading: false,
    };
    privates = {
        formInstance: React.createRef(),
        isIpForbidden: false,
        relationCompanyList: undefined,
        virtualLoginModal: false,
    };
    //#endregion

    //#region Life Cycle
    componentDidMount() {}
    componentWillUnmount() {}
    //#endregion

    //#region actions
    actions = {
        onAfterClose() {
            this.privates.isIpForbidden = false;
            this.actions.update();
        },
        onValueChanged(obj, key, e) {
            let value = e;
            if (key === 'rememberMe') {
                value = e.target.checked;
            } else if (e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.update();
        },
        loginV2(values) {
            const { email, password } = values;

            if (!email) {
                this.setState({
                    errorMsg: UdeskLocales.current.pages.auth.login.emailCannotBeEmpty,
                });
                return false;
            }

            if (!password) {
                this.setState({
                    errorMsg: UdeskLocales.current.pages.auth.login.passwordCannotByEmpty,
                });
                return false;
            }

            const data = {
                email,
                password,
            };
            this.setState({
                loading: true,
            });
            Udesk.ajax
                .post(Udesk.business.apiPath.concatApiPath('/login', this.props.sdkOptions), data)
                .then(
                    (resp) => {
                        if (resp.code === Udesk.system.apiCodes.mustChangePassword) {
                            // TODO 目前还没实现修改密码页面，暂时给一个提示信息
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.mustChangeLogin,
                            });
                        } else if (resp.code === Udesk.system.apiCodes.disableStatus) {
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.forbiddenLogin,
                            });
                        } else if (resp.code === Udesk.system.apiCodes.dimissionStatus) {
                            this.setState({
                                errorMsg: UdeskLocales.current.pages.auth.login.dismissionLogin,
                            });
                        } else {
                            if (resp.data?.loginStatusCode === LoginStatusCode.Multi) {
                                this.privates.relationCompanyList =
                                    resp.data.relationCompanyList || [];
                                this.privates.virtualLoginModal = true;
                                this.actions.update();
                            } else {
                                let refer = this.privates.model.refer;
                                let userName = window.localStorage.getItem('userName');
                                if (refer && userName === email) {
                                    window.location.href = refer;
                                } else {
                                    window.location.href = '/entry-page';
                                }
                            }
                        }
                        this.setState({
                            loading: false,
                        });
                    },
                    (reason) => {
                        // this.privates.isIpForbidden = true;

                        let errorMsg =
                            reason.errorMsg || UdeskLocales.current.pages.auth.login.loginError;
                        this.setState({
                            loading: false,
                            errorMsg: errorMsg,
                        });

                        // Udesk.ui.notify.error(errorMsg);
                    }
                );
        },
        onVirtualLoginModalClose() {
            this.privates.virtualLoginModal = false;
            this.actions.update();
        },
    };
    //#endregion
}
