import { useEffect, useState } from 'react';

export const useFitView = (reactFlowInstance) => {
    const [random, setRandom] = useState(0);

    const trigger = () => {
        setRandom((prev) => prev + 1);
    };

    useEffect(() => {
        reactFlowInstance?.fitView();
    }, [random]);
    
    return {
        triggerFitView: trigger,
    };
};
