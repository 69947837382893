// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerTaskInfosByTaskIdReScoringQuery,
    GetIntelligentPartnerTaskInfosByTaskIdReScoringParam,
    BaseResponseVoid,
} from '../../../types';

/**
 * 任务批量重��评分
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/{taskId}/reScoring
 * @param taskId
 */
export function getIntelligentPartnerTaskInfosByTaskIdReScoring(
    options: ApiOptions<
        GetIntelligentPartnerTaskInfosByTaskIdReScoringParam,
        GetIntelligentPartnerTaskInfosByTaskIdReScoringQuery
    > & { segments: GetIntelligentPartnerTaskInfosByTaskIdReScoringParam } & {
        params: GetIntelligentPartnerTaskInfosByTaskIdReScoringQuery;
    }
): Promise<BaseResponseVoid> {
    return request<
        never,
        GetIntelligentPartnerTaskInfosByTaskIdReScoringParam,
        GetIntelligentPartnerTaskInfosByTaskIdReScoringQuery
    >('/intelligentPartnerTaskInfos/{taskId}/reScoring', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerTaskInfosByTaskIdReScoring',
    });
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/{taskId}/reScoring',
        method: 'get',
    },
];
