import { useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { getIntelligentPartnerTaskInfosUserFindAll } from 'src/api/intelligentPartnerTaskInfos/user/findAll';
import { IntelligentPartnerTaskInfoFoundResponse } from 'src/api/types';

type Option = {
    label: string;
    value: number;
    [key: string]: unknown;
};

type UseTaskProps = {
    userId?: number;
    taskType?: number;
};

export const useTask = (props: UseTaskProps) => {
    const { userId, taskType } = props;

    const [tasks, setTasks] = useState<IntelligentPartnerTaskInfoFoundResponse[]>([]);

    useRequest(() => getIntelligentPartnerTaskInfosUserFindAll({ params: { userId } }), {
        refreshDeps: [userId],
        onSuccess: (res) => {
            setTasks(res.data || []);
        },
    });

    const options: Option[] = useMemo(() => {
        return tasks
            .filter((t) => (taskType ? t.taskType === taskType : true))
            .map((d) => {
                return {
                    ...d,
                    label: d.taskName!,
                    value: d.id!,
                };
            });
    }, [taskType, tasks]);

    return { tasks, options };
};
