import dagre from 'dagre';

export const useDagreGraph = ({ nodeWidth, nodeHeight }) => {
    // const [dagreGraph, setDagreGraph] = useState<any>(null);
    // useEffect(() => {
    //     const dagreGraph = new dagre.graphlib.Graph();
    //     // setDagreGraph(dagreGraph);
    //     dagreGraph.setDefaultEdgeLabel(() => ({}));
    // }, []);

    const initDagreGraph = () => {
        const dagreGraph = new dagre.graphlib.Graph();
        // setDagreGraph(dagreGraph);
        dagreGraph.setDefaultEdgeLabel(() => ({}));
        return dagreGraph;
    };

    const getLayoutedBranches = (nodes, edges, direction = 'TB') => {
        const dagreGraph = initDagreGraph();
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });

        const judgeNode = nodes.find(
            (n) => n.data.type === 'JUDGE_NODE' || n.data.type === 'DIALOG_NODE'
        );
        judgeNode.positionAbsolute = undefined;
        // const judgeNodePosition = judgeNode.position;
        const nodesMap = {};
        nodes.forEach((node) => {
            nodesMap[node.id] = true;
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge) => {
            if (nodesMap[edge.source] && nodesMap[edge.target]) {
                dagreGraph.setEdge(edge.source, edge.target);
            }
        });

        dagre.layout(dagreGraph);

        const judgeNodeWithPosition = dagreGraph.node(judgeNode.id);
        // const xOffset = judgeNodePosition.x - judgeNodeWithPosition.x;
        // const yOffset = judgeNodePosition.y - judgeNodeWithPosition.y;
        const xCenter = judgeNodeWithPosition.x;
        const yZero = judgeNodeWithPosition.y;

        nodes
            .filter((n) => n.data.type !== 'JUDGE_NODE' && n.data.type !== 'DIALOG_NODE')
            .forEach((node) => {
                const nodeWithPosition = dagreGraph.node(node.id);
                node.targetPosition = isHorizontal ? 'left' : 'top';
                node.sourcePosition = isHorizontal ? 'right' : 'bottom';

                // node.position = {
                //     x: nodeWithPosition.x + xOffset,
                //     y: nodeWithPosition.y + yOffset,
                // };
                node.position = {
                    x: nodeWithPosition.x - xCenter,
                    y: nodeWithPosition.y - yZero,
                };
                // return node;
            });
        // judgeNode.
        return { nodes, edges };
    };
    const getLayoutedElements = (nodes, edges, direction = 'TB') => {
        const dagreGraph = initDagreGraph();
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });
        const nodesMap = {};

        nodes.forEach((node) => {
            nodesMap[node.id] = true;
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge) => {
            if (nodesMap[edge.source] && nodesMap[edge.target]) {
                dagreGraph.setEdge(edge.source, edge.target);
            }
        });

        dagre.layout(dagreGraph);

        nodes.forEach((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? 'left' : 'top';
            node.sourcePosition = isHorizontal ? 'right' : 'bottom';

            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            if (node.data.type !== 'BRANCH') {
                node.position = {
                    x: nodeWithPosition.x - nodeWidth / 2,
                    y: nodeWithPosition.y - nodeHeight / 2,
                };
            } else {
                const judgeNode = nodes.find(
                    (n) =>
                        (n.data.type === 'JUDGE_NODE' || n.data.type === 'DIALOG_NODE') &&
                        n.id === node.data.preId
                );
                const judgeNodeWithPosition = dagreGraph.node(judgeNode.id);
                // const xOffset = judgeNodePosition.x - judgeNodeWithPosition.x;
                // const yOffset = judgeNodePosition.y - judgeNodeWithPosition.y;
                const xCenter = judgeNodeWithPosition.x;
                const yZero = judgeNodeWithPosition.y;

                const nodeWithPosition = dagreGraph.node(node.id);
                node.targetPosition = isHorizontal ? 'left' : 'top';
                node.sourcePosition = isHorizontal ? 'right' : 'bottom';

                // node.position = {
                //     x: nodeWithPosition.x + xOffset,
                //     y: nodeWithPosition.y + yOffset,
                // };
                node.position = {
                    x: nodeWithPosition.x - xCenter,
                    y: nodeWithPosition.y - yZero,
                };
            }
        });

        return { nodes, edges };
    };
    return {
        getLayoutedBranches,
        getLayoutedElements,
    };
};
