// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetCalltaskQaTaskManualQuery,
    BaseResponseListInspectionTask,
    PostCalltaskQaTaskManualBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 根据通话ID查询添加的质检任务
 *
 * @export
 * @tags data-call-manual-add-qa-task-controller
 * @link [GET] /calltask/qaTask/manual
 */
export function getCalltaskQaTaskManual(): Promise<BaseResponseListInspectionTask>;
export function getCalltaskQaTaskManual(
    options: ApiOptions<never, GetCalltaskQaTaskManualQuery>
): Promise<BaseResponseListInspectionTask>;
export function getCalltaskQaTaskManual(
    options?: ApiOptions<never, GetCalltaskQaTaskManualQuery>
): Promise<BaseResponseListInspectionTask> {
    return request<never, never, GetCalltaskQaTaskManualQuery>('/calltask/qaTask/manual', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCalltaskQaTaskManual',
    });
}

/**
 * 手动添加质检任务
 *
 * @export
 * @tags data-call-manual-add-qa-task-controller
 * @link [POST] /calltask/qaTask/manual
 */
export function postCalltaskQaTaskManual(
    data: PostCalltaskQaTaskManualBody
): Promise<BaseResponseVoid>;
export function postCalltaskQaTaskManual(
    data: PostCalltaskQaTaskManualBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postCalltaskQaTaskManual(
    data: PostCalltaskQaTaskManualBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostCalltaskQaTaskManualBody, never, never>('/calltask/qaTask/manual', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCalltaskQaTaskManual',
    });
}

export const meta = [
    {
        tags: ['data-call-manual-add-qa-task-controller'],
        path: '/calltask/qaTask/manual',
        method: 'get',
    },
    {
        tags: ['data-call-manual-add-qa-task-controller'],
        path: '/calltask/qaTask/manual',
        method: 'post',
    },
];
