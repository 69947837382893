import Udesk from 'Udesk';
import SdkLoaderBase from '../../../common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';

class QualityAnalysisComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: "data-analysis-quality-analysis",
        pageTitle: UdeskLocales.current.components.pages.dataAnalysis.qualityAnalysis

    };
}

export default Udesk.react.udeskify(QualityAnalysisComponent);