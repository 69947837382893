import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Select } from 'udesk-ui';
import { getUsers } from 'src/api/users';
import UdeskLocales from 'UdeskLocales';

const locales = UdeskLocales['current'];

type TemplateProps = {
    value?: any;
    onChange?: (v: any) => void;
    disabled?: boolean;
    outerOptions?: any[];
    setOuterOptions?: (o: any) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange, disabled, outerOptions, setOuterOptions } = props;

    const [userList, setUserList] = useState<any[]>([]);

    const getUserDataList = useCallback(() => {
        const params = {
            pageSize: 1000,
        };
        getUsers({ params }).then((res) => {
            setUserList(res.data ?? []);
            setOuterOptions?.(res.data ?? []);
        });
    }, [setOuterOptions]);

    useEffect(() => {
        getUserDataList();
    }, [getUserDataList]);

    return (
        <Select
            showSearch
            showArrow
            allowClear
            mode="multiple"
            filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={locales.pages.gong.saleClientCenter.list.index.allMembers}
            style={{ width: 160 }}
            value={value}
            onChange={onChange}
            disabled={disabled}
        >
            {(outerOptions ?? userList).map((item) => {
                return (
                    <Select.Option key={item.id} value={item.id}>
                        {item.realname}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default memo(Template);
