import React, { useState, useEffect } from 'react';
// import './style.scss';
// 引入编辑器组件
import BraftEditor from 'braft-editor';
// 引入编辑器样式
import 'braft-editor/dist/index.css';
// import { ContentUtils } from 'braft-utils';
import styled from 'styled-components';
import Udesk from 'Udesk';
import { postStorageCreateResource } from 'src/api/storage/createResource';
import { language } from 'src/components/common/b-editor';

const braftEditorStyle = {
    border: '1px solid #d9d9d9',
    height: 300,
};

const RichTextWrap = styled.div`
    .bf-container {
        display: flex;
        flex-direction: column;
    }
`;

const RichText = (props) => {
    const { value, onChange, isView } = props;
    const [editorValue, setEditorValue] = useState(undefined);

    useEffect(() => {
        if (!editorValue && value !== undefined) {
            setEditorValue(BraftEditor.createEditorState(value || null));
        }
    }, [value]);

    if (isView) {
        return (
            <div style={{ lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: value }}></div>
        );
    }

    const handleEditorChange = (editorState) => {
        onChange(editorState.toHTML());
        setEditorValue(editorState);
    };
    return (
        <RichTextWrap>
            <BraftEditor
                style={braftEditorStyle}
                value={editorValue}
                onChange={handleEditorChange}
                media={{
                    uploadFn: (param) => {
                        const successFn = (file, uploadData) => {
                            // 假设服务端直接返回文件上传后的地址
                            // 上传成功后调用param.success并传入上传后的文件地址
                            postStorageCreateResource({
                                keyName: uploadData.key,
                            }).then((resp) => {
                                param.success({
                                    url: resp.data ?? '',
                                    meta: {
                                        loop: true, // 指定音视频是否循环播放
                                        autoPlay: true, // 指定音视频是否自动播放
                                        controls: true, // 指定音视频是否显示控制栏
                                    } as any,
                                });
                            });
                        };
                        const errorFn = (response) => {
                            // 上传发生错误时调用param.error
                            param.error({
                                msg: 'unable to upload.',
                            });
                        };
                        Udesk.app.fileStorage.upload(param.file, {
                            token: new Promise((resolve, reject) => {
                                Udesk.business
                                    .getServerAuthorization({
                                        type: 2,
                                    })
                                    .then(
                                        function (resp) {
                                            resolve(resp);
                                        },
                                        function (reason) {
                                            reject(reason);
                                        }
                                    );
                            }),
                            //progressCallback: progressCallback.bind(this),
                            successCallback: successFn,
                            errorCallback: errorFn,
                        });
                    },
                }}
                language={language}
            />
        </RichTextWrap>
    );
};

export default RichText;
