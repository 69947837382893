// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetInspectionModulesByTypeDataSourceByDataSourceParam,
    BaseResponseMapintListInspectionModuleVersionDetail,
} from '../../../../types';

/**
 * queryModuleList
 *
 * @export
 * @tags 模组
 * @link [GET] /inspectionModules/{type}/dataSource/{dataSource}
 * @param type
 * @param dataSource
 */
export function getInspectionModulesByTypeDataSourceByDataSource(
    options: ApiOptions<GetInspectionModulesByTypeDataSourceByDataSourceParam, never> & {
        segments: GetInspectionModulesByTypeDataSourceByDataSourceParam;
    }
): Promise<BaseResponseMapintListInspectionModuleVersionDetail> {
    return request<never, GetInspectionModulesByTypeDataSourceByDataSourceParam, never>(
        '/inspectionModules/{type}/dataSource/{dataSource}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getInspectionModulesByTypeDataSourceByDataSource',
        }
    );
}

export const meta = [
    { tags: ['模组'], path: '/inspectionModules/{type}/dataSource/{dataSource}', method: 'get' },
];
