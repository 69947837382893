import React from 'react';
import BusinessAnalysisFilterComponent from './component';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Select, Input, Button, Tooltip, Form, Icon, TreeSelect } from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import _compact from 'lodash-es/compact';
import _size from 'lodash-es/size';
import _find from 'lodash-es/find';
import BusinessAnalysisFilter from '.';
import ClassNames from 'classnames';
import UdeskLocales from 'UdeskLocales';
export default class BusinessAnalysisFilterTemplate extends BusinessAnalysisFilterComponent {
    render() {
        let { actions, locales, props } = this;
        let { searchTemplate, driverFlag, style } = this.props;
        let { businessList, fieldsList, data, domInstance, isVisible } = this.privates;
        // let { getFieldDecorator } = this.props.form;
        let { Option } = Select;
        return (
            <div ref={domInstance} style={style}>
                {isVisible && !_isEmpty(data) &&
                    _compact(data).map((itemss, index) => {
                        return (
                            <div
                                // key={itemss.num}
                                className={ClassNames('custome-filter-friver-next-box', {
                                    'custome-filter-friver-next-box-flag':
                                        (_size(data) === 1 && !driverFlag) || index === _size(data) - 1,
                                })}
                            >
                                <div
                                    className={ClassNames('custome-filter-dirver-first-box', {
                                        'custome-filter-friver-flag-box': driverFlag,
                                    })}
                                >
                                    <div className={'custome-filter-driver-one-box'}>
                                        <div className='custome-filter-driver-one'>
                                            <div className='custome-filter-driver-circle-add-one'>
                                                <div
                                                    className='customer-filter-driver-circle-add'
                                                    style={{left: '-42px'}}
                                                    onClick={actions.addTwoCustome.params(itemss)}
                                                >
                                                    <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-iconfont707'></i>
                                                </div>
                                                {!_isEmpty(itemss.children) && (
                                                    <div
                                                        className="customer-filter-driver-circle-add"
                                                        style={{
                                                            border: 'none',
                                                            left: '-20px',
                                                            backgroundColor: 'transparent',
                                                        }}
                                                        onClick={actions.onShowChildrenChange.params(
                                                            itemss
                                                        )}
                                                    >
                                                        <Icon
                                                            type="CaretRightOutlined"
                                                            antdIcon={true}
                                                            style={{
                                                                transform: itemss.showChildren
                                                                    ? 'rotateZ(90deg)'
                                                                    : undefined,
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <Form.Item>
                                                {/* {getFieldDecorator(`${itemss.num}-first`, {
                                                    initialValue: itemss.type || undefined,
                                                })( */}
                                                <Select
                                                    onChange={actions.dataSourceChanged.params(itemss)}
                                                    value={itemss.type || undefined}
                                                    placeholder={locales.labels.pleaseChoice}
                                                >
                                                    {!_isEmpty(businessList) &&
                                                        businessList.map((items) => {
                                                            return (
                                                                <Option
                                                                    key={items.type}
                                                                    value={items.type}
                                                                    disabled={
                                                                        items.type === '0' &&
                                                                        this.props.dataSourceType === 0
                                                                    }
                                                                >
                                                                    {items.text}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                                {/* )} */}
                                            </Form.Item>
                                            {_find(fieldsList, (current) => current.id === itemss.type) &&
                                                !_isEmpty(
                                                    _find(fieldsList, (current) => current.id === itemss.type).list
                                                ) && (
                                                <Form.Item>
                                                    {/* {getFieldDecorator(`${itemss.num}-second`, {
                                                    initialValue: itemss.operator || undefined,
                                                })( */}
                                                    <Select
                                                        onChange={actions.dataSourceFromChanged.params(itemss)}
                                                        value={itemss.operator || undefined}
                                                        placeholder={locales.labels.pleaseChoice}
                                                    >
                                                        {_find(
                                                            fieldsList,
                                                            (current) => current.id === itemss.type
                                                        ).list.map((items) => {
                                                            return <Option value={items.type}>{items.name}</Option>;
                                                        })}
                                                    </Select>
                                                    {/* )} */}
                                                </Form.Item>
                                            )}
                                            {itemss.type === '1' && (
                                                <Form.Item>
                                                    {/* {getFieldDecorator(`${itemss.num}-three`, {
                                                        initialValue: itemss.value || undefined, */}
                                                    {/* })( */}
                                                    <Input
                                                        style={{ width: '320px' }}
                                                        value={itemss.value}
                                                        onChange={actions.dataSourceFromThreeChanged.params(
                                                            itemss,
                                                            'input'
                                                        )}
                                                    />
                                                    {/* )} */}
                                                </Form.Item>
                                            )}
                                            {itemss.type === '2' && (
                                                <Form.Item>
                                                    {/* {getFieldDecorator(`${itemss.num}-four`, {
                                                        initialValue: itemss.value || undefined,
                                                    })( */}
                                                    {/* <Select
                                                        mode='multiple'
                                                        maxTagCount={2}
                                                        style={{ width: '320px' }}
                                                        value={itemss.value || undefined}
                                                        // labelInValue={true}
                                                        filterOption={false}
                                                        onSearch={actions.onSearchSelect}
                                                        onChange={actions.dataSourceFromThreeChanged.params(
                                                            itemss,
                                                            'select'
                                                        )}
                                                    >
                                                        <For each='items' index='index' of={isSearch ? findAllSamrtTagsItems : props.findAllSamrtTagsItems}>
                                                            <Option key={items.id} value={`${items.id}`}>
                                                                {items.tagName}
                                                            </Option>
                                                        </For>
                                                    </Select> */}
                                                    <TreeSelect
                                                        placeholder={/* 请选择智能标签 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.template.pleaseSelectASmartTag}
                                                        treeDataSimpleMode={{ rootPId: 0 }}
                                                        value={itemss.value || undefined}
                                                        treeData={props.findAllSamrtTagsItems}
                                                        treeCheckable={true}
                                                        treeNodeFilterProp='_title'
                                                        // loadData={onLoadData}
                                                        style={{ width: '320px' }}
                                                        onChange={actions.dataSourceFromThreeChanged.params(
                                                            itemss,
                                                            'treeSelect'
                                                        )}
                                                        // getPopupContainer={() => wrapDom.current!}
                                                        maxTagCount="responsive"
                                                        allowClear
                                                    ></TreeSelect>
                                                    {/* )} */}
                                                </Form.Item>
                                            )}
                                            {itemss.type === '0' && (
                                                <Form.Item>
                                                    {/* {getFieldDecorator(`${itemss.num}-five`, {
                                                        initialValue: itemss.value || undefined,
                                                    })( */}
                                                    <Select
                                                        placeholder={/* 请选择高级条件 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.template.pleaseSelectAdvancedConditions}
                                                        style={{ width: '120px' }}
                                                        value={itemss.value || undefined}
                                                        onChange={actions.dataSourceFromfiveChanged.params(itemss)}
                                                    >
                                                        {!_isEmpty(searchTemplate) &&
                                                            searchTemplate.map((items) => {
                                                                return <Option value={items.id}>{items.name}</Option>;
                                                            })}
                                                    </Select>
                                                    {/* )} */}
                                                </Form.Item>
                                            )}
                                            {itemss.type === '0' && (
                                                <Button onClick={actions.newAddFilter} style={{ marginRight: '10px' }}>{/* 新建高级筛选 */}{UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.template.newAdvancedFilter}</Button>
                                            )}
                                            <Form.Item>
                                                <Input
                                                    type='color'
                                                    defaultValue={itemss.color || undefined}
                                                    style={{ width: '100px' }}
                                                    onChange={actions.InputColorItem.params(itemss.num)}
                                                />
                                            </Form.Item>
                                            <Form.Item label={/* 重命名 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.template.rename}>
                                                <Input
                                                    defaultValue={itemss.conditionName || ''}
                                                    onChange={actions.renameItem.params(itemss)}
                                                />
                                            </Form.Item>
                                            <Tooltip
                                                placement='bottom'
                                                title={
                                                    <div className='custome-filter-tootip-button'>
                                                        <p onClick={actions.displacement.params(itemss.num, 'top')}>{/* 上移 */}{UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.template.moveUp}</p>
                                                        <p onClick={actions.displacement.params(itemss.num, 'bottom')}>{/* 下移 */}{UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.template.moveDown}</p>
                                                        <p onClick={actions.displacement.params(itemss.num, 'left')}>{/* 左移 */}{UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.template.shiftLeft}</p>
                                                        <p onClick={actions.displacement.params(itemss.num, 'right')}>{/* 右移 */}{UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.template.shiftRight}</p>
                                                    </div>
                                                }
                                                overlayClassName='custome-filter-tootip'
                                            >
                                                <div
                                                    className='customer-filter-driver-circle-del'
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tuozhuai1'></i>
                                                </div>
                                            </Tooltip>
                                            <div
                                                className='customer-filter-driver-circle-del'
                                                onClick={actions.delTwoCustome.params(itemss.num)}
                                            >
                                                <i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jianhao'></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!_isEmpty(itemss.children) && (
                                    <BusinessAnalysisFilter
                                        data={itemss.children}
                                        findAllSamrtTagsItems={props.findAllSamrtTagsItems}
                                        searchTemplate={searchTemplate}
                                        // key={`${props.activeTemplatId}-${itemss.num}`}
                                        num={this.props.num}
                                        fieldDelList={this.props.fieldDelList}
                                        fieldAddList={this.props.fieldAddList}
                                        dataSourceChanged={this.props.dataSourceChanged}
                                        dataSourceFromChanged={this.props.dataSourceFromChanged}
                                        dataSourceFromThreeChanged={this.props.dataSourceFromThreeChanged}
                                        dataSourceFromfiveChanged={this.props.dataSourceFromfiveChanged}
                                        newAddFilterFileds={this.props.newAddFilterFileds}
                                        InputColorItemChange={this.props.InputColorItemChange}
                                        displacementChange={this.props.displacementChange}
                                        dataSourceType={this.props.dataSourceType}
                                        style={
                                            itemss.showChildren
                                                ? undefined
                                                : { display: 'none' }
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
            </div>
        );
    }
}
