// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostReviewListV1Body, BaseResponseTaskFilterListDataWrapperDto } from '../../types';

/**
 * queryReviewListV1
 *
 * @export
 * @tags review-controller
 * @link [POST] /review/list-v1
 */
export function postReviewListV1(
    data: PostReviewListV1Body
): Promise<BaseResponseTaskFilterListDataWrapperDto>;
export function postReviewListV1(
    data: PostReviewListV1Body,
    options: ApiOptions<never, never>
): Promise<BaseResponseTaskFilterListDataWrapperDto>;
export function postReviewListV1(
    data: PostReviewListV1Body,
    options?: ApiOptions<never, never>
): Promise<BaseResponseTaskFilterListDataWrapperDto> {
    return request<PostReviewListV1Body, never, never>('/review/list-v1', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewListV1',
    });
}

export const meta = [{ tags: ['review-controller'], path: '/review/list-v1', method: 'post' }];
