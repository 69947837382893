// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PutSubjectMarkFilesByIdParam,
    PutSubjectMarkFilesByIdBody,
    BaseResponseVoid,
    DeleteSubjectMarkFilesByIdParam,
} from '../../types';

/**
 * 更新话题
 *
 * @export
 * @tags 话题标注
 * @link [PUT] /subjectMarkFiles/{id}
 * @param id
 */
export function putSubjectMarkFilesById(
    data: PutSubjectMarkFilesByIdBody,
    options: ApiOptions<PutSubjectMarkFilesByIdParam, never> & {
        segments: PutSubjectMarkFilesByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutSubjectMarkFilesByIdBody, PutSubjectMarkFilesByIdParam, never>(
        '/subjectMarkFiles/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putSubjectMarkFilesById' }
    );
}

/**
 * 删除话题分类
 *
 * @export
 * @tags 话题标注
 * @link [DELETE] /subjectMarkFiles/{id}
 * @param id
 */
export function deleteSubjectMarkFilesById(
    options: ApiOptions<DeleteSubjectMarkFilesByIdParam, never> & {
        segments: DeleteSubjectMarkFilesByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteSubjectMarkFilesByIdParam, never>('/subjectMarkFiles/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteSubjectMarkFilesById',
    });
}

export const meta = [
    { tags: ['话题标注'], path: '/subjectMarkFiles/{id}', method: 'put' },
    { tags: ['话题标注'], path: '/subjectMarkFiles/{id}', method: 'delete' },
];
