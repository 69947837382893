// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostDataCallNumBody, BaseResponseDataCallNumDTO } from '../../types';

/**
 * callNum
 *
 * @export
 * @tags 通对话管理
 * @link [POST] /data/call-num
 */
export function postDataCallNum(data: PostDataCallNumBody): Promise<BaseResponseDataCallNumDTO>;
export function postDataCallNum(
    data: PostDataCallNumBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseDataCallNumDTO>;
export function postDataCallNum(
    data: PostDataCallNumBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseDataCallNumDTO> {
    return request<PostDataCallNumBody, never, never>('/data/call-num', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postDataCallNum',
    });
}

export const meta = [{ tags: ['通对话管理'], path: '/data/call-num', method: 'post' }];
