// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerByRecordIdScorePageDetailQuery,
    GetIntelligentPartnerByRecordIdScorePageDetailParam,
    BaseResponseIntelligentPartnerScorePageDetails,
} from '../../../types';

/**
 * 考试/练习得分页面详情
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [GET] /intelligentPartner/{recordId}/scorePageDetail
 * @param recordId
 */
export function getIntelligentPartnerByRecordIdScorePageDetail(
    options: ApiOptions<
        GetIntelligentPartnerByRecordIdScorePageDetailParam,
        GetIntelligentPartnerByRecordIdScorePageDetailQuery
    > & { segments: GetIntelligentPartnerByRecordIdScorePageDetailParam }
): Promise<BaseResponseIntelligentPartnerScorePageDetails> {
    return request<
        never,
        GetIntelligentPartnerByRecordIdScorePageDetailParam,
        GetIntelligentPartnerByRecordIdScorePageDetailQuery
    >('/intelligentPartner/{recordId}/scorePageDetail', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerByRecordIdScorePageDetail',
    });
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/{recordId}/scorePageDetail',
        method: 'get',
    },
];
