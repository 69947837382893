import React from 'react';
import Udesk from 'Udesk';
import AnomalousDataRoute from './route';
import { Select, Button, Input, PageHeader } from 'udesk-ui';
import _isEmpty from 'lodash-es/isEmpty';
import AutoSizeTable from 'Component/common/auto-size-table';
import UdeskDatePicker from 'Component/common/udesk-date-picker';

import './style.scss';
export default class AnomalousDataTemplate extends AnomalousDataRoute {
    render() {
        let { actions, locales } = this;
        let { sourceList } = this.privates;
        let {
            dataSource,
            statAt,
            columns,
            paging,
            data,
            businessId
        } = this.privates;
        const company = Udesk.data.init.company;
        return (
            // <div className="anomalous-data-pages">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.pages.admin.anomalousData.title}
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root anomalous-data-pages'>
                        <div className="anomalous-data-pages-body">
                            <div>
                                <Select
                                    disabled={!(company.enabledCallInspect !== 0 && company.enabledImInspect !== 0)}
                                    onChange={actions.sourceChange}
                                    placeholder={locales.pages.admin.anomalousData.list.selectHolder}
                                    style={{ width: '120px', marginRight: '10px', marginBottom: '5px' }}
                                    defaultValue={dataSource}>
                                    {
                                        !_isEmpty(sourceList) && sourceList.map(item => {
                                            return (
                                                <Select.Option value={item.value}>{item.text}</Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                                {locales.components.pages.trainingCenter.classifiedTraining.cellTime}：<UdeskDatePicker style={{ marginRight: '10px' }} onChange={actions.timeChange} value={statAt} />
                                {locales.pages.admin.anomalousData.list.businessTime}：<UdeskDatePicker style={{ marginRight: '10px' }} onChange={actions.timeFieldChange} />
                                {locales.pages.admin.anomalousData.list.businessId}：<Input value={businessId} onChange={actions.businessIdChange} style={{ width: '150px', marginRight: '10px' }} />
                                <Button type='primary' className='classified-taining-pager-body-sumbit-time' onClick={actions.sumbitGet}>{locales.components.pages.trainingCenter.classifiedTraining.search}</Button>
                                <Button style={{ marginLeft: '10px' }} onClick={actions.export}>{locales.pages.admin.anomalousData.list.export}</Button>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <AutoSizeTable
                                    columns={columns}
                                    dataSource={data}
                                    rowKey={item => item.id}
                                    pagination={{
                                        onChange: actions.pageNumChange,
                                        total: paging.total,
                                        pageSize: paging.pageSize,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['5', '10', '20', '50', '100'],
                                        onShowSizeChange: actions.pageSizeChange,
                                        showTotal: (total, range) => {
                                            return Udesk.utils.string.format(locales.components.pages.customerServiceRankList.table.page, total, Math.ceil(total / paging.pageSize));
                                        },
                                    }}
                                >
                                </AutoSizeTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// function disabledDate(current) {
//     return current && current < moment().subtract(1, "years");
// }