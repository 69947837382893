// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type { GetExternalUsersQuery, BaseResponseListExternalUserFoundResponse } from '../types';

/**
 * findAll
 *
 * @export
 * @tags external-user-controller
 * @link [GET] /externalUsers
 */
export function getExternalUsers(): Promise<BaseResponseListExternalUserFoundResponse>;
export function getExternalUsers(
    options: ApiOptions<never, GetExternalUsersQuery>
): Promise<BaseResponseListExternalUserFoundResponse>;
export function getExternalUsers(
    options?: ApiOptions<never, GetExternalUsersQuery>
): Promise<BaseResponseListExternalUserFoundResponse> {
    return request<never, never, GetExternalUsersQuery>('/externalUsers', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getExternalUsers',
    });
}

export const meta = [{ tags: ['external-user-controller'], path: '/externalUsers', method: 'get' }];
