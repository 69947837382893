import { useMemo } from 'react';
import { ChartType } from '../../components/header/components/ChartToggle/hooks';
import { getLineAndBarEchartOptions, getPieEchartOptions } from './utils';

type UseChartOptionProps = {
    xField: string;
    yFields: string[];
    staticData: any[];
    chartType: string;
};

export const useChartOption = (props: UseChartOptionProps) => {
    const { xField, yFields, staticData, chartType } = props;

    const chartOption = useMemo(() => {
        if (chartType === ChartType.Line) {
            const xAxisData = staticData.map((d) => d?.[xField]);
            const series = yFields.map((field) => {
                return {
                    name: field,
                    type: 'line',
                    data: staticData.map((d) => d?.[field]),
                    smooth: true,
                };
            });
            return getLineAndBarEchartOptions(xAxisData, series);
        } else if (chartType === ChartType.Bar) {
            const xAxisData = staticData.map((d) => d?.[xField]);
            const series = yFields.map((field) => {
                return {
                    name: field,
                    type: 'bar',
                    data: staticData.map((d) => d?.[field]),
                };
            });
            return getLineAndBarEchartOptions(xAxisData, series);
        } else if (chartType === ChartType.Pie) {
            const series = yFields.map((yf) => {
                return {
                    name: yf,
                    type: 'pie',
                    radius: '50%',
                    data: staticData.map((d) => {
                        return {
                            value: d?.[yf],
                            name: d?.[xField],
                        };
                    }),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                };
            });
            return getPieEchartOptions(series);
        } else {
            return [];
        }
    }, [chartType, staticData, xField, yFields]);

    return {
        chartOption,
    };
};
