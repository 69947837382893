// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerTaskInfosTaskScoreQuery,
    BaseResponseIntelligentPartnerTaskScoreDetail,
} from '../../../types';

/**
 * 获取任务对应课程的总分详情
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/task/score
 */
export function getIntelligentPartnerTaskInfosTaskScore(): Promise<BaseResponseIntelligentPartnerTaskScoreDetail>;
export function getIntelligentPartnerTaskInfosTaskScore(
    options: ApiOptions<never, GetIntelligentPartnerTaskInfosTaskScoreQuery>
): Promise<BaseResponseIntelligentPartnerTaskScoreDetail>;
export function getIntelligentPartnerTaskInfosTaskScore(
    options?: ApiOptions<never, GetIntelligentPartnerTaskInfosTaskScoreQuery>
): Promise<BaseResponseIntelligentPartnerTaskScoreDetail> {
    return request<never, never, GetIntelligentPartnerTaskInfosTaskScoreQuery>(
        '/intelligentPartnerTaskInfos/task/score',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerTaskInfosTaskScore',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/task/score',
        method: 'get',
    },
];
