// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PutInspectionTasksByIdCollectByCollectParam,
    BaseResponseVoid,
} from '../../../../types';

/**
 * collect
 *
 * @export
 * @tags 质检任务
 * @link [PUT] /inspectionTasks/{id}/collect/{collect}
 * @param id
 * @param collect
 */
export function putInspectionTasksByIdCollectByCollect(
    options: ApiOptions<PutInspectionTasksByIdCollectByCollectParam, never> & {
        segments: PutInspectionTasksByIdCollectByCollectParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PutInspectionTasksByIdCollectByCollectParam, never>(
        '/inspectionTasks/{id}/collect/{collect}',
        {
            ...options,
            method: 'put',
            __$requestCalleeName: 'putInspectionTasksByIdCollectByCollect',
        }
    );
}

export const meta = [
    { tags: ['质检任务'], path: '/inspectionTasks/{id}/collect/{collect}', method: 'put' },
];
