import React from 'react';
import { FormBuilder, InputNumber, Select, Space } from 'udesk-ui';
import { getDataDeleteConfig } from '../component';
import UdeskLocales from 'UdeskLocales';

const SettingItem = (props) => {
    const { value = {}, onChange } = props;

    const dataDeleteTimeConfigInterval = getDataDeleteConfig().dataDeleteTimeConfigInterval;

    return (
        <Space>
            <InputNumber
                value={value.interval}
                onChange={(interval) => {
                    onChange({
                        ...value,
                        interval,
                    });
                }}
                style={{ width: 100 }}
            ></InputNumber>
            <Select
                value={value.type}
                onChange={(type) => {
                    onChange({
                        ...value,
                        type,
                    });
                }}
                options={dataDeleteTimeConfigInterval}
                style={{ width: 70 }}
            ></Select>
        </Space>
    );
};

export const DataDeleteConfigItem = (props) => {
    const { usedScopes, onChange, ...initialValues } = props;

    const scopeOptions = getDataDeleteConfig().scopeOptions;

    return (
        <FormBuilder
            initialValues={initialValues}
            onValuesChange={(_, values) => {
                onChange?.(values);
            }}
            customComponents={{ SettingItem }}
            fields={[
                {
                    label: /* 清理范围 */ UdeskLocales['current'].pages.admin.system.components
                        .dataDeleteConfigItem.scopeOfCleaning,
                    type: 'Select',
                    name: 'scope',
                    props: {
                        style: {
                            width: 200,
                        },
                        options: scopeOptions.map((i) => ({
                            ...i,
                            disabled: usedScopes.includes(i.value),
                        })),
                        placeholder: UdeskLocales['current'].labels.pleaseSelect,
                    },
                },
                {
                    label: /* 数据清理时间设置 */ UdeskLocales['current'].pages.admin.system
                        .components.dataDeleteConfigItem.dataCleaningTimeSetting,
                    type: 'SettingItem',
                    name: 'dataDeleteTimeConfig',
                    addonAfter:
                        /* 后，清理数据 */ UdeskLocales['current'].pages.admin.system.components
                            .dataDeleteConfigItem.afterCleanUpTheData,
                },
                {
                    label: /* 申诉完成后数据 */ UdeskLocales['current'].pages.admin.system
                        .components.dataDeleteConfigItem.dataAfterComplaintCompletion,
                    type: 'SettingItem',
                    name: 'complainDeleteTimeConfig',
                    addonAfter:
                        /* 后，清理数据 */ UdeskLocales['current'].pages.admin.system.components
                            .dataDeleteConfigItem.afterCleanUpTheData,
                },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            footerRender={false}
        ></FormBuilder>
    );
};
