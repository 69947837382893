import React from 'react';
import ClassNames from 'classnames';
import Component from './component';
import { Select } from 'udesk-ui';

const format = (data, config) => {
    const {
        valueField,
        nameField, 
        nameFieldPostfix
    } = config;
    const nameFieldValue = data[nameField];
    const result = {
        label: nameFieldValue,
        labelTag: nameFieldValue,
        labelTagValue: nameFieldValue,
        value: data[valueField],
    };
    if (nameFieldPostfix) {
        const postfix = data[nameFieldPostfix];
        if (postfix) {
            result.labelTagValue = `${nameFieldValue}&#xd;${postfix}`;
            result.label = (
                <span dangerouslySetInnerHTML={{
                    __html: `<span title='${result.labelTagValue}'>${nameFieldValue}<br/>${postfix}</span>`,
                }} />
            );
        }
    }
    return result;
};

class SelectTemplate extends Component {
    render() {
        let {
            privates: {
                computes,
            },
        } = this;
        let { mergedAttributes } = computes;
        let {
            placeholder,
            mode,
            firstValueClassName,
        } = mergedAttributes;
        let { disabled, value } = this.props;

        let selectProps = {
            dropdownRender: (node) => <div>{node}</div>,
            value,
            disabled,
            options: this.props.dataSource.map(item => format(item, mergedAttributes)),
            showSearch: true,
            optionFilterProp: "labelTagValue",
            optionLabelProp: "labelTag",
            maxTagTextLength: 20,
            placeholder,
            onChange: this.actions.onChanged,
            className: ClassNames(firstValueClassName, 'custom-filter-value-select'),
        };
        if (mode) {
            selectProps.mode = mode;
            selectProps.value = Array.isArray(value) ? value : (value ? [value] : []);
        }
        return (
            <div className='component-custom-filter-antd-select'>
                <Select {...selectProps}></Select>
            </div>
        );
    }
}
export default SelectTemplate;
