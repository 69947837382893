import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';

import 'reactflow/dist/style.css';
import { ConfigDrawer } from './components/ConfigDrawer';
// import { postReviewIntelligentPartnerCourse } from 'src/api/review/intelligentPartner/course';
import { Button, Icon, message } from 'udesk-ui';
import { CustomerPortraitConfig } from 'Component/pages/components/coach/CustomerPortrait';
import { CustomerPortraitConfigDrawer } from './components/CustomerPortraitConfigDrawer';
import { Flow } from './components/Flow/index';
import { GlobalConfigDrawer } from './components/GlobalConfigDrawer';
import { postReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowId } from 'src/api/review/intelligentPartner/dialogFlow/publish/{courseId}/{flowId}';
import PPTTestDrawer, { TestRefType } from './components/PPTTestDrawer';
import UdeskLocales from 'UdeskLocales';
import { useRequest, useSdkOptions } from 'src/util/hook';
import { getReviewIntelligentPartnerCourseById } from 'src/api/review/intelligentPartner/course/{id}';
import { inspect } from 'src/util/core';
import ImportModal from 'Component/common/import-modal';
import { postReviewIntelligentPartnerDialogFlowImportByFlowId } from 'src/api/review/intelligentPartner/dialogFlow/import/{flowId}';
import { postReviewIntelligentPartnerDialogFlowBatchCheckByFlowId } from 'src/api/review/intelligentPartner/dialogFlow/batchCheck/{flowId}';
import { AiGenFlowDrawer } from './components/AiGenFlowDrawer';
import { AiGenPPTDrawer } from './components/AiGenPPTDrawer';

// const noop = () => {};

const Template = React.memo((props: any) => {
    // const locales = Locales['current'];
    const courseId = props.match.params.id;
    const taskId = props.match.params.taskId;
    const { readOnly } = props;
    const {
        isPPTFlow,
        isAiGenFlow,
        evaluationMode,
        requestFlowAndInit,
        setNodes,
        sideBarVisible,
        courseType,
        pictureNodes,
        ...flowProps
    } = Flow.useFlow(courseId, readOnly, taskId);
    const customerPortraitConfigContext = CustomerPortraitConfig.useCustomerPortraitConfig(
        flowProps.flowId
    );

    const requestFlowData = (successCallback = () => {}) => {
        requestFlowAndInit(courseId).then(({ id }) => {
            const queryString = window.location.search;
            const urlParams: any = new URLSearchParams(queryString);
            const imageId = urlParams.get('imageId');
            readOnly && pictureNodes(id, imageId);
            successCallback?.();
        });
    };

    const {
        onNodeClick,
        currentSelectedNode,
        clearSelectedNode,
        initialValues,
        ...nodeConfigDrawerProps
    } = ConfigDrawer.useConfigDrawer({
        setNodes,
        flowId: flowProps.flowId,
        requestFlowData,
        readOnly,
        courseId,
    });

    const { onClose: closeNodeConfigDrawer } = nodeConfigDrawerProps;

    const { openDrawer: openCustomerPortraitConfigDrawer, ...customerPortraitConfigDrawerProps } =
        CustomerPortraitConfigDrawer.useDrawer();
    const { openDrawer: openGlobalConfigDrawer, ...globalConfigDrawerProps } =
        GlobalConfigDrawer.useDrawer();

    useEffect(() => {
        requestFlowData();
    }, []);

    const onBack = useCallback(() => {
        if (readOnly) {
            window.history.back();
            return;
        }
        let routeOptions = {
            history: props.history,
            routeName: 'coachLearningCenterCourseConfig',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    }, [props.history, readOnly]);

    const { onOpen, ...aiGenFlowDrawerProps } = AiGenFlowDrawer.useConfigDrawer({
        flowId: flowProps.flowId,
        requestFlowData,
        allCustomerPortrait: customerPortraitConfigContext.allCustomerPortrait,
        reactFlowInstance: flowProps.reactFlowInstance,
    });

    const { onOpen: onAiGenPPTOpen, ...aiGenPPTDrawerProps } = AiGenPPTDrawer.useConfigDrawer({
        flowId: flowProps.flowId,
        requestFlowData,
        reactFlowInstance: flowProps.reactFlowInstance,
    });

    const onCheckRules = useCallback(() => {
        postReviewIntelligentPartnerDialogFlowBatchCheckByFlowId({
            segments: { flowId: flowProps.flowId },
        }).then(() => {
            message.success(/* 校验通过 */UdeskLocales['current'].pages.coach.courseManagement.flow.index.verificationPassed);
        });
    }, [flowProps.flowId]);

    const extra = useMemo(() => {
        if (readOnly)
            return [
                <Button
                    onClick={() => {
                        window.open(`/coach/course-management/flow/${courseId}`);
                    }}
                    type="primary"
                >
                    {/* 编辑课程 */}
                    {UdeskLocales['current'].pages.coach.courseManagement.flow.index.editCourse}
                </Button>,
            ];

        return [
            courseType === Udesk.enums.lessonType.dialogue.id ? (
                <Button type="primary" onClick={onOpen}>{/* AI生成 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.index.aIGeneration}</Button>
            ) : null,
            courseType === Udesk.enums.lessonType.ppt.id ? (
                <Button type="primary" onClick={onAiGenPPTOpen}>{/* 生成全文演讲话术 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.index.generateFullTextSpeechScript}</Button>
            ) : null,
            <ImportButton
                disabled={flowProps?.nodes?.length > 0}
                id={courseId}
                flowId={flowProps.flowId}
                successCallback={requestFlowData}
            />,
            <Button
                onClick={() => {
                    let routeOptions = {
                        history: props.history,
                        routeName: 'courseManagementDetail',
                        pathParams: { id: courseId },
                    };
                    Udesk.ui.routing.transitionTo(routeOptions);
                }}
            >
                {/* 编辑基本信息 */}
                {
                    UdeskLocales['current'].pages.coach.courseManagement.flow.index
                        .editBasicInformation
                }
            </Button>,
            [Udesk.enums.lessonType.dialogue.id, Udesk.enums.lessonType.ppt.id].includes(
                courseType
            ) ? (
                <Button onClick={() => onCheckRules()}>{/* 规则校验 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.index.ruleValidation}</Button>
            ) : null,
            ((sideBarVisible && !isPPTFlow) || isAiGenFlow) && (
                <Button
                    onClick={() => {
                        let routeOptions = {
                            history: props.history,
                            routeName: 'coachNodeConfigTest',
                            pathParams: { id: flowProps.flowId },
                            queryParams: { courseId },
                        };
                        Udesk.ui.routing.transitionTo(routeOptions);
                    }}
                >
                    {/* 测试 */}
                    {UdeskLocales['current'].pages.coach.courseManagement.flow.index.test}
                </Button>
            ),
            <Button
                type="primary"
                onClick={() => {
                    postReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowId({
                        segments: {
                            courseId,
                            flowId: flowProps.flowId,
                        },
                    }).then(onBack);
                }}
            >
                {/* 发布 */}
                {UdeskLocales['current'].pages.coach.courseManagement.flow.index.release}
            </Button>,
        ].filter(Boolean);
    }, [
        readOnly,
        courseType,
        onOpen,
        onAiGenPPTOpen,
        flowProps?.nodes?.length,
        flowProps.flowId,
        courseId,
        requestFlowData,
        sideBarVisible,
        isPPTFlow,
        isAiGenFlow,
        props.history,
        onCheckRules,
        onBack,
    ]);

    const testRef = useRef<TestRefType>(null);
    return (
        <Page
            pageBodyClassName="udesk-qa-web-course-management-detail"
            title={courseType ? Udesk.enums.lessonType.getName(courseType) : ' '}
            padding={false}
            backGroundGhost={true}
            onBack={onBack}
            extra={extra}
        >
            <div className="dndflow">
                {!readOnly && sideBarVisible && (
                    <Flow.Sidebar isPPTFlow={isPPTFlow} courseType={courseType} />
                )}
                <Flow
                    {...flowProps}
                    isPPTFlow={isPPTFlow}
                    evaluationMode={evaluationMode}
                    currentSelectedNode={currentSelectedNode}
                    onNodeClick={(event: React.MouseEvent, node) =>
                        onNodeClick(event, node, flowProps.nodes)
                    }
                    onNodesDelete={(...args: any) => {
                        flowProps.onNodesDelete.apply(null, args);
                        closeNodeConfigDrawer();
                        clearSelectedNode();
                    }}
                    onPaneClick={() => {
                        closeNodeConfigDrawer();
                        clearSelectedNode();
                    }}
                    readOnly={readOnly}
                />
                <CustomerPortraitConfig.CustomerPortraitConfigProvider
                    value={customerPortraitConfigContext}
                >
                    <ConfigDrawer
                        initialValues={initialValues}
                        onOpenTestDrawer={() => testRef.current?.open(nodeConfigDrawerProps.node)}
                        {...nodeConfigDrawerProps}
                        readOnly={readOnly}
                        // triggerFlowNodeNameChange={}
                    />
                    <CustomerPortraitConfigDrawer
                        {...customerPortraitConfigDrawerProps}
                        readOnly={readOnly}
                    />
                    <GlobalConfigDrawer
                        {...globalConfigDrawerProps}
                        flowId={flowProps.flowId}
                        readOnly={readOnly}
                    />
                    <PPTTestDrawer
                        ref={testRef}
                        flowId={flowProps.flowId}
                        initialValues={initialValues}
                        evaluationMode={evaluationMode}
                    />
                    <AiGenFlowDrawer {...aiGenFlowDrawerProps} />
                    <AiGenPPTDrawer {...aiGenPPTDrawerProps} />
                </CustomerPortraitConfig.CustomerPortraitConfigProvider>
                <div className="global-setting-btn" onClick={openCustomerPortraitConfigDrawer}>
                    <Icon type="UserOutlined" antdIcon={true} />
                </div>
                <div
                    className="global-setting-btn"
                    style={{ left: 40 }}
                    onClick={openGlobalConfigDrawer}
                >
                    <Icon type="SettingOutlined" antdIcon={true} />
                </div>
            </div>
        </Page>
    );
});

export const ViewFlow = Udesk.react.udeskify(
    class extends React.Component {
        render() {
            return <Template {...this.props} readOnly={true} />;
        }
    }
);

export default Udesk.react.udeskify(
    class extends React.Component {
        render() {
            return <Template {...this.props} />;
        }
    }
);

const ImportButton = React.memo<{
    id: number;
    flowId: number;
    disabled: boolean;
    successCallback: Function;
}>((props) => {
    const { data } = useRequest({
        manual: false,
        defaultApi: getReviewIntelligentPartnerCourseById,
        defaultParams: [
            {
                segments: {
                    id: props.id,
                },
            },
        ],
    });
    const sdkOptions = useSdkOptions();

    const {
        handleOnCancel,
        handleOnOk,
        isUploadVisible,
        uploadFile,
        fileRef,
        file,
        clearFile,
        showImportModal,
    } = ImportModal.useImportModal({
        isShowImportSuccessNotification: false,
        accept: ['xls', 'xlsx'],
        request({ uploadData }) {
            return postReviewIntelligentPartnerDialogFlowImportByFlowId(
                {
                    keyName: uploadData.key,
                    name: uploadData.name,
                    downloadPath: uploadData.key,
                    host: uploadData.token.host,
                },
                {
                    segments: {
                        flowId: props.flowId,
                    },
                }
            );
        },
        formatParams: useCallback((file, uploadData) => {
            return {
                file,
                uploadData,
            };
        }, []),
    });

    const downloadTemplate = () => {
        let templateLocalUrl = `/import/coach/dialog-flow-template.xlsx`;
        let templateUrl =
            Udesk.business.apiPath.getStaticBasePath(
                sdkOptions.props.domain,
                sdkOptions.props.pathPrefix
            ) + templateLocalUrl;

        (window as any).location = templateUrl;
    };

    return inspect(Udesk.enums.lessonType.dialogue.id === data?.courseType, () => (
        <>
            <Button
                disabled={props.disabled}
                onClick={showImportModal}
                icon={<Icon type="tianjia1" />}
            >
                {/* 导入对话 */}
                {UdeskLocales['current'].pages.coach.courseManagement.flow.index.importConversation}
            </Button>
            <ImportModal
                onCancel={() => {
                    clearFile();
                    handleOnCancel();
                }}
                onOk={() => handleOnOk().then(props.successCallback).then(clearFile)}
                visible={isUploadVisible}
                fileRef={fileRef}
                uploadFile={uploadFile}
                title={
                    /* 导入对话 */ UdeskLocales['current'].pages.coach.courseManagement.flow.index
                        .importConversation
                }
                file={file}
            >
                <div className="form-group">
                    <div className="form-group">
                        {UdeskLocales['current'].labels.numOne}
                        {UdeskLocales['current'].labels.colon}&nbsp;&nbsp;
                        {UdeskLocales['current'].labels.click}&nbsp;&nbsp;
                        <span className="upload-file-template" onClick={downloadTemplate}>
                            {UdeskLocales['current'].labels.downloadTemplate}
                        </span>
                        &nbsp;&nbsp;
                        {UdeskLocales['current'].labels.fillInTheForm}
                    </div>
                    <div className="form-group">
                        {UdeskLocales['current'].labels.numTwo}
                        {UdeskLocales['current'].labels.colon}&nbsp;&nbsp;
                        {
                            /* 上传Excel完成对话导入 */ UdeskLocales['current'].pages.coach
                                .courseManagement.flow.index.uploadExcelToCompleteDialogueImport
                        }
                    </div>
                </div>
            </ImportModal>
        </>
    ));
});
