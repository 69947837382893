// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PutIntelligentPartnerTaskFinishRecordsShareLogByShareIdStatusParam,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 修改分享记录的查看状态
 *
 * @export
 * @tags 智能陪练-历史学习数据
 * @link [PUT] /intelligentPartnerTaskFinishRecords/shareLog/{shareId}/status
 * @param shareId
 */
export function putIntelligentPartnerTaskFinishRecordsShareLogByShareIdStatus(
    options: ApiOptions<
        PutIntelligentPartnerTaskFinishRecordsShareLogByShareIdStatusParam,
        never
    > & { segments: PutIntelligentPartnerTaskFinishRecordsShareLogByShareIdStatusParam }
): Promise<BaseResponseVoid> {
    return request<
        never,
        PutIntelligentPartnerTaskFinishRecordsShareLogByShareIdStatusParam,
        never
    >('/intelligentPartnerTaskFinishRecords/shareLog/{shareId}/status', {
        ...options,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerTaskFinishRecordsShareLogByShareIdStatus',
    });
}

export const meta = [
    {
        tags: ['智能陪练-历史学习数据'],
        path: '/intelligentPartnerTaskFinishRecords/shareLog/{shareId}/status',
        method: 'put',
    },
];
