import Udesk from 'Udesk';

let tokenData = null;
let requestTimestamp = 0;
const expDuration = 1 * 60 * 1000;
function getToken(url, sdkOptions, that) {
    if(sdkOptions.methods.getToken && typeof sdkOptions.methods.getToken === 'function'){
        const now = Date.now();
        if (now - requestTimestamp < expDuration && tokenData) {
            return new Promise(resolve => resolve(tokenData));
        } else {
            return sdkOptions.methods.getToken().then(data => {
                let token = data || {};
                const { props } = that.props.sdkOptions;
                requestTimestamp = Date.now();
                tokenData = token;
                that.props.sdkOptions.props = { ...tokenData, ...props };
                return token;
            });
        }    
    }else if(sdkOptions.props && sdkOptions.props.signature && sdkOptions.props.timestamp){
        return new Promise(resolve => resolve(tokenData));
    }
}

function resetToken() {
    tokenData = null;
}
//请求
function get(url, sdkOptions, that, params) {
    return getToken(url, sdkOptions, that).then(
        (res) => {
            let integratedUrl = Udesk.business.apiPath.concatApiPath(url, that.props.sdkOptions, null, true);
            if (params) {
                return Udesk.ajax.get(integratedUrl, params);
            } else {
                return Udesk.ajax.get(integratedUrl);
            }
        }
    );
}

function post(url, sdkOptions, that, params) {
    return getToken(url, sdkOptions, that).then(
        (res) => {
            let integratedUrl = Udesk.business.apiPath.concatApiPath(url, that.props.sdkOptions, null, true);
            if (params) {
                return Udesk.ajax.post(integratedUrl, params);
            } else {
                return Udesk.ajax.post(integratedUrl);
            }
        }
    );
}
export default {
    get,
    post,
    resetToken,
};