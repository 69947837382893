import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

const Div = styled.div`
    height: 100%;
    .udesk-qa-web-page {
        position: relative;
        overflow: hidden;
    }
`;

class AdminUserEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { routes, route, location, sdkOptions } = this.props as any;

        if (this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <Div>
                    {routes.map((route, i) =>
                        !route.authCode ||
                        (route.authCode && Udesk.data.init.user.hasFeature(route.authCode)) ? (
                                <SubRoutes key={i} route={route} />
                            ) : null
                    )}
                </Div>
            );
        }
    }
}
export default Udesk.react.udeskify(AdminUserEntry);
