import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { notification, Icon, Button } from 'udesk-ui';
import { getInspectionDataRecheckStatus } from 'src/api/inspectionData/recheck/status';
import { fire, inspect, isDefined, isEmpty, isNotEmpty } from 'src/util/core';
import { getDateTimeString } from 'src/util/moment';
import { LoadingOutlined } from '@ant-design/icons';
import { InspectRecheckRecordFoundResponse as InspectRecheckRecord } from 'src/api/types';
import { linkTo } from 'src/util/link';
import { putInspectionDataRecheckByRecordIdStatus } from 'src/api/inspectionData/recheck/{recordId}/status';
import uniqBy from 'lodash-es/uniqBy';

interface RecheckModalPropsType {
    history: any;
    inspectionId?: number;
    taskId: number;
    onSuccess?: () => void;
}

const Template: React.FC<RecheckModalPropsType> = (props) => {
    const { history, taskId, inspectionId, onSuccess } = props;
    const [manualList, setManualList] = useState<InspectRecheckRecord[]>([]);
    const [delList, setDelList] = useState<InspectRecheckRecord[]>([]);
    const [listForReloadPage, setListForReloadPage] = useState<InspectRecheckRecord[]>([]);

    const changeViewStatus = useCallback((recordId: number, callback?: Function) => {
        putInspectionDataRecheckByRecordIdStatus({
            segments: {
                recordId,
            },
        }).then((resp) => {
            fire(callback, resp);
        });
    }, []);

    const showControllerNotification = useCallback(() => {
        notification.open({
            prefixCls: 'udesk-qa-ui-notification',
            message: /* 关闭所有通知 */UdeskLocales['current'].components.reInspectionResultModal.index.turnOffAllNotifications,
            duration: null,
            key: 'ControllerNotification',
            description: null,
            onClose() {
                setDelList([...manualList]);
                manualList.forEach((m) => {
                    const key = m.manualLoadId?.toString();
                    key && notification.close(key);
                    changeViewStatus(m.id!);
                });
            },
        });
    }, [changeViewStatus, manualList]);

    const showNotification = useCallback(
        (data: InspectRecheckRecord) => {
            notification.open({
                prefixCls: 'udesk-qa-ui-notification',
                message:
                    /* 重新质检任务 */ UdeskLocales['current'].components.reInspectionResultModal
                        .index.reQualityInspectionTask,
                duration: null,
                key: data.manualLoadId?.toString(),
                description: (
                    <TaskItem history={history} changeViewStatus={changeViewStatus} {...data} />
                ),
                onClose() {
                    setDelList((list) => uniqBy([...list, data], 'id'));
                    changeViewStatus(data.id!);
                },
            });
        },
        [changeViewStatus, history]
    );

    const successHandle = useCallback(
        (data: InspectRecheckRecord[]) => {
            if (!inspectionId) {
                return;
            }
            const newList = data.filter((item) => {
                if (item.inspectionId === inspectionId && item.taskId === taskId) {
                    return item.taskStatus === Udesk.enums.recheckStatus.success.id;
                }
                return false;
            });

            setListForReloadPage((prev) => {
                const next = newList.filter((item) => {
                    return !prev.some((manual) => manual.manualLoadId === item.manualLoadId);
                });

                return isEmpty(next, prev, () => next.concat(prev));
            });
        },
        [inspectionId, taskId]
    );

    const getStatus = useCallback(
        (callback?: Function) => {
            getInspectionDataRecheckStatus({
                params: {
                    taskId,
                    inspectionId,
                },
            }).then((resp) => {
                const { data } = resp;
                isNotEmpty(data, () => {
                    fire(callback, data);
                    successHandle(data!);
                    setManualList(
                        data!.filter((item) => {
                            return !delList.some(
                                (manual) => manual.manualLoadId === item.manualLoadId
                            );
                        })
                    );
                });
            });
        },
        [delList, inspectionId, successHandle, taskId]
    );

    useEffect(() => {
        const timer = setInterval(getStatus, 5000);

        return () => {
            clearInterval(timer);
        };
    }, [getStatus]);

    useEffect(() => {
        if (listForReloadPage?.length > 0) {
            onSuccess?.();
        }
    }, [listForReloadPage]);

    useEffect(() => {
        if (manualList.length) {
            showControllerNotification();
            manualList.forEach(showNotification);
        }
    }, [manualList, showControllerNotification, showNotification]);

    return null;
};

export default React.memo(Template);

const TaskItem = React.memo<InspectRecheckRecord & { history: any; changeViewStatus: Function }>(
    (props) => {
        const {
            history,
            id,
            callId,
            inspectionId,
            manualLoadId,
            taskId,
            taskName,
            taskStatus,
            finishTime,
            filterCondition,
            changeViewStatus,
        } = props;

        const name = useMemo(() => {
            return [
                isDefined(callId, callId, taskName),
                /* 重新质检 */ UdeskLocales['current'].components.reInspectionResultModal.index
                    .requalityInspection,
            ].join('-');
        }, [callId, taskName]);

        const dataTime = useMemo(() => {
            return finishTime || getDateTimeString();
        }, [finishTime]);

        const goLink = useCallback(() => {
            changeViewStatus(id, () => {
                isDefined(
                    inspectionId,
                    () => {
                        linkTo(history, 'qualityInspectionListDetail', {
                            taskId,
                            id: inspectionId,
                        });
                    },
                    () => {
                        linkTo(
                            history,
                            'qualityInspectionListIndex',
                            {
                                taskId,
                            },
                            filterCondition
                        );
                    }
                );

                if (manualLoadId) {
                    notification.close(manualLoadId.toString());
                }
            });
        }, [changeViewStatus, filterCondition, history, id, inspectionId, manualLoadId, taskId]);

        return (
            <TaskDiv>
                <Icon type="zhijianpingfen"></Icon>
                <div>
                    <span>{name}</span>
                    <div>
                        <span className={'data-time'}>{dataTime}</span>
                        {inspect(
                            taskStatus === Udesk.enums.recheckStatus.waitForExecution.id,
                            () => (
                                <>
                                    <span>
                                        {/* 等待中 */}
                                        {
                                            UdeskLocales['current'].components
                                                .reInspectionResultModal.index.waiting
                                        }
                                    </span>
                                </>
                            )
                        )}
                        {inspect(taskStatus === Udesk.enums.recheckStatus.inExecution.id, () => (
                            <>
                                <LoadingOutlined />
                            </>
                        ))}
                        {inspect(taskStatus === Udesk.enums.recheckStatus.success.id, () => (
                            <>
                                <Icon type="CheckCircleFilled" antdIcon={true}></Icon>
                                <span>{UdeskLocales['current'].enums.complainStatus.success}</span>
                            </>
                        ))}
                        {inspect(taskStatus === Udesk.enums.recheckStatus.failed.id, () => (
                            <>
                                <Icon type="CloseCircleFilled" antdIcon={true}></Icon>
                                <span>{UdeskLocales['current'].enums.complainStatus.fail}</span>
                            </>
                        ))}
                    </div>
                </div>
                <Button prefixCls="udesk-qa-ui-btn" type="link" size="small" onClick={goLink}>
                    {/* 查看详情 */}
                    {UdeskLocales['current'].components.reInspectionResultModal.index.viewDetails}
                </Button>
            </TaskDiv>
        );
    }
);

const TaskDiv = styled.div`
    display: flex;
    justify-content: space-between;

    > span {
        flex: 0 0 24px;
        font-size: 24px;
        color: #458fff;
        margin-right: 16px;
    }

    > div {
        flex: 1 1 auto;

        > span {
            font-size: 14px;
        }
        > div {
            color: #555;

            > span {
                margin-right: 8px;

                &.data-time {
                    font-size: 12px;
                    color: #a0a0a0;
                }

                &[role='img'] {
                    font-size: 16px;
                }

                &.icon-udesk-qa-react-web-CheckCircleFilled {
                    color: #0fba26;
                }

                &.icon-udesk-qa-react-web-CloseCircleFilled {
                    color: #ff4d4f;
                }
            }
        }
    }
`;
