import Recorder, { RealTimeSendTry, RealTimeSendTryReset } from './util';
import UdeskLocales from 'UdeskLocales';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { sha256 } from 'js-sha256';
import { fire, isEmpty } from 'src/util/core';
import { UploadFile } from 'src/util/upload';
import { useDebounce } from 'src/util/hook';
import { postIntelligentPartnerWordsCorrect } from 'src/api/intelligentPartner/words/correct';
import Udesk from 'Udesk';

export const TIMEOUT_PERIOD = 6; // 单位秒， 在未发出声音6秒后将自动提交

let useProConfig = false; // 测试使用，生产环境下需要关闭
let [AsrWebsocketUrl, appId, appSecret] = JSON.parse(process.env.REACT_APP_ASR || '[]');

if (useProConfig) {
    AsrWebsocketUrl = 'asr-service.s4.udesk.cn'; // 生产环境
    appId = '2016';
    appSecret = 'fd4d7d03-03ea-48a3-b472-a8e2001819fa';
}

type TextListType = {
    text: string;
    isLoading?: boolean;
    isCorrected?: boolean;
    isLineOver?: boolean;
};

const useCorrect = (setList) => {
    const callback = useCallback((current, last) => {
        last.isCorrected = last.isLineOver;
        if (current.textList?.every((item) => item.isCorrected) && current.isOver) {
            current.isAsrOver = true;
            delete current.textList;
        }
    }, []);

    const ajax = useCallback(
        (current, last) => {
            last.isLoading = true;
            postIntelligentPartnerWordsCorrect({
                words: [last.text],
            })
                .then((resp) => {
                    last.text = resp.data?.join() || '';
                    current.text = current.textList?.reduce(
                        (text, item) => (text += item.text),
                        ''
                    );
                    callback(current, last);
                    setList((list) => [...list]);
                })
                .finally(() => {
                    last.isLoading = false;
                });
        },
        [callback, setList]
    );

    const correctText = useCallback(
        (current, last) => {
            if (!last.isCorrected && !last.isLoading) {
                if (isEmpty(last.text) || !last.isLineOver) {
                    callback(current, last);
                } else {
                    ajax(current, last);
                }
            }
        },
        [ajax, callback]
    );

    return correctText;
};

const useAsr = (setList) => {
    const correctText = useCorrect(setList);
    const isCreatingRef = useRef<boolean>(false);
    const websocketRef = useRef<WebSocket>();
    const isLastFrameRef = useRef<boolean>(false);
    const allFrame = useRef<any[]>([]);

    const createAsrWebsocket = useCallback(
        (callback?) => {
            if (isCreatingRef.current) {
                return;
            } else {
                isCreatingRef.current = true;
            }
            const timestamp = Math.floor(new Date().getTime() / 1000);
            const sign = sha256.hex([appId, appSecret, timestamp].join(''));

            const websocket: WebSocket = new WebSocket(
                `wss://${AsrWebsocketUrl}/v1/iat?app_id=${appId}&sign=${sign}&timestamp=${timestamp}&group_id=${Udesk.data.init.company?.id}`
            );

            websocket.onerror = function () {
                isCreatingRef.current = false;
            };

            //开启连接open后客户端处理方法
            websocket.onopen = function () {
                console.log(
                    /* 当前客户端已经连接到ASR Websocket服务器 */ UdeskLocales['current'].pages
                        .coach.learningCenter.components.record.index
                        .theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer
                );
                fire(callback);
                isCreatingRef.current = false;
            };
            // 接收消息后客户端处理方法
            websocket.onmessage = function (evt) {
                const result = JSON.parse(evt.data);
                const ls = result.data.result.ls;
                const ws = result.data.result.ws?.[0]?.cw?.[0]?.w;
                if (result.code === 0) {
                    let hasNotHandle = true;
                    setList((list) => {
                        const current = list.find((item) => !item.isAsrOver);
                        if (current && hasNotHandle) {
                            hasNotHandle = false;

                            current.textList = current.textList || new Array<TextListType>();
                            // 获得最后一个未转译结束的子项
                            let last = current.textList.find((item) => !item.isLineOver);
                            if (!last) {
                                last = {
                                    text: '',
                                    isLineOver: false,
                                };
                                current.textList.push(last);
                            }

                            // 如果ASR返回结果已标记结束，这个将在子项中进行标记
                            // 如果后面还需继续录音，将新建子项进行缓存
                            if (ls) {
                                last.isLineOver = ls;
                            }
                            last.text = ws;

                            current.text = current.textList.reduce(
                                (text, item) => (text += item.text),
                                ''
                            );

                            correctText(current, last); // 添加ASR转译纠错功能

                            /* 未使用ASR转译功能需要添加以下代码
                        current.isAsrOver = current.isOver;
                        //当最后一帧时，将清空缓存
                        if (current.isOver) {
                            delete current.textList;
                        }
                        */
                        }
                        return [...list];
                    });
                    // 处理完最后一帧后， 将状态恢复
                    if (isLastFrameRef.current) {
                        console.log(
                            /* 处理完最后一帧后， 将状态恢复 */ UdeskLocales['current'].pages.coach
                                .learningCenter.components.record.index
                                .afterProcessingTheLastFrameRestoreTheState
                        );
                        isLastFrameRef.current = false;
                    }
                }
            };
            // 关闭websocket
            websocket.onclose = function () {
                console.log(
                    /* ASR连接已关闭... */ UdeskLocales['current'].pages.coach.learningCenter
                        .components.record.index.aSRConnectionClosed
                );
            };

            return (websocketRef.current = websocket);
        },
        [correctText, setList]
    );

    // 通过websocket发送消息到服务器
    // 这里是通过数组长度决定发送次数的， 这个函数可能会被多个线程多次执行，但是执行数量和顺序不变
    const sendMessage = useCallback(() => {
        const list = allFrame.current;
        const asrWebsocket: any = websocketRef.current;

        if (!isLastFrameRef.current) {
            const frame = list.shift();

            if (frame) {
                const status = frame.status;
                const message: {
                    data: any;
                    option?: any;
                } = {
                    data: {
                        ...frame,
                        status: status === 3 ? 2 : status,
                    },
                };
                if (status === 0) {
                    message.option = {
                        app_id: appId,
                        language: 'zh_cn',
                        app_bussiness_token: '2008',
                        app_group_id: '9527',
                        domain: 'iat',
                        dwa: 'subst',
                        accent: 'mandarin',
                    };
                }
                // 记录最后一帧的状态
                if (status === 2) {
                    isLastFrameRef.current = true;
                }
                // 暂定时将结束ASR转译，但是并会不会标记当前为结束帧。
                // console.log('ASR正在向后台发送数据---------->', frame, list.length);
                asrWebsocket.send(JSON.stringify(message));
                sendMessage();
            }
        } else {
            // 如果是最后一帧，将等待300毫秒再执行发送命令
            if (list.length > 0) {
                // setTimeout(sendMessage, 300);
                console.log(
                    /* 录音暂停，并且已处理完，但是缓存中仍然存在数据 */ UdeskLocales['current']
                        .pages.coach.learningCenter.components.record.index
                        .recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache
                );
            }
        }
    }, []);

    /**
     * @status 0:第一帧，1:中间帧，2:最后一帧 3:录音暂停，但是会向ASR服务发送 status = 2
     * @audio Base64文本
     */
    const updateAsrInfo = useCallback(
        (status: number, audio: string | null) => {
            const asrWebsocket: any = websocketRef.current;

            // 将数据放到缓存中
            if (status !== undefined) {
                allFrame.current.push({
                    status,
                    format: 'audio/L16;rate=8000',
                    encoding: 'rwa',
                    audio,
                });
            }

            if (asrWebsocket) {
                if (asrWebsocket.readyState === WebSocket.OPEN) {
                    sendMessage();
                }
                if (asrWebsocket.readyState === WebSocket.CONNECTING) {
                    setTimeout(updateAsrInfo, 10);
                }
                if ([WebSocket.CLOSED, WebSocket.CLOSING].includes(asrWebsocket.readyState)) {
                    console.log(
                        /* 发现ASR通道关闭，重新创建Websocket链接 */ UdeskLocales['current'].pages
                            .coach.learningCenter.components.record.index
                            .foundASRChannelClosedRecreateWebsocketLink
                    );
                    createAsrWebsocket(() => {
                        sendMessage();
                    });
                }
            } else {
                console.log(
                    /* 发现ASR通道未开启，重新创建Websocket链接 */ UdeskLocales['current'].pages
                        .coach.learningCenter.components.record.index
                        .foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink
                );
                createAsrWebsocket(() => {
                    sendMessage();
                });
            }
        },
        [createAsrWebsocket, sendMessage]
    );

    useEffect(() => {
        return () => {
            websocketRef.current?.close();
            websocketRef.current = undefined;
        };
    }, []);

    return [updateAsrInfo];
};

const useWave = (list, lineCount = 120) => {
    const recordWaveListRef = useRef<any[]>([]);

    const createRecordWave = useCallback((elem) => {
        return Recorder.FrequencyHistogramView({
            elem, //自动显示到dom，并以此dom大小为显示大小
            lineCount,
            position: 0,
            minHeight: 1,
            fallDuration: 400,
            stripeEnable: false,
            // mirrorEnable: true,
            linear: [0, '#1A6EFF', 1, '#4FAAFF'],
        });
    }, []);

    const updateWave = useCallback((buffer: any, powerLevel: number, bufferSampleRate: number) => {
        recordWaveListRef.current = list.map((elem, index) => {
            if (elem) {
                if (typeof elem == 'string') {
                    elem = document.querySelector(elem);
                } else if (elem.length) {
                    elem = elem[0];
                }
            }
            let wave = recordWaveListRef.current?.[index];
            if (wave) {
                if (elem === wave.elem) {
                    return wave;
                }
            }
            return createRecordWave(elem);
        });
        recordWaveListRef.current.forEach((wave) => {
            wave.input(buffer, powerLevel, bufferSampleRate);
        });
    }, []);

    useEffect(() => {
        return () => {
            recordWaveListRef.current?.forEach((wave) => {
                wave.elem.innerHTML = '';
            });
            recordWaveListRef.current = [];
        };
    }, []);

    return [updateWave];
};

type AudioFile = {
    id: number; // 前端自增长ID，由前端自己生成
    text: string; // 转译文本
    blob: Blob | null; // 文件内容
    duration: number; // 文件时长
    url: string; // 文件路径, 默认为本地路径，上传成功后会更改为网络地址
    isOver: boolean; // 文件暂停，开始等待ASR及上传结束
    isAsrOver: boolean; // ASR转译状态
    isFileUploadOver: boolean; // 文件上传状态
};
const onRecorderError = function (msg: string, isUserNotAllow: boolean) {
    throw new Error(
        (isUserNotAllow ? 'UserNotAllow，' : '') +
            /* 无法录音: */ UdeskLocales['current'].pages.coach.learningCenter.components.record
                .index.unableToRecord +
            msg
    );
};
export const useRecorder: (props: {
    onStart?: Function;
    onProcess: (
        buffers: any,
        powerLevel: number,
        bufferDuration: number,
        bufferSampleRate: number
    ) => void;
    onError?: (msg: string, isUserNotAllow: boolean) => void;
}) => [MutableRefObject<any>, () => void] = (props) => {
    const recorderRef = useRef<any>();
    const { onProcess, onStart, onError } = props;

    const createRecorder = useCallback(() => {
        return (recorderRef.current = Recorder({
            type: 'wav',
            sampleRate: 8000,
            bitRate: 16,
            onProcess,
            audioTrackSet: {
                noiseSuppression: true,
            },
        }));
    }, [onProcess]);

    const startRecord = useCallback(() => {
        recorderRef.current.open(function () {
            //打开麦克风授权获得相关资源
            recorderRef.current.start(); //开始录音
            RealTimeSendTryReset(); //重置环境，开始录音时必须调用一次\
            onStart?.();
        }, onError || onRecorderError);
    }, [onStart, onError]);

    useEffect(() => {
        let recorder = recorderRef.current;

        if (!recorder) {
            console.log(
                /* 创建Recorder服务 */ UdeskLocales['current'].pages.coach.learningCenter.components
                    .record.index.creatingARecorderService
            );
            recorder = createRecorder();
        }

        return () => {
            console.log(
                /* 关闭Recorder服务 */ UdeskLocales['current'].pages.coach.learningCenter.components
                    .record.index.turnOffTheRecorderService
            );
            recorder?.close();
        };
    }, [createRecorder]);

    return [recorderRef, startRecord];
};

export const useAudio = () => {
    const [updateWave] = useWave(['.wave'], 50);
    const [isUserNotAllow, setIsUserNotAllow] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');

    const onProcess = useCallback(
        (buffers: any, powerLevel: number, bufferDuration: number, bufferSampleRate: number) => {
            updateWave(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
        },
        [updateWave]
    );

    const onError = useCallback((msg, isUserNotAllow) => {
        setIsUserNotAllow(isUserNotAllow);
        setErrorMsg(msg);
    }, []);

    const [, startRecord] = useRecorder({
        onProcess,
        onError,
    });

    useEffect(() => {
        fire(startRecord);
    }, [startRecord]);

    return [isUserNotAllow, errorMsg];
};

export const useAudioCheck = () => {
    const [updateNoiseWave] = useWave(['.noise_wave'], 50);
    const [updateVolumnWave] = useWave(['.volumn_wave'], 50);
    const [isUserNotAllow, setIsUserNotAllow] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');
    const checkType = useRef('normal'); // checkType: 'normal'-常规是否开启 'noise'-噪音 'volume'-音量
    const maxVolRef = useRef(0);
    const [isNormalChecking, setIsNormalChecking] = useState(false);
    const [isNoiseChecking, setIsNoiseChecking] = useState(false);
    const [isVolumnChecking, setIsVolumnChecking] = useState(false);

    const stopRecord = useCallback((callback = (max: number) => {}) => {
        const recorder = recorderRef.current;
        const state = recorder?.state; // 0未录音 1录音中 2暂停 3等待ctx激活

        if ([1, 2].includes(state)) {
            recorder.stop((blob: any, duration?: number) => {
                callback(maxVolRef.current || 0, blob);
                maxVolRef.current = 0;
                checkType.current = 'normal';
                setIsNormalChecking(false);
                setIsNoiseChecking(false);
                setIsVolumnChecking(false);
            });
        }
    }, []);

    const onProcess = useCallback(
        (buffers: any, powerLevel: number, bufferDuration: number, bufferSampleRate: number) => {
            console.log('powerLevel', powerLevel, checkType);
            if (checkType.current === 'normal') {
                stopRecord();
                updateNoiseWave(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
                updateVolumnWave(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
            }
            if (checkType.current === 'noise') {
                const max = Math.max(maxVolRef.current, powerLevel);
                maxVolRef.current = max;
                updateNoiseWave(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
            }
            if (checkType.current === 'volumn') {
                const max = Math.max(maxVolRef.current, powerLevel);
                maxVolRef.current = max;
                updateVolumnWave(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
            }
        },
        [stopRecord, updateNoiseWave, updateVolumnWave]
    );

    const onError = useCallback((msg, isUserNotAllow) => {
        setIsUserNotAllow(isUserNotAllow);
        setErrorMsg(msg);
    }, []);

    const [recorderRef, _startRecord] = useRecorder({
        onProcess,
        onError,
    });

    const startRecord = useCallback(
        (t) => {
            checkType.current = t;
            _startRecord();
            if (checkType.current === 'noise') {
                setIsNoiseChecking(true);
            }
            if (checkType.current === 'volumn') {
                setIsVolumnChecking(true);
            }
        },
        [_startRecord]
    );

    useEffect(() => {
        setIsNormalChecking(true);
        fire(_startRecord);
    }, [_startRecord]);

    return {
        isUserNotAllow,
        errorMsg,
        startRecord,
        stopRecord,
        isNormalChecking,
        isNoiseChecking,
        isVolumnChecking,
    };
};

export const useAudioRecorder = (task) => {
    const [list, setList] = useState<AudioFile[]>([]);
    const [updateAsrInfo] = useAsr(setList);
    const [updateWave] = useWave(['.wave']);

    const clearList = useCallback(() => setList([]), []);

    const onProcess = useCallback(
        (buffers: any, powerLevel: number, bufferDuration: number, bufferSampleRate: number) => {
            //推入实时处理，因为是unknown格式，buffers和rec.buffers是完全相同的，只需清理buffers就能释放内存。
            RealTimeSendTry(buffers, bufferSampleRate, false, (number, blob) => {
                if (blob) {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        updateAsrInfo(
                            number === 1 ? 0 : 1,
                            (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader?.result as string) || [])[1]
                        );
                    };
                    reader.readAsDataURL(blob);
                }
            });
            updateWave(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
            // 音量超过20将重置超时时间
            // if (powerLevel > 20) {
            //     setList(list => [...list]);
            // }
        },
        [updateAsrInfo, updateWave]
    );

    const onStart = useCallback(() => {
        setList((list) => {
            return [
                ...list,
                {
                    id: list.length,
                    text: '',
                    blob: null,
                    duration: 0,
                    url: '',
                    isOver: false,
                    isAsrOver: false,
                    isFileUploadOver: false,
                },
            ];
        });
    }, []);

    const [recorderRef, startRecord] = useRecorder({
        onProcess,
        onStart,
    });

    const uploadFile = useDebounce(
        (active, errorCallback) => {
            UploadFile(
                new window.File([active.blob], `task-${task.id}-${active.id}.wav`),
                ({ url }) => {
                    setList((list) => {
                        return list.map((item) => {
                            if (item.id === active.id) {
                                // 这里清空blob文件的内存占用
                                if (item.url) {
                                    (window.URL || window.webkitURL).revokeObjectURL(item.url);
                                }
                                item.url = url;
                                item.blob = null; //这里释放blob的数据
                                item.isFileUploadOver = true;
                            }
                            return item;
                        });
                    });
                },
                (file, error) => {
                    fire(errorCallback, error);
                }
            );
        },
        [task?.id]
    );

    const command = useCallback(
        (state: 2 | 3) => {
            // 这里等待最后一帧的数据，暂停或停止并不能立即结束流
            setTimeout(() => {
                RealTimeSendTry([], 0, true, () => updateAsrInfo(state, '')); //最后一次发送
            }, 300);
        },
        [updateAsrInfo]
    );

    const pauseRecord = useCallback(() => {
        const recorder = recorderRef.current;
        const state = recorder?.state; // 0未录音 1录音中 2暂停 3等待ctx激活

        if ([1, 2].includes(state)) {
            recorder.stop((blob: any, duration?: number) => {
                setList((list) => {
                    return list.map((item) => {
                        if (!item.isOver) {
                            item.blob = blob;
                            item.duration = duration || 0;
                            item.url = (window.URL || window.webkitURL).createObjectURL(blob);
                            item.isOver = true;

                            // 定时清理ASR无法正确返回的问题
                            // 这里时间不能小于300毫秒, 因为结束命令是在300毫秒后发送的，需要等待最后一帧的ASR的转译
                            setTimeout(() => {
                                if (item) {
                                    item.isAsrOver = true;
                                }
                            }, 2000);
                        }

                        return item;
                    });
                });
            });

            command(2); //最后一次发送
        }
    }, [command, recorderRef]);

    const [pointOfFirstEntry, setPointOfFirstEntry] = useState(TIMEOUT_PERIOD);

    useEffect(() => {
        if (task?.dialogueMode !== Udesk.enums.learningTaskDialogueModeType.phone.id) {
            return;
        }
        const current = list.find((item) => !item.isAsrOver);
        if (current && !current.isOver) {
            setPointOfFirstEntry(TIMEOUT_PERIOD);
        }
        const timer = setInterval(() => {
            setPointOfFirstEntry((time) => {
                let next = time;
                if (current?.text !== '' && !current?.isOver) {
                    next -= 1;
                }
                if (next < 0) {
                    pauseRecord();
                    clearInterval(timer);
                }
                return next < 0 ? 0 : next;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [list, pauseRecord, task]);

    return { list, clearList, startRecord, pauseRecord, uploadFile, pointOfFirstEntry };
};

export const usePPTRecorder = (task) => {
    const [isPause, setIsPause] = useState(false);
    const [list, setList] = useState<AudioFile[]>([]);
    const [updateAsrInfo] = useAsr(setList);
    const [updateWave] = useWave(['.wave']);
    const isFirstFrameRef = useRef<boolean>(false);

    const clearList = useCallback(() => setList([]), []);

    const onProcess = useCallback(
        (buffers: any, powerLevel: number, bufferDuration: number, bufferSampleRate: number) => {
            //推入实时处理，因为是unknown格式，buffers和rec.buffers是完全相同的，只需清理buffers就能释放内存。
            RealTimeSendTry(buffers, bufferSampleRate, false, (number, blob) => {
                if (blob) {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        // console.log('录音中，开始调用ASR服务---------->', number, recorder?.state);
                        updateAsrInfo(
                            isFirstFrameRef.current ? 0 : 1,
                            (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader?.result as string) || [])[1]
                        );
                        isFirstFrameRef.current = false;
                    };
                    reader.readAsDataURL(blob);
                }
            });
            updateWave(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
        },
        [updateAsrInfo, updateWave]
    );

    const onStart = useCallback(() => {
        isFirstFrameRef.current = true; // 标记为录音的第一帧
        setList((list) => {
            return [
                ...list,
                {
                    id: list.length,
                    text: '',
                    blob: null,
                    duration: 0,
                    url: '',
                    isOver: false,
                    isAsrOver: false,
                    isFileUploadOver: false,
                },
            ];
        });
        setIsPause(false);
    }, []);

    const [recorderRef, startRecord] = useRecorder({
        onProcess,
        onStart,
    });

    const uploadFile = useDebounce(
        (active, errorCallback) => {
            UploadFile(
                new window.File([active.blob], `task-${task.id}-${active.id}.wav`),
                ({ url }) => {
                    setList((list) => {
                        return list.map((item) => {
                            if (item.id === active.id) {
                                // 这里清空blob文件的内存占用
                                if (item.url) {
                                    (window.URL || window.webkitURL).revokeObjectURL(item.url);
                                }
                                item.url = url;
                                item.blob = null; //这里释放blob的数据
                                item.isFileUploadOver = true;
                            }
                            return item;
                        });
                    });
                },
                (file, error) => {
                    fire(errorCallback, error);
                }
            );
        },
        [task?.id]
    );

    const command = useCallback(
        (state: 2 | 3) => {
            // 这里等待最后一帧的数据，暂停或停止并不能立即结束流
            setTimeout(() => {
                RealTimeSendTry([], 0, true, () => updateAsrInfo(state, '')); //最后一次发送
            }, 300);
        },
        [updateAsrInfo]
    );

    const pauseRecord = useCallback(
        (isAutoResume = true) => {
            const recorder = recorderRef.current;
            const state = recorder?.state; // 0未录音 1录音中 2暂停 3等待ctx激活
            const isPauseForPrevFrame = recorder?.state === 2;
            if (state === 1) {
                recorder.pause();
                command(3); // 最后一次发送
            }
            if (state === 2 && isAutoResume) {
                isFirstFrameRef.current = true; // 标记为录音的第一帧
                recorder.resume();
            }
            setIsPause(recorder?.state === 2);
            return isPauseForPrevFrame;
        },
        [command, recorderRef]
    );

    const stopRecord = useCallback(() => {
        const recorder = recorderRef.current;
        const state = recorder?.state; // 0未录音 1录音中 2暂停 3等待ctx激活

        if ([1, 2].includes(state)) {
            recorder.stop((blob: any, duration?: number) => {
                setList((list) => {
                    return list.map((item) => {
                        if (!item.isOver) {
                            item.blob = blob;
                            item.duration = duration || 0;
                            item.url = (window.URL || window.webkitURL).createObjectURL(blob);
                            item.isOver = true;

                            // 定时清理ASR无法正确返回的问题
                            // 这里时间不能小于300毫秒, 因为结束命令是在300毫秒后发送的，需要等待最后一帧的ASR的转译
                            setTimeout(() => {
                                if (item) {
                                    item.isAsrOver = true;
                                }
                            }, 2000);
                        }

                        return item;
                    });
                });
            });

            command(2); //最后一次发送
        }
    }, [command, recorderRef]);

    return {
        isPauseRecord: isPause,
        recordList: list,
        startRecord,
        pauseRecord,
        stopRecord,
        uploadFile,
        clearList,
    };
};
