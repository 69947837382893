import Udesk from 'Udesk';
import QualityInspectionListDetailComponent from './component';

class QualityInspectionListDetailRoute extends QualityInspectionListDetailComponent {
    asyncModel(changedParams) {
        let { sdkOptions, match } = this.props;
        // let { relatedDatasPaging } = this.privates.storages;
        let id = match.params.id;
        let taskId = match.params.taskId;
        if (changedParams && changedParams.id) {
            id = changedParams.id;
        }
        if (id == null) {
            return {};
        }
        let urlData = {};
        this.privates.renderLoading = true;
        if (this.privates.detail.id) {
            this.privates.detail = {};
        }
        this.actions.update();
        let detailUrl = Udesk.business.apiPath.concatApiPath(`inspectionData/${id}?taskId=${taskId}`, sdkOptions);
        if (sdkOptions.props.task.taskType === Udesk.enums.taskType.informationCollection.id) {
            detailUrl = Udesk.business.apiPath.concatApiPath(`informationDatas/${id}`, sdkOptions);
        }
        if (this.props.location && this.props.location.search) {
            urlData = Object.assign(
                {},
                this.props.match.params,
                Object.fromEntries(new URLSearchParams(this.props.location.search))
            );
            if (urlData.highLightType && urlData.highLightType === '1') {
                detailUrl = Udesk.business.apiPath.concatApiPath(
                    `inspectionData/${id}?taskId=${taskId}&highLightType=${urlData.highLightType}`,
                    sdkOptions
                );
            }
        }
        let detailPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(detailUrl).then(
                (resp) => {
                    resolve({ detail: resp });
                    const { related, relatedInspectionDataExtension } = resp.data;
                    const callId = related
                        ? Object.values(relatedInspectionDataExtension)?.[0]?.callId
                        : resp.data.callId;
                    if (callId) {
                        let smartTagsUrl = Udesk.business.apiPath.concatApiPath(
                            `callTags/${callId}/tagsOfCall`,
                            sdkOptions
                        );
                        Udesk.ajax.get(smartTagsUrl).then(
                            (resp) => {
                                this.privates.smartTags = resp.data;
                                this.actions.update();
                            },
                            (reason) => {
                                Udesk.ui.notify.error(reason.errorMsg);
                            }
                        );
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        // .then((detailResp) => {
        //     let relatedData = {
        //         id: detailResp.data.callId,
        //         pageNum: relatedDatasPaging.pageNum,
        //         pageSize: relatedDatasPaging.pageSize,
        //     };
        //     let relatedDataUrl = Udesk.business.apiPath.concatApiPath(`data/related-data`, sdkOptions);
        //     return new Promise((resolve, reject) => {
        //         Udesk.ajax.post(relatedDataUrl, relatedData).then(
        //             (resp) => {
        //                 resolve({ detail: detailResp, relateData: resp });
        //             },
        //             (reason) => {
        //                 Udesk.ui.notify.error(reason.errorMsg);
        //                 reject(reason);
        //             }
        //         );
        //     });
        // });

        let changeListUrl = Udesk.business.apiPath.concatApiPath(
            `inspectionData/${id}/changeList?taskId=${taskId}`,
            sdkOptions
        );
        let changeListPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(changeListUrl).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        let caseBaseUrl = Udesk.business.apiPath.concatApiPath(`caseLibraryCategorys/byInspectionId`, sdkOptions);
        let caseBase = new Promise((resolve, reject) => {
            Udesk.ajax.get(caseBaseUrl, { taskId, inspectionId: id, pageNum: 1, pageSize: 51 }).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        // let relatedDataPromise = new Promise((resolve,reject)=>{
        //     Udesk.ajax.post(relatedDataUrl,relatedData).then(
        //         (resp) => {
        //             resolve(resp);
        //         },
        //         (reason) => {
        //             Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.components.recordDetail.getTemplatesFailed);
        //             reject(reason);
        //         }
        //     );
        // });
        let testSetListPromise = null;

        if (urlData.testSetFlag && urlData.testSetFlag === 'true') {
            this.privates.testSetListFlag = urlData.testSetFlag;
            let { sdkOptions, match } = this.props;
            if (!this.privates.pageTransToId) {
                this.privates.pageTransToId = match.params.id;
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `inspection-test-set/${this.privates.pageTransToId}?taskId=${match.params.taskId}`,
                sdkOptions
            );
            testSetListPromise = new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    (resp) => {
                        resolve(resp);
                        // this.privates.testSetList = resp.data;
                        // this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        }
        let sysConfigPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/companys/sys/config', this.props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let checkPointsPromise = new Promise((resolve, reject) => {
            let checkPointsUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionData/${match.params.taskId}/pointList`,
                sdkOptions
            );
            Udesk.ajax.get(checkPointsUrl).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            detailWithRelateData: detailPromise,
            changeList: changeListPromise,
            caseBase: caseBase,
            // smartTags: smartTagsPromise,
            testSetList: testSetListPromise,
            // relatedDatas:relatedDataPromise
            sysConfig: sysConfigPromise,
            checkPoints: checkPointsPromise,
        };
    }
}

export default QualityInspectionListDetailRoute;
