import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useMount } from 'ahooks';
import {
    Button,
    Space,
    Table,
    Form,
    Input,
    Icon,
    Tooltip,
    Carousel,
    Popover,
    Modal,
    InputNumber,
    Tag,
} from 'udesk-ui';
import Page from 'Component/common/udesk-qa-page-layout';
import Circle from '../components/circle';
import CustomerTagSelect from 'Component/pages/gong/customer-tag-select';
import ListControl from 'Component/common/list-control';
import { useListControl, BusinessType } from 'Component/common/list-control/hook';
import AdvancedFilterButton, {
    TemplateProps as AdvancedFilterButtonProps,
} from './components/advanced-filter-button';
import { request } from 'src/api/context';
import { postRiskConfigs } from 'src/api/riskConfigs';
import { getRiskConfigsFindByCompany } from 'src/api/riskConfigs/findByCompany';
import { PostReviewCallCustomerMessageFindallQuery } from 'src/api/types';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import moment from 'moment';
import './index.scss';

const locales = UdeskLocales['current'];
const CURRENT_NUM = 1;
const CURRENT_SIZE = 10;

const createCustomerTag = (tag) => (
    <Tag
        key={tag.id}
        style={{
            borderWidth: tag.borderWidth,
            marginBottom: 4,
            background: tag.categoryColor + '1a',
            color: tag.categoryColor,
            maxWidth: '100%',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
        }}
        color={tag.categoryColor}
        title={tag.tagName}
    >
        {tag.tagName}
    </Tag>
);

const Template = React.memo((props: any) => {
    const [form] = Form.useForm();
    const [riskForm] = Form.useForm();
    const carouselRef: any = React.useRef();
    const [loading, setLoading] = useState(false);
    const [currentDay] = useState(moment().endOf('day').format('YYYY-MM-DD 23:59:59'));
    const [lastTime, setLastTime] = useState(currentDay);
    const [dataLastTime, setDataLastTime] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [isShowRisk, setIsShowRisk] = useState(false);
    const [semanticsList, setSemanticsList] = useState<any[]>([]);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);
    const [searchCondition, setSearchCondition] = useState({});

    const [outerId, setOuterId] = useState<any>(null);
    const [innerId, setInnerId] = useState<any>(null);

    const [page, setPage] = useState({
        current: CURRENT_NUM,
        pageSize: CURRENT_SIZE,
        total: 0,
    });

    const handlerDetail = useCallback(
        (record) => {
            const routeOptions = {
                history: props.history,
                routeName: 'saleClientDetail',
                pathParams: { id: record.customerId },
                state: {
                    data: {
                        customerId: record.customerId, // 客户ID
                        customerName: record.customerName, // 客户名称
                        followUpSuggestions: record.followUpSuggestions, // 跟进建议
                        followUpDays: record.followUpDays, // 跟进天数
                        ownerName: record.ownerName, // 负责人
                        userName: record.userName, // 最近跟进人
                        customerTagList: record.customerTagList, // 客户标签
                        firstContactTime: record.firstContactTime, //首次触达时间
                        wfCustomFieldList: record.wfCustomFieldList,
                    },
                    searchCondition,
                    page: {
                        current: page.current,
                        pageSize: page.pageSize,
                    },
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        [page, props.history, searchCondition]
    );

    const getCustomDataList = useCallback(
        (pageNum, pageSize, data?, time?) => {
            setLoading(true);

            const params: PostReviewCallCustomerMessageFindallQuery = {
                pageNum,
                pageSize: Math.max(CURRENT_SIZE, pageSize),
                lastTime: time || lastTime,
            };

            setSearchCondition(data);

            request('/review/call/customer/message/findall', {
                method: 'post',
                __$requestCalleeName: 'postFindall',
                params,
                data: data ?? {},
            })
                .then((res) => {
                    setLoading(false);
                    setDataSource(
                        (res.data ?? []).map((d) => {
                            return {
                                ...d,
                                ...d.wfCustomFieldList?.reduce((pre, cur) => {
                                    if (cur.id) {
                                        return {
                                            ...pre,
                                            [cur.id]: cur.value,
                                        };
                                    }
                                    return pre;
                                }, {}),
                            };
                        })
                    );
                    let followDataList: any = [];
                    if (res.data && res.data.length) {
                        setTotalCount(res.paging?.total ?? 0);
                        setDataLastTime(res.data[0]?.lastTime ?? '');
                        let monthDate = res.data[0]?.timeStringList;
                        monthDate?.forEach((item) => {
                            followDataList.push({
                                // allDate: item,
                                date: moment(item).format('M-D'),
                                children: [],
                            });
                        });
                        if ((time || lastTime) === moment().format('YYYY-MM-DD 23:59:59')) {
                            followDataList.push({
                                allDate: moment().format('YYYY-M-D'),
                                date: locales.pages.gong.saleClientCenter.list.index.today,
                                children: [],
                            });
                        }
                        followDataList.forEach((item, idx) => {
                            res.data?.forEach((record) => {
                                item.children.push({
                                    customerId: record.customerId,
                                    children: [],
                                });
                            });
                        });
                        followDataList.forEach((item, idx) => {
                            item.children.forEach((record) => {
                                res.data?.forEach((data) => {
                                    if (record.customerId === data.customerId) {
                                        record.children = [
                                            ...record.children,
                                            ...(data?.oneWeekDataCallAnalysisList?.[idx] ?? []),
                                        ];
                                        if (
                                            idx === followDataList.length - 1 &&
                                            (time || lastTime) ===
                                                moment().format('YYYY-MM-DD 23:59:59')
                                        ) {
                                            record.children = data?.dataCallAnalysisList?.filter(
                                                (sin) =>
                                                    moment(sin.callTime).format('YYYY-M-D') ===
                                                    item.allDate
                                            );
                                        }
                                    }
                                });
                            });
                        });
                    }
                    setChartData(followDataList);
                    setPage({
                        current: res.paging?.pageNum ?? CURRENT_NUM,
                        pageSize: res.paging?.pageSize ?? CURRENT_SIZE,
                        total: res.paging?.total ?? 0,
                    });
                })
                .catch((error) => {
                    setLoading(false);
                });
        },
        [lastTime]
    );

    useMount(() => {
        let backState = props.location?.state;
        if (backState) {
            getCustomDataList(
                backState?.page?.current ?? CURRENT_NUM,
                backState?.page?.pageSize ?? CURRENT_SIZE,
                backState.searchCondition
            );
            form &&
                form.setFieldsValue({
                    ...backState.searchCondition,
                });
        } else {
            getCustomDataList(CURRENT_NUM, CURRENT_SIZE);
        }
    });

    const handlerPrevCarousel = useCallback(() => {
        // carouselRef.current.prev();
        let prevDate = moment(lastTime).subtract(7, 'day').format('YYYY-MM-DD 23:59:59');
        setLastTime(prevDate);
        getCustomDataList(CURRENT_NUM, CURRENT_SIZE, searchCondition, prevDate);
    }, [getCustomDataList, lastTime, searchCondition]);

    const handlerNextCarousel = useCallback(() => {
        // carouselRef.current.next();
        let nextDate = moment(lastTime).add(7, 'day').format('YYYY-MM-DD 23:59:59');
        setLastTime(nextDate);
        getCustomDataList(CURRENT_NUM, CURRENT_SIZE, searchCondition, nextDate);
    }, [getCustomDataList, lastTime, searchCondition]);

    const { displayFields, ...listControlProps } = useListControl({
        businessType: BusinessType.SALES_CLIENT_CENTER_LIST,
    });

    const columns = useMemo(() => {
        const fieldCols =
            displayFields?.map((f) => {
                const column = {
                    title: f.fieldName,
                    dataIndex: f.fieldCode,
                    key: f.fieldCode,
                };

                if (f.fieldCode === 'riskCount') {
                    return {
                        ...column,
                        width: 90,
                        render: (text, record) => {
                            const content = (
                                <div className="pages-gong-sale-client-center-risk-tips-text">
                                    {record?.isNotActive === 1 && (
                                        <p>
                                            <i></i>
                                            {locales.pages.gong.saleClientCenter.list.index.exceed}
                                            {record.timeoutDayCounts}
                                            {
                                                locales.pages.gong.saleClientCenter.list.index
                                                    .noActivityForMoreThanDays
                                            }
                                        </p>
                                    )}
                                    {record?.isRisk === 1 && (
                                        <p>
                                            <i></i>
                                            {locales.pages.gong.saleClientCenter.list.index.lately}
                                            {record.riskDayCounts}
                                            {
                                                locales.pages.gong.saleClientCenter.list.index
                                                    .risksOccurredInTheLastDays
                                            }
                                        </p>
                                    )}
                                </div>
                            );
                            return (
                                <>
                                    {record?.isNotActive === 1 || record?.isRisk === 1 ? (
                                        <Popover placement="bottom" content={content}>
                                            <span className="risk-tips">
                                                <Space>
                                                    <Icon
                                                        style={{ color: '#F09A00', fontSize: 14 }}
                                                        type="tishi"
                                                    ></Icon>
                                                    <p style={{ fontSize: 14, color: '#F09A00' }}>
                                                        {record.riskCount ?? 0}
                                                    </p>
                                                </Space>
                                            </span>
                                        </Popover>
                                    ) : (
                                        <span className="risk-tips">
                                            <Space>
                                                <Icon
                                                    style={{ color: '#F09A00', fontSize: 14 }}
                                                    type="tishi"
                                                ></Icon>
                                                <p style={{ fontSize: 14, color: '#F09A00' }}>
                                                    {record.riskCount ?? 0}
                                                </p>
                                            </Space>
                                        </span>
                                    )}
                                </>
                            );
                        },
                    };
                } else if (f.fieldCode === 'timeStringList') {
                    return {
                        ...column,
                        width: 500,
                        className: 'sale-client-center-list-echarts-container',
                        render: (text, record, index) => {
                            let obj = {
                                children: (
                                    <div className="carousel-detail">
                                        <Button
                                            className="prev-btn"
                                            onClick={handlerPrevCarousel}
                                            type="text"
                                            icon={<Icon type="ic-arrow-left-s-line"></Icon>}
                                        ></Button>
                                        {currentDay === dataLastTime ? (
                                            <Button
                                                className="next-btn"
                                                type="text"
                                                icon={
                                                    <Icon
                                                        type="ic-arrow-right-s-youjiantou"
                                                        style={{ color: '#ccc' }}
                                                    ></Icon>
                                                }
                                            ></Button>
                                        ) : (
                                            <Button
                                                className="next-btn"
                                                onClick={handlerNextCarousel}
                                                type="text"
                                                icon={
                                                    <Icon type="ic-arrow-right-s-youjiantou"></Icon>
                                                }
                                            ></Button>
                                        )}
                                        <Carousel
                                            className="custom-carousel-container-class-name"
                                            ref={carouselRef}
                                            dots={false}
                                        >
                                            <div key={1} className="carousel-detail-container">
                                                {chartData.map((item, idx) => {
                                                    return (
                                                        <div
                                                            key={item.date + idx}
                                                            className="carousel-detail-day"
                                                        >
                                                            <h3>{item.date}</h3>
                                                            {item?.children?.map((data, index) => {
                                                                return (
                                                                    <div key={data.customerId}>
                                                                        {data.children?.map(
                                                                            (record) => {
                                                                                return (
                                                                                    <Circle
                                                                                        data={
                                                                                            record
                                                                                        }
                                                                                        key={
                                                                                            record.id
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Carousel>
                                    </div>
                                ),
                                props: {
                                    rowSpan: dataSource.length,
                                },
                            };
                            if (index === 0) {
                                obj.props.rowSpan = dataSource.length;

                                clearTimeout(record.timer);
                                record.timer = setTimeout(() => {
                                    const $table = $(
                                        '.sale-client-center-list-echarts-container'
                                    ).parents('table');
                                    const $trList = $('tbody tr', $table);
                                    const $divList = $('.carousel-detail-day', $table);
                                    const heightList = $trList.map(
                                        (index, tr) => $(tr).height()! - 1
                                    );

                                    $divList.each((index, div) => {
                                        $('> div', div).each((index, div) => {
                                            $(div).height(heightList[index] as any);
                                        });
                                    });
                                }, 100);
                            } else {
                                obj.props.rowSpan = 0;
                            }

                            return obj;
                        },
                    };
                } else if (f.fieldCode === 'customerTagList') {
                    return {
                        ...column,
                        width: 200,
                        render: (name, item, index) => {
                            return (
                                <div>
                                    {Array.isArray(name) ? (
                                        <div>
                                            {name.filter((item, i) => i < 3).map(createCustomerTag)}

                                            {name.length > 3 && (
                                                <Tooltip
                                                    placement="topLeft"
                                                    title={
                                                        <div
                                                            style={{
                                                                whiteSpace: 'pre-wrap',
                                                                maxHeight: 400,
                                                                overflowY: 'auto',
                                                            }}
                                                        >
                                                            {name
                                                                .filter((item, i) => i >= 3)
                                                                .map(createCustomerTag)}
                                                        </div>
                                                    }
                                                    color="#fff"
                                                >
                                                    {createCustomerTag({
                                                        id: -1,
                                                        borderWidth: 0,
                                                        background: '#fafafa',
                                                        color: '#1b6dff',
                                                        tagName: `+${name.length - 3}`,
                                                    })}
                                                </Tooltip>
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        },
                    };
                } else if (f.fieldCode === 'followUpSuggestions') {
                    return {
                        ...column,
                        ellipsis: true,
                        render(text) {
                            return (
                                <div style={{ position: 'relative' }}>
                                    <Tooltip
                                        zIndex={1}
                                        placement={'bottom'}
                                        title={
                                            <div>
                                                <div dangerouslySetInnerHTML={{ __html: text }} />
                                            </div>
                                        }
                                        trigger="click"
                                        getPopupContainer={(triggerNode) => {
                                            return $(triggerNode).parents('table').get(0) as any;
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                maxHeight: 60,
                                                overflow: 'hidden',
                                            }}
                                            dangerouslySetInnerHTML={{ __html: text }}
                                        />
                                    </Tooltip>
                                </div>
                            );
                        },
                    };
                } else {
                    return {
                        ...column,
                        ellipsis: true,
                    };
                }
            }) || [];

        const actionCols = [
            {
                title: locales.pages.gong.saleClientCenter.list.index.operation,
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record) => {
                    return (
                        <>
                            {record.customerId && (
                                <Button type="link" onClick={handlerDetail.bind(null, record)}>
                                    {locales.pages.gong.saleClientCenter.list.index.see}
                                </Button>
                            )}
                        </>
                    );
                },
            },
        ];

        return [...fieldCols, ...actionCols];
    }, [
        chartData,
        currentDay,
        dataLastTime,
        dataSource.length,
        displayFields,
        handlerDetail,
        handlerNextCarousel,
        handlerPrevCarousel,
    ]);

    const handlerPageChange = useCallback(
        (current, pageSize) => {
            setPage({
                ...page,
                current,
                pageSize,
            });
            getCustomDataList(current, pageSize, searchCondition);
        },
        [getCustomDataList, page, searchCondition]
    );

    const handlerRiskAdd = () => {
        setSemanticsList([]);
        getRiskConfigsFindByCompany().then((res) => {
            setOuterId(res.data?.id);
            setInnerId(res.data?.riskTag?.id);
            setIsShowRisk(true);
            riskForm &&
                riskForm.setFieldsValue({
                    timeoutDayCounts: res.data?.timeoutDayCounts,
                    riskDayCounts: res.data?.riskDayCounts,
                });
            let tagList: any = [];
            res.data?.conditionListAfterSort?.forEach((item) => {
                item?.operatorList?.forEach((record) => {
                    let tags: any = [
                        {
                            content: record?.semanticTag?.sentence,
                            standardFlag: 1,
                            groupId: record?.semanticTag?.semanticId,
                        },
                    ];
                    tagList.push({
                        id: record?.semanticTag?.semanticId,
                        title: record?.semanticTag?.semanticName,
                        tags,
                    });
                });
            });
            setSemanticsList(tagList);
        });
    };
    const handlerAddTags = () => {
        props.SmartToolBarRef.current.actions.open(handlerSelectTags, '');
        props.SmartToolBarRef.current.actions.seleteTag('3');
        if (props.SmartToolBarRef.current.privates.isFront) {
            props.SmartToolBarRef.current.actions.backToIndex();
        }
    };
    const handlerSelectTags = (tag) => {
        let newTags = [tag, ...semanticsList];
        setSemanticsList(newTags);
    };
    const handlerDelete = (id) => {
        let newTags = [...semanticsList];
        newTags.forEach((item, index) => {
            if (id === item.id) {
                newTags.splice(index, 1);
            }
        });
        setSemanticsList(newTags);
    };
    const handlerSave = () => {
        riskForm.validateFields().then((values) => {
            let conditionList: any = [];
            semanticsList.forEach((item) => {
                conditionList.push({
                    operatorList: [
                        {
                            keyword: item.id,
                        },
                    ],
                });
            });
            const params = {
                id: outerId,
                riskTagCreateRequest: {
                    id: innerId,
                    ruleList: [{ formula: 'R1', judgeStrategy: 2 }],
                    tagType: 2,
                    funcType: 'REVIEW_CALL',
                    conditionList,
                },
                ...values,
            };
            postRiskConfigs(params, {
                successMsg: locales.pages.gong.saleClientCenter.list.index.savedSuccessfully,
            }).then((res) => {
                setIsShowRisk(false);
            });
        });
    };

    const handlerSearch = (values) => {
        getCustomDataList(CURRENT_NUM, CURRENT_SIZE, { ...searchCondition, ...values });
    };

    const AdvancedFilterButtonRef: AdvancedFilterButtonProps['forwardRef'] = useRef(null);

    const onSearch: AdvancedFilterButtonProps['onSearch'] = (values) => {
        handlerSearch(values);
    };

    const onReset = () => {
        setLastTime(currentDay);
        form.resetFields();
        AdvancedFilterButtonRef.current?.onClear?.();
        setSearchCondition({});
        getCustomDataList(CURRENT_NUM, CURRENT_SIZE, {});
    };

    return (
        <Page
            pageBodyClassName="pages-gong-sale-client-center-list-container"
            title={locales.pages.gong.saleClientCenter.list.index.customer}
            padding={true}
        >
            <Form form={form} onFinish={handlerSearch} layout="inline" style={{ width: '100%' }}>
                <Form.Item name="userIds"></Form.Item>

                <Form.Item name="key">
                    <Input
                        placeholder={locales.pages.gong.saleClientCenter.list.index.customerName}
                        style={{ width: 160 }}
                    />
                </Form.Item>

                <Form.Item name="customerTagKeyword">
                    <CustomerTagSelect />
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button onClick={() => onReset()}>
                            {locales.pages.gong.saleClientCenter.list.index.reset}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {locales.pages.gong.saleClientCenter.list.index.query}
                        </Button>
                        <AdvancedFilterButton
                            forwardRef={AdvancedFilterButtonRef}
                            onSearch={onSearch}
                        />
                    </Space>
                </Form.Item>
            </Form>

            <div className="risk-allocation">
                <Space size={40}>
                    <p>
                        {totalCount}
                        {locales.pages.gong.saleClientCenter.list.index.customers}
                    </p>
                    <div className="user-proportion">
                        <Space size={4}>
                            <div>
                                <i className="bg-custom"></i>
                                <i className="bg-user"></i>
                            </div>
                            <Tooltip
                                title={
                                    locales.pages.gong.saleClientCenter.list.index
                                        .showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles
                                }
                            >
                                <Icon type="icon_explain" style={{ fontSize: 16 }}></Icon>
                            </Tooltip>
                        </Space>
                    </div>
                    <Button type="link" onClick={handlerRiskAdd}>
                        {locales.pages.gong.saleClientCenter.list.index.riskAllocation}
                    </Button>
                </Space>

                <ListControl {...listControlProps} />
            </div>

            <Table
                bordered
                key="customerId"
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                pagination={{
                    ...page,
                    onChange: handlerPageChange,
                }}
            />

            <Modal
                className="pages-modal-sale-client-center-risk-setting"
                title={locales.pages.gong.saleClientCenter.list.index.riskAllocation}
                visible={isShowRisk}
                okText={locales.pages.gong.saleClientCenter.list.index.preservation}
                onOk={handlerSave}
                onCancel={() => setIsShowRisk(false)}
            >
                <Form form={riskForm} initialValues={{ timeoutDayCounts: 7, riskDayCounts: 7 }}>
                    <Form.Item
                        label={locales.pages.gong.saleClientCenter.list.index.exceed}
                        rules={[
                            {
                                required: true,
                                message: locales.pages.gong.saleClientCenter.list.index.pleaseEnter,
                            },
                        ]}
                    >
                        <Form.Item name="timeoutDayCounts" noStyle>
                            <InputNumber precision={0} min={1} />
                        </Form.Item>
                        <span style={{ marginLeft: '8px' }} className="udesk-ui-form-text">
                            {locales.pages.gong.saleClientCenter.list.index.noActivityForDays}
                        </span>
                    </Form.Item>
                    <Form.Item
                        label={locales.pages.gong.saleClientCenter.list.index.lately}
                        rules={[
                            {
                                required: true,
                                message: locales.pages.gong.saleClientCenter.list.index.pleaseEnter,
                            },
                        ]}
                    >
                        <Form.Item name="riskDayCounts" noStyle>
                            <InputNumber precision={0} min={1} />
                        </Form.Item>
                        <span
                            style={{ marginLeft: '8px', marginRight: '16px' }}
                            className="udesk-ui-form-text"
                        >
                            {locales.pages.gong.saleClientCenter.list.index.riskSemanticsWithinDays}
                        </span>
                        <Button onClick={handlerAddTags} icon={<Icon type="ic-add-jia1"></Icon>}>
                            {locales.pages.gong.saleClientCenter.list.index.addRiskSemantics}
                        </Button>
                    </Form.Item>
                    {!!semanticsList.length &&
                        semanticsList.map((item) => {
                            return (
                                <Form.Item key={item.id}>
                                    <div className="semantics-tags-item">
                                        <div className="semantics-tags-item-content">
                                            <h3>
                                                {
                                                    locales.pages.gong.saleClientCenter.list.index
                                                        .semanticTags
                                                }
                                                {item.title}
                                            </h3>
                                            <p>
                                                {
                                                    locales.pages.gong.saleClientCenter.list.index
                                                        .standardSentence
                                                }
                                                {
                                                    item.tags?.filter(
                                                        (record) => record.standardFlag === 1
                                                    )?.[0]?.content
                                                }
                                            </p>
                                        </div>
                                        <div
                                            className="semantics-tags-item-icon"
                                            onClick={handlerDelete.bind(null, item.id)}
                                        >
                                            <Icon type="ic-minus-circle-jian"></Icon>
                                        </div>
                                    </div>
                                </Form.Item>
                            );
                        })}
                </Form>
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
