import React from 'react';
import Udesk from 'Udesk';
import _filter from 'lodash-es/filter';
import _isEmpty from 'lodash-es/isEmpty';
import _isEqual from 'lodash-es/isEqual';
import _find from 'lodash-es/find';
import Locales from 'UdeskLocales';

export default class connectedByConversationComponent extends React.Component {
    static defaultProps = {
        timeDifferStates: ['diff_greater_than', 'diff_greater_than_eq', 'diff_less_than', 'diff_less_than_eq'],
        beginAndEndEqual: ['head_equal', 'tail_equal'],
        inputNumberprice: [
            'diff_greater_than',
            'diff_equal',
            'diff_greater_than_eq',
            'diff_less_than',
            'diff_less_than_eq'
        ],
        sourceListAjax: [1, 2, 3, 4],
        fieldTypeListString: [1, 4, 5, 6, 7, 8, 9, 10, 11],
        fieldTypeListNumber: [2, 13],
        fieldTypeListTime: [3],
        fieldTypeListSource: [12, 14]
    };

    privates = {
        listData: [], //改商户下通对话关联数据集合
        parentNumList: [], //一级数据源集合包含 序号num、集合长度childNum、数据源一source、数据源二sourceForm
        num: 0, // 一级数据源新增时序号状态，只增不减
        fieldsData: [], //二级分类中数据源一二list集合,
        editOrSaveStates: false, //页面保存或者新增状态  true 修改  false 保存
        activeFlag: 0,
        dataCallRelatedConfigId: null,
        loading: false
    };
    actions = {
        //通对话关联开关
        switchChange(value) {
            let { sdkOptions } = this.props;
            this.privates.activeFlag = value ? 1 : 0;
            this.actions.update();
            let url = Udesk.business.apiPath.concatApiPath(
                `data-call-related-config/switch?activeFlag=${this.privates.activeFlag}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url).then(
                    resp => {
                        Udesk.ui.notify.success(Locales['current'].fix.statusChangeSuccess);
                        resolve(resp);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                        this.privates.activeFlag = 0;
                        this.actions.update();
                    }
                );
            });
        },

        //添加一级分类
        addOneCustome() {
            let { parentNumList, num } = this.privates;
            parentNumList = [
                ...parentNumList,
                {
                    num: num + 1,
                    childNum: 0,
                    source: null,
                    sourceFrom: null
                }
            ];
            this.privates.num = num + 1;
            this.privates.parentNumList = parentNumList;

            this.actions.update();
        },
        //添加二级分类
        addTwoCustome(value) {
            let { listData, parentNumList } = this.privates;
            let list = parentNumList.map(item => {
                if (item.num === value.num) {
                    item.childNum++;
                }
                return item;
            });
            this.privates.parentNumList = list;
            let data = {
                field: {},
                operator: null,
                value: null,
                unit: null,
                fieldFrom: {},
                source: value.source,
                sourceFrom: value.sourceFrom,
                num: value.childNum,
                parentNum: value.num
            };
            listData = [...listData, data];
            this.privates.listData = listData;
            this.actions.update();
        },
        //删除一级分类
        delOneCustome(value) {
            let { parentNumList, listData } = this.privates;
            let list = _filter(parentNumList, current => current.num !== value.num);
            _filter(listData, current => current.parentNum !== value.num);
            this.privates.parentNumList = list;
            this.actions.update();
        },
        //删除二级分类
        delTwoCustome(value) {
            let { listData } = this.privates;
            let list = _filter(
                listData,
                current =>
                    (current.parentNum === value.parentNum && current.num !== value.num) ||
                    (current.parentNum !== value.parentNum && current.num === value.num) ||
                    (current.parentNum !== value.parentNum && current.num !== value.num)
            );
            this.privates.listData = list;
            this.actions.update();
        },
        //改变一级分类数据源一
        dataSourceChanged(objKey, strKey, value) {
            let { parentNumList, listData } = this.privates;
            let list = parentNumList.map(item => {
                if (item.num === objKey.num) {
                    item.source = value;
                }
                return item;
            });
            let valuesList = this.props.form.getFieldsValue();
            if (valuesList && !_isEmpty(valuesList)) {
                for (let key in valuesList) {
                    if (key.indexOf(strKey) !== -1 && !_isEqual(strKey, key)) {
                        this.props.form.setFieldsValue({ [key]: undefined });
                        this.actions.update();
                    }
                }
            }
            this.privates.parentNumList = list;
            listData.map(item => {
                if (item.parentNum === objKey.num) {
                    item.source = value;
                    item.field = {};
                    item.operator = null;
                    item.value = null;
                    item.unit = null;
                }
                return item;
            });
            this.actions.update();
        },
        //改变一级分类数据源二
        dataSourceFromChanged(objKey, strKey, value) {
            let { parentNumList, listData } = this.privates;
            let list = parentNumList.map(item => {
                if (item.num === objKey.num) {
                    item.sourceFrom = value;
                }
                return item;
            });
            let valuesList = this.props.form.getFieldsValue();
            if (valuesList && !_isEmpty(valuesList)) {
                for (let key in valuesList) {
                    if (key.indexOf(strKey) !== -1 && !_isEqual(strKey, key)) {
                        this.props.form.setFieldsValue({ [key]: undefined });
                        this.actions.update();
                    }
                }
            }
            this.privates.listData = listData.map(item => {
                if (item.parentNum === objKey.num) {
                    item.sourceFrom = value;
                    item.fieldFrom = {};
                }
                return item;
            });
            this.privates.parentNumList = list;
            this.actions.update();
        },
        //改变二级分类数据源一
        customeFilterThreeChange(parentItem, item, list, strKey, value) {
            let { listData } = this.privates;
            let fieldTypes = null;
            if (list && !_isEmpty(list.list)) {
                fieldTypes = _find(list.list, current => current.id === value);
            }
            let valuesList = this.props.form.getFieldsValue();
            if (valuesList && !_isEmpty(valuesList)) {
                for (let key in valuesList) {
                    if (key.indexOf(strKey) !== -1 && !_isEqual(strKey, key)) {
                        this.props.form.setFieldsValue({ [key]: undefined });
                        this.actions.update();
                    }
                }
            }
            if (!_isEmpty(listData)) {
                listData.map(items => {
                    if (items.parentNum === parentItem.num && items.num === item.num) {
                        items.field = fieldTypes;
                        items.operator = null;
                        items.value = null;
                        items.unit = null;
                    }
                    return items;
                });
            }

            this.actions.update();
        },
        //改变二级分类数据源二
        customeFilterThreeChangeFrom(parentItem, item, list, value) {
            let { listData } = this.privates;
            let fieldTypes = null;
            if (list && !_isEmpty(list.list)) {
                fieldTypes = _find(list.list, current => current.id === value);
            }
            if (!_isEmpty(listData)) {
                listData.map(items => {
                    if (items.parentNum === parentItem.num && items.num === item.num) {
                        items.fieldFrom = fieldTypes;
                    }
                    return items;
                });
            }
            this.actions.update();
        },
        //改变二级分类操作符
        fieldsChange(parentItem, item, value) {
            let { listData } = this.privates;
            if (!_isEmpty(listData)) {
                listData.map(items => {
                    if (items.parentNum === parentItem.num && items.num === item.num) {
                        items.operator = value;
                    }
                    return items;
                });
            }
            this.actions.update();
        },
        fieldChangedInput(parentItem, item, value) {
            let { listData } = this.privates;
            if (!_isEmpty(listData)) {
                listData.map(items => {
                    if (items.parentNum === parentItem.num && items.num === item.num) {
                        items.value = value;
                    }
                    return items;
                });
            }
            this.actions.update();
        },
        fieldChangedTime(parentItem, item, value) {
            let { listData } = this.privates;
            if (!_isEmpty(listData)) {
                listData.map(items => {
                    if (items.parentNum === parentItem.num && items.num === item.num) {
                        items.unit = value;
                    }
                    return items;
                });
            }
            this.actions.update();
        },

        //信息修改或者保存
        dataCallRelatedSaved(e) {
            let { sdkOptions } = this.props;
            let { locales } = this;
            this.privates.loading = true;
            this.actions.update();
            let { editOrSaveStates, listData, dataCallRelatedConfigId, activeFlag, parentNumList } = this.privates;
            let url1 = Udesk.business.apiPath.concatApiPath(`data-call-related-config`, sdkOptions);
            let url2 = Udesk.business.apiPath.concatApiPath(
                `data-call-related-config/${dataCallRelatedConfigId}`,
                sdkOptions
            );
            let list = [];
            parentNumList.forEach((item, count) => {
                let arr = _filter(listData, current => current.parentNum === item.num).map((items, index) => {
                    items.num = index + 1;
                    items.parentNum = count + 1;
                    return items;
                });
                list = [...list, ...arr];
            });
            e.preventDefault();
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let data = {
                        activeFlag,
                        filter: {
                            conditionList: list
                        }
                    };
                    new Promise((resolve, reject) => {
                        if (editOrSaveStates && dataCallRelatedConfigId) {
                            Udesk.ajax.put(url2, data).then(
                                resp => {
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.success(Locales['current'].fix.editSuccess);
                                    this.actions.reloadAsyncModel();
                                    resolve(resp);
                                },
                                reason => {
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.error(reason.errorMsg);
                                    reject(reason);
                                }
                            );
                        } else {
                            Udesk.ajax.post(url1, data).then(
                                resp => {
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.success(Locales['current'].fix.saveSuccess);
                                    this.actions.reloadAsyncModel();
                                    resolve(resp);
                                },
                                reason => {
                                    this.privates.loading = false;
                                    this.actions.update();
                                    Udesk.ui.notify.error(reason.errorMsg);
                                    reject(reason);
                                }
                            );
                        }
                    });
                } else {
                    Udesk.ui.notify.error(locales.components.pages.connectedByConversation.list.fieldNot);
                }
            });
        },
        //信息取消保存
        dataCallRelatedCancel() {
            this.actions.reloadAsyncModel();
        },
        //开头等于，结尾等于位数
        beginAndEndInput(parentItem, item, value) {
            let { listData } = this.privates;
            if (!_isEmpty(listData)) {
                listData.map(items => {
                    if (items.parentNum === parentItem.num && items.num === item.num) {
                        items.value = value;
                    }
                    return items;
                });
            }
            this.actions.update();
        },
        //数字类型，相差输入框
        numberChangeInput(parentItem, item, value) {
            let { listData } = this.privates;
            if (!_isEmpty(listData)) {
                listData.map(items => {
                    if (items.parentNum === parentItem.num && items.num === item.num) {
                        items.value = value;
                    }
                    return items;
                });
            }
            this.actions.update();
        }
    };
    componentDidUpdate(prevProps, prevStates) { }
}
