// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostConceptWordsFindAllBody, BaseResponseListWordsFoundResponse } from '../../types';

/**
 * findAllOperator
 *
 * @export
 * @tags words-controller
 * @link [POST] /conceptWords/findAll
 */
export function postConceptWordsFindAll(
    data: PostConceptWordsFindAllBody
): Promise<BaseResponseListWordsFoundResponse>;
export function postConceptWordsFindAll(
    data: PostConceptWordsFindAllBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListWordsFoundResponse>;
export function postConceptWordsFindAll(
    data: PostConceptWordsFindAllBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListWordsFoundResponse> {
    return request<PostConceptWordsFindAllBody, never, never>('/conceptWords/findAll', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postConceptWordsFindAll',
    });
}

export const meta = [{ tags: ['words-controller'], path: '/conceptWords/findAll', method: 'post' }];
