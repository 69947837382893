import { useMemo } from 'react';

export const useFormattedDialogueSentenceList = (
    dialogueSentenceListProps,
    formatItemRenderProps = (props) => props
) => {
    return useMemo(() => {
        return dialogueSentenceListProps?.map((sentence) => {
            return formatItemRenderProps(sentence);
        });
    }, [dialogueSentenceListProps, formatItemRenderProps]);
};
