import Udesk from 'Udesk';
import React from 'react';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import UdeskLocales from 'UdeskLocales';

class AnalysisEnable extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { route, location, sdkOptions } = this.props;

        let { locales } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('analysis:supervision:root')) {
            navLists.push({
                text: /* 执行力监督 */locales.pages.analysisEnable.executiveSupervision,
                icon: 'yonghuzhongxin',
                pathName: 'monitoringReport',
                isActive: (match, location) => {
                    return location.pathname.includes('/analysis-enable/monitoring-report');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('analysis:supervision:board')) {
            navLists.push({
                text: /* 执行力分析 */locales.pages.analysisEnable.executiveForceAnalysis,
                icon: 'yonghuzhongxin',
                pathName: 'executionAnalysis',
                isActive: (match, location) => {
                    return location.pathname.includes('/analysis-enable/execution-analysis');
                },
            });
        }
        // 根据当前用户权限，组装菜单列表
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('analysis:group:root')) {
            navLists.push({
                text: /* 最佳实践 */UdeskLocales['current'].pages.analysisEnable.bestPractices,
                icon: 'yonghuzhongxin',
                pathName: 'teamAnalysis',
                isActive: (match, location) => {
                    return location.pathname.includes('/analysis-enable/team-analysis');
                },
            });
        }
        let grounp = [];
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('analysis:study:root')) {
            grounp.push({
                text: locales.menu.tasks.analysisStudy.text,
                pathName: 'analysisEnableStudy',
                isActive: (match, location) => {
                    return location.pathname.includes('/analysis-enable/study');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('analysis:review:root')) {
            grounp.push({
                text: locales.menu.tasks.analysisReview.text,
                pathName: 'analysisEnableReplay',
                isActive: (match, location) => {
                    return location.pathname.includes('/analysis-enable/replay');
                },
            });
        }
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('analysis:seek:root')) {
            grounp.push({
                text: locales.menu.tasks.analysisHelp.text,
                pathName: 'analysisEnableHelp',
                isActive: (match, location) => {
                    return location.pathname.includes('/analysis-enable/help');
                },
            });
        }
        navLists.push({
            text: /* 行为分析 */UdeskLocales['current'].pages.analysisEnable.behaviorAnalysis,
            children: grounp,
        });
        grounp = [];

        // navLists.push({
        //     text: locales.menu.tasks.analysisSentiment.text,
        //     pathName: 'analysisEnableSentiment',
        //     isActive: (match, location) => {
        //         return location.pathname.includes('/analysis-enable/sentiment');
        //     },
        // });
        // navLists.push({
        //     text: locales.menu.tasks.analysisBusiness.text,
        //     pathName: 'analysisEnableBusiness',
        //     isActive: (match, location) => {
        //         return location.pathname.includes('/analysis-enable/business');
        //     },
        // });

        if (sdkOptions.props.landingPath != null && this.isRedirect(route.path, location.pathname)) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className='semantic-intelligence'>
                    <LayoutMenu {...this.props} navLists={navLists} menuTitle={/* 团队分析 */UdeskLocales['current'].pages.analysisEnable.teamAnalysis} />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(AnalysisEnable);
