export default {
    getRunTimeEnvironment,
};

const ENVIRONMENT_DOMAINS = {
    production: [],
    staging: ['s-*.udesk.cn', '*.flyudesk.com', '*.tryudesk.com', '*.udeskt1.com', '*.udeskt2.com', '*.udeskt3.com'],
    development: ['*.ud.com', '*.dev', '*.udeskdev.com', 'local-*.udesk.cn', '*.local.udesk.cn'],
};

export function getRunTimeEnvironment(hostname = window.location.hostname, config = ENVIRONMENT_DOMAINS) {
    let environmentKeys = Object.keys(config);
    let runTimeEnvironment = 'production';
    for (let index = 0; index < environmentKeys.length; index++) {
        let environment = environmentKeys[index];
        let domains = config[environment];
        if (domains != null && domains.length > 0) {
            for (let domainsIndex = 0; domainsIndex < domains.length; domainsIndex++) {
                let domainRule = domains[domainsIndex];
                if (typeof domainRule === 'string') {
                    let regExp = new RegExp(
                        '^' + domainRule.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$&').replace('\\*', '.*') + '$',
                        'i'
                    );
                    if (regExp.test(hostname)) {
                        runTimeEnvironment = environment;
                    }
                }
            }
        }
    }
    return runTimeEnvironment;
}
