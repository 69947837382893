import React from 'react';
import Udesk from 'Udesk';
import ClassNames from 'classnames';
import SafeHtml from '../../../common/safe-html';
import AudioTextViewContentComponent from './component';
import ReactTinymce from 'udesk-react/src/components/react-tinymce';
import './style.scss';
import { Input, Tooltip } from 'udesk-ui';
import XssComponent from 'udesk-react/src/components/xss';

export default class AudioTextViewContentTemplate extends AudioTextViewContentComponent {
    render() {
        let {
            inspectDataSource,
            item,
            type,
            content,
            markSemanticVisible,
            sdkOptions,
            dialogRemarkList
        } = this.props;
        let {
            actions,
            locales
        } = this;
        let { callFields, averageFlag, vedioDom } = this.privates;
        let dialogRemark = Array.isArray(dialogRemarkList) ? dialogRemarkList.find(i => `${i.index}` === `${this.props.index + 1}`) : null;
        let hasRemark = !!dialogRemark;
        let remarkText = hasRemark ? `${locales.labels.remark}: ${dialogRemark.remark}` : '';
        return (
            <div className="audio-text-list-item-content">
                <p className="audio-text-list-item-time">
                    <Choose>
                        <When condition={inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id}>
                            {getAudioItemTime(item.data.fromSec, item.data.endSec)}
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-play audio-text-list-item-player" onClick={actions.sendCurrentTimeRange.params(item)}></i>
                        </When>
                        <When condition={inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id}>
                            {item.data.createdTime}
                        </When>
                    </Choose>
                    <If condition={item.data.averageSpeakSpeed && averageFlag}>
                        <span style={{ marginLeft: '5px' }} className='audio-text-average'> -{item.data.averageSpeakSpeed} {locales.fix.wordSpeed}</span>
                    </If>
                </p>
                <div className="audio-text-list-item-text case-base-audio-text-list-detail">
                    <If condition={this.props.fieldFlag} >
                        <If condition={this.privates.addFlag} >
                            <If condition={item.data.role === "agent" && (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || type === "message")} >
                                <div className="audio-text-list-item-text-action-caseBase">
                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yitianjiaanli" onClick={actions.markSemantic.params(item.states.text || content)}></i>
                                </div>
                            </If>
                        </If>
                    </If>

                    <If condition={!this.props.fieldFlag && markSemanticVisible && item.data.role === "agent" && (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || type === "message")} >
                        <div className="audio-text-list-item-text-action">
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-dengpao audio-text-list-item-mark-semantic" onClick={actions.markSemantic.params(item.states.text || content)}></i>
                        </div>
                    </If>

                    <div className="audio-text-list-item-text-container" onMouseEnter={actions.averageChange.params(true)} onMouseLeave={actions.averageChange.params(false)}>
                        <div className="audio-text-list-item-text" >
                            <Choose>
                                <When condition={inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id}>
                                    {
                                        this.props.fieldFlag ? (
                                            <div>{this.privates.inputStatus ? <XssComponent classNames={ClassNames("", { "audio-text-list-item-text-hit": item.states.isHit })} value={callFields} /> : <Input defaultValue={callFields} onChange={actions.inputEdit} />}</div>
                                        ) : (<XssComponent classNames={ClassNames("", { "audio-text-list-item-text-hit": item.states.isHit })} value={item.states.text} />)
                                    }
                                </When>
                                <When condition={inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id}>
                                    <Choose>
                                        <When condition={type === "message"}>
                                            {
                                                this.props.fieldFlag ? (
                                                    <div>
                                                        {
                                                            this.privates.inputStatus ? <XssComponent classNames={ClassNames("", { "audio-text-list-item-text-hit": item.states.isHit })} value={callFields} /> : <ReactTinymce value={callFields} onEditorChange={actions.onEditorChange} tinymcePath={`${Udesk.business.apiPath.getStaticBasePath(sdkOptions.props.domain, sdkOptions.props.pathPrefix)}/vendor/tinymce/tinymce.min.js`} />
                                                        }
                                                    </div>
                                                ) : (<XssComponent classNames={ClassNames("", { "audio-text-list-item-text-hit": item.states.isHit })} value={content} />)
                                            }
                                        </When>
                                        <When condition={type === "rich"}>
                                            <SafeHtml className={ClassNames("", { "audio-text-list-item-text-hit": item.states.isHit })} content={content} />
                                        </When>
                                        <When condition={type === "image"}>
                                            <img src={content || item.states.content.data.origin_url} className="audio-text-list-item-img" alt="" style={{ width: '100%' }} />
                                        </When>
                                        <When condition={type === "audio"}>
                                            <audio src={content}></audio>
                                        </When>
                                        <When condition={type === "file"}>
                                            <a href={content}>{item.states.content.filename}</a>
                                        </When>
                                        <When condition={type === 'video'}>
                                            <video ref={vedioDom} onClick={() => vedioDom && vedioDom.current.webkitRequestFullScreen()} className='audio-text-list-item-text-video' controls="controls" src={content}></video>
                                        </When>
                                        <Otherwise>
                                            {locales.components.pages.appealChekingTask.list.unsupportedFormat}{type}
                                        </Otherwise>
                                    </Choose>
                                </When>
                            </Choose>
                        </div>
                        <If condition={this.props.fieldFlag} >
                            <If condition={markSemanticVisible && item.data.role === "agent" && (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || type === "message")} >
                                <div className="aduio-text-case-base-agent" >
                                    <span onClick={actions.caseBaseAdd.params(item.data.id)}>
                                        {
                                            this.privates.fields ? (<span className="case-audio-add"><i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tianjiaanli'></i>{locales.components.pages.caseBaseList.detail.addCaseBase}</span>) : (<span className="case-audio-add"><i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yichu'></i>{locales.components.pages.caseBaseList.detail.delCaseBase}</span>)
                                        }
                                    </span>
                                    {
                                        this.privates.inputStatus ? <span onClick={actions.fieldsChange}>{locales.components.pages.caseBaseList.edit}</span> : <span><span className="audio-detail-okText" onClick={actions.onOkFields.params(item.data.id)}>{locales.components.pages.caseBaseList.ok}</span><span onClick={actions.closeFields}>{locales.components.pages.caseBaseList.cancel}</span></span>
                                    }

                                </div>
                            </If>
                            <If condition={markSemanticVisible && item.data.role === "customer" && (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || type === "message")} >

                                <div className="aduio-text-case-base-customer" >
                                    <span onClick={actions.caseBaseAdd.params(item.data.id)}>
                                        {
                                            this.privates.fields ? (<span className="case-audio-add"><i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-tianjiaanli'></i>{locales.components.pages.caseBaseList.detail.addCaseBase}</span>) : (<span className="case-audio-add"><i className='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yichu'></i>{locales.components.pages.caseBaseList.detail.delCaseBase}</span>)
                                        }
                                    </span>
                                    {
                                        this.privates.inputStatus ? <span onClick={actions.fieldsChange}>{locales.components.pages.caseBaseList.edit}</span> : <span><span className="audio-detail-okText" onClick={actions.onOkFields.params(item.data.id)}>{locales.components.pages.caseBaseList.ok}</span><span onClick={actions.closeFields}>{locales.components.pages.caseBaseList.cancel}</span></span>
                                    }

                                </div>

                            </If>
                        </If>
                    </div>
                    <If condition={!this.props.fieldFlag && markSemanticVisible && item.data.role === "customer" && (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || type === "message")} >
                        <div className="audio-text-list-item-text-action">
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-dengpao audio-text-list-item-mark-semantic" onClick={actions.markSemantic.params(item.states.text || content)}></i>
                        </div>
                    </If>
                    <If condition={this.props.fieldFlag} >
                        <If condition={this.privates.addFlag} >
                            <If condition={markSemanticVisible && item.data.role === "customer" && (inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || type === "message")} >
                                <div className="audio-text-list-item-text-action-caseBase">
                                    <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yitianjiaanli" onClick={actions.markSemantic.params(item.states.text || content)}></i>
                                </div>
                            </If>
                        </If>
                    </If>
                </div>
                {hasRemark && (
                    <Tooltip title={remarkText}>
                        <div
                            trigger='hover'
                            className='message-tools-bar-remark'
                        >{remarkText}</div>
                    </Tooltip>
                )}
                <div style={{ width: "100%", height: "25px" }}></div>
            </div>
        );
    }
}

function getAudioItemTime(startPoint, endPoint) {
    let start = Udesk.utils.date.formatDurationWithMillisecond(startPoint);
    let end = Udesk.utils.date.formatDurationWithMillisecond(endPoint);
    return `${start} - ${end}`;
}
