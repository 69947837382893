export function installPlugin(udesk, path, plugin) {
    assign(udesk, path, plugin);
}

export function globalInstall(path, plugin) {
    if (window.Udesk != null) {
        assign(window.Udesk, path, plugin);
    }
}

function assign(obj, path, value) {
    if (obj != null) {
        let subPaths = path.split(".");
        if (subPaths.length === 1) {
            obj[path] = value;
        } else {
            let curObj = obj;
            for (let i = 0; i < subPaths.length - 1; i++) {
                let curPath = subPaths[i];
                if (!Object.prototype.hasOwnProperty.call(curObj,curPath)) {
                    curObj[curPath] = {};
                }
                curObj = curObj[curPath];
            }
            let targetObj = curObj[subPaths[subPaths.length - 1]];
            if (typeof targetObj === "object") {
                Object.assign(targetObj, value);
            } else {
                curObj[subPaths[subPaths.length - 1]] = value;
            }
        }
    }
}
