import Locales from '../locales/index';
import UdeskLocales from 'UdeskLocales';

let visibleRangeTypes = [
    { id: 1, name: () => Locales.current.enums.visibleRangeTypes.all, key: 'all' },
    {
        id: 2,
        name: () => Locales.current.enums.visibleRangeTypes.customerGroup,
        key: 'customerGroup',
    },
    { id: 3, name: () => Locales.current.enums.visibleRangeTypes.userDefined, key: 'userDefined' },
];

let samplingRateTypes = [
    { id: 1, name: () => Locales.current.enums.samplingRateTypes.everyDay, key: 'everyDay' },
    { id: 2, name: () => Locales.current.enums.samplingRateTypes.everyWeek, key: 'everyWeek' },
];

let taskRemindTypes = [
    { id: 1, name: () => Locales.current.enums.taskRemindTypes.confirm, key: 'confirm' },
    { id: 2, name: () => Locales.current.enums.taskRemindTypes.appeal, key: 'appeal' },
];

let samplingRangeDayTypes = [
    { id: 2, name: () => Locales.current.enums.samplingRangeDayTypes.today, key: 'today' },
    { id: 1, name: () => Locales.current.enums.samplingRangeDayTypes.yesterday, key: 'yesterday' },
];

let samplingRangeWeekTypes = [
    { id: 4, name: () => Locales.current.enums.samplingRangeWeekTypes.thisWeek, key: 'thisWeek' },
    { id: 3, name: () => Locales.current.enums.samplingRangeWeekTypes.lastWeek, key: 'lastWeek' },
];

let samplingRuleTypes = [
    { id: 1, name: () => Locales.current.enums.samplingRuleTypes.proportion, key: 'proportion' },
    { id: 2, name: () => Locales.current.enums.samplingRuleTypes.random, key: 'random' },
];

let taskStatusTypes = [
    { id: 1, name: () => Locales.current.enums.taskStatusTypes.waitEvaluate, key: 'waitEvaluate' },
    { id: 2, name: () => Locales.current.enums.taskStatusTypes.waitSubmit, key: 'waitSubmit' },
    { id: 3, name: () => Locales.current.enums.taskStatusTypes.waitConfirm, key: 'waitConfirm' },
    { id: 4, name: () => Locales.current.enums.taskStatusTypes.waitReview, key: 'waitReview' },
    { id: 5, name: () => Locales.current.enums.taskStatusTypes.completed, key: 'completed' },
    { id: -1, name: () => Locales.current.enums.taskStatusTypes.all, key: 'all' },
];

let samplingColumnCaptions = [
    {
        id: 'created_at',
        name: () => Locales.current.enums.samplingColumnCaptions.created_at,
        key: 'created_at',
    },
    {
        id: 'agent_id',
        name: () => Locales.current.enums.samplingColumnCaptions.agent_id,
        key: 'agent_id',
    },
    {
        id: 'get_direction',
        name: () => Locales.current.enums.samplingColumnCaptions.get_direction,
        key: 'get_direction',
    },
    {
        id: 'call_result',
        name: () => Locales.current.enums.samplingColumnCaptions.call_result,
        key: 'call_result',
    },
    {
        id: 'survey',
        name: () => Locales.current.enums.samplingColumnCaptions.survey,
        key: 'survey',
    },
    {
        id: 'derived_from_id',
        name: () => Locales.current.enums.samplingColumnCaptions.derived_from_id,
        key: 'derived_from_id',
    },
    {
        id: 'quit_queue_why',
        name: () => Locales.current.enums.samplingColumnCaptions.quit_queue_why,
        key: 'quit_queue_why',
    },
    {
        id: 'queuing_duration',
        name: () => Locales.current.enums.samplingColumnCaptions.queuing_duration,
        key: 'queuing_duration',
    },
    {
        id: 'talking_seconds',
        name: () => Locales.current.enums.samplingColumnCaptions.talking_seconds,
        key: 'talking_seconds',
    },
    {
        id: 'ringing_seconds',
        name: () => Locales.current.enums.samplingColumnCaptions.ringing_seconds,
        key: 'ringing_seconds',
    },
    {
        id: 'hangup_by',
        name: () => Locales.current.enums.samplingColumnCaptions.hangup_by,
        key: 'hangup_by',
    },
];

let workFlowTypes = [
    { id: 1, name: () => Locales.current.enums.workFlowTypes.noAppealsMode, key: 'noAppealsMode' },
    { id: 2, name: () => Locales.current.enums.workFlowTypes.appealsMode, key: 'appealsMode' },
];

let fieldCategories = [
    {
        id: 1,
        name: () => Locales.current.enums.fieldCategories.standardCondition,
        key: 'standardCondition',
    },
    {
        id: 2,
        name: () => Locales.current.enums.fieldCategories.customerCondition,
        key: 'customerCondition',
    },
];

let callTypes = [
    {
        id: /* 呼入 */ Locales.current.enums.qualityChecingTask.incomingCall,
        name: () => Locales.current.enums.callTypes.callIn,
        key: 'callIn',
    },
    {
        id: /* 呼出 */ Locales.current.enums.qualityChecingTask.exhale,
        name: () => Locales.current.enums.callTypes.callOut,
        key: 'callOut',
    },
    {
        id: /* 呼入（三方） */ Locales.current.enums.qualityChecingTask.incomingCallthirdParty,
        name: () =>
            Locales.current.enums.callTypes.callIn + Locales.current.enums.callTypes.threeParty,
        key: 'callInThreeParty',
    },
    {
        id: /* 呼入（咨询） */ Locales.current.enums.qualityChecingTask.incomingCallconsultation,
        name: () =>
            Locales.current.enums.callTypes.callIn + Locales.current.enums.callTypes.consultation,
        key: 'callInConsultation',
    },
    {
        id: /* 呼入（强插） */ Locales.current.enums.qualityChecingTask.incomingCallforcedInsertion,
        name: () => Locales.current.enums.callTypes.callIn + Locales.current.enums.callTypes.insert,
        key: 'callInInsert',
    },
    {
        id: /* 呼入（监听） */ Locales.current.enums.qualityChecingTask.incomingCallmonitoring,
        name: () =>
            Locales.current.enums.callTypes.callIn + Locales.current.enums.callTypes.monitor,
        key: 'callInMonitor',
    },
    {
        id: /* 呼入（转接） */ Locales.current.enums.qualityChecingTask.incomingCalltransfer,
        name: () =>
            Locales.current.enums.callTypes.callIn + Locales.current.enums.callTypes.transfer,
        key: 'callInTransfer',
    },
    {
        id: /* 呼入（拦截） */ Locales.current.enums.qualityChecingTask.incomingCallinterception,
        name: () =>
            Locales.current.enums.callTypes.callIn + Locales.current.enums.callTypes.intercept,
        key: 'callInIntercept',
    },
    {
        id: /* 呼入（转接外线） */ Locales.current.enums.qualityChecingTask
            .incomingCalltransferToExternalLine,
        name: () =>
            Locales.current.enums.callTypes.callIn +
            Locales.current.enums.callTypes.transferOutside,
        key: 'callInTransferOutside',
    },
    {
        id: /* 呼入（三方外线） */ Locales.current.enums.qualityChecingTask
            .incomingCallthirdPartyExternalLine,
        name: () =>
            Locales.current.enums.callTypes.callIn +
            Locales.current.enums.callTypes.threePartyOutside,
        key: 'callInThreePartyOutside',
    },
    {
        id: /* 呼入（咨询外线） */ Locales.current.enums.qualityChecingTask
            .incomingCallexternalLine,
        name: () =>
            Locales.current.enums.callTypes.callIn +
            Locales.current.enums.callTypes.consultingOutside,
        key: 'callInConsultingOutside',
    },
    {
        id: /* 呼出（三方） */ Locales.current.enums.qualityChecingTask.outgoingCallthirdParty,
        name: () =>
            Locales.current.enums.callTypes.callOut + Locales.current.enums.callTypes.threeParty,
        key: 'callOutThreeParty',
    },
    {
        id: /* 呼出（转接） */ Locales.current.enums.qualityChecingTask.outgoingCalltransfer,
        name: () =>
            Locales.current.enums.callTypes.callOut + Locales.current.enums.callTypes.transfer,
        key: 'callOutTransfer',
    },
    {
        id: /* 呼出（强插） */ Locales.current.enums.qualityChecingTask.outgoingCallforcedInsertion,
        name: () =>
            Locales.current.enums.callTypes.callOut + Locales.current.enums.callTypes.insert,
        key: 'callOutInsert',
    },
    {
        id: /* 呼出（监听） */ Locales.current.enums.qualityChecingTask.outgoingCallmonitoring,
        name: () =>
            Locales.current.enums.callTypes.callOut + Locales.current.enums.callTypes.monitor,
        key: 'callOutMonitor',
    },
    {
        id: /* 呼出（拦截） */ Locales.current.enums.qualityChecingTask.outgoingCallinterception,
        name: () =>
            Locales.current.enums.callTypes.callOut + Locales.current.enums.callTypes.intercept,
        key: 'callOutIntercept',
    },
    {
        id: /* 呼出（转接外线） */ Locales.current.enums.qualityChecingTask
            .outgoingCalltransferToExternalLine,
        name: () =>
            Locales.current.enums.callTypes.callOut +
            Locales.current.enums.callTypes.transferOutside,
        key: 'callOutTransferOutside',
    },
    {
        id: /* 呼出（三方外线） */ Locales.current.enums.qualityChecingTask
            .outgoingCallthreePartyExternalLine,
        name: () =>
            Locales.current.enums.callTypes.callOut +
            Locales.current.enums.callTypes.threePartyOutside,
        key: 'callOutThreePartyOutside',
    },
];

let callResults = [
    {
        id: /* 客服接听 */ Locales.current.enums.qualityChecingTask.customerServiceAnswer,
        name: () => Locales.current.enums.callResults.staffAnswer,
        key: 'staffAnswer',
    },
    {
        id: /* 客服未接 */ Locales.current.enums.qualityChecingTask.customerServiceMissed,
        name: () => Locales.current.enums.callResults.staffNotAnswer,
        key: 'staffNotAnswer',
    },
    {
        id: /* 客户接听 */ Locales.current.enums.qualityChecingTask.customerAnswer,
        name: () => Locales.current.enums.callResults.customerAnswer,
        key: 'customerAnswer',
    },
    {
        id: /* 客户未接 */ Locales.current.enums.qualityChecingTask.customerMissed,
        name: () => Locales.current.enums.callResults.customerNotAnswer,
        key: 'customerNotAnswer',
    },
    {
        id: /* 话机占线 */ Locales.current.enums.qualityChecingTask.theTelephoneIsBusy,
        name: () => Locales.current.enums.callResults.phoneBusy,
        key: 'phoneBusy',
    },
    {
        id: /* 话机离线 */ Locales.current.enums.qualityChecingTask.phoneOffline,
        name: () => Locales.current.enums.callResults.phoneOffline,
        key: 'phoneOffline',
    },
    {
        id: /* 客户速挂 */ Locales.current.enums.qualityChecingTask.customerQuickHanging,
        name: () => Locales.current.enums.callResults.customerSpeedHangUp,
        key: 'customerSpeedHangUp',
    },
    {
        id: /* 客户挂机 */ Locales.current.enums.qualityChecingTask.clientHangsUp,
        name: () => Locales.current.enums.callResults.customerHangUp,
        key: 'customerHangUp',
    },
    {
        id: /* 排队超时 */ Locales.current.enums.qualityChecingTask.queueTimeout,
        name: () => Locales.current.enums.callResults.queueTimeout,
        key: 'queueTimeout',
    },
    {
        id: /* 放弃排队 */ Locales.current.enums.qualityChecingTask.giveUpQueuing,
        name: () => Locales.current.enums.callResults.queueGiveUp,
        key: 'queueGiveUp',
    },
    {
        id: /* 外线接听 */ Locales.current.enums.qualityChecingTask.outsideLineAnswering,
        name: () => Locales.current.enums.callResults.outlineAnswer,
        key: 'outlineAnswer',
    },
    {
        id: /* 外线未接 */ Locales.current.enums.qualityChecingTask.externalLineIsNotConnected,
        name: () => Locales.current.enums.callResults.outlineNotAnswer,
        key: 'outlineNotAnswer',
    },
    {
        id: /* 未选择队列 */ Locales.current.enums.qualityChecingTask.noQueueSelected,
        name: () => Locales.current.enums.callResults.noChooseQueue,
        key: 'noChooseQueue',
    },
];

let defaultSurveys = [
    {
        id: /* 未评价 */ Locales.current.enums.qualityChecingTask.notEvaluated,
        name: () => Locales.current.enums.defaultSurveys.noEvaluation,
        key: 'noEvaluation',
    },
    {
        id: /* 无需评价 */ Locales.current.enums.qualityChecingTask.noEvaluationRequired,
        name: () => Locales.current.enums.defaultSurveys.noNeedToEvaluate,
        key: 'noNeedToEvaluate',
    },
];

let queueStates = [
    {
        id: /* 排队成功 */ Locales.current.enums.qualityChecingTask.queuingSucceeded,
        name: () => Locales.current.enums.queueStates.queueSuccess,
        key: 'queueSuccess',
    },
    {
        id: /* 排队超时 */ Locales.current.enums.qualityChecingTask.queueTimeout,
        name: () => Locales.current.enums.queueStates.queueTimeout,
        key: 'queueTimeout',
    },
    {
        id: /* 放弃排队 */ Locales.current.enums.qualityChecingTask.giveUpQueuing,
        name: () => Locales.current.enums.queueStates.queueGiveUp,
        key: 'queueGiveUp',
    },
    {
        id: /* 无客服在线 */ Locales.current.enums.qualityChecingTask.noCustomerServiceOnline,
        name: () => Locales.current.enums.queueStates.noStaffOnline,
        key: 'noStaffOnline',
    },
];

let callHangers = [
    {
        id: /* 客户 */ Locales.current.enums.qualityChecingTask.customer,
        name: () => Locales.current.enums.callHangers.customer,
        key: 'customer',
    },
    {
        id: /* 客服 */ Locales.current.enums.qualityChecingTask.customerService,
        name: () => Locales.current.enums.callHangers.staff,
        key: 'staff',
    },
    {
        id: /* 外线 */ Locales.current.enums.qualityChecingTask.outsideLines,
        name: () => Locales.current.enums.callHangers.outline,
        key: 'outline',
    },
];

let sampleTypes = [
    { id: 1, name: () => Locales.current.enums.sampleTypes.automatic, key: 'automatic' },
    { id: 2, name: () => Locales.current.enums.sampleTypes.manual, key: 'manual' },
];

let defaultFields = [
    {
        id: 'created_at',
        name: () => Locales.current.enums.defaultFields.createdAt,
        key: 'createdAt',
    },
    { id: 'agent_id', name: () => Locales.current.enums.defaultFields.agentId, key: 'agentId' },
    {
        id: 'get_direction',
        name: () => Locales.current.enums.defaultFields.getDirection,
        key: 'getDirection',
    },
    {
        id: 'call_result',
        name: () => Locales.current.enums.defaultFields.callResult,
        key: 'callResult',
    },
    { id: 'survey', name: () => Locales.current.enums.defaultFields.survey, key: 'survey' },
    {
        id: 'derived_from_id',
        name: () => Locales.current.enums.defaultFields.derivedFrom,
        key: 'derivedFrom',
    },
    {
        id: 'quit_queue_why',
        name: () => Locales.current.enums.defaultFields.quitQueueWhy,
        key: 'quitQueueWhy',
    },
    {
        id: 'queuing_duration',
        name: () => Locales.current.enums.defaultFields.queuingDuration,
        key: 'queuingDuration',
    },
    {
        id: 'talking_seconds',
        name: () => Locales.current.enums.defaultFields.talkingSeconds,
        key: 'talkingSeconds',
    },
    {
        id: 'ringing_seconds',
        name: () => Locales.current.enums.defaultFields.ringingSeconds,
        key: 'ringingSeconds',
    },
    { id: 'hangup_by', name: () => Locales.current.enums.defaultFields.hangupBy, key: 'hangupBy' },
];

let conditionFieldCategories = [
    {
        id: 1,
        key: 'fixedField',
        name: () => Locales.current.enums.conditionFieldCategories.fixedField,
    },
    {
        id: 2,
        key: 'customField',
        name: () => Locales.current.enums.conditionFieldCategories.customField,
    },
    {
        id: 3,
        key: 'secondLevelScore',
        name: () => Locales.current.enums.conditionFieldCategories.secondLevelScore,
    },
    {
        id: 4,
        key: 'formItemScore',
        name: () => Locales.current.enums.conditionFieldCategories.formItemScore,
    },
    { id: 5, key: 'forbids', name: () => Locales.current.enums.conditionFieldCategories.forbids },
    { id: 6, key: 'deadlies', name: () => Locales.current.enums.conditionFieldCategories.deadlies },
];

let columnFieldCategories = [
    { id: 1, key: 'agent', name: () => Locales.current.enums.columnFieldCategories.agent },
    { id: 2, key: 'callLog', name: () => Locales.current.enums.columnFieldCategories.callLog },
    {
        id: 3,
        key: 'businessFields',
        name: () => Locales.current.enums.columnFieldCategories.businessFields,
    },
    { id: 4, key: 'customer', name: () => Locales.current.enums.columnFieldCategories.customer },
    {
        id: 5,
        key: 'customerCustomFields',
        name: () => Locales.current.enums.columnFieldCategories.customerCustomFields,
    },
    { id: 6, key: 'source', name: () => Locales.current.enums.columnFieldCategories.source },
    { id: 7, key: 'queue', name: () => Locales.current.enums.columnFieldCategories.queue },
    { id: 8, key: 'fixed', name: () => Locales.current.enums.columnFieldCategories.fixed },
    {
        id: 9,
        key: 'secondLevelScore',
        name: () => Locales.current.enums.columnFieldCategories.secondLevelScore,
    },
    {
        id: 10,
        key: 'formItemScore',
        name: () => Locales.current.enums.columnFieldCategories.formItemScore,
    },
    { id: 11, key: 'forbids', name: () => Locales.current.enums.columnFieldCategories.forbids },
    { id: 12, key: 'deadlies', name: () => Locales.current.enums.columnFieldCategories.deadlies },
];

let taskListDefaultFields = [
    {
        id: 'start_time',
        key: 'startTime',
        name: () => Locales.current.enums.taskListDefaultFields.startTime,
    },
    { id: 'name', key: 'name', name: () => Locales.current.enums.taskListDefaultFields.name },
    { id: 'state', key: 'state', name: () => Locales.current.enums.taskListDefaultFields.state },
    {
        id: 'totalScore',
        key: 'totalScore',
        name: () => Locales.current.enums.taskListDefaultFields.totalScore,
    },
    {
        id: 'formItemForbidList',
        key: 'formItemForbidList',
        name: () => Locales.current.enums.taskListDefaultFields.formItemForbidList,
    },
    {
        id: 'formItemDeadlyList',
        key: 'formItemDeadlyList',
        name: () => Locales.current.enums.taskListDefaultFields.formItemDeadlyList,
    },
    {
        id: 'comment',
        key: 'comment',
        name: () => Locales.current.enums.taskListDefaultFields.comment,
    },
    {
        id: 'inspector',
        key: 'inspector',
        name: () => Locales.current.enums.taskListDefaultFields.inspector,
    },
    { id: 'review', key: 'review', name: () => Locales.current.enums.taskListDefaultFields.review },
];

let evaluationStates = [
    { id: 0, key: 'initial', name: () => Locales.current.enums.evaluationStates.initial },
    { id: 1, key: 'waitEvaluate', name: () => Locales.current.enums.evaluationStates.waitEvaluate },
    { id: 2, key: 'evaluated', name: () => Locales.current.enums.evaluationStates.evaluated },
    { id: 3, key: 'waitConfirm', name: () => Locales.current.enums.evaluationStates.waitConfirm },
    { id: 4, key: 'waitCheck', name: () => Locales.current.enums.evaluationStates.waitCheck },
    { id: 5, key: 'complete', name: () => Locales.current.enums.evaluationStates.complete },
];

let workBenchInfoTypes = [
    {
        id: 'callInfo',
        key: 'callInfo',
        name: () => Locales.current.enums.workBenchInfoTypes.callInfo,
    },
    {
        id: 'businessInfo',
        key: 'businessInfo',
        name: () => Locales.current.enums.workBenchInfoTypes.businessInfo,
    },
    {
        id: 'customerInfo',
        key: 'customerInfo',
        name: () => Locales.current.enums.workBenchInfoTypes.customerInfo,
    },
];

let evaluationEventStates = [
    { id: 1, key: 'create', name: () => Locales.current.enums.evaluationEventStates.creat },
    { id: 2, key: 'valuate', name: () => Locales.current.enums.evaluationEventStates.valuate },
    { id: 3, key: 'edit', name: () => Locales.current.enums.evaluationEventStates.edit },
    { id: 4, key: 'submit', name: () => Locales.current.enums.evaluationEventStates.submit },
    { id: 5, key: 'confirm', name: () => Locales.current.enums.evaluationEventStates.confirm },
    { id: 6, key: 'appeal', name: () => Locales.current.enums.evaluationEventStates.appeal },
    {
        id: 7,
        key: 'checkConfirm',
        name: () => Locales.current.enums.evaluationEventStates.checkConfirm,
    },
    { id: 8, key: 'check', name: () => Locales.current.enums.evaluationEventStates.check },
];

let formItemTypes = [
    { id: 1, key: 'direct', name: () => Locales.current.enums.formItemTypes.direct },
    { id: 2, key: 'weight', name: () => Locales.current.enums.formItemTypes.weight },
    { id: 3, key: 'deduct', name: () => Locales.current.enums.formItemTypes.deduct },
    { id: 4, key: 'forbid', name: () => Locales.current.enums.formItemTypes.forbid },
    { id: 5, key: 'deadly', name: () => Locales.current.enums.formItemTypes.deadly },
];

let gradeStatus = [
    { id: 1, key: 'unread', name: () => Locales.current.enums.gradeStatus.unread },
    { id: 2, key: 'alreadyReview', name: () => Locales.current.enums.gradeStatus.alreadyReview },
    { id: 3, key: 'alreadyFiled', name: () => Locales.current.enums.gradeStatus.alreadyFiled },
    { id: 4, key: 'waitReview', name: () => Locales.current.enums.gradeStatus.waitReview },
    { id: 5, key: 'alreadyRead', name: () => Locales.current.enums.gradeStatus.alreadyRead },
];

let qualityCheckDetailTypes = [
    { id: 1, key: 'review', name: () => Locales.current.enums.qualityCheckDetailTypes.review },
    {
        id: 2,
        key: 'spotCheck',
        name: () => Locales.current.enums.qualityCheckDetailTypes.spotCheck,
    },
];

let sampleAndReviewStatus = [
    { id: 0, key: 'init', name: () => Locales.current.enums.sampleAndReviewStatus.init },
    { id: 1, key: 'unread', name: () => Locales.current.enums.sampleAndReviewStatus.unread },
    {
        id: 2,
        key: 'alreadyRead',
        name: () => Locales.current.enums.sampleAndReviewStatus.alreadyRead,
    },
    {
        id: 3,
        key: 'complaining',
        name: () => Locales.current.enums.sampleAndReviewStatus.complaining,
    },
    { id: 4, key: 'reviewed', name: () => Locales.current.enums.sampleAndReviewStatus.reviewed },
    { id: 5, key: 'sampling', name: () => Locales.current.enums.sampleAndReviewStatus.sampling },
    {
        id: 6,
        key: 'samplingCompleted',
        name: () => Locales.current.enums.sampleAndReviewStatus.samplingCompleted,
    },
    { id: 7, key: 'complainCanceled', name: () => /* 申诉取消 */UdeskLocales['current'].enums.qualityChecingTask.appealCanceled },
    { id: 8, key: 'complainExpired', name: () => /* 申诉过期 */UdeskLocales['current'].enums.qualityChecingTask.complaintExpired },
];

let recheckStatus = [
    { id: 1, key: 'waitForExecution' },
    { id: 2, key: 'inExecution' },
    { id: 3, key: 'success' },
    { id: 4, key: 'failed' },
];

let reInspectionTypes = [
    { id: 1, key: 'recalcScore', name: () => Locales.current.enums.reInspectionType.recalcScore },
    { id: 2, key: 'recheckPoint', name: () => Locales.current.enums.reInspectionType.recheckPoint },
];

export default {
    visibleRangeTypes,
    samplingRateTypes,
    taskRemindTypes,
    samplingRangeDayTypes,
    samplingRangeWeekTypes,
    samplingRuleTypes,
    taskStatusTypes,
    samplingColumnCaptions,
    workFlowTypes,
    fieldCategories,
    callTypes,
    callResults,
    defaultSurveys,
    queueStates,
    callHangers,
    sampleTypes,
    defaultFields,
    conditionFieldCategories,
    columnFieldCategories,
    taskListDefaultFields,
    evaluationStates,
    workBenchInfoTypes,
    evaluationEventStates,
    formItemTypes,
    gradeStatus,
    qualityCheckDetailTypes,
    sampleAndReviewStatus,
    recheckStatus,
    reInspectionTypes,
};
