import React from 'react';
import Udesk from 'Udesk';
import { FormBuilder, InputNumber } from 'udesk-ui';
import {
    getReviewIntelligentPartnerNodeKnowledgeConfig,
    postReviewIntelligentPartnerNodeKnowledgeConfig,
} from 'src/api/review/intelligentPartner/node/knowledgeConfig';
import { putReviewIntelligentPartnerNodeKnowledgeConfigById } from 'src/api/review/intelligentPartner/node/knowledgeConfig/{id}';
import UdeskLocales from 'UdeskLocales';

const fields = (props) => [
    {
        label: /* 知识库问答 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.knowledgeNode.knowledgeBaseQA,
        type: 'KnowledgeTreeSelect',
        name: 'knowledgeIds',
    },
    {
        label: /* 问答方式 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.knowledgeNode.qAMethod,
        type: 'RadioGroup',
        name: 'questionType',
        props: {
            options: Udesk.enums.questionTypes.map((i) => ({
                label: i.name,
                value: i.id,
            })),
        },
    },
    {
        label: /* 问答数量 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.knowledgeNode.numberOfQuestionsAndAnswers,
        type: 'RadioGroup',
        name: 'questionNumType',
        addonAfter: (
            <FormBuilder.Item name="customQuestionNum">
                <InputNumber min={1} />
            </FormBuilder.Item>
        ),
        props: {
            options: Udesk.enums.questionNumTypes.map((i) => ({
                label: i.name,
                value: i.id,
            })),
        },
    },
    {
        label: /* 得分规则 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.knowledgeNode.scoringRules,
        type: 'InputNumber',
        name: 'correctScore',
        addonBefore: /* 任意一个问答正确，得 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.knowledgeNode.ifAnyQuestionAndAnswerIsCorrect,
        addonAfter: /* 分 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.knowledgeNode.branch,
    },
];

const request = ({ nodeId }) => {
    return getReviewIntelligentPartnerNodeKnowledgeConfig({
        params: {
            nodeId,
        },
    }).then((resp) => {
        const res: any = resp.data || {};
        if (res.knowledgeIds) {
            res.knowledgeIds = res.knowledgeIds?.split(',').map((i) => parseInt(i, 10)) ?? [];
        } else {
            res.knowledgeIds = [];
        }
        if (!res.questionNumType) {
            res.questionNumType = 1;
        }
        if (!res.questionType) {
            res.questionType = 1;
        }
        return res;
    });
};

const finish = ({ formInstanceCache, configId, nodeId }) => {
    return (values) => {
        const params = {
            ...values,
            knowledgeIds: values.knowledgeIds?.join(',') ?? '',
            nodeId,
        };
        if (params.questionNumType === 1) {
            params.customQuestionNum = null;
        }
        if (!configId) {
            return postReviewIntelligentPartnerNodeKnowledgeConfig(params, {
                successMsg: /* 保存成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.knowledgeNode.successfullySaved,
            });
        } else {
            return putReviewIntelligentPartnerNodeKnowledgeConfigById(params, {
                segments: {
                    id: configId,
                },
                successMsg: /* 保存成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.knowledgeNode.successfullySaved,
            });
        }
    };
};

export default {
    fields,
    request,
    finish,
};
