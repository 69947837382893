// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { DeleteSmartTagIgnoresByIdParam, BaseResponseVoid } from '../../types';

/**
 * deleteById
 *
 * @export
 * @tags smart-tag-ignore-controller
 * @link [DELETE] /smartTagIgnores/{id}
 * @param id
 */
export function deleteSmartTagIgnoresById(
    options: ApiOptions<DeleteSmartTagIgnoresByIdParam, never> & {
        segments: DeleteSmartTagIgnoresByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteSmartTagIgnoresByIdParam, never>('/smartTagIgnores/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteSmartTagIgnoresById',
    });
}

export const meta = [
    { tags: ['smart-tag-ignore-controller'], path: '/smartTagIgnores/{id}', method: 'delete' },
];
