// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetMallConfigsByIdParam,
    BaseResponseMallConfigFoundResponse,
    PutMallConfigsByIdParam,
    PutMallConfigsByIdBody,
    BaseResponseVoid,
    DeleteMallConfigsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags mall-config-controller
 * @link [GET] /mallConfigs/{id}
 * @param id
 */
export function getMallConfigsById(
    options: ApiOptions<GetMallConfigsByIdParam, never> & { segments: GetMallConfigsByIdParam }
): Promise<BaseResponseMallConfigFoundResponse> {
    return request<never, GetMallConfigsByIdParam, never>('/mallConfigs/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getMallConfigsById',
    });
}

/**
 * update
 *
 * @export
 * @tags mall-config-controller
 * @link [PUT] /mallConfigs/{id}
 * @param id
 */
export function putMallConfigsById(
    data: PutMallConfigsByIdBody,
    options: ApiOptions<PutMallConfigsByIdParam, never> & { segments: PutMallConfigsByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutMallConfigsByIdBody, PutMallConfigsByIdParam, never>('/mallConfigs/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putMallConfigsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags mall-config-controller
 * @link [DELETE] /mallConfigs/{id}
 * @param id
 */
export function deleteMallConfigsById(
    options: ApiOptions<DeleteMallConfigsByIdParam, never> & {
        segments: DeleteMallConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteMallConfigsByIdParam, never>('/mallConfigs/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteMallConfigsById',
    });
}

export const meta = [
    { tags: ['mall-config-controller'], path: '/mallConfigs/{id}', method: 'get' },
    { tags: ['mall-config-controller'], path: '/mallConfigs/{id}', method: 'put' },
    { tags: ['mall-config-controller'], path: '/mallConfigs/{id}', method: 'delete' },
];
