import React from 'react';
import Udesk from 'Udesk';
import DialogueManageDetailRoute from './route';
import './style.scss';

import { Layout, PageHeader } from 'udesk-ui';
import RecordDetail from '../../../components/record-detail';
const { Content } = Layout;

export default class DialogueManageDetailTemplate extends DialogueManageDetailRoute {
    render() {
        let { selectedDatum, selectedFilter, scrollFlag, manageRef } =
            this.privates;
        let { actions, locales } = this;
        return (
            <div className='layout-menu-pages'>
                <Layout style={{ height: '100%' }}>
                    <Content style={{ background: 'rgba(240,242,245,1)' }}>
                        <div className='udesk-qa-web-page'>
                            <PageHeader
                                className='udesk-qa-web-page-header'
                                title={/* 企微语音通话详情 */locales.pages.wechatQa.chartAudioRecord.detail.template.enterpriseMicroVoiceCallDetails}
                            />
                            <div
                                className='udesk-qa-web-page-body'
                                onScrollCapture={actions.handleScroll}
                                ref={manageRef}
                            >
                                <div className='udesk-qa-web-page-body-root'>
                                    <RecordDetail
                                        scrollFlag={scrollFlag}
                                        keywords={selectedFilter.keyword}
                                        selectedDatum={selectedDatum}
                                        onTransitionToTemplate={actions.transitionToTemplate}
                                        inspectDataSource={Udesk.enums.inspectDataSources.wechatRadio.id}
                                        onSeriesClicked={actions.transitionToSentimentAnalysis}
                                    />
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}
