// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetAiModelsQuery,
    BaseResponseListAiModelFoundResponse,
    PostAiModelsBody,
    BaseResponseAiModelFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags ai-model-controller
 * @link [GET] /aiModels
 */
export function getAiModels(): Promise<BaseResponseListAiModelFoundResponse>;
export function getAiModels(
    options: ApiOptions<never, GetAiModelsQuery>
): Promise<BaseResponseListAiModelFoundResponse>;
export function getAiModels(
    options?: ApiOptions<never, GetAiModelsQuery>
): Promise<BaseResponseListAiModelFoundResponse> {
    return request<never, never, GetAiModelsQuery>('/aiModels', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getAiModels',
    });
}

/**
 * save
 *
 * @export
 * @tags ai-model-controller
 * @link [POST] /aiModels
 */
export function postAiModels(data: PostAiModelsBody): Promise<BaseResponseAiModelFoundResponse>;
export function postAiModels(
    data: PostAiModelsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseAiModelFoundResponse>;
export function postAiModels(
    data: PostAiModelsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseAiModelFoundResponse> {
    return request<PostAiModelsBody, never, never>('/aiModels', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postAiModels',
    });
}

export const meta = [
    { tags: ['ai-model-controller'], path: '/aiModels', method: 'get' },
    { tags: ['ai-model-controller'], path: '/aiModels', method: 'post' },
];
