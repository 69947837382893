import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';
import Condition from './components/condition';
import cloneDeep from 'lodash-es/cloneDeep';
import { PageHeader, Form, Input, Button, DatePicker, Checkbox, Space, Popconfirm, Spin } from 'udesk-ui';
import { EnumSelect } from 'Component/common/enum-component';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { array2tree, isDefined, isEmpty, posterity } from 'src/util/core';
import { inspect } from 'src/util/core';
import { getInspectionTasksSourceBySource } from 'src/api/inspectionTasks/source/{source}';
import { addItem, isHidden } from './components/conditionItem';
import { postFieldDataSearchTemplate } from 'src/api/field/data/search-template';
import { getSystemModule } from 'Udesk/system/subApp';
import './index.scss'; 

const formatSmartTagsTree = (resp) => {
    return (resp.data ?? []).map(
        (category) => ({
            ...category,
            title: category.categoryName,
            key: `category-${category.id}`,
            children: Array.isArray(category.smartTagList)
                ? category.smartTagList.map(
                    (point) => ({
                        ...point,
                        key: `point-${point.id}`,
                        title: point.tagName,
                    })
                )
                : undefined,
        })
    );
};

const FormLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
};

const FormItemLayout={
    wrapperCol: {
        offset: FormLayout.labelCol.span,
        span: FormLayout.wrapperCol.span
    }
};

export const getIsShowInspectionTasksSelect = (isSales, conditionType) => {
    return !isSales && [
        Udesk.enums.clusteringRulesType.rating.id,
        Udesk.enums.clusteringRulesType.actualGrade.id,
        Udesk.enums.clusteringRulesType.group.id,
    ].includes(conditionType);
};

const next = (list, isSales) => {
    for(let item of list) {
        if (
            !(
                isDefined(item.logicalOperator) ||
                isDefined(item.condition) && 
                isHidden(item.condition.conditionScope, true, () => !!item.condition.conditionValue) && 
                inspect(getIsShowInspectionTasksSelect(isSales, item.condition.conditionType), () => isDefined(item.condition.inspectionTaskId), true) &&
                inspect(isSales, () => {
                    return item.condition.conditionValue.category.length > 0 || item.condition.conditionValue.point.length > 0;
                }, () => {
                    return true;
                })
            )
        ) {
            return false;
        }
        if (item.conditionList) {
            const result = next(item.conditionList, isSales);
            if (!result) {
                return result;
            }
        }
    }
    return true;
};

const goBack = () => window.history.back();

const formatDateTime = (date) => {
    if (typeof date === 'string') {
        return moment(date, "YYYY-MM-DD HH:mm:ss");
    }
    return date.format("YYYY-MM-DD HH:mm:ss");
};

const Confirm = React.memo((props: any) => {
    const {locales, onClick, children} = props;
    return (
        <Popconfirm
            title={/* 当前聚类已完成，重新编辑将产生一条新的聚类数据，不会覆盖已完成的聚类数据，请确认 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm}
            onConfirm={onClick}
            onCancel={(e: any) => e.stopPropagation()}
            okText={locales.labels.confirm}
            placement='bottom'
            cancelText={locales.labels.cancel}
        >
            {children}  
        </Popconfirm>
    );
});

const Template = React.memo((props: any) => {
    const {match} = props;
    const locales = UdeskLocales['current'];
    const [form] = Form.useForm();
    const [selectOptionData, setSelectOptionData] = useState({
        sales: [] as any[],
        inspectionTasks: [] as any[] | undefined,
        searchTemplate: [] as any[] | undefined,
    });
    const [isCompleted, setIsCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const request = useRequest();
    const [dataSource, setDataSource] = useState(Udesk.enums.clusteringAnalysisDataSource.sales.id);
    const [applicationScenarios, setApplicationScenarios] = useState(Udesk.enums.applicationScenarios.objection.id);
    const isSales = useMemo(() => dataSource === Udesk.enums.clusteringAnalysisDataSource.sales.id, [dataSource]);

    const formatValues = useCallback((formValue) => {
        formValue.autoClusterDaily = formValue.autoClusterDaily ? 1 : 0;
        formValue.dialogDate = 2;

        if (formValue.channelType) {
            if (formValue.channelType.includes(
                Udesk.enums.clusteringSessionTypesForSales.voiceRecording.id
            )) {
                formValue.channelType = [...formValue.channelType, 1].join();
            } else {
                formValue.channelType = formValue.channelType.join();
            }
        }
        if (formValue.sessionDate) {
            formValue.dialogStartTime = formatDateTime(formValue.sessionDate[0]);
            formValue.dialogEndTime = formatDateTime(formValue.sessionDate[1]);
        }
        delete formValue.sessionDate;

        if (formValue.ruleConfig) {
            const result = posterity(
                formValue.ruleConfig = cloneDeep(formValue.ruleConfig), 
                (node) => !!node.logicalOperator, 
                (item) => item.conditionList
            );
            
            result.filter(item => item.result).forEach(({data: item}) => {
                if (item.logicalOperator === Udesk.enums.clusteringRulesConditionType.and.id) {
                    item.logicalOperator = '&&';
                }
                if (item.logicalOperator === Udesk.enums.clusteringRulesConditionType.or.id) {
                    item.logicalOperator = '||';
                }
            });

            result.filter(item => !item.result).forEach(({data: item}, index) => {
                const condition = item.condition;
                const conditionValue = item.condition.conditionValue;

                delete item.condition.conditionValue;
                delete item.condition;

                inspect(!isSales, () => {
                    Object.assign(item, {
                        idCode: `R${index + 1}`, ...condition,
                    });

                    delete item.conditionScope;
                }, () => {
                    Object.assign(item, {
                        idCode: `R${index + 1}`, ...condition, ...conditionValue,
                    });
                });
            });

            formValue.ruleConfig = formValue.ruleConfig[0];
        }

        formValue.ruleConfig = formValue.ruleConfig ? JSON.stringify(formValue.ruleConfig) : undefined;
    }, [isSales]);

    const onFinish = useCallback((formValue) => {
        formatValues(formValue);
        let url = 'review/cluster';
        if (!isCompleted && match.params.id) {
            url = [url, formValue.id = +match.params.id].join('/');
        }
        request(url, formValue, formValue.id ? 'put' : 'post').then(
            resp => {
                Udesk.ui.notify.success(/* 保存成功 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.savingSucceeded);
                goBack();
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    },[
        locales,
        match.params.id,
        isCompleted,
        formatValues, 
        request
    ]);

    const submitHandle = useCallback(() => {
        form.submit();
    }, [form]);

    const startClusterHandle = useCallback(() => {
        form.validateFields().then((formValue) => {
            formatValues(formValue);
            if (match.params.id) {
                formValue.id = +match.params.id;
            }
            formValue.systemModule = getSystemModule();
            request('review/cluster/start', formValue, 'post').then(
                resp => {
                    Udesk.ui.notify.success(/* 聚类开始 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.clusteringStart);
                    goBack();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        });
    }, [
        match.params.id,
        locales,
        form, 
        formatValues, 
        request
    ]);

    useLiveEffect((success) => {
        const callback = resp => {
            const data = resp.data;
            const isSales = resp.data.dataSource === Udesk.enums.clusteringAnalysisDataSource.sales.id;
            data.autoClusterDaily = !!data.autoClusterDaily;
            if (data.ruleConfig) {
                const ruleConfig = JSON.parse(data.ruleConfig);

                if (ruleConfig) {
                    data.ruleConfig = [ruleConfig];
                } else {
                    data.ruleConfig = [];
                }
                const result = posterity(
                    data.ruleConfig, 
                    (node) => !!node.logicalOperator, 
                    (item) => item.conditionList
                );
                                
                result.filter(item => item.result).forEach(({data: item}) => {
                    if (item.logicalOperator === '&&') {
                        item.logicalOperator = Udesk.enums.clusteringRulesConditionType.and.id;
                    }
                    if (item.logicalOperator === '||') {
                        item.logicalOperator = Udesk.enums.clusteringRulesConditionType.or.id;
                    }
                });
                    
                result.filter(item => !item.result).forEach(({data: item}, index) => {
                    const {
                        conditionType,
                        conditionScope,
                        category, 
                        point,
                        fieldConditionList,
                        inspectionTaskId,
                    } = item;
        
                    for(let key in item) {
                        delete item[key];
                    }
                    inspect(isSales, () => {
                        item.condition = {
                            conditionType,
                            conditionScope,
                            conditionValue: {category, point} 
                        };
                    }, () => {
                        item.condition = {
                            inspectionTaskId,
                            fieldConditionList,
                            conditionType,
                            conditionScope: fieldConditionList.operator,
                            conditionValue: fieldConditionList.value
                        };
                    });
                });
            }
            if (data.dialogStartTime && data.dialogEndTime) {
                data.sessionDate = [
                    formatDateTime(data.dialogStartTime),
                    formatDateTime(data.dialogEndTime),
                ];
            }
            if (data.channelType) {
                data.channelType = data.channelType.split(',').map(id => +id);
                if (data.channelType.includes(
                    Udesk.enums.clusteringSessionTypesForSales.voiceRecording.id
                )) {
                    data.channelType = data.channelType.filter(id => id > 1);
                }
            }
            setApplicationScenarios(data.scenario);
            setDataSource(data.dataSource);
            setIsCompleted(data.status === Udesk.enums.clusteringTypes.completed.id);
            form.setFieldsValue(data);
        };
        if (match.params.id) {
            setLoading(true);
            
            request(`review/cluster/${match.params.id}`, {}).then(
                resp => {
                    success(() => callback(resp));
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            ).finally(() => {
                setLoading(false);
            });
        }
    }, [
        match.params.id
    ]);

    useLiveEffect((success) => {
        inspect(isSales && isEmpty(selectOptionData.sales), () => {
            Promise.all([
                request('smartTags/categoryWithTags/REVIEW_CALL', {}),
                request('subjectMarkFiles', {}),
                request('smartTags/categoryWithTags/REVIEW_KEY_EVENT', {}),
                request('smartTags/categoryWithTags/CUSTOMER_TAG', {}),
            ]).then(result => {
                success(() => {
                    setSelectOptionData(data => {
                        return {
                            ...data, 
                            sales: result.map((resp, index) => {
                                if (index === 1) {
                                    return array2tree(result[1].data.map(
                                        item => ({
                                            id: `category-${item.id}`,
                                            key: `category-${item.id}`,
                                            title: item.name,
                                            parentId: item.parentId
                                        })
                                    ));
                                }
                                return formatSmartTagsTree(resp);
                            })
                        };
                    });
                });
            });
        });
    }, []);
    
    useEffect(() => {
        inspect(!isSales && isEmpty(selectOptionData.inspectionTasks), () => {
            getInspectionTasksSourceBySource({
                segments: {
                    source: 0
                }
            }).then((resp) => {
                setSelectOptionData((data) => {
                    return {
                        ...data,
                        inspectionTasks: resp.data?.map(item => ({
                            key: item.id,
                            value: item.id,
                            label: item.name,
                        })),
                    };
                });
            });
        });
    }, [isSales, selectOptionData.inspectionTasks]);

    useEffect(() => {
        inspect(!isSales && isEmpty(selectOptionData.searchTemplate), () => {
            postFieldDataSearchTemplate({
                sourceList: [1],
                typeList: [1, 2],
            }).then((resp) => {
                const searchTemplate: any = {};
                resp.data?.[0].typeList?.forEach(item => {
                    addItem(item.fieldList, searchTemplate);
                });
                setSelectOptionData((data) => {
                    return {
                        ...data, searchTemplate,
                    };
                });
            });
        });
    }, [isSales, selectOptionData.searchTemplate]);

    return (
        <div className='udesk-qa-web-page'>
            <PageHeader
                className='udesk-qa-web-page-header'
                title={`${match.params.id ? /* 编辑 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.edit : /* 新建 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.newlyBuild}${/* 聚类 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.clustering}`}
                onBack={goBack}
            />
            <div className='udesk-qa-web-page-body'>
                <Spin wrapperClassName="udesk-qa-web-page-body-root clustering-analysis-add" spinning={loading}>
                    <Form
                        {...{
                            name: 'clustering-analysis-add',
                            form,
                            onFinish,
                            ...FormLayout
                        }}>
                        <Form.Item
                            label={/* 应用场景 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.applicationScenario}
                            name="scenario"
                            rules={[
                                { required: true, message: /* 请选择应用场景 */UdeskLocales['current'].pages.semanticIntelligence.clusteringAnalysis.add.index.pleaseSelectAnApplicationScenario },
                            ]}
                        >
                            <EnumSelect 
                                width={200} 
                                enumKey='applicationScenarios'
                                onChangeAfter={value => {
                                    setApplicationScenarios(value);
                                }}/>
                        </Form.Item>
                        {
                            inspect(applicationScenarios === Udesk.enums.applicationScenarios.objection.id, () => (
                                <Form.Item
                                    label={/* 聚类名称 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.clusterName}
                                    name="name"
                                    rules={[
                                        { required: true, message: /* 请输入名称 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.pleaseEnterAName },
                                        {
                                            pattern: /^.{1,40}$/,
                                            message: /* 名称长度40字符内 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.withinCharactersOfTheName
                                        }
                                    ]}
                                >
                                    <Input style={{width: 300}} />
                                </Form.Item>
                            ), () => (
                                <Form.Item
                                    label={/* 关键词 */UdeskLocales['current'].pages.semanticIntelligence.clusteringAnalysis.add.index.keyWord}
                                    name="keyword"
                                    rules={[
                                        { required: true, message: /* 请输入关键词 */UdeskLocales['current'].pages.semanticIntelligence.clusteringAnalysis.add.index.pleaseEnterKeywords },
                                        {
                                            pattern: /^.{1,40}$/,
                                            message: /* 关键词长度40字符内 */UdeskLocales['current'].pages.semanticIntelligence.clusteringAnalysis.add.index.keywordLengthWithinCharacters
                                        }
                                    ]}
                                >
                                    <Input style={{width: 300}} />
                                </Form.Item>
                            ))
                        }
                        <Form.Item
                            label={/* 数据来源 */UdeskLocales['current'].pages.semanticIntelligence.clusteringAnalysis.add.index.dataSources}
                            name="dataSource"
                            rules={[
                                { required: true, message: /* 请选择数据来源 */UdeskLocales['current'].pages.semanticIntelligence.clusteringAnalysis.add.index.pleaseSelectADataSource },
                            ]}
                        >
                            <EnumSelect 
                                width={200} 
                                enumKey='clusteringAnalysisDataSource'
                                onChangeAfter={value => {
                                    setDataSource(value);
                                    form.setFieldsValue({ ruleConfig: [], channelType: [] });
                                }}/>
                        </Form.Item>

                        <Form.Item
                            label={/* 说话人 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.speaker}
                            name="speaker"
                            rules={[
                                { required: true, message: /* 请选择说话人 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.pleaseSelectASpeaker },
                            ]}
                        >
                            <EnumSelect width={200} enumKey='speakerTypes'/>
                        </Form.Item>
                        
                        <Form.Item
                            label={/* 会话日期 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.sessionDate}
                            name="sessionDate"
                            rules={[
                                { required: true, message: /* 请输入会话日期 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.pleaseEnterTheSessionDate },
                                // {
                                //     validator: (_, value) => {
                                //         if (value) {
                                //             const step = value[0].clone().add(1, 'M').diff(value[0], 'days');
                                //             const isOk = value[1].diff(value[0], 'days') <= step;
                                //             if (!isOk) {
                                //                 return Promise.reject(/* 最多选择1个月的历史会话数据 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.selectUpToMonthOfHistoricalSessionData);
                                //             }
                                //         }
                                //         return Promise.resolve();
                                //     }
                                // },
                            ]}
                        >
                            <DatePicker.RangePicker />
                        </Form.Item>

                        <Form.Item
                            name="autoClusterDaily"
                            valuePropName="checked"
                            {...FormItemLayout}
                        >
                            <Checkbox>{/* 自动聚类每日新增数据 */}{locales.pages.semanticIntelligence.clusteringAnalysis.add.index.automaticallyClusterDailyNewData}</Checkbox>
                        </Form.Item>
      
                        <Form.Item
                            label={/* 会话渠道 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.conversationChannel}
                            name="channelType"
                            rules={[
                                { required: true, message: /* 请选择会话渠道 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.pleaseSelectAConversationChannel },
                            ]}
                        >
                            <EnumSelect mode="multiple" width={200} enumKey={inspect(isSales, 'clusteringSessionTypesForSales', 'clusteringSessionTypes')}/>
                        </Form.Item>

                        <Form.Item
                            label={/* 聚类规则 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.clusteringRules}
                            name="ruleConfig"
                            className="clustering-rule"
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value) {
                                            if (!next(value, isSales)) {
                                                return Promise.reject(new Error(/* 聚类规则设置不完整 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.incompleteClusteringRuleSettings));
                                            }
                                        }
                                        return Promise.resolve();
                                    }
                                },
                            ]}
                        >
                            <Condition selectOptionData={selectOptionData} isSales={isSales} />
                        </Form.Item>

                        <Form.Item  {...FormItemLayout}>
                            <Space>
                                <Button htmlType="button" onClick={goBack}>
                                    {locales.labels.cancel}
                                </Button>
                                {
                                    isCompleted ? (
                                        <>
                                            <Confirm onClick={submitHandle} locales={locales}>
                                                <Button type="primary" htmlType="submit">{locales.labels.save}</Button>
                                            </Confirm> 
                                            <Confirm onClick={startClusterHandle} locales={locales}>
                                                <Button type="primary">{/* 开始聚类 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.startClustering}</Button>
                                            </Confirm> 
                                        </>
                                    ) : (
                                        <>
                                            <Button type="primary" htmlType="submit">{locales.labels.save}</Button>
                                            <Button type="primary" onClick={startClusterHandle}>{/* 开始聚类 */locales.pages.semanticIntelligence.clusteringAnalysis.add.index.startClustering}</Button>
                                        </>
                                    )
                                }                                
                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        </div>
        
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
