// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostPointTemplateImportBody, BaseResponseVoid } from '../../types';

/**
 * importSave
 *
 * @export
 * @tags 智能分析-规则模板
 * @link [POST] /pointTemplate/import
 */
export function postPointTemplateImport(
    data: PostPointTemplateImportBody
): Promise<BaseResponseVoid>;
export function postPointTemplateImport(
    data: PostPointTemplateImportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postPointTemplateImport(
    data: PostPointTemplateImportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostPointTemplateImportBody, never, never>('/pointTemplate/import', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postPointTemplateImport',
    });
}

export const meta = [
    { tags: ['智能分析-规则模板'], path: '/pointTemplate/import', method: 'post' },
];
