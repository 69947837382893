import Udesk from 'Udesk';
import SdkLoaderBase from 'Component/common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';

class WholeReportComponent extends SdkLoaderBase {
    privates = {
        renderContainerId: "tasks-reports-whole-report",
        pageTitle: UdeskLocales.current.components.tasks.subMenu.statisticsAndReports.wholeReport.text
    };
}

export default Udesk.react.udeskify(WholeReportComponent);