import React from 'react';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import LayoutMenu from 'Component/common/layout-menu';
import { Redirect } from 'react-router-dom';

class TasksManageEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: this.props.match.params,
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let { route, location, sdkOptions, match } = this.props;

        let { locales, privates } = this;

        let navLists = [];
        let group = {};
        // 根据当前用户权限，组装菜单列表
        const task = sdkOptions.props.task;
        const hasFeature = task ? task._hasFeature : null;
        let taskId = privates.model.taskId;
        
        if (hasFeature && hasFeature('task:template:root')) {
            group = {
                text: locales.components.tasks.subMenu.qualityCheckTemplate.collectText,
                children: [],
            };
            group.children.push({
                text: locales.components.tasks.subMenu.qualityCheckTemplate.basicInfo.text,
                pathName: 'informationCollectionIndex',
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes('/basic-info');
                },
            });
            group.children.push({
                text: locales.components.tasks.subMenu.qualityCheckTemplate.collectionRule.text,
                pathName: "informationCollectPointIndex",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/template/collection-point");
                }
            });
            group.children.push({
                text: locales.components.tasks.subMenu.qualityCheckTemplate.addData.text,
                pathName: "informationCollectDataAddAuto",
                pathParams: { taskId },
                isActive: (match, location) => {
                    return location.pathname.includes("/template/data-add");
                }
            });
            navLists.push(group);
        }
        if (hasFeature && hasFeature('task:work:root')) {
            group = {
                text: locales.components.tasks.subMenu.qualityCheckWorkbench.informationCollectText,
                children: [],
            };
            if (hasFeature && hasFeature('task:work:inpectionList:root')) {
                group.children.push({
                    text: locales.components.tasks.subMenu.qualityCheckWorkbench.qualityInspectionList.informationCollectText,
                    pathName: 'informationCollectList',
                    pathParams: { taskId },
                    isActive: (match, location) => {
                        return location.pathname.includes('/information-collect-list');
                    },
                });
            }
            navLists.push(group);
        }
        if (this.isRedirect(route, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].children[0].pathName,
                pathParams: match.params,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className='tasks-manage-entry'>
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={locales.components.pages.menuTitleList.informationCollectTasksManageTitle}
                    />
                </div>
            );
        }
    }
    model() {
        const { props } = this;
        let params = Object.assign(
            {},
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );

        let associateFields = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/field/task/' + params.taskId, props.sdkOptions);
            Udesk.ajax.get(url, { type: 'associate' }).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.screeningCondition
                            )
                    );
                    reject(reason);
                }
            );
        });

        //  任务的数据源
        let taskPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + params.taskId, props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                            )
                    );
                    reject(reason);
                }
            );
        });

        // 任务权限数据源
        let taskMenuPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/menu/' + params.taskId, props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                            )
                    );
                    reject(reason);
                }
            );
        });

        // 检查质检点版本
        let taskVersionPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                `/inspectionTasks/${params.taskId}/version`,
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                            )
                    );
                    reject(reason);
                }
            );
        });

        return {
            taskId: params.taskId,
            associateFields: associateFields,
            task: taskPromise,
            taskMenu: taskMenuPromise,
            taskVersion: taskVersionPromise,
        };
    }
    onModelResolved(model) {
        let task = model.task;
        let features = model.taskMenu;
        let featureCodeMappings = {};
        if (features) {
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                featureCodeMappings[feature.permission] = true;
            }
        }
        if (!Object.prototype.hasOwnProperty.call(task, '_hasFeature')) {
            Object.defineProperty(task, '_hasFeature', {
                value: function (featureCode) {
                    return Object.prototype.hasOwnProperty.call(featureCodeMappings, featureCode);
                },
                enumerable: false,
                configurable: false,
                writable: false,
            });
        }
        this.props.sdkOptions.props.biTaskId = parseInt(model.taskId, 10);
        this.props.sdkOptions.props.task = task;
        this.props.sdkOptions.props.basicInfoFields = model.associateFields;
    }
    componentWillUnmount() {
        // 清理掉相关数据，否则会污染其他页面
        this.props.sdkOptions.props.biTaskId = null;
        this.props.sdkOptions.props.task = null;
        this.props.sdkOptions.props.basicInfoFields = null;
    }
}

export default Udesk.react.udeskify(TasksManageEntry);
