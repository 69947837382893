import { useContext, useEffect, useState,createContext } from 'react';
// import { getIntelligentPartnerHomePageAdminTaskTrend } from 'src/api/intelligentPartner/homePage/admin/taskTrend';
// import { getIntelligentPartnerHomePageStudentTaskSummary } from 'src/api/intelligentPartner/homePage/student/taskSummary';
// import { getIntelligentPartnerHomePageStudentTaskTrend } from 'src/api/intelligentPartner/homePage/student/taskTrend';
// import { UserContext } from '..';
// import UdeskLocales from 'UdeskLocales';
import { useRequest } from 'src/util/hook';
import UdeskLocales from 'UdeskLocales';

const UserContext = createContext<[any, React.Dispatch<React.SetStateAction<any>>]>(
    [] as any
);

export const useSummary = (taskId,startTime, endTime, userSelectObj, echartsType) => {
    console.log('echartsType',echartsType);
    const request = useRequest();
    const [taskSummary] = useState<any>(null);
    const [taskSummaryEcharts, setTaskSummaryEcharts] = useState<any>(null);
    const [_params] = useContext(UserContext);

    useEffect(() => {
        const params: any = {
            organizationIds: userSelectObj.organizationIds,
            userIds: userSelectObj.userIds,
            endTime: endTime.split(' ')[0],
            startTime: startTime.split(' ')[0],
        };
        let requestUrl = '/intelligentPartnerTaskInfos/result/analysis/tendencyScore/'+taskId;
        console.log('paramsss',params);
        if (echartsType === 'byNumber') {
            requestUrl = '/intelligentPartnerTaskInfos/result/analysis/tendencyCount/'+taskId;
            // GET /intelligentPartner/homePage/student/taskSummary?startTime=2023-03-02&endTime=2023-03-05
            // getIntelligentPartnerHomePageStudentTaskSummary({
            //     params,
            // }).then((resp) => {
            //     setTaskSummary(resp.data ?? null);
            // });
        } else {
            // requestUrl = '/intelligentPartnerTaskInfos/result/analysis/tendencyCount/148'
            // params.organizationIds = _params?.organizationIds;
            // params.startTime = _params?.startTime;
            // params.endTime = _params?.endTime;
        }
        if (!params.startTime || !params.endTime) return;
        // GET /intelligentPartner/homePage/student/taskTrend?startTime=2023-03-02&endTime=2023-03-02
        request(requestUrl, params, 'post').then((resp) => {

            const avgScore: number[] = []; // 平均分
            const speechCraftAvgScore: number[] = []; // 话术平均得分
            const emotionAvgScore: number[] = []; // 情绪平均得分
            const speedAvgScore: number[] = []; // 表达平均得分
            const flowAvgScore	: number[] = []; // 流程平均得分	
            const practiceCostTime: number[] = []; // 累计时长

            // trainTime.push(tranToHour(t.practiceCostTime)); // 转成小时
            // practiceCostTime.push(tranToHour(t.practiceCostTime));
            // practiceAvgCostTime.push(tranToHour(t.practiceAvgCostTime));
            
            if (echartsType === 'byNumber') {
                const xAxis: string[] = []; // X轴数据
                
                const personNumber: number[] = []; // 人数


                const taskTrend = resp.data ?? [];
                taskTrend.forEach((t) => {
                    xAxis.push(t.stringCount!);

                    avgScore.push(t.avgScore ?? 0);
                    personNumber.push(t.personNumber ?? 0);
                    speechCraftAvgScore.push(t.speechCraftAvgScore ?? 0);
                    emotionAvgScore.push(t.emotionAvgScore ?? 0);
                    speedAvgScore.push(t.speedAvgScore ?? 0);
                    flowAvgScore.push(t.flowAvgScore ?? 0);
                    practiceCostTime.push(t.practiceCostTime ?? 0);

                    // totalPracticeTimes.push(t.totalPracticeTimes ?? 0);
                    // participateCommitRate.push(t.participateCommitRate ?? 0);
                    // participateExcellentRate.push(t.participateExcellentRate ?? 0);
                });
                setTaskSummaryEcharts(null);
                setTimeout(() => {
                    setTaskSummaryEcharts(
                        getNumberEchartOptions(
                            avgScore,
                            personNumber,
                            speechCraftAvgScore, 
                            emotionAvgScore,
                            speedAvgScore,
                            flowAvgScore,
                            practiceCostTime,
                            xAxis
                        )
                    );
                }, 1000);
                
            } else {
                const xAxis: string[] = []; // X轴数据

                const totalPracticeTimes: number[] = []; // 累计次数
                const participateCommitRate: number[] = []; // 参与达标率
                const participateExcellentRate: number[] = []; // 参与达优率


                const taskTrend = resp.data ?? [];
                taskTrend.forEach((t) => {
                    xAxis.push(t.datePoint!);

                    avgScore.push(t.avgScore ?? 0);
                    speechCraftAvgScore.push(t.speechCraftAvgScore ?? 0);
                    emotionAvgScore.push(t.emotionAvgScore ?? 0);
                    speedAvgScore.push(t.speedAvgScore ?? 0);
                    flowAvgScore.push(t.flowAvgScore ?? 0);
                    practiceCostTime.push(t.practiceCostTime ?? 0);

                    totalPracticeTimes.push(t.totalPracticeTimes ?? 0);
                    participateCommitRate.push(t.participateCommitRate ?? 0);
                    participateExcellentRate.push(t.participateExcellentRate ?? 0);
                });
                setTaskSummaryEcharts(
                    getTimeEchartOptions(
                        avgScore,speechCraftAvgScore, emotionAvgScore,
                        speedAvgScore,flowAvgScore,totalPracticeTimes,practiceCostTime,
                        participateCommitRate,participateExcellentRate,
                        xAxis
                    )
                );
            }
        });
    }, [
        startTime,
        endTime,
        userSelectObj,
        echartsType,
        _params?.organizationIds,
        _params?.startTime,
        _params?.endTime,
    ]);

    return {
        taskSummary,
        taskSummaryEcharts,
    };
};

// function tranToHour(seconds) {
//     return Number(((seconds ?? 0) / (60 * 60)).toFixed(2));
// }

function getTimeEchartOptions(
    avgScore, speechCraftAvgScore, emotionAvgScore,
    speedAvgScore,flowAvgScore,totalPracticeTimes,practiceCostTime,
    participateCommitRate,participateExcellentRate, xAxis) {

    const leftMaxValue = Math.max(calMax(avgScore), calMax(speechCraftAvgScore), calMax(emotionAvgScore),
    calMax(speedAvgScore),calMax(flowAvgScore),calMax(participateExcellentRate), calMax(participateCommitRate));

    const rightMaxValue = Math.max(calMax(totalPracticeTimes), secondToMin(calMax(practiceCostTime)));

    const interval_left = leftMaxValue / 5; //左轴间隔
    const interval_right = rightMaxValue / 5; //右轴间隔

    let series = [
        {
            name: /* 平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.averageScore,
            type: 'line',
            yAxisIndex: 0,
            data: avgScore,
            smooth: true,
        },
        {
            name: /* 话术平均得分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.averageScoreOfScript,
            type: 'line',
            yAxisIndex: 0,
            data: speechCraftAvgScore,
            smooth: true,
        },
        {
            name: /* 情绪平均得分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.averageEmotionalScore,
            type: 'line',
            yAxisIndex: 0,
            data: emotionAvgScore,
            smooth: true,
        },


        {
            name: /* 表达平均得分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.expressionAverageScore,
            type: 'line',
            yAxisIndex: 0,
            data: speedAvgScore,
            smooth: true,
        },
        {
            name: /* 流程平均得分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.processAverageScore,
            type: 'line',
            yAxisIndex: 0,
            data: flowAvgScore,
            smooth: true,
        },
        
        {
            name: /* 累计时长 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.accumulatedDuration,
            type: 'line',
            yAxisIndex: 1,
            // data: secondToMin(practiceCostTime),
            data: practiceCostTime.map(item => {
                return secondToMin(item);
            }),
            smooth: true,
        },

        {
            name: /* 累计次数 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.accumulatedTimes,
            type: 'line',
            yAxisIndex: 1,
            data: totalPracticeTimes,
            smooth: true,
        },
        {
            name: /* 参与达标率 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.participationComplianceRate,
            type: 'line',
            yAxisIndex: 0,
            data: participateCommitRate,
            smooth: true,
        },
        {
            name: /* 参与达优率 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.participationRateForAchievingExcellence,
            type: 'line',
            yAxisIndex: 0,
            data: participateExcellentRate,
            smooth: true,
        },
    ];
    console.log('opt:Time',series,
        leftMaxValue,
        interval_left,
        rightMaxValue,
        interval_right);
    const res = getEchartOptions(
        series,
        leftMaxValue,
        interval_left,
        rightMaxValue,
        interval_right,
        xAxis
    );

    res.yAxis[0].axisLabel.formatter = function (value, index) {
        return (
            // value + /* 人 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary.people
            value + /* 分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.branch
        );
    };
    res.yAxis[1].axisLabel.formatter = function (value, index) {
        return (
            Number(value).toFixed(2) + 'min'
        );
    };
    return res;
}
function getNumberEchartOptions(
    avgScore,
    personNumber,
    speechCraftAvgScore, 
    emotionAvgScore,
    speedAvgScore,
    flowAvgScore,
    practiceCostTime,
    xAxis
) {
    const formPracticeCostTime = practiceCostTime.map((item)=> secondToMin(item));
    const unit = /* 分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.branch;
    const leftMaxValue = Math.max(
        calMax(personNumber)
    );
    const rightMaxValue = Math.max(calMax(avgScore), calMax(speechCraftAvgScore), calMax(emotionAvgScore),
    calMax(speedAvgScore),calMax(flowAvgScore));

    const interval_left = leftMaxValue / 5; //左轴间隔
    const interval_right = rightMaxValue / 5 ; //右轴间隔

    let series = [
        {
            name: /* 人数 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.numberOfPeople,
            type: 'line',
            yAxisIndex: 0,
            data: personNumber,
            smooth: true,
        },
        {
            name: /* 平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.averageScore,
            type: 'line',
            yAxisIndex: 1,
            data: avgScore,
            smooth: true,
        },
        {
            name: /* 话术平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.averageScoreOfScript,
            type: 'line',
            yAxisIndex: 1,
            data: speechCraftAvgScore,
            smooth: true,
        },
        {
            name: /* 流程平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.processAverageScore,
            type: 'line',
            yAxisIndex: 1,
            data: flowAvgScore,
            smooth: true,
        },
        {
            name: /* 情绪平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.averageEmotionalScore,
            type: 'line',
            yAxisIndex: 1,
            data: emotionAvgScore,
            smooth: true,
        },
        {
            name: /* 表达平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.averageScoreOfExpression,
            type: 'line',
            yAxisIndex: 1,
            data: speedAvgScore,
            smooth: true,
        },
        {
            name: /* 累积时长 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.accumulatedDuration,
            type: 'line',
            yAxisIndex: 0,
            data: formPracticeCostTime,
            smooth: true,
        },
    ];
    console.log('opt',
        series,
        leftMaxValue,
        interval_left,
        rightMaxValue,
        interval_right,
        xAxis,
        unit);
    const res =  getNumberEchartOpt(
        series,
        leftMaxValue,
        interval_left,
        rightMaxValue,
        interval_right,
        xAxis,
        unit
    );
    res.yAxis[0].axisLabel.formatter = function (value, index) {
        return (
            // value + /* 人 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary.people
            value + /* 人 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.dataOverview.hook.useSummary.people
        );
    };
    console.log('res',res);
    return res;
}

function getEchartOptions(series, maxLeft, interval_left, maxRight, interval_right, xAxis, unit = 'min') {
    console.log('series',series);
    const option = {
        backgroundColor: '#F5F8FE',
        color: [
            "#5B8FF9",
            "#5AD8A6",
            "#F6BD16",
            "#E86452",
            "#6DC8EC",
            "#945FB9",
            "#FF9845",
            "#1E9493",
            "#FF99C3",
            "#CDDDFD",
            "#CDF3E4",
            "#FCEBB9",
            "#F8D0CB",
            "#D3EEF9",
            "#DECFEA",
            "#FFE0C7",
            "#BBDEDE",
            "#FFE0ED",
        ],
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            top: 24,
            data: series.map((s) => s.name),
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
        },
        yAxis: [
            {
                type: 'value',
                min: 0,
                max: maxLeft,
                splitNumber: 5,
                interval: interval_left,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return (
                            value + ' '
                        );
                    },
                },
            },
            {
                type: 'value',
                min: 0,
                max: maxRight,
                splitNumber: 5,
                interval: interval_right,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return value + unit;
                    },
                },
            },
        ],
        series,
    };

    return option;
}
function getNumberEchartOpt(series, maxLeft, interval_left, maxRight, interval_right, xAxis, unit = 'h') {
    console.log('series',series);
    const option = {
        backgroundColor: '#F5F8FE',
        color: [
            "#5B8FF9",
            "#5AD8A6",
            "#F6BD16",
            "#E86452",
            "#6DC8EC",
            "#945FB9",
            "#FF9845",
        ],
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            top: 24,
            data: series.map((s) => s.name),
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
        },
        yAxis: [
            {
                type: 'value',
                min: 0,
                max: maxLeft,
                splitNumber: 5,
                interval: interval_left,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return (
                            value + ' '
                        );
                    },
                },
            },
            {
                type: 'value',
                min: 0,
                max: maxRight,
                splitNumber: 5,
                interval: interval_right,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return value + unit;
                    },
                },
            },
        ],
        series,
    };

    return option;
}

function secondToMin (second) {
    return Number((second / 60 / 60).toFixed(2));
}

function calMax(arr) {
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
        // 求出一组数组中的最大值
        if (max < arr[i]) {
            max = arr[i];
        }
    }
    const maxint = Math.ceil(max / 10); // 向上取整
    const maxval = maxint * 10; // 最终设置的最大值
    return maxval; // 输出最大值
}
