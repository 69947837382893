import React from 'react';
import Udesk from 'Udesk';
import WaveAudioComponent from './component';
import { Divider } from 'udesk-ui';
import './style.scss';

export default class WaveAudioTemplate extends WaveAudioComponent {
    render() {
        let {
            locales
        } = this;
        let {
            rates,
            isShowEmotionButton
        } = this.props;
        let {
            isShowPlayButton,
            isShowRateOptions,
            duration,
            currentTime,
            playbackRate,
            volume,
            isShowVolumeRange
        } = this.state;
        let {
            limitTimeOfWave,
            noWave,
            noWaveCursorPosition,
        } = this.privates;

        return (
            <div className="wave-audio-component" ref={this.privates.waveAudioRef}>
                <div className="wave-audio-container">
                    <If condition={noWave}>
                        <Divider type="vertical" className="wave-audio-container-cursor" style={{ left: noWaveCursorPosition + "%", transform: "translateX(" + (noWaveCursorPosition > 90 ? "-1" : "1") + "px)" }} />
                        <Divider className="wave-audio-container-no-wave-message">{Udesk.utils.string.format(locales.components.common.waveAudio.noWaveMessage, limitTimeOfWave)}</Divider>
                    </If>
                </div>
                <div className="wave-audio-operation-panel">
                    <If condition={isShowPlayButton}>
                        <span className="wave-audio-button-box wave-audio-play-button" onClick={this.actions.play} title="play">
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-bofang"></i>
                        </span>
                    </If>
                    <If condition={!isShowPlayButton}>
                        <span className="wave-audio-button-box wave-audio-pause-button" onClick={this.actions.pause} title="pause">
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-icon--1"></i>
                        </span>
                    </If>
                    <span className="wave-audio-time-box pull-right">
                        {currentTime} / {duration}
                    </span>
                    <span className="wave-audio-button-box wave-audio-rate-button pull-right">
                        <span className="wave-audio-rate-selected-option" onClick={this.actions.showRateOptions}>X {playbackRate}</span>
                        <If condition={isShowRateOptions}>
                            <ul className="wave-audio-rate-options">
                                <For each="rate" index="index" of={rates}>
                                    <li className="wave-audio-rate-option" key={index} onClick={this.actions.changePlaybackRate.params(rate)}>
                                        {rate}
                                    </li>
                                </For>
                            </ul>
                        </If>
                    </span>
                    <span className="wave-audio-button-box wave-audio-volumn-button pull-right">
                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-yinliang" onClick={this.actions.showVolumeRange}></i>
                        <If condition={isShowVolumeRange}>
                            <span className="wave-audio-volumn-input-box">
                                <input type="range" id="wave-audio-volume" min="0" max="1" step="0.1" value={volume} onChange={this.actions.changeVolume} />
                            </span>
                        </If>
                    </span>
                    <If condition={isShowEmotionButton}>
                        <span className="wave-audio-button-box wave-audio-emotion-button pull-right" onClick={this.actions.showEmotion} title="Emotion Analysis">
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xindiantu"></i>
                        </span>
                    </If>
                    <span className="wave-audio-button-box wave-audio-download-button pull-right" onClick={this.actions.downloadVoice}>
                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-xiazai"></i>
                    </span>
                </div>
            </div>
        );
    }
}