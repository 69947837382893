// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    BaseResponseListLibraryFileFoundResponse,
    PostLibraryFilesBody,
    BaseResponseLibraryFileFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 案例库文件夹
 * @link [GET] /libraryFiles
 */
export function getLibraryFiles(): Promise<BaseResponseListLibraryFileFoundResponse>;
export function getLibraryFiles(
    options: ApiOptions<never, never>
): Promise<BaseResponseListLibraryFileFoundResponse>;
export function getLibraryFiles(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListLibraryFileFoundResponse> {
    return request<never, never, never>('/libraryFiles', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getLibraryFiles',
    });
}

/**
 * save
 *
 * @export
 * @tags 案例库文件夹
 * @link [POST] /libraryFiles
 */
export function postLibraryFiles(
    data: PostLibraryFilesBody
): Promise<BaseResponseLibraryFileFoundResponse>;
export function postLibraryFiles(
    data: PostLibraryFilesBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseLibraryFileFoundResponse>;
export function postLibraryFiles(
    data: PostLibraryFilesBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseLibraryFileFoundResponse> {
    return request<PostLibraryFilesBody, never, never>('/libraryFiles', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postLibraryFiles',
    });
}

export const meta = [
    { tags: ['案例库文件夹'], path: '/libraryFiles', method: 'get' },
    { tags: ['案例库文件夹'], path: '/libraryFiles', method: 'post' },
];
