import React from 'react';
import { SortableContainer as sortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Button, Space, Icon } from 'udesk-ui';

const DragHandle = SortableHandle(() => (
    <Button
        style={{ cursor: 'move' }}
        type='text'
        size='small'
        icon={<Icon type='MenuOutlined' antdIcon={true}></Icon>}
    ></Button>
));

const SortableItemConstructor = (dragable) => {
    if (!dragable)
        return (props) => {
            return props.children;
        };
    return SortableElement(({ children }) => (
        <div>
            <Space>
                <DragHandle />
                {children}
            </Space>
        </div>
    ));
};
const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

export { SortableItemConstructor, SortableContainer };
