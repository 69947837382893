import React from 'react';
import Udesk from 'Udesk';
import SmartWordsLibraryCommonListRoute from './route';
// import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import SmartWordsLibraryUploadModal from '../smartwords-library-upload-modal';
import { Button, Space, Alert } from 'udesk-ui';
import ExportButton from 'Component/common/export-button';
import AutoSizeTable from 'Component/common/auto-size-table';

export default class SmartWordsLibraryCommonListTemplate extends SmartWordsLibraryCommonListRoute {
    render() {

        let {
            locales,
            actions
        } = this;

        let {
            // columns,
            // sdkOptions,
            uploadModalTitle,
            boardName,
            navItemType,
            buttons,
            alertMessage,
        } = this.props;

        let {
            model,
            isShowUploadModal,
            pageNum,
            pageSize,
            total,
            // cacheKey,
            keywords,
            loading,
            columns,
        } = this.privates;

        let {
            smartWordsLibraryListData,
        } = model;

        return (
            <div className="smart-words-library-nav-item">
                {
                    Boolean(alertMessage) && <Alert style={{marginBottom: 8}} message={alertMessage} type="info" showIcon/>
                }
                <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:view")}>
                    <div className="nav-item-header">
                        <div className="nav-item-header-search">
                            <If condition={navItemType === Udesk.enums.smartWordsLibraryMenus.professionalWords.id}>
                                <div className="pull-left">
                                    <input type="text" className="semantic-tags-index-page-body-search-input form-control" defaultValue={keywords} placeholder={locales.labels.searchPlaceHolder} onKeyUp={actions.searchItems} onChange={actions.mutator('privates.keywords', e => e.target.value)} />
                                </div>
                            </If>
                        </div>
                        <Space>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:add") && buttons.includes('add')}>
                                <Button onClick={actions.openAddModal} type='primary'>
                                    {locales.labels.create}
                                </Button>
                            </If>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:import") && buttons.includes('import')}>
                                <Button onClick={actions.showUploadModal}>
                                    {locales.labels.batchingImport}
                                </Button>
                            </If>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:export") && buttons.includes('export')}>
                                <ExportButton disabled={loading} exportDataCount={total} hasFilterCondition={true} onClick={actions.export} />
                            </If>
                        </Space>
                    </div>

                    <div className="nav-item-content">
                        <AutoSizeTable
                            // enableChooseColumns={true}
                            // cacheKey={cacheKey}
                            dataSource={smartWordsLibraryListData}
                            columns={columns}
                            pagination={false}
                            subtractedHeight={alertMessage ? 126 + 40 + 8 : 126}
                        />

                        {/* <ReactPager
                            pageSize={pageSize}
                            total={total}
                            pageNum={pageNum}
                            languageCode={sdkOptions.props.language}
                            pageChanged={actions.onPageChanged}
                        /> */}
                        <UdeskPagination
                            current={pageNum}
                            pageSize={pageSize}
                            total={total}
                            onChange={actions.onPageChanged}
                        />
                    </div>
                </If>

                <SmartWordsLibraryUploadModal
                    navItemType={navItemType}
                    title={uploadModalTitle}
                    visible={isShowUploadModal}
                    headerClassName="udesk-qa-smart-words-library-modal-header"
                    onCancel={actions.closeUploadModal}
                    boardName={boardName}
                    makeListRefresh={actions.refresh}
                />
            </div>
        );
    }
}