import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'udesk-ui';
import CustomTooltip, { getPlacement, formatTime } from '../customer-tooltip';
import ColumnLeftTitle from '../columnLeftTitle';
import UdeskLocales from 'UdeskLocales';

export default (props) => {
    const {locales, bindModal, rowTotal} = props;
    const [isClosed, setIsClosed] = useState(false);

    const handlerToggle = useCallback(() => {
        setIsClosed(isClosed => !isClosed);
    }, []);

    const leftTitle = useMemo(() => {
        return (
            <ColumnLeftTitle 
                {...{
                    title: /* 客户动态 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row1.customerDynamics,
                    className: 'row-title-1',
                    content: (
                        <>
                            <li>{/* 共 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row1.common}<span className='num'>{rowTotal.customerDynamic}</span>{/* 条动态 */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row1.barDynamic}</li>
                            <li>
                                <Button type='link' onClick={() => bindModal?.open('2')}>
                                    {locales.labels.seeDetail}
                                </Button>
                            </li>
                        </>
                    ),
                    isClosed,
                    handlerToggle
                }}
            />
        );
    }, [rowTotal.customerDynamic, locales.labels.seeDetail, isClosed, handlerToggle, bindModal]);

    const columnRender = useCallback((item, key, index, columns) => {
        const {customerDynamicList: list} = item;
        if (list) {
            if (isClosed) {
                return (
                    list.length
                );
            } else {
                return (
                    <CustomTooltip
                        placement={getPlacement(index, columns)}
                        title={[
                            /* 客户动态 */UdeskLocales['current'].pages.gong.saleClientCenter.components.table.components.row.row1.customerDynamics,
                            list.map(item => {
                                return (
                                    <div className='custom-tooltip-content-item'>
                                        <div className='time'>{formatTime(item.hitTime)}</div>
                                        <div>{item.content}</div>
                                    </div>
                                );
                            })
                        ]} 
                    >
                        <ul className='customer-dynamic-chart' key={key}>
                            {
                                list.map(item => <li><i/></li>)
                            }
                        </ul>
                    </CustomTooltip>
                );
            }
        }
    }, [isClosed]);

    return [leftTitle, columnRender];
};