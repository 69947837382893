export default {
  lang: {
    num: 'No.',
    total: 'Barra/total',
    strip: 'Artigo',
    more: 'Mais',
    chosen: 'Selecionadas',
    cancel: 'Cancelar seleção',
    bulkOperations: 'Operação em lote',
    import: 'Importação',
  },
};
