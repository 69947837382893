import React, { useEffect, useState, useMemo, useContext } from 'react';
import Udesk from 'Udesk';

import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import UdeskDatePicker from 'Component/common/udesk-date-picker';

import Locales from 'UdeskLocales';
import { Button, Tag, Tooltip } from 'udesk-ui';
import { Context } from '../wechat-qa-customer-label';
import { postWecomSmartTagsSearch } from 'src/api/wecomSmartTags/search';
import { putWecomSmartTagsByIdStatusByStatus } from 'src/api/wecomSmartTags/{id}/status/{status}';
import { deleteWecomSmartTagsById } from 'src/api/wecomSmartTags/{id}';

interface PageContext {
    pageNum: number;
    pageSize: number;
    savePageInfo: ({ pageNum, pageSize }: { pageNum: number; pageSize: number }) => void;
}
function ClientManage(props) {
    const locales = Locales['current'];

    const {
        pageNum: defaultPageNum,
        pageSize: defaultPageSize,
        savePageInfo,
    } = useContext<PageContext>(Context as any);
    const [loading, setLoading] = useState(false);
    // const [loadingDelete, setLoadingDelete] = useState(false);
    const [pageNum, setPageNum] = useState(defaultPageNum);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [refresh, needRefresh] = useState(1);
    // const [statusSwitchLoadingMap, setStatusSwitchLoadingMap] = useState({});
    const [requestParams, setRequestParams] = useState({});
    const statusChange = (item, index) => {
        // setStatusSwitchLoadingMap((prev) => ({ ...prev, [item.id]: true }));
        putWecomSmartTagsByIdStatusByStatus({
            segments: {
                id: item.id,
                status: dataSource[index].openStatus ? 0 : 1,
            },
        }).then(
            (resp) => {
                // setStatusSwitchLoadingMap((prev) => ({ ...prev, [item.id]: false }));
                let newData = [...dataSource];
                newData[index].openStatus = !newData[index].openStatus;
                setDataSource(newData);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const goToEdit = (item) => {
        savePageInfo?.({
            pageNum: pageNum,
            pageSize: pageSize,
        });
        let routeOptions = {
            history: props.history,
            routeName: 'wechatQaCustomerLabelDetail',
            pathParams: {
                id: item.id,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    const deleteItem = (item) => {
        deleteWecomSmartTagsById({
            segments: {
                id: item.id,
            },
            successMsg: locales.business.notifyMessage.deleteSuccess,
        }).then(() => {
            needRefresh((prev) => prev + 1);
        });
    };

    const columns = useMemo(() => {
        return [
            {
                key: 'ruleName',
                dataIndex: 'ruleName',
                title: locales.pages.wechatQa.smartCustomerTag.table.name,
                width: '10%',
                type: 'Input',
                props: {
                    placeholder: locales.labels.pleaseEnter,
                },
            },
            {
                key: 'tags',
                dataIndex: 'tags',
                title: locales.pages.wechatQa.smartCustomerTag.table.tags,
                render: (name, item, index) => {
                    let tags = item.tagList ?? [];
                    return (
                        <div>
                            {Array.isArray(tags) && tags.length > 0 ? (
                                <Tooltip
                                    placement="topLeft"
                                    title={
                                        <div>
                                            {tags.map((tag) => (
                                                <Tag
                                                    style={{
                                                        color: tag.tagColor ?? '#000',
                                                        marginBottom: 4,
                                                    }}
                                                    color={
                                                        tag.tagColor
                                                            ? tag.tagColor + '1a'
                                                            : undefined
                                                    }
                                                >
                                                    {tag.tagName}
                                                </Tag>
                                            ))}
                                        </div>
                                    }
                                    color="#fff"
                                >
                                    <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden' }}>
                                        {tags
                                            .filter((item, i) => i < 4)
                                            .map((tag) => (
                                                <Tag
                                                    style={{ color: tag.tagColor ?? '#000' }}
                                                    color={
                                                        tag.tagColor
                                                            ? tag.tagColor + '1a'
                                                            : undefined
                                                    }
                                                >
                                                    {tag.tagName}
                                                </Tag>
                                            ))}
                                        {tags.length > 4 && '...'}
                                    </div>
                                </Tooltip>
                            ) : (
                                '-'
                            )}
                        </div>
                    );
                },
            },
            {
                key: 'tagRefCnt',
                dataIndex: 'tagRefCnt',
                title: locales.pages.wechatQa.setting.wechatQaCustomerLabel.template
                    .numberOfCustomerTags,
                width: '10%',
            },
            {
                key: 'openStatus',
                dataIndex: 'openStatus',
                title: locales.pages.wechatQa.smartCustomerTag.table.status,
                width: '10%',
                renderType: 'switch',
                onSwitchChange: statusChange,
            },
            {
                key: 'createUserUsername',
                dataIndex: 'createUserUsername',
                title: locales.pages.wechatQa.smartCustomerTag.table.createUser,
                width: '10%',
            },
            {
                key: 'createTime',
                dataIndex: 'createTime',
                title: locales.pages.wechatQa.smartCustomerTag.table.createTime,
                width: '10%',
                type: 'RangePicker',
            },

            {
                key: 'actions',
                dataIndex: 'actions',
                title: locales.columns.tasks.manage.template.checkPoint.actions,
                width: '18%',
                align: 'center' as any,
                renderType: 'actions',
                onDelete: deleteItem,
                onEdit: goToEdit,
            },
        ];
    }, [dataSource]);

    const tableProps = useMemo(() => {
        return {
            columns,
            dataSource,
            subtractedHeight: 136,
        };
    }, [columns, dataSource]);
    const paginationProps = useMemo(() => {
        return {
            current: pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                setPageNum(page);
                setPageSize(pageSize);
            },
        };
    }, [pageNum, pageSize, total]);

    const createBtn = useMemo(() => {
        const a = () => {
            let routeOptions = {
                history: props.history,
                routeName: 'wechatQaCustomerLabelNew',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
            savePageInfo?.({
                pageNum,
                pageSize,
            });
        };
        return <Button onClick={a}>{locales.labels.create}</Button>;
    }, []);

    useEffect(() => {
        requestList(requestParams, { pageNum, pageSize });
    }, [pageNum, pageSize, refresh]);

    const requestList = (params, paging = {}) => {
        setLoading(true);
        const { createTime, ...others } = params;
        let requestParams: any = {
            ...others,
        };
        if (Array.isArray(createTime) && createTime.every(Boolean)) {
            requestParams.startTime = createTime[0];
            requestParams.endTime = createTime[1];
        }
        postWecomSmartTagsSearch(requestParams, {
            params: {
                pageNum,
                pageSize,
                ...paging,
            },
        }).then(
            (resp) => {
                setLoading(false);
                setDataSource(resp.data ?? []);
                if (resp.paging) {
                    const { pageNum = 1, pageSize = 20, total = 0 } = resp.paging;
                    setPageNum(pageNum);
                    setPageSize(pageSize);
                    setTotal(total);
                }
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const onSearch = (params) => {
        setRequestParams(params);
        requestList(params, { pageSize, pageNum: 1 });
    };

    return (
        <Page
            pageBodyClassName="real-time-analysis-page-index"
            title={locales.pages.wechatQa.smartCustomerTag.title}
            padding={true}
        >
            <CommonTablePage
                loading={loading}
                mainButton={createBtn}
                customComponents={{ RangePicker: UdeskDatePicker }}
                searchFormInitialValues={{}}
                onSearch={onSearch}
                onReset={onSearch}
            >
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
            </CommonTablePage>
        </Page>
    );
}

export default React.memo(ClientManage);
