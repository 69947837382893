import React, { FC } from 'react';
import { Input, Tag } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Tags = styled.div`
    padding: 5px;
`;

type TipInputProps = {
    value?: string;
    onChange?: (l: any) => void;
};

const TipInput: FC<TipInputProps> = (props) => {
    const onChooseTag = (l: string) => {
        props.onChange?.(l);
    };

    const Tips = [
        {
            label: /* 销售代表 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                .components.aiGenPPTDrawer.components.tipInput.index.salesRepresentative,
        },
        {
            label: /* 客服人员 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                .components.aiGenPPTDrawer.components.tipInput.index.customerServicePersonnel,
        },
        {
            label: /* 门店专柜 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                .components.aiGenPPTDrawer.components.tipInput.index.storeCounter,
        },
    ];

    return (
        <>
            <Input style={{ width: '100%' }} value={props.value} onChange={props.onChange} />
            <Tags>
                {Tips.map((t) => (
                    <Tag
                        title={t.label}
                        onClick={() => onChooseTag(t.label)}
                        style={{ cursor: 'pointer' }}
                    >
                        {t.label}
                    </Tag>
                ))}
            </Tags>
        </>
    );
};

export default TipInput;
