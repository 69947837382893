// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostIntelligentPartnerWordsCorrectBody,
    BaseResponseListstring,
} from '../../../types';

/**
 * 语音转写实时纠错
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [POST] /intelligentPartner/words/correct
 */
export function postIntelligentPartnerWordsCorrect(
    data: PostIntelligentPartnerWordsCorrectBody
): Promise<BaseResponseListstring>;
export function postIntelligentPartnerWordsCorrect(
    data: PostIntelligentPartnerWordsCorrectBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListstring>;
export function postIntelligentPartnerWordsCorrect(
    data: PostIntelligentPartnerWordsCorrectBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListstring> {
    return request<PostIntelligentPartnerWordsCorrectBody, never, never>(
        '/intelligentPartner/words/correct',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerWordsCorrect',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/words/correct',
        method: 'post',
    },
];
