import React, { useMemo, FC } from 'react';
import { Table, Input, Spin } from 'udesk-ui';
import { ListCell } from './components/ListCell';
import { AiDialogFinishResultResponse } from 'src/api/types';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div``;
const UpperWrapper = styled.div`
    margin-bottom: 16px;
`;
const BelowWrapper = styled.div``;
const BelowWrapperLabel = styled.div`
    margin-bottom: 5px;
`;

type AiGenScoreProps = {
    tableHeight?: number;
    data?: AiDialogFinishResultResponse;
    loading?: boolean;
};

export const AiGenScore: FC<AiGenScoreProps> = (props) => {
    const { tableHeight, data, loading = false } = props;

    const columns = useMemo(
        () => [
            {
                title: /* 沟通阶段 */ UdeskLocales['current'].components.coach.aiGenScore.index
                    .communicationStage,
                key: 'communicationPhase',
                dataIndex: 'communicationPhase',
                width: '20%',
                render: (text, record) => {
                    return <span>{`${text}: ${record.communicationTheme}`}</span>;
                },
            },
            {
                title: /* 沟通目标 */ UdeskLocales['current'].components.coach.aiGenScore.index
                    .communicationObjectives,
                key: 'communicationTargetList',
                dataIndex: 'communicationTargetList',
                width: '25%',
                render: (text) => {
                    return <ListCell content={text} showIsHit={false} />;
                },
            },
            {
                title: /* 评分标准 */ UdeskLocales['current'].components.coach.aiGenScore.index
                    .scoringCriteria,
                key: 'evaluationStandardResultList',
                dataIndex: 'evaluationStandardResultList',
                render: (text, record) => {
                    const content = (text || []).map((t) => {
                        return {
                            ...t,
                            name: t.evaluationStandard,
                        };
                    });
                    return <ListCell content={content} showIsHit={true} />;
                },
            },
        ],
        []
    );

    return (
        <Wrapper>
            <Spin spinning={loading}>
                <UpperWrapper>
                    <Table
                        columns={columns}
                        dataSource={data?.intelligentPartnerDialogNodeDfContentList || []}
                        pagination={false}
                        scroll={{ y: tableHeight || 300 }}
                    />
                </UpperWrapper>

                <BelowWrapper>
                    <BelowWrapperLabel>
                        {/* 提升建议 */}
                        {
                            UdeskLocales['current'].components.coach.aiGenScore.index
                                .suggestionsForImprovement
                        }
                    </BelowWrapperLabel>
                    <Input.TextArea
                        value={data?.suggest}
                        rows={4}
                        style={{ resize: 'none' }}
                        readOnly
                    />
                </BelowWrapper>
            </Spin>
        </Wrapper>
    );
};
