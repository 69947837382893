// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetDepartmentsSearchQuery,
    BaseResponseListDepartmentFoundResponse,
} from '../../types';

/**
 * search
 *
 * @export
 * @tags department-controller
 * @link [GET] /departments/search
 */
export function getDepartmentsSearch(
    options: ApiOptions<never, GetDepartmentsSearchQuery> & { params: GetDepartmentsSearchQuery }
): Promise<BaseResponseListDepartmentFoundResponse> {
    return request<never, never, GetDepartmentsSearchQuery>('/departments/search', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getDepartmentsSearch',
    });
}

export const meta = [
    { tags: ['department-controller'], path: '/departments/search', method: 'get' },
];
