export default {
  labels: {
    clear: "Trống rỗng",
    refresh: "Làm mới",
    nextStep: "Kế tiếp",
    prevStep: "Bước trước",
    closeDriver: "Thoát khỏi hướng dẫn",
    complete: "Hoàn thành",
    viewStatistics: "Xem số liệu thống kê",
    colon: "：",
    splitter: "/",
    remark: "Ghi chú",
    correction: "Sửa lỗi",
    save: "Lưu",
    reset: "Đặt lại",
    increase: "Thêm",
    preview: "Xem trước",
    unit: "Sọc",
    cancel: "Hủy bỏ",
    all: "Tất cả",
    searchPlaceHolder: "Nhập truy vấn từ khóa",
    greaterThan: "Lớn hơn",
    lessThan: "Nhỏ hơn",
    second: "Giây",
    description: "Mô tả",
    column: "Cột",
    pleaseChoice: "Vui lòng chọn",
    filter: "Sàng lọc",
    saveAndSubmit: "Lưu và gửi",
    submit: "Gửi",
    appeal: "Kháng nghị",
    review: "Xem lại",
    choose: "Lựa chọn",
    manage: "Quản lý",
    delete: "Xóa",
    add: "Thêm",
    create: "Mới xây dựng",
    edit: "Chỉnh sửa",
    name: "Tên",
    describe: "Mô tả",
    next: "Kế tiếp",
    percent: "%",
    export: "Xuất",
    exportAll: "Tất cả xuất",
    confirm: "Chắc chắn",
    back: "Trở về",
    versionUpdate:
      "Rất vui được thông báo cho bạn rằng trang web của chúng tôi vừa phát hành phiên bản mới! Để đảm bảo việc sử dụng bình thường của bạn, chúng tôi cần bạn lưu công việc hiện tại ngay lập tức và sau đó làm mới trang (nhấn nút làm mới của trình duyệt hoặc nhấn F5) để phiên bản mới của chúng tôi có hiệu lực. Cảm ơn bạn rất nhiều vì sự hợp tác của bạn!",
    more: "Thêm",
    selected: "Đã chọn",
    deduct: "Khuy",
    maxScore: "Nhiều nhất",
    scoreUnit: "Điểm",
    pointUnit: "Cái",
    comma: "，",
    view: "Xem",
    batchingImport: "Nhập",
    deleteContent:
      "Bạn có xác nhận rằng bạn muốn xóa? Sẽ không thể khôi phục sau khi xóa",
    import: "Nhập",
    uploadFile: "Tải lên tập tin",
    downloadFile: "Xuất tệp",
    upload: "Tải lên",
    click: "Bấm vào",
    downloadTemplate: "Tải mẫu",
    fillInTheForm: "Điền theo định dạng",
    UploadExcelfile: "Tải lên Excel để hoàn tất nhập hàng loạt",
    uploadIdentical:
      "Nếu tên API tồn tại, dữ liệu hiện có trong hệ thống sẽ được cập nhật khi nhập",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Áp dụng",
    leftBracket: "(",
    rightBracket: ")",
    question: "Vấn đề",
    answer: "Câu trả lời",
    content: "Nội dung",
    search: "Tìm kiếm",
    query: "Truy vấn",
    cancelEdit: "Hủy chỉnh sửa",
    noValue: "Không có dữ liệu",
    selectAll: "Chọn tất cả",
    changeAGroup: "Thay đổi một nhóm",
    arrow: " ---> ",
    saveReview: "Xem xét và nộp",
    saveSpotCheck: "Gửi kiểm tra ngẫu nhiên",
    rapidSampling: "Kiểm tra lấy mẫu nhanh",
    file: "Lưu trữ",
    download: "Tải về",
    lastPage: "Trang trước",
    nextPage: "Tiếp theo",
    batchActions: "Hoạt động hàng loạt",
    cancelDistribution: "Hủy phân phối",
    againDistribution: "Phân phối lại",
    deleteRecord: "Xóa bản ghi",
    oneOptionOpertor: "Thanh tra chất lượng tự chọn",
    allOptionOpertor: "Tất cả các thanh tra chất lượng",
    distributionInfoFirstOne: "Xác nhận",
    distributionInfoFirstTwo:
      "Bản ghi có được phân phối lại không? Vui lòng chọn mục tiêu phân phối",
    distributionInfoSecondOne: "Xác định rằng bạn muốn hủy",
    distributionInfoSecondTwo:
      "Thông tin người được chỉ định của bản ghi sẽ trở thành bản ghi xem xét chưa được chỉ định sau khi bị hủy.",
    distributionInfoSecondThree:
      "Thông tin người được chỉ định của hồ sơ sẽ trở thành hồ sơ kiểm tra ngẫu nhiên chưa được chỉ định sau khi bị hủy.",
    distributionInfoThirdOne: "Có",
    distributionInfoThirdTwo: "Hồ sơ đã được xem xét sẽ không bị xử lý.",
    distributionInfoThirdThree:
      "Hồ sơ đã được kiểm tra ngẫu nhiên sẽ không được xử lý.",
    exportError: "Xuất không thành công",
    exportSuccess: "Xuất thành công",
    deleteRecordInfo:
      "Xác nhận rằng bạn muốn xóa bản ghi {0}? Dữ liệu sẽ không được khôi phục sau khi xóa",
    pleaseSetFilter: "Vui lòng chọn lọc",
    noCountAfterFilter: "Không có dữ liệu xuất, vui lòng đặt lại tiêu chí lọc",
    redo: "Thực thi lại",
    batchMove: "Di chuyển hàng loạt",
    websiteName: "Kiểm tra chất lượng thông minh",
    salesEnable: "Trao quyền bán hàng",
    systemName: "Kiểm tra chất lượng thông minh",
    initSystemError:
      "Mạng bất thường hoặc trang không khởi tạo được, vui lòng liên hệ với bộ phận chăm sóc khách hàng",
    day: "Ngày",
    hour: "Giờ",
    minute: "Điểm",
    questionMark: "？",
    personalSettings: "Cài đặt cá nhân",
    logout: "Đăng xuất",
    ok: "Chắc chắn",
    saveAs: "Lưu dưới dạng",
    pleaseEnter: "Vui lòng nhập",
    executeMessage: {
      executeSuccess: "Thực hiện thành công bài báo {0}",
      executeError: "Điều {0} không thực hiện được",
    },
    field: "Trường",
    operator: "Toán tử",
    value: "Giá trị",
    placeholder: "Vui lòng chọn",
    pleaseSelect: "Vui lòng chọn",
    totalScore: "Tổng điểm",
    hyphen: "-",
    semicolon: "；",
    wfm: "Sắp xếp thông minh",
    user: "Người dùng",
    none: "Không có",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Lưu thành công! Nhân viên có liên quan có hiệu lực sau khi làm mới trang",
    saveAsName: "{0}(bản sao)",
    systemInformation: "Xem thông tin hệ thống",
    billingCenter: "Trung tâm thanh toán",
    warningMessage:
      "Hạn ngạch còn lại không đủ, vui lòng nạp tiền càng sớm càng tốt",
    fileTypeHolder:
      "Định dạng tệp không chính xác, vui lòng tải tệp định dạng {0} lên",
    dataNo: "Không có dữ liệu",
    move: "Di chuyển",
    study: "Bạn có điểm kiểm tra chất lượng tự học không được đánh dấu",
    studySuccess: "Đánh dấu thành công",
    studyError: "Đánh dấu không thành công",
    editType: "Chỉnh sửa nhãn phân loại",
    copyed: "Đã sao chép vào clipboard",
    clickToCopy: "Bấm để sao chép",
    addFilterConditions: "Thêm bộ lọc",
    filterConditions: "Điều kiện sàng lọc",
    messageTime: "Thời gian tin nhắn",
    messageType: "Loại tin nhắn",
    pushType: "Kênh tin nhắn",
    messageContent: "Nội dung tin nhắn",
    messageName: "Tên tin nhắn",
    messageStatus: "Trạng thái tin nhắn",
    configuration: "Thông tin cấu hình",
    sendTarget: "Gửi mục tiêu",
    guideUrl: "URL khởi động",
    noticeDetail: "Chi tiết tin nhắn",
    action: "Hoạt động",
    seeDetail: "Xem chi tiết",
    theDay: "{0} ngày",
    theLastDayOfMonth: "Ngày cuối cùng của tháng",
    ignore: "Bỏ qua",
    seeNow: "Xem ngay",
    exportConfirmText: {
      export: "Tổng cộng {0} dữ liệu, xác nhận xuất?",
      exportAll: "Xác nhận xuất toàn bộ nội dung?",
    },
    samrTagAdd: "Thêm nhãn",
    deletePopconfirmContent: "Bạn có xác nhận rằng bạn muốn xóa?",
    TheLastThirtyDays: "30 ngày qua",
    addNumber: "Cộng thêm",
    subNumber: "Giảm",
    addStrategy: "Thêm chiến lược",
    minutes: "Phút",
    status: "Bật trạng thái",
    pushConfig: "Cấu hình đẩy",
    category: "Phân loại",
    and: "Với",
    interval: "Khoảng thời gian",
    switchOn: "Mở",
    switchOff: "Đóng cửa",
    batchDel: "Xóa hàng loạt",
    exportTxt: "Xuất văn bản TXT",
    playSpeed: "Tốc độ gấp đôi",
    messageLogBtn: "Xem cuộc gọi/cuộc trò chuyện kích hoạt thông báo",
    dataLink: "Liên kết dữ liệu",
    yes: "Là",
    no: "Không.",
    sentenceUnit: "Câu",
    appealDetail: "Chi tiết hồ sơ kháng nghị",
    detail: "Chi tiết",
    default: "Mặc định",
    customer: "Khách hàng",
    agent: "Dịch vụ khách hàng",
    sort: "Sắp xếp",
    applyNow: "Ứng dụng",
    index: "Số thứ tự",
    allColumns: "Cột có thể thêm",
    selectedColumns: "Cột đã thêm",
    syn: "Đồng bộ hóa",
    saveAndNext: "Lưu và tạo tiếp theo",
    viewReference: "Xem trích dẫn",
    referenceDetail: "Chi tiết trích dẫn",
    goToQaList: "Kiểm tra chất lượng",
    goToCheck: "Đi lấy mẫu",
    businessCard: "Danh thiếp",
    weapp: "Chương trình nhỏ",
    redpacket: "Phong bao đỏ",
    externalRedpacket: "Phong bao đỏ trao đổi",
    sphfeed: "Số video",
    exportTaskCreated: "Xuất nhiệm vụ được tạo thành công",
    plzGoTo: "Vui lòng chuyển đến",
    importTaskCreated: "Nhiệm vụ nhập khẩu được tạo thành công",
    taskCreated: "Nhiệm vụ tạo thành công",
    reply: "Trả lời",
    replied: "Đã trả lời",
    release: "Phát hành",
    expandMore: "Mở rộng các câu trả lời khác {0}",
    comment: "Bình luận",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Vui lòng có ít nhất một {0}",
      canNotBeEmptyFormat: "{0} Không thể để trống",
      repeatErrorTipFormat: "{0} Có sự trùng lặp",
      getInformationFailedFormat:
        "Nhận thông tin {0} không thành công, vui lòng kiểm tra kết nối mạng hoặc thử lại sau",
      deleteSuccessFormat: "{0} Xóa thành công.",
      deleteFailedFormat: "{0} Xóa không thành công, vui lòng thử lại sau.",
      saveSuccessFormat: "{0} Lưu thành công.",
      saveFailedFormat: "{0} Lưu không thành công, vui lòng thử lại sau.",
      fileSuccessFormat: "{0} Lưu trữ thành công.",
      fileFailedFormat: "{0} Lưu trữ không thành công, vui lòng thử lại sau.",
      exportSuccessFormat: "{0} Xuất thành công",
      exportFailedFormat: "{0} Xuất không thành công, vui lòng thử lại sau.",
      submitSuccessFormat: "{0} Gửi thành công.",
      submitFailedFormat: "{0} Gửi không thành công, vui lòng thử lại sau.",
      confirmSuccessFormat: "{0} Xác nhận thành công.",
      confirmFailedFormat:
        "{0} Xác nhận không thành công, vui lòng thử lại sau.",
      pleaseUploadFile: "Vui lòng tải tệp lên trước!",
      importFileSuccess: "Nhập tệp thành công!",
      importFildFailed: "Nhập tệp không thành công!",
      deleteSuccess: "Xóa thành công!",
      deleteFailed: "Xóa không thành công, vui lòng thử lại sau!",
      illegalTitle: "Không đủ quyền",
      illegalMessage:
        "Bạn không có một phần hoặc tất cả các quyền cho bước tiếp theo, vui lòng liên hệ với quản trị viên để cấu hình, nhấp vào nút OK để thoát khởi động",
      illegalMessageCurrent:
        "Bạn không có một phần hoặc tất cả các quyền của bước hiện tại, vui lòng liên hệ với quản trị viên để cấu hình, nhấp vào nút OK để thoát hướng dẫn",
      skipMessage: "Nếu bạn muốn bỏ qua bước này tạm thời, hãy nhấp vào Bỏ qua",
      errorMsg500:
        "Yêu cầu máy chủ không thành công, vui lòng kiểm tra hoạt động của bạn hoặc làm mới và thử lại",
      errorMsg404: "Ooops... trang bạn đang tìm kiếm đã biến mất",
      errorMsg400:
        "Yêu cầu máy chủ không thành công, vui lòng kiểm tra dữ liệu của bạn và thử lại",
      pageOverFlow:
        "Hiện tại chỉ hỗ trợ 10.000 mục hiển thị, vui lòng đặt tiêu chí lọc để xem phần vượt quá",
      isHandAddSmartTag: "Là nhãn thông minh được thêm thủ công",
      defaultGradeMustAtLast: "Xếp hạng mặc định phải được đặt ở cuối",
      AutomaticAllocationInspectorsIsNull:
        "Vui lòng vào trang thông tin cơ bản để thêm thanh tra chất lượng",
      timePickerTips:
        "Để không ảnh hưởng đến việc sử dụng bình thường của bạn, 23:00 ~ 2:00 là thời gian bảo trì hệ thống và các nhiệm vụ theo lịch trình không được thực hiện.",
      batchDeleteInfo:
        "Đã xóa thành công mục {0} và mục {1} không thành công. Lý do thất bại là dữ liệu đã được sử dụng.",
      batchDeleteTips: "Kiểm tra tổng cộng {0} dữ liệu để xác nhận xóa?",
      correctionSuccess: "Sửa lỗi thành công",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError: "Thêm tối đa 10 biểu mẫu kiểm tra chất lượng",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "Tổng của các mục chỉ số {0} phải cộng lại thành 100",
          banProjectMaxErrorTip: "Thêm tối đa 2000 mục bị cấm",
          deadlyProjectMaxErrorTip: "Thêm tối đa 500 mục chết người",
        },
      },
    },
    info: {
      creatSuccess: "Tạo thành công",
      creatError: "Tạo thất bại",
      editSuccess: "Sửa đổi thành công",
      editError: "Sửa đổi không thành công",
      deleteSuccess: "Xóa thành công",
      addSuccess: "Thành công mới",
      deleteError: "Xóa không thành công",
      conditionError: "Toán tử lỗi biểu thức logic",
      customJudgeLogic: "Điều kiện tùy chỉnh kết hợp biểu thức logic lỗi",
      exportError: "Xuất không thành công",
      searchError: "Sàng lọc thất bại",
      loadError: "{0} Tải không thành công",
      saveSuccess: "Lưu thành công",
      saveError: "Lưu thất bại",
      fileSuccess: "Lưu trữ thành công",
      fileError: "Lưu trữ thất bại",
      cannotBeNull: "{0} Không thể để trống!",
      pleaseSearchAndSelect: "Vui lòng nhập từ khóa tìm kiếm",
      clickAndToggle: "Nhấp vào Chọn chuyển đổi",
      deleteWarning:
        "{0} Xóa sẽ không được khôi phục, bạn có chắc muốn tiếp tục không?",
      getOssAuthFailed:
        "Nhận ủy quyền OSS không thành công, vui lòng kiểm tra kết nối mạng hoặc thử lại sau.",
      getInsightAuthFailed:
        "Nhận ủy quyền Insight không thành công, vui lòng kiểm tra kết nối mạng hoặc thử lại sau.",
      pictureUploadFailed: "Tải lên hình ảnh không thành công",
      pictureUploadFailedSize:
        "Kích thước hình ảnh vượt quá giới hạn, tải lên hình ảnh không thành công",
      wecomTagsSyncInfo: "Cập nhật gần đây:{0}, đồng bộ hóa thông tin mục {1}",
      collectSuccess: "Bộ sưu tập thành công",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "Không lấy được dữ liệu báo cáo" },
      waveAudio: {
        noWaveMessage:
          "Để duy trì trải nghiệm của bạn, các bản ghi trên {0} phút tạm thời không hiển thị dạng sóng, bạn có thể phát trực tiếp",
      },
      customFilter: {
        del: "Xóa",
        edit: "Chỉnh sửa",
        new: "Tùy chỉnh sàng lọc",
        apply: "Ứng dụng",
        cancel: "Hủy bỏ",
        onlyApply: "Chỉ áp dụng",
        ohterSave: "Lưu dưới dạng",
        save: "Lưu",
        customFilter: "Sàng lọc chung",
        reset: "Đặt lại",
        holderKeyWord: "Vui lòng nhập từ khóa",
        customFilterRule: "Quy tắc logic lọc:",
        customFilterName: "Tên bộ lọc:",
        holderCustomFilterName: "Vui lòng nhập tên bộ lọc",
        manualConfiguration: "Cấu hình thủ công",
        holderCustomRule: "Vui lòng nhập điều kiện logic tùy chỉnh",
        example: "Ví dụ",
        dragConfiguration: "Cấu hình kéo",
        condition: "Điều kiện",
        operation: "Tính toán",
        infoDel: "Xóa nội dung ra khỏi khu vực",
        toConfigure: "Cấu hình",
        holderCustomFilterNameLength: "Chiều dài tối đa của tên bộ lọc là 10",
        customFilterNameNone: "Tên bộ lọc không được để trống",
        and: "Và",
        or: "Hoặc",
        not: "Không",
        delSuccess: "Xóa thành công",
        saveSuccess: "Lưu thành công",
        updateSuccess: "Sửa đổi thành công",
        customFilterCondition: "Điều kiện sàng lọc",
        conditionRule: "Quy tắc điều kiện",
        none: "Không có",
        filterPlaceholder: "Vui lòng nhập từ khóa",
      },
      labelTabs: {
        addThis: "Thêm vào đây",
        goThis: "Di chuyển đến đây",
        saveGo: "Lưu và đào tạo",
      },
      datePicker: {
        today: "Hôm nay",
        yesterday: "Hôm qua",
        theDayBeforeYesterday: "Ngày hôm kia",
        thisWeek: "Tuần này",
        lastWeek: "Tuần trước",
        thisMonth: "Tháng này",
        lastMonth: "Tháng trước",
        lastSevenDay: "7 ngày qua",
        lastThirtyDay: "30 ngày qua",
        thisQuarter: "Quý này",
        lastQuarter: "Quý trước",
        thisYear: "Năm nay",
        lastYear: "Năm ngoái",
        lastYearFromToday: "Năm gần đây nhất",
      },
      pagination: { page: "Tổng số {0} mục,{1} trang" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Tải tin nhắn sớm hơn",
          bottom: "Tải thêm tin nhắn",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Nhiệm vụ kiểm tra chất lượng",
        informationCollectTasksManageTitle: "Nhiệm vụ thu thập",
        customerServiceCenterTitle: "Trung tâm dịch vụ khách hàng",
        informationTitle: "Trung tâm dữ liệu",
        intelligentAnalysisTitle: "Phân tích thông minh",
        semanticIntelligence: "Ngữ nghĩa thông minh",
        trainingCenterTitle: "Trung tâm đào tạo",
        adminTitle: "Quản lý hệ thống",
      },
      qualityCheckingTask: {
        title: "Kiểm tra chất lượng cuộc gọi",
        index: {
          tipOne:
            "Kiểm tra chất lượng cuộc gọi được sử dụng để đánh giá chất lượng công việc của trung tâm cuộc gọi của nhân viên. Bạn có thể thiết lập biểu mẫu kiểm tra chất lượng phù hợp kết hợp với công việc kinh doanh của mình",
          tipTwo:
            "Các quy tắc thích hợp có thể được thiết lập cho các nhiệm vụ kiểm tra chất lượng, bao gồm sàng lọc, lấy mẫu, v. v. Sau khi thanh tra chất lượng chấm điểm, bộ phận chăm sóc khách hàng có thể xem kết quả kiểm tra chất lượng và kháng cáo thêm.",
          addTaskTitle: "Thêm nhiệm vụ kiểm tra chất lượng",
          deleteTipTitle: "Xóa nhiệm vụ kiểm tra chất lượng",
          deleteTipContent:
            "Không thể khôi phục sau khi nhiệm vụ kiểm tra chất lượng bị xóa. Bạn có xác nhận xóa không?",
          checkingTask: "Nhiệm vụ kiểm tra chất lượng",
          checkingTaskList: "Danh sách nhiệm vụ kiểm tra chất lượng",
          formList: "Danh sách biểu mẫu",
          viewHelpDocuments: "Xem tài liệu trợ giúp",
        },
        manage: {
          taskSetting: {
            title: "Cài đặt nhiệm vụ",
            form: "Biểu mẫu",
            basicInfo: "Thông tin cơ bản",
            visibleRange: "Phạm vi có thể nhìn thấy",
            visibleRangeTip:
              "Nhóm dịch vụ khách hàng và dịch vụ khách hàng đã chọn có thể xem nhiệm vụ kiểm tra chất lượng và cũng có thể kết hợp cài đặt quyền hạn cho nhiệm vụ này. Trưởng nhóm dịch vụ khách hàng, dịch vụ khách hàng, nhân viên kiểm tra chất lượng, v. v.",
            automaticSampling: "Lấy mẫu tự động",
            automaticSamplingTip:
              "Bạn có thể đặt quy tắc lấy mẫu tự động, để hệ thống sẽ thường xuyên thêm các tác vụ kiểm tra chất lượng theo quy tắc bạn đã đặt, chẳng hạn như trích xuất 5% cuộc gọi của tuần trước vào thứ Hai hàng tuần",
            samplingRate: "Tần suất lấy mẫu",
            samplingTime: "Thời gian lấy mẫu",
            samplingRange: "Phạm vi lấy mẫu",
            samplingRule: "Quy tắc lấy mẫu",
            fillterCondition: "Điều kiện sàng lọc",
            workflow: "Quy trình kiểm tra chất lượng",
            appealsTime: "Cho phép thời gian kháng cáo",
            appealsTimeTipOne: "Chấm điểm sau khi gửi",
            appealsTimeTipTwo: "Trong ngày",
            appealsTimeTipThree:
              'Sau thời gian kháng nghị cho phép, trạng thái kiểm tra chất lượng hồ sơ sẽ tự động được sửa đổi thành "Đã xác nhận"',
            auditTime: "Thời gian xử lý kiểm toán",
            auditTimeTipOne: "Sau khi nộp đơn khiếu nại",
            auditTimeTipTwo: "Trong ngày",
            auditTimeTipThree:
              'Sau thời gian xem xét và xử lý, hồ sơ sẽ duy trì điểm ban đầu và trạng thái kiểm tra chất lượng sẽ tự động được sửa đổi thành "đã xác nhận"',
            noAppealsMode: "Chế độ không khiếu nại",
            appealsMode: "Cho phép chế độ khiếu nại",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Dịch vụ khách hàng",
            customerServicesGroup: "Nhóm dịch vụ khách hàng",
            surveyList: "Danh sách đánh giá",
            customFields: "Trường tùy chỉnh",
            fieldConfig: "Cấu hình trường",
            from: "Từ",
            owner: "Người phụ trách",
            inspectorAndReviewerAuthority: "Cơ quan kiểm tra chất lượng",
            inspector: "Thanh tra chất lượng",
            reviewer: "Người kiểm toán",
            inspectorTip: "Thanh tra chất lượng có thể chấm điểm nhiệm vụ",
            reviewerTip: "Người đánh giá có thể chấm điểm nhiệm vụ",
            dataStatisticsAuthority: "Quyền thống kê dữ liệu",
          },
          samplingRecords: {
            title: "Lấy mẫu hồ sơ",
            index: {},
            manualSampling: {
              title: "Lấy mẫu",
              fillterCondition: "Điều kiện sàng lọc",
              samplingRule: "Quy tắc lấy mẫu",
              agent: "Dịch vụ khách hàng",
              type: "Loại",
              result: "Kết quả",
              evaluate: "Đánh giá",
              relayNumber: "Số tiếp sức",
              source: "Nguồn",
              task: "Nhiệm vụ",
              queueState: "Trạng thái xếp hàng",
              callHanger: "Bên treo cuộc gọi",
              queueTime: "Thời gian xếp hàng",
              talkTime: "Thời lượng cuộc gọi",
              bellTime: "Thời lượng chuông",
              recordCountTipOne: "Tổng cộng",
              recordCountTipTwo: "Bản ghi",
              customerServices: "Dịch vụ khách hàng",
              customerServicesGroup: "Nhóm dịch vụ khách hàng",
              surveyList: "Danh sách đánh giá",
              customFields: "Trường tùy chỉnh",
              fieldConfig: "Cấu hình trường",
              defaultFields: "Trường mặc định",
            },
          },
          taskList: {
            title: "Danh sách nhiệm vụ",
            index: {
              batchDelete: "Xóa hàng loạt",
              batchSubmit: "Gửi hàng loạt",
              batchConfirm: "Xác nhận hàng loạt",
              itemFields: "Chấm điểm trường dự án",
              categoryFields: "Trường phân loại cấp hai",
              forbidsAndDeadlies: "Các mục bị cấm và gây tử vong",
              scoreFieldList: "Danh sách trường mục điểm",
              customerName: "Tên khách hàng",
              cellphone: "Điện thoại",
              email: "Hộp thư",
              organization: "Công ty",
              description: "Mô tả",
              tags: "Nhãn",
              taskUserRole: "Vai trò người dùng nhiệm vụ hiện tại",
            },
            workBench: {
              title: "Chi tiết hồ sơ",
              checkForm: "Biểu mẫu kiểm tra chất lượng",
              waitEvaluate: "Đang chờ đánh giá",
              alreadyEvaluate: "Đã đánh giá",
              waitSubmit: "Đang chờ gửi",
              alreadySubmit: "Đã gửi",
              waitConfirm: "Để được xác nhận",
              alreadyConfirm: "Đã xác nhận",
              waitReview: "Đang chờ xem xét",
              alreadyReview: "Đã xem xét",
              alreadyDone: "Đã hoàn thành",
              comment: "Nhận xét đánh giá",
              totalScore: "Tổng điểm",
              score: "Điểm",
              remark: "Ghi chú",
              changeRecords: "Thay đổi hồ sơ",
              subject: "Chủ đề",
              secondLevelScore: "Điểm cấp hai",
              deadlyList: "Mục chết người",
              forbidList: "Mục bị cấm",
              addToCheckTask: "Thêm vào nhiệm vụ kiểm tra chất lượng",
              submitCheckRemark: "Đã gửi đánh giá kiểm tra chất lượng",
              changeCheckRemark: "Đã sửa đổi đánh giá kiểm tra chất lượng",
              doneCheckRemark: "Đã hoàn thành đánh giá kiểm tra chất lượng",
              submitCheckReview: "Đã gửi đánh giá kiểm tra chất lượng",
              submitCheckAppeal: "Đã gửi khiếu nại kiểm tra chất lượng",
              saveFlowSuccess: "Đánh giá thành công.",
              saveFlowFailed:
                "Đánh giá không thành công, vui lòng thử lại sau.",
              FormDetail: "Chi tiết biểu mẫu",
              callDetail: "Chi tiết ghi âm cuộc gọi",
              sampleCalllogDetail: "Chi tiết hồ sơ",
              appealReasonTip: "Vui lòng nhập lý do kháng cáo (bắt buộc)",
              appeal: "Kháng nghị",
              appealReason: "Lý do kháng cáo",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Biểu mẫu kiểm tra chất lượng",
        basicInformationTitle: "Thông tin cơ bản",
        indicatorsProjectTitle: "Mục chỉ số",
        specialProjectTitle: "Vật phẩm đặc biệt",
        totalScore: "Tổng điểm",
        indicatorsName: "Tên chỉ số",
        score: "Phân số",
        singleScore: "Điểm đơn",
        weight: "Trọng lượng",
        maxDeductScore: "Khấu trừ nhiều nhất",
        add: "Tăng",
        detail: "Chi tiết biểu mẫu",
        list: {
          title: "Biểu mẫu kiểm tra chất lượng",
          tipOne:
            "Định cấu hình biểu mẫu kiểm tra chất lượng là bước đầu tiên trong công tác kiểm tra chất lượng, bạn có thể kết hợp hoạt động kinh doanh của công ty để định cấu hình biểu mẫu kiểm tra chất lượng phù hợp nhất.",
          tipTwo:
            "Chúng tôi hỗ trợ nhiều biểu mẫu khác nhau như chấm điểm trực tiếp, chấm điểm trọng số và hệ thống khấu trừ. Chúng tôi khuyên bạn nên thêm vào hệ thống sau khi bạn thiết lập các quy tắc.",
        },
        basicInformation: {
          formTemplate: "Mẫu biểu mẫu",
          directScore: "Xếp hạng trực tiếp",
          weightedScore: "Điểm trọng lượng",
          deductScore: "Điểm trừ",
          directScoreSecondLevel: "Điểm trực tiếp-Cấp 2",
          weightedScoreSecondLevel: "Điểm trọng lượng-Cấp độ 2",
          deductScoreSecondLevel: "Điểm trừ-Cấp 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Mục bị cấm",
          banProjectTip:
            "Nó có thể được sử dụng để kiểm tra khi chấm điểm kiểm tra chất lượng. Nó có thể được coi là vi phạm hoặc bổ sung cho nhận xét mà không ảnh hưởng đến điểm số thực tế.",
          deadlyProject: "Mục chết người",
          deadlyProjectTip:
            "Mục chết người có thể được sử dụng để kiểm tra khi điểm kiểm tra chất lượng. Nếu mục chết người được kiểm tra, nhiệm vụ kiểm tra chất lượng sẽ được chấm trực tiếp 0 điểm",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Kiểm tra chất lượng cuộc gọi",
          entiretyReportTitle: "Báo cáo tổng thể",
          serviceGroupReportTitle: "Báo cáo nhóm dịch vụ khách hàng",
          serviceReportTitle: "Báo cáo dịch vụ khách hàng",
          entiretyReport: {
            pandect: "Tổng quan",
            contrast: "Tương phản",
            trend: "Xu hướng",
            analyze: "Phân tích",
            totalScore: "Tổng điểm",
            banProject: "Mục bị cấm",
            deadlyProject: "Mục chết người",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Danh sách kết quả kiểm tra chất lượng",
          taskSelectPlaceholder: "Chọn nhiệm vụ kiểm tra chất lượng",
          agentSelectPlaceholder: "Chọn dịch vụ khách hàng",
          refresh: "Làm mới",
          all: "Tất cả",
          unread: "Chưa đọc",
          inComplaint: "Đang kháng cáo",
          reviewed: "Đã xem xét",
          totalScore: "Nhận xét chung",
          detail: "Chi tiết kiểm tra chất lượng",
          audioText: "Văn bản ghi âm",
          callInfo: "Thông tin cuộc gọi",
          ticketInfo: "Thông tin đơn đặt hàng công việc",
          wechatInfo: "Thông tin phiên",
          dialogText: "Văn bản đối thoại",
          dialogInfo: "Thông tin đối thoại",
          customer: "Khách hàng",
          agent: "Dịch vụ khách hàng",
          none: "Không có dữ liệu kiểm tra chất lượng",
          appealSuccess: "Kháng nghị thành công!",
          appealFailed: "Kháng nghị không thành công, vui lòng thử lại sau!",
          pointListIsNull:
            "Điểm kiểm tra chất lượng khiếu nại không được để trống!",
          pleaseSelectPoint: "Vui lòng chọn điểm kiểm tra chất lượng!",
          unsupportedFormat: "Định dạng này không được hỗ trợ",
          shardingInformation: "Sau đây là các mảnh {0}",
          smartTags: "Nhãn thông minh",
          businessRecords: "Hồ sơ kinh doanh",
          selectedPointsWarning:
            "Vui lòng kiểm tra các mục kiểm tra chất lượng để khiếu nại, chỉ định các câu liên quan cho các mục khiếu nại, sau đó nhấp vào Gửi.",
          applyReason: "Lý do kháng cáo (tối đa 50 từ)",
          chatMessageTypes: {
            all: "Tất cả",
            text: "Văn bản",
            image: "Hình ảnh",
            agree: "Đồng ý với nội dung phiên",
            video: "Video",
            voice: "Giọng nói",
            file: "Tài liệu",
            emotion: "Biểu cảm",
            link: "Liên kết",
            chatrecord: "Lịch sử trò chuyện",
            mixed: "Tin nhắn hỗn hợp",
            default: "Tin nhắn không được hỗ trợ",
            deleteFriend: "Xóa bạn bè",
            redpacket: "Phong bao đỏ",
            card: "Danh thiếp",
            disAgree: "Không đồng ý với nội dung cuộc trò chuyện",
            weapp: "Chương trình nhỏ",
            externalRedpacket: "Phong bao đỏ trao đổi",
            meetingVoicevCall: "Tin nhắn cuộc gọi",
            uRL: "Trang web",
            replyTimeout: "Trả lời ngoài giờ",
            sphfeed: "Số video",
            chatRecordTitle: "Lịch sử trò chuyện",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Tổng điểm",
            actualScore: "Chấm điểm",
            actualGrade: "Xếp hạng",
            appeal: "Kháng nghị",
            remark: "Ghi chú nhân tạo",
            none: "Không có dữ liệu chi tiết kiểm tra chất lượng",
            pointEdit: "Phân loại chỉnh sửa điểm kiểm tra chất lượng",
          },
          changeList: {
            title: "Thay đổi hồ sơ",
            user: "Người dùng",
            point: "Điểm kiểm tra chất lượng",
          },
          relatedRecord: {
            title: "Hồ sơ liên quan",
            qualityList: "Nhiệm vụ kiểm tra chất lượng",
            none: "Không có",
            check: "Xem",
            loadMore: "Tải thêm",
          },
        },
      },
      grade: {
        title: "Xếp hạng kiểm tra chất lượng",
        subTitle:
          "Xếp hạng kiểm tra chất lượng được sử dụng để đánh giá các đối tượng kiểm tra chất lượng sau khi chấm điểm kiểm tra chất lượng hoàn thành. Ví dụ: nó có thể được cấu hình dưới 60 điểm và không đủ tiêu chuẩn, 60-85 điểm là tốt và trên 80 điểm là xuất sắc",
        list: {
          up: "Di chuyển lên",
          down: "Di chuyển xuống",
          del: "Xóa",
          case: "Tự động tham gia thư viện trường hợp",
          orderCase: "Thư viện trường hợp mặc định",
          info: "Xếp hạng mặc định không có điều kiện khớp và không thể bị xóa. Nếu nó không đáp ứng bất kỳ xếp hạng nào ở trên, bạn sẽ nhận được xếp hạng mặc định",
          add: "Thêm xếp hạng",
          save: "Lưu",
          close: "Hủy bỏ",
          max: "Có thể định cấu hình tối đa 20 xếp hạng kiểm tra chất lượng",
          name: "Chưa đặt tên",
          success: "Lưu thành công",
        },
      },
      qualityInspectionList: {
        title: "Danh sách kiểm tra chất lượng",
        fastFilter: "Vi phạm kiểm tra chất lượng",
        fastFilterTip:
          "Hệ thống trừ điểm là vi phạm và hệ thống cộng điểm là vi phạm",
        titleWechat: "Danh sách phân tích",
        titleCollect: "Danh sách thu thập",
        status: "Trạng thái phân tích",
        reQualityInspectionConfig: "Kiểm tra lại tùy chọn nhiệm vụ",
        pleaseSelectRecheckPoints:
          "Vui lòng chọn điểm kiểm tra chất lượng cần được hạch toán lại",
        beginReQualityInspection: "Bắt đầu thực hiện",
        cancelReQualityInspection: "Hủy nhiệm vụ",
        reQualityInspection: "Kiểm tra lại",
        reQualityInspectionWechat: "Phân tích lại",
        reQualityInspectionUnderWay: "Nhiệm vụ kiểm tra lại...",
        reQualityInspectionUnderWayTip:
          "Rời khỏi trang sau khi đóng cửa sổ sẽ không ảnh hưởng đến tiến độ tác vụ",
        reQualityInspectionDone: "Kiểm tra lại đã hoàn thành",
        reQualityInspectionDoneTip:
          "Kiểm tra lại đã được hoàn thành thành công, vui lòng nhấp vào nút xác nhận",
        endReQualityInspection: "Chấm dứt nhiệm vụ",
        alreadyDoneTip: "Đã hoàn thành bài báo {0}",
        totalTip: "Tổng số {0}",
        endReInspectionSuccess: "Nhiệm vụ kiểm tra lại đã bị chấm dứt",
        closeWindow: "Đóng cửa sổ",
        endReInspectionFailed:
          "Nhiệm vụ kiểm tra lại không thành công, vui lòng thử lại sau!",
        detail: {
          title: "Chi tiết kiểm tra chất lượng",
          wechatTitle: "Phân tích chi tiết",
          titleCollect: "Chi tiết bộ sưu tập",
          callIndex: "Cuộc gọi liên quan",
          textIndex: "Đối thoại liên quan",
          fileConfirm: "Xác nhận lưu trữ?",
          fileConfirmDesc:
            "Sau khi nộp hồ sơ sẽ không chỉnh sửa được điểm kiểm tra chất lượng, vui lòng xác nhận có nộp hồ sơ hay không",
        },
        sysCloumnsContal: "Quản lý trường cột hệ thống",
        personCloumnsContal: "Quản lý trường cột cá nhân",
      },
      spotCheckList: {
        title: "Danh sách kiểm tra lấy mẫu",
        onlyMyTip: "Chỉ cần kiểm tra lấy mẫu của tôi",
        plInspector: "Vui lòng chọn người kiểm tra ngẫu nhiên",
        allInspector: "Xem tất cả",
        seInspector: "Xem nhân viên kiểm tra ngẫu nhiên được chỉ định",
        myInspector: "Chỉ cần nhìn vào tôi",
        noInspector:
          "Kiểm tra nhân viên kiểm tra ngẫu nhiên chưa được chỉ định",
        detail: { title: "Chi tiết kiểm tra lấy mẫu" },
      },
      reviewList: {
        title: "Xem lại danh sách",
        onlyMyTip: "Chỉ cần xem đánh giá của tôi",
        plInspector: "Vui lòng chọn người đánh giá",
        allInspector: "Xem tất cả",
        seInspector: "Xem người đánh giá được chỉ định",
        myInspector: "Chỉ cần nhìn vào tôi",
        noInspector: "Xem người đánh giá chưa được chỉ định",
        detail: { title: "Xem lại chi tiết" },
      },
      caseBaseList: {
        title: "Thư viện trường hợp",
        del: "Xóa",
        delTitle: "Tất cả nội dung sẽ không được lưu sau khi xóa",
        cancel: "Hủy bỏ",
        actions: "Hoạt động",
        seeCaseBase: "Xem trường hợp",
        seeDetail: "Xem chi tiết",
        edit: "Chỉnh sửa",
        save: "Lưu",
        addCaseBase: "Thư viện trường hợp mới",
        export: "Xuất",
        ok: "Chắc chắn",
        tip: "Dữ liệu cuộc gọi và cuộc trò chuyện điển hình trong danh sách kiểm tra chất lượng có thể được lưu vào thư viện trường hợp để tạo điều kiện học tập và chia sẻ",
        detail: {
          choiceCaseBase: "Vui lòng chọn trường hợp",
          delCaseBase: "Xóa trường hợp",
          addCaseBase: "Thêm trường hợp",
          successed: "Lưu thành công",
          info: "Vui lòng lưu hoặc hủy nội dung đang được chỉnh sửa trước",
          caseType: "Vui lòng chọn thư viện trường hợp bạn muốn tham gia",
        },
      },
      testSetList: {
        title: "Bộ kiểm tra chất lượng",
        tip: "Dữ liệu kiểm tra ngẫu nhiên của cán bộ kiểm tra chất lượng sẽ được lưu trong bộ sưu tập kiểm tra chất lượng, thuận tiện cho việc tối ưu hóa điều kiện kiểm tra chất lượng và cấu hình quy tắc kiểm tra chất lượng đối với một số lỗi kiểm tra máy được so sánh sau khi kiểm tra ngẫu nhiên.",
        refresh: "Làm mới",
        reQualityInspectionUnderWay: "Đánh giá lại nhiệm vụ...",
        reQualityInspectionUnderWayTip:
          "Rời khỏi trang sau khi đóng cửa sổ sẽ không ảnh hưởng đến tiến độ tác vụ",
        reQualityInspectionDone: "Đánh giá lại đã hoàn tất",
        reQualityInspectionDoneTip:
          "Đánh giá lại đã được hoàn thành thành công, vui lòng nhấp vào nút xác nhận",
        endReQualityInspection: "Chấm dứt nhiệm vụ",
        alreadyDoneTip: "Đã hoàn thành bài báo {0}",
        totalTip: "Tổng số {0}",
        endReInspectionSuccess: "Nhiệm vụ đánh giá lại đã chấm dứt",
        closeWindow: "Đóng cửa sổ",
        endReInspectionFailed:
          "Đánh giá lại nhiệm vụ không thành công, vui lòng thử lại sau!",
        recheckPoint:
          "Tính toán lại điểm kiểm tra chất lượng và tỷ lệ chính xác",
        recheckPointTitle: "Đánh giá lại các tùy chọn",
        delete: {
          reQualityInspectionUnderWay: "Xóa hàng loạt nhiệm vụ...",
          reQualityInspectionUnderWayTip:
            "Rời khỏi trang sau khi đóng cửa sổ sẽ không ảnh hưởng đến tiến độ tác vụ",
          reQualityInspectionDone: "Nhiệm vụ xóa hàng loạt đã hoàn thành",
          reQualityInspectionDoneTip:
            "Đã xóa hàng loạt thành công, vui lòng nhấp vào nút xác nhận",
          endReQualityInspection: "Chấm dứt nhiệm vụ",
          alreadyDoneTip: "Đã hoàn thành bài báo {0}",
          totalTip: "Tổng số {0}",
          endReInspectionSuccess: "Nhiệm vụ xóa hàng loạt đã bị chấm dứt",
          closeWindow: "Đóng cửa sổ",
          endReInspectionFailed:
            "Nhiệm vụ xóa hàng loạt không thành công, vui lòng thử lại sau!",
        },
        reultList: {
          assessmentTime: "Thời gian đánh giá cuối cùng",
          assessmentAll: "Đánh giá tổng hợp",
          strip: "Sọc",
          spot: "Điểm",
          deviation: "Độ lệch",
          accuracyRate: "Tỷ lệ chính xác",
          info: "Không có kết quả đánh giá, vui lòng đánh giá",
          onlyDeviationInfo: "Chỉ xem bản ghi độ lệch",
          againAssessment: "Đánh giá lại",
          screen: "Sàng lọc",
          batchDelete: "Loại bỏ hàng loạt",
          matching: "Phù hợp",
          ok: "Là",
          noCancel: "Không.",
        },
      },
      smartWordsLibrary: {
        title: "Từ điển thông minh",
        tip1: "Từ đồng nghĩa: Được sử dụng để giảm khối lượng đào tạo của các thẻ ngữ nghĩa, các câu tương tự của các từ đồng nghĩa không cần đào tạo lặp lại",
        tip2: "Thuật ngữ chuyên môn: tối ưu hóa phiên âm và tối ưu hóa hiểu ngôn ngữ tự nhiên cho các danh từ thích hợp như tên công ty, tên sản phẩm và từ vựng ngành không chính xác để cải thiện độ chính xác nhận dạng và độ chính xác phân tích",
        tip3: "Các từ quan tâm: chú ý đến các từ bạn muốn chú ý trong phân tích đám mây từ để giúp AI phân tích các điểm nóng phù hợp với doanh nghiệp hiện tại",
        tip4: "Các từ bị chặn: Chặn các từ không muốn chú ý trong phân tích đám mây từ để giúp AI phân tích các điểm phát sóng phù hợp với hoạt động kinh doanh hiện tại",
        tip5: "Trọng số từ: được sử dụng để sửa lỗi và tối ưu hóa việc nhận dạng các thẻ ngữ nghĩa. Bằng cách sửa đổi trọng số ảnh hưởng của các từ trong câu, hiệu ứng nhận dạng ngữ nghĩa trong các tình huống cụ thể được tối ưu hóa theo nhu cầu",
        tip6: "Dữ liệu ngữ liệu: Nhập văn bản đoạn văn lớn cho các tình huống kinh doanh tương ứng của họ, được sử dụng để chuyển ngữ và sửa lỗi và đào tạo ASR, đồng thời cải thiện độ chính xác của chuyển ngữ ASR",
        upload: {
          uploadSynonymWords: "Nhập từ đồng nghĩa",
          uploadProfessionalWords: "Nhập thuật ngữ chuyên môn",
          uploadAttentionWords: "Nhập từ quan tâm",
          uploadIgnoreWords: "Nhập từ bỏ qua",
          uploadWordsWeight: "Nhập từ trọng lượng",
          uploadTypo: "Nhập bộ từ lỗi chính tả",
          uploadCorpusData: "Nhập dữ liệu ngữ liệu",
          uploadCorpusContent: "Nhập nội dung dữ liệu kho ngữ liệu",
          firstUploadStep: "1. Tải mẫu và điền vào mẫu theo định dạng",
          downloadTemplateMessage: "Tải mẫu:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Nhấp vào nút nhập để tải lên tệp EXCEl phù hợp với định dạng mẫu",
          secondUploadCorpusDataStep:
            "2. Nhấp vào nút nhập để tải lên tệp TXT phù hợp với định dạng mẫu",
          postUploadDataFail: "Không gửi dữ liệu tải lên",
          pleaseUploadTemplate: "Vui lòng tải lên tệp EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "Mẫu tệp chỉ có thể ở định dạng JSON hoặc xlsx",
          templateNeedTxt: "Mẫu tệp dữ liệu ngữ liệu chỉ có thể là tệp TXT",
          uploadSuccess: "Nhập thành công",
          uploadFailed: "Nhập không thành công",
          downloadError: "Tải xuống thất bại",
          downLoadTxtTitle: "Nội dung ngữ liệu. txt",
        },
        navTabNames: {
          synonymWords: "Từ đồng nghĩa",
          professionalWords: "Danh từ chuyên môn",
          attentionWords: "Từ quan tâm",
          ignoreWords: "Bỏ qua các từ",
          wordsWeight: "Trọng lượng từ",
          corpusData: "Dữ liệu ngữ liệu",
          typoLibrary: "Bộ sưu tập từ lỗi chính tả",
        },
        synonymWords: {
          createSynonymWords: "Từ đồng nghĩa mới",
          editSynonymWords: "Chỉnh sửa từ đồng nghĩa",
          addSynonymWords: "Thêm từ đồng nghĩa",
          pleaseEnterStandardWords: "Vui lòng nhập từ chuẩn",
          synonymWordsExists: "Từ đồng nghĩa đã tồn tại và không thể thêm",
          synonymWordsCanNotNull:
            "Các từ đồng nghĩa được thêm vào không được để trống",
          pleaseAddSynonymWords: "Vui lòng thêm từ đồng nghĩa",
          addSuccess: "Thành công mới",
          editSuccess: "Sửa đổi thành công",
          deleteSuccess: "Xóa thành công",
          deleteError: "Xóa không thành công",
          required: "*",
        },
        professionalWords: {
          create: "Danh từ chuyên môn mới",
          edit: "Chỉnh sửa danh từ chuyên nghiệp",
          addTargetWords: "Thêm từ thay thế",
          targetWordsExists: "Từ thay thế đã tồn tại và không thể thêm",
          targetWordsCanNotNull:
            "Từ thay thế được thêm vào không được để trống",
          pleaseAddTargetWords: "Vui lòng thêm từ thay thế",
          pleaseEnter: "Vui lòng nhập thuật ngữ chuyên môn",
          wordsExists: "Thuật ngữ chuyên môn đã tồn tại",
          createSuccess: "Thêm thành công",
          editSuccess: "Sửa đổi thành công",
          deleteSuccess: "Xóa thành công",
          deleteError: "Xóa không thành công",
          saveError: "Lưu thất bại",
          addTargetWordsMaxNumberError: "Từ thay thế có thể thêm tối đa 100",
          addHomophonyWordsMaxNumberError: "Bạn có thể thêm tối đa 100 từ chặn",
        },
        attentionWords: {
          create: "Từ chú ý mới",
          edit: "Chỉnh sửa từ theo dõi",
          pleaseEnter: "Vui lòng nhập từ quan tâm",
          wordsExists: "Từ quan tâm đã tồn tại",
          createSuccess: "Thêm thành công",
          editSuccess: "Sửa đổi thành công",
          deleteSuccess: "Xóa thành công",
          deleteError: "Xóa không thành công",
        },
        ignoreWords: {
          create: "Từ bỏ qua mới",
          edit: "Bỏ qua chỉnh sửa từ",
          pleaseEnter: "Vui lòng nhập từ bỏ qua",
          wordsExists: "Từ bỏ qua đã tồn tại",
          createSuccess: "Thêm thành công",
          editSuccess: "Sửa đổi thành công",
          deleteSuccess: "Xóa thành công",
          deleteError: "Xóa không thành công",
        },
        wordsWeight: {
          createWordsWeight: "Từ trọng lượng mới",
          editWordsWeight: "Chỉnh sửa từ trọng lượng",
          addWordsWeight: "Thêm từ trọng lượng",
          pleaseEnterWeightWords: "Vui lòng nhập từ trọng lượng",
          pleaseEnterWeight: "Vui lòng nhập trọng lượng",
          weightNeedInZeroToHundred: "Trọng lượng cần phải là 0 ~ Giữa 100",
          synonymWordsExists: "Từ trọng lượng đã tồn tại",
          weightNeedNumber: "Trọng số yêu cầu định dạng số nguyên",
          standardWeightInformation:
            "Trọng số cơ sở là 50, trọng số càng lớn thì ảnh hưởng càng mạnh trong phân tích",
          createSuccess: "Thêm thành công",
          editSuccess: "Sửa đổi thành công",
          deleteSuccess: "Xóa thành công",
          deleteError: "Xóa không thành công",
        },
        corpusData: {
          createCorpusData: "Dữ liệu kho mới",
          editCorpusData: "Chỉnh sửa dữ liệu kho ngữ liệu",
          addCorpusData: "Thêm dữ liệu ngữ liệu",
          pleaseEnterCorpusDescription: "Vui lòng nhập mô tả ngữ liệu",
          pleaseEnterCorpusContent: "Vui lòng nhập nội dung tài liệu",
          synonymWordsExists: "Dữ liệu kho ngữ liệu đã tồn tại",
          CorpusContentWordsOverSetting:
            "Số lượng từ trong nội dung vượt quá 20.000 từ,",
          download: "Tải về",
          upload: "Tải lên",
          see: "Xem",
          canNotSave:
            "Vui lòng sử dụng chức năng nhập, nếu không nó không thể được lưu",
          createSuccess: "Thêm thành công",
          editSuccess: "Sửa đổi thành công",
          deleteSuccess: "Xóa thành công",
          deleteError: "Xóa không thành công",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Từ đúng",
            wrongWord: "Từ sai",
            wrongWordCount: "Số từ sai",
            wrongWordTip: "Nhấn phím Enter để thêm",
            status: "Hiệu quả",
          },
          create: "Bộ từ lỗi chính tả mới",
          edit: "Chỉnh sửa bộ sưu tập từ lỗi chính tả",
          tip: 'Bộ từ lỗi chính tả được sử dụng để tối ưu hóa tác dụng của "phát hiện lỗi chính tả".',
          onlyChinese: "Chỉ hỗ trợ nhập từ tiếng Trung",
          wrongWordLengthErr: "Độ dài của mỗi từ sai phải là 2 ~ 10 từ",
        },
      },
      semanticTags: {
        title: "Nhãn ngữ nghĩa",
        current: "Nhãn ngữ nghĩa hiện tại",
        reference1: "Nhiệm vụ kiểm tra chất lượng: {0} - {1}",
        reference2: "Thẻ thông minh: {0}",
        reference3: "Tiền xử lý dữ liệu: {0}",
        reference4: "Cơ sở kiến thức: {0}",
        index: { import: "Nhập nhãn ngữ nghĩa" },
        detail: {
          new: "Nhãn ngữ nghĩa mới",
          edit: "Chỉnh sửa thẻ ngữ nghĩa",
          name: "Tên",
          standardSentences: "Câu chuẩn",
          effectiveness: "Hiệu quả",
          similarSentences: "Câu tương tự",
          tip: "Hỗ trợ gọi API thực thể bằng # #, ví dụ: Số điện thoại của bạn có phải là # sys_phone # không?",
          excludeSimilarSentences: "Loại trừ các câu tương tự",
          intelligentRecommend: "Đề xuất thông minh nội dung tương tự",
          fastTraining: "Đào tạo nhanh:",
          qualify: "Chính quy",
          recallKeyWord: "Từ khóa thu hồi",
          excludeKeywords: "Loại trừ từ khóa",
          excludeKeywordsInfo1:
            "Loại trừ từ khóa, thông thường được ưu tiên hơn tất cả các logic có hiệu lực, nếu bạn nhấn loại trừ, bạn sẽ không nhấn nhãn ngữ nghĩa",
          keyWordInfo1:
            "Mỗi dòng từ khóa thu hồi hỗ trợ điền nhiều, được phân tách bằng dấu phẩy tiếng Anh và logic là tất cả các lần đánh khi điền nhiều.",
          keyWordInfo2:
            "Từ khóa thu hồi hỗ trợ điền nhiều dòng và logic điền nhiều dòng có thể được thiết lập cho bất kỳ lượt truy cập nào.",
          recallRegular: "Thu hồi chính quy",
          excludeRegular: "Loại trừ chính quy",
          similaSentences: "Loại trừ các câu tương tự",
          simim: "Sự tương đồng",
          keyWordInfo3:
            "Loại trừ các câu tương tự được ưu tiên hơn tất cả các logic có hiệu lực. Nếu bạn nhấn loại trừ các câu tương tự, bạn sẽ không nhấn nhãn ngữ nghĩa. Độ giống nhau mặc định là 80%. Độ giống nhau càng lớn thì càng giống nhau.",
          keyWordInfo4:
            "Các quy tắc tương tự như quy tắc thông thường, bắt đầu và kết thúc tồn tại theo mặc định. Không nhất thiết phải được sử dụng với dấu ngoặc, ví dụ: [không phải XXXX]",
          keyWordInfo5:
            "Các chức năng trong các mô-đun nâng cao thường là các chức năng thử nghiệm được chuẩn bị cho các chuyên gia quen thuộc với các thao tác. Nếu bạn không chắc chắn về cách bạn nên làm, bạn không nên thay đổi phần này.",
          keyWordInfo6:
            "Chức năng phát hiện giữa các câu cung cấp cho các thẻ ngữ nghĩa khả năng phát hiện trên các câu.",
          keyWordInfo7: "Nhiều nhất là liên tục trên cùng một vai trò",
          keyWordInfo8:
            "Bỏ qua các nhân vật khác nhau, độ dài câu không lớn hơn",
          keyWordInfo9: "Phát hiện câu chéo",
          keyWordInfo10: "Giới hạn câu chéo dài nhất không lớn hơn",
          keyWordInfo11: "Loại bỏ tỷ lệ phần trăm tương tự không nhỏ hơn",
          keyWordInfo12: "Sự tương đồng không nhỏ hơn",
          keyWordInfo13:
            "% Các câu tương tự sẽ được khớp tương tự (độ giống nhau mặc định là 80%)",
          keyWordInfo14:
            "Việc loại bỏ các câu tương tự sẽ phù hợp dựa trên sự giống nhau của các câu, nên loại bỏ các câu quá giống nhau để nâng cao hiệu quả phát hiện.",
          keyWord: "Từ khóa",
          entity: "Thực thể",
          ruleField: "Khái niệm từ",
          customRule: "Thiết kế quy tắc nói",
          fanhua: "Câu hỏi tương tự",
          test: "Kiểm tra",
          testOk: "Kiểm tra đánh",
          senior: "Cài đặt nâng cao",
          seniorList: "Phát hiện câu chéo",
          up: "Kích hoạt",
          down: "Đóng cửa",
          ju: "Câu",
          oneWord: "Chữ",
          simimNo: "Tương tự",
          seniorField: "Ngưỡng tương tự",
          save: "Lưu",
          cancel: "Hủy bỏ",
          specialElements: "Các yếu tố đặc biệt",
          logicRule: "Quy tắc logic",
          every: "Nhiều trận đấu",
          recallOk: "Đánh các từ thu hồi:",
          recallNo: "Từ thu hồi không trúng đích",
          ruleOk: "Đánh thu hồi thông thường",
          ruleNo: "Không đánh thường xuyên",
          rularOk: "Quy tắc đánh",
          rularNo: "Quy tắc trượt",
          infoOk: "Đánh vào câu chuẩn hoặc câu tương tự:",
          infoNo: "Câu tiêu chuẩn hoặc câu tương tự",
          fieldOk: "Đánh để loại trừ các câu tương tự:",
          fieldNo: "Các câu tương tự bị loại trừ",
          excludeRuleNo: "Chính quy loại trừ",
          excludeRuleOk: "Thông thường loại trừ",
          excludeKeywordNo: "Từ khóa bị loại",
          excludeKeywordOk: "Lượt truy cập để loại trừ các từ khóa",
          update: "Cập nhật thành công",
          fanhuaList:
            "Đã gửi đến trang tổng quát để ký danh sách các câu tương tự",
          wordList:
            "Danh sách đào tạo trống và không thể gửi vào danh sách tổng quát",
          start: "Bắt đầu",
          end: "Kết thúc",
          keyWordInfo15:
            "Có các trường tương tự trong danh sách tổng quát hóa (cấu hình nâng cao tương tự với các ràng buộc loại bỏ tái tạo)",
          saveSuccess: "Lưu thành công",
          errInfo: "Có những trường không được điền hoặc điền không chính xác",
        },
        table: {
          deepTraining: "Đào tạo chuyên sâu {0} vòng",
          fastTraining: "Đào tạo nhanh",
        },
      },
      intelligentCollection: {
        title: "Thu thập thông minh",
        classification: "Danh sách nhóm",
        new: "Bộ sưu tập mới",
        table: {
          name: "Tên trường",
          props: "Thuộc tính khách hàng",
          type: "Loại trường",
          desc: "Mô tả trường",
        },
        detail: {
          isCustomerProps: "Lưu vào Thuộc tính khách hàng",
          customerPropsHelper:
            "Sau khi lưu, thông tin thu thập được sẽ được cập nhật vào thông tin khách hàng. Nếu không lưu, nó sẽ không được cập nhật vào thông tin khách hàng",
          name: "Tên trường",
          type: "Loại trường",
          text: "Văn bản",
          desc: "Mô tả trường",
          descPlaceHolder:
            "Mô tả việc sử dụng thông tin thu thập trong trường này",
          condition: "Điều kiện",
          logic: "Quy tắc",
          method: "Phương thức thu thập",
          message: "Nội dung thông tin",
          range: "Phạm vi chiết xuất",
          entity: "Thực thể trích xuất",
          base: "Thông tin cơ bản",
          collect: "Thu thập thông tin",
        },
      },
      intelligentTags: {
        title: "Nhãn thông minh",
        sessionLabelTitle: "Cảnh đàm thoại",
        keyEventsTitle: "Sự kiện quan trọng",
        customerLabelTitle: "Nhãn khách hàng",
        addClassification: "Tạo một danh mục nhãn mới",
        defaultCategoryName: "Phân loại mặc định",
        index: {
          createTag: "Nhãn mới",
          createEvent: "Sự kiện mới",
          editTagClassification: "Chỉnh sửa phân loại nhãn",
          selectTagClassification: "Chọn phân loại nhãn",
          editTagGrounp: "Nhóm chỉnh sửa",
          callTimeError:
            "Thời gian cuộc gọi/thời gian bắt đầu cuộc trò chuyện đã chọn không được vượt quá một tháng (31 ngày)",
          callRecordCountError:
            "Số bản ghi là 0, vui lòng chọn lại thời gian cuộc gọi/hội thoại",
          selectItmeCountError:
            "Vui lòng chọn nhãn thông minh cần thực hiện thao tác hàng loạt",
          redoSuccess:
            "Để thực hiện lại thành công, vui lòng xem trong trung tâm tác vụ",
          redoError: "Thực hiện lại không thành công, vui lòng thử lại sau",
          timeError:
            "Thời gian truy vấn không được vượt quá một tháng (31 ngày)",
          categoryName: "Tên phân loại",
          categoryColor: "Phân loại màu sắc",
          newTagClassification: "Phân loại nhãn mới",
          newEventClassification: "Phân loại mới",
          tagClassification: "Phân loại nhãn",
          eventClassification: "Phân loại sự kiện",
        },
        detail: {
          new: "Nhãn thông minh mới",
          edit: "Chỉnh sửa nhãn thông minh",
          tagName: "Tên",
          tagDesc: "Mô tả",
          category: "Phân loại",
          tagStatus: "Hiệu quả",
          operator: "Toán tử",
          rule: "Quy tắc",
          labelClassAtion: "Loại nhãn",
          labelClassAtionTip1: "Đánh dấu nhãn: đánh dấu nhãn dựa trên quy tắc",
          labelClassAtionTip2:
            "Nhãn danh mục: Đánh dấu nhãn dựa trên đào tạo mô hình",
          group: "Nhóm",
          checkLabel: "Chọn phân loại",
          warn: "Cảnh báo sớm",
          warnTips:
            "Sau khi bật cảnh báo, khi phiên đáp ứng các quy tắc sự kiện quan trọng này, nhân viên được chỉ định sẽ được nhắc và bạn có thể nhận được lời nhắc tại nơi cần làm",
          warnError: "Vui lòng chọn người được chỉ định cảnh báo sớm",
        },
        subTitle: {
          common: "Đàm thoại thông minh Tags",
          wecom: "Nhãn vi mô doanh nghiệp",
        },
      },
      customerPortrait: {
        title: "Chân dung khách hàng",
        index: { import: "Nhập chân dung khách hàng" },
        searchPlaceHolder: "ID khách hàng, biệt danh hoặc điện thoại",
        callCount: "Số cuộc hội thoại:",
        callDuration: "Tổng thời lượng cuộc gọi:",
        dialogCount: "Tổng số cuộc hội thoại:",
        keyWordsStatistics: "Thống kê từ khóa (Top 20 & Từ quan tâm)",
        keyWordsCount: "({0})",
        businessTag: "Nhãn kinh doanh",
        keyWordsCloudImg: "Từ khóa hình ảnh đám mây",
        customerEmotionTrend: "Xu hướng thay đổi tâm trạng khách hàng",
        notFoundCustomer: "Khách hàng liên quan không được truy xuất",
        noKeyWords: "Không tìm thấy các từ khóa liên quan",
        noBusinessTags: "Không có thẻ kinh doanh liên quan",
        getKeyWordsStatisticsDataError:
          "Truy vấn thống kê từ khóa của khách hàng không thành công",
        getBusinessTagsStatisticsDataError:
          "Khách hàng kinh doanh thẻ truy vấn không thành công",
        personCustome: "Không truy vấn thông tin khách hàng",
        notimeInfo: "Không truy vấn thông tin cảm xúc",
        timeList: "Phạm vi ngày",
        operator: "Nhãn thông minh",
        placeHolder: "Vui lòng chọn",
        placeOperator: "Vui lòng chọn nhãn thông minh",
        search: "Truy vấn",
        reset: "Đặt lại",
        searchHolder: "Tìm kiếm",
        call: "Cuộc gọi",
        text: "Đối thoại",
      },
      sentimentAnalysis: { title: "Khám phá dư luận" },
      saleSentimentAnalysis: { title: "Phân tích dư luận" },
      dataPreprocess: {
        title: "Xử lý trước dữ liệu",
        index: {
          createData: "Dữ liệu mới",
          selectDataSource: "Chọn nguồn dữ liệu",
          selectStatus: "Chọn tính hợp lệ",
          callTimeError:
            "Thời gian cuộc gọi/thời gian bắt đầu cuộc trò chuyện đã chọn không được vượt quá một tháng (31 ngày)",
          callRecordCountError:
            "Số bản ghi là 0, vui lòng chọn lại thời gian cuộc gọi/hội thoại",
          selectItmeCountError:
            "Vui lòng chọn dữ liệu bạn cần thực hiện thao tác hàng loạt",
          redoSuccess:
            "Để thực hiện lại thành công, vui lòng xem trong trung tâm tác vụ",
          redoError: "Thực hiện lại không thành công, vui lòng thử lại sau",
        },
        detail: {
          new: "Dữ liệu mới",
          edit: "Chỉnh sửa dữ liệu",
          name: "Tên",
          desc: "Mô tả",
          dataSource: "Nguồn dữ liệu",
          status: "Hiệu quả",
          entity: "Thực thể trích xuất",
          operator: "Toán tử",
          rule: "Quy tắc",
        },
      },
      connectedByConversation: {
        title: "Liên kết đối thoại",
        list: {
          title: "Chức năng liên kết đối thoại",
          info: "Chức năng liên kết hội thoại có thể liên kết các cuộc gọi và hội thoại khác nhau theo các quy tắc đặt trước",
          rule: "Quy tắc liên kết đối thoại",
          source: "Vui lòng chọn nguồn dữ liệu",
          classification: "Vui lòng chọn phân loại",
          choose: "Vui lòng chọn",
          input: "Vui lòng nhập",
          save: "Lưu",
          cancel: "Hủy bỏ",
          fieldwei: "Vị trí",
          fieldNot: "Có các trường chưa điền",
          fiedldlengthNo: "Độ dài trường vượt quá giới hạn",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Nhiều từ khóa có thể được đặt, được phân tách bằng dấu phẩy",
          applyRole: "Phát hiện vai trò",
          preOperator: "Toán tử phía trước",
          applyOperatorScope: "Phạm vi phát hiện",
          addOperator: "Thêm toán tử",
          noPreOperator: "Không có",
          from: "Không.",
          to: "Câu đến đầu tiên",
          end: "Câu",
          testRegular: "Kiểm tra biểu thức chính quy",
          test: "Kiểm tra",
          matchSuccess: "Phù hợp với thành công",
          matchError: "Trận đấu thất bại",
          querySementicTags: "Chọn nhãn ngữ nghĩa",
          reQuerySementicTags: "Chọn lại nhãn ngữ nghĩa",
          sementicTag: "Nhãn ngữ nghĩa",
          standardSentence: "Câu chuẩn",
          similarity: "Sự tương đồng",
          actions: "Hoạt động",
          noFitTag: "Không tìm thấy nhãn phù hợp,",
          addSemanticTag: "Thêm một thẻ ngữ nghĩa mới",
          pleaseEnterRegular: "Vui lòng nhập biểu thức chính quy",
          pleaseEnterRegularText: "Vui lòng nhập nội dung cần xác minh",
          newAssociateComponentTip:
            "Nếu giá trị là loại số, hãy sử dụng chức năng cũ",
        },
        filterManage: {
          noValueTip: "Không có dữ liệu",
          allCall: "Tất cả các cuộc gọi",
          allDialogue: "Tất cả các cuộc trò chuyện",
          allWechat: "Tất cả các phiên",
          getFiltersListFailed:
            "Nhận danh sách các bộ lọc không thành công, vui lòng thử lại sau!",
          getFilterDetailFailed:
            "Nhận chi tiết bộ lọc không thành công, vui lòng thử lại sau!",
          getAllCountFailed:
            "Không thể lấy tổng số cuộc gọi/cuộc trò chuyện, vui lòng thử lại sau!",
        },
        listManage: {
          searchPlaceHolder: "Vui lòng nhập từ khóa tìm kiếm",
          filterConditions: "Điều kiện sàng lọc",
          records: "Hồ sơ",
          getRecordsFailed: "Không lấy được hồ sơ, vui lòng thử lại sau!",
        },
        miniListManage: {
          getRecordsFailed: "Không lấy được hồ sơ, vui lòng thử lại sau!",
        },
        applyTemplates: {
          title: "Mẫu kiểm tra chất lượng áp dụng",
          collectionTitle: "Mẫu bộ sưu tập áp dụng",
        },
        recordDetail: {
          showWaveAudio: "Hiển thị ghi âm",
          getDetailFailed:
            "Không thể lấy thông tin chi tiết, vui lòng thử lại sau!",
          getSmartTagsFailed:
            "Nhận thẻ thông minh không thành công, vui lòng thử lại sau!",
          getTemplatesFailed:
            "Không thể lấy được mẫu áp dụng, vui lòng thử lại sau!",
        },
        changeList: {
          sampleStatus: "Tình trạng lấy mẫu",
          sampleAssignInspector: "Chỉ định nhân viên kiểm tra ngẫu nhiên",
          reviewAssignInspector: "Phân công người kiểm toán",
          read: "Đã đọc",
          distributivePerson: "Phân phối người",
          totalScore: "Tổng điểm",
        },
        qualityCheckScore: {
          totalScore: "Tổng điểm",
          actualScore: "Điểm số",
          actualGrade: "Xếp hạng",
          appeal: "Kháng nghị",
          remark: "Ghi chú",
          manualRemark: "Ghi chú nhân tạo",
          none: "Không có dữ liệu chi tiết kiểm tra chất lượng",
          interactiveTip:
            "Sử dụng nhận dạng tương tác để phát hiện việc sử dụng FAQ của dịch vụ khách hàng",
          interactiveIsEmptyTip: "Không phát hiện nội dung nhận dạng tương tác",
        },
        semanticMark: {
          semanticContent: "Câu đánh dấu",
          markedSimilarTitel: "Các thẻ ngữ nghĩa tương tự đã được gắn nhãn",
          markedExcludeTitle: "Các thẻ ngữ nghĩa tương tự đã bị loại trừ",
          taskTagTitle: "Các thẻ ngữ nghĩa liên quan đến nhiệm vụ",
          similarTagTitle:
            "Xác định các thẻ ngữ nghĩa tương tự (tương tự> 40%,Top 20)",
          delete: "Xóa",
          markSimilar: "Đánh dấu tương tự",
          markExclude: "Loại trừ tương tự",
          none: "Không có",
        },
        selectCallTime: {
          callTime: "Thời gian nói chuyện/thời gian bắt đầu phiên",
          callTimeRemind:
            "Bạn có thể chọn tối đa một bản ghi cuộc trò chuyện không quá một tháng (31 ngày) trong năm gần nhất",
          callRecordCount:
            "Tổng số bản ghi cuộc gọi/cuộc trò chuyện bạn đã chọn {0}",
          getCallRecordError: "Không lấy được bản ghi, vui lòng thử lại sau",
        },
      },
      informationEntities: {
        title: "Thực thể thông tin",
        index: { import: "Nhập thực thể thông tin" },
        detail: {
          new: "Thực thể thông tin mới",
          edit: "Chỉnh sửa thực thể thông tin",
          name: "Tên",
          apiName: "Tên API",
          activeFlag: "Hiệu quả",
          remark: "Mô tả",
          logicExp: "Chính quy thực thể",
          sentenceList: "Nội dung vật lý",
          tip: "Nội dung thực thể hỗ trợ nhiều đầu vào, được phân tách bằng dấu cách và là từ đồng nghĩa của cùng một thực thể",
          remarkLengthError: "Độ dài mô tả không được vượt quá 255",
          nameLengthError: "Độ dài tên không được vượt quá 40",
          entityDuplicateError: "Nội dung thực thể trùng lặp",
        },
      },
      interactiveRecognition: {
        title: "Nhận dạng tương tác",
        classify: "Phân loại",
        addClassify: "Thêm danh mục",
        editClassify: "Chỉnh sửa phân loại",
        index: { import: "Nhập nhận dạng tương tác" },
        detail: {
          new: "Tương tác thông minh mới",
          edit: "Chỉnh sửa tương tác thông minh",
          type: "Loại",
          standardQuestion: "Vấn đề tiêu chuẩn",
          similarQList: "Vấn đề tương tự",
          exceptSimilarQList: "Loại trừ các vấn đề tương tự",
          category: "Phân loại",
          targetType: "Vấn đề phát hiện mục tiêu",
          status: "Hiệu quả",
          standardAnswer: "Câu trả lời tiêu chuẩn",
          similarAList: "Câu trả lời tương tự",
          wrongAList: "Câu trả lời sai",
        },
      },
      businessAnalysis: {
        title: "Phân tích kinh doanh",
        tip1: "Phân tích xu hướng kinh doanh: tọa độ là số đoạn hội thoại, tức là tổng số đoạn hội thoại đáp ứng các điều kiện và abscissa là thời gian",
        tip2: "Phân tích mối tương quan: Phân tích mối tương quan của nhiều chỉ số, thả nổi chuột để hiển thị trạng thái dữ liệu tương quan, khối lượng dữ liệu, mức độ tương quan và tỷ lệ lặp lại",
        tip3: "Phân tích chuyển đổi: Phân tích mối quan hệ chuyển đổi lẫn nhau giữa nhiều chỉ số. Tiêu chí xác định chuyển đổi là cùng một cuộc gọi/cuộc trò chuyện. Nếu bạn có thể đánh hai chỉ số khác nhau, có thể coi hai chỉ số này có mối quan hệ chuyển đổi.",
      },
      knowledgeBase: {
        title: "Cơ sở kiến thức",
        list: {
          add: "Thêm danh mục phụ",
          delete: "Xóa phân loại",
          categoryName: "Tên phân loại",
          createSuccess: "Phân loại phụ được tạo thành công",
          keyWord: "Từ khóa",
        },
        detail: {
          type: "Loại",
          status: "Trạng thái",
          classification: "Phân loại",
          standardQuestion: "Vấn đề tiêu chuẩn",
          answerDetectionRange: "Phạm vi phát hiện câu trả lời",
          standardAnswer: "Câu trả lời tiêu chuẩn",
        },
      },
      callManage: {
        title: "Bộ lọc ghi âm cuộc gọi",
        listTitle: "Quản lý cuộc gọi",
        detailTitle: "Chi tiết cuộc gọi",
      },
      dialogueManage: {
        title: "Bộ lọc văn bản đối thoại",
        listTitle: "Quản lý cuộc trò chuyện",
        detailTitle: "Chi tiết cuộc trò chuyện",
      },
      weChatManage: {
        title: "Bộ lọc hội thoại WeChat doanh nghiệp",
        listTitle: "Quản lý phiên WeChat doanh nghiệp",
        detailTitle: "Chi tiết phiên WeChat doanh nghiệp",
      },
      speechRecognition: {
        title: "Nhận dạng giọng nói",
        asr: "Nhận dạng giọng nói",
        asrRemind:
          "Tắt công tắc này sẽ khiến chức năng chuyển giọng nói thành văn bản và chức năng kiểm tra chất lượng máy bị lỗi",
        asrConfig: "Bộ lọc ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Sau khi được bật, phiên âm ASR của IM sẽ được thực hiện",
        asrConfigRemind:
          "Sau khi được bật, chỉ các cuộc gọi đủ điều kiện mới thực hiện nội dung ASR",
        correction: "Sửa lỗi thông minh",
        correctionRemind:
          "Sau khi bật, nó sẽ sử dụng \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words' target =' _ blank'> từ điển thông minh-danh từ chuyên nghiệp </a>",
        correctionConfig: "Lọc sửa lỗi",
        advancedSettings: "Cài đặt nâng cao",
        advancedSettingsTip:
          "Chọn mô hình ASR được chỉ định cho dữ liệu được chỉ định.",
        mandarinASRModel: "Mandarin ASR mô hình",
        cantoneseASRModel: "Mô hình ASR Quảng Đông",
        englishASRModel: "Mô hình tiếng Anh ASR",
        correctionConfigRemind:
          "Sau khi kích hoạt, chỉ các cuộc gọi đáp ứng các điều kiện sau sẽ được sửa chữa",
        customJudgeLogicIsNull: "Logic tùy chỉnh không thể trống",
        redoCorrection: "Thực hiện lại sửa lỗi",
        callTime: "Chọn thời gian gọi",
        callTimeRemind:
          "Bạn có thể chọn bản ghi cuộc gọi tối đa một lần trong năm gần nhất không quá một tháng (31 ngày)",
        callRecordCount: "Bạn đã chọn bản ghi cuộc gọi tổng cộng {0}",
        redoCorrectionSubmit: "Gửi để thực hiện",
        callTimeError:
          "Phạm vi thời gian cuộc gọi được chọn không thể vượt quá một tháng (31 ngày)",
        callRecordCountError:
          "Số bản ghi cuộc gọi là 0, vui lòng chọn lại thời gian cuộc gọi",
        redoCorrectionSuccess:
          "Sửa lại thành công, vui lòng xem trong trung tâm tác vụ",
        redoCorrectionError: "Sửa lại không thành công, vui lòng thử lại sau",
      },
      role: {
        title: "Quản lý vai trò",
        create: "Vai trò mới",
        detail: {
          title: "Vai trò",
          new: "Vai trò mới",
          view: "Xem nhân vật",
          edit: "Chỉnh sửa vai trò",
          navTabNames: {
            basicInfo: "Quyền menu",
            taskPermissions: "Kiểm tra chất lượng nhiệm vụ thẩm quyền",
            dataPermissions: "Quyền dữ liệu",
          },
          basicInfo: { roleName: "Tên", permissions: "Quyền" },
          taskPermissions: {
            allTask: "Tất cả các nhiệm vụ kiểm tra chất lượng",
            myCreatedTask: "Nhiệm vụ kiểm tra chất lượng tôi đã tạo",
          },
          dataPermissions: {
            dataPermissionsConfig: "Cấu hình quyền dữ liệu",
            tips: "Vai trò có thể xem dữ liệu trong danh mục đã chọn",
            select: "Chọn phân loại",
            selectPlaceholder:
              "Vui lòng chọn danh mục yêu cầu quyền hiển thị dữ liệu",
          },
        },
      },
      dataMigration: {
        title: "Di chuyển dữ liệu",
        dataUpload: "Di chuyển dữ liệu",
        dataDowload: "Di chuyển dữ liệu",
        error: "Vui lòng chọn nhiệm vụ kiểm tra chất lượng để xuất",
        list: {
          info: "Vui lòng chọn nhiệm vụ kiểm tra chất lượng để chuyển đi",
          listInfo:
            "Vui lòng chọn xem bạn có muốn chuyển dữ liệu liên quan đến nhiệm vụ kiểm tra chất lượng hay không (chẳng hạn như từ điển đồng nghĩa thông minh, thẻ ngữ nghĩa, thực thể thông tin, tương tác thông minh, v. v.)",
          noInfo:
            "Vui lòng chọn xem bạn có muốn chuyển ra khỏi dữ liệu không kiểm tra chất lượng khác không",
          ownInfo: "Chỉ chuyển ra khỏi nhiệm vụ",
          everyInfo: "Chuyển ra khỏi nhiệm vụ liên quan",
          allInfo: "Di chuyển ra khỏi tất cả",
          callFilter: "Bộ lọc cuộc gọi",
          talkFilter: "Bộ lọc hội thoại",
          smartTag: "Nhãn thông minh",
        },
        upload: {
          info: "1. Nhấp vào nút tải lên để tải lên tệp JSON phù hợp với định dạng",
          uploadSuccess: "Tải lên thành công",
          uploadError: "Tải lên không thành công",
        },
      },
      dataAnalysis: {
        businessStatistics: "Thống kê kinh doanh",
        qualityEffect: "Kiểm tra chất lượng sức khỏe",
        qualityAnalysis: "Phân tích kiểm tra chất lượng",
        generalInspecitionReport: "Báo cáo chung",
        fullAmountWork: "Khối lượng công việc của thanh tra chất lượng",
        intelligentTagsAnalysis: "Phân tích nhãn",
        saleIntelligentTagsAnalysis: "Phân tích nhãn thông minh",
        generalInspecitionReportTip:
          "Tổng số dữ liệu: Tổng số dữ liệu cuộc gọi và cuộc trò chuyện trong phạm vi thời gian và nhiệm vụ được giới hạn hiện tại, bao gồm số lượng & phần trăm dữ liệu kiểm tra chất lượng thông minh, số lượng & phần trăm dữ liệu kiểm tra lấy mẫu thủ công, số lượng khiếu nại, tỷ lệ kiểm tra lại và biểu đồ xu hướng thời gian",
        groupTest: "Kiểm tra nhóm",
      },
      uploadDownloadList: {
        title: "Trung tâm nhiệm vụ",
        tabs: {
          upload: "Nhập tải lên",
          download: "Tải xuống xuất",
          taskCenter: "Thực hiện nhiệm vụ",
        },
      },
      uploadDownloadModel: {
        title: "Tải lên Tải xuống",
        list: {
          clear: "Trống rỗng",
          success: "Thành công",
          fail: "Thất bại",
          hand: "Đang tiến hành",
          noInfo: "Không có dữ liệu",
        },
      },
      billingCenter: {
        title: "Trung tâm thanh toán",
        export: "Xuất",
        limit: "Giới hạn nhắc nhở",
        tips: "Kết quả thanh toán sẽ bị sai lệch do làm tròn, chỉ mang tính chất tham khảo",
        dashboard: {
          remainingQuota: "Hạn ngạch còn lại hiện tại",
          usedQuota: "Hạn ngạch đã sử dụng tích lũy",
          voiceQuality: "Kiểm tra chất lượng giọng nói:",
          voice: "Giọng nói:",
          textQuality: "Kiểm tra chất lượng văn bản:",
          text: "Văn bản:",
          hour: "Giờ",
          tenThousandHour: "Triệu giờ",
          day: "Ngày",
          cutoff: "Hạn chót",
          warning:
            "(Hạn ngạch còn lại không đủ, vui lòng nạp tiền càng sớm càng tốt)",
          isClosed: "(Trạng thái đóng)",
        },
        table: {
          quotaRecord: "Hồ sơ hạn ngạch",
          timeFilter: "Chọn ngày:",
          search: "Truy vấn",
          reset: "Đặt lại",
          id: "Số sê-ri",
          type: "Loại",
          qualityType: "Loại hình kiểm tra chất lượng",
          quotaChange: "Thay đổi",
          surplus: "Số dư",
          changeTime: "Thời gian",
          voiceQuality: "Kiểm tra chất lượng giọng nói",
          textQuality: "Kiểm tra chất lượng văn bản",
          sizeChangeText: "Hiển thị trên mỗi trang",
          used: "Sử dụng",
          give: "Cấp",
          deduction: "Khấu trừ",
          callTime: "Thời gian nói chuyện",
          count: "Xử lý khối lượng dữ liệu",
          page: "Tổng số {0} mục,{1} trang",
        },
        limitModal: {
          call: "Hạn ngạch kiểm tra chất lượng bằng giọng nói thấp hơn",
          callFix: "Nhắc nhở hàng giờ",
          dialogue: "Thời gian còn lại của kiểm tra chất lượng văn bản nhỏ hơn",
          dialogueFix: "Ngày nhắc nhở",
        },
      },
      messageCenter: {
        replyTitle: "Trả lời tôi",
        answerTitle: "@ Của tôi",
        shareTitle: "Chia sẻ của tôi",
      },
      columnsManage: { title: "Quản lý trường kiểm tra chất lượng" },
      recordUpload: {
        list: {
          task: "Nhiệm vụ kiểm tra chất lượng",
          user: "Người điều hành",
          uploadTime: "Thời gian tải lên",
          endTime: "Thời gian kết thúc",
          total: "Tổng tải lên",
          success: "Kiểm tra chất lượng thành công",
          failed: "Kiểm tra chất lượng không thành công",
          progress: "Tiến độ kiểm tra chất lượng",
          inProgress: "Đang tiến hành",
          finished: "Đã hoàn thành",
        },
        steps: {
          uploading: "Tải lên tệp ghi âm",
          baseInfo: "Điền thông tin cơ bản",
          start: "Bắt đầu kiểm tra chất lượng",
        },
        uploadComponent: {
          dragger: "Kéo và thả để thêm tệp",
          or: "Hoặc",
          selectFile: "Chọn tập tin",
          des: "Hỗ trợ wav, định dạng mp3, tốc độ mẫu 8000Hz,16-bit tập tin",
          limit1: "Tải lên nhiều nhất một lần",
          limit2: "Tệp, kích thước của mỗi tệp không vượt quá",
          uploading: "Đang tải lên",
          finished: "Tải lên hoàn tất",
          success: "Thành công",
          failure: "Thất bại",
        },
        baseInfo: {
          task: "Chọn nhiệm vụ kiểm tra chất lượng",
          type: "Chọn hình thức ghi âm",
          agentSide: "Kênh dịch vụ khách hàng",
          excel: "Tải lên dữ liệu theo dõi",
          checkFileInfo: "Xác minh thông tin tài liệu",
          excelFileError: "Vui lòng tải lên dữ liệu",
          checking: "Xác minh...",
          reCheck: "Kiểm tra lại",
          success: "Xác minh thành công",
          excelUoloadFail: "Tải lên dữ liệu không thành công",
          tips: {
            tip1: "1. Tên của tệp ghi âm trong ecxel phải giống với tên của bản ghi đã tải lên và không được trùng tên",
            tip2: "2. Hệ thống sử dụng tên tệp ghi âm và ID cuộc gọi để kiểm tra lại, việc lặp lại sẽ khiến việc kiểm tra chất lượng không thành công",
            tip3: "3. Mẫu ghi âm excel đã tải xuống, màu phông chữ màu đỏ là trường bắt buộc",
            tip4: "4. Định dạng trường của loại thời gian là: yyyy-mm-dd hh:mm:ss, ví dụ: 2021-04-20 17:36:30",
          },
        },
        title: "Tải lên bản ghi địa phương",
        checkTitle: "Kiểm tra chất lượng ghi âm",
        start: "Bắt đầu kiểm tra chất lượng",
        disabledTips:
          "Vui lòng xác minh tệp ghi âm trước khi kiểm tra chất lượng",
        tips: "Tệp ghi âm cùng tên bao gồm kết quả kiểm tra chất lượng và thông tin cơ bản",
        tips1: "Kéo và thả tệp vào đây hoặc nhấp vào Tải lên",
        tips2: "Hỗ trợ",
        tips3: "Định dạng",
        type: "wav、mp3",
        tips4: "Tải lên nhiều nhất một lần",
        tips5: "Tệp, kích thước của mỗi tệp không vượt quá",
        tipsOverSize:
          "Hàng đợi hiện tại đã đầy, vui lòng xóa tệp trong hàng đợi trước khi tải lên",
        size: "500M",
        count: "20",
        uploaded: "Tệp ghi âm đã tải lên",
        uploading: "({0}%)",
        uploadFinished: "(Tải lên đã hoàn tất)",
        bottomTitle: "Thu thập thông tin ghi âm:",
        recordType: "Chọn hình thức ghi âm:",
        double: "Ghi âm cuộc gọi kênh đôi",
        single: "Ghi âm cuộc gọi một kênh",
        recordBaseInfo: "Tải lên thông tin cơ bản của bản ghi âm:",
        addBtn: "Thêm tập tin",
        downloadTemplate: "Tải xuống mẫu. excel",
        checkRecord: "Kiểm tra thông tin ghi âm",
        checking: "Xác minh...",
        reCheck: "Kiểm tra lại",
        checkAll: "Chọn tất cả",
        cancelCheckAll: "Hủy chọn tất cả",
        delete: "Xóa",
        deleteLotSize: "Xóa hàng loạt",
        agentChannel: "Kênh dịch vụ khách hàng:",
        left: "Kênh bên trái",
        right: "Kênh bên phải",
        donotKonw: "Không biết",
        mission: "Chọn nhiệm vụ kiểm tra chất lượng:",
        uploadFail: "Tải lên không thành công",
        repeat: "Tập tin trùng lặp",
        overCount: "Số lượng tệp vượt quá giới hạn",
        overSize: "Kích thước tệp vượt quá giới hạn",
        uoloadFail: "Tải lên đám mây không thành công",
        formatError: "Lỗi định dạng",
        seccess: "Xác minh thành công",
        fail: "Xác minh không thành công",
        recordChecking: "Đang kiểm tra chất lượng ghi âm, vui lòng đợi...",
        recordCheckSuccess: "Hoàn thành kiểm tra chất lượng ghi âm",
        recordCheckFail:
          "Kiểm tra chất lượng bản ghi không thành công, vui lòng kiểm tra xem bản ghi có trùng lặp hoặc không hợp lệ hay không",
        backToIndex: "Quay lại danh sách tải lên",
        recordCheckIsStarted:
          "Hiện tại có nhiệm vụ kiểm tra chất lượng đang được thực hiện, vui lòng bắt đầu nhiệm vụ mới sau khi nhiệm vụ hiện tại kết thúc",
      },
      customerServicePortrait: {
        title: "Chân dung dịch vụ khách hàng",
        tip1: "Quy tắc phân bố độ dài câu: sự phân bố độ dài câu, số lượng từ được tính theo độ dài câu, và chỉ tính dịch vụ khách hàng",
        tip2: "Luật phân phối tốc độ nói: Phân phối tốc độ nói, theo tốc độ nói (từ/phút), thống kê được thực hiện theo từng phần, chỉ tính dịch vụ khách hàng",
        tip3: "Luật phân phối thời gian phản hồi: phân phối thời gian phản hồi, chỉ tính toán tình hình khách hàng phản hồi",
        tip4: "Tuân thủ kiểm tra chất lượng: tỷ lệ tuân thủ tại điểm kiểm tra chất lượng",
        tip5: "Điểm trung bình của nhiệm vụ kiểm tra chất lượng: điểm trung bình của nhiệm vụ kiểm tra chất lượng",
        contol: {
          date: "Chọn ngày:",
          today: "Hôm nay",
          thisMonth: "Tháng này",
          lastMonth: "Tháng trước",
          thisQuarter: "Quý này",
          lastQuarter: "Quý trước",
          thisYear: "Năm nay",
          lastYear: "Năm ngoái",
          customer: "Dịch vụ khách hàng:",
          search: "Truy vấn",
          reset: "Đặt lại",
          export: "Xuất",
          indicator: "Quản lý chỉ số",
          selectPlaceholder: "Vui lòng chọn dịch vụ khách hàng cần so sánh",
          warning: "Chọn tối đa 5 dịch vụ khách hàng để so sánh",
          warning2: "Vui lòng chọn ít nhất 1 dịch vụ khách hàng để yêu cầu",
          dataWarning:
            "Chỉ hỗ trợ truy vấn dữ liệu từ 366 ngày sau ngày bắt đầu",
          seccuss: "Xuất bản ghi thành công",
        },
        echarts: {
          distributionLaw: "Luật phân phối",
          count: "Số lần",
          bout: "Lần",
        },
        portrait: {
          ability: "Kích thước khả năng dịch vụ khách hàng",
          option: "Cấu hình",
          abilityOption: "Kích thước khả năng cấu hình",
          placeholder: "Khả năng đầu vào thứ nguyên tìm kiếm từ khóa",
          save: "Lưu",
          warning:
            "Vui lòng chọn ít nhất 3 chỉ số để cấu hình và 10 chỉ số có thể được chọn",
        },
        label: { title: "Thẻ kinh doanh dịch vụ khách hàng" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Xếp hạng dịch vụ khách hàng",
        select: {
          date: "Chọn ngày:",
          today: "Hôm nay",
          thisMonth: "Tháng này",
          lastMonth: "Tháng trước",
          thisQuarter: "Quý này",
          lastQuarter: "Quý trước",
          thisYear: "Năm nay",
          lastYear: "Năm ngoái",
          group: "Nhóm dịch vụ khách hàng:",
          groupPlaceholder: "Vui lòng chọn nhóm dịch vụ khách hàng",
          customer: "Dịch vụ khách hàng:",
          customerPlaceholder: "Vui lòng chọn dịch vụ khách hàng",
          search: "Truy vấn",
          reset: "Đặt lại",
          compared: "Tương phản",
          export: "Xuất",
          column: "Cột",
          warning: "Chọn tối đa 5 dịch vụ khách hàng để so sánh",
          warning2: "Vui lòng chọn ít nhất một dịch vụ khách hàng",
          dataWarning:
            "Chỉ hỗ trợ truy vấn dữ liệu từ 366 ngày sau ngày bắt đầu",
          selected: "Dịch vụ khách hàng đã chọn:",
          seccuss: "Xuất bản ghi thành công",
        },
        compared: {
          title: "Chọn dịch vụ khách hàng",
          return: "Hủy bỏ",
          compared: "Tương phản",
          tips: "Vui lòng chọn 2-5 dịch vụ khách hàng để so sánh",
          warning: "Chỉ hỗ trợ 2-5 dịch vụ khách hàng để so sánh",
        },
        transfer: { leftTitle: "Mục danh sách", rightTitle: "Mục hiển thị" },
        table: {
          page: "Tổng số {0} mục,{1} trang",
          actionTitle: "Hoạt động",
          action: "Xem",
        },
      },
      conceptWord: {
        title: "Khái niệm từ",
        new: "Từ khái niệm mới",
        control: {
          search: "Tìm kiếm:",
          searchPlaceholder: "Tìm kiếm tên hoặc từ khóa",
          commit: "Truy vấn",
          reset: "Đặt lại",
          import: "Nhập",
          export: "Xuất",
          seccuss: "Xuất bản ghi thành công",
        },
        modal: { title: "Nhập từ khái niệm" },
        table: {
          sizeChangeText: "Hiển thị trên mỗi trang",
          page: "Tổng số {0} mục,{1} trang",
          edit: "Chỉnh sửa",
          delete: "Xóa",
          request:
            "Nó sẽ không được khôi phục sau khi xóa. Việc xóa có được xác nhận không?",
          cancel: "Hủy bỏ",
          title: {
            conceptWord: "Khái niệm từ",
            describeWordsNum: "Số từ mô tả",
            updateTime: "Thời gian cập nhật",
            status: "Hiệu quả",
            effective: "Hiệu quả",
            invalid: "Không hợp lệ",
            action: "Hoạt động",
          },
        },
        detail: {
          title: { new: "Từ khái niệm mới", edit: "Chỉnh sửa từ khái niệm" },
          name: "Tên từ khái niệm",
          namePlaceholder: "Vui lòng nhập tên từ khái niệm",
          effectiveness: "Hiệu quả",
          effective: "Hiệu quả",
          invalid: "Không hợp lệ",
          description: "Từ mô tả:",
          descriptionPlaceholder: "Vui lòng nhập từ mô tả",
          add: "Thêm",
          repeat: "Nội dung trùng lặp",
          empty: "Mô tả không thể trống",
          recommendEmpty:
            "Không có thêm từ mô tả được đề xuất, vui lòng nhập thủ công",
          recommend: "Mô tả đề xuất thông minh",
          cancel: "Hủy bỏ",
          change: "Thay đổi một nhóm",
          descriptionSelected: "Đã thêm từ mô tả:",
          save: "Lưu",
          success: "Từ khái niệm được lưu thành công",
          warning: "Tên từ khái niệm không được để trống",
          warning2: "Vui lòng thêm ít nhất 1 từ mô tả",
          submitBtnHandle: {
            title:
              "Các từ mô tả được kiểm tra không được thêm vào, lưu sẽ bỏ các từ mô tả không được thêm vào",
            content:
              "Nhấp vào OK để quay lại trang chủ của từ khái niệm, xác nhận tiếp tục?",
          },
        },
      },
      smartTools: {
        btn: "Công cụ thông minh",
        title: {
          similarWords: "Khám phá từ liên quan",
          search: "Câu theo từ",
          semanticTags: "Nhãn ngữ nghĩa",
        },
        sentenceSearch: {
          placeholder: "Vui lòng nhập tìm kiếm từ khóa, hỗ trợ nhiều từ khóa",
          more: "Xem toàn văn",
          pageUp: "Trang trước",
          pageDown: "Tiếp theo",
          total: "Tổng cộng {0} bản ghi",
        },
        similarDiscover: {
          placeholder: "Vui lòng nhập từ khóa",
          copyed: "Đã sao chép",
          none: "Không có từ nào liên quan, vui lòng thử các từ khóa khác",
        },
        smartTags: {
          searchPlaceHolder: "Vui lòng nhập từ khóa hoặc câu",
          createSmartTag: "Mới xây dựng",
          createClusteringSmartTag: "Tạo với dữ liệu {0}",
          addFromKeyword: "Thêm nội dung tìm kiếm vào các câu tương tự",
          hit: "Đánh trúng",
          similar: "Tương tự",
          name: "Tên nhãn ngữ nghĩa",
          namePlaceHolder: "Vui lòng nhập tên nhãn ngữ nghĩa",
          recallWords: "Từ thu hồi",
          recallWordsPlaceHolder: "Vui lòng nhập từ khóa thu hồi",
          recallRegular: "Thu hồi chính quy",
          recallRegularPlaceHolder: "Vui lòng nhập quy tắc thu hồi",
          rules: "Quy tắc",
          standardSentences: "Câu chuẩn",
          standardSentencesPlaceHolder: "Vui lòng nhập câu chuẩn",
          similaSentences: "Câu tương tự",
          similaSentencesPlaceHolder: "Vui lòng nhập câu tương tự",
          notSaveError: "Vui lòng lưu nhãn ngữ nghĩa trước khi tìm kiếm",
          add: "Thêm",
          newPage: "Mở trên trang mới",
          querryError:
            "Có các trường tương tự trong danh sách tổng quát hóa (cấu hình nâng cao tương tự với các ràng buộc loại bỏ tái tạo)",
          detailTitle: "Chi tiết nhãn ngữ nghĩa",
        },
      },
      trainingCenter: {
        title: "Trung tâm đào tạo",
        edit: "Chỉnh sửa",
        delete: "Xóa",
        seleteAll: "Chọn tất cả",
        cancelCheckAll: "Hủy chọn tất cả",
        add: "Thêm",
        remove: "Xóa",
        save: "Lưu",
        cancel: "Hủy bỏ",
        fastTraining: {
          title: "Đào tạo nhanh",
          titleDes:
            "Đào tạo nhanh nhằm giúp các thẻ ngữ nghĩa nhanh chóng được khái quát hóa để đạt được 20 câu tương tự",
          semanticTags: "Nhãn ngữ nghĩa",
          semanticTagsPlaceholder: "Vui lòng nhập quá trình tìm kiếm từ khóa",
          standardSentence: "Câu chuẩn:{0}",
          similarSentencesPlaceholder:
            "Nhập câu tương tự để thêm hoặc tìm kiếm",
          similarSentencesAdd: "Thêm",
          similarSentencesSearch: "Tìm kiếm",
          similarSentences: "Các câu tương tự đã được thêm vào",
          trainingCount: "Số lượng đào tạo:{0}",
          similarSentencesRecommend: "Đề xuất các câu tương tự",
          noResp: "Không có thẻ ngữ nghĩa liên quan, vui lòng thay đổi từ khóa",
          similarSentencesModalTitle: "Tìm kiếm câu tương tự",
          moreInfo: "Xem bối cảnh",
          moreInfoBtn: "Bối cảnh",
          modalSearchType: {
            all: "Tất cả",
            customer: "Dịch vụ khách hàng",
            client: "Khách hàng",
          },
          repetWarning:
            "Các câu tương tự giống nhau đã tồn tại, vui lòng không thêm nhiều lần",
          repetRemoveTip:
            "Lưu nội dung trùng lặp trong các câu tương tự và nó đã được tự động loại bỏ",
        },
        trainingHistory: {
          title: "Lịch sử đào tạo",
          select: {
            date: "Chọn thời gian:",
            today: "Hôm nay",
            thisMonth: "Tháng này",
            lastMonth: "Tháng trước",
            thisQuarter: "Quý này",
            lastQuarter: "Quý trước",
            thisYear: "Năm nay",
            lastYear: "Năm ngoái",
            search: "Truy vấn",
            reset: "Đặt lại",
          },
          table: {
            time: "Thời gian",
            user: "Người điều hành",
            tag: "Nhãn ngữ nghĩa",
            similarCount: "Số lượng nhận dạng câu tương tự",
            deleteCount: "Xóa số câu",
            page: "Tổng số {0} mục,{1} trang",
          },
        },
        classifiedTraining: {
          title: "Phân loại đào tạo",
          tagsGrounp: "Nhóm nhãn",
          placeHolder: "Vui lòng chọn",
          cellTime: "Chọn thời gian",
          search: "Truy vấn",
          reset: "Đặt lại",
          classifiedFlag: "Tình hình đào tạo phân loại",
          num: "Giá trị số",
          gas: "Tỷ lệ phần trăm",
          noData: "Đào tạo chưa được phân loại",
          noDataHeight:
            "Dữ liệu chưa được phân loại chiếm tỷ lệ tương đối cao và cần đào tạo gấp",
          noDataSmall:
            "Dữ liệu chưa được phân loại chiếm tỷ lệ tương đối cao, nên đào tạo",
          noDataDays: "Lần thực hiện cuối cùng {0} ngày trước",
          training: "Đào tạo",
          start: "Bắt đầu",
          addClass: "Tăng phân loại",
          custom: "Sàng lọc nâng cao",
          marked: "Errata dữ liệu được đánh dấu",
          markedHeight: "Đã {0} ngày mà không có errata, đề nghị errata",
          markedSmall: "Đã {0} ngày chưa thực hiện errata, cần khẩn cấp errata",
          mark: "Errata",
          dataCheck: "Xác minh dữ liệu đã phân loại",
          dataCheckSmall: "Độ phân kỳ K-L cao hơn 0,5, nên tập luyện",
          dataCheckHeight: "Độ phân kỳ K-L cao hơn 0,8, cần đào tạo gấp",
          check: "Xác minh",
          history: "Đánh dấu lịch sử",
          historyTime:
            "Đánh dấu và sửa đổi hàng ngày sẽ tự động thực hiện đào tạo mô hình lúc 2 giờ sáng",
          today: "Đánh dấu hôm nay",
          article: "Sọc",
          none: "Không có",
          historyCheck: "Tổng số nhãn lịch sử",
          look: "Xem hồ sơ",
          historyluate: "Dữ liệu lịch sử được tính lại",
          startluate: "Phát hành tính toán lại",
          classData: "Dữ liệu phân loại",
          classStatus: "Tiến độ đào tạo",
          next: "Tiếp theo",
          prev: "Cái trước",
          finish: "Hoàn thành đào tạo",
          stop: "Kết thúc đào tạo",
          classDetail: "Chi tiết đào tạo",
          trainingNone: "Thẻ thông minh không được đào tạo",
        },
        classifiedTrainingHistory: {
          title: "Lịch sử đào tạo",
          time: "Chọn thời gian:",
          tag: "Loại nhãn:",
          user: "Nhà điều hành:",
          data: "Chọn nguồn dữ liệu:",
          search: "Truy vấn",
          searchBar: "Tìm kiếm:",
          searchBarPlaceholder:
            "Vui lòng nhập ID cuộc gọi/cuộc hội thoại ID tìm kiếm",
          reset: "Đặt lại",
          export: "Xuất",
          all: "Tất cả",
          select: {
            today: "Hôm nay",
            thisMonth: "Tháng này",
            lastMonth: "Tháng trước",
            thisQuarter: "Quý này",
            lastQuarter: "Quý trước",
            thisYear: "Năm nay",
            lastYear: "Năm ngoái",
            search: "Truy vấn",
            reset: "Đặt lại",
          },
          table: {
            user: "Nhà điều hành",
            date: "Thời gian hoạt động",
            data: "Nguồn dữ liệu",
            id: "ID cuộc gọi/ID cuộc hội thoại",
            tagType: "Nhóm thẻ thông minh",
            tagName: "Tên nhãn",
            state: "Trạng thái",
            sizeChangeText: "Hiển thị trên mỗi trang",
            page: "Tổng số {0} mục,{1} trang",
            call: "Cuộc gọi",
            dialogue: "Đối thoại",
          },
        },
        clusteringAnnotation: {
          title: "Nhãn cụm",
          left: { title: "Kết quả cụm" },
          right: {
            title: "Cụm câu",
            checkAll: "Chọn tất cả trang này",
            tagging: "Nhãn",
            detail: "Xem",
            meaningless: "Bỏ qua",
            meaninglessTips:
              "Các kết quả phân cụm bị bỏ qua sẽ không còn được hiển thị trong tương lai. Bạn có xác nhận rằng bạn muốn bỏ qua không?",
            cancel: "Hủy bỏ",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Quản lý khách hàng",
          tabTitle: "Khách hàng trung tâm dịch vụ khách hàng",
        },
        wechatClientManage: {
          title: "Khách hàng doanh nghiệp",
          table: {
            id: "ID khách hàng",
            name: "Khách hàng biệt danh",
            tag: "Nhãn khách hàng",
          },
        },
      },
      collectionRule: {
        title: "Quy tắc thu thập",
        form: {
          info: "Thông tin thu thập thông minh",
          infoPlaceholder: "Vui lòng chọn phân loại",
        },
        table: {
          name: "Tên trường",
          props: "Thuộc tính khách hàng",
          type: "Loại trường",
          desc: "Mô tả trường",
        },
      },
      dataAdd: {
        title: "Thêm dữ liệu",
        types: { auto: "Tự động thêm", manual: "Thêm thủ công" },
      },
      wechatStaff: {
        table: {
          name: "Tên",
          part: "Bộ phận trực thuộc",
          phone: "Số điện thoại",
          email: "Hộp thư",
          wechartId: "ID WeChat doanh nghiệp",
          status: "Tình trạng công việc",
        },
        info: "Vui lòng chọn bộ phận cần đồng bộ hóa dữ liệu trước",
        synSuccess: "Đồng bộ hóa thành công",
        init: "Khởi tạo",
        syn: "Đồng bộ hóa",
      },
      appealHistory: {
        appealTime: "Thời gian kháng cáo",
        cancelTime: "Thời gian hủy",
        reviewTime: "Thời gian xem xét",
        inspector: "Thanh tra chất lượng",
        result: "Kết quả kháng nghị",
        complainResult:
          "Tổng cộng {0} mục kiểm tra chất lượng đã được kháng cáo, {1} đã được kháng cáo thành công và {2} đã bị từ chối",
        reason: "Lý do kháng cáo",
        rejection: "Lý do bác bỏ",
        cancel: "Thu hồi",
        tip: "Xác nhận rằng bạn muốn rút đơn khiếu nại?",
      },
      appealSdk: {
        time: "Thông qua thời gian đối thoại",
        externalIdCall: "ID ghi âm cuộc gọi",
        externalIdText: "ID ghi âm cuộc trò chuyện",
        customerNickName: "Khách hàng biệt danh",
        readStatus: "Trạng thái xem",
        status: "Trạng thái kháng cáo",
        isManual: "Có kiểm tra chất lượng thủ công không",
        complainButtonInfo:
          "Được phép kháng cáo {0} lần, đã kháng cáo {1} lần và có thể kháng cáo {2} lần còn lại. {3} Có thể kháng cáo trước",
        submit: "Nộp đơn khiếu nại",
        complain: "Kháng nghị",
        allComplain: "Kháng nghị tất cả",
        pendingSubmit: "Đang chờ gửi",
        taskIds: "Nhiệm vụ kiểm tra chất lượng",
        graded: "Chấm điểm",
        complainSuccess: "Kháng nghị được gửi thành công",
        appealSdkIndex: { title: "Kết quả kiểm tra chất lượng thông minh" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Nhận thông tin danh sách bộ lọc không thành công, vui lòng kiểm tra kết nối mạng hoặc thử lại sau!",
      columns: "Cột",
      filter: "Sàng lọc",
      noSearchResult: "Không tìm thấy hồ sơ",
      pleaseSearch: "Vui lòng chọn một bộ lọc và cố gắng tìm kiếm",
      sift: "Sàng lọc",
      create: "Mới xây dựng",
      search: "Tìm kiếm",
      createTaskTitle: "Nhiệm vụ mới",
      title: "Tiêu đề",
      deadline: "Thời hạn",
      principal: "Người phụ trách",
      enqueueTime: "Thời gian gia nhập",
      computeError: "Tính toán không thành công, vui lòng đợi!",
      sum: "Tìm kiếm hòa bình",
      max: "Giá trị tối đa",
      min: "Giá trị tối thiểu",
      avg: "Trung bình",
      count: "Đếm",
      maxDate: "Giá trị tối đa (mới nhất)",
      minDate: "Giá trị tối thiểu (cũ nhất)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Không thể lấy được địa chỉ liên kết Insight, vui lòng thử lại sau!",
      },
      template: {
        learningReminder: "Nhắc nhở học tập",
        subscriptionDynamics: "Đăng ký tin tức",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Quản lý nhiệm vụ", chaneg: "Chuyển đổi" },
        qualityCheckTemplate: {
          text: "Mẫu kiểm tra chất lượng",
          collectText: "Bộ sưu tập mẫu",
          condition: { text: "Điều kiện kiểm tra chất lượng" },
          checkPoint: { text: "Cấu hình quy tắc" },
          automaticAdd: { text: "Tự động hóa quy trình" },
          manualAdd: { text: "Thêm thủ công" },
          roleConfig: { text: "Cấu hình nhân vật" },
          basicInfo: { text: "Thông tin cơ bản" },
          smartWordsLibrary: { text: "Từ điển thông minh" },
          grade: { text: "Xếp hạng kiểm tra chất lượng" },
          collectionRule: { text: "Quy tắc thu thập" },
          addData: { text: "Thêm dữ liệu" },
          collectionList: { text: "Danh sách thu thập" },
        },
        qualityCheckWorkbench: {
          text: "Bàn làm việc kiểm tra chất lượng",
          wechatText: "Bàn làm việc phân tích",
          informationCollectText: "Bàn làm việc thu thập",
          qualityInspectionList: {
            text: "Danh sách kiểm tra chất lượng",
            wechatText: "Danh sách phân tích",
            informationCollectText: "Danh sách thu thập",
          },
          condition: { text: "Danh sách kiểm tra chất lượng (đã bị loại bỏ)" },
          reviewList: { text: "Xem lại danh sách" },
          spotCheckList: { text: "Danh sách kiểm tra lấy mẫu" },
          caseBaseList: { text: "Thư viện trường hợp" },
          testSetList: { text: "Bộ kiểm tra chất lượng" },
        },
        statisticsAndReports: {
          text: "Thống kê và báo cáo",
          wholeReport: { text: "Báo cáo tổng thể" },
          groupReport: { text: "Báo cáo nhóm dịch vụ khách hàng" },
          agentReport: { text: "Báo cáo dịch vụ khách hàng" },
          customerServiceExecution: { text: "Phân tích dịch vụ khách hàng" },
          analysisOverview: { text: "Tổng quan về phân tích" },
          inspectionItemsAnalysis: {
            text: "Phân tích các hạng mục kiểm tra chất lượng",
          },
        },
        wechatQa: {
          text: "Kiểm tra chất lượng WeChat doanh nghiệp",
          task: { text: "Nhiệm vụ phân tích" },
          baseInfo: { text: "Thông tin cơ bản" },
          checkPoint: { text: "Phân tích cấu hình mô hình" },
          setting: { text: "Cấu hình phân tích WeChat doanh nghiệp" },
          automaticAdd: { text: "Tự động hóa quy trình" },
          manualAdd: { text: "Thêm thủ công" },
        },
        clientCenter: { text: "Trung tâm dịch vụ khách hàng" },
      },
      conditionDictionaries: {
        title: "Tất cả các điều kiện",
        searchDefaultText: "Vui lòng nhập ID hoặc tên",
        id: "ID",
        name: "Tên",
        conditionDetailedShow: "Mở ra",
        conditionDetailedHide: "Thu gọn",
        remark: "Mô tả:",
        checkRole: "Vai trò:",
        operator: "Toán tử:",
        operatorLogic: "Logic:",
      },
      audioTextView: {
        customer: "Khách hàng",
        agent: "Dịch vụ khách hàng",
        unsupportedFormat: "Định dạng này không được hỗ trợ",
      },
      checkPointClassifications: {
        add: "Danh mục mới",
        name: "Tên",
        enterToSave: "Nhấn phím Enter để lưu",
        highestScore: "Giới hạn điểm tối đa",
        minimumScore: "Điểm tối thiểu thấp hơn",
        defaultMinScore: "Mặc định là 0 điểm",
        minimumScoreTitle: "Điểm thấp hơn",
        beginScore: "Điểm tổng thể",
        beginScoreTitle: "Giá trị chấm điểm",
        scoreSetTip:
          "Giới hạn trên của điểm tối đa nhỏ hơn tổng của mỗi điểm, bạn có thể chọn:",
        ignoreExcessSpillover: "Bỏ qua tràn quá mức",
        automaticWeightCalculation: "Kế toán trọng lượng tự động",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Trường" },
    checkRange: {
      numberTipOne: "Không.",
      numberTipTwo: "Câu",
      numberTipThree: "Câu đến đầu tiên",
      conditionLimit: "Hạn chế điều kiện",
      roleLimit: "Giới hạn vai trò",
      contextLimit: "Giới hạn câu",
      notZero: "Giới hạn câu không hỗ trợ đầu vào 0",
      startMustLessThanEnd:
        "Vị trí câu bắt đầu phải nhỏ hơn vị trí câu kết thúc",
    },
    waveAudio: {
      play: "Chơi",
      pause: "Tạm dừng",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Vui lòng nhập",
      pleaseEnterRegular: "Vui lòng nhập biểu thức chính quy",
      pleaseEnterGrammar: "Vui lòng nhập quy tắc ngữ pháp",
      pleaseEnterTestText: "Vui lòng nhập nội dung cần xác minh",
      pleaseEnterLoudness: "Vui lòng nhập decibel to",
      pleaseEnterRangeAbility: "Vui lòng nhập biên độ thay đổi",
      afterHit: "Kiểm tra sau khi đánh",
      pleaseEnterContext: "Vui lòng nhập câu lệnh tham chiếu",
      wordNumber: "Khoảng cách từ",
      intervalSecond: "Khoảng thời gian lớn hơn",
      minute: "Mỗi phút",
      wordUnit: "Chữ",
      test: "Kiểm tra",
      testRegular: "Kiểm tra biểu thức chính quy",
      testGrammar: "Kiểm tra ngữ pháp",
      testGrammarError: "Bài kiểm tra ngữ pháp không thành công",
      regularError: "Biểu thức chính quy sai, vui lòng nhập lại",
      pleaseEnterSemanticSentence: "Vui lòng nhập câu cần phát hiện ngữ nghĩa",
      pleaseEnterAssociate:
        "Vui lòng tham khảo hướng dẫn ở bên phải để xây dựng biểu thức dữ liệu theo dõi",
      querySemanticTags: "Chọn nhãn ngữ nghĩa",
      reQuerySemanticTags: "Chọn lại nhãn ngữ nghĩa",
      semanticTags: "Nhãn ngữ nghĩa",
      standardSentence: "Câu chuẩn",
      semanticName: "Nhãn ngữ nghĩa",
      sentence: "Câu chuẩn",
      similarity: "Sự tương đồng",
      actions: "Hoạt động",
      tipOne: "Không tìm thấy nhãn phù hợp,",
      tipTwo: "Thêm một thẻ ngữ nghĩa mới",
      notLessThan: "Không nhỏ hơn",
      notGreaterThan: "Không lớn hơn",
      ignoreLessThan: "Bỏ qua ít hơn",
      wordUnitSet: "Câu từ",
      speedWordNotNull:
        "Số lượng từ ít nhất và số lượng từ tối đa không được để trống",
      speedWordMinValueLessThanMaxValue:
        "Số lượng từ tối thiểu để phát hiện tốc độ nói không được lớn hơn số lượng từ tối đa",
      speechGrabbingIntervalTip: "Khoảng thời gian lấy> =",
      speechLengthTip: "Câu dài> =",
      msUnit: "ms",
      sUnit: "Giây",
      grabIntervalNotNull: "Khoảng thời gian lấy không được để trống",
      grabLengthNotNull: "Câu dài không thể trống",
      pleaseSelectEntity: "Vui lòng chọn thực thể thông tin",
      pleaseSelectOperator: "Vui lòng chọn hoạt động",
      pleaseSelectBasicInfo: "Vui lòng chọn thông tin cơ bản",
      pleaseEnterFixedValue: "Vui lòng nhập giá trị cố định",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Phù hợp với thành công",
      matchError: "Trận đấu thất bại",
      mindetectionStatement: "Câu lệnh phát hiện tối thiểu",
      conceptWordholder: "Vui lòng chọn từ khái niệm",
      elementHolder: "Vui lòng nhập một yếu tố",
      associateMedol: { tabs: { data: "Dữ liệu", operator: "Toán tử" } },
      repet: "Lặp lại",
      bout: "Lần",
      ignore: "Bỏ qua ít hơn",
      ignoreAffix: "Câu từ",
      associateData: "Dữ liệu theo đường",
      pretreatmentData: "Xử lý trước dữ liệu",
      reviewTaskData: "Xem lại dữ liệu nhiệm vụ",
      taskData: "Kiểm tra chất lượng nhiệm vụ dữ liệu",
      businessData: "Hồ sơ kinh doanh",
      functionOperator: "Toán tử hàm",
      operation: "Toán tử hoạt động",
      notAnyMatch: "Khi bạn bỏ lỡ bất kỳ vấn đề nào:",
      times: "Số lần xuất hiện",
      newAssociateDataTip: "Nếu giá trị là loại số, hãy sử dụng chức năng cũ",
    },
    conditionGather: {
      cycle: "Chu kỳ",
      rlue: "Quy tắc",
      delete: "Xóa",
      sampling: "Trích xuất",
      item: "Sọc",
      percent: "%",
      samplingSame: "Số lượng rút ra như nhau cho mỗi dịch vụ khách hàng",
      samplingRecheck: "Cho phép trích xuất nhiều lần các mục đã được lấy mẫu",
      dateUnit: "Số",
      samplingTip:
        "Các quy tắc khác nhau ghi đè cùng một cuộc gọi sẽ không được trích xuất lặp lại và các bản ghi đã được trích xuất nhưng không được kiểm tra sẽ không được trích xuất lại.",
      add: "Thêm quy tắc",
    },
    ruleGather: {
      title: "Quy tắc",
      screeningCondition: "Điều kiện sàng lọc",
      addRule: "Thêm quy tắc",
      inspector: "Thanh tra chất lượng",
      ruleTip:
        "Chế độ phân bổ ưu tiên quy tắc, phân bổ từ trên xuống dưới, không phân bổ lặp lại và bất kỳ mục quy tắc nào trong quy tắc hoặc không khớp sẽ được phân bổ ngẫu nhiên",
      moveUp: "Di chuyển lên",
      moveDown: "Di chuyển xuống",
      top: "Hàng đầu",
    },
    pushRulesGather: {
      title: "Quy tắc",
      delete: "Xóa",
      newPush: "Đẩy mới",
      newRule: "Thêm quy tắc",
      sampling: "Trích xuất",
      item: "Sọc",
      percent: "%",
      samplingSame: "Số lượng rút ra như nhau cho mỗi dịch vụ khách hàng",
      dateUnit: "Số",
      screeningCondition: "Điều kiện sàng lọc",
      email: "Hộp thư",
      emailPlaceholder: "Nhập hộp thư và phân đoạn bằng dấu phẩy",
      updateType: "Cập nhật loại",
      pushDetail: "Chi tiết đẩy",
      pushUrl: "URL đẩy",
      pushWay: "Loại đẩy",
      pushMethod: "Phương thức đẩy",
      pushContentType: "Định dạng dữ liệu",
      pushData: "Đẩy dữ liệu",
      additionalData: "Dữ liệu bổ sung",
      inspectionType: "Loại hình kiểm tra chất lượng",
      all: "Tất cả",
    },
    taskAdditionalData: {
      newData: "Thêm dữ liệu",
      delete: "Xóa",
      placeholder: "Vui lòng nhập tên",
      dataPlaceholder: "Vui lòng nhập nội dung",
    },
    UdeskQaReact: {
      loadSdkError: "Tải SDK không thành công, vui lòng thử lại sau!",
    },
    gearOptionList: { evaluation: "Đánh giá" },
    tableComponentTransfer: {
      leftTitle: "Mục danh sách",
      rightTitle: "Mục hiển thị",
    },
    kmTinymce: {
      link: "Chèn/chỉnh sửa liên kết",
      modalTitle: "Chèn liên kết",
      href: "Địa chỉ",
      text: "Hiển thị văn bản",
      title: "Tiêu đề",
      target: "Cách mở",
      none: "Không có",
      newWindow: "Mở trong cửa sổ mới",
      buttonStyle: "Kiểu nút",
      close: "Đóng cửa",
      open: "Mở",
      answerMessage: "Nhảy/Gửi tin nhắn",
      answerMessageTitle: "Nút gửi tin nhắn",
      answerMessageText: "Hiển thị văn bản",
      answerMessageContent: "Nội dung tin nhắn",
      answerMessageButtonStyle: "Kiểu nút",
      answerMessageTips:
        "Lưu ý: Nút gửi tin nhắn chỉ hỗ trợ các kênh web, vui lòng cấu hình cẩn thận",
      answerSwitchStaff: "Nhảy/xoay thủ công",
      answerSwitchStaffTitle: "Chuyển sang nút nhân tạo",
      answerSwitchStaffText: "Hiển thị văn bản",
      answerSwitchStaffRule: "Quy tắc nút",
      answerSwitchStaffButtonStyle: "Kiểu nút",
      answerSwitchStaffTips:
        "Lưu ý: Chuyển sang nút thủ công chỉ hỗ trợ kênh web, vui lòng cấu hình cẩn thận",
      textButton: "Nút văn bản",
      borderButton: "Nút có khung",
      message: "Gửi tin nhắn",
      switchStaff: "Chuyển sang nhân tạo",
      messageError: "Nội dung nút không được vượt quá 10 từ",
      messageIsEmptyError: "Nội dung nút không được để trống",
      okText: "Chắc chắn",
      cancelText: "Hủy bỏ",
      video: "Video",
      uploadVideo: "Tải lên video",
      uploadText: "Chỉ hỗ trợ định dạng MP4, kích thước không quá 20M",
      videoEmptyOrUploading: "Video trống hoặc đang tải lên",
      customEmoji: "Biểu cảm",
    },
    massageConfiguration: {
      pushTarget: "Mục tiêu thông báo",
      customizePushTarget: "Mục tiêu đẩy tùy chỉnh",
      sysUser: "Người dùng hệ thống hiện tại",
      sysRole: "Vai trò hệ thống hiện tại",
      emailPushTarget: "Gửi mục tiêu",
      targetEmailsPlaceholder:
        "Vui lòng nhập email và phân tách bằng dấu phẩy tiếng Anh",
      subject: "Tên thư",
      SDKID: "ID nhận dạng SDK",
      content: "Nội dung thông báo",
      sdkPushType: "Chương trình đẩy",
      sdkPushURL: "URL đẩy",
      pushData: "Đẩy dữ liệu",
      sdkPushWay: "Phương thức đẩy",
      dataType: "Định dạng dữ liệu",
      additionalData: "Dữ liệu bổ sung",
      name: "Tên tin nhắn",
      filter: "Điều kiện sàng lọc",
      triggerType: "Cơ chế kích hoạt",
      pushCycle: "Thời gian cố định tần số",
      pushContent: "Nội dung báo cáo",
      qualityList: "Danh sách kết quả kiểm tra chất lượng",
      designated: "Chỉ định nhân sự",
      messageType: "Loại tin nhắn",
    },
    taskCard: {
      template: {
        cancelCollection: "Hủy bộ sưu tập",
        collection: "Bộ sưu tập",
      },
    },
    semanticTagsSelect: { index: { standardSentence: "Câu chuẩn:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Bộ sưu tập",
          calls: "Một cuộc gọi",
          createdBy: "Người tạo:",
          createdOn: "Tạo thời gian:",
        },
        fileItem: {
          uIProvidesIcon: "Ui cung cấp icon",
          minute: "Phút",
          callClip: "Clip cuộc gọi",
          start: "Bắt đầu",
          End: "Kết thúc",
          employees: "Nhân viên:",
          Customer: "Khách hàng:",
          sessionTime: "Thời gian phiên:",
          reasonForRecommendation: "Lý do đề xuất:",
          addedBy: "Người thêm:",
          addTime: "Thêm thời gian:",
        },
        modalAddFile: {
          caseTitle: "Tiêu đề trường hợp",
          addSomeClipsToTheCaseLibrary:
            "Thêm một số mảnh vào thư viện trường hợp",
          addToCaseBase: "Thêm vào thư viện trường hợp",
          storedIn: "Được lưu trữ trong",
          pleaseSelect: "Vui lòng chọn",
          reasonsForRecommendation: "Lý do đề xuất",
          pleaseEnter: "Vui lòng nhập",
        },
      },
      shareModal: {
        index: {
          customer: "Khách hàng:",
          employees: "Nhân viên:",
          timeliness: "Tính kịp thời:",
          recordingLinkValidWithinDays: "Liên kết ghi âm hợp lệ trong ngày",
          sharingInstructions: "Chia sẻ hướng dẫn:",
          shareSuccess: "Chia sẻ thành công!",
          cancel: "Hủy bỏ",
          share: "Chia sẻ",
          copySucceeded: "Sao chép thành công!",
          copyShareLink: "Sao chép liên kết chia sẻ",
          shareWithColleagues: "Chia sẻ với đồng nghiệp",
          shareWithCustomers: "Chia sẻ với khách hàng",
          staff: "Nhân viên",
          selectColleagues: "Chọn đồng nghiệp",
          pleaseSelectColleaguesToShare: "Vui lòng chọn đồng nghiệp để chia sẻ",
          pleaseEnterTheSharingDescription: "Vui lòng nhập mô tả chia sẻ",
          taskName: "Nhiệm vụ:",
          recordingShareValidWithinDays: "Chia sẻ liên kết hợp lệ trong ngày",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Không thể tìm thấy tập tin!",
          sale: "Bán hàng:",
          customer: "Khách hàng:",
          topicDetection: "Phát hiện chủ đề:",
          keyEvents: "Sự kiện quan trọng:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Chọn đồng nghiệp",
          helpContent: "Nội dung trợ giúp",
          visibleRange: "Phạm vi có thể nhìn thấy",
          seekCounseling: "Tìm kiếm tư vấn",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Tìm kiếm lời khuyên", concatText: "Hướng tới" },
      },
      customerInsight: {
        index: {
          customerInformation: "Thông tin khách hàng",
          customerInsight: "Thông tin chi tiết của khách hàng",
          customerLabel: "Nhãn khách hàng",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Lời khuyên",
          objectionHandlingScripts: "Xử lý phản đối",
          productSellingPointScripts: "Điểm bán sản phẩm",
          knowledgePointRecommendation: "Đề xuất điểm kiến thức",
          relatedKnowledgePoints: "Điểm kiến thức liên quan",
          recommendedCases: "Trường hợp được đề xuất",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Lời khuyên",
          caseRecommendation: "Đề xuất trường hợp",
          hit: "Đánh trúng",
          miss: "Trượt",
          supervisionItem: "Mục giám sát",
          implementation: "Thực hiện",
          reInspection: "Kiểm tra lại",
          supervisionItemSettings: "Cài đặt mục giám sát",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Cuộc gọi điện thoại",
          enterpriseWeChatCall: "Cuộc gọi vi mô doanh nghiệp",
          enterpriseMicroConversation: "Phiên vi mô doanh nghiệp",
          open: "Mở",
          close: "Đóng cửa",
          conversationScenario: "Cảnh đàm thoại",
          monitorConversationChannels: "Giám sát các kênh phiên",
          permanentlyValid: "Hiệu quả vĩnh viễn",
          permanentlyInvalid: "Không hợp lệ vĩnh viễn",
          customValidity: "Thời hạn hiệu lực tùy chỉnh",
          termOfValidity: "Thời hạn hiệu lực",
          newRating: "Xếp hạng mới",
          whenConversation: "Khi phiên",
          hit: "Đánh trúng",
          miss: "Trượt",
          time: "Thời gian",
          rated: "Xếp hạng là",
          qualified: "Đủ điều kiện",
          unqualified: "Không đủ tiêu chuẩn",
          modifiedSuccessfully: "Sửa đổi thành công",
          classificationName: "Tên phân loại",
          supervisionObject: "Đối tượng giám sát",
          entryintoforceTime: "Thời gian có hiệu lực",
          supervisionItemRating: "Xếp hạng mục giám sát",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Vui lòng kiểm tra xem xếp hạng mục giám sát có đúng không",
          ratingRules: "Quy tắc xếp hạng",
          settingOfRatingRulesForSupervisionItems:
            "Cài đặt quy tắc xếp hạng mục giám sát",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Sự kiện quan trọng",
          supervisionItem: "Mục giám sát",
          conversationScenario: "Cảnh đàm thoại",
          customerLabel: "Nhãn khách hàng",
          reDetectionRules: "Quy tắc phát hiện lại",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Tải xuống bản ghi âm",
          downloadText: "Tải văn bản",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Tải lên hình ảnh",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Vui lòng chọn hình ảnh có kích thước không quá 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Hoặc",
            and: "Và",
            wrong: "Không",
            matchTo: "Phù hợp với",
            rule: "Quy tắc",
            satisfactionOperator: "Thỏa mãn toán tử",
            customerLabel: "Nhãn khách hàng",
            keyEvents: "Sự kiện quan trọng",
            knowledgePoints: "Điểm kiến thức",
            sessionLabel: "Nhãn phiên",
            supervisionItem: "Mục giám sát",
          },
          linkExplorer: { linkInTheText: "Liên kết trong văn bản:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound: "Không tìm thấy thành phần tùy chỉnh:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "Tùy chỉnh" },
      dropdownMonit: {
        updateSucceeded: "Cập nhật thành công",
        areYouSureToDelete: "Chắc chắn xóa?",
        custom: "Tùy chỉnh",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Lưu cấu hình báo cáo hiện tại === Biểu mẫu",
        saveCurrentReportConfiguration: "Lưu cấu hình báo cáo hiện tại",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Vui lòng nhập tên cài đặt chung, độ dài tối đa là 20!",
        preservation: "Lưu",
      },
      template: {
        saveCurrentReportConfiguration: "Lưu cấu hình báo cáo hiện tại",
        updateCurrentReportConfiguration: "Cập nhật cấu hình báo cáo hiện tại",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Thông tin đơn đặt hàng công việc",
        workOrder: "Danh sách công việc",
        triggerScenario: "Cảnh kích hoạt",
        associatedCallConversation: "Cuộc gọi/cuộc trò chuyện liên quan",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Kiểm tra với văn bản cuộc gọi/hội thoại hiện tại",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "Không.", day: "Ngày" },
      businessSummary: {
        normal: {
          equalTo: "Bằng",
          notEqualTo: "Không bằng",
          contain: "Chứa",
          excluding: "Không chứa",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Tôi là tôi, pháo hoa khác nhau @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Xin chào, tôi là thông báo cảnh báo sớm kiểm tra chất lượng robot, vì tôi đã thêm vào nhóm vào ngày 17 tháng 10",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Tôi là tôi, pháo hoa khác nhau @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Xin chào, tôi là thông báo cảnh báo sớm kiểm tra chất lượng robot, vì tôi đã thêm vào nhóm vào ngày 17 tháng 10",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Chủ đề truyền thông",
          conversationSummary: "Tóm tắt phiên",
          serviceSuggestions: "Đề xuất dịch vụ",
        },
        template: { smartTags: "Nhãn thông minh" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Bấm để biết thêm" },
      schema: {
        textRobot: "Robot văn bản",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Hộ tống huấn luyện viên, câu hỏi và câu trả lời tài liệu, đối thoại theo nhiệm vụ, câu hỏi và câu trả lời bảng, câu hỏi và câu trả lời bản đồ",
        capitalRetentionRobot: "Robot giữ vốn",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Chiến lược đặt câu hỏi và giữ lại phong phú, giao tiếp nhân văn cao, đảm bảo tỷ lệ giữ lại cao và chuyển đổi đầu mối hiệu quả và tự động",
        outboundRobot: "Robot gọi đi",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Hoàn thành đơn đặt hàng công việc, thăm lại, nghiên cứu mức độ hài lòng về dịch vụ, cảnh báo lỗi vận hành và bảo trì, nhắc nhở giao hàng, thông báo hoạt động thương mại điện tử, cuộc hẹn của ứng viên, giúp tự động hóa kinh doanh và nâng cao hiệu quả kinh doanh",
        incomingRobot: "Robot thở vào",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Tích hợp linh hoạt các đơn đặt hàng công việc, đơn đặt hàng và các hệ thống kinh doanh khác để thực hiện tự động hóa quy trình; nhận dạng tùy chỉnh mô hình ngữ nghĩa giọng nói, hiệu quả kinh doanh ngay lập tức; thực thể (số điện thoại di động, số lượng đặt hàng, thời gian đặt hàng, địa chỉ,...) Xác định, hỗ trợ nhanh chóng cho các doanh nghiệp phức tạp",
        intelligentQualityInspection: "Kiểm tra chất lượng thông minh",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Kiểm tra chất lượng đầy đủ, kịp thời và hiệu quả, tỷ lệ chính xác 85%",
        intelligentSessionAnalysis: "Phân tích phiên thông minh",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Hiểu biết sâu sắc về những thiếu sót của doanh nghiệp, lắng nghe tiếng nói của khách hàng và cải thiện chuyển đổi tiếp thị",
        enterpriseWechatConversationAnalysis:
          "Phân tích phiên WeChat doanh nghiệp",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Xác định hiệu quả các cơ hội kinh doanh và rủi ro tiềm năng, tăng tỷ lệ chuyển đổi tiếp thị lên 20%",
        assistant: "Trợ lý chỗ ngồi",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Trợ lý ghế có thể đảm bảo rằng dịch vụ khách hàng mới làm quen hoàn thành dịch vụ và cải thiện chất lượng cuộc gọi dịch vụ khách hàng lành nghề. Hãy để mọi chỗ ngồi đều là con át chủ bài của bạn",
        digitalPeople: "Người kỹ thuật số",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          'Giao tiếp nhân hóa "mặt đối mặt", hỗ trợ tương tác đa phương thức âm thanh, văn bản, video và cảm ứng, hiện thực hóa trải nghiệm giao tiếp nhập vai, nâng cao hình ảnh thương hiệu và trải nghiệm dịch vụ người dùng',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Kiến thức AI Trung tâm Đài Loan · Tìm kiếm doanh nghiệp",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Tìm kiếm kiến thức, chia sẻ kiến thức, tìm kiếm tài liệu, tìm kiếm docking cơ sở dữ liệu",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Trung tâm Kiến thức AI · Cơ sở Kiến thức KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Cung cấp quản lý toàn bộ vòng đời kiến thức, bảo trì thống nhất robot và cơ sở kiến thức IM, hỗ trợ kết nối cơ sở kiến thức của bên thứ ba, mẫu kiến thức, kiến thức nguyên tử và các chức năng mạnh mẽ khác để mang lại trải nghiệm quản lý kiến thức thuận tiện hơn",
        aIKnowledgeCenterKnowledgeMap:
          "Trạm Trung tâm Kiến thức AI · Bản đồ Kiến thức",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "Xây dựng bản đồ một cửa tích hợp kiến thức doanh nghiệp, hình ảnh lớn hiển thị chi tiết kiến thức và khả năng kết nối để tạo ra trải nghiệm tùy chỉnh",
        modularDevelopmentPlatformOriginalEngineASR:
          "Nền tảng phát triển mô-đun · Động cơ tim ban đầu · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Sử dụng thuật toán đầu cuối để đạt được trải nghiệm hiệu ứng tùy chỉnh kinh doanh, tỷ lệ nhận dạng cảnh tự nhiên vượt quá 90% và nhận dạng từ vựng được cá nhân hóa vượt quá 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Nền tảng phát triển mô-đun · Tiếp thị thông minh",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Sử dụng các thuật toán học sâu, kết hợp với chân dung người dùng, nhận ra đề xuất chính xác của sản phẩm và cải thiện chuyển đổi bán hàng",
        modularDevelopmentPlatformUserPortrait:
          "Nền tảng phát triển mô-đun · Chân dung người dùng",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Xây dựng chính xác chân dung người dùng dựa trên dữ liệu người dùng và dữ liệu hội thoại, mô tả đầy đủ các đặc điểm của khách hàng",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Nền tảng phát triển mô-đun · Đề xuất thông minh",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Theo chân dung người dùng và chân dung sản phẩm, tự động đề xuất các sản phẩm mà người dùng có thể quan tâm và nhận ra các đề xuất được cá nhân hóa cho hàng nghìn người",
        modularDevelopmentPlatformNLPPAAS:
          "Nền tảng phát triển mô-đun · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Cung cấp các khả năng cơ bản NLP với độ chính xác cao, bao gồm các khả năng cơ bản với các mức độ chi tiết khác nhau như từ, câu và bài báo",
      },
    },
    qualityScore: {
      index: {
        show: "Hiển thị:",
        displayBySecondaryClassification: "Hiển thị theo phân loại phụ",
        showByRule: "Hiển thị theo quy tắc",
        pleaseEnterClassificationruleName:
          "Vui lòng nhập tên phân loại/quy tắc",
        clickCopy: "Bấm để sao chép",
        hitDetailsOfOperatorsInRules:
          "Chi tiết truy cập toán tử trong quy tắc:",
        hit: "Đánh trúng",
        miss: "Trượt",
        filter: "Sàng lọc:",
        clickToViewDetails: "Bấm để xem chi tiết",
      },
      components: {
        flowNodeList: {
          branch: "Điểm",
          pleaseSelect: "Vui lòng chọn",
          viewTheCompleteProcess: "Xem toàn bộ quy trình",
          thereIsCurrentlyNoProcessAvailable: "Không có quy trình",
        },
        pointItem: { xiangHit: "Mục truy cập" },
        flowNodeListModal: {
          index: { completeProcess: "Quy trình hoàn chỉnh" },
        },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Nếu nhiệm vụ kiểm tra chất lượng của quy trình đã thay đổi, vui lòng kháng cáo lại.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Dữ liệu này đã được xóa thường xuyên",
        subject: "Chủ đề:",
        description: "Mô tả:",
      },
      components: {
        addToTask: {
          index: {
            addToQualityInspectionTask: "Thêm vào nhiệm vụ kiểm tra chất lượng",
          },
        },
      },
    },
    relatedDetail: {
      index: {
        associatedCallConversation: "Cuộc gọi/cuộc trò chuyện liên quan",
      },
    },
    samplingTarget: {
      index: {
        time: "Thời gian",
        samplingQuantityIsLessThan: "Số lượng kiểm tra lấy mẫu thấp hơn",
        alertWhen: "Cảnh báo sớm",
        everyday: "Hàng ngày",
        weekly: "Mỗi tuần",
        monthly: "Hàng tháng",
        samplingInspectionTargetSavedSuccessfully:
          "Mục tiêu kiểm tra lấy mẫu được lưu thành công",
        personnel: "Nhân viên",
        eachCustomerService: "Mỗi dịch vụ khách hàng",
        cycle: "Chu kỳ",
        samplingInspectionTarget: "Mục tiêu kiểm tra lấy mẫu",
        strip: "Sọc",
        alertSettings: "Cài đặt cảnh báo",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Dịch vụ khách hàng",
        complete: "Hoàn thành",
        Objective: "/Mục tiêu",
        everyday: "Hàng ngày",
        weekly: "Mỗi tuần",
        monthly: "Hàng tháng",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Tỷ lệ tương tác trò chuyện nhóm",
        maximumResponseIntervalTime: "Trả lời khoảng thời gian tối đa",
        numberOfPreemptiveCalls: "Số lần lấy",
        numberOfMessages: "Số lượng tin nhắn",
        strip: "Sọc",
        proportionOfMessages: "Tỷ lệ tin nhắn",
        speechPercentage: "Tỷ lệ nói",
        longestCustomerTalk: "Bài phát biểu của khách hàng dài nhất",
        numberOfIndepthDiscussions: "Số lượng thảo luận chuyên sâu",
        second: "Lần",
        maximumDurationOfContinuousSpeech: "Thời gian nói liên tục dài nhất",
        questionFrequency: "Tần suất đặt câu hỏi",
        customer: "Khách hàng",
        keyEvents: "Sự kiện quan trọng:",
        opportunity: {
          index: {
            generationFailed: "Tạo thất bại",
            generateOpportunityPoints: "Tạo điểm cơ hội",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Tạo thất bại",
            generateSummary: "Tạo tóm tắt",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Chắc chắn xóa?",
        add: "Thêm",
        opportunityPoints: "Điểm cơ hội",
        conversationSummary: "Tóm tắt phiên",
        cancel: "Hủy bỏ",
        determine: "Chắc chắn",
        edit: "Chỉnh sửa",
        establish: "Tạo",
        conversationalInsights: "Thông tin chi tiết hội thoại",
        keyEvents: "Sự kiện quan trọng",
        numKeyEvents: "Sự kiện quan trọng",
        questionAnalysis: "Phân tích câu hỏi",
        find: "Khám phá",
        questions: "Câu hỏi",
        conversationAction: "Hành động đàm thoại",
        customer: "Khách hàng",
        sale: "Bán hàng",
        sessionLabel: "Cảnh đàm thoại",
        employeeQuestions: "Nhân viên đặt câu hỏi",
        customerQuestions: "Câu hỏi của khách hàng",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Khách hàng",
        customerService: "Dịch vụ khách hàng",
        replyTo: "Trả lời",
        addressee: "Người nhận:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Xác định lại",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Nhận dạng lại sẽ tiêu tốn thời gian chuyển tiếp, hãy cẩn thận",
        determine: "Chắc chắn",
        executionSuccessful: "Thực hiện thành công",
        addToQualityInspectionTask: "Thêm vào nhiệm vụ kiểm tra chất lượng",
        batchOperation: "Hoạt động hàng loạt",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Thêm thành công",
        addToQualityInspectionTask: "Thêm vào nhiệm vụ kiểm tra chất lượng",
        qualityInspectionTask: "Nhiệm vụ kiểm tra chất lượng",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Phát hiện vai trò",
        detectionRange: "Phạm vi phát hiện",
        sentence: "Câu",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "Dữ liệu này đã được xóa thường xuyên",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Quay lại hệ thống kinh doanh" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Vấn đề trích xuất",
        businessSummary: "Tóm tắt kinh doanh",
        keyWord: "Từ khóa",
      },
      components: {
        problemExtraction: {
          no: "Không.",
          customerServiceDidNotAnswer: "Dịch vụ khách hàng chưa trả lời",
          customerServiceHasAnswered: "Dịch vụ khách hàng đã trả lời",
          problemUnresolved: "Vấn đề chưa được giải quyết",
          theProblemHasBeenResolved: "Vấn đề đã được giải quyết",
          customerIssue: "Câu hỏi của khách hàng:",
          customerServiceAnswer: "Câu trả lời dịch vụ khách hàng:",
          reExtractQuestions: "Trích xuất lại vấn đề",
          clickToExtractQuestions: "Nhấp vào vấn đề trích xuất",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Tên chân dung",
            backgroundDescription: "Mô tả nền",
            customerLabel: "Nhãn khách hàng",
            labelManagement: "Quản lý nhãn",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: {
              index: { newlyBuild: "Mới xây dựng" },
            },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Theo dõi cài đặt" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Thêm câu hỏi",
              remove: "Xóa",
              addQuestioning: "Thêm theo dõi",
              section: "Không.",
              secondInquiry: "Theo dõi",
              questioningScore: "Theo dõi điểm số",
            },
          },
        },
        index: {
          accordingToRules: "Theo quy tắc",
          byScore: "Điểm theo",
          whenMissRule: "Khi bỏ lỡ quy tắc",
          whenConductingQuestioning: "Khi nào, hãy hỏi",
          pleaseSelectARule: "Vui lòng chọn quy tắc",
          ruleCannotBeEmpty: "Quy tắc không được để trống",
          currentScriptScore: "Điểm khi nói ≤",
          pleaseEnterTheScore: "Vui lòng nhập điểm số",
          scoreCannotBeEmpty: "Điểm không thể trống",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Người kể chuyện:" },
          pPT: { slide: "Trình chiếu" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Nhận xét kỹ năng",
              script: "Lời nói",
              semantics: "Ngữ nghĩa",
              violatingWords: "Từ vi phạm",
              speechSpeed: "Tốc độ nói",
              emotion: "Cảm xúc",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Nhận xét kỹ năng",
              regenerate: "Tái tạo",
              generate: "Tạo",
              hitSituation: "Tình huống đánh",
              noSemanticTagsMatched: "Không khớp với nhãn ngữ nghĩa",
              matchToSemanticTags: "Phù hợp với các thẻ ngữ nghĩa",
              appealToBeSubmitted: "Kháng nghị sẽ được gửi",
              ratingHasBeenReviewed: "Xếp hạng đã được xem xét",
              ratingAppealInProgress: "Đánh giá khiếu nại",
              expressionScore: "Điểm biểu hiện",
              moodParticlesTimes: "Từ ngữ, {0} lần",
              changeTheModalParticlesTo: "Thay đổi từ ngữ thành",
              second: "Lần",
              interrogation: "Theo dõi",
              questioningWithoutScoring: "Không tính điểm",
              branch: "Điểm",
              matchingRate: "Tỷ lệ khớp",
              ratingAppeal: "Khiếu nại chấm điểm",
              changeTo: "Thay đổi thành",
              wordPerMinute: "Từ mỗi phút",
              completeScript: "Lời nói hoàn chỉnh",
              incompleteScript: "Lời nói không đầy đủ",
              semanticHit: "Lượt truy cập ngữ nghĩa",
              semanticMisses: "Ngữ nghĩa",
              scriptViolation: "Vi phạm lời nói",
              modified: "Đã sửa đổi",
              notChanged: "Không sửa đổi",
              keyWord: "Từ khóa",
              semanticLabels: "Nhãn ngữ nghĩa",
              semanticMatchingRate: "Tỷ lệ khớp ngữ nghĩa",
              violatingWords: 'Từ vi phạm"',
              delete: "Xóa",
              unmatched: "Không khớp",
              matching: "Phù hợp",
              matchTo: "Phù hợp với",
              notMatchedTo: "Không khớp với",
              scriptScore: "Điểm kỹ năng nói",
              standardScript: "Kỹ năng nói tiêu chuẩn",
              emotionalScore: "Điểm số cảm xúc",
              speechSpeedScore: "Điểm tốc độ nói",
              viewResolution: "Xem phân tích",
              nodeConfiguration: "Cấu hình nút",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Thời gian trả lời câu hỏi này quá giờ, nội dung trả lời sẽ tự động được gửi",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Thời gian trả lời câu hỏi này quá giờ, nội dung trả lời sẽ tự động được gửi",
          },
          correctBtn: {
            saveSuccessful: "Lưu thành công",
            errorCorrection: "Sửa lỗi",
            cancel: "Hủy bỏ",
            preserve: "Lưu",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Quy tắc ghi bàn",
            cancel: "Hủy bỏ",
            determine: "Chắc chắn",
          },
          scoringRuleItem: {
            whole: "Tất cả",
            arbitrarily: "Tùy ý",
            pleaseEnterARuleName: "Vui lòng nhập tên quy tắc",
            standardScript: "Kỹ năng nói tiêu chuẩn",
            if: "Nếu",
            and: "Và",
            or: "Hoặc",
            thenScore: "Sau đó ghi điểm",
            pleaseEnterAScore: "Vui lòng nhập điểm",
            bonusPoints: "Điểm thưởng",
            minusPoints: "Giảm điểm",
            hit: "Đánh trúng",
            misses: "Trượt",
            keyWord: "Từ khóa",
            semantics: "Ngữ nghĩa",
          },
          title: {
            ruleValidation: "Xác minh quy tắc",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Quy tắc tính điểm (tổng điểm hiện tại là",
            points: "Điểm)",
            editRules: "Chỉnh sửa quy tắc",
          },
        },
      },
      userTagsSelect: {
        index: { noLabelsCurrentlyAvailable: "Không có nhãn" },
      },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Mục tiêu truyền thông",
              scoringCriteria: "Tiêu chí chấm điểm",
            },
          },
        },
        index: {
          generating: "Đang tạo",
          generateDialogueFramework: "Tạo khung đối thoại",
          theme: "Chủ đề",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Lời nhắc nhở",
            generatePromptLanguage: "Tạo lời nhắc",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Giai đoạn giao tiếp",
          communicationObjectives: "Mục tiêu truyền thông",
          scoringCriteria: "Tiêu chí chấm điểm",
          suggestionsForImprovement: "Khuyến nghị nâng",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Điểm quy trình",
            contentIntegrity: "Tính toàn vẹn nội dung:",
            NumberOfCompletedProcesses: "%, Số lượng quy trình đã hoàn thành",
            keyHitPointsTotal: "Lượt truy cập chính: Tổng số",
            notReached: "Chưa đạt",
            accurateFollowupAccumulationOfStandardScripts:
              "Theo dõi chính xác: tích lũy kỹ năng nói tiêu chuẩn",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Một từ, tổng số từ mà học sinh theo dõi",
            aWord: "Chữ",
            languageNormsYes: "Đặc tả ngôn ngữ: Có",
            emotionsAppearing: "Cảm xúc: xuất hiện",
            speechSpeedSpeakingTooFast: "Tốc độ nói: tốc độ nói quá nhanh",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Từ ngữ: xuất hiện quá nhiều từ ngữ",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Thêm vào Bỏ qua thẻ",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Bạn có chắc chắn muốn thực hiện lại thẻ thông minh không?",
        reExecute: "Thực thi lại",
      },
      component: { successfullyAdded: "Thêm thành công" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Tắt tất cả các thông báo",
        reQualityInspectionTask: "Nhiệm vụ kiểm tra lại",
        requalityInspection: "Kiểm tra lại",
        waiting: "Đang chờ",
        viewDetails: "Xem chi tiết",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Thời gian phát hiện:" } },
        },
        template: { responseOpeningSentence: "Trả lời câu bắt đầu" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "Tạo thất bại", generate: "Tạo" },
        },
        empty: {
          index: { thereIsCurrentlyNoDataAvailable: "Không có dữ liệu" },
        },
      },
      index: { regenerate: "Tái tạo" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Chắc chắn xóa?",
        operationSuccessful: "Hoạt động thành công",
      },
      template: { delete: "Xóa" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Vui lòng chọn câu hỏi!",
        similarQuestionRecommendation: "Đề xuất câu hỏi tương tự",
        selectAllOnPage: "Chọn tất cả các trang",
        cancel: "Hủy bỏ",
        preserve: "Lưu",
        changeBatch: "Thay đổi một lô",
      },
    },
    listControl: { index: { columnControl: "Kiểm soát cột" } },
    loginUserAvatar: { index: { switch: "Chuyển đổi" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Khi thêm dữ liệu trong thời gian thực, việc thực hiện nhiệm vụ kiểm tra chất lượng vẫn cần thời gian. Thời gian cụ thể phụ thuộc vào lượng dữ liệu kiểm tra chất lượng, từ vài phút đến 2 giờ.",
      },
    },
    bEditor: {
      index: {
        delete: "Xóa",
        cancel: "Hủy bỏ",
        confirm: "Chắc chắn",
        insert: "Chèn",
        width: "Chiều rộng",
        height: "Chiều cao",
        clearContent: "Xóa nội dung",
        revoked: "Thu hồi",
        redo: "Làm lại",
        fontSize: "Cỡ chữ",
        rowHeight: "Dòng cao",
        wordSpacing: "Khoảng cách từ",
        paragraphIndent: "Thụt lề đoạn văn",
        increaseIndent: "Tăng thụt lề",
        decreaseIndent: "Giảm thụt lề",
        border: "Viền",
        color: "Màu sắc",
        textColor: "Màu chữ",
        backgroundColor: "Màu nền",
        temporaryColor: "Màu sắc tạm thời",
        bold: "To hơn",
        italic: "Chữ nghiêng",
        underline: "Gạch chân",
        strikethrough: "Dòng xóa",
        font: "Phông chữ",
        textAlignment: "Căn chỉnh văn bản",
        left: "Bên trái",
        centered: "Căn giữa",
        right: "Bên phải",
        bothEnds: "Hai đầu",
        leftFloat: "Trái nổi",
        rightFloat: "Phải nổi",
        subrupt: "Dấu trên",
        subscript: "Dấu dưới",
        clearStyle: "Xóa phong cách",
        title: "Tiêu đề",
        regular: "Thông thường",
        orderedList: "Danh sách có trật tự",
        unorderedList: "Danh sách rối loạn",
        reference: "Trích dẫn",
        code: "Mã",
        link: "Liên kết",
        clearLink: "Xóa liên kết",
        horizontalLine: "Đường ngang",
        media: "Phương tiện truyền thông",
        mediaLibrary: "Thư viện phương tiện",
        smallExpression: "Biểu cảm nhỏ",
        fullScreen: "Toàn màn hình",
        exitFullScreen: "Thoát toàn màn hình",
        enterLinkText: "Nhập văn bản liên kết",
        enterLinkAddress: "Nhập địa chỉ liên kết",
        enterTheLinkAddressAndPressEnter: "Nhập địa chỉ liên kết và nhấn Enter",
        openInNewWindow: "Mở trong cửa sổ mới",
        removeLink: "Xóa liên kết",
        playingAudioFiles: "Phát tệp âm thanh",
        playVideoFiles: "Phát tệp video",
        embeddedMedia: "Phương tiện nhúng",
        image: "Hình ảnh",
        video: "Video",
        audio: "Âm thanh",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Tên",
        description: "Mô tả",
        type: "Loại",
        status: "Trạng thái",
        actions: "Hoạt động",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Tên nhiệm vụ",
        description: "Mô tả",
        form: "Biểu mẫu kiểm tra chất lượng",
        actions: "Hoạt động",
        createUser: "Người sáng tạo",
      },
      samplingRecords: {
        createTime: "Thời gian",
        type: "Loại",
        operateUser: "Người điều hành",
        number: "Thêm số lượng",
        filterConditionList: "Điều kiện sàng lọc",
        sampleRuleStr: "Điều kiện lấy mẫu",
        timeGreaterThan: "Thời gian lớn hơn",
        timeLessThan: "Thời gian ít hơn",
      },
    },
    customFilters: {
      createdAt: "Thời gian nói chuyện",
      customerId: "Khách hàng",
      agentId: "Dịch vụ khách hàng",
      userGroupId: "Nhóm dịch vụ khách hàng",
      state: "Trạng thái",
      lastEvaluateUserId: "Người xếp hạng cuối cùng",
      submitTime: "Thời gian nộp cuối cùng",
      totalScore: "Tổng điểm",
      formItemForbidIdList: "Mục bị cấm",
      formItemDeadlyIdList: "Mục chết người",
    },
    samplingInspection: {
      callTime: "Thời gian nói chuyện",
      dialogTime: "Thời gian đối thoại",
      originator: "Phân phối người",
      createTime: "Tạo thời gian",
      updateTime: "Thời gian cập nhật",
      status: "Trạng thái quá trình",
      score: "Phân số",
      actions: "Hoạt động",
      reviewer: "Người kiểm toán",
      samplingOfficer: "Kiểm tra ngẫu nhiên",
      id: "ID ghi âm cuộc gọi",
      time: "Thời gian nói chuyện",
      phone: "Điện thoại của khách hàng",
      staff: "Dịch vụ khách hàng",
      inspectionStatus: "Tình trạng kiểm tra chất lượng",
      samplingStatus: "Tình trạng lấy mẫu",
      inspectionGrade: "Xếp hạng",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Từ chuẩn",
        synonymWords: "Từ đồng nghĩa",
        synonymWordsNumber: "Số từ đồng nghĩa",
        useFrequency: "Tần suất sử dụng",
        effectiveness: "Hiệu quả",
        operation: "Hoạt động",
      },
      common: {
        useFrequency: "Tần suất sử dụng",
        effectiveness: "Hiệu quả",
        operation: "Hoạt động",
      },
      professionalWords: {
        professionalWords: "Danh từ chuyên môn",
        useFrequency: "Thống kê sử dụng",
        homophonic: "Từ nóng (thay thế đồng âm)",
        target: "Danh từ thay thế",
        targetWords: "Từ thay thế",
        homophonyWords: "Từ chặn đồng âm",
        operation: "Hoạt động",
      },
      attentionWords: "Từ quan tâm",
      ignoreWords: "Bỏ qua các từ",
      wordsWeight: {
        selfDefineWeightWords: "Từ trọng lượng tùy chỉnh",
        weightWords: "Từ trọng lượng",
        weight: "Trọng lượng",
        useFrequency: "Tần suất sử dụng",
        effectiveness: "Hiệu quả",
        operation: "Hoạt động",
      },
      corpusData: {
        corpusDescription: "Mô tả ngữ liệu",
        updateTime: "Thời gian cập nhật",
        wordsCount: "Số từ",
        effectiveness: "Hiệu quả",
        operation: "Hoạt động",
        corpusContent: "Nội dung",
      },
    },
    semanticTags: {
      title: "Nhãn ngữ nghĩa",
      tags: "Câu chuẩn",
      orgName: "Tổ chức thuộc về",
      tagCount: "Số đào tạo",
      usingCount: "Số trích dẫn",
      activeFlag: "Hiệu quả",
      suggestion: "Khuyến nghị đào tạo",
      actions: "Hoạt động",
    },
    intelligentTags: {
      tagName: "Tên",
      tagDesc: "Mô tả",
      tagRefCnt: "Số điểm đánh dấu",
      categoryName: "Phân loại nhãn",
      eventName: "Phân loại sự kiện",
      tagStatus: "Hiệu quả",
      actions: "Hoạt động",
      tagType: "Loại nhãn",
    },
    dataPreprocess: {
      name: "Tên",
      desc: "Mô tả",
      dataSource: "Giới hạn nguồn dữ liệu",
      type: "Loại",
      status: "Hiệu quả",
      actions: "Hoạt động",
    },
    informationEntities: {
      name: "Tên",
      apiName: "Tên API",
      type: "Loại",
      remark: "Mô tả",
      activeFlag: "Hiệu quả",
      actions: "Hoạt động",
    },
    interactiveRecognition: {
      questionAndAnswer: "Câu hỏi tiêu chuẩn & câu trả lời",
      type: "Loại",
      status: "Trạng thái",
      actions: "Hoạt động",
      question: "Vấn đề",
    },
    role: { id: "ID", name: "Tên", actions: "Hoạt động" },
    uploadDownload: {
      taskName: "Tên tệp",
      createTime: "Ngày",
      size: "Kích thước",
      createUserName: "Nhà điều hành",
      status: "Trạng thái",
      result: "Kết quả",
      actions: "Hoạt động",
    },
    taskCenterList: {
      name: "Nội dung nhiệm vụ",
      createTime: "Thời gian gửi",
      startTime: "Thời gian bắt đầu",
      endTime: "Thời gian kết thúc",
      createUserName: "Nhà điều hành",
      status: "Trạng thái",
      result: "Kết quả",
      actions: "Hoạt động",
    },
    customerInformation: {
      title: "Thông tin dịch vụ khách hàng",
      data: "Chỉ xem dữ liệu bất thường",
      list: {
        agentNo: "Số công việc/ID dịch vụ khách hàng",
        agentName: "Dịch vụ khách hàng",
        agentTel: "Điện thoại",
        agentEmail: "Hộp thư",
        updateTime: "Thời gian cập nhật",
        actions: "Hoạt động",
      },
      upload: {
        second:
          "2. Nhấp vào nút nhập để tải lên tệp EXCEl phù hợp với định dạng mẫu",
      },
    },
    IndexManagement: {
      title: "Quản lý chỉ số",
      list: {
        indicatorName: "Tên",
        indicatorDesc: "Mô tả",
        activity: "Hiệu quả",
        inspectDataSource: "Nguồn dữ liệu",
        sysType: "Loại",
        agentGroups: "Nhóm dịch vụ khách hàng",
        actions: "Hoạt động",
      },
      detail: {
        nameEmpty: "Tên không được để trống",
        descript: "Mô tả",
        descriptEmpty: "Mô tả không thể trống",
        active: "Hiệu quả",
        activeEmpty: "Hiệu quả không thể trống",
        dataSource: "Nguồn dữ liệu",
        dataSourceEmpty: "Nguồn dữ liệu không được để trống",
        grounp: "Nhóm dịch vụ khách hàng",
        grounpEmpty: "Nhóm dịch vụ khách hàng không thể trống",
        grounpHolder: "Vui lòng chọn nhóm dịch vụ khách hàng",
        type: "Phương pháp tính điểm",
        typeNone: "Phương pháp tính điểm không được để trống",
        base: "Điểm cơ bản",
        baseNone: "Điểm cơ bản không được để trống",
        positive: "Yếu tố tích cực",
        positiveNone: "Các yếu tố tích cực không thể trống",
        customAdd: "Thêm bộ lọc",
        negative: "Yếu tố tiêu cực",
        negativeNone: "Các yếu tố tiêu cực không thể trống rỗng",
        customOK: "Lọc điều kiện:",
        judgeStrategy: "Quy tắc điều kiện:",
        other: "Các yếu tố khác",
        otherAdd: "Thêm yếu tố",
        agent: "Dữ liệu thô",
        section: "Số lượng khoảng",
        sectionNone: "Số khoảng không được để trống",
        step: "Chiều dài bước khoảng",
        stepNone: "Chiều dài bước khoảng",
        save: "Lưu",
        cancel: "Hủy bỏ",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Tất cả",
      staff: "Chỉ tôi",
      department: "Bộ phận này trở xuống",
    },
    gongStatisticsDate: { week: "Tuần", month: "Tháng", season: "Mùa" },
    gongCoachTaskStatus: {
      wait: "Đang chờ tư vấn",
      already: "Đã được tư vấn",
      ignore: "Đã bỏ qua",
    },
    callRangeStatus: {
      all: "Tất cả các cuộc gọi",
      sale: "Nhân viên khởi xướng",
      customer: "Khách hàng khởi xướng",
    },
    todoListType: {
      later: "Nghe sau",
      help: "Tư vấn giúp đỡ",
      contact: "Liên hệ sau",
      send: "Gửi thông tin",
      warn: "Cảnh báo rủi ro",
    },
    frequencyTypes: {
      everyDay: "Hàng ngày",
      everyWeek: "Mỗi tuần",
      everyMonth: "Hàng tháng",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Đào tạo dữ liệu chưa được phân loại",
        markedDataCorrigenda: "Errata dữ liệu được đánh dấu",
        classifiedDataCheck: "Xác minh dữ liệu đã phân loại",
        markedHistoryRecord: "Đánh dấu lịch sử",
        historyDataRecalculate: "Dữ liệu lịch sử được tính lại",
      },
      dataExtractMode: {
        random: "Hoàn toàn ngẫu nhiên",
        intelligentRecommend: "Khuyến nghị thông minh",
        custom: "Tùy chỉnh",
        specifiedClass: "Chỉ định phân loại",
      },
      smartRatesList: {
        markRate: "Tỷ lệ đánh dấu",
        corrigendaRate: "Tỷ lệ errata",
        kl: "Độ phân kỳ K-L",
        signalNoiseRate: "Tỷ lệ tín hiệu trên nhiễu",
      },
    },
    visibleRangeTypes: {
      all: "Tất cả",
      customerGroup: "Nhóm dịch vụ khách hàng",
      userDefined: "Tùy chỉnh",
    },
    samplingRateTypes: {
      everyDay: "Rút tiền mỗi ngày",
      everyWeek: "Rút tiền hàng tuần",
    },
    taskRemindTypes: {
      confirm:
        "Gửi lời nhắc khi hồ sơ nhiệm vụ kiểm tra chất lượng cần được xác nhận",
      appeal:
        "Gửi lời nhắc khi hồ sơ nhiệm vụ kiểm tra chất lượng bắt đầu kháng cáo",
    },
    weekDays: {
      monday: "Thứ hai",
      tuesday: "Thứ ba",
      wednesday: "Thứ tư",
      thursday: "Thứ năm",
      friday: "Thứ sáu",
      saturday: "Thứ bảy",
      sunday: "Chủ nhật",
    },
    reportDateTypes: {
      dailyReport: "Báo cáo hàng ngày",
      weeklyReport: "Báo cáo hàng tuần",
    },
    samplingRangeDayTypes: { today: "Hôm nay", yesterday: "Hôm qua" },
    samplingRangeWeekTypes: { thisWeek: "Tuần này", lastWeek: "Tuần trước" },
    samplingRuleTypes: {
      proportion: "Lấy mẫu tỷ lệ",
      random: "Lấy mẫu ngẫu nhiên",
    },
    taskStatusTypes: {
      waitEvaluate: "Đang chờ đánh giá",
      waitSubmit: "Đang chờ gửi",
      waitConfirm: "Để được xác nhận",
      waitReview: "Đang chờ xem xét",
      completed: "Đã hoàn thành",
      all: "Tất cả",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Xếp hạng trực tiếp",
        weightedScore: "Điểm trọng lượng",
        deductScore: "Điểm trừ",
        directScoreSecondLevel: "Điểm trực tiếp-Cấp 2",
        weightedScoreSecondLevel: "Điểm trọng lượng-Cấp độ 2",
        deductScoreSecondLevel: "Điểm trừ-Cấp 2",
      },
    },
    fieldOperators: {
      is: "Bằng",
      not: "Không bằng",
      contains: "Chứa",
      notContains: "Không chứa",
      not_contains: "Không chứa",
      containsAny: "Chứa tùy ý",
      contains_any: "Chứa tùy ý",
      is_any: "Chứa tùy ý",
      not_any: "Không chứa tùy ý",
      notContainsAny: "Không chứa tùy ý",
      not_contains_any: "Không chứa tùy ý",
      containsAll: "Chứa tất cả",
      contains_all: "Chứa tất cả",
      notContainsAll: "Không chứa tất cả",
      nont_contains_all: "Không chứa tất cả",
      isNull: "Để trống",
      is_null: "Để trống",
      isNotNull: "Không trống rỗng",
      is_not_null: "Không trống rỗng",
      greaterThan: "Lớn hơn",
      greater_than: "Lớn hơn",
      greaterThanEq: "Lớn hơn hoặc bằng",
      greater_than_eq: "Lớn hơn hoặc bằng",
      lessThan: "Nhỏ hơn",
      less_than: "Nhỏ hơn",
      lessThanEq: "Nhỏ hơn hoặc bằng",
      less_than_eq: "Nhỏ hơn hoặc bằng",
      between: "Giữa",
      notBetween: "Không ở đâu",
      not_between: "Không ở đâu",
      relative: "Bằng",
      set: "Đặt thành",
      belongs: "Thuộc về",
      notBelongs: "Không thuộc về",
      not_belongs: "Không bằng",
      today: "Hôm nay",
      yesterday: "Hôm qua",
      the_day_before_yesterday: "Ngày hôm kia",
      tomorrow: "Ngày mai",
      next_seven_day: "Bảy ngày tới",
      last_seven_day: "Bảy ngày qua",
      this_week: "Tuần này",
      next_week: "Tuần sau",
      last_week: "Tuần trước",
      this_month: "Tháng này",
      next_month: "Tháng tới",
      last_month: "Tháng trước",
      this_year: "Năm nay",
      next_year: "Năm sau",
      last_year: "Năm ngoái",
      exact_match: "Kết hợp chính xác",
      add_value: "Giá trị gia tăng mới",
      remove_value: "Xóa giá trị",
      set_null: "Để trống",
      isAny: "Bất kỳ bằng",
      notAny: "Tùy ý không bằng",
      belongTo: "Thuộc về",
      notBelongTo: "Không thuộc về",
      hasAny: "Chứa tùy ý",
      notHasAny: "Không chứa tùy ý",
      prefixContains: "Bắt đầu bằng",
      prefixNotContains: "Khởi đầu không bằng",
      suffixContains: "Kết thúc bằng",
      suffixNotContains: "Kết thúc không bằng",
      nextSevenDay: "7 ngày tới",
      lastSevenDay: "7 ngày qua",
      thisWeek: "Tuần này",
      nextWeek: "Tuần sau",
      lastWeek: "Tuần trước",
      thisMonth: "Tháng này",
      nextMonth: "Tháng tới",
      lastMonth: "Tháng trước",
      thisYear: "Năm nay",
      nextYear: "Năm sau",
      lastYear: "Năm ngoái",
      after: "Muộn hơn (bao gồm cả ngày)",
      before: "Sớm hơn (bao gồm cả ngày)",
      allMatching: "Tất cả phù hợp",
      allNotMatching: "Tất cả không khớp",
      hit: "Đánh trúng",
      not_hit: "Trượt",
    },
    fieldsOperators: {
      is: "Bằng",
      is_null: "Đồng thời trống",
      head_equal: "Bắt đầu bằng",
      tail_equal: "Kết thúc bằng",
      is_not_null: "Đồng thời không trống",
      not: "Không bằng",
      diff_equal: "Sự khác biệt bằng",
      diff_greater_than: "Sự khác biệt lớn hơn",
      diff_greater_than_eq: "Sự khác biệt lớn hơn hoặc bằng",
      diff_less_than: "Sự khác biệt nhỏ hơn",
      diff_less_than_eq: "Sự khác biệt nhỏ hơn hoặc bằng",
      hour_equal: "Cùng giờ",
      day_equal: "Cùng ngày",
      week_equal: "Cùng tuần",
      month_equal: "Cùng tháng",
      year_equal: "Cùng năm",
      day: "Ngày",
      minute: "Phút",
      hour: "Giờ",
      part_equal: "Một phần bằng",
    },
    samplingColumnCaptions: {
      created_at: "Thời gian nói chuyện",
      agent_id: "Dịch vụ khách hàng",
      get_direction: "Loại cuộc gọi",
      call_result: "Kết quả cuộc gọi",
      survey: "Đánh giá",
      derived_from_id: "Nguồn",
      quit_queue_why: "Trạng thái xếp hàng",
      queuing_duration: "Thời gian xếp hàng",
      talking_seconds: "Thời lượng cuộc gọi",
      ringing_seconds: "Chuông thời gian",
      hangup_by: "Bên treo cuộc gọi",
    },
    callTypes: {
      callIn: "Thở vào",
      callOut: "Thở ra",
      threeParty: "(Ba bên)",
      consultation: "(Tư vấn)",
      insert: "(Chèn mạnh)",
      monitor: "(Giám sát)",
      transfer: "(Chuyển tiếp)",
      intercept: "(Đánh chặn)",
      transferOutside: "(Chuyển sang đường dây bên ngoài)",
      threePartyOutside: "(Đường bên ngoài ba bên)",
      consultingOutside: "(Tư vấn bên ngoài)",
    },
    callResults: {
      staffAnswer: "Trả lời dịch vụ khách hàng",
      staffNotAnswer: "Dịch vụ khách hàng không trả lời",
      customerAnswer: "Khách hàng trả lời",
      customerNotAnswer: "Khách hàng không nhận",
      phoneBusy: "Điện thoại bận",
      phoneOffline: "Điện thoại ngoại tuyến",
      customerSpeedHangUp: "Treo nhanh khách hàng",
      customerHangUp: "Khách hàng treo máy",
      queueTimeout: "Xếp hàng quá giờ",
      queueGiveUp: "Từ bỏ hàng đợi",
      outlineAnswer: "Trả lời bên ngoài",
      outlineNotAnswer: "Đường dây bên ngoài không được kết nối",
      noChooseQueue: "Hàng đợi không được chọn",
    },
    defaultSurveys: {
      noEvaluation: "Không đánh giá",
      noNeedToEvaluate: "Không cần đánh giá",
    },
    queueStates: {
      queueSuccess: "Xếp hàng thành công",
      queueTimeout: "Xếp hàng quá giờ",
      queueGiveUp: "Từ bỏ hàng đợi",
      noStaffOnline: "Không có dịch vụ khách hàng trực tuyến",
    },
    callHangers: {
      customer: "Khách hàng",
      staff: "Dịch vụ khách hàng",
      outline: "Đường bên ngoài",
    },
    workFlowTypes: {
      noAppealsMode: "Chế độ không khiếu nại",
      appealsMode: "Cho phép chế độ khiếu nại",
    },
    fieldCategories: {
      standardCondition: "Điều kiện sàng lọc tiêu chuẩn",
      customerCondition: "Bộ lọc do người dùng xác định",
    },
    sampleTypes: { automatic: "Lấy mẫu tự động", manual: "Lấy mẫu thủ công" },
    defaultFields: {
      createdAt: "Thời gian nói chuyện",
      agentId: "Dịch vụ khách hàng",
      getDirection: "Loại cuộc gọi",
      callResult: "Kết quả cuộc gọi",
      survey: "Đánh giá",
      derivedFrom: "Nguồn",
      quitQueueWhy: "Trạng thái xếp hàng",
      queuingDuration: "Thời gian xếp hàng",
      talkingSeconds: "Thời lượng cuộc gọi",
      ringingSeconds: "Chuông thời gian",
      hangupBy: "Bên treo cuộc gọi",
    },
    conditionFieldCategories: {
      fixedField: "Trường cố định",
      customField: "Trường tùy chỉnh",
      secondLevelScore: "Phân loại thứ cấp",
      formItemScore: "Mục điểm",
      forbids: "Mục bị cấm",
      deadlies: "Mục chết người",
    },
    columnFieldCategories: {
      agent: "Dịch vụ khách hàng",
      callLog: "Ghi âm cuộc gọi",
      businessFields: "Thông tin kinh doanh",
      customer: "Khách hàng",
      customerCustomFields: "Khách hàng tùy chỉnh",
      source: "Nguồn",
      queue: "Hàng đợi",
      fixed: "Cố định",
      secondLevelScore: "Phân loại thứ cấp",
      formItemScore: "Mục điểm",
      forbids: "Mục bị cấm",
      deadlies: "Mục chết người",
    },
    taskListDefaultFields: {
      startTime: "Thời gian nói chuyện",
      name: "Dịch vụ khách hàng",
      state: "Trạng thái",
      totalScore: "Tổng điểm",
      formItemForbidList: "Mục bị cấm",
      formItemDeadlyList: "Mục chết người",
      comment: "Nhận xét đánh giá",
      inspector: "Người xếp hạng",
      review: "Người kiểm toán",
    },
    evaluationStates: {
      initial: "Khởi tạo",
      waitEvaluate: "Đang chờ đánh giá",
      evaluated: "Đang chờ gửi",
      waitConfirm: "Để được xác nhận",
      waitCheck: "Đang chờ xem xét",
      complete: "Đã hoàn thành",
    },
    workBenchInfoTypes: {
      callInfo: "Thông tin cuộc gọi",
      businessInfo: "Thông tin kinh doanh",
      customerInfo: "Thông tin khách hàng",
    },
    evaluationEventStates: {
      create: "Tạo",
      valuate: "Đánh giá",
      edit: "Chỉnh sửa",
      submit: "Gửi",
      confirm: "Xác nhận",
      appeal: "Kháng nghị",
      checkConfirm: "Xác nhận xem xét",
      check: "Xem lại",
    },
    formItemTypes: {
      direct: "Xếp hạng trực tiếp",
      weight: "Trọng lượng",
      deduct: "Điểm trừ",
      forbid: "Mục bị cấm",
      deadly: "Mục chết người",
    },
    appealCheckCallTypes: {
      callIn: "Thở vào",
      callBack: "Quay số hai chiều",
      callOut: "Gọi trực tiếp ra ngoài",
      autoCall: "Cuộc gọi đi tự động",
    },
    appealCheckCallStatus: {
      waitReview: "Đang chờ xem xét",
      reviewed: "Đã xem xét",
      filed: "Đã lưu trữ",
    },
    samplingStatus: {
      all: "Tất cả",
      unchecked: "Trích xuất không được kiểm tra",
      checked: "Đã lấy mẫu",
    },
    inspectionStatus: {
      all: "Tất cả",
      unread: "Chưa đọc",
      readed: "Đã đọc",
      appeal: "Kháng nghị",
      review: "Xem lại",
      filed: "Đã lưu trữ",
    },
    gradeChooseHits: { yes: "Là", no: "Không." },
    appealCheckStatus: {
      all: "Tất cả trạng thái",
      unread: "Chưa đọc",
      inComplaint: "Đang kháng cáo",
      reviewed: "Đã xem xét",
      readed: "Đã đọc",
      filed: "Đã lưu trữ",
      spotchecked: "Đã lấy mẫu",
    },
    flowStatus: {
      unread: "Chưa đọc",
      alreadyRead: "Đã đọc",
      complaining: "Đang kháng cáo",
      reviewed: "Đã xem xét",
      sampling: "Trích xuất không được kiểm tra",
      samplingCompleted: "Đã lấy mẫu",
      complainCancel: "Đã thu hồi",
    },
    complainStatus: {
      success: "Thành công",
      fail: "Thất bại",
      default: "Đang kháng cáo",
      canceled: "Đã thu hồi",
    },
    reviewInspectionStatus: {
      inComplaint: "Đang kháng cáo",
      reviewed: "Đã xem xét",
    },
    gradeChangeTypes: {
      edit: "Chỉnh sửa",
      check: "Xem lại",
      file: "Lưu trữ",
      remark: "Ghi chú",
      complain: "Kháng nghị",
      review: "Xem lại",
      reviewEvaluation: "Đánh giá đánh giá",
      sample: "Lấy mẫu",
      sampleEvaluation: "Đánh giá lấy mẫu",
      sampleSubmit: "Gửi kiểm tra ngẫu nhiên",
      sampleAssign: "Phân phối kiểm tra ngẫu nhiên",
      reviewAssign: "Xem lại phân phối",
      read: "Đã đọc",
      reviewConfirm: "Xác nhận xem xét",
      sampleConfirm: "Kiểm tra lấy mẫu xác nhận",
      caseBase: "Thêm vào nhóm trường hợp",
      sampleCancel: "Kiểm tra ngẫu nhiên hủy bỏ phân phối",
      reviewCancel: "Xem lại việc hủy bỏ phân phối",
      sampleAgain: "Kiểm tra ngẫu nhiên và phân phối lại",
      reviewAgain: "Xem lại phân phối lại",
      btachDelete: "Xóa hồ sơ kiểm tra ngẫu nhiên",
      rapidSampling: "Kiểm tra lấy mẫu nhanh",
      reInspection: "Kiểm tra lại",
      smartTagsChange: "Thay đổi nhãn thông minh",
      cancelAppeal: "Thu hồi kháng nghị",
    },
    appealTypes: {
      transliterationError: "Lỗi chuyển ngữ",
      discriminationError: "Sai lầm phân biệt",
      otherError: "Các lỗi khác",
    },
    inspectDataSources: {
      voiceCall: "Cuộc gọi thoại",
      textDialogue: "Đối thoại văn bản",
      realTimeVoiceCall: "Cuộc gọi thoại trong thời gian thực",
      realTimeTextDialogue: "Đối thoại văn bản thời gian thực",
      wechatDialogue: "Phiên vi mô doanh nghiệp",
      taobao: "Đối thoại văn bản thương mại điện tử",
      ticket: "Danh sách công việc",
      wechatRadio: "Giọng nói vi mô",
    },
    inspectTaskType: {
      all: "Tất cả",
      common: "Kiểm tra chất lượng thường xuyên",
      relate: "Kiểm tra chất lượng liên quan",
    },
    inspectApproveType: {
      all: "Tất cả",
      resolve: "Phê duyệt",
      resolving: "Đang xem xét",
      reject: "Đánh giá không thành công",
    },
    dataSourceFlags: {
      all: "Không giới hạn",
      voiceCall: "Cuộc gọi",
      textDialogue: "Đối thoại",
    },
    smartWordsLibrary: { effective: "Hiệu quả", invalid: "Không hợp lệ" },
    activeFlags: { effiective: "Hiệu quả", invalid: "Không hợp lệ" },
    labelTypes: {
      dimensionLabel: "Nhãn mác",
      classificationLabel: "Nhãn phân loại",
    },
    pointTypes: {
      automatic: "Đánh giá tự động",
      manual: "Đánh giá thủ công",
      interactiveDetection: "Phát hiện tương tác",
      smartRules: "Quy tắc thông minh",
      machineLearning: "Chế độ tự học",
      intelligentModel: "Mô hình thông minh",
    },
    pointGradeTypes: {
      radio: "Lựa chọn đơn",
      input: "Đầu vào",
      select: "Lựa chọn",
      all: "Đáp ứng tất cả",
      any: "Đáp ứng tùy ý",
      customize: "Tùy chỉnh",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Từ khóa phù hợp",
      question: "Câu hỏi",
      regular: "Biểu thức chính quy",
      context: "Bối cảnh lặp lại",
      semantics: "Phù hợp ngữ nghĩa",
      word: "Số lượng từ tương tự",
      dialogue: "Khoảng thời gian đối thoại",
      speed: "Phát hiện tốc độ nói",
      grabDetection: "Phát hiện cướp",
      semanticTags: "Nhãn ngữ nghĩa",
    },
    applyRoles: {
      all: "Tất cả",
      customer: "Khách hàng",
      agent: "Dịch vụ khách hàng",
      robot: "Robot",
    },
    saleApplyRoles: {
      all: "Tất cả",
      customer: "Khách hàng",
      agent: "Nhân viên",
    },
    applyOperatorScopes: {
      all: "Toàn văn",
      preCondition: "Điều kiện tiên quyết",
      scope: "Chỉ định phạm vi",
    },
    keywordScopes: {
      all: "Phát hiện tất cả các từ khóa",
      any: "Phát hiện bất kỳ từ khóa nào",
    },
    preOperatorHitTypes: {
      first: "Trận đấu đầu tiên",
      every: "Mỗi lần phù hợp",
      last: "Trận đấu cuối cùng",
      any: "Bất kỳ trận đấu",
      none: "Không khớp",
    },
    operatorRuleTypes: {
      all: "Đáp ứng tất cả",
      any: "Đáp ứng tùy ý",
      custom: "Logic tùy chỉnh",
    },
    informationEntityTypes: {
      system: "Hệ thống",
      custom: "Tùy chỉnh",
      moduleCompany: "Mô đun",
    },
    organizationTypes: { null: "Không có", moduleCompany: "Mô đun" },
    customType: {
      smartTag: "Thực thể trích xuất",
      scriptTimes: "Đếm kỹ năng",
      dataCategory: "Phân loại dữ liệu",
    },
    interactiveQuestionTypes: {
      standard: "Câu hỏi và câu trả lời tiêu chuẩn",
      multiElement: "Hỏi và đáp đa yếu tố",
      guideMultiRound: "Tương tác nhiều bánh có hướng dẫn",
    },
    targetTypes: {
      all: "Tất cả",
      customerService: "Dịch vụ khách hàng",
      customer: "Khách hàng",
    },
    interactiveTypes: {
      similarQList: "Vấn đề tương tự",
      exceptSimilarQList: "Loại trừ các vấn đề tương tự",
      similarAList: "Câu trả lời tương tự",
      wrongAList: "Câu trả lời sai",
    },
    filterTypes: {
      call: "Ghi âm cuộc gọi",
      dialogue: "Văn bản đối thoại",
      wechat: "WeChat doanh nghiệp",
      wechatAll: "Tất cả các cuộc trò chuyện trên WeChat của công ty",
      ticket: "Phiên lệnh công việc",
      taobao: "Phiên thương mại điện tử",
      wechatRadio: "Giọng nói vi mô",
    },
    fieldTypes: {
      keyword: "Từ khóa",
      agent: "Dịch vụ khách hàng",
      agentGroup: "Nhóm dịch vụ khách hàng",
      sigleListbox: "Lựa chọn đơn",
      multiListbox: "Nhiều lựa chọn",
      text: "Văn bản một dòng",
      textArea: "Văn bản nhiều dòng",
      dateTime: "Thời gian",
      number: "Số",
      voice: "",
      customer: "Khách hàng",
      fieldWithLink: "Với trường liên kết",
      percentage: "Tỷ lệ phần trăm",
      intelligentClassification: "Phân loại thông minh",
      testSetList: "Trường động bộ kiểm tra",
      inspector: "Thanh tra chất lượng",
    },
    saveTypes: { save: "Lưu" },
    knowledgeType: { oneToOne: "Một câu hỏi và một câu trả lời" },
    updateTypes: { update: "Cập nhật", saveAs: "Lưu dưới dạng" },
    recommendItemActionTypes: {
      similar: "Tương tự",
      exclude: "Loại trừ",
      ignore: "Bỏ qua",
    },
    gradeStatus: {
      unread: "Chưa đọc",
      alreadyReview: "Đã xem xét",
      alreadyFiled: "Đã lưu trữ",
      waitReview: "Đang chờ xem xét",
      alreadyRead: "Đã đọc",
    },
    qualityCheckDetailTypes: { review: "Xem lại", spotCheck: "Lấy mẫu" },
    sampleAndReviewStatus: {
      init: "Không trích xuất",
      unread: "Chưa đọc",
      alreadyRead: "Đã đọc",
      complaining: "Đang kháng cáo",
      reviewed: "Đã xem xét",
      sampling: "Trích xuất không được kiểm tra",
      samplingCompleted: "Đã lấy mẫu",
    },
    interactiveAnswerTypes: {
      noAnswer: "Không phát hiện câu trả lời",
      answer: "Phát hiện câu trả lời",
      wrongAnswer: "Phát hiện câu trả lời sai",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Không phát hiện ra vấn đề",
      question: "Phát hiện vấn đề",
      eliminateQuestion: "Vấn đề loại trừ được phát hiện",
    },
    isManual: {
      yes: "Kiểm tra chất lượng thủ công",
      no: "Không kiểm tra chất lượng thủ công",
    },
    readStatus: { yes: "Đã đọc", no: "Chưa đọc" },
    reInspectionType: {
      recalcScore: "Chỉ tính toán lại điểm số (bao gồm cả xếp hạng)",
      recheckPoint: "Kế toán lại điểm kiểm tra chất lượng và điểm số",
    },
    common: {
      eCommerceReview: "Đánh giá thương mại điện tử",
      socialMediaComments: "Bình luận truyền thông xã hội",
      customer: "Khách hàng",
      customerService: "Dịch vụ khách hàng",
      robot: "Robot",
      customerInformation: "Thông tin khách hàng",
      workOrderFields: "Trường lệnh công việc",
      intelligentConversationAnalysis: "Phân tích phiên thông minh",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Hiểu biết sâu sắc về những thiếu sót của doanh nghiệp, lắng nghe tiếng nói của khách hàng và cải thiện chuyển đổi tiếp thị",
      intelligentAccompanyingPractice: "Đào tạo thông minh",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Tạo một cuộc tập luyện AI nhập vai mô phỏng các cảnh kinh doanh thực tế, hiện thực hóa kỹ năng nói chuyện huy chương vàng và cải thiện nhanh chóng kỹ năng đối thoại",
      intelligentQualityInspection: "Kiểm tra chất lượng thông minh",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Kiểm tra chất lượng đầy đủ, kịp thời và hiệu quả, tỷ lệ chính xác trên 85%",
      salesEmpowerment: "Trao quyền bán hàng",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI định hình lại quản lý bán hàng, hiện thực hóa quá trình giao tiếp kỹ thuật số, trực quan hóa và quản lý minh bạch mọi khía cạnh của bán hàng, hiểu biết thông minh về từng bước giao tiếp, chẩn đoán tinh chỉnh các vấn đề kinh doanh và khắc phục hoàn hảo trải nghiệm bán hàng",
      algorithmPlatform: "Nền tảng thuật toán",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Dựa trên ngữ nghĩa giọng nói của công cụ trái tim ban đầu tự phát triển, tùy chỉnh mô hình ASR và NLP, mở dữ liệu cảnh kinh doanh, thực hiện tự học hệ thống và đạt được mục đích sử dụng nhiều hơn, thông minh hơn và chính xác hơn",
      generalSettings: "Cài đặt chung",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Thiết lập và quản lý thống nhất quyền dữ liệu và vai trò của nhân viên",
      callLog: "Ghi âm cuộc gọi",
      intelligentRules: "Quy tắc thông minh",
      businessRecords: "Hồ sơ kinh doanh",
      customFilter: {
        call: "Cuộc gọi thoại",
        text: "Đối thoại văn bản",
        smartTags: "Nhãn thông minh",
        pretreatment: "Tiền xử lý",
        smartTagGroup: "Nhóm nhãn thông minh",
        currency: "Dữ liệu chung",
        wechat: "WeChat doanh nghiệp",
        spotCheck: "Lấy mẫu",
        personaField: "Danh sách khách hàng",
        ticket: "Phiên lệnh công việc",
        taobao: "Phiên thương mại điện tử",
        wechatRadio: "Giọng nói vi mô",
        reviewList: "Xem lại",
      },
      talkerRecognitionType: {
        API: "API xác định vai trò theo dõi",
        text: "Nhận dạng văn bản",
      },
      operatorTypes: {
        keyword: "Từ khóa phù hợp",
        question: "Câu hỏi",
        regular: "Biểu thức chính quy",
        context: "Bối cảnh lặp lại",
        semantics: "Phù hợp ngữ nghĩa",
        word: "Số lượng từ tương tự",
        dialogue: "Khoảng thời gian đối thoại",
        speed: "Phát hiện tốc độ nói",
        grabDetection: "Phát hiện cướp",
        semanticTags: "Nhãn ngữ nghĩa",
        keywordStrict: "Khớp từ khóa (nghiêm ngặt)",
        link: "Trường liên kết",
        entityInfoDetection: "Phát hiện thực thể thông tin",
        basicInfoDetection: "Phát hiện thông tin cơ bản",
        volume: "Phát hiện âm lượng",
        emotion: "Phân tích cảm xúc",
        grammar: "Quy tắc ngữ pháp",
        sentenceLength: "Phát hiện độ dài câu",
        conceptWord: "Khái niệm từ",
        questionsAndAnswers: "Câu trả lời cơ sở kiến thức (cũ)",
        element: "Câu hỏi và câu trả lời về các yếu tố",
        silent: "Thời gian chờ phản hồi",
        associate: "Dữ liệu theo đường",
        repeat: "Gửi lặp lại",
        timeInterval: "Chiều dài khoảng thời gian",
        typoDetection: "Phát hiện lỗi chính tả",
        silentOld: "Im lặng",
        knowledge: "Giải đáp kiến thức",
        wecomRemark: "Ghi chú vi mô doanh nghiệp của khách hàng",
        customerAddTime: "Khách hàng thêm thời gian",
      },
      keywordOperators: {
        all: "Tất cả phù hợp",
        allNot: "Tất cả không khớp",
        any: "Phù hợp tùy ý",
      },
      applyRoles: {
        all: "Tất cả",
        customer: "Khách hàng",
        agent: "Dịch vụ khách hàng",
      },
      sentenceLength: {
        less: "Nhỏ hơn",
        greater: "Lớn hơn",
        equal: "Bằng",
        greater_or_equal: "Lớn hơn hoặc bằng",
        less_or_equal: "Nhỏ hơn hoặc bằng",
      },
      dialogueApplyScopes: {
        differentRoles: "Giữa các nhân vật khác nhau",
        agent: "Dịch vụ khách hàng",
        customer: "Khách hàng",
        agentResponse: "Trả lời dịch vụ khách hàng",
        customerResponse: "Phản hồi của khách hàng",
        customerNoResponse: "Khách hàng không đáp ứng",
      },
      applyConditionHitTypes: {
        first: "Lượt truy cập đầu tiên",
        every: "Mỗi lần đánh",
        last: "Lượt truy cập cuối cùng",
      },
      applyConditionScopes: {
        current: "Hiện tại",
        before: "Trước",
        after: "Sau",
        beforeAll: "Tất cả trước",
        afterAll: "Sau tất cả",
        around: "Gần đó",
      },
      voiceDemoTypes: {
        singleChannel: "Ghi âm cuộc gọi một kênh",
        dualChannel: "Ghi âm cuộc gọi kênh đôi",
      },
      sendStatus: {
        sending: "Gửi",
        arrive: "Đã phục vụ",
        fail: "Gửi thất bại",
        off_sending: "Gửi chưa đọc ngoại tuyến",
        off_arrive: "Đã đọc",
        rollback: "Đã rút",
      },
      collectionMethod: {
        assignment: "Gán trực tiếp",
        entity: "Trích xuất thực thể",
      },
      systemTypes: {
        qa: "Kiểm tra chất lượng thông minh",
        wfm: "Sắp xếp thông minh",
      },
      entityOperators: {
        equals: "Thông tin bằng",
        notEquals: "Không bằng thông tin",
        contains: "Chứa thông tin",
        notContains: "Không chứa thông tin",
        equalsFix: "Bằng giá trị cố định",
        notEqualsFix: "Không bằng giá trị cố định",
        containsFix: "Chứa giá trị cố định",
        notContainsFix: "Không chứa giá trị cố định",
      },
      basicInfoOperators: {
        equals: "Bằng",
        notEquals: "Không bằng",
        contains: "Chứa",
        notContains: "Không chứa",
        isNull: "Để trống",
        isNotNull: "Không trống rỗng",
      },
      volumeDetectionWays: {
        loudness: "Độ ồn decibel",
        rangeAbilitySelf: "Biên độ thay đổi (hơn câu trước của tôi)",
        rangeAbilityOth: "Biên độ thay đổi (câu trước của bên so sánh)",
        rangeAbility3: "Biên độ thay đổi (hơn câu gốc)",
      },
      volumeDetectionOperators: {
        is: "Bằng",
        not: "Không bằng",
        greaterThan: "Lớn hơn",
        greaterThanEq: "Lớn hơn hoặc bằng",
        lessThan: "Nhỏ hơn",
        lessThanEq: "Nhỏ hơn hoặc bằng",
      },
      sentimentTypes: {
        positive: "Cảm xúc tích cực",
        negative: "Cảm xúc tiêu cực",
        neutral: "Tâm trạng trung tính",
        thankful: "Cảm ơn",
        happy: "Hạnh phúc",
        complaining: "Khiếu nại",
        angry: "Tức giận",
        post: "Mặt trước",
        negat: "Tiêu cực",
        neut: "Trung tính",
      },
      emotionOperators: { is: "Phù hợp", not: "Không phù hợp" },
      propTypes: { system: "Hệ thống", user: "Tùy chỉnh" },
      valueTypes: { text: "Văn bản", number: "Số" },
    },
    tasks: {
      allowCustomGrievance: "Cho phép tùy chỉnh lý do kháng cáo",
      businessSummary: "Tóm tắt kinh doanh",
      theDayBeforeYesterday: "Ngày hôm kia",
      assessActionTypes: { reassess: "Đánh giá lại" },
      scoreType: {
        aiScore: "Đánh giá thông minh",
        manualScore: "Đánh giá thủ công",
        interactiveDetection: "Phát hiện tương tác",
        smartRules: "Quy tắc thông minh",
        machineLearning: "Chế độ tự học",
      },
      inspectType: {
        inspectTrue: "Kiểm tra để",
        inspectFalse: "Không kiểm tra",
      },
      operatorType: {
        keyword: "Từ khóa phù hợp",
        notKeyword: "Từ khóa không khớp",
      },
      applyRole: {
        all: "Tất cả",
        customer: "Khách hàng",
        agent: "Dịch vụ khách hàng",
      },
      applyScope: {
        all: "Tất cả",
        specific: "Câu chỉ định",
        scope: "Chỉ định phạm vi",
      },
      judgeStrategy: {
        all: "Đáp ứng tất cả các điều kiện",
        arbitrarily: "Đáp ứng các điều kiện tùy ý",
        custom: "Logic tùy chỉnh",
      },
      checkPointStatus: {
        waitFor: "Đang chờ xem xét",
        notDeductScore: "Không trừ điểm",
        deductScore: "Điểm trừ",
      },
      applyOptions: {
        hit: "Lượt truy cập có điều kiện",
        notHit: "Không có điều kiện được chỉ định",
      },
      gradeTypes: {
        yesOrNo: "Có phù hợp không",
        input: "Nhập thủ công",
        level: "Đánh giá phân loại",
        multipleFactors: "Đa yếu tố",
      },
      gradeChooseHits: { yes: "Là", no: "Không." },
      gradeChangeTypes: {
        edit: "Chỉnh sửa",
        check: "Xem lại",
        file: "Lưu trữ",
        remark: "Ghi chú",
        complain: "Kháng nghị",
        review: "Xem lại",
        reviewEvaluation: "Đánh giá đánh giá",
        sample: "Lấy mẫu",
        sampleEvaluation: "Đánh giá lấy mẫu",
        sampleSubmit: "Gửi kiểm tra ngẫu nhiên",
        sampleAssign: "Phân phối kiểm tra ngẫu nhiên",
        reviewAssign: "Xem lại phân phối",
        read: "Đã đọc",
        reviewConfirm: "Xác nhận xem xét",
        sampleConfirm: "Kiểm tra lấy mẫu xác nhận",
      },
      gradeStatus: {
        unread: "Chưa đọc",
        alreadyReview: "Đã xem xét",
        alreadyFild: "Đã lưu trữ",
        waitReview: "Đang chờ xem xét",
        alreadyRead: "Đã đọc",
      },
      samplingFrequencyTypes: {
        everyDay: "Hàng ngày",
        everyWeek: "Mỗi tuần",
        everyMonth: "Hàng tháng",
      },
      samplingEveryDayTimeTypes: { yesterday: "Hôm qua", today: "Hôm nay" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "Tuần này",
        lastWeek: "Tuần trước",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "Tháng này",
        lastMonth: "Tháng trước",
      },
      samplingTypes: {
        random: "Lấy mẫu ngẫu nhiên",
        proportional: "Lấy mẫu tỷ lệ",
      },
      weekdays: {
        Monday: "Thứ hai",
        Tuesday: "Thứ ba",
        Wednesday: "Thứ tư",
        Thursday: "Thứ năm",
        Friday: "Thứ sáu",
        Saturday: "Thứ bảy",
        Sunday: "Chủ nhật",
      },
      distributeTypes: {
        rule: "Quy tắc phân bổ ưu tiên",
        average: "Phân phối ngẫu nhiên trung bình",
      },
      notMatchAnyRuleTypes: {
        random: "Phân bổ trung bình ngẫu nhiên",
        assign: "Giao cho người được chỉ định",
        ignore: "Không phân phối",
      },
      inspectorTypes: {
        all: "Tất cả các thanh tra chất lượng",
        some: "Thanh tra chất lượng được chỉ định",
      },
      pushFrequencyTypes: {
        everyDay: "Hàng ngày",
        everyWeek: "Mỗi tuần",
        everyMonth: "Hàng tháng",
        inspectionUpdate: "Cập nhật kiểm tra chất lượng",
        timelyInspection: "Kiểm tra chất lượng thời gian thực",
      },
      pushObjectTypes: {
        email: "Chỉ định hộp thư",
        staff: "Dịch vụ khách hàng thuộc về",
      },
      reviewOptions: {
        reviewResult:
          "Kết quả kiểm tra chất lượng được hiển thị trong quá trình xem xét",
        reviewRecord: "Hiển thị hồ sơ liên quan trong quá trình xem xét",
        reviewAgentHidden:
          "Ẩn thông tin dịch vụ khách hàng trong quá trình xem xét",
        samplingResult:
          "Kết quả kiểm tra chất lượng được hiển thị trong quá trình kiểm tra lấy mẫu",
        samplingRecord:
          "Hiển thị các hồ sơ liên quan trong quá trình kiểm tra ngẫu nhiên",
        sampleAgentHidden:
          "Ẩn thông tin dịch vụ khách hàng trong quá trình kiểm tra ngẫu nhiên",
        checkExportAddCallInfoIphone:
          "Đính kèm lịch sử cuộc gọi khi xuất danh sách",
        sampleExportAddCallInfoIphone:
          "Đính kèm lịch sử cuộc gọi khi xuất danh sách",
        checkExportAddCallInfoText:
          "Đính kèm bản ghi cuộc hội thoại khi xuất danh sách",
        sampleExportAddCallInfoText:
          "Đính kèm bản ghi cuộc hội thoại khi xuất danh sách",
        inspectionExportAddCallInfoIphone:
          "Đính kèm lịch sử cuộc gọi khi xuất danh sách (có thể xuất tối đa 10.000 mục mỗi lần)",
        inspectionExportAddCallInfoText:
          "Đính kèm bản ghi cuộc hội thoại khi xuất danh sách (có thể xuất tối đa 10.000 mục mỗi lần)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Cho phép nhanh chóng thực hiện kiểm tra ngẫu nhiên trong các chi tiết kiểm tra chất lượng",
        fastSampleInSampleDetail:
          "Cho phép nhanh chóng thực hiện kiểm tra ngẫu nhiên trong các chi tiết kiểm tra ngẫu nhiên",
        fastSampleInReviewDetail:
          "Cho phép nhanh chóng thực hiện kiểm tra ngẫu nhiên trong các chi tiết xem xét",
      },
      permitCustomReasonList: {
        permitCustomReason: "Cho phép tùy chỉnh lý do khiếu nại",
      },
      autoMateTasks: {
        autoLoad: "Tự động thêm",
        sample: "Kiểm tra lấy mẫu tự động",
        assign: "Phân phối tự động",
        pushes: "Đẩy tự động",
        assess: "Đánh giá tự động",
        check: "Kháng nghị kiểm tra lại",
        case: "Đồng bộ hóa thư viện trường hợp",
      },
      qualityCheckDetailTypes: { review: "Xem lại", spotCheck: "Lấy mẫu" },
      appealTypes: {
        transliterationError: "Lỗi chuyển ngữ",
        discriminationError: "Sai lầm phân biệt",
        otherError: "Các lỗi khác",
      },
      inspectDataSources: {
        voiceCall: "Cuộc gọi thoại",
        textDialogue: "Đối thoại văn bản",
        realTimeVoiceCall: "Cuộc gọi thoại trong thời gian thực",
        realTimeTextDialogue: "Đối thoại văn bản thời gian thực",
      },
      pointTypes: {
        automatic: "Đánh giá tự động",
        manual: "Đánh giá thủ công",
        interactiveDetection: "Phát hiện tương tác",
      },
      pointGradeTypes: {
        radio: "Lựa chọn đơn",
        input: "Đầu vào",
        select: "Lựa chọn",
      },
      sampleAndReviewStatus: {
        init: "Không trích xuất",
        unread: "Chưa đọc",
        alreadyRead: "Đã đọc",
        complaining: "Đang kháng cáo",
        reviewed: "Đã xem xét",
        sampling: "Trích xuất không được kiểm tra",
        samplingCompleted: "Đã lấy mẫu",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Bỏ qua tràn quá mức",
        automaticWeightCalculation: "Kế toán trọng lượng tự động",
      },
      calculateScoreTypes: { add: "Điểm thưởng", sub: "Giảm điểm" },
      naChecked: { check: "Điều này không liên quan đến thử nghiệm cho phép" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Cho điểm theo tỷ lệ",
        allRight: "Tất cả các điểm chính xác",
        anyRight: "Bất kỳ điểm chính xác",
      },
      noHitQuestionResults: {
        true: 'Kết quả là "có"',
        false: 'Kết quả là "Không"',
      },
      repetRules: {
        same: "Nội dung giống hệt nhau",
        similar: "Ngữ nghĩa tương tự",
      },
      relateConditions: {
        highlightQuestion: "Vấn đề làm nổi bật",
        highlightAnswer: "Câu trả lời nổi bật",
        hightlightFoctors: "Yếu tố nổi bật",
      },
      interactiveQuestionTypes: {
        question: "Phát hiện vấn đề",
        eliminateQuestion: "Vấn đề loại trừ được phát hiện",
      },
      interactiveAnswerTypes: {
        noAnswer: "Không phát hiện câu trả lời",
        answer: "Phát hiện câu trả lời",
        wrongAnswer: "Phát hiện câu trả lời sai",
      },
      inspectionUpdateTypes: {
        autoInspection: "Kiểm tra chất lượng tự động",
        autoInspectionWechat: "Phân tích tự động",
        artificialSampling: "Lấy mẫu thủ công",
        artificialReview: "Đánh giá thủ công",
        manualModification: "Kiểm tra lấy mẫu nhanh",
        timelyInspection: "Kiểm tra chất lượng thời gian thực",
        samplingAssign: "Phân phối kiểm tra ngẫu nhiên",
        artificialComplain: "Nộp đơn khiếu nại",
        fastSampling: "Gửi kiểm tra lấy mẫu nhanh",
        recheckInspection: "Kiểm tra lại",
        recheckInspectionWechat: "Phân tích lại",
      },
      timelyInspectionTypes: {
        timelyInspection: "Kiểm tra chất lượng thời gian thực",
      },
      pushActionTypes: { email: "Thư", interfacePush: "Đẩy giao diện" },
      pushDetailWays: {
        riskAlert: "Nhắc nhở rủi ro",
        subscribe: "Đăng ký tin nhắn",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Tin nhắn cuộc gọi/cuộc trò chuyện",
        inspectionResults: "Kết quả kiểm tra chất lượng",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Tin nhắn cuộc gọi/cuộc trò chuyện",
        textContent: "Nội dung văn bản cuộc gọi/hội thoại",
        inspectionResults: "Kết quả kiểm tra chất lượng",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Điểm trung bình trong nhóm",
        addOrSubtract: "Cộng trừ điểm trực tiếp",
        noChange: "Không cộng trừ điểm",
      },
      defaultScore: {
        empty: "Để trống",
        add: "Điểm thưởng",
        notAdd: "Không cộng điểm",
        sub: "Giảm điểm",
        notSub: "Không trừ điểm",
        na: "N/A",
        input: "Nhập thủ công",
        choose: "Lựa chọn",
      },
      strategyTypes: {
        hitAdd: "Điểm nhấn",
        hitNotAdd: "Không có điểm cộng khi đánh",
        hitSub: "Đánh trừ điểm",
        hitNotSub: "Đánh không trừ điểm",
      },
    },
    download: {
      exportStatus: {
        prepare: "Đang chuẩn bị",
        process: "Đang tiến hành",
        faild: "Xuất không thành công",
        success: "Thành công",
      },
    },
    messageType: {
      all: "Tất cả",
      monitor: "Giám sát tức thì",
      trigger: "Kích hoạt thông báo",
      timing: "Báo cáo thời gian",
    },
    messagePushType: {
      all: "Tất cả",
      system: "Tin nhắn hệ thống",
      sdk: "SDK tin nhắn",
      push: "Đẩy giao diện",
      email: "Thư",
      udesk: "Thông báo udesk",
      udeskWhite: "Thông báo hệ thống dịch vụ khách hàng",
      weChat: "Tin tức vi mô doanh nghiệp",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Chỉ định nhân viên kiểm tra ngẫu nhiên",
      sampleInspector: "Kiểm tra ngẫu nhiên",
      assignReviewer: "Phân công người kiểm toán",
      reviewer: "Người kiểm toán",
      taskInspector: "Thanh tra chất lượng nhiệm vụ",
    },
    sdkPushType: {
      webHook: "Webhook tiêu chuẩn",
      dingDing: "Robot đinh",
      weChat: "Robot WeChat doanh nghiệp",
      customer: "Nội dung Json tùy chỉnh",
    },
    inspectionRemarksType: {
      inspection: "Kiểm tra chất lượng",
      check: "Lấy mẫu",
      review: "Xem lại",
    },
    segmentationStrategyType: {
      count: "Số lượng tin nhắn",
      spacing: "Khoảng thời gian tin nhắn",
    },
    segmentationStrategy: {
      byTime: "Thời gian cắt",
      customize: "Phân đoạn kết hợp",
    },
    inspectType: {
      commonInspect: "Kiểm tra chất lượng thường xuyên",
      associateInspect: "Kiểm tra chất lượng liên quan",
    },
    typoDetectionOperators: { less: "Nhỏ hơn", greater: "Lớn hơn" },
    autoAddTypes: {
      realTimeAdd: "Thêm trong thời gian thực",
      timingAdd: "Thời gian thêm",
    },
    messageTypes: { text: "Tin nhắn văn bản", markdown: "Tin nhắn Markdown" },
    inServiceStatus: {
      activated: "Đã kích hoạt",
      disabled: "Đã vô hiệu hóa",
      inactivated: "Không được kích hoạt",
      resigned: "Thoát khỏi doanh nghiệp",
    },
    roles: { agent: "Nhân viên", customer: "Khách hàng" },
    commentVisibleAuthority: {
      all: "Có thể nhìn thấy cho tất cả mọi người",
      self: "Chỉ hiển thị cho chính bạn",
      designation: "Người được chỉ định có thể nhìn thấy",
    },
    appealCheckingTask: {
      eCommerceReview: "Đánh giá thương mại điện tử",
      socialMediaComments: "Bình luận truyền thông xã hội",
      largeModel: "Mô hình lớn",
      customModel: "Mô hình tùy chỉnh",
      universalModel: "Mô hình phổ quát",
      enterpriseWeChatText: "Văn bản vi mô doanh nghiệp",
      generalQualityInspection: "Kiểm tra chất lượng chung",
      processQualityInspection: "Kiểm tra chất lượng quy trình",
      voiceCopy: "Phát lại giọng nói",
      beEqualTo: "Bằng",
      notEqualTo: "Không bằng",
      canAppeal: "Có thể kháng cáo",
      noAppeal: "Không thể kháng cáo",
    },
    wechat: {
      redEnvelopes: "Phong bao đỏ",
      text: "Văn bản",
      picture: "Hình ảnh",
      voice: "Giọng nói",
      voiceCall: "Cuộc gọi thoại",
      video: "Video",
      businessCard: "Danh thiếp",
      position: "Vị trí",
      expression: "Biểu cảm",
      link: "Liên kết",
      applet: "Chương trình nhỏ",
      chatRecord: "Lịch sử trò chuyện",
      mixedMessage: "Tin nhắn hỗn hợp",
      file: "Tài liệu",
    },
    gong: {
      sessionActivity: "Hoạt động đàm thoại",
      conversationAction: "Hành động đàm thoại",
      riskSemantics: "Ngữ nghĩa rủi ro",
      daysNotFollowedUp: "Số ngày không theo dõi",
      waysideData: "Dữ liệu theo đường",
      notStarted: "Chưa bắt đầu",
      completed: "Đã hoàn thành",
      haveInHand: "Đang tiến hành",
      unpublished: "Chưa phát hành",
      published: "Đã phát hành",
      knowledgePoints: "Điểm kiến thức",
      technologicalProcess: "Quy trình",
      notMarked: "Không đánh dấu",
      inAnnotation: "Đánh dấu",
      annotated: "Đã đánh dấu",
    },
    qualityChecingTask: {
      appealCanceled: "Kháng nghị hủy bỏ",
      complaintExpired: "Kháng nghị hết hạn",
      incomingCall: "Thở vào",
      exhale: "Thở ra",
      incomingCallthirdParty: "Cuộc gọi đến (ba bên)",
      incomingCallconsultation: "Gọi vào (tư vấn)",
      incomingCallforcedInsertion: "Gọi vào (chèn mạnh)",
      incomingCallmonitoring: "Gọi vào (giám sát)",
      incomingCalltransfer: "Gọi vào (chuyển)",
      incomingCallinterception: "Cuộc gọi đến (chặn)",
      incomingCalltransferToExternalLine:
        "Cuộc gọi đến (chuyển sang đường bên ngoài)",
      incomingCallthirdPartyExternalLine:
        "Cuộc gọi đến (đường bên ngoài ba chiều)",
      incomingCallexternalLine: "Gọi vào (tư vấn bên ngoài)",
      outgoingCallthirdParty: "Thở ra (ba bên)",
      outgoingCalltransfer: "Thở ra (chuyển)",
      outgoingCallforcedInsertion: "Thở ra (chèn mạnh)",
      outgoingCallmonitoring: "Gọi ra (giám sát)",
      outgoingCallinterception: "Thở ra (chặn)",
      outgoingCalltransferToExternalLine:
        "Gọi ra (chuyển sang đường bên ngoài)",
      outgoingCallthreePartyExternalLine: "Thở ra (ba bên bên ngoài)",
      customerServiceAnswer: "Trả lời dịch vụ khách hàng",
      customerServiceMissed: "Dịch vụ khách hàng không trả lời",
      customerAnswer: "Khách hàng trả lời",
      customerMissed: "Khách hàng không nhận",
      theTelephoneIsBusy: "Điện thoại bận",
      phoneOffline: "Điện thoại ngoại tuyến",
      customerQuickHanging: "Treo nhanh khách hàng",
      clientHangsUp: "Khách hàng treo máy",
      queueTimeout: "Xếp hàng quá giờ",
      giveUpQueuing: "Từ bỏ hàng đợi",
      outsideLineAnswering: "Trả lời bên ngoài",
      externalLineIsNotConnected: "Đường dây bên ngoài không được kết nối",
      noQueueSelected: "Hàng đợi không được chọn",
      notEvaluated: "Không đánh giá",
      noEvaluationRequired: "Không cần đánh giá",
      queuingSucceeded: "Xếp hàng thành công",
      noCustomerServiceOnline: "Không có dịch vụ khách hàng trực tuyến",
      customer: "Khách hàng",
      customerService: "Dịch vụ khách hàng",
      outsideLines: "Đường bên ngoài",
    },
    sessionActivity: {
      single: "Thời gian trung bình của một cuộc gọi",
      all: "Tổng số cuộc gọi",
      allTime: "Tổng thời lượng cuộc gọi",
    },
    sessionAction: {
      salesProportion: "Tỷ lệ bán hàng",
      salesLongest: "Bài phát biểu bán hàng dài nhất trung bình",
      customerLongest: "Khách hàng trung bình dài nhất nói",
      discussNumber: "Số lượng thảo luận chuyên sâu",
      frequency: "Tần suất đặt câu hỏi",
    },
    monitorCriteria: { all: "Trước sau", before: "Trước", after: "Sau" },
    monitorTimeCycle: { day: "Ngày", week: "Tuần", month: "Tháng" },
    monitorGroupTestType: { ab: "So sánh hai nhóm", a: "Nhóm A", b: "Nhóm B" },
    fields: {
      department: "Phòng ban",
      customerTags: "Nhãn khách hàng",
      member: "Thành viên",
      time: "Thời gian",
      cascade: "Xếp tầng",
      anyMatch: "Phù hợp tùy ý",
      keyWord: "Từ khóa",
      keyEvents: "Sự kiện quan trọng",
    },
    semanticIntelligence: {
      staff: "Nhân viên",
      customer: "Khách hàng",
      employeesAndCustomers: "Nhân viên và khách hàng",
      notStarted: "Chưa bắt đầu",
      haveInHand: "Đang tiến hành",
      completed: "Đã hoàn thành",
      callRecording: "Ghi âm cuộc gọi",
      enterpriseWeChatCall: "Cuộc gọi vi mô doanh nghiệp",
      enterpriseWeChatText: "Văn bản vi mô doanh nghiệp",
      discourseMiningForObjectionHandling: "Khai thác kỹ năng xử lý phản đối",
      productSellingPointScriptsMining: "Khai thác điểm bán sản phẩm",
      conversationScenario: "Cảnh đàm thoại",
      topicOfConversation: "Chủ đề",
      keyEvents: "Sự kiện quan trọng",
      customerLabel: "Nhãn khách hàng",
      includeAny: "Chứa tùy ý",
      includeAll: "Chứa tất cả",
      and: "Và",
      or: "Hoặc",
      question: "Câu hỏi",
      nonInterrogativeSentence: "Câu không nghi vấn",
    },
    coach: {
      notPassed: "Không đạt",
      analogTelephone: "Điện thoại Analog",
      enterScenarioInformation: "Nhập thông tin tình huống",
      generateDialogueFramework: "Tạo khung đối thoại",
      generatePromptLanguage: "Tạo lời nhắc",
      aIGeneratedDialogue: "AI tạo cuộc trò chuyện",
      system: "Hệ thống",
      custom: "Tùy chỉnh",
      flow: "Quy trình",
      script: "Lời nói",
      express: "Biểu hiện",
      emotion: "Cảm xúc",
      rateAccordingToCourseConfiguration: "Điểm theo cấu hình khóa học",
      intelligentModelRating: "Điểm mô hình thông minh",
      allData: "Tất cả dữ liệu",
      dataFromThisDepartment: "Dữ liệu của bộ phận này",
      multidepartmentalData: "Dữ liệu đa phòng ban",
      underReview: "Đang xem xét",
      no: "Không.",
      yes: "Là",
      automaticRating: "Tự động chấm điểm",
      auditRating: "Đánh giá điểm",
      reRating: "Đánh giá lại",
      inAppeal: "Đang kháng cáo",
      reviewed: "Đã xem xét",
      notViewed: "Chưa xem",
      viewed: "Đã xem",
      uncorrected: "Không sửa",
      corrected: "Đã sửa",
      practice: "Bài tập",
      examination: "Thi",
      other: "Khác",
      notMeetingStandards: "Không đạt tiêu chuẩn",
      meetingStandards: "Đạt tiêu chuẩn",
      excellent: "Xuất sắc",
      invalid: "Thất bại",
      notStarted: "Chưa bắt đầu",
      haveInHand: "Đang tiến hành",
      ended: "Đã kết thúc",
      completed: "Đã hoàn thành",
      hangInTheAir: "Chưa hoàn thành",
      passed: "Đã qua",
      voiceConversation: "Đối thoại bằng giọng nói",
      textDialogue: "Đối thoại văn bản",
      slidePresentation: "Bài phát biểu trình chiếu",
      unpublished: "Chưa phát hành",
      published: "Đã phát hành",
      narration: "Tường thuật",
      studentSpeaking: "Học sinh nói",
      robotTalk: "Robot nói",
      knowledgeBaseQA: "Câu hỏi và câu trả lời về cơ sở kiến thức",
      slide: "Trình chiếu",
      negativeEmotions: "Cảm xúc tiêu cực",
      emotionallyNormal: "Tâm trạng bình thường",
      incompleteScript: "Lời nói không đầy đủ",
      completeScript: "Lời nói hoàn chỉnh",
      normalSpeechSpeed: "Tốc độ nói bình thường",
      speakTooFast: "Nói quá nhanh",
      speakTooSlowly: "Nói chậm",
      whole: "Tất cả",
      singlePage: "Trang đơn",
      qA: "Một câu hỏi và một câu trả lời",
      situationalDialogue: "Đối thoại tình huống",
      misses: "Trượt",
      hit: "Đánh trúng",
      sequentialQA: "Câu hỏi và câu trả lời theo thứ tự",
      randomQA: "Câu hỏi và câu trả lời ngẫu nhiên",
      mastered: "Đã làm chủ",
      notMastered: "Không làm chủ",
    },
    algorithm: {
      salesEmpowerment: "Trao quyền bán hàng",
      enterpriseWeChatVoice: "Giọng nói WeChat doanh nghiệp",
      enterpriseWeChatText: "Văn bản WeChat doanh nghiệp",
      waiting: "Đang chờ",
      extracting: "Trích xuất",
      success: "Thành công",
      fail: "Thất bại",
      intelligentQualityInspection: "Kiểm tra chất lượng thông minh",
      textDialogue: "Đối thoại văn bản",
      voiceCall: "Cuộc gọi thoại",
      smartTags: "Nhãn thông minh",
      satisfactionEvaluation: "Đánh giá sự hài lòng",
      score: "Chấm điểm",
      grade: "Xếp hạng",
      customerServiceTeam: "Nhóm dịch vụ khách hàng",
      conversationAnalysis: "Phân tích phiên",
    },
    operatorListGather: {
      businessSummary: "Tóm tắt kinh doanh",
      firstSentence: "Câu đầu tiên",
      lastSentence: "Câu cuối cùng",
      allTime: "Tất cả thời gian",
      nonBusinessWorkingHours: "Thời gian làm việc phi kinh doanh",
      businessWorkingHours: "Thời gian làm việc kinh doanh",
    },
    callDialogueManage: {
      eCommerceReview: "Đánh giá thương mại điện tử",
      socialMediaComments: "Bình luận truyền thông xã hội",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Đăng nhập",
        email: "Hộp thư",
        password: "Mật khẩu",
        forgetPwd: "Quên mật khẩu",
        emailCannotBeEmpty: "Hộp thư không được để trống.",
        passwordCannotByEmpty: "Mật khẩu không được để trống.",
        mustChangeLogin:
          "Xin lỗi, mật khẩu của bạn phải được đặt lại, vui lòng liên hệ với quản trị viên để đặt lại mật khẩu.",
        dismissionLogin: "Xin lỗi, bạn đã rời đi.",
        forbiddenLogin: "Xin lỗi, tài khoản của bạn bị vô hiệu hóa.",
        loginError:
          "Đăng nhập không thành công, hộp thư hoặc mật khẩu không chính xác.",
        loginPrompt: "Đăng nhập vào hệ thống",
        rememberMe: "Nhớ tôi",
      },
      logout: {
        logoutError:
          "Đăng xuất không thành công, hãy kiểm tra kết nối mạng hoặc thử lại sau.",
      },
      resetPassword: {
        forgetPwd: "Quên mật khẩu",
        inputEmailPrompt:
          "Nhập địa chỉ email tài khoản của bạn vào bên dưới và chúng tôi sẽ gửi cho bạn email và hướng dẫn đặt lại mật khẩu.",
        sendEmailSuccess:
          "Thư xác nhận sửa đổi mật khẩu đã được gửi thành công đến hộp thư bạn điền, vui lòng kiểm tra kịp thời.",
        sendEmailFailed:
          "Gửi email không thành công, vui lòng kiểm tra kết nối mạng hoặc thử lại sau.",
        setNewPassword: "Đặt mật khẩu mới của bạn",
        passwordCannotEmpty: "Mật khẩu không được để trống",
        passwordFormWrong: "Định dạng mật khẩu không chính xác",
        confirmPasswordWrong: "Mật khẩu được nhập hai lần không bằng nhau.",
        passwordResetSuccess: "Đặt lại mật khẩu thành công.",
        passwordResetFailed:
          "Đặt lại mật khẩu không thành công, vui lòng kiểm tra kết nối mạng hoặc thử lại sau.",
        linkTimeover: "Liên kết đặt lại mật khẩu hết hạn, vui lòng thử lại",
      },
      form: { title: "Hiển thị biểu mẫu CASE" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Hộp thư",
          emailFormatError: "Định dạng hộp thư sai, vui lòng nhập lại!",
          forgotPassword: "Quên mật khẩu?",
          describe:
            "Nhập địa chỉ email tài khoản của bạn vào bên dưới và chúng tôi sẽ gửi cho bạn email và hướng dẫn đặt lại mật khẩu.",
          send: "Gửi",
          emailCannotBeEmpty: "Hộp thư không được để trống",
          resetPasswordExpire:
            "Liên kết đặt lại mật khẩu hết hạn, vui lòng thử lại",
        },
        resetPassword: {
          setPassword: "Đặt mật khẩu mới của bạn",
          newPassword: "Mật khẩu mới",
          repeatPassword: "Lặp lại mật khẩu",
          resetPassword: "Đặt lại mật khẩu",
          passwordCannotBeEmpty: "Mật khẩu mới không thể để trống.",
          confirmPasswordCannotBeEmpty:
            "Mật khẩu trùng lặp không thể để trống.",
          passwordsNotMatch: "Mật khẩu không nhất quán hai lần!",
          passwordFormWrong:
            "Mật khẩu không được mang tiếng Trung và số lượng là 6-14 chữ số!",
          linkExpiration: "Liên kết đặt lại mật khẩu hết hạn, vui lòng thử lại",
        },
      },
      errors: {
        haveNoPermissionWarningText: "Bạn không có quyền xem trang này",
      },
    },
    downloadCenter: {
      name: "Trung tâm tải xuống",
      saveTime: "(Lưu theo mặc định trong 30 ngày)",
      headerTitle: "Tải tập tin",
      download: "Tải về",
      downloadPromp: "Nhiệm vụ tải xuống đã được gửi, vui lòng truy cập",
      viewProgress: "Xem tiến độ",
    },
    uploadCenter: { name: "Trung tâm tải lên" },
    tasks: {
      index: {
        name: "Tên",
        remark: "Mô tả",
        qualityCheckTasksTitle: "Bàn làm việc",
        informationCollection: "Nhiệm vụ thu thập",
        qualityCheckTasks: "Nhiệm vụ kiểm tra chất lượng",
        wechatQaTasks: "Nhiệm vụ phân tích",
        moduleName: "Sử dụng mẫu để tạo mới",
        creatNewQualityCheckTask: "Nhiệm vụ mới",
        generalInspectionList: "Báo cáo kiểm tra chất lượng chung",
        editQualityCheckTask: "Chỉnh sửa nhiệm vụ kiểm tra chất lượng",
        creatNewQualityCheckTaskError:
          "Nhiệm vụ kiểm tra chất lượng mới không thành công",
        editQualityCheckTaskError:
          "Chỉnh sửa nhiệm vụ kiểm tra chất lượng không thành công",
        type: "Loại biểu mẫu",
        singleScore: "Điểm đơn",
        singleScoreNullError: "Điểm đơn không được để trống",
        inspector: "Thanh tra chất lượng",
        inspectDataSource: "Nguồn dữ liệu",
        taskName: "Tên nhiệm vụ",
        inspectionType: "Loại hình kiểm tra chất lượng",
      },
      manage: {
        screeningCondition: "Điều kiện sàng lọc",
        template: {
          condition: {
            index: { name: "Điều kiện", existingConditions: "Đã có điều kiện" },
            detail: {
              id: "ID",
              name: "Tên điều kiện",
              remark: "Mô tả",
              checkRange: "Phạm vi phát hiện",
              operator: "Toán tử",
              operatorLogic: "Logic toán tử",
            },
          },
          checkPointTest: {
            title: "Kiểm tra quy tắc",
            running: "Kiểm tra quy tắc, vui lòng đợi...",
            clearSuccess: "Đối thoại mô phỏng trống thành công",
            mock: "Đối thoại mô phỏng",
            save: "Lưu cuộc trò chuyện đã chọn",
            saveAll: "Lưu tất cả các cuộc hội thoại",
            saveSuccess: "Cuộc trò chuyện được lưu thành công",
            close: "Thoát",
            delete: "Xóa lựa chọn",
            clear: "Trống rỗng",
            customer: "Khách hàng",
            agent: "Dịch vụ khách hàng",
            test: "Kiểm tra",
            request:
              "Cuộc trò chuyện mô phỏng sẽ không được khôi phục sau khi nó được làm trống. Nó có được xác nhận không?",
            deletePopconfirmContent:
              "Bạn có xác nhận xóa cuộc trò chuyện đã chọn không?",
          },
          checkPoint: {
            index: {
              name: "Cấu hình quy tắc",
              wechatTitle: "Phân tích cấu hình mô hình",
              existingCheckPoints: "Quy tắc kiểm tra chất lượng hiện có",
              currentCheckPointScore:
                "Điểm của các quy tắc kiểm tra chất lượng hiện tại là",
              currentCheckPointScoreWechat:
                "Điểm mô hình phân tích hiện tại là",
              currentCheckPointWeight:
                "Tổng trọng số của các quy tắc kiểm tra chất lượng hiện tại là",
              weightTotalScoreAndItemScore: "Tổng điểm: 100 điểm Điểm cá nhân:",
              template: "Mẫu quy tắc kiểm tra chất lượng",
              import: "Nhập mẫu quy tắc kiểm tra chất lượng",
              getTemplateError: "Không thể lấy mẫu quy tắc kiểm tra chất lượng",
              tabs: { template: "Mẫu", demo: "Mẫu" },
              customize: "Mẫu trống",
              move: "Kéo để điều chỉnh phân loại và sắp xếp",
              classification: "Phân loại kiểm tra chất lượng",
              classificationWeChat: "Phân loại mô hình",
              first: "Phân loại cấp một",
              second: "Phân loại thứ cấp",
              new: "Quy tắc kiểm tra chất lượng mới",
              weChatNew: "Thêm mô hình phân tích",
              createWeChat: "Mô hình phân tích mới",
              empty: "Không có quy tắc kiểm tra chất lượng,",
              wechatEmpty: "Không có mô hình phân tích,",
              newSecond: "Phân loại phụ mới",
              newFirst: "Phân loại cấp một mới",
              checkPoint: "Quy tắc kiểm tra chất lượng",
              checkPointWeChat: "Mô hình phân tích",
            },
            detail: {
              name: "Tên",
              remark: "Mô tả",
              type: "Loại",
              deduction: "Điểm trừ",
              rule: "Quy tắc",
              highlighted: "Nổi bật",
              gradeType: "Phương pháp tính điểm",
              interactiveCategory: "Phân loại tương tác",
              predeterminedScore: "Giá trị điểm",
              weight: "Trọng lượng",
              na: "N/A",
              default: "Điểm mặc định",
              preRule: "Quy tắc tiền điều kiện",
              analysisHighlighted: "Quy tắc phân tích cú pháp",
              categorySelectPrompt: "Vui lòng chọn phân loại",
              checkHighlightArrayError:
                "Các quy tắc không khớp với các điểm nổi bật, vui lòng nhấp lại vào nút quy tắc phân tích cú pháp",
              foctorsHolder: "Vui lòng chọn các yếu tố",
              foctorsErrorNone:
                "Quy tắc đa yếu tố trống, vui lòng thêm loại đa yếu tố",
              naError: "Không thể đóng mục này khi điểm mặc định là N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A có nghĩa là Không có ứng dụng, đặt điều kiện tiên quyết cho quy tắc kiểm tra chất lượng, kiểm tra khi đáp ứng các điều kiện và không áp dụng quy tắc khi không đáp ứng các điều kiện, nếu quy tắc không được kiểm tra;\nHướng dẫn điền điều kiện tiên quyết: "&&" có nghĩa là "và", "||" có nghĩa là "hoặc", "!" Có nghĩa là "không", điền vào ví dụ "(R1 || R2) && R3"',
                applicationMessageType: "Loại tin nhắn ứng dụng",
                selectAll: "Chọn tất cả",
                highlightTags: "Đánh dấu nhãn:",
                ruleType: "Loại quy tắc",
                dataSource: "Nguồn dữ liệu",
                whole: "Tất cả",
                enterpriseMicroConversation: "Phiên vi mô doanh nghiệp",
                enterpriseAndMicroCommunication: "Cuộc gọi vi mô doanh nghiệp",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Xác định các nút",
                    dialogueNode: "Nút hội thoại",
                    editNodes: "Chỉnh sửa nút",
                  },
                  nodeConfig: {
                    nodeName: "Tên nút",
                    nodeRules: "Quy tắc nút",
                    scoringLogic: "Logic chấm điểm",
                    score: "Điểm:",
                    rule: "Quy tắc",
                    multiBranchLogic: "Logic đa nhánh",
                    highlight: "Nổi bật",
                    submit: "Gửi",
                  },
                  ruleList: {
                    branch: "Chi nhánh",
                    branchName: "Tên chi nhánh:",
                    addRules: "Tăng quy tắc",
                  },
                  sidebar: { node: "Nút" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Tự động sắp xếp",
                      canvasAdaptation: "Vải thích ứng",
                      copy: "Sao chép",
                      delete: "Xóa",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Xác định rằng các nút con của nút không thể được sử dụng làm mục tiêu kết nối",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Nút bắt đầu không thể là mục tiêu kết nối",
                      startingNodeCannotConnectMultipleNodes:
                        "Nút bắt đầu không thể kết nối nhiều nút",
                      nodeCannotBeConnectedToItself:
                        "Các nút không thể kết nối với chính chúng",
                      startNode: "Nút bắt đầu",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Hủy chọn tất cả",
                    selectAll: "Chọn tất cả",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Có phù hợp không",
                    gradedEvaluation: "Đánh giá phân loại",
                  },
                },
              },
            },
            configuration: {
              title: "Quy tắc kiểm tra chất lượng",
              wechatTitle: "Mô hình phân tích",
              neme: "Tên",
              errorMessage:
                "Xóa không thành công, ít nhất phải có một điều kiện",
              desc: "Mô tả",
              status: "Trạng thái",
              yes: "Hiệu quả",
              no: "Không hợp lệ",
              namePlaceHolder: "Vui lòng nhập tên nhãn",
              desPlaceHolder: "Vui lòng nhập mô tả",
              nameEmpty: "Tên không được để trống",
              desEmpty: "Mô tả không thể trống",
              type: "Loại",
              ruler: "Quy tắc thông minh",
              machineLearning: "Chế độ tự học",
              autoScore: "Xếp hạng thông minh (cũ)",
              manualScore: "Điểm thủ công",
              interaction: "Phát hiện tương tác (cũ)",
              typeTips: {
                model:
                  'Mô hình thông minh: Đây là một bộ hệ thống kiểm tra chất lượng mặc định, không cần cấu hình và bảo trì, đồng thời có thể nhanh chóng kiểm tra chất lượng các vấn đề trong dịch vụ. Mô hình này phổ biến hơn. Nếu bạn không đáp ứng các yêu cầu kinh doanh, vui lòng sử dụng "quy tắc thông minh" hoặc "tính điểm thủ công',
                rule: "Quy tắc thông minh: tức là AI tự động chấm điểm dựa trên quy tắc",
                manual:
                  "Đánh giá thủ công: tức là kiểm tra chất lượng thủ công, chấm điểm thủ công",
              },
              model: "Mô hình:",
              ruleType: {
                words: "Lời nói",
                interaction: "Tương tác",
                feature: "Đặc điểm",
                information: "Thông tin",
              },
              testingMode: {
                all: "Phát hiện tất cả các từ khóa",
                any: "Phát hiện bất kỳ từ khóa nào",
              },
              operatorTypesOfInteraction: {
                question: "Một câu hỏi và một câu trả lời",
                element: "Câu trả lời yếu tố",
                repet: "Bối cảnh lặp lại",
                snatch: "Lấy lời",
                silent: "Im lặng",
              },
              role: "Phát hiện vai trò:",
              preposition: "Toán tử phía trước:",
              range: "Phạm vi phát hiện:",
              from: "Không.",
              to: "Câu đến đầu tiên",
              end: "Câu",
              hasOldSilentTip:
                'Xin chào, để cải thiện độ chính xác, "Quy tắc thông minh-Tương tác-Im lặng" đã được nâng cấp thành "Thời gian chờ phản hồi", nên cập nhật các quy tắc sử dụng!',
              changeToOld: "Chuyển sang phiên bản cũ",
              changeToNew: "Chuyển sang phiên bản mới",
              logic: {
                logic: "Logic chấm điểm",
                naTip:
                  "N/A có nghĩa là Không có ứng dụng, đặt điều kiện tiên quyết cho quy tắc kiểm tra chất lượng, kiểm tra khi đáp ứng các điều kiện và không áp dụng quy tắc khi không đáp ứng các điều kiện, nếu quy tắc không được kiểm tra",
                score: "Điểm:",
                na: "N/A：",
                naDes: "Điều này không liên quan đến thử nghiệm cho phép",
                preRule: "Quy tắc tiền điều kiện:",
                notCatchPreRule: "Khi điều kiện tiên quyết không được đáp ứng:",
                naChecked: "Khi đánh N/A:",
                preRuleEmpty: "Quy tắc tiền điều kiện không được để trống",
                operatorEmpty:
                  "Các điều kiện cần thiết trong toán tử không được để trống",
                multipleEmpty:
                  "Các điều kiện cần thiết trong nhiều điểm logic không được để trống",
                average: "Điểm trung bình trong nhóm",
                addOrSubtract: "Cộng trừ điểm trực tiếp",
                noChange: "Không cộng trừ điểm",
                rule: "Quy tắc:",
                all: "Thỏa mãn tất cả các toán tử",
                any: "Thỏa mãn các toán tử tùy ý",
                customize: "Logic toán tử tùy chỉnh",
                multiple: "Nhiều điểm logic",
                heightLight: "Làm nổi bật:",
                machineLearningTip:
                  "Chế độ tự học hiện chỉ có hiệu lực đối với các từ đơn, vui lòng sử dụng các quy tắc thông minh cho các từ phức tạp",
                strategy: "Chiến lược:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Phê duyệt đệ trình thành công",
            },
            components: {
              pageHeaderExtra: {
                test: "Kiểm tra",
                formal: "Chính thức",
                submit: "Gửi",
              },
              createModal: { index: { myRules: "Quy tắc của tôi" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Tự động hóa quy trình",
              screeningCondition: "Điều kiện sàng lọc",
              aiScreeningCondition: "Điều kiện sàng lọc thông minh",
              rule: "Quy tắc",
              automaticAddition: "Tự động thêm",
              automaticAdditionTip:
                "Tự động thêm dữ liệu vào tác vụ trong thời gian thực hoặc thường xuyên để kiểm tra chất lượng AI",
              autoAdd: {
                autoAddType: "Cách tự động thêm",
                addFrequency: "Thêm tần số",
                add: "Thêm",
              },
              automaticSamplingInspection: "Kiểm tra lấy mẫu tự động",
              automaticSamplingInspectionTip:
                "Tự động trích xuất dữ liệu yêu cầu kiểm tra chất lượng thủ công. Ví dụ: 10% dữ liệu ngẫu nhiên của ngày hôm qua được trích xuất hàng ngày để kiểm tra ngẫu nhiên thủ công",
              everyAgentCheckLimit: "Giới hạn trên cho mỗi dịch vụ khách hàng",
              automaticAllocation: "Phân phối tự động",
              automaticAllocationTip:
                "Phân bổ kiểm tra ngẫu nhiên: Dữ liệu được chọn được tự động phân bổ cho nhân viên kiểm tra chất lượng. Ví dụ: 10% dữ liệu ngẫu nhiên của ngày hôm qua được chọn hàng ngày để kiểm tra ngẫu nhiên thủ công và 2 nhân viên kiểm tra chất lượng được phân bổ ngẫu nhiên cho nhiệm vụ này.",
              automaticAllocationTip2:
                "Phân bổ đánh giá: Dữ liệu của khiếu nại được tự động gán cho thanh tra chất lượng để xem xét. Ví dụ: dữ liệu của khiếu nại hàng ngày được phân bổ ngẫu nhiên cho hai thanh tra chất lượng của nhiệm vụ này để xem xét.",
              automaticPush: "Đẩy tự động",
              automaticPushTip:
                "Thông báo kích hoạt: thông báo/cảnh báo được thực hiện trong quá trình kiểm tra chất lượng. Ví dụ: khi có lỗi nghiêm trọng trong dịch vụ khách hàng, hãy gửi tin nhắn thông báo đến nhóm doanh nghiệp",
              automaticPushTip2:
                "Báo cáo định kỳ: Gửi báo cáo định kỳ kết quả kiểm tra chất lượng qua email. Ví dụ: gửi kết quả kiểm tra chất lượng của ngày hôm qua đến hộp thư của giám sát dịch vụ khách hàng vào lúc 9:00 hàng ngày",
              automaticAssess: "Đánh giá tự động",
              automaticAssessTip:
                "Thường xuyên so sánh kết quả kiểm tra ngẫu nhiên thủ công với kết quả kiểm tra chất lượng AI để đánh giá độ chính xác. Ví dụ: đánh giá độ chính xác của dữ liệu lấy mẫu ngày hôm qua vào lúc 8 giờ hàng ngày",
              reexaminationComplaint: "Kháng nghị kiểm tra lại",
              spoktReviewDetail: "Cấu hình kinh doanh",
              spoktReviewDetailTip:
                "Cấu hình các chức năng như danh sách kiểm tra chất lượng, danh sách kiểm tra ngẫu nhiên, dịch vụ khiếu nại, danh sách đánh giá và nhận xét",
              qaDetailAction: "Cấu hình kiểm tra chất lượng",
              spoktDetailAction: "Cấu hình lấy mẫu",
              reviewDetailAction: "Xem lại cấu hình",
              appealAction: "Cấu hình kháng nghị",
              remarkTemplate: "Mẫu nhận xét",
              automaticArchiving: "Lưu trữ tự động",
              conditionalRules: "Quy tắc điều kiện",
              qualityInspectionConditions: "Điều kiện kiểm tra chất lượng",
              unopened: "Chưa mở",
              sampleInspectionDistributionModel:
                "Phân phối kiểm tra ngẫu nhiên",
              reviewAllocationModel: "Xem lại phân phối",
              distributionModelTitle: "Chiến lược phân phối:",
              pushRules: "Quy tắc đẩy",
              push: "Đẩy",
              assessRules: "Quy tắc đánh giá",
              on: "on",
              off: "off",
              updateStatusSuccess: "Thay đổi trạng thái thành công!",
              updateStatusFailed:
                "Thay đổi trạng thái không thành công, vui lòng thử lại sau!",
              sampleNumberOrPercent: "Số lượng hoặc tỷ lệ lấy mẫu",
              inspectorList: "Thanh tra chất lượng được chỉ định",
              numberOfSubmission: "Cho phép gửi số lượng tuyên bố",
              rewivewDays: "Ngày",
              TimeOfSubmission: "Thời hạn hiệu lực của tuyên bố nộp",
              rewivewSecond: "Lần",
              switchOn: "Mở",
              switchOff: "Đóng cửa",
              notMatchAnyRule: "Khi không phù hợp với quy tắc tùy ý:",
              appealOptionsList: {
                hiddenInspector: "Khiếu nại ẩn thông tin thanh tra chất lượng",
                hiddenHit: "Trang khiếu nại hỗ trợ đánh dấu đánh dấu vị trí",
              },
              caseLibrary: "Đồng bộ hóa thư viện trường hợp",
              caseLibrarySyncTip:
                "Đồng bộ hóa các trường hợp được thêm vào hệ thống QA vào hệ thống dịch vụ khách hàng",
              addCase: "Tăng đồng bộ hóa",
              caseLibraryId: "Thư viện trường hợp",
              caseLibraryIdTip: "Nhóm thư viện trường hợp của hệ thống QA",
              udeskCaseLibraryIdTip:
                "Nhóm trường hợp của hệ thống dịch vụ khách hàng",
              syncHis: "Trường hợp lịch sử đồng bộ",
              otherSettings: "Cài đặt khác",
              udeskCaseLibraryId: "Nhóm trường hợp đẩy",
              url: "URL đẩy",
              urlTip:
                "Vui lòng nhập địa chỉ giao diện bắt đầu bằng http:// hoặc https://",
            },
            templateNew: {
              secondaryReview: "Đánh giá cấp hai",
              numberOfAppealsAllowedToBeSubmitted:
                "Cho phép gửi số lượng khiếu nại",
              validityOfSubmissionOfAppeal:
                "Thời hạn hiệu lực của việc nộp đơn khiếu nại",
              validityOfAppealReview:
                "Thời hạn hiệu lực của việc xem xét kháng cáo",
              hours: "Giờ",
              businessSummary: "Tóm tắt kinh doanh",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Giới hạn dưới của mỗi dịch vụ khách hàng",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Tự động tạo tóm tắt kinh doanh từ mô hình lớn, kích thước thế hệ có thể được tùy chỉnh theo tình hình kinh doanh",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Chiến lược phân bổ đánh giá cấp một chỉ",
              secondaryCompounding: "Hợp chất thứ cấp:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Người đánh giá thứ cấp chỉ hỗ trợ phân bổ trung bình",
              secondLevelReviewer: "Đánh giá viên cấp hai:",
              samplingInspectionTarget: "Mục tiêu kiểm tra lấy mẫu",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Đặt mục tiêu kiểm tra ngẫu nhiên thủ công cần hoàn thành và đưa ra lời nhắc khi mục tiêu không đạt hoặc vượt quá. Ví dụ: mỗi dịch vụ khách hàng phải hoàn thành 100 lần kiểm tra ngẫu nhiên mỗi tháng. Khi ít hơn 80, trang kiểm tra chất lượng và kiểm tra ngẫu nhiên sẽ nhắc nhở.",
            },
            component: {
              verificationFailed: "Xác minh không thành công",
              pleaseAddASecondLevelQualityInspector:
                "Vui lòng thêm thanh tra chất lượng cấp hai",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Lời nhắc nhở",
                    fieldName: "Tên trường",
                    fieldDescription: "Mô tả trường",
                    generateContent: "Tạo nội dung",
                    enabled: "Có bật không",
                    generationMethod: "Phương pháp tạo",
                    automaticGeneration: "Tự động tạo",
                    manualTrigger: "Kích hoạt thủ công",
                    filterCondition: "Điều kiện sàng lọc",
                    return: "Trở về",
                    preserve: "Lưu",
                  },
                  components: {
                    training: { index: { generateResults: "Tạo kết quả" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Không giới hạn",
                    limitedScope: "Phạm vi giới hạn",
                    add: "Thêm",
                    thereIsCurrentlyNoDataAvailable: "Không có dữ liệu",
                  },
                },
              },
              index: {
                fieldName: "Tên trường",
                fieldDescription: "Mô tả trường",
                nothing: "Không có",
                generationMethod: "Phương pháp tạo",
                generateManually: "Tạo thủ công",
                automaticGeneration: "Tự động tạo",
                generateContent: "Tạo nội dung",
                notQualified: "Không giới hạn",
                filterCondition: "Điều kiện sàng lọc",
                enabled: "Có bật không",
                type: "Loại",
                system: "Hệ thống",
                custom: "Tùy chỉnh",
                operation: "Hoạt động",
                edit: "Chỉnh sửa",
                areYouSureToDeleteIt: "Chắc chắn xóa?",
                delete: "Xóa",
                addField: "Tăng trường",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Cấu hình nhân vật",
              roleConfigTip:
                "Nó được sử dụng để tách các quy tắc nhận dạng cấu hình cho người gọi khi dữ liệu cuộc gọi được nhập là đơn âm. Vai trò dịch vụ khách hàng tuân thủ các quy tắc",
              operator: "Toán tử",
              operatorLogic: "Logic toán tử",
            },
          },
          manualAdd: {
            index: {
              name: "Thêm thủ công",
              tip: "Được sử dụng để lọc dữ liệu xem trước thông qua các điều kiện cấu hình thủ công và nhanh chóng thêm nó vào các nhiệm vụ kiểm tra chất lượng",
              screeningCondition: "Thêm theo bộ lọc",
              aiScreeningCondition: "Điều kiện sàng lọc thông minh",
              rule: "Quy tắc",
              createTime: "Thêm thời gian",
              filterTitle: "Lọc nội dung",
              filterRule: "Điều kiện sàng lọc",
              startTime: "Thời gian bắt đầu",
              endTime: "Thời gian kết thúc",
              records: "Nhập bản ghi",
              createUserName: "Nhân viên vận hành",
              successCount: "Thêm thành công",
              conditionRule: "Quy tắc điều kiện:",
              preview: "Xem trước",
              previewData:
                "Hiện tại đáp ứng tiêu chí lọc {0}, đã thêm nhiệm vụ {1}, có thể thêm nhiệm vụ {2}, không thể thêm nhiệm vụ {3}",
              failCount: "Thêm thất bại",
            },
          },
          basicInfo: {
            index: {
              title: "Thông tin cơ bản",
              name: "Tên",
              taskName: "Tên nhiệm vụ",
              taskNamePlaceholder: "Vui lòng nhập tên nhiệm vụ",
              requiredName: "Tên nhiệm vụ không được để trống",
              taskRemarkPlaceholder: "Vui lòng nhập mô tả nhiệm vụ",
              requiredRemark: "Mô tả nhiệm vụ không được để trống",
              remark: "Mô tả",
              taskRemark: "Mô tả nhiệm vụ",
              source: "Nguồn dữ liệu",
              benchmarkScore: "Điểm chuẩn",
              benchmarkScorePlaceholder: "Vui lòng nhập điểm chuẩn",
              scoreLowerLimit: "Điểm tối thiểu thấp hơn",
              scoreUpperLimit: "Giới hạn điểm tối đa",
              inspectType: "Loại hình kiểm tra chất lượng",
              nlpModel: "Mô hình NLP",
              commonInspect: "Kiểm tra chất lượng thường xuyên",
              associateInspect: "Kiểm tra chất lượng liên quan",
              associateCycle: "Chu kỳ liên quan",
              associateRegulation: "Quy tắc liên kết",
              judgeStrategy: "Thực hiện logic",
              rule: "Quy tắc",
              commonTip:
                "Kiểm tra chất lượng định kỳ: thích hợp cho một lần giao tiếp và đối thoại, kiểm tra chất lượng nội dung của một lần giao tiếp",
              associateTip:
                "Kiểm tra chất lượng liên quan: Thích hợp cho nhiều cuộc giao tiếp và đối thoại, liên kết nội dung của nhiều cuộc giao tiếp để kiểm tra chất lượng",
              cycleTip:
                "Nếu nhiều liên lạc và đối thoại được liên kết, dữ liệu trong chu kỳ liên kết tuân thủ được nhập vào kiểm tra chất lượng nhiệm vụ",
              scoreLowerLimitPlaceholder:
                "Vui lòng nhập giới hạn dưới của điểm tối thiểu",
              scoreUpperLimitPlaceholder: "Vui lòng nhập giới hạn điểm tối đa",
              call: "Cuộc gọi thoại",
              dialogue: "Đối thoại văn bản",
              user: "Thanh tra chất lượng",
              wechatUser: "Nhà điều hành",
              noDesignated: "Không chỉ định",
              type: "Loại biểu mẫu",
              singleScore: "Điểm đơn",
              conduct:
                "Vui lòng cải thiện thông tin sau trước, sau khi hoàn thành, vui lòng nhấp vào Tiếp theo",
              conduct2:
                "Vui lòng hoàn thành các quy tắc kiểm tra chất lượng và cấu hình điểm kiểm tra chất lượng. Nếu bạn đã hoàn thành cấu hình, bạn có thể nhấp vào Tiếp theo hoặc thoát hướng dẫn",
              conduct3:
                "Xếp hạng kiểm tra chất lượng là cấu hình xếp hạng dựa trên điểm kiểm tra chất lượng và trạng thái xếp hạng của nó. Bạn có thể xác định các mức kết quả kiểm tra chất lượng khác nhau. Nếu bạn không cần xếp hạng, bạn có thể bỏ qua bước này. Nếu bạn đã hoàn thành cấu hình, bạn có thể nhấp vào Tiếp theo hoặc thoát khỏi hướng dẫn",
              conduct4:
                "Bạn có thể định cấu hình các đặc điểm dữ liệu của tác vụ sẽ được tự động giới thiệu ở vị trí tự động thêm dữ liệu theo các đặc điểm dữ liệu cần được kiểm tra trong nhiệm vụ kiểm tra chất lượng này. Nếu tạm thời không cần tự động thêm dữ liệu, bạn có thể nhấp để bỏ qua. Nếu bạn đã hoàn thành cấu hình, Bạn có thể nhấp vào Tiếp theo",
              conduct5:
                "Nếu bạn đang sử dụng bản ghi đơn âm, vui lòng cấu hình mục này và nhấp vào Tiếp theo, vui lòng sử dụng chỉ dịch vụ khách hàng sẽ sử dụng kỹ thuật từ ngữ để giúp hệ thống xác định các bản nhạc khác nhau giữa dịch vụ khách hàng và khách hàng. Nếu bạn sử dụng bản ghi hai kênh hoặc đã hoàn thành cấu hình, bạn có thể nhấp để bỏ qua",
              conduct6:
                "Bạn có thể thêm chức năng theo cách thủ công, giới thiệu dữ liệu hội thoại hiện có trong hệ thống để kiểm tra chất lượng, hoặc bạn đã gửi tác vụ thêm thủ công, vui lòng nhấp trực tiếp để hoàn thành",
              helper: "Xem tài liệu trợ giúp",
              quit: "Thoát khỏi hướng dẫn",
              next: "Kế tiếp",
              complete: "Hoàn thành",
              skip: "Bỏ qua",
              cancel: "Hủy bỏ",
              delete: "Xóa",
              save: "Lưu",
            },
            template: {
              samplingInspector: "Kiểm tra ngẫu nhiên",
              sessionType: "Loại phiên",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Bàn làm việc kiểm tra chất lượng",
              search: "Sàng lọc",
              export: "Xuất",
              condition: "Điều kiện",
              logic: "Logic",
              reQualityInspection: "Kiểm tra lại",
              reQualityInspectionUnderWay: "Nhiệm vụ kiểm tra lại...",
              reQualityInspectionUnderWayTip:
                "Rời khỏi trang sau khi đóng cửa sổ sẽ không ảnh hưởng đến tiến độ tác vụ",
              reQualityInspectionDone: "Kiểm tra lại đã hoàn thành",
              reQualityInspectionDoneTip:
                "Kiểm tra lại đã được hoàn thành thành công, vui lòng nhấp vào nút xác nhận",
              endReQualityInspection: "Chấm dứt nhiệm vụ",
              alreadyDoneTip: "Đã hoàn thành",
              totalTip: "Tổng số",
              unit: "Sọc",
              endReInspectionSuccess: "Nhiệm vụ kiểm tra lại đã bị chấm dứt",
              closeWindow: "Đóng cửa sổ",
              endReInspectionFailed:
                "Nhiệm vụ kiểm tra lại không thành công, vui lòng thử lại sau!",
            },
            detail: {
              name: "Chi tiết kiểm tra chất lượng",
              reviewName: "Xem lại chi tiết",
              spotCheckName: "Chi tiết kiểm tra lấy mẫu",
              audioText: "Văn bản ghi âm",
              callInfo: "Thông tin cuộc gọi",
              satisfactionEvaluation: "Đánh giá sự hài lòng:",
              queueTime: "Thời gian xếp hàng:",
              ringingTime: "Chuông thời gian:",
              callTime: "Sự kiện cuộc gọi:",
              hangUpIdentity: "Bên treo cuộc gọi:",
              location: "Ghi công:",
              source: "Nguồn:",
              agent: "Dịch vụ khách hàng:",
              deviceType: "Loại thiết bị:",
              callType: "Loại cuộc gọi:",
              phoneNumber: "Số tiếp sức:",
              task: "Nhiệm vụ:",
              commentsTip: "Vui lòng nhập nhận xét kiểm tra chất lượng",
              lastPage: "Trang trước",
              nextPage: "Tiếp theo",
              score: "Điểm kiểm tra chất lượng",
              review: "Xem lại",
              file: "Lưu trữ",
              changeList: "Thay đổi hồ sơ",
              fullMarks: "Điểm đầy đủ",
              most: "Nhiều nhất",
              saveReview: "Xem xét và nộp",
              saveSpotCheck: "Gửi kiểm tra ngẫu nhiên",
              point: "Điểm kiểm tra chất lượng",
              sampleAssignInspector: "Chỉ định nhân viên kiểm tra ngẫu nhiên",
              reviewAssignInspector: "Phân công người kiểm toán",
              sampleStatus: "Tình trạng lấy mẫu",
              read: "Đã đọc",
              user: "Người dùng",
              confirmer: "Người xác nhận",
              voiceDownloadError:
                "Tải xuống giọng nói không thành công, vui lòng thử lại sau!",
              smartTags: "Nhãn thông minh",
              interactiveTip:
                "Sử dụng nhận dạng tương tác để phát hiện việc sử dụng FAQ của dịch vụ khách hàng",
              interactiveIsEmptyTip:
                "Không phát hiện nội dung nhận dạng tương tác",
              businessRecords: "Hồ sơ kinh doanh",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Phân tích trò chuyện nhóm WeChat doanh nghiệp",
            },
          },
        },
      },
      cards: {
        health: "Kiểm tra chất lượng sức khỏe",
        accuracy: "Tỷ lệ phê duyệt được tìm thấy",
        sessionsTotal: "Tổng số phiên",
        enable: "Kích hoạt",
        disable: "Vô hiệu hóa",
        none: "Không",
        call: "Cuộc gọi",
        dialogue: "Đối thoại",
        taobao: "Thương mại điện tử",
        wecom: "Phiên",
        ticket: "Danh sách công việc",
      },
      component: {
        backup: "(Sao lưu)",
        taskSaveAsSucceeded: "Lưu nhiệm vụ để thành công",
        copyContent: "Sao chép nội dung",
        essentialInformation: "Thông tin cơ bản",
        ruleConfiguration: "Cấu hình quy tắc",
        basicInformationQualityInspector:
          "Thông tin cơ bản-Thanh tra chất lượng",
        qualityInspectionRating: "Xếp hạng kiểm tra chất lượng",
        roleConfiguration: "Cấu hình nhân vật",
        autoAddConfiguration: "Tự động thêm cấu hình",
        autoPushConfiguration: "Cấu hình đẩy tự động",
        automaticSamplingConfiguration: "Cấu hình lấy mẫu tự động",
        automaticAllocationConfiguration: "Tự động phân bổ cấu hình",
        businessConfiguration: "Cấu hình kinh doanh",
        caseBaseConfiguration: "Thư viện trường hợp",
      },
      template: {
        cancel: "Hủy bỏ",
        complete: "Hoàn thành",
        sort: "Sắp xếp",
        ruleApproval: "Quy tắc phê duyệt",
        enterpriseMicroConversationAnalysisTask:
          "Nhiệm vụ phân tích phiên vi mô doanh nghiệp",
        enterpriseAndMicroVoiceAnalysisTask:
          "Nhiệm vụ phân tích giọng nói vi mô doanh nghiệp",
        saveTaskAs: "Lưu nhiệm vụ dưới dạng",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Chi tiết quy tắc" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Sửa đổi thành công",
            automaticApproval: "Phê duyệt tự động",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Sửa đổi thành công",
            test: "Kiểm tra",
            formal: "Chính thức",
            adopt: "Thông qua",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Bạn có chắc chắn để từ chối",
              pleaseEnterTheRejectionReason: "Vui lòng nhập lý do từ chối",
              reject: "Bác bỏ",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Sửa đổi thành công",
              taskName: "Tên nhiệm vụ",
              dataSource: "Nguồn dữ liệu",
              qualityInspectionType: "Loại hình kiểm tra chất lượng",
              state: "Trạng thái",
              updateTime: "Thời gian cập nhật",
              updatedBy: "Cập nhật người",
              approver: "Người phê duyệt",
              reasonForRejection: "Lý do bác bỏ",
              operation: "Hoạt động",
              details: "Chi tiết",
              adopt: "Thông qua",
              whole: "Tất cả",
              approvalStatus: "Trạng thái phê duyệt",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Tổng quan",
      hi: "Chào,",
      standardEdition: "Phiên bản tiêu chuẩn",
      call: "Số dư kiểm tra chất lượng bằng giọng nói",
      text: "Thời hạn hiệu lực kiểm tra chất lượng văn bản",
      collectionTasks: "Nhiệm vụ kiểm tra chất lượng thu thập",
      noCollection: "Không có nhiệm vụ kiểm tra chất lượng thu thập,",
      goToCollecte: "Đi bộ sưu tập",
      yesterdayOverView: "Thống kê kinh doanh ngày hôm qua",
      yesterdayAgent: "Xếp hạng dịch vụ khách hàng ngày hôm qua",
      yesterdayInspWork: "Công việc của thanh tra chất lượng ngày hôm qua",
      operationalStatistics: {
        title: "Thống kê kinh doanh",
        qualityTesting: "Kiểm tra chất lượng",
        spotCheck: "Lấy mẫu",
        appeal: "Kháng nghị",
        chartTitles: {
          qualityTestingTrend: "Xu hướng kiểm tra chất lượng",
          qualityTestingAnalysis: "Kiểm tra chất lượng nhiệm vụ",
          spotCheckTrend: "Xu hướng lấy mẫu",
          spotCheckAnalysis: "Kiểm tra ngẫu nhiên nhiệm vụ",
          appealTrend: "Xu hướng kháng cáo",
          appealAnalysis: "Tình hình nhiệm vụ khiếu nại",
        },
        titles: {
          qualityDataTend: "Phân phối dữ liệu kiểm tra chất lượng",
          qualityCover: "Phạm vi kiểm tra chất lượng",
          qualityAverageTime: "Thời gian chờ kiểm tra chất lượng trung bình",
          qualityCoverTend: "Xu hướng bao phủ kiểm tra chất lượng",
          handCover: "Xử lý thủ công",
          checkCover: "Phạm vi kiểm tra lấy mẫu",
          resAverageTime: "Thời gian xử lý trung bình",
          handTend: "Xu hướng xử lý nhân tạo",
          dataTend: "Phân phối nhiệm vụ dữ liệu",
        },
        qualityAverage: "Điểm trung bình kiểm tra chất lượng",
        scoreTrend: "Xu hướng chấm điểm kiểm tra chất lượng",
        qualityAverageTime: "Thời gian chờ kiểm tra chất lượng trung bình",
        callCoverage: "Tỷ lệ bao phủ kiểm tra chất lượng bằng giọng nói",
        textCoverage: "Bảo hiểm kiểm tra chất lượng văn bản",
        checkCount: "Khối lượng dữ liệu lấy mẫu",
        siphonCount: "Số lượng dữ liệu được trích xuất",
        reviewCount: "Xem lại khối lượng dữ liệu",
        appealCount: "Khối lượng dữ liệu khiếu nại",
        checkRate: "Tỷ lệ thực hiện kiểm tra lấy mẫu",
        reviewRate: "Tỷ lệ xem xét kháng cáo",
        PieTitle: {
          totalCount: "Tổng số cuộc đối thoại",
          totalCall: "Số cuộc gọi thoại",
          totalIm: "Số đối thoại văn bản",
          inspectionCall: "Kiểm tra chất lượng bằng giọng nói",
          inspectionIm: "Kiểm tra chất lượng văn bản",
          checkCall: "Kiểm tra lấy mẫu bằng giọng nói",
          unCheckCall: "Số lượng giọng nói không được kiểm tra",
          checkIm: "Kiểm tra lấy mẫu văn bản",
          unCheckIm: "Số lượng văn bản chưa được kiểm tra",
          reviewCall: "Khối lượng đánh giá bằng giọng nói",
          reviewIm: "Khối lượng xem xét văn bản",
          unReviewCall: "Số lượng giọng nói chưa được xem xét",
          unReviewIm: "Số lượng văn bản chưa được xem xét",
          unInspectionCall: "Số lượng giọng nói chưa được kiểm tra",
          unInspectionIm: "Văn bản chưa được kiểm tra",
        },
        check_rate: "Phạm vi kiểm tra lấy mẫu",
        voice_check_rate: "Phạm vi kiểm tra lấy mẫu bằng giọng nói",
        text_check_rate: "Bảo hiểm kiểm tra lấy mẫu văn bản",
        sample_execute_time: "Thời gian xử lý lấy mẫu trung bình",
        review_execute_time: "Thời gian xử lý khiếu nại trung bình",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Kanban quy tắc kiểm tra chất lượng",
          agent: "Xếp hạng dịch vụ khách hàng",
        },
        tableTips: {
          conditions:
            "<Div> Sắp xếp tổng số cuộc đối thoại chung vi phạm và tuân thủ các quy tắc kiểm tra chất lượng. <Br/>\nMục kiểm tra chất lượng: tên của điểm kiểm tra chất lượng <br />\nTổng số cuộc đối thoại bất hợp pháp: tổng số cuộc đối thoại bất hợp pháp trong mục kiểm tra chất lượng <br />\nTỷ lệ vi phạm: tỷ lệ vi phạm = tổng số cuộc đối thoại bất hợp pháp/tổng số cuộc đối thoại </div>",
          agent:
            "<Div> Sắp xếp xếp hạng trung bình cho chỗ ngồi <br />\nTên dịch vụ khách hàng: tên dịch vụ khách hàng <br />\nNhóm dịch vụ khách hàng: Nhóm dịch vụ khách hàng nơi đặt dịch vụ khách hàng (thông tin tổ chức trong thông tin dịch vụ khách hàng)<br />\nTổng số cuộc đối thoại được xếp hạng: tổng số cuộc đối thoại kiểm tra chất lượng của dịch vụ khách hàng <br />\nĐiểm kiểm tra chất lượng trung bình: Điểm kiểm tra chất lượng trung bình của dịch vụ khách hàng (tổng điểm/tổng số đối thoại)</div>",
        },
        tips: {
          total:
            "Tổng số cuộc nói chuyện/đối thoại tham gia nhiệm vụ kiểm tra chất lượng",
          agentTotal:
            "Tổng số dịch vụ khách hàng kiểm tra chất lượng tham gia nhiệm vụ kiểm tra chất lượng",
          avg: "Điểm trung bình của điểm kiểm tra chất lượng",
          violation:
            "Tổng số lần vi phạm kiểm tra chất lượng của tất cả các cuộc đối thoại",
          compliance:
            "Tổng số lần tuân thủ các hạng mục kiểm tra chất lượng trong quá trình kiểm tra chất lượng",
          check: "Tổng số kiểm tra lấy mẫu thủ công",
          appeal:
            "Tổng số đơn khiếu nại đã được gửi trong tổng số cuộc đối thoại",
          review:
            "Tổng số bài đánh giá đã được gửi trong tổng số cuộc đối thoại",
        },
        subTips: {
          violation:
            "Tỷ lệ vi phạm: tổng số vi phạm/tổng số lần kiểm tra chất lượng * 100%",
          compliance:
            "Tỷ lệ tuân thủ: tổng số lần tuân thủ/tổng số lần kiểm tra chất lượng * 100%",
          check:
            "Tỷ lệ kiểm tra lấy mẫu: tổng số kiểm tra lấy mẫu thủ công/tổng số kiểm tra chất lượng * 100%",
          appeal:
            "Tỷ lệ khiếu nại: tổng số khiếu nại/tổng số kiểm tra chất lượng * 100%",
          review:
            "Tỷ lệ đánh giá: tổng số lần đánh giá/tổng số lần kiểm tra chất lượng * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Cài đặt hệ thống",
          systemName: "Tên hệ thống",
          speakerRecognize: "Nhận dạng người nói",
          massage: "Thông báo tin nhắn",
          evaluate: "Đánh giá nhận thức nhân tạo",
          evaluateTip:
            "Sau khi mở, chọn kiểm tra lấy mẫu nhanh trong chi tiết kiểm tra chất lượng và sẽ nhắc đánh giá thủ công",
          speakerRecognizeTip:
            "Truy cập bản ghi cuộc gọi của bên thứ ba thông qua API cần xác định kênh bên trái là dịch vụ khách hàng hay khách hàng",
          speakerRecognizeTip2:
            "Ghi âm một kênh xác định vai trò của dịch vụ khách hàng thông qua nhận dạng văn bản và cấu hình vai trò",
          enabledImSendStatusFilter: "Lọc tin nhắn kiểm tra chất lượng văn bản",
          enabledImSendStatusFilterTips:
            "Nội dung kiểm tra của thuật ngữ toán tử kiểm tra chất lượng không chứa tin nhắn có trạng thái [đã rút], [gửi không thành công], [tin nhắn ngoại tuyến chưa đọc]",
          lang: "Cài đặt ngôn ngữ hệ thống",
          zh: "Tiếng Trung giản thể",
          en: "English",
          tw: "Tiếng Trung phồn thể",
        },
        template: {
          largeModelSettings: "Thiết lập mô hình lớn",
          indonesian: "Tiếng Indonesia",
          vietnamese: "Tiếng Việt",
          portuguese: "Tiếng Bồ Đào Nha",
          malay: "Tiếng Mã Lai",
          french: "Tiếng Pháp",
          spanish: "Tiếng Tây Ban Nha",
          japanese: "Tiếng Nhật",
          thai: "Tiếng Thái",
          blockSystemSendingMessages: "Hệ thống che chắn gửi tin nhắn",
          textDataDesensitization: "Văn bản dữ liệu giải mẫn cảm",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Công tắc được bật và danh sách thả xuống của trường giải mẫn cảm sẽ hiển thị (hỗ trợ nhiều lựa chọn), trường giải mẫn cảm: tên người, số điện thoại di động, địa chỉ",
          desensitizingField: "Trường giải mẫn cảm",
          name: "Tên người",
          cellphoneNumber: "Số điện thoại",
          address: "Địa chỉ",
          dataCleaning: "Làm sạch dữ liệu",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Sau khi thiết lập, dữ liệu cuộc trò chuyện qua kiểm tra chất lượng và cuộc trò chuyện WeChat của công ty sẽ được dọn dẹp thường xuyên và không thể khôi phục. Vui lòng thận trọng",
          newCleaningRules: "Thêm quy tắc dọn dẹp",
          qualityInspectionRuleApproval:
            "Phê duyệt quy tắc kiểm tra chất lượng",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Sau khi mở, việc sửa đổi các quy tắc kiểm tra chất lượng cần được phê duyệt trước khi có hiệu lực",
          maskOfflineMessageUnread: "Chặn tin nhắn ngoại tuyến chưa đọc",
          privacyNumberProtection: "Bảo vệ số riêng tư",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'Sau khi mở, trường "Điện thoại khách hàng" trong cuộc gọi được giải mẫn cảm và hiển thị là 1881 *******',
          shieldingWithdrawn: "Lá chắn đã được rút",
          maskSendingFailed: "Chặn gửi không thành công",
        },
        component: {
          conversation: "Cuộc gọi",
          dialogue: "Đối thoại",
          weCom: "WeChat doanh nghiệp",
          day: "Ngày",
          week: "Tuần",
          month: "Tháng",
          year: "Năm",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Làm sạch phạm vi",
            dataCleaningTimeSetting: "Cài đặt thời gian làm sạch dữ liệu",
            afterCleanUpTheData: "Sau khi dọn dẹp dữ liệu",
            dataAfterComplaintCompletion: "Dữ liệu sau khi kháng cáo hoàn tất",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Thực hiện thành công",
          saveSuccessful: "Lưu thành công",
          speechRecognition: "Nhận dạng giọng nói",
          reExecuteErrorCorrection: "Thực hiện lại sửa lỗi",
          confirmExecution: "Xác nhận thực hiện",
          voiceDetection: "Phát hiện giọng nói",
          noiseDetectionPowerLevel: "Phát hiện tiếng ồn powerLevel ≤",
          pleaseEnterTheThreshold: "Vui lòng nhập ngưỡng",
          volumeDetectionPowerLevel: "Phát hiện âm lượng powerLevel ≥",
          reset: "Đặt lại",
          preserve: "Lưu",
        },
        coachTemplate: {
          french: "Tiếng Pháp",
          spanish: "Tiếng Tây Ban Nha",
          japanese: "Tiếng Nhật",
          thai: "Tiếng Thái",
          loginPopupWindow: "Đăng nhập cửa sổ bật lên",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Sau khi mở, cửa sổ bật lên sẽ được nhắc khi vào hệ thống đào tạo thông minh",
          title: "Tiêu đề",
          text: "Văn bản",
          checkBoxContent: "Kiểm tra nội dung hộp",
          informedConsentForm: "Đồng ý thông báo",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Để giúp bạn cải thiện kỹ năng giao tiếp và nói chuyện cũng như ứng dụng kiến thức chuyên môn, cho phép bạn thực hành mọi lúc, mọi nơi và nhận phản hồi theo thời gian thực, chúng tôi sẽ thu thập thông tin sau từ bạn thông qua nền tảng này:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Tôi đã đọc đầy đủ và hiểu đầy đủ về sự đồng ý được thông báo này và long trọng tuyên bố rằng tôi đồng ý xử lý thông tin cá nhân nhạy cảm của mình theo mục đích và phương pháp được mô tả trong sự đồng ý được thông báo này",
        },
      },
      systemIntegration: {
        title: "Cài đặt hệ thống",
        ccIntegrationTips:
          "Tài liệu mô tả trung tâm cuộc gọi Udesk kiểm tra chất lượng thông minh",
        unauthorized: "Không được ủy quyền",
        authorized: "Được ủy quyền",
        udeskDomain: "Tên miền hệ thống dịch vụ khách hàng",
        udeskToken: "Khóa hệ thống dịch vụ khách hàng",
        udeskAdminEmail: "Tài khoản quản trị viên hệ thống dịch vụ khách hàng",
        password: "Mật khẩu tài khoản quản trị viên",
        applyAuth: "Ủy quyền",
        cancelAuth: "Hủy ủy quyền",
        reApplyAuth: "Ủy quyền lại",
        keyTips:
          "Quản lý nền hệ thống dịch vụ khách hàng-xác thực API trong đăng nhập một lần Token",
      },
      customerField: {
        title: "Trường khách hàng",
        table: {
          propName: "Tên trường",
          propDesc: "Mô tả trường",
          valueType: "Loại trường",
          propType: "Cách tạo",
          createTime: "Tạo thời gian",
          canNotEdit: "Trường hệ thống không hỗ trợ chỉnh sửa",
        },
        edit: "Trường chỉnh sửa",
        new: "Trường mới",
      },
      wechatDocking: {
        title: "Kết nối vi mô doanh nghiệp",
        formLabels: {
          companyId: "ID doanh nghiệp",
          communicationKey: "Khóa giao tiếp",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "URL gọi lại sự kiện",
          applicationName: "Tên ứng dụng",
          applicationId: "ID ứng dụng web",
          applicationSecret: "Ứng dụng secret",
        },
        formTitle: {
          initialForm: "Thiết lập ban đầu",
          synchronizeForm: "Đồng bộ hóa sổ địa chỉ",
          settingForm: "Cài đặt ứng dụng",
        },
        qrCodeUrlTip:
          "Vui lòng quét mã QR bên dưới để liên kết ứng dụng phát triển",
      },
      taobao: {
        formLabels: {
          name: "Tên cửa hàng",
          account: "Tài khoản",
          password: "Mật khẩu",
          confirm: "Xác nhận mật khẩu",
          authorization: "Ủy quyền",
          switchOn: "Mở",
          switchOff: "Đóng cửa",
        },
        tableLabels: {
          name: "Tên cửa hàng",
          account: "Tài khoản",
          authorization: "Ủy quyền",
          updateUser: "Người cập nhật gần đây",
          updateTime: "Thời gian cập nhật gần đây",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Tên miền cấp hai Weifeng",
          secretKeyTip:
            "Khóa bí mật của người thuê, lấy tại điểm cập bến hệ thống nền của Hệ thống Weifeng",
        },
      },
      user: {
        index: {
          name: "Quản lý nhân viên",
          existingUsers: "Đã có nhân viên",
          deleteSuccess: "Xóa thành công",
          deleteFailed: "Xóa không thành công",
        },
        detail: {
          accountPrompt: "Vui lòng nhập email",
          resetPassword: "Đặt lại mật khẩu",
          passwordPrompt: "Mật khẩu (6-14 chữ cái và số kết hợp)",
          originPasswordPrompt: "Vui lòng nhập mật khẩu ban đầu",
          resumePasswordPrompt: "Vui lòng nhập lại mật khẩu",
          passwordFormWrong:
            "Vui lòng nhập mật khẩu cho sự kết hợp của 6-14 chữ cái và số!",
          resumePasswordWrong: "Mật khẩu không nhất quán hai lần!",
          suppertImageType: "Hỗ trợ jpg, gif, png, tối đa 500Kb",
          namePrompt: "Vui lòng nhập tên",
          nickNamePrompt: "Vui lòng nhập biệt hiệu",
          telephonePrompt: "Vui lòng nhập số điện thoại",
          roleSelectedPrompt: "Vui lòng chọn vai trò",
          agentIdPrompt: "Vui lòng nhập số công việc",
          disabled: "Vô hiệu hóa",
          enabled: "Kích hoạt",
          reloadBowerInfo:
            "Thay đổi thông tin người dùng, tôi có cần làm mới lại trình duyệt không?",
          saveUserError:
            "Lưu thông tin người dùng không thành công, vui lòng thử lại sau",
          resetPasswordSuccess: "Đặt lại mật khẩu thành công",
          resetPasswordFailed:
            "Đặt lại mật khẩu không thành công, vui lòng thử lại sau",
        },
      },
      userGroups: {
        index: {
          name: "Quản lý nhóm nhân viên",
          existingUserGroups: "Nhóm nhân viên hiện có",
        },
        detail: {},
      },
      role: {
        index: { name: "Quản lý vai trò", existingRoles: "Đã có vai trò" },
        edit: {
          editRole: "Chỉnh sửa vai trò",
          createRole: "Tạo nhân vật",
          basicInfo: "Thông tin cơ bản",
          taskPermissions: "Quyền tác vụ",
          existingTasks: "Đã có nhiệm vụ",
        },
      },
      anomalousData: {
        title: "Dữ liệu bất thường",
        list: {
          selectHolder: "Vui lòng chọn nguồn dữ liệu",
          businessTime: "Thời gian kinh doanh",
          businessId: "Kinh doanh Id",
          export: "Xuất",
        },
      },
      templateComponent: {
        list: {
          title: "Thị trường mẫu",
          name: "Tên mẫu",
          searchHolder: "Vui lòng nhập nội dung bạn muốn truy vấn",
          update: "Cập nhật tình hình",
          sort: "Cách sắp xếp",
          moduleType: "Loại mẫu",
          moduleSourceType: "Loại áp dụng",
          fetchNum: "Nhận được số lần",
          newest: "Cập nhật gần đây",
          usedMost: "Nhận được nhiều nhất",
          call: "Cuộc gọi",
          dialogue: "Đối thoại",
          waiting: "Được xem xét",
          cancel: "Hủy đăng",
          success: "Đã phê duyệt, đã phát hành",
          failed: "Kiểm toán từ chối",
          no: "Không thể nâng cấp",
          ok: "Có thể nâng cấp",
          all: "Tất cả",
          delete: "Xóa thành công",
          downOk: "Đã xóa thành công",
          withdraw: "Rút tiền thành công",
          updateOk: "Nâng cấp thành công",
          get: "Nhận",
          width: "Rút tiền",
          getOk: "Nhận được thành công",
          orgName: "Tổ chức thuộc về",
          nowVersion: "Phiên bản hiện tại",
          lastedVersion: "Phiên bản mới nhất",
          appStatus: "Trạng thái ứng dụng",
          actions: "Hoạt động",
          down: "Kệ",
          updateTop: "Nâng cấp",
          check: "Xem",
          deleteWhen: "Xác nhận xóa?",
          yes: "Là",
          noo: "Không.",
          deleteOk: "Xóa",
          permissible: "Cho phép",
          noPermissible: "Không được phép",
          openUp: "Mở",
          openDown: "Không mở",
          task: "Nhiệm vụ kiểm tra chất lượng",
          point: "Điểm kiểm tra chất lượng",
          tag: "Nhãn ngữ nghĩa",
          muti: "Đa yếu tố",
          inter: "Một câu hỏi và một câu trả lời",
          info: "Thực thể thông tin",
          concept: "Khái niệm từ",
          detail: "Chi tiết mô-đun",
          infomation: "Thông tin cơ bản",
          detailConfig: "Cấu hình chi tiết",
          twoEdit: "Chỉnh sửa thứ cấp",
          mustUpdate: "Nâng cấp ngay lập tức",
          descition: "Mô tả mẫu",
          descitionSize: "Mô tả mẫu không được vượt quá 250 từ",
          stencilsDetail: "Chi tiết mẫu",
          updatedRecords: "Cập nhật hồ sơ",
          version: "Số phiên bản",
          updateDescription: "Cập nhật mô tả",
          myModule: "Mẫu của tôi",
          addModule: "Mô-đun mới",
          publishVersion: "Phiên bản phát hành",
          earliestVersion: "Phiên bản sớm nhất",
          edit: "Chỉnh sửa",
          add: "Thêm",
          configNo: "Chi tiết cấu hình mô-đun không được để trống",
          updateNo: "Mô tả cập nhật không được để trống",
          editSuccess: "Sửa đổi thành công",
          saveSuccess: "Lưu thành công",
          newer: "Cập nhật",
          sumbit: "Gửi",
          cancelName: "Hủy bỏ",
          nameRequired: "Tên mô-đun là trường bắt buộc",
          nameSize: "Tên mô-đun không được vượt quá 16 từ",
          publishStatus: "Trạng thái đăng",
          submitUpdate: "Gửi bản cập nhật",
          updateVersion: "Phiên bản cập nhật",
          updateExplain: "Cập nhật hướng dẫn",
        },
      },
      customDataSource: {
        amazonEcommerce: "Thương mại điện tử Amazon",
        youtubeVideo: "Youtube video",
        amazon: {
          component: {
            queuing: "Xếp hàng",
            notStarted: "Chưa bắt đầu",
            inProgress: "Đang tiến hành",
            success: "Thành công",
            failed: "Thất bại",
            successfullyCreated: "Tạo thành công",
            editedSuccessfully: "Chỉnh sửa thành công",
            operationSucceeded: "Hoạt động thành công",
            deletedSuccessfully: "Xóa thành công",
            link: "Liên kết",
            grabState: "Trạng thái nắm bắt",
            updateTime: "Thời gian cập nhật",
            grab: "Lấy",
            edit: "Chỉnh sửa",
          },
          template: {
            amazonEcommerce: "Thương mại điện tử Amazon",
            thisProduct: "Sản phẩm này",
            competition: "Sản phẩm cạnh tranh",
            new: "Mới xây dựng",
            edit: "Chỉnh sửa",
            link: "Liên kết",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Youtube video",
            thisProduct: "Sản phẩm này",
            competition: "Sản phẩm cạnh tranh",
            new: "Mới xây dựng",
            edit: "Chỉnh sửa",
            link: "Liên kết",
          },
          component: {
            queuing: "Xếp hàng",
            notStarted: "Chưa bắt đầu",
            inProgress: "Đang tiến hành",
            success: "Thành công",
            failed: "Thất bại",
            successfullyCreated: "Tạo thành công",
            editedSuccessfully: "Chỉnh sửa thành công",
            operationSucceeded: "Hoạt động thành công",
            deletedSuccessfully: "Xóa thành công",
            youTubeVideoLink: "Liên kết video YouTube",
            grabState: "Trạng thái nắm bắt",
            updateTime: "Thời gian cập nhật",
            grab: "Lấy",
            edit: "Chỉnh sửa",
          },
        },
      },
    },
    demo: {
      title: "Kiểm tra chất lượng thông minh",
      template: "Chọn mẫu kiểm tra chất lượng",
      radio: "Chọn hình thức ghi âm",
      uploadAudio: "Tải lên bản ghi âm",
      uploadAudioTip:
        "Tải lên bản ghi âm chỉ hỗ trợ wav và định dạng mp3, kích thước không vượt quá 1G",
      uploadText: "Tải lên văn bản",
      uploadTextTip:
        "Tải lên văn bản chỉ hỗ trợ định dạng srt và txt, kích thước không vượt quá 10M, sau khi tải lên văn bản sẽ không còn sử dụng ASR để phân tích các tệp ghi âm",
      uploadSuccess: "Tải lên thành công tệp ghi âm",
      uploadFailed: "Tải lên tệp ghi không thành công",
      startQualityInspection: "Bắt đầu kiểm tra chất lượng",
      qualityInspectionStarted: "Đang kiểm tra chất lượng",
      downloadText: "Tải xuống văn bản",
      checking: "Tiến độ kiểm tra chất lượng",
      checkingTip: "Dự kiến sẽ mất vài phút, hãy kiên nhẫn",
      noCheckContent: "Chưa có kết quả kiểm tra chất lượng",
      noTextDownload: "Không có văn bản kiểm tra chất lượng để tải xuống!",
      pleaseCheckParams:
        "Vui lòng kiểm tra xem có chọn mẫu, loại giọng nói và tải lên tệp ghi âm hay không",
      inspectionFailed:
        "Kiểm tra chất lượng không thành công, vui lòng thử lại sau!",
    },
    wechatQa: {
      baseInfo: { title: "Thông tin cơ bản" },
      conversationSetting: {
        title: "Cài đặt phiên",
        segmentationStrategy: "Chiến lược phân đoạn",
        rule: "Chiến lược",
        time: "Thời gian phân đoạn",
        daily: "Mỗi ngày",
        passScore: "Điểm đủ điều kiện phiên",
        ruleLogic: "Thực hiện logic",
      },
      message: {
        title: "Sự kiện đẩy",
        table: {
          index: "Số thứ tự",
          name: "Tên",
          type: "Phương thức đẩy",
          desc: "Mô tả",
          status: "Trạng thái",
        },
        subTitle: "Danh sách các sự kiện",
      },
      checkPoint: {
        title: "Mô hình phân tích",
        groups: "Nhóm mô hình",
        newClassification: "Nhóm mới",
        editClassification: "Nhóm chỉnh sửa",
        benchmarkScore: "Điểm chuẩn",
        pointCount: "Mô hình phân tích chung theo phân loại hiện tại",
        openPointCount: "Số lượng được kích hoạt là",
        closedPointCount: "Số lần vô hiệu hóa là",
        scoreLowerLimit: "Điểm tối thiểu thấp hơn",
        scoreUpperLimit: "Giới hạn điểm tối đa",
        classificationName: "Tên nhóm",
        classificationNamePlaceHolder: "Vui lòng nhập tên nhóm",
        classificationRemark: "Mô tả nhóm",
        classificationRemarkPlaceHolder: "Vui lòng nhập mô tả nhóm",
      },
      chartRecord: {
        title: "Phiên WeChat doanh nghiệp",
        table: {
          id: "ID phiên",
          type: "Loại phiên",
          startTime: "Thời gian bắt đầu phiên",
          endTime: "Thời gian kết thúc gần đây của phiên",
          groupId: "ID nhóm",
          client: "Khách hàng",
          customer: "Dịch vụ khách hàng",
          averageScore: "Điểm kiểm tra chất lượng trung bình",
        },
        detail: {
          title: "Chi tiết phiên WeChat doanh nghiệp",
          inspectionInfo: "Phân tích thông tin",
          chartInfo: "Thông tin phiên",
          tasks: "Nhiệm vụ phân tích",
          avgScore: "Phân tích điểm trung bình",
          qualified: "Đủ điều kiện",
          unqualified: "Không đủ tiêu chuẩn",
          taskTitle: "Mẫu phân tích áp dụng",
          info: "Thông tin phân tích thời gian thực",
          today: "Tin tức hôm nay",
          history: "Tin tức lịch sử",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Vui lòng nhập ghi chú/biệt hiệu",
                pleaseEnterTheGroupChatName:
                  "Vui lòng nhập tên trò chuyện nhóm",
              },
            },
          },
          template: { whole: "Tất cả" },
        },
      },
      realTimeAnalysis: {
        title: "Phân tích thời gian thực",
        table: {
          name: "Tên cảnh",
          count: "Số lượng mô hình phân tích",
          baseScore: "Điểm chuẩn",
          passScore: "Điểm đủ điều kiện",
          maxScore: "Giới hạn điểm tối đa",
          minScore: "Điểm tối thiểu thấp hơn",
          status: "Trạng thái",
          actions: "Hoạt động",
        },
        detail: {
          title: "Phân tích cảnh trong thời gian thực",
          form: {
            name: "Tên cảnh",
            prevCondition: "Điều kiện kích hoạt cảnh",
            prevConditionToolTip:
              "Nếu điều kiện trống, cảnh này được phân tích theo mặc định",
            prevConditionError:
              "Các điều kiện kích hoạt cảnh không thể trùng với mô hình phân tích",
            scoreInputPlaceholder: "Nhập điểm",
            baseScore: "Điểm chuẩn",
            status: "Trạng thái",
            passScore: "Điểm đủ điều kiện",
            maxScore: "Giới hạn cao nhất",
            minScore: "Điểm tối thiểu thấp hơn",
            analysisModel: "Mô hình phân tích",
            message: "Kích hoạt thông báo",
            pushType: "Phương thức đẩy",
            createAnalysisModel: "Thêm mô hình",
            scoreUpperLimitError:
              "Giới hạn dưới của điểm tối thiểu không được lớn hơn giới hạn trên của điểm tối đa",
            table: {
              name: "Tên mô hình",
              rule: "Quy tắc",
              score: "Giá trị điểm",
            },
            pushConfigSwitchTip1: "Khi cảnh xuất hiện",
            pushConfigSwitchTip2: "Lần trở lên, thông báo đẩy",
          },
        },
      },
      smartCustomerTag: {
        title: "Nhãn khách hàng thông minh",
        table: {
          name: "Tên quy tắc",
          tags: "Nhãn khách hàng vi mô doanh nghiệp",
          status: "Bật trạng thái",
          createUser: "Người sáng tạo",
          createTime: "Tạo thời gian",
        },
        detail: {
          name: "Tên quy tắc",
          condition: "Đặt quy tắc",
          logic: "Logic",
          status: "Bật trạng thái",
          tags: "Chọn nhãn",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Khách hàng đánh dấu số lượng" },
        },
        messageFiltering: {
          template: {
            edit: "Chỉnh sửa",
            messageContent: "Nội dung tin nhắn",
            messageTypeID: "ID loại tin nhắn",
            messageTypeDescription: "Mô tả loại tin nhắn",
            messageFiltering: "Lọc tin nhắn",
            suitableForFilteringDataWithoutQualityInspection:
              "Thích hợp để lọc dữ liệu mà không cần kiểm tra chất lượng",
            create: "Tạo",
            messageDescription: "Mô tả tin nhắn",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Thời gian nói chuyện",
          sessionType: "Loại phiên",
          whole: "Tất cả",
          staff: "Nhân viên",
          customer: "Khách hàng",
          callKeywords: "Từ khóa cuộc gọi",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Chi tiết cuộc gọi thoại vi mô doanh nghiệp",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Lưu trữ phiên",
        voiceCall: "Cuộc gọi thoại",
        noMoreVoiceCalls: "Không có thêm cuộc gọi thoại",
        enterpriseWechatVoiceCall: "Cuộc gọi thoại WeChat doanh nghiệp",
        enterpriseWechatConversationDetails:
          "Chi tiết phiên WeChat doanh nghiệp",
        previous: "Cái trước",
        next: "Tiếp theo",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Nhiệm vụ phân tích phiên vi mô doanh nghiệp",
          enterpriseAndMicroVoiceAnalysisTask:
            "Nhiệm vụ phân tích giọng nói vi mô doanh nghiệp",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "Phân tích trò chuyện nhóm WeChat doanh nghiệp",
      },
      wechatQaSettingEntry: { messageFiltering: "Lọc tin nhắn" },
    },
    businessAnalysis: {
      keyword: "Tên báo cáo",
      createUser: "Người sáng tạo",
      createTime: "Tạo thời gian",
      updateTime: "Thời gian cập nhật",
    },
    semanticIntelligence: {
      applicationMessageType: "Loại tin nhắn ứng dụng",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "Vui lòng cải thiện cấu hình",
              generatePromptLanguage: "Tạo lời nhắc",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Có phù hợp không",
              gradedEvaluation: "Đánh giá phân loại",
            },
          },
          training: {
            index: {
              pleaseEnter: "Vui lòng nhập",
              pleaseGeneratePrompt: "Vui lòng tạo lời nhắc",
              pleaseSelectADialogue: "Vui lòng chọn đối thoại",
              training: "Đào tạo",
              dataType: "Loại dữ liệu:",
              voiceCall: "Cuộc gọi thoại",
              textDialogue: "Đối thoại văn bản",
              generate: "Tạo",
              qualityInspectionResults: "Kết quả kiểm tra chất lượng:",
              hit: "Đánh lời:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Tên mô hình",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Vui lòng nhập tên mô hình, ví dụ: có bất kỳ hành động nào trong dịch vụ khách hàng không",
            modelDescription: "Mô tả mô hình",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Nội dung bạn cần kiểm tra có thể được mô tả chi tiết để dễ hiểu mô hình, chẳng hạn như: dịch vụ khách hàng có hoạt động không, tức là dịch vụ khách hàng đã trả lời câu hỏi của khách hàng, nhưng câu hỏi của khách hàng chưa được xử lý trong suốt bài viết hoặc nội dung trả lời không liên quan gì đến câu trả lời của khách hàng",
            validity: "Hiệu quả",
            effective: "Hiệu quả",
            invalid: "Không hợp lệ",
            type: "Loại",
            hintLanguage: "Lời nhắc nhở",
            editIntelligentModel: "Chỉnh sửa mô hình thông minh",
            createANewIntelligentModel: "Mô hình thông minh mới",
          },
        },
        index: {
          modelName: "Tên mô hình",
          modelDescription: "Mô tả mô hình",
          type: "Loại",
          whetherItMeetsTheRequirements: "Có phù hợp không",
          gradedEvaluation: "Đánh giá phân loại",
          validity: "Hiệu quả",
          intelligentModel: "Mô hình thông minh",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Áp dụng cho việc kiểm tra chất lượng chủ quan (chẳng hạn như thái độ phục vụ kiểm tra chất lượng có nhiệt tình hay không, phục vụ có kiên nhẫn hay không, v. v.)",
        },
      },
      clusterAnalysis: "Phân tích cụm",
      speechMining: "Khai thác kỹ năng nói",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Hỗ trợ nhập hàng loạt thực thể thông tin thông qua giao diện API, không hỗ trợ chỉnh sửa sau khi tên API được lưu",
        },
        component: { nothing: "Không có" },
      },
      knowledgeBase: {
        index: {
          classification: "Phân loại",
          import: "Nhập",
          importKnowledgeBase: "Nhập cơ sở kiến thức",
          moveKnowledgePointsInBatch: "Điểm kiến thức di chuyển hàng loạt",
          moveTo: "Di chuyển đến:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Vui lòng chọn điểm kiến thức cần di chuyển hàng loạt",
        },
        detail: {
          index: { intrasentence: "Trong câu", afterProblem: "Sau vấn đề" },
        },
      },
      semanticTags: { component: { nothing: "Không có" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Từ nhạy cảm",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Từ nhạy cảm: Từ nhạy cảm có thể được định cấu hình từ quan tâm kinh doanh, được sử dụng để tìm từ nhạy cảm trong dư luận",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Từ nhạy cảm mới",
            editSensitiveWords: "Chỉnh sửa từ nhạy cảm",
            sensitiveWords: "Từ nhạy cảm",
          },
          template: {
            sensitiveWords: "Từ nhạy cảm",
            sensitiveWordsCannotBeEmpty: "Từ nhạy cảm không thể trống rỗng",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Vui lòng nhập các từ nhạy cảm, sử dụng ',' để tách nhiều từ nhạy cảm",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "Vui lòng chọn cảnh ứng dụng",
            keyWord: "Từ khóa",
            pleaseEnterKeywords: "Vui lòng nhập từ khóa",
            keywordLengthWithinCharacters: "Độ dài từ khóa trong 40 ký tự",
            dataSources: "Nguồn dữ liệu",
            pleaseSelectADataSource: "Vui lòng chọn nguồn dữ liệu",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Việc phân cụm hiện tại đã hoàn thành, việc chỉnh sửa lại sẽ tạo ra một phần dữ liệu phân cụm mới và sẽ không ghi đè dữ liệu phân cụm đã hoàn thành, vui lòng xác nhận",
            savingSucceeded: "Lưu thành công",
            clusteringStart: "Bắt đầu cụm",
            clustering: "Cụm",
            edit: "Chỉnh sửa",
            newlyBuild: "Mới xây dựng",
            clusterName: "Tên cụm",
            pleaseEnterAName: "Vui lòng nhập tên",
            withinCharactersOfTheName: "Chiều dài tên trong 40 ký tự",
            speaker: "Người nói chuyện",
            pleaseSelectASpeaker: "Hãy chọn người nói chuyện",
            sessionDate: "Ngày phiên",
            pleaseEnterTheSessionDate: "Vui lòng nhập ngày phiên",
            selectUpToMonthOfHistoricalSessionData:
              "Chọn tối đa 1 tháng dữ liệu phiên lịch sử",
            automaticallyClusterDailyNewData:
              "Phân cụm tự động thêm dữ liệu hàng ngày",
            conversationChannel: "Kênh đàm thoại",
            pleaseSelectAConversationChannel: "Vui lòng chọn kênh phiên",
            applicationScenario: "Cảnh ứng dụng",
            clusteringRules: "Quy tắc cụm",
            incompleteClusteringRuleSettings:
              "Cài đặt quy tắc cụm không đầy đủ",
            startClustering: "Bắt đầu cụm",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask:
                "Vui lòng chọn nhiệm vụ kiểm tra chất lượng",
              beEqualTo: "Bằng",
            },
          },
        },
        detail: {
          index: {
            theme: "Chủ đề",
            numberOfCorpora: "Số lượng ngữ liệu",
            corpusCoverage: "Phủ ngữ liệu",
            clusterDetails: "Chi tiết cụm",
          },
          ranking: {
            hotWords: "Từ nóng",
            numberOfCorpora: "Số lượng ngữ liệu",
            speaker: "Người nói chuyện",
            sit: "Ngồi",
            passenger: "Khách",
            hotSentence: "Câu nóng",
            corpusDetails: "Chi tiết ngữ liệu",
          },
        },
        index: {
          delete: "Xóa",
          deletionSucceeded: "Xóa thành công",
          clusterName: "Tên cụm",
          speaker: "Người nói chuyện",
          creationTime: "Tạo thời gian",
          state: "Trạng thái",
          operation: "Hoạt động",
          see: "Xem",
          edit: "Chỉnh sửa",
          clusterAnalysis: "Phân tích cụm",
          newCluster: "Cụm mới",
          clusteringStatus: "Trạng thái cụm",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Nhập từ khóa tìm kiếm phản đối",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Thư viện ngôn ngữ",
                recommendedScripts: "Kỹ năng nói được đề xuất",
                common: "Tổng cộng",
                strip: "Sọc",
                scriptFavorites: "Yêu thích",
              },
              conditionPostfix: {
                detectRoles: "Phát hiện vai trò",
                detectionRange: "Phạm vi phát hiện",
                sentence: "Câu",
              },
              customerFilter: {
                scriptFiltering: "Lọc lời nói",
                cancel: "Hủy bỏ",
              },
              index: {
                viewDetails: "Xem chi tiết",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Bạn có xác nhận rằng bạn muốn từ bỏ nó? Sẽ không thể phục hồi sau khi bị bỏ rơi",
                abandonment: "Bỏ rơi",
                deletionSucceeded: "Xóa thành công",
                passenger: "Khách",
                sit: "Ngồi",
                adopted: "Được thông qua",
                determine: "Chắc chắn",
                cancel: "Hủy bỏ",
                adopt: "Thông qua",
              },
              pageRightFrom: {
                savingSucceeded: "Lưu thành công",
                pleaseEnterTheScriptContent: "Vui lòng nhập nội dung",
                pleaseSelectAFavoriteGroup: "Vui lòng chọn nhóm yêu thích",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary: "Thư viện từ xử lý phản đối",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "Vui lòng chọn chấp nhận lời",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "Yêu thích xử lý phản đối",
            },
            pageRightTable: {
              likeCancel: "Thích hủy",
              likeSuccessfully: "Thích thành công",
              adoptScripts: "Thông qua lời nói",
              operation: "Hoạt động",
              viewConversationDetails: "Xem chi tiết cuộc trò chuyện",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Thư viện kỹ thuật điểm bán sản phẩm",
              },
              pageRightTable: {
                keyPointsOfScript: "Những điểm chính của lời nói",
              },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "Yêu thích xử lý phản đối",
              newGroup: "Nhóm mới",
              editGroup: "Nhóm chỉnh sửa",
              addGroup: "Thêm nhóm",
              savingSucceeded: "Lưu thành công",
              groupName: "Tên nhóm",
              pleaseEnterTheGroupName: "Vui lòng nhập tên nhóm",
              groupNameWithinCharacters: "Tên nhóm trong vòng 20 ký tự",
              deletionSucceeded: "Xóa thành công",
            },
            pageRightTable: {
              configDrawer: {
                index: { scriptRecommendationSettings: "Cài đặt đề xuất" },
                rightForm: {
                  savingSucceeded: "Lưu thành công",
                  intelligentRecommendation: "Khuyến nghị thông minh",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Tự động hiển thị các từ xuất sắc đã được thông qua trong thanh bên của các cuộc hội thoại phản đối tương tự và nhân viên có thể trực tiếp xem các từ được đề xuất trên trang chi tiết của cuộc hội thoại để học",
                  objectionsIncludedByDefault:
                    "Phản đối được bao gồm theo mặc định",
                  addRecommendationTriggerSemantics:
                    "Thêm ngữ nghĩa kích hoạt được đề xuất",
                  remindEmployeesToView: "Nhắc nhân viên xem",
                  shareWithMoreEmployees: "Chia sẻ với nhiều nhân viên hơn",
                  allHands: "Tất cả nhân viên",
                },
                smartToolbar: { selectSemanticTag: "Chọn nhãn ngữ nghĩa" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Bạn có xuất tất cả các bản ghi dữ liệu theo nhóm hiện tại không?",
                newScript: "Kỹ năng nói mới",
                editingScript: "Chỉnh sửa lời nói",
                savingSucceeded: "Lưu thành công",
                objection: "Phản đối",
                pleaseEnterAScriptObjection: "Vui lòng nhập phản đối",
                speechTechnique: "Lời nói",
                pleaseEnterTheScriptContent: "Vui lòng nhập nội dung",
                grouping: "Nhóm",
                pleaseSelectAFavoriteGroup: "Vui lòng chọn nhóm yêu thích",
                adoptScripts: "Thông qua lời nói",
                passenger: "Khách",
                sit: "Ngồi",
                numberOfViews: "Lượt xem",
                useful: "Hữu ích",
                operation: "Hoạt động",
                edit: "Chỉnh sửa",
                delete: "Xóa",
                deletionSucceeded: "Xóa thành công",
                scriptRecommendationSettings: "Cài đặt đề xuất",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Thư viện ngôn ngữ",
          scriptMining: "Khai thác kỹ năng nói",
          objectionHandlingScripts: "Xử lý phản đối",
          productSellingPointScripts: "Điểm bán sản phẩm",
        },
        sellingPoint: {
          index: { newCluster: "Cụm mới" },
          components: {
            callDetailModal: {
              sessionDetails: "Chi tiết phiên",
              cancel: "Hủy bỏ",
              determine: "Chắc chắn",
              choice: "Lựa chọn",
            },
            pageCenterTable: { scriptOverview: "Tổng quan về kỹ năng nói" },
            pageLeftTable: {
              enterSearchKeywords: "Nhập từ khóa tìm kiếm",
              keyWord: "Từ khóa",
            },
            pageRightTable: {
              index: {
                viewDetails: "Xem chi tiết",
                scriptLibrary: "Thư viện ngôn ngữ",
                scriptRefinement: "Tinh chỉnh lời nói",
                adoptScript: "Thông qua lời nói",
              },
              tagsEdit: { keyPointsOfScript: "Những điểm chính của lời nói" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Thư viện kỹ thuật điểm bán sản phẩm",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Điểm bán hàng được bao gồm theo mặc định",
                  },
                },
                index: {
                  keyWord: "Từ khóa",
                  pleaseSelectAKeyword: "Vui lòng chọn từ khóa",
                  keyPointsOfScript: "Những điểm chính của lời nói",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Vui lòng nhập các điểm chính của lời nói",
                  viewDetails: "Xem chi tiết",
                  pleaseChooseToAdoptScript: "Vui lòng chọn chấp nhận lời",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Kết hợp logic",
            nLPMatching: "NLP phù hợp",
            strictlyMatchByWord: "Phù hợp nghiêm ngặt theo từ",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Tên loại tin nhắn",
          messageTypeID: "ID loại tin nhắn",
          messageTypeDescription: "Mô tả loại tin nhắn",
          applicationMessageType: "Loại tin nhắn ứng dụng",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Nó phù hợp để truy cập dữ liệu kênh khác nhau để kiểm tra chất lượng và cần được phân loại theo nhận dạng kênh",
        },
        detail: {
          index: {
            messageTypeName: "Tên loại tin nhắn",
            pleaseEnterAMessageTypeName: "Vui lòng nhập tên loại tin nhắn",
            messageTypeDescription: "Mô tả loại tin nhắn",
            describeYourMessageTypeInDetail:
              "Mô tả chi tiết loại tin nhắn của bạn",
            editMessageType: "Chỉnh sửa loại tin nhắn",
            newMessageType: "Loại tin nhắn mới",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Vui lòng chọn thành viên trong nhóm",
        existingFilter: "Đã có bộ lọc",
        pleaseSelect: "Vui lòng chọn",
        filterCondition: "Điều kiện lọc",
        areYouSureToDeleteTheCurrentFilter:
          "Có xác nhận xóa bộ lọc hiện tại không?",
        sessionDate: "Ngày phiên",
        staff: "Nhân viên",
        customer: "Khách hàng",
        pleaseEnterCustomerName: "Vui lòng nhập tên khách hàng",
        keyWord: "Từ khóa",
        whole: "Tất cả",
        pleaseEnterSessionKeywords: "Vui lòng nhập từ khóa hội thoại",
        sessionDuration: "Thời lượng phiên",
        sessionLabel: "Cảnh đàm thoại",
        pleaseSelectASessionLabel: "Vui lòng chọn cảnh phiên",
        commentTopic: "Chủ đề bình luận",
        pleaseSelectACommentTopic: "Vui lòng chọn chủ đề bình luận",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Có xác nhận ghi đè bộ lọc hiện tại không?",
        overlayFilter: "Bộ lọc lớp phủ",
        NewFilter: "Bộ lọc mới",
        newFilter: "Bộ lọc mới",
        filterName: "Tên bộ lọc",
        pleaseEnter: "Vui lòng nhập",
        allCall: "Tất cả các cuộc gọi",
        percentage: "Tỷ lệ phần trăm",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'Hỗ trợ tìm kiếm nhiều từ khóa. Mối quan hệ "hoặc" được phân tách bằng dấu phẩy (tiếng Trung và tiếng Anh), và mối quan hệ "và" được phân tách bằng dấu dừng, chẳng hạn như:',
          callRecordID: "ID ghi âm cuộc gọi",
          pleaseFillInTheCallRecordID: "Vui lòng điền ID lịch sử cuộc gọi",
          minute: "Phút",
          second: "Giây",
          whole: "Tất cả",
          callRecording: "Ghi âm cuộc gọi",
          enterpriseWechat: "WeChat doanh nghiệp",
          conversationChannel: "Kênh đàm thoại",
          pleaseSelectASessionChannel: "Vui lòng chọn kênh phiên",
          learningStatus: "Trạng thái học tập",
          pleaseSelectLearningStatus: "Vui lòng chọn trạng thái học tập",
          notLearned: "Không học",
          learned: "Đã học",
          Minutes: "Phút",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Các điều kiện lọc trống và không hỗ trợ bộ lọc mới!",
          filterAddedSuccessfully: "Bộ lọc đã được thêm vào thành công!",
          failedToAddFilter: "Bộ lọc mới không thành công!",
          filterDeletedSuccessfully: "Bộ lọc loại bỏ thành công!",
          filterDeletionFailed: "Bộ lọc loại bỏ thất bại!",
          filterOverwriteSucceeded: "Bộ lọc che thành công!",
          filterOverwriteFailed: "Bộ lọc che thất bại!",
          theme: "Chủ đề",
          pleaseSelectATheme: "Vui lòng chọn chủ đề",
          keyEvents: "Sự kiện quan trọng",
          pleaseSelectAKeyEvent: "Vui lòng chọn sự kiện quan trọng",
        },
        filterItem: {
          startRetesting: "Bắt đầu phát hiện lại",
          time: "Thời gian",
          talkTime: "Thời lượng cuộc gọi",
          keyEvents: "Sự kiện quan trọng",
          totalImplementationRateOfSupervisionItems:
            "Tổng tỷ lệ thực hiện các dự án giám sát",
          sort: "Sắp xếp:",
          seeMore: "Xem thêm",
        },
        filterStatistics: {
          conversation: "Cuộc gọi",
          weChat: "WeChat",
          quantity: "Số lượng",
        },
      },
      caseBase: {
        index: {
          copyTo: "Sao chép vào",
          pleaseSelectTheLocationToMove: "Vui lòng chọn vị trí để di chuyển!",
          folderCopiedSuccessfully: "Sao chép thư mục thành công!",
          cancelCollectionSuccessfully: "Hủy bộ sưu tập thành công!",
          collectionSucceeded: "Bộ sưu tập thành công!",
          starCaseBase: "Thư viện trường hợp Star Mark",
          move: "Di chuyển",
          rename: "Đổi tên",
          delete: "Xóa",
          folderMovedSuccessfully: "Di chuyển thư mục thành công!",
          fileMovedSuccessfully: "Di chuyển tệp thành công!",
          fileMoveFailed: "Chuyển động tệp không thành công!",
          folderAddedSuccessfully: "Thêm thư mục thành công!",
          folderRenameSucceeded: "Đổi tên thư mục thành công!",
          fileRenameSucceeded: "Đổi tên tệp thành công!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Sau khi xóa thư mục sẽ không lấy lại được, bạn có xác nhận xóa không?",
          deleteSucceeded: "Xóa thành công!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Sau khi xóa các tập tin sẽ không thể lấy lại được, bạn có xác nhận xóa không?",
          share: "Tổng số",
          folders: "Thư mục,",
          calls: "Một cuộc gọi",
          newFolder: "Thêm thư mục mới",
          modifyFolder: "Sửa đổi thư mục",
          title: "Tiêu đề",
          pleaseEnter: "Vui lòng nhập",
          selectMoveLocation: "Chọn vị trí di chuyển",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Nhắc nhở học tập",
          subscriptionDynamics: "Đăng ký tin tức",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "Phiên bạn đã đăng ký (ID",
            thereAreDynamicUpdates: "Cập nhật động",
            newCommentsReplies: "Thêm nhận xét/trả lời",
            strip: "Sọc",
            newHelp: "Thêm trợ giúp",
            goAndWatch: "Đi xem",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Đã trả lời bình luận của tôi",
            replySucceeded: "Trả lời thành công!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Đã cho tôi trong các bình luận",
            replySucceeded: "Trả lời thành công!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Chia sẻ cuộc gọi cho bạn",
            shareCasesForYou: "Chia sẻ trường hợp cho bạn",
          },
        },
        study: {
          index: {
            learned: "Đã học",
            ignored: "Đã bỏ qua",
            study: "Học tập",
            ignore: "Bỏ qua",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Nhập thành công",
          account: "Tài khoản",
          password: "Mật khẩu",
          cover: "Phủ sóng",
          skip: "Bỏ qua",
          import: "Nhập",
          importEmployees: "Nhập nhân viên",
          importPrompt: "Mẹo nhập",
          confirm: "Chắc chắn",
          theDataContainsDuplicateAccountsOrNames:
            "Dữ liệu được lưu trữ với tài khoản hoặc tên trùng lặp",
          allCoverage: "Bảo hiểm tất cả",
          skipAll: "Bỏ qua tất cả",
          headPortrait: "Hình đại diện",
          fullName: "Tên",
          nickname: "Biệt danh",
          excellentStaff: "Nhân viên xuất sắc",
          yes: "Là",
          no: "Không.",
          department: "Phòng ban",
          accountNumberemail: "Tài khoản (email)",
          telephone: "Điện thoại",
          jobNumber: "Số công việc",
          role: "Vai trò",
          pleaseEnterANameOrPhoneSearch:
            "Vui lòng nhập email, tên hoặc tìm kiếm điện thoại",
          person: "Người)",
          AddEmployee: "Thêm nhân viên",
        },
        edit: {
          jobNo: "Số công việc",
          udeskCustomerServiceSystemIdentity:
            "Danh tính hệ thống dịch vụ khách hàng Udesk",
          enterpriseWechatIdentity: "Danh tính WeChat doanh nghiệp",
          inputID: "Nhận dạng đầu vào",
          newIdentityBinding: "Ràng buộc danh tính mới",
          identityBinding: "Ràng buộc danh tính",
          avatarGreaterThan500kb: "Hình đại diện lớn hơn 500Kb",
          uploadSuccessful: "Tải lên thành công",
          upload: "Tải lên",
          supportJPGGIFAndPNGUpTo500kb: "Hỗ trợ jpg, gif, png, tối đa 500Kb",
          yes: "Là",
          no: "Không.",
          accountNumber: "Tài khoản",
          pleaseEnterTheAccountNumber: "Vui lòng nhập số tài khoản",
          initialPassword: "Mật khẩu ban đầu",
          pleaseInputAPassword: "Vui lòng nhập mật khẩu",
          confirmPassword: "Xác nhận mật khẩu",
          thePasswordsEnteredTwiceAreDifferent:
            "Mật khẩu nhập hai lần không giống nhau!",
          headPortrait: "Hình đại diện",
          fullName: "Tên",
          pleaseEnterYourName: "Vui lòng nhập tên",
          nickname: "Biệt danh",
          department: "Phòng ban",
          excellentStaff: "Nhân viên xuất sắc",
          sessionIdentity: "Nhận dạng phiên",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Đánh dấu bản ghi phiên thuộc về nhân viên",
          telephone: "Điện thoại",
          jobNumber: "Số công việc",
          role: "Vai trò",
          pleaseSelectARole: "Vui lòng chọn vai trò",
          preservation: "Lưu",
          cancel: "Hủy bỏ",
          addedSuccessfully: "Thêm thành công!",
          modificationSucceeded: "Sửa đổi thành công!",
          newEmployees: "Nhân viên mới",
          editEmployee: "Nhân viên biên tập",
        },
        detail: {
          viewDetails: "Xem chi tiết",
          accountNumber: "Tài khoản",
          headPortrait: "Hình đại diện",
          fullName: "Tên",
          nickname: "Biệt danh",
          department: "Phòng ban",
          excellentStaff: "Nhân viên xuất sắc",
          yes: "Là",
          no: "Không.",
          sessionIdentity: "Nhận dạng phiên",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Đánh dấu bản ghi phiên thuộc về nhân viên",
          telephone: "Điện thoại",
          jobNumber: "Số công việc",
          role: "Vai trò",
        },
      },
      roleManage: {
        list: {
          menu: "Thực đơn",
          functionalCategory: "Danh mục chức năng",
          functionalSubclass: "Danh mục chức năng",
          deletedSuccessfully: "Xóa thành công",
          NewRole: "Vai trò mới",
          role: "Vai trò",
          dataPermissions: "Quyền dữ liệu:",
          edit: "Chỉnh sửa",
          areYouSureYouWantToDeleteTheRole: "Xác định xóa vai trò",
          determine: "Chắc chắn",
          cancel: "Hủy bỏ",
          delete: "Xóa",
          applicationPermission: "Quyền ứng dụng",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Chỉ có thể xem dữ liệu phiên của riêng bạn",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Bạn có thể xem dữ liệu phiên của bộ phận này và các bộ phận trực thuộc",
          menu: "Thực đơn",
          functionalCategory: "Danh mục chức năng",
          functionalSubclass: "Danh mục chức năng",
          successfullyAdded: "Thành công mới",
          modifiedSuccessfully: "Sửa đổi thành công",
          edit: "Chỉnh sửa",
          cancel: "Hủy bỏ",
          confirm: "Xác nhận",
          roleName: "Tên nhân vật",
          pleaseEnterTheRoleName: "Vui lòng nhập tên vai trò",
          dataAuthority: "Quyền dữ liệu",
          youCanViewCompanyWideSessionData:
            "Có thể xem dữ liệu phiên của toàn công ty",
          applicationPermission: "Quyền ứng dụng",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Quá trình phiên",
          generateProcess: "Tạo quy trình",
          addProcess: "Thêm quy trình",
          cancel: "Hủy bỏ",
          complete: "Hoàn thành",
          edit: "Chỉnh sửa",
          theSettingWillBeSuccessfulLater: "Xem thành công sau khi thiết lập!",
          previous: "Cái trước",
          next: "Tiếp theo",
          debuggingMode: "Chế độ gỡ lỗi",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Sau khi mở, nếu thông báo khớp với các sự kiện chính, mục giám sát, quy tắc thẻ khách hàng hoặc quy tắc điểm kiến thức, tên của quy tắc khớp với thông báo và tên điểm kiến thức của cơ sở kiến thức sẽ được hiển thị.",
          seeLater: "Xem sau",
          determine: "Chắc chắn",
          conversationalInsights: "Thông tin chi tiết hội thoại",
          customerInsight: "Thông tin chi tiết của khách hàng",
          knowledgePointRecommendation: "Đề xuất điểm kiến thức",
          comment: "Bình luận",
          sessionQuality: "Chất lượng phiên",
          setToListenLaterSuccessfully: "Cài đặt để nghe thành công sau!",
          listenLater: "Nghe sau",
          seekCounselingSuccess: "Tìm kiếm tư vấn thành công!",
          altogether: "Tổng cộng",
          appearsAt: "Xuất hiện ở đâu'",
          commentsPublishedSuccessfully: "Bình luận được đăng thành công",
          callRecording: "Ghi âm cuộc gọi",
          addedSuccessfully: "Thêm thành công",
          shareWithColleagues: "Chia sẻ với đồng nghiệp",
          shareWithCustomers: "Chia sẻ với khách hàng",
          resumptionDetails: "Chi tiết phát hành lại",
          addToCaseBase: "Thêm vào thư viện trường hợp",
          shareCases: "Chia sẻ trường hợp",
          seekCounseling: "Tìm kiếm tư vấn",
          sessionRecord: "Bản ghi phiên",
          businessRecord: "Hồ sơ kinh doanh",
          keywordSearch: "Tìm kiếm từ khóa",
          whole: "Tất cả",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Vui lòng nhập nhận xét, nhập @ để thông báo cho người khác, nhập # để thêm chủ đề",
          subscriptionSession: "Đăng ký phiên",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Sau khi đăng ký, khi có yêu cầu trợ giúp, nhận xét và trả lời mới, bạn sẽ nhận được lời nhắc thông báo",
        },
      },
      coachTask: {
        index: {
          employeeName: "Tên nhân viên",
          customerName: "Tên khách hàng",
          source: "Nguồn",
          viewSessionRecords: "Xem bản ghi phiên",
          coachingStatus: "Tình trạng tư vấn",
          helpTime: "Thời gian trợ giúp",
          counselingTime: "Thời gian tư vấn",
          helpContent: "Nội dung trợ giúp",
          coachingOperation: "Hoạt động tư vấn",
          areYouSureYouWantToIgnoreIt: "Chắc chắn bỏ qua?",
          determine: "Chắc chắn",
          cancel: "Hủy bỏ",
          ignoreHelp: "Bỏ qua để được giúp đỡ",
          coaching: "Tiến hành tư vấn",
          viewCoaching: "Xem dạy kèm",
          areYouSureToReturnToCounseling:
            "Bạn có chắc chắn tiếp tục cho đến khi được tư vấn?",
          returnToPendingCoaching: "Tiếp tục để được tư vấn",
          tutor: "Người cố vấn",
          helpSeekingStatus: "Trạng thái trợ giúp",
          recentCoachingTime: "Thời gian tư vấn gần đây",
          operation: "Hoạt động",
          ignoreSuccess: "Bỏ qua thành công!",
          recoverySucceeded: "Khôi phục thành công!",
          successfulCoaching: "Tư vấn thành công!",
          myMission: "Nhiệm vụ của tôi",
          selectCoachingStatus: "Chọn trạng thái tư vấn",
          whole: "Tất cả",
          iAskedForHelp: "Tôi đã yêu cầu giúp đỡ",
          signOut: "Thoát",
          submit: "Gửi",
          helpPerson: "Tìm người giúp đỡ",
          customer: "Khách hàng",
          toBeCoached: "Đang chờ tư vấn",
          counselingSuggestions: "Tư vấn tư vấn",
          pleaseEnterCoachingSuggestions: "Vui lòng nhập lời khuyên tư vấn",
        },
      },
      coachTaskCenter: {
        title: "Trung tâm nhiệm vụ",
        list: {
          taskName: "Tên nhiệm vụ",
          lessonGroup: "Phân loại khóa học",
          lessonName: "Các khóa học liên kết",
          taskType: "Loại nhiệm vụ",
          taskStatus: "Tình trạng nhiệm vụ",
          taskExecutionInfo: "Thực hiện",
          totalCount: "Số lượng người nên được thực hiện",
          executeCount: "Số người đã thực hiện",
          executeRate: "Tỷ lệ thực hiện",
          completeCount: "Số lượng người đã hoàn thành",
          completeRate: "Tỷ lệ hoàn thành",
          commitCount: "Số người đạt tiêu chuẩn",
          commitRate: "Tỷ lệ tuân thủ",
          excellentRate: "Tỷ lệ vượt trội",
          taskTime: "Thời gian nhiệm vụ",
          option: "Hoạt động",
          edit: "Chỉnh sửa",
          taskDetail: "Dữ liệu nhiệm vụ",
          delete: "Xóa",
          new: "Phát hành mới",
          taskTypeAll: "Tất cả",
          taskTypePractice: "Bài tập",
          taskTypeTest: "Thi",
          taskStatusNotStarted: "Chưa bắt đầu",
          taskStatusRunning: "Đang tiến hành",
          taskStatusEnd: "Đã kết thúc",
          taskStatusInvalid: "Không có hiệu lực",
          unlimitedTime: "Không giới hạn thời gian",
          to: "Đến",
          deleteConfirm:
            "Sau khi xóa, tất cả các hồ sơ học tập theo nhiệm vụ này sẽ bị xóa. Bạn có xác nhận xóa không?",
          deleteSuccess: "Nhiệm vụ xóa thành công",
        },
        edit: {
          addTask: "Nhiệm vụ phát hành mới",
          editTask: "Chỉnh sửa nhiệm vụ xuất bản",
          publishSuccess: "Phát hành thành công",
          publishFailed: "Phát hành thất bại",
          updateSuccess: "Cập nhật thành công",
          updateFailed: "Cập nhật thất bại",
          cannotBeNull: "Không thể trống rỗng",
          explain: "Mô tả",
          taskDescExtra: "(Nội dung ở đây sẽ được hiển thị ở lối vào {0})",
          taskTargetObj: "Đăng đối tượng",
          isEnable: "Trạng thái có hiệu lực",
          dialogueMode: "Chế độ đối thoại",
          voice: "Giọng nói",
          text: "Văn bản",
          userPortrait: "Chân dung người dùng",
          difficultyLevel: "Khó khăn",
          limitDuration: "Giới hạn thời gian",
          tipLabel: "Lời khuyên",
          tipTooltip: "Học sinh có được phép kiểm tra các từ tham khảo không",
          limitTimes: "Số lần thi",
          time: "Thời gian",
          gradingStandart: "Tiêu chí chấm điểm",
          gradingStandartDescAffix:
            "(Tổng số điểm của quá trình đối thoại hiện tại là",
          gradingStandartDescSuffix:
            "Điểm, kỹ năng nói {0} điểm, cảm xúc {1} điểm, tốc độ nói {2} điểm)",
          completeStandart: "Yêu cầu hoàn thành nhiệm vụ",
          byTimes: "Theo số lần",
          byScore: "Điểm theo",
          completeByTimes: "Số lượng bài tập",
          times: "Lần",
          score: "Điểm",
          anyTimes: "Bất kỳ một lần",
          getScore: "Điểm số",
          offLimitDuration: "Không giới hạn thời gian dài",
          onLimitDuration: "Thời gian giới hạn dài",
          commitScore: "Tiêu chuẩn tuân thủ, điểm số",
          excellentScore: "Tiêu chuẩn xuất sắc, điểm số",
          offLimitTime: "Không giới hạn thời gian",
          onLimitTime: "Thời gian giới hạn",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Chào," },
          callDynamic: {
            today: "Hôm nay",
            yesterday: "Hôm qua",
            sessions: "Một cuộc trò chuyện",
          },
          todoList: {
            strip: "Sọc",
            theSessionIsAddedTolistenLater: 'Phiên được thêm vào "Nghe sau"',
            askYouForHelp: "Nhờ bạn giúp đỡ",
            iHopeToContactYouLater: "Mong được liên lạc sau",
            lookingForwardToSendingTheInformationLater:
              "Mong muốn gửi thông tin sau",
            dialogueRisk: "Có những điểm rủi ro trong phiên",
            tutored: "Đã được tư vấn",
            goToCounseling: "Đi dạy kèm",
            contacted: "Đã liên hệ",
            hasBeenSent: "Đã gửi",
            areYouSureYouWantToIgnoreIt: "Chắc chắn bỏ qua?",
            determine: "Chắc chắn",
            cancel: "Hủy bỏ",
            ignore: "Bỏ qua",
            checked: "Đã xem",
          },
          callListItem: {
            strip: "Sọc",
            learned: "Đã học",
            keyEvents: "Sự kiện quan trọng",
            totalImplementationRateOfSupervisionItems:
              "Tổng tỷ lệ thực hiện các dự án giám sát",
          },
        },
        index: {
          operationSucceeded: "Hoạt động thành công!",
          sessionDynamics: "Động lực hội thoại",
          my: "Của tôi",
          department: "Phòng ban",
          company: "Công ty",
          toDoList: "Việc cần làm",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "Khối lượng cuộc gọi hiệu quả (a)",
          bestPractices: "Thực hành tốt nhất",
          averageDurationOfASingleCallminutes:
            "Thời lượng trung bình của một cuộc gọi (phút)",
          totalCallVolumePCs: "Tổng số cuộc gọi (a)",
          totalTalkTimehours: "Tổng thời lượng cuộc gọi (giờ)",
          speakingPercentagepercentage: "Tỷ lệ nói (phần trăm)",
          maximumContinuousSpeakingTimeminutes:
            "Thời lượng nói liên tục dài nhất (phút)",
          numberOfIndepthDiscussionsPCs: "Số cuộc thảo luận chuyên sâu (a)",
          maximumCustomerTalkminutes: "Tối đa khách hàng nói (phút)",
          questionFrequencyPCs: "Tần suất câu hỏi (a)",
          averageValue: "Trung bình",
          selectDate: "Chọn ngày",
          personnel: "Nhân viên",
          pleaseSelect: "Vui lòng chọn",
          callRange: "Phạm vi cuộc gọi",
          conversationActivity: "Hoạt động đàm thoại",
          conversationAction: "Hành động đàm thoại",
          keyEvents: "Sự kiện quan trọng",
          callRecord: "Ghi âm cuộc gọi",
          filter: "Sàng lọc:",
          conversation: "Cuộc gọi",
          minute: "Phút",
          sale: "Nhân viên",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Tháng 1",
            numberOfCalls: "Số cuộc gọi",
            accountInformation: "Thông tin tài khoản",
            month: "Tháng",
            today: "Hôm nay",
            customerDetails: "Chi tiết khách hàng",
            searchKeywordsInCommunicationContent:
              "Tìm kiếm từ khóa trong nội dung giao tiếp",
            allMembers: "Tất cả các thành viên",
            reset: "Đặt lại",
            query: "Truy vấn",
            allTrip: "Hiển thị đầy đủ hành trình",
            contactOverview: "Tổng quan liên hệ",
            lastContact: "Liên hệ lần trước",
            nextContact: "Liên hệ lần sau",
            previous: "Cái trước",
            next: "Tiếp theo",
            viewDetails: "Xem chi tiết",
            contactDynamics: "Tin tức liên hệ",
            lastYear: "Năm gần đây nhất",
            lastCall: "Cuộc gọi gần đây nhất",
            hotTopicsForDiscussion: "Chủ đề thảo luận nóng",
            totalInTheLastYear: "Tổng cộng trong năm qua",
            callTimes: "Cuộc gọi",
          },
        },
        list: {
          index: {
            risk: "Rủi ro",
            have: "Có",
            nothing: "Không có",
            channel: "Kênh",
            callRecording: "Ghi âm cuộc gọi",
            enterpriseWeChatConversation: "Phiên vi mô doanh nghiệp",
            enterpriseWeChatCall: "Cuộc gọi vi mô doanh nghiệp",
            followUpSuggestions: "Gợi ý theo dõi",
            customer: "Trung tâm khách hàng",
            followUp: "Theo dõi tình hình",
            personInCharge: "Người phụ trách",
            recentFollowup: "Theo dõi mọi người gần đây",
            lastContactTime: "Thời gian liên hệ gần đây nhất",
            operation: "Hoạt động",
            see: "Xem",
            exceed: "Hơn",
            noActivityForMoreThanDays: "Ngày không có hoạt động",
            lately: "Gần đây",
            risksOccurredInTheLastDays: "Ngữ nghĩa rủi ro trong ngày",
            savedSuccessfully: "Lưu thành công",
            today: "Hôm nay",
            allDepartments: "Tất cả các phòng ban",
            allMembers: "Tất cả các thành viên",
            customerName: "Tên khách hàng",
            reset: "Đặt lại",
            query: "Truy vấn",
            customers: "Khách hàng",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Hiển thị tình hình liên hệ giữa nhân viên và khách hàng, trong đó bong bóng màu hồng là tin nhắn khách hàng và màu xanh là tin nhắn của nhân viên. Tỷ lệ bài phát biểu càng lớn thì bong bóng càng lớn.",
            riskAllocation: "Phân bổ rủi ro",
            preservation: "Lưu",
            pleaseEnter: "Vui lòng nhập",
            noActivityForDays: "Ngày không có hoạt động",
            riskSemanticsWithinDays: "Ngữ nghĩa rủi ro trong ngày",
            addRiskSemantics: "Thêm ngữ nghĩa rủi ro",
            semanticTags: "Thẻ ngữ nghĩa:",
            standardSentence: "Câu chuẩn:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "Thêm điều kiện mới" } },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Đáp ứng tất cả các điều kiện sau",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Sàng lọc",
                pleaseSelectFilteringCriteria: "Vui lòng chọn lọc",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Thay đổi giai đoạn cơ hội kinh doanh",
              theCurrentStageIs: "Giai đoạn hiện tại là 【",
              ConfirmToChangeItTo: "】, Đảm bảo thay đổi nó thành 【",
              IsIt: "】?",
              StageCoStay: "】 Giai đoạn ở lại",
              day: "Ngày",
              start: "Bắt đầu",
              end: "Kết thúc",
              orderInput: "Mất đơn",
              restart: "Khởi động lại",
              pleaseConfigureTheSalesStageInformation:
                "Vui lòng cấu hình thông tin giai đoạn bán hàng",
              reasonForDocumentInput: "Lý do mất đơn hàng:",
              completeOrderInput: "Hoàn thành lệnh nhập",
              pleaseSelectTheReason: "Vui lòng chọn lý do",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "Người quản lý đề nghị:",
              },
              index: {
                sessionDetails: "Chi tiết phiên",
                customerDynamics: "Tin khách hàng",
                riskPoint: "Điểm rủi ro",
                followUpSuggestions: "Gợi ý theo dõi",
              },
              sessionDetails: {
                common: "Tổng cộng",
                currentlyViewingThe: "Xem hiện tại",
                sessions: "Một cuộc trò chuyện",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Giai đoạn hiện tại đã ở lại",
              day: "Ngày",
            },
          },
          business: {
            index: { reEnableOpportunity: "Kích hoạt lại cơ hội kinh doanh" },
          },
          customerTag: { index: { label: "Nhãn" } },
          moreCustomer: {
            index: {
              essentialInformation: "Thông tin cơ bản",
              cancel: "Hủy bỏ",
              confirm: "Xác nhận",
              customer: "Khách hàng",
              currentFollowupStage: "Giai đoạn theo dõi hiện tại",
              telephone: "Điện thoại",
              followUpDays: "Số ngày theo dõi",
              day: "Ngày",
              remarks: "Ghi chú",
              channelPreference: "Sở thích kênh",
              hello: "Xin chào.",
              customFields: "Trường tùy chỉnh",
              opportunityAmount: "Số lượng cơ hội kinh doanh",
              focus: "Trọng tâm",
              customerInformation: "Thông tin khách hàng",
              viewMoreCustomerInformation: "Xem thêm thông tin khách hàng",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Sự kiện quan trọng:" },
                row1: {
                  customerDynamics: "Tin khách hàng",
                  common: "Tổng cộng",
                  barDynamic: "Bài viết động",
                },
                row2: {
                  riskPoint: "Điểm rủi ro",
                  common: "Tổng cộng",
                  risks: "Rủi ro",
                },
                row3: {
                  followUpSuggestions: "Gợi ý theo dõi",
                  intelligentRecommendation: "Khuyến nghị thông minh",
                  strip: "Sọc",
                  managersSuggestATotalOf: "Các nhà quản lý đề xuất",
                  recommendedByManagers: "Đề xuất của người quản lý",
                  creationTime: "Tạo thời gian",
                  administratorSuggestions: "Quản trị viên đề nghị",
                  suggestionsForNewManagers: "Đề xuất quản lý mới",
                },
              },
            },
            index: { typeTime: "Loại/Thời gian" },
          },
          userInfo: {
            index: {
              personInCharge: "Người phụ trách",
              recentFollower: "Theo dõi mọi người gần đây",
              startTime: "Thời gian tiếp cận đầu tiên",
              followUpDays: "Số ngày theo dõi",
              day: "Ngày",
              remarks: "Ghi chú",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Thành viên nhóm",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Các thành viên học tổng số cuộc trò chuyện của người khác (xem lại), kiểm tra chi tiết cuộc trò chuyện và nhấp vào ghi và phát lại để được coi là học một lần",
          totalNumberOfStudies: "Tổng số học tập",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Tổng số nhận xét và phản hồi của thành viên đối với các cuộc trò chuyện của người khác (loại bỏ)",
          totalNumberOfInteractions: "Tổng số tương tác",
          learnTheSumOfConversationCallDuration:
            "Tổng thời lượng cuộc trò chuyện học tập",
          lengthOfStudy: "Thời gian học",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Tổng số thành viên bắt đầu yêu cầu trợ giúp cho các cuộc trò chuyện của người khác (loại bỏ)",
          totalNumberOfRequests: "Tổng số trợ giúp",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Tổng số thành viên tư vấn cho sự giúp đỡ của người khác (loại bỏ)",
          totalCounseling: "Tổng số tư vấn",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Tổng số phiên của thành viên trên người khác được thêm vào thư viện trường hợp (loại bỏ)",
          addCase: "Thêm trường hợp",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Tổng số thành viên đăng ký các phiên của người khác (loại bỏ)",
          subscribe: "Đăng ký",
          totalNumberOfSessionsCommunicatedByMembers:
            "Tổng số phiên giao tiếp thành viên",
          totalSessions: "Tổng số phiên",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Các thành viên xem lại tổng số cuộc trò chuyện của họ (xem lại), kiểm tra chi tiết cuộc trò chuyện và nhấp vào ghi và phát lại để được coi là phát lại một lần",
          totalNumberOfDoubleDisk: "Tổng số lượt xem lại",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Tổng số lượt xem lại/tổng số phiên * 100%",
          proportionOfDoubleDisk: "Tỷ lệ phát hành lại",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Tổng thời gian cuộc gọi hội thoại",
          resumptionDuration: "Thời gian quay lại",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Tổng số thành viên bắt đầu yêu cầu trợ giúp cho các cuộc hội thoại của họ (loại bỏ)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Tổng số nhận xét của người khác (bao gồm cả phản hồi) mà phiên của thành viên nhận được",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Tổng số phiên của thành viên được người khác thêm vào thư viện trường hợp (loại bỏ)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Tổng số phiên của thành viên được người khác đăng ký (loại bỏ)",
          askForHelpInTheLastMonth: "Trợ giúp trong tháng gần đây nhất",
          lastTimeForHelp: "Thời gian yêu cầu trợ giúp gần đây nhất",
          operation: "Hoạt động",
          viewAllSessionsInTheLastMonth:
            "Xem tất cả các phiên trong tháng gần đây nhất",
          allDepartments: "Tất cả các phòng ban",
          reset: "Đặt lại",
          query: "Truy vấn",
          altogether: "Tổng cộng",
          secondSession: "Phiên",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Các mục thử nghiệm hiện tại:",
            supervisionItem: "Mục giám sát",
            keyEvents: "Sự kiện quan trọng",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Vui lòng kiểm tra các mục giám sát hoặc các sự kiện quan trọng để kiểm tra và đảm bảo rằng có nội dung cuộc gọi trong cuộc trò chuyện mô phỏng",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Phân tích thực thi" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Quy trình 1",
              technologicalProcess: "Quy trình",
              newProcess: "Quy trình mới",
            },
          },
          index: {
            upload: "Tải lên",
            preservation: "Lưu",
            newlyBuild: "Mới xây dựng",
            cancel: "Hủy bỏ",
            name: "Tên",
            describe: "Mô tả",
            type: "Loại",
            classification: "Phân loại",
            content: "Nội dung",
            enclosure: "Phụ kiện",
            knowledgePointAssociation: "Điểm kiến thức liên quan",
            open: "Mở",
            close: "Đóng cửa",
            operator: "Toán tử",
            logic: "Logic",
            see: "Xem",
            edit: "Chỉnh sửa",
          },
        },
        index: {
          name: "Tên",
          describe: "Mô tả",
          createdBy: "Người sáng tạo",
          type: "Loại",
          lastUpdated: "Thời gian cập nhật gần đây",
          numberOfReferences: "Số lần trích dẫn",
          operation: "Hoạt động",
          see: "Xem",
          edit: "Chỉnh sửa",
          delete: "Xóa",
          categoryDeletedSuccessfully: "Đã xóa danh mục thành công",
          classificationOfKnowledgePoints: "Phân loại điểm kiến thức",
          newlyBuild: "Mới xây dựng",
          knowledgeBase: "Cơ sở kiến thức",
          knowledgePoints: "Điểm kiến thức",
          technologicalProcess: "Quy trình",
          keyword: "Từ khóa",
          batchExport: "Xuất hàng loạt",
          batchDelete: "Xóa hàng loạt",
          importProcess: "Quá trình nhập",
          importKnowledgePoints: "Nhập điểm kiến thức",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Nếu điểm kiến thức không tồn tại trong cơ sở kiến thức thì trực tiếp thêm điểm kiến thức, nếu tồn tại thì bỏ qua, không cập nhật",
          editKnowledgePointClassification:
            "Chỉnh sửa phân loại điểm kiến thức",
          newKnowledgePointClassification: "Phân loại điểm kiến thức mới",
          categoryEditingSucceeded: "Chỉnh sửa danh mục thành công",
          newClassificationSucceeded: "Phân loại mới thành công",
          classificationName: "Tên phân loại",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Giám sát thực thi" },
      },
      topicCallout: {
        index: {
          whole: "Tất cả",
          date: "Ngày",
          dimensionStatus: "Trạng thái đánh dấu",
          callRecordID: "ID ghi âm cuộc gọi",
          callRecordIDMustBeNumeric: "ID ghi âm cuộc gọi phải là số",
          strip: "Sọc",
          topicAnnotation: "Chú thích chủ đề",
          annotated: "Đã đánh dấu",
          stripNotMarked: "Thanh, không được đánh dấu",
          barInDimension: "Bài báo, được đánh dấu",
          customer: "Khách hàng",
          sale: "Bán hàng",
          common: "Tổng cộng",
          sessions: "Phiên",
          currentTopic: "Chủ đề hiện tại:",
          staff: "Nhân viên",
          topicOfConversation: "Chủ đề",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Hoạt động thành công, đánh dấu đã được đặt lại",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Sau khi đặt lại, tất cả các bản ghi được đánh dấu của phiên này sẽ được xóa, vui lòng xác nhận",
          resetAll: "Đặt lại tất cả",
          pleaseSelectTheTopicToBeMarked:
            "Vui lòng chọn chủ đề bạn muốn đánh dấu",
          pleaseTickTheSentenceToBeMarked:
            "Vui lòng kiểm tra câu bạn muốn đánh dấu",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Hoạt động thành công, nhãn đã có hiệu lực",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Phạm vi được đánh dấu lần này bao gồm các tin tức về các chủ đề khác, hãy nhấp vào OK để ghi đè",
          confirmDimensions: "Xác nhận nhãn",
          talkTime: "Thời gian nói chuyện",
          callID: "Cuộc gọi id",
        },
      },
      topicList: {
        index: {
          callID: "ID cuộc gọi",
          latestMarkedTime: "Thời gian được đánh dấu mới nhất",
          topicOfConversation: "Chủ đề",
          numberOfSentences: "Số lượng câu",
          sentence: "Câu",
          operation: "Hoạt động",
          see: "Xem",
          delete: "Xóa",
          edit: "Chỉnh sửa",
          newSubTopic: "Chủ đề phụ mới",
          move: "Di chuyển",
          whole: "Tất cả",
          topicDeletedSuccessfully: "Xóa chủ đề thành công",
          newlyBuild: "Mới xây dựng",
          topicList: "Danh sách chủ đề",
          callIDMustBeNumeric: "ID cuộc gọi phải là số",
          leadInTopic: "Nhập chủ đề",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Khi tên danh mục được lặp lại, danh mục chủ đề ban đầu sẽ không bị ghi đè, mục này sẽ bị bỏ qua trực tiếp và sẽ không được nhập",
          mobileClassification: "Phân loại di động",
          editTopic: "Chủ đề chỉnh sửa",
          newTopic: "Chủ đề mới",
          topicName: "Tên chủ đề",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Sửa đổi thành công",
            createdSuccessfully: "Tạo thành công",
            modificationFailed: "Sửa đổi không thành công",
            creationFailed: "Tạo thất bại",
            monitoringName: "Tên giám sát",
            upToCharacters: "Tối đa 40 ký tự",
            pleaseEnterAName: "Vui lòng nhập tên",
            monitoringIndicators: "Giám sát chỉ số",
            sessionActivity: "Hoạt động đàm thoại",
            conversationAction: "Hành động đàm thoại",
            keyEvents: "Sự kiện quan trọng",
            supervisionItems: "Mục giám sát",
            monitoringMembers: "Giám sát thành viên",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Các nhóm được hướng dẫn, đào tạo hoặc can thiệp quản lý",
            groupA: "Nhóm A",
            pleaseSelectGroupAData: "Vui lòng chọn dữ liệu nhóm A",
            thereAreDuplicateUsersInGroupB:
              "Sự tồn tại của người dùng trùng lặp với nhóm B",
            groupARemarks: "Ghi chú nhóm A",
            controlGroupGroupComparedWithGroupA:
              "Nhóm kiểm soát, nhóm so sánh với nhóm A",
            groupB: "Nhóm B",
            pleaseSelectGroupBData: "Vui lòng chọn dữ liệu nhóm B",
            thereAreDuplicateUsersInGroupA:
              "Với sự tồn tại của người dùng trùng lặp trong nhóm A",
            groupBRemarks: "Ghi chú nhóm B",
            monitoringPeriod: "Chu kỳ giám sát",
            startNode: "Nút bắt đầu",
            monitoringScope: "Giám sát phạm vi",
            pleaseSelectMonitoringConditions:
              "Vui lòng chọn điều kiện giám sát",
            pleaseEnterANumber: "Vui lòng nhập số",
            pleaseSelectATimePeriod: "Vui lòng chọn khoảng thời gian",
            preservation: "Lưu",
            cancel: "Hủy bỏ",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Xóa thành công",
            notStarted: "Chưa bắt đầu",
            haveInHand: "Đang tiến hành",
            closed: "Đã kết thúc",
            newGroupTest: "Kiểm tra nhóm mới",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Giai đoạn bán hàng",
        communicationStrategy1: "Chiến lược truyền thông",
        riskAllocation: "Phân bổ rủi ro",
        businessConfiguration: "Cấu hình kinh doanh",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Xử lý phản đối",
                  productSellingPointScript: "Điểm bán sản phẩm",
                  caseGrouping: "Nhóm trường hợp",
                  pleaseSelectExcellentCases:
                    "Vui lòng chọn trường hợp xuất sắc",
                  pleaseSelectKnowledgePoints: "Hãy chọn điểm kiến thức",
                  associativeLinks: "Liên kết liên kết",
                  jumpTo: "Chuyển đến",
                  jumpType: "Loại hình nhảy",
                  knowledgePoints: "Điểm kiến thức",
                  excellentCases: "Trường hợp xuất sắc",
                  excellentScript: "Kỹ năng nói xuất sắc",
                  pleaseSelectASingleKnowledgePoint:
                    "Vui lòng chọn một điểm kiến thức duy nhất",
                },
              },
            },
            index: {
              name: "Tên",
              pushText: "Văn bản đẩy",
              enableStatus: "Bật trạng thái",
              triggerRules: "Quy tắc kích hoạt",
              communicationStrategy: "Chiến lược truyền thông",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "Nhóm chiến lược truyền thông",
              name: "Tên",
              pushText: "Văn bản đẩy",
              effectiveness: "Hiệu quả",
              openSuccessfully: "Mở thành công",
              closeSuccessfully: "Đóng thành công",
              operationFailed: "Hoạt động thất bại",
              operation: "Hoạt động",
              edit: "Chỉnh sửa",
              delete: "Xóa",
              newlyBuild: "Mới xây dựng",
              communicationStrategy: "Chiến lược truyền thông",
              editGroup: "Nhóm chỉnh sửa",
              groupName: "Tên nhóm",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Tất cả các phiên",
                  recentDays: "Số ngày gần nhất",
                  recentSessions: "Phiên gần đây",
                  scopeOfApplication: "Phạm vi áp dụng:",
                  day: "Ngày",
                  through: "Thông",
                },
              },
            },
            index: {
              describe: "Mô tả",
              applicableCustomers: "Khách hàng áp dụng",
              enableStatus: "Bật trạng thái",
              triggerRules: "Quy tắc kích hoạt",
              riskRules: "Quy tắc rủi ro",
            },
          },
          list: {
            index: {
              riskAllocation: "Phân bổ rủi ro",
              keyWord: "Từ khóa",
              newRiskRule: "Quy tắc rủi ro mới",
              riskRuleName: "Tên quy tắc rủi ro",
              describe: "Mô tả",
              state: "Trạng thái",
              openSuccessfully: "Mở thành công",
              closeSuccessfully: "Đóng thành công",
              operationFailed: "Hoạt động thất bại",
              operation: "Hoạt động",
              edit: "Chỉnh sửa",
              delete: "Xóa",
              deletionSucceeded: "Xóa thành công",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Mất đơn" },
              endReasonList: {
                addEndReason: "Thêm lý do kết thúc",
                enterEndReason: "Nhập lý do kết thúc",
              },
              stageList: {
                keyEvents: "Sự kiện quan trọng",
                waysideData: "Dữ liệu theo đường",
                includeAny: "Chứa tùy ý",
                includeAll: "Chứa tất cả",
                pleaseEnterTheStageName: "Vui lòng nhập tên giai đoạn",
                setStageRules: "Thiết lập quy tắc giai đoạn",
                stageName: "Tên giai đoạn",
                operator: "Toán tử",
                logic: "Logic",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Định dạng đầu vào của trường loại thời gian là: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Yêu cầu không thành công, vui lòng thử lại sau",
              salesStage: "Giai đoạn bán hàng",
              essentialInformation: "Thông tin cơ bản",
              name: "Tên",
              applicableDepartments: "Bộ phận áp dụng",
              applicableToOthers: "Áp dụng khác",
              manualChangePhase: "Giai đoạn thay đổi thủ công",
              enableStatus: "Bật trạng thái",
              describe: "Mô tả",
              opportunityStageSettings: "Thiết lập giai đoạn cơ hội kinh doanh",
              stageSettings: "Thiết lập giai đoạn",
              aliasSettings: "Cài đặt bí danh",
              closeOpportunity: "Kết thúc cơ hội kinh doanh",
              newSuccessfully: "Mới thành công",
              newFailed: "Không thành công mới",
              savingSucceeded: "Lưu thành công",
              saveFailed: "Lưu thất bại",
            },
          },
          list: {
            index: {
              salesStage: "Giai đoạn bán hàng",
              keyWord: "Từ khóa",
              newSalesStage: "Giai đoạn bán hàng mới",
              salesStageName: "Tên giai đoạn bán hàng",
              describe: "Mô tả",
              state: "Trạng thái",
              openSuccessfully: "Mở thành công",
              closeSuccessfully: "Đóng thành công",
              operationFailed: "Hoạt động thất bại",
              operation: "Hoạt động",
              edit: "Chỉnh sửa",
              delete: "Xóa",
              deletionSucceeded: "Xóa thành công",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Tạo mô hình lớn",
            conversationInsights: "Thông tin chi tiết hội thoại",
          },
          sessionsInsight: {
            components: {
              autoGenerate: { index: { automaticGeneration: "Tự động tạo" } },
              hooks: {
                conversationSummary: "Tóm tắt phiên",
                conversationProcess: "Quá trình phiên",
                opportunityPoints: "Điểm cơ hội",
              },
              prompt: {
                index: {
                  edit: "Chỉnh sửa",
                  restoreDefault: "Khôi phục mặc định",
                  cancel: "Hủy bỏ",
                  confirm: "Xác nhận",
                },
              },
            },
            index: {
              saveSuccessful: "Lưu thành công",
              hintLanguage: "Lời nhắc nhở",
              preserve: "Lưu",
              cancel: "Hủy bỏ",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Tạo mô hình lớn",
        salesStage: "Giai đoạn bán hàng",
        communicationStrategy: "Chiến lược truyền thông",
        riskAllocation: "Phân bổ rủi ro",
        businessConfiguration: "Cấu hình kinh doanh",
      },
      salesLearningCenter: {
        knowledgeBase: "Cơ sở kiến thức",
        speechCorpus: "Thư viện ngôn ngữ",
        learningCenter: "Trung tâm học tập",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Thông tin khách hàng" },
          },
        },
        index: { otherInformation: "Thông tin khác" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Nhập" } } },
        hooks: {
          index: {
            all: "Tất cả",
            intelligentModel: "Mô hình thông minh",
            smartRules: "Quy tắc thông minh",
            manualScoring: "Điểm thủ công",
            enable: "Kích hoạt",
            notEnabled: "Không kích hoạt",
            voiceCall: "Cuộc gọi thoại",
            textDialogue: "Đối thoại văn bản",
            ecommerceTextSession: "Phiên văn bản thương mại điện tử",
            workOrder: "Danh sách công việc",
          },
        },
        index: {
          ruleName: "Tên quy tắc",
          ruleType: "Loại quy tắc",
          pleaseSelect: "Vui lòng chọn",
          ruleStatus: "Trạng thái quy tắc",
          dataSourceType: "Loại nguồn dữ liệu",
          voiceCall: "Cuộc gọi thoại",
          textDialogue: "Đối thoại văn bản",
          ecommerceTextSession: "Phiên văn bản thương mại điện tử",
          workOrder: "Danh sách công việc",
          status: "Trạng thái",
          operation: "Hoạt động",
          edit: "Chỉnh sửa",
          ruleTemplate: "Mẫu quy tắc",
        },
      },
      analysisReportTitle: "Báo cáo phân tích",
      ruleTest: "Kiểm tra quy tắc",
      intelligentTags: {
        labelDisplayQuantity: "Nhãn hiển thị số lượng",
        unlimited: "Không giới hạn",
        ignoringTagLibraries: "Bỏ qua thư viện thẻ",
        automaticallyGenerateLabels: "Tự động tạo nhãn",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'Sau khi mở, phiên được tạo tự động dựa trên mô hình lớn và các thẻ được tạo được phân loại thành "Chưa được phân loại" theo mặc định',
        defaultValidity: "Hiệu lực mặc định",
        supervisionItem: "Mục giám sát",
        customerLabel: "Nhãn khách hàng",
        newSupervisionItemRule: "Quy tắc giám sát mới",
        editSupervisionItemRule: "Chỉnh sửa quy tắc giám sát",
        newCustomerLabel: "Nhãn khách hàng mới",
        editCustomerLabel: "Chỉnh sửa thẻ khách hàng",
        newSessionLabel: "Nhãn phiên mới",
        editSessionLabel: "Chỉnh sửa thẻ phiên",
        newKeyEvent: "Sự kiện quan trọng mới",
        editKeyEvents: "Chỉnh sửa các sự kiện quan trọng",
        detail: {
          template: { nameIsARequiredField: "Tên là trường bắt buộc" },
          formItemComponent: {
            largeModelExtensionLabel: "Nhãn mở rộng mô hình lớn",
            sessionRecordID: "Bản ghi phiên id",
            sessionTime: "Thời gian phiên",
            smartTags: "Nhãn thông minh",
            viewDetails: "Xem chi tiết",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Sau khi xóa, dữ liệu được ghi lại bởi phiên này sẽ bị trống. Bạn có xác nhận xóa không?",
            tagData: "Đánh dấu dữ liệu",
            batchDeletion: "Xóa hàng loạt",
          },
        },
        template: {
          advancedFiltering: "Sàng lọc nâng cao",
          dataType: "Kiểu dữ liệu",
          filterCriteria: "Điều kiện sàng lọc",
          edit: "Chỉnh sửa",
          createANewSubcategory: "Phân loại phụ mới",
          delete: "Xóa",
          nameLengthCannotExceed: "Độ dài tên không được vượt quá 40",
        },
        component: {
          modelExpansion: "Mở rộng mô hình",
          enable: "Kích hoạt",
          notEnabled: "Không kích hoạt",
          standardScreening: "Sàng lọc tiêu chuẩn",
          advancedFiltering: "Sàng lọc nâng cao",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Từ khóa",
              sessionLabel: "Nhãn phiên",
              keyEvents: "Sự kiện quan trọng",
              matchAny: "Phù hợp với bất kỳ",
              matchAll: "Phù hợp với tất cả",
              beEqualTo: "Bằng",
              includeAll: "Chứa tất cả",
              includeAny: "Chứa tùy ý",
              advancedConditions: "Điều kiện nâng cao",
              smartTag: "Nhãn thông minh",
              smartTagI: "Nhãn thông minh một",
              smartTagII: "Nhãn thông minh II",
            },
            template: {
              pleaseSelectSmartTag: "Vui lòng chọn nhãn thông minh",
              pleaseSelectASessionLabel: "Vui lòng chọn tab phiên",
              pleaseSelectAKeyEvent: "Vui lòng chọn sự kiện quan trọng",
              pleaseSelectAdvancedCondition: "Vui lòng chọn điều kiện nâng cao",
              newAdvancedFilter: "Bộ lọc nâng cao mới",
              moveUp: "Di chuyển lên",
              moveDown: "Di chuyển xuống",
              moveLeft: "Dịch chuyển trái",
              shiftRight: "Dịch chuyển sang phải",
            },
          },
          component: {
            businessAnalysispng: "Phân tích kinh doanh. png",
            savedSuccessfully: "Lưu thành công",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Hiệu quả vĩnh viễn",
            permanentlyInvalid: "Không hợp lệ vĩnh viễn",
            customValidity: "Thời hạn hiệu lực tùy chỉnh",
            termOfValidity: "Thời hạn hiệu lực",
            name: "Tên",
            explain: "Mô tả",
            state: "Trạng thái",
            classification: "Phân loại",
            smartRecommendation: "Khuyến nghị thông minh",
            preconditions: "Điều kiện tiên quyết",
            SetPreconditionsForThisSupervisionItem:
              "1. Đặt điều kiện tiên quyết cho mục giám sát này:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'Khi các điều kiện tiên quyết được đáp ứng, nghĩa là "mục giám sát cần được thực hiện", mục giám sát sẽ được kiểm tra;',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Khi các điều kiện tiên quyết không được đáp ứng, mục giám sát sẽ không được kiểm tra;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Không đặt điều kiện trước, tức là tất cả các phiên được phát hiện theo mặc định",
            have: "Có",
            nothing: "Không có",
            preconditionRule: "Quy tắc tiền điều kiện",
            preconditionLogic: "Logic tiền điều kiện",
          },
          recommendation: {
            caseGrouping: "Nhóm trường hợp",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Khi nhân viên không thực hiện đúng cách, họ có thể đề xuất các kỹ năng nói xuất sắc và các trường hợp xuất sắc một cách thông minh",
            smartRecommendation: "Khuyến nghị thông minh",
            open: "Mở",
            close: "Đóng cửa",
            whenThisSupervisionItem: "Khi mục giám sát này",
            implement: "Thực hiện",
            unexecuted: "Không thực hiện",
            when: "Thời gian:",
            recommendedKnowledgePoints: "Điểm kiến thức được đề xuất",
            caseRecommendation: "Đề xuất trường hợp",
          },
        },
        index: {
          determine: "Chắc chắn",
          sort: "Sắp xếp",
          sortSucceeded: "Sắp xếp thành công",
          supervisionItemClassification: "Phân loại mục giám sát",
          pressEnterToCreateANew: "Nhấn enter để tạo",
          nameCannotBeEmpty: "Tên không được để trống",
          newlyBuild: "Mới xây dựng",
          name: "Tên",
          explain: "Mô tả",
          validStatus: "Trạng thái hợp lệ",
          permanentlyValid: "Hiệu quả vĩnh viễn",
          permanentlyInvalid: "Không hợp lệ vĩnh viễn",
          operation: "Hoạt động",
          edit: "Chỉnh sửa",
          batchDelete: "Xóa hàng loạt",
          newSupervisionItem: "Mục giám sát mới",
          keyWord: "Từ khóa",
          delete: "Xóa",
          deleteSucceeded: "Xóa thành công",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Dữ liệu sẽ không được khôi phục sau khi bị xóa. Việc xóa có được xác nhận không?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Nhãn thông minh",
              beEqualTo: "Bằng",
              includeAll: "Chứa tất cả",
              includeAny: "Chứa tùy ý",
              matchAny: "Phù hợp với bất kỳ",
              matchAll: "Phù hợp với tất cả",
              smartTagI: "Nhãn thông minh một",
              smartTagII: "Nhãn thông minh II",
            },
            template: {
              pleaseSelectASmartTag: "Vui lòng chọn nhãn thông minh",
              pleaseSelectAdvancedConditions:
                "Vui lòng chọn điều kiện nâng cao",
              newAdvancedFilter: "Bộ lọc nâng cao mới",
              rename: "Đổi tên",
              moveUp: "Di chuyển lên",
              moveDown: "Di chuyển xuống",
              shiftLeft: "Dịch chuyển trái",
              shiftRight: "Dịch chuyển sang phải",
            },
          },
        },
        template: {
          delete: "Xóa",
          labelFiltering: "Sàng lọc nhãn:",
          satisfyAny: "Đáp ứng tùy ý",
          meetAll: "Đáp ứng tất cả",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Số lượng cuộc hội thoại thẻ phân loại thứ cấp ≥ Phân loại cấp một, một cuộc hội thoại có thể chứa nhiều thẻ",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logic tính toán thay đổi theo chuỗi: (khối lượng đối thoại trong khoảng thời gian đã chọn-khối lượng đối thoại trong khoảng thời gian trước đó bên cạnh)/khối lượng đối thoại trong khoảng thời gian trước",
          proportionOfPrimaryClassification: "Tỷ lệ phân loại cấp một",
          saveAs: "Lưu dưới dạng",
          cover: "Phủ sóng",
          preservation: "Lưu",
          qualityInspectionTask: "Nhiệm vụ phân tích:",
          query: "Truy vấn",
          changeFromMonthToMonth: "Thay đổi theo tháng",
          tagRanking: "Xếp hạng nhãn",
          proportion: "Tỷ lệ",
        },
        component: {
          numberOfConversations: "Số đối thoại",
          operation: "Hoạt động",
          businessAnalysispng: "Phân tích kinh doanh. png",
          savingSucceeded: "Lưu thành công",
          deletionSucceeded: "Xóa thành công",
          sequence: "Trình tự",
          firstLevelClassification: "Phân loại cấp một",
          ringRatioChange: "Thay đổi chuỗi",
          classClassification: "Phân loại cấp",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Xác nhận xóa tài liệu và nội dung trích xuất của nó?",
            addKnowledgeBase: "Thêm cơ sở kiến thức",
            uploadDocument: "Tải lên tài liệu",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Hỗ trợ các tập tin định dạng word, pdf, ppt, excel, txt",
            taskName: "Tên nhiệm vụ",
            extractConversationDate: "Trích xuất ngày đối thoại",
            pleaseSelectAConversationDate: "Vui lòng chọn ngày hội thoại",
            dataSource: "Nguồn dữ liệu",
            dataType: "Kiểu dữ liệu",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Xuất thành công",
              problem: "Vấn đề",
              answer: "Câu trả lời",
              addToKnowledgeBase: "Thêm vào cơ sở kiến thức",
              pleaseConfirmWhetherToDeleteIt:
                "Vui lòng xác định có xóa hay không",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Thêm vào cơ sở kiến thức",
              selectClassification: "Chọn phân loại",
              pleaseSelectAKnowledgeBaseClassification:
                "Vui lòng chọn phân loại cơ sở kiến thức",
            },
          },
        },
        index: {
          intelligentExtraction: "Trích xuất thông minh",
          documentExtraction: "Trích xuất tài liệu",
          dialogueExtraction: "Trích xuất đối thoại",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Hoạt động thành công",
          labelIgnored: "Nhãn bị bỏ qua",
          add: "Thêm",
          areYouSureToDeleteIt: "Chắc chắn xóa?",
          batchFilterLabels: "Nhãn lọc hàng loạt",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Kiểm tra chất lượng thông minh",
        savedSuccessfully: "Lưu thành công",
        logoSettings: "Cài đặt logo",
        default: "Mặc định",
        custom: "Tùy chỉnh",
        title: "Tiêu đề",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Tên tiêu đề hiển thị trong hệ thống được khuyến nghị trong vòng 6 ký tự.",
        logoImageUpload: "Tải lên hình ảnh logo",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Vui lòng chọn hình ảnh có kích thước không quá 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Để đảm bảo hiệu quả duyệt logo, bạn nên tải ảnh lên:",
        Size5050Pixels: "1. kích thước: 50*50 pixel;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Định dạng: nền trong suốt cho hình ảnh, png hoặc jpg, biểu tượng đồ họa sử dụng màu trắng",
        browserLabelIcon: "Biểu tượng nhãn trình duyệt",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Để đảm bảo hiệu quả của việc duyệt thẻ, bạn nên tải ảnh lên:",
        Size3232Pixels: "1. kích thước: 32*32 pixel;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Định dạng: nền trong suốt cho hình ảnh, png hoặc jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Đang tiến hành kiểm tra chất lượng, vui lòng đợi...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "Vào năm 2015, bất động sản của Trung Quốc đã đến thời điểm quan trọng để tồn tại, một số lượng lớn nhà không được quan tâm và người dân không muốn mua. Hàng Châu đã từng giảm xuống dưới 8.000 và một số nhà phát triển đã đập phá thị trường và xuất xưởng. Thâm Quyến vẫn đang củng cố vào khoảng 2w-3w vào thời điểm đó, và nhà ở các thành phố cấp một cũng đang gặp nguy hiểm.",
        xiaoAn: "Tiểu An",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload Các rào cản bộ nhớ này được gọi là rào cản bộ nhớ jsr, ở cấp độ jvm. Có hai cách để triển khai ở dưới cùng của hệ điều hành: 1 là khóa bus và cách thứ hai là yếu lý lịch rào cản bộ nhớ, chẳng hạn như rào cản ghi (rào cản ghi) mfence (rào cản đầy đủ) ifence (rào cản đọc). Phần dưới cùng của máy ảo hotspots sử dụng một câu lệnh lắp ráp: khóa addl, khóa một hoạt động trống, là một bus khóa. Điều này được thực hiện bởi vì một số hệ điều hành không hỗ trợ các phần tử rào cản bộ nhớ.",
        xiaobei: "Tiểu Bắc",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Cố gắng ướp thịt vài lần, rau xào sau khi thêm vào, thịt thối và có mùi chua! Kể từ đó, thịt được ướp và không có rượu nấu ăn",
        xiaodong: "Tiểu Đông",
        xiaoxi: "Tiểu Tây",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Cố gắng ướp thịt vài lần, rau xào sau khi thêm vào, thịt thối và có mùi chua! Kể từ đó, thịt được ướp và không có rượu nấu ăn",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Quản lý đánh giá thương mại điện tử",
        socialMediaReviewManagement: "Quản lý bình luận truyền thông xã hội",
        customDataSource: "Dữ liệu tùy chỉnh gốc",
        qualityInspectionResults: "Kết quả kiểm tra chất lượng",
        accountManagement: "Quản lý tài khoản",
        employeeProfile: "Chân dung nhân viên",
        intelligentAnalysis: "Phân tích thông minh",
        customKanban: "Kanban tùy chỉnh",
        dataScreening: "Tổng quan về dữ liệu",
        learningRecord: "Hồ sơ học tập",
        dataAnalysis: "Phân tích dữ liệu",
        scoringManagement: "Quản lý điểm",
        businessConfiguration: "Cấu hình kinh doanh",
        manualReview: "Đánh giá thủ công",
        taskList: "Danh sách nhiệm vụ",
        videoAnalysis: "Phân tích video",
        overview: "Tổng quan",
        personalSignage: "Kanban cá nhân",
        learningCenter: "Trung tâm học tập",
        wrongQuestionSet: "Bộ câu hỏi sai",
        abilityImprovement: "Nâng cao năng lực",
        close: "Đóng cửa",
        switchingSystemApplications: "Chuyển đổi ứng dụng hệ thống",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Di chuyển chuột đến đây để gọi menu chuyển đổi ứng dụng, nhấp vào mục menu để chuyển đến các ứng dụng khác nhau",
        teamAnalysis: "Phân tích nhóm",
        semanticIntelligence: "Ngữ nghĩa thông minh",
        permissionManagement: "Quản lý quyền",
        dataConnection: "Docking dữ liệu",
        courseManagement: "Quản lý khóa học",
        labelManagement: "Quản lý nhãn",
        curriculum: "Trung tâm khóa học",
        courseConfiguration: "Cấu hình khóa học",
        task: "Nhiệm vụ của tôi",
        historicalLearningData: "Lịch sử",
        taskCenter: "Quản lý nhiệm vụ",
        knowledgeBase: "Cơ sở kiến thức",
        ecommerceSessionManagement: "Quản lý phiên thương mại điện tử",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Chuyển đổi ứng dụng",
          descriptionOfIntelligentQualityInspectionSystem:
            "Mô tả hệ thống kiểm tra chất lượng thông minh",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Bộ lọc đàm thoại thương mại điện tử",
        allEcommerceSessions: "Tất cả các cuộc hội thoại thương mại điện tử",
        ecommerceSessionManagement: "Quản lý phiên thương mại điện tử",
      },
    },
    analysisEnable: {
      bestPractices: "Thực hành tốt nhất",
      behaviorAnalysis: "Phân tích hành vi",
      teamAnalysis: "Phân tích nhóm",
      executiveSupervision: "Giám sát thực thi",
      executiveForceAnalysis: "Phân tích thực thi",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Xếp hạng:",
          startTime: "Thời gian bắt đầu",
          workOrderNo: "Số hóa đơn công việc",
          customerServiceHasReadNotRead: "Dịch vụ khách hàng đã đọc/chưa đọc",
          canYouAppeal: "Có thể kháng cáo không",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Ghi chú vi mô doanh nghiệp",
          addFriendTime: "Thêm thời gian bạn bè",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Bắt đầu thực hiện kiểm tra lại",
          callID: "ID cuộc gọi",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Tính năng mới! Nhấp vào nút 'lấy mẫu nhanh' và nó sẽ tự động chuyển sang trang tiếp theo sau khi gửi thành công ~~~",
        },
      },
      template: {
        deleteRecord: "Xóa bản ghi",
        areYouSureToDeleteIt: "Chắc chắn xóa?",
        confirm: "Chắc chắn",
        reIdentification: "Xác định lại",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Nhận dạng lại sẽ tiêu tốn thời gian chuyển tiếp, hãy cẩn thận",
        determine: "Chắc chắn",
        executionSuccessful: "Thực hiện thành công",
      },
      component: {
        pleaseSelectData: "Vui lòng chọn dữ liệu",
        operationSuccessful: "Hoạt động thành công",
        startPerformingReQualityInspection: "Bắt đầu thực hiện kiểm tra lại",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Tải lên tên tệp",
        analysisTask: "Nhiệm vụ phân tích",
        analyzeProgress: "Tiến độ phân tích",
        analysisSucceeded: "Phân tích thành công",
        analysisFailed: "Phân tích thất bại",
        localDataUpload: "Tải lên dữ liệu cục bộ",
        recordingUpload: "Tải lên bản ghi",
        textUpload: "Tải lên văn bản",
        uploadTime: "Thời gian tải lên",
        taskName: "Tên nhiệm vụ",
        operator: "Người điều hành",
        qualityInspectionProgress: "Tiến độ kiểm tra chất lượng",
        whole: "Tất cả",
        haveInHand: "Đang tiến hành",
        completed: "Đã hoàn thành",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Bạn có xuất tất cả các bản ghi dữ liệu đã hoàn thành trong bộ lọc hiện tại không?",
        upload: "Tải lên",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Điền tên",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Điền tên, chẳng hạn như loại/bộ sưu tập/mục đích nhập dữ liệu lần này",
            default: "Mặc định",
            jDCOM: "Jingdong",
            dataType: "Kiểu dữ liệu",
            selectAnalysisTask: "Chọn nhiệm vụ phân tích",
            uploadFiles: "Tải lên tập tin",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Tải mẫu excel, điền vào định dạng:",
            template: "Mẫu",
            SupportUpTo10000PiecesOfData: "2. Hỗ trợ tối đa 10000 dữ liệu",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Hỗ trợ tải lên dữ liệu hội thoại do JD xuất, tệp. txt, kích thước tệp hỗ trợ tối đa 100M",
            alongTheWayDataTemplate: "Mẫu dữ liệu theo dõi",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Đang kiểm tra chất lượng văn bản, hãy kiên nhẫn",
            textQualityInspectionCompleted:
              "Hoàn thành kiểm tra chất lượng văn bản",
          },
        },
        template: {
          startAnalysis: "Bắt đầu phân tích",
          pleaseUploadTheFile: "Vui lòng tải lên tệp",
          localDataUpload: "Tải lên dữ liệu cục bộ",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            'Tính năng mới! Nhấp vào nút "Xem lại và gửi", nó sẽ tự động chuyển sang trang tiếp theo sau khi gửi thành công ~~~',
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Kiểm tra chất lượng nhiệm vụ thẩm quyền",
            edit: "Chỉnh sửa",
            iParticipated: "Tôi đã tham gia",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Vai trò có thể xem dữ liệu trong bộ phận đã chọn",
            dataPermissions: "Quyền dữ liệu",
            selectDepartment: "Chọn bộ phận",
            edit: "Chỉnh sửa",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Bắt đầu thực hiện kiểm tra lại",
          callID: "ID cuộc gọi",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'Tính năng mới! Nhấp vào nút "Gửi kiểm tra lấy mẫu" và "Kiểm tra lấy mẫu nhanh", nó sẽ tự động chuyển sang trang tiếp theo sau khi gửi thành công ~~~',
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Quy tắc" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Quản lý đơn đặt hàng công việc",
          workOrderDetails: "Chi tiết đơn đặt hàng công việc",
        },
      },
      template: {
        workOrderFilter: "Bộ lọc đơn đặt hàng công việc",
        allWorkOrders: "Tất cả các đơn đặt hàng công việc",
        workOrderManagement: "Quản lý đơn đặt hàng công việc",
      },
    },
    trainingCenter: {
      topicAnnotation: "Chú thích chủ đề",
      topicList: "Danh sách chủ đề",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Loại nhân vật",
              keyWord: "Từ khóa",
              pleaseEnterKeywordSearch: "Vui lòng nhập từ khóa tìm kiếm",
              query: "Truy vấn",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Chức năng này chưa được mở, vui lòng liên hệ với quản trị viên hệ thống nếu cần",
          pleaseSelectAQuestion: "Vui lòng chọn câu hỏi!",
          recommendedSimilarSentencesLargeModel:
            "Đề xuất các câu tương tự (mô hình lớn)",
          selectAll: "Chọn tất cả",
          add: "Thêm",
          changeBatch: "Thay đổi một lô",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Thời gian làm việc kinh doanh",
      localDataUpload: "Tải lên dữ liệu cục bộ",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Lắp ráp danh sách menu dựa trên quyền người dùng hiện tại",
      sessionSettings: "Cài đặt phiên",
      logoSettings: "Cài đặt logo",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Chào mừng trở lại! Vui lòng nhập thông tin chi tiết của bạn",
        mailbox: "Hộp thư",
        password: "Mật khẩu",
        signIn: "Đăng nhập",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "IP hiện tại không cho phép đăng nhập",
          welcomeToLogin: "Chào mừng bạn đến đăng nhập",
          pursuingExcellenceAndLeadingChange:
            "Theo đuổi sự xuất sắc và thay đổi hàng đầu",
          intelligentPlatform: "Nền tảng thông minh",
        },
        virtualLogin: { index: { pleaseSelect: "Vui lòng chọn" } },
      },
      oauth: {
        illegalCall: "Cuộc gọi bất hợp pháp",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Chào mừng trở lại! Nhấp vào nút bên dưới để chuyển đến nền tảng xác thực danh tính",
        loggingIn: "Đăng nhập...",
        unifiedIdentityAuthenticationLogin:
          "Đăng nhập xác thực danh tính hợp nhất",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Kiểm tra quy tắc: Một số quy tắc không thể sử dụng kiểm tra quy tắc, chẳng hạn như âm lượng tốc độ nói, dữ liệu theo dõi, v. v.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Tìm hiểu thêm",
          enteringTheSystem: "Vào hệ thống",
        },
      },
      index: {
        cancel: "Hủy bỏ",
        enteringTheSystem: "Vào hệ thống",
        close: "Đóng cửa",
        enterSystemApplication: "Nhập ứng dụng hệ thống",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'Nhấp vào "Nhập hệ thống" để chuyển đến ứng dụng hệ thống đã bật',
      },
    },
    permissionManagement: {
      employeeManagement: "Quản lý nhân viên",
      roleManagement: "Quản lý vai trò",
      permissionManagement: "Quản lý quyền",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Bằng",
            contain: "Chứa",
            doesNotContain: "Không chứa",
            applicationSystem: "Hệ thống ứng dụng",
            intelligentQualityInspection:
              "Kiểm tra chất lượng thông minh/phân tích phiên thông minh",
            salesEmpowerment: "Trao quyền bán hàng",
            dataSourceType: "Loại nguồn dữ liệu",
            filterCriteria: "Điều kiện sàng lọc",
          },
        },
        index: {
          dataConnection: "Docking dữ liệu",
          savingSucceeded: "Lưu thành công",
          dataIsolation: "Cách ly dữ liệu",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Sau khi mở, bạn có thể đặt phạm vi dữ liệu của từng hệ thống ứng dụng để truy cập các cuộc gọi và phiên WeChat của công ty. Nếu công tắc không được bật, mỗi hệ thống sẽ truy cập đầy đủ dữ liệu",
          accessRules: "Quy tắc truy cập",
          preservation: "Lưu",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Tên ứng dụng",
            accessRights: "Quyền truy cập",
            inAppPermissionSettings: "Cài đặt quyền trong ứng dụng",
            setUp: "Cài đặt",
            applicationAccess: "Quyền truy cập ứng dụng",
            newRole: "Thêm vai trò",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "Chi tiết phiên" } },
      messageNotification: {
        index: {
          sessionDetails: "Chi tiết phiên",
          caseDetails: "Chi tiết trường hợp",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Nhân viên có thể nhìn thấy",
            newCourse: "Khóa học mới",
            editCourse: "Chỉnh sửa khóa học",
            courseName: "Tên khóa học",
            courseIntroduction: "Giới thiệu khóa học",
            courseType: "Loại khóa học",
            courseClassification: "Phân loại khóa học",
            courseCover: "Bìa khóa học",
            learningContent: "Nội dung học tập",
            uploadLearningMaterials: "Tải lên tài liệu học tập",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Kiểm tra",
              preservation: "Lưu",
              cancel: "Hủy bỏ",
              editNodeName: "Chỉnh sửa tên nút",
              determine: "Chắc chắn",
              individual: "Cái",
              of: ", Tổng cộng",
              branch: "Điểm",
            },
            configDrawerComponents: {
              pPTParsing: "Phân tích PPT...",
              uploadSuccessful: "Tải lên thành công",
              uploadAttachments: "Tải lên tệp đính kèm",
              pPTParsingPleaseWait: "Phân tích PPT, vui lòng đợi",
            },
            customerPortraitConfigDrawer: {
              cancel: "Hủy bỏ",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Không thể khôi phục sau khi xóa, bạn có chắc chắn muốn xóa bức chân dung này không?",
              delete: "Xóa",
              determine: "Chắc chắn",
              customerPortrait: "Chân dung khách hàng",
              bindSelectedCharacterPortrait:
                "Ràng buộc chân dung nhân vật đã chọn",
              customerProfileDetails: "Chi tiết chân dung khách hàng",
              createANewCustomerProfile: "Chân dung khách hàng mới",
            },
            flow: {
              sidebar: { node: "Nút" },
              useControlButtons: {
                cancellingPleaseWait: "Thu hồi, vui lòng đợi...",
                redoInProgressPleaseWait: "Làm lại, vui lòng đợi...",
                automaticOrganization: "Tự động sắp xếp",
                canvasAdaptation: "Vải thích ứng",
                delete: "Xóa",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Các nút không thể kết nối với chính chúng",
              },
            },
            globalConfigDrawer: {
              cancel: "Hủy bỏ",
              determine: "Chắc chắn",
              globalConfiguration: "Cấu hình toàn cầu",
              sessionDetection: "Phát hiện phiên",
              reciprocalRule: "Quy tắc tương tác",
              robotVoice: "Âm thanh robot",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Nút sinh viên không trả lời cài đặt",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Thời gian im lặng tối đa cho phép khi học sinh không trả lời (s)",
              promptScriptForFailureToAnswerOverTime:
                "Không có lời nhắc trả lời ngoài giờ",
              addScript: "Thêm lời",
            },
            robotTimbreForm: {
              aixianatural: "Aixia (tự nhiên)",
              zhishaChat: "Zhisha (trò chuyện)",
              zhiqiangentle: "Zhiqian (nhẹ nhàng)",
              zhimiEmotion: "Zhimi (cảm xúc)",
              aiShuoNatural: "Ai Shuo (tự nhiên)",
              zhishuoChat: "Zhishuo (trò chuyện)",
              aiHaoAffinity: "Ai Hao (thân thiện)",
              zhixiangEmotion: "Zhixiang (cảm xúc)",
              robotVoice: "Âm thanh robot",
              schoolboy: "Nam sinh",
              girlStudent: "Nữ sinh",
              volume: "Âm lượng",
              tone: "Tông màu",
              speechSpeed: "Tốc độ nói",
              test: "Kiểm tra",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Xin chào, tôi là một huấn luyện viên đào tạo thông minh, mong được giao tiếp với bạn",
            },
            sessionDetectionForm: {
              expressionDetection: "Phát hiện biểu hiện",
              excessiveDetectionOfModalParticles:
                "Phát hiện quá nhiều giọng nói",
              theNumberOfRepetitionsIs: "Số lần lặp lại là",
              minus: "Thời gian, giảm",
              appear: "Xuất hiện",
              subtract: "Thời gian, giảm",
              branch: "Điểm",
              addRules: "Tăng quy tắc",
              includingNegativeAngryComplaining:
                "(Bao gồm tiêu cực, tức giận, phàn nàn)",
              scorePointsDisplay: "Điểm hiển thị",
              reminderOfSpeakingTooFasttooSlow:
                "Tốc độ nói quá nhanh/nhắc nhở quá chậm",
              scriptViolationReminder: "Lời nhắc vi phạm",
              emotionalReminder: "Nhắc nhở cảm xúc",
              speechSpeedDetection: "Phát hiện tốc độ nói",
              normalSpeechSpeedScore: "Tốc độ nói bình thường",
              speakTooFast: "Nói quá nhanh",
              everyMinuteGreaterThan: "Lớn hơn mỗi phút",
              aWordAppears: "Từ, xuất hiện",
              speakTooSlowly: "Nói chậm",
              lessThanPerMinute: "Ít hơn mỗi phút",
              ignoreLessThan: "Bỏ qua ít hơn",
              aSentenceOfWords: "Câu từ",
              emotionalDetection: "Phát hiện cảm xúc",
              emotionalNormalScore: "Điểm số cảm xúc bình thường",
              negativeEmotions: "Cảm xúc tiêu cực",
              illegalWordDetection: "Phát hiện từ vi phạm",
              scriptViolationConfiguration: "Cấu hình vi phạm lời nói",
              realTimePromptOnTheFrontEnd: "Mẹo thời gian thực front-end",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Câu hỏi và câu trả lời về cơ sở kiến thức",
                  qAMethod: "Phương pháp hỏi đáp",
                  numberOfQuestionsAndAnswers: "Số câu hỏi và câu trả lời",
                  scoringRules: "Quy tắc ghi bàn",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Bất kỳ câu hỏi và câu trả lời nào đều đúng, phải",
                  branch: "Điểm",
                  successfullySaved: "Lưu thành công",
                },
                pptNode: {
                  verificationPassed: "Đã thông qua xác minh",
                  upload: "Tải lên",
                  supportPptPdfFormatFiles: "Hỗ trợ ppt, tập tin định dạng pdf",
                  uploadSlide: "Tải lên trình chiếu",
                  assessmentMethod: "Phương pháp đánh giá",
                  associateSlide: "Trang trình bày liên kết",
                  standardScript: "Kỹ năng nói tiêu chuẩn",
                  successfullySaved: "Lưu thành công",
                },
                robotNode: {
                  robotScript: "Thuật ngữ robot",
                  randomScript: "Kỹ thuật nói ngẫu nhiên",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Chọn các từ ngữ nghĩa tương tự và robot sẽ nói ngẫu nhiên một trong các từ",
                  customerPortrait: "Chân dung khách hàng",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Robot nói điều này khi chân dung của khách hàng đồng nhất",
                  setAsDefaultScript: "Đặt làm thuật ngữ mặc định",
                  yes: "Là",
                  no: "Không.",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Điều này xảy ra khi robot không đánh trúng bất kỳ thẻ người dùng nào",
                  successfullySaved: "Lưu thành công",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Nút nhánh này đã có thuật ngữ mặc định. Bạn có thay thế thuật ngữ mặc định bằng thuật ngữ hiện tại không?",
                },
                startNode: {
                  paragon: "Tường thuật",
                  successfullySaved: "Lưu thành công",
                },
                userNode: {
                  verificationPassed: "Đã thông qua xác minh",
                  standardScript: "Kỹ năng nói tiêu chuẩn",
                  successfullySaved: "Lưu thành công",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "Vui lòng cải thiện cấu hình",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Vui lòng nhập ví dụ cuộc hội thoại",
                  dialogueExample: "Ví dụ về cuộc trò chuyện",
                  generateConversationExamples: "Tạo ví dụ hội thoại",
                  mattersNeedingAttention: "Những vấn đề cần chú ý",
                  pleaseEnterThePromptLanguage: "Vui lòng nhập lời nhắc",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Cảnh đối thoại",
                  simulateCharacter: "Mô phỏng vai trò",
                  accompanyingTrainees: "Đối tượng đào",
                  selectFromTemplateLibrary: "Chọn từ thư viện mẫu",
                  templateLibrary: "Thư viện mẫu",
                  role: "Vai trò",
                  scene: "Cảnh",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Cửa sổ kiểm tra trình chiếu 【",
              clearContent: "Xóa nội dung",
              close: "Đóng cửa",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "Quy trình tạo AI",
                scriptStyle: "Phong cách nói",
                theMostConversationRounds: "Nhiều vòng đối thoại nhất",
                copyConversation: "Sao chép cuộc trò chuyện",
                application: "Ứng dụng",
                regenerate: "Tái tạo",
                startGenerating: "Bắt đầu tạo",
                pleaseGenerateAProcess: "Vui lòng tạo quy trình",
                copySuccessful: "Sao chép thành công",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Đại diện bán hàng",
                    customerServicePersonnel: "Nhân viên dịch vụ khách hàng",
                    storeCounter: "Quầy cửa hàng",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "Tạo lời nói toàn văn",
                speaker: "Diễn giả",
                scriptStyle: "Phong cách nói",
                copyConversation: "Sao chép cuộc trò chuyện",
                application: "Ứng dụng",
                regenerate: "Tái tạo",
                startGenerating: "Bắt đầu tạo",
                pleaseGenerateAProcess: "Vui lòng tạo quy trình",
                operationSuccessful: "Hoạt động thành công",
                copySuccessful: "Sao chép thành công",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Tô màu" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Lời nói không thể trống rỗng!",
              copySuccessful: "Sao chép thành công",
              cancel: "Hủy bỏ",
              copy: "Sao chép",
              replaceTheOriginalText: "Thay thế văn bản gốc",
              aiGeneration: "Ai tạo",
              aiPolishing: "Ai đánh bóng",
              currentStyle: "Phong cách hiện tại",
              rigorous: "Nghiêm khắc",
              mild: "Ôn hòa",
              lively: "Sống động",
              simplicity: "Đơn giản",
              pleaseEnterTheSpeakerRole: "Vui lòng nhập vai trò người nói",
              startGenerating: "Bắt đầu tạo",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Lời nói không thể trống rỗng!",
              copySuccessful: "Sao chép thành công",
              aiRewrite: "Ai viết lại",
              cancel: "Hủy bỏ",
              copy: "Sao chép",
              replaceTheOriginalText: "Thay thế văn bản gốc",
              currentStyle: "Phong cách hiện tại",
              rigorous: "Nghiêm khắc",
              mild: "Ôn hòa",
              lively: "Sống động",
              simplicity: "Đơn giản",
              pleaseEnterTheSpeakerRole: "Vui lòng nhập vai trò người nói",
              startGenerating: "Bắt đầu tạo",
            },
          },
          index: {
            verificationPassed: "Đã thông qua xác minh",
            ruleValidation: "Xác minh quy tắc",
            aIGeneration: "Tạo AI",
            generateFullTextSpeechScript: "Tạo lời nói toàn văn",
            editCourse: "Chỉnh sửa khóa học",
            importConversation: "Nhập cuộc trò chuyện",
            uploadExcelToCompleteDialogueImport:
              "Tải lên Excel để hoàn tất nhập cuộc hội thoại",
            editBasicInformation: "Chỉnh sửa thông tin cơ bản",
            test: "Kiểm tra",
            release: "Phát hành",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Nhãn mới" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Không thể khôi phục sau khi xóa, bạn có chắc chắn muốn xóa nhãn này không?",
              delete: "Xóa",
              cancel: "Hủy bỏ",
            },
          },
        },
        index: {
          labelManagement: "Quản lý nhãn",
          newGroup: "Nhóm mới",
          edit: "Chỉnh sửa",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Không thể khôi phục sau khi xóa, bạn có chắc chắn muốn xóa nhóm thẻ này không?",
          delete: "Xóa",
          cancel: "Hủy bỏ",
          editGroup: "Nhóm chỉnh sửa",
          labelGroupName: "Tên nhóm nhãn",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Máy ảnh bị vô hiệu hóa",
              noCameraDevicesFound: "Không tìm thấy thiết bị camera",
            },
          },
          cue: {
            index: {
              regenerate: "Tái tạo",
              generate: "Tạo",
              referenceScript: "Tài liệu tham khảo",
              mattersNeedingAttention: "Những vấn đề cần chú ý",
              examFrequency: "Số lần thi",
              section: "Không.",
              timestotal: "Thời gian/Tổng số",
              second: "Lần",
              requirementsForPassingTheExam: "Yêu cầu vượt qua kỳ thi",
              examScore: "Điểm thi ≥",
              branch: "Điểm",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Không có chân dung",
              customerBackground: "Nền khách hàng",
              customerLabel: "Nhãn khách hàng",
            },
          },
          flow: {
            index: {
              processReminder: "Nhắc nhở quy trình (",
              score: "Điểm số",
              TotalScore: "/Tổng điểm",
            },
          },
          index: {
            taskName: "Tên nhiệm vụ",
            taskStatus: "Tình trạng nhiệm vụ",
            whole: "Tất cả",
            completionStatus: "Trạng thái hoàn thành",
            courseClassification: "Phân loại khóa học",
            practiceMode: "Chế độ thực hành",
            examMode: "Chế độ thi",
            practiceTime: "Thời gian thực hành",
          },
          ppt: {
            index: {
              nextPage: "Tiếp theo",
              tips: "Mẹo",
              continueWithCurrentPage: "Tiếp tục trang hiện tại",
              goToTheNextPage: "Chuyển đến trang tiếp theo",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Sau khi vào trang tiếp theo, bạn sẽ không thể quay lại trang trước, vui lòng xác nhận rằng trang hiện tại đã được giải thích đầy đủ trước khi chuyển sang",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Nhấp vào trang tiếp theo sẽ không bật lên lời nhắc này",
            },
            list: { index: { slide: "Trình chiếu" } },
          },
          record: {
            index: {
              unableToRecord: "Không thể ghi âm:",
              creatingARecorderService: "Tạo dịch vụ Recorder",
              turnOffTheRecorderService: "Tắt dịch vụ Recorder",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Máy khách hiện tại đã kết nối với máy chủ Websocket ASR",
              afterProcessingTheLastFrameRestoreTheState:
                "Sau khi xử lý khung cuối cùng, khôi phục trạng thái",
              aSRConnectionClosed: "Kết nối ASR đã bị tắt...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Bản ghi bị tạm dừng và nó đã được xử lý, nhưng dữ liệu vẫn còn trong bộ nhớ cache",
              foundASRChannelClosedRecreateWebsocketLink:
                "Tìm thấy đóng kênh ASR, tạo lại liên kết Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Nhận thấy rằng kênh ASR không được mở, hãy tạo lại liên kết Websocket",
            },
            ppt: { hook: { index: { serverError: "Lỗi máy chủ" } } },
            text: {
              index: {
                pleaseEnter: "Vui lòng nhập",
                sendOut: "Gửi",
                startPracticing: "Bắt đầu thực hành",
                startTheExam: "Bắt đầu kỳ thi",
              },
            },
            util: {
              recorded: "Đã ghi",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 bit pcm SendFrameSize phải là bội số nguyên của 2",
              pCMSamplingRateShouldNotOccur:
                "Không nên xuất hiện tỷ lệ lấy mẫu pcm",
              andTheRequiredSamplingRate: "Và tỷ lệ lấy mẫu cần thiết",
              atypism: "Không nhất quán",
              errorsThatShouldNotOccur: "Lỗi không nên xuất hiện:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> Dữ liệu không theo thứ tự, nếu yêu cầu không cao, bạn có thể trực tiếp loại bỏ </span>',
              flower: "Hoa",
              TransferStopped: ": Đã ngừng truyền",
            },
            video: { index: { camera: "Máy ảnh" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Máy khách hiện tại đã kết nối với máy chủ Websocket ASR",
                    aSRConnectionClosed: "Kết nối ASR đã bị tắt...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Tìm thấy đóng kênh ASR, tạo lại liên kết Websocket",
                    unableToRecord: "Không thể ghi âm:",
                    aSRChannelClosedReopened: "Đóng kênh ASR, mở lại",
                    theASRChannelWasNotCreatedStartingNow:
                      "Kênh ASR chưa được tạo, bây giờ hãy bắt đầu tạo",
                    useEffectCallbackToClearTheASRChannel:
                      "Cuộc gọi lại uselang Effect, để xóa kênh ASR",
                    creatingARecorderService: "Tạo dịch vụ Recorder",
                    turnOffTheRecorderService: "Tắt dịch vụ Recorder",
                  },
                  util: {
                    recorded: "Đã ghi",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 bit pcm SendFrameSize phải là bội số nguyên của 2",
                    pCMSamplingRateShouldNotOccur:
                      "Không nên xuất hiện tỷ lệ lấy mẫu pcm",
                    andTheRequiredSamplingRate: "Và tỷ lệ lấy mẫu cần thiết",
                    atypism: "Không nhất quán",
                    errorsThatShouldNotOccur: "Lỗi không nên xuất hiện:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> Dữ liệu không theo thứ tự, nếu yêu cầu không cao, bạn có thể trực tiếp loại bỏ </span>',
                    flower: "Hoa",
                    TransferStopped: ": Đã ngừng truyền",
                  },
                },
                index: { failInSend: "Gửi thất bại" },
              },
              index: {
                startRecording: "Bắt đầu ghi âm",
                endRecording: "Kết thúc ghi âm",
                startPracticing: "Bắt đầu thực hành",
                startTheExam: "Bắt đầu kỳ thi",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Số kỳ thi hiện tại còn lại là",
              totalScriptScore: "Tổng điểm kỹ năng nói:",
              totalEmotionalScore: "Tổng điểm cảm xúc:",
              totalScoreOfExpression: "Thể hiện tổng điểm:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Kết quả chấm điểm nhiệm vụ hiện tại cần được xem xét thủ công, kết quả cuối cùng sẽ được thông báo sau khi xem xét xong, hãy kiên nhẫn chờ đợi",
              areYouSureToSubmit: "Bạn có xác nhận gửi không?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Bài tập đã kết thúc, bạn có xác nhận gửi không?",
              theExamHasEndedAreYouSureToSubmit:
                "Kỳ thi đã kết thúc, bạn có xác nhận nộp hồ sơ không?",
              completed: "Đã hoàn thành",
              page: "Trang",
              pageCount: "Tổng số trang",
              topic: "Câu hỏi",
              totalNumberOfQuestions: "Tổng số câu hỏi",
              totalScore: "Tổng điểm",
              branch: "Điểm",
              accumulatedCompletionProgress: "Tiến độ hoàn thành tích lũy",
              accumulatedPracticeDuration: "Thời gian thực hành tích lũy",
              lastPauseExerciseResults: "Tạm dừng cuối cùng-Kết quả thực hành",
              exerciseInstructions: "Hướng dẫn thực hành",
              examInstructions: "Mô tả kỳ thi",
              halfwayPausePracticeResults:
                "Tạm dừng giữa chừng-Kết quả thực hành",
              tips: "Mẹo",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Bài tập này không được tính vào kết quả sau khi rút tiền. Bạn có xác nhận rút tiền không?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Không được phép rút giữa kỳ thi. Bạn có xác nhận nộp hồ sơ không?",
              pleaseOperateWithCaution:
                "Thời gian, vui lòng hoạt động thận trọng)",
              restart: "Bắt đầu lại",
              returnToLearning: "Trở lại học tập",
              continuePracticing: "Tiếp tục luyện tập",
              continuingTheExam: "Tiếp tục thi",
              return: "Trở về",
              notSubmittedTemporarily: "Tạm thời không gửi",
              iGotIt: "Tôi biết rồi mà.",
              confirmExit: "Xác nhận thoát",
              confirmSubmission: "Xác nhận gửi",
              signOut: "Thoát",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Tạm dừng",
              submit: "Gửi",
              remainingTimeOfThisQuestion: "Thời gian còn lại cho câu hỏi này",
              SecondsLeftUntilTheEndTime: "Còn 10 giây nữa là kết thúc",
              remainingTime: "Thời gian còn lại",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Nội dung giọng nói của người dùng không được phát hiện, vui lòng thử lại",
          },
          wave: {
            index: {
              continueRecording: "Tiếp tục ghi âm",
              submit: "Gửi",
              endRecording: "Kết thúc ghi âm",
              suspendRecording: "Tạm dừng ghi âm",
              startPracticing: "Bắt đầu thực hành",
              startTheExam: "Bắt đầu kỳ thi",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Chế độ đối thoại",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Tiếng ồn quá lớn, vui lòng chuyển sang môi trường yên tĩnh",
              normalNoiseDetection: "Phát hiện tiếng ồn bình thường",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Âm lượng quá nhỏ, vui lòng tăng âm lượng",
              volumeDetectionIsNormal: "Phát hiện âm lượng bình thường",
              noiseDetection: "Phát hiện tiếng ồn",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Xin vui lòng giữ im lặng và ghi lại âm thanh môi trường 3s",
              endRecording: "Kết thúc ghi âm",
              startRecording: "Bắt đầu ghi âm",
              volumeDetection: "Phát hiện âm lượng",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Vui lòng đọc to "Kiểm tra âm lượng ngay bây giờ" với âm lượng bình thường',
              playLastDetectedAudio: "Phát âm thanh phát hiện cuối cùng",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Vui lòng đọc kỹ các yêu cầu trước khi bắt đầu",
              return: "Trở về",
              iGotIt: "Tôi biết rồi mà.",
              timeLimitForSingleQuestionAnswering:
                "Giới hạn thời gian trả lời một câu hỏi",
              totalTimeLimit: "Tổng thời gian",
              unlimitedTime: "Không giới hạn thời gian",
              stillNeedToCompleteTheTaskInTotal: "Tổng nhiệm vụ cần hoàn thành",
              practice: "Bài tập",
              second: "Lần",
              taskScore: "Điểm nhiệm vụ một lần ≥",
              branch: "Điểm",
              or: "Hoặc",
              examScore: "Điểm thi ≥",
              remainingExamTimes: "Số kỳ thi còn lại",
              totalScore: "Tổng điểm",
              Script: "(Lời nói",
              scoreEmotion: "Phân chia cảm xúc",
              fractionExpression: "Biểu thức phụ",
              points: "Điểm)",
              standardScore: "Điểm đạt tiêu chuẩn",
              excellentScore: "Điểm xuất sắc",
              exerciseInstructions: "Hướng dẫn thực hành",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Để trả lời câu hỏi suôn sẻ, vui lòng hoàn thành việc kiểm tra thiết bị trước:",
              camera: "Máy ảnh",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Có thể nhìn thấy chính mình trong video, có nghĩa là máy ảnh vẫn bình thường",
              microphone: "Micro",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Vui lòng nói chuyện với micrô, xem mẫu sóng có nghĩa là micrô bình thường",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Rỗng",
                  copyTo: "Sao chép vào",
                  classification: "Phân loại",
                  rename: "Đổi tên",
                  newlyBuild: "Mới xây dựng",
                  categoryParent: "Phân loại cấp trên",
                  moveTo: "Di chuyển đến",
                  name: "Tên",
                },
              },
              util: {
                rename: "Đổi tên",
                moveTo: "Di chuyển đến",
                copyTo: "Sao chép vào",
                delete: "Xóa",
                courseClassification: "Phân loại khóa học",
                newlyBuild: "Mới xây dựng",
              },
            },
          },
          edit: { index: { curriculum: "Khóa học" } },
          index: {
            learn: "Học",
            practice: "Luyện tập",
            examination: "Kiểm tra",
            curriculum: "Khóa học",
            courseIntroduction: "Giới thiệu khóa học:",
            createdOn: "Tạo thời gian:",
            published: "Đã phát hành",
            tasks: "Nhiệm vụ",
            unpublishedTasks: "Nhiệm vụ chưa được phát hành",
            task: "Nhiệm vụ",
            operation: "Hoạt động",
            learningMaterials: "Tài liệu học tập",
            goPractice: "Đi thực hành",
            publishTask: "Xuất bản nhiệm vụ",
            taskData: "Dữ liệu nhiệm vụ",
            edit: "Chỉnh sửa",
            copy: "Sao chép",
            successfullyCopied: "Sao chép thành công",
            delete: "Xóa",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Sau khi xóa, tất cả dữ liệu nhiệm vụ trong khóa học này sẽ bị trống. Việc xóa khóa học có được xác nhận không?",
            courseName: "Tên khóa học",
            creationTime: "Tạo thời gian",
            releaseStatus: "Trạng thái đăng",
            whole: "Tất cả",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Không phát hiện âm thanh",
              voice: "Giọng nói",
              text: "Văn bản",
              referenceScript: "Kỹ năng tham khảo:",
              clickOnTheRightSideToStartRecording:
                "Nhấp vào bên phải để bắt đầu ghi âm",
              sendOut: "Gửi",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Máy khách hiện tại đã kết nối với máy chủ Websocket ASR",
                afterProcessingTheLastFrameRestoreTheState:
                  "Sau khi xử lý khung cuối cùng, khôi phục trạng thái",
                aSRConnectionClosed: "Kết nối ASR đã bị tắt...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Bản ghi bị tạm dừng và nó đã được xử lý, nhưng dữ liệu vẫn còn trong bộ nhớ cache",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Tìm thấy đóng kênh ASR, tạo lại liên kết Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Nhận thấy rằng kênh ASR không được mở, hãy tạo lại liên kết Websocket",
                unableToRecord: "Không thể ghi âm:",
                creatingARecorderService: "Tạo dịch vụ Recorder",
                turnOffTheRecorderService: "Tắt dịch vụ Recorder",
              },
              util: {
                recorded: "Đã ghi",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 bit pcm SendFrameSize phải là bội số nguyên của 2",
                pCMSamplingRateShouldNotOccur:
                  "Không nên xuất hiện tỷ lệ lấy mẫu pcm",
                andTheRequiredSamplingRate: "Và tỷ lệ lấy mẫu cần thiết",
                atypism: "Không nhất quán",
                errorsThatShouldNotOccur: "Lỗi không nên xuất hiện:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> Dữ liệu không theo thứ tự, nếu yêu cầu không cao, bạn có thể trực tiếp loại bỏ </span>',
                flower: "Hoa",
                TransferStopped: ": Đã ngừng truyền",
              },
            },
            finishTest: {
              index: {
                endTest: "Kết thúc bài kiểm tra",
                testResult: "Kết quả kiểm tra",
                returnToCanvas: "Quay lại canvas",
                retest: "Kiểm tra lại",
              },
            },
          },
          index: {
            testFromCurrentNode: "Kiểm tra từ nút hiện tại",
            listening: "Đài...",
            canvasTesting: "Kiểm tra vải",
            endOfProcess: "Kết thúc quá trình",
            restart: "Bắt đầu lại",
            start: "Bắt đầu",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Phân tích thu gọn",
                  expandParsing: "Mở rộng phân tích",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: ", Quá nhiều giọng nói",
                  second: "Lần",
                  ratingOverview: "Tổng quan về xếp hạng",
                  totalScore: "Tổng điểm",
                  section: "Không.",
                  name: "Tên",
                  of: "(Tổng cộng",
                  people: "Người)",
                  scriptScore: "Điểm kỹ năng nói",
                  common: "Tổng cộng",
                  keyPoints: "Điểm chính,",
                  notReached: "Chưa đạt được,",
                  have: "Có",
                  violationOfRegulations: "Vi phạm",
                  emotionalScore: "Điểm số cảm xúc",
                  appear: "Xuất hiện",
                  secondaryNegativeEmotions: "Cảm xúc tiêu cực",
                  expressionScore: "Điểm biểu hiện",
                  speakTooFast: "Nói quá nhanh",
                  times: "Thời gian,",
                  speakTooSlowly: "Nói chậm",
                  average: "Trung bình",
                  wordminute: "Lời/phút",
                },
              },
              score: {
                index: {
                  studentNodeName: "Tên nút sinh viên",
                  keyPointName: "Tên điểm",
                  slideNodeName: "Tên nút trượt",
                  dialogue: "Đối thoại",
                  mainPoints: "Điểm chính",
                  slide: "Trình chiếu",
                  detectionResult: "Kết quả kiểm tra",
                  satisfy: "Thỏa mãn",
                  dissatisfaction: "Không hài lòng",
                  score: "Điểm số",
                  script: "Lời nói",
                  semantics: "Ngữ nghĩa",
                  scriptViolation: "Vi phạm lời nói",
                  emotion: "Cảm xúc",
                  expression: "Biểu hiện",
                },
              },
              taskTitle: { index: { branch: "Điểm" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Lần này",
                  lastTime: "Lần trước",
                  comprehensiveEvaluation: "Đánh giá toàn diện",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Tích lũy từ ngữ chuẩn',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Một từ, tổng số từ mà học sinh theo dõi",
                  aWordspan: "Từ </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Thời gian </span><br /><span style = "margin-left: 8px">· Nói chậm',
                  timesspan: "Thời gian </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Quá nhiều từ',
                  complete: "Nội dung đầy đủ",
                  keyHit: "Điểm chính",
                  languageNorm: "Đặc tả ngôn ngữ",
                  fluentExpression: "Biểu hiện trôi chảy",
                  emotionallyPositive: "Cảm xúc tích cực",
                  radarChart: "Bản đồ radar",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· Số quá trình hoàn chỉnh',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· Điểm đánh',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· Có',
                  dealingWithIllegalWordsspan: "Từ vi phạm </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· Nói quá nhanh',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'Lần </span><br /><span style = "margin-left: 8px">· Quá nhiều từ',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· Cảm xúc tiêu cực',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Ghi điểm",
                  firstRating: "Điểm đầu tiên",
                  totalScore: "Tổng điểm",
                  changeTo: "Thay đổi thành",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Giai đoạn giao tiếp",
                  communicationObjectives: "Mục tiêu truyền thông",
                  scoringCriteria: "Tiêu chí chấm điểm",
                  suggestionsForImprovement: "Khuyến nghị nâng",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Số lần còn lại:",
                  second: "Lần",
                  doItAgain: "Một lần nữa",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Trình duyệt không hỗ trợ phát lại âm thanh.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "Nhận xét và đề xuất",
                  regenerate: "Tái tạo",
                  generate: "Tạo",
                  generationFailed: "Tạo thất bại",
                },
              },
              resultModal: {
                index: { return: "Trở về", viewDetails: "Xem chi tiết" },
              },
            },
            index: {
              reRatingSuccessful: "Đánh giá lại thành công",
              reRatingFailed: "Đánh giá lại thất bại",
              downloadRecording: "Tải xuống bản ghi âm",
              downloadText: "Tải văn bản",
              download: "Tải về",
              reRating: "Đánh giá lại",
              submitARatingAppeal: "Gửi khiếu nại chấm điểm",
              reviewSubmission: "Xem xét và nộp",
              saveChanges: "Lưu sửa đổi",
              previousSentence: "Câu trước",
              nextSentence: "Câu tiếp theo",
              score: "Chấm điểm",
              scoringSituation: "Tình huống ghi bàn",
              dialogueAnalysis: "Phân tích đối thoại",
            },
          },
          index: {
            ratingAppealInProgress: "Đánh giá khiếu nại",
            operation: "Hoạt động",
            wrongQuestionSet: "Bộ câu hỏi sai",
            ratingUpdate: "Cập nhật xếp hạng",
            viewDetails: "Xem chi tiết",
            curriculum: "Khóa học",
            taskName: "Tên nhiệm vụ",
            taskType: "Loại nhiệm vụ",
            score: "Điểm số",
            scoringResults: "Kết quả ghi điểm",
            time: "Thời gian",
            courseClassification: "Phân loại khóa học",
            whole: "Tất cả",
            learningTime: "Thời gian học",
            historicalLearningData: "Dữ liệu học tập lịch sử",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Thực hành tạm dừng",
              lastProgress: "Tiến độ cuối cùng",
              continuePracticing: "Tiếp tục luyện tập",
              practice: "Bài tập",
              startPracticing: "Bắt đầu thực hành",
              historicalData: "Dữ liệu lịch sử",
              courseIntroduction: "Giới thiệu khóa học:",
              exerciseDifficulty: "Độ khó thực hành:",
              practiceCompletionRequirements: "Yêu cầu hoàn thành bài tập:",
              practiceFrequency: "Số lần thực hành ≥",
              second: "Lần",
              anyScore: "Điểm bất kỳ ≥",
              branch: "Điểm",
              or: "Hoặc",
              taskList: "Danh sách nhiệm vụ",
              practiceTime: "Thời gian thực hành:",
              to: "Đến",
              unlimitedTime: "Không giới hạn thời gian",
              completionStatus: "Trạng thái hoàn thành",
              numberOfExercises: "Số lượng bài tập",
              maximumScore: "Điểm cao nhất",
              operation: "Hoạt động",
              enteringLearning: "Nhập học",
              historicalLearningData: "Dữ liệu học tập lịch sử",
              wrongQuestionSet: "Bộ câu hỏi sai",
            },
          },
          index: {
            practiceTasks: "Nhiệm vụ thực hành",
            examTasks: "Nhiệm vụ thi",
            task: "Nhiệm vụ",
          },
          learn: {
            index: {
              courseName: "Tên khóa học",
              courseIntroduction: "Giới thiệu khóa học",
              learningContent: "Nội dung học tập",
              downloadLearningMaterials: "Tải xuống tài liệu học tập",
              startPracticing: "Bắt đầu thực hành",
            },
          },
          test: {
            index: {
              taskList: "Danh sách nhiệm vụ",
              courseIntroduction: "Giới thiệu khóa học:",
              examDifficulty: "Độ khó của kỳ thi:",
              requirementsForPassingTheExam: "Yêu cầu vượt qua kỳ thi:",
              examScore: "Điểm thi ≥",
              branch: "Điểm",
              examTime: "Thời gian thi:",
              to: "Đến",
              unlimitedTime: "Không giới hạn thời gian",
              completionStatus: "Trạng thái hoàn thành",
              completionTimes: "Số lần hoàn thành",
              maximumScore: "Điểm cao nhất",
              operation: "Hoạt động",
              immediateExam: "Kiểm tra ngay lập tức",
              history: "Lịch sử",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Tài liệu tham khảo" } },
              flow: {
                index: {
                  wrongQuestionItem: "Các mục câu hỏi sai (",
                  score: "Điểm số",
                  TotalScore: "/Tổng điểm",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Lỗi máy chủ" } },
                  index: {
                    startRecording: "Bắt đầu ghi âm",
                    endRecording: "Kết thúc ghi âm",
                    startPracticing: "Bắt đầu thực hành",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Vui lòng nhập",
                    sendOut: "Gửi",
                    startPracticing: "Bắt đầu thực hành",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Bắt đầu ghi âm",
                    endRecording: "Kết thúc ghi âm",
                    startPracticing: "Bắt đầu thực hành",
                  },
                  hook: { index: { failInSend: "Gửi thất bại" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Điểm thực hành câu hỏi sai này",
                  tips: "Mẹo",
                  areYouSureToEnd: "Có xác nhận kết thúc không?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Thực hành lại mà không nắm vững các câu hỏi sai",
                  continue: "Tiếp tục",
                  returnToTheSetOfIncorrectQuestions: "Quay lại bộ câu hỏi sai",
                  end: "Kết thúc",
                  wrongQuestionItem: "Câu hỏi sai",
                  numberOfExercisesThisTime: "Số lần luyện tập này",
                  masteringTheSituation: "Làm chủ tình hình",
                  mastered: "Đã làm chủ",
                  notMastered: "Không làm chủ",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Thực hành lại câu hỏi này",
                  nextQuestion: "Câu hỏi tiếp theo",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Bài tập câu hỏi sai",
                endExercise: "Kết thúc bài tập",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Bài tập câu hỏi sai",
                endExercise: "Kết thúc bài tập",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Bài tập câu hỏi sai",
                endExercise: "Kết thúc bài tập",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Xem kỹ năng nói",
                  section: "Không.",
                  secondaryDiscourseTechnique: "Kỹ thuật nói phụ",
                },
              },
              tableItem: {
                count: { second: "Lần" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Không tìm thấy thông tin liên quan",
                  recallWords: "Từ thu hồi",
                  branch: "Điểm",
                  answerIncorrectly: "Trả lời sai",
                  second: "Lần",
                  wrongAnswerRate: "Tỷ lệ trả lời sai",
                },
                standardScript: {
                  standardScript: "Kỹ năng nói tiêu chuẩn",
                  myScript: "Lời nói của tôi",
                },
                status: {
                  mastered: "Đã làm chủ",
                  notMastered: "Không làm chủ",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "Tổng số câu trả lời",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Câu hỏi sai đã thực hành số lần",
                },
              },
            },
            index: {
              wrongQuestionList: "Danh sách câu hỏi sai",
              wrongQuestionSet: "Bộ câu hỏi sai",
              mispracticeExercises: "Bài tập câu hỏi sai",
              taskName: "Tên nhiệm vụ",
              staff: "Nhân viên",
              masteringTheSituation: "Làm chủ tình hình",
              whole: "Tất cả",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Số lần thực hành ≥",
              second: "Lần",
              or: "Hoặc",
              excellentScoreAtAnyOneTime: "Điểm xuất sắc bất kỳ (≥",
              points: "Điểm)",
              examScore: "Điểm thi ≥",
              branch: "Điểm",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Phân bổ trung bình ngẫu nhiên",
              assignToDesignatedPersonnel: "Giao cho người được chỉ định",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Tăng số lượng bài kiểm tra thành công",
              failedToIncreaseTheNumberOfExams: "Tăng số lần thi trượt",
              increaseTheNumberOfExams: "Tăng số lượng bài kiểm tra",
              selectObjects: "Chọn đối tượng",
            },
          },
          executionFilter: { index: { participationRate: "Tỷ lệ tham gia" } },
          intelligentReview: {
            index: {
              overallReview: "Đánh giá tổng thể",
              pleaseEnterTheReviewCriterianotMandatory:
                "Vui lòng nhập tiêu chuẩn đánh giá (không bắt buộc)",
              commentaryOnSingleSentenceRhetoric: "Nhận xét về kỹ năng một câu",
              realTimeReview: "Đánh giá thời gian thực",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Điểm thi trung bình",
            averageExamDurationmin: "Thời lượng thi trung bình (min)",
            accumulatedPracticeDurationmin:
              "Thời lượng thực hành tích lũy (min)",
            accumulatedPracticeTimes: "Số lượng bài tập tích lũy",
            totalScore: "Tổng điểm",
            average: "Điểm trung bình",
            total: "Tổng số",
            keyHitCount: "Số lần truy cập chính",
            practiceAverageScore: "Điểm trung bình thực hành",
            averagePracticeDurationmin: "Thời lượng thực hành trung bình (min)",
            topOfTheHighestScore: "Điểm cao nhất Top3",
            branch: "Điểm",
            errorProneItemTop: "Các mục dễ mắc lỗi Top5",
            hitRate: "Tỷ lệ trúng",
            hitRateOfKeyPointsInScript: "Tỷ lệ trúng",
            averageScriptScore: "Điểm trung bình từ",
            sortByProcess: "Sắp xếp theo quy trình",
            sortByScore: "Sắp xếp theo điểm",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Điểm trung bình của trang này/tổng điểm của trang này",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Điểm trung bình của mục này/tổng điểm của mục này",
            details: "Chi tiết",
            viewRules: "Xem quy tắc",
            standardScript: "Kỹ năng nói tiêu chuẩn",
          },
          scoreDetailList: {
            section: "Không.",
            second: "Lần",
            practice: "Bài tập",
            examination: "Thi",
            notMeetingStandards: "Không đạt tiêu chuẩn",
            meetingStandards: "Đạt tiêu chuẩn",
            excellent: "Xuất sắc",
            frequency: "Số lần",
            fraction: "Phân số",
            result: "Kết quả",
            operation: "Hoạt động",
            viewDetails: "Xem chi tiết",
          },
          index: {
            common: "Tổng cộng",
            people: "Người",
            staff: "Nhân viên",
            notMeetingStandards: "Không đạt tiêu chuẩn",
            meetingStandards: "Đạt tiêu chuẩn",
            excellent: "Xuất sắc",
            notStarted: "Chưa bắt đầu",
            haveInHand: "Đang tiến hành",
            completed: "Đã hoàn thành",
            passed: "Đã qua",
            hangInTheAir: "Chưa hoàn thành",
            failed: "Không đạt",
            unlimitedTime: "Không giới hạn thời gian",
            to: "Đến",
            maximumScoringCriteria: "Tiêu chuẩn điểm tối đa",
            completionStatus: "Trạng thái hoàn thành",
            employeeName: "Tên nhân viên",
            frequency: "Số lần",
            practice: "Bài tập",
            examination: "Thi",
            average: "Điểm trung bình",
            speechCraftAvgScore: "Điểm trung bình từ",
            emotionAvgScore: "Điểm trung bình cảm xúc",
            speedAvgScore: "Biểu hiện điểm trung bình",
            flowAvgScore: "Điểm trung bình của quá trình",
            practiceCostTime: "Thời lượng tích lũy",
            participateCommitRate: "Tỷ lệ tuân thủ",
            participateExcellentRate: "Tỷ lệ vượt trội",
            duration: "Thời lượng",
            score: "Điểm số",
            scoringResults: "Kết quả ghi điểm",
            flowScore: "Điểm quy trình",
            craftScore: "Điểm kỹ năng nói",
            emotionScore: "Điểm số cảm xúc",
            speedScore: "Điểm biểu hiện",
            contentIntegrity: "Nội dung đầy đủ",
            pointHitRate: "Tỷ lệ trúng đích chính",
            emotionNegativeRate: "Tỷ lệ cảm xúc tiêu cực",
            paraPhaSiaRate: "Tỷ lệ tốc độ nói bất thường",
            toneOvermuchRate: "Quá nhiều giọng nói chiếm tỷ lệ",
            maximumScore: "Điểm cao nhất",
            highestScoreResult: "Kết quả điểm số cao nhất",
            state: "Trạng thái",
            averageDurationmin: "Thời gian trung bình (min)",
            lately: "Gần đây",
            time: "Thời gian",
            operation: "Hoạt động",
            viewDetails: "Xem chi tiết",
            wrongQuestionSet: "Bộ câu hỏi sai",
            taskName: "Tên nhiệm vụ",
            completeRequirements: "Hoàn thành yêu cầu",
            publishTo: "Đăng đối tượng",
            taskTime: "Thời gian nhiệm vụ",
            fractionalComposition: "Thành phần phân số",
            totalScore: "Tổng điểm",
            branch: "Điểm",
            Script: "(Lời nói",
            scoreEmotion: "Phân chia cảm xúc",
            fractionExpression: "Biểu thức phụ",
            points: "Điểm)",
            implementationRate: "Tỷ lệ tham gia",
            numberOfExecutors: "Số lượng người tham gia",
            numberOfPeopleNotExecuted: "Số người không tham gia",
            completionRate: "Tỷ lệ hoàn thành",
            numberOfCompletedPersonnel: "Số lượng người đã hoàn thành",
            numberOfUnfinishedPersonnel: "Số người chưa hoàn thành",
            complianceRate: "Tỷ lệ tuân thủ",
            numberOfQualifiedPersonnel: "Số người đạt tiêu chuẩn",
            numberOfNonCompliantIndividuals: "Số người không đạt tiêu chuẩn",
            excellentRate: "Tỷ lệ vượt trội",
            participateCompleteRate: "Tỷ lệ hoàn thành tham gia",
            numberOfOutstandingIndividuals: "Số lượng xuất sắc",
            numberOfNonOutstandingIndividuals: "Số lượng không xuất sắc",
            export: "Xuất",
            taskData: "Dữ liệu nhiệm vụ",
            resultAnalysis: "Phân tích kết quả",
            scoreDetails: "Chi tiết điểm số-",
            dataOverview: "Tổng quan về dữ liệu",
            taskDetail: "Chi tiết nhiệm vụ",
            scriptAnalysis: "Phân tích kỹ năng nói",
            flowAnalysis: "Phân tích quy trình",
            expressionAnalysis: "Phân tích biểu hiện",
            emotionAnalysis: "Phân tích cảm xúc",
            byTime: "Chế độ xem chiều thời gian",
            byNumber: "Lượt xem theo chiều số",
            personData: "Thống kê theo nhân sự",
            numberData: "Ghi lại chi tiết theo thời gian",
            count: "Theo số lần",
            person: "Theo số lượng người",
            reachTimes: "Số lần chạm",
            outOfReachTimes: "Số lần không đạt",
            seeOutOfReach: "Nhấp để xem",
            accumulative: "Tích lũy",
            script: "Lời nói",
            times: "Lần",
            sentence: "Câu",
            expressionAverage: "Biểu hiện điểm trung bình",
            modalAnalysis: "Phân tích giọng nói",
            total: "Tổng cộng",
            muchModalAnalysis: "Có quá nhiều từ ngữ",
            normal: "Bình thường",
            speedAnalysis: "Phân tích tốc độ nói",
            speedDistribution: "Phân phối tốc độ nói",
            speakingSpeed: "Tốc độ nói",
            normalSpeak: "Tốc độ nói bình thường",
            tooFastSpeak: "Tốc độ nói quá nhanh",
            tooSlowlySpeak: "Tốc độ nói quá chậm",
            violationWord: "Từ vi phạm",
            violationWordAnalysis: "Phân tích từ vi phạm",
            noData: "Không",
            emotionalScore: "Điểm số cảm xúc",
            emotionalDistribution: "Phân phối cảm xúc",
            negativeEmotion: "Cảm xúc tiêu cực",
            normalEmotion: "Tâm trạng bình thường",
            getDetail: "Xem chi tiết",
            barPattern: "Chế độ xem biểu đồ thanh",
            timeDimension: "Chế độ xem vĩ độ thời gian",
            timesDimension: "Lượt xem theo chiều số",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Điểm trung bình",
                  averageScoreOfScript: "Điểm trung bình",
                  averageEmotionalScore: "Điểm trung bình cảm xúc",
                  expressionAverageScore: "Biểu hiện điểm trung bình",
                  processAverageScore: "Điểm trung bình quá trình",
                  accumulatedDuration: "Thời gian tích lũy",
                  accumulatedTimes: "Số lần tích lũy",
                  participationComplianceRate: "Tỷ lệ tuân thủ tham gia",
                  participationRateForAchievingExcellence:
                    "Tham gia đạt tỷ lệ xuất sắc",
                  branch: "Điểm",
                  numberOfPeople: "Số người",
                  averageScoreOfExpression: "Biểu hiện điểm trung bình",
                  people: "Người",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Cảm xúc tiêu cực" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Điểm trung bình từ",
                  branch: "Điểm",
                },
                useSummaryDetail: { keyHitRate: "Tỷ lệ trúng đích chính" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Đánh giá thông minh",
            pleaseImproveTheConfiguration: "Vui lòng cải thiện cấu hình",
            examResults: "Kết quả thi",
            showTheResultsAfterEachExam: "Hiển thị kết quả sau mỗi kỳ thi",
            showTheResultsAfterTheLastExam:
              "Kết quả hiển thị sau kỳ thi cuối cùng",
            doNotDisplayResults: "Không hiển thị kết quả",
            resultDisplay: "Kết quả hiển thị",
            score: "Điểm số",
            scoringResults: "Kết quả ghi điểm",
            allowViewingScoreDetails: "Cho phép xem chi tiết điểm số",
            viewCanvasConfiguration: "Xem cấu hình canvas",
            synchronizationFailedProcessNodeHasChanged:
              "Đồng bộ hóa không thành công, nút quy trình đã thay đổi",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Đồng bộ hóa thành công, vui lòng nhấp vào Đăng cập nhật khóa học",
            retrievedTheLatestCanvasConfiguration:
              "Cấu hình canvas mới nhất đã được rút ra",
            synchronizeCanvasConfiguration: "Cấu hình canvas đồng bộ",
            scoringMethod: "Phương pháp tính điểm",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Điểm, quá trình {0} điểm, kỹ năng nói {1} điểm, cảm xúc {2} điểm, tốc độ nói {3} điểm)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Tiêu chuẩn xuất sắc phải cao hơn tiêu chuẩn",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Tiêu chuẩn chấm điểm cần đáp ứng lớn hơn 0%, nhỏ hơn bằng 100%",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Tiêu chuẩn chấm điểm cần đáp ứng nhiều hơn 0 điểm, nhỏ hơn hoặc bằng {0} điểm",
            doRatingsRequireManualReview:
              "Điểm có cần được xem xét thủ công không",
            allocationStrategy: "Chiến lược phân phối",
            pleaseSelectTheAssignedPersonnel: "Vui lòng chọn phân công nhân sự",
            pleaseSelectADesignatedPerson: "Vui lòng chọn người được chỉ định",
            timeLimitForSingleQuestionAnswering:
              "Giới hạn thời gian trả lời một câu hỏi",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Đăng cho nhân viên mới được thêm vào theo mặc định",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Việc chấm điểm lại có nghĩa là nó sẽ được khớp theo quy tắc tính điểm của canvas mới nhất. Nhiệm vụ hiện tại có dữ liệu chấm điểm đã được xem xét thủ công và có ghi đè điểm hay không",
            notCovered: "Không che",
            cover: "Phủ sóng",
            participationSituation: "Tình hình tham gia",
            numberOfParticipants: "Số lượng người tham gia",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Số lượng đối tượng thực hành được chọn cho nhiệm vụ này là tổng",
            sumOfTheNumberOfParticipantsInTheTask:
              "Số người đã tham gia nhiệm vụ",
            numberOfCompletedPersonnel: "Số lượng người đã hoàn thành",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Số lượng người đáp ứng yêu cầu hoàn thành nhiệm vụ",
            numberOfQualifiedPersonnel: "Số người đạt tiêu chuẩn",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Tổng số người đáp ứng điểm số nhiệm vụ",
            excellentPersonnel: "Số lượng xuất sắc",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Đáp ứng số lượng tuyệt vời của điểm số nhiệm vụ và tổng",
            participationRate: "Tỷ lệ tham gia",
            numberOfParticipantsNumberOfParticipants:
              "Số người tham gia/số người tham gia",
            numberOfCompletedNumberOfParticipants:
              "Số người hoàn thành/số người nên tham gia",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Số người đạt tiêu chuẩn/số người tham gia",
            excellentNumbernumberOfParticipants:
              "Số người xuất sắc/số người tham gia",
            participationCompletionRate: "Tỷ lệ hoàn thành tham gia",
            numberOfCompletedParticipated:
              "Số người hoàn thành/số người tham gia",
            participationComplianceRate: "Tỷ lệ tuân thủ tham gia",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Số người đạt tiêu chuẩn/số người tham gia",
            participationRateForAchievingExcellence:
              "Tham gia đạt tỷ lệ xuất sắc",
            accumulatedDuration: "Thời gian tích lũy",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Học sinh tham gia nhiệm vụ",
            averageDuration: "Thời lượng trung bình",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Tổng thời gian học viên tham gia nhiệm vụ/tổng số lần tham gia",
            accumulatedTimes: "Số lần tích lũy",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Tổng số lần học sinh tham gia nhiệm vụ",
            averageScore: "Điểm trung bình",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Điểm trung bình của những người tham gia nhiệm vụ",
            maximumScore: "Điểm cao nhất",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Điểm số cao nhất cho những người tham gia nhiệm vụ",
            averageScoreOfScript: "Điểm trung bình",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Tổng điểm lời nói của học sinh/tổng số người tham gia",
            totalScoreOfScript: "Tổng điểm kỹ năng nói",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Đặt tổng điểm của kỹ thuật từ trong nhiệm vụ này",
            processAverageScore: "Điểm trung bình quá trình",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Tổng điểm quy trình học sinh/tổng số lần tham gia",
            totalProcessScore: "Tổng điểm quá trình",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Tổng điểm quy trình được đặt trong nhiệm vụ này",
            averageEmotionalScore: "Điểm trung bình cảm xúc",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Tổng điểm cảm xúc của học sinh/tổng số lần tham gia",
            totalEmotionalScore: "Tổng điểm cảm xúc",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Tổng điểm cảm xúc được thiết lập theo nhiệm vụ",
            averageScoreOfExpression: "Biểu hiện điểm trung bình",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Tổng điểm thể hiện của học sinh/tổng số lần tham gia",
            totalScoreOfExpression: "Thể hiện tổng điểm",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Tổng điểm biểu thức được đặt trong nhiệm vụ này",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Bắt đầu thực hiện chấm điểm lại, bạn có thể chuyển đến trung tâm tác vụ để xem tiến trình",
            reRating: "Đánh giá lại",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Đánh giá lại có nghĩa là nó sẽ khớp theo quy tắc tính điểm của canvas mới nhất và sẽ ghi đè kết quả tính điểm hiện tại",
            releaseTime: "Thời gian phát hành",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Đã xem xét",
              pendingReview: "Được xem xét",
              enterKeywordSearch: "Nhập từ khóa tìm kiếm",
            },
            pageRightTable: {
              index: {
                employeeName: "Tên nhân viên",
                systemRating: "Điểm hệ thống",
                scoringResults: "Kết quả ghi điểm",
                duration: "Thời lượng",
                time: "Thời gian",
                state: "Trạng thái",
                whetherToModifyTheRating: "Có sửa điểm không",
                finalScore: "Điểm cuối cùng",
                reviewedBy: "Người đánh giá",
                operation: "Hoạt động",
                viewScoreDetails: "Xem chi tiết điểm số",
                viewStatus: "Xem trạng thái",
                whole: "Tất cả",
                correctionStatus: "Sửa trạng thái",
                completeReview: "Hoàn thành kiểm toán",
                tips: "Mẹo",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Điểm cuối cùng của học sinh dựa trên kết quả đánh giá cuối cùng hiện tại, vui lòng xác nhận",
              },
            },
          },
          index: { manualReview: "Kiểm toán thủ công" },
        },
        review: {
          index: {
            employeeName: "Tên nhân viên",
            score: "Điểm số",
            scoringResults: "Kết quả ghi điểm",
            duration: "Thời lượng",
            taskName: "Tên nhiệm vụ",
            numberOfAppealScripts: "Số lượng khiếu nại",
            appealTime: "Thời gian kháng cáo",
            processStatus: "Trạng thái quá trình",
            scoreAfterReview: "Điểm sau khi xem xét",
            reviewedBy: "Người đánh giá",
            operation: "Hoạt động",
            viewScoreStatus: "Xem tình hình ghi bàn",
            whole: "Tất cả",
            manualReview: "Đánh giá thủ công",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Thực hành dữ liệu nhiệm vụ",
            implementationRate: "Tỷ lệ thực hiện",
            numberOfExecutors: "Số người thực hiện",
            numberOfPeopleNotExecuted: "Số người chưa thực hiện",
            completionRate: "Tỷ lệ hoàn thành",
            numberOfCompletedPersonnel: "Số lượng người đã hoàn thành",
            numberOfUnfinishedPersonnel: "Số người chưa hoàn thành",
            complianceRate: "Tỷ lệ tuân thủ",
            numberOfQualifiedPersonnel: "Số người đạt tiêu chuẩn",
            numberOfNonCompliantIndividuals: "Số người không đạt tiêu chuẩn",
            excellentRate: "Tỷ lệ vượt trội",
            numberOfOutstandingIndividuals: "Số lượng xuất sắc",
            numberOfNonOutstandingIndividuals: "Số lượng không xuất sắc",
            examTaskData: "Dữ liệu nhiệm vụ thi",
            published: "Đã phát hành",
            courses: "Một khóa học",
            courseData: "Dữ liệu khóa học",
            practiceTasks: "Nhiệm vụ thực hành",
            examTasks: "Nhiệm vụ thi",
          },
          practiceSummary: {
            practiceSummary: "Tóm tắt bài tập",
            numberOfPracticeTasks: "Số lượng nhiệm vụ thực hành",
            numberOfExercises: "Số lượng bài tập",
            accumulatedPracticeDuration: "Thời gian thực hành tích lũy",
            complianceRate: "Tỷ lệ tuân thủ",
            excellentRate: "Tỷ lệ vượt trội",
            ranking: "Xếp hạng",
          },
          rankingList: {
            numberOfExercises: "Số lượng bài tập",
            exerciseDuration: "Thời gian luyện tập",
            complianceRate: "Tỷ lệ tuân thủ",
            excellentRate: "Tỷ lệ vượt trội",
            diligenceChart: "Danh sách siêng năng",
            excellentList: "Danh sách xuất sắc",
            ranking: "Xếp hạng",
            fullName: "Tên",
            i: "Tôi",
          },
          taskCard: {
            end: "Hạn chót:",
            noDeadline: "Không có thời hạn",
            second: "Lần",
            branch: "Điểm",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Không có nhiệm vụ",
            completed: "Đã hoàn thành",
          },
          filter: { employeeDepartment: "Bộ phận nhân viên" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Xuất thành công, vui lòng vào trung tâm tác vụ để xem",
              export: "Xuất",
            },
          },
          filterConfig: {
            dropdown: { custom: "Tùy chỉnh" },
            modal: {
              updateSuccessful: "Cập nhật thành công",
              successfullySaved: "Lưu thành công",
              setAsACommonReport: "Đặt làm báo cáo chung",
              updateCurrentReport: "Cập nhật báo cáo hiện tại",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Điểm trung bình",
                  averageScoreOfScript: "Điểm trung bình từ",
                  averageEmotionalScore: "Điểm trung bình cảm xúc",
                  expressionAverageScore: "Biểu hiện điểm trung bình",
                  processAverageScore: "Điểm trung bình của quá trình",
                  branch: "Điểm",
                },
              },
              comprehensiveEvaluation: { const: { data: "Dữ liệu" } },
              doneStatus: {
                index: {
                  practice: "Bài tập",
                  examination: "Thi",
                  executed: "Đã thực hiện",
                  unexecuted: "Không thực hiện",
                  accumulatedExamDuration: "Thời gian kiểm tra tích lũy",
                  examFrequency: "Số lần thi",
                },
              },
              filter: {
                index: {
                  practice: "Bài tập",
                  examination: "Thi",
                  pleaseCompleteTheRequiredFields:
                    "Vui lòng hoàn thiện các mục bắt buộc",
                  staff: "Nhân viên",
                  taskType: "Loại nhiệm vụ",
                  pleaseSelect: "Vui lòng chọn",
                  taskName: "Tên nhiệm vụ",
                },
              },
            },
            index: {
              completionStatus: "Hoàn thành tình huống",
              comprehensiveEvaluation: "Đánh giá toàn diện",
              abilityGrowthTrend: "Xu hướng tăng trưởng năng lực",
              errorProneAnalysis: "Phân tích điểm dễ mắc lỗi",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Số người đang hoạt động",
            accumulatedPracticeDuration: "Thời gian thực hành tích lũy",
            averagePracticeDuration: "Thời gian luyện tập trung bình",
            people: "Người",
            numberOfPracticeTasks: "Số lượng nhiệm vụ thực hành",
            numberOfExercises: "Số lượng bài tập",
            exerciseDuration: "Thời gian luyện tập",
            second: "Lần",
            avgScore: "Điểm trung bình",
            speechCraftAvgScore: "Điểm trung bình từ",
            emotionAvgScore: "Điểm trung bình cảm xúc",
            speedAvgScore: "Biểu hiện điểm trung bình",
            flowAvgScore: "Điểm trung bình của quá trình",
            practiceCostTime: "Thời lượng tích lũy",
            totalPracticeTimes: "Số lần tích lũy",
            participateCommitRate: "Tỷ lệ tuân thủ tham gia",
            participateExcellentRate: "Tham gia đạt tỷ lệ xuất sắc",
          },
        },
        index: {
          employeeProfile: "Chân dung nhân viên",
          overview: "Tổng quan",
          personalSignage: "Kanban cá nhân",
          ongoingExercises: "Các bài tập đang diễn ra",
          ongoingExams: "Kỳ thi đang diễn ra",
          practiceTaskProgress: "Thực hành tiến độ nhiệm vụ",
          examTaskProgress: "Tiến độ nhiệm vụ thi",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Vui lòng chọn từ khái niệm",
              sessionDetectionSettings: "Cài đặt phát hiện phiên",
              successfullySaved: "Lưu thành công",
              abnormalSpeechSpeed: "Tốc độ nói bất thường",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Hỗ trợ các tiêu chuẩn phát hiện tốc độ nói tùy chỉnh và kết quả sẽ được đánh giá là tốc độ nói bình thường, tốc độ nói quá nhanh và tốc độ nói quá chậm. Khi tốc độ nói bất thường (quá nhanh/quá chậm), điểm sẽ bị trừ theo quy tắc chấm điểm",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Tốc độ nói quá nhanh: lớn hơn mỗi phút",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Từ, tốc độ nói quá chậm: ít hơn mỗi phút",
              charactersIgnoringLessThan: "Từ, bỏ qua nhỏ hơn",
              aSentenceOfWords: "Câu từ",
              excessiveModalParticles: "Quá nhiều giọng nói",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Hỗ trợ các từ ngữ khí tùy chỉnh và thiết lập các quy tắc cho các từ ngữ khí quá mức. Khi có quá nhiều từ ngữ, điểm sẽ bị trừ theo quy tắc chấm điểm",
              theNumberOfRepetitionsIs: "Số lần lặp lại là",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "Thời gian, được đánh giá là quá nhiều",
              definitionOfSensitiveViolations: "Định nghĩa vi phạm nhạy cảm",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Hỗ trợ tùy chỉnh từ đồng nghĩa của các từ vi phạm để phát hiện xem các từ vi phạm có xuất hiện trong một câu hay không. Khi các từ vi phạm xuất hiện, điểm sẽ bị trừ theo quy tắc chấm điểm",
              violationWords: "Từ vi phạm:",
              emotionalDetection: "Phát hiện cảm xúc",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Theo kỹ thuật đối thoại mô hình cảm xúc hệ thống để phát hiện cảm xúc, kết quả sẽ được đánh giá là cảm xúc bình thường và cảm xúc tiêu cực (bao gồm tiêu cực, tức giận và phàn nàn). Khi có cảm xúc tiêu cực, điểm sẽ bị trừ theo quy tắc chấm điểm",
              preserve: "Lưu",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Kích thước chấm điểm",
              ratingPoints: "Điểm chấm điểm",
              explain: "Mô tả",
              type: "Loại",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Dữ liệu ngẫu nhiên" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· Tích lũy từ ngữ chuẩn',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Một từ, tổng số từ mà học sinh theo dõi",
                  aWordspan: "Từ </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Thời gian </span><br /><span style = "margin-left: 8px">· Nói chậm',
                  timesspan: "Thời gian </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· Quá nhiều từ',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Điểm là" },
                  item: {
                    violationOnceReduced: "1 lần giảm vi phạm",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Tốc độ nói bất thường (bao gồm tốc độ nói quá nhanh và tốc độ nói quá chậm)",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Quá nhiều bài phát biểu",
                    oneNegativeDecreaseInEmotions:
                      "Cảm xúc tiêu cực 1 lần giảm",
                    branch: "Điểm",
                  },
                  wrap: { name: "Tên", preserve: "Lưu" },
                },
              },
              index: {
                complete: "Nội dung đầy đủ",
                keyHit: "Điểm chính",
                accurateFollowupReading: "Đọc chính xác",
                languageNorm: "Đặc tả ngôn ngữ",
                speechSpeed: "Tốc độ nói",
                modalParticles: "Lời nói",
                emotionallyPositive: "Cảm xúc tích cực",
                scoringModel: "Mô hình chấm điểm",
                successfullyModified: "Sửa đổi thành công",
                newSuccessfullyCreated: "Mới thành công",
              },
            },
            index: {
              scoringModel: "Mô hình chấm điểm",
              newModel: "Mô hình mới",
              model: "Mô hình",
              type: "Loại",
              operation: "Hoạt động",
              preview: "Xem trước",
              edit: "Chỉnh sửa",
            },
          },
          index: {
            scoringModel: "Mô hình chấm điểm",
            ratingDimension: "Kích thước chấm điểm",
            sessionDetectionSettings: "Cài đặt phát hiện phiên",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Vui lòng chọn",
                hit: "Đánh trúng",
                misses: "Trượt",
              },
            },
          },
          index: {
            duration: "Thời lượng",
            processScore: "Điểm quy trình",
            branch: "Điểm",
            contentCompleteness: "Nội dung đầy đủ",
            scriptScore: "Điểm kỹ năng nói",
            keyHitRate: "Tỷ lệ trúng đích chính",
            emotionalScore: "Điểm số cảm xúc",
            negativeProportionOfEmotions: "Tỷ lệ cảm xúc tiêu cực",
            expressionScore: "Điểm biểu hiện",
            theProportionOfAbnormalSpeechSpeed: "Tỷ lệ tốc độ nói bất thường",
            excessiveProportionOfModalParticles:
              "Quá nhiều giọng nói chiếm tỷ lệ",
            learningRecordData: "Học cách ghi dữ liệu",
            staff: "Nhân viên",
            curriculum: "Khóa học",
            mainPoints: "Điểm chính",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Hình thức",
                        lineChart: "Biểu đồ đường",
                        barChart: "Biểu đồ cột",
                        pieChart: "Hình bánh",
                      },
                      index: { switchingTypes: "Loại chuyển đổi" },
                    },
                  },
                  index: {
                    saveSuccessful: "Lưu thành công",
                    editChart: "Chỉnh sửa biểu đồ",
                    chartName: "Tên biểu đồ",
                    deleteSuccessful: "Xóa thành công",
                    refresh: "Làm mới",
                    export: "Xuất",
                    areYouSureToDeleteIt: "Chắc chắn xóa?",
                    delete: "Xóa",
                    viewFilteringCriteria: "Xem bộ lọc",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Xuất thành công, vui lòng vào trung tâm tác vụ để xem",
              },
            },
            header: {
              index: {
                saveSuccessful: "Lưu thành công",
                deleteSuccessful: "Xóa thành công",
                editReport: "Chỉnh sửa báo cáo",
                reportName: "Tên báo cáo",
                refresh: "Làm mới",
                areYouSureToDeleteIt: "Chắc chắn xóa?",
                delete: "Xóa",
                exportToExcel: "Xuất Excel",
                exportSnapshot: "Xuất ảnh chụp nhanh",
                export: "Xuất",
                authority: "Quyền",
                permissionSettings: "Cài đặt quyền",
                reportVisiblePermissions: "Quyền hiển thị báo cáo",
                visibleToEveryone: "Mọi người đều có thể nhìn thấy",
                onlyVisibleToOneself: "Chỉ hiển thị cho chính bạn",
                designatedPersonnelVisible:
                  "Người được chỉ định có thể nhìn thấy",
                pleaseSelect: "Vui lòng chọn",
                reportDataPermissions: "Quyền dữ liệu báo cáo",
                restrictedByUserPermissions: "Bị giới hạn bởi quyền người dùng",
                visiblePeopleAreTheSame:
                  "Có thể thấy rằng mọi người đều giống nhau",
              },
            },
            titleFooter: {
              index: { addReport: "Báo cáo mới", reportName: "Tên báo cáo" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Xuất thành công, vui lòng vào trung tâm tác vụ để xem",
            dataQueryInProgress: "Truy vấn dữ liệu...",
            customKanban: "Kanban tùy chỉnh",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Hình thức",
                lineChart: "Biểu đồ đường",
                barChart: "Biểu đồ cột",
                pieChart: "Hình bánh",
              },
              index: {
                successfullyAdded: "Thêm thành công",
                viewSQL: "Xem sql",
                export: "Xuất",
                addToReport: "Thêm vào báo cáo",
              },
            },
            refer: {
              hooks: {
                data: "Dữ liệu",
                interrogativeSentence: "Câu hỏi",
                historicalQuery: "Truy vấn lịch sử",
              },
              index: {
                copySuccessful: "Sao chép thành công",
                copy: "Sao chép",
              },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Không đạt tiêu chuẩn",
                        meetingStandards: "Đạt tiêu chuẩn",
                        excellent: "Xuất sắc",
                        notStartedYet: "Chưa bắt đầu",
                        inProgress: "Đang tiến hành",
                        completed: "Đã hoàn thành",
                        incomplete: "Chưa hoàn thành",
                      },
                    },
                  },
                  const: {
                    taskName: "Tên nhiệm vụ",
                    department: "Phòng ban",
                    studentName: "Tên học sinh",
                    scoringResults: "Kết quả ghi điểm",
                    taskCompletionStatus: "Tình trạng hoàn thành nhiệm vụ",
                  },
                  index: { pleaseSelectADimension: "Vui lòng chọn thứ nguyên" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Nhập @ có thể mở hộp bật lên để chọn thứ nguyên",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Vui lòng nhập câu hỏi để truy vấn trực tiếp",
                query: "Truy vấn",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Xuất thành công, vui lòng vào trung tâm tác vụ để xem",
            dataQueryInProgress: "Truy vấn dữ liệu...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Tìm kiếm từ khóa",
                previous: "Trước",
                next: "Kế tiếp",
                theObjectThatTriggersTheScrollingEventIs:
                  "Đối tượng kích hoạt sự kiện cuộn là",
                userScrolling: "Cuộn người dùng",
              },
            },
            summary: {
              index: {
                reminder: "Lời nhắc:",
                edit: "Chỉnh sửa",
                name: "Tên",
                hintLanguage: "Lời nhắc nhở",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Trích xuất quan điểm" },
            },
            outline: {
              index: {
                numberOfKeywords: "Số lần từ khóa",
                speaker: "Người nói chuyện",
                speechDuration: "Thời lượng nói",
                proportionOfSpeaking: "Tỷ lệ nói",
                maximumSpeechDuration: "Thời gian nói dài nhất",
                numberOfQuestions: "Số lượng câu hỏi",
                averageSpeechSpeed: "Tốc độ nói trung bình",
                conversationalActions: "Hành động đàm thoại",
                contentSummary: "Tóm tắt nội dung",
                generateSummary: "Tạo bản tóm tắt",
                keywordCloud: "Từ khóa đám mây",
              },
            },
          },
          index: {
            outline: "Tóm tắt",
            viewpoint: "Quan điểm",
            contentSummary: "Tóm tắt nội dung",
            viewpointExtraction: "Trích xuất quan điểm",
            summaryOfKeyPoints: "Tóm tắt các điểm chính",
            videoAnalysis: "Phân tích video",
          },
        },
        list: {
          index: {
            delete: "Xóa",
            uploadSuccessful: "Tải lên thành công",
            videoList: "Danh sách video",
            uploadVideo: "Tải lên video",
            videoName: "Tên video",
            uploadTime: "Thời gian tải lên",
            endTime: "Thời gian kết thúc",
            analyzeProgress: "Tiến độ phân tích",
            haveInHand: "Đang tiến hành",
            completed: "Đã hoàn thành",
            fail: "Thất bại",
            toBegin: "Chờ bắt đầu",
            operation: "Hoạt động",
            see: "Xem",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Thêm giờ làm việc" },
        },
      },
      index: {
        businessWorkingHours: "Thời gian làm việc kinh doanh",
        newlyBuild: "Mới xây dựng",
        name: "Tên",
        describe: "Mô tả",
        operation: "Hoạt động",
        edit: "Chỉnh sửa",
        delete: "Xóa",
        editBusinessWorkingHours: "Chỉnh sửa thời gian làm việc kinh doanh",
        newBusinessWorkingHours: "Giờ làm việc cho doanh nghiệp mới",
        workingHours: "Thời gian làm việc",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Trích xuất thông minh",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Bạn có xác nhận rằng bạn muốn hủy? Sẽ không thể khôi phục lại sau khi hủy",
        },
      },
    },
    accountManagement: {
      securitySetting: "Cài đặt bảo mật",
      accountManagement: "Quản lý tài khoản",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Đến",
            switchToIPAddressMode: "Chuyển sang chế độ địa chỉ IP",
            switchToIPSegmentMode: "Chuyển sang chế độ phân đoạn IP",
          },
        },
      },
      index: {
        saveSuccessful: "Lưu thành công",
        pleaseImproveTheConfiguration: "Vui lòng cải thiện cấu hình",
        securitySetting: "Cài đặt bảo mật",
        employeeLoginIPRestrictions: "Giới hạn IP đăng nhập của nhân viên",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Giới hạn phạm vi IP mà nhân viên có thể đăng nhập và không được phép đăng nhập ngoài phạm vi. Chỉ có hiệu lực đối với trang web, ứng dụng di động không bị hạn chế và quản trị viên không bị hạn chế.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Khi thêm hoặc sửa đổi, vui lòng điền vào phân đoạn IP mạng bên ngoài (cùng phân đoạn C) hoặc địa chỉ IP mạng bên ngoài cố định.",
        allowLoginIPRange: "Phạm vi IP cho phép đăng nhập",
        preserve: "Lưu",
      },
    },
    coachAdminEntry: { speechRecognition: "Nhận dạng giọng nói" },
    billingCenter: {
      template: {
        details: "Chi tiết",
        languageType: "Loại ngôn ngữ",
        aSREngine: "Động cơ ASR",
        voiceQualityQuota: "Hạn ngạch kiểm tra chất lượng bằng giọng nói",
        realtimeData: "Dữ liệu thời gian thực",
        calculatedByDay: "Tính theo ngày",
      },
      component: {
        aSRName: "Tên ASR",
        language: "Ngôn ngữ",
        remainingQuota: "Hạn ngạch còn lại",
        hours: "Giờ",
        usedQuota: "Hạn ngạch đã sử dụng",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Hoạt động đã được lưu thành công!",
        deletedSuccessfully: "Xóa thành công!",
        pleaseSelectLanguage: "Vui lòng chọn ngôn ngữ",
        pleaseConfigureRules: "Vui lòng cấu hình quy tắc",
        updateSuccessful: "Cập nhật thành công!",
        operationSuccessful: "Hoạt động thành công!",
      },
      template: {
        aSRLanguage: "Ngôn ngữ ASR",
        pleaseSelect: "Vui lòng chọn",
        model: "Mô hình",
        pleaseSelectLanguage: "Vui lòng chọn ngôn ngữ",
        delete: "Xóa",
        newASRModel: "Mô hình ASR mới",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Phân tích sản phẩm cạnh tranh" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Bộ lọc đánh giá thương mại điện tử",
        allComments: "Tất cả bình luận",
        ecommerceReviewManagement: "Quản lý đánh giá thương mại điện tử",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Bộ lọc đánh giá truyền thông xã hội",
        allComments: "Tất cả bình luận",
        socialMediaReviewManagement: "Quản lý bình luận truyền thông xã hội",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Phân tích sản phẩm cạnh tranh",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Hình đại diện",
        realname: "Tên",
        nickname: "Biệt danh",
        name: "Tên",
        email: "Tài khoản (email)",
        mobile: "Điện thoại",
        agentId: "Số công việc",
        position: "Vị trí",
        role: "Vai trò",
        actions: "Hoạt động",
        createUser: "Nhân viên mới",
        editUser: "Nhân viên biên tập",
        account: "Tài khoản",
        originPassword: "Mật khẩu ban đầu",
        newPassword: "Mật khẩu mới",
        resumePassword: "Xác nhận mật khẩu",
        userGroup: "Nhân viên nhóm",
        authorizationGroup: "Nhóm quyền",
        resetPassword: "Đặt lại mật khẩu",
      },
      userGroups: {
        id: "ID",
        name: "Tên",
        userCount: "Số lượng nhân viên",
        actions: "Hoạt động",
        createUserGroup: "Nhóm nhân viên mới",
        editUserGroup: "Biên tập viên nhân viên nhóm",
        member: "Thành viên",
      },
      role: {
        id: "ID",
        name: "Tên",
        actions: "Hoạt động",
        all: "Tất cả",
        taskName: "Nhiệm vụ",
        readTask: "Xem nhiệm vụ",
        manageTemplate: "Quản lý mẫu",
        manualInspection: "Kiểm tra lại thủ công",
        readResultAll: "Xem kết quả",
        readResultPersonal: "Xem kết quả (cá nhân)",
        readProcess: "Xem quá trình kiểm tra chất lượng",
        readReport: "Thống kê và báo cáo",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Ngày",
        taskName: "Tên",
        size: "Kích thước",
        status: "Trạng thái",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Tên",
            remark: "Mô tả",
            actions: "Hoạt động",
          },
          checkPoint: {
            name: "Tên",
            remark: "Mô tả",
            type: "Loại",
            gradeType: "Phương pháp đánh giá",
            conditionLogic: "Quy tắc",
            predeterminedScore: "Giá trị điểm",
            weight: "Trọng lượng",
            status: "Trạng thái",
            actions: "Hoạt động",
            switchOn: "Kích hoạt",
            switchOff: "Vô hiệu hóa",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Thời gian",
            customer: "Khách hàng",
            customerPhone: "Điện thoại của khách hàng",
            callType: "Loại cuộc gọi",
            source: "Nguồn",
            voiceRecording: "Ghi âm cuộc gọi",
            graded: "Chấm điểm",
            openingGreeting: "Lời chào mở đầu",
          },
          checkPointList: {
            name: "Điểm kiểm tra chất lượng",
            type: "Loại",
            deductScore: "Phân số",
            isHit: "Tình huống đánh",
            status: "Xem lại",
            finalDeductScore: "Xác nhận trừ điểm",
            hit: "Đánh trúng",
            notHit: "Trượt",
          },
        },
      },
    },
    custom: { keywords: "Từ khóa đối thoại" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Cài đặt hệ thống" },
      systemIntegration: {
        text: "Tích hợp hệ thống",
        subTitle: {
          udesk: "Hệ thống dịch vụ khách hàng Udesk",
          udeskWhite: "Hệ thống dịch vụ khách hàng",
          wechat: "WeChat doanh nghiệp",
          taobao: "Taobao ngàn bò",
          weifeng: "Vi phong",
        },
      },
      columnsManage: { text: "Quản lý trường kiểm tra chất lượng" },
      customerField: { text: "Trường khách hàng" },
      speechRecognition: { text: "Nhận dạng giọng nói" },
      user: { text: "Quản lý nhân viên" },
      userGroups: { text: "Quản lý nhóm nhân viên" },
      role: { text: "Quản lý vai trò" },
      dataMigration: { text: "Di chuyển dữ liệu" },
      taskCenter: { text: "Trung tâm nhiệm vụ" },
      recordUpload: { text: "Tải lên bản ghi địa phương" },
      anomalousData: { text: "Dữ liệu bất thường" },
      systemLog: { text: "Nhật ký tin nhắn" },
      msgCenter: { text: "Trung tâm tin nhắn" },
      wechatDocking: { text: "Kết nối vi mô doanh nghiệp" },
    },
    tasks: {
      homePage: { text: "Trang chủ" },
      workbench: { text: "Bàn làm việc" },
      semanticIntelligence: { text: "Ngữ nghĩa thông minh" },
      intelligentAnalysis: { text: "Phân tích thông minh" },
      intelligentLabel: { text: "Nhãn thông minh" },
      admin: { text: "Quản lý" },
      callManage: { text: "Quản lý cuộc gọi" },
      dialogueManage: { text: "Quản lý cuộc trò chuyện" },
      ticketManage: { text: "Quản lý đơn đặt hàng công việc" },
      dataAnalysis: { text: "Phân tích dữ liệu" },
      customerService: { text: "Trung tâm dịch vụ khách hàng" },
      clientCenter: { text: "Trung tâm khách hàng" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Trung tâm đào tạo" },
      templates: { text: "Quản lý mẫu" },
      wechatQaTasks: { text: "Bàn làm việc WeChat doanh nghiệp" },
      wechatQaSetting: { text: "Cấu hình phân tích WeChat doanh nghiệp" },
      wechatQaChartRecord: { text: "Phiên WeChat doanh nghiệp" },
      wechatStaffCenter: { text: "Trung tâm nhân viên vi mô doanh nghiệp" },
      callAnalysis: { text: "Phân tích phiên" },
      caseBaseFolder: { text: "Thư viện trường hợp" },
      coachTask: { text: "Nhiệm vụ tư vấn" },
      teamAnalysis: { text: "Phân tích nhóm" },
      customer: { text: "Trung tâm khách hàng" },
      analysisEnable: { text: "Phân tích trao quyền" },
      analysisHelp: { text: "Phân tích trợ giúp" },
      analysisStudy: { text: "Phân tích học tập" },
      analysisReview: { text: "Phân tích đánh giá" },
      analysisTags: { text: "Phân tích nhãn thông minh" },
      analysisSentiment: { text: "Phân tích dư luận" },
      analysisBusiness: { text: "Phân tích kinh doanh" },
      groupTest: { text: "Kiểm tra nhóm" },
    },
    navBar: {
      billingCenter: "Trung tâm trợ giúp",
      uploadDownload: "Trung tâm tải lên và tải xuống",
      setting: "Cài đặt hệ thống",
    },
  },
  fix: {
    selectType: "Chọn loại:",
    all: "Tất cả",
    call: "Cuộc gọi",
    text: "Đối thoại",
    wechat: "Phiên WeChat doanh nghiệp",
    businessTrendAnalysis: "Phân tích xu hướng kinh doanh phân loại cấp một",
    noEchart: "Biểu đồ không có dữ liệu",
    checkPointisRequired: "Quy tắc được định cấu hình là bắt buộc",
    voiceDownLoad: "Tải xuống bản ghi âm",
    exportTxt: "Xuất văn bản TXT",
    exportWord: "Xuất văn bản từ",
    sureReRole: "Xác nhận vai trò của người nói ngược?",
    sureRecover: "Xác nhận sửa lại?",
    sureReAsr: "Xác nhận nhận dạng lại?",
    reRole: "Người nói ngược",
    recover: "Sửa lại",
    reAsr: "Xác định lại",
    recordingOperation: "Hoạt động ghi âm",
    onlySave30Day: "(Chỉ giữ trong 30 ngày)",
    appealError: "Vui lòng khiếu nại điểm kiểm tra chất lượng trước khi gửi",
    exportToTaskCenter: "Đã xuất thành công vào trung tâm tải lên và tải xuống",
    caseBaseLimit: "Thư viện trường hợp không được vượt quá 50",
    unnamed: "Chưa đặt tên",
    wordSpeed: "Chữ/điểm",
    hit: "Đánh trúng",
    checkPoint: "Điểm kiểm tra chất lượng",
    realTimeAdditionTip1:
      "Thêm theo thời gian thực: kiểm tra chất lượng bắt đầu ngay lập tức khi dữ liệu vào hệ thống kiểm tra chất lượng",
    realTimeAdditionTip2:
      "Bổ sung thường xuyên: tự động bổ sung dữ liệu thường xuyên để kiểm tra chất lượng",
    null: "Không có",
    collcetedProps: "Trường thu thập",
    editingNameNow: "Có tên đang chỉnh sửa",
    upToFourLevels: "Phân loại lên đến bốn",
    default: "Mặc định",
    nameCantBeNull: "Tên không được để trống",
    editingNow: "Có một hoạt động đang được chỉnh sửa",
    nameError: "Tên sai",
    nameMaxLength8: "Chiều dài tối đa của tên là 8",
    callTime: "Thời gian nói chuyện",
    sentimentValue: "Giá trị cảm xúc của khách hàng",
    agentSentimentValue: "Giá trị cảm xúc dịch vụ khách hàng",
    sentimentAnalysis: "Phân tích cảm xúc",
    associatedData: "Dữ liệu theo đường",
    notifyTheOwner: "Thông báo cho chủ nhóm",
    notifyTheEmployee: "Thông báo cho nhân viên",
    notifyTheOwnerTip:
      "Khi loại cuộc trò chuyện là trò chuyện nhóm, chủ sở hữu nhóm sẽ được thông báo",
    customNotifyEmployee: "Nhân viên thông báo tùy chỉnh",
    customNotifyEmployeeTip:
      "Nhân viên kích hoạt là nhân viên, nhân viên được thông báo",
    clickToLearnMore: "Bấm để biết thêm",
    repeatMarkingTip:
      "Hồ sơ đã được đánh dấu bởi điểm kiểm tra chất lượng, không cần phải đánh dấu lại",
    successfulEvaluation: "Đánh giá thành công",
    evaluationFailed: "Đánh giá thất bại",
    qualityInspectionScore: "Điểm kiểm tra chất lượng",
    semanticTags: "Nhãn ngữ nghĩa",
    smartTags: "Nhãn thông minh",
    collect: "Thu thập thông tin",
    appealRecord: "Hồ sơ kháng cáo",
    satisfy: "Hài lòng",
    notSatisfy: "Không hài lòng",
    appealPlaceHolder: "Vui lòng nhập lý do khiếu nại",
    giveUp: "Từ bỏ",
    rejectReasonPlaceHolder: "Vui lòng nhập lý do từ chối",
    reject: "Bác bỏ",
    changeRejectReason: "Sửa đổi lý do từ chối",
    pass: "Thông qua",
    revise: "Sửa đổi",
    machineEvaluation: "Đánh giá máy",
    poor: "Kém",
    excellent: "Tốt lắm.",
    moderate: "Nói chung",
    mustMarked: "Điểm kiểm tra chất lượng phải được đánh dấu",
    pleaseChoose: "Vui lòng chọn",
    manualEvaluation: "Đánh giá nhân tạo",
    qualityInspectionTemplate: "Mẫu kiểm tra chất lượng áp dụng",
    collectionTemplate: "Mẫu bộ sưu tập áp dụng",
    keywordsWordCloud: "Từ khóa đám mây",
    editCategoryLabel: "Chỉnh sửa nhãn phân loại",
    justCall: "Chỉ xem cuộc gọi",
    justConversation: "Chỉ nhìn vào cuộc trò chuyện",
    batchOperation: "Hoạt động hàng loạt",
    customerServicesGroup: "Nhóm dịch vụ khách hàng",
    joinGroup: "Tham gia nhóm",
    removeGroup: "Di chuyển ra khỏi nhóm",
    enterInformationEntity: "Vui lòng nhập nội dung thực thể thông tin",
    enterNotBeNull: "Đầu vào không được để trống",
    operationGroup: "Nhóm hoạt động",
    customerServiceSelected: "Dịch vụ khách hàng đã chọn",
    callAndConversation: "Cuộc gọi/cuộc trò chuyện",
    conversation: "Đối thoại",
    system: "Hệ thống",
    customize: "Tùy chỉnh",
    effective: "Hiệu quả",
    invalid: "Không hợp lệ",
    successfulAndToTaskCenter:
      "Để thực hiện thành công, vui lòng đến trung tâm tác vụ để kiểm tra",
    recalculationFailed: "Không tính lại",
    selectRecalculatedIndicator: "Vui lòng chọn chỉ số để tính lại",
    source: "Vui lòng chọn nguồn dữ liệu",
    placeholder: "Vui lòng nhập từ khóa",
    cumulative: "Loại tích lũy",
    average: "Loại trung bình",
    distribution: "Luật phân phối",
    originalDataCannotEmpty: "Dữ liệu thô không được để trống",
    inspection: "Kiểm tra chất lượng",
    check: "Lấy mẫu",
    review: "Xem lại",
    draftSavedSuccessfully: "Bản nháp được lưu thành công",
    optimization: "Tối ưu hóa",
    agentName: "Tên dịch vụ khách hàng",
    allTasks: "Tất cả các nhiệm vụ kiểm tra chất lượng",
    datePickerSelect: "Chọn ngày",
    callSelect: "Chọn một cuộc trò chuyện",
    taskSelect: "Chọn nhiệm vụ",
    genReport: "Tạo báo cáo",
    dataSource: "Nguồn dữ liệu",
    callsTotal: "Tổng số cuộc gọi",
    intelligentQualityInspection: "Kiểm tra chất lượng thông minh",
    percentage: "Tỷ lệ",
    artificialSampling: "Lấy mẫu thủ công",
    appealNumber: "Khối lượng khiếu nại",
    totalConversation: "Tổng số đối thoại",
    samplingReviewRate: "Tỷ lệ kiểm tra lại",
    data: "Dữ liệu",
    inspectionResults: "Kết quả kiểm tra chất lượng",
    inspected: "Đã kiểm tra chất lượng",
    totalScore: "Tổng điểm",
    learnMore: "Xem thêm",
    inspectionEffect: "Hiệu ứng kiểm tra chất lượng",
    allTotal: "Tổng số tiền",
    manualInspection: "Kiểm tra lại thủ công",
    artificialReview: "Đánh giá thủ công",
    approvalAmountAndRate: "Khối lượng phê duyệt & tỷ lệ phê duyệt",
    customerScore: "Điểm dịch vụ khách hàng",
    inspectionRecommendations: "Khuyến nghị kiểm tra chất lượng",
    saveDraft: "Lưu bản nháp",
    historicalAdvice: "Đề xuất lịch sử",
    advicePlaceHolder: "Vui lòng nhập đề xuất...",
    upload: "Tải lên tệp",
    customAdd: "Thêm bộ lọc",
    advancedConditions: "Điều kiện nâng cao",
    keyWord: "Từ khóa",
    containsAll: "Chứa tất cả",
    containsAny: "Chứa tùy ý",
    matchAny: "Phù hợp với bất kỳ",
    matchAll: "Phù hợp với tất cả",
    smartTagsOne: "Nhãn thông minh một",
    smartTagsTwo: "Nhãn thông minh II",
    season: "Mùa",
    month: "Tháng",
    week: "Tuần",
    day: "Ngày",
    hour: "Thời gian",
    notMoreThan366: "Thời gian không thể vượt quá 366 ngày",
    propsCannotBeNull: "Có những trường chưa được điền",
    other: "Khác",
    correlationCoefficient: "Hệ số tương quan",
    repetitionRate: "Tỷ lệ lặp lại",
    configurationItemsMoreThan20: "Thêm tối đa 20 mục cấu hình",
    conversionAnalysis: "Phân tích chuyển đổi",
    correlationAnalysis: "Phân tích tương quan",
    successfulExportAndToTaskCenter:
      "Xuất thành công, vui lòng vào trung tâm tác vụ để xem",
    typeSelect: "Chọn loại",
    exportExcel: "Xuất excel",
    exportPng: "Xuất png",
    noDataChart: "Biểu đồ không có dữ liệu",
    placeOperator: "Vui lòng chọn nhãn thông minh",
    placeAdvancedConditions: "Vui lòng chọn điều kiện nâng cao",
    createAdvancedConditions: "Bộ lọc nâng cao mới",
    moveUp: "Di chuyển lên",
    moveDown: "Di chuyển xuống",
    moveLeft: "Dịch chuyển trái",
    moveRight: "Dịch chuyển sang phải",
    statusChangeSuccess: "Sửa đổi trạng thái thành công",
    editSuccess: "Sửa đổi thành công",
    saveSuccess: "Lưu thành công",
    firstPage: "Trang chủ",
    lastPage: "Trang cuối",
    customerInfo: "Thông tin khách hàng",
    type: "Loại",
    NumberTypeErrorMessage:
      'Nội dung thông tin trường của loại số chỉ có thể chứa số hoặc ".',
    taskStartPrompt: "Mẹo mở nhiệm vụ",
    startConfirm:
      "Vui lòng nhấp vào nút xác nhận để bắt đầu nhiệm vụ kiểm tra chất lượng",
    continueToSubmitWithoutEvaluation: "Tiếp tục gửi mà không cần đánh giá",
    rejectTip:
      "Không có hoạt động thay đổi nào được phát hiện, bạn có xác nhận rằng tất cả các khiếu nại đều bị từ chối trực tiếp không?",
    unmarkedSelfLearningTip:
      "Bạn có điểm kiểm tra chất lượng tự học không được đánh dấu",
    markedSuccessfully: "Đánh dấu thành công",
    markingFailed: "Đánh dấu không thành công",
    exceedingLength: "Vượt quá chiều dài",
    uncategorized: "Chưa phân loại",
    addSuccess: "Thành công mới",
    deleteSuccess: "Xóa thành công",
    featureName: "Tên yếu tố",
    informationEntity: "Thực thể thông tin",
    standardAnswer: "Câu trả lời tiêu chuẩn",
    keyElementAnswer: "Các yếu tố chính trả lời",
    generalStatement: "Tuyên bố chung (không yêu cầu)",
    generalQuestionsAndConfirmation: "Câu hỏi chung và xác nhận",
    specialQuestionsAndAnswers: "Câu hỏi và câu trả lời đặc biệt",
    agent: "Dịch vụ khách hàng",
    customer: "Khách hàng",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Các yếu tố chính Thẻ ngữ nghĩa và thực thể thông tin không thể trống cùng một lúc",
    standardSentence: "Câu chuẩn",
    acquaintance: "Sự quen biết",
    smartUePlaceholder: "Vui lòng nhập tên tương tác thông minh",
    typeSelectPlaceholder: "Vui lòng chọn loại",
    keyElement: "Yếu tố chính",
    reQuerySementicTags: "Chọn lại nhãn ngữ nghĩa",
    querySementicTags: "Chọn nhãn ngữ nghĩa",
    QATestForm: "Các hình thức phát hiện câu hỏi và câu trả lời",
    selectNotBeNull: "Lựa chọn không thể trống",
    featureDetectionTarget: "Mục tiêu phát hiện yếu tố",
    categorized: "Phân loại",
    effectiveness: "Hiệu quả",
    hideAdvancedSettings: "Ẩn cài đặt nâng cao",
    showAdvancedSettings: "Hiển thị cài đặt nâng cao",
    generalStatementOnly: "Tuyên bố chung",
    generalQuestions: "Câu hỏi chung",
    affirmativeExpression: "Biểu hiện khẳng định",
    negativeIntention: "Ý định phủ định",
    searchSementicTags: "Truy vấn nhãn ngữ nghĩa",
    choiceQuestion: "Câu hỏi chọn lọc",
    selectiveAnswer: "Câu trả lời có chọn lọc",
    sementicTagsPlaceholder: "Vui lòng nhập nội dung nhãn ngữ nghĩa",
    openSpecialQuestion: "Câu hỏi đặc biệt mở",
    openAnswer: "Câu trả lời mở",
    customQA: "Câu hỏi và câu trả lời tùy chỉnh",
    questions: "Phương pháp hỏi",
    dataPlaceholder: "Vui lòng nhập nội dung",
    reply: "Trả lời",
    notFindSuitableLabel: "Không tìm thấy nhãn phù hợp",
    addSemanticTag: "Thêm một thẻ ngữ nghĩa mới",
    intelligentInteraction: "Tương tác thông minh",
    matching: "Phù hợp",
    bout: "Lần",
    keywordMatch: "Từ khóa phù hợp",
    addFilterConditions: "Thêm bộ lọc",
    buttonStartCollectionTask:
      "Vui lòng nhấp vào nút OK để bắt đầu tác vụ thu thập",
    buttonStartTask:
      "Vui lòng nhấp vào nút OK để bắt đầu nhiệm vụ kiểm tra chất lượng",
    ruleConfigurationIsRequired: "Quy tắc được định cấu hình là bắt buộc",
    asrRunning: "Thực thi ASR",
    errorCorrectionExecution: "Thực hiện sửa lỗi",
    emotionalVolumeRecognition: "Nhận dạng âm lượng cảm xúc",
    dataPreprocessing: "Xử lý trước dữ liệu",
    inspectionExecution: "Thực hiện kiểm tra chất lượng",
    businessId: "Kinh doanh Id",
    businessTime: "Thời gian kinh doanh",
    createTime: "Tạo thời gian",
    abnormalReason: "Nguyên nhân bất thường",
    singleChat: "Trò chuyện đơn",
    groupChat: "Trò chuyện nhóm",
    taskContent: "Nội dung nhiệm vụ",
    submissionTime: "Thời gian gửi",
    startTime: "Thời gian bắt đầu",
    endTime: "Thời gian kết thúc",
    runningTime: "Thời gian chạy",
    totalAmountOfData: "Tổng khối lượng dữ liệu",
    resultsOfThe: "Kết quả thực hiện",
    operator: "Nhà điều hành",
    taskDetails: "Chi tiết nhiệm vụ",
    executiveFunction: "Chức năng thực hiện",
    qualityCheckTasks: "Nhiệm vụ kiểm tra chất lượng",
    implementationDetails: "Chi tiết thực hiện",
    conditionalRules: "Quy tắc điều kiện",
    smartCondition: "Điều kiện thông minh",
    goThis: "Di chuyển đến đây",
    pleaseMarkClassification: "Vui lòng đánh dấu phân loại",
    advancedScreening: "Sàng lọc nâng cao",
    noDataToManipulate: "Không có dữ liệu có thể được vận hành",
    concept: "Khái niệm từ",
    checkPoint1: "Cấu hình quy tắc",
    requiredFieldsCannotEmpty: "Các mục bắt buộc không được để trống",
    rule: "Quy tắc",
    afterFixUnit: "Chữ",
    unmarkedSelfLearningPointsTip:
      "Bạn có điểm kiểm tra chất lượng tự học không được đánh dấu",
    onlyReservedFor7Days: "(Chỉ giữ trong 7 ngày)",
    downloadError: "Tải xuống thất bại",
    pause: "Tạm dừng",
    continue: "Tiếp tục",
    recalcScore: "Chỉ tính toán lại điểm số (bao gồm cả xếp hạng)",
    recalculatePointsAndScores:
      "Kế toán lại điểm kiểm tra chất lượng và điểm số",
    recalculatePointsAndScoresWithEmtry:
      "Tính toán lại điểm kiểm tra chất lượng (điểm kiểm tra chất lượng trống)",
    inProgress: "Đang tiến hành",
    toBeExecuted: "Đang chờ xử lý",
    executeImmediately: "Thực hiện ngay lập tức",
    specialElements: "Các yếu tố đặc biệt",
    logicRule: "Quy tắc logic",
    every: "Nhiều trận đấu",
    hitRecall: "Đánh từ thu hồi",
    notHitRecall: "Từ thu hồi không trúng đích",
    hitRecallRegular: "Đánh thu hồi thông thường",
    notHitRecallRegular: "Không đánh thường xuyên",
    hitRule: "Quy tắc đánh",
    notHitRule: "Quy tắc trượt",
    hitSimilarSentence: "Đánh các câu tương tự",
    notHitSimilarSentence: "Câu tương tự chưa trúng",
    hitExcludeSimilarSentences: "Đánh để loại trừ các câu tương tự",
    notHitExcludeSimilarSentences: "Các câu tương tự bị loại trừ",
    updateSuccess: "Cập nhật thành công",
    qualify: "Chính quy",
    recallKeyWord: "Từ khóa thu hồi",
    grammar: "Quy tắc ngữ pháp",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Thay đổi hiệu quả không thành công, vui lòng thử lại",
    postAutoMateTasksCaseByTaskId:
      "Cấu hình đồng bộ hóa thư viện trường hợp không lưu được",
    getAutoMateTasksCaseByTaskId:
      "Cấu hình đồng bộ thư viện trường hợp không thành công",
    postInspectionRemarkModelConfigs: "Lưu mẫu nhận xét không thành công",
    putInspectionRemarkModelConfigsById: "Lưu mẫu nhận xét không thành công",
    getInspectionRemarkModelConfigsById: "Không lấy được dữ liệu mẫu nhận xét",
    postKnowledgeDatas: "Không thể tạo cơ sở kiến thức mới, vui lòng thử lại",
    putKnowledgeDatasById:
      "Không thể sửa đổi cơ sở kiến thức, vui lòng thử lại",
    getLibraryFiles: "Không thể lấy danh sách thư mục giá",
    getLibraryCalls: "Nhận tệp không thành công",
    getLibraryFilesSearch: "Tìm kiếm thất bại",
    putLibraryFilesMoveById: "Không thể di chuyển thư mục giá",
    putLibraryCallsById: "Đổi tên tệp không thành công!",
    postLibraryFiles: "Thêm thư mục không thành công",
    putLibraryFilesById: "Đổi tên thư mục không thành công",
    deleteLibraryFilesById: "Xóa không thành công",
    deleteLibraryCallsById: "Xóa không thành công",
    postLibraryCalls: "Thêm thất bại",
    getVoiceUploadResultById: "Kết quả tải lên không xuất được",
  },
};
