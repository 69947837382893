import React from 'react';
import Udesk from 'Udesk';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    //删除并记录 删除元素
    const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, removed);
    return result;
};

// 插入排序
const insert = (list, insertObj, endIndex) => {
    const result = Array.from(list);
    //删除并记录 删除元素
    // const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, insertObj);
    return result;
};
class DataPreprocessDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        id: null,
        name: '',
        desc: '',
        dataSourceFlags: [],
        dataSource: Udesk.enums.dataPreprocessSourceFlags.all.id,
        statusFlags: [],
        status: Udesk.enums.dataPreprocessStatusFlags.effiective.id,
        entity: null,
        operatorList: [],
        compositeType: Udesk.enums.operatorRuleTypes.all.id,
        operatorLogic: '',
        ruleType: Udesk.enums.dataPreprocessTypes.custom.id,
        ruleSecondaryType: Udesk.enums.dataSourceCutom.smartTag.id,
        customTypeList: [],
        logicList: [
            {
                id: 'logicList-1',
                content: UdeskLocales.current.components.common.customFilter.and,
                value: 'and',
                flag: true,
            },
            {
                id: 'logicList-2',
                content: UdeskLocales.current.components.common.customFilter.or,
                value: 'or',
                flag: true,
            },
            {
                id: 'logicList-3',
                content: UdeskLocales.current.components.common.customFilter.not,
                value: 'not',
                flag: true,
            },
            {
                id: 'logicList-4',
                content: '(',
                value: '(',
                flag: true,
            },
            {
                id: 'logicList-5',
                content: ')',
                value: ')',
                flag: true,
            },
            {
                id: 'logicList-6',
                content: UdeskLocales.current.components.common.customFilter.and,
                value: '&&',
                flag: false,
            },
            {
                id: 'logicList-7',
                content: UdeskLocales.current.components.common.customFilter.or,
                value: '||',
                flag: false,
            },
            {
                id: 'logicList-8',
                content: UdeskLocales.current.components.common.customFilter.not,
                value: '!',
                flag: false,
            },
        ],
        items: [],
        tarskList: [],
        tarskDel: false,
        conditionListFilter: [],
        itemsCount: 0,
        conditionList: [],
        ruleList: [
            {
                formula: '',
                judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
                // score: '',
                // evaluation: ''
            },
        ],
        operatorMultipleLogicitems: [[]],
        operatorMultipleLogicitemsCount: [0],
        operatorMultipleLogic: [''],
        loading: false
    };

    actions = {
        backToIndex() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('dataPreprocessIndex'),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        nameChanged(value) {
            this.privates.name = value;
            this.actions.update();
        },
        descChanged(value) {
            this.privates.desc = value;
            this.actions.update();
        },
        dataSourceChanged(value) {
            this.privates.dataSource = value;
            this.privates.conditionList = [
                {
                    conditionType: 1, //1话术2交互3特征4信息
                    taskConditionId: null,
                    orderId: 1,
                    operatorType: 11,
                    idCode: 'R1',
                    operatorList: [
                        {
                            applyConditionHitType: 1,
                            applyOperatorScope: {
                                key: 'all',
                                id: 1,
                            },
                            type: 11,
                            operator: 'all',
                            applyRole: 'all',
                        },
                    ],
                    applyConditionId: null,
                    applyConditionIdCode: null,
                    operatorLogic: '1',
                    operator: 'all',
                    applyRole: 'all',
                    applyScope: null,
                    similarityScore: null,
                    differWordNumber: null,
                    wordPerMinite: null,
                    maxWordPerMinite: null,
                    grabIntervalTime: null,
                    grabMaxSpeakTime: null,
                    intervalSecond: null,
                    keyword: '',
                    semanticSentence: null,
                    applyConditionScope: null,
                    applyConditionHitType: 1,
                    // applyOperatorScope: {
                    //     key: 'all',
                    //     id: 1
                    // },
                    param: {},
                },
            ];
            this.privates.ruleList = [
                {
                    formula: '',
                    judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
                    // score: '',
                    // evaluation: ''
                },
            ];
            this.actions.update();
        },
        statusChanged(value) {
            this.privates.status = value;
            this.actions.update();
        },
        customTypeChanged(value) {
            this.privates.ruleSecondaryType = value;
            this.actions.update();
        },
        entityChanged(value) {
            this.privates.entity = value;
            this.actions.update();
        },
        saveDataPreprocess(submitContent) {
            let {
                locales,
                privates: {
                    conditionList,
                    id,
                    operatorList,
                    ruleList,
                    compositeType,
                    operatorLogic,
                    ruleType,
                    ruleSecondaryType,
                    entity,
                },
            } = this;
            let { sdkOptions } = this.props;
            let relateConditionList =
                conditionList.map((item) => {
                    return {
                        idCode: item.idCode,
                        // highlight: 0
                    };
                }) || [];
            ruleList.forEach((item) => {
                if (item.judgeStrategy === 3) {
                    item.formula = operatorLogic;
                }
            });
            let flag = true;
            conditionList.forEach((item) => {
                if (item.operatorType === Udesk.enums.operatorTypes.element.id) {
                    if (
                        !item.operatorList[0].param.mulElementId ||
                        !(item.operatorList[0].param.hit === 0 || item.operatorList[0].param.hit === 1)
                    ) {
                        flag = false;
                    }
                }
                if (item.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id) {
                    if (
                        !item.operatorList[0].param.interactiveRule ||
                        !item.operatorList[0].param.interactiveCategoryId ||
                        !item.operatorList[0].param.interactiveCategoryName
                    ) {
                        flag = false;
                    }
                }
                if (item.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id) {
                    if (
                        !item.operatorList[0].param.operator ||
                        !(item.operatorList[0].param.expectedValueObject || item.operatorList[0].param.expectedValue) ||
                        !item.operatorList[0].param.entity
                    ) {
                        flag = false;
                    }
                }
            });
            if (!flag) {
                Udesk.ui.notify.error(
                    UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.operatorEmpty
                );
                return;
            }
            this.privates.loading = true;
            this.actions.update();
            let params = {
                ruleName: submitContent.name,
                ruleDesc: submitContent.desc,
                conditionList: conditionList,
                ruleSecondaryType: ruleSecondaryType,
                ruleStatus: submitContent.status,
                dataSource: submitContent.dataSource,
                informationEntity: entity,
                compositeType: compositeType,
                operatorList: operatorList,
                // operatorLogic: operatorLogic,
                ruleType: ruleType,
                relateConditionList: relateConditionList,
                ruleList: ruleList,
            };

            if (submitContent && (submitContent.name == null || submitContent.name.trim() === '')) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.components.pages.dataPreprocess.detail.name
                    )
                );
            }
            if (
                ruleType === Udesk.enums.dataPreprocessTypes.custom.id &&
                entity == null &&
                ruleSecondaryType === Udesk.enums.dataSourceCutom.smartTag.id
            ) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.components.pages.dataPreprocess.detail.entity
                    )
                );
            } else if (
                ruleType === Udesk.enums.dataPreprocessTypes.custom.id &&
                ruleSecondaryType === Udesk.enums.dataSourceCutom.scriptTimes.id
            ) {
                params.informationEntity = null;
            }
            let url = Udesk.business.apiPath.concatApiPath(`preprocessRules`, sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            if (id) {
                url = Udesk.business.apiPath.concatApiPath(`preprocessRules/${id}`, sdkOptions);
                method = Udesk.enums.requestMethods.update.name;
                params.id = id;
            }

            Udesk.ajax(url, params, method).then(
                (resp) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locales.components.pages.intelligentTags.title
                        )
                    );
                    this.actions.backToIndex();
                },
                (reason) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveFailedFormat,
                            locales.components.pages.intelligentTags.title
                        )
                    );
                }
            );
        },
        editSemanticTag(id) {
            let routeOptions = {
                history: this.props.history,
                routeName: 'semanticTagsEdit',
                pathParams: {
                    id: id,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        createSemanticTag() {
            let routeOptions = {
                history: this.props.history,
                routeName: 'semanticTagsNew',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        changeCompositeType(value) {
            this.privates.compositeType = value.target.value;
            this.actions.update();
        },
        customJudgeLogicChange(e) {
            let reg = /[\u4e00-\u9fa5]/g;
            this.privates.operatorLogic = e.target.value.replace(reg, '');
            this.privates.items = [];
            this.actions.update();
        },
        resetConditions() {
            this.privates.ruleList = [
                {
                    formula: '',
                    // score: '',
                    // evaluation: ''
                },
            ];
            this.privates.items = [];
            this.privates.itemsCount = 0;
            this.privates.operatorMultipleLogicitems = [[]];
            this.privates.operatorLogic = '';
            this.privates.operatorMultipleLogicitemsCount = [0];
            this.privates.operatorMultipleLogic = [''];
        },
        getConditionList(gearOptionList) {
            this.privates.conditionList = gearOptionList;
            this.actions.getOperatorList(this.privates.conditionList);
        },
        onGradeTypeChange(e) {
            this.actions.resetConditions();
            this.privates.compositeType = e.target.value;
            this.privates.ruleList.forEach((item) => {
                item.judgeStrategy = e.target.value;
            });
            this.privates.conditionList && this.actions.getOperatorList(this.privates.conditionList);
            this.actions.update();
        },
        onDragStart(option) {
            if (option.draggableId.indexOf('drag') !== -1) {
                this.privates.tarskDel = true;
                this.actions.update();
            }
        },
        onDragEnd(result) {
            let { itemsCount } = this.privates;
            if (!result.destination) {
                if (result.draggableId.indexOf('drag') !== -1) {
                    let list = this.privates.items.filter((current) => current.id !== result.draggableId);
                    this.privates.items = list;
                    let str = '';
                    this.privates.items.forEach((item) => {
                        item && (str += item.value);
                    });
                    this.privates.operatorLogic = str;
                    this.privates.tarskDel = false;
                    this.actions.update();
                }
                return;
            }
            if (result.draggableId.indexOf('drag') !== -1) {
                const items = reorder(this.privates.items, result.source.index, result.destination.index);
                let str = '';
                items.forEach((item) => {
                    item && (str += item.value);
                });
                this.privates.operatorLogic = str;
                this.privates.items = items;
                this.privates.tarskDel = false;
                this.actions.update();
            } else {
                if (result.draggableId.indexOf('-') !== 1) {
                    let drag = result.draggableId.split('-')[0];
                    let obj = this.privates[drag].find((current) => current.id === result.draggableId);
                    if (obj) {
                        let data = {
                            id: `drag-${itemsCount + 1}`,
                            content: obj.content,
                            value: obj.value,
                            prevId: result.draggableId,
                            prevClass: drag,
                        };
                        const items = insert(this.privates.items, data, result.destination.index);
                        let str = '';
                        items.forEach((item) => {
                            item && (str += item.value);
                        });
                        this.privates.operatorLogic = str;
                        this.privates.itemsCount = itemsCount + 1;
                        this.privates.items = items;
                        this.actions.update();
                    }
                }
            }
        },
        onDragEndMultiple(result) {
            let { operatorMultipleLogicitemsCount } = this.privates;
            let itemIndex = result.destination && parseInt(result.destination.droppableId.substr(13), 10);
            let itemIndexDelete = result.source && parseInt(result.source.droppableId.substr(13), 10);
            if (!result.destination) {
                if (result.draggableId.indexOf('drag') !== -1) {
                    let list = this.privates.operatorMultipleLogicitems[itemIndexDelete].filter(
                        (current) => current && current.id !== result.draggableId
                    );
                    this.privates.operatorMultipleLogicitems[itemIndexDelete] = list;
                    let str = '';
                    this.privates.operatorMultipleLogicitems[itemIndexDelete].forEach((item) => {
                        item && (str += item.value);
                    });
                    this.privates.ruleList[itemIndexDelete].formula = str;
                    this.privates.tarskDel = false;
                    this.actions.update();
                }
                return;
            }
            if (result.draggableId.indexOf('drag') !== -1) {
                const items = reorder(
                    this.privates.operatorMultipleLogicitems[itemIndex],
                    result.source.index,
                    result.destination.index
                );
                let str = '';
                items.forEach((item) => {
                    item && (str += item.value);
                });
                this.privates.ruleList[itemIndex].formula = str;
                this.privates.operatorMultipleLogicitems[itemIndex] = items;
                this.privates.tarskDel = false;
                this.actions.update();
            } else {
                if (result.draggableId.indexOf('-') !== 1) {
                    let drag = result.draggableId.split('-')[0];
                    let obj = this.privates[drag].find((current) => current.id === result.draggableId);
                    if (obj) {
                        let data = {
                            id: `drag-${itemIndex}-${operatorMultipleLogicitemsCount[itemIndex] + 1}`,
                            content: obj.content,
                            value: obj.value,
                            prevId: result.draggableId,
                            prevClass: drag,
                        };
                        const items = insert(
                            this.privates.operatorMultipleLogicitems[itemIndex],
                            data,
                            result.destination.index
                        );
                        let str = '';
                        items.forEach((item) => {
                            item && (str += item.value);
                        });
                        this.privates.ruleList[itemIndex].formula = str;
                        this.privates.operatorMultipleLogicitemsCount[itemIndex] =
                            operatorMultipleLogicitemsCount[itemIndex] + 1;
                        this.privates.operatorMultipleLogicitems[itemIndex] = items;
                        this.actions.update();
                    }
                }
            }
        },
        getOperatorList(operatorList) {
            this.privates.operatorList = operatorList;
            this.privates.conditionListFilter = operatorList.map((item, index) => {
                return {
                    id: `conditionListFilter-${item.idCode}`,
                    content: item.idCode,
                    value: item.idCode,
                };
            });
            let list = this.privates.items.filter(
                (current) => current.prevId !== `conditionListFilter-${operatorList.length}`
            );
            this.privates.items = list.map((item, index) => {
                return {
                    ...item,
                    id: `drag-${index}`,
                };
            });
            this.actions.update();
        },
    };

    //#region Life Cycle
    init() {
        const company = Udesk.data.init.company;
        if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
            this.privates.dataSource = Udesk.enums.dataPreprocessSourceFlags.all.id;
        } else if (company.enabledCallInspect !== 0) {
            this.privates.dataSource = Udesk.enums.dataPreprocessSourceFlags.voiceCall.id;
        } else if (company.enabledImInspect !== 0) {
            this.privates.dataSource = Udesk.enums.dataPreprocessSourceFlags.textDialogue.id;
        }
    }
    componentDidMount() {
        let dataSourceFlags = Udesk.enums.dataPreprocessSourceFlags;
        dataSourceFlags = dataSourceFlags.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
        this.privates.dataSourceFlags = dataSourceFlags;
        let statusFlags = Udesk.enums.dataPreprocessStatusFlags;
        statusFlags = statusFlags.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
        let customTypeList = Udesk.enums.dataSourceCutom;
        customTypeList = customTypeList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
        this.privates.customTypeList = customTypeList;
        this.privates.statusFlags = statusFlags;
        this.actions.update();
    }
    componentWillUnmount() { }
    //#endregion
}

export default DataPreprocessDetailComponent;
