import React from 'react';
import Udesk from 'Udesk';
import { getSystemModule } from 'Udesk/system/subApp';

const isLocalBiWebService = true; // 是否启用bi服务

class SdkLoaderBaseComponent extends React.Component {
    privates = {
        releaseVersionUrl: '',
        externalAppUrl: '',
        externalAppCssUrl: '',
        udeskBISdkInstance: null,
        sdkLoaderInstance: null,
        fileLoadFailedMessage: '',
        qaRef: React.createRef(),
    };
    init() {
        let domain = this.props.sdkOptions.props.biDomain;
        let releaseVersionUrl = `${domain}/qastar/RELEASE-VERSION`;
        let externalAppUrl = `${domain}/qastar/static/js/udesk-qastar-bi.js`;
        let externalAppCssUrl = `${domain}/qastar/static/css/udesk-qastar-bi.css`;

        // 本地测试
        if (isLocalBiWebService) {
            if (process.env.NODE_ENV === 'development') {
                externalAppUrl = `http://qastar-bi.ud.com/static/js/main.min.js`;
                externalAppCssUrl= `http://qastar-bi.ud.com/static/css/udesk-qastar-bi.css`;
            }
        }

        Object.assign(this.privates, {
            releaseVersionUrl,
            externalAppUrl,
            externalAppCssUrl,
        });
    }

    actions = {
        onCreatedInstance(instance) {
            this.privates.sdkLoaderInstance = instance;
        },
        onDestroyInstance() {
            this.privates.sdkLoaderInstance = null;
        },
        export() {
            let agentExportDateJason = sessionStorage.getItem('agentExportDate');
            let statAt = null;
            let agentExportDate = {};
            if (agentExportDateJason && agentExportDateJason !== 'null') {
                agentExportDate = JSON.parse(agentExportDateJason);
                const { selectedTime = {} } = agentExportDate;
                statAt = [selectedTime.startDate, selectedTime.endDate];
            }
            if (!statAt) {
                return;
            }
            let { sdkOptions, match } = this.props;
            //客服报表导出
            let url = Udesk.business.apiPath.concatApiPath(`exps/agentExport`, sdkOptions);
            // const {
            //     agentNo,
            //     countOperator,
            //     countValue,
            // } = agentExportDate;
            let params = {
                statAt: statAt,
                task_id: `${match.params.taskId}`,
                // agentNo,
                // countOperator,
                // countValue,
                systemModule: getSystemModule(),
                ...agentExportDate,
            };
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(resp.message);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.message);
                }
            );
        },
        getRenderToOptions() {
            let that = this;
            let { releaseVersionUrl } = this.privates;
            let { match } = this.props;
            let id = match.params.id;

            const props = {
                domain: this.props.sdkOptions.props.biDomain,
                reportDomain: this.props.sdkOptions.props.biDomain,
                taskId: this.props.sdkOptions.props.biTaskId,
                helpCenterId: id,
                token: this.props.sdkOptions.props.biToken,
                releaseVersionUrl,
                landingPath: this.props.landingPath,
                language: Udesk.data.init.userLanguage,
                qaReactRef: this.privates.qaRef,
            };
            // 本地测试
            if (isLocalBiWebService) {
                if (process.env.NODE_ENV === 'development') {
                    props.domain = 'http://qastar-bi.ud.com';
                    props.reportDomain = 'http://qastar-bi.ud.com';
                    // props.token = 'DDBB7EAC6977ED86A864E59A3CD696BC'; // 线上环境
                    props.token = '9ABC805875FAC772AA0C80C9256B3D21';
                    // props.token = 'tiandixuanhuangyuzhouhonghuang_5';
                }
            }
            const { company } = Udesk.data.init;
            let renderToOptions = {
                props,
                company,
                methods: {
                    tip: function (text) {},
                    log: getMethodsLog,
                    // transitionTo: function ({ basePath, subPath, pathParams, queryParams }) {
                    //     if (basePath && subPath) {
                    //         that.transitionToRoute(`${basePath}?landingPath=${subPath}`);
                    //     } else if (basePath) {
                    //         if (pathParams == null) {
                    //             pathParams = {};
                    //         }
                    //         if (queryParams == null) {
                    //             queryParams = {};
                    //         }
                    //         that.transitionToRoute(`${basePath}`, ...Object.values(pathParams), { queryParams });
                    //     } else {
                    //         throw new Error("transitionTo params is null in robot/udesk-bi");
                    //     }
                    // },
                    transitionTo: this.props.sdkOptions.methods.transitionTo,
                    buildConditionsUseDefaultSearchTemplate: (params, templateType) => {
                        let defaultSearchTemplate = this.props.sdkOptions.props.defaultSearchTemplate.find(template => `${template.templateType}` === templateType);
                        return defaultSearchTemplate;
                    },
                    transitionToWithConditions: (params, routeName = 'qualityInspectionListIndex') => {
                        let url = Udesk.business.apiPath.concatApiPath(
                            '/search-template/buildSearch',
                            this.props.sdkOptions
                        );
                        return Udesk.ajax
                            .post(url, {
                                type: this.props.sdkOptions.props.task.inspectDataSource,
                                taskId: this.props.sdkOptions.props.task.id,
                                ...params,
                            })
                            .then(
                                (resp) => {
                                    const routeOptions = {
                                        history: this.props.history,
                                        routeName,
                                        pathParams: { taskId: this.props.sdkOptions.props.task.id },
                                        state: {
                                            conditionList: resp.data.map((condition) => {
                                                if (condition.field.fieldType !== 10) {
                                                    return condition;
                                                }
                                                return {
                                                    ...condition,
                                                    field: {
                                                        ...condition.field,
                                                        statusKey: condition.field.id,
                                                    },
                                                };
                                            }),
                                            customJudgeLogic: '',
                                            judgeStrategy: 1,
                                        },
                                    };
                                    Udesk.ui.routing.transitionTo(routeOptions);
                                },
                                (reason) => {
                                    Udesk.ui.notify.error(reason.errorMsg);
                                }
                            );
                    },
                    showBack: (path) => {
                        this.privates.showBack = false;
                        this.privates.showExport = false;
                        if (path.includes('/reports/agent-report/detail/')) {
                            this.privates.showBack = true;
                        }
                        if (path.includes('/reports/agent-report/list')) {
                            this.privates.showExport = true;
                        }
                        if (path.includes('/public-sentiment/analysis/detail')) {
                            this.privates.showBack = true;
                        }
                        this.actions.update();
                    },
                    reloadCallback: function () {
                        let sdkLoaderInstance = that.get('states.sdkLoaderInstance');
                        if (sdkLoaderInstance && typeof sdkLoaderInstance.reload === 'function') {
                            return sdkLoaderInstance.reload();
                        }
                    },
                    checkPermission: (permission) => {
                        const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
                        return hasFeature && hasFeature(permission);
                    },
                },
            };
            return renderToOptions;
        },
        onLoaded(SdkClass) {
            var that = this;
            SdkClass.getUserForSentry = function () {
                return {
                    id: that.props.sdkOptions.props.biUser.id,
                    userName: that.props.sdkOptions.props.biUser.username,
                    realname: that.props.sdkOptions.props.biUser.realname,
                    email: that.props.sdkOptions.props.biUser.email,
                    mobile: that.props.sdkOptions.props.biUser.mobile,
                };
            };
            SdkClass.getTagsContextForSentry = function () {
                return {
                    // agentTotal: Udesk.company.agentTotal,
                    // appKeyStatus: Udesk.company.appKeyStatus,
                    id: that.props.sdkOptions.props.biUser.company.id,
                    logoUrl: that.props.sdkOptions.props.biUser.headPortraitUrl,
                    // name: Udesk.company.name,
                    // remoteType: Udesk.company.remoteType,
                    // robotCount: Udesk.company.robotCount,
                    // systemName: Udesk.company.systemName,
                    // tagUrl: Udesk.company.tagUrl
                };
            };

            let renderToOptions = this.actions.getRenderToOptions();
            let { renderContainerId } = this.privates;

            let udeskBISdkInstance = new SdkClass(renderToOptions);
            let renderSdkContainer = document.getElementById(renderContainerId);
            if (renderSdkContainer) {
                udeskBISdkInstance.renderTo(renderSdkContainer);
                this.privates.udeskBISdkInstance = udeskBISdkInstance;
            }
        },
        onDestroying() {
            let { udeskBISdkInstance } = this.privates;
            if (udeskBISdkInstance && typeof udeskBISdkInstance.destroy === 'function') {
                udeskBISdkInstance.destroy();
                this.privates.udeskBISdkInstance = null;
            }
        }
    };
}

function getMethodsLog(type, text) {
    switch (type) {
        case 'success':
            Udesk.ui.notify.success(`${text}`);
            break;
        case 'warning':
            Udesk.ui.notify.warn(`${text}`);
            break;
        case 'info':
            Udesk.ui.notify.info(`${text}`);
            break;
        case 'danger':
            Udesk.ui.notify.error(`${text}`);
            break;
        default:
            break;
    }
}

export default SdkLoaderBaseComponent;
