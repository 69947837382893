import React from 'react';
import PropTypes from "prop-types";

export default class CustomizeModelErrorComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        componentPrivates: {
            modelError: {
                status: '404'
            }
        },
        backToTasks: null,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        componentPrivates: PropTypes.object,
        backToTasks: PropTypes.func,
    };
    actions = {
        backToTasks() {
            // window.location.href = `${window.location.origin}/site/tasks/index`;
            if(this.props.backToTasks){
                this.props.backToTasks();
            }
        },
        reload() {
            window.location.reload();
        }
    };
    //#region Life Cycle
    componentWillUnmount() {

    }
    //#endregion
}
