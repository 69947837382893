import React, { useImperativeHandle, useMemo } from 'react';
import { getChannelComponent } from './channelNodeTypeMap';
import styled from 'styled-components';
import { getChannelBottomRender } from './channelNodeDefaultBottomRenderMap';
const ItemWrap = styled.div`
    margin-bottom: 24px;
    opacity: ${(props) => props.opacity};
`;
// import Locales from 'UdeskLocales';
/* cover?: string;

customerTags?: IntelligentPartnerTagGroup[];

desc?: string;

flowId?: number;

id?: number;

name?: string; */
export const Item = React.memo(
    React.forwardRef((props: any, ref) => {
        const { bottomRender, ...componentProps } = props;

        useImperativeHandle(ref, () => {
            return {};
        });

        const align = useMemo(() => (props.channel === 2 ? 'right' : 'left'), [props.channel]);
        const Component = useMemo(
            () => getChannelComponent(props.channel, props.nodeType),
            [props.channel, props.nodeType]
        );
        const BottomRenderComponent = useMemo(
            () => (bottomRender ?? getChannelBottomRender)?.(props.nodeType),
            [props.nodeType, bottomRender]
        );
        if (Component)
            return (
                <React.Fragment>
                    <ItemWrap opacity={componentProps.isPreview ? 0.6 : 1}>
                        <Component align={align} {...componentProps} />
                        <BottomRenderComponent align={align} {...componentProps} />
                    </ItemWrap>
                </React.Fragment>
            );
        return null;
    })
);
