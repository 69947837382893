import React from 'react';
// import AudioStickers from '../audio-stickers-talk-v2';
import WaveAudio from 'Component/common/wave-audio-talk-v2';
import SmartTags from '../smart-tags';
import AudioCallBasicInfo from 'Component/pages/components/audio-call-basic-info-v2';
import AudioCallContentInfo from 'Component/pages/components/audio-call-content-info-v2';
import QualityCheckScoreTalk from '../quality-check-score-talk';
import InspectionDetailComponent from './component';
import ChangeList from '../change-list';
import { Modal, Icon, Spin, Alert } from 'udesk-ui';
import './style.scss';
import RelatedRecordsList from '../related-records-list';
import _isEmpty from 'lodash-es/isEmpty';
import _map from 'lodash-es/map';
import ClassNames from 'classnames';
import Udesk from 'Udesk';
// import { DndProvider } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
import { InView } from 'react-intersection-observer';
import Remark from '../inspection-detail-remark';
import UdeskLocales from 'UdeskLocales';
import { createClassName } from 'src/util/core';

const noop = () => {};

export default class InspectionDetailTemplate extends InspectionDetailComponent {
    render() {
        let {
            taskId,
            hasFeature,
            qualityCheckScoreSaving,
            qualityCheckScoreType,
            caseBase,
            id,
            sdkOptions,
            caseBaseStatus,
            testSetList,
            testSetListFlag,
            relatedDatas,
            relatedDatasPaging,
            relatedLoading,
            showQualityCheckScoreTalk,
            dialogRemarkList,
            changeDialogRemarkList,
            isWechatQa = false,
            changeList,
            relatedRecordsLoading,
            loading,
            isInformationCollect = false,
            isAppeal = false,
            isReview = false,
            isTest = false,
            showCheckbox = false,
            onComplainPointListChange,
            complaintHistory,
            appealSdkOptions = {},
            refershHistory,
            refreshList,
            resetComplainPointIdMap,
            renderHeader,
            onMessageItemCheckboxClick,
            trainRecords,
            isDebug,
            indexPointTag,
            audioCallBasicInfoWidth,
            isTimelyScene,
            timelySceneList,
            isTaskChanged,
        } = this.props;
        let {
            detail,
            smartTags,
            // changeList,
            waveAudioOptions,
            semanticContent,
            highlightSeqList,
            // highlightContent,
            audioCallInfoRef,
            qcsRef,
            keywords,
            computes,
            visible,
            labelRef,
            treeDataList,
            treeDataRef,
            labelParentModal,
            labelActiveModal,
            hitData,
            dropChed,
            heightList,
            MarkFlag,
            pointChildrenComputer,
            pointChildrenPerson,
            checkedMap,
            // pointRef,
            // basicInfoRef,
            pointWidth,
            detailRef,
            resetStatus,
            highlightGroupList,
            timeValue,
            waveAudio,
            // spinLoading,
            talkAudioFlag,
            canRemarkEdit,
            showBaseInfo,
            autoStepToNext,
            currentHighLightPointOrSmartKey,
            allPoints,
            remarkRef,
        } = this.privates;
        let { actions, locales } = this;
        let isRelated = sdkOptions.props?.task?.related ?? false;
        return (
            <div className="qa-react-inspection-detail-page" ref={detailRef}>
                <Choose>
                    <When condition={detail}>
                        {!!detail.isHideText && (
                            <Alert
                                message={
                                    /* 该数据已被定期清除 */ UdeskLocales['current'].components
                                        .inspectionDetail.template.theDataHasBeenClearedPeriodically
                                }
                                type="info"
                                showIcon
                                style={{ marginBottom: 8 }}
                            />
                        )}
                        <If condition={detail.voiceUrl && !isTest}>
                            {/* <AudioStickers voiceUrl={detail?.voiceUrl??''} dialogSentenceList={detail?.dialogRecords?.dialogSentenceList??[]} voiceLength={detail?.voiceLength} /> */}
                            <WaveAudio
                                isInspectionDetail={true}
                                reverse={actions.reverse}
                                recover={actions.recover}
                                onWaveAudio={actions.onWaveAudio}
                                timeChaged={actions.timeChaged}
                                detail={detail}
                                src={detail.voiceUrl}
                                options={waveAudioOptions}
                                ref={(instance) => {
                                    this.privates.waveAudioInstance = instance;
                                }}
                                isRelated={Boolean(isRelated)}
                                // ===重构加入===
                                onManualStartOrStop={actions.onManualStartOrStop}
                                isAppeal={isAppeal}
                                onAudioRateChange={actions.onAudioRateChange}
                                defaultAudioRate={this.privates.audioRate}
                            />
                        </If>
                        {!isInformationCollect && !isAppeal && !isReview && !isTest && (
                            <SmartTags
                                disabled={this.props.disabledSmartTagsChange}
                                smartTags={smartTags || []}
                                smartTagsTitle={
                                    locales.components.pages.appealChekingTask.list.smartTags
                                }
                                treeListVisible={actions.treeListVisible}
                                callId={this.privates.detail.callId}
                                onSmartTagsChange={actions.onSmartTagsChange}
                                onRedoSmartTags={this.props.onRedoSmartTags}
                            />
                        )}

                        {/* <If condition={highlightContent !== ''}>
                            <Tag style={{ marginTop: '10px' }}>{highlightContent}</Tag>
                        </If> */}
                        {/* <DndProvider backend={HTML5Backend}> */}
                        <div
                            className={ClassNames('qa-react-inspection-detail-content', {
                                'width-audio': detail.voiceUrl && !isTest,
                                'width-smart': smartTags && smartTags.length > 0,
                                'width-all': detail.voiceUrl && smartTags && smartTags.length > 0,
                            })}
                        >
                            {!isTest && (
                                <div
                                    className="qa-react-inspection-detail-basic-info" /* ref={basicInfoRef} */
                                >
                                    <AudioCallBasicInfo
                                        isWechatQa={isWechatQa}
                                        testSetListFlag={testSetListFlag}
                                        // key={detail.id}
                                        // taskId={taskId}
                                        detail={detail}
                                        // content={semanticContent}
                                        resizeWidthChange={actions.resizeWidthChange}
                                        width={
                                            audioCallBasicInfoWidth ||
                                            (Udesk?.data?.init?.userLanguage === 'en' ? 400 : 265)
                                        }
                                        isBusinessRecordsVisible={true}
                                        inspectDataSource={computes._inspectDataSource}
                                        visible={showBaseInfo}
                                        changeBaseInfoOnOff={actions.changeBaseInfoOnOff}
                                        changeBaseInfoOff={actions.changeBaseInfoOff}
                                        changeBaseInfoOn={actions.changeBaseInfoOn}
                                        isTimelyScene={isTimelyScene}
                                        timelySceneList={timelySceneList} // 触发场景
                                    />
                                </div>
                            )}
                            <div
                                className="qa-react-inspection-detail-content-info"
                                ref={audioCallInfoRef}
                            >
                                <AudioCallContentInfo
                                    ref={this.privates.audioCallContentInfoRef}
                                    rerenderOptimization={true}
                                    changeToHighlightItemsCallDetail={
                                        isRelated
                                            ? this.props.changeToHighlightItemsCallDetail
                                            : noop
                                    }
                                    isWechatQa={isWechatQa}
                                    canRemarkEdit={canRemarkEdit}
                                    changeDialogRemarkList={changeDialogRemarkList}
                                    dialogRemarkList={dialogRemarkList}
                                    talkAudioFlag={talkAudioFlag}
                                    onAudioStop={actions.onAudioStop}
                                    audioPlay={actions.onAudioPlay}
                                    waveAudio={waveAudio}
                                    onAudioPause={actions.onAudioPause}
                                    timeValue={timeValue}
                                    highlightGroupList={highlightGroupList}
                                    testSetListFlag={testSetListFlag}
                                    key={detail.id}
                                    checkedProps={actions.checkedProps}
                                    pointChildrenComputer={pointChildrenComputer}
                                    pointChildrenPerson={pointChildrenPerson}
                                    MarkFlag={MarkFlag}
                                    heightList={heightList}
                                    dropChecked={actions.dropChecked}
                                    sentenceList={
                                        detail &&
                                        detail.dialogRecords &&
                                        detail.dialogRecords.dialogSentenceList
                                    }
                                    keywords={keywords}
                                    qcsRef={qcsRef}
                                    caseBaseStatus={caseBaseStatus}
                                    sdkOptions={sdkOptions}
                                    onUpdate={actions.onUpdate}
                                    caseLibraryId={this.props.caseLibraryId}
                                    caseBaseFlagRight={this.privates.caseBaseFlagRight}
                                    caseBaseUnRemrks={actions.caseBaseUnRemrks}
                                    detail={detail}
                                    caseBase={caseBase}
                                    id={id}
                                    highlightSeqList={highlightSeqList}
                                    inspectDataSource={computes._inspectDataSource}
                                    // 工单质检 test
                                    // inspectDataSource={10}
                                    isBusinessRecordsVisible={true}
                                    onTimeRangeChanged={actions.sendCurrentTimeRange}
                                    onMarkSemanticClick={actions.markSemantic}
                                    onCloseHitClick={actions.closeHit}
                                    // ===重构加入===
                                    changeBaseInfoOnOff={actions.changeBaseInfoOnOff}
                                    showBaseInfo={showBaseInfo}
                                    virtual={
                                        !isAppeal &&
                                        computes._inspectDataSource ===
                                            Udesk.enums.inspectDataSources.voiceCall.id
                                    }
                                    autoStepToNext={autoStepToNext}
                                    smartTags={smartTags} //用于组织高亮数据
                                    currentHighLightPointOrSmartKey={
                                        currentHighLightPointOrSmartKey
                                    }
                                    closeHighLight={actions.closeHighLight}
                                    remark={
                                        this.privates.caseBaseFlag
                                            ? this.privates.remark
                                            : detail.remark
                                    }
                                    loading={loading}
                                    isInformationCollect={isInformationCollect}
                                    isAppeal={isAppeal}
                                    isTest={isTest}
                                    showCheckbox={showCheckbox}
                                    renderHeader={renderHeader}
                                    onMessageItemCheckboxClick={onMessageItemCheckboxClick}
                                    trainRecords={trainRecords}
                                    isDebug={isDebug}
                                    indexPointTag={indexPointTag}
                                />
                            </div>
                            <div
                                /* ref={pointRef} */ className={createClassName({
                                    'qa-react-inspection-detail-content-point': true,
                                    'qa-react-inspection-detail-content-extraction-point':
                                        this.privates.isProblemExtraction,
                                })}
                            >
                                <If condition={showQualityCheckScoreTalk}>
                                    <QualityCheckScoreTalk
                                        // ===质检点得分===
                                        isWechatQa={isWechatQa}
                                        isDemo={this.props.isDemo}
                                        evaluateChange={actions.evaluateChange}
                                        audioStop={actions.onAudioStop}
                                        audioPlay={actions.onAudioPlay}
                                        resetStatus={resetStatus}
                                        key={detail.id}
                                        pointWidth={pointWidth}
                                        checkedMap={checkedMap}
                                        pointChildrenPerson={pointChildrenPerson}
                                        handMarkMap={actions.handMarkMap}
                                        heightHit={actions.heightHit}
                                        dropChed={dropChed}
                                        testSetList={testSetList}
                                        testSetListFlag={testSetListFlag}
                                        detail={detail}
                                        ref={qcsRef}
                                        caseBaseRemark={this.privates.caseBaseRemark}
                                        caseBaseFlag={this.privates.caseBaseFlag}
                                        type={qualityCheckScoreType}
                                        saving={qualityCheckScoreSaving}
                                        task={detail}
                                        hasFeature={hasFeature}
                                        pointCategoryList={detail.pointCategoryList || []}
                                        onHitCheckPointSelected={actions.hitCheckPointSelected}
                                        onCancel={actions.qualityCheckScoreCanceled}
                                        onSave={actions.qualityCheckScoreSaved}
                                        onSubmit={actions.qualityCheckScoreSubmitted}
                                        onRapidSampling={actions.qualityCheckScoreRapidSampling}
                                        // ===语义标签===
                                        content={semanticContent}
                                        taskId={taskId}
                                        // ===智能标签===
                                        // disabled={this.props.disabledSmartTagsChange}
                                        // smartTags={smartTags || []}
                                        // smartTagsTitle={locales.components.pages.appealChekingTask.list.smartTags}
                                        // treeListVisible={actions.treeListVisible}
                                        callId={this.privates.detail.callId}
                                        // onSmartTagsChange={actions.onSmartTagsChange}
                                        // ===重构加入===
                                        triggerHighLight={actions.triggerHighLight}
                                        isInformationCollect={isInformationCollect}
                                        showAppealButton={isAppeal}
                                        isTest={isTest}
                                        isReview={isReview}
                                        onComplainPointListChange={onComplainPointListChange}
                                        complaintHistory={complaintHistory}
                                        appealSdkOptions={appealSdkOptions}
                                        refershHistory={refershHistory}
                                        refreshList={refreshList}
                                        resetComplainPointIdMap={resetComplainPointIdMap}
                                        onShowHitChange={this.props.onShowHitChange}
                                        showHit={this.props.showHit}
                                        pointRemarksMap={this.privates.pointRemarksMap}
                                        onChangeActiveTab={actions.onChangeActiveTab}
                                        isTaskChanged={isTaskChanged}
                                    />
                                </If>
                            </div>
                            {/* <div ref={audioCallInfoRef} className="qa-react-inspection-detail-content-left-part col-xs-12 col-md-5 col-lg-5">
                                <AudioCallInfo keywords={keywords} qcsRef={qcsRef} caseBaseStatus={caseBaseStatus} sdkOptions={sdkOptions} onUpdate={actions.onUpdate} caseLibraryId={this.props.caseLibraryId} caseBaseFlagRight={this.privates.caseBaseFlagRight} caseBaseUnRemrks={actions.caseBaseUnRemrks} detail={detail} caseBase={caseBase} id={id} highlightSeqList={highlightSeqList} inspectDataSource={computes._inspectDataSource} isBusinessRecordsVisible={true} onTimeRangeChanged={actions.sendCurrentTimeRange} onMarkSemanticClick={actions.markSemantic} onCloseHitClick={actions.closeHit} />
                            </div>
                            <div className='qa-react-inspection-detail-content-info' ref={audioCallInfoRef}>
                                <AudioCallContentInfo
                                    isWechatQa={isWechatQa}
                                    canRemarkEdit={canRemarkEdit}
                                    changeDialogRemarkList={changeDialogRemarkList}
                                    dialogRemarkList={dialogRemarkList}
                                    talkAudioFlag={talkAudioFlag}
                                    onAudioStop={actions.onAudioStop}
                                    audioPlay={actions.onAudioPlay}
                                    waveAudio={waveAudio}
                                    onAudioPause={actions.onAudioPause}
                                    timeValue={timeValue}
                                    highlightGroupList={highlightGroupList}
                                    testSetListFlag={testSetListFlag}
                                    // key={detail.id}
                                    checkedProps={actions.checkedProps}
                                    pointChildrenComputer={pointChildrenComputer}
                                    pointChildrenPerson={pointChildrenPerson}
                                    MarkFlag={MarkFlag}
                                    heightList={heightList}
                                    dropChecked={actions.dropChecked}
                                    sentenceList={
                                        detail && detail.dialogRecords && detail.dialogRecords.dialogSentenceList
                                    }
                                    keywords={keywords}
                                    qcsRef={qcsRef}
                                    caseBaseStatus={caseBaseStatus}
                                    sdkOptions={sdkOptions}
                                    onUpdate={actions.onUpdate}
                                    caseLibraryId={this.props.caseLibraryId}
                                    caseBaseFlagRight={this.privates.caseBaseFlagRight}
                                    caseBaseUnRemrks={actions.caseBaseUnRemrks}
                                    detail={detail}
                                    caseBase={caseBase}
                                    id={id}
                                    highlightSeqList={highlightSeqList}
                                    inspectDataSource={computes._inspectDataSource}
                                    isBusinessRecordsVisible={true}
                                    onTimeRangeChanged={actions.sendCurrentTimeRange}
                                    onMarkSemanticClick={actions.markSemantic}
                                    onCloseHitClick={actions.closeHit}
                                    rerenderOptimization={true}
                                />
                            </div>
                            <div ref={pointRef} className='qa-react-inspection-detail-content-point'>
                                <If condition={showQualityCheckScoreTalk}>
                                    <QualityCheckScoreTalk
                                        isWechatQa={isWechatQa}
                                        isDemo={this.props.isDemo}
                                        evaluateChange={actions.evaluateChange}
                                        audioStop={actions.onAudioStop}
                                        audioPlay={actions.onAudioPlay}
                                        resetStatus={resetStatus}
                                        key={detail.id}
                                        pointWidth={pointWidth}
                                        checkedMap={checkedMap}
                                        pointChildrenPerson={pointChildrenPerson}
                                        handMarkMap={actions.handMarkMap}
                                        heightHit={actions.heightHit}
                                        dropChed={dropChed}
                                        testSetList={testSetList}
                                        testSetListFlag={testSetListFlag}
                                        detail={detail}
                                        ref={qcsRef}
                                        caseBaseRemark={this.privates.caseBaseRemark}
                                        caseBaseFlag={this.privates.caseBaseFlag}
                                        type={qualityCheckScoreType}
                                        saving={qualityCheckScoreSaving}
                                        task={detail}
                                        hasFeature={hasFeature}
                                        pointCategoryList={detail.pointCategoryList || []}
                                        onHitCheckPointSelected={actions.hitCheckPointSelected}
                                        onCancel={actions.qualityCheckScoreCanceled}
                                        onSave={actions.qualityCheckScoreSaved}
                                        onSubmit={actions.qualityCheckScoreSubmitted}
                                        onRapidSampling={actions.qualityCheckScoreRapidSampling}
                                    />
                                </If>
                            </div>
                            {/* <div ref={audioCallInfoRef} className="qa-react-inspection-detail-content-left-part col-xs-12 col-md-5 col-lg-5">
                            <AudioCallInfo keywords={keywords} qcsRef={qcsRef} caseBaseStatus={caseBaseStatus} sdkOptions={sdkOptions} onUpdate={actions.onUpdate} caseLibraryId={this.props.caseLibraryId} caseBaseFlagRight={this.privates.caseBaseFlagRight} caseBaseUnRemrks={actions.caseBaseUnRemrks} detail={detail} caseBase={caseBase} id={id} highlightSeqList={highlightSeqList} inspectDataSource={computes._inspectDataSource} isBusinessRecordsVisible={true} onTimeRangeChanged={actions.sendCurrentTimeRange} onMarkSemanticClick={actions.markSemantic} onCloseHitClick={actions.closeHit} />
                        </div>
                        <div className="qa-react-inspection-detail-content-right-part col-xs-12 col-md-7 col-lg-7">
                            <QualityCheckScore testSetList={testSetList} testSetListFlag={testSetListFlag} detail={detail} ref={qcsRef} caseBaseRemark={this.privates.caseBaseRemark} caseBaseFlag={this.privates.caseBaseFlag} type={qualityCheckScoreType} saving={qualityCheckScoreSaving} task={detail} hasFeature={hasFeature} pointCategoryList={detail.pointCategoryList} onHitCheckPointSelected={actions.hitCheckPointSelected} onCancel={actions.qualityCheckScoreCanceled} onSave={actions.qualityCheckScoreSaved} onSubmit={actions.qualityCheckScoreSubmitted} onRapidSampling={actions.qualityCheckScoreRapidSampling} />
                        </div> */}
                        </div>
                        {/* </DndProvider> */}
                        {!isTest && (
                            <div className="qa-react-inspection-detail-footer">
                                {!isInformationCollect && (
                                    <React.Fragment>
                                        <h2 style={{ fontWeight: 'bold' }}>
                                            {
                                                locales.components.pages.components
                                                    .qualityCheckScore.manualRemark
                                            }
                                        </h2>
                                        <Remark
                                            key={this.props.detail?.id}
                                            value={
                                                this.privates.caseBaseFlag
                                                    ? this.privates.remark
                                                    : detail.richRemark
                                            }
                                            onChange={actions.changeTaskRemark}
                                            onlyView={isAppeal}
                                            pointsList={allPoints}
                                            ref={remarkRef}
                                            // loading={this.privates.isRemarkLoading || loading}
                                            callId={detail.callId}
                                        />
                                    </React.Fragment>
                                )}
                                {!isAppeal && !isInformationCollect && (
                                    <React.Fragment>
                                        <div>
                                            <h2 style={{ fontWeight: 'bold' }}>
                                                {
                                                    locales.components.pages.appealChekingTask
                                                        .components.relatedRecord.title
                                                }
                                            </h2>
                                        </div>
                                        <InView
                                            as="div"
                                            onChange={actions.onScrollToRelatedRecords}
                                        >
                                            {relatedDatas && !_isEmpty(relatedDatas) ? (
                                                <div>
                                                    <RelatedRecordsList
                                                        relatedDatas={relatedDatas}
                                                        relatedDatasPaging={relatedDatasPaging}
                                                        parentLoadMore={actions.parentLoadMore}
                                                        relatedRecordsFieldSee={
                                                            actions.onRelatedRecordsFieldSeed
                                                        }
                                                        selectedDatum={detail && detail.callId}
                                                    />
                                                </div>
                                            ) : (
                                                <Spin spinning={relatedRecordsLoading}>
                                                    {relatedRecordsLoading ? (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: '30px',
                                                            }}
                                                        ></div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: '30px',
                                                                textAlign: 'center',
                                                                lineHeight: '30px',
                                                            }}
                                                        >
                                                            {locales.labels.dataNo}
                                                        </div>
                                                    )}
                                                </Spin>
                                            )}
                                            <ChangeList
                                                list={changeList}
                                                pointCategoryList={detail.pointCategoryList || []}
                                                caseBase={caseBase}
                                                loading={relatedLoading}
                                            />
                                        </InView>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </When>
                    <Otherwise>
                        <div className="qa-react-inspection-detail-page-no-value">
                            {locales.labels.noValue}
                        </div>
                    </Otherwise>
                </Choose>
                {/* <SemanticMark show={semanticMarkShow} content={semanticContent} taskId={taskId} onClose={actions.markSemanticClose} /> */}
                <Modal
                    title={this.locales.labels.editType}
                    visible={visible}
                    onCancel={actions.modalCancel}
                    onOk={actions.modalOk}
                >
                    <div className="label-tabs-list-page" ref={labelRef}>
                        {!_isEmpty(treeDataList) &&
                            _map(treeDataList, (item, index) => {
                                if (item) {
                                    return (
                                        <div
                                            className="label-tabs-list-page-field"
                                            key={index}
                                            style={{ width: '190px' }}
                                            ref={treeDataRef[`tree-${index}`]}
                                            onMouseUp={actions.fieldUp.params(labelRef)}
                                        >
                                            <div
                                                className="label-field-target-drag"
                                                onMouseDown={actions.treeDrag.params(
                                                    treeDataRef[`tree-${index}`],
                                                    labelRef
                                                )}
                                            >
                                                <Icon type="ic-pause-zanting" />
                                            </div>
                                            <div className="label-tabs-list-page-field-drag">
                                                {!_isEmpty(item) &&
                                                    item.map((items) => {
                                                        return (
                                                            <div
                                                                className={ClassNames(
                                                                    'label-field-info',
                                                                    {
                                                                        'label-field-info-active-parent':
                                                                            labelParentModal[
                                                                                items.tagLevel
                                                                            ] === items.id ||
                                                                            hitData[
                                                                                items.tagLevel
                                                                            ] === items.id,
                                                                    },
                                                                    {
                                                                        'label-field-info-active':
                                                                            items.id ===
                                                                            labelActiveModal,
                                                                    }
                                                                )}
                                                                key={items.id}
                                                                onClick={actions.activesLabelModal.params(
                                                                    items.id,
                                                                    items.tagLevel,
                                                                    items.leafFlag
                                                                )}
                                                            >
                                                                <span>{items.tagName}</span>
                                                                {items.leafFlag !== 1 && (
                                                                    <Icon type="ic-play-line" />
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return;
                                }
                            })}
                    </div>
                </Modal>
            </div>
        );
    }
}
