// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetCallWorkTimesByIdParam,
    BaseResponseCallWorkTimeFoundResponse,
    PutCallWorkTimesByIdParam,
    PutCallWorkTimesByIdBody,
    BaseResponseVoid,
    DeleteCallWorkTimesByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 业务工作时间
 * @link [GET] /callWorkTimes/{id}
 * @param id
 */
export function getCallWorkTimesById(
    options: ApiOptions<GetCallWorkTimesByIdParam, never> & { segments: GetCallWorkTimesByIdParam }
): Promise<BaseResponseCallWorkTimeFoundResponse> {
    return request<never, GetCallWorkTimesByIdParam, never>('/callWorkTimes/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCallWorkTimesById',
    });
}

/**
 * update
 *
 * @export
 * @tags 业务工作时间
 * @link [PUT] /callWorkTimes/{id}
 * @param id
 */
export function putCallWorkTimesById(
    data: PutCallWorkTimesByIdBody,
    options: ApiOptions<PutCallWorkTimesByIdParam, never> & { segments: PutCallWorkTimesByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutCallWorkTimesByIdBody, PutCallWorkTimesByIdParam, never>(
        '/callWorkTimes/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putCallWorkTimesById' }
    );
}

/**
 * deleteById
 *
 * @export
 * @tags 业务工作时间
 * @link [DELETE] /callWorkTimes/{id}
 * @param id
 */
export function deleteCallWorkTimesById(
    options: ApiOptions<DeleteCallWorkTimesByIdParam, never> & {
        segments: DeleteCallWorkTimesByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteCallWorkTimesByIdParam, never>('/callWorkTimes/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteCallWorkTimesById',
    });
}

export const meta = [
    { tags: ['业务工作时间'], path: '/callWorkTimes/{id}', method: 'get' },
    { tags: ['业务工作时间'], path: '/callWorkTimes/{id}', method: 'put' },
    { tags: ['业务工作时间'], path: '/callWorkTimes/{id}', method: 'delete' },
];
