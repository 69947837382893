// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostInspectionHomePageQueryAgentDownBody,
    BaseResponseHomePageAgentDownResponse,
} from '../../types';

/**
 * queryAgentDown
 *
 * @export
 * @tags inspection-home-page-controller
 * @link [POST] /inspectionHomePage/queryAgentDown
 */
export function postInspectionHomePageQueryAgentDown(
    data: PostInspectionHomePageQueryAgentDownBody
): Promise<BaseResponseHomePageAgentDownResponse>;
export function postInspectionHomePageQueryAgentDown(
    data: PostInspectionHomePageQueryAgentDownBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseHomePageAgentDownResponse>;
export function postInspectionHomePageQueryAgentDown(
    data: PostInspectionHomePageQueryAgentDownBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseHomePageAgentDownResponse> {
    return request<PostInspectionHomePageQueryAgentDownBody, never, never>(
        '/inspectionHomePage/queryAgentDown',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postInspectionHomePageQueryAgentDown',
        }
    );
}

export const meta = [
    {
        tags: ['inspection-home-page-controller'],
        path: '/inspectionHomePage/queryAgentDown',
        method: 'post',
    },
];
