// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetIntelligentPartnerAiAnalysisPromptSentenceFindAllQuery,
    BaseResponseListIntelligentPartnerPromptSentenceFoundResponse,
} from '../../../../../../types';

/**
 * 查询提示语句
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [GET] /intelligentPartner/ai/analysis/prompt/sentence/findAll
 */
export function getIntelligentPartnerAiAnalysisPromptSentenceFindAll(): Promise<BaseResponseListIntelligentPartnerPromptSentenceFoundResponse>;
export function getIntelligentPartnerAiAnalysisPromptSentenceFindAll(
    options: ApiOptions<never, GetIntelligentPartnerAiAnalysisPromptSentenceFindAllQuery>
): Promise<BaseResponseListIntelligentPartnerPromptSentenceFoundResponse>;
export function getIntelligentPartnerAiAnalysisPromptSentenceFindAll(
    options?: ApiOptions<never, GetIntelligentPartnerAiAnalysisPromptSentenceFindAllQuery>
): Promise<BaseResponseListIntelligentPartnerPromptSentenceFoundResponse> {
    return request<never, never, GetIntelligentPartnerAiAnalysisPromptSentenceFindAllQuery>(
        '/intelligentPartner/ai/analysis/prompt/sentence/findAll',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerAiAnalysisPromptSentenceFindAll',
        }
    );
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/prompt/sentence/findAll',
        method: 'get',
    },
];
