import React, { useEffect, useState } from 'react';
// import Udesk from 'Udesk';
import { getLibraryFiles } from 'src/api/libraryFiles';
import { LibraryFileFoundResponse } from 'src/api/types';
import {
    Modal,
    Form,
    TreeSelect,
    Input,
    Row,
    Col,
    Space,
    Switch,
    TimePicker,
    Icon,
    Slider,
} from 'udesk-ui';
import Locales from 'UdeskLocales';
import moment from 'moment';
import { formatDuration } from 'src/util/date';

const { TextArea } = Input;
const locales = Locales['current'];
const format = 'HH:mm:ss';

const getMomentByMillisecond = (millisecond: number) => {
    return moment.utc(millisecond);
};

const getSeconds = (time) => {
    if (time) {
        const list = time.toArray();
        if (list.length === 7) {
            return list[3] * 60 * 60 + list[4] * 60 + list[5];
        }
    }
    return 0;
};

interface TreeData extends LibraryFileFoundResponse {
    title?: string;
    key?: number;
    children?: TreeData[];
    selectedKeys?: number[];
    expandedKeys?: number[];
}

function ModalAddFile(props) {
    const { voiceUrl, voiceLength = 0, isAddCaseBaseShow } = props;
    const [form] = Form.useForm();
    const [isAddCaseBase, setIsAddCaseBase] = useState(false);
    const [treeData, setTreeData] = useState<TreeData[]>([]);
    const [rangTime, setRangTime] = useState<[any, any] | null>(null);
    const [rangTimeVal, setRangTimeVal] = useState<number[]>([0, 0]);
    const [isOpen, setIsOpen] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [audio] = useState(new Audio(normalizeAudioSrc(voiceUrl)));
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 20,
            },
        },
    };
    const treeDataList = () => {
        getLibraryFiles().then((resq) => {
            let getTreeDataList = transformArrayToTree(resq.data ?? []);
            setTreeData(getTreeDataList);
        });
    };
    // 树结构数据处理
    const transformArrayToTree = (arr: LibraryFileFoundResponse[] = []): TreeData[] => {
        if (!arr || !arr.length) return [];
        const sortedArr = arr
            .map((i: any) => ({
                ...i,
                title: i.name,
                key: i.id,
                parentId: i.parentId * 1,
            }))
            .sort((a, b) => a.parentId - b.parentId);
        let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
        let nodeMap = {};
        sortedArr.forEach((item) => {
            nodeMap[item.id] = item;
            if (nodeMap[item.parentId]) {
                if (Array.isArray(nodeMap[item.parentId].children)) {
                    nodeMap[item.parentId].children.push(item);
                } else {
                    nodeMap[item.parentId].children = [item];
                }
            }
        });
        return [...root];
    };
    const handleOk = () => {
        form.validateFields().then((values) => {
            props.handlerModalOk && props.handlerModalOk(values, rangTimeVal, isOpen);
            audio && audio.pause();
        });
    };
    const handleCancel = () => {
        form.resetFields();
        setIsAddCaseBase(false);
        props.handlerModalCancel && props.handlerModalCancel();
        audio && audio.pause();
    };
    const handlerSwitchChange = (checked) => {
        setIsOpen(checked);
    };

    const handlerSelectTime = (value) => {
        setRangTime(value);
        if (value) {
            setRangTimeVal([getSeconds(value[0]), getSeconds(value[1])]);
        } else {
            setRangTimeVal([0, 0]);
        }
    };
    const handlerChange = (value) => {
        setRangTimeVal(value);
        setRangTime([
            getMomentByMillisecond(value[0] * 1000),
            getMomentByMillisecond(value[1] * 1000),
        ]);
    };

    const audioPlay = () => {
        const status = audio.paused && !isPlay;
        if (status) {
            audio.currentTime = rangTimeVal[0];
            audio.play();
        } else {
            audio.pause();
        }
        setIsPlay(status);

        audio.onpause = (e) => {
            setIsPlay(false);
        };
        audio.ontimeupdate = function (event) {
            if (audio.currentTime >= rangTimeVal[1]) {
                setIsPlay(false);
                audio.pause();
            }
        };
    };

    useEffect(() => {
        setIsAddCaseBase(isAddCaseBaseShow);
    }, [isAddCaseBaseShow]);

    useEffect(() => {
        setRangTimeVal([0, voiceLength]);
        if (voiceLength) {
            setRangTime([getMomentByMillisecond(0), getMomentByMillisecond(voiceLength * 1000)]);
        }
        treeDataList();
    }, []);

    return (
        <Modal
            title={locales.components.gong.filesOpen.modalAddFile.addToCaseBase}
            visible={isAddCaseBase}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form form={form} {...formItemLayout}>
                <Form.Item
                    required
                    name="name"
                    label={/* 案例标题 */ locales.components.gong.filesOpen.modalAddFile.caseTitle}
                >
                    <Input showCount={true} maxCount={100} maxLength={100} />
                </Form.Item>
                <Form.Item
                    name="fileId"
                    label={locales.components.gong.filesOpen.modalAddFile.storedIn}
                    rules={[
                        {
                            required: true,
                            message: locales.components.gong.filesOpen.modalAddFile.pleaseSelect,
                        },
                    ]}
                >
                    <TreeSelect
                        showSearch
                        treeData={treeData}
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={locales.components.gong.filesOpen.modalAddFile.pleaseSelect}
                        allowClear
                        treeDefaultExpandAll
                        treeNodeFilterProp="name"
                    ></TreeSelect>
                </Form.Item>
                <Form.Item
                    name="recommendReason"
                    label={locales.components.gong.filesOpen.modalAddFile.reasonsForRecommendation}
                >
                    <TextArea
                        showCount={true}
                        maxCount={100}
                        maxLength={100}
                        placeholder={locales.components.gong.filesOpen.modalAddFile.pleaseEnter}
                        rows={4}
                    />
                </Form.Item>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={12}>
                        <Space>
                            <Switch checked={isOpen} onChange={handlerSwitchChange} />
                            <span>
                                {
                                    locales.components.gong.filesOpen.modalAddFile
                                        .addSomeClipsToTheCaseLibrary
                                }
                            </span>
                        </Space>
                    </Col>
                    {isOpen && (
                        <React.Fragment>
                            <Col span={12}>
                                <TimePicker.RangePicker
                                    value={rangTime}
                                    onChange={handlerSelectTime}
                                    format={format}
                                />
                            </Col>
                            <Col span={24}>
                                <div className="component-share-modal-rang-time-container">
                                    <div className="select-rang-time">
                                        <div style={{ cursor: 'pointer' }} onClick={audioPlay}>
                                            {isPlay ? (
                                                <Icon
                                                    type="PauseCircleOutlined"
                                                    style={{ fontSize: 20, color: '#1A6EFF' }}
                                                    antdIcon={true}
                                                ></Icon>
                                            ) : (
                                                <Icon
                                                    type="PlayCircleOutlined"
                                                    style={{ fontSize: 20, color: '#1A6EFF' }}
                                                    antdIcon={true}
                                                ></Icon>
                                            )}
                                        </div>
                                        <Slider
                                            value={[rangTimeVal[0], rangTimeVal[1]]}
                                            min={0}
                                            max={parseInt(voiceLength)}
                                            onChange={handlerChange}
                                            tooltipVisible={false}
                                            className="slider-rang"
                                            range
                                        />
                                    </div>
                                    <div className="slider-rang-time">
                                        {/* {rangTime[0]}/{rangTime[1]} */}
                                        {formatDuration(rangTimeVal[1] - rangTimeVal[0])}/
                                        {formatDuration(voiceLength)}
                                    </div>
                                </div>
                            </Col>
                        </React.Fragment>
                    )}
                </Row>
            </Form>
        </Modal>
    );
}

export default ModalAddFile;

function normalizeAudioSrc(src) {
    if (src == null) {
        return src;
    }
    const protocolRegExp = /^http:\/\/(.+?)$/i;
    if (window.location.protocol === 'https:' && protocolRegExp.test(src)) {
        src = src.replace(protocolRegExp, 'https://$1');
    }
    let domainRegExp = /^(http[s]?):\/\/ccrecords\.alioss\.udesk\.cn(.+?)$/i;
    if (domainRegExp.test(src)) {
        src = src.replace(domainRegExp, '$1://pro-ccrecords.oss-cn-hangzhou.aliyuncs.com$2');
    }
    return src;
}
