import React from 'react';
import { Button, Switch, Space, Icon } from 'udesk-ui';
import Locales from 'UdeskLocales';

const noop = () => {};

function Card(props) {
    const locales = Locales['current'];

    const { showContent, title, cardId, switchProps, canEdit, onSave = noop, cardBodyStyle, tip } = props;
    return (
        <div className='tasks-automatic-add-card' id={cardId}>
            <div className='tasks-automatic-add-card-header'>
                <Space align='center'>
                    <div className='tasks-automatic-add-card-header-title'>{title}</div>
                    {switchProps && <Switch size='small' {...switchProps} />}
                    {tip && (
                        <div style={{ color: 'rgba(0, 0, 0, .45)', display: 'flex', alignItems: 'center' }}>
                            <Icon type='InfoCircleTwoTone' antdIcon={true} style={{marginRight: 4}} />
                            <div>{Array.isArray(tip) ? tip.map((i) => <div>{i}</div>) : tip}</div>
                        </div>
                    )}
                </Space>
            </div>
            {showContent && (
                <React.Fragment>
                    <div className='tasks-automatic-add-card-body' style={cardBodyStyle}>
                        <div className='automatic-content-item-body'>
                            <div className='automatic-content-item-body-item'>
                                <div className='automatic-content-item-body-item-content'>{props.children}</div>
                            </div>
                        </div>
                    </div>
                    {canEdit && (
                        <div className='tasks-automatic-add-card-footer'>
                            <Button type='primary' onClick={onSave}>
                                {locales.labels.save}
                            </Button>
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

export default React.memo(Card);
