import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'udesk-ui';

const TitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
`;

type TitleProps = {
    onButtonClick: () => void;
    disabled?: boolean;
    label?: string;
    btnText?: string;
};

export const Title: FC<TitleProps> = React.memo((props) => {
    const { onButtonClick, disabled, label, btnText } = props;

    return (
        <TitleWrap className="udesk-coach-web-scoring-rules-title">
            <div>{label}</div>
            <Button size="small" type="link" onClick={onButtonClick} disabled={disabled}>
                {btnText}
            </Button>
        </TitleWrap>
    );
});
