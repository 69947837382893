import Udesk from 'Udesk';

export function trans(pamars, type) {
    localStorage.setItem(
        'maualAddToManage_pamars',
        JSON.stringify({
            judgeStrategy: 1,
            customJudgeLogic: '',
            keyword: '',
            keywordType: null,
            ...pamars,
        })
    );
    setTimeout(() => {
        if (type === Udesk.enums.inspectDataSources.voiceCall.id) {
            window.open('/site/call-manage/index');
        }
        if (type === Udesk.enums.inspectDataSources.textDialogue.id) {
            window.open('/site/dialogue-manage/index');
        }
        if (type === Udesk.enums.inspectDataSources.ticket.id) {
            window.open('/site/ticket-manage/index');
        }
        if (type === Udesk.enums.inspectDataSources.wechatDialogue.id) {
            window.open('/site/wechat-qa/chart-record/index');
        }
        if (type === Udesk.enums.inspectDataSources.taobao.id) {
            window.open('/site/e-commerce-manage/index');
        }
    });
}

export default {
    trans,
};
