import React, { useState, useEffect } from 'react';
// import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Alert } from 'udesk-ui';
import { getSampleSampleTargetById } from 'src/api/sample/sampleTarget/{id}';

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const { id, taskId, sourceType } = props;
    const [data, setData] = useState<any>(null);
    useEffect(() => {
        getSampleSampleTargetById({
            segments: {
                id,
            },
            params: {
                taskId,
                sourceType,
            },
        }).then((resp) => {
            setData(resp.data);
        });
    }, [id]);
    if (!data || !data.showFlag) return null;
    return (
        <Alert
            message={`${/* 客服 */locales.components.samplingTargetBanner.index.customerService}${data.agentName}：${getCycleName(data.sampleTargetConf?.cycleType)}${/* 完成 */locales.components.samplingTargetBanner.index.complete}${
                data.completedNum ?? '-'
            }${/* / 目标 */locales.components.samplingTargetBanner.index.Objective}${data.sampleTargetConf?.targetNum ?? '-'}`}
            banner
            type={(data.completedNum ?? 0) >= (data.sampleTargetConf?.targetNum ?? 0) ? 'success' : 'warning'}
        />
    );
});
export default React.memo(Template);

function getCycleName(type) {
    const locales = Locales['current'];

    if (type === 'day') return /* 每日 */locales.components.samplingTargetBanner.index.everyday;
    if (type === 'week') return /* 每周 */locales.components.samplingTargetBanner.index.weekly;
    if (type === 'month') return /* 每月 */locales.components.samplingTargetBanner.index.monthly;
    return '';
}
