import { useCallback, useEffect, useState } from "react";
import { fire } from "../core";

export const useTableBtn = (className) => {
    const [resetCount, setResetCount] = useState(0);
    const [submitCount, setSubmitCount] = useState(0);

    const clickSubmitBtn = useCallback(() => {
        $(`.${className} .udesk-qa-ui-common-filter .udesk-qa-ui-btn-primary`).trigger('click');
    }, [className]);

    const clickResetBtn = useCallback((callback = undefined) => {
        $(`.${className} .udesk-qa-ui-common-filter .udesk-qa-ui-btn-default`).trigger('click', true);
        fire(callback);
    }, [className]);

    const submitFilterForm = useCallback(() => {
        setResetCount(count => count + 1);
    }, []);

    useEffect(() => {
        if (submitCount > 0) {
            clickSubmitBtn();
        }
    }, [clickSubmitBtn, submitCount]);

    useEffect(() => {
        if (resetCount > 0) {
            clickResetBtn(() => {
                setSubmitCount(count => count + 1);
            });
        }
    }, [clickResetBtn, resetCount]);

    return {
        submitFilterForm,
    };
};

export const getDeletedPagination = (pagination, delNum = 1) => {
    let {
        current,
        pageSize, 
        total
    } = pagination;

    total -= delNum;

    if (total <=0) {
        current = 1;
    } else {
        current = Math.min(Math.ceil(total / pageSize), current);
    }
    
    return {
        ...pagination, current, pageSize, total,
    };
};