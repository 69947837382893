// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { DeleteIntelligentExtractTasksByIdParam, BaseResponseVoid } from '../../types';

/**
 * 删除抽取任务
 *
 * @export
 * @tags 智能抽取接口
 * @link [DELETE] /intelligentExtractTasks/{id}
 * @param id
 */
export function deleteIntelligentExtractTasksById(
    options: ApiOptions<DeleteIntelligentExtractTasksByIdParam, never> & {
        segments: DeleteIntelligentExtractTasksByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentExtractTasksByIdParam, never>(
        '/intelligentExtractTasks/{id}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteIntelligentExtractTasksById' }
    );
}

export const meta = [
    { tags: ['智能抽取接口'], path: '/intelligentExtractTasks/{id}', method: 'delete' },
];
