import ConceptWordComponent from './component';
import Udesk from 'Udesk';

class ConceptWordRoute extends ConceptWordComponent {
    asyncModel(changedParams) {

        let { sdkOptions } = this.props;
        let paramsCopy = {
            keyword: this.privates.searchInputForSearch,
            pageNum: this.privates.pageNum,
            pageSize: this.privates.pageSize,
            sort: this.privates.sort,
            asc: this.privates.asc,
            type: this.privates.moduleType,
        };

        if (changedParams != null) {
            Object.assign(paramsCopy, changedParams);
        }
        this.privates.loading = true;
        this.actions.update();
        let url = Udesk.business.apiPath.concatApiPath(`conceptWords/findByKeyWord`, sdkOptions);
        Udesk.ajax.post(url, paramsCopy).then(
            resp => {
                this.privates.tableData = [];
                resp.data.forEach(item => {
                    let tabelData = {
                        ...item,
                        key: item.id,
                    };
                    this.privates.tableData.push(tabelData);
                });
                this.privates.total = resp.paging.total;
                this.privates.loading = false;
                this.actions.update();
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }
}

export default ConceptWordRoute;
