import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import FilterStatistics from '../common/filter-statistics';
import FilterScreen from '../common/filter-screen';
import { Layout } from 'udesk-ui';
import { fire } from 'src/util/core';
import './style.scss';
import { postReviewCallSearch } from 'src/api/review/call/search';
import Locales from 'UdeskLocales';
import { getSystemModule } from 'Udesk/system/subApp';
import { postReviewRecheckSession } from 'src/api/review/recheck/session';

const { Sider, Content } = Layout;

const Template = React.memo((props: any) => {
    const locales = Locales['current'];

    const { sdkOptions, history } = props;
    const [collapsed, setCollapsed] = useState(false);
    const [filterFormValues, setFilterFormValues] = useState<any>(
        props.location.state?.filterFormValues ?? {}
    );
    const [currentFilterId, setCurrentFilterId] = useState(null);
    const [defaultFilter, setDefaultFilter] = useState<any>([]);
    const [page, setPage] = useState(
        props.location?.state?.page ?? {
            current: 1,
            pageSize: 10,
            total: 0,
        }
    );
    // =================================================
    const [listData, setListData] = useState<any[]>([]);
    const [defaultOrders, setDefaultOrders] = useState<any>(
        props.location?.state?.defaultOrders ?? {
            ordersType: 2,
            key: 'time',
        }
    );

    const [tablePanelList, setTablePanelList] = useState(() => {
        let res = [
            {
                label: /* 时间 */ locales.pages.gong.common.filterItem.time,
                key: 'time',
            },
            {
                label: /* 通话时长 */ locales.pages.gong.common.filterItem.talkTime,
                key: 'call',
            },
            {
                label: /* 关键事件 */ locales.pages.gong.common.filterItem.keyEvents,
                key: 'key_event',
            },
            {
                label: /* 监督项总执行率 */ locales.pages.gong.common.filterItem
                    .totalImplementationRateOfSupervisionItems,
                key: 'superversion_rate',
            },
        ].map((i) => ({
            ...i,
            ordersType: defaultOrders.key === i.key ? defaultOrders.ordersType : 0,
        }));
        return res;
    });
    // tabs切换
    const [tabToggle, setTabToggle] = useState(props.location?.state?.tabToggle ?? 'time');
    const [loading, setLoading] = useState(false);

    const siderAction = (
        <div className="layout-menu-pages-sider-actions-arrow">
            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-zhankaitixing"></i>
            {!collapsed ? (
                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-left-s-line"></i>
            ) : (
                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-youjiantou"></i>
            )}
        </div>
    );
    const siderActionsChange = (collapsed, type) => {
        setCollapsed(collapsed);
    };
    // const handlerInputSearch = (values) => {
    //     setFilterFormValues(values);
    // };
    const onSearch = (formValues, currentFilterId) => {
        if (
            formValues.call_time &&
            formValues.call_time.length &&
            !formValues.call_time[0] &&
            !formValues.call_time[1]
        ) {
            formValues.call_time = null;
        }

        setFilterFormValues(formValues);
        setCurrentFilterId(currentFilterId);
        let pageData = {
            ...page,
            current: 1,
        };
        callRewordList(
            getSearchCondition(formValues, defaultFilter),
            pageData,
            tabToggle,
            defaultOrders
        );
    };

    const jumpToPage = (item, page) => {
        const routeOptions = {
            history,
            routeName: 'gongCallDetail',
            pathParams: { callId: item.dataId },
            state: {
                type: 'list',
                filterFormValues: filterFormValues,
                page,
                defaultOrders,
                tabToggle,
                keyword: filterFormValues?.keyword,
                role: filterFormValues?.keywordType,
                keywordType: filterFormValues?.keywordType,
                channelType: filterFormValues?.channelType ?? 0,
                currentFilterId,
                studyStatue: filterFormValues?.studyStatue,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    // =================================================
    const getSearchCondition = (filterFormValues: any, defaultFilter: any) => {
        if (!defaultFilter?.length) return {};
        return {
            judgeStrategy: 1,
            keyword: filterFormValues?.keyword,
            keywordType: filterFormValues?.keywordType ?? -1,
            customJudgeLogic: '',
            conditionList: formatConditions(filterFormValues, defaultFilter),
            currentFilter: currentFilterId,
            channelType: filterFormValues?.channelType ?? 0,
            studyStatue: filterFormValues?.studyStatue,
        };
    };
    // const searchCondition = useMemo(() => {
    //     if (!defaultFilter?.length) return {};
    //     return {
    //         judgeStrategy: 1,
    //         keyword: filterFormValues?.keyword,
    //         keywordType: filterFormValues?.keywordType,
    //         customJudgeLogic: '',
    //         conditionList: formatConditions(filterFormValues, defaultFilter),
    //         currentFilter: currentFilterId,
    //         channelType: filterFormValues?.channelType ?? 0,
    //         studyStatue: filterFormValues?.studyStatue,
    //     };
    // }, [formatConditions, filterFormValues, defaultFilter, currentFilterId]);

    const onPageChange = (page) => {
        setPage(page);
        callRewordList(
            getSearchCondition(filterFormValues, defaultFilter),
            page,
            tabToggle,
            defaultOrders
        );
    };

    const handlerTabs = (type) => {
        setTabToggle(type);
        // setPreTabType(type);
        // const currentType = tablePanelList.length >= 0 && tablePanelList.find((item) => item.key === type);
        let orders: any = {};
        const newTablePanelList = tablePanelList.map((i) => {
            if (i.key === type) {
                orders = {
                    ...i,
                    ordersType: (i.ordersType % 2) + 1,
                };
                return orders;
            }
            return {
                ...i,
                ordersType: 0,
            };
        });
        setDefaultOrders(orders);
        setTablePanelList(newTablePanelList);
        let pageData = {
            ...page,
            current: 1,
        };
        setPage(pageData);
        callRewordList(getSearchCondition(filterFormValues, defaultFilter), pageData, type, orders);
    };

    const getCallRewordListParams = (
        searchCondition,
        pageData,
        type = tabToggle,
        orders,
        _defaultFilter = defaultFilter
    ) => {
        let ordersList: any = [];
        if (type === 'time') {
            _defaultFilter?.forEach((item) => {
                if (item.name === 'call_time' || item.name === 'start_time') {
                    ordersList.push({
                        id: item.id,
                        label: item.label,
                        fieldName: item.fieldName,
                        ordersType: orders.ordersType,
                    });
                }
            });
        } else if (type === 'call') {
            _defaultFilter?.forEach((item) => {
                if (item.name === 'call_duration' || item.name === 'total_time') {
                    ordersList.push({
                        id: item.id,
                        label: item.label,
                        fieldName: item.fieldName,
                        ordersType: orders.ordersType,
                    });
                }
            });
        } else {
            ordersList.push({
                fieldName: orders.key,
                ordersType: orders.ordersType,
            });
            // defaultFilter?.forEach((item) => {
            // if (item.name === orders.key) {
            //     ordersList.push({
            //         id: item.id,
            //         label: item.label,
            //         fieldName: item.fieldName,
            //         ordersType: orders.ordersType,
            //     });
            // }
            // });
        }
        return {
            ...searchCondition,
            ordersList,
            pageNum: pageData.current,
            pageSize: pageData.pageSize,
        };
    };
    // 查看更多
    const handlerViewMore = (record, ev) => {
        let newListData = listData.map((item) => {
            if (item.dataId === record.dataId) {
                item.isMoreData = true;
            }
            return item;
        });
        setListData(newListData);
        ev.stopPropagation();
    };
    // 通话记录列表
    const callRewordList = (
        searchCondition,
        pageData,
        type = tabToggle,
        orders,
        defaultFilter?
    ) => {
        setLoading(true);

        let params = getCallRewordListParams(
            searchCondition,
            pageData,
            type,
            orders,
            defaultFilter
        );
        // setFilterFormValues(params);
        postReviewCallSearch(params).then(
            (resp) => {
                let listDataKey = {};
                let listData: any = [];
                resp.data?.showFieldList?.forEach((item) => {
                    listDataKey[item.id!] = item.name;
                });

                resp.data?.fieldDataList?.forEach((item, index) => {
                    let data = {
                        ...item,
                        dataId: item.dataId,
                        keywords: item.keywords,
                        isMoreData: false,
                        chanelType: item.channelType,
                    };
                    for (let i in item.fieldValueMap) {
                        let key = listDataKey[i];
                        data[key] = item.fieldValueMap[i];
                    }

                    listData.push(data);
                });
                setLoading(false);
                setListData(listData);
                if (resp.paging) {
                    setPage({
                        current: resp.paging.pageNum,
                        pageSize: resp.paging.pageSize,
                        total: resp.paging.total,
                    });
                }
            },
            (reason) => {
                setLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const onReInspect = (checkRuleList) => {
        const params = getCallRewordListParams(
            Object.assign(
                { systemModule: getSystemModule() },
                getSearchCondition(filterFormValues, defaultFilter)
            ),
            {},
            tabToggle,
            defaultOrders
        );
        postReviewRecheckSession(
            {
                ...params,
                total: page.total,
                checkRuleList,
            },
            {
                successMsg: /* 开始重新检测 */ locales.pages.gong.common.filterItem.startRetesting,
            }
        );
    };

    useEffect(() => {
        // 获取默认过滤条件
        const getCompanyMemberData = () => {
            let url = Udesk.business.apiPath.concatApiPath(
                `field/data/search-template`,
                props.sdkOptions
            );
            const params = {
                sourceList: ['9'],
                typeList: ['1', '5', '11'],
            };
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    let result = [
                        ...resp.data[0].typeList[0].fieldList,
                        ...resp.data[0].typeList[2].fieldList,
                    ];
                    setDefaultFilter(result);
                    let condition = getSearchCondition(filterFormValues, result);

                    if (!(JSON.stringify(condition) === '{}')) {
                        callRewordList(condition, page, tabToggle, defaultOrders, result);
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        };

        getCompanyMemberData();
    }, []);

    return (
        <Page
            pageClassName="page-qa-call-anslysis"
            pageBodyClassName="page-qa-call-anslysis-body"
            padding={true}
        >
            <div className="page-qa-analysis-call-list-container">
                <Layout style={{ height: '100%' }}>
                    <Sider
                        className="layout-menu-pages-sider"
                        collapsed={collapsed}
                        collapsedWidth={1}
                        collapsible
                        onCollapse={siderActionsChange}
                        width="368px"
                        style={{ background: '#fff' }}
                        trigger={siderAction}
                    >
                        <FilterScreen
                            onSearch={onSearch}
                            // handlerInputSearch={handlerInputSearch}
                            sdkOptions={sdkOptions}
                            defaultFilter={defaultFilter}
                            formatConditions={formatConditions}
                            stateData={props.location?.state}
                        ></FilterScreen>
                    </Sider>
                    <Content style={{ background: '#fff' }}>
                        <div className="page-qa-analysis-call-list-content">
                            <FilterStatistics
                                // history={history}
                                // sdkOptions={sdkOptions}
                                // filterFormValues={filterFormValues}
                                // currentFilterId={currentFilterId}
                                // defaultFilter={defaultFilter}
                                // formatConditions={formatConditions}
                                onPageChange={onPageChange}
                                // defaultPage={page}
                                // defaultTimes={props.location?.state?.defaultTimes}
                                // defaultDurtion={props.location?.state?.defaultDurtion}
                                // defaultOrders={}
                                jumpToPage={jumpToPage}
                                // stateData={props.location?.state}

                                // callRewordList={callRewordList}
                                loading={loading}
                                handlerViewMore={handlerViewMore}
                                listData={listData}
                                handlerTabs={handlerTabs}
                                tablePanelList={tablePanelList}
                                onReInspect={onReInspect}
                                page={page}
                            ></FilterStatistics>
                        </div>
                    </Content>
                </Layout>
            </div>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

function formatConditions(values, defaultFilter) {
    let conditionList: any = [];
    let pushItem = (types: string[], operator, value, item) => {
        if (types.includes(item.name)) {
            conditionList.push({
                operator: fire(operator),
                value: fire(value),
                field: {
                    id: item.id,
                    label: item.label,
                    dataSourceType: item.inspectDataSourceType,
                    fieldName: item.fieldName,
                    fieldType: item.fieldType,
                    name: item.name,
                },
            });
        }
    };
    defaultFilter.forEach((item) => {
        pushItem(
            CALL_DURATION,
            () => {
                let operator = 'between';
                let value = values?.call_duration;
                let [start, end] = value || [];
                if (!start || !end) {
                    if (start) {
                        operator = 'greater_than_eq';
                    }
                    if (end) {
                        operator = 'less_than_eq';
                    }
                }
                return operator;
            },
            () => {
                let value = values?.call_duration;
                let [start, end] = value || [];
                if (!start || !end) {
                    if (start) {
                        value = start;
                    }
                    if (end) {
                        value = end;
                    }
                    if (!start && !end) {
                        value = null;
                    }
                }
                if (start && end) {
                    if (end === 0 || start === end) {
                        value = null;
                    }
                }
                return value;
            },
            item
        );
        pushItem(CALL_ID, 'is', values?.call_id ?? null, item);
        pushItem(
            CALL_TIME,
            'between',
            values?.call_time &&
                values.call_time.length &&
                !values.call_time[0] &&
                !values.call_time[1]
                ? null
                : values?.call_time ?? null,
            item
        );
        pushItem(AGENT, 'is_any', values?.agent ?? null, item);
        pushItem(CUSTOMER_NICK_NAME, 'is', values?.customer_nick_name ?? null, item);
        pushItem(SMART_TAGS, 'is_any', values?.smart_tags ?? null, item);
        pushItem(TOPICS, 'is_any', values?.topics ?? null, item);
        pushItem(TOPIC_TAGS, 'is_any', values?.topic_tags ?? null, item);
        pushItem(KEY_EVENTS, 'is_any', values?.key_events ?? null, item);
    });
    // return conditionList.filter(i => i.value !== null);
    return conditionList;
}

export const CALL_DURATION = ['call_duration', 'total_time'];
export const CALL_ID = ['conversation_id', 'call_id', 'recordId'];
export const CALL_TIME = ['call_time', 'start_time', 'recordTime'];
export const AGENT = ['agent'];
export const CUSTOMER_NICK_NAME = ['customer_nick_name'];
export const SMART_TAGS = ['smart_tags'];
export const TOPICS = ['topics'];
export const TOPIC_TAGS = ['topic_tags'];
export const KEY_EVENTS = ['key_events'];
export const CHANNEL = ['channel'];
