import React from 'react';
import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import LayoutMenu from 'Component/common/layout-menu';
import { Redirect } from 'react-router-dom';

class TasksManageEntry extends React.Component {
    isRedirect(currentPath, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: this.props.match.params,
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let { route, location, sdkOptions, match } = this.props;

        let {
            locales,
            // privates,
        } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        // const task = sdkOptions.props.task;
        // const hasFeature = task ? task._hasFeature : null;
        // let taskId = privates.model.taskId;
        navLists.push({
            text: locales.pages.wechatQa.conversationSetting.title,
            pathName: 'wechatQaConversationSetting',
            // pathParams: { taskId },
            isActive: (match, location) => {
                return location.pathname.includes('/wechat-qa/setting/conversation-setting/index');
            },
        });
        navLists.push({
            text: locales.pages.wechatQa.checkPoint.title,
            pathName: 'wechatQaCheckPointSetting',
            // pathParams: { taskId },
            isActive: (match, location) => {
                return location.pathname.includes('/wechat-qa/setting/check-point');
            },
        });
        navLists.push({
            text: locales.pages.wechatQa.message.title,
            pathName: 'wechatQaMessagePushSetting',
            // pathParams: { taskId },
            isActive: (match, location) => {
                return location.pathname.includes('/wechat-qa/setting/message');
            },
        });
        navLists.push({
            text: locales.pages.wechatQa.realTimeAnalysis.title,
            pathName: 'wechatQaRealTimeAnalysis',
            // pathParams: { taskId },
            isActive: (match, location) => {
                return location.pathname.includes('/wechat-qa/setting/real-time-analysis');
            },
        });
        navLists.push({
            text: locales.pages.wechatQa.smartCustomerTag.title,
            pathName: 'wechatQaCustomerLabel',
            // pathParams: { taskId },
            isActive: (match, location) => {
                return location.pathname.includes('/wechat-qa/setting/customer-tag');
            },
        });

        navLists.push({
            text: /* 消息过滤 */UdeskLocales['current'].pages.wechatQa.wechatQaSettingEntry.messageFiltering,
            pathName: 'wechatQaMessageFiltering',
            // pathParams: { taskId },
            isActive: (match, location) => {
                return location.pathname.includes('/wechat-qa/setting/msg-filtering');
            },
        });

        if (this.isRedirect(route, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else if (navLists.length > 0 && this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].children[0].pathName,
                pathParams: match.params,
            });
            return <Redirect to={toPath} sdkOptions={sdkOptions} />;
        } else {
            return (
                <div className="tasks-manage-entry">
                    <LayoutMenu
                        {...this.props}
                        navLists={navLists}
                        menuTitle={locales.components.tasks.subMenu.wechatQa.setting.text}
                    />
                </div>
            );
        }
    }
    model() {
        const { props } = this;

        let associateFields = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/field/data/5', props.sdkOptions);
            Udesk.ajax.get(url, { type: 'associate' }).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.screeningCondition
                            )
                    );
                    reject(reason);
                }
            );
        });

        return {
            associateFields: associateFields,
        };
    }
    onModelResolved(model) {
        if (model.associateFields) {
            this.props.sdkOptions.props.basicInfoFields = model.associateFields;
        }
    }
    componentWillUnmount() {
        // 清理掉相关数据，否则会污染其他页面
        this.props.sdkOptions.props.biTaskId = null;
        this.props.sdkOptions.props.task = null;
        this.props.sdkOptions.props.basicInfoFields = null;
    }
}

export default Udesk.react.udeskify(TasksManageEntry);
