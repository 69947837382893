import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Input, Space } from 'udesk-ui';
import { SmartRemarkConfigObj } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

type IntelligentReviewProps = {
    value?: SmartRemarkConfigObj;
    onChange?: (v: SmartRemarkConfigObj) => void;
    disabled?: boolean;
    isAiGenCourse?: boolean;
};

type InnerValue = {
    enableSingle?: boolean;
    enableWhole?: boolean;
    remarkStandard?: string;
    enableRealTimeReview?: boolean;
};

const IntelligentReview: FC<IntelligentReviewProps> = (props) => {
    const { value, onChange, disabled, isAiGenCourse } = props;

    const [innerValue, setInnerValue] = useState<InnerValue>();

    useEffect(() => {
        setInnerValue(fallback(value));
    }, [value]);

    const onWholeChange = (checked: boolean) => {
        const v = { ...innerValue, enableWhole: checked };
        setInnerValue(v);
        onChange?.(transform(v));
    };

    const onRemarkChange = (s: string) => {
        const v = { ...innerValue, remarkStandard: s };
        setInnerValue(v);
        onChange?.(transform(v));
    };

    const onSingleChange = (checked: boolean) => {
        const v = { ...innerValue, enableSingle: checked };
        setInnerValue(v);
        onChange?.(transform(v));
    };

    const onRTChange = (checked: boolean) => {
        const v = { ...innerValue, enableRealTimeReview: checked };
        setInnerValue(v);
        onChange?.(transform(v));
    };

    return (
        <Space align="start">
            <Space direction="vertical">
                <Checkbox
                    checked={innerValue?.enableWhole}
                    onChange={(e) => onWholeChange(e.target.checked)}
                    disabled={disabled}
                >{/* 整体点评 */}{UdeskLocales['current'].pages.coach.taskCenter.components.intelligentReview.index.overallReview}</Checkbox>

                {innerValue?.enableWhole ? (
                    <Input.TextArea
                        rows={3}
                        style={{ width: 200 }}
                        value={innerValue.remarkStandard}
                        onChange={(e) => onRemarkChange(e.target.value)}
                        placeholder={/* 请输入点评标准（非必填） */UdeskLocales['current'].pages.coach.taskCenter.components.intelligentReview.index.pleaseEnterTheReviewCriterianotMandatory}
                        disabled={disabled}
                    />
                ) : null}
            </Space>

            <Checkbox
                checked={innerValue?.enableSingle}
                onChange={(e) => onSingleChange(e.target.checked)}
                disabled={disabled}
            >{/* 单句话术点评 */}{UdeskLocales['current'].pages.coach.taskCenter.components.intelligentReview.index.commentaryOnSingleSentenceRhetoric}</Checkbox>

            {isAiGenCourse ? (
                <Checkbox
                    checked={innerValue?.enableRealTimeReview}
                    onChange={(e) => onRTChange(e.target.checked)}
                    disabled={disabled}
                >{/* 实时点评 */}{UdeskLocales['current'].pages.coach.taskCenter.components.intelligentReview.index.realTimeReview}</Checkbox>
            ) : null}
        </Space>
    );
};

const transform = (value?: InnerValue): SmartRemarkConfigObj => {
    return {
        enableSingle: value?.enableSingle ? 1 : 0,
        enableWhole: value?.enableWhole ? 1 : 0,
        remarkStandard: value?.remarkStandard,
        enableRealTimeReview: value?.enableRealTimeReview ? 1 : 0,
    };
};

const fallback = (value?: SmartRemarkConfigObj): InnerValue => {
    return {
        enableSingle: value?.enableSingle === 1,
        enableWhole: value?.enableWhole === 1,
        remarkStandard: value?.remarkStandard,
        enableRealTimeReview: value?.enableRealTimeReview === 1,
    };
};

export default IntelligentReview;
