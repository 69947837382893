import {
    objectPath as ExternalObjectPath,
    mergeDeep as ExternalMergeDeep,
    clone as ExternalClone,
    deepFreeze as ExternalDeepFreeze,
} from '../ref-npm-modules';

//Other useful libraries: node-deep-equal(npm: deep-diff), deep-object-diff, deep-equal, fast-deep-equal, fast-equals

export default {
    get,
    set,
    empty,
    del,
    deepCopy,
    shallowCopy,
    clone,
    deepFreeze,
    isInheritedBy
};
export {
    get,
    gets,
    set,
    sets,
    empty,
    del,
    deepCopy,
    shallowCopy,
    clone,
    deepFreeze,
    isInheritedBy
};

function get(obj, fieldName) {
    if (obj && Object.prototype.hasOwnProperty.call(obj, fieldName)) {
        return obj[fieldName];
    }
    return ExternalObjectPath.get(obj, fieldName);
}

function gets(obj, ...fieldNames) {
    let results = {};
    for (let name of fieldNames) {
        results[name] = get(obj, name);
    }
    return results;
}

function set(obj, fieldName, value) {
    return ExternalObjectPath.set(obj, fieldName, value);
}

function sets(obj, payload) {
    if (payload) {
        for (let key of Object.keys(payload)) {
            set(obj, key, payload[key]);
        }
    }
}

function empty(obj, fieldName) {
    return ExternalObjectPath.empty(obj, fieldName);
}

function del(obj, fieldName) {
    return ExternalObjectPath.del(obj, fieldName);
}

function deepCopy(obj1, obj2) {
    return ExternalMergeDeep.apply(null, arguments);
}

function shallowCopy(obj1, obj2) {
    let baseValue = {};
    if (Array.isArray(obj1)) {
        baseValue = [];
    }
    return Object.assign(baseValue, ...arguments);
}

function clone(obj, circular, depth, prototype, includeNonEnumerable) {
    return ExternalClone.apply(null, arguments);
}

function deepFreeze(obj) {
    return ExternalDeepFreeze(obj);
}

function isInheritedBy(childClass, baseClass) {
    let result = false;
    let proto = childClass != null ? Object.getPrototypeOf(childClass) : null;
    while (childClass && baseClass && proto && typeof proto === "function" && typeof baseClass === "function") {
        if (proto === baseClass) {
            result = true;
            break;
        } else if (proto === Function.prototype) {
            result = false;
            break;
        }
        childClass = proto;
        proto = Object.getPrototypeOf(childClass);
    }
    return result;
}
