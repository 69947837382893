import React, { useMemo } from 'react';
import UdeskList from 'Component/common/udesk-list';
import { FormBuilder, Input, Select, Space, Spin } from 'udesk-ui';
import styled from 'styled-components';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

const ConditionList = (props) => {
    const value = useMemo(() => {
        return props.value[0];
    }, [props.value]);
    const fieldNameOptions = useMemo(() => {
        return [props.filedConditions[props.inspectDataSource]].map((i) => ({
            ...i,
            value: i.fieldName,
        }));
    }, [props.inspectDataSource, props.filedConditions]);
    const depOptions = useMemo(() => {
        return props.filedConditions[props.inspectDataSource].optionValueList;
    }, [props.inspectDataSource, props.filedConditions]);
    return (
        <Space>
            <Select
                options={fieldNameOptions}
                value={value.field.fieldName}
                style={{ width: 200 }}
                onChange={(v) => {
                    props.onChange([
                        {
                            ...value,
                            field: fieldNameOptions.find((i) => i.value === v),
                        },
                    ]);
                }}
            ></Select>
            <Select
                value={value.operator}
                style={{ width: 200 }}
                onChange={(v) => {
                    props.onChange([
                        {
                            ...value,
                            operator: v,
                        },
                    ]);
                }}
                options={
                    value.field.fieldType === 1
                        ? [
                              {
                                  label: /* 等于 */ UdeskLocales['current'].pages.generalSetting
                                      .docking.components.ruleList.beEqualTo,
                                  value: 'is',
                              },
                          ]
                        : [
                              {
                                  label: /* 包含 */ UdeskLocales['current'].pages.generalSetting
                                      .docking.components.ruleList.contain,
                                  value: 'is_any',
                              },
                              {
                                  label: /* 不包含 */ UdeskLocales['current'].pages.generalSetting
                                      .docking.components.ruleList.doesNotContain,
                                  value: 'not_any',
                              },
                          ]
                }
            ></Select>
            {value.field.fieldType === 1 && (
                <Input
                    value={value.value}
                    style={{ width: 200 }}
                    onChange={(e) => {
                        props.onChange([
                            {
                                ...value,
                                value: e.target.value,
                            },
                        ]);
                    }}
                />
            )}
            {value.field.fieldType === 4 && (
                <Select
                    value={value.value.split(',').filter(Boolean)}
                    options={depOptions.map((i) => ({
                        label: i.name,
                        value: i.id,
                    }))}
                    style={{ width: 200 }}
                    mode="multiple"
                    onChange={(v) => {
                        props.onChange([
                            {
                                ...value,
                                value: v.join(','),
                            },
                        ]);
                    }}
                ></Select>
            )}
        </Space>
    );
};

const RuleItemWrap = styled.div`
    background: rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    line-height: 22px;
    max-width: 900px;
`;

const RuleItem = (props) => {
    return (
        <RuleItemWrap>
            <FormBuilder
                customComponents={{ ConditionList }}
                initialValues={props.item}
                footerRender={false}
                onValuesChange={(changedValues, values) => {
                    props.onChange({
                        ...props.item,
                        ...changedValues,
                    });
                }}
                fields={[
                    {
                        type: 'Select',
                        name: 'appType',
                        label: /* 应用系统 */ UdeskLocales['current'].pages.generalSetting.docking
                            .components.ruleList.applicationSystem,
                        props: {
                            style: {
                                width: 200,
                            },
                            options: [
                                {
                                    label: /* 智能质检/智能会话分析 */ UdeskLocales['current'].pages
                                        .generalSetting.docking.components.ruleList
                                        .intelligentQualityInspection,
                                    value: 'QA',
                                },
                                {
                                    label: /* 销售赋能 */ UdeskLocales['current'].pages
                                        .generalSetting.docking.components.ruleList
                                        .salesEmpowerment,
                                    value: 'SALES',
                                },
                            ],
                            placeholder: UdeskLocales['current'].labels.pleaseSelect,
                        },
                    },
                    {
                        type: 'Select',
                        name: 'inspectDataSource',
                        label: /* 数据源类型 */ UdeskLocales['current'].pages.generalSetting.docking
                            .components.ruleList.dataSourceType,
                        props: {
                            style: {
                                width: 200,
                            },
                            options: Udesk.enums.inspectDataSources
                                .filter((item) =>
                                    [
                                        Udesk.enums.inspectDataSources.voiceCall.id,
                                        Udesk.enums.inspectDataSources.wechatDialogue.id,
                                        Udesk.enums.inspectDataSources.wechatRadio.id,
                                    ].includes(item.id)
                                )
                                .map((i) => ({ label: i.name, value: i.id })),
                            placeholder: UdeskLocales['current'].labels.pleaseSelect,
                        },
                        effect: {
                            value: [
                                Udesk.enums.inspectDataSources.voiceCall.id,
                                Udesk.enums.inspectDataSources.wechatDialogue.id,
                                Udesk.enums.inspectDataSources.wechatRadio.id,
                            ].reduce((prev, cur) => {
                                prev[cur] = [
                                    {
                                        name: ['conditionList'],
                                        output: [
                                            {
                                                field: props.filedConditions[cur],
                                                value: '',
                                                operator:
                                                    props.filedConditions[cur].fieldType === 1
                                                        ? 'is'
                                                        : 'is_any',
                                            },
                                        ],
                                    },
                                ];
                                return prev;
                            }, {}),
                        },
                    },
                    {
                        type: 'ConditionList',
                        name: 'conditionList',
                        label: /* 筛选条件 */ UdeskLocales['current'].pages.generalSetting.docking
                            .components.ruleList.filterCriteria,
                        props: {
                            inspectDataSource: props.item.inspectDataSource,
                            filedConditions: props.filedConditions,
                        },
                    },
                ]}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 20 }}
            ></FormBuilder>
        </RuleItemWrap>
    );
};

const RuleList = (props) => {
    const { value: dataSplitSettings = [], onChange, filedConditions, addBtnDisabled } = props;

    const onDataSplitSettingChange = (index, newValue) => {
        let res = [...dataSplitSettings];
        res[index] = newValue;

        onChange?.(res);
    };
    if (!filedConditions) return <Spin spinning={true}></Spin>;

    return (
        <React.Fragment>
            <UdeskList
                deleteItem={(index) => {
                    let newValue = [...dataSplitSettings];
                    newValue[index] = null;
                    onChange?.(newValue.filter(Boolean));
                }}
                addBtnDisabled={addBtnDisabled}
                addItem={() => {
                    onChange?.([
                        ...dataSplitSettings,
                        {
                            appType: 'QA',
                            inspectDataSource: Udesk.enums.inspectDataSources.voiceCall.id,
                            conditionList: [
                                {
                                    field: filedConditions[
                                        Udesk.enums.inspectDataSources.voiceCall.id
                                    ],
                                    operator: 'is',
                                    value: '',
                                },
                            ],
                            _id: Udesk.utils.string.uuid(),
                        },
                    ]);
                }}
            >
                {dataSplitSettings.map((item, index) => {
                    return (
                        <RuleItem
                            key={item._id}
                            item={item}
                            filedConditions={filedConditions}
                            onChange={onDataSplitSettingChange.bind(null, index)}
                        ></RuleItem>
                    );
                })}
            </UdeskList>
        </React.Fragment>
    );
};

export { RuleList };
