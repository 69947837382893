export default {
  lang: {
    num: 'Первый',
    total: 'Статья/Всего',
    strip: 'Полоска',
    more: 'Больше',
    chosen: 'Выбранный',
    cancel: 'Отмена выбора',
    bulkOperations: 'Операции партии',
    import: 'Импорт',
  },
};
