// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetIntelligentPartnerAiAnalysisByIdQueryFormParam,
    BaseResponseIntelligentPartnerAiQueryReportFormFoundResponse,
    PutIntelligentPartnerAiAnalysisByIdQueryFormParam,
    PutIntelligentPartnerAiAnalysisByIdQueryFormBody,
    BaseResponseVoid,
    DeleteIntelligentPartnerAiAnalysisByIdQueryFormParam,
} from '../../../../../../types';

/**
 * 查询自定义报表
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [GET] /intelligentPartner/ai/analysis/{id}/query/form
 * @param id
 */
export function getIntelligentPartnerAiAnalysisByIdQueryForm(
    options: ApiOptions<GetIntelligentPartnerAiAnalysisByIdQueryFormParam, never> & {
        segments: GetIntelligentPartnerAiAnalysisByIdQueryFormParam;
    }
): Promise<BaseResponseIntelligentPartnerAiQueryReportFormFoundResponse> {
    return request<never, GetIntelligentPartnerAiAnalysisByIdQueryFormParam, never>(
        '/intelligentPartner/ai/analysis/{id}/query/form',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerAiAnalysisByIdQueryForm',
        }
    );
}

/**
 * 修改自定义报表
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [PUT] /intelligentPartner/ai/analysis/{id}/query/form
 * @param id
 */
export function putIntelligentPartnerAiAnalysisByIdQueryForm(
    data: PutIntelligentPartnerAiAnalysisByIdQueryFormBody,
    options: ApiOptions<PutIntelligentPartnerAiAnalysisByIdQueryFormParam, never> & {
        segments: PutIntelligentPartnerAiAnalysisByIdQueryFormParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentPartnerAiAnalysisByIdQueryFormBody,
        PutIntelligentPartnerAiAnalysisByIdQueryFormParam,
        never
    >('/intelligentPartner/ai/analysis/{id}/query/form', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerAiAnalysisByIdQueryForm',
    });
}

/**
 * 删除自定义报表
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [DELETE] /intelligentPartner/ai/analysis/{id}/query/form
 * @param id
 */
export function deleteIntelligentPartnerAiAnalysisByIdQueryForm(
    options: ApiOptions<DeleteIntelligentPartnerAiAnalysisByIdQueryFormParam, never> & {
        segments: DeleteIntelligentPartnerAiAnalysisByIdQueryFormParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentPartnerAiAnalysisByIdQueryFormParam, never>(
        '/intelligentPartner/ai/analysis/{id}/query/form',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteIntelligentPartnerAiAnalysisByIdQueryForm',
        }
    );
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/{id}/query/form',
        method: 'get',
    },
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/{id}/query/form',
        method: 'put',
    },
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/{id}/query/form',
        method: 'delete',
    },
];
