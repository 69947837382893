import AdminUserGroupsDetailTemplate from '../detail/template';

export default class AdminUserGroupsNewRoute extends AdminUserGroupsDetailTemplate {
    model() {
        let modelPromise = new Promise((resolve, reject) => {
            resolve({
                name: '',
                userList: []
            });
        });
        return {
            userGroup: modelPromise
        };
    }
}
