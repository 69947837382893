// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    PostReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowIdParam,
    PostReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowIdBody,
    BaseResponseListIntelligentPartnerDialogNodePptConfigFoundResponse,
} from '../../../../../../types';

/**
 * 生成全文演讲话术
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [POST] /review/intelligentPartner/node/pptConfig/generateAiWords/{flowId}
 * @param flowId
 */
export function postReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowId(
    data: PostReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowIdBody,
    options: ApiOptions<
        PostReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowIdParam,
        never
    > & { segments: PostReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowIdParam }
): Promise<BaseResponseListIntelligentPartnerDialogNodePptConfigFoundResponse> {
    return request<
        PostReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowIdBody,
        PostReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowIdParam,
        never
    >('/review/intelligentPartner/node/pptConfig/generateAiWords/{flowId}', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewIntelligentPartnerNodePptConfigGenerateAiWordsByFlowId',
    });
}

export const meta = [
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/generateAiWords/{flowId}',
        method: 'post',
    },
];
