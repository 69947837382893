// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetInspectionTasksSourceBySourceQuery,
    GetInspectionTasksSourceBySourceParam,
    BaseResponseListInspectionTaskFoundResponse,
} from '../../../types';

/**
 * 质检任务列表
 *
 * @export
 * @tags 质检任务
 * @link [GET] /inspectionTasks/source/{source}
 * @param source
 */
export function getInspectionTasksSourceBySource(
    options: ApiOptions<
        GetInspectionTasksSourceBySourceParam,
        GetInspectionTasksSourceBySourceQuery
    > & { segments: GetInspectionTasksSourceBySourceParam }
): Promise<BaseResponseListInspectionTaskFoundResponse> {
    return request<
        never,
        GetInspectionTasksSourceBySourceParam,
        GetInspectionTasksSourceBySourceQuery
    >('/inspectionTasks/source/{source}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getInspectionTasksSourceBySource',
    });
}

export const meta = [
    { tags: ['质检任务'], path: '/inspectionTasks/source/{source}', method: 'get' },
];
