// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetIntelligentPartnerTagsQuery,
    BaseResponseListIntelligentPartnerTagFoundResponse,
    PostIntelligentPartnerTagsBody,
    BaseResponseIntelligentPartnerTagFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 智能陪练-客户标签
 * @link [GET] /intelligentPartnerTags
 */
export function getIntelligentPartnerTags(): Promise<BaseResponseListIntelligentPartnerTagFoundResponse>;
export function getIntelligentPartnerTags(
    options: ApiOptions<never, GetIntelligentPartnerTagsQuery>
): Promise<BaseResponseListIntelligentPartnerTagFoundResponse>;
export function getIntelligentPartnerTags(
    options?: ApiOptions<never, GetIntelligentPartnerTagsQuery>
): Promise<BaseResponseListIntelligentPartnerTagFoundResponse> {
    return request<never, never, GetIntelligentPartnerTagsQuery>('/intelligentPartnerTags', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerTags',
    });
}

/**
 * 新建客户标签
 *
 * @export
 * @tags 智能陪练-客户标签
 * @link [POST] /intelligentPartnerTags
 */
export function postIntelligentPartnerTags(
    data: PostIntelligentPartnerTagsBody
): Promise<BaseResponseIntelligentPartnerTagFoundResponse>;
export function postIntelligentPartnerTags(
    data: PostIntelligentPartnerTagsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerTagFoundResponse>;
export function postIntelligentPartnerTags(
    data: PostIntelligentPartnerTagsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerTagFoundResponse> {
    return request<PostIntelligentPartnerTagsBody, never, never>('/intelligentPartnerTags', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postIntelligentPartnerTags',
    });
}

export const meta = [
    { tags: ['智能陪练-客户标签'], path: '/intelligentPartnerTags', method: 'get' },
    { tags: ['智能陪练-客户标签'], path: '/intelligentPartnerTags', method: 'post' },
];
