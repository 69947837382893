import React, { useEffect, useMemo, useState, useRef } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
import { Empty, Icon, Spin, Tabs, Button, Menu, Input, Avatar, Image } from 'udesk-ui';
import UdeskPagination from 'Component/common/udesk-pagination';
import Locales from 'UdeskLocales';
import { TreeMenu } from 'udesk_gm_ui';
import useResizeObserver from 'use-resize-observer';
import { getDepartmentsSearch } from 'src/api/departments/search';
import { getEmployeesByIdSessionSearchByType } from 'src/api/employees/{id}/session/search/{type}';

interface DataNode {
    title: string;
    key: string;
    isLeaf?: boolean;
    children?: DataNode[];
    icon?: any;
    userId?: string;
}
interface ChartListItem {
    callId?: number;

    endTime?: string;

    fromId?: string;

    name?: string;

    sessionId?: number;

    sessionType?: number;

    startTime?: string;

    toId?: string;

    customerAvatar?: string;
}
let timer: any = null;
const SINGLE_CHAT = Udesk.enums.chatTypes.singleChat.id;
const GROUP_CHAT = Udesk.enums.chatTypes.groupChat.id;

const Component = (props) => {
    const locales = Locales['current'];

    const { sdkOptions } = props;
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [selectedNode, setSelectedNode] = useState<DataNode | {}>({});
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [chartList, setChartList] = useState<ChartListItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [chartType, setChartType] = useState(SINGLE_CHAT);
    const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 10,
        total: 0,
    });
    const expandedKeys = useRef<string[]>([]);
    const [isFilter, setIsFilter] = useState(false);
    const [filterTreeData, setFilterTreeData] = useState<DataNode[]>([]);
    const [filterTreeDataLoading, setFilterTreeDataLoading] = useState(false);
    const [chartNameKeyword, setchartNameKeyword] = useState<string>('');

    const changeSiderBarStatus = () => {
        setIsOpen(!isOpen);
    };
    const onPageChange = (pageNum, pageSize) => {
        setPaging({
            ...paging,
            pageNum,
            pageSize,
        });
        getChartRoomData(pageNum);
    };
    // const getEmployee = (departmentId) => {
    //     let url = Udesk.business.apiPath.concatApiPath(`departments/employee/${departmentId}`, sdkOptions);
    //     Udesk.ajax.get(url).then?.(
    //         (resp) => {
    //             setLoading(false);
    //         },
    //         (reason) => {
    //             Udesk.ui.notify.error(reason.errorMsg);
    //             setLoading(false);
    //         }
    //     );
    // };
    const treeProps = useMemo(() => {
        const onExpand = (newExpandedKeys) => {
            setTreeData(updateExpandNode(treeData, newExpandedKeys));
            expandedKeys.current = newExpandedKeys;
        };
        const onSelect = (selectedKeys, { selected, selectedNodes, node, event }) => {
            if (selected) {
                setSelectedKey(node.key);
                setSelectedNode(node);
                setPaging({
                    total: 0,
                    pageNum: 1,
                    pageSize: 10,
                });
            }
        };
        const loadData = ({ key, children }: any) =>
            new Promise<void>((resolve) => {
                if (children) {
                    resolve();
                    return;
                }
                let url = Udesk.business.apiPath.concatApiPath(`departments/${key}`, sdkOptions);
                clearTimeout(timer);
                timer = setTimeout(() => {
                    Udesk.ajax.get(url).then?.(
                        (resp) => {
                            setTreeData((origin) =>
                                updateExpandNode(
                                    updateTreeData(
                                        origin,
                                        key,
                                        getNode([...(resp.data?.childDepartment || []), ...(resp.data?.employee || [])])
                                    ),
                                    // updateTreeData(origin, key, getNode(resp.data?.childDepartment || [])),
                                    expandedKeys.current
                                )
                            );
                            resolve();
                            timer = null;
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            timer = null;
                        }
                    );
                }, 100);
            });
        return {
            treeData,
            loadData,
            blockNode: true,
            onSelect,
            selectedKeys: [selectedKey],
            onExpand,
            showIcon: true,
        };
    }, [treeData, selectedKey]);

    const filterTreeProps = useMemo(() => {
        const onSelect = (selectedKeys, { selected, selectedNodes, node, event }) => {

            if (node && (node.isLeaf || !node.children) && selected) {
                setSelectedKey(node.key);
                setSelectedNode(node);
            }
        };
        return {
            onSelect,
            selectedKeys: [selectedKey],
            showIcon: true,
            treeData: filterTreeData,
            virtual: false,
        };
    }, [selectedKey, filterTreeData]);

    const onTabsChange = (activeKey) => {
        setChartType(activeKey);
        setPaging({
            total: 0,
            pageNum: 1,
            pageSize: 10,
        });
    };
    const handleClick = ({ key }) => {
        const clickItem = chartList.find(c => `${c.callId}` === `${key}`);
        props?.onChartChange?.(key, clickItem);
        setSelectedKeys([key]);
    };
    const getChartRoomData = (pageNum) => {
        if (!(selectedNode as DataNode)?.userId) {
            return;
        }
        let url = Udesk.business.apiPath.concatApiPath(
            `employees/${(selectedNode as DataNode).userId}/session/${chartType}?pageNum=${pageNum}&pageSize=${
                paging.pageSize
            }`,
            sdkOptions
        );
        Udesk.ajax.get(url).then?.(
            (resp) => {
                setChartList(resp.data);
                setPaging(resp.paging);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const getChartRoomDataByKeyword = (pageNum) => {
        if (!(selectedNode as DataNode)?.userId) {
            return;
        }
        getEmployeesByIdSessionSearchByType({
            params: {
                name: chartNameKeyword,
                pageNum,
                pageSize: paging.pageSize,
            },
            segments: {
                id: (selectedNode as DataNode).userId as string,
                type: chartType,
            },
        }).then?.(
            (resp) => {
                setChartList(resp.data ?? []);
                setPaging(Object.assign({}, paging, resp.paging));
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    useEffect(() => {
        if (chartNameKeyword) {
            getChartRoomDataByKeyword(paging.pageNum);
        } else {
            getChartRoomData(paging.pageNum);
        }
    }, [selectedNode, chartType, chartNameKeyword]);
    useEffect(() => {
        let url = Udesk.business.apiPath.concatApiPath(`departments`, sdkOptions);
        setLoading(true);
        Udesk.ajax.get(url).then?.(
            (resp) => {
                if (Array.isArray(resp.data) && resp.data.length > 0) {
                    setTreeData(updateExpandNode(getNode(resp.data), [`${resp.data[0].departmentId}`]));
                    setDefaultExpandedKeys([`${resp.data[0].departmentId}`]);
                }
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
        let lastSessionUrl = Udesk.business.apiPath.concatApiPath(`employees/session/last`, sdkOptions);
        Udesk.ajax.get(lastSessionUrl).then?.(
            (resp) => {
                if (resp?.data) {
                    setChartList(resp.data.sessionDtoList || []);
                    setChartType(resp.data.sessionType ? `${resp.data.sessionType}` : SINGLE_CHAT);
                    setSelectedKey(resp.data.userId || '');
                    resp.data.userId && setSelectedNode({ userId: resp.data.userId });
                    resp.data.lastCallId && props?.onChartChange?.(resp.data.lastCallId, resp.data.sessionDtoList?.[0]??null);
                    resp.data.lastCallId && setSelectedKeys([`${resp.data.lastCallId}`]);
                }
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, []);
    const { ref: containerDomRef, height = 1 } = useResizeObserver<HTMLDivElement>();

    const filterKeywords = (value) => {
        setIsFilter(Boolean(value));
        setFilterTreeData([]);
        setFilterTreeDataLoading(true);
        getDepartmentsSearch({ params: { name: value } })
            .then((resp) => {
                setFilterTreeData(updateExpandNode(getNode(resp.data), []));
            })
            .finally(() => {
                setFilterTreeDataLoading(false);
            });
    };

    return (
        <React.Fragment>
            <Spin tip='Loading...' spinning={loading}>
                <div className={`wechat-qa-chart-record-detail-left-filter-list ${isOpen ? '' : 'closed'}`}>
                    <Input.Search
                        onChange={(e) => !e.target.value && setIsFilter(false)}
                        allowClear
                        onSearch={filterKeywords}
                        style={{ marginBottom: 8 }}
                    ></Input.Search>
                    <div className='wechat-qa-chart-record-detail-left-filter-list-content' ref={containerDomRef}>
                        {!isFilter ? (
                            defaultExpandedKeys.length > 0 ? (
                                <TreeMenu
                                    {...treeProps}
                                    defaultExpandedKeys={defaultExpandedKeys}
                                    height={height}
                                    key='isNotFilter'
                                />
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        ) : filterTreeDataLoading && !filterTreeData.length ? (
                            <Spin tip='Loading...' spinning={!filterTreeData.length}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%' }} />
                            </Spin>
                        ) : (
                            <TreeMenu {...filterTreeProps} defaultExpandAll height={height} key='isFilter' />
                        )}
                    </div>
                </div>
                {treeData.length > 0 && (
                    <div
                        className='wechat-qa-chart-record-detail-left-filter-list'
                        style={isOpen ? { borderLeft: '1px solid rgb(240, 240, 240)' } : undefined}
                    >
                        <Tabs
                            size='small'
                            tabBarExtraContent={{
                                left: (
                                    <Button
                                        type='text'
                                        onClick={changeSiderBarStatus}
                                        style={{ marginRight: 8 }}
                                        icon={
                                            <Icon
                                                type={!isOpen ? 'MenuUnfoldOutlined' : 'MenuFoldOutlined'}
                                                antdIcon={true}
                                            ></Icon>
                                        }
                                    ></Button>
                                ),
                            }}
                            activeKey={chartType}
                            onChange={onTabsChange}
                        >
                            <Tabs.TabPane tab={locales.fix.singleChat} key={SINGLE_CHAT}></Tabs.TabPane>
                            <Tabs.TabPane tab={locales.fix.groupChat} key={GROUP_CHAT}></Tabs.TabPane>
                        </Tabs>
                        {selectedKey && (
                            <Input.Search
                                placeholder={chartType === SINGLE_CHAT ? /* 请输入备注/昵称 */locales.pages.wechatQa.chartRecord.detail.components.filter.index.pleaseEnterACommentNickname : /* 请输入群聊名称 */locales.pages.wechatQa.chartRecord.detail.components.filter.index.pleaseEnterTheGroupChatName}
                                allowClear
                                onSearch={(value) => setchartNameKeyword(value)}
                                style={{ marginBottom: 8, paddingLeft: 16 }}
                            ></Input.Search>
                        )}
                        {paging.total ? (
                            <div className='wechat-qa-chart-record-detail-left-filter-list-content'>
                                <Menu
                                    selectedKeys={selectedKeys}
                                    onSelect={handleClick}
                                    // style={{ width: 256 }}
                                    mode='inline'
                                >
                                    {chartList.map((item, index) => {
                                        return (
                                            <Menu.Item key={item.callId}>
                                                <div
                                                    className='wechat-qa-chart-record-detail-left-filter-list-item'
                                                    title={item.name}
                                                >
                                                    {chartType === SINGLE_CHAT && <Avatar size="small" src={<Image src={item.customerAvatar} style={{ height: '100%' }} />} style={{marginRight: 4}} icon={<Icon type='UserOutlined' antdIcon={true} />} />}
                                                    <div className='wechat-qa-chart-record-detail-left-filter-list-item-left'>
                                                        {item.name}
                                                    </div>
                                                    <div className='wechat-qa-chart-record-detail-left-filter-list-item-right'>
                                                        {item.endTime}
                                                    </div>
                                                </div>
                                            </Menu.Item>
                                        );
                                    })}
                                </Menu>
                                <UdeskPagination
                                    current={paging.pageNum}
                                    pageSize={paging.pageSize}
                                    total={paging.total}
                                    onChange={onPageChange}
                                    simple={true}
                                />
                            </div>
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </div>
                )}
            </Spin>
        </React.Fragment>
    );
};

export default Component;

function updateTreeData(list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] {
    return list.map((node) => {
        if (node.key === key) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });
}
function getNode(data) {
    return data.map((i) => {
        let children = Array.isArray(i.childDepartment) ? getNode(i.childDepartment || []) : undefined;
        if (Array.isArray(i.employee)) {
            let employee = i.employee.map((e) => {
                return {
                    key: `${i.departmentId}-${e.id}`,
                    isLeaf: true,
                    title: e.name,
                    parentId: e.departmentId,
                    id: e.id,
                    isEmployee: true,
                    userId: e.userId,
                };
            });
            if (employee.length) {
                children = [...(children ?? []), ...employee];
            }
        }
        if (i.userId) {
            return {
                key: `${i.departmentId}-${i.userId}`,
                isLeaf: true,
                title: i.name,
                // employee: i.employee,
                parentId: i.departmentId,
                id: i.id,
                userId: i.userId,
                isEmployee: true,
            };
        }
        return {
            key: `${i.departmentId}`,
            // isLeaf: i.leaf && !i.employee?.length,
            title: i.departmentName,
            children,
            // employee: i.employee,
            parentId: i.parentId,
            id: i.id,
            selectable: i.leaf,
        };
    });
}

function updateExpandNode(data, expandedKeys) {
    return data.map((i) => ({
        ...i,
        icon: !i.isEmployee ? (
            <Icon
                type={expandedKeys.includes(i.key) ? 'ic-folder-open' : 'FolderFilled'}
                style={{ color: '#1A6EFF' }}
                antdIcon={!expandedKeys.includes(i.key)}
            />
        ) : (
            <div className='employee-icon'></div>
        ),
        children: Array.isArray(i.children) ? updateExpandNode(i.children || [], expandedKeys) : undefined,
    }));
}
