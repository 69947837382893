import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';

class SentimentAnalysis extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let {
            routes,
            route,
            location,
            sdkOptions,
        } = this.props;
        if (this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name
            });
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else {
            return (
                <div className="sentiment-analysis" style={{height: '100%'}}>
                    {routes.map((routeItem, i) => (
                        <SubRoutes key={i} route={routeItem} />
                    ))}
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(SentimentAnalysis);