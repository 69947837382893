// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetReviewSubscribesQuery, BaseResponseListSubscribeFoundResponse } from '../../types';

/**
 * findAll
 *
 * @export
 * @tags subscribe-controller
 * @link [GET] /review/subscribes
 */
export function getReviewSubscribes(): Promise<BaseResponseListSubscribeFoundResponse>;
export function getReviewSubscribes(
    options: ApiOptions<never, GetReviewSubscribesQuery>
): Promise<BaseResponseListSubscribeFoundResponse>;
export function getReviewSubscribes(
    options?: ApiOptions<never, GetReviewSubscribesQuery>
): Promise<BaseResponseListSubscribeFoundResponse> {
    return request<never, never, GetReviewSubscribesQuery>('/review/subscribes', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewSubscribes',
    });
}

export const meta = [{ tags: ['subscribe-controller'], path: '/review/subscribes', method: 'get' }];
