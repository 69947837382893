import React, { useEffect, useMemo, useState } from 'react';
import { Select, Input, Space } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

/** value: { type, inspectTaskId, fieldId, operator, value } */
export const Template = React.memo((props) => {
    const { associateList, locales, value, onChange } = props;

    const operatorList = useMemo(
        () => [
            {
                operatorValue: 'equal',
                operatorLabel: /* 等于 */UdeskLocales['current'].components.checkPointOperatorListGather.businessSummary.normal.equalTo,
            },
            {
                operatorValue: 'not_equal',
                operatorLabel: /* 不等于 */UdeskLocales['current'].components.checkPointOperatorListGather.businessSummary.normal.notEqualTo,
            },
            {
                operatorValue: 'contain_any',
                operatorLabel: /* 包含 */UdeskLocales['current'].components.checkPointOperatorListGather.businessSummary.normal.contain,
            },
            {
                operatorValue: 'not_contain_any',
                operatorLabel: /* 不包含 */UdeskLocales['current'].components.checkPointOperatorListGather.businessSummary.normal.excluding,
            },
            {
                operatorValue: 'empty',
                operatorLabel: locales.enums.fieldOperators.isNull,
            },
            {
                operatorValue: 'not_empty',
                operatorLabel: locales.enums.fieldOperators.isNotNull,
            },
        ],
        [locales]
    );

    const onFieldChange = (v) => {
        onChange?.({
            ...value,
            fieldId: v,
        });
    };

    const onOperatorChange = (v) => {
        onChange?.({
            ...value,
            operator: v,
            value: undefined,
        });
        setInnerValue(undefined);
    };

    const onValuesChange = (v) => {
        if (value.operator === 'contain_any' || value.operator === 'not_contain_any') {
            onChange?.({
                ...value,
                value: JSON.stringify(v),
            });
        } else if (value.operator === 'equal' || value.operator === 'not_equal') {
            onChange?.({
                ...value,
                value: v,
            });
        }
    };

    const [innerValue, setInnerValue] = useState();

    useEffect(() => {
        if (value.operator === 'contain_any' || value.operator === 'not_contain_any') {
            try {
                const v = JSON.parse(value.value) || [];
                if (Array.isArray(v)) {
                    setInnerValue(v);
                } else {
                    setInnerValue([]);
                }
            } catch (e) {
                setInnerValue([]);
            }
        } else if (value.operator === 'equal' || value.operator === 'not_equal') {
            setInnerValue(value.value);
        }
    }, [associateList, value]);

    return (
        <Space style={{ marginLeft: 8 }}>
            <Select
                value={value.fieldId}
                onChange={onFieldChange}
                options={associateList}
                style={{ width: 200 }}
            />

            <Select
                value={value.operator}
                onChange={onOperatorChange}
                options={operatorList.map((i) => ({
                    label: i.operatorLabel,
                    value: i.operatorValue,
                }))}
                style={{ width: 100 }}
            />

            {value.operator === 'contain_any' || value.operator === 'not_contain_any' ? (
                <Select
                    mode="tags"
                    value={innerValue || []}
                    tokenSeparators={[',']}
                    onChange={onValuesChange}
                    style={{ minWidth: '100px' }}
                />
            ) : value.operator === 'equal' || value.operator === 'not_equal' ? (
                <Input
                    value={innerValue}
                    onChange={(e) => onValuesChange(e.target.value)}
                    style={{ minWidth: '100px' }}
                />
            ) : null}
        </Space>
    );
});
