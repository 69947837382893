import React from 'react';
import PropTypes from 'prop-types';
import defaultXssWhiteList from '../../udesk/ui/widgets/xss';

const { whiteLists } = defaultXssWhiteList;
class XssComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        value: "",
        classNames: "",
        whiteList: whiteLists.default || []
    }
    static propTypes = {
        value: PropTypes.string,
        classNames: PropTypes.string,
        whiteList: PropTypes.array
    }
    //#endregion

    //#region defaultProps
    static computes = {
        content: ["props.value", "props.whiteList", function ({ props, state, privates, locales }) {
            let {
                value,
                whiteList
            } = props;
            if (window.filterXSS == null) {
                return value;
            }
            return window.filterXSS(value, {
                whiteList
            });
        }],
    }
    //#endregion
}

export default XssComponent;