import React, { useState, useEffect } from 'react';
import { Icon, Button, Form, Input, Space } from 'udesk-ui';
import Locales from 'UdeskLocales';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import './style.scss';

interface FormValues {
    [key: string]: string | number;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
};

const tailLayout = {
    wrapperCol: { offset: 5, span: 10 },
};

function Authorization({ authorized }) {
    const locales = Locales['current'];

    let { unauthorized, authorized: hasAuthorized } = locales.pages.admin.systemIntegration;
    return (
        <React.Fragment>
            <Icon
                antdIcon={true}
                type={authorized ? 'CheckCircleFilled' : 'ExclamationCircleFilled'}
                style={{ color: authorized ? '#52c41a' : '#faad14', fontSize: '22px' }}
            />
            <div style={{ fontWeight: 600, fontSize: '18px', marginLeft: 8 }}>
                {authorized ? hasAuthorized : unauthorized}
            </div>
        </React.Fragment>
    );
}

export default function Tempalte(props: any) {
    const locales = Locales['current'];

    const [authorized, setAuthorized] = useState(false);
    const [refersh, needRefersh] = useState(1);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [initFormValues, setInitFormValues] = useState<null | FormValues>(null);
    useEffect(() => {
        // let testFromValue = {
        //     udeskAdminEmail: '1@test.cn',
        //     udeskDomain: 'www.baidu.com',
        //     udeskToken: '1234-5678-asdf-zxcv-qwer-uiop',
        // };
        let { sdkOptions } = props;
        let url = Udesk.business.apiPath.concatApiPath(`middlewares`, sdkOptions);
        setLoading(true);
        Udesk.ajax.get(url).then(
            (resp) => {
                if (resp?.data) {
                    form.setFieldsValue(resp.data);
                    setInitFormValues(resp.data);
                    if (
                        resp.data.udeskAdminEmail &&
                        resp.data.udeskDomain &&
                        resp.data.udeskToken
                    ) {
                        setAuthorized(true);
                    }
                }
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, [refersh]);

    const onFinish = (values: FormValues) => {
        let { sdkOptions } = props;
        let url = Udesk.business.apiPath.concatApiPath(
            `middlewares/${initFormValues?.id}`,
            sdkOptions
        );
        setLoading(true);
        Udesk.ajax.put(url, { id: initFormValues!.id, ...values }).then(
            (resp) => {
                setAuthorized(true);
                setInitFormValues({ id: initFormValues!.id, ...values });
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    };

    const cancelAuth = () => {
        let { sdkOptions } = props;
        let url = Udesk.business.apiPath.concatApiPath(
            `middlewares/${initFormValues!.id}`,
            sdkOptions
        );
        setLoading(true);
        Udesk.ajax.del(url).then(
            (resp) => {
                setAuthorized(false);
                setInitFormValues({
                    udeskAdminEmail: '',
                    udeskDomain: '',
                    udeskToken: '',
                });
                form.setFieldsValue({
                    udeskAdminEmail: '',
                    udeskDomain: '',
                    udeskToken: '',
                });
                needRefersh(refersh + 1);
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    };

    const reApplyAuth = () => {
        setAuthorized(false);
    };

    const cancel = () => {
        form.setFieldsValue(initFormValues);
        if (
            initFormValues &&
            initFormValues.udeskAdminEmail &&
            initFormValues.udeskDomain &&
            initFormValues.udeskToken
        ) {
            setAuthorized(true);
        } else {
            setAuthorized(false);
        }
    };

    return (
        <Page
            pageBodyClassName="system-integration-pages"
            title={locales.menu.admin.systemIntegration.text}
            // padding={true}
            loading={loading}
        >
            {/* <Divider style={{ marginTop: 0 }} /> */}
            <div className="system-integration-pages-block">
                <div className="system-integration-pages-block-tips">
                    <Authorization authorized={authorized} />
                </div>
                <div className="system-integration-pages-block-form">
                    <Form {...layout} initialValues={{}} form={form} onFinish={onFinish}>
                        <Form.Item
                            name="udeskDomain"
                            label={locales.pages.admin.systemIntegration.udeskDomain}
                            rules={[{ required: true }]}
                        >
                            <div>{form.getFieldValue('udeskDomain')}</div>
                        </Form.Item>
                        <Form.Item
                            name="udeskToken"
                            label={locales.pages.admin.systemIntegration.udeskToken}
                            rules={[{ required: true }]}
                            tooltip={{
                                title: locales.pages.admin.systemIntegration.keyTips,
                                icon: (
                                    <Icon
                                        style={{ verticalAlign: 'middle' }}
                                        antdIcon={true}
                                        type="InfoCircleOutlined"
                                    />
                                ),
                            }}
                        >
                            {!authorized ? (
                                <Input />
                            ) : (
                                <div>{form.getFieldValue('udeskToken')}</div>
                            )}
                        </Form.Item>
                        <Form.Item
                            name="udeskAdminEmail"
                            label={locales.pages.admin.systemIntegration.udeskAdminEmail}
                            rules={[{ required: true }, { type: 'email' }]}
                        >
                            {!authorized ? (
                                <Input />
                            ) : (
                                <div>{form.getFieldValue('udeskAdminEmail')}</div>
                            )}
                        </Form.Item>
                        {/* {!authorized && (
                                    <Form.Item
                                        name='password'
                                        label={locales.pages.admin.systemIntegration.password}
                                        rules={[{ required: true, message: requiredTips('password') }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                )} */}
                        <Form.Item {...tailLayout}>
                            {!authorized ? (
                                <Space>
                                    <Button
                                        key="applyAuth"
                                        loading={loading}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {locales.pages.admin.systemIntegration.applyAuth}
                                    </Button>
                                    {initFormValues && (
                                        <Button key="cancel" onClick={cancel}>
                                            {locales.labels.cancel}
                                        </Button>
                                    )}
                                </Space>
                            ) : (
                                <Space>
                                    <Button key="cancelAuth" loading={loading} onClick={cancelAuth}>
                                        {locales.pages.admin.systemIntegration.cancelAuth}
                                    </Button>
                                    <Button
                                        key="reApplyAuth"
                                        loading={loading}
                                        onClick={reApplyAuth}
                                    >
                                        {locales.pages.admin.systemIntegration.reApplyAuth}
                                    </Button>
                                </Space>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </div>
            {!Udesk.data.init.company.enabledWhiteSystem && (
                <div className="system-integration-pages-block">
                    <div className="system-integration-pages-block-tips">
                        <Icon
                            antdIcon={true}
                            type="InfoCircleFilled"
                            style={{ color: '#1b6dff', fontSize: '22px' }}
                        />
                        <Button
                            target="view_window"
                            href="https://qa.udesk.cn/help/docs/api/assets/chapter1-6.html"
                            type="link"
                        >
                            {locales.pages.admin.systemIntegration.ccIntegrationTips}
                        </Button>
                    </div>
                </div>
            )}
        </Page>
    );
}
