// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { DeleteSubjectMarkCallsByFileIdByCallIdParam, BaseResponseVoid } from '../../../types';

/**
 * 删除会话
 *
 * @export
 * @tags 话题标注通话
 * @link [DELETE] /subjectMarkCalls/{fileId}/{callId}
 * @param fileId
 * @param callId
 */
export function deleteSubjectMarkCallsByFileIdByCallId(
    options: ApiOptions<DeleteSubjectMarkCallsByFileIdByCallIdParam, never> & {
        segments: DeleteSubjectMarkCallsByFileIdByCallIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteSubjectMarkCallsByFileIdByCallIdParam, never>(
        '/subjectMarkCalls/{fileId}/{callId}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteSubjectMarkCallsByFileIdByCallId',
        }
    );
}

export const meta = [
    { tags: ['话题标注通话'], path: '/subjectMarkCalls/{fileId}/{callId}', method: 'delete' },
];
