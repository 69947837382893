import React, { FC, useEffect, useState } from 'react';
import { useRequest } from 'src/util/hook';
import { message, Modal, Popconfirm, Space, Button, Form, Input } from 'udesk-ui';
import { EditOutlined } from '@udesk/icons';
import ChartToggle from './components/ChartToggle';
import {
    RefreshOutlined,
    Download2Outlined,
    DeleteBinOutlined,
    InformationOutlined,
} from '@udesk/icons';
import styled from 'styled-components';
import { deleteIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormId } from 'src/api/intelligentPartner/ai/analysis/{historyId}/query/history/form/{formId}';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
    width: 100%;
`;

const TitleWrapper = styled.div`
    width: 50%;
    flex: 0 0 60%;
`;

const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const TitleLabel = styled.div`
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: bold;
    margin-right: 8px;
`;

const SQLWrapper = styled.div`
    max-height: 300px;
    overflow-y: auto;
`;

type TemplateProps = {
    pageTitle?: string;
    chartType?: string;
    onChartTypeChange?: (v: string) => void;
    chartDisable?: boolean;
    pieChartDisabled?: boolean;
    onExport?: () => void;
    sql?: string;
    chatId?: number;
    activeKey?: string;
    getChart?: any;
    refresh?: any;
    canModify?: boolean;
};

const Template: FC<TemplateProps> = (props) => {
    const {
        pageTitle,
        chartType,
        onChartTypeChange,
        chartDisable = false,
        pieChartDisabled = false,
        onExport,
        sql,
        chatId,
        activeKey,
        getChart,
        refresh,
        canModify,
    } = props;

    const onRefresh = () => {
        refresh?.();
    };

    const onTypeChange = (v) => {
        if (v !== chartType) {
            onChartTypeChange?.(v);
        }
    };

    const onInnerExport = () => {
        onExport?.();
    };

    const onDelete = () => {
        deleteIntelligentPartnerAiAnalysisByHistoryIdQueryHistoryFormByFormId({
            segments: {
                formId: chatId!,
                historyId: activeKey as any,
            },
        }).then(() => {
            message.success(/* 删除成功 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.deleteSuccessful);
            getChart();
        });
    };

    const [visible, setVisible] = useState(false);

    const onCheck = () => {
        setVisible(true);
    };

    const onCancel = () => {
        setVisible(false);
    };

    const [chartTitle, setChartTitle] = useState<string>();

    useEffect(() => {
        setChartTitle(pageTitle);
    }, [pageTitle]);

    const [editVisible, setEditVisible] = useState(false);
    const [form] = Form.useForm<{ name: string }>();

    const onEdit = () => {
        setEditVisible(true);
        form.setFieldsValue({ name: chartTitle });
    };

    const onEditCancel = () => {
        setEditVisible(false);
    };

    const request = useRequest();

    const onOk = () => {
        form.validateFields().then((values) => {
            request(
                `/intelligentPartner/ai/analysis/query/history/${chatId}`,
                {
                    title: values.name,
                },
                'put'
            ).then((resp) => {
                message.success(/* 保存成功 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.saveSuccessful);
                setChartTitle(values.name);
                onEditCancel();
            });
        });
    };

    return (
        <Wrapper>
            <TitleWrapper>
                <Title>
                    <TitleLabel title={chartTitle}>{chartTitle}</TitleLabel>
                    {canModify ? (
                        <Button type="link" icon={<EditOutlined />} onClick={onEdit}></Button>
                    ) : null}
                </Title>

                <Modal title={/* 编辑图表 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.editChart} visible={editVisible} onCancel={onEditCancel} onOk={onOk}>
                    <Form form={form}>
                        <Form.Item name="name" label={/* 图表名称 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.chartName} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </TitleWrapper>

            <Space>
                <RefreshOutlined title={/* 刷新 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.refresh} style={{ cursor: 'pointer' }} onClick={onRefresh} />

                <ChartToggle
                    chartType={chartType}
                    onTypeChange={onTypeChange}
                    chartDisable={chartDisable}
                    pieChartDisabled={pieChartDisabled}
                />

                <Download2Outlined title={/* 导出 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.export} onClick={onInnerExport} />

                {canModify ? (
                    <Popconfirm title={/* 确定删除吗？ */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.areYouSureToDeleteIt} onConfirm={onDelete}>
                        <DeleteBinOutlined title={/* 删除 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.delete} />
                    </Popconfirm>
                ) : null}

                <InformationOutlined
                    title={/* 查看筛选条件 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.viewFilteringCriteria}
                    style={{ cursor: 'pointer' }}
                    onClick={onCheck}
                />
            </Space>

            <Modal title={/* 查看筛选条件 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.components.header.index.viewFilteringCriteria} visible={visible} footer={null} onCancel={onCancel}>
                <SQLWrapper>{sql}</SQLWrapper>
            </Modal>
        </Wrapper>
    );
};

export default Template;
