var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
var FilterContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 12px 0 12px 16px;\n  display: flex;\n  align-items: flex-end;\n  background-color: #fff;\n  /* transition: all 0.3s; */\n  ", " {\n    width: 100%;\n    ", " {\n      ", " {\n        &:last-child {\n          flex: 1;\n          ", " {\n            text-align: end;\n          }\n        }\n      }\n    }\n  }\n  ", " {\n    margin-top: 4px;\n    margin-bottom: 4px;\n  }\n"])), function (props) {
  return ".".concat(props.prefixCls, "-form-builder-container");
}, function (props) {
  return ".".concat(props.prefixCls, "-form-inline");
}, function (props) {
  return ".".concat(props.prefixCls, "-form-item");
}, function (props) {
  return ".".concat(props.prefixCls, "-form-item-control-input-content");
}, function (porps) {
  return ".".concat(porps.prefixCls, "-form-item");
});
export { FilterContainer };