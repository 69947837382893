import React from 'react';
import Udesk from 'Udesk';
import InspectionDetail from '../../components/inspection-detail';
import SpotCheckListDetailRoute from './route';
import './style.scss';
import { PageHeader, Button, Space, Modal, Icon, Popconfirm } from 'udesk-ui';
import ClassNames from 'classnames';
import SamplingTargetBanner from 'Component/pages/components/sampling-target-banner';
import ReInspectionModel from 'Component/pages/components/reinspection-model';
import ReInspectionResultModel from 'Component/pages/components/re-inspection-result-modal';

const LOCAL_STORAGE_KEY = 'spotCheckScoreSubmittedPopconfirmShowed';

export default class SpotCheckListDetailTemplate extends SpotCheckListDetailRoute {
    render() {
        let { match, sdkOptions } = this.props;
        let {
            detail,
            smartTags,
            changeList,
            qualityCheckScoreSaving,
            caseBase,
            relatedDatas,
            relatedLoading,
            pointChildrenComputer,
            pointChildrenPerson,
            resetStatus,
            evaluteVisibe,
            activeFlag,
            dialogRemarkList,
            enabledEvaluate,
            relatedRecordsLoading,
            renderLoading,
            wrapDom,
            showHit,
            samplingTargetBannerKey,
            allPoints,
            reInspectionConfigVisible,
            reInspectionPoints,
            reInspectionType,
            reCheckLoading,
            reInspectionModalVisible,
            // stateParamsType,
            // circlePercent,
        } = this.privates;
        let { relatedDatasPaging } = this.privates.storages;
        let { actions, locales } = this;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;

        const noFiled = detail.status != null && detail.status !== Udesk.enums.gradeStatus.alreadyFiled.id;

        return (
            // <div className="spot-check-list-detail-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className='udesk-qa-web-page-header'
                    onBack={actions.backToIndexPage}
                    title={locales.components.pages.spotCheckList.detail.title}
                    extra={
                        <If
                            condition={
                                hasFeature &&
                                hasFeature('task:work:inpectionList:view') &&
                                !this.privates.testSetListFlag
                            }
                        >
                            <Space>
                                <If condition={noFiled}>
                                    <If condition={hasFeature && hasFeature('task:work:inpectionList:gradeFile') && this.props.route.name === 'spotCheckListDetail'}>
                                        <Button
                                            disabled={qualityCheckScoreSaving}
                                            // type='primary'
                                            onClick={actions.openSubmitModal}
                                        >
                                            {locales.labels.file}
                                        </Button>
                                    </If>
                                    {this.props.route.name === 'spotCheckListDetail' && (
                                        <Button onClick={actions.changeReInspectionConfigVisible}>
                                            {locales.components.pages.qualityInspectionList.reQualityInspection}
                                        </Button>
                                    )}
                                </If>
                                <Button
                                    onClick={actions.changePages.params(1)}
                                    loading={renderLoading && { delay: 400 }}
                                >
                                    {locales.labels.lastPage}
                                </Button>
                                <Button
                                    onClick={actions.changePages.params(2)}
                                    loading={renderLoading && { delay: 400 }}
                                >
                                    {locales.labels.nextPage}
                                </Button>
                                <If condition={hasFeature && hasFeature('task:work:sample:edit') && noFiled}>
                                    <Choose>
                                        <When
                                            condition={
                                                detail.sampleStatus !==
                                                Udesk.enums.sampleAndReviewStatus.samplingCompleted.id
                                            }
                                        >
                                            <Button disabled={qualityCheckScoreSaving} onClick={actions.cancel}>
                                                {locales.labels.cancel}
                                            </Button>
                                            <Popconfirm
                                                visible={!localStorage.getItem(LOCAL_STORAGE_KEY)}
                                                icon={<Icon antdIcon={true} type='InfoCircleTwoTone' />}
                                                title={/* 新功能！点击 ‘抽检提交’和‘快速抽检’ 按钮，提交成功后会自动跳转到下一页啦~~~ */locales.pages.spotCheckList.detail.template.newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage}
                                                onConfirm={() => {
                                                    localStorage.setItem(LOCAL_STORAGE_KEY, true);
                                                    this.actions.update();
                                                }}
                                                showCancel={false}
                                                disabled={true}
                                                placement='bottomRight'
                                                cancelButtonProps={{ style: { display: 'none' } }}
                                            >
                                                <Button
                                                    disabled={qualityCheckScoreSaving}
                                                    type='primary'
                                                    onClick={actions.spotCheckScoreSubmitted}
                                                >
                                                    {locales.labels.saveSpotCheck}
                                                </Button>
                                            </Popconfirm>
                                        </When>
                                        <When
                                            condition={
                                                detail.sampleStatus ===
                                                Udesk.enums.sampleAndReviewStatus.samplingCompleted.id
                                            }
                                        >
                                            <Button disabled={qualityCheckScoreSaving} onClick={actions.cancel}>
                                                {locales.labels.cancel}
                                            </Button>
                                            <Popconfirm
                                                visible={!localStorage.getItem(LOCAL_STORAGE_KEY)}
                                                icon={<Icon antdIcon={true} type='InfoCircleTwoTone' />}
                                                title={/* 新功能！点击 ‘抽检提交’和‘快速抽检’ 按钮，提交成功后会自动跳转到下一页啦~~~ */locales.pages.spotCheckList.detail.template.newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage}
                                                onConfirm={() => {
                                                    localStorage.setItem(LOCAL_STORAGE_KEY, true);
                                                    this.actions.update();
                                                }}
                                                showCancel={false}
                                                disabled={true}
                                                placement='bottomRight'
                                                cancelButtonProps={{ style: { display: 'none' } }}
                                            >
                                                <Button
                                                    disabled={qualityCheckScoreSaving}
                                                    type='primary'
                                                    onClick={actions.onQualityCheckScoreRapidSampling}
                                                >
                                                    {locales.labels.rapidSampling}
                                                </Button>
                                            </Popconfirm>
                                        </When>
                                    </Choose>
                                </If>
                            </Space>
                        </If>
                    }
                />
                <SamplingTargetBanner
                    id={match.params.id}
                    taskId={match.params.taskId}
                    sourceType={2}
                    key={samplingTargetBannerKey}
                />
                <div className='udesk-qa-web-page-body' ref={wrapDom}>
                    <div className='udesk-qa-web-page-body-root spot-check-list-detail-page'>
                        <Modal
                            visible={evaluteVisibe && enabledEvaluate}
                            closable={false}
                            onOk={actions.evaluateSave}
                            okText={locales.fix.continueToSubmitWithoutEvaluation}
                            onCancel={actions.cancelSave}
                            mask={false}
                        >
                            <div className='quality-check-score-talk-header-eveluation-person'>
                                <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '16px' }}>
                                    {locales.fix.manualEvaluation}：
                                </span>
                                <Space>
                                    <Icon
                                        className={ClassNames({
                                            'quality-check-score-talk-header-xiao': activeFlag === 'true',
                                        })}
                                        type='xiaolian'
                                        onClick={actions.activeChange.params('true')}
                                    />
                                    <Icon
                                        className={ClassNames({
                                            'quality-check-score-talk-header-ku': activeFlag === 'false',
                                        })}
                                        type='kulian'
                                        onClick={actions.activeChange.params('false')}
                                    />
                                </Space>
                            </div>
                        </Modal>
                        <div className='udesk-qa-layout-main-page-body'>
                            <div className='spot-check-list-detail-page-body qa-react-page-body'>
                                {/* <If condition={hasFeature && hasFeature('task:work:sample:view')}> */}
                                <InspectionDetail
                                    evaluateChange={actions.evaluateChange}
                                    relatedDatas={relatedDatas}
                                    resetStatus={resetStatus}
                                    onQualityCheckScoreSave={actions.qualityCheckScoreSaved}
                                    handMarkMap={actions.handMarkMap}
                                    pointChildrenComputer={pointChildrenComputer}
                                    pointChildrenPerson={pointChildrenPerson}
                                    caseBaseStatus={true}
                                    onUpdate={actions.onUpdate}
                                    id={match.params.id}
                                    caseBase={caseBase}
                                    sdkOptions={sdkOptions}
                                    caseLibraryId={match.params.caseLibraryId}
                                    taskId={match.params.taskId}
                                    hasFeature={hasFeature}
                                    detail={detail}
                                    smartTags={smartTags}
                                    changeList={changeList}
                                    qualityCheckScoreSaving={qualityCheckScoreSaving}
                                    qualityCheckScoreType={Udesk.enums.qualityCheckDetailTypes.spotCheck.id}
                                    onQualityCheckScoreCancel={actions.spotCheckScoreCanceled}
                                    relatedDatasPaging={relatedDatasPaging}
                                    parentLoadMore={actions.parentLoadMore}
                                    relatedLoading={relatedLoading}
                                    dialogRemarkList={dialogRemarkList}
                                    changeDialogRemarkList={actions.changeDialogRemarkList}
                                    querryChangeList={actions.querryChangeList}
                                    refresh={actions.reloadAsyncModel}
                                    requestRelatedRecords={actions.requestRelatedRecords}
                                    relatedRecordsLoading={relatedRecordsLoading}
                                    loading={renderLoading}
                                    trainRecords={actions.trainRecords}
                                    showHit={showHit}
                                    onShowHitChange={actions.showHitChange}
                                />
                                {/* </If> */}
                            </div>
                        </div>
                        <ReInspectionModel
                            title={locales.components.pages.qualityInspectionList.reQualityInspectionConfig}
                            allPoints={allPoints}
                            reInspectionConfigVisible={reInspectionConfigVisible}
                            reInspectionPoints={reInspectionPoints}
                            reInspectionType={reInspectionType}
                            onOk={actions.reInspection}
                            onPointsCheckedChange={actions.onPointsCheckedChange}
                            reInspectionTypeChanged={actions.reInspectionTypeChanged}
                            onCancel={actions.changeReInspectionConfigVisible}
                            okButtonLoading={reCheckLoading}
                        />
                        <ReInspectionResultModel
                            history={this.props.history}
                            inspectionId={this.privates.task?.id}
                            visible={reInspectionModalVisible}
                            onVisibleChange={(visible) => {
                                this.privates.reInspectionModalVisible = visible;
                                this.actions.update();
                            }}
                            taskId={parseInt(match.params.taskId, 10)}
                            onSuccess={() => {
                                this.actions.reloadAsyncModel();
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
