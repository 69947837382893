import { useContext, useEffect, useState } from 'react';
import { getIntelligentPartnerHomePageAdminTaskTrend } from 'src/api/intelligentPartner/homePage/admin/taskTrend';
import { getIntelligentPartnerHomePageStudentTaskSummary } from 'src/api/intelligentPartner/homePage/student/taskSummary';
import { getIntelligentPartnerHomePageStudentTaskTrend } from 'src/api/intelligentPartner/homePage/student/taskTrend';
import { FilterContext } from '../context';
import UdeskLocales from 'UdeskLocales';

export const useSummary = (startDate, endDate, isAdmin) => {
    const [taskSummary, setTaskSummary] = useState<any>(null);
    const [taskSummaryEcharts, setTaskSummaryEcharts] = useState<any>(null);
    const [_params] = useContext(FilterContext);

    useEffect(() => {
        const params: any = {
            endDate: endDate.split(' ')[0],
            startDate: startDate.split(' ')[0],
        };
        if (!isAdmin) {
            // GET /intelligentPartner/homePage/student/taskSummary?startDate=2023-03-02&endDate=2023-03-05
            getIntelligentPartnerHomePageStudentTaskSummary({
                params,
            }).then((resp) => {
                setTaskSummary(resp.data ?? null);
            });
        } else {
            params.organizationIds = _params?.organizationIds;
            params.startDate = _params?.startDate;
            params.endDate = _params?.endDate;
        }
        if (!params.startDate || !params.endDate) return;
        // GET /intelligentPartner/homePage/student/taskTrend?startDate=2023-03-02&endDate=2023-03-02
        (isAdmin
            ? getIntelligentPartnerHomePageAdminTaskTrend
            : getIntelligentPartnerHomePageStudentTaskTrend)({
            params,
        }).then((resp) => {
            const taskCount: number[] = [];
            const trainCount: number[] = [];
            const trainTime: number[] = [];

            const practiceAvgCostTime: number[] = [];
            const practiceCostTime: number[] = [];
            const activeCount: number[] = [];

            const xAxis: string[] = [];

            const taskTrend = resp.data ?? [];
            taskTrend.forEach((t) => {
                taskCount.push(t.taskCount ?? 0);
                trainCount.push(t.practiceTimes ?? 0);
                trainTime.push(tranToHour(t.practiceCostTime)); // 转成小时

                practiceCostTime.push(tranToHour(t.practiceCostTime));
                practiceAvgCostTime.push(tranToHour(t.practiceAvgCostTime));
                activeCount.push(t.activeCount ?? 0);

                xAxis.push(t.startPoint!);
            });
            if (isAdmin) {
                setTaskSummaryEcharts(
                    getAdminEchartOptions(activeCount, practiceCostTime, practiceAvgCostTime, xAxis)
                );
            } else {
                setTaskSummaryEcharts(
                    getStudentEchartOptions(taskCount, trainCount, trainTime, xAxis)
                );
            }
        });
    }, [
        startDate,
        endDate,
        isAdmin,
        _params?.organizationIds,
        _params?.startDate,
        _params?.endDate,
    ]);

    return {
        taskSummary,
        taskSummaryEcharts,
    };
};

function tranToHour(seconds) {
    return Number(((seconds ?? 0) / (60 * 60)).toFixed(2));
}

function getAdminEchartOptions(activeCount, practiceCostTime, practiceAvgCostTime, xAxis) {
    const maxActiveCount = calMax(activeCount);
    const maxPracticeCostTime = calMax(practiceCostTime);
    const maxPracticeAvgCostTime = calMax(practiceAvgCostTime);

    const interval_left = maxActiveCount / 5; //左轴间隔
    const interval_right = Math.max(maxPracticeCostTime, maxPracticeAvgCostTime) / 5; //右轴间隔

    let series = [
        {
            name: /* 活跃人数 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary
                .activePopulation,
            type: 'line',
            yAxisIndex: 0,
            data: activeCount,
            smooth: true,
        },
        {
            name: /* 累计练习时长 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary
                .accumulatedPracticeDuration,
            type: 'line',
            yAxisIndex: 1,
            data: practiceCostTime,
            smooth: true,
        },
        {
            name: /* 平均练习时长 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary
                .averagePracticeDuration,
            type: 'line',
            yAxisIndex: 1,
            data: practiceAvgCostTime,
            smooth: true,
        },
    ];
    const res = getEchartOptions(
        series,
        maxActiveCount,
        interval_left,
        Math.max(maxPracticeCostTime, maxPracticeAvgCostTime),
        interval_right,
        xAxis
    );

    res.yAxis[0].axisLabel.formatter = function (value, index) {
        return (
            value + /* 人 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary.people
        );
    };
    return res;
}
function getStudentEchartOptions(taskCount, trainCount, trainTime, xAxis) {
    const maxTaskCount = calMax(taskCount);
    const maxTrainCount = calMax(trainCount);
    const maxTrainTime = calMax(trainTime);

    const interval_left = Math.max(maxTaskCount, maxTrainCount) / 5; //左轴间隔
    const interval_right = maxTrainTime / 5; //右轴间隔

    let series = [
        {
            name: /* 练习任务数 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary
                .numberOfPracticeTasks,
            type: 'line',
            yAxisIndex: 0,
            data: taskCount,
            smooth: true,
        },
        {
            name: /* 练习次数 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary
                .numberOfExercises,
            type: 'line',
            yAxisIndex: 0,
            data: trainCount,
            smooth: true,
        },
        {
            name: /* 练习时长 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary
                .exerciseDuration,
            type: 'line',
            yAxisIndex: 1,
            data: trainTime,
            smooth: true,
        },
    ];
    return getEchartOptions(
        series,
        Math.max(maxTaskCount, maxTrainCount),
        interval_left,
        maxTrainTime,
        interval_right,
        xAxis
    );
}

function getEchartOptions(series, maxLeft, interval_left, maxRight, interval_right, xAxis) {
    const option = {
        backgroundColor: '#F5F8FE',
        color: ['#1A6EFF', '#0FBA26', '#F09A00'],
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            top: 24,
            data: series.map((s) => s.name),
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
        },
        yAxis: [
            {
                type: 'value',
                min: 0,
                max: maxLeft,
                splitNumber: 5,
                interval: interval_left,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return (
                            value +
                            /* 次 */ UdeskLocales['current'].pages.coach.dashboard.hook.useSummary
                                .second
                        );
                    },
                },
            },
            {
                type: 'value',
                min: 0,
                max: maxRight,
                splitNumber: 5,
                interval: interval_right,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return value + 'h';
                    },
                },
            },
        ],
        series,
    };

    return option;
}

function calMax(arr) {
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
        // 求出一组数组中的最大值
        if (max < arr[i]) {
            max = arr[i];
        }
    }
    const maxint = Math.ceil(max / 10); // 向上取整
    const maxval = maxint * 10; // 最终设置的最大值
    return maxval; // 输出最大值
}
