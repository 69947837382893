// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListEmployeeNameFoundResponse } from '../../types';

/**
 * findAllEmployee
 *
 * @export
 * @tags employee-controller
 * @link [GET] /employees/all
 */
export function getEmployeesAll(): Promise<BaseResponseListEmployeeNameFoundResponse>;
export function getEmployeesAll(
    options: ApiOptions<never, never>
): Promise<BaseResponseListEmployeeNameFoundResponse>;
export function getEmployeesAll(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListEmployeeNameFoundResponse> {
    return request<never, never, never>('/employees/all', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getEmployeesAll',
    });
}

export const meta = [{ tags: ['employee-controller'], path: '/employees/all', method: 'get' }];
