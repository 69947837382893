import { message } from 'udesk-ui';
import {
    getReviewIntelligentPartnerNodeDialogueFrameworkConfig,
    postReviewIntelligentPartnerNodeDialogueFrameworkConfig,
    putReviewIntelligentPartnerNodeDialogueFrameworkConfig,
} from 'src/api/review/intelligentPartner/node/dialogueFrameworkConfig';
import UdeskLocales from 'UdeskLocales';

const fields = (props) => [
    {
        type: 'AiGenDialogFrame',
        name: 'intelligentPartnerDialogNodeDfContentList',
        props: {
            courseId: props.courseId,
            nodeId: props.node?.parentNodeId,
        },
    },
];

const request = ({ nodeId }) => {
    return getReviewIntelligentPartnerNodeDialogueFrameworkConfig({
        params: {
            nodeId,
        },
    }).then((resp) => {
        const res: any = resp.data || {};
        return res;
    });
};

const finish = ({ formInstanceCache, configId, nodeId }) => {
    return (values) => {
        values.intelligentPartnerDialogNodeDfContentList = (
            values?.intelligentPartnerDialogNodeDfContentList || []
        ).map((d) => ({
            ...d,
            communicationTargetList: d.communicationTargetList?.filter((t) => !!t),
            evaluationStandardList: d.evaluationStandardList?.filter((t) => !!t),
        }));

        const isDefect = values.intelligentPartnerDialogNodeDfContentList.some(
            (d) => !d.communicationTargetList?.length || !d.evaluationStandardList?.length
        );
        if (isDefect) {
            message.warning(
                /* 请完善配置 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                    .components.const.nodeConfigs.aiGenDialogueNode.pleaseImproveTheConfiguration
            );
            return;
        }

        const params = {
            ...values,
            nodeId,
            id: configId,
        };

        if (!configId) {
            return postReviewIntelligentPartnerNodeDialogueFrameworkConfig(params, {
                successMsg:
                    /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                        .components.const.nodeConfigs.knowledgeNode.successfullySaved,
            });
        } else {
            return putReviewIntelligentPartnerNodeDialogueFrameworkConfig(params, {
                successMsg:
                    /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                        .components.const.nodeConfigs.knowledgeNode.successfullySaved,
            });
        }
    };
};

export default {
    fields,
    request,
    finish,
};
