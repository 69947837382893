import React from 'react';
import SynonymWordsRoute from './route';
import ReactModal from 'udesk-react/src/components/react-modal';

export default class SmartWordsLibraryUploadModalTemplate extends SynonymWordsRoute {
    render() {
        let { locales, actions } = this;
        let { visible, title, onCancel, headerClassName, boardName } = this.props;
        return (
            <div>
                <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save}
                    visible={visible}
                    title={title}
                    headerClassName={headerClassName}
                    footer={null}
                    onCancel={onCancel}
                    boardName={boardName}>
                    <div className='udesk-qa-upload-modal-content'>
                        <div className='upload-modal-content-secnond-step-information udesk-qa-upload-modal-item'>
                            <span>{locales.components.pages.dataMigration.upload.info}</span>
                        </div>

                        <div className='upload-modal-content-bottom'>
                            <label
                                htmlFor='uploadfile'
                                className='upload-item-btn upload-modal-content-bottom-upload-btn'>
                                {locales.labels.batchingImport}
                            </label>
                            <input
                                className='upload-file-input'
                                type='file'
                                name='uploadfile'
                                id='uploadfile'
                                onInput={actions.upload}
                            />
                            <button
                                type='button'
                                className='upload-item-btn upload-modal-content-bottom-cancel-btn'
                                onClick={onCancel}>
                                {locales.labels.cancel}
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}
