import React, { FC, memo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    white-space: pre-wrap;
`;

type TemplateProps = {};

const Template: FC<TemplateProps> = memo((props) => {
    return <Wrapper>{props.children}</Wrapper>;
});

export default Template;
