// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetKnowledgeDetailByIdParam,
    BaseResponseKnowledgePointDetailFoundResponse,
} from '../../types';

/**
 * 查询知识点/流程详情
 *
 * @export
 * @tags 知识点/流程API
 * @link [GET] /knowledgeDetail/{id}
 * @param id
 */
export function getKnowledgeDetailById(
    options: ApiOptions<GetKnowledgeDetailByIdParam, never> & {
        segments: GetKnowledgeDetailByIdParam;
    }
): Promise<BaseResponseKnowledgePointDetailFoundResponse> {
    return request<never, GetKnowledgeDetailByIdParam, never>('/knowledgeDetail/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getKnowledgeDetailById',
    });
}

export const meta = [{ tags: ['知识点/流程API'], path: '/knowledgeDetail/{id}', method: 'get' }];
