// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetLibraryFilesSearchQuery,
    BaseResponseListLibraryFileFoundResponse,
} from '../../types';

/**
 * search
 *
 * @export
 * @tags 案例库文件夹
 * @link [GET] /libraryFiles/search
 */
export function getLibraryFilesSearch(
    options: ApiOptions<never, GetLibraryFilesSearchQuery> & { params: GetLibraryFilesSearchQuery }
): Promise<BaseResponseListLibraryFileFoundResponse> {
    return request<never, never, GetLibraryFilesSearchQuery>('/libraryFiles/search', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getLibraryFilesSearch',
    });
}

export const meta = [{ tags: ['案例库文件夹'], path: '/libraryFiles/search', method: 'get' }];
