// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetWecomMessageFiltersQuery,
    BaseResponseListWecomMessageFilterFoundResponse,
    PostWecomMessageFiltersBody,
    BaseResponseWecomMessageFilterFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags wecom-message-filter-controller
 * @link [GET] /wecomMessageFilters
 */
export function getWecomMessageFilters(): Promise<BaseResponseListWecomMessageFilterFoundResponse>;
export function getWecomMessageFilters(
    options: ApiOptions<never, GetWecomMessageFiltersQuery>
): Promise<BaseResponseListWecomMessageFilterFoundResponse>;
export function getWecomMessageFilters(
    options?: ApiOptions<never, GetWecomMessageFiltersQuery>
): Promise<BaseResponseListWecomMessageFilterFoundResponse> {
    return request<never, never, GetWecomMessageFiltersQuery>('/wecomMessageFilters', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getWecomMessageFilters',
    });
}

/**
 * save
 *
 * @export
 * @tags wecom-message-filter-controller
 * @link [POST] /wecomMessageFilters
 */
export function postWecomMessageFilters(
    data: PostWecomMessageFiltersBody
): Promise<BaseResponseWecomMessageFilterFoundResponse>;
export function postWecomMessageFilters(
    data: PostWecomMessageFiltersBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseWecomMessageFilterFoundResponse>;
export function postWecomMessageFilters(
    data: PostWecomMessageFiltersBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseWecomMessageFilterFoundResponse> {
    return request<PostWecomMessageFiltersBody, never, never>('/wecomMessageFilters', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postWecomMessageFilters',
    });
}

export const meta = [
    { tags: ['wecom-message-filter-controller'], path: '/wecomMessageFilters', method: 'get' },
    { tags: ['wecom-message-filter-controller'], path: '/wecomMessageFilters', method: 'post' },
];
