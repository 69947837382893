// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseMapstringobject } from '../../types';

/**
 * warn
 *
 * @export
 * @tags 语义标签聚类主句
 * @link [GET] /call-sentence-cluster-results/warn
 */
export function getCallSentenceClusterResultsWarn(): Promise<BaseResponseMapstringobject>;
export function getCallSentenceClusterResultsWarn(
    options: ApiOptions<never, never>
): Promise<BaseResponseMapstringobject>;
export function getCallSentenceClusterResultsWarn(
    options?: ApiOptions<never, never>
): Promise<BaseResponseMapstringobject> {
    return request<never, never, never>('/call-sentence-cluster-results/warn', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCallSentenceClusterResultsWarn',
    });
}

export const meta = [
    { tags: ['语义标签聚类主句'], path: '/call-sentence-cluster-results/warn', method: 'get' },
];
