import { HighlightWord } from 'src/api/types';

type HighlighterProps = {
    text?: string;
    highlightWords?: HighlightWord[];
};

const Highlighter = (props: HighlighterProps) => {
    const { text, highlightWords } = props;

    const highlightText = highlightWords?.reduceRight((pre, cur) => {
        return (
            pre?.slice(0, cur.startIndex) +
            '<span style="font-weight: bold">' +
            pre?.slice(cur.startIndex, (cur.endIndex || 0) + 1) +
            '</span>' +
            pre?.slice((cur.endIndex || 0) + 1)
        );
    }, text);

    return highlightText;
};

export default Highlighter;
