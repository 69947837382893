var emojiList = [
    { text: "微笑", index: 0, code: "/::)", regcode: "/::\\)", regtext: "\\[微笑\\]" },
    { text: "伤心", index: 1, code: "/::~", regcode: "/::~", regtext: "\\[伤心\\]" },
    { text: "美女", index: 2, code: "/::B", regcode: "/::B", regtext: "\\[美女\\]" },
    { text: "发呆", index: 3, code: "/::|", regcode: "/::\\|", regtext: "\\[发呆\\]" },
    { text: "墨镜", index: 4, code: "/:8-)", regcode: "/:8\\-\\)", regtext: "\\[墨镜\\]" },
    // { text: "哭泣", index: 5, code: "/::<", regcode: "/::<", regtext: "\\[哭泣\\]" },
    { text: "羞", index: 6, code: "/::$", regcode: "/::\\$", regtext: "\\[羞\\]" },
    { text: "哑", index: 7, code: "/::X", regcode: "/::X", regtext: "\\[哑\\]" },
    { text: "睡", index: 8, code: "/::Z", regcode: "/::Z", regtext: "\\[睡\\]" },
    { text: "大哭", index: 9, code: "/::'(", regcode: "/::'\\(", regtext: "\\[大哭\\]" },
    { text: "尴尬", index: 10, code: "/::-|", regcode: "/::\\-\\|", regtext: "\\[尴尬\\]" },
    { text: "怒", index: 11, code: "/::@", regcode: "/::@", regtext: "\\[怒\\]" },
    { text: "调皮", index: 12, code: "/::P", regcode: "/::P", regtext: "\\[调皮\\]" },
    { text: "笑", index: 13, code: "/::D", regcode: "/::D", regtext: "\\[笑\\]" },
    { text: "惊讶", index: 14, code: "/::O", regcode: "/::O", regtext: "\\[惊讶\\]" },
    { text: "难过", index: 15, code: "/::(", regcode: "/::\\(", regtext: "\\[难过\\]" },
    { text: "酷", index: 16, code: "/:: ", regcode: "/:: ", regtext: "\\[酷\\]" },
    { text: "囧", index: 17, code: "/:--b", regcode: "/:--b", regtext: "\\[囧\\]" },
    { text: "抓狂", index: 18, code: "/::Q", regcode: "/::Q", regtext: "\\[抓狂\\]" },
    { text: "吐", index: 19, code: "/::T", regcode: "/::T", regtext: "\\[吐\\]" },
    { text: "偷笑", index: 20, code: "/:,@P", regcode: "/:,@P", regtext: "\\[偷笑\\]" },
    { text: "快乐", index: 21, code: "/:,@-D", regcode: "/:,@\\-D", regtext: "\\[快乐\\]" },
    { text: "白眼", index: 22, code: "/::d", regcode: "/::d", regtext: "\\[白眼\\]" },
    { text: "撇嘴", index: 23, code: "/:,@o", regcode: "/:,@o", regtext: "\\[撇嘴\\]" },
    { text: "饿", index: 24, code: "/::g", regcode: "/::g", regtext: "\\[饿\\]" },
    { text: "困", index: 25, code: "/:|-)", regcode: "/:\\|\\-\\)", regtext: "\\[困\\]" },
    { text: "惊吓", index: 26, code: "/::!", regcode: "/::!", regtext: "\\[惊吓\\]" },
    { text: "流汗", index: 27, code: "/::L", regcode: "/::L", regtext: "\\[流汗\\]" },
    { text: "大笑", index: 28, code: "/::>", regcode: "/::>", regtext: "\\[大笑\\]" },
    { text: "抽烟", index: 29, code: "/::,@", regcode: "/::,@", regtext: "\\[抽烟\\]" },
    { text: "奋斗", index: 30, code: "/:,@f", regcode: "/:,@f", regtext: "\\[奋斗\\]" },
    { text: "咒骂", index: 31, code: "/::-S", regcode: "/::\\-S", regtext: "\\[咒骂\\]" },
    { text: "疑问", index: 32, code: "/:?", regcode: "/:\\?", regtext: "\\[疑问\\]" },
    { text: "秘密", index: 33, code: "/:,@x", regcode: "/:,@x", regtext: "\\[秘密\\]" },
    { text: "晕", index: 34, code: "/:,@@", regcode: "/:,@@", regtext: "\\[晕\\]" },
    { text: "发疯", index: 35, code: "/::8", regcode: "/::8", regtext: "\\[发疯\\]" },
    { text: "衰", index: 36, code: "/:,@!", regcode: "/:,@!", regtext: "\\[衰\\]" },
    { text: "骷髅", index: 37, code: "/:!!!", regcode: "/:!!!", regtext: "\\[骷髅\\]" },
    { text: "敲打", index: 38, code: "/:xx", regcode: "/:xx", regtext: "\\[敲打\\]" },
    { text: "再见", index: 39, code: "/:bye", regcode: "/:bye", regtext: "\\[再见\\]" },
    { text: "汗", index: 40, code: "/:wipe", regcode: "/:wipe", regtext: "\\[汗\\]" },
    { text: "抠鼻", index: 41, code: "/:dig", regcode: "/:dig", regtext: "\\[抠鼻\\]" },
    { text: "鼓掌", index: 42, code: "/:handclap", regcode: "/:handclap", regtext: "\\[鼓掌\\]" },
    { text: "糗", index: 43, code: "/:&-(", regcode: "/:&\\-\\(", regtext: "\\[糗\\]" },
    { text: "坏笑", index: 44, code: "/:B-)", regcode: "/:B\\-\\)", regtext: "\\[坏笑\\]" },
    { text: "左哼哼", index: 45, code: "/:<@", regcode: "/:<@", regtext: "\\[左哼哼\\]" },
    { text: "右哼哼", index: 46, code: "/:@>", regcode: "/:@>", regtext: "\\[右哼哼\\]" },
    { text: "哈欠", index: 47, code: "/::-O", regcode: "/::\\-O", regtext: "\\[哈欠\\]" },
    { text: "鄙视", index: 48, code: "/:>-|", regcode: "/:>\\-\\|", regtext: "\\[鄙视\\]" },
    { text: "委屈", index: 49, code: "/:P-(", regcode: "/:P\\-\\(", regtext: "\\[委屈\\]" },
    { text: "难过", index: 50, code: "/::'|", regcode: "/::'\\|", regtext: "\\[难过\\]" },
    { text: "奸笑", index: 51, code: "/:X-)", regcode: "/:X\\-\\)", regtext: "\\[奸笑\\]" },
    { text: "亲亲", index: 52, code: "/::*", regcode: "/::\\*", regtext: "\\[亲亲\\]" },
    { text: "吓", index: 53, code: "/:@x", regcode: "/:@x", regtext: "\\[吓\\]" },
    { text: "可怜", index: 54, code: "/:8*", regcode: "/:8\\*", regtext: "\\[可怜\\]" },
    { text: "菜刀", index: 55, code: "/:pd", regcode: "/:pd", regtext: "\\[菜刀\\]" },
    { text: "西瓜", index: 56, code: "/:<W>", regcode: "/:<W>", regtext: "\\[西瓜\\]" },
    { text: "啤酒", index: 57, code: "/:beer", regcode: "/:beer", regtext: "\\[啤酒\\]" },
    { text: "篮球", index: 58, code: "/:basketb", regcode: "/:basketb", regtext: "\\[篮球\\]" },
    { text: "乒乓", index: 59, code: "/:oo", regcode: "/:oo", regtext: "\\[乒乓\\]" },
    { text: "咖啡", index: 60, code: "/:coffee", regcode: "/:coffee", regtext: "\\[咖啡\\]" },
    { text: "饭", index: 61, code: "/:eat", regcode: "/:eat", regtext: "\\[饭\\]" },
    { text: "猪头", index: 62, code: "/:pig", regcode: "/:pig", regtext: "\\[猪头\\]" },
    { text: "鲜花", index: 63, code: "/:rose", regcode: "/:rose", regtext: "\\[鲜花\\]" },
    { text: "枯萎", index: 64, code: "/:fade", regcode: "/:fade", regtext: "\\[枯萎\\]" },
    { text: "红唇", index: 65, code: "/:showlove", regcode: "/:showlove", regtext: "\\[红唇\\]" },
    { text: "爱心", index: 66, code: "/:heart", regcode: "/:heart", regtext: "\\[爱心\\]" },
    { text: "心碎", index: 67, code: "/:break", regcode: "/:break", regtext: "\\[心碎\\]" },
    { text: "蛋糕", index: 68, code: "/:cake", regcode: "/:cake", regtext: "\\[蛋糕\\]" },
    { text: "闪电", index: 69, code: "/:li", regcode: "/:li", regtext: "\\[闪电\\]" },
    { text: "炸弹", index: 70, code: "/:bome", regcode: "/:bome", regtext: "\\[炸弹\\]" },
    { text: "刀", index: 71, code: "/:kn", regcode: "/:kn", regtext: "\\[刀\\]" },
    { text: "足球", index: 72, code: "/:footb", regcode: "/:footb", regtext: "\\[足球\\]" },
    { text: "瓢虫", index: 73, code: "/:ladybug", regcode: "/:ladybug", regtext: "\\[瓢虫\\]" },
    { text: "便便", index: 74, code: "/:shit", regcode: "/:shit", regtext: "\\[便便\\]" },
    { text: "晚安", index: 75, code: "/:moon", regcode: "/:moon", regtext: "\\[晚安\\]" },
    { text: "太阳", index: 76, code: "/:sun", regcode: "/:sun", regtext: "\\[太阳\\]" },
    { text: "礼物", index: 77, code: "/:gift", regcode: "/:gift", regtext: "\\[礼物\\]" },
    { text: "抱抱", index: 78, code: "/:hug", regcode: "/:hug", regtext: "\\[抱抱\\]" },
    { text: "赞", index: 79, code: "/:strong", regcode: "/:strong", regtext: "\\[赞\\]" },
    { text: "差劲", index: 80, code: "/:weak", regcode: "/:weak", regtext: "\\[差劲\\]" },
    { text: "握手", index: 81, code: "/:share", regcode: "/:share", regtext: "\\[握手\\]" },
    { text: "yeah", index: 82, code: "/:v", regcode: "/:v", regtext: "\\[yeah\\]" },
    { text: "抱拳", index: 83, code: "/:@)", regcode: "/:@\\)", regtext: "\\[抱拳\\]" },
    { text: "勾引", index: 84, code: "/:jj", regcode: "/:jj", regtext: "\\[勾引\\]" },
    { text: "力量", index: 85, code: "/:@@", regcode: "/:@@", regtext: "\\[力量\\]" },
    { text: "坏", index: 86, code: "/:bad", regcode: "/:bad", regtext: "\\[坏\\]" },
    { text: "爱你", index: 87, code: "/:lvu", regcode: "/:lvu", regtext: "\\[爱你\\]" },
    { text: "NO", index: 88, code: "/:no", regcode: "/:no", regtext: "\\[NO\\]" },
    { text: "OK", index: 89, code: "/:ok", regcode: "/:ok", regtext: "\\[OK\\]" },
    { text: "爱", index: 90, code: "/:love", regcode: "/:love", regtext: "\\[爱\\]" },
    { text: "亲吻", index: 91, code: "/:<L>", regcode: "/:<L>", regtext: "\\[亲吻\\]" },
    { text: "跳舞", index: 92, code: "/:jump", regcode: "/:jump", regtext: "\\[跳舞\\]" },
    { text: "害怕", index: 93, code: "/:shake", regcode: "/:shake", regtext: "\\[害怕\\]" },
    { text: "尖叫", index: 94, code: "/:<O>", regcode: "/:<O>", regtext: "\\[尖叫\\]" },
    { text: "圈", index: 95, code: "/:circle", regcode: "/:circle", regtext: "\\[圈\\]" },
    { text: "拜", index: 96, code: "/:kotow", regcode: "/:kotow", regtext: "\\[拜\\]" },
    { text: "回头", index: 97, code: "/:turn", regcode: "/:turn", regtext: "\\[回头\\]" },
    { text: "跳", index: 98, code: "/:skip", regcode: "/:skip", regtext: "\\[跳\\]" },
    { text: "挥手", index: 99, code: "/:oY", regcode: "/:o\\Y", regtext: "\\[挥手\\]" },
    { text: "激动", index: 100, code: "/:#-0", regcode: "/:#\\-0", regtext: "\\[激动\\]" },
    // { text: "街舞", clz: "wechat-emoji101", code: "[街舞]", regcode: "[街舞]" },
    // { text: '街舞', index: 101, code: '/:hiphot', regcode: '/:hiphot', regtext: '\\[街舞\\]' },
    { text: "吻", index: 102, code: "/:kiss", regcode: "/:kiss", regtext: "\\[吻\\]" },
    // { text: '旋转', index: 103, code: '/:<&', regcode: '/:<&', regtext: '\\[旋转\\]' },
    { text: "太极", index: 104, code: "/:&>", regcode: "/:&>", regtext: "\\[太极\\]" }
];

export default emojiList;