import React from 'react';
import InputBaseClass from './input-base-class';
import Udesk from '../../udesk/index';
import Locales from "../../udesk/locales/index";

class TextBox extends InputBaseClass {
    actions = {
        _getInternalValueFromValue(value) {
            let internalValue = this.actions._getBaseClassGlobalInternalValueFromValue(value);
            if (internalValue == null) {
                return "";
            }
            return internalValue;
        },
        _validate() {
            let {
                name
            } = this.props;
            let {
                privates: { computes },
            } = this;

            let {
                internalValue,
                mergedAttributes
            } = computes;

            // custom validation
            if (/^[\s]+$/.test(internalValue)) {
                return [Udesk.utils.string.format(Locales.get("components.customFieldInputs.textBox.invalidInputError", this.props.language), name)];
            }
            let maxLength = mergedAttributes.maxLength;
            if (maxLength != null) {
                if (internalValue != null) {
                    if (internalValue.length > maxLength) {
                        return [Udesk.utils.string.format(Locales.get("components.customFieldInputs.textBox.integerMaxLengthError", this.props.language), name, maxLength)];
                    }
                }
            }
            return true;
        },

        _onInputValueChanged(e) {
            let internalValue = e.target.value;
            this.actions._onChanged(internalValue);
        },

        _onInputKeyUp(event) {
            if (event.keyCode === 13) {
                this.trigger("onComfirmValue");
            }
        }
    }

    static get emberName() {
        return 'udesk/custom-field-inputs/text-box';
    }
    
    render() {
        let {
            privates: { computes },
        } = this;

        let {
            internalValue,
            mergedAttributes
        } = computes;
        let {
            disabled
        } = this.props;

        return (
            <input type="text" className={mergedAttributes.classNames} value={internalValue} disabled={disabled} maxLength={mergedAttributes.maxLength} placeholder={mergedAttributes.placeholder} autoFocus={mergedAttributes.autofocus}  onKeyUp={this.actions._onInputKeyUp} onInput={this.actions._onInputValueChanged} onChange={() => {}} />
        );
    }
}

export default Udesk.react.udeskify(TextBox);