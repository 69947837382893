import React, { Component } from 'react';
import Udesk from '../../udesk/index';
import locales from '../../udesk/locales/index';
import PropTypes from 'prop-types';

export default function getComponentClass($, moment) {
    class DateRangePicker extends Component {
        constructor(props) {
            super(props);
            this.dateRangePickerElement = React.createRef();
        }
        privates = {
            isSameTime: true
        }
        static computes = {
            rangeText: ({ props, state, privates, locales }) => {
                let {
                    format,
                    start,
                    end,
                    separator,
                    singleDatePicker
                } = props;
                let momentStartDate = moment(start, format);
                let momentEndDate = moment(end, format);
                let startDate = momentStartDate.isValid() ? momentStartDate.format(format) : undefined;
                let endDate = momentEndDate.isValid() ? momentEndDate.format(format) : undefined;

                let rangeText = "";
                if (singleDatePicker) {
                    if (startDate != null) {
                        rangeText = startDate;
                    }
                } else {
                    if (startDate != null || endDate != null) {
                        rangeText = [startDate, endDate].join(separator);
                    }
                }
                return rangeText;
            }
        }

        componentDidMount() {
            initDateRangePicker.apply(this, [true, $]);
        }

        shouldComponentUpdate(nextProps) {
            this.setDatePickerData(nextProps);
            return true;
        }
        componentWillUnmount() {
            let dateRangePicker = this.dateRangePicker.data('daterangepicker');
            if (dateRangePicker != null) {
                this.dateRangePicker.off('apply.daterangepicker');
                this.dateRangePicker.off('cancel.daterangepicker');
                if (typeof dateRangePicker.remove === "function") {
                    dateRangePicker.remove();
                }
            }
            this.dateRangePicker = null;
            this.dateRangePickerElement = null;
        }

        attachPickerEvents() {
            let that = this;
            this.dateRangePicker.off('apply.daterangepicker').on('apply.daterangepicker', (ev, picker) => {
                let {
                    serverFormat,
                    applyAction
                } = that.props;
                let start = picker.startDate.format(serverFormat);
                let end = picker.endDate.format(serverFormat);

                applyAction(start, end);
            });
            this.dateRangePicker.off('cancel.daterangepicker').on('cancel.daterangepicker', (ev, picker) => {
                let {
                    applyAction,
                    enableClear
                } = that.props;

                if (applyAction != null && enableClear) {
                    applyAction(null, null);
                }
            });
        }
        setDatePickerData(props) {
            let {
                serverFormat,
                start,
                end
            } = props;
            let momentStartDate = moment(start, serverFormat);
            let momentEndDate = moment(end, serverFormat);
            let startDate = momentStartDate.isValid() ? momentStartDate.format(serverFormat) : undefined;
            let endDate = momentEndDate.isValid() ? momentEndDate.format(serverFormat) : undefined;
            this.dateRangePicker.data('daterangepicker').setStartDate(startDate);
            this.dateRangePicker.data('daterangepicker').setEndDate(endDate);
        }

        getOptions() {
            let {
                start,
                end,
                serverFormat,
                minDate,
                maxDate,
                alwaysShowCalendars,
                autoUpdateInput,
                autoApply,
                applyLabel,
                cancelLabel,
                customRangeLabel,
                fromLabel,
                toLabel,
                format,
                firstDay,
                daysOfWeek,
                monthNames,
                timePicker,
                buttonClasses,
                applyClass,
                cancelClass,
                separator,
                singleDatePicker,
                drops,
                opens,
                timePicker24Hour,
                timePickerSeconds,
                timePickerIncrement,
                showWeekNumbers,
                showDropdowns,
                linkedCalendars,
                dateLimit,
                parentEl,
                languageCode,
                enableClear,
                ranges
            } = this.props;

            let momentStartDate = moment(start, serverFormat);
            let momentEndDate = moment(end, serverFormat);
            let startDate = momentStartDate.isValid() ? momentStartDate : undefined;
            let endDate = momentEndDate.isValid() ? momentEndDate : undefined;

            let momentMinDate = moment(minDate, serverFormat);
            let momentMaxDate = moment(maxDate, serverFormat);
            minDate = momentMinDate.isValid() ? momentMinDate : undefined;
            maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;

            if (!daysOfWeek || daysOfWeek.length < 1) {
                daysOfWeek = [
                    locales.get("ui.widgets.bootstrapDateRangePicker.daysOfWeek.Sun", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.daysOfWeek.Mon", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.daysOfWeek.Tu", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.daysOfWeek.We", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.daysOfWeek.Th", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.daysOfWeek.Fr", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.daysOfWeek.Sa", languageCode)
                ];
            }
            if (!monthNames || monthNames.length < 1) {
                monthNames = [
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Jan", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Feb", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Mar", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Apr", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.May", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Jun", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Jul", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Aug", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Sep", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Oct", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Nov", languageCode),
                    locales.get("ui.widgets.bootstrapDateRangePicker.monthNames.Dec", languageCode)
                ];
            }

            let options = {
                alwaysShowCalendars: alwaysShowCalendars,
                autoUpdateInput: autoUpdateInput,
                autoApply: autoApply,
                locale: {
                    applyLabel: applyLabel || locales.get("ui.widgets.bootstrapDateRangePicker.applyLabel", languageCode),
                    cancelLabel: cancelLabel || (enableClear ? locales.get("ui.widgets.bootstrapDateRangePicker.emptyLabel", languageCode) : locales.get("ui.widgets.bootstrapDateRangePicker.cancelLabel", languageCode)),
                    customRangeLabel: customRangeLabel || locales.get("ui.widgets.bootstrapDateRangePicker.customRangeLabel", languageCode),
                    fromLabel: fromLabel || locales.get("ui.widgets.bootstrapDateRangePicker.fromLabel", languageCode),
                    toLabel: toLabel || locales.get("ui.widgets.bootstrapDateRangePicker.toLabel", languageCode),
                    format: format,
                    firstDay: firstDay,
                    daysOfWeek: daysOfWeek,
                    monthNames: monthNames,
                },
                startDate: startDate,
                endDate: endDate,
                minDate: minDate,
                maxDate: maxDate,
                timePicker: timePicker,
                buttonClasses: buttonClasses,
                applyClass: applyClass,
                cancelClass: cancelClass,
                separator: separator,
                singleDatePicker: singleDatePicker,
                drops: drops,
                opens: opens,
                timePicker24Hour: timePicker24Hour,
                timePickerSeconds: timePickerSeconds,
                timePickerIncrement: timePickerIncrement,
                showWeekNumbers: showWeekNumbers,
                showDropdowns: showDropdowns,
                linkedCalendars: linkedCalendars,
                dateLimit: dateLimit,
                parentEl: parentEl
            };

            if (!singleDatePicker) {
                if (ranges == null) {
                    options.ranges = getDefaultRanges(languageCode, null, moment);
                } else {
                    options.ranges = ranges;
                }
            }
            return options;
        }

        render() {
            let {
                classNames,
                disabled,
                placeholder
            } = this.props;
            let {
                privates: { computes },
            } = this;

            if (!this.privates.isSameTime && this.dateRangePicker) {
                initDateRangePicker.apply(this, [true, $]);
            }
            return (
                <input type="text" value={computes.rangeText} ref={this.dateRangePickerElement} disabled={disabled} placeholder={placeholder} className={classNames} onChange={() => { }} />
            );
        }
    }

    DateRangePicker.propTypes = {
        start: PropTypes.string,
        end: PropTypes.string,
        classNames: PropTypes.string,
        minDate: PropTypes.string,
        maxDate: PropTypes.string,
        timePicker: PropTypes.bool,
        timePicker24Hour: PropTypes.bool,
        timePickerSeconds: PropTypes.bool,
        timePickerIncrement: PropTypes.number,
        showWeekNumbers: PropTypes.bool,
        showDropdowns: PropTypes.bool,
        linkedCalendars: PropTypes.bool,
        datelimit: PropTypes.bool,
        parentEl: PropTypes.string,
        disabled: PropTypes.bool,
        format: PropTypes.string,
        serverFormat: PropTypes.string,
        separator: PropTypes.string,
        singleDatePicker: PropTypes.bool,
        placeholder: PropTypes.string,
        buttonClasses: PropTypes.array,
        applyClass: PropTypes.string,
        cancelClass: PropTypes.string,
        drops: PropTypes.string,
        opens: PropTypes.string,
        daysOfWeek: PropTypes.array,
        monthNames: PropTypes.array,
        removeDropdownOnDestroy: PropTypes.bool,
        cancelLabel: PropTypes.string,
        applyLabel: PropTypes.string,
        customRangeLabel: PropTypes.string,
        fromLabel: PropTypes.string,
        toLabel: PropTypes.string,
        autoUpdateInput: PropTypes.bool,
        autoApply: PropTypes.bool,
        alwaysShowCalendars: PropTypes.bool,
        firstDay: PropTypes.number,
        ranges: PropTypes.object,
        applyAction: PropTypes.func,
        languageCode: PropTypes.string,
        enableClear: PropTypes.bool,
        currentTimeAsDefault: PropTypes.bool,
        currentTimePrecision: PropTypes.string,
    };

    DateRangePicker.defaultProps = {
        start: undefined,
        end: undefined,
        classNames: "form-control input-sm",
        minDate: undefined,
        maxDate: undefined,
        timePicker: false,
        timePicker24Hour: true,
        timePickerSeconds: false,
        timePickerIncrement: 1,
        showWeekNumbers: false,
        showDropdowns: false,
        linkedCalendars: false,
        datelimit: false,
        parentEl: 'body',
        disabled: false,
        format: Udesk.config.date.momentDateFormat,
        serverFormat: Udesk.config.date.momentDateTimeFormat,
        separator: ' - ',
        singleDatePicker: false,
        placeholder: null,
        buttonClasses: ['btn', 'btn-sm'],
        applyClass: "btn-success",
        cancelClass: "btn-default",
        drops: "down",
        opens: "right",
        daysOfWeek: [],
        monthNames: [],
        removeDropdownOnDestroy: false,
        cancelLabel: "",
        applyLabel: "",
        customRangeLabel: "",
        fromLabel: "",
        toLabel: "",
        autoUpdateInput: false,
        autoApply: false,
        alwaysShowCalendars: false,
        firstDay: 0,
        ranges: null,
        applyAction: null,
        languageCode: 'zh-cn',
        enableClear: false,
        /*
        **  currentTimeAsDefault: Boolean
        **      默认: false,
        **      描述: 设置当输入框内没有时间值的时候，展开组件是否将当前时间设为默认展示时间
        */
        currentTimeAsDefault: false,
        /*
        **   currentTimePrecision: String
        **      默认: "year",
        **      描述: 设置默认时间显示精度，当currentTimeAsDefault为true时有效，用的moment
        **      ().startOf，可选参数见moment文档
        */
        currentTimePrecision: 'year',
    };

    return Udesk.react.udeskify(DateRangePicker);
}


function initDateRangePicker(isRecreate = true, $ = window.jQuery) {
    let {
        languageCode,
        ranges,
        singleDatePicker,
        currentTimeAsDefault,
        currentTimePrecision,
        start,
        end,
    } = this.props;
    if (currentTimeAsDefault) {
        if (!start && !end) {
            let date = moment().startOf(currentTimePrecision);
            this.props = Object.assign(
                {},
                this.props,
                {
                    start: date.format(Udesk.config.date.momentDateTimeFormat),
                    end: date.format(Udesk.config.date.momentDateTimeFormat)
                }
            );
        }
    }
    let that = this;
    this.dateRangePicker = $(this.dateRangePickerElement.current);
    if (typeof this.dateRangePicker.daterangepicker === "function") {
        this.dateRangePicker.daterangepicker(this.getOptions(), function (startDate, endDate, chosenLabel) {
            if (!singleDatePicker && chosenLabel) {
                let newRanges = ranges ? ranges : getDefaultRanges(languageCode, null, moment);
                let rangeLabel = newRanges[chosenLabel];
                if (rangeLabel) {
                    let newRangeLabel = ranges ? ranges[chosenLabel] : getDefaultRanges(languageCode, chosenLabel, moment);
                    let isSameTime = moment(newRangeLabel[0]).isSame(moment(startDate)) && moment(newRangeLabel[1]).isSame(moment(endDate));
                    if (!isSameTime && that && that.dateRangePicker && typeof that.dateRangePicker.data === "function") {
                        that.dateRangePicker.data('daterangepicker').setStartDate(newRangeLabel[0]);
                        that.dateRangePicker.data('daterangepicker').setEndDate(newRangeLabel[1]);
                        that.privates.isSameTime = isSameTime;
                    } else {
                        that.privates.isSameTime = true;
                    }
                }
            }
        });
        this.attachPickerEvents();
        this.setDatePickerData(this.props);
    }
    that.privates.isSameTime = true;
}
function getDefaultRanges(languageCode, type = null, moment = window.moment) {
    let defaultRanges = {
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.today", languageCode)]: [moment().startOf('day'), moment().endOf('day')],
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.yesterday", languageCode)]: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.recentlySevenDay", languageCode)]: [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.recentlyThirtyDay", languageCode)]: [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.thisMonth", languageCode)]: [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.lastMonth", languageCode)]: [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').endOf('day')],
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.thisYear", languageCode)]: [moment().startOf('year').startOf('day'), moment().endOf('year').endOf('day')],
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.lastYear", languageCode)]: [moment().subtract(1, 'year').startOf('year').startOf('day'), moment().subtract(1, 'year').endOf('year').endOf('day')],
        [locales.get("ui.widgets.bootstrapDateRangePicker.ranges.recentlyYear", languageCode)]: [moment().subtract(1, 'year'), moment()]
    };
    if (type && defaultRanges[type]) {
        return defaultRanges[type];
    }
    return defaultRanges;
}