import React, { useState, useRef } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { CommonPage, StandardizeTable } from 'udesk_gm_ui';
import { Button, Drawer, Spin } from 'udesk-ui';
import { linkTo } from 'src/util/link';
import { getRouteNameWithCoach } from 'src/routes/coach/const';
import { getIntelligentPartnerScoringModels } from 'src/api/intelligentPartner/scoring/models';
import { IntelligentPartnerScoringModelFoundResponse } from 'src/api/types';
import { RequestTableProps } from 'udesk_gm_ui/es/standardize-table/with-request';
import { getIntelligentPartnerScoringModelsById } from 'src/api/intelligentPartner/scoring/models/{id}';
import { ComprehensiveEvaluation } from './components/ComprehensiveEvaluation';
import { OverView } from './components/OverView';
import UdeskLocales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import './style.scss';

function useRequestTable<D extends Array<unknown>>({
    onRequest,
}: {
    onRequest: (params: Parameters<RequestTableProps['onRequest']>[0]) => Promise<{
        data?: D;
        paging?: {
            current?: number;
            pageSize?: number;
            total?: number;
            pageNum?: number;
        };
    }>;
}) {
    const { pageInfo = {}, storePageInfo } = CommonPage.usePageStateMemo();

    const [pagination, setPagination] = useState(
        pageInfo.paging || {
            current: 1,
            pageSize: 10,
            total: 0,
        }
    );

    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<D>([] as unknown[] as D);
    const lastRequestTime = useRef(0);

    const _onRequest: RequestTableProps['onRequest'] = (params) => {
        const time = Date.now();
        setLoading(true);
        onRequest(params).then(({ data, paging }) => {
            if (lastRequestTime.current > time) return;

            lastRequestTime.current = time;
            setLoading(false);
            const newPage = {
                ...pagination,
                ...(paging || {}),
                current: paging?.pageNum ?? 1,
            };
            setPagination(newPage);
            setDataSource((data ?? []) as unknown[] as D);
            storePageInfo((prev) => ({ ...prev, paging: newPage }));
        });
    };
    return {
        dataSource,
        pagination,
        onRequest: _onRequest,
        loading,
    };
}

function useDrawer() {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [detail, setDetail] = useState<IntelligentPartnerScoringModelFoundResponse | null>(null);

    const showPreviewById = (id) => {
        setVisible(true);
        setLoading(true);
        getIntelligentPartnerScoringModelsById({
            segments: {
                id,
            },
        })
            .then((resp) => {
                console.log(resp.data);
                setDetail(resp.data || null);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onClose = () => {
        setVisible(false);
    };

    return {
        loading,
        visible,
        showPreviewById,
        detail,
        onClose,
    };
}

const Template = React.memo((props: any) => {
    const { onRequest, ...tableProps } = useRequestTable<
        IntelligentPartnerScoringModelFoundResponse[]
    >({
        onRequest: ({ pagination }) => {
            return getIntelligentPartnerScoringModels({
                params: {
                    pageNum: pagination.current,
                    pageSize: pagination.pageSize,
                },
            });
        },
    });

    const { showPreviewById, visible, detail, onClose, loading } = useDrawer();
    return (
        <Page
            pageBodyClassName="udesk-coach-score-model"
            title={/* 评分模型 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.index.scoringModel}
            padding={false}
            extra={[
                <Button
                    type="primary"
                    onClick={() => {
                        linkTo(props.history, getRouteNameWithCoach('scoreModelCreate'));
                    }}
                >{/* 新建模型 */}{UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.index.newModel}</Button>,
            ]}
        >
            <StandardizeTable.RequestTable
                onRequest={onRequest}
                table={{
                    ...tableProps,
                    columns: [
                        {
                            title: /* 模型 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.index.model,
                            key: 'name',
                            dataIndex: 'name',
                        },
                        {
                            title: /* 类型 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.index.type,
                            key: 'type',
                            dataIndex: 'type',
                            render(value) {
                                return Udesk.enums.scoringModelTypes.getName(value);
                            },
                        },
                        {
                            title: /* 操作 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.index.operation,
                            dataIndex: 'actions',
                            key: 'actions',
                            renderType: 'actions',
                            buttons: [
                                {
                                    label: /* 预览 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.index.preview,
                                    onClick(item) {
                                        showPreviewById(item.id);
                                    },
                                },
                                {
                                    label: /* 编辑 */UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement.scoreModel.index.edit,
                                    disabled(item) {
                                        return item.type === 1;
                                    },
                                    onClick(item) {
                                        linkTo(
                                            props.history,
                                            getRouteNameWithCoach('scoreModelDetail'),
                                            {
                                                id: item.id,
                                            }
                                        );
                                    },
                                },
                            ],
                        },
                    ],
                }}
            />
            <Drawer visible={visible} title={detail?.name} onClose={onClose} width="50%">
                {loading ? (
                    <Spin spinning={true} />
                ) : (
                    <>
                        <ComprehensiveEvaluation detail={detail} />
                        <OverView detail={detail} />
                    </>
                )}
            </Drawer>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
