import React, { useEffect, useState, useContext } from 'react';
import { Icon, Empty } from 'udesk-ui';
import Udesk from 'Udesk';
import CommentDispalyItem from 'Component/common/gong/comment-dispaly-item';
import '../style.scss';
import Locales from 'UdeskLocales';
import { getMessageUserNotifications } from 'src/api/messageUserNotifications';
import { MessageUserNotificationFoundResponse } from 'src/api/types';
import UdeskPagination from 'Component/common/udesk-pagination';
import { putMessageUserNotificationsRead } from 'src/api/messageUserNotifications/read';
import { MsgCenterContext } from 'src/pages/home';
import { postReplayComments } from 'src/api/replayComments';

const locales = Locales['current'];
const ACTION_TYPE = 'AT_ME';

interface ReplyData extends MessageUserNotificationFoundResponse {
    commentItemProps: any,
}

function AdminMsgCenterAnswer(props) {
    const msgNoticeCountData = useContext(MsgCenterContext);
    const [page, setPage] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [listData, setListData] = useState<ReplyData[]>([]);
    const pageChanged = (pageNum, pageSize) => {
        setPage({
            ...page,
            current: pageNum,
            pageSize,
        });
        getAnswerData(pageNum, pageSize);
    };
    const getAnswerData = (pageNum, pageSize) => {
        const params = {
            action: ACTION_TYPE,
            pageNum,
            pageSize,
        };
        getMessageUserNotifications({params}).then(res => {
            readMessage();
            let listData = res.data?.map(item => {
                return {
                    ...item,
                    commentItemProps: {
                        textType: locales.pages.gong.msgCenter.answer.index.youMeInTheComments,
                        // beginTime: item.message?.replayComment?.beginTime??0,
                        // text: item.message?.content, //回复的消息的摘要
                        createUserUserName: item.message?.senderUser?.realname??'--', //评论创建人
                        createTime: item.message?.replayComment?.createTime, //评论创建时间
                        content: item.message?.content, //评论内容
                        // replyUserName: item.message?.senderUser?.realname, //回复谁
                        headPortraitUrl: item.message?.senderUser?.headPortraitUrl, //头像链接
                    }
                };
            });
            setListData(listData ?? []);
            setPage({
                current: res.paging?.pageNum || page.current,
                pageSize: res.paging?.pageSize || page.pageSize,
                total: res.paging?.total || page.total,
            });
        });
    };
    const timeFormat = (value) => {
        let hh: number = Math.floor(value / 60 / 60);
        let mm: number = Math.floor((value / 60) % 60);
        let ss: number = Math.floor(value % 60);
        return (hh < 10 ? '0' + hh : hh) + ':' + (mm < 10 ? '0' + mm : mm) + ':' + (ss < 10 ? '0' + ss : ss);
    };
    const onReply = (record, textAreaValue, allAtUser, sourceCommentId, replyCommentId) => {
        let params = {
            contentType: 3,
            callId: record.message.replayComment.callId,
            isHelp: 0,
            content: textAreaValue,
            remindUsers: allAtUser.map((i) => ({ id: i.value })),
            authority: 0,
            sourceCommentId,
            replyCommentId: record.message.replayComment.id,
            isMessageBox: 1,
        };
        return postReplayComments(params, {successMsg: locales.pages.gong.msgCenter.answer.index.replySucceeded});
    };
    const readMessage = () => {
        putMessageUserNotificationsRead({params: {action: ACTION_TYPE}}).then(res => {
            msgNoticeCountData && msgNoticeCountData();
        });
    };
    const handlerOpenDetail = (item) => {
        const routeOptions = {
            history: props.history,
            routeName: 'gongCallDetail',
            pathParams: { callId: item.message.dataCallId },
            state: { type: 'answer' },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() => {
        getAnswerData(page.current, page.pageSize);
    }, []);
    return (
        <React.Fragment>
            {listData.length ? (
                listData.map((item) => {
                    return (
                        <div key={item.id} className='page-qa-message-center-notice-item'>
                            <CommentDispalyItem onReply={onReply.bind(null, item)} {...item.commentItemProps} editorWrapperClassName='udesk-qa-ui-input'></CommentDispalyItem>
                            <div onClick={handlerOpenDetail.bind(null, item)} className='message-center-audio-message'>
                                <div className='message-center-audio-message-text'>
                                    <h3>{item.message?.dataCall?.employeeName || '--'} | {item.message?.dataCall?.customerName || '--'}</h3>
                                    <p>{timeFormat(item.message?.dataCall?.voiceLength??0)}</p>
                                    <p>{item.message?.dataCall?.callTime}</p>
                                </div>
                                <Icon
                                    style={{ fontSize: 40, color: '#BDDEFF' }}
                                    type='a-maikefengshengyinluyinluzhishengyin1'
                                    antdIcon={true}
                                ></Icon>
                            </div>
                        </div>
                    );
                })
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={locales.labels.noValue} />
            )}
            {page.total ? <UdeskPagination style={{marginTop: 20}} {...page} showSizeChanger={false} onChange={pageChanged} /> : null}
        </React.Fragment>
    );
}

export default AdminMsgCenterAnswer;
