// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostIntelligentPartnerHomePageStudentPictureEvaluationBody,
    BaseResponseIntelligentPartnerScoreEvaluation,
} from '../../../../../types';

/**
 * 员工画像-综合评价
 *
 * @export
 * @tags 智能陪练首页接口
 * @link [POST] /intelligentPartner/homePage/student/picture/evaluation
 */
export function postIntelligentPartnerHomePageStudentPictureEvaluation(
    data: PostIntelligentPartnerHomePageStudentPictureEvaluationBody
): Promise<BaseResponseIntelligentPartnerScoreEvaluation>;
export function postIntelligentPartnerHomePageStudentPictureEvaluation(
    data: PostIntelligentPartnerHomePageStudentPictureEvaluationBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerScoreEvaluation>;
export function postIntelligentPartnerHomePageStudentPictureEvaluation(
    data: PostIntelligentPartnerHomePageStudentPictureEvaluationBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerScoreEvaluation> {
    return request<PostIntelligentPartnerHomePageStudentPictureEvaluationBody, never, never>(
        '/intelligentPartner/homePage/student/picture/evaluation',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerHomePageStudentPictureEvaluation',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练首页接口'],
        path: '/intelligentPartner/homePage/student/picture/evaluation',
        method: 'post',
    },
];
