import Udesk from 'Udesk';
import { useCallback, useEffect, useState } from 'react';
import { usePassThroughParams } from 'src/pages/coach/learning-center/components';
import { useStartSecond } from 'src/pages/coach/learning-center/components/record/hook';
import { useDebounce, useStateForPropsAttribute } from 'src/util/hook';
import { createMessage } from 'src/pages/coach/learning-center/components/util';

const updatePassThroughParams = (passThroughParams, item) => {
    $.extend(passThroughParams, {
        bindNodeId: item.bindNodeId, // 节点ID ，后续交互作为参数传入，如果返回多个，取最后一个节点的
        nodeType: item.nodeType,
        questionClosely: item.questionClosely, // 是否未追问 0: 否 1: 是,后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionCloselyWordsIndex: item.questionCloselyWordsIndex, // 追问索引句子, 后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionIdList: item.questionIdList, // 已经回答的知识库节点的问答id, 后续交互作为参数传入，如果返回多个，取最后一个节点的
        hasAnswerFinishKnowledge: item.hasAnswerFinishKnowledge, // 知识库节点是否已经回答完毕// 后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionCloselyId: item.questionCloselyId, // 追问增加的id
    });
};

export const useChatRecord = (props: any) => {
    const { request, wrongQuestion } = props;
    const [isLastNode, setIsLastNode] = useState<boolean>(false);
    const [getPassThroughParams, setPassThroughParams, passThroughParams] = usePassThroughParams();
    const [initStartSecond, newStartSecond] = useStartSecond();
    const [record, setRecord] = useState<any>(null);

    const [loadingForTaskStart, setLoadingForTaskStart] = useState(false);
    const [loadingForSendMessage, setLoadingForSendMessage] = useState(false);
    const [list, setList] = useStateForPropsAttribute(
        wrongQuestion,
        'messageList',
        useCallback(() => {}, [])
    );
    const [referenceWords, setReferenceWords] = useStateForPropsAttribute(
        wrongQuestion,
        'nodeWords',
        '',
        false
    );

    useEffect(() => {
        setIsLastNode(false);
    }, [wrongQuestion]);

    const updateDataInfo = useCallback(
        (data, info) => {
            setList((list) => {
                return list.map((item) => {
                    if (item.id === data.id) {
                        return $.extend(true, {}, item, info);
                    }

                    return item;
                });
            });
        },
        [setList]
    );

    const sendMessage = useDebounce(
        (data: any, fileInfo?) => {
            const aiDialogContentList = (data.newList || []).slice(
                0,
                (data.newList || []).length - 1
            );

            const params = getPassThroughParams({
                words: data.content, // 用户说的话，如果是语音，需转化为文字
                speakDuration: fileInfo?.duration, // 说话时长 毫秒
                speakTime: data.createDate, // 说话时间
                ossKey: fileInfo?.url, // 语音上传的地址
                aiDialogContentList, // ai对话时使用
            });

            request(
                `/intelligentPartnerMistakeCollections/${wrongQuestion.id}/interactive`,
                params,
                'post'
            )
                .then(
                    (resp) => {
                        const { nextNodeList, traineeWordsResult } = resp.data;
                        const newPassThroughParams = {
                            dialogueMode: params.dialogueMode,
                        };

                        let isLastNode = false;
                        let newList = [];
                        if (nextNodeList) {
                            newList = nextNodeList.map((item, index) => {
                                // 如果发现机器人对话为最后一个阶段，将自动触发后续的逻辑
                                isLastNode = !!item.isLastNode;
                                // 更新透传参数
                                if (index === nextNodeList.length - 1) {
                                    updatePassThroughParams(newPassThroughParams, item);
                                }
                                return createMessage({
                                    channel: 1,
                                    nodeType: item.nodeType,
                                    content: item.words,
                                    ossKey: item.ossKey,
                                    startSecond: newStartSecond(),
                                });
                            });
                            setReferenceWords(resp.data?.referenceWords || ''); // 重置推荐话术
                        } else {
                            isLastNode = true;
                        }

                        if (isLastNode) {
                            wrongQuestion.exerciseTimes += 1; // 练习次数加一
                            setIsLastNode(isLastNode);
                        }
                        setList((list) => {
                            if (traineeWordsResult) {
                                list[list.length - 1]['traineeWordsResult'] = traineeWordsResult;
                            }
                            return new Array<any>().concat(list, newList);
                        });
                        setPassThroughParams(newPassThroughParams);
                        updateDataInfo(data, {
                            isSync: 2,
                        });
                    },
                    (reason) => {
                        updateDataInfo(data, {
                            isSync: 0,
                        });
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                )
                .finally(() => {
                    setLoadingForSendMessage(false);
                });
        },
        [setList, wrongQuestion?.id, request, record?.id]
    );

    const submitMessageHandle = useCallback(
        (content) => {
            const newMessage = createMessage({
                isSync: 1,
                startSecond: newStartSecond(),
                content,
            });
            const newList = [...list];
            setList((list) => {
                const index = list.findIndex((item) => item.id === newMessage.id);

                if (index > -1) {
                    newList.splice(index, 1, newMessage);
                } else {
                    newList.push(newMessage);
                }
                return newList;
            });
            setLoadingForSendMessage(true);
            sendMessage({ ...newMessage, newList });
        },
        [sendMessage, setList, newStartSecond, list]
    );

    // 开始任务
    const startTaskHandle = useCallback(() => {
        if (wrongQuestion) {
            setLoadingForTaskStart(true);
            request('/intelligentPartnerMistakeCollections/dialogDetail', {
                // taskId: wrongQuestion.taskId,
                // nodeId: wrongQuestion.nodeId,
                // dialogueMode: wrongQuestion.dialogueMode,
                // ruleId: wrongQuestion.mistakeRuleDetailList?.[0].ruleId,
                collectionId: wrongQuestion.id,
            })
                .then(
                    (resp) => {
                        const {
                            words,
                            bindNodeId,
                            nodeType,
                            questionIdList,
                            hasAnswerFinishKnowledge,
                            ...props
                        } = resp.data;

                        setList([
                            createMessage({
                                channel: 1,
                                nodeType,
                                content: words,
                                ossKey: props.ossKey,
                                startSecond: newStartSecond(),
                            }),
                        ]);
                        setPassThroughParams({
                            dialogueMode: wrongQuestion.dialogueMode,
                            bindNodeId,
                            nodeType,
                            questionIdList,
                            hasAnswerFinishKnowledge,
                            questionClosely: null,
                            questionCloselyWordsIndex: null,
                        });
                        setRecord(resp.data?.record);
                        initStartSecond(); // 初始化任务开始时间
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                )
                .finally(() => {
                    setLoadingForTaskStart(false);
                });
        }
    }, [wrongQuestion, request, setList, newStartSecond, setPassThroughParams, initStartSecond]);

    return {
        referenceWords,
        loadingForSendMessage,
        loadingForTaskStart, // 任务开始的loading
        passThroughParams,
        record,
        isLastNode,
        list,
        startTaskHandle,
        submitMessageHandle,
    };
};
