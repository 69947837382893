import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Button, FormBuilder } from 'udesk-ui';
import { RuleList } from './components/RuleList';
import {
    getCompanyGeneralSettingss,
    postCompanyGeneralSettingss,
} from 'src/api/companyGeneralSettingss';
import { putCompanyGeneralSettingssByTypeByValue } from 'src/api/companyGeneralSettingss/{type}/{value}';
import { putCompanyGeneralSettingssById } from 'src/api/companyGeneralSettingss/{id}';
import { getCompanyGeneralSettingssDATA_SPLITFiledConditions } from 'src/api/companyGeneralSettingss/DATA_SPLIT/filedConditions';
import UdeskLocales from 'UdeskLocales';

// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';

const Template = React.memo((props: any) => {
    // const locales = Locales['current'];
    // const { sdkOptions } = props;
    const [form] = FormBuilder.useForm();
    const [filedConditions, setFiledConditions] = useState<any>(null);
    const [addBtnDisabled, setAddBtnDisabled] = useState(true);

    const [showRule, setShowRule] = useState(
        Boolean(Udesk.data.init.company.enabledDataStreamSplit)
    );
    const [initialValues, setInitialValues] = useState({
        enabledDataStreamSplit: Udesk.data.init.company.enabledDataStreamSplit,
    });
    const [isPut, setIsPut] = useState<number | false>(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getCompanyGeneralSettingss({
            params: {
                type: 'DATA_SPLIT',
            },
        }).then((resp) => {

            setInitialValues((prev) => ({
                ...prev,
                dataSplitSettings: (resp.data?.[0]?.dataSplitSettings ?? []).map(i => ({...i, _id: Udesk.utils.string.uuid()})),
            }));
            setIsPut(!!resp.data?.length && resp.data[0].id!);
        });
    }, []);
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);

    useEffect(() => {
        getCompanyGeneralSettingssDATA_SPLITFiledConditions()
            .then((resp) => {
                const res = resp.data || {};
                if (res[Udesk.enums.inspectDataSources.wechatDialogue.id]) {
                    res[Udesk.enums.inspectDataSources.wechatRadio.id] =
                        res[Udesk.enums.inspectDataSources.wechatDialogue.id];
                }
                setFiledConditions(res);
            })
            .finally(() => {
                setAddBtnDisabled(false);
            });
    }, []);

    return (
        <Page pageBodyClassName="udesk-qa-web-data-docking-index" title={/* 数据对接 */UdeskLocales['current'].pages.generalSetting.docking.index.dataConnection} padding={true}>
            <FormBuilder
                customComponents={{ RuleList }}
                initialValues={initialValues}
                form={form}
                onFinish={(values) => {
                    if (!isPut) {
                        postCompanyGeneralSettingss(
                            {
                                type: 'DATA_SPLIT',
                                dataSplitSettings: values.dataSplitSettings,
                            },
                            {
                                successMsg: /* 保存成功 */UdeskLocales['current'].pages.generalSetting.docking.index.savingSucceeded,
                            }
                        );
                    } else {
                        putCompanyGeneralSettingssById(
                            {
                                type: 'DATA_SPLIT',
                                dataSplitSettings: values.dataSplitSettings,
                            },
                            {
                                segments: {
                                    id: isPut,
                                },
                                successMsg: /* 保存成功 */UdeskLocales['current'].pages.generalSetting.docking.index.savingSucceeded,
                            }
                        );
                    }
                }}
                fields={[
                    {
                        type: 'Switch',
                        name: 'enabledDataStreamSplit',
                        label: /* 数据隔离 */UdeskLocales['current'].pages.generalSetting.docking.index.dataIsolation,
                        props: {
                            loading,
                            onChange: (checked) => {
                                setLoading(true);
                                putCompanyGeneralSettingssByTypeByValue({
                                    segments: {
                                        type: 'DATA_SPLIT',
                                        value: checked ? 1 : 0,
                                    },
                                })
                                    .then((resp) => {
                                        setShowRule(checked);
                                        Udesk.data.init.company.enabledDataStreamSplit = checked
                                            ? 1
                                            : 0;
                                    })
                                    .catch(() => {
                                        form.setFieldsValue({
                                            enabledDataStreamSplit: !checked,
                                        });
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            },
                        },

                        // effect: {
                        //     visible: {
                        //         true: ['dataSplitSettings'],
                        //     },
                        // },
                        addonAfter:
                        /* 开启后，可设置各应用系统接入通话、企业微信会话的数据范围。开关不开启，则各个系统都接入全量数据 */UdeskLocales['current'].pages.generalSetting.docking.index.afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData,
                    },
                    {
                        type: 'RuleList',
                        name: 'dataSplitSettings',
                        label: /* 接入规则 */UdeskLocales['current'].pages.generalSetting.docking.index.accessRules,
                        forceHidden: !showRule,
                        props: {
                            filedConditions,
                            addBtnDisabled,
                            onChange: (v) => console.log(v)
                        },
                    },
                ]}
                footerRender={
                    showRule
                        ? () => (
                            <Button type="primary" htmlType="submit">{/* 保存 */}{UdeskLocales['current'].pages.generalSetting.docking.index.preservation}</Button>
                        )
                        : false
                }
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 20 }}
                btnsCol={{ offset: 3 }}
            ></FormBuilder>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
