// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetReplayCommentsFindAllBySourceCommentIdByIdQuery,
    GetReplayCommentsFindAllBySourceCommentIdByIdParam,
    BaseResponseListReplayCommentFoundResponse,
} from '../../../types';

/**
 * 单条评论展示
 *
 * @export
 * @tags 会话评论
 * @link [GET] /replayComments/findAllBySourceCommentId/{id}
 * @param id
 */
export function getReplayCommentsFindAllBySourceCommentIdById(
    options: ApiOptions<
        GetReplayCommentsFindAllBySourceCommentIdByIdParam,
        GetReplayCommentsFindAllBySourceCommentIdByIdQuery
    > & { segments: GetReplayCommentsFindAllBySourceCommentIdByIdParam }
): Promise<BaseResponseListReplayCommentFoundResponse> {
    return request<
        never,
        GetReplayCommentsFindAllBySourceCommentIdByIdParam,
        GetReplayCommentsFindAllBySourceCommentIdByIdQuery
    >('/replayComments/findAllBySourceCommentId/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReplayCommentsFindAllBySourceCommentIdById',
    });
}

export const meta = [
    { tags: ['会话评论'], path: '/replayComments/findAllBySourceCommentId/{id}', method: 'get' },
];
