//ajax success states
function resolveModel(context) {
    context.isFullfiled = true;
    context.isSuccess = true;
    context.modelError = null;
}
//ajax fail states
function rejectModel(context) {
    context.isFullfiled = true;
    context.isSuccess = false;
    // context.modelError = errorMessage;
}
//remove states
function resetModelStatus(context) {
    context.isFullfiled = false;
    context.isSuccess = false;
    context.modelError = null;
}

export default {
    resolveModel,
    rejectModel,
    resetModelStatus
};

