import React from 'react';
import { useCustomerPortraitConfigContext } from '../Context';
import { CustomerPortraitList } from '../List';


/* cover?: string;

customerTags?: IntelligentPartnerTagGroup[];

desc?: string;

flowId?: number;

id?: number;

name?: string; */
interface CustomerPortraitListProps {
    onChange?: (newValue: number[]) => void;
    value?: Array<number>;
}

const CustomerPortraitSelect = React.memo<CustomerPortraitListProps>((props) => {
    const CustomerPortraitListProps = CustomerPortraitList.useCustomerPortraitList();
    const { flowId } = useCustomerPortraitConfigContext();
    const { allCustomerPortrait } = CustomerPortraitListProps;
    const onChange = (id, checkable) => {
        props.onChange?.(id);
    };
    return (
        <CustomerPortraitList
            {...CustomerPortraitListProps}
            allCustomerPortrait={allCustomerPortrait.filter(item => item.bindFlowIds?.includes(flowId))}
            value={[props.value].filter(Boolean) as unknown as number[]}
            onChange={onChange}
            addable={false}
        />
    );
});

export { CustomerPortraitSelect };
