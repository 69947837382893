// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewIntelligentPartnerNodePptConfigByIdParam,
    BaseResponseIntelligentPartnerDialogNodePptConfigFoundResponse,
    PutReviewIntelligentPartnerNodePptConfigByIdParam,
    PutReviewIntelligentPartnerNodePptConfigByIdBody,
    BaseResponseVoid,
    DeleteReviewIntelligentPartnerNodePptConfigByIdParam,
} from '../../../../../types';

/**
 * findById
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [GET] /review/intelligentPartner/node/pptConfig/{id}
 * @param id
 */
export function getReviewIntelligentPartnerNodePptConfigById(
    options: ApiOptions<GetReviewIntelligentPartnerNodePptConfigByIdParam, never> & {
        segments: GetReviewIntelligentPartnerNodePptConfigByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodePptConfigFoundResponse> {
    return request<never, GetReviewIntelligentPartnerNodePptConfigByIdParam, never>(
        '/review/intelligentPartner/node/pptConfig/{id}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodePptConfigById',
        }
    );
}

/**
 * update
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [PUT] /review/intelligentPartner/node/pptConfig/{id}
 * @param id
 */
export function putReviewIntelligentPartnerNodePptConfigById(
    data: PutReviewIntelligentPartnerNodePptConfigByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerNodePptConfigByIdParam, never> & {
        segments: PutReviewIntelligentPartnerNodePptConfigByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutReviewIntelligentPartnerNodePptConfigByIdBody,
        PutReviewIntelligentPartnerNodePptConfigByIdParam,
        never
    >('/review/intelligentPartner/node/pptConfig/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerNodePptConfigById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [DELETE] /review/intelligentPartner/node/pptConfig/{id}
 * @param id
 */
export function deleteReviewIntelligentPartnerNodePptConfigById(
    options: ApiOptions<DeleteReviewIntelligentPartnerNodePptConfigByIdParam, never> & {
        segments: DeleteReviewIntelligentPartnerNodePptConfigByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReviewIntelligentPartnerNodePptConfigByIdParam, never>(
        '/review/intelligentPartner/node/pptConfig/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerNodePptConfigById',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/{id}',
        method: 'get',
    },
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/{id}',
        method: 'put',
    },
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/{id}',
        method: 'delete',
    },
];
