export default {
  lang: {
    export: '수출',
    successfully: '성공적으로 생성 된 내보내기 작업',
    pleaseJumpTo: '점프하세요',
    uploadAndDownloadCenter: '업로드 및 다운로드 센터',
    check: '보기',
    exportAll: '모든 콘텐츠를 내보내는 것을 확인 하시겠습니까?',
    total: '합계',
    confirm: '데이터, 수출 확인?',
    error: '수출 가능한 데이터의 양은 0 입니다, 필터 기준을 변경하고 다시 시도하십시오',
  },
};
