// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { BaseResponseint } from '../../../types';

/**
 * 查询当前账号未学习数量
 *
 * @export
 * @tags 话术推荐-学习提醒
 * @link [GET] /review/studyRemind/number
 */
export function getReviewStudyRemindNumber(): Promise<BaseResponseint>;
export function getReviewStudyRemindNumber(
    options: ApiOptions<never, never>
): Promise<BaseResponseint>;
export function getReviewStudyRemindNumber(
    options?: ApiOptions<never, never>
): Promise<BaseResponseint> {
    return request<never, never, never>('/review/studyRemind/number', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewStudyRemindNumber',
    });
}

export const meta = [
    { tags: ['话术推荐-学习提醒'], path: '/review/studyRemind/number', method: 'get' },
];
