export default {
  lang: {
    confirm: {
      title:
        'Apakah Anda mengonfirmasi bahwa Anda ingin menghapusnya? Tidak dapat dipulihkan setelah dihapus',
      okText: 'Hapus',
      cancelText: 'Pembatalan',
    },
  },
};
