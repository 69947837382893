export const getLinksFromString = (text) => {
    // const urlRegex = /(https?:\/\/[(\w|\-|?|=|&|#|%)\.\/]+)/g;
    const urlRegex = /https?:\/\/[\w|\-|\?|\+|\=|\&|\#|\%\.\/]+/g;
    return text.match(urlRegex);
};

export function replaceLink(content) {
    const urls = getLinksFromString(content || '');

    if (!urls) return content;

    const res = urls.reduce((p, url) => {
        return p.replaceAll(url, `<a href=${url} target="_blank" rel="noreferrer" style="word-break: break-word;">${url}</a>`);
    }, content || '');
    return res;
}

export function replaceLineBreak(content) {
    //const regex = /\n/g;

    return (content || ''); //.replace(regex, "<br>");
}
