import React, { useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import { FormBuilder, Button, Space, Row, Col } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskLocales from 'UdeskLocales';
import './style.scss';
import {
    // @ts-ignore
    // BaseResponseCommunicateStrategyFoundResponse,
    // @ts-ignore
    CommunicateStrategyFoundResponse,
} from 'src/api/types';
import { FormRichText } from './components/PushRichText';
import {
    getCommunicateStrategyById,
    putCommunicateStrategyById,
} from 'src/api/communicateStrategy/{id}';
import { postCommunicateStrategy } from 'src/api/communicateStrategy';
import { StrategyKnowledge } from './components/StrategyKnowledge';

const LAYOUT = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
};
const MAX_WIDTH = {
    maxWidth: 350,
};

function SupervisionDetail(props) {
    const locales = UdeskLocales['current'];

    const categoryId = useMemo(() => {
        const queryParams = new URLSearchParams(props.location.search);
        return Number(queryParams.get('classificationId'));
    }, []);

    const [initialValues, setInitialValues] = useState<CommunicateStrategyFoundResponse>(() => {
        let res: any = {};
        return res;
    });
    const [loading, setLoading] = useState(false);
    const [wrapForm] = FormBuilder.useForm();
    // const [triggerPointId, setTriggerPointId] = useState<number | undefined>(undefined);
    useEffect(() => {
        let { match } = props;
        let id = match.params.id;
        if (id) {
            setLoading(true);
            getCommunicateStrategyById({
                segments: {
                    id,
                },
            })
                .then((resp) => {
                    if (resp.data) {
                        setInitialValues({
                            ...resp.data,
                            strategyRuleList: resp.data.strategyRuleList ?? [],
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    const transitionToIndex = () => {
        let routeOptions = {
            history: props.history,
            routeName: 'communicationStrategyListIndex',
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const onFinish = async (values) => {
        let { match } = props;
        let id = match.params.id;
        // let request: BaseResponseCommunicateStrategyFoundResponse | null = null;
        const params = {
            ...values,
            isEnable: values.isEnable ? 1 : 0,
        };
        if (!id) {
            await postCommunicateStrategy({
                ...params,
                categoryId,
            });
        } else {
            await putCommunicateStrategyById(
                {
                    ...params,
                    categoryId,
                },
                {
                    segments: {
                        id,
                    },
                }
            );
        }
        transitionToIndex();
    };

    const config: FormProps<any> = {
        // onValuesChange: onCollectionValuesChange,
        onFinish: onFinish,
        initialValues: initialValues,
        customComponents: { FormRichText, StrategyKnowledge },
        ...LAYOUT,
        fields: [
            {
                type: 'Input',
                name: 'name',
                label: /* 名称 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.detail.index.name,
                props: {
                    showCount: true,
                    maxCount: 40,
                    style: MAX_WIDTH,
                    placeholder: UdeskLocales['current'].labels.pleaseEnter,
                },
                rules: [
                    {
                        required: true,
                    },
                    {
                        max: 40,
                        type: 'string',
                    },
                ],
            },
            {
                type: 'FormRichText',
                name: 'pushText',
                label: /* 推送文字 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.detail.index.pushText,
                props: {
                    autoSize: { minRows: 2, maxRows: 2 },
                    showCount: true,
                    maxCount: 255,
                },
            },
            {
                label: /* 启用状态 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.detail.index.enableStatus,
                name: 'isEnable',
                type: 'Switch',
            },
            {
                label: /* 触发规则 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.detail.index.triggerRules,
                name: 'strategyRuleList',
                type: 'StrategyKnowledge',
            },
        ],
        footerRender: false,
    };

    useEffect(() => {
        wrapForm.resetFields();
    }, [initialValues]);
    return (
        <Page
            title={
                /* 沟通策略 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.detail.index.communicationStrategy
            }
            pageBodyClassName="udesk-qa-communication-strategy-detail"
            padding={true}
            loading={loading}
            onBack={transitionToIndex}
        >
            <FormBuilder {...(config as FormProps)} form={wrapForm}></FormBuilder>
            <Row>
                <Col offset={4}>
                    <Space>
                        <Button type="primary" onClick={wrapForm.submit}>
                            {locales.labels.save}
                        </Button>
                        <Button onClick={transitionToIndex}>{locales.labels.cancel}</Button>
                    </Space>
                </Col>
            </Row>
        </Page>
    );
}

export default React.memo(SupervisionDetail);
