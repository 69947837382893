import React, { useMemo } from 'react';
import { OverView as OverViewComponent } from 'Component/pages/components/coach/Score/Overview';
import Udesk from 'Udesk';

export const OverView = React.memo((props: any) => {
    const { detail } = props;
    const mockData = useMemo(() => {
        if (!detail) return {};

        const res: any = {};

        const { modelRuleList, emotionScore, expressScore, flowScore, wordsScore, totalScore } =
            detail;

        modelRuleList.forEach((model) => {
            switch (model.dimension) {
                case Udesk.enums.dimension.flow.id:
                    res.flowScore = {
                        score: Math.min(25, flowScore),
                        totalMainPoints: 6,
                        notCommitMainPoints: 5,
                        violations: 0,
                        totalScore: flowScore,
                        finishFlows: Math.min(25, flowScore),
                        totalFlows: flowScore,
                    };
                    break;
                case Udesk.enums.dimension.word.id:
                    res.speechCraftScore = {
                        score: 25,
                        totalMainPoints: 6,
                        notCommitMainPoints: 5,
                        violations: 0,
                        totalScore: wordsScore,
                        totalWordNum: 1000,
                        commitWordNum: 850,
                    };
                    break;
                case Udesk.enums.dimension.deliver.id:
                    res.speedScore = {
                        score: Math.min(25, expressScore),
                        speedFastTimes: 0,
                        speedSlowTimes: 0,
                        averageSpeed: 0,
                        modalParticleTooMuchTimes: 0,
                        totalScore: expressScore,
                    };
                    break;
                case Udesk.enums.dimension.emotion.id:
                    res.emotionScore = {
                        score: Math.min(25, emotionScore),
                        negativeEmotions: 0,
                        totalScore: emotionScore,
                    };
                    break;
                default:
                    break;
            }
        });

        return {
            totalScore,
            ...res,
        };
    }, [detail]);
    if (!detail) return null;

    return <OverViewComponent modelRuleList={detail.modelRuleList} scoreOverView={mockData} />;
});
