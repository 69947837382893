import Udesk from 'Udesk';
import React from 'react';
import Locales from 'UdeskLocales';
import { getSystemModule } from 'Udesk/system/subApp';

class AnomalousDataComponent extends React.Component {
    static defaultProps = {
        
    };
    privates = {
        dataSource: 0,
        statAt: [
            moment()
                .add(-30, 'day')
                .format('YYYY-MM-DD 00:00:00'),
            moment().format('YYYY-MM-DD 23:59:59')
        ],
        columns: [],
        paging: {
            pageSize: 10,
            pageNum: 1,
            total: 20
        },
        statFieldAt: [],
        businessId: null,
        sourceList: [
            {
                value: 0,
                text: Locales['current'].fix.callAndConversation
            },
            {
                value: 1,
                text: Locales['current'].fix.call
            },
            {
                value: 2,
                text: Locales['current'].fix.conversation
            }
        ],
    };
    init() {
        const company = Udesk.data.init.company;
        if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
            this.privates.dataSource = 0;
        } else if (company.enabledCallInspect !== 0) {
            this.privates.dataSource = 1;
        } else if (company.enabledImInspect !== 0) {
            this.privates.dataSource = 2;
        }
    }
    actions = {
        sourceChange(value) {
            this.privates.dataSource = value;
            this.actions.update();
        },
        businessIdChange(e) {
            this.privates.businessId = e.target.value;
            this.actions.update();
        },
        timeChange(dataString) {
            let startTime = '',
                endTime = '';
            if (!dataString[0] || !dataString[1]) {
                startTime = moment()
                    .add(-30, 'day')
                    .format('YYYY-MM-DD 00:00:00');
                endTime = moment().format('YYYY-MM-DD 23:59:59');
            } else {
                startTime = dataString[0];
                endTime = dataString[1];
            }
            this.privates.statAt = [startTime, endTime];
            this.actions.update();
        },
        timeFieldChange(dataString) {
            let startTime = '',
                endTime = '';
            if (!dataString[0] || !dataString[1]) {
                this.privates.statFieldAt = [];
            } else {
                startTime = dataString[0];
                endTime = dataString[1];
                this.privates.statFieldAt = [startTime, endTime];
            }


            this.actions.update();
        },
        sumbitGet() {
            this.actions.reloadAsyncModel();
        },
        pageNumChange(pageNumber) {
            if (pageNumber !== this.privates.paging.pageNum) {
                this.privates.paging.pageNum = pageNumber;
                this.actions.reloadAsyncModel();

            }
        },
        pageSizeChange(current, pageSize) {
            this.privates.paging.pageNum = current;
            this.privates.paging.pageSize = pageSize;
            this.actions.reloadAsyncModel();

        },
        export() {
            let { sdkOptions } = this.props;
            let data = {
                pageNum: this.privates.paging.pageNum,
                pageSize: this.privates.paging.pageSize,
                sourceType: this.privates.dataSource,
                startTime: this.privates.statAt[0],
                endTime: this.privates.statAt[1],
                businessId: this.privates.businessId,
                systemModule: getSystemModule(),
            };
            if (this.privates.statFieldAt && this.privates.statFieldAt.length > 0) {
                data = {
                    ...data,
                    businessStartTime: this.privates.statFieldAt[0],
                    businessEndTime: this.privates.statFieldAt[1]
                };
            }
            let url = Udesk.business.apiPath.concatApiPath(`callIrregularSource/expData`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    resp => {
                        Udesk.ui.notify.success(Locales['current'].fix.successfulExportAndToTaskCenter);
                        resolve(resp);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });

        },
    };
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult, asyncKey } = parseOptions;
        if (asyncKey === 'data') {
            if (asyncResult.data) {
                this.privates.data = asyncResult.data.map((item, index) => { return { ...item, id: index }; });
                if (asyncResult.paging) {
                    this.privates.paging = asyncResult.paging;
                }

            }
        }
        this.actions.update();
    }
    componentDidMount() {
        getColumns(this);
    }
}
function getColumns(that) {
    const columns = [
        {
            title: Locales['current'].fix.businessId,
            dataIndex: 'businessId',
            width: '15%'
        },
        {
            title: Locales['current'].fix.type,
            dataIndex: 'sourceType',
            width: '10%',
            render: (text, item) => {
                if (item.sourceType) {
                    let obj = that.privates.sourceList.find(current => current.value === item.sourceType);
                    if (obj) {
                        return obj.text;
                    } else {
                        return '--';
                    }
                } else {
                    return '--';
                }
            }
        },
        {
            title: Locales['current'].fix.businessTime,
            dataIndex: 'businessTime',
            width: '20%'
        },
        {
            title: Locales['current'].fix.createTime,
            dataIndex: 'createTime',
            width: '20%'
        },
        {
            title: Locales['current'].fix.abnormalReason,
            dataIndex: 'reason',
            width: '20%'
        }
    ];
    that.privates.columns = columns;
    that.actions.update();
}
export default AnomalousDataComponent;
