import {
    // uuid_v1,
    uuid_v4
} from '../../ref-npm-modules';
import stringFormat from './string-format';
const STRING_CAPITALIZE_REGEXP = /(^|\/)([a-z])/g;
const KEY_GENS = {
    UpperChars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    LowerChars: "abcdefghijklmnopqrstuvwxyz",
    Numbers: "1234567890",
    DefaultSpecials: "~!@#$%^&*()-_+={}[]\\|<,>.?/\"';:`"
};
let KeygenStore = new Map();

export default {
    format: stringFormat.format,
    transformers: stringFormat.transformers,
    capitalize,
    localeCompare,
    base64Encode,
    base64Decode,
    random,
    uuid
};

export let format = stringFormat.format;
export let transformers = stringFormat.transformers;

export {
    capitalize,
    localeCompare,
    base64Encode,
    base64Decode,
    random,
    uuid
};

function capitalize(str) {
    str = normalizeString(str);
    if (str == null) {
        return str;
    }
    // Stolen from ember.js.
    return str.replace(STRING_CAPITALIZE_REGEXP, function(match, separator, chr) {
        return match.toUpperCase();
    });
}

function normalizeString(str) {
    if (str == null) {
        return str;
    } else {
        if (typeof str !== "string") {
            str = str.toString();
        }
        return str;
    }
}

function localeCompare(a, b) {
    if (a != null && b == null) {
        return 1;
    } else if (a == null && b != null) {
        return -1
    } else {
        if (typeof a !== "string") {
            a = a.toString();
        }
        if (typeof b !== "string") {
            b = b.toString();
        }
        return a.localeCompare(b);
    }
}

function base64Encode(s) {
    var rv;
    rv = encodeURIComponent(s);
    rv = unescape(rv);
    rv = window.btoa(rv);
    return rv;
}

function base64Decode(s) {
    var rv;
    rv = decodeURIComponent(s);
    rv = unescape(rv);
    rv = window.atob(rv);
    return rv;
}

function random(length = 0, {
    chars = false,
    upperCase = false,
    lowerCase = false,
    numbers = false,
    specials = false,
    excludeSpecials = []
} = {
    chars: true,
    lowerCase: true,
    numbers: true
}) {
    let keys = [];
    let isDynamicSpecials = false;
    if (chars) {
        if (upperCase) {
            keys.push("UpperChars");
        }
        if (lowerCase || (!upperCase && !lowerCase)) {
            keys.push("LowerChars");
        }
    }
    if (numbers) {
        keys.push("Numbers");
    }
    if (specials) {
        if (specials === true) {
            keys.push("DefaultSpecials");
        } else if (specials === false || specials == null) {
            //nothing
        } else if (Array.isArray(specials)) {
            isDynamicSpecials = true;
        } else if (typeof specials === "string") {
            isDynamicSpecials = true;
            specials = specials.split("");
        } else if (Array.isArray(excludeSpecials) || typeof excludeSpecials === "string") {
            isDynamicSpecials = true;
            specials = KEY_GENS.DefaultSpecials.split("").filter(c => !excludeSpecials.includes(c));
        }
    }

    let keyString = keys.join(",");
    let candidates = KeygenStore.get(keyString);
    if (candidates == null) {
        candidates = [];
        for (let key of keys) {
            candidates.push(...KEY_GENS[key].split(""));
        }
        KeygenStore.set(keyString, candidates);
    }

    if (isDynamicSpecials) {
        candidates.push(...specials);
    }

    if (candidates.length === 0 || typeof length !== "number" || length <= 0) {
        return "";
    }
    let results = [];
    for (let i = 0; i < length; i++) {
        let charIndex = Math.floor(Math.random() * (candidates.length + 1));
        results.push(candidates[charIndex]);
    }
    return results.join("");
}

function uuid() {
    // return uuid_v1();
    return uuid_v4();
}