import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useMemo,
} from 'react';
import _cloneDeep from 'lodash-es/cloneDeep';
import ChatCardItem from '../chat-record-item';
import './style.scss';
import VirtualList, { VirtualListRef } from '../../udesk-virtual-list';
import { Button, Icon, Checkbox } from 'udesk-ui';
// import { useImmer } from 'use-immer';

const CheckboxWrapper = (props) => {
    const { checked, showCheckbox, children, onChange } = props;
    return showCheckbox ? (
        <Checkbox checked={checked} onChange={onChange}>
            {children}
        </Checkbox>
    ) : (
        children
    );
};

const CountGroup = (props) => {
    const { top, bottom } = props;
    return (
        <div className="highlight-count-groups">
            <div className="highlight-count-group-content">{top}</div>
            <div className="highlight-count-group-content split">-</div>
            <div className="highlight-count-group-content">{bottom}</div>
        </div>
    );
};
// let timer2: any = null;

const HighLightControl = (props) => {
    const {
        highlightGroups,
        currentIndex,
        goToCurrentIndex,
        changeCurrentIndex,
        closeHighlight,
        changeToHighlightItemsCallDetail,
        searchHighlightKeywords,
        // searchHighlightRoleType,
    } = props;
    const [currentIndexInGroup, setCurrentIndexInGroup] = useState(0);

    const groupIndexChange = useCallback(
        (nextOrPrev) => {
            let next =
                nextOrPrev === 'next'
                    ? currentIndex + 1 < highlightGroups.length
                        ? currentIndex + 1
                        : currentIndex
                    : currentIndex - 1 >= 0
                    ? currentIndex - 1
                    : currentIndex;
            if (next === currentIndex) return;
            // const [prevElement] = Array.from(document.getElementsByClassName(`current-highlight-element`));
            // const { groupIndex, itemIndex } = highlightGroups[currentIndex]?.highlightItemList?.[0];
            // prevElement?.setAttribute('class', `high-light-prev ${groupIndex}-${itemIndex}`);
            changeCurrentIndex(next);
        },
        [currentIndex, highlightGroups]
    );

    const next = useCallback(() => {
        groupIndexChange('next');
    }, [groupIndexChange]);

    const prev = useCallback(() => {
        groupIndexChange('prev');
    }, [groupIndexChange]);
    const innerIndexChange = useCallback(
        (nextOrPrev) => {
            let next =
                nextOrPrev === 'next'
                    ? currentIndexInGroup + 1 <
                      highlightGroups[currentIndex]?.highlightItemList?.length
                        ? currentIndexInGroup + 1
                        : currentIndexInGroup
                    : currentIndexInGroup - 1 >= 0
                    ? currentIndexInGroup - 1
                    : currentIndexInGroup;
            if (next === currentIndexInGroup) return;
            // const [prevElement] = Array.from(document.getElementsByClassName(`current-highlight-element`));
            // const { groupIndex, itemIndex } = highlightGroups[currentIndex]?.highlightItemList?.[currentIndexInGroup];
            // prevElement?.setAttribute('class', `high-light-prev ${groupIndex}-${itemIndex}`);
            setCurrentIndexInGroup(next);
        },
        [currentIndex, currentIndexInGroup, highlightGroups]
    );

    const prevInner = useCallback(() => {
        innerIndexChange('prev');
    }, [innerIndexChange]);
    const nextInner = useCallback(() => {
        innerIndexChange('next');
    }, [innerIndexChange]);

    useEffect(() => {
        changeToHighlightItemsCallDetail?.(highlightGroups[currentIndex]?.callId);
        goToCurrentIndex(highlightGroups[currentIndex]?.highlightItemList?.[0].index - 1);
        setCurrentIndexInGroup(0);
    }, [currentIndex, highlightGroups, searchHighlightKeywords]);
    useEffect(() => {
        if (highlightGroups[currentIndex]?.highlightItemList?.[currentIndexInGroup]) {
            goToCurrentIndex(
                highlightGroups[currentIndex]?.highlightItemList?.[currentIndexInGroup].index - 1,
                currentIndexInGroup
            );
        } else {
            goToCurrentIndex(0);
        }
    }, [currentIndexInGroup, highlightGroups]);
    return (
        <div className="high-light-controls-component">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                }}
            >
                <div>
                    <Button icon={<Icon type="ic-arrow-up-s-line" />} size="small" onClick={prev} />
                    <CountGroup top={currentIndex + 1} bottom={highlightGroups.length} />
                    <Button
                        icon={<Icon type="ic-arrow-down-s-xiajiantou" />}
                        size="small"
                        onClick={next}
                    />
                </div>
                {(highlightGroups[currentIndex]?.highlightItemList?.length ?? 0) > 1 && (
                    <div style={{ marginRight: 8 }}>
                        <Button
                            icon={<Icon type="ic-arrow-up-s-line" />}
                            size="small"
                            onClick={prevInner}
                        />
                        <CountGroup
                            top={currentIndexInGroup + 1}
                            bottom={highlightGroups[currentIndex]?.highlightItemList?.length}
                        />
                        <Button
                            icon={<Icon type="ic-arrow-down-s-xiajiantou" />}
                            size="small"
                            onClick={nextInner}
                        />
                    </div>
                )}
            </div>
            <Button
                type="text"
                icon={
                    <Icon
                        type="ic-close-circle-guanbi"
                        style={{ fontSize: '18px', color: 'rgba(0, 0, 0, .6)' }}
                    />
                }
                size="small"
                onClick={closeHighlight}
            />
        </div>
    );
};
let timer: NodeJS.Timeout | null = null;
const ChatRecordList = forwardRef<any, any>(
    (
        {
            data,
            position,
            height,
            onItemMounted,
            highlightPoint: highlightPointWithParentId,
            allHighlightPoints,
            closeHighlight,
            defaultScrollToTop,
            propsFromDetail,
            manualHighlightIndex,
            virtual,
            searchHighlightKeywords,
            searchHighlightRoleType,
            callId,
            changeToHighlightItemsCallDetail,
            showCheckbox,
            onMessageItemCheckboxClick,
            allHighlightMap: allHighlightMapProps,
            inspectDataSource,
            isDebug,
            // markFlag,
        },
        ref
    ) => {
        if (!allHighlightMapProps) {
            allHighlightMapProps = {};
        }
        const allHighlightMap = useMemo(() => {
            console.log('allHighlightMapProps', allHighlightMapProps);
            let ret = {};
            for (let key in allHighlightMapProps) {
                ret[key] = allHighlightMapProps[key]
                    .filter((t) => t.highlightTag === 1)
                    .map((p) => p.name);
            }
            return ret;
        }, [allHighlightMapProps]);
        // const allHighlightMap = useMemo(() => {
        //     return allHighlightPoints.filter(i => i._isHit).reduce((map, pointInfo) => {
        //         pointInfo.highlightGroupsList.forEach(highlightGroup => {
        //             highlightGroup.highlightItemList.forEach(highlightItem => {
        //                 if(!Array.isArray(map[highlightItem.index])){
        //                     map[highlightItem.index] = [pointInfo.name];
        //                 }else{
        //                     map[highlightItem.index].push(pointInfo.name);
        //                 }
        //             });
        //         });
        //         return map;
        //     }, {});
        // }, [allHighlightPoints]);

        const highlightPoint =
            typeof highlightPointWithParentId === 'string'
                ? highlightPointWithParentId.split('-')[0] === 'smartTags' ||
                  highlightPointWithParentId.split('-')[0] === 'flowNode'
                    ? highlightPointWithParentId.split('-')[1]
                    : parseInt(highlightPointWithParentId.split('-')[1], 10)
                : searchHighlightKeywords ?? null;
        const parentId =
            typeof highlightPointWithParentId === 'string'
                ? highlightPointWithParentId.split('-')[0] === 'smartTags' ||
                  highlightPointWithParentId.split('-')[0] === 'flowNode'
                    ? highlightPointWithParentId.split('-')[0]
                    : parseInt(highlightPointWithParentId.split('-')[0], 10)
                : null;
        const listRef = useRef<VirtualListRef | null>(null);
        useImperativeHandle(ref, () => {
            return Object.assign({}, listRef.current, {
                scrollTo: virtual
                    ? listRef.current?.scrollTo
                    : () => {
                          const element =
                              document.getElementsByClassName('current-playing-item')[0];
                          element && (element as any).scrollIntoViewIfNeeded?.();
                      },
                // current-playing-item
            });
        });
        /**
         * highlightIndex 当前高亮对应的高亮组索引
         */
        const [highlightIndex, setHighlightIndex] = useState<number>(0);
        /**
         * highlightSeq 当前高亮对应的句子索引
         */
        const [highlightSeq, setHighlightSeq] = useState<number>(0);
        const [highlightGroups, setHighlightGroups] = useState<any[]>([]);
        const [dataWithHighlight, setDataWithHighlight] = useState([]);
        /**
         * currentHighlightGroup 当前正在高亮状态的高亮信息
         */
        const [currentHighlightGroup, setCurrentHighlightGroup] = useState<any[]>([]);
        /**
         * prevHighlightGroupsList 储存当前质检点、自能标签、搜索关键字对应的高亮信息
         */
        const [prevHighlightGroupsList, setPrevHighlightGroupsList] = useState<any[]>([]);
        /**
         * searchKeyWordHighlightGroups 储存关键词搜索生成的高亮信息
         */
        const [searchKeyWordHighlightGroups, setSearchKeyWordHighlightGroups] = useState<any[]>([]);
        const [checkedMessageList, setCheckedMessageList] = useState<(number | string)[]>([]);

        useEffect(() => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                let dialogSentenceListWithHighlight = _cloneDeep(data ?? []);
                let allHighlightItems: any[] = [];
                allHighlightPoints?.forEach((point) => {
                    allHighlightItems = [
                        ...allHighlightItems,
                        ...(point.highlightGroupsList ?? []).map((g) =>
                            (g.highlightItemList ?? []).map((i) => ({
                                ...i,
                                pointId: point.pointId,
                                callId: g.callId,
                            }))
                        ),
                    ];
                });
                if (searchHighlightKeywords) {
                    let highlightGroupsList: any[] = [];
                    dialogSentenceListWithHighlight.forEach((item, index) => {
                        if (
                            item.sender !== searchHighlightRoleType &&
                            searchHighlightRoleType !== 'all'
                        )
                            return;
                        let content = '';
                        let type = '';
                        if (item?.content) {
                            if (typeof item?.content === 'string') {
                                let _data = JSON.parse(item.content);
                                content = _data?.data?.content ?? '';
                                type = _data.type;
                            } else if (typeof item?.content === 'object') {
                                content = item.content?.data?.content ?? '';
                                type = item.content?.type;
                            }
                        }
                        // if(content && type === 'rich'){
                        //     content = content.replace(/<[/]?[^<]+>/g, '');
                        // }
                        const findAll = (str, target) => {
                            if (typeof str !== 'string' || type === 'unknown') return [];
                            const div = document.createElement('div');
                            div.innerHTML = str;
                            str = div.innerText;
                            let position = str.indexOf(target);
                            let ret: number[] = [];
                            while (position !== -1) {
                                ret.push(position);
                                position = str.indexOf(target, position + 1);
                            }
                            return ret;
                        };
                        let starts = findAll(content, searchHighlightKeywords);
                        if (starts.length === 0) return;
                        const createHighlightGroup = (start, index, length) => {
                            return {
                                callId: null,
                                highlightItemList: [
                                    {
                                        groupIndex: highlightGroupsList.length,
                                        highlightType: 1,
                                        index: item.highlightMessageIndex,
                                        itemIndex: 0,
                                        length,
                                        start,
                                    },
                                ],
                            };
                        };
                        starts.forEach((start) => {
                            highlightGroupsList.push(
                                createHighlightGroup(
                                    start,
                                    index + 1,
                                    searchHighlightKeywords.length
                                )
                            );
                        });
                    });
                    setSearchKeyWordHighlightGroups(highlightGroupsList);
                    setHighlightIndex(0);
                } else {
                    setSearchKeyWordHighlightGroups([]);
                }
                if (dialogSentenceListWithHighlight.length > 0) {
                    dialogSentenceListWithHighlight[
                        dialogSentenceListWithHighlight.length - 1
                    ].isLast = true;
                }
                setDataWithHighlight(dialogSentenceListWithHighlight);

                timer = null;
            }, 100);
        }, [allHighlightPoints, data, searchHighlightKeywords, searchHighlightRoleType, callId]);
        const dataDidUpdate = (data, prevData, listRef) => {
            //仅使用defaultScrollToTop模拟非加载更多的情况
            if (defaultScrollToTop) {
                return;
            }
            listRef.scrollTo({
                index: data.length - prevData.length,
                align: 'auto',
            });
        };
        const goToCurrentIndex = (index, currentHighlightInGroupIndex) => {
            if (typeof index === 'number') {
                setHighlightSeq(index);
                //虚拟化/非虚拟化需要不同方式跳转至指定位置 使用virtual字段区分
                if (virtual) {
                    const getElements = () => {
                        setTimeout(() => {
                            listRef.current?.scrollTo({
                                index: index,
                                align: 'auto',
                            });
                            const elements =
                                document.getElementsByClassName('current-highlight-item');
                            const timeElements =
                                document.getElementsByClassName('current-high-light-item');
                            if (!elements?.[0] && !timeElements?.[0]) {
                                getElements();
                            }
                        }, 200);
                    };
                    getElements();
                } else {
                    const getElements = () => {
                        setTimeout(() => {
                            const elements =
                                document.getElementsByClassName('current-highlight-item');
                            if (!elements?.[0]) {
                                getElements();
                            } else {
                                (
                                    elements[currentHighlightInGroupIndex ?? 0] as any
                                )?.scrollIntoViewIfNeeded?.();
                            }
                        }, 200);
                    };
                    getElements();
                }
            }
        };

        const onCheckboxClick = useCallback(
            (id, e) => {
                let newCheckedMessageList = [...checkedMessageList];
                if (newCheckedMessageList.includes(id)) {
                    newCheckedMessageList = newCheckedMessageList.filter((i) => i !== id);
                } else {
                    newCheckedMessageList.push(id);
                }

                setCheckedMessageList(newCheckedMessageList);
                onMessageItemCheckboxClick?.(newCheckedMessageList);
            },
            [checkedMessageList, onMessageItemCheckboxClick]
        );

        useEffect(() => {
            setCheckedMessageList([]);
        }, [showCheckbox]);

        const itemRender = useCallback(
            ({ item, index }) => {
                let itemData: any = {};
                //兼容语音ASR转义后的文本信息
                if (item.__type === 'call') {
                    itemData = item?.content ?? {};
                } else {
                    itemData = item?.content ? JSON.parse(item.content) : {};
                }
                return (
                    <CheckboxWrapper
                        checked={checkedMessageList.includes(item.id)}
                        showCheckbox={showCheckbox}
                        onChange={onCheckboxClick.bind(null, item.id)}
                    >
                        <ChatCardItem
                            index={index}
                            //这个地方解构了item，是为了避免组件频繁的重复渲染，在ChatCardItem组件内部同样会以解构的形式再组装回解构前的样子
                            // itemData={item}
                            {...item}
                            highlightPoint={highlightPoint}
                            currentHighlightGroup={currentHighlightGroup}
                            prevHighlightGroupsList={prevHighlightGroupsList}
                            searchHighlightKeywords={searchHighlightKeywords}
                            key={item.id}
                            isSysMessage={[
                                'transfer_send',
                                'survey',
                                'form',
                                'form_received',
                                'info_transfer',
                                'customer_close',
                                'invitation',
                                'lock',
                                'start_session',
                                'shardingInformation',
                            ].includes(itemData.type)}
                            // isTicket={itemData.type === 'ticket'}
                            isMe={item.sender === 'agent'}
                            virtual={virtual}
                            // onItemMounted={onItemMounted}
                            currentHighlightSeq={highlightSeq}
                            manualHighlightIndex={manualHighlightIndex}
                            propsFromDetail={propsFromDetail}
                            // markFlag={markFlag}
                            callId={callId}
                            hitPointTags={allHighlightMap[index + 1]}
                            inspectDataSource={inspectDataSource}
                            isDebug={isDebug}
                        />
                    </CheckboxWrapper>
                );
            },
            [
                checkedMessageList,
                inspectDataSource,
                callId,
                highlightSeq,
                highlightPoint,
                propsFromDetail,
                manualHighlightIndex,
                currentHighlightGroup,
                searchHighlightKeywords,
                showCheckbox,
                onCheckboxClick,
                virtual,
                allHighlightMap,
            ]
        );
        useEffect(() => {
            setHighlightSeq(0);
            setCurrentHighlightGroup([]);
        }, [propsFromDetail]);
        useEffect(() => {
            setHighlightIndex(0);
        }, [highlightPoint]);

        useEffect(() => {
            // setHighlightIndex(0);
            if (searchKeyWordHighlightGroups?.length) {
                setCurrentHighlightGroup(searchKeyWordHighlightGroups[highlightIndex]);
                setPrevHighlightGroupsList(searchKeyWordHighlightGroups);
                setHighlightGroups(searchKeyWordHighlightGroups);
            } else {
                const currentHighLight = allHighlightPoints?.find(
                    (p) => p.pointId === highlightPoint && p.parentId === parentId
                );
                setHighlightGroups(currentHighLight?.highlightGroupsList ?? []);
                // goToCurrentIndex(0);
                const highlightGroupsList =
                    allHighlightPoints?.find((i) => i.pointId === highlightPoint)
                        ?.highlightGroupsList ?? [];
                setCurrentHighlightGroup(highlightGroupsList[highlightIndex]);
                setPrevHighlightGroupsList(highlightGroupsList);
            }
        }, [highlightPoint, allHighlightPoints, highlightIndex, searchKeyWordHighlightGroups]);
        return (
            <div
                className="udesk-qa-chat-record-list-component"
                style={{
                    width: '100%',
                    height,
                    overflowY: 'auto',
                    /* position: 'relative' */ scrollBehavior: 'smooth',
                }}
            >
                <VirtualList
                    dataDidUpdate={dataDidUpdate}
                    data={dataWithHighlight}
                    height={virtual ? height : 0}
                    itemRender={itemRender}
                    ref={listRef}
                />
                {highlightPoint && Boolean(highlightGroups?.length) && (
                    <HighLightControl
                        highlightGroups={highlightGroups}
                        currentIndex={highlightIndex}
                        changeCurrentIndex={setHighlightIndex}
                        goToCurrentIndex={goToCurrentIndex}
                        closeHighlight={closeHighlight}
                        changeToHighlightItemsCallDetail={changeToHighlightItemsCallDetail}
                        searchHighlightKeywords={searchHighlightKeywords}
                        searchHighlightRoleType={searchHighlightRoleType}
                    />
                )}
            </div>
        );
    }
);

export default React.memo(ChatRecordList);
