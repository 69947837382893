// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerNodeTraineeConfigQuery,
    BaseResponseIntelligentPartnerDialogNodeTraineeConfigFoundResponse,
    PostReviewIntelligentPartnerNodeTraineeConfigBody,
} from '../../../../types';

/**
 * 查询学员节点配置
 *
 * @export
 * @tags 智能陪练-学员对话节点配置
 * @link [GET] /review/intelligentPartner/node/traineeConfig
 */
export function getReviewIntelligentPartnerNodeTraineeConfig(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodeTraineeConfigQuery> & {
        params: GetReviewIntelligentPartnerNodeTraineeConfigQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeTraineeConfigFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerNodeTraineeConfigQuery>(
        '/review/intelligentPartner/node/traineeConfig',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeTraineeConfig',
        }
    );
}

/**
 * 保存学员节点配置
 *
 * @export
 * @tags 智能陪练-学员对话节点配置
 * @link [POST] /review/intelligentPartner/node/traineeConfig
 */
export function postReviewIntelligentPartnerNodeTraineeConfig(
    data: PostReviewIntelligentPartnerNodeTraineeConfigBody
): Promise<BaseResponseIntelligentPartnerDialogNodeTraineeConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeTraineeConfig(
    data: PostReviewIntelligentPartnerNodeTraineeConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeTraineeConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeTraineeConfig(
    data: PostReviewIntelligentPartnerNodeTraineeConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeTraineeConfigFoundResponse> {
    return request<PostReviewIntelligentPartnerNodeTraineeConfigBody, never, never>(
        '/review/intelligentPartner/node/traineeConfig',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeTraineeConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-学员对话节点配置'],
        path: '/review/intelligentPartner/node/traineeConfig',
        method: 'get',
    },
    {
        tags: ['智能陪练-学员对话节点配置'],
        path: '/review/intelligentPartner/node/traineeConfig',
        method: 'post',
    },
];
