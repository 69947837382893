import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Modal } from 'antd';
import Udesk from "../../udesk/index";
import Locales from '../../udesk/locales/index';
import 'antd/lib/modal/style/index.css';
import './index.css';
import '../../fonts/iconfont.css';

// 在这里写死ant，由于重写的样式，而且用css的方式引入的，不支持动态修改变量。
// 如果希望支持动态antPrefix，需要对业务项目改造，都支持less编译，
// 但早期的react项目不支持less，只支持sass
const ANT_PREFIX = "ant-modal";

class ReactModal extends React.Component {
    privates = {}

    static computes = {
        _title: ({ props, state, privates, locales }) => {
            let {
                title
            } = props;
            if (typeof title === "string") {
                let {
                    closable,
                    closeIconClass,
                    onCancel,
                    headerClassName
                } = props;
                title = (
                    <If condition={title}>
                        <div className={headerClassName}>
                            {title}
                            <If condition={closable}>
                                <i className={ClassNames("close-modal", { [`${closeIconClass}`]: closeIconClass })} onClick={(e) => onCancel(e)}></i>
                            </If>
                        </div>
                    </If >
                );
            }
            return title;
        },
        _closable: ({ props, state, privates, locales }) => {
            let {
                title,
                closable
            } = props;
            if (typeof title === "string") {
                closable = false;
            }
            return closable;
        },
        _footer: ({ props, state, privates, locales }) => {
            let {
                footer
            } = props;
            if (footer === undefined) {
                let {
                    onOk,
                    onCancel,
                    okText,
                    cancelText,
                    okClassName,
                    cancelClassName,
                    sdkOptions,
                    footerClassName
                } = props;
                let language = sdkOptions.props && sdkOptions.props.language;
                if (cancelText === "" || cancelText == null) {
                    cancelText = Locales.get("labels.cancel", language);
                }
                if (okText === "" || okText == null) {
                    okText = Locales.get("labels.OK", language);
                }

                footer = (
                    <div className={footerClassName}>
                        <button type="button" className={ClassNames({ [`${cancelClassName}`]: cancelClassName })} onClick={(e) => onCancel(e)}>
                            {cancelText}
                        </button>
                        <button type="button" className={ClassNames({ [`${okClassName}`]: okClassName })} onClick={(e) => onOk(e)}>
                            {okText}
                        </button>
                    </div >
                );
            }
            return footer;
        }
    }

    render() {
        let {
            title,
            footer,
            closable,
            sdkOptions,
            ...other
        } = this.props;

        let {
            privates: { computes },
        } = this;

        return (
            <If condition={this.props.visible}>
                <Modal prefixCls={ANT_PREFIX} title={computes._title} footer={computes._footer} closable={computes._closable} {...other}>
                    {this.props.children}
                </Modal>
            </If >
        );
    }
}

ReactModal.propTypes = {
    afterClose: PropTypes.func,
    bodyStyle: PropTypes.object,
    cancelText: PropTypes.string,
    closable: PropTypes.bool,
    closeIconClass: PropTypes.string,
    confirmLoading: PropTypes.bool,
    destroyOnClose: PropTypes.bool,
    mask: PropTypes.bool,
    maskClosable: PropTypes.bool,
    maskStyle: PropTypes.object,
    okText: PropTypes.string,
    okType: PropTypes.string,
    style: PropTypes.object,
    visible: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    wrapClassName: PropTypes.string,
    zIndex: PropTypes.number,
    cancelClassName: PropTypes.string,
    okClassName: PropTypes.string,
    sdkOptions: PropTypes.object,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,

    headerClassName: PropTypes.string,
    footerClassName: PropTypes.string
};

ReactModal.defaultProps = {
    afterClose: null,//Modal 完全关闭后的回调
    bodyStyle: {},//Modal body 样式
    cancelText: "",//取消按钮文字
    closable: true,//是否显示右上角的关闭按钮
    closeIconClass: "udesk-react-iconfont icon-udesk-react-close",
    confirmLoading: false,//确定按钮 loading
    destroyOnClose: true,//关闭时销毁 Modal 里的子元素
    footer: undefined,//底部内容，当不需要默认底部按钮时，可以设为 footer={null}
    getContainer: undefined,//指定 Modal 挂载的 HTML 节点
    mask: true,//是否展示遮罩
    maskClosable: true,//点击蒙层是否允许关闭
    maskStyle: {},//遮罩样式
    okText: "",//确认按钮文字
    // okType: "primary",//确认按钮类型
    style: null,//可用于设置浮层的样式，调整浮层位置等
    title: null,//标题
    visible: false,//对话框是否可见
    width: 520,
    wrapClassName: "",//对话框外层容器的类名
    zIndex: 2000,
    cancelClassName: "btn btn-default",
    okClassName: "btn btn-success",
    sdkOptions: {},
    onOk: null,
    onCancel: null,

    headerClassName: '',
    footerClassName: ''
};

export default Udesk.react.udeskify(ReactModal);
