export default {
  lang: {
    export: 'エクスポート',
    successfully: 'エクスポートタスク作成成功',
    pleaseJumpTo: 'にジャンプしてください',
    uploadAndDownloadCenter: 'アップロードダウンロードセンター',
    check: '表示',
    exportAll: 'すべてをエクスポートしますか?',
    total: '合計',
    confirm: 'データをエクスポートしますか?',
    error: 'エクスポート可能データ数量は0です。フィルタ条件を変更してから再試行してください。',
  },
};
