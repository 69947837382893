// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostUsersExportBody, BaseResponseVoid } from '../../types';

/**
 * 导出员工
 *
 * @export
 * @tags 用户
 * @link [POST] /users/export
 */
export function postUsersExport(data: PostUsersExportBody): Promise<BaseResponseVoid>;
export function postUsersExport(
    data: PostUsersExportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postUsersExport(
    data: PostUsersExportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostUsersExportBody, never, never>('/users/export', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postUsersExport',
    });
}

export const meta = [{ tags: ['用户'], path: '/users/export', method: 'post' }];
