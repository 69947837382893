import OptionsBuilderClass from '../options-builder';
import common from '../../../../udesk/index';
import echarts from 'echarts';
class RawPie extends OptionsBuilderClass {

    getLegendOptions(optionsOwner) {
        let legend = super.getLegendOptions(...arguments);

        let {
            records,
            legendLimit
        } = optionsOwner.props;

        legend.data = [];
        legend.selected = {};
        legend.formatter = function (name) {
            return echarts.format.truncateText(name, 90, "Microsoft Yahei", '...');
        };
        legend.tooltip = {
            show: true
        };

        if (!records) {
            return false;
        }

        let legendCounter = 0;
        for (var i = 0; i < records.length; i++) {
            let record = records[i];
            if (record == null) {
                continue;
            }
            let label = record.xLabel;
            if (label != null && label !== "") {
                legend.data.push(label);
                legendCounter++;
                if (legendCounter >= legendLimit) {
                    break;
                }
            }
        }
        return legend;
    }

    _formatSeriesLabelValue(newParams, optionsOwner) {
        let {
            seriesColumns,
            records,
            showExtraPercentInLabels,
            showSeriesLabels,
            seriesLabelContentMode
        } = optionsOwner.props;

        if (seriesColumns == null || seriesColumns.length === 0 ||
            records == null || records.length === 0) {
            return newParams.value;
        }

        let fieldName = seriesColumns[0]['field'];
        let value = this._getFieldOriginalValue(newParams.record, fieldName);

        if (seriesLabelContentMode === "recordValue") {
            if (showSeriesLabels && showExtraPercentInLabels) {
                let percentage = !isNaN(newParams.originParams.percent) ? newParams.originParams.percent : (0).toFixed(2);
                return `${newParams.record.xLabel} : ${value} (${percentage}%)`;
            } else if (showSeriesLabels) {
                return `${newParams.record.xLabel} : ${value}`;
            } else if (showExtraPercentInLabels) {
                return `${newParams.record.xLabel} :  ${newParams.originParams.percent}%`;
            }
        } else if (seriesLabelContentMode === "recordXLabel") {
            return newParams.record.xLabel;
        }
    }

    _decorateSeries(series, optionsOwner) {
        series = super._decorateSeries(...arguments);
        let newSeries = series.slice(0, 1);

        let {
            pieLikeCenter,
            pieLikeRaduis
        } = optionsOwner.props;


        for (let seriesItem of newSeries) {
            seriesItem.radius = pieLikeRaduis ? pieLikeRaduis : this.getRadius(optionsOwner);

            if (pieLikeCenter && pieLikeCenter.length === 2) {
                seriesItem.center = [].concat(pieLikeCenter);
            }
        }
        return newSeries;
    }

    getRadius(optionsOwner) {
        return null;
    }
    _getChartType() {
        return common.enums.echartTypes.pie.key;
    }

    _getLabelPosition() {
        return "outside";
    }
}

export default RawPie;
