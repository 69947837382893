import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { useRequest } from 'src/util/hook';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { StandardizeTable } from 'udesk_gm_ui';
import { EnumSelect } from 'Component/common/enum-component';
import { linkTo } from 'src/util/link';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { beautifulTime } from 'src/util/moment';
import { useTableBtn } from 'src/util/hook/table';
import { createComponent } from 'src/util/component';
import { Badge, Button } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const Template = React.memo((props: any) => {
    const {
        updateStorages,
        storages: { pagination, filterData },
    } = props;
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const request = useRequest();
    const { submitFilterForm } = useTableBtn('task-center-review-page-index');

    const getTableSource = useCallback(
        (data) => {
            const { filter, pagination } = data;
            updateStorages(false, () => {
                return {
                    filterData: filter,
                    pagination: pagination,
                };
            });

            const params = {
                ...filter,
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            };

            setLoading(true);
            request('/intelligentPartner/check/record/findAll', params)
                .then(
                    (resp) => {
                        setTableData(resp.data ?? []);
                        updateStorages(() => {
                            return {
                                pagination: {
                                    current: resp.paging.pageNum,
                                    total: resp.paging.total,
                                },
                            };
                        });
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                )
                .finally(() => {
                    setLoading(false);
                });
        },
        [updateStorages, request]
    );

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 员工姓名 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .employeeName,
                key: 'userName',
                dataIndex: 'userName',
            },
            {
                title: /* 得分 */ UdeskLocales['current'].pages.coach.taskCenter.review.index.score,
                key: 'score',
                dataIndex: 'score',
            },
            {
                title: /* 得分结果 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .scoringResults,
                key: 'scoreResult',
                dataIndex: 'scoreResult',
                render: (name, item, index) => {
                    return Udesk.enums.learningTaskScoreType.getName(name);
                },
            },
            {
                title: /* 时长 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .duration,
                key: 'costTime',
                dataIndex: 'costTime',
                render: beautifulTime,
            },
            {
                title: /* 任务名称 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .taskName,
                key: 'taskName',
                dataIndex: 'taskName',
            },
            {
                title: /* 申诉话术数量 */ UdeskLocales['current'].pages.coach.taskCenter.review
                    .index.numberOfAppealScripts,
                key: 'appealWordNum',
                dataIndex: 'appealWordNum',
            },
            {
                title: /* 申诉时间 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .appealTime,
                key: 'appealTime',
                dataIndex: 'appealTime',
            },
            {
                title: /* 流程状态 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .processStatus,
                key: 'status',
                dataIndex: 'status',
                render: (name, item, index) => {
                    return Udesk.enums.taskCenterReviewStatus.getName(name);
                },
            },
            {
                title: /* 复核后得分 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .scoreAfterReview,
                key: 'scoreAfterCheck',
                dataIndex: 'scoreAfterCheck',
            },
            {
                title: /* 复核人 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .reviewedBy,
                key: 'checkerName',
                dataIndex: 'checkerName',
            },
            {
                title: /* 操作 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .operation,
                key: 'id',
                // renderType: 'actions',
                fixed: 'right',
                render: (text, record) => {
                    return (
                        <Badge
                            count={
                                // 0 - 未查看 1 - 已查看
                                record.viewStatus === 0 ? (
                                    <div
                                        style={{
                                            color: '#fff',
                                            background: '#ff4d4f',
                                            borderRadius: '12px',
                                            padding: '6px 10px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        new
                                    </div>
                                ) : (
                                    0
                                )
                            }
                        >
                            <Button
                                type="link"
                                onClick={() => {
                                    linkTo(
                                        props.history,
                                        getCurrentRouteName('taskCenterReviewDetail'),
                                        {
                                            id: record.recordId,
                                            reviewId: record.id,
                                        }
                                    );
                                }}
                            >
                                {
                                    /* 查看得分情况 */ UdeskLocales['current'].pages.coach
                                        .taskCenter.review.index.viewScoreStatus
                                }
                            </Button>
                        </Badge>
                    );
                },
            },
        ];
        return {
            columns,
            dataSource: tableData,
            pagination,
            loading,
        };
    }, [tableData, pagination, loading, props.history]);

    const filterConfig = useMemo<any>(() => {
        return {
            customComponents: {
                EnumSelect,
            },
            fields: [
                {
                    name: 'status',
                    label: /* 流程状态 */ UdeskLocales['current'].pages.coach.taskCenter.review
                        .index.processStatus,
                    type: 'EnumSelect',
                    props: {
                        width: 130,
                        enumKey: 'taskCenterReviewStatus',
                        empty: {
                            label: /* 全部 */ UdeskLocales['current'].pages.coach.taskCenter.review
                                .index.whole,
                        },
                    },
                },
            ],
            initialValues: filterData,
        };
    }, [filterData]);

    useEffect(() => {
        $('.task-center-review-page-index .udesk-qa-ui-common-filter').on(
            'click',
            '.udesk-qa-ui-btn-default',
            (event, isTree) => {
                updateStorages(false, {
                    filterData: {},
                });
                if (!isTree) {
                    submitFilterForm();
                }
                return !!isTree;
            }
        );
    }, [submitFilterForm, updateStorages]);

    return (
        <Page
            pageBodyClassName="task-center-review-page-index"
            title={
                /* 人工复核 */ UdeskLocales['current'].pages.coach.taskCenter.review.index
                    .manualReview
            }
            backGroundGhost={true}
            bodyStyle={{
                height: '100%',
            }}
        >
            <StandardizeTable.RequestTable
                onRequest={getTableSource}
                table={tableProps}
                filter={filterConfig as any}
            />
        </Page>
    );
});

export default createComponent(Template, undefined, [
    /^\/coach\/task-center\/review\/*/i,
    /^\/coach\/task-center\/review-detail\/*/i,
]);
