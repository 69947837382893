import React from 'react';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import ReactFlow, {
    MiniMap,
    Background,
    ReactFlowProvider,
    Controls,
    ControlButton,
} from 'reactflow';

import 'reactflow/dist/style.css';
// import { postReviewIntelligentPartnerCourse } from 'src/api/review/intelligentPartner/course';
import { Sidebar } from './Sidebar';
import { useFlow } from './useFlow';
import { Divider, Icon, Space } from 'udesk-ui';
import { useControlButtons } from './useControlButtons';

// const courseId = 1;
// const flowId = 1;

const InternalFlow = React.memo((props: any) => {
    // const locales = Locales['current'];

    const {
        reactFlowWrapper,
        // onInteractiveChange,
        currentSelectedNode,
        reactFlowInstance,
        store,
        undoAble,
        redoAble,
        autoLayout,
        readOnly,
        isPPTFlow,
        evaluationMode,
        ...reactFlowProps
    } = props;

    const { btns, onZoom } = useControlButtons(
        reactFlowInstance,
        currentSelectedNode,
        store,
        undoAble,
        redoAble,
        autoLayout,
        readOnly,
        isPPTFlow,
        evaluationMode,
    );
    
    return (
        <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow {...reactFlowProps} fitView zoomOnScroll={false} panOnScroll={true} zoomActivationKeyCode={['Alt']} onMove={onZoom}>
                    <Controls
                        position="top-center"
                        // onInteractiveChange={onInteractiveChange}
                        className="control-button-bar"
                        showFitView={false}
                        showZoom={false}
                        showInteractive={false}
                    >
                        <Space split={<Divider type="vertical" />}>
                            {btns.map((btns) => {
                                return (
                                    <Space>
                                        {btns.map((b) => {
                                            return (
                                                <ControlButton onClick={b.onClick}>
                                                    <div style={(b as any).style} title={b.label}>
                                                        <Space>
                                                            {b.icon && (
                                                                <Icon
                                                                    type={b.icon}
                                                                    antdIcon={true}
                                                                />
                                                            )}
                                                            {b.label && <span>{b.label}</span>}
                                                        </Space>
                                                    </div>
                                                </ControlButton>
                                            );
                                        })}
                                    </Space>
                                );
                            })}
                        </Space>
                    </Controls>
                    <MiniMap />
                    <Background />
                </ReactFlow>
            </div>
        </ReactFlowProvider>
    );
});

type FlowType = typeof InternalFlow & {
    Sidebar: typeof Sidebar;
    useFlow: typeof useFlow;
};

const Flow: FlowType = InternalFlow as FlowType;
Flow.Sidebar = Sidebar;
Flow.useFlow = useFlow;

export { Flow };
