// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type { BaseResponseListIntelligentPartnerAiTextToSqlFoundResponse } from '../../../../../types';

/**
 * 查询历史记录
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [GET] /intelligentPartner/ai/analysis/query/history
 */
export function getIntelligentPartnerAiAnalysisQueryHistory(): Promise<BaseResponseListIntelligentPartnerAiTextToSqlFoundResponse>;
export function getIntelligentPartnerAiAnalysisQueryHistory(
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerAiTextToSqlFoundResponse>;
export function getIntelligentPartnerAiAnalysisQueryHistory(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerAiTextToSqlFoundResponse> {
    return request<never, never, never>('/intelligentPartner/ai/analysis/query/history', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerAiAnalysisQueryHistory',
    });
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/query/history',
        method: 'get',
    },
];
