import React from 'react';
import PropTypes from 'prop-types';
import Udesk from '../../udesk';
import '../../udesk-plugin-installers/utils/web/safe-html';
import locales from '../../udesk/locales/index';
import Checkbox from '../check-box';
import { setTimeout } from 'timers';
import CustomReactPopover from '../custom-react-popover';
import './base.css';

const DEFAULT_VISIBILITY_SPY_INTERVAL = 100;
const DEFAULT_VISIBILITY_SPY_TRY_TIMES = 10;

const CheckableOptions = [{
    id: 1,
    name: ""
}];

const RowNavigationModes = {
    click: "click",
    link: "link"
};


const STORAGE_KEY_PREFIX = "udeskBi.React-table.columns";

// TODO: <a>链接需要加上href="#blank"，action要调用preventDefault方法
// TODO: 迁移的不完整，选择列不能自动隐藏
class ReactTable extends React.Component {
    constructor(props) {
        super(props);
        let restoreColumnStates = buildRestoreColumnStates.bind(this)();
        this.state = {
            columns: [],
            oldColumns: [],
            oldItems: [],
            visibleColumns: [],
            restoreColumnStates: restoreColumnStates,
            stackedHeaderRows: [],
            isAllChecked: false,
            isColumnsPanelVisible: false,
            isClickCheckbox: false,
            storageKey: "",
            eventNamespace: "",
            renderingItems: [],
            hoverId: null,
            operableItems: [],

            currentItemMenuShow: false,
        };
        this.savedColumns = null;
    }

    // componentWillMount() {

    // }

    componentDidMount() {
        // from componentWillMount
        computeStorageKey.bind(this)();
        this.initReactTable();
        let {
            columns,
            items
        } = this.props;
        let fullColumns = buildColumns(columns, this);
        this.changeColumns(fullColumns);
        computeOperableItems.bind(this)(items);
        computeAlllCheck.bind(this)(this.props);
        let eventNamespace = computeEventNamespace.bind(this)(this.props);
        this.changeItems(items);
        this.changeColumns(fullColumns);
        let dataTableWrapper = $(this.reactTableElement).find(".data-table-wrapper")[0];
        if (dataTableWrapper != null) {
            let that = this;
            $(dataTableWrapper).off(`scroll.${eventNamespace}`).on(`scroll.${eventNamespace}`, function (e) {
                if (that.isDestroying || that.isDestroyed) {
                    return;
                }

                let lastScrollTop = this.__lastScrollTop;
                let lastScrollLeft = this.__lastScrollLeft;

                if (this.scrollLeft !== lastScrollLeft) {
                    let frozenTableWrapper = $(that.reactTableElement).find(".frozen-table-wrapper");
                    let frozenTable = frozenTableWrapper.find("table.frozen-header-table");
                    // Scrolling horizontally
                    if (this.scrollLeft > lastScrollLeft) {
                        // Scrolling right
                        frozenTable.css({
                            left: `-${this.scrollLeft}px`
                        });
                    } else {
                        // Scrolling left
                        frozenTable.css({
                            left: `-${this.scrollLeft}px`
                        });
                    }
                } else if (this.scrollTop !== lastScrollTop) {
                    let frozenTableColumnWrapper = $(that.reactTableElement).find(".frozen-table-column-wrapper");
                    let frozenTableColumn = frozenTableColumnWrapper.find("table.frozen-column-table");
                    // Scrolling vertically
                    if (this.scrollTop > lastScrollTop) {
                        // Scrolling down
                        // Nothing need to do for vertical scrolling.
                        frozenTableColumn.css({
                            top: `-${this.scrollTop}px`
                        });
                    } else {
                        // Scrolling up
                        // // Nothing need to do for vertical scrolling.
                        frozenTableColumn.css({
                            top: `-${this.scrollTop}px`
                        });
                    }
                }

                this.__lastScrollTop = this.scrollTop;
                this.__lastScrollLeft = this.scrollLeft;
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // from componentWillReceiveProps
        let {
            columns,
            selectedItems,
            items,
            cacheKey,
        } = this.props;
        let {
            columns: oldPropsColumns,
            selectedItems: oldSelectedItems,
            items: oldItems,
            cacheKey: oldCacheKey
        } = prevProps;
        //计算columns并引起依赖于columns的变化
        if (oldPropsColumns !== columns) {
            let fullColumns = buildColumns(columns, this);
            this.changeColumns(fullColumns);
        }
        //计算全部选中
        if (selectedItems !== oldSelectedItems || items !== oldItems) {
            computeOperableItems.bind(this)(items);
            computeAlllCheck.bind(this)(this.props);
        }

        if (cacheKey !== oldCacheKey) {
            computeStorageKey.bind(this)();
        }
        if (items !== oldItems) {
            this.changeItems(items);
        }

        let {
            asyncRender,
            asyncRenderChunkSize
        } = this.props;
        let {
            renderingItems
        } = this.state;
        if (renderingItems == null) {
            renderingItems = [];
        }
        let isAsyncRendering = (renderingItems.length > 0 && renderingItems.length < items.length && items.length > 0);
        let isAsyncRendered = renderingItems.length === items.length;
        asyncRenderChunkSize = parseInt(asyncRenderChunkSize, 10);
        if (isNaN(asyncRenderChunkSize)) {
            asyncRenderChunkSize = 10;
        }
        if (asyncRenderChunkSize === 0) {
            throw new Error("`asyncRenderChunkSize` of react-table must be greater than 0.");
        }
        if (asyncRender) {
            let asyncRenderRecursively = () => {
                if (this.isDestroying || this.isDestroyed) {
                    return;
                }
                if (renderingItems.length < items.length) {
                    let newRenderingItems = (items.slice(renderingItems.length, renderingItems.length + asyncRenderChunkSize));
                    newRenderingItems.forEach((item) => {
                        renderingItems.push(item);
                    });
                    this.setState({
                        renderingItems: renderingItems
                    });
                    if (this.isDestroying || this.isDestroyed) {
                        return;
                    }
                    setTimeout(function () {
                        asyncRenderRecursively();
                    });
                }
                syncFreezeTablePart(this);
            };
            if (!isAsyncRendering && !isAsyncRendered) {
                asyncRenderRecursively();
            }
        } else {
            syncFreezeTablePart(this);
        }
    }

    getSnapshotBeforeUpdate() {

    }

    getDerivedStateFromProps() {

    }

    componentWillUnmount() {
        let {
            eventNamespace
        } = this.state;
        let dataTableWrapper = $(this.reactTableElement).find(".data-table-wrapper")[0];
        if (dataTableWrapper != null) {
            $(dataTableWrapper).off(`scroll.${eventNamespace}`);
        }
        this.reactTableElement = null;
    }

    initReactTable() {
        let {
            enableChooseColumns,
            rememberColumns,
        } = this.props;
        let storageKey = computeStorageKey.bind(this)();
        if (enableChooseColumns) {
            if (rememberColumns && Udesk.browser.storage.localStorage.available) {
                let savedColumnsString = Udesk.browser.storage.localStorage.getItem(storageKey);
                if (savedColumnsString != null) {
                    try {
                        let savedColumnsData = JSON.parse(savedColumnsString);
                        if (!(savedColumnsData instanceof Array)) {
                            throw new Error(`The saved columns must be array.`);
                        }
                        if (savedColumnsData.some(item => typeof item !== "object")) {
                            throw new Error(`Each saved column object must be POJO.`);
                        }
                        let savedColumns = [];
                        for (let item of savedColumnsData) {
                            savedColumns.push(new SavedColumn(item));
                        }
                        this.savedColumns = savedColumns;
                    } catch (ex) {
                        // Udesk.logger.error(`Parse saved columns from local storage failed! See the next log for more details. The cache key is ${storageKey}, and the data is: ${savedColumnsString}`);
                        // Udesk.logger.error(ex);
                    }
                }
            }
        }
    }

    changeItems(items) {
        let {
            asyncRender,
        } = this.props;

        if (asyncRender) {
            this.setState({
                renderingItems: []
            });
        } else {
            this.setState({
                renderingItems: items
            });
        }
    }

    changeColumns(fullColumns) {
        let oldColumns = $.extend(true, [], fullColumns);
        let visibleColumns = getVisibleColumns.bind(this)(fullColumns);
        let stackedHeaderRows = buildStackedHeaderRows.bind(this)(visibleColumns);
        this.setState({
            columns: fullColumns,
            oldColumns,
            visibleColumns,
            stackedHeaderRows
        });
    }

    onCheckAllChanged(value) {
        let {
            operableItems
        } = this;
        let {
            selectedItems
        } = this.props;
        if (value) {
            selectedItems = operableItems.concat([]);
        } else {
            selectedItems = [];
        }
        setSelection.bind(this)(selectedItems);
    }

    reset() {
        setSelection.bind(this)([]);
    }

    sort(fullColumn) {
        let {
            sortDirectionsSeries,
            onSorted
        } = this.props;
        let {
            isColumnsPanelVisible,
            columns: fullColumns
        } = this.state;
        if (!fullColumn.states.sortable || isColumnsPanelVisible) {
            return;
        }
        fullColumns.forEach((col) => {
            if (col.states.sortDirection !== Udesk.enums.sortDirection.none && col !== fullColumn) {
                col.states.sortDirection = Udesk.enums.sortDirection.none;
            }
        });

        let sortDirection = fullColumn.states.sortDirection;
        if (sortDirectionsSeries == null || sortDirectionsSeries.length == null) {
            sortDirectionsSeries = [];
        }
        let nextSortDirection = null;
        let matchIndex = sortDirectionsSeries.indexOf(sortDirection);
        if (matchIndex !== -1) {
            if (matchIndex < sortDirectionsSeries.length - 1) {
                nextSortDirection = sortDirectionsSeries[matchIndex + 1];
            } else if (matchIndex === sortDirectionsSeries.length - 1) {
                nextSortDirection = sortDirectionsSeries[0];
            }
        } else {
            nextSortDirection = sortDirectionsSeries[0];
        }

        if (nextSortDirection == null) {
            return;
        }
        fullColumns.forEach((item) => {
            if (fullColumn === item) {
                fullColumn.states.sortDirection = nextSortDirection;
            }
        });
        this.changeColumns(fullColumns);
        if (onSorted != null) {
            if (typeof onSorted === "function") {
                onSorted(fullColumn.data, nextSortDirection);
            }
        }
    }
    toggleIsColumnsPanelVisible() {
        this.setState((prevState) => {
            return {
                isColumnsPanelVisible: !prevState.isColumnsPanelVisible
            };
        });
    }

    columnVisibilityChanged(fullColumn, e) {
        e.stopPropagation();
        let {
            columns
        } = this.state;
        if (columns == null) {
            columns = [];
        }
        if (!(columns instanceof Array)) {
            columns = [columns];
        }
        columns.forEach((item) => {
            if (item === fullColumn) {
                item.states.checked = e.target.checked;
            }
        });
        this.changeColumns(columns);
        saveCheckedColumns.bind(this)();
        let onColumnsChanged = this.props.onColumnsChanged;
        if (onColumnsChanged != null) {
            if (typeof onColumnsChanged === "function") {
                onColumnsChanged(this.state.columns.filter(fc => isColumnVisible(fc)).map(fc => fc.data));
            }
        }
    }

    clickRow(item, e) {
        let {
            rowClickCancelBubbles,
            rowContentClick,
            rowConentClickContext,
            rowClick,
            rowClickContext
        } = this.props;

        let {
            isClickCheckbox,
        } = this.state;
        if (rowClickCancelBubbles) {
            e.stopPropagation();
        }
        e.preventDefault();
        if (isRowClickable.bind(this)(item)) {
            if (rowContentClick) {
                rowContentClick.apply(rowConentClickContext, [item]);
            }
        }
        if (rowClick && !isClickCheckbox) {
            rowClick.apply(rowClickContext, [item]);
        }
        this.setState({
            isClickCheckbox: false
        });
    }

    mouseEnterRow(index, e) {
        this.setState({
            hoverId: index
        });
    }

    mouseLeaveRow(e) {
        this.setState({
            hoverId: null
        });
    }

    onRowSelectionChanged(item, rowState, e) {
        if (rowState && (rowState.checkboxDisabled || rowState.hideCheckbox)) {
            return false;
        }
        e.stopPropagation();
        this.setState({
            isClickCheckbox: true
        });
        let {
            selectedItems,
            singleSelectMode,
            itemIdentityField
        } = this.props;
        let isAlreadySelected = false;
        if (itemIdentityField == null || itemIdentityField === "") {
            isAlreadySelected = selectedItems.some(i => i === item);
        } else {
            isAlreadySelected = selectedItems.some(i => i[itemIdentityField] === item[itemIdentityField]);
        }
        if (isAlreadySelected) {
            if (itemIdentityField == null || itemIdentityField === "") {
                selectedItems = selectedItems.filter((i) => i !== item);
            } else {
                selectedItems = selectedItems.filter(i => i[itemIdentityField] !== item[itemIdentityField]);
            }
            setSelection.bind(this)(selectedItems);
        } else {
            // 单选模式只能选择一个，后来者冲掉前者
            if (singleSelectMode && selectedItems.length >= 1) {
                selectedItems = [];
            }
            selectedItems.push(item);
            setSelection.bind(this)(selectedItems);
        }
    }

    getReactTableElement(instance) {
        if (instance != null && this.reactTableElement == null) {
            this.reactTableElement = instance;
        }
    }

    /**
     * 列表项动态菜单
     * @param {*} index
     * @param {*} fullColumnData
     * @param {*} item
     * @param {*} e
     */
    mouseEnterTd(index, fullColumn, item, columnIndex, e) {
        const fullColumnData = fullColumn.data;
        const { itemMenuEnable } = this.props; // 是否开启table列表项菜单功能
        if (itemMenuEnable) {
            this.currentItemMenuTarget = e.target;
            setTimeout(() => {
                this.currentItemData = {
                    index,
                    fieldData: {
                        fieldId: fullColumnData.name,
                        fieldType: fullColumnData.type,
                        label: fullColumnData.caption
                    },
                    row: item
                };
                this.activeColumnItemInfo = {
                    colIndex: columnIndex,
                    rowIndex: index,
                    colData: fullColumnData,
                    rowData: item,
                    colDetail: fullColumn,
                };
                this.setState({
                    currentItemMenuShow: true
                });
            }, 300);
        }
    }

    /**
     * 隐藏动态菜单
     */
    handlePopoverClose() {
        this.currentItemMenuTarget = null;
        this.activeColumnItemInfo = null;
        this.currentItemData = null;
        this.setState({
            currentItemMenuShow: false
        });
    }

    /**
     * 列表项动态菜单渲染
     * @returns
     */
    itemMenuRender() {
        const { itemMenuEnable, getItemMenus, onItemMenuClick } = this.props;
        if (itemMenuEnable && getItemMenus && onItemMenuClick && this.currentItemData) {
            const menus = getItemMenus(this.currentItemData.fieldData, this.currentItemData.row);
            if (menus.length > 0) {
                return (
                    <CustomReactPopover
                        containerStyle={{ marginTop: 1, padding: 0 }}
                        arrowStyle={{ bottom: -3 }}
                        placement='top'
                        container={this}
                        target={this.currentItemMenuTarget}
                        show={this.state.currentItemMenuShow}
                        onHide={this.handlePopoverClose.bind(this)}
                    >
                        <div className='list-edit-menu-wrapper' onClick={e => e.stopPropagation()}>
                            <For each='item' of={menus}>
                                <div className='item-col' key={item.key} onClick={(e) => {
                                    const fieldData = { ...this.currentItemData.fieldData };
                                    const row = { ...this.currentItemData.row };
                                    const meta = this.activeColumnItemInfo;
                                    onItemMenuClick(e, item, fieldData, row, {
                                        ...meta, target: this.currentItemMenuTarget,
                                    });
                                    this.handlePopoverClose();
                                }}>
                                    {item.icon}
                                </div>
                            </For>
                        </div>
                    </CustomReactPopover>
                );
            }
        }
        return null;
    }

    render() {
        let {
            horizontalScrollbar,
            showHorizontalScrollbar,
            verticalScrollbar,
            freezeHeader,
            freezeColumn,
            enableChooseColumns,
            maxHeight,
            theme
        } = this.props;
        let tableWrapperClassNames = ["table-wrapper"];
        if (horizontalScrollbar || showHorizontalScrollbar) {
            tableWrapperClassNames.push("horizontal-scrollbar");
        }
        if (verticalScrollbar) {
            tableWrapperClassNames.push("vertical-scrollbar");
        }
        if (freezeHeader) {
            tableWrapperClassNames.push("freeze-header");
        }
        if (freezeColumn) {
            tableWrapperClassNames.push("freeze-columns");
        }
        if (freezeHeader && freezeColumn) {
            tableWrapperClassNames.push("freeze-header-columns");
        }
        if (enableChooseColumns) {
            tableWrapperClassNames.push("choose-columns");
        }
        tableWrapperClassNames = tableWrapperClassNames.join(" ");
        if (maxHeight) {
            if (Number(maxHeight) > 0) {
                maxHeight = maxHeight + "px";
            }
        }


        return (
            <div className={`component-react-table ${theme}`} ref={this.getReactTableElement.bind(this)}>
                <div className={tableWrapperClassNames} style={{ height: maxHeight }}>
                    {enableChooseColumns ? getTabChooseColumns.bind(this)() : null}
                    {freezeHeader ? getFreezeHeaderTable.bind(this)() : null}
                    {freezeColumn ? getFreezeColumnTable.bind(this)() : null}
                    {(freezeHeader && freezeColumn) ? getFreezeHeaderColumnTable.bind(this)() : null}
                    <div className="data-table-wrapper">
                        {getInnerTable.bind(this)()}
                    </div>
                </div>
                {this.itemMenuRender()}
            </div>
        );
    }
}

//计算部分state属性
//计算全部选中
function computeAlllCheck(props) {
    let {
        selectedItems
    } = props;
    let {
        operableItems
    } = this;
    let isAllChecked = false;
    if (selectedItems instanceof Array && operableItems instanceof Array && selectedItems.length > 0 && selectedItems.length === operableItems.length) {
        isAllChecked = true;
    }
    if (this.state.isAllChecked !== isAllChecked) {
        this.setState({
            isAllChecked
        });
    }
}

//计算
function computeStorageKey() {
    let cacheKey = this.props.cacheKey;
    if (cacheKey == null || cacheKey === "") {
        cacheKey = "no_cache_key";
    }
    let storageKey = STORAGE_KEY_PREFIX + "." + cacheKey;
    if (storageKey !== this.state.storageKey) {
        this.setState({
            storageKey
        });
    }
    return storageKey;
}

function computeEventNamespace() {
    let eventNamespace = `react-table-${new Date().getTime()}`;
    this.setState({
        eventNamespace: eventNamespace
    });
    return eventNamespace;
}

function computeOperableItems(items) {
    if (!items) {
        return [];
    }
    this.operableItems = items.filter(item => {
        let checkboxState = innerGetCheckboxState.bind(this)(item) || {};
        return !checkboxState.hideCheckbox && !checkboxState.checkboxDisabled;
    }).concat([]);
}

function innerGetCheckboxState(item) {
    let defaultCheckboxState = {
        hideCheckbox: false,
        checkboxDisabled: false,
    };
    let {
        getCheckboxState
    } = this.props;
    if (getCheckboxState && typeof getCheckboxState === 'function') {
        return Object.assign({}, defaultCheckboxState, getCheckboxState(item));
    }
    return defaultCheckboxState;
}


//页面相关构造方法

function getTabChooseColumns() {
    let {
        chooseColumnsIconClass,
    } = this.props;
    let {
        isColumnsPanelVisible,
        columns,
        visibleColumns
    } = this.state;

    return (
        <div className="columns">
            <a className="columns-trigger" onClick={this.toggleIsColumnsPanelVisible.bind(this)}>
                <i className={`trigger-icon ${chooseColumnsIconClass}`} title={this.locales.components.reactTable.chooseColumns}></i>
            </a>
            <If condition={isColumnsPanelVisible}>
                <div className="columns-wrapper">
                    <dl className="columns-list">
                        {columns.map((fullColumn, fullColumnIndex) => {
                            if (fullColumn.states.visible) {
                                return (
                                    <dd key={`column-item-ley-${fullColumnIndex}`} className="column-item">
                                        <div className="checkbox">
                                            <label className="column-label">
                                                <input
                                                    type="checkbox"
                                                    checked={fullColumn.states.checked}
                                                    disabled={fullColumn.states.disabled || (visibleColumns.length === 1 && contains(fullColumn, visibleColumns))}
                                                    onChange={this.columnVisibilityChanged.bind(this, fullColumn)}
                                                />
                                                <span className="text">{fullColumn.data.caption}</span>
                                            </label>
                                        </div>
                                    </dd>
                                );
                            } else {
                                return ("");
                            }
                        }).filter(Boolean)}
                    </dl>
                </div>
            </If>
        </div>
    );
}

function getFreezeHeaderTable() {
    let {
        tableClassName,
        sortable
    } = this.props;
    let freezeHeaderTableClassNames = [tableClassName, "frozen-header-table"];
    if (sortable) {
        freezeHeaderTableClassNames.push("sortable");
    }
    freezeHeaderTableClassNames = freezeHeaderTableClassNames.join(" ");
    return (
        <div className="frozen-table-wrapper">
            <table className={freezeHeaderTableClassNames}>
                {getTableHeadersColgroup.bind(this)()}
                {getTableHeadersThead.bind(this)()}
            </table>
        </div>
    );
}

function getFreezeColumnTable() {
    let {
        tableClassName,
        sortable
    } = this.props;
    let freezeColumnTableClassNames = [tableClassName, "frozen-column-table"];
    let freezeColumnColspansNumber = getFreezeCellNumbers.bind(this)();
    if (sortable) {
        freezeColumnTableClassNames.push("sortable");
    }
    freezeColumnTableClassNames = freezeColumnTableClassNames.join(" ");
    return (
        <div className="frozen-table-column-wrapper">
            <table className={freezeColumnTableClassNames}>
                {getTableHeadersColgroup.bind(this)(freezeColumnColspansNumber)}
                {getTableHeadersThead.bind(this)(freezeColumnColspansNumber)}
                {getInnerTableTbody.bind(this)(freezeColumnColspansNumber)}
            </table>
        </div>
    );
}

function getFreezeHeaderColumnTable() {
    let {
        tableClassName,
        sortable
    } = this.props;
    let freezeColumnTableClassNames = [tableClassName, "frozen-header-column-table"];
    let freezeColumnColspansNumber = getFreezeCellNumbers.bind(this)();
    if (sortable) {
        freezeColumnTableClassNames.push("sortable");
    }
    freezeColumnTableClassNames = freezeColumnTableClassNames.join(" ");
    return (
        <div className="frozen-table-header-column-wrapper">
            <table className={freezeColumnTableClassNames}>
                {getTableHeadersColgroup.bind(this)(freezeColumnColspansNumber)}
                {getTableHeadersThead.bind(this)(freezeColumnColspansNumber)}
            </table>
        </div>
    );
}

function getTableHeadersColgroup(maxColumnIndex) {
    let {
        checkable,
    } = this.props;
    let {
        visibleColumns
    } = this.state;
    if (visibleColumns == null) {
        visibleColumns = [];
    }
    if (!(visibleColumns instanceof Array)) {
        visibleColumns = [visibleColumns];
    }
    if (maxColumnIndex == null) {
        maxColumnIndex = visibleColumns.length + 1;
    }
    return (
        <colgroup>
            {checkable ? (<col className="checkbox-col" />) : (null)}
            {visibleColumns.map((fullColumn, index) => {
                if (index < maxColumnIndex) {
                    if (fullColumn.data.width || fullColumn.data.width === 0) {
                        return (<col key={index} className="checkbox-col" style={{ width: fullColumn.data.width }} />);
                    } else {
                        return (<col key={index} className="checkbox-col" />);
                    }
                }
                else {
                    return null;
                }
            })}
        </colgroup>
    );
}

function getTableHeadersThead(maxColumnIndex) {
    let {
        checkable,
        singleSelectMode,
        hideNoSortingIcon,
        hideNoSortingIconBehavior,
        sortAscIconClass,
        sortDescIconClass,
        noSortingIconClass,
        languageCode
    } = this.props;
    let {
        stackedHeaderRows,
        visibleColumns,
        isColumnsPanelVisible
    } = this.state;
    let visibleColumnsMaxIndex = maxColumnIndex;
    if (stackedHeaderRows == null) {
        stackedHeaderRows = [];
    }
    if (!(stackedHeaderRows instanceof Array)) {
        stackedHeaderRows = [stackedHeaderRows];
    }
    if (visibleColumns == null) {
        visibleColumns = [];
    }
    if (!(visibleColumns instanceof Array)) {
        visibleColumns = [visibleColumns];
    }
    if (visibleColumnsMaxIndex == null) {
        visibleColumnsMaxIndex = visibleColumns.length + 1;
    }
    return (
        <thead>
            {stackedHeaderRows.map((stackedHeaderRow, stackedHeaderRowIndex) => {
                let colSpansTemp = 0;
                return (
                    <tr key={`stacked-header-row-${stackedHeaderRowIndex}`} className="stacked-header-row">
                        {checkable ? (
                            <th className="stacked-header-col " colSpan="1"></th>
                        ) : (null)}
                        {
                            stackedHeaderRow.map((cell, cellIndex) => {
                                let cellClassNames = cell.stackedHeaderClass != null ? `stacked-header-col ${cell.stackedHeaderClass}` : "stacked-header-col";
                                let cellColSpan = 1;
                                if (cell.colSpan > 1) {
                                    cellColSpan = cell.colSpan;
                                }
                                colSpansTemp += cellColSpan;
                                if (colSpansTemp <= visibleColumnsMaxIndex) {
                                    return (
                                        <th key={`cell-${cellIndex}`} className={cellClassNames} colSpan={cellColSpan}>
                                            {cell.text}
                                        </th>
                                    );
                                }
                                else {
                                    return null;
                                }
                            })
                        }
                    </tr>
                );
            })}
            <tr className="header-row">
                {checkable ? (
                    <th className="checkbox-col">
                        {!singleSelectMode ? (
                            <Checkbox options={CheckableOptions} singleCheckBox={true} value={this.state.isAllChecked} onChanged={this.onCheckAllChanged.bind(this)} />
                        ) : (null)}
                    </th>
                ) : (null)}
                {visibleColumns.map((fullColumn, fullColumnIndex) => {
                    if (fullColumnIndex < visibleColumnsMaxIndex) {
                        let classNames = ["header-col"];
                        if (fullColumn.data.thClass != null) {
                            classNames.push(fullColumn.data.thClass);
                        }
                        if (fullColumn.states.sortable) {
                            classNames.push("sortable");
                        }
                        classNames = classNames.join(" ");
                        return (
                            <th key={fullColumnIndex} className={classNames} onClick={this.sort.bind(this, fullColumn)}>
                                <div className="header-cell-wrapper">
                                    <div className={hideNoSortingIcon ? `header-cell-body hide-no-sorting-icon-${hideNoSortingIconBehavior}` : "header-cell-body"}>
                                        <div className="header-cell-body-item caption-text">{fullColumn.data.caption}</div>
                                        <div className="header-cell-body-item table-actions">
                                            {(fullColumn.states.sortable && !isColumnsPanelVisible) ? (
                                                <a className="action-item">
                                                    {(() => {
                                                        if (fullColumn.states.sortDirection.key === Udesk.enums.sortDirection.ascending.key) {
                                                            return (<i className={`sort-icon ${sortAscIconClass} sorting-${fullColumn.states.sortDirection.key}`} title={locales.get("components.reactTable.sortAsc", languageCode)}></i>);
                                                        } else {
                                                            if (fullColumn.states.sortDirection.key === Udesk.enums.sortDirection.descending.key) {
                                                                return (<i className={`sort-icon ${sortDescIconClass} sorting-${fullColumn.states.sortDirection.key}`} title={locales.get("components.reactTable.sortDesc", languageCode)}></i>);
                                                            } else {
                                                                return (<i className={`sort-icon ${noSortingIconClass} sorting-${Udesk.enums.sortDirection.none.key}`} title={this.locales.components.reactTable.noSorting}></i>);
                                                            }
                                                        }
                                                    })()}
                                                </a>
                                            ) : ("")}
                                        </div>
                                    </div>
                                </div>
                            </th>
                        );
                    }
                    else {
                        return null;
                    }
                })}
            </tr>
        </thead>
    );
}

function getInnerTable() {
    let {
        tableClassName,
        sortable
    } = this.props;
    let innerTableClassNames = [tableClassName, "inner-data-table"];
    if (sortable) {
        innerTableClassNames.push("sortable");
    }
    innerTableClassNames = innerTableClassNames.join(" ");
    return (
        <table className={innerTableClassNames}>
            {getTableHeadersColgroup.bind(this)()}
            {getTableHeadersThead.bind(this)()}
            {getInnerTableTbody.bind(this)()}
        </table>
    );
}

function getInnerTableTbody(maxColumnIndex) {
    let {
        selectedItems,
        activeItemId,
        checkable,
        highlightSortColumn,
        enableHtmlContent,
        rowNavigationMode,
        itemIdentityField
    } = this.props;
    let {
        visibleColumns,
        renderingItems,
        hoverId
    } = this.state;
    if (renderingItems == null) {
        renderingItems = [];
    }
    if (!(renderingItems instanceof Array)) {
        renderingItems = [renderingItems];
    }
    if (visibleColumns == null) {
        visibleColumns = [];
    }
    if (!(visibleColumns instanceof Array)) {
        visibleColumns = [visibleColumns];
    }
    if (maxColumnIndex == null) {
        maxColumnIndex = visibleColumns.length + 1;
    }

    return (
        <tbody>
            {renderingItems.map((item, index) => {
                let rowExtensions = getRowExtensions.bind(this)(item);
                let trClassNames = [];
                if (rowExtensions != null && typeof rowExtensions === "object") {
                    trClassNames.push(rowExtensions.className);
                }
                if (contains(item, selectedItems, itemIdentityField)) {
                    trClassNames.push("selected");
                }
                if ((hoverId != null) && (hoverId === index)) {
                    trClassNames.push("row-item-hover");
                }
                if (isRowClickable.bind(this)(item)) {
                    trClassNames.push("row-clickable");
                }
                if (activeItemId && (item.id === activeItemId)) {
                    trClassNames.push("active-item");
                }
                // 禁用列表复制功能
                if (this.props.copyIsDisabled) {
                    trClassNames.push("list-disable-copy");
                }
                trClassNames = trClassNames.join(" ");
                let rowState = innerGetCheckboxState.bind(this)(item);
                let checkboxClassName = rowState.checkboxDisabled ? "check-box-decorate" : "check-box-decorate check-box-decorate-disabled";
                return (
                    <tr key={`tbody-tr-${index}`} className={trClassNames} onClick={this.clickRow.bind(this, item)} onMouseEnter={this.mouseEnterRow.bind(this, index)} onMouseLeave={this.mouseLeaveRow.bind(this)} >
                        {(checkable ? (
                            <td className="a-center checkbox-col">
                                <If condition={!rowState.hideCheckbox}>
                                    <div className="component-udesk-react-check-box">
                                        <label className="check-box-item">
                                            <input type="checkbox" className="check-box-input" checked={contains.bind(this)(item, selectedItems, itemIdentityField)} disabled="disabled" />
                                            <i className={checkboxClassName} onClick={this.onRowSelectionChanged.bind(this, item, rowState)}></i>
                                        </label>
                                    </div>
                                </If>
                            </td>
                        ) : (null))}
                        {visibleColumns.map((fullColumn, fullColumnIndex) => {
                            if (fullColumnIndex < maxColumnIndex) {

                                let visibleColumnsTdClassNames = ["data-col"];
                                if (fullColumn.data.tdClass != null && fullColumn.data.tdClass !== "") {
                                    visibleColumnsTdClassNames.push(fullColumn.data.tdClass);
                                }
                                if (highlightSortColumn && fullColumn.states.sortDirection !== Udesk.enums.sortDirection.none) {
                                    visibleColumnsTdClassNames.push("sorting");
                                }
                                visibleColumnsTdClassNames = visibleColumnsTdClassNames.join(" ");
                                let getYieldContent = fullColumn.data.getYieldContent;
                                return (
                                    <td key={`tbody-tr-td-${fullColumnIndex}`} className={visibleColumnsTdClassNames} onMouseEnter={this.mouseEnterTd.bind(this, index, fullColumn, item, fullColumnIndex)}>
                                        {(function () {
                                            if (fullColumn.data.hasTemplate && typeof getYieldContent === "function") {
                                                return getYieldContent(fullColumn.data.name, item, index);
                                            } else {
                                                let cellOriginalValue = getCellValue.bind(this)(item, fullColumn.data);
                                                if (enableHtmlContent) {
                                                    cellOriginalValue = Udesk.utils.web.safeHtml(cellOriginalValue);
                                                }
                                                if (isCellClickable.bind(this)(item, fullColumn.data)) {
                                                    const component = (
                                                        <a className="cell-link" title={removeHtmlTags(cellOriginalValue)} dangerouslySetInnerHTML={{ __html: safeHtml(cellOriginalValue) }}></a>
                                                    );
                                                    return (
                                                        <NormalFieldComponentWrapper
                                                            fieldData={fullColumn.data}
                                                            item={item}
                                                            index={index}
                                                            component={component}
                                                        />
                                                    );
                                                } else {
                                                    if (rowNavigationMode === RowNavigationModes.link) {
                                                        let linkData = getCellNavigationLink.bind(this)(item, fullColumn.data);

                                                        if (linkData.hasValue) {
                                                            const component = (
                                                                <a className="cell-link" href={linkData.url} title={removeHtmlTags(cellOriginalValue)} dangerouslySetInnerHTML={{ __html: safeHtml(cellOriginalValue) }}></a>
                                                            );
                                                            return (
                                                                <NormalFieldComponentWrapper
                                                                    fieldData={fullColumn.data}
                                                                    item={item}
                                                                    index={index}
                                                                    component={component}
                                                                />
                                                            );
                                                        } else {
                                                            const component = (
                                                                <span title={removeHtmlTags(cellOriginalValue)} dangerouslySetInnerHTML={{ __html: safeHtml(cellOriginalValue) }}></span>
                                                            );
                                                            return (
                                                                <NormalFieldComponentWrapper
                                                                    fieldData={fullColumn.data}
                                                                    item={item}
                                                                    index={index}
                                                                    component={component}
                                                                />
                                                            );
                                                        }
                                                    } else {
                                                        const component = (
                                                            <span className="cell-text" title={removeHtmlTags(cellOriginalValue)} dangerouslySetInnerHTML={{ __html: safeHtml(cellOriginalValue) }}></span>
                                                        );
                                                        return (
                                                            <NormalFieldComponentWrapper
                                                                fieldData={fullColumn.data}
                                                                item={item}
                                                                index={index}
                                                                component={component}
                                                            />
                                                        );
                                                    }
                                                }
                                            }
                                        }).bind(this)()}
                                    </td>
                                );
                            }
                            else {
                                return null;
                            }
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
}

/**
 * field常规显示组件装饰器
 * 注：column含 wrapperRender 方法时执行包装
 */
function NormalFieldComponentWrapper({ fieldData, item, index, component }) {
    if (fieldData && typeof fieldData.wrapperRender === 'function') {
        const fieldComponent = fieldData.wrapperRender(fieldData.name, item, index, component);
        if (fieldComponent) {
            return fieldComponent;
        }
    }
    return component;
}

//公共的方法

function isCellClickable(item, column) {
    if (column != null && typeof column.click === "function") {
        return true;
    }
    let {
        rowContentClick,
        tableRowClickable,
        rowNavigationMode
    } = this.props;
    return (rowNavigationMode === RowNavigationModes.click && typeof rowContentClick === "function" &&
        !tableRowClickable
    );
}

function getCellValue(item, column) {
    let value = "";
    if (column.get) { value = column.get(item, column); } else { value = item[column.name]; }
    if (column.format) {
        value = column.format(value, item, column);
    }
    value = customStringify(value);
    return value;
}

function customStringify(value) {
    if (value == null) {
        return "";
    } else if (typeof value === "boolean") {
        return value.toString();
    } else if (value === 0) {
        return "0";
    } else {
        return value;
    }
}

function isRowClickable(items) {
    let {
        rowContentClick,
        tableRowClickable,
        rowNavigationMode
    } = this.props;
    return (rowNavigationMode === RowNavigationModes.click &&
        typeof rowContentClick === "function" &&
        tableRowClickable);
}

function getRowExtensions(item) {
    let getRowExtensionsFunc = this.props.getRowExtensions;
    if (getRowExtensions) {
        return getRowExtensionsFunc(item) || {};
    } else {
        return {};
    }
}

function contains(item, array, itemField) {
    if (array == null) {
        array = [];
    }
    if (!(array instanceof Array)) {
        array = [];
    }
    if (itemField == null || itemField === "") {
        let finder = array.includes;
        return finder.apply(array, item);
    } else {
        return array.some((o) => item[itemField] === o[itemField]);
    }
}

//构造columns
function buildColumns(columns, context) {
    let {
        oldColumns,
        restoreColumnStates
    } = context.state;
    let savedColumns = context.savedColumns;
    if (columns == null) {
        columns = [];
    }
    if (!(columns instanceof Array)) {
        columns = [columns];
    }
    columns = columns.map((item) => {
        let fullColumn = wrapColumn(item, savedColumns, context);
        // try to restore the column states.
        if (restoreColumnStates && oldColumns) {
            let oldFullColumn = oldColumns.find(col => col.data.name === fullColumn.data.name);
            if (oldFullColumn) {
                fullColumn.states = oldFullColumn.states;
            }
        }
        return fullColumn;
    });
    return columns;
}

function wrapColumn(column, savedColumns, context) {
    if (column == null) {
        throw new Error("'column' cannot be null.");
    }
    let {
        sortable,
        enableChooseColumns
    } = context.props;
    let fullColumn = {
        data: column,
        states: {
            sortDirection: column.sortDirection || Udesk.enums.sortDirection.none,
            visible: parseBoolean(column.visible, true),
            checked: (savedColumns != null ? savedColumns.some(item => item.name === column.name) : (!enableChooseColumns || parseBoolean(column.checked, true))),
            sortable: sortable && parseBoolean(column.sortable, true),
            disabled: parseBoolean(column.disabled, false)
        }
    };
    return fullColumn;
}

function parseBoolean(value, defaultValue) {
    if (typeof value === "function") {
        return !!value();
    } else if (value == null) {
        return !!defaultValue;
    } else {
        return !!value;
    }
}

function buildRestoreColumnStates() {
    let restoreColumnStates = this.props.restoreColumnStates;
    if (restoreColumnStates != null) {
        return !!restoreColumnStates;
    } else {
        return true;
    }
}

function getVisibleColumns(fullColumns) {
    if (fullColumns == null) {
        fullColumns = [];
    }
    if (!(fullColumns instanceof Array)) {
        fullColumns = [fullColumns];
    }
    return fullColumns.filter(item => isColumnVisible(item));
}

function isColumnVisible(fullColumn) {
    return (fullColumn.states.visible && fullColumn.states.checked);
}

function buildStackedHeaderRows(visibleColumns) {
    let {
        stackedHeaderGroups
    } = this.props;
    if (stackedHeaderGroups == null || stackedHeaderGroups.length === 0 || visibleColumns.length === 0) {
        return [];
    }

    visibleColumns = visibleColumns.map(c => c.data);

    stackedHeaderGroups = $.extend(true, [], stackedHeaderGroups);
    // Build parent relationships.
    let maxDepth = 0;
    traverseStackedHeaderGroups(stackedHeaderGroups, ({ group, depth, parentGroup }) => {
        if (group.columns == null) {
            group.columns = [];
        }
        if (group.groups == null) {
            group.groups = [];
        }

        group.depth = depth;
        if (depth > maxDepth) {
            maxDepth = depth;
        }
        group.parentGroup = parentGroup;
    });

    traverseStackedHeaderGroups(stackedHeaderGroups, ({ group, depth, parentGroup }) => {
        if (group.groups && group.groups.length > 0) {
            group.level = group.groups[0].level + 1;
        } else {
            group.level = 0;
        }
    });

    let visibleColumnIndexMappings = new Map();
    // [Validation] One columns must be included in only one group.
    for (let i = 0; i < visibleColumns.length; i++) {
        let column = visibleColumns[i];
        visibleColumnIndexMappings.set(column.name, i);

        let attendance = 0;
        traverseStackedHeaderGroups(stackedHeaderGroups, ({ group, depth, parentGroup }) => {
            if (group.columns.length > 0 && group.columns.includes(column.name)) {
                attendance++;
                if (attendance > 1) {
                    throw new Error(`[udesk-react/react-table] One column must be included in only one group at most! Column name: ${column.name}`);
                }
            }
        });
    }

    // Validate groups.
    traverseStackedHeaderGroups(stackedHeaderGroups, ({ group, depth, parentGroup }) => {
        if (group.columns.length > 0) {
            let groupColumns = [...group.columns];
            traverseStackedHeaderGroups(group.groups, ({ group: childGroup, depth: childDepth, parentGroup: innerParentGroup }) => {
                groupColumns.push(...childGroup.columns);
            });

            let groupColumnIndexes = [];
            for (let columnName of group.columns) {
                if (visibleColumnIndexMappings.has(columnName)) {
                    groupColumnIndexes.push(visibleColumnIndexMappings.get(columnName));
                }
                //  else {
                //     throw new Error(`[udesk/react-table] The column '${columnName}' which is defined in stacked header group '${group.text}' doesn't exist.`);
                // }
            }
            groupColumnIndexes.sort((a, b) => a - b);

            for (let i = 0; i < groupColumnIndexes.length - 1; i++) {
                let currentColumnIndex = groupColumnIndexes[i];
                let nextColumnIndex = groupColumnIndexes[i + 1];
                if (nextColumnIndex - currentColumnIndex !== 1) {
                    let wrongColumn = visibleColumns[currentColumnIndex];
                    throw new Error(`[udesk-react/react-table] All columns in one group must be continuous. Group name: ${group.text}, column name: ${wrongColumn.name}.`);
                }
            }
        }
    });

    // Build owner groups of each column recursively.
    let stackedGroupsOfAllColumns = [];
    for (let i = 0; i < visibleColumns.length; i++) {
        let column = visibleColumns[i];
        let groupInfo = {
            column,
            groups: []
        };
        traverseStackedHeaderGroups(stackedHeaderGroups, ({ group, depth, parentGroup }) => {
            if (group.columns.length > 0 && group.columns.includes(column.name)) {
                for (let k = 0; k < group.level; k++) {
                    groupInfo.groups.push(null);
                }
                let currentGroup = group;
                do {
                    groupInfo.groups.push(currentGroup);
                    currentGroup = currentGroup.parentGroup;
                }
                while (currentGroup != null);
            }
        });
        stackedGroupsOfAllColumns.push(groupInfo);
    }

    let stackedHeaderRows = [];
    for (let i = maxDepth - 1; i >= 0; i--) {
        let stackedRowCells = [];
        for (let k = 0; k < stackedGroupsOfAllColumns.length; k++) {
            let currentGroupInfo = stackedGroupsOfAllColumns[k];
            let currentGroup = currentGroupInfo.groups[i];
            let isFirstCell = (k === 0);
            let isLastCell = (k === stackedGroupsOfAllColumns.length - 1);
            let isDifferentAsPreviousCell = (k > 0 && currentGroup !== stackedGroupsOfAllColumns[k - 1].groups[i]);
            let isNoneGroup = (currentGroup == null);

            let isStartPoint = (isFirstCell || isDifferentAsPreviousCell || isNoneGroup);

            if (isStartPoint) {
                let sumMergedCells = 0;
                if (stackedRowCells.length > 0) {
                    sumMergedCells = stackedRowCells.map(c => c.colSpan).reduce((a, b) => a + b);
                }
                // If the last cell is already merged, then should not be a start point.
                if (isLastCell && sumMergedCells === stackedGroupsOfAllColumns.length) {
                    isStartPoint = false;
                }
            }

            if (isStartPoint) {
                let mergedColumnCount = 1;
                for (let m = k + 1; m < stackedGroupsOfAllColumns.length; m++) {
                    let rightColumn = stackedGroupsOfAllColumns[m];
                    if (rightColumn.groups[i] === currentGroup &&
                        rightColumn.groups[i + 1] === currentGroupInfo.groups[i + 1]) {
                        mergedColumnCount++;
                    } else {
                        break;
                    }
                }
                k += (mergedColumnCount - 1);
                let mergedCell = {
                    text: (currentGroup != null ? currentGroup.text : ""),
                    colSpan: mergedColumnCount,
                    stackedHeaderClass: (currentGroup != null ? currentGroup.stackedHeaderClass : "")
                };
                stackedRowCells.push(mergedCell);
            }
        }
        stackedHeaderRows.push(stackedRowCells);
    }

    return stackedHeaderRows;

}

function traverseStackedHeaderGroups(root, callback) {
    return innerTraverseStackedHeaderGroups(root, 1, null, callback);
}

function innerTraverseStackedHeaderGroups(root, depth, parentGroup, callback) {
    if (root != null) {
        for (let group of root) {
            if (group.groups && group.groups.length > 0) {
                innerTraverseStackedHeaderGroups(group.groups, depth + 1, group, callback);
            }
            callback({ group, depth, parentGroup });
        }
    }
}

function setSelection(selection) {
    let onSelectionChanged = this.props.onSelectionChanged;
    if (onSelectionChanged != null) {
        if (typeof onSelectionChanged === "function") {
            onSelectionChanged(selection);
        }
    }
}

function SavedColumn(data) {
    if (data) {
        this.name = data.name;
    }
}

function saveCheckedColumns() {
    let {
        columns: fullColumns,
        storageKey
    } = this.state;
    let savedColumns = [];
    for (let fullColumn of fullColumns) {
        if (fullColumn.states.checked) {
            savedColumns.push(new SavedColumn(fullColumn.data));
        }
    }
    if (Udesk.browser.storage.localStorage.available) {
        Udesk.browser.storage.localStorage.setItem(storageKey, JSON.stringify(savedColumns));
    }
    this.savedColumns = savedColumns;
}

function removeHtmlTags(content) {
    return window.filterXSS(content || "", {
        whiteList: {},
        stripIgnoreTag: true
    });
}

function safeHtml(value) {
    if (value != null && typeof value === "string") {
        return Udesk.utils.web.safeHtml(value);
    }
    return value;
}

function getCellNavigationLink(item, column) {
    let {
        getCellNavigationLink,
        getCellNavigationLinkContext
    } = this.props;

    let result = {
        hasValue: false,
        data: null,
        url: null
    };
    if (typeof getCellNavigationLink === "function") {
        let linkData = getCellNavigationLink.apply(getCellNavigationLinkContext, [item, column]);
        if (linkData != null) {
            result.hasValue = true;
            result.data = linkData || {};
            result.url = linkData ? linkData.url : "";
        }
    }
    return result;
}

function syncFreezeTablePart(context) {
    let {
        freezeHeader,
        freezeColumn,
        visibilitySpyInterval,
        visibilitySpyTryTimes
    } = context.props;

    if (freezeHeader || freezeColumn) {
        let interval = parseInt(visibilitySpyInterval, 10);
        if (isNaN(interval) || interval < 0) {
            interval = DEFAULT_VISIBILITY_SPY_INTERVAL;
        }
        let tryTimes = parseInt(visibilitySpyTryTimes, 10);
        if (isNaN(tryTimes) || tryTimes < 0) {
            tryTimes = DEFAULT_VISIBILITY_SPY_TRY_TIMES;
        }
        let tryCounter = 0;
        let checkAndSync = () => {
            if (context.isDestroying || context.isDestroyed) {
                return;
            }
            if (context.reactTableElement != null && $(context.reactTableElement).is(":visible")) {
                if (freezeHeader) {
                    syncFrozenHeaderWidth.bind(context)();
                }
                if (freezeColumn) {
                    syncFrozenColumnsSize.bind(context)();
                }
                if (freezeHeader && freezeColumn) {
                    syncFrozenHeaderColumnsSize.bind(context)();
                }
            } else {
                if (tryCounter < tryTimes) {
                    setTimeout(function () {
                        checkAndSync();
                    }, interval);
                    tryCounter++;
                }
            }
        };
        checkAndSync();
    }
}

function syncFrozenHeaderWidth() {
    let outterTableWrapper = $(this.reactTableElement).find(".table-wrapper");
    let frozenTableWrapper = $(this.reactTableElement).find(".frozen-table-wrapper");
    let frozenTable = frozenTableWrapper.find("table.frozen-header-table");
    let frozenTableCols = frozenTable.find("col");

    let dataTableWrapper = $(this.reactTableElement).find(".data-table-wrapper");
    let dataTable = dataTableWrapper.find("table.inner-data-table");
    let dataTableHeader = dataTable.find(">thead");
    let dataTableHeaderRow = dataTableHeader.find("tr.header-row");
    let dataHeaderCells = dataTableHeaderRow.find(">th");

    // 把读取尺寸和设置尺寸的代码分割开来，以让浏览器进行优化，尽可能少的减少reflow
    let sizeSetters = [];
    frozenTableCols.each((index, el) => {
        sizeSetters.push({
            element: el,
            styleName: "width",
            styleValue: getTableCellWidth(dataHeaderCells[index])
        });
    });

    sizeSetters.push({
        element: frozenTable[0],
        styleName: "width",
        styleValue: window.getComputedStyle(dataTable[0]).width
    });

    let dataHeaderHeight = window.getComputedStyle(dataTableHeader[0]).height;
    sizeSetters.push({
        element: dataTableWrapper[0],
        styleName: "marginTop",
        styleValue: dataHeaderHeight
    });
    sizeSetters.push({
        element: dataTable[0],
        styleName: "marginTop",
        styleValue: `-${dataHeaderHeight}`
    });
    sizeSetters.push({
        element: outterTableWrapper[0],
        styleName: "paddingBottom",
        styleValue: dataHeaderHeight
    });

    execSizeSetters(sizeSetters);
}

function syncFrozenColumnsSize() {
    let {
        checkable,
    } = this.props;
    let freezeCellNumbers = getFreezeCellNumbers.bind(this)();
    let outterTableWrapper = $(this.reactTableElement).find(".table-wrapper");
    let frozenTableWrapper = $(this.reactTableElement).find(".frozen-table-column-wrapper");
    let frozenTable = frozenTableWrapper.find("table.frozen-column-table");
    let frozenTableCols = frozenTable.find("col");
    let frozenTableBody = frozenTable.find(">tbody");
    let frozenTableHeader = frozenTable.find(">thead");
    let frozenStackedHeaderCells = frozenTableHeader.find(">tr.stacked-header-row");
    let frozenTableRows = frozenTableBody.find(">tr");

    let dataTableWrapper = $(this.reactTableElement).find(".data-table-wrapper");
    let dataTable = dataTableWrapper.find("table.inner-data-table");
    let dataTableHeader = dataTable.find(">thead");
    let dataTableHeaderRow = dataTableHeader.find(">tr.header-row");
    let dataHeaderCells = dataTableHeaderRow.find(">th");
    let dataStackedHeaderCells = dataTableHeader.find(">tr.stacked-header-row");
    let dataTableBody = dataTable.find(">tbody");
    let dataTableBodyRows = dataTableBody.find(">tr");

    // 把读取尺寸和设置尺寸的代码分割开来，以让浏览器进行优化，尽可能少的减少reflow
    let sizeSetters = [];
    frozenTableCols.each((index, el) => {
        sizeSetters.push({
            element: el,
            styleName: "width",
            styleValue: getTableCellWidth(dataHeaderCells[index])
        });
    });

    frozenStackedHeaderCells.each((index, el) => {
        sizeSetters.push({
            element: $(el).find(">th")[0],
            styleName: "height",
            styleValue: getTableCellHeight($(dataStackedHeaderCells[index]).find(">th")[0])
        });
    });

    frozenTableHeader.find(">tr.header-row").each((index, el) => {
        sizeSetters.push({
            element: el,
            styleName: "height",
            styleValue: getTableCellHeight(dataTableHeaderRow[index])
        });
    });

    frozenTableRows.each((index, el) => {
        sizeSetters.push({
            element: el,
            styleName: "height",
            styleValue: getTableCellHeight(dataTableBodyRows[index])
        });
    });

    let dataHeaderHeight = window.getComputedStyle(dataTableHeader[0]).height;
    let dataTableWrapperHeight = getDomStyleValueWithoutPx(dataTableWrapper[0], "height") + getDomStyleValueWithoutPx(dataTableWrapper[0], "marginTop") - getScrollBarHeight(dataTableWrapper[0]) + "px";
    let sliceLength = checkable ? (freezeCellNumbers + 1) : freezeCellNumbers;
    let allFreezeCellsWidth = getDomsStyleValue(dataHeaderCells.slice(0, sliceLength), "width");

    sizeSetters.push({
        element: frozenTableWrapper[0],
        styleName: "width",
        styleValue: allFreezeCellsWidth
    });
    sizeSetters.push({
        element: frozenTableWrapper[0],
        styleName: "height",
        styleValue: dataTableWrapperHeight
    });

    sizeSetters.push({
        element: outterTableWrapper[0],
        styleName: "paddingBottom",
        styleValue: dataHeaderHeight
    });

    execSizeSetters(sizeSetters);
}

function syncFrozenHeaderColumnsSize() {
    let outterTableWrapper = $(this.reactTableElement).find(".table-wrapper");
    let frozenTableWrapper = $(this.reactTableElement).find(".frozen-table-header-column-wrapper");
    let frozenTable = frozenTableWrapper.find("table.frozen-header-column-table");
    let frozenTableHeader = frozenTable.find(">thead");
    let frozenStackedHeaderCells = frozenTableHeader.find(">tr.stacked-header-row");
    let frozenTableCols = frozenTable.find("col");

    let dataTableWrapper = $(this.reactTableElement).find(".data-table-wrapper");
    let dataTable = dataTableWrapper.find("table.inner-data-table");
    let dataTableHeader = dataTable.find(">thead");
    let dataTableHeaderRow = dataTableHeader.find(">tr.header-row");
    let dataHeaderCells = dataTableHeaderRow.find(">th");
    let dataStackedHeaderCells = dataTableHeader.find(">tr.stacked-header-row");

    // 把读取尺寸和设置尺寸的代码分割开来，以让浏览器进行优化，尽可能少的减少reflow
    let sizeSetters = [];
    frozenTableCols.each((index, el) => {
        sizeSetters.push({
            element: el,
            styleName: "width",
            styleValue: getTableCellWidth(dataHeaderCells[index])
        });
    });

    frozenStackedHeaderCells.each((index, el) => {
        sizeSetters.push({
            element: $(el).find(">th")[0],
            styleName: "height",
            styleValue: getTableCellHeight($(dataStackedHeaderCells[index]).find(">th")[0])
        });
    });

    frozenTableHeader.find(">tr.header-row").each((index, el) => {
        sizeSetters.push({
            element: el,
            styleName: "height",
            styleValue: getDomStyleValue(dataTableHeaderRow[index], "height")
        });
    });

    let dataHeaderHeight = window.getComputedStyle(dataTableHeader[0]).height;

    sizeSetters.push({
        element: outterTableWrapper[0],
        styleName: "paddingBottom",
        styleValue: dataHeaderHeight
    });

    execSizeSetters(sizeSetters);
}

function getComputedStyle(cellDom) {
    return window.getComputedStyle(cellDom);
}

function getDomStyleValue(cellDom, styleAttribute) {
    if (!cellDom) {
        return '';
    }
    let cellComputedStyle = getComputedStyle(cellDom);
    return cellComputedStyle[styleAttribute];
}

function getDomStyleValueWithoutPx(cellDom, styleAttribute) {
    let result = parseInt(getDomStyleValue(cellDom, styleAttribute), 10);
    if (isNaN(result)) {
        result = 0;
    }
    return result;
}

function getDomsStyleValue(cellDoms, styleAttribute) {
    if (!cellDoms || !cellDoms.length) {
        return '';
    }
    let result = 0;
    cellDoms.each((index, cellDom) => {
        result += getDomStyleValueWithoutPx(cellDom, styleAttribute);
    });
    return result + 'px';
}

function getTableCellWidth(cellDom) {
    return getDomStyleValue(cellDom, "width");
}

function getTableCellHeight(cellDom) {
    return getDomStyleValue(cellDom, "height");
}

function getScrollBarHeight(domElement) {
    return domElement.offsetHeight - domElement.clientHeight - getDomStyleValueWithoutPx(domElement, "borderTopWidth") - getDomStyleValueWithoutPx(domElement, "borderBottomWidth");
}

function execSizeSetters(sizeSetters) {
    for (let setter of sizeSetters) {
        if (setter && setter.element && (setter.element.style[setter.styleName] !== setter.styleValue)) {
            setter.element.style[setter.styleName] = setter.styleValue;
        }
    }
}

function getFreezeCellNumbers() {
    let {
        freezeColumnNumber
    } = this.props;
    let {
        stackedHeaderRows
    } = this.state;
    if (!stackedHeaderRows || !stackedHeaderRows.length) {
        return freezeColumnNumber;
    } else {
        let spans = 0;
        stackedHeaderRows.forEach((stackedHeaderRow) => {
            let tmpSpans = 0;
            stackedHeaderRow.forEach((stackedHeaderCell, index) => {
                if (index < freezeColumnNumber) {
                    if (stackedHeaderCell.colSpan) {
                        tmpSpans += stackedHeaderCell.colSpan;
                    } else {
                        tmpSpans += 1;
                    }
                }
            });
            if (tmpSpans > spans) {
                spans = tmpSpans;
            }
        });
        return spans;
    }
}

//default out func
function onDefaultColumnsChanged() { }
function onDefaultSorted() { }
function defaultGetRowExtensions() { }
function defaultReset() { }

// function getDefaultYieldContent(columnName, item, rowIndex) {
//     return columnName;
// }


ReactTable.propTypes = {
    columns: PropTypes.array,
    theme: PropTypes.string,
    items: PropTypes.array,
    classNames: PropTypes.string,
    itemIdentityField: PropTypes.string,
    tableClassName: PropTypes.string,
    checkable: PropTypes.bool,
    sortable: PropTypes.bool,
    enableChooseColumns: PropTypes.bool,
    stackedHeaderGroups: PropTypes.array,
    singleSelectMode: PropTypes.bool,
    hideNoSortingIcon: PropTypes.bool,
    hideNoSortingIconBehavior: PropTypes.string,
    sortAscIconClass: PropTypes.string,
    sortDescIconClass: PropTypes.string,
    sortDirectionsSeries: PropTypes.array,
    noSortingIconClass: PropTypes.string,
    horizontalScrollbar: PropTypes.bool,
    showHorizontalScrollbar: PropTypes.bool,
    verticalScrollbar: PropTypes.bool,
    freezeHeader: PropTypes.bool,
    freezeColumn: PropTypes.bool,
    freezeColumnNumber: PropTypes.number,
    chooseColumnsIconClass: PropTypes.string,
    getRowExtensions: PropTypes.func,
    tableRowClickable: PropTypes.bool,
    rowNavigationMode: PropTypes.string,
    rowClickCancelBubbles: PropTypes.bool,
    highlightSortColumn: PropTypes.bool,
    enableHtmlContent: PropTypes.bool,
    cacheKey: PropTypes.string,
    rememberColumns: PropTypes.bool,
    asyncRender: PropTypes.bool,
    asyncRenderChunkSize: PropTypes.number,

    onColumnsChanged: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    onSorted: PropTypes.func,
    getCheckboxState: PropTypes.func,
    // getYieldContent: PropTypes.func,
    reset: PropTypes.func
};

ReactTable.defaultProps = {
    languageCode: "",
    theme: "",
    columns: [],
    items: [],
    selectedItems: [],
    itemIdentityField: "id",
    classNames: "",
    tableClassName: "table table-striped",
    checkable: false,
    sortable: false,
    enableChooseColumns: false,
    restoreColumnStates: null,
    stackedHeaderGroups: [],
    singleSelectMode: false,
    hideNoSortingIcon: false,
    hideNoSortingIconBehavior: "always", // hover | always
    sortAscIconClass: "udesk-react-iconfont icon-udesk-react-sort-amount-asc",
    sortDescIconClass: "udesk-react-iconfont icon-udesk-react-sort-amount-desc",
    sortDirectionsSeries: [Udesk.enums.sortDirection.none, Udesk.enums.sortDirection.ascending, Udesk.enums.sortDirection.descending],
    noSortingIconClass: "udesk-react-iconfont icon-udesk-react-sort",
    horizontalScrollbar: true,
    showHorizontalScrollbar: true,
    verticalScrollbar: false,
    freezeHeader: true,
    freezeColumn: false,
    freezeColumnNumber: 1,
    maxHeight: null,
    chooseColumnsIconClass: "udesk-react-iconfont icon-udesk-react-gear",
    getRowExtensions: defaultGetRowExtensions,
    rowContentClick: null,
    activeItemId: null,
    rowClickCancelBubbles: false,
    rowConentClickContext: null,
    rowClickContext: null,
    highlightSortColumn: true,
    tableRowClickable: false,
    rowNavigationMode: RowNavigationModes.click,
    enableHtmlContent: false,
    cacheKey: "",
    rememberColumns: true,
    asyncRender: false,
    asyncRenderChunkSize: 10,
    visibilitySpyTryTimes: DEFAULT_VISIBILITY_SPY_TRY_TIMES,
    visibilitySpyInterval: DEFAULT_VISIBILITY_SPY_INTERVAL,

    rowClick: null,
    onColumnsChanged: onDefaultColumnsChanged,
    onSelectionChanged: null,
    onSorted: onDefaultSorted,
    getCheckboxState: null,
    // getYieldContent: getDefaultYieldContent,
    reset: defaultReset,
};

export default ReactTable;
