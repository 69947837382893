import React, { useState, useEffect } from 'react';
import { Button, FormBuilder, Row, Col, Space } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import Page from 'Component/common/udesk-qa-page-layout';
import styled from 'styled-components';
import { postInspectionPointSystemDialogs } from 'src/api/inspectionPointSystemDialogs';
import {
    getInspectionPointSystemDialogsById,
    putInspectionPointSystemDialogsById,
} from 'src/api/inspectionPointSystemDialogs/{id}';
import { PostInspectionPointSystemDialogsBody } from 'src/api/types';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    height: 100%;
    overflow: auto;
`;

interface FormValues extends Omit<PostInspectionPointSystemDialogsBody, 'id'> {}

const Template = React.memo((props: any) => {
    const locales = UdeskLocales['current'];

    const id = props.match.params.id;

    const [btnLoading, setBtnLoading] = useState(false);

    const [initialValues, setInitialValues] = useState<FormValues>();

    const [baseForm] = FormBuilder.useForm();

    useEffect(() => {
        if (id) {
            setBtnLoading(true);
            getInspectionPointSystemDialogsById({
                segments: {
                    id,
                },
            })
                .then((resp) => {
                    const data = resp.data || {};

                    setInitialValues((initialValues) => ({
                        ...initialValues,
                        ...data,
                    }));
                })
                .finally(() => {
                    setBtnLoading(false);
                });
        }
    }, [id]);

    useEffect(() => {
        baseForm.resetFields();
    }, [baseForm, initialValues]);

    const onCancel = () => {
        let routeOptions: any = {
            history: props.history,
            routeName: 'applicationMessage',
            state: {},
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const onFinish = async (values: FormValues) => {
        const params: PostInspectionPointSystemDialogsBody = {
            name: values.name,
            messageDesc: values.messageDesc,
        };

        setBtnLoading(true);

        try {
            if (id) {
                await putInspectionPointSystemDialogsById(params, {
                    segments: {
                        id,
                    },
                    successMsg: locales.business.info.saveSuccess,
                });
            } else {
                await postInspectionPointSystemDialogs(params, {
                    successMsg: locales.business.info.creatSuccess,
                });
            }
            onCancel();
        } catch (e) {
            console.log(e);
        } finally {
            setBtnLoading(false);
        }
    };

    const config: FormProps = {
        customComponents: {},
        initialValues,
        onFinish: onFinish,
        fields: [
            {
                name: 'name',
                label: /* 消息类型名称 */ UdeskLocales['current'].pages.semanticIntelligence
                    .applicationMessage.detail.index.messageTypeName,
                type: 'Input',
                rules: [
                    {
                        required: true,
                    },
                ],
                props: {
                    placeholder:
                        /* 请输入消息类型名称 */ UdeskLocales['current'].pages.semanticIntelligence
                            .applicationMessage.detail.index.pleaseEnterAMessageTypeName,
                },
            },
            {
                name: 'messageDesc',
                label: /* 消息类型描述 */ UdeskLocales['current'].pages.semanticIntelligence
                    .applicationMessage.detail.index.messageTypeDescription,
                type: 'TextArea',
                props: {
                    autoSize: true,
                    placeholder:
                        /* 可详细描述您的消息类型 */ UdeskLocales['current'].pages
                            .semanticIntelligence.applicationMessage.detail.index
                            .describeYourMessageTypeInDetail,
                },
                rules: [],
            },
        ],
        footerRender: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 13 },
    };

    return (
        <Page
            pageBodyClassName="wechat-staff-center-page-index"
            title={
                props.match.params.id
                    ? /* 编辑消息类型 */ UdeskLocales['current'].pages.semanticIntelligence
                          .applicationMessage.detail.index.editMessageType
                    : /* 新建消息类型 */ UdeskLocales['current'].pages.semanticIntelligence
                          .applicationMessage.detail.index.newMessageType
            }
            padding={true}
            onBack={onCancel}
        >
            <Wrapper>
                <FormBuilder {...config} form={baseForm} />

                <Row>
                    <Col offset={4}>
                        <Space>
                            <Button loading={btnLoading && { delay: 200 }} onClick={onCancel}>
                                {locales.labels.cancel}
                            </Button>
                            <Button
                                loading={btnLoading && { delay: 200 }}
                                type="primary"
                                onClick={() => baseForm.submit()}
                            >
                                {locales.labels.save}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Wrapper>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
