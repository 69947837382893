import UdeskLocales from 'UdeskLocales';

export const ScoreMap = {
    NotReach: 0,
    Reach: 1,
    Excellent: 2,
};

export const TaskStatusMap = {
    NotStart: 0,
    Running: 1,
    Done: 2,
    NotDone: 3,
};

export const useOptions = () => {
    const ScoreOptions = [
        {
            label: /* 未达标 */ UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis
                .components.search.components.latitudeModal.components.selector.const
                .notMeetingStandards,
            value: ScoreMap.NotReach,
        },
        {
            label: /* 达标 */ UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis
                .components.search.components.latitudeModal.components.selector.const
                .meetingStandards,
            value: ScoreMap.Reach,
        },
        {
            label: /* 优秀 */ UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis
                .components.search.components.latitudeModal.components.selector.const.excellent,
            value: ScoreMap.Excellent,
        },
    ];

    const TaskStatusOptions = [
        {
            label: /* 未开始 */ UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis
                .components.search.components.latitudeModal.components.selector.const.notStartedYet,
            value: TaskStatusMap.NotStart,
        },
        {
            label: /* 进行中 */ UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis
                .components.search.components.latitudeModal.components.selector.const.inProgress,
            value: TaskStatusMap.Running,
        },
        {
            label: /* 已完成 */ UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis
                .components.search.components.latitudeModal.components.selector.const.completed,
            value: TaskStatusMap.Done,
        },
        {
            label: /* 未完成 */ UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis
                .components.search.components.latitudeModal.components.selector.const.incomplete,
            value: TaskStatusMap.NotDone,
        },
    ];

    return {
        ScoreOptions,
        TaskStatusOptions,
    };
};
