import React, { useEffect, useState, useMemo } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import {
    Modal,
    Form,
    Input,
    InputNumber,
    Space,
    Button,
} from 'udesk-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './style.scss';
import { postIntelligentPartnerTaskFinishRecordsByIdShare } from 'src/api/intelligentPartnerTaskFinishRecords/{id}/share/index';
import { IntelligentPartnerTaskFinishRecordShareCreateRequest } from 'src/api/types';
import { useCoachUserTreeDataWithPermit, UserTreeSelect } from 'src/util/hook/user';

const { TextArea } = Input;
const locales = Locales['current'];

const ShareModal:React.FC<any> = (props) => {
    let { detail } = props;
    
    let taskId = detail && detail.id;
    
    let answerUserName = detail?.userName || '--';
    let taskName = detail?.taskName || '--';
    
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 20,
            },
        },
    };
    const [form] = Form.useForm();
    const [isShare, setIsShare] = useState(false);
    const [timeLiness, setTimeLiness] = useState<number>(7);
    const [shareRecommend, setShareRecommend] = useState<string>('');
    const [shareUrl, setShareUrl] = useState('');
    const { treeData } = useCoachUserTreeDataWithPermit();
    
    const shareText = useMemo(() => {
        return `${locales.components.gong.shareModal.index.employees}${answerUserName || '--'}\n${locales.components.gong.shareModal.index.taskName}${taskName || '--'}\n${locales.components.gong.shareModal.index.timeliness}${timeLiness}${locales.components.gong.shareModal.index.recordingShareValidWithinDays}${shareUrl || '--'}\n${locales.components.gong.shareModal.index.sharingInstructions}${shareRecommend || '--'}`;
    }, [answerUserName,taskName, timeLiness, shareUrl, shareRecommend]);

    const timeLineChange = (val) => {
        setTimeLiness(val);
        if (props.shareType === 'customer') {
            const params: IntelligentPartnerTaskFinishRecordShareCreateRequest = {
                effectiveTime: val,
                remark: shareRecommend,
                type: 2,
            };
            getShareData(params);
        }
    };
    const shareRecommendChange = (e) => {
        setShareRecommend(e.target.value);

        if (props.shareType === 'customer') {
            const params: IntelligentPartnerTaskFinishRecordShareCreateRequest = {
                effectiveTime: timeLiness,
                remark: e.target.value,
                type: 2,
            };
            getShareData(params);
        }
    };
    const getShareData = (params) => {
        postIntelligentPartnerTaskFinishRecordsByIdShare(params, { segments: { id: taskId } }).then(
            (res) => {
                if (props.shareType === 'customer') {
                    setShareUrl(`${props.sdkOptions.props.domain}/case-share-qa/${res?.data?.id}`);
                } else {
                    Udesk.ui.notify.success(locales.components.gong.shareModal.index.shareSuccess);
                    setIsShare(false);
                    props.handlerModalCancel && props.handlerModalCancel();
                }
            }
        );
    };
    const handleOk = () => {
        form.validateFields().then((values) => {
            let toOrganizationIds = values.employeeIds?.organizationIds || [];
            let toUserIds = values.employeeIds?.userIds || [];

            const params: IntelligentPartnerTaskFinishRecordShareCreateRequest = {
                toOrganizationIds,
                toUserIds,
                remark: values.recommend,
                effectiveTime: values.timeLiness,
                type: 1,
            };
            getShareData(params);
        });
    };
    const handleCancel = () => {
        form.resetFields();
        setIsShare(false);
        props.handlerModalCancel && props.handlerModalCancel();
    };

    const footerNode = () => {
        if (props.shareType === 'colleague') {
            return (
                <Space>
                    <Button onClick={handleCancel}>{locales.components.gong.shareModal.index.cancel}</Button>
                    <Button type='primary' onClick={handleOk}>{locales.components.gong.shareModal.index.share}</Button>
                </Space>
            );
        } else {
            return (
                <Space>
                    <Button onClick={handleCancel}>{locales.components.gong.shareModal.index.cancel}</Button>
                    <CopyToClipboard text={shareText} onCopy={() => {
                        Udesk.ui.notify.success(locales.components.gong.shareModal.index.copySucceeded);
                        setIsShare(false);
                        props.handlerModalCancel && props.handlerModalCancel();
                    } }>
                        <Button type='primary'>{locales.components.gong.shareModal.index.copyShareLink}</Button>
                    </CopyToClipboard>
                </Space>
            );
        }
    };
    

    useEffect(() => {
        // 分享给客户可以默认成功 请求分享的链接
        setIsShare(props.isShare);
        if (props.shareType === 'customer') {
            const params: IntelligentPartnerTaskFinishRecordShareCreateRequest = {
                effectiveTime: timeLiness,
                remark: shareRecommend,
                type: 2,
            };
            getShareData(params);
        }
    }, [props.isShare,detail]);
    return (
        <Modal
            title={
                props.shareType === 'colleague'
                    ? locales.components.gong.shareModal.index.shareWithColleagues
                    : locales.components.gong.shareModal.index.shareWithCustomers
            }
            visible={isShare}
            footer={footerNode()}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form form={form} {...formItemLayout} initialValues={{ timeLiness }}>
                {props.shareType === 'colleague' ? (
                    <Form.Item
                        name="employeeIds"
                        label={locales.components.gong.shareModal.index.selectColleagues}
                        rules={[
                            {
                                required: true,
                                message:
                                    locales.components.gong.shareModal.index
                                        .pleaseSelectColleaguesToShare,
                            },
                        ]}
                    >
                        <UserTreeSelect
                            {...{
                                width: '100%',
                                treeData: treeData,
                                multiple: true,
                                showSearch: true,
                                allowClear: true,
                                showArrow: true,
                                maxTagCount: 1,
                                placeholder:
                                    locales.components.gong.shareModal.index
                                        .pleaseSelectColleaguesToShare,
                                treeDefaultExpandAll: false,
                                treeCheckable: true,
                            }}
                        />
                    </Form.Item>
                ) : (
                    <Form.Item label={locales.components.gong.shareModal.index.timeliness}>
                        <Space>
                            <Form.Item name="timeLiness" noStyle>
                                <InputNumber onChange={timeLineChange} min={1} />
                            </Form.Item>
                            <span className="udesk-ui-form-text">
                                {
                                    locales.components.gong.shareModal.index
                                        .recordingLinkValidWithinDays
                                }
                            </span>
                        </Space>
                    </Form.Item>
                )}
                <Form.Item
                    name="recommend"
                    label={locales.components.gong.shareModal.index.sharingInstructions}
                >
                    <TextArea
                        maxLength={400}
                        onChange={shareRecommendChange}
                        placeholder={
                            locales.components.gong.shareModal.index
                                .pleaseEnterTheSharingDescription
                        }
                        rows={3}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default React.memo(ShareModal);
