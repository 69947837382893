import UdeskError from './udesk-error';

export default class WrongTypeError extends UdeskError {
    constructor(argName, correctTypeName, options) {
        let { message, fileName, lineNumber } = options || {};
        let innerMessage = message;
        if (!innerMessage) {
            if (argName && correctTypeName) {
                innerMessage = `The argument \`${argName}\` must be an instance of \`${correctTypeName}\``;
            } else if (argName) {
                innerMessage = `The argument \`${argName}\` is wrong type`;
            } else if (correctTypeName) {
                innerMessage = `The argument must be an instance of \`${correctTypeName}\``;
            } else {
                innerMessage = "The argument is wrong type";
            }
        }
        super(innerMessage, fileName, lineNumber);
    }
}