import React from 'react';
import SmartWordsLibraryCommonComponent from '../smart-words-library-common';
import Udesk from 'Udesk';
export default class AttentionWordsTemplate extends React.Component {
    render() {
        let {
            locales
        } = this;

        let corporaType = Udesk.enums.smartWordsLibraryMenus.attentionWords.id;

        return (
            <div>
                <SmartWordsLibraryCommonComponent
                    boardName={Udesk.enums.smartWordsLibraryBoard.attentionWords.id}
                    getCommonListPath={`corpora/commonList/${corporaType}`}
                    navItemType={corporaType}
                    navItemName={Udesk.enums.smartWordsLibraryMenus.attentionWords.key}
                    uploadModalTitle={locales.components.pages.smartWordsLibrary.upload.uploadAttentionWords}
                />
            </div>
        );
    }
}