// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PutReviewIntelligentPartnerNodeBatchBody,
    BaseResponseListIntelligentPartnerDialogFlowNodeFoundResponse,
} from '../../../../types';

/**
 * 批量修改对话节点(主要用于一键整理)
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [PUT] /review/intelligentPartner/node/batch
 */
export function putReviewIntelligentPartnerNodeBatch(
    data: PutReviewIntelligentPartnerNodeBatchBody
): Promise<BaseResponseListIntelligentPartnerDialogFlowNodeFoundResponse>;
export function putReviewIntelligentPartnerNodeBatch(
    data: PutReviewIntelligentPartnerNodeBatchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogFlowNodeFoundResponse>;
export function putReviewIntelligentPartnerNodeBatch(
    data: PutReviewIntelligentPartnerNodeBatchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogFlowNodeFoundResponse> {
    return request<PutReviewIntelligentPartnerNodeBatchBody, never, never>(
        '/review/intelligentPartner/node/batch',
        {
            ...options,
            data,
            method: 'put',
            __$requestCalleeName: 'putReviewIntelligentPartnerNodeBatch',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/batch',
        method: 'put',
    },
];
