import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import { Checkbox, Select } from 'udesk-ui';
import Locales from 'UdeskLocales';

let timeout;

const PushTargetComponent = (props) => {
    const { value = {}, onChange, url } = props;
    const locales = Locales['current'];

    const { /*toOwner, toStaffSelf,*/ staffIdList, toCustomerStaff } = value;
    const [options, setOptions] = useState([]);
    const onCheckBoxChange = (type, e) => {
        let newValues = { ...value };
        newValues[type] = e.target.checked ? 1 : 0;
        onChange?.(newValues);
    };

    const handleSearch = (value = '') => {
        let _url = url + `?keywords=${value}`;
        
        if(timeout){
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            timeout = null;
            Udesk.ajax.get(_url).then(
                (resp) => {
                    setOptions(resp.data.map(i => ({label: i.name, value: i.userId})));
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }, 300);
    };

    const handleChange = v => {
        let newValues = { ...value };
        newValues.staffIdList = v;
        onChange?.(newValues);
    };

    useEffect(() => {
        handleSearch('');
    }, []);
    return (
        <div>
            {/* <div style={{ marginBottom: 16 }}>
                <Checkbox checked={Boolean(toOwner)} onChange={onCheckBoxChange.bind(null, 'toOwner')}>
                    {locales.fix.notifyTheOwner}
                </Checkbox>
            </div>
            <div>
                <Checkbox checked={Boolean(toStaffSelf)} onChange={onCheckBoxChange.bind(null, 'toStaffSelf')}>
                    {locales.fix.notifyTheEmployee}
                </Checkbox>
            </div>
            <div style={{ marginBottom: 16, color: 'rgba(0, 0, 0, .25)' }}>{locales.fix.notifyTheOwnerTip}</div> */}
            <div>
                <Checkbox checked={Boolean(toCustomerStaff)} onChange={onCheckBoxChange.bind(null, 'toCustomerStaff')}>
                    {locales.fix.customNotifyEmployee}
                </Checkbox>
            </div>
            <div style={{ color: 'rgba(0, 0, 0, .25)' }}>{locales.fix.customNotifyEmployeeTip}</div>
            <div>
                <Select
                    value={staffIdList}
                    mode='multiple'
                    style={{ width: '100%' }}
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    notFoundContent={null}
                    options={options}
                    disabled={!toCustomerStaff}
                />
            </div>
        </div>
    );
};

export default PushTargetComponent;
