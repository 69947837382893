// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PutCallWeComConfigsByIdParam,
    PutCallWeComConfigsByIdBody,
    BaseResponseVoid,
} from '../../types';

/**
 * update
 *
 * @export
 * @tags call-we-com-config-controller
 * @link [PUT] /callWeComConfigs/{id}
 * @param id
 */
export function putCallWeComConfigsById(
    data: PutCallWeComConfigsByIdBody,
    options: ApiOptions<PutCallWeComConfigsByIdParam, never> & {
        segments: PutCallWeComConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutCallWeComConfigsByIdBody, PutCallWeComConfigsByIdParam, never>(
        '/callWeComConfigs/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putCallWeComConfigsById' }
    );
}

export const meta = [
    { tags: ['call-we-com-config-controller'], path: '/callWeComConfigs/{id}', method: 'put' },
];
