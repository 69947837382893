import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import TasksManualAddComponent from './component';
import { getSystemModule } from 'Udesk/system/subApp';
// import _isEmpty from 'lodash-es/isEmpty';
// import _filter from 'lodash-es/filter';
// import _extend from 'lodash-es/extend';
// import _toString from 'lodash-es/toString';

export default class TasksManualAddRoute extends TasksManualAddComponent {
    model(changeParams) {
        const { props, state } = this;
        let { sdkOptions } = this.props;
        let type = sdkOptions?.props?.task?.inspectDataSource ?? Udesk.enums.inspectDataSources.voiceCall.id;
        if (
            sdkOptions.props.task &&
            (sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id ||
                sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id)
        ) {
            type = Udesk.enums.inspectDataSources.voiceCall.id;
        } else if (
            sdkOptions.props.task &&
            (sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id ||
                sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
        ) {
            type = Udesk.enums.inspectDataSources.textDialogue.id;
        } else if (
            sdkOptions.props.task &&
            sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.wechatDialogue.id
        ) {
            type = Udesk.enums.inspectDataSources.wechatDialogue.id;
        }
        this.privates.type = type;
        let params = Object.assign(
            { pageNum: state.pageNum, pageSize: state.pageSize, systemModule: getSystemModule(), },
            props.match.params,
            Object.fromEntries(new URLSearchParams(props.location.search))
        );
        if (changeParams) {
            params = Object.assign(params, changeParams);
        }
        let manualAddRecordsPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionManualLoads', props.sdkOptions);
            Udesk.ajax.get(url, params).then(
                (resp) => {
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.manualAdd.index.records
                            )
                    );
                    reject(reason);
                }
            );
        });

        //手动添加使用新的字段查询接口，新接口中增加了预处理字段
        let manualCustomFields = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/field/task/' + params.taskId + '?type=call',
                props.sdkOptions
            );
            Udesk.ajax.get(url).then(
                (resp) => {
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.labels.field
                            )
                    );
                    reject(reason);
                }
            );
        });
        let processStatusPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath('/inspectionTasks/' + params.taskId, this.props.sdkOptions);
            Udesk.ajax.get(url).then(
                (resp) => {
                    this.privates.processStatus = resp.data;
                    resolve(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title
                            )
                    );
                    reject(reason);
                }
            );
        });

        return {
            manualAddRecords: manualAddRecordsPromise,
            task: props.sdkOptions.props.task,
            taskId: params.taskId,
            manualCustomFields: manualCustomFields,
            processCreateStatus: processStatusPromise,
        };
    }

    onModelResolved(model) {
        if (model.manualAddRecords) {
            const { paging } = model.manualAddRecords;
            this.setState({
                pageNum: paging.pageNum,
                pageSize: paging.pageSize,
                total: paging.total,
            });
        }
    }
}
