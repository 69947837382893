import React from 'react';
import Locales from 'UdeskLocales';
// import Udesk from 'Udesk';
import { Button, Space, Upload as UdeskUpload } from 'udesk-ui';
import { UploadFile } from 'src/util/upload';
import { Cover } from 'src/pages/coach/learning-center/components/cover';

// const MAX_PICTURE_SIZE = 500;

export const Upload = (props) => {
    const locales = Locales['current'];

    const { onChange, value, onUploadData } = props;
    const handlerUpload = (file) => {
        // if (file.size / 1024 > MAX_PICTURE_SIZE) {
        //     return Udesk.ui.notify.error(locales.pages.gong.userManage.edit.avatarGreaterThan500kb);
        // }

        UploadFile(file, (file) => {
            onUploadData(file.name);
            onChange(file.url);
        });

        // Udesk.app.fileStorage.upload(file, {
        //     token: new Promise((resolve, reject) => {
        //         Udesk.business.getServerAuthorization(Udesk.enums.uploadFileTypes.image.id).then(
        //             function (resp) {
        //                 resolve(resp);
        //             },
        //             function (reason) {
        //                 reject(reason);
        //             }
        //         );
        //     }),
        //     //progressCallback: progressCallback.bind(this),
        //     successCallback: (file, uploadData) => {
        //         Udesk.ui.notify.success(locales.pages.gong.userManage.edit.uploadSuccessful);
        //         let fileUrl = `${uploadData.token.host}/${uploadData.key}`;
        //         onUploadData(uploadData.filename);
        //         onChange(fileUrl);
        //     },
        //     //errorCallback: uploadFileError.bind(this),
        // });
        return false;
    };
    return (
        <Space align='end'>
            <Cover src={value} />
            <p> 
                {locales.pages.gong.userManage.edit.supportJPGGIFAndPNGUpTo500kb}
                <UdeskUpload
                    accept=".jpg,.gif,.png"
                    showUploadList={false}
                    beforeUpload={handlerUpload}
                >
                    <Button type="link">{locales.pages.gong.userManage.edit.upload}</Button>
                </UdeskUpload>
            </p>

        </Space>
    );
};
