import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { putUsersSetLanguage } from 'src/api/users/setLanguage/index';
import { putCompanysModel } from 'src/api/companys/model';

export default class AdminSystemIndexComponent extends React.Component {
    privates = {
        lang: Udesk.data.init.userLanguage,
        largeModel: undefined,
        largeModelOptions: [],
    };
    //#region actions
    actions = {
        onValueChanged(obj, key, e) {
            let value = e;
            if (e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.update();
        },
        onSwitchValueChangedWithEffect(fn, cb, ...args) {
            fn(...args);
            cb?.();
        },
        onSwitchValueChanged(obj, key, checked) {
            let value = checked ? 1 : 0;
            obj[key] = value;
            this.actions.update();
        },
        imSendStatusFilterChange(obj, key, valueList) {
            let value = valueList.length ? valueList.reduce((prev, cur) => prev + cur, 0) : 0;
            obj[key] = value;
            this.actions.update();
        },
        save() {
            const { privates, props } = this;
            let url = Udesk.business.apiPath.concatApiPath(
                '/companys/sys/config',
                props.sdkOptions
            );
            // Udesk.ajax.put(url, privates.model.sysConfig).then(
            //     resp => {
            //     },
            //     reason => {
            //         Udesk.ui.notify.error(reason.errorMsg);
            //     }
            // );

            let tasks = [
                Udesk.ajax.put(url, privates.model.sysConfig),
                putUsersSetLanguage({ userLanguage: privates.lang }),
            ];

            if (this.privates.largeModel) {
                tasks = [
                    ...tasks,
                    putCompanysModel({ params: { modelInfoId: this.privates.largeModel } }),
                ];
            }

            Promise.all([...tasks]).then(([resp1]) => {
                Udesk.ui.notify.success(UdeskLocales.current.business.info.editSuccess);
                if (props.massageSdkRef.current) {
                    // QaSubApp功能
                    props.massageSdkRef.current.actions.setAgentProperties({
                        disable: !privates.model.sysConfig.enabledMessage,
                    });
                }
                if (Udesk.data.init.userLanguage !== privates.lang) {
                    window.location.reload();
                }
            });
        },
        onUserLanguageChange(value) {
            this.privates.lang = value;
            this.actions.update();
        },
        onSelectValueChanged(obj, key, values) {
            obj[key] = values;
            this.actions.update();
        },
        addDataDeleteConfig(init) {
            if (!Array.isArray(this.privates.model.sysConfig.dataDeleteConfig)) {
                this.privates.model.sysConfig.dataDeleteConfig = [];
            }
            if ((init && !this.privates.model.sysConfig.dataDeleteConfig.length) || !init) {
                const scopeOptions = getDataDeleteConfig().scopeOptions;

                this.privates.model.sysConfig.dataDeleteConfig.push(
                    creactDataDeleteConfig(
                        scopeOptions.find((i) =>
                            this.privates.model.sysConfig.dataDeleteConfig.every(
                                (c) => c.scope !== i.value
                            )
                        ).value
                    )
                );
            }
            this.actions.update();
        },
        onDataDeleteConfigChange(index, values) {
            this.privates.model.sysConfig.dataDeleteConfig[index] = values;
            this.actions.update();
        },
        deleteDataDeleteConfig(index) {
            this.privates.model.sysConfig.dataDeleteConfig =
                this.privates.model.sysConfig.dataDeleteConfig.filter((_, i) => index !== i);
            this.actions.update();
        },

        onLargeModelChange(value) {
            this.privates.largeModel = value;
            this.actions.update();
        },
    };
    //#endregion
    onModelResolved(asyncModel) {
        requestIdleCallback(() => {
            if (asyncModel.largeModel) {
                this.privates.largeModelOptions = asyncModel.largeModel.map((t) => ({
                    label: t.name,
                    value: t.id,
                }));
                this.privates.largeModel = asyncModel.largeModel.find((l) => l.isUse)?.id;
            }
            this.actions.update();
        });
    }
}

export class DataDeleteConfig {
    constructor(scope = 1) {
        return {
            scope,
            dataDeleteTimeConfig: {
                interval: 7,
                type: 'day',
            },
            complainDeleteTimeConfig: {
                interval: 3,
                type: 'day',
            },
        };
    }
    changeDataDeleteTimeConfig(newValue) {
        this.dataDeleteTimeConfig = Object.assign({}, this.dataDeleteTimeConfig, newValue);
    }
    changeComplainDeleteTimeConfig(newValue) {
        this.complainDeleteTimeConfig = Object.assign({}, this.complainDeleteTimeConfig, newValue);
    }
}

function creactDataDeleteConfig(scope) {
    return new DataDeleteConfig(scope);
}

export const getDataDeleteConfig = () => {
    return {
        scopeOptions: [
            {
                value: 1,
                label: /* 通话 */ UdeskLocales['current'].pages.admin.system.component.conversation,
            },
            {
                value: 2,
                label: /* 对话 */ UdeskLocales['current'].pages.admin.system.component.dialogue,
            },
            {
                value: 3,
                label: /* 企业微信 */ UdeskLocales['current'].pages.admin.system.component.weCom,
            },
        ],
        dataDeleteTimeConfigInterval: [
            {
                value: 'day',
                label: /* 日 */ UdeskLocales['current'].pages.admin.system.component.day,
            },
            {
                value: 'week',
                label: /* 周 */ UdeskLocales['current'].pages.admin.system.component.week,
            },
            {
                value: 'month',
                label: /* 月 */ UdeskLocales['current'].pages.admin.system.component.month,
            },
            {
                value: 'year',
                label: /* 年 */ UdeskLocales['current'].pages.admin.system.component.year,
            },
        ],
    };
};
