import Udesk from 'Udesk';
import React from 'react';
import ReactTree from 'udesk-react/src/components/react-tree';
import { Button } from 'udesk-ui';
import RoleDetailTaskPermissionsRoute from './route';
import './style.scss';
import { Checkbox } from '@alifd/next';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { isQaSubApp } from 'Udesk/system/subApp';

const Title = (props) => {
    const locales = UdeskLocales['current'];

    return (
        <div className="role-detail-data-permissions-page-title">
            <div>{/* 质检任务权限 */}{UdeskLocales['current'].pages.role.detail.taskPermissions.template.qualityInspectionTaskPermission}</div>
            {props.showBtn && (
                <Button onClick={props.goToEdit}>{locales.pages.gong.roleManage.list.edit}</Button>
            )}
        </div>
    );
};

export default class RoleDetailTaskPermissionsTemplate extends RoleDetailTaskPermissionsRoute {
    render() {
        let { actions, locales } = this;
        let { hiddenPageHeader: readOnly } = this.props;
        let { defaultMenuList, taskList, inspectorMenuList } = this.privates;
        let { _allTag, _myCreatedTag, _myParticipateTag } = this.privates.computes;

        return (
            <Page
                pageBodyClassName="role-detail-task-permissions-page"
                title={readOnly ? '' : /* 编辑 */UdeskLocales['current'].pages.role.detail.taskPermissions.template.edit}
                padding={false}
                onBack={() => window.history.back()}
                extra={
                    readOnly
                        ? []
                        : [
                            <Button onClick={() => window.history.back()}>
                                {locales.labels.cancel}
                            </Button>,
                            <Button type="primary" onClick={actions.saveMenuList}>
                                {locales.pages.gong.roleManage.edit.confirm}
                            </Button>,
                        ]
                }
            >
                <Title goToEdit={actions.goToEdit} showBtn={readOnly} />
                <div className="role-detail-task-permissions-page-content">
                    <div className="role-detail-task-permissions-page-title-row">
                        <If condition={!readOnly}>
                            <span className="role-detail-task-permissions-page-title">
                                {locales.components.pages.role.detail.taskPermissions.allTask}
                            </span>
                            <Checkbox checked={_allTag} onChange={actions.checkedAll}>
                                {locales.labels.selectAll}
                            </Checkbox>
                        </If>
                    </div>
                    <div className="role-detail-task-permissions-page-title-row">
                        <span className="role-detail-task-permissions-page-title">
                            {locales.components.pages.role.detail.taskPermissions.myCreatedTask}
                        </span>
                        <If condition={!readOnly}>
                            <Checkbox
                                checked={_myCreatedTag}
                                onChange={actions.checkedMyCreatedAll}
                            >
                                {locales.labels.selectAll}
                            </Checkbox>
                        </If>
                    </div>
                    <div className="role-detail-task-permissions-page-tree-row">
                        <ReactTree
                            nodes={Udesk.data.adminTaskMenuList.root}
                            selectedNodes={defaultMenuList}
                            childNodesField="childItems"
                            selectionMode="items"
                            checkable={true}
                            onChecked={actions.checkedMyCreatedMenu}
                            onUnchecked={actions.checkedMyCreatedMenu}
                            getNodeBehaviors={actions.getNodeBehaviors}
                            expandedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-down-s-xiasanjiao"
                            collapsedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-yousanjiao"
                        />
                    </div>
                    <div className="role-detail-task-permissions-page-title-row">
                        <span className="role-detail-task-permissions-page-title">
                            {/* 我参与的 */}
                            {locales.pages.role.detail.taskPermissions.template.iParticipated}
                        </span>
                        <If condition={!readOnly}>
                            <Checkbox
                                checked={_myParticipateTag}
                                onChange={actions.checkedMyParticipateAll}
                            >
                                {locales.labels.selectAll}
                            </Checkbox>
                        </If>
                    </div>
                    <div className="role-detail-task-permissions-page-tree-row">
                        <ReactTree
                            nodes={Udesk.data.adminTaskMenuList.root}
                            selectedNodes={inspectorMenuList}
                            childNodesField="childItems"
                            selectionMode="items"
                            checkable={true}
                            onChecked={actions.checkedMyCreatedMenu}
                            onUnchecked={actions.checkedMyCreatedMenu}
                            getNodeBehaviors={actions.getNodeBehaviors}
                            expandedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-down-s-xiasanjiao"
                            collapsedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-yousanjiao"
                        />
                    </div>
                    <For each="task" index="index" of={taskList}>
                        <div className="role-detail-task-permissions-page-title-row">
                            <span className="role-detail-task-permissions-page-title">
                                {task.taskName}
                            </span>
                            <If condition={!readOnly}>
                                <Checkbox
                                    checked={task._tag}
                                    onChange={actions.checkedTaskAll.params(task)}
                                >
                                    {locales.labels.selectAll}
                                </Checkbox>
                            </If>
                        </div>
                        <div className="role-detail-task-permissions-page-tree-row">
                            <ReactTree
                                nodes={Udesk.data.adminTaskMenuList.root}
                                selectedNodes={task.taskMenuList}
                                childNodesField="childItems"
                                selectionMode="items"
                                checkable={true}
                                onChecked={actions.checkedTaskMenu.params(task)}
                                onUnchecked={actions.checkedTaskMenu.params(task)}
                                getNodeBehaviors={actions.getNodeBehaviors}
                                expandedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-down-s-xiasanjiao"
                                collapsedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-yousanjiao"
                            />
                        </div>
                    </For>
                </div>
                <If condition={!readOnly && isQaSubApp()}>
                    <div className='role-detail-task-permissions-page-button-row'>
                        <Button onClick={actions.backToIndex}>
                            {locales.labels.cancel}
                        </Button>
                        &nbsp;
                        <Button type='primary' onClick={actions.saveMenuList}>
                            {locales.labels.save}
                        </Button>
                    </div>
                </If>
            </Page>
        );
    }
}
