import Udesk from 'Udesk';
import { fire, inspect } from './core';

const getHasFeatureHandle = () => {
    return Udesk.data.init?.user?.hasFeature;
};

const validate = (handle, permit) => {
    if (typeof permit === 'function') {
        return fire(permit, handle);
    }
    return fire(handle, permit);
};

export const hasAllFeature = (list, success, fail) => {
    const handle = getHasFeatureHandle();
    if (handle) {
        return inspect(
            list.every(item => {
                return validate(handle, item);
            }), 
            success,
            fail
        );
    }
    return null;
};

export const hasAnyFeature = (list, success, fail) => {
    const handle = getHasFeatureHandle();
    if (handle) {
        return inspect(
            list.some(item => {
                return validate(handle, item);
            }), 
            success,
            fail
        );
    }
    return null;
};

export const hasFeature = (feature, success, fail) => {
    if (Array.isArray(feature)) {
        return hasAllFeature(feature, success, fail);
    }
    if (typeof feature === 'string') {
        return hasAllFeature([feature], success, fail);
    }
    return null;
};