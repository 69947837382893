// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PostReviewIntelligentPartnerNodeRelationBody,
    BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse,
    DeleteReviewIntelligentPartnerNodeRelationBody,
} from '../../../../types';

/**
 * 保存对话流程节点关系
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/node/relation
 */
export function postReviewIntelligentPartnerNodeRelation(
    data: PostReviewIntelligentPartnerNodeRelationBody
): Promise<BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse>;
export function postReviewIntelligentPartnerNodeRelation(
    data: PostReviewIntelligentPartnerNodeRelationBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse>;
export function postReviewIntelligentPartnerNodeRelation(
    data: PostReviewIntelligentPartnerNodeRelationBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse> {
    return request<PostReviewIntelligentPartnerNodeRelationBody, never, never>(
        '/review/intelligentPartner/node/relation',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeRelation',
        }
    );
}

/**
 * 解除流程节点关系
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [DELETE] /review/intelligentPartner/node/relation
 */
export function deleteReviewIntelligentPartnerNodeRelation(
    data: DeleteReviewIntelligentPartnerNodeRelationBody
): Promise<BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse>;
export function deleteReviewIntelligentPartnerNodeRelation(
    data: DeleteReviewIntelligentPartnerNodeRelationBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse>;
export function deleteReviewIntelligentPartnerNodeRelation(
    data: DeleteReviewIntelligentPartnerNodeRelationBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogFlowNodeFoundResponse> {
    return request<DeleteReviewIntelligentPartnerNodeRelationBody, never, never>(
        '/review/intelligentPartner/node/relation',
        {
            ...options,
            data,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerNodeRelation',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/relation',
        method: 'post',
    },
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/node/relation',
        method: 'delete',
    },
];
