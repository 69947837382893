import React, { useState, useEffect } from 'react';
// import Locales from 'UdeskLocales';
import _cloneDeep from 'lodash-es/cloneDeep';
import { DetailedInquiryConfigTitle } from './components/DetailedInquiryConfigTitle';
import { DetailedInquiryList } from './components/DetailedInquiryList';
import { FormBuilder } from 'udesk-ui';
import { useFieldValues } from 'src/pages/coach/course-management/flow/components/ConfigDrawer';
import UdeskLocales from 'UdeskLocales';

// const ScoringRuleListWrap = styled.div`
//     .udesk-coach-web-scoring-rule-item-delete-btn {
//         position: absolute;
//         top: 8px;
//         right: 0;
//     }
// `;

const useCloneValue = (safeValue) => {
    const cloneValue = (safeValue: any = {}) => {
        const temp = _cloneDeep(safeValue);
        if (typeof safeValue.notHitRule === 'string') {
            safeValue.notHitRule = safeValue.notHitRule.split(',').filter(Boolean);
        }
        if (!temp.questionCloselyMode) {
            temp.questionCloselyMode = 1;
        }
        return temp;
    };
    const [value, setValue] = useState(() => {
        return cloneValue(safeValue);
    });

    useEffect(() => {
        setValue(cloneValue(safeValue));
    }, [safeValue]);

    return [value, setValue];
};

export const DetailedInquiryConfig = React.memo((props: any) => {
    // const locales = Locales['current'];
    const { value: safeValue, onChange, saveFormInstance } = props;
    const [value, setValue] = useCloneValue(safeValue);
    const [form] = FormBuilder.useForm();
    const { current: fieldValues } = useFieldValues();
    const [questionCloselyMode, setQuestionCloselyMode] = useState(1);

    useEffect(() => {
        saveFormInstance(form);
        
    }, []);
    useEffect(() => {
        form.resetFields();
        setQuestionCloselyMode(safeValue?.questionCloselyMode ?? 1);
    }, [value]);
    return (
        <React.Fragment>
            <DetailedInquiryConfigTitle
                value={value.enableQuestionClosely}
                onChange={(enableQuestionClosely) => {
                    setValue((prev) => ({
                        ...prev,
                        enableQuestionClosely,
                    }));
                    onChange({
                        enableQuestionClosely,
                    });
                }}
            ></DetailedInquiryConfigTitle>
            {!!value.enableQuestionClosely && (
                <FormBuilder
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    customComponents={{ DetailedInquiryList }}
                    initialValues={value}
                    footerRender={false}
                    onValuesChange={(a, b) => {
                        
                    }}
                    fields={[
                        {
                            name: 'questionCloselyMode', // 1:按规则 2:按得分
                            type: 'RadioGroup',
                            props: {
                                options: [
                                    {
                                        label: /* 按规则 */ UdeskLocales['current'].components.coach
                                            .detailedInquiryConfig.index.accordingToRules,
                                        value: 1,
                                    },
                                    {
                                        label: /* 按得分 */ UdeskLocales['current'].components.coach
                                            .detailedInquiryConfig.index.byScore,
                                        value: 2,
                                    },
                                ],
                                onChange: (e) => {
                                    setQuestionCloselyMode(e.target.value);
                                    form.setFieldsValue({
                                        traineeConfigAskedList: [],
                                    });
                                },
                            },
                        },
                        {
                            // name: 'traineeConfigAskedList', // 追问规则
                            type: 'DetailedInquiryList',
                            props: {
                                questionCloselyMode,
                                scoreRuleList: (fieldValues.scoreRuleList || []).map((i) => ({
                                    label: i.name,
                                    value: `${i.id}`,
                                })),
                            },
                        },
                    ]}
                />
            )}
        </React.Fragment>
    );
});
