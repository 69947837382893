import React, { useState, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { message, Icon, Space, Button } from 'udesk-ui';
import { useLiveEffect, useRequest } from 'src/util/hook';
import '../index.scss'; 
import CallDetailModal from '../../../selling-point/components/callDetailModal';

const defaultPagination = {
    current: Udesk.config.paging.defaultPageNumber,
    pageSize: Udesk.config.paging.defaultPageSize,
    total: 0,
};

export default React.memo((props: any) => {
    const { selectedRowKey: groupId, locales } = props;
    const [pagination, setPagination] = useState(defaultPagination);
    const [tableData, setTableData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [likeBtnDisabled, setLikeBtnDisabled] = useState(false);
    const request = useRequest();
    const [selectedRow, setSelectedRow] = useState();
    const [visible, setVisible] = useState(false);

    const ajaxTableData=  useCallback((map) => {
        setLoading(true);
        return request(`review/words/accept`, map).then((resp) => {
            const { pageNum, pageSize } = map;
            const pagination = {
                total: resp.paging?.total,
                current: resp.paging?.pageNum || pageNum,
                pageSize: resp.paging?.pageSize || pageSize,
            };
            setTableData(resp.data ?? []);
            setPagination(pagination);
        }).finally(() => {
            setLoading(false);
        });
    }, [
        request
    ]);

    const onRequest = useCallback((params) => {
        const {pagination}  = params;
        ajaxTableData({
            groupId,
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
        });
    }, [
        ajaxTableData, 
        groupId
    ]);

    const likeHandle = useCallback(item => {
        setLikeBtnDisabled(true);
        request(`review/words/accept/like/${item.id}`, {}, 'post').then((resp: any) => {
            message.success(/* 点赞成功 */locales.pages.semanticIntelligence.wordsMining.favoriteView.components.pageRightTable.likeSuccessfully);
            item.likeUserIdList = item.likeUserIdList || [];
            item.likeUserIdList.push(Udesk.data.init.user.id);
            item.likeNum = (item.likeNum || 0) + 1;
        }).finally(() => {
            setLikeBtnDisabled(false);
            setTableData(list => {
                return [...list];
            });
        });
    }, [
        locales,
        request
    ]);

    const cancelLikeHandle = useCallback(item => {
        setLikeBtnDisabled(true);
        request(`review/words/accept/cancelLike/${item.id}`, {}, 'post').then((resp: any) => {
            message.success(/* 点赞取消 */locales.pages.semanticIntelligence.wordsMining.favoriteView.components.pageRightTable.likeCancel);
            const index = item.likeUserIdList.findIndex(id => Udesk.data.init.user.id === id);
            if (index > -1) {
                item.likeUserIdList.splice(index, 1);
            }
            item.likeNum = item.likeNum - 1;
        }).finally(() => {
            setLikeBtnDisabled(false);
            setTableData(list => {
                return [...list];
            });
        });
    }, [
        locales.pages.semanticIntelligence.wordsMining.favoriteView.components.pageRightTable.likeCancel, 
        request
    ]);

    const linkToSessionDetails = useCallback((record) => {
        request(`review/words/accept/${record.id}`, {}).then((resp: any) => {
            setVisible(true);
            setSelectedRow(record);
        });
    }, []);

    const hasClick = useCallback((list) => {
        if (list) {
            return list.some(id => id === Udesk.data.init.user.id);
        }
        return false;
    }, []);

    useLiveEffect(() => {
        if (groupId) {
            const params = {
                pageSize: pagination.pageSize,
                current: 1,
                total: 0,
            };
            setPagination(params);
            ajaxTableData({
                groupId,
                pageNum: params.current,
                pageSize: params.pageSize,
            });
        }
    }, [
        groupId
    ]);

    const tableProps = useMemo<StandardizeTableProps['table']>(() => {
        const columns: StandardizeTableProps['table']['columns'] = [
            {
                title: /* 采纳话术 */locales.pages.semanticIntelligence.wordsMining.favoriteView.components.pageRightTable.adoptScripts,
                key: 'name',
                dataIndex: 'name',
                width: '70%',
                render(_name, record) {
                    return (
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <div className='message'>
                                <span className="user-type customer">{/* 客 */}{locales.pages.semanticIntelligence.wordsMining.dissent.components.pageRightTable.index.passenger}</span>
                                <p>{record.customerWord}</p>
                            </div>
                            {
                                record.seatsWord.map(item => (
                                    <div className='message'>
                                        <span className="user-type">{/* 坐 */}{locales.pages.semanticIntelligence.wordsMining.favorite.components.pageRightTable.index.sit}</span>
                                        {
                                            item.length > 200 ? (
                                                <p title={item}>{item.slice(0, 200)}...</p>
                                            ) : (
                                                <p>{item}</p>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </Space>
                    );
                }
            },
            {
                title: /* 操作 */locales.pages.semanticIntelligence.wordsMining.favoriteView.components.pageRightTable.operation,
                key: 'actions',
                width: '30%',
                render (_name, record) {
                    return (
                        <Space size={50}>
                            <Button 
                                type='link' 
                                disabled={likeBtnDisabled}
                                className='like-btn'
                                onClick={
                                    () => hasClick(record.likeUserIdList) ? cancelLikeHandle(record) : likeHandle(record)
                                }
                                icon={
                                    <Icon 
                                        antdIcon={true} 
                                        type={hasClick(record.likeUserIdList) ? 'LikeFilled' : 'LikeOutlined'}
                                    />
                                }
                            >{record.likeNum || 0}</Button>
                            <Button disabled={!record.callId} onClick={() => linkToSessionDetails(record)} type='link'>{/* 查看对话详情 */}{locales.pages.semanticIntelligence.wordsMining.favoriteView.components.pageRightTable.viewConversationDetails}</Button>
                        </Space>
                    );
                },
            },
        ];
        return {
            loading,
            columns,
            dataSource: tableData,
            pagination,
            rowSelectionEnhance: {},
        };
    }, [
        locales,
        loading, 
        likeBtnDisabled,
        tableData, 
        pagination, 
        hasClick,
        cancelLikeHandle,
        likeHandle,
        linkToSessionDetails
    ]);

    return (
        <div className='words-mining-favorite-view-page-right'>
            <CallDetailModal
                selectedRow={selectedRow} 
                request={request} 
                visible={visible} 
                setVisible={setVisible}
            />
            <StandardizeTable.RequestTable autoRequestOnMount={false} onRequest={onRequest} table={tableProps} />
        </div>
    );
});
