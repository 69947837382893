import Udesk from 'Udesk';
import React from 'react';
import ReactTree from 'udesk-react/src/components/react-tree';
import RoleDetailBasicInfoRoute from './route';
import './style.scss';
import { Form } from '@alifd/next';
import { Input } from 'udesk-ui';

const FormItem = Form.Item;

export default class RoleDetailBasicInfoTemplate extends RoleDetailBasicInfoRoute {
    render() {
        let {
            actions,
            locales,
        } = this;
        let {
            route,
        } = this.props;
        let {
            name,
            selectedMenuList,
        } = this.privates;
        let readOnly = route.name === 'roleViewBasicInfo';

        return (
            <div className="role-detail-basic-info-page">
                <Form style={{ width: '100%' }}>
                    <FormItem required label={locales.components.pages.role.detail.basicInfo.roleName}>
                        <Input style={{ width: '300px' }} name="roleName" value={name} maxLength={100} maxCount={100} showCount={true} onChange={actions.changeRoleName} disabled={readOnly} />
                    </FormItem>
                    <FormItem required label={locales.components.pages.role.detail.basicInfo.permissions}>
                        <div className="role-detail-basic-info-page-tree">
                            <ReactTree nodes={Udesk.data.adminMenuList.root} selectedNodes={selectedMenuList} childNodesField="childItems" selectionMode="items" checkable={true} getNodeBehaviors={actions.getNodeBehaviors} expandedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-down-s-xiasanjiao" collapsedIconClass="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-yousanjiao"/>
                        </div>
                    </FormItem>
                    <If condition={!readOnly}>
                        <FormItem label=" ">
                            <Form.Reset type="normal" onClick={actions.backToIndex}>
                                {locales.labels.cancel}
                            </Form.Reset>
                            &nbsp;
                            <Form.Submit type="primary" validate={["roleName"]} onClick={actions.saveRole}>
                                {locales.labels.save}
                            </Form.Submit>
                        </FormItem>
                    </If>
                </Form>
            </div>
        );
    }
}

