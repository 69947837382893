import React from 'react';
import Udesk from 'Udesk';
import _find from 'lodash-es/find';
import _isEmpty from 'lodash-es/isEmpty';
class AudioCallTextInfoComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        inspectDataSource: null,
        item: null,
        type: null,
        content: null,
        markSemanticVisible: true,
        caseBaseFlag: false,
        isSysMsg:false,
    };
    //#endregion

    state = {
        copied: false,
    };
    privates = {
        fields: true,
        inputStatus: true,
        caseBaseFlag: false,
        addFlag: false,
        callFields: '',
        inputValue: '',
        statustCase: true,
        averageFlag: false,
        audioFlag: true,
        playingRudio: new Audio(),
        visible: false,
        remark: {},
    };
    static computes = {};
    actions = {
        sendCurrentTimeRange(item) {
            this.privates.audioFlag = false;
            this.actions.update();
            this.trigger('onTimeRangeChanged', item);
        },
        markSemantic(content) {
            this.trigger('onMarkSemanticClick', content);
        },
        caseBaseAdd(id) {
            let { caseBaselist } = this.props;
            this.privates.fields
                ? caseBaselist({ id: id, flag: true, inputValue: this.privates.callFields })
                : caseBaselist({ id: id, flag: false });
            this.privates.fields = !this.privates.fields;
            this.privates.statustCase = false;
            this.privates.addFlag = !this.privates.addFlag;
            this.actions.update();
            this.trigger('audioStop');
        },
        fieldsChange() {
            let { inputFlag, item } = this.props;
            inputFlag && inputFlag({ id: item.data.id, flag: false });
            this.privates.inputStatus = false;
            this.actions.update();
        },
        closeFields() {
            let { inputFlag, item } = this.props;
            this.privates.inputValue = this.privates.callFields;
            this.privates.inputStatus = true;

            inputFlag && inputFlag({ id: item.data.id, flag: true });
            this.actions.update();
        },
        onOkFields(id) {
            let { inputFlag, item, caseBaselist } = this.props;
            this.privates.callFields = this.privates.inputValue || this.privates.callFields;
            this.privates.inputStatus = true;
            if (!this.privates.fields) {
                caseBaselist({ id: id, flag: true, inputValue: this.privates.callFields });
            }
            inputFlag && inputFlag({ id: item.data.id, flag: true });
            this.actions.update();
        },
        inputEdit(e) {
            this.privates.inputValue = e.target.value;
            this.trigger('audioStop');
        },
        onEditorChange(value) {
            this.privates.inputValue = value;
            this.trigger('audioStop');
        },
        proverChange(list) {
            return (
                list &&
                list.map((item) => {
                    return <p key={item.id}>{item.name}</p>;
                })
            );
        },
        averageChange(flag) {
            this.privates.averageFlag = flag;
            let { audioFlag } = this.privates;
            let { waveAudio } = this.props;
            if (audioFlag && waveAudio) {
                this.trigger('onAudioPause', !flag);
            }
            this.actions.update();
        },
        playRudio(url, e){
            e.stopPropagation();
            this.trigger('playRudio', url);
        },
        copyText(ref){
            this.setState({copied: true}, () => {
                setTimeout(() => {
                    this.setState({copied: false});
                }, 1000);
            });
        },
        showRemark(dialogRemark, item){
            this.privates.visible = true;
            if(!this.privates.remark.remark){
                this.privates.remark = {...dialogRemark};
            }
            if(!this.privates.remark.index){
                this.privates.remark.index = item.data.id;
            }
            this.actions.update();
        },
        saveRemark(){
            // /inspectionRemarks
            if(!this.props.canRemarkEdit){
                this.privates.visible = false;
                this.actions.update();
                return;
            }
            let {index, remark, id} = this.privates.remark;
            if(id){
                //更改逻辑
                let url = Udesk.business.apiPath.concatApiPath(
                    `inspectionRemarks/${id}`,
                    this.props.sdkOptions
                );
                let params = {
                    id: parseInt(id, 10),
                    index,
                    type: this.props.canRemarkEdit,
                    remark,
                };
                Udesk.ajax.put(url, params).then(
                    (resp) => {
                        let {dialogRemarkList} = this.props;
                        dialogRemarkList.forEach(item => {
                            if(item.index === this.privates.remark.index){
                                item.remark = this.privates.remark.remark;
                            }
                        });
                        this.props.changeDialogRemarkList && this.props.changeDialogRemarkList(dialogRemarkList.filter(i => i.remark));
                        this.actions.cancelRemark();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
    
            } else {
                //新增备注
                let url = Udesk.business.apiPath.concatApiPath(
                    `inspectionRemarks`,
                    this.props.sdkOptions
                );
                let params = {
                    dataId: parseInt(this.props.id, 10),
                    index,
                    type: this.props.canRemarkEdit,
                    remark,
                    callId:this.props.callId,
                };
                Udesk.ajax.post(url, params).then(
                    (resp) => {
                        let {data} = resp;
                        let {dialogRemarkList} = this.props;
                        this.props.changeDialogRemarkList && this.props.changeDialogRemarkList([...dialogRemarkList, data]);
                        this.actions.cancelRemark();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );    
            }
        },
        cancelRemark(){
            this.privates.visible = false;
            this.privates.remark = {};
            this.actions.update();
        },
        remarkChange(e){
            this.privates.remark.remark = e.target.value;
            this.actions.update();
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        if (props.fieldsList !== prevProps.fieldsList) {
            
            if (props.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id) {
                if (
                    props.fieldsList &&
                    _find(props.fieldsList.dialogText, (current) => {
                        return current.id === props.item.data.id;
                    })
                ) {
                    let obj = _find(props.fieldsList.dialogText, (current) => {
                        return current.id === props.item.data.id;
                    });
                    return {
                        callFields: obj.text,
                        addFlag: true,
                        fields: false,
                    };
                } else if (
                    props.fieldsList &&
                    _isEmpty(props.fieldsList.dialogText) &&
                    props.fieldsList.id &&
                    props.fieldsList.libraryId
                ) {
                    return {
                        callFields: props.item.states.text,
                        addFlag: true,
                        fields: false,
                    };
                } else {
                    return {
                        callFields: props.item.states.text,
                        addFlag: false,
                        fields: true,
                    };
                }
            }
            if (props.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id) {
                if (
                    props.fieldsList &&
                    _find(props.fieldsList.dialogText, (current) => {
                        return current.id === props.item.data.id;
                    })
                ) {
                    let obj = _find(props.fieldsList.dialogText, (current) => {
                        return current.id === props.item.data.id;
                    });
                    let text = JSON.parse(obj.text);
                    return {
                        callFields: text.data.content,
                        addFlag: true,
                        fields: false,
                    };
                } else if (
                    props.fieldsList &&
                    _isEmpty(props.fieldsList.dialogText) &&
                    props.fieldsList.id &&
                    props.fieldsList.libraryId
                ) {
                    return {
                        callFields: props.content,
                        addFlag: true,
                        fields: false,
                    };
                } else {
                    return {
                        callFields: props.content,
                        addFlag: false,
                        fields: true,
                    };
                }
            }
        }
    }
    componentDidMount() {
    }
    componentWillUnmount() {}
    asyncModel(changedParams) {}
    //#endregion
}

export default AudioCallTextInfoComponent;
