// import { useState,createContext } from 'react';
import UdeskLocales from 'UdeskLocales';

export const getNumberEchartOptions = (
    nodeName,
    avgScoreArr,
    xAxis
) => {
    console.log(UdeskLocales);
    // console.log('res', nodeName, avgScoreArr,xAxis);
    // 拆分 avgScoreArr 为nodeName length 的个数 用对象保存
    let valueObjArr = {};
    nodeName.forEach((item, index) => {
        valueObjArr[index] = [];
    });
    avgScoreArr.forEach(itemArr => {
        itemArr.forEach((vo, index) => {
            valueObjArr[index].push(vo);
        });
    });
    // 获取所有结果
    let allValueArr: any[] = [];
    for (let key in valueObjArr) {
        allValueArr = allValueArr.concat(valueObjArr[key]);
    }

    const leftMaxValue = calMax(allValueArr);
    const rightMaxValue = calMax(allValueArr);

    const interval_left = leftMaxValue / 5; //左轴间隔
    const interval_right = rightMaxValue / 5; //右轴间隔


    let variableSeries: any[]= [];
    nodeName.map((item, index) => {
        let tmpObj = {
            name: item,
            type: 'line',
            data: valueObjArr[index],
            smooth: true,
        };

        variableSeries.push(tmpObj);
    });
    let series = variableSeries;
    return getEchartOptions(
        series,
        leftMaxValue,
        interval_left,
        rightMaxValue,
        interval_right,
        xAxis
    );
};

function getEchartOptions(series, maxLeft, interval_left, maxRight, interval_right, xAxis) {
    const option = {
        backgroundColor: '#F5F8FE',
        color: [
            "#5B8FF9",
            "#5AD8A6",
            "#F6BD16",
            "#E86452",
            "#6DC8EC",
            "#945FB9",
            "#FF9845",
            "#1E9493",
            "#FF99C3",
            "#CDDDFD",
            "#CDF3E4",
            "#FCEBB9",
            "#F8D0CB",
            "#D3EEF9",
            "#DECFEA",
            "#FFE0C7",
            "#BBDEDE",
            "#FFE0ED",
        ],
        title: {
            text: /* 要点命中率 */UdeskLocales['current'].pages.coach.taskCenter.detail.components.scriptAnalysis.hook.useSummaryDetail.keyHitRate
        },
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            top: 24,
            data: series.map((s) => s.name),
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
        },
        yAxis: [
            {
                type: 'value',
                min: 0,
                max: maxLeft,
                splitNumber: 5,
                interval: interval_left,
                axisLine: {
                    //y轴
                    show: false,
                },
                axisTick: {
                    //y轴刻度线
                    show: false,
                },
                axisLabel: {
                    color: '#444343',
                    formatter: function (value, index) {
                        return (
                            value + '%'
                        );
                    },
                },
            },
            
        ],
        series,
    };

    return option;
}

function calMax(arr) {
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
        // 求出一组数组中的最大值
        if (max < arr[i]) {
            max = arr[i];
        }
    }
    const maxint = Math.ceil(max / 10); // 向上取整
    const maxval = maxint * 10; // 最终设置的最大值
    return maxval; // 输出最大值
}
