import React, { useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs } from 'udesk-ui';
import Locales from 'UdeskLocales';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { useEffect } from 'react';
import './style.scss';

const { TabPane } = Tabs;

function CollectionDataAdd(props) {
    const locales = Locales['current'];
    const ADD_TYPES = [
        {
            pathName: 'informationCollectDataAddAuto',
            key: 'auto',
            text: locales.components.pages.dataAdd.types.auto,
        },
        {
            pathName: 'informationCollectDataAddManual',
            key: 'manual',
            text: locales.components.pages.dataAdd.types.manual,
        },
    ];
    const [activeKey, setActiveKey] = useState<'auto' | 'manual'>('auto');
    const onAddTypeChange = (activeKey) => {
        setActiveKey(activeKey);
        const tab: any = ADD_TYPES.find(t => t.key === activeKey)??{};
        const routeOptions = {
            history: props.history,
            routeName: tab.pathName,
            pathParams: { taskId: props.match.params.taskId }
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    useEffect(() =>{
        if(props.history.location.pathname.indexOf('/data-add/manual') >= 0){
            setActiveKey('manual');
        }else{
            setActiveKey('auto');
        }
    }, [props.history.location]);
    return (
        <Page
            pageBodyClassName='data-collection-add-page-index'
            title={locales.components.pages.dataAdd.title}
            padding={true}
        >
            <Tabs activeKey={activeKey} onChange={onAddTypeChange}>
                {props.routes.map((item, i) => {
                    const tab: any = ADD_TYPES.find(t => t.pathName === item.name)??{};
                    return <TabPane tab={tab.text} key={tab.key}>
                        <SubRoutes key={i} route={item} />
                    </TabPane>;
                })}
            </Tabs>
        </Page>
    );
}

export default React.memo(CollectionDataAdd);
