import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Page from 'Component/common/udesk-qa-page-layout';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import OrganizationMemberSelect from 'src/pages/coach/task-center/components/OrganizationMemberSelect';
import { Icon, Tooltip, TreeSelect, Button, Form, Space, Input } from 'udesk-ui';
import { getCurrentRouteName, isGeneralSetting, isQaSubApp } from 'Udesk/system/subApp';
import { getRoleDatasByRoleId, putRoleDatasByRoleId } from 'src/api/roleDatas/{roleId}';
import { postPreprocessRulesFindAllDataCategoryType } from 'src/api/preprocessRules/findAllDataCategoryType';
import { linkTo } from 'src/util/link';
import { inspect } from 'src/util/core';
import { useOrgTreeData } from 'src/util/hook/user';
import { EnumRadio } from 'Component/common/enum-component';
import { getRolesById, putRolesById } from 'src/api/roles/{id}';
import './style.scss';

const Title = (props) => {
    const locales = UdeskLocales['current'];
    return (
        <div className="role-detail-data-permissions-page-title">
            <div>
                {locales.components.pages.role.detail.dataPermissions.dataPermissionsConfig}
                <Tooltip title={props.title}>
                    <Icon
                        style={{ marginLeft: 8 }}
                        type="InfoCircleOutlined"
                        antdIcon={true}
                    ></Icon>
                </Tooltip>
            </div>
            {props.showBtn && (
                <Button onClick={props.goToEdit}>{locales.pages.gong.roleManage.list.edit}</Button>
            )}
        </div>
    );
};
class DataPermissions extends React.Component<any, any> {
    formRef = React.createRef<RefType>();

    render() {
        let { match, hiddenPageHeader: readOnly } = this.props;
        const id = match.params.id;
        const sysModule = parseInt(match.params.sysModule, 10) || Udesk.enums.sysModuleList.qa.id;
        const locales = UdeskLocales['current'];

        return (
            <Page
                pageBodyClassName="role-detail-data-permissions-page"
                title={
                    readOnly
                        ? ''
                        : /* 编辑 */ UdeskLocales['current'].pages.role.detail.dataPermissions
                            .template.edit
                }
                padding={false}
                onBack={() => window.history.back()}
                extra={
                    readOnly
                        ? []
                        : [
                            <Button onClick={() => window.history.back()}>
                                {locales.labels.cancel}
                            </Button>,
                            <Button type="primary" onClick={this.save}>
                                {locales.pages.gong.roleManage.edit.confirm}
                            </Button>,
                        ]
                }
            >
                {
                    <Title 
                        goToEdit={this.goToEdit} 
                        showBtn={readOnly} 
                        title={
                            inspect(sysModule === Udesk.enums.sysModuleList.qa.id, () => (
                                locales.components.pages.role.detail.dataPermissions.tips
                            ), () => (
                                inspect(sysModule === Udesk.enums.sysModuleList.coach.id, () => (
                                    /* 该角色能够看见所选部门下的数据 */UdeskLocales['current'].pages.role.detail.dataPermissions.template.thisRoleCanSeeDataUnderTheSelectedDepartment
                                ))
                            ))

                        }
                    />
                }
                <div style={{
                    padding: '16px 0'
                }}>
                    {
                        inspect(sysModule === Udesk.enums.sysModuleList.qa.id, () => (
                            <QaDataPreprocessForm 
                                ref={this.formRef} 
                                id={id} 
                                readOnly={readOnly}
                                backToIndex={this.backToIndex} 
                            />
                        ))
                    }
                    {
                        inspect(sysModule === Udesk.enums.sysModuleList.coach.id, () => (
                            <CoachDataPreprocessForm 
                                ref={this.formRef} 
                                id={id} 
                                readOnly={readOnly}
                                backToIndex={this.backToIndex} 
                            />
                        ))
                    }
                </div>
            </Page>
        );
    }
    goToEdit = () => {
        linkTo(this.props.history, getCurrentRouteName('roleEditDataPermissions'), { 
            id: this.props.match.params.id, 
            sysModule: parseInt(this.props.match.params.sysModule, 10)
        });
    };
    backToIndex = () => {
        if(isGeneralSetting()){
            window.history.back();
        }else{
            linkTo(this.props.history, getCurrentRouteName('roleIndex'));
        }
    };
    save = () => {
        this.formRef.current?.submit();
    };
}

export default DataPermissions;

type RefType = {
    submit: () => void;
}

type PropsType = {
    id: number;
    readOnly: boolean;
    backToIndex?: any;
}

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};

const QaDataPreprocessForm = React.forwardRef<RefType, PropsType>((props, ref) => {
    const locales = UdeskLocales['current'];
    const {id, readOnly, backToIndex} = props;

    const [treeData, setTreeData] = useState<any[]>([]);
    const [form] = Form.useForm();

    const onSubmit = useCallback((values) => {
        putRoleDatasByRoleId(values, {
            segments: {
                roleId: id
            }
        }).then(
            resp => {
                Udesk.ui.notify.success(locales.business.info.editSuccess);
                backToIndex();
            }
        );
    }, [
        backToIndex, 
        id, 
        locales.business.info.editSuccess,
    ]);

    useImperativeHandle(ref, () => ({
        submit: form.submit
    }), [
        form
    ]);

    useEffect(() => {
        getRoleDatasByRoleId({
            segments: {
                roleId: id,
            }
        }).then((resp) => {
            form.setFieldsValue({
                dataPreprocessIdList: resp.data?.dataPreprocessIdList
            });
        });
    }, [
        form, 
        id
    ]);

    useEffect(() => {
        postPreprocessRulesFindAllDataCategoryType({
            keyWords: '',
            pageNum: 1,
            pageSize: 50,
        }).then(
            resp => setTreeData(resp.data?.map((item) => {
                return {
                    title: item.ruleName,
                    value: item.id,
                    key: item.id,
                };
            }) || [])
        );
    }, []);

    return (
        <Form
            {...layout}
            form={form}
            onFinish={onSubmit}
        >
            <Form.Item
                name="dataPreprocessIdList"
                label={locales.components.pages.role.detail.dataPermissions.select}
            >
                <TreeSelect
                    treeData={treeData}
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    placeholder={
                        locales.components.pages.role.detail.dataPermissions.selectPlaceholder
                    }
                    style={{ width: '80%' }}
                    disabled={readOnly}
                    showSearch={false}
                />
            </Form.Item>
            {
                inspect(isQaSubApp(), () => (
                    <Form.Item {...tailLayout}>
                        <Space>
                            {
                                inspect(!readOnly, () => (
                                    <Button type="primary" htmlType="submit">{locales.labels.save}</Button>
                                ))
                            }
                            <Button onClick={backToIndex}>
                                {readOnly ? locales.labels.back : locales.labels.cancel}
                            </Button>
                        </Space>
                    </Form.Item>
                ))
            }
        </Form>
    );
});

const CoachDataPreprocessForm = React.forwardRef<RefType, PropsType>((props, ref) => {
    const locales = UdeskLocales['current'];
    const {id, readOnly, backToIndex} = props;
    const {treeData} = useOrgTreeData();
    const [isHidden, setIsHidden] = useState(true);
    const [form] = Form.useForm();

    const onSubmit = useCallback((values) => {
        putRolesById(values, {
            segments: {
                id
            }
        }).then(
            resp => {
                Udesk.ui.notify.success(locales.business.info.editSuccess);
                backToIndex();
            }
        );
    }, [
        backToIndex, 
        id, 
        locales.business.info.editSuccess,
    ]);

    useImperativeHandle(ref, () => ({
        submit: form.submit
    }), [
        form
    ]);

    useEffect(() => {
        getRolesById({
            segments: {
                id,
            }
        }).then((resp) => {
            setIsHidden(Udesk.enums.dataPreprocessType.custom.id !== resp.data?.dataPermission);
            form.setFieldsValue({
                name: resp.data?.name,
                dataPermission: resp.data?.dataPermission || Udesk.enums.dataPreprocessType.current.id,
                dataPermissionOrg:  resp.data?.dataPermissionOrg
            });
        });
    }, [
        form, 
        id
    ]);

    return (
        <Form
            {...layout}
            form={form}
            onFinish={onSubmit}
        >
            <Form.Item hidden={true} name="name">
                <Input />
            </Form.Item>
            <Form.Item
                label={/* 数据权限 */UdeskLocales['current'].pages.role.detail.dataPermissions.template.dataPermissions}
                name="dataPermission"
            >
                <EnumRadio 
                    disabled={readOnly}
                    enumKey={'dataPreprocessType'} 
                    onChangeAfter={(event) => {
                        setIsHidden(Udesk.enums.dataPreprocessType.custom.id !== event.target.value);
                    }}
                />
            </Form.Item>
            {
                inspect(!isHidden, () => (
                    <Form.Item
                        label={/* 选择部门 */UdeskLocales['current'].pages.role.detail.dataPermissions.template.selectDepartment}
                        name="dataPermissionOrg"
                        rules={[{ required: !readOnly }]}
                    >
                        <OrganizationMemberSelect isView={readOnly} treeData={treeData}/>
                    </Form.Item>
                ))
            }
        </Form>
    );
});