import React from 'react';
import SynonymWordsComponent from './component';
import ReactModal from 'udesk-react/src/components/react-modal';
import ReactSelect from 'udesk-react/src/components/react-select';
import Udesk from 'Udesk';
import classNames from 'classnames';
import SmartWordsLibraryCommonList from '../smart-words-library-common-list';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Icon, Input } from 'udesk-ui';
import EllipsisLnline from 'src/components/common/ellipsis-inline';

export default class SynonymWordsTemplate extends SynonymWordsComponent {
    render() {
        let { locales, actions } = this;

        let {
            columns,
            isSynonymWordsModalShow,
            synonymWordsModalTitle,
            standardWords,
            synonymWords,
            effectiveness,
            newSynonymWordsIsNull,
            pleaseAddSynonymWords,
            synonymWordsExists,
        } = this.privates;

        let { getFieldDecorator } = this.props.form;

        let corporaType = Udesk.enums.smartWordsLibraryMenus.synonymWords.id;

        return (
            <div>
                <SmartWordsLibraryCommonList
                    columns={columns}
                    navItemType={corporaType}
                    getlistDataPath="corpora/synonymList"
                    openAddModal={actions.createSynonymWords}
                    uploadModalTitle={
                        locales.components.pages.smartWordsLibrary.upload.uploadSynonymWords
                    }
                    onCreated={(instance) => {
                        this.privates.smartWordsLibraryListInstance = instance;
                    }}
                    boardName={Udesk.enums.smartWordsLibraryBoard.synonymWords.id}
                />

                <ReactModal
                    cancelText={locales.labels.cancel}
                    visible={isSynonymWordsModalShow}
                    title={synonymWordsModalTitle}
                    headerClassName="udesk-qa-smart-words-library-modal-header"
                    footerClassName="udesk-qa-smart-words-library-modal-footer"
                    okText={locales.labels.save}
                    onOk={actions.saveSynonymWords}
                    onCancel={actions.closeSynonymWordsModal}
                >
                    <Form
                        onSubmit={actions.saveSynonymWords}
                        layout="horizontal"
                        className="synonym-words-modal-form smart-words-library-nav-item-form"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 19 }}
                    >
                        <Form.Item
                            label={
                                <EllipsisLnline>
                                    {locales.fields.smartWordsLibrary.synonymWords.standardWords}
                                </EllipsisLnline>
                            }
                        >
                            {getFieldDecorator('standardWords', {
                                initialValue: standardWords,
                                rules: [
                                    {
                                        required: true,
                                        message:
                                            locales.components.pages.smartWordsLibrary.synonymWords
                                                .pleaseEnterStandardWords,
                                    },
                                ],
                            })(
                                <Input
                                    className="synonym-words-login-form-input"
                                    placeholder={
                                        locales.components.pages.smartWordsLibrary.synonymWords
                                            .pleaseEnterStandardWords
                                    }
                                />
                            )}
                        </Form.Item>

                        {/* <Form.Item style={{display: 'none'}}>
                            {getFieldDecorator('synonymWords', {
                                rules: [{
                                    required: true,
                                    message: ''
                                }]
                            })(<span></span>)}
                        </Form.Item> */}

                        <Form.Item
                            label={
                                <EllipsisLnline>
                                    {locales.fields.smartWordsLibrary.synonymWords.synonymWords}
                                </EllipsisLnline>
                            }
                            className="synonym-words-required-form-item synonym-words-list-box"
                            required
                        >
                            {/* <span className="synonym-words-required-star">
                                {locales.components.pages.smartWordsLibrary.synonymWords.required}
                            </span> */}
                            <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list clearfix">
                                <With
                                    existSynonymWords={
                                        synonymWords !== null &&
                                        synonymWords !== undefined &&
                                        synonymWords.length > 0
                                    }
                                >
                                    <If condition={existSynonymWords}>
                                        <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list-item-groups clearfix">
                                            <For
                                                of={synonymWords}
                                                each="synonymWordsItem"
                                                index="index"
                                            >
                                                <div
                                                    key={`smart-words-library-synonym-words-modal-item-group-${index}`}
                                                    className="synonym-words-item-group"
                                                >
                                                    <label className="control-label synonym-words-item-label">
                                                        {synonymWordsItem}
                                                    </label>
                                                    <button
                                                        type="button"
                                                        onClick={actions.deleteOneSynonymWords.params(
                                                            index
                                                        )}
                                                        className="synonym-words-item-button"
                                                    >
                                                        <Icon
                                                            type="ic-minus-circle-jian"
                                                            className="synonym-words-icon"
                                                        />
                                                    </button>
                                                </div>
                                            </For>
                                        </div>
                                    </If>
                                </With>

                                <div className="udesk-qa-smart-words-library-modal-body-synonym-words-list-input-box col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <Input
                                        id="showNewSynonymWordsInput"
                                        className={classNames(
                                            'udesk-qa-smart-words-library-modal-body-synonym-words-list-input col-xs-12 col-sm-10 col-md-10 col-lg-10'
                                        )}
                                        onChange={actions.changeSynonymWords}
                                        onKeyPress={actions.pressEnter}
                                        value={this.privates.newSynonymWords}
                                        placeholder={
                                            locales.components.pages.smartWordsLibrary.synonymWords
                                                .addSynonymWords
                                        }
                                    />
                                    <button
                                        type="button"
                                        onClick={actions.addOneSynonymWords}
                                        className={classNames('synonym-words-item-button')}
                                    >
                                        <Icon
                                            type="ic-add-circle-jia"
                                            className={classNames({
                                                [`no-synonym-words-warning`]:
                                                    newSynonymWordsIsNull || pleaseAddSynonymWords,
                                            })}
                                        />
                                    </button>
                                </div>

                                <If condition={newSynonymWordsIsNull}>
                                    <span className="udesk-qa-smart-words-library-modal-body-warning-span">
                                        {
                                            locales.components.pages.smartWordsLibrary.synonymWords
                                                .synonymWordsCanNotNull
                                        }
                                    </span>
                                </If>
                                <If condition={pleaseAddSynonymWords}>
                                    <span className="udesk-qa-smart-words-library-modal-body-warning-span">
                                        {
                                            locales.components.pages.smartWordsLibrary.synonymWords
                                                .pleaseAddSynonymWords
                                        }
                                    </span>
                                </If>
                                <If condition={synonymWordsExists}>
                                    <span className="udesk-qa-smart-words-library-modal-body-warning-span">
                                        {
                                            this.locales.components.pages.smartWordsLibrary
                                                .synonymWords.synonymWordsExists
                                        }
                                    </span>
                                </If>
                            </div>
                        </Form.Item>

                        <Form.Item
                            label={
                                <EllipsisLnline>
                                    {locales.fields.smartWordsLibrary.synonymWords.effectiveness}
                                </EllipsisLnline>
                            }
                        >
                            {getFieldDecorator('effectiveness', {
                                initialValue: effectiveness.toString(),
                            })(
                                <ReactSelect
                                    classNames="udesk-qa-smart-words-library-react-select"
                                    hasNullSelect={false}
                                    dataScource={Udesk.enums.wordsEffectiveness}
                                    onChanged={actions.changeSynonymWordsEffectiveness}
                                ></ReactSelect>
                            )}
                        </Form.Item>
                    </Form>
                </ReactModal>
            </div>
        );
    }
}
