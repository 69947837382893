export default {
  lang: {
    num: '第',
    total: 'バー/合計',
    strip: '条',
    more: 'もっと',
    chosen: '選択済',
    cancel: '選択をキャンセルします',
    bulkOperations: '一括操作',
    import: 'インポート',
  },
};
