import React, { FC } from 'react';
import { Form } from 'udesk-ui';
import { FormItemProps } from 'udesk-ui/lib/form';
import Text, { TemplateProps as TextProps } from './components/Text';
import Edit, { TemplateProps as FieldProps } from './components/Edit';

export type TemplateProps = {
    isEdit?: boolean;
    formItemProps?: Partial<FormItemProps>;
    textProps?: TextProps;
    fieldProps?: FieldProps;
};

const Template: FC<TemplateProps> = (props) => {
    const { isEdit, formItemProps, textProps, fieldProps } = props;

    return (
        <Form.Item {...formItemProps}>
            {!isEdit ? <Text {...textProps} /> : <Edit {...fieldProps} />}
        </Form.Item>
    );
};

export default React.memo(Template);
