// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetSmartTagCategorysQuery,
    BaseResponseListSmartTagCategoryFoundResponse,
    PostSmartTagCategorysBody,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags smart-tag-category-controller
 * @link [GET] /smartTagCategorys
 */
export function getSmartTagCategorys(): Promise<BaseResponseListSmartTagCategoryFoundResponse>;
export function getSmartTagCategorys(
    options: ApiOptions<never, GetSmartTagCategorysQuery>
): Promise<BaseResponseListSmartTagCategoryFoundResponse>;
export function getSmartTagCategorys(
    options?: ApiOptions<never, GetSmartTagCategorysQuery>
): Promise<BaseResponseListSmartTagCategoryFoundResponse> {
    return request<never, never, GetSmartTagCategorysQuery>('/smartTagCategorys', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSmartTagCategorys',
    });
}

/**
 * save
 *
 * @export
 * @tags smart-tag-category-controller
 * @link [POST] /smartTagCategorys
 */
export function postSmartTagCategorys(
    data: PostSmartTagCategorysBody
): Promise<BaseResponseListSmartTagCategoryFoundResponse>;
export function postSmartTagCategorys(
    data: PostSmartTagCategorysBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListSmartTagCategoryFoundResponse>;
export function postSmartTagCategorys(
    data: PostSmartTagCategorysBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListSmartTagCategoryFoundResponse> {
    return request<PostSmartTagCategorysBody, never, never>('/smartTagCategorys', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSmartTagCategorys',
    });
}

export const meta = [
    { tags: ['smart-tag-category-controller'], path: '/smartTagCategorys', method: 'get' },
    { tags: ['smart-tag-category-controller'], path: '/smartTagCategorys', method: 'post' },
];
