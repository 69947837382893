// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PutIntelligentExtractTasksResultByIdParam,
    PutIntelligentExtractTasksResultByIdBody,
    BaseResponseVoid,
    DeleteIntelligentExtractTasksResultByIdParam,
} from '../../../types';

/**
 * 更新抽取结果问答
 *
 * @export
 * @tags 智能抽取接口
 * @link [PUT] /intelligentExtractTasks/result/{id}
 * @param id
 */
export function putIntelligentExtractTasksResultById(
    data: PutIntelligentExtractTasksResultByIdBody,
    options: ApiOptions<PutIntelligentExtractTasksResultByIdParam, never> & {
        segments: PutIntelligentExtractTasksResultByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentExtractTasksResultByIdBody,
        PutIntelligentExtractTasksResultByIdParam,
        never
    >('/intelligentExtractTasks/result/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentExtractTasksResultById',
    });
}

/**
 * 删除抽取结果
 *
 * @export
 * @tags 智能抽取接口
 * @link [DELETE] /intelligentExtractTasks/result/{id}
 * @param id
 */
export function deleteIntelligentExtractTasksResultById(
    options: ApiOptions<DeleteIntelligentExtractTasksResultByIdParam, never> & {
        segments: DeleteIntelligentExtractTasksResultByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentExtractTasksResultByIdParam, never>(
        '/intelligentExtractTasks/result/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteIntelligentExtractTasksResultById',
        }
    );
}

export const meta = [
    { tags: ['智能抽取接口'], path: '/intelligentExtractTasks/result/{id}', method: 'put' },
    { tags: ['智能抽取接口'], path: '/intelligentExtractTasks/result/{id}', method: 'delete' },
];
