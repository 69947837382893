import React from 'react';
import Udesk from 'Udesk';
import TasksCheckPointIndex from '../../manage/template/check-point/template';
import {getCategories} from '../../manage/template/check-point/component';
import {PageHeaderExtra} from '../components/pageHeaderExtra';

function extend(object, attrs) {
    Reflect.ownKeys(attrs).forEach((key) => {
        Reflect.set(object, key, attrs[key]);
    });
    return object;
}

class ApproveTasksCheckPointIndex extends TasksCheckPointIndex {
    storageStrategies = {
        storageWay: 'memory',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/tasks\/approve\/\d+\/template\/check-point\/\d+\/\d+\/\d+\/view\/\d+/i,
            ],
        },
    };
    constructor(props) {
        super(props);

        extend(this.privates, {
            storages: {
                categoryId: null,
                isTest: true,
                isEdit: false,
            },
        });

        extend(this.actions, {
            getPageTitle() {
                return /* 规则详情 */this.locales.pages.tasks.approve.checkPoint.index.ruleDetails;
            },
            goBack: () => () => {
                let routeOptions = {
                    history: this.props.history,
                    routeName: 'tasksIndex',
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            },
            changeVersion(value) {
                this.privates.storages.isTest = value;
                getCategories(this);
            },
            getPageHeaderExtra () {
                const {privates, actions, props} = this;
                return (
                    <PageHeaderExtra 
                        approveId={props.match.params.approveId}
                        approveStatus={+props.match.params.approveStatus}
                        enabledInspectRuleApprove={privates.enabledInspectRuleApprove}
                        value={privates.storages.isTest}
                        onChange={actions.changeVersion}
                    />
                );
            },
            goEditPage (item) {
                const {privates} = this;
                return `/site/tasks/approve/${privates.model.taskId}/template/check-point/${props.match.params.approveId}/${props.match.params.approveStatus}/${item.categoryId}/view/${item.id}`;
            },
            getCategoriesAjaxParams () {
                const { privates, props } = this;
                return [
                    Udesk.business.apiPath.concatApiPath(
                        `/inspectionRule/approves/inspectionPointCategories/${props.match.params.approveId}`, 
                        props.sdkOptions
                    ),
                    {
                        configVersion: privates.storages.isTest ? 1 : 2,
                    },
                ];
            },
        });
    }
}

export default Udesk.react.udeskify(ApproveTasksCheckPointIndex);
