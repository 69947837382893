import { putReviewIntelligentPartnerNodeById } from 'src/api/review/intelligentPartner/node/{id}';
import UdeskLocales from 'UdeskLocales';

const fields = () => [
    {
        label: /* 旁白话术 */ UdeskLocales['current'].pages.coach.courseManagement.flow.components
            .const.nodeConfigs.startNode.paragon,
        type: 'TextArea',
        name: 'words',
        props: {
            placeholder: UdeskLocales['current'].labels.pleaseEnter,
        },
    },
];

const request = ({ node }) => {
    return Promise.resolve({
        words: node.data.words,
    });
};

const finish = ({ formInstanceCache, configId, nodeId }) => {
    return (values) => {
        const params = {
            ...values,
            nodeId,
        };
        return putReviewIntelligentPartnerNodeById(params, {
            segments: {
                id: nodeId,
            },
            successMsg:
                /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow.components
                    .const.nodeConfigs.startNode.successfullySaved,
        });
    };
};

export default {
    fields,
    request,
    finish,
};
