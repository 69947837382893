import React, { FC, memo, useMemo } from 'react';
import { Select } from 'udesk-ui';
import CustomFilters from 'udesk-react/src/components/custom-filters';
import styled from 'styled-components';
import config from 'src/common/config';
import { Field, FieldForComponent, Condition } from './types';
import Udesk from 'Udesk';

const Wrapper = styled.div`
    .filter-wrapper {
        display: flex;
        align-items: center;
    }

    .operator-selector {
        height: unset !important;
    }

    .field-selector {
        height: unset !important;
    }
`;

export type TemplateProps = {
    fields?: Field[];
    value?: Condition;
    onChange?: (v: TemplateProps['value']) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value: condition, onChange, fields: _fields } = props;

    const fields: FieldForComponent[] = useMemo(() => {
        return Udesk.business.fields.buildConditionFields({
            fields: _fields || [],
        });
    }, [_fields]);

    const fieldValueSettings = useMemo(() => {
        return config.getFilterComponentSettings({});
    }, []);

    console.log('fields', fields);
    console.log('fieldValueSettings', fieldValueSettings);

    return (
        <Wrapper>
            {fields?.length ? (
                <CustomFilters
                    showConditionNumber={false}
                    showRemovalButton={false}
                    showAddButton={false}
                    allowEmptyConditions={true}
                    fields={fields}
                    fieldValueSettings={fieldValueSettings}
                    conditions={[condition]}
                    onChanged={(conditions: Condition[]) => {
                        onChange?.(conditions?.[0]);
                    }}
                    theme="bootstrap"
                    antdStyle={true}
                    antdSelectComponent={Select}
                    // fieldSelectorClass="form-control input-sm"
                    // operatorSelectorClass="form-control input-sm"
                    // languageCode={props.sdkOptions.props.language}
                    fieldNameField="key"
                    fieldKeyField="key"
                    fieldTextField="label"
                />
            ) : null}
        </Wrapper>
    );
};

export default memo(Template);
