import React from 'react';
import Udesk from 'Udesk';
// import ReactTinymce from 'Component/common/udesk-qa-tinymce';
import MassagePushConfigurationComponent from './component';
import './style.scss';
import { Collapse, Checkbox } from 'udesk-ui';
import InterfacePush from './interface-push';
import OtherPush from './other-push';

// 引入编辑器样式
import 'braft-editor/dist/index.css';
const { Panel } = Collapse;
export default class MassagePushConfigurationTemplate extends MassagePushConfigurationComponent {
    render() {
        let { privates, props, actions } = this;
        return (
            <div className='massage-push-configuration'>
                <Collapse
                    activeKey={privates.pushConf
                        .filter((pushConf) => pushConf.enabled)
                        .map((pushConf) => pushConf.actionType)}
                    // onChange={actions.onCollapseChange}
                >
                    {privates.pushConf.map((pushConf) => {
                        const headerWithCheckbox = (
                            <span
                                onClick={(e) => {
                                    e && e.stopPropagation && e.stopPropagation();
                                }}
                            >
                                <Checkbox
                                    checked={props.checkedTypes.includes(pushConf.actionType)}
                                    onChange={actions.onCheckedTypesChange.params(pushConf.actionType)}
                                >
                                    {Udesk.enums.messagePushType.getName(pushConf.actionType)}
                                </Checkbox>
                            </span>
                        );
                        return (
                            <Panel header={headerWithCheckbox} key={pushConf.actionType} showArrow={false}>
                                <OtherPush
                                    pushConf={pushConf}
                                    onPushConfItemChange={actions.onPushConfItemChange}
                                    taskType={props.taskType}
                                    taskTypeList={props.taskTypeList}
                                    taskId={props.taskId}
                                    users={props.users}
                                    roles={props.roles}
                                    associateDataClickInRich={actions.associateDataClickInRich}
                                />
                                {/* 接口推送 */}
                                <If condition={[Udesk.enums.messagePushType.interfacePush.id, Udesk.enums.messagePushType.udesk.id].includes(parseInt(pushConf.actionType, 10))}>
                                    <InterfacePush
                                        pushConf={pushConf}
                                        onPushConfItemChange={actions.onPushConfItemChange}
                                        taskType={props.taskType}
                                        taskTypeList={props.taskTypeList}
                                        taskId={props.taskId}
                                    />
                                </If>
                            </Panel>
                        );
                    })}
                </Collapse>
            </div>
        );
    }
}
