// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetReplayCommentsByIdParam,
    BaseResponseReplayCommentFoundResponse,
    PutReplayCommentsByIdParam,
    PutReplayCommentsByIdBody,
    BaseResponseVoid,
    DeleteReplayCommentsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 会话评论
 * @link [GET] /replayComments/{id}
 * @param id
 */
export function getReplayCommentsById(
    options: ApiOptions<GetReplayCommentsByIdParam, never> & {
        segments: GetReplayCommentsByIdParam;
    }
): Promise<BaseResponseReplayCommentFoundResponse> {
    return request<never, GetReplayCommentsByIdParam, never>('/replayComments/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReplayCommentsById',
    });
}

/**
 * update
 *
 * @export
 * @tags 会话评论
 * @link [PUT] /replayComments/{id}
 * @param id
 */
export function putReplayCommentsById(
    data: PutReplayCommentsByIdBody,
    options: ApiOptions<PutReplayCommentsByIdParam, never> & {
        segments: PutReplayCommentsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutReplayCommentsByIdBody, PutReplayCommentsByIdParam, never>(
        '/replayComments/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putReplayCommentsById' }
    );
}

/**
 * 删除评论
 *
 * @export
 * @tags 会话评论
 * @link [DELETE] /replayComments/{id}
 * @param id
 */
export function deleteReplayCommentsById(
    options: ApiOptions<DeleteReplayCommentsByIdParam, never> & {
        segments: DeleteReplayCommentsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReplayCommentsByIdParam, never>('/replayComments/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteReplayCommentsById',
    });
}

export const meta = [
    { tags: ['会话评论'], path: '/replayComments/{id}', method: 'get' },
    { tags: ['会话评论'], path: '/replayComments/{id}', method: 'put' },
    { tags: ['会话评论'], path: '/replayComments/{id}', method: 'delete' },
];
