import Udesk from 'Udesk';
import DataPreprocessComponent from './component';

class DataPreprocessRoute extends DataPreprocessComponent {
    asyncModel(changedParams) {
        let {
            sdkOptions,
        } = this.props;
        let {
            pageSize,
            pageNum
        } = this.state;
        let {
            keyWords,
            dataSource,
            status,
        } = this.privates;

        let params = {
            pageNum,
            pageSize,
            keyWords,
            dataSource,
            ruleStatus: status,
        };
        if (changedParams != null) {
            Object.assign(params, changedParams);
        }

        let url = Udesk.business.apiPath.concatApiPath(`preprocessRules/findAll`, sdkOptions);
        let dataPreprocessPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        return ({
            dataPreprocess: dataPreprocessPromise,
        });
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        if (asyncKey === "dataPreprocess") {
            if (asyncModel.dataPreprocess) {
                if (asyncModel.dataPreprocess.data) {
                    this.privates.dataPreprocess = asyncModel.dataPreprocess.data.map(i => ({...i, key: i.id}));
                    this.actions.update();
                }
                if (asyncModel.dataPreprocess.paging) {
                    this.setState({
                        pageNum: asyncModel.dataPreprocess.paging.pageNum,
                        pageSize: asyncModel.dataPreprocess.paging.pageSize,
                        total: asyncModel.dataPreprocess.paging.total
                    });
                }
            }
        }
    }
}

export default DataPreprocessRoute;
