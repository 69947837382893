import React, { useState } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { StandardizeTable } from 'udesk_gm_ui';
import { FormBuilder, Modal } from 'udesk-ui';
import { getCallWorkTimes, postCallWorkTimes } from 'src/api/callWorkTimes';
import { BusinessWorkingHoursSetting } from './components/BusinessWorkingHoursSetting';
import {
    deleteCallWorkTimesById,
    getCallWorkTimesById,
    putCallWorkTimesById,
} from 'src/api/callWorkTimes/{id}';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

const DEFAULT_VALUES = {
    name: '',
    description: '',
    workTimeList: Udesk.enums.weekDays.map((day) => {
        return {
            workDay: parseInt(day.id, 10),
            workDayTimeList: [
                {
                    time: ['09:00', '12:00'],
                },
            ],
        };
    }),
};

const Template = React.memo((props: any) => {
    const [form] = FormBuilder.useForm();
    const [visible, setVisible] = useState(false);
    const [currentDetail, setCurrentDetail] = useState<any>(null);
    const [data, setData] = useState<any[]>([]);

    const requestList = ({ pageNum, pageSize }) => {
        getCallWorkTimes({
            params: {
                pageNum,
                pageSize,
            },
        }).then((resp) => {
            setData(resp.data ?? []);
        });
    };

    const onCancel = () => {
        setVisible(false);
        setCurrentDetail(null);
    };

    return (
        <Page
            pageBodyClassName="business-working-hours-page-index"
            title={
                /* 业务工作时间 */ UdeskLocales['current'].pages.businessWorkingHours.index
                    .businessWorkingHours
            }
            padding={false}
        >
            <StandardizeTable.RequestTable
                onRequest={(params) => {
                    requestList(params.pagination);
                }}
                toolBarRightButtons={[
                    {
                        label: /* 新建 */ UdeskLocales['current'].pages.businessWorkingHours.index
                            .newlyBuild,
                        onClick() {
                            setVisible(true);
                            form.setFieldsValue(DEFAULT_VALUES);
                        },
                        mainBtn: true,
                    },
                ]}
                table={{
                    dataSource: data,
                    columns: [
                        {
                            title: /* 名称 */ UdeskLocales['current'].pages.businessWorkingHours
                                .index.name,
                            key: 'name',
                            dataIndex: 'name',
                        },
                        {
                            title: /* 描述 */ UdeskLocales['current'].pages.businessWorkingHours
                                .index.describe,
                            key: 'description',
                            dataIndex: 'description',
                        },
                        {
                            title: /* 操作 */ UdeskLocales['current'].pages.businessWorkingHours
                                .index.operation,
                            key: 'action',
                            dataIndex: 'action',
                            renderType: 'actions',
                            buttons: [
                                {
                                    label: /* 编辑 */ UdeskLocales['current'].pages
                                        .businessWorkingHours.index.edit,
                                    onClick(item) {
                                        getCallWorkTimesById({
                                            segments: {
                                                id: item.id,
                                            },
                                        }).then((resp) => {
                                            setVisible(true);
                                            const detail = resp.data || null;
                                            detail?.workTimeList?.forEach((time) => {
                                                time?.workDayTimeList?.forEach((day: any) => {
                                                    day.time = [
                                                        day.startHourMinute,
                                                        day.endHourMinute,
                                                    ];
                                                });
                                            });
                                            setCurrentDetail(detail);

                                            if (detail) {
                                                form.setFieldsValue(detail);
                                            }
                                        });
                                    },
                                },
                                {
                                    label: /* 删除 */ UdeskLocales['current'].pages
                                        .businessWorkingHours.index.delete,
                                    danger: true,
                                    onClick(item) {
                                        return deleteCallWorkTimesById({
                                            segments: {
                                                id: item.id,
                                            },
                                        });
                                    },
                                },
                            ],
                        },
                    ],
                }}
            />
            <Modal
                title={
                    currentDetail
                        ? /* 编辑业务工作时间 */ UdeskLocales['current'].pages.businessWorkingHours
                              .index.editBusinessWorkingHours
                        : /* 新建业务工作时间 */ UdeskLocales['current'].pages.businessWorkingHours
                              .index.newBusinessWorkingHours
                }
                visible={visible}
                onCancel={onCancel}
                onOk={() => {
                    form.validateFields().then((values) => {
                        const request = (params) => {
                            const id = currentDetail?.id;
                            if (id) {
                                return putCallWorkTimesById(params, {
                                    segments: {
                                        id,
                                    },
                                });
                            }

                            return postCallWorkTimes(params);
                        };
                        const params = {
                            ...values,
                        };
                        params.workTimeList.forEach((time) => {
                            time.workDayTimeList.forEach((day) => {
                                day.startHourMinute = day.time[0];
                                day.endHourMinute = day.time[1];
                            });
                        });

                        request(params).then(() => {
                            onCancel();
                            requestList({ pageNum: 1, pageSize: 20 });
                        });
                    });
                }}
                width={800}
            >
                <FormBuilder
                    footerRender={false}
                    form={form}
                    customComponents={{ BusinessWorkingHoursSetting }}
                    initialValues={DEFAULT_VALUES}
                    fields={[
                        {
                            type: 'Input',
                            name: 'name',
                            label: /* 名称 */ UdeskLocales['current'].pages.businessWorkingHours
                                .index.name,
                            rules: [{ required: true }],
                            props: {
                                style: {
                                    width: 200,
                                },
                                placeholder: UdeskLocales['current'].labels.pleaseEnter,
                            },
                        },
                        {
                            type: 'TextArea',
                            name: 'description',
                            label: /* 描述 */ UdeskLocales['current'].pages.businessWorkingHours
                                .index.describe,
                            props: {
                                style: {
                                    width: 400,
                                },
                                placeholder: UdeskLocales['current'].labels.pleaseEnter,
                            },
                        },
                        {
                            type: 'BusinessWorkingHoursSetting',
                            label: /* 工作时间 */ UdeskLocales['current'].pages.businessWorkingHours
                                .index.workingHours,
                        },
                    ]}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                />
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
