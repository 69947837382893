// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostCallTestsCallByCallIdParam,
    PostCallTestsCallByCallIdBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 保存测试对话，从dataCall获取相关信息
 *
 * @export
 * @tags 质检规则测试
 * @link [POST] /callTests/call/{callId}
 * @param callId
 */
export function postCallTestsCallByCallId(
    data: PostCallTestsCallByCallIdBody,
    options: ApiOptions<PostCallTestsCallByCallIdParam, never> & {
        segments: PostCallTestsCallByCallIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PostCallTestsCallByCallIdBody, PostCallTestsCallByCallIdParam, never>(
        '/callTests/call/{callId}',
        { ...options, data, method: 'post', __$requestCalleeName: 'postCallTestsCallByCallId' }
    );
}

export const meta = [{ tags: ['质检规则测试'], path: '/callTests/call/{callId}', method: 'post' }];
