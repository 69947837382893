import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import { useFlowModalProvider } from './Context';

const nodeWidth = 200;
const nodeHeight = 44;

const NodeCompWrap = styled.div<any>`
    /* background: white; */
    /* border: 2px solid #000; */
    border-radius: 3px;
    > .react-flow__handle {
        opacity: 0;
        width: 1px !important;
        height: 1px !important;
        min-width: 1px !important;
        min-height: 1px !important;
    }
    > .react-flow__handle-top {
        top: 0;
    }
    > .react-flow__handle-bottom {
        bottom: 0;
    }

    .edge-connector-wrap {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        overflow: hidden;
    }
    .edge-connector {
        position: absolute;
        top: 0;
        bottom: auto !important;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: none !important;
        border: 4px solid transparent;
        border-radius: 0 !important;
        transform: none;
        cursor: inherit !important;
        border-radius: 3px;
        &:hover {
            border-image: radial-gradient(
                    circle,
                    rgba(63, 251, 217, 0.6306897759103641) 0%,
                    rgba(70, 181, 252, 1) 100%
                )
                1 !important;
            cursor: crosshair !important;
        }
    }
    .edge-connector-inner {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        .edge-connector-inner-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-weight: bold;
            font-size: 12px;
            font-family: monospace;
            text-align: center;
        }
    }
    .node-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
    }
`;
const ResizableNode = memo<any>((props) => {
    // const { connecting } = useFlowContext();
    const { id, data, selected } = props;
    const { indexNodeMap, showNodeIndexMap } = useFlowModalProvider();

    const getHandleType = (isTarget) => {
        return isTarget ? 'target' : 'source';
    };
    return (
        // <>
        //     {data.type !== 'START' && <Handle type="target" position={Position.Top} />}
        //     <NodeWrap selected={selected} className={data.type}>
        //         <div className="node-title" style={{ padding: 10 }}>
        //             {data.name}
        //         </div>
        //     </NodeWrap>
        //     <Handle type="source" position={Position.Bottom} />
        // </>
        <NodeCompWrap
            style={{
                background: indexNodeMap[id].isHit ? data.color : '#d9d9d9',
                width: nodeWidth,
                height: nodeHeight,
                cursor: showNodeIndexMap[id] ? 'pointer' : 'no-drop',
            }}
            selected={selected}
            className={data.type}
            preNodeType={data.preNodeType}
        >
            {Array.isArray(data.handle) &&
                data.handle.map((h) => {
                    const isHandleTarget = h.target === id;
                    return (
                        <Handle
                            type={getHandleType(isHandleTarget)}
                            position={isHandleTarget ? Position.Top : Position.Bottom}
                        />
                    );
                })}
            <Handle type="target" position={Position.Top} />
            <div className="edge-connector-wrap">
                <div className="drag-me edge-connector-inner">
                    <div className="edge-connector-inner-content">
                        <div className="node-title">{data.name}</div>
                    </div>
                </div>
            </div>
            <Handle type="source" position={Position.Bottom} />
        </NodeCompWrap>
    );
});

export const nodeTypes = {
    cusNode: ResizableNode,
};
