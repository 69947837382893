import LocalesManagerClass from './locales-manager';
import Chinese from './zh-cn';
import English from './en-us';
import Japanese from './ja';
import fr from './fr';
import es from './es';
import ar from './ar';
import pt from './pt';
import id from './id';
import ru from './ru';
import th from './th';
import it from './it';
import de from './de';
import Incremental from "./incremental";

const DEFAULT_LANGUAGE_CODE = "ZH-CN";

const localesStore = {
    "ZH-CN": Chinese,
    "EN-US": English,
    "JA": Japanese,
    "FR": fr,
    "ES": es,
    'AR': ar,
    'PT': pt,
    'ID': id,
    'RU': ru,
    'TH': th,
    'IT': it,
    'DE': de,
    "[INCREMENTAL]": Incremental
};

export default new LocalesManagerClass(localesStore, DEFAULT_LANGUAGE_CODE);