// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetSupervisionRuleDetailsQuery,
    BaseResponseListInspectionPointFoundResponse,
    PostSupervisionRuleDetailsBody,
    BaseResponseInspectionPointFoundResponse,
    DeleteSupervisionRuleDetailsQuery,
    BaseResponseVoid,
} from '../types';

/**
 * selectByName
 *
 * @export
 * @tags 监督项规则设置API
 * @link [GET] /supervisionRuleDetails
 */
export function getSupervisionRuleDetails(
    options: ApiOptions<never, GetSupervisionRuleDetailsQuery> & {
        params: GetSupervisionRuleDetailsQuery;
    }
): Promise<BaseResponseListInspectionPointFoundResponse> {
    return request<never, never, GetSupervisionRuleDetailsQuery>('/supervisionRuleDetails', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSupervisionRuleDetails',
    });
}

/**
 * 保存/更新规则
 *
 * @export
 * @tags 监督项规则设置API
 * @link [POST] /supervisionRuleDetails
 */
export function postSupervisionRuleDetails(
    data: PostSupervisionRuleDetailsBody
): Promise<BaseResponseInspectionPointFoundResponse>;
export function postSupervisionRuleDetails(
    data: PostSupervisionRuleDetailsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseInspectionPointFoundResponse>;
export function postSupervisionRuleDetails(
    data: PostSupervisionRuleDetailsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseInspectionPointFoundResponse> {
    return request<PostSupervisionRuleDetailsBody, never, never>('/supervisionRuleDetails', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSupervisionRuleDetails',
    });
}

/**
 * 删除规则
 *
 * @export
 * @tags 监督项规则设置API
 * @link [DELETE] /supervisionRuleDetails
 */
export function deleteSupervisionRuleDetails(
    options: ApiOptions<never, DeleteSupervisionRuleDetailsQuery> & {
        params: DeleteSupervisionRuleDetailsQuery;
    }
): Promise<BaseResponseVoid> {
    return request<never, never, DeleteSupervisionRuleDetailsQuery>('/supervisionRuleDetails', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteSupervisionRuleDetails',
    });
}

export const meta = [
    { tags: ['监督项规则设置API'], path: '/supervisionRuleDetails', method: 'get' },
    { tags: ['监督项规则设置API'], path: '/supervisionRuleDetails', method: 'post' },
    { tags: ['监督项规则设置API'], path: '/supervisionRuleDetails', method: 'delete' },
];
