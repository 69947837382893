import Udesk from 'Udesk';
import React from 'react';
import PropTypes from 'prop-types';
import config from '../../../../common/config/index';

export default class TaskRuleGatherComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        rules: [],
        fields: [],
        users: [],
        onChanged: null,
        disabled: false,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        rules: PropTypes.array,
        fields: PropTypes.array,
        users: PropTypes.array,
        onChanged: PropTypes.func,
        disabled: PropTypes.bool,
    };
    //#endregion

    //#region state
    state = {
        baseFilterVisible: false,
        conditionList: [],
        // currentRule: null,
        judgeStrategy: Udesk.enums.judgeStrategy.all.id,
        customJudgeLogic: '',
        currentRule: {
            baseFilter: {
                conditionList: []
            },
            customJudgeLogic: '',
            judgeStrategy: 1,
        },
    };
    //#endregion

    static computes = {
        _fields: ["props.fields", function ({ props, privates, locales }) {
            if (props.fields) {
                return Udesk.business.fields.buildConditionFields({
                    fields: props.fields
                });
            } else {
                return [];
            }
        }],
        _fieldValueSettings: ["props.fields", function ({ props, privates, locales }) {
            return config.getFilterComponentSettings();
        }]
    };

    privates = {
        autoCompleteUrl: '',
        rules: [],
        rulesIndex: 0,
        conditionJsonInfo: [],
        conditionJson: []
    };

    //#region actions
    actions = {
        onValueChanged(obj, key, e) {
            let value = e;
            if (e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.rulesChanged();
        },

        onStateChanged(key, e) {
            let value = e;
            if (e.target) {
                value = e.target.value;
            }
            this.setState({
                [key]: value
            });
        },

        inspectorListChanged(rule, inspectorList) {
            rule.assignConf.inspectorList = inspectorList;
            let inspectors = '';
            if (inspectorList && inspectorList.length > 0) {
                inspectorList = inspectorList.map(inspector => {
                    return inspector.id;
                });
                inspectors = inspectorList.join(',');
            }
            rule.assignConf.inspectors = inspectors;
            this.actions.rulesChanged();
        },

        deleteRule(index) {
            let rules = this.privates.rules;
            rules.splice(index, 1);
            this.actions.rulesChanged();
        },

        addRule() {
            let rules = this.privates.rules;
            let newRule = {
                _id: this.privates.rulesIndex,
                assignConf: initAssignConf(),
                baseFilter: initBaseFilter()
            };
            rules.push(newRule);
            this.privates.rulesIndex += 1;
            this.actions.rulesChanged();
        },

        moveUp(rule, index) {
            if (index === 0) {
                return;
            }

            let rules = this.privates.rules;
            rules.splice(index, 1);
            rules.splice(index - 1, 0, rule);
            this.actions.rulesChanged();
        },

        moveDown(rule, index) {
            let rules = this.privates.rules;
            if (index === rules.length - 1) {
                return;
            }

            rules.splice(index, 1);
            rules.splice(index + 1, 0, rule);
            this.actions.rulesChanged();
        },

        moveTop(rule, index) {
            if (index === 0) {
                return;
            }

            let rules = this.privates.rules;
            rules.splice(index, 1);
            rules.unshift(rule);
            this.actions.rulesChanged();
        },

        rulesChanged() {
            this.actions.update();
            let rules = this.privates.rules;
            this.trigger('onChanged', rules);
        },

        //显示或隐藏弹出框，将该筛选条件重新复制给一个新的数组
        changeBaseFilterVisible(condition) {
            if(condition && condition.baseFilter && !condition.baseFilter.conditionList){
                condition.baseFilter.conditionList = [];
            }
            if(condition && condition.baseFilter && !condition.baseFilter.judgeStrategy){
                condition.baseFilter.judgeStrategy = 1;
            }
            let baseFilterVisible = this.state.baseFilterVisible;

            if (!baseFilterVisible) {
                this.setState({
                    currentRule: condition
                });
                if (condition && condition.baseFilter && condition.baseFilter.conditionList) {
                    this.setState({
                        conditionList: [].concat(condition.baseFilter.conditionList)
                    });
                }
                if (condition && condition.baseFilter && condition.baseFilter.judgeStrategy != null) {
                    this.setState({
                        judgeStrategy: condition.baseFilter.judgeStrategy
                    });
                }
                if (condition && condition.baseFilter && condition.baseFilter.customJudgeLogic != null) {
                    this.setState({
                        customJudgeLogic: condition.baseFilter.customJudgeLogic
                    });
                }
            }

            this.setState({
                baseFilterVisible: !baseFilterVisible
            });
        },

        //监听新数组的变化
        conditionListChanged(val) {
            this.setState({
                conditionList: [].concat(val)
            });
        },

        //取消变更，不复制给筛选条件
        cancelConditionListChanged() {
            this.actions.changeBaseFilterVisible();
        },

        //确认变更，新数组赋值给筛选条件
        confirmConditionListChanged() {
            let rule = this.state.currentRule;
            let conditionList = this.state.conditionList;
            let judgeStrategy = this.state.judgeStrategy;
            let customJudgeLogic = this.state.customJudgeLogic;

            if (rule) {
                rule.baseFilter.conditionList = conditionList;
                rule.baseFilter.judgeStrategy = judgeStrategy;
                rule.baseFilter.customJudgeLogic = customJudgeLogic;
            }

            this.actions.changeBaseFilterVisible();
            this.actions.rulesChanged();
        },

        //高级筛选器
        handleConditionCancel() {
            this.setState({
                baseFilterVisible: false
            });
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy) {
            // let { editKey } = this.privates;
            let condition = this.state.currentRule;
            condition.baseFilter.conditionList = conditionList;
            condition.baseFilter.judgeStrategy = judgeStrategy;
            if (judgeStrategy !== 3) {
                condition.baseFilter.customJudgeLogic = '';
            } else {
                condition.baseFilter.customJudgeLogic = customJudgeLogic;
            }
            this.privates.customId = null;
            this.privates.customName = null;
            // this.setState({currentRule: condition});
            this.actions.changeBaseFilterVisible(condition);
            this.actions.rulesChanged();
        },        
        editFilterOhter(condition) {
            this.setState({
                baseFilterVisible: true,
                currentRule: condition
            });
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.forEach(item => {
                if (item.value) {
                    if (
                        item.field &&
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = "";
                        item.value.forEach(items => {
                            if (items.agentName) {
                                str += items.agentName + ",";
                            }
                        });
                        list = [
                            ...list,
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            str.substring(0, str.length - 1)
                        ];
                    } else if (typeof item.value === "object") {
                        let str =
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === "string") {
                        let str =
                            item.field.label + " " + obj[item.operator] + " " + item.value;
                        list = [...list, str];
                    } else {
                        let str =
                            item.field.label + " " + obj[item.operator] + " " + item.value;
                        list = [...list, str];
                    }
                } else if(item.field) {
                    let str = item.field.label + " " + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <div
                        style={{
                            color: 'rgba(0, 0, 0, 0.65)',
                            marginBottom:
                                index + 1 === list.length
                                    ? '0px'
                                    : '8px',
                        }}
                    >
                        {index + 1}. {item}
                    </div>
                );
            });
        },
    };
    //#endregion

    //#region Life Cycle
    init() {
        const {privates, props} = this;
        privates.autoCompleteUrl = Udesk.business.apiPath.concatApiPath(`/users?pageSize=1000`, this.props.sdkOptions);
        if (props.rules && props.rules.length > 0) {
            privates.rules = props.rules.map((item, index) => {
                if (item.baseFilter == null) {
                    item.baseFilter = initBaseFilter();
                }
                if (item.assignConf == null) {
                    item.assignConf = initAssignConf();
                }
                return {
                    _id: index,
                    ...item
                };
            });
            privates.rulesIndex = props.rules.length;
        }
    }
    parseProps({ props, prevProps }) {
        let conditions = [];
        let conditionJsonInfo = [];
        let conditionJson = [];
        if (props.rules && props.rules.length > 0) {
            conditions = props.rules.map((item, index) => {
                if (item.baseFilter == null) {
                    item.baseFilter = initBaseFilter();
                }
                if (item.assignConf == null) {
                    item.assignConf = initAssignConf();
                }
                return {
                    _id: index,
                    ...item
                };
            });
            conditions.forEach(condition => {
                let res = condition.baseFilter && condition.baseFilter.conditionList && condition.baseFilter.conditionList.map(item => {
                    if (item.field && item.field.statusKey) {
                        return {
                            ...item,
                            field: {
                                ...item.field,
                                id: `${item.field.id}-${item.field.inspectionTaskId}`,
                            }
                        };
                    }
                    return item;   
                });
                conditionJsonInfo.push(res);
            });
            conditions.forEach(condition => {
                let res = condition.baseFilter && condition.baseFilter.conditionList && condition.baseFilter.conditionList.map(item => {
                    if (item.field && item.field.statusKey) {
                        return {
                            field: `${item.field.id}-${item.field.inspectionTaskId}`,
                            operator: item.operator,
                            value: item.value,
                            statusKey: `${item.field.id}-${item.field.inspectionTaskId}`
                        };
                    }
                    return {
                        field: item.field ? item.field.id : null,
                        operator: item.operator,
                        value: item.value
                    };
                });
                conditionJson.push(res);
            });
        }
        return {
            conditionJsonInfo,
            conditionJson,
        };
    }

    componentDidMount() {
    }
    //#endregion
}

function initAssignConf() {
    return {
        assignType: Udesk.enums.inspectorTypes.all.id,
        inspectorList: []
    };
}

function initBaseFilter() {
    return {
        conditionList: [],
        judgeStrategy: Udesk.enums.judgeStrategy.all.id,
        customJudgeLogic: ''
    };
}

function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter(item => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map(item => {
            return item.name;
        });
        return value.join(",");
    } else {
        return "";
    }
}
