import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Udesk from '../../udesk/index';
import Locales from '../../udesk/locales';

let startDragNodeTmp = null;

const DRAG_SIDE_RANGE = 0.25;
const DRAG_MIN_GAP = 2;
class ReactTreeNode extends React.Component {
    //#region defaultProps
    static defaultProps = {
        node: {},
        nameField: "name",
        isShowNodeNum: false,
        numField: "num",
        keyField: "id",

        activeClass: "active-node",
        clickable: false,
        checkable: false,
        showNodeDescriptorIcon: false,
        enableActions: false,
        expandedIconClass: "fa fa-caret-down",
        collapsedIconClass: "fa fa-caret-right",
        dragIconClass: "",
        addIconClass: "",
        editIconClass: "",
        deleteIconClass: "",
        attributeBindings: ['_isDragable:draggable'],
        draggable: false,
        parentComponentClassName: null,
        getDropPositionClass: null,
        getNodeDescriptorIcon: null,
        isNodeMovable: null,
        enableSort: true,
        enableMove: false,
        nodeCheckBoxComponent: null,
        nodeTextComponent: null,
        nodeActionsComponent: null,

        onExpanded: null,
        onCollapsed: null,
        onClicked: null,
        onChecked: null,
        onUnchecked: null,
        onNodeAction: null,

    }
    static propTypes = {
        node: PropTypes.object,
        nameField: PropTypes.string,
        isShowNodeNum: PropTypes.bool,
        numField: PropTypes.string,
        keyField: PropTypes.string,

        activeClass: PropTypes.string,
        clickable: PropTypes.bool,
        checkable: PropTypes.bool,
        showNodeDescriptorIcon: PropTypes.bool,
        enableActions: PropTypes.bool,
        expandedIconClass: PropTypes.string,
        collapsedIconClass: PropTypes.string,
        dragIconClass: PropTypes.string,
        addIconClass: PropTypes.string,
        editIconClass: PropTypes.string,
        deleteIconClass: PropTypes.string,
        attributeBindings: PropTypes.array,
        draggable: PropTypes.bool,
        parentComponentClassName: PropTypes.string,
        getDropPositionClass: PropTypes.func,
        getNodeDescriptorIcon: PropTypes.func,
        isNodeMovable: PropTypes.bool,
        enableSort: PropTypes.bool,
        enableMove: PropTypes.bool,
        nodeCheckBoxComponent: PropTypes.object,
        nodeTextComponent: PropTypes.object,
        nodeActionsComponent: PropTypes.object,

        onExpanded: PropTypes.func,
        onCollapsed: PropTypes.func,
        onClicked: PropTypes.func,
        onChecked: PropTypes.func,
        onUnchecked: PropTypes.func,
        onNodeAction: PropTypes.func,
    }
    //#endregion

    privates = {
        isActive: this.props.active,
        isChecked: this.props.checked,
        isClickable: this.props.clickable && this.props.node.states.clickable,
        isCheckable: this.props.checkable && this.props.node.states.checkable,
        isDragable: this.props.draggable && this.props.node.states.draggable,
        isDragOvering: this.props.draggable && this.props.node.states.dragOvering,
        reactTreeNodeElement: null
    }

    static computes = {
        _dropPositionClass: ["privates.isDragOvering", "props.node.states.dropPosition", function ({ props, privates }) {
            let {
                isDragOvering
            } = privates;
            let {
                node,
                getDropPositionClass
            } = props;

            if (isDragOvering && node && node.states) {
                let dropPosition = node.states.dropPosition;
                if (typeof getDropPositionClass === "function") {
                    return getDropPositionClass(node.data, dropPosition);
                } else {
                    if (dropPosition === -1) {
                        return "drop-top";
                    } else if (dropPosition === 1) {
                        return "drop-bottom";
                    } else if (dropPosition === 0) {
                        return "drop-inner";
                    } else {
                        return "";
                    }
                }
            }
        }],
        _className: ["privates.isClickable", "privates.isCheckable", "privates.isActive", "privates.isChecked", "privates.isDragOvering", "privates.computes.dropPositionClass", function ({ props, privates }) {
            let computeClassName = ["tree-node"];
            if (privates.isClickable) {
                computeClassName.push("clickable-node");
            }
            if (privates.isCheckable) {
                computeClassName.push("checkable-node");
            }
            if (privates.isActive) {
                computeClassName.push("active-node");
            }
            if (privates.isChecked) {
                computeClassName.push("checked-node");
            }
            if (privates.isDragOvering) {
                computeClassName.push("dragOvering-node");
            }
            if (privates.computes.dropPositionClass) {
                computeClassName.push(privates.computes.dropPositionClass);
            }
            return computeClassName.join(" ");
        }]
    }

    parseProps({ props, prevProps, privates }) {
        this.privates.isActive = props.active;
        this.privates.isChecked = props.checked;
        this.privates.isClickable = props.clickable && props.node.states.clickable;
        this.privates.isCheckable = props.checkable && props.node.states.checkable;
        this.privates.isDragable = props.draggable && props.node.states.draggable;
        this.privates.isDragOvering = props.draggable && props.node.states.dragOvering;
        this.privates.reactTreeNodeElement = null;
    }
    componentDidMount() {
        let reactTreeNodeJqueryObj = $(this.privates.reactTreeNodeElement);
        if (reactTreeNodeJqueryObj.length) {
            let that = this;
            reactTreeNodeJqueryObj.on("dragstart", function (event) {
                dragStartEvent.bind(that, event);
            }).on("drag", function (event) {
                dragEvent.bind(that, event);
            }).on("dragend", function (event) {
                dragEndEvent.bind(that, event);
            }).on("dragover", function (event) {
                dragOverEvent.bind(that, event);
            }).on("dragenter", function (event) {
                dragEnterEvent.bind(that, event);
            }).on("dragleave", function (event) {
                dragLeaveEvent.bind(that, event);
            }).on("dragover", function (event) {
                dragEnterEvent.bind(that, event);
            }).on("drop", function (event) {
                dropEvent.bind(that, event);
            });
        }
    }
    componentWillUnmount() {
        let reactTreeNodeJqueryObj = $(this.privates.reactTreeNodeElement);
        if (reactTreeNodeJqueryObj.length) {
            let that = this;
            reactTreeNodeJqueryObj.off();
        }
    }

    actions = {
        expand: function (node) {
            this.trigger("onExpanded", node);
        },
        collapse: function (node) {
            this.trigger("onCollapsed", node);
        },
        click(node) {
            let {
                privates
            } = this;
            let {
                isClickable,
                isCheckable
            } = privates;
            if (isClickable) {
                this.trigger("onClicked", node);
            } else if (isCheckable) {
                if (!node.states.disabled) {
                    fireCheckedEvents.call(this, !node.states.checked, node);
                }
            } else {
                if (node.states.expanded) {
                    this.actions.collapse(node);
                } else {
                    this.actions.expand(node);
                }
            }
        },
        nativeCheckStatusChanged: function (node, event) {
            this.actions.checkStatusChanged(node, !node.states.checked);
        },
        checkStatusChanged(node, checked) {
            fireCheckedEvents.call(this, checked, node);
        },
        onNodeAction(command, node, args) {
            this.trigger("onNodeAction", command, node, args);
        },
    }
}

function fireCheckedEvents(checked, node) {
    if (checked) {
        this.trigger("onChecked", node);
    } else {
        this.trigger("onUnchecked", node);
    }
}

function calcDropPosition(event, element) {
    const { clientY } = event;
    const { top, bottom, height } = element.getBoundingClientRect();
    const des = 5 || Math.max(height * DRAG_SIDE_RANGE, DRAG_MIN_GAP);

    if (clientY <= top + des) {
        return -1;
    } else if (clientY >= bottom - des) {
        return 1;
    }

    return 0;
}

function addDragingClass(targetElement, className) {
    $(targetElement).addClass(className);
}

function removeDragingClass(targetElement, className) {
    $(targetElement).removeClass(className);
}


function dragStartEvent(event) {
    let {
        props,
        privates
    } = this;
    let {
        isDragable
    } = privates;
    if (isDragable) {
        event.stopPropagation();

        let {
            parentComponentClassName,
            node,
            keyField
        } = props;
        let targetElement = event.target;
        addDragingClass(targetElement, parentComponentClassName);

        let id = Udesk.utils.object.get(node.data, keyField);
        event.dataTransfer.setData("startDragNodeId", id);
        startDragNodeTmp = node;
        this.trigger("onDragStart", event, node);
    }
}

function dragEvent(event) {
    event.stopPropagation();
    let {
        props,
        privates
    } = this;
    let {
        isDragable
    } = privates;
    let {
        node
    } = props;
    if (isDragable) {
        this.trigger("onDrag", event, node);
    }
}

function dragEndEvent(event) {
    event.stopPropagation();
    let targetElement = event.target;
    let {
        props,
        privates
    } = this;
    let {
        parentComponentClassName
    } = props;

    removeDragingClass(targetElement, parentComponentClassName);
}

function dragOverEvent(event) {
    let {
        props,
        privates
    } = this;
    let {
        isDragable,
        reactTreeNodeElement
    } = privates;
    let {
        node
    } = props;
    if (isDragable) {
        let dropPosition = calcDropPosition(event, reactTreeNodeElement);
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = 'move';

        this.trigger("onDragOvered", event, node, dropPosition);
    }
}

function dragEnterEvent(event) {
    let {
        props,
        privates
    } = this;
    let {
        isDragable,
    } = privates;
    let {
        node
    } = props;
    if (isDragable) {
        event.preventDefault();
        event.stopPropagation();
        this.trigger("onDragEnter", event, node);
    }
}

function dragLeaveEvent(event) {
    let {
        node
    } = this.props;
    this.trigger("onDragLeaved", event, node);
}

function dropEvent(event) {
    let {
        props,
        privates
    } = this;
    let {
        isDragable,
    } = privates;
    let {
        node
    } = props;
    if (isDragable) {
        event.stopPropagation();
        startDragNodeTmp = null;

        this.trigger("onDrop", event, node);
        return false;
    }
}
export default ReactTreeNode;