import React from "react";
import Udesk from 'Udesk';
import _isEmpty from "lodash-es/isEmpty";
import _find from "lodash-es/find";
import _concat from "lodash-es/concat";
import _size from "lodash-es/size";
import _compact from "lodash-es/compact";
import _filter from "lodash-es/filter";
import Echarts from "echarts";
import config from "../../../../../common/config/index";
import html2canvas from "html2canvas";
import { postBusinessAnalysisConditionConfigs } from "src/api/businessAnalysisConditionConfigs";
import { putBusinessAnalysisConditionConfigsById } from "src/api/businessAnalysisConditionConfigs/{id}";
// import _cloneDeep from 'lodash-es/cloneDeep';
import Locales from 'UdeskLocales';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { getSystemModule } from 'Udesk/system/subApp';

class BusinessAnalysisComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};

    privates = {
        fieldsData: [],
        businessList: [
            {
                type: "0",
                text: Locales['current'].fix.advancedConditions
            },
            {
                type: "1",
                text: Locales['current'].fix.keyWord
            },
            {
                type: "2",
                text: Locales['current'].fix.smartTags
            }
        ],
        fieldsList: [
            {
                id: "0",
                list: []
            },
            {
                id: "2",
                list: [
                    {
                        type: "is",
                        name: Locales['current'].enums.fieldOperators.is
                    },
                    {
                        type: "contains_all",
                        name: Locales['current'].fix.containsAll
                    },
                    {
                        type: "is_any",
                        name: Locales['current'].fix.containsAny
                    }
                ]
            },
            {
                id: "1",
                list: [
                    {
                        type: "contains_any",
                        name: Locales['current'].fix.matchAny
                    },
                    {
                        type: "contains_all",
                        name: Locales['current'].fix.matchAll
                    }
                ]
            }
        ],
        intelligenceList: [
            {
                value: "17",
                text: Locales['current'].fix.smartTagsOne
            },
            {
                value: "18",
                text: Locales['current'].fix.smartTagsTwo
            }
        ],
        colorList: [
            "#945FB9",
            "#5B8FF9",
            "#6DC9EC",
            "#FF9AC3",
            "#5AD8A6",
            "#FF9846"
        ],
        childrenList: [],
        xAxisItems: [],
        seriversItems: [],
        sanKeyItems: [],
        sanKeyItemsLink: [],
        graphData: [],
        graphLinks: [],
        treeNumList: [],
        format: Udesk.config.date.momentDateFormat,
        serverFormat: Udesk.config.date.momentDateTimeFormat,
        storages: {
            startTime: moment()
                .add(-30, "day")
                .format("YYYY-MM-DD 00:00:00"),
            endTime: moment().format("YYYY-MM-DD 23:59:59"),
            groupByType: "",
            dataSourceType: 0,
            data: [],
            num: 0, // 一级数据源新增时序号状态，只增不减
            colorChangeList: [],
            countList: 0
        },

        searchTemplate: [],
        findAllSamrtTagsItems: [],

        seriversItemsTitle: [],
        timeList: [
            {
                key: "1q",
                name: Locales['current'].fix.season
            },
            {
                key: "1M",
                name: Locales['current'].fix.month
            },
            {
                key: "1w",
                name: Locales['current'].fix.week
            },
            {
                key: "1d",
                name: Locales['current'].fix.day
            },
            {
                key: "1h",
                name: Locales['current'].fix.hour
            }
        ],
        customJudgeLogic: "",
        judgeStrategy: 1,
        customTtile: "",
        saveFlag: true,
        conditionList: [],
        name: '',
        formInstance: React.createRef(),
    };
    // enableStorage = true;
    // _storageKey = "";
    // storageStrategies = {
    //     storageWay: "sessionStorage",
    //     resetStrategies: {
    //         transitionToRoutes: [],
    //         notTransitionToRoutes: []
    //     }
    // };
    init() {
        // const company = Udesk.data.init.company;
        // if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
        //     this.privates.storages.dataSourceType = 0;
        // } else if (company.enabledCallInspect !== 0) {
        //     this.privates.storages.dataSourceType = 1;
        // } else if (company.enabledImInspect !== 0) {
        //     this.privates.storages.dataSourceType = 2;
        // }
        if (this.props.route.name === 'analysisBusinessAnalysisDetail' || this.props.route.name === 'analysisBusinessAnalysisCreate') {
            this.privates.storages.dataSourceType = 1;
        } else {
            this.privates.storages.dataSourceType = 0;
        }
        // this._storageKey = `business-analysis-filter-userId=${company.id}`;
    }
    static computes = {
        _fields: [
            "privates.fieldsData",
            function ({ props, privates, locales }) {
                let { fieldsData } = privates;
                return Udesk.business.fields.buildConditionFields({
                    fields: fieldsData
                });
            }
        ],
        _fieldValueSettings: [
            "privates.fieldsData",
            function ({ props, privates, locales }) {
                return config.getFilterComponentSettings();
            }
        ]
    };
    actions = {
        timeClick(value) {
            this.privates.storages.groupByType = value;
            this.actions.fieldSave();
        },
        dateChanged([startTime, endTime]) {
            this.privates.storages.startTime = startTime;
            this.privates.storages.endTime = endTime;
            if (moment(endTime).diff(moment(startTime), "day") > 366) {
                Udesk.ui.notify.error(Locales['current'].fix.notMoreThan366);
                this.privates.storages.startTime = moment()
                    .add(-30, "day")
                    .format("YYYY-MM-DD 00:00:00");
                this.privates.storages.endTime = moment().format("YYYY-MM-DD 23:59:59");
            }
            this.actions.update();
        },
        dataTypeMapChange(data, value) {
            if (!_isEmpty(data)) {
                data.forEach(item => {
                    if (item.type === "0") {
                        if (value === 0) {
                            item.value = "";
                            item.type = "";
                        } else {
                            item.value = "";
                        }
                    }
                    if (!_isEmpty(item.children))
                        this.actions.dataTypeMapChange(item.children, value);
                });
            }
        },
        dataSourceTypeChange(value) {
            let { data } = this.privates.storages;
            this.privates.storages.dataSourceType = value;
            this.actions.dataTypeMapChange(data, value);
            this.privates.storages.data = [].concat(data);
            this.actions.update();
            let { sdkOptions } = this.props;
            if (value === 1 || value === 2 || value === 5) {
                let searchTemplateUrl = Udesk.business.apiPath.concatApiPath(`search-template/advanced`, sdkOptions);
                let serachTemplateData = {
                    dataFieldSourceList: ['a', 'pr'],
                    fieldIdList: ['f_3', 'f_10'],
                    excludeFieldSourceList: [],
                    excludeFieldIdList: [],
                    type: this.privates.storages.dataSourceType,
                };
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(searchTemplateUrl, serachTemplateData).then(
                        resp => {
                            resolve(resp.data);
                            this.privates.searchTemplate = resp.data.filter((item) => item.status);
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
        datalistSaveMap(data) {
            if (!_isEmpty(data)) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].type === "") {
                        Udesk.ui.notify.error(Locales['current'].fix.propsCannotBeNull);
                        this.privates.saveFlag = false;
                        break;
                    } else if (data[i].type === "1" || data[i].type === "2") {
                        if (data[i].value === "" || data[i].operator === "") {
                            Udesk.ui.notify.error(Locales['current'].fix.propsCannotBeNull);
                            this.privates.saveFlag = false;
                            break;
                        }
                    } else if (data[i].type === "0") {
                        if (data[i].value === "") {
                            Udesk.ui.notify.error(Locales['current'].fix.propsCannotBeNull);
                            this.privates.saveFlag = false;
                            break;
                        }
                    }
                    !_isEmpty(data[i].children) &&
                        this.actions.datalistSaveMap(data[i].children);
                }
            }
        },
        dataValueMap(data) {
            if (!_isEmpty(data)) {
                data.forEach(item => {
                    if (item.children && item.children.length > 0) {
                        this.actions.dataValueMap(item.children);
                    }
                    if (item.type === '2') {
                        item.value = (item.value && item.value.map(items => {
                            return items.value;
                        })) || [];
                    }

                });
            }
        },
        fieldSave() {
            this.privates.saveFlag = true;
            this.actions.datalistSaveMap(this.privates.storages.data);
            let { saveFlag } = this.privates;
            let {
                startTime,
                endTime,
                groupByType,
                dataSourceType,
                data,
                colorChangeList
            } = this.privates.storages;
            let { sdkOptions } = this.props;
            // let dataList = _cloneDeep(data);
            // this.actions.dataValueMap(dataList);
            let datas = {
                conditions: formatConditions(data, colorChangeList),
                startTime,
                endTime,
                dataSourceType,
                groupByType
            };
            
            let searchUrl = Udesk.business.apiPath.concatApiPath(
                `businessAnalysis/search`,
                sdkOptions
            );
            if (saveFlag) {
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(searchUrl, datas).then(
                        resp => {
                            resolve(resp.data);
                            if (!_isEmpty(resp.data)) {
                                if (!this.privates.storages.groupByType) {
                                    if (moment(endTime).diff(moment(startTime), "day") > 180) {
                                        this.privates.storages.groupByType = "1M";
                                    } else if (
                                        moment(endTime).diff(moment(startTime), "day") > 60
                                    ) {
                                        this.privates.storages.groupByType = "1w";
                                    } else if (
                                        moment(endTime).diff(moment(startTime), "day") >= 7
                                    ) {
                                        this.privates.storages.groupByType = "1d";
                                    } else {
                                        this.privates.storages.groupByType = "1h";
                                    }
                                }

                                let seriversItems = [];
                                let xAxisItems = [];
                                
                                resp.data.analysisList.forEach((item, index) => {
                                    seriversItems = [
                                        ...seriversItems,
                                        {
                                            name: parseInt(item.num, 10) + "-" + item.key,
                                            type: "line",
                                            itemStyle: {
                                                normal: {
                                                    color: _find(
                                                        colorChangeList,
                                                        current => current.id === parseInt(item.num, 10)
                                                    )
                                                        ? _find(
                                                            colorChangeList,
                                                            current => current.id === parseInt(item.num, 10)
                                                        ).color?.[0]
                                                        : "#000", //折线点的颜色
                                                    lineStyle: {
                                                        color: _find(
                                                            colorChangeList,
                                                            current => current.id === parseInt(item.num, 10)
                                                        )
                                                            ? _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id === parseInt(item.num, 10)
                                                            ).color?.[0]
                                                            : "#000" //折线的颜色
                                                    }
                                                }
                                            },
                                            data: item.nodes && item.nodes.map(items => items.data)
                                        }
                                    ];

                                    if (item.nodes) {
                                        item.nodes.forEach(itemss => {
                                            if (
                                                !_find(xAxisItems, current => current === itemss.time)
                                            ) {
                                                xAxisItems = [...xAxisItems, itemss.time];
                                                // }
                                            }
                                        });
                                    }
                                });
                                this.privates.xAxisItems = xAxisItems;
                                this.privates.seriversItems = seriversItems;
                                let list = [];
                                seriversItems.forEach(item => {
                                    list = [...list, item.name];
                                });
                                this.privates.seriversItemsTitle = list;
                                let lists = [];
                                let link = [];
                                let links = [];
                                let conversionList = resp.data.conversionList.map(item => {
                                    return item;
                                });
                                conversionList.forEach((item, index) => {
                                    if (
                                        !_find(
                                            lists,
                                            current =>
                                                current.name ===
                                                parseInt(item.num.split(",")[0], 10) + "-" + item.source
                                        )
                                    ) {
                                        lists = [
                                            ...lists,
                                            {
                                                name:
                                                    parseInt(item.num.split(",")[0], 10) +
                                                    "-" +
                                                    item.source,
                                                itemStyle: {
                                                    normal: {
                                                        color: _find(
                                                            colorChangeList,
                                                            current =>
                                                                current.id ===
                                                                parseInt(item.num.split(",")[0], 10)
                                                        )
                                                            ? _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id ===
                                                                    parseInt(item.num.split(",")[0], 10)
                                                            ).color?.[0]
                                                            : "#000", //折线点的颜色
                                                        lineStyle: {
                                                            color: _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id ===
                                                                    parseInt(item.num.split(",")[0], 10)
                                                            )
                                                                ? _find(
                                                                    colorChangeList,
                                                                    current =>
                                                                        current.id ===
                                                                        parseInt(item.num.split(",")[0], 10)
                                                                ).color?.[0]
                                                                : "#000" //折线的颜色
                                                        }
                                                    }
                                                }
                                            }
                                        ];
                                    }
                                    if (
                                        !_find(
                                            lists,
                                            current =>
                                                current.name ===
                                                parseInt(item.num.split(",")[1], 10) + "-" + item.target
                                        )
                                    ) {
                                        lists = [
                                            ...lists,
                                            {
                                                name:
                                                    parseInt(item.num.split(",")[1], 10) +
                                                    "-" +
                                                    item.target,
                                                itemStyle: {
                                                    normal: {
                                                        color: _find(
                                                            colorChangeList,
                                                            current =>
                                                                current.id ===
                                                                parseInt(item.num.split(",")[1], 10)
                                                        )
                                                            ? _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id ===
                                                                    parseInt(item.num.split(",")[1], 10)
                                                            ).color?.[0]
                                                            : "#000", //折线点的颜色
                                                        lineStyle: {
                                                            color: _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id ===
                                                                    parseInt(item.num.split(",")[1], 10)
                                                            )
                                                                ? _find(
                                                                    colorChangeList,
                                                                    current =>
                                                                        current.id ===
                                                                        parseInt(item.num.split(",")[1], 10)
                                                                ).color?.[0]
                                                                : "#000" //折线的颜色
                                                        }
                                                    }
                                                }
                                            }
                                        ];
                                    }
                                });
                                lists = [
                                    ...lists,
                                    {
                                        name: Locales['current'].fix.other
                                    }
                                ];

                                link = conversionList.map(item => {
                                    return {
                                        source:
                                            parseInt(item.num.split(",")[0], 10) + "-" + item.source,
                                        target:
                                            parseInt(item.num.split(",")[1], 10) + "-" + item.target,
                                        value: item.conversion
                                    };
                                });
                                conversionList.forEach(item => {
                                    if (
                                        !_find(
                                            links,
                                            current =>
                                                current.source ===
                                                parseInt(item.num.split(",")[0], 10) + "-" + item.source
                                        )
                                    ) {
                                        links = [
                                            ...links,
                                            {
                                                source:
                                                    parseInt(item.num.split(",")[0], 10) +
                                                    "-" +
                                                    item.source,
                                                target: Locales['current'].fix.other,
                                                value: item.sourceTotal - item.targetTotal
                                            }
                                        ];
                                    }
                                });
                                this.privates.sanKeyItems = lists;
                                this.privates.sanKeyItemsLink = _concat(link, links);
                                let listss = [];
                                let items = [];
                                let correlationList = resp.data.correlationList.map(item => {
                                    return item;
                                });
                                correlationList.forEach((item, index) => {
                                    if (
                                        !_find(
                                            listss,
                                            current =>
                                                current.name ===
                                                parseInt(item.num.split(",")[0], 10) + "-" + item.source
                                        )
                                    ) {
                                        listss = [
                                            ...listss,
                                            {
                                                name:
                                                    parseInt(item.num.split(",")[0], 10) +
                                                    "-" +
                                                    item.source,
                                                itemStyle: {
                                                    normal: {
                                                        color: _find(
                                                            colorChangeList,
                                                            current =>
                                                                current.id ===
                                                                parseInt(item.num.split(",")[0], 10)
                                                        )
                                                            ? _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id ===
                                                                    parseInt(item.num.split(",")[0], 10)
                                                            ).color?.[0]
                                                            : "#000", //折线点的颜色
                                                        lineStyle: {
                                                            color: _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id ===
                                                                    parseInt(item.num.split(",")[0], 10)
                                                            )
                                                                ? _find(
                                                                    colorChangeList,
                                                                    current =>
                                                                        current.id ===
                                                                        parseInt(item.num.split(",")[0], 10)
                                                                ).color?.[0]
                                                                : "#000" //折线的颜色
                                                        }
                                                    }
                                                },
                                                des:
                                                    parseInt(item.num.split(",")[0], 10) +
                                                    "-" +
                                                    item.source +
                                                    ":" +
                                                    item.sourceTotal
                                            }
                                        ];
                                    }
                                    if (
                                        !_find(
                                            listss,
                                            current =>
                                                current.name ===
                                                parseInt(item.num.split(",")[1], 10) + "-" + item.target
                                        )
                                    ) {
                                        listss = [
                                            ...listss,
                                            {
                                                name:
                                                    parseInt(item.num.split(",")[1], 10) +
                                                    "-" +
                                                    item.target,
                                                itemStyle: {
                                                    normal: {
                                                        color: _find(
                                                            colorChangeList,
                                                            current =>
                                                                current.id ===
                                                                parseInt(item.num.split(",")[1], 10)
                                                        )
                                                            ? _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id ===
                                                                    parseInt(item.num.split(",")[1], 10)
                                                            ).color?.[0]
                                                            : "#000", //折线点的颜色
                                                        lineStyle: {
                                                            color: _find(
                                                                colorChangeList,
                                                                current =>
                                                                    current.id ===
                                                                    parseInt(item.num.split(",")[1], 10)
                                                            )
                                                                ? _find(
                                                                    colorChangeList,
                                                                    current =>
                                                                        current.id ===
                                                                        parseInt(item.num.split(",")[1], 10)
                                                                ).color?.[0]
                                                                : "#000" //折线的颜色
                                                        }
                                                    }
                                                },
                                                des:
                                                    parseInt(item.num.split(",")[1], 10) +
                                                    "-" +
                                                    item.target +
                                                    ":" +
                                                    item.targetTotal
                                            }
                                        ];
                                    }
                                    items = [
                                        ...items,
                                        {
                                            source:
                                                parseInt(item.num.split(",")[0], 10) +
                                                "-" +
                                                item.source,
                                            target:
                                                parseInt(item.num.split(",")[1], 10) +
                                                "-" +
                                                item.target,
                                            name: `${Locales['current'].fix.correlationCoefficient}：${item.correlationRate}`,
                                            des: `${Locales['current'].fix.correlationCoefficient}：${item.correlationRate} <br /> ${Locales['current'].fix.repetitionRate}：${item.repetitionRate}`
                                        }
                                    ];
                                });
                                this.privates.graphData = listss;
                                this.privates.graphLinks = items;
                                this.actions.update();
                            }
                            this.actions.update();
                        },
                        reason => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
        datalistMap(data, count) {
            if (!_isEmpty(data)) {
                if (count) {
                    this.privates.storages.countList = count + _size(data);
                } else {
                    this.privates.storages.countList = _size(data);
                }
                data.forEach(item => {
                    if (item.children && !_isEmpty(item.children)) {
                        this.actions.datalistMap(
                            item.children,
                            this.privates.storages.countList
                        );
                    }
                });
            }
        },
        //添加一级分类
        addOneCustome() {
            let { colorList } = this.privates;
            let { data, num, colorChangeList, countList } = this.privates.storages;
            if (countList >= 20) {
                Udesk.ui.notify.error(Locales['current'].fix.configurationItemsMoreThan20);
            } else {
                this.privates.storages.data = [
                    ...data,
                    {
                        type: "",
                        value: "",
                        num: num + 1,
                        operator: "",
                        color: [colorList[countList]]
                    }
                ];
                this.privates.storages.colorChangeList = [
                    ...colorChangeList,
                    {
                        id: num + 1,
                        color: [colorList[countList]]
                    }
                ];
                this.actions.datalistMap(this.privates.storages.data);
            }

            this.privates.storages.num = num + 1;
            this.actions.update();
        },
        echartInitSanKey() {
            let myChart = Echarts.init(document.getElementById("sanKeyRef"));
            let option = {
                title: {
                    text: Locales['current'].fix.conversionAnalysis
                },
                series: {
                    type: "sankey",
                    layout: "none",
                    draggable: false,
                    grid: { bottom: 100, top: 30 },
                    focusNodeAdjacency: "allEdges",
                    data: this.privates.sanKeyItems,
                    links: this.privates.sanKeyItemsLink
                }
            };
            myChart.setOption(option);
        },
        echartInitGraph() {
            let myChart = Echarts.init(document.getElementById("graph"));
            let option = {
                title: {
                    text: Locales['current'].fix.correlationAnalysis
                },
                tooltip: {
                    formatter: function (x) {
                        return x.data.des;
                    }
                },
                series: [
                    {
                        type: "graph",
                        layout: "force",
                        symbolSize: 70,
                        roam: true,
                        circular: {
                            rotateLabel: "none"
                        },
                        hoverAnimation: true,
                        grid: { bottom: 100 },
                        focusNodeAdjacency: true,
                        edgeSymbol: ["none", "none"],
                        edgeSymbolSize: 10,
                        edgeLabel: {
                            normal: {
                                textStyle: {
                                    fontSize: 12
                                },
                                show: true,
                                formatter: function (x) {
                                    return x.data.name;
                                }
                            }
                        },
                        force: {
                            repulsion: 2500,
                            edgeLength: 100
                        },
                        draggable: true,
                        itemStyle: {
                            normal: {
                                color: "#4b565b"
                            }
                        },
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: "#4b565b"
                            }
                        },

                        label: {
                            normal: {
                                show: true,
                                textStyle: {}
                            }
                        },
                        data: this.privates.graphData,
                        links: this.privates.graphLinks
                    }
                ]
            };
            myChart.setOption(option);
        },
        fieldDelListChangeMap(data, value) {
            if (!_isEmpty(data)) {
                for (let i = data.length - 1; i >= 0; i--) {
                    if (data[i].num === value) {
                        if (data[i].children && data[i].children.length > 0) {
                            data.splice(i, 1, ...data[i].children);
                        } else {
                            data.splice(i, 1);
                        }
                    } else {
                        this.actions.fieldDelListChangeMap(data[i].children, value);
                    }
                }
            }
        },
        fieldDelListChange(value) {
            let { data, countList, colorChangeList } = this.privates.storages;
            this.actions.fieldDelListChangeMap(data, value);
            this.privates.storages.colorChangeList = _filter(
                colorChangeList,
                current => current.id !== value
            );
            this.privates.storages.countList = countList - 1;
            this.actions.update();
        },
        fieldAddListChangeMap(data, value, num) {
            let { colorList } = this.privates;
            let { countList } = this.privates.storages;
            if (!_isEmpty(data)) {
                _compact(data).forEach(item => {
                    if (item.num === value) {
                        if (!_isEmpty(item.children) && item.children) {
                            item.children = [
                                ...item.children,
                                {
                                    type: "",
                                    value: "",
                                    num: num,
                                    operator: "",
                                    color: colorList[countList]
                                }
                            ];
                        } else {
                            item.children = [
                                {
                                    type: "",
                                    value: "",
                                    num: num,
                                    operator: "",
                                    color: colorList[countList]
                                }
                            ];
                        }
                    } else {
                        this.actions.fieldAddListChangeMap(
                            _compact(item.children),
                            value,
                            num
                        );
                    }
                });
            }
        },
        fieldAddListChange(value) {
            let { colorList } = this.privates;
            let { data, colorChangeList, num, countList } = this.privates.storages;
            this.privates.storages.num = num + 1;
            if (countList >= 20) {
                Udesk.ui.notify.error(Locales['current'].fix.configurationItemsMoreThan20);
            } else {
                this.actions.fieldAddListChangeMap(
                    data,
                    value,
                    this.privates.storages.num
                );
                this.actions.datalistMap(this.privates.storages.data);
                this.privates.storages.colorChangeList = [
                    ...colorChangeList,
                    {
                        id: num + 1,
                        color: [colorList[countList]]
                    }
                ];
            }
            this.actions.update();
        },
        dataSourceChangedMap(data, num, value) {
            if (!_isEmpty(data)) {
                data.forEach(item => {
                    if (item.num === num) {
                        item.type = value;
                        item.value = "";
                        item.operator = "";
                    } else {
                        this.actions.dataSourceChangedMap(item.children, num, value);
                    }
                });
            }
        },
        dataSourceFromChangeMap(data, num, value) {
            if (!_isEmpty(data)) {
                data.forEach(item => {
                    if (item.num === num) {
                        item.operator = value;
                    } else {
                        this.actions.dataSourceFromChangeMap(item.children, num, value);
                    }
                });
            }
        },
        dataSourceChange(num, value) {
            let { data } = this.privates.storages;
            this.actions.dataSourceChangedMap(data, num, value);
            this.actions.update();
        },
        dataSourceFromChange(num, value) {
            let { data } = this.privates.storages;
            this.actions.dataSourceFromChangeMap(data, num, value);
            this.actions.update();
        },
        dataSourceFromThreeChangeMap(data, num, value) {
            if (!_isEmpty(data)) {
                data.forEach(item => {
                    if (item.num === num) {
                        item.value = value;
                    } else {
                        this.actions.dataSourceFromThreeChangeMap(
                            item.children,
                            num,
                            value
                        );
                    }
                });
            }
        },
        dataSourceFromThreeChange(num, value) {
            let { data } = this.privates.storages;
            this.actions.dataSourceFromThreeChangeMap(data, num, value);
            this.actions.update();
        },
        dataSourceFromfiveChange(num, value) {
            let { data } = this.privates.storages;
            this.actions.dataSourceFromThreeChangeMap(data, num, value);
            this.actions.update();
        },
        InputColorItemChange(num, value) {
            let { colorChangeList } = this.privates.storages;
            this.privates.storages.colorChangeList = colorChangeList.map(item => {
                if (item.id === parseInt(num, 10)) {
                    item.color = [value];
                }
                return item;
            });
            
            this.actions.update();
        },
        newAddFilterFileds() {
            this.privates._isFilterVisible = true;
            this.actions.update();

        },
        filterContidionChanged(conditions) {
            let showFieldList = this.privates.fieldsData || [];
            let queryConditionList = this.actions.getQueryConditionList(
                conditions,
                showFieldList
            );
            this.privates.displayConditionList = conditions;
            this.privates.conditionList = queryConditionList;
        },
        handleConditionApply() {
            this.actions.handleConditionCancel();
            this.actions.getFieldUrl();

        },
        getFieldUrl() {
            let {
                sdkOptions
                // match
            } = this.props;
            let searchTemplateUrl = Udesk.business.apiPath.concatApiPath(
                `search-template/advanced`,
                sdkOptions
            );
            let serachTemplateData = {
                dataFieldSourceList: ["a", "pr"],
                fieldIdList: ["f_3", "f_10"],
                excludeFieldSourceList: [],
                excludeFieldIdList: [],
                type: this.privates.storages.dataSourceType
            };
            new Promise((resolve, reject) => {
                Udesk.ajax.post(searchTemplateUrl, serachTemplateData).then(
                    resp => {
                        resolve(resp.data);
                        this.privates.searchTemplate = resp.data.filter(item => item.status);
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        handleConditionCancel() {
            this.privates._isFilterVisible = false;
            this.actions.update();
        },

        fieldExport() {
            let {
                data,
                startTime,
                endTime,
                dataSourceType,
                groupByType,
                colorChangeList,
            } = this.privates.storages;
            let { sdkOptions } = this.props;
            // let dataList = _cloneDeep(data);
            // this.actions.dataValueMap(dataList);
            let datas = {
                conditions: formatConditions(data, colorChangeList),
                startTime,
                endTime,
                dataSourceType,
                groupByType,
                systemModule: getSystemModule(),
            };
            let searchUrl = Udesk.business.apiPath.concatApiPath(
                `businessAnalysis/exp`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(searchUrl, datas).then(
                    resp => {
                        Udesk.ui.notify.success(Locales['current'].fix.successfulExportAndToTaskCenter);
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        fieldPdf() {
            html2canvas(document.getElementById("exportPDF")).then(canvas => {
                let link = document.getElementById("exprotLink");
                link.href = canvas.toDataURL(); //下载链接
                // window.location = canvas.toDataURL();
                link.setAttribute("download", /* 业务分析.png */Locales['current'].pages.intelligentAnalysis.businessAnalysis.detail.component.businessAnalysispng);
                link.style.display = "none"; //a标签隐藏
                link.click();
            });
        },
        changeJudgeStrategy(value) {
            this.privates.judgeStrategy = value;
            this.actions.update();
        },
        customTtileChange(e) {
            this.privates.customTtile = e.target.value;
        },
        displacementChangeMapAdd(datas, item, field) {
            if (field) {
                if (!_isEmpty(datas)) {
                    for (let index = 0; index < datas.length; index++) {
                        if (datas[index].num === field) {
                            datas.splice(index + 1, 0, item);
                            return;
                        } else {
                            this.actions.displacementChangeMapAdd(
                                datas[index].children,
                                item,
                                field
                            );
                        }
                    }

                }
            } else {
                if (_size(datas) > 0) {
                    if (!_isEmpty(item)) {
                        if (item.children) {
                            item.children = [...item.children, ...datas];
                        } else {
                            item.children = [...datas];
                        }
                        this.privates.storages.data = [item];
                        this.actions.update();
                    }

                }
            }
        },
        displacementChangeMapAddRight(data, item, num) {
            if (!_isEmpty(data)) {
                for (let index = 0; index < data.length; index++) {
                    if (data[index].num === num) {
                        if (data[index].children) {
                            data[index].children = [...data[index].children, item];
                            return;
                        } else {
                            data[index].children = [item];
                            return;
                        }
                    } else {
                        this.actions.displacementChangeMapAddRight(
                            data[index].children,
                            item,
                            num
                        );
                    }
                }
            }
        },
        displacementChangeMap(data, num, str, field) {
            if (!_isEmpty(data)) {
                for (let index = data.length - 1; index >= 0; index--) {
                    if (data[index].num === num) {
                        if (str === "top" && index !== 0) {
                            let items = data[index];
                            data.splice(index, 1);
                            data.splice(index - 1, 0, items);
                            return;
                        } else if (str === "bottom" && index !== _size(data) - 1) {
                            let items = data[index];
                            data.splice(index, 1);
                            data.splice(index + 1, 0, items);
                            return;
                        } else if (str === "left") {
                            if (field) {
                                let items = data[index];
                                if (!_isEmpty(this.privates.storages.data.filter(current => current.num === num && _size(this.privates.storages.data) === 1))) {
                                    return;
                                } else {
                                    data.splice(index, 1);
                                    this.actions.displacementChangeMapAdd(
                                        this.privates.storages.data,
                                        items,
                                        field.num
                                    );
                                }
                                return;
                            } else {
                                let items = data[index];
                                if (!_isEmpty(this.privates.storages.data.filter(current => current.num === num && _size(this.privates.storages.data) === 1))) {
                                    return;
                                } else {
                                    if (_size(this.privates.storages.data) > 1) {
                                        data.splice(index, 1);
                                        this.actions.displacementChangeMapAdd(
                                            this.privates.storages.data,
                                            items
                                        );

                                        return;
                                    }
                                }

                            }
                        } else if (str === "right") {
                            if (_size(data) > 1) {
                                if (index > 0) {
                                    let items = data[index];
                                    let itemss = data[index - 1].num;
                                    data.splice(index, 1);
                                    this.actions.displacementChangeMapAddRight(
                                        this.privates.storages.data,
                                        items,
                                        itemss
                                    );

                                    return;
                                }
                            }
                        }
                    } else {
                        this.actions.displacementChangeMap(
                            data[index].children,
                            num,
                            `${str}`,
                            data[index]
                        );
                    }
                }

            }
        },
        displacementChange(num, str) {
            let { data } = this.privates.storages;
            this.actions.displacementChangeMap(data, num, `${str}`);
            this.actions.update();
        },
        getQueryConditionList(conditionList, showFieldList) {
            let queryConditionList = [];
            if (
                conditionList &&
                conditionList instanceof Array &&
                conditionList.length > 0
            ) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map(condition => {
                        showFieldList.forEach(field => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        backToList() {
            const routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('businessAnalysisList'),
            };
            if (this.props.route.name === 'analysisBusinessAnalysisDetail' || this.props.route.name === 'analysisBusinessAnalysisCreate') {
                routeOptions.routeName = 'analysisBusinessAnalysisList';
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        save(isCreate) {
            this.privates.formInstance.current.validateFields().then(() => {
                if(isCreate) {
                    postBusinessAnalysisConditionConfigs({
                        condition: {...this.privates.storages, conditions: formatConditions(this.privates.storages.data, this.privates.storages.colorChangeList)},
                        name: this.privates.name,
                    }, {
                        successMsg: /* 保存成功 */Locales['current'].pages.intelligentAnalysis.businessAnalysis.detail.component.savedSuccessfully
                    }).then(resp => {
                        this.actions.backToList();
                    });
                }else{
                    putBusinessAnalysisConditionConfigsById({
                        condition: {...this.privates.storages, conditions: formatConditions(this.privates.storages.data, this.privates.storages.colorChangeList)},
                        name: this.privates.name,
                    }, {
                        successMsg: /* 保存成功 */Locales['current'].pages.intelligentAnalysis.businessAnalysis.detail.component.savedSuccessfully,
                        segments: {
                            id: this.props.match.params.id
                        }
                    }).then(resp => {
                        this.actions.backToList();
                    });
                }
            });
        },
        onNameChange(e) {
            this.privates.name = e.target.value;
            this.actions.update();
        },
    };

    //#region Life Cycle
    componentDidMount() { }
    componentDidUpdate(prevProps, prevState) {
        this.actions.echartInitSanKey();
        this.actions.echartInitGraph();
    }
    componentWillUnmount() { }
    //#endregion
}

export default BusinessAnalysisComponent;

function formatConditions(conditions, colorList) {
    if(!Array.isArray(conditions)) return [];

    return conditions.map(condition => {
        const colorObj = colorList.find(i => i.id === parseInt(condition.num, 10));
        return {
            ...condition,
            color: colorObj?.color || (condition.color && typeof condition.color === 'string' ? [condition.color] : condition.color),
            children: formatConditions(condition.children, colorList),
        };
    });
}