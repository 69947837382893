import Locales from '../locales/index';
import Udesk from 'Udesk';

const userEnableStatus = [
    { id: 1, key: 'enabled', name: 'enabled' },
    { id: 2, key: 'disabled', name: 'disabled' },
];
const templateComponentList = [
    { id: 1, key: 'waiting', name: () => Locales.current.pages.admin.templateComponent.list.waiting },
    { id: 2, key: 'cancel', name: () => Locales.current.pages.admin.templateComponent.list.cancel },
    { id: 3, key: 'success', name: () => Locales.current.pages.admin.templateComponent.list.success },
    { id: 4, key: 'failed', name: () => Locales.current.pages.admin.templateComponent.list.failed }
];
const templateNeedUpdate = [
    { id: 2, key: "all", name: () => Locales.current.pages.admin.templateComponent.list.all },
    { id: 0, key: "no", name: () => Locales.current.pages.admin.templateComponent.list.no },
    { id: 1, key: "ok", name: () => Locales.current.pages.admin.templateComponent.list.ok },
];
const messageType = [
    { id: 0, key: "all", name: () => Locales.current.enums.messageType.all },
    // { id: 1, key: "monitor", name: () => Locales.current.enums.messageType.monitor },
    { id: 2, key: "trigger", name: () => Locales.current.enums.messageType.trigger },
    { id: 3, key: "timing", name: () => Locales.current.enums.messageType.timing },
];
const messagePushType = [
    { id: 3, key: "system", name: () => Locales.current.enums.messagePushType.system },
    { id: 4, key: "sdk", name: () => Locales.current.enums.messagePushType.sdk },
    { id: 1, key: "interfacePush", name: () => Locales.current.enums.messagePushType.push },
    { id: 2, key: "email", name: () => Locales.current.enums.messagePushType.email },
    { id: 5, key: "udesk", name: () => Locales.current.enums.messagePushType[Udesk.data.init.company.enabledWhiteSystem ? 'udeskWhite' : 'udesk'] },
    { id: 6, key: "weChat", name: () => Locales.current.enums.messagePushType.weChat },
];
const messageTypes = [
    { id: 1, key: "text", name: () => Locales.current.enums.messageTypes.text },
    { id: 2, key: "markdown", name: () => Locales.current.enums.messageTypes.markdown },
];
export default {
    userEnableStatus,
    templateComponentList,
    templateNeedUpdate,
    messageType,
    messagePushType,
    messageTypes,
};