import React, { useMemo } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
// import { QA_SUB_APP, SALES_SUB_APP, setCurrentSubAppType } from 'Udesk/system/subApp';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Typography } from 'udesk-ui';
import WrapPre from 'src/components/common/pre-wrap';
import styled from 'styled-components';
import { getSystemType, userEnableSysModule } from 'Udesk/business';
import UdeskLocales from 'UdeskLocales';
// import './style.scss';

const Wrap = styled.div`
    width: 320px;
    /* height: 314px; */
    padding: 24px 20px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.02));
    border-radius: 12px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: all 0.1s;
    opacity: ${(props) => (props.disabled ? 0.8 : 1)};
    height: 300px;

    .entry-btn {
        transition: all 0.1s;
        opacity: 0;
    }
    img {
        transition: all 0.1s;
        width: 120px;
    }

    &.driver-highlighted-element,
    &:hover {
        opacity: 1;
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.06));
        .entry-btn {
            opacity: 1;
        }
        img {
            width: 64px;
        }
    }
    .appName {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
        margin-top: 16px;
    }
    .desc {
        color: rgba(0, 0, 0, 0.45);
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }
`;
const Button = styled.div`
    background: linear-gradient(90deg, #1a6eff 0%, #1a6eff 0.01%, #71bbff 100%);
    border-radius: 14px;
    padding: 4px 24px;
    height: 30px;
    color: #ffffff;
    cursor: pointer;
    position: absolute;
    bottom: 28px;
`;

const SubSystemEntryCard = React.memo((props: any) => {
    // const locales = Locales['current'];
    const disabled = useMemo(() => {
        return !userEnableSysModule(props.sysModule)();
    }, [props.sysModule]);

    const tranToUdeskWebSite = () => {
        if (props.sysModule === Udesk.enums.sysModuleList.qa.id) {
            window.open('https://www.udesk.cn/feature_check.html');
        }
        if (props.sysModule === Udesk.enums.sysModuleList.sales.id) {
            window.open(
                '/static/import/Introduction to application scenarios of sales enablement.pdf'
            );
        }
        if (props.sysModule === Udesk.enums.sysModuleList.coach.id) {
            window.open('/static/import/Udesk intelligent sparring product introduction.pdf');
        }
    };
    const isFirstEnableSysModule = getSystemType()[0] === props.sysModule;

    return (
        <Wrap disabled={disabled} id={isFirstEnableSysModule ? 'firstEnableSysModule' : undefined}>
            <img src={props.icon} alt={props.appName} />
            <div className="appName">{props.appName}</div>
            <Typography.Paragraph
                className="desc"
                ellipsis={{ rows: 3, tooltip: <WrapPre>{props.desc}</WrapPre> }}
            >
                {props.desc}
            </Typography.Paragraph>
            <Button className="entry-btn" onClick={disabled ? tranToUdeskWebSite : props.onClick}>
                {disabled
                    ? /* 了解更多 */ UdeskLocales['current'].pages.appEntry.components
                          .subSystemEntryCard.learnMore
                    : /* 进入系统 */ UdeskLocales['current'].pages.appEntry.components
                          .subSystemEntryCard.enteringTheSystem}
            </Button>
        </Wrap>
    );
});

export { SubSystemEntryCard };
