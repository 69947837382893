import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';

import Page from 'Component/common/udesk-qa-page-layout';
import CommonTable from 'Component/common/udesk-qa-table-page';

import './style.scss';
import { Button, Modal, FormBuilder, Space, Popconfirm } from 'udesk-ui';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';

function IntelligentCollection(props) {
    const locales = Locales['current'];
    const intelligentCollection = locales.components.pages.intelligentCollection;

    let hasFeature = Udesk.data.init?.user?.hasFeature??null;
    const [form] = FormBuilder.useForm();
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [visible, setVisible] = useState(false);
    const [classifications, setClassifications] = useState([]);
    const [paging, setPaging] = useState({
        pageNumber: props?.location?.state?.pageNumber ?? 1,
        pageSize: props?.location?.state?.pageSize ?? 20,
        total: 0,
    });
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [initialValues, setInitialValues] = useState<any>({});
    const [needRefershRight, setNeedRefersh] = useState<any>(0);
    const [needRefershLeft, setNeedRefershLeft] = useState<any>(0);

    const [selectedClassificationId, setSelectedClassificationId] = useState<number | null>(null);
    const paginationProps = useMemo(() => {
        const { pageNumber, ...args } = paging;
        return {
            current: pageNumber,
            ...args,
            onChange: (page, pageSize) => {
                setPaging({
                    ...paging,
                    pageNumber: page,
                    pageSize,
                });
            },
        };
    }, [paging.pageNumber, paging.pageSize, paging.total]);
    const handleOk = useCallback(() => {
        form.submit();
    }, [form]);
    const handleCancel = useCallback(() => {
        setVisible(false);
        form.resetFields();
    }, [form]);

    const createBtn = useMemo(() => {
        const create = () => {
            let routeOptions = {
                history: props.history,
                routeName: 'intelligentCollectionNew',
                pathParams: {
                    groupId: selectedClassificationId,
                },
                state: {
                    pageNumber: paging.pageNumber,
                    pageSize: paging.pageSize,
                    selectedClassificationId: selectedClassificationId,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        };
        return <Button onClick={create}>{intelligentCollection.new}</Button>;
    }, [selectedClassificationId]);
    useEffect(() => {
        setLoading(true);
        let url = Udesk.business.apiPath.concatApiPath(`informationAcquisitionCategories`, props.sdkOptions);
        Udesk.ajax.get(url, { pageSize: 1000 }).then(
            (resp) => {
                setClassifications(
                    resp.data.map((i) => ({
                        ...i,
                        label: i.name,
                        description: i.describe,
                        canDelete: !i.isDefault,
                        canEdit: !i.isDefault,
                    }))
                );
                if (!selectedClassificationId) {
                    if (
                        typeof props?.location?.state?.selectedClassificationId === 'number' &&
                        !isNaN(props?.location?.state?.selectedClassificationId)
                    ) {
                        setSelectedClassificationId(props?.location?.state?.selectedClassificationId);
                    } else {
                        setSelectedClassificationId(resp.data[0].id);
                    }
                } else {
                    if (!resp.data.some((d) => d.id === selectedClassificationId)) {
                        setSelectedClassificationId(resp.data[0].id);
                    }
                }

                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, [needRefershLeft]);
    useEffect(() => {
        if (!selectedClassificationId) return;
        setLoading(true);
        let url = Udesk.business.apiPath.concatApiPath(
            `informationAcquisitionRules/category/${selectedClassificationId}`,
            props.sdkOptions
        );
        Udesk.ajax.get(url, { pageSize: paging.pageSize, pageNum: paging.pageNumber }).then(
            (resp) => {
                setDataSource(resp.data);
                setPaging({
                    pageSize: resp.paging.pageSize,
                    pageNumber: resp.paging.pageNum,
                    total: resp.paging.total,
                });
                setLoading(false);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, [selectedClassificationId, needRefershRight, paging.pageNumber, paging.pageSize]);
    const onFinish = (values) => {
        let url = Udesk.business.apiPath.concatApiPath(`informationAcquisitionCategories`, props.sdkOptions);
        let ajax: Promise<any> | null = null;
        setConfirmLoading(true);
        if (initialValues.id) {
            url = Udesk.business.apiPath.concatApiPath(
                `informationAcquisitionCategories/${initialValues.id}`,
                props.sdkOptions
            );
            ajax = Udesk.ajax.put(url, { ...values, isDefault: 0, id: initialValues.id });
        } else {
            ajax = Udesk.ajax.post(url, { ...values, isDefault: 0 });
        }
        if (!ajax) return;
        ajax.then(
            (resp) => {
                handleCancel();
                setConfirmLoading(false);
                setNeedRefershLeft((prev) => prev + 1);
            },
            (reason) => {
                setConfirmLoading(false);
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    const config: FormProps = {
        initialValues: initialValues,
        fields: [
            {
                name: 'name',
                label: locales.pages.wechatQa.checkPoint.classificationName,
                type: 'Input',
                props: {
                    placeholder: locales.pages.wechatQa.checkPoint.classificationNamePlaceHolder,
                    showCount: true,
                    maxCount: 20,
                },
                rules: [
                    {
                        required: true,
                        max: 20,
                    },
                ],
            },
            {
                name: 'describe',
                label: locales.pages.wechatQa.checkPoint.classificationRemark,
                type: 'TextArea',
                props: {
                    placeholder: locales.pages.wechatQa.checkPoint.classificationRemarkPlaceHolder,
                    autoSize: { minRows: 2, maxRows: 2 },
                    showCount: true,
                    maxCount: 255,
                },
                rules: [
                    {
                        max: 255,
                    },
                ],
            },
        ],
        footerRender: false as any,
        onFinish: onFinish,
    };
    const classificationProps = useMemo<any>(() => {
        const create = () => {
            setVisible(true);
            setInitialValues({});
        };
        return {
            classifications: classifications,
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>{intelligentCollection.classification}</div>
                    <Button onClick={create} size='small' type='link'>
                        {locales.labels.create}
                    </Button>
                </div>
            ),
            selectedKeys: selectedClassificationId ? [`${selectedClassificationId}`] : undefined,
            onSelect: ({ item, key, keyPath, selectedKeys, domEvent }) => {
                setSelectedClassificationId(parseInt(key as string, 10));
            },
            onEdit: (item, index) => {
                setVisible(true);
                const values = {
                    ...item,
                    name: item.label,
                    describe: item.describe ?? '',
                };
                setInitialValues(values);
            },
            onDelete: (item) => {
                if (!item.id) return;
                let url = Udesk.business.apiPath.concatApiPath(
                    `informationAcquisitionCategories/${item.id}`,
                    props.sdkOptions
                );
                Udesk.ajax.del(url).then(
                    (resp) => {
                        setNeedRefershLeft((prev) => prev + 1);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            },
        };
    }, [classifications, selectedClassificationId]);
    const tableProps = useMemo(() => {
        const deleteItem = (item) => {
            setLoadingDelete(true);
            let url = Udesk.business.apiPath.concatApiPath(`/informationAcquisitionRules/${item.id}`, props.sdkOptions);
            Udesk.ajax.del(url).then(
                (resp) => {
                    setLoadingDelete(false);
                    setNeedRefersh((prev) => prev + 1);
                },
                (reason) => {
                    setLoadingDelete(false);
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            `${locales.pages.tasks.manage.template.checkPoint.index.name}${locales.business.info.deleteError}`
                    );
                }
            );
        };
        const goToPointEdit = (item) => {
            let routeOptions = {
                history: props.history,
                routeName: 'intelligentCollectionEdit',
                pathParams: {
                    groupId: selectedClassificationId,
                    id: item.id,
                },
                state: {
                    pageNumber: paging.pageNumber,
                    pageSize: paging.pageSize,
                    selectedClassificationId: selectedClassificationId,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);

            Udesk.ui.routing.transitionTo(routeOptions);
        };
        const columns = [
            {
                title: intelligentCollection.table.name,
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                ellipsis: true,
            },
            {
                title: intelligentCollection.table.props,
                dataIndex: 'customField',
                key: 'customField',
                width: '20%',
                render: (name, item, index) => {
                    return <div>{name ? locales.labels.yes : locales.labels.no}</div>;
                },
            },
            {
                title: intelligentCollection.table.type,
                dataIndex: 'fieldType',
                key: 'fieldType',
                width: '20%',
                render: (name) => {
                    return Udesk.enums.valueTypes.getName(name);
                },
            },
            {
                title: intelligentCollection.table.desc,
                dataIndex: 'describe',
                key: 'describe',
                width: '20%',
                ellipsis: true,
            },
            {
                key: 'actions',
                dataIndex: 'actions',
                title: locales.labels.action,
                width: '18%',
                align: 'center' as any,
                render: (name, item, index) => {
                    return (
                        <div>
                            <Space>
                                {(hasFeature?.('smart:information:edit') || hasFeature?.('smart:information:view')) && (
                                    <Button type='link' size='small' onClick={goToPointEdit.bind(null, item)}>
                                        {locales.labels.edit}
                                    </Button>
                                )}
                                {hasFeature?.('smart:information:delete') && (
                                    <Popconfirm
                                        title={locales.labels.deleteContent}
                                        onConfirm={deleteItem.bind(null, item)}
                                        onCancel={(e) => e!.stopPropagation()}
                                        okText={locales.labels.confirm}
                                        okButtonProps={{
                                            loading: loadingDelete,
                                        }}
                                        placement='topRight'
                                        cancelText={locales.labels.cancel}
                                    >
                                        <Button danger type='link' size='small'>
                                            {locales.labels.delete}
                                        </Button>
                                    </Popconfirm>
                                )}
                            </Space>
                        </div>
                    );
                },
            },
        ];
        return {
            subtractedHeight: 136,
            dataSource,
            columns,
        };
    }, [selectedClassificationId, dataSource]);
    useEffect(() => {
        form?.resetFields?.();
    }, [initialValues]);
    return (
        <Page title={intelligentCollection.title} padding={true} pageBodyClassName='intelligent-collection-list-page'>
            <CommonTable
                // table={tableProps}
                // showClassification={true}
                // classification={classificationProps}
                mainButton={hasFeature?.('smart:information:add') ? createBtn : undefined}
                // pagination={paginationProps}
                loading={loading}
            >
                <CommonTable.Classification {...classificationProps}></CommonTable.Classification>
                <CommonTable.Pagination {...paginationProps}></CommonTable.Pagination>
                <CommonTable.Table {...tableProps}></CommonTable.Table>
            </CommonTable>
            <Modal
                title={
                    selectedClassificationId
                        ? locales.pages.wechatQa.checkPoint.editClassification
                        : locales.pages.wechatQa.checkPoint.newClassification
                }
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                // afterClose={afterClose}
            >
                <FormBuilder form={form} {...config}></FormBuilder>
            </Modal>
        </Page>
    );
}

class Component extends React.Component {
    render() {
        return <IntelligentCollection {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
