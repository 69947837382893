// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PutRolesModuleByIdParam,
    PutRolesModuleByIdBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * updateModule
 *
 * @export
 * @tags 角色
 * @link [PUT] /roles/module/{id}
 * @param id
 */
export function putRolesModuleById(
    data: PutRolesModuleByIdBody,
    options: ApiOptions<PutRolesModuleByIdParam, never> & { segments: PutRolesModuleByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutRolesModuleByIdBody, PutRolesModuleByIdParam, never>('/roles/module/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putRolesModuleById',
    });
}

export const meta = [{ tags: ['角色'], path: '/roles/module/{id}', method: 'put' }];
