import Udesk from 'Udesk';
import InteractiveRecognitionComponent from "./component";

class InteractiveRecognitionRoute extends InteractiveRecognitionComponent {
    asyncModel(changedParams) {
        let {
            sdkOptions
            // match
        } = this.props;
        let { keyword, activeNode, pageSize, pageNum } = this.privates;

        let url1 = Udesk.business.apiPath.concatApiPath(
            `interactiveCategories/initTree`,
            sdkOptions
        );
        let categories = new Promise((resolve, reject) => {
            Udesk.ajax.get(url1).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        }).then((categories) => {
            let url2 = Udesk.business.apiPath.concatApiPath(
                `interactiveDatas/findByKeyword`,
                sdkOptions
            );
            let params = {
                pageNum,
                pageSize,
                keyword
            };
            if (activeNode && activeNode.id) {
                params.categoryId = activeNode.id;
            } else {
                params.categoryId = categories[0].id;
            }
            if (changedParams != null) {
                Object.assign(params, changedParams);
            }
            return new Promise((resolve, reject) => {
                Udesk.ajax.post(url2, params).then(
                    resp => {
                        resolve({ interactiveDatas: resp, categories });
                        // if (resp.paging) {
                        //     that.setState({
                        //         pageSize: resp.paging.pageSize,
                        //         pageNum: resp.paging.pageNum,
                        //         total: resp.paging.total
                        //     });
                        // }
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        });
        return {
            categories,
        };
    }
    parseAsyncModel(asyncModel) {
        return asyncModel;
    }

}

export default InteractiveRecognitionRoute;
