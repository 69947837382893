import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Space, Button } from "udesk-ui";
import { Dialogue } from 'Component/pages/components/coach/Dialogue';
import { getRtaProps, isRta } from 'Component/pages/components/coach/Dialogue/components/RealTimeAnalyzer';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        isStartTask, setIsStartTask,
        wrongQuestion,
        globalConfig = {
            enableEmotionDetection: true,
            enableWordsViolationDetection: true,
            enableScorePointDisplay: true,
            enableSpeedWarn: true,
            enableWordsViolationWarn: true,
            enableEmotionDisplay: true,
        }, // 默认所有实时解析都显示
        isLastNode,
        list,
        audioRef,
        isOver,
        pause,
        startAudioRecord,
        loadingForTaskStart,
        loadingForSendMessage,
        setWrongQuestionIndex,
    } = props;

    const chatRecordRef = useRef<any>(null);

    const startHandle = useCallback(() => {
        setIsStartTask(true);
        setWrongQuestionIndex(0);
    }, [
        setIsStartTask, 
        setWrongQuestionIndex
    ]);

    const scoreDetailProps = useMemo(() => getRtaProps(wrongQuestion, globalConfig), [wrongQuestion, globalConfig]);

    useEffect(() => {
        chatRecordRef.current?.scrollTo(list?.length);
    }, [list?.length]);

    return (
        <div className={'chat-record-panel'}>
            <audio 
                className='hidden' 
                ref={audioRef} 
                controls 
                autoPlay={false} />
            <div className='chat-panel'>
                <Dialogue
                    ref={chatRecordRef}
                    dialogueSentenceList={list}
                    itemRender={(props, index) => {
                        return (
                            <React.Fragment>
                                <Dialogue.Item 
                                    {...props} 
                                    {...scoreDetailProps}
                                    index={index}
                                    isRta={isRta(wrongQuestion, props)}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
            {!isLastNode && <div className='record-toolbar'>
                <Space>
                    <div className={'wave'}></div>
                    <Button 
                        loading={loadingForSendMessage}
                        type='primary' 
                        size='large' 
                        onClick={isOver ? startAudioRecord : pause}
                    >
                        {isOver ? /* 开始录音 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.record.voice.index.startRecording: /* 结束录音 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.record.voice.index.endRecording}
                    </Button>
                </Space>
                {
                    !isStartTask && (
                        <div className='start-button'>
                            <Button 
                                loading={loadingForTaskStart}
                                type='primary'
                                size='large' 
                                onClick={startHandle}
                            >
                                {/* 开始练习 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.record.voice.index.startPracticing}
                            </Button>
                        </div>
                    )
                }
            </div>
            }
        </div>
    );
});

