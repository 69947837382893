import {
    InvalidArgumentError
} from '../../../error';

const TIMEOUT_INSTANCE_SYMBOL = Symbol("TIMEOUT_INSTANCE_SYMBOL");

class SetIntervalClass {
    constructor() {
        this[TIMEOUT_INSTANCE_SYMBOL] = null;
    }
    setInterval(callback, seconds) {
        if (typeof callback !== "function") {
            throw new InvalidArgumentError(`callback`);
        }
        if (seconds == null || typeof seconds !== "number" || seconds <= 0) {
            throw new InvalidArgumentError(`seconds`);
        }
        let context = this;
        if (this[TIMEOUT_INSTANCE_SYMBOL] != null) { 
            clearTimeout(this[TIMEOUT_INSTANCE_SYMBOL]);
        }
        this[TIMEOUT_INSTANCE_SYMBOL] = setTimeout(function () {
            callback();
            context.setInterval(callback, seconds);
        }, seconds * 1000);
    }
    clear() {
        clearTimeout(this[TIMEOUT_INSTANCE_SYMBOL]);
    }
}

export default SetIntervalClass;