import Recorder from 'recorder-core';
import 'recorder-core/src/engine/pcm';
//引入相应格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/wav';
import 'recorder-core/src/extensions/lib.fft.js';
import 'recorder-core/src/extensions/frequency.histogram.view.js';
import UdeskLocales from 'UdeskLocales';

const LogAudios: any[] = [0];

export const formatMs = function (ms: number, all?: any) {
    const f = Math.floor(ms / 60000),
        m = Math.floor(ms / 1000) % 60;
    const s =
        (all || f > 0 ? (f < 10 ? '0' : '') + f + ':' : '') +
        (all || f > 0 || m > 0 ? ('0' + m).substr(-2) + '″' : '') +
        ('00' + (ms % 1000)).substr(-3);
    return s;
};

const Runtime = {
    Log: (msg: string, color: number = 0) => {
        // console.log(msg, color);
    },
    LogAudio: (blob: any, duration: number, rec: any, msg: string) => {
        const set = (rec && rec.set) || {};
        LogAudios.push({
            blob,
            set,
            duration: duration,
        });

        Runtime.Log(
            (msg ||
                /* 已录制 */ UdeskLocales['current'].pages.coach.learningCenter.components.record
                    .util.recorded) +
                ':' +
                (set.bitRate || '-') +
                'kbps ' +
                (set.sampleRate || '-') +
                'hz ' +
                blob.size +
                'b [' +
                (set.type || '-') +
                ']' +
                formatMs(duration || 0) +
                'ms'
        );
    },
    LogClear: () => {
        LogAudios.splice(1, LogAudios.length - 1);
    },
};

const testSampleRate = 8000;
const testBitRate = 16;

/**
 * 每次发送指定二进制数据长度的数据帧，单位字节，16位pcm取值必须为2的整数倍，8位随意。
 * 16位16khz的pcm 1秒有：16000hz*16位/8比特=32000字节的数据，默认配置3200字节每秒发送大约10次
 */
const SendFrameSize = 3200;

let realTimeSendTryNumber: number = 0;
let transferUploadNumberMax: number = 0;
let realTimeSendTryChunk: any = null;
let realTimeSendTryChunks: any[] | null = [];

export const RealTimeSendTryReset = function () {
    realTimeSendTryChunks = null;
};

type sendData = (index: number, blob: Blob | '') => void;

export const RealTimeSendTry = function (
    buffers: any[],
    bufferSampleRate: number,
    isClose: boolean,
    callback: sendData
) {
    if (realTimeSendTryChunks == null) {
        realTimeSendTryNumber = 0;
        transferUploadNumberMax = 0;
        realTimeSendTryChunk = null;
        realTimeSendTryChunks = [];
    }
    //配置有效性检查
    if (testBitRate == 16 && SendFrameSize % 2 == 1) {
        Runtime.Log(
            /* 16位pcm SendFrameSize 必须为2的整数倍 */ UdeskLocales['current'].pages.coach
                .learningCenter.components.record.util
                .BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2,
            1
        );
        return;
    }

    let pcm: any = [];
    let pcmSampleRate: any = 0;
    if (buffers.length > 0) {
        //借用SampleData函数进行数据的连续处理，采样率转换是顺带的，得到新的pcm数据
        const chunk = Recorder.SampleData(
            buffers,
            bufferSampleRate,
            testSampleRate,
            realTimeSendTryChunk
        );

        //清理已处理完的缓冲数据，释放内存以支持长时间录音，最后完成录音时不能调用stop，因为数据已经被清掉了
        for (let i = realTimeSendTryChunk ? realTimeSendTryChunk.index : 0; i < chunk.index; i++) {
            // buffers[i]=null;
        }
        realTimeSendTryChunk = chunk; //此时的chunk.data就是原始的音频16位pcm数据（小端LE），直接保存即为16位pcm文件、加个wav头即为wav文件、丢给mp3编码器转一下码即为mp3文件

        pcm = chunk.data;
        pcmSampleRate = chunk.sampleRate;

        if (pcmSampleRate != testSampleRate)
            //除非是onProcess给的bufferSampleRate低于testSampleRate
            throw new Error(
                `${
                    /* 不应该出现pcm采样率 */ UdeskLocales['current'].pages.coach.learningCenter
                        .components.record.util.pCMSamplingRateShouldNotOccur
                }${pcmSampleRate}${
                    /* 和需要的采样率 */ UdeskLocales['current'].pages.coach.learningCenter
                        .components.record.util.andTheRequiredSamplingRate
                }${testSampleRate}${
                    /* 不一致 */ UdeskLocales['current'].pages.coach.learningCenter.components
                        .record.util.atypism
                }`
            );
    }

    //将pcm数据丢进缓冲，凑够一帧发送，缓冲内的数据可能有多帧，循环切分发送
    if (pcm.length > 0) {
        realTimeSendTryChunks.push({ pcm: pcm, pcmSampleRate: pcmSampleRate });
    }

    //从缓冲中切出一帧数据
    const chunkSize = SendFrameSize / (testBitRate / 8); //8位时需要的采样数和帧大小一致，16位时采样数为帧大小的一半
    pcm = new Int16Array(chunkSize);
    pcmSampleRate = 0;

    let pcmOK = false;
    let pcmLen = 0;
    for1: for (let i1 = 0; i1 < realTimeSendTryChunks.length; i1++) {
        let chunk = realTimeSendTryChunks[i1];
        pcmSampleRate = chunk.pcmSampleRate;

        for (let i2 = chunk.offset || 0; i2 < chunk.pcm.length; i2++) {
            pcm[pcmLen] = chunk.pcm[i2];
            pcmLen++;

            //满一帧了，清除已消费掉的缓冲
            if (pcmLen === chunkSize) {
                pcmOK = true;
                chunk.offset = i2 + 1;
                for (let i3 = 0; i3 < i1; i3++) {
                    realTimeSendTryChunks.splice(0, 1);
                }
                break for1;
            }
        }
    }

    //缓冲的数据不够一帧时，不发送 或者 是结束了
    if (!pcmOK) {
        if (isClose) {
            let number = ++realTimeSendTryNumber;
            TransferUpload(number, null, 0, null, isClose, callback);
        }
        return;
    }

    //16位pcm格式可以不经过mock转码，直接发送new Blob([pcm.buffer],{type:"audio/pcm"}) 但8位的就必须转码，通用起见，均转码处理，pcm转码速度极快
    const number = ++realTimeSendTryNumber;
    const encStartTime = Date.now();
    const recMock = Recorder({
        type: 'pcm',
        sampleRate: testSampleRate, //需要转换成的采样率
        bitRate: testBitRate, //需要转换成的比特率
    });
    recMock.mock(pcm, pcmSampleRate);
    recMock.stop(
        function (blob: any, duration: number) {
            blob.encTime = Date.now() - encStartTime;

            //转码好就推入传输
            TransferUpload(number, blob, duration, recMock, false, callback);

            //循环调用，继续切分缓冲中的数据帧，直到不够一帧
            RealTimeSendTry([], 0, isClose, callback);
        },
        function (msg: string) {
            //转码错误？没想到什么时候会产生错误！
            Runtime.Log(
                /* 不应该出现的错误: */ UdeskLocales['current'].pages.coach.learningCenter
                    .components.record.util.errorsThatShouldNotOccur + msg,
                1
            );
        }
    );
};

export const TransferUpload = function (
    number: number,
    blobOrNull: any,
    duration: number,
    blobRec: any,
    isClose: boolean,
    callback: sendData
) {
    transferUploadNumberMax = Math.max(transferUploadNumberMax, number);
    if (blobOrNull) {
        const blob = blobOrNull;
        const encTime = blob.encTime;

        //*********发送方式一：Base64文本发送***************
        // const reader = new FileReader();
        // reader.onloadend = function () {
        //     const base64 = (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader ?.result as string) || [])[1];
        //     //可以实现
        //     //WebSocket send(base64) ...
        //     //WebRTC send(base64) ...
        //     //XMLHttpRequest send(base64) ...

        //     //这里啥也不干

        //     if (callback) {
        //         callback(number === 1 ? 0 : 1, base64);
        //     }
        // };
        // reader.readAsDataURL(blob);

        //*********发送方式二：Blob二进制发送***************
        //可以实现
        //WebSocket send(blob) ...
        //WebRTC send(blob) ...
        //XMLHttpRequest send(blob) ...

        //****这里仅 console.log一下 意思意思****
        const numberFail =
            number < transferUploadNumberMax
                ? /* <span style="color:red">顺序错乱的数据，如果要求不高可以直接丢弃</span> */ UdeskLocales[
                      'current'
                  ].pages.coach.learningCenter.components.record.util
                      .spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan
                : '';
        const logMsg = 'No.' + (number < 100 ? ('000' + number).substr(-3) : number) + numberFail;

        Runtime.LogAudio(
            blob,
            duration,
            blobRec,
            logMsg +
                /* 花 */ UdeskLocales['current'].pages.coach.learningCenter.components.record.util
                    .flower +
                ('___' + encTime).substr(-3) +
                'ms'
        );

        callback(number, blob);

        if (number % 100 === 0) {
            //emmm....
            Runtime.LogClear();
        }
    }

    if (isClose) {
        callback(-1, '');

        Runtime.Log(
            'No.' +
                (number < 100 ? ('000' + number).substr(-3) : number) +
                /* :已停止传输 */ UdeskLocales['current'].pages.coach.learningCenter.components
                    .record.util.TransferStopped
        );
    }
};

//=====pcm文件合并核心函数==========
Recorder.PCMMerge = function (
    fileBytesList: any[],
    bitRate: number,
    sampleRate: number,
    True: any,
    False?: any
) {
    //计算所有文件总长度
    let size = 0;
    for (let i = 0; i < fileBytesList.length; i++) {
        size += fileBytesList[i].byteLength;
    }

    //全部直接拼接到一起
    const fileBytes = new Uint8Array(size);
    let pos = 0;
    for (let i = 0; i < fileBytesList.length; i++) {
        const bytes = fileBytesList[i];
        fileBytes.set(bytes, pos);
        pos += bytes.byteLength;
    }

    //计算合并后的总时长
    const duration = Math.round(((size * 8) / bitRate / sampleRate) * 1000);

    True(fileBytes, duration, { bitRate: bitRate, sampleRate: sampleRate });
};

// 这里，覆盖内部的音量计算方式，改变返回的 powerLevel 的值
Recorder.PowerLevel = function (pcmAbsSum, pcmLength) {
    /* 计算音量 https://blog.csdn.net/jody1989/article/details/73480259 */
    var power = pcmAbsSum / pcmLength || 0; //NaN
    var level;

    level = 20 * Math.log10(power / 32767) + 60;

    return level;
};

export default Recorder;
