import React, { useState, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { StandardizeTable } from 'udesk_gm_ui';
import { useVideoDemoRequest } from 'src/util/hook';
import { Badge, Button, Upload } from 'udesk-ui';
import { UploadProps } from 'udesk-ui/lib/upload';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
import './style.scss';
import { linkTo } from 'src/util/link';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

const Template = React.memo((props: any) => {
    const request = useVideoDemoRequest({
        ...props.sdkOptions,
        props: {
            ...props.sdkOptions.props,
            isVideo: true,
        },
    });

    const [dataSource, setDataSource] = useState<any[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const timeToken = useRef<any>(null);

    const requestList = () => {
        request('video-upload-history').then((resp) => {
            console.log('resp', resp);
            setDataSource(resp || []);
            if (timeToken.current) {
                clearTimeout(timeToken.current);
            }
            setLoading(false);

            if (resp.some((i) => i.status === 1)) {
                timeToken.current = setTimeout(() => {
                    requestList();
                }, 1000 * 10);
            }
        });
    };
    const uploadProps: UploadProps = {
        name: 'file',
        beforeUpload: (file) => {
            setUploading(true);
            Udesk.app.fileStorage.upload(file, {
                token: new Promise((resolve, reject) => {
                    Udesk.business
                        .getServerAuthorization(Udesk.enums.uploadFileTypes.image.id)
                        .then(
                            function (resp) {
                                resolve(resp);
                            },
                            function (reason) {
                                reject(reason);
                            }
                        );
                }),
                //progressCallback: progressCallback.bind(this),
                successCallback: (file, uploadData) => {
                    Udesk.ui.notify.success(/* 上传成功 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.uploadSuccessful);
                    const res = {
                        keyName: uploadData.key,
                        name: uploadData.name,
                        downloadPath: uploadData.key,
                        host: uploadData.token.host,
                    };
                    console.log('res', res);
                    const fullPath = res.host + '/' + res.downloadPath;
                    console.log('fullPath', fullPath);
                    request(
                        'video-upload-history',
                        {
                            fileName: res.name,
                            ossKey: fullPath,
                        },
                        'post'
                    ).then((resp) => {
                        setLoading(true);
                        requestList();
                    });
                    setUploading(false);
                },
                errorCallback: () => {
                    setUploading(false);
                },
            });
            return false;
        },
        showUploadList: false,
    };

    useEffect(() => {
        setLoading(true);
        requestList();
        return () => {
            if (timeToken.current) {
                clearTimeout(timeToken.current);
            }
        };
    }, []);
    return (
        <Page
            pageBodyClassName="video-analysis-list-page-index"
            title={/* 视频列表 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.videoList}
            padding={false}
            extra={[
                <Upload {...uploadProps}>
                    <Button type="primary" loading={uploading}>{/* 上传视频 */}{UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.uploadVideo}</Button>
                </Upload>,
            ]}
        >
            <StandardizeTable
                table={{
                    columns: [
                        { title: /* 视频名称 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.videoName, dataIndex: 'fileName' },
                        { title: /* 上传时间 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.uploadTime, dataIndex: 'createTime' },
                        { title: /* 结束时间 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.endTime, dataIndex: 'finishTime' },
                        {
                            title: /* 分析进度 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.analyzeProgress,
                            dataIndex: 'status',
                            render(text) {
                                return (
                                    <div>
                                        <Badge
                                            status={
                                                {
                                                    1: 'processing',
                                                    2: 'success',
                                                    3: 'error',
                                                    4: 'default'
                                                }[text]
                                            }
                                        />
                                        {
                                            {
                                                1: /* 进行中 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.haveInHand,
                                                2: /* 已完成 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.completed,
                                                3: /* 失败 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.fail,
                                                4: /* 待开始 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.toBegin
                                            }[text]
                                        }
                                    </div>
                                );
                            },
                        },
                        {
                            title: /* 操作 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.operation,
                            dataIndex: 'actions',
                            renderType: 'actions',
                            buttons: [
                                {
                                    label: /* 查看 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.see,
                                    onClick: (item) => {
                                        linkTo(
                                            props.history,
                                            getCurrentRouteName('videoAnalysisDetail'),
                                            {
                                                id: item.id,
                                            }
                                        );
                                    },
                                },
                                {
                                    label: /* 删除 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.list.index.delete,
                                    danger: true,
                                    onClick: (item) => {
                                        request(
                                            `video-upload-history/${item.id}`,
                                            {},
                                            'del'
                                        ).then(() => {
                                            setLoading(true);
                                            requestList();
                                            request(`video-upload-history/run/${Udesk.data.init.company.id}`);
                                        });
                                    },
                                },
                            ],
                        },
                    ],
                    dataSource,
                    loading,
                }}
            />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
