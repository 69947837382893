// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerTaskInfosScorePointsQuery,
    BaseResponseListIntelligentPartnerScoreRule,
} from '../../../types';

/**
 * 查询任务对应要点列表
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/score/points
 */
export function getIntelligentPartnerTaskInfosScorePoints(): Promise<BaseResponseListIntelligentPartnerScoreRule>;
export function getIntelligentPartnerTaskInfosScorePoints(
    options: ApiOptions<never, GetIntelligentPartnerTaskInfosScorePointsQuery>
): Promise<BaseResponseListIntelligentPartnerScoreRule>;
export function getIntelligentPartnerTaskInfosScorePoints(
    options?: ApiOptions<never, GetIntelligentPartnerTaskInfosScorePointsQuery>
): Promise<BaseResponseListIntelligentPartnerScoreRule> {
    return request<never, never, GetIntelligentPartnerTaskInfosScorePointsQuery>(
        '/intelligentPartnerTaskInfos/score/points',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerTaskInfosScorePoints',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练发布任务接口'],
        path: '/intelligentPartnerTaskInfos/score/points',
        method: 'get',
    },
];
