// 统一合并为完整的 Locale
var locale = {
  lang: {
    selectAll: 'Select all as page',
    selected: 'Selected',
    term: 'term',
    deselect: 'Deselect',
  },
};
export default locale;
