import React from 'react';
import Udesk from 'Udesk';
import { getCurrentRouteName } from 'Udesk/system/subApp';

class DialogueManageDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        storages: {},
        pageConfig: {
            pageNum: Udesk.config.paging.defaultPageNumber,
            pageSize: Udesk.config.paging.defaultPageSize,
            total: null,
        },
        selectedDatum: {},
        selectedFilter: {},
        layoutSiderRef: React.createRef(),
        collapsed: false,
        scrollFlag: false,
        selectId: null,
        manageRef: React.createRef(),
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'sessionStorage',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/dialogue-manage\/view/i,
                /^\/conversation-analysis\/dialogue-manage\/view/i,
            ],
        },
    };
    static computes = {};

    actions = {
        onPageChanged(pageNum, pageSize, total) {
            this.trigger('onPageChanged', pageNum, pageSize, total);
            this.privates.pageConfig = { pageNum, pageSize, total };
            this.privates.storages.pageConfig = { pageNum, pageSize, total };
        },
        changeSelectedDatum(datum) {
            if (this.privates.selectedDatum.dataId !== datum.dataId) {
                this.privates.scrollFlag = false;
                this.privates.manageRef && (this.privates.manageRef.current.scrollTop = 0);
            }
            this.privates.selectedDatum = datum;
            this.trigger('onSelectedDatumChanged', datum);
            this.actions.update();
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('dialogueManageView'),
                pathParams: {
                    id: datum.dataId,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        backToIndexPage() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('dialogueManageIndex'),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        transitionToTemplate(template) {
            const routeOptions = {
                history: this.props.history,
                routeName: 'qualityInspectionListDetail',
                pathParams: { taskId: template.taskId, id: template.id },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        transitionToSentimentAnalysis() {
            const routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('sentimentAnalysisIndex'),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        getPageConfig() {
            let pageConfig = this.trigger('getPageConfig');
            return pageConfig;
        },
        siderActionsChange(collapsed, type) {
            this.privates.collapsed = collapsed;
            this.actions.update();
        },
        handleScroll(e) {
            if (!this.privates.scrollFlag) {
                this.privates.scrollFlag = true;
                this.actions.update();
            }
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        let { pageConfig, selectedDatum, selectedFilter } = this.privates.storages;
        const queryParams = new URLSearchParams(props.location.search);
        let fieldState = queryParams.get('newTab');
        let fieldList = {
            displayConditionList: [],
            conditionList: [],
            judgeStrategy: 1,
            customJudgeLogic: '',
            keyword: queryParams.get('keyword') || '',
            callId: props.match.params.id && parseInt(props.match.params.id, 10),
        };        
        return {
            pageConfig: pageConfig || this.trigger('getPageConfig'),
            selectedDatum: (props.match.params.id && { dataId: parseInt(props.match.params.id, 10) }) || selectedDatum,
            selectedFilter: (fieldState && fieldList) || selectedFilter || props.selectedFilter || fieldList,
        };
    }
    componentDidUpdate(prevProps, prevStates) {
        let { pageConfig, selectedDatum, selectedFilter } = this.privates;
        this.privates.storages = Object.assign({}, { pageConfig, selectedDatum, selectedFilter });
    }
    componentDidMount() {
        let { pageConfig, selectedDatum, selectedFilter } = this.privates;
        this.privates.storages = Object.assign({}, { pageConfig, selectedDatum, selectedFilter });
    }
    componentWillUnmount() {}
    asyncModel(changedParams) {}
    // parseAsyncModel(asyncModel, { asyncKey }) {

    // }
    //#endregion
}

export default DialogueManageDetailComponent;
