import React, { FC, memo } from 'react';
import { Button, Space } from 'udesk-ui';
import FilterItem, { TemplateProps as FilterItemProps } from '../item';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Item = styled.div``;

export type TemplateProps = {
    fields?: FilterItemProps['fields'];
    value?: FilterItemProps['value'][];
    onChange?: (v: TemplateProps['value']) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { fields, value, onChange } = props;

    const onContentChange = (idx: number, val: FilterItemProps['value']) => {
        const newValue = (value || []).map((v, _idx) => {
            if (_idx === idx) {
                return val;
            } else {
                return v;
            }
        });
        onChange?.(newValue);
    };

    const onAdd = () => {
        const newValue: FilterItemProps['value'][] = [
            ...(value || []),
            {
                field: fields?.[0].id,
            },
        ];
        onChange?.(newValue);
    };

    const onRemove = (idx: number) => {
        const newValue = (value || []).filter((v, _idx) => _idx !== idx);
        onChange?.(newValue);
    };

    return (
        <Wrapper>
            {value?.map((v, idx) => {
                return (
                    <Item key={idx}>
                        <Space>
                            <FilterItem
                                fields={fields}
                                value={v}
                                onChange={(v) => onContentChange(idx, v)}
                            />
                            <MinusCircleOutlined onClick={() => onRemove(idx)} />
                        </Space>
                    </Item>
                );
            })}

            <Button
                type="link"
                icon={<PlusCircleOutlined style={{ verticalAlign: 'text-bottom' }} />}
                style={{ padding: 0 }}
                onClick={onAdd}
            >{/* 添加新条件 */}{UdeskLocales['current'].pages.gong.saleClientCenter.list.components.advancedFilter.components.list.index.addNewConditions}</Button>
        </Wrapper>
    );
};

export default memo(Template);
