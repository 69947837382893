// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewCallAnalysisUserByIdQuery,
    GetReviewCallAnalysisUserByIdParam,
    BaseResponseDataCallAnalysisUserResultResponse,
} from '../../../../../types';

/**
 * byUser
 *
 * @export
 * @tags 团队分析
 * @link [GET] /review/call/analysis/user/{id}
 * @param id
 */
export function getReviewCallAnalysisUserById(
    options: ApiOptions<GetReviewCallAnalysisUserByIdParam, GetReviewCallAnalysisUserByIdQuery> & {
        segments: GetReviewCallAnalysisUserByIdParam;
    } & { params: GetReviewCallAnalysisUserByIdQuery }
): Promise<BaseResponseDataCallAnalysisUserResultResponse> {
    return request<never, GetReviewCallAnalysisUserByIdParam, GetReviewCallAnalysisUserByIdQuery>(
        '/review/call/analysis/user/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewCallAnalysisUserById' }
    );
}

export const meta = [
    { tags: ['团队分析'], path: '/review/call/analysis/user/{id}', method: 'get' },
];
