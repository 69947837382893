import { useState } from 'react';
import { DrawerProps } from 'udesk-ui/lib/drawer';

interface UseDrawerReturn extends DrawerProps {
    showDrawer: () => void;
    onClose: () => void;
}

export const useDrawer = (): UseDrawerReturn => {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    return {
        visible,
        showDrawer,
        onClose,
        width: 500,
        closable: false,
    };
};
