export default {
  labels: {
    clear: "أفرغ",
    refresh: "تحديث",
    nextStep: "الخطوة التالية",
    prevStep: "الخطوة السابقة",
    closeDriver: "الخروج من التوجيه",
    complete: "الانتهاء",
    viewStatistics: "عرض الإحصائيات",
    colon: "：",
    splitter: "/",
    remark: "ملاحظات",
    correction: "تصحيح الخطأ",
    save: "حفظ",
    reset: "إعادة التعيين",
    increase: "إضافة",
    preview: "معاينة",
    unit: "المادة",
    cancel: "إلغاء",
    all: "كل شيء",
    searchPlaceHolder: "أدخل استعلام الكلمة الرئيسية",
    greaterThan: "أكبر من",
    lessThan: "أقل من",
    second: "ثواني",
    description: "الوصف",
    column: "العمود",
    pleaseChoice: "يرجى اختيار",
    filter: "الفحص",
    saveAndSubmit: "حفظ وتقديمها",
    submit: "المقدمة",
    appeal: "الشكاوى",
    review: "مراجعة",
    choose: "الاختيار",
    manage: "الإدارة",
    delete: "حذف",
    add: "جديد",
    create: "جديد",
    edit: "تحرير",
    name: "اسم",
    describe: "الوصف",
    next: "الخطوة التالية",
    percent: "%",
    export: "تصدير",
    exportAll: "تصدير كل شيء",
    confirm: "تحديد",
    back: "العودة",
    versionUpdate:
      "يسعدني أن أبلغكم أن موقعنا قد أصدر للتو نسخة جديدة! لضمان الاستخدام العادي الخاص بك ، نحن بحاجة إلى حفظ العمل الحالي على الفور ، ثم تحديث الصفحة (اضغط على زر تحديث المتصفح ، أو اضغط على مفتاح F5) للسماح للإصدار الجديد الخاص بنا بأن يكون ساري المفعول. شكرا جزيلا لتعاون!",
    more: "المزيد",
    selected: "تم اختياره",
    deduct: "مشبك",
    maxScore: "على الأكثر",
    scoreUnit: "نقاط",
    pointUnit: "هذا واحد",
    comma: "，",
    view: "عرض",
    batchingImport: "استيراد",
    deleteContent: "هل أكدت أنك تريد حذفها ؟ لن يتم استعادتها بعد الحذف",
    import: "استيراد",
    uploadFile: "تحميل الملفات",
    downloadFile: "تصدير الملفات",
    upload: "تحميل",
    click: "انقر فوق",
    downloadTemplate: "تحميل قالب",
    fillInTheForm: "املأ حسب الشكل",
    UploadExcelfile: "تحميل Excel لإكمال الاستيراد بالجملة",
    uploadIdentical:
      "إذا كان اسم API موجودًا ، فسيتم تحديث البيانات الموجودة بالفعل في النظام عند الاستيراد",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "تطبيق",
    leftBracket: "(",
    rightBracket: ")",
    question: "السؤال",
    answer: "الجواب",
    content: "المحتوى",
    search: "البحث",
    query: "الاستفسارات",
    cancelEdit: "إلغاء التحرير",
    noValue: "لا توجد بيانات",
    selectAll: "الاختيار الكامل",
    changeAGroup: "مجموعة أخرى",
    arrow: " ---> ",
    saveReview: "مراجعة التقديم",
    saveSpotCheck: "تقديم أخذ العينات",
    rapidSampling: "أخذ عينات سريعة",
    file: "أرشفة",
    download: "تحميل",
    lastPage: "الصفحة السابقة",
    nextPage: "الصفحة التالية",
    batchActions: "عمليات الدُفعات",
    cancelDistribution: "إلغاء التخصيص",
    againDistribution: "إعادة التوزيع",
    deleteRecord: "حذف السجلات",
    oneOptionOpertor: "مفتش جودة اختياري",
    allOptionOpertor: "جميع مفتشي الجودة",
    distributionInfoFirstOne: "تأكد من أن هذا صحيح",
    distributionInfoFirstTwo: "إعادة توزيع السجلات ؟ يرجى اختيار هدف التوزيع",
    distributionInfoSecondOne: "تأكد من الإلغاء",
    distributionInfoSecondTwo:
      "هل سيتم تغيير معلومات الشخص المخصص للسجل إلى سجل مراجعة غير مخصص بعد الإلغاء.",
    distributionInfoSecondThree:
      "هل سيتم تغيير معلومات الشخص المخصص للسجل إلى سجل أخذ العينات غير المخصص بعد الإلغاء.",
    distributionInfoThirdOne: "في السجل المختار",
    distributionInfoThirdTwo: "لن تتم معالجة السجلات التي تمت مراجعة السجلات.",
    distributionInfoThirdThree:
      "لن تتم معالجة السجلات التي تم فحصها بشكل عشوائي.",
    exportError: "فشل التصدير",
    exportSuccess: "تصدير النجاح",
    deleteRecordInfo:
      "هل تريد التأكد من حذف {0} سجل ؟ لن يتم استعادة البيانات بعد الحذف",
    pleaseSetFilter: "يرجى اختيار شروط الفحص",
    noCountAfterFilter: "لا توجد بيانات تصدير ، يرجى إعادة تعيين شروط الفحص",
    redo: "إعادة التنفيذ",
    batchMove: "دفعة للتحرك",
    websiteName: "فحص الجودة الذكية",
    salesEnable: "مبيعات التمكين",
    systemName: "فحص الجودة الذكية",
    initSystemError:
      "شذوذ الشبكة أو فشل تهيئة الصفحة ، يرجى الاتصال بخدمة العملاء",
    day: "يا إلهي",
    hour: "ساعات",
    minute: "نقاط",
    questionMark: "？",
    personalSettings: "الإعدادات الشخصية",
    logout: "شطب",
    ok: "تحديد",
    saveAs: "حفظ بشكل منفصل",
    pleaseEnter: "الرجاء إدخال",
    executeMessage: {
      executeSuccess: "التنفيذ الناجح للمقالة {0}",
      executeError: "{0} فشل التنفيذ",
    },
    field: "الحقول",
    operator: "عامل التشغيل",
    value: "القيمة",
    placeholder: "يرجى اختيار",
    pleaseSelect: "يرجى اختيار",
    totalScore: "مجموع النقاط",
    hyphen: "-",
    semicolon: "；",
    wfm: "جدول ذكي",
    user: "المستخدمين",
    none: "لا شيء",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "حفظ النجاح! يسري مفعول الموظفين المعنيين بعد تحديث الصفحة",
    saveAsName: "{0}(نسخة)",
    systemInformation: "عرض بيانات النظام",
    billingCenter: "مركز الفواتير",
    warningMessage:
      "الحصة المتبقية غير كافية ، يرجى إعادة الشحن في أقرب وقت ممكن",
    fileTypeHolder: "تنسيق الملف غير صحيح ، يرجى تحميل ملف تنسيق {0}",
    dataNo: "لا توجد بيانات",
    move: "تحرك",
    study: "لديك نقاط فحص جودة التعلم الذاتي غير المميزة",
    studySuccess: "تحديد النجاح",
    studyError: "تحديد الفشل",
    editType: "تحرير علامات التصنيف",
    copyed: "تم نسخها إلى الحافظة",
    clickToCopy: "انقر على نسخ",
    addFilterConditions: "إضافة شروط الفرز",
    filterConditions: "شروط الفرز",
    messageTime: "وقت الرسالة",
    messageType: "نوع الرسالة",
    pushType: "قنوات الرسائل",
    messageContent: "محتوى الرسالة",
    messageName: "اسم الرسالة",
    messageStatus: "حالة الرسالة",
    configuration: "معلومات التكوين",
    sendTarget: "إرسال الأهداف",
    guideUrl: "عنوان URL التمهيد",
    noticeDetail: "تفاصيل الرسالة",
    action: "العملية",
    seeDetail: "عرض التفاصيل",
    theDay: "{0} يوم",
    theLastDayOfMonth: "آخر يوم من الشهر",
    ignore: "تجاهل",
    seeNow: "تحقق الآن",
    exportConfirmText: {
      export: "ما مجموعه {0} قطعة من البيانات ، تأكيد التصدير ؟",
      exportAll: "تأكيد تصدير المحتوى الكامل ؟",
    },
    samrTagAdd: "إضافة علامات التبويب",
    deletePopconfirmContent: "هل أكدت أنك تريد حذفها ؟",
    TheLastThirtyDays: "آخر 30 يوما",
    addNumber: "زائد",
    subNumber: "ناقص",
    addStrategy: "إضافة استراتيجية",
    minutes: "دقيقة",
    status: "تمكين الحالة",
    pushConfig: "دفع التكوين",
    category: "تصنيف",
    and: "مع",
    interval: "الفاصل الزمني",
    switchOn: "افتح",
    switchOff: "أغلق",
    batchDel: "الحذف بالجملة",
    exportTxt: "تصدير نص TXT",
    playSpeed: "مضاعفة السرعة",
    messageLogBtn: "عرض الاتصال/المحادثة لإشعار الزناد",
    dataLink: "روابط البيانات",
    yes: "نعم",
    no: "لا",
    sentenceUnit: "الجملة",
    appealDetail: "تفاصيل سجل الشكوى",
    detail: "التفاصيل",
    default: "الافتراضي",
    customer: "العملاء",
    agent: "خدمة العملاء",
    sort: "فرز",
    applyNow: "التطبيقات",
    index: "الرقم التسلسلي",
    allColumns: "الأعمدة التي يمكن إضافتها",
    selectedColumns: "الأعمدة التي تمت إضافتها",
    syn: "تزامن",
    saveAndNext: "حفظ وإنشاء المادة التالية",
    viewReference: "عرض الاقتباس",
    referenceDetail: "اقتباس التفاصيل",
    goToQaList: "فحص الجودة",
    goToCheck: "اذهب لأخذ عينات",
    businessCard: "بطاقة العمل",
    weapp: "برنامج صغير",
    redpacket: "المغلف الأحمر",
    externalRedpacket: "ربط المظاريف الحمراء",
    sphfeed: "رقم الفيديو",
    exportTaskCreated: "تم إنشاء مهمة التصدير بنجاح",
    plzGoTo: "الرجاء القفز إلى",
    importTaskCreated: "نجاح إنشاء مهمة الاستيراد",
    taskCreated: "نجاح إنشاء المهمة",
    reply: "الرد",
    replied: "لقد أجبت",
    release: "نشر",
    expandMore: "توسيع الردود الأخرى {0}",
    comment: "تعليقات",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "من فضلك لديك واحد على الأقل {0}",
      canNotBeEmptyFormat: "{0} لا يمكن أن تكون فارغة",
      repeatErrorTipFormat: "{0} هناك تكرار",
      getInformationFailedFormat:
        "الحصول على معلومات {0} فشل ، يرجى التحقق من اتصال الشبكة أو حاول مرة أخرى في وقت لاحق",
      deleteSuccessFormat: "{0} الحذف ناجح.",
      deleteFailedFormat: "{0} فشل الحذف ، يرجى المحاولة مرة أخرى في وقت لاحق.",
      saveSuccessFormat: "{0} حفظ بنجاح.",
      saveFailedFormat: "{0} فشل الحفظ ، يرجى المحاولة مرة أخرى في وقت لاحق.",
      fileSuccessFormat: "{0} أرشفة ناجحة.",
      fileFailedFormat: "{0} فشل الأرشيف ، يرجى المحاولة مرة أخرى لاحقًا.",
      exportSuccessFormat: "{0} تصدير ناجح",
      exportFailedFormat: "{0} فشل التصدير ، يرجى المحاولة مرة أخرى لاحقًا.",
      submitSuccessFormat: "{0} قدم بنجاح.",
      submitFailedFormat: "{0} إرسال فشل ، يرجى المحاولة مرة أخرى لاحقًا.",
      confirmSuccessFormat: "{0} تأكيد النجاح.",
      confirmFailedFormat: "{0} تأكد من الفشل ، يرجى المحاولة مرة أخرى لاحقًا.",
      pleaseUploadFile: "يرجى تحميل الملف أولا!",
      importFileSuccess: "استيراد الملفات بنجاح!",
      importFildFailed: "فشل استيراد الملف!",
      deleteSuccess: "حذف النجاح!",
      deleteFailed: "فشل الحذف ، يرجى المحاولة مرة أخرى في وقت لاحق!",
      illegalTitle: "أذونات غير كافية",
      illegalMessage:
        "ليس لديك بعض أو كل الأذونات للخطوة التالية ، يرجى الاتصال بالمسؤول للتكوين ، والنقر على زر موافق سيخرج من التمهيد",
      illegalMessageCurrent:
        "ليس لديك بعض أو كل الأذونات للخطوات الحالية ، يرجى الاتصال بالمسؤول التكوين ، والنقر على زر موافق سيخرج من التمهيد",
      skipMessage: "إذا كنت تريد تخطي هذه الخطوة مؤقتًا ، فانقر على تخطي",
      errorMsg500:
        "فشل طلب الخادم ، يرجى التحقق من الإجراءات الخاصة بك أو تحديث إعادة المحاولة",
      errorMsg404: "Ooops... الصفحة التي تبحث عنها مفقودة",
      errorMsg400:
        "فشل طلب الخادم ، يرجى التحقق من البيانات الخاصة بك بعد إعادة المحاولة",
      pageOverFlow:
        "حاليًا ، يتم دعم 10000 عرض فقط ، يرجى تعيين شروط تصفية للعرض",
      isHandAddSmartTag: "هي علامات التبويب الذكية المضافة يدويا",
      defaultGradeMustAtLast: "يجب وضع التصنيف الافتراضي في النهاية",
      AutomaticAllocationInspectorsIsNull:
        "يرجى الدخول إلى صفحة المعلومات الأساسية لإضافة مفتش جودة",
      timePickerTips:
        "حتى لا يؤثر على استخدامك العادي ، 23:00 ~ 2:00 هو وقت صيانة النظام ، ولا يتم تنفيذ مهام التوقيت.",
      batchDeleteInfo:
        "نجح في حذف {0} ، فشل {1} ، سبب الفشل هو استخدام البيانات.",
      batchDeleteTips: "تحقق من بيانات {0} وتأكيد الحذف ؟",
      correctionSuccess: "تصحيح الأخطاء بنجاح",
    },
    pages: {
      qualityInspectionForm: {
        list: { IsMaxFormNumberError: "إضافة ما يصل إلى 10 نماذج فحص الجودة" },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "يجب أن يكون مجموع عناصر المؤشر {0} 100",
          banProjectMaxErrorTip: "إضافة ما يصل إلى 2000 عنصر محظور",
          deadlyProjectMaxErrorTip: "إضافة ما يصل إلى 500 عنصر مميت",
        },
      },
    },
    info: {
      creatSuccess: "خلق النجاح",
      creatError: "إنشاء فشل",
      editSuccess: "مراجعة ناجحة",
      editError: "فشل التعديل",
      deleteSuccess: "حذف النجاح",
      addSuccess: "نجاح جديد",
      deleteError: "فشل الحذف",
      conditionError: "خطأ في التعبير المنطقي المشغل",
      customJudgeLogic: "شرط مخصص مزيج من أخطاء التعبير المنطقي",
      exportError: "فشل التصدير",
      searchError: "فشل الفحص",
      loadError: "{0} فشل التحميل",
      saveSuccess: "حفظ النجاح",
      saveError: "فشل الحفظ",
      fileSuccess: "أرشفة ناجحة",
      fileError: "فشل الأرشيف",
      cannotBeNull: "{0} لا يمكن أن تكون فارغة!",
      pleaseSearchAndSelect: "الرجاء إدخال البحث عن الكلمات الرئيسية",
      clickAndToggle: "انقر على تبديل الاختيار",
      deleteWarning:
        "{0} لن يتم استعادة الحذف ، هل أنت متأكد من أنك تريد المتابعة ؟",
      getOssAuthFailed:
        "فشل الحصول على تفويض OSS ، يرجى التحقق من اتصال الشبكة أو حاول مرة أخرى في وقت لاحق.",
      getInsightAuthFailed:
        "الحصول على تفويض Insight فشل ، يرجى التحقق من اتصال الشبكة أو حاول مرة أخرى في وقت لاحق.",
      pictureUploadFailed: "فشل تحميل الصور",
      pictureUploadFailedSize: "حجم الصورة يتجاوز الحد ، فشل تحميل الصورة",
      wecomTagsSyncInfo: "آخر تحديث:{0} ، مزامنة {1} معلومات الشريط",
      collectSuccess: "جمع ناجح",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "فشل الحصول على بيانات التقرير" },
      waveAudio: {
        noWaveMessage:
          "للحفاظ على تجربتك ، لن تظهر التسجيلات التي تزيد عن {0} دقيقة شكل الموجة في الوقت الحالي ، يمكنك تشغيلها مباشرة",
      },
      customFilter: {
        del: "حذف",
        edit: "تحرير",
        new: "فحص مخصص",
        apply: "التطبيقات",
        cancel: "إلغاء",
        onlyApply: "ينطبق فقط",
        ohterSave: "حفظ بشكل منفصل",
        save: "حفظ",
        customFilter: "الفحص المشترك",
        reset: "إعادة التعيين",
        holderKeyWord: "الرجاء إدخال الكلمات الرئيسية",
        customFilterRule: "قواعد منطق الفحص:",
        customFilterName: "اسم المرشح:",
        holderCustomFilterName: "يرجى إدخال اسم المرشح",
        manualConfiguration: "التكوين اليدوي",
        holderCustomRule: "يرجى إدخال شروط منطقية مخصصة",
        example: "أمثلة",
        dragConfiguration: "تكوين السحب",
        condition: "الشروط",
        operation: "العمليات الحسابية",
        infoDel: "سحب المحتوى خارج المنطقة لحذفه",
        toConfigure: "التكوين",
        holderCustomFilterNameLength: "الحد الأقصى لطول اسم المرشح هو 10",
        customFilterNameNone: "لا يمكن أن يكون اسم المرشح فارغًا",
        and: "و",
        or: "أو",
        not: "لا",
        delSuccess: "حذف النجاح",
        saveSuccess: "حفظ النجاح",
        updateSuccess: "مراجعة ناجحة",
        customFilterCondition: "شروط الفرز",
        conditionRule: "قواعد الشروط",
        none: "لا شيء",
        filterPlaceholder: "الرجاء إدخال الكلمات الرئيسية",
      },
      labelTabs: {
        addThis: "إضافة إلى هذا",
        goThis: "الانتقال إلى هذا",
        saveGo: "حفظ وتدريب",
      },
      datePicker: {
        today: "اليوم",
        yesterday: "بالأمس",
        theDayBeforeYesterday: "أول من أمس",
        thisWeek: "هذا الأسبوع",
        lastWeek: "الأسبوع الماضي",
        thisMonth: "هذا الشهر",
        lastMonth: "الشهر الماضي",
        lastSevenDay: "آخر 7 أيام",
        lastThirtyDay: "آخر 30 يوما",
        thisQuarter: "هذا الربع",
        lastQuarter: "الربع الأخير",
        thisYear: "هذا العام",
        lastYear: "العام الماضي",
        lastYearFromToday: "آخر سنة",
      },
      pagination: { page: "ما مجموعه {0} ، {1} صفحة" },
      wechatRecord: {
        loadMoreBtn: {
          top: "تحميل الرسائل في وقت سابق",
          bottom: "تحميل المزيد من الرسائل",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "مهام فحص الجودة",
        informationCollectTasksManageTitle: "مهمة الجمع",
        customerServiceCenterTitle: "مركز خدمة العملاء",
        informationTitle: "مركز البيانات",
        intelligentAnalysisTitle: "التحليل الذكي",
        semanticIntelligence: "الذكاء الدلالي",
        trainingCenterTitle: "مركز التدريب",
        adminTitle: "إدارة النظم",
      },
      qualityCheckingTask: {
        title: "فحص جودة المكالمات",
        index: {
          tipOne:
            "يتم استخدام فحص جودة الاتصال لتقييم جودة عمل مركز اتصال الموظفين. يمكنك الجمع بين عملك الخاص وإعداد نموذج فحص الجودة المناسب",
          tipTwo:
            "يمكن وضع قواعد مناسبة لمهام فحص الجودة ، بما في ذلك الفحص وأخذ العينات وما إلى ذلك. بعد أن يسجل مفتش الجودة ، يمكن لخدمة العملاء التحقق من نتائج فحص الجودة وتقديم مزيد من الاستئناف.",
          addTaskTitle: "إضافة مهام فحص الجودة",
          deleteTipTitle: "حذف مهمة فحص الجودة",
          deleteTipContent:
            "لا يمكن استعادة مهمة فحص الجودة بعد حذفها ، هل تم تأكيد الحذف ؟",
          checkingTask: "مهام فحص الجودة",
          checkingTaskList: "قائمة مهام فحص الجودة",
          formList: "قائمة النماذج",
          viewHelpDocuments: "عرض وثائق المساعدة",
        },
        manage: {
          taskSetting: {
            title: "إعداد المهمة",
            form: "النموذج",
            basicInfo: "المعلومات الأساسية",
            visibleRange: "النطاق المرئي",
            visibleRangeTip:
              "يمكن لفريق خدمة العملاء وخدمة العملاء الذي تم اختياره عرض مهمة فحص الجودة ، بالإضافة إلى ذلك ، يمكن دمجها مع تعيين الأذونات لفريق خدمة العملاء لهذه المهمة ، وخدمة العملاء ، وفتش الجودة ، إلخ.",
            automaticSampling: "أخذ العينات التلقائي",
            automaticSamplingTip:
              "يمكنك إعداد قواعد أخذ العينات التلقائية بحيث يضيف النظام مهام فحص الجودة بانتظام وفقًا للقواعد التي قمت بتعيينها ، مثل سحب 5 ٪ من المكالمات الأسبوع الماضي كل يوم اثنين",
            samplingRate: "تردد أخذ العينات",
            samplingTime: "وقت أخذ العينات",
            samplingRange: "نطاق أخذ العينات",
            samplingRule: "قواعد أخذ العينات",
            fillterCondition: "شروط الفرز",
            workflow: "عملية عمل فحص الجودة",
            appealsTime: "الوقت المسموح به للطعن",
            appealsTimeTipOne: "بعد تقديم الدرجات",
            appealsTimeTipTwo: "في غضون أيام",
            appealsTimeTipThree:
              'بعد وقت الاستئناف المسموح به ، يتم تعديل حالة فحص الجودة المسجلة تلقائيًا إلى "مؤكدة"',
            auditTime: "وقت معالجة المراجعة",
            auditTimeTipOne: "بعد تقديم الشكوى",
            auditTimeTipTwo: "في غضون أيام",
            auditTimeTipThree:
              'بعد وقت المراجعة والمعالجة ، تحافظ السجلات على النتيجة الأصلية ، ويتم تعديل حالة فحص الجودة تلقائيًا إلى "مؤكدة"',
            noAppealsMode: "نموذج عدم التظلم",
            appealsMode: "نموذج التظلم المسموح به",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "خدمة العملاء",
            customerServicesGroup: "مجموعة خدمة العملاء",
            surveyList: "قائمة التقييم",
            customFields: "الحقول المخصصة",
            fieldConfig: "تكوين الحقل",
            from: "من",
            owner: "المسؤول",
            inspectorAndReviewerAuthority: "سلطة مراجعة فحص الجودة",
            inspector: "مفتش الجودة",
            reviewer: "المراجع",
            inspectorTip: "يمكن لمفتشي الجودة تقييم المهمة",
            reviewerTip: "يمكن للمراجعي تقييم المهمة",
            dataStatisticsAuthority: "سلطة إحصاءات البيانات",
          },
          samplingRecords: {
            title: "سجلات أخذ العينات",
            index: {},
            manualSampling: {
              title: "أخذ العينات",
              fillterCondition: "شروط الفرز",
              samplingRule: "قواعد أخذ العينات",
              agent: "خدمة العملاء",
              type: "نوع",
              result: "النتائج",
              evaluate: "التقييم",
              relayNumber: "رقم الترحيل",
              source: "المصدر",
              task: "المهام",
              queueState: "حالة قائمة الانتظار",
              callHanger: "الطرف الذي يعلق المكالمة",
              queueTime: "الانتظار في الطابور يستغرق وقتا طويلا",
              talkTime: "طول المكالمة",
              bellTime: "طول الجرس",
              recordCountTipOne: "المجموع",
              recordCountTipTwo: "سجل",
              customerServices: "خدمة العملاء",
              customerServicesGroup: "مجموعة خدمة العملاء",
              surveyList: "قائمة التقييم",
              customFields: "الحقول المخصصة",
              fieldConfig: "تكوين الحقل",
              defaultFields: "الحقول الافتراضية",
            },
          },
          taskList: {
            title: "قائمة المهام",
            index: {
              batchDelete: "الحذف بالجملة",
              batchSubmit: "التقديم بالجملة",
              batchConfirm: "تأكيد الدُفعات",
              itemFields: "سجل حقول المشروع",
              categoryFields: "حقل تصنيف المستوى الثاني",
              forbidsAndDeadlies: "المحظورات والمميتة",
              scoreFieldList: "قائمة حقول عنصر التقييم",
              customerName: "اسم العميل",
              cellphone: "الهاتف",
              email: "صندوق البريد",
              organization: "شركة",
              description: "الوصف",
              tags: "علامات التبويب",
              taskUserRole: "دور مستخدم المهمة الحالية",
            },
            workBench: {
              title: "تفاصيل السجل",
              checkForm: "نموذج فحص الجودة",
              waitEvaluate: "في انتظار التقييم",
              alreadyEvaluate: "التقييم",
              waitSubmit: "لم تقدم بعد",
              alreadySubmit: "قُدم",
              waitConfirm: "ليتم تأكيدها",
              alreadyConfirm: "تم تأكيده",
              waitReview: "في انتظار المراجعة",
              alreadyReview: "تمت مراجعة",
              alreadyDone: "أنجز",
              comment: "ملاحظات التقييم",
              totalScore: "مجموع النقاط",
              score: "نقاط",
              remark: "ملاحظات",
              changeRecords: "تغيير السجلات",
              subject: "الموضوع",
              secondLevelScore: "درجة المستوى الثاني",
              deadlyList: "العناصر القاتلة",
              forbidList: "المحظورات",
              addToCheckTask: "إضافة إلى مهمة فحص الجودة",
              submitCheckRemark: "تم تقديم تقييم فحص الجودة",
              changeCheckRemark: "مراجعة تقييم فحص الجودة",
              doneCheckRemark: "الانتهاء من تقييم فحص الجودة",
              submitCheckReview: "تم تقديم مراجعة فحص الجودة",
              submitCheckAppeal: "تم تقديم شكوى بشأن فحص الجودة",
              saveFlowSuccess: "التقييم ناجح.",
              saveFlowFailed:
                "فشل التقييم ، يرجى المحاولة مرة أخرى في وقت لاحق.",
              FormDetail: "تفاصيل النموذج",
              callDetail: "تفاصيل سجل المكالمات",
              sampleCalllogDetail: "تفاصيل السجل",
              appealReasonTip: "يرجى إدخال أسباب الاستئناف (مطلوب)",
              appeal: "الشكاوى",
              appealReason: "أسباب الشكوى",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} نموذج فحص الجودة",
        basicInformationTitle: "المعلومات الأساسية",
        indicatorsProjectTitle: "مشروع المؤشرات",
        specialProjectTitle: "عناصر خاصة",
        totalScore: "مجموع النقاط",
        indicatorsName: "اسم المؤشر",
        score: "النتيجة",
        singleScore: "نقاط فردية",
        weight: "الأوزان",
        maxDeductScore: "خصم النقاط القصوى",
        add: "زيادة",
        detail: "تفاصيل النموذج",
        list: {
          title: "نموذج فحص الجودة",
          tipOne:
            "يعد تكوين نموذج فحص الجودة الخطوة الأولى في عمل فحص الجودة ، حيث يمكنك الجمع بين أعمال الشركة وتكوين نموذج فحص الجودة الأنسب.",
          tipTwo:
            "نحن ندعم مجموعة متنوعة من النماذج مثل التسجيل المباشر ، ودرجات الوزن ، ونظام الخصم. بعد إعداد القواعد ، يوصى بإضافتها إلى النظام.",
        },
        basicInformation: {
          formTemplate: "قالب النموذج",
          directScore: "التقييم المباشر",
          weightedScore: "درجة الوزن",
          deductScore: "خصم النقاط",
          directScoreSecondLevel: "التقييم المباشر-المستوى الثاني",
          weightedScoreSecondLevel: "درجة الوزن-المستوى الثاني",
          deductScoreSecondLevel: "خصم النقاط-الدرجة الثانية",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "المحظورات",
          banProjectTip:
            "يمكن استخدامه لفحص درجة فحص الجودة ، ويمكن اعتباره انتهاكًا أو مكملاً للملاحظات ، دون التأثير على النتيجة الفعلية.",
          deadlyProject: "العناصر القاتلة",
          deadlyProjectTip:
            "يمكن استخدام العناصر القاتلة عند التحقق من نتائج فحص الجودة. إذا تم التحقق من العناصر القاتلة ، فإن مهمة فحص الجودة هذه تحصل على 0 نقاط مباشرة",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "فحص جودة المكالمات",
          entiretyReportTitle: "التقرير العام",
          serviceGroupReportTitle: "تقرير مجموعة خدمة العملاء",
          serviceReportTitle: "تقرير خدمة العملاء",
          entiretyReport: {
            pandect: "نظرة عامة",
            contrast: "مقارنة",
            trend: "الاتجاهات",
            analyze: "تحليل",
            totalScore: "مجموع النقاط",
            banProject: "المحظورات",
            deadlyProject: "العناصر القاتلة",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "قائمة نتائج فحص الجودة",
          taskSelectPlaceholder: "اختيار مهام فحص الجودة",
          agentSelectPlaceholder: "اختيار خدمة العملاء",
          refresh: "تحديث",
          all: "كل شيء",
          unread: "غير مقروء",
          inComplaint: "في الشكوى",
          reviewed: "تمت مراجعة",
          totalScore: "التعليق العام",
          detail: "تفاصيل فحص الجودة",
          audioText: "نص التسجيل الصوتي",
          callInfo: "معلومات الاتصال",
          ticketInfo: "معلومات ورقة العمل",
          wechatInfo: "معلومات الجلسة",
          dialogText: "نص الحوار",
          dialogInfo: "معلومات الحوار",
          customer: "العملاء",
          agent: "خدمة العملاء",
          none: "لا توجد بيانات فحص الجودة",
          appealSuccess: "الاستئناف ناجح!",
          appealFailed: "إذا فشلت الشكوى ، يرجى المحاولة مرة أخرى لاحقًا!",
          pointListIsNull: "نقطة فحص الجودة للشكوى لا يمكن أن تكون فارغة!",
          pleaseSelectPoint: "يرجى اختيار نقطة فحص الجودة!",
          unsupportedFormat: "التنسيق غير مدعوم في الوقت الحالي",
          shardingInformation: "فيما يلي القطع {0}",
          smartTags: "العلامات الذكية",
          businessRecords: "سجلات العمليات",
          selectedPointsWarning:
            "يرجى التحقق من عناصر فحص الجودة التي تريد الاستئناف ، وتحديد الجمل ذات الصلة لبنود الاستئناف ، ثم انقر فوق إرسال.",
          applyReason: "أسباب التظلم (50 كلمة كحد أقصى)",
          chatMessageTypes: {
            all: "كل شيء",
            text: "النص",
            image: "الصور",
            agree: "توافق على محتوى الجلسة",
            video: "فيديو",
            voice: "صوت",
            file: "الوثائق",
            emotion: "التعبير",
            link: "الروابط",
            chatrecord: "سجلات الدردشة",
            mixed: "رسائل مختلطة",
            default: "رسالة غير مدعومة",
            deleteFriend: "حذف الأصدقاء",
            redpacket: "المغلف الأحمر",
            card: "بطاقة العمل",
            disAgree: "لا أتفق مع محتوى الجلسة",
            weapp: "برنامج صغير",
            externalRedpacket: "ربط المظاريف الحمراء",
            meetingVoicevCall: "رسالة مكالمة",
            uRL: "صفحة ويب",
            replyTimeout: "مهلة الرد",
            sphfeed: "رقم الفيديو",
            chatRecordTitle: "سجلات الدردشة",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "مجموع النقاط",
            actualScore: "التقييم",
            actualGrade: "تصنيف",
            appeal: "الشكاوى",
            remark: "ملاحظات يدوية",
            none: "لا توجد بيانات تفاصيل فحص الجودة",
            pointEdit: "تصنيف تحرير نقاط فحص الجودة",
          },
          changeList: {
            title: "تغيير السجلات",
            user: "المستخدمين",
            point: "نقطة فحص الجودة",
          },
          relatedRecord: {
            title: "السجلات ذات الصلة",
            qualityList: "مهام فحص الجودة",
            none: "لا شيء",
            check: "عرض",
            loadMore: "تحميل أكثر",
          },
        },
      },
      grade: {
        title: "تصنيف فحص الجودة",
        subTitle:
          "يتم استخدام تصنيف فحص الجودة لتقييم كائنات فحص الجودة بعد الانتهاء من تسجيل فحص الجودة. على سبيل المثال ، يمكن تهيئتها بأقل من 60 نقطة غير مؤهلة ، 60-85 نقطة جيدة ، أعلى من 80 نقطة ممتازة",
        list: {
          up: "الانتقال العلوي",
          down: "تحركت لأسفل",
          del: "حذف",
          case: "انضم تلقائيًا إلى مكتبة الحالات",
          orderCase: "مكتبة الحالات الافتراضية",
          info: "لا يوجد تطابق في التصنيف الافتراضي ، ولا يمكن حذفه ، وإذا لم يستوف أي من التصنيفات المذكورة أعلاه ، فسيتم الحصول على التصنيف الافتراضي",
          add: "إضافة تصنيف",
          save: "حفظ",
          close: "إلغاء",
          max: "يمكن تكوين ما يصل إلى 20 تصنيفًا لفحص الجودة",
          name: "غير مسمى",
          success: "حفظ النجاح",
        },
      },
      qualityInspectionList: {
        title: "قائمة فحص الجودة",
        fastFilter: "مخالفات فحص الجودة",
        fastFilterTip:
          "نظام النقاط المخالف هو انتهاك ، ونظام النقاط غير المخالف هو انتهاك",
        titleWechat: "قائمة التحليل",
        titleCollect: "قائمة الجمع",
        status: "تحليل الحالة",
        reQualityInspectionConfig: "خيارات مهمة إعادة فحص الجودة",
        pleaseSelectRecheckPoints:
          "يرجى اختيار نقطة فحص الجودة التي تحتاج إلى إعادة حسابها",
        beginReQualityInspection: "بدء التنفيذ",
        cancelReQualityInspection: "إلغاء المهمة",
        reQualityInspection: "إعادة فحص الجودة",
        reQualityInspectionWechat: "إعادة التحليل",
        reQualityInspectionUnderWay: "مهمة إعادة فحص الجودة...",
        reQualityInspectionUnderWayTip:
          "مغادرة الصفحة بعد إغلاق النافذة لن يؤثر على المهمة",
        reQualityInspectionDone: "تم الانتهاء من إعادة فحص الجودة",
        reQualityInspectionDoneTip:
          "تم الانتهاء من إعادة فحص الجودة بنجاح ، يرجى النقر على زر التأكيد",
        endReQualityInspection: "إنهاء المهمة",
        alreadyDoneTip: "اكتمل {0}",
        totalTip: "مجموع {0}",
        endReInspectionSuccess: "تم إنهاء مهمة إعادة فحص الجودة",
        closeWindow: "أغلق النافذة",
        endReInspectionFailed:
          "فشلت مهمة إعادة فحص الجودة ، يرجى المحاولة مرة أخرى لاحقًا!",
        detail: {
          title: "تفاصيل فحص الجودة",
          wechatTitle: "تفاصيل التحليل",
          titleCollect: "جمع التفاصيل",
          callIndex: "المكالمات المرتبطة",
          textIndex: "حوار الارتباط",
          fileConfirm: "هل تم تأكيد الأرشيف ؟",
          fileConfirmDesc:
            "بعد الأرشفة ، لن تتمكن من تحرير نتائج فحص الجودة ، يرجى تأكيد ما إذا كان سيتم أرشفة",
        },
        sysCloumnsContal: "إدارة حقل عمود النظام",
        personCloumnsContal: "إدارة حقل العمود الشخصي",
      },
      spotCheckList: {
        title: "قائمة أخذ العينات",
        onlyMyTip: "تحقق فقط من فحص العينات الخاص بي",
        plInspector: "يرجى اختيار مفتش أخذ العينات",
        allInspector: "عرض الكل",
        seInspector: "عرض مفتشي العينات المعينين",
        myInspector: "فقط انظر إلي",
        noInspector: "تحقق من عدم تعيين مفتشي العينات",
        detail: { title: "تفاصيل أخذ العينات" },
      },
      reviewList: {
        title: "قائمة المراجعة",
        onlyMyTip: "تحقق فقط من مراجعتي",
        plInspector: "يرجى اختيار المراجع",
        allInspector: "عرض الكل",
        seInspector: "عرض المراجع المعين",
        myInspector: "فقط انظر إلي",
        noInspector: "عرض المراجع غير المعينة",
        detail: { title: "تفاصيل المراجعة" },
      },
      caseBaseList: {
        title: "مكتبة الحالات",
        del: "حذف",
        delTitle: "لن يتم حفظ جميع المحتويات بعد الحذف",
        cancel: "إلغاء",
        actions: "العملية",
        seeCaseBase: "عرض الحالة",
        seeDetail: "عرض التفاصيل",
        edit: "تحرير",
        save: "حفظ",
        addCaseBase: "مكتبة حالات جديدة",
        export: "تصدير",
        ok: "تحديد",
        tip: "يمكن حفظ بيانات المكالمات والحوارات النموذجية في قائمة فحص الجودة في قاعدة بيانات الحالة لتسهيل التعلم والمشاركة",
        detail: {
          choiceCaseBase: "يرجى اختيار الحالة",
          delCaseBase: "حالة الحذف",
          addCaseBase: "إضافة حالة",
          successed: "حفظ النجاح",
          info: "يرجى حفظ أو إلغاء المحتوى الذي يتم تحريره أولاً",
          caseType: "يرجى اختيار مكتبة الحالات التي تريد الانضمام إليها",
        },
      },
      testSetList: {
        title: "مجموعة اختبار فحص الجودة",
        tip: "سيتم تخزين بيانات الفحص العشوائي من قبل مفتشي الجودة في مجموعة اختبار فحص الجودة ، وهو مناسب لتحسين تكوين شروط فحص الجودة وقواعد فحص الجودة لبعض أخطاء فحص الماكينة التي تمت مقارنتها بعد أخذ العينات",
        refresh: "تحديث",
        reQualityInspectionUnderWay: "إعادة تقييم المهمة...",
        reQualityInspectionUnderWayTip:
          "مغادرة الصفحة بعد إغلاق النافذة لن يؤثر على المهمة",
        reQualityInspectionDone: "تم الانتهاء من إعادة التقييم",
        reQualityInspectionDoneTip:
          "تم الانتهاء من إعادة التقييم بنجاح ، يرجى النقر على زر التأكيد",
        endReQualityInspection: "إنهاء المهمة",
        alreadyDoneTip: "اكتمل {0}",
        totalTip: "مجموع {0}",
        endReInspectionSuccess: "تم إنهاء مهمة إعادة التقييم",
        closeWindow: "أغلق النافذة",
        endReInspectionFailed:
          "إعادة تقييم فشل المهمة ، يرجى المحاولة مرة أخرى في وقت لاحق!",
        recheckPoint: "إعادة حساب درجات نقاط فحص الجودة ودقتها",
        recheckPointTitle: "خيارات إعادة التقييم",
        delete: {
          reQualityInspectionUnderWay: "مهمة إزالة الدُفعات...",
          reQualityInspectionUnderWayTip:
            "مغادرة الصفحة بعد إغلاق النافذة لن يؤثر على المهمة",
          reQualityInspectionDone: "اكتملت مهمة الحذف بالجملة",
          reQualityInspectionDoneTip:
            "تم الانتهاء من الحذف بالجملة بنجاح ، يرجى النقر على زر التأكيد",
          endReQualityInspection: "إنهاء المهمة",
          alreadyDoneTip: "اكتمل {0}",
          totalTip: "مجموع {0}",
          endReInspectionSuccess: "تم إنهاء مهمة الحذف بالجملة",
          closeWindow: "أغلق النافذة",
          endReInspectionFailed:
            "فشل مهمة الحذف بالجملة ، يرجى المحاولة مرة أخرى في وقت لاحق!",
        },
        reultList: {
          assessmentTime: "وقت التقييم الأخير",
          assessmentAll: "التقييم الإجمالي",
          strip: "المادة",
          spot: "نقطة",
          deviation: "الانحراف",
          accuracyRate: "معدل الدقة",
          info: "لا توجد نتائج تقييم ، يرجى التقييم",
          onlyDeviationInfo: "انظر فقط إلى سجل الانحراف",
          againAssessment: "إعادة التقييم",
          screen: "الفحص",
          batchDelete: "الإزالة الجماعية",
          matching: "تطابق",
          ok: "نعم",
          noCancel: "لا",
        },
      },
      smartWordsLibrary: {
        title: "قاموس المرادفات الذكية",
        tip1: "المرادفات: تستخدم لتقليل مقدار التدريب من العلامات الدلالية ، والجمل المماثلة من المرادفات دون تكرار التدريب",
        tip2: "المصطلحات المهنية: تحسين النسخ وتحسين فهم اللغة الطبيعية للأسماء المناسبة مثل أسماء الشركات وأسماء المنتجات ومفردات الصناعة التي لا يتم تحديدها بدقة ، وتحسين دقة تحديد الهوية ودقة التحليل",
        tip3: "التركيز على الكلمات: الانتباه إلى الكلمات التي تريد الانتباه إلى التحليل السحابي للكلمات لمساعدة الذكاء الاصطناعي على تحليل النقاط الساخنة بما يتماشى مع الأعمال الحالية",
        tip4: "الكلمات المحمية: حجب الكلمات التي لا تريد الانتباه إلى التحليل السحابي للكلمات ، مما يساعد منظمة العفو الدولية على تحليل النقاط الساخنة بما يتماشى مع الأعمال الحالية",
        tip5: "وزن الكلمة: يستخدم لتصحيح وتحسين التعرف على العلامات الدلالية ، عن طريق تعديل وزن تأثير الكلمة في الجملة ، وتحسين تأثير التعرف الدلالي في مشهد معين حسب الحاجة",
        tip6: "بيانات المجموعة: أدخل فقرات كبيرة من النصوص لسيناريوهات الأعمال الخاصة بكل منها ، وتستخدم لإعادة كتابة وتصحيح الأخطاء وتدريب ASR لتحسين دقة النسخ ASR",
        upload: {
          uploadSynonymWords: "استيراد المرادفات",
          uploadProfessionalWords: "استيراد الأسماء المهنية",
          uploadAttentionWords: "استيراد كلمات الاهتمام",
          uploadIgnoreWords: "استيراد تجاهل الكلمات",
          uploadWordsWeight: "استيراد كلمات الوزن",
          uploadTypo: "استيراد الكلمات المطبعية",
          uploadCorpusData: "استيراد البيانات",
          uploadCorpusContent: "استيراد محتوى البيانات",
          firstUploadStep: "1. قم بتنزيل القالب ، املأه حسب تنسيق القالب",
          downloadTemplateMessage: "تحميل قالب:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. اضغط على زر الاستيراد لتحميل ملف EXCEl الذي يتوافق مع تنسيق القالب",
          secondUploadCorpusDataStep:
            "2. اضغط على زر الاستيراد لتحميل ملف TXT الذي يتوافق مع تنسيق القالب",
          postUploadDataFail: "فشل إرسال البيانات وتحميلها",
          pleaseUploadTemplate: "يرجى تحميل ملف EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "يمكن أن يكون قالب الملف بتنسيق JSON أو xlsx فقط",
          templateNeedTxt: "يمكن أن يكون قالب ملف البيانات فقط ملف TXT",
          uploadSuccess: "استيراد ناجح",
          uploadFailed: "فشل الاستيراد",
          downloadError: "فشل التنزيل",
          downLoadTxtTitle: "محتوى المواد. txt",
        },
        navTabNames: {
          synonymWords: "مرادفات",
          professionalWords: "المصطلحات المهنية",
          attentionWords: "التركيز على الكلمات",
          ignoreWords: "تجاهل الكلمات",
          wordsWeight: "كلمة الأوزان",
          corpusData: "البيانات",
          typoLibrary: "الكلمات المطبعية",
        },
        synonymWords: {
          createSynonymWords: "مرادفات جديدة",
          editSynonymWords: "تحرير المرادفات",
          addSynonymWords: "إضافة مرادفات",
          pleaseEnterStandardWords: "الرجاء إدخال كلمة قياسية",
          synonymWordsExists: "المرادف موجود بالفعل ولا يمكن إضافته",
          synonymWordsCanNotNull: "المرادفات المضافة لا يمكن أن تكون فارغة",
          pleaseAddSynonymWords: "الرجاء إضافة مرادفات",
          addSuccess: "نجاح جديد",
          editSuccess: "مراجعة ناجحة",
          deleteSuccess: "حذف النجاح",
          deleteError: "فشل الحذف",
          required: "*",
        },
        professionalWords: {
          create: "مصطلحات مهنية جديدة",
          edit: "محرر المصطلحات المهنية",
          addTargetWords: "إضافة كلمات بديلة",
          targetWordsExists: "كلمة الاستبدال موجودة بالفعل ولا يمكن إضافتها",
          targetWordsCanNotNull:
            "لا يمكن أن تكون الكلمات البديلة المضافة فارغة",
          pleaseAddTargetWords: "الرجاء إضافة كلمات بديلة",
          pleaseEnter: "يرجى إدخال الأسماء المهنية",
          wordsExists: "هذا المصطلح المهني موجود بالفعل",
          createSuccess: "إضافة النجاح",
          editSuccess: "مراجعة ناجحة",
          deleteSuccess: "حذف النجاح",
          deleteError: "فشل الحذف",
          saveError: "فشل الحفظ",
          addTargetWordsMaxNumberError: "يمكن إضافة ما يصل إلى 100 كلمة بديلة",
          addHomophonyWordsMaxNumberError:
            "يمكن إضافة ما يصل إلى 100 كلمة محمية",
        },
        attentionWords: {
          create: "كلمات قلق جديدة",
          edit: "التركيز على تحرير الكلمات",
          pleaseEnter: "الرجاء إدخال كلمة الاهتمام",
          wordsExists: "كلمة القلق موجودة بالفعل",
          createSuccess: "إضافة النجاح",
          editSuccess: "مراجعة ناجحة",
          deleteSuccess: "حذف النجاح",
          deleteError: "فشل الحذف",
        },
        ignoreWords: {
          create: "جديد تجاهل الكلمات",
          edit: "تجاهل تحرير الكلمات",
          pleaseEnter: "الرجاء إدخال كلمة تجاهل",
          wordsExists: "كلمة التجاهل موجودة بالفعل",
          createSuccess: "إضافة النجاح",
          editSuccess: "مراجعة ناجحة",
          deleteSuccess: "حذف النجاح",
          deleteError: "فشل الحذف",
        },
        wordsWeight: {
          createWordsWeight: "كلمات وزن جديدة",
          editWordsWeight: "تحرير كلمات الوزن",
          addWordsWeight: "إضافة كلمة الوزن",
          pleaseEnterWeightWords: "الرجاء إدخال كلمة الوزن",
          pleaseEnterWeight: "الرجاء إدخال الأوزان",
          weightNeedInZeroToHundred: "يجب أن يكون الوزن 0 ~ بين 100",
          synonymWordsExists: "كلمة الوزن موجودة بالفعل",
          weightNeedNumber: "يتطلب الأوزان تنسيق عدد صحيح",
          standardWeightInformation:
            "الوزن المعياري هو 50 ، كلما زاد الوزن ، كان التأثير أقوى في التحليل",
          createSuccess: "إضافة النجاح",
          editSuccess: "مراجعة ناجحة",
          deleteSuccess: "حذف النجاح",
          deleteError: "فشل الحذف",
        },
        corpusData: {
          createCorpusData: "بيانات جديدة",
          editCorpusData: "تحرير البيانات",
          addCorpusData: "إضافة بيانات",
          pleaseEnterCorpusDescription: "يرجى إدخال وصف",
          pleaseEnterCorpusContent: "يرجى إدخال محتوى البيان",
          synonymWordsExists: "بيانات الحبيبات موجودة بالفعل",
          CorpusContentWordsOverSetting:
            "عدد كلمات المحتوى أكثر من 20000 كلمة ،",
          download: "تحميل",
          upload: "تحميل",
          see: "عرض",
          canNotSave: "يرجى استخدام وظيفة الاستيراد ، وإلا لا يمكن حفظها",
          createSuccess: "إضافة النجاح",
          editSuccess: "مراجعة ناجحة",
          deleteSuccess: "حذف النجاح",
          deleteError: "فشل الحذف",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "الكلمات الصحيحة",
            wrongWord: "كلمة خاطئة",
            wrongWordCount: "عدد الكلمات الخاطئة",
            wrongWordTip: "اضغط على مفتاح العودة لإضافة",
            status: "فعالية",
          },
          create: "مجموعة جديدة من الكلمات المطبعية",
          edit: "تحرير الكلمات المطبعية",
          tip: 'تُستخدم مجموعة الكلمات المطبعية لتحسين تأثير "اكتشاف الأخطاء المطبعية". على سبيل المثال ، يتم إرسال "الاسترداد" عن طريق الخطأ إلى "التراجع". يمكنك استخدام هذه الوظيفة لتعيين الأخطاء المطبعية الشائعة والكلمات الصحيحة ، والانتباه إلى حفظها في اليوم التالي.',
          onlyChinese: "دعم إدخال الكلمات الصينية فقط",
          wrongWordLengthErr: "يجب أن يكون طول كل كلمة خاطئة 2 ~ 10 كلمات",
        },
      },
      semanticTags: {
        title: "العلامات الدلالية",
        current: "العلامات الدلالية الحالية",
        reference1: "مهمة فحص الجودة: {0} - {1}",
        reference2: "العلامة الذكية: {0}",
        reference3: "المعالجة المسبقة للبيانات: {0}",
        reference4: "قاعدة المعارف: {0}",
        index: { import: "استيراد العلامات الدلالية" },
        detail: {
          new: "علامة دلالية جديدة",
          edit: "تحرير العلامات الدلالية",
          name: "اسم",
          standardSentences: "الجملة القياسية",
          effectiveness: "فعالية",
          similarSentences: "جمل مماثلة",
          tip: "يدعم استدعاء واجهة برمجة تطبيقات الكيان باستخدام # # ، مثال: هل رقم هاتفك هو # sys_phone # ؟",
          excludeSimilarSentences: "استبعاد جمل مماثلة",
          intelligentRecommend: "يوصي ذكي بمحتوى مماثل",
          fastTraining: "تدريب سريع:",
          qualify: "تقويم",
          recallKeyWord: "استدعاء الكلمات الرئيسية",
          excludeKeywords: "استبعاد الكلمات الرئيسية",
          excludeKeywordsInfo1:
            "استبعاد الكلمات الرئيسية ، تكون الأولوية على جميع المنطق ، وإذا تم استبعاد الضربة ، فلن يتم ضرب العلامة الدلالية",
          keyWordInfo1:
            "يدعم كل سطر من الكلمات الرئيسية التي تم استرجاعها ملء أكثر من واحد ، مفصولة بفاصلة باللغة الإنجليزية ، وعند ملء الكلمات الرئيسية المتعددة ، يكون المنطق هو جميع الضربات.",
          keyWordInfo2:
            "تدعم الكلمات الرئيسية التي تم استرجاعها ملء أسطر متعددة ، ويمكن إنشاء منطق الضرب عند ملء العديد من الوقت.",
          recallRegular: "استدعاء المبادئ",
          excludeRegular: "استبعاد المبادئ",
          similaSentences: "استبعاد جمل مماثلة",
          simim: "التشابه",
          keyWordInfo3:
            "يتم إعطاء الأولوية لاستبعاد الجمل المتشابهة على جميع المنطق. إذا تم استبعاد الجمل المتشابهة ، فلن يتم ضرب العلامة الدلالية. التشابه الافتراضي هو 80 ٪ ، وكلما زاد التشابه ، زاد التشابه.",
          keyWordInfo4:
            "القواعد تشبه القواعد العادية ، بداية ونهاية الوجود الافتراضي. غير ضروري للاستخدام بين قوسين ، على سبيل المثال [غير XXXX]",
          keyWordInfo5:
            "غالبًا ما تكون الوظائف في الوحدات المتقدمة عبارة عن وظائف تجريبية لخبراء على دراية بالعمليات ، وإذا لم تكن متأكدًا من كيفية القيام بذلك ، فمن المستحسن عدم تغيير هذا الجزء.",
          keyWordInfo6:
            "تمنح وظيفة الكشف عبر الجملة العلامات الدلالية القدرة على الكشف عبر الجمل.",
          keyWordInfo7: "على الأكثر عبر نفس الدور المستمر",
          keyWordInfo8: "تجاهل الشخصيات المختلفة ليس أطول من الجمل",
          keyWordInfo9: "جمل من الكلمات للكشف عن الجمل المتقاطعة",
          keyWordInfo10: "أطول جملة عبور لا يزيد طول الجملة",
          keyWordInfo11: "إزالة نسبة مماثلة لا تقل عن",
          keyWordInfo12: "التشابه ليس أقل من",
          keyWordInfo13:
            "سيتم مطابقة جمل التشابه ٪ بشكل مشابه (التشابه الافتراضي 80 ٪)",
          keyWordInfo14:
            "سيتم مطابقة إعادة التشابه وفقًا لتشابه الجملة ، ويوصى بإزالة الجمل المتشابهة جدًا لتحسين كفاءة الكشف.",
          keyWord: "الكلمات الرئيسية",
          entity: "الكيانات",
          ruleField: "الكلمات المفاهيمية",
          customRule: "تصميم قواعد الكلام",
          fanhua: "أسئلة مماثلة",
          test: "اختبار",
          testOk: "ضرب الاختبار",
          senior: "الإعدادات المتقدمة",
          seniorList: "الكشف عبر الجملة",
          up: "تمكين",
          down: "أغلق",
          ju: "الجملة",
          oneWord: "كلمة واحدة",
          simimNo: "تشابه",
          seniorField: "عتبة التشابه",
          save: "حفظ",
          cancel: "إلغاء",
          specialElements: "عناصر خاصة",
          logicRule: "قواعد المنطق",
          every: "مطابقة متعددة",
          recallOk: "ضرب كلمة استدعاء:",
          recallNo: "لم يتم ضرب كلمة الاستدعاء",
          ruleOk: "ضرب الاسترجاع",
          ruleNo: "عدم ضرب",
          rularOk: "ضرب القواعد",
          rularNo: "عدم ضرب القواعد",
          infoOk: "ضرب الجملة القياسية أو الجملة المماثلة:",
          infoNo: "لم يتم ضرب الجملة القياسية أو الجمل المماثلة",
          fieldOk: "ضرب استبعاد الجمل المماثلة:",
          fieldNo: "استبعاد جمل مماثلة دون إصابة",
          excludeRuleNo: "استبعاد عدم الإصابة",
          excludeRuleOk: "ضرب للقضاء على",
          excludeKeywordNo: "استبعاد الكلمات الرئيسية دون إصابة",
          excludeKeywordOk: "ضرب استبعاد الكلمات الرئيسية",
          update: "نجاح التحديث",
          fanhuaList: "تم تقديمه إلى صفحة التعميم لتوقيع قائمة الجمل المماثلة",
          wordList: "قائمة التدريب فارغة ولا يمكن تقديمها إلى قائمة التعميم",
          start: "ابدأ",
          end: "النهاية",
          keyWordInfo15:
            "هناك حقول مماثلة في قائمة التعميم (تتشابه التكوينات المتقدمة لإزالة القيود)",
          saveSuccess: "حفظ النجاح",
          errInfo: "هناك حقول لم يتم ملؤها أو ملؤها بشكل غير صحيح",
        },
        table: {
          deepTraining: "جولة تدريب عميقة {0}",
          fastTraining: "تدريب سريع",
        },
      },
      intelligentCollection: {
        title: "الاستحواذ الذكي",
        classification: "قائمة المجموعات",
        new: "مجموعة جديدة",
        table: {
          name: "اسم الحقل",
          props: "خصائص العملاء",
          type: "نوع الحقل",
          desc: "وصف الحقل",
        },
        detail: {
          isCustomerProps: "حفظ إلى خصائص العميل",
          customerPropsHelper:
            "بعد الحفظ ، يتم تحديث المعلومات التي تم جمعها إلى معلومات العميل. إذا لم يتم حفظه ، فلن يتم تحديثه إلى معلومات العميل",
          name: "اسم الحقل",
          type: "نوع الحقل",
          text: "النص",
          desc: "وصف الحقل",
          descPlaceHolder: "وصف فائدة الحصول على المعلومات في هذا الحقل",
          condition: "الشروط",
          logic: "القواعد",
          method: "طريقة الجمع",
          message: "محتوى المعلومات",
          range: "نطاق الاستخراج",
          entity: "استخراج الكيانات",
          base: "المعلومات الأساسية",
          collect: "جمع المعلومات",
        },
      },
      intelligentTags: {
        title: "العلامات الذكية",
        sessionLabelTitle: "مشهد المحادثة",
        keyEventsTitle: "الأحداث الرئيسية",
        customerLabelTitle: "علامات العملاء",
        addClassification: "تصنيف تسمية جديد",
        defaultCategoryName: "التصنيف الافتراضي",
        index: {
          createTag: "علامة تبويب جديدة",
          createEvent: "حادثة جديدة",
          editTagClassification: "تحرير تصنيف العلامات",
          selectTagClassification: "اختيار تصنيف العلامات",
          editTagGrounp: "تجميع التحرير",
          callTimeError:
            "لا يمكن أن يتجاوز الإطار الزمني المحدد لوقت المكالمة/بدء المحادثة شهرًا واحدًا (31 يومًا)",
          callRecordCountError:
            "عدد السجلات هو 0 ، يرجى إعادة تحديد وقت المكالمة/المحادثة",
          selectItmeCountError:
            "يرجى اختيار العلامات الذكية التي تحتاج إلى تنفيذ عمليات الدُفعات",
          redoSuccess: "إعادة التنفيذ ناجحة ، يرجى التحقق من مركز المهام",
          redoError: "فشل إعادة التنفيذ ، يرجى المحاولة مرة أخرى في وقت لاحق",
          timeError: "لا يمكن أن يكون وقت الاستعلام أكثر من شهر (31 يومًا)",
          categoryName: "اسم التصنيف",
          categoryColor: "تصنيف اللون",
          newTagClassification: "تصنيف علامة جديدة",
          newEventClassification: "تصنيف جديد",
          tagClassification: "تصنيف العلامات",
          eventClassification: "تصنيف الأحداث",
        },
        detail: {
          new: "علامة تبويب ذكية جديدة",
          edit: "تحرير العلامات الذكية",
          tagName: "اسم",
          tagDesc: "وصف",
          category: "تصنيف",
          tagStatus: "فعالية",
          operator: "عامل",
          rule: "القواعد",
          labelClassAtion: "نوع العلامة",
          labelClassAtionTip1: "وضع العلامات: وضع العلامات على أساس القواعد",
          labelClassAtionTip2:
            "تصنيف العلامات: وضع العلامات على أساس تدريب النموذج",
          group: "مجموعات",
          checkLabel: "اختيار التصنيف",
          warn: "الإنذار المبكر",
          warnTips:
            "بعد فتح الإنذار المبكر ، عندما تفي الجلسة بقاعدة الحدث الحرجة هذه ، سيتم مطالبة الشخص المعين ، ويمكنك تلقي تذكير في مكتب المهام",
          warnError: "يرجى اختيار شخص معين للإنذار المبكر",
        },
        subTitle: {
          common: "محادثة ذكية Tags",
          wecom: "العلامات الصغيرة للشركات",
        },
      },
      customerPortrait: {
        title: "صورة العميل",
        index: { import: "استيراد صور العملاء" },
        searchPlaceHolder: "هوية العميل أو الاسم المستعار أو الهاتف",
        callCount: "عدد المحادثات:",
        callDuration: "إجمالي مدة المكالمة:",
        dialogCount: "إجمالي عدد بنود الحوار:",
        keyWordsStatistics: "احصائيات الكلمات الرئيسية (Top 20 & كلمات القلق)",
        keyWordsCount: "({0})",
        businessTag: "علامة تجارية",
        keyWordsCloudImg: "الكلمات الرئيسية صورة سحابة",
        customerEmotionTrend: "اتجاهات تغير مزاج العملاء",
        notFoundCustomer: "لم يتم استرجاع العملاء المعنيين",
        noKeyWords: "لم يتم العثور على الكلمات الرئيسية ذات الصلة",
        noBusinessTags: "لم يتم العثور على علامة تجارية ذات صلة",
        getKeyWordsStatisticsDataError:
          "فشل استعلام إحصاءات الكلمات الرئيسية للعميل",
        getBusinessTagsStatisticsDataError: "فشل استعلام علامة خدمة العملاء",
        personCustome: "عدم الاستعلام عن معلومات العميل",
        notimeInfo: "لم يتم الاستعلام عن المعلومات العاطفية",
        timeList: "نطاق التاريخ",
        operator: "العلامات الذكية",
        placeHolder: "يرجى اختيار",
        placeOperator: "يرجى اختيار علامة التبويب الذكية",
        search: "الاستفسارات",
        reset: "إعادة التعيين",
        searchHolder: "البحث",
        call: "مكالمة هاتفية",
        text: "الحوار",
      },
      sentimentAnalysis: { title: "اكتشاف الرأي العام" },
      saleSentimentAnalysis: { title: "تحليل الرأي العام" },
      dataPreprocess: {
        title: "المعالجة المسبقة للبيانات",
        index: {
          createData: "بيانات جديدة",
          selectDataSource: "اختيار مصادر البيانات",
          selectStatus: "فعالية الاختيار",
          callTimeError:
            "لا يمكن أن يتجاوز الإطار الزمني المحدد لوقت المكالمة/بدء المحادثة شهرًا واحدًا (31 يومًا)",
          callRecordCountError:
            "عدد السجلات هو 0 ، يرجى إعادة تحديد وقت المكالمة/المحادثة",
          selectItmeCountError:
            "يرجى تحديد البيانات التي تحتاج إلى تنفيذ عمليات الدُفعات",
          redoSuccess: "إعادة التنفيذ ناجحة ، يرجى التحقق من مركز المهام",
          redoError: "فشل إعادة التنفيذ ، يرجى المحاولة مرة أخرى في وقت لاحق",
        },
        detail: {
          new: "بيانات جديدة",
          edit: "تحرير البيانات",
          name: "اسم",
          desc: "وصف",
          dataSource: "مصادر البيانات",
          status: "فعالية",
          entity: "استخراج الكيانات",
          operator: "عامل",
          rule: "القواعد",
        },
      },
      connectedByConversation: {
        title: "اتصال الحوار",
        list: {
          title: "وظيفة ارتباط الحوار",
          info: "يمكن لوظيفة ارتباط الحوار ربط المكالمات المختلفة بالحوار وفقًا للقواعد المحددة مسبقًا",
          rule: "قواعد الارتباط بالحوار",
          source: "يرجى اختيار مصدر البيانات",
          classification: "يرجى اختيار التصنيف",
          choose: "يرجى اختيار",
          input: "الرجاء إدخال",
          save: "حفظ",
          cancel: "إلغاء",
          fieldwei: "بت",
          fieldNot: "هناك حقول غير مملوءة",
          fiedldlengthNo: "طول الحقل يتجاوز الحد",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip: "يمكن تعيين كلمات رئيسية متعددة ، مفصولة بفاصلة",
          applyRole: "كشف الدور",
          preOperator: "عامل ما قبل",
          applyOperatorScope: "نطاق الكشف",
          addOperator: "إضافة عامل",
          noPreOperator: "لا شيء",
          from: "رقم",
          to: "الجملة إلى الأولى",
          end: "الجملة",
          testRegular: "اختبار التعبيرات العادية",
          test: "اختبار",
          matchSuccess: "نجاح المطابقة",
          matchError: "فشل المطابقة",
          querySementicTags: "اختيار العلامات الدلالية",
          reQuerySementicTags: "إعادة اختيار العلامات الدلالية",
          sementicTag: "العلامات الدلالية",
          standardSentence: "الجملة القياسية",
          similarity: "التشابه",
          actions: "العملية",
          noFitTag: "لم يتم العثور على علامة مناسبة ،",
          addSemanticTag: "إضافة علامة دلالية جديدة",
          pleaseEnterRegular: "الرجاء إدخال تعبير عادي",
          pleaseEnterRegularText: "يرجى إدخال المحتوى الذي يجب التحقق منه",
          newAssociateComponentTip:
            "استخدم ميزة الإصدار القديم إذا كانت القيمة من النوع الرقمي",
        },
        filterManage: {
          noValueTip: "لا توجد بيانات",
          allCall: "جميع المكالمات",
          allDialogue: "جميع المحادثات",
          allWechat: "جلسة كاملة",
          getFiltersListFailed:
            "الحصول على قائمة من المرشحات تفشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
          getFilterDetailFailed:
            "الحصول على تفاصيل فلتر فشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
          getAllCountFailed:
            "الحصول على مجموع المكالمات/المحادثات فشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
        },
        listManage: {
          searchPlaceHolder: "الرجاء إدخال البحث عن الكلمات الرئيسية",
          filterConditions: "شروط الفرز",
          records: "سجل",
          getRecordsFailed:
            "الحصول على سجل فشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
        },
        miniListManage: {
          getRecordsFailed:
            "الحصول على سجل فشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
        },
        applyTemplates: {
          title: "تطبيق قوالب فحص الجودة",
          collectionTitle: "تطبيق قالب الجمع",
        },
        recordDetail: {
          showWaveAudio: "عرض التسجيلات",
          getDetailFailed:
            "الحصول على تفاصيل الفشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
          getSmartTagsFailed:
            "الحصول على العلامة الذكية تفشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
          getTemplatesFailed:
            "الحصول على قالب قابل للتطبيق فشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
        },
        changeList: {
          sampleStatus: "حالة أخذ العينات",
          sampleAssignInspector: "تعيين مفتشي العينات",
          reviewAssignInspector: "تعيين مراجعي الحسابات",
          read: "تمت قراءته",
          distributivePerson: "تعيين الناس",
          totalScore: "مجموع النقاط",
        },
        qualityCheckScore: {
          totalScore: "مجموع النقاط",
          actualScore: "النتيجة",
          actualGrade: "تصنيف",
          appeal: "الشكاوى",
          remark: "ملاحظات",
          manualRemark: "ملاحظات يدوية",
          none: "لا توجد بيانات تفاصيل فحص الجودة",
          interactiveTip:
            "استخدام التعرف التفاعلي للكشف عن استخدام الأسئلة الشائعة لخدمة العملاء",
          interactiveIsEmptyTip: "لم يتم الكشف عن محتوى التعرف على التفاعل",
        },
        semanticMark: {
          semanticContent: "الجملة القياسية",
          markedSimilarTitel: "العلامات الدلالية مماثلة",
          markedExcludeTitle: "تم استبعاد العلامات الدلالية المماثلة",
          taskTagTitle: "مهمة ذات صلة العلامات الدلالية",
          similarTagTitle:
            "التعرف على العلامات الدلالية المماثلة (التشابه> 40 ٪ ، أعلى 20)",
          delete: "حذف",
          markSimilar: "علامات مماثلة",
          markExclude: "استبعاد التشابه",
          none: "لا شيء",
        },
        selectCallTime: {
          callTime: "وقت التحدث/وقت بدء الجلسة",
          callTimeRemind:
            "يمكنك اختيار ما يصل إلى سجل حوار واحد لا يزيد عن شهر واحد (31 يومًا) في آخر سنة",
          callRecordCount: "لقد اخترت مجموع {0} من سجلات المكالمات/المحادثات",
          getCallRecordError:
            "الحصول على سجل فشل ، يرجى المحاولة مرة أخرى في وقت لاحق",
        },
      },
      informationEntities: {
        title: "كيانات المعلومات",
        index: { import: "استيراد كيانات المعلومات" },
        detail: {
          new: "كيان معلومات جديد",
          edit: "تحرير كيانات المعلومات",
          name: "اسم",
          apiName: "اسم API",
          activeFlag: "فعالية",
          remark: "الوصف",
          logicExp: "الكيانات",
          sentenceList: "محتوى الكيان",
          tip: "يدعم محتوى الكيان إدخال عناصر متعددة ، مفصولة بمسافات ، كمرادف لنفس الكيان",
          remarkLengthError: "لا يمكن أن يتجاوز طول الوصف 255",
          nameLengthError: "لا يمكن أن يتجاوز طول الاسم 40",
          entityDuplicateError: "ازدواجية محتوى الكيان",
        },
      },
      interactiveRecognition: {
        title: "التعرف على التفاعل",
        classify: "تصنيف",
        addClassify: "إضافة تصنيف",
        editClassify: "تحرير التصنيف",
        index: { import: "استيراد التعرف على التفاعل" },
        detail: {
          new: "تفاعل ذكي جديد",
          edit: "تحرير التفاعل الذكي",
          type: "نوع",
          standardQuestion: "القضايا القياسية",
          similarQList: "مشاكل مماثلة",
          exceptSimilarQList: "استبعاد مشاكل مماثلة",
          category: "تصنيف",
          targetType: "هدف الكشف عن المشكلة",
          status: "فعالية",
          standardAnswer: "الإجابات القياسية",
          similarAList: "إجابات مماثلة",
          wrongAList: "إجابة خاطئة",
        },
      },
      businessAnalysis: {
        title: "تحليل الأعمال",
        tip1: "تحليل اتجاه العمل: الإحداثيات الطولية هي عدد المحادثات ، أي عدد المحادثات التي تفي بالشروط ، والإحداثيات هي الوقت",
        tip2: "تحليل الارتباط: تحليل ارتباط مؤشرات متعددة ، عرض الماوس المعلق حالة بيانات الارتباط ، حجم البيانات ، الارتباط ، معدل التكرار",
        tip3: "تحليل التحويل: يمكن أن يشكل تحليل المؤشرات المتعددة علاقة تحويل متبادلة ، ومعيار تحديد التحويل هو نفس المكالمة/الحوار. إذا كان من الممكن ضرب مؤشرين مختلفين ، فيمكن اعتبار أن هذين المؤشرين لهما علاقة تحول.",
      },
      knowledgeBase: {
        title: "قاعدة المعرفة",
        list: {
          add: "إضافة تصنيف فرعي",
          delete: "حذف التصنيف",
          categoryName: "اسم التصنيف",
          createSuccess: "نجاح إنشاء التصنيف الفرعي",
          keyWord: "الكلمات الرئيسية",
        },
        detail: {
          type: "نوع",
          status: "الحالة",
          classification: "تصنيف",
          standardQuestion: "القضايا القياسية",
          answerDetectionRange: "نطاق الكشف عن الإجابة",
          standardAnswer: "الإجابات القياسية",
        },
      },
      callManage: {
        title: "مرشح تسجيل المكالمات",
        listTitle: "إدارة المكالمات",
        detailTitle: "تفاصيل المكالمة",
      },
      dialogueManage: {
        title: "مرشح نص الحوار",
        listTitle: "إدارة الحوار",
        detailTitle: "تفاصيل الحوار",
      },
      weChatManage: {
        title: "مرشح محادثة WeChat Enterprise",
        listTitle: "إدارة محادثة WeChat المؤسسة",
        detailTitle: "تفاصيل محادثة الشركة WeChat",
      },
      speechRecognition: {
        title: "التعرف على الكلام",
        asr: "التعرف على الكلام",
        asrRemind:
          "سيؤدي إيقاف تشغيل هذا المفتاح إلى فشل وظيفة تحويل الصوت إلى نص ووظيفة فحص جودة الجهاز",
        asrConfig: "تصفية ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "بعد التمكين ، سيتم تنفيذ نسخ ASR من IM",
        asrConfigRemind: "عند التمكين ، فقط المكالمات المؤهلة ستنفذ محتوى ASR",
        correction: "تصحيح الأخطاء الذكية",
        correctionRemind:
          "عند التفعيل ، سيتم استخدام المحتوى الموجود في \"<a href = '/site/semantic-intelligence/smart-words-library/professional-words' target = '_ blank'> Smart Thesaurus-Professional no </a>\" لتحويل تصحيح الخطأ الإجباري",
        correctionConfig: "تصفية تصحيح الخطأ",
        advancedSettings: "الإعدادات المتقدمة",
        advancedSettingsTip: "حدد نموذج ASR المحدد للبيانات المحددة.",
        mandarinASRModel: "نموذج ASR الماندرين",
        cantoneseASRModel: "نموذج ASR الكانتونية",
        englishASRModel: "الإنجليزية ASR نموذج",
        correctionConfigRemind:
          "بعد التفعيل ، سيتم تصحيح الأخطاء فقط المكالمات التي تفي بالشروط التالية",
        customJudgeLogicIsNull: "المنطق المخصص لا يمكن أن يكون فارغا",
        redoCorrection: "إعادة تنفيذ تصحيح الأخطاء",
        callTime: "اختيار وقت التحدث",
        callTimeRemind:
          "يمكنك اختيار ما يصل إلى سجل مكالمات واحد لا يزيد عن شهر واحد (31 يومًا) في آخر سنة",
        callRecordCount: "لقد اخترت إجمالي {0} من سجلات المكالمات",
        redoCorrectionSubmit: "تقديم التنفيذ",
        callTimeError:
          "لا يمكن أن يتجاوز الإطار الزمني للمكالمات المختارة شهر واحد (31 يومًا)",
        callRecordCountError:
          "عدد سجلات المكالمات هو 0 ، يرجى إعادة تحديد وقت المكالمة",
        redoCorrectionSuccess:
          "إعادة تصحيح الأخطاء بنجاح ، يرجى التحقق من مركز المهام",
        redoCorrectionError:
          "إعادة تصحيح فشل ، يرجى المحاولة مرة أخرى في وقت لاحق",
      },
      role: {
        title: "إدارة الأدوار",
        create: "شخصيات جديدة",
        detail: {
          title: "الدور",
          new: "شخصيات جديدة",
          view: "عرض الشخصية",
          edit: "تحرير الأدوار",
          navTabNames: {
            basicInfo: "أذونات القائمة",
            taskPermissions: "سلطة مهمة فحص الجودة",
            dataPermissions: "أذونات البيانات",
          },
          basicInfo: { roleName: "اسم", permissions: "أذونات" },
          taskPermissions: {
            allTask: "جميع مهام فحص الجودة",
            myCreatedTask: "مهام فحص الجودة التي قمت بإنشائها",
          },
          dataPermissions: {
            dataPermissionsConfig: "تكوين أذونات البيانات",
            tips: "يمكن للشخصية رؤية البيانات تحت الفئة المختارة",
            select: "اختيار التصنيف",
            selectPlaceholder:
              "يرجى تحديد التصنيف الذي يتطلب أذونات مرئية للبيانات",
          },
        },
      },
      dataMigration: {
        title: "ترحيل البيانات",
        dataUpload: "نقل البيانات",
        dataDowload: "نقل البيانات",
        error: "يرجى اختيار مهمة فحص الجودة للتصدير",
        list: {
          info: "يرجى اختيار مهمة فحص الجودة للانتقال",
          listInfo:
            "يرجى اختيار ما إذا كنت تريد الخروج من البيانات ذات الصلة بمهمة فحص الجودة (مثل المرادفات الذكية ، والعلامات الدلالية ، وكيانات المعلومات ، والتفاعل الذكي ، وما إلى ذلك)",
          noInfo:
            "يرجى اختيار ما إذا كنت تريد الخروج من بيانات أخرى غير فحص الجودة",
          ownInfo: "فقط اخرج من المهمة",
          everyInfo: "نقل المهام ذات الصلة",
          allInfo: "اخرج من كل شيء",
          callFilter: "مرشحات المكالمات",
          talkFilter: "مرشحات الحوار",
          smartTag: "العلامات الذكية",
        },
        upload: {
          info: "1. اضغط على زر التحميل لتحميل ملف JSON الذي يتوافق مع التنسيق",
          uploadSuccess: "نجاح التحميل",
          uploadError: "فشل التحميل",
        },
      },
      dataAnalysis: {
        businessStatistics: "إحصاءات الأعمال",
        qualityEffect: "فحص الجودة الصحية",
        qualityAnalysis: "تحليل فحص الجودة",
        generalInspecitionReport: "التقارير العامة",
        fullAmountWork: "عبء العمل على مفتشي الجودة",
        intelligentTagsAnalysis: "تحليل العلامات",
        saleIntelligentTagsAnalysis: "تحليل العلامات الذكية",
        generalInspecitionReportTip:
          "إجمالي كمية البيانات: احسب المبلغ الإجمالي لبيانات المكالمات والحوارات ضمن الوقت المحدد الحالي ونطاق المهمة ، بما في ذلك عدد ونسبة بيانات فحص الجودة الذكية ، وعدد ونسبة بيانات أخذ العينات اليدوية ، وعدد الشكاوى ، ومعدل إعادة الفحص ، ومخطط اتجاه الوقت",
        groupTest: "اختبار المجموعة",
      },
      uploadDownloadList: {
        title: "مركز البعثة",
        tabs: {
          upload: "تحميل الاستيراد",
          download: "تنزيل التصدير",
          taskCenter: "تنفيذ المهام",
        },
      },
      uploadDownloadModel: {
        title: "تحميل التنزيلات",
        list: {
          clear: "أفرغ",
          success: "النجاح",
          fail: "فشل",
          hand: "قيد العمل",
          noInfo: "لا توجد بيانات",
        },
      },
      billingCenter: {
        title: "مركز الفواتير",
        export: "تصدير",
        limit: "حدود التذكير",
        tips: "ستكون نتيجة الفوترة متحيزة بسبب التقريب ، كمرجع فقط",
        dashboard: {
          remainingQuota: "الحصة المتبقية الحالية",
          usedQuota: "الحصص المتراكمة المستخدمة",
          voiceQuality: "فحص جودة الصوت:",
          voice: "الصوت:",
          textQuality: "فحص جودة النص:",
          text: "النص:",
          hour: "ساعات",
          tenThousandHour: "عشرة آلاف ساعة",
          day: "يا إلهي",
          cutoff: "الموعد النهائي",
          warning:
            "(الحصة المتبقية غير كافية ، يرجى إعادة الشحن في أقرب وقت ممكن)",
          isClosed: "(حالة الإغلاق)",
        },
        table: {
          quotaRecord: "سجلات الحصص",
          timeFilter: "اختيار التاريخ:",
          search: "الاستفسارات",
          reset: "إعادة التعيين",
          id: "رقم الانسياب",
          type: "نوع",
          qualityType: "نوع فحص الجودة",
          quotaChange: "التغييرات",
          surplus: "الرصيد",
          changeTime: "الوقت",
          voiceQuality: "فحص جودة الصوت",
          textQuality: "فحص جودة النص",
          sizeChangeText: "عرض لكل صفحة",
          used: "استخدام",
          give: "منح",
          deduction: "الخصم",
          callTime: "وقت التحدث",
          count: "معالجة كمية البيانات",
          page: "ما مجموعه {0} ، {1} صفحة",
        },
        limitModal: {
          call: "حصة فحص الجودة الصوتية أقل من",
          callFix: "ساعة للتذكير",
          dialogue: "الوقت المتبقي لفحص جودة النص أقل من",
          dialogueFix: "تذكير السماء",
        },
      },
      messageCenter: {
        replyTitle: "الرد على لي",
        answerTitle: "@ بلدي",
        shareTitle: "شاركني",
      },
      columnsManage: { title: "إدارة حقول فحص الجودة" },
      recordUpload: {
        list: {
          task: "مهام فحص الجودة",
          user: "الشخص الذي يعمل",
          uploadTime: "وقت التحميل",
          endTime: "نهاية الوقت",
          total: "إجمالي التحميل",
          success: "فحص الجودة بنجاح",
          failed: "فشل فحص الجودة",
          progress: "تقدم فحص الجودة",
          inProgress: "قيد العمل",
          finished: "أنجز",
        },
        steps: {
          uploading: "تحميل ملفات التسجيل",
          baseInfo: "املأ المعلومات الأساسية",
          start: "بدء فحص الجودة",
        },
        uploadComponent: {
          dragger: "سحب إضافة ملف",
          or: "أو",
          selectFile: "اختيار الملفات",
          des: "يدعم تنسيق wav ، mp3 ، معدل أخذ العينات 8000Hz ، ملف 16 بت",
          limit1: "تحميل ما يصل إلى مرة واحدة",
          limit2: "ملف واحد ، لا يتجاوز حجم كل ملف",
          uploading: "تحميل",
          finished: "اكتمال التحميل",
          success: "النجاح",
          failure: "فشل",
        },
        baseInfo: {
          task: "اختيار مهام فحص الجودة",
          type: "اختيار شكل التسجيل",
          agentSide: "قناة خدمة العملاء",
          excel: "تحميل البيانات على الطريق",
          checkFileInfo: "معلومات وثيقة التحقق",
          excelFileError: "يرجى تحميل البيانات",
          checking: "التحقق من ذلك...",
          reCheck: "إعادة التحقق",
          success: "التحقق بنجاح",
          excelUoloadFail: "فشل تحميل البيانات على الطريق",
          tips: {
            tip1: "1. يجب أن يكون اسم ملف التسجيل في ecxel هو نفسه اسم التسجيل الذي تم تحميله ، ولا يمكن إعادة تسميته",
            tip2: "2. يقوم النظام بفحص اسم ملف التسجيل ومعرف المكالمة ، وسيؤدي التكرار إلى فشل فحص الجودة",
            tip3: "3. تحميل قالب تسجيل excel ، لون الخط أحمر هو الحقل المطلوب",
            tip4: "4. تنسيق الحقل لنوع الوقت هو: yyyy-mm-dd hh:mm:ss ، على سبيل المثال 2021-04-20 17:36:30",
          },
        },
        title: "تحميل التسجيل المحلي",
        checkTitle: "فحص جودة التسجيل",
        start: "بدء فحص الجودة",
        disabledTips: "يرجى التحقق من ملفات التسجيل قبل فحص الجودة",
        tips: "يغطي ملف التسجيل الذي يحمل نفس الاسم نتائج فحص الجودة والمعلومات الأساسية",
        tips1: "اسحب الملف هنا ، أو انقر على تحميل",
        tips2: "الدعم",
        tips3: "تنسيق",
        type: "wav、mp3",
        tips4: "تحميل ما يصل إلى مرة واحدة",
        tips5: "ملف واحد ، لا يتجاوز حجم كل ملف",
        tipsOverSize:
          "قائمة الانتظار الحالية ممتلئة ، يرجى إزالة الملفات الموجودة في قائمة الانتظار قبل تحميلها",
        size: "500M",
        count: "20",
        uploaded: "تم تحميل ملفات التسجيل",
        uploading: "({0}%)",
        uploadFinished: "(اكتمال التحميل)",
        bottomTitle: "جمع معلومات التسجيل:",
        recordType: "اختيار شكل التسجيل:",
        double: "تسجيل المكالمات ذات القناة المزدوجة",
        single: "تسجيل مكالمة أحادية القناة",
        recordBaseInfo: "تحميل المعلومات الأساسية للتسجيل:",
        addBtn: "إضافة ملف",
        downloadTemplate: "تنزيل قالب. excel",
        checkRecord: "التحقق من معلومات التسجيل",
        checking: "التحقق من ذلك...",
        reCheck: "إعادة التحقق",
        checkAll: "الاختيار الكامل",
        cancelCheckAll: "إلغاء الاختيار الكامل",
        delete: "حذف",
        deleteLotSize: "الحذف بالجملة",
        agentChannel: "قناة خدمة العملاء:",
        left: "القناة اليسرى",
        right: "القناة اليمنى",
        donotKonw: "لا أعرف",
        mission: "اختيار مهمة فحص الجودة:",
        uploadFail: "فشل التحميل",
        repeat: "الملفات المكررة",
        overCount: "عدد الملفات يتجاوز الحد",
        overSize: "حجم الملف يتجاوز الحد",
        uoloadFail: "فشل التحميل إلى السحابة",
        formatError: "خطأ في التنسيق",
        seccess: "التحقق بنجاح",
        fail: "فشل التحقق",
        recordChecking: "فحص جودة التسجيل ، يرجى...",
        recordCheckSuccess: "اكتمل فحص جودة التسجيل",
        recordCheckFail:
          "فشل فحص جودة التسجيل ، يرجى التحقق مما إذا كان التسجيل مكرر أو غير صالح",
        backToIndex: "العودة إلى قائمة التحميل",
        recordCheckIsStarted:
          "يتم تنفيذ مهمة فحص الجودة حاليًا ، يرجى بدء مهمة جديدة بعد انتهاء المهمة الحالية",
      },
      customerServicePortrait: {
        title: "صورة خدمة العملاء",
        tip1: "قانون توزيع طول الجملة: يتم احتساب توزيع طول الجملة حسب عدد الكلمات الطويلة في الجملة ، ويتم حساب خدمة العملاء فقط",
        tip2: "قانون توزيع سرعة الكلام: يتم احتساب توزيع سرعة الكلام حسب سرعة الكلام (كلمة/دقيقة) في أقسام ، ويتم حساب خدمة العملاء فقط",
        tip3: "قانون توزيع وقت الاستجابة: توزيع وقت الاستجابة ، حساب فقط استجابة خدمة العملاء",
        tip4: "الامتثال لفحص الجودة: معدل الامتثال لنقاط فحص الجودة",
        tip5: "متوسط درجات مهام فحص الجودة: متوسط درجات مهام فحص الجودة",
        contol: {
          date: "اختيار التاريخ:",
          today: "اليوم",
          thisMonth: "هذا الشهر",
          lastMonth: "الشهر الماضي",
          thisQuarter: "هذا الربع",
          lastQuarter: "الربع الأخير",
          thisYear: "هذا العام",
          lastYear: "العام الماضي",
          customer: "خدمة العملاء:",
          search: "الاستفسارات",
          reset: "إعادة التعيين",
          export: "تصدير",
          indicator: "إدارة المؤشرات",
          selectPlaceholder: "يرجى اختيار خدمة العملاء التي تحتاج إلى مقارنة",
          warning: "اختر ما يصل إلى 5 عملاء للمقارنة",
          warning2: "يرجى اختيار خدمة عملاء واحدة على الأقل للاستفسار",
          dataWarning:
            "دعم ما يصل إلى 366 يوما بعد الاستعلام عن البيانات من تاريخ البدء",
          seccuss: "سجل التصدير بنجاح",
        },
        echarts: {
          distributionLaw: "قانون التوزيع",
          count: "عدد المرات",
          bout: "مرة واحدة",
        },
        portrait: {
          ability: "أبعاد قدرة خدمة العملاء",
          option: "التكوين",
          abilityOption: "أبعاد القدرة على التكوين",
          placeholder: "القدرة على إدخال أبعاد الكلمات الرئيسية البحث",
          save: "حفظ",
          warning:
            "يرجى اختيار 3 مؤشرات على الأقل للتكوين ، يمكنك اختيار 10 مؤشرات",
        },
        label: { title: "تسميات خدمة العملاء" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "ترتيب خدمة العملاء",
        select: {
          date: "اختيار التاريخ:",
          today: "اليوم",
          thisMonth: "هذا الشهر",
          lastMonth: "الشهر الماضي",
          thisQuarter: "هذا الربع",
          lastQuarter: "الربع الأخير",
          thisYear: "هذا العام",
          lastYear: "العام الماضي",
          group: "مجموعة خدمة العملاء:",
          groupPlaceholder: "يرجى اختيار مجموعة خدمة العملاء",
          customer: "خدمة العملاء:",
          customerPlaceholder: "يرجى اختيار خدمة العملاء",
          search: "الاستفسارات",
          reset: "إعادة التعيين",
          compared: "مقارنة",
          export: "تصدير",
          column: "العمود",
          warning: "اختر ما يصل إلى 5 عملاء للمقارنة",
          warning2: "يرجى اختيار خدمة عملاء واحدة على الأقل",
          dataWarning:
            "دعم ما يصل إلى 366 يوما بعد الاستعلام عن البيانات من تاريخ البدء",
          selected: "خدمة العملاء المختارة:",
          seccuss: "سجل التصدير بنجاح",
        },
        compared: {
          title: "اختيار خدمة العملاء",
          return: "إلغاء",
          compared: "مقارنة",
          tips: "يرجى اختيار 2-5 عملاء للمقارنة",
          warning: "دعم 2-5 خدمة العملاء فقط للمقارنة",
        },
        transfer: { leftTitle: "عناصر القائمة", rightTitle: "عناصر العرض" },
        table: {
          page: "ما مجموعه {0} ، {1} صفحة",
          actionTitle: "العملية",
          action: "عرض",
        },
      },
      conceptWord: {
        title: "الكلمات المفاهيمية",
        new: "كلمة مفهوم جديدة",
        control: {
          search: "البحث:",
          searchPlaceholder: "البحث عن اسم أو كلمة رئيسية",
          commit: "الاستفسارات",
          reset: "إعادة التعيين",
          import: "استيراد",
          export: "تصدير",
          seccuss: "سجل التصدير بنجاح",
        },
        modal: { title: "استيراد الكلمات المفاهيمية" },
        table: {
          sizeChangeText: "عرض لكل صفحة",
          page: "ما مجموعه {0} ، {1} صفحة",
          edit: "تحرير",
          delete: "حذف",
          request: "لن تكون قابلة للاسترداد بعد الحذف ، هل تم تأكيد الحذف ؟",
          cancel: "إلغاء",
          title: {
            conceptWord: "الكلمات المفاهيمية",
            describeWordsNum: "وصف عدد الكلمات",
            updateTime: "وقت التحديث",
            status: "فعالية",
            effective: "فعالة",
            invalid: "غير صالح",
            action: "العملية",
          },
        },
        detail: {
          title: { new: "كلمة مفهوم جديدة", edit: "تحرير الكلمات المفاهيمية" },
          name: "اسم الكلمة المفاهيمية",
          namePlaceholder: "الرجاء إدخال اسم الكلمة المفاهيمية",
          effectiveness: "فعالية",
          effective: "فعالة",
          invalid: "غير صالح",
          description: "وصف الكلمة:",
          descriptionPlaceholder: "الرجاء إدخال كلمة وصف",
          add: "إضافة",
          repeat: "تكرار المحتوى",
          empty: "لا يمكن أن تكون كلمات الوصف فارغة",
          recommendEmpty:
            "لا يوجد المزيد من الكلمات الموصوفة الموصى بها ، يرجى إدخال إضافة يدويًا",
          recommend: "كلمات الوصف الموصى بها الذكية",
          cancel: "إلغاء",
          change: "مجموعة أخرى",
          descriptionSelected: "تمت إضافة كلمة وصف:",
          save: "حفظ",
          success: "نجاح حفظ الكلمة المفاهيمية",
          warning: "لا يمكن أن يكون اسم الكلمة المفاهيمية فارغًا",
          warning2: "يرجى إضافة كلمة وصف واحدة على الأقل",
          submitBtnHandle: {
            title:
              "لم تتم إضافة كلمات الوصف التي تم التحقق منها ، وسيتخلى الحفظ عن كلمات الوصف غير المضافة",
            content:
              'انقر على "موافق" سيعود إلى الصفحة الرئيسية للكلمة المفاهيمية ، هل يمكنك تأكيد المتابعة ؟',
          },
        },
      },
      smartTools: {
        btn: "الأدوات الذكية",
        title: {
          similarWords: "اكتشاف الكلمات ذات الصلة",
          search: "اتبع الكلمات",
          semanticTags: "العلامات الدلالية",
        },
        sentenceSearch: {
          placeholder:
            "يرجى إدخال البحث عن الكلمات الرئيسية ، ودعم كلمات رئيسية متعددة",
          more: "عرض النص الكامل",
          pageUp: "الصفحة السابقة",
          pageDown: "الصفحة التالية",
          total: "ما مجموعه {0} سجل",
        },
        similarDiscover: {
          placeholder: "الرجاء إدخال الكلمات الرئيسية",
          copyed: "تم نسخها",
          none: "لا توجد كلمات ذات صلة ، يرجى تجربة كلمات رئيسية أخرى",
        },
        smartTags: {
          searchPlaceHolder: "الرجاء إدخال كلمة رئيسية أو جملة",
          createSmartTag: "جديد",
          createClusteringSmartTag: "قم ببناء بيانات جديدة مع {0}",
          addFromKeyword: "إضافة محتوى بحث إلى جمل مماثلة",
          hit: "ضرب",
          similar: "مماثلة",
          name: "اسم العلامة الدلالية",
          namePlaceHolder: "يرجى إدخال اسم العلامة الدلالية",
          recallWords: "استدعاء الكلمات",
          recallWordsPlaceHolder: "يرجى إدخال كلمة استدعاء",
          recallRegular: "استدعاء المبادئ",
          recallRegularPlaceHolder: "يرجى إدخال الاستدعاء",
          rules: "القواعد",
          standardSentences: "الجملة القياسية",
          standardSentencesPlaceHolder: "الرجاء إدخال الجملة القياسية",
          similaSentences: "جمل مماثلة",
          similaSentencesPlaceHolder: "يرجى إدخال جملة مماثلة",
          notSaveError: "يرجى حفظ العلامة الدلالية قبل البحث",
          add: "إضافة",
          newPage: "فتح صفحة جديدة",
          querryError:
            "هناك حقول مماثلة في قائمة التعميم (تتشابه التكوينات المتقدمة لإزالة القيود)",
          detailTitle: "تفاصيل العلامات الدلالية",
        },
      },
      trainingCenter: {
        title: "مركز التدريب",
        edit: "تحرير",
        delete: "حذف",
        seleteAll: "الاختيار الكامل",
        cancelCheckAll: "إلغاء الاختيار الكامل",
        add: "إضافة",
        remove: "إزالة",
        save: "حفظ",
        cancel: "إلغاء",
        fastTraining: {
          title: "تدريب سريع",
          titleDes:
            "تم تصميم التدريب السريع للمساعدة في تعميم العلامات الدلالية بسرعة للوصول إلى 20 جمل مماثلة",
          semanticTags: "العلامات الدلالية",
          semanticTagsPlaceholder:
            "الرجاء إدخال عملية البحث عن الكلمات الرئيسية",
          standardSentence: "الجملة القياسية:{0}",
          similarSentencesPlaceholder: "إدخال جملة مماثلة لإضافة أو البحث",
          similarSentencesAdd: "إضافة",
          similarSentencesSearch: "البحث",
          similarSentences: "تمت إضافة جمل مماثلة",
          trainingCount: "عدد التدريب:{0}",
          similarSentencesRecommend: "يوصي جمل مماثلة",
          noResp: "لا توجد علامات دلالية ذات صلة ، يرجى تغيير الكلمات الرئيسية",
          similarSentencesModalTitle: "بحث مماثل",
          moreInfo: "عرض السياق",
          moreInfoBtn: "السياق",
          modalSearchType: {
            all: "كل شيء",
            customer: "خدمة العملاء",
            client: "العملاء",
          },
          repetWarning: "نفس الجمل المتشابهة موجودة بالفعل ، لا تكرر إضافتها",
          repetRemoveTip:
            "حفظ المحتوى المتكرر في الجمل المماثلة ، تمت إعادة الوزن تلقائيًا",
        },
        trainingHistory: {
          title: "تاريخ التدريب",
          select: {
            date: "اختيار الوقت:",
            today: "اليوم",
            thisMonth: "هذا الشهر",
            lastMonth: "الشهر الماضي",
            thisQuarter: "هذا الربع",
            lastQuarter: "الربع الأخير",
            thisYear: "هذا العام",
            lastYear: "العام الماضي",
            search: "الاستفسارات",
            reset: "إعادة التعيين",
          },
          table: {
            time: "الوقت",
            user: "الشخص الذي يعمل",
            tag: "العلامات الدلالية",
            similarCount: "عدد الجمل المتشابهة",
            deleteCount: "إزالة عدد الجمل",
            page: "ما مجموعه {0} ، {1} صفحة",
          },
        },
        classifiedTraining: {
          title: "تصنيف التدريب",
          tagsGrounp: "تجميع العلامات",
          placeHolder: "يرجى اختيار",
          cellTime: "اختيار الوقت",
          search: "الاستفسارات",
          reset: "إعادة التعيين",
          classifiedFlag: "تصنيف التدريب",
          num: "القيم العددية",
          gas: "النسبة المئوية",
          noData: "تدريب غير مصنف",
          noDataHeight:
            "تمثل البيانات غير المصنفة نسبة عالية نسبيًا ، وهناك حاجة ماسة إلى التدريب",
          noDataSmall:
            "تمثل البيانات غير المصنفة نسبة عالية نسبيًا ، ويوصى بالتدريب",
          noDataDays: "آخر تنفيذ {0} قبل أيام",
          training: "التدريب",
          start: "ابدأ",
          addClass: "زيادة التصنيف",
          custom: "الفحص المتقدم",
          marked: "تم وضع علامة على البيانات",
          markedHeight: "لم يتم إجراء خطأ لمدة {0} يومًا ، يوصى بإجراء خطأ",
          markedSmall:
            "لم يتم إجراء أي أخطاء منذ {0} يومًا ، وهناك حاجة ماسة إلى أخطاء",
          mark: "أخطاء",
          dataCheck: "التحقق من البيانات المصنفة",
          dataCheckSmall: "K-L أكثر من 0.5 ، يوصى بالتدريب",
          dataCheckHeight: "K-L أكثر من 0.8 ، هناك حاجة ماسة إلى التدريب",
          check: "التحقق",
          history: "سجل التاريخ",
          historyTime:
            "سيتم تنفيذ العلامات والتعديلات اليومية تلقائيًا في الساعة 2:00 صباحًا",
          today: "علامة اليوم",
          article: "المادة",
          none: "لا شيء",
          historyCheck: "المجموع التاريخي",
          look: "عرض السجلات",
          historyluate: "إعادة حساب البيانات التاريخية",
          startluate: "إعادة الحساب",
          classData: "البيانات المصنفة",
          classStatus: "تقدم التدريب",
          next: "التالي",
          prev: "المادة السابقة",
          finish: "الانتهاء من التدريب",
          stop: "إنهاء التدريب",
          classDetail: "تفاصيل التدريب",
          trainingNone:
            "علامة التبويب الذكية التي لا يمكن تدريبها في الوقت الحالي",
        },
        classifiedTrainingHistory: {
          title: "تاريخ التدريب",
          time: "اختيار الوقت:",
          tag: "نوع العلامة:",
          user: "المشغل:",
          data: "اختيار مصدر البيانات:",
          search: "الاستفسارات",
          searchBar: "البحث:",
          searchBarPlaceholder: "الرجاء إدخال معرف الاتصال/هوية الحوار للبحث",
          reset: "إعادة التعيين",
          export: "تصدير",
          all: "كل شيء",
          select: {
            today: "اليوم",
            thisMonth: "هذا الشهر",
            lastMonth: "الشهر الماضي",
            thisQuarter: "هذا الربع",
            lastQuarter: "الربع الأخير",
            thisYear: "هذا العام",
            lastYear: "العام الماضي",
            search: "الاستفسارات",
            reset: "إعادة التعيين",
          },
          table: {
            user: "المشغل",
            date: "وقت التشغيل",
            data: "مصادر البيانات",
            id: "معرف الاتصال/معرف المحادثة",
            tagType: "مجموعة العلامات الذكية",
            tagName: "اسم العلامة",
            state: "الحالة",
            sizeChangeText: "عرض لكل صفحة",
            page: "ما مجموعه {0} ، {1} صفحة",
            call: "مكالمة هاتفية",
            dialogue: "الحوار",
          },
        },
        clusteringAnnotation: {
          title: "علامة التجميع",
          left: { title: "نتائج التجميع" },
          right: {
            title: "جمل التجميع",
            checkAll: "اختر هذه الصفحة بالكامل",
            tagging: "علامة",
            detail: "عرض",
            meaningless: "تجاهل",
            meaninglessTips:
              "لن يتم عرض نتائج التجميع التي تم تجاهلها لاحقًا ، هل تؤكد أنك تريد تجاهلها ؟",
            cancel: "إلغاء",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "إدارة العملاء",
          tabTitle: "عملاء مركز خدمة العملاء",
        },
        wechatClientManage: {
          title: "الشركات الصغيرة العملاء",
          table: {
            id: "هوية العميل",
            name: "لقب العميل",
            tag: "علامات العملاء",
          },
        },
      },
      collectionRule: {
        title: "قواعد الجمع",
        form: {
          info: "جمع المعلومات الذكية",
          infoPlaceholder: "يرجى اختيار التصنيف",
        },
        table: {
          name: "اسم الحقل",
          props: "خصائص العملاء",
          type: "نوع الحقل",
          desc: "وصف الحقل",
        },
      },
      dataAdd: {
        title: "إضافة البيانات",
        types: { auto: "إضافة تلقائيا", manual: "إضافة يدويا" },
      },
      wechatStaff: {
        table: {
          name: "الاسم",
          part: "القسم",
          phone: "رقم الهاتف المحمول",
          email: "صندوق البريد",
          wechartId: "المؤسسة WeChat ID",
          status: "حالة العمل",
        },
        info: "يرجى أولاً اختيار القسم الذي يحتاج إلى مزامنة البيانات",
        synSuccess: "نجاح المزامنة",
        init: "التهيئة",
        syn: "تزامن",
      },
      appealHistory: {
        appealTime: "وقت الاستئناف",
        cancelTime: "وقت الإلغاء",
        reviewTime: "وقت المراجعة",
        inspector: "مفتش الجودة",
        result: "نتيجة الشكوى",
        complainResult:
          "استأنف ما مجموعه {0} عنصر فحص الجودة ، واستأنف بنجاح {1} ، ورفض {2}",
        reason: "سبب الشكوى",
        rejection: "أسباب الرفض",
        cancel: "الإبطال",
        tip: "تأكيد إلغاء الشكوى ؟",
      },
      appealSdk: {
        time: "من خلال وقت الحوار",
        externalIdCall: "معرف سجل المكالمات",
        externalIdText: "معرف سجل الحوار",
        customerNickName: "لقب العميل",
        readStatus: "التحقق من الحالة",
        status: "حالة الشكوى",
        isManual: "ما إذا كان فحص الجودة اليدوي",
        complainButtonInfo:
          "يُسمح بالاستئناف {0} مرة ، تم استئناف {1} مرة ، ويمكن استئناف {2} مرة المتبقية. {3} الاستئناف قبل",
        submit: "تقديم الشكاوى",
        complain: "الشكاوى",
        allComplain: "جميع الشكاوى",
        pendingSubmit: "لم تقدم بعد",
        taskIds: "مهام فحص الجودة",
        graded: "التقييم",
        complainSuccess: "تقديم الشكاوى بنجاح",
        appealSdkIndex: { title: "نتائج فحص الجودة الذكية" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "الحصول على معلومات عن فشل قائمة المرشحات ، يرجى التحقق من اتصال الشبكة أو حاول مرة أخرى في وقت لاحق!",
      columns: "العمود",
      filter: "الفحص",
      noSearchResult: "لم يتم العثور على أي سجلات",
      pleaseSearch: "يرجى اختيار مرشح ومحاولة البحث",
      sift: "الفحص",
      create: "جديد",
      search: "البحث",
      createTaskTitle: "مهام جديدة",
      title: "العنوان",
      deadline: "الموعد النهائي",
      principal: "المسؤول",
      enqueueTime: "وقت الفريق",
      computeError: "فشل الحساب ، من فضلك في وقت لاحق!",
      sum: "البحث عن السلام",
      max: "الحد الأقصى",
      min: "الحد الأدنى",
      avg: "متوسط القيمة",
      count: "العد",
      maxDate: "الحد الأقصى (الأحدث)",
      minDate: "الحد الأدنى (الأقدم)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "الحصول على عنوان رابط Insight فشل ، يرجى المحاولة مرة أخرى في وقت لاحق!",
      },
      template: {
        learningReminder: "تعلم تذكير",
        subscriptionDynamics: "ديناميكيات الاشتراك",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "إدارة المهام", chaneg: "التبديل" },
        qualityCheckTemplate: {
          text: "قالب فحص الجودة",
          collectText: "قوالب جمع",
          condition: { text: "شروط فحص الجودة" },
          checkPoint: { text: "تكوين القاعدة" },
          automaticAdd: { text: "أتمتة العمليات" },
          manualAdd: { text: "إضافة يدويا" },
          roleConfig: { text: "تكوين الدور" },
          basicInfo: { text: "المعلومات الأساسية" },
          smartWordsLibrary: { text: "قاموس المرادفات الذكية" },
          grade: { text: "تصنيف فحص الجودة" },
          collectionRule: { text: "قواعد الجمع" },
          addData: { text: "إضافة البيانات" },
          collectionList: { text: "قائمة الجمع" },
        },
        qualityCheckWorkbench: {
          text: "فحص الجودة طاولة العمل",
          wechatText: "طاولة العمل التحليلية",
          informationCollectText: "طاولة التجميع",
          qualityInspectionList: {
            text: "قائمة فحص الجودة",
            wechatText: "قائمة التحليل",
            informationCollectText: "قائمة الجمع",
          },
          condition: { text: "قائمة فحص الجودة (مهجورة)" },
          reviewList: { text: "قائمة المراجعة" },
          spotCheckList: { text: "قائمة أخذ العينات" },
          caseBaseList: { text: "مكتبة الحالات" },
          testSetList: { text: "مجموعة اختبار فحص الجودة" },
        },
        statisticsAndReports: {
          text: "الإحصائيات والبيانات",
          wholeReport: { text: "التقرير العام" },
          groupReport: { text: "تقرير مجموعة خدمة العملاء" },
          agentReport: { text: "تقرير خدمة العملاء" },
          customerServiceExecution: { text: "تحليل خدمة العملاء" },
          analysisOverview: { text: "نظرة عامة على التحليل" },
          inspectionItemsAnalysis: { text: "تحليل عناصر فحص الجودة" },
        },
        wechatQa: {
          text: "فحص جودة الشركات WeChat",
          task: { text: "المهام التحليلية" },
          baseInfo: { text: "المعلومات الأساسية" },
          checkPoint: { text: "تحليل تكوين النموذج" },
          setting: { text: "تكوين تحليل WeChat للمؤسسة" },
          automaticAdd: { text: "أتمتة العمليات" },
          manualAdd: { text: "إضافة يدويا" },
        },
        clientCenter: { text: "مركز خدمة العملاء" },
      },
      conditionDictionaries: {
        title: "جميع الشروط",
        searchDefaultText: "الرجاء إدخال المعرف أو الاسم",
        id: "ID",
        name: "اسم",
        conditionDetailedShow: "تتكشف",
        conditionDetailedHide: "ابعد عن هذا",
        remark: "الوصف:",
        checkRole: "الدور:",
        operator: "المشغل:",
        operatorLogic: "المنطق:",
      },
      audioTextView: {
        customer: "العملاء",
        agent: "خدمة العملاء",
        unsupportedFormat: "التنسيق غير مدعوم في الوقت الحالي",
      },
      checkPointClassifications: {
        add: "تصنيف جديد",
        name: "اسم",
        enterToSave: "اضغط على مفتاح العودة للحفظ",
        highestScore: "الحد الأقصى للنتيجة",
        minimumScore: "الحد الأدنى للدرجة",
        defaultMinScore: "الافتراضي هو 0 نقطة",
        minimumScoreTitle: "الحد الأدنى للتسجيل",
        beginScore: "قيمة النتيجة الإجمالية",
        beginScoreTitle: "قيمة التسجيل",
        scoreSetTip: "الحد الأقصى للنتيجة أقل من مجموع النقاط ، يمكنك اختيار:",
        ignoreExcessSpillover: "تجاهل الفائض الزائد",
        automaticWeightCalculation: "المحاسبة التلقائية للأوزان",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "الحقول" },
    checkRange: {
      numberTipOne: "رقم",
      numberTipTwo: "الجملة",
      numberTipThree: "الجملة إلى الأولى",
      conditionLimit: "قيود الشروط",
      roleLimit: "حدود الدور",
      contextLimit: "حدود الجملة",
      notZero: "تقييد الجملة لا يدعم الإدخال 0",
      startMustLessThanEnd:
        "يجب أن يكون موضع الجملة الافتتاحية أقل من موضع الجملة الأخيرة",
    },
    waveAudio: {
      play: "تشغيل",
      pause: "تعليق مؤقت",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "الرجاء إدخال",
      pleaseEnterRegular: "الرجاء إدخال تعبير عادي",
      pleaseEnterGrammar: "الرجاء إدخال القواعد النحوتية",
      pleaseEnterTestText: "يرجى إدخال المحتوى الذي يجب التحقق منه",
      pleaseEnterLoudness: "الرجاء إدخال ديسيبل الصوت",
      pleaseEnterRangeAbility: "يرجى إدخال حجم التغيير",
      afterHit: "تحقق بعد الضربة",
      pleaseEnterContext: "يرجى إدخال بيان مرجعي",
      wordNumber: "فجوة عدد الكلمات",
      intervalSecond: "الفاصل الزمني أكبر من",
      minute: "كل دقيقة",
      wordUnit: "كلمة واحدة",
      test: "اختبار",
      testRegular: "اختبار التعبيرات العادية",
      testGrammar: "قواعد الاختبار",
      testGrammarError: "فشل اختبار القواعد",
      regularError: "التعبيرات العادية خاطئة ، يرجى إعادة إدخالها",
      pleaseEnterSemanticSentence: "يرجى إدخال الجمل التي تتطلب الكشف الدلالي",
      pleaseEnterAssociate:
        "يرجى الرجوع إلى التعليمات على اليمين لبناء تعبير البيانات",
      querySemanticTags: "اختيار العلامات الدلالية",
      reQuerySemanticTags: "إعادة اختيار العلامات الدلالية",
      semanticTags: "العلامات الدلالية",
      standardSentence: "الجملة القياسية",
      semanticName: "العلامات الدلالية",
      sentence: "الجملة القياسية",
      similarity: "التشابه",
      actions: "العملية",
      tipOne: "لم يتم العثور على التسمية المناسبة ،",
      tipTwo: "إضافة علامة دلالية جديدة",
      notLessThan: "ليس أقل من",
      notGreaterThan: "ليس أكبر من",
      ignoreLessThan: "تجاهل أقل من",
      wordUnitSet: "كلمات الجمل",
      speedWordNotNull:
        "لا يمكن أن يكون الحد الأدنى لعدد الكلمات والحد الأقصى لعدد الكلمات في الكشف عن سرعة الكلام فارغًا",
      speedWordMinValueLessThanMaxValue:
        "لا يمكن أن يكون الحد الأدنى لعدد الكلمات للكشف عن سرعة الكلام أكبر من الحد الأقصى لعدد الكلمات",
      speechGrabbingIntervalTip: "الفاصل الزمني للمكالمات> =",
      speechLengthTip: "جمل طويلة> =",
      msUnit: "ms",
      sUnit: "ثواني",
      grabIntervalNotNull: "لا يمكن أن تكون فترة الاندفاع فارغة",
      grabLengthNotNull: "لا يمكن أن تكون الجملة طويلة",
      pleaseSelectEntity: "يرجى اختيار كيان المعلومات",
      pleaseSelectOperator: "يرجى اختيار العملية",
      pleaseSelectBasicInfo: "يرجى اختيار المعلومات الأساسية",
      pleaseEnterFixedValue: "يرجى إدخال قيمة ثابتة",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "نجاح المطابقة",
      matchError: "فشل المطابقة",
      mindetectionStatement: "بيان الكشف الأدنى",
      conceptWordholder: "يرجى اختيار الكلمة المفاهيمية",
      elementHolder: "الرجاء إدخال عنصر",
      associateMedol: { tabs: { data: "البيانات", operator: "عامل التشغيل" } },
      repet: "كرر",
      bout: "مرة واحدة",
      ignore: "تجاهل أقل من",
      ignoreAffix: "كلمات الجمل",
      associateData: "البيانات مع الطريق",
      pretreatmentData: "بيانات المعالجة المسبقة",
      reviewTaskData: "مراجعة بيانات البعثة",
      taskData: "بيانات مهمة فحص الجودة",
      businessData: "سجلات العمليات",
      functionOperator: "عامل الوظيفة",
      operation: "عامل التشغيل",
      notAnyMatch: "عدم ضرب أي مشكلة عند:",
      times: "عدد مرات الظهور",
      newAssociateDataTip:
        "استخدم ميزة الإصدار القديم إذا كانت القيمة من النوع الرقمي",
    },
    conditionGather: {
      cycle: "دورة",
      rlue: "القواعد",
      delete: "إزالة",
      sampling: "استخراج",
      item: "المادة",
      percent: "%",
      samplingSame: "نفس الكمية لكل خدمة عملاء",
      samplingRecheck:
        "السماح باستخراج العناصر التي تم أخذ عينات منها بشكل متكرر",
      dateUnit: "رقم",
      samplingTip:
        "لن يتم استخراج نفس المكالمات التي تمت تغطيتها بواسطة قواعد مختلفة بشكل متكرر ، ولن يتم سحب السجلات التي تم استخراجها ولم يتم التحقق منها مرة أخرى.",
      add: "قواعد جديدة",
    },
    ruleGather: {
      title: "القواعد",
      screeningCondition: "شروط الفرز",
      addRule: "قواعد جديدة",
      inspector: "مفتش الجودة",
      ruleTip:
        "وضع التوزيع ذي الأولوية للقواعد ، يتم تخصيصه من أعلى إلى أسفل ، ولن يتم إعادة التوزيع ، وسيتم تخصيص القواعد أو عدم مطابقة بنود القواعد التعسفية بشكل عشوائي",
      moveUp: "الانتقال العلوي",
      moveDown: "تحركت لأسفل",
      top: "أعلى",
    },
    pushRulesGather: {
      title: "القواعد",
      delete: "إزالة",
      newPush: "دفع جديد",
      newRule: "قواعد جديدة",
      sampling: "استخراج",
      item: "المادة",
      percent: "%",
      samplingSame: "نفس الكمية لكل خدمة عملاء",
      dateUnit: "رقم",
      screeningCondition: "شروط الفرز",
      email: "صندوق البريد",
      emailPlaceholder: "أدخل صندوق البريد ، قسّم بفاصلة",
      updateType: "نوع التحديث",
      pushDetail: "دفع التفاصيل",
      pushUrl: "دفع URL",
      pushWay: "نوع الدفع",
      pushMethod: "طريقة الدفع",
      pushContentType: "تنسيق البيانات",
      pushData: "دفع البيانات",
      additionalData: "بيانات إضافية",
      inspectionType: "نوع فحص الجودة",
      all: "كل شيء",
    },
    taskAdditionalData: {
      newData: "بيانات جديدة",
      delete: "إزالة",
      placeholder: "الرجاء إدخال الاسم",
      dataPlaceholder: "الرجاء إدخال المحتوى",
    },
    UdeskQaReact: {
      loadSdkError: "فشل تحميل SDK ، يرجى المحاولة مرة أخرى في وقت لاحق!",
    },
    gearOptionList: { evaluation: "التقييم" },
    tableComponentTransfer: {
      leftTitle: "عناصر القائمة",
      rightTitle: "عناصر العرض",
    },
    kmTinymce: {
      link: "إدراج/تحرير الروابط",
      modalTitle: "إدراج الرابط",
      href: "العنوان",
      text: "عرض النص",
      title: "العنوان",
      target: "فتح الطريق",
      none: "لا شيء",
      newWindow: "فتح في نافذة جديدة",
      buttonStyle: "نمط زر",
      close: "أغلق",
      open: "افتح",
      answerMessage: "القفز/إرسال رسالة",
      answerMessageTitle: "زر إرسال رسالة",
      answerMessageText: "عرض النص",
      answerMessageContent: "محتوى الرسالة",
      answerMessageButtonStyle: "نمط زر",
      answerMessageTips:
        "ملاحظة: زر إرسال رسالة يدعم فقط قنوات الويب ، يرجى تكوين بعناية",
      answerSwitchStaff: "القفز/تحويل العمل",
      answerSwitchStaffTitle: "تحويل الزر اليدوي",
      answerSwitchStaffText: "عرض النص",
      answerSwitchStaffRule: "قواعد الأزرار",
      answerSwitchStaffButtonStyle: "نمط زر",
      answerSwitchStaffTips:
        "ملاحظة: زر التحويل اليدوي يدعم فقط قنوات الويب ، يرجى تكوين بعناية",
      textButton: "زر النص",
      borderButton: "زر مع الإطار",
      message: "إرسال رسالة",
      switchStaff: "نقل العمالة",
      messageError: "لا يمكن أن يتجاوز محتوى الزر 10 كلمات",
      messageIsEmptyError: "لا يمكن أن يكون محتوى الزر فارغًا",
      okText: "تحديد",
      cancelText: "إلغاء",
      video: "فيديو",
      uploadVideo: "تحميل الفيديو",
      uploadText: "دعم تنسيق MP4 فقط ، حجم لا يزيد عن 20M",
      videoEmptyOrUploading: "الفيديو فارغ أو يتم تحميله",
      customEmoji: "التعبير",
    },
    massageConfiguration: {
      pushTarget: "أهداف الإخطار",
      customizePushTarget: "أهداف دفع مخصصة",
      sysUser: "مستخدم النظام الحالي",
      sysRole: "دور النظام الحالي",
      emailPushTarget: "إرسال الأهداف",
      targetEmailsPlaceholder:
        "يرجى إدخال صندوق البريد وفصله بفاصلة باللغة الإنجليزية",
      subject: "اسم البريد",
      SDKID: "معرف التعرف على SDK",
      content: "محتوى الإخطار",
      sdkPushType: "برنامج الدفع",
      sdkPushURL: "دفع URL",
      pushData: "دفع البيانات",
      sdkPushWay: "طريقة الدفع",
      dataType: "تنسيق البيانات",
      additionalData: "بيانات إضافية",
      name: "اسم الرسالة",
      filter: "شروط الفرز",
      triggerType: "آلية الزناد",
      pushCycle: "توقيت التردد الثابت",
      pushContent: "محتوى التقرير",
      qualityList: "قائمة نتائج فحص الجودة",
      designated: "الموظفون المعينون",
      messageType: "نوع الرسالة",
    },
    taskCard: {
      template: { cancelCollection: "إلغاء المجموعة", collection: "مجموعة" },
    },
    semanticTagsSelect: { index: { standardSentence: "الجملة القياسية:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "مجموعة",
          calls: "مكالمة هاتفية",
          createdBy: "مؤسس:",
          createdOn: "إنشاء الوقت:",
        },
        fileItem: {
          uIProvidesIcon: "Ui تقديم icon",
          minute: "دقيقة",
          callClip: "مقاطع المكالمات",
          start: "ابدأ",
          End: "النهاية",
          employees: "الموظفون:",
          Customer: "العملاء:",
          sessionTime: "وقت الجلسة:",
          reasonForRecommendation: "الأسباب الموصى بها:",
          addedBy: "الإضافة:",
          addTime: "إضافة الوقت:",
        },
        modalAddFile: {
          caseTitle: "عنوان القضية",
          addSomeClipsToTheCaseLibrary: "إضافة أجزاء إلى مكتبة الحالات",
          addToCaseBase: "إضافة إلى مكتبة الحالات",
          storedIn: "تخزين في",
          pleaseSelect: "يرجى اختيار",
          reasonsForRecommendation: "أسباب التوصية",
          pleaseEnter: "الرجاء إدخال",
        },
      },
      shareModal: {
        index: {
          customer: "العملاء:",
          employees: "الموظفون:",
          timeliness: "حسن التوقيت:",
          recordingLinkValidWithinDays: "روابط التسجيل خلال أيام صالحة",
          sharingInstructions: "وصف المشاركة:",
          shareSuccess: "مشاركة النجاح!",
          cancel: "إلغاء",
          share: "المشاركة",
          copySucceeded: "نجاح النسخ!",
          copyShareLink: "نسخ رابط المشاركة",
          shareWithColleagues: "شارك مع الزملاء",
          shareWithCustomers: "مشاركة العملاء",
          staff: "الموظفون",
          selectColleagues: "اختيار الزملاء",
          pleaseSelectColleaguesToShare: "يرجى اختيار الزملاء للمشاركة",
          pleaseEnterTheSharingDescription: "يرجى إدخال وصف المشاركة",
          taskName: "المهمة:",
          recordingShareValidWithinDays: "مشاركة الروابط في أيام صالحة",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "لا يمكن العثور على الملف!",
          sale: "المبيعات:",
          customer: "العملاء:",
          topicDetection: "موضوع الكشف:",
          keyEvents: "الأحداث الرئيسية:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "اختيار الزملاء",
          helpContent: "طلب المساعدة",
          visibleRange: "النطاق المرئي",
          seekCounseling: "طلب المشورة",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "طلب المشورة", concatText: "إلى" },
      },
      customerInsight: {
        index: {
          customerInformation: "معلومات العملاء",
          customerInsight: "رؤى العملاء",
          customerLabel: "علامات العملاء",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "توصية الكلام",
          objectionHandlingScripts: "معالجة المعارضة",
          productSellingPointScripts: "تقنية بيع المنتج",
          knowledgePointRecommendation: "نقطة المعرفة الموصى بها",
          relatedKnowledgePoints: "نقاط المعرفة المرتبطة",
          recommendedCases: "الحالات الموصى بها",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "توصية الكلام",
          caseRecommendation: "توصية الحالة",
          hit: "ضرب",
          miss: "لم يضرب",
          supervisionItem: "بند الإشراف",
          implementation: "حالة التنفيذ",
          reInspection: "إعادة فحص الجودة",
          supervisionItemSettings: "الإشراف على إعدادات العنصر",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "المكالمات الهاتفية",
          enterpriseWeChatCall: "مكالمة من شركة Weitong",
          enterpriseMicroConversation: "محادثة الشركات الصغيرة",
          open: "افتح",
          close: "أغلق",
          conversationScenario: "مشهد المحادثة",
          monitorConversationChannels: "قنوات جلسة الإشراف",
          permanentlyValid: "فعالة بشكل دائم",
          permanentlyInvalid: "غير صالح بشكل دائم",
          customValidity: "فترة الصلاحية المخصصة",
          termOfValidity: "فترة الصلاحية",
          newRating: "تصنيف جديد",
          whenConversation: "عندما تكون الجلسة",
          hit: "ضرب",
          miss: "لم يضرب",
          time: "الوقت",
          rated: "التصنيف هو",
          qualified: "مؤهل",
          unqualified: "غير مؤهل",
          modifiedSuccessfully: "مراجعة ناجحة",
          classificationName: "اسم التصنيف",
          supervisionObject: "كائن الإشراف",
          entryintoforceTime: "الوقت الفعلي",
          supervisionItemRating: "تصنيف عنصر الإشراف",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "يرجى التحقق مما إذا كان تصنيف عنصر الإشراف صحيحًا",
          ratingRules: "قواعد التصنيف",
          settingOfRatingRulesForSupervisionItems:
            "الإشراف على إعداد قواعد التصنيف",
        },
      },
      reInspect: {
        index: {
          keyEvents: "الأحداث الرئيسية",
          supervisionItem: "بند الإشراف",
          conversationScenario: "مشهد المحادثة",
          customerLabel: "علامات العملاء",
          reDetectionRules: "قواعد إعادة الكشف",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "تحميل التسجيلات",
          downloadText: "تحميل النص",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "تحميل الصور",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "يرجى اختيار الصور التي لا يزيد حجمها عن 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "أو",
            and: "و",
            wrong: "لا",
            matchTo: "تطابق إلى",
            rule: "القواعد",
            satisfactionOperator: "إرضاء المشغل",
            customerLabel: "علامات العملاء",
            keyEvents: "الأحداث الرئيسية",
            knowledgePoints: "نقاط المعرفة",
            sessionLabel: "علامات التبويب الجلسة",
            supervisionItem: "بند الإشراف",
          },
          linkExplorer: { linkInTheText: "رابط المقال:" },
        },
      },
    },
    createForm: {
      field: { customComponentNotFound: "لم يتم العثور على مكونات مخصصة:" },
    },
    sdkLoaderBase: {
      component: { custom: "التخصيص" },
      dropdownMonit: {
        updateSucceeded: "نجاح التحديث",
        areYouSureToDelete: "هل أنت متأكد من الحذف ؟",
        custom: "التخصيص",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "حفظ تكوين التقرير الحالي === النموذج",
        saveCurrentReportConfiguration: "حفظ تكوين التقرير الحالي",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "يرجى إدخال اسم الإعدادات الشائعة ، والحد الأقصى للطول هو 20!",
        preservation: "حفظ",
      },
      template: {
        saveCurrentReportConfiguration: "حفظ تكوين التقرير الحالي",
        updateCurrentReportConfiguration: "تحديث تكوين التقرير الحالي",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "معلومات ورقة العمل",
        workOrder: "قائمة العمل",
        triggerScenario: "مشهد الزناد",
        associatedCallConversation: "المكالمات/المحادثات المرتبطة",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "اختبار مع النص السابق/الحوار",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "رقم", day: "يا إلهي" },
      businessSummary: {
        normal: {
          equalTo: "يساوي",
          notEqualTo: "لا يساوي",
          contain: "يحتوي على",
          excluding: "لا يحتوي على",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "أنا أنا نفس الألعاب النارية @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Hi ، أنا إشعار بالإنذار المبكر لفحص جودة الروبوت ، لأنه تمت إضافته إلى المجموعة في 17 أكتوبر",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "أنا أنا نفس الألعاب النارية @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Hi ، أنا إشعار بالإنذار المبكر لفحص جودة الروبوت ، لأنه تمت إضافته إلى المجموعة في 17 أكتوبر",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "موضوع الاتصال",
          conversationSummary: "ملخص الجلسة",
          serviceSuggestions: "توصيات الخدمة",
        },
        template: { smartTags: "العلامات الذكية" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "انقر لمعرفة المزيد" },
      schema: {
        textRobot: "نص الروبوت",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "مرافقة المدرب ، سؤال وجواب المستند ، حوار المهمة ، سؤال وجواب الجدول ، سؤال وجواب الخريطة",
        capitalRetentionRobot: "الروبوتات المحتفظ بها",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "استراتيجية غنية للاستجواب والاحتفاظ برأس المال ، والتواصل عالي المجسم ، وضمان ارتفاع معدل الاحتفاظ برأس المال والتحويل التلقائي الفعال للقرائن",
        outboundRobot: "استدعاء الروبوت",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "زيارات العودة لإنهاء أوامر العمل ، واستطلاعات الرضا عن الخدمة ، وتحذيرات أخطاء التشغيل والصيانة ، وتذكيرات تسليم الطلبات ، وإشعارات أنشطة التجارة الإلكترونية ، وتحديد مواعيد المرشحين ، مما يساعد على أتمتة الأعمال وتحسين كفاءة الأعمال",
        incomingRobot: "استدعاء الروبوت",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "تكامل مرن لأنظمة الأعمال مثل أوامر العمل والأوامر لتحقيق أتمتة العمليات ؛ التعرف على النماذج الدلالية الصوتية ، وتأثير الأعمال فوري ؛ الكيان (رقم الهاتف المحمول ، مبلغ الطلب ، وقت الطلب ، العنوان ،...) تحديد ، دعم سريع للأعمال المعقدة",
        intelligentQualityInspection: "فحص الجودة الذكية",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "فحص الجودة الكامل ، في الوقت المناسب وفعال ، معدل دقة 85 ٪",
        intelligentSessionAnalysis: "تحليل الجلسة الذكية",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "نظرة ثاقبة على أوجه القصور في الأعمال التجارية ، والاستماع إلى أصوات العملاء ، وتحسين التحول التسويقي",
        enterpriseWechatConversationAnalysis: "تحليل جلسة WeChat للمؤسسات",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "تحديد الفرص التجارية والمخاطر المحتملة بكفاءة ، وزيادة معدل تحويل التسويق بنسبة 20 ٪",
        assistant: "مساعد مقعد",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "يمكن لمساعد المقعد ضمان خدمة العملاء المبتدئين لإكمال الخدمة وتحسين جودة مكالمات خدمة العملاء الماهرة. اجعل كل مقعد هو الورقة الرابحة الخاصة بك",
        digitalPeople: "الرجل الرقمي",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          'التواصل المجسم "وجهاً لوجه" ، ودعم الصوت ، والنصوص ، والفيديو ، والتفاعل متعدد الوسائط باللمس ، وتحقيق تجربة اتصال غامرة ، وتعزيز صورة العلامة التجارية وتجربة خدمة المستخدم',
        aIKnowledgeZhongtaiEnterpriseSearch:
          "الذكاء الاصطناعي المعرفة في تايوان · البحث عن الشركات",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "البحث عن المعرفة ، تقاسم المعرفة ، البحث عن المستندات ، البحث عن الالتحام في قاعدة البيانات",
        aIKnowledgeCenterKCsKnowledgeBase: "قاعدة المعرفة AI Zhongtai · KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "توفير إدارة دورة الحياة الكاملة للمعرفة ، والصيانة الموحدة للروبوتات وقواعد المعرفة IM ، ودعم ربط قواعد المعرفة التابعة لجهات خارجية ، وقوالب المعرفة ، والمعرفة الذرية والوظائف القوية الأخرى لتوفير تجربة إدارة معرفة أكثر ملاءمة",
        aIKnowledgeCenterKnowledgeMap:
          "معرفة الذكاء الاصطناعي Zhongtai · خريطة المعرفة",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "بناء خريطة وقفة واحدة يدمج معرفة الشركة ، ويعرض الصورة الكبيرة تفاصيل المعرفة ، ويخلق القدرة على الالتحام تجربة مخصصة",
        modularDevelopmentPlatformOriginalEngineASR:
          "منصة تطوير وحدات · محرك القلب الأصلي · ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "اعتماد خوارزمية شاملة لتحقيق تجربة تأثير الأعمال المخصصة ، معدل التعرف على المشهد الطبيعي يتجاوز 90 ٪ ، والتعرف على المفردات الشخصية يتجاوز 97 ٪",
        modularDevelopmentPlatformIntelligentMarketing:
          "منصة تطوير وحدات · التسويق الذكي",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "استخدم خوارزميات التعلم المتعمق ، جنبًا إلى جنب مع صور المستخدم ، لتحقيق توصيات دقيقة للمنتجات ، وتحسين تحويل المبيعات",
        modularDevelopmentPlatformUserPortrait:
          "منصة تطوير معيارية · صورة المستخدم",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "بناء صور المستخدم بدقة بناءً على بيانات المستخدم وبيانات الحوار ، ووصف خصائص العميل بالكامل",
        modularDevelopmentPlatformIntelligentRecommendation:
          "منصة تطوير وحدات · توصية ذكية",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "وفقًا لصورة المستخدم وصورة المنتج ، يوصي المستخدمون تلقائيًا بالمنتجات التي قد تكون مهتمة بها ، وتحقيق توصية شخصية من آلاف الأشخاص",
        modularDevelopmentPlatformNLPPAAS: "منصة تطوير وحدات · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "توفير قدرة أساسية عالية الدقة من NLP ، والتي تغطي القدرات الأساسية للكلمات والجمل والمقالات وغيرها من الجسيمات المختلفة",
      },
    },
    qualityScore: {
      index: {
        show: "عرض:",
        displayBySecondaryClassification: "عرض حسب التصنيف الثانوي",
        showByRule: "عرض وفقا للقواعد",
        pleaseEnterClassificationruleName: "الرجاء إدخال اسم التصنيف/القاعدة",
        clickCopy: "انقر على نسخ",
        hitDetailsOfOperatorsInRules: "تفاصيل إصابة المشغل داخل القاعدة:",
        hit: "ضرب",
        miss: "لم يضرب",
        filter: "الفرز:",
        clickToViewDetails: "انقر للاطلاع على التفاصيل",
      },
      components: {
        flowNodeList: {
          branch: "نقاط",
          pleaseSelect: "يرجى اختيار",
          viewTheCompleteProcess: "عرض العملية الكاملة",
          thereIsCurrentlyNoProcessAvailable: "لا توجد عملية",
        },
        pointItem: { xiangHit: "ضرب العنصر" },
        flowNodeListModal: { index: { completeProcess: "العملية الكاملة" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "إذا تم تغيير مهمة فحص جودة العملية ، فيرجى الاستئناف مرة أخرى.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "تم مسح البيانات بانتظام",
        subject: "الموضوع:",
        description: "الوصف:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "إضافة إلى مهمة فحص الجودة" },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "المكالمات/المحادثات المرتبطة" },
    },
    samplingTarget: {
      index: {
        time: "الوقت",
        samplingQuantityIsLessThan: "عدد عمليات أخذ العينات أقل من",
        alertWhen: "الإنذار المبكر في الوقت",
        everyday: "يوميا",
        weekly: "كل أسبوع",
        monthly: "كل شهر",
        samplingInspectionTargetSavedSuccessfully:
          "تم الحفاظ على هدف أخذ العينات بنجاح",
        personnel: "الموظفون",
        eachCustomerService: "خدمة العملاء لكل",
        cycle: "دورة",
        samplingInspectionTarget: "أهداف أخذ العينات",
        strip: "المادة",
        alertSettings: "إعدادات الإنذار المبكر",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "خدمة العملاء",
        complete: "الانتهاء",
        Objective: "/الهدف",
        everyday: "يوميا",
        weekly: "كل أسبوع",
        monthly: "كل شهر",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "معدل تفاعل الدردشة الجماعية",
        maximumResponseIntervalTime: "الحد الأقصى للفاصل الزمني للرد",
        numberOfPreemptiveCalls: "عدد المكالمات",
        numberOfMessages: "عدد الرسائل",
        strip: "المادة",
        proportionOfMessages: "نسبة عدد الرسائل",
        speechPercentage: "نسبة الكلام",
        longestCustomerTalk: "أطول خطاب العميل",
        numberOfIndepthDiscussions: "عدد المناقشات المتعمقة",
        second: "مرة واحدة",
        maximumDurationOfContinuousSpeech: "أطول وقت للتحدث المستمر",
        questionFrequency: "تكرار الأسئلة",
        customer: "العملاء",
        keyEvents: "الأحداث الرئيسية:",
        opportunity: {
          index: {
            generationFailed: "توليد الفشل",
            generateOpportunityPoints: "توليد نقاط الفرصة",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "توليد الفشل",
            generateSummary: "توليد الملخص",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "هل أنت متأكد من الحذف ؟",
        add: "إضافة",
        opportunityPoints: "فرصة",
        conversationSummary: "ملخص الجلسة",
        cancel: "إلغاء",
        determine: "تحديد",
        edit: "تحرير",
        establish: "إنشاء",
        conversationalInsights: "رؤى المحادثة",
        keyEvents: "الأحداث الرئيسية",
        numKeyEvents: "حدث رئيسي",
        questionAnalysis: "تحليل الأسئلة",
        find: "اكتشاف",
        questions: "سؤال واحد",
        conversationAction: "عمل الجلسة",
        customer: "العملاء",
        sale: "المبيعات",
        sessionLabel: "مشهد المحادثة",
        employeeQuestions: "أسئلة الموظفين",
        customerQuestions: "أسئلة العملاء",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "العملاء",
        customerService: "خدمة العملاء",
        replyTo: "الرد على",
        addressee: "المرسل إليه:",
      },
    },
    listManage: {
      template: {
        reIdentification: "إعادة تحديد الهوية",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "إعادة تحديد الهوية تستهلك وقت النسخ ، يرجى توخي الحذر",
        determine: "تحديد",
        executionSuccessful: "التنفيذ الناجح",
        addToQualityInspectionTask: "إضافة إلى مهمة فحص الجودة",
        batchOperation: "عمليات الدُفعات",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "إضافة النجاح",
        addToQualityInspectionTask: "إضافة إلى مهمة فحص الجودة",
        qualityInspectionTask: "مهام فحص الجودة",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "كشف الدور",
        detectionRange: "نطاق الكشف",
        sentence: "الجملة",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "تم مسح البيانات بانتظام",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "العودة إلى نظام الأعمال" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "استخراج الأسئلة",
        businessSummary: "ملخص الأعمال",
        keyWord: "الكلمات الرئيسية",
      },
      components: {
        problemExtraction: {
          no: "لا",
          customerServiceDidNotAnswer: "خدمة العملاء لم تجيب",
          customerServiceHasAnswered: "تم الرد على خدمة العملاء",
          problemUnresolved: "المشكلة لم تحل",
          theProblemHasBeenResolved: "تم حل المشكلة",
          customerIssue: "مشاكل العملاء:",
          customerServiceAnswer: "إجابات خدمة العملاء:",
          reExtractQuestions: "إعادة استخراج الأسئلة",
          clickToExtractQuestions: "انقر على استخراج الأسئلة",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "اسم الصورة",
            backgroundDescription: "وصف الخلفية",
            customerLabel: "علامات العملاء",
            labelManagement: "إدارة العلامات",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "جديد" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "اسأل عن الإعدادات" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "زيادة الاستجواب",
              remove: "إزالة",
              addQuestioning: "إضافة الاستجواب",
              section: "رقم",
              secondInquiry: "السؤال الثاني",
              questioningScore: "اسأل عن النتيجة",
            },
          },
        },
        index: {
          accordingToRules: "حسب القواعد",
          byScore: "اضغط على النتيجة",
          whenMissRule: "عندما لا تضرب القواعد",
          whenConductingQuestioning: "في ذلك الوقت ، اسأل",
          pleaseSelectARule: "يرجى اختيار القواعد",
          ruleCannotBeEmpty: "القواعد لا يمكن أن تكون فارغة",
          currentScriptScore: "عندما يسجل الكلام ≤",
          pleaseEnterTheScore: "الرجاء إدخال النتيجة",
          scoreCannotBeEmpty: "لا يمكن أن تكون النتيجة فارغة",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "الراوي:" },
          pPT: { slide: "عرض الشرائح" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "تعليقات الكلام",
              script: "الكلام",
              semantics: "دلالات",
              violatingWords: "كلمة انتهاك",
              speechSpeed: "سرعة الكلام",
              emotion: "العواطف",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "تعليقات الكلام",
              regenerate: "إعادة توليد",
              generate: "توليد",
              hitSituation: "ضرب الوضع",
              noSemanticTagsMatched: "غير متطابق إلى علامة الدلالية",
              matchToSemanticTags: "تطابق العلامات الدلالية",
              appealToBeSubmitted: "الشكوى معلقة",
              ratingHasBeenReviewed: "تمت مراجعة التسجيل",
              ratingAppealInProgress: "درجة الاستئناف",
              expressionScore: "درجة التعبير",
              moodParticlesTimes: "كلمات الغاز ، {0} مرات",
              changeTheModalParticlesTo: "تم تغيير كلمات الصوت إلى",
              second: "مرة واحدة",
              interrogation: "اسأل",
              questioningWithoutScoring: "لا يتم تسجيل الأسئلة",
              branch: "نقاط",
              matchingRate: "معدل المطابقة",
              ratingAppeal: "درجة الاستئناف",
              changeTo: "يستعاض عنها بما يلي:",
              wordPerMinute: "كلمة كل دقيقة",
              completeScript: "الكلام الكامل",
              incompleteScript: "الكلام غير مكتمل",
              semanticHit: "ضرب الدلالي",
              semanticMisses: "دلالات غير ضرب",
              scriptViolation: "مخالفات الكلام",
              modified: "تم تعديله",
              notChanged: "لم يعدل",
              keyWord: "الكلمات الرئيسية",
              semanticLabels: "العلامات الدلالية",
              semanticMatchingRate: "معدل المطابقة الدلالية",
              violatingWords: 'كلمة مخالفة"',
              delete: "حذف",
              unmatched: "غير متطابق",
              matching: "تطابق",
              matchTo: "تطابق إلى",
              notMatchedTo: "لم تتطابق مع",
              scriptScore: "درجة الكلام",
              standardScript: "الكلام القياسي",
              emotionalScore: "النتيجة العاطفية",
              speechSpeedScore: "درجة سرعة الكلام",
              viewResolution: "عرض التحليل",
              nodeConfiguration: "تكوين العقدة",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "وقت مهلة الإجابة على هذا السؤال ، قم تلقائيًا بتقديم محتوى الإجابة",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "وقت مهلة الإجابة على هذا السؤال ، قم تلقائيًا بتقديم محتوى الإجابة",
          },
          correctBtn: {
            saveSuccessful: "حفظ النجاح",
            errorCorrection: "تصحيح الخطأ",
            cancel: "إلغاء",
            preserve: "حفظ",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "قواعد التهديف",
            cancel: "إلغاء",
            determine: "تحديد",
          },
          scoringRuleItem: {
            whole: "كل شيء",
            arbitrarily: "تعسفي",
            pleaseEnterARuleName: "الرجاء إدخال اسم القاعدة",
            standardScript: "الكلام القياسي",
            if: "إذا",
            and: "و",
            or: "أو",
            thenScore: "ثم النتيجة",
            pleaseEnterAScore: "يرجى إدخال النقاط",
            bonusPoints: "نقاط إضافية",
            minusPoints: "تخفيض النقاط",
            hit: "ضرب",
            misses: "لم يضرب",
            keyWord: "الكلمات الرئيسية",
            semantics: "دلالات",
          },
          title: {
            ruleValidation: "التحقق من القواعد",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "قواعد التسجيل (النتيجة الإجمالية الحالية للكلمات هي",
            points: "(نقاط)",
            editRules: "قواعد التحرير",
          },
        },
      },
      userTagsSelect: {
        index: { noLabelsCurrentlyAvailable: "لا يوجد تسميات في الوقت الحالي" },
      },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "أهداف الاتصال",
              scoringCriteria: "معايير التسجيل",
            },
          },
        },
        index: {
          generating: "توليد",
          generateDialogueFramework: "إنشاء إطار للحوار",
          theme: "الموضوع",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "تذكير",
            generatePromptLanguage: "توليد تذكير",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "مرحلة الاتصال",
          communicationObjectives: "أهداف الاتصال",
          scoringCriteria: "معايير التسجيل",
          suggestionsForImprovement: "توصيات الترقية",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "نتيجة العملية",
            contentIntegrity: "اكتمال المحتوى:",
            NumberOfCompletedProcesses:
              "النسبة المئوية ، عدد العمليات المكتملة",
            keyHitPointsTotal: "ضرب النقاط الرئيسية: المجموع",
            notReached: "لم يتم الوصول إليها",
            accurateFollowupAccumulationOfStandardScripts:
              "تابع القراءة بدقة: تراكم الكلام القياسي",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "في كلمة واحدة ، اتبع الطلاب عدد الكلمات التي قرأتها",
            aWord: "كلمة واحدة",
            languageNormsYes: "معايير اللغة: هناك",
            emotionsAppearing: "العواطف: تظهر",
            speechSpeedSpeakingTooFast: "سرعة الكلام: تحدث بسرعة كبيرة",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "الكلمات الهوائية: تحدث الكثير من الكلمات الهوائية",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "إضافة إلى تجاهل علامات التبويب",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "هل أنت متأكد من إعادة تطبيق العلامة الذكية ؟",
        reExecute: "إعادة التنفيذ",
      },
      component: { successfullyAdded: "إضافة النجاح" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "إغلاق جميع الإشعارات",
        reQualityInspectionTask: "مهمة إعادة فحص الجودة",
        requalityInspection: "إعادة فحص الجودة",
        waiting: "في انتظار",
        viewDetails: "عرض التفاصيل",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "وقت الكشف:" } },
        },
        template: { responseOpeningSentence: "الجملة الافتتاحية للرد" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "توليد الفشل", generate: "توليد" },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "لا توجد بيانات" } },
      },
      index: { regenerate: "إعادة توليد" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "هل أنت متأكد من الحذف ؟",
        operationSuccessful: "عملية ناجحة",
      },
      template: { delete: "حذف" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "يرجى اختيار الأسئلة!",
        similarQuestionRecommendation: "توصية مماثلة",
        selectAllOnPage: "اختيار جميع الصفحات",
        cancel: "إلغاء",
        preserve: "حفظ",
        changeBatch: "دفعة أخرى",
      },
    },
    listControl: { index: { columnControl: "التحكم في العمود" } },
    loginUserAvatar: { index: { switch: "التبديل" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "عند إضافة البيانات في الوقت الفعلي ، لا يزال تنفيذ مهام فحص الجودة يستغرق وقتًا طويلاً. يختلف الوقت المحدد من بضع دقائق إلى ساعتين ، اعتمادًا على حجم بيانات فحص الجودة.",
      },
    },
    bEditor: {
      index: {
        delete: "حذف",
        cancel: "إلغاء",
        confirm: "تحديد",
        insert: "إدراج",
        width: "العرض",
        height: "الارتفاع",
        clearContent: "مسح المحتوى",
        revoked: "الإبطال",
        redo: "إعادة",
        fontSize: "حجم الخط",
        rowHeight: "شينغ قاو",
        wordSpacing: "تباعد الكلمات",
        paragraphIndent: "الفقرة المسافة البادئة",
        increaseIndent: "زيادة المسافة البادئة",
        decreaseIndent: "تقليل المسافة البادئة",
        border: "الحدود",
        color: "اللون",
        textColor: "لون النص",
        backgroundColor: "لون الخلفية",
        temporaryColor: "الألوان المؤقتة",
        bold: "جريئة",
        italic: "مائل",
        underline: "تسطير",
        strikethrough: "حذف الخط",
        font: "الخطوط",
        textAlignment: "محاذاة النص",
        left: "على اليسار",
        centered: "في الوسط",
        right: "على اليمين",
        bothEnds: "كلا الطرفين",
        leftFloat: "يطفو اليسار",
        rightFloat: "تعويم اليمين",
        subrupt: "العطاء العلوي",
        subscript: "عرض",
        clearStyle: "مسح النمط",
        title: "العنوان",
        regular: "الروتين",
        orderedList: "قائمة مرتبة",
        unorderedList: "قائمة غير منظمة",
        reference: "الاقتباس",
        code: "رمز",
        link: "الروابط",
        clearLink: "مسح الروابط",
        horizontalLine: "خط أفقي",
        media: "وسائل الإعلام",
        mediaLibrary: "مكتبة الوسائط",
        smallExpression: "تعبير صغير",
        fullScreen: "كامل الشاشة",
        exitFullScreen: "الخروج من الشاشة الكاملة",
        enterLinkText: "أدخل نص الرابط",
        enterLinkAddress: "أدخل عنوان الرابط",
        enterTheLinkAddressAndPressEnter:
          "أدخل عنوان الرابط والعودة إلى السيارة",
        openInNewWindow: "فتح في نافذة جديدة",
        removeLink: "إزالة الروابط",
        playingAudioFiles: "تشغيل الملفات الصوتية",
        playVideoFiles: "تشغيل ملفات الفيديو",
        embeddedMedia: "الوسائط المدمجة",
        image: "الصور",
        video: "فيديو",
        audio: "الصوت",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "اسم",
        description: "الوصف",
        type: "نوع",
        status: "الحالة",
        actions: "العملية",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "اسم المهمة",
        description: "الوصف",
        form: "نموذج فحص الجودة",
        actions: "العملية",
        createUser: "مؤسس",
      },
      samplingRecords: {
        createTime: "الوقت",
        type: "نوع",
        operateUser: "الشخص الذي يعمل",
        number: "إضافة الكمية",
        filterConditionList: "شروط الفرز",
        sampleRuleStr: "شروط أخذ العينات",
        timeGreaterThan: "الوقت أكبر من",
        timeLessThan: "الوقت أقل من",
      },
    },
    customFilters: {
      createdAt: "وقت التحدث",
      customerId: "العملاء",
      agentId: "خدمة العملاء",
      userGroupId: "مجموعة خدمة العملاء",
      state: "الحالة",
      lastEvaluateUserId: "الشخص الأخير",
      submitTime: "وقت التقديم النهائي",
      totalScore: "مجموع النقاط",
      formItemForbidIdList: "المحظورات",
      formItemDeadlyIdList: "العناصر القاتلة",
    },
    samplingInspection: {
      callTime: "وقت التحدث",
      dialogTime: "وقت الحوار",
      originator: "تعيين الناس",
      createTime: "إنشاء الوقت",
      updateTime: "وقت التحديث",
      status: "حالة العملية",
      score: "النتيجة",
      actions: "العملية",
      reviewer: "المراجع",
      samplingOfficer: "مفتشي العينات",
      id: "معرف سجل المكالمات",
      time: "وقت التحدث",
      phone: "هاتف العميل",
      staff: "خدمة العملاء",
      inspectionStatus: "حالة فحص الجودة",
      samplingStatus: "حالة أخذ العينات",
      inspectionGrade: "تصنيف",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "الكلمات القياسية",
        synonymWords: "مرادفات",
        synonymWordsNumber: "عدد المرادفات",
        useFrequency: "تردد الاستخدام",
        effectiveness: "فعالية",
        operation: "العملية",
      },
      common: {
        useFrequency: "تردد الاستخدام",
        effectiveness: "فعالية",
        operation: "العملية",
      },
      professionalWords: {
        professionalWords: "المصطلحات المهنية",
        useFrequency: "إحصاءات الاستخدام",
        homophonic: "الكلمات الساخنة (استبدال متجانس)",
        target: "استبدال الاسم",
        targetWords: "كلمات بديلة",
        homophonyWords: "الكلمات المحمية المتجانسة",
        operation: "العملية",
      },
      attentionWords: "التركيز على الكلمات",
      ignoreWords: "تجاهل الكلمات",
      wordsWeight: {
        selfDefineWeightWords: "كلمات الوزن المخصصة",
        weightWords: "كلمة الوزن",
        weight: "الأوزان",
        useFrequency: "تردد الاستخدام",
        effectiveness: "فعالية",
        operation: "العملية",
      },
      corpusData: {
        corpusDescription: "وصف",
        updateTime: "وقت التحديث",
        wordsCount: "عدد الكلمات",
        effectiveness: "فعالية",
        operation: "العملية",
        corpusContent: "المحتوى",
      },
    },
    semanticTags: {
      title: "العلامات الدلالية",
      tags: "الجملة القياسية",
      orgName: "المنظمة التي تنتمي إليها",
      tagCount: "عدد التدريب",
      usingCount: "عدد الاستشهادات",
      activeFlag: "فعالية",
      suggestion: "توصيات التدريب",
      actions: "العملية",
    },
    intelligentTags: {
      tagName: "اسم",
      tagDesc: "وصف",
      tagRefCnt: "عدد العلامات",
      categoryName: "تصنيف العلامات",
      eventName: "تصنيف الأحداث",
      tagStatus: "فعالية",
      actions: "العملية",
      tagType: "نوع العلامة",
    },
    dataPreprocess: {
      name: "اسم",
      desc: "وصف",
      dataSource: "قيود مصدر البيانات",
      type: "نوع",
      status: "فعالية",
      actions: "العملية",
    },
    informationEntities: {
      name: "اسم",
      apiName: "اسم API",
      type: "نوع",
      remark: "الوصف",
      activeFlag: "فعالية",
      actions: "العملية",
    },
    interactiveRecognition: {
      questionAndAnswer: "الأسئلة القياسية & الأجوبة",
      type: "نوع",
      status: "الحالة",
      actions: "العملية",
      question: "السؤال",
    },
    role: { id: "ID", name: "اسم", actions: "العملية" },
    uploadDownload: {
      taskName: "اسم الملف",
      createTime: "التاريخ",
      size: "الحجم",
      createUserName: "المشغل",
      status: "الحالة",
      result: "النتائج",
      actions: "العملية",
    },
    taskCenterList: {
      name: "محتوى المهمة",
      createTime: "وقت التقديم",
      startTime: "وقت البدء",
      endTime: "نهاية الوقت",
      createUserName: "المشغل",
      status: "الحالة",
      result: "النتائج",
      actions: "العملية",
    },
    customerInformation: {
      title: "معلومات خدمة العملاء",
      data: "عرض البيانات غير الطبيعية فقط",
      list: {
        agentNo: "رقم العمل/معرف خدمة العملاء",
        agentName: "خدمة العملاء",
        agentTel: "الهاتف",
        agentEmail: "صندوق البريد",
        updateTime: "وقت التحديث",
        actions: "العملية",
      },
      upload: {
        second:
          "2. اضغط على زر الاستيراد لتحميل ملف EXCEl الذي يتوافق مع تنسيق القالب",
      },
    },
    IndexManagement: {
      title: "إدارة المؤشرات",
      list: {
        indicatorName: "اسم",
        indicatorDesc: "الوصف",
        activity: "فعالية",
        inspectDataSource: "مصادر البيانات",
        sysType: "نوع",
        agentGroups: "مجموعة خدمة العملاء",
        actions: "العملية",
      },
      detail: {
        nameEmpty: "لا يمكن أن يكون الاسم فارغًا",
        descript: "الوصف",
        descriptEmpty: "الوصف لا يمكن أن يكون فارغا",
        active: "فعالية",
        activeEmpty: "لا يمكن أن تكون الفعالية فارغة",
        dataSource: "مصادر البيانات",
        dataSourceEmpty: "لا يمكن أن تكون مصادر البيانات فارغة",
        grounp: "مجموعة خدمة العملاء",
        grounpEmpty: "لا يمكن أن تكون مجموعة خدمة العملاء فارغة",
        grounpHolder: "يرجى اختيار مجموعة خدمة العملاء",
        type: "طريقة التسجيل",
        typeNone: "لا يمكن أن تكون طريقة التسجيل فارغة",
        base: "النقاط الأساسية",
        baseNone: "لا يمكن أن تكون النقاط الأساسية فارغة",
        positive: "العوامل الإيجابية",
        positiveNone: "لا يمكن أن تكون العوامل الإيجابية فارغة",
        customAdd: "إضافة فحص",
        negative: "العوامل السلبية",
        negativeNone: "لا يمكن أن تكون العوامل السلبية فارغة",
        customOK: "شروط الفحص:",
        judgeStrategy: "قواعد الشروط:",
        other: "عوامل أخرى",
        otherAdd: "إضافة عوامل",
        agent: "البيانات الخام",
        section: "عدد الفترات",
        sectionNone: "لا يمكن أن يكون عدد الفترات فارغًا",
        step: "خطوات الفاصل",
        stepNone: "خطوات الفاصل",
        save: "حفظ",
        cancel: "إلغاء",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "كل شيء",
      staff: "فقط نفسي",
      department: "هذا القسم وما دون",
    },
    gongStatisticsDate: { week: "الأسبوع", month: "الشهر", season: "الموسم" },
    gongCoachTaskStatus: {
      wait: "في انتظار الاستشارة",
      already: "تم تقديم المشورة",
      ignore: "تم تجاهله",
    },
    callRangeStatus: {
      all: "جميع المكالمات",
      sale: "بدأ الموظف",
      customer: "بدء العملاء",
    },
    todoListType: {
      later: "استمع لاحقا",
      help: "المساعدة الاستشارية",
      contact: "اتصل في وقت لاحق",
      send: "إرسال المعلومات",
      warn: "الإنذار المبكر بالمخاطر",
    },
    frequencyTypes: {
      everyDay: "يوميا",
      everyWeek: "كل أسبوع",
      everyMonth: "كل شهر",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "تدريب البيانات غير المصنفة",
        markedDataCorrigenda: "تم وضع علامة على البيانات",
        classifiedDataCheck: "التحقق من البيانات المصنفة",
        markedHistoryRecord: "سجل التاريخ",
        historyDataRecalculate: "إعادة حساب البيانات التاريخية",
      },
      dataExtractMode: {
        random: "عشوائي تماما",
        intelligentRecommend: "توصية ذكية",
        custom: "التخصيص",
        specifiedClass: "تصنيف معين",
      },
      smartRatesList: {
        markRate: "معدل وضع العلامات",
        corrigendaRate: "معدل الخطأ",
        kl: "تناثر K-L",
        signalNoiseRate: "نسبة الإشارة إلى الضوضاء",
      },
    },
    visibleRangeTypes: {
      all: "كل شيء",
      customerGroup: "مجموعة خدمة العملاء",
      userDefined: "التخصيص",
    },
    samplingRateTypes: { everyDay: "أخذ كل يوم", everyWeek: "استخراج أسبوعي" },
    taskRemindTypes: {
      confirm: "إرسال تذكير عند الحاجة إلى تأكيد سجل مهمة فحص الجودة",
      appeal: "سجل مهمة فحص الجودة عند بدء الاستئناف ، أرسل تذكيرًا",
    },
    weekDays: {
      monday: "الاثنين",
      tuesday: "الثلاثاء",
      wednesday: "الأربعاء",
      thursday: "الخميس",
      friday: "الجمعة",
      saturday: "السبت",
      sunday: "الأحد",
    },
    reportDateTypes: {
      dailyReport: "التقرير اليومي",
      weeklyReport: "التقرير الأسبوعي",
    },
    samplingRangeDayTypes: { today: "اليوم", yesterday: "بالأمس" },
    samplingRangeWeekTypes: {
      thisWeek: "هذا الأسبوع",
      lastWeek: "الأسبوع الماضي",
    },
    samplingRuleTypes: {
      proportion: "أخذ العينات التناسبية",
      random: "أخذ العينات العشوائية",
    },
    taskStatusTypes: {
      waitEvaluate: "في انتظار التقييم",
      waitSubmit: "لم تقدم بعد",
      waitConfirm: "ليتم تأكيدها",
      waitReview: "في انتظار المراجعة",
      completed: "أنجز",
      all: "كل شيء",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "التقييم المباشر",
        weightedScore: "درجة الوزن",
        deductScore: "خصم النقاط",
        directScoreSecondLevel: "التقييم المباشر-المستوى الثاني",
        weightedScoreSecondLevel: "درجة الوزن-المستوى الثاني",
        deductScoreSecondLevel: "خصم النقاط-الدرجة الثانية",
      },
    },
    fieldOperators: {
      is: "يساوي",
      not: "لا يساوي",
      contains: "يحتوي على",
      notContains: "لا يحتوي على",
      not_contains: "لا يحتوي على",
      containsAny: "يحتوي على تعسفي",
      contains_any: "يحتوي على تعسفي",
      is_any: "يحتوي على تعسفي",
      not_any: "لا يحتوي على أي تعسفي",
      notContainsAny: "لا يحتوي على أي تعسفي",
      not_contains_any: "لا يحتوي على أي تعسفي",
      containsAll: "يحتوي على جميع",
      contains_all: "يحتوي على جميع",
      notContainsAll: "لا يحتوي على كل",
      nont_contains_all: "لا يحتوي على كل",
      isNull: "فارغة",
      is_null: "فارغة",
      isNotNull: "ليس فارغا",
      is_not_null: "ليس فارغا",
      greaterThan: "أكبر من",
      greater_than: "أكبر من",
      greaterThanEq: "أكبر من يساوي",
      greater_than_eq: "أكبر من يساوي",
      lessThan: "أقل من",
      less_than: "أقل من",
      lessThanEq: "أقل من يساوي",
      less_than_eq: "أقل من يساوي",
      between: "بين",
      notBetween: "ليس بين",
      not_between: "ليس بين",
      relative: "يساوي",
      set: "تعيين",
      belongs: "ينتمي",
      notBelongs: "لا تنتمي",
      not_belongs: "لا يساوي",
      today: "اليوم",
      yesterday: "بالأمس",
      the_day_before_yesterday: "أول من أمس",
      tomorrow: "غدا",
      next_seven_day: "الأيام السبعة القادمة",
      last_seven_day: "الأيام السبعة الماضية",
      this_week: "هذا الأسبوع",
      next_week: "الأسبوع القادم",
      last_week: "الأسبوع الماضي",
      this_month: "هذا الشهر",
      next_month: "الشهر المقبل",
      last_month: "الشهر الماضي",
      this_year: "هذا العام",
      next_year: "العام القادم",
      last_year: "العام الماضي",
      exact_match: "تطابق دقيق",
      add_value: "قيمة مضافة جديدة",
      remove_value: "حذف القيم",
      set_null: "ضع فارغة",
      isAny: "أي يساوي",
      notAny: "تعسفي لا يساوي",
      belongTo: "ينتمي",
      notBelongTo: "لا تنتمي",
      hasAny: "يحتوي على تعسفي",
      notHasAny: "لا يحتوي على أي تعسفي",
      prefixContains: "بداية تساوي",
      prefixNotContains: "البداية لا تساوي",
      suffixContains: "النهاية تساوي",
      suffixNotContains: "النهاية لا تساوي",
      nextSevenDay: "الأيام السبعة القادمة",
      lastSevenDay: "آخر 7 أيام",
      thisWeek: "هذا الأسبوع",
      nextWeek: "الأسبوع القادم",
      lastWeek: "الأسبوع الماضي",
      thisMonth: "هذا الشهر",
      nextMonth: "الشهر المقبل",
      lastMonth: "الشهر الماضي",
      thisYear: "هذا العام",
      nextYear: "العام القادم",
      lastYear: "العام الماضي",
      after: "في وقت متأخر عن (بما في ذلك اليوم)",
      before: "في وقت سابق (بما في ذلك اليوم)",
      allMatching: "تطابق كل شيء",
      allNotMatching: "كل شيء غير متطابق",
      hit: "ضرب",
      not_hit: "لم يضرب",
    },
    fieldsOperators: {
      is: "يساوي",
      is_null: "في نفس الوقت فارغ",
      head_equal: "بداية تساوي",
      tail_equal: "النهاية تساوي",
      is_not_null: "في نفس الوقت ليست فارغة",
      not: "لا يساوي",
      diff_equal: "الفرق يساوي",
      diff_greater_than: "الفرق أكبر من",
      diff_greater_than_eq: "الفرق أكبر من أو يساوي",
      diff_less_than: "الفرق أقل من",
      diff_less_than_eq: "الفرق أقل من يساوي",
      hour_equal: "في نفس الساعة",
      day_equal: "في نفس اليوم",
      week_equal: "نفس الأسبوع",
      month_equal: "نفس الشهر",
      year_equal: "في نفس العام",
      day: "يا إلهي",
      minute: "دقيقة",
      hour: "ساعات",
      part_equal: "الجزء يساوي",
    },
    samplingColumnCaptions: {
      created_at: "وقت التحدث",
      agent_id: "خدمة العملاء",
      get_direction: "نوع المكالمة",
      call_result: "نتيجة المكالمة",
      survey: "التقييم",
      derived_from_id: "المصدر",
      quit_queue_why: "حالة قائمة الانتظار",
      queuing_duration: "الانتظار في الطابور يستغرق وقتا طويلا",
      talking_seconds: "طول المكالمة",
      ringing_seconds: "وقت الرنين",
      hangup_by: "الطرف الذي يعلق المكالمة",
    },
    callTypes: {
      callIn: "استدعاء",
      callOut: "صرخة",
      threeParty: "(الأطراف الثلاثة)",
      consultation: "(المشورة)",
      insert: "(إدراج قوي)",
      monitor: "(مراقبة)",
      transfer: "(نقل)",
      intercept: "(اعتراض)",
      transferOutside: "(تحويل الخط الخارجي)",
      threePartyOutside: "(الخطوط الخارجية الثلاثية)",
      consultingOutside: "(استشارة الخطوط الخارجية)",
    },
    callResults: {
      staffAnswer: "خدمة العملاء الرد",
      staffNotAnswer: "خدمة العملاء لم يتم الرد عليها",
      customerAnswer: "العملاء يجيبون",
      customerNotAnswer: "لم يرد العملاء",
      phoneBusy: "الهاتف مشغولة",
      phoneOffline: "الهاتف غير متصل",
      customerSpeedHangUp: "توقف العملاء بسرعة",
      customerHangUp: "تعليق العملاء",
      queueTimeout: "الانتظار في الطابور لوقت العمل الإضافي",
      queueGiveUp: "التخلي عن الطابور",
      outlineAnswer: "الرد الخارجي",
      outlineNotAnswer: "الخط الخارجي غير متصل",
      noChooseQueue: "قائمة انتظار غير مختارة",
    },
    defaultSurveys: {
      noEvaluation: "لم يتم تقييمها",
      noNeedToEvaluate: "لا حاجة للتقييم",
    },
    queueStates: {
      queueSuccess: "النجاح في الطابور",
      queueTimeout: "الانتظار في الطابور لوقت العمل الإضافي",
      queueGiveUp: "التخلي عن الطابور",
      noStaffOnline: "لا خدمة العملاء عبر الإنترنت",
    },
    callHangers: {
      customer: "العملاء",
      staff: "خدمة العملاء",
      outline: "الخط الخارجي",
    },
    workFlowTypes: {
      noAppealsMode: "نموذج عدم التظلم",
      appealsMode: "نموذج التظلم المسموح به",
    },
    fieldCategories: {
      standardCondition: "شروط الفرز القياسية",
      customerCondition: "شروط الفحص المخصصة من قبل المستخدم",
    },
    sampleTypes: {
      automatic: "أخذ العينات التلقائي",
      manual: "أخذ العينات اليدوية",
    },
    defaultFields: {
      createdAt: "وقت التحدث",
      agentId: "خدمة العملاء",
      getDirection: "نوع المكالمة",
      callResult: "نتيجة المكالمة",
      survey: "التقييم",
      derivedFrom: "المصدر",
      quitQueueWhy: "حالة قائمة الانتظار",
      queuingDuration: "الانتظار في الطابور يستغرق وقتا طويلا",
      talkingSeconds: "طول المكالمة",
      ringingSeconds: "وقت الرنين",
      hangupBy: "الطرف الذي يعلق المكالمة",
    },
    conditionFieldCategories: {
      fixedField: "حقل ثابت",
      customField: "الحقول المخصصة",
      secondLevelScore: "تصنيف المستوى الثاني",
      formItemScore: "عنصر النتيجة",
      forbids: "المحظورات",
      deadlies: "العناصر القاتلة",
    },
    columnFieldCategories: {
      agent: "خدمة العملاء",
      callLog: "سجل المكالمات",
      businessFields: "معلومات الأعمال",
      customer: "العملاء",
      customerCustomFields: "تخصيص العملاء",
      source: "المصدر",
      queue: "قائمة الانتظار",
      fixed: "ثابت",
      secondLevelScore: "تصنيف المستوى الثاني",
      formItemScore: "عنصر النتيجة",
      forbids: "المحظورات",
      deadlies: "العناصر القاتلة",
    },
    taskListDefaultFields: {
      startTime: "وقت التحدث",
      name: "خدمة العملاء",
      state: "الحالة",
      totalScore: "مجموع النقاط",
      formItemForbidList: "المحظورات",
      formItemDeadlyList: "العناصر القاتلة",
      comment: "ملاحظات التقييم",
      inspector: "تصنيف الناس",
      review: "المراجع",
    },
    evaluationStates: {
      initial: "التهيئة",
      waitEvaluate: "في انتظار التقييم",
      evaluated: "لم تقدم بعد",
      waitConfirm: "ليتم تأكيدها",
      waitCheck: "في انتظار المراجعة",
      complete: "أنجز",
    },
    workBenchInfoTypes: {
      callInfo: "معلومات الاتصال",
      businessInfo: "معلومات الأعمال",
      customerInfo: "معلومات العملاء",
    },
    evaluationEventStates: {
      create: "إنشاء",
      valuate: "التقييم",
      edit: "تحرير",
      submit: "المقدمة",
      confirm: "تأكيد",
      appeal: "الشكاوى",
      checkConfirm: "تأكيد المراجعة",
      check: "مراجعة",
    },
    formItemTypes: {
      direct: "التقييم المباشر",
      weight: "الأوزان",
      deduct: "خصم النقاط",
      forbid: "المحظورات",
      deadly: "العناصر القاتلة",
    },
    appealCheckCallTypes: {
      callIn: "استدعاء",
      callBack: "عودة ثنائية الاتجاه",
      callOut: "الاتصال المباشر",
      autoCall: "استدعاء تلقائي",
    },
    appealCheckCallStatus: {
      waitReview: "في انتظار المراجعة",
      reviewed: "تمت مراجعة",
      filed: "تم حفظه",
    },
    samplingStatus: {
      all: "كل شيء",
      unchecked: "لم يتم فحص السحب",
      checked: "تم أخذ عينات منها",
    },
    inspectionStatus: {
      all: "كل شيء",
      unread: "غير مقروء",
      readed: "تمت قراءته",
      appeal: "الشكاوى",
      review: "مراجعة",
      filed: "تم حفظه",
    },
    gradeChooseHits: { yes: "نعم", no: "لا" },
    appealCheckStatus: {
      all: "الحالة الكاملة",
      unread: "غير مقروء",
      inComplaint: "في الشكوى",
      reviewed: "تمت مراجعة",
      readed: "تمت قراءته",
      filed: "تم حفظه",
      spotchecked: "تم أخذ عينات منها",
    },
    flowStatus: {
      unread: "غير مقروء",
      alreadyRead: "تمت قراءته",
      complaining: "في الشكوى",
      reviewed: "تمت مراجعة",
      sampling: "لم يتم فحص السحب",
      samplingCompleted: "تم أخذ عينات منها",
      complainCancel: "الملغاة",
    },
    complainStatus: {
      success: "النجاح",
      fail: "فشل",
      default: "في الشكوى",
      canceled: "الملغاة",
    },
    reviewInspectionStatus: {
      inComplaint: "في الشكوى",
      reviewed: "تمت مراجعة",
    },
    gradeChangeTypes: {
      edit: "تحرير",
      check: "مراجعة",
      file: "أرشفة",
      remark: "ملاحظات",
      complain: "الشكاوى",
      review: "مراجعة",
      reviewEvaluation: "تقييم المراجعة",
      sample: "أخذ العينات",
      sampleEvaluation: "تقييم أخذ العينات",
      sampleSubmit: "تقديم أخذ العينات",
      sampleAssign: "توزيع أخذ العينات",
      reviewAssign: "إعادة النظر في التوزيع",
      read: "تمت قراءته",
      reviewConfirm: "تأكيد المراجعة",
      sampleConfirm: "تأكيد أخذ العينات",
      caseBase: "إضافة إلى مجموعة الحالات",
      sampleCancel: "أخذ العينات إلغاء التوزيع",
      reviewCancel: "مراجعة إلغاء التوزيع",
      sampleAgain: "إعادة توزيع أخذ العينات",
      reviewAgain: "إعادة توزيع المراجعة",
      btachDelete: "حذف سجلات أخذ العينات",
      rapidSampling: "أخذ عينات سريعة",
      reInspection: "إعادة فحص الجودة",
      smartTagsChange: "تغيير العلامة الذكية",
      cancelAppeal: "سحب الشكوى",
    },
    appealTypes: {
      transliterationError: "أخطاء النسخ",
      discriminationError: "خطأ في الحكم",
      otherError: "أخطاء أخرى",
    },
    inspectDataSources: {
      voiceCall: "المكالمات الصوتية",
      textDialogue: "حوار نصي",
      realTimeVoiceCall: "المكالمات الصوتية في الوقت الحقيقي",
      realTimeTextDialogue: "محادثات النص في الوقت الحقيقي",
      wechatDialogue: "محادثة الشركات الصغيرة",
      taobao: "محادثات نص التجارة الإلكترونية",
      ticket: "قائمة العمل",
      wechatRadio: "صوت الشركات الصغيرة",
    },
    inspectTaskType: {
      all: "كل شيء",
      common: "فحص الجودة الروتيني",
      relate: "فحص الجودة ذات الصلة",
    },
    inspectApproveType: {
      all: "كل شيء",
      resolve: "تمت الموافقة عليه",
      resolving: "قيد المراجعة",
      reject: "فشل التدقيق",
    },
    dataSourceFlags: {
      all: "لا قيود",
      voiceCall: "مكالمة هاتفية",
      textDialogue: "الحوار",
    },
    smartWordsLibrary: { effective: "فعالة", invalid: "غير صالح" },
    activeFlags: { effiective: "فعالة", invalid: "غير صالح" },
    labelTypes: {
      dimensionLabel: "وضع العلامات",
      classificationLabel: "تصنيف العلامات",
    },
    pointTypes: {
      automatic: "التقييم التلقائي",
      manual: "التقييم اليدوي",
      interactiveDetection: "الكشف عن التفاعل",
      smartRules: "القواعد الذكية",
      machineLearning: "نموذج التعلم الذاتي",
      intelligentModel: "نموذج ذكي",
    },
    pointGradeTypes: {
      radio: "اختيار واحد",
      input: "إدخال",
      select: "الاختيار",
      all: "تلبية جميع",
      any: "إرضاء التعسفية",
      customize: "التخصيص",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "مطابقة الكلمات الرئيسية",
      question: "سؤال",
      regular: "التعبير العادي",
      context: "تكرار السياق",
      semantics: "المطابقة الدلالية",
      word: "عدد الكلمات متشابهة في الجمل",
      dialogue: "الفاصل الزمني للحوار",
      speed: "اختبار سرعة الكلام",
      grabDetection: "اختبار الاندفاع",
      semanticTags: "العلامات الدلالية",
    },
    applyRoles: {
      all: "كل شيء",
      customer: "العملاء",
      agent: "خدمة العملاء",
      robot: "الروبوتات",
    },
    saleApplyRoles: { all: "كل شيء", customer: "العملاء", agent: "الموظفون" },
    applyOperatorScopes: {
      all: "النص الكامل",
      preCondition: "الشروط المسبقة",
      scope: "تحديد النطاق",
    },
    keywordScopes: {
      all: "الكشف عن جميع الكلمات الرئيسية",
      any: "الكشف عن أي كلمة رئيسية",
    },
    preOperatorHitTypes: {
      first: "المطابقة الأولى",
      every: "كل مباراة",
      last: "آخر مباراة",
      any: "أي مباراة",
      none: "غير متطابق",
    },
    operatorRuleTypes: {
      all: "تلبية جميع",
      any: "إرضاء التعسفية",
      custom: "منطق مخصص",
    },
    informationEntityTypes: {
      system: "النظام",
      custom: "التخصيص",
      moduleCompany: "وحدات",
    },
    organizationTypes: { null: "لا شيء", moduleCompany: "وحدات" },
    customType: {
      smartTag: "استخراج الكيانات",
      scriptTimes: "مقياس الكلام",
      dataCategory: "تصنيف البيانات",
    },
    interactiveQuestionTypes: {
      standard: "أسئلة وأجوبة قياسية",
      multiElement: "أسئلة وأجوبة متعددة العناصر",
      guideMultiRound: "تفاعل متعدد العجلات",
    },
    targetTypes: {
      all: "كل شيء",
      customerService: "خدمة العملاء",
      customer: "العملاء",
    },
    interactiveTypes: {
      similarQList: "مشاكل مماثلة",
      exceptSimilarQList: "استبعاد مشاكل مماثلة",
      similarAList: "إجابات مماثلة",
      wrongAList: "إجابة خاطئة",
    },
    filterTypes: {
      call: "تسجيل المكالمات",
      dialogue: "نص الحوار",
      wechat: "الشركات WeChat",
      wechatAll: "حوار كامل بين الشركات WeChat",
      ticket: "جلسة عمل واحدة",
      taobao: "جلسة التجارة الإلكترونية",
      wechatRadio: "صوت الشركات الصغيرة",
    },
    fieldTypes: {
      keyword: "الكلمات الرئيسية",
      agent: "خدمة العملاء",
      agentGroup: "مجموعة خدمة العملاء",
      sigleListbox: "اختيار واحد",
      multiListbox: "اختيار متعدد",
      text: "نص سطر واحد",
      textArea: "نص متعدد الأسطر",
      dateTime: "الوقت",
      number: "الأرقام",
      voice: "",
      customer: "العملاء",
      fieldWithLink: "الحقول مع الروابط",
      percentage: "النسبة المئوية",
      intelligentClassification: "التصنيف الذكي",
      testSetList: "مجموعة اختبار الحقل الديناميكي",
      inspector: "مفتش الجودة",
    },
    saveTypes: { save: "حفظ" },
    knowledgeType: { oneToOne: "سؤال واحد ، إجابة واحدة" },
    updateTypes: { update: "التحديثات", saveAs: "حفظ بشكل منفصل" },
    recommendItemActionTypes: {
      similar: "مماثلة",
      exclude: "استبعاد",
      ignore: "تجاهل",
    },
    gradeStatus: {
      unread: "غير مقروء",
      alreadyReview: "تمت مراجعة",
      alreadyFiled: "تم حفظه",
      waitReview: "في انتظار المراجعة",
      alreadyRead: "تمت قراءته",
    },
    qualityCheckDetailTypes: { review: "مراجعة", spotCheck: "أخذ العينات" },
    sampleAndReviewStatus: {
      init: "غير مستخرج",
      unread: "غير مقروء",
      alreadyRead: "تمت قراءته",
      complaining: "في الشكوى",
      reviewed: "تمت مراجعة",
      sampling: "لم يتم فحص السحب",
      samplingCompleted: "تم أخذ عينات منها",
    },
    interactiveAnswerTypes: {
      noAnswer: "لم يتم اكتشاف الإجابة",
      answer: "اكتشاف الجواب",
      wrongAnswer: "اكتشاف إجابات خاطئة",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "لم يتم اكتشاف أي مشاكل",
      question: "تم اكتشاف المشكلة",
      eliminateQuestion: "اكتشاف مشكلة الاستبعاد",
    },
    isManual: { yes: "فحص الجودة اليدوي", no: "فحص الجودة غير اليدوي" },
    readStatus: { yes: "تمت قراءته", no: "غير مقروء" },
    reInspectionType: {
      recalcScore: "إعادة حساب الدرجات فقط (بما في ذلك التصنيفات)",
      recheckPoint: "إعادة حساب نقاط فحص الجودة والنتائج",
    },
    common: {
      eCommerceReview: "تعليقات التجارة الإلكترونية",
      socialMediaComments: "تعليقات وسائل الإعلام الاجتماعية",
      customer: "العملاء",
      customerService: "خدمة العملاء",
      robot: "الروبوتات",
      customerInformation: "معلومات العملاء",
      workOrderFields: "حقل ورقة العمل",
      intelligentConversationAnalysis: "تحليل الجلسة الذكية",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "نظرة ثاقبة على أوجه القصور في الأعمال التجارية ، والاستماع إلى أصوات العملاء ، وتحسين التحول التسويقي",
      intelligentAccompanyingPractice: "السجال الذكي",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "إنشاء سجال الذكاء الاصطناعي الغامر الذي يحاكي المشاهد الحقيقية للأعمال التجارية ، وتحقيق إتقان جميع الموظفين للميدالية الذهبية ، وتحسين مهارات الحوار بسرعة",
      intelligentQualityInspection: "فحص الجودة الذكية",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "فحص الجودة الكامل ، في الوقت المناسب وفعال ، معدل الدقة أكثر من 85 ٪",
      salesEmpowerment: "مبيعات التمكين",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "تقوم منظمة العفو الدولية بإعادة تشكيل إدارة المبيعات ، وتحقيق رقمنة عملية الاتصال ، والتصور ، والإدارة الشفافة لكل جزء من المبيعات ، والرؤى الذكية في كل خطوة من خطوات الاتصال ، والتشخيص الدقيق لمشاكل العمل ، وتجربة إعادة المبيعات المثالية",
      algorithmPlatform: "منصة الخوارزمية",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "استنادًا إلى الدلالات الصوتية لمحرك القلب الأصلي الذي تم بحثه ذاتيًا ، تم تخصيص نموذج ASR و NLP ، وتم فتح بيانات مشهد الأعمال ، وتم تحقيق التعلم الذاتي للنظام ، وتم تحقيق الغرض من الاستخدام الأكثر ذكاءً والأكثر دقة.",
      generalSettings: "الإعدادات العامة",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "إدارة الإعدادات الموحدة لأذونات البيانات وأدوار الموظفين",
      callLog: "سجل المكالمات",
      intelligentRules: "القواعد الذكية",
      businessRecords: "سجلات العمليات",
      customFilter: {
        call: "المكالمات الصوتية",
        text: "حوار نصي",
        smartTags: "العلامات الذكية",
        pretreatment: "المعالجة المسبقة",
        smartTagGroup: "مجموعة العلامات الذكية",
        currency: "البيانات العامة",
        wechat: "الشركات WeChat",
        spotCheck: "أخذ العينات",
        personaField: "قائمة العملاء",
        ticket: "جلسة عمل واحدة",
        taobao: "جلسة التجارة الإلكترونية",
        wechatRadio: "صوت الشركات الصغيرة",
        reviewList: "مراجعة",
      },
      talkerRecognitionType: {
        API: "واجهة برمجة التطبيقات تحدد دور المسار",
        text: "التعرف على النص",
      },
      operatorTypes: {
        keyword: "مطابقة الكلمات الرئيسية",
        question: "سؤال",
        regular: "التعبير العادي",
        context: "تكرار السياق",
        semantics: "المطابقة الدلالية",
        word: "عدد الكلمات متشابهة في الجمل",
        dialogue: "الفاصل الزمني للحوار",
        speed: "اختبار سرعة الكلام",
        grabDetection: "اختبار الاندفاع",
        semanticTags: "العلامات الدلالية",
        keywordStrict: "مطابقة الكلمات الرئيسية (صارمة)",
        link: "حقل الارتباط",
        entityInfoDetection: "الكشف عن كيانات المعلومات",
        basicInfoDetection: "الكشف عن المعلومات الأساسية",
        volume: "الكشف عن مستوى الصوت",
        emotion: "تحليل المشاعر",
        grammar: "القواعد النحوبية",
        sentenceLength: "اختبار طول الجملة",
        conceptWord: "الكلمات المفاهيمية",
        questionsAndAnswers: "إجابات قاعدة المعرفة (القديمة)",
        element: "أسئلة وأجوبة العناصر",
        silent: "الاستجابة لمهلة العمل",
        associate: "البيانات مع الطريق",
        repeat: "إرسال مكرر",
        timeInterval: "طول الفاصل الزمني",
        typoDetection: "اكتشاف الأخطاء المطبعية",
        silentOld: "صمت",
        knowledge: "إجابات المعرفة",
        wecomRemark: "ملاحظات شركة العميل",
        customerAddTime: "العملاء إضافة الوقت",
      },
      keywordOperators: {
        all: "تطابق كل شيء",
        allNot: "كل شيء غير متطابق",
        any: "تطابق تعسفي",
      },
      applyRoles: { all: "كل شيء", customer: "العملاء", agent: "خدمة العملاء" },
      sentenceLength: {
        less: "أقل من",
        greater: "أكبر من",
        equal: "يساوي",
        greater_or_equal: "أكبر من يساوي",
        less_or_equal: "أقل من يساوي",
      },
      dialogueApplyScopes: {
        differentRoles: "بين الشخصيات المختلفة",
        agent: "خدمة العملاء",
        customer: "العملاء",
        agentResponse: "رد خدمة العملاء",
        customerResponse: "رد العملاء",
        customerNoResponse: "لا استجابة العملاء",
      },
      applyConditionHitTypes: {
        first: "الضربة الأولى",
        every: "كل ضربة",
        last: "آخر ضربة",
      },
      applyConditionScopes: {
        current: "حاليا",
        before: "قبل",
        after: "بعد",
        beforeAll: "قبل كل شيء",
        afterAll: "بعد كل شيء",
        around: "قريب",
      },
      voiceDemoTypes: {
        singleChannel: "تسجيل مكالمة أحادية القناة",
        dualChannel: "تسجيل المكالمات ذات القناة المزدوجة",
      },
      sendStatus: {
        sending: "إرسال",
        arrive: "تم تسليمها",
        fail: "فشل الإرسال",
        off_sending: "إرسال غير مقروء دون اتصال",
        off_arrive: "تمت قراءته",
        rollback: "تم سحبها",
      },
      collectionMethod: {
        assignment: "التعيين المباشر",
        entity: "استخراج الكيانات",
      },
      systemTypes: { qa: "فحص الجودة الذكية", wfm: "جدول ذكي" },
      entityOperators: {
        equals: "يساوي المعلومات",
        notEquals: "لا يساوي المعلومات",
        contains: "يحتوي على معلومات",
        notContains: "لا يحتوي على معلومات",
        equalsFix: "يساوي القيمة الثابتة",
        notEqualsFix: "لا يساوي القيمة الثابتة",
        containsFix: "يحتوي على قيمة ثابتة",
        notContainsFix: "لا تحتوي على قيم ثابتة",
      },
      basicInfoOperators: {
        equals: "يساوي",
        notEquals: "لا يساوي",
        contains: "يحتوي على",
        notContains: "لا يحتوي على",
        isNull: "فارغة",
        isNotNull: "ليس فارغا",
      },
      volumeDetectionWays: {
        loudness: "ديسيبل الصوت",
        rangeAbilitySelf: "نطاق التغيير (مقارنة بجملتي السابقة)",
        rangeAbilityOth: "حجم التغيير (مقارنة الجملة الأولى)",
        rangeAbility3: "حجم التغيير (مقارنة بالجملة نفسها)",
      },
      volumeDetectionOperators: {
        is: "يساوي",
        not: "لا يساوي",
        greaterThan: "أكبر من",
        greaterThanEq: "أكبر من يساوي",
        lessThan: "أقل من",
        lessThanEq: "أقل من يساوي",
      },
      sentimentTypes: {
        positive: "المشاعر الإيجابية",
        negative: "المشاعر السلبية",
        neutral: "المشاعر المحايدة",
        thankful: "شكرا لك",
        happy: "سعيد",
        complaining: "شكوى",
        angry: "غاضب",
        post: "إيجابي",
        negat: "سلبية",
        neut: "محايد",
      },
      emotionOperators: { is: "مطابقة", not: "لا تطابق" },
      propTypes: { system: "النظام", user: "التخصيص" },
      valueTypes: { text: "النص", number: "الأرقام" },
    },
    tasks: {
      allowCustomGrievance: "السماح بتخصيص أسباب الشكوى",
      businessSummary: "ملخص الأعمال",
      theDayBeforeYesterday: "أول من أمس",
      assessActionTypes: { reassess: "إعادة التقييم" },
      scoreType: {
        aiScore: "التقييم الذكي",
        manualScore: "التقييم اليدوي",
        interactiveDetection: "الكشف عن التفاعل",
        smartRules: "القواعد الذكية",
        machineLearning: "نموذج التعلم الذاتي",
      },
      inspectType: {
        inspectTrue: "تحقق من ذلك",
        inspectFalse: "لم يتم التحقق من ذلك",
      },
      operatorType: {
        keyword: "مطابقة الكلمات الرئيسية",
        notKeyword: "عدم تطابق الكلمات الرئيسية",
      },
      applyRole: { all: "كل شيء", customer: "العملاء", agent: "خدمة العملاء" },
      applyScope: {
        all: "كل شيء",
        specific: "الجملة المحددة",
        scope: "تحديد النطاق",
      },
      judgeStrategy: {
        all: "تلبية جميع الشروط",
        arbitrarily: "استيفاء الشروط التعسفية",
        custom: "منطق مخصص",
      },
      checkPointStatus: {
        waitFor: "في انتظار المراجعة",
        notDeductScore: "لا خصم النقاط",
        deductScore: "خصم النقاط",
      },
      applyOptions: { hit: "ضرب الشرط", notHit: "لا توجد شروط محددة" },
      gradeTypes: {
        yesOrNo: "ما إذا كان يفي",
        input: "الإدخال اليدوي",
        level: "تقييم الدرجات",
        multipleFactors: "متعدد العناصر",
      },
      gradeChooseHits: { yes: "نعم", no: "لا" },
      gradeChangeTypes: {
        edit: "تحرير",
        check: "مراجعة",
        file: "أرشفة",
        remark: "ملاحظات",
        complain: "الشكاوى",
        review: "مراجعة",
        reviewEvaluation: "تقييم المراجعة",
        sample: "أخذ العينات",
        sampleEvaluation: "تقييم أخذ العينات",
        sampleSubmit: "تقديم أخذ العينات",
        sampleAssign: "توزيع أخذ العينات",
        reviewAssign: "إعادة النظر في التوزيع",
        read: "تمت قراءته",
        reviewConfirm: "تأكيد المراجعة",
        sampleConfirm: "تأكيد أخذ العينات",
      },
      gradeStatus: {
        unread: "غير مقروء",
        alreadyReview: "تمت مراجعة",
        alreadyFild: "تم حفظه",
        waitReview: "في انتظار المراجعة",
        alreadyRead: "تمت قراءته",
      },
      samplingFrequencyTypes: {
        everyDay: "يوميا",
        everyWeek: "كل أسبوع",
        everyMonth: "كل شهر",
      },
      samplingEveryDayTimeTypes: { yesterday: "بالأمس", today: "اليوم" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "هذا الأسبوع",
        lastWeek: "الأسبوع الماضي",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "هذا الشهر",
        lastMonth: "الشهر الماضي",
      },
      samplingTypes: {
        random: "أخذ العينات العشوائية",
        proportional: "أخذ العينات التناسبية",
      },
      weekdays: {
        Monday: "الاثنين",
        Tuesday: "الثلاثاء",
        Wednesday: "الأربعاء",
        Thursday: "الخميس",
        Friday: "الجمعة",
        Saturday: "السبت",
        Sunday: "الأحد",
      },
      distributeTypes: {
        rule: "تخصيص الأولوية للقواعد",
        average: "متوسط التوزيع العشوائي",
      },
      notMatchAnyRuleTypes: {
        random: "التوزيع العشوائي",
        assign: "تعيين الموظفين المعينين",
        ignore: "لا توزيع",
      },
      inspectorTypes: { all: "جميع مفتشي الجودة", some: "تعيين مفتشي الجودة" },
      pushFrequencyTypes: {
        everyDay: "يوميا",
        everyWeek: "كل أسبوع",
        everyMonth: "كل شهر",
        inspectionUpdate: "تحديث فحص الجودة",
        timelyInspection: "فحص الجودة في الوقت الحقيقي",
      },
      pushObjectTypes: { email: "تحديد صندوق البريد", staff: "خدمة العملاء" },
      reviewOptions: {
        reviewResult: "يتم عرض نتائج فحص الجودة أثناء المراجعة",
        reviewRecord: "عرض السجلات ذات الصلة أثناء المراجعة",
        reviewAgentHidden: "إخفاء معلومات خدمة العملاء أثناء المراجعة",
        samplingResult: "يتم عرض نتائج فحص الجودة أثناء أخذ العينات",
        samplingRecord: "عرض السجلات ذات الصلة أثناء أخذ العينات",
        sampleAgentHidden: "إخفاء معلومات خدمة العملاء أثناء أخذ العينات",
        checkExportAddCallInfoIphone: "إرفاق سجل المكالمات عند تصدير القائمة",
        sampleExportAddCallInfoIphone: "إرفاق سجل المكالمات عند تصدير القائمة",
        checkExportAddCallInfoText: "إرفاق سجل حوار عند تصدير قائمة",
        sampleExportAddCallInfoText: "إرفاق سجل حوار عند تصدير قائمة",
        inspectionExportAddCallInfoIphone:
          "إرفاق سجل المكالمات عند تصدير قائمة (يمكن تصدير ما يصل إلى 10000 في كل مرة)",
        inspectionExportAddCallInfoText:
          "إرفاق سجل حوار عند تصدير قائمة (يمكن تصدير ما يصل إلى 10000 في كل مرة)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "السماح بإجراء عمليات تفتيش عشوائية بسرعة على تفاصيل فحص الجودة",
        fastSampleInSampleDetail:
          "السماح بإجراء عمليات أخذ العينات بسرعة على تفاصيل أخذ العينات",
        fastSampleInReviewDetail:
          "السماح بإجراء عمليات أخذ العينات بسرعة في تفاصيل المراجعة",
      },
      permitCustomReasonList: { permitCustomReason: "السماح بتخصيص الأسباب" },
      autoMateTasks: {
        autoLoad: "إضافة تلقائيا",
        sample: "أخذ العينات التلقائي",
        assign: "التخصيص التلقائي",
        pushes: "الدفع التلقائي",
        assess: "التقييم التلقائي",
        check: "إعادة فحص الاستئناف",
        case: "تزامن مكتبة الحالة",
      },
      qualityCheckDetailTypes: { review: "مراجعة", spotCheck: "أخذ العينات" },
      appealTypes: {
        transliterationError: "أخطاء النسخ",
        discriminationError: "خطأ في الحكم",
        otherError: "أخطاء أخرى",
      },
      inspectDataSources: {
        voiceCall: "المكالمات الصوتية",
        textDialogue: "حوار نصي",
        realTimeVoiceCall: "المكالمات الصوتية في الوقت الحقيقي",
        realTimeTextDialogue: "محادثات النص في الوقت الحقيقي",
      },
      pointTypes: {
        automatic: "التقييم التلقائي",
        manual: "التقييم اليدوي",
        interactiveDetection: "الكشف عن التفاعل",
      },
      pointGradeTypes: {
        radio: "اختيار واحد",
        input: "إدخال",
        select: "الاختيار",
      },
      sampleAndReviewStatus: {
        init: "غير مستخرج",
        unread: "غير مقروء",
        alreadyRead: "تمت قراءته",
        complaining: "في الشكوى",
        reviewed: "تمت مراجعة",
        sampling: "لم يتم فحص السحب",
        samplingCompleted: "تم أخذ عينات منها",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "تجاهل الفائض الزائد",
        automaticWeightCalculation: "المحاسبة التلقائية للأوزان",
      },
      calculateScoreTypes: { add: "نقاط إضافية", sub: "تخفيض النقاط" },
      naChecked: { check: "هذا غير متورط في الاختبار المسموح به" },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "إعطاء النقاط بالتناسب",
        allRight: "جميع النقاط الصحيحة",
        anyRight: "إعطاء النقاط بشكل صحيح بشكل تعسفي",
      },
      noHitQuestionResults: {
        true: 'والنتيجة هي "نعم"',
        false: 'النتيجة "لا"',
      },
      repetRules: { same: "نفس المحتوى", similar: "التشابه الدلالي" },
      relateConditions: {
        highlightQuestion: "مشكلة عالية",
        highlightAnswer: "إجابات عالية",
        hightlightFoctors: "عناصر عالية",
      },
      interactiveQuestionTypes: {
        question: "تم اكتشاف المشكلة",
        eliminateQuestion: "اكتشاف مشكلة الاستبعاد",
      },
      interactiveAnswerTypes: {
        noAnswer: "لم يتم اكتشاف الإجابة",
        answer: "اكتشاف الجواب",
        wrongAnswer: "اكتشاف إجابات خاطئة",
      },
      inspectionUpdateTypes: {
        autoInspection: "فحص الجودة التلقائي",
        autoInspectionWechat: "التحليل الآلي",
        artificialSampling: "أخذ العينات اليدوية",
        artificialReview: "مراجعة اصطناعية",
        manualModification: "أخذ عينات سريعة",
        timelyInspection: "فحص الجودة في الوقت الحقيقي",
        samplingAssign: "توزيع أخذ العينات",
        artificialComplain: "تقديم الشكاوى",
        fastSampling: "تقديم أخذ العينات بسرعة",
        recheckInspection: "إعادة فحص الجودة",
        recheckInspectionWechat: "إعادة التحليل",
      },
      timelyInspectionTypes: {
        timelyInspection: "فحص الجودة في الوقت الحقيقي",
      },
      pushActionTypes: { email: "البريد", interfacePush: "دفع واجهة" },
      pushDetailWays: {
        riskAlert: "تذكير بالمخاطر",
        subscribe: "الاشتراك في الرسائل",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "رسالة مكالمة/حوار",
        inspectionResults: "نتائج فحص الجودة",
      },
      subscribePushDataTypes: {
        dialogueMessage: "رسالة مكالمة/حوار",
        textContent: "محتوى نص المكالمة/المحادثة",
        inspectionResults: "نتائج فحص الجودة",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "متوسط الدرجات داخل المجموعة",
        addOrSubtract: "إضافة أو طرح نقاط مباشرة",
        noChange: "لا نقاط زائد أو ناقص",
      },
      defaultScore: {
        empty: "فارغة",
        add: "نقاط إضافية",
        notAdd: "لا نقاط إضافية",
        sub: "تخفيض النقاط",
        notSub: "لا تفقد النقاط",
        na: "N/A",
        input: "الإدخال اليدوي",
        choose: "الاختيار",
      },
      strategyTypes: {
        hitAdd: "ضرب نقاط إضافية",
        hitNotAdd: "ضرب دون نقاط إضافية",
        hitSub: "ضرب ناقص النقاط",
        hitNotSub: "ضرب دون فقدان النقاط",
      },
    },
    download: {
      exportStatus: {
        prepare: "على استعداد",
        process: "قيد العمل",
        faild: "فشل التصدير",
        success: "النجاح",
      },
    },
    messageType: {
      all: "كل شيء",
      monitor: "المراقبة الفورية",
      trigger: "إشعار الزناد",
      timing: "تقارير موقوتة",
    },
    messagePushType: {
      all: "كل شيء",
      system: "رسائل النظام",
      sdk: "رسالة SDK",
      push: "دفع واجهة",
      email: "البريد",
      udesk: "إشعار udesk",
      udeskWhite: "إخطار نظام خدمة العملاء",
      weChat: "أخبار الشركة الصغيرة",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "تعيين مفتشي العينات",
      sampleInspector: "مفتشي العينات",
      assignReviewer: "تعيين مراجعي الحسابات",
      reviewer: "المراجع",
      taskInspector: "مفتش جودة المهمة",
    },
    sdkPushType: {
      webHook: "Webhook القياسية",
      dingDing: "مسمار الروبوت",
      weChat: "الشركات WeChat الروبوتات",
      customer: "تخصيص محتوى Json",
    },
    inspectionRemarksType: {
      inspection: "فحص الجودة",
      check: "أخذ العينات",
      review: "مراجعة",
    },
    segmentationStrategyType: { count: "عدد الرسائل", spacing: "فاصل الرسائل" },
    segmentationStrategy: { byTime: "تقسيم الوقت", customize: "تقسيم الجمع" },
    inspectType: {
      commonInspect: "فحص الجودة الروتيني",
      associateInspect: "فحص الجودة ذات الصلة",
    },
    typoDetectionOperators: { less: "أقل من", greater: "أكبر من" },
    autoAddTypes: {
      realTimeAdd: "إضافة في الوقت الحقيقي",
      timingAdd: "إضافة توقيت",
    },
    messageTypes: { text: "رسالة نصية", markdown: "رسالة Markdown" },
    inServiceStatus: {
      activated: "تم تفعيلها",
      disabled: "تم تعطيله",
      inactivated: "غير نشط",
      resigned: "الخروج من المؤسسة",
    },
    roles: { agent: "الموظفون", customer: "العملاء" },
    commentVisibleAuthority: {
      all: "مرئي للجميع",
      self: "مرئية فقط من تلقاء نفسها",
      designation: "الشخص المعين مرئي",
    },
    appealCheckingTask: {
      eCommerceReview: "تعليقات التجارة الإلكترونية",
      socialMediaComments: "تعليقات وسائل الإعلام الاجتماعية",
      largeModel: "نموذج كبير",
      customModel: "نموذج مخصص",
      universalModel: "نموذج عام",
      enterpriseWeChatText: "نص المؤسسة الصغيرة",
      generalQualityInspection: "فحص الجودة العامة",
      processQualityInspection: "عملية فحص الجودة",
      voiceCopy: "إعادة الصوت",
      beEqualTo: "يساوي",
      notEqualTo: "لا يساوي",
      canAppeal: "يمكن تقديم شكوى",
      noAppeal: "غير قابل للطعن",
    },
    wechat: {
      redEnvelopes: "المغلف الأحمر",
      text: "النص",
      picture: "الصور",
      voice: "صوت",
      voiceCall: "المكالمات الصوتية",
      video: "فيديو",
      businessCard: "بطاقة العمل",
      position: "الموقع",
      expression: "التعبير",
      link: "الروابط",
      applet: "برنامج صغير",
      chatRecord: "سجلات الدردشة",
      mixedMessage: "رسائل مختلطة",
      file: "الوثائق",
    },
    gong: {
      sessionActivity: "أنشطة المحادثة",
      conversationAction: "عمل الجلسة",
      riskSemantics: "دلالات المخاطر",
      daysNotFollowedUp: "عدد الأيام التي لم تتم متابعتها",
      waysideData: "البيانات مع الطريق",
      notStarted: "لم تبدأ",
      completed: "أنجز",
      haveInHand: "قيد العمل",
      unpublished: "لم تنشر",
      published: "نشرت",
      knowledgePoints: "نقاط المعرفة",
      technologicalProcess: "العملية",
      notMarked: "لم يتم وضع علامة عليها",
      inAnnotation: "في وضع العلامات",
      annotated: "تم وضع علامة عليه",
    },
    qualityChecingTask: {
      appealCanceled: "إلغاء الشكوى",
      complaintExpired: "انتهاء صلاحية الشكوى",
      incomingCall: "استدعاء",
      exhale: "صرخة",
      incomingCallthirdParty: "نداء (ثلاثي)",
      incomingCallconsultation: "نداء (استشارة)",
      incomingCallforcedInsertion: "الزفير (إدخال قوي)",
      incomingCallmonitoring: "نداء (مراقبة)",
      incomingCalltransfer: "استدعاء (تحويل)",
      incomingCallinterception: "نداء (اعتراض)",
      incomingCalltransferToExternalLine: "استدعاء (نقل إلى الخارج)",
      incomingCallthirdPartyExternalLine: "مكالمات (خطوط ثلاثية)",
      incomingCallexternalLine: "الاتصال (الخط الخارجي للتشاور)",
      outgoingCallthirdParty: "الزفير (ثلاثي)",
      outgoingCalltransfer: "استدعاء (تحويل)",
      outgoingCallforcedInsertion: "الزفير (الإدراج القوي)",
      outgoingCallmonitoring: "صرخة (مراقبة)",
      outgoingCallinterception: "زفير (اعتراض)",
      outgoingCalltransferToExternalLine: "استدعاء (تحويل إلى الخارج)",
      outgoingCallthreePartyExternalLine: "استدعاء (الأطراف الثلاثة الخارجية)",
      customerServiceAnswer: "خدمة العملاء الرد",
      customerServiceMissed: "خدمة العملاء لم يتم الرد عليها",
      customerAnswer: "العملاء يجيبون",
      customerMissed: "لم يرد العملاء",
      theTelephoneIsBusy: "الهاتف مشغولة",
      phoneOffline: "الهاتف غير متصل",
      customerQuickHanging: "توقف العملاء بسرعة",
      clientHangsUp: "تعليق العملاء",
      queueTimeout: "الانتظار في الطابور لوقت العمل الإضافي",
      giveUpQueuing: "التخلي عن الطابور",
      outsideLineAnswering: "الرد الخارجي",
      externalLineIsNotConnected: "الخط الخارجي غير متصل",
      noQueueSelected: "قائمة انتظار غير مختارة",
      notEvaluated: "لم يتم تقييمها",
      noEvaluationRequired: "لا حاجة للتقييم",
      queuingSucceeded: "النجاح في الطابور",
      noCustomerServiceOnline: "لا خدمة العملاء عبر الإنترنت",
      customer: "العملاء",
      customerService: "خدمة العملاء",
      outsideLines: "الخط الخارجي",
    },
    sessionActivity: {
      single: "متوسط وقت المكالمة الفردية",
      all: "إجمالي حجم المكالمات",
      allTime: "إجمالي مدة المكالمة",
    },
    sessionAction: {
      salesProportion: "نسبة الكلام في المبيعات",
      salesLongest: "متوسط أطول بيان مبيعات",
      customerLongest: "متوسط أطول خطاب العميل",
      discussNumber: "عدد المناقشات المتعمقة",
      frequency: "تكرار الأسئلة",
    },
    monitorCriteria: { all: "قبل وبعد", before: "قبل", after: "بعد" },
    monitorTimeCycle: { day: "يا إلهي", week: "الأسبوع", month: "الشهر" },
    monitorGroupTestType: {
      ab: "مقارنة بين مجموعتين",
      a: "المجموعة ألف",
      b: "المجموعة باء",
    },
    fields: {
      department: "الإدارات",
      customerTags: "علامات العملاء",
      member: "الأعضاء",
      time: "الوقت",
      cascade: "سلسلة",
      anyMatch: "تطابق تعسفي",
      keyWord: "الكلمات الرئيسية",
      keyEvents: "الأحداث الرئيسية",
    },
    semanticIntelligence: {
      staff: "الموظفون",
      customer: "العملاء",
      employeesAndCustomers: "الموظفين والعملاء",
      notStarted: "لم تبدأ",
      haveInHand: "قيد العمل",
      completed: "أنجز",
      callRecording: "تسجيل المكالمات",
      enterpriseWeChatCall: "مكالمة من شركة Weitong",
      enterpriseWeChatText: "نص المؤسسة الصغيرة",
      discourseMiningForObjectionHandling: "معالجة الاعتراض",
      productSellingPointScriptsMining: "حفر نقطة بيع المنتج",
      conversationScenario: "مشهد المحادثة",
      topicOfConversation: "الموضوع",
      keyEvents: "الأحداث الرئيسية",
      customerLabel: "علامات العملاء",
      includeAny: "يحتوي على تعسفي",
      includeAll: "يحتوي على كل شيء",
      and: "و",
      or: "أو",
      question: "سؤال",
      nonInterrogativeSentence: "جمل غير استفهام",
    },
    coach: {
      notPassed: "فشل",
      analogTelephone: "الهواتف التناظرية",
      enterScenarioInformation: "إدخال معلومات السيناريو",
      generateDialogueFramework: "إنشاء إطار للحوار",
      generatePromptLanguage: "توليد تذكير",
      aIGeneratedDialogue: "AI توليد المحادثات",
      system: "النظام",
      custom: "التخصيص",
      flow: "العملية",
      script: "الكلام",
      express: "التعبير",
      emotion: "العواطف",
      rateAccordingToCourseConfiguration: "الدرجات حسب تكوين الدورة",
      intelligentModelRating: "تصنيف النموذج الذكي",
      allData: "جميع البيانات",
      dataFromThisDepartment: "بيانات القطاع",
      multidepartmentalData: "البيانات المتعددة القطاعات",
      underReview: "قيد المراجعة",
      no: "لا",
      yes: "نعم",
      automaticRating: "التسجيل التلقائي",
      auditRating: "مراجعة الدرجات",
      reRating: "إعادة التسجيل",
      inAppeal: "في الشكوى",
      reviewed: "تمت مراجعة",
      notViewed: "لم يتم عرضها",
      viewed: "عرض",
      uncorrected: "لم يتم تعديله",
      corrected: "تم تعديله",
      practice: "تمارين",
      examination: "الامتحانات",
      other: "أخرى",
      notMeetingStandards: "فشل في تلبية المعايير",
      meetingStandards: "تلبية المعايير",
      excellent: "ممتاز",
      invalid: "فشل",
      notStarted: "لم تبدأ",
      haveInHand: "قيد العمل",
      ended: "انتهى",
      completed: "أنجز",
      hangInTheAir: "لم يكتمل",
      passed: "اعتمد",
      voiceConversation: "المحادثة الصوتية",
      textDialogue: "حوار نصي",
      slidePresentation: "عرض الشرائح",
      unpublished: "لم تنشر",
      published: "نشرت",
      narration: "السرد",
      studentSpeaking: "الطلاب يتحدثون",
      robotTalk: "الروبوت يتحدث",
      knowledgeBaseQA: "قاعدة المعرفة سؤال وجواب",
      slide: "عرض الشرائح",
      negativeEmotions: "سلبية عاطفيا",
      emotionallyNormal: "مزاج طبيعي",
      incompleteScript: "الكلام غير مكتمل",
      completeScript: "الكلام الكامل",
      normalSpeechSpeed: "سرعة الكلام طبيعية",
      speakTooFast: "تحدث بسرعة كبيرة",
      speakTooSlowly: "تحدث ببطء شديد",
      whole: "كل شيء",
      singlePage: "صفحة واحدة",
      qA: "سؤال واحد ، إجابة واحدة",
      situationalDialogue: "حوار السيناريو",
      misses: "لم يضرب",
      hit: "ضرب",
      sequentialQA: "سؤال وجواب متسلسل",
      randomQA: "أسئلة وأجوبة عشوائية",
      mastered: "اتقنت",
      notMastered: "غير السيطرة",
    },
    algorithm: {
      salesEmpowerment: "مبيعات التمكين",
      enterpriseWeChatVoice: "صوت المؤسسة WeChat",
      enterpriseWeChatText: "المؤسسة WeChat Text",
      waiting: "في انتظار",
      extracting: "استخراج",
      success: "النجاح",
      fail: "فشل",
      intelligentQualityInspection: "فحص الجودة الذكية",
      textDialogue: "حوار نصي",
      voiceCall: "المكالمات الصوتية",
      smartTags: "العلامات الذكية",
      satisfactionEvaluation: "تقييم الرضا",
      score: "التقييم",
      grade: "تصنيف",
      customerServiceTeam: "مجموعة خدمة العملاء",
      conversationAnalysis: "تحليل الجلسة",
    },
    operatorListGather: {
      businessSummary: "ملخص الأعمال",
      firstSentence: "الجملة الأولى",
      lastSentence: "الجملة الأخيرة",
      allTime: "الوقت الكامل",
      nonBusinessWorkingHours: "ساعات العمل غير التجارية",
      businessWorkingHours: "ساعات العمل",
    },
    callDialogueManage: {
      eCommerceReview: "تعليقات التجارة الإلكترونية",
      socialMediaComments: "تعليقات وسائل الإعلام الاجتماعية",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "تسجيل الدخول",
        email: "صندوق البريد",
        password: "كلمة السر",
        forgetPwd: "نسيت كلمة المرور",
        emailCannotBeEmpty: "يجب ألا يكون صندوق البريد فارغًا.",
        passwordCannotByEmpty: "يجب ألا تكون كلمة المرور فارغة.",
        mustChangeLogin:
          "عذرًا ، يجب إعادة تعيين كلمة المرور الخاصة بك ، اتصل بالمسؤول لإعادة تعيين كلمة المرور.",
        dismissionLogin: "آسف ، لقد تركت.",
        forbiddenLogin: "آسف ، حسابك هو حالة معطلة.",
        loginError:
          "فشل تسجيل الدخول أو البريد الإلكتروني أو كلمة المرور الخاطئة.",
        loginPrompt: "تسجيل الدخول إلى النظام",
        rememberMe: "تذكرني",
      },
      logout: {
        logoutError:
          "فشل تسجيل الخروج ، يرجى التحقق من اتصال الشبكة أو حاول مرة أخرى في وقت لاحق.",
      },
      resetPassword: {
        forgetPwd: "نسيت كلمة المرور",
        inputEmailPrompt:
          "أدخل صندوق بريد حسابك أدناه ، وسوف نرسل لك رسالة بريد إلكتروني وتعليمات لإعادة تعيين كلمة المرور.",
        sendEmailSuccess:
          "قم بتعديل كلمة المرور لتأكيد إرسال الرسالة بنجاح إلى صندوق البريد الذي تملأه ، يرجى التحقق منها في الوقت المناسب.",
        sendEmailFailed:
          "فشل إرسال صندوق البريد ، يرجى التحقق من اتصال الشبكة أو حاول مرة أخرى في وقت لاحق.",
        setNewPassword: "تعيين كلمة المرور الجديدة الخاصة بك",
        passwordCannotEmpty: "يجب ألا تكون كلمة المرور فارغة",
        passwordFormWrong: "تنسيق كلمة المرور غير صحيح",
        confirmPasswordWrong: "كلمة المرور التي تم إدخالها مرتين ليست متساوية.",
        passwordResetSuccess: "إعادة تعيين كلمة المرور بنجاح.",
        passwordResetFailed:
          "فشل إعادة تعيين كلمة المرور ، يرجى التحقق من اتصال الشبكة أو حاول مرة أخرى في وقت لاحق.",
        linkTimeover:
          "إعادة تعيين رابط كلمة المرور تنتهي ، يرجى المحاولة مرة أخرى",
      },
      form: { title: "عرض نموذج CASE" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "صندوق البريد",
          emailFormatError: "تنسيق صندوق البريد غير صحيح ، يرجى إعادة إدخاله!",
          forgotPassword: "نسيت كلمة المرور ؟",
          describe:
            "أدخل صندوق بريد حسابك أدناه ، وسوف نرسل لك رسالة بريد إلكتروني وتعليمات لإعادة تعيين كلمة المرور.",
          send: "إرسال",
          emailCannotBeEmpty: "لا يمكن أن يكون صندوق البريد فارغًا",
          resetPasswordExpire:
            "إعادة تعيين رابط كلمة المرور تنتهي ، يرجى المحاولة مرة أخرى",
        },
        resetPassword: {
          setPassword: "تعيين كلمة المرور الجديدة الخاصة بك",
          newPassword: "كلمة المرور الجديدة",
          repeatPassword: "تكرار كلمة المرور",
          resetPassword: "إعادة تعيين كلمة المرور",
          passwordCannotBeEmpty: "لا يمكن أن تكون كلمة المرور الجديدة فارغة.",
          confirmPasswordCannotBeEmpty:
            "لا يمكن أن تكون كلمات المرور المكررة فارغة.",
          passwordsNotMatch: "كلمات المرور غير متسقة مرتين!",
          passwordFormWrong:
            "لا يمكن أن تكون كلمة المرور باللغة الصينية ، والرقم هو 6-14 بت!",
          linkExpiration:
            "إعادة تعيين رابط كلمة المرور تنتهي ، يرجى المحاولة مرة أخرى",
        },
      },
      errors: { haveNoPermissionWarningText: "ليس لديك إذن لعرض هذه الصفحة" },
    },
    downloadCenter: {
      name: "مركز التحميل",
      saveTime: "(يتم حفظ 30 يومًا افتراضيًا)",
      headerTitle: "تحميل الملفات",
      download: "تحميل",
      downloadPromp: "تم تقديم مهمة التنزيل ، يرجى الذهاب إلى",
      viewProgress: "عرض التقدم المحرز",
    },
    uploadCenter: { name: "مركز التحميل" },
    tasks: {
      index: {
        name: "اسم",
        remark: "الوصف",
        qualityCheckTasksTitle: "طاولة العمل",
        informationCollection: "مهمة الجمع",
        qualityCheckTasks: "مهام فحص الجودة",
        wechatQaTasks: "المهام التحليلية",
        moduleName: "استخدام قالب جديد",
        creatNewQualityCheckTask: "مهام جديدة",
        generalInspectionList: "تقرير فحص الجودة العام",
        editQualityCheckTask: "تحرير مهام فحص الجودة",
        creatNewQualityCheckTaskError: "فشلت مهمة فحص الجودة الجديدة",
        editQualityCheckTaskError: "فشل مهمة فحص الجودة التحريرية",
        type: "نوع النموذج",
        singleScore: "النتيجة الفردية",
        singleScoreNullError: "لا يمكن أن تكون النتيجة الفردية فارغة",
        inspector: "مفتش الجودة",
        inspectDataSource: "مصادر البيانات",
        taskName: "اسم المهمة",
        inspectionType: "نوع فحص الجودة",
      },
      manage: {
        screeningCondition: "شروط الفرز",
        template: {
          condition: {
            index: { name: "الشروط", existingConditions: "الشروط الموجودة" },
            detail: {
              id: "ID",
              name: "اسم الشرط",
              remark: "الوصف",
              checkRange: "نطاق الكشف",
              operator: "عامل",
              operatorLogic: "منطق المشغل",
            },
          },
          checkPointTest: {
            title: "اختبار القواعد",
            running: "في اختبار القواعد ، يرجى في وقت لاحق...",
            clearSuccess: "محاكاة الحوار إفراغ النجاح",
            mock: "محاكاة الحوار",
            save: "حفظ الحوار المختار",
            saveAll: "حفظ جميع المحادثات",
            saveSuccess: "الحوار ينقذ النجاح",
            close: "الخروج",
            delete: "حذف الاختيار",
            clear: "أفرغ",
            customer: "العملاء",
            agent: "خدمة العملاء",
            test: "اختبار",
            request:
              "لن يتم استردادها بعد إفراغ المحادثة المحاكاة ، هل تم تأكيد إفراغ ؟",
            deletePopconfirmContent: "هل تم تأكيد حذف محادثة الاختيار ؟",
          },
          checkPoint: {
            index: {
              name: "تكوين القاعدة",
              wechatTitle: "تحليل تكوين النموذج",
              existingCheckPoints: "قواعد فحص الجودة الحالية",
              currentCheckPointScore: "درجة قواعد فحص الجودة الحالية هي",
              currentCheckPointScoreWechat: "درجة نموذج التحليل الحالي هي",
              currentCheckPointWeight:
                "مجموع أوزان قواعد فحص الجودة الحالية هو",
              weightTotalScoreAndItemScore:
                "مجموع النقاط: 100 نقطة نقاط فردية:",
              template: "قالب قواعد فحص الجودة",
              import: "استيراد قالب قواعد فحص الجودة",
              getTemplateError: "فشل الحصول على قوالب قواعد فحص الجودة",
              tabs: { template: "قالب", demo: "مثال على ذلك" },
              customize: "قالب فارغ",
              move: "السحب لضبط التصنيف والفرز",
              classification: "تصنيف فحص الجودة",
              classificationWeChat: "تصنيف النماذج",
              first: "تصنيف المستوى الأول",
              second: "تصنيف المستوى الثاني",
              new: "قواعد فحص الجودة الجديدة",
              weChatNew: "إضافة نموذج تحليلي",
              createWeChat: "نموذج تحليل جديد",
              empty: "لا توجد قواعد فحص الجودة ،",
              wechatEmpty: "لا يوجد نموذج تحليلي ،",
              newSecond: "تصنيف ثانوي جديد",
              newFirst: "تصنيف جديد من المستوى الأول",
              checkPoint: "قواعد فحص الجودة",
              checkPointWeChat: "نموذج تحليلي",
            },
            detail: {
              name: "اسم",
              remark: "الوصف",
              type: "نوع",
              deduction: "خصم النقاط",
              rule: "القواعد",
              highlighted: "جاو ليانغ",
              gradeType: "طريقة التسجيل",
              interactiveCategory: "تصنيف التفاعل",
              predeterminedScore: "نقاط",
              weight: "الأوزان",
              na: "N/A",
              default: "النتيجة الافتراضية",
              preRule: "قواعد الشروط المسبقة",
              analysisHighlighted: "قواعد التحليل",
              categorySelectPrompt: "يرجى اختيار التصنيف",
              checkHighlightArrayError:
                "القواعد لا تتطابق مع التألق ، يرجى النقر مرة أخرى على زر تحليل القواعد",
              foctorsHolder: "يرجى اختيار العناصر",
              foctorsErrorNone:
                "قاعدة العناصر المتعددة فارغة ، يرجى إضافة أنواع العناصر المتعددة",
              naError:
                "لا يمكن إيقاف تشغيل هذا البند عندما تكون النتيجة الافتراضية N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A هو Not Applicable ، ضع شروطًا مسبقة لقواعد فحص الجودة ، واكتشفها عند استيفاء الشروط ، وإذا لم يتم استيفاء الشروط ، فإن القاعدة لا تنطبق ، ولن يتم اختبار القاعدة ؛\nشرح ملء الشروط المسبقة: "& &" تعني "و" ، "| |" تعني "أو" ، "!" يعني "غير" ، املأ المثال "(R1 || R2) && R3"',
                applicationMessageType: "نوع رسالة التطبيق",
                selectAll: "الاختيار الكامل",
                highlightTags: "علامة عالية:",
                ruleType: "نوع القاعدة",
                dataSource: "مصادر البيانات",
                whole: "كل شيء",
                enterpriseMicroConversation: "محادثة الشركات الصغيرة",
                enterpriseAndMicroCommunication: "مكالمة من شركة Weitong",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "عقدة الحكم",
                    dialogueNode: "عقدة الحوار",
                    editNodes: "تحرير العقدة",
                  },
                  nodeConfig: {
                    nodeName: "اسم العقدة",
                    nodeRules: "قاعدة العقدة",
                    scoringLogic: "سجل المنطق",
                    score: "النقاط:",
                    rule: "القواعد",
                    multiBranchLogic: "منطق متعدد الفروع",
                    highlight: "جاو ليانغ",
                    submit: "المقدمة",
                  },
                  ruleList: {
                    branch: "الفرع",
                    branchName: "اسم الفرع:",
                    addRules: "زيادة القواعد",
                  },
                  sidebar: { node: "العقدة" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "التشطيب التلقائي",
                      canvasAdaptation: "التكيف القماشية",
                      copy: "نسخ",
                      delete: "حذف",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "لا يمكن استخدام العقد الفرعية للحكم على العقد كهدف اتصال",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "لا يمكن أن تكون عقدة البداية هدفًا للاتصال",
                      startingNodeCannotConnectMultipleNodes:
                        "بداية العقد لا يمكن ربط عدة عقد",
                      nodeCannotBeConnectedToItself:
                        "لا يمكن ربط العقد مع نفسها",
                      startNode: "بدء العقد",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "إلغاء الاختيار الكامل",
                    selectAll: "الاختيار الكامل",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "ما إذا كان يفي",
                    gradedEvaluation: "تقييم الدرجات",
                  },
                },
              },
            },
            configuration: {
              title: "قواعد فحص الجودة",
              wechatTitle: "نموذج تحليلي",
              neme: "اسم",
              errorMessage: "حذف الفشل ، شرط واحد على الأقل",
              desc: "الوصف",
              status: "الحالة",
              yes: "فعالة",
              no: "غير صالح",
              namePlaceHolder: "الرجاء إدخال اسم العلامة",
              desPlaceHolder: "الرجاء إدخال الوصف",
              nameEmpty: "لا يمكن أن يكون الاسم فارغًا",
              desEmpty: "الوصف لا يمكن أن يكون فارغا",
              type: "نوع",
              ruler: "القواعد الذكية",
              machineLearning: "نموذج التعلم الذاتي",
              autoScore: "الدرجات الذكية (القديمة)",
              manualScore: "التسجيل اليدوي",
              interaction: "الكشف عن التفاعل (القديم)",
              typeTips: {
                model:
                  'النموذج الذكي: هو نظام فحص جودة محدد مسبقًا ، ولا يحتاج إلى تكوين وصيانة ، ويمكنه فحص جودة المشكلات في الخدمة بسرعة. هذا النموذج أكثر عمومية. إذا كنت لا تفي بمتطلبات العمل ، فيرجى استخدام "القواعد الذكية" أو "التسجيل اليدوي',
                rule: "القواعد الذكية: أي أن منظمة العفو الدولية تقوم تلقائيًا بتصنيف النقاط بناءً على القواعد",
                manual:
                  "التسجيل اليدوي: أي ، فحص الجودة اليدوي ، التسجيل اليدوي",
              },
              model: "نموذج:",
              ruleType: {
                words: "الكلام",
                interaction: "التفاعل",
                feature: "الخصائص",
                information: "المعلومات",
              },
              testingMode: {
                all: "الكشف عن جميع الكلمات الرئيسية",
                any: "الكشف عن أي كلمة رئيسية",
              },
              operatorTypesOfInteraction: {
                question: "سؤال واحد ، إجابة واحدة",
                element: "عناصر الإجابة",
                repet: "تكرار السياق",
                snatch: "الاستيلاء على الكلمات",
                silent: "صمت",
              },
              role: "كشف الدور:",
              preposition: "عامل ما قبل:",
              range: "نطاق الكشف:",
              from: "رقم",
              to: "الجملة إلى الأولى",
              end: "الجملة",
              hasOldSilentTip:
                'مرحبًا ، من أجل تحسين الدقة ، تمت ترقية "القواعد الذكية-التفاعل-الصمت" إلى "مهلة الاستجابة" ، يوصى بتحديث قواعد الاستخدام!',
              changeToOld: "التبديل إلى الإصدار القديم",
              changeToNew: "التبديل إلى الإصدار الجديد",
              logic: {
                logic: "سجل المنطق",
                naTip:
                  "N/A هو Not Applicable ، ضع شروطًا مسبقة لقواعد فحص الجودة ، واكتشفها عند استيفاء الشروط ، وإذا لم يتم استيفاء الشروط ، فإن القاعدة لا تنطبق ، ولن يتم اختبار القاعدة",
                score: "النقاط:",
                na: "N/A：",
                naDes: "هذا غير متورط في الاختبار المسموح به",
                preRule: "قواعد الشروط المسبقة:",
                notCatchPreRule: "عندما لا يتم استيفاء الشروط المسبقة:",
                naChecked: "عند ضرب N/A:",
                preRuleEmpty: "لا يمكن أن تكون قواعد الشروط المسبقة فارغة",
                operatorEmpty: "لا يمكن أن تكون الشروط اللازمة في المشغل فارغة",
                multipleEmpty:
                  "لا يمكن أن تكون الشروط اللازمة في الدرجات المنطقية المتعددة فارغة",
                average: "متوسط الدرجات داخل المجموعة",
                addOrSubtract: "إضافة أو طرح نقاط مباشرة",
                noChange: "لا نقاط زائد أو ناقص",
                rule: "القواعد:",
                all: "تلبية جميع المشغلين",
                any: "إرضاء أي عامل",
                customize: "منطق المشغل المخصص",
                multiple: "الدرجات المنطقية المتعددة",
                heightLight: "جاو ليانغ:",
                machineLearningTip:
                  "نموذج التعلم الذاتي ساري المفعول حاليًا فقط لتقنية الجملة الواحدة ، يرجى استخدام القواعد الذكية للكلمات المعقدة",
                strategy: "الاستراتيجية:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "تم تقديم الموافقة بنجاح",
            },
            components: {
              pageHeaderExtra: {
                test: "اختبار",
                formal: "رسميا",
                submit: "المقدمة",
              },
              createModal: { index: { myRules: "القواعد الخاصة بي" } },
            },
          },
          automaticAdd: {
            index: {
              name: "أتمتة العمليات",
              screeningCondition: "شروط الفرز",
              aiScreeningCondition: "شروط الفحص الذكي",
              rule: "القواعد",
              automaticAddition: "إضافة تلقائيا",
              automaticAdditionTip:
                "إضافة البيانات تلقائيًا إلى المهمة في الوقت الفعلي أو بانتظام لفحص جودة الذكاء الاصطناعي",
              autoAdd: {
                autoAddType: "طريقة الإضافة التلقائية",
                addFrequency: "إضافة التردد",
                add: "إضافة",
              },
              automaticSamplingInspection: "أخذ العينات التلقائي",
              automaticSamplingInspectionTip:
                "الاستخراج التلقائي للبيانات التي تتطلب فحصًا يدويًا للفحص ، على سبيل المثال ، يتم أخذ 10 ٪ من البيانات العشوائية يوميًا للفحص اليدوي العشوائي",
              everyAgentCheckLimit: "الحد الأعلى لكل خدمة عملاء",
              automaticAllocation: "التخصيص التلقائي",
              automaticAllocationTip:
                "تخصيص أخذ العينات: يتم توزيع البيانات المستخرجة تلقائيًا على مفتشي الجودة. على سبيل المثال ، يتم أخذ 10 ٪ من بيانات الأمس العشوائية يوميًا للفحص اليدوي العشوائي ، ويتم تعيين مفتشي الجودة في هذه المهمة بشكل عشوائي في المتوسط.",
              automaticAllocationTip2:
                "تخصيص المراجعة: يتم تعيين بيانات الاستئناف تلقائيًا إلى مفتشي الجودة للمراجعة. على سبيل المثال ، يتم توزيع بيانات الاستئناف اليومي بشكل عشوائي بالتساوي على مفتشي الجودة في هذه المهمة للمراجعة",
              automaticPush: "الدفع التلقائي",
              automaticPushTip:
                "إشعار الزناد: إشعار/تحذير أثناء فحص الجودة ، على سبيل المثال ، عندما يكون لدى خدمة العملاء خطأ فادح ، أرسل رسالة إشعار إلى المجموعة الصغيرة للمؤسسة",
              automaticPushTip2:
                "تقرير منتظم: يتم إرسال نتائج فحص الجودة إلى تقرير منتظم في شكل بريد إلكتروني ، على سبيل المثال ، يتم إرسال نتائج فحص الجودة للفحص اليدوي يوم أمس إلى صندوق بريد مشرف خدمة العملاء في الساعة 9:00 كل يوم",
              automaticAssess: "التقييم التلقائي",
              automaticAssessTip:
                "تتم مقارنة نتائج أخذ العينات اليدوية بانتظام بنتائج فحص جودة الذكاء الاصطناعي لتقييم الدقة. على سبيل المثال ، يتم تقييم دقة البيانات التي تم أخذ عينات منها بالأمس في الساعة 8:00 يوميًا",
              reexaminationComplaint: "إعادة فحص الاستئناف",
              spoktReviewDetail: "تكوين الأعمال",
              spoktReviewDetailTip:
                "تكوين وظائف مثل قائمة فحص الجودة ، وقوائم فحص العينات ، وأعمال الاستئناف ، وقوائم المراجعة ، والملاحظات ، وما إلى ذلك",
              qaDetailAction: "تكوين فحص الجودة",
              spoktDetailAction: "تكوين أخذ العينات",
              reviewDetailAction: "تكوين مراجعة",
              appealAction: "تكوين الشكاوى",
              remarkTemplate: "قالب الملاحظات",
              automaticArchiving: "الأرشفة التلقائية",
              conditionalRules: "قواعد الشروط",
              qualityInspectionConditions: "شروط فحص الجودة",
              unopened: "لم يفتح بعد",
              sampleInspectionDistributionModel: "توزيع أخذ العينات",
              reviewAllocationModel: "إعادة النظر في التوزيع",
              distributionModelTitle: "استراتيجية التوزيع:",
              pushRules: "قواعد الدفع",
              push: "دفع",
              assessRules: "قواعد التقييم",
              on: "on",
              off: "off",
              updateStatusSuccess: "تغيير الحالة بنجاح!",
              updateStatusFailed:
                "فشل تغيير الحالة ، يرجى المحاولة مرة أخرى في وقت لاحق!",
              sampleNumberOrPercent: "عدد أو نسب أخذ العينات",
              inspectorList: "تعيين مفتشي الجودة",
              numberOfSubmission: "عدد الطلبات المسموح بها",
              rewivewDays: "يا إلهي",
              TimeOfSubmission: "فترة صلاحية تقديم الشكوى",
              rewivewSecond: "مرة واحدة",
              switchOn: "افتح",
              switchOff: "أغلق",
              notMatchAnyRule: "عندما لا تتطابق مع القواعد التعسفية:",
              appealOptionsList: {
                hiddenInspector: "الشكوى تخفي معلومات مفتش الجودة",
                hiddenHit: "صفحة الاستئناف تدعم موقع Gao Liang",
              },
              caseLibrary: "تزامن مكتبة الحالة",
              caseLibrarySyncTip:
                "مزامنة الحالات المضافة لنظام QA مع نظام خدمة العملاء",
              addCase: "زيادة التزامن",
              caseLibraryId: "مكتبة الحالات",
              caseLibraryIdTip: "تجميع قاعدة بيانات نظام QA",
              udeskCaseLibraryIdTip: "تجميع الحالات لنظام خدمة العملاء",
              syncHis: "حالة تاريخية متزامنة",
              otherSettings: "الإعدادات الأخرى",
              udeskCaseLibraryId: "دفع مجموعة الحالات",
              url: "دفع URL",
              urlTip:
                "يرجى إدخال عنوان الواجهة التي تبدأ بـ http:// أو https://https://.",
            },
            templateNew: {
              secondaryReview: "مراجعة المستوى الثاني",
              numberOfAppealsAllowedToBeSubmitted: "عدد الشكاوى المسموح بها",
              validityOfSubmissionOfAppeal: "فترة صلاحية تقديم الشكوى",
              validityOfAppealReview: "فترة صلاحية مراجعة الشكوى",
              hours: "ساعات",
              businessSummary: "ملخص الأعمال",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "الحد الأدنى لكل سحب خدمة العملاء",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "يتم إنشاء ملخص الأعمال تلقائيًا بواسطة نموذج كبير ، ويمكن تخصيص الأبعاد وفقًا لحالة العمل",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "استراتيجية توزيع المراجع من المستوى الأول فقط",
              secondaryCompounding: "مركب ثانوي:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "يدعم مراجعو المستوى الثاني التوزيع المتوسط فقط",
              secondLevelReviewer: "المراجع من المستوى الثاني:",
              samplingInspectionTarget: "أهداف أخذ العينات",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "حدد الهدف الذي يجب إكماله في الفحص العشوائي اليدوي ، وإعطاء تذكير إذا لم يتم تحقيق الهدف أو تجاوزه. على سبيل المثال ، يجب على كل خدمة عملاء إكمال 100 فحص عشوائي شهريًا. إذا كان أقل من 80 ، يجب أن تكون صفحة فحص الجودة والتفتيش العشوائي مطالبة.",
            },
            component: {
              verificationFailed: "فشل التحقق",
              pleaseAddASecondLevelQualityInspector:
                "يرجى إضافة مفتش جودة من المستوى الثاني",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "تذكير",
                    fieldName: "اسم الحقل",
                    fieldDescription: "وصف الحقل",
                    generateContent: "توليد المحتوى",
                    enabled: "ما إذا كان سيتم تمكينه",
                    generationMethod: "طريقة التوليد",
                    automaticGeneration: "توليد تلقائي",
                    manualTrigger: "الزناد اليدوي",
                    filterCondition: "شروط الفرز",
                    return: "العودة",
                    preserve: "حفظ",
                  },
                  components: {
                    training: { index: { generateResults: "توليد النتائج" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "غير محدود",
                    limitedScope: "نطاق محدود",
                    add: "إضافة",
                    thereIsCurrentlyNoDataAvailable: "لا توجد بيانات",
                  },
                },
              },
              index: {
                fieldName: "اسم الحقل",
                fieldDescription: "وصف الحقل",
                nothing: "لا شيء",
                generationMethod: "طريقة التوليد",
                generateManually: "توليد يدويا",
                automaticGeneration: "توليد تلقائي",
                generateContent: "توليد المحتوى",
                notQualified: "غير محدود",
                filterCondition: "شروط الفرز",
                enabled: "ما إذا كان سيتم تمكينه",
                type: "نوع",
                system: "النظام",
                custom: "التخصيص",
                operation: "العملية",
                edit: "تحرير",
                areYouSureToDeleteIt: "هل أنت متأكد من الحذف ؟",
                delete: "حذف",
                addField: "زيادة الحقول",
              },
            },
          },
          roleConfig: {
            index: {
              name: "تكوين الدور",
              roleConfigTip:
                "عندما تكون بيانات المكالمة المستوردة أحادية ، يتم استخدام قاعدة تعريف التكوين لفصل معالجة المتصل. دور خدمة العملاء الذي يتوافق مع القواعد",
              operator: "عامل",
              operatorLogic: "منطق المشغل",
            },
          },
          manualAdd: {
            index: {
              name: "إضافة يدويا",
              tip: "يتم استخدامه لفحص بيانات المعاينة من خلال شروط التكوين اليدوي وإضافتها بسرعة إلى مهمة فحص الجودة",
              screeningCondition: "إضافة حسب شروط الفرز",
              aiScreeningCondition: "شروط الفحص الذكي",
              rule: "القواعد",
              createTime: "إضافة الوقت",
              filterTitle: "فحص المحتوى",
              filterRule: "شروط الفرز",
              startTime: "وقت البدء",
              endTime: "نهاية الوقت",
              records: "استيراد السجلات",
              createUserName: "المشغل",
              successCount: "إضافة النجاح",
              conditionRule: "قواعد الشروط:",
              preview: "معاينة",
              previewData:
                "يفي حاليًا بشروط الفحص {0} ، تمت إضافة المهمة {1} ، ويمكن إضافة المهمة {2} ، ولا يمكن إضافة المهمة {3}",
              failCount: "فشل إضافة",
            },
          },
          basicInfo: {
            index: {
              title: "المعلومات الأساسية",
              name: "اسم",
              taskName: "اسم المهمة",
              taskNamePlaceholder: "الرجاء إدخال اسم المهمة",
              requiredName: "لا يمكن أن يكون اسم المهمة فارغًا",
              taskRemarkPlaceholder: "الرجاء إدخال وصف المهمة",
              requiredRemark: "لا يمكن أن يكون وصف المهمة فارغًا",
              remark: "الوصف",
              taskRemark: "وصف المهمة",
              source: "مصادر البيانات",
              benchmarkScore: "النقاط المرجعية",
              benchmarkScorePlaceholder: "يرجى إدخال النقاط المرجعية",
              scoreLowerLimit: "الحد الأدنى للدرجة",
              scoreUpperLimit: "الحد الأقصى للنتيجة",
              inspectType: "نوع فحص الجودة",
              nlpModel: "نموذج NLP",
              commonInspect: "فحص الجودة الروتيني",
              associateInspect: "فحص الجودة ذات الصلة",
              associateCycle: "دورة الارتباط",
              associateRegulation: "قواعد الارتباط",
              judgeStrategy: "تنفيذ المنطق",
              rule: "القواعد",
              commonTip:
                "فحص الجودة الروتيني: ينطبق على الاتصالات والحوارات الفردية ، ويتم فحص محتوى الاتصال الفردي",
              associateTip:
                "فحص الجودة ذات الصلة: مناسب للاتصالات والحوارات المتعددة ، وربط محتوى الاتصالات المتعددة لفحص الجودة",
              cycleTip:
                "إذا كانت الاتصالات والحوارات المتعددة ذات الصلة تتوافق مع البيانات في دورة الارتباط وتدخل في فحص الجودة للمهمة",
              scoreLowerLimitPlaceholder: "الرجاء إدخال الحد الأدنى من الدرجات",
              scoreUpperLimitPlaceholder: "الرجاء إدخال الحد الأقصى للنتيجة",
              call: "المكالمات الصوتية",
              dialogue: "حوار نصي",
              user: "مفتش الجودة",
              wechatUser: "المشغل",
              noDesignated: "غير محدد",
              type: "نوع النموذج",
              singleScore: "النتيجة الفردية",
              conduct:
                "يرجى تحسين المعلومات التالية أولاً ، يرجى النقر على التالي بعد الانتهاء",
              conduct2:
                "يرجى إكمال تكوين قواعد فحص الجودة ونقاط فحص الجودة. إذا كنت قد أكملت التكوين ، يمكنك النقر فوق الخطوة التالية أو الخروج من التوجيه",
              conduct3:
                "تصنيف فحص الجودة هو تكوين يعتمد على نقطة فحص الجودة ودرجة التقييم الخاصة بها. يمكنك تحديد مستويات مختلفة من نتائج فحص الجودة. إذا لم يكن لديك طلب على التصنيف ، يمكنك تخطي هذه الخطوة مباشرة. إذا كنت قد أكملت التكوين ، يمكنك النقر فوق الخطوة التالية أو الخروج من التمهيد",
              conduct4:
                "يمكنك تكوين خصائص البيانات التي سيتم إدخالها تلقائيًا في المهمة وفقًا لخصائص البيانات التي يجب التحقق منها في مهمة فحص الجودة هذه. إذا لم تكن بحاجة إلى إضافة البيانات تلقائيًا في الوقت الحالي ، فيمكنك النقر فوق تخطي. إذا كنت قد أكملت التكوين ، يمكنك النقر فوق التالي.",
              conduct5:
                "إذا كنت تستخدم تسجيلًا أحادي ، فيرجى التأكد من تكوين هذا العنصر والنقر على التالي. يرجى استخدام تقنية الكلمات التي تستخدمها خدمة العملاء فقط لمساعدة النظام على تحديد المسارات المختلفة بين خدمة العملاء والعميل. إذا كنت تستخدم تسجيل مزدوج القناة أو أكملت التكوين ، يمكنك النقر فوق تخطي",
              conduct6:
                "يمكنك إضافة وظيفة يدويًا ، وإدخال بيانات الحوار الحالية في النظام لفحص الجودة ، أو قمت بتقديم مهمة الإضافة اليدوية ، يرجى النقر لإكمالها مباشرة",
              helper: "عرض وثائق المساعدة",
              quit: "الخروج من التوجيه",
              next: "الخطوة التالية",
              complete: "الانتهاء",
              skip: "تخطي",
              cancel: "إلغاء",
              delete: "حذف",
              save: "حفظ",
            },
            template: {
              samplingInspector: "مفتشي العينات",
              sessionType: "نوع الجلسة",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "فحص الجودة طاولة العمل",
              search: "الفحص",
              export: "تصدير",
              condition: "الشروط",
              logic: "المنطق",
              reQualityInspection: "إعادة فحص الجودة",
              reQualityInspectionUnderWay: "مهمة إعادة فحص الجودة...",
              reQualityInspectionUnderWayTip:
                "مغادرة الصفحة بعد إغلاق النافذة لن يؤثر على المهمة",
              reQualityInspectionDone: "تم الانتهاء من إعادة فحص الجودة",
              reQualityInspectionDoneTip:
                "تم الانتهاء من إعادة فحص الجودة بنجاح ، يرجى النقر على زر التأكيد",
              endReQualityInspection: "إنهاء المهمة",
              alreadyDoneTip: "أنجز",
              totalTip: "المجموع",
              unit: "المادة",
              endReInspectionSuccess: "تم إنهاء مهمة إعادة فحص الجودة",
              closeWindow: "أغلق النافذة",
              endReInspectionFailed:
                "فشلت مهمة إعادة فحص الجودة ، يرجى المحاولة مرة أخرى لاحقًا!",
            },
            detail: {
              name: "تفاصيل فحص الجودة",
              reviewName: "تفاصيل المراجعة",
              spotCheckName: "تفاصيل أخذ العينات",
              audioText: "نص التسجيل الصوتي",
              callInfo: "معلومات الاتصال",
              satisfactionEvaluation: "تقييم الرضا:",
              queueTime: "الطابور يستغرق وقتا طويلا:",
              ringingTime: "وقت الرنين:",
              callTime: "حدث المكالمة:",
              hangUpIdentity: "الطرف الذي يعلق المكالمة:",
              location: "مكان الإسناد:",
              source: "المصدر:",
              agent: "خدمة العملاء:",
              deviceType: "نوع الجهاز:",
              callType: "نوع المكالمة:",
              phoneNumber: "رقم الترحيل:",
              task: "المهمة:",
              commentsTip: "يرجى إدخال تعليق فحص الجودة",
              lastPage: "الصفحة السابقة",
              nextPage: "الصفحة التالية",
              score: "درجة فحص الجودة",
              review: "مراجعة",
              file: "أرشفة",
              changeList: "تغيير السجلات",
              fullMarks: "درجة كاملة",
              most: "على الأكثر",
              saveReview: "مراجعة التقديم",
              saveSpotCheck: "تقديم أخذ العينات",
              point: "نقطة فحص الجودة",
              sampleAssignInspector: "تعيين مفتشي العينات",
              reviewAssignInspector: "تعيين مراجعي الحسابات",
              sampleStatus: "حالة أخذ العينات",
              read: "تمت قراءته",
              user: "المستخدمين",
              confirmer: "تأكيد الشخص",
              voiceDownloadError:
                "فشل التنزيل الصوتي ، يرجى المحاولة مرة أخرى لاحقًا!",
              smartTags: "العلامات الذكية",
              interactiveTip:
                "استخدام التعرف التفاعلي للكشف عن استخدام الأسئلة الشائعة لخدمة العملاء",
              interactiveIsEmptyTip: "لم يتم الكشف عن محتوى التعرف على التفاعل",
              businessRecords: "سجلات العمليات",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "تحليل الدردشة الجماعية للشركات WeChat",
            },
          },
        },
      },
      cards: {
        health: "فحص الجودة الصحية",
        accuracy: "اكتشاف معدل الموافقة",
        sessionsTotal: "مجموع الجلسات",
        enable: "تمكين",
        disable: "تعطيل",
        none: "لا شيء",
        call: "مكالمة هاتفية",
        dialogue: "الحوار",
        taobao: "التجارة الإلكترونية",
        wecom: "جلسة",
        ticket: "قائمة العمل",
      },
      component: {
        backup: "(النسخ الاحتياطي)",
        taskSaveAsSucceeded: "يتم حفظ المهمة بنجاح",
        copyContent: "نسخ المحتوى",
        essentialInformation: "المعلومات الأساسية",
        ruleConfiguration: "تكوين القاعدة",
        basicInformationQualityInspector: "المعلومات الأساسية-مفتش الجودة",
        qualityInspectionRating: "تصنيف فحص الجودة",
        roleConfiguration: "تكوين الدور",
        autoAddConfiguration: "إضافة تكوين تلقائيا",
        autoPushConfiguration: "تكوين الدفع التلقائي",
        automaticSamplingConfiguration: "تكوين أخذ العينات التلقائي",
        automaticAllocationConfiguration: "تخصيص التكوين التلقائي",
        businessConfiguration: "تكوين الأعمال",
        caseBaseConfiguration: "مكتبة الحالات",
      },
      template: {
        cancel: "إلغاء",
        complete: "الانتهاء",
        sort: "فرز",
        ruleApproval: "الموافقة على القواعد",
        enterpriseMicroConversationAnalysisTask:
          "مهمة تحليل المحادثة الدقيقة للمؤسسة",
        enterpriseAndMicroVoiceAnalysisTask: "مهمة تحليل الصوت الصغير للمؤسسة",
        saveTaskAs: "يتم حفظ المهمة بشكل منفصل",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "تفاصيل القواعد" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "مراجعة ناجحة",
            automaticApproval: "الموافقة التلقائية",
          },
          pageHeaderExtra: {
            modificationSucceeded: "مراجعة ناجحة",
            test: "اختبار",
            formal: "رسميا",
            adopt: "بواسطة",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "هل أنت متأكد من الرفض ؟",
              pleaseEnterTheRejectionReason: "الرجاء إدخال سبب الرفض",
              reject: "رفض",
            },
          },
          table: {
            index: {
              modificationSucceeded: "مراجعة ناجحة",
              taskName: "اسم المهمة",
              dataSource: "مصادر البيانات",
              qualityInspectionType: "نوع فحص الجودة",
              state: "الحالة",
              updateTime: "وقت التحديث",
              updatedBy: "تحديث الناس",
              approver: "الموافقة",
              reasonForRejection: "أسباب الرفض",
              operation: "العملية",
              details: "التفاصيل",
              adopt: "بواسطة",
              whole: "كل شيء",
              approvalStatus: "حالة الموافقة",
            },
          },
        },
      },
    },
    dashboard: {
      title: "نظرة عامة",
      hi: "مرحبا ،",
      standardEdition: "الإصدار القياسي",
      call: "رصيد فحص الجودة الصوتية",
      text: "فترة صلاحية فحص جودة النص",
      collectionTasks: "مجموعة مهام فحص الجودة",
      noCollection: "لا توجد مهمة فحص الجودة للمجموعة ،",
      goToCollecte: "اذهب إلى المجموعة",
      yesterdayOverView: "إحصائيات الأعمال أمس",
      yesterdayAgent: "تصنيفات خدمة العملاء أمس",
      yesterdayInspWork: "عمل مفتش الجودة أمس",
      operationalStatistics: {
        title: "إحصاءات الأعمال",
        qualityTesting: "فحص الجودة",
        spotCheck: "أخذ العينات",
        appeal: "الشكاوى",
        chartTitles: {
          qualityTestingTrend: "اتجاه فحص الجودة",
          qualityTestingAnalysis: "مهمة فحص الجودة",
          spotCheckTrend: "اتجاهات أخذ العينات",
          spotCheckAnalysis: "أخذ عينات من حالة المهمة",
          appealTrend: "الاتجاهات في الشكاوى",
          appealAnalysis: "حالة الولاية المتعلقة بالشكاوى",
        },
        titles: {
          qualityDataTend: "توزيع بيانات فحص الجودة",
          qualityCover: "تغطية فحص الجودة",
          qualityAverageTime: "متوسط وقت الانتظار لفحص الجودة",
          qualityCoverTend: "اتجاه تغطية فحص الجودة",
          handCover: "حالة المعالجة اليدوية",
          checkCover: "تغطية أخذ العينات",
          resAverageTime: "متوسط وقت المعالجة",
          handTend: "اتجاهات المعالجة اليدوية",
          dataTend: "توزيع مهام البيانات",
        },
        qualityAverage: "متوسط درجات فحص الجودة",
        scoreTrend: "اتجاه درجة فحص الجودة",
        qualityAverageTime: "متوسط وقت الانتظار لفحص الجودة",
        callCoverage: "تغطية فحص الجودة الصوتية",
        textCoverage: "تغطية فحص جودة النص",
        checkCount: "أخذ عينات من كمية البيانات",
        siphonCount: "كمية البيانات المستخرجة",
        reviewCount: "مراجعة حجم البيانات",
        appealCount: "حجم بيانات الطعون",
        checkRate: "نسبة تنفيذ أخذ العينات",
        reviewRate: "نسبة مراجعة الشكاوى",
        PieTitle: {
          totalCount: "مجموع الحوارات العامة",
          totalCall: "عدد المكالمات الصوتية",
          totalIm: "عدد المحادثات النصية",
          inspectionCall: "جودة الصوت",
          inspectionIm: "فحص جودة النص",
          checkCall: "كمية أخذ العينات الصوتية",
          unCheckCall: "كمية الصوت غير المدققة",
          checkIm: "كمية أخذ عينات النص",
          unCheckIm: "لم يتم أخذ عينات من النص",
          reviewCall: "حجم مراجعة الصوت",
          reviewIm: "حجم مراجعة النص",
          unReviewCall: "كمية الصوت غير المراجعة",
          unReviewIm: "كمية النص غير المراجعة",
          unInspectionCall: "كمية الصوت غير الجودة",
          unInspectionIm: "النص غير فحص الجودة",
        },
        check_rate: "تغطية أخذ العينات",
        voice_check_rate: "تغطية أخذ العينات الصوتية",
        text_check_rate: "تغطية أخذ عينات النص",
        sample_execute_time: "متوسط وقت أخذ العينات",
        review_execute_time: "متوسط مدة معالجة الشكاوى",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "لوحة قواعد فحص الجودة",
          agent: "تصنيفات خدمة العملاء",
        },
        tableTips: {
          conditions:
            "<Div> فرز العدد الإجمالي لحوارات الاتصال التي تنتهك قواعد فحص الجودة والامتثال. <Br/>\nعنصر فحص الجودة: اسم نقطة فحص الجودة <br />\nالعدد الإجمالي لحوارات المرور غير القانونية: العدد الإجمالي لحوارات المرور غير القانونية في عنصر فحص الجودة هذا <br />\nنسبة الانتهاكات: نسبة الانتهاكات = العدد الإجمالي لحوارات المرور غير القانونية/العدد الإجمالي لحوارات المرور </div>",
          agent:
            "<Div> فرز متوسط درجات المقاعد <br />\nاسم خدمة العملاء: اسم خدمة العملاء <br />\nمجموعة خدمة العملاء: مجموعة خدمة العملاء (المعلومات التنظيمية في معلومات خدمة العملاء)<br />\nإجمالي عدد الحوارات التي تم الحصول عليها: إجمالي عدد محادثات فحص الجودة لخدمة العملاء <br />\nمتوسط درجة فحص الجودة: متوسط درجة فحص الجودة لخدمة العملاء (إجمالي عدد الحوارات)</div>",
        },
        tips: {
          total: "إجمالي عدد الاتصالات/المحادثات التي تدخل مهمة فحص الجودة",
          agentTotal:
            "العدد الإجمالي لخدمة العملاء لفحص الجودة التي تدخل مهمة فحص الجودة",
          avg: "متوسط درجات درجات فحص الجودة",
          violation: "عدد انتهاكات فحص الجودة لجميع عمليات فحص الجودة",
          compliance:
            "عدد مرات الامتثال لبنود فحص الجودة في عملية فحص الجودة من خلال الحوار",
          check: "مجموع عمليات أخذ العينات اليدوية",
          appeal: "مجموع عدد الشكاوى المقدمة من مجموع الحوار",
          review: "مجموع عدد حالات المراجعة المقدمة من مجموع الحوارات العامة",
        },
        subTips: {
          violation:
            "معدل المخالفات: إجمالي عدد المخالفات/إجمالي عدد عناصر فحص الجودة * 100 ٪",
          compliance:
            "معدل الامتثال: إجمالي عدد مرات الامتثال/إجمالي عدد مرات فحص الجودة * 100 ٪",
          check:
            "تغطية أخذ العينات: إجمالي عدد عمليات التفتيش اليدوي/إجمالي فحص الجودة * 100 ٪",
          appeal: "معدل الشكاوى: إجمالي عدد الطعون/إجمالي فحص الجودة * 100 ٪",
          review:
            "معدل المراجعة: إجمالي عدد المراجعة/إجمالي فحص الجودة * 100 ٪",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "إعدادات النظام",
          systemName: "اسم النظام",
          speakerRecognize: "التعرف على المتصل",
          massage: "إشعار الرسالة",
          evaluate: "التقييم المعرفي الاصطناعي",
          evaluateTip:
            "بعد الفتح ، حدد فحص عشوائي سريع في تفاصيل فحص الجودة ، وسيطلب التقييم اليدوي",
          speakerRecognizeTip:
            "يتطلب الوصول إلى تسجيل مكالمات الطرف الثالث من خلال واجهة برمجة التطبيقات تحديد ما إذا كانت القناة اليسرى هي خدمة العملاء أو العميل",
          speakerRecognizeTip2:
            "يحدد التسجيل أحادي القناة دور خدمة العملاء من خلال التعرف على النص وتكوين الدور",
          enabledImSendStatusFilter: "تصفية رسالة فحص جودة النص",
          enabledImSendStatusFilterTips:
            "لا يتضمن محتوى الكشف عن كلمات المشغل لفحص الجودة الرسائل التي تكون حالتها [تم سحبها] ، [فشل الإرسال] ، [رسالة غير مقروءة]",
          lang: "إعدادات لغة النظام",
          zh: "الصينية المبسطة",
          en: "English",
          tw: "الصينية التقليدية",
        },
        template: {
          largeModelSettings: "إعداد نموذج كبير",
          indonesian: "لغة إندونيسية",
          vietnamese: "الفيتنامية",
          portuguese: "البرتغالية",
          malay: "لغة الملايو",
          french: "الفرنسية",
          spanish: "الأسبانية",
          japanese: "اليابانية",
          thai: "اللغة التايلاندية",
          blockSystemSendingMessages: "نظام منع إرسال الرسائل",
          textDataDesensitization: "إزالة حساسية البيانات النصية",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "يؤدي فتح المفتاح إلى عرض قائمة منسدلة لحقل إزالة الحساسية (يدعم الاختيار المتعدد) ، وحقل إزالة الحساسية: اسم الشخص ، ورقم الهاتف المحمول ، والعنوان",
          desensitizingField: "حقل إزالة الحساسية",
          name: "اسم الشخص",
          cellphoneNumber: "رقم الهاتف المحمول",
          address: "العنوان",
          dataCleaning: "تنظيف البيانات",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "بعد الإعداد ، سيتم تنظيف حوار فحص الجودة وبيانات جلسة WeChat الخاصة بالمؤسسة بانتظام ولا يمكن استعادتها. يرجى توخي الحذر",
          newCleaningRules: "قواعد تنظيف جديدة",
          qualityInspectionRuleApproval: "الموافقة على قواعد فحص الجودة",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "بعد الفتح ، يجب الموافقة على مراجعة قواعد فحص الجودة قبل أن تصبح سارية المفعول",
          maskOfflineMessageUnread: "حظر الرسائل غير المقروءة حاليا",
          privacyNumberProtection: "حماية رقم الخصوصية",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            'بعد الفتح ، تمت إزالة حقل "هاتف العميل" في المكالمة ، والذي تم عرضه على أنه 1881 *****',
          shieldingWithdrawn: "تم سحب الحماية",
          maskSendingFailed: "فشل إرسال الحظر",
        },
        component: {
          conversation: "مكالمة هاتفية",
          dialogue: "الحوار",
          weCom: "الشركات WeChat",
          day: "يوم",
          week: "الأسبوع",
          month: "الشهر",
          year: "سنوات",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "نطاق التنظيف",
            dataCleaningTimeSetting: "إعداد وقت تنظيف البيانات",
            afterCleanUpTheData: "بعد تنظيف البيانات",
            dataAfterComplaintCompletion: "بيانات ما بعد إتمام الشكوى",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "التنفيذ الناجح",
          saveSuccessful: "حفظ النجاح",
          speechRecognition: "التعرف على الكلام",
          reExecuteErrorCorrection: "إعادة تنفيذ تصحيح الأخطاء",
          confirmExecution: "تأكيد التنفيذ",
          voiceDetection: "الكشف عن الصوت",
          noiseDetectionPowerLevel: "الكشف عن الضوضاء powerLevel ≤",
          pleaseEnterTheThreshold: "الرجاء إدخال العتبة",
          volumeDetectionPowerLevel: "كشف مستوى الصوت powerLevel ≥",
          reset: "إعادة التعيين",
          preserve: "حفظ",
        },
        coachTemplate: {
          french: "الفرنسية",
          spanish: "الأسبانية",
          japanese: "اليابانية",
          thai: "اللغة التايلاندية",
          loginPopupWindow: "تسجيل الدخول إلى النافذة المنبثقة",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "بعد الفتح ، سيتم تقديم تذكير من النافذة المنبثقة عند الدخول إلى نظام السجال الذكي",
          title: "العنوان",
          text: "النص",
          checkBoxContent: "ضع علامة على محتوى المربع",
          informedConsentForm: "الموافقة المستنيرة",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "لمساعدتك في تحسين مهاراتك في الاتصال والتحدث ، وكذلك تطبيق المعرفة المتخصصة ، مما يسمح لك بالتدرب في أي وقت وفي أي مكان وتلقي ردود الفعل في الوقت الحقيقي ، سنقوم بجمع المعلومات التالية من خلال هذه المنصة:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "لقد قرأت هذه الموافقة المستنيرة وفهمت تمامًا ، وأعلن رسميًا أنني أوافق على معالجة معلوماتي الشخصية الحساسة وفقًا للغرض والطريقة الموضحة في نموذج الموافقة المستنيرة هذا",
        },
      },
      systemIntegration: {
        title: "إعدادات النظام",
        ccIntegrationTips:
          "وثيقة تعليمات مركز الاتصال Udesk لفحص الجودة الذكية",
        unauthorized: "غير مصرح به",
        authorized: "مأذون به",
        udeskDomain: "اسم نطاق نظام خدمة العملاء",
        udeskToken: "مفتاح نظام خدمة العملاء",
        udeskAdminEmail: "حساب مسؤول نظام خدمة العملاء",
        password: "كلمة مرور حساب المسؤول",
        applyAuth: "التفويض",
        cancelAuth: "إلغاء التفويض",
        reApplyAuth: "إعادة التفويض",
        keyTips:
          "إدارة الخلفية لنظام خدمة العملاء-API Customs Token في تسجيل الدخول الفردي",
      },
      customerField: {
        title: "حقل العميل",
        table: {
          propName: "اسم الحقل",
          propDesc: "وصف الحقل",
          valueType: "نوع الحقل",
          propType: "طريقة الإنشاء",
          createTime: "إنشاء الوقت",
          canNotEdit: "لا يدعم تحرير حقول النظام",
        },
        edit: "تحرير الحقول",
        new: "حقل جديد",
      },
      wechatDocking: {
        title: "الالتحام الدقيق",
        formLabels: {
          companyId: "معرف المؤسسة",
          communicationKey: "مفتاح الاتصال",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "حدث رد عنوان URL",
          applicationName: "اسم التطبيق",
          applicationId: "معرف تطبيق الويب",
          applicationSecret: "تطبيق secret",
        },
        formTitle: {
          initialForm: "الإعداد الأولي",
          synchronizeForm: "مزامنة دفتر العناوين",
          settingForm: "تطبيق الإعدادات",
        },
        qrCodeUrlTip:
          "اطلب من المسؤول مسح رمز الاستجابة السريعة أدناه لتطوير تطبيقات الربط",
      },
      taobao: {
        formLabels: {
          name: "اسم المتجر",
          account: "رقم الحساب",
          password: "كلمة السر",
          confirm: "تأكيد كلمة المرور",
          authorization: "التفويض",
          switchOn: "افتح",
          switchOff: "أغلق",
        },
        tableLabels: {
          name: "اسم المتجر",
          account: "رقم الحساب",
          authorization: "التفويض",
          updateUser: "آخر تحديث للناس",
          updateTime: "آخر تحديث",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "اسم المجال من المستوى الثاني Weifeng",
          secretKeyTip:
            "مفتاح المستأجر السري ، تم الحصول عليه في نقطة إرساء النظام الخلفي لنظام Weifeng",
        },
      },
      user: {
        index: {
          name: "إدارة الموظفين",
          existingUsers: "الموظفين",
          deleteSuccess: "حذف النجاح",
          deleteFailed: "فشل الحذف",
        },
        detail: {
          accountPrompt: "الرجاء إدخال البريد الإلكتروني",
          resetPassword: "إعادة تعيين كلمة المرور",
          passwordPrompt: "كلمة المرور (الجمع بين 6-14 بت من الحروف والأرقام)",
          originPasswordPrompt: "الرجاء إدخال كلمة المرور الأولية",
          resumePasswordPrompt: "الرجاء إعادة إدخال كلمة المرور",
          passwordFormWrong:
            "الرجاء إدخال كلمة المرور 6-14 حرفا مع مجموعة من الأرقام!",
          resumePasswordWrong: "كلمات المرور غير متسقة مرتين!",
          suppertImageType: "دعم jpg ، gif ، png ، الحد الأقصى 500Kb",
          namePrompt: "من فضلك أدخل الاسم",
          nickNamePrompt: "الرجاء إدخال الاسم المستعار",
          telephonePrompt: "الرجاء إدخال رقم الهاتف",
          roleSelectedPrompt: "يرجى اختيار الدور",
          agentIdPrompt: "من فضلك أدخل رقم العمل",
          disabled: "تعطيل",
          enabled: "تمكين",
          reloadBowerInfo:
            "تغيير معلومات المستخدم ، هل تحتاج إلى إعادة تحديث المتصفح ؟",
          saveUserError:
            "فشل حفظ معلومات المستخدم ، يرجى المحاولة مرة أخرى في وقت لاحق",
          resetPasswordSuccess: "نجاح إعادة تعيين كلمة المرور",
          resetPasswordFailed:
            "فشل إعادة تعيين كلمة المرور ، يرجى المحاولة مرة أخرى في وقت لاحق",
        },
      },
      userGroups: {
        index: {
          name: "إدارة مجموعة الموظفين",
          existingUserGroups: "مجموعة الموظفين الحالية",
        },
        detail: {},
      },
      role: {
        index: { name: "إدارة الأدوار", existingRoles: "الأدوار الحالية" },
        edit: {
          editRole: "تحرير الأدوار",
          createRole: "إنشاء شخصية",
          basicInfo: "المعلومات الأساسية",
          taskPermissions: "أذونات المهام",
          existingTasks: "المهام الحالية",
        },
      },
      anomalousData: {
        title: "بيانات غير طبيعية",
        list: {
          selectHolder: "يرجى اختيار مصدر البيانات",
          businessTime: "وقت العمل",
          businessId: "الأعمال Id",
          export: "تصدير",
        },
      },
      templateComponent: {
        list: {
          title: "سوق القالب",
          name: "اسم القالب",
          searchHolder: "يرجى إدخال المحتوى الذي تريد التحقق منه",
          update: "التحديثات",
          sort: "طريقة الفرز",
          moduleType: "نوع القالب",
          moduleSourceType: "أنواع قابلة للتطبيق",
          fetchNum: "عدد مرات الحصول عليها",
          newest: "آخر التحديثات",
          usedMost: "الحصول على معظم",
          call: "مكالمة هاتفية",
          dialogue: "الحوار",
          waiting: "في انتظار المراجعة",
          cancel: "إلغاء الإصدار",
          success: "تمت الموافقة عليه ، تم نشره",
          failed: "رفض المراجعة",
          no: "غير قابل للترقية",
          ok: "يمكن ترقيته",
          all: "كل شيء",
          delete: "حذف النجاح",
          downOk: "تم إزالتنا بنجاح",
          withdraw: "سحب ناجح",
          updateOk: "نجاح الترقية",
          get: "الحصول على",
          width: "السحب",
          getOk: "الحصول على النجاح",
          orgName: "المنظمة التي تنتمي إليها",
          nowVersion: "الإصدار الحالي",
          lastedVersion: "أحدث إصدار",
          appStatus: "حالة التطبيق",
          actions: "العملية",
          down: "أزل من الرفوف",
          updateTop: "ترقية",
          check: "عرض",
          deleteWhen: "هل تم تأكيد الحذف ؟",
          yes: "نعم",
          noo: "لا",
          deleteOk: "حذف",
          permissible: "السماح",
          noPermissible: "غير مسموح به",
          openUp: "مفتوح",
          openDown: "غير مفتوح",
          task: "مهام فحص الجودة",
          point: "نقطة فحص الجودة",
          tag: "العلامات الدلالية",
          muti: "متعدد العناصر",
          inter: "سؤال واحد ، إجابة واحدة",
          info: "كيانات المعلومات",
          concept: "الكلمات المفاهيمية",
          detail: "تفاصيل الوحدة",
          infomation: "المعلومات الأساسية",
          detailConfig: "تفاصيل التكوين",
          twoEdit: "التحرير الثانوي",
          mustUpdate: "ترقية على الفور",
          descition: "وصف قالب",
          descitionSize: "لا يمكن أن يتجاوز وصف القالب 250 كلمة",
          stencilsDetail: "تفاصيل القالب",
          updatedRecords: "تحديث السجلات",
          version: "رقم الإصدار",
          updateDescription: "تحديث الوصف",
          myModule: "قالب بلدي",
          addModule: "وحدات جديدة",
          publishVersion: "إصدار الإصدار",
          earliestVersion: "أقدم نسخة",
          edit: "تحرير",
          add: "جديد",
          configNo: "لا يمكن أن تكون تفاصيل تكوين الوحدة فارغة",
          updateNo: "لا يمكن أن تكون تعليمات التحديث فارغة",
          editSuccess: "مراجعة ناجحة",
          saveSuccess: "حفظ النجاح",
          newer: "التحديثات",
          sumbit: "المقدمة",
          cancelName: "إلغاء",
          nameRequired: "اسم الوحدة هو حقل مطلوب",
          nameSize: "لا يمكن أن يتجاوز اسم الوحدة 16 كلمة",
          publishStatus: "حالة النشر",
          submitUpdate: "تقديم التحديثات",
          updateVersion: "نسخة محدثة",
          updateExplain: "تحديث الوصف",
        },
      },
      customDataSource: {
        amazonEcommerce: "الأمازون التجارة الإلكترونية",
        youtubeVideo: "يوتيوب فيديو",
        amazon: {
          component: {
            queuing: "في الطابور",
            notStarted: "لم تبدأ",
            inProgress: "قيد العمل",
            success: "النجاح",
            failed: "فشل",
            successfullyCreated: "خلق النجاح",
            editedSuccessfully: "نجاح التحرير",
            operationSucceeded: "عملية ناجحة",
            deletedSuccessfully: "حذف النجاح",
            link: "الروابط",
            grabState: "حالة الزحف",
            updateTime: "وقت التحديث",
            grab: "الاستيلاء",
            edit: "تحرير",
          },
          template: {
            amazonEcommerce: "الأمازون التجارة الإلكترونية",
            thisProduct: "هذا المنتج",
            competition: "المنتجات المنافسة",
            new: "جديد",
            edit: "تحرير",
            link: "الروابط",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "يوتيوب فيديو",
            thisProduct: "هذا المنتج",
            competition: "المنتجات المنافسة",
            new: "جديد",
            edit: "تحرير",
            link: "الروابط",
          },
          component: {
            queuing: "في الطابور",
            notStarted: "لم تبدأ",
            inProgress: "قيد العمل",
            success: "النجاح",
            failed: "فشل",
            successfullyCreated: "خلق النجاح",
            editedSuccessfully: "نجاح التحرير",
            operationSucceeded: "عملية ناجحة",
            deletedSuccessfully: "حذف النجاح",
            youTubeVideoLink: "يوتيوب رابط الفيديو",
            grabState: "حالة الزحف",
            updateTime: "وقت التحديث",
            grab: "الاستيلاء",
            edit: "تحرير",
          },
        },
      },
    },
    demo: {
      title: "فحص الجودة الذكية",
      template: "اختيار قالب فحص الجودة",
      radio: "اختيار شكل التسجيل",
      uploadAudio: "تحميل التسجيلات",
      uploadAudioTip:
        "يدعم تحميل التسجيلات تنسيقات wav و mp3 فقط ، ولا يزيد حجمها عن 1G",
      uploadText: "تحميل النص",
      uploadTextTip:
        "يدعم تحميل النص تنسيقات srt و txt فقط ، ولا يتجاوز الحجم 10M ، وبعد تحميل النص ، لن يتم استخدام ASR لتحليل ملفات التسجيل",
      uploadSuccess: "تم تحميل ملفات التسجيل بنجاح",
      uploadFailed: "فشل تحميل ملفات التسجيل",
      startQualityInspection: "بدء فحص الجودة",
      qualityInspectionStarted: "قيد فحص الجودة",
      downloadText: "تحميل النص",
      checking: "تقدم فحص الجودة",
      checkingTip: "من المتوقع أن يستغرق بضع دقائق ، يرجى التحلي بالصبر",
      noCheckContent: "لا توجد نتائج فحص الجودة",
      noTextDownload: "لا يوجد نص فحص الجودة للتنزيل!",
      pleaseCheckParams:
        "يرجى التحقق من اختيار القالب ونوع الصوت وتحميل ملفات التسجيل",
      inspectionFailed: "فشل فحص الجودة ، يرجى المحاولة مرة أخرى لاحقًا!",
    },
    wechatQa: {
      baseInfo: { title: "المعلومات الأساسية" },
      conversationSetting: {
        title: "إعدادات الجلسة",
        segmentationStrategy: "استراتيجية التقطيع",
        rule: "استراتيجية",
        time: "تقسيم الوقت",
        daily: "كل يوم",
        passScore: "نقاط تأهيل الجلسة",
        ruleLogic: "تنفيذ المنطق",
      },
      message: {
        title: "دفع الأحداث",
        table: {
          index: "الرقم التسلسلي",
          name: "اسم",
          type: "طريقة الدفع",
          desc: "الوصف",
          status: "الحالة",
        },
        subTitle: "قائمة الأحداث",
      },
      checkPoint: {
        title: "نموذج تحليلي",
        groups: "تجميع النماذج",
        newClassification: "مجموعة جديدة",
        editClassification: "تجميع التحرير",
        benchmarkScore: "النقاط المرجعية",
        pointCount: "نموذج التحليل المشترك تحت التصنيف الحالي",
        openPointCount: "عدد التمكين هو",
        closedPointCount: "عدد التعطيل هو",
        scoreLowerLimit: "الحد الأدنى للدرجة",
        scoreUpperLimit: "الحد الأقصى للنتيجة",
        classificationName: "اسم المجموعة",
        classificationNamePlaceHolder: "الرجاء إدخال اسم المجموعة",
        classificationRemark: "وصف المجموعة",
        classificationRemarkPlaceHolder: "الرجاء إدخال وصف المجموعة",
      },
      chartRecord: {
        title: "محادثة الشركة WeChat",
        table: {
          id: "معرف الجلسة",
          type: "نوع الجلسة",
          startTime: "وقت بدء الجلسة",
          endTime: "آخر وقت انتهاء الجلسة",
          groupId: "معرف المجموعة",
          client: "العملاء",
          customer: "خدمة العملاء",
          averageScore: "متوسط درجة فحص الجودة",
        },
        detail: {
          title: "تفاصيل محادثة الشركة WeChat",
          inspectionInfo: "تحليل المعلومات",
          chartInfo: "معلومات الجلسة",
          tasks: "المهام التحليلية",
          avgScore: "تحليل متوسط الدرجات",
          qualified: "مؤهل",
          unqualified: "غير مؤهل",
          taskTitle: "قالب تحليل قابل للتطبيق",
          info: "تحليل المعلومات في الوقت الحقيقي",
          today: "أخبار اليوم",
          history: "رسالة تاريخية",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "يرجى إدخال الملاحظات/اللقب",
                pleaseEnterTheGroupChatName:
                  "الرجاء إدخال اسم الدردشة الجماعية",
              },
            },
          },
          template: { whole: "كل شيء" },
        },
      },
      realTimeAnalysis: {
        title: "تحليل الوقت الحقيقي",
        table: {
          name: "اسم المشهد",
          count: "تحليل عدد النماذج",
          baseScore: "النقاط المرجعية",
          passScore: "نقاط التأهيل",
          maxScore: "الحد الأقصى للنتيجة",
          minScore: "الحد الأدنى للدرجة",
          status: "الحالة",
          actions: "العملية",
        },
        detail: {
          title: "تحليل المشهد في الوقت الحقيقي",
          form: {
            name: "اسم المشهد",
            prevCondition: "شروط تشغيل المشهد",
            prevConditionToolTip:
              "إذا كان الشرط فارغًا ، يتم تحليل هذا المشهد افتراضيًا",
            prevConditionError:
              "لا يمكن أن يتزامن تشغيل المشهد مع النموذج التحليلي",
            scoreInputPlaceholder: "أدخل النتيجة",
            baseScore: "النقاط المرجعية",
            status: "الحالة",
            passScore: "نقاط التأهيل",
            maxScore: "الحد الأقصى للنتيجة",
            minScore: "الحد الأدنى من الدرجة",
            analysisModel: "نموذج تحليلي",
            message: "إشعار الزناد",
            pushType: "طريقة الدفع",
            createAnalysisModel: "إضافة نموذج",
            scoreUpperLimitError:
              "لا يمكن أن يكون الحد الأدنى للدرجة أكبر من الحد الأعلى",
            table: { name: "اسم النموذج", rule: "القواعد", score: "نقاط" },
            pushConfigSwitchTip1: "عندما يظهر المشهد",
            pushConfigSwitchTip2: "مرات وما فوق ، دفع الإخطارات",
          },
        },
      },
      smartCustomerTag: {
        title: "علامات العملاء الذكية",
        table: {
          name: "اسم القاعدة",
          tags: "تسميات العملاء الصغيرة للشركات",
          status: "تمكين الحالة",
          createUser: "مؤسس",
          createTime: "إنشاء الوقت",
        },
        detail: {
          name: "اسم القاعدة",
          condition: "إعداد القواعد",
          logic: "المنطق",
          status: "تمكين الحالة",
          tags: "اختيار علامات التبويب",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "عدد علامات العملاء" },
        },
        messageFiltering: {
          template: {
            edit: "تحرير",
            messageContent: "محتوى الرسالة",
            messageTypeID: "معرف نوع الرسالة",
            messageTypeDescription: "وصف نوع الرسالة",
            messageFiltering: "تصفية الرسائل",
            suitableForFilteringDataWithoutQualityInspection:
              "مناسبة لتصفية البيانات التي لا تتطلب فحص الجودة",
            create: "إنشاء",
            messageDescription: "وصف الرسالة",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "وقت التحدث",
          sessionType: "نوع الجلسة",
          whole: "كل شيء",
          staff: "الموظفون",
          customer: "العملاء",
          callKeywords: "الكلمات الرئيسية للمكالمات",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails: "تفاصيل الاتصال الصوتي الصغير",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "أرشيف الجلسة",
        voiceCall: "المكالمات الصوتية",
        noMoreVoiceCalls: "لا مزيد من المكالمات الصوتية",
        enterpriseWechatVoiceCall: "المكالمات الصوتية للشركات WeChat",
        enterpriseWechatConversationDetails: "تفاصيل محادثة الشركة WeChat",
        previous: "المادة السابقة",
        next: "التالي",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "مهمة تحليل المحادثة الدقيقة للمؤسسة",
          enterpriseAndMicroVoiceAnalysisTask:
            "مهمة تحليل الصوت الصغير للمؤسسة",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat:
          "تحليل الدردشة الجماعية للشركات WeChat",
      },
      wechatQaSettingEntry: { messageFiltering: "تصفية الرسائل" },
    },
    businessAnalysis: {
      keyword: "اسم التقرير",
      createUser: "مؤسس",
      createTime: "إنشاء الوقت",
      updateTime: "وقت التحديث",
    },
    semanticIntelligence: {
      applicationMessageType: "نوع رسالة التطبيق",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "يرجى تحسين التكوين",
              generatePromptLanguage: "توليد تذكير",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "ما إذا كان يفي",
              gradedEvaluation: "تقييم الدرجات",
            },
          },
          training: {
            index: {
              pleaseEnter: "الرجاء إدخال",
              pleaseGeneratePrompt: "يرجى إنشاء تذكير",
              pleaseSelectADialogue: "يرجى اختيار المحادثة",
              training: "التدريب",
              dataType: "نوع البيانات:",
              voiceCall: "المكالمات الصوتية",
              textDialogue: "حوار نصي",
              generate: "توليد",
              qualityInspectionResults: "نتائج فحص الجودة:",
              hit: "ضرب الكلام:",
            },
          },
        },
        detail: {
          index: {
            modelName: "اسم النموذج",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "يرجى إدخال اسم النموذج ، على سبيل المثال: ما إذا كانت خدمة العملاء لا تعمل",
            modelDescription: "وصف النموذج",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "يمكنك وصف المحتوى الذي تحتاج إلى اكتشافه بالتفصيل لتسهيل فهم النموذج ، مثل: ما إذا كانت خدمة العملاء لا تفعل شيئًا ، أي أن خدمة العملاء لديها رد على سؤال العميل ، ولكن لم يتم التعامل مع سؤال العميل أو أن محتوى الرد لا علاقة له بالرد.",
            validity: "فعالية",
            effective: "فعالة",
            invalid: "غير صالح",
            type: "نوع",
            hintLanguage: "تذكير",
            editIntelligentModel: "تحرير النماذج الذكية",
            createANewIntelligentModel: "نموذج ذكي جديد",
          },
        },
        index: {
          modelName: "اسم النموذج",
          modelDescription: "وصف النموذج",
          type: "نوع",
          whetherItMeetsTheRequirements: "ما إذا كان يفي",
          gradedEvaluation: "تقييم الدرجات",
          validity: "فعالية",
          intelligentModel: "نموذج ذكي",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "تنطبق على فحص الجودة الذاتي (مثل ما إذا كان موقف خدمة فحص الجودة متحمسًا ، وما إذا كانت الخدمة صبورًا ، وما إلى ذلك)",
        },
      },
      clusterAnalysis: "تحليل المجموعات",
      speechMining: "حفر الكلام",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "يدعم استيراد مجموعة من كيانات المعلومات من خلال واجهة API ، ولا يدعم اسم API التحرير بعد الحفظ",
        },
        component: { nothing: "لا شيء" },
      },
      knowledgeBase: {
        index: {
          classification: "تصنيف",
          import: "استيراد",
          importKnowledgeBase: "استيراد قاعدة المعرفة",
          moveKnowledgePointsInBatch: "نقاط المعرفة المتنقلة على دفعات",
          moveTo: "الانتقال إلى:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "يرجى اختيار نقاط المعرفة التي تحتاج إلى التحرك على دفعات",
        },
        detail: {
          index: { intrasentence: "داخل الجملة", afterProblem: "بعد السؤال" },
        },
      },
      semanticTags: { component: { nothing: "لا شيء" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "كلمات حساسة",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "الكلمات الحساسة: يمكن تكوين الكلمات الحساسة بالكلمات ذات الاهتمام التجاري ، والتي يمكن استخدامها لاكتشاف سحابة الكلمات الحساسة في الرأي العام",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "كلمات حساسة جديدة",
            editSensitiveWords: "تحرير الكلمات الحساسة",
            sensitiveWords: "كلمات حساسة",
          },
          template: {
            sensitiveWords: "كلمات حساسة",
            sensitiveWordsCannotBeEmpty:
              "الكلمات الحساسة لا يمكن أن تكون فارغة",
            pleaseEnterSensitiveWordsSeparatedBy:
              'يرجى إدخال كلمات حساسة ، يتم فصل الكلمات الحساسة المتعددة بـ "،"',
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario: "يرجى اختيار تطبيق المشهد",
            keyWord: "الكلمات الرئيسية",
            pleaseEnterKeywords: "الرجاء إدخال الكلمات الرئيسية",
            keywordLengthWithinCharacters: "طول الكلمة الرئيسية ضمن 40 حرفا",
            dataSources: "مصادر البيانات",
            pleaseSelectADataSource: "يرجى اختيار مصدر البيانات",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "تم الانتهاء من التجميع الحالي ، وستنتج إعادة التحرير بيانات تجميع جديدة لن تغطي بيانات التجميع المكتملة ، يرجى تأكيد",
            savingSucceeded: "حفظ النجاح",
            clusteringStart: "بدء التجميع",
            clustering: "التجميع",
            edit: "تحرير",
            newlyBuild: "جديد",
            clusterName: "اسم الكتلة",
            pleaseEnterAName: "الرجاء إدخال الاسم",
            withinCharactersOfTheName: "طول الاسم ضمن 40 حرفا",
            speaker: "المتحدث",
            pleaseSelectASpeaker: "يرجى اختيار المتحدث",
            sessionDate: "تاريخ الجلسة",
            pleaseEnterTheSessionDate: "يرجى إدخال تاريخ الجلسة",
            selectUpToMonthOfHistoricalSessionData:
              "حدد ما يصل إلى شهر واحد من بيانات الجلسة التاريخية",
            automaticallyClusterDailyNewData:
              "التجميع التلقائي البيانات الجديدة اليومية",
            conversationChannel: "قنوات المحادثة",
            pleaseSelectAConversationChannel: "يرجى اختيار قناة المحادثة",
            applicationScenario: "تطبيق المشهد",
            clusteringRules: "قواعد التجميع",
            incompleteClusteringRuleSettings: "إعداد قاعدة التجميع غير مكتمل",
            startClustering: "ابدأ في التجميع",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask: "يرجى اختيار مهمة فحص الجودة",
              beEqualTo: "يساوي",
            },
          },
        },
        detail: {
          index: {
            theme: "الموضوع",
            numberOfCorpora: "عدد المواد",
            corpusCoverage: "تغطية",
            clusterDetails: "تفاصيل التجميع",
          },
          ranking: {
            hotWords: "الكلمات الساخنة",
            numberOfCorpora: "عدد المواد",
            speaker: "المتحدث",
            sit: "اجلس",
            passenger: "الضيف",
            hotSentence: "الجمل الساخنة",
            corpusDetails: "تفاصيل",
          },
        },
        index: {
          delete: "حذف",
          deletionSucceeded: "حذف النجاح",
          clusterName: "اسم الكتلة",
          speaker: "المتحدث",
          creationTime: "إنشاء الوقت",
          state: "الحالة",
          operation: "العملية",
          see: "عرض",
          edit: "تحرير",
          clusterAnalysis: "تحليل المجموعات",
          newCluster: "مجموعات جديدة",
          clusteringStatus: "حالة التجميع",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "أدخل الكلمات الرئيسية للبحث عن الاعتراضات",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "مكتبة الهاتف",
                recommendedScripts: "الكلمات الموصى بها",
                common: "المجموع",
                strip: "المادة",
                scriptFavorites: "المفضلة",
              },
              conditionPostfix: {
                detectRoles: "كشف الدور",
                detectionRange: "نطاق الكشف",
                sentence: "الجملة",
              },
              customerFilter: {
                scriptFiltering: "تصفية الكلام",
                cancel: "إلغاء",
              },
              index: {
                viewDetails: "عرض التفاصيل",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "هل أكدت أنك تريد التخلي عنها ؟ لن يتم استعادتها بعد التخلي عنها",
                abandonment: "التخلي",
                deletionSucceeded: "حذف النجاح",
                passenger: "الضيف",
                sit: "اجلس",
                adopted: "تم اعتماده",
                determine: "تحديد",
                cancel: "إلغاء",
                adopt: "اعتماد",
              },
              pageRightFrom: {
                savingSucceeded: "حفظ النجاح",
                pleaseEnterTheScriptContent: "الرجاء إدخال محتوى الكلام",
                pleaseSelectAFavoriteGroup: "يرجى اختيار مجموعة المفضلة",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "قاعدة بيانات معالجة الاعتراضات",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "يرجى اختيار استخدام الكلام",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "التعامل مع المعارضة المفضلة",
            },
            pageRightTable: {
              likeCancel: "إلغاء الإعجاب",
              likeSuccessfully: "نجاح الإعجاب",
              adoptScripts: "اعتماد الكلام",
              operation: "العملية",
              viewConversationDetails: "عرض تفاصيل المحادثة",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "مكتبة نقاط بيع المنتج",
              },
              pageRightTable: {
                keyPointsOfScript: "النقاط الرئيسية في الكلام",
              },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "التعامل مع المعارضة المفضلة",
              newGroup: "مجموعة جديدة",
              editGroup: "تجميع التحرير",
              addGroup: "إضافة مجموعات",
              savingSucceeded: "حفظ النجاح",
              groupName: "اسم المجموعة",
              pleaseEnterTheGroupName: "الرجاء إدخال اسم المجموعة",
              groupNameWithinCharacters: "طول اسم المجموعة 20 حرفا",
              deletionSucceeded: "حذف النجاح",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings: "الإعدادات الموصى بها للكلمات",
                },
                rightForm: {
                  savingSucceeded: "حفظ النجاح",
                  intelligentRecommendation: "توصية ذكية",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "يتم عرض مهارات الكلام الممتازة المعتمدة تلقائيًا في الشريط الجانبي للمحادثة مع اعتراضات مماثلة. يمكن للموظفين التحقق مباشرة من الكلمات الموصى بها للتعلم في صفحة تفاصيل إعادة المحادثة",
                  objectionsIncludedByDefault: "الاعتراضات الواردة افتراضيا",
                  addRecommendationTriggerSemantics:
                    "إضافة دلالات الزناد الموصى بها",
                  remindEmployeesToView: "تذكير الموظفين للتحقق",
                  shareWithMoreEmployees: "مشاركة المزيد من الموظفين",
                  allHands: "جميع الموظفين",
                },
                smartToolbar: { selectSemanticTag: "اختيار العلامات الدلالية" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "هل تقوم بتصدير جميع سجلات البيانات ضمن المجموعة الحالية ؟",
                newScript: "الحديث الجديد",
                editingScript: "تحرير الكلام",
                savingSucceeded: "حفظ النجاح",
                objection: "الاعتراض",
                pleaseEnterAScriptObjection: "يرجى إدخال اعتراض على الكلام",
                speechTechnique: "الكلام",
                pleaseEnterTheScriptContent: "الرجاء إدخال محتوى الكلام",
                grouping: "مجموعات",
                pleaseSelectAFavoriteGroup: "يرجى اختيار مجموعة المفضلة",
                adoptScripts: "اعتماد الكلام",
                passenger: "الضيف",
                sit: "اجلس",
                numberOfViews: "عدد المشاهدات",
                useful: "مفيدة",
                operation: "العملية",
                edit: "تحرير",
                delete: "حذف",
                deletionSucceeded: "حذف النجاح",
                scriptRecommendationSettings: "الإعدادات الموصى بها للكلمات",
              },
            },
          },
        },
        index: {
          scriptLibrary: "مكتبة الهاتف",
          scriptMining: "حفر الكلام",
          objectionHandlingScripts: "معالجة المعارضة",
          productSellingPointScripts: "تقنية بيع المنتج",
        },
        sellingPoint: {
          index: { newCluster: "مجموعات جديدة" },
          components: {
            callDetailModal: {
              sessionDetails: "تفاصيل الجلسة",
              cancel: "إلغاء",
              determine: "تحديد",
              choice: "الاختيار",
            },
            pageCenterTable: { scriptOverview: "نظرة عامة على الكلام" },
            pageLeftTable: {
              enterSearchKeywords: "أدخل كلمة البحث الرئيسية",
              keyWord: "الكلمات الرئيسية",
            },
            pageRightTable: {
              index: {
                viewDetails: "عرض التفاصيل",
                scriptLibrary: "مكتبة الهاتف",
                scriptRefinement: "تكرير الكلام",
                adoptScript: "اعتماد الكلام",
              },
              tagsEdit: { keyPointsOfScript: "النقاط الرئيسية في الكلام" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary: "مكتبة نقاط بيع المنتج",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints: "نقاط البيع المدرجة افتراضيا",
                  },
                },
                index: {
                  keyWord: "الكلمات الرئيسية",
                  pleaseSelectAKeyword: "يرجى اختيار الكلمات الرئيسية",
                  keyPointsOfScript: "النقاط الرئيسية في الكلام",
                  pleaseInputTheKeyPointsOfTheScript:
                    "من فضلك أدخل النقاط الرئيسية",
                  viewDetails: "عرض التفاصيل",
                  pleaseChooseToAdoptScript: "يرجى اختيار استخدام الكلام",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "تطابق المنطق",
            nLPMatching: "مطابقة NLP",
            strictlyMatchByWord: "مطابقة صارمة حسب الكلمات",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "اسم نوع الرسالة",
          messageTypeID: "معرف نوع الرسالة",
          messageTypeDescription: "وصف نوع الرسالة",
          applicationMessageType: "نوع رسالة التطبيق",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "وهي مناسبة للوصول إلى بيانات القنوات المختلفة لفحص الجودة ، ويجب تصنيفها وفقًا لتعريف القناة",
        },
        detail: {
          index: {
            messageTypeName: "اسم نوع الرسالة",
            pleaseEnterAMessageTypeName: "الرجاء إدخال اسم نوع الرسالة",
            messageTypeDescription: "وصف نوع الرسالة",
            describeYourMessageTypeInDetail: "يمكنك وصف نوع رسالتك بالتفصيل",
            editMessageType: "تحرير نوع الرسالة",
            newMessageType: "نوع رسالة جديدة",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "يرجى اختيار أعضاء الفريق",
        existingFilter: "مرشحات موجودة",
        pleaseSelect: "يرجى اختيار",
        filterCondition: "شروط التصفية",
        areYouSureToDeleteTheCurrentFilter: "هل تم تأكيد إزالة المرشح الحالي ؟",
        sessionDate: "تاريخ الجلسة",
        staff: "الموظفون",
        customer: "العملاء",
        pleaseEnterCustomerName: "الرجاء إدخال اسم العميل",
        keyWord: "الكلمات الرئيسية",
        whole: "كل شيء",
        pleaseEnterSessionKeywords: "يرجى إدخال الكلمات الرئيسية في الجلسة",
        sessionDuration: "مدة الجلسة",
        sessionLabel: "مشهد المحادثة",
        pleaseSelectASessionLabel: "يرجى اختيار مشهد المحادثة",
        commentTopic: "التعليق على الموضوع",
        pleaseSelectACommentTopic: "يرجى اختيار موضوع التعليق",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "هل تم تأكيد غطاء المرشح الحالي ؟",
        overlayFilter: "مرشح التغطية",
        NewFilter: "مرشح جديد",
        newFilter: "مرشح جديد",
        filterName: "اسم المرشح",
        pleaseEnter: "الرجاء إدخال",
        allCall: "جميع المكالمات",
        percentage: "النسبة المئوية",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            'يدعم البحث عن كلمات رئيسية متعددة. يتم فصل العلاقة "أو" بفاصلة (باللغتين الصينية والإنجليزية) ، ويتم فصل العلاقة "و" بعلامة ، مثل:',
          callRecordID: "معرف سجل المكالمات",
          pleaseFillInTheCallRecordID: "يرجى ملء معرف سجل المكالمات",
          minute: "دقيقة",
          second: "ثواني",
          whole: "كل شيء",
          callRecording: "تسجيل المكالمات",
          enterpriseWechat: "الشركات WeChat",
          conversationChannel: "قنوات المحادثة",
          pleaseSelectASessionChannel: "يرجى اختيار قناة المحادثة",
          learningStatus: "حالة التعلم",
          pleaseSelectLearningStatus: "يرجى اختيار حالة التعلم",
          notLearned: "لم يتعلم",
          learned: "تعلم",
          Minutes: "دقيقة",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "شروط الترشيح فارغة ولا يتم دعم المرشحات الجديدة!",
          filterAddedSuccessfully: "تمت إضافة مرشح بنجاح!",
          failedToAddFilter: "فشل مرشح جديد!",
          filterDeletedSuccessfully: "إزالة مرشح بنجاح!",
          filterDeletionFailed: "فشل إزالة مرشح!",
          filterOverwriteSucceeded: "مرشح التغطية بنجاح!",
          filterOverwriteFailed: "فشل غطاء المرشح!",
          theme: "الموضوع",
          pleaseSelectATheme: "يرجى اختيار موضوع",
          keyEvents: "الأحداث الرئيسية",
          pleaseSelectAKeyEvent: "يرجى اختيار الأحداث الرئيسية",
        },
        filterItem: {
          startRetesting: "بدء إعادة الكشف",
          time: "الوقت",
          talkTime: "طول المكالمة",
          keyEvents: "الأحداث الرئيسية",
          totalImplementationRateOfSupervisionItems:
            "معدل التنفيذ العام للرقابة",
          sort: "فرز:",
          seeMore: "عرض المزيد",
        },
        filterStatistics: {
          conversation: "مكالمة هاتفية",
          weChat: "WeChat",
          quantity: "الكمية",
        },
      },
      caseBase: {
        index: {
          copyTo: "نسخ إلى",
          pleaseSelectTheLocationToMove: "يرجى اختيار موقع التحرك!",
          folderCopiedSuccessfully: "نسخ المجلد بنجاح!",
          cancelCollectionSuccessfully: "إلغاء المجموعة بنجاح!",
          collectionSucceeded: "جمع ناجح!",
          starCaseBase: "مكتبة حالات النجوم",
          move: "تحرك",
          rename: "إعادة تسمية",
          delete: "حذف",
          folderMovedSuccessfully: "المجلد يتحرك بنجاح!",
          fileMovedSuccessfully: "نقل الملفات بنجاح!",
          fileMoveFailed: "فشل نقل الملفات!",
          folderAddedSuccessfully: "إضافة مجلد بنجاح!",
          folderRenameSucceeded: "إعادة تسمية المجلد بنجاح!",
          fileRenameSucceeded: "إعادة تسمية الملف ناجحة!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "لن تتمكن من استعادتها بعد حذف المجلد ، هل أكدت الحذف ؟",
          deleteSucceeded: "حذف النجاح!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "بعد حذف الملف لن يتم استعادته ، هل تؤكد الحذف ؟",
          share: "مجموع",
          folders: "مجلد ،",
          calls: "مكالمة هاتفية",
          newFolder: "إضافة مجلدات",
          modifyFolder: "تعديل المجلدات",
          title: "العنوان",
          pleaseEnter: "الرجاء إدخال",
          selectMoveLocation: "اختيار موقع الحركة",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "تعلم تذكير",
          subscriptionDynamics: "ديناميكيات الاشتراك",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "الجلسات التي اشتركت فيها (ID)",
            thereAreDynamicUpdates: "هناك تحديثات ديناميكية",
            newCommentsReplies: "تعليقات/ردود إضافية",
            strip: "المادة",
            newHelp: "مساعدة جديدة",
            goAndWatch: "اذهب إلى المتفرجين",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "الرد على تعليقاتي",
            replySucceeded: "الرد بنجاح!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ لي في التعليقات",
            replySucceeded: "الرد بنجاح!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "مشاركة المكالمات الخاصة بك",
            shareCasesForYou: "شارك القضية لك",
          },
        },
        study: {
          index: {
            learned: "تعلم",
            ignored: "تم تجاهله",
            study: "التعلم",
            ignore: "تجاهل",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "استيراد ناجح",
          account: "رقم الحساب",
          password: "كلمة السر",
          cover: "تغطية",
          skip: "تخطي",
          import: "استيراد",
          importEmployees: "استيراد الموظفين",
          importPrompt: "نصائح الاستيراد",
          confirm: "تحديد",
          theDataContainsDuplicateAccountsOrNames: "هناك رقم حساب أو اسم مكرر",
          allCoverage: "تغطية كاملة",
          skipAll: "تخطي كل شيء",
          headPortrait: "الصورة الرمزية",
          fullName: "الاسم",
          nickname: "اللقب",
          excellentStaff: "موظف ممتاز",
          yes: "نعم",
          no: "لا",
          department: "الإدارات",
          accountNumberemail: "الحساب (البريد الإلكتروني)",
          telephone: "الهاتف",
          jobNumber: "رقم العمل",
          role: "الدور",
          pleaseEnterANameOrPhoneSearch:
            "الرجاء إدخال البريد الإلكتروني أو الاسم أو الهاتف للبحث",
          person: "الناس)",
          AddEmployee: "إضافة الموظفين",
        },
        edit: {
          jobNo: "رقم العمل",
          udeskCustomerServiceSystemIdentity: "Udesk هوية نظام خدمة العملاء",
          enterpriseWechatIdentity: "هوية الشركة WeChat",
          inputID: "إدخال الهوية",
          newIdentityBinding: "إضافة ربط الهوية",
          identityBinding: "ربط الهوية",
          avatarGreaterThan500kb: "الصورة الرمزية أكبر من 500 كيلوبايت",
          uploadSuccessful: "نجاح التحميل",
          upload: "تحميل",
          supportJPGGIFAndPNGUpTo500kb:
            "دعم jpg ، gif ، png ، الحد الأقصى 500Kb",
          yes: "نعم",
          no: "لا",
          accountNumber: "رقم الحساب",
          pleaseEnterTheAccountNumber: "الرجاء إدخال الحساب",
          initialPassword: "كلمة المرور الأولية",
          pleaseInputAPassword: "الرجاء إدخال كلمة السر",
          confirmPassword: "تأكيد كلمة المرور",
          thePasswordsEnteredTwiceAreDifferent:
            "كلمة المرور التي تم إدخالها مرتين ليست هي نفسها!",
          headPortrait: "الصورة الرمزية",
          fullName: "الاسم",
          pleaseEnterYourName: "من فضلك أدخل الاسم",
          nickname: "اللقب",
          department: "الإدارات",
          excellentStaff: "موظف ممتاز",
          sessionIdentity: "هوية الجلسة",
          markTheSessionRecordAsBelongingToTheEmployee:
            "سجل جلسة الوسم ينتمي إلى الموظف",
          telephone: "الهاتف",
          jobNumber: "رقم العمل",
          role: "الدور",
          pleaseSelectARole: "يرجى اختيار الدور",
          preservation: "حفظ",
          cancel: "إلغاء",
          addedSuccessfully: "إضافة النجاح!",
          modificationSucceeded: "تعديل ناجح!",
          newEmployees: "موظفين جدد",
          editEmployee: "موظفو التحرير",
        },
        detail: {
          viewDetails: "عرض التفاصيل",
          accountNumber: "رقم الحساب",
          headPortrait: "الصورة الرمزية",
          fullName: "الاسم",
          nickname: "اللقب",
          department: "الإدارات",
          excellentStaff: "موظف ممتاز",
          yes: "نعم",
          no: "لا",
          sessionIdentity: "هوية الجلسة",
          markTheSessionRecordAsBelongingToTheEmployee:
            "سجل جلسة الوسم ينتمي إلى الموظف",
          telephone: "الهاتف",
          jobNumber: "رقم العمل",
          role: "الدور",
        },
      },
      roleManage: {
        list: {
          menu: "القائمة",
          functionalCategory: "فئات وظيفية",
          functionalSubclass: "فئة وظيفية صغيرة",
          deletedSuccessfully: "حذف النجاح",
          NewRole: "شخصيات جديدة",
          role: "الدور",
          dataPermissions: "أذونات البيانات:",
          edit: "تحرير",
          areYouSureYouWantToDeleteTheRole: "تحديد دور الحذف",
          determine: "تحديد",
          cancel: "إلغاء",
          delete: "حذف",
          applicationPermission: "أذونات التطبيق",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "يمكنك فقط رؤية بيانات الجلسة الخاصة بك",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "يمكنك عرض بيانات جلسة القسم والإدارات التابعة له",
          menu: "القائمة",
          functionalCategory: "فئات وظيفية",
          functionalSubclass: "فئة وظيفية صغيرة",
          successfullyAdded: "نجاح جديد",
          modifiedSuccessfully: "مراجعة ناجحة",
          edit: "تحرير",
          cancel: "إلغاء",
          confirm: "تأكيد",
          roleName: "اسم الشخصية",
          pleaseEnterTheRoleName: "الرجاء إدخال اسم الشخصية",
          dataAuthority: "أذونات البيانات",
          youCanViewCompanyWideSessionData:
            "يمكن عرض بيانات الجلسة على مستوى الشركة",
          applicationPermission: "أذونات التطبيق",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "عملية الجلسة",
          generateProcess: "عملية توليد",
          addProcess: "إضافة عملية",
          cancel: "إلغاء",
          complete: "الانتهاء",
          edit: "تحرير",
          theSettingWillBeSuccessfulLater: "تعيين لنرى النجاح في وقت لاحق!",
          previous: "المادة السابقة",
          next: "التالي",
          debuggingMode: "وضع التصحيح",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "بعد الفتح ، إذا كانت الرسالة تتطابق مع الأحداث الرئيسية أو عناصر الإشراف أو قواعد علامة العميل أو قواعد نقطة المعرفة ، فسيتم عرض اسم القاعدة المطابق للرسالة واسم نقطة المعرفة لقاعدة المعرفة.",
          seeLater: "انظر لاحقا",
          determine: "تحديد",
          conversationalInsights: "رؤى المحادثة",
          customerInsight: "رؤى العملاء",
          knowledgePointRecommendation: "نقطة المعرفة الموصى بها",
          comment: "تعليقات",
          sessionQuality: "جودة الجلسة",
          setToListenLaterSuccessfully:
            "إعداد الاستماع إلى النجاح في وقت لاحق!",
          listenLater: "استمع لاحقا",
          seekCounselingSuccess: "البحث عن المشورة للنجاح!",
          altogether: "واحد مشترك",
          appearsAt: 'في الظهور"',
          commentsPublishedSuccessfully: "نشر التعليق بنجاح",
          callRecording: "تسجيل المكالمات",
          addedSuccessfully: "إضافة النجاح",
          shareWithColleagues: "شارك مع الزملاء",
          shareWithCustomers: "مشاركة العملاء",
          resumptionDetails: "تفاصيل إعادة النظر",
          addToCaseBase: "إضافة إلى مكتبة الحالات",
          shareCases: "تقاسم الحالات",
          seekCounseling: "طلب المشورة",
          sessionRecord: "سجل الجلسة",
          businessRecord: "سجلات العمليات",
          keywordSearch: "البحث عن الكلمات الرئيسية",
          whole: "كل شيء",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "يرجى إدخال تعليق ، أدخل @ لإعلام الآخرين ، أدخل # لإضافة موضوع",
          subscriptionSession: "جلسة الاشتراك",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "بعد الاشتراك ، عندما يكون هناك طلب جديد للمساعدة ، والتعليقات ، والردود ، ستتلقى تذكيرًا بالإخطار",
        },
      },
      coachTask: {
        index: {
          employeeName: "اسم الموظف",
          customerName: "اسم العميل",
          source: "المصدر",
          viewSessionRecords: "عرض سجل الجلسة",
          coachingStatus: "حالة الاستشارة",
          helpTime: "وقت المساعدة",
          counselingTime: "وقت الاستشارة",
          helpContent: "طلب المساعدة",
          coachingOperation: "عملية التوجيه",
          areYouSureYouWantToIgnoreIt: "هل أنت متأكد من الإهمال ؟",
          determine: "تحديد",
          cancel: "إلغاء",
          ignoreHelp: "تجاهل طلب المساعدة",
          coaching: "تقديم المشورة",
          viewCoaching: "عرض الدروس الخصوصية",
          areYouSureToReturnToCounseling:
            "هل أنت متأكد من العودة إلى التدريب ؟",
          returnToPendingCoaching: "العودة إلى التدريب",
          tutor: "مستشار",
          helpSeekingStatus: "طلب المساعدة",
          recentCoachingTime: "وقت التدريب الأخير",
          operation: "العملية",
          ignoreSuccess: "تجاهل النجاح!",
          recoverySucceeded: "استعادة ناجحة!",
          successfulCoaching: "تدريب ناجح!",
          myMission: "مهمتي",
          selectCoachingStatus: "اختيار حالة الاستشارة",
          whole: "كل شيء",
          iAskedForHelp: "أنا أطلب المساعدة",
          signOut: "الخروج",
          submit: "المقدمة",
          helpPerson: "استجدي للمساعدة",
          customer: "العملاء",
          toBeCoached: "في انتظار الاستشارة",
          counselingSuggestions: "المشورة الاستشارية",
          pleaseEnterCoachingSuggestions: "يرجى إدخال نصيحة الاستشارة",
        },
      },
      coachTaskCenter: {
        title: "مركز البعثة",
        list: {
          taskName: "اسم المهمة",
          lessonGroup: "تصنيف الدورة",
          lessonName: "الدورات التي تنتمي إليها",
          taskType: "نوع المهمة",
          taskStatus: "حالة المهمة",
          taskExecutionInfo: "حالة التنفيذ",
          totalCount: "العدد الواجب تنفيذه",
          executeCount: "عدد الأشخاص المنفذين",
          executeRate: "معدل التنفيذ",
          completeCount: "العدد المنجز",
          completeRate: "معدل الإنجاز",
          commitCount: "عدد الأشخاص الذين يستوفون المعايير",
          commitRate: "معدل الامتثال",
          excellentRate: "معدل ممتاز",
          taskTime: "وقت المهمة",
          option: "العملية",
          edit: "تحرير",
          taskDetail: "بيانات المهمة",
          delete: "حذف",
          new: "إصدار جديد",
          taskTypeAll: "كل شيء",
          taskTypePractice: "تمارين",
          taskTypeTest: "الامتحانات",
          taskStatusNotStarted: "لم تبدأ",
          taskStatusRunning: "قيد العمل",
          taskStatusEnd: "انتهى",
          taskStatusInvalid: "لم يدخل حيز التنفيذ",
          unlimitedTime: "وقت غير محدود",
          to: "إلى",
          deleteConfirm:
            "بعد الحذف سيتم إفراغ جميع سجلات التعلم في هذه المهمة ، هل تم تأكيد الحذف ؟",
          deleteSuccess: "نجاح حذف المهمة",
        },
        edit: {
          addTask: "مهام الإصدار الجديدة",
          editTask: "تحرير مهمة النشر",
          publishSuccess: "نجاح الإصدار",
          publishFailed: "فشل النشر",
          updateSuccess: "نجاح التحديث",
          updateFailed: "فشل التحديث",
          cannotBeNull: "لا يمكن أن تكون فارغة",
          explain: "وصف",
          taskDescExtra: "(سيتم عرض المحتوى هنا عند مدخل {0})",
          taskTargetObj: "كائن النشر",
          isEnable: "الحالة الفعالة",
          dialogueMode: "نموذج الحوار",
          voice: "صوت",
          text: "النص",
          userPortrait: "صورة المستخدم",
          difficultyLevel: "صعوبة",
          limitDuration: "حد طول الوقت",
          tipLabel: "نصائح الكلام",
          tipTooltip: "ما إذا كان يُسمح للطلاب بالتحقق من الكلمات المرجعية",
          limitTimes: "عدد الامتحانات",
          time: "الوقت",
          gradingStandart: "معايير التسجيل",
          gradingStandartDescAffix:
            "(النتيجة الإجمالية لعملية الحوار الحالية هي",
          gradingStandartDescSuffix:
            "النقاط ، الكلام {0} نقطة ، العاطفة {1} نقطة ، سرعة الكلام {2} نقطة)",
          completeStandart: "متطلبات إنجاز المهمة",
          byTimes: "حسب العدد",
          byScore: "اضغط على النتيجة",
          completeByTimes: "عدد التمارين",
          times: "مرة واحدة",
          score: "نقاط",
          anyTimes: "في أي وقت",
          getScore: "النتيجة",
          offLimitDuration: "فترة غير محدودة",
          onLimitDuration: "مدة محدودة",
          commitScore: "معايير الامتثال ، النتيجة",
          excellentScore: "معايير ممتازة ، النتيجة",
          offLimitTime: "وقت غير محدود",
          onLimitTime: "الحد الزمني",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "مرحبا ،" },
          callDynamic: {
            today: "اليوم",
            yesterday: "بالأمس",
            sessions: "جلسة واحدة",
          },
          todoList: {
            strip: "المادة",
            theSessionIsAddedTolistenLater:
              'تمت إضافة الجلسة إلى "الاستماع لاحقًا"',
            askYouForHelp: "أطلب منك المساعدة",
            iHopeToContactYouLater: "توقع الاتصال في وقت لاحق",
            lookingForwardToSendingTheInformationLater:
              "نتوقع إرسال المعلومات في وقت لاحق",
            dialogueRisk: "هناك نقاط خطر في الجلسة",
            tutored: "تم تقديم المشورة",
            goToCounseling: "اذهب للاستشارة",
            contacted: "تم الاتصال به",
            hasBeenSent: "تم إرسالها",
            areYouSureYouWantToIgnoreIt: "هل أنت متأكد من الإهمال ؟",
            determine: "تحديد",
            cancel: "إلغاء",
            ignore: "تجاهل",
            checked: "عرض",
          },
          callListItem: {
            strip: "المادة",
            learned: "تعلم",
            keyEvents: "الأحداث الرئيسية",
            totalImplementationRateOfSupervisionItems:
              "معدل التنفيذ العام للرقابة",
          },
        },
        index: {
          operationSucceeded: "عملية ناجحة!",
          sessionDynamics: "ديناميكيات الجلسة",
          my: "لي",
          department: "الإدارات",
          company: "شركة",
          toDoList: "المهام",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "حجم المكالمات الفعالة (عدد)",
          bestPractices: "أفضل الممارسات",
          averageDurationOfASingleCallminutes:
            "متوسط مدة المكالمة الواحدة (دقيقة)",
          totalCallVolumePCs: "إجمالي حجم المكالمات (عدد)",
          totalTalkTimehours: "إجمالي مدة المكالمة (بالساعات)",
          speakingPercentagepercentage: "نسبة الكلام (نسبة مئوية)",
          maximumContinuousSpeakingTimeminutes:
            "أطول وقت للتحدث المستمر (دقيقة)",
          numberOfIndepthDiscussionsPCs: "عدد المناقشات المتعمقة (عدد)",
          maximumCustomerTalkminutes: "الحد الأقصى لخطاب العميل (دقائق)",
          questionFrequencyPCs: "تكرار الأسئلة (عدد)",
          averageValue: "متوسط القيمة",
          selectDate: "اختيار التاريخ",
          personnel: "الموظفون",
          pleaseSelect: "يرجى اختيار",
          callRange: "نطاق المكالمات",
          conversationActivity: "أنشطة المحادثة",
          conversationAction: "عمل الجلسة",
          keyEvents: "الأحداث الرئيسية",
          callRecord: "سجل المكالمات",
          filter: "الفرز:",
          conversation: "مكالمة هاتفية",
          minute: "دقيقة",
          sale: "الموظفون",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "يناير",
            numberOfCalls: "عدد المكالمات",
            accountInformation: "معلومات الحساب",
            month: "الشهر",
            today: "اليوم",
            customerDetails: "تفاصيل العميل",
            searchKeywordsInCommunicationContent:
              "استرجاع الكلمات الرئيسية في محتوى الاتصال",
            allMembers: "جميع الأعضاء",
            reset: "إعادة التعيين",
            query: "الاستفسارات",
            allTrip: "عرض رحلة كاملة",
            contactOverview: "نظرة عامة على الاتصال",
            lastContact: "آخر إتصال",
            nextContact: "الاتصال القادم",
            previous: "المادة السابقة",
            next: "التالي",
            viewDetails: "عرض التفاصيل",
            contactDynamics: "ديناميات الاتصال",
            lastYear: "آخر سنة",
            lastCall: "آخر مكالمة",
            hotTopicsForDiscussion: "مواضيع ساخنة للمناقشة",
            totalInTheLastYear: "في العام الماضي",
            callTimes: "مكالمة ثانية",
          },
        },
        list: {
          index: {
            risk: "المخاطر",
            have: "هناك",
            nothing: "لا شيء",
            channel: "القنوات",
            callRecording: "تسجيل المكالمات",
            enterpriseWeChatConversation: "محادثة الشركات الصغيرة",
            enterpriseWeChatCall: "مكالمة من شركة Weitong",
            followUpSuggestions: "متابعة التوصيات",
            customer: "مركز العملاء",
            followUp: "متابعة الوضع",
            personInCharge: "المسؤول",
            recentFollowup: "المتابعة الأخيرة",
            lastContactTime: "وقت الاتصال الأخير",
            operation: "العملية",
            see: "عرض",
            exceed: "أكثر من",
            noActivityForMoreThanDays: "لا يوجد نشاط",
            lately: "مؤخرا",
            risksOccurredInTheLastDays: "دلالات المخاطر في أيام",
            savedSuccessfully: "حفظ النجاح",
            today: "اليوم",
            allDepartments: "جميع القطاعات",
            allMembers: "جميع الأعضاء",
            customerName: "اسم العميل",
            reset: "إعادة التعيين",
            query: "الاستفسارات",
            customers: "عميل",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "عرض الاتصال بين الموظفين والعميل ، حيث الفقاعة الوردية هي أخبار العملاء والأزرق هو أخبار الموظفين ، وكلما زادت نسبة الكلام ، زادت الفقاعة",
            riskAllocation: "تكوين المخاطر",
            preservation: "حفظ",
            pleaseEnter: "الرجاء إدخال",
            noActivityForDays: "لا يوجد نشاط",
            riskSemanticsWithinDays: "دلالات المخاطر في أيام",
            addRiskSemantics: "إضافة دلالات المخاطر",
            semanticTags: "العلامات الدلالية:",
            standardSentence: "الجملة القياسية:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "إضافة شروط جديدة" } },
              },
              index: {
                meetAllOfTheFollowingConditions: "تلبية جميع الشروط التالية",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "الفحص",
                pleaseSelectFilteringCriteria: "يرجى اختيار شروط الفحص",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "تغيير مرحلة فرص العمل",
              theCurrentStageIs: "المرحلة الحالية هي [",
              ConfirmToChangeItTo: "] ، تأكد من تغييره إلى [",
              IsIt: "] هل هو ؟",
              StageCoStay: "] البقاء معا في المرحلة",
              day: "يا إلهي",
              start: "ابدأ",
              end: "النهاية",
              orderInput: "خسر",
              restart: "إعادة التشغيل",
              pleaseConfigureTheSalesStageInformation:
                "يرجى تكوين معلومات مرحلة المبيعات",
              reasonForDocumentInput: "سبب الخسارة:",
              completeOrderInput: "إكمال أوامر الدفع",
              pleaseSelectTheReason: "يرجى اختيار السبب",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "يوصي المدير:" },
              index: {
                sessionDetails: "تفاصيل الجلسة",
                customerDynamics: "ديناميات العملاء",
                riskPoint: "نقطة الخطر",
                followUpSuggestions: "متابعة التوصيات",
              },
              sessionDetails: {
                common: "المجموع",
                currentlyViewingThe: "العرض الحالي رقم",
                sessions: "جلسة واحدة",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "توقفت المرحلة الحالية",
              day: "يا إلهي",
            },
          },
          business: { index: { reEnableOpportunity: "إعادة تنشيط فرص العمل" } },
          customerTag: { index: { label: "علامات التبويب" } },
          moreCustomer: {
            index: {
              essentialInformation: "المعلومات الأساسية",
              cancel: "إلغاء",
              confirm: "تأكيد",
              customer: "العملاء",
              currentFollowupStage: "مرحلة المتابعة الحالية",
              telephone: "الهاتف",
              followUpDays: "عدد أيام المتابعة",
              day: "يا إلهي",
              remarks: "ملاحظات",
              channelPreference: "تفضيلات القنوات",
              hello: "كيف حالك ؟",
              customFields: "الحقول المخصصة",
              opportunityAmount: "مقدار فرص العمل",
              focus: "التركيز",
              customerInformation: "معلومات العملاء",
              viewMoreCustomerInformation: "عرض المزيد من معلومات العملاء",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "الأحداث الرئيسية:" },
                row1: {
                  customerDynamics: "ديناميات العملاء",
                  common: "المجموع",
                  barDynamic: "ديناميكيات المادة",
                },
                row2: {
                  riskPoint: "نقطة الخطر",
                  common: "المجموع",
                  risks: "المخاطر",
                },
                row3: {
                  followUpSuggestions: "متابعة التوصيات",
                  intelligentRecommendation: "توصية ذكية",
                  strip: "المادة",
                  managersSuggestATotalOf: "يقترح المدير",
                  recommendedByManagers: "توصية المدير",
                  creationTime: "إنشاء الوقت",
                  administratorSuggestions: "نصيحة المسؤول",
                  suggestionsForNewManagers: "اقتراحات جديدة للمديرين",
                },
              },
            },
            index: { typeTime: "النوع/الوقت" },
          },
          userInfo: {
            index: {
              personInCharge: "المسؤول",
              recentFollower: "المتابعة الأخيرة",
              startTime: "وقت الوصول الأول",
              followUpDays: "عدد أيام المتابعة",
              day: "يا إلهي",
              remarks: "ملاحظات",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "أعضاء الفريق",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "إجمالي عدد جلسات تعلم الأعضاء للآخرين (إزالة الوزن) ، والتحقق من تفاصيل الجلسة ، والنقر على تشغيل التسجيل يعتبر التعلم مرة واحدة",
          totalNumberOfStudies: "مجموع الدراسات",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "العدد الإجمالي للتعليقات والردود التي بدأها الأعضاء على جلسات الآخرين (إزالة الوزن)",
          totalNumberOfInteractions: "مجموع التفاعلات",
          learnTheSumOfConversationCallDuration:
            "تعلم مدة المحادثة وطلب السلام",
          lengthOfStudy: "مدة الدراسة",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "العدد الإجمالي للأعضاء الذين يبدأون المساعدة في جلسات الآخرين (إزالة الوزن)",
          totalNumberOfRequests: "مجموع طلبات المساعدة",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "العدد الإجمالي لتقديم المشورة للأعضاء بشأن المساعدة من الآخرين (زيادة الوزن)",
          totalCounseling: "مجموع الدروس الخصوصية",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "العدد الإجمالي لجلسات الأعضاء مع الآخرين المضافة إلى مكتبة الحالات (إزالة الوزن)",
          addCase: "إضافة حالة",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "العدد الإجمالي لاشتراكات الأعضاء في جلسات الآخرين (إزالة الوزن)",
          subscribe: "الاشتراك",
          totalNumberOfSessionsCommunicatedByMembers:
            "إجمالي عدد الجلسات التي يتواصل فيها الأعضاء",
          totalSessions: "مجموع الجلسات",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "يقوم الأعضاء بإعادة العدد الإجمالي للمحادثات الخاصة بهم (إزالة الوزن) ، والتحقق من تفاصيل الجلسة ، والنقر على التسجيل للتشغيل يعتبر بمثابة نسخة مرة واحدة",
          totalNumberOfDoubleDisk: "مجموع عمليات إعادة التداول",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "إجمالي عدد عمليات إعادة القرص/إجمالي عدد الجلسات * 100 ٪",
          proportionOfDoubleDisk: "نسبة إعادة",
          summationOfConversationCallDurationOfDuplicateDisk:
            "جلسة إعادة الاتصال لفترة طويلة",
          resumptionDuration: "وقت إعادة النظر",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "العدد الإجمالي للأعضاء الذين يبدأون المساعدة في جلساتهم (إزالة الوزن)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "إجمالي عدد التعليقات الواردة من الآخرين (بما في ذلك الردود) في جلسات الأعضاء",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "إجمالي عدد جلسات الأعضاء التي تمت إضافتها إلى مكتبة الحالات من قبل الآخرين (إزالة الوزن)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "العدد الإجمالي لجلسات الأعضاء التي يشترك فيها الآخرون (إزالة الوزن)",
          askForHelpInTheLastMonth: "طلب المساعدة في الشهر الماضي",
          lastTimeForHelp: "آخر وقت لطلب المساعدة",
          operation: "العملية",
          viewAllSessionsInTheLastMonth: "عرض جميع الجلسات في الشهر الماضي",
          allDepartments: "جميع القطاعات",
          reset: "إعادة التعيين",
          query: "الاستفسارات",
          altogether: "المجموع",
          secondSession: "الجلسة الفرعية",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "مشاريع الاختبار الحالية:",
            supervisionItem: "بند الإشراف",
            keyEvents: "الأحداث الرئيسية",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "يرجى التحقق من عنصر الإشراف أو الحدث الرئيسي الذي سيتم اختباره والتأكد من وجود محتوى مكالمة في محادثة محاكاة",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "تحليل القوة التنفيذية" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "العملية 1",
              technologicalProcess: "العملية",
              newProcess: "عملية جديدة",
            },
          },
          index: {
            upload: "تحميل",
            preservation: "حفظ",
            newlyBuild: "جديد",
            cancel: "إلغاء",
            name: "اسم",
            describe: "الوصف",
            type: "نوع",
            classification: "تصنيف",
            content: "المحتوى",
            enclosure: "المرفق",
            knowledgePointAssociation: "ارتباط نقطة المعرفة",
            open: "افتح",
            close: "أغلق",
            operator: "عامل",
            logic: "المنطق",
            see: "عرض",
            edit: "تحرير",
          },
        },
        index: {
          name: "اسم",
          describe: "الوصف",
          createdBy: "مؤسس",
          type: "نوع",
          lastUpdated: "آخر تحديث",
          numberOfReferences: "عدد الاستشهادات",
          operation: "العملية",
          see: "عرض",
          edit: "تحرير",
          delete: "حذف",
          categoryDeletedSuccessfully: "تصنيف حذف النجاح",
          classificationOfKnowledgePoints: "تصنيف نقاط المعرفة",
          newlyBuild: "جديد",
          knowledgeBase: "قاعدة المعرفة",
          knowledgePoints: "نقاط المعرفة",
          technologicalProcess: "العملية",
          keyword: "الكلمات الرئيسية",
          batchExport: "تصدير الدُفعات",
          batchDelete: "الحذف بالجملة",
          importProcess: "عملية الاستيراد",
          importKnowledgePoints: "استيراد نقاط المعرفة",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "إذا لم تكن نقاط المعرفة موجودة في قاعدة المعرفة ، تتم إضافة نقاط المعرفة مباشرة. إذا كانت موجودة ، فسيتم تخطيها وعدم تحديثها",
          editKnowledgePointClassification: "تحرير تصنيف نقاط المعرفة",
          newKnowledgePointClassification: "تصنيف جديد لنقاط المعرفة",
          categoryEditingSucceeded: "نجاح تحرير التصنيف",
          newClassificationSucceeded: "نجح التصنيف الجديد",
          classificationName: "اسم التصنيف",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "الإشراف على التنفيذ" },
      },
      topicCallout: {
        index: {
          whole: "كل شيء",
          date: "التاريخ",
          dimensionStatus: "وضع العلامات على الحالة",
          callRecordID: "معرف سجل المكالمات",
          callRecordIDMustBeNumeric: "يجب أن يكون معرف سجل المكالمات رقميًا",
          strip: "المادة",
          topicAnnotation: "موضوع علامة",
          annotated: "تم وضع علامة عليه",
          stripNotMarked: "المادة ، غير معلنة",
          barInDimension: "المادة ، في علامة",
          customer: "العملاء",
          sale: "المبيعات",
          common: "المجموع",
          sessions: "جلسة",
          currentTopic: "الموضوعات الحالية:",
          staff: "الموظفون",
          topicOfConversation: "الموضوع",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "عملية ناجحة ، تمت إعادة تعيين العلامة",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "بعد إعادة التعيين ، سيتم إفراغ جميع سجلات العلامات الخاصة بالجلسة ، يرجى تأكيد",
          resetAll: "إعادة تعيين كل شيء",
          pleaseSelectTheTopicToBeMarked:
            "يرجى اختيار الموضوع الذي تريد وضع علامة عليه",
          pleaseTickTheSentenceToBeMarked:
            "يرجى التحقق من الجملة التي تريد وضع علامة عليها",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "عملية ناجحة ، علامة سارية المفعول",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "هذا النطاق المحدد يحتوي على رسائل حول مواضيع أخرى ، انقر فوق موافق سيتم تغطية",
          confirmDimensions: "تأكيد العلامة",
          talkTime: "وقت التحدث",
          callID: "معرف الاتصال",
        },
      },
      topicList: {
        index: {
          callID: "معرف الاتصال",
          latestMarkedTime: "أحدث علامة على الوقت",
          topicOfConversation: "الموضوع",
          numberOfSentences: "عدد الجمل",
          sentence: "الجمل",
          operation: "العملية",
          see: "عرض",
          delete: "حذف",
          edit: "تحرير",
          newSubTopic: "موضوع فرعي جديد",
          move: "تحرك",
          whole: "كل شيء",
          topicDeletedSuccessfully: "حذف موضوع النجاح",
          newlyBuild: "جديد",
          topicList: "قائمة المواضيع",
          callIDMustBeNumeric: "يجب أن يكون معرف المكالمة رقميًا",
          leadInTopic: "استيراد الموضوع",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "عندما يتكرر اسم التصنيف ، لن يتم تغطية تصنيف الموضوع الأصلي ، وسيتم تخطي هذا الموضوع مباشرة ولن يتم استيراده",
          mobileClassification: "التصنيف المتحرك",
          editTopic: "تحرير الموضوع",
          newTopic: "موضوع جديد",
          topicName: "اسم الموضوع",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "مراجعة ناجحة",
            createdSuccessfully: "خلق النجاح",
            modificationFailed: "فشل التعديل",
            creationFailed: "إنشاء فشل",
            monitoringName: "اسم المراقبة",
            upToCharacters: "ما يصل إلى 40 حرفا",
            pleaseEnterAName: "الرجاء إدخال الاسم",
            monitoringIndicators: "مؤشرات الرصد",
            sessionActivity: "أنشطة المحادثة",
            conversationAction: "عمل الجلسة",
            keyEvents: "الأحداث الرئيسية",
            supervisionItems: "بند الإشراف",
            monitoringMembers: "أعضاء الرصد",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "المجموعات التي تتلقى التوجيه أو التدريب أو التدخل الإداري",
            groupA: "المجموعة ألف",
            pleaseSelectGroupAData: "الرجاء اختيار المجموعة أ من البيانات",
            thereAreDuplicateUsersInGroupB:
              "مع المستخدمين الذين لديهم ازدواجية في المجموعة B",
            groupARemarks: "ملاحظات المجموعة ألف",
            controlGroupGroupComparedWithGroupA:
              "المجموعة الضابطة ، المجموعة التي تقارن بالمجموعة أ",
            groupB: "المجموعة باء",
            pleaseSelectGroupBData: "الرجاء اختيار بيانات المجموعة ب",
            thereAreDuplicateUsersInGroupA:
              "مع المستخدمين الذين لديهم ازدواجية في المجموعة A",
            groupBRemarks: "ملاحظات المجموعة باء",
            monitoringPeriod: "دورة الرصد",
            startNode: "بدء العقد",
            monitoringScope: "نطاق الرصد",
            pleaseSelectMonitoringConditions: "يرجى اختيار شروط المراقبة",
            pleaseEnterANumber: "الرجاء إدخال الأرقام",
            pleaseSelectATimePeriod: "يرجى اختيار دورة زمنية",
            preservation: "حفظ",
            cancel: "إلغاء",
          },
        },
        list: {
          template: {
            deletionSucceeded: "حذف النجاح",
            notStarted: "لم تبدأ",
            haveInHand: "قيد العمل",
            closed: "انتهى",
            newGroupTest: "اختبار مجموعة جديدة",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "مرحلة المبيعات",
        communicationStrategy1: "استراتيجيات الاتصال",
        riskAllocation: "تكوين المخاطر",
        businessConfiguration: "تكوين الأعمال",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "معالجة المعارضة",
                  productSellingPointScript: "تقنية بيع المنتج",
                  caseGrouping: "مجموعات القضايا",
                  pleaseSelectExcellentCases: "يرجى اختيار حالة ممتازة",
                  pleaseSelectKnowledgePoints: "يرجى اختيار نقطة المعرفة",
                  associativeLinks: "روابط الارتباط",
                  jumpTo: "القفز إلى",
                  jumpType: "نوع القفز",
                  knowledgePoints: "نقاط المعرفة",
                  excellentCases: "حالة ممتازة",
                  excellentScript: "مهارات ممتازة في الكلام",
                  pleaseSelectASingleKnowledgePoint:
                    "يرجى اختيار نقطة معرفة واحدة",
                },
              },
            },
            index: {
              name: "اسم",
              pushText: "دفع النص",
              enableStatus: "تمكين الحالة",
              triggerRules: "قواعد الزناد",
              communicationStrategy: "استراتيجيات الاتصال",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "تجميع استراتيجية الاتصال",
              name: "اسم",
              pushText: "دفع النص",
              effectiveness: "فعالية",
              openSuccessfully: "فتح النجاح",
              closeSuccessfully: "نجاح الإغلاق",
              operationFailed: "فشل العملية",
              operation: "العملية",
              edit: "تحرير",
              delete: "حذف",
              newlyBuild: "جديد",
              communicationStrategy: "استراتيجيات الاتصال",
              editGroup: "تجميع التحرير",
              groupName: "اسم المجموعة",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "جميع الجلسات",
                  recentDays: "آخر أيام",
                  recentSessions: "جلسة حديثة",
                  scopeOfApplication: "نطاق التطبيق:",
                  day: "يا إلهي",
                  through: "من خلال",
                },
              },
            },
            index: {
              describe: "الوصف",
              applicableCustomers: "ينطبق على العملاء",
              enableStatus: "تمكين الحالة",
              triggerRules: "قواعد الزناد",
              riskRules: "قواعد المخاطر",
            },
          },
          list: {
            index: {
              riskAllocation: "تكوين المخاطر",
              keyWord: "الكلمات الرئيسية",
              newRiskRule: "قواعد المخاطر الجديدة",
              riskRuleName: "اسم قاعدة المخاطر",
              describe: "الوصف",
              state: "الحالة",
              openSuccessfully: "فتح النجاح",
              closeSuccessfully: "نجاح الإغلاق",
              operationFailed: "فشل العملية",
              operation: "العملية",
              edit: "تحرير",
              delete: "حذف",
              deletionSucceeded: "حذف النجاح",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "خسر" },
              endReasonList: {
                addEndReason: "إضافة سبب النهاية",
                enterEndReason: "أدخل سبب النهاية",
              },
              stageList: {
                keyEvents: "الأحداث الرئيسية",
                waysideData: "البيانات مع الطريق",
                includeAny: "يحتوي على تعسفي",
                includeAll: "يحتوي على كل شيء",
                pleaseEnterTheStageName: "الرجاء إدخال اسم المرحلة",
                setStageRules: "تعيين قواعد المرحلة",
                stageName: "اسم المرحلة",
                operator: "عامل",
                logic: "المنطق",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "شكل إدخال حقل نوع الوقت هو: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "فشل الطلب ، يرجى المحاولة مرة أخرى في وقت لاحق",
              salesStage: "مرحلة المبيعات",
              essentialInformation: "المعلومات الأساسية",
              name: "اسم",
              applicableDepartments: "الإدارات المطبقة",
              applicableToOthers: "تطبيق أخرى",
              manualChangePhase: "مرحلة التغيير اليدوي",
              enableStatus: "تمكين الحالة",
              describe: "الوصف",
              opportunityStageSettings: "إعداد مرحلة الفرص التجارية",
              stageSettings: "إعداد المرحلة",
              aliasSettings: "إعدادات الاسم المستعار",
              closeOpportunity: "إنهاء فرص العمل",
              newSuccessfully: "نجاح البناء الجديد",
              newFailed: "فشل البناء الجديد",
              savingSucceeded: "حفظ النجاح",
              saveFailed: "فشل الحفظ",
            },
          },
          list: {
            index: {
              salesStage: "مرحلة المبيعات",
              keyWord: "الكلمات الرئيسية",
              newSalesStage: "مرحلة المبيعات الجديدة",
              salesStageName: "اسم مرحلة البيع",
              describe: "الوصف",
              state: "الحالة",
              openSuccessfully: "فتح النجاح",
              closeSuccessfully: "نجاح الإغلاق",
              operationFailed: "فشل العملية",
              operation: "العملية",
              edit: "تحرير",
              delete: "حذف",
              deletionSucceeded: "حذف النجاح",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "توليد النماذج الكبيرة",
            conversationInsights: "رؤى المحادثة",
          },
          sessionsInsight: {
            components: {
              autoGenerate: { index: { automaticGeneration: "توليد تلقائي" } },
              hooks: {
                conversationSummary: "ملخص الجلسة",
                conversationProcess: "عملية الجلسة",
                opportunityPoints: "فرصة",
              },
              prompt: {
                index: {
                  edit: "تحرير",
                  restoreDefault: "استعادة الافتراضي",
                  cancel: "إلغاء",
                  confirm: "تأكيد",
                },
              },
            },
            index: {
              saveSuccessful: "حفظ النجاح",
              hintLanguage: "تذكير",
              preserve: "حفظ",
              cancel: "إلغاء",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "توليد النماذج الكبيرة",
        salesStage: "مرحلة المبيعات",
        communicationStrategy: "استراتيجيات الاتصال",
        riskAllocation: "تكوين المخاطر",
        businessConfiguration: "تكوين الأعمال",
      },
      salesLearningCenter: {
        knowledgeBase: "قاعدة المعرفة",
        speechCorpus: "مكتبة الهاتف",
        learningCenter: "مركز التعلم",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "معلومات العملاء" } },
        },
        index: { otherInformation: "معلومات أخرى" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "استيراد" } } },
        hooks: {
          index: {
            all: "كل شيء",
            intelligentModel: "نموذج ذكي",
            smartRules: "القواعد الذكية",
            manualScoring: "التسجيل اليدوي",
            enable: "تمكين",
            notEnabled: "لم يتم تمكينه",
            voiceCall: "المكالمات الصوتية",
            textDialogue: "حوار نصي",
            ecommerceTextSession: "جلسة نص التجارة الإلكترونية",
            workOrder: "قائمة العمل",
          },
        },
        index: {
          ruleName: "اسم القاعدة",
          ruleType: "نوع القاعدة",
          pleaseSelect: "يرجى اختيار",
          ruleStatus: "حالة القاعدة",
          dataSourceType: "نوع مصدر البيانات",
          voiceCall: "المكالمات الصوتية",
          textDialogue: "حوار نصي",
          ecommerceTextSession: "جلسة نص التجارة الإلكترونية",
          workOrder: "قائمة العمل",
          status: "الحالة",
          operation: "العملية",
          edit: "تحرير",
          ruleTemplate: "قوالب القواعد",
        },
      },
      analysisReportTitle: "تحليل التقارير",
      ruleTest: "اختبار القواعد",
      intelligentTags: {
        labelDisplayQuantity: "عدد عرض العلامات",
        unlimited: "غير محدود",
        ignoringTagLibraries: "تجاهل مكتبة العلامات",
        automaticallyGenerateLabels: "إنشاء علامات التبويب تلقائيا",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          'بعد الفتح ، يتم إنشاء العلامات تلقائيًا للجلسة بناءً على النموذج الكبير ، ويتم تصنيف العلامات التي تم إنشاؤها افتراضيًا ضمن "غير مصنف"',
        defaultValidity: "الصلاحية الافتراضية",
        supervisionItem: "بند الإشراف",
        customerLabel: "علامات العملاء",
        newSupervisionItemRule: "قواعد الإشراف الجديدة",
        editSupervisionItemRule: "قواعد الرقابة التحريرية",
        newCustomerLabel: "علامة تبويب العملاء الجديدة",
        editCustomerLabel: "تحرير علامات العملاء",
        newSessionLabel: "علامة تبويب جلسة جديدة",
        editSessionLabel: "تحرير علامات التبويب الجلسة",
        newKeyEvent: "الأحداث الرئيسية الجديدة",
        editKeyEvents: "تحرير الأحداث الرئيسية",
        detail: {
          template: { nameIsARequiredField: "الاسم هو الحقل المطلوب" },
          formItemComponent: {
            largeModelExtensionLabel: "توسعة نموذج كبير",
            sessionRecordID: "سجل الجلسة id",
            sessionTime: "وقت الجلسة",
            smartTags: "العلامات الذكية",
            viewDetails: "عرض التفاصيل",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "سيتم إفراغ البيانات المسجلة في هذه الجلسة بعد الحذف ، هل تم تأكيد الحذف ؟",
            tagData: "بيانات الوسم",
            batchDeletion: "الحذف بالجملة",
          },
        },
        template: {
          advancedFiltering: "الفحص المتقدم",
          dataType: "أنواع البيانات",
          filterCriteria: "شروط الفرز",
          edit: "تحرير",
          createANewSubcategory: "تصنيف فرعي جديد",
          delete: "حذف",
          nameLengthCannotExceed: "لا يمكن أن يتجاوز طول الاسم 40",
        },
        component: {
          modelExpansion: "توسيع النموذج",
          enable: "تمكين",
          notEnabled: "لم يتم تمكينه",
          standardScreening: "الفحص القياسي",
          advancedFiltering: "الفحص المتقدم",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "الكلمات الرئيسية",
              sessionLabel: "علامات التبويب الجلسة",
              keyEvents: "الأحداث الرئيسية",
              matchAny: "تطابق أي شيء",
              matchAll: "تطابق كل شيء",
              beEqualTo: "يساوي",
              includeAll: "يحتوي على كل شيء",
              includeAny: "يحتوي على تعسفي",
              advancedConditions: "شروط متقدمة",
              smartTag: "العلامات الذكية",
              smartTagI: "العلامة الذكية واحد",
              smartTagII: "العلامة الذكية الثانية",
            },
            template: {
              pleaseSelectSmartTag: "يرجى اختيار علامة التبويب الذكية",
              pleaseSelectASessionLabel: "يرجى اختيار علامة التبويب الجلسة",
              pleaseSelectAKeyEvent: "يرجى اختيار الأحداث الرئيسية",
              pleaseSelectAdvancedCondition: "يرجى اختيار الشروط المتقدمة",
              newAdvancedFilter: "فحص متقدم جديد",
              moveUp: "الانتقال العلوي",
              moveDown: "تحركت لأسفل",
              moveLeft: "إلى اليسار",
              shiftRight: "إلى اليمين",
            },
          },
          component: {
            businessAnalysispng: "تحليل الأعمال. png",
            savedSuccessfully: "حفظ النجاح",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "فعالة بشكل دائم",
            permanentlyInvalid: "غير صالح بشكل دائم",
            customValidity: "فترة الصلاحية المخصصة",
            termOfValidity: "فترة الصلاحية",
            name: "اسم",
            explain: "وصف",
            state: "الحالة",
            classification: "تصنيف",
            smartRecommendation: "توصية ذكية",
            preconditions: "الشروط المسبقة",
            SetPreconditionsForThisSupervisionItem:
              "1-وضع الشروط المسبقة لعنصر الإشراف في هذه المادة:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              'عندما يتم استيفاء الشروط المسبقة ، يكون "عنصر الإشراف الذي يجب تنفيذه" ، ويتم اختبار عنصر الإشراف ؛',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "إذا لم يتم استيفاء الشروط المسبقة ، فلن يتم اختبار عنصر الإشراف ؛",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. لا يتم تعيين الشروط المسبقة ، أي يتم الكشف عن جميع الجلسات افتراضيًا",
            have: "هناك",
            nothing: "لا شيء",
            preconditionRule: "قواعد الشروط المسبقة",
            preconditionLogic: "منطق الشرط المسبق",
          },
          recommendation: {
            caseGrouping: "مجموعات القضايا",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "عندما لا يقوم الموظفون بالأداء ، يمكنهم التوصية بذكاء بمهارات الكلام الممتازة والحالات الممتازة",
            smartRecommendation: "توصية ذكية",
            open: "افتح",
            close: "أغلق",
            whenThisSupervisionItem: "عند هذا العنصر الإشرافي",
            implement: "التنفيذ",
            unexecuted: "لم ينفذ",
            when: "الوقت:",
            recommendedKnowledgePoints: "نقاط المعرفة الموصى بها",
            caseRecommendation: "توصية الحالة",
          },
        },
        index: {
          determine: "تحديد",
          sort: "فرز",
          sortSucceeded: "فرز النجاح",
          supervisionItemClassification: "تصنيف الرقابة",
          pressEnterToCreateANew: "اضغط على السيارة مرة أخرى لبناء جديد",
          nameCannotBeEmpty: "لا يمكن أن يكون الاسم فارغًا",
          newlyBuild: "جديد",
          name: "اسم",
          explain: "وصف",
          validStatus: "الحالة الفعالة",
          permanentlyValid: "فعالة بشكل دائم",
          permanentlyInvalid: "غير صالح بشكل دائم",
          operation: "العملية",
          edit: "تحرير",
          batchDelete: "الحذف بالجملة",
          newSupervisionItem: "بنود الإشراف الجديدة",
          keyWord: "الكلمات الرئيسية",
          delete: "حذف",
          deleteSucceeded: "حذف النجاح",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "لا يمكن استرداد البيانات بعد حذفها ، هل تم تأكيد الحذف ؟",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "العلامات الذكية",
              beEqualTo: "يساوي",
              includeAll: "يحتوي على كل شيء",
              includeAny: "يحتوي على تعسفي",
              matchAny: "تطابق أي شيء",
              matchAll: "تطابق كل شيء",
              smartTagI: "العلامة الذكية واحد",
              smartTagII: "العلامة الذكية الثانية",
            },
            template: {
              pleaseSelectASmartTag: "يرجى اختيار علامة التبويب الذكية",
              pleaseSelectAdvancedConditions: "يرجى اختيار الشروط المتقدمة",
              newAdvancedFilter: "فحص متقدم جديد",
              rename: "إعادة تسمية",
              moveUp: "الانتقال العلوي",
              moveDown: "تحركت لأسفل",
              shiftLeft: "إلى اليسار",
              shiftRight: "إلى اليمين",
            },
          },
        },
        template: {
          delete: "حذف",
          labelFiltering: "فحص العلامات:",
          satisfyAny: "إرضاء التعسفية",
          meetAll: "تلبية جميع",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "عدد محادثات علامة التصنيف الثانوية ≥ تصنيف المستوى الأول ، قد تحتوي الجلسة على علامات متعددة",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "منطق حساب التغيير على أساس شهري: (مقدار الحوار في الفترة الزمنية المحددة-مقدار الحوار في الفترة الزمنية التالية)/مقدار الحوار في الفترة الزمنية الأولى",
          proportionOfPrimaryClassification: "نسبة التصنيف من المستوى الأول",
          saveAs: "حفظ بشكل منفصل",
          cover: "تغطية",
          preservation: "حفظ",
          qualityInspectionTask: "المهام التحليلية:",
          query: "الاستفسارات",
          changeFromMonthToMonth: "التغييرات على أساس شهري",
          tagRanking: "تصنيف العلامات",
          proportion: "النسبة",
        },
        component: {
          numberOfConversations: "عدد المحادثات",
          operation: "العملية",
          businessAnalysispng: "تحليل الأعمال. png",
          savingSucceeded: "حفظ النجاح",
          deletionSucceeded: "حذف النجاح",
          sequence: "تسلسل",
          firstLevelClassification: "تصنيف المستوى الأول",
          ringRatioChange: "التغيير على أساس شهري",
          classClassification: "تصنيف المستوى",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "تأكيد حذف المستند ومحتواه المستخرج ؟",
            addKnowledgeBase: "إضافة قاعدة المعرفة",
            uploadDocument: "تحميل المستندات",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "دعم ملفات تنسيق Word ، pdf ، ppt ، excel ، txt",
            taskName: "اسم المهمة",
            extractConversationDate: "سحب تواريخ الحوار",
            pleaseSelectAConversationDate: "يرجى اختيار تاريخ المحادثة",
            dataSource: "مصادر البيانات",
            dataType: "أنواع البيانات",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "تصدير النجاح",
              problem: "السؤال",
              answer: "الجواب",
              addToKnowledgeBase: "إضافة إلى قاعدة المعرفة",
              pleaseConfirmWhetherToDeleteIt: "يرجى تحديد ما إذا كنت تريد حذف",
            },
            pageRightFrom: {
              addToKnowledgeBase: "إضافة إلى قاعدة المعرفة",
              selectClassification: "اختيار التصنيف",
              pleaseSelectAKnowledgeBaseClassification:
                "يرجى اختيار تصنيف قاعدة المعرفة",
            },
          },
        },
        index: {
          intelligentExtraction: "استخراج ذكي",
          documentExtraction: "استخراج المستندات",
          dialogueExtraction: "استخراج الحوار",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "عملية ناجحة",
          labelIgnored: "تم تجاهل التسمية",
          add: "إضافة",
          areYouSureToDeleteIt: "هل أنت متأكد من الحذف ؟",
          batchFilterLabels: "تصفية الدُفعات",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "فحص الجودة الذكية",
        savedSuccessfully: "حفظ النجاح",
        logoSettings: "إعدادات الشعار",
        default: "الافتراضي",
        custom: "التخصيص",
        title: "العنوان",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "اسم العنوان الذي يعرضه النظام ، يوصى به في غضون 6 كلمات.",
        logoImageUpload: "شعار تحميل الصور",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "يرجى اختيار الصور التي لا يزيد حجمها عن 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "لضمان تأثير تصفح الشعار ، يوصى بتحميل الصور:",
        Size5050Pixels: "الحجم: 50*50 بكسل.",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. التنسيق: صورة خلفية شفافة ، png أو jpg ، رسومات الشعار باستخدام الأبيض",
        browserLabelIcon: "رمز علامة تبويب المتصفح",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "لضمان تأثير تصفح العلامات ، قم بتحميل الصور الموصى بها:",
        Size3232Pixels: "الحجم: 32*32 بكسل ؛",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. الشكل: صورة خلفية شفافة ، png أو jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "فحص الجودة قيد العمل ، يرجى في وقت لاحق...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "في عام 2015 ، وصلت العقارات في بلدي إلى لحظة حرجة للبقاء على قيد الحياة ، ولم يهتم عدد كبير من المنازل ، وكان الناس غير مستعدين لشرائها. انخفض Hangzhou مرة واحدة إلى أقل من 8000. حطم بعض المطورين السوق وشحنوا. كانت Shenzhen لا تزال تتماسك حول 2w-3w في ذلك الوقت ، وكانت المنازل في مدن الدرجة الأولى في خطر أيضًا.",
        xiaoAn: "شياو آن",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore storeload هذه الحواجز في الذاكرة تسمى حاجز الذاكرة jsr. وهي على مستوى jvm. هناك طريتان لتحقيق في الطبقة السفلية من نظام التشغيل: 1 هو قفل الحافلة ، و 2 هو عنصر حاجز الذاكرة ، مثل حاجز الكتابة (حاجز الكتابة) mfence (حاجز القراءة). تستخدم الطبقة السفلية من الجهاز الظاهري hotspots عبارة تجميع: lock addl ، lock عملية فارغة ، وهي عبارة عن ناقل قفل ، ويتم ذلك لأن بعض أنظمة التشغيل لا تدعم لغة حاجز الذاكرة.",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. حاولت أن تنقع اللحم عدة مرات ، وأضيف الخضار المقلية بعد الشرب ، واللحوم فاتنة ، ورائحة حامض! منذ ذلك الحين ، لا مزيد من النبيذ",
        xiaodong: "شياو دونغ",
        xiaoxi: "(ليتل)",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. حاولت أن تتبل اللحم عدة مرات ، وأضيف الخضار المقلية بعد الشرب ، واللحوم فاتنة ، ورائحة حامض! منذ ذلك الحين ، لا مزيد من النبيذ",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "إدارة تعليقات التجارة الإلكترونية",
        socialMediaReviewManagement: "إدارة تعليقات وسائل الإعلام الاجتماعية",
        customDataSource: "بيانات أصلية مخصصة",
        qualityInspectionResults: "نتائج فحص الجودة",
        accountManagement: "إدارة الحسابات",
        employeeProfile: "صورة الموظف",
        intelligentAnalysis: "التحليل الذكي",
        customKanban: "لوحة مخصصة",
        dataScreening: "نظرة عامة على البيانات",
        learningRecord: "سجل الدراسة",
        dataAnalysis: "تحليل البيانات",
        scoringManagement: "إدارة الدرجات",
        businessConfiguration: "تكوين الأعمال",
        manualReview: "مراجعة اصطناعية",
        taskList: "قائمة المهام",
        videoAnalysis: "تحليل الفيديو",
        overview: "نظرة عامة",
        personalSignage: "لوحة شخصية",
        learningCenter: "مركز التعلم",
        wrongQuestionSet: "مجموعة الأسئلة الخاطئة",
        abilityImprovement: "تحسين القدرة",
        close: "أغلق",
        switchingSystemApplications: "تبديل تطبيقات النظام",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "حرك الماوس هنا لاستدعاء قائمة تبديل التطبيقات ، انقر فوق عنصر القائمة للانتقال إلى تطبيقات مختلفة",
        teamAnalysis: "تحليل الفريق",
        semanticIntelligence: "الذكاء الدلالي",
        permissionManagement: "إدارة الأذونات",
        dataConnection: "إرساء البيانات",
        courseManagement: "إدارة المناهج الدراسية",
        labelManagement: "إدارة العلامات",
        curriculum: "مركز الدورات",
        courseConfiguration: "تكوين الدورة",
        task: "مهمتي",
        historicalLearningData: "السجلات التاريخية",
        taskCenter: "إدارة المهام",
        knowledgeBase: "قاعدة المعرفة",
        ecommerceSessionManagement: "إدارة جلسة التجارة الإلكترونية",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "تبديل التطبيقات",
          descriptionOfIntelligentQualityInspectionSystem:
            "وصف نظام فحص الجودة الذكي",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "تصفية جلسة التجارة الإلكترونية",
        allEcommerceSessions: "جميع جلسات التجارة الإلكترونية",
        ecommerceSessionManagement: "إدارة جلسة التجارة الإلكترونية",
      },
    },
    analysisEnable: {
      bestPractices: "أفضل الممارسات",
      behaviorAnalysis: "التحليل السلوكي",
      teamAnalysis: "تحليل الفريق",
      executiveSupervision: "الإشراف على التنفيذ",
      executiveForceAnalysis: "تحليل القوة التنفيذية",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "التصنيف:",
          startTime: "وقت البدء",
          workOrderNo: "رقم ورقة العمل",
          customerServiceHasReadNotRead: "خدمة العملاء مقروءة/غير مقروءة",
          canYouAppeal: "إمكانية الطعن",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "ملاحظات الشركة الصغيرة",
          addFriendTime: "إضافة وقت الأصدقاء",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "البدء في إعادة فحص الجودة",
          callID: "معرف الاتصال",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'ميزات جديدة! انقر فوق الزر "فحص عشوائي سريع" ، وبعد التقديم الناجح ، سينتقل تلقائيًا إلى الصفحة التالية.',
        },
      },
      template: {
        deleteRecord: "حذف السجلات",
        areYouSureToDeleteIt: "هل أنت متأكد من الحذف ؟",
        confirm: "تحديد",
        reIdentification: "إعادة تحديد الهوية",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "إعادة تحديد الهوية تستهلك وقت النسخ ، يرجى توخي الحذر",
        determine: "تحديد",
        executionSuccessful: "التنفيذ الناجح",
      },
      component: {
        pleaseSelectData: "يرجى اختيار البيانات",
        operationSuccessful: "عملية ناجحة",
        startPerformingReQualityInspection: "البدء في إعادة فحص الجودة",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "تحميل اسم الملف",
        analysisTask: "المهام التحليلية",
        analyzeProgress: "تحليل التقدم",
        analysisSucceeded: "تحليل النجاح",
        analysisFailed: "فشل التحليل",
        localDataUpload: "تحميل البيانات المحلية",
        recordingUpload: "تحميل التسجيل",
        textUpload: "تحميل النص",
        uploadTime: "وقت التحميل",
        taskName: "اسم المهمة",
        operator: "الشخص الذي يعمل",
        qualityInspectionProgress: "تقدم فحص الجودة",
        whole: "كل شيء",
        haveInHand: "قيد العمل",
        completed: "أنجز",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "هل يتم تصدير جميع سجلات البيانات المكتملة في ظل ظروف الفحص الحالية ؟",
        upload: "تحميل",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "املأ الاسم",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "املأ الاسم ، مثل نوع/مجموعة/الغرض من بيانات الاستيراد هذه المرة",
            default: "الافتراضي",
            jDCOM: "Jingdong",
            dataType: "أنواع البيانات",
            selectAnalysisTask: "اختيار المهام التحليلية",
            uploadFiles: "تحميل الملفات",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. تحميل قالب excel ، املأ حسب الشكل:",
            template: "قالب",
            SupportUpTo10000PiecesOfData: "2. دعم أقصى 10000 قطعة من البيانات",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "دعم تحميل بيانات الحوار المصدرة من JD.com ، ملف .txt ، الحد الأقصى لحجم الملف يدعم 100M",
            alongTheWayDataTemplate: "قالب البيانات مع الطريق",
          },
        },
        result: {
          template: {
            textUnderInspection: "في فحص النص ، يرجى التحلي بالصبر",
            textQualityInspectionCompleted: "اكتمال فحص النص",
          },
        },
        template: {
          startAnalysis: "بدء التحليل",
          pleaseUploadTheFile: "يرجى تحميل الملف",
          localDataUpload: "تحميل البيانات المحلية",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            'ميزات جديدة! انقر فوق الزر "مراجعة الإرسال" ، وبعد التقديم الناجح ، سوف ينتقل تلقائيًا إلى الصفحة التالية.',
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "سلطة مهمة فحص الجودة",
            edit: "تحرير",
            iParticipated: "أنا متورط",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "يمكن للشخصية رؤية البيانات تحت القسم المختار",
            dataPermissions: "أذونات البيانات",
            selectDepartment: "اختيار القطاع",
            edit: "تحرير",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "البدء في إعادة فحص الجودة",
          callID: "معرف الاتصال",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            'ميزات جديدة! انقر فوق أزرار "إرسال أخذ العينات" و "فحص العينات السريع" ، وبعد التقديم الناجح ، ستقفز تلقائيًا إلى الصفحة التالية.',
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "القواعد" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "إدارة أوامر العمل",
          workOrderDetails: "تفاصيل ورقة العمل",
        },
      },
      template: {
        workOrderFilter: "عامل فرز",
        allWorkOrders: "جميع أوامر العمل",
        workOrderManagement: "إدارة أوامر العمل",
      },
    },
    trainingCenter: {
      topicAnnotation: "موضوع علامة",
      topicList: "قائمة المواضيع",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "نوع الدور",
              keyWord: "الكلمات الرئيسية",
              pleaseEnterKeywordSearch: "الرجاء إدخال كلمة البحث الرئيسية",
              query: "الاستفسارات",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "لم يتم فتح هذه الميزة بعد ، يرجى الاتصال بمسؤول النظام إذا لزم الأمر",
          pleaseSelectAQuestion: "يرجى اختيار الأسئلة!",
          recommendedSimilarSentencesLargeModel:
            "جمل مماثلة موصى بها (نموذج كبير)",
          selectAll: "الاختيار الكامل",
          add: "إضافة",
          changeBatch: "دفعة أخرى",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "ساعات العمل",
      localDataUpload: "تحميل البيانات المحلية",
      assembleMenuListAccordingToCurrentUserPermissions:
        "تجميع قائمة القائمة على أساس أذونات المستخدم الحالية",
      sessionSettings: "إعدادات الجلسة",
      logoSettings: "إعدادات الشعار",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "مرحبا بكم في العودة! الرجاء إدخال التفاصيل الخاصة بك",
        mailbox: "صندوق البريد",
        password: "كلمة السر",
        signIn: "تسجيل الدخول",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin: "لا يسمح IP الحالي بتسجيل الدخول",
          welcomeToLogin: "مرحبا بكم في تسجيل الدخول",
          pursuingExcellenceAndLeadingChange:
            "السعي لتحقيق التميز وقيادة التغيير",
          intelligentPlatform: "منصة ذكية",
        },
        virtualLogin: { index: { pleaseSelect: "يرجى اختيار" } },
      },
      oauth: {
        illegalCall: "استدعاء غير قانوني",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "مرحبا بكم في العودة! انقر على الزر أدناه للانتقال إلى منصة مصادقة الهوية",
        loggingIn: "تسجيل الدخول في...",
        unifiedIdentityAuthenticationLogin:
          "تسجيل الدخول الموحد لمصادقة الهوية",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "اختبار القاعدة: لا يمكن لبعض القواعد استخدام اختبار القاعدة ، مثل سرعة الكلام وحجم الصوت والبيانات الواردة ، إلخ.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "معرفة المزيد",
          enteringTheSystem: "الوصول إلى النظام",
        },
      },
      index: {
        cancel: "إلغاء",
        enteringTheSystem: "الوصول إلى النظام",
        close: "أغلق",
        enterSystemApplication: "الوصول إلى تطبيقات النظام",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          'انقر على "الدخول إلى النظام" للقفز إلى تطبيقات النظام التي تم تمكينها',
      },
    },
    permissionManagement: {
      employeeManagement: "إدارة الموظفين",
      roleManagement: "إدارة الأدوار",
      permissionManagement: "إدارة الأذونات",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "يساوي",
            contain: "يحتوي على",
            doesNotContain: "لا يحتوي على",
            applicationSystem: "نظام التطبيق",
            intelligentQualityInspection:
              "فحص الجودة الذكية/تحليل الجلسة الذكية",
            salesEmpowerment: "مبيعات التمكين",
            dataSourceType: "نوع مصدر البيانات",
            filterCriteria: "شروط الفرز",
          },
        },
        index: {
          dataConnection: "إرساء البيانات",
          savingSucceeded: "حفظ النجاح",
          dataIsolation: "عزل البيانات",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "بعد الفتح ، يمكنك ضبط نطاق البيانات لمكالمات الوصول إلى كل نظام تطبيق وجلسات WeChat الخاصة بالمؤسسة. إذا لم يتم تشغيل المفتاح ، فسيتصل كل نظام بالبيانات الكاملة",
          accessRules: "قواعد الوصول",
          preservation: "حفظ",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "اسم التطبيق",
            accessRights: "أذونات الوصول",
            inAppPermissionSettings: "إعدادات الأذونات داخل التطبيق",
            setUp: "الإعدادات",
            applicationAccess: "أذونات الوصول إلى التطبيقات",
            newRole: "أدوار جديدة",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "تفاصيل الجلسة" } },
      messageNotification: {
        index: {
          sessionDetails: "تفاصيل الجلسة",
          caseDetails: "تفاصيل القضية",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "يمكن رؤية الموظفين",
            newCourse: "دورات جديدة",
            editCourse: "دورة التحرير",
            courseName: "اسم الدورة",
            courseIntroduction: "مقدمة الدورة",
            courseType: "أنواع الدورات",
            courseClassification: "تصنيف الدورة",
            courseCover: "غلاف الدورة",
            learningContent: "محتوى التعلم",
            uploadLearningMaterials: "تحميل المواد التعليمية",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "اختبار",
              preservation: "حفظ",
              cancel: "إلغاء",
              editNodeName: "تحرير اسم العقدة",
              determine: "تحديد",
              individual: "هذا واحد",
              of: "، المجموع",
              branch: "نقاط",
            },
            configDrawerComponents: {
              pPTParsing: "تحليل PPT في...",
              uploadSuccessful: "نجاح التحميل",
              uploadAttachments: "تحميل المرفقات",
              pPTParsingPleaseWait: "تحليل PPT ، يرجى في وقت لاحق",
            },
            customerPortraitConfigDrawer: {
              cancel: "إلغاء",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "لا يمكن استعادتها بعد الحذف ، هل أنت متأكد من أنه تريد حذف هذه الصورة ؟",
              delete: "حذف",
              determine: "تحديد",
              customerPortrait: "صورة العميل",
              bindSelectedCharacterPortrait: "صورة شخصية مختارة ملزمة",
              customerProfileDetails: "تفاصيل صورة العميل",
              createANewCustomerProfile: "صور العملاء الجديدة",
            },
            flow: {
              sidebar: { node: "العقدة" },
              useControlButtons: {
                cancellingPleaseWait: "التراجع ، يرجى في وقت لاحق...",
                redoInProgressPleaseWait: "إعادة ، يرجى في وقت لاحق...",
                automaticOrganization: "التشطيب التلقائي",
                canvasAdaptation: "التكيف القماشية",
                delete: "حذف",
              },
              useFlow: {
                nodeCannotBeConnectedToItself: "لا يمكن ربط العقد مع نفسها",
              },
            },
            globalConfigDrawer: {
              cancel: "إلغاء",
              determine: "تحديد",
              globalConfiguration: "التكوين العالمي",
              sessionDetection: "كشف الجلسة",
              reciprocalRule: "قواعد التفاعل",
              robotVoice: "جرس الروبوت",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "لم تجيب عقدة الطالب على الإعدادات",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "الحد الأقصى لوقت الصمت الذي يسمح به المتدربون دون إجابة (ق)",
              promptScriptForFailureToAnswerOverTime: "الوقت الإضافي دون إجابة",
              addScript: "إضافة الكلام",
            },
            robotTimbreForm: {
              aixianatural: "آيشا (الطبيعة)",
              zhishaChat: "تشيشا (دردشة)",
              zhiqiangentle: "Zhiqian (معتدل)",
              zhimiEmotion: "Zhimi (العاطفة)",
              aiShuoNatural: "آي شو (الطبيعة)",
              zhishuoChat: "Zhishuo (دردشة)",
              aiHaoAffinity: "آي هاو (التقارب)",
              zhixiangEmotion: "Zhixiang (العاطفة)",
              robotVoice: "جرس الروبوت",
              schoolboy: "الأولاد",
              girlStudent: "فتاة",
              volume: "مستوى الصوت",
              tone: "نغمة",
              speechSpeed: "سرعة الكلام",
              test: "اختبار",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "مرحبا ، أنا مدرب تدريب ذكي ، وأتطلع إلى التواصل معك",
            },
            sessionDetectionForm: {
              expressionDetection: "الكشف عن التعبير",
              excessiveDetectionOfModalParticles: "الكشف عن الكثير من الكلمات",
              theNumberOfRepetitionsIs: "عدد التكرار هو",
              minus: "في الساعة الثانية ، ناقص",
              appear: "يظهر",
              subtract: "مرات ، ناقص",
              branch: "نقاط",
              addRules: "زيادة القواعد",
              includingNegativeAngryComplaining:
                "(بما في ذلك السلبية والغضب والشكوى)",
              scorePointsDisplay: "نقاط النتيجة تظهر",
              reminderOfSpeakingTooFasttooSlow: "تذكير سريع جدًا/بطيء جدًا",
              scriptViolationReminder: "تذكير بانتهاكات الكلام",
              emotionalReminder: "تذكير عاطفي",
              speechSpeedDetection: "اختبار سرعة الكلام",
              normalSpeechSpeedScore: "سرعة الكلام طبيعية",
              speakTooFast: "تحدث بسرعة كبيرة",
              everyMinuteGreaterThan: "أكبر من كل دقيقة",
              aWordAppears: "كلمة ، تظهر",
              speakTooSlowly: "تحدث ببطء شديد",
              lessThanPerMinute: "أقل من كل دقيقة",
              ignoreLessThan: "تجاهل أقل من",
              aSentenceOfWords: "كلمات الجمل",
              emotionalDetection: "الكشف عن المشاعر",
              emotionalNormalScore: "النتيجة العاطفية العادية",
              negativeEmotions: "المشاعر السلبية",
              illegalWordDetection: "الكشف عن كلمة الانتهاك",
              scriptViolationConfiguration: "التكوين غير القانوني للكلمات",
              realTimePromptOnTheFrontEnd:
                "نصائح في الوقت الحقيقي للواجهة الأمامية",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "قاعدة المعرفة سؤال وجواب",
                  qAMethod: "طريقة الأسئلة والأجوبة",
                  numberOfQuestionsAndAnswers: "عدد الأسئلة والأجوبة",
                  scoringRules: "قواعد التهديف",
                  ifAnyQuestionAndAnswerIsCorrect: "أي سؤال وجواب صحيح ، يجب",
                  branch: "نقاط",
                  successfullySaved: "حفظ النجاح",
                },
                pptNode: {
                  verificationPassed: "اجتاز التحقق",
                  upload: "تحميل",
                  supportPptPdfFormatFiles: "دعم الملفات بتنسيق ppt و pdf",
                  uploadSlide: "تحميل الشرائح",
                  assessmentMethod: "طريقة التقييم",
                  associateSlide: "الشرائح المرتبطة",
                  standardScript: "الكلام القياسي",
                  successfullySaved: "حفظ النجاح",
                },
                robotNode: {
                  robotScript: "الروبوت الحديث",
                  randomScript: "الكلام العشوائي",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "اختر تقنية الكلمات الدلالية المماثلة ، وسيقوم الروبوت بقول إحدى الجمل بشكل عشوائي",
                  customerPortrait: "صورة العميل",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "عندما تكون صورة العميل متسقة ، يقول الروبوت هذه التقنية",
                  setAsDefaultScript: "تعيين الكلمات الافتراضية",
                  yes: "نعم",
                  no: "لا",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "تحدث هذه المحادثة عندما لا يضرب الروبوت أي من علامات المستخدم",
                  successfullySaved: "حفظ النجاح",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "هذه العقدة الفرعية مجهزة بالفعل بتقنية الكلام الافتراضية ، هل يتم استبدال الكلمة الافتراضية بتقنية الكلام الحالية ؟",
                },
                startNode: {
                  paragon: "السرد",
                  successfullySaved: "حفظ النجاح",
                },
                userNode: {
                  verificationPassed: "اجتاز التحقق",
                  standardScript: "الكلام القياسي",
                  successfullySaved: "حفظ النجاح",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "يرجى تحسين التكوين",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "يرجى إدخال مثال المحادثة",
                  dialogueExample: "أمثلة على الحوار",
                  generateConversationExamples: "توليد أمثلة على الحوار",
                  mattersNeedingAttention: "ملاحظات",
                  pleaseEnterThePromptLanguage: "الرجاء إدخال المطلب",
                },
                aiGenSceneNode: {
                  dialogueScenario: "مشهد الحوار",
                  simulateCharacter: "محاكاة الأدوار",
                  accompanyingTrainees: "موضوع السجال",
                  selectFromTemplateLibrary: "اختر من مكتبة القوالب",
                  templateLibrary: "مكتبة قالب",
                  role: "الدور",
                  scene: "المشهد",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "نافذة اختبار الشرائح [",
              clearContent: "مسح المحتوى",
              close: "أغلق",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "عملية توليد الذكاء الاصطناعي",
                scriptStyle: "أسلوب الكلام",
                theMostConversationRounds: "معظم جولات الحوار",
                copyConversation: "نسخ الحوار",
                application: "التطبيقات",
                regenerate: "إعادة توليد",
                startGenerating: "بدء التوليد",
                pleaseGenerateAProcess: "الرجاء توليد العملية",
                copySuccessful: "نجاح النسخ",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "مندوب المبيعات",
                    customerServicePersonnel: "موظفو خدمة العملاء",
                    storeCounter: "عداد المتجر",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "توليد كلمات النص الكامل",
                speaker: "المتحدثون",
                scriptStyle: "أسلوب الكلام",
                copyConversation: "نسخ الحوار",
                application: "التطبيقات",
                regenerate: "إعادة توليد",
                startGenerating: "بدء التوليد",
                pleaseGenerateAProcess: "الرجاء توليد العملية",
                operationSuccessful: "عملية ناجحة",
                copySuccessful: "نجاح النسخ",
              },
            },
            aiTextArea: { index: { scriptPolishing: "ترطيب الكلمات" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "الكلام لا يمكن أن يكون فارغا!",
              copySuccessful: "نجاح النسخ",
              cancel: "إلغاء",
              copy: "نسخ",
              replaceTheOriginalText: "استبدال النص الأصلي",
              aiGeneration: "توليد Ai",
              aiPolishing: "آي ملمس",
              currentStyle: "النمط الحالي",
              rigorous: "صارم",
              mild: "لطيف",
              lively: "حيوية",
              simplicity: "البساطة",
              pleaseEnterTheSpeakerRole: "الرجاء إدخال دور المتحدث",
              startGenerating: "بدء التوليد",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "الكلام لا يمكن أن يكون فارغا!",
              copySuccessful: "نجاح النسخ",
              aiRewrite: "Ai إعادة كتابة",
              cancel: "إلغاء",
              copy: "نسخ",
              replaceTheOriginalText: "استبدال النص الأصلي",
              currentStyle: "النمط الحالي",
              rigorous: "صارم",
              mild: "لطيف",
              lively: "حيوية",
              simplicity: "البساطة",
              pleaseEnterTheSpeakerRole: "الرجاء إدخال دور المتحدث",
              startGenerating: "بدء التوليد",
            },
          },
          index: {
            verificationPassed: "اجتاز التحقق",
            ruleValidation: "التحقق من القواعد",
            aIGeneration: "توليد الذكاء الاصطناعي",
            generateFullTextSpeechScript: "توليد كلمات النص الكامل",
            editCourse: "دورة التحرير",
            importConversation: "استيراد الحوار",
            uploadExcelToCompleteDialogueImport:
              "تحميل Excel لاستكمال استيراد المحادثات",
            editBasicInformation: "تحرير المعلومات الأساسية",
            test: "اختبار",
            release: "نشر",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "علامة تبويب جديدة" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "لا يمكن استعادتها بعد الحذف ، هل أنت متأكد من أنه تريد حذف هذه العلامة ؟",
              delete: "حذف",
              cancel: "إلغاء",
            },
          },
        },
        index: {
          labelManagement: "إدارة العلامات",
          newGroup: "مجموعة جديدة",
          edit: "تحرير",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "لا يمكن استعادتها بعد الحذف ، هل أنت متأكد من أنه تريد حذف مجموعة علامات التبويب هذه ؟",
          delete: "حذف",
          cancel: "إلغاء",
          editGroup: "تجميع التحرير",
          labelGroupName: "اسم مجموعة العلامات",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "تعطيل الكاميرا",
              noCameraDevicesFound: "لم يتم العثور على معدات الكاميرا",
            },
          },
          cue: {
            index: {
              regenerate: "إعادة توليد",
              generate: "توليد",
              referenceScript: "الحديث المرجعي",
              mattersNeedingAttention: "ملاحظات",
              examFrequency: "عدد الامتحانات",
              section: "رقم",
              timestotal: "مرة/المجموع",
              second: "مرة واحدة",
              requirementsForPassingTheExam: "متطلبات اجتياز الامتحان",
              examScore: "درجة الاختبار ≥",
              branch: "نقاط",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "لا توجد صورة",
              customerBackground: "خلفية العميل",
              customerLabel: "علامات العملاء",
            },
          },
          flow: {
            index: {
              processReminder: "نصائح العملية (",
              score: "النتيجة",
              TotalScore: "/مجموع النقاط",
            },
          },
          index: {
            taskName: "اسم المهمة",
            taskStatus: "حالة المهمة",
            whole: "كل شيء",
            completionStatus: "الحالة المكتملة",
            courseClassification: "تصنيف الدورة",
            practiceMode: "وضع الممارسة",
            examMode: "وضع الامتحان",
            practiceTime: "وقت الممارسة",
          },
          ppt: {
            index: {
              nextPage: "الصفحة التالية",
              tips: "نصائح",
              continueWithCurrentPage: "استمر في الصفحة الحالية",
              goToTheNextPage: "انتقل إلى الصفحة التالية",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "بعد الدخول إلى الصفحة التالية ، لن تتمكن من العودة إلى الصفحة السابقة ، يرجى التأكد من أن الصفحة الحالية كاملة قبل الانتقال",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "النقر على الصفحة التالية لن يطفو على السطح هذا التلميح",
            },
            list: { index: { slide: "عرض الشرائح" } },
          },
          record: {
            index: {
              unableToRecord: "لا يمكن تسجيل:",
              creatingARecorderService: "إنشاء خدمة Recorder",
              turnOffTheRecorderService: "إغلاق خدمة Recorder",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "العميل الحالي متصل بالفعل بخادم ASR Websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "استعادة الحالة بعد معالجة الإطار الأخير",
              aSRConnectionClosed: "تم إغلاق اتصال ASR...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "تم تعليق التسجيل ، وتم الانتهاء منه ، ولكن البيانات لا تزال موجودة في ذاكرة التخزين المؤقت",
              foundASRChannelClosedRecreateWebsocketLink:
                "اكتشاف إغلاق قناة ASR ، وإعادة إنشاء رابط Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "إعادة إنشاء رابط Websocket بعد العثور على قناة ASR غير مفتوحة",
            },
            ppt: { hook: { index: { serverError: "خطأ الخادم" } } },
            text: {
              index: {
                pleaseEnter: "الرجاء إدخال",
                sendOut: "إرسال",
                startPracticing: "بدء الممارسة",
                startTheExam: "بدء الامتحان",
              },
            },
            util: {
              recorded: "تم تسجيله",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "يجب أن يكون 16 بت pcm SendFrameSize عددًا صحيحًا مضاعفًا من 2",
              pCMSamplingRateShouldNotOccur:
                "لا ينبغي أن يظهر معدل أخذ العينات pcm",
              andTheRequiredSamplingRate: "ومعدلات أخذ العينات المطلوبة",
              atypism: "عدم الاتساق",
              errorsThatShouldNotOccur: "الأخطاء التي لا ينبغي أن تحدث:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> بيانات متسلسلة ، إذا لم تكن المتطلبات عالية ، يمكنك التخلص منها مباشرة </span>',
              flower: "زهرة",
              TransferStopped: ": توقف الإرسال",
            },
            video: { index: { camera: "الكاميرا" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "العميل الحالي متصل بالفعل بخادم ASR Websocket",
                    aSRConnectionClosed: "تم إغلاق اتصال ASR...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "اكتشاف إغلاق قناة ASR ، وإعادة إنشاء رابط Websocket",
                    unableToRecord: "لا يمكن تسجيل:",
                    aSRChannelClosedReopened: "قناة ASR مغلقة ، إعادة فتح",
                    theASRChannelWasNotCreatedStartingNow:
                      "لم يتم إنشاء قناة ASR ، ابدأ في الإنشاء الآن",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect رد الاتصال ، لإزالة قناة ASR",
                    creatingARecorderService: "إنشاء خدمة Recorder",
                    turnOffTheRecorderService: "إغلاق خدمة Recorder",
                  },
                  util: {
                    recorded: "تم تسجيله",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "يجب أن يكون 16 بت pcm SendFrameSize عددًا صحيحًا مضاعفًا من 2",
                    pCMSamplingRateShouldNotOccur:
                      "لا ينبغي أن يظهر معدل أخذ العينات pcm",
                    andTheRequiredSamplingRate: "ومعدلات أخذ العينات المطلوبة",
                    atypism: "عدم الاتساق",
                    errorsThatShouldNotOccur: "الأخطاء التي لا ينبغي أن تحدث:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> بيانات متسلسلة ، إذا لم تكن المتطلبات عالية ، يمكنك التخلص منها مباشرة </span>',
                    flower: "زهرة",
                    TransferStopped: ": توقف الإرسال",
                  },
                },
                index: { failInSend: "فشل الإرسال" },
              },
              index: {
                startRecording: "بدء التسجيل",
                endRecording: "إنهاء التسجيل",
                startPracticing: "بدء الممارسة",
                startTheExam: "بدء الامتحان",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(العدد المتبقي من الاختبارات الحالية هو",
              totalScriptScore: "مجموع نقاط الكلام:",
              totalEmotionalScore: "مجموع النقاط العاطفية:",
              totalScoreOfExpression: "التعبير عن النتيجة الإجمالية:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "يجب مراجعة نتائج تقييم المهمة الحالية يدويًا ، وسيتم إعلامك بالنتائج النهائية عند الانتهاء من المراجعة. يرجى الانتظار بصبر",
              areYouSureToSubmit: "هل تم تأكيد التقديم ؟",
              theExerciseHasEndedAreYouSureToSubmit:
                "انتهت الممارسة ، هل تم تأكيد التقديم ؟",
              theExamHasEndedAreYouSureToSubmit:
                "انتهى الامتحان ، هل تم تأكيد التقديم ؟",
              completed: "أنجز",
              page: "الصفحة",
              pageCount: "مجموع الصفحات",
              topic: "سؤال",
              totalNumberOfQuestions: "العدد الإجمالي للأسئلة",
              totalScore: "مجموع النقاط",
              branch: "نقاط",
              accumulatedCompletionProgress: "التقدم التراكمي في الإنجاز",
              accumulatedPracticeDuration: "مدة التمرين التراكمي",
              lastPauseExerciseResults: "آخر تعليق-نتائج التمرين",
              exerciseInstructions: "تعليمات الممارسة",
              examInstructions: "تعليمات الامتحان",
              halfwayPausePracticeResults: "وقفة منتصف الطريق-نتائج الممارسة",
              tips: "نصائح",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "بعد الانسحاب ، لا يتم تضمين هذا التمرين في النتيجة ، هل يتم تأكيد الانسحاب ؟",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "الانسحاب غير مسموح به في منتصف الامتحان ، هل تم تأكيد التقديم ؟",
              pleaseOperateWithCaution: "مرة ، يرجى العمل بحذر)",
              restart: "ابدأ من جديد",
              returnToLearning: "العودة إلى التعلم",
              continuePracticing: "استمر في الممارسة",
              continuingTheExam: "استمر في الامتحان",
              return: "العودة",
              notSubmittedTemporarily: "عدم تقديم",
              iGotIt: "أعلم ذلك",
              confirmExit: "تأكيد الخروج",
              confirmSubmission: "تأكيد التقديم",
              signOut: "الخروج",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "تعليق مؤقت",
              submit: "المقدمة",
              remainingTimeOfThisQuestion: "الوقت المتبقي من هذا السؤال",
              SecondsLeftUntilTheEndTime:
                "لم يتبق سوى 10 ثوانٍ قبل وقت النهاية",
              remainingTime: "الوقت المتبقي",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "لم يتم اكتشاف المحتوى الصوتي للمستخدم ، يرجى المحاولة مرة أخرى",
          },
          wave: {
            index: {
              continueRecording: "استمر في التسجيل",
              submit: "المقدمة",
              endRecording: "إنهاء التسجيل",
              suspendRecording: "تعليق التسجيل",
              startPracticing: "بدء الممارسة",
              startTheExam: "بدء الامتحان",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "نموذج الحوار",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "الضوضاء المفرطة ، يرجى التبديل إلى بيئة هادئة",
              normalNoiseDetection: "الكشف عن الضوضاء أمر طبيعي",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "مستوى الصوت صغير جدًا ، يرجى رفع مستوى الصوت",
              volumeDetectionIsNormal: "الكشف عن مستوى الصوت أمر طبيعي",
              noiseDetection: "الكشف عن الضوضاء",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "يرجى الحفاظ على الهدوء وتسجيل نغمة البيئة 3s",
              endRecording: "إنهاء التسجيل",
              startRecording: "بدء التسجيل",
              volumeDetection: "الكشف عن مستوى الصوت",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'يرجى قراءة "اختبار مستوى الصوت الآن" بصوت عالٍ مع مستوى الصوت العادي',
              playLastDetectedAudio: "تشغيل آخر كشف الصوت",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "يرجى قراءة المتطلبات بعناية قبل البدء",
              return: "العودة",
              iGotIt: "أعلم ذلك",
              timeLimitForSingleQuestionAnswering:
                "حد وقت الإجابة على سؤال واحد",
              totalTimeLimit: "مجموع الوقت",
              unlimitedTime: "وقت غير محدود",
              stillNeedToCompleteTheTaskInTotal:
                "ما زلت بحاجة إلى إكمال المهمة",
              practice: "تمارين",
              second: "مرة واحدة",
              taskScore: "مهمة درجة واحدة ≥",
              branch: "نقاط",
              or: "أو",
              examScore: "درجة الاختبار ≥",
              remainingExamTimes: "عدد الاختبارات المتبقية",
              totalScore: "مجموع النقاط",
              Script: "(تقنية الكلام",
              scoreEmotion: "تقسيم العواطف",
              fractionExpression: "التعبير الفرعي",
              points: "(نقاط)",
              standardScore: "تحقيق النتيجة",
              excellentScore: "نقاط ممتازة",
              exerciseInstructions: "تعليمات الممارسة",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "من أجل الإجابة على الأسئلة بسلاسة ، يرجى إكمال اختبار الجهاز أولاً:",
              camera: "الكاميرا",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "أن تكون قادرًا على رؤية نفسك في الفيديو يعني أن الكاميرا طبيعية",
              microphone: "الميكروفون",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "يرجى التحدث إلى الميكروفون ، ورؤية نمط الموجة يعني أن الميكروفون طبيعي",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "فارغ",
                  copyTo: "نسخ إلى",
                  classification: "تصنيف",
                  rename: "إعادة تسمية",
                  newlyBuild: "جديد",
                  categoryParent: "تصنيف الرؤساء",
                  moveTo: "الانتقال إلى",
                  name: "اسم",
                },
              },
              util: {
                rename: "إعادة تسمية",
                moveTo: "الانتقال إلى",
                copyTo: "نسخ إلى",
                delete: "حذف",
                courseClassification: "تصنيف الدورة",
                newlyBuild: "جديد",
              },
            },
          },
          edit: { index: { curriculum: "دورات تدريبية" } },
          index: {
            learn: "تعلم",
            practice: "ممارسة",
            examination: "اختبار",
            curriculum: "دورات تدريبية",
            courseIntroduction: "مقدمة الدورة:",
            createdOn: "إنشاء الوقت:",
            published: "نشرت",
            tasks: "مهمة واحدة",
            unpublishedTasks: "المهام غير المنشورة",
            task: "المهام",
            operation: "العملية",
            learningMaterials: "المواد الدراسية",
            goPractice: "اذهب للتدرب",
            publishTask: "نشر المهام",
            taskData: "بيانات المهمة",
            edit: "تحرير",
            copy: "نسخ",
            successfullyCopied: "نجاح النسخ",
            delete: "حذف",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "سيتم إفراغ جميع بيانات المهمة ضمن هذه الدورة بعد الحذف ، هل تم تأكيد حذف الدورة التدريبية ؟",
            courseName: "اسم الدورة",
            creationTime: "إنشاء الوقت",
            releaseStatus: "حالة النشر",
            whole: "كل شيء",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "لم يتم اكتشاف أي صوت",
              voice: "صوت",
              text: "النص",
              referenceScript: "الكلمات المرجعية:",
              clickOnTheRightSideToStartRecording:
                "اضغط على الجانب الأيمن لبدء التسجيل",
              sendOut: "إرسال",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "العميل الحالي متصل بالفعل بخادم ASR Websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "استعادة الحالة بعد معالجة الإطار الأخير",
                aSRConnectionClosed: "تم إغلاق اتصال ASR...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "تم تعليق التسجيل ، وتم الانتهاء منه ، ولكن البيانات لا تزال موجودة في ذاكرة التخزين المؤقت",
                foundASRChannelClosedRecreateWebsocketLink:
                  "اكتشاف إغلاق قناة ASR ، وإعادة إنشاء رابط Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "إعادة إنشاء رابط Websocket بعد العثور على قناة ASR غير مفتوحة",
                unableToRecord: "لا يمكن تسجيل:",
                creatingARecorderService: "إنشاء خدمة Recorder",
                turnOffTheRecorderService: "إغلاق خدمة Recorder",
              },
              util: {
                recorded: "تم تسجيله",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "يجب أن يكون 16 بت pcm SendFrameSize عددًا صحيحًا مضاعفًا من 2",
                pCMSamplingRateShouldNotOccur:
                  "لا ينبغي أن يظهر معدل أخذ العينات pcm",
                andTheRequiredSamplingRate: "ومعدلات أخذ العينات المطلوبة",
                atypism: "عدم الاتساق",
                errorsThatShouldNotOccur: "الأخطاء التي لا ينبغي أن تحدث:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> بيانات متسلسلة ، إذا لم تكن المتطلبات عالية ، يمكنك التخلص منها مباشرة </span>',
                flower: "زهرة",
                TransferStopped: ": توقف الإرسال",
              },
            },
            finishTest: {
              index: {
                endTest: "إنهاء الاختبار",
                testResult: "نتائج الاختبار",
                returnToCanvas: "العودة إلى القماش",
                retest: "إعادة الاختبار",
              },
            },
          },
          index: {
            testFromCurrentNode: "اختبار من العقدة الحالية",
            listening: "في الإقبال...",
            canvasTesting: "اختبار القماش",
            endOfProcess: "نهاية العملية",
            restart: "ابدأ من جديد",
            start: "ابدأ",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "أغلق التحليل",
                  expandParsing: "تحليل",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "، الكثير من الكلمات",
                  second: "مرة واحدة",
                  ratingOverview: "نظرة عامة على الدرجات",
                  totalScore: "مجموع النقاط",
                  section: "رقم",
                  name: "الاسم",
                  of: "(المجموع",
                  people: "الناس)",
                  scriptScore: "درجة الكلام",
                  common: "المجموع",
                  keyPoints: "نقطة ،",
                  notReached: "لم يتم الوصول إليها ،",
                  have: "هناك",
                  violationOfRegulations: "انتهاك",
                  emotionalScore: "النتيجة العاطفية",
                  appear: "يظهر",
                  secondaryNegativeEmotions: "المشاعر السلبية",
                  expressionScore: "درجة التعبير",
                  speakTooFast: "تحدث بسرعة كبيرة",
                  times: "مرة أخرى ،",
                  speakTooSlowly: "تحدث ببطء شديد",
                  average: "متوسط",
                  wordminute: "كلمة/دقيقة",
                },
              },
              score: {
                index: {
                  studentNodeName: "اسم عقدة الطالب",
                  keyPointName: "اسم النقطة الرئيسية",
                  slideNodeName: "اسم عقدة الشريحة",
                  dialogue: "الحوار",
                  mainPoints: "النقاط الرئيسية",
                  slide: "عرض الشرائح",
                  detectionResult: "نتائج الاختبار",
                  satisfy: "تلبية",
                  dissatisfaction: "غير راضٍ",
                  score: "النتيجة",
                  script: "الكلام",
                  semantics: "دلالات",
                  scriptViolation: "مخالفات الكلام",
                  emotion: "العواطف",
                  expression: "التعبير",
                },
              },
              taskTitle: { index: { branch: "نقاط" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "هذه المرة",
                  lastTime: "آخر مرة",
                  comprehensiveEvaluation: "التقييم المتكامل",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· تراكم الكلام القياسي',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "في كلمة واحدة ، اتبع الطلاب عدد الكلمات التي قرأتها",
                  aWordspan: "كلمة </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'مرات </span><br /><span style = "margin-left: 8px">· سرعة التحدث بطيئة للغاية',
                  timesspan: "مرة </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· الكثير من الكلمات',
                  complete: "المحتوى الكامل",
                  keyHit: "ضرب النقاط الرئيسية",
                  languageNorm: "معايير اللغة",
                  fluentExpression: "التعبير السلس",
                  emotionallyPositive: "إيجابية عاطفيا",
                  radarChart: "مخطط الرادار",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· عدد العمليات الكاملة',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· النقاط المراد ضربها',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· نعم',
                  dealingWithIllegalWordsspan: "كلمة مخالفة </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· سرعة التحدث بسرعة كبيرة',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    'مرات </span><br /><span style = "margin-left: 8px">· الكثير من الكلمات',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· المشاعر السلبية',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "سجل الدرجات",
                  firstRating: "التقييم الأول",
                  totalScore: "مجموع النقاط",
                  changeTo: "التغيير إلى",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "مرحلة الاتصال",
                  communicationObjectives: "أهداف الاتصال",
                  scoringCriteria: "معايير التسجيل",
                  suggestionsForImprovement: "توصيات الترقية",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "عدد المرات المتبقية:",
                  second: "مرة واحدة",
                  doItAgain: "مرة أخرى",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "المتصفح لا يدعم تشغيل الصوت.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "التعليقات والاقتراحات",
                  regenerate: "إعادة توليد",
                  generate: "توليد",
                  generationFailed: "توليد الفشل",
                },
              },
              resultModal: {
                index: { return: "العودة", viewDetails: "عرض التفاصيل" },
              },
            },
            index: {
              reRatingSuccessful: "نجاح إعادة التسجيل",
              reRatingFailed: "فشل إعادة التسجيل",
              downloadRecording: "تحميل التسجيلات",
              downloadText: "تحميل النص",
              download: "تحميل",
              reRating: "إعادة التسجيل",
              submitARatingAppeal: "تقديم شكوى درجة",
              reviewSubmission: "مراجعة التقديم",
              saveChanges: "حفظ التعديلات",
              previousSentence: "الجملة السابقة",
              nextSentence: "الجملة التالية",
              score: "التقييم",
              scoringSituation: "حالة التهديف",
              dialogueAnalysis: "تحليل الحوار",
            },
          },
          index: {
            ratingAppealInProgress: "درجة الاستئناف",
            operation: "العملية",
            wrongQuestionSet: "مجموعة الأسئلة الخاطئة",
            ratingUpdate: "تحديث التقييم",
            viewDetails: "عرض التفاصيل",
            curriculum: "دورات تدريبية",
            taskName: "اسم المهمة",
            taskType: "نوع المهمة",
            score: "النتيجة",
            scoringResults: "نتيجة التهديف",
            time: "الوقت",
            courseClassification: "تصنيف الدورة",
            whole: "كل شيء",
            learningTime: "وقت الدراسة",
            historicalLearningData: "بيانات التعلم التاريخية",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "تعليق التمرين",
              lastProgress: "آخر تقدم",
              continuePracticing: "استمر في الممارسة",
              practice: "تمارين",
              startPracticing: "بدء الممارسة",
              historicalData: "البيانات التاريخية",
              courseIntroduction: "مقدمة الدورة:",
              exerciseDifficulty: "صعوبة الممارسة:",
              practiceCompletionRequirements: "متطلبات إتمام التمرين:",
              practiceFrequency: "عدد التمارين ≥",
              second: "مرة واحدة",
              anyScore: "أي درجة ≥",
              branch: "نقاط",
              or: "أو",
              taskList: "قائمة المهام",
              practiceTime: "وقت الممارسة:",
              to: "إلى",
              unlimitedTime: "وقت غير محدود",
              completionStatus: "الحالة المكتملة",
              numberOfExercises: "عدد التمارين",
              maximumScore: "أعلى الدرجات",
              operation: "العملية",
              enteringLearning: "الدخول في التعلم",
              historicalLearningData: "بيانات التعلم التاريخية",
              wrongQuestionSet: "مجموعة الأسئلة الخاطئة",
            },
          },
          index: {
            practiceTasks: "مهام التدريب",
            examTasks: "مهام الامتحان",
            task: "المهام",
          },
          learn: {
            index: {
              courseName: "اسم الدورة",
              courseIntroduction: "مقدمة الدورة",
              learningContent: "محتوى التعلم",
              downloadLearningMaterials: "تحميل المواد التعليمية",
              startPracticing: "بدء الممارسة",
            },
          },
          test: {
            index: {
              taskList: "قائمة المهام",
              courseIntroduction: "مقدمة الدورة:",
              examDifficulty: "صعوبة الامتحان:",
              requirementsForPassingTheExam: "متطلبات اجتياز الامتحان:",
              examScore: "درجة الاختبار ≥",
              branch: "نقاط",
              examTime: "وقت الامتحان:",
              to: "إلى",
              unlimitedTime: "وقت غير محدود",
              completionStatus: "الحالة المكتملة",
              completionTimes: "عدد مرات الإنجاز",
              maximumScore: "أعلى الدرجات",
              operation: "العملية",
              immediateExam: "الامتحان الفوري",
              history: "السجلات التاريخية",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "الحديث المرجعي" } },
              flow: {
                index: {
                  wrongQuestionItem: "سؤال خاطئ (",
                  score: "النتيجة",
                  TotalScore: "/مجموع النقاط",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "خطأ الخادم" } },
                  index: {
                    startRecording: "بدء التسجيل",
                    endRecording: "إنهاء التسجيل",
                    startPracticing: "بدء الممارسة",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "الرجاء إدخال",
                    sendOut: "إرسال",
                    startPracticing: "بدء الممارسة",
                  },
                },
                voice: {
                  index: {
                    startRecording: "بدء التسجيل",
                    endRecording: "إنهاء التسجيل",
                    startPracticing: "بدء الممارسة",
                  },
                  hook: { index: { failInSend: "فشل الإرسال" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "نتيجة هذا التمرين الخاطئ",
                  tips: "نصائح",
                  areYouSureToEnd: "هل تم تأكيد النهاية ؟",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "التدرب مرة أخرى دون إتقان الأسئلة الخاطئة",
                  continue: "استمر",
                  returnToTheSetOfIncorrectQuestions:
                    "ارجع إلى مجموعة الأسئلة الخاطئة",
                  end: "النهاية",
                  wrongQuestionItem: "سؤال خاطئ",
                  numberOfExercisesThisTime: "عدد هذه التمارين",
                  masteringTheSituation: "فهم الموقف",
                  mastered: "اتقنت",
                  notMastered: "غير السيطرة",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "ممارسة هذا السؤال مرة أخرى",
                  nextQuestion: "السؤال التالي",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "تمارين الأسئلة الخاطئة",
                endExercise: "نهاية التمرين",
              },
            },
            text: {
              index: {
                mispracticeExercises: "تمارين الأسئلة الخاطئة",
                endExercise: "نهاية التمرين",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "تمارين الأسئلة الخاطئة",
                endExercise: "نهاية التمرين",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "عرض الكلام",
                  section: "رقم",
                  secondaryDiscourseTechnique: "تقنية الكلام",
                },
              },
              tableItem: {
                count: { second: "مرة واحدة" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "لم يتم الاستعلام عن المعلومات ذات الصلة",
                  recallWords: "استدعاء الكلمات",
                  branch: "نقاط",
                  answerIncorrectly: "إجابة خاطئة",
                  second: "مرة واحدة",
                  wrongAnswerRate: "معدل الإجابة غير الصحيحة",
                },
                standardScript: {
                  standardScript: "الكلام القياسي",
                  myScript: "كلامي",
                },
                status: { mastered: "اتقنت", notMastered: "غير السيطرة" },
                index: {
                  totalNumberOfQuestionsAnswered: "مجموع عدد الإجابات",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "عدد المرات التي تم فيها ممارسة الأسئلة الخاطئة",
                },
              },
            },
            index: {
              wrongQuestionList: "قائمة الأسئلة الخاطئة",
              wrongQuestionSet: "مجموعة الأسئلة الخاطئة",
              mispracticeExercises: "تمارين الأسئلة الخاطئة",
              taskName: "اسم المهمة",
              staff: "الموظفون",
              masteringTheSituation: "فهم الموقف",
              whole: "كل شيء",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "عدد التمارين ≥",
              second: "مرة واحدة",
              or: "أو",
              excellentScoreAtAnyOneTime: "سجل ممتاز في أي وقت (≥",
              points: "(نقاط)",
              examScore: "درجة الاختبار ≥",
              branch: "نقاط",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "التوزيع العشوائي",
              assignToDesignatedPersonnel: "تعيين الموظفين المعينين",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "زيادة عدد الاختبارات الناجحة",
              failedToIncreaseTheNumberOfExams: "زيادة عدد الاختبارات الفاشلة",
              increaseTheNumberOfExams: "زيادة عدد الامتحانات",
              selectObjects: "اختيار الكائنات",
            },
          },
          executionFilter: { index: { participationRate: "معدل المشاركة" } },
          intelligentReview: {
            index: {
              overallReview: "مراجعة شاملة",
              pleaseEnterTheReviewCriterianotMandatory:
                "يرجى إدخال معيار التعليق (غير مطلوب)",
              commentaryOnSingleSentenceRhetoric: "تعليق جملة واحدة",
              realTimeReview: "التعليقات في الوقت الحقيقي",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "متوسط درجات الامتحان",
            averageExamDurationmin: "متوسط مدة الامتحان (دقيقة)",
            accumulatedPracticeDurationmin: "مدة التمرين التراكمي (دقيقة)",
            accumulatedPracticeTimes: "عدد التمارين التراكمية",
            totalScore: "مجموع النقاط",
            average: "متوسط الدرجات",
            total: "المجموع",
            keyHitCount: "عدد الضربات الرئيسية",
            practiceAverageScore: "متوسط درجات الممارسة",
            averagePracticeDurationmin: "متوسط مدة التمرين (دقيقة)",
            topOfTheHighestScore: "أعلى درجة Top3",
            branch: "نقاط",
            errorProneItemTop: "Top5 عنصر خاطئ",
            hitRate: "معدل الإصابة",
            hitRateOfKeyPointsInScript: "النقاط الرئيسية للكلمات معدل الإصابة",
            averageScriptScore: "متوسط درجة الكلام",
            sortByProcess: "الفرز حسب العملية",
            sortByScore: "فرز حسب النتيجة",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "متوسط درجات الصفحة/مجموع نقاط الصفحة",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "متوسط الدرجات/مجموع النقاط",
            details: "التفاصيل",
            viewRules: "عرض القواعد",
            standardScript: "الكلام القياسي",
          },
          scoreDetailList: {
            section: "رقم",
            second: "مرة واحدة",
            practice: "تمارين",
            examination: "الامتحانات",
            notMeetingStandards: "فشل في تلبية المعايير",
            meetingStandards: "تلبية المعايير",
            excellent: "ممتاز",
            frequency: "عدد المرات",
            fraction: "النتيجة",
            result: "النتائج",
            operation: "العملية",
            viewDetails: "عرض التفاصيل",
          },
          index: {
            common: "المجموع",
            people: "الناس",
            staff: "الموظفون",
            notMeetingStandards: "فشل في تلبية المعايير",
            meetingStandards: "تلبية المعايير",
            excellent: "ممتاز",
            notStarted: "لم تبدأ",
            haveInHand: "قيد العمل",
            completed: "أنجز",
            passed: "اعتمد",
            hangInTheAir: "لم يكتمل",
            failed: "فشل",
            unlimitedTime: "وقت غير محدود",
            to: "إلى",
            maximumScoringCriteria: "أعلى معايير التهديف",
            completionStatus: "الحالة المكتملة",
            employeeName: "اسم الموظف",
            frequency: "عدد المرات",
            practice: "تمارين",
            examination: "الامتحانات",
            average: "متوسط الدرجات",
            speechCraftAvgScore: "متوسط درجة الكلام",
            emotionAvgScore: "متوسط درجة المزاج",
            speedAvgScore: "التعبير عن متوسط الدرجات",
            flowAvgScore: "متوسط درجة العملية",
            practiceCostTime: "الوقت التراكمي",
            participateCommitRate: "معدل الامتثال",
            participateExcellentRate: "معدل ممتاز",
            duration: "طول الوقت",
            score: "النتيجة",
            scoringResults: "نتيجة التهديف",
            flowScore: "نتيجة العملية",
            craftScore: "درجة الكلام",
            emotionScore: "النتيجة العاطفية",
            speedScore: "درجة التعبير",
            contentIntegrity: "اكتمال المحتوى",
            pointHitRate: "النقاط الرئيسية معدل الإصابة",
            emotionNegativeRate: "نسبة سلبية عاطفيا",
            paraPhaSiaRate: "نسبة غير طبيعية في سرعة الكلام",
            toneOvermuchRate: "نسبة مفرطة من الكلمات",
            maximumScore: "أعلى الدرجات",
            highestScoreResult: "أعلى نتيجة",
            state: "الحالة",
            averageDurationmin: "متوسط طول (دقيقة)",
            lately: "مؤخرا",
            time: "الوقت",
            operation: "العملية",
            viewDetails: "عرض التفاصيل",
            wrongQuestionSet: "مجموعة الأسئلة الخاطئة",
            taskName: "اسم المهمة",
            completeRequirements: "استكمال المتطلبات",
            publishTo: "كائن النشر",
            taskTime: "وقت المهمة",
            fractionalComposition: "تكوين الكسر",
            totalScore: "مجموع النقاط",
            branch: "نقاط",
            Script: "(تقنية الكلام",
            scoreEmotion: "تقسيم العواطف",
            fractionExpression: "التعبير الفرعي",
            points: "(نقاط)",
            implementationRate: "معدل المشاركة",
            numberOfExecutors: "عدد المشاركين",
            numberOfPeopleNotExecuted: "عدد غير المشاركين",
            completionRate: "معدل الإنجاز",
            numberOfCompletedPersonnel: "العدد المنجز",
            numberOfUnfinishedPersonnel: "العدد غير المكتمل",
            complianceRate: "معدل الامتثال",
            numberOfQualifiedPersonnel: "عدد الأشخاص الذين يستوفون المعايير",
            numberOfNonCompliantIndividuals: "عدد الأشخاص غير المستوفين",
            excellentRate: "معدل ممتاز",
            participateCompleteRate: "معدل إتمام المشاركة",
            numberOfOutstandingIndividuals: "عدد ممتاز",
            numberOfNonOutstandingIndividuals: "عدد غير ممتاز",
            export: "تصدير",
            taskData: "بيانات المهمة",
            resultAnalysis: "تحليل النتائج",
            scoreDetails: "تفاصيل النتيجة-",
            dataOverview: "نظرة عامة على البيانات",
            taskDetail: "تفاصيل المهمة",
            scriptAnalysis: "تحليل الكلام",
            flowAnalysis: "تحليل العملية",
            expressionAnalysis: "تحليل التعبير",
            emotionAnalysis: "تحليل المشاعر",
            byTime: "عرض البعد الزمني",
            byNumber: "عدد مرات عرض البعد",
            personData: "حسب الموظفين",
            numberData: "تفاصيل السجل لكل مرة",
            count: "حسب العدد",
            person: "حسب عدد الأشخاص",
            reachTimes: "عدد مرات الوصول",
            outOfReachTimes: "عدد مرات عدم الوصول",
            seeOutOfReach: "انقر لعرض عدم لمس",
            accumulative: "تراكمي",
            script: "الكلام",
            times: "مرة واحدة",
            sentence: "الجملة",
            expressionAverage: "التعبير عن متوسط الدرجات",
            modalAnalysis: "تحليل الكلمات",
            total: "المجموع",
            muchModalAnalysis: "هناك الكثير من الكلمات",
            normal: "طبيعي",
            speedAnalysis: "تحليل سرعة الكلام",
            speedDistribution: "توزيع سرعة الكلام",
            speakingSpeed: "تحدث بسرعة",
            normalSpeak: "سرعة الكلام العادية",
            tooFastSpeak: "تحدث بسرعة كبيرة",
            tooSlowlySpeak: "تحدث ببطء شديد",
            violationWord: "كلمة انتهاك",
            violationWordAnalysis: "تحليل كلمة الانتهاك",
            noData: "لا شيء",
            emotionalScore: "النتيجة العاطفية",
            emotionalDistribution: "التوزيع العاطفي",
            negativeEmotion: "المشاعر السلبية",
            normalEmotion: "المزاج الطبيعي",
            getDetail: "عرض التفاصيل",
            barPattern: "عرض الرسم البياني الشريطي",
            timeDimension: "عرض خط العرض الوقت",
            timesDimension: "عدد مرات عرض البعد",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "متوسط الدرجات",
                  averageScoreOfScript: "متوسط درجة الكلام",
                  averageEmotionalScore: "متوسط الدرجات العاطفية",
                  expressionAverageScore: "التعبير عن متوسط الدرجات",
                  processAverageScore: "متوسط درجات العملية",
                  accumulatedDuration: "الوقت التراكمي",
                  accumulatedTimes: "العدد التراكمي",
                  participationComplianceRate: "معدل الامتثال للمشاركة",
                  participationRateForAchievingExcellence:
                    "المشاركة في المعدل الأمثل",
                  branch: "نقاط",
                  numberOfPeople: "عدد الأشخاص",
                  averageScoreOfExpression: "التعبير عن متوسط الدرجات",
                  people: "الناس",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "المشاعر السلبية" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "متوسط درجة الكلام",
                  branch: "نقاط",
                },
                useSummaryDetail: {
                  keyHitRate: "النقاط الرئيسية معدل الإصابة",
                },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "التعليقات الذكية",
            pleaseImproveTheConfiguration: "يرجى تحسين التكوين",
            examResults: "نتائج الامتحان",
            showTheResultsAfterEachExam: "عرض النتائج بعد كل اختبار",
            showTheResultsAfterTheLastExam: "نتائج العرض بعد الامتحان الأخير",
            doNotDisplayResults: "لا تظهر النتائج",
            resultDisplay: "عرض النتائج",
            score: "النتيجة",
            scoringResults: "نتيجة التهديف",
            allowViewingScoreDetails: "السماح بعرض تفاصيل النتيجة",
            viewCanvasConfiguration: "عرض تكوين اللوحة",
            synchronizationFailedProcessNodeHasChanged:
              "فشل المزامنة ، تغيرت عقدة العملية",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "المزامنة ناجحة ، يرجى النقر على نشر دورات محدثة",
            retrievedTheLatestCanvasConfiguration: "تم سحب أحدث تكوين قماشية",
            synchronizeCanvasConfiguration: "تزامن التكوين القماشية",
            scoringMethod: "طريقة التسجيل",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "النقاط ، العملية {0} دقيقة ، الكلام {1} دقيقة ، العاطفة {2} دقيقة ، سرعة الكلام {3} دقيقة)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "يجب أن تكون المعايير الممتازة أعلى من معايير الامتثال",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "يجب أن تفي معايير التسجيل بأكثر من 0 ٪ وأقل من أو يساوي 100 ٪",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "يجب أن يفي معيار التسجيل بأكثر من 0 نقطة وأقل من {0} نقطة",
            doRatingsRequireManualReview:
              "ما إذا كانت الدرجات تتطلب مراجعة يدوية",
            allocationStrategy: "استراتيجية التوزيع",
            pleaseSelectTheAssignedPersonnel: "يرجى اختيار تعيين الموظفين",
            pleaseSelectADesignatedPerson: "يرجى اختيار الشخص المعين",
            timeLimitForSingleQuestionAnswering: "حد وقت الإجابة على سؤال واحد",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "النشر الافتراضي للموظفين المضافة حديثا",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "تشير إعادة التسجيل إلى أنه سيتم مطابقتها وفقًا لقواعد التسجيل الخاصة بأحدث لوحة قماشية. هناك بيانات تسجيل تم التحقق منها يدويًا في المهمة الحالية.",
            notCovered: "لا تغطي",
            cover: "تغطية",
            participationSituation: "المشاركة",
            numberOfParticipants: "عدد المشاركين",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "عدد الأشخاص الذين تم اختيارهم لهذه المهمة",
            sumOfTheNumberOfParticipantsInTheTask:
              "عدد الأشخاص الذين شاركوا في البعثة",
            numberOfCompletedPersonnel: "العدد المنجز",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "عدد الأشخاص الذين يستوفون متطلبات إكمال المهمة",
            numberOfQualifiedPersonnel: "عدد الأشخاص الذين يستوفون المعايير",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "عدد الأشخاص الذين يستوفون أهداف المهمة",
            excellentPersonnel: "عدد ممتاز",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "تلبية عدد الأشخاص الذين سجلوا نتائج ممتازة",
            participationRate: "معدل المشاركة",
            numberOfParticipantsNumberOfParticipants:
              "عدد المشاركين/عدد المشاركين",
            numberOfCompletedNumberOfParticipants:
              "عدد الأشخاص المنجز/عدد المشاركين",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "عدد الأشخاص الذين يستوفون المعايير/عدد المشاركين",
            excellentNumbernumberOfParticipants: "عدد ممتاز/عدد المشاركين",
            participationCompletionRate: "معدل إتمام المشاركة",
            numberOfCompletedParticipated: "العدد المنجز/عدد المشاركين",
            participationComplianceRate: "معدل الامتثال للمشاركة",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "العدد القياسي/عدد المشاركين",
            participationRateForAchievingExcellence:
              "المشاركة في المعدل الأمثل",
            accumulatedDuration: "الوقت التراكمي",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "يشارك المتدربون في المهمة لفترة طويلة",
            averageDuration: "متوسط طول الوقت",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "مشاركة المتدربين في البعثات طلب طويل الأجل/العدد الإجمالي للمشاركة",
            accumulatedTimes: "العدد التراكمي",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "عدد المشاركين في المهام",
            averageScore: "متوسط الدرجات",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "متوسط درجات الأشخاص المشاركين في المهمة",
            maximumScore: "أعلى الدرجات",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "أعلى الدرجات للأشخاص المشاركين في المهمة",
            averageScoreOfScript: "متوسط درجة الكلام",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "مجموع نقاط الكلام للطلاب/إجمالي عدد المشاركة",
            totalScoreOfScript: "مجموع نقاط الكلام",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "يتم تعيين مجموع نقاط الكلمات في هذه المهمة",
            processAverageScore: "متوسط درجات العملية",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "مجموع نقاط عملية الطالب/إجمالي عدد المشاركة",
            totalProcessScore: "مجموع نقاط العملية",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "مجموع نقاط العملية المحددة في إطار هذه المهمة",
            averageEmotionalScore: "متوسط الدرجات العاطفية",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "مجموع الدرجات العاطفية للطلاب/إجمالي عدد المشاركة",
            totalEmotionalScore: "مجموع النقاط العاطفية",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "مجموع النقاط العاطفية المحددة في إطار هذه المهمة",
            averageScoreOfExpression: "التعبير عن متوسط الدرجات",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "يعبر المتدربون عن مجموع الدرجات/إجمالي عدد المشاركة",
            totalScoreOfExpression: "التعبير عن النتيجة الإجمالية",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "مجموع نقاط التعبير المحددة في إطار هذه المهمة",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "ابدأ في إعادة التقييم ، يمكنك الانتقال إلى مركز المهام للتحقق من التقدم",
            reRating: "إعادة التسجيل",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "تشير إعادة التسجيل إلى أنه سيتم مطابقتها وفقًا لقواعد التسجيل الخاصة بأحدث لوحة قماشية ، والتي ستغطي النتيجة الحالية",
            releaseTime: "وقت النشر",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "تمت مراجعته",
              pendingReview: "في انتظار المراجعة",
              enterKeywordSearch: "أدخل البحث عن الكلمات الرئيسية",
            },
            pageRightTable: {
              index: {
                employeeName: "اسم الموظف",
                systemRating: "تسجيل النظام",
                scoringResults: "نتيجة التهديف",
                duration: "طول الوقت",
                time: "الوقت",
                state: "الحالة",
                whetherToModifyTheRating: "ما إذا كان سيتم تصحيح النتيجة",
                finalScore: "النتيجة النهائية",
                reviewedBy: "المدقق",
                operation: "العملية",
                viewScoreDetails: "عرض تفاصيل النتيجة",
                viewStatus: "عرض الحالة",
                whole: "كل شيء",
                correctionStatus: "حالة التصحيح",
                completeReview: "استكمال المراجعة",
                tips: "نصائح",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "تخضع النتائج النهائية للطلاب لنتائج المراجعة النهائية الحالية ، يرجى التأكيد",
              },
            },
          },
          index: { manualReview: "التدقيق اليدوي" },
        },
        review: {
          index: {
            employeeName: "اسم الموظف",
            score: "النتيجة",
            scoringResults: "نتيجة التهديف",
            duration: "طول الوقت",
            taskName: "اسم المهمة",
            numberOfAppealScripts: "عدد الشكاوى",
            appealTime: "وقت الاستئناف",
            processStatus: "حالة العملية",
            scoreAfterReview: "النتيجة بعد المراجعة",
            reviewedBy: "شخص مراجعة",
            operation: "العملية",
            viewScoreStatus: "تحقق من النتيجة",
            whole: "كل شيء",
            manualReview: "مراجعة اصطناعية",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "بيانات مهمة التدريب",
            implementationRate: "معدل التنفيذ",
            numberOfExecutors: "عدد حالات التنفيذ",
            numberOfPeopleNotExecuted: "عدد الأشخاص الذين لم ينفذوا",
            completionRate: "معدل الإنجاز",
            numberOfCompletedPersonnel: "العدد المنجز",
            numberOfUnfinishedPersonnel: "العدد غير المكتمل",
            complianceRate: "معدل الامتثال",
            numberOfQualifiedPersonnel: "عدد الأشخاص الذين يستوفون المعايير",
            numberOfNonCompliantIndividuals: "عدد الأشخاص غير المستوفين",
            excellentRate: "معدل ممتاز",
            numberOfOutstandingIndividuals: "عدد ممتاز",
            numberOfNonOutstandingIndividuals: "عدد غير ممتاز",
            examTaskData: "بيانات مهمة الامتحان",
            published: "نشرت",
            courses: "دورة واحدة",
            courseData: "بيانات الدورة",
            practiceTasks: "مهام التدريب",
            examTasks: "مهام الامتحان",
          },
          practiceSummary: {
            practiceSummary: "ملخص الممارسة",
            numberOfPracticeTasks: "عدد مهام التدريب",
            numberOfExercises: "عدد التمارين",
            accumulatedPracticeDuration: "مدة التمرين التراكمي",
            complianceRate: "معدل الامتثال",
            excellentRate: "معدل ممتاز",
            ranking: "الترتيب",
          },
          rankingList: {
            numberOfExercises: "عدد التمارين",
            exerciseDuration: "طول الممارسة",
            complianceRate: "معدل الامتثال",
            excellentRate: "معدل ممتاز",
            diligenceChart: "قائمة الاجتهاد",
            excellentList: "قائمة ممتازة",
            ranking: "الترتيب",
            fullName: "الاسم",
            i: "أنا",
          },
          taskCard: {
            end: "الموعد النهائي:",
            noDeadline: "لا يوجد موعد نهائي",
            second: "مرة واحدة",
            branch: "نقاط",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "لا توجد مهمة",
            completed: "أنجز",
          },
          filter: { employeeDepartment: "قسم الموظفين" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "تصدير ناجح ، يرجى الذهاب إلى مركز المهام للتحقق",
              export: "تصدير",
            },
          },
          filterConfig: {
            dropdown: { custom: "التخصيص" },
            modal: {
              updateSuccessful: "نجاح التحديث",
              successfullySaved: "حفظ النجاح",
              setAsACommonReport: "تعيين كتقرير مشترك",
              updateCurrentReport: "تحديث التقرير الحالي",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "متوسط الدرجات",
                  averageScoreOfScript: "متوسط درجة الكلام",
                  averageEmotionalScore: "متوسط درجة المزاج",
                  expressionAverageScore: "التعبير عن متوسط الدرجات",
                  processAverageScore: "متوسط درجة العملية",
                  branch: "نقاط",
                },
              },
              comprehensiveEvaluation: { const: { data: "البيانات" } },
              doneStatus: {
                index: {
                  practice: "تمارين",
                  examination: "الامتحانات",
                  executed: "نفذت",
                  unexecuted: "لم ينفذ",
                  accumulatedExamDuration: "مدة الامتحان التراكمي",
                  examFrequency: "عدد الامتحانات",
                },
              },
              filter: {
                index: {
                  practice: "تمارين",
                  examination: "الامتحانات",
                  pleaseCompleteTheRequiredFields:
                    "يرجى تحسين العناصر المطلوبة",
                  staff: "الموظفون",
                  taskType: "نوع المهمة",
                  pleaseSelect: "يرجى اختيار",
                  taskName: "اسم المهمة",
                },
              },
            },
            index: {
              completionStatus: "حالة الإنجاز",
              comprehensiveEvaluation: "التقييم المتكامل",
              abilityGrowthTrend: "اتجاه نمو القدرة",
              errorProneAnalysis: "تحليل نقطة الخطأ",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "عدد الأشخاص النشطين",
            accumulatedPracticeDuration: "مدة التمرين التراكمي",
            averagePracticeDuration: "متوسط مدة التمرين",
            people: "الناس",
            numberOfPracticeTasks: "عدد مهام التدريب",
            numberOfExercises: "عدد التمارين",
            exerciseDuration: "طول الممارسة",
            second: "مرة واحدة",
            avgScore: "متوسط الدرجات",
            speechCraftAvgScore: "متوسط درجة الكلام",
            emotionAvgScore: "متوسط درجة المزاج",
            speedAvgScore: "التعبير عن متوسط الدرجات",
            flowAvgScore: "متوسط درجة العملية",
            practiceCostTime: "الوقت التراكمي",
            totalPracticeTimes: "العدد التراكمي",
            participateCommitRate: "معدل الامتثال للمشاركة",
            participateExcellentRate: "المشاركة في المعدل الأمثل",
          },
        },
        index: {
          employeeProfile: "صورة الموظف",
          overview: "نظرة عامة",
          personalSignage: "لوحة شخصية",
          ongoingExercises: "تمارين جارية",
          ongoingExams: "الامتحانات الجارية",
          practiceTaskProgress: "ممارسة تقدم المهمة",
          examTaskProgress: "تقدم مهمة الامتحان",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "يرجى اختيار الكلمة المفاهيمية",
              sessionDetectionSettings: "إعدادات الكشف عن الجلسة",
              successfullySaved: "حفظ النجاح",
              abnormalSpeechSpeed: "سرعة الكلام غير طبيعية",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "يدعم معيار الكشف عن سرعة الكلام المخصص ، وسيتم الحكم على النتيجة على أنها سرعة طبيعية ، وسرعة التحدث سريعة جدًا ، وسرعة التحدث بطيئة جدًا. عندما تكون سرعة الكلام غير طبيعية (سريعة جدًا/بطيئة جدًا) ، سيتم تخفيض النتيجة وفقًا لقواعد التسجيل",
              speechSpeedTooFastGreaterThanEveryMinute:
                "سرعة التحدث بسرعة كبيرة: أكبر من كل دقيقة",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "كلمة ، سرعة الكلام بطيئة للغاية: أقل من كل دقيقة",
              charactersIgnoringLessThan: "كلمة ، تجاهل أقل من",
              aSentenceOfWords: "كلمات الجمل",
              excessiveModalParticles: "الكثير من الكلمات",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "يدعم الكلمات العامية المخصصة ، ويضع قواعد الكثير من الكلمات. عندما يكون هناك الكثير من الكلمات ، يتم تخفيض النقاط وفقًا لقواعد التسجيل",
              theNumberOfRepetitionsIs: "عدد التكرار هو",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "في المرة الثانية ، يتم الحكم على أن هناك الكثير من الكلمات",
              definitionOfSensitiveViolations: "تعريف الانتهاكات الحساسة",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "دعم المرادفات المخصصة للكلمات المخالفة ، واكتشاف ما إذا كانت هناك كلمات غير قانونية في جملة واحدة. عند حدوث انتهاك ، يتم تخفيض النقاط وفقًا لقواعد التسجيل",
              violationWords: "كلمة الانتهاك:",
              emotionalDetection: "الكشف عن المشاعر",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "يتم إجراء الاختبار العاطفي وفقًا لتقنية حوار نموذج المزاج المنهجي ، وسيتم الحكم على النتيجة على أنها طبيعية عاطفياً وسلبية (بما في ذلك السلبية والغاضبة والشكوى). عندما يكون هناك عاطفة سلبية ، يتم تخفيض النقاط وفقًا لقواعد التسجيل",
              preserve: "حفظ",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "أبعاد التهديف",
              ratingPoints: "نقاط التهديف",
              explain: "وصف",
              type: "نوع",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "بيانات عشوائية" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· تراكم الكلام القياسي',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "في كلمة واحدة ، اتبع الطلاب عدد الكلمات التي قرأتها",
                  aWordspan: "كلمة </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'مرات </span><br /><span style = "margin-left: 8px">· سرعة التحدث بطيئة للغاية',
                  timesspan: "مرة </span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· الكثير من الكلمات',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "النتيجة هي" },
                  item: {
                    violationOnceReduced: "تم تخفيض الانتهاك مرة واحدة",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "سرعة الكلام غير الطبيعية (بما في ذلك سرعة الكلام السريعة وسرعة الكلام البطيئة) مرة واحدة",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "يتم تقليل الكلمات المفرطة مرة واحدة",
                    oneNegativeDecreaseInEmotions:
                      "انخفاض سلبي عاطفي مرة واحدة",
                    branch: "نقاط",
                  },
                  wrap: { name: "اسم", preserve: "حفظ" },
                },
              },
              index: {
                complete: "المحتوى الكامل",
                keyHit: "ضرب النقاط الرئيسية",
                accurateFollowupReading: "تابع القراءة بدقة",
                languageNorm: "معايير اللغة",
                speechSpeed: "سرعة الكلام",
                modalParticles: "كلمات",
                emotionallyPositive: "إيجابية عاطفيا",
                scoringModel: "نموذج التهديف",
                successfullyModified: "مراجعة ناجحة",
                newSuccessfullyCreated: "نجاح البناء الجديد",
              },
            },
            index: {
              scoringModel: "نموذج التهديف",
              newModel: "نموذج جديد",
              model: "نموذج",
              type: "نوع",
              operation: "العملية",
              preview: "معاينة",
              edit: "تحرير",
            },
          },
          index: {
            scoringModel: "نموذج التهديف",
            ratingDimension: "أبعاد التهديف",
            sessionDetectionSettings: "إعدادات الكشف عن الجلسة",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "يرجى اختيار",
                hit: "ضرب",
                misses: "لم يضرب",
              },
            },
          },
          index: {
            duration: "طول الوقت",
            processScore: "نتيجة العملية",
            branch: "نقاط",
            contentCompleteness: "اكتمال المحتوى",
            scriptScore: "درجة الكلام",
            keyHitRate: "النقاط الرئيسية معدل الإصابة",
            emotionalScore: "النتيجة العاطفية",
            negativeProportionOfEmotions: "نسبة سلبية عاطفيا",
            expressionScore: "درجة التعبير",
            theProportionOfAbnormalSpeechSpeed:
              "نسبة غير طبيعية في سرعة الكلام",
            excessiveProportionOfModalParticles: "نسبة مفرطة من الكلمات",
            learningRecordData: "تعلم تسجيل البيانات",
            staff: "الموظفون",
            curriculum: "دورات تدريبية",
            mainPoints: "النقاط الرئيسية",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "النموذج",
                        lineChart: "الرسم البياني الخطي",
                        barChart: "الرسم البياني",
                        pieChart: "مخطط كعكة",
                      },
                      index: { switchingTypes: "نوع التبديل" },
                    },
                  },
                  index: {
                    saveSuccessful: "حفظ النجاح",
                    editChart: "تحرير الرسوم البيانية",
                    chartName: "اسم الرسم البياني",
                    deleteSuccessful: "حذف النجاح",
                    refresh: "تحديث",
                    export: "تصدير",
                    areYouSureToDeleteIt: "هل أنت متأكد من الحذف ؟",
                    delete: "حذف",
                    viewFilteringCriteria: "عرض شروط الفرز",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "تصدير ناجح ، يرجى الذهاب إلى مركز المهام للتحقق",
              },
            },
            header: {
              index: {
                saveSuccessful: "حفظ النجاح",
                deleteSuccessful: "حذف النجاح",
                editReport: "تحرير التقارير",
                reportName: "اسم التقرير",
                refresh: "تحديث",
                areYouSureToDeleteIt: "هل أنت متأكد من الحذف ؟",
                delete: "حذف",
                exportToExcel: "تصدير Excel",
                exportSnapshot: "تصدير اللقطات",
                export: "تصدير",
                authority: "أذونات",
                permissionSettings: "إعدادات الأذونات",
                reportVisiblePermissions: "تقرير الأذونات المرئية",
                visibleToEveryone: "الجميع مرئي",
                onlyVisibleToOneself: "مرئية فقط من تلقاء نفسها",
                designatedPersonnelVisible: "يمكن رؤية الموظفين المعينين",
                pleaseSelect: "يرجى اختيار",
                reportDataPermissions: "أذونات بيانات التقرير",
                restrictedByUserPermissions: "يقتصر على أذونات المستخدم",
                visiblePeopleAreTheSame: "يمكن أن نرى نفس الناس",
              },
            },
            titleFooter: {
              index: { addReport: "تقرير جديد", reportName: "اسم التقرير" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "تصدير ناجح ، يرجى الذهاب إلى مركز المهام للتحقق",
            dataQueryInProgress: "استعلام البيانات في...",
            customKanban: "لوحة مخصصة",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "النموذج",
                lineChart: "الرسم البياني الخطي",
                barChart: "الرسم البياني",
                pieChart: "مخطط كعكة",
              },
              index: {
                successfullyAdded: "إضافة النجاح",
                viewSQL: "عرض sql",
                export: "تصدير",
                addToReport: "إضافة إلى التقرير",
              },
            },
            refer: {
              hooks: {
                data: "البيانات",
                interrogativeSentence: "سؤال",
                historicalQuery: "استعلام تاريخي",
              },
              index: { copySuccessful: "نجاح النسخ", copy: "نسخ" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "فشل في تلبية المعايير",
                        meetingStandards: "تلبية المعايير",
                        excellent: "ممتاز",
                        notStartedYet: "لم تبدأ",
                        inProgress: "قيد العمل",
                        completed: "أنجز",
                        incomplete: "لم يكتمل",
                      },
                    },
                  },
                  const: {
                    taskName: "اسم المهمة",
                    department: "الإدارات",
                    studentName: "أسماء المتدربين",
                    scoringResults: "نتيجة التهديف",
                    taskCompletionStatus: "حالة إنجاز المهمة",
                  },
                  index: { pleaseSelectADimension: "يرجى اختيار البعد" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "أدخل @ يمكن فتح الإطار لتحديد الأبعاد",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "يرجى إدخال بيان الاستفسار للاستعلام مباشرة",
                query: "الاستفسارات",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "تصدير ناجح ، يرجى الذهاب إلى مركز المهام للتحقق",
            dataQueryInProgress: "استعلام البيانات في...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "البحث عن الكلمات الرئيسية",
                previous: "السابق",
                next: "التالي",
                theObjectThatTriggersTheScrollingEventIs:
                  "الكائن الذي يؤدي إلى أحداث التمرير هو",
                userScrolling: "المتداول المستخدم",
              },
            },
            summary: {
              index: {
                reminder: "تذكير:",
                edit: "تحرير",
                name: "اسم",
                hintLanguage: "تذكير",
              },
            },
            viewpoint: { index: { viewpointExtraction: "استخراج الرأي" } },
            outline: {
              index: {
                numberOfKeywords: "عدد الكلمات الرئيسية",
                speaker: "المتحدث",
                speechDuration: "طول الكلام",
                proportionOfSpeaking: "نسبة الكلام",
                maximumSpeechDuration: "الحد الأقصى للبيان",
                numberOfQuestions: "عدد الأسئلة",
                averageSpeechSpeed: "متوسط سرعة الكلام",
                conversationalActions: "عمل الجلسة",
                contentSummary: "ملخص المحتوى",
                generateSummary: "توليد الملخصات",
                keywordCloud: "الكلمات الرئيسية كلمة سحابة",
              },
            },
          },
          index: {
            outline: "موجز",
            viewpoint: "وجهة نظر",
            contentSummary: "ملخص المحتوى",
            viewpointExtraction: "استخراج الرأي",
            summaryOfKeyPoints: "ملخص النقاط الرئيسية",
            videoAnalysis: "تحليل الفيديو",
          },
        },
        list: {
          index: {
            delete: "حذف",
            uploadSuccessful: "نجاح التحميل",
            videoList: "قائمة الفيديو",
            uploadVideo: "تحميل الفيديو",
            videoName: "اسم الفيديو",
            uploadTime: "وقت التحميل",
            endTime: "نهاية الوقت",
            analyzeProgress: "تحليل التقدم",
            haveInHand: "قيد العمل",
            completed: "أنجز",
            fail: "فشل",
            toBegin: "إبدأ",
            operation: "العملية",
            see: "عرض",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "إضافة وقت العمل" },
        },
      },
      index: {
        businessWorkingHours: "ساعات العمل",
        newlyBuild: "جديد",
        name: "اسم",
        describe: "الوصف",
        operation: "العملية",
        edit: "تحرير",
        delete: "حذف",
        editBusinessWorkingHours: "تحرير ساعات العمل",
        newBusinessWorkingHours: "ساعات العمل في الأعمال الجديدة",
        workingHours: "ساعات العمل",
      },
    },
    algorithmSemanticIntelligence: { intelligentExtraction: "استخراج ذكي" },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "هل أكدت أنك تريد الإلغاء ؟ لن يتم استعادتها بعد الإلغاء",
        },
      },
    },
    accountManagement: {
      securitySetting: "إعدادات الأمان",
      accountManagement: "إدارة الحسابات",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "إلى",
            switchToIPAddressMode: "التبديل إلى وضع عنوان IP",
            switchToIPSegmentMode: "التبديل إلى نمط قطاع IP",
          },
        },
      },
      index: {
        saveSuccessful: "حفظ النجاح",
        pleaseImproveTheConfiguration: "يرجى تحسين التكوين",
        securitySetting: "إعدادات الأمان",
        employeeLoginIPRestrictions: "قيود تسجيل الدخول للموظفين IP",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "تقييد نطاق IP الذي يمكن للموظفين تسجيل الدخول إليه ، ولا يُسمح بتسجيل الدخول خارج النطاق. يسري فقط على مواقع الويب ، ولا يتم تقييد تطبيقات الجوال ، ولا يتم تقييد المسؤولين.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "عند إضافة أو تعديل ، يرجى ملء قسم IP للشبكة الخارجية (نفس الفقرة C) أو عنوان IP الثابت للشبكة الخارجية.",
        allowLoginIPRange: "نطاق IP الذي يسمح بهبوط",
        preserve: "حفظ",
      },
    },
    coachAdminEntry: { speechRecognition: "التعرف على الكلام" },
    billingCenter: {
      template: {
        details: "التفاصيل",
        languageType: "نوع اللغة",
        aSREngine: "محرك ASR",
        voiceQualityQuota: "حصص فحص الجودة الصوتية",
        realtimeData: "البيانات في الوقت الحقيقي",
        calculatedByDay: "حسب اليوم",
      },
      component: {
        aSRName: "اسم ASR",
        language: "اللغات",
        remainingQuota: "الحصص المتبقية",
        hours: "ساعات",
        usedQuota: "الحصص المستخدمة",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "عملية حفظ ناجحة!",
        deletedSuccessfully: "حذف النجاح!",
        pleaseSelectLanguage: "يرجى اختيار اللغة",
        pleaseConfigureRules: "يرجى تكوين القواعد",
        updateSuccessful: "نجاح التحديث!",
        operationSuccessful: "عملية ناجحة!",
      },
      template: {
        aSRLanguage: "لغة ASR",
        pleaseSelect: "يرجى اختيار",
        model: "نموذج",
        pleaseSelectLanguage: "يرجى اختيار اللغة",
        delete: "حذف",
        newASRModel: "نموذج ASR جديد",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "تحليل المنتجات التنافسية" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "مراجعة التجارة الإلكترونية",
        allComments: "جميع التعليقات",
        ecommerceReviewManagement: "إدارة تعليقات التجارة الإلكترونية",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "مراجعة وسائل الإعلام الاجتماعية",
        allComments: "جميع التعليقات",
        socialMediaReviewManagement: "إدارة تعليقات وسائل الإعلام الاجتماعية",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "تحليل المنتجات التنافسية",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "الصورة الرمزية",
        realname: "الاسم",
        nickname: "اللقب",
        name: "الاسم",
        email: "الحساب (البريد الإلكتروني)",
        mobile: "الهاتف",
        agentId: "رقم العمل",
        position: "وظيفة",
        role: "الدور",
        actions: "العملية",
        createUser: "موظفين جدد",
        editUser: "موظفو التحرير",
        account: "رقم الحساب",
        originPassword: "كلمة المرور الأولية",
        newPassword: "كلمة المرور الجديدة",
        resumePassword: "تأكيد كلمة المرور",
        userGroup: "مجموعة الموظفين",
        authorizationGroup: "مجموعة الأذونات",
        resetPassword: "إعادة تعيين كلمة المرور",
      },
      userGroups: {
        id: "ID",
        name: "اسم",
        userCount: "عدد الموظفين",
        actions: "العملية",
        createUserGroup: "مجموعة جديدة من الموظفين",
        editUserGroup: "مجموعة موظفي التحرير",
        member: "الأعضاء",
      },
      role: {
        id: "ID",
        name: "اسم",
        actions: "العملية",
        all: "كل شيء",
        taskName: "المهام",
        readTask: "عرض المهمة",
        manageTemplate: "إدارة القوالب",
        manualInspection: "إعادة الفحص اليدوي",
        readResultAll: "عرض النتائج",
        readResultPersonal: "عرض النتائج (فردي)",
        readProcess: "التحقق من عملية فحص الجودة",
        readReport: "الإحصائيات والبيانات",
      },
    },
    download: {
      downloadCenter: {
        createTime: "التاريخ",
        taskName: "اسم",
        size: "الحجم",
        status: "الحالة",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "اسم",
            remark: "الوصف",
            actions: "العملية",
          },
          checkPoint: {
            name: "اسم",
            remark: "الوصف",
            type: "نوع",
            gradeType: "طرائق التقييم",
            conditionLogic: "القواعد",
            predeterminedScore: "نقاط",
            weight: "الأوزان",
            status: "الحالة",
            actions: "العملية",
            switchOn: "تمكين",
            switchOff: "تعطيل",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "الوقت",
            customer: "العملاء",
            customerPhone: "هاتف العميل",
            callType: "نوع المكالمة",
            source: "المصدر",
            voiceRecording: "تسجيل المكالمات",
            graded: "التقييم",
            openingGreeting: "تحية الافتتاح",
          },
          checkPointList: {
            name: "نقطة فحص الجودة",
            type: "نوع",
            deductScore: "النتيجة",
            isHit: "ضرب الوضع",
            status: "مراجعة",
            finalDeductScore: "تأكيد الخصم",
            hit: "ضرب",
            notHit: "لم يضرب",
          },
        },
      },
    },
    custom: { keywords: "الكلمات الرئيسية للحوار" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "إعدادات النظام" },
      systemIntegration: {
        text: "تكامل النظام",
        subTitle: {
          udesk: "Udesk نظام خدمة العملاء",
          udeskWhite: "نظام خدمة العملاء",
          wechat: "الشركات WeChat",
          taobao: "تاوباو ألف نيو",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "إدارة حقول فحص الجودة" },
      customerField: { text: "حقل العميل" },
      speechRecognition: { text: "التعرف على الكلام" },
      user: { text: "إدارة الموظفين" },
      userGroups: { text: "إدارة مجموعة الموظفين" },
      role: { text: "إدارة الأدوار" },
      dataMigration: { text: "ترحيل البيانات" },
      taskCenter: { text: "مركز البعثة" },
      recordUpload: { text: "تحميل التسجيل المحلي" },
      anomalousData: { text: "بيانات غير طبيعية" },
      systemLog: { text: "سجل الرسائل" },
      msgCenter: { text: "مركز الرسائل" },
      wechatDocking: { text: "الالتحام الدقيق" },
    },
    tasks: {
      homePage: { text: "الصفحة الرئيسية" },
      workbench: { text: "طاولة العمل" },
      semanticIntelligence: { text: "الذكاء الدلالي" },
      intelligentAnalysis: { text: "التحليل الذكي" },
      intelligentLabel: { text: "العلامات الذكية" },
      admin: { text: "الإدارة" },
      callManage: { text: "إدارة المكالمات" },
      dialogueManage: { text: "إدارة الحوار" },
      ticketManage: { text: "إدارة أوامر العمل" },
      dataAnalysis: { text: "تحليل البيانات" },
      customerService: { text: "مركز خدمة العملاء" },
      clientCenter: { text: "مركز العملاء" },
      insight: { text: "Insight" },
      trainingCenter: { text: "مركز التدريب" },
      templates: { text: "إدارة القوالب" },
      wechatQaTasks: { text: "منصة عمل الشركة WeChat" },
      wechatQaSetting: { text: "تكوين تحليل WeChat للمؤسسة" },
      wechatQaChartRecord: { text: "محادثة الشركة WeChat" },
      wechatStaffCenter: { text: "مركز الموظفين الصغير" },
      callAnalysis: { text: "تحليل الجلسة" },
      caseBaseFolder: { text: "مكتبة الحالات" },
      coachTask: { text: "مهام التدريب" },
      teamAnalysis: { text: "تحليل الفريق" },
      customer: { text: "مركز العملاء" },
      analysisEnable: { text: "تحليل التمكين" },
      analysisHelp: { text: "طلب المساعدة في التحليل" },
      analysisStudy: { text: "تحليل التعلم" },
      analysisReview: { text: "تحليل إعادة النظر" },
      analysisTags: { text: "تحليل العلامات الذكية" },
      analysisSentiment: { text: "تحليل الرأي العام" },
      analysisBusiness: { text: "تحليل الأعمال" },
      groupTest: { text: "اختبار المجموعة" },
    },
    navBar: {
      billingCenter: "مركز المساعدة",
      uploadDownload: "تحميل مركز التحميل",
      setting: "إعدادات النظام",
    },
  },
  fix: {
    selectType: "اختيار نوع:",
    all: "كل شيء",
    call: "مكالمة هاتفية",
    text: "الحوار",
    wechat: "محادثة الشركة WeChat",
    businessTrendAnalysis: "تحليل اتجاهات الأعمال في التصنيف من المستوى الأول",
    noEchart: "لا توجد بيانات على الرسم البياني",
    checkPointisRequired: "تكوين القاعدة مطلوب",
    voiceDownLoad: "تحميل التسجيلات",
    exportTxt: "تصدير نص TXT",
    exportWord: "تصدير نص كلمة",
    sureReRole: "تأكيد دور المتحدث العكسي ؟",
    sureRecover: "هل تؤكد إعادة تصحيح الخطأ ؟",
    sureReAsr: "تأكيد إعادة تحديد الهوية ؟",
    reRole: "عكس المتحدث",
    recover: "إعادة تصحيح الأخطاء",
    reAsr: "إعادة تحديد الهوية",
    recordingOperation: "عملية التسجيل الصوتي",
    onlySave30Day: "(لمدة 30 يومًا فقط)",
    appealError: "يرجى تقديم شكوى إلى نقطة التفتيش قبل التقديم",
    exportToTaskCenter: "تم تصديرها بنجاح إلى مركز التحميل والتنزيل",
    caseBaseLimit: "لا يمكن أن يتجاوز بنك الحالات 50",
    unnamed: "غير مسمى",
    wordSpeed: "كلمة/دقيقة",
    hit: "ضرب",
    checkPoint: "نقطة فحص الجودة",
    realTimeAdditionTip1:
      "إضافة في الوقت الفعلي: يبدأ فحص الجودة على الفور عندما تدخل البيانات نظام فحص الجودة",
    realTimeAdditionTip2:
      "إضافة منتظمة: إضافة البيانات تلقائيًا بانتظام لفحص الجودة",
    null: "لا شيء",
    collcetedProps: "الحصول على الحقل",
    editingNameNow: "هناك اسم قيد التحرير",
    upToFourLevels: "تصنيف ما يصل إلى أربعة مستويات",
    default: "الافتراضي",
    nameCantBeNull: "لا يمكن أن يكون الاسم فارغًا",
    editingNow: "هناك عملية قيد التحرير",
    nameError: "اسم خاطئ",
    nameMaxLength8: "الحد الأقصى لطول الاسم هو 8",
    callTime: "وقت التحدث",
    sentimentValue: "قيمة مزاج العملاء",
    agentSentimentValue: "قيمة مزاج خدمة العملاء",
    sentimentAnalysis: "تحليل المشاعر",
    associatedData: "البيانات مع الطريق",
    notifyTheOwner: "إخطار أصحاب المجموعة",
    notifyTheEmployee: "إخطار الموظفين أنفسهم",
    notifyTheOwnerTip:
      "سيتم إخطار مالك المجموعة عندما يكون نوع المحادثة عبارة عن دردشة جماعية",
    customNotifyEmployee: "إشعار مخصص للموظفين",
    customNotifyEmployeeTip:
      "إذا كان المشغل موظفًا ، فسيتم إخطار الموظف الذي تنتمي إليه الرسالة",
    clickToLearnMore: "انقر لمعرفة المزيد",
    repeatMarkingTip:
      "تم وضع علامة على السجل بواسطة نقطة فحص الجودة ، ولا يلزم وضع علامة عليه بشكل متكرر",
    successfulEvaluation: "تقييم النجاح",
    evaluationFailed: "فشل التقييم",
    qualityInspectionScore: "درجة فحص الجودة",
    semanticTags: "العلامات الدلالية",
    smartTags: "العلامات الذكية",
    collect: "جمع المعلومات",
    appealRecord: "سجل الشكاوى",
    satisfy: "راض",
    notSatisfy: "غير راض",
    appealPlaceHolder: "يرجى إدخال سبب الشكوى",
    giveUp: "استسلم",
    rejectReasonPlaceHolder: "الرجاء إدخال أسباب الرفض",
    reject: "رفض",
    changeRejectReason: "تعديل أسباب الرفض",
    pass: "بواسطة",
    revise: "تعديل",
    machineEvaluation: "تقييم الآلة",
    poor: "الفرق",
    excellent: "حسناً ، حسناً",
    moderate: "بشكل عام",
    mustMarked: "يجب وضع علامة على نقطة فحص الجودة",
    pleaseChoose: "يرجى اختيار",
    manualEvaluation: "التقييم اليدوي",
    qualityInspectionTemplate: "تطبيق قوالب فحص الجودة",
    collectionTemplate: "تطبيق قالب الجمع",
    keywordsWordCloud: "الكلمات الرئيسية كلمة سحابة",
    editCategoryLabel: "تحرير علامات التصنيف",
    justCall: "انظر فقط إلى المكالمة",
    justConversation: "انظر فقط إلى الحوار",
    batchOperation: "عمليات الدُفعات",
    customerServicesGroup: "مجموعة خدمة العملاء",
    joinGroup: "الانضمام إلى المجموعة",
    removeGroup: "الخروج من المجموعة",
    enterInformationEntity: "يرجى إدخال محتوى كيان المعلومات",
    enterNotBeNull: "المدخلات لا يمكن أن تكون فارغة",
    operationGroup: "مجموعة العمليات",
    customerServiceSelected: "خدمة العملاء المختارة",
    callAndConversation: "المكالمات/المحادثات",
    conversation: "الحوار",
    system: "النظام",
    customize: "التخصيص",
    effective: "فعالة",
    invalid: "غير صالح",
    successfulAndToTaskCenter: "التنفيذ الناجح ، يرجى التحقق من مركز المهام",
    recalculationFailed: "فشل إعادة الحساب",
    selectRecalculatedIndicator: "يرجى اختيار المؤشرات التي تريد إعادة حسابها",
    source: "يرجى اختيار مصدر البيانات",
    placeholder: "الرجاء إدخال الكلمات الرئيسية",
    cumulative: "النوع التراكمي",
    average: "نوع المتوسط",
    distribution: "قانون التوزيع",
    originalDataCannotEmpty: "لا يمكن أن تكون البيانات الأولية فارغة",
    inspection: "فحص الجودة",
    check: "أخذ العينات",
    review: "مراجعة",
    draftSavedSuccessfully: "حفظ المسودة بنجاح",
    optimization: "التحسين",
    agentName: "اسم خدمة العملاء",
    allTasks: "جميع مهام فحص الجودة",
    datePickerSelect: "اختيار التاريخ",
    callSelect: "اختيار الحوار",
    taskSelect: "اختيار المهام",
    genReport: "إعداد التقارير",
    dataSource: "مصادر البيانات",
    callsTotal: "إجمالي عدد المكالمات",
    intelligentQualityInspection: "فحص الجودة الذكية",
    percentage: "نسبة",
    artificialSampling: "أخذ العينات اليدوية",
    appealNumber: "عدد الشكاوى",
    totalConversation: "مجموع الحوارات",
    samplingReviewRate: "معدل إعادة الفحص",
    data: "البيانات",
    inspectionResults: "نتائج فحص الجودة",
    inspected: "فحص الجودة",
    totalScore: "مجموع النقاط",
    learnMore: "عرض المزيد",
    inspectionEffect: "تأثير فحص الجودة",
    allTotal: "المجموع الكلي",
    manualInspection: "إعادة الفحص اليدوي",
    artificialReview: "مراجعة اصطناعية",
    approvalAmountAndRate: "الكميات المعتمدة والمعدلات المعتمدة",
    customerScore: "تسجيل خدمة العملاء",
    inspectionRecommendations: "توصيات فحص الجودة",
    saveDraft: "حفظ المسودات",
    historicalAdvice: "التوصيات التاريخية",
    advicePlaceHolder: "الرجاء إدخال اقتراحات...",
    upload: "تحميل الملفات",
    customAdd: "إضافة فحص",
    advancedConditions: "شروط متقدمة",
    keyWord: "الكلمات الرئيسية",
    containsAll: "يحتوي على كل شيء",
    containsAny: "يحتوي على تعسفي",
    matchAny: "تطابق أي شيء",
    matchAll: "تطابق كل شيء",
    smartTagsOne: "العلامة الذكية واحد",
    smartTagsTwo: "العلامة الذكية الثانية",
    season: "الموسم",
    month: "الشهر",
    week: "الأسبوع",
    day: "يا إلهي",
    hour: "الوقت",
    notMoreThan366: "لا يمكن أن يكون أكثر من 366 يوما",
    propsCannotBeNull: "هناك حقول لم يتم ملؤها",
    other: "أخرى",
    correlationCoefficient: "معامل الارتباط",
    repetitionRate: "معدل التكرار",
    configurationItemsMoreThan20: "إضافة ما يصل إلى 20 عنصر التكوين",
    conversionAnalysis: "تحليل التحويل",
    correlationAnalysis: "تحليل الارتباط",
    successfulExportAndToTaskCenter:
      "تصدير ناجح ، يرجى الذهاب إلى مركز المهام للتحقق",
    typeSelect: "اختيار نوع",
    exportExcel: "تصدير excel",
    exportPng: "تصدير png",
    noDataChart: "لا توجد بيانات على الرسم البياني",
    placeOperator: "يرجى اختيار علامة التبويب الذكية",
    placeAdvancedConditions: "يرجى اختيار الشروط المتقدمة",
    createAdvancedConditions: "فحص متقدم جديد",
    moveUp: "الانتقال العلوي",
    moveDown: "تحركت لأسفل",
    moveLeft: "إلى اليسار",
    moveRight: "إلى اليمين",
    statusChangeSuccess: "تعديل الحالة بنجاح",
    editSuccess: "مراجعة ناجحة",
    saveSuccess: "حفظ النجاح",
    firstPage: "الصفحة الرئيسية",
    lastPage: "نهاية الصفحة",
    customerInfo: "معلومات العملاء",
    type: "نوع",
    NumberTypeErrorMessage:
      'يمكن أن يحتوي محتوى معلومات الحقل من النوع الرقمي فقط على أرقام أو "."',
    taskStartPrompt: "نصائح لفتح المهمة",
    startConfirm: "يرجى النقر على زر التأكيد لبدء مهمة فحص الجودة",
    continueToSubmitWithoutEvaluation: "استمرار تقديم التقييم",
    rejectTip:
      "لم يتم الكشف عن أي عملية تغيير ، هل تؤكد رفض جميع الشكاوى مباشرة ؟",
    unmarkedSelfLearningTip: "لديك نقاط فحص جودة التعلم الذاتي غير المميزة",
    markedSuccessfully: "تحديد النجاح",
    markingFailed: "تحديد الفشل",
    exceedingLength: "تجاوز الطول",
    uncategorized: "غير مصنف",
    addSuccess: "نجاح جديد",
    deleteSuccess: "حذف النجاح",
    featureName: "اسم العنصر",
    informationEntity: "كيانات المعلومات",
    standardAnswer: "إجابة قياسية",
    keyElementAnswer: "العناصر الرئيسية للإجابة",
    generalStatement: "بيان عام (لا حاجة للاستفسار)",
    generalQuestionsAndConfirmation: "الشكوك العامة والتأكيد",
    specialQuestionsAndAnswers: "أسئلة خاصة وإجابات",
    agent: "خدمة العملاء",
    customer: "العملاء",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "العناصر الرئيسية لا يمكن أن تكون العلامات الدلالية وكيانات المعلومات فارغة في نفس الوقت",
    standardSentence: "الجملة القياسية",
    acquaintance: "درجة التعارف",
    smartUePlaceholder: "الرجاء إدخال اسم التفاعل الذكي",
    typeSelectPlaceholder: "يرجى اختيار نوع",
    keyElement: "العناصر الرئيسية",
    reQuerySementicTags: "إعادة اختيار العلامات الدلالية",
    querySementicTags: "اختيار العلامات الدلالية",
    QATestForm: "شكل اختبار الأسئلة والأجوبة",
    selectNotBeNull: "لا يمكن أن يكون الاختيار فارغا",
    featureDetectionTarget: "هدف الكشف عن العناصر",
    categorized: "تصنيف",
    effectiveness: "فعالية",
    hideAdvancedSettings: "إخفاء الإعدادات المتقدمة",
    showAdvancedSettings: "عرض الإعدادات المتقدمة",
    generalStatementOnly: "بيان عام",
    generalQuestions: "سؤال عام",
    affirmativeExpression: "التعبير الإيجابي",
    negativeIntention: "النية السلبية",
    searchSementicTags: "الاستعلام عن العلامات الدلالية",
    choiceQuestion: "أسئلة الاختيار",
    selectiveAnswer: "إجابة الاختيار",
    sementicTagsPlaceholder: "يرجى إدخال محتوى العلامة الدلالية",
    openSpecialQuestion: "أسئلة خاصة مفتوحة",
    openAnswer: "إجابة مفتوحة",
    customQA: "سؤال وجواب مخصص",
    questions: "سؤال",
    dataPlaceholder: "الرجاء إدخال المحتوى",
    reply: "أجب",
    notFindSuitableLabel: "لم يتم العثور على التسمية المناسبة",
    addSemanticTag: "إضافة علامة دلالية جديدة",
    intelligentInteraction: "التفاعل الذكي",
    matching: "تطابق",
    bout: "مرة واحدة",
    keywordMatch: "مطابقة الكلمات الرئيسية",
    addFilterConditions: "إضافة شروط الفرز",
    buttonStartCollectionTask: 'يرجى النقر على زر "موافق" لفتح مهمة الجمع',
    buttonStartTask: "يرجى النقر على زر موافق لبدء مهمة فحص الجودة",
    ruleConfigurationIsRequired: "تكوين القاعدة مطلوب",
    asrRunning: "تنفيذ ASR",
    errorCorrectionExecution: "تنفيذ تصحيح الخطأ",
    emotionalVolumeRecognition: "التعرف على حجم المشاعر",
    dataPreprocessing: "المعالجة المسبقة للبيانات",
    inspectionExecution: "تنفيذ فحص الجودة",
    businessId: "الأعمال Id",
    businessTime: "وقت العمل",
    createTime: "إنشاء الوقت",
    abnormalReason: "أسباب غير طبيعية",
    singleChat: "محادثة واحدة",
    groupChat: "دردشة جماعية",
    taskContent: "محتوى المهمة",
    submissionTime: "وقت التقديم",
    startTime: "وقت البدء",
    endTime: "نهاية الوقت",
    runningTime: "تستغرق العملية وقتًا طويلاً",
    totalAmountOfData: "إجمالي حجم البيانات",
    resultsOfThe: "نتائج التنفيذ",
    operator: "المشغل",
    taskDetails: "تفاصيل المهمة",
    executiveFunction: "وظيفة تنفيذية",
    qualityCheckTasks: "مهام فحص الجودة",
    implementationDetails: "تفاصيل التنفيذ",
    conditionalRules: "قواعد الشروط",
    smartCondition: "الظروف الذكية",
    goThis: "الانتقال إلى هذا",
    pleaseMarkClassification: "يرجى وضع علامة على التصنيف",
    advancedScreening: "الفحص المتقدم",
    noDataToManipulate: "لا توجد بيانات يمكن تشغيلها",
    concept: "الكلمات المفاهيمية",
    checkPoint1: "تكوين القاعدة",
    requiredFieldsCannotEmpty: "لا يمكن أن تكون العناصر المطلوبة فارغة",
    rule: "القواعد",
    afterFixUnit: "كلمة واحدة",
    unmarkedSelfLearningPointsTip:
      "لديك نقاط فحص جودة التعلم الذاتي غير المميزة",
    onlyReservedFor7Days: "(لمدة 7 أيام فقط)",
    downloadError: "فشل التنزيل",
    pause: "تعليق مؤقت",
    continue: "استمر",
    recalcScore: "إعادة حساب الدرجات فقط (بما في ذلك التصنيفات)",
    recalculatePointsAndScores: "إعادة حساب نقاط ودرجات فحص الجودة",
    recalculatePointsAndScoresWithEmtry:
      "إعادة حساب نقاط ودرجات فحص الجودة (نقاط فحص الجودة فارغة)",
    inProgress: "قيد العمل",
    toBeExecuted: "ليتم تنفيذها",
    executeImmediately: "التنفيذ الفوري",
    specialElements: "عناصر خاصة",
    logicRule: "قواعد المنطق",
    every: "مطابقة متعددة",
    hitRecall: "ضرب كلمة استدعاء",
    notHitRecall: "لم يتم ضرب كلمة الاستدعاء",
    hitRecallRegular: "ضرب الاسترجاع",
    notHitRecallRegular: "عدم ضرب",
    hitRule: "ضرب القواعد",
    notHitRule: "عدم ضرب القواعد",
    hitSimilarSentence: "ضرب الجمل المماثلة",
    notHitSimilarSentence: "لم يتم ضرب الجمل المماثلة",
    hitExcludeSimilarSentences: "ضرب استبعاد الجمل المماثلة",
    notHitExcludeSimilarSentences: "استبعاد جمل مماثلة دون إصابة",
    updateSuccess: "نجاح التحديث",
    qualify: "تقويم",
    recallKeyWord: "استدعاء الكلمات الرئيسية",
    grammar: "القواعد النحوبية",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "فشل تغيير الفعالية ، يرجى المحاولة مرة أخرى",
    postAutoMateTasksCaseByTaskId:
      "فشل التكوين المتزامن لمكتبة الحالة في الحفظ",
    getAutoMateTasksCaseByTaskId: "فشل التكوين المتزامن لمكتبة الحالة",
    postInspectionRemarkModelConfigs: "فشل حفظ قالب الملاحظات",
    putInspectionRemarkModelConfigsById: "فشل حفظ قالب الملاحظات",
    getInspectionRemarkModelConfigsById: "فشل الحصول على بيانات قالب الملاحظات",
    postKnowledgeDatas: "فشلت قاعدة المعرفة الجديدة ، يرجى المحاولة مرة أخرى",
    putKnowledgeDatasById: "فشل تعديل قاعدة المعرفة ، يرجى المحاولة مرة أخرى",
    getLibraryFiles: "فشل الحصول على قائمة مقاطع سعر النص",
    getLibraryCalls: "فشل الحصول على الملفات",
    getLibraryFilesSearch: "فشل البحث",
    putLibraryFilesMoveById: "فشل تحريك مقطع السعر",
    putLibraryCallsById: "فشل إعادة تسمية الملف!",
    postLibraryFiles: "فشل إضافة مجلد",
    putLibraryFilesById: "فشل إعادة تسمية المجلد",
    deleteLibraryFilesById: "فشل الحذف",
    deleteLibraryCallsById: "فشل الحذف",
    postLibraryCalls: "فشل إضافة",
    getVoiceUploadResultById: "فشل تصدير نتائج التحميل",
  },
};
