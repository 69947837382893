import React from 'react';
import { Button, Modal, Icon, notification } from 'udesk-ui';
import Udesk from 'Udesk';
import './style.scss';
import Locales from 'UdeskLocales';

const { confirm } = Modal;

interface ExportButtonProps {
    onClick: () => void;
    hasFilterCondition: boolean;
    exportDataCount?: number;
    disabled?: boolean;
}

const exportSuccessNotification = (event?) => {
    let locales = Locales['current'];

    const goToDownloadCenter = () => {
        if (window.location.pathname.startsWith('/sales/')) {
            window.open('/sales/admin/task-center/download-list');
            return;
        }
        if (window.location.pathname.startsWith('/conversation-analysis/')) {
            window.open('/conversation-analysis/admin/task-center/download-list');
            return;
        }
        window.open('/site/admin/task-center/download-list');
    };

    notification.success({
        key: 'exportNotification',
        message: locales.labels.exportTaskCreated,
        description: (
            <div>
                {locales.labels.plzGoTo}
                <Button
                    prefixCls="udesk-qa-ui-btn"
                    type="link"
                    size="small"
                    onClick={event || goToDownloadCenter}
                >
                    {locales.pages.downloadCenter.name}
                </Button>
                {locales.labels.view}
            </div>
        ),
    });
};

type ExportButtonType = React.FC<ExportButtonProps> & {
    exportSuccessNotification: (event?: Function) => void;
};

const ExportButton: ExportButtonType = (props) => {
    let locales = Locales['current'];

    const isExportAll = () => typeof props.exportDataCount !== 'number';

    const showConfirm = () => {
        props.hasFilterCondition
            ? props.exportDataCount === 0
                ? Udesk.ui.notify.error(locales.labels.noCountAfterFilter)
                : confirm({
                      title: isExportAll()
                          ? locales.labels.exportConfirmText.exportAll
                          : Udesk.utils.string.format(
                                locales.labels.exportConfirmText.export,
                                props.exportDataCount || 0
                            ),
                      icon: <Icon type="ExclamationCircleOutlined" antdIcon={true} />,
                      maskClosable: true,
                      okText: locales.labels.export,
                      onOk() {
                          props.onClick();
                          exportSuccessNotification();
                      },
                      onCancel() {},
                  })
            : Udesk.ui.notify.error(locales.labels.pleaseSetFilter);
    };
    return (
        <Button disabled={props.disabled} onClick={showConfirm}>
            {locales.labels.export}
        </Button>
    );
};

ExportButton.exportSuccessNotification = exportSuccessNotification;

export default ExportButton;
