import Locales from 'UdeskLocales';

const ORIGINAL = "_original";
const DIMENSION_VALUE_SEPARATOR = "-";
const TOOTIP = "_tootip";

// 根据配置项和数据,生成echarts所需要的数据结构
function getChartData(values, indicators = []) {
    let dimensions = [];
    if (values && values.header && values.header.dimensions) {
        dimensions = values.header.dimensions;
    }

    if (indicators.length === 0 && values && values.header && values.header.indicators) {
        indicators = values.header.indicators;
    }

    let rows = [];
    if (values && values.rows) {
        rows = values.rows;
    }

    let items = [];
    let columns = [];
    if (indicators && indicators.length > 0) {
        // 有维度
        if (dimensions && dimensions.length > 0) {
            indicators.forEach(indicator => {
                columns.push({
                    field: indicator.fieldKey,
                    name: indicator.fieldTitle || ""
                });
            });

            // 有维度，有指标。维度作为x轴，指标作为series。
            rows.forEach(row => {
                let item = {};
                let dimensionNames = [];
                let originalDimensionNames = [];
                dimensions.forEach(dimension => {
                    let fieldKey = dimension.fieldKey;
                    let fieldKeyOriginal = fieldKey + ORIGINAL;
                    if (row[fieldKey]) {
                        dimensionNames.push(row[fieldKey]);
                    }
                    if (row[fieldKeyOriginal]) {
                        originalDimensionNames.push(row[fieldKeyOriginal]);
                    }
                });

                item.xLabel = dimensionNames.join(DIMENSION_VALUE_SEPARATOR);
                item.xLabelOriginal = originalDimensionNames.join(DIMENSION_VALUE_SEPARATOR);
                indicators.forEach(indicator => {
                    let fieldKey = indicator.fieldKey;
                    let fieldKeyOriginal = fieldKey + ORIGINAL;
                    item[fieldKey] = {
                        value: row[fieldKey],
                        originalValue: row[fieldKeyOriginal] || Number(row[fieldKey]) || 0
                    };
                });
                items.push(item);
            });
        } else {
            // 无维度，只有指标。
            // 创建一个虚拟的维度，以指标字段名为值，列名（即legend名）为固定字符串“多指标”（如果只有一个指标时，取指标字段名）。
            let row = {};
            if (rows && rows.length > 0) {
                row = rows[0];
            }

            let columnName = Locales.current.labels.multipleIndicatorName;
            if (indicators.length === 1) {
                columnName = indicators[0].fieldTitle;
            }

            let columnField = indicators[0].fieldKey;
            columns.push({
                field: columnField,
                name: columnName
            });

            indicators.forEach(indicator => {
                let fieldKey = indicator.fieldKey;
                let fieldKeyOriginal = fieldKey + ORIGINAL;
                let item = {};
                item.xLabel = indicator.fieldTitle;
                item.xLabelOriginal = indicator.fieldTitle;
                item[columnField] = {
                    value: row[fieldKey],
                    originalValue: row[fieldKeyOriginal] || Number(row[fieldKey]) || 0
                };
                items.push(item);
            });
        }
    } else {
        // keep the chart alive (give him some default values)
    }

    return {
        columns,
        items
    };
}

//趋势部分字段分组
function getFieldGroupLists(values) {
    let groups = [];
    let groupNames = null;
    if (values && values.header) {
        let indicators = values.header.indicators;
        if (indicators && indicators.length > 0) {
            groupNames = [];
            indicators.forEach(list => {
                let isChecked = (groupNames[0] === list.group || groupNames.length === 0) ? true : false;
                let matchIndex = groupNames.findIndex(name => name === list.group);
                if (matchIndex > -1) {
                    groups[matchIndex].groupLists.push(list);
                } else {
                    let groupData = {
                        groupName: list.group,
                        groupLists: [list],
                        values: isChecked ? [list.fieldKey] : [],
                        states: {
                            isChecked: isChecked
                        }
                    };
                    groupNames.push(list.group);
                    groups.push(groupData);
                }
            });
        }
    }
    groupNames = null;
    return groups;
}

//转化表格列数据
function getTableColumnsData(values, options, selectedFields) {
    if (values) {
        let allColumns = [];
        let stackedHeaderGroups = [];

        let defaultOptions = {
            orderField: undefined,
            orderTypeEnum: undefined,
            hasStackedHeaderGroups: false
        };
        Object.assign(defaultOptions, options);

        let {
            orderField,
            orderTypeEnum,
            hasStackedHeaderGroups
        } = defaultOptions;

        if (values.header && values.header.dimensions && values.header.dimensions.length > 0) {
            let dimensions = values.header.dimensions;
            dimensions.forEach(dimensionItem => {
                let visible = true;
                let sortDirection = null;
                if (selectedFields && selectedFields.length > 0) {
                    visible = selectedFields.some(fieldKey => dimensionItem.fieldKey === fieldKey);
                }
                if (orderTypeEnum && orderField && orderField === dimensionItem.fieldKey) {
                    sortDirection = orderTypeEnum;
                }

                let hasIsShowPropery = Object.prototype.hasOwnProperty.call(dimensionItem, "isShow") && ("isShow" in dimensionItem);
                //isShow 为后端进行隐藏字段
                if (hasIsShowPropery) {
                    visible = dimensionItem.isShow;
                }

                let dimensionColumns = {
                    name: dimensionItem.fieldKey,
                    caption: dimensionItem.fieldTitle,
                    visible,
                    sortable: false,
                    sortDirection,
                    get: function (item, column) {
                        let fieldKey = column.name;
                        let fieldKeyOriginal = `${fieldKey}_original`;

                        let itemValue = 0;
                        if (item != null) {
                            if (item[fieldKey] != null) {
                                itemValue = item[fieldKey];
                            } else if (item[fieldKeyOriginal] != null) {
                                itemValue = item[fieldKeyOriginal];
                            }
                        }
                        if (itemValue == null) {
                            itemValue = 0;
                        }
                        return itemValue;
                    }
                };
                if (hasStackedHeaderGroups) {
                    stackedHeaderGroups = getStackedHeaderGroups(stackedHeaderGroups, dimensionItem);
                }
                allColumns.push(dimensionColumns);
            });
        }

        if (values.header && values.header.indicators && values.header.indicators.length > 0) {
            let indicators = values.header.indicators;
            indicators.forEach(indicatorItem => {
                let visible = true;
                let sortDirection = null;

                if (selectedFields && selectedFields.length > 0) {
                    visible = selectedFields.some(fieldKey => indicatorItem.fieldKey === fieldKey);
                }
                if (orderTypeEnum && orderField && orderField === indicatorItem.fieldKey) {
                    sortDirection = orderTypeEnum;
                }
                let indicatorColumns = {
                    name: indicatorItem.fieldKey,
                    caption: indicatorItem.fieldTitle,
                    visible,
                    sortable: true,
                    sortDirection,
                    checked: false,
                    get: function (item, column) {
                        let fieldKey = column.name;
                        let fieldKeyOriginal = `${fieldKey}_original`;

                        let itemValue = 0;
                        if (item != null) {
                            if (item[fieldKey] != null) {
                                itemValue = item[fieldKey];
                            } else if (item[fieldKeyOriginal] != null) {
                                itemValue = item[fieldKeyOriginal];
                            }
                        }
                        if (itemValue == null) {
                            itemValue = 0;
                        }
                        return itemValue;
                    },
                    hasTemplate: false,
                    getYieldContent: function (name, item, index) {
                        return "";
                    }
                };
                if (hasStackedHeaderGroups) {
                    stackedHeaderGroups = getStackedHeaderGroups(stackedHeaderGroups, indicatorItem);
                }
                allColumns.push(indicatorColumns);
            });
        }
        return {
            columns: allColumns,
            stackedHeaderGroups
        };
    } else {
        return {
            allColumns: [],
            stackedHeaderGroups: []
        };
    }
}

function getStackedHeaderGroups(stackedHeaderGroups, fieldItem) {
    let group = fieldItem.group;
    if (group != null) {
        let targetHeaderGroupsItem = stackedHeaderGroups.find(groupItem => groupItem.text === group);
        if (targetHeaderGroupsItem != null) {
            targetHeaderGroupsItem.columns.push(fieldItem.fieldKey);
        } else {
            let stackedHeaderGroupsItem = {
                text: group,
                stackedHeaderClass: "stacked-header-cell-item",
                columns: [fieldItem.fieldKey],
                groups: []
            };
            stackedHeaderGroups.push(stackedHeaderGroupsItem);
        }
    }
    return stackedHeaderGroups;
}

function getReverseData(data, fieldGroups = [], language) {
    let indicators = data.rows.map((row) => {
        let fieldKey = row[data.header.dimensions[0].fieldKey];
        let fieldTitle = row[data.header.dimensions[0].fieldKey];
        return {
            fieldKey,
            fieldTitle
        };
    });

    let selectedIndicators = data.header.indicators;
    if (fieldGroups && fieldGroups.length > 0) {
        let selectedFieldGroups = fieldGroups.filter((fieldGroup) => {
            return fieldGroup.states.isChecked;
        });
        let groupLists = [];
        let selectedGroupListsValues = [];
        if (selectedFieldGroups.length) {
            groupLists = selectedFieldGroups[0].groupLists;
            selectedGroupListsValues = selectedFieldGroups[0].values;
        }
        selectedIndicators = groupLists.filter((group) => {
            return selectedGroupListsValues.some((selectedGroupListsValue) => {
                return group.fieldKey === selectedGroupListsValue;
            });
        });
    }

    let rows = selectedIndicators.map((selectedIndicator) => {
        let row = {};
        //第一个key及value;
        row["_reverseDimensionsFieldKey"] = selectedIndicator.fieldTitle;
        //剩余的key及value;

        let indicatorFieldKey = selectedIndicator.fieldKey;
        let dimensionFieldKey = data.header.dimensions[0].fieldKey;

        let rowItemKeys = Object.keys(data.rows[0]);
        let keyPrefixData = null;
        if (rowItemKeys && rowItemKeys.length > 0) {
            keyPrefixData = rowItemKeys.reduce((arr, currentKey) => {
                let keyItem = null;
                if (currentKey.startsWith(indicatorFieldKey)) {
                    keyItem = {
                        pos: "start",
                        prefix: currentKey.replace(indicatorFieldKey, "")
                    };
                } else if (currentKey.endsWith(indicatorFieldKey)) {
                    keyItem = {
                        pos: "end",
                        prefix: currentKey.replace(indicatorFieldKey, "")
                    };
                }
                if (keyItem != null) {
                    arr.push(keyItem);
                }
                return arr;
            }, []);
        }

        data.rows.forEach((oldRow) => {
            if (keyPrefixData && keyPrefixData.length > 0) {
                let oldRawDimensionFieldKey = oldRow[dimensionFieldKey];
                let oldRawIndicatorFieldKey = indicatorFieldKey;
                keyPrefixData.forEach(prefixItem => {
                    let prefix = prefixItem.prefix;
                    if (prefixItem && prefix) {
                        if (prefixItem.pos === "start") {
                            oldRawDimensionFieldKey = oldRawDimensionFieldKey + prefix;
                            oldRawIndicatorFieldKey = `${indicatorFieldKey}${prefix}`;
                        } else if (prefixItem.pos === "end") {
                            oldRawDimensionFieldKey = prefix + oldRawDimensionFieldKey;
                            oldRawIndicatorFieldKey = `${prefix}${indicatorFieldKey}`;
                        } else {
                            oldRawDimensionFieldKey = oldRow[dimensionFieldKey];
                            oldRawIndicatorFieldKey = indicatorFieldKey;
                        }
                    } else {
                        oldRawDimensionFieldKey = oldRow[dimensionFieldKey];
                        oldRawIndicatorFieldKey = indicatorFieldKey;
                    }
                    if (oldRawDimensionFieldKey && oldRawIndicatorFieldKey) {
                        row[oldRawDimensionFieldKey] = oldRow[oldRawIndicatorFieldKey];
                    }
                });
            } else {
                row[oldRow[dimensionFieldKey]] = oldRow[indicatorFieldKey];
            }
        });
        return row;
    });
    let reverseData = {
        header: {
            dimensions: [{
                fieldKey: "_reverseDimensionsFieldKey",
                fieldTitle: Locales.get("components.reportEcharts.noReportValue", language),
            }],
            indicators,
        },
        rows
    };
    return reverseData;
}

//转化表格列数据
function buildTableColumnsData(props, isUpdate = true) {
    let {
        reportData,
        orderTypeEnum,
        orderField,
        selectedFields,
        hasStackedHeaderGroups
    } = props;

    let allColumns = [];
    let stackedHeaderGroups = [];

    if (reportData) {
        let currentOrderField = undefined;
        let orderTypeEnums = undefined;
        if (orderField && orderTypeEnum) {
            currentOrderField = orderField;
            orderTypeEnums = orderTypeEnum;
        }

        if (reportData.header && reportData.header.dimensions && reportData.header.dimensions.length > 0) {
            let dimensions = reportData.header.dimensions;
            dimensions.forEach(dimensionItem => {
                let visible = true;
                let sortDirection = null;
                if (selectedFields && selectedFields.length > 0) {
                    visible = selectedFields.some(fieldKey => dimensionItem.fieldKey === fieldKey);
                }
                if (orderTypeEnums && currentOrderField && currentOrderField === dimensionItem.fieldKey) {
                    sortDirection = orderTypeEnums;
                }

                let hasIsShowPropery = Object.prototype.hasOwnProperty.call(dimensionItem,"isShow") && ("isShow" in dimensionItem);
                //isShow 为后端进行隐藏字段
                if (hasIsShowPropery) {
                    visible = dimensionItem.isShow;
                }

                let dimensionColumns = {
                    name: `${dimensionItem.fieldKey}`,
                    caption: dimensionItem.fieldTitle,
                    visible,
                    sortable: false,
                    sortDirection,
                    get: function (item, column) {
                        let fieldKey = column.name;
                        let fieldKeyOriginal = `${fieldKey}_original`;

                        let itemValue = 0;
                        if (item != null) {
                            if (item[fieldKey] != null) {
                                itemValue = item[fieldKey];
                            } else if (item[fieldKeyOriginal] != null) {
                                itemValue = item[fieldKeyOriginal];
                            }
                        }
                        if (itemValue == null) {
                            itemValue = 0;
                        }
                        return itemValue;
                    }
                };
                if (hasStackedHeaderGroups) {
                    stackedHeaderGroups = getStackedHeaderGroups(stackedHeaderGroups, dimensionItem);
                }
                allColumns.push(dimensionColumns);
            });
        }

        if (reportData.header && reportData.header.indicators && reportData.header.indicators.length > 0) {
            let indicators = reportData.header.indicators;
            indicators.forEach(item => {
                let visible = true;
                let sortDirection = null;

                if (selectedFields && selectedFields.length > 0) {
                    visible = selectedFields.some(fieldKey => item.fieldKey === fieldKey);
                }
                if (orderTypeEnums && currentOrderField && currentOrderField === item.fieldKey) {
                    sortDirection = orderTypeEnums;
                }
                let indicatorColumns = {
                    name: `${item.fieldKey}`,
                    caption: item.fieldTitle,
                    visible,
                    sortable: true,
                    sortDirection,
                    get: function (item, column) {
                        let fieldKey = column.name;
                        let fieldKeyOriginal = `${fieldKey}_original`;

                        let itemValue = 0;
                        if (item != null) {
                            if (item[fieldKey] != null) {
                                itemValue = item[fieldKey];
                            } else if (item[fieldKeyOriginal] != null) {
                                itemValue = item[fieldKeyOriginal];
                            }
                        }
                        if (itemValue == null) {
                            itemValue = 0;
                        }
                        return itemValue;
                    },
                    hasTemplate: false,
                    getYieldContent: function (name, item, index) {
                        return "";
                    }
                };
                if (hasStackedHeaderGroups) {
                    stackedHeaderGroups = getStackedHeaderGroups(stackedHeaderGroups, item);
                }
                allColumns.push(indicatorColumns);
            });
        }
    }

    if (isUpdate) {
        this.setState({
            tableColumns: allColumns,
            stackedHeaderGroups,
        });
    } else {
        return {
            cloumns: allColumns
        };

    }
}

function getChartDataFix(that, values, selectedIndicators = [], fieldGroups = []) {
    let {
        language,
        isReverseData,
        enableDataView,
    } = that.props;

    let items = [];
    let columns = [];
    let tableColumns = [];
    let tableItems = [];
    let exceedName = "";

    if (values) {
        if (isReverseData) {
            if (values.rows && values.rows.length > 0) {
                values = getReverseData(values, fieldGroups, language);
            }
            selectedIndicators = [];
        }

        let dimensions = [];
        if (values.header && values.header.dimensions) {
            dimensions = values.header.dimensions;
        }

        let indicators = [];
        if (values.header && values.header.indicators && values.header.indicators) {
            indicators = values.header.indicators;
        }

        if (selectedIndicators.length > 0) {
            indicators = selectedIndicators.map(fieldKey => indicators.find(indicator => fieldKey === indicator.fieldKey)).filter(Boolean);
        }

        let rows = [];
        if (values && values.rows) {
            rows = values.rows;
        }

        if (indicators && indicators.length > 0) {
            // 有维度
            if (dimensions && dimensions.length > 0) {
                indicators.forEach(indicator => {
                    columns.push({
                        field: indicator.fieldKey,
                        name: indicator.fieldTitle || ""
                    });
                });

                // 有维度，有指标。维度作为x轴，指标作为series。
                rows.forEach(row => {
                    let item = {};
                    let dimensionNames = [];
                    let originalDimensionNames = [];
                    dimensions.forEach(dimension => {
                        let fieldKey = dimension.fieldKey;
                        let fieldKeyOriginal = fieldKey + ORIGINAL;
                        if (row[fieldKey]) {
                            dimensionNames.push(row[fieldKey]);
                        }
                        if (row[fieldKeyOriginal]) {
                            originalDimensionNames.push(row[fieldKeyOriginal]);
                        }
                    });

                    item.xLabel = dimensionNames.join(DIMENSION_VALUE_SEPARATOR);
                    item.xLabelOriginal = originalDimensionNames.join(DIMENSION_VALUE_SEPARATOR);
                    indicators.forEach(indicator => {
                        let fieldKey = indicator.fieldKey;
                        let fieldKeyOriginal = fieldKey + ORIGINAL;
                        item[fieldKey] = {
                            value: row[fieldKey],
                            originalValue: row[fieldKeyOriginal] || Number(row[fieldKey]) || 0
                        };
                        if (Object.prototype.hasOwnProperty.call(row,`${fieldKey}${TOOTIP}`)) {
                            let tootipValue = row[`${fieldKey}${TOOTIP}`] || row[`${fieldKey}${TOOTIP}${ORIGINAL}`];
                            item[fieldKey].tootipValue = tootipValue;
                        }
                    });
                    items.push(item);
                });
            } else {
                // 无维度，只有指标。
                // 创建一个虚拟的维度，以指标字段名为值，列名（即legend名）为固定字符串“多指标”（如果只有一个指标时，取指标字段名）。
                let row = {};
                if (rows && rows.length > 0) {
                    row = rows[0];
                }

                let columnName = Locales.get("reportEcharts.multipleIndicatorName", language);
                if (indicators.length === 1) {
                    columnName = indicators[0].fieldTitle;
                }

                let columnField = indicators[0].fieldKey;
                columns.push({
                    field: columnField,
                    name: columnName
                });

                indicators.forEach((indicator, index) => {
                    let fieldKey = indicator.fieldKey;
                    let fieldKeyOriginal = fieldKey + ORIGINAL;
                    let item = {};
                    item.xLabel = indicator.fieldTitle;
                    item.xLabelOriginal = indicator.fieldTitle;
                    item[columnField] = {
                        value: row[fieldKey],
                        originalValue: row[fieldKeyOriginal] || Number(row[fieldKey]) || 0
                    };
                    if (row && Object.prototype.hasOwnProperty.call(row,`${fieldKey}${TOOTIP}`)) {
                        let tootipValue = row[`${fieldKey}${TOOTIP}`] || row[`${fieldKey}${TOOTIP}${ORIGINAL}`];
                        item[fieldKey].tootipValue = tootipValue;
                    }
                    if (that.props.showChartExceedSameIndustry) {
                        if (index === 0) {
                            items.push(item);
                        }
                    } else {
                        items.push(item);
                    }
                });
            }
        } else {
            // keep the chart alive (give him some default values)
        }

        if (enableDataView) {
            // 字段筛选
            // deep-copy
            const valuesCopy = Udesk.utils.object.deepCopy({}, values);
            valuesCopy.header.indicators = indicators;

            tableColumns = buildTableColumnsData({
                reportData: valuesCopy
            }, false).cloumns;
            tableItems = valuesCopy.rows;
            that.privates.dataViewTableItemsCache = Udesk.utils.object.deepCopy([], tableItems);
        }

        let exceedField = indicators[1] ? indicators[1].fieldKey : "";
        exceedName = rows[0] ? rows[0][exceedField] : "";
    }

    return {
        columns,
        items,
        tableColumns,
        tableItems,
        exceedName,
    };
}

function getTableColumnsFromChartData(data) {
    return data.columns.map(i => ({
        title: i.name,
        dataIndex: i.field,
        key: i.field,
    }));
}

function getTableDataFromChartData(data) {
    return data.items.map((item, index) => {
        const keys = Object.keys(item);
        return keys.reduce((prev, current) => {
            return {
                ...prev,
                [current]: ((item[current] && item[current].value) || item[current]),
            };
        }, {key: index});
    });
}

export default {
    getChartData,
    getFieldGroupLists,
    getTableColumnsData,
    getChartDataFix,
    getTableColumnsFromChartData,
    getTableDataFromChartData,
};


