import React from 'react';
import { Button, Icon } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

export const ScoreDetailOpenButton = React.memo((props: any) => {
    const {isAllOpen, setIsAllOpen} = props;

    return (
        <Button 
            type={isAllOpen ? 'link' : 'primary'}
            onClick={() => setIsAllOpen(isOpen => !isOpen)} 
            icon={
                isAllOpen ? null : <Icon 
                    type='CheckCircleFilled'
                    antdIcon={true}
                />
            }>{isAllOpen ? /* 收起解析 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.openButton.index.collapseAnalysis : /* 展开解析 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.openButton.index.expandParsing }</Button>
    );
});


