import React, { FC, memo, useState } from 'react';
import { Button, Modal } from 'udesk-ui';
import { getSystemModule } from 'Udesk/system/subApp';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { postPointTemplateImport } from 'src/api/pointTemplate/import';

interface TemplateProps {
    sdkOptions;
}

const Template: FC<TemplateProps> = memo((props) => {
    const { sdkOptions } = props;

    const locales = Locales['current'];

    const [isUploadVisible, setIsUploadVisible] = useState(false);

    const onOpen = () => {
        setIsUploadVisible(true);
    };

    const changeUploadVisible = () => {
        setIsUploadVisible((v) => !v);
    };

    const [file, setFile] = useState<any>();
    const [uploadData, setUploadData] = useState<any>();

    const importTemplate = () => {
        if (!file || !uploadData) {
            return Udesk.ui.notify.error(locales.business.notifyMessage.pleaseUploadFile);
        }

        postPointTemplateImport({
            keyName: uploadData.key,
            fileName: file.name,
            systemModule: getSystemModule(),
        })
            .then((resp) => {
                Udesk.ui.notify.success(locales.business.notifyMessage.importFileSuccess);
                changeUploadVisible();
            })
            .catch((reason) => {
                Udesk.ui.notify.error(
                    reason.errorMsg || locales.business.notifyMessage.importFildFailed
                );
                changeUploadVisible();
            });
    };

    const uploadFile = (e) => {
        let files = e.target.files;

        if (files.length === 0) {
            return;
        }

        // 取消文件大小限制
        // if (files[0] && (files[0].size > 2 * 1024 * 1024)) {
        //     return Udesk.ui.notify.error(UDL.business.notifyMessage.overFileSize);
        // }
        let fileName = fileType(files[0].name);
        if (!fileName) {
            return;
        } else if (fileName !== 'xlsx') {
            return Udesk.ui.notify.error(
                Udesk.utils.string.format(locales.labels.fileTypeHolder, 'xlsx')
            );
        }
        //type 1语音,2导入,3导出,4语料数据
        Udesk.app.fileStorage.upload(files[0], {
            token: new Promise((resolve, reject) => {
                Udesk.business
                    .getServerAuthorization({
                        type: 2,
                    })
                    .then(
                        function (resp) {
                            resolve(resp);
                        },
                        function (reason) {
                            reject(reason);
                        }
                    );
            }),
            //progressCallback: progressCallback.bind(this),
            successCallback: (file, uploadData) => {
                setFile(file);
                setUploadData(uploadData);
            },
            errorCallback: (file, uploadData) => {},
        });
    };

    const downloadTemplate = () => {
        const lan = Udesk.data.init?.userLanguage;
        let templateLocalUrl = lan
            ? `/import/inspection-point-template/inspection-point-template_${lan}.xlsx`
            : `/import/inspection-point-template/inspection-point-template_en.xlsx`;
        let templateUrl =
            Udesk.business.apiPath.getStaticBasePath(
                sdkOptions.props.domain,
                sdkOptions.props.pathPrefix
            ) + templateLocalUrl;
        window.location = templateUrl as any;
    };

    return (
        <>
            <Button type="primary" onClick={() => onOpen()}>
                {/* 导入 */}
                {
                    locales.pages.intelligentAnalysis.ruleTemplate.components.importButton.index
                        .import
                }
            </Button>

            <Modal
                visible={isUploadVisible}
                title={locales.pages.tasks.manage.template.checkPoint.index.import}
                onCancel={changeUploadVisible}
                onOk={importTemplate}
            >
                <div className="udesk-qa-modal-body-content">
                    <div className="form-group">
                        <label>
                            {locales.labels.uploadFile}
                            {locales.labels.colon}
                        </label>
                        <div className="upload-file-button-container">
                            <input
                                type="file"
                                className="upload-file-inpiut"
                                onChange={uploadFile}
                            />
                            <button
                                type="button"
                                className="upload-file-button btn btn-sm btn-primary"
                            >
                                {locales.labels.upload}
                            </button>
                        </div>
                        {file && file.name ? (
                            <span className="upload-file-name">{file.name}</span>
                        ) : null}
                    </div>
                    <div className="form-group">
                        {locales.labels.numOne}
                        {locales.labels.colon}&nbsp;&nbsp;
                        {locales.labels.click}&nbsp;&nbsp;
                        <span className="upload-file-template" onClick={downloadTemplate}>
                            {locales.labels.downloadTemplate}
                        </span>
                        &nbsp;&nbsp;
                        {locales.labels.fillInTheForm}
                    </div>
                    <div className="form-group">
                        {locales.labels.numTwo}
                        {locales.labels.colon}&nbsp;&nbsp;
                        {locales.labels.UploadExcelfile}
                    </div>
                </div>
            </Modal>
        </>
    );
});

export default Template;

function fileType(fileName) {
    if (!fileName || typeof fileName !== 'string') {
        return false;
    }
    let list = fileName.split('.');
    return list[list.length - 1];
}
