import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import PropTypes from 'prop-types';

export default class TaskCheckRangeComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        inspectionConditions: [],
        condition: {},
        onChange: null,
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        inspectionConditions: PropTypes.array,
        condition: PropTypes.object,
        onChange: PropTypes.func,
    };
    //#endregion

    //#region state
    state = {
        applyOption: null,
        condition: {},
        inspectionConditions: []
    };
    //#endregion

    //#region actions
    actions = {
        changeApplyConditionId(value) {
            const condition = this.state.condition;
            if (value === Udesk.enums.applyOptions.notHit.id) {
                condition.applyConditionId = null;
                this.trigger('onChange', condition);
            }
            this.setState({
                applyOption: value
            });
        },

        changeCondition(key, e) {
            let value = e;
            if (e.target && e.currentTarget && e.stopPropagation) {
                value = e.target.value;
            }
            const condition = this.state.condition;
            condition[key] = value;
            this.actions.update();
            this.trigger('onChange', condition);
        },

        validateRangeValue(condition, attribute, value) {
            if (value === '0') {
                condition[attribute] = '';
                this.actions.update();
                this.trigger('onChange', this.state.condition);
                return Udesk.ui.notify.error(UdeskLocales.current.components.checkRange.notZero);
            }

            if (condition.applyScopeStart && condition.applyScopeEnd) {
                if (parseInt(condition.applyScopeStart, 10) > parseInt(condition.applyScopeEnd, 10)) {
                    condition[attribute] = '';
                    this.actions.update();
                    this.trigger('onChange', this.state.condition);
                    return Udesk.ui.notify.error(UdeskLocales.current.components.checkRange.startMustLessThanEnd);
                }
            }
        }
    };
    //#endregion

    //#region Life Cycle
    init() {
        const { condition, inspectionConditions } = this.props;
        const applyConditionId = condition.applyConditionId;

        /* eslint-disable react/no-direct-mutation-state */
        if (applyConditionId) {
            this.state.applyOption = Udesk.enums.applyOptions.hit.id;
        } else {
            this.state.applyOption = Udesk.enums.applyOptions.notHit.id;
        }
        this.state.condition = {
            applyConditionId: condition.applyConditionId,
            applyConditionHitType: condition.applyConditionHitType,
            applyConditionScope: condition.applyConditionScope,
            applyConditionLimit: condition.applyConditionLimit,
            applyRole: condition.applyRole,
            applyScopeStart: condition.applyScopeStart,
            applyScopeEnd: condition.applyScopeEnd,
        };
        this.state.inspectionConditions = inspectionConditions.filter(item => {
            return item.id !== condition.id;
        });
        /* eslint-enable react/no-direct-mutation-state */
    }
    componentDidMount() {
    }
    //#endregion
}
