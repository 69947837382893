import RegExps from '../config/regex';

export default {
    required,
    number,
    numberNullable,
    mobile,
    mobileNullable,
    telephone,
    telephoneNullable,
    email,
    emailNullable,
    currency,
    currencyNullable,
};

function required(str) {
    return RegExps.required.test(str);
}

function number(str) {
    return RegExps.number.test(str);
}

function numberNullable(str) {
    return RegExps.numberNullable.test(str);
}

function mobile(str) {
    return RegExps.mobile.test(str);
}

function mobileNullable(str) {
    return RegExps.mobileNullable.test(str);
}

function telephone(str) {
    return RegExps.telephone.test(str);
}

function telephoneNullable(str) {
    return RegExps.telephoneNullable.test(str);
}

function email(str) {
    return RegExps.email.test(str);
}

function emailNullable(str) {
    return RegExps.emailNullable.test(str);
}

function currency(str) {
    return RegExps.currency.test(str);
}

function currencyNullable(str) {
    return RegExps.currencyNullable.test(str);
}


/*function isBlank(obj) {
    return obj === null || obj === undefined || typeof obj === 'string' && obj.match(/\S/) === null;
}*/
