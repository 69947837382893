import React, { useCallback, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import PageLeftTable from './components/pageLeftTable';
import PageRightTable from './components/pageRightTable';
import { getSearchParams } from 'src/util/link';
import { createComponent } from 'src/util/component';
import './index.scss'; 

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const id = useMemo(() => getSearchParams(props.location.search)?.id, [props.location.search]);
    const {
        storages: {selectedRowKey = parseInt(id) || undefined}, 
        updateStorages, 
        sdkOptions, 
        history,
    } = props;
    const [data, setData] = useState<any[]>([]);
    const scenario = Udesk.enums.applicationScenarios.objection.id;

    const setSelectedRowKey = useCallback((values) => {
        updateStorages({
            selectedRowKey: values
        });
    }, [updateStorages]);

    return (
        <div className='udesk-qa-web-page words-mining-favorite-view-page'>
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root'>
                    <div>
                        <PageLeftTable {
                            ...{
                                scenario,
                                data,
                                setData,
                                history,
                                locales,
                                sdkOptions,
                                selectedRowKey, 
                                setSelectedRowKey,
                            }
                        }/>
                        <PageRightTable {
                            ...{
                                scenario,
                                groupDataSource: data,
                                history,
                                locales,
                                sdkOptions,
                                selectedRowKey, 
                            }
                        }/>
                    </div>

                </div>
            </div>
        </div>
        
    );
});

export default createComponent(
    Template,
    {},
    [
        /^\/sales\/learning-center\/words-mining\/*/i,
    ]
);