import React, { useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { Button } from 'udesk-ui';

const SemanticTagsSelect: React.FC<any> = (props) => {
    const locales = Locales['current'];
    const {
        onChange: propsOnChange,
        value = {},
        showStandardSentence = true,
        groupIdFieldName = 'groupId',
        semanticNameFieldName = 'semanticName',
        sentenceFieldName = 'sentence',
        ...args
    } = props;
    const [isHover, setIsHover] = useState(false);
    const seleteSemanticNameBySider = (tag) => {
        let standard = tag.tags.find((item) => item.standardFlag === 1);

        propsOnChange({
            [groupIdFieldName]: tag.id,
            key: tag.id,
            [semanticNameFieldName]: tag.title,
            [sentenceFieldName]: standard ? standard.content : '',
        });
    };
    const onClick = () => {
        props.SmartToolBarRef.current.actions.open(seleteSemanticNameBySider, value.key || value[groupIdFieldName]);
        props.SmartToolBarRef.current.actions.seleteTag('3');
        if (!props.SmartToolBarRef.current.privates.isFront) {
            props.SmartToolBarRef.current.actions.backToIndex();
        }
    };
    return (
        <React.Fragment>
            <Button
                {...args}
                onClick={onClick}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                style={{
                    width: 144,
                }}
                title={value[semanticNameFieldName]}
            >
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {value[semanticNameFieldName]
                        ? isHover
                            ? Locales['current'].components.operatorListGather.reQuerySemanticTags
                            : value[semanticNameFieldName]
                        : Locales['current'].components.operatorListGather.querySemanticTags}
                </div>
            </Button>
            {showStandardSentence && Boolean((value.key || value[groupIdFieldName])) && (
                <div
                    style={{ marginTop: 4, color: 'rgba(0, 0, 0, .65)' }}
                >{`${locales.components.semanticTagsSelect.index.standardSentence}${value[sentenceFieldName]}`}</div>
            )}
        </React.Fragment>
    );
};
class Component extends React.Component {
    render() {
        return <SemanticTagsSelect {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
