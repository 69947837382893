import React, { useEffect, useState } from 'react';
import Comment from 'Component/common/gong/comment-component';
import Wave from 'Component/common/gong/wave-audio';
import Page from 'Component/common/udesk-qa-page-layout';
import Udesk from 'Udesk';
import { getUsers } from 'src/api/users';
import { getInspectionDataById } from 'src/api/inspectionData/{id}';
import { Button } from 'udesk-ui';
import Locales from 'UdeskLocales';

const SDKTest = (props) => {
    const locales = Locales['current'];

    const mockData = {
        // time: '00:11:12',
        quote: /* 2015年那会，我国房地产到了生存的关键时刻，大量的房子无人问津，老百姓不愿意购买。杭州一度跌破8000，有开发商砸盘出货，深圳当时还在2w-3w左右盘整，一线城市的房子也岌岌可危 */locales.pages.sdkTest.componentIndex.atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger,
        author: /* 小安 */locales.pages.sdkTest.componentIndex.xiaoAn,
        headPortraitUrl: Udesk.data.init.user.headPortraitUrl,
        createTime: '2021-12-27 15:04:10',
        content:
        /* Storestore storeload这些内存屏障叫jsr内存屏障，是jvm层面的，在操作系统底层实现方式有二：1是锁总线，二是内存屏障元语，如sfance(写屏障)mfence(全屏障)ifence(读屏障)。hotspots虚拟机底层采用的是一条汇编语句：lock addl，lock一个空操作，即为锁总线，这么做是因为部分操作系统不支持内存屏障元语。 */locales.pages.sdkTest.componentIndex.storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage,
        childComments: [
            {
                author: /* 小北 */locales.pages.sdkTest.componentIndex.xiaobei,
                createTime: '2021-12-27 15:04:10',
                content: /* 1. 试着腌过几次肉，加料酒后炒出来的菜，肉和馊了似的，一股酸臭味！从此腌肉再不放料酒 */locales.pages.sdkTest.componentIndex.TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon,
                replyToAuthor: /* 小安 */locales.pages.sdkTest.componentIndex.xiaoAn,
            },
            {
                author: /* 小东 */locales.pages.sdkTest.componentIndex.xiaodong,
                createTime: '2021-12-27 15:04:10',
                content: /* 2. 试着腌过几次肉，加料酒后炒出来的菜，肉和馊了似的，一股酸臭味！从此腌肉再不放料酒 */locales.pages.sdkTest.componentIndex.TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon,
                replyToAuthor: /* 小安 */locales.pages.sdkTest.componentIndex.xiaoAn,
            },
            {
                author: /* 小西 */locales.pages.sdkTest.componentIndex.xiaoxi,
                createTime: '2021-12-27 15:04:10',
                content: /* 3. 试着腌过几次肉，加料酒后炒出来的菜，肉和馊了似的，一股酸臭味！从此腌肉再不放料酒 */locales.pages.sdkTest.componentIndex.TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon,
                replyToAuthor: /* 小东 */locales.pages.sdkTest.componentIndex.xiaodong,
            },
        ],
    };
    const mockDataList = new Array(3).fill(1).map((item, index) => ({
        ...mockData,
        content: `${index}. ${mockData.content}`,
    }));
    const [users, setUsers] = useState<any[]>([]);
    const [detail, setDetail] = useState<any>(null);
    useEffect(() => {
        getUsers({
            params: {
                pageNum: 1,
                pageSize: 1000,
            },
        }).then((resp) => {            
            setUsers((resp.data??[]).map((item: any) => ({
                label: item.realname,
                value: item.id,
            })));
        });
        getInspectionDataById({
            params: {
                taskId: 530
            },
            segments: {
                id: 99694065
            }
        }).then(resp => {
            setDetail(resp.data);
        });
    }, []);
    const {audioInstance, canPlay, currentTime} = Wave.useAudio(detail?.voiceUrl);
    return (
        <Page pageBodyClassName='data-collection-point-page-index' title='test' padding={true}>
            <Wave detail={detail} audioInstance={audioInstance} disabled={!canPlay} currentTime={currentTime} />
            <Button disabled={!canPlay} onClick={() => {
                audioInstance.currentTime = 5;
            }}>set currentTime 5s</Button>
            <Comment comments={mockDataList} userList={users} />
        </Page>
    );
};

export default SDKTest;
