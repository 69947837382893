import React from 'react';
import Udesk from 'Udesk';
// import ReactTable from 'udesk-react/src/components/react-table';
import UdeskPagination from 'Component/common/udesk-pagination';
import ReactModal from 'udesk-react/src/components/react-modal';
import './style.scss';
import SemanticTagsRoute from './route';
import { PageHeader, Button, Space, Input, Select, Drawer, Empty } from 'udesk-ui';
import AutoSizeTable from 'Component/common/auto-size-table';
import ExportButton from 'Component/common/export-button';
import BatchDeleteButton from 'Component/common/batch-delete-btn';

const { Option } = Select;
export default class SemanticTagsTemplate extends SemanticTagsRoute {
    render() {
        // let {
        //     sdkOptions
        // } = this.props;
        let { columns, pageNum, pageSize, total } = this.state;
        let {
            isUploadVisible,
            file,
            loading,
            selectedItems,
            dataSource,
            moduleType,
            viewReferenceVisible,
            currentTag,
        } = this.privates;
        let { actions, privates, locales } = this;
        return (
            // <div className="semantic-tags-index-page">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.semanticTags.title}
                />
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root semantic-tags-index-page">
                        <div className="semantic-tags-index-page-body qa-react-page-body">
                            <If
                                condition={
                                    Udesk.data.init.user &&
                                    Udesk.data.init.user.hasFeature('semantic:semanticTag:view')
                                }
                            >
                                <div className="semantic-tags-index-page-body-search-container qa-react-page-body-search-container">
                                    <div className="qa-react-page-body-search-container-left-part pull-left">
                                        <Space>
                                            <Input.Search
                                                defaultValue={privates.keywords}
                                                placeholder={locales.labels.searchPlaceHolder}
                                                onSearch={actions.searchItems}
                                                onChange={actions.mutator(
                                                    'privates.keywords',
                                                    (e) => e.target.value
                                                )}
                                            />
                                            <div>
                                                {locales.fields.semanticTags.orgName}:
                                                <Select
                                                    value={moduleType}
                                                    onChange={actions.moduleTypeChange}
                                                    style={{ width: 120 }}
                                                >
                                                    <Option value={null}>
                                                        {locales.labels.all}
                                                    </Option>
                                                    {Udesk.enums.organizationTypes.map((item) => {
                                                        return (
                                                            <Option value={item.id}>
                                                                {item.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <Button type="primary" onClick={actions.searchItems}>
                                                {
                                                    locales.components.pages.conceptWord.control
                                                        .commit
                                                }
                                            </Button>
                                        </Space>
                                    </div>
                                    <div className="qa-react-page-body-search-container-right-part pull-right">
                                        <Space>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature(
                                                        'semantic:semanticTag:add'
                                                    )
                                                }
                                            >
                                                <Button onClick={actions.createItem}>
                                                    {locales.labels.create}
                                                </Button>
                                            </If>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature(
                                                        'semantic:semanticTag:delete'
                                                    )
                                                }
                                            >
                                                <BatchDeleteButton
                                                    deleteItemsCount={selectedItems.length}
                                                    // failedCount={0}
                                                    onDelete={actions.batchDelete}
                                                />
                                            </If>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature(
                                                        'semantic:semanticTag:import'
                                                    )
                                                }
                                            >
                                                <Button onClick={actions.changeUploadVisible}>
                                                    {locales.labels.import}
                                                </Button>
                                            </If>
                                            <If
                                                condition={
                                                    Udesk.data.init.user &&
                                                    Udesk.data.init.user.hasFeature(
                                                        'semantic:semanticTag:export'
                                                    )
                                                }
                                            >
                                                <ExportButton
                                                    disabled={loading}
                                                    exportDataCount={selectedItems?.length || total}
                                                    hasFilterCondition={true}
                                                    onClick={actions.export}
                                                />
                                            </If>
                                        </Space>
                                    </div>
                                </div>
                                <div className="semantic-tags-index-page-body-content-container qa-react-page-body-content-container">
                                    <If condition={privates.asyncModelFulfilled}>
                                        <If condition={!privates.asyncModelResolved}>
                                            {privates.asyncModelErrorMsg}
                                        </If>
                                    </If>
                                    <AutoSizeTable
                                        loading={loading}
                                        subtractedHeight={146}
                                        columns={columns}
                                        pagination={false}
                                        dataSource={dataSource}
                                        rowSelection={{
                                            type: 'checkbox',
                                            onSelect: actions.onCheckChange,
                                            onSelectAll: actions.onSelectAll,
                                            selectedRowKeys: selectedItems.map((i) => i.id),
                                        }}
                                    />
                                    {/* <ReactPager
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        total={total}
                                        languageCode={sdkOptions.props.language}
                                        pageChanged={actions.pageChanged}
                                    /> */}
                                    <UdeskPagination
                                        current={pageNum}
                                        pageSize={pageSize}
                                        total={total}
                                        onChange={actions.pageChanged}
                                    />
                                </div>
                            </If>
                            <Drawer
                                title={locales.labels.referenceDetail}
                                placement="right"
                                onClose={actions.onViewReferenceVisibleClose}
                                visible={viewReferenceVisible}
                                width={500}
                            >
                                {/* <Space>
                                    <div>{locales.components.pages.semanticTags.current}:</div>
                                    <div>{currentTag?.title}</div>
                                </Space> */}
                                {/* <div>{locales.components.pages.semanticTags.reference}:</div> */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {currentTag?.reference ? (
                                        (currentTag?.reference ?? []).map((item, index) => {
                                            return (
                                                <div>
                                                    <span>{`${index + 1}. `}</span>
                                                    <span>
                                                        {Udesk.utils.string.format(
                                                            locales.components.pages.semanticTags[
                                                                `reference${item.type}`
                                                            ],
                                                            item.type === 4
                                                                ? `${(item.categories ?? []).join(
                                                                      ' - '
                                                                  )}${
                                                                      item.name
                                                                          ? ` - ${item.name}`
                                                                          : ''
                                                                  }`
                                                                : item.name,
                                                            item.pointName
                                                        )}
                                                    </span>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    )}
                                </div>
                            </Drawer>
                            <ReactModal
                                cancelText={locales.labels.cancel}
                                okText={locales.labels.save}
                                visible={isUploadVisible}
                                title={locales.components.pages.semanticTags.index.import}
                                closeIconClass="udesk-qc-iconfont icon-qc-quxiao pull-right"
                                onCancel={actions.changeUploadVisible}
                                onOk={actions.importSemanticTags}
                                headerClassName="udesk-qa-modal-default-header"
                                footerClassName="udesk-qa-modal-default-footer"
                            >
                                <div className="udesk-qa-modal-body-content">
                                    <div className="form-group">
                                        <label>
                                            {locales.labels.uploadFile}
                                            {locales.labels.colon}
                                        </label>
                                        <div className="upload-file-button-container">
                                            <input
                                                type="file"
                                                className="upload-file-inpiut"
                                                onChange={actions.uploadFile}
                                            />
                                            <button
                                                type="button"
                                                className="upload-file-button btn btn-sm btn-primary"
                                            >
                                                {locales.labels.upload}
                                            </button>
                                        </div>
                                        <If condition={file && file.name}>
                                            <span className="upload-file-name">{file.name}</span>
                                        </If>
                                    </div>
                                    <div className="form-group">
                                        {locales.labels.numOne}
                                        {locales.labels.colon}&nbsp;&nbsp;
                                        {locales.labels.click}&nbsp;&nbsp;
                                        <span
                                            className="upload-file-template"
                                            onClick={actions.downloadTemplate}
                                        >
                                            {locales.labels.downloadTemplate}
                                        </span>
                                        &nbsp;&nbsp;
                                        {locales.labels.fillInTheForm}
                                    </div>
                                    <div className="form-group">
                                        {locales.labels.numTwo}
                                        {locales.labels.colon}&nbsp;&nbsp;
                                        {locales.labels.UploadExcelfile}
                                    </div>
                                </div>
                            </ReactModal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
