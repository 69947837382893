import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Button, Form, Input } from "udesk-ui";
import { Dialogue } from 'Component/pages/components/coach/Dialogue';
import { getRtaProps, isRta } from 'Component/pages/components/coach/Dialogue/components/RealTimeAnalyzer';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        isStartTask, setIsStartTask,
        wrongQuestion,
        globalConfig = {
            enableEmotionDetection: true,
            enableWordsViolationDetection: true,
            enableScorePointDisplay: true,
            enableSpeedWarn: true,
            enableWordsViolationWarn: true,
            enableEmotionDisplay: true,
        }, // 默认所有实时解析都显示
        isLastNode,
        list,
        submitMessageHandle,
        loadingForTaskStart,
        loadingForSendMessage,
        setWrongQuestionIndex,
    } = props;

    const chatRecordRef = useRef<any>(null);
    const inputRef = useRef<any>(null);
    const [form] = Form.useForm();

    const finishHandle = useCallback(({textMessage}) => {
        submitMessageHandle(textMessage);
        form.resetFields();
        inputRef.current?.focus();
    }, [
        form, 
        submitMessageHandle
    ]);

    const startHandle = useCallback(() => {
        setIsStartTask(true);
        setWrongQuestionIndex(0);
    }, [
        setWrongQuestionIndex
    ]);

    const scoreDetailProps = useMemo(() => getRtaProps(wrongQuestion, globalConfig), [wrongQuestion,globalConfig]);

    useEffect(() => {
        chatRecordRef.current?.scrollTo(list?.length);
    }, [list?.length]);

    return (
        <div className={'chat-record-panel'}>
            <div className='chat-panel'>
                <Dialogue
                    ref={chatRecordRef}
                    dialogueSentenceList={list}
                    itemRender={(props, index) => {
                        return (
                            <React.Fragment>
                                <Dialogue.Item 
                                    {...props} 
                                    {...scoreDetailProps}
                                    index={index}
                                    isRta={isRta(wrongQuestion, props)}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
            {!isLastNode && <div className='record-toolbar'>
                <Form form={form} layout="inline" onFinish={finishHandle}>
                    <Form.Item name="textMessage">
                        <Input ref={inputRef} placeholder={/* 请输入 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.record.text.index.pleaseEnter} />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button loading={loadingForSendMessage} size='large' type="primary" htmlType="submit">{/* 发送 */}{UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.record.text.index.sendOut}</Button>
                        )}
                    </Form.Item>
                </Form>
                {
                    !isStartTask && (
                        <div className='start-button'>
                            <Button 
                                loading={loadingForTaskStart}
                                type='primary'
                                size='large' 
                                onClick={startHandle}
                            >
                                {/* 开始练习 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.record.text.index.startPracticing}
                            </Button>
                        </div>
                    )
                }
            </div>
            }
        </div>
    );
});

