import React from 'react';

class EmotionAnalysisComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {

    };
    //#endregion

    state = {

    };
    privates = {

    };
    static computes = {
        _emotions: ["props.detail", function ({ props, privates, locales }) {
            let {
                detail
            } = props;
            let emotions = {
                "header": {
                    "dimensions": [{
                        "fieldKey": "emotion_analysis_call_second",
                        "fieldTitle": locales.fix.callTime,
                        "dataType": "integer",
                        "group": "ignore",
                        "expression": null
                    }],
                    "indicators": [{
                        "fieldKey": "emotion_analysis_customer_value",
                        "fieldTitle": locales.fix.sentimentValue,
                        "dataType": "integer",
                        "group": null,
                        "expression": null
                    }, {
                        "fieldKey": "emotion_analysis_agent_value",
                        "fieldTitle": locales.fix.agentSentimentValue,
                        "dataType": "integer",
                        "group": null,
                        "expression": null
                    }],
                    "tooltips": []
                },
                "info": {
                    "title": locales.fix.sentimentAnalysis,
                    "noConfig": false,
                    "multilevelHeader": false
                }
            };
            let sentenceList = [];
            let rows = [];
            if (detail && detail.dialogRecords && detail.dialogRecords.dialogSentenceList && detail.dialogRecords.dialogSentenceList.length > 0) {
                sentenceList = detail.dialogRecords.dialogSentenceList;
            }
            if (sentenceList.length > 0) {
                sentenceList.forEach(item => {
                    let customerValue, agentValue;
                    if (item.role === "customer") {
                        customerValue = item.emotionValue;
                        agentValue = 0;
                    } else if (item.role === "agent") {
                        customerValue = 0;
                        agentValue = item.emotionValue;
                    }
                    if (item.fromSec !== item.endSec) {
                        for (let i = item.fromSec; i < item.endSec; i++) {
                            let row = {
                                emotion_analysis_call_second: i,
                                emotion_analysis_customer_value: customerValue,
                                emotion_analysis_agent_value: agentValue
                            };
                            rows.push(row);
                        }
                    }
                });
            }
            emotions.rows = rows;
            return emotions;
        }]
    };
    actions = {

    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {

    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    componentWillUnmount() {

    }
    //#endregion
}

export default EmotionAnalysisComponent;