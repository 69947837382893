// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewCallCustomerMessageFindDetailByCustomerIdQuery,
    BaseResponseCustomerDetail,
} from '../../../../../types';

/**
 * findDetailByCustomerId
 *
 * @export
 * @tags 联络看板
 * @link [GET] /review/call/customer/message/findDetailByCustomerId
 */
export function getReviewCallCustomerMessageFindDetailByCustomerId(
    options: ApiOptions<never, GetReviewCallCustomerMessageFindDetailByCustomerIdQuery> & {
        params: GetReviewCallCustomerMessageFindDetailByCustomerIdQuery;
    }
): Promise<BaseResponseCustomerDetail> {
    return request<never, never, GetReviewCallCustomerMessageFindDetailByCustomerIdQuery>(
        '/review/call/customer/message/findDetailByCustomerId',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewCallCustomerMessageFindDetailByCustomerId',
        }
    );
}

export const meta = [
    {
        tags: ['联络看板'],
        path: '/review/call/customer/message/findDetailByCustomerId',
        method: 'get',
    },
];
