// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerCategoryCenterQuery,
    BaseResponseListIntelligentPartnerCourseCategoryFoundResponse,
} from '../../../../types';

/**
 * 课程中心查询课程分类
 *
 * @export
 * @tags 智能陪练-课程分类
 * @link [GET] /review/intelligentPartner/category/center
 */
export function getReviewIntelligentPartnerCategoryCenter(): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse>;
export function getReviewIntelligentPartnerCategoryCenter(
    options: ApiOptions<never, GetReviewIntelligentPartnerCategoryCenterQuery>
): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse>;
export function getReviewIntelligentPartnerCategoryCenter(
    options?: ApiOptions<never, GetReviewIntelligentPartnerCategoryCenterQuery>
): Promise<BaseResponseListIntelligentPartnerCourseCategoryFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerCategoryCenterQuery>(
        '/review/intelligentPartner/category/center',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerCategoryCenter',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-课程分类'],
        path: '/review/intelligentPartner/category/center',
        method: 'get',
    },
];
