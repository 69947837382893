import Udesk from 'Udesk';
import SdkLoaderBase from '../../../common/sdk-loader-base';
import UdeskLocales from 'UdeskLocales';

class QualityAnalysisComponent extends SdkLoaderBase {
    privates: any = {
        renderContainerId: "gong-execution-analysis",
        pageTitle: /* 执行力分析 */UdeskLocales['current'].pages.gong.executionAnalysis.index.executiveForceAnalysis,
    };
}

export default Udesk.react.udeskify(QualityAnalysisComponent);