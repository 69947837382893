import ReactUdesk from 'udesk-react/src/udesk';
import enums from './enums';
import business from './business';
import config from './config';
import helpers from './helpers';
import system from './system';
import data from './data';
import hooks from './hooks/index';
import ui from './ui/index';

let bakReactUdeskEnums = ReactUdesk.enums;
delete ReactUdesk.enums;
let common = ReactUdesk.utils.object.deepCopy(ReactUdesk, {
    RootNamespace: null,
    enums,
    business,
    config,
    helpers,
    system,
    data,
    hooks,
});
ReactUdesk.enums = bakReactUdeskEnums;

Object.assign(common, {
    enums: Object.assign(bakReactUdeskEnums, enums),
});

common.enums._rebuild();

common.ui.notify = Object.assign(common.ui.notify, ui.notify);

export default common;
