import { useMemo, useState } from 'react';

const KEY = 'Page_Cache_CoachTaskCenterList';
const useTaskCenterPageInfoCache = () => {
    const initData = useMemo(() => {
        const cacheJson = sessionStorage.getItem(KEY);
        if (cacheJson) {
            return JSON.parse(cacheJson);
        }
        return null;
    }, []);

    const [cache, setCache] = useState<any>(initData);

    const saveCache = (value) => {
        sessionStorage.setItem(KEY, JSON.stringify(value));
        setCache(value);
    };

    const clearCache = () => {
        sessionStorage.removeItem(KEY);
        setCache(null);
    };

    return [cache, saveCache, clearCache];
};

const clearTaskCenterPageInfoCache = () => {
    sessionStorage.removeItem(KEY);
};

export { useTaskCenterPageInfoCache, clearTaskCenterPageInfoCache };
