import React, { useMemo, useCallback } from 'react';
import { Dropdown, Button, Icon, Menu, Row, Col } from 'udesk-ui';
import Locales from 'UdeskLocales';

import './style.scss';
import { useSchema } from './hooks';

// function getCount(status) {
//     return status === 'hot' ? 2 : status === 'new' ? 1 : 0;
// }

// const sortedSchema = schema.sort((a, b) => {
//     const aWeights = getCount(a.status);
//     const bWeights = getCount(b.status);
//     return bWeights - aWeights;
// });

const Card = (props) => {
    const locales = Locales['current'];

    const {
        name,
        introduction,
        url = '',
        status = '',
        icon,
        isLastRow,
        domain = 'https://qa.udesk.cn',
    } = props;
    const goToLearnMore = useCallback(() => {
        url && window.open(url);
    }, [url]);
    return (
        <div className="udesk-qa-web-product-matrix-component-card-wrapper">
            <div
                className="udesk-qa-web-product-matrix-component-card"
                title={
                    url
                        ? /* 点击了解更多 */ locales.components.productMatrix.index.clickToLearnMore
                        : undefined
                }
                style={isLastRow ? { position: 'relative' } : undefined}
                onClick={goToLearnMore}
            >
                {icon ? (
                    <div
                        className="udesk-qa-web-product-matrix-component-card-logo"
                        style={{
                            backgroundSize: 'contain',
                        }}
                    >
                        <Icon type={icon} style={{ fontSize: '32px', color: '#1b6dff' }} />
                    </div>
                ) : (
                    <div
                        className="udesk-qa-web-product-matrix-component-card-logo"
                        style={{
                            background: `url('${domain}/static/images/qa-logo.png') center 0 no-repeat`,
                            backgroundSize: 'contain',
                        }}
                    ></div>
                )}
                <div className="udesk-qa-web-product-matrix-component-card-content">
                    <div className="udesk-qa-web-product-matrix-component-card-title">
                        <div className="udesk-qa-web-product-matrix-component-card-name">
                            {name}
                        </div>
                        {Boolean(status) && (
                            <div
                                className="udesk-qa-web-product-matrix-component-card-status"
                                style={{
                                    backgroundColor: status === 'new' ? '#0FBA26' : '#FB5127',
                                }}
                            >
                                {status?.toUpperCase?.()}
                            </div>
                        )}
                    </div>
                    <div className="udesk-qa-web-product-matrix-component-card-introduction">
                        {introduction}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Matrix = (props) => {
    const { width, minHeight, list = [] } = props;
    const span = useMemo(() => {
        return 24 / Math.floor(width / 244);
    }, [width]);
    const lastRowCount = useMemo(() => {
        const colCount = Math.floor(width / 244);
        return list.length % colCount;
    }, [list.length, width]);
    return (
        <div className="udesk-qa-web-product-matrix-component-tooltip" style={{ width, minHeight }}>
            <div className="udesk-qa-web-product-matrix-component-tooltip-header"></div>
            <div className="udesk-qa-web-product-matrix-component-tooltip-body">
                <Row>
                    {list.map((item, index) => {
                        return (
                            <Col className="gutter-row" span={span}>
                                <Card {...item} isLastRow={index >= list.length - lastRowCount} />
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </div>
    );
};

function ProductMatrix(props) {
    const { width = 1180, minHeight = 528 } = props;

    const { schema } = useSchema();

    return (
        <div className="udesk-qa-web-product-matrix-component">
            <Dropdown
                // visible={true}
                trigger={['click']}
                overlay={
                    <Menu>
                        <Matrix width={width} minHeight={minHeight} list={schema} />
                    </Menu>
                }
            >
                <Button icon={<Icon type="gongzuotai" />}>
                    GaussMind
                    <Icon type="DownOutlined" antdIcon={true} />
                </Button>
            </Dropdown>
        </div>
    );
}

export default ProductMatrix;
