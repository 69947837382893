import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';
import { CommonPage } from 'udesk_gm_ui';
import Page from 'Component/common/udesk-qa-page-layout';
import { Tabs } from 'udesk-ui';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    height: 100%;
    background-color: #fff;
    padding: 16px;
`;

class intelligentTags extends React.Component<any, any> {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { routes, route, location, sdkOptions } = this.props;
        return (
            <CommonPage.PageInfoMemo>
                {(() => {
                    if (this.isRedirect(route.path, location.pathname)) {
                        let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                            routeName: route.name,
                        });
                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else {
                        return (
                            <div className="udesk-qa-supervision" style={{ height: '100%' }}>
                                <div className="case-base-pages" style={{ height: '100%' }}>
                                    <Page
                                        pageBodyClassName="udesk-qa-sales-large-model-page-index"
                                        bodyStyle={{ height: '100%' }}
                                        title={/* 大模型生成 */UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.index.largeModelGeneration}
                                        padding={false}
                                        backGroundGhost={true}
                                        footer={
                                            <Tabs defaultActiveKey="1">
                                                <Tabs.TabPane tab={/* 会话洞察 */UdeskLocales['current'].pages.gong.businessConfiguration.largeModelGeneration.index.conversationInsights} key="1" />
                                            </Tabs>
                                        }
                                    >
                                        <Wrapper>
                                            {routes.map((routeItem, i) => (
                                                <SubRoutes key={i} route={routeItem} />
                                            ))}
                                        </Wrapper>
                                    </Page>
                                </div>
                            </div>
                        );
                    }
                })()}
            </CommonPage.PageInfoMemo>
        );
    }
}

export default Udesk.react.udeskify(intelligentTags);
