import React, { useCallback, useMemo } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';
// import styled from 'styled-components';
import { useScoreDetailContext } from '../Context';
import { Icon, Table, Tag } from 'udesk-ui';
import { ColumnsType } from 'udesk-ui/lib/table';
import { TraineeWordsResultWrapper } from 'src/api/types';
import { Text } from '../Text';
import UdeskLocales from 'UdeskLocales';
import { ScoreTitle } from 'Component/pages/components/coach/Score/ScoreTitle';

// {
//     nodeName: '节点名称',
//     wordsCompletion: 0, // 得分是否完整 0-不完整 1-完整
//     currentProcessNodeType: 2, // 节点类型  1-旁白 2-学员说话 3-机器人说话 4-知识库问答
//     knowLedgeScoreResult: null,
//     wordsScore: 3, // 话术得分
//     wordsViolationDeduction: 3, // 话术违法次数
//     sentimentValue: 0, // 情绪类型  -1:负向情绪 0-中性情绪 1:正向情绪
//     sentimentDeduction: 0, // 情绪扣分
//     speakSpeed: 2, // 语速类型 1:正常  2:过快  3:过慢
//     speakSpeedDeduction: 3, // 语速扣分
// },
export const Score = React.memo((props: any) => {
    // const locales = Locales['current'];
    const {
        dialogueMode,
        evaluationMode,
        scoreDetail = [],
        scoreRuleDetail = [],
        scoreOverView,
    } = useScoreDetailContext();
    const { onItemClick } = props;
    const { speechCraftScore, emotionScore, speedScore } = scoreOverView || {};

    const getData = useCallback(
        (defaultValue: any, overallPPTValue, singlePPTValue = defaultValue) => {
            if (dialogueMode === Udesk.enums.learningTaskDialogueModeType.ppt.id) {
                if (evaluationMode === Udesk.enums.pptEvaluationMode.whole.id) {
                    return overallPPTValue;
                } else {
                    return singlePPTValue;
                }
            }
            return defaultValue;
        },
        [dialogueMode, evaluationMode]
    );

    const title = useMemo(() => getData(/* 学员节点名称 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.studentNodeName, /* 要点名称 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.keyPointName, /* 幻灯片节点名称 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.slideNodeName), [getData]);
    const isOverAll = useMemo(() => getData(false, true), [getData]);
    const dataSource = useMemo(
        () => getData(scoreDetail, scoreRuleDetail),
        [getData, scoreDetail, scoreRuleDetail]
    );

    const columns: ColumnsType<TraineeWordsResultWrapper> = [
        {
            title,
            dataIndex: 'nodeName',
            render(value, record, index) {
                return (
                    <Text>
                        {[
                            {children: getData(/* 对话 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.dialogue, /* 要点 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.mainPoints, /* 幻灯片 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.slide) + (index + 1) + '：', className: 'font-grey'},
                            {children: getData(value, record.ruleName)},
                            {children: getData('', '（' + Udesk.enums.scoreRuleOperator.getName(record.operate) + record.score + '）')}
                        ]}
                    </Text>
                );
            },
        },
        {
            title: /* 检测结果 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.detectionResult,
            dataIndex: 'nodeId',
            render(value, record) {
                if (record.isHit) {
                    return (
                        <Tag icon={<Icon antdIcon={true} type={'CheckOutlined'} />} color="green">{/* 满足 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.satisfy}</Tag>
                    );
                }
                return (
                    <Tag icon={<Icon antdIcon={true} type={'CloseOutlined'} />} color="red">{/* 不满足 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.dissatisfaction}</Tag>
                );
            },
        },
        {
            title: /* 得分 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.score,
            dataIndex: 'nodeId',
            render(value, record) {
                let color = 'font-green';
                let score = record.score;

                if (record.isHit) {
                    if (record.operate === Udesk.enums.scoreRuleOperator.sub.id) {
                        color = 'font-red';
                    }
                } else {
                    score = 0;

                    if (record.operate === Udesk.enums.scoreRuleOperator.add.id) {
                        color = 'font-red';
                    }
                }
                return (
                    <span className={color}>
                        {Udesk.enums.scoreRuleOperator.getName(record.operate)}
                        {score}
                    </span>
                );
            },
        },
        {
            title: <ScoreTitle title={/* 话术 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.script} {...speechCraftScore} />,
            dataIndex: 'wordsScore',
            render(value, record, index) {
                if (record.wordsScore === null && !record.wordsViolationDeduction) return null;
                if (record?.knowLedgeScoreResult) {
                    return (
                        <Tag
                            color={!record.knowLedgeScoreResult.isHit ? 'gold' : 'green'}
                        >{/* 语义 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.semantics}{Udesk.enums.scoreRuleHit.getName(record.knowLedgeScoreResult.isHit ? 1 : 0)}
                            {!!record.knowLedgeScoreResult && ` +${record.knowLedgeScoreResult.score}`}
                        </Tag>
                    );
                }
                return (
                    <React.Fragment>
                        {record.wordsScore !== null && typeof record.wordsCompletion === 'boolean' && (
                            <Tag color={!record.wordsCompletion ? 'gold' : 'green'}>
                                {Udesk.enums.wordsCompletions.getName(record.wordsCompletion)}
                                {!!record.wordsScore && ` +${record.wordsScore}`}
                            </Tag>
                        )}
                        {!!record.wordsViolationDeduction && (
                            <Tag color="red">{/* 话术违规 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.scriptViolation}{`-${record.wordsViolationDeduction}`}</Tag>
                        )}
                    </React.Fragment>
                );
            },
        },
        {
            title: <ScoreTitle title={/* 情绪 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.emotion} {...emotionScore} />,
            dataIndex: 'sentimentDeduction',
            render(value, record, index) {
                if (record.sentimentDeduction === null) return null;
                return (
                    <Tag
                        color={
                            record.sentimentValue === Udesk.enums.sentimentValues.negative.id
                                ? 'gold'
                                : 'green'
                        }
                    >
                        {Udesk.enums.sentimentValues.getName(record.sentimentValue)}
                        {!!record.sentimentDeduction && ` -${record.sentimentDeduction}`}
                    </Tag>
                );
            },
        },
        {
            title: <ScoreTitle title={/* 表达 */UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.score.index.expression} {...speedScore} />,
            dataIndex: 'speakSpeedDeduction',
            render(value, record, index) {
                return (
                    <Tag
                        color={
                            record.speakSpeed === Udesk.enums.speakSpeeds.neutral.id
                                ? 'green'
                                : 'gold'
                        }
                    >
                        {Udesk.enums.speakSpeeds.getName(record.speakSpeed)}
                        {!!record.speakSpeedDeduction && ` -${record.speakSpeedDeduction}`}
                    </Tag>
                );
            },
        },
    ].filter((item, index) => {
        if (isOverAll) {
            return index < 3;
        } else {
            return ![1, 2].includes(index);
        }
    });
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onRow={(record) => {
                return {
                    onClick: (event) => {
                        onItemClick?.(record.nodeId, scoreDetail.indexOf(record));
                    }, // 点击行
                };
            }}
        />
    );
});
