import React from 'react';
import Udesk from 'Udesk';
import DialogueManageDetailRoute from './route';
import './style.scss';

import { Layout, PageHeader } from 'udesk-ui';
import MiniListManage from '../../components/mini-list-manage-info';
import RecordDetail from '../../components/record-detail';
const { Sider, Content } = Layout;

export default class DialogueManageDetailTemplate extends DialogueManageDetailRoute {
    render() {
        let { pageConfig, selectedDatum, selectedFilter, layoutSiderRef, collapsed, scrollFlag, manageRef } =
            this.privates;
        let { actions, locales } = this;
        const siderAction = (
            <div className='layout-menu-pages-sider-actions-arrow'>
                <i class='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-zhankaitixing'></i>
                {!collapsed ? (
                    <i class='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-left-s-line'></i>
                ) : (
                    <i class='udesk-qa-react-web-iconfont icon-udesk-qa-react-web-ic-arrow-right-s-youjiantou'></i>
                )}
            </div>
        );
        return (
            // <div className="row qa-react-page call-manage-detail-page">
            // <div className='udesk-qa-web-page'>
            //     <div className='udesk-qa-web-page-body'>
            //         <div className='udesk-qa-web-page-body-root call-manage-detail-page'>
            //             <div className="qa-react-page-left-part call-manage-detail-page-left-part col-md-2 col-sm-3 col-xs-12">
            //                 <MiniListManage manageTitle={locales.components.pages.dialogueManage.listTitle} pageConfig={pageConfig} selectedDatum={selectedDatum} onSelectedDatumChanged={actions.changeSelectedDatum} selectedFilter={selectedFilter} type={Udesk.enums.filterTypes.dialogue.id} onBackToIndex={actions.backToIndexPage} onPageChanged={actions.onPageChanged} getPageConfig={actions.getPageConfig} />
            //             </div>
            //             <div className="qa-react-page-right-part call-manage-detail-page-right-part col-md-10 col-sm-9 col-xs-12">
            //                 <div className="qa-react-call-manage-detail-title">
            //                     <span className="qa-react-call-manage-detail-span" onClick={actions.backToIndexPage}>
            //                         <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jiantou2"></i>
            //                         {locales.components.pages.dialogueManage.detailTitle}
            //                     </span>
            //                 </div>
            //                 <RecordDetail keywords={selectedFilter.keyword} selectedDatum={selectedDatum} onTransitionToTemplate={actions.transitionToTemplate} inspectDataSource={Udesk.enums.inspectDataSources.textDialogue.id} onSeriesClicked={actions.transitionToSentimentAnalysis} />
            //             </div>
            //         </div>
            //     </div>
            // </div>
            <div className='layout-menu-pages'>
                <Layout style={{ height: '100%' }}>
                    <Sider
                        ref={layoutSiderRef}
                        collapsed={collapsed}
                        width={260}
                        collapsedWidth={1}
                        collapsible
                        className='layout-menu-pages-sider'
                        onCollapse={actions.siderActionsChange}
                        trigger={siderAction}
                    >
                        <MiniListManage
                            manageTitle={locales.components.pages.dialogueManage.listTitle}
                            pageConfig={pageConfig}
                            selectedDatum={selectedDatum}
                            onSelectedDatumChanged={actions.changeSelectedDatum}
                            selectedFilter={selectedFilter}
                            type={Udesk.enums.filterTypes.dialogue.id}
                            onBackToIndex={actions.backToIndexPage}
                            onPageChanged={actions.onPageChanged}
                            getPageConfig={actions.getPageConfig}
                        />
                    </Sider>
                    <Content style={{ background: 'rgba(240,242,245,1)' }}>
                        <div className='udesk-qa-web-page'>
                            <PageHeader
                                className='udesk-qa-web-page-header'
                                onBack={actions.backToIndexPage}
                                title={locales.components.pages.dialogueManage.detailTitle}
                            />
                            <div
                                className='udesk-qa-web-page-body'
                                onScrollCapture={actions.handleScroll}
                                ref={manageRef}
                            >
                                <div className='udesk-qa-web-page-body-root'>
                                    <RecordDetail
                                        scrollFlag={scrollFlag}
                                        keywords={selectedFilter.keyword}
                                        keywordType={selectedFilter.keywordType}
                                        selectedDatum={selectedDatum}
                                        onTransitionToTemplate={actions.transitionToTemplate}
                                        inspectDataSource={Udesk.enums.inspectDataSources.textDialogue.id}
                                        onSeriesClicked={actions.transitionToSentimentAnalysis}
                                    />
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}
