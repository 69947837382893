// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostInspectionHomePageQueryAgentTopBody,
    BaseResponseHomePageAgentTopResponse,
} from '../../types';

/**
 * queryAgentTop
 *
 * @export
 * @tags inspection-home-page-controller
 * @link [POST] /inspectionHomePage/queryAgentTop
 */
export function postInspectionHomePageQueryAgentTop(
    data: PostInspectionHomePageQueryAgentTopBody
): Promise<BaseResponseHomePageAgentTopResponse>;
export function postInspectionHomePageQueryAgentTop(
    data: PostInspectionHomePageQueryAgentTopBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseHomePageAgentTopResponse>;
export function postInspectionHomePageQueryAgentTop(
    data: PostInspectionHomePageQueryAgentTopBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseHomePageAgentTopResponse> {
    return request<PostInspectionHomePageQueryAgentTopBody, never, never>(
        '/inspectionHomePage/queryAgentTop',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postInspectionHomePageQueryAgentTop',
        }
    );
}

export const meta = [
    {
        tags: ['inspection-home-page-controller'],
        path: '/inspectionHomePage/queryAgentTop',
        method: 'post',
    },
];
