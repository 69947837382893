import TemplateComponentsListComponent from './component';
import Udesk from 'Udesk';

class TemplateComponentsListRoute extends TemplateComponentsListComponent {
    asyncModel() {
        let { sdkOptions } = this.props;
        let { paging, dataType } = this.privates;
        let data = {
            pageNum: paging.pageNum,
            pageSize: paging.pageSize,
            sourceType: 1, // 1： 亚马逊  2： youtube
            dataType: dataType,
        };
        this.privates.disabled = true;
        // this.actions.update();
        let taskUrl = Udesk.business.apiPath.concatApiPath(`voc/comment`, sdkOptions);
        let taskPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(taskUrl, data).then(
                (resp) => {
                    resolve(resp);
                    this.privates.disabled = false;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    this.privates.disabled = false;
                    this.actions.update();
                    reject(reason);
                }
            );
        });
        return {
            taskData: taskPromise,
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}

export default TemplateComponentsListRoute;
