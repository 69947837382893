import React from 'react';
import { TreeSelect } from 'udesk-ui';

export default React.memo((props: any) => {
    const {
        value = {
            category: [],
            point: []
        }, 
        treeData,
        onChange,
        ...otherValue
    } = props;

    const formatValue = (value) => {
        return [].concat(
            value.category.map((id) => `category-${id}`),
            value.point.map((id) => `point-${id}`)
        );
    };

    const changeHandle = (data) => {
        const value = {
            category: [],
            point: []
        };

        data.forEach((key) => {
            const [, attr, id] = /^(.*)-(\d*)$/.exec(key) || [];
            if (attr) {
                value[attr].push(+id);
            }
        });
        onChange(value); 
    };

    return (
        <TreeSelect
            multiple
            treeCheckable
            value={formatValue(value)}
            onChange={changeHandle}
            treeNodeFilterProp="title"
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
            treeData={treeData}
            {...otherValue}
        />
    );
});
