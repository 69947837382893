import Udesk from 'Udesk';
import React from 'react';
import GeneralInspectionReportRoute from './route';
import {
    Radio,
    Select,
    Button,
    Divider,
    Table,
    Row,
    Col,
    Card,
    Input,
    Spin,
    PageHeader,
    Tooltip,
    Icon,
    Space,
} from 'udesk-ui';
import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import _size from 'lodash-es/size';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import ClassNames from 'classnames';
import UdeskDatePicker from 'Component/common/udesk-date-picker';

export default class GeneralInspectionReportTemplate extends GeneralInspectionReportRoute {
    render() {
        let { actions, locales } = this;
        let { TextArea } = Input;
        let { callList, echarsType } = this.privates;
        let {
            radioValue,
            loading,
            textAreaText,
            echarsTypeField,
            reultMore,
            reultDataList,
            reultInfoFlag,
            effectDataListEcharts,
            agentTableRef,
            agentDataList,
            agentColumns,
            effectListData,
            lineDataList,
            columns,
            effectTableData,
            statAt,
            taskDataList,
            taskId,
            proposalData,
            callListData,
            dialogListData,
        } = this.privates;
        let { Option } = Select;
        let list = Udesk.business.report.getChartDataFix(this, lineDataList);
        let effectList = Udesk.business.report.getChartDataFix(this, effectDataListEcharts);
        const company = Udesk.data.init.company;
        return (
            // <div className="general-inspection-report-page">
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={this.locales.components.pages.dataAnalysis.generalInspecitionReport}
                />
                <div className="udesk-qa-web-page-body">
                    <div
                        className="udesk-qa-web-page-body-root general-inspection-report-page"
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <div className="general-inspection-report-page-filter">
                            <span>
                                {locales.fix.datePickerSelect}：{' '}
                                <UdeskDatePicker onChange={actions.timeChange} value={statAt} />
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                                {locales.fix.callSelect}：
                                <Radio.Group
                                    disabled={
                                        !(
                                            company.enabledCallInspect !== 0 &&
                                            company.enabledImInspect !== 0
                                        )
                                    }
                                    onChange={actions.radioGroup}
                                    value={radioValue}
                                >
                                    {!_isEmpty(callList) &&
                                        callList.map((item) => {
                                            return <Radio value={item.key}>{item.name}</Radio>;
                                        })}
                                </Radio.Group>
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                                {locales.fix.taskSelect}：
                                <Select
                                    style={{ width: '130px' }}
                                    value={taskId}
                                    onChange={actions.taskFieldChange}
                                >
                                    {!_isEmpty(taskDataList) &&
                                        taskDataList.map((item) => {
                                            return (
                                                <Option
                                                    value={item.qa_inspection__task_id}
                                                    title={item.qa_inspection__task_name}
                                                >
                                                    {item.qa_inspection__task_name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </span>
                            <span style={{ marginLeft: '15px' }}>
                                <Button type="primary" onClick={actions.dataSave}>
                                    {locales.fix.genReport}
                                </Button>
                                <Button style={{ marginLeft: '5px' }} onClick={actions.dataCancel}>
                                    {locales.labels.reset}
                                </Button>
                                <Button style={{ marginLeft: '5px' }} onClick={actions.dataExport}>
                                    {locales.labels.export}
                                </Button>
                            </span>
                        </div>
                        <Spin spinning={loading}>
                            <div className="general-inspection-report-page-data">
                                <h2 className="general-list-title">
                                    <Space>
                                        {locales.fix.dataSource}
                                        <Tooltip
                                            title={
                                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                                    {
                                                        this.locales.components.pages.dataAnalysis
                                                            .generalInspecitionReportTip
                                                    }
                                                </div>
                                            }
                                        >
                                            <Icon
                                                type="QuestionCircleOutlined"
                                                antdIcon={true}
                                            ></Icon>
                                        </Tooltip>
                                    </Space>
                                </h2>
                                <div className="general-inspection-report-page-data-list">
                                    {!_isEmpty(callListData) && (
                                        <div className="general-inspection-report-page-data-list-info">
                                            <div className="general-inspection-report-page-data-list-info-left">
                                                <h2>{locales.fix.callsTotal}</h2>
                                                <div>
                                                    {callListData.qa_inspection_generic__call_count >
                                                    9999
                                                        ? `${(
                                                              callListData.qa_inspection_generic__call_count /
                                                              1000
                                                          ).toFixed(1)}K`
                                                        : `${callListData.qa_inspection_generic__call_count}`}
                                                </div>
                                            </div>
                                            <div className="general-inspection-report-page-data-list-info-right">
                                                <h2>{locales.fix.intelligentQualityInspection}</h2>
                                                <div>
                                                    {callListData.qa_inspection_generic__total_inspection_count >
                                                    9999
                                                        ? `${(
                                                              callListData.qa_inspection_generic__total_inspection_count /
                                                              1000
                                                          ).toFixed(1)}K`
                                                        : `${callListData.qa_inspection_generic__total_inspection_count}`}
                                                </div>
                                                <p>
                                                    {locales.fix.percentage}：
                                                    <span>
                                                        {
                                                            callListData.qa_inspection_generic__total_inspection_rate
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="general-inspection-report-page-data-list-info-right">
                                                <h2>{locales.fix.artificialSampling}</h2>
                                                <div>
                                                    {callListData.qa_inspection_generic__extraction_count >
                                                    9999
                                                        ? `${(
                                                              callListData.qa_inspection_generic__extraction_count /
                                                              1000
                                                          ).toFixed(1)}K`
                                                        : `${callListData.qa_inspection_generic__extraction_count}`}
                                                </div>
                                                <p>
                                                    {locales.fix.percentage}：
                                                    <span>
                                                        {
                                                            callListData.qa_inspection_generic__extraction_rate
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="general-inspection-report-page-data-list-info-right">
                                                <h2>{locales.fix.appealNumber}</h2>
                                                <div>
                                                    {callListData.qa_inspection_generic__appeal_count >
                                                    9999
                                                        ? `${(
                                                              callListData.qa_inspection_generic__appeal_count /
                                                              1000
                                                          ).toFixed(1)}K`
                                                        : `${callListData.qa_inspection_generic__appeal_count}`}
                                                </div>
                                                <p>
                                                    {locales.fix.percentage}：
                                                    <span>
                                                        {
                                                            callListData.qa_inspection_generic__appeal_rate
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="general-inspection-report-page-data-list-info-right">
                                                <h2>{locales.fix.samplingReviewRate}</h2>
                                                <div>
                                                    {
                                                        callListData.qa_inspection_generic__manual_check_rate
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!_isEmpty(callListData) && !_isEmpty(dialogListData) && (
                                        <Divider />
                                    )}
                                    {!_isEmpty(dialogListData) && (
                                        <div className="general-inspection-report-page-data-list-info">
                                            <div className="general-inspection-report-page-data-list-info-left info-text">
                                                <h2>{locales.fix.totalConversation}</h2>
                                                <div>
                                                    {dialogListData.qa_inspection_generic__dialog_count >
                                                    9999
                                                        ? `${(
                                                              dialogListData.qa_inspection_generic__dialog_count /
                                                              1000
                                                          ).toFixed(1)}K`
                                                        : `${dialogListData.qa_inspection_generic__dialog_count}`}
                                                </div>
                                            </div>
                                            <div className="general-inspection-report-page-data-list-info-right">
                                                <h2>{locales.fix.intelligentQualityInspection}</h2>
                                                <div>
                                                    {dialogListData.qa_inspection_generic__total_inspection_count >
                                                    9999
                                                        ? `${(
                                                              dialogListData.qa_inspection_generic__total_inspection_count /
                                                              1000
                                                          ).toFixed(1)}K`
                                                        : `${dialogListData.qa_inspection_generic__total_inspection_count}`}
                                                </div>
                                                <p>
                                                    {locales.fix.percentage}：
                                                    <span>
                                                        {
                                                            dialogListData.qa_inspection_generic__total_inspection_rate
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="general-inspection-report-page-data-list-info-right">
                                                <h2>{locales.fix.artificialSampling}</h2>
                                                <div>
                                                    {dialogListData.qa_inspection_generic__extraction_count >
                                                    9999
                                                        ? `${(
                                                              dialogListData.qa_inspection_generic__extraction_count /
                                                              1000
                                                          ).toFixed(1)}K`
                                                        : `${dialogListData.qa_inspection_generic__extraction_count}`}
                                                </div>
                                                <p>
                                                    {locales.fix.percentage}：
                                                    <span>
                                                        {
                                                            dialogListData.qa_inspection_generic__extraction_rate
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="general-inspection-report-page-data-list-info-right">
                                                <h2>{locales.fix.appealNumber}</h2>
                                                <div>
                                                    {dialogListData.qa_inspection_generic__appeal_count >
                                                    9999
                                                        ? `${(
                                                              dialogListData.qa_inspection_generic__appeal_count /
                                                              1000
                                                          ).toFixed(1)}K`
                                                        : `${dialogListData.qa_inspection_generic__appeal_count}`}
                                                </div>
                                                <p>
                                                    {locales.fix.percentage}：
                                                    <span>
                                                        {
                                                            dialogListData.qa_inspection_generic__appeal_rate
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="general-inspection-report-page-data-list-info-right">
                                                <h2>{locales.fix.samplingReviewRate}</h2>
                                                <div>
                                                    {
                                                        dialogListData.qa_inspection_generic__manual_check_rate
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!_isEmpty(list.items) && !_isEmpty(list.columns) && (
                                    <div className="general-inspection-report-page-data-line">
                                        <div className="general-inspection-echarsType">
                                            {!_isEmpty(echarsType) &&
                                                echarsType.map((item) => {
                                                    return (
                                                        <div
                                                            className={ClassNames({
                                                                'general-inspection-echarsType-active':
                                                                    echarsTypeField === item.key,
                                                            })}
                                                            onClick={actions.echarsTypeChange.params(
                                                                item.key
                                                            )}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    );
                                                })}
                                        </div>

                                        <ReactEcharts
                                            type="line"
                                            xLabelField="xLabel"
                                            height={350}
                                            showTooltips={true}
                                            finalizeChartOptions={(option) => {
                                                option.legend = {
                                                    ...option.legend,
                                                    left: 'center',
                                                };
                                                option.tooltip = {
                                                    trigger: 'axis',
                                                    formatter: (params) => {
                                                        let str = '';
                                                        str += params[0].data.name + '<br />';
                                                        params.forEach((item) => {
                                                            if (item.seriesIndex === 2) {
                                                                if (
                                                                    item.value.indexOf('%') === -1
                                                                ) {
                                                                    item.value = item.value + '%';
                                                                }
                                                            }
                                                            str +=
                                                                item.seriesName +
                                                                '：' +
                                                                item.value +
                                                                '<br />';
                                                        });
                                                        return str;
                                                    },
                                                };
                                                if (option.series.length > 0) {
                                                    option.series[0].type = 'bar';
                                                    if (option.series.length > 1) {
                                                        option.series[0].stack = locales.fix.data;
                                                        option.series[1].stack = locales.fix.data;
                                                        option.series[1].type = 'bar';
                                                    }
                                                    if (option.series.length > 2) {
                                                        option.series[2].data.forEach((item) => {
                                                            item.value = (item.value * 100).toFixed(
                                                                2
                                                            );
                                                        });
                                                    }
                                                }
                                                return option;
                                            }}
                                            records={list.items}
                                            seriesColumns={list.columns}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="general-inspection-report-page-effect">
                                <h2 className="general-list-title">
                                    {locales.fix.inspectionEffect}
                                </h2>
                                <div className="general-inspection-report-page-effect-list">
                                    {!_isEmpty(effectListData) && (
                                        <div className="general-inspection-report-page-effect-list-info">
                                            <div className="general-inpsection-report-page-effect-list-info-field">
                                                <h2>{locales.fix.allTotal}</h2>
                                                <p className="field-top-p-style1">
                                                    {locales.fix.artificialSampling}
                                                </p>
                                                <div class="field-big-effect-sp">
                                                    {effectListData.qa_inspection_effects__check_count >
                                                    9999
                                                        ? `${(
                                                              effectListData.qa_inspection_effects__check_count /
                                                              1000
                                                          ).toFixed(1)}`
                                                        : `${
                                                              effectListData.qa_inspection_effects__check_count ??
                                                              0
                                                          }`}
                                                </div>
                                            </div>
                                            <div className="general-inpsection-report-page-effect-list-info-field">
                                                <p className="field-top-p-style2">
                                                    {locales.fix.manualInspection}
                                                </p>
                                                <div class="field-big-effect-rew">
                                                    {effectListData.qa_inspection_effects__review_approval_count >
                                                    9999
                                                        ? `${(
                                                              effectListData.qa_inspection_effects__review_approval_count /
                                                              1000
                                                          ).toFixed(2)}K`
                                                        : `${
                                                              effectListData.qa_inspection_effects__review_approval_count ??
                                                              0
                                                          }`}
                                                </div>
                                            </div>
                                            <div className="general-inpsection-report-page-effect-list-info-field">
                                                <h2>{locales.fix.approvalAmountAndRate}</h2>
                                                <p className="field-top-p-style1">
                                                    {locales.fix.artificialSampling}
                                                </p>
                                                <div class="field-small-effect">
                                                    {effectListData.qa_inspection_effects__extraction_count >
                                                    9999
                                                        ? `${(
                                                              effectListData.qa_inspection_effects__extraction_count /
                                                              1000
                                                          ).toFixed(1)}`
                                                        : `${
                                                              effectListData.qa_inspection_effects__extraction_count ??
                                                              0
                                                          }`}
                                                </div>
                                                <p>
                                                    {locales.fix.percentage}：
                                                    <span>
                                                        {effectListData.qa_inspection_effects__extraction_rate ??
                                                            0}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="general-inpsection-report-page-effect-list-info-field">
                                                <p className="field-top-p-style2">
                                                    {locales.fix.artificialReview}
                                                </p>
                                                <div class="field-small-effect">
                                                    {effectListData.qa_inspection_effects__review_count >
                                                    9999
                                                        ? `${(
                                                              effectListData.qa_inspection_effects__review_count /
                                                              1000
                                                          ).toFixed(1)}`
                                                        : `${
                                                              effectListData.qa_inspection_effects__review_count ??
                                                              0
                                                          }`}
                                                </div>
                                                <p>
                                                    {locales.fix.percentage}：
                                                    <span>
                                                        {effectListData.qa_inspection_effects__review_approval_rate ??
                                                            0}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {!_isEmpty(effectList.items) && !_isEmpty(effectList.columns) && (
                                        <div className="general-inspection-report-page-effect-line">
                                            <ReactEcharts
                                                type="line"
                                                xLabelField="xLabel"
                                                height={320}
                                                showTooltips={true}
                                                finalizeChartOptions={(option) => {
                                                    option.legend = {
                                                        ...option.legend,
                                                        left: 'center',
                                                    };

                                                    option.tooltip = {
                                                        trigger: 'axis',
                                                        formatter: (params) => {
                                                            let str = '';
                                                            str += params[0].data.name + '<br />';
                                                            params.forEach((item) => {
                                                                if (
                                                                    item.value.indexOf('%') === -1
                                                                ) {
                                                                    item.value = item.value + '%';
                                                                }
                                                                str +=
                                                                    item.seriesName +
                                                                    '：' +
                                                                    item.value +
                                                                    '<br />';
                                                            });
                                                            return str;
                                                        },
                                                    };
                                                    option.series.forEach((item) => {
                                                        item.data.forEach((items) => {
                                                            items.value = (
                                                                items.value * 100
                                                            ).toFixed(2);
                                                        });
                                                    });
                                                    return option;
                                                }}
                                                records={effectList.items}
                                                seriesColumns={effectList.columns}
                                            />
                                        </div>
                                    )}
                                    <div className="general-inspection-report-page-effect-table">
                                        <Table
                                            columns={columns}
                                            dataSource={effectTableData}
                                            pagination={false}
                                            scroll={{ y: 406 }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="general-inspection-report-page-result">
                                <h2 className="general-list-title">
                                    {locales.fix.inspectionResults}
                                </h2>
                                <div className="general-inpection-report-page-result-list">
                                    {!_isEmpty(reultDataList) &&
                                        reultDataList.map((item, index) => {
                                            return (
                                                <div
                                                    className={ClassNames(
                                                        'general-inspection-report-page-result-list-info',
                                                        {
                                                            'result-list-info':
                                                                index > 1 && reultMore,
                                                        }
                                                    )}
                                                >
                                                    <div className="result-list-info-title">
                                                        <h2>
                                                            <span className="result-list-info-title-name">
                                                                {item.name}
                                                            </span>
                                                            <span className="result-list-info-title-margin">
                                                                {locales.fix.inspected}{' '}
                                                                <span>{item.count}</span>
                                                                {locales.labels.unit}
                                                                {item.dataSource === '1' &&
                                                                    locales.fix.call}
                                                                {item.dataSource === '2' &&
                                                                    locales.fix.conversation}
                                                                {locales.fix.data}
                                                            </span>
                                                        </h2>
                                                        <div>
                                                            {locales.fix.totalScore}：
                                                            <span className="result-list-info-title-name">
                                                                {item.score}
                                                            </span>
                                                            {locales.labels.scoreUnit}
                                                        </div>
                                                    </div>
                                                    <div className="result-list-info-list-data">
                                                        <Row gutter={16}>
                                                            {!_isEmpty(item.categoryList) &&
                                                                item.categoryList.map(
                                                                    (items, index) => {
                                                                        return (
                                                                            <Col
                                                                                span={6}
                                                                                style={{
                                                                                    marginBottom:
                                                                                        '10px',
                                                                                }}
                                                                                className={ClassNames(
                                                                                    {
                                                                                        'result-info-more':
                                                                                            index >
                                                                                                7 &&
                                                                                            reultInfoFlag[
                                                                                                item
                                                                                                    .taskId
                                                                                            ],
                                                                                    }
                                                                                )}
                                                                            >
                                                                                <Card
                                                                                    title={
                                                                                        items.name
                                                                                    }
                                                                                    size="small"
                                                                                    bordered={false}
                                                                                    extra={
                                                                                        items.score
                                                                                    }
                                                                                >
                                                                                    <div className="result-list-info-list-data-Card">
                                                                                        <div className="result-list-info-list-dat-Card-absolute"></div>

                                                                                        {!_isEmpty(
                                                                                            items.points
                                                                                        ) &&
                                                                                            items.points.map(
                                                                                                (
                                                                                                    itemss
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <div className="result-list-info-list-data-Card-info">
                                                                                                            <div
                                                                                                                className="result-list-info-list-data-info-left"
                                                                                                                title={
                                                                                                                    itemss.name
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    itemss.name
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="result-list-info-list-data-info-right">
                                                                                                                {
                                                                                                                    itemss.score
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </div>
                                                                                </Card>
                                                                            </Col>
                                                                        );
                                                                    }
                                                                )}
                                                        </Row>
                                                    </div>
                                                    {_size(item.categoryList) > 8 &&
                                                        reultInfoFlag[item.taskId] && (
                                                            <div className="result-list-info-more">
                                                                <i
                                                                    className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-zhankai"
                                                                    onClick={actions.reultInfoChange.params(
                                                                        item.taskId
                                                                    )}
                                                                ></i>
                                                            </div>
                                                        )}
                                                </div>
                                            );
                                        })}
                                </div>
                                {_size(reultDataList) > 2 && reultMore && (
                                    <div className="result-list-more">
                                        <span onClick={actions.reultMoreChange}>
                                            {locales.fix.learnMore}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="general-inspection-report-page-agent">
                                <h2 className="general-list-title">{locales.fix.customerScore}</h2>
                                <div className="general-inspection-report-page-agent-list">
                                    <div
                                        id="agent-table"
                                        style={{
                                            width: `${
                                                (document.documentElement.clientWidth - 60) * 0.83 -
                                                30 -
                                                160 -
                                                20
                                            }px`,
                                        }}
                                        ref={agentTableRef}
                                        className="general-inspection-report-page-agent-list-table"
                                    >
                                        <Table
                                            columns={agentColumns}
                                            dataSource={agentDataList}
                                            pagination={false}
                                            scroll={{ x: _size(agentColumns) * 200 + 100, y: 406 }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="general-inspection-report-page-proposal">
                                <h2 className="general-list-title">
                                    {locales.fix.inspectionRecommendations}
                                </h2>
                                <div className="general-inspection-report-page-proposal-list">
                                    <div className="general-inspection-report-page-proposal-list-textarea">
                                        <TextArea
                                            autosize={{ minRows: 7, maxRows: 10 }}
                                            allowClear={true}
                                            value={textAreaText}
                                            placeholder={locales.fix.advicePlaceHolder}
                                            onChange={actions.textAreaChange}
                                        />
                                        <div className="proposal-list-button">
                                            <Button type="primary" onClick={actions.textAreaSave}>
                                                {locales.fix.saveDraft}
                                            </Button>
                                            <Button
                                                style={{ marginLeft: '5px' }}
                                                onClick={actions.textAreaCancel}
                                            >
                                                {locales.labels.reset}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="general-inspection-report-page-proposal-list-history">
                                        <div className="general-inspection-report-page-proposal-list-history-list">
                                            <div className="general-inspection-report-page-proposal-list-history-list-title">
                                                {locales.fix.historicalAdvice}（
                                                {_size(proposalData)}）
                                            </div>
                                            <div className="general-inspection-report-page-proposal-list-history-list-info">
                                                {!_isEmpty(proposalData) &&
                                                    proposalData.map((item) => {
                                                        return (
                                                            <div className="proposal-list-history-list-info-field">
                                                                <div>
                                                                    <span className="field-title">
                                                                        {item.name}
                                                                    </span>
                                                                    <span className="field-time">
                                                                        {item.createTime}
                                                                    </span>
                                                                </div>
                                                                {!_isEmpty(item.inspectionRemark) &&
                                                                    item.inspectionRemark.map(
                                                                        (items) => {
                                                                            return (
                                                                                <div className="field-info-list">
                                                                                    <p className="field-info">
                                                                                        -{items}
                                                                                    </p>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                <Divider />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        );
    }
}
