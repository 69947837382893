import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import Locales from 'UdeskLocales';
interface CustomFilterBtnProps {
    conditionList?: any[];
    judgeStrategy?: number;
    customJudgeLogic?: string;
}

const CustomFilterBtn: React.FC<CustomFilterBtnProps> = (props) => {
    const locales = Locales['current'];

    const { conditionList, judgeStrategy, customJudgeLogic } = props;

    // 组织发请求用的筛选数据
    const getQueryConditionList = (conditionList) => {
        let queryConditionList = [];
        if (Array.isArray(conditionList) && conditionList.length > 0) {
            queryConditionList = Udesk.utils.object.deepCopy(conditionList);
        }
        return queryConditionList;
    };

    const queckListMang = () => {
        return (
            <div className="custom-filter-info-type">
                <div className="index-management-custom-filter-Info">
                    <div>
                        {locales.components.common.customFilter.customFilterCondition}：
                        {_isEmpty(conditionList) && locales.components.common.customFilter.none}
                    </div>
                    <div>
                        {!_isEmpty(conditionList) &&
                            fieldsFilterChange({
                                conditionList: getQueryConditionList(conditionList),
                            })}
                    </div>
                </div>
                <div>
                    {locales.components.common.customFilter.conditionRule}：
                    <span>
                        {judgeStrategy !== 3 &&
                            Udesk.enums.operatorRuleTypes.find(
                                (current) =>
                                    current.id ===
                                    (judgeStrategy || Udesk.enums.operatorRuleTypes.all.id)
                            ).name}
                        {judgeStrategy === 3 && customJudgeLogic}
                    </span>
                </div>
            </div>
        );
    };

    return <>{queckListMang()}</>;
};
export default CustomFilterBtn;

function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter((item) => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map((item) => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}

export function getInfoList(data) {
    let obj = Udesk.enums.fieldOperators;
    let list: string[] = [];

    data.conditionList.forEach((condition) => {
        const label = condition.field.label;
        const operator = obj.getName(condition.operator);
        const getValue = (item) =>
            ['realname', 'tagName', 'agentGroupName', 'agentName']
                .map((key) => item[key])
                .find((name) => !!name);
        let value = condition.value;

        if (value) {
            if (
                [
                    Udesk.enums.fieldTypes.sigleListbox.id,
                    Udesk.enums.fieldTypes.multiListbox.id,
                    Udesk.enums.fieldTypes.supervision.id,
                    Udesk.enums.fieldTypes.keyEvents.id,
                ].includes(condition.field.fieldType) &&
                condition.field.optionValueList &&
                condition.field.optionValueList.length > 0
            ) {
                value = getOptionValue(value, condition.field.optionValueList);
            } else if (Array.isArray(value)) {
                value = value.map(getValue).join();
            } else if (typeof value === 'object') {
                value = getValue(value);
            }
            list.push([label, operator, value].join(' '));
        } else {
            list.push([label, operator].join(' '));
        }
    });

    return list;
}

function fieldsFilterChange(data) {
    const list = getInfoList(data);
    return list.map((item, index) => {
        return (
            <p style={{ marginBottom: '3px' }}>
                {index + 1}. {item}
            </p>
        );
    });
}
