// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PutIntelligentPartnerScoringSessionDetectionByIdParam,
    PutIntelligentPartnerScoringSessionDetectionByIdBody,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 会话检测-修改检测配置
 *
 * @export
 * @tags 智能陪练-评分模型
 * @link [PUT] /intelligentPartner/scoring/session/detection/{id}
 * @param id
 */
export function putIntelligentPartnerScoringSessionDetectionById(
    data: PutIntelligentPartnerScoringSessionDetectionByIdBody,
    options: ApiOptions<PutIntelligentPartnerScoringSessionDetectionByIdParam, never> & {
        segments: PutIntelligentPartnerScoringSessionDetectionByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentPartnerScoringSessionDetectionByIdBody,
        PutIntelligentPartnerScoringSessionDetectionByIdParam,
        never
    >('/intelligentPartner/scoring/session/detection/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerScoringSessionDetectionById',
    });
}

export const meta = [
    {
        tags: ['智能陪练-评分模型'],
        path: '/intelligentPartner/scoring/session/detection/{id}',
        method: 'put',
    },
];
