let notification = {
    init,
    send
};

Object.defineProperty(notification, "isGranted", {
    get: function() {
        return window.Notification && Notification.permission === "granted";
    },
    enumerable: true,
    configurable: false
});

Object.defineProperty(notification, "isDefault", {
    get: function() {
        return window.Notification && Notification.permission === "default";
    },
    enumerable: true,
    configurable: false
});

Object.defineProperty(notification, "isDenied", {
    get: function() {
        return window.Notification && Notification.permission === "denied";
    },
    enumerable: true,
    configurable: false
});

export default notification;

function init(onDenied, onGranted) {
    _checkNotificationPermission(function() {
        if (typeof onGranted === "function") {
            onGranted();
        }
    }, function() {
        if (typeof onDenied === "function") {
            onDenied();
        } else {
            //alert("");
        }
    });
}

function send(title, options) {
    return _checkNotificationPermission(function() {
        options = Object.assign({
            //通知的具体内容
            body: null,
            //显示的位置
            dir: "rtl",
            //语言
            lang: "en-ZH",
            //显示的icon
            //icon: null,
            //显示图片
            //image: null,
            //通知是否应该保持活跃，知道用户点击或者否否定它，而不是自动关闭
            requireInteraction: false,
            //新的通知是否取代旧的通知
            renotify: false,
            //指定时间创建适用的通知
            timestamp: null,
        }, options);
        return new Notification(title, options);
    }, function() {
        return null;
    });
}

function _checkNotificationPermission(onGranted, onDenied) {
    if (!("Notification" in window)) {
        // eslint-disable-next-line
        console.info("This browser does not support desktop notification");
        return onDenied();
    } else if (notification.isGranted) {
        return onGranted();
    } else if (notification.isDenied || notification.isDefault) {
        let result = null;
        Notification.requestPermission(function(permission) {
            if (permission === "granted") {
                result = onGranted();
            } else {
                result = onDenied();
            }
        });
        return result;
    } else {
        return onDenied();
    }
}