// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostBusinessAnalysisMomChangeBody,
    BaseResponseBusinessAnalysisMomChangeFoundResponseDto,
} from '../../types';

/**
 * momChange
 *
 * @export
 * @tags business-analysis-controller
 * @link [POST] /businessAnalysis/momChange
 */
export function postBusinessAnalysisMomChange(
    data: PostBusinessAnalysisMomChangeBody
): Promise<BaseResponseBusinessAnalysisMomChangeFoundResponseDto>;
export function postBusinessAnalysisMomChange(
    data: PostBusinessAnalysisMomChangeBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisMomChangeFoundResponseDto>;
export function postBusinessAnalysisMomChange(
    data: PostBusinessAnalysisMomChangeBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisMomChangeFoundResponseDto> {
    return request<PostBusinessAnalysisMomChangeBody, never, never>('/businessAnalysis/momChange', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postBusinessAnalysisMomChange',
    });
}

export const meta = [
    { tags: ['business-analysis-controller'], path: '/businessAnalysis/momChange', method: 'post' },
];
