// @ts-nocheck

import type { RequestResponse } from 'umi-request';
import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetSemanticTagDrawerQuery } from '../../types';

/**
 * getDrawer
 *
 * @export
 * @tags semantic-tag-controller
 * @link [GET] /semantic-tag/drawer
 */
export function getSemanticTagDrawer(
    options: ApiOptions<never, GetSemanticTagDrawerQuery> & { params: GetSemanticTagDrawerQuery }
): Promise<RequestResponse<void>> {
    return request<never, never, GetSemanticTagDrawerQuery>('/semantic-tag/drawer', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSemanticTagDrawer',
    });
}

export const meta = [
    { tags: ['semantic-tag-controller'], path: '/semantic-tag/drawer', method: 'get' },
];
