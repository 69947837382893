import React from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
import styled from 'styled-components';
import { userEnableSysModule } from 'Udesk/business';
import { isAlgorithm, isGeneralSetting, isSubApp } from 'Udesk/system/subApp';
import UdeskLocales from 'UdeskLocales';

const Card = styled.div`
    width: 128px;
    height: 128px;

    border-radius: 8px;
    /* background-color: #fff; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 4px;
    /* border: 1px solid #fff; */
    cursor: pointer;
    background: #fff;
    border: ${(props) => (props.isActive ? '1px solid #1a6eff' : '1px solid #fff')};

    &:hover {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.02));
    }

    img {
        width: 64px;
        margin-bottom: 8px;
    }
    div {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 22px;
    }
`;
const SubSystemEntryCard = (props) => {
    if (props.hidden) return null;

    return (
        <Card onClick={props.onClick} isActive={props.isActive}>
            <img src={props.icon} alt={props.appName} />
            <div className="appName" style={{ textTransform: 'capitalize' }}>
                {props.appName}
            </div>
        </Card>
    );
};

const Wrap = styled.div`
    padding: 20px 12px 16px;
    width: 296px;
`;
const Title = styled.div`
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
`;
const SubSystemList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const SubAppEntries = React.memo((props: any) => {
    // const locales = Locales['current'];
    const goToSubApp = (routeName) => {
        if (
            [
                Udesk.enums.sysModuleList.algorithmPlatform.routeName,
                Udesk.enums.sysModuleList.commonSetting.routeName,
            ].includes(routeName) &&
            !isAlgorithm() &&
            !isGeneralSetting()
        ) {
            sessionStorage.setItem('Udesk_qa_web_backUrl', props.history.location.pathname);
        }
        const routeOptions = {
            history: props.history,
            routeName,
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    return (
        <Wrap>
            <Title>
                {/* 应用切换 */}
                {UdeskLocales['current'].pages.home.components.subAppEntries.applicationSwitching}
            </Title>
            <SubSystemList>
                {Udesk.enums.sysModuleList.map((sys) => {
                    return (
                        <SubSystemEntryCard
                            appName={sys.name}
                            desc={
                                /* 智能质检系统描述 */ UdeskLocales['current'].pages.home.components
                                    .subAppEntries.descriptionOfIntelligentQualityInspectionSystem
                            }
                            icon={sys.icon}
                            onClick={goToSubApp.bind(null, sys.routeName)}
                            hidden={!userEnableSysModule(sys.id)()}
                            isActive={isSubApp(sys.nameSpace)()}
                        ></SubSystemEntryCard>
                    );
                })}
            </SubSystemList>
        </Wrap>
    );
});

export { SubAppEntries };
