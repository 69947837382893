import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStep } from './useStep';
import { message } from 'udesk-ui';
import { nodeWidth, nodeHeight } from '../const/node';
import { createNode } from '../utils/node';
import { state } from './useFlowState';
import { useNodesState, useEdgesState, addEdge, OnConnectStartParams } from 'reactflow';
import { lineStyle } from '../const/lineStyle';
import UdeskLocales from 'UdeskLocales';

export const useFlow = (
    initNodes: any,
    reactFlowWrapper: React.MutableRefObject<any>,
    setCurrentNode: React.Dispatch<any>
) => {
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const nodePositionPrev = useRef<any>(null);
    const [currentSelectedNode, setCurrentSelectedNode] = useState<any>(null);

    const { undoAble, redoAble, undo, redo, record } = useStep();

    useEffect(() => {
        const nodes = generateBackendNodesIds(initNodes);

        setNodes(generateFlowNodes(nodes));
        setEdges(generateFlowEdges(nodes));
    }, [initNodes]);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (type === 'undefined' || type === 'null' || !type) {
                return;
            }

            const typeObj = JSON.parse(type);

            const zoom = reactFlowInstance.getZoom();

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left - (nodeWidth * zoom) / 2,
                y: event.clientY - reactFlowBounds.top - (nodeHeight * zoom) / 2,
            });

            const newNode = createNode(typeObj, position);
            setNodes((nds) => nds.concat(newNode));

            // 记录创建节点的操作
            record({
                undo: () => {
                    setNodes([...nodes]);
                    setEdges([...edges]);
                },
                redo: () => {
                    setNodes([...nodes, newNode]);
                    setEdges([...edges]);
                },
            });
        },
        [reactFlowInstance, nodes, edges, record]
    );
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onNodeClick = (_, node) => {
        // 开始节点没有详情
        return new Promise((res, rej) => {
            if (node.data.type === 'START') {
                rej(0);
                return;
            }
            // 判断当前点击的是不是分支节点
            if (node.data.type === 'BRANCH') {
                // 如果是，就把当前节点所属的判断节点找出来
                const judgeNode = nodes.find(
                    (n) =>
                        (n.data.type === 'JUDGE_NODE' || n.data.type === 'DIALOG_NODE') &&
                        n.id === node.data.preId
                );
                setCurrentNode(judgeNode);
                // setCurrentSelectedNode(judgeNode);
            } else {
                // 如果不是 则可能是判断节点或者对话节点
                // TODO 需要区分判断节点或者对话节点，会白屏
                setCurrentNode(node);
                // setCurrentSelectedNode(node);
            }
            res(1);
        });
    };
    const onNodeDragStart = (event, node, nodes) => {
        nodePositionPrev.current = node.position;
    };
    const onNodeDragStop = (event, node, newNodes) => {
        const pos = nodePositionPrev.current;

        // 记录移动节点的操作
        record({
            undo: () => {
                setNodes(
                    nodes.map((n) => {
                        if (n.id === node.id) {
                            return { ...node, position: pos };
                        } else {
                            return n;
                        }
                    })
                );
                setEdges([...edges]);
            },
            redo: () => {
                setNodes(
                    nodes.map((n) => {
                        if (n.id === node.id) {
                            return node;
                        } else {
                            return n;
                        }
                    })
                );
                setEdges([...edges]);
            },
        });
    };

    const onNodesDelete = (delNodes) => {
        // 记录删除节点的操作
        record({
            undo: () => {
                setNodes([...nodes]);
                setEdges([...edges]);
            },
            redo: () => {
                setNodes(nodes.filter((n) => !delNodes.some((d) => d.id === n.id)));
                setEdges([...edges]);
            },
        });
    };

    const onConnect = useCallback(
        (params) => {
            const errors = verifyConnect(params, nodes, edges);
            if (errors.length) {
                errors.forEach((content) => {
                    message.error(content);
                });
            } else {
                const paramsWithArrow = {
                    ...params,
                    // animated: true,
                    type: 'smoothstep',
                    // markerEnd,
                    style: lineStyle,
                };
                paramsWithArrow.sourceHandle = undefined;
                paramsWithArrow.targetHandle = undefined;

                setNodes((prev) => {
                    return prev.map((node) => {
                        if (
                            node.id !== paramsWithArrow.source &&
                            node.id !== paramsWithArrow.target
                        ) {
                            return node;
                        } else {
                            node.data.handle = edges
                                .filter((e) => e.target === node.id || e.source === node.id)
                                .concat(paramsWithArrow);
                            return {
                                ...node,
                            };
                        }
                    });
                });
                Promise.resolve().then(() => {
                    let newEdges;
                    setEdges((eds) => {
                        newEdges = addEdge(paramsWithArrow, eds);
                        return newEdges;
                    });
                    // 记录创建边的操作
                    record({
                        undo: () => {
                            setEdges([...edges]);
                        },
                        redo: () => {
                            setEdges(newEdges);
                        },
                    });
                });

                // createBackEndNode(nodeNeedChange)
            }
        },
        [nodes, edges, record]
    );
    useEffect(() => {
        setNodes((prev) => {
            return prev.map((node) => {
                node.data.handle = edges.filter(
                    (e) => e.target === node.id || e.source === node.id
                );
                return {
                    ...node,
                    data: {
                        ...node.data,
                    },
                };
            });
        });
    }, [edges.length]);

    const onEdgesDelete = (delEdges) => {
        // 记录删除边的操作
        record({
            undo: () => {
                setEdges([...edges]);
            },
            redo: () => {
                setEdges(edges.filter((e) => !delEdges.some((d) => d.id === e.id)));
            },
        });
    };

    const onConnectStart = (_: any, params: OnConnectStartParams) => {
        // setConnecting(params);
        state.connecting.sourceId = params.nodeId || '';
        // setNodes((prev) => {
        //     const res = prev.map(n => {
        //         n.data.connecting = params;
        //         return {...n};
        //     });
        //     return res;
        // });
    };
    const onConnectEnd = () => {
        state.connecting.sourceId = '';
    };

    const onSelectionChange = ({ nodes: SelectionNodes, edges }) => {
        const node = SelectionNodes[0];
        if (!node) {
            setCurrentSelectedNode(null);
            return;
        }
        if (node.data.type === 'BRANCH') {
            // 如果是，就把当前节点所属的判断节点找出来
            const judgeNode = nodes.find(
                (n) =>
                    (n.data.type === 'JUDGE_NODE' || n.data.type === 'DIALOG_NODE') &&
                    n.id === node.data.preId
            );
            setCurrentSelectedNode(judgeNode);
        } else {
            // 如果不是 则可能是判断节点或者对话节点
            setCurrentSelectedNode(node);
        }
    };

    return {
        onNodesChange,
        onEdgesChange,
        onDrop,
        onNodeDragStop,
        onNodeDragStart,
        onNodesDelete,
        onDragOver,
        onNodeClick,
        onConnect,
        onConnectStart,
        onConnectEnd,
        onInit: setReactFlowInstance,
        reactFlowInstance,
        nodes,
        edges,
        setNodes,
        setEdges,
        currentSelectedNode,
        onSelectionChange,
        undoAble,
        redoAble,
        undo,
        redo,
        onEdgesDelete,
    };
};

function verifyConnect(params, nodes, edges) {
    let errors: string[] = [];
    // const sourceNode = nodes.find((n) => params.source === n.id);

    // const isJudgeNode = sourceNode?.data?.type === 'JUDGE_NODE';
    // if (isJudgeNode) {
    //     if(!Array.isArray(sourceNode?.data?.ruleList)) {
    //         errors.push('判断节点不能连接目标');
    //     }
    // }
    const isBranch = nodes.find((n) => params.target === n.id)?.data?.type === 'BRANCH';
    if (isBranch) {
        errors.push(
            /* 判断节点的子节点不能作为连接目标 */ UdeskLocales['current'].pages.tasks.manage
                .template.checkPoint.detail.components.flow.hooks.useFlow
                .determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets
        );
    }
    const isStart = nodes.find((n) => params.target === n.id)?.data?.type === 'START';
    if (isStart) {
        errors.push(
            /* 开始节点不能作为连接目标 */ UdeskLocales['current'].pages.tasks.manage.template
                .checkPoint.detail.components.flow.hooks.useFlow
                .theStartingNodeCannotBeUsedAsAConnectionTarget
        );
    }
    const startNodeHasNext = edges.filter((e) => e.source === params.source).length > 1;
    if (startNodeHasNext) {
        errors.push(
            /* 开始节点不能连接多个节点 */ UdeskLocales['current'].pages.tasks.manage.template
                .checkPoint.detail.components.flow.hooks.useFlow
                .startingNodeCannotConnectMultipleNodes
        );
    }
    if (params.source === params.target) {
        errors.push(
            /* 节点不能与自己相连接 */ UdeskLocales['current'].pages.tasks.manage.template
                .checkPoint.detail.components.flow.hooks.useFlow.nodeCannotBeConnectedToItself
        );
    }
    return errors;
}

function generateFlowNodes(backendNodes) {
    if (!backendNodes.length) {
        return [
            {
                id: `${Math.random()}`,
                type: 'cusNode',
                data: {
                    name: /* 开始节点 */ UdeskLocales['current'].pages.tasks.manage.template
                        .checkPoint.detail.components.flow.hooks.useFlow.startNode,
                    type: 'START',
                },
                position: { x: 250, y: 0 },
            },
        ];
    }
    return backendNodes.map((node) => {
        return {
            id: node.id,
            type: 'cusNode',
            data: node,
            position: node.position,
            parentNode: node.type === 'BRANCH' ? node.preId : undefined,
        };
    });
}
function generateFlowEdges(backendNodes) {
    return backendNodes.reduce((prev, cur) => {
        const sources = cur.preIdList;
        const target = cur.id;
        if (sources?.length) {
            sources.forEach((source) => {
                prev.push({
                    id: `${Math.random()}`,
                    source,
                    target,
                    type: 'smoothstep',
                    style: lineStyle,
                });
            });
        }
        return prev;
    }, []);
}

function generateBackendNodesIds(backendNodes) {
    const preIdMap = {};
    const preNodeTypeMap = {};
    const res = backendNodes.map((node) => {
        const id = `${Math.random()}`;
        preIdMap[node.nodeIndex] = id;
        preNodeTypeMap[node.nodeIndex] = node.type;
        return {
            ...node,
            id,
            _id: node.id,
        };
    });
    return res.map((n) => {
        return {
            ...n,
            preId: preIdMap[n.preIndex], // 只有当节点是分支节点时有用，用来查找父节点
            preIdList: n.preIndexList?.map((i) => preIdMap[i]), // 用来创建连线
            preNodeType: preNodeTypeMap[n.preIndex],
        };
    });
}
