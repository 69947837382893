// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListExternalUserNameFoundResponse } from '../../types';

/**
 * findAllUser
 *
 * @export
 * @tags external-user-controller
 * @link [GET] /externalUsers/all
 */
export function getExternalUsersAll(): Promise<BaseResponseListExternalUserNameFoundResponse>;
export function getExternalUsersAll(
    options: ApiOptions<never, never>
): Promise<BaseResponseListExternalUserNameFoundResponse>;
export function getExternalUsersAll(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListExternalUserNameFoundResponse> {
    return request<never, never, never>('/externalUsers/all', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getExternalUsersAll',
    });
}

export const meta = [
    { tags: ['external-user-controller'], path: '/externalUsers/all', method: 'get' },
];
