// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetCaseLibraryCategorysQuery,
    BaseResponseListCaseLibraryCategoryFoundResponse,
} from '../types';

/**
 * 案例库-列表
 *
 * @export
 * @tags 案例库同步
 * @link [GET] /caseLibraryCategorys
 */
export function getCaseLibraryCategorys(
    options: ApiOptions<never, GetCaseLibraryCategorysQuery> & {
        params: GetCaseLibraryCategorysQuery;
    }
): Promise<BaseResponseListCaseLibraryCategoryFoundResponse> {
    return request<never, never, GetCaseLibraryCategorysQuery>('/caseLibraryCategorys', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCaseLibraryCategorys',
    });
}

export const meta = [{ tags: ['案例库同步'], path: '/caseLibraryCategorys', method: 'get' }];
