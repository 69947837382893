import UploadBaseAdapterClass from 'udesk-web-toolkits/src/udesk/app/file-storage/adapters/base';
import Udesk from 'Udesk';

export default function initUploadAdapter() {
    for (let [, value] of Object.entries(Udesk.app.fileStorage.adapters)) {
        if (value instanceof UploadBaseAdapterClass) {
            value.loadPathData = (files, tokenData) => {
                let file = {};
                if (files instanceof Array) {
                    file = files[0];
                }
                let dir = tokenData && tokenData.dir;
                if (!dir) {
                    dir = 'unknown';
                }
                let url = `${dir}/${getCreateTimeName()}${randomString(10)}`;
                return {
                    rawUrl: `${url}/${file.name}`,
                    encodedUrl: `${url}/${file.name}`
                };
            };
        }
    }
}

function randomString(len) {
    len = len || 32;
    var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    var maxPos = chars.length;
    var pwd = "";
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

function getCreateTimeName() {
    //dir = document.getElementById("dirname").value;
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }

    return `${year}${month}${day}/`;
}
