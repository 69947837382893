import React, { useEffect, useState } from 'react';
import { Empty } from 'udesk-ui';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import ProgressComponent from './progress-component';
import moment from 'moment';

const stacticParams = {
    task_id: '-1',
    inspect_data_source: '-1',
    permission: 'all',
};

const Component = (props) => {
    const locales = Locales['current'];
    let { request } = props;

    const [getCheckRate, setGetCheckRate] = useState<any>(() => ({}));
    const [checkRateData, setCheckRateData] = useState<any>({});

    useEffect(() => {
        let params = {
            statAt: [moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'), moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59')],
            beginOfWeek: '1',
            endOfWeek: '7',
            ...stacticParams,
        };
        request(params).then((res) => {
            let checkRateData: any = {};
            let resp: any = formatChartData(res?.data?.data??{});
            if (resp.items && resp.items.length) {
                resp.columns.forEach((col: any) => {
                    let key = col.field.replace('qa_inspection_statistics_check_rate__', '');
                    if (key === 'sample_execute_time' || key === 'review_execute_time') {
                        if (resp.items[0][col.field].originalValue < 2) {
                            checkRateData[key] = (resp.items[0][col.field].originalValue * 60).toFixed(2);
                            checkRateData[`${key}_suffix`] = 'sec';
                        } else if (resp.items[0][col.field].originalValue < 2 * 60) {
                            checkRateData[key] = (resp.items[0][col.field].originalValue * 1).toFixed(2);
                            checkRateData[`${key}_suffix`] = 'min';
                        } else if (resp.items[0][col.field].originalValue < 2 * 60 * 24) {
                            checkRateData[key] = (resp.items[0][col.field].originalValue / 60).toFixed(2);
                            checkRateData[`${key}_suffix`] = 'hour';
                        } else if (resp.items[0][col.field].originalValue < 2 * 60 * 24 * 30) {
                            checkRateData[key] = (
                                resp.items[0][col.field].originalValue /
                                (60 * 24)
                            ).toFixed(2);
                            checkRateData[`${key}_suffix`] = 'day';
                        } else if (resp.items[0][col.field].originalValue < 2 * 60 * 24 * 30 * 12) {
                            checkRateData[key] = (
                                resp.items[0][col.field].originalValue /
                                (60 * 24 * 30)
                            ).toFixed(2);
                            checkRateData[`${key}_suffix`] = 'month';
                        } else {
                            checkRateData[key] = (
                                resp.items[0][col.field].originalValue /
                                (60 * 24 * 30 * 12)
                            ).toFixed(2);
                            checkRateData[`${key}_suffix`] = 'year';
                        }
                    } else {
                        checkRateData[key] = resp.items[0][col.field].originalValue;
                    }
                });
                setCheckRateData(checkRateData);
                setGetCheckRate(() => getCheckRateOptions.bind(null, checkRateData));
                
            }
        });
    }, []);
    return (
        <div className='operational-tatistics-box-right'>
            <div className='operational-tatistics-box-average-item'>
                <div className='operational-tatistics-box-title'>
                    {locales.pages.dashboard.operationalStatistics.titles.handCover}
                </div>
                <div className='operational-tatistics-box-content'>
                    <ProgressComponent
                        count={[checkRateData.extraction_count, checkRateData.check_count]}
                        desc={[
                            locales.pages.dashboard.operationalStatistics.checkCount,
                            locales.pages.dashboard.operationalStatistics.siphonCount,
                        ]}
                        percent={(checkRateData.sample_execute_rate * 100).toFixed(2)}
                        strokeColor='#E66A4F'
                        title={locales.pages.dashboard.operationalStatistics.checkRate}
                    />
                    <ProgressComponent
                        count={[checkRateData.appeal_count, checkRateData.review_count]}
                        desc={[
                            locales.pages.dashboard.operationalStatistics.reviewCount,
                            locales.pages.dashboard.operationalStatistics.appealCount,
                        ]}
                        percent={(checkRateData.review_execute_rate * 100).toFixed(2)}
                        strokeColor='#E66A4F'
                        title={locales.pages.dashboard.operationalStatistics.reviewRate}
                    />
                </div>
            </div>
            <div className='operational-tatistics-box-average-item'>
                <div className='operational-tatistics-box-title'>
                    {locales.pages.dashboard.operationalStatistics.titles.checkCover}
                </div>
                <div className='operational-tatistics-box-content'>
                    {getCheckRate ? (
                        <ReactEcharts
                            // ref={actions.setEchartsRefs}
                            type={Udesk.enums.echartTypes.column}
                            // xLabelField="xLabel"
                            enableDataZoom={true}
                            // height={320}
                            rerenderOptimization={true}
                            finalizeChartOptions={getCheckRate}
                        ></ReactEcharts>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Component;

function getCheckRateOptions(checkRateData: any, option) {
    let local = Locales['current'].pages.dashboard.operationalStatistics;
    option.tooltip = {
        trigger: 'axis',
        // position: setTooltipPosition,
    };
    option.color = ['#5E91F6', '#60D7A7'];
    // option.dataZoom = [{
    //     type: 'slider',
    //     bottom: 40,
    //     show: false,
    // }];
    option.yAxis = {
        type: 'value',
        axisLabel: {
            formatter: '{value}%'
        },
    };
    option.grid = {
        right: '16px',
        top: '16px',
        bottom: '32px',
    };
    // option.legend = {
    //     data: seriversItemsTitle,
    //     type: 'scroll',
    //     bottom: 10
    // };
    option.xAxis = {
        type: 'category',
        boundaryGap: true,
        data: [local.check_rate, local.voice_check_rate, local.text_check_rate,],
        splitLine: {
            show: false
        },
    };
    option.series = [{
        data: [checkRateData.check_rate, checkRateData.voice_check_rate, checkRateData.text_check_rate].map(item => (item * 100).toFixed(2)),
        type: 'bar',
        barMaxWidth: 35,
        showBackground: true,
        backgroundStyle: {
            color: 'rgba(220, 220, 220, 0.8)'
        }
    }];
    return option;
}

function formatChartData(values, selectedIndicators = [], fieldGroups = []) {
    const ORIGINAL = "_original";
    const TOOTIP = "_tootip";
    // const ID = '_id';
    // const PID = '_pid';

    let items: any[] = [];
    let columns: any[] = [];
    let tableColumns = [];
    let tableItems = [];
    if (values) {

        let dimensions = [];
        if (values.header && values.header.dimensions) {
            dimensions = values.header.dimensions;
        }

        let indicators: any[] = [];
        if (values.header && values.header.indicators && values.header.indicators) {
            indicators = values.header.indicators;
        }

        if (selectedIndicators.length > 0) {
            indicators = selectedIndicators.map(fieldKey => indicators.find(indicator => fieldKey === indicator.fieldKey)).filter(Boolean);
        }

        let rows = [];
        if (values && values.rows) {
            rows = values.rows;
        }

        if (indicators && indicators.length > 0) {
            indicators.forEach(indicator => {
                columns.push({
                    field: indicator.fieldKey,
                    name: indicator.fieldTitle || "",
                    id: indicator.id || null,
                    pid: indicator.pid || null,
                });
            });

            // 有维度，有指标。维度作为x轴，指标作为series。
            rows.forEach(row => {
                let item: any = {};
                let dimensionNames = [];
                let originalDimensionNames = [];
                dimensions.forEach((dimension: any) => {
                    let fieldKey = dimension.fieldKey;
                    let fieldKeyOriginal = fieldKey + ORIGINAL;
                    // let fieldKeyId = fieldKey + ID;
                    // let fieldKeyPid = fieldKey + PID;
                    if (row[fieldKey]) {
                        dimensionNames.push(row[fieldKey]);
                    }
                    if (row[fieldKeyOriginal]) {
                        originalDimensionNames.push(row[fieldKeyOriginal]);
                    }
                });

                item.xLabel = dimensionNames.join("-");
                item.xLabelOriginal = originalDimensionNames.join("-");
                indicators.forEach(indicator => {
                    let fieldKey = indicator.fieldKey;
                    let fieldKeyOriginal = fieldKey + ORIGINAL;
                    item[fieldKey] = {
                        value: row[fieldKey],
                        originalValue: row[fieldKeyOriginal] || Number(row[fieldKey]) || 0
                    };
                    if (Object.prototype.hasOwnProperty.call(row, `${fieldKey}${TOOTIP}`)) {
                        let tootipValue = row[`${fieldKey}${TOOTIP}`] || row[`${fieldKey}${TOOTIP}${ORIGINAL}`];
                        item[fieldKey].tootipValue = tootipValue;
                    }
                });
                items.push(item);
            });
        } else {
            // keep the chart alive (give him some default values)
        }
    }

    return {
        columns,
        items,
        tableColumns,
        tableItems,
    };
}
