// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PutReviewIntelligentPartnerNodeInputSceneConfigByIdParam,
    PutReviewIntelligentPartnerNodeInputSceneConfigByIdBody,
    BaseResponseIntelligentPartnerDialogNodeInputSceneConfigFoundResponse,
    DeleteReviewIntelligentPartnerNodeInputSceneConfigByIdParam,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * update
 *
 * @export
 * @tags 智能陪练-AI输入场景节点配置
 * @link [PUT] /review/intelligentPartner/node/inputSceneConfig/{id}
 * @param id
 */
export function putReviewIntelligentPartnerNodeInputSceneConfigById(
    data: PutReviewIntelligentPartnerNodeInputSceneConfigByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerNodeInputSceneConfigByIdParam, never> & {
        segments: PutReviewIntelligentPartnerNodeInputSceneConfigByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeInputSceneConfigFoundResponse> {
    return request<
        PutReviewIntelligentPartnerNodeInputSceneConfigByIdBody,
        PutReviewIntelligentPartnerNodeInputSceneConfigByIdParam,
        never
    >('/review/intelligentPartner/node/inputSceneConfig/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerNodeInputSceneConfigById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 智能陪练-AI输入场景节点配置
 * @link [DELETE] /review/intelligentPartner/node/inputSceneConfig/{id}
 * @param id
 */
export function deleteReviewIntelligentPartnerNodeInputSceneConfigById(
    options: ApiOptions<DeleteReviewIntelligentPartnerNodeInputSceneConfigByIdParam, never> & {
        segments: DeleteReviewIntelligentPartnerNodeInputSceneConfigByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReviewIntelligentPartnerNodeInputSceneConfigByIdParam, never>(
        '/review/intelligentPartner/node/inputSceneConfig/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerNodeInputSceneConfigById',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-AI输入场景节点配置'],
        path: '/review/intelligentPartner/node/inputSceneConfig/{id}',
        method: 'put',
    },
    {
        tags: ['智能陪练-AI输入场景节点配置'],
        path: '/review/intelligentPartner/node/inputSceneConfig/{id}',
        method: 'delete',
    },
];
