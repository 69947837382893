import React from "react";
import Udesk from 'Udesk';
import _find from 'lodash-es/find';
import _filter from 'lodash-es/filter';
import Locales from 'UdeskLocales';

class InteractiveRecognitionDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        categoriesVisible: false,
        category: {},
        searchList: [],
        semanticTypeList: [
            {
                id: 1,
                name: Locales['current'].fix.standardAnswer
            },
            {
                id: 2,
                name: Locales['current'].fix.keyElementAnswer
            }
        ],
        talkCheckList: [
            {
                value: '1',
                label: Locales['current'].fix.generalStatement
            },
            {
                value: '2',
                label: Locales['current'].fix.generalQuestionsAndConfirmation
            },
            {
                value: '3',
                label: Locales['current'].fix.specialQuestionsAndAnswers
            }
        ],
        detectionTargetList: [
            {
                value: 0,
                text: Locales['current'].fix.all
            },
            {
                value: 1,
                text: Locales['current'].fix.agent
            },
            {
                value: 2,
                text: Locales['current'].fix.customer
            }
        ],
        effectivenessList: [
            {
                value: 0,
                text: Locales['current'].fix.invalid
            },
            {
                value: 1,
                text: Locales['current'].fix.effective
            }
        ],
        questionTypeList: [
            {
                value: 1,
                text: Locales['current'].fix.informationEntity
            },
            {
                value: 2,
                text: Locales['current'].fix.semanticTags
            }
        ],
        customCount: 9,
        detailType: 1,
        foctorsData: {
            name: "",
            type: 2,
            targetType: 0,
            categoryName: "",
            status: 1,
            categoryId: null,
            details: [
                {
                    additionalId: 0,
                    detectType: '',
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 0,
                    detectType: '',
                    elementParamType: 2,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 1,
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 2,
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 3,
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 4,
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 5,
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 6,
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 7,
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                },
                {
                    additionalId: 8,
                    elementParamType: 1,
                    elementParamId: '',
                    semanticTagGroup: {},
                    content: '',
                    interactiveType: 5
                }
            ]
        },
        customFoctorsData: [],
        visibleFoctors: false,
        confirmLoading: false,
        modalData: {
            key: null,
            text: '',
            elementParamType: 2,
            type: null
        },
        actitonsFlag: false,
        informationEntityshowlist: []
    };

    actions = {
        backToIndex() {
            let routeOptions = {
                history: this.props.history,
                routeName: "interactiveRecognitionIndex"
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        sentenceValueChange(type, index, value) {
            this.privates.model.detail[type][index].content = value;
            this.actions.update();

            if (value == null || value.trim() === "") {
                this.privates.searchList = [];
                this.actions.update();
                return;
            }
            let signIndex = value.lastIndexOf("#");
            if (signIndex !== -1 && value.length - 1 !== signIndex) {
                let keyword = value.substring(signIndex + 1);
                if (keyword == null || keyword.trim() === "") {
                    return;
                }
                this.actions.searchInformationEntities(keyword);
            } else if (signIndex !== -1 && value.length - 1 === signIndex) {
                this.privates.searchList = [];
                this.actions.update();
            }
        },
        actionsChange() {
            let { actitonsFlag } = this.privates;
            this.privates.actitonsFlag = !actitonsFlag;
            this.actions.update();
        },
        addSentence(type) {
            if (
                Array.isArray(this.privates.model.detail[type]) &&
                this.privates.model.detail[type].length >= 2000
            ) {
                return;
            }
            this.privates.model.detail[type].unshift({
                content: "",
                interactiveType: Udesk.enums.interactiveTypes[type].id
            });
            this.actions.update();
        },
        sentenceEmptyErrorTip(type) {
            Udesk.ui.notify.error(
                Udesk.utils.string.format(
                    this.locales.business.notifyMessage.canNotBeEmptyFormat,
                    this.locales.labels.content
                )
            );
        },
        deleteSentence(type, index) {
            this.privates.model.detail[type].splice(index, 1);
            this.actions.update();
        },
        changeType(value) {
            let { match } = this.props;
            if (!match.params.id && value !== 3) {
                this.privates.detailType = value;
                this.actions.update();
            }
        },
        factorsChangeType(value) {
            let { match } = this.props;
            if (!match.params.id) {
                this.privates.detailType = value.target.value;
                this.actions.update();
            }
        },
        changeTargetType(value) {
            this.privates.model.detail.targetType = value;
            this.actions.update();
        },
        changeStatus(value) {
            this.privates.model.detail.status = value;
            this.actions.update();
        },
        saveRecognition() {
            let { locales } = this;
            let { sdkOptions } = this.props;
            let {
                model: { detail, categories },
                category
            } = this.privates;

            if (
                detail.standardQuestion == null ||
                detail.standardQuestion.trim() === ""
            ) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.components.pages.interactiveRecognition.detail
                            .standardQuestion
                    )
                );
            }
            if (
                detail.standardAnswer == null ||
                detail.standardAnswer.trim() === ""
            ) {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.components.pages.interactiveRecognition.detail
                            .standardAnswer
                    )
                );
            }

            let params = detail;
            if (category.id) {
                params.categoryId = category.id;
                params.categoryName = category.name;
            } else {
                params.categoryId = categories[0].id;
                params.categoryName = categories[0].name;
            }
            let url = Udesk.business.apiPath.concatApiPath(
                `interactiveDatas`,
                sdkOptions
            );
            let method = Udesk.enums.requestMethods.create.name;
            if (params.id) {
                url = Udesk.business.apiPath.concatApiPath(
                    `interactiveDatas/${params.id}`,
                    sdkOptions
                );
                method = Udesk.enums.requestMethods.update.name;
            }
            Udesk.ajax(url, params, method).then(
                resp => {
                    this.actions.backToIndex();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        switchNode(node) {
            this.privates.category = node;
            this.actions.update();
        },
        changeCategoriesVisible() {
            let { categoriesVisible } = this.privates;
            this.privates.categoriesVisible = !categoriesVisible;
            this.actions.update();
        },
        getNodeBehaviors(node) {
            return {
                expanded: true
            };
        },
        changeStandardQuestion(value) {
            this.privates.model.detail.standardQuestion = value;
        },
        changeStandardAnswer(value) {
            this.privates.model.detail.standardAnswer = value;
        },
        onFocusInput(type, index) {
            let { detail } = this.privates.model;
            let items = detail[type] || [];
            items.forEach((item, itemIndex) => {
                if (index === itemIndex) {
                    item.isSearchVisible = true;
                } else {
                    item.isSearchVisible = false;
                }
            });
            this.privates.searchList = [];
            this.actions.update();
            this.actions.sentenceValueChange(type, index, items[index].content);
        },
        searchInformationEntities(keyword) {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `information-entity/keyword`,
                sdkOptions
            );
            let params = {
                keyword,
                pageNum: Udesk.config.paging.defaultPageNumber,
                pageSize: Udesk.config.paging.defaultPageSize,
                // systemType: Udesk.data.init.company.systemType,
            };
            Udesk.ajax.post(url, params).then(
                resp => {
                    // if (type) {
                    this.privates.searchList = resp.data;
                    // } else {
                    // this.privates.informationEntities = resp.data;
                    // this.privates.isEntitiesVisible = true;
                    // }
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        onSelectEntity(type, index, item) {
            let { detail } = this.privates.model;
            let items = detail[type] || [];
            let content = items[index].content;
            let signIndex = content.lastIndexOf("#");
            if (signIndex !== -1) {
                let sentence = content.substring(0, signIndex + 1);
                sentence = sentence + item.apiName + "#";
                items[index].content = sentence;
                items[index].isSearchVisible = false;
                this.actions.update();
            }
        },
        categoriesDataMap(data) {
            if (data && data.length > 0) {
                data.forEach(item => {
                    item.title = item.name;
                    item.value = item.id;
                    item.key = item.id;
                    item.level = item.level;
                    if (item.children) {
                        this.actions.categoriesDataMap(item.children);
                    }
                });
            }
        },
        onSearchTree() { },
        grounpCancel() {
            this.privates.visibleFoctors = false;
            this.actions.update();
        },
        grounpSave() {
            this.privates.visibleFoctors = false;
            this.actions.update();
        },
        searchFoctors(id, type) {
            this.privates.visibleFoctors = true;
            let { sdkOptions } = this.props;
            let { foctorsData, customFoctorsData } = this.privates;
            let text = this.privates.modalData.text;
            if (type && typeof (type) === 'string') {
                let obj = _find(customFoctorsData, current => current.key === id);
                text = obj[type].content;
                this.privates.modalData.text = obj[type].content;
                this.privates.modalData.key = obj[type].additionalId;
                this.privates.modalData.type = type;
            } else {
                if (id || id === 0) {
                    let obj = _find(foctorsData.details, current => current.additionalId === id && current.elementParamType === 2);
                    text = obj.content;
                    this.privates.modalData.text = obj.content;
                    this.privates.modalData.key = obj.additionalId;
                    this.privates.type = null;
                }
            }

            let params = {
                keyword: text
            };
            let url1 = Udesk.business.apiPath.concatApiPath(
                `smartTags/findSemanticList`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(url1, params).then(
                    resp => {
                        resolve(resp.data);
                        this.privates.dataFoctors = resp.data;
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });

            this.actions.update();
        },
        inputNameChange(e) {
            this.privates.foctorsData.name = e.target.value;
        },
        foctorsChange(id, type, e) {
            let { foctorsData } = this.privates;
            let list = foctorsData.details.map(item => {
                if (item.additionalId === id && item.elementParamType === type) {
                    item.content = e.target.value;
                }
                return item;
            });
            this.privates.foctorsData.details = list;
        },
        checkboxGroupChange(id, e) {
            let { foctorsData } = this.privates;
            let list = foctorsData.details.map(item => {
                if (item.additionalId === id) {
                    item.detectType = e.join(',');
                }
                return item;
            });
            this.privates.foctorsData.details = list;
        },
        detectionTarget(value) {
            this.privates.foctorsData.targetType = value;
        },
        classTreeChange(value) {
            this.privates.foctorsData.categoryId = value;
        },
        statusChange(value) {
            this.privates.foctorsData.status = value;
        },
        foctorsSelectChange(id, text, value) {
            let { foctorsData } = this.privates;
            let list = foctorsData.details.map(item => {
                if (item.additionalId === id) {
                    item.elementParamType = value;
                    item.semanticTagGroup = {};
                    item.content = '';
                    item.elementParamId = null;
                }
                return item;
            });
            this.props.form.setFieldsValue({ [text]: '' });
            this.privates.foctorsData.details = list;
            this.actions.update();
        },
        foctorsInputChange(id, value) {
            let { foctorsData } = this.privates;
            let list = foctorsData.details.map(item => {
                if (item.additionalId === id) {
                    item.content = value.target.value;
                }
                return item;
            });
            this.privates.foctorsData.details = list;
            this.actions.update();
        },
        customFoctorsSelectChange(id, text, type, value) {
            let { customFoctorsData } = this.privates;
            let list = customFoctorsData.map(item => {
                if (item.key === id) {
                    if (text === 'question') {
                        item.question.elementParamType = value;
                        item.question.semanticTagGroup = {};
                        item.question.content = '';
                        item.question.elementParamId = null;
                    } else if (text === 'answer') {
                        item.answer.elementParamType = value;
                        item.answer.semanticTagGroup = {};
                        item.answer.content = '';
                        item.answer.elementParamId = null;
                    }
                }
                return item;
            });
            this.props.form.setFieldsValue({ [type]: '' });
            this.privates.customFoctorsData = list;
            this.actions.update();
        },
        modalChangeInput(value) {
            let { modalData, foctorsData } = this.privates;
            let list = foctorsData.details.map(item => {
                if (item.additionalId === modalData.key && item.elementParamType === modalData.elementParamType) {
                    item.content = value.target.value;
                }
                return item;
            });
            this.privates.modalData.text = value.target.value;
            this.privates.foctorsData.details = list;
            this.actions.update();
        },
        modalActions(value, e) {
            e.stopPropagation();
            e.preventDefault();
            let { modalData, foctorsData, customFoctorsData } = this.privates;
            if (modalData.type) {
                let list = customFoctorsData.map(item => {
                    if (item.key === modalData.key) {
                        item[modalData.type].semanticTagGroup = value;
                        item[modalData.type].elementParamId = value.groupId;
                    }
                    return item;
                });
                this.privates.customFoctorsData = list;
            } else {
                let list = foctorsData.details.map(item => {
                    if (item.additionalId === modalData.key && item.elementParamType === modalData.elementParamType) {
                        item.semanticTagGroup = value;
                        item.elementParamId = value.groupId;
                    }
                    return item;
                });
                this.privates.foctorsData.details = list;
            }

            this.actions.update();
        },
        foctorsSave() {
            let { foctorsData, customFoctorsData } = this.privates;
            let { sdkOptions, match } = this.props;
            this.props.form.validateFields((err, values) => {
                if (!err) {

                    let list = foctorsData.details.filter(current => current.content !== '');
                    let obj = _find(list, current => current.additionalId === 0);
                    if (obj) {
                        list = list.map(item => {
                            return {
                                interactiveId: item.interactiveId,
                                interactiveType: item.interactiveType,
                                content: item.content,
                                detectType: item.detectType,
                                additionalId: item.additionalId,
                                elementParamType: item.elementParamType,
                                elementParamId: item.elementParamId
                            };
                        });
                        if (customFoctorsData.length > 0) {
                            customFoctorsData.forEach(item => {
                                delete item.semanticTagGroup;
                                list.push(item.question);
                                list.push(item.answer);
                            });
                        }
                        let data = {
                            type: foctorsData.type,
                            name: foctorsData.name,
                            targetType: foctorsData.targetType,
                            categoryId: foctorsData.categoryId,
                            categoryName: foctorsData.categoryName,
                            status: foctorsData.status,
                            details: list
                        };
                        if (match.params.id) {
                            data.id = foctorsData.id;
                        }
                        let url1 = Udesk.business.apiPath.concatApiPath(
                            `interactiveDatas/multiElement`,
                            sdkOptions
                        );
                        if (match.params.id) {
                            url1 = Udesk.business.apiPath.concatApiPath(
                                `interactiveDatas/multiElement/${match.params.id}`,
                                sdkOptions
                            );
                            new Promise((resolve, reject) => {
                                Udesk.ajax.put(url1, data).then(
                                    resp => {
                                        Udesk.ui.notify.success(Locales['current'].fix.saveSuccess);
                                        let routeOptions = {
                                            history: this.props.history,
                                            routeName: "interactiveRecognition"
                                        };
                                        Udesk.ui.routing.transitionTo(routeOptions);
                                        this.actions.update();
                                    },
                                    reason => {
                                        Udesk.ui.notify.error(reason.errorMsg);
                                        reject(reason);
                                    }
                                );
                            });
                        } else {
                            new Promise((resolve, reject) => {
                                Udesk.ajax.post(url1, data).then(
                                    resp => {
                                        Udesk.ui.notify.success(Locales['current'].fix.saveSuccess);
                                        let routeOptions = {
                                            history: this.props.history,
                                            routeName: "interactiveRecognition"
                                        };
                                        Udesk.ui.routing.transitionTo(routeOptions);
                                        this.actions.update();
                                    },
                                    reason => {
                                        Udesk.ui.notify.error(reason.errorMsg);
                                        reject(reason);
                                    }
                                );
                            });
                        }
                    } else {
                        Udesk.ui.notify.error(Locales['current'].fix.semanticTagsAndInformationEntitiesCannotBeEmpty);
                    }

                }
            });



        },
        foctorCancel() {
            let routeOptions = {
                history: this.props.history,
                routeName: "interactiveRecognition"
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        customAdd() {
            let { customCount } = this.privates;
            let data = {
                key: customCount + 1,
                question: { additionalId: customCount + 1, elementParamType: 1, elementParamId: '', content: '', interactiveType: 5, detectType: '4', semanticTagGroup: {} },
                answer: { additionalId: customCount + 1, elementParamType: 2, elementParamId: '', content: '', interactiveType: 5, detectType: '5', semanticTagGroup: {} },
            };
            this.privates.customFoctorsData.push(data);
            this.privates.customCount = customCount + 1;
            this.actions.update();
        },
        customDel(value) {
            let { customFoctorsData } = this.privates;
            let list = customFoctorsData.filter(current => current.key !== value.key);
            this.privates.customFoctorsData = list;
            this.actions.update();
        },
        customInputChange(id, text, value) {
            let { customFoctorsData } = this.privates;
            let list = customFoctorsData.map(item => {
                if (item.key === id) {
                    item[text].content = value.target.value;
                }
                return item;
            });
            this.privates.customFoctorsData = list;
            this.actions.update();
        },
        modalFieldsTo(value) {
            let routeOptions = {
                history: this.props.history,
                routeName: "semanticTagsEdit",
                pathParams: {
                    id: value.groupId
                }
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        foctorsChangeSearch(value) {
            this.actions.informationGet(value);
        },
        foctorsChangSelect(id, type, value) {
            let { foctorsData } = this.privates;
            let list = foctorsData.details.map(item => {
                if (item.additionalId === id && item.elementParamType === type) {
                    if (value) {
                        item.content = value.label;
                        item.elementParamId = value.key;
                    } else {
                        item.content = '';
                        item.elementParamId = '';
                    }

                }
                return item;
            });
            this.privates.foctorsData.details = list;
        },
        foctorsChangSelectCustome(id, text, value) {
            let { customFoctorsData } = this.privates;
            let list = customFoctorsData.map(item => {
                if (item.key === id) {
                    if (text === 'question') {
                        if (value) {
                            item.question.content = value.label;
                            item.question.elementParamId = value.key;
                        } else {
                            item.question.content = '';
                            item.question.elementParamId = '';
                        }

                    } else if (text === 'answer') {
                        if (value) {
                            item.answer.content = value.label;
                            item.answer.elementParamId = value.key;
                        } else {
                            item.answer.content = '';
                            item.answer.elementParamId = '';
                        }

                    }
                }
                return item;
            });
            this.privates.customFoctorsData = list;
            this.actions.update();
        },
        informationGet(value) {
            let { sdkOptions } = this.props;
            let data = {
                keyword: value || '',
                activeFlag: 1,
                // systemType: Udesk.data.init.company.systemType
            };
            let url3 = Udesk.business.apiPath.concatApiPath(
                `information-entity/list`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url3, data).then(
                    resp => {
                        resolve(resp.data);
                        this.privates.informationEntityshowlist = resp.data;
                        this.actions.update();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        fctorsFocus(text) {
            let value = this.props.form.getFieldsValue([text]);
            if (value.key_elements) {
                this.actions.informationGet(value.key_elements.label);
            } else {
                this.actions.informationGet('');
            }

        }
    };
    onModelResolved(model) {
        let detail = model.detail;
        let categories = model.categories;
        this.privates.categoriesData = categories;
        this.actions.categoriesDataMap(this.privates.categoriesData);
        this.privates.detailType = detail.type;
        if (detail.categoryId && detail.categoryName) {
            let category = {
                id: detail.categoryId,
                name: detail.categoryName
            };
            this.privates.category = category;
        } else {
            if (categories && categories.length > 0) {
                this.privates.category = categories[0];
            }
        }
        if (detail.type === 2) {
            if (detail.details && detail.details.length > 1) {
                if (detail.details.length === 2 && _filter(detail.details, current => current.additionalId === 0).length === 2) {
                    this.privates.actitonsFlag = false;
                } else {
                    this.privates.actitonsFlag = true;
                }
            }
            let list = this.privates.foctorsData.details.map(item => {
                if (_find(detail.details, current => current.additionalId === item.additionalId)) {
                    if (item.additionalId === 0) {
                        if (_find(detail.details, current => current.additionalId === item.additionalId && current.elementParamType === item.elementParamType)) {
                            item = _find(detail.details, current => current.additionalId === item.additionalId && current.elementParamType === item.elementParamType);
                        } else {
                            item.detectType = _find(detail.details, current => current.additionalId === item.additionalId).detectType;
                        }
                    }
                    if (item.additionalId !== 0) {
                        item = _find(detail.details, current => current.additionalId === item.additionalId);
                    }
                }
                return item;
            });
            this.privates.foctorsData = {
                ...detail,
                details: list
            };
            detail.details.forEach(item => {
                if (item.additionalId > 8) {
                    if (!_find(this.privates.customFoctorsData, current => current.key === item.additionalId)) {
                        let data = {
                            key: item.additionalId
                        };
                        if (item.detectType === '4') {
                            data.question = item;
                        }
                        if (item.detectType === '5') {
                            data.answer = item;
                        }
                        this.privates.customFoctorsData.push(data);
                    } else {
                        this.privates.customFoctorsData.forEach(items => {
                            if (items.key === item.additionalId) {
                                if (item.detectType === '4') {
                                    items.question = item;
                                }
                                if (item.detectType === '5') {
                                    items.answer = item;
                                }
                            }
                        });
                    }
                }
            });
            this.privates.customFoctorsData.forEach((item, index) => {
                item.key = index + 9;
                if (item.question) {
                    item.question.additionalId = index + 9;
                }
                if (item.answer) {
                    item.answer.additionalId = index + 9;
                }
                if (index === this.privates.customFoctorsData.length - 1) {
                    this.privates.customCount = index + 10;
                }
            });

        }
        this.actions.update();
    }
    //#region Life Cycle
    componentDidMount() {
        this.privates.columns = [
            {
                title: Locales['current'].fix.semanticTags,
                dataIndex: 'semanticName',
                width: 150
            },
            {
                title: Locales['current'].fix.standardSentence,
                dataIndex: 'sentence',
                width: 150
            },
            {
                title: Locales['current'].fix.acquaintance,
                dataIndex: 'similarity',
                width: 150
            },
            {
                title: Locales['current'].labels.action,
                dataIndex: 'actions',
                render: (text, record) => {
                    return (
                        <div>
                            <a href={`/site/semantic-intelligence/semantic-tags/edit/${record.groupId}`} target='_blank' rel="noreferrer">{Locales['current'].labels.edit}</a>
                            <a href="" onClick={this.actions.modalActions.params(record)}>{Locales['current'].labels.apply}</a>
                        </div>

                    );
                }
            },
        ];
        this.actions.update();
    }
    componentWillUnmount() { }
    //#endregion
}

export default InteractiveRecognitionDetailComponent;
