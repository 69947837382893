// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetRiskSettingsByIdParam,
    BaseResponseRiskSettingFoundResponse,
    PutRiskSettingsByIdParam,
    PutRiskSettingsByIdBody,
    BaseResponseVoid,
    DeleteRiskSettingsByIdParam,
} from '../../types';

/**
 * 获取风险配置详情-单个
 *
 * @export
 * @tags 销售赋能-风险配置
 * @link [GET] /riskSettings/{id}
 * @param id
 */
export function getRiskSettingsById(
    options: ApiOptions<GetRiskSettingsByIdParam, never> & { segments: GetRiskSettingsByIdParam }
): Promise<BaseResponseRiskSettingFoundResponse> {
    return request<never, GetRiskSettingsByIdParam, never>('/riskSettings/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getRiskSettingsById',
    });
}

/**
 * 更新风险配置信息
 *
 * @export
 * @tags 销售赋能-风险配置
 * @link [PUT] /riskSettings/{id}
 * @param id
 */
export function putRiskSettingsById(
    data: PutRiskSettingsByIdBody,
    options: ApiOptions<PutRiskSettingsByIdParam, never> & { segments: PutRiskSettingsByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutRiskSettingsByIdBody, PutRiskSettingsByIdParam, never>('/riskSettings/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putRiskSettingsById',
    });
}

/**
 * 删除风险配置
 *
 * @export
 * @tags 销售赋能-风险配置
 * @link [DELETE] /riskSettings/{id}
 * @param id
 */
export function deleteRiskSettingsById(
    options: ApiOptions<DeleteRiskSettingsByIdParam, never> & {
        segments: DeleteRiskSettingsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteRiskSettingsByIdParam, never>('/riskSettings/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteRiskSettingsById',
    });
}

export const meta = [
    { tags: ['销售赋能-风险配置'], path: '/riskSettings/{id}', method: 'get' },
    { tags: ['销售赋能-风险配置'], path: '/riskSettings/{id}', method: 'put' },
    { tags: ['销售赋能-风险配置'], path: '/riskSettings/{id}', method: 'delete' },
];
