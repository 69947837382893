import { useEffect, useMemo, useState } from 'react';
import { getIntelligentPartnerHomePageStudentTaskDetail } from 'src/api/intelligentPartner/homePage/student/taskDetail';
import Udesk from 'Udesk';

export const useTasks = () => {
    const [tasks, setTasks] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        getIntelligentPartnerHomePageStudentTaskDetail()
            .then((resp) => {
                setTasks(resp.data ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return useMemo(() => {
        const practise = tasks.find(
            (t) => t.taskType === Udesk.enums.learningTaskType.notStarted.id
        );
        const examination = tasks.find(
            (t) => t.taskType === Udesk.enums.learningTaskType.underWay.id
        );
        return {
            practiseTasks: practise?.processTaskList ?? null,
            examinationTasks: examination?.processTaskList ?? null,
            practiseProgress: {
                totalCount: practise?.totalCount,
                finishCount: practise?.finishCount,
            },
            examinationProgress: {
                totalCount: examination?.totalCount,
                finishCount: examination?.finishCount,
            },
            loading,
        };
    }, [tasks, loading]);
};
