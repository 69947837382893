import React, { useState, useMemo, useEffect, useRef } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import CommonTablePage from 'Component/common/udesk-qa-table-page';
import type { TableComponentProps } from 'Component/common/udesk-qa-table-page';
import { Button, Col, Modal, Popconfirm, Row, Select, Space, TreeSelect } from 'udesk-ui';
import { getKnowledgeCategorysInitTree } from 'src/api/knowledgeCategorys/initTree';
import { postKnowledgeCategorys } from 'src/api/knowledgeCategorys';
import {
    deleteKnowledgeCategorysById,
    putKnowledgeCategorysById,
} from 'src/api/knowledgeCategorys/{id}';
import { putKnowledgeDatasBatchDelete } from 'src/api/knowledgeDatas/batch/delete';
import { putKnowledgeDatasBatchUpdate } from 'src/api/knowledgeDatas/batch/update';
import { postKnowledgeDatasFindByKeyword } from 'src/api/knowledgeDatas/findByKeyword';
import { deleteKnowledgeDatasById, putKnowledgeDatasById } from 'src/api/knowledgeDatas/{id}';
import ImportModal from 'Component/common/import-modal';

import './style.scss';
import { postKnowledgeDatasImport } from 'src/api/knowledgeDatas/import';
import { getSystemModule, isAlgorithm } from 'Udesk/system/subApp';
import { postKnowledgeDatasExport } from 'src/api/knowledgeDatas/export';
// import { PostKnowledgeDatasImportBody } from 'src/api/types';

interface DataNode {
    title: string;
    key: string;
    isLeaf?: boolean;
    children?: DataNode[];
    icon?: any;
    userId?: string;
}

const Template = React.memo((props: any) => {
    const { current: locales } = useRef(Locales['current']);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });
    const [selectedKey, setSelectedKey] = useState<string>(props.location?.state?.categoryId ?? '');
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const expandedKeys = useRef<string[]>([]);
    const ref = useRef<any>({});
    const { table: tableRef, searchForm } = ref.current;
    const [detailModalVisible, setDetailModalVisible] = useState(false);
    const [detailModalLoading, setDetailModalLoading] = useState(false);
    const [detailModalInitialValues, setDetailModalInitialValues] = useState<any>({});
    const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
    const [moveModalVisible, setMoveModalVisible] = useState(false);
    const [moveToId, setMoveToId] = useState('');
    // const [keyWord, setKeyWord] = useState(props.location?.state?.keyword ?? '');

    const treeProps = useMemo(() => {
        const onSelect = (selectedKeys, { selected, selectedNodes, node, event }) => {
            if (selected) {
                tableRef.clearSelectedRowKeys();
                setSelectedKey(node.key);
                const newPaging = {
                    ...paging,
                    pageNum: 1,
                };
                setPaging(newPaging);
                queryList({ categoryId: parseInt(node.key, 10), ...newPaging });
            }
        };
        const onAdd = (item) => {
            setDetailModalInitialValues({ pid: item.id });
            setDetailModalVisible(true);
        };
        const onEdit = (item) => {
            setDetailModalInitialValues({ ...item });
            setDetailModalVisible(true);
        };
        const onDelete = (item) => {
            deleteKnowledgeCategorysById({
                segments: {
                    id: item.id,
                },
                successMsg: locales.business.notifyMessage.deleteSuccess,
            }).then(() => {
                queryTree();
            });
        };
        return {
            treeNodeExtra: [
                {
                    label: locales.components.pages.knowledgeBase.list.add,
                    onClick: onAdd,
                    disabled: (item) => item.level > 4 || (item.pid && item.defaultFlag),
                },
                {
                    label: locales.labels.edit,
                    disabled: (item) => item.defaultFlag,
                    onClick: onEdit,
                },
                {
                    label: locales.components.pages.knowledgeBase.list.delete,
                    danger: true,
                    disabled: (item) => item.defaultFlag,
                    onClick: onDelete,
                },
            ],
            treeData,
            blockNode: true,
            onSelect,
            selectedKeys: [selectedKey],
            showIcon: true,
        };
    }, [treeData, selectedKey, paging, searchForm]);

    const modalProps = useMemo(() => {
        const formConfig = {
            initialValues: detailModalInitialValues,
            fields: [
                {
                    name: 'name',
                    label: locales.components.pages.knowledgeBase.list.categoryName,
                    type: 'Input',
                    props: {
                        style: {
                            width: 300,
                        },
                        placeholder: locales.labels.pleaseEnter,
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            ],
            footerRender: false as false,
        };
        const onOk = async (values) => {
            setDetailModalLoading(true);
            try {
                if (detailModalInitialValues.id) {
                    await putKnowledgeCategorysById(
                        {
                            id: detailModalInitialValues.id,
                            ...values,
                        },
                        {
                            segments: {
                                id: detailModalInitialValues.id,
                            },
                            successMsg: locales.business.info.editSuccess,
                        }
                    );
                } else {
                    if (!detailModalInitialValues.pid) return;
                    await postKnowledgeCategorys(
                        {
                            ...values,
                            pid: detailModalInitialValues.pid,
                        },
                        {
                            successMsg: locales.components.pages.knowledgeBase.list.createSuccess,
                        }
                    );
                }
                setDetailModalVisible(false);
                setDetailModalLoading(false);
                queryTree();
            } catch (e) {
                setDetailModalLoading(false);
            }
        };
        return {
            visible: detailModalVisible,
            loading: detailModalLoading,
            formConfig,
            onCancel: () => setDetailModalVisible(false),
            onOk,
        };
    }, [detailModalVisible, detailModalInitialValues, detailModalLoading]);
    const tableProps = useMemo<TableComponentProps>(() => {
        return {
            columns,
            dataSource,
            cacheKey: false,
            checkable: true,
            onSelectedRowsChange: (selectedRowKeys) => {
                setSelectedKeys(selectedRowKeys);
            },
        };
    }, [columns, dataSource]);
    const queryList = ({ pageSize, pageNum, categoryId }) => {
        setLoading(true);
        postKnowledgeDatasFindByKeyword({
            categoryId: parseInt(categoryId, 10),
            pageNum,
            pageSize,
            ...(searchForm?.getFieldsValue?.() ?? {
                keyword: props.location?.state?.keyword ?? '',
            }),
        }).then((resp) => {
            setDataSource(resp.data || []);
            setLoading(false);
            setPaging(resp.paging as any);
        });
    };
    const paginationProps = useMemo(() => {
        const { pageSize, total } = paging;
        return {
            current: paging.pageNum,
            pageSize,
            total,
            onChange: (page, pageSize) => {
                const newPages = {
                    ...paging,
                    pageNum: page,
                    pageSize,
                };
                queryList({ ...newPages, categoryId: parseInt(selectedKey, 10) });
                setPaging(newPages);
            },
        };
    }, [paging.pageNum, paging.pageSize, paging.total, selectedKey]);
    const queryTree = () => {
        getKnowledgeCategorysInitTree().then(
            (resp) => {
                const newTreeData = getNode(resp.data);
                const getExpandedKeys = (source: any[] = [], level = 0) => {
                    if (level > 2) return [];
                    let ret = source.map((s) => s.key);

                    return [
                        ...ret,
                        ...source.map((s) => getExpandedKeys(s.children, level + 1)).flat(Infinity),
                    ];
                };
                const expKeys = getExpandedKeys(newTreeData);
                expandedKeys.current = expKeys;
                setTreeData(newTreeData);
                if (Array.isArray(resp.data) && resp.data.length) {
                    queryList({
                        categoryId: selectedKey ? parseInt(selectedKey, 10) : resp.data[0].id,
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                    if (resp.data[0].id && !selectedKey) {
                        setSelectedKey(`${resp.data[0].id}`);
                    }
                }
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    };
    useEffect(() => {
        // queryList(paging);
        queryTree();
    }, []);
    useEffect(() => {
        setColumns([
            {
                title: locales.fields.interactiveRecognition.question,
                dataIndex: 'standardQuestionText',
                key: 'standardQuestionText',
            },
            {
                title: locales.fields.interactiveRecognition.type,
                dataIndex: 'type',
                key: 'type',
                render: (name, item, index) =>
                    Udesk.enums.interactiveQuestionTypes.getName(item.type),
            },
            {
                title: /* 分类 */ Locales['current'].pages.semanticIntelligence.knowledgeBase.index
                    .classification,
                dataIndex: 'category',
                key: 'category',
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: locales.fields.interactiveRecognition.status,
                renderType: 'switch',
                onSwitchChange: (item, index) => {
                    const { id, status } = item;
                    putKnowledgeDatasById(
                        { status: status ? 0 : 1 },
                        {
                            segments: {
                                id,
                            },
                            successMsg: locales.business.info.editSuccess,
                        }
                    ).then(() => {
                        let newData = [...dataSource];
                        newData[index].status = !newData[index].status;
                        setDataSource(newData);
                    });
                },
            },
            {
                key: 'actions',
                dataIndex: 'actions',
                title: locales.labels.action,
                renderType: 'actions',
                onEdit:
                    Udesk.data.init.user &&
                    Udesk.data.init.user.hasFeature('semantic:knowledgeData:edit')
                        ? (item) => {
                              let routeOptions = {
                                  history: props.history,
                                  routeName: isAlgorithm()
                                      ? 'algorithmKnowledgeBaseEdit'
                                      : 'knowledgeBaseEdit',
                                  pathParams: { id: item.id },
                                  state: {
                                      categoryId: selectedKey,
                                      ...searchForm.getFieldsValue(),
                                  },
                              };
                              Udesk.ui.routing.transitionTo(routeOptions);
                          }
                        : undefined,
                onDelete:
                    Udesk.data.init.user &&
                    Udesk.data.init.user.hasFeature('semantic:knowledgeData:delete')
                        ? (item) => {
                              deleteKnowledgeDatasById({
                                  segments: {
                                      id: item.id,
                                  },
                                  successMsg: locales.business.notifyMessage.deleteSuccess,
                              }).then(() => {
                                  queryList({
                                      categoryId: parseInt(selectedKey, 10),
                                      pageNum: 1,
                                      pageSize: paging.pageSize,
                                  });
                              });
                          }
                        : undefined,
            },
            {
                name: 'keyword',
                type: 'Input',
                title: locales.components.pages.knowledgeBase.list.keyWord,
                props: {
                    placeholder: locales.labels.pleaseEnter,
                },
            },
        ]);
    }, [selectedKey, dataSource]);
    const createBtn = (
        <Button
            onClick={() => {
                let routeOptions = {
                    history: props.history,
                    routeName: isAlgorithm() ? 'algorithmKnowledgeBaseNew' : 'knowledgeBaseNew',
                    state: {
                        categoryId: selectedKey,
                        ...searchForm.getFieldsValue(),
                    },
                };
                Udesk.ui.routing.transitionTo(routeOptions);
            }}
        >
            {locales.labels.create}
        </Button>
    );
    const selecthandleChange = (value) => {
        if (value === 'delete') {
            return;
        }

        if (selectedKeys.length === 0) {
            return Udesk.ui.notify.error(
                locales.pages.semanticIntelligence.knowledgeBase.index
                    .pleaseSelectTheKnowledgePointsToBeMovedInBatch
            );
        }
        if (value === 'move') {
            setMoveModalVisible(true);
        }
    };
    const deleteRecordOk = () => {
        if (!selectedKeys.length || !selectedKey) return;
        putKnowledgeDatasBatchDelete(
            {
                categoryId: parseInt(selectedKey, 10),
                id: selectedKeys,
            },
            {
                successMsg: locales.business.info.deleteSuccess,
            }
        ).then(() => {
            tableRef.clearSelectedRowKeys();
            setSelectedKeys([]);
            const newPages = {
                ...paging,
                pageNum: 1,
            };
            queryList({ ...newPages, categoryId: parseInt(selectedKey, 10) });
        });
    };
    const { handleOnCancel, handleOnOk, isUploadVisible, uploadFile, file, showImportModal } =
        ImportModal.useImportModal({
            formatParams(file, uploadData) {
                return {
                    fileName: file.name,
                    keyName: uploadData.key,
                    systemModule: getSystemModule(),
                };
            },
            request: postKnowledgeDatasImport,
        });
    const tableHeaderRightRender = (
        <Space>
            <Select
                value={locales.labels.batchActions}
                style={{ width: 120, marginRight: '10px' }}
                onChange={selecthandleChange}
                disabled={!selectedKeys.length}
            >
                <Select.Option value="delete" style={{ padding: '0px' }}>
                    <Popconfirm
                        title={Udesk.utils.string.format(
                            locales.labels.deleteRecordInfo,
                            selectedKeys.length
                        )}
                        okText={locales.labels.confirm}
                        cancelText={locales.labels.cancel}
                        onConfirm={deleteRecordOk}
                    >
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            style={{
                                display: 'inline-block',
                                width: '100%',
                                padding: '5px 12px',
                            }}
                        >
                            {locales.labels.deleteRecord}
                        </a>
                    </Popconfirm>
                </Select.Option>
                <Select.Option value="move">{locales.labels.batchMove}</Select.Option>
            </Select>
            <Button onClick={showImportModal}>
                {/* 导入 */}
                {locales.pages.semanticIntelligence.knowledgeBase.index.import}
            </Button>
        </Space>
    );
    const batchMove = () => {
        putKnowledgeDatasBatchUpdate({
            categoryId: parseInt(moveToId, 10),
            id: selectedKeys.map((i) => parseInt(i, 10)),
        }).then(() => {
            setMoveModalVisible(false);
            const newPages = {
                ...paging,
                pageNum: 1,
            };
            tableRef.clearSelectedRowKeys();
            queryList({ ...newPages, categoryId: parseInt(selectedKey, 10) });
        });
    };

    const downloadTemplate = () => {
        let { sdkOptions } = props;
        let templateLocalUrl = `/import/semantic-intelligence/concept-word/knowledge-base/knowledge-base-template.xlsx`;
        let templateUrl =
            Udesk.business.apiPath.getStaticBasePath(
                sdkOptions.props.domain,
                sdkOptions.props.pathPrefix
            ) + templateLocalUrl;

        (window as any).location = templateUrl;
    };
    return (
        <Page
            pageBodyClassName="knowledge-base-page-index"
            title={locales.components.pages.knowledgeBase.title}
            padding={true}
        >
            <CommonTablePage
                loading={loading}
                // showExport={true}
                // showColumsControl={true}
                // showFilter={true}
                // filter={filterProps}
                // exportBtn={exportBtnProps}
                searchFormInitialValues={{ keyword: props.location?.state?.keyword ?? '' }}
                tableHeaderRightRender={tableHeaderRightRender}
                ref={ref}
                mainButton={
                    Udesk.data.init.user &&
                    Udesk.data.init.user.hasFeature('semantic:knowledgeData:add')
                        ? createBtn
                        : null
                }
                onReset={(params) => {
                    searchForm.setFieldsValue({
                        keyword: '',
                    });
                    queryList({
                        ...params,
                        keyword: '',
                        categoryId: parseInt(selectedKey, 10),
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                }}
                onSearch={(params) => {
                    queryList({
                        ...params,
                        categoryId: parseInt(selectedKey, 10),
                        pageNum: 1,
                        pageSize: paging.pageSize,
                    });
                }}
                showExport={true}
                exportBtn={{
                    hasFilterCondition: true,
                    onExport: () => {
                        return postKnowledgeDatasExport({
                            systemModule: getSystemModule(),
                        });
                    },
                    exportDataCount: null,
                }}
            >
                {Boolean(treeData.length) && (
                    <CommonTablePage.Tree
                        {...treeProps}
                        defaultExpandedKeys={expandedKeys.current}
                        treeKey="knowledgeGroupTree"
                    ></CommonTablePage.Tree>
                )}
                <CommonTablePage.Table {...tableProps}></CommonTablePage.Table>
                <CommonTablePage.Pagination {...paginationProps}></CommonTablePage.Pagination>
                <CommonTablePage.Modal {...modalProps}></CommonTablePage.Modal>
            </CommonTablePage>
            <Modal
                onOk={batchMove}
                title={
                    locales.pages.semanticIntelligence.knowledgeBase.index
                        .moveKnowledgePointsInBatch
                }
                visible={moveModalVisible}
                onCancel={() => setMoveModalVisible(false)}
            >
                <Row>
                    <Col>
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                            {locales.pages.semanticIntelligence.knowledgeBase.index.moveTo}
                        </div>
                    </Col>
                    <Col span={20}>
                        <TreeSelect
                            onSelect={setMoveToId}
                            value={moveToId}
                            treeData={treeData}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
            </Modal>
            <ImportModal
                onCancel={handleOnCancel}
                onOk={handleOnOk}
                visible={isUploadVisible}
                uploadFile={uploadFile}
                downloadTemplate={downloadTemplate}
                title={
                    /* 导入知识库 */ locales.pages.semanticIntelligence.knowledgeBase.index
                        .importKnowledgeBase
                }
                file={file}
            />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

export function getNode(data) {
    return data.map((i) => {
        let children = Array.isArray(i.children) ? getNode(i.children || []) : undefined;
        return {
            ...i,
            key: `${i.id}`,
            // isLeaf: i.leaf && !i.employee?.length,
            title: i.name,
            children,
            // employee: i.employee,
            parentId: i.pid,
            id: i.id,
        };
    });
}
