/*
 * @Description: 任务详情-数据总览
 */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Page from 'Component/common/udesk-qa-page-layout';
import * as echarts from 'echarts';
import useResizeObserver from 'use-resize-observer';
import { useSummary } from './hook/useSummary';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { useUdeskDatePicker } from 'Component/common/udesk-date-picker/template';
import OrganizationMemberSelect from '../../../components/OrganizationMemberSelect';
import Udesk from 'Udesk';
import { Radio, Button} from 'udesk-ui';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import ResultAnalysis from './components/ResultAnalysis';
import SimplePieChart from '../../../components/SimplePieChart';
import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import { postIntelligentPartnerTaskInfosByTaskIdStatistics } from 'src/api/intelligentPartnerTaskInfos/{taskId}/statistics';
// import { useRequest } from 'src/util/hook';

import './index.less';
import { isExercise } from '../../../../learning-center/components/util';
import { useCoachUserTreeDataWithPermit } from 'src/util/hook/user';

const TaskCenterDetail: React.FC<any> = (props) => {
    // const request = useRequest();
    const locales = UdeskLocales['current'];
    const { history, match } = props;
    // console.log('task-center:routes', routes);
    const taskId = match?.params?.id || '0';
    const [taskInfo, setTaskInfo] = useState<any>();
    const [taskInfoExtra, setTaskInfoExtra] = useState<any>({
        totalCount: 0, // 应该执行的总人数
        executeCount: 0, // 执行人数
        executeRate: 0, // 执行率
        completeCount: 0, // 完成人数
        completeRate: 0, // 完成率
        commitCount: 0, // 达标人数
        commitRate: 0, // 达标率
        excellentCount: 0, // 优秀人数
        excellentRate: 0, // 优秀率
        participateCompleteRate: 0, //  参与完成率	
        participateCommitRate: 0, // 参与达标率	
        participateExcellentRate: 0, // 参与达优率	
    });
    const preStartTime = moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00';
    const preEndTime = moment().format('YYYY-MM-DD') + ' 23:59:59';
    const [loading, setLoading] = useState(false);
    const [userSelectObj, setUserSelectObj] = useState<any>({
        userIds: [],
        organizationIds: []
    });
    const { treeData } = useCoachUserTreeDataWithPermit();

    const [echartsType, setEchartsType] = useState('byTime');
    const [echartInstance, setEchartInstance] = useState<any>(null);
    const [echartInstanceNumber, setEchartInstanceNumber] = useState<any>(null);
    const { startTime, endTime, ...datePickerProps } = useUdeskDatePicker();
    const [ confirmFilterParams, setConfirmFilterParams] = useState<any>({
        startTime: preStartTime,
        endTime: preEndTime,
        userSelectObj: {
            userIds: [],
            organizationIds: []
        }
    });
    const { taskSummaryEcharts } = useSummary(
        taskId,
        confirmFilterParams.startTime,
        confirmFilterParams.endTime,
        confirmFilterParams.userSelectObj,
        echartsType
    );
    const {  width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const domInstance = useRef(null);
    const domInstanceNumber = useRef(null);
    useEffect(() => {
        const c = echarts.init(domInstance.current);
        setEchartInstance(c);
    }, []);
    useEffect(() => {
        // console.log('paramss00',echartInstance, taskSummaryEcharts);
        if (echartInstance && taskSummaryEcharts) {
            if (echartsType == 'byTime') {
                echartInstance.setOption(taskSummaryEcharts);
            } else if (echartsType == 'byNumber') {
                echartInstanceNumber.setOption(taskSummaryEcharts);
            }
        }
    }, [echartInstance, taskSummaryEcharts]);
    useEffect(() => {
        requestIdleCallback(() => {
            echartInstance?.resize?.();
        });
    }, [width, height, echartInstance]);

    const onBack = () => {
        const routeName = 'coachTaskCenterList';
        Udesk.ui.routing.transitionTo({
            history,
            routeName,
        });
    };

    const filterConfirm = () => {
        setConfirmFilterParams({
            startTime,
            endTime,
            userSelectObj
        });
    };
    const filterReset = () => {
        setConfirmFilterParams({
            startTime: preStartTime,
            endTime: preEndTime,
            userSelectObj: {
                userIds: [],
                organizationIds: []
            }
        });
        setUserSelectObj({
            userIds: [],
            organizationIds: []
        });
        datePickerProps.onChange([preStartTime, preEndTime]);
    };

    const isPPT = useCallback(
        (wholeCallback, singleCallback, errorCallback?) => {
            // taskInfo?.evaluationMode 等于null:非ppt， 1：整体， 2：单页
            if (taskInfo?.evaluationMode === 1) {
                return wholeCallback.call(taskInfo);
            }
            if (taskInfo?.evaluationMode === 2) {
                return singleCallback.call(taskInfo);
            }
            return (errorCallback || singleCallback).call(taskInfo);
        },
        [taskInfo]
    );

    const pieShowNull = (data) => {
        let mark = false;
        data.forEach(item => {
            if (item.value != 0) {
                mark = true;
            }
        });
        if (mark) {
            return data;
        } else {
            return null;
        }
    };

    const onSortWayChange = (e) => {
        setEchartsType(e.target.value);
            // if (e.target.value == 'byTime') {
            //     echartInstanceNumber.dispose();
            //     const c = echarts.init(domInstance.current);
            //     setEchartInstance(c);
            // } else if (e.target.value == 'byNumber') {
            //     echartInstance.dispose();
            //     const c = echarts.init(domInstanceNumber.current);
            //     setEchartInstanceNumber(c);
            // }
        // echartInstance.dispose();
        setTimeout(() => {
            if (e.target.value == 'byTime') {
                echartInstanceNumber.dispose();
                const c = echarts.init(domInstance.current);
                setEchartInstance(c);
            } else if (e.target.value == 'byNumber') {
                echartInstance.dispose();
                const c = echarts.init(domInstanceNumber.current);
                setEchartInstanceNumber(c);
            }
        }, 500);
    };
    const treeDataChange = (data) => {
        // console.log(data);
        setUserSelectObj(data);
    };
    const init = useCallback(
        () => {
            const id = match?.params?.id || '0';
            if (id !== '0') {
                setLoading(true);
                getIntelligentPartnerTaskInfosById({
                    segments: { id },
                })
                    .then((resp) => {
                        let values: any = {
                            ...resp?.data,
                        };
                        //画像
                        values.imageIdInfo = {
                            status: 2,
                        };
                        if (values.imageId != null) {
                            values.imageIdInfo.status = 1;
                            values.imageIdInfo.selectedItemId = values.imageId;
                        }
                        // 时长限制
                        values.limitDurationInfo = {
                            status: 1,
                        };
                        if (values.limitDuration != null) {
                            values.limitDurationInfo.status = 2;
                            values.limitDurationInfo.inputValue = values.limitDuration;
                        }
                        // 时间
                        values.taskTimeInfo = {
                            status: 1,
                            viewText:
                                /* 不限时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                    .index.unlimitedTime,
                        };
                        if (values.taskStartTime && values.taskStartTime) {
                            values.taskTimeInfo.status = 2;
                            values.taskTimeInfo.viewText =
                                moment(values.taskStartTime).format('YYYY-MM-DD HH:mm') +
                                /* 至 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                    .to +
                                moment(values.taskEndTime).format('YYYY-MM-DD HH:mm');
                        }
                        // 评分标准
                        values.gradingStandartInfo = {
                            commitScore: values.commitScore,
                            excellentScore: values.excellentScore,
                        };
                        // 完成要求
                        values.completeStandartInfo = {};
                        if (values.completeByTimes != null) {
                            values.completeStandartInfo.timesStatus = true;
                            values.completeStandartInfo.completeByTimes = values.completeByTimes;
                        }
                        if (values.completeByScore != null) {
                            values.completeStandartInfo.scoreStatus = true;
                            values.completeStandartInfo.completeByScore = values.completeByScore;
                        }
                        setTaskInfo(values);
                        // getTaskScore(values);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
    
                const reqData = {
                    taskId: id,
                    endTime: endTime.split(' ')[0],
                    startTime: startTime.split(' ')[0],
                    ...userSelectObj
                };
                postIntelligentPartnerTaskInfosByTaskIdStatistics(reqData, {segments:{taskId:id}})
                .then((resp) => {
                    // const currentItem = (resp?.data || []).find((item) => item.id + '' === id);
                    const currentItem = (resp?.data || []);
                    // console.log('statistics:findAll::',currentItem);
                    if (currentItem) {
                        setTaskInfoExtra(currentItem);
                    }
                });
            }
        },[startTime, endTime,userSelectObj]);
    // const init = 

    useEffect(() => {
        init();
    }, [confirmFilterParams.startTime, confirmFilterParams.endTime,confirmFilterParams.userSelectObj]);

    return (
        <Page
            pageBodyClassName="task-center-detail-container"
            backGroundGhost={true}
            title={`${taskInfo?.taskName || ''}-${
                taskInfo?.taskType === 1
                    ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                          .practice
                    : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                          .examination
            }`}
            // loading={loading}
            onBack={onBack}
        >
            <div className="tcd-header-wrapper">
                    <div className="tcd-header-filter-box" style={{background: '#fff'}}>
                        <div className="tcd-header-filter-box-left">
                            {UdeskLocales['current'].pages.coach.taskCenter.detail.index.staff}：
                            <OrganizationMemberSelect 
                                placeholder={locales.labels.pleaseSelect}
                                width={200}
                                value={userSelectObj}
                                onChange={treeDataChange}
                                treeData={treeData}
                            />
                            &nbsp;
                            <UdeskDatePicker {...datePickerProps} allowClear={false} />
                        </div>
                        <div className="tcd-header-filter-box-right">
                            <Button onClick={filterReset}>{locales.labels.reset}</Button>
                            <Button type="primary" onClick={filterConfirm}>{locales.labels.confirm}</Button>
                        </div>
                    </div>
                    <div className="tcd-header-echarts">
                    <SimplePieChart
                        id="executeRateChart"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={
                            /* 执行率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .implementationRate
                        }
                        title={taskInfoExtra.executeRate + '%'}
                        data={[
                            {
                                name: /* 执行人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                    .detail.index.numberOfExecutors,
                                value: taskInfoExtra.executeCount,
                            },
                            {
                                name: /* 未执行人数 */ UdeskLocales['current'].pages.coach
                                    .taskCenter.detail.index.numberOfPeopleNotExecuted,
                                value: taskInfoExtra.totalCount - taskInfoExtra.executeCount,
                            },
                        ]}
                    />
                    <SimplePieChart
                        id="completeRateChart"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={
                            /* 完成率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .completionRate
                        }
                        title={taskInfoExtra.completeRate + '%'}
                        data={[
                            {
                                name: /* 完成人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                    .detail.index.numberOfCompletedPersonnel,
                                value: taskInfoExtra.completeCount,
                            },
                            {
                                name: /* 未完成人数 */ UdeskLocales['current'].pages.coach
                                    .taskCenter.detail.index.numberOfUnfinishedPersonnel,
                                value: taskInfoExtra.totalCount - taskInfoExtra.completeCount,
                            },
                        ]}
                    />
                    <SimplePieChart
                        id="commitRateChart"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={
                            /* 达标率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .complianceRate
                        }
                        title={taskInfoExtra.commitRate + '%'}
                        data={[
                            {
                                name: /* 达标人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                    .detail.index.numberOfQualifiedPersonnel,
                                value: taskInfoExtra.commitCount,
                            },
                            {
                                name: /* 未达标人数 */ UdeskLocales['current'].pages.coach
                                    .taskCenter.detail.index.numberOfNonCompliantIndividuals,
                                value: taskInfoExtra.totalCount - taskInfoExtra.commitCount,
                            },
                        ]}
                    />
                    <SimplePieChart
                        id="excellentRateChart"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={
                            /* 达优率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .excellentRate
                        }
                        title={taskInfoExtra.excellentRate + '%'}
                        data={[
                            {
                                name: /* 优秀人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                    .detail.index.numberOfOutstandingIndividuals,
                                value: taskInfoExtra.excellentCount,
                            },
                            {
                                name: /* 非优秀人数 */ UdeskLocales['current'].pages.coach
                                    .taskCenter.detail.index.numberOfNonOutstandingIndividuals,
                                value: taskInfoExtra.totalCount - taskInfoExtra.excellentCount,
                            },
                        ]}
                    />


                    <SimplePieChart
                        id="excellentRateChart1"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={
                            /* 参与完成率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .participateCompleteRate
                        }
                        title={taskInfoExtra.participateCompleteRate + '%'}
                        data={pieShowNull([
                            {
                                name: /* 完成人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                    .detail.index.numberOfCompletedPersonnel,
                                value: taskInfoExtra.completeCount,
                            },
                            {
                                name: /* 未完成人数 */ UdeskLocales['current'].pages.coach
                                    .taskCenter.detail.index.numberOfUnfinishedPersonnel,
                                value: taskInfoExtra.executeCount - taskInfoExtra.completeCount,
                            },
                        ])}
                    />
                    <SimplePieChart
                        id="excellentRateChart2"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={
                            /* 参与达标率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .participateCommitRate
                        }
                        title={taskInfoExtra.participateCommitRate + '%'}
                        data={pieShowNull([
                            {
                                name: /* 达标人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                    .detail.index.numberOfQualifiedPersonnel,
                                value: taskInfoExtra.commitCount,
                            },
                            {
                                name: /* 未达标人数 */ UdeskLocales['current'].pages.coach
                                    .taskCenter.detail.index.numberOfNonCompliantIndividuals,
                                value: taskInfoExtra.executeCount - taskInfoExtra.commitCount,
                            },
                        ])}
                    />
                    <SimplePieChart
                        id="excellentRateChart3"
                        // width='calc(25% - 8px)'
                        loading={loading}
                        height={180}
                        subTitle={
                            /* 参与达优率 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .participateExcellentRate
                        }
                        title={taskInfoExtra.participateExcellentRate + '%'}
                        data={pieShowNull(
                            [
                                {
                                    name: /* 优秀人数 */ UdeskLocales['current'].pages.coach.taskCenter
                                        .detail.index.numberOfOutstandingIndividuals,
                                    value: taskInfoExtra.excellentCount,
                                },
                                {
                                    name: /* 非优秀人数 */ UdeskLocales['current'].pages.coach
                                        .taskCenter.detail.index.numberOfNonOutstandingIndividuals,
                                    value: taskInfoExtra.executeCount - taskInfoExtra.excellentCount,
                                },
                            ])
                        }
                    />
                    </div>
            </div>
            
            <div style={{background: '#fff'}}>
                <ResultAnalysis
                    taskId={match?.params?.id}
                    lessonId={taskInfo?.lessonId}
                    isExercise={isExercise(taskInfo?.taskType)}
                    isPPT={isPPT}
                    startTime={confirmFilterParams.startTime}
                    endTime={confirmFilterParams.endTime}
                    userSelectObj={confirmFilterParams.userSelectObj}
                    showTop={true}
                    history={props.history}
                />
            </div>
            <div style={{background: '#fff', marginTop: '10px'}}>
                <div className="udsk-qa-web-practice-summary-title">
                    <Radio.Group
                        defaultValue="byTime"
                        onChange={onSortWayChange}
                    >
                        <Radio.Button value="byTime">
                            {/* 时间维度视图 */}
                            {
                                UdeskLocales['current'].pages.coach
                                .taskCenter.detail.index.byTime
                            }
                        </Radio.Button>
                        <Radio.Button value="byNumber">
                            {/* 次数维度视图 */}
                            {
                                UdeskLocales['current'].pages.coach
                                .taskCenter.detail.index.byNumber
                            }
                        </Radio.Button>
                    </Radio.Group>
                </div>
                {echartsType == 'byTime' && (<div className="echarts-wrap" ref={domInstance}></div>)}
                {echartsType == 'byNumber' && (<div className="echarts-wrap" ref={domInstanceNumber}></div>)}
                {/* <div className="echarts-wrap" ref={domInstance}></div> */}
            </div>
            
        </Page>
    );
};

export default hookProps(TaskCenterDetail);
