import Udesk from 'Udesk';
import React from 'react';
import TaskConditionGatherComponent from './component';
import { Checkbox,  Select, TimePicker, Icon, Tooltip } from 'udesk-ui';
import './style.scss';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import FilterModalEdit from '../../../../pages/tasks/manage/template/automatic-add/filter-modal';
const Hours = Array.from(Array(24), (v, k) => k);
let getFiltedHours = () => Hours.filter(i => !(i > 1 && i < 23));
export default class TaskConditionGatherTemplate extends TaskConditionGatherComponent {
    render() {
        let { actions, locales, props, state, privates } = this;
        return (
            <div className="task-condition-gather-component">
                <For each="condition" index="index" of={privates.conditions}>
                    <div key={condition._id} className="condition-gather-component-item">
                        {/* 执行周期 */}
                        <div style={{ whiteSpace: "nowrap" }}>
                            {locales.components.conditionGather.rlue + (index + 1) + '：'}
                        </div>
                        <div style={{ background: 'rgba(0,0,0,0.03)', padding: 24, display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: 16 }}>
                                <span>{locales.components.conditionGather.cycle}<Tooltip placement="top" title={locales.business.notifyMessage.timePickerTips}><Icon antdIcon={true} style={{margin: '0 4px'}} type='QuestionCircleOutlined' /></Tooltip>：</span>
                                <Select
                                    disabled={props.disabled}
                                    value={condition.sampleConf.sampleCycle}
                                    onChange={actions.onSampleCycleChanged.params(condition.sampleConf)}
                                    className="condition-gather-component-sampling-frequency">
                                    <For each="item" index="index" of={Udesk.enums.samplingFrequencyTypes}>
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    </For>
                                </Select>
                                {/* 执行时间 */}
                                <div className="condition-gather-component-sampling-time-container">
                                    <Choose>
                                        <When condition={condition.sampleConf.sampleCycle === Udesk.enums.samplingFrequencyTypes.everyWeek.id}>
                                            <Select
                                                disabled={props.disabled}
                                                value={condition.sampleConf.dayOfWeek}
                                                onChange={actions.onValueChanged.params(condition.sampleConf, 'dayOfWeek')}
                                                className="condition-gather-component-sampling-weekday">
                                                <For each="item" index="index" of={Udesk.enums.samplingFrequencyWeekDays}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </When>
                                        <When condition={condition.sampleConf.sampleCycle === Udesk.enums.samplingFrequencyTypes.everyMonth.id}>
                                            <Select
                                                disabled={props.disabled}
                                                value={condition.sampleConf.dayOfMonth}
                                                onChange={actions.onValueChanged.params(condition.sampleConf, 'dayOfMonth')}
                                                className="condition-gather-component-sampling-weekday">
                                                <For each="item" index="index" of={props.monthDays}>
                                                    <Select.Option key={index} value={item}>
                                                        {item}
                                                        {locales.components.conditionGather.dateUnit}
                                                    </Select.Option>
                                                </For>
                                            </Select>
                                        </When>
                                    </Choose>
                                    <TimePicker
                                        id={'task-condition-gather-' + condition._id}
                                        disabled={props.disabled}
                                        disabledHours={getFiltedHours}
                                        defaultValue={moment(condition.sampleConf.executeTime, 'HH:mm:ss')}
                                        format={'HH:mm'}
                                        allowClear={false}
                                        onChange={actions.changeTime.params(condition)}
                                    />
                                </div>
                            </div>
                            <div>
                                {/* 执行范围 */}
                                <div className="condition-gather-component-sampling-time-range-container">
                                    <span className="condition-gather-component-sampling-time-range-title">
                                        {locales.components.conditionGather.sampling + '：'}
                                    </span>
                                    <Choose>
                                        <When condition={condition.sampleConf.sampleCycle === Udesk.enums.samplingFrequencyTypes.everyDay.id}>
                                            <Select
                                                disabled={props.disabled}
                                                value={condition.sampleConf.sampleScope}
                                                onChange={actions.onValueChanged.params(condition.sampleConf, 'sampleScope')}
                                                className="condition-gather-component-sampling-time-range">
                                                <For each="item" index="index" of={Udesk.enums.samplingEveryDayTimeTypes}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </When>
                                        <When condition={condition.sampleConf.sampleCycle === Udesk.enums.samplingFrequencyTypes.everyWeek.id}>
                                            <Select
                                                disabled={props.disabled}
                                                value={condition.sampleConf.sampleScope}
                                                onChange={actions.onValueChanged.params(condition.sampleConf, 'sampleScope')}
                                                className="condition-gather-component-sampling-time-range">
                                                <For each="item" index="index" of={Udesk.enums.samplingEveryWeekTimeTypes}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </When>
                                        <When condition={condition.sampleConf.sampleCycle === Udesk.enums.samplingFrequencyTypes.everyMonth.id}>
                                            <Select
                                                disabled={props.disabled}
                                                value={condition.sampleConf.sampleScope}
                                                onChange={actions.onValueChanged.params(condition.sampleConf, 'sampleScope')}
                                                className="condition-gather-component-sampling-time-range">
                                                <For each="item" index="index" of={Udesk.enums.samplingEveryMonthTimeTypes}>
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                </For>
                                            </Select>
                                        </When>
                                    </Choose>
                                </div>
                                {/* 抽样形式 */}
                                <div className="condition-gather-component-sampling-type-container">
                                    <Select
                                        disabled={props.disabled}
                                        value={condition.sampleConf.sampleForm}
                                        onChange={actions.onValueChanged.params(condition.sampleConf, 'sampleForm')}
                                        className="condition-gather-component-sampling-type">
                                        <For each="item" index="index" of={Udesk.enums.samplingTypes}>
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        </For>
                                    </Select>
                                    {/* 随机或者比例抽取的值 */}
                                    <Choose>
                                        <When condition={condition.sampleConf.sampleForm === Udesk.enums.samplingTypes.random.id}>
                                            <input
                                                type="text"
                                                disabled={props.disabled}
                                                className="form-control condition-gather-component-sampling-number"
                                                value={condition.sampleConf.random || ''}
                                                onChange={actions.onValueChanged.params(condition.sampleConf, 'random')}
                                            />
                                        </When>
                                        <Otherwise>
                                            <input
                                                type="number"
                                                disabled={props.disabled}
                                                min="0"
                                                max="100"
                                                className="form-control condition-gather-component-sampling-number"
                                                value={condition.sampleConf.percent || ''}
                                                onChange={actions.onValueChanged.params(condition.sampleConf, 'percent')}
                                            />
                                        </Otherwise>
                                    </Choose>
                                    <span className="condition-gather-component-sampling-number-unit">
                                        <Choose>
                                            <When condition={condition.sampleConf.sampleForm === Udesk.enums.samplingTypes.random.id}>
                                                {locales.components.conditionGather.item}
                                            </When>
                                            <Otherwise>
                                                {locales.components.conditionGather.percent}
                                            </Otherwise>
                                        </Choose>
                                    </span>
                                </div>
                            </div>
                            <div style={{ width: '100%', marginTop: 24 }}>
                                {/* 客服抽取相同数量 */}
                                <Checkbox
                                    disabled={props.disabled}
                                    checked={condition.sampleConf.sampleAvg === 1}
                                    onChange={actions.changeSampleAvg.params(condition)}>
                                    {locales.components.conditionGather.samplingSame}
                                </Checkbox>
                                {/* 是否可以重复抽取 */}
                                <Checkbox
                                    disabled={props.disabled}
                                    checked={condition.sampleConf.sampleReCheck === 1}
                                    onChange={actions.changeSampleRecheck.params(condition)}>
                                    {locales.components.conditionGather.samplingRecheck}
                                </Checkbox>
                            </div>
                            {/* 筛选条件 */}
                            {
                                condition.baseFilter.conditionList && !condition.baseFilter.conditionList.length ?
                                    <div
                                        className="rule-item-rule-filter-btn"
                                        onClick={actions.changeBaseFilterVisible.params(condition)}>
                                        <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1"></i>
                                        {locales.pages.tasks.manage.template.automaticAdd.index.screeningCondition}
                                    </div> :
                                    <FilterModalEdit
                                        conditionJson={privates.conditionJson[index]}
                                        judgeStrategy={condition.baseFilter.judgeStrategy}
                                        customJudgeLogic={condition.baseFilter.customJudgeLogic}
                                        conditionList={condition.baseFilter.conditionList}
                                        conditionJsonInfo={privates.conditionJsonInfo[index]}
                                        editFilterOhter={actions.editFilterOhter.params(condition)}
                                        fieldsFilterChange={actions.fieldsFilterChange}
                                        disabled={props.disabled}
                                    />
                            }
                        </div>
                        <If condition={!props.disabled}>
                            <div
                                className="condition-gather-component-delete-icon-container"
                                onClick={actions.deleteCondition.params(index)}>
                                <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-delete_minus"></i>
                                <span style={{ marginLeft: 4 }}>{locales.components.conditionGather.delete}</span>
                            </div>
                        </If>
                    </div>
                </For>
                <div className="condition-gather-component-add-icon-container">
                    <If condition={!props.disabled}>
                        <span className="condition-gather-component-add-icon" onClick={actions.addCondition}>
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-add1"></i>
                            <span>
                                {locales.components.conditionGather.add}
                            </span>
                        </span>
                    </If>
                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{locales.components.conditionGather.samplingTip}</span>
                </div>
                <CustomFilterModal
                    visible={state.baseFilterVisible}
                    cancel={actions.handleConditionCancel}
                    applyAction={actions.handleConditionApply}
                    //条件规则类型
                    judgeStrategy={state.currentCondition.baseFilter.judgeStrategy}
                    //自定义规则
                    customJudgeLogic={state.currentCondition.baseFilter.customJudgeLogic}
                    //添加的条件
                    conditionList={state.currentCondition.baseFilter.conditionList}
                    customName={privates.customName}
                    customId={privates.customId}
                    type={props.type}
                    taskId={props.taskId}
                    taskName={props.taskName}
                    filterList={['f_8', 'f_6', 'f_7', 'r_1', 'r_2', 'r_3']}
                />
                {/* <ReactModal
                    headerClassName="udesk-qa-modal-default-header"
                    title={locales.pages.tasks.manage.template.automaticAdd.index.screeningCondition}
                    // visible={state.baseFilterVisible}
                    onCancel={actions.cancelConditionListChanged}
                    footer={false}
                >
                    <div className="bs-modal-base">
                        <div className="content-box">
                            <div className="con-body form-horizontal form-label-left">
                                <CustomFilters
                                    showConditionNumber={true}
                                    allowEmptyConditions={true}
                                    conditions={state.conditionList}
                                    fields={privates.computes._fields}
                                    fieldValueSettings={privates.computes._fieldValueSettings}
                                    theme="bootstrap"
                                    onChanged={actions.conditionListChanged}
                                    fieldSelectorClass="form-control input-sm"
                                    operatorSelectorClass="form-control input-sm"
                                    languageCode={props.sdkOptions.props.language}
                                    fieldNameField="key"
                                    fieldKeyField="key"
                                    fieldTextField="label"
                                    disabled={props.disabled}
                                />
                                <Radio.Group
                                    value={state.judgeStrategy}
                                    disabled={props.disabled}
                                    onChange={actions.onStateChanged.params('judgeStrategy')}>
                                    <For each="item" index="index" of={Udesk.enums.judgeStrategy}>
                                        <Radio key={item.id} value={item.id}>{item.name}</Radio>
                                    </For>
                                </Radio.Group>
                                <If condition={state.judgeStrategy === Udesk.enums.judgeStrategy.custom.id}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ margin: '10px 0px' }}
                                        maxLength="255"
                                        value={state.customJudgeLogic}
                                        disabled={props.disabled}
                                        onChange={actions.onStateChanged.params('customJudgeLogic')}
                                    />
                                </If>
                                <div className="form-group action" style={{ textAlign: 'end' }}>
                                    <Space>
                                        <Button
                                            onClick={actions.cancelConditionListChanged}>
                                            {locales.labels.cancel}
                                        </Button>
                                        <If condition={!props.disabled}>
                                            <Button
                                                type='primary'
                                                onClick={actions.confirmConditionListChanged}>
                                                {locales.labels.ok}
                                            </Button>
                                        </If>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactModal> */}
            </div>
        );
    }
}
