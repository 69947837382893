export function getTotalTime(practiceCostTime) {
    if (typeof practiceCostTime !== 'number') return '-';
    const min = practiceCostTime / 60;
    const hour = min / 60;
    if (hour > 1) return `${hour.toFixed(2)}h`;
    if (min > 1) return `${min.toFixed(2)}min`;
    return `${practiceCostTime}s`;
}

export function getRate(rate) {
    if (typeof rate !== 'number') return '-';
    return `${rate.toFixed(2)}%`;
}
