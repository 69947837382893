import UploadBaseCredentialClass from "./base";
import { ArgumentNullError, WrongTypeError } from "../../../../error/index";
const CONNECTED_CREDENTIAL_SYMBOL = Symbol("[ConnectedCredential]");
const IS_ABORTED_SYMBOL = Symbol("[IsAborted]");

export default class UploadProxyCredentialClass extends UploadBaseCredentialClass {
    constructor() { 
        super();
        this[IS_ABORTED_SYMBOL] = false;
    }
    abort() {
        if (this[CONNECTED_CREDENTIAL_SYMBOL] != null) {
            this[CONNECTED_CREDENTIAL_SYMBOL].abort();
        }
        else {
            this[IS_ABORTED_SYMBOL] = true;
        }
    }
    get isAborted() {
        return this[IS_ABORTED_SYMBOL];
    }
    connect(credential) {
        if (credential == null) {
            throw new ArgumentNullError("credential");
        }
        if (!(credential instanceof UploadBaseCredentialClass)) {
            throw new WrongTypeError("credential", credential.name);
        }
        this[CONNECTED_CREDENTIAL_SYMBOL] = credential;
    }
}