// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { PutAccountSetReminderBody, BaseResponseVoid } from '../../../types';

/**
 * update
 *
 * @export
 * @tags 账户接口
 * @link [PUT] /account/set/reminder
 */
export function putAccountSetReminder(data: PutAccountSetReminderBody): Promise<BaseResponseVoid>;
export function putAccountSetReminder(
    data: PutAccountSetReminderBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function putAccountSetReminder(
    data: PutAccountSetReminderBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PutAccountSetReminderBody, never, never>('/account/set/reminder', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putAccountSetReminder',
    });
}

export const meta = [{ tags: ['账户接口'], path: '/account/set/reminder', method: 'put' }];
