export default {
    truncate,
    toolboxPosition
};

function truncate(targetString, config) {
    let {
        autoTruncateLabel,
        truncateCharacter,
        labelTruncateLength,
        labelTruncateMode
    } = config;
    let strLength = targetString ? targetString.length : 0;
    if (autoTruncateLabel && strLength > labelTruncateLength && strLength > 3) {
        /*eslint-disable */
        switch (labelTruncateMode) {
            case "left":
                return truncateCharacter + targetString.splice(0, strLength - labelTruncateLength);
            case "middle":
                let headStrLen = Math.ceil(labelTruncateLength / 2);
                let footStrLen = Math.floor(labelTruncateLength / 2);
                let reg = new RegExp(`^(.{${headStrLen}}).+(.{${footStrLen}})$`, 'g');
                return targetString.replace(reg, `$1${truncateCharacter}$2`);
            case "right":
                return targetString.slice(0, labelTruncateLength) + truncateCharacter;
        }
         /*eslint-enable */
    } else {
        return targetString;
    }
}

function toolboxPosition(position) {
    /*eslint-disable */
    switch (position) {
        case "top":
            return { top: 20 };
        case "bottom":
            return { bottom: 20 };
        case "left":
            return { left: 20 };
        case "right":
            return { right: 20 };
    }
     /*eslint-enable */
}
