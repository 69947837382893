import React, { useState, useMemo, useEffect } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { useVideoDemoRequest } from 'src/util/hook';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';
import styled from 'styled-components';
import { Dialogue } from './components/Dialogue';
import { useScroll } from './hooks/useScroll';
import { Viewpoint } from './components/Viewpoint';
import UdeskLocales from 'UdeskLocales';
import { Button, Tabs } from 'udesk-ui';
import { Outline } from './components/Outline';

const Wrap = styled.div`
    display: flex;
    height: 100%;

    .left-part {
        flex: 1;
        display: flex;
        flex-direction: column;

        video {
            width: 100%;
            margin-bottom: 16px;
            border-radius: 5px;
            max-height: 40%;
            background-color: #000;
        }
        > div {
            flex: 1;
            display: flex;
            overflow: hidden;
            flex-direction: column;
        }
    }
    .right-part {
        flex: 1;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-left: 1px solid #f0f0f0;
        padding-left: 16px;
        .udesk-qa-ui-tabs-tab {
            font-size: 16px;
            padding-top: 0;
        }
        .udesk-qa-ui-tabs-content {
            height: 100%;
            .udesk-qa-ui-tabs-tab {
                font-size: 14px;
            }
        }
        .udesk-qa-ui-tabs-tabpane {
            display: flex;
            flex-direction: column;
            .summary-content {
                overflow-y: auto;
                flex: 1;
            }
        }
        > .summary-content {
            /* max-height: 30%; */
            overflow: auto;
        }
    }
`;

const Template = React.memo((props: any) => {
    const { match } = props;
    const id = match.params.id;
    const [detail, setDetail] = useState<any>({});
    const [summaries, setSummaries] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { ref, ...dialogueProps } = useScroll({
        dialogue: detail.dialogue,
    });
    console.log('ref', ref);

    const request = useVideoDemoRequest({
        ...props.sdkOptions,
        props: {
            ...props.sdkOptions.props,
            isVideo: true,
        },
    });
    const requestSummaries = () => {
        request(`video-summary/${id}`).then((resp) => {
            console.log('resp', resp);
            setSummaries(resp);
        });
    };
    useEffect(() => {
        setLoading(true);
        request(`video-upload-history/${id}`).then((resp) => {
            console.log('resp', resp);
            setDetail(resp);
            setLoading(false);
        });
        requestSummaries();
    }, []);

    const onSummaryAdd = (type, data) => {
        const content = detail.dialogue
            .filter((i) => {
                if (type === 1) return true;

                return i.speaker === data.speaker;
            })
            .map((i) => i.text)
            .join();
        return request(
            `video-summary/create/${id}`,
            {
                ...data,
                content,
                type,
            },
            'POST'
        ).then((resp) => {
            console.log('resp', resp);
            requestSummaries();
        });
    };
    const speakerList = useMemo(() => {
        if (!Array.isArray(detail.dialogue)) return [];

        return Array.from(
            detail.dialogue.reduce((p, c) => {
                p.add(c.speaker);
                return p;
            }, new Set())
        );
    }, [detail.dialogue]);

    const onRemove = (id) => {
        return request(`video-summary/${id}`, {}, 'del').then((resp) => {
            requestSummaries();
        });
    };

    const TABS = [
        { id: 'summary', name: /* 概要 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.index.outline },
        { id: 'viewpoint', name: /* 观点 */UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis.detail.index.viewpoint },
        // {
        //     id: 'keyPoints',
        //     name: /* 要点总结 */ UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis
        //         .detail.index.summaryOfKeyPoints,
        // },
        { id: 'qa', name: 'QA' },
    ];

    const summaryData = useMemo(() => summaries.filter((i) => i.type === 1), [summaries]);
    return (
        <Page
            pageBodyClassName="video-analysis-list-page-index"
            title={
                detail.fileName ||
                /* 视频分析 */ UdeskLocales['current'].pages.conversationAnalysis.videoAnalysis
                    .detail.index.videoAnalysis
            }
            padding={true}
            onBack={() => window.history.back()}
            extra={[
                <Button
                    onClick={() => {
                        request(
                            `conversational-actions/create`,
                            {
                                bindId: id,
                                dialogue: detail.dialogue,
                            },
                            'post'
                        );
                    }}
                >
                    123
                </Button>,
            ]}
        >
            <Wrap>
                <div className="left-part">
                    <video src={detail.ossKey} controls={true} ref={ref} />
                    <Dialogue loading={loading} data={detail.dialogue} {...dialogueProps} />
                </div>
                <div className="right-part">
                    <Tabs>
                        {TABS.map((i) => (
                            <Tabs.TabPane tab={i.name} key={`${i.id}`}>
                                {
                                    {
                                        summary: (
                                            <Outline
                                                summaryData={summaryData}
                                                onAdd={onSummaryAdd.bind(null, 1)}
                                                onRemove={onRemove}
                                                loading={loading}
                                                request={request}
                                                bindId={id}
                                            />
                                        ),
                                        viewpoint: (
                                            <Viewpoint
                                                data={summaries
                                                    .filter((i) => i.type === 4)
                                                    .concat(summaries.filter((i) => i.type === 2))}
                                                onAdd={onSummaryAdd.bind(null, 2)}
                                                speakerList={speakerList}
                                                onRemove={onRemove}
                                                loading={loading}
                                            />
                                        ),
                                        // keyPoints: (
                                        //     <Viewpoint
                                        //         data={summaries.filter((i) => i.type === 4)}
                                        //         // onAdd={onSummaryAdd.bind(null, 2)}
                                        //         speakerList={speakerList}
                                        //         // onRemove={onRemove}
                                        //         isKeyPoints={true}
                                        //         loading={loading}
                                        //     />
                                        // ),
                                        qa: (
                                            <Viewpoint
                                                data={summaries.filter((i) => i.type === 3)}
                                                // onAdd={onSummaryAdd.bind(null, 2)}
                                                speakerList={speakerList}
                                                // onRemove={onRemove}
                                                isKeyPoints={true}
                                                loading={loading}
                                            />
                                        ),
                                    }[i.id]
                                }
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </div>
            </Wrap>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
