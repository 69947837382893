import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import useResizeObserver from 'use-resize-observer';
import { useChartOption } from './hooks/useChartOption';
import Header from './components/header';
import { StandardizeTable } from 'udesk_gm_ui';
import { message, Spin } from 'udesk-ui';
import { ChartType } from './components/header/components/ChartToggle/hooks';
import * as echarts from 'echarts';
import styled from 'styled-components';
import { postIntelligentPartnerAiAnalysisTextToSqlResultFindAll } from 'src/api/intelligentPartner/ai/analysis/textToSql/result/findAll/index';
import { postIntelligentPartnerAiAnalysisTextToSqlResultExport } from 'src/api/intelligentPartner/ai/analysis/textToSql/result/export/index';
import { getSystemModule } from 'Udesk/system/subApp';
import { AiTextToSqlResult } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    height: 300px;
    overflow-y: auto;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #eee;
    border-radius: 4px;
`;

const Main = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: #fff;
    padding: 20px;
`;

const Content = styled.div`
    height: calc(100% - 32px);
    width: 100%;
`;

const Chart = styled.div`
    height: 220px;
`;

type TemplateProps = AiTextToSqlResult & {
    activeKey?: string;
    getChart?: any;
    canModify?: boolean;
};

const Template: FC<TemplateProps> = React.memo((props) => {
    const {
        title: curTitle,
        sql: curSql,
        xcolumns,
        ycolumns: curYfields = [],
        columnEnumMap: curColumnEnumMap,
        id: chatId,
        activeKey,
        getChart,
        canModify,
    } = props;

    const curXfield = xcolumns?.[0] || '';

    const [staticData, setStaticData] = useState<any[]>([]);

    const { run: getData, loading: getDataLoading } = useRequest(
        postIntelligentPartnerAiAnalysisTextToSqlResultFindAll,
        {
            manual: true,
            onSuccess: (res) => {
                setStaticData(res.data || []);
                if (chartType === ChartType.Table) {
                    setPagination({
                        total: res.paging?.total || 0,
                        current: res.paging?.pageNum || 1,
                        pageSize: res.paging?.pageSize || 20,
                    });
                }
            },
        }
    );

    const [chartType, setChartType] = useState(ChartType.Table);

    const onChartTypeChange = (v) => {
        setChartType(v);
        if (v === ChartType.Table) {
            setPagination({
                total: 0,
                current: 1,
                pageSize: 20,
            });
        } else {
            getData(
                {
                    sql: curSql,
                    columnEnumMap: curColumnEnumMap,
                },
                {}
            );
            echartInstance && echartInstance?.clear();
        }
    };

    const refresh = () => {
        let params: any = {
            sql: curSql,
            columnEnumMap: curColumnEnumMap,
        };

        if (chartType === ChartType.Table) {
            setPagination({
                total: 0,
                current: 1,
                pageSize: 20,
            });

            params = {
                ...params,
                pageNum: 1,
                pageSize: 20,
            };
        }

        getData(params, {});
    };

    const domInstance = useRef(null);
    const [echartInstance, setEchartInstance] = useState<any>(null);

    useEffect(() => {
        if (chartType !== ChartType.Table) {
            const c = echarts.init(domInstance.current);
            setEchartInstance(c);
        }
    }, [chartType]);

    const { chartOption } = useChartOption({
        xField: curXfield,
        yFields: curYfields,
        staticData,
        chartType,
    });

    useEffect(() => {
        if (echartInstance && chartOption) {
            echartInstance.setOption(chartOption);
        }
    }, [echartInstance, chartOption]);

    const { ref: wrapperRef, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        requestIdleCallback(() => {
            echartInstance?.resize?.();
        });
    }, [width, height, echartInstance]);

    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 20,
    });

    const getTableSource = (data) => {
        const { pagination } = data;

        curSql &&
            getData(
                {
                    sql: curSql,
                    columnEnumMap: curColumnEnumMap,
                    pageNum: pagination.current,
                    pageSize: pagination.pageSize,
                },
                {}
            );
    };

    const tableProps = useMemo(() => {
        const columns = [curXfield, ...curYfields]
            .filter((c) => !!c)
            .map((c) => {
                return {
                    title: c,
                    key: c,
                    dataIndex: c,
                };
            });

        return {
            columns,
            dataSource: staticData,
            pagination,
            scroll: { y: 120 },
            size: 'small',
        };
    }, [curXfield, curYfields, pagination, staticData]);

    const [exportLoading, setExportLoading] = useState(false);

    const onExport = () => {
        if (curSql) {
            setExportLoading(true);
            postIntelligentPartnerAiAnalysisTextToSqlResultExport({
                sql: curSql,
                columns: [curXfield, ...curYfields].filter((c) => !!c),
                columnEnumMap: curColumnEnumMap,
                systemModule: getSystemModule(),
            })
                .then(() => {
                    message.success(/* 导出成功，请前往任务中心查看 */UdeskLocales['current'].pages.coach.dataAnalysis.customKanban.components.chart.index.exportSuccessfulPleaseGoToTheTaskCenterToCheck);
                })
                .finally(() => {
                    setExportLoading(false);
                });
        }
    };

    return (
        <Wrapper ref={wrapperRef}>
            <Main>
                <Spin spinning={getDataLoading || exportLoading}>
                    <Header
                        pageTitle={curTitle}
                        chartType={chartType}
                        onChartTypeChange={onChartTypeChange}
                        chartDisable={!curXfield}
                        pieChartDisabled={curYfields.length > 1}
                        onExport={onExport}
                        chatId={chatId}
                        activeKey={activeKey}
                        getChart={getChart}
                        sql={curSql}
                        refresh={refresh}
                        canModify={canModify}
                    />

                    <Content>
                        {chartType === ChartType.Table ? (
                            <StandardizeTable.RequestTable
                                onRequest={getTableSource}
                                table={tableProps as any}
                            />
                        ) : (
                            <Chart ref={domInstance}></Chart>
                        )}
                    </Content>
                </Spin>
            </Main>
        </Wrapper>
    );
});

export default Template;
