import React, { useState, useRef, forwardRef, useCallback /*useContext*/ } from 'react';
// import ImageViewer from 'udesk-react/src/components/image-viewer';
import Udesk from 'Udesk';
import './style.scss';
import { Popover, Divider, Icon, Card, Tooltip, Input, Button, Modal } from 'udesk-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import XssComponent from 'udesk-react/src/components/xss';
import ImageViewer from 'udesk-react/src/components/image-viewer';
import ReactTinymce from 'udesk-react/src/components/react-tinymce';
import Locales from 'UdeskLocales';
import { Remark, CaseBase, ErrorCorrection } from './item-bottoms';
import TicketTextInfo from 'Component/pages/components/ticket-text-info/index';
import { replaceLineBreak, replaceLink } from 'src/util/text-replace';
// import { playIconContext } from 'src/components/pages/gong/sale-client-center/detail';

const { Meta } = Card;

interface ChatMessageType {
    type: 'message' | 'unknown';
}
interface ContentComponentProps {
    itemData: any;
    measure?: any;
    allData?: any;
    // hasCached?: boolean;
    // setCached?: () => void;
    index?: number;
    onMarkSemanticClick?: (content: string, index: number) => void;
    isEditing?: boolean;
    onInputValueChange?: (e: any) => void;
    tinymcePath?: any;
    itemDataType?: 'call';
    voice_url?: string;
    describes?: any;
    msgTime?: any;
    virtual?: boolean;
    type?: string;
    created_at?: string;
    isLast?: boolean;
    titleRef?: any;
    asrTransContent?: string;
}

const textStyles: React.CSSProperties = {
    cursor: 'pointer',
};
const Text = forwardRef<HTMLDivElement, ContentComponentProps>(
    (
        {
            itemData,
            onMarkSemanticClick,
            isEditing,
            onInputValueChange,
            tinymcePath,
            itemDataType,
            index,
            voice_url,
            ...allData
        },
        ref
    ) => {
        // const isPlayIconContext = useContext(playIconContext);
        itemData = itemData ? (typeof itemData === 'string' ? itemData : itemData.content) : '';
        const onClick = () => {
            typeof index === 'number' &&
                typeof onMarkSemanticClick === 'function' &&
                onMarkSemanticClick(itemData, index);
        };
        // const instance = useRef(null);

        return (
            <div
                ref={ref}
                id={`audio-text-list-item-${index ?? 0 + 1}`}
                className="udesk-qa-chat-record-item-chat-bubble-content udesk-qa-chat-record-item-chat-bubble-content-text"
                style={textStyles}
                onClick={onClick}
            >
                {/* 通话语音 */}
                {itemDataType === 'call' && (
                    <Button
                        icon={<Icon type="ic-play-circle-bofang" />}
                        onClick={(allData as any)?.sendCurrentTimeRange}
                        type="text"
                        size="small"
                    />
                )}
                {/* 文本类型语音 */}
                {voice_url && (
                    <Button
                        icon={
                            <Icon
                                type={
                                    (allData as any).isPlaying !== index
                                        ? 'ic-play-circle-bofang'
                                        : 'ic-pause-circle-zanting'
                                }
                            />
                        }
                        onClick={(allData as any).playRudio.bind(null, voice_url, index)}
                        type="text"
                        size="small"
                    />
                )}
                {isEditing ? (
                    itemDataType === 'call' ? (
                        <Input value={itemData} onChange={onInputValueChange} />
                    ) : (
                        tinymcePath && (
                            <ReactTinymce
                                value={Udesk.business.emoji.replaceWechatEmotions(itemData)}
                                onEditorChange={onInputValueChange}
                                tinymcePath={tinymcePath}
                            />
                        )
                    )
                ) : (
                    <div style={{ flex: 1 }}>
                        <XssComponent
                            value={Udesk.business.emoji.replaceWechatEmotions(
                                replaceLineBreak(replaceLink(itemData))
                            )}
                        />
                    </div>
                )}
            </div>
        );
    }
) as (
    props: React.PropsWithChildren<ContentComponentProps> & { ref?: React.Ref<HTMLDivElement> }
) => React.ReactNode;

const imageStyles = { width: '100%', height: '100%', maxWidth: 300 };
const Image: React.FC<ContentComponentProps> = ({ itemData }) => {
    return (
        <div className="udesk-qa-chat-record-item-chat-bubble-content udesk-qa-chat-record-item-chat-bubble-content-image">
            <ImageViewer>
                <img src={itemData} alt="" style={imageStyles} />
            </ImageViewer>
        </div>
    );
};

const Unknow: React.FC<ContentComponentProps> = ({ itemData, measure, index, ...allData }) => {
    const locales = Locales['current'];

    return (
        <Popover
            content={
                typeof itemData === 'object' && itemData !== null
                    ? JSON.stringify(itemData)
                    : typeof itemData === 'string'
                    ? itemData
                    : ''
            }
        >
            <div className="udesk-qa-chat-record-item-chat-bubble-content">
                {locales.components.pages.appealChekingTask.list.unsupportedFormat}
                {(allData as any).type}
            </div>
        </Popover>
    );
};

const ShardingInformation: React.FC<ContentComponentProps> = ({ itemData, measure, index }) => {
    const locales = Locales['current'];

    return (
        <Divider plain>
            {Udesk.utils.string.format(
                locales.components.pages.appealChekingTask.list.shardingInformation,
                itemData
            )}
        </Divider>
    );
};

const sysTypeStyle: React.CSSProperties = {
    color: 'rgba(0,0,0,.35)',
    textAlign: 'center',
    margin: '0 auto',
};
const Sys = forwardRef<HTMLDivElement, ContentComponentProps>(
    ({ itemData, measure, index, msgTime }, ref) => {
        return (
            <div style={sysTypeStyle}>
                <div ref={ref} style={{ fontSize: '12px' }}>
                    {msgTime}
                </div>
                <div>{itemData}</div>
            </div>
        );
    }
);
const Rich: React.FC<ContentComponentProps> = forwardRef(
    ({ itemData, measure, index }, ref: any) => {
        return (
            <div className="udesk-qa-chat-record-item-chat-bubble-content">
                <div
                    ref={ref}
                    id={`audio-text-list-item-${index ?? 0 + 1}`}
                    className="udesk-qa-chat-record-item-chat-bubble-content-rich"
                >
                    <ImageViewer>
                        <XssComponent
                            value={Udesk.business.emoji.replaceWechatEmotionsInRich(itemData)}
                        />
                    </ImageViewer>
                </div>
            </div>
        );
    }
);

const structStyles: React.CSSProperties = {
    maxWidth: 300,
};
const Struct: React.FC<ContentComponentProps> = ({
    itemData,
    measure,
    index,
    describes,
    ...allData
}) => {
    // const item: any = allData; //这个地方在某特殊情况下应该不是从这里取值的，但未复现该情况
    const locales = Locales['current'];

    const [copied, setCopied] = useState(false);
    const copyText = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };
    return (
        <Card style={structStyles} cover={<img alt="" src={itemData.img_url} />}>
            <Meta
                description={
                    <div>
                        {itemData.title && (
                            <div title={itemData.title} className="struct-type-card-item">{`${
                                describes && describes.find((item) => item.key === 'title')
                                    ? describes.find((item) => item.key === 'title').text
                                    : 'title'
                            }: ${itemData.title}`}</div>
                        )}
                        {itemData.description && (
                            <div
                                title={itemData.description}
                                className={`struct-type-card-item${
                                    itemData.buttons && itemData.buttons.length
                                        ? ' struct-type-card-item-bottom'
                                        : ''
                                }`}
                            >{`${
                                describes && describes.find((item) => item.key === 'description')
                                    ? describes.find((item) => item.key === 'description').text
                                    : 'description'
                            }: ${itemData.description}`}</div>
                        )}
                        {itemData.buttons.map((item, index) => {
                            return (
                                <CopyToClipboard key={index} text={item.value} onCopy={copyText}>
                                    <Tooltip
                                        key={index}
                                        trigger={['hover']}
                                        title={copied ? locales.labels.copyed : item.value}
                                    >
                                        <div
                                            key={index}
                                            className="struct-type-card-item"
                                        >{`${item.text}: ${item.value}`}</div>
                                    </Tooltip>
                                </CopyToClipboard>
                            );
                        })}
                    </div>
                }
            />
        </Card>
    );
};

const Video: React.FC<ContentComponentProps> = ({ itemData, ...allData }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const vedioDom = useRef<HTMLVideoElement | null>(null);
    const openModal = () => {
        setVisible(true);
    };
    itemData = itemData?.replace?.(/\S*https?:\/\//, 'https://') ?? undefined;
    const play = () =>
        allData.virtual ? openModal() : (vedioDom?.current as any)?.webkitRequestFullScreen?.();
    return (
        <div className="udesk-qa-chat-record-item-chat-bubble-content">
            <video
                style={{ ...imageStyles, position: 'relative' }}
                ref={vedioDom}
                onClick={play}
                // onClick={() => (vedioDom?.current as any)?.webkitRequestFullScreen?.()}
                className="audio-text-list-item-text-video"
                controls={!allData.virtual}
                // controls={true}
                src={itemData}
            ></video>
            <div onClick={play} className="audio-text-list-item-text-video-play">
                <Icon
                    type="PlayCircleFilled"
                    antdIcon={true}
                    style={{ background: '#fff', borderRadius: '50%' }}
                />
            </div>
            <Modal visible={visible} onCancel={() => setVisible(false)} footer={null}>
                <video
                    controls={true}
                    src={itemData}
                    style={{ width: '100%', maxHeight: '70vh', maxWidth: '70vw' }}
                ></video>
            </Modal>
        </div>
    );
};

const File: React.FC<ContentComponentProps> = ({ itemData, ...allData }) => {
    return (
        <div className="udesk-qa-chat-record-item-chat-bubble-content">
            <a href={itemData}>{(allData as any).filename}</a>
            {(allData as any).filesize && (
                <span className="audio-text-list-item-file-size">{`(${
                    (allData as any).filesize
                })`}</span>
            )}
        </div>
    );
};

const Audio: React.FC<ContentComponentProps> = ({ itemData, asrTransContent, ...allData }) => {
    return (
        <div className="udesk-qa-chat-record-item-chat-bubble-content">
            <span>{asrTransContent}</span>
            <Button
                icon={
                    <Icon
                        type={
                            (allData as any).isPlaying !== (allData as any).index
                                ? 'ic-play-circle-bofang'
                                : 'ic-pause-circle-zanting'
                        }
                    />
                }
                onClick={(allData as any).playRudio.bind(null, itemData, (allData as any).index)}
                type="text"
                size="small"
            />
        </div>
    );
};

const Link: React.FC<ContentComponentProps> = ({ itemData, ...allData }) => {
    itemData = formatContent(itemData);
    const openLink = useCallback(() => {
        itemData?.linkUrl && window.open(itemData?.linkUrl);
    }, [itemData?.linkUrl]);
    return (
        <div className="udesk-qa-chat-record-item-chat-bubble-content">
            <a onClick={openLink} style={{ textDecorationLine: 'underline', color: '#1b6dff' }}>
                {itemData?.title || itemData?.linkUrl}
            </a>
        </div>
    );
};

const Emotion: React.FC<ContentComponentProps> = Image;

const Weapp: React.FC<ContentComponentProps> = ({ itemData, ...allData }) => {
    itemData = formatContent(itemData);
    const locales = Locales['current'];
    return (
        <div className="chat-record-component-chat-card-weapp">
            <div className="chat-record-component-chat-card-weapp-title">
                <Icon classname="weapp-icon" type="Frame11" />
                <div style={{ marginLeft: 8, maxWidth: 214 }}>{itemData.displayName}</div>
            </div>
            <div style={{ marginLeft: 15, marginRight: 15, maxWidth: 244 }}>{itemData.title}</div>
            <div className="chat-record-component-chat-card-weapp-content">
                <Icon classname="weapp-icon-big" type="Frame2" />
            </div>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <div className="chat-record-component-chat-card-weapp-bottom">
                {locales.labels.weapp}
            </div>
        </div>
    );
};

const Redpacket: React.FC<ContentComponentProps> = ({ itemData, ...allData }) => {
    itemData = formatContent(itemData);
    const locales = Locales['current'];
    return (
        <div className="chat-record-component-chat-card-redpacket">
            <div className="chat-record-component-chat-card-redpacket-top">
                <Icon classname="redpacket-icon" type="Frame157" />
                <div className="chat-record-component-chat-card-redpacket-content">
                    {itemData.wish}
                </div>
            </div>
            <div className="chat-record-component-chat-card-redpacket-bottom">
                <span style={{ marginLeft: 8, marginTop: 2 }}>
                    {
                        locales.labels[
                            allData.type === 'all_red_packet' ? 'redpacket' : 'externalRedpacket'
                        ]
                    }
                </span>
            </div>
        </div>
    );
};

const UserCard: React.FC<ContentComponentProps> = ({ itemData, ...allData }) => {
    itemData = formatContent(itemData);
    const locales = Locales['current'];
    return (
        <div className="chat-record-component-chat-card-card">
            <div className="chat-record-component-chat-card-card-top">
                <div className="chat-record-component-chat-card-card-top-box">
                    <div className="chat-record-component-chat-card-card-corp-name">
                        {itemData.corpName}
                    </div>
                    <div className="chat-record-component-chat-card-card-name">
                        {itemData.userId}
                    </div>
                </div>
                <Icon type="touxiangzhanwei" style={{ fontSize: '40px' }} />
                {/* <img src={props.content.user.avatar || avatarUrl} alt="" width={40} height={40} /> */}
            </div>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <div className="chat-record-component-chat-card-card-bottom">
                {locales.labels.businessCard}
            </div>
        </div>
    );
};

const Sphfeed: React.FC<ContentComponentProps> = ({ itemData, ...allData }) => {
    const locales = Locales['current'];
    itemData = formatContent(itemData);
    const getIconType = (feedType: number) => {
        if (feedType === 2) {
            return 'ic-img1';
        } else if (feedType === 9) {
            return 'a-Frame222';
        }
        return 'a-Frame220';
    };
    return (
        <div className={'chat-record-component-sphfeed'}>
            <div className={'chat-record-component-sphfeed-imgbox'}>
                <Icon classname="sphfeed-icon" type={getIconType(itemData?.feedType)} />
            </div>
            <div className={'chat-record-component-sphfeed-name'}>{itemData?.sphName}</div>
            <div className={'chat-record-component-sphfeed-desc'}>{itemData?.feedDesc}</div>
            <Divider style={{ marginTop: 8, marginBottom: 5 }} />
            <div className={'chat-record-component-sphfeed-msgtype'}>
                <Icon type="ic-videoup" style={{ fontSize: 16, marginRight: 5 }} />
                <div className={'chat-record-component-sphfeed-title'}>
                    {locales.labels.sphfeed}
                </div>
            </div>
        </div>
    );
};

const ChatRecord: React.FC<ContentComponentProps> = ({ itemData, ...allData }) => {
    const locales = Locales['current'];
    itemData = formatContent(itemData);

    const [visible, setVisible] = useState(false);
    const open = () => {
        setVisible(true);
    };
    const handleCancel = (e: any) => {
        setVisible(false);
        e.stopPropagation();
    };
    const getChatrecordContent = (item: any) => {
        let content = '';
        switch (item.type) {
            case 'chatrecord':
                content =
                    locales.components.pages.appealChekingTask.list.chatMessageTypes.chatrecord;
                break;
            case 'mixed':
                content = locales.components.pages.appealChekingTask.list.chatMessageTypes.mixed;
                break;
            case 'link':
                content = locales.components.pages.appealChekingTask.list.chatMessageTypes.link;
                break;
            case 'voice':
                content = locales.components.pages.appealChekingTask.list.chatMessageTypes.voice;
                break;
            case 'video':
                content = locales.components.pages.appealChekingTask.list.chatMessageTypes.video;
                break;
            case 'file':
                content = locales.components.pages.appealChekingTask.list.chatMessageTypes.file;
                break;
            case 'emotion':
                content = locales.components.pages.appealChekingTask.list.chatMessageTypes.emotion;
                break;
            case 'image':
                content = locales.components.pages.appealChekingTask.list.chatMessageTypes.image;
                break;
            case 'agree':
                content = locales.components.pages.appealChekingTask.list.chatMessageTypes.agree;
                break;
            case 'redpacket':
                content =
                    locales.components.pages.appealChekingTask.list.chatMessageTypes.redpacket;
                break;
            default:
                content = item.content.content;
        }
        return content;
    };
    return (
        <div className="udesk-qa-chat-record-item-chat-bubble-content chat-record" onClick={open}>
            <div className="chat-record-component-chat-card-chatrecord-title">
                {itemData?.title ||
                    locales.components.pages.appealChekingTask.list.chatMessageTypes
                        .chatRecordTitle}
            </div>
            {itemData?.item
                ?.filter((item: any, i: number) => i < 3)
                .map((item: any) => {
                    return (
                        <div className="chat-record-component-chat-card-chatrecord-content">
                            {getChatrecordContent(item)}
                        </div>
                    );
                }) || null}
            <Modal
                title={
                    itemData?.title ||
                    locales.components.pages.appealChekingTask.list.chatMessageTypes.chatRecordTitle
                }
                visible={visible}
                footer={null}
                onCancel={handleCancel}
                centered={true}
                // getContainer={false}
                wrapClassName="chat-record-component-chat-card-chatrecord-modal"
            >
                <div
                    style={{ margin: '-8px -4px', height: '500px', overflow: 'auto' }}
                    className="chat-record-modal-body"
                >
                    {itemData?.item?.map((item: any) => {
                        const Component: any = configs.has(item.type)
                            ? configs.get(item.type)
                            : () => null;
                        return (
                            <div style={{ marginBottom: 16 }}>
                                {item.type !== 'file' && item.type !== 'chatrecord' ? (
                                    <Component {...item} itemData={formatContent(item.content)} />
                                ) : (
                                    <div style={{ padding: 8 }}>
                                        <Component
                                            {...item}
                                            itemData={formatContent(item.content)}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    }) || null}
                </div>
            </Modal>
        </div>
    );
};

const Ticket: React.FC<ContentComponentProps> = forwardRef((props, ref) => {
    // const locales = Locales['current'];

    return (
        <div className="udesk-qa-chat-record-item-chat-bubble-content">
            <TicketTextInfo
                item={{ data: { ...props, createdTime: props.created_at } }}
                isLastItem={props.isLast}
                content={props.itemData}
                titleRef={props.titleRef}
                ref={ref}
            />
        </div>
    );
});

const configs = new Map([
    ['message', React.memo(Text as any)],
    ['ticket', React.memo(Ticket as any)],
    ['text', React.memo(Text as any)],
    ['shardingInformation', React.memo(ShardingInformation)],
    ['unknown', React.memo(Unknow)],
    // ['unknown', React.memo(ChatRecord)],
    ['rich', React.memo(Rich)],
    ['struct', React.memo(Struct)],
    ['image', React.memo(Image)],
    ['video', React.memo(Video)],
    ['file', React.memo(File)],
    ['audio', React.memo(Audio)],
    // ====wf====
    ['link', React.memo(Link)],
    ['chatrecord', React.memo(ChatRecord)],
    ['emotion', React.memo(Emotion)],
    ['weapp', React.memo(Weapp)],
    ['card', React.memo(UserCard)],
    ['all_red_packet', React.memo(Redpacket)],
    ['external_redpacket', React.memo(Redpacket)],
    ['redpacket', React.memo(Redpacket)],
    ['sphfeed', React.memo(Sphfeed)],
    ['voice', React.memo(Audio)],
    // ====systom message====
    ['survey', React.memo(Sys)],
    ['form', React.memo(Sys)],
    ['form_received', React.memo(Sys)],
    ['transfer_send', React.memo(Sys)],
    ['info_transfer', React.memo(Sys)],
    ['start_session', React.memo(Sys)],
    ['lock', React.memo(Sys)],
    ['invitation', React.memo(Sys)],
    ['customer_close', React.memo(Sys)],
    // ['Agree', (props: any) => <Agree {...props} />],
    // ['Video', (props: any) => <Video {...props} />],
    // ['Chatrecord', (props: any) => <Chatrecord {...props} />],
    // ['Link', (props: any) => <Link {...props} />],
    // ['File', (props: any) => <File {...props} />],
    // ['Mixed', (props: any) => <Mixed {...props} />],
    // ['Emotion', (props: any) => <Image {...props} />],
    // ['Default', (props: any) => <Text {...props} />],
    // ['DeleteFriend', (props: any) => <DeleteFriend {...props} />],
    // ['Redpacket', (props: any) => <Redpacket {...props} />],
    // ['ExternalRedpacket', (props: any) => <Redpacket {...props} />],
    // ['Card', (props: any) => <Card {...props} />],
    // ['Weapp', (props: any) => <Weapp {...props} />],
    // ['MeetingVoicevCall', (props: any) => <MeetingVoicevCall {...props} />],
]);

const ChatCardContentFactory = (type: ChatMessageType['type']) => {
    return configs.has(type) ? configs.get(type) : configs.get('unknown');
};
const SYS_MESSAGE_TYPES = [
    'transfer_send',
    'survey',
    'form',
    'form_received',
    'info_transfer',
    'customer_close',
    'invitation',
    'lock',
    'start_session',
];
const isSysMessage = (type: ChatMessageType['type']) => {
    return SYS_MESSAGE_TYPES.includes(type);
};
function formatContent(itemData) {
    if (!itemData) return '';
    return typeof itemData === 'string' && itemData.includes('{') ? JSON.parse(itemData) : itemData;
}

export default ChatCardContentFactory;
export { Remark, CaseBase, isSysMessage, ErrorCorrection };
