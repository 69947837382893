import React from 'react';
import Component from './component';
import './sdk-style.scss';
import { Popover, Icon, Button } from 'udesk-ui';

const MessageContent = ({ content, dataLink, text }) => {
    return (
        <div>
            <div>{content}</div>
            {dataLink && (
                <Button type='link' size='small' href={dataLink} target='_blank'>
                    {text}
                </Button>
            )}
        </div>
    );
};

export default class Template extends Component {
    render() {
        let { actions, privates, locales } = this;
        return (
            <React.Fragment>
                <If condition={!privates.disable}>
                    <div
                        className='udesk-qa-massage-sdk-new'
                        onMouseUp={actions.onSortMouseUp}
                        onMouseDown={actions.onHelpMenuBarMouseDown}
                    >
                        {/* <div className='reflective'></div> */}
                        <div class={privates.isError ? 'circle-error' : 'circle'}></div>
                        <Icon
                            type='Frame'
                            className='udesk-qa-massage-sdk-logo'
                            style={{ color: privates.isError ? '#fafafa' : '#1b6dff' }}
                        />
                        {/* <div className="udesk-qa-massage-sdk-logo-bg"></div> */}
                    </div>
                    <div>
                        {privates.notificationNotReadedList.map((item) => {
                            return (
                                <Popover
                                    title={item.title}
                                    key={item.id}
                                    content={
                                        <MessageContent
                                            text={locales.labels.dataLink}
                                            content={item.content}
                                            dataLink={item.dataLink}
                                        />
                                    }
                                    visible={item.visible}
                                    placement='leftTop'
                                    arrowPointAtCenter
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode;
                                    }}
                                >
                                    <div
                                        className='udesk-qa-massage-sdk-tooltip'
                                        id={`udesk-qa-massage-sdk-tooltip-${item.id}`}
                                        style={{ top: item.top }}
                                    ></div>
                                </Popover>
                            );
                        })}
                    </div>
                </If>
            </React.Fragment>
        );
    }
}
