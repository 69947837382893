// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    PostReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowIdParam,
    BaseResponseVoid,
} from '../../../../../../types';

/**
 * 发布课程对话流程
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [POST] /review/intelligentPartner/dialogFlow/publish/{courseId}/{flowId}
 * @param courseId
 * @param flowId
 */
export function postReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowId(
    options: ApiOptions<
        PostReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowIdParam,
        never
    > & { segments: PostReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowIdParam }
): Promise<BaseResponseVoid> {
    return request<
        never,
        PostReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowIdParam,
        never
    >('/review/intelligentPartner/dialogFlow/publish/{courseId}/{flowId}', {
        ...options,
        method: 'post',
        __$requestCalleeName: 'postReviewIntelligentPartnerDialogFlowPublishByCourseIdByFlowId',
    });
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/dialogFlow/publish/{courseId}/{flowId}',
        method: 'post',
    },
];
