import {
    getReviewIntelligentPartnerNodeTraineeConfig,
    postReviewIntelligentPartnerNodeTraineeConfig,
} from 'src/api/review/intelligentPartner/node/traineeConfig';
import { putReviewIntelligentPartnerNodeTraineeConfigById } from 'src/api/review/intelligentPartner/node/traineeConfig/{id}';
import { postReviewIntelligentPartnerNodeScoreRuleCheck } from 'src/api/review/intelligentPartner/node/scoreRule/check';
import UdeskLocales from 'UdeskLocales';
import { message } from 'udesk-ui';

const fields = (props) => [
    {
        label: /* 标准话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.userNode.standardScript,
        type: 'AiTextArea',
        name: 'words',
    },
    {
        label: '',
        type: 'ScoringRules',
        name: 'scoreRuleList',
        props: {
            onChange: (scoreRuleList) => {
                props.saveFieldValues((prev) => {
                    prev.scoreRuleList = scoreRuleList;
                });
            },
            configId: props.configId,
            type: 1, // 1:学员节点  2:ppt
            readOnly: props.readOnly,
            onCheckRule: () => {
                const values = props.form?.getFieldsValue?.();
                postReviewIntelligentPartnerNodeScoreRuleCheck(values).then(() => {
                    message.success(/* 校验通过 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.userNode.verificationPassed);
                });
            },
        },
    },
    {
        label: '',
        type: 'DetailedInquiryConfig',
        name: 'questionClosely',
        props: {
            // scoreRuleList: props.getFieldValues('scoreRuleList'),
            saveFormInstance: props.saveFormInstance.bind(null, 'questionClosely'),
        },
    },
];

const request = ({ nodeId, saveFieldValues }) => {
    return getReviewIntelligentPartnerNodeTraineeConfig({
        params: {
            nodeId,
        },
    }).then((resp) => {
        const res: any = resp.data || {};
        if (Array.isArray(res.scoreRuleList)) {
            res.scoreRuleList = res.scoreRuleList.map((i) => {
                return {
                    ...i,
                    rule: JSON.parse(i.rule || ''),
                };
            });
        }
        if (Array.isArray(res.traineeConfigAskedList)) {
            res.traineeConfigAskedList = res.traineeConfigAskedList.map((i) => {
                return {
                    ...i,
                    questionCloselyContent: JSON.parse(i.questionCloselyContent || '[]'),
                };
            });
        }
        res.questionClosely = {
            ...res,
        };
        saveFieldValues((prev) => {
            prev.scoreRuleList = res.scoreRuleList;
        });
        return res;
    });
};

const finish = ({ formInstanceCache, configId, nodeId }) => {
    return (values) => {
        return formInstanceCache.questionClosely.validateFields().then((questionCloselyValues) => {
            const { questionClosely, ...otherValues } = values;
            const { traineeConfigAskedList } = questionCloselyValues;
            const { enableQuestionClosely } = questionClosely;
            const params = {
                ...otherValues,
                ...questionCloselyValues,
                enableQuestionClosely: enableQuestionClosely ? 1 : 0,
                notHitRule: questionCloselyValues.notHitRule
                    ? `${questionCloselyValues.notHitRule}`
                    : undefined,
                scoreRuleId: values.scoreRuleList?.map((i) => i.id).toString(),
                nodeId,
                traineeConfigAskedList: traineeConfigAskedList?.map((ask) => {
                    return {
                        ...ask,
                        questionCloselyContent: JSON.stringify(ask.questionCloselyContent),
                    };
                }),
            };
            if (!configId) {
                return postReviewIntelligentPartnerNodeTraineeConfig(params, {
                    successMsg:
                        /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                            .components.const.nodeConfigs.userNode.successfullySaved,
                });
            } else {
                return putReviewIntelligentPartnerNodeTraineeConfigById(params, {
                    segments: {
                        id: configId,
                    },
                    successMsg:
                        /* 保存成功 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                            .components.const.nodeConfigs.userNode.successfullySaved,
                });
            }
        });
    };
};

export default {
    fields,
    request,
    finish,
};
