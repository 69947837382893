// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostAutoMateTasksSampleTargetBody,
    BaseResponseAutoMateSampleTargetFoundResponse,
} from '../../types';

/**
 * saveSampleTargetConf
 *
 * @export
 * @tags 自动化配置
 * @link [POST] /autoMateTasks/sampleTarget
 */
export function postAutoMateTasksSampleTarget(
    data: PostAutoMateTasksSampleTargetBody
): Promise<BaseResponseAutoMateSampleTargetFoundResponse>;
export function postAutoMateTasksSampleTarget(
    data: PostAutoMateTasksSampleTargetBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseAutoMateSampleTargetFoundResponse>;
export function postAutoMateTasksSampleTarget(
    data: PostAutoMateTasksSampleTargetBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseAutoMateSampleTargetFoundResponse> {
    return request<PostAutoMateTasksSampleTargetBody, never, never>('/autoMateTasks/sampleTarget', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postAutoMateTasksSampleTarget',
    });
}

export const meta = [{ tags: ['自动化配置'], path: '/autoMateTasks/sampleTarget', method: 'post' }];
