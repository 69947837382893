import HasLegendBuilderClass from './has-legend';
import common from '../../../../udesk/index';

class MapOptionsBuilderClass extends HasLegendBuilderClass {
    notMergeOption() {
        return true;
    }
    lazyUpdate() {
        return true;
    }
    getVisualMapOptions(optionsOwner) {
        let {
            seriesColumns,
            records
        } = optionsOwner.props;
        let maxValue = 0;
        if (seriesColumns && seriesColumns.length > 0) {
            seriesColumns.forEach((column) => {
                if (records && records.length > 0) {
                    records.forEach((record) => {
                        let value = Number(this._getFieldOriginalValue(record, column.field));
                        if (isNaN(value)) {
                            value = 0;
                        }
                        if (value > maxValue) {
                            maxValue = value;
                        }
                    });
                }
            });
        }

        if (maxValue === 0) {
            maxValue = 100;
        }

        let visualMap = {
            left: 'left',
            top: 'bottom',
            // text: ['高', '低'], // 文本，默认为数值文本
            calculable: true,
            min: 0,
            max: common.utils.math.roundUp(maxValue, 10)
        };
        return visualMap;
    }
    getTooltipOptions(optionsOwner) {

        let tooltip = super.getTooltipOptions(optionsOwner);
        tooltip.trigger = "item";
        return tooltip;
    }

    _getChartType() {
        return common.enums.echartTypes.map.key;
    }

    _decorateSeries(series, optionsOwner) {
        series = super._decorateSeries(...arguments);
        let {
            showSeriesLabels,
            seriesLabelContentMode,
            // recordXLabel| recordValue
            // enableSeriesLabelFallback
            mapName,
            mapSeriesRoam
        } = optionsOwner.props;
        for (let seriesItem of series) {
            seriesItem.map = mapName;
            seriesItem.roam = mapSeriesRoam;
            if (seriesItem.label == null) {
                seriesItem.label = {};
            }
            if (seriesItem.label.normal == null) {
                seriesItem.label.normal = {};
            }
            if (showSeriesLabels) {
                //不使用legend作为series的label，而走label的formatter
                seriesItem.showLegendSymbol = false;
                if (seriesLabelContentMode === "recordXLabel") {
                    seriesItem.label.normal.show = true;
                }
            } else {
                if (seriesLabelContentMode === "recordValue") {
                    seriesItem.label.normal.show = true;
                } else {
                    seriesItem.label.normal.show = false;
                }
                //设置始终使用legend作为series的label.
                seriesItem.showLegendSymbol = true;
            }
        }
        return series;
    }
}

export default MapOptionsBuilderClass;
