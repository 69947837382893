import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import ScoreRecordButton from './components/ScoreRecord';
import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import { OverView } from './components/OverView';
import EvaluationSuggestion from './components/EvaluationSuggestion';
import { Button, Dropdown, Menu, message, Space, Tabs } from 'udesk-ui';
import { ScoreDetailProvider } from './components/Context';
import { TaskTitle } from './components/TaskTitle';
import { Score } from './components/Score';
import { AiGenScore } from './components/AiGenScore';
import { Dialogue, RefType } from 'Component/pages/components/coach/Dialogue';
import { ScoreDetailOpenButton } from './components/OpenButton';
import { linkTo } from 'src/util/link';
import { useDataPath, useLiveEffect, useRequest } from 'src/util/hook';
import { fire, inspect, isDefined } from 'src/util/core';
import { IntelligentPartnerSentence } from 'src/api/types';
import { getCurrentRouteName } from 'Udesk/system/subApp';
import { HistoricalLearningRenderCountContext } from 'src/pages/home';
import { DoubleRightArrowOutlined } from '@udesk/icons';
import { ComprehensiveEvaluation } from './components/ComprehensiveEvaluation';
import { putIntelligentPartnerCheckRecordByCheckRecordIdViewStatus } from 'src/api/intelligentPartner/check/record/{checkRecordId}/viewStatus';
import { ManualReviewTextRenderCountContext } from 'src/pages/home';
import './style.scss';
import { hasFeature } from 'src/util/permit';
import { getIntelligentPartnerByRecordIdRecordReScoring } from 'src/api/intelligentPartner/{recordId}/record/reScoring';
import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';
import { useRequest as useRequestAHook } from 'ahooks';
import Audio from './components/Audio';
import { getIntelligentPartnerRecordByRecordIdDownLoad } from 'src/api/intelligentPartner/record/{recordId}/downLoad';
import ShareModal from './components/ShareModal';
import OnceMore from './components/OnceMore';

const formatItemRenderProps = (data) => {
    if (data) {
        data.scoreAnalysis?.forEach((item) => {
            if (item.traineeWordsResult) {
                return $.extend(true, item, {
                    traineeWordsResult: {
                        // 是否是追问， 默认false：不是追问
                        isEnableQuestionClosely: isDefined(
                            item.isEnableQuestionClosely,
                            item.isEnableQuestionClosely,
                            false
                        ),
                        // 追问是否得分，默认true：得分
                        isQuestionCloselyScore: isDefined(
                            item.isQuestionCloselyScore,
                            item.isQuestionCloselyScore,
                            true
                        ),
                        // 是否已经完成审核  默认false，从审核列表转跳得分详情页面，根据该值，可置灰"保存修改"按钮，已审核的记录不允许再次修改得分
                        isFinishReview: isDefined(
                            data.isFinishReview,
                            !!data.isFinishReview,
                            false
                        ),
                        // 是否已经完成复核 默认false，从复核列表转跳得分详情页面，根据该值，可置灰"复核提交"按钮，已经复核的记录不允许再次复核
                        isFinishChecked: isDefined(
                            data.isFinishChecked,
                            !!data.isFinishChecked,
                            false
                        ),
                        // 是否复核中 默认false，学员从历史记录查看得分详情，如果该练习有申诉记录正在复核中，不允许提交评分申诉，用来置灰该申诉按钮
                        isInChecked: isDefined(data.isInChecked, !!data.isInChecked, false),
                    },
                });
            }
        });
    }
};

const Template = React.memo((props: any) => {
    const locales = UdeskLocales['current'];
    const recordId = parseInt(props.match.params.id, 10);
    const { location, history } = props;

    const [detail, setDetail] = useState<any>({});
    const [scoreAnalysisDataSource, setScoreAnalysisDataSource] =
        useState<IntelligentPartnerSentence[]>();
    const [isAllOpen, setIsAllOpen] = useState(true);
    const [tabKey, setTabKey] = useState('1');
    const {
        data: scoreAnalysis,
        setPropValue: setPropValueHandle,
        getPropValue,
    } = useDataPath(detail?.scoreAnalysis);
    const { getPropValue: getPropValueWithDataSource } = useDataPath(scoreAnalysisDataSource);
    const [scoreRecordData, setScoreRecordData] = useState<any[]>();
    const request = useRequest();
    const dialogueRef = useRef<RefType>(null);
    const isAudit = isDefined(props.match.params?.auditId); // 审核详情
    const isReview = isDefined(props.match.params?.reviewId); // 复合详情

    const isEdit = isAudit || isReview; // 历史记录详情
    const updateHistoricalLearningRenderCount = useContext(HistoricalLearningRenderCountContext);
    const updateManualReviewTextRenderCount = useContext(ManualReviewTextRenderCountContext);
    const [isShareModal, setIsShareModal] = useState<boolean>(false);
    const [shareType, setShareType] = useState('');

    const [taskId, setTaskId] = useState<number>();
    const [enableWhole, setEnableWhole] = useState(false);
    const [enableSingle, setEnableSingle] = useState(false);
    const [enableRealTimeReview, setEnableRealTimeReview] = useState(false);
    const [showScore, setShowScore] = useState(false);
    const [showResult, setShowResult] = useState(false);
    useEffect(() => {
        taskId &&
            getIntelligentPartnerTaskInfosById({
                segments: { id: taskId as any },
            }).then((res) => {
                setEnableWhole(
                    res.data?.enableSmartRemark === 1 &&
                        res.data.smartRemarkConfigObj?.enableWhole === 1
                );
                setEnableSingle(
                    res.data?.enableSmartRemark === 1 &&
                        res.data.smartRemarkConfigObj?.enableSingle === 1
                );
                setEnableRealTimeReview(
                    res.data?.enableSmartRemark === 1 &&
                        res.data.smartRemarkConfigObj?.enableRealTimeReview === 1
                );
                setShowScore(
                    !!res.data?.resultDisplayConfigObj?.resultDisplayContents?.includes(1)
                );
                setShowResult(
                    !!res.data?.resultDisplayConfigObj?.resultDisplayContents?.includes(2)
                );
            });
    }, [taskId]);

    const goBack = useCallback(() => {
        console.log(location);
        const s = new URLSearchParams(location.search);
        const from = s.get('from');

        if (location?.state?.backRouteParams) {
            linkTo(history, ...location.state.backRouteParams);
        } else if (from) {
            history.push(from);
        } else {
            history.goBack();
        }
    }, [history, location]);

    const setPropValue = useCallback(
        (path, value) => {
            const data = setPropValueHandle(path, value);
            if (path.indexOf('..') === -1) {
                request(
                    `/intelligentPartner/${props.match.params.id}/score/recalculate`,
                    data,
                    'post'
                ).then(
                    (resp) => {
                        formatItemRenderProps(resp.data);
                        setDetail(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            }
        },
        [props.match.params.id, request, setPropValueHandle]
    );

    const submitHandle = useCallback(() => {
        if (isReview) {
            const scoreAnalysisClone = cloneDeep(scoreAnalysis);
            scoreAnalysisClone.forEach((item) => {
                if (item.appealContent) {
                    item.appealContent.scoringStatus = 2;
                }
            });
            request(
                `/intelligentPartner/check/record/${props.match.params.reviewId}/submit`,
                scoreAnalysisClone,
                'put'
            ).then(
                (resp) => {
                    fire(updateHistoricalLearningRenderCount, () => {
                        linkTo(history, getCurrentRouteName('taskCenterReview'));
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
        if (isAudit) {
            request(
                `/intelligentPartner/review/${props.match.params.auditId}/record/updateScore`,
                scoreAnalysis,
                'put'
            ).then(
                (resp) => {
                    fire(updateHistoricalLearningRenderCount, () => {
                        linkTo(history, getCurrentRouteName('taskCenterAudit'));
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
        // 学员申诉提交
        if (!isEdit) {
            request(
                `/intelligentPartner/appeal/${props.match.params.id}/submit`,
                scoreAnalysis,
                'post'
            ).then(
                (resp) => {
                    updateManualReviewTextRenderCount(() => {
                        linkTo(history, getCurrentRouteName('learningCenterRecord'));
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [
        isAudit,
        isEdit,
        isReview,
        props.match.params.reviewId,
        props.match.params.auditId,
        props.match.params.id,
        request,
        scoreAnalysis,
        history,
        updateHistoricalLearningRenderCount,
        updateManualReviewTextRenderCount,
    ]);

    const [updateCount, setUpdateCount] = useState(0);

    const { run: getDetail, cancel: cancelGetDetail } = useRequestAHook(
        (params) => request(`/intelligentPartner/${props.match.params.id}/scorePageDetail`, params),
        {
            manual: true,
            pollingInterval: 2000,
            onSuccess: (resp) => {
                formatItemRenderProps(resp.data);
                setDetail(resp.data ?? {});
                setScoreAnalysisDataSource(resp.data?.scoreAnalysis);
                setTaskId(resp.data?.taskId);

                if (resp.data.scoreOverView) {
                    cancelGetDetail();
                }
            },
            onError: () => {
                cancelGetDetail();
            },
        }
    );

    useLiveEffect(
        (success) => {
            const params: any = {};
            if (isReview) {
                params.checkId = props.match.params?.reviewId; // 复核任务记录ID, 后台与前端所用字段不一致，前端用reviewId， 后端用checkId
            }
            if (isAudit) {
                params.reviewId = props.match.params?.auditId; // 审核记录ID，后台与前端所用字段不一致，前端用auditId， 后端用reviewId
            }

            getDetail(params);
        },
        [props.match.params?.id, props.match.params?.reviewId, isReview, updateCount]
    );

    useLiveEffect(
        (success) => {
            request(`/intelligentPartner/${props.match.params.id}/scoring/list`).then(
                (resp) => {
                    success(() => {
                        setScoreRecordData(resp.data.reverse());
                    });
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        [request, props.match.params.id]
    );

    useEffect(() => {
        props.match?.params?.reviewId &&
            isReview &&
            putIntelligentPartnerCheckRecordByCheckRecordIdViewStatus({
                segments: { checkRecordId: props.match?.params?.reviewId },
            }).then(() => {
                updateManualReviewTextRenderCount();
            });
    }, [isReview, props.match?.params?.reviewId, updateManualReviewTextRenderCount]);

    const [isRating, setIsRating] = useState(false);
    const onReRate = () => {
        setIsRating(true);
        getIntelligentPartnerByRecordIdRecordReScoring({ segments: { recordId } })
            .then(() => {
                message.success(
                    /* 重新评分成功 */ UdeskLocales['current'].pages.coach.learningCenter.record
                        .detail.index.reRatingSuccessful
                );
                setUpdateCount((c) => c + 1);
            })
            .catch(() => {
                message.error(
                    /* 重新评分失败 */ UdeskLocales['current'].pages.coach.learningCenter.record
                        .detail.index.reRatingFailed
                );
            })
            .finally(() => {
                setIsRating(false);
            });
    };

    const onExportAudio = (url) => {
        if (url) {
            window.location = url;
        }
    };

    const onExportText = () => {
        recordId &&
            getIntelligentPartnerRecordByRecordIdDownLoad({
                segments: { recordId },
            }).then((res) => {
                const blob = new Blob([res as string], { type: 'text/plain' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${new Date().getTime()}.txt`;
                document.body.appendChild(link);
                link.click();
            });
    };

    const messageMenuNotice = (e) => {
        setShareType(e.key);
        setIsShareModal(true);
    };
    const handlerModalCancel = () => {
        setIsShareModal(false);
    };

    const menu = (
        <Menu onClick={messageMenuNotice}>
            <Menu.Item key="colleague">
                {locales.pages.gong.callDetail.index.shareWithColleagues}
            </Menu.Item>
            <Menu.Item key="customer">
                {locales.pages.gong.callDetail.index.shareWithCustomers}
            </Menu.Item>
        </Menu>
    );

    return (
        <Page
            pageBodyClassName="udesk-coach-web-learning-center-record-detail"
            title={
                /* 评分 */ UdeskLocales['current'].pages.coach.learningCenter.record.detail.index
                    .score
            }
            padding={0}
            backGroundGhost={true}
            onBack={goBack}
            extra={
                <Space>
                    {detail.voice ? (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item onClick={() => onExportAudio(detail.voice)}>
                                        {/* 下载录音 */}
                                        {
                                            UdeskLocales['current'].pages.coach.learningCenter
                                                .record.detail.index.downloadRecording
                                        }
                                    </Menu.Item>
                                    <Menu.Item onClick={onExportText}>
                                        {/* 下载文本 */}
                                        {
                                            UdeskLocales['current'].pages.coach.learningCenter
                                                .record.detail.index.downloadText
                                        }
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type="link">
                                {/* 下载 */}
                                {
                                    UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                        .index.download
                                }
                            </Button>
                        </Dropdown>
                    ) : (
                        <Button type="link" onClick={onExportText}>
                            {/* 下载 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .index.download
                            }
                        </Button>
                    )}
                    <ScoreRecordButton data={scoreRecordData} />
                    {inspect(hasFeature('intelligentpartner:tasklist:reScore'), () => [
                        <Button disabled={isRating} type="primary" onClick={() => onReRate()}>
                            {/* 重新评分 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .index.reRating
                            }
                        </Button>,
                    ])}
                    {inspect(!isEdit, () => [
                        <Button
                            disabled={
                                !(
                                    !detail?.isInChecked &&
                                    !isEqual(scoreAnalysisDataSource, scoreAnalysis)
                                )
                            }
                            type="primary"
                            onClick={() => submitHandle()}
                        >
                            {/* 提交评分申诉 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .index.submitARatingAppeal
                            }
                        </Button>,
                    ])}
                    {inspect(isReview, () => [
                        <Button
                            disabled={!!detail?.isFinishChecked}
                            type="primary"
                            onClick={() => submitHandle()}
                        >
                            {/* 复核提交 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .index.reviewSubmission
                            }
                        </Button>,
                    ])}
                    {inspect(isAudit, () => [
                        <Button
                            disabled={
                                !(
                                    !detail?.isFinishReview &&
                                    !isEqual(scoreAnalysisDataSource, scoreAnalysis)
                                )
                            }
                            type="primary"
                            onClick={() => submitHandle()}
                        >
                            {/* 保存修改 */}
                            {
                                UdeskLocales['current'].pages.coach.learningCenter.record.detail
                                    .index.saveChanges
                            }
                        </Button>,
                    ])}
                    <OnceMore {...detail} taskId={taskId} history={history} />
                    <React.Fragment>
                        {/* todo: 分享按钮暂时无权限码 */}
                        {
                            <Dropdown overlay={menu}>
                                <Button onClick={(e) => e.preventDefault()}>
                                    {UdeskLocales['current'].pages.gong.callDetail.index.shareCases}
                                </Button>
                            </Dropdown>
                        }
                    </React.Fragment>
                </Space>
            }
        >
            <ScoreDetailProvider value={detail}>
                <div className="udesk-coach-web-learning-center-record-detail-left">
                    <TaskTitle {...detail} showScore={showScore} showResult={showResult} />
                    <ComprehensiveEvaluation
                        recordId={recordId}
                        scoreOverView={detail.scoreOverView}
                    />
                    <OverView recordId={recordId} />
                    {enableWhole ? <EvaluationSuggestion recordId={recordId} /> : null}
                </div>
                <div className="udesk-coach-web-learning-center-record-detail-right">
                    <div className="udesk-coach-web-learning-center-record-detail-right-wrapper">
                        <Tabs
                            activeKey={tabKey}
                            onChange={(activeKey) => setTabKey(activeKey)}
                            tabBarExtraContent={
                                tabKey === '1' ? null : (
                                    <ScoreDetailOpenButton
                                        isAllOpen={isAllOpen}
                                        setIsAllOpen={setIsAllOpen}
                                    />
                                )
                            }
                        >
                            <Tabs.TabPane
                                tab={
                                    /* 得分情况 */ UdeskLocales['current'].pages.coach
                                        .learningCenter.record.detail.index.scoringSituation
                                }
                                key="1"
                            >
                                {detail.courseType !== Udesk.enums.lessonType.aiGen.id ? (
                                    <Score
                                        onItemClick={(nodeId, index) => {
                                            setTabKey('2');
                                            let scrollTo = 0;
                                            let seenCount = 0;
                                            for (let i = 0; i < detail.scoreAnalysis.length; i++) {
                                                const element = detail.scoreAnalysis[i];
                                                if (element.channel === 2) {
                                                    seenCount += 1;
                                                }
                                                if (index === seenCount - 1) {
                                                    scrollTo = i;
                                                    break;
                                                }
                                            }
                                            requestIdleCallback(() => {
                                                dialogueRef.current?.scrollTo?.(scrollTo || 0);
                                            });
                                        }}
                                    />
                                ) : (
                                    <AiGenScore tableHeight={450} data={detail} />
                                )}
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                className="udesk-coach-components-dialogue-tab"
                                tab={
                                    /* 对话解析 */ UdeskLocales['current'].pages.coach
                                        .learningCenter.record.detail.index.dialogueAnalysis
                                }
                                key="2"
                            >
                                {inspect(isReview, () => (
                                    <Tools {...{ scoreAnalysis, dialogueRef }} />
                                ))}

                                <div
                                    style={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Audio
                                        src={detail.voice}
                                        dialogueRef={dialogueRef}
                                        scoreAnalysis={scoreAnalysis}
                                    />

                                    <Dialogue
                                        ref={dialogueRef}
                                        dialogueSentenceList={scoreAnalysis}
                                        itemRender={(props, index) => {
                                            return (
                                                <React.Fragment>
                                                    <Dialogue.Item
                                                        isChangeMatchResult={
                                                            !isEqual(
                                                                scoreAnalysis?.[index],
                                                                scoreAnalysisDataSource?.[index]
                                                            )
                                                        }
                                                        isAudit={isAudit}
                                                        isReview={isReview}
                                                        isEdit={inspect(isEdit, () => {
                                                            if (isReview) {
                                                                return (
                                                                    !detail?.isFinishChecked &&
                                                                    !!props.appealContent
                                                                );
                                                            }
                                                            if (isAudit) {
                                                                return !detail?.isFinishReview;
                                                            }
                                                            return true;
                                                        })}
                                                        setPropValue={(path, value) =>
                                                            setPropValue(
                                                                `${index}.traineeWordsResult.${path}`,
                                                                value
                                                            )
                                                        }
                                                        getPropValue={(path) =>
                                                            getPropValue(
                                                                `${index}.traineeWordsResult.${path}`
                                                            )
                                                        }
                                                        getPropValueWithDataSource={(path) =>
                                                            getPropValueWithDataSource(
                                                                `${index}.traineeWordsResult.${path}`
                                                            )
                                                        }
                                                        isAllOpen={isAllOpen}
                                                        index={index}
                                                        showCorrect={true}
                                                        recordId={recordId}
                                                        enableSingle={enableSingle}
                                                        enableRealTimeReview={enableRealTimeReview}
                                                        {...props}
                                                    />
                                                </React.Fragment>
                                            );
                                        }}
                                    />
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </ScoreDetailProvider>
            {isShareModal ? (
                <ShareModal
                    sdkOptions={props.sdkOptions}
                    detail={{ ...detail, id: props.match.params?.id }}
                    handlerModalCancel={handlerModalCancel}
                    shareType={shareType}
                    isShare={isShareModal}
                ></ShareModal>
            ) : null}
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);

const Tools = React.memo<any>((props) => {
    const { scoreAnalysis = [], dialogueRef } = props;
    const [index, setIndex] = useState(0);
    const item = scoreAnalysis[index];
    const list = scoreAnalysis.filter((item) => isDefined(item.appealContent));
    const firstIndex = scoreAnalysis.findIndex((item) => isDefined(item.appealContent));
    const isFist = list[0] === item;
    const isLast = list[list.length - 1] === item;

    const getNextIndex = useCallback(
        (reversed, start) => {
            for (
                let index = inspect(reversed, start - 1, start + 1);
                inspect(
                    reversed,
                    () => index > -1,
                    () => index < scoreAnalysis.length
                );
                inspect(
                    reversed,
                    () => index--,
                    () => index++
                )
            ) {
                if (scoreAnalysis[index].appealContent) {
                    return index;
                }
            }
            return inspect(reversed, scoreAnalysis.length - 1, 0);
        },
        [scoreAnalysis]
    );

    const scrollTo = useCallback((index) => {
        setIndex(index);
    }, []);

    useEffect(() => {
        isDefined(firstIndex, () => {
            scrollTo(firstIndex);
        });
    }, [firstIndex, scrollTo]);

    useEffect(() => {
        dialogueRef.current.scrollTo(index);
    }, [dialogueRef, index]);

    return inspect(list.length > 1 && isDefined(item?.appealContent), () => {
        return (
            <Space direction="vertical" className="udesk-coach-components-dialogue-tools">
                <Button
                    onClick={() => scrollTo(getNextIndex(true, index))}
                    disabled={isFist}
                    icon={<DoubleRightArrowOutlined rotate={-90} />}
                    shape="round"
                >
                    {
                        /* 上一句 */ UdeskLocales['current'].pages.coach.learningCenter.record
                            .detail.index.previousSentence
                    }
                </Button>
                <Button
                    onClick={() => scrollTo(getNextIndex(false, index))}
                    disabled={isLast}
                    icon={<DoubleRightArrowOutlined rotate={90} />}
                    shape="round"
                >
                    {
                        /* 下一句 */ UdeskLocales['current'].pages.coach.learningCenter.record
                            .detail.index.nextSentence
                    }
                </Button>
            </Space>
        );
    });
});
