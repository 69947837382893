/*
 * @Author: 唐毓斌
 * @Description: 简单饼图
 */

import React, { useEffect, useRef } from 'react';
import Echarts from 'echarts';
import { Spin } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

interface SimplePieChartProps {
    id: string;
    // width?: number | string;
    loading?: boolean;
    height: number | string;
    title: string;
    subTitle: string;
    data: { name: string; value?: number }[];
    extraData: any;
    textFontSize?: number;
    colorArr: string[];
}

const SimplePieChart: React.FC<SimplePieChartProps> = (props) => {
    console.log(UdeskLocales);
    const {
        id,
        height,
        title,
        subTitle,
        extraData,
        colorArr = ['#0376FB', '#fafafa'],
        data,
        loading,
        textFontSize,
    } = props;
    console.log(textFontSize);
    const currentChartRef = useRef<any>(null);

    useEffect(() => {
        if (!currentChartRef.current) {
            currentChartRef.current = Echarts.init(document.getElementById(id));
        }
        if (currentChartRef.current) {
            const option = {
                animation: 'auto',
                color: colorArr,
                // title: {
                //   text: '情绪分布',
                //   left: 'left',
                //   textStyle: {
                //     lineHeight: 10,
                //     fontSize: 18
                //   },
                //   subtext: subTitle,
                //   subtextStyle: {
                //       color: '#0376FB',
                //   },
                // },
                title: {
                    top: 'center',
                    left: 'center',
                    text: title,
                    textStyle: {
                        color: '#0376FB',
                        lineHeight: 10,
                        fontSize: textFontSize || 18,
                    },
                    subtext: subTitle,
                    subtextStyle: {
                        color: '#0376FB',
                    },
                },
                legend: {
                    orient: 'vertical',
                    right: 100,
                    top: 100,
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        // console.log('tooltip:',params);
                        if (params.dataIndex == 0) {
                            let str = `${
                                /* 负向情绪 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                    .components.emotionAnalysis.components.simplePieChart.index
                                    .negativeEmotions
                            }${title} <br/>
                                ${extraData.map((item) => {
                                    return `&nbsp;&nbsp;&nbsp;&nbsp;` + item + `<br/>`;
                                })}
                                `;

                            return str;
                        } else {
                            return params.data.name + ' ' + params.data.value;
                        }
                    },
                },
                series: [
                    {
                        name: 'testName',
                        type: 'pie',
                        radius: ['50%', '80%'],
                        hoverOffset: 2,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        // itemStyle: {
                        //     borderRadius: 10,
                        //     borderColor: '#fff',
                        //     borderWidth: 2,
                        // },
                        label: {
                            show: false,
                            position: 'outer',
                            alignTo: 'none',
                            bleedMargin: 5,
                            formatter: '{name|{b}}',
                            rich: {
                                count: {
                                    fontSize: 10,
                                    color: '#999',
                                },
                            },
                        },
                        // tooltip: {
                        //     formatter: '456456456',
                        // },
                        data,
                    },
                ],
            };
            currentChartRef.current.setOption(option);
        }
    }, [data, id, subTitle, title]);

    const chartResize = () => {
        currentChartRef.current && currentChartRef.current.resize();
    };

    useEffect(() => {
        window.addEventListener('resize', chartResize);

        return () => {
            window.removeEventListener('resize', chartResize);
        };
    }, []);

    return (
        <Spin spinning={loading}>
            <div
                id={id}
                style={{
                    // width: width,
                    height: height,
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '3px',
                    paddingTop: '30px',
                }}
            />
        </Spin>
    );
};

export default SimplePieChart;
