// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type { PostSmartTagIgnoresBody, BaseResponseSmartTagIgnoreFoundResponse } from '../types';

/**
 * save
 *
 * @export
 * @tags smart-tag-ignore-controller
 * @link [POST] /smartTagIgnores
 */
export function postSmartTagIgnores(
    data: PostSmartTagIgnoresBody
): Promise<BaseResponseSmartTagIgnoreFoundResponse>;
export function postSmartTagIgnores(
    data: PostSmartTagIgnoresBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseSmartTagIgnoreFoundResponse>;
export function postSmartTagIgnores(
    data: PostSmartTagIgnoresBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseSmartTagIgnoreFoundResponse> {
    return request<PostSmartTagIgnoresBody, never, never>('/smartTagIgnores', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSmartTagIgnores',
    });
}

export const meta = [
    { tags: ['smart-tag-ignore-controller'], path: '/smartTagIgnores', method: 'post' },
];
