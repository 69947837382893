import {
    InvalidArgumentError
} from '../../../error';
import { 
    imurmurhash as Imurmurhash
} from "../../../../udesk/ref-npm-modules/plugins/utils.object.hash";

let DEFAULT_STORAGE_OPTIONS = {
    version: null,
    oldVersion: null,
    isVersionRollback: false,
};

let RESOLVING_STORAGE_MAPPINGS = {
    "UQDZHJKASDHKASHD": "version",
    "UQDZHJLKNMDUHZKJ": "oldVersion",
    "UQDZMNNHUHHZBMBQ": "isVersionRollback"
};

let CONFUSION_STORAGE_MAPPINGS = {
    "version": "UQDZHJKASDHKASHD",
    "oldVersion": "UQDZHJLKNMDUHZKJ",
    "isVersionRollback": "UQDZMNNHUHHZBMBQ"
};

function confusionStorage(data) {
    let result = {};
    if (data == null) {
        data = {};
    }
    Object.keys(CONFUSION_STORAGE_MAPPINGS).forEach(key => {
        let confusionKey = CONFUSION_STORAGE_MAPPINGS[key];
        result[confusionKey] = data[key];
    });
    return result;
}

function resolvingStorage(data) {
    let result = {};
    if (data == null) {
        data = {};
    }
    Object.keys(RESOLVING_STORAGE_MAPPINGS).forEach(key => {
        let resolvingKey = RESOLVING_STORAGE_MAPPINGS[key];
        result[resolvingKey] = data[key];
    });
    return result;
}

function getStorageData(key) {
    if (key == null) {
        return;
    }
    let sessionStorageData = sessionStorage.getItem(key);
    if (sessionStorageData != null) {
        sessionStorageData = JSON.parse(sessionStorageData);
    }
    return sessionStorageData;
}

function getHash(content) { 
    if (content == null || (typeof content === "number" && isNaN(content))) {
        content = "";
    }
    if (typeof content !== "string") {
        if (typeof content === "object" || Array.isArray(content)) {
            content = JSON.stringify(content);
        } else {
            content = content.toString();
        }
    }
    let imurmurhashInstance = new Imurmurhash();
    imurmurhashInstance.hash(content);
    return imurmurhashInstance.result();
}

function setStorageData(key, value) {
    if (key == null || value == null) {
        return;
    }
    if (value == null || typeof value !== "object") {
        return
    }
    sessionStorage.setItem(key, JSON.stringify(value));
}

const UDESK_SDK_HASH_PROFIX = "~UDESKPREVSDKSTORAGEHASH";
const STORAGE_DATA_HASH_SYMBOL = Symbol(`STORAGE_DATA_HASH_SYMBOL`);
const PREV_STORAGE_DATA_SYMBOL = Symbol(`PREV_STORAGE_DATA_SYMBOL`);
const STORAGE_CLASS_OPTIONS_SYMBOL = Symbol(`STORAGE_CLASS_OPTIONS_SYMBOL`);

class StorageClass { 
    constructor() { 
        this[STORAGE_DATA_HASH_SYMBOL] = null;
        this[PREV_STORAGE_DATA_SYMBOL] = null;
        this[STORAGE_CLASS_OPTIONS_SYMBOL] = {};
    }
    init(options) { 
        if (options.sdkName == null || options.sdkName === "") { 
            throw new InvalidArgumentError(`options.sdkName`);
        }
        this[STORAGE_CLASS_OPTIONS_SYMBOL] = options;
        let prevHash = null;
        let prevHashName = `${UDESK_SDK_HASH_PROFIX}_${this[STORAGE_CLASS_OPTIONS_SYMBOL].sdkName}`;
        if (Object.prototype.hasOwnProperty.call(window.document.documentElement,prevHashName)) {
            prevHash = window.document.documentElement[prevHashName];
            delete window.document.documentElement[prevHashName];
        }
        let isResetStorage = true;
        let storageData = getStorageData(this[STORAGE_CLASS_OPTIONS_SYMBOL].sdkName);
        if (storageData != null) {
            if (prevHash != null) {
                let currentHash = getHash(storageData);
                if (currentHash === prevHash) {
                    isResetStorage = false;
                    this[STORAGE_DATA_HASH_SYMBOL] = currentHash;
                }
            }
        } 
        if (isResetStorage) { 
            storageData = Object.assign({}, DEFAULT_STORAGE_OPTIONS);
            storageData = confusionStorage(storageData);
            this[STORAGE_DATA_HASH_SYMBOL] = getHash(storageData);
            this[PREV_STORAGE_DATA_SYMBOL] = storageData;
            setStorageData(this[STORAGE_CLASS_OPTIONS_SYMBOL].sdkName, storageData);
        }
    }

    get() { 
        let targetParams = [];
        if (arguments.length > 0) { 
            for (let i = 0; i < arguments.length; i++) { 
                let argument = arguments[i];
                if (argument != null && argument !== "" && typeof argument === "string") {
                    targetParams.push(argument);
                }
            }
        }
        let result = null;
        let storageData = getStorageData(this[STORAGE_CLASS_OPTIONS_SYMBOL].sdkName);
        if (storageData == null) { 
            storageData = {};
        }
        let currentHash = getHash(storageData);
        if (currentHash === this[STORAGE_DATA_HASH_SYMBOL]) {
            result = storageData;
        } else { 
            result = this[PREV_STORAGE_DATA_SYMBOL] || {};
        }
        result = resolvingStorage(result);
        if (targetParams.length !== 0) { 
            if (targetParams.length === 1) { 
                return result[targetParams[0]];
            }
            let designatedResult = {};
            targetParams.forEach(param => { 
                if (Object.prototype.hasOwnProperty.call(result,param)) { 
                    designatedResult[param] = result[param];
                }
            });
            return designatedResult;
        }
        return result;
    }

    set(value) { 
        if (value == null || typeof value !== "object") { 
            return;
        }
        let prevStorageData = this.get();
        let storageData = Object.assign({}, prevStorageData, value);
        storageData = confusionStorage(storageData);
        this[STORAGE_DATA_HASH_SYMBOL] = getHash(storageData);
        this[PREV_STORAGE_DATA_SYMBOL] = storageData;
        setStorageData(this[STORAGE_CLASS_OPTIONS_SYMBOL].sdkName, storageData);
    }

    destroy() { 
        let prevHashName = `${UDESK_SDK_HASH_PROFIX}_${this[STORAGE_CLASS_OPTIONS_SYMBOL].sdkName}`;
        window.document.documentElement[prevHashName] = this[STORAGE_DATA_HASH_SYMBOL];
        this[STORAGE_DATA_HASH_SYMBOL] = null;
        this[PREV_STORAGE_DATA_SYMBOL] = null;
        this[STORAGE_CLASS_OPTIONS_SYMBOL] = null;
    }
}

export default new StorageClass();