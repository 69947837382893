import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Alert, Divider } from 'udesk-ui';
import { isJuSiHong } from '../const';
import UdeskLocales from 'UdeskLocales';
import './style.scss';

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    /* margin-top: -20%; */
`;
const Logo = styled.div`
    position: absolute;
    top: 40px;
    left: 40px;
    display: flex;
    align-items: center;

    .website-title {
        margin-left: 8px;
        font-size: 20px;
        font-weight: 600;
    }
`;

export const Layout: React.FC<{
    desc: string;
    subdomain?: string;
    isIpForbidden?: boolean;
    onAfterClose?: () => void;
}> = ({ children, desc, subdomain, isIpForbidden, onAfterClose }) => {
    const [bgImg, setBgImg] = useState('/static/images/login-page-bg.jpg');

    useEffect(() => {
        if (isJuSiHong(subdomain)) {
            setBgImg('/static/images/jusihong-login.png');
        }
    }, [subdomain]);

    const _websiteLogo = useMemo(() => {
        const companyLogo = window.localStorage.getItem('company.logo');
        return companyLogo || '/static/images/qa-logo.png';
    }, []);

    const _websiteTitle = useMemo(() => {
        return getWebsiteTitle(subdomain);
    }, []);

    return (
        <div
            className="udesk-qa-web-login-page"
            style={{ background: isJuSiHong(subdomain) ? '#fff' : '' }}
        >
            <div className="left">
                {isJuSiHong(subdomain) ? (
                    <Logo>
                        <img
                            className="title-image"
                            src="/static/images/jusihong-logo.png"
                            alt=""
                            width="50%"
                        />
                    </Logo>
                ) : (
                    <Logo>
                        <img className="title-image" src={_websiteLogo} alt="" width="30" />
                        <div className="website-title">{_websiteTitle}</div>
                    </Logo>
                )}

                <FormWrap>
                    {isIpForbidden ? (
                        <Alert
                            message={<h4 style={{ color: '#fff' }}>{/* 当前IP不允许登陆 */}{UdeskLocales['current'].pages.login.components.layout.theCurrentIPDoesNotAllowLogin}</h4>}
                            type="error"
                            closable
                            style={{
                                width: '45%',
                                borderColor: '#ff4d4f',
                                background: '#ff4d4f',
                                marginBottom: 8,
                            }}
                            afterClose={onAfterClose}
                        />
                    ) : null}

                    {isJuSiHong(subdomain) ? (
                        <div
                            className="udesk-qa-ui-form-builder-container"
                            style={{ textAlign: 'center', fontSize: 30, color: '#003366' }}
                        >{/* 欢迎登录 */}{UdeskLocales['current'].pages.login.components.layout.welcomeToLogin}</div>
                    ) : (
                        <>
                            <div className="udesk-qa-ui-form-builder-container title">
                                Welcome back
                            </div>
                            <div className="udesk-qa-ui-form-builder-container desc">
                                {/* 欢迎回来！请输入您的详细信息 */}
                                {desc}
                            </div>
                        </>
                    )}

                    {children}

                    {isJuSiHong(subdomain) ? (
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: 24,
                                fontWeight: 500,
                                position: 'absolute',
                                bottom: 30,
                                width: '100%',
                                color: '#003366',
                            }}
                        >{/* 追求卓越，引领变革 */}{UdeskLocales['current'].pages.login.components.layout.pursuingExcellenceAndLeadingChange}</div>
                    ) : null}
                </FormWrap>
            </div>

            {isJuSiHong(subdomain) ? (
                <Divider
                    type="vertical"
                    style={{
                        height: 'calc(100% - 40px)',
                        borderColor: 'rgba(0, 0, 0, 0.45)',
                        margin: '20px 8px',
                    }}
                />
            ) : null}

            <div
                className="right"
                style={{
                    backgroundImage: `url(${bgImg})`,
                    backgroundSize: isJuSiHong(subdomain) ? 'contain' : 'cover',
                    margin: isJuSiHong(subdomain) ? 20 : 0,
                }}
            ></div>
        </div>
    );
};

function getWebsiteTitle(subdomain) {
    if (subdomain === 'xian-janssen.s4') return /* 智能平台 */UdeskLocales['current'].pages.login.components.layout.intelligentPlatform;
    const companyName = window.localStorage.getItem('company.name');

    return companyName || UdeskLocales['current'].labels.systemName;
}
