// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseint } from '../../types';

/**
 * 获取当前用户收到的通知总数
 *
 * @export
 * @tags 智能陪练-消息中心
 * @link [GET] /intelligentPartnerNotification/num
 */
export function getIntelligentPartnerNotificationNum(): Promise<BaseResponseint>;
export function getIntelligentPartnerNotificationNum(
    options: ApiOptions<never, never>
): Promise<BaseResponseint>;
export function getIntelligentPartnerNotificationNum(
    options?: ApiOptions<never, never>
): Promise<BaseResponseint> {
    return request<never, never, never>('/intelligentPartnerNotification/num', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerNotificationNum',
    });
}

export const meta = [
    { tags: ['智能陪练-消息中心'], path: '/intelligentPartnerNotification/num', method: 'get' },
];
