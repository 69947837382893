import React, { FC } from 'react';
import { Input, Space } from 'udesk-ui';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const DynamicInputWrapper = styled.div``;
const DynamicInputItem = styled.div`
    margin-bottom: 5px;
`;

type DynamicInputProps = {
    value?: string[];
    onChange?: (v: string[]) => void;
};

export const DynamicInput: FC<DynamicInputProps> = (props) => {
    const { value, onChange } = props;

    const onContentChange = (idx: number, val: string) => {
        const newValue = (value || []).map((v, _idx) => {
            if (_idx === idx) {
                return val;
            } else {
                return v;
            }
        });
        onChange?.(newValue);
    };

    const onAdd = () => {
        const newValue = [...(value || []), ''];
        onChange?.(newValue);
    };

    const onRemove = (idx: number) => {
        const newValue = (value || []).filter((v, _idx) => _idx !== idx);
        onChange?.(newValue);
    };

    return (
        <DynamicInputWrapper>
            {value?.map((v, idx) => {
                return (
                    <DynamicInputItem>
                        <Space>
                            <span>{idx + 1}</span>
                            <Input
                                value={v}
                                style={{ width: 380 }}
                                onChange={(e) => onContentChange(idx, e.target.value)}
                            />
                            {/* {(value || []).length > 1 ? ( */}
                            <MinusCircleOutlined onClick={() => onRemove(idx)} />
                            {/* ) : null} */}
                        </Space>
                    </DynamicInputItem>
                );
            })}
            <PlusCircleOutlined onClick={onAdd} />
        </DynamicInputWrapper>
    );
};
