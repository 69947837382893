// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type { PostReplayCommentsBody, BaseResponseReplayCommentFoundResponse } from '../types';

/**
 * 输入评论
 *
 * @export
 * @tags 会话评论
 * @link [POST] /replayComments
 */
export function postReplayComments(
    data: PostReplayCommentsBody
): Promise<BaseResponseReplayCommentFoundResponse>;
export function postReplayComments(
    data: PostReplayCommentsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseReplayCommentFoundResponse>;
export function postReplayComments(
    data: PostReplayCommentsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseReplayCommentFoundResponse> {
    return request<PostReplayCommentsBody, never, never>('/replayComments', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReplayComments',
    });
}

export const meta = [{ tags: ['会话评论'], path: '/replayComments', method: 'post' }];
