import Locales from '../locales/index';

let dataPreprocessStatusFlags = [
    { id: 0, key: "invalid", name: () => Locales.current.enums.activeFlags.invalid },
    { id: 1, key: "effiective", name: () => Locales.current.enums.activeFlags.effiective },
];

let dataPreprocessSourceFlags = [
    { id: 0, key: "all", name: () => Locales.current.enums.dataSourceFlags.all },
    { id: 1, key: "voiceCall", name: () => Locales.current.enums.dataSourceFlags.voiceCall },
    { id: 2, key: "textDialogue", name: () => Locales.current.enums.dataSourceFlags.textDialogue }
];

let dataPreprocessTypes = [
    { id: 1, key: "system", name: () => Locales.current.enums.informationEntityTypes.system },
    { id: 2, key: "custom", name: () => Locales.current.enums.informationEntityTypes.custom },
];
let dataSourceCutom = [
    { id: 13, key: "smartTag", name: () => Locales.current.enums.customType.smartTag },
    { id: 14, key: "scriptTimes", name: () => Locales.current.enums.customType.scriptTimes },
    { id: 23, key: "dataCategory", name: () => Locales.current.enums.customType.dataCategory },
];

export default {
    dataPreprocessStatusFlags,
    dataPreprocessSourceFlags,
    dataPreprocessTypes,
    dataSourceCutom
};
