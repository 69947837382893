import { NotImplementedError } from "../error";
import EventedClass from '../evented-class';
import WebUtils from '../utils/web';

class NotifyPluginClass extends EventedClass {
    constructor() {
        super();
        this.defaults = this.getDefaults();
    }

    show(type, options) {
        throw new NotImplementedError(`${this.constructor.name}.show`);
    }

    destroy() {
        throw new NotImplementedError(`${this.constructor.name}.destroy`);
    }

    getDefaults() {
        throw new NotImplementedError(`${this.constructor.name}.getDefaults`);
    }

    updateDefaults(defaults) {
        Object.assign(this.defaults, defaults);
        this.trigger("onDefaultsChanged", [this.defaults]);
    }
}

let _plugin = null;

export default {
    init,
    success,
    warn,
    error,
    info,
    destroy,
    setPlugin,
    Options,
    plugins: {},
    NotifyPluginClass,
};

export {
    NotifyPluginClass
};

function init() {
    autoInstallFromPlugins.call(this);
}

function warn(options) {
    notify.apply(this, [options, "warn"]);
}

function error(options) {
    notify.apply(this, [options, "error"]);
}

function info(options) {
    notify.apply(this, [options, "info"]);
}

function success(options) {
    notify.apply(this, [options, "success"]);
}

function setPlugin(plugin) {
    //argument support string object
    if (plugin) {
        if (typeof plugin === "string") {
            let targetPlugin = this.plugins[plugin];
            if (targetPlugin) {
                if (targetPlugin instanceof NotifyPluginClass) {
                    _plugin = targetPlugin;
                } else {
                    throw new Error(`Found one notify plugin in Udesk.ui.notify.plugins, but the type is not compatible with ${NotifyPluginClass.name}.`);
                }
            } else {
                throw new Error(`The given notify plugin does not exist in Udesk.ui.notify.plugins. Please install the plugin via a plugin installer.`);
            }
        } else if (typeof plugin === "object" && !Array.isArray(plugin)) {
            Object.assign(this.plugins, plugin);
            _plugin = plugin;
        }
    } else {
        throw new Error(`Found one notify plugin in Udesk.ui.notify.plugins, but the type is not compatible with ${NotifyPluginClass.name}.`);
    }
}

function destroy() {
    if (_plugin) {
        _plugin.destroy();
        _plugin = null;
    }
}

function notify(options, type) {
    options = parseMessageOptions(options);
    if (_plugin == null) {
        autoInstallFromPlugins.call(this);
    }
    options.message = WebUtils.safeHtml(options.message);
    if (_plugin) {
        _plugin.show(type, options);
    } else {
        if (window.console && window.console[type]) {
            window.console[type](options.message);
        }
        // See env values from .env. Powered by `babel-conditial-loader`.
        // #if process.env.ENABLE_DEBUG_INFO === 'true'
        if (window.console) {
            window.console.error("Warning! There is no notification service set for `Udesk.ui.notify`, so it fallbacks to use `console` to display notification messages. If you want to display in a better way, please install a plugin from `udesk-react/src/udesk-plugin-installers/ui/notify/`.");
        }
        // #endif
    }
}

function parseMessageOptions(msg) {
    let options = msg;
    if (typeof msg === "string") {
        options = {};
        options.message = msg;
    } else if (typeof msg !== "object") {
        options = {};
        if (msg) {
            options.message = options.toString();
        } else {
            options.message = "";
        }
    }
    return options;
}

function autoInstallFromPlugins() {
    if (this.plugins) {
        let plugins = Object.values(this.plugins).filter(p => p instanceof NotifyPluginClass);
        if (plugins.length > 0) {
            _plugin = plugins[plugins.length - 1];
        }
    }
}

function Options(hash) {
    this.message = undefined;
    this.duration = undefined;

    Object.assign(this, hash);
}
