function _typeof(o) {
  '@babel/helpers - typeof';
  return (
    (_typeof =
      'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
        ? function (o) {
            return typeof o;
          }
        : function (o) {
            return o &&
              'function' == typeof Symbol &&
              o.constructor === Symbol &&
              o !== Symbol.prototype
              ? 'symbol'
              : typeof o;
          }),
    _typeof(o)
  );
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r &&
      (o = o.filter(function (r) {
        return Object.getOwnPropertyDescriptor(e, r).enumerable;
      })),
      t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2
      ? ownKeys(Object(t), !0).forEach(function (r) {
          _defineProperty(e, r, t[r]);
        })
      : Object.getOwnPropertyDescriptors
      ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t))
      : ownKeys(Object(t)).forEach(function (r) {
          Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
        });
  }
  return e;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, 'string');
  return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== 'object' || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || 'default');
    if (_typeof(res) !== 'object') return res;
    throw new TypeError('@@toPrimitive must return a primitive value.');
  }
  return (hint === 'string' ? String : Number)(input);
}
export default function filterTreeDataByKeyword() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var keyword = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var fieldNames = arguments.length > 2 ? arguments[2] : undefined;
  var res = [];
  var _ref = fieldNames || {},
    _ref$title = _ref.title,
    title = _ref$title === void 0 ? 'title' : _ref$title,
    _ref$children = _ref.children,
    children = _ref$children === void 0 ? 'children' : _ref$children;
  data.forEach(function (element) {
    if (element[title].indexOf(keyword) > -1) {
      var childrenValue = filterTreeDataByKeyword(element[children], keyword, fieldNames);
      element = _objectSpread(
        _objectSpread({}, element),
        {},
        _defineProperty({}, children, childrenValue.length ? childrenValue : undefined),
      );
      res.push(element);
    } else {
      if (element[children] && element[children].length > 0) {
        var arr = filterTreeDataByKeyword(element[children], keyword, fieldNames);
        if (arr && arr.length > 0) {
          var obj = _objectSpread(
            _objectSpread({}, element),
            {},
            _defineProperty({}, children, arr),
          );
          res.push(obj);
        }
      }
    }
  });
  return res;
}
function includeKeyword(node, keyword) {
  return node.title.includes(keyword);
}
// function walker(data: TreeNode[] = [], stack: any[]) {
//     data.forEach(item => {
//         stack.push(item);
//     });
// }
