// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostReviewIntelligentPartnerNodePptConfigWordsPolishBody,
    BaseResponsestring,
} from '../../../../../types';

/**
 * ppt话术润色
 *
 * @export
 * @tags 智能陪练-ppt节点配置
 * @link [POST] /review/intelligentPartner/node/pptConfig/wordsPolish
 */
export function postReviewIntelligentPartnerNodePptConfigWordsPolish(
    data: PostReviewIntelligentPartnerNodePptConfigWordsPolishBody
): Promise<BaseResponsestring>;
export function postReviewIntelligentPartnerNodePptConfigWordsPolish(
    data: PostReviewIntelligentPartnerNodePptConfigWordsPolishBody,
    options: ApiOptions<never, never>
): Promise<BaseResponsestring>;
export function postReviewIntelligentPartnerNodePptConfigWordsPolish(
    data: PostReviewIntelligentPartnerNodePptConfigWordsPolishBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponsestring> {
    return request<PostReviewIntelligentPartnerNodePptConfigWordsPolishBody, never, never>(
        '/review/intelligentPartner/node/pptConfig/wordsPolish',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodePptConfigWordsPolish',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-ppt节点配置'],
        path: '/review/intelligentPartner/node/pptConfig/wordsPolish',
        method: 'post',
    },
];
