// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewCallByIdKeyEventParam,
    BaseResponseListKeyEventFoundResultResponse,
} from '../../../../types';

/**
 * findKeyEventById
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/{id}/keyEvent
 * @param id
 */
export function getReviewCallByIdKeyEvent(
    options: ApiOptions<GetReviewCallByIdKeyEventParam, never> & {
        segments: GetReviewCallByIdKeyEventParam;
    }
): Promise<BaseResponseListKeyEventFoundResultResponse> {
    return request<never, GetReviewCallByIdKeyEventParam, never>('/review/call/{id}/keyEvent', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallByIdKeyEvent',
    });
}

export const meta = [{ tags: ['通话记录'], path: '/review/call/{id}/keyEvent', method: 'get' }];
