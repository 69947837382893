import React from 'react';
import Udesk from 'Udesk';
import config from '../../../../common/config/index';
import UdeskLocales from 'UdeskLocales';
import _isEmpty from 'lodash-es/isEmpty';
// import { postSearchTemplateAdvanced } from 'src/api/search-template/advanced';
import { getInspectionTasksSimple } from 'src/api/inspectionTasks/simple';

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    //删除并记录 删除元素
    const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, removed);
    return result;
};

// 插入排序
const insert = (list, insertObj, endIndex) => {
    const result = Array.from(list);
    //删除并记录 删除元素
    // const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, insertObj);
    return result;
};
const CUSTOMTYPEEDIT = 1; //筛选器可编辑状态
const CUTOMTYPESHOW = 2; //筛选器展示状态
class CustomFilterModalComponent extends React.Component {
    static propTypes = {};
    static defaultProps = {
        title: '',
        cacheKey: '',
        searchPlaceHolder: '',
        searchFieldName: 'keyword',
        type: Udesk.enums.filterTypes.call.id,
        // filter: null,
        editFilterVisible: false,
        selectedFilter: {},
        customTypeList: {
            customTypeEdit: CUSTOMTYPEEDIT,
            customTypeShow: CUTOMTYPESHOW,
        },
        filterList: [],
        visible: false,
    };
    privates = {
        displayConditionList: [],
        conditionList: [],
        judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        customJudgeLogic: '',
        data: {},
        isSave: null,
        filterName: '',
        updateType: null,
        conditionListFilter: [],
        logicList: [
            {
                id: 'logicList-1',
                content: UdeskLocales.current.components.common.customFilter.and,
                value: 'and',
                color: 'rgba(255,152,70,0.1)',
                borColor: 'rgba(255,152,70,1)',
            },
            {
                id: 'logicList-2',
                content: UdeskLocales.current.components.common.customFilter.or,
                value: 'or',
                color: 'rgba(148,95,185,0.1)',
                borColor: 'rgba(148,95,185,1)',
            },
            {
                id: 'logicList-3',
                content: '(',
                value: '(',
                color: 'rgba(255,154,195,0.1)',
                borColor: 'rgba(255,154,195,1)',
            },
            {
                id: 'logicList-4',
                content: ')',
                value: ')',
                color: 'rgba(255,154,195,0.1)',
                borColor: 'rgba(255,154,195,1)',
            },
        ],
        customType: 1,
        customName: '',
        customFilterList: [],
        customeFilterField: null,
        customeFilterFieldKey: [],
        customEdit: false,
        customDataSourceList: [],
        fieldList: [],
        _isFilterVisible: false,
        items: [],
        tarskList: [],
        tarskDel: false,
        // nameCount: 0,
        myDropTargetRef: null,
        itemsCount: 0,
    };
    static computes = {
        _fields: [
            'privates.fieldList',
            function ({ props, privates, locales }) {
                let { fieldList } = privates;
                return Udesk.business.fields.buildConditionFields({
                    fields: fieldList,
                });
            },
        ],
        _fieldValueSettings: [
            'privates.fieldList',
            function ({ props, privates, locales }) {
                let data = {};
                if (props.testTaskId) {
                    data = {
                        taskId: props.testTaskId,
                    };
                }
                return config.getFilterComponentSettings(data);
            },
        ],
    };
    actions = {
        handleConditionCancel() {
            this.trigger('cancel');
        },
        filterContidionChanged(conditions) {
            let showFieldList = this.privates.fieldList || [];
            let queryConditionList = this.actions.getQueryConditionList(conditions, showFieldList);
            this.privates.displayConditionList = conditions;
            this.privates.conditionListFilter = conditions.map((item, index) => {
                return {
                    id: `conditionListFilter-${index}`,
                    content: index + 1,
                    value: `${index + 1}`,
                };
            });
            let list = this.privates.items.filter(
                (current) => current.prevId !== `conditionListFilter-${conditions.length}`
            );
            this.privates.items = list.map((item, index) => {
                return {
                    ...item,
                    id: `drag-${index}`,
                };
            });
            this.privates.conditionList = queryConditionList;
            this.actions.update();
        },
        changeJudgeStrategy(value) {
            this.privates.judgeStrategy = value.target.value;
            if (
                value.target.value === Udesk.enums.operatorRuleTypes.all.id ||
                value.target.value === Udesk.enums.operatorRuleTypes.any.id
            ) {
                this.privates.customJudgeLogic = '';
            }
            this.actions.update();
        },
        getQueryConditionList(conditionList, showFieldList) {
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map((condition) => {
                        showFieldList.forEach((field) => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        getQueryConditionListField(conditionList, showFieldList) {
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map((condition) => {
                        showFieldList.forEach((field) => {
                            if (condition.field.inspectionTaskId) {
                                if (condition.field.id === field.statusKey) {
                                    condition.field = field;
                                }
                            } else {
                                if (condition.field.id === field.id) {
                                    condition.field = field;
                                }
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        onDragStart(option) {
            if (option.draggableId.indexOf('drag') !== -1) {
                this.privates.tarskDel = true;
                this.actions.update();
            }
        },
        onDragEnd(result) {
            let { itemsCount } = this.privates;
            if (!result.destination) {
                if (result.draggableId.indexOf('drag') !== -1) {
                    let list = this.privates.items.filter(
                        (current) => current.id !== result.draggableId
                    );
                    this.privates.items = list;
                    let str = '';
                    this.privates.items.forEach((item) => {
                        str += item.value + ' ';
                    });
                    this.privates.customJudgeLogic = str.trim();
                    this.privates.tarskDel = false;
                    this.actions.update();
                }
                return;
            }

            if (result.draggableId.indexOf('drag') !== -1) {
                const items = reorder(
                    this.privates.items,
                    result.source.index,
                    result.destination.index
                );
                let str = '';
                items.forEach((item) => {
                    str += item.value + ' ';
                });
                this.privates.customJudgeLogic = str.trim();
                this.privates.items = items;
                this.privates.tarskDel = false;
                this.actions.update();
            } else {
                if (result.draggableId.indexOf('-') !== 1) {
                    let drag = result.draggableId.split('-')[0];
                    let obj = this.privates[drag].find(
                        (current) => current.id === result.draggableId
                    );
                    if (obj) {
                        let data = {
                            id: `drag-${itemsCount + 1}`,
                            content: obj.content,
                            value: obj.value,
                            prevId: result.draggableId,
                            prevClass: drag,
                        };
                        const items = insert(this.privates.items, data, result.destination.index);
                        let str = '';
                        items.forEach((item) => {
                            str += item.value + ' ';
                        });
                        this.privates.customJudgeLogic = str.trim();
                        this.privates.itemsCount = itemsCount + 1;
                        this.privates.items = items;
                        this.actions.update();
                    }
                }
            }
        },
        customStaus(value) {
            this.privates.customType = parseInt(value, 10);
            this.privates.customEdit = false;
            this.actions.update();
        },
        handleConditionDel() {
            let { customeFilterField } = this.privates;
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(
                `search-template/${customeFilterField.id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(
                            this.locales.components.common.customFilter.delSuccess
                        );
                        this.actions.getCustomFilterList();
                        this.trigger('onConditionFilterSaved', true);
                        resolve(resp);
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                this.locales.components.pages.components.listManage.getRecordsFailed
                        );
                        reject(reason);
                    }
                );
            });
        },
        handleConditionApply() {
            let { conditionList, customJudgeLogic, judgeStrategy } = this.privates;
            customJudgeLogic = this.actions.getCustom(customJudgeLogic);
            let list =
                conditionList.map((item) => {
                    let formmatItem = { ...item };
                    if (item.operator === 'between' && Array.isArray(item.value)) {
                        formmatItem.value = `${formmatItem.value[0]} 00:00:00,${formmatItem.value[1]} 23:59:59`;
                    }
                    if (item.field.statusKey) {
                        return {
                            ...formmatItem,
                            field: {
                                ...item.field,
                                id: item.field.statusKey,
                            },
                        };
                    } else {
                        return formmatItem;
                    }
                }) || [];
            if (this.props.applyAction) {
                this.props.applyAction(list, customJudgeLogic, judgeStrategy, null, null);
            }
            this.privates.customName = null;
            this.privates.customId = null;
            this.trigger('handleConditionCancelWithoutClearData');
        },
        handleConditionApplyField() {
            let { customeFilterField, fieldList } = this.privates;
            this.privates.conditionList = this.actions.getQueryConditionListField(
                customeFilterField.conditionList,
                fieldList
            );
            this.privates.judgeStrategy = customeFilterField.judgeStrategy;
            this.privates.customJudgeLogic = customeFilterField.customJudgeLogic;
            this.privates.customJudgeLogic = this.actions.getCustom(this.privates.customJudgeLogic);
            let list =
                this.privates.conditionList.map((item) => {
                    // let formmatItem = {...item};
                    // if(item.operator === "between"){
                    //     formmatItem.value = `${formmatItem.value[0]} 00:00:00,${formmatItem.value[1]} 23:59:59`;
                    // }
                    const { statusKey, ...field } = item.field;
                    if (statusKey) {
                        field.id = field.statusKey = statusKey;
                    }
                    return {
                        ...item,
                        field,
                    };
                }) || [];
            this.privates.customName = null;
            this.privates.customId = null;
            if (this.props.applyAction) {
                this.props.applyAction(
                    list,
                    this.privates.customJudgeLogic,
                    this.privates.judgeStrategy,
                    null,
                    null
                );
            }
            this.trigger('handleConditionCancelWithoutClearData');
            this.actions.update();
        },
        handleConditionSave() {
            let {
                customName,
                // nameCount,
                customId,
                conditionList,
                customJudgeLogic,
                judgeStrategy,
            } = this.privates;
            let { customSaveAjax } = this.props;
            customJudgeLogic = this.actions.getCustom(customJudgeLogic);
            let list = conditionList.map((item) => {
                let formmatItem = { ...item };
                if (formmatItem.operator === 'between' && Array.isArray(formmatItem.value)) {
                    formmatItem.value = `${formmatItem.value[0]} 00:00:00,${formmatItem.value[1]} 23:59:59`;
                }
                const { statusKey, ...field } = formmatItem.field;
                if (statusKey) {
                    field.id = field.statusKey = statusKey;
                }
                delete formmatItem.optionValueList;
                return {
                    ...formmatItem,
                    field,
                };
            });
            let data = {
                templateType: this.props.type,
                name: customName,
                conditionList: list,
                customJudgeLogic,
                judgeStrategy,
            };
            const successHandle = (resp, resolve) => {
                Udesk.ui.notify.success(this.locales.components.common.customFilter.updateSuccess);
                if (this.props.applyAction) {
                    this.props.applyAction(
                        list,
                        customJudgeLogic,
                        judgeStrategy,
                        customId,
                        customName
                    );
                }
                this.actions.getCustomFilterList();
                this.trigger('onConditionFilterSaved', true, resp.data);
                resolve(resp);
            };
            const errorHandle = (reason, reject) => {
                Udesk.ui.notify.error(
                    reason.errorMsg ||
                        this.locales.components.pages.components.listManage.getRecordsFailed
                );
                reject(reason);
            };
            //抽检列表改为高级筛选器优化加入
            if (this.props.querryType === 'spotCheckList') {
                data.templateType = 66;
                let { taskId } = this.props;
                if (taskId) {
                    data.taskInclude = taskId;
                }
            }
            //客户管理列表高级筛选器
            if (this.props.querryType === 'personaField') {
                data.templateType = 88;
            }
            if (customName) {
                // if (nameCount > 10) {
                //     Udesk.ui.notify.error(this.locales.components.common.customFilter.holderCustomFilterNameLength);
                // } else {
                if (customId) {
                    data.id = this.privates.customId;
                }
                let { sdkOptions } = this.props;
                let url = Udesk.business.apiPath.concatApiPath(`search-template/save`, sdkOptions);
                let method = Udesk.enums.requestMethods.create.name;
                if (data.id) {
                    url = Udesk.business.apiPath.concatApiPath(
                        `search-template/update`,
                        sdkOptions
                    );
                    method = Udesk.enums.requestMethods.update.name;
                } else {
                    url = Udesk.business.apiPath.concatApiPath(`search-template/save`, sdkOptions);
                    method = Udesk.enums.requestMethods.create.name;
                }
                if (customSaveAjax) {
                    new Promise((resolve, reject) => {
                        customSaveAjax(data, method).then(
                            (resp) => successHandle(resp, resolve),
                            (reason) => errorHandle(reason, reject)
                        );
                    });
                } else {
                    new Promise((resolve, reject) => {
                        Udesk.ajax(url, data, method).then(
                            (resp) => successHandle(resp, resolve),
                            (reason) => errorHandle(reason, reject)
                        );
                    });
                }
                // }
            } else {
                Udesk.ui.notify.error(
                    this.locales.components.common.customFilter.customFilterNameNone
                );
            }
        },
        getCustomFilterList(value) {
            let { sdkOptions, businessFlag, querryType, taskId, customAjax } = this.props;
            const successHandle = (resp, resolve) => {
                let newData = [...resp.data];
                resolve({ ...resp, data: newData });
                this.privates.customFilterList = newData;
                this.privates.customeFilterField = {};
                this.privates.customeFilterFieldKey = [];
                for (let i = 0; i < newData.length; i++) {
                    if (newData[i].status !== 0) {
                        this.privates.customeFilterField = newData[i];
                        this.privates.customeFilterFieldKey = [
                            `${this.privates.customeFilterField.id}-${i}`,
                        ];
                        break;
                    }
                }
                this.actions.update();
            };
            const errorHandle = (reason, reject) => {
                Udesk.ui.notify.error(
                    reason.errorMsg ||
                        this.locales.components.pages.components.listManage.getRecordsFailed
                );
                reject(reason);
            };
            if (customAjax) {
                new Promise((resolve, reject) => {
                    customAjax().then(
                        (resp) => {
                            successHandle(resp, resolve);
                        },
                        (resp) => {
                            errorHandle(resp, reject);
                        }
                    );
                });
            } else {
                let data = {
                    type: this.props.type,
                };
                if (value) {
                    data.name = value;
                }
                if (taskId) {
                    data.inspectionTaskId = taskId;
                }
                //抽检列表改为高级筛选器加入
                if (querryType === 'spotCheckList') {
                    data.type = 66;
                }
                //客户管理列表高级筛选器
                if (querryType === 'personaField') {
                    data.type = 88;
                }
                let url = Udesk.business.apiPath.concatApiPath(
                    `search-template/advanced`,
                    sdkOptions
                );
                new Promise((resolve, reject) => {
                    if (businessFlag || querryType === 'spotCheckList') {
                        data = {
                            ...data,
                            dataFieldSourceList: ['a', 'pr'],
                            fieldIdList: ['f_3', 'f_10'],
                            excludeFieldSourceList: [],
                            excludeFieldIdList: [],
                        };
                    }
                    Udesk.ajax.post(url, data).then(
                        (resp) => {
                            successHandle(resp, resolve);
                        },
                        (resp) => {
                            errorHandle(resp, reject);
                        }
                    );
                });
            }
        },
        handleConditionEdit() {
            let { customeFilterField } = this.privates;
            this.privates.displayConditionList =
                !_isEmpty(customeFilterField) &&
                !_isEmpty(customeFilterField.conditionList) &&
                customeFilterField.conditionList.map((item) => {
                    let itemCopy = { ...item };
                    if (itemCopy.operator === 'between' && typeof itemCopy.value === 'string') {
                        let res = itemCopy.value.split(/[,]+|\s+/);
                        itemCopy.value = [`${res[0]}`, `${res[2]}`];
                    }

                    if (item.field.inspectionTaskId) {
                        return {
                            field: `${item.field.id}-${item.field.inspectionTaskId}`,
                            operator: item.operator,
                            value: item.value ? itemCopy.value : null,
                            statusKey: item.field.id,
                        };
                    } else {
                        return {
                            field: item.field.id,
                            operator: item.operator,
                            value: item.value ? itemCopy.value : null,
                        };
                    }
                });
            this.privates.conditionList = customeFilterField.conditionList;
            this.privates.customType = 1;
            this.privates.customEdit = true;
            this.privates.customId = customeFilterField.id;
            this.privates.customName = customeFilterField.name;
            // this.privates.nameCount = customeFilterField.name.length;
            this.privates.judgeStrategy = customeFilterField.judgeStrategy;
            this.privates.customJudgeLogic = customeFilterField.customJudgeLogic;
            this.privates.conditionListFilter = this.privates.displayConditionList.map(
                (item, index) => {
                    return {
                        id: `conditionListFilter-${index}`,
                        content: index + 1,
                        value: `${index + 1}`,
                    };
                }
            );
            if (this.privates.customJudgeLogic && this.privates.customJudgeLogic.length > 0) {
                this.privates.items = [];
                let list = this.privates.customJudgeLogic.split(/(or|and|!|\(|\))/);
                this.privates.itemsCount = list.length;
                list.forEach((item, index) => {
                    item = item.trim();
                    if (item && item.length > 0) {
                        if (this.privates.logicList.find((current) => current.value === item)) {
                            this.privates.items = [
                                ...this.privates.items,
                                {
                                    id: `drag-${index}`,
                                    content: this.privates.logicList.find(
                                        (current) => current.value === item
                                    ).content,
                                    value: this.privates.logicList.find(
                                        (current) => current.value === item
                                    ).value,
                                    prevId: this.privates.logicList.find(
                                        (current) => current.value === item
                                    ).id,
                                    prevClass: this.privates.logicList
                                        .find((current) => current.value === item)
                                        .id.split('-')[0],
                                },
                            ];
                        } else if (
                            this.privates.conditionListFilter.find(
                                (current) => current.value === item
                            )
                        ) {
                            this.privates.items = [
                                ...this.privates.items,
                                {
                                    id: `drag-${index}`,
                                    content: this.privates.conditionListFilter.find(
                                        (current) => current.value === item
                                    ).content,
                                    value: this.privates.conditionListFilter.find(
                                        (current) => current.value === item
                                    ).value,
                                    prevId: this.privates.conditionListFilter.find(
                                        (current) => current.value === item
                                    ).id,
                                    prevClass: this.privates.conditionListFilter
                                        .find((current) => current.value === item)
                                        .id.split('-')[0],
                                },
                            ];
                        }
                    }
                });
            }
            this.actions.update();
        },
        customInputChange(e) {
            this.privates.customName = e.target.value;
            // this.privates.nameCount = e.target.value.length;
            this.actions.update();
        },
        customInputSearch(value) {
            this.actions.getCustomFilterList(value);
        },
        customInputSearchChange(e) {
            if (!e.target.value) {
                this.actions.getCustomFilterList();
            }
        },
        handleConditionOtherSave() {
            let { sdkOptions } = this.props;
            // let { customName, nameCount, conditionList, customJudgeLogic, judgeStrategy } = this.privates;
            let { customName, conditionList, customJudgeLogic, judgeStrategy } = this.privates;
            customJudgeLogic = this.actions.getCustom(customJudgeLogic);
            let list = conditionList.map((item) => {
                let formmatItem = { ...item };
                if (formmatItem.operator === 'between' && Array.isArray(formmatItem.value)) {
                    formmatItem.value = `${formmatItem.value[0]} 00:00:00,${formmatItem.value[1]} 23:59:59`;
                }
                if (item.field.statusKey) {
                    return {
                        ...formmatItem,
                        field: {
                            ...item.field,
                            id: item.field.statusKey,
                        },
                    };
                } else {
                    return formmatItem;
                }
            });
            let data = {
                templateType: this.props.type,
                name: customName,
                conditionList: list,
                customJudgeLogic,
                judgeStrategy,
            };
            //抽检列表改为高级筛选器优化加入
            if (this.props.querryType === 'spotCheckList') {
                data.templateType = 66;
                let { taskId } = this.props;
                if (taskId) {
                    data.taskInclude = taskId;
                }
            }
            //客户管理列表高级筛选器
            if (this.props.querryType === 'personaField') {
                data.templateType = 88;
            }
            if (customName) {
                // if (nameCount > 10) {
                //     Udesk.ui.notify.error(this.locales.components.common.customFilter.holderCustomFilterNameLength);
                // } else {
                let url = Udesk.business.apiPath.concatApiPath(`search-template/save`, sdkOptions);
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(url, data).then(
                        (resp) => {
                            Udesk.ui.notify.success(
                                this.locales.components.common.customFilter.saveSuccess
                            );
                            this.actions.getCustomFilterList();
                            this.trigger('onConditionFilterSaved', true, resp.data);
                            this.actions.update();
                            resolve(resp);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    this.locales.components.pages.components.listManage
                                        .getRecordsFailed
                            );
                            reject(reason);
                        }
                    );
                });
                // }
            } else {
                Udesk.ui.notify.error(
                    this.locales.components.common.customFilter.customFilterNameNone
                );
            }
        },
        menuKeysChange(list) {
            let { customFilterList } = this.privates;
            this.privates.customeFilterFieldKey = [`${list.key}`];
            this.privates.customeFilterField = customFilterList.find(
                (current) => current.id === parseInt(list.key, 10)
            );
            this.privates.conditionList = this.privates.customeFilterField.conditionList;
            this.privates.customJudgeLogic = this.privates.customeFilterField.customJudgeLogic;
            this.privates.judgeStrategy = this.privates.customeFilterField.judgeStrategy;
            this.actions.update();
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            let getValue = (item) =>
                ['agentName', 'agentGroupName', 'tagName', 'realname']
                    .map((key) => item[key])
                    .filter((name) => !!name)
                    .join();

            !_isEmpty(data) &&
                !_isEmpty(data.conditionList) &&
                data.conditionList.forEach((item) => {
                    const type = item.field.label;
                    const operator = obj[item.operator];
                    let { postfix, value } = item;
                    let newItem = null;
                    if (value) {
                        if (
                            item.field.optionValueList &&
                            item.field.optionValueList.length > 0 &&
                            [
                                Udesk.enums.fieldTypes.sigleListbox.id,
                                Udesk.enums.fieldTypes.multiListbox.id,
                                Udesk.enums.fieldTypes.supervision.id,
                                Udesk.enums.fieldTypes.keyEvents.id,
                            ].includes(item.field.fieldType)
                        ) {
                            value = this.getOptionValue(value, item.field.optionValueList);
                        } else if (Array.isArray(value)) {
                            value = value.map(getValue).join();
                        } else if (typeof value === 'object') {
                            value = getValue(item.value);
                        }
                        newItem = {
                            type,
                            operator,
                            value,
                        };
                    } else {
                        newItem = {
                            type,
                            operator,
                            operatorTitle: item.value,
                        };
                    }
                    if (postfix) {
                        const { applyRole, applyConditionScope, applyConditionLimit } = postfix;
                        const result = [];
                        if (applyRole) {
                            result.push(
                                [
                                    /* 检测角色 */ locales.components.customFilterModal.component
                                        .detectRoles,
                                    '：',
                                    Udesk.enums.applyRoles.getName(applyRole),
                                ].join('')
                            );
                        }
                        if (applyConditionScope) {
                            const temp = [
                                /* 检测范围 */ locales.components.customFilterModal.component
                                    .detectionRange,
                                '：',
                                Udesk.enums.applyConditionScopes.getName(applyConditionScope),
                            ];
                            if (
                                [
                                    Udesk.enums.applyConditionScopes.before.id,
                                    Udesk.enums.applyConditionScopes.after.id,
                                    Udesk.enums.applyConditionScopes.around.id,
                                ].includes(applyConditionScope)
                            ) {
                                temp.push(
                                    applyConditionLimit +
                                        /* 句 */ locales.components.customFilterModal.component
                                            .sentence
                                );
                            }
                            result.push(temp.join(''));
                        }
                        if (result.length > 0) {
                            newItem.postfixInfo = result.join('  ');
                        }
                    }
                    list.push(newItem);
                });

            return list.map((item, index) => {
                return (
                    <div className="custom-filter-field-list-page">
                        <div className="custom-filter-field-info-count">{index + 1}</div>
                        <div className="custom-filter-field-info">
                            <div>
                                <div className="custom-fitler-field-info-type" title={item.type}>
                                    {item.type}
                                </div>
                                <div
                                    className="custom-fitler-field-info-operator"
                                    title={item.operatorTitle}
                                >
                                    {item.operator}
                                </div>
                                {item.value ? (
                                    <div
                                        className="custom-fitler-field-info-value"
                                        title={item.value}
                                    >
                                        {item.value}
                                    </div>
                                ) : null}
                            </div>
                            {item.postfixInfo ? (
                                <div className="custom-fitler-field-info-postfix">
                                    {item.postfixInfo}
                                </div>
                            ) : null}
                        </div>
                    </div>
                );
            });
        },
        customJudgeLogicChange(e) {
            let reg = /[\u4e00-\u9fa5]/g;
            this.privates.customJudgeLogic = e.target.value.replace(reg, '');
            this.privates.items = [];
            this.actions.update();
        },
        reset() {
            this.privates.customJudgeLogic = '';
            this.privates.displayConditionList = [];
            this.privates.customName = '';
            // this.privates.nameCount = 0;
            this.privates.judgeStrategy = 1;
            this.privates.customId = null;
            this.privates.conditionList = [];
            this.privates.items = [];
            this.actions.update();
        },
        /**
         * 获取页面所需的ajax数据
         * @param {*} sourceValue
         * @param {*} taskId
         * @param {*} flag
         * @param {*} querryType
         * @param {*} fieldAjax 筛选器列表接口
         * @param {*} customAjax 常用筛选列表接口,此方法可以为undefined, 如果使用请使用useCallback包裹
         * @param {*} filterInspectData 是否过滤质检任务条件
         * @returns
         */
        promisePublicUrl(
            sourceValue,
            taskId,
            flag,
            querryType,
            fieldAjax,
            customAjax,
            filterInspectData
        ) {
            let { sdkOptions } = this.props;
            let data = { sourceList: [] };
            this.privates.fieldList = [];
            this.privates.customDataSourceList = [];
            this.actions.update();
            if (sourceValue === 0) {
                data = {
                    sourceList: [1, 2],
                };
            } else {
                data = {
                    sourceList: [sourceValue],
                };
            }
            let searchTemplate = null;
            if (fieldAjax) {
                searchTemplate = fieldAjax();
            } else {
                let fieldUrl = Udesk.business.apiPath.concatApiPath(
                    `field/data/search-template`,
                    sdkOptions
                );
                searchTemplate = new Promise((resolve, reject) => {
                    if (flag) {
                        data = {
                            ...data,
                            typeList: [1, 3],
                        };
                    } else if (querryType === 'manualAdd') {
                        data = {
                            ...data,
                            typeList: [1, 2, 3],
                        };
                    } else if (querryType === 'speechRecognition') {
                        data = {
                            ...data,
                            typeList: [1],
                        };
                    } else if (querryType === 'spotCheckList') {
                        data = {
                            ...data,
                            taskId,
                            typeList: [1, 7, 16],
                        };
                    } else if (querryType === 'reviewList') {
                        data = {
                            ...data,
                            taskId,
                            typeList: [1, 8, 16],
                        };
                    } else if (querryType === 'personaField') {
                        data = {
                            ...data,
                            typeList: [9],
                        };
                    } else if (querryType === 'autoAdd') {
                        data = {
                            ...data,
                            typeList: [1, 3, 16],
                        };
                    } else {
                        data = {
                            ...data,
                            typeList: [],
                        };
                    }
                    Udesk.ajax.post(fieldUrl, data).then(
                        (resp) => {
                            resolve(resp);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    this.locales.components.pages.components.listManage
                                        .getRecordsFailed
                            );
                            reject(reason);
                        }
                    );
                });
            }

            let advanced = null;
            if (customAjax) {
                advanced = customAjax();
            } else {
                let customUrl = Udesk.business.apiPath.concatApiPath(
                    `search-template/advanced`,
                    sdkOptions
                );
                advanced = new Promise((resolve, reject) => {
                    if (!flag && !querryType) {
                        let data = {
                            type: sourceValue,
                        };
                        if (taskId) {
                            data.inspectionTaskId = taskId;
                        }
                        Udesk.ajax.post(customUrl, data).then(
                            (resp) => {
                                resolve(resp);
                            },
                            (reason) => {
                                Udesk.ui.notify.error(
                                    reason.errorMsg ||
                                        this.locales.components.pages.components.listManage
                                            .getRecordsFailed
                                );
                                reject(reason);
                            }
                        );
                    } else {
                        let data = {
                            type: sourceValue,
                            dataFieldSourceList: ['a', 'pr'],
                            fieldIdList: ['f_3', 'f_10'],
                            excludeFieldSourceList: [],
                            excludeFieldIdList: [],
                        };
                        //抽检列表改为高级筛选器加入
                        if (querryType === 'spotCheckList') {
                            data.type = 66;
                            if (taskId) {
                                data.inspectionTaskId = taskId;
                            }
                        }
                        if (querryType === 'reviewList') {
                            data.dataFieldSourceList.push('af');
                        }
                        //客户管理列表高级筛选器
                        if (querryType === 'personaField') {
                            data.type = 88;
                        }
                        Udesk.ajax.post(customUrl, data).then(
                            (resp) => {
                                resolve(resp);
                            },
                            (reason) => {
                                Udesk.ui.notify.error(
                                    reason.errorMsg ||
                                        this.locales.components.pages.components.listManage
                                            .getRecordsFailed
                                );
                                reject(reason);
                            }
                        );
                    }
                });
            }

            // let teskUrl = Udesk.business.apiPath.concatApiPath(
            //     `inspectionTasks/simple?inspectDataSource=${sourceValue}`,
            //     sdkOptions
            // );
            let taskUrl = Udesk.business.apiPath.concatApiPath(
                `field/data/task/${taskId}`,
                sdkOptions
            );
            let inspectData = new Promise((resolve, reject) => {
                if (taskId || flag || querryType || filterInspectData) {
                    resolve(null);
                } else {
                    getInspectionTasksSimple({
                        params: {
                            inspectDataSource: sourceValue,
                        },
                        useCache: true,
                    }).then(
                        (resp) => {
                            resolve(resp);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    this.locales.components.pages.components.listManage
                                        .getRecordsFailed
                            );
                            reject(reason);
                        }
                    );
                }
            });
            let taskData = new Promise((resolve, reject) => {
                if (taskId && !flag) {
                    Udesk.ajax.get(taskUrl).then(
                        (resp) => {
                            resolve(resp);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(
                                reason.errorMsg ||
                                    this.locales.components.pages.components.listManage
                                        .getRecordsFailed
                            );
                            reject(reason);
                        }
                    );
                } else {
                    resolve(null);
                }
            });
            return {
                searchTemplate,
                advanced,
                inspectData,
                taskData,
            };
        },
        getCustom(str) {
            if (str && str !== '') {
                let list = str.split(/(or|and|!|\(|\))/);
                list = list.map((item) => item.trim());
                let logic = list.join(' ').trim();
                return logic;
            } else {
                return str;
            }
        },
    };
    model() {
        let { type, taskId, businessFlag, querryType, fieldAjax, customAjax, filterInspectData } =
            this.props;
        return this.actions.promisePublicUrl(
            type,
            taskId,
            businessFlag,
            querryType,
            fieldAjax,
            customAjax,
            filterInspectData
        );
    }
    parseModel(asyncModel) {
        return asyncModel;
    }
    onModelResolved(asyncModel) {
        let { type, taskId, taskName, filterList, filterTaskData, querryType } = this.props;
        if (asyncModel.searchTemplate) {
            if (asyncModel.searchTemplate.data && asyncModel.searchTemplate.data.length > 0) {
                asyncModel.searchTemplate.data.forEach((item, index) => {
                    item.typeList.forEach((items) => {
                        let listLength = [];
                        if (asyncModel.searchTemplate.data.length > 1) {
                            if (type === 0 && index !== 1) {
                                listLength = Udesk.enums.customFilterFieldList.filter(
                                    (current) => current.value === items.type
                                );
                            } else {
                                listLength = Udesk.enums.customFilterFieldList.filter(
                                    (current) => current.value === items.type && current.value === 1
                                );
                            }
                        } else {
                            // ['spotCheckList', 'personaField'].includes(querryType)
                            // querryType === 'spotCheckList'
                            if (
                                ['spotCheckList', 'personaField', 'reviewList'].includes(querryType)
                            ) {
                                listLength = Udesk.enums.customFilterFieldList.filter(
                                    (current) => current.value === items.type
                                );
                            } else if (type === Udesk.enums.filterTypes.taobao.id) {
                                listLength = Udesk.enums.customFilterFieldList.filter(
                                    (current) => current.value === items.type
                                );
                            } else {
                                listLength = Udesk.enums.customFilterFieldList.filter(
                                    (current) => current.value === items.type && current.value !== 5
                                );
                            }
                        }
                        if (listLength.length > 0) {
                            if (listLength.length > 1) {
                                //企业微信质检的会话列表 数据源类型整体会话为6，切片为5，切片与会话使用统一模板：Udesk.enums.inspectDataSources.wechatDialogue.id
                                //因历史原因，电商会话需要特殊处理，因为维护了两套枚举，须统一
                                let enumItem = listLength.find((current) => {
                                    if (
                                        [
                                            Udesk.enums.inspectDataSources.wechatDialogue.id,
                                            6,
                                        ].includes(item.source)
                                    ) {
                                        return (
                                            current.id ===
                                            Udesk.enums.customFilterFieldList.wechat.id
                                        );
                                    }
                                    if (
                                        [Udesk.enums.inspectDataSources.taobao.id].includes(
                                            item.source
                                        )
                                    ) {
                                        return (
                                            current.id ===
                                            Udesk.enums.customFilterFieldList.taobao.id
                                        );
                                    }
                                    // 针对异议话术高阶筛选做的处理 source = 4
                                    if (
                                        [
                                            Udesk.enums.inspectDataSources.realTimeTextDialogue.id,
                                            Udesk.enums.inspectDataSources.reviewCall.id,
                                        ].includes(item.source)
                                    ) {
                                        if (
                                            current.id ===
                                            Udesk.enums.customFilterFieldList.callRecord.id
                                        ) {
                                            return true;
                                        }
                                    }

                                    if (
                                        [Udesk.enums.inspectDataSources.eComment.id].includes(
                                            item.source
                                        )
                                    ) {
                                        return (
                                            current.id ===
                                            Udesk.enums.customFilterFieldList.eComment.id
                                        );
                                    }

                                    if (
                                        [Udesk.enums.inspectDataSources.mComment.id].includes(
                                            item.source
                                        )
                                    ) {
                                        return (
                                            current.id ===
                                            Udesk.enums.customFilterFieldList.mComment.id
                                        );
                                    }

                                    return current.id === item.source;
                                });
                                let data = {
                                    id: enumItem?.id,
                                    name: enumItem?.name,
                                    fieldList: items.fieldList.filter(
                                        (item) => !filterList.includes(item.id)
                                    ),
                                };
                                this.privates.customDataSourceList = [
                                    data,
                                    ...this.privates.customDataSourceList,
                                ];
                            } else {
                                let data = {
                                    id: listLength[0].id,
                                    name: listLength[0].name,
                                    fieldList: items.fieldList.filter(
                                        (item) => !filterList.includes(item.id)
                                    ),
                                };
                                if (
                                    this.privates.customDataSourceList.find(
                                        (current) => current.id === listLength[0].key
                                    )
                                ) {
                                    items.fieldList.forEach((itemss) => {
                                        if (
                                            !this.privates.customDataSourceList
                                                .find((current) => current.id === listLength[0].key)
                                                .fieldList.find(
                                                    (current) => current.id === itemss.id
                                                )
                                        ) {
                                            this.privates.customDataSourceList.find(
                                                (current) => current.id === listLength[0].key
                                            ).fieldList = [
                                                ...this.privates.customDataSourceList.find(
                                                    (current) => current.id === listLength[0].key
                                                ).fieldList,
                                                itemss,
                                            ];
                                        }
                                    });
                                } else {
                                    this.privates.customDataSourceList = [
                                        ...this.privates.customDataSourceList,
                                        data,
                                    ];
                                }
                            }
                        }
                        items.fieldList
                            .filter((item) => !filterList.includes(item.id))
                            .forEach((itemss) => {
                                if (
                                    !this.privates.fieldList.find(
                                        (current) => current.id === itemss.id
                                    )
                                ) {
                                    this.privates.fieldList = [...this.privates.fieldList, itemss];
                                }
                            });
                    });
                });
            }
        }
        if (asyncModel.inspectData) {
            if (asyncModel.inspectData.data && asyncModel.inspectData.data.length > 0) {
                asyncModel.inspectData.data.forEach((item) => {
                    let data = {
                        id: item.id,
                        name: item.name,
                        fieldList: item.dataFields
                            .filter((item) => !filterList.includes(item.id))
                            .map((items) => {
                                return {
                                    ...items,
                                    id: `${items.id}-${items.inspectionTaskId}`,
                                };
                            }),
                    };
                    this.privates.customDataSourceList = [
                        ...this.privates.customDataSourceList,
                        data,
                    ];
                    item.dataFields
                        .filter((item) => !filterList.includes(item.id))
                        .forEach((itemss) => {
                            if (
                                !_isEmpty(this.privates.fieldList) &&
                                !this.privates.fieldList.find((current) => current.id === itemss.id)
                            ) {
                                this.privates.fieldList = [
                                    ...this.privates.fieldList,
                                    {
                                        ...itemss,
                                        id: `${itemss.id}-${itemss.inspectionTaskId}`,
                                        statusKey: itemss.id,
                                    },
                                ];
                            }
                        });
                });
            }
        }
        if (asyncModel.advanced) {
            if (asyncModel.advanced.data && asyncModel.advanced.data.length > 0) {
                this.privates.customFilterList = asyncModel.advanced.data;
                for (let i = 0; i < asyncModel.advanced.data.length; i++) {
                    if (asyncModel.advanced.data[i].status !== 0) {
                        this.privates.customeFilterField = asyncModel.advanced.data[i];
                        this.privates.customeFilterFieldKey = [
                            `${
                                this.privates.customeFilterField &&
                                this.privates.customeFilterField.id
                            }-${i}`,
                        ];
                        break;
                    }
                }
            }
        }
        if (asyncModel.taskData) {
            if (
                asyncModel.taskData.data &&
                asyncModel.taskData.data.length > 0 &&
                !filterTaskData
            ) {
                const _taskData = asyncModel.taskData.data
                    .filter((item) => !filterList.includes(item.id))
                    .filter((i) => i.fieldName !== 'business_keywords');
                let data = {
                    id: taskId,
                    name: taskName,
                    fieldList: _taskData.map((items) => {
                        return {
                            ...items,
                            id: `${items.id}-${items.inspectionTaskId}`,
                        };
                    }),
                };
                this.privates.customDataSourceList = [...this.privates.customDataSourceList, data];
                _taskData.forEach((itemss) => {
                    if (!this.privates.fieldList.find((current) => current.id === itemss.id)) {
                        this.privates.fieldList = [
                            ...this.privates.fieldList,
                            {
                                ...itemss,
                                id: `${itemss.id}-${itemss.inspectionTaskId}`,
                                statusKey: itemss.id,
                            },
                        ];
                    }
                });
            }
        }
        this.actions.update();
    }
    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible) {
            this.actions.update();
            this.privates.customType = this.props.customTypeList.customTypeEdit;
            this.privates.judgeStrategy = this.props.judgeStrategy;
            this.privates.customJudgeLogic = this.props.customJudgeLogic;
            this.privates.conditionList = formmatBetweenConditionListData(
                this.props.conditionList
            ).map((item) => {
                if (item.field.inspectionTaskId) {
                    return {
                        ...item,
                        field: {
                            ...item.field,
                            id: `${item.field.id}-${item.field.inspectionTaskId}`,
                            statusKey: item.field.id,
                        },
                    };
                }
                return item;
            });

            this.privates.displayConditionList = formmatBetweenConditionListData(
                this.privates.conditionList
            ).map((item) => {
                let data = {
                    field: item.field.id,
                    operator: item.operator,
                };
                if (item.value) {
                    data = {
                        ...data,
                        value: item.value,
                    };
                }
                if (item.postfix) {
                    data.postfix = item.postfix;
                }
                return data;
            });
            this.privates.customId = this.props.customId;
            this.privates.customName = this.props.customName;
            // this.privates.nameCount = this.props.customName ? this.props.customName.length : 0;
            if (this.privates.customId) {
                this.privates.customEdit = true;
            }
            this.privates.conditionListFilter = formmatBetweenConditionListData(
                this.props.conditionList
            ).map((item, index) => {
                return {
                    id: `conditionListFilter-${index}`,
                    content: index + 1,
                    value: `${index + 1}`,
                };
            });
            if (this.props.customJudgeLogic && this.props.customJudgeLogic.length > 0) {
                this.privates.items = [];
                let list = this.privates.customJudgeLogic.split(/(or|and|!|\(|\))/);
                this.privates.itemsCount = list.length;
                list.forEach((item, index) => {
                    item = item.trim();
                    if (item && item.length > 0) {
                        let obj = this.privates.logicList.find((current) => current.value === item);
                        let objs = this.privates.conditionListFilter.find(
                            (current) => current.value === item
                        );
                        if (obj) {
                            this.privates.items = [
                                ...this.privates.items,
                                {
                                    id: `drag-${index}`,
                                    content: obj.content,
                                    value: obj.value,
                                    prevId: obj.id,
                                    prevClass: obj.id.split('-')[0],
                                },
                            ];
                        } else if (objs) {
                            this.privates.items = [
                                ...this.privates.items,
                                {
                                    id: `drag-${index}`,
                                    content: objs.content,
                                    value: objs.value,
                                    prevId: objs.id,
                                    prevClass: objs.id.split('-')[0],
                                },
                            ];
                        }
                    }
                });
            }
            this.actions.update();
        }
        if (this.props.type !== prevProps.type) {
            this.privates.customDataSourceList = [];
            this.privates.fieldList = [];
            this.actions.reloadModel();
        }
        // 自定义常用筛选接口如果发生参数变化，将会重新执行
        if (this.props.customAjax !== prevProps.customAjax) {
            this.props.customAjax().then((resp) => {
                this.privates.customFilterList = resp.data || [];
                this.privates.customeFilterField = {};
                this.privates.customeFilterFieldKey = [];
                for (let i = 0; i < this.privates.customFilterList.length; i++) {
                    if (resp.data[i].status !== 0) {
                        this.privates.customeFilterField = resp.data[i];
                        this.privates.customeFilterFieldKey = [
                            `${
                                this.privates.customeFilterField &&
                                this.privates.customeFilterField.id
                            }-${i}`,
                        ];
                        break;
                    }
                }
            });
        }
    }

    getOptionValue(id, optionValueList) {
        let value = optionValueList.filter((item) => {
            if (Array.isArray(id)) {
                return id.includes(item.id);
            } else {
                return item.id === id;
            }
        });
        if (value.length > 0) {
            value = value.map((item) => {
                return item.name;
            });
            return value.join(',');
        } else {
            return '';
        }
    }
}

export default CustomFilterModalComponent;

// function formmatBetweenData(data){
//     let originData = data;
//     for(let i = 0; i < originData.length; i++){
//         let conditionList = originData[i] && originData[i].conditionList && Array.isArray(originData[i].conditionList) ? originData[i].conditionList : [];
//         for(let j = 0; j < conditionList.length; j++){
//             if(conditionList[j].operator === 'between' && typeof conditionList[j].value === 'string'){
//                 let res = conditionList[j].value.split(/[,]+|\s+/);
//                 conditionList[j].value = [`${res[0]}`,`${res[2]}`];
//             }
//         }
//     }
//     return originData;
// }
function formmatBetweenConditionListData(data) {
    let originData = data.map((i) => ({ ...i }));
    for (let j = 0; j < originData.length; j++) {
        if (originData[j].operator === 'between' && typeof originData[j].value === 'string') {
            let res = originData[j].value.split(/[,]+|\s+/);
            originData[j].value = [`${res[0]}`, `${res[2]}`];
        }
    }
    return originData;
}
