import {
    NotImplementedError
} from "../../../../error";

class UploadCredentialBaseClass {
    abort() {
        throw new NotImplementedError("abort");
    }
}

export default UploadCredentialBaseClass;