// 主界面
import Home from '../../pages/home/index';
import GeneralSettingPermissionManagement from 'Component/pages/permission-management';
// gong 员工管理
import AdminGongUser from 'Component/pages/gong/user-manage';
import AdminGongUserList from 'Component/pages/gong/user-manage/list';
import AdminGongUserEdit from 'Component/pages/gong/user-manage/edit';
import AdminGongUserDetail from 'Component/pages/gong/user-manage/detail';

// gong 角色管理
import AdminGongRole from 'src/pages/general-setting/permission-management/role.tsx';
import GeneralSettingRoleDetail from 'src/pages/general-setting/permission-management/role/detail/index';
import AdminGongRoleList from 'src/pages/general-setting/permission-management/role/index';
import AdminGongRoleEdit from 'Component/pages/gong/role-manage/edit';
import generalSettingRoleView from 'Component/pages/gong/role-manage/list';
import RoleDetailTaskPermissions from 'Component/pages/role/detail/task-permissions';
import RoleDetailDataPermissions from 'Component/pages/role/detail/data-permissions';
// 数据对接
import Docking from '../../pages/general-setting/docking/index';
// 账户管理
import GeneralSettingAccountManagement from 'Component/pages/account-management';
import GeneralSettingSecuritySettings from 'Component/pages/security-settings';

//404
import PageNotFound from '../../pages/404';

const generalSettingRouteComponentByRouteNameMappings = {
    // 主界面
    generalSettingHome: {
        component: Home,
    },
    generalSettingPermissionManagement: {
        component: GeneralSettingPermissionManagement,
    },
    generalSettingEmployeeManagement: {
        component: AdminGongUser,
    },
    generalSettingRoleManagement: {
        component: AdminGongRole,
    },
    generalSettingUserList: {
        component: AdminGongUserList,
    },
    generalSettingUserEdit: {
        component: AdminGongUserEdit,
    },
    generalSettingUserDetail: {
        component: AdminGongUserDetail,
    },
    generalSettingRoleList: {
        component: AdminGongRoleList,
    },
    generalSettingRoleView: {
        component: generalSettingRoleView,
    },
    generalSettingRoleEdit: {
        component: AdminGongRoleEdit,
    },
    generalSettingRoleDetail: {
        component: GeneralSettingRoleDetail,
    },
    generalSettingRoleEditEntry: {
        component: GeneralSettingRoleDetail,
    },
    generalSettingRoleViewTaskPermissions: {
        component: RoleDetailTaskPermissions,
    },
    generalSettingRoleViewDataPermissions: {
        component: RoleDetailDataPermissions,
    },
    generalSettingRoleEditTaskPermissions: {
        component: RoleDetailTaskPermissions,
    },
    generalSettingRoleEditDataPermissions: {
        component: RoleDetailDataPermissions,
    },
    generalSettingDataDocking: {
        component: Docking,
    },
    generalSettingAccountManagement: {
        component: GeneralSettingAccountManagement,
    },
    generalSettingSecuritySettings: {
        component: GeneralSettingSecuritySettings,
    },
    generalSettingPageNotFound: {
        component: PageNotFound,
    },
};

export { generalSettingRouteComponentByRouteNameMappings };
