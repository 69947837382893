import React, { FC } from 'react';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

type TemplateProps = {};

const Template: FC<TemplateProps> = (props) => {
    return (
        <Wrapper>
            <div style={{ marginBottom: 8 }}>
                {/* 暂无数据 */}
                {
                    UdeskLocales['current'].components.businessSummary.components.empty.index
                        .thereIsCurrentlyNoDataAvailable
                }
            </div>
            {props.children}
        </Wrapper>
    );
};

export default Template;
