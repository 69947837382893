import Udesk from 'Udesk';
import React from 'react';
import AdminUserDetailComponent from './component';
import AutoComplete from 'udesk-react/src/components/auto-complete';
import ReactModal from 'udesk-react/src/components/react-modal';
import './style.scss';
import { Input, PageHeader } from 'udesk-ui';

export default class AdminUserDetailTemplate extends AdminUserDetailComponent {
    render() {
        let { actions, locales, privates } = this;
        const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;
        return (
            <React.Fragment>
                {/* <div className="sub-page admin-user-detail"> */}
                <div className='udesk-qa-web-page'>
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        title={privates.computes.isEditing ? locales.columns.admin.user.editUser : locales.columns.admin.user.createUser}
                        onBack={actions.userHistoryBack}
                    />
                    <div className='udesk-qa-web-page-body'>
                        <div className='udesk-qa-web-page-body-root admin-user-detail' style={{ padding: 16 }}>
                            <div className="page-title clearfix">
                                <div className="title_left"></div>
                                <div className="title_right"></div>
                            </div>
                            <div className="page-content">
                                <div className="x_panel clearfix custom-object-list">
                                    <div className="x_content clearfix">
                                        <div className="col-lg-10 col-md-10 col-xs-12 col-12">
                                            <form className="form-horizontal form-label-left">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="user_email"
                                                        style={{ padding: "0 15px" }}
                                                        className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.account}
                                                        <span className="required">*</span>
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <Choose>
                                                            <When condition={!privates.computes.isEditing}>
                                                                <Input
                                                                    type="text"
                                                                    value={privates.model.user.email}
                                                                    // className="form-control"
                                                                    id="user_email"
                                                                    placeholder={locales.pages.admin.user.detail.accountPrompt}
                                                                    onChange={actions.onValueChanged.params(privates.model.user, 'email')}
                                                                />
                                                            </When>
                                                            <Otherwise>
                                                                <p className="account">
                                                                    <span>{privates.model.user.email}</span>
                                                                </p>
                                                                <If condition={hasFeature && hasFeature('sys:user:resetPwd')}>
                                                                    <button
                                                                        type="button"
                                                                        style={{ marginTop: '5px' }}
                                                                        className="btn btn-default btn-sm"
                                                                        onClick={actions.openResetPasswordModal}>
                                                                        {locales.pages.admin.user.detail.resetPassword}
                                                                    </button>
                                                                </If>
                                                            </Otherwise>
                                                        </Choose>
                                                    </div>
                                                </div>
                                                <If condition={!privates.computes.isEditing}>
                                                    <div className={'form-group' + (!privates.computes._originPasswordValidation.isValid && privates.enableValidation ? ' has-error' : '')}>
                                                        <label
                                                            htmlFor="user_origin_password"
                                                            style={{ padding: "0 15px" }}
                                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                            {locales.columns.admin.user.originPassword}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                            <Input
                                                                type="password"
                                                                id="user_origin_password"
                                                                // className="form-control"
                                                                value={privates.password.originPassword}
                                                                placeholder={locales.pages.admin.user.detail.passwordPrompt}
                                                                onChange={actions.onValueChanged.params(privates.password, 'originPassword')}
                                                            />
                                                        </div>
                                                        <div className="col-lg-3 col-md-2 col-xs-12 col-12 help-block">
                                                            <If condition={!privates.computes._originPasswordValidation.isValid && privates.enableValidation}>
                                                                <For each="error" index="index" of={privates.computes._originPasswordValidation.messages}>
                                                                    {error}
                                                                </For>
                                                            </If>
                                                        </div>
                                                    </div>
                                                    <div className={'form-group ' + (!privates.computes._resumePasswordValidation.isValid && privates.enableValidation ? ' has-error' : '')}>
                                                        <label
                                                            htmlFor="user_resume_password"
                                                            style={{ padding: "0 15px" }}
                                                            className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                            {locales.columns.admin.user.resumePassword}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                            <Input
                                                                type="password"
                                                                name="password"
                                                                id="user_resume_password"
                                                                // className="form-control"
                                                                value={privates.password.resumePassword}
                                                                placeholder={locales.pages.admin.user.detail.resumePasswordPrompt}
                                                                onChange={actions.onValueChanged.params(privates.password, 'resumePassword')}
                                                            />
                                                        </div>
                                                        <div className="col-lg-3 col-md-2 col-xs-12 col-12 help-block">
                                                            <If condition={!privates.computes._resumePasswordValidation.isValid && privates.enableValidation}>
                                                                <For each="error" index="index" of={privates.computes._resumePasswordValidation.messages}>
                                                                    {error}
                                                                </For>
                                                            </If>
                                                        </div>
                                                    </div>
                                                </If>
                                                <div className="form-group">
                                                    <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.headPortraitUrl}
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <div>
                                                            {/* 成功后，图片展示缩略图 */}
                                                            <If condition={privates.headPortraitUrl}>
                                                                <img src={privates.headPortraitUrl} alt="" width="52" className="pull-left thumbnail" />
                                                            </If>
                                                            <p className="pull-left">
                                                                {/* 上传触发按钮 */}
                                                                <label for="upload-avatar-user" class="action-btn upload-name">
                                                                    {locales.labels.upload}
                                                                </label>
                                                                <Input
                                                                    type="file"
                                                                    autoComplete="off"
                                                                    name="fileUpload"
                                                                    className="upload-file-input-user"
                                                                    onChange={actions.uploadAvatar}
                                                                    id="upload-avatar-user"
                                                                />
                                                                {/* 提示性文字 */}
                                                                <div>
                                                                    {locales.pages.admin.user.detail.suppertImageType}
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="user_realname"
                                                        style={{ padding: "0 15px" }}
                                                        className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.name}
                                                        <span className="required">*</span>
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <Input
                                                            type="text"
                                                            name="realname"
                                                            id="user_realname"
                                                            showCount={true}
                                                            // className="form-control"
                                                            value={privates.model.user.realname}
                                                            placeholder={locales.pages.admin.user.detail.namePrompt}
                                                            onChange={actions.onValueChanged.params(privates.model.user, 'realname')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="user_nickname"
                                                        style={{ padding: "0 15px" }}
                                                        className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.nickname}
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <Input
                                                            type="text"
                                                            name="nickname"
                                                            id="user_nickname"
                                                            showCount={true}
                                                            // className="form-control"
                                                            value={privates.model.user.nickname}
                                                            placeholder={locales.pages.admin.user.detail.nickNamePrompt}
                                                            onChange={actions.onValueChanged.params(privates.model.user, 'nickname')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="user_mobile"
                                                        style={{ padding: "0 15px" }}
                                                        className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.mobile}
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <Input
                                                            type="text"
                                                            id="user_mobile"
                                                            // className="form-control"
                                                            value={privates.model.user.mobile}
                                                            placeholder={locales.pages.admin.user.detail.telephonePrompt}
                                                            onChange={actions.onValueChanged.params(privates.model.user, 'mobile')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="user_agentId"
                                                        style={{ padding: "0 15px" }}
                                                        className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.agentId}
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <Input
                                                            type="text"
                                                            id="user_agentId"
                                                            // className="form-control"
                                                            value={privates.model.user.agentId}
                                                            placeholder={locales.pages.admin.user.detail.agentIdPrompt}
                                                            onChange={actions.onValueChanged.params(privates.model.user, 'agentId')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label style={{ padding: "0 15px" }} className="control-label col-lg-2 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.role}
                                                        <span className="required">*</span>
                                                    </label>
                                                    <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                        <AutoComplete
                                                            defaultOptions={privates.model.roles}
                                                            value={privates.model.user.roleList}
                                                            placeholder={locales.pages.admin.user.detail.roleSelectedPrompt}
                                                            onChanged={actions.onValueChanged.params(privates.model.user, "roleList")}
                                                            isFilterSelectedOption={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-3 col-lg-offset-2">
                                                        <button type="button" style={{ marginRight: '5px' }} className="btn primary btn-md" onClick={actions.save}>
                                                            {locales.labels.save}
                                                        </button>
                                                        <button type="button" className="btn white btn-md" onClick={actions.userHistoryBack}>
                                                            {locales.labels.cancel}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <If condition={privates.computes.isEditing}>
                                <ReactModal cancelText={locales.labels.cancel} okText={locales.labels.save}
                                    headerClassName="udesk-qa-modal-default-header"
                                    title={locales.pages.admin.user.detail.resetPassword}
                                    visible={privates.resetPasswordModalVisible}
                                    onCancel={actions.hidePasswordModal}
                                    footer={false}
                                >
                                    <div className="bs-modal-base">
                                        <div className="content-box">
                                            <form className="form-horizontal form-label-right">
                                                <If condition={Udesk.data.init.user.id === privates.model.user.id}>
                                                    <div className={'form-group' + (!privates.computes._passwordValidation.isValid && privates.resetPwEnableValidation ? ' has-error' : '')}>
                                                        <label
                                                            htmlFor="user_origin_password"
                                                            className="control-label col-lg-3 col-md-3 col-xs-12 col-12">
                                                            {locales.columns.admin.user.originPassword}
                                                            <span className="required">*</span>
                                                        </label>
                                                        <div className="col-lg-6 col-md-6 col-xs-12 col-12">
                                                            <Input
                                                                type="password"
                                                                id="user_origin_password"
                                                                // className="form-control"
                                                                value={privates.password.password}
                                                                placeholder={locales.pages.admin.user.detail.originPasswordPrompt}
                                                                onChange={actions.onValueChanged.params(privates.password, 'password')}
                                                            />
                                                        </div>
                                                        <div className="col-lg-3 col-md-3 col-xs-12 col-12 help-block">
                                                            <If condition={!privates.computes._passwordValidation.isValid && privates.resetPwEnableValidation}>
                                                                <For each="error" index="index" of={privates.computes._passwordValidation.messages}>
                                                                    {error}
                                                                </For>
                                                            </If>
                                                        </div>
                                                    </div>
                                                </If>
                                                <div className={'form-group' + (!privates.computes._newPasswordValidation.isValid && privates.resetPwEnableValidation ? ' has-error' : '')}>
                                                    <label
                                                        htmlFor="user_new_password"
                                                        className="control-label col-lg-3 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.newPassword}
                                                        <span className="required">*</span>
                                                    </label>
                                                    <div className="col-lg-6 col-md-6 col-xs-12 col-12">
                                                        <Input
                                                            type="password"
                                                            id="user_new_password"
                                                            // className="form-control"
                                                            value={privates.password.newPassword}
                                                            placeholder={locales.pages.admin.user.detail.passwordPrompt}
                                                            onChange={actions.onValueChanged.params(privates.password, 'newPassword')}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-xs-12 col-12 help-block">
                                                        <If condition={!privates.computes._newPasswordValidation.isValid && privates.resetPwEnableValidation}>
                                                            <For each="error" index="index" of={privates.computes._newPasswordValidation.messages}>
                                                                {error}
                                                            </For>
                                                        </If>
                                                    </div>
                                                </div>
                                                <div className={'form-group' + (!privates.computes._resumePasswordValidation.isValid && privates.resetPwEnableValidation ? ' has-error' : '')}>
                                                    <label
                                                        htmlFor="user_resume_password"
                                                        className="control-label col-lg-3 col-md-3 col-xs-12 col-12">
                                                        {locales.columns.admin.user.resumePassword}
                                                        <span className="required">*</span>
                                                    </label>
                                                    <div className="col-lg-6 col-md-6 col-xs-12 col-12">
                                                        <Input
                                                            type="password"
                                                            name="password"
                                                            id="user_resume_password"
                                                            // className="form-control"
                                                            value={privates.password.resumePassword}
                                                            placeholder={locales.pages.admin.user.detail.resumePasswordPrompt}
                                                            onChange={actions.onValueChanged.params(privates.password, 'resumePassword')}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-xs-12 col-12 help-block">
                                                        <If condition={!privates.computes._resumePasswordValidation.isValid && privates.resetPwEnableValidation}>
                                                            <For each="error" index="index" of={privates.computes._resumePasswordValidation.messages}>
                                                                {error}
                                                            </For>
                                                        </If>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="modal-btn-container">
                                        <button className="btn primary pull-right" onClick={actions.resetPassword}>
                                            {locales.labels.save}
                                        </button>
                                    </div>
                                </ReactModal>
                            </If>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
