// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    BaseResponseListSupervisionItemReportConfigFoundResponse,
    PostSupervisionItemReportConfigsBody,
    BaseResponseSupervisionItemReportConfigFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 监督项报表配置
 * @link [GET] /supervisionItemReportConfigs
 */
export function getSupervisionItemReportConfigs(): Promise<BaseResponseListSupervisionItemReportConfigFoundResponse>;
export function getSupervisionItemReportConfigs(
    options: ApiOptions<never, never>
): Promise<BaseResponseListSupervisionItemReportConfigFoundResponse>;
export function getSupervisionItemReportConfigs(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListSupervisionItemReportConfigFoundResponse> {
    return request<never, never, never>('/supervisionItemReportConfigs', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSupervisionItemReportConfigs',
    });
}

/**
 * save
 *
 * @export
 * @tags 监督项报表配置
 * @link [POST] /supervisionItemReportConfigs
 */
export function postSupervisionItemReportConfigs(
    data: PostSupervisionItemReportConfigsBody
): Promise<BaseResponseSupervisionItemReportConfigFoundResponse>;
export function postSupervisionItemReportConfigs(
    data: PostSupervisionItemReportConfigsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseSupervisionItemReportConfigFoundResponse>;
export function postSupervisionItemReportConfigs(
    data: PostSupervisionItemReportConfigsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseSupervisionItemReportConfigFoundResponse> {
    return request<PostSupervisionItemReportConfigsBody, never, never>(
        '/supervisionItemReportConfigs',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postSupervisionItemReportConfigs',
        }
    );
}

export const meta = [
    { tags: ['监督项报表配置'], path: '/supervisionItemReportConfigs', method: 'get' },
    { tags: ['监督项报表配置'], path: '/supervisionItemReportConfigs', method: 'post' },
];
