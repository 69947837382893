import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Page from 'Component/common/udesk-qa-page-layout';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import UdeskLocales from 'UdeskLocales';
import { Tabs } from 'udesk-ui';
import { linkTo } from 'src/util/link';
import { getCurrentRouteName, isSalesSubApp } from 'Udesk/system/subApp';
import './index.scss';

const { TabPane } = Tabs;

type PropsType = {
    history: any;
    routes: any;
}

export default React.memo((props: PropsType) => {
    const locales = UdeskLocales['current'];
    const {history, routes} = props;
    const [activeKey, setActiveKey] = useState('');

    const isLearningCenter = isSalesSubApp(); // 学习中心的话术库只有只读功能
    const title = useMemo(() => isLearningCenter ? /* 话术库 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.index.scriptLibrary : /* 话术挖掘 */UdeskLocales['current'].pages.semanticIntelligence.wordsMining.index.scriptMining, [isLearningCenter]);

    const ADD_TYPES = useMemo(() => [
        {
            pathName: getCurrentRouteName(isLearningCenter ? 'dissentFavoriteWordsMining' : 'dissentWordsMining'),
            key: 'dissent',
            text: /* 异议处理话术 */locales.pages.semanticIntelligence.wordsMining.index.objectionHandlingScripts,
        },
        {
            pathName: getCurrentRouteName(isLearningCenter ? 'sellingPointFavoriteWordsMining' : 'sellingPointWordsMining'),
            key: 'selling-point',
            text: /* 产品卖点话术 */locales.pages.semanticIntelligence.wordsMining.index.productSellingPointScripts,
        },
    ], [
        isLearningCenter, 
        locales
    ]);

    const onAddTypeChange = useCallback((key) => {
        const tab = ADD_TYPES.find(item => item.key === key);
        const route = routes.find(route => route.path === history.location.pathname);
        if (tab) {
            if (route) {
                if (activeKey === key) {
                    if (route.name !== tab.pathName) {
                        linkTo(history, tab.pathName);
                    }
                } else {
                    linkTo(history, tab.pathName);
                }   
            } else {
                linkTo(history, tab.pathName);
            }
        }
    }, [ADD_TYPES, activeKey, history, routes]);

    useEffect(() => {
        const currentPath = ADD_TYPES.find(
            (item) => history.location.pathname.indexOf(item.key) >= 0
        );
        if (currentPath) {
            setActiveKey(currentPath.key);
        } else {
            onAddTypeChange('dissent');
        }
    }, 
    [
        ADD_TYPES, 
        onAddTypeChange, 
        history.location
    ]);

    return (
        <Page
            pageClassName='words-mining-page-index'
            pageBodyClassName='words-mining-page-body'
            bodyStyle={{ height: '100%' }}
            title={title}
            isRouteEntry={true}
            footer={
                <Tabs className='words-mining-page-index-tabs' activeKey={activeKey} onChange={onAddTypeChange}>
                    {
                        routes
                            .map((route) => {
                                const tab = ADD_TYPES.find((item) => {
                                    return item.pathName === route.name;
                                });
                                if (tab) {
                                    return <TabPane tab={tab.text} key={tab.key}></TabPane>;
                                }
                                return null;
                            })
                    }
                </Tabs>
            }
        >
            <SubRoutes
                route={
                    routes.find(route => {
                        const { pathname } = history.location;
                        const { path } = route;
                        const list = path.split('/').map(item => {
                            if (item.indexOf(':') === 0) {
                                return '[0-9]+';
                            }
                            return item;
                        });

                        return new RegExp(`^${list.join('/')}$`).test(pathname);
                    })
                }
            />
        </Page>
    );
});
