import Udesk from 'Udesk';
import GradeComponent from './component';
import _isEmpty from 'lodash-es/isEmpty';
// import _filter from 'lodash-es/filter';
import _extend from 'lodash-es/extend';
import _toString from 'lodash-es/toString';
import UdeskLocales from 'UdeskLocales';

class GradeRoute extends GradeComponent {
    model() {
        let { sdkOptions, match } = this.props;
        let url1 = Udesk.business.apiPath.concatApiPath(`inspectionGradeRules/${match.params.taskId}`, sdkOptions);
        let data = new Promise((resolve, reject) => {
            Udesk.ajax.get(url1).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });

        // let url2 = Udesk.business.apiPath.concatApiPath(`field/task/${match.params.taskId}`, sdkOptions);
        // let inspectionDataPromise = new Promise((resolve, reject) => {
        //     Udesk.ajax.get(url2).then(
        //         resp => {
        //             resolve(resp);
        //         },
        //         reason => {
        //             Udesk.ui.notify.error(reason.errorMsg);
        //             reject(reason);
        //         }
        //     );
        // });
        let url3 = Udesk.business.apiPath.concatApiPath(`caseLibraryCategorys`, sdkOptions);
        let caseBase = new Promise((resolve, reject) => {
            Udesk.ajax.get(url3, { taskId: match.params.taskId, pageNum: 1, pageSize: 51 }).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        this.privates.fieldList = [];
        this.privates.customDataSourceList = [];
        let type = Udesk.enums.inspectDataSources.voiceCall.id;
        this.privates.type = type;
        if (sdkOptions.props.task && (sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.voiceCall.id || sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeVoiceCall.id)) {
            type = Udesk.enums.inspectDataSources.voiceCall.id;
            this.privates.type = type;
        } else if (sdkOptions.props.task && (sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.textDialogue.id || sdkOptions.props.task.inspectDataSource === Udesk.enums.inspectDataSources.realTimeTextDialogue.id)) {
            type = Udesk.enums.inspectDataSources.textDialogue.id;
            this.privates.type = type;
        }
        let fieldUrl = Udesk.business.apiPath.concatApiPath(`field/data/search-template`, sdkOptions);
        let fieldData = new Promise((resolve, reject) => {
            Udesk.ajax.post(fieldUrl, { sourceList: [type] }).then(
                resp => {
                    resolve(resp);
                    this.actions.update();
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        this.locales.components.pages.components.listManage
                            .getRecordsFailed
                    );
                    reject(reason);
                }
            );
        });
        let customUrl = Udesk.business.apiPath.concatApiPath(`search-template/advanced`, sdkOptions);
        let customData = new Promise((resolve, reject) => {
            Udesk.ajax.post(customUrl, { type }).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        this.locales.components.pages.components.listManage
                            .getRecordsFailed
                    );
                    reject(reason);
                }
            );
        });
        let teskUrl = Udesk.business.apiPath.concatApiPath(`field/data/task/${match.params.taskId}`, sdkOptions);
        let taskData = new Promise((resolve, reject) => {
            Udesk.ajax.get(teskUrl).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                        this.locales.components.pages.components.listManage
                            .getRecordsFailed
                    );
                    reject(reason);
                }
            );
        });
        let processStatus = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionTasks/" + match.params.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url)
                .then(
                    resp => {
                        this.privates.processStatus = resp.data;
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                        reject(reason);
                    }
                );
        });
        return {
            data: data,
            caseBase: caseBase,
            fieldData,
            customData,
            taskData,
            status: processStatus,
        };
    }

    parseModel(asyncModel) {
        let { match } = this.props;
        let data = Array.isArray(asyncModel.data.data) && asyncModel.data.data.length ? asyncModel.data.data : [
            {
                autoInCaseLibrary: null,
                caseLibraryId: null,
                conditionJson: null,
                customJudgeLogic: null,
                defaultGrade: 1,
                gradeName: this.locales.labels.default,
                id: Udesk.utils.string.uuid(),
                judgeStrategy: null,
                orderIndex: 0,
                taskId: match.params.taskId,
                isFake: true,
            }
        ];
        !_isEmpty(data) &&
            data.map(item => {
                this.privates.judgeStrategy = _extend(this.privates.judgeStrategy, { [item.id]: item.judgeStrategy });
                if (!_isEmpty(JSON.parse(item.conditionJson))) {
                    this.privates.conditionJson = _extend(this.privates.conditionJson, {
                        [item.id]: JSON.parse(item.conditionJson)
                    });
                } else {
                    this.privates.conditionJson = _extend(this.privates.conditionJson, {
                        [item.id]: JSON.parse(item.conditionJson) || []
                    });

                }

                this.privates.customJudgeLogic = _extend(this.privates.customJudgeLogic, {
                    [item.id]: item.customJudgeLogic
                });
                return _extend(item, { keyIds: _toString(item.id) });
            });
        this.privates.panelList = data;
        this.privates.panelList.forEach(item => {
            if (!item.caseLibraryId) {
                item.caseLibraryId = asyncModel.caseBase.data[0].id;
            }
        });
        this.privates.selectOptionList = asyncModel.caseBase.data;
        this.actions.update();
        return asyncModel;
    }
}

export default GradeRoute;
