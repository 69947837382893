// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { BaseResponseIntelligentPartnerScoringSessionDetectionFoundResponse } from '../../../../types';

/**
 * 会话检测-查询检测配置
 *
 * @export
 * @tags 智能陪练-评分模型
 * @link [GET] /intelligentPartner/scoring/session/detection
 */
export function getIntelligentPartnerScoringSessionDetection(): Promise<BaseResponseIntelligentPartnerScoringSessionDetectionFoundResponse>;
export function getIntelligentPartnerScoringSessionDetection(
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerScoringSessionDetectionFoundResponse>;
export function getIntelligentPartnerScoringSessionDetection(
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerScoringSessionDetectionFoundResponse> {
    return request<never, never, never>('/intelligentPartner/scoring/session/detection', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getIntelligentPartnerScoringSessionDetection',
    });
}

export const meta = [
    {
        tags: ['智能陪练-评分模型'],
        path: '/intelligentPartner/scoring/session/detection',
        method: 'get',
    },
];
