import Udesk from 'Udesk';
import ClassifiedTrainingHistoryIndexComponent from './component';

class ClassifiedTrainingHistoryIndexRoute extends ClassifiedTrainingHistoryIndexComponent {
    asyncModel(changedParams) {
        let {
            sdkOptions,
            // match
        } = this.props;

        let url = Udesk.business.apiPath.concatApiPath(`smartTagCategorys`, sdkOptions);
        let smartTagCategorysPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        let url2 = Udesk.business.apiPath.concatApiPath(`smartTagTrainRecordDetails/list`, sdkOptions);
        let paras = {
            "pageNum": this.privates.paging.pageNum,
            "pageSize": this.privates.paging.pageSize,
            "keyword": this.privates.idForSearch,
            "dataSource": this.privates.seletedDataSource,
            "userId": this.privates.seletedUserId && this.privates.seletedUserId.map(item => item.id),
            "categoryId": this.privates.seletedCategoryId,
            "startTime": this.privates.startTime,
            "endTime": this.privates.endTime
        };
        Udesk.ajax.post(url2, paras).then(
            (resp) => {
                this.privates.data = convertKey(
                    resp.data,
                    {
                        'id':'key',
                    }
                );
                if(resp.paging){
                    this.privates.paging = resp.paging;
                }
                this.actions.update();
            },
            reason => {
                Udesk.ui.notify.error(
                    reason.errorMsg
                );
            }
        );    


        return ({
            smartTagCategorys: smartTagCategorysPromise
        });
    }

    parseAsyncModel(asyncModel, { asyncKey }) {
        if (asyncKey === "smartTagCategorys") {
            if (asyncModel.smartTagCategorys) {
                if (asyncModel.smartTagCategorys.data) {
                    this.privates.smartTagCategorys = asyncModel.smartTagCategorys.data?.map(i => ({
                        ...i,
                        pId: i.parentId,
                        value: i.id,
                        title: i.categoryName,
                    })) ?? [];
                }
            }
        }
        return asyncModel;
    }
}

function evil (fn) {
    let Fn = Function;
    return new Fn('return ' + fn)();
}

function convertKey(arr,keyMap){
    let tempString = JSON.stringify(arr);
    for(var key in keyMap){
        var reg = `/"${key}":/g`;
        tempString = tempString.replace(evil(reg),'"'+keyMap[key]+'":');
        // tempString = tempString.replace(eval(reg),'"'+keyMap[key]+'":');
    }
    return JSON.parse(tempString);
}


export default ClassifiedTrainingHistoryIndexRoute;
