// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetKnowledgeCategorysByIdParam,
    BaseResponseKnowledgeCategoryFoundResponse,
    PutKnowledgeCategorysByIdParam,
    PutKnowledgeCategorysByIdBody,
    BaseResponseVoid,
    DeleteKnowledgeCategorysByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags knowledge-category-controller
 * @link [GET] /knowledgeCategorys/{id}
 * @param id
 */
export function getKnowledgeCategorysById(
    options: ApiOptions<GetKnowledgeCategorysByIdParam, never> & {
        segments: GetKnowledgeCategorysByIdParam;
    }
): Promise<BaseResponseKnowledgeCategoryFoundResponse> {
    return request<never, GetKnowledgeCategorysByIdParam, never>('/knowledgeCategorys/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getKnowledgeCategorysById',
    });
}

/**
 * update
 *
 * @export
 * @tags knowledge-category-controller
 * @link [PUT] /knowledgeCategorys/{id}
 * @param id
 */
export function putKnowledgeCategorysById(
    data: PutKnowledgeCategorysByIdBody,
    options: ApiOptions<PutKnowledgeCategorysByIdParam, never> & {
        segments: PutKnowledgeCategorysByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<PutKnowledgeCategorysByIdBody, PutKnowledgeCategorysByIdParam, never>(
        '/knowledgeCategorys/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putKnowledgeCategorysById' }
    );
}

/**
 * deleteById
 *
 * @export
 * @tags knowledge-category-controller
 * @link [DELETE] /knowledgeCategorys/{id}
 * @param id
 */
export function deleteKnowledgeCategorysById(
    options: ApiOptions<DeleteKnowledgeCategorysByIdParam, never> & {
        segments: DeleteKnowledgeCategorysByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteKnowledgeCategorysByIdParam, never>('/knowledgeCategorys/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteKnowledgeCategorysById',
    });
}

export const meta = [
    { tags: ['knowledge-category-controller'], path: '/knowledgeCategorys/{id}', method: 'get' },
    { tags: ['knowledge-category-controller'], path: '/knowledgeCategorys/{id}', method: 'put' },
    { tags: ['knowledge-category-controller'], path: '/knowledgeCategorys/{id}', method: 'delete' },
];
