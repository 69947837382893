import { Timeline, Empty } from "udesk-ui";
import React from 'react';
import Event from "./event";
import './index.less';
import 'braft-editor/dist/output.css';
import UdeskLocales from 'UdeskLocales';
type PropsType = {
    data: any[]
};

export const Content = React.memo((props: any) => {
    const {children} = props;
    if (children) {
        return <div 
            style={{
                display: 'inline-block'
            }} 
            className="braft-output-content" 
            dangerouslySetInnerHTML={{__html: children}} />;
    }
    return null;
});

export default React.memo<PropsType>((props) => {
    const {data} = props;
    return data.length > 0 ? (
        <Timeline>
            {
                data.map((item, index) => {
                    return (
                        <Timeline.Item color={index > 0 ? "gray" : "blue"}>
                            <Event date={item.timeStr}>
                                {
                                    item.isManagerCommunicate ? (
                                        <><span className={"admin-label"}>{/* 管理者建议： */}{UdeskLocales['current'].pages.gong.saleClientCenter.components.businessStage.modal.customerDynamics.theManagerSuggests}</span><Content>{item.content}</Content></>
                                    ) : (
                                        <Content>{item.content}</Content>
                                    )
                                }
                            </Event>
                        </Timeline.Item>
                    );
                })
            }
        </Timeline>
    ): (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    );
});
