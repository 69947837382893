// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetAiModelsGetUniqueRecordFieldByDataSourceTypeParam,
    BaseResponseAssociateFieldFoundResponse,
} from '../../../types';

/**
 * getUniqueRecordField
 *
 * @export
 * @tags ai-model-controller
 * @link [GET] /aiModels/getUniqueRecordField/{dataSourceType}
 * @param dataSourceType
 */
export function getAiModelsGetUniqueRecordFieldByDataSourceType(
    options: ApiOptions<GetAiModelsGetUniqueRecordFieldByDataSourceTypeParam, never> & {
        segments: GetAiModelsGetUniqueRecordFieldByDataSourceTypeParam;
    }
): Promise<BaseResponseAssociateFieldFoundResponse> {
    return request<never, GetAiModelsGetUniqueRecordFieldByDataSourceTypeParam, never>(
        '/aiModels/getUniqueRecordField/{dataSourceType}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getAiModelsGetUniqueRecordFieldByDataSourceType',
        }
    );
}

export const meta = [
    {
        tags: ['ai-model-controller'],
        path: '/aiModels/getUniqueRecordField/{dataSourceType}',
        method: 'get',
    },
];
