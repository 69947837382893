import React, { useCallback, useState } from 'react';
import Udesk from 'Udesk';
import { postIntelligentPartnerTtsTest } from 'src/api/intelligentPartner/tts/test';
import { getReviewIntelligentPartnerDialogFlowConfig } from 'src/api/review/intelligentPartner/dialogFlow/config';
import { useLiveEffect } from 'src/util/hook';
import { Button, Form, Icon, Input, Modal, Select, Space, Card, message, Tooltip } from 'udesk-ui';
import { useAudioPlay } from 'src/pages/coach/learning-center/components/record/hook';
import { RobotTimbreConfig } from 'src/api/types';
import { DefaultConfig } from './RobotTimbreForm';
import { postReviewIntelligentPartnerNodeWordsPolish } from 'src/api/review/intelligentPartner/node/wordsPolish';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const { value, onChange, flowId, ...textareaProps } = props;
    const [robotTimbreConfig, setRobotTimbreConfig] = useState<RobotTimbreConfig>();
    const [audioRef, audioPlayHandle] = useAudioPlay();

    const play = useCallback(() => {
        if (value) {
            const config = robotTimbreConfig || DefaultConfig;
            postIntelligentPartnerTtsTest({
                ...config,
                text: value,
            }).then(
                (resp) => {
                    audioPlayHandle([resp.data]);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        }
    }, [audioPlayHandle, robotTimbreConfig, value]);

    useLiveEffect(
        (success) => {
            if (!flowId) return;
            getReviewIntelligentPartnerDialogFlowConfig({
                params: {
                    flowId,
                },
            }).then((resp) => {
                success(() => {
                    setRobotTimbreConfig(resp?.data?.robotTimbreConfig);
                });
            });
        },
        [flowId]
    );

    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const onShow = () => {
        setVisible(true);
    };

    const onCancel = () => {
        setVisible(false);
        setWords('');
        form.setFieldsValue({ wordsStyle: 1, role: undefined });
    };

    const [words, setWords] = useState('');
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        if (!value) {
            message.warning(/* 话术不能为空！ */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.theScriptCannotBeEmpty);
            return;
        }
        setLoading(true);
        postReviewIntelligentPartnerNodeWordsPolish({
            role: values.role,
            wordsStyle: values.wordsStyle,
            words: value,
        })
            .then((res) => {
                setWords(res.data || '');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onCopy = () => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', words);
        input.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            message.success(/* 复制成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.copySuccessful);
        }
        document.body.removeChild(input);
    };

    const onReplace = () => {
        onChange(words);
        onCancel();
    };

    return (
        <>
            <Space direction="vertical" style={{ display: 'flex' }}>
                <div style={{ position: 'relative' }}>
                    <Input.TextArea value={value} onChange={onChange} {...textareaProps} />
                    <Tooltip mouseEnterDelay={1} color={'blue'} placement="left" title={/* Ai重写 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.aiRewrite}>
                        <Button
                            onClick={onShow}
                            icon={<Icon type="zhinengtuijian" />}
                            type="link"
                            style={{ position: 'absolute', bottom: 0, right: 0 }}
                        />
                    </Tooltip>
                </div>

                <Button
                    style={{ float: 'right' }}
                    className="udesk-qa-audition-button"
                    onClick={play}
                    type="primary"
                    icon={<Icon type="SoundFilled" antdIcon={true} />}
                />
                <audio className="hidden" ref={audioRef} controls autoPlay={false} />
            </Space>

            <Modal
                visible={visible}
                title={/* Ai重写 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.aiRewrite}
                onCancel={onCancel}
                footer={
                    <Space>
                        <Button onClick={onCancel}>{/* 取消 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.cancel}</Button>
                        <Button type="primary" disabled={!words} onClick={onCopy}>{/* 复制 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.copy}</Button>
                        <Button type="primary" disabled={!words} onClick={onReplace}>{/* 替换原文 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.replaceTheOriginalText}</Button>
                    </Space>
                }
            >
                <Form form={form} onFinish={onFinish} initialValues={{ wordsStyle: 1 }}>
                    <Form.Item label={/* 当前风格 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.currentStyle} required>
                        <Space>
                            <Form.Item noStyle name="wordsStyle">
                                <Select
                                    style={{ width: 140 }}
                                    options={[
                                        {
                                            label: /* 严谨 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.rigorous,
                                            value: 1,
                                        },
                                        {
                                            label: /* 温和 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.mild,
                                            value: 2,
                                        },
                                        {
                                            label: /* 活泼 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.lively,
                                            value: 3,
                                        },
                                        {
                                            label: /* 简约 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.simplicity,
                                            value: 4,
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item noStyle name="role">
                                <Input placeholder={/* 请输入说话者角色 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.pleaseEnterTheSpeakerRole} />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}
                                    loading={loading}
                                >{/* 开始生成 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.auditionTextArea.startGenerating}</Button>
                            </Form.Item>
                        </Space>
                    </Form.Item>
                </Form>

                <Card bodyStyle={{ backgroundColor: '#eee' }}>
                    <div style={{ height: 160, overflowY: 'auto' }}>{words}</div>
                </Card>
            </Modal>
        </>
    );
});
