// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type { PostCallTestsBody, BaseResponseVoid } from '../types';

/**
 * 保存测试对话
 *
 * @export
 * @tags 质检规则测试
 * @link [POST] /callTests
 */
export function postCallTests(data: PostCallTestsBody): Promise<BaseResponseVoid>;
export function postCallTests(
    data: PostCallTestsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postCallTests(
    data: PostCallTestsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostCallTestsBody, never, never>('/callTests', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCallTests',
    });
}

export const meta = [{ tags: ['质检规则测试'], path: '/callTests', method: 'post' }];
