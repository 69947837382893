import React from 'react';
import './style.scss';
import SmartToolsIndexRoute from './route';
import SentenceSearch from './sentence-search';
import SimilarDiscover from './similar-discover';
import SmartTags from './smart-tags';
import { Tabs, Icon } from 'udesk-ui';

const { TabPane } = Tabs;

export default class SmartToolsIndexTemplate extends SmartToolsIndexRoute {
    render() {
        let { visible, tags, activeTag } = this.state;
        let { actions, locales, privates } = this;
        return (
            <div className='smart-tools-sider-bar-index-page'>
                <div
                    className='smart-tools-sider-bar-index-page-btn'
                    // onClick={actions.open}
                    onMouseUp={actions.onSortMouseUp}
                    onMouseDown={actions.onHelpMenuBarMouseDown}
                >
                    <span className='udesk-qa-react-web-iconfont smart-tools-sider-bar-index-page-btn-icon'>
                        &#xe78e;
                    </span>
                    {locales.components.pages.smartTools.btn}
                </div>
                <div
                    className={`smart-tools-sider-bar-index-page-drawer${visible ? ' open' : ''}`}
                    // style={{ display: visible ? 'block' : 'none' }}
                >
                    {/* <div className='smart-tools-sider-bar-index-page-drawer' style={{ display: visible ? 'block' : 'none' }}> */}
                    <div className='smart-tools-sider-bar-index-page-drawer-header'>
                        <div className={`smart-tools-sider-bar-index-page-drawer-header-cube${!privates.isFront ? ' flipme' : ''}`}>
                            <div
                                className={`smart-tools-sider-bar-index-page-drawer-header-cube-front`}
                            >
                                <div className='smart-tools-sider-bar-index-page-drawer-header-left'>
                                    <Tabs
                                        style={{ height: 39 }}
                                        activeKey={activeTag}
                                        onChange={actions.seleteTag}
                                        size='small'
                                        tabBarStyle={{ border: 'none' }}
                                    >
                                        {tags.map(tag => {
                                            return <TabPane tab={tag.name} key={tag.key}></TabPane>;
                                        })}
                                    </Tabs>
                                </div>
                                <div className='smart-tools-sider-bar-index-page-drawer-header-right' style={{display:visible?'block':'none'}}>
                                    <span
                                        style={{position: visible ? 'absolute' : 'unset'}}
                                        className='udesk-qa-react-web-iconfont smart-tools-sider-bar-index-page-drawer-header-right-cancel'
                                        onClick={actions.onClose}
                                    >
                                        &#xe644;
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`smart-tools-sider-bar-index-page-drawer-header-cube-bottom`}
                            >
                                <div className='smart-tools-sider-bar-index-page-drawer-header-left' onClick={actions.backToIndex} style={{cursor: 'pointer'}}>
                                    <div>
                                        <Icon type='ic-arrow-left-s-line'></Icon>
                                    </div>
                                    <div>
                                        {locales.components.pages.smartTools.smartTags.detailTitle}
                                    </div>
                                </div>
                                <div className='smart-tools-sider-bar-index-page-drawer-header-right'>
                                    <span
                                        style={{position: visible ? 'absolute' : 'unset'}}
                                        className='udesk-qa-react-web-iconfont smart-tools-sider-bar-index-page-drawer-header-right-cancel'
                                        onClick={actions.onClose}
                                    >
                                        &#xe644;
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 相似词发现 */}
                    <If condition={activeTag === '1'}>
                        <SimilarDiscover></SimilarDiscover>
                    </If>
                    {/* 按词索句 */}
                    <If condition={activeTag === '2'}>
                        <SentenceSearch learnMore={this.props.learnMore} ref={privates.sentenceSearchRef}></SentenceSearch>
                    </If>
                    {/* 语义标签 */}
                    <If condition={activeTag === '3'}>
                        <SmartTags
                            ref={privates.smartTagsRef}
                            changeTabsDown={actions.changeTabsDown}
                            changeTabsUp={actions.changeTabsUp}
                            smartTagsCallBack={privates.smartTagsCallBack}
                            smartTagsKeyword={privates.smartTagsKeyword}
                            // activeTagId={this.privates.activeTagId}
                            siderClose={actions.onClose}
                        ></SmartTags>
                    </If>
                </div>
            </div>
        );
    }
}
