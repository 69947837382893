import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import { useLiveEffect, useRequest } from 'src/util/hook';
import { IntelligentPartnerMistakeCollectionFoundResponse } from 'src/api/types';
import { isArray } from 'src/util/core';
import PPT from './ppt';
import Text from './text';
import Voice from './voice';
import './index.less';

const useActive = (wrongQuestionSet) => {
    const [index, setIndex] = useState<number>(-1);
    const [wrongQuestion, setWrongQuestion] = useState<IntelligentPartnerMistakeCollectionFoundResponse & {cue: boolean}>();
    
    const isLastQuestion = useMemo(() => (1 + index) === wrongQuestionSet?.length, [index, wrongQuestionSet?.length]);
    
    const validate = useCallback((index) => {
        if (wrongQuestionSet?.length <= index) {
            return wrongQuestionSet?.length - 1;
        }
        if (index < 0) {
            return 0;
        }
        return index;
    }, [wrongQuestionSet?.length]);

    const setWrongQuestionIndex = useCallback((index) => {
        index = validate(index);
        if (wrongQuestionSet) {
            const {messageList, ...data} = wrongQuestionSet[index];
            if (data) {
                setWrongQuestion(wrongQuestionSet[index] = data);
            }
            if (isArray(messageList)) {
                messageList.splice(0, messageList.length);
            }
        }
        setIndex(index);
    }, [validate, wrongQuestionSet]);

    const nextWrongQuestion = useCallback(() => {
        setIndex(index => validate(index + 1));
    }, [validate]);

    const refreshWrongQuestion = useCallback(() => {
        setWrongQuestionIndex(index);
    }, [index, setWrongQuestionIndex]);

    useEffect(() => {
        if (index >= 0) {
            if (isArray(wrongQuestionSet)) {
                setWrongQuestion(wrongQuestionSet[validate(index)]);
            } else {
                setWrongQuestion(undefined);
            }
        }
    }, [index, validate, wrongQuestionSet]);

    return {
        isLastQuestion,
        wrongQuestion,
        nextWrongQuestion,
        refreshWrongQuestion,
        setWrongQuestionIndex,
    };
};

const Template = React.memo((props: any) => {
    const {history, location} = props;
    const selectedData = location.state?.selectedData;
    const [taskDetail, setTaskDetail] = useState<any>(null);
    const request = useRequest();
    const {
        isLastQuestion,
        wrongQuestion,
        nextWrongQuestion,
        refreshWrongQuestion,
        setWrongQuestionIndex,
    } = useActive(selectedData);
    const [isShowCue, setIsShowCue] = useState(false);
    const [isStartTask, setIsStartTask] = useState(false); // 是否开始任务
    const [loadingForTaskDetail, setLoadingForTaskDetail] = useState(false);

    // 获取任务详情信息
    useLiveEffect((success) => {
        if (wrongQuestion) {
            setLoadingForTaskDetail(true);
            request(`/intelligentPartner/${wrongQuestion?.taskId}/pageDetail`).then(
                resp => {
                    success(() => {
                        const data = resp.data;
                        if (data) {
                            data.id = wrongQuestion?.taskId;
                            setTaskDetail(data);
                        }
                    });
                }
            ).finally(() => {
                setLoadingForTaskDetail(false);
            });
        }
    }, [request, wrongQuestion?.taskId]);

    const Component = useMemo(() => {
        let dialogueMode = selectedData?.[0]?.dialogueMode;

        if (wrongQuestion) {
            dialogueMode = wrongQuestion.dialogueMode;
        }

        if (dialogueMode === Udesk.enums.learningTaskDialogueModeType.voice.id) {
            return Voice;
        }
        if (dialogueMode === Udesk.enums.learningTaskDialogueModeType.text.id) {
            return Text;
        }
        if (dialogueMode === Udesk.enums.learningTaskDialogueModeType.ppt.id) {
            return PPT;
        }

        return React.Fragment;
    }, [
        selectedData, 
        wrongQuestion
    ]);

    useEffect(() => {
        if (selectedData?.length > 0) {
            selectedData.forEach(item => {
                item.exerciseTimes = 0;
            });
        }
    }, [selectedData]);

    useEffect(() => {
        if (wrongQuestion) {
            wrongQuestion.cue = isShowCue;
        }
    }, [wrongQuestion, isShowCue]);

    return (
        <Component 
            {
                ... {
                    isShowCue, setIsShowCue,
                    isStartTask, setIsStartTask,
                    isLastQuestion,
                    wrongQuestion,
                    nextWrongQuestion,
                    refreshWrongQuestion,
                    setWrongQuestionIndex,
                    wrongQuestionSet: selectedData,
                    taskDetail,
                    loadingForTaskDetail,
                    history,
                }
            }
        />
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
