import React from 'react';
import Udesk from 'Udesk';
import _find from 'lodash-es/find';
import _isEmpty from 'lodash-es/isEmpty';
import UdeskLocales from 'UdeskLocales';
import { getReviewCallAnalysisOrganizations } from 'src/api/review/call/analysis/organizations';
import { getCurrentRouteName } from 'Udesk/system/subApp';

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    //删除并记录 删除元素
    const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, removed);
    return result;
};
// 插入排序
const insert = (list, insertObj, endIndex) => {
    const result = Array.from(list);
    //删除并记录 删除元素
    // const [removed] = result.splice(startIndex, 1);
    //将原来的元素添加进数组
    result.splice(endIndex, 0, insertObj);
    return result;
};
class IntelligentTagsDetailComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};
    //#endregion

    state = {};
    privates = {
        isEdit: false,
        id: null,
        activeFlags: [],
        tagName: '',
        tagDesc: '',
        tagStatus: Udesk.enums.activeFlags.effiective.id,
        tagType: Udesk.enums.labelTypes.dimensionLabel.id,
        isEnableModel: 0,
        smartTagCategorys: [],
        categoryId: this.props.location?.state?.categoryId,
        isClassificationVisible: false,
        operatorList: [],
        compositeType: Udesk.enums.operatorRuleTypes.all.id,
        operatorLogic: '',
        grounpValueList: [],
        grounpValue: null,
        treeDataList: {},
        labelActive: null,
        labelLevel: null,
        addLevel: null,
        labelParent: {},
        hitData: {},
        treeDataRef: {},
        labelRef: React.createRef(),
        conditionList: [
            {
                conditionType: 1, //1话术2交互3特征4信息
                taskConditionId: null,
                orderId: 1,
                operatorType: 11,
                idCode: 'R1',
                operatorList: [
                    {
                        applyConditionHitType: 1,
                        applyOperatorScope: {
                            key: 'all',
                            id: 1,
                        },
                        type: 11,
                        operator: 'all',
                        applyRole: 'all',
                    },
                ],
                applyConditionId: null,
                applyConditionIdCode: null,
                operatorLogic: '1',
                operator: 'all',
                applyRole: 'all',
                applyScope: null,
                similarityScore: null,
                differWordNumber: null,
                wordPerMinite: null,
                maxWordPerMinite: null,
                grabIntervalTime: null,
                grabMaxSpeakTime: null,
                intervalSecond: null,
                keyword: '',
                semanticSentence: null,
                applyConditionScope: null,
                applyConditionHitType: 1,
                // applyOperatorScope: {
                //     key: 'all',
                //     id: 1
                // },
                param: {},
            },
        ],
        logicList: [
            {
                id: 'logicList-1',
                content: UdeskLocales.current.components.common.customFilter.and,
                value: 'and',
                flag: true,
            },
            {
                id: 'logicList-2',
                content: UdeskLocales.current.components.common.customFilter.or,
                value: 'or',
                flag: true,
            },
            {
                id: 'logicList-3',
                content: UdeskLocales.current.components.common.customFilter.not,
                value: 'not',
                flag: true,
            },
            {
                id: 'logicList-4',
                content: '(',
                value: '(',
                flag: true,
            },
            {
                id: 'logicList-5',
                content: ')',
                value: ')',
                flag: true,
            },
            {
                id: 'logicList-6',
                content: UdeskLocales.current.components.common.customFilter.and,
                value: '&&',
                flag: false,
            },
            {
                id: 'logicList-7',
                content: UdeskLocales.current.components.common.customFilter.or,
                value: '||',
                flag: false,
            },
            {
                id: 'logicList-8',
                content: UdeskLocales.current.components.common.customFilter.not,
                value: '!',
                flag: false,
            },
        ],
        items: [],
        ruleList: [
            {
                formula: '',
                judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
                // score: '',
                // evaluation: ''
            },
        ],
        operatorMultipleLogicitems: [[]],
        operatorMultipleLogicitemsCount: [0],
        operatorMultipleLogic: [''],
        conditionListFilter: [],
        loading: false,
        funcType: '',
        smartRecommendStatus: 0,
        earlyWarning: 0,
        earlyWarningPersonList: [],
        warningTreeData: [],
    };

    actions = {
        // 人员数据处理
        transformArrayToTree(arr) {
            if (!arr || !arr.length) return [];
            const sortedArr = arr
                .map((i) => ({
                    ...i,
                    title: i.name,
                    key: i.id,
                    parentId: i.parentId * 1,
                }))
                .sort((a, b) => a.parentId - b.parentId);
            let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
            let nodeMap = {};
            sortedArr.forEach((item) => {
                nodeMap[item.id] = item;
                if (nodeMap[item.parentId]) {
                    if (Array.isArray(nodeMap[item.parentId].children)) {
                        nodeMap[item.parentId].children.push(item);
                    } else {
                        nodeMap[item.parentId].children = [item];
                    }
                }
            });
            return root;
        },
        // 人员数据
        getUserAllList() {
            getReviewCallAnalysisOrganizations().then((res) => {
                let userData = res.data?.users?.map((item) => {
                    return {
                        ...item,
                        id: item.id + '_userId', // 标识是用户id
                        name: item.realname,
                        parentId: item.organizationId,
                    };
                });
                let treeData = this.actions.transformArrayToTree([...userData, ...(res.data?.organizations ?? [])]);
                this.privates.warningTreeData = treeData;
                this.actions.update();
            });
        },
        onEarlyWarningChange(e) {
            let val = e.target.value;
            this.privates.earlyWarning = val;
            this.actions.update();
        },
        handlerEarlyWarningTreeChange(newValue) {
            this.privates.earlyWarningPersonList = newValue;
            this.actions.update();
        },
        tagStatusChanged(value) {
            this.privates.tagStatus = value;
            this.actions.update();
        },
        saveIntelligentTag() {
            let {
                locales,
                privates: {
                    id,
                    categoryId,
                    conditionList,
                    // compositeType,
                    operatorLogic,
                    tagType,
                    isEnableModel,
                    addLevel,
                    treeDataList,
                    labelParent,
                    ruleList,
                    tagName,
                    tagDesc,
                    tagStatus,
                    funcType,
                    smartRecommendStatus,
                    knowledgeBases,
                    libraryFiles,
                    earlyWarning,
                    earlyWarningPersonList,
                },
            } = this;
            let { sdkOptions } = this.props;
            // if (submitContent && (submitContent.tagName == null || submitContent.tagName.trim() === '')) {
            //     return Udesk.ui.notify.error(
            //         Udesk.utils.string.format(
            //             locales.business.notifyMessage.canNotBeEmptyFormat,
            //             locales.components.pages.intelligentTags.detail.tagName
            //         )
            //     );
            // }
            if (tagName == null || tagName.trim() === '') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.components.pages.intelligentTags.detail.tagName
                    )
                );
            }
            ruleList.forEach((item) => {
                if (item.judgeStrategy === 3) {
                    item.formula = operatorLogic;
                }
            });
            let relateConditionList =
                conditionList.map((item) => {
                    return {
                        idCode: item.idCode,
                        // highlight: 0
                    };
                }) || [];
            let flag = true;
            conditionList.forEach((item) => {
                if (item.operatorType === Udesk.enums.operatorTypes.element.id) {
                    if (
                        !item.operatorList[0].param.mulElementId ||
                        !(item.operatorList[0].param.hit === 0 || item.operatorList[0].param.hit === 1)
                    ) {
                        flag = false;
                    }
                }
                if (item.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id) {
                    if (
                        !item.operatorList[0].param.interactiveRule ||
                        !item.operatorList[0].param.interactiveCategoryId ||
                        !item.operatorList[0].param.interactiveCategoryName
                    ) {
                        flag = false;
                    }
                }
                if (item.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id) {
                    if (
                        !item.operatorList[0].param.operator ||
                        !(item.operatorList[0].param.expectedValueObject || item.operatorList[0].param.expectedValue) ||
                        !item.operatorList[0].param.entity
                    ) {
                        flag = false;
                    }
                }
            });
            if (!flag) {
                Udesk.ui.notify.error(
                    UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.logic.operatorEmpty
                );
                return;
            }
            this.privates.loading = true;
            this.actions.update();
            let params = {
                tagName,
                tagDesc,
                categoryId: categoryId,
                tagStatus,
                isEnableModel,
                // compositeType: compositeType,
                conditionList: conditionList,
                // operatorLogic: operatorLogic,
                tagType,
                tagLevel: addLevel,
                ruleList: ruleList,
                relateConditionList: relateConditionList,
                funcType: this.privates.funcType,
                smartRecommendStatus,
                earlyWarning,
            };
            if (smartRecommendStatus) {
                params.libraryFileIds = libraryFiles;
                params.knowledgeBaseIds = knowledgeBases;
            }
            if (earlyWarning) {
                let formatEarlyWarningPersonList = [];
                (earlyWarningPersonList || []).forEach(id => {
                    if (typeof id === 'string') {
                        // 人员id
                        let currentUserId = Number(id.split('_')[0]);
                        formatEarlyWarningPersonList.push(currentUserId);
                    } else {
                        // 当 organization 下面没有人员时，为 organization id，不处理
                    }
                });
                if (formatEarlyWarningPersonList.length) {
                    params.earlyWarningPersonList = formatEarlyWarningPersonList;
                } else {
                    return Udesk.ui.notify.error(locales.components.pages.intelligentTags.detail.warnError);
                }
            }
            let parentIds = null;
            if (treeDataList[addLevel] && treeDataList[addLevel].length > 0) {
                if (treeDataList[addLevel][0].parentId) {
                    parentIds = treeDataList[addLevel][0].parentId;
                }
            } else {
                if (addLevel > 1) {
                    parentIds = labelParent[addLevel - 1];
                }
            }
            params.parentId = parentIds;
            let url = Udesk.business.apiPath.concatApiPath(`smartTags`, sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            if (id) {
                url = Udesk.business.apiPath.concatApiPath(`smartTags/${id}`, sdkOptions);
                method = Udesk.enums.requestMethods.update.name;
                params.id = id;
            }

            Udesk.ajax(url, params, method).then(
                (resp) => {
                    this.privates.loading = false;
                    this.actions.update();
                    if (funcType === 'QUALITY_INSPECT') {
                        Udesk.ui.notify.success(
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveSuccessFormat,
                                locales.components.pages.intelligentTags.title
                            )
                        );
                    } else if (funcType === 'REVIEW_CALL') {
                        Udesk.ui.notify.success(
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveSuccessFormat,
                                locales.components.pages.intelligentTags.sessionLabelTitle
                            )
                        );
                    } else if (funcType === 'REVIEW_KEY_EVENT') {
                        Udesk.ui.notify.success(
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveSuccessFormat,
                                locales.components.pages.intelligentTags.keyEventsTitle
                            )
                        );
                    } else if (funcType === 'CUSTOMER_TAG') {
                        Udesk.ui.notify.success(
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveSuccessFormat,
                                locales.components.pages.intelligentTags.customerLabelTitle
                            )
                        );
                    }

                    this.actions.backToIndex();
                },
                (reason) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locales.components.pages.intelligentTags.title
                            )
                    );
                }
            );
        },
        onFormValuesChange(changedValues, allValues) {
            this.privates.tagName = allValues.tagName;
            this.privates.tagDesc = allValues.tagDesc;
            this.privates.tagStatus = allValues.tagStatus;
            // this.privates.status = allValues.status;
            // if (Object.keys(changedValues).includes('type')) {
            //     this.privates.manualDefaultType = Udesk.enums.defaultScore.empty.id;
            //     this.privates.manualDefaultValue = '';
            //     if (this.privates.type === 2) {
            //         this.privates.model.inspectionPoint.type = allValues.type;
            //         this.privates.model.inspectionPoint.gradeType = Udesk.enums.gradeTypes.yesOrNo.id;
            //     }
            //     if (this.privates.type === 1 || this.privates.type === 3) {
            //         this.privates.model.inspectionPoint.type = this.privates.type;
            //         this.privates.model.inspectionPoint.gradeType = Udesk.enums.gradeTypes.yesOrNo.id;
            //     }
            //     if (this.privates.type === 4) {
            //         if (!this.privates.heightLightList) {
            //             this.privates.heightLightList = [];
            //         }
            //     }
            // }
            this.actions.update();
        },
        saveIntelligentTagTo() {
            let {
                locales,
                privates: {
                    id,
                    categoryId,
                    operatorList,
                    compositeType,
                    operatorLogic,
                    tagType,
                    addLevel,
                    treeDataList,
                    labelParent,
                    tagName,
                    tagDesc,
                    tagStatus,
                },
            } = this;
            let { sdkOptions } = this.props;

            // if (submitContent && (submitContent.tagName == null || submitContent.tagName.trim() === '')) {
            //     return Udesk.ui.notify.error(
            //         Udesk.utils.string.format(
            //             locales.business.notifyMessage.canNotBeEmptyFormat,
            //             locales.components.pages.intelligentTags.detail.tagName
            //         )
            //     );
            // }
            if (tagName == null || tagName.trim() === '') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        locales.business.notifyMessage.canNotBeEmptyFormat,
                        locales.components.pages.intelligentTags.detail.tagName
                    )
                );
            }
            let params = {
                tagName,
                tagDesc,
                categoryId: categoryId,
                tagStatus,
                compositeType: compositeType,
                operatorList: operatorList,
                operatorLogic: operatorLogic,
                tagType,
                tagLevel: addLevel,
                funcType: this.privates.funcType,
            };
            let parentIds = null;
            if (treeDataList[addLevel] && treeDataList[addLevel].length > 0) {
                if (treeDataList[addLevel][0].parentId) {
                    parentIds = treeDataList[addLevel][0].parentId;
                }
            } else {
                if (addLevel > 1) {
                    parentIds = labelParent[addLevel - 1];
                }
            }
            this.privates.loading = true;
            this.actions.update();
            params.parentId = parentIds;
            let url = Udesk.business.apiPath.concatApiPath(`smartTags`, sdkOptions);
            let method = Udesk.enums.requestMethods.create.name;
            if (id) {
                url = Udesk.business.apiPath.concatApiPath(`smartTags/${id}`, sdkOptions);
                method = Udesk.enums.requestMethods.update.name;
                params.id = id;
            }

            Udesk.ajax(url, params, method).then(
                (resp) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.saveSuccessFormat,
                            locales.components.pages.intelligentTags.title
                        )
                    );
                    this.actions.backToClass();
                },
                (reason) => {
                    this.privates.loading = false;
                    this.actions.update();
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.saveFailedFormat,
                                locales.components.pages.intelligentTags.title
                            )
                    );
                }
            );
        },
        backToClass() {
            window.sessionStorage.setItem('categoryId', this.privates.categoryId);
            const routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('classifiedTraining'),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        backToIndex() {
            const routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('intelligentTagsIndex'),
            };
            if (this.privates.funcType === 'QUALITY_INSPECT') {
                routeOptions.routeName = getCurrentRouteName('intelligentTagsIndex');
            } else if (this.privates.funcType === 'REVIEW_CALL') {
                routeOptions.routeName = 'sessionLabelTagsIndex';
            } else if (this.privates.funcType === 'REVIEW_KEY_EVENT') {
                routeOptions.routeName = 'keyEventsTagsIndex';
            } else if (this.privates.funcType === 'CUSTOMER_TAG') {
                routeOptions.routeName = 'customerLabelTagsIndex';
            }
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        changeTagClassification(value) {
            this.privates.categoryId = value;
            if (this.privates.tagType === Udesk.enums.labelTypes.classificationLabel.id) {
                this.actions.getTagTreeInit(value, 1);
            }
            this.actions.update();
        },
        changeClassificationModalVisible() {
            let { isClassificationVisible } = this.privates;
            this.privates.isClassificationVisible = !isClassificationVisible;
            this.actions.update();
        },
        saveClassification(smartTagCategorys) {
            this.privates.smartTagCategorys = smartTagCategorys;
            if (this.privates.categoryId == null && smartTagCategorys.length > 0) {
                this.privates.categoryId = smartTagCategorys[0].id;
            }
            this.actions.changeClassificationModalVisible();
        },
        deleteClassification(item) {
            let { categoryId, smartTagCategorys } = this.privates;
            this.privates.smartTagCategorys = smartTagCategorys.filter((category) => {
                return category.id !== item.id;
            });
            if (categoryId != null && categoryId === item.id) {
                if (this.privates.smartTagCategorys.length > 0) {
                    this.privates.categoryId = this.privates.smartTagCategorys[0].id;
                } else {
                    this.privates.categoryId = undefined;
                }
            }
            this.actions.update();
        },
        changeTagName(value) {
            this.privates.tagName = value;
            this.actions.update();
        },
        changeTagDesc(value) {
            this.privates.tagDesc = value;
            this.actions.update();
        },
        // getOperatorList(operatorList) {
        //     this.privates.operatorList = operatorList;
        // },
        editSemanticTag(id) {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('semanticTagsEdit'),
                pathParams: {
                    id: id,
                },
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        createSemanticTag() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('semanticTagsNew'),
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        changeCompositeType(value) {
            this.privates.compositeType = value;
            this.actions.update();
        },
        labelChanged(value) {
            this.privates.tagType = value > 4 ? value - 4 : value;
            this.privates.isEnableModel = value > 4 ? 1 : 0;
            this.actions.getTagTreeInit(value, 1);
            this.actions.update();
        },
        labelFieldAdd(key) {
            if (this.privates.tagName === '') {
                return;
            }
            this.privates.addLevel = key;
            this.privates.idState = parseInt(this.props.match.params.id, 10);
            this.actions.update();
        },
        getTagTreeInit(categoryId, type, tagId) {
            let { sdkOptions } = this.props;
            let data = {
                categoryId,
                treeType: type,
            };
            if (tagId) {
                data.tagId = tagId;
            }
            let url = Udesk.business.apiPath.concatApiPath(`smartTags/tree`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(url, data).then(
                    (resp) => {
                        resolve(resp.data);
                        if (type === 1) {
                            if (resp.data && resp.data.length > 0) {
                                let level = resp.data[0].tagLevel;
                                if (!_isEmpty(this.privates.treeDataList)) {
                                    let obj = _find(this.privates.treeDataList, (item, key) => key === level);
                                    if (obj) {
                                        this.privates.treeDataList[level] = resp.data;
                                    } else {
                                        this.privates.treeDataList = {
                                            ...this.privates.treeDataList,
                                            [level]: resp.data,
                                        };
                                    }
                                    let data = {};
                                    for (let key in this.privates.treeDataList) {
                                        if (key <= level) {
                                            data[key] = this.privates.treeDataList[key];
                                        }
                                    }
                                    this.privates.treeDataList = data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                    }
                                } else {
                                    this.privates.treeDataList[level] = resp.data;
                                    for (let key in this.privates.treeDataList) {
                                        this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                    }
                                }
                                this.actions.update();
                            } else {
                                if (this.privates.labelLevel) {
                                    this.privates.treeDataList = {
                                        ...this.privates.treeDataList,
                                        [this.privates.labelLevel + 1]: [],
                                    };
                                }
                                let data = {};
                                for (let key in this.privates.treeDataList) {
                                    if (key <= this.privates.labelLevel + 1) {
                                        data[key] = this.privates.treeDataList[key];
                                    }
                                }
                                this.privates.treeDataList = data;
                                for (let key in this.privates.treeDataList) {
                                    this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                                }
                                this.actions.update();
                            }
                        } else if (type === 2) {
                            this.privates.treeDataList = resp.data;
                            for (let key in this.privates.treeDataList) {
                                this.privates.treeDataRef[`tree-${key}`] = React.createRef();
                            }
                            let level = this.privates.addLevel || 1;
                            this.actions.treeMap(tagId, level);
                            this.actions.hitMap(tagId, level);
                            this.actions.update();
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        treeMap(id, level) {
            let { treeDataList } = this.privates;
            for (let key in treeDataList) {
                if (key === level) {
                    if (treeDataList[key] && treeDataList[key].length > 0) {
                        let obj = treeDataList[key].find((item) => item.id === id);
                        if (obj && obj.parentId) {
                            treeDataList[key] = treeDataList[key].filter((item) => item.parentId === obj.parentId);
                            this.actions.treeMap(obj.parentId, level - 1);
                            break;
                        }
                    }
                }
            }
        },
        treeDrag(value, e) {
            var x1 = e.pageX || e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
            if (value.current) {
                let width = value.current.style.width;
                let wid = parseInt(width.split('px')[0], 10);
                this.privates.labelRef.current.onmousemove = (e) => {
                    var x2 = e.pageX || e.clientX + (document.body.scrollLeft || document.documentElement.scrollLeft);
                    if (x2 >= x1 && x2 - x1 <= 500) {
                        value.current.style.width = x2 - x1 + wid + 'px';
                    } else {
                        if (x2 - x1 < 0) {
                            value.current.style.width = wid - (x1 - x2) + 'px';
                        }
                    }
                };
            }
        },
        fieldUp() {
            this.privates.labelRef.current.onmousemove = (e) => {
                return;
            };
        },
        hitMap(id, level) {
            let { treeDataList } = this.privates;
            for (let key in treeDataList) {
                if (parseInt(key, 10) === level) {
                    if (treeDataList[key] && treeDataList[key].length > 0) {
                        treeDataList[key].forEach((item) => {
                            if (item.id === id) {
                                this.privates.hitData[item.tagLevel] = id;
                                if (item.parentId) {
                                    this.actions.hitMap(item.parentId, level - 1);
                                }
                            }
                        });
                    }
                }
            }
        },
        activesLabel(value, level, leveflag) {
            let { treeDataList } = this.privates;
            this.privates.labelActive = value;
            this.privates.labelLevel = level;
            this.privates.parentId = value;
            this.privates.labelParent[level] = value;
            for (let key in this.privates.labelParent) {
                if (key > level) {
                    this.privates.labelParent[key] = null;
                }
            }
            for (let key in this.privates.hitData) {
                if (key >= level) {
                    this.privates.hitData[key] = null;
                }
            }
            if (this.props.match.params.id) {
                if (leveflag !== 1) {
                    this.actions.getTagTreeInit(this.privates.categoryId, 1, value);
                } else {
                    for (let key in treeDataList) {
                        if (key > level) {
                            this.privates.treeDataList[key] = null;
                        }
                    }
                }
            } else {
                this.actions.getTagTreeInit(this.privates.categoryId, 1, value);
            }

            this.actions.update();
        },
        deleteLevel() {
            this.privates.addLevel = null;
            this.actions.update();
        },
        customJudgeLogicChange(e) {
            let reg = /[\u4e00-\u9fa5]/g;
            this.privates.operatorLogic = e.target.value.replace(reg, '');
            this.privates.items = [];
            this.actions.update();
        },
        resetConditions() {
            this.privates.ruleList = [
                {
                    formula: '',
                    // score: '',
                    // evaluation: ''
                },
            ];
            this.privates.items = [];
            this.privates.itemsCount = 0;
            this.privates.operatorMultipleLogicitems = [[]];
            this.privates.operatorLogic = '';
            this.privates.operatorMultipleLogicitemsCount = [0];
            this.privates.operatorMultipleLogic = [''];
        },
        getConditionList(gearOptionList) {
            this.privates.conditionList = gearOptionList;
            this.actions.getOperatorList(this.privates.conditionList);
        },
        onGradeTypeChange(e) {
            this.actions.resetConditions();
            this.privates.compositeType = e.target.value;
            this.privates.ruleList.forEach((item) => {
                item.judgeStrategy = e.target.value;
            });
            this.privates.conditionList && this.actions.getOperatorList(this.privates.conditionList);
            this.actions.update();
        },
        onDragStart(option) {
            if (option.draggableId.indexOf('drag') !== -1) {
                this.privates.tarskDel = true;
                this.actions.update();
            }
        },
        onDragEnd(result) {
            let { itemsCount } = this.privates;
            if (!result.destination) {
                if (result.draggableId.indexOf('drag') !== -1) {
                    let list = this.privates.items.filter((current) => current.id !== result.draggableId);
                    this.privates.items = list;
                    let str = '';
                    this.privates.items.forEach((item) => {
                        item && (str += item.value);
                    });
                    this.privates.operatorLogic = str;
                    this.privates.tarskDel = false;
                    this.actions.update();
                }
                return;
            }
            if (result.draggableId.indexOf('drag') !== -1) {
                const items = reorder(this.privates.items, result.source.index, result.destination.index);
                let str = '';
                items.forEach((item) => {
                    item && (str += item.value);
                });
                this.privates.operatorLogic = str;
                this.privates.items = items;
                this.privates.tarskDel = false;
                this.actions.update();
            } else {
                if (result.draggableId.indexOf('-') !== 1) {
                    let drag = result.draggableId.split('-')[0];
                    let obj = this.privates[drag].find((current) => current.id === result.draggableId);
                    if (obj) {
                        let data = {
                            id: `drag-${itemsCount + 1}`,
                            content: obj.content,
                            value: obj.value,
                            prevId: result.draggableId,
                            prevClass: drag,
                        };
                        const items = insert(this.privates.items, data, result.destination.index);
                        let str = '';
                        items.forEach((item) => {
                            item && (str += item.value);
                        });
                        this.privates.operatorLogic = str;
                        this.privates.itemsCount = itemsCount + 1;
                        this.privates.items = items;
                        this.actions.update();
                    }
                }
            }
        },
        getOperatorList(operatorList) {
            this.privates.operatorList = operatorList;
            this.privates.conditionListFilter = operatorList.map((item, index) => {
                return {
                    id: `conditionListFilter-${item.idCode}`,
                    content: item.idCode,
                    value: item.idCode,
                };
            });
            let list = this.privates.items.filter(
                (current) => current.prevId !== `conditionListFilter-${operatorList.length}`
            );
            this.privates.items = list.map((item, index) => {
                return {
                    ...item,
                    id: `drag-${index}`,
                };
            });
            this.actions.update();
        },
    };
    //#region Life Cycle
    componentDidMount() {
        this.actions.getUserAllList();

        this.privates.funcType = this.props.location?.state?.funcType;
        this.privates.isEdit = !!this.props.match.params.id;
        let activeFlags = Udesk.enums.activeFlags;
        activeFlags = activeFlags.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
        let labelTypes = Udesk.enums.labelTypes;
        labelTypes = labelTypes.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
        this.privates.labelTypes = labelTypes;
        this.privates.activeFlags = activeFlags;
        this.actions.update();
    }
    componentWillUnmount() {}
    //#endregion
}

export default IntelligentTagsDetailComponent;
