import interfaceClasses from '../interface-classes';
import PieLikeClass from './pie-like';
import common from '../../../../udesk/index';

class GaugeOptionsBuilderClass extends PieLikeClass {
    isSingleValue(optionsOwner) {
        return true;
    }

    getLegendOptions(optionsOwner) {
        return null;
    }

    _getChartType() {
        return common.enums.echartTypes.gauge.key;
    }
    getRadius(optionsOwner) {
        return "100%";
    }

    _decorateSeries(series, optionsOwner) {
        series = super._decorateSeries(...arguments);

        let {
            gaugeMaxValue: maxValue,
            showSeriesLabels,
            gaugeAxisPrecision
        } = optionsOwner.props;

        let newSeries = series.slice(0);

        if (series && series.length > 1) {
            newSeries = newSeries.slice(0, 1);

            let newDataValue = series.reduce((a, b) => {
                let c = 0;
                let d = 0;
                if (a && a.data && a.data.length > 0) {
                    c = a.data[0].value;
                }
                if (b && b.data && b.data.length > 0) {
                    d = b.data[0].value;
                }
                return {
                    name: a.data[0].name,
                    value: c + d
                };
            });
            newSeries[0].data = [newDataValue];
        }

        if (maxValue == null || maxValue === "") {
            let seriesValues = newSeries.map(s => {
                if (s.data != null && s.data.length > 0) {
                    let value = Number(s.data[0].value);
                    if (isNaN(value)) {
                        value = 0;
                    }
                    return value;
                } else {
                    return 0;
                }
            });
            let maxValueFromSeries = Math.max(...seriesValues);
            if (maxValueFromSeries == null || isNaN(maxValueFromSeries) || maxValueFromSeries <= 0) {
                maxValue = 0;
            } else {
                //计算默认的最大值
                //逻辑是：取series中最大值，然后在首位加一，再取整。
                maxValue = common.utils.math.roundUp(maxValueFromSeries);
            }
        } else {
            maxValue = common.utils.math.roundUp(maxValue);
        }

        for (let seriesItem of newSeries) {
            seriesItem.radius = this.getRadius(optionsOwner);
            seriesItem.detail = {
                show: showSeriesLabels,
                formatter: (value) => {
                    let {
                        seriesColumns,
                        records
                    } = optionsOwner.props;
                    let newParams = new interfaceClasses.FormatSeriesLabelParamsClass({
                        value: value,
                        record: records[0],
                        column: seriesColumns[0],
                        seriesColumns: seriesColumns,
                        originParams: value
                    });
                    return this._formatSeriesLabelCore(newParams, optionsOwner);
                }
            };

            seriesItem.axisLabel = {
                formatter: function (value) {
                    let valueNumber = parseFloat(value);
                    if (isNaN(valueNumber)) {
                        return value;
                    }
                    return value.toFixed(gaugeAxisPrecision);
                }
            };
            /**
             * [min description]仪表盘需设置最大最小值
             * 最大值需手动设置
             * @type {Number}
             */
            seriesItem.min = 0;
            seriesItem.max = maxValue;
        }
        return newSeries;
    }

    _formatSeriesLabelValue(newParams, optionsOwner) {
        let {
            seriesColumns,
            records,
            showExtraPercentInLabels,
        } = optionsOwner.props;

        if (seriesColumns == null || seriesColumns.length === 0 ||
            records == null || records.length === 0) {
            return newParams.value;
        }

        let fieldName = seriesColumns[0].field;
        let value;
        let valueSum = records.map(item => {
            value = Number(this._getFieldOriginalValue(item, fieldName));
            if (isNaN(value)) {
                value = 0;
            }
            return value;
        })
            .reduce((a, b) => a + b);
        let percentage = (value / valueSum * 100).toFixed(2);

        if (showExtraPercentInLabels) {
            return `${value} (${percentage}%)`;
        } else {
            return value;
        }
    }
}

export default GaugeOptionsBuilderClass;
