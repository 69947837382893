import OptionsBuilderClass from '../options-builder';
import common from '../../../../udesk/index';

const LIQUID_FILL_DEFAULT_OPTION = {
    color: [
        '#1e6980',
        '#32c5e9',
        '#67e0e3',
        '#9fe6b8',
        '#ffdb5c',
        '#ff9f7f',
        '#fb7293',
        '#e062ae',
        '#e690d1',
        '#e7bcf3',
        '#9d96f5',
        '#8378ea',
        '#96bfff',
    ],
    center: ['50%', '50%'],
    radius: '90%', // 半径
    amplitude: '15%', // 振幅
    waveLength: '25%', // 波长
    phase: 'auto', // 相位 
    period: 'auto', // 周期
    direction: 'right',
    // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow' 
    // svg path
    shape: 'rect',

    waveAnimation: true,
    animationEasing: 'linear',
    animationEasingUpdate: 'linear',
    animationDuration: 2000,
    animationDurationUpdate: 10,

    outline: {
        show: true,
        borderDistance: 8,
        itemStyle: {
            color: 'none',
            borderColor: {
                type: "linear",
                x: 0,
                y: 0,
                x1: 0,
                y1: 1,
                colorStops: [
                    { offset: 0, color: '#3ce6c9' },
                    { offset: 1, color: '#00BAFF' },
                ],
                global: false,
            },
            borderWidth: 3,
            shadowBlur: 20,
            shadowColor: 'rgba(0, 0, 0, 0.25)'
        }
    },

    backgroundStyle: {
        color: 'transparent'
    },

    itemStyle: {
        opacity: 0.95,
        shadowBlur: 50,
        shadowColor: 'rgba(0, 0, 0, 0.4)'
    },

    label: {
        show: true,
        color: '#294D99',
        insideColor: '#fff',
        fontSize: 50,
        fontWeight: 'bold',

        align: 'center',
        baseline: 'middle',
        position: 'inside',
        normal: {},
    },

    emphasis: {
        itemStyle: {
            opacity: 0.8
        }
    }
};

/**
 * 水球图
 */
class LiquidFillOptionsBuilderClass extends OptionsBuilderClass {
    _getChartType() {
        return common.enums.echartTypes.liquidFill.key;
    }

    getSeriesOptions(optionsOwner) {
        let series = super.getSeriesOptions(...arguments);

        let {
            records,
            seriesColumns,
        } = optionsOwner.props;

        for (let seriesItem of series) {
            // Default option for liquid fill chart
            Object.keys(LIQUID_FILL_DEFAULT_OPTION).forEach(key => {
                if (seriesItem[key] === undefined) {
                    seriesItem[key] = LIQUID_FILL_DEFAULT_OPTION[key];
                }
            });
            let field = seriesColumns[0].field;
            let labelValue = records[0][field].value;
            seriesItem.label.normal.formatter = () => labelValue;

        }

        return series;
    }

}

export default LiquidFillOptionsBuilderClass;