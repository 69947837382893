// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { BaseResponseMapintDataField } from '../../../types';

/**
 * getDataSplitConditionFields
 *
 * @export
 * @tags company-general-settings-controller
 * @link [GET] /companyGeneralSettingss/DATA_SPLIT/filedConditions
 */
export function getCompanyGeneralSettingssDATA_SPLITFiledConditions(): Promise<BaseResponseMapintDataField>;
export function getCompanyGeneralSettingssDATA_SPLITFiledConditions(
    options: ApiOptions<never, never>
): Promise<BaseResponseMapintDataField>;
export function getCompanyGeneralSettingssDATA_SPLITFiledConditions(
    options?: ApiOptions<never, never>
): Promise<BaseResponseMapintDataField> {
    return request<never, never, never>('/companyGeneralSettingss/DATA_SPLIT/filedConditions', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCompanyGeneralSettingssDATA_SPLITFiledConditions',
    });
}

export const meta = [
    {
        tags: ['company-general-settings-controller'],
        path: '/companyGeneralSettingss/DATA_SPLIT/filedConditions',
        method: 'get',
    },
];
