export default {
  lang: {
    num: 'Nee.',
    total: 'Artikel/Totaal',
    strip: 'Artikel',
    more: 'Meer',
    chosen: 'Geselecteerd',
    cancel: 'Deselecteer',
    bulkOperations: 'Batch operatie',
    import: 'Importeren',
  },
};
