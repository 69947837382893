// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetReviewShowFieldsByBusinessTypeParam,
    BaseResponseListTableShowFieldsFoundResponse,
} from '../../../types';

/**
 * 查询销售赋能列表展示字段
 *
 * @export
 * @tags 销售赋能列表列字段管理
 * @link [GET] /review/showFields/{businessType}
 * @param businessType
 */
export function getReviewShowFieldsByBusinessType(
    options: ApiOptions<GetReviewShowFieldsByBusinessTypeParam, never> & {
        segments: GetReviewShowFieldsByBusinessTypeParam;
    }
): Promise<BaseResponseListTableShowFieldsFoundResponse> {
    return request<never, GetReviewShowFieldsByBusinessTypeParam, never>(
        '/review/showFields/{businessType}',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewShowFieldsByBusinessType' }
    );
}

export const meta = [
    { tags: ['销售赋能列表列字段管理'], path: '/review/showFields/{businessType}', method: 'get' },
];
