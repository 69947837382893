import React from 'react';
import Udesk from '../../udesk/index';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

class RadioBtn extends React.Component {
    privates = {
        radioName: ""
    }
    actions = {
        optionCheckChanged(option) {
            let {
                valueField,
                value
            } = this.props;
            if (value === option[valueField]) {
                return;
            }
            this.props.onChanged(option[valueField]);
        }
    }
    checkOptionIsSelected(option) {
        let {
            valueField,
            value
        } = this.props;
        return option[valueField] === value;
    }
    componentWillMount() {
        let {
            name
        } = this.props;
        if (name == null || name === "") {
            this.privates.radioName = `radio-${parseInt(Math.random() * 1e18, 10)}`;
        } else {
            this.privates.radioName = name;
        }
    }

    render() {
        let {
            value,
            nameField,
            options,
            classNames,
            itemClassNames,
            disabled
        } = this.props;
        return (
            <div className={ClassNames("component-udesk-react-radio-btn", { [`${classNames}`]: classNames })}>
                <For each="option" index="index" of={options}>
                    <label key={`radio-btn-item-${index}`} className={(itemClassNames == null ? `radio-btn-item` : `radio-btn-item ${itemClassNames}`)}>
                        <input type="radio" className={(!disabled ? `radio-btn-input` : `radio-btn-input input-disabled`)} disabled={disabled} name={this.privates.radioName} checked={this.checkOptionIsSelected(option, value)} onClick={this.actions.optionCheckChanged.params(option)} readOnly />
                        <i className="radio-btn-decorate"></i>
                        {option[nameField]}
                    </label>
                </For>
            </div>
        );
    }
}

RadioBtn.propTypes = {
    options: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    classNames: PropTypes.string,
    name: PropTypes.string,
    valueField: PropTypes.string,
    nameField: PropTypes.string,
    disabled: PropTypes.bool,
    itemClassNames: PropTypes.string,
    onChanged: PropTypes.func
};

RadioBtn.defaultProps = {
    classNames: "",
    value: null,
    options: [],
    name: null,
    valueField: "id",
    nameField: "name",
    disabled: false,
    itemClassNames: "",
    onChanged: null
};

export default Udesk.react.udeskify(RadioBtn);