import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
// import Locales from 'UdeskLocales';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import Page from 'Component/common/udesk-qa-page-layout';
import { Button, Card, Spin, Modal, FormBuilder, Popconfirm, Icon, Empty } from 'udesk-ui';
import './style.scss';
import { IntelligentPartnerTagGroupFoundResponse } from 'src/api/types';
import {
    getIntelligentPartnerTagGroups,
    postIntelligentPartnerTagGroups,
} from 'src/api/intelligentPartnerTagGroups';
import {
    deleteIntelligentPartnerTagGroupsById,
    putIntelligentPartnerTagGroupsById,
} from 'src/api/intelligentPartnerTagGroups/{id}';
import { AddTag } from './components/AddTag';
import { Tag } from './components/Tag';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Div = styled.div`
    overflow-y: auto;
    flex: 1;
    .udesk-qa-ui-card-head-title {
        flex: unset;
    }
    .udesk-qa-ui-card-extra {
        flex: 1;
        .customer-tag-groups-toolbar {
            display: flex;
            justify-content: space-between;
        }
    }
`;

const Template = React.memo((props: any) => {
    // const locales = Locales['current'];
    const [customerTagGroups, setCustomerTagGroups] = useState<
        IntelligentPartnerTagGroupFoundResponse[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [currentGroup, setCurrentGroup] = useState<IntelligentPartnerTagGroupFoundResponse>({});
    const [form] = FormBuilder.useForm();

    const queryCustomerTagGroups = async () => {
        setLoading(true);
        try {
            const { data = [] } = await getIntelligentPartnerTagGroups();
            setCustomerTagGroups(data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };
    useEffect(() => {
        queryCustomerTagGroups();
    }, []);
    const openModal = (group) => {
        setVisible(true);
        setCurrentGroup(group);
        form.setFieldsValue(group);
    };
    const closeModal = () => {
        setVisible(false);
    };
    return (
        <Page
            pageBodyClassName="udesk-coach-web-customer-tags-management-index"
            title={
                /* 标签管理 */ UdeskLocales['current'].pages.coach.customerTagsManagement.index
                    .labelManagement
            }
            padding={true}
            extra={[
                <Button onClick={openModal.bind(null, { groupName: '' })} type="primary">
                    {/* 新建分组 */}
                    {UdeskLocales['current'].pages.coach.customerTagsManagement.index.newGroup}
                </Button>,
            ]}
        >
            <Div>
                <Spin spinning={loading} delay={200}>
                    {customerTagGroups.map((item) => {
                        return (
                            <Card
                                type="inner"
                                title={item.groupName}
                                extra={
                                    <div className="customer-tag-groups-toolbar">
                                        <Button
                                            icon={<Icon type="EditTwoTone" antdIcon={true} />}
                                            size="small"
                                            tooltip={
                                                /* 编辑 */ UdeskLocales['current'].pages.coach
                                                    .customerTagsManagement.index.edit
                                            }
                                            onClick={() => {
                                                openModal(item);
                                            }}
                                        ></Button>
                                        <Popconfirm
                                            title={
                                                /* 删除后无法恢复，确定要删除此标签组吗？ */ UdeskLocales[
                                                    'current'
                                                ].pages.coach.customerTagsManagement.index
                                                    .afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup
                                            }
                                            onConfirm={() => {
                                                deleteIntelligentPartnerTagGroupsById({
                                                    segments: {
                                                        id: item.id!,
                                                    },
                                                }).then(queryCustomerTagGroups);
                                            }}
                                            // onCancel={cancel}
                                            okText={
                                                /* 删除 */ UdeskLocales['current'].pages.coach
                                                    .customerTagsManagement.index.delete
                                            }
                                            cancelText={
                                                /* 取消 */ UdeskLocales['current'].pages.coach
                                                    .customerTagsManagement.index.cancel
                                            }
                                        >
                                            <Button type="link" danger size="small">
                                                {/* 删除 */}
                                                {
                                                    UdeskLocales['current'].pages.coach
                                                        .customerTagsManagement.index.delete
                                                }
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                }
                            >
                                {(item.tags || []).map((tag) => {
                                    return (
                                        <Tag {...tag} onSubmit={queryCustomerTagGroups}>
                                            {tag.tagName}
                                        </Tag>
                                    );
                                })}
                                <AddTag onSubmit={queryCustomerTagGroups} groupId={item.id} />
                            </Card>
                        );
                    })}
                    {!customerTagGroups.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </Div>
            <Modal
                visible={visible}
                onCancel={closeModal}
                title={
                    currentGroup.id
                        ? /* 编辑分组 */ UdeskLocales['current'].pages.coach.customerTagsManagement
                              .index.editGroup
                        : /* 新建分组 */ UdeskLocales['current'].pages.coach.customerTagsManagement
                              .index.newGroup
                }
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            if (!currentGroup.id) {
                                return postIntelligentPartnerTagGroups(values);
                            } else {
                                return putIntelligentPartnerTagGroupsById(
                                    {
                                        ...currentGroup,
                                        ...values,
                                    },
                                    {
                                        segments: {
                                            id: currentGroup.id,
                                        },
                                    }
                                );
                            }
                        })
                        .then(() => {
                            closeModal();
                            queryCustomerTagGroups();
                        });
                }}
            >
                <FormBuilder
                    fields={[
                        {
                            type: 'Input',
                            name: 'groupName',
                            label: /* 标签分组名称 */ UdeskLocales['current'].pages.coach
                                .customerTagsManagement.index.labelGroupName,
                            rules: [{ required: true }],
                            props: {
                                placeholder: UdeskLocales['current'].labels.pleaseEnter,
                            },
                        },
                    ]}
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 12 }}
                    footerRender={false}
                />
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
