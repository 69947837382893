export default {
  lang: {
    export: 'Eksport',
    successfully: 'Misi eksport berjaya dibuat',
    pleaseJumpTo: 'Sila lompat ke',
    uploadAndDownloadCenter: 'Muat naik pusat muat turun',
    check: 'Lihat',
    exportAll: 'Sahkan untuk mengeksport semua kandungan?',
    total: 'Jumlah',
    confirm: 'Data, sahkan eksport?',
    error: 'Jumlah data yang dapat dieksport adalah 0, sila ubah keadaan penapis dan cuba lagi',
  },
};
