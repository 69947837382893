import React from 'react';
import Udesk from 'Udesk';
import _isEmpty from 'lodash-es/isEmpty';
import _find from 'lodash-es/find';
import _size from 'lodash-es/size';
import _compact from 'lodash-es/compact';
import _filter from 'lodash-es/filter';
import config from 'src/common/config/index';
import html2canvas from 'html2canvas';
// import _cloneDeep from 'lodash-es/cloneDeep';
import Locales from 'UdeskLocales';
import { Button, Space, Icon } from 'udesk-ui';
import { postBusinessAnalysisTrend } from 'src/api/businessAnalysis/trend';
import { postBusinessAnalysisProportion } from 'src/api/businessAnalysis/proportion';
import { postBusinessAnalysisMomChange } from 'src/api/businessAnalysis/momChange';
import { postBusinessAnalysisRank } from 'src/api/businessAnalysis/rank';
import { getInspectionTasksSourceBySource } from 'src/api/inspectionTasks/source/{source}';
import { getSystemModule } from 'Udesk/system/subApp';

class BusinessAnalysisComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {};

    privates = {
        fieldsData: [],
        businessList: [
            {
                type: '0',
                text: Locales['current'].fix.advancedConditions,
            },
            {
                type: '1',
                text: Locales['current'].fix.keyWord,
            },
            {
                type: '2',
                text: Locales['current'].fix.smartTags,
            },
        ],
        fieldsList: [
            {
                id: '0',
                list: [],
            },
            {
                id: '2',
                list: [
                    {
                        type: 'is',
                        name: Locales['current'].enums.fieldOperators.is,
                    },
                    {
                        type: 'contains_all',
                        name: Locales['current'].fix.containsAll,
                    },
                    {
                        type: 'is_any',
                        name: Locales['current'].fix.containsAny,
                    },
                ],
            },
            {
                id: '1',
                list: [
                    {
                        type: 'contains_any',
                        name: Locales['current'].fix.matchAny,
                    },
                    {
                        type: 'contains_all',
                        name: Locales['current'].fix.matchAll,
                    },
                ],
            },
        ],
        intelligenceList: [
            {
                value: '17',
                text: Locales['current'].fix.smartTagsOne,
            },
            {
                value: '18',
                text: Locales['current'].fix.smartTagsTwo,
            },
        ],
        colorList: ['#945FB9', '#5B8FF9', '#6DC9EC', '#FF9AC3', '#5AD8A6', '#FF9846'],
        childrenList: [],
        xAxisItems: [],
        seriversItems: [],
        sanKeyItems: [],
        sanKeyItemsLink: [],
        graphData: [],
        graphLinks: [],
        treeNumList: [],
        format: Udesk.config.date.momentDateFormat,
        serverFormat: Udesk.config.date.momentDateTimeFormat,
        storages: {
            startTime: moment().add(-30, 'day').format('YYYY-MM-DD 00:00:00'),
            endTime: moment().format('YYYY-MM-DD 23:59:59'),
            groupByType: '',
            dataSourceType: 0,
            data: [],
            num: 0, // 一级数据源新增时序号状态，只增不减
            colorChangeList: [],
            countList: 0,
            taskIds: [],
            smartTagsCondition: {}
        },

        searchTemplate: [],
        // findAllSamrtTagsItems: [],

        seriversItemsTitle: [],
        timeList: [
            {
                key: '1q',
                name: Locales['current'].fix.season,
            },
            {
                key: '1M',
                name: Locales['current'].fix.month,
            },
            {
                key: '1w',
                name: Locales['current'].fix.week,
            },
            {
                key: '1d',
                name: Locales['current'].fix.day,
            },
            {
                key: '1h',
                name: Locales['current'].fix.hour,
            },
        ],
        customJudgeLogic: '',
        judgeStrategy: 1,
        customTtile: '',
        saveFlag: true,
        conditionList: [],
        name: '',
        formInstance: React.createRef(),
        columns: [],
        detailColumns: [],
        dataSource: [],
        callDetailVisible: false,
        savedTemplates: [],
        proportionDataList: [],
        rankDataList: [],
        recordConditions: [],
        recordPagination: {
            current: 1,
            pageSize: 20,
            total: 0,
        },
        inspectionDetailData: {},
        smartTags: [],
        rankTableLoading: true,
        inspectionDetailLoading: true,
        momChangeData: [],
        tagRankKey: 1,
        lineEchartsLoading: true,
        pieEchartsLoading: true,
        dataSourceTypeOptions: [],
        allTasks: [],
        showAllConditionListFilter: true,
        smartTagsConditionOptions: [],
        smartTagsConditionOptionsForm: [],
        activeDetailId: null,
    };
    // enableStorage = true;
    // _storageKey = "";
    // storageStrategies = {
    //     storageWay: "sessionStorage",
    //     resetStrategies: {
    //         transitionToRoutes: [],
    //         notTransitionToRoutes: []
    //     }
    // };
    init() {
        // const company = Udesk.data.init.company;
        // if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
        //     this.privates.storages.dataSourceType = 0;
        // } else if (company.enabledCallInspect !== 0) {
        //     this.privates.storages.dataSourceType = 1;
        // } else if (company.enabledImInspect !== 0) {
        //     this.privates.storages.dataSourceType = 2;
        // }
        const company = Udesk.data.init.company;
        this.privates.storages.dataSourceType = 0;
        // this._storageKey = `business-analysis-filter-userId=${company.id}`;
        if(company.enabledCallInspect !== 0){
            this.privates.dataSourceTypeOptions.push({label: Locales['current'].fix.call, value: 1});
            !this.privates.storages.dataSourceType && (this.privates.storages.dataSourceType = 1);
        }
        if(company.enabledImInspect !== 0){
            this.privates.dataSourceTypeOptions.push({label: Locales['current'].fix.text, value: 2});
            !this.privates.storages.dataSourceType && (this.privates.storages.dataSourceType = 2);
        }
        if(company.enabledWeComInspect !== 0){
            this.privates.dataSourceTypeOptions.push({label: Locales['current'].fix.wechat, value: 5});
            !this.privates.storages.dataSourceType && (this.privates.storages.dataSourceType = 5);
        }
    }
    static computes = {
        _fields: [
            'privates.fieldsData',
            function ({ props, privates, locales }) {
                let { fieldsData } = privates;
                return Udesk.business.fields.buildConditionFields({
                    fields: fieldsData,
                });
            },
        ],
        _fieldValueSettings: [
            'privates.fieldsData',
            function ({ props, privates, locales }) {
                return config.getFilterComponentSettings();
            },
        ],
    };
    actions = {
        timeClick(value) {
            this.privates.storages.groupByType = value;
            this.actions.fieldSave();
        },
        dateChanged([startTime, endTime]) {
            this.privates.storages.startTime = startTime;
            this.privates.storages.endTime = endTime;
            if (moment(endTime).diff(moment(startTime), 'day') > 366) {
                Udesk.ui.notify.error(Locales['current'].fix.notMoreThan366);
                this.privates.storages.startTime = moment().add(-30, 'day').format('YYYY-MM-DD 00:00:00');
                this.privates.storages.endTime = moment().format('YYYY-MM-DD 23:59:59');
            }
            this.actions.update();
        },
        dataTypeMapChange(data, value) {
            if (!_isEmpty(data)) {
                data.forEach((item) => {
                    if (item.type === '0') {
                        if (value === 0) {
                            item.value = '';
                            item.type = '';
                        } else {
                            item.value = '';
                        }
                    }
                    if (!_isEmpty(item.children)) this.actions.dataTypeMapChange(item.children, value);
                });
            }
        },
        dataSourceTypeChange(value) {
            let { data } = this.privates.storages;
            this.privates.storages.dataSourceType = value;
            this.actions.dataTypeMapChange(data, value);
            this.privates.storages.data = [].concat(data);
            this.privates.storages.taskIds = [];
            this.privates.storages.smartTagsCondition = {};
            this.actions.update();
            let { sdkOptions } = this.props;
            if (value === 1 || value === 2 || value === 5) {
                let searchTemplateUrl = Udesk.business.apiPath.concatApiPath(`search-template/advanced`, sdkOptions);
                let serachTemplateData = {
                    dataFieldSourceList: ['a', 'pr'],
                    fieldIdList: ['f_3', 'f_10'],
                    excludeFieldSourceList: [],
                    excludeFieldIdList: [],
                    type: this.privates.storages.dataSourceType,
                };
                new Promise((resolve, reject) => {
                    Udesk.ajax.post(searchTemplateUrl, serachTemplateData).then(
                        (resp) => {
                            resolve(resp.data);
                            this.privates.searchTemplate = resp.data.filter((item) => item.status);
                            this.actions.update();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                            reject(reason);
                        }
                    );
                });
            }
        },
        datalistSaveMap(data) {
            if (!_isEmpty(data)) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].type === '') {
                        Udesk.ui.notify.error(Locales['current'].fix.propsCannotBeNull);
                        this.privates.saveFlag = false;
                        break;
                    } else if (data[i].type === '1' || data[i].type === '2') {
                        if (data[i].value === '' || data[i].operator === '') {
                            Udesk.ui.notify.error(Locales['current'].fix.propsCannotBeNull);
                            this.privates.saveFlag = false;
                            break;
                        }
                    } else if (data[i].type === '0') {
                        if (data[i].value === '') {
                            Udesk.ui.notify.error(Locales['current'].fix.propsCannotBeNull);
                            this.privates.saveFlag = false;
                            break;
                        }
                    }
                    !_isEmpty(data[i].children) && this.actions.datalistSaveMap(data[i].children);
                }
            }
        },
        dataValueMap(data) {
            if (!_isEmpty(data)) {
                data.forEach((item) => {
                    if (item.children && item.children.length > 0) {
                        this.actions.dataValueMap(item.children);
                    }
                    if (item.type === '2') {
                        item.value =
                            (item.value &&
                                item.value.map((items) => {
                                    return items.value;
                                })) ||
                            [];
                    }
                });
            }
        },
        requestTrend(datas) {
            let { colorChangeList } = this.privates.storages;
            this.privates.lineEchartsLoading = true;
            this.actions.update();
            postBusinessAnalysisTrend(datas).then((resp) => {
                let seriversItems = [];
                let xAxisItems = [];
                if (Array.isArray(resp?.data?.analysisList)) {
                    resp.data.analysisList.forEach((item, index) => {
                        seriversItems = [
                            ...seriversItems,
                            {
                                name: parseInt(item.num, 10) + '-' + item.key,
                                type: 'line',
                                itemStyle: {
                                    normal: {
                                        color: _find(
                                            colorChangeList,
                                            (current) => current.id === parseInt(item.num, 10)
                                        )
                                            ? _find(colorChangeList, (current) => current.id === parseInt(item.num, 10))
                                                .color[0]
                                            : '#000', //折线点的颜色
                                        lineStyle: {
                                            color: _find(
                                                colorChangeList,
                                                (current) => current.id === parseInt(item.num, 10)
                                            )
                                                ? _find(
                                                    colorChangeList,
                                                    (current) => current.id === parseInt(item.num, 10)
                                                ).color[0]
                                                : '#000', //折线的颜色
                                        },
                                    },
                                },
                                data: item.nodes && item.nodes.map((items) => items.data),
                            },
                        ];

                        if (item.nodes) {
                            item.nodes.forEach((itemss) => {
                                if (!_find(xAxisItems, (current) => current === itemss.time)) {
                                    xAxisItems = [...xAxisItems, itemss.time];
                                    // }
                                }
                            });
                        }
                    });
                }
                this.privates.xAxisItems = xAxisItems;
                this.privates.seriversItems = seriversItems;

                let list = [];
                seriversItems.forEach((item) => {
                    list = [...list, item.name];
                });
                this.privates.seriversItemsTitle = list;
            }).finally(() => {
                this.privates.lineEchartsLoading = false;
                this.actions.update();    
            });
        },
        requestProportion(datas) {
            let { colorChangeList } = this.privates.storages;
            this.privates.pieEchartsLoading = true;
            this.actions.update();    

            postBusinessAnalysisProportion(datas).then((resp) => {
                this.privates.proportionDataList = (resp?.data?.proportionDataList || []).map((item) => {
                    return {
                        ...item,
                        name: item.key,
                        value: item.total,
                        itemStyle: {
                            normal: {
                                color: _find(colorChangeList, (current) => current.id === parseInt(item.num, 10))
                                    ? _find(colorChangeList, (current) => current.id === parseInt(item.num, 10))
                                        .color[0]
                                    : '#000', //折线点的颜色
                                lineStyle: {
                                    color: _find(colorChangeList, (current) => current.id === parseInt(item.num, 10))
                                        ? _find(colorChangeList, (current) => current.id === parseInt(item.num, 10))
                                            .color[0]
                                        : '#000', //折线的颜色
                                },
                            },
                        },
                    };
                });
            }).finally(() => {
                this.privates.pieEchartsLoading = false;
                this.actions.update();    
            });
        },
        requestMomChange(datas) {
            postBusinessAnalysisMomChange(datas).then((resp) => {
                requestAnimationFrame(() => {
                    this.privates.momChangeData = resp.data.momChangeDataList;
                    this.actions.update();
                });
            });
        },
        createOtherClumns(){
            return [
                {
                    key: 'total',
                    title: /* 对话数 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.numberOfConversations,
                    dataIndex: 'total',
                },
                {
                    key: 'actions',
                    title: /* 操作 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.operation,
                    render: (text, record, index) => {
                        return (
                            <Space>
                                {
                                    this.privates.dataSourceTypeOptions.filter(o => o.value === this.privates.storages.dataSourceType).map(item => (<Button
                                        type='link'
                                        size='small'
                                        onClick={this.actions.tranToManageList.bind(null, text, item.value)}
                                    >
                                        {item.label}
                                    </Button>))
                                }
                            </Space>
                            
                        );
                    },
                },
            ];
        },
        onRankExpand(expanded, record) {
            if (expanded) {
                // record.children =
                const datas = this.actions.getParams(record.id);
                this.actions.requestRank(datas).then((resp) => {
                    record.children = (resp.data.rankDataList || []).map((item) => {
                        return {
                            ...item,
                            [record.level + 1]: item.conditionName,
                            level: record.level + 1,
                            children: item.levelConditions[0].children.length ? [] : undefined,
                        };
                    });
                    this.privates.rankDataList = [...this.privates.rankDataList];
                    if (record.level + 2 + 2 > this.privates.columns.length) {
                        this.privates.columns = [
                            ...new Array(record.level + 2).fill(1).map((i, index) => createColumns(index)),
                            ...this.actions.createOtherClumns(),
                        ];
                    }
                    this.actions.update();
                });
            }
        },
        requestRank(datas) {
            this.privates.rankTableLoading = true;
            this.actions.update();
            const params = { ...datas };
            if(Array.isArray(params.smartTagsCondition?.value)) {
                params.smartTagsCondition = {
                    ...params.smartTagsCondition,
                    value: params.smartTagsCondition?.value.map(i => `${i}`),
                };
            }
            return postBusinessAnalysisRank(params).then((resp) => {
                this.privates.rankTableLoading = false;
                this.actions.update();
                return resp;
            });
        },
        getParams(parentId = 0) {
            this.actions.datalistSaveMap(this.privates.storages.data);
            let { startTime, endTime, groupByType, dataSourceType, data, colorChangeList, taskIds, smartTagsCondition } = this.privates.storages;
            let ret =  {
                conditions: formatConditions(data, colorChangeList),
                startTime,
                endTime,
                dataSourceType,
                groupByType,
                parentId,
                taskIds,
            };
            if(smartTagsCondition?.operator && smartTagsCondition?.value?.length){
                ret.smartTagsCondition = smartTagsCondition;
            }
            return ret;
        },
        fieldSave() {
            this.privates.saveFlag = true;
            this.actions.datalistSaveMap(this.privates.storages.data);
            let { saveFlag } = this.privates;
            // let dataList = _cloneDeep(data);
            // this.actions.dataValueMap(dataList);
            this.privates.tagRankKey = this.privates.tagRankKey + 1;
            this.actions.update();
            let datas = this.actions.getParams(0);

            if (saveFlag) {
                this.actions.requestTrend(datas);
                this.actions.requestProportion(datas);
                this.actions.requestMomChange(datas);
                this.actions.requestRank(datas).then((resp) => {
                    requestAnimationFrame(() => {
                        this.privates.rankDataList = (resp.data.rankDataList || []).map((item) => {
                            return {
                                ...item,
                                0: item.conditionName,
                                key: item.id,
                                level: 0,
                                children: item.levelConditions[0].children.length ? [] : undefined,
                            };
                        });
                        this.privates.columns = [
                            createColumns(0),
                            ...this.actions.createOtherClumns(),
                        ];
                        this.actions.update();
                    });
                });
            }
        },
        datalistMap(data, count) {
            if (!_isEmpty(data)) {
                if (count) {
                    this.privates.storages.countList = count + _size(data);
                } else {
                    this.privates.storages.countList = _size(data);
                }
                data.forEach((item) => {
                    if (item.children && !_isEmpty(item.children)) {
                        this.actions.datalistMap(item.children, this.privates.storages.countList);
                    }
                });
            }
        },
        //添加一级分类
        addOneCustome() {
            let { colorList } = this.privates;
            let { data, num, colorChangeList, countList } = this.privates.storages;
            this.privates.storages.data = [
                ...data,
                {
                    type: '2',
                    value: '',
                    num: num + 1,
                    operator: '',
                    color: [colorList[countList]],
                    level: 1,
                },
            ];
            this.privates.storages.colorChangeList = [
                ...colorChangeList,
                {
                    id: num + 1,
                    color: [colorList[countList]],
                },
            ];
            this.actions.datalistMap(this.privates.storages.data);

            this.privates.storages.num = num + 1;
            this.actions.update();
        },
        fieldDelListChangeMap(data, value) {
            if (!_isEmpty(data)) {
                for (let i = data.length - 1; i >= 0; i--) {
                    if (data[i].num === value) {
                        if (data[i].children && data[i].children.length > 0) {
                            data.splice(i, 1, ...data[i].children);
                        } else {
                            data.splice(i, 1);
                        }
                    } else {
                        this.actions.fieldDelListChangeMap(data[i].children, value);
                    }
                }
            }
        },
        fieldDelListChange(value) {
            let { data, countList, colorChangeList } = this.privates.storages;
            this.actions.fieldDelListChangeMap(data, value);
            this.privates.storages.colorChangeList = _filter(colorChangeList, (current) => current.id !== value);
            this.privates.storages.countList = countList - 1;
            this.actions.update();
        },
        fieldAddListChangeMap(data, value, num) {
            let { colorList } = this.privates;
            let { countList } = this.privates.storages;
            if (!_isEmpty(data)) {
                _compact(data).forEach((item) => {
                    if (item.num === value) {
                        if (!_isEmpty(item.children) && item.children) {
                            item.children = [
                                ...item.children,
                                {
                                    type: '2',
                                    value: '',
                                    num: num,
                                    operator: '',
                                    color: colorList[countList],
                                },
                            ];
                        } else {
                            item.children = [
                                {
                                    type: '2',
                                    value: '',
                                    num: num,
                                    operator: '',
                                    color: colorList[countList],
                                },
                            ];
                        }
                    } else {
                        this.actions.fieldAddListChangeMap(_compact(item.children), value, num);
                    }
                });
            }
        },
        fieldAddListChange(value) {
            let { colorList } = this.privates;
            let { data, colorChangeList, num, countList } = this.privates.storages;
            this.privates.storages.num = num + 1;
            this.actions.fieldAddListChangeMap(data, value, this.privates.storages.num);
            this.actions.datalistMap(this.privates.storages.data);
            this.privates.storages.colorChangeList = [
                ...colorChangeList,
                {
                    id: num + 1,
                    color: [colorList[countList]],
                },
            ];
            this.actions.update();
        },
        dataSourceChangedMap(data, num, value) {
            if (!_isEmpty(data)) {
                data.forEach((item) => {
                    if (item.num === num) {
                        item.type = value;
                        item.value = '';
                        item.operator = '';
                    } else {
                        this.actions.dataSourceChangedMap(item.children, num, value);
                    }
                });
            }
        },
        dataSourceFromChangeMap(data, num, value) {
            if (!_isEmpty(data)) {
                data.forEach((item) => {
                    if (item.num === num) {
                        item.operator = value;
                    } else {
                        this.actions.dataSourceFromChangeMap(item.children, num, value);
                    }
                });
            }
        },
        dataSourceChange(num, value) {
            let { data } = this.privates.storages;
            this.actions.dataSourceChangedMap(data, num, value);
            this.actions.update();
        },
        dataSourceFromChange(num, value) {
            let { data } = this.privates.storages;
            this.actions.dataSourceFromChangeMap(data, num, value);
            this.actions.update();
        },
        dataSourceFromThreeChangeMap(data, num, value) {
            if (!_isEmpty(data)) {
                data.forEach((item) => {
                    if (item.num === num) {
                        item.value = value;
                    } else {
                        this.actions.dataSourceFromThreeChangeMap(item.children, num, value);
                    }
                });
            }
        },
        dataSourceFromThreeChange(num, value) {
            let { data } = this.privates.storages;
            this.actions.dataSourceFromThreeChangeMap(data, num, value);
            this.actions.update();
        },
        dataSourceFromfiveChange(num, value) {
            let { data } = this.privates.storages;
            this.actions.dataSourceFromThreeChangeMap(data, num, value);
            this.actions.update();
        },
        InputColorItemChange(num, value) {
            let { colorChangeList } = this.privates.storages;
            this.privates.storages.colorChangeList = colorChangeList.map((item) => {
                if (item.id === parseInt(num, 10)) {
                    item.color = [value];
                }
                return item;
            });

            this.actions.update();
        },
        renameItemChange(num, value) {
            // let { colorChangeList } = this.privates.storages;
            // this.privates.storages.colorChangeList = colorChangeList.map((item) => {
            //     if (item.id === parseInt(num, 10)) {
            //         item.color = [value];
            //     }
            //     return item;
            // });
            // this.actions.update();
        },
        newAddFilterFileds() {
            this.privates._isFilterVisible = true;
            this.actions.update();
        },
        filterContidionChanged(conditions) {
            let showFieldList = this.privates.fieldsData || [];
            let queryConditionList = this.actions.getQueryConditionList(conditions, showFieldList);
            this.privates.displayConditionList = conditions;
            this.privates.conditionList = queryConditionList;
        },
        handleConditionApply() {
            this.actions.handleConditionCancel();
            this.actions.getFieldUrl();
        },
        getFieldUrl() {
            let {
                sdkOptions,
                // match
            } = this.props;
            let searchTemplateUrl = Udesk.business.apiPath.concatApiPath(`search-template/advanced`, sdkOptions);
            let serachTemplateData = {
                dataFieldSourceList: ['a', 'pr'],
                fieldIdList: ['f_3', 'f_10'],
                excludeFieldSourceList: [],
                excludeFieldIdList: [],
                type: this.privates.storages.dataSourceType,
            };
            new Promise((resolve, reject) => {
                Udesk.ajax.post(searchTemplateUrl, serachTemplateData).then(
                    (resp) => {
                        resolve(resp.data);
                        this.privates.searchTemplate = resp.data.filter((item) => item.status);
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        handleConditionCancel() {
            this.privates._isFilterVisible = false;
            this.actions.update();
        },

        fieldExport() {
            let { data, startTime, endTime, dataSourceType, groupByType, colorChangeList } = this.privates.storages;
            let { sdkOptions } = this.props;
            // let dataList = _cloneDeep(data);
            // this.actions.dataValueMap(dataList);
            let datas = {
                conditions: formatConditions(data, colorChangeList),
                startTime,
                endTime,
                dataSourceType,
                groupByType,
                systemModule: getSystemModule(),
            };
            let searchUrl = Udesk.business.apiPath.concatApiPath(`businessAnalysis/exp`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.post(searchUrl, datas).then(
                    (resp) => {
                        Udesk.ui.notify.success(Locales['current'].fix.successfulExportAndToTaskCenter);
                        resolve(resp.data);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        fieldPdf() {
            html2canvas(document.getElementById('exportPDF')).then((canvas) => {
                let link = document.getElementById('exprotLink');
                link.href = canvas.toDataURL(); //下载链接
                // window.location = canvas.toDataURL();
                link.setAttribute('download', /* 业务分析.png */Locales['current'].pages.intelligentAnalysis.analysisReport.component.businessAnalysispng);
                link.style.display = 'none'; //a标签隐藏
                link.click();
            });
        },
        changeJudgeStrategy(value) {
            this.privates.judgeStrategy = value;
            this.actions.update();
        },
        customTtileChange(e) {
            this.privates.customTtile = e.target.value;
        },
        displacementChangeMapAdd(datas, item, field) {
            if (field) {
                if (!_isEmpty(datas)) {
                    for (let index = 0; index < datas.length; index++) {
                        if (datas[index].num === field) {
                            datas.splice(index + 1, 0, item);
                            return;
                        } else {
                            this.actions.displacementChangeMapAdd(datas[index].children, item, field);
                        }
                    }
                }
            } else {
                if (_size(datas) > 0) {
                    if (!_isEmpty(item)) {
                        if (item.children) {
                            item.children = [...item.children, ...datas];
                        } else {
                            item.children = [...datas];
                        }
                        this.privates.storages.data = [item];
                        this.actions.update();
                    }
                }
            }
        },
        displacementChangeMapAddRight(data, item, num) {
            if (!_isEmpty(data)) {
                for (let index = 0; index < data.length; index++) {
                    if (data[index].num === num) {
                        if (data[index].children) {
                            data[index].children = [...data[index].children, item];
                            return;
                        } else {
                            data[index].children = [item];
                            return;
                        }
                    } else {
                        this.actions.displacementChangeMapAddRight(data[index].children, item, num);
                    }
                }
            }
        },
        displacementChangeMap(data, num, str, field) {
            if (!_isEmpty(data)) {
                for (let index = data.length - 1; index >= 0; index--) {
                    if (data[index].num === num) {
                        if (str === 'top' && index !== 0) {
                            let items = data[index];
                            data.splice(index, 1);
                            data.splice(index - 1, 0, items);
                            return;
                        } else if (str === 'bottom' && index !== _size(data) - 1) {
                            let items = data[index];
                            data.splice(index, 1);
                            data.splice(index + 1, 0, items);
                            return;
                        } else if (str === 'left') {
                            if (field) {
                                let items = data[index];
                                if (
                                    !_isEmpty(
                                        this.privates.storages.data.filter(
                                            (current) => current.num === num && _size(this.privates.storages.data) === 1
                                        )
                                    )
                                ) {
                                    return;
                                } else {
                                    data.splice(index, 1);
                                    this.actions.displacementChangeMapAdd(
                                        this.privates.storages.data,
                                        items,
                                        field.num
                                    );
                                }
                                return;
                            } else {
                                let items = data[index];
                                if (
                                    !_isEmpty(
                                        this.privates.storages.data.filter(
                                            (current) => current.num === num && _size(this.privates.storages.data) === 1
                                        )
                                    )
                                ) {
                                    return;
                                } else {
                                    if (_size(this.privates.storages.data) > 1) {
                                        data.splice(index, 1);
                                        this.actions.displacementChangeMapAdd(this.privates.storages.data, items);

                                        return;
                                    }
                                }
                            }
                        } else if (str === 'right') {
                            if (_size(data) > 1) {
                                if (index > 0) {
                                    let items = data[index];
                                    let itemss = data[index - 1].num;
                                    data.splice(index, 1);
                                    this.actions.displacementChangeMapAddRight(
                                        this.privates.storages.data,
                                        items,
                                        itemss
                                    );

                                    return;
                                }
                            }
                        }
                    } else {
                        this.actions.displacementChangeMap(data[index].children, num, `${str}`, data[index]);
                    }
                }
            }
        },
        displacementChange(num, str) {
            let { data } = this.privates.storages;
            this.actions.displacementChangeMap(data, num, `${str}`);
            this.actions.update();
        },
        getQueryConditionList(conditionList, showFieldList) {
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map((condition) => {
                        showFieldList.forEach((field) => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        backToList() {
            const routeOptions = {
                history: this.props.history,
                routeName: 'businessAnalysisList',
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        save({ item, key, keyPath, domEvent }) {
            let saveAs = key === '1';
            const { sdkOptions } = this.props;
            this.privates.formInstance.current.validateFields().then(() => {
                if (saveAs || !this.privates.activeDetailId) {
                    Udesk.ajax
                        .post(Udesk.business.apiPath.concatApiPath(`businessAnalysisConditionConfigs`, sdkOptions), {
                            condition: {
                                ...this.privates.storages,
                                conditions: formatConditions(
                                    this.privates.storages.data,
                                    this.privates.storages.colorChangeList
                                ),
                            },
                            name: this.privates.name,
                            sourceFrom: 'SDK',
                        })
                        .then(
                            (resp) => {
                                this.actions.requestBusinessAnalysisConditionConfigs((savedTemplates) => {
                                    Udesk.ui.notify.success(/* 保存成功 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.savingSucceeded);
                                    if (savedTemplates.length) {
                                        this.actions.onDetailIdChange(resp.data.id);
                                    }
                                });
                            },
                            (reason) => {
                                Udesk.ui.notify.error(reason.errorMsg);
                            }
                        );
                } else {
                    Udesk.ajax
                        .put(
                            Udesk.business.apiPath.concatApiPath(
                                `businessAnalysisConditionConfigs/${this.privates.activeDetailId}`,
                                sdkOptions
                            ),
                            {
                                condition: {
                                    ...this.privates.storages,
                                    conditions: formatConditions(
                                        this.privates.storages.data,
                                        this.privates.storages.colorChangeList
                                    ),
                                },
                                name: this.privates.name,
                                sourceFrom: 'SDK',
                            }
                        )
                        .then(
                            (resp) => {
                                this.actions.requestBusinessAnalysisConditionConfigs((savedTemplates) => {
                                    Udesk.ui.notify.success(/* 保存成功 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.savingSucceeded);
                                    if (savedTemplates.length) {
                                        this.actions.onDetailIdChange(this.privates.activeDetailId);
                                    }
                                });
                            },
                            (reason) => {
                                Udesk.ui.notify.error(reason.errorMsg);
                            }
                        );
                }
            });
        },
        onNameChange(e) {
            this.privates.name = e.target.value;
            this.actions.update();
        },
        openCallDetail(item) {
            this.privates.callDetailVisible = true;
            this.privates.inspectionDetailLoading = true;
            this.actions.update();
            const { sdkOptions } = this.props;
            Udesk.ajax
                .get(
                    Udesk.business.apiPath.concatApiPath(`businessAnalysis/rank/callDetail/${item.dataId}`, sdkOptions)
                )
                .then(
                    (resp) => {
                        this.privates.inspectionDetailData = resp.data;
                        this.privates.inspectionDetailLoading = false;
                        this.actions.update();
                    },
                    () => {
                        this.privates.inspectionDetailLoading = true;
                        this.actions.update();
                    }
                );
            Udesk.ajax
                .get(
                    Udesk.business.apiPath.concatApiPath(`businessAnalysis/rank/tagsOfCall/${item.dataId}`, sdkOptions)
                )
                .then(
                    (resp) => {
                        this.privates.smartTags = resp.data;
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        },
        onCallDetailClose() {
            this.privates.callDetailVisible = false;
            this.actions.update();
        },
        onDetailIdChange(id) {
            const { sdkOptions } = this.props;
            this.privates.activeDetailId = id;
            this.privates.storages.data = [];
            this.actions.update();
            Udesk.ajax
                .get(Udesk.business.apiPath.concatApiPath(`businessAnalysisConditionConfigs/${id}`, sdkOptions))
                .then(
                    (resp) => {
                        if (resp.data) {
                            const { condition, name } = resp.data;
                            if (condition) {
                                const { startTime, endTime, dataSourceType, conditions, taskIds, smartTagsCondition } = condition;
                                this.privates.storages.startTime = startTime;
                                this.privates.storages.endTime = endTime;
                                this.privates.storages.dataSourceType = dataSourceType;
                                correctConditionNum(conditions);
                                addShowChildrenPropsInFirst(conditions);
                                this.privates.storages.data = conditions;
                                this.privates.storages.taskIds = taskIds ?? [];
                                this.privates.storages.smartTagsCondition = smartTagsCondition ?? {};
                                this.privates.name = name;
                                // this.privates.storages.colorChangeList = conditions;
                                let colorChangeList = [];
                                let countList = genColorChangeList(conditions, colorChangeList);
                                this.privates.storages.countList = countList;
                                this.privates.storages.colorChangeList = colorChangeList;
                                this.privates.storages.num = getMaxNum(conditions);
                                this.actions.update();
                                this.privates.formInstance?.current?.resetFields?.();
                                this.actions.fieldSave();
                            }
                        }
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        },
        onRecordPaginationChange(current, pageSize) {
            this.privates.recordPagination = {
                ...this.privates.recordPagination,
                current,
                pageSize,
            };
            this.actions.requestDrawerList(this.privates.recordConditions, current);
        },
        requestDrawerList(conditions, pageNum = 1) {
            const { sdkOptions } = this.props;
            let { startTime, endTime, groupByType, dataSourceType } = this.privates.storages;
            let { recordPagination } = this.privates;
            this.privates.detailTableLoading = true;
            this.actions.update();
            Udesk.ajax
                .post(Udesk.business.apiPath.concatApiPath(`businessAnalysis/rank/calls`, sdkOptions), {
                    startTime,
                    endTime,
                    dataSourceType,
                    groupByType,
                    conditions,
                    pageNum,
                    pageSize: recordPagination.pageSize,
                })
                .then(
                    (resp) => {
                        let columns = [];
                        if (resp.paging) {
                            this.privates.recordPagination = {
                                current: resp.paging.pageNum,
                                pageSize: resp.paging.pageSize,
                                total: resp.paging.total,
                            };
                        }
                        if (resp.data) {
                            resp.data.showFieldList.forEach((field) => {
                                let column = {
                                    title: field.label,
                                    key: field.id,
                                    render: (name, item, index) => {
                                        return item.fieldValueMap[field.id];
                                    },
                                };
                                columns.push(column);
                            });
                        }
                        columns.push({
                            key: 'actions',
                            title: /* 操作 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.operation,
                            render: (name, item, index) => {
                                return (
                                    <Button
                                        type='link'
                                        size='small'
                                        onClick={this.actions.tranToManageList.bind(null, item, 1)}
                                    >{/* 操作 */}{Locales['current'].pages.intelligentAnalysis.analysisReport.component.operation}</Button>
                                );
                            },
                        });
                        this.privates.dataSource = resp.data.fieldDataList;
                        this.privates.detailColumns = columns;
                        this.privates.detailTableLoading = false;
                        this.actions.update();
                    },
                    () => {
                        this.privates.detailTableLoading = false;
                        this.actions.update();
                    }
                );
        },
        tranToManageList(item, type) {
            Udesk.business.goToListManager.trans(
                {
                    conditionList: item.sourceConditions[type],
                },
                type
            );
        },
        onTaskIdsChange(values){
            this.privates.storages.taskIds = values;
            this.actions.update();
        },
        requestBusinessAnalysisConditionConfigs(cb) {
            const { sdkOptions } = this.props;
            Udesk.ajax
                .post(Udesk.business.apiPath.concatApiPath(`businessAnalysisConditionConfigs/search?pageSize=50`, sdkOptions), {
                    sourceFrom: 'SDK',
                })
                .then(
                    (resp) => {
                        this.privates.savedTemplates = resp.data || [];
                        this.actions.update();
                        cb?.(this.privates.savedTemplates);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        },
        export() {
            const { sdkOptions } = this.props;
            let { startTime, endTime, groupByType, dataSourceType, data, colorChangeList } = this.privates.storages;
            let datas = {
                conditions: formatConditions(data, colorChangeList),
                startTime,
                endTime,
                dataSourceType,
                groupByType,
            };
            Udesk.ajax.post(Udesk.business.apiPath.concatApiPath(`yili/businessAnalysis/exp`, sdkOptions), datas).then(
                (resp) => {
                    Udesk.ui.notify.success(Locales['current'].labels.exportSuccess);
                    window.location = resp.data;
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        onDelete(id) {
            const { sdkOptions } = this.props;
            Udesk.ajax
                .del(Udesk.business.apiPath.concatApiPath(`businessAnalysisConditionConfigs/${id}`, sdkOptions))
                .then(
                    (resp) => {
                        Udesk.ui.notify.success(/* 删除成功 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.deletionSucceeded);
                        this.privates.activeDetailId = null;
                        this.actions.requestBusinessAnalysisConditionConfigs((savedTemplates) => {
                            if (savedTemplates.length) {
                                this.actions.onDetailIdChange(savedTemplates[0].id);
                            }
                        });
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        },
        showConditionListFilter(){
            this.privates.showAllConditionListFilter = !this.privates.showAllConditionListFilter;
            this.actions.update();
        },
        onTagSelectModeChange(value){
            let { smartTagsCondition } = this.privates.storages;
            if(!smartTagsCondition) {
                smartTagsCondition = {};
            }
            this.privates.storages.smartTagsCondition = {...smartTagsCondition, operator: value};
            this.actions.update();
        },
        onTagSelectValueChange(value){
            let { smartTagsCondition } = this.privates.storages;
            if(!smartTagsCondition) {
                smartTagsCondition = {};
            }
            this.privates.storages.smartTagsCondition = {...smartTagsCondition, value: value.filter(i => typeof i === 'number')};
            this.actions.update();
        },
    };

    //#region Life Cycle
    componentDidMount() {
        const { sdkOptions } = this.props;
        Promise.all([
            Udesk.ajax.get(Udesk.business.apiPath.concatApiPath(`smartTagCategorys?funcType=QUALITY_INSPECT`, sdkOptions)),
            Udesk.ajax.post(Udesk.business.apiPath.concatApiPath(`smartTags/findAll`, sdkOptions), {
                tagName: '',
                pageSize: 3000,
                pageNum: 1,
            })
        ]).then(
            ([smartTagCategorys, smartTags]) => {
                // this.privates.findAllSamrtTagsItems = smartTags.data;
                this.privates.smartTagsConditionOptions = smartTagCategorys.data.map(c => ({
                    id: `category-${c.id}`, pId: c.parentId ? `category-${c.parentId}` : 0, value: `category-${c.id}`, title: (<Space align='baseline'><Icon type='FolderFilled' antdIcon={true} style={{ color: '#1b6dff' }} />{c.categoryName}</Space>), _title: c.categoryName
                })).concat(smartTags.data.map(t => ({
                    id: `${t.id}`, pId: `category-${t.categoryId}`, value: `${t.id}`, title: t.tagName, _title: t.tagName
                })));
                this.privates.smartTagsConditionOptionsForm = smartTagCategorys.data.map(c => ({
                    id: `category-${c.id}`, pId: c.parentId ? `category-${c.parentId}` : 0, value: `category-${c.id}`, title: (<Space align='baseline'><Icon type='FolderFilled' antdIcon={true} style={{ color: '#1b6dff' }} />{c.categoryName}</Space>), _title: c.categoryName
                })).concat(smartTags.data.map(t => ({
                    id: `${t.id}`, pId: `category-${t.categoryId}`, value: t.id, title: t.tagName, _title: t.tagName
                })));
                this.actions.update();
            },
            reason => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );

        this.actions.requestBusinessAnalysisConditionConfigs((savedTemplates) => {
            if (savedTemplates.length) {
                this.actions.onDetailIdChange(savedTemplates[0].id);
            }
        });
        Promise.all([getInspectionTasksSourceBySource({
            segments: {
                source: 0
            }
        }), getInspectionTasksSourceBySource({
            segments: {
                source: 5
            }
        })]).then(([resp1, resp2]) => {
            this.privates.allTasks = [...resp1.data, ...resp2.data].map(i => ({
                ...i,
                label: i.name,
                value: i.id,
            }));
            this.actions.update();
        });
        this.privates.momChangeColumns = [
            {
                key: 'order',
                title: /* 序列 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.sequence,
                render: (text, record, index) => {
                    return index + 1;
                },
            },
            {
                key: 'conditionName',
                dataIndex: 'conditionName',
                title: /* 一级分类 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.firstLevelClassification,
            },
            {
                key: 'total',
                dataIndex: 'total',
                title: /* 对话数 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.numberOfConversations,
            },
            {
                key: 'momChange',
                title: /* 环比变化 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.ringRatioChange,
                dataIndex: 'momChange',
                render: (text, record, index) => {
                    return text + '%';
                },
            },
        ];
    }
    // componentDidUpdate(prevProps, prevState) {
    //     this.actions.echartInitSanKey();
    //     this.actions.echartInitGraph();
    // }
    // componentWillUnmount() { }
    //#endregion
}

export default BusinessAnalysisComponent;

// function formatConditions(conditions, colorList, prevId = 0) {
//     if (!Array.isArray(conditions)) return [];

//     return conditions.map((condition, index) => {
//         const colorObj = colorList.find((i) => i.id === parseInt(condition.num, 10));
//         return {
//             ...condition,
//             color:
//                 colorObj?.color ||
//                 (condition.color && typeof condition.color === 'string' ? [condition.color] : condition.color),
//             children: formatConditions(condition.children, colorList, prevId),
//             id: prevId + index + 1,
//             parentId: prevId
//         };
//     });
// }
function formatConditions(conditions, colorList) {
    if (!Array.isArray(conditions)) return [];

    let currentId = 1;
    let stack = [...conditions];
    stack.forEach((c) => {
        c.parentId = 0;
        c.parentIds = '0';
        c.id = currentId++;
    });

    while (stack.length) {
        const current = stack.shift();
        if (Array.isArray(current.children) && current.children.length) {
            current.children.forEach((condition) => {
                condition.parentId = current.id;
                condition.parentIds = current.parentIds.split(',').concat(current.id).join(',');
                condition.id = currentId++;
            });
            current.rootFlag = 0;
            Array.prototype.push.apply(stack, current.children);
        } else {
            current.rootFlag = 1;
        }
    }

    function setColor(conditions, colorList) {
        if (!Array.isArray(conditions)) return [];

        return conditions.map((condition, index) => {
            const colorObj = colorList.find((i) => i.id === parseInt(condition.num, 10));
            return {
                ...condition,
                color:
                    colorObj?.color ||
                    (condition.color && typeof condition.color === 'string' ? [condition.color] : condition.color),
                children: setColor(condition.children, colorList),
            };
        });
    }
    return setColor(conditions, colorList);
}

function genColorChangeList(conditions, colorChangeList = [], countList = 0) {
    let ret = conditions.length;
    conditions.forEach((condition) => {
        colorChangeList.push({ id: parseInt(condition.num, 10), color: condition.color });
        if (Array.isArray(condition.children)) {
            ret += genColorChangeList(condition.children, colorChangeList, condition.children.length);
        }
    });
    return ret;
}
function getMaxNum(conditions) {
    let ret = 0;
    let stack = [...conditions];
    while(stack.length){
        let condition = stack.shift();
        if(Array.isArray(condition.children)){
            Array.prototype.push.apply(stack, condition.children);
        }
        ret = Math.max(parseInt(condition.num, 10), ret);
    }
    return ret;
}

function correctConditionNum(conditions) {
    let ret = 0;
    let stack = [...conditions];
    while(stack.length){
        let condition = stack.shift();
        if(Array.isArray(condition.children)){
            Array.prototype.push.apply(stack, condition.children);
        }
        condition.num = `${ret}`;
        ret++;
    }
}

function addShowChildrenPropsInFirst(conditions) {
    conditions.forEach(item => {
        item.showChildren = false;
    });
}

function createColumns(index) {
    return {
        key: `${index}`,
        title: `${index + 1}${/* 级分类 */Locales['current'].pages.intelligentAnalysis.analysisReport.component.classClassification}`,
        dataIndex: `${index}`,
        render: (text) => text || '-',
    };
}
