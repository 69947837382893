// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PutCallServiceSummaryFieldsEnableStatusByIdParam,
    PutCallServiceSummaryFieldsEnableStatusByIdBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * updateFieldEnableStatus
 *
 * @export
 * @tags call-service-summary-field-controller
 * @link [PUT] /callServiceSummaryFields/enableStatus/{id}
 * @param id
 */
export function putCallServiceSummaryFieldsEnableStatusById(
    data: PutCallServiceSummaryFieldsEnableStatusByIdBody,
    options: ApiOptions<PutCallServiceSummaryFieldsEnableStatusByIdParam, never> & {
        segments: PutCallServiceSummaryFieldsEnableStatusByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutCallServiceSummaryFieldsEnableStatusByIdBody,
        PutCallServiceSummaryFieldsEnableStatusByIdParam,
        never
    >('/callServiceSummaryFields/enableStatus/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putCallServiceSummaryFieldsEnableStatusById',
    });
}

export const meta = [
    {
        tags: ['call-service-summary-field-controller'],
        path: '/callServiceSummaryFields/enableStatus/{id}',
        method: 'put',
    },
];
