import React from 'react';

export default function DynamicComponent(props) {
    let Component = props.component;
    if (Component) {
        return (<Component {...props} />);
    }
    else {
        return null;
    }
}