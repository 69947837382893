import Udesk from 'Udesk';
import React from 'react';

export default class UdeskMenuBarSdkComponent extends React.Component {
    //#region state
    state = {
        releaseVersionUrl: '',
        menuBarSdkJsUrl: '',
        menuBarSdkCssUrl: '',
        udeskCaseReactInstance: null,
        sdkLoaderInstance: null
    };
    //#endregion

    //#region Computed properties
    static computes = {
        currentSdkUrl: function({ props, state, privates, locales }) {
            return 'https://sgo-assets.udesk.cn/menu-bar';
        }
    };
    //#endregion

    //#region Life Cycle
    model() {
        let currUser = Udesk.data.init.user.email;
        let ticketSdkConfig = new Promise((resolve, reject) => {
            Udesk.ajax.get(Udesk.business.apiPath.concatApiPath('/sign/ticket_sdk', this.props.sdkOptions)).then(
                resp => resolve(resp.data),
                reason => Udesk.ui.notify.error(reason.errorMsg)
            );
        });
        let imSdkConfig = new Promise((resolve, reject) => {
            Udesk.ajax
                .post(Udesk.business.apiPath.concatApiPath('/sign/web_im', this.props.sdkOptions), {
                    webToken: currUser
                })
                .then(
                    resp => resolve(Object.assign(
                        {
                            targetSelector: '#start-chat-btn',
                            panel: { css: { top: '62px' } }
                        },
                        resp.data
                    )),
                    reason => Udesk.ui.notify.error(reason.errorMsg)
                );
        });
        return {
            ticketSdkConfig,
            imSdkConfig
        };
    }
    componentDidMount() {
        let currentSdkUrl = this.privates.computes.currentSdkUrl;
        let releaseVersionUrl = `${currentSdkUrl}/RELEASE-VERSION`;
        let menuBarSdkJsUrl = `${currentSdkUrl}/static/js/udesk-menu-bar-sdk.js`;
        let menuBarSdkCssUrl = `${currentSdkUrl}/static/css/udesk-menu-bar-sdk.css`;
        this.setState({
            releaseVersionUrl: releaseVersionUrl,
            menuBarSdkJsUrl: menuBarSdkJsUrl,
            menuBarSdkCssUrl: menuBarSdkCssUrl
        });
    }
    //#endregion

    //#region actions
    actions = {
        onLoaded(SdkClass) {
            SdkClass.getUserForSentry = function() {
                return {
                    id: Udesk.data.init.user.id,
                    realname: Udesk.data.init.user.realname,
                    email: Udesk.data.init.user.email,
                    mobile: Udesk.data.init.user.mobile
                };
            };

            SdkClass.getTagsContextForSentry = function() {
                return {
                    id: Udesk.data.init.company.id,
                    logoUrl: Udesk.data.init.company.logo,
                    icon: Udesk.data.init.company.icon,
                    systemName: Udesk.data.init.company.sysName
                };
            };

            let releaseVersionUrl = this.state.releaseVersionUrl;
            let renderToOptions = {
                releaseVersionUrl,
                user: Udesk.data.init.user,
                reloadCallback: () => {
                    let sdkLoaderInstance = this.state.sdkLoaderInstance;

                    if (sdkLoaderInstance != null && typeof sdkLoaderInstance.reload === 'function') {
                        sdkLoaderInstance.reload();
                    }
                },
                ...this.privates.model,
                ticketSdkJsUrl: 'https://assets-cli.udesk.cn/ticket_js_sdk/1.0.1/js/sdk.es6.min.js',
                ticketSdkCssUrl: 'https://assets-cli.udesk.cn/ticket_js_sdk/1.0.1/css/sdk.min.css',
                externalParams: {
                    defaultFormData: {
                        // [UDQ.config.globalSettings.ticketSDKOriginFiledId]: UDQ.config.globalSettings.ticketSDKOriginFiledValue
                    }
                },
                imSdkJsUrl: '//assets-cli.udesk.cn/im_client/js/udeskApi.js',
                domain: this.privates.computes.currentSdkUrl,
                language: ''
            };
            let udeskCaseReactInstance = new SdkClass(renderToOptions);
            let renderSdkContainer = $('body')[0];

            if (renderSdkContainer) {
                udeskCaseReactInstance.renderTo(renderSdkContainer);
                this.setState({
                    udeskCaseReactInstance: udeskCaseReactInstance
                });
            }
        },

        onDestroying() {
            let udeskCaseReactInstance = this.state.udeskCaseReactInstance;

            if (udeskCaseReactInstance && typeof udeskCaseReactInstance.destroy === 'function') {
                udeskCaseReactInstance.destroy();
                this.setState({
                    udeskCaseReactInstance: null
                });
            }
        }
    };
    //#endregion
}
