import React from 'react';
import CustomerServicePortraitRoute from './route';
import './style.scss';
import { PageHeader, Select, Button, Empty, Modal, Checkbox, Row, Col, Input, Divider, Space, Tooltip, Icon } from 'udesk-ui';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import ExportButton from 'Component/common/export-button';

const { Option } = Select;
const { Search } = Input;

export default class CustomerServicePortraitTemplate extends CustomerServicePortraitRoute {
    render() {
        let {
            actions,
            locales,
            privates,
        } = this;
        let {
            customerSelectedValue,
            optinVisible,
            customerLabels,
            startTime,
            endTime,
            lineDatas,
            radarData,
            // customerSelectedValueDefault
        } = this.state;
        let { areaStyle, optionsAfterSearch, radarSeriesColumns, optionsSelected, customerDataShow } = privates;
        return (
            // <div className="customer-service-portrait-index-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.components.pages.customerServicePortrait.title}
                    subTitle={
                        <Tooltip
                            placement='bottomLeft'
                            overlayStyle={{maxWidth: 'unset'}}
                            title={
                                <div>
                                    <div>{locales.components.pages.customerServicePortrait.tip1}</div>
                                    <div>{locales.components.pages.customerServicePortrait.tip2}</div>
                                    <div>{locales.components.pages.customerServicePortrait.tip3}</div>
                                    <div>{locales.components.pages.customerServicePortrait.tip4}</div>
                                    <div>{locales.components.pages.customerServicePortrait.tip5}</div>
                                </div>
                            }
                        >
                            <Icon
                                type='QuestionCircleOutlined'
                                antdIcon={true}
                            ></Icon>
                        </Tooltip>
                    }
                />
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root customer-service-portrait-index-page'>
                        <div className="customer-service-portrait-index-page-body">
                            <div className="customer-service-portrait-index-page-body-contol-bar">
                                <div className="customer-service-portrait-index-page-body-contol-bar-date">
                                    <div className="customer-service-portrait-index-page-body-contol-bar-date-des">
                                        {
                                            locales.components.pages.customerServicePortrait.contol
                                                .date
                                        }
                                    </div>
                                    <UdeskDatePicker onChange={actions.selectTime} value={[startTime, endTime]} allowClear={false} style={{ width: 246, height: 32 }} />
                                </div>
                                <div className="customer-service-portrait-index-page-body-contol-bar-customer">
                                    <div className="customer-service-portrait-index-page-body-contol-bar-customer-des">
                                        {
                                            locales.components.pages.customerServicePortrait.contol
                                                .customer
                                        }
                                    </div>
                                    <Select
                                        mode="multiple"
                                        labelInValue
                                        filterOption={actions.filterOption}
                                        value={customerSelectedValue}
                                        maxTagCount={5}
                                        placeholder={locales.components.pages.customerServicePortrait.contol.selectPlaceholder}
                                        onChange={actions.selectCustomerChange}
                                        // defaultValue={customerSelectedValueDefault}
                                        style={{ width: "calc(100% - 42px)" }}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div
                                                    style={{ padding: '4px 8px', color: customerSelectedValue.length === 30 ? 'rgba(251,81,39,1)' : 'rgba(0, 0, 0, 0.65)' }}
                                                    onMouseDown={e => e.preventDefault()}
                                                >
                                                    {customerSelectedValue.length + '/30'}
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {customerDataShow.map(d => (
                                            <Option key={d.id}>{d.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="customer-service-portrait-index-page-body-contol-bar-btn">
                                    <div className="customer-service-portrait-index-page-body-contol-bar-btn-left">
                                        <Space>
                                            <Button
                                                type="primary"
                                                onClick={actions.searchCustomerInfoDebounce}
                                            >
                                                {
                                                    locales.components.pages.customerServicePortrait.contol
                                                        .search
                                                }
                                            </Button>
                                            <Button
                                                onClick={actions.reset}
                                            >
                                                {
                                                    locales.components.pages.customerServicePortrait.contol
                                                        .reset
                                                }
                                            </Button>
                                        </Space>
                                    </div>
                                    <div className="customer-service-portrait-index-page-body-contol-bar-btn-right">
                                        <Space>
                                            <ExportButton hasFilterCondition={true} onClick={actions.export} />
                                            <Button onClick={actions.toIndicator}>
                                                {
                                                    locales.components.pages.customerServicePortrait.contol
                                                        .indicator
                                                }
                                            </Button>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                            <div className="customer-service-portrait-index-page-body-dashboard">
                                <div
                                    className="customer-service-portrait-index-page-body-dashboard-left"
                                >
                                    {lineDatas.length ?
                                        lineDatas.map((lineData, index) => {
                                            return (
                                                <div style={{ height: "400px", width: "100%", paddingBottom: '20px' }}>
                                                    <ReactEcharts
                                                        // ref={(instance) => {echartsRefs.push(instance)}}
                                                        ref={actions.setEchartsRefs}
                                                        key={index}
                                                        type="line"
                                                        xLabelField="xLabel"
                                                        enableDataZoom={true}
                                                        height={"100%"}
                                                        width={"100%"}
                                                        rerenderOptimization={true}
                                                        finalizeChartOptions={lineData.getOption}
                                                    />
                                                </div>
                                            );
                                        })
                                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    }
                                </div>
                                <div className="customer-service-portrait-index-page-body-dashboard-right">
                                    <div className="customer-service-portrait-index-page-body-dashboard-right-top">
                                        <div className="customer-service-portrait-index-page-body-dashboard-right-top-des">
                                            <div className="customer-service-portrait-index-page-body-dashboard-right-top-des-left">
                                                {
                                                    locales.components.pages.customerServicePortrait
                                                        .portrait.ability
                                                }
                                            </div>
                                            <div className="customer-service-portrait-index-page-body-dashboard-right-top-des-right">
                                                <span onClick={actions.showOption}>

                                                    {
                                                        locales.components.pages.customerServicePortrait
                                                            .portrait.option
                                                    }
                                                </span>
                                            </div>
                                            <Modal
                                                className="customer-service-portrait-index-page-body-madal-1"
                                                title={
                                                    locales.components.pages.customerServicePortrait
                                                        .portrait.abilityOption
                                                }
                                                visible={optinVisible}
                                                onOk={actions.optinHandleOk}
                                                onCancel={actions.optinHandleCancel}
                                                okText={
                                                    locales.components.pages.customerServicePortrait
                                                        .portrait.save
                                                }
                                            >
                                                <div className="customer-service-portrait-index-page-body-madal-1-content">
                                                    <div className="customer-service-portrait-index-page-body-madal-1-content-search">
                                                        <Search
                                                            placeholder={
                                                                locales.components.pages.customerServicePortrait
                                                                    .portrait.placeholder
                                                            }
                                                            onSearch={actions.onSearch}
                                                            style={{ width: "100%" }}
                                                            onChange={actions.onSearchChange}
                                                        />
                                                    </div>
                                                    <Checkbox.Group
                                                        style={{ width: "100%", padding: "0 16px" }}
                                                        onChange={actions.onOptionChange}
                                                        value={optionsSelected}
                                                    >
                                                        <Row>
                                                            {optionsAfterSearch.map(option => {
                                                                return (
                                                                    <Col span={12}>
                                                                        <Checkbox
                                                                            value={option.title}
                                                                            key={option.key}
                                                                        >
                                                                            {option.title}
                                                                        </Checkbox>
                                                                    </Col>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Checkbox.Group>
                                                </div>
                                            </Modal>
                                        </div>
                                        <div className="right-top-cavas" id="dashboardRightTop">
                                            {radarData.series && radarData.series.length ?
                                                <ReactEcharts
                                                    ref={actions.setEchartsRefs}
                                                    type="radar"
                                                    areaStyle={areaStyle}
                                                    seriesColumns={radarSeriesColumns}
                                                    top={100}
                                                    rerenderOptimization={true}
                                                    finalizeChartOptions={actions.getEchartsRadarOptions}
                                                />
                                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                        </div>
                                    </div>
                                    <div className="customer-service-portrait-index-page-body-dashboard-right-bottom">
                                        <div className="customer-service-portrait-index-page-body-dashboard-right-bottom-title">
                                            {
                                                locales.components.pages.customerServicePortrait.label
                                                    .title
                                            }
                                        </div>
                                        <div className="customer-service-portrait-index-page-body-dashboard-right-bottom-content">
                                            {customerLabels.length ?
                                                customerLabels.map(customerLabel => {
                                                    return (
                                                        <div className="customer-service-portrait-index-page-body-dashboard-right-bottom-content-label">
                                                            <div className="customer-service-portrait-index-page-body-dashboard-right-bottom-content-label-title">
                                                                {customerLabel.name}
                                                            </div>
                                                            <div className="customer-service-portrait-index-page-body-dashboard-right-bottom-content-label-tiglist">
                                                                {customerLabel.tags && customerLabel.tags.map(tig => {
                                                                    return (
                                                                        <div className="customer-service-portrait-index-page-body-dashboard-right-bottom-content-label-tig">
                                                                            {`${tig.tagsName} (${tig.count})`}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
