// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetIntelligentPartnerNotificationShareListQuery,
    BaseResponseListIntelligentPartnerNotificationShareWrapper,
} from '../../../types';

/**
 * 消息中心-分享给我的消息记录
 *
 * @export
 * @tags 智能陪练-消息中心
 * @link [GET] /intelligentPartnerNotification/share/list
 */
export function getIntelligentPartnerNotificationShareList(): Promise<BaseResponseListIntelligentPartnerNotificationShareWrapper>;
export function getIntelligentPartnerNotificationShareList(
    options: ApiOptions<never, GetIntelligentPartnerNotificationShareListQuery>
): Promise<BaseResponseListIntelligentPartnerNotificationShareWrapper>;
export function getIntelligentPartnerNotificationShareList(
    options?: ApiOptions<never, GetIntelligentPartnerNotificationShareListQuery>
): Promise<BaseResponseListIntelligentPartnerNotificationShareWrapper> {
    return request<never, never, GetIntelligentPartnerNotificationShareListQuery>(
        '/intelligentPartnerNotification/share/list',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerNotificationShareList',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-消息中心'],
        path: '/intelligentPartnerNotification/share/list',
        method: 'get',
    },
];
