import React, { FC, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { Button, Modal, Space, Spin } from 'udesk-ui';
import { TaskTitle } from '../TaskTitle';
import { ComprehensiveEvaluation } from '../ComprehensiveEvaluation';
import OnceMore from '../OnceMore';
import { getIntelligentPartnerByRecordIdScorePageDetail } from 'src/api/intelligentPartner/{recordId}/scorePageDetail';
import { IntelligentPartnerScorePageDetails } from 'src/api/types';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

export type ResultModalRefType = {
    onOpen: () => void;
    onClose: () => void;
};

type TemplateProps = {
    recordId?: number;
    templateRef?: any;
    history: any;
    onBack?: () => void;
    onDetail?: () => void;
    config?: number[];
};

const Template: FC<TemplateProps> = (props) => {
    const { recordId, templateRef, history, onBack, onDetail, config } = props;

    const [visible, setVisible] = useState(false);

    const onOpen = () => {
        setVisible(true);
    };

    const onCancel = () => {
        setVisible(false);
    };

    useImperativeHandle(templateRef, () => {
        return {
            onOpen,
            onCancel,
        };
    });

    const [detail, setDetail] = useState<IntelligentPartnerScorePageDetails>();
    const [loading, setLoading] = useState(false);

    const { run: getDetail, cancel: cancelGetDetail } = useRequest(
        () => getIntelligentPartnerByRecordIdScorePageDetail({ segments: { recordId: recordId! } }),
        {
            manual: true,
            pollingInterval: 2000,
            ready: !!recordId,
            onSuccess: (resp) => {
                setDetail(resp.data ?? {});

                if (resp.data?.scoreOverView) {
                    cancelGetDetail();
                    setLoading(false);
                }
            },
            onError: () => {
                cancelGetDetail();
                setLoading(false);
            },
        }
    );

    useEffect(() => {
        if (visible) {
            setLoading(true);
            getDetail();
        } else {
            setLoading(false);
            cancelGetDetail();
        }
    }, [cancelGetDetail, getDetail, visible]);

    const onOnceMore = () => {
        // 这里，自己跳自己，使用中转站重定向
        const routeOptions = {
            history,
            routeName: 'transferStation',
            queryParams: {
                from: window.location.pathname + window.location.search,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const { canCheckDetail, showScore, showResult } = useMemo(() => {
        return {
            canCheckDetail: config?.includes(3),
            showScore: config?.includes(1),
            showResult: config?.includes(2),
        };
    }, [config]);

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            closable={false}
            maskClosable={false}
            footer={
                <Space>
                    <Button onClick={onBack}>{/* 返回 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.resultModal.index.return}</Button>
                    <OnceMore
                        {...detail}
                        taskId={detail?.taskId}
                        history={history}
                        onClick={onOnceMore}
                    />

                    {canCheckDetail ? (
                        <Button onClick={onDetail} type="primary">{/* 查看详情 */}{UdeskLocales['current'].pages.coach.learningCenter.record.detail.components.resultModal.index.viewDetails}</Button>
                    ) : null}
                </Space>
            }
        >
            <div>
                <Spin spinning={loading}>
                    <TaskTitle {...detail} showScore={showScore} showResult={showResult} />
                    <ComprehensiveEvaluation
                        recordId={recordId}
                        scoreOverView={detail?.scoreOverView}
                    />
                </Spin>
            </div>
        </Modal>
    );
};

export default Template;
