import React from 'react';
import PropTypes from 'prop-types';
import reactUdesk from '../../udesk';

class NavBar extends React.Component {
    //#region defaultProps
    static defaultProps = {
        linkConfig: {},
        classNames: "",
        pathValueField: "pathName",
        pathParamsValueField: "pathParams",
        queryParamsValueField: "queryParams",
        textValueField: "text",
        tooltipValue: "tooltipData",
        navItemClassNames: "",
        activeClassNames: "link-active",
        onChanged: null,
        CustomReactTool: null,
        CustomReactToolExportUrl: ""

    }
    static propTypes = {
        linkConfig: PropTypes.object,
        classNames: PropTypes.string,
        pathValueField: PropTypes.string,
        pathParamsValueField: PropTypes.string,
        queryParamsValueField: PropTypes.string,
        textValueField: PropTypes.string,
        tooltipValue: PropTypes.string,
        navItemClassNames: PropTypes.string,
        activeClassNames: PropTypes.string,
        onChanged: PropTypes.func,
        CustomReactToolExportUrl: PropTypes.string,
    }
    //#endregion

    actions = {
        onClicked(item, event) {
            this.trigger("onChanged", item, event);
        },
        getItemOptions() {
            let {
                pathValueField,
                pathParamsValueField,
                queryParamsValueField,
                tooltipValue,
                linkConfig
            } = this.props;

            let hasIsVisiblePropery = Object.prototype.hasOwnProperty.call(linkConfig, "isVisible") && ("isVisible" in linkConfig);
            let isVisible = hasIsVisiblePropery ? linkConfig.isVisible : true;

            let hasExactPropery = Object.prototype.hasOwnProperty.call(linkConfig, "exact") && ("exact" in linkConfig);
            let exact = hasExactPropery ? linkConfig.exact : true;

            let hasIsActivePropery = Object.prototype.hasOwnProperty.call(linkConfig, "isActive") && ("isActive" in linkConfig);
            let isActive = hasIsActivePropery ? linkConfig.isActive : undefined;

            let hasTooltipPropery = Object.prototype.hasOwnProperty.call(linkConfig, tooltipValue) && (tooltipValue in linkConfig);

            let hasStrictPropery = Object.prototype.hasOwnProperty.call(linkConfig, "strict") && ("strict" in linkConfig);
            let strict = hasStrictPropery ? linkConfig.strict : true;

            let hasIsRoutePathPropery = Object.prototype.hasOwnProperty.call(linkConfig, pathValueField) && (pathValueField in linkConfig);
            let pathValue = hasIsRoutePathPropery ? linkConfig[pathValueField] : null;

            let routePathOPtions = {
                routeName: linkConfig[pathValueField],
                pathParams: linkConfig[pathParamsValueField],
                queryParams: linkConfig[queryParamsValueField]
            };
            let routePath = reactUdesk.ui.routing.getRoutePath(routePathOPtions);

            return {
                isVisible,
                exact,
                isActive,
                hasTooltipPropery,
                strict,
                pathValue,
                routePath
            };
        }
    }

}

export default NavBar;