import React, { FC } from 'react';
import { Input } from 'udesk-ui';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DivideRule } from 'src/api/types';

const DynamicInputWrapper = styled.div`
    padding: 14px;
    background-color: rgba(0, 0, 0, 0.05);
`;
const DynamicInputItem = styled.div`
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

type DynamicInputProps = {
    value?: DivideRule[];
    onChange?: (v: DivideRule[]) => void;
};

export const DynamicInput: FC<DynamicInputProps> = (props) => {
    const { value, onChange } = props;

    const onLevelChange = (idx: number, val: string) => {
        const newValue = (value || []).map((v, _idx) => {
            if (_idx === idx) {
                return {
                    ...v,
                    divide: val,
                };
            } else {
                return v;
            }
        });
        onChange?.(newValue);
    };

    const onDesChange = (idx: number, val: string) => {
        const newValue = (value || []).map((v, _idx) => {
            if (_idx === idx) {
                return {
                    ...v,
                    divideDesc: val,
                };
            } else {
                return v;
            }
        });
        onChange?.(newValue);
    };

    const onAdd = () => {
        const newValue = [...(value || []), {}];
        onChange?.(newValue);
    };

    const onRemove = (idx: number) => {
        const newValue = (value || []).filter((v, _idx) => _idx !== idx);
        onChange?.(newValue);
    };

    return (
        <DynamicInputWrapper>
            {value?.map((v, idx) => {
                return (
                    <DynamicInputItem>
                        <span style={{ marginRight: 8 }}>{idx + 1}</span>

                        <Input
                            value={v.divide}
                            style={{ width: 180, marginRight: 8 }}
                            onChange={(e) => onLevelChange(idx, e.target.value)}
                        />

                        <Input
                            value={v.divideDesc}
                            style={{ flex: 1, width: 'auto', marginRight: 8 }}
                            onChange={(e) => onDesChange(idx, e.target.value)}
                        />

                        <MinusCircleOutlined onClick={() => onRemove(idx)} />
                    </DynamicInputItem>
                );
            })}
            {(value?.length || 0) < 5 ? <PlusCircleOutlined onClick={onAdd} /> : null}
        </DynamicInputWrapper>
    );
};
