// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type { BaseResponseWfConfigFoundResponse, PostWfConfigsBody } from '../types';

/**
 * findAll
 *
 * @export
 * @tags wf-config-controller
 * @link [GET] /wfConfigs
 */
export function getWfConfigs(): Promise<BaseResponseWfConfigFoundResponse>;
export function getWfConfigs(
    options: ApiOptions<never, never>
): Promise<BaseResponseWfConfigFoundResponse>;
export function getWfConfigs(
    options?: ApiOptions<never, never>
): Promise<BaseResponseWfConfigFoundResponse> {
    return request<never, never, never>('/wfConfigs', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getWfConfigs',
    });
}

/**
 * save
 *
 * @export
 * @tags wf-config-controller
 * @link [POST] /wfConfigs
 */
export function postWfConfigs(data: PostWfConfigsBody): Promise<BaseResponseWfConfigFoundResponse>;
export function postWfConfigs(
    data: PostWfConfigsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseWfConfigFoundResponse>;
export function postWfConfigs(
    data: PostWfConfigsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseWfConfigFoundResponse> {
    return request<PostWfConfigsBody, never, never>('/wfConfigs', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postWfConfigs',
    });
}

export const meta = [
    { tags: ['wf-config-controller'], path: '/wfConfigs', method: 'get' },
    { tags: ['wf-config-controller'], path: '/wfConfigs', method: 'post' },
];
