import React from 'react';
import Udesk from 'Udesk';
import config from '../../../../common/config/index';
import { getSystemModule } from 'Udesk/system/subApp';
// import _isEmpty from 'lodash-es/isEmpty';

class ListManageComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        title: '',
        cacheKey: '',
        searchPlaceHolder: '',
        searchFieldName: 'keyword',
        type: Udesk.enums.filterTypes.call.id,
        // filter: null,
        editFilterVisible: false,
        selectedFilter: {},
    };

    privates = {
        storages: {
            searchField: '',
            paging: {},
            selectedFilter: null,
            fieldList: [],
            // type: Udesk.enums.applyRoles.all.id,
            keywordType: null,
        },
        displayConditionList: [],
        conditionList: [],
        judgeStrategy: Udesk.enums.operatorRuleTypes.all.id,
        customJudgeLogic: '',
        fields: [],
        // fieldValueSettings: null,
        // filter: null,
        tableDOMRef: React.createRef(),
        editFilterVisible: false,
        newFilterVisible: false,
        data: {},
        isSave: null,
        filterName: '',
        updateType: null,
        currentKeywords: '',
        conditionListFilter: [],
        customFilterList: [],
        customeFilterField: null,
        customeFilterFieldKey: [],
        customDataSourceList: [],
        customId: null,
        customName: '',
        tableRef: React.createRef(),
        length150items: ['a_9', 'a_119', 'a_120', 'a_121', 'a_122'],
        loading: false,
        selectedItems: [],
        addToTaskModalVisible: false,
    };
    enableStorage = true;
    _storageKey = 'ListManage';
    storageStrategies = {
        storageWay: 'sessionStorage',
        extraStorages: [],
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/site\/dialogue-manage\/view/i,
                /^\/site\/call-manage\/view/i,
                /^\/site\/wechat-qa\/chart-record\/detail/i,
                /^\/site\/ticket-manage\/view/i,
                /^\/site\/e-commerce-manage\/view/i,
            ],
        },
    };
    static computes = {
        _columns: [
            'privates.data',
            function ({ props, privates, locales }) {
                let { data } = privates;
                let showFieldList = data.showFieldList || [];
                let columns = [];
                showFieldList.forEach((field) => {
                    let column = {
                        // name: field.fieldName,
                        title: field.label,
                        key: field.id,
                        // hasTemplate: true,
                        ellipsis: field.id === 'a_132',
                        width:
                            field.id === 'a_132'
                                ? 200
                                : privates.length150items.includes(field.id)
                                ? 150
                                : undefined,
                        render: (name, item, index) => {
                            return item.fieldValueMap[field.id];
                        },
                    };
                    columns.push(column);
                });
                if (showFieldList.length > 0) {
                    // 构建对话关键词列
                    const keywordsFieldName = '_keywords';
                    const column = {
                        // name: keywordsFieldName,
                        title: locales.columns.custom.keywords,
                        key: keywordsFieldName,
                        // checked: true,
                        // hasTemplate: true,
                        width: 150,
                        render: (name, item, index) => {
                            const fieldValue = item.fieldValueMap[keywordsFieldName];
                            if (fieldValue) {
                                const list = JSON.parse(fieldValue);
                                if (list.length > 0) {
                                    return list.map((value) => {
                                        if (value.length > 20) {
                                            let text = value.substring(0, 20) + '...';
                                            return (
                                                <div
                                                    className="udesk-custom-columns-keywords"
                                                    title={value}
                                                >
                                                    {text}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="udesk-custom-columns-keywords">
                                                    {value}
                                                </div>
                                            );
                                        }
                                    });
                                } else {
                                    return '-';
                                }
                            } else {
                                return '-';
                            }
                        },
                    };
                    if (!props.isWechatQa) {
                        columns.push(column);
                    }
                }
                return columns;
            },
        ],
        _isFilterVisible: [
            'privates.editFilterVisible',
            'privates.newFilterVisible',
            function ({ props, privates, locales }) {
                let {
                    // filter,
                    editFilterVisible,
                    newFilterVisible,
                } = privates;
                if (editFilterVisible || newFilterVisible) {
                    return true;
                }
                return false;
            },
        ],
        _fields: [
            'privates.fieldList',
            function ({ props, privates, locales }) {
                let { fieldList } = privates;

                return Udesk.business.fields.buildConditionFields({
                    fields: fieldList,
                });
            },
        ],
        _fieldValueSettings: [
            'privates.fieldList',
            function ({ props, privates, locales }) {
                return config.getFilterComponentSettings();
            },
        ],
    };
    actions = {
        pageChanged(pageNum, pageSize) {
            this.actions.reloadAsyncModel({ pageNum, pageSize });
        },
        changeSearchField(event) {
            this.privates.storages.searchField = event.target.value;
            this.actions.update();
        },
        searchItems() {
            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
            };
            this.actions.reloadAsyncModel(params);
        },
        changeFilterVisible(e) {
            e.stopPropagation();
            let { newFilterVisible } = this.privates;
            this.privates.newFilterVisible = !newFilterVisible;
            this.actions.update();
        },
        handleConditionFilter() {
            let { selectedFilter } = this.props;
            let { isSave, updateType, filterName, conditionList, judgeStrategy, customJudgeLogic } =
                this.privates;
            let filterOptions = {
                conditionList,
                judgeStrategy,
                customJudgeLogic,
                filterName,
            };
            if (isSave) {
                filterOptions.isSave = true;
                filterOptions.isUpdate = false;
            } else {
                if (updateType === Udesk.enums.updateTypes.saveAs.id) {
                    filterOptions.isSave = true;
                    filterOptions.isUpdate = false;
                } else if (updateType === Udesk.enums.updateTypes.update.id) {
                    filterOptions.isSave = false;
                    filterOptions.isUpdate = true;
                    filterOptions.id = selectedFilter.id;
                } else {
                    filterOptions.isSave = false;
                    filterOptions.isUpdate = false;
                }
            }
            this.actions.handleConditionCancelWithoutClearData();
            if (filterOptions.isSave || filterOptions.isUpdate) {
                this.trigger('onConditionFilterSaved', filterOptions);
                this.privates.isSave = false;
                this.privates.updateType = null;
                this.privates.filterName = '';
                this.actions.update();
            }
            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
            };
            this.actions.reloadAsyncModel(params);
        },
        handleConditionCancel() {
            this.privates.editFilterVisible = false;
            this.privates.newFilterVisible = false;
            this.trigger('onEditFilterVisibleChanged', this.privates.editFilterVisible);
            this.actions.update();
        },
        handleConditionCancelWithoutClearData() {
            this.privates.editFilterVisible = false;
            this.privates.newFilterVisible = false;
            this.trigger('onEditFilterVisibleChanged', this.privates.editFilterVisible);
            this.actions.update();
        },
        filterContidionChanged(conditions) {
            let showFieldList = this.privates.storages.fieldList || [];
            let queryConditionList = this.actions.getQueryConditionList(conditions, showFieldList);
            this.privates.displayConditionList = conditions;
            this.privates.conditionListFilter = conditions.map((item, index) => {
                return {
                    id: `conditionListFilter-${index}`,
                    content: index + 1,
                };
            });
            this.privates.conditionList = queryConditionList;
            this.actions.update();
        },
        gotoDetail(item) {
            let { displayConditionList, conditionList, judgeStrategy, customJudgeLogic } =
                this.privates;
            let { searchField, keywordType } = this.privates.storages;
            let selectedFilter = Object.assign(
                {},
                {
                    displayConditionList,
                    conditionList,
                    judgeStrategy,
                    customJudgeLogic,
                    keyword: searchField,
                    keywordType,
                }
            );
            this.trigger('onViewItemDetail', item, selectedFilter);
        },
        changeJudgeStrategy(value) {
            this.privates.judgeStrategy = value.target.value;
            if (
                value.target.value === Udesk.enums.operatorRuleTypes.all.id ||
                value.target.value === Udesk.enums.operatorRuleTypes.any.id
            ) {
                this.privates.customJudgeLogic = '';
            }
            this.actions.update();
        },
        changeIsSaved(value) {
            this.privates.isSave = value;
            this.actions.update();
        },
        changeUpdateType(value) {
            this.privates.updateType = value;
            this.actions.update();
        },
        exportCallList() {
            let { locales } = this;
            let { sdkOptions, type, searchFieldName } = this.props;
            let { data, conditionList, judgeStrategy, customJudgeLogic } = this.privates;
            let { searchField, keywordType } = this.privates.storages;
            let showFieldList = data.showFieldList || [];
            let exportTaskFieldCreateRequestList = showFieldList.map((field) => {
                return {
                    field: field.id,
                    label: field.label,
                };
            });
            let showFieldIdList = [];
            if (showFieldList && showFieldList.length > 0) {
                showFieldIdList = showFieldList.map((field) => {
                    return field.id;
                });
            }
            let url = Udesk.business.apiPath.concatApiPath(`exps/callData`, sdkOptions);
            let params = {
                docType: 1,
                source: 1,
                showFieldList: showFieldIdList,
                exportTaskFieldCreateRequestList: exportTaskFieldCreateRequestList,
                conditionList: conditionList,
                judgeStrategy: judgeStrategy,
                customJudgeLogic: customJudgeLogic,
                systemModule: getSystemModule(),
            };
            if (keywordType !== null) {
                params.keywordType = keywordType;
            }
            params[searchFieldName] = searchField;
            if (type === Udesk.enums.filterTypes.dialogue.id) {
                params.source = 2;
            }
            if (type === Udesk.enums.filterTypes.ticket.id) {
                params.source = 10;
            }
            if (type === Udesk.enums.filterTypes.taobao.id) {
                params.source = 8;
            }
            if (type === Udesk.enums.filterTypes.eComment.id) {
                params.source = 15;
            }
            if (type === Udesk.enums.filterTypes.mComment.id) {
                params.source = 16;
            }
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    Udesk.ui.notify.success(
                        Udesk.utils.string.format(
                            locales.business.notifyMessage.exportSuccessFormat,
                            locales.components.pages.components.listManage.records
                        )
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.exportFailedFormat,
                                locales.components.pages.components.listManage.records
                            )
                    );
                }
            );
        },
        getQueryConditionList(conditionList, showFieldList) {
            let queryConditionList = [];
            if (conditionList && conditionList instanceof Array && conditionList.length > 0) {
                queryConditionList = Udesk.utils.object.deepCopy(conditionList);
                if (queryConditionList && queryConditionList.length > 0) {
                    queryConditionList.map((condition) => {
                        showFieldList.forEach((field) => {
                            if (condition.field === field.id) {
                                condition.field = field;
                            }
                        });
                        return condition;
                    });
                }
            }
            return queryConditionList;
        },
        onColumnsChanged() {
            this.actions.update();
        },
        handleConditionDel(id) {
            let { sdkOptions } = this.props;
            let url = Udesk.business.apiPath.concatApiPath(`search-template/${id}`, sdkOptions);
            new Promise((resolve, reject) => {
                Udesk.ajax.del(url).then(
                    (resp) => {
                        Udesk.ui.notify.success(
                            this.locales.components.common.customFilter.delSuccess
                        );
                        this.actions.getCustomFilterList();
                        this.trigger('onConditionFilterSaved', true);
                        resolve(resp);
                        this.actions.update();
                    },
                    (reason) => {
                        Udesk.ui.notify.error(
                            reason.errorMsg ||
                                this.locales.components.pages.components.listManage.getRecordsFailed
                        );
                        reject(reason);
                    }
                );
            });
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy, id, name) {
            this.privates.conditionList = conditionList;
            this.privates.customJudgeLogic = customJudgeLogic;
            this.privates.judgeStrategy = judgeStrategy;
            let params = {
                pageNum: Udesk.config.paging.defaultPageNumber,
            };
            this.privates.customName = name;
            this.privates.customId = id;
            this.actions.reloadAsyncModel(params);
            this.actions.handleConditionCancelWithoutClearData();
        },

        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.conditionList.forEach((item) => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            this.getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = '';
                        item.value.forEach((items) => {
                            if (items.agentName) {
                                str += items.agentName + ',';
                            }
                        });
                        list = [
                            ...list,
                            item.field.label +
                                ' ' +
                                obj[item.operator] +
                                ' ' +
                                str.substring(0, str.length - 1),
                        ];
                    } else if (typeof item.value === 'object') {
                        let str =
                            item.field.label +
                            ' ' +
                            obj[item.operator] +
                            ' ' +
                            (item.value.agentName ||
                                item.value.agentGroupName ||
                                item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === 'string') {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    } else {
                        let str = item.field.label + ' ' + obj[item.operator] + ' ' + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + ' ' + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <p style={{ marginBottom: '3px' }}>
                        {index + 1}. {item}
                    </p>
                );
            });
        },

        onPopoverVisible(e) {
            this.privates.tableRef.current.triggerColumnsControl(e);
        },

        selectType(value) {
            this.privates.storages.keywordType = value;
            this.actions.update();
        },
        onSelectChange(record, selected, selectedRows, nativeEvent) {
            if (selected) {
                this.privates.selectedItems = [...this.privates.selectedItems, record];
            } else {
                this.privates.selectedItems = this.privates.selectedItems.filter(
                    (i) => i.key !== record.key
                );
            }
            this.actions.update();
        },
        onSelectAllChange(selected, selectedRows, changeRows) {
            if (selected) {
                this.privates.selectedItems = [...this.privates.selectedItems, ...changeRows];
            } else {
                this.privates.selectedItems = this.privates.selectedItems.filter(
                    (i) => !changeRows.some((c) => c.key === i.key)
                );
            }
            this.actions.update();
        },
        close() {
            this.privates.addToTaskModalVisible = false;
            this.actions.update();
        },
    };

    //#region Life Cycle
    init() {
        if (window.location.pathname === '/site/dialogue-manage/index') {
            this._storageKey = 'dialogueListManage';
        } else if (window.location.pathname === '/site/ticket-manage/index') {
            this._storageKey = 'ticketListManage';
        } else {
            this._storageKey = 'callListManage';
        }
        let type = this.props.type;
        //回显默认筛选器
        if (type === Udesk.enums.filterTypes.wechatAll.id) {
            type = Udesk.enums.filterTypes.wechat.id;
        }
        let defaultSearchTemplate = this.props.sdkOptions.props.defaultSearchTemplate.find(
            (template) => `${template.templateType}` === type
        );
        if (defaultSearchTemplate) {
            this.privates.conditionList = defaultSearchTemplate.conditionList;
            this.privates.judgeStrategy = defaultSearchTemplate.judgeStrategy;
            this.privates.customJudgeLogic = defaultSearchTemplate.customJudgeLogic;
            this.privates.storages.selectedFilter = defaultSearchTemplate;
        }
    }
    parseProps({ props, prevProps }) {
        if (props.selectedFilter.id !== prevProps.selectedFilter.id) {
            return {
                editFilterVisible: props.editFilterVisible,
                conditionList: props.selectedFilter.conditionList,
                judgeStrategy: props.selectedFilter.judgeStrategy,
                customJudgeLogic: props.selectedFilter.customJudgeLogic,
                customName: props.selectedFilter.name,
                customId: props.selectedFilter.id,
            };
        } else {
            return {
                editFilterVisible: props.editFilterVisible,
            };
        }
    }
    componentDidMount() {
        if (this.props.keyword) {
            this.privates.storages.searchField = this.props.keyword;
        }
        highlightKeyword(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let { selectedFilter } = this.props;
        let { storages } = this.privates;

        if (selectedFilter.id !== prevProps.selectedFilter.id) {
            storages.searchField = '';
            storages.keywordType = null;
            storages.paging = {
                pageNum: Udesk.config.paging.defaultPageNumber,
                pageSize: Udesk.config.paging.defaultPageSize,
            };
            storages.selectedFilter = null;
            this.actions.update();
            this.actions.reloadAsyncModel();
        } else {
            if (storages.selectedFilter != null) {
                this.privates.conditionList = storages.selectedFilter.conditionList;
                this.privates.judgeStrategy = storages.selectedFilter.judgeStrategy;
                this.privates.customJudgeLogic = storages.selectedFilter.customJudgeLogic;
                storages.selectedFilter = null;
            }
        }
        highlightKeyword(this);
    }
    componentWillUnmount() {
        let { conditionList, judgeStrategy, customJudgeLogic, storages } = this.privates;

        storages.selectedFilter = Object.assign(
            {},
            { conditionList, judgeStrategy, customJudgeLogic }
        );
    }
    asyncModel(changedParams) {
        let { sdkOptions, type, searchFieldName } = this.props;
        this.privates.loading = true;
        this.actions.update();
        // let { conditionList, judgeStrategy, customJudgeLogic } = this.privates;
        let { searchField, paging, keywordType } = this.privates.storages;
        this.privates.customDataSourceList = [];

        let params = Object.assign(
            {},
            {
                type: type,
                pageNum: paging.pageNum || Udesk.config.paging.defaultPageNumber,
                pageSize: paging.pageSize || Udesk.config.paging.defaultPageSize,
                conditionList: this.privates.conditionList || [],
                judgeStrategy: this.privates.judgeStrategy || Udesk.enums.operatorRuleTypes.all.id,
                customJudgeLogic: this.privates.customJudgeLogic || '',
            }
        );
        if (keywordType !== null) {
            params.keywordType = keywordType;
        }
        params[searchFieldName] = searchField;

        let transitionParams = JSON.parse(localStorage.getItem('maualAddToManage_pamars'));
        if (transitionParams != null) {
            this.privates.storages.selectedFilter = transitionParams;
            const keywordType =
                transitionParams.keywordType === null ? null : Number(transitionParams.keywordType);
            this.privates.storages.keywordType = keywordType;
            params.keywordType = keywordType;
            this.privates.storages.searchField = transitionParams.keyword;
            params.keyword = this.privates.storages.searchField;
            this.actions.update();
        }
        localStorage.removeItem('maualAddToManage_pamars');
        if (this.privates.storages.selectedFilter != null) {
            params.conditionList = this.privates.storages.selectedFilter.conditionList;
            params.judgeStrategy = this.privates.storages.selectedFilter.judgeStrategy;
            params.customJudgeLogic = this.privates.storages.selectedFilter.customJudgeLogic;
        }

        if (changedParams) params = Object.assign({}, params, changedParams);
        let url = Udesk.business.apiPath.concatApiPath(`data/search`, sdkOptions);
        let dataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                (resp) => {
                    this.privates.loading = false;
                    this.actions.update();
                    resolve(resp);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            this.locales.components.pages.components.listManage.getRecordsFailed
                    );
                    this.privates.loading = false;
                    this.actions.update();
                    reject(reason);
                }
            );
        });
        return {
            data: dataPromise,
        };
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult, asyncKey } = parseOptions;
        if (asyncKey === 'data') {
            if (asyncResult.data) {
                this.privates.data = asyncResult.data;
                this.privates.data.fieldDataList = (asyncResult.data.fieldDataList ?? []).map(
                    (i) => ({ ...i, key: i.dataId })
                );
            }
            if (asyncResult.paging) {
                this.privates.storages.paging = asyncResult.paging;
                let { pageNum, pageSize, total } = this.privates.storages.paging;
                this.trigger('onPageChanged', pageNum, pageSize, total);
            }
            this.privates.currentKeywords = this.privates.storages.searchField;
            this.actions.update();
        }
    }
    getOptionValue(id, optionValueList) {
        let value = optionValueList.filter((item) => {
            if (Array.isArray(id)) {
                return id.includes(item.id);
            } else {
                return item.id === id;
            }
        });
        if (value.length > 0) {
            value = value.map((item) => {
                return item.name;
            });
            return value.join(',');
        } else {
            return '';
        }
    }
    //#endregion
}

function highlightKeyword(that) {
    const searchContet = that.privates.storages.searchField.trim();
    if (searchContet === '' || that.privates.currentKeywords !== searchContet) return;

    const keywords = searchContet.split(/ +/);
    const tableDOM = that.privates.tableDOMRef.current;
    const keywordsElems = tableDOM.getElementsByClassName('udesk-custom-columns-keywords');
    for (let i = 0; i < keywordsElems.length; i++) {
        Udesk.utils.dom.clearHighlight(keywordsElems[i]);
        keywords.forEach((item) => {
            Udesk.utils.dom.highlightText(keywordsElems[i], item);
        });
    }
}

export default ListManageComponent;
