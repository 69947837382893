export default {
  lang: {
    export: 'Uitvoer',
    successfully: 'Taak exporteren met succes gemaakt',
    pleaseJumpTo: 'Spring alsjeblieft',
    uploadAndDownloadCenter: 'Upload-en downloadcentrum',
    check: 'Weergave',
    exportAll: 'Bevestig om alle inhoud te exporteren?',
    total: 'Totaal',
    confirm: 'Gegevens, export bevestigen?',
    error:
      'Hoeveelheid exporteerbare gegevens is 0, wijzig de filtercriteria en probeer het opnieuw',
  },
};
