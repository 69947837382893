export default {
  lang: {
    export: '導出',
    successfully: '導出任務創建成功',
    pleaseJumpTo: '請跳轉至',
    uploadAndDownloadCenter: '上傳下載中心',
    check: '查看',
    exportAll: '確認導出全部內容?',
    total: '總共',
    confirm: '條數據,確認導出?',
    error: '可導出數據數量為0,請更改篩選條件後重試',
  },
};
