// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    PostIntelligentPartnerAiAnalysisTextToSqlResultFindAllBody,
    BaseResponseListMapstringobject,
} from '../../../../../../types';

/**
 * 分页查询SQL结果
 *
 * @export
 * @tags 陪练智能分析接口
 * @link [POST] /intelligentPartner/ai/analysis/textToSql/result/findAll
 */
export function postIntelligentPartnerAiAnalysisTextToSqlResultFindAll(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultFindAllBody
): Promise<BaseResponseListMapstringobject>;
export function postIntelligentPartnerAiAnalysisTextToSqlResultFindAll(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultFindAllBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListMapstringobject>;
export function postIntelligentPartnerAiAnalysisTextToSqlResultFindAll(
    data: PostIntelligentPartnerAiAnalysisTextToSqlResultFindAllBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListMapstringobject> {
    return request<PostIntelligentPartnerAiAnalysisTextToSqlResultFindAllBody, never, never>(
        '/intelligentPartner/ai/analysis/textToSql/result/findAll',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentPartnerAiAnalysisTextToSqlResultFindAll',
        }
    );
}

export const meta = [
    {
        tags: ['陪练智能分析接口'],
        path: '/intelligentPartner/ai/analysis/textToSql/result/findAll',
        method: 'post',
    },
];
