import React from 'react';
import PropTypes from 'prop-types';
import Udesk from 'Udesk';

class MassageConfigurationComponent extends React.Component {
    //#region Default Props
    static propTypes = {
        type: PropTypes.number,
    };
    static defaultProps = {
        type: Udesk.enums.messageType.trigger.id,
    };
    //#endregion

    state = {

    };
    privates = {
        isFilterVisible: false,
        conditionList: [],
        triggerTypeList: [],
        checkedTypes: [],
        days: [],
    };
    static computes = {

    };
    actions = {
        //高级筛选器
        openCustomFilterModal(e) {
            e && e.stopPropagation && e.stopPropagation();
            this.privates.isFilterVisible = !this.privates.isFilterVisible;
            this.actions.update();
        },
        handleConditionCancel() {
            this.actions.openCustomFilterModal();
        },
        handleConditionApply(conditionList, customJudgeLogic, judgeStrategy) {
            this.privates.configuration.baseFilter.conditionList = conditionList;
            this.privates.configuration.baseFilter.customJudgeLogic = customJudgeLogic;
            this.privates.configuration.baseFilter.judgeStrategy = judgeStrategy;
            this.actions.openCustomFilterModal();
            this.props.onConfigurationChange(this.privates.configuration);
        },
        onPushTypesChange(newCheckedTypes) {
            let { configuration } = this.props;
            let id = configuration._id;
            // newConfiguration.checkedTypes = newCheckedTypes;
            this.props.onMassageConfigurationChange(newCheckedTypes, id);
        },
        onPushConfChange(newPushConf){
            let {configuration} = this.privates;
            configuration.monitorConf.pushConf = Object.assign(configuration.monitorConf.pushConf, newPushConf);
            this.props.onConfigurationChange(configuration);
        },
        onSelectChange(key, value){
            this.privates.configuration.monitorConf[key] = value;
            this.actions.onPushConfChange(this.privates.configuration);
        },
        onTimePickerChange(key, time, timeString){
            this.privates.configuration.monitorConf[key] = timeString + ':00';
            this.actions.onPushConfChange(this.privates.configuration);
        },
        changeMonitorConfName(e){
            this.privates.configuration.monitorConf.name = e.target.value;
            this.actions.onPushConfChange(this.privates.configuration);
        },
        fieldsFilterChange(data) {
            let { locales } = this;
            let obj = locales.enums.fieldOperators;
            let list = [];
            data.forEach(item => {
                if (item.value) {
                    if (
                        item.field.fieldType === 4 &&
                        item.field.optionValueList &&
                        item.field.optionValueList.length > 0
                    ) {
                        let str =
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            getOptionValue(item.value, item.field.optionValueList);
                        list = [...list, str];
                    } else if (Array.isArray(item.value)) {
                        let str = "";
                        item.value.forEach(items => {
                            if (items.agentName) {
                                str += items.agentName + ",";
                            }
                        });
                        list = [
                            ...list,
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            str.substring(0, str.length - 1)
                        ];
                    } else if (typeof item.value === "object") {
                        let str =
                            item.field.label +
                            " " +
                            obj[item.operator] +
                            " " +
                            (item.value.agentName || item.value.agentGroupName || item.value.tagName);
                        list = [...list, str];
                    } else if (typeof item.value === "string") {
                        let str =
                            item.field.label + " " + obj[item.operator] + " " + item.value;
                        list = [...list, str];
                    } else {
                        let str =
                            item.field.label + " " + obj[item.operator] + " " + item.value;
                        list = [...list, str];
                    }
                } else {
                    let str = item.field.label + " " + obj[item.operator];
                    list = [...list, str];
                }
            });
            return list.map((item, index) => {
                return (
                    <div
                        style={{
                            color: 'rgba(0, 0, 0, 0.65)',
                            marginBottom:
                                index + 1 === list.length
                                    ? '0px'
                                    : '8px',
                        }}
                    >
                        {index + 1}. {item}
                    </div>
                );
            });
        },
    };

    //#region Life Cycle
    init(){
        this.privates.days = [];
        for(let i = 1; i <= 32; i++){
            this.privates.days.push(i);
        }
    }
    parseProps({ props, prevProps }) {
        let configuration = Udesk.utils.object.deepCopy(props.configuration);
        let isWechatQa = props.isWechatQa;
        let isTicket = props.taskType === Udesk.enums.inspectDataSources.ticket.id;
        //初始化executeTime字段
        if(configuration.monitorConf.pushCycle === Udesk.enums.assessFrequencyTypes.everyDay.id && !configuration.monitorConf.executeTime){
            configuration.monitorConf.executeTime = '10:00:00';
        }
        let triggerTypeList = Udesk.enums.inspectionUpdateTypes.map(item => (
            {
                label: isWechatQa ? item.wechatName : Udesk.enums.inspectionUpdateTypes.getName(item.id),
                value: item.id
            }
        ));
        if(isWechatQa){
            triggerTypeList = triggerTypeList.filter(item => [Udesk.enums.inspectionUpdateTypes.autoInspection.id, Udesk.enums.inspectionUpdateTypes.recheckInspection.id].includes(item.value));
        }
        if(isTicket){
            triggerTypeList = triggerTypeList.filter(item => ![Udesk.enums.inspectionUpdateTypes.artificialComplain.id, Udesk.enums.inspectionUpdateTypes.artificialReview.id].includes(item.value));
        }
        let checkedTypes = configuration.monitorConf.pushConf.filter(item => item.enabled).map(item => item.actionType);
        
        return {checkedTypes, triggerTypeList, configuration};
    }
    componentDidMount() {
    }
    // shouldComponentUpdate(nextProps, nextState){
    //     // if(nextProps.configuration.monitorConf.pushConf.actionDetails.content !== this.props.configuration.monitorConf.pushConf.actionDetails.content){
    //     //     return false;
    //     // }
    //     let res = true;
    //     this.props.configuration.monitorConf.pushConf.forEach((pushConf, index) => {
    //         if(nextProps.configuration.monitorConf.pushConf[index].actionType === pushConf.actionType){
    //             if(pushConf.actionDetails.content !== nextProps.configuration.monitorConf.pushConf[index].actionDetails.content){
    //                 res = false;
    //             }
    //         }

    //         // nextProps.configuration.monitorConf.pushConf.forEach(pushConfNext => {
    //         //     if(pushConf.actionType === pushConfNext.actionType){
                    
    //         //     }
    //         // });
    //     });
    //     return res;
    // }
    componentWillUnmount() {

    }
    asyncModel(changedParams) {

    }
    //#endregion
}

export default MassageConfigurationComponent;

function getOptionValue(id, optionValueList) {
    let value = optionValueList.filter((item) => {
        if (Array.isArray(id)) {
            return id.includes(item.id);
        } else {
            return item.id === id;
        }
    });
    if (value.length > 0) {
        value = value.map((item) => {
            return item.name;
        });
        return value.join(',');
    } else {
        return '';
    }
}
