// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetReviewIntelligentPartnerDialogFlowQuery,
    BaseResponseIntelligentPartnerDialogFlowFoundResponse,
} from '../../../types';

/**
 * 查询课程对话流程
 *
 * @export
 * @tags 智能陪练-对话流程(节点, 全局配置, 客户画像)
 * @link [GET] /review/intelligentPartner/dialogFlow
 */
export function getReviewIntelligentPartnerDialogFlow(
    options: ApiOptions<never, GetReviewIntelligentPartnerDialogFlowQuery> & {
        params: GetReviewIntelligentPartnerDialogFlowQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogFlowFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerDialogFlowQuery>(
        '/review/intelligentPartner/dialogFlow',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewIntelligentPartnerDialogFlow' }
    );
}

export const meta = [
    {
        tags: ['智能陪练-对话流程(节点, 全局配置, 客户画像)'],
        path: '/review/intelligentPartner/dialogFlow',
        method: 'get',
    },
];
