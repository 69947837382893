import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import { Button, Badge, Tabs } from 'udesk-ui';
// import AutoSizeTable from 'Component/common/auto-size-table';
// import UdeskPagination from 'Component/common/udesk-pagination';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import { getVoiceUploadResultById } from 'src/api/voice-upload/result/{id}';
import { StandardizeTable } from 'udesk_gm_ui';
import { StandardizeTableProps } from 'udesk_gm_ui/es/standardize-table/types';
import { UserFoundResponse } from 'src/api/types';
import { getUsersFindAll } from 'src/api/users/findAll';
import { postUploadRecordsResultsSearch } from 'src/api/uploadRecordsResults/search';
import { postUploadRecordsResultsExport } from 'src/api/uploadRecordsResults/export';
import { getCurrentRouteName, getSystemModule, isSalesSubApp } from 'Udesk/system/subApp';
import './style.scss';

export default function ConversationSetting(props) {
    const locales = UdeskLocales['current'];

    const [loading, setLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [total, setTotal] = useState<number>(0);
    const [users, setUsers] = useState<UserFoundResponse[]>([]);
    const [searchValues, setSearchValues] = useState<any>({});
    const [activeKey, setActiveKey] = useState<'1' | '2'>(
        props?.location?.state?.recordType ?? '1'
    );

    const trigger = Udesk.hooks.useRequest(
        () => {
            setLoading(true);
            let params = {
                ...searchValues,
                pageSize,
                pageNum,
                recordType: Number(activeKey),
                systemModule: getSystemModule(),
            };
            if (searchValues.time && searchValues.time[0] && searchValues.time[1]) {
                params.startTime = searchValues.time[0];
                params.endTime = searchValues.time[1];
            }

            let progressUrl = Udesk.business.apiPath.concatApiPath(
                `voice-upload/progressUndone`,
                props.sdkOptions
            );
            Udesk.ajax.get(progressUrl).then(() =>
                postUploadRecordsResultsSearch(params).then(
                    (resp) => {
                        setLoading(false);
                        if (resp.data) {
                            setDataSource(resp.data);
                        }
                        if (resp.paging) {
                            setPageNum(resp.paging.pageNum ?? 1);
                            setPageSize(resp.paging.pageSize ?? 20);
                            setTotal(resp.paging.total ?? 0);
                        }
                    },
                    (reason) => {
                        setLoading(false);
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                )
            );
        },
        {
            autoRequestWhenComponentDidMount: true,
        }
    );

    const getColumns = () => {
        let columns: any = [
            {
                key: 'taskName',
                dataIndex: 'taskName',
                title:
                    `${activeKey}` === '2'
                        ? /* 分析任务 */ locales.pages.recordUpload.template.analysisTask
                        : locales.components.pages.recordUpload.list.task,
                // width: '10%',
                ellipsis: true,
            },
            {
                key: 'createUserUsername',
                dataIndex: 'createUserUsername',
                title: locales.components.pages.recordUpload.list.user,
            },
            {
                key: 'createTime',
                dataIndex: 'createTime',
                title: locales.components.pages.recordUpload.list.uploadTime,
                ellipsis: true,
                // width: '15%',
            },
            `${activeKey}` === '2' && {
                key: 'uploadFileName',
                dataIndex: 'uploadFileName',
                title: /* 上传文件名称 */ UdeskLocales['current'].pages.recordUpload.template
                    .uploadFileName,
                ellipsis: true,
                // width: '15%',
            },
            {
                key: 'endTime',
                dataIndex: 'endTime',
                title: locales.components.pages.recordUpload.list.endTime,
                // width: '15%',
                ellipsis: true,
            },
            {
                key: 'total',
                dataIndex: 'total',
                title: locales.components.pages.recordUpload.list.total,
                // width: '10%',
            },
            {
                key: 'success',
                dataIndex: 'success',
                title:
                    `${activeKey}` === '2'
                        ? /* 分析成功 */ locales.pages.recordUpload.template.analysisSucceeded
                        : locales.components.pages.recordUpload.list.success,
                // width: '10%',
            },
            {
                key: 'fail',
                dataIndex: 'fail',
                title:
                    `${activeKey}` === '2'
                        ? /* 分析失败 */ locales.pages.recordUpload.template.analysisFailed
                        : locales.components.pages.recordUpload.list.failed,
                // width: '10%',
            },
            {
                key: 'status',
                dataIndex: 'status',
                title:
                    `${activeKey}` === '2'
                        ? /* 分析进度 */ locales.pages.recordUpload.template.analyzeProgress
                        : locales.components.pages.recordUpload.list.progress,
                // width: '10%',
                render: (text, record, index) => (
                    <React.Fragment>
                        <Badge
                            status={record.status === 0 && record.total ? 'processing' : 'success'}
                        />
                        <span>
                            {record.status === 0 && record.total
                                ? locales.components.pages.recordUpload.list.inProgress
                                : locales.components.pages.recordUpload.list.finished}
                        </span>
                    </React.Fragment>
                ),
            },
        ].filter(Boolean);
        if (`${activeKey}` !== '2') {
            columns.push({
                key: 'actions',
                dataIndex: 'actions',
                title: locales.labels.action,
                // width: '10%',
                render: (text, record, index) => (
                    <Button
                        type="link"
                        size="small"
                        disabled={record.status === 0 && record.total}
                        onClick={(e) => {
                            e.stopPropagation();
                            getVoiceUploadResultById({
                                segments: { id: record.id },
                                responseType: 'blob',
                            } as any).then((res) => {
                                const elink = document.createElement('a');
                                elink.download = `${record.taskName}-${record.createTime}.xlsx`;
                                elink.style.display = 'none';
                                elink.href = URL.createObjectURL(res as any);
                                document.body.appendChild(elink);
                                elink.click();
                                URL.revokeObjectURL(elink.href); // 释放URL 对象
                                document.body.removeChild(elink);
                            });
                        }}
                    >
                        {locales.labels.download}
                    </Button>
                ),
            });
        }
        return columns;
    };
    const pageChange = (pageNum, pageSize) => {
        setPageNum(pageNum);
        setPageSize(pageSize);
        trigger();
    };
    const goToUpload = (id) => {
        let routeOptions = {
            history: props.history,
            routeName: getCurrentRouteName(
                `${activeKey}` === '1' ? 'recordUploadDetail' : 'recordUploadDetailIm'
            ),
            state: {
                id: id || null,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    useEffect(() => {
        getUsersFindAll().then((resp) => {
            setUsers(
                (resp.data ?? []).map((i) => {
                    return {
                        ...i,
                        label: i.realname,
                        value: i.id,
                    };
                })
            );
        });
    }, []);

    const filter: StandardizeTableProps['filter'] = {
        customComponents: { RangePicker: UdeskDatePicker },
        initialValues: {
            status: null,
        },
        onSearch: (value, o) => {
            setSearchValues(value);
            setPageNum(o.pagination.current);
            setPageSize(o.pagination.pageSize);
            trigger();
        },
        fields: [
            {
                type: 'RangePicker',
                label: /* 上传时间 */ locales.pages.recordUpload.template.uploadTime,
                name: 'time',
            },
            {
                type: 'Input',
                label: /* 任务名称 */ locales.pages.recordUpload.template.taskName,
                name: 'taskName',
                props: {
                    placeholder: locales.labels.pleaseEnter,
                },
            },
            {
                type: 'Select',
                label: /* 操作人 */ locales.pages.recordUpload.template.operator,
                name: 'createUserId',
                props: {
                    options: users,
                    optionFilterProp: 'label',
                    showSearch: true,
                    style: {
                        width: 125,
                    },
                    placeholder: locales.labels.pleaseSelect,
                },
            },
            {
                type: 'Select',
                label:
                    `${activeKey}` === '2'
                        ? /* 分析进度 */ locales.pages.recordUpload.template.analyzeProgress
                        : /* 质检进度 */ locales.pages.recordUpload.template
                              .qualityInspectionProgress,
                name: 'status',
                props: {
                    options: [
                        {
                            label: /* 全部 */ locales.pages.recordUpload.template.whole,
                            value: null,
                        },
                        {
                            label: /* 进行中 */ locales.pages.recordUpload.template.haveInHand,
                            value: 0,
                        },
                        {
                            label: /* 已完成 */ locales.pages.recordUpload.template.completed,
                            value: 1,
                        },
                    ],
                    placeholder: locales.labels.pleaseSelect,
                },
            },
        ],
    };
    const exportResult = () => {
        let params = {
            ...searchValues,
            status: 1,
            recordType: Number(activeKey),
            systemModule: getSystemModule(),
        };
        if (searchValues.time && searchValues.time[0] && searchValues.time[1]) {
            params.startTime = searchValues.time[0];
            params.endTime = searchValues.time[1];
        }

        return postUploadRecordsResultsExport(params);
    };

    const onActiveKeyChange = (activeKey) => {
        setActiveKey(activeKey);
        setPageNum(1);
        trigger();
    };
    return (
        <Page
            pageBodyClassName="record-upload-history-list-page"
            title={/* 本地数据上传 */ locales.pages.recordUpload.template.localDataUpload}
            padding={false}
            backGroundGhost={true}
            footer={
                <Tabs activeKey={`${activeKey}`} onChange={onActiveKeyChange}>
                    <Tabs.TabPane
                        key="1"
                        tab={/* 录音上传 */ locales.pages.recordUpload.template.recordingUpload}
                    ></Tabs.TabPane>
                    {isSalesSubApp() ? null : (
                        <Tabs.TabPane
                            key="2"
                            tab={/* 文本上传 */ locales.pages.recordUpload.template.textUpload}
                        ></Tabs.TabPane>
                    )}
                </Tabs>
            }
            // loading={loading}
        >
            <StandardizeTable
                // autoTriggerOnSearchOnMount={true}
                filter={filter}
                table={{
                    dataSource,
                    columns: getColumns(),
                    onRow: (record) => ({
                        onClick: (event) => {
                            if (!record.total || record.status === 1) return;
                            goToUpload(record.id);
                        }, // 点击行
                    }),
                    loading,
                    pagination: {
                        current: pageNum,
                        pageSize,
                        total,
                        onChange: pageChange,
                    },
                }}
                exportBtn={{
                    onClick: exportResult,
                    goToDownloadCenter: () =>
                        window.open(
                            `/${isSalesSubApp() ? 'sales' : 'site'}/admin/task-center/download-list`
                        ),
                    confirm: {
                        title: /* 是否导出当前筛选条件下所有已完成的数据记录？ */ locales.pages
                            .recordUpload.template
                            .exportAllCompletedDataRecordsUnderTheCurrentFilterCondition,
                    },
                    disabled: searchValues.status === 0,
                }}
                toolBarRightButtons={[
                    {
                        mainBtn: true,
                        label: /* 上传 */ locales.pages.recordUpload.template.upload,
                        onClick: goToUpload.bind(null, null),
                    },
                ]}
            />
        </Page>
    );
}
