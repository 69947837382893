import React, { useState, useCallback } from 'react';
import Udesk from 'Udesk';
import { Button, Space, Modal, Badge, Spin, Popconfirm } from 'udesk-ui';
import Locales from 'UdeskLocales';

import './style.scss';


const Item = (props) => {
    const { label, value } = props;
    return (
        <div className='appeal-history-component-item-body-row'>
            <div className='appeal-history-component-item-body-row-label'>{label}:</div>
            <div className='appeal-history-component-item-body-row-value'>{value}</div>
        </div>
    );
};

const DetailItem = (props) => {
    const locales = Locales['current'];

    const appealHistory = locales.components.pages.appealHistory;

    const { firstCategoryName, secondCategoryName, point } = props;
    const color =
        point.complainStatus === Udesk.enums.complainStatus.success.id
            ? '#0FBA26'
            : point.complainStatus === Udesk.enums.complainStatus.fail.id
                ? '#FB5127'
                : 'rgba(0, 0, 0, 0.25)';
    return (
        <div className='appeal-history-component-detail-item'>
            <div className='appeal-history-component-detail-item-title'>
                {`${firstCategoryName} > ${secondCategoryName}`}
            </div>
            <div className='appeal-history-component-detail-item-content'>
                <div>{point.name}</div>
                <Space size={0}>
                    <Badge color={color} />
                    <div>{Udesk.enums.complainStatus.getName(point.complainStatus || 1)}</div>
                </Space>
            </div>
            <div className='appeal-history-component-detail-item-content'>
                <Space>
                    <div>{`${appealHistory.reason}:`}</div>
                    <div className='appeal-history-component-detail-item-content-value'>{point.complaint}</div>
                </Space>
            </div>
            {point.rejection && (
                <div className='appeal-history-component-detail-item-content'>
                    <Space>
                        <div>{`${appealHistory.rejection}:`}</div>
                        <div className='appeal-history-component-detail-item-content-value'>{point.rejection}</div>
                    </Space>
                </div>
            )}
        </div>
    );
};
const AppealHistory: React.FC<any> = (props) => {
    const locales = Locales['current'];

    const appealHistory = locales.components.pages.appealHistory;

    const { history = [], sdkOptions, refershHistory, refreshList } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [historyDetail, setHistoryDetail] = useState<any>({});

    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, []);
    const openModal = useCallback(({ id }) => {
        setModalVisible(true);
        setLoading(true);
        Udesk.business.sdkAjax.get(`open_api_v1/complaint/${id}`, sdkOptions, { props }).then(
            (resp) => {
                setLoading(false);
                setHistoryDetail(resp.data || {});
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, []);

    const revoke = useCallback(({ id }) => {
        Udesk.business.sdkAjax.post(`open_api_v1/complaint/${id}/cancel`, sdkOptions, { props }).then(
            (resp) => {
                refershHistory?.();
                refreshList?.();
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
            }
        );
    }, []);
    
    return (
        <div className='appeal-history-component'>
            {history.map((item) => {
                const color =
                    item.complainStatus === Udesk.enums.flowStatus.complaining.id
                        ? '#F09A00'
                        : item.complainStatus === Udesk.enums.flowStatus.reviewed.id
                            ? '#1A6EFF'
                            : 'rgba(0, 0, 0, 0.25)';
                return (
                    <div className='appeal-history-component-item'>
                        <div className='appeal-history-component-item-body'>
                            <div>
                                <div className='appeal-history-component-item-body-row space-between'>
                                    <Space>
                                        <div className='appeal-history-component-item-body-row-label'>
                                            {appealHistory.inspector}:{' '}
                                        </div>
                                        <div className='appeal-history-component-item-body-row-value'>
                                            {item.inspector}
                                        </div>
                                    </Space>
                                    <Space>
                                        <div className='appeal-history-component-item-body-row-value'>
                                            <Space size={0}>
                                                <Badge color={color} />
                                                <div>{Udesk.enums.flowStatus.getName(item.complainStatus)}</div>
                                            </Space>
                                        </div>
                                        <div className='appeal-history-component-item-body-row-buttons'>
                                            <Space>
                                                {item.complainStatus === Udesk.enums.flowStatus.complaining.id && (
                                                    <Popconfirm title={appealHistory.tip} okText={appealHistory.cancel} cancelText={locales.labels.cancel} onConfirm={revoke.bind(null, item)}>
                                                        <Button type='link' size='small'>
                                                            {appealHistory.cancel}
                                                        </Button>
                                                    </Popconfirm>
                                                )}
                                                <Button type='link' size='small' onClick={openModal.bind(null, item)}>
                                                    {locales.labels.detail}
                                                </Button>
                                            </Space>
                                        </div>
                                    </Space>
                                </div>
                                <Item label={appealHistory.appealTime} value={item.complainTime} />
                                {item.complainStatus === Udesk.enums.flowStatus.complainCancel.id && <Item label={appealHistory.cancelTime} value={item.updateTime} />}
                                {item.complainStatus === Udesk.enums.flowStatus.reviewed.id && (
                                    <Item label={appealHistory.reviewTime} value={item.updateTime} />
                                )}
                                <Item
                                    label={appealHistory.result}
                                    value={Udesk.utils.string.format(
                                        appealHistory.complainResult,
                                        item.pointCount,
                                        item.successCount,
                                        item.failedCount
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
            <Modal
                onCancel={closeModal}
                visible={modalVisible}
                title={locales.labels.appealDetail}
                footer={[
                    <Button type='primary' key='back' onClick={closeModal}>
                        {locales.labels.confirm}
                    </Button>,
                ]}
                wrapClassName='appeal-history-component-detail-modal'
            >
                <Spin spinning={loading}>
                    <div>
                        {(historyDetail?.pointCategoryList || []).map((firstCategory) => {
                            return (firstCategory.children || []).map((secondCategory) => {
                                return (secondCategory.pointDataList || []).map((point) => {
                                    return (
                                        <DetailItem
                                            firstCategoryName={firstCategory.name}
                                            secondCategoryName={secondCategory.name}
                                            point={point}
                                        />
                                    );
                                });
                            });
                        })}
                    </div>
                </Spin>
            </Modal>
        </div>
    );
};

export default AppealHistory;
