import React from 'react';
import Udesk from 'Udesk';
import InspectionDetail from '../../components/inspection-detail';
import QualityInspectionListDetailRoute from './route';
import ReInspectionModel from 'Component/pages/components/reinspection-model';
import '../style.scss';
import './style.scss';
import { PageHeader, Button, Space, Modal, Icon, Tabs, Popconfirm } from 'udesk-ui';
import ClassNames from 'classnames';
import SamplingTargetBanner from 'Component/pages/components/sampling-target-banner';
import ReInspectionResultModel from 'Component/pages/components/re-inspection-result-modal';

const { TabPane } = Tabs;

const LOCAL_STORAGE_KEY = 'spotCheckScoreSubmittedPopconfirmShowed';

const noop = () => {};
export default class QualityInspectionListDetailTemplate extends QualityInspectionListDetailRoute {
    render() {
        let { match, sdkOptions, location } = this.props;
        let {
            detail,
            smartTags,
            changeList,
            qualityCheckScoreSaving,
            caseBase,
            relatedDatas,
            relatedLoading,
            testSetList,
            testSetListFlag,
            pointChildrenComputer,
            pointChildrenPerson,
            resetStatus,
            evaluteVisibe,
            activeFlag,
            dialogRemarkList,
            enabledEvaluate,
            isWechatQa,
            callDetailsList,
            activeCallId,
            related,
            relatedRecordsLoading,
            renderLoading,
            wrapDom,
            isInformationCollect,
            allPoints,
            reInspectionConfigVisible,
            reInspectionPoints,
            reInspectionType,
            reCheckLoading,
            reInspectionModalVisible,
            // stateParamsType,
            // circlePercent,
            showHit,
            samplingTargetBannerKey,
        } = this.privates;
        let { relatedDatasPaging } = this.privates.storages;
        let { actions, locales } = this;
        let hasFeature = sdkOptions.props.task ? sdkOptions.props.task._hasFeature : null;

        return (
            // <div className="quality-inspection-list-detail-page">
            <div className='udesk-qa-web-page'>
                <PageHeader
                    className='udesk-qa-web-page-header quality-inspection-list-detail-header-footer'
                    onBack={actions.backToIndexPage}
                    title={
                        locales.components.pages.qualityInspectionList.detail[
                            isWechatQa ? 'wechatTitle' : isInformationCollect ? 'titleCollect' : 'title'
                        ]
                    }
                    footer={
                        related ? (
                            <Tabs activeKey={activeCallId} size='small' onChange={actions.changeCallDetail}>
                                {callDetailsList.map((detail, index) => (
                                    <TabPane
                                        tab={
                                            <div title={detail.callId}>
                                                {
                                                    locales.components.pages.qualityInspectionList.detail[
                                                        detail.inspectDataSource ===
                                                        Udesk.enums.inspectDataSources.voiceCall
                                                            ? 'callIndex'
                                                            : 'textIndex'
                                                    ]
                                                }
                                                {index + 1}
                                            </div>
                                        }
                                        key={detail.callId}
                                    />
                                ))}
                            </Tabs>
                        ) : null
                    }
                    extra={
                        <If condition={hasFeature && hasFeature('task:work:inpectionList:view')}>
                            <Space>
                                <Button
                                    // disabled={detail.previousId == null}
                                    onClick={actions.changePages.params(1)}
                                    loading={renderLoading && { delay: 400 }}
                                >
                                    {locales.labels.lastPage}
                                </Button>
                                <Button
                                    // disabled={detail.nextId == null}
                                    onClick={actions.changePages.params(2)}
                                    loading={renderLoading && { delay: 400 }}
                                >
                                    {locales.labels.nextPage}
                                </Button>
                                <If
                                    condition={
                                        !testSetList &&
                                        !testSetListFlag &&
                                        detail.status != null &&
                                        detail.status !== Udesk.enums.gradeStatus.alreadyFiled.id &&
                                        !this.privates.testSetListFlag &&
                                        !detail.isHideText
                                        // stateParamsType === 'only'
                                    }
                                >
                                    {this.props.route.name === 'qualityInspectionListDetail' && (
                                        <Button onClick={actions.changeReInspectionConfigVisible}>
                                            {locales.components.pages.qualityInspectionList.reQualityInspection}
                                        </Button>
                                    )}
                                    <If
                                        condition={
                                            hasFeature &&
                                            hasFeature('task:work:inpectionList:fastSample')
                                        }
                                    >
                                        <Button disabled={qualityCheckScoreSaving} onClick={actions.cancel}>
                                            {locales.labels.cancel}
                                        </Button>
                                        <Popconfirm
                                            visible={!localStorage.getItem(LOCAL_STORAGE_KEY)}
                                            icon={<Icon antdIcon={true} type='InfoCircleTwoTone' />}
                                            title={/* 新功能！点击 ‘快速抽检’ 按钮，提交成功后会自动跳转到下一页啦~~~ */locales.pages.qualityInspectionList.detail.template.newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage}
                                            onConfirm={() => {
                                                localStorage.setItem(LOCAL_STORAGE_KEY, true);
                                                this.actions.update();
                                            }}
                                            showCancel={false}
                                            disabled={true}
                                            placement='bottomRight'
                                            cancelButtonProps={{ style: { display: 'none' } }}
                                        >
                                            <Button
                                                disabled={qualityCheckScoreSaving}
                                                type='primary'
                                                onClick={actions.save}
                                            >
                                                {locales.labels.rapidSampling}
                                            </Button>
                                        </Popconfirm>
                                    </If>
                                    <If condition={hasFeature && hasFeature('task:work:inpectionList:gradeFile')}>
                                        <Button
                                            disabled={qualityCheckScoreSaving}
                                            type='primary'
                                            onClick={actions.openSubmitModal}
                                        >
                                            {locales.labels.file}
                                        </Button>
                                    </If>
                                </If>
                            </Space>
                        </If>
                    }
                />
                <SamplingTargetBanner
                    id={match.params.id}
                    taskId={match.params.taskId}
                    sourceType={1}
                    key={samplingTargetBannerKey}
                />
                <div className='udesk-qa-web-page-body' ref={wrapDom}>
                    <div className='udesk-qa-web-page-body-root quality-inspection-list-detail-page'>
                        <div className='udesk-qa-layout-main-page'>
                            <Modal
                                visible={evaluteVisibe && enabledEvaluate}
                                closable={false}
                                onOk={actions.evaluateSave}
                                okText={locales.fix.continueToSubmitWithoutEvaluation}
                                onCancel={actions.cancelSave}
                                mask={false}
                            >
                                <div className='quality-check-score-talk-header-eveluation-person'>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '16px' }}>
                                        {locales.fix.manualEvaluation}：
                                    </span>
                                    <Space>
                                        <Icon
                                            className={ClassNames({
                                                'quality-check-score-talk-header-xiao': activeFlag === 'true',
                                            })}
                                            type='xiaolian'
                                            onClick={actions.activeChange.params('true')}
                                        />
                                        <Icon
                                            className={ClassNames({
                                                'quality-check-score-talk-header-ku': activeFlag === 'false',
                                            })}
                                            type='kulian'
                                            onClick={actions.activeChange.params('false')}
                                        />
                                    </Space>
                                </div>
                            </Modal>
                            <div className='udesk-qa-layout-main-page-body'>
                                <div className='quality-inspection-list-detail-page-body qa-react-page-body'>
                                    <If condition={hasFeature && hasFeature('task:work:inpectionList:view')}>
                                        {/* <Spin spinning={renderLoading}> */}
                                        <InspectionDetail
                                            rerenderOptimization={true}
                                            evaluateChange={actions.evaluateChange}
                                            resetStatus={resetStatus}
                                            handMarkMap={actions.handMarkMap}
                                            pointChildrenComputer={pointChildrenComputer}
                                            pointChildrenPerson={pointChildrenPerson}
                                            keywords={sdkOptions.props._keywords}
                                            testSetList={this.privates.testSetList}
                                            testSetListFlag={this.privates.testSetListFlag}
                                            caseBaseStatus={true}
                                            onUpdate={actions.onUpdate}
                                            id={match.params.id}
                                            caseBase={caseBase}
                                            caseLibraryId={location.search}
                                            taskId={match.params.taskId}
                                            hasFeature={hasFeature}
                                            detail={detail}
                                            smartTags={smartTags}
                                            changeList={changeList}
                                            qualityCheckScoreSaving={qualityCheckScoreSaving}
                                            onQualityCheckScoreCancel={actions.qualityCheckScoreCanceled}
                                            onQualityCheckScoreSave={actions.qualityCheckScoreSaved}
                                            onQualityCheckScoreSubmit={actions.qualityCheckScoreSubmitted}
                                            relatedDatas={relatedDatas}
                                            relatedDatasPaging={relatedDatasPaging}
                                            parentLoadMore={actions.parentLoadMore}
                                            relatedLoading={relatedLoading}
                                            dialogRemarkList={dialogRemarkList}
                                            changeDialogRemarkList={actions.changeDialogRemarkList}
                                            querryChangeList={actions.querryChangeList}
                                            querrySmartTags={actions.querrySmartTags}
                                            isWechatQa={this.privates.isWechatQa}
                                            changeToHighlightItemsCallDetail={related ? actions.changeCallDetail : noop}
                                            // onUpdated={actions.onUpdated}
                                            refresh={actions.reloadAsyncModel}
                                            // rerenderOptimization={true}
                                            requestRelatedRecords={actions.requestRelatedRecords}
                                            relatedRecordsLoading={relatedRecordsLoading}
                                            loading={renderLoading}
                                            isInformationCollect={isInformationCollect}
                                            trainRecords={
                                                this.props.route.name === 'qualityInspectionListDetail'
                                                    ? actions.trainRecords
                                                    : undefined
                                            }
                                            showHit={showHit}
                                            onShowHitChange={actions.showHitChange}
                                            indexPointTag={!related ? detail.indexPointTag : this.privates.relatedIndexPointTagMap[activeCallId]}
                                            onRedoSmartTags={actions.redoSmartTags}
                                        />
                                        {/* </Spin> */}
                                    </If>
                                </div>
                            </div>
                            <ReInspectionModel
                                title={locales.components.pages.qualityInspectionList.reQualityInspectionConfig}
                                allPoints={allPoints}
                                reInspectionConfigVisible={reInspectionConfigVisible}
                                reInspectionPoints={reInspectionPoints}
                                reInspectionType={reInspectionType}
                                onOk={actions.reInspection}
                                onPointsCheckedChange={actions.onPointsCheckedChange}
                                reInspectionTypeChanged={actions.reInspectionTypeChanged}
                                onCancel={actions.changeReInspectionConfigVisible}
                                okButtonLoading={reCheckLoading}
                            />
                            {/* <Modal onCancel={actions.handlerModalProgressCancel} className='custom-modal-progress-style' visible={reInspectionModalVisible} title="" footer={null}>
                                <Progress type="circle" percent={circlePercent} />
                            </Modal> */}
                            <ReInspectionResultModel
                                history={this.props.history}
                                inspectionId={this.privates.task?.id}
                                visible={reInspectionModalVisible}
                                onVisibleChange={(visible) => {
                                    this.privates.reInspectionModalVisible = visible;
                                    this.actions.update();
                                }}
                                taskId={parseInt(match.params.taskId, 10)}
                                onSuccess={() => {
                                    this.actions.reloadAsyncModel();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
