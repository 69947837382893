import React, { useRef } from 'react';
import { Progress, Tooltip, Icon } from 'udesk-ui';

const ProgressComponent = (props) => {
    let { percent } = props;
    if (!percent || isNaN(percent)) percent = 0;
    const progressInstance = useRef(null);

    const left = progressInstance.current
        ? ((parseInt(window.getComputedStyle(progressInstance.current).width, 10) - 48) * percent) / 100 + 24
        : 0;
    return (
        <div className='progress-component' ref={progressInstance}>
            <div className='progress-component-title'>{props.title}</div>
            <div className='progress-component-percent' style={{ color: props.strokeColor }}>
                {props.isTime ? `${percent} ${props.suffix || ''}` : `${percent}%`}
            </div>
            <Progress
                strokeWidth={2}
                percent={percent}
                strokeLinecap='square'
                strokeColor={props.strokeColor}
                success={{ strokeColor: props.strokeColor }}
                trailColor='rgba(0, 0, 0, 0.1)'
                showInfo={false}
            />
            <If condition={props.count}>
                <If condition={props.count[0]}>
                    <Tooltip
                        title={
                            <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{`${props.desc[0]}: ${props.count[0]}`}</div>
                        }
                        color='#fff'
                    >
                        <span
                            style={{ left: left, color: props.strokeColor, bottom: 10 }}
                            className='progress-component-percent-count'
                        >
                            {props.count[0] || '0'}
                        </span>
                    </Tooltip>
                    <Icon
                        className='progress-component-percent-count'
                        type='CaretUpOutlined'
                        style={{ bottom: '-3px', color: props.strokeColor, left: left }}
                        antdIcon
                    />
                </If>
                <Tooltip
                    title={<div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{`${props.desc[1]}: ${props.count[1]}`}</div>}
                    color='#fff'
                >
                    <span className='progress-component-percent-count'>{props.count[1] || '0'}</span>
                </Tooltip>
            </If>
        </div>
    );
};

export default ProgressComponent;
