import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import React from 'react';
import { getCurrentRouteName } from 'Udesk/system/subApp';
export default class DetailComponent extends React.Component {
    //#region Computed properties
    static computes = {
        isEditing: [
            'privates.model.userGroup',
            function({ props, state, privates, locales }) {
                return privates.model && privates.model.userGroup.id > 0;
            }
        ],
        _autoCompleteUrl: function({ props, state, privates, locales }) {
            return Udesk.business.apiPath.concatApiPath('/users?pageSize=1000', props.sdkOptions);
        }
    };
    //#endregion

    //#region actions
    actions = {
        onValueChanged(obj, key, e) {
            let value = e;
            if (e && e.target) {
                value = e.target.value;
            }
            obj[key] = value;
            this.actions.update();
        },

        save() {
            let userGroup = Object.assign({}, this.privates.model.userGroup);
            if (userGroup.name == null || userGroup.name === '') {
                return Udesk.ui.notify.error(
                    Udesk.utils.string.format(
                        UdeskLocales.current.business.info.cannotBeNull,
                        UdeskLocales.current.columns.admin.userGroups.name
                    )
                );
            }
            let isEditing = this.privates.computes.isEditing;
            let url = isEditing
                ? Udesk.business.apiPath.concatApiPath(`/userGroup/${userGroup.id}`, this.props.sdkOptions)
                : Udesk.business.apiPath.concatApiPath(`/userGroup`, this.props.sdkOptions);
            let method = isEditing ? Udesk.enums.requestMethods.update.name : Udesk.enums.requestMethods.create.name;
            Udesk.ajax(url, userGroup, method).then(
                (resp, textStatus, jqXHR) => {
                    this.actions.userGroupsHistoryBack();
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.notifyMessage.saveFailedFormat, ''));
                }
            );
        },

        userGroupsHistoryBack() {
            let routeOptions = {
                history: this.props.history,
                routeName: getCurrentRouteName('adminUserGroupsIndex')
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        }
    };
    //#endregion
}
