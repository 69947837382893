import React, { useState, useCallback, useRef, useEffect } from 'react';
import Udesk from 'Udesk';
import CustomFilters from './customFilters';
import CustomFilterModalComponent from './component';
import './style.scss';
import { Modal, Button, Icon, Input, Collapse, Radio, Tabs, Menu } from 'udesk-ui';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import _isEmpty from 'lodash-es/isEmpty';
import { fire } from 'src/util/core';
const { Panel } = Collapse;
const { TabPane } = Tabs;
const grid = 8;
// 设置样式
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    width: '32px',
    height: '24px',
    color: 'rgba(0,0,0,0.65)',
    borderRadius: '3px',
    margin: `0 ${grid}px 0 0 `,
    textAlign: 'center',
    lineHeight: '24px',
    // 拖拽的时候背景变化
    background: isDragging ? "lightgreen" : "rgba(232,244,255,1)",
    marginBottom: '3px',
    ...draggableStyle
});

const getListStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    width: '745px',
});
const getListDropStyle = () => ({
    background: '#fff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    width: '745px',
    border: '1px solid #e8e8e8',
    minHeight: '40px'
});
const DraggableComponent = (props) => {
    const [, drag] = useDrag({
        item: { type: 'cards', name: `${props.name}`, ...props, },
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                delete item.index;
            }
        }
    });
    return (
        <div ref={drag} className='react-qa-custom-filter-body-left-info-field'>
            <Icon type="list-check" />
            <span title={props.name} className='react-qa-custom-filter-body-left-info-text'>{props.name}</span>
        </div>
    );
};
const MyDropTarget = (props) => {
    let ref = useRef(null);
    let customFiltersRef = useRef(null);
    let [list, setList] = useState(props.displayConditionList || []);

    const {operatorsFilter, fields} = props;

    const customFiltersRefed = useCallback(() => {
        customFiltersRef && customFiltersRef.current && (customFiltersRef.current.privates.dirtyFullConditions = null);
    }, []);

    const filterContidionChanged = useCallback((condition) => {
        setList(condition);
        props.filterContidionChange && props.filterContidionChange(condition);
    }, [
        props
    ]);
    
    const [, drop] = useDrop({
        accept: 'cards',
        drop: (item, monitor) => {
            customFiltersRefed();
            const field = fields.find(field => field.key === item.id);
            let defaultOperator = 'is';
            if (field) {
                const operators = fire(operatorsFilter, field.operators, item.id) || field.operators;
                if (operators && operators.length > 0) {
                    defaultOperator = operators[0].id;
                }
            }
            // 此处为遗留逻辑，暂时保留，如果发现此次只是为了获取第一个操作符的key， 可去掉。
            if (item.fieldType === 16) {
                defaultOperator = 'contains_all';
            }
            const temp = list || [];

            setList([
                ...temp,
                { field: item.id, operator: defaultOperator, value: null }
            ]);
            props.filterContidionChange && props.filterContidionChange([
                ...temp,
                { field: item.id, operator: defaultOperator, value: null }
            ]);
        },
    });

    useEffect(() => {
        if (props.displayConditionList.length === 0) {
            customFiltersRefed();
            setList([]);
        } else {
            setList(props.displayConditionList);
        }
    }, [
        customFiltersRefed, 
        props.displayConditionList
    ]);

    drop(ref);
    return (
        <div ref={ref} style={{ height: '100%' }}>
            {
                props.fields && props.fields.length > 0 && (
                    <CustomFilters 
                        ref={customFiltersRef} 
                        allowEmptyConditions={true} 
                        conditions={list} 
                        fields={props.fields} 
                        fieldValueSettings={props.fieldValueSettings} 
                        theme="bootstrap" 
                        onChanged={filterContidionChanged} 
                        fieldSelectorClass="form-control input-sm" 
                        operatorSelectorClass="form-control input-sm" 
                        languageCode={props.sdkOptions.props.language} 
                        fieldNameField="key" 
                        fieldKeyField="key" 
                        fieldTextField="label" 
                        showConditionNumber={true} 
                        postfixComponentRenderInLine={props.postfixComponentRenderInLine}
                        conditionPostfixComponent={props.conditionPostfixComponent}
                        operatorsFilter={props.operatorsFilter}
                        isAutoHideOperator={props.isAutoHideOperator}/>
                )
            }
        </div>
    );
};
const CustomInfo = (props) => {
    let [customDataSourceList, setCustomDataSourceList] = useState(props.customDataSourceList || []);
    let [filterKeyWord, setFilterKeyWord] = useState('');
    useEffect(() => {
        setCustomDataSourceList(props.customDataSourceList);
    }, [props]);
    return (
        <div>
            <Input.Search placeholder={props.locales.filterPlaceholder} onChange={(e) => {!e.target.value && setFilterKeyWord('');}} onSearch={(value) => setFilterKeyWord(value)}></Input.Search>
            {
                customDataSourceList && customDataSourceList.length > 0 && (
                    <Collapse
                        bordered={false}
                        expandIconPosition='right'
                        defaultActiveKey={customDataSourceList.filter((item, index) => index < 3).map(item => item.id)}
                    >
                        {
                            customDataSourceList && customDataSourceList.length > 0 && customDataSourceList.map(item => {
                                let fieldList = [];
                                if(item.fieldList && item.fieldList.length > 0){
                                    fieldList = item.fieldList.filter(items => filterKeyWord ? items.label.includes(filterKeyWord) : true);
                                }
                                if(!fieldList.length) return null;
                                return (
                                    <Panel header={(<div className='panel-header-title'>{item.name}</div>)} key={item.id}>
                                        <div>
                                            {
                                                fieldList.map((items, index) => {
                                                    return (
                                                        <DraggableComponent key={`${items.id}-${index}`} id={items.id} name={items.label} fieldType={items.fieldType} />
                                                    );
                                                })
                                            }
                                        </div>
                                    </Panel>
                                );
                            })
                        }
                    </Collapse>

                )
            }
        </div>

    );

};
export default class CustomFilterModalTemplate extends CustomFilterModalComponent {
    render() {
        let {
            sdkOptions,
            customTypeList,
            businessFlag,
            conditionPostfixComponent,
            postfixComponentRenderInLine,
            operatorsFilter,
            isAutoHideOperator,
        } = this.props;
        let {
            displayConditionList,
            judgeStrategy,
            customJudgeLogic,
            customeFilterFieldKey,
            conditionListFilter,
            logicList,
            customType,
            customName,
            customFilterList,
            customeFilterField,
            // customEdit,
            customDataSourceList,
            // nameCount,
            tarskDel,
            myDropTargetRef,
            // customInputSearchValve
        } = this.privates;
        let {
            _fields,
            _fieldValueSettings,
        } = this.privates.computes;
        let {
            actions,
            locales,
        } = this;
        let footerButton = (
            <div>
                {
                    customType === customTypeList.customTypeShow && (
                        <div>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("st:delete")}>
                                <Button onClick={actions.handleConditionDel}>{locales.components.common.customFilter.del}</Button>
                            </If>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("st:edit")}>
                                <Button onClick={actions.handleConditionEdit}>{locales.components.common.customFilter.edit}</Button>
                            </If>
                            <If condition={!businessFlag}>
                                <Button type='primary' onClick={actions.handleConditionApplyField}>{locales.components.common.customFilter.apply}</Button>
                            </If>
                        </div>

                    )
                }
                {
                    customType === customTypeList.customTypeEdit && (
                        <div>
                            <Button onClick={actions.handleConditionCancel}>{locales.components.common.customFilter.cancel}</Button>
                            <If condition={!businessFlag}>
                                <Button type={customName ? 'default' : 'primary'} onClick={actions.handleConditionApply}>{locales.components.common.customFilter.onlyApply}</Button>
                            </If>

                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("st:add")}>
                                {/* <If condition={!businessFlag}>
                                    <Button onClick={actions.handleConditionOtherSave}>{locales.components.common.customFilter.ohterSave}</Button>
                                </If> */}
                                <Button type={!customName ? 'default' : 'primary'} onClick={actions.handleConditionSave}>{locales.components.common.customFilter.save}</Button>
                            </If>

                        </div>
                    )
                }
            </div>

        );

        let filterTitle = (
            <div className='custom-filter-modal-title-node'>
                <div className='custom-filter-modal-title-node-left-info'>
                    <Tabs activeKey={`${customType}`} onChange={actions.customStaus}>
                        <TabPane tab={(<span>{locales.components.common.customFilter.new}</span>)} key={1}>
                        </TabPane>
                        <TabPane tab={(<span>{locales.components.common.customFilter.customFilter}</span>)} key={2}>
                        </TabPane>
                    </Tabs>
                    {/* {
                        customType === customTypeList.customTypeEdit && (<span className='custom-filter-modal-titile-node-left-info-title'>{customEdit ? `${locales.components.common.customFilter.edit}` : `${locales.components.common.customFilter.new}`}</span>)
                    }
                    {
                        customType === customTypeList.customTypeShow && (<span className='custom-filter-modal-titile-node-left-info-title' onClick={actions.customStaus.params(1)}>
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-jiantou2"></i>
                            {locales.components.common.customFilter.customFilter}
                        </span>)
                    }
                    {
                        customType === customTypeList.customTypeEdit && (<span className='custom-filter-modal-titile-node-left-info-action' onClick={actions.customStaus.params(2)}>{locales.components.common.customFilter.customFilter}</span>)
                    } */}

                </div>
                <div className='custom-filter-modal-title-node-right-action'>
                    <Button onClick={actions.reset}>{locales.components.common.customFilter.reset}</Button>
                </div>
            </div>
        );
        return (
            <Modal
                visible={this.props.visible}
                title={filterTitle}
                footer={footerButton}
                wrapClassName='react-qa-custom-filters'
                onCancel={actions.handleConditionCancel}
                destroyOnClose
                maskStyle={{ transition: 'none'}}
            >
                <DndProvider backend={HTML5Backend}>
                    <div className='react-qa-custom-filters-body'>
                        <div className='react-qa-custom-filter-body-left-info' style={{ display: customType === customTypeList.customTypeEdit ? 'block' : 'none' }}>
                            <div>
                                <div>
                                    <CustomInfo customDataSourceList={customDataSourceList} filterFieldList={actions.filterFieldList} locales={locales.components.common.customFilter} />
                                </div>
                            </div>
                        </div>
                        <div className='custom-fiter-custom-type-show' style={{ display: customType === customTypeList.customTypeShow ? 'block' : 'none' }}>
                            <div style={{ padding: '10px 16px 10px 0', }}>
                                <Input.Search placeholder={locales.components.common.customFilter.holderKeyWord} onChange={actions.customInputSearchChange} onSearch={actions.customInputSearch} />
                            </div>
                            <Menu
                                mode='vertical'
                                theme='light'
                                selectedKeys={customeFilterFieldKey}
                                style={{ width: '210px', height: '100%' }}
                                onClick={actions.menuKeysChange}
                            >
                                {
                                    customFilterList && customFilterList.length > 0 && customFilterList.map((item, index) => {
                                        return (
                                            <Menu.Item key={`${item.id}-${index}`} disabled={!item.status}>
                                                {item.name}
                                            </Menu.Item>
                                        );
                                    })
                                }
                            </Menu>
                        </div>



                        <div className='react-qa-custom-filter-body-right-info'>
                            <div className='react-qa-custom-filter-body-right-info-field'>
                                <div className='react-qa-custom-filter-body-right-info-title'>
                                    <span className='react-qa-custom-filter-body-right-info-title-name'>{locales.components.common.customFilter.customFilterName}</span>
                                    {
                                        customType === customTypeList.customTypeEdit && (
                                            <Input value={customName} onChange={actions.customInputChange} showCount={true} maxCount={500} maxLength={500} placeholder={locales.components.common.customFilter.holderCustomFilterName} style={{ width: '200px' }} />
                                        )
                                    }
                                    {
                                        customType === customTypeList.customTypeShow && (
                                            <span>{!_isEmpty(customeFilterField) && customeFilterField.name}</span>
                                        )
                                    }
                                </div>

                                <div style={{ height: 'calc(100% - 32px)', display: customType === customTypeList.customTypeEdit ? 'block' : 'none' }}>
                                    <MyDropTarget 
                                        postfixComponentRenderInLine={postfixComponentRenderInLine}
                                        conditionPostfixComponent={conditionPostfixComponent}
                                        operatorsFilter={operatorsFilter}
                                        isAutoHideOperator={isAutoHideOperator}
                                        refField={myDropTargetRef} 
                                        filterContidionChange={actions.filterContidionChanged} 
                                        displayConditionList={displayConditionList} 
                                        fields={_fields} 
                                        fieldValueSettings={_fieldValueSettings} 
                                        sdkOptions={sdkOptions} />
                                </div>

                                {
                                    customType === customTypeList.customTypeShow && !_isEmpty(customeFilterField) && this.actions.fieldsFilterChange(customeFilterField)
                                }
                            </div>
                            {
                                customType === customTypeList.customTypeEdit && (
                                    <div className='react-qa-custom-filter-body-right-info-footer'>
                                        <div className='react-qa-custom-filter-body-right-info-title'>
                                            <span className='react-qa-custom-filter-body-right-info-title-name'>{locales.components.common.customFilter.customFilterRule}</span>
                                            <Radio.Group onChange={actions.changeJudgeStrategy} value={judgeStrategy}>
                                                {
                                                    Udesk.enums.operatorRuleTypes.map(item => {
                                                        return (
                                                            <Radio key={item.id} value={item.id}>{item.name}</Radio>
                                                        );
                                                    })
                                                }
                                            </Radio.Group>
                                        </div>
                                        {
                                            Udesk.enums.operatorRuleTypes.custom.id === judgeStrategy && (
                                                <Tabs defaultActiveKey="2" >
                                                    <TabPane tab={locales.components.common.customFilter.manualConfiguration} key="1">
                                                        <Input placeholder={locales.components.common.customFilter.holderCustomRule} value={customJudgeLogic} onChange={actions.customJudgeLogicChange} />
                                                        <div style={{ marginTop: '10px' }}>
                                                            {locales.components.common.customFilter.example}：1 or 2 and (3 and 4)
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tab={locales.components.common.customFilter.dragConfiguration} key="2">
                                                        <div>
                                                            <DragDropContext onDragEnd={actions.onDragEnd} onDragStart={actions.onDragStart}>
                                                                <div className='index-management-custom-drag'>
                                                                    <div className='react-qa-custom-filter-body-right-info-drap-title'>{locales.components.common.customFilter.condition}：</div>
                                                                    <div>
                                                                        <Droppable droppableId="droppables" direction="horizontal" isDropDisabled={true}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                                >
                                                                                    {conditionListFilter.map((item, index) => (
                                                                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided.draggableProps.style
                                                                                                    )}
                                                                                                >
                                                                                                    {item.content}
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </div>
                                                                </div>
                                                                <div className='index-management-custom-drag'>
                                                                    <div className='react-qa-custom-filter-body-right-info-drap-title'>{locales.components.common.customFilter.operation}：</div>
                                                                    <div>
                                                                        <Droppable droppableId="droppabless" direction="horizontal" isDropDisabled={true}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                    style={getListStyle(snapshot.isDragging)}
                                                                                >
                                                                                    {logicList.map((item, index) => (
                                                                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided.draggableProps.style
                                                                                                    )}
                                                                                                >
                                                                                                    {item.content}
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>

                                                                    </div>
                                                                </div>
                                                                <div className='index-management-custom-drag'>
                                                                    {
                                                                        tarskDel && <div className='index-manmagement-custom-drag-tarsk'>{locales.components.common.customFilter.infoDel}</div>
                                                                    }
                                                                    <div classNa me='react-qa-custom-filter-body-right-info-drap-title'>{locales.components.common.customFilter.toConfigure}：</div>
                                                                    <div>
                                                                        <Droppable droppableId="droppable" direction="horizontal">
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                    style={getListDropStyle(snapshot.isDraggingOver)}
                                                                                >
                                                                                    {this.privates.items.map((item, index) => (
                                                                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provided.draggableProps.style
                                                                                                    )}
                                                                                                >
                                                                                                    {item.content}
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </div>

                                                                </div>

                                                            </DragDropContext>
                                                        </div>

                                                    </TabPane>
                                                </Tabs>

                                            )
                                        }

                                    </div>

                                )
                            }
                            {
                                customType === customTypeList.customTypeShow && (
                                    <div className='react-qa-custom-filter-body-right-info-footer'>
                                        <div className='react-qa-custom-filter-body-right-info-title' style={{alignItems: 'unset'}}>
                                            <span className='react-qa-custom-filter-body-right-info-title-name' style={{wordBreak: 'keep-all'}}>{locales.components.common.customFilter.customFilterRule}</span>
                                            <span style={{wordBreak: 'keep-all'}}>
                                                {!_isEmpty(customeFilterField) && customeFilterField.judgeStrategy && Udesk.enums.operatorRuleTypes.find(current => current.id === customeFilterField.judgeStrategy).name}
                                            </span>
                                            <span style={{ marginLeft: '10px', wordBreak: 'keep-all' }}>
                                                {
                                                    !_isEmpty(customeFilterField) && customeFilterField.judgeStrategy && customeFilterField.judgeStrategy === 3 && customeFilterField.customJudgeLogic
                                                }
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </DndProvider>

            </Modal>

        );
    }
}

