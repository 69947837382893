import { useMemo } from 'react';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

export const useEnum = () => {
    const RuleType = {
        All: 0,
        Model: 1,
        Rule: 2,
        Rate: 3,
    };

    const Status = {
        Open: 1,
        Close: 0,
    };

    const DatasourceType = {
        Voice: Udesk.enums.inspectDataSources.voiceCall.id,
        Text: Udesk.enums.inspectDataSources.textDialogue.id,
        EText: Udesk.enums.inspectDataSources.taobao.id,
        Ticket: Udesk.enums.inspectDataSources.ticket.id,
    };

    const EvaluationMethod = {
        Confront: 1,
        Level: 2,
        Manual: 3,
    };

    return {
        RuleType,
        Status,
        DatasourceType,
        EvaluationMethod,
    };
};

export const useOptions = () => {
    const { RuleType, Status } = useEnum();

    const RuleTypeOptions = useMemo(() => {
        return [
            {
                value: RuleType.All,
                label: /* 全部 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.all,
            },
            {
                value: RuleType.Model,
                label: /* 智能模型 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.intelligentModel,
            },
            {
                value: RuleType.Rule,
                label: /* 智能规则 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.smartRules,
            },
            {
                value: RuleType.Rate,
                label: /* 手动评分 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.manualScoring,
            },
        ];
    }, [RuleType.All, RuleType.Model, RuleType.Rate, RuleType.Rule]);

    const StatusOptions = useMemo(() => {
        return [
            {
                value: Status.Open,
                label: /* 启用 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.enable,
            },
            {
                value: Status.Close,
                label: /* 未启用 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.notEnabled,
            },
        ];
    }, [Status.Close, Status.Open]);

    const DatasourceTypeOptions = useMemo(() => {
        return [
            {
                value: Udesk.enums.inspectDataSources.voiceCall.id,
                label: /* 语音通话 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.voiceCall,
            },
            {
                value: Udesk.enums.inspectDataSources.textDialogue.id,
                label: /* 文本对话 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.textDialogue,
            },
            {
                value: Udesk.enums.inspectDataSources.taobao.id,
                label: /* 电商文本会话 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.ecommerceTextSession,
            },
            {
                value: Udesk.enums.inspectDataSources.ticket.id,
                label: /* 工单 */UdeskLocales['current'].pages.intelligentAnalysis.ruleTemplate.hooks.index.workOrder,
            },
        ];
    }, []);

    return {
        RuleTypeOptions,
        StatusOptions,
        DatasourceTypeOptions,
    };
};
