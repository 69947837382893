import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';
import { CallSentenceClusterResultsWarnContext } from 'src/pages/home';
import UdeskLocales from 'UdeskLocales';

class trainingCenter extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { route, location, sdkOptions } = this.props;

        const hasFeature = Udesk.data.init.user ? Udesk.data.init.user.hasFeature : null;

        // let { locales } = this;
        return (
            <CallSentenceClusterResultsWarnContext.Consumer>
                {({ showSentenceClusterResultsWarn }) => {
                    let navLists = [];
                    // 根据当前用户权限，组装菜单列表
                    //&& Udesk.data.init.user.hasFeature("smart:tag:root")

                    if (hasFeature && hasFeature('sys:security:setting')) {
                        navLists.push({
                            text: /* 安全设置 */UdeskLocales['current'].pages.accountManagement.securitySetting,
                            pathName: 'generalSettingSecuritySettings',
                            isActive: (match, location) => {
                                return location.pathname.includes('/security-settings');
                            },
                        });
                    }

                    if (
                        sdkOptions.props.landingPath != null &&
                        this.isRedirect(route.path, location.pathname)
                    ) {
                        let toPath = location.pathname + sdkOptions.props.landingPath;
                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else if (
                        navLists.length > 0 &&
                        this.isRedirect(route.path, location.pathname)
                    ) {
                        let toPath = null;
                        if (navLists[0].pathName) {
                            toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                                routeName: navLists[0].pathName,
                            });
                        } else {
                            toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                                routeName: navLists[0].children[0].pathName,
                            });
                        }

                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else {
                        return (
                            <div className="semantic-intelligence">
                                <LayoutMenu
                                    {...this.props}
                                    navLists={navLists}
                                    menuTitle={/* 账户管理 */UdeskLocales['current'].pages.accountManagement.accountManagement}
                                />
                            </div>
                        );
                    }
                }}
            </CallSentenceClusterResultsWarnContext.Consumer>
        );
    }
}

export default Udesk.react.udeskify(trainingCenter);
