// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetCallServiceSummaryFieldsByIdParam,
    BaseResponseCallServiceSummaryFieldFoundResponse,
    PutCallServiceSummaryFieldsByIdParam,
    PutCallServiceSummaryFieldsByIdBody,
    BaseResponseVoid,
    DeleteCallServiceSummaryFieldsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags call-service-summary-field-controller
 * @link [GET] /callServiceSummaryFields/{id}
 * @param id
 */
export function getCallServiceSummaryFieldsById(
    options: ApiOptions<GetCallServiceSummaryFieldsByIdParam, never> & {
        segments: GetCallServiceSummaryFieldsByIdParam;
    }
): Promise<BaseResponseCallServiceSummaryFieldFoundResponse> {
    return request<never, GetCallServiceSummaryFieldsByIdParam, never>(
        '/callServiceSummaryFields/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getCallServiceSummaryFieldsById' }
    );
}

/**
 * update
 *
 * @export
 * @tags call-service-summary-field-controller
 * @link [PUT] /callServiceSummaryFields/{id}
 * @param id
 */
export function putCallServiceSummaryFieldsById(
    data: PutCallServiceSummaryFieldsByIdBody,
    options: ApiOptions<PutCallServiceSummaryFieldsByIdParam, never> & {
        segments: PutCallServiceSummaryFieldsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutCallServiceSummaryFieldsByIdBody,
        PutCallServiceSummaryFieldsByIdParam,
        never
    >('/callServiceSummaryFields/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putCallServiceSummaryFieldsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags call-service-summary-field-controller
 * @link [DELETE] /callServiceSummaryFields/{id}
 * @param id
 */
export function deleteCallServiceSummaryFieldsById(
    options: ApiOptions<DeleteCallServiceSummaryFieldsByIdParam, never> & {
        segments: DeleteCallServiceSummaryFieldsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteCallServiceSummaryFieldsByIdParam, never>(
        '/callServiceSummaryFields/{id}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteCallServiceSummaryFieldsById' }
    );
}

export const meta = [
    {
        tags: ['call-service-summary-field-controller'],
        path: '/callServiceSummaryFields/{id}',
        method: 'get',
    },
    {
        tags: ['call-service-summary-field-controller'],
        path: '/callServiceSummaryFields/{id}',
        method: 'put',
    },
    {
        tags: ['call-service-summary-field-controller'],
        path: '/callServiceSummaryFields/{id}',
        method: 'delete',
    },
];
