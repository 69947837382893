import React from 'react';
// import Udesk from 'Udesk';

class ChangeListComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {
        list: [],
        pointCategoryList: [],
    };
    //#endregion

    state = {

    };
    privates = {
        pointList: []
    };
    static computes = {

    };
    actions = {
        getCreateUserName(item) {
            if (item && item.createUseName) {
                return item.createUseName;
            }
            return this.locales.components.pages.appealChekingTask.components.changeList.user;
        },
        getPointName(id) {
            let {
                locales
            } = this;
            let {
                pointList
            } = this.privates;
            if (pointList && pointList instanceof Array && pointList.length > 0) {
                let point = pointList.find(item => {
                    return item.pointId === id;
                });
                if (point) {
                    return point.name;
                }
                return locales.components.pages.appealChekingTask.components.changeList.point;
            }
            return locales.components.pages.appealChekingTask.components.changeList.point;
        },
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        return {
            pointList: getPointList(props.pointCategoryList)
        };
    }
    componentDidMount() {
    }
    componentWillUnmount() {

    }
    asyncModel(changedParams) {

    }
    //#endregion
}

function getPointList(pointCategoryList) {
    let pointDataList = [];
    pointCategoryList.forEach((firstCategory) => {
        let secondCategorys = firstCategory.children || [];
        secondCategorys.forEach((secondCategory) => {
            if (secondCategory.pointDataList) {
                pointDataList = pointDataList.concat(secondCategory.pointDataList);
            }
        });
    });

    return pointDataList;
}


export default ChangeListComponent;