import isNil from 'lodash-es/isNil';

class EventBus {
    constructor() {
        this.events = {}; // 记录
    }

    on(type, fn, isOnce = false) {
        const events = this.events;
        if (isNil(events[type])) {
            events[type] = []; // 初始化
        }
        events[type].push({ fn, isOnce });
    }

    off(type, fn) {
        const events = this.events;
        if (isNil(events[type])) return;
        if (!fn) {
            // 如果没有特定的，就将所有事件都清空
            events[type] = [];
            return;
        }
        this.events = events[type].filter((item) => item.fn !== fn);
    }

    emit(type, ...argus) {
        const events = this.events;
        if (isNil(events[type])) return;
        this.events = events[type].filter((item) => {
            item.fn(...argus);
            return !item.isOnce;
        });
    }

    once(type, fn) {
        this.on(type, fn, true);
    }
}

export const emitter = new EventBus();
