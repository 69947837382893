import React from 'react';
import {
    getReviewIntelligentPartnerNodeRobotConfig,
    postReviewIntelligentPartnerNodeRobotConfig,
} from 'src/api/review/intelligentPartner/node/robotConfig';
import { getReviewIntelligentPartnerNodeRobotConfigExistsDefault } from 'src/api/review/intelligentPartner/node/robotConfig/existsDefault';
import { putReviewIntelligentPartnerNodeRobotConfigById } from 'src/api/review/intelligentPartner/node/robotConfig/{id}';

import { Icon, Modal } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
const { confirm } = Modal;

const fields: (args: any) => any[] = ({flowId}) => [
    {
        label: /* 机器人话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.robotScript,
        type: 'AuditionTextArea',
        name: 'words',
        props: {
            flowId,
            autoSize: {
                minRows: 4, maxRows: 8
            }
        }
    },
    {
        label: /* 随机话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.randomScript,
        type: 'SemanticTagsSelect',
        name: 'randomSemanticTag',
        tooltip: /* 选择相似语义的话术，机器人将随机说出其中一句 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence,
        props: {
            groupIdFieldName: 'tagId',
            semanticNameFieldName: 'semanticName',
            sentenceFieldName: 'sentence',
        },
    },
    {
        label: /* 客户画像 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.customerPortrait,
        type: 'CustomerPortraitConfigSelect',
        name: 'customerTag',
        tooltip: /* 当客户画像一致时，机器人说出此话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript,
    },
    {
        label: /* 设为默认话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.setAsDefaultScript,
        type: 'RadioGroup',
        props: {
            options: [
                {
                    label: /* 是 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.yes,
                    value: 1,
                },
                {
                    label: /* 否 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.no,
                    value: 0,
                },
            ],
            buttonStyle: 'solid',
            optionType: 'button',
            size: 'small',
        },
        name: 'enableDefaultWords',
        tooltip: /* 当机器人未命中任何用户标签时出此话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.useThisScriptWhenTheRobotMissesAnyUserTags,
    },
];

const request = ({ nodeId }) => {
    return getReviewIntelligentPartnerNodeRobotConfig({
        params: {
            nodeId,
        },
    }).then((resp) => {
        const res: any = resp.data || {};
        if (typeof res.enableDefaultWords !== 'number') {
            res.enableDefaultWords = 0;
        }
        return res;
    });
};

const finish = ({ formInstanceCache, configId, nodeId, parentNodeId }) => {
    return (values) => {
        const request = (cover = false) => {
            const params = {
                ...values,
                nodeId,
                cover,
            };
            if (!configId) {
                return postReviewIntelligentPartnerNodeRobotConfig(params, {
                    successMsg: /* 保存成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.successfullySaved,
                });
            } else {
                return putReviewIntelligentPartnerNodeRobotConfigById(params, {
                    segments: {
                        id: configId,
                    },
                    successMsg: /* 保存成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.successfullySaved,
                });
            }
        };

        return new Promise((res, rej) => {
            if (values.enableDefaultWords) {
                getReviewIntelligentPartnerNodeRobotConfigExistsDefault({
                    params: {
                        nodeId,
                        parentNodeId,
                    },
                })
                    .then((resp) => {
                        res(resp.data);
                    })
                    .catch((e) => {
                        rej(e);
                    });
            } else {
                res(false);
            }
        }).then((needConfirm) => {
            if (needConfirm) {
                return new Promise((res, rej) => {
                    confirm({
                        title: /* 此分支节点已设有默认话术，是否将默认话说替换为当前话术？ */UdeskLocales['current'].pages.coach.courseManagement.flow.components.const.nodeConfigs.robotNode.thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript,
                        icon: <Icon type="ExclamationCircleFilled" antdIcon={true} />,
                        // content: 'Some descriptions',
                        onOk() {
                            res(undefined);
                        },
                        onCancel() {
                            rej();
                        },
                    });
                }).then(() => {
                    return request(true);
                });
            } else {
                return request();
            }
        });
    };
};

export default {
    fields,
    request,
    finish,
};
