import React, { FC, useState, useEffect } from 'react';
import { Button, Modal, Space, Card, Descriptions } from 'udesk-ui';
import { FormProps } from 'udesk-ui/es/form-builder';
import { getReviewIntelligentPartnerNodeInputSceneConfigTemplate } from 'src/api/review/intelligentPartner/node/inputSceneConfig/template';
import UdeskLocales from 'UdeskLocales';
import { IntelligentPartnerDialogNodeAiTemplateFoundResponse } from 'src/api/types';
import styled from 'styled-components';

const Div = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Scroller = styled.div`
    padding: 5px 5px 10px;
    overflow: auto;
`;

type FormHeaderProps = {
    form: FormProps<any>['form'];
};

const FormHeader: FC<FormHeaderProps> = (props) => {
    const { form } = props;

    const [roles, setRoles] = useState<IntelligentPartnerDialogNodeAiTemplateFoundResponse[]>([]);

    useEffect(() => {
        getReviewIntelligentPartnerNodeInputSceneConfigTemplate().then((res) => {
            setRoles(res.data || []);
        });
    }, []);

    const [visible, setVisible] = useState(false);

    const onOpen = () => {
        setVisible(true);
    };

    const onCancel = () => {
        setVisible(false);
    };

    const onChoose = (r: IntelligentPartnerDialogNodeAiTemplateFoundResponse) => {
        form?.setFieldsValue?.({
            dialogueScene: r.dialogueScene,
            simulatedRole: r.id,
            sparringPartner: r.sparringPartner,
        });
        setVisible(false);
    };

    return (
        <Div>
            <Button type="link" onClick={onOpen}>
                {/* 从模板库中选择 */}
                {
                    UdeskLocales['current'].pages.coach.courseManagement.flow.components.const
                        .nodeConfigs.aiGenSceneNode.selectFromTemplateLibrary
                }
            </Button>

            <Modal
                title={
                    /* 模版库 */ UdeskLocales['current'].pages.coach.courseManagement.flow
                        .components.const.nodeConfigs.aiGenSceneNode.templateLibrary
                }
                visible={visible}
                footer={null}
                onCancel={onCancel}
                width={950}
            >
                <Scroller>
                    <Space>
                        {roles.map((r) => (
                            <Card
                                style={{ width: 220, height: 240 }}
                                hoverable
                                onClick={() => onChoose(r)}
                            >
                                <Descriptions title={null} column={1}>
                                    <Descriptions.Item
                                        label={
                                            /* 角色 */ UdeskLocales['current'].pages.coach
                                                .courseManagement.flow.components.const.nodeConfigs
                                                .aiGenSceneNode.role
                                        }
                                    >
                                        {r.simulatedRole}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={
                                            /* 场景 */ UdeskLocales['current'].pages.coach
                                                .courseManagement.flow.components.const.nodeConfigs
                                                .aiGenSceneNode.scene
                                        }
                                    >
                                        {r.dialogueScene}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        ))}
                    </Space>
                </Scroller>
            </Modal>
        </Div>
    );
};

export default FormHeader;
