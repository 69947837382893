import React, { memo, useState, useCallback, useEffect, FC } from 'react';
import { useRequest } from 'ahooks';
import { Button, Empty, Form, Input, Space, Spin, message } from 'udesk-ui';
import {
    getReviewCallSummaryByCallId,
    putReviewCallSummaryByCallId,
} from 'src/api/review/call/summary/{callId}';
import { getReviewCallSummaryCreate } from 'src/api/review/call/summary/create';
import { inspect } from 'src/util/core';
import isNil from 'lodash-es/isNil';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

type TemplateProps = {
    callId?: number;
    value?: string;
    onChange?: (v: string) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { callId, onChange } = props;

    const [sessionSummary, setSessionSummary] = useState<string>('');

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ sessionSummary });
    }, [form, sessionSummary]);

    const [isEdit, setIsEdit] = useState(false);

    const onFinish = useCallback(
        (values) => {
            if (onChange) {
                onChange(values.sessionSummary);
            } else {
                if (!isNil(callId)) {
                    putReviewCallSummaryByCallId(values, {
                        segments: { callId: callId! },
                    }).then(
                        (resp) => {
                            setSessionSummary(values.sessionSummary);
                            setIsEdit(false);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                    );
                }
            }
        },
        [callId, onChange]
    );

    const [getSessionSummaryLoading, setGetSessionSummaryLoading] = useState(false);
    const { run: getSessionSummary, cancel: cancelGetSessionSummary } = useRequest(
        (callId) => getReviewCallSummaryByCallId({ segments: { callId } }),
        {
            manual: true,
            pollingInterval: 3000,
            onSuccess: (res) => {
                // 1-进行中 2-成功 3-失败
                if (res.data?.status === 2 || !res.data?.status) {
                    setSessionSummary(res.data?.sessionSummary || '');
                    cancelGetSessionSummary();
                    setGetSessionSummaryLoading(false);
                } else if (res.data?.status === 3) {
                    setSessionSummary('');
                    cancelGetSessionSummary();
                    setGetSessionSummaryLoading(false);
                    message.error(res.data.failReason);
                }
            },
            onError: (e) => {
                message.error(e.message || /* 生成失败 */UdeskLocales['current'].components.sessionInsights.components.sessionSummary.index.generationFailed);
                cancelGetSessionSummary();
                setGetSessionSummaryLoading(false);
            },
        }
    );

    useEffect(() => {
        getSessionSummary(callId);
    }, [callId, getSessionSummary]);

    const { run: genSessionSummary, loading: genSessionSummaryLoading } = useRequest(
        (callId) => getReviewCallSummaryCreate({ params: { callId } }),
        {
            manual: true,
            onSuccess: () => {
                setGetSessionSummaryLoading(true);
                getSessionSummary(callId);
            },
            onError: (e) => {
                message.error(e.message || /* 生成失败 */UdeskLocales['current'].components.sessionInsights.components.sessionSummary.index.generationFailed);
            },
        }
    );

    const onGen = () => {
        genSessionSummary(callId);
    };

    return (
        <Spin spinning={getSessionSummaryLoading || genSessionSummaryLoading}>
            {inspect(
                sessionSummary,
                () => {
                    return (
                        <Form form={form} onFinish={onFinish} style={{ flex: 1 }}>
                            <Form.Item name="sessionSummary">
                                <Input.TextArea rows={8} readOnly={!isEdit} bordered={isEdit} />
                            </Form.Item>

                            <Space style={{ float: 'right', marginBottom: 16 }}>
                                {isEdit ? (
                                    <>
                                        <Button size="small" onClick={() => setIsEdit(false)}>
                                            {/* 取消 */}
                                            {
                                                UdeskLocales['current'].components.sessionInsights
                                                    .index.cancel
                                            }
                                        </Button>
                                        <Button size="small" type="primary" htmlType="submit">
                                            {/* 确定 */}
                                            {
                                                UdeskLocales['current'].components.sessionInsights
                                                    .index.determine
                                            }
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        onClick={() => setIsEdit(true)}
                                        size="small"
                                        type="primary"
                                    >
                                        {/* 编辑 */}
                                        {
                                            UdeskLocales['current'].components.sessionInsights.index
                                                .edit
                                        }
                                    </Button>
                                )}
                            </Space>
                        </Form>
                    );
                },
                () => {
                    return (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ flex: 1 }}>
                            <Button onClick={onGen} type="primary">{/* 生成小结 */}{UdeskLocales['current'].components.sessionInsights.components.sessionSummary.index.generateSummary}</Button>
                        </Empty>
                    );
                }
            )}
        </Spin>
    );
};

export default memo(Template);
