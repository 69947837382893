import React, { useMemo } from 'react';
import PPTRecord from '../../ppt';
import Wave from '../../wave';
import './index.less';

export default React.memo((props: any) => {
    const {
        isFullscreen,
        requestFullscreen,
        exitFullscreen,
        current,
        nextPage,
        goIndex,

        task,
        recordText,
        isStartTask,
        isPauseRecord,
        isLastPage,
        isOver,
        pause,
        stop,
        startTaskHandle,
        startAudioRecord,
        loadingForTaskStart,
        loadingForSendMessage,

        taskDetail,
        record,
        submitTaskHandle,
        taskMessageModalRef,
        suspendDetail,
    } = props;

    const isHiddenButton = useMemo(() => {
        return isStartTask && isLastPage && isOver && !isPauseRecord;
    }, [isLastPage, isOver, isPauseRecord, isStartTask]);

    return (
        <div className={'ppt-record-panel'}>
            <div className='ppt-content-panel'>
                <PPTRecord {...{
                    record,
                    task,
                    next: nextPage,
                    current,
                    taskDetail,
                    submitTaskHandle,
                    isLastPage,
                    isFullscreen,
                    isStartTask,
                    requestFullscreen,
                    exitFullscreen,
                }}/>
            </div>
            {
                !isFullscreen && (
                    <Wave {...{
                        isHiddenButton,
                        goIndex,
                        current,
                        task,
                        recordText,
                        isStartTask,
                        isPauseRecord,
                        isLastPage,
                        isOver,
                        pause,
                        stop,
                        startTaskHandle,
                        startAudioRecord,
                        taskMessageModalRef,
                        loadingForTaskStart,
                        loadingForSendMessage,
                        suspendDetail,
                    }} />
                )
            }
        </div>
    );
});

