// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PutIntelligentPartnerCheckRecordByCheckRecordIdViewStatusParam,
    BaseResponseVoid,
} from '../../../../../types';

/**
 * 查询未查看的复核记录
 *
 * @export
 * @tags 智能陪练任务评审/申诉接口
 * @link [PUT] /intelligentPartner/check/record/{checkRecordId}/viewStatus
 * @param checkRecordId
 */
export function putIntelligentPartnerCheckRecordByCheckRecordIdViewStatus(
    options: ApiOptions<PutIntelligentPartnerCheckRecordByCheckRecordIdViewStatusParam, never> & {
        segments: PutIntelligentPartnerCheckRecordByCheckRecordIdViewStatusParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PutIntelligentPartnerCheckRecordByCheckRecordIdViewStatusParam, never>(
        '/intelligentPartner/check/record/{checkRecordId}/viewStatus',
        {
            ...options,
            method: 'put',
            __$requestCalleeName: 'putIntelligentPartnerCheckRecordByCheckRecordIdViewStatus',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练任务评审/申诉接口'],
        path: '/intelligentPartner/check/record/{checkRecordId}/viewStatus',
        method: 'put',
    },
];
