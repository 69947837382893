let udeskReactCustomUiGridTypeEnums = [
    { id: "normal", key: "normal" }
];

let udeskReactCustomUiGridModeEnums = [
    { id: "edit", key: "edit" },
    { id: "design", key: "design" },
    { id: "preview", key: "preview"}
];

export default {
    udeskReactCustomUiGridTypeEnums,
    udeskReactCustomUiGridModeEnums
};