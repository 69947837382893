// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetLibraryFileCollectsQuery,
    BaseResponseListLibraryFileFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags 星标案例库
 * @link [GET] /libraryFileCollects
 */
export function getLibraryFileCollects(): Promise<BaseResponseListLibraryFileFoundResponse>;
export function getLibraryFileCollects(
    options: ApiOptions<never, GetLibraryFileCollectsQuery>
): Promise<BaseResponseListLibraryFileFoundResponse>;
export function getLibraryFileCollects(
    options?: ApiOptions<never, GetLibraryFileCollectsQuery>
): Promise<BaseResponseListLibraryFileFoundResponse> {
    return request<never, never, GetLibraryFileCollectsQuery>('/libraryFileCollects', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getLibraryFileCollects',
    });
}

export const meta = [{ tags: ['星标案例库'], path: '/libraryFileCollects', method: 'get' }];
