import React, { createContext, useContext } from 'react';

const FlowModalContext = createContext<any>(undefined);

export const FlowModalProvider = ({ children, value }) => {
    return <FlowModalContext.Provider value={value}>{children}</FlowModalContext.Provider>;
};

export const useFlowModalProvider = () => {
    const ctx = useContext(FlowModalContext);
    if (ctx === undefined) {
        throw new Error('FlowModalProvider should has value, but got undefined');
    }
    return ctx;
};
