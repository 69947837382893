import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Empty, Space, Switch } from 'udesk-ui';
import { isExercise } from '../util';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export default React.memo((props: any) => {
    const {
        taskDetail,
        referenceWords,
        isAiGenCourse,
        onGenRefer,
        onGenReferLoading,
        isStartTask,
    } = props;
    const [isShow, setIsShow] = useState(false);
    const cueListRef = useRef<HTMLDivElement>(null);

    const data = useMemo(() => {
        if (Array.isArray(referenceWords)) {
            return referenceWords;
        }

        if (typeof referenceWords === 'string' && !!referenceWords) {
            return [referenceWords];
        }

        return [];
    }, [referenceWords]);

    const genRefer = () => {
        onGenRefer?.(!!data?.length);
    };

    useEffect(() => {
        cueListRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }, [data]);

    return (
        <div className="cue-panel">
            {isExercise(
                taskDetail?.taskType,
                () => (
                    <>
                        <div className="cue-title">
                            <div>
                                <span>
                                    {/* 参考话术 */}
                                    {
                                        UdeskLocales['current'].pages.coach.learningCenter
                                            .components.cue.index.referenceScript
                                    }
                                </span>
                            </div>
                            {!!taskDetail?.isTip && (
                                <Switch
                                    checked={isShow}
                                    onChange={() => setIsShow((isShow) => !isShow)}
                                />
                            )}
                        </div>

                        {isShow && taskDetail?.isTip ? (
                            <>
                                <div ref={cueListRef} className="cue-list">
                                    {data?.map((words) => {
                                        return <div className="cue-list-item">{words}</div>;
                                    })}
                                </div>

                                {isAiGenCourse && isStartTask ? (
                                    <div className="cue-list-operation">
                                        <Button
                                            type="primary"
                                            onClick={genRefer}
                                            loading={onGenReferLoading}
                                            disabled={onGenReferLoading}
                                        >
                                            {data?.length ? /* 重新生成 */UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.regenerate : /* 生成 */UdeskLocales['current'].pages.coach.learningCenter.components.cue.index.generate}
                                        </Button>
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </>
                ),
                () => (
                    <>
                        <div className="cue-title">
                            <div>
                                <span>
                                    {/* 注意事项 */}
                                    {
                                        UdeskLocales['current'].pages.coach.learningCenter
                                            .components.cue.index.mattersNeedingAttention
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="cue-tip">
                            <Space direction="vertical">
                                <div>
                                    <span>
                                        {/* 考试次数 */}
                                        {
                                            UdeskLocales['current'].pages.coach.learningCenter
                                                .components.cue.index.examFrequency
                                        }
                                    </span>
                                    <span>{`${
                                        /* 第 */ UdeskLocales['current'].pages.coach.learningCenter
                                            .components.cue.index.section
                                    }${taskDetail?.completedTimes + 1}${
                                        /* 次 / 共 */ UdeskLocales['current'].pages.coach
                                            .learningCenter.components.cue.index.timestotal
                                    }${taskDetail?.limitTimes}${
                                        /* 次 */ UdeskLocales['current'].pages.coach.learningCenter
                                            .components.cue.index.second
                                    }`}</span>
                                </div>
                                <div>
                                    <span>
                                        {/* 考试通过要求 */}
                                        {
                                            UdeskLocales['current'].pages.coach.learningCenter
                                                .components.cue.index.requirementsForPassingTheExam
                                        }
                                    </span>
                                    <span>{`${
                                        /* 考试得分 ≥ */ UdeskLocales['current'].pages.coach
                                            .learningCenter.components.cue.index.examScore
                                    }${taskDetail?.completeByScore}${
                                        /* 分 */ UdeskLocales['current'].pages.coach.learningCenter
                                            .components.cue.index.branch
                                    }`}</span>
                                </div>
                            </Space>
                        </div>
                    </>
                )
            )}
        </div>
    );
});
