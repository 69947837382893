import { useState } from 'react';

export const useInputValue = (initValue: string): [string, (e: string | object) => void] => {
    const [value, setValue] = useState<string>(initValue || '');
    const onValueChange = (e) => {
        if (typeof e === 'string') {
            setValue(e);
        } else if (typeof e === 'object') {
            setValue(e?.target?.value ?? '');
        }
    };
    return [value, onValueChange];
};
