// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostBusinessAnalysisProportionBody,
    BaseResponseBusinessAnalysisProportionFoundResponseDto,
} from '../../types';

/**
 * proportion
 *
 * @export
 * @tags business-analysis-controller
 * @link [POST] /businessAnalysis/proportion
 */
export function postBusinessAnalysisProportion(
    data: PostBusinessAnalysisProportionBody
): Promise<BaseResponseBusinessAnalysisProportionFoundResponseDto>;
export function postBusinessAnalysisProportion(
    data: PostBusinessAnalysisProportionBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisProportionFoundResponseDto>;
export function postBusinessAnalysisProportion(
    data: PostBusinessAnalysisProportionBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseBusinessAnalysisProportionFoundResponseDto> {
    return request<PostBusinessAnalysisProportionBody, never, never>(
        '/businessAnalysis/proportion',
        { ...options, data, method: 'post', __$requestCalleeName: 'postBusinessAnalysisProportion' }
    );
}

export const meta = [
    {
        tags: ['business-analysis-controller'],
        path: '/businessAnalysis/proportion',
        method: 'post',
    },
];
