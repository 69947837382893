import React from 'react';
import AssociateSelectComponent from './component';
import './style.scss';
import { Input, Tabs } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const AssociateComponent = (props) => {
    let {
        pushConf,
        onClick,
        associateActiveTab,
        operations,
        associateTabsChange,
        locales,
        associateList,
        associateDataClick,
    } = props;

    const getTitle = (type) => {
        switch (type) {
            case 1: {
                return locales.components.operatorListGather.associateData;
            }
            case 3: {
                return locales.components.operatorListGather.pretreatmentData;
            }
            case 6: {
                return locales.components.operatorListGather.businessData;
            }
            case 8: {
                return locales.components.operatorListGather.reviewTaskData;
            }
            case 'task': {
                return locales.components.operatorListGather.taskData;
            }
            case 17: {
                return /* 智能标签 */UdeskLocales['current'].components.massagePushConfiguration.associateSelect.template.smartTags;
            }
            default: {
                return '';
            }
        }
    };

    return (
        <React.Fragment>
            <div>
                <Tabs
                    size="small"
                    tabBarGutter={8}
                    activeKey={associateActiveTab}
                    tabBarExtraContent={operations}
                    onChange={associateTabsChange}
                >
                    <Tabs.TabPane
                        tab={locales.components.operatorListGather.associateMedol.tabs.data}
                        key="data"
                    ></Tabs.TabPane>
                    {/* <Tabs.TabPane tab={locales.components.operatorListGather.associateMedol.tabs.operator} key="operator"></Tabs.TabPane> */}
                </Tabs>
            </div>
            <If condition={associateActiveTab === 'data'}>
                <div className="operator-list-item-associate-model">
                    {associateList &&
                        associateList.map((associateData, index) => {
                            return (
                                <If
                                    condition={
                                        associateData.fieldList &&
                                        associateData.fieldList.length > 0
                                    }
                                >
                                    <div
                                        style={{
                                            marginTop: 8,
                                            borderBottom: `${
                                                index === associateList.length - 1
                                                    ? 'unset'
                                                    : '1px solid rgba(0,0,0,0.1)'
                                            }`,
                                        }}
                                    >
                                        <div
                                            style={{ fontSize: '12px', color: 'rgba(0,0,0,0.45)' }}
                                        >
                                            {getTitle(associateData.type)}
                                        </div>
                                        {associateData.fieldList &&
                                            associateData.fieldList.map((item) => {
                                                return (
                                                    <div
                                                        className="operator-list-item-associate-model-item"
                                                        onClick={
                                                            onClick
                                                                ? typeof onClick.params ===
                                                                  'function'
                                                                    ? onClick.params(
                                                                          pushConf,
                                                                          pushConf.actionDetails
                                                                              ?._editorState,
                                                                          `[${item.label}]`
                                                                      )
                                                                    : onClick.bind(
                                                                          this,
                                                                          pushConf,
                                                                          pushConf.actionDetails
                                                                              ?._editorState,
                                                                          `[${item.label}]`
                                                                      )
                                                                : associateDataClick.params(
                                                                      pushConf.actionType,
                                                                      'data',
                                                                      `[${item.label}]`
                                                                  )
                                                        }
                                                    >{`[${item.label}]`}</div>
                                                );
                                            })}
                                    </div>
                                </If>
                            );
                        })}
                </div>
            </If>
        </React.Fragment>
    );
};
export default class AssociateSelect extends AssociateSelectComponent {
    render() {
        let { privates, props, actions, locales } = this;
        let { pushConf } = props;
        const operations = (
            <Input.Search
                size="small"
                placeholder={locales.labels.searchPlaceHolder}
                onClick={actions.associateSearchChange}
                style={{ width: 146 }}
                onSearch={actions.setAssociateList}
                onChange={actions.associateListSearchChange}
                onBlur={actions.associateInputFocus}
            />
        );
        return (
            <AssociateComponent
                associateDataClick={actions.associateDataClick}
                associateList={privates.associateList}
                locales={locales}
                associateTabsChange={actions.associateTabsChange}
                operations={operations}
                associateActiveTab={privates.associateActiveTab}
                pushConf={pushConf}
                onClick={props.onClick}
            ></AssociateComponent>
        );
    }
}
