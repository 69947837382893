import filterComponentSettings from './components';
import converters from './converters';
import conditionOperators from './operators';
import emojiCodes from './emoji-codes';
import Udesk from 'Udesk';

const VALUE_SETTINGS_TABLE_VALIDATOR_INDEX = 0;
const VALUE_SETTINGS_TABLE_CONVERTER_INDEX = 1;
const VALUE_SETTINGS_TABLE_START_INDEX =
    Math.max(VALUE_SETTINGS_TABLE_VALIDATOR_INDEX, VALUE_SETTINGS_TABLE_CONVERTER_INDEX) + 1;
const VALUE_SETTINGS_AVOID_EVALUATION_FIELDS = [];
let _commonFilterConfigSettings = null;
const FIELD_TEST_SET_LIST_TYPE = 16;
export default {
    getFilterComponentSettings,
    buildConditionField,
    emojiCodes,
};

function buildConditionField(customFields, scenarioEnum) {
    let field = wrapCustomFiled(customFields);
    let fieldName = field['originalField'];
    buildConditionNormalField(customFields, scenarioEnum, field, fieldName);
    return field;
}

//创建判定条件-常规字段
function buildConditionNormalField(customFields, scenarioEnum, field, fieldName, isSub) {
    let inputType = fieldTypeConvertInputType(fieldName);

    field.inputType = converters.convertInputType(inputType);
    // if (scenarioEnum === UDQ.enums.businessScenarios.workBench) {
    //     let workBenchFieldFilterConditionOperators = conditionOperators.workBench[field.inputType.key];
    //     if (workBenchFieldFilterConditionOperators != null) {
    //         if (customFields.length > 1) {
    //             field.operators = converters.convertOperators(workBenchFieldFilterConditionOperators.nested);
    //         } else {
    //             field.operators = converters.convertOperators(workBenchFieldFilterConditionOperators.default);
    //         }
    //     }
    // } else {

    let normalFieldCommonConditionOperators = conditionOperators.common[field.inputType.key];

    // 这里，针对销售赋能的客户中心列表筛选，做特殊处理
    if (fieldName.isCustomer) {
        normalFieldCommonConditionOperators = conditionOperators.customer[field.inputType.key];
        // 固定字段只支持 ‘contains’
        if (fieldName.fixed === 1) {
            normalFieldCommonConditionOperators = conditionOperators.customer.fixed;
        }
    }

    if (normalFieldCommonConditionOperators != null) {
        if (customFields.length > 1) {
            field.operators = converters.convertOperators(
                normalFieldCommonConditionOperators.nested
            );
        } else {
            field.operators = converters.convertOperators(
                normalFieldCommonConditionOperators.default
            );
        }
    } else if (
        customFields.length === 1 &&
        customFields[0].fieldType === FIELD_TEST_SET_LIST_TYPE
    ) {
        field.operators = converters.convertOperators(customFields[0].operators);
    }
    // }
}

function fieldTypeConvertInputType(field) {
    let fieldType = converters.convertFieldType(field.fieldType ?? Udesk.enums.fieldTypes.text.id);
    return fieldType.key;
}

function getFilterComponentSettings(options) {
    _commonFilterConfigSettings = buildFieldComponentSettings(
        filterComponentSettings.getCommonFilterSettings(options)
    );
    if (arguments.length === 2) {
        for (let i = 0; i < _commonFilterConfigSettings.length; i++) {
            if (_commonFilterConfigSettings[i].fieldType.id === arguments[1]) {
                return _commonFilterConfigSettings[i];
            }
        }
    }
    return _commonFilterConfigSettings;
}

function wrapCustomFiled(fields) {
    let lastFieldObject = fields[fields.length - 1];
    var fullField = {
        key: lastFieldObject.id,
        label: lastFieldObject.label,
        originalField: lastFieldObject,
        attributes: {},
    };
    return fullField;
}

function buildFieldComponentSettings(configTable) {
    let settingsArray = [];
    if (configTable.length > 0) {
        let validators = configTable[VALUE_SETTINGS_TABLE_VALIDATOR_INDEX];
        let converts = configTable[VALUE_SETTINGS_TABLE_CONVERTER_INDEX];
        for (let i = VALUE_SETTINGS_TABLE_START_INDEX; i < configTable.length; i++) {
            let config = configTable[i];
            for (let fieldName in validators) {
                if (Object.prototype.hasOwnProperty.call(validators, fieldName)) {
                    let validator = validators[fieldName];
                    let value = config[fieldName];
                    if (typeof validator === 'function') {
                        let result = validator.call(config, value);
                        if (result !== true) {
                            throw new Error(
                                `
Wrong configuration in 'field-value-settings.js'. 
Inner error message is '${result}'. 
Source row is at #${i - VALUE_SETTINGS_TABLE_START_INDEX + 1}, field is '${fieldName}'.`.trim()
                            );
                        }
                    }
                }
            }
            let settings = Object.assign({}, config);
            for (let fieldName in converts) {
                if (Object.prototype.hasOwnProperty.call(converts, fieldName)) {
                    let converter = converts[fieldName];
                    let value = config[fieldName];
                    if (typeof converter === 'function') {
                        value = converter.call(config, value);
                    }
                    settings[fieldName] = value;
                }
            }
            for (let fieldName in settings) {
                if (Object.prototype.hasOwnProperty.call(settings, fieldName)) {
                    let value = settings[fieldName];
                    if (
                        typeof value === 'function' &&
                        VALUE_SETTINGS_AVOID_EVALUATION_FIELDS.indexOf(fieldName) === -1
                    ) {
                        settings[fieldName] = value.call(settings, fieldName);
                    }
                }
            }
            settingsArray.push(settings);
        }
    }
    return normalizeSettings(settingsArray);
}

function normalizeSettings(settings) {
    return settings;
}
