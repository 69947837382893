import React from 'react';
import Udesk from 'Udesk';

// const UNITS_LIST = ['K', 'M', 'G'];

export default class WordsWeightComponent extends React.Component {
    // #region privates
    privates = {
        isWordsWeightModalShow: false,
        wordsWeightModalTitle: '',
        weightWords: '',
        weight: '',
        effectiveness: '1',
        newWordsWeight: '',
        wordsWeightId: '',
        smartWordsLibraryListInstance: null
    };
    //#endregion

    // #region cycles
    init() {

        let columns = this.getColumns();

        this.privates.columns = columns;
        // this.actions.update();
    }
    componentWillUnmount() {
    }
    //#endregion

    // #region actions
    actions = {
        createWordsWeight() {

            let {
                locales
            } = this;

            this.privates.isWordsWeightModalShow = true;
            this.privates.wordsWeightModalTitle = locales.components.pages.smartWordsLibrary.wordsWeight.createWordsWeight;
            this.actions.update();
        },
        editWordsWeight(id) {

            let {
                locales
            } = this;


            let {
                sdkOptions
            } = this.props;

            let url = Udesk.business.apiPath.concatApiPath(`corpora/weight/${id}`, sdkOptions);

            Udesk.ajax.get(url).then(
                (resp, textStatus, jqXHR) => {
                    let responseItem = $.extend(true, {}, resp.data);
                    this.privates.isWordsWeightModalShow = true;
                    this.privates.wordsWeightModalTitle = locales.components.pages.smartWordsLibrary.wordsWeight.editWordsWeight;
                    this.privates.weightWords = responseItem.weightWords;
                    this.privates.weight = responseItem.weight;
                    this.privates.effectiveness = responseItem.effectiveness;
                    this.privates.wordsWeightId = responseItem.id;
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.smartWordsLibrary.professionalWords.deleteErr);
                });
        },
        deleteWordsWeight(id) {

            let {
                smartWordsLibraryListInstance
            } = this.privates;

            let {
                sdkOptions
            } = this.props;

            Udesk.ui.alerts.warning({
                content: this.locales.labels.deleteContent,
                onOk: () => {
                    Udesk.ajax.del(
                        `${Udesk.business.apiPath.concatApiPath(`corpora/${id}`, sdkOptions)}`
                    ).then(
                        (resp, textStatus, jqXHR) => {
                            Udesk.ui.notify.success(this.locales.components.pages.smartWordsLibrary.wordsWeight.deleteSuccess);

                            smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || this.locales.components.pages.smartWordsLibrary.wordsWeight.deleteError);
                        }
                    );
                }
            });
        },
        saveWordsWeight() {

            let isEdit = false;

            if (this.privates.wordsWeightId) {
                isEdit = true;
            }

            this.props.form.validateFields((err, values) => {
                if (!err) {

                    let { locales } = this;

                    let {
                        sdkOptions
                    } = this.props;

                    let params = Object.assign({}, values);

                    let {
                        smartWordsLibraryListInstance
                    } = this.privates;

                    let title = isEdit ? locales.components.pages.smartWordsLibrary.wordsWeight.editSuccess : locales.components.pages.smartWordsLibrary.wordsWeight.createSuccess;

                    let requestType = isEdit ? 'put' : 'post';

                    let url = '';

                    if (isEdit) {

                        let id = this.privates.wordsWeightId;
                        url = Udesk.business.apiPath.concatApiPath(`corpora/weight/${id}`, sdkOptions);

                    } else {
                        url = Udesk.business.apiPath.concatApiPath(`corpora/weight`, sdkOptions);
                    }

                    let that = this;

                    Udesk.ajax[requestType](url, params).then(
                        (resp, textStatus, jqXHR) => {
                            that.actions.closeWordsWeightModal();
                            Udesk.ui.notify.success(title);
                            smartWordsLibraryListInstance.actions.refresh();
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg || that.locales.components.pages.smartWordsLibrary.wordsWeight.deleteErr);
                        });

                } else {
                    return;
                }
            });

        },
        changeWordsWeightEffectiveness(value) {
            this.privates.effectiveness = value;
            this.actions.update();
        },
        closeWordsWeightModal() {
            this.privates.weightWords = '';
            this.privates.effectiveness = '1';
            this.privates.weight = '';
            this.privates.isWordsWeightModalShow = false;
            this.privates.wordsWeightId = '';
            this.actions.update();
        }
    };
    //#endregion

    // #region public methods used "this":
    getColumns() {
        let { locales } = this;
        let columns = [
            {
                name: "weightWords",
                width: "20%",
                caption: locales.fields.smartWordsLibrary.wordsWeight.selfDefineWeightWords
            },
            {
                name: "weight",
                width: "20%",
                caption: locales.fields.smartWordsLibrary.wordsWeight.weight
            },
            // {
            //     name: "useFrequency",
            //     width: "20%",
            //     caption: locales.fields.smartWordsLibrary.wordsWeight.useFrequency,
            //     hasTemplate: true,
            //     getYieldContent: (name, item, index) => {
            //         return (
            //             <span>
            //                 {Udesk.business.smartWordsMethods.getFormatFrequency(item.useFrequency, UNITS_LIST)}
            //             </span>
            //         );
            //     }
            // },
            {
                name: "effectiveness",
                width: "20%",
                caption: locales.fields.smartWordsLibrary.wordsWeight.effectiveness,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    let targetItem = Udesk.enums.wordsEffectiveness.get(item.effectiveness);
                    return (
                        <span>
                            {targetItem.name}
                            {/* <If condition={item.effectiveness === Udesk.enums.samplingRateTypes.effective.id}>
                                {Udesk.enums.samplingRateTypes.effective.name}
                            </If>
                            <If condition={item.effectiveness === Udesk.enums.samplingRateTypes.invalid.id}>
                                {Udesk.enums.samplingRateTypes.invalid.name}
                            </If> */}
                        </span>
                    );
                }
            },
            {
                name: "operation",
                width: "20%",
                caption: locales.fields.smartWordsLibrary.wordsWeight.operation,
                hasTemplate: true,
                getYieldContent: (name, item, index) => {
                    return (
                        <div className="smart-words-library-words-weight-operation">
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:edit")}>
                                <span className="smart-words-library-operation-edit" title={locales.labels.edit} onClick={this.actions.editWordsWeight.params(item.id)}>
                                    {locales.labels.edit}
                                </span>
                            </If>
                            <If condition={Udesk.data.init.user && Udesk.data.init.user.hasFeature("semantic:corpora:delete")}>
                                <span className="smart-words-library-operation-delete" title={locales.labels.delete} onClick={this.actions.deleteWordsWeight.params(item.id)}>
                                    {locales.labels.delete}
                                </span>
                            </If>
                        </div>

                    );
                }
            }
        ];
        return columns;
    }
}