import UdeskLocales from 'UdeskLocales';

let speakerTypes = [
    {
        id: -1,
        key: 'employeesAndCustomer',
        name: () =>
            /* 员工和客户 */ UdeskLocales['current'].enums.semanticIntelligence
                .employeesAndCustomers,
    },
    {
        id: 0,
        key: 'employees',
        name: () => /* 员工 */ UdeskLocales['current'].enums.semanticIntelligence.staff,
    },
    {
        id: 1,
        key: 'customer',
        name: () => /* 客户 */ UdeskLocales['current'].enums.semanticIntelligence.customer,
    },
];

let clusteringTypes = [
    {
        id: 1,
        key: 'notStarted',
        name: () => /* 未开始 */ UdeskLocales['current'].enums.semanticIntelligence.notStarted,
    },
    {
        id: 2,
        key: 'haveInHand',
        name: () => /* 进行中 */ UdeskLocales['current'].enums.semanticIntelligence.haveInHand,
    },
    {
        id: 3,
        key: 'completed',
        name: () => /* 已完成 */ UdeskLocales['current'].enums.semanticIntelligence.completed,
    },
];

let clusteringSessionTypes = [
    {
        id: 1,
        key: 'voiceRecording',
        name: () => /* 通话录音 */ UdeskLocales['current'].enums.semanticIntelligence.callRecording,
    },
    {
        id: 2,
        key: 'textDialogue',
        name: () => /* 文本对话 */ UdeskLocales['current'].enums.inspectDataSources.textDialogue,
    },
    {
        id: 12,
        key: 'weChatCall',
        name: () => /* 企微语音 */ UdeskLocales['current'].enums.inspectDataSources.wechatRadio,
    },
    {
        id: 5,
        key: 'weChatText',
        name: () => /* 企微会话 */ UdeskLocales['current'].enums.inspectDataSources.wechatDialogue,
    },
];

let clusteringSessionTypesForSales = [
    {
        id: 9,
        key: 'voiceRecording',
        name: () => /* 通话录音 */ UdeskLocales['current'].enums.semanticIntelligence.callRecording,
    },
    {
        id: 13,
        key: 'weChatCall',
        name: () =>
            /* 企微通话 */ UdeskLocales['current'].enums.semanticIntelligence.enterpriseWeChatCall,
    },
    {
        id: 14,
        key: 'weChatText',
        name: () =>
            /* 企微文本 */ UdeskLocales['current'].enums.semanticIntelligence.enterpriseWeChatText,
    },
];

let applicationScenarios = [
    {
        id: 1,
        key: 'objection',
        name: () =>
            /* 异议处理话术挖掘 */ UdeskLocales['current'].enums.semanticIntelligence
                .discourseMiningForObjectionHandling,
    },
    {
        id: 2,
        key: 'sellingPoints',
        name: () =>
            /* 产品卖点话术挖掘 */ UdeskLocales['current'].enums.semanticIntelligence
                .productSellingPointScriptsMining,
    },
];

let clusteringRulesType = [
    {
        id: 5,
        key: 'smartTags',
        name: () => /* 智能标签 */ UdeskLocales['current'].enums.algorithm.smartTags,
    },
    {
        id: 6,
        key: 'satisfactionEvaluation',
        name: () => /* 满意度评价 */ UdeskLocales['current'].enums.algorithm.satisfactionEvaluation,
    },
    { id: 7, key: 'rating', name: () => /* 评分 */ UdeskLocales['current'].enums.algorithm.score },
    {
        id: 8,
        key: 'actualGrade',
        name: () => /* 评级 */ UdeskLocales['current'].enums.algorithm.grade,
    },
    {
        id: 9,
        key: 'group',
        name: () => /* 客服组 */ UdeskLocales['current'].enums.algorithm.customerServiceTeam,
    },
];

let clusteringRulesTypeForSales = [
    {
        id: 1,
        key: 'conversation',
        name: () =>
            /* 会话场景 */ UdeskLocales['current'].enums.semanticIntelligence.conversationScenario,
    },
    {
        id: 2,
        key: 'topic',
        name: () =>
            /* 话题 */ UdeskLocales['current'].enums.semanticIntelligence.topicOfConversation,
    },
    {
        id: 3,
        key: 'keyword',
        name: () => /* 关键事件 */ UdeskLocales['current'].enums.semanticIntelligence.keyEvents,
    },
    {
        id: 4,
        key: 'customerTag',
        name: () => /* 客户标签 */ UdeskLocales['current'].enums.semanticIntelligence.customerLabel,
    },
];

let clusteringRulesContainType = [
    {
        id: 1,
        key: 'any',
        name: () => /* 包含任意 */ UdeskLocales['current'].enums.semanticIntelligence.includeAny,
    },
    {
        id: 2,
        key: 'all',
        name: () => /* 包含全部 */ UdeskLocales['current'].enums.semanticIntelligence.includeAll,
    },
];

let clusteringRulesConditionType = [
    {
        id: 1,
        key: 'and',
        name: () => /* 且 */ UdeskLocales['current'].enums.semanticIntelligence.and,
    },
    {
        id: 2,
        key: 'or',
        name: () => /* 或 */ UdeskLocales['current'].enums.semanticIntelligence.or,
    },
];

let clusteringRulesSentenceType = [
    {
        id: 1,
        key: 'question',
        name: () => /* 疑问句 */ UdeskLocales['current'].enums.semanticIntelligence.question,
    },
    {
        id: 2,
        key: 'other',
        name: () =>
            /* 非疑问句 */ UdeskLocales['current'].enums.semanticIntelligence
                .nonInterrogativeSentence,
    },
];

const clusteringAnalysisDataSource = [
    {
        id: 1,
        key: 'sales',
        name: () => /* 销售赋能 */ UdeskLocales['current'].enums.algorithm.salesEmpowerment,
    },
    {
        id: 2,
        key: 'qa',
        name: () =>
            /* 智能质检 */ UdeskLocales['current'].enums.algorithm.intelligentQualityInspection,
    },
    {
        id: 3,
        key: 'callAnalysis',
        name: () => /* 会话分析 */ UdeskLocales['current'].enums.algorithm.conversationAnalysis,
    },
];

const intelligentExtractorsTaskStatus = [
    {
        id: 1,
        key: 'waiting',
        name: () => /* 等待中 */ UdeskLocales['current'].enums.algorithm.waiting,
    },
    {
        id: 2,
        key: 'extracting',
        name: () => /* 抽取中 */ UdeskLocales['current'].enums.algorithm.extracting,
    },
    {
        id: 3,
        key: 'successful',
        name: () => /* 成功 */ UdeskLocales['current'].enums.algorithm.success,
    },
    { id: 4, key: 'lose', name: () => /* 失败 */ UdeskLocales['current'].enums.algorithm.fail },
];

const intelligentExtractorsDataSource = [
    {
        id: 1,
        key: 'qa',
        name: () =>
            /* 智能质检 */ UdeskLocales['current'].enums.algorithm.intelligentQualityInspection,
    },
    { id: 2, key: 'sales', name: () => /* 销售赋能 */UdeskLocales['current'].enums.algorithm.salesEmpowerment },
];

const intelligentExtractorsDataType = [
    {
        id: 1,
        key: 'call',
        name: () => /* 语音通话 */ UdeskLocales['current'].enums.algorithm.voiceCall,
    },
    {
        id: 2,
        key: 'text',
        name: () => /* 文本对话 */ UdeskLocales['current'].enums.algorithm.textDialogue,
    },
    {
        id: 3,
        key: 'wechatVoice',
        name: () => /* 企业微信语音 */UdeskLocales['current'].enums.algorithm.enterpriseWeChatVoice,
    },
    {
        id: 4,
        key: 'wechatText',
        name: () => /* 企业微信文本 */UdeskLocales['current'].enums.algorithm.enterpriseWeChatText,
    },
];

export default {
    applicationScenarios,
    speakerTypes,
    clusteringTypes,
    clusteringRulesType,
    clusteringRulesTypeForSales,
    clusteringRulesSentenceType,
    clusteringRulesConditionType,
    clusteringRulesContainType,
    clusteringSessionTypes,
    clusteringSessionTypesForSales,
    clusteringAnalysisDataSource,
    intelligentExtractorsTaskStatus,
    intelligentExtractorsDataSource,
    intelligentExtractorsDataType,
};
