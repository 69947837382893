import SDKBaseClass from './sdk-base-class';
import DomSdkBaseClass from './dom-sdk-base-class';
import MessageChannelClientClass from "./message-channel/message-channel-client-class";
import MessageChannelServerClass from "./message-channel/message-channel-server-class";

export default {
    SDKBaseClass,
    DomSdkBaseClass,
    MessageChannelClientClass,
    MessageChannelServerClass
};

export {
    SDKBaseClass,
    DomSdkBaseClass,
    MessageChannelClientClass,
    MessageChannelServerClass
};