// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetSupervisionItemReportConfigsByIdParam,
    BaseResponseSupervisionItemReportConfigFoundResponse,
    PutSupervisionItemReportConfigsByIdParam,
    PutSupervisionItemReportConfigsByIdBody,
    BaseResponseVoid,
    DeleteSupervisionItemReportConfigsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 监督项报表配置
 * @link [GET] /supervisionItemReportConfigs/{id}
 * @param id
 */
export function getSupervisionItemReportConfigsById(
    options: ApiOptions<GetSupervisionItemReportConfigsByIdParam, never> & {
        segments: GetSupervisionItemReportConfigsByIdParam;
    }
): Promise<BaseResponseSupervisionItemReportConfigFoundResponse> {
    return request<never, GetSupervisionItemReportConfigsByIdParam, never>(
        '/supervisionItemReportConfigs/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getSupervisionItemReportConfigsById' }
    );
}

/**
 * update
 *
 * @export
 * @tags 监督项报表配置
 * @link [PUT] /supervisionItemReportConfigs/{id}
 * @param id
 */
export function putSupervisionItemReportConfigsById(
    data: PutSupervisionItemReportConfigsByIdBody,
    options: ApiOptions<PutSupervisionItemReportConfigsByIdParam, never> & {
        segments: PutSupervisionItemReportConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutSupervisionItemReportConfigsByIdBody,
        PutSupervisionItemReportConfigsByIdParam,
        never
    >('/supervisionItemReportConfigs/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putSupervisionItemReportConfigsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 监督项报表配置
 * @link [DELETE] /supervisionItemReportConfigs/{id}
 * @param id
 */
export function deleteSupervisionItemReportConfigsById(
    options: ApiOptions<DeleteSupervisionItemReportConfigsByIdParam, never> & {
        segments: DeleteSupervisionItemReportConfigsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteSupervisionItemReportConfigsByIdParam, never>(
        '/supervisionItemReportConfigs/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteSupervisionItemReportConfigsById',
        }
    );
}

export const meta = [
    { tags: ['监督项报表配置'], path: '/supervisionItemReportConfigs/{id}', method: 'get' },
    { tags: ['监督项报表配置'], path: '/supervisionItemReportConfigs/{id}', method: 'put' },
    { tags: ['监督项报表配置'], path: '/supervisionItemReportConfigs/{id}', method: 'delete' },
];
