const VALUE_SETTINGS_TABLE_VALIDATOR_INDEX = 0;
const VALUE_SETTINGS_TABLE_CONVERTER_INDEX = 1;
const VALUE_SETTINGS_TABLE_START_INDEX = (Math.max(
    VALUE_SETTINGS_TABLE_VALIDATOR_INDEX,
    VALUE_SETTINGS_TABLE_CONVERTER_INDEX) + 1);
const VALUE_SETTINGS_AVOID_EVALUATION_FIELDS = [];


function buildFieldComponentSettings(configTable, options) {
    let settingsArray = [];
    if (options == null) {
        options = {};
    }
    if (configTable.length > 0) {
        let validators = configTable[VALUE_SETTINGS_TABLE_VALIDATOR_INDEX];
        let converts = configTable[VALUE_SETTINGS_TABLE_CONVERTER_INDEX];
        for (let i = VALUE_SETTINGS_TABLE_START_INDEX; i < configTable.length; i++) {
            let config = configTable[i];
            for (let fieldName in validators) {
                if (Object.prototype.hasOwnProperty.call(validators, fieldName)) {
                    let validator = validators[fieldName];
                    let value = config[fieldName];
                    if (typeof validator === "function") {
                        let result = validator.call(config, value);
                        if (result !== true) {
                            throw new Error(`
Wrong configuration in 'udesk-react/config/components/custom-filter.js'. 
Inner error message is '${result}'. 
Source row is at #${i - VALUE_SETTINGS_TABLE_START_INDEX + 1}, field is '${fieldName}'.`.trim());
                        }
                    }
                }
            }
            let settings = Object.assign({}, config);
            for (let fieldName in converts) {
                if (Object.prototype.hasOwnProperty.call(converts, fieldName)) {
                    let converter = converts[fieldName];
                    let value = config[fieldName];
                    if (typeof converter === "function") {
                        value = converter.call(config, value);
                    }
                    settings[fieldName] = value;
                }
            }
            for (let fieldName in settings) {
                if (Object.prototype.hasOwnProperty.call(settings, fieldName)) {
                    let value = settings[fieldName];
                    if (typeof value === "function" && VALUE_SETTINGS_AVOID_EVALUATION_FIELDS.indexOf(fieldName) === -1) {
                        settings[fieldName] = value.call(settings, fieldName);
                    }
                }
            }
            settingsArray.push(settings);
        }
    }
    let normalizeSettings = options.normalizeSettings;
    if (typeof normalizeSettings !== "function") {
        normalizeSettings = defaultNormalizeSettings;
    }
    return normalizeSettings(settingsArray);
}

function defaultNormalizeSettings(settings) {
    return settings;
}

export default {
    buildFieldComponentSettings
};