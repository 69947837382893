import React, { useCallback, useState } from 'react';
import CustomFilterModal from 'Component/pages/components/custom-filter-modal';
import CustomFilterEdit from 'Component/pages/components/custom-filter-edit';
import UdeskList from 'Component/common/udesk-list';
import ConditionFieldComponent from './conditionPostfix';
import { postFieldReviewDataSearchTemplate } from 'src/api/field/reviewData/search-template';
import { postSearchTemplateAdvanced } from 'src/api/search-template/advanced';
import { postSearchTemplateWordsFilterSave } from 'src/api/search-template/wordsFilter/save';
import './style.scss';

const TYPE = 4;

const StrategyKnowledgeItem = (props) => {
    const { onChange } = props;
    const [filterVisible, setFilterVisible] = useState(false);
    const [customId, setCustomId] = useState<number>();
    const [customName] = useState('');

    const fieldAjax = useCallback(() => {
        return new Promise((resolve, reject) => {
            postFieldReviewDataSearchTemplate({
                sourceList: [9],
            }).then(
                (resp) => resolve(resp),
                (reason) => {
                    reject(reason);
                }
            );
        });
    }, []);
    const customSaveAjax = useCallback((data, method) => {
        // data.subjectId = 19144565;
        return postSearchTemplateWordsFilterSave(data);
    }, []);
    const customAjax = useCallback(() => {
        return new Promise((resolve, reject) => {
            postSearchTemplateAdvanced({
                type: TYPE,
                // subjectId: 19144565,
            } as any).then(
                (resp) => resolve(resp),
                (reason) => {
                    reject(reason);
                }
            );
        });
    }, []);

    /**
     * 扩展组件是否显示在新的一行
     * @param position 扩展字段显示的位置信息 0：同一行末尾， 1：下一行， 此处只做函数调用的位置标记，并不决定显示不显示
     * @param data 当前行的数据
     * @returns true：显示， false：不显示，当不存在此函数且存在扩展字段组件时将默认显示在同一行末尾
     */
    const postfixComponentRenderInLine = (position, data) => {
        let isRender = false;
        if (position) {
            isRender = ['f_107', 'f_108', 'f_109', 'f_110'].includes(data?.field);
            if (!isRender) {
                delete data.postfix;
            }
        }
        return isRender;
    };

    const cancelHandle = () => {
        setFilterVisible(false);
    };

    const handleConditionApply = (conditionList, customJudgeLogic, judgeStrategy, id, name) => {
        onChange({
            conditionList,
            customJudgeLogic,
            judgeStrategy,
        });
        cancelHandle();
    };

    const onConditionFilterSaved = (flag, data) => {
        if (data) {
            setCustomId(data.id);
        }
    };

    return (
        <div>
            <CustomFilterModal
                visible={filterVisible}
                cancel={cancelHandle}
                applyAction={handleConditionApply}
                onConditionFilterSaved={onConditionFilterSaved}
                judgeStrategy={props.value?.judgeStrategy}
                customJudgeLogic={props.value?.customJudgeLogic}
                customName={customName}
                customId={customId}
                conditionList={props.value?.conditionList ?? []}
                type={TYPE}
                // 因为是新的接口，这里无需传参，保证有值即可
                querryType={'empty'}
                filterList={['empty']}
                // 筛选项接口
                fieldAjax={fieldAjax}
                // 常用筛选接口
                customAjax={customAjax}
                // 常用筛选保存接口
                customSaveAjax={customSaveAjax}
                filterTaskData={true}
                postfixComponentRenderInLine={postfixComponentRenderInLine}
                conditionPostfixComponent={ConditionFieldComponent}
                // operatorsFilter={operatorsFilter}
                isAutoHideOperator={true}
            />
            <CustomFilterEdit
                cusBtnProps={{ type: 'default', icon: null }}
                onClick={() => setFilterVisible(true)}
                {...props.value}
            />
            {/* {Array.isArray(props.value?.conditionList) && props.value?.conditionList.length ? (
                <div>111</div>
            ) : (
                <Button onClick={() => setFilterVisible(true)}>123</Button>
            )} */}
        </div>
    );
};

const StrategyKnowledge = (props) => {
    const { value = [], onChange } = props;

    const onRuleItemChange = (index, newValue) => {
        const newList = [...value];
        newList[index] = newValue;
        onChange(newList);
    };

    return (
        <div className="udesk-qa-strategy-knowledge-list">
            <UdeskList
                deleteItem={(index) => {
                    let newValue = [...value];
                    newValue[index] = null;
                    onChange?.(newValue.filter(Boolean));
                }}
                // addBtnDisabled={RiskSettingRuleItem.loading}
                addItem={() => {
                    onChange?.([
                        ...value,
                        {
                            judgeStrategy: 1,
                            customJudgeLogic: '',
                            conditionList: [],
                        },
                    ]);
                }}
            >
                {value.map((item, index) => {
                    return (
                        <StrategyKnowledgeItem
                            value={item}
                            index={index}
                            onChange={onRuleItemChange.bind(null, index)}
                        ></StrategyKnowledgeItem>
                    );
                })}
            </UdeskList>
        </div>
    );
};

export { StrategyKnowledge };
