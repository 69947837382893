import React from 'react';
// import Udesk from 'Udesk';
import RelatedRecordsListComponent from './component';
import { Button } from 'udesk-ui';
import './style.scss';
import _isEmpty from 'lodash-es/isEmpty';
import _isEqual from 'lodash-es/isEqual';
import ClassNames from 'classnames';
export default class RelatedRecordsListTemplate extends RelatedRecordsListComponent {
    render() {
        let { relatedDatas, relatedDatasPaging, selectedDatum } = this.props;
        let {
            actions,
            // privates,
            locales
        } = this;
        let { loading } = this.privates;
        return (
            <div className='related-records-list-page'>
                <div className='related-records-list-page-content'>
                    {!_isEmpty(relatedDatas) &&
                        relatedDatas.map(item => {
                            return (
                                <div
                                    className={ClassNames('related-records-list-page-content-list', {
                                        'related-records-list-page-content-list-active':
                                            selectedDatum && _isEqual(item.callId, selectedDatum)
                                    })}>
                                    <div className='related-records-list-page-content-list-one'>
                                        {!_isEmpty(item.showFieldList) &&
                                            item.showFieldList.map(items => {
                                                return (
                                                    <div className='related-records-list-page-content-list-one-content'>
                                                        <span className='related-records-list-page-content-list-title'>
                                                            {items.label}
                                                        </span>
                                                        <span>
                                                            {!_isEmpty(item.fieldDataList) &&
                                                                item.fieldDataList[0].fieldValueMap[items.id]}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div className='related-records-list-page-content-list-three'>
                                        <span className='related-records-list-page-content-list-title'>
                                            {
                                                locales.components.pages.appealChekingTask.components.relatedRecord
                                                    .qualityList
                                            }
                                        </span>
                                        <div className='related-records-list-page-content-list-three-content'>
                                            {!_isEmpty(item.inspectionTaskList) ? (
                                                item.inspectionTaskList.map(items => {
                                                    return (
                                                        <span
                                                            className='related-records-list-page-content-list-three-card'
                                                            onClick={actions.inspectionTaskOpen.params(
                                                                items.taskId,
                                                                items.id
                                                            )}>
                                                            {items.name}
                                                            <span className='related-records-list-page-content-list-three-card-price'>
                                                                {items.score}
                                                            </span>
                                                        </span>
                                                    );
                                                })
                                            ) : (<span className='related-records-list-page-content-list-three-card-none'>
                                                {
                                                    locales.components.pages.appealChekingTask.components
                                                        .relatedRecord.none
                                                }
                                            </span>)}
                                        </div>
                                    </div>
                                    <div className='related-records-list-page-content-list-four'>
                                        <span
                                            onClick={actions.relatedRecordsFieldSee.params(
                                                item.callId,
                                                item.inspectDataSource
                                            )}>
                                            {locales.components.pages.appealChekingTask.components.relatedRecord.check}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                {!_isEmpty(relatedDatasPaging) && relatedDatasPaging.total > 10 && (
                    <div className='related-records-list-page-footer'>
                        <Button onClick={actions.loadMore.params(relatedDatasPaging)} loading={loading}>
                            {locales.components.pages.appealChekingTask.components.relatedRecord.loadMore}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}
