export default {
  labels: {
    clear: "Pusty",
    refresh: "Odśwież",
    nextStep: "Następny krok",
    prevStep: "Poprzedni krok",
    closeDriver: "Wyjdź z rozruchowego",
    complete: "Kompletny",
    viewStatistics: "Zobacz statystyki",
    colon: "：",
    splitter: "/",
    remark: "Uwagi",
    correction: "Korekcja błędów",
    save: "Zapisz",
    reset: "Zresetuj",
    increase: "Dodaj",
    preview: "Podgląd",
    unit: "Artykuł",
    cancel: "Anuluj",
    all: "Wszystkie",
    searchPlaceHolder: "Wprowadź zapytanie o słowo kluczowe",
    greaterThan: "Większy",
    lessThan: "Mniej",
    second: "Sekundy",
    description: "Opis",
    column: "Kolumna",
    pleaseChoice: "Proszę wybrać",
    filter: "Filtr",
    saveAndSubmit: "Zapisz i Prześlij",
    submit: "Przedłożenie",
    appeal: "Skarga",
    review: "Przegląd",
    choose: "Wybierz",
    manage: "Zarządzanie",
    delete: "Usuń",
    add: "Nowy",
    create: "Nowy",
    edit: "Edytuj",
    name: "Imię i nazwisko",
    describe: "Opis",
    next: "Następny krok",
    percent: "%",
    export: "Eksport",
    exportAll: "Eksportuj wszystkie",
    confirm: "Określ",
    back: "Powrót",
    versionUpdate:
      "Z przyjemnością informuję, że nasza strona właśnie wydała nową wersję! Aby zapewnić normalne użytkowanie, musisz natychmiast zapisać bieżącą pracę, a następnie odświeżyć stronę (kliknij przycisk odświeżania przeglądarki lub naciśnij klawisz F5), aby nasza nowa wersja stała się skuteczna. Dziękuję bardzo za współpracę!",
    more: "Więcej",
    selected: "Wybrane",
    deduct: "Klamra",
    maxScore: "Większość",
    scoreUnit: "Punkty",
    pointUnit: "Jeden",
    comma: "，",
    view: "Widok",
    batchingImport: "Import",
    deleteContent:
      "Czy na pewno chcesz go usunąć? Nie można go przywrócić po usunięciu",
    import: "Import",
    uploadFile: "Prześlij plik",
    downloadFile: "Eksportowanie pliku",
    upload: "Prześlij",
    click: "Kliknij",
    downloadTemplate: "Szablon do pobrania",
    fillInTheForm: "Wypełnij według formatu",
    UploadExcelfile: "Prześlij program Excel, aby zakończyć import wsadowy",
    uploadIdentical:
      "Jeśli nazwa interfejsu API istnieje, istniejące dane w systemie zostaną zaktualizowane podczas importu.",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "Dotyczy",
    leftBracket: "(",
    rightBracket: ")",
    question: "Pytanie",
    answer: "Odpowiedź",
    content: "Treść",
    search: "Szukaj",
    query: "Zapytanie",
    cancelEdit: "Anuluj edycję",
    noValue: "Brak danych",
    selectAll: "Wybierz wszystkie",
    changeAGroup: "Zmienić grupę",
    arrow: " ---> ",
    saveReview: "Zgłosię do przeglądu",
    saveSpotCheck: "Kontrola pobierania próbek i składanie",
    rapidSampling: "Szybkie pobieranie próbek",
    file: "Archiwizacja",
    download: "Pobierz",
    lastPage: "Poprzednia strona",
    nextPage: "Następna strona",
    batchActions: "Operacja partii",
    cancelDistribution: "Wypisuj przypisanie",
    againDistribution: "Zmiana przypisania",
    deleteRecord: "Usuń rekord",
    oneOptionOpertor: "Opcjonalny inspektor jakości",
    allOptionOpertor: "Wszyscy inspektorzy jakości",
    distributionInfoFirstOne: "Potwierdź",
    distributionInfoFirstTwo:
      "Chcesz zmienić przypisanie rekordów? Wybierz cel przydziału.",
    distributionInfoSecondOne: "OK, aby anulować",
    distributionInfoSecondTwo:
      "Czy informacje o zadaniach są zawarte w tym zapisie? Po anulowaniu stanie się nieprzypisanym rekordem recenzji.",
    distributionInfoSecondThree:
      "Jest informacją przydziału tego rekordu, stanie się nieprzypisanym rekordem próbkowania po anulowaniu.",
    distributionInfoThirdOne: "Wybrane rekordy mają",
    distributionInfoThirdTwo: "Przejrzane zapisy nie będą przetwarzane.",
    distributionInfoThirdThree:
      "Zapisy, które zostały pobrane, nie będą przetwarzane.",
    exportError: "Eksport nie powiódł się",
    exportSuccess: "Eksport się powiódł",
    deleteRecordInfo:
      "Czy na pewno chcesz usunąć {0} rekordów? Dane nie mogą być odzyskane po usunięciu",
    pleaseSetFilter: "Proszę wybrać kryteria filtrowania",
    noCountAfterFilter:
      "Brak danych eksportowych, zresetuj kryteria filtrowania",
    redo: "Ponowne wykonanie",
    batchMove: "Przesunięcie partii",
    websiteName: "Inteligentna kontrola jakości",
    salesEnable: "Upodmiotowienie sprzedaży",
    systemName: "Inteligentna kontrola jakości",
    initSystemError:
      "Wyjątek sieci lub awaria inicjalizacji strony, skontaktuj się z obsługą klienta",
    day: "Dzień",
    hour: "Godziny",
    minute: "Punkty",
    questionMark: "？",
    personalSettings: "Ustawienia osobiste",
    logout: "Odpis",
    ok: "Określ",
    saveAs: "Zapisz",
    pleaseEnter: "Proszę wejść",
    executeMessage: {
      executeSuccess: "Pomyślnie wykonane {0}",
      executeError: "Nie udało się wykonać {0}",
    },
    field: "Pole",
    operator: "Operator",
    value: "Wartość",
    placeholder: "Proszę wybrać",
    pleaseSelect: "Proszę wybrać",
    totalScore: "Całkowity wynik",
    hyphen: "-",
    semicolon: "；",
    wfm: "Inteligentne planowanie",
    user: "Użytkownik",
    none: "Brak",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload:
      "Oszczędzaj pomyślnie! Skuteczne po odświeżeniu strony odpowiedniego pracownika",
    saveAsName: "{0}(kopia)",
    systemInformation: "Wyświetl dane systemowe",
    billingCenter: "Centrum rozliczeniowe",
    warningMessage:
      "Pozostała kwota jest niewystarczająca, prosimy o jak najszybsze naładowanie",
    fileTypeHolder:
      "Format pliku jest niepoprawny. Prześlij plik w formacie {0}.",
    dataNo: "Brak danych",
    move: "Przesuń",
    study: "Masz nieoznakowane punkty kontroli jakości samouczące się",
    studySuccess: "Sukces etykietowania",
    studyError: "Etykietowanie nie powiodło się",
    editType: "Edytuj etykiety kategorii",
    copyed: "Skopiowane do Schowka",
    clickToCopy: "Kliknij Kopiuj",
    addFilterConditions: "Dodaj stan filtra",
    filterConditions: "Stan filtra",
    messageTime: "Czas wiadomości",
    messageType: "Rodzaj wiadomości",
    pushType: "Kanał wiadomości",
    messageContent: "Treść wiadomości",
    messageName: "Nazwa wiadomości",
    messageStatus: "Status wiadomości",
    configuration: "Informacje konfiguracyjne",
    sendTarget: "Wyślij przeznaczenie",
    guideUrl: "URL Bootstrap",
    noticeDetail: "Szczegóły wiadomości",
    action: "Operacja",
    seeDetail: "Zobacz szczegóły",
    theDay: "Dzień {0}",
    theLastDayOfMonth: "Ostatni dzień każdego miesiąca",
    ignore: "Ignoruj",
    seeNow: "Zobacz teraz",
    exportConfirmText: {
      export: "W sumie {0} danych, potwierdzić eksport?",
      exportAll: "Czy chcesz wyeksportować całą zawartość?",
    },
    samrTagAdd: "Dodaj tagi",
    deletePopconfirmContent: "Czy na pewno chcesz usunąć?",
    TheLastThirtyDays: "Ostatnie 30 dni",
    addNumber: "Plus",
    subNumber: "Minus",
    addStrategy: "Dodaj politykę",
    minutes: "Minuty",
    status: "Włączony status",
    pushConfig: "Konfiguracja push",
    category: "Klasyfikacja",
    and: "Z",
    interval: "Interwał",
    switchOn: "Otwarte",
    switchOff: "Zamknij",
    batchDel: "Usuń partię",
    exportTxt: "Eksportuj tekst TXT",
    playSpeed: "Podwójna prędkość",
    messageLogBtn:
      "Wyświetl powiadomienia/rozmowy, które wyzwalają powiadomienia",
    dataLink: "Łącze danych",
    yes: "Tak",
    no: "Nie",
    sentenceUnit: "Zdanie",
    appealDetail: "Szczegóły dotyczące skargi",
    detail: "Szczegóły",
    default: "Domyślnie",
    customer: "Klient",
    agent: "Obsługa klienta",
    sort: "Sortuj",
    applyNow: "Aplikacja",
    index: "Numer seryjny",
    allColumns: "Kolumny, które można dodać",
    selectedColumns: "Dodano kolumny",
    syn: "Synchronizacja",
    saveAndNext: "Zapisz i utwórz następny",
    viewReference: "Wyświetl odniesienia",
    referenceDetail: "Szczegóły referencyjne",
    goToQaList: "Przejdź do kontroli jakości",
    goToCheck: "Idź do kontroli na miejscu",
    businessCard: "Wizytówka",
    weapp: "Mały program",
    redpacket: "Czerwona koperta",
    externalRedpacket: "Wymień czerwone koperty",
    sphfeed: "Numer wideo",
    exportTaskCreated: "Pomyślnie utworzone zadanie eksportu",
    plzGoTo: "Proszę skoczyć",
    importTaskCreated: "Pomyślnie utworzone zadanie importowe",
    taskCreated: "Zadanie utworzone pomyślnie",
    reply: "Odpowiedź",
    replied: "Odpowiedź",
    release: "Opublikuj",
    expandMore: "Rozwiń inne {0} odpowiedzi",
    comment: "Uwagi",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "Proszę mieć co najmniej jeden {0}",
      canNotBeEmptyFormat: "{0} nie może być puste",
      repeatErrorTipFormat: "{0} ma duplikaty",
      getInformationFailedFormat:
        "Nie udało się uzyskać {0} informacji. Sprawdź połączenie sieciowe lub spróbuj ponownie później",
      deleteSuccessFormat: "Pomyślnie usunięto {0}.",
      deleteFailedFormat: "Nie udało się usunąć {0}. Spróbuj ponownie później.",
      saveSuccessFormat: "Pomyślnie zapisany {0}.",
      saveFailedFormat: "{0} nie udało się zapisać. Spróbuj ponownie później.",
      fileSuccessFormat: "{0} pomyślnie zarchiwizowana.",
      fileFailedFormat:
        "Archiwizacja {0} nie powiodła się. Spróbuj ponownie później.",
      exportSuccessFormat: "{0} export zakończył się sukcesem",
      exportFailedFormat:
        "Eksport {0} nie powiódł się. Spróbuj ponownie później.",
      submitSuccessFormat: "Pomyślnie przesłane {0}.",
      submitFailedFormat:
        "Nie udało się przesłać {0}, spróbuj ponownie później.",
      confirmSuccessFormat: "Potwierdzenie {0} zakończyło się sukcesem.",
      confirmFailedFormat:
        "Potwierdzenie {0} nie powiodło się. Spróbuj ponownie później.",
      pleaseUploadFile: "Najpierw prześlij plik!",
      importFileSuccess: "Import plików zakończył się sukcesem!",
      importFildFailed: "Import plików nie powiódł się!",
      deleteSuccess: "Usunięty pomyślnie!",
      deleteFailed: "Usuń nie powiodło się, spróbuj ponownie później!",
      illegalTitle: "Niewystarczające uprawnienia",
      illegalMessage:
        "Nie masz niektórych lub wszystkich uprawnień do następnego kroku, skontaktuj się z administratorem, aby skonfigurować, kliknij przycisk OK, aby wyjść z rozruchu",
      illegalMessageCurrent:
        "Nie masz niektórych lub wszystkich uprawnień dla bieżącego kroku, skontaktuj się z administratorem, aby skonfigurować, kliknij przycisk OK, aby wyjść z rozruchu",
      skipMessage: "Jeśli chcesz na razie pominąć ten krok, kliknij Pomiń",
      errorMsg500:
        "Żądanie serwera nie powiodło się, sprawdź swoją operację lub odśwież i spróbuj ponownie",
      errorMsg404: "Ups... brakuje strony, której szukasz",
      errorMsg400:
        "Żądanie serwera nie powiodło się, sprawdź swoje dane i spróbuj ponownie",
      pageOverFlow:
        "Obecnie można wyświetlić tylko 10000 pasków. Proszę ustawić kryteria filtrowania, aby wyświetlić nadmiar.",
      isHandAddSmartTag: "Jest ręcznie dodaną inteligentną etykietą",
      defaultGradeMustAtLast:
        "Ocena domyślna musi być umieszczona na samym końcu",
      AutomaticAllocationInspectorsIsNull:
        "Proszę wejść na stronę z podstawowymi informacjami, aby dodać inspektora jakości",
      timePickerTips:
        "Aby nie wpływać na normalne użytkowanie, 23:00 ~ 2:00 to czas konserwacji systemu, nie wykonuj zaplanowanych zadań.",
      batchDeleteInfo:
        "{0} wpisów zostało usuniętych, a {1} wpisów nie powiodło się, ponieważ dane zostały użyte.",
      batchDeleteTips: "Sprawdź {0} danych, potwierdź, aby usunąć?",
      correctionSuccess: "Sukces korekcji błędów",
    },
    pages: {
      qualityInspectionForm: {
        list: {
          IsMaxFormNumberError: "Dodaj do 10 formularzy kontroli jakości",
        },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip:
            "Pozycje metryczne {0} muszą dodać maksymalnie 100",
          banProjectMaxErrorTip: "Dodaj do 2000 przedmiotów zabronionych",
          deadlyProjectMaxErrorTip: "Dodaj do 500 przedmiotów śmiertelnych",
        },
      },
    },
    info: {
      creatSuccess: "Pomyślnie stworzony",
      creatError: "Nie udało się utworzyć",
      editSuccess: "Modyfikacja się powiodła",
      editError: "Modyfikacja nie powiodła się",
      deleteSuccess: "Pomyślnie usunięte",
      addSuccess: "Nowy sukces",
      deleteError: "Usuń nie powiodło się",
      conditionError: "Błąd wyrażenia logicznego operatora",
      customJudgeLogic:
        "Błąd wyrażeń logicznych kombinacji warunków niestandardowych",
      exportError: "Eksport nie powiódł się",
      searchError: "Filtr nie powiódł się",
      loadError: "Nie udało się załadować {0}",
      saveSuccess: "Oszczędzaj pomyślnie",
      saveError: "Nie udało się zapisać",
      fileSuccess: "Sukces archiwizacji",
      fileError: "Archiwum nie powiodło się",
      cannotBeNull: "{0} nie może być puste!",
      pleaseSearchAndSelect: "Proszę wpisać słowo kluczowe wyszukiwania",
      clickAndToggle: "Kliknij, aby wybrać przełącznik",
      deleteWarning:
        "{0} usunięcia nie da się odzyskać, czy na pewno chcesz kontynuować?",
      getOssAuthFailed:
        "Nie udało się uzyskać autoryzacji OSS. Sprawdź połączenie sieciowe lub spróbuj ponownie później.",
      getInsightAuthFailed:
        "Nie udało się uzyskać autoryzacji Insight. Sprawdź połączenie sieciowe lub spróbuj ponownie później.",
      pictureUploadFailed: "Przesyłanie obrazu nie powiodło się",
      pictureUploadFailedSize:
        "Rozmiar obrazu przekracza limit, przesyłanie obrazu nie powiodło się",
      wecomTagsSyncInfo:
        "Ostatnia aktualizacja:{0}, synchronizacja {1} wiadomości",
      collectSuccess: "Sukces kolekcji.",
    },
  },
  components: {
    common: {
      qcReportEcharts: { errorMessage: "Nie udało się uzyskać danych raportu" },
      waveAudio: {
        noWaveMessage:
          "Aby zachować wrażenia, przebieg nie będzie wyświetlany w przypadku nagrań powyżej {0} minut. Możesz grać bezpośrednio.",
      },
      customFilter: {
        del: "Usuń",
        edit: "Edytuj",
        new: "Filtr niestandardowy",
        apply: "Aplikacja",
        cancel: "Anuluj",
        onlyApply: "Dotyczy tylko",
        ohterSave: "Zapisz",
        save: "Zapisz",
        customFilter: "Wspólny filtr",
        reset: "Zresetuj",
        holderKeyWord: "Wpisz słowo kluczowe",
        customFilterRule: "Zasady logiki filtra:",
        customFilterName: "Nazwa filtra:",
        holderCustomFilterName: "Wprowadź nazwę filtra",
        manualConfiguration: "Ręczna konfiguracja",
        holderCustomRule: "Wprowadź niestandardowy warunek logiczny",
        example: "Przykład",
        dragConfiguration: "Konfiguracja przeciągnij i upuść",
        condition: "Stan",
        operation: "Operacja",
        infoDel: "Przeciągnij zawartość poza obszar, aby usunąć",
        toConfigure: "Konfiguracja",
        holderCustomFilterNameLength:
          "Nazwa filtra maksymalna długość wynosi 10",
        customFilterNameNone: "Nazwa filtra nie może być pusta",
        and: "I",
        or: "Lub",
        not: "Nie",
        delSuccess: "Pomyślnie usunięte",
        saveSuccess: "Oszczędzaj pomyślnie",
        updateSuccess: "Modyfikacja się powiodła",
        customFilterCondition: "Stan filtra",
        conditionRule: "Zasady warunku",
        none: "Brak",
        filterPlaceholder: "Wpisz słowo kluczowe",
      },
      labelTabs: {
        addThis: "Dodaj do tego",
        goThis: "Przenieś się do tego",
        saveGo: "Oszczędzaj i trenuj",
      },
      datePicker: {
        today: "Dzisiaj",
        yesterday: "Wczoraj",
        theDayBeforeYesterday: "Przedwczorajszy dzień",
        thisWeek: "W tym tygodniu",
        lastWeek: "W zeszłym tygodniu.",
        thisMonth: "W tym miesiącu",
        lastMonth: "W zeszłym miesiącu",
        lastSevenDay: "Ostatnie 7 dni",
        lastThirtyDay: "Ostatnie 30 dni",
        thisQuarter: "W tym kwartale",
        lastQuarter: "Ostatni kwartał",
        thisYear: "W tym roku",
        lastYear: "W zeszłym roku",
        lastYearFromToday: "W zeszłym roku",
      },
      pagination: { page: "Strona {1} z {0}" },
      wechatRecord: {
        loadMoreBtn: {
          top: "Załaduj wcześniejsze wiadomości",
          bottom: "Załaduj więcej wiadomości",
        },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "Zadanie kontroli jakości",
        informationCollectTasksManageTitle: "Zadania zbiórki",
        customerServiceCenterTitle: "Centrum obsługi klienta",
        informationTitle: "Centrum danych",
        intelligentAnalysisTitle: "Inteligentna analiza",
        semanticIntelligence: "Inteligencja semantyczna",
        trainingCenterTitle: "Centrum szkoleniowe",
        adminTitle: "Zarządzanie systemem",
      },
      qualityCheckingTask: {
        title: "Zadzwoń do inspekcji jakości",
        index: {
          tipOne:
            "Kontrola jakości połączeń służy do oceny jakości pracy pracowników w call center. Możesz skonfigurować odpowiednie formularze kontroli jakości w oparciu o swoją firmę.",
          tipTwo:
            "Można ustanowić odpowiednie zasady dotyczące zadań kontroli jakości, w tym filtrowania i pobierania próbek. Po wynikach inspektora jakości obsługa klienta może przeglądać wyniki kontroli jakości i dalsze odwoływanie się,",
          addTaskTitle: "Dodaj zadanie kontroli jakości",
          deleteTipTitle: "Usuń zadanie kontroli jakości",
          deleteTipContent:
            "Zadania kontroli jakości nie mogą zostać przywrócone po usunięciu. Czy na pewno chcesz je usunąć?",
          checkingTask: "Zadanie kontroli jakości",
          checkingTaskList: "Lista zadań związanych z inspekcją jakości",
          formList: "Lista formularzy",
          viewHelpDocuments: "Wyświetl dokumentację pomocy",
        },
        manage: {
          taskSetting: {
            title: "Ustawienia zadań",
            form: "Formularz",
            basicInfo: "Podstawowe informacje",
            visibleRange: "Widoczny zasięg",
            visibleRangeTip:
              "Wybrany zespół obsługi klienta i obsługi klienta może wyświetlić zadanie kontroli jakości. Ponadto lider zespołu obsługi klienta, inspektor obsługi klienta i jakości można połączyć z ustawieniami uprawnień do tego zadania.",
            automaticSampling: "Automatyczne pobieranie próbek",
            automaticSamplingTip:
              "Możesz ustawić automatyczne zasady próbkowania, aby system okresowo dodawał zadania kontroli jakości zgodnie z ustalonymi regułami, na przykład odbierając 5% połączeń z zeszłego tygodnia w każdy poniedziałek.",
            samplingRate: "Częstotliwość próbkowania",
            samplingTime: "Czas próbkowania",
            samplingRange: "Zakres próbkowania",
            samplingRule: "Zasady pobierania próbek",
            fillterCondition: "Stan filtra",
            workflow: "Przepływ pracy inspekcji jakości",
            appealsTime: "Daj czas na odwołanie",
            appealsTimeTipOne: "Po złożeniu wyniku",
            appealsTimeTipTwo: "W ciągu kilku dni",
            appealsTimeTipThree:
              'Jeśli czas odwołania zostanie przekroczony, status kontroli jakości rekordu zostanie automatycznie zmieniony na Potwierdzony"',
            auditTime: "Czas przetwarzania audytu",
            auditTimeTipOne: "Po złożeniu skargi",
            auditTimeTipTwo: "W ciągu kilku dni",
            auditTimeTipThree:
              'Po przekroczeniu czasu przetwarzania zatwierdzenia, rekord zachowuje oryginalny wynik, a status kontroli jakości jest automatycznie zmieniany na Potwierdzony"',
            noAppealsMode: "Tryb bez skarg",
            appealsMode: "Zezwalaj na tryb odwołania",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "Obsługa klienta",
            customerServicesGroup: "Grupa Obsługi Klienta",
            surveyList: "Lista ewaluacyjna",
            customFields: "Pola niestandardowe",
            fieldConfig: "Konfiguracja pola",
            from: "Od",
            owner: "Osoba odpowiedzialna",
            inspectorAndReviewerAuthority: "Organ kontroli jakości",
            inspector: "Inspektor jakości",
            reviewer: "Recenzent",
            inspectorTip: "Inspektor może zdobyć zadanie",
            reviewerTip: "Recenzent może ocenić zadanie",
            dataStatisticsAuthority: "Zezwolenie na statystyki danych",
          },
          samplingRecords: {
            title: "Zapisy dotyczące pobierania próbek",
            index: {},
            manualSampling: {
              title: "Pobieranie próbek",
              fillterCondition: "Stan filtra",
              samplingRule: "Zasady pobierania próbek",
              agent: "Obsługa klienta",
              type: "Rodzaj",
              result: "Wyniki",
              evaluate: "Ocena",
              relayNumber: "Numer przekaźnika",
              source: "Źródło",
              task: "Zadania",
              queueState: "Status kolejkowania",
              callHanger: "Zadzwoń na imprezę",
              queueTime: "Czasochłonne kolejkowanie",
              talkTime: "Czas trwania połączenia",
              bellTime: "Czas trwania pierścienia",
              recordCountTipOne: "Ogółem",
              recordCountTipTwo: "Rekord",
              customerServices: "Obsługa klienta",
              customerServicesGroup: "Grupa Obsługi Klienta",
              surveyList: "Lista ewaluacyjna",
              customFields: "Pola niestandardowe",
              fieldConfig: "Konfiguracja pola",
              defaultFields: "Domyślne pola",
            },
          },
          taskList: {
            title: "Lista zadań",
            index: {
              batchDelete: "Usuń partię",
              batchSubmit: "Składanie partii",
              batchConfirm: "Potwierdzenie partii",
              itemFields: "Pole do oceny przedmiotu",
              categoryFields: "Wtórne pole klasyfikacji",
              forbidsAndDeadlies: "Przedmioty zabronione i śmiertelne",
              scoreFieldList: "Lista punktów w terenie",
              customerName: "Nazwa klienta",
              cellphone: "Telefon",
              email: "Skrzynka pocztowa",
              organization: "Firma",
              description: "Opis",
              tags: "Etykieta",
              taskUserRole: "Bieżąca rola użytkownika zadania",
            },
            workBench: {
              title: "Szczegóły zapisu",
              checkForm: "Formularz inspekcji jakości",
              waitEvaluate: "Do oceny",
              alreadyEvaluate: "Oceniony",
              waitSubmit: "Do złożenia",
              alreadySubmit: "Przesłane",
              waitConfirm: "Do potwierdzenia",
              alreadyConfirm: "Potwierdzone",
              waitReview: "Do przeglądu",
              alreadyReview: "Sprawdził",
              alreadyDone: "Ukończone",
              comment: "Uwagi dotyczące oceny",
              totalScore: "Całkowity wynik",
              score: "Punkty",
              remark: "Uwagi",
              changeRecords: "Zmień rekord",
              subject: "Motyw",
              secondLevelScore: "Ocena II stopnia",
              deadlyList: "Fatalny przedmiot",
              forbidList: "Przedmioty zabronione",
              addToCheckTask: "Dodaj do zadania jakości",
              submitCheckRemark: "Przedłożona ocena kontroli jakości",
              changeCheckRemark: "Ocena kontroli jakości została zmodyfikowana",
              doneCheckRemark: "Zakończono ocenę kontroli jakości",
              submitCheckReview: "Przesłane do przeglądu kontroli jakości",
              submitCheckAppeal: "Złożono odwołanie od kontroli jakości",
              saveFlowSuccess: "Oceń sukces.",
              saveFlowFailed:
                "Ocena nie powiodła się, spróbuj ponownie później.",
              FormDetail: "Szczegóły formularza",
              callDetail: "Szczegóły dziennika połączeń",
              sampleCalllogDetail: "Szczegóły zapisu",
              appealReasonTip: "Proszę podać powód odwołania (wymagany)",
              appeal: "Skarga",
              appealReason: "Podstawy skargi",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} Formularz inspekcji jakości",
        basicInformationTitle: "Podstawowe informacje",
        indicatorsProjectTitle: "Pozycja wskaźnikowa",
        specialProjectTitle: "Przedmioty specjalne",
        totalScore: "Całkowity wynik",
        indicatorsName: "Nazwa wskaźnika",
        score: "Frakcja",
        singleScore: "Wynik pojedynczego przedmiotu",
        weight: "Waga",
        maxDeductScore: "Maksymalne odliczenie punktów",
        add: "Zwiększ",
        detail: "Szczegóły formularza",
        list: {
          title: "Formularz inspekcji jakości",
          tipOne:
            "Konfiguracja formularza kontroli jakości jest pierwszym krokiem w pracach kontroli jakości. Możesz skonfigurować najbardziej odpowiedni formularz kontroli jakości w oparciu o działalność Twojej firmy.",
          tipTwo:
            "Wspieramy różne formy, takie jak punktacja bezpośrednia, punktacja ważona i systemy odliczania punktów. Zaleca się skonfigurowanie reguł przed dodaniem ich do systemu.",
        },
        basicInformation: {
          formTemplate: "Formularz Szablon",
          directScore: "Bezpośrednia ocena",
          weightedScore: "Ocena wagi",
          deductScore: "Odliczenia",
          directScoreSecondLevel: "Bezpośrednia ocena-poziom 2",
          weightedScoreSecondLevel: "Waga Score-Poziom 2",
          deductScoreSecondLevel: "Odliczanie punktów-poziom 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "Przedmioty zabronione",
          banProjectTip:
            "Może być używany do sprawdzania podczas punktacji kontroli jakości. Można to uznać za naruszenie lub uzupełnienie komentarzy bez wpływu na rzeczywisty wynik.",
          deadlyProject: "Fatalny przedmiot",
          deadlyProjectTip:
            "Śmiertelne przedmioty można wykorzystać do sprawdzenia podczas punktacji kontroli jakości. Jeśli sprawdzany jest element fatalny, zadanie kontroli jakości jest bezpośrednio zdobywane 0 punktów.",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "Zadzwoń do inspekcji jakości",
          entiretyReportTitle: "Sprawozdanie ogólne",
          serviceGroupReportTitle: "Raport grupy obsługi klienta",
          serviceReportTitle: "Raport z obsługi klienta",
          entiretyReport: {
            pandect: "Przegląd",
            contrast: "Kontrast",
            trend: "Trend",
            analyze: "Analiza",
            totalScore: "Całkowity wynik",
            banProject: "Przedmioty zabronione",
            deadlyProject: "Fatalny przedmiot",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "Lista wyników kontroli jakości",
          taskSelectPlaceholder: "Wybierz zadanie jakości",
          agentSelectPlaceholder: "Wybierz obsługę klienta",
          refresh: "Odśwież",
          all: "Wszystkie",
          unread: "Nieprzeczytane",
          inComplaint: "W skardze",
          reviewed: "Sprawdził",
          totalScore: "Uwagi ogólne",
          detail: "Szczegóły kontroli jakości",
          audioText: "Nagrany tekst",
          callInfo: "Informacje o połączeniu",
          ticketInfo: "Informacje o zamówieniu pracy",
          wechatInfo: "Informacje o sesji",
          dialogText: "Tekst dialogu",
          dialogInfo: "Informacje o dialogu",
          customer: "Klient",
          agent: "Obsługa klienta",
          none: "Brak danych dotyczących kontroli jakości",
          appealSuccess: "Odwołanie się powiodła!",
          appealFailed: "Odwołanie nie powiodło się, spróbuj ponownie później!",
          pointListIsNull:
            "Punkt kontroli jakości reklamacji nie może być pusty!",
          pleaseSelectPoint: "Proszę wybrać punkt kontroli jakości!",
          unsupportedFormat: "Ten format nie jest obsługiwany.",
          shardingInformation: "Poniżej znajduje się odłamek {0}",
          smartTags: "Inteligentna etykieta",
          businessRecords: "Dokumentacja biznesowa",
          selectedPointsWarning:
            "Sprawdź elementy kontroli jakości, aby się odwołać, określ odpowiednie oświadczenia dotyczące elementów odwołania, a następnie kliknij Prześlij.",
          applyReason: "Podstawy odwołania (maks. 50 słów)",
          chatMessageTypes: {
            all: "Wszystkie",
            text: "Tekst",
            image: "Zdjęcie",
            agree: "Zawartość sesji zgody",
            video: "Wideo",
            voice: "Głos",
            file: "Plik",
            emotion: "Wyrażenie",
            link: "Link",
            chatrecord: "Zapis czatu",
            mixed: "Wiadomość mieszana",
            default: "Wiadomość nie jest obsługiwana",
            deleteFriend: "Usuń znajomego",
            redpacket: "Czerwona koperta",
            card: "Wizytówka",
            disAgree: "Nie zgadzam się z treścią konwersacji",
            weapp: "Mały program",
            externalRedpacket: "Wymień czerwone koperty",
            meetingVoicevCall: "Zadzwoń do wiadomości",
            uRL: "Strona internetowa",
            replyTimeout: "Odpowiedź Timeout",
            sphfeed: "Numer wideo",
            chatRecordTitle: "Zapis czatu",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "Całkowity wynik",
            actualScore: "Ocena",
            actualGrade: "Ocena",
            appeal: "Skarga",
            remark: "Uwagi ręczne",
            none: "Brak szczegółowych danych do kontroli jakości",
            pointEdit: "Klasyfikacja edycji punktów kontroli jakości",
          },
          changeList: {
            title: "Zmień rekord",
            user: "Użytkownik",
            point: "Punkt kontroli jakości",
          },
          relatedRecord: {
            title: "Powiązane zapisy",
            qualityList: "Zadanie kontroli jakości",
            none: "Brak",
            check: "Widok",
            loadMore: "Załaduj więcej",
          },
        },
      },
      grade: {
        title: "Ocena jakości kontroli",
        subTitle:
          "Ocena kontroli jakości służy do oceny obiektów kontroli jakości po zakończeniu punktacji kontroli jakości. Na przykład może być skonfigurowany jako niekwalifikowany, jeśli jest niższy niż 60, dobry, jeśli jest wyższy niż 60, i doskonały, jeśli jest wyższy niż 80.",
        list: {
          up: "Przesuń w górę",
          down: "Przesuń w dół",
          del: "Usuń",
          case: "Automatycznie dołącz do biblioteki spraw",
          orderCase: "Domyślna biblioteka przypadków",
          info: "Ocena domyślna nie ma odpowiednich kryteriów i nie może zostać usunięta. Jeśli którakolwiek z powyższych ocen nie zostanie spełniona, zostanie uzyskana ocena domyślna.",
          add: "Dodaj ocenę",
          save: "Zapisz",
          close: "Anuluj",
          max: "Ocena kontroli jakości może być skonfigurowana do 20",
          name: "Nienazwany",
          success: "Oszczędzaj pomyślnie",
        },
      },
      qualityInspectionList: {
        title: "Lista inspekcji jakości",
        fastFilter: "Naruszenie kontroli jakości",
        fastFilterTip:
          "Uderzenie w system minus-punktowy jest naruszeniem, a chybienie w systemie plus-point jest narusze niem.",
        titleWechat: "Lista analityczna",
        titleCollect: "Lista nabycia",
        status: "Status analizy",
        reQualityInspectionConfig: "Opcje zadań rejakości",
        pleaseSelectRecheckPoints:
          "Wybierz punkt kontroli jakości, który ma zostać ponownie obliczony",
        beginReQualityInspection: "Rozpocznij wykonanie",
        cancelReQualityInspection: "Anuluj zadanie",
        reQualityInspection: "Ponowna inspekcja",
        reQualityInspectionWechat: "Ponowna analiza",
        reQualityInspectionUnderWay: "Ponowna inspekcja zadania...",
        reQualityInspectionUnderWayTip:
          "Opuszczenie strony po zamknięciu okna nie wpływa na zadanie",
        reQualityInspectionDone: "Ponowna inspekcja zakończona",
        reQualityInspectionDoneTip:
          "Ponowna inspekcja została pomyślnie zakończona, kliknij przycisk potwierdzenia.",
        endReQualityInspection: "Zakończcie zadanie",
        alreadyDoneTip: "{0} ukończone",
        totalTip: "Razem {0}",
        endReInspectionSuccess: "Zadanie o ponownej jakości zakończone",
        closeWindow: "Zamknij okno",
        endReInspectionFailed:
          "Zadanie o ponownej jakości nie powiodło się, spróbuj ponownie później!",
        detail: {
          title: "Szczegóły kontroli jakości",
          wechatTitle: "Szczegóły analizy",
          titleCollect: "Szczegóły kolekcji",
          callIndex: "Połączenie powiązane",
          textIndex: "Rozmowy powiązane",
          fileConfirm: "Potwierdzić zgłoszenie?",
          fileConfirmDesc:
            "Po archiwizacji nie można edytować wyniku kontroli jakości. Potwierdź, czy archiwizować.",
        },
        sysCloumnsContal: "Zarządzanie polem kolumny systemu",
        personCloumnsContal: "Zarządzanie polem kolumny osobistej",
      },
      spotCheckList: {
        title: "Lista pobierania próbek",
        onlyMyTip: "Wyświetl tylko moje pobieranie próbek",
        plInspector: "Proszę wybrać personel do pobierania próbek",
        allInspector: "Zobacz wszystkie",
        seInspector: "Zobacz wyznaczonego inspektora pobierania próbek",
        myInspector: "Wyświetl tylko mój",
        noInspector: "Sprawdź nieprzydzielonego inspektora pobierania próbek",
        detail: { title: "Szczegóły dotyczące pobierania próbek" },
      },
      reviewList: {
        title: "Lista przeglądowa",
        onlyMyTip: "Wyświetl tylko moją recenzję",
        plInspector: "Wybierz recenzenta",
        allInspector: "Zobacz wszystkie",
        seInspector: "Wyświetl wyznaczonego recenzenta",
        myInspector: "Wyświetl tylko mój",
        noInspector: "Wyświetl nieprzypisanego recenzenta",
        detail: { title: "Szczegóły przeglądu" },
      },
      caseBaseList: {
        title: "Biblioteka przypadków",
        del: "Usuń",
        delTitle: "Cała zawartość nie zostanie zapisana po usunięciu",
        cancel: "Anuluj",
        actions: "Operacja",
        seeCaseBase: "Zobacz etui",
        seeDetail: "Zobacz szczegóły",
        edit: "Edytuj",
        save: "Zapisz",
        addCaseBase: "Nowa biblioteka przypadków",
        export: "Eksport",
        ok: "Określ",
        tip: "Typowe dane rozmów i rozmów na liście kontroli jakości można zapisać w bibliotece spraw w celu łatwego uczenia się i udostępniania.",
        detail: {
          choiceCaseBase: "Proszę wybrać etui",
          delCaseBase: "Usuń skrzynkę",
          addCaseBase: "Dodaj etui",
          successed: "Oszczędzaj pomyślnie",
          info: "Najpierw zapisz lub anuluj zawartość, którą edytujesz",
          caseType: "Wybierz bibliotekę spraw, do której chcesz dołączyć",
        },
      },
      testSetList: {
        title: "Zestaw do badań kontroli jakości",
        tip: "Dane pobrane przez inspektora jakości zostaną zapisane w zestawie testów kontroli jakości, co jest wygodne, aby zoptymalizować konfigurację warunków kontroli jakości i zasad dla niektórych elementów błędów kontroli maszyny w porównaniu po kontroli próbek.",
        refresh: "Odśwież",
        reQualityInspectionUnderWay: "Ponowna ocena misji w...",
        reQualityInspectionUnderWayTip:
          "Opuszczenie strony po zamknięciu okna nie wpływa na zadanie",
        reQualityInspectionDone: "Ponowna ocena zakończona",
        reQualityInspectionDoneTip:
          "Ponowna ocena została pomyślnie zakończona, kliknij przycisk Potwierdź",
        endReQualityInspection: "Zakończcie zadanie",
        alreadyDoneTip: "{0} ukończone",
        totalTip: "Razem {0}",
        endReInspectionSuccess: "Zadanie ponownej oceny zakończone",
        closeWindow: "Zamknij okno",
        endReInspectionFailed:
          "Ponowna ocena nie powiodła się, spróbuj ponownie później!",
        recheckPoint:
          "Przeliczenie wyników punktów kontroli jakości i dokładności",
        recheckPointTitle: "Opcje ponownej oceny",
        delete: {
          reQualityInspectionUnderWay: "Zadania wsadowe Usuń...",
          reQualityInspectionUnderWayTip:
            "Opuszczenie strony po zamknięciu okna nie wpływa na zadanie",
          reQualityInspectionDone: "Zadanie Usuń partię zakończone",
          reQualityInspectionDoneTip:
            "Usuwanie zbiorcze zostało pomyślnie zakończone, kliknij przycisk Potwierdź",
          endReQualityInspection: "Zakończcie zadanie",
          alreadyDoneTip: "{0} ukończone",
          totalTip: "Razem {0}",
          endReInspectionSuccess: "Zakończono zadanie usuwania partii",
          closeWindow: "Zamknij okno",
          endReInspectionFailed:
            "Usunięcie partii nie powiodło się, spróbuj ponownie później!",
        },
        reultList: {
          assessmentTime: "Ostatni czas oceny",
          assessmentAll: "Ocena ogółem",
          strip: "Artykuł",
          spot: "Punkt",
          deviation: "Odchylenie",
          accuracyRate: "Dokładność",
          info: "Brak wyniku oceny, proszę ocenić",
          onlyDeviationInfo: "Wyświetl tylko rekordy odchylenia",
          againAssessment: "Ponowna ocena",
          screen: "Filtr",
          batchDelete: "Usuń partię",
          matching: "Dopasuj",
          ok: "Tak",
          noCancel: "Nie",
        },
      },
      smartWordsLibrary: {
        title: "Inteligentny tezaurus",
        tip1: "Synonimy: stosowane w celu zmniejszenia ilości szkoleń etykiet semantycznych, podobne zdania synonimów nie muszą być wielokrotnie trenowane.",
        tip2: "Profesjonalne rzeczowniki: optymalizuj transkrypcję i rozumienie języka naturalnego pod kątem odpowiednich rzeczowników, takich jak nazwy przedsiębiorstw, nazwy produktów i słowa branżowe, które nie są dokładnie rozpoznawane, aby poprawić dokładność rozpoznawania i dokładność analizy.",
        tip3: "Skoncentruj się na słowach: skoncentruj się na słowach, na których chcesz się skupić w analizie chmury słów, aby pomóc hotspotom analizy sztucznej inteligencji sprostać bieżącej działalności.",
        tip4: "Słowa blokowe: Blokuj słowa, na które nie chcesz zwracać uwagi w analizie chmury słów, aby pomóc AI analizować gorące punkty zgodnie z bieżącym biznesem.",
        tip5: "Waga słowa: służy do korygowania i optymalizacji rozpoznawania etykiet semantycznych. Modyfikując wagę wpływu słów w zdaniach, efekt rozpoznawania semantycznego w określonych scenariuszach można zoptymalizować w razie potrzeby.",
        tip6: "Dane korpusa: wprowadź duże akapity tekstu dla odpowiednich scenariuszy biznesowych w celu transkodowania korekcji błędów i szkolenia ASR, aby poprawić dokładność transkodowania ASR.",
        upload: {
          uploadSynonymWords: "Importowanie synonimów",
          uploadProfessionalWords: "Importuj warunki profesjonalne",
          uploadAttentionWords: "Importuj słowa zainteresowania",
          uploadIgnoreWords: "Import Ignoruj słowa",
          uploadWordsWeight: "Importowane słowa wagi",
          uploadTypo: "Importowanie zestawu słów literówek",
          uploadCorpusData: "Importowanie danych korpusu",
          uploadCorpusContent: "Importowanie zawartości danych corpus",
          firstUploadStep:
            "1. Pobierz szablon i wypełnij go zgodnie z formatem szablonu",
          downloadTemplateMessage: "Pobierz szablon:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. Kliknij przycisk Importuj, aby przesłać plik EXCEl zgodny z formatem szablonu",
          secondUploadCorpusDataStep:
            "2. Kliknij przycisk Importuj, aby przesłać plik TXT zgodny z formatem szablonu.",
          postUploadDataFail: "Nie udało się przesłać i przesłać danych.",
          pleaseUploadTemplate: "Prześlij plik EXCEl/JSON/TXT",
          templateNeedJSONOrXLSX:
            "Szablony plików mogą być tylko w formacie JSON lub xlsx",
          templateNeedTxt:
            "Szablon pliku danych korpusu może być tylko plikiem TXT",
          uploadSuccess: "Import się powiódł",
          uploadFailed: "Import nie powiódł się",
          downloadError: "Pobieranie nie powiodło się",
          downLoadTxtTitle: "Corpus twierdzi. Txt",
        },
        navTabNames: {
          synonymWords: "Synonimy",
          professionalWords: "Profesjonalny rzeczownik",
          attentionWords: "Zaniepokojone słowa",
          ignoreWords: "Ignoruj słowa",
          wordsWeight: "Słowo waga",
          corpusData: "Dane korpusu",
          typoLibrary: "Zestaw literówek",
        },
        synonymWords: {
          createSynonymWords: "Nowy synonim",
          editSynonymWords: "Edytuj Synonimy",
          addSynonymWords: "Dodaj synonim",
          pleaseEnterStandardWords: "Wprowadź standardowe słowo",
          synonymWordsExists: "Synonim już istnieje i nie można go dodać",
          synonymWordsCanNotNull: "Dodany synonim nie może być pusty",
          pleaseAddSynonymWords: "Dodaj synonimy",
          addSuccess: "Nowy sukces",
          editSuccess: "Modyfikacja się powiodła",
          deleteSuccess: "Pomyślnie usunięte",
          deleteError: "Usuń nie powiodło się",
          required: "*",
        },
        professionalWords: {
          create: "Nowe warunki zawodowe",
          edit: "Profesjonalny edytor rzeczowników",
          addTargetWords: "Dodaj słowo zastępcze",
          targetWordsExists:
            "Słowo zastępcze już istnieje i nie można go dodać",
          targetWordsCanNotNull: "Dodane słowo zastępcze nie może być puste",
          pleaseAddTargetWords: "Dodaj słowo zastępcze",
          pleaseEnter: "Wprowadź termin zawodowy",
          wordsExists: "Termin już istnieje.",
          createSuccess: "Dodano pomyślnie",
          editSuccess: "Modyfikacja się powiodła",
          deleteSuccess: "Pomyślnie usunięte",
          deleteError: "Usuń nie powiodło się",
          saveError: "Nie udało się zapisać",
          addTargetWordsMaxNumberError: "Możesz dodać do 100 słów zastępczych",
          addHomophonyWordsMaxNumberError:
            "Możesz dodać do 100 zamaskowane słowa",
        },
        attentionWords: {
          create: "Nowe słowo niepokoju",
          edit: "Zaniepokojony edytor słów",
          pleaseEnter: "Proszę wpisać słowo zainteresowania",
          wordsExists: "Słowo zainteresowania już istnieje.",
          createSuccess: "Dodano pomyślnie",
          editSuccess: "Modyfikacja się powiodła",
          deleteSuccess: "Pomyślnie usunięte",
          deleteError: "Usuń nie powiodło się",
        },
        ignoreWords: {
          create: "Nowe słowo Ignoruj",
          edit: "Ignoruj edytowanie słów",
          pleaseEnter: "Wpisz słowo ignorowania",
          wordsExists: "Słowo ignorowania już istnieje",
          createSuccess: "Dodano pomyślnie",
          editSuccess: "Modyfikacja się powiodła",
          deleteSuccess: "Pomyślnie usunięte",
          deleteError: "Usuń nie powiodło się",
        },
        wordsWeight: {
          createWordsWeight: "Nowe słowo wagi",
          editWordsWeight: "Edytuj słowo wagi",
          addWordsWeight: "Dodaj słowo wagi",
          pleaseEnterWeightWords: "Wpisz słowo wagi",
          pleaseEnterWeight: "Proszę wpisać wagę",
          weightNeedInZeroToHundred: "Waga musi wynosić od 0 do 100",
          synonymWordsExists: "Słowo wagi już istnieje",
          weightNeedNumber: "Waga wymaga formatu liczb całkowitych",
          standardWeightInformation:
            "Waga odniesienia wynosi 50, a im większa waga, tym silniejszy wpływ na analizę.",
          createSuccess: "Dodano pomyślnie",
          editSuccess: "Modyfikacja się powiodła",
          deleteSuccess: "Pomyślnie usunięte",
          deleteError: "Usuń nie powiodło się",
        },
        corpusData: {
          createCorpusData: "Nowe dane Corpus",
          editCorpusData: "Edytuj dane Corpus",
          addCorpusData: "Dodaj dane Corpus",
          pleaseEnterCorpusDescription: "Proszę wpisać opis korpusu",
          pleaseEnterCorpusContent: "Wprowadź treść korpusu",
          synonymWordsExists: "Te dane korpusu już istnieją",
          CorpusContentWordsOverSetting:
            "Liczba słów w treści przekracza 20000 słów,",
          download: "Pobierz",
          upload: "Prześlij",
          see: "Widok",
          canNotSave:
            "Użyj funkcji importu, w przeciwnym razie nie można jej zapisać",
          createSuccess: "Dodano pomyślnie",
          editSuccess: "Modyfikacja się powiodła",
          deleteSuccess: "Pomyślnie usunięte",
          deleteError: "Usuń nie powiodło się",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "Poprawne słowo",
            wrongWord: "Niewłaściwe słowo",
            wrongWordCount: "Liczba błędnych słów",
            wrongWordTip: "Naciśnij Enter, aby dodać",
            status: "Skuteczność",
          },
          create: "Nowy zestaw słów literówki",
          edit: "Edytuj literówki",
          tip: "Zestaw słów literówek służy do optymalizacji efektu „ wykrywania literówek ”. Na przykład, jeśli „ zwrot pieniędzy ”zostanie omyłkowo wysłany jako „ zwrot szerokości”, za pomocą tej funkcji można ustawić wspólne literówki i poprawne słowa, które zaczną obowiązywać następnego dnia po zapisaniu.",
          onlyChinese: "Tylko wsparcie wejściowe chińskie słowa",
          wrongWordLengthErr:
            "Długość każdego złego słowa powinna wynosić 2 ~ 10 słów",
        },
      },
      semanticTags: {
        title: "Etykieta semantyczna",
        current: "Aktualna etykieta semantyczna",
        reference1: "Badanie jakości: {0} - {1}",
        reference2: "Inteligentna etykieta: {0}",
        reference3: "Przetwarzanie wstępne danych: {0}",
        reference4: "Baza wiedzy: {0}",
        index: { import: "Importowanie etykiet semantycznych" },
        detail: {
          new: "Nowa etykieta semantyczna",
          edit: "Edytuj etykietę semantyczną",
          name: "Imię i nazwisko",
          standardSentences: "Standardowe zdanie",
          effectiveness: "Skuteczność",
          similarSentences: "Podobne zdania",
          tip: "Wsparcie przy użyciu # # to call entity API, na przykład: Czy Twój numer telefonu komórkowego # sys_phone?",
          excludeSimilarSentences: "Wyklucz podobne zdania",
          intelligentRecommend: "Inteligentna rekomendacja podobnych treści",
          fastTraining: "Szybkie szkolenie:",
          qualify: "Regularne",
          recallKeyWord: "Przypomnij sobie słowa kluczowe",
          excludeKeywords: "Wyklucz słowa kluczowe",
          excludeKeywordsInfo1:
            "Wyklucz słowa kluczowe i wyrażenia regularne mają pierwszeństwo przed wszystkimi logikami. Jeśli zostaną wykluczone, etykieta semantyczna nie zostanie trafiona.",
          keyWordInfo1:
            "W każdym wierszu można wypełnić wiele słów kluczowych przypominających, oddzielonych przecinkami angielskimi. Po wypełnieniu wielu słów kluczowych przypominających, logika jest taka, że wszystkie trafienia są trafione.",
          keyWordInfo2:
            "Przypomnij słowa kluczowe można wypełnić w wielu liniach. Po wypełnieniu wielu linii logikę można ustawić jako każde trafienie.",
          recallRegular: "Przypomnij sobie regularne",
          excludeRegular: "Wyklucz regularne",
          similaSentences: "Wyklucz podobne zdania",
          simim: "Podobieństwo",
          keyWordInfo3:
            "Wykluczenie podobnych zdań ma pierwszeństwo przed całą logiką. Jeśli trafione zostanie podobne zdanie, etykieta semantyczna nie zostanie trafiona. Domyślne podobieństwo wynosi 80%, a im większe podobieństwo, tym bardziej jest podobne.",
          keyWordInfo4:
            "Reguła jest podobna do zwykłej, z domyślnym początkiem i końcem. Niekoniecznie używane z nawiasami, np. [nie XXXX]",
          keyWordInfo5:
            "Funkcje w zaawansowanym module są zwykle funkcjami eksperymentalnymi dla ekspertów, którzy są zaznajomieni z operacją. Jeśli nie masz pewności, jak działać, zaleca się, aby nie zmieniać tej sekcji.",
          keyWordInfo6:
            "Funkcja wykrywania zdań krzyżowych daje tagom semantycznym możliwość wykrywania między zdaniami.",
          keyWordInfo7: "Co najwyżej z rzędu w tej samej roli",
          keyWordInfo8: "Zignoruj różne role Długość zdania nie jest większa",
          keyWordInfo9: "Zdania słów do wykrywania zdań krzyżowych",
          keyWordInfo10:
            "Długość najdłuższego zdania granicznego w zdaniu krzyżowym nie jest większa",
          keyWordInfo11: "Usuń podobny procent nie mniej",
          keyWordInfo12: "Podobieństwo nie jest niższe",
          keyWordInfo13:
            "% Podobnych zdań zostanie dopasowanych podobnie (domyślne podobieństwo 80%)",
          keyWordInfo14:
            "Podobna deduplikacja dopasowuje zdania na podstawie ich podobieństwa. Zaleca się usuwanie zdań, które są zbyt podobne, aby poprawić skuteczność wykrywania.",
          keyWord: "Słowa kluczowe",
          entity: "Podmioty",
          ruleField: "Słowa koncepcyjne",
          customRule: "Projektowanie reguł mowy",
          fanhua: "Podobne pytania",
          test: "Test",
          testOk: "Test trafienia",
          senior: "Zaawansowane ustawienia",
          seniorList: "Wykrywanie w zdaniu krzyżowym",
          up: "Włącz",
          down: "Zamknij",
          ju: "Zdanie",
          oneWord: "Słowo",
          simimNo: "Podobne do Waga",
          seniorField: "Próg podobieństwa",
          save: "Zapisz",
          cancel: "Anuluj",
          specialElements: "Elementy specjalne",
          logicRule: "Zasady logiczne",
          every: "Wiele meczów",
          recallOk: "Hit Recall Słowo:",
          recallNo: "Missed Recall Word",
          ruleOk: "Regularne przywoływanie hitów",
          ruleNo: "Przegapione regularnie",
          rularOk: "Zasady trafień",
          rularNo: "Nieoddanych reguł",
          infoOk: "Uderz w zdanie standardowe lub podobne:",
          infoNo: "Niebite zdanie standardowe lub zdanie podobne",
          fieldOk: "Hit, aby wykluczyć podobne zdania:",
          fieldNo: "Nie można wykluczyć podobnych zdań",
          excludeRuleNo: "Brak wykluczenia Regex",
          excludeRuleOk: "Regularne wykluczenie hit",
          excludeKeywordNo: "Misses wyklucza słowa kluczowe",
          excludeKeywordOk: "Hit Wyklucz słowa kluczowe",
          update: "Aktualizacja zakończyła się sukcesem",
          fanhuaList: "Przesłane do zakładki uogólniania Lista zdań podobnych",
          wordList:
            "Lista szkoleń jest pusta i nie można jej przesłać do listy uogólnień",
          start: "Start",
          end: "Koniec",
          keyWordInfo15:
            "Podobne pola istnieją na liście uogólnień (zaawansowana konfiguracja podobnych ograniczeń deduplikacji)",
          saveSuccess: "Oszczędzaj pomyślnie",
          errInfo:
            "Niektóre pola nie są wypełnione lub nieprawidłowo wypełnione",
        },
        table: {
          deepTraining: "Głębokie szkolenie {0} rundy",
          fastTraining: "Szybkie szkolenie",
        },
      },
      intelligentCollection: {
        title: "Inteligentne nabycie",
        classification: "Lista grup",
        new: "Nowa kolekcja",
        table: {
          name: "Nazwa pola",
          props: "Atrybuty klienta",
          type: "Typ pola",
          desc: "Opis pola",
        },
        detail: {
          isCustomerProps: "Zapisz w atrybutach klienta",
          customerPropsHelper:
            "Po zapisaniu zebrane informacje zostaną zaktualizowane do informacji o kliencie. Nie zapisuj, nie aktualizuj informacji o klientach",
          name: "Nazwa pola",
          type: "Typ pola",
          text: "Tekst",
          desc: "Opis pola",
          descPlaceHolder:
            "Opisz wykorzystanie tego pola do zbierania informacji",
          condition: "Stan",
          logic: "Zasady",
          method: "Tryb akwizycji",
          message: "Treść informacyjna",
          range: "Zakres ekstrakcji",
          entity: "Wyciąg podmiotów",
          base: "Podstawowe informacje",
          collect: "Zbieraj informacje",
        },
      },
      intelligentTags: {
        title: "Inteligentna etykieta",
        sessionLabelTitle: "Scenariusze sesji",
        keyEventsTitle: "Kluczowe wydarzenia",
        customerLabelTitle: "Etykieta klienta",
        addClassification: "Utwórz nową klasyfikację etykiet",
        defaultCategoryName: "Klasyfikacja domyślna",
        index: {
          createTag: "Nowa etykieta",
          createEvent: "Nowe wydarzenie",
          editTagClassification: "Edytuj klasyfikację etykiet",
          selectTagClassification: "Wybierz klasyfikację etykiet",
          editTagGrounp: "Edytuj grupowanie",
          callTimeError:
            "Wybrany czas rozpoczęcia rozmowy/rozmowy nie może przekraczać jednego miesiąca (31 dni)",
          callRecordCountError:
            "Liczba rekordów wynosi 0, prosimy o ponowne wybranie czasu rozmowy/rozmowy",
          selectItmeCountError:
            "Wybierz inteligentną etykietę, dla której chcesz wykonać operację zbiorczą",
          redoSuccess:
            "Ponowne wykonanie zakończyło się sukcesem, przejdź do centrum zadań, aby wyświetlić",
          redoError:
            "Ponowne wykonanie nie powiodło się, spróbuj ponownie później",
          timeError:
            "Czas zapytania nie może przekroczyć jednego miesiąca (31 dni)",
          categoryName: "Nazwa kategorii",
          categoryColor: "Kolor klasyfikacji",
          newTagClassification: "Nowa klasyfikacja etykiet",
          newEventClassification: "Nowa kategoria",
          tagClassification: "Klasyfikacja etykiet",
          eventClassification: "Klasyfikacja zdarzeń",
        },
        detail: {
          new: "Nowa inteligentna etykieta",
          edit: "Edytuj inteligentną etykietę",
          tagName: "Imię i nazwisko",
          tagDesc: "Opis",
          category: "Klasyfikacja",
          tagStatus: "Skuteczność",
          operator: "Operator",
          rule: "Zasady",
          labelClassAtion: "Rodzaj etykiety",
          labelClassAtionTip1:
            "Etykiety callout: etykietowanie oparte na zasadach",
          labelClassAtionTip2:
            "Klasyfikacja Etykietowanie: Etykietowanie na podstawie szkolenia modelowego",
          group: "Grupowanie",
          checkLabel: "Wybierz kategorię",
          warn: "Ostrzeżenie",
          warnTips:
            "Po włączeniu alertu, gdy sesja spełnia tę regułę zdarzenia kluczowego, wyznaczona osoba zostanie poproszona i może otrzymać przypomnienie na liście rzeczy do zrobienia.",
          warnError: "Proszę wybrać Alert Określony personel",
        },
        subTitle: {
          common: "Inteligentne znaczniki sesji",
          wecom: "Mikro etykieta przedsiębiorstwa",
        },
      },
      customerPortrait: {
        title: "Portret klienta",
        index: { import: "Portret klienta importu" },
        searchPlaceHolder: "Identyfikator klienta, pseudonim lub telefon",
        callCount: "Liczba rozmów:",
        callDuration: "Całkowity czas trwania połączenia:",
        dialogCount: "Łączna liczba rozmów:",
        keyWordsStatistics:
          "Statystyki słów kluczowych (Top 20 i słowa zainteresowania)",
        keyWordsCount: "({0})",
        businessTag: "Etykieta biznesowa",
        keyWordsCloudImg: "Wykres w chmurze słów kluczowych",
        customerEmotionTrend: "Trendy nastrojów klientów",
        notFoundCustomer: "Nie odzyskano żadnych powiązanych klientów",
        noKeyWords: "Nie znaleziono powiązanych słów kluczowych",
        noBusinessTags: "Nie znaleziono powiązanych tagów biznesowych",
        getKeyWordsStatisticsDataError:
          "Zapytanie o statystyki słów kluczowych klienta nie powiodło się",
        getBusinessTagsStatisticsDataError:
          "Zapytanie o etykietę biznesową klienta nie powiodło się",
        personCustome: "Informacje o kliencie nie zostały zapytane",
        notimeInfo: "Nie znaleziono żadnych informacji o emocjach.",
        timeList: "Zakres daty",
        operator: "Inteligentna etykieta",
        placeHolder: "Proszę wybrać",
        placeOperator: "Wybierz inteligentną etykietę",
        search: "Zapytanie",
        reset: "Zresetuj",
        searchHolder: "Szukaj",
        call: "Zadzwoń",
        text: "Dialog",
      },
      sentimentAnalysis: { title: "Odkrycie opinii publicznej" },
      saleSentimentAnalysis: { title: "Analiza opinii publicznej" },
      dataPreprocess: {
        title: "Wstępne przetwarzanie danych",
        index: {
          createData: "Nowe dane",
          selectDataSource: "Wybierz źródło danych",
          selectStatus: "Wybierz skuteczność",
          callTimeError:
            "Wybrany czas rozpoczęcia rozmowy/rozmowy nie może przekraczać jednego miesiąca (31 dni)",
          callRecordCountError:
            "Liczba rekordów wynosi 0, prosimy o ponowne wybranie czasu rozmowy/rozmowy",
          selectItmeCountError: "Wybierz dane, aby wykonać operację zbiorczą",
          redoSuccess:
            "Ponowne wykonanie zakończyło się sukcesem, przejdź do centrum zadań, aby wyświetlić",
          redoError:
            "Ponowne wykonanie nie powiodło się, spróbuj ponownie później",
        },
        detail: {
          new: "Nowe dane",
          edit: "Edytuj dane",
          name: "Imię i nazwisko",
          desc: "Opis",
          dataSource: "Źródło danych",
          status: "Skuteczność",
          entity: "Wyciąg podmiotów",
          operator: "Operator",
          rule: "Zasady",
        },
      },
      connectedByConversation: {
        title: "Stowarzyszenie konwersacji komunikacji",
        list: {
          title: "Funkcja asocjacji dialogu komunikacyjnego",
          info: "Funkcja asocjacji może kojarzyć różne połączenia i konwersacje zgodnie z ustalonymi regułami.",
          rule: "Zasady stowarzyszenia konwersacji",
          source: "Proszę wybrać źródło danych",
          classification: "Wybierz kategorię",
          choose: "Proszę wybrać",
          input: "Proszę wejść",
          save: "Zapisz",
          cancel: "Anuluj",
          fieldwei: "Bit",
          fieldNot: "Są niewypełnione pola",
          fiedldlengthNo: "Długość pola przekracza limit",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip:
            "Można ustawić wiele słów kluczowych, oddzielonych przecinkami",
          applyRole: "Wykryj rolę",
          preOperator: "Pre-operator",
          applyOperatorScope: "Zasięg wykrywania",
          addOperator: "Dodaj operatora",
          noPreOperator: "Brak",
          from: "Nie.",
          to: "Wyrok na nr.",
          end: "Zdanie",
          testRegular: "Przetestuj Wyrażenia Regularne",
          test: "Test",
          matchSuccess: "Dopasuj pomyślnie",
          matchError: "Mecz nie powiódł się",
          querySementicTags: "Wybierz etykietę semantyczną",
          reQuerySementicTags: "Zmień wybór etykiet semantycznych",
          sementicTag: "Etykieta semantyczna",
          standardSentence: "Standardowe zdanie",
          similarity: "Podobieństwo",
          actions: "Operacja",
          noFitTag: "Nie znalaz³em odpowiedniej etykiety,",
          addSemanticTag: "Dodaj nowy znacznik semantyczny",
          pleaseEnterRegular: "Wprowadź wyrażenie regularne",
          pleaseEnterRegularText:
            "Wprowadź treść, która ma zostać zweryfikowana",
          newAssociateComponentTip:
            "Jeśli wartość jest typem numerycznym, użyj funkcji starszej",
        },
        filterManage: {
          noValueTip: "Brak danych",
          allCall: "Wszystkie połączenia",
          allDialogue: "Wszystkie dialogi",
          allWechat: "Wszystkie sesje",
          getFiltersListFailed:
            "Nie udało się uzyskać listy filtrów, spróbuj ponownie później!",
          getFilterDetailFailed:
            "Nie udało się uzyskać szczegółów filtra, spróbuj ponownie później!",
          getAllCountFailed:
            "Nie udało się uzyskać całkowitej liczby połączeń/rozmów, spróbuj ponownie później!",
        },
        listManage: {
          searchPlaceHolder: "Proszę wpisać słowo kluczowe wyszukiwania",
          filterConditions: "Stan filtra",
          records: "Rekord",
          getRecordsFailed:
            "Nie udało się uzyskać rekordu, spróbuj ponownie później!",
        },
        miniListManage: {
          getRecordsFailed:
            "Nie udało się uzyskać rekordu, spróbuj ponownie później!",
        },
        applyTemplates: {
          title: "Obowiązujący szablon kontroli jakości",
          collectionTitle: "Obowiązujący szablon akwizycji",
        },
        recordDetail: {
          showWaveAudio: "Pokaż nagranie",
          getDetailFailed:
            "Nie udało się uzyskać szczegółów, spróbuj ponownie później!",
          getSmartTagsFailed:
            "Nie udało się uzyskać inteligentnej etykiety, spróbuj ponownie później!",
          getTemplatesFailed:
            "Nie udało się uzyskać odpowiedniego szablonu, spróbuj ponownie później!",
        },
        changeList: {
          sampleStatus: "Status pobierania próbek",
          sampleAssignInspector: "Przydziel inspektora pobierania próbek",
          reviewAssignInspector: "Przypisywanie recenzenta",
          read: "Czytaj",
          distributivePerson: "Osoba alokowana",
          totalScore: "Całkowity wynik",
        },
        qualityCheckScore: {
          totalScore: "Całkowity wynik",
          actualScore: "Wynik",
          actualGrade: "Ocena",
          appeal: "Skarga",
          remark: "Uwagi",
          manualRemark: "Uwagi ręczne",
          none: "Brak szczegółowych danych do kontroli jakości",
          interactiveTip:
            "Użyj rozpoznawania interakcji, aby wykryć korzystanie z FAQ obsługi klienta",
          interactiveIsEmptyTip:
            "Nie wykryto interaktywnych treści rozpoznawania",
        },
        semanticMark: {
          semanticContent: "Zdanie znakujące",
          markedSimilarTitel: "Podobne etykiety semantyczne",
          markedExcludeTitle:
            "Oznaczone w celu wykluczenia podobnych znaczników semantycznych",
          taskTagTitle: "Etykiety semantyczne związane z zadaniami",
          similarTagTitle:
            "Zidentyfikuj podobne znaczniki semantyczne (podobieństwo> 40%,Top 20)",
          delete: "Usuń",
          markSimilar: "Zaznacz podobne",
          markExclude: "Wyklucz podobne",
          none: "Brak",
        },
        selectCallTime: {
          callTime: "Czas rozpoczęcia rozmów/Sesja",
          callTimeRemind:
            "Możesz wybrać do jednego miesiąca (31 dni) zapisów rozmów w ostatnim roku.",
          callRecordCount: "Wybrałeś łącznie {0} rekordów rozmów/rozmów",
          getCallRecordError:
            "Nie udało się uzyskać rekordu, spróbuj ponownie później",
        },
      },
      informationEntities: {
        title: "Podmiot informacyjny",
        index: { import: "Podmioty informacji o imporcie" },
        detail: {
          new: "Nowy Podmioty Informacyjne",
          edit: "Edytuj jednostkę informacji",
          name: "Imię i nazwisko",
          apiName: "Nazwa interfejsu API",
          activeFlag: "Skuteczność",
          remark: "Opis",
          logicExp: "Podmiot regularny",
          sentenceList: "Treść podmiotów",
          tip: "Treść jednostki obsługuje wiele wpisów, oddzielonych spacjami, które są synonimami tej samej jednostki.",
          remarkLengthError: "Długość opisu nie może przekraczać 255",
          nameLengthError: "Długość nazwy nie może przekraczać 40",
          entityDuplicateError: "Duplikatowa zawartość jednostki",
        },
      },
      interactiveRecognition: {
        title: "Interaktywne rozpoznawanie",
        classify: "Klasyfikacja",
        addClassify: "Dodaj kategorię",
        editClassify: "Edytuj kategorię",
        index: { import: "Importowe rozpoznawanie interakcji" },
        detail: {
          new: "Nowa inteligentna interakcja",
          edit: "Edytuj inteligentną interakcję",
          type: "Rodzaj",
          standardQuestion: "Kwestie standardowe",
          similarQList: "Podobny problem",
          exceptSimilarQList: "Wyeliminuj podobne problemy",
          category: "Klasyfikacja",
          targetType: "Cel wykrywania problemów",
          status: "Skuteczność",
          standardAnswer: "Standardowa odpowiedź",
          similarAList: "Podobne odpowiedzi",
          wrongAList: "Zła odpowiedź",
        },
      },
      businessAnalysis: {
        title: "Analiza biznesowa",
        tip1: "Analiza trendów biznesowych: współrzędna pionowa to liczba rozmów, I. E. całkowita liczba rozmów spełniających warunki, a współrzędna pozioma to czas.",
        tip2: "Analiza korelacji: analiza korelacji wielu wskaźników, dane korelacji zawieszenia myszy, objętość danych, korelacja, częstotliwość powtarzania",
        tip3: "Analiza konwersji: analiza wielu wskaźników może tworzyć wzajemną relację konwersji, kryteria konwersji dla tego samego połączenia/rozmowy, jeśli możesz trafić na dwa różne wskaźniki, możesz wziąć pod uwagę, że oba wskaźniki mają relację konwersji.",
      },
      knowledgeBase: {
        title: "Baza wiedzy",
        list: {
          add: "Dodaj podkategorię",
          delete: "Usuń klasyfikację",
          categoryName: "Nazwa kategorii",
          createSuccess: "Utworzona podkategoria z powodzeniem",
          keyWord: "Słowa kluczowe",
        },
        detail: {
          type: "Rodzaj",
          status: "Status",
          classification: "Klasyfikacja",
          standardQuestion: "Kwestie standardowe",
          answerDetectionRange: "Zakres wykrywania odpowiedzi",
          standardAnswer: "Standardowa odpowiedź",
        },
      },
      callManage: {
        title: "Filtry do nagrywania połączeń",
        listTitle: "Zarządzanie połączeń",
        detailTitle: "Szczegóły połączenia",
      },
      dialogueManage: {
        title: "Filtr tekstu konwersacji",
        listTitle: "Zarządzanie dialogiem",
        detailTitle: "Szczegóły dialogu",
      },
      weChatManage: {
        title: "Filtr sesji WeChat dla przedsiębiorstw",
        listTitle: "Zarządzanie sesją Enterprise WeChat",
        detailTitle: "Szczegóły sesji Enterprise WeChat",
      },
      speechRecognition: {
        title: "Rozpoznawanie mowy",
        asr: "Rozpoznawanie mowy",
        asrRemind:
          "Wyłączenie tego przełącznika spowoduje wyłączenie funkcji zamiany głosu na tekst i funkcji kontroli jakości maszyny.",
        asrConfig: "Filtracja ASR",
        imAsrConfig: "IMASR",
        imAsrConfigRemind:
          "Po włączeniu przeprowadzana jest transkrypcja IM ASR",
        asrConfigRemind:
          "Po włączeniu tylko kwalifikujące się połączenia wykonują zawartość ASR",
        correction: "Inteligentna korekcja błędów",
        correctionRemind:
          "Po włączeniu zawartość w „ <a href = '/site/semantic-intelligence/smart-words-library/professional-words' target =' _ blank '> Smart Thesaurus-Professional Nouns </a> ”zostanie użyta do wymuszenia konwersji korekcji błędów",
        correctionConfig: "Filtrowanie korekcji błędów",
        advancedSettings: "Zaawansowane ustawienia",
        advancedSettingsTip:
          "Wybiera określony model ASR dla określonych danych.",
        mandarinASRModel: "Mandaryński model ASR",
        cantoneseASRModel: "Kantoński model ASR",
        englishASRModel: "Angielski model ASR",
        correctionConfigRemind:
          "Po włączeniu poprawione zostaną tylko połączenia spełniające następujące warunki",
        customJudgeLogicIsNull: "Niestandardowa logika nie może być pusta",
        redoCorrection: "Ponownie wykonaj korekcję błędów",
        callTime: "Wybierz czas rozmowy",
        callTimeRemind:
          "Możesz wybrać do jednego miesiąca (31 dni) rekordów połączeń w ostatnim roku.",
        callRecordCount: "Wybrałeś łącznie {0} rekordów połączeń",
        redoCorrectionSubmit: "Prześlij do wykonania",
        callTimeError:
          "Wybrany zakres czasu połączenia nie może przekraczać jednego miesiąca (31 dni)",
        callRecordCountError:
          "Liczba rekordów połączeń to 0, prosimy o ponowne wybranie czasu połączenia",
        redoCorrectionSuccess:
          "Ponowna korekta błędów jest udana, przejdź do centrum zadań, aby wyświetlić",
        redoCorrectionError:
          "Ponowny błąd nie powiódł się, spróbuj ponownie później",
      },
      role: {
        title: "Zarządzanie Role",
        create: "Nowa rola",
        detail: {
          title: "Rola",
          new: "Nowa rola",
          view: "Wyświetl role",
          edit: "Edytuj rolę",
          navTabNames: {
            basicInfo: "Uprawnienia do menu",
            taskPermissions: "Pozwolenie na zadanie kontroli jakości",
            dataPermissions: "Uprawnienia danych",
          },
          basicInfo: {
            roleName: "Imię i nazwisko",
            permissions: "Uprawnienia",
          },
          taskPermissions: {
            allTask: "Wszystkie zadania kontroli jakości",
            myCreatedTask: "Zadanie kontroli jakości, które stworzyłem",
          },
          dataPermissions: {
            dataPermissionsConfig: "Konfiguracja uprawnień do danych",
            tips: "Ta rola może zobaczyć dane w wybranej klasyfikacji",
            select: "Wybierz kategorię",
            selectPlaceholder:
              "Proszę wybrać klasyfikację, która wymaga pozwolenia na widoczność danych",
          },
        },
      },
      dataMigration: {
        title: "Migracja danych",
        dataUpload: "Migracja danych",
        dataDowload: "Migracja danych",
        error: "Wybierz zadanie jakości do wyeksportowania",
        list: {
          info: "Wybierz zadanie kontroli jakości, aby się wyprowadzić",
          listInfo:
            "Proszę wybrać, czy migrować dane związane z zadaniem kontroli jakości (takie jak inteligentny tezaurus, znacznik semantyczny, jednostka informacyjna, inteligentna interakcja itp.)",
          noInfo: "Wybierz, czy chcesz przenieść inne dane inne niż QC",
          ownInfo: "Wyprowadzaj tylko zadania",
          everyInfo: "Zadanie związane z przeprowadzką",
          allInfo: "Wynieś się ze wszystkich",
          callFilter: "Filtr wywoławczy",
          talkFilter: "Filtr dialogowy",
          smartTag: "Inteligentna etykieta",
        },
        upload: {
          info: "1. Kliknij przycisk Prześlij, aby przesłać plik JSON zgodny z formatem.",
          uploadSuccess: "Udało się przesłać",
          uploadError: "Przesyłanie nie powiodło się",
        },
      },
      dataAnalysis: {
        businessStatistics: "Statystyki biznesowe",
        qualityEffect: "Jakość zdrowia inspekcji",
        qualityAnalysis: "Analiza kontroli jakości",
        generalInspecitionReport: "Sprawozdanie ogólne",
        fullAmountWork: "Obciążenie inspektorem jakości",
        intelligentTagsAnalysis: "Analiza etykiet",
        saleIntelligentTagsAnalysis: "Inteligentna analiza etykiet",
        generalInspecitionReportTip:
          "Łączne dane: zlicza całkowitą ilość danych dotyczących połączeń i rozmów w bieżącym terminie i zakresie zadań, w tym liczbę i procent danych inteligentnej kontroli jakości, liczbę i procent danych ręcznego pobierania próbek, liczbę odwołań, częstotliwość próbkowania i wykres trendów czasowych.",
        groupTest: "Test grupowy",
      },
      uploadDownloadList: {
        title: "Centrum misji",
        tabs: {
          upload: "Prześlij import",
          download: "Pobierz Export",
          taskCenter: "Wykonanie zadania",
        },
      },
      uploadDownloadModel: {
        title: "Prześlij do pobrania",
        list: {
          clear: "Pusty",
          success: "Sukces",
          fail: "Nie powiodło się",
          hand: "W toku",
          noInfo: "Brak danych",
        },
      },
      billingCenter: {
        title: "Centrum rozliczeniowe",
        export: "Eksport",
        limit: "Ograniczenie przypomnienia",
        tips: "Wyniki rozliczeń będą stronnicze ze względu na zaokrąglanie, tylko w celach informacyjnych",
        dashboard: {
          remainingQuota: "Bieżąca pozostała kwota",
          usedQuota: "Łączny kontyngent używany",
          voiceQuality: "Kontrola jakości głosu:",
          voice: "Głos:",
          textQuality: "Kontrola jakości tekstu:",
          text: "Tekst:",
          hour: "Godziny",
          tenThousandHour: "Milion godzin.",
          day: "Dzień",
          cutoff: "Odcięcie",
          warning:
            "(Pozostała kwota jest niewystarczająca, proszę naładować tak szybko, jak to możliwe)",
          isClosed: "(Stan zamknięty)",
        },
        table: {
          quotaRecord: "Rekord kontyngentu",
          timeFilter: "Wybierz datę:",
          search: "Zapytanie",
          reset: "Zresetuj",
          id: "Numer seryjny",
          type: "Rodzaj",
          qualityType: "Rodzaj kontroli jakości",
          quotaChange: "Zmień",
          surplus: "Równowaga",
          changeTime: "Czas",
          voiceQuality: "Inspekcja jakości głosu",
          textQuality: "Inspekcja jakości tekstu",
          sizeChangeText: "Wyświetl na stronę",
          used: "Użyj",
          give: "Dotacja",
          deduction: "Odliczenie",
          callTime: "Czas rozmów",
          count: "Przetwarzanie ilości danych",
          page: "Strona {1} z {0}",
        },
        limitModal: {
          call: "Limit kontroli jakości głosu jest niższy",
          callFix: "Godziny do przypomnienia",
          dialogue: "Pozostały czas kontroli jakości tekstu jest mniejszy",
          dialogueFix: "Dni do przypomnienia",
        },
      },
      messageCenter: {
        replyTitle: "Odpowiedz na moje",
        answerTitle: "@ Mój",
        shareTitle: "Podziel się moim.",
      },
      columnsManage: { title: "Zarządzanie polowe inspekcją jakości" },
      recordUpload: {
        list: {
          task: "Zadanie kontroli jakości",
          user: "Operator",
          uploadTime: "Czas przesyłania",
          endTime: "Czas zakończenia",
          total: "Całkowite przesłanie",
          success: "Sukces inspekcji jakości",
          failed: "Kontrola jakości nie powiodła się",
          progress: "Postęp kontroli jakości",
          inProgress: "W toku",
          finished: "Ukończone",
        },
        steps: {
          uploading: "Prześlij plik nagrywania",
          baseInfo: "Wypełnij podstawowe informacje",
          start: "Rozpocznij inspekcję jakości",
        },
        uploadComponent: {
          dragger: "Przeciągnij i upuść, aby dodać pliki",
          or: "Lub",
          selectFile: "Wybierz plik",
          des: "Obsługa formatu wav, mp3, częstotliwość próbkowania 8000Hz, plik 16-bitowy",
          limit1: "Prześlij co najwyżej raz",
          limit2: "Pliki, każdy rozmiar pliku nie przekracza",
          uploading: "Przesyłanie",
          finished: "Przesyłanie kompletne",
          success: "Sukces",
          failure: "Nie powiodło się",
        },
        baseInfo: {
          task: "Wybierz zadanie jakości",
          type: "Wybierz formularz nagrania",
          agentSide: "Kanał, w którym znajduje się obsługa klienta",
          excel: "Prześlij dane po drodze",
          checkFileInfo: "Zweryfikuj informacje o pliku",
          excelFileError: "Prześlij dane po drodze",
          checking: "Kalibracja w...",
          reCheck: "Ponowna kalibracja",
          success: "Sukces weryfikacji",
          excelUoloadFail: "Nie udało się przesłać danych wzdłuż trasy.",
          tips: {
            tip1: "1. Nazwa pliku nagrywającego w ecxel musi być taka sama jak nazwa przesłanego nagrania i nie może mieć tej samej nazwy.",
            tip2: "2. System sprawdza duplikat z nazwą pliku nagrywania i identyfikatorem połączenia. Powtarzające się dane doprowadzą do awarii kontroli jakości.",
            tip3: "3. W przypadku pobranego szablonu nagrywania programu Excel kolor czcionki jest czerwony, co jest polem wymaganym.",
            tip4: "4. Format pola typu czasu to: rrrr-mm-dd hh:mm:ss, na przykład 2021-04-20 17:36:30",
          },
        },
        title: "Lokalne przesyłanie nagrań",
        checkTitle: "Inspekcja jakości zapisu",
        start: "Rozpocznij inspekcję jakości",
        disabledTips:
          "Proszę zweryfikować plik nagrania przed kontrolą jakości",
        tips: "Plik nagrywający o tej samej nazwie nadpisuje wyniki kontroli jakości i podstawowe informacje.",
        tips1: "Przeciągnij i upuść plik tutaj lub kliknij Prześlij",
        tips2: "Wsparcie",
        tips3: "Format",
        type: "wav、mp3",
        tips4: "Prześlij co najwyżej raz",
        tips5: "Pliki, każdy rozmiar pliku nie przekracza",
        tipsOverSize:
          "Bieżąca kolejka jest pełna, usuń pliki z kolejki przed załadowa niem.",
        size: "500M",
        count: "20",
        uploaded: "Przesłany plik do nagrywania",
        uploading: "({0}%)",
        uploadFinished: "(Prześlij kompletne)",
        bottomTitle: "Gromadzenie informacji o nagrywaniu:",
        recordType: "Wybierz formularz nagrania:",
        double: "Dwukanałowe nagrywanie rozmów",
        single: "Nagrywanie rozmów jednokanałowych",
        recordBaseInfo: "Prześlij podstawowe informacje dotyczące nagrywania:",
        addBtn: "Dodaj plik",
        downloadTemplate: "Szablon Download. excel",
        checkRecord: "Zweryfikuj informacje dotyczące zapisu",
        checking: "Kalibracja w...",
        reCheck: "Ponowna kalibracja",
        checkAll: "Wybierz wszystkie",
        cancelCheckAll: "Anuluj Wybierz wszystkie",
        delete: "Usuń",
        deleteLotSize: "Usuń partię",
        agentChannel: "Kanał obsługi klienta:",
        left: "Lewy kanał",
        right: "Prawy kanał",
        donotKonw: "Nie wiem",
        mission: "Wybierz zadanie jakości:",
        uploadFail: "Przesyłanie nie powiodło się",
        repeat: "Duplikat pliku",
        overCount: "Liczba plików przekracza limit",
        overSize: "Rozmiar pliku przekracza limit",
        uoloadFail: "Nie udało się przesłać do chmury",
        formatError: "Błąd formatu",
        seccess: "Sukces weryfikacji",
        fail: "Weryfikacja nie powiodła się",
        recordChecking: "Nagrywanie w kontroli jakości, proszę później...",
        recordCheckSuccess: "Inspekcja jakości zapisu zakończona",
        recordCheckFail:
          "Kontrola jakości nagrywania nie powiodła się, sprawdź, czy nagranie jest duplikowane lub nieważne",
        backToIndex: "Powrót do listy przesyłania",
        recordCheckIsStarted:
          "Obecnie wykonywane jest zadanie kontroli jakości. Po zakończeniu bieżącego zadania rozpocznij nowe zadanie.",
      },
      customerServicePortrait: {
        title: "Portret obsługi klienta",
        tip1: "Prawo dystrybucji długości zdania: rozkład długości zdania jest liczony w segmentach zgodnie z liczbą słów w długości zdania, a tylko z obsługą klienta",
        tip2: "Prawo dystrybucji prędkości: rozkład prędkości, zgodnie z prędkością (słowa/minutę), statystyki segmentu, oblicza tylko obsługę klienta",
        tip3: "Prawo dystrybucji czasu reakcji: rozkład czasu reakcji, obliczana jest tylko sytuacja reakcji obsługi klienta.",
        tip4: "Zgodność z inspekcją jakości: wskaźnik zgodności punktów kontroli jakości",
        tip5: "Wynik zadania inspekcji średniej jakości: średni wynik zadania kontroli jakości",
        contol: {
          date: "Wybierz datę:",
          today: "Dzisiaj",
          thisMonth: "W tym miesiącu",
          lastMonth: "W zeszłym miesiącu",
          thisQuarter: "W tym kwartale",
          lastQuarter: "Ostatni kwartał",
          thisYear: "W tym roku",
          lastYear: "W zeszłym roku",
          customer: "Obsługa klienta:",
          search: "Zapytanie",
          reset: "Zresetuj",
          export: "Eksport",
          indicator: "Zarządzanie wskaźnikami",
          selectPlaceholder: "Wybierz obsługę klienta, którą należy porównać",
          warning: "Wybierz do 5 agentów do porównania",
          warning2: "Wybierz co najmniej jednego agenta, aby zapytać",
          dataWarning:
            "Można przeszukiwać tylko dane do 366 dni po dacie rozpoczęcia.",
          seccuss: "Rekordowy eksport udany",
        },
        echarts: {
          distributionLaw: "Prawo dystrybucji",
          count: "Liczba razy",
          bout: "Czasy",
        },
        portrait: {
          ability: "Wymiar możliwości obsługi klienta",
          option: "Konfiguracja",
          abilityOption: "Konfigurowanie wymiarów zdolności",
          placeholder: "Wyszukiwanie słów kluczowych w wymiarze wejściowym",
          save: "Zapisz",
          warning:
            "Wybierz co najmniej 3 wskaźniki do skonfigurowania i możesz wybrać do 10 wskaźników",
        },
        label: { title: "Etykieta obsługi klienta" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "Ranking obsługi klienta",
        select: {
          date: "Wybierz datę:",
          today: "Dzisiaj",
          thisMonth: "W tym miesiącu",
          lastMonth: "W zeszłym miesiącu",
          thisQuarter: "W tym kwartale",
          lastQuarter: "Ostatni kwartał",
          thisYear: "W tym roku",
          lastYear: "W zeszłym roku",
          group: "Grupa Obsługi Klienta:",
          groupPlaceholder: "Proszę wybrać grupę agentów",
          customer: "Obsługa klienta:",
          customerPlaceholder: "Proszę wybrać obsługę klienta",
          search: "Zapytanie",
          reset: "Zresetuj",
          compared: "Kontrast",
          export: "Eksport",
          column: "Kolumna",
          warning: "Wybierz do 5 agentów do porównania",
          warning2: "Wybierz co najmniej jednego agenta",
          dataWarning:
            "Można przeszukiwać tylko dane do 366 dni po dacie rozpoczęcia.",
          selected: "Wybrany agent:",
          seccuss: "Rekordowy eksport udany",
        },
        compared: {
          title: "Wybierz obsługę klienta",
          return: "Anuluj",
          compared: "Kontrast",
          tips: "Proszę wybrać 2-5 agentów do porównania",
          warning: "Tylko 2-5 agentów są obsługiwane dla porównania",
        },
        transfer: { leftTitle: "Lista pozycji", rightTitle: "Pokaż przedmiot" },
        table: {
          page: "Strona {1} z {0}",
          actionTitle: "Operacja",
          action: "Widok",
        },
      },
      conceptWord: {
        title: "Słowa koncepcyjne",
        new: "Nowe słowo koncepcyjne",
        control: {
          search: "Szukaj:",
          searchPlaceholder: "Wyszukiwanie nazwy lub słowa kluczowego",
          commit: "Zapytanie",
          reset: "Zresetuj",
          import: "Import",
          export: "Eksport",
          seccuss: "Rekordowy eksport udany",
        },
        modal: { title: "Importuj słowa koncepcyjne" },
        table: {
          sizeChangeText: "Wyświetl na stronę",
          page: "Strona {1} z {0}",
          edit: "Edytuj",
          delete: "Usuń",
          request:
            "Po usunięciu nie można go odzyskać. Czy na pewno chcesz go usunąć?",
          cancel: "Anuluj",
          title: {
            conceptWord: "Słowa koncepcyjne",
            describeWordsNum: "Liczba opisów",
            updateTime: "Czas aktualizacji",
            status: "Skuteczność",
            effective: "Skuteczne",
            invalid: "Nieważne",
            action: "Operacja",
          },
        },
        detail: {
          title: {
            new: "Nowe słowo koncepcyjne",
            edit: "Edytuj słowa koncepcyjne",
          },
          name: "Nazwa słowa koncepcyjnego",
          namePlaceholder: "Wprowadź nazwę słowa pojęcia",
          effectiveness: "Skuteczność",
          effective: "Skuteczne",
          invalid: "Nieważne",
          description: "Słowa opisowe:",
          descriptionPlaceholder: "Wprowadź deskryptor",
          add: "Dodaj",
          repeat: "Zduplikowane treści",
          empty: "Słowa opisowe nie mogą być puste",
          recommendEmpty:
            "Nigdy więcej zalecanych opisów, wprowadź i dodaj je ręcznie.",
          recommend: "Inteligentny deskryptor rekomendacji",
          cancel: "Anuluj",
          change: "Zmienić grupę",
          descriptionSelected: "Dodano deskryptor:",
          save: "Zapisz",
          success: "Słowa koncepcyjne zapisane pomyślnie",
          warning: "Nazwa słowa koncepcyjnego nie może być pusta",
          warning2: "Dodaj co najmniej 1 deskryptor",
          submitBtnHandle: {
            title:
              "Wybrany deskryptor nie został dodany. Zapisywanie spowoduje odrzut niedodanego deskryptora.",
            content:
              "Kliknij OK, aby powrócić do strony głównej ze słowem koncepcyjnym, potwierdzić, aby kontynuować?",
          },
        },
      },
      smartTools: {
        btn: "Inteligentne narzędzia",
        title: {
          similarWords: "Znaleziono powiązane słowa",
          search: "Słowem",
          semanticTags: "Etykieta semantyczna",
        },
        sentenceSearch: {
          placeholder:
            "Proszę wpisać słowa kluczowe wyszukiwania, obsługuje wiele słów kluczowych",
          more: "Wyświetl pełny tekst",
          pageUp: "Poprzednia strona",
          pageDown: "Następna strona",
          total: "Łącznie {0} rekordów",
        },
        similarDiscover: {
          placeholder: "Wpisz słowo kluczowe",
          copyed: "Skopiowane",
          none: "Brak odpowiednich słów, spróbuj innych słów kluczowych",
        },
        smartTags: {
          searchPlaceHolder: "Proszę wpisać słowa kluczowe lub zdania",
          createSmartTag: "Nowy",
          createClusteringSmartTag: "Utwórz nowe z {0} fragmentów danych",
          addFromKeyword: "Dodaj zawartość wyszukiwania do podobnych zdań",
          hit: "Uderzenie",
          similar: "Podobne",
          name: "Nazwa etykiety semantycznej",
          namePlaceHolder: "Wprowadź nazwę etykiety semantycznej",
          recallWords: "Przypomnij sobie słowo",
          recallWordsPlaceHolder:
            "Wprowadź słowo kluczowe przypominające o wycofaniu",
          recallRegular: "Przypomnij sobie regularne",
          recallRegularPlaceHolder: "Proszę wpisać Recall Regularne",
          rules: "Zasady",
          standardSentences: "Standardowe zdanie",
          standardSentencesPlaceHolder: "Proszę wprowadzić standardowe zdanie",
          similaSentences: "Podobne zdania",
          similaSentencesPlaceHolder: "Proszę wpisać podobne zdanie",
          notSaveError: "Zapisz etykietę semantyczną przed wyszukaniem",
          add: "Dodaj",
          newPage: "Otwórz na nowej stronie",
          querryError:
            "Podobne pola istnieją na liście uogólnień (zaawansowana konfiguracja podobnych ograniczeń deduplikacji)",
          detailTitle: "Szczegóły etykiety semantycznej",
        },
      },
      trainingCenter: {
        title: "Centrum szkoleniowe",
        edit: "Edytuj",
        delete: "Usuń",
        seleteAll: "Wybierz wszystkie",
        cancelCheckAll: "Anuluj Wybierz wszystkie",
        add: "Dodaj",
        remove: "Usuń",
        save: "Zapisz",
        cancel: "Anuluj",
        fastTraining: {
          title: "Szybkie szkolenie",
          titleDes:
            "Szybkie szkolenie ma na celu pomóc etykietom semantycznym szybko uogólnić do 20 podobnych zdań",
          semanticTags: "Etykieta semantyczna",
          semanticTagsPlaceholder:
            "Wprowadź proces wyszukiwania słów kluczowych",
          standardSentence: "Standardowe zdanie:{0}",
          similarSentencesPlaceholder:
            "Wpisz podobne zdania, aby dodać lub wyszukać",
          similarSentencesAdd: "Dodaj",
          similarSentencesSearch: "Szukaj",
          similarSentences: "Dodano podobne zdanie",
          trainingCount: "Liczba treningów:{0}",
          similarSentencesRecommend: "Zalecane podobne zdania",
          noResp:
            "Brak odpowiedniej etykiety semantycznej, zmień słowo kluczowe",
          similarSentencesModalTitle: "Podobne wyszukiwanie zdań",
          moreInfo: "Zobacz kontekst",
          moreInfoBtn: "Kontekst",
          modalSearchType: {
            all: "Wszystkie",
            customer: "Obsługa klienta",
            client: "Klient",
          },
          repetWarning:
            "To samo podobne zdanie już istnieje, proszę nie dodawać go wielokrotnie.",
          repetRemoveTip:
            "W zapisanej podobnej sentencji znajduje się zduplikowana zawartość, która została automatycznie zdeduplikowana.",
        },
        trainingHistory: {
          title: "Historia szkolenia",
          select: {
            date: "Wybierz czas:",
            today: "Dzisiaj",
            thisMonth: "W tym miesiącu",
            lastMonth: "W zeszłym miesiącu",
            thisQuarter: "W tym kwartale",
            lastQuarter: "Ostatni kwartał",
            thisYear: "W tym roku",
            lastYear: "W zeszłym roku",
            search: "Zapytanie",
            reset: "Zresetuj",
          },
          table: {
            time: "Czas",
            user: "Operator",
            tag: "Etykieta semantyczna",
            similarCount: "Liczba podobnych zdań",
            deleteCount: "Liczba usuniętych zdań",
            page: "Strona {1} z {0}",
          },
        },
        classifiedTraining: {
          title: "Szkolenie klasyfikacyjne",
          tagsGrounp: "Grupowanie etykiet",
          placeHolder: "Proszę wybrać",
          cellTime: "Wybierz czas",
          search: "Zapytanie",
          reset: "Zresetuj",
          classifiedFlag: "Szkolenie klasyfikacyjne",
          num: "Numeryczny",
          gas: "Procent",
          noData: "Szkolenie niesklasyfikowane",
          noDataHeight:
            "Niesklasyfikowane dane są wysokie i pilnie potrzebne jest szkolenie.",
          noDataSmall:
            "Dane niesklasyfikowane stanowią stosunkowo wysoki odsetek i zaleca się szkolenie.",
          noDataDays: "{0} dni przed ostatnią egzekucją",
          training: "Szkolenie",
          start: "Start",
          addClass: "Dodaj klasyfikację",
          custom: "Zaawansowane filtrowanie",
          marked: "Oznaczone dane Errata",
          markedHeight:
            "Errata nie była wykonywana przez {0} dni i zaleca się to zrobić.",
          markedSmall:
            "Errata nie była wykonywana przez {0} dni. Errata jest pilnie potrzebna.",
          mark: "Errata",
          dataCheck: "Weryfikacja danych niejawnych",
          dataCheckSmall:
            "K-L rozbieżność jest wyższa niż 0,5, zaleca się trenować.",
          dataCheckHeight:
            "K-L dywergencja jest wyższa niż 0,8 i pilnie potrzebne jest szkolenie.",
          check: "Kalibracja",
          history: "Historia Callout",
          historyTime:
            "Codzienna adnotacja i modyfikacja automatycznie wykona szkolenie modelowe o godzinie 2:00.",
          today: "Otagowane dzisiaj",
          article: "Artykuł",
          none: "Brak",
          historyCheck: "Historyczne całkowite objawienie",
          look: "Wyświetl rekordy",
          historyluate: "Przeliczenie danych historycznych",
          startluate: "Obliczanie dźwigu",
          classData: "Dane niejawne",
          classStatus: "Postęp szkoleniowy",
          next: "Następny artykuł",
          prev: "Poprzedni artykuł",
          finish: "Kompletne szkolenie",
          stop: "Koniec szkolenia",
          classDetail: "Szczegóły szkolenia",
          trainingNone: "Brak szkolenia dla inteligentnej etykiety",
        },
        classifiedTrainingHistory: {
          title: "Historia szkolenia",
          time: "Wybierz czas:",
          tag: "Rodzaj etykiety:",
          user: "Operator:",
          data: "Wybierz źródło danych:",
          search: "Zapytanie",
          searchBar: "Szukaj:",
          searchBarPlaceholder:
            "Wprowadź wyszukiwanie identyfikatora połączenia/identyfikatora konwersacji",
          reset: "Zresetuj",
          export: "Eksport",
          all: "Wszystkie",
          select: {
            today: "Dzisiaj",
            thisMonth: "W tym miesiącu",
            lastMonth: "W zeszłym miesiącu",
            thisQuarter: "W tym kwartale",
            lastQuarter: "Ostatni kwartał",
            thisYear: "W tym roku",
            lastYear: "W zeszłym roku",
            search: "Zapytanie",
            reset: "Zresetuj",
          },
          table: {
            user: "Operator",
            date: "Czas operacji",
            data: "Źródło danych",
            id: "Identyfikator połączenia/identyfikator rozmowy",
            tagType: "Inteligentne grupowanie etykiet",
            tagName: "Nazwa etykiety",
            state: "Status",
            sizeChangeText: "Wyświetl na stronę",
            page: "Strona {1} z {0}",
            call: "Zadzwoń",
            dialogue: "Dialog",
          },
        },
        clusteringAnnotation: {
          title: "Grupowanie adnotacji",
          left: { title: "Wyniki grupowania" },
          right: {
            title: "Klastrowanie zdań",
            checkAll: "Wybierz całą tę stronę",
            tagging: "Objaśnia",
            detail: "Widok",
            meaningless: "Ignoruj",
            meaninglessTips:
              "Zignorowane wyniki grupowania nie będą wyświetlane w przyszłości. Czy na pewno chcesz je zignorować?",
            cancel: "Anuluj",
          },
        },
      },
      clientCenter: {
        clientManage: {
          title: "Zarządzanie klientami",
          tabTitle: "Centrum obsługi klienta klienta",
        },
        wechatClientManage: {
          title: "Przedsiębiorstwo mikro klienta",
          table: {
            id: "Identyfikator klienta",
            name: "Pseudonim klienta",
            tag: "Etykieta klienta",
          },
        },
      },
      collectionRule: {
        title: "Zasady zbierania",
        form: {
          info: "Inteligentne zbieranie informacji",
          infoPlaceholder: "Wybierz kategorię",
        },
        table: {
          name: "Nazwa pola",
          props: "Atrybuty klienta",
          type: "Typ pola",
          desc: "Opis pola",
        },
      },
      dataAdd: {
        title: "Dodaj dane",
        types: { auto: "Dodaj automatycznie", manual: "Dodaj ręcznie" },
      },
      wechatStaff: {
        table: {
          name: "Imię i nazwisko",
          part: "Departament",
          phone: "Numer telefonu komórkowego",
          email: "Skrzynka pocztowa",
          wechartId: "Enterprise WeChat ID",
          status: "Status w miejscu pracy",
        },
        info: "Proszę wybrać dział, który musi najpierw zsynchronizować dane",
        synSuccess: "Synchronizacja się powiodła",
        init: "Inicjalizacja",
        syn: "Synchronizacja",
      },
      appealHistory: {
        appealTime: "Czas odwołania",
        cancelTime: "Czas odwołania",
        reviewTime: "Czas przeglądu",
        inspector: "Inspektor jakości",
        result: "Wynik reklamacji",
        complainResult:
          "W sumie od {0} elementów kontroli jakości odwołano się, {1} zostały pomyślnie odwołane, a {2} odrzucono",
        reason: "Powód reklamacji",
        rejection: "Powód odrzucenia",
        cancel: "Odwołany",
        tip: "Czy można potwierdzić wycofanie skargi?",
      },
      appealSdk: {
        time: "Czas dialogu",
        externalIdCall: "Identyfikator dziennika połączeń",
        externalIdText: "Identyfikator rekordu konwersacji",
        customerNickName: "Pseudonim klienta",
        readStatus: "Status przeglądu",
        status: "Status reklamacji",
        isManual: "Ręczna kontrola jakości",
        complainButtonInfo:
          "{0} dozwolonych odwołań, {1} już wniesionych odwołań, pozostały {2} odwołań. {3} może odwołać się wcześniej",
        submit: "Złóż skargę",
        complain: "Skarga",
        allComplain: "Skargi wszystkie",
        pendingSubmit: "Do złożenia",
        taskIds: "Zadanie kontroli jakości",
        graded: "Ocena",
        complainSuccess: "Skarga złożona pomyślnie",
        appealSdkIndex: { title: "Inteligentne wyniki kontroli jakości" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "Nie udało się uzyskać informacji o liście filtrów, sprawdź połączenie sieciowe lub spróbuj ponownie później!",
      columns: "Kolumna",
      filter: "Filtr",
      noSearchResult: "Nie znaleziono żadnych zapisów",
      pleaseSearch: "Wybierz filtr i spróbuj wyszukać",
      sift: "Filtr",
      create: "Nowy",
      search: "Szukaj",
      createTaskTitle: "Nowe zadanie",
      title: "Tytuł",
      deadline: "Termin",
      principal: "Osoba odpowiedzialna",
      enqueueTime: "Czas wejścia",
      computeError: "Obliczenia nie powiodły się, proszę czekać!",
      sum: "Suma",
      max: "Maksimum",
      min: "Minimum",
      avg: "Średnia",
      count: "Hrabia",
      maxDate: "Maksimum (Najnowsze)",
      minDate: "Minimum (najstarsze)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Nie udało się uzyskać adresu linku Insight, spróbuj ponownie później!",
      },
      template: {
        learningReminder: "Przypomnienie o nauce",
        subscriptionDynamics: "Dynamika subskrypcji",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "Zarządzanie zadaniami", chaneg: "Przełącznik" },
        qualityCheckTemplate: {
          text: "Szablon kontroli jakości",
          collectText: "Szablon akwizycji",
          condition: { text: "Warunki kontroli jakości" },
          checkPoint: { text: "Konfiguracja reguły" },
          automaticAdd: { text: "Automatyzacja procesów" },
          manualAdd: { text: "Dodaj ręcznie" },
          roleConfig: { text: "Konfiguracja roli" },
          basicInfo: { text: "Podstawowe informacje" },
          smartWordsLibrary: { text: "Inteligentny tezaurus" },
          grade: { text: "Ocena jakości kontroli" },
          collectionRule: { text: "Zasady zbierania" },
          addData: { text: "Dodaj dane" },
          collectionList: { text: "Lista nabycia" },
        },
        qualityCheckWorkbench: {
          text: "Stół do kontroli jakości",
          wechatText: "Analiza Workbench",
          informationCollectText: "Akwizycja Workbench",
          qualityInspectionList: {
            text: "Lista inspekcji jakości",
            wechatText: "Lista analityczna",
            informationCollectText: "Lista nabycia",
          },
          condition: { text: "Lista kontroli jakości (przestarzała)" },
          reviewList: { text: "Lista przeglądowa" },
          spotCheckList: { text: "Lista pobierania próbek" },
          caseBaseList: { text: "Biblioteka przypadków" },
          testSetList: { text: "Zestaw do badań kontroli jakości" },
        },
        statisticsAndReports: {
          text: "Statystyki i sprawozdania",
          wholeReport: { text: "Sprawozdanie ogólne" },
          groupReport: { text: "Raport grupy obsługi klienta" },
          agentReport: { text: "Raport z obsługi klienta" },
          customerServiceExecution: { text: "Analiza obsługi klienta" },
          analysisOverview: { text: "Przegląd analizy" },
          inspectionItemsAnalysis: { text: "Analiza pozycji kontroli jakości" },
        },
        wechatQa: {
          text: "Inspekcja jakości Enterprise WeChat",
          task: { text: "Zadanie analizy" },
          baseInfo: { text: "Podstawowe informacje" },
          checkPoint: { text: "Konfiguracja modelu analizy" },
          setting: { text: "Konfiguracja analizy WeChat Enterprise" },
          automaticAdd: { text: "Automatyzacja procesów" },
          manualAdd: { text: "Dodaj ręcznie" },
        },
        clientCenter: { text: "Centrum obsługi klienta" },
      },
      conditionDictionaries: {
        title: "Wszystkie warunki",
        searchDefaultText: "Wprowadź identyfikator lub nazwisko",
        id: "ID",
        name: "Imię i nazwisko",
        conditionDetailedShow: "Rozwiń",
        conditionDetailedHide: "Stow",
        remark: "Opis:",
        checkRole: "Rola:",
        operator: "Operator:",
        operatorLogic: "Logika:",
      },
      audioTextView: {
        customer: "Klient",
        agent: "Obsługa klienta",
        unsupportedFormat: "Ten format nie jest obsługiwany.",
      },
      checkPointClassifications: {
        add: "Dodaj kategorię",
        name: "Imię i nazwisko",
        enterToSave: "Naciśnij Enter, aby zapisać",
        highestScore: "Maksymalny limit w skali",
        minimumScore: "Minimalny wynik Niższy Limit",
        defaultMinScore: "Domyślnie jest to 0 punktów",
        minimumScoreTitle: "Niższy limit punktacji",
        beginScore: "Ogólna wartość wyniku początkowego",
        beginScoreTitle: "Początkowa wartość wyniku",
        scoreSetTip:
          "Maksymalny wynik jest mniejszy niż suma wszystkich wyników. Możesz wybrać:",
        ignoreExcessSpillover: "Zignoruj nadmiar przepełnienia",
        automaticWeightCalculation: "Automatyczne rozliczanie wagi",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "Pole" },
    checkRange: {
      numberTipOne: "Nie.",
      numberTipTwo: "Zdanie",
      numberTipThree: "Wyrok na nr.",
      conditionLimit: "Ograniczenia warunkowe",
      roleLimit: "Ograniczenia ról",
      contextLimit: "Ograniczenia zdań",
      notZero: "Wejście 0 nie jest obsługiwane w przypadku ograniczenia zdań",
      startMustLessThanEnd:
        "Pozycja zdania początkowego musi być mniejsza niż pozycja zdania końcowego",
    },
    waveAudio: {
      play: "Graj",
      pause: "Przerwa",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "Proszę wejść",
      pleaseEnterRegular: "Wprowadź wyrażenie regularne",
      pleaseEnterGrammar: "Wprowadź regułę gramatyki",
      pleaseEnterTestText: "Wprowadź treść, która ma zostać zweryfikowana",
      pleaseEnterLoudness: "Wprowadź decybel głośności",
      pleaseEnterRangeAbility: "Wprowadź zakres zmian",
      afterHit: "Sprawdź po trafieniu",
      pleaseEnterContext: "Proszę wpisać oświadczenie referencyjne",
      wordNumber: "Słowo Gap",
      intervalSecond: "Przedział czasu większy",
      minute: "Co minutę.",
      wordUnit: "Słowo",
      test: "Test",
      testRegular: "Przetestuj Wyrażenia Regularne",
      testGrammar: "Gramatyka testowa",
      testGrammarError: "Test gramatyki nie powiódł się",
      regularError: "Błąd wyrażeń regularnych, proszę ponownie wejść",
      pleaseEnterSemanticSentence:
        "Proszę wprowadzić zdanie, które wymaga wykrywania semantycznego",
      pleaseEnterAssociate:
        "Zapoznaj się z instrukcjami po prawej stronie, aby zbudować wyrażenie wzdłuż danych",
      querySemanticTags: "Wybierz etykietę semantyczną",
      reQuerySemanticTags: "Zmień wybór etykiet semantycznych",
      semanticTags: "Etykieta semantyczna",
      standardSentence: "Standardowe zdanie",
      semanticName: "Etykieta semantyczna",
      sentence: "Standardowe zdanie",
      similarity: "Podobieństwo",
      actions: "Operacja",
      tipOne: "Nie znalaz³em odpowiedniej etykiety,",
      tipTwo: "Dodaj nowy znacznik semantyczny",
      notLessThan: "Nie mniej",
      notGreaterThan: "Nie większy",
      ignoreLessThan: "Ignoruj mniej",
      wordUnitSet: "Zdanie słów",
      speedWordNotNull:
        "Minimalna liczba słów i maksymalna liczba słów w wykrywaniu prędkości mowy nie mogą być puste.",
      speedWordMinValueLessThanMaxValue:
        "Minimalna liczba słów w wykrywaniu prędkości mowy nie może być większa niż maksymalna liczba słów.",
      speechGrabbingIntervalTip: "Odstęp> =",
      speechLengthTip: "Zdanie jest długie> =",
      msUnit: "ms",
      sUnit: "Sekundy",
      grabIntervalNotNull: "Interwał wyrwania nie może być pusty",
      grabLengthNotNull: "Zdanie nie może być puste.",
      pleaseSelectEntity: "Wybierz jednostkę informacyjną",
      pleaseSelectOperator: "Proszę wybrać działanie",
      pleaseSelectBasicInfo: "Proszę wybrać podstawowe informacje",
      pleaseEnterFixedValue: "Wprowadź stałą wartość",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "Dopasuj pomyślnie",
      matchError: "Mecz nie powiódł się",
      mindetectionStatement: "Minimalne oświadczenie o wykrywaniu",
      conceptWordholder: "Proszę wybrać słowo koncepcyjne",
      elementHolder: "Wprowadź element",
      associateMedol: { tabs: { data: "Dane", operator: "Operator" } },
      repet: "Powtórz",
      bout: "Czasy",
      ignore: "Ignoruj mniej",
      ignoreAffix: "Zdanie słów",
      associateData: "Dane dotyczące ruchu drogowego",
      pretreatmentData: "Wstępne przetwarzanie danych",
      reviewTaskData: "Przejrzyj dane zadania",
      taskData: "Dane dotyczące zadań kontroli jakości",
      businessData: "Dokumentacja biznesowa",
      functionOperator: "Operatory funkcyjne",
      operation: "Operator operacyjny",
      notAnyMatch: "Gdy jakiekolwiek problemy nie zostaną trafione:",
      times: "Liczba wystąpień",
      newAssociateDataTip:
        "Jeśli wartość jest typem numerycznym, użyj funkcji starszej",
    },
    conditionGather: {
      cycle: "Cykl",
      rlue: "Zasady",
      delete: "Usuń",
      sampling: "Ekstrakcja",
      item: "Artykuł",
      percent: "%",
      samplingSame: "Ta sama liczba ekstrakcji na środek",
      samplingRecheck:
        "Umożliwienie powtórnego ekstrakcji produktów objętych próbą",
      dateUnit: "Nie.",
      samplingTip:
        "Różne zasady dotyczące tego samego wywołania nie będą wyodrębniane wielokrotnie, a rekordy, które zostały wyodrębnione, ale nie zostały sprawdzone, nie zostaną ponownie wyodrębnione.",
      add: "Nowa zasada",
    },
    ruleGather: {
      title: "Zasady",
      screeningCondition: "Stan filtra",
      addRule: "Nowa zasada",
      inspector: "Inspektor jakości",
      ruleTip:
        "Tryb alokacji w pierwszej kolejności, od góry do dołu, nie powtarza alokacji, w ramach reguły lub nie pasuje do żadnej reguły elementy zostaną przydzielone losowo.",
      moveUp: "Przesuń w górę",
      moveDown: "Przesuń w dół",
      top: "Top",
    },
    pushRulesGather: {
      title: "Zasady",
      delete: "Usuń",
      newPush: "Nowy Push",
      newRule: "Nowa zasada",
      sampling: "Ekstrakcja",
      item: "Artykuł",
      percent: "%",
      samplingSame: "Ta sama liczba ekstrakcji na środek",
      dateUnit: "Nie.",
      screeningCondition: "Stan filtra",
      email: "Skrzynka pocztowa",
      emailPlaceholder: "Wprowadź skrzynki pocztowe, oddzielone przecinkami",
      updateType: "Typ aktualizacji",
      pushDetail: "Push Szczegóły",
      pushUrl: "Push URL",
      pushWay: "Typ Push",
      pushMethod: "Tryb push",
      pushContentType: "Format danych",
      pushData: "Dane push",
      additionalData: "Dodatkowe dane",
      inspectionType: "Rodzaj kontroli jakości",
      all: "Wszystkie",
    },
    taskAdditionalData: {
      newData: "Nowe dane",
      delete: "Usuń",
      placeholder: "Wpisz nazwę",
      dataPlaceholder: "Wprowadź zawartość",
    },
    UdeskQaReact: {
      loadSdkError: "Ładowanie SDK nie powiodło się, spróbuj ponownie później!",
    },
    gearOptionList: { evaluation: "Ocena" },
    tableComponentTransfer: {
      leftTitle: "Lista pozycji",
      rightTitle: "Pokaż przedmiot",
    },
    kmTinymce: {
      link: "Wstaw/Edytuj link",
      modalTitle: "Wstaw link",
      href: "Adres",
      text: "Pokaż tekst",
      title: "Tytuł",
      target: "Tryb otwarty",
      none: "Brak",
      newWindow: "Otwórz w nowym oknie",
      buttonStyle: "Styl przycisku",
      close: "Zamknij",
      open: "Otwarte",
      answerMessage: "Jump/Wyślij Wiadomość",
      answerMessageTitle: "Przycisk Wyślij wiadomość",
      answerMessageText: "Pokaż tekst",
      answerMessageContent: "Treść wiadomości",
      answerMessageButtonStyle: "Styl przycisku",
      answerMessageTips:
        "Uwaga: przycisk wysyłania wiadomości obsługuje tylko kanał internetowy, należy go starannie skonfigurować.",
      answerSwitchStaff: "Podręcznik skoku/skrętu",
      answerSwitchStaffTitle: "Przekręć przycisk ręczny",
      answerSwitchStaffText: "Pokaż tekst",
      answerSwitchStaffRule: "Zasady przycisków",
      answerSwitchStaffButtonStyle: "Styl przycisku",
      answerSwitchStaffTips:
        "Uwaga: przycisk ręcznego przełączania obsługuje tylko kanał internetowy, należy go dokładnie skonfigurować.",
      textButton: "Przycisk tekstowy",
      borderButton: "Oprawiony przycisk",
      message: "Wyślij wiadomość",
      switchStaff: "Obróć instrukcję",
      messageError: "Zawartość przycisku nie może przekraczać 10 słów",
      messageIsEmptyError: "Zawartość przycisku nie może być pusta",
      okText: "Określ",
      cancelText: "Anuluj",
      video: "Wideo",
      uploadVideo: "Prześlij wideo",
      uploadText: "Obsługuje tylko format MP4, rozmiar nie większy niż 20M",
      videoEmptyOrUploading: "Wideo jest puste lub przesyłanie",
      customEmoji: "Wyrażenie",
    },
    massageConfiguration: {
      pushTarget: "Cel powiadomienia",
      customizePushTarget: "Niestandardowe miejsce docelowe Push",
      sysUser: "Aktualny użytkownik systemu",
      sysRole: "Obecna rola systemu",
      emailPushTarget: "Wyślij przeznaczenie",
      targetEmailsPlaceholder: "Wpisz skrzynki pocztowe oddzielone przecinkami",
      subject: "Nazwa wiadomości",
      SDKID: "ID SDK",
      content: "Treść powiadomienia",
      sdkPushType: "Schemat push",
      sdkPushURL: "Push URL",
      pushData: "Dane push",
      sdkPushWay: "Tryb push",
      dataType: "Format danych",
      additionalData: "Dodatkowe dane",
      name: "Nazwa wiadomości",
      filter: "Stan filtra",
      triggerType: "Mechanizm spustowy",
      pushCycle: "Czas stałej częstotliwości",
      pushContent: "Zawartość raportu",
      qualityList: "Lista wyników kontroli jakości",
      designated: "Wyznaczony personel",
      messageType: "Rodzaj wiadomości",
    },
    taskCard: {
      template: { cancelCollection: "Anuluj ulubione", collection: "Kolekcja" },
    },
    semanticTagsSelect: { index: { standardSentence: "Standardowe zdanie:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "Kolekcja",
          calls: "Połączenie",
          createdBy: "Stworzony:",
          createdOn: "Czas tworzenia:",
        },
        fileItem: {
          uIProvidesIcon: "Ui zapewnia ikonę",
          minute: "Minuty",
          callClip: "Zadzwoń do fragmentu",
          start: "Start",
          End: "Koniec",
          employees: "Pracownicy:",
          Customer: "Klient:",
          sessionTime: "Czas sesji:",
          reasonForRecommendation: "Powód rekomendacji:",
          addedBy: "Dodano:",
          addTime: "Dodaj czas:",
        },
        modalAddFile: {
          caseTitle: "Tytuł sprawy",
          addSomeClipsToTheCaseLibrary:
            "Dodaj częściowe fragmenty do biblioteki spraw",
          addToCaseBase: "Dodaj do biblioteki spraw",
          storedIn: "Przechowywane w",
          pleaseSelect: "Proszę wybrać",
          reasonsForRecommendation: "Powód rekomendacji",
          pleaseEnter: "Proszę wejść",
        },
      },
      shareModal: {
        index: {
          customer: "Klient:",
          employees: "Pracownicy:",
          timeliness: "Terminowość:",
          recordingLinkValidWithinDays: "Link do nagrywania ważny w ciągu dni",
          sharingInstructions: "OPIS UDZIAŁU:",
          shareSuccess: "Dzielenie się sukcesem!",
          cancel: "Anuluj",
          share: "Udostępnij",
          copySucceeded: "Kopiowanie udane!",
          copyShareLink: "Kopiowanie łącza udostępniania",
          shareWithColleagues: "Podziel się z kolegami",
          shareWithCustomers: "Podziel się z klientami",
          staff: "Pracownik",
          selectColleagues: "Select Koledzy",
          pleaseSelectColleaguesToShare: "Wybierz kolegę do udostępnienia",
          pleaseEnterTheSharingDescription: "Wprowadź instrukcje udostępniania",
          taskName: "Zadania:",
          recordingShareValidWithinDays:
            "Łącze do udostępniania ważne w ciągu dni",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "Nie można znaleźć pliku!",
          sale: "Sprzedaż:",
          customer: "Klient:",
          topicDetection: "Wykrywanie tematu:",
          keyEvents: "Kluczowe wydarzenia:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "Select Koledzy",
          helpContent: "Zawartość pomocy",
          visibleRange: "Widoczny zasięg",
          seekCounseling: "Szukaj porady",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "Szukaj porady", concatText: "Do" },
      },
      customerInsight: {
        index: {
          customerInformation: "Informacje o kliencie",
          customerInsight: "Wgląd klienta",
          customerLabel: "Etykieta klienta",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "Zalecane słowa",
          objectionHandlingScripts: "Obsługa sprzeciwu",
          productSellingPointScripts: "Punkty sprzedaży produktów",
          knowledgePointRecommendation: "Zalecenie Knowledge Point",
          relatedKnowledgePoints: "Powiązane punkty wiedzy",
          recommendedCases: "Zalecane przypadki",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "Zalecane słowa",
          caseRecommendation: "Rekomendacja przypadku",
          hit: "Uderzenie",
          miss: "Przegapione",
          supervisionItem: "Pozycje nadzoru",
          implementation: "Status wdrożenia",
          reInspection: "Ponowna inspekcja",
          supervisionItemSettings: "Ustawianie pozycji nadzoru",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "Rozmowy telefoniczne",
          enterpriseWeChatCall: "Przedsiębiorstwo Micro-Talk",
          enterpriseMicroConversation: "Przedsiębiorstwo mikro-sesja",
          open: "Otwarte",
          close: "Zamknij",
          conversationScenario: "Scenariusze sesji",
          monitorConversationChannels: "Nadzoruj kanał konwersacji",
          permanentlyValid: "Trwale skuteczny",
          permanentlyInvalid: "Trwale nieważny",
          customValidity: "Okres ważności na zamówienie",
          termOfValidity: "Okres ważności",
          newRating: "Nowa ocena",
          whenConversation: "Kiedy sesja",
          hit: "Uderzenie",
          miss: "Przegapione",
          time: "Kiedy",
          rated: "Ocena jest",
          qualified: "Wykwalifikowany",
          unqualified: "Bez kwalifikacji",
          modifiedSuccessfully: "Modyfikacja się powiodła",
          classificationName: "Nazwa kategorii",
          supervisionObject: "Przedmiot nadzoru",
          entryintoforceTime: "Efektywny czas",
          supervisionItemRating: "Ocena pozycji nadzoru",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "Sprawdź, czy ocena nadzoru jest prawidłowa",
          ratingRules: "Zasady oceny",
          settingOfRatingRulesForSupervisionItems:
            "Monitorowanie Ustawianie reguły oceny pozycji",
        },
      },
      reInspect: {
        index: {
          keyEvents: "Kluczowe wydarzenia",
          supervisionItem: "Pozycje nadzoru",
          conversationScenario: "Scenariusze sesji",
          customerLabel: "Etykieta klienta",
          reDetectionRules: "Ponownie wykryj zasady",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "Pobieranie nagrywania",
          downloadText: "Pobierz tekst",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "Prześlij obraz",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Proszę wybrać zdjęcie o rozmiarze nieprzekraczającym 2048kb",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "Lub",
            and: "I",
            wrong: "Nie",
            matchTo: "Dopasuj",
            rule: "Zasady",
            satisfactionOperator: "Satysfakcjonować operatora",
            customerLabel: "Etykieta klienta",
            keyEvents: "Kluczowe wydarzenia",
            knowledgePoints: "Punkty wiedzy",
            sessionLabel: "Etykieta sesji",
            supervisionItem: "Pozycje nadzoru",
          },
          linkExplorer: { linkInTheText: "Link w artykule:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound: "Nie znaleziono komponentu niestandardowego:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "Niestandardowe" },
      dropdownMonit: {
        updateSucceeded: "Aktualizacja zakończyła się sukcesem",
        areYouSureToDelete: "Czy na pewno chcesz usunąć?",
        custom: "Niestandardowe",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm:
          "Zapisz aktualną konfigurację raportu === Formularz",
        saveCurrentReportConfiguration: "Zapisz konfigurację bieżącego raportu",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "Wprowadź wspólną nazwę ustawień, maksymalna długość to 20!",
        preservation: "Zapisz",
      },
      template: {
        saveCurrentReportConfiguration: "Zapisz konfigurację bieżącego raportu",
        updateCurrentReportConfiguration:
          "Aktualizacja konfiguracji bieżącego raportu",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "Informacje o zamówieniu pracy",
        workOrder: "Zamówienie na pracę",
        triggerScenario: "Scenariusz wyzwalania",
        associatedCallConversation: "Połączenie/rozmowa stowarzyszona",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "Test z aktualnym tekstem przejścia/dialogu",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "Nie.", day: "Dzień" },
      businessSummary: {
        normal: {
          equalTo: "Równe",
          notEqualTo: "Nie równe",
          contain: "Zawiera",
          excluding: "Nie zawiera",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "Jestem tym, kim jestem, innym fajerwerkiem @ 156 xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "Cześć, jestem powiadomieniem o komunikacie ostrzegawczym inspekcji jakości robota, który został dodany do grupy 17 października",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "Jestem tym, kim jestem, innym fajerwerkiem @ 156 xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "Cześć, jestem powiadomieniem o komunikacie ostrzegawczym inspekcji jakości robota, który został dodany do grupy 17 października",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "Tematy komunikacji",
          conversationSummary: "Podsumowanie sesji",
          serviceSuggestions: "Zalecenia serwisowe",
        },
        template: { smartTags: "Inteligentna etykieta" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "Kliknij, aby dowiedzieć się więcej" },
      schema: {
        textRobot: "Robot tekstowy",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "Eskorta trenera, pytanie i odpowiedź dokumentu, dialog oparty na zadaniu, pytanie i odpowiedź w tabeli, pytanie i odpowiedź wykresu.",
        capitalRetentionRobot: "Robot retencyjny",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "Bogata strategia retencji, wysoka komunikacja personifikacyjna, zapewniająca wysoki wskaźnik retencji, wskazówki dotyczące wydajnej automatycznej konwersji.",
        outboundRobot: "Robot do połączeń wychodzących",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "Wizyta powrotna po zakończeniu zlecenia pracy, badanie satysfakcji z usług, ostrzeżenie o awarii obsługi i konserwacji, przypomnienie o dostarczeniu zamówienia, powiadomienie o działalności e-commerce, mianowanie kandydatów, pomoc w automatyzacji biznesu i poprawie wydajności biznesowej",
        incomingRobot: "Robot przychodzący",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "Elastyczna integracja zleceń pracy, zamówień i innych systemów biznesowych w celu osiągnięcia automatyzacji procesów; rozpoznawanie semantyczne głosu, natychmiastowe wyniki biznesowe; jednostka (numer telefonu komórkowego, kwota zamówienia, czas zamówienia, adres,...) identyfikacja, szybkie wsparcie dla złożonej działalności",
        intelligentQualityInspection: "Inteligentna kontrola jakości",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "Pełna kontrola jakości, terminowa i wydajna, z dokładnością 85%",
        intelligentSessionAnalysis: "Inteligentna analiza sesji",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "Wgląd w krótki zarząd biznesu, słuchaj głosu klientów, usprawniaj transformację marketingową",
        enterpriseWechatConversationAnalysis: "Analiza sesji WeChat Enterprise",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "Skuteczna identyfikacja potencjalnych możliwości biznesowych, ryzyka, marketingowy współczynnik konwersji wzrósł o 20%",
        assistant: "Asystent siedzenia",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "Asystent agenta może zapewnić obsługę początkującego klienta i poprawić jakość połączeń wykwalifikowanej obsługi klienta. Niech każde miejsce będzie Twoją kartą atutową",
        digitalPeople: "Cyfrowy człowiek",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          "„ Twarzą w twarz ”komunikacja antropomorficzna, wspierająca komunikację głosową, tekstową, wideo, dotykową interakcję multimodalną, w celu uzyskania wciągającej komunikacji, poprawy wizerunku marki i obsługi użytkownika",
        aIKnowledgeZhongtaiEnterpriseSearch:
          "Wiedza AI Wyszukiwanie w przedsiębiorstwie na średnim etapie",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "Wyszukiwanie wiedzy, udostępnianie wiedzy, wyszukiwanie dokumentów, wyszukiwanie w bazie danych",
        aIKnowledgeCenterKCsKnowledgeBase:
          "Środkowe biurko wiedzy AI · Baza wiedzy KCS",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "Zapewnia pełne zarządzanie wiedzą w cyklu życia, ujednoliconą konserwację robotów i baz wiedzy komunikatorów, wspiera dokowanie z bazami wiedzy stron trzecich, szablonami wiedzy, wiedzą atomową i innymi potężnymi funkcjami, aby zapewnić wygodniejsze doświadczenie w zarządzaniu wiedzą.",
        aIKnowledgeCenterKnowledgeMap: "Platforma wiedzy AI-mapa wiedzy",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "Jednorazowa konstrukcja mapowania integruje wiedzę przedsiębiorstwa, duży obraz pokazuje szczegóły wiedzy i możliwości dokowania w celu stworzenia spersonalizowanego doświadczenia.",
        modularDevelopmentPlatformOriginalEngineASR:
          "Modułowa platforma rozwojowa-oryginalny silnik serca-ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "Przyjęcie algorytmu end-to-end, aby zrealizować spersonalizowane wrażenia z efektu usługi, z naturalnym współczynnikiem rozpoznawania scen przekraczającym 90% i spersonalizowanym rozpoznawaniem słownictwa przekraczającym 97%",
        modularDevelopmentPlatformIntelligentMarketing:
          "Modułowa platforma rozwojowa · Inteligentny marketing",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "Korzystanie z algorytmów głębokiego uczenia, w połączeniu z portretami użytkowników, w celu uzyskania dokładnych zaleceń dotyczących produktów i poprawy konwersji sprzedaży.",
        modularDevelopmentPlatformUserPortrait:
          "Modułowa platforma rozwojowa · Portret użytkownika",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "Dokładne budowanie profili użytkowników w oparciu o dane użytkownika i dane dialogowe, w pełni opisując cechy klienta",
        modularDevelopmentPlatformIntelligentRecommendation:
          "Modułowa platforma rozwojowa · Inteligentna rekomendacja",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "Zgodnie z portretem użytkownika i portretem produktu, automatycznie polecamy produkty, które mogą zainteresować użytkowników, realizując spersonalizowane rekomendacje dla tysięcy osób.",
        modularDevelopmentPlatformNLPPAAS:
          "Modułowa platforma rozwojowa · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "Zapewnia podstawowe możliwości NLP z dużą dokładnością, obejmujące niskopoziomowe możliwości o różnej szczegółowości, takie jak słowa, zdania i artykuły.",
      },
    },
    qualityScore: {
      index: {
        show: "Pokaż:",
        displayBySecondaryClassification:
          "Wyświetlanie według klasyfikacji wtórnej",
        showByRule: "Pokaż według reguły",
        pleaseEnterClassificationruleName: "Wprowadź klasyfikację/nazwę reguły",
        clickCopy: "Kliknij Kopiuj",
        hitDetailsOfOperatorsInRules: "Operator uderzył w szczegóły w regule:",
        hit: "Uderzenie",
        miss: "Przegapione",
        filter: "Filtr:",
        clickToViewDetails: "Kliknij, aby wyświetlić szczegóły",
      },
      components: {
        flowNodeList: {
          branch: "Punkty",
          pleaseSelect: "Proszę wybrać",
          viewTheCompleteProcess: "Zobacz pełny proces",
          thereIsCurrentlyNoProcessAvailable: "Brak procesu",
        },
        pointItem: { xiangHit: "Uderz w przedmiot" },
        flowNodeListModal: { index: { completeProcess: "Kompletny proces" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "Zadanie kontroli jakości procesu uległo zmianie, proszę odwołać się ponownie.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "Dane te były okresowo oczyszczane",
        subject: "Przedmiot:",
        description: "Opis:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "Dodaj do zadania jakości" },
        },
      },
    },
    relatedDetail: {
      index: { associatedCallConversation: "Połączenie/rozmowa stowarzyszona" },
    },
    samplingTarget: {
      index: {
        time: "Kiedy",
        samplingQuantityIsLessThan: "Ilość próbkowania jest niższa",
        alertWhen: "Wczesne ostrzeganie",
        everyday: "Codziennie",
        weekly: "Tygodnik",
        monthly: "Co miesiąc",
        samplingInspectionTargetSavedSuccessfully:
          "Cel inspekcji pobierania próbek został pomyślnie zapisany",
        personnel: "Personel",
        eachCustomerService: "Każda obsługa klienta",
        cycle: "Cykl",
        samplingInspectionTarget: "Docelowa kontrola pobierania próbek",
        strip: "Artykuł",
        alertSettings: "Ustawienia alertu",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "Obsługa klienta",
        complete: "Kompletny",
        Objective: "/Cel",
        everyday: "Codziennie",
        weekly: "Tygodnik",
        monthly: "Co miesiąc",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "Szybkość interakcji na czacie grupowym",
        maximumResponseIntervalTime: "Maksymalny odstęp między odpowiedziami",
        numberOfPreemptiveCalls: "Liczba urywków",
        numberOfMessages: "Liczba wiadomości",
        strip: "Artykuł",
        proportionOfMessages: "Procent liczby wiadomości",
        speechPercentage: "Współczynnik mówienia",
        longestCustomerTalk: "Najdłuższy klient mówiący",
        numberOfIndepthDiscussions: "Liczba szczegółowych dyskusji",
        second: "Czasy",
        maximumDurationOfContinuousSpeech:
          "Maksymalny czas trwania ciągłego mówienia",
        questionFrequency: "Częstotliwość pytań",
        customer: "Klient",
        keyEvents: "Kluczowe wydarzenia:",
        opportunity: {
          index: {
            generationFailed: "Budowa nie powiodła się",
            generateOpportunityPoints: "Generowanie punktów możliwości",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "Budowa nie powiodła się",
            generateSummary: "Wygeneruj podsumowanie",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "Czy na pewno chcesz usunąć?",
        add: "Dodaj",
        opportunityPoints: "Punkt możliwości",
        conversationSummary: "Podsumowanie sesji",
        cancel: "Anuluj",
        determine: "Określ",
        edit: "Edytuj",
        establish: "Stwórz",
        conversationalInsights: "Session Insights Insights",
        keyEvents: "Kluczowe wydarzenia",
        numKeyEvents: "Kluczowe wydarzenia",
        questionAnalysis: "Analiza pytań",
        find: "Znaleziono",
        questions: "Pytanie drugie",
        conversationAction: "Akcja sesyjna",
        customer: "Klient",
        sale: "Sprzedaż",
        sessionLabel: "Scenariusze sesji",
        employeeQuestions: "Pytania personelu",
        customerQuestions: "Pytania klientów",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "Klient",
        customerService: "Obsługa klienta",
        replyTo: "Odpowiedź",
        addressee: "Do:",
      },
    },
    listManage: {
      template: {
        reIdentification: "Ponowna identyfikacja",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Ponowna identyfikacja pochłonie czas transferu, proszę działać ostrożnie.",
        determine: "Określ",
        executionSuccessful: "Udana egzekucja",
        addToQualityInspectionTask: "Dodaj do zadania jakości",
        batchOperation: "Operacja partii",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "Dodano pomyślnie",
        addToQualityInspectionTask: "Dodaj do zadania jakości",
        qualityInspectionTask: "Zadanie kontroli jakości",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "Wykryj rolę",
        detectionRange: "Zasięg wykrywania",
        sentence: "Zdanie",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically: "Dane te były okresowo oczyszczane",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "Powrót do systemu biznesowego" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "Problem ekstrakcji",
        businessSummary: "Podsumowanie biznesowe",
        keyWord: "Słowa kluczowe",
      },
      components: {
        problemExtraction: {
          no: "Nie",
          customerServiceDidNotAnswer: "Obsługa klienta nie odpowiedziała",
          customerServiceHasAnswered: "Obsługa klienta odpowiedziała",
          problemUnresolved: "Problem nie został rozwiązany",
          theProblemHasBeenResolved: "Problem rozwiązany",
          customerIssue: "Problemy klienta:",
          customerServiceAnswer: "Obsługa klienta odpowiedź:",
          reExtractQuestions: "Problem z ponowną ekstrakcją",
          clickToExtractQuestions: "Kliknij, aby wyodrębnić pytania",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "Nazwa portretu",
            backgroundDescription: "Opis tła",
            customerLabel: "Etykieta klienta",
            labelManagement: "Zarządzanie etykietami",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "Nowy" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "Ustawienia kwestionowania" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "Zwiększ liczbę pytań",
              remove: "Usuń",
              addQuestioning: "Dodaj pytania",
              section: "Nie.",
              secondInquiry: "Drugie przesłuchanie",
              questioningScore: "Wynik przesłuchania",
            },
          },
        },
        index: {
          accordingToRules: "Według reguły",
          byScore: "Według wyniku",
          whenMissRule: "Gdy reguła zostanie pominięta",
          whenConductingQuestioning: "Podczas zadawania pytań",
          pleaseSelectARule: "Wybierz regułę",
          ruleCannotBeEmpty: "Zasada nie może być pusta",
          currentScriptScore: "Gdy wynik mowy ≤",
          pleaseEnterTheScore: "Wpisz wynik",
          scoreCannotBeEmpty: "Wynik nie może być pusty",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "Narrator:" },
          pPT: { slide: "Slajd" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "Uwagi",
              script: "Słowa",
              semantics: "Semantyczny",
              violatingWords: "Naruszenie słów",
              speechSpeed: "Prędkość mowy",
              emotion: "Emocja",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "Uwagi",
              regenerate: "Regenerować",
              generate: "Generuj",
              hitSituation: "Przypadek trafienia",
              noSemanticTagsMatched: "Nie pasuje do etykiety semantycznej",
              matchToSemanticTags: "Dopasuj do etykiety semantycznej",
              appealToBeSubmitted: "Skarga do złożenia",
              ratingHasBeenReviewed: "Ocena sprawdzona",
              ratingAppealInProgress: "Punktacja skarg",
              expressionScore: "Wynik wyrażenia",
              moodParticlesTimes: "Słowo tonowe, {0} razy",
              changeTheModalParticlesTo: "Dla słów modalnych",
              second: "Czasy",
              interrogation: "Kwestionowanie",
              questioningWithoutScoring: "Zapytaj bez punktacji",
              branch: "Punkty",
              matchingRate: "Wskaźnik dopasowania",
              ratingAppeal: "Ocena skargi",
              changeTo: "Zmień",
              wordPerMinute: "Słowa na minutę",
              completeScript: "Pełna mowa",
              incompleteScript: "Niepełna mowa",
              semanticHit: "Hit semantyczny",
              semanticMisses: "Semantic Misses",
              scriptViolation: "Naruszenie mowy",
              modified: "Zmodyfikowany",
              notChanged: "Nie zmodyfikowany",
              keyWord: "Słowa kluczowe",
              semanticLabels: "Etykieta semantyczna",
              semanticMatchingRate: "Stopa dopasowania semantycznego",
              violatingWords: 'Naruszenie słowa"',
              delete: "Usuń",
              unmatched: "Nie dopasowane",
              matching: "Dopasuj",
              matchTo: "Dopasuj",
              notMatchedTo: "Nie dopasowane",
              scriptScore: "Wynik mowy",
              standardScript: "Standardowa mowa",
              emotionalScore: "Wynik emocjonalny",
              speechSpeedScore: "Punktacja prędkości",
              viewResolution: "Wyświetl rozdzielczość",
              nodeConfiguration: "Konfiguracja węzła",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Czas odpowiedzi na to pytanie to nadgodziny, a treść odpowiedzi jest automatycznie przesyłana.",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "Czas odpowiedzi na to pytanie to nadgodziny, a treść odpowiedzi jest automatycznie przesyłana.",
          },
          correctBtn: {
            saveSuccessful: "Oszczędzaj pomyślnie",
            errorCorrection: "Korekcja błędów",
            cancel: "Anuluj",
            preserve: "Zapisz",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "Zasady punktacji",
            cancel: "Anuluj",
            determine: "Określ",
          },
          scoringRuleItem: {
            whole: "Wszystkie",
            arbitrarily: "Arbitralne",
            pleaseEnterARuleName: "Wpisz nazwę reguły",
            standardScript: "Standardowa mowa",
            if: "Jeśli",
            and: "I",
            or: "Lub",
            thenScore: "Następnie zdobądź punkty",
            pleaseEnterAScore: "Wpisz wynik",
            bonusPoints: "Punkty bonusowe",
            minusPoints: "Minus punkty",
            hit: "Uderzenie",
            misses: "Przegapione",
            keyWord: "Słowa kluczowe",
            semantics: "Semantyczny",
          },
          title: {
            ruleValidation: "Weryfikacja zasady",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "Zasady punktacji (łączna punktacja aktualnego wystąpienia wynosi",
            points: "Punkty)",
            editRules: "Edytuj regułę",
          },
        },
      },
      userTagsSelect: {
        index: { noLabelsCurrentlyAvailable: "Brak etykiety" },
      },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "Cele komunikacji",
              scoringCriteria: "Kryteria punktacji",
            },
          },
        },
        index: {
          generating: "Generowanie",
          generateDialogueFramework: "Generowanie ram dialogu",
          theme: "Motyw",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "Prompt",
            generatePromptLanguage: "Prompt wygenerowany",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "Faza komunikacji",
          communicationObjectives: "Cele komunikacji",
          scoringCriteria: "Kryteria punktacji",
          suggestionsForImprovement: "Rekomendacje promocyjne",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "Ocena procesu",
            contentIntegrity: "Integralność treści:",
            NumberOfCompletedProcesses: "%, Liczba zakończonych procesów",
            keyHitPointsTotal: "Kluczowe hity: łącznie",
            notReached: "Nie osiągnięto",
            accurateFollowupAccumulationOfStandardScripts:
              "Dokładna kontynuacja: standardowa akumulacja mowy",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "Słowo, liczba słów trafionych przez ucznia",
            aWord: "Słowo",
            languageNormsYes: "Specyfikacja języka: Tak",
            emotionsAppearing: "Emocje: pojawiające się",
            speechSpeedSpeakingTooFast: "PRĘDKOŚĆ: PRĘDKOŚĆ zbyt szybka",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "Tone słowa: nadmierne występowanie słów tonowych",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "Dodaj do Ignorowania Tagi",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "Czy na pewno chcesz ponownie wykonać inteligentną etykietę?",
        reExecute: "Ponowne wykonanie",
      },
      component: { successfullyAdded: "Dodano pomyślnie" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "Wyłącz wszystkie powiadomienia",
        reQualityInspectionTask: "Zadanie ponownej inspekcji",
        requalityInspection: "Ponowna inspekcja",
        waiting: "Czekanie",
        viewDetails: "Zobacz szczegóły",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "Czas wykrywania:" } },
        },
        template: { responseOpeningSentence: "Odpowiedź na początek zdania" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: {
            generationFailed: "Budowa nie powiodła się",
            generate: "Generuj",
          },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "Brak danych" } },
      },
      index: { regenerate: "Regenerować" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "Czy na pewno chcesz usunąć?",
        operationSuccessful: "Operacja powiodła się",
      },
      template: { delete: "Usuń" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "Proszę wybrać pytanie!",
        similarQuestionRecommendation: "Podobne zalecenia",
        selectAllOnPage: "Podczas wybierania wszystkich stron",
        cancel: "Anuluj",
        preserve: "Zapisz",
        changeBatch: "Zmiana partii",
      },
    },
    listControl: { index: { columnControl: "Kontrola kolumny" } },
    loginUserAvatar: { index: { switch: "Przełącznik" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "Podczas dodawania danych w czasie rzeczywistym zadanie kontroli jakości nadal wymaga czasu. Określony czas zależy od wielkości danych kontroli jakości, od kilku minut do 2 godzin.",
      },
    },
    bEditor: {
      index: {
        delete: "Usuń",
        cancel: "Anuluj",
        confirm: "Określ",
        insert: "Wkładka",
        width: "Szerokość",
        height: "Wysokość",
        clearContent: "Wyczyść zawartość",
        revoked: "Odwołany",
        redo: "Ponowne",
        fontSize: "Rozmiar czcionki",
        rowHeight: "Wysokość wiersza",
        wordSpacing: "Rozstaw słów",
        paragraphIndent: "Ustęp wcięcie",
        increaseIndent: "Zwiększ wcięcie",
        decreaseIndent: "Zmniejsz wcięcie",
        border: "Granica",
        color: "Kolor",
        textColor: "Kolor tekstu",
        backgroundColor: "Kolor tła",
        temporaryColor: "Tymczasowy kolor",
        bold: "Odbite",
        italic: "Kursywa",
        underline: "Podkreśl",
        strikethrough: "Strikethrough",
        font: "Czcionka",
        textAlignment: "Wyrównanie tekstu",
        left: "Po lewej stronie",
        centered: "Wyśrodkowany",
        right: "W prawo",
        bothEnds: "Oba końce",
        leftFloat: "Lewy pływak",
        rightFloat: "Prawy pływak",
        subrupt: "Subrupt",
        subscript: "Subscript",
        clearStyle: "Jasny styl",
        title: "Tytuł",
        regular: "Regularne",
        orderedList: "Lista zamówionych",
        unorderedList: "Lista nieuporządkowana",
        reference: "Odniesienie",
        code: "Kod",
        link: "Link",
        clearLink: "Wyczyść link",
        horizontalLine: "Linia pozioma",
        media: "Media",
        mediaLibrary: "Biblioteka mediów",
        smallExpression: "Małe wyrażenie",
        fullScreen: "Pełny ekran",
        exitFullScreen: "Wyjście na pełny ekran",
        enterLinkText: "Wprowadź tekst linku",
        enterLinkAddress: "Wprowadź adres linku",
        enterTheLinkAddressAndPressEnter:
          "Wprowadź adres linku i naciśnij Enter",
        openInNewWindow: "Otwórz w nowym oknie",
        removeLink: "Usuń link",
        playingAudioFiles: "Odtwarzanie plików audio",
        playVideoFiles: "Odtwarzaj pliki wideo",
        embeddedMedia: "Media wbudowane",
        image: "Obraz",
        video: "Wideo",
        audio: "Dźwięk",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "Imię i nazwisko",
        description: "Opis",
        type: "Rodzaj",
        status: "Status",
        actions: "Operacja",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "Nazwa zadania",
        description: "Opis",
        form: "Formularz inspekcji jakości",
        actions: "Operacja",
        createUser: "Stwórca",
      },
      samplingRecords: {
        createTime: "Czas",
        type: "Rodzaj",
        operateUser: "Operator",
        number: "Dodaj ilość",
        filterConditionList: "Stan filtra",
        sampleRuleStr: "Warunki pobierania próbek",
        timeGreaterThan: "Czas jest większy",
        timeLessThan: "Mniej czasu",
      },
    },
    customFilters: {
      createdAt: "Czas rozmów",
      customerId: "Klient",
      agentId: "Obsługa klienta",
      userGroupId: "Grupa Obsługi Klienta",
      state: "Status",
      lastEvaluateUserId: "Ostatni Rater",
      submitTime: "Ostatni czas na poddanie",
      totalScore: "Całkowity wynik",
      formItemForbidIdList: "Przedmioty zabronione",
      formItemDeadlyIdList: "Fatalny przedmiot",
    },
    samplingInspection: {
      callTime: "Czas rozmów",
      dialogTime: "Czas dialogu",
      originator: "Osoba alokowana",
      createTime: "Czas tworzenia",
      updateTime: "Czas aktualizacji",
      status: "Status procesu",
      score: "Frakcja",
      actions: "Operacja",
      reviewer: "Recenzent",
      samplingOfficer: "Inspektor pobierania próbek",
      id: "Identyfikator dziennika połączeń",
      time: "Czas rozmów",
      phone: "Telefon klienta",
      staff: "Obsługa klienta",
      inspectionStatus: "Status kontroli jakości",
      samplingStatus: "Status pobierania próbek",
      inspectionGrade: "Ocena",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "Standardowe słowo",
        synonymWords: "Synonimy",
        synonymWordsNumber: "Liczba synonimów",
        useFrequency: "Częstotliwość użycia",
        effectiveness: "Skuteczność",
        operation: "Operacja",
      },
      common: {
        useFrequency: "Częstotliwość użycia",
        effectiveness: "Skuteczność",
        operation: "Operacja",
      },
      professionalWords: {
        professionalWords: "Profesjonalny rzeczownik",
        useFrequency: "Użyj statystyk",
        homophonic: "Gorące słowa (substytucja homofonu)",
        target: "Podstawienie rzeczownika",
        targetWords: "Zastępcze słowa",
        homophonyWords: "Słowo maskujące homofon",
        operation: "Operacja",
      },
      attentionWords: "Zaniepokojone słowa",
      ignoreWords: "Ignoruj słowa",
      wordsWeight: {
        selfDefineWeightWords: "Słowo o niestandardowej wadze",
        weightWords: "Słowa wagi",
        weight: "Waga",
        useFrequency: "Częstotliwość użycia",
        effectiveness: "Skuteczność",
        operation: "Operacja",
      },
      corpusData: {
        corpusDescription: "Opis korpusu",
        updateTime: "Czas aktualizacji",
        wordsCount: "Liczba słów",
        effectiveness: "Skuteczność",
        operation: "Operacja",
        corpusContent: "Treść",
      },
    },
    semanticTags: {
      title: "Etykieta semantyczna",
      tags: "Standardowe zdanie",
      orgName: "Organizacja",
      tagCount: "Liczba szkoleń",
      usingCount: "Liczba referencji",
      activeFlag: "Skuteczność",
      suggestion: "Zalecenia dotyczące szkoleń",
      actions: "Operacja",
    },
    intelligentTags: {
      tagName: "Imię i nazwisko",
      tagDesc: "Opis",
      tagRefCnt: "Liczba znaków",
      categoryName: "Klasyfikacja etykiet",
      eventName: "Klasyfikacja zdarzeń",
      tagStatus: "Skuteczność",
      actions: "Operacja",
      tagType: "Rodzaj etykiety",
    },
    dataPreprocess: {
      name: "Imię i nazwisko",
      desc: "Opis",
      dataSource: "Limity źródeł danych",
      type: "Rodzaj",
      status: "Skuteczność",
      actions: "Operacja",
    },
    informationEntities: {
      name: "Imię i nazwisko",
      apiName: "Nazwa interfejsu API",
      type: "Rodzaj",
      remark: "Opis",
      activeFlag: "Skuteczność",
      actions: "Operacja",
    },
    interactiveRecognition: {
      questionAndAnswer: "Standardowe pytania i odpowiedzi",
      type: "Rodzaj",
      status: "Status",
      actions: "Operacja",
      question: "Pytanie",
    },
    role: { id: "ID", name: "Imię i nazwisko", actions: "Operacja" },
    uploadDownload: {
      taskName: "Nazwa pliku",
      createTime: "Data",
      size: "Rozmiar",
      createUserName: "Operator",
      status: "Status",
      result: "Wyniki",
      actions: "Operacja",
    },
    taskCenterList: {
      name: "Zawartość zadania",
      createTime: "Czas na poddanie",
      startTime: "Czas rozpoczęcia",
      endTime: "Czas zakończenia",
      createUserName: "Operator",
      status: "Status",
      result: "Wyniki",
      actions: "Operacja",
    },
    customerInformation: {
      title: "Informacje o obsłudze klienta",
      data: "Wyświetl tylko dane wyjątku",
      list: {
        agentNo: "Numer pracy/identyfikator obsługi klienta",
        agentName: "Obsługa klienta",
        agentTel: "Telefon",
        agentEmail: "Skrzynka pocztowa",
        updateTime: "Czas aktualizacji",
        actions: "Operacja",
      },
      upload: {
        second:
          "2. Kliknij przycisk Importuj, aby przesłać plik EXCEl zgodny z formatem szablonu",
      },
    },
    IndexManagement: {
      title: "Zarządzanie wskaźnikami",
      list: {
        indicatorName: "Imię i nazwisko",
        indicatorDesc: "Opis",
        activity: "Skuteczność",
        inspectDataSource: "Źródło danych",
        sysType: "Rodzaj",
        agentGroups: "Grupa Obsługi Klienta",
        actions: "Operacja",
      },
      detail: {
        nameEmpty: "Nazwa nie może być pusta",
        descript: "Opis",
        descriptEmpty: "Opis nie może być pusty",
        active: "Skuteczność",
        activeEmpty: "Ważność nie może być pusta",
        dataSource: "Źródło danych",
        dataSourceEmpty: "Źródło danych nie może być puste",
        grounp: "Grupa Obsługi Klienta",
        grounpEmpty: "Grupa agentów nie może być pusta",
        grounpHolder: "Proszę wybrać grupę agentów",
        type: "Metoda punktacji",
        typeNone: "Metoda punktacji nie może być pusta",
        base: "Punkty fundamentowe",
        baseNone: "Wynik podstawowy nie może być pusty",
        positive: "Czynniki pozytywne",
        positiveNone: "Czynnik pozytywny nie może być pusty",
        customAdd: "Dodaj filtr",
        negative: "Czynniki negatywne",
        negativeNone: "Czynniki negatywne nie mogą być puste",
        customOK: "Kryteria filtra:",
        judgeStrategy: "Zasady stanu:",
        other: "Inne czynniki",
        otherAdd: "Dodaj współczynnik",
        agent: "Surowe dane",
        section: "Liczba przedziałów",
        sectionNone: "Liczba przedziałów nie może być pusta",
        step: "Rozmiar kroku interwałowego",
        stepNone: "Rozmiar kroku interwałowego",
        save: "Zapisz",
        cancel: "Anuluj",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "Wszystkie",
      staff: "Tylko ja",
      department: "Departament i poniżej",
    },
    gongStatisticsDate: { week: "Tydzień", month: "Miesiąc", season: "Sezon" },
    gongCoachTaskStatus: {
      wait: "Być mentorem",
      already: "Trenowany",
      ignore: "Ignorowane",
    },
    callRangeStatus: {
      all: "Wszystkie połączenia",
      sale: "Zainicjowany przez pracownika",
      customer: "Klient zainicjowany",
    },
    todoListType: {
      later: "Posłuchaj później",
      help: "Poradnictwo dla pomocy",
      contact: "Skontaktuj się później",
      send: "Wyślij dane",
      warn: "Wczesne ostrzeżenie o ryzyku",
    },
    frequencyTypes: {
      everyDay: "Codziennie",
      everyWeek: "Tygodnik",
      everyMonth: "Co miesiąc",
    },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "Szkolenie w zakresie niesklasyfikowanych danych",
        markedDataCorrigenda: "Oznaczone dane Errata",
        classifiedDataCheck: "Weryfikacja danych niejawnych",
        markedHistoryRecord: "Historia Callout",
        historyDataRecalculate: "Przeliczenie danych historycznych",
      },
      dataExtractMode: {
        random: "Całkowicie przypadkowe",
        intelligentRecommend: "Inteligentna rekomendacja",
        custom: "Niestandardowe",
        specifiedClass: "Określ klasyfikację",
      },
      smartRatesList: {
        markRate: "Wskaźnik znakowania",
        corrigendaRate: "Stawka errata",
        kl: "K-L dywergencja",
        signalNoiseRate: "Stosunek sygnału do szumu",
      },
    },
    visibleRangeTypes: {
      all: "Wszystkie",
      customerGroup: "Grupa Obsługi Klienta",
      userDefined: "Niestandardowe",
    },
    samplingRateTypes: {
      everyDay: "Rysuj codziennie",
      everyWeek: "Cotygodniowe losowanie",
    },
    taskRemindTypes: {
      confirm:
        "Wyślij przypomnienie, gdy zapis zadania kontroli jakości musi zostać potwierdzony.",
      appeal:
        "Gdy zapis zadania kontroli jakości zainicjuje skargę, wyślij przypomnienie.",
    },
    weekDays: {
      monday: "Poniedziałek",
      tuesday: "Wtorek",
      wednesday: "Środa",
      thursday: "Czwartek",
      friday: "Piątek",
      saturday: "Sobota",
      sunday: "Niedziela",
    },
    reportDateTypes: {
      dailyReport: "Raport dzienny",
      weeklyReport: "Raport tygodniowy",
    },
    samplingRangeDayTypes: { today: "Dzisiaj", yesterday: "Wczoraj" },
    samplingRangeWeekTypes: {
      thisWeek: "W tym tygodniu",
      lastWeek: "W zeszłym tygodniu.",
    },
    samplingRuleTypes: {
      proportion: "Próbkowanie proporcjonalne",
      random: "Losowe pobieranie próbek",
    },
    taskStatusTypes: {
      waitEvaluate: "Do oceny",
      waitSubmit: "Do złożenia",
      waitConfirm: "Do potwierdzenia",
      waitReview: "Do przeglądu",
      completed: "Ukończone",
      all: "Wszystkie",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "Bezpośrednia ocena",
        weightedScore: "Ocena wagi",
        deductScore: "Odliczenia",
        directScoreSecondLevel: "Bezpośrednia ocena-poziom 2",
        weightedScoreSecondLevel: "Waga Score-Poziom 2",
        deductScoreSecondLevel: "Odliczanie punktów-poziom 2",
      },
    },
    fieldOperators: {
      is: "Równe",
      not: "Nie równe",
      contains: "Zawiera",
      notContains: "Nie zawiera",
      not_contains: "Nie zawiera",
      containsAny: "Zawiera dowolny",
      contains_any: "Zawiera dowolny",
      is_any: "Zawiera dowolny",
      not_any: "Nie zawiera żadnych",
      notContainsAny: "Nie zawiera żadnych",
      not_contains_any: "Nie zawiera żadnych",
      containsAll: "Zawiera wszystko",
      contains_all: "Zawiera wszystko",
      notContainsAll: "Nie zawiera wszystkich",
      nont_contains_all: "Nie zawiera wszystkich",
      isNull: "Pusty",
      is_null: "Pusty",
      isNotNull: "Nie pusty",
      is_not_null: "Nie pusty",
      greaterThan: "Większy",
      greater_than: "Większy",
      greaterThanEq: "Większy lub równy",
      greater_than_eq: "Większy lub równy",
      lessThan: "Mniej",
      less_than: "Mniej",
      lessThanEq: "Mniej niż lub równe",
      less_than_eq: "Mniej niż lub równe",
      between: "Między",
      notBetween: "Nie",
      not_between: "Nie",
      relative: "Równe",
      set: "Zestaw",
      belongs: "Należał",
      notBelongs: "Nie należą",
      not_belongs: "Nie równe",
      today: "Dzisiaj",
      yesterday: "Wczoraj",
      the_day_before_yesterday: "Przedwczorajszy dzień",
      tomorrow: "Jutro.",
      next_seven_day: "Następne siedem dni",
      last_seven_day: "Ostatnie siedem dni",
      this_week: "W tym tygodniu",
      next_week: "W przyszłym tygodniu",
      last_week: "W zeszłym tygodniu.",
      this_month: "W tym miesiącu",
      next_month: "W przyszłym miesiącu",
      last_month: "W zeszłym miesiącu",
      this_year: "W tym roku",
      next_year: "W przyszłym roku",
      last_year: "W zeszłym roku",
      exact_match: "Dokładne dopasowanie",
      add_value: "Nowa wartość dodana",
      remove_value: "Usuń wartość",
      set_null: "Pusty",
      isAny: "Arbitralnie równa się",
      notAny: "Arbitralny nie równy",
      belongTo: "Należał",
      notBelongTo: "Nie należą",
      hasAny: "Zawiera dowolny",
      notHasAny: "Nie zawiera żadnych",
      prefixContains: "Zaczyna się równo",
      prefixNotContains: "Początek nie jest równy",
      suffixContains: "Koniec równa się",
      suffixNotContains: "Koniec nie jest równy",
      nextSevenDay: "Następne 7 dni",
      lastSevenDay: "Ostatnie 7 dni",
      thisWeek: "W tym tygodniu",
      nextWeek: "W przyszłym tygodniu",
      lastWeek: "W zeszłym tygodniu.",
      thisMonth: "W tym miesiącu",
      nextMonth: "W przyszłym miesiącu",
      lastMonth: "W zeszłym miesiącu",
      thisYear: "W tym roku",
      nextYear: "W przyszłym roku",
      lastYear: "W zeszłym roku",
      after: "Później niż (w tym bieżący dzień)",
      before: "Wcześniej niż (w tym bieżący dzień)",
      allMatching: "Dopasuj wszystkie",
      allNotMatching: "Wszystkie nie pasują",
      hit: "Uderzenie",
      not_hit: "Przegapione",
    },
    fieldsOperators: {
      is: "Równe",
      is_null: "Również pusty",
      head_equal: "Zaczyna się równo",
      tail_equal: "Koniec równa się",
      is_not_null: "Również nie jest pusty",
      not: "Nie równe",
      diff_equal: "Różnica jest równa",
      diff_greater_than: "Różnica jest większa",
      diff_greater_than_eq: "Różnica większa lub równa",
      diff_less_than: "Różnica jest mniejsza",
      diff_less_than_eq: "Różnica mniejsza lub równa",
      hour_equal: "Ta sama godzina.",
      day_equal: "Tego samego dnia",
      week_equal: "W tym samym tygodniu",
      month_equal: "W tym samym miesiącu",
      year_equal: "W tym samym roku",
      day: "Dzień",
      minute: "Minuty",
      hour: "Godziny",
      part_equal: "Częściowo równe",
    },
    samplingColumnCaptions: {
      created_at: "Czas rozmów",
      agent_id: "Obsługa klienta",
      get_direction: "Rodzaj połączenia",
      call_result: "Zadzwoń do wyników",
      survey: "Ocena",
      derived_from_id: "Źródło",
      quit_queue_why: "Status kolejkowania",
      queuing_duration: "Czasochłonne kolejkowanie",
      talking_seconds: "Czas trwania połączenia",
      ringing_seconds: "Czas pierścienia",
      hangup_by: "Zadzwoń na imprezę",
    },
    callTypes: {
      callIn: "Połączenie przychodzące",
      callOut: "Wydech",
      threeParty: "(Trójstronna)",
      consultation: "(Doradztwo)",
      insert: "(Silne wstawienie)",
      monitor: "(Słuchanie)",
      transfer: "(Transfer)",
      intercept: "(Intercept)",
      transferOutside: "(Transfer linii zewnętrznej)",
      threePartyOutside: "(Trójstronna linia zewnętrzna)",
      consultingOutside: "(Skonsultuj się na zewnątrz)",
    },
    callResults: {
      staffAnswer: "Odpowiedź obsługi klienta",
      staffNotAnswer: "Brakowało obsługi klienta",
      customerAnswer: "Odpowiedź klienta",
      customerNotAnswer: "Klient przegapił",
      phoneBusy: "Telefon zajęty",
      phoneOffline: "Telefon offline",
      customerSpeedHangUp: "Klient Quick Hanging",
      customerHangUp: "Klient rozłącza się",
      queueTimeout: "Limit czasu kolejki",
      queueGiveUp: "Porzuć kolejkę",
      outlineAnswer: "Odpowiedź zewnętrzna",
      outlineNotAnswer: "Linia zewnętrzna nie jest podłączona",
      noChooseQueue: "Nie wybrano kolejki",
    },
    defaultSurveys: {
      noEvaluation: "Nie oceniono",
      noNeedToEvaluate: "Nie trzeba oceniać",
    },
    queueStates: {
      queueSuccess: "Sukces w kolejce",
      queueTimeout: "Limit czasu kolejki",
      queueGiveUp: "Porzuć kolejkę",
      noStaffOnline: "Brak obsługi klienta online",
    },
    callHangers: {
      customer: "Klient",
      staff: "Obsługa klienta",
      outline: "Na zewnątrz",
    },
    workFlowTypes: {
      noAppealsMode: "Tryb bez skarg",
      appealsMode: "Zezwalaj na tryb odwołania",
    },
    fieldCategories: {
      standardCondition: "Standardowe kryteria filtru",
      customerCondition: "Kryteria filtrowania zdefiniowane przez użytkownika",
    },
    sampleTypes: {
      automatic: "Automatyczne pobieranie próbek",
      manual: "Ręczne pobieranie próbek",
    },
    defaultFields: {
      createdAt: "Czas rozmów",
      agentId: "Obsługa klienta",
      getDirection: "Rodzaj połączenia",
      callResult: "Zadzwoń do wyników",
      survey: "Ocena",
      derivedFrom: "Źródło",
      quitQueueWhy: "Status kolejkowania",
      queuingDuration: "Czasochłonne kolejkowanie",
      talkingSeconds: "Czas trwania połączenia",
      ringingSeconds: "Czas pierścienia",
      hangupBy: "Zadzwoń na imprezę",
    },
    conditionFieldCategories: {
      fixedField: "Stałe pole",
      customField: "Pola niestandardowe",
      secondLevelScore: "Klasyfikacja wtórna",
      formItemScore: "Pozycja w kategorii Score",
      forbids: "Przedmioty zabronione",
      deadlies: "Fatalny przedmiot",
    },
    columnFieldCategories: {
      agent: "Obsługa klienta",
      callLog: "Nagrywanie rozmów",
      businessFields: "Informacje biznesowe",
      customer: "Klient",
      customerCustomFields: "Dostosowywanie klienta",
      source: "Źródło",
      queue: "Kolejka",
      fixed: "Naprawiono",
      secondLevelScore: "Klasyfikacja wtórna",
      formItemScore: "Pozycja w kategorii Score",
      forbids: "Przedmioty zabronione",
      deadlies: "Fatalny przedmiot",
    },
    taskListDefaultFields: {
      startTime: "Czas rozmów",
      name: "Obsługa klienta",
      state: "Status",
      totalScore: "Całkowity wynik",
      formItemForbidList: "Przedmioty zabronione",
      formItemDeadlyList: "Fatalny przedmiot",
      comment: "Uwagi dotyczące oceny",
      inspector: "Oceny",
      review: "Recenzent",
    },
    evaluationStates: {
      initial: "Inicjalizacja",
      waitEvaluate: "Do oceny",
      evaluated: "Do złożenia",
      waitConfirm: "Do potwierdzenia",
      waitCheck: "Do przeglądu",
      complete: "Ukończone",
    },
    workBenchInfoTypes: {
      callInfo: "Informacje o połączeniu",
      businessInfo: "Informacje biznesowe",
      customerInfo: "Informacje o kliencie",
    },
    evaluationEventStates: {
      create: "Stwórz",
      valuate: "Ocena",
      edit: "Edytuj",
      submit: "Przedłożenie",
      confirm: "Potwierdź",
      appeal: "Skarga",
      checkConfirm: "Potwierdzenie przeglądu",
      check: "Przegląd",
    },
    formItemTypes: {
      direct: "Bezpośrednia ocena",
      weight: "Waga",
      deduct: "Odliczenia",
      forbid: "Przedmioty zabronione",
      deadly: "Fatalny przedmiot",
    },
    appealCheckCallTypes: {
      callIn: "Połączenie przychodzące",
      callBack: "Dwukierunkowe oddzwonienie",
      callOut: "Bezpośrednie połączenie Outcall",
      autoCall: "Automatyczne połączenie wychodzące",
    },
    appealCheckCallStatus: {
      waitReview: "Do przeglądu",
      reviewed: "Sprawdził",
      filed: "Zarchiwizowane",
    },
    samplingStatus: {
      all: "Wszystkie",
      unchecked: "Ekstrakcja nie została sprawdzona",
      checked: "Pobieranie próbek",
    },
    inspectionStatus: {
      all: "Wszystkie",
      unread: "Nieprzeczytane",
      readed: "Czytaj",
      appeal: "Skarga",
      review: "Przegląd",
      filed: "Zarchiwizowane",
    },
    gradeChooseHits: { yes: "Tak", no: "Nie" },
    appealCheckStatus: {
      all: "Wszystkie Status",
      unread: "Nieprzeczytane",
      inComplaint: "W skardze",
      reviewed: "Sprawdził",
      readed: "Czytaj",
      filed: "Zarchiwizowane",
      spotchecked: "Pobieranie próbek",
    },
    flowStatus: {
      unread: "Nieprzeczytane",
      alreadyRead: "Czytaj",
      complaining: "W skardze",
      reviewed: "Sprawdził",
      sampling: "Ekstrakcja nie została sprawdzona",
      samplingCompleted: "Pobieranie próbek",
      complainCancel: "Odwołany",
    },
    complainStatus: {
      success: "Sukces",
      fail: "Nie powiodło się",
      default: "W skardze",
      canceled: "Odwołany",
    },
    reviewInspectionStatus: { inComplaint: "W skardze", reviewed: "Sprawdził" },
    gradeChangeTypes: {
      edit: "Edytuj",
      check: "Przegląd",
      file: "Archiwizacja",
      remark: "Uwagi",
      complain: "Skarga",
      review: "Przegląd",
      reviewEvaluation: "Ocena przeglądu",
      sample: "Inspekcja losowa",
      sampleEvaluation: "Kontrola i ocena pobierania próbek",
      sampleSubmit: "Kontrola pobierania próbek i składanie",
      sampleAssign: "Przydział pobierania próbek",
      reviewAssign: "Przegląd alokacji",
      read: "Czytaj",
      reviewConfirm: "Potwierdzenie przeglądu",
      sampleConfirm: "Potwierdzenie kontroli pobierania próbek",
      caseBase: "Dodaj do grupy przypadków",
      sampleCancel: "Pobieranie próbek Anuluj przypisanie",
      reviewCancel: "Anulowanie przeglądu",
      sampleAgain: "Ponowna alokacja próbek",
      reviewAgain: "Przegląd Ponowne przypisanie",
      btachDelete: "Usuń rekordy inspekcji losowych",
      rapidSampling: "Szybkie pobieranie próbek",
      reInspection: "Ponowna inspekcja",
      smartTagsChange: "Inteligentne zmiany etykiet",
      cancelAppeal: "Wycofanie skargi",
    },
    appealTypes: {
      transliterationError: "Błąd transkrypcja",
      discriminationError: "Błąd oceny",
      otherError: "Inne błędy",
    },
    inspectDataSources: {
      voiceCall: "Połączenie głosowe",
      textDialogue: "Dialog tekstowy",
      realTimeVoiceCall: "Połączenia głosowe w czasie rzeczywistym",
      realTimeTextDialogue: "Rozmowy tekstowe w czasie rzeczywistym",
      wechatDialogue: "Przedsiębiorstwo mikro-sesja",
      taobao: "Dialog tekstowy e-commerce",
      ticket: "Zamówienie na pracę",
      wechatRadio: "Przedsiębiorstwo Micro Voice",
    },
    inspectTaskType: {
      all: "Wszystkie",
      common: "Ogólna kontrola jakości",
      relate: "Powiązana inspekcja jakości",
    },
    inspectApproveType: {
      all: "Wszystkie",
      resolve: "Zatwierdzone",
      resolving: "W przeglądzie",
      reject: "Audyt nie przeszedł",
    },
    dataSourceFlags: {
      all: "Brak ograniczeń",
      voiceCall: "Zadzwoń",
      textDialogue: "Dialog",
    },
    smartWordsLibrary: { effective: "Skuteczne", invalid: "Nieważne" },
    activeFlags: { effiective: "Skuteczne", invalid: "Nieważne" },
    labelTypes: {
      dimensionLabel: "Etykiety objawiania",
      classificationLabel: "Etykieta kategorii",
    },
    pointTypes: {
      automatic: "Automatyczna ocena",
      manual: "Ocena ręczna",
      interactiveDetection: "Wykrywanie interakcji",
      smartRules: "Inteligentne zasady",
      machineLearning: "Tryb samokształcenia",
      intelligentModel: "Inteligentny model",
    },
    pointGradeTypes: {
      radio: "Pojedynczy wybór",
      input: "Wejście",
      select: "Wybierz",
      all: "Poznaj wszystkich",
      any: "Spotkać się z arbitralnym",
      customize: "Niestandardowe",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "Dopasowanie słów kluczowych",
      question: "Wyrok pytający",
      regular: "Wyrażenia regularne",
      context: "Powielanie kontekstu",
      semantics: "Semantyczne dopasowanie",
      word: "Podobne słowa",
      dialogue: "Interwał dialogowy",
      speed: "Wykrywanie prędkości mowy",
      grabDetection: "Wykrywanie wyrwania",
      semanticTags: "Etykieta semantyczna",
    },
    applyRoles: {
      all: "Wszystkie",
      customer: "Klient",
      agent: "Obsługa klienta",
      robot: "Robot",
    },
    saleApplyRoles: {
      all: "Wszystkie",
      customer: "Klient",
      agent: "Pracownik",
    },
    applyOperatorScopes: {
      all: "Pełny tekst",
      preCondition: "Warunek wstępny",
      scope: "Określony zakres",
    },
    keywordScopes: {
      all: "Wykrywanie wszystkich słów kluczowych.",
      any: "Wykryj dowolne słowa kluczowe",
    },
    preOperatorHitTypes: {
      first: "Pierwszy mecz",
      every: "Każdy mecz",
      last: "Ostatni mecz",
      any: "Dowolny mecz",
      none: "Nie dopasowane",
    },
    operatorRuleTypes: {
      all: "Poznaj wszystkich",
      any: "Spotkać się z arbitralnym",
      custom: "Niestandardowa logika",
    },
    informationEntityTypes: {
      system: "System",
      custom: "Niestandardowe",
      moduleCompany: "Moduł",
    },
    organizationTypes: { null: "Brak", moduleCompany: "Moduł" },
    customType: {
      smartTag: "Wyciąg podmiotów",
      scriptTimes: "Liczenie mowy",
      dataCategory: "Klasyfikacja danych",
    },
    interactiveQuestionTypes: {
      standard: "Standardowe pytania i odpowiedzi",
      multiElement: "Wieloelementowe pytanie i odpowiedź",
      guideMultiRound: "Interakcja z wieloma kołami z przewodnikiem",
    },
    targetTypes: {
      all: "Wszystkie",
      customerService: "Obsługa klienta",
      customer: "Klient",
    },
    interactiveTypes: {
      similarQList: "Podobny problem",
      exceptSimilarQList: "Wyeliminuj podobne problemy",
      similarAList: "Podobne odpowiedzi",
      wrongAList: "Zła odpowiedź",
    },
    filterTypes: {
      call: "Nagrywanie rozmów",
      dialogue: "Tekst dialogu",
      wechat: "WeChat dla przedsiębiorstw",
      wechatAll: "Enterprise WeChat All Dialogue",
      ticket: "Sesja zlecenia pracy",
      taobao: "Sesja e-commerce",
      wechatRadio: "Przedsiębiorstwo Micro Voice",
    },
    fieldTypes: {
      keyword: "Słowa kluczowe",
      agent: "Obsługa klienta",
      agentGroup: "Grupa Obsługi Klienta",
      sigleListbox: "Pojedynczy wybór",
      multiListbox: "Wielokrotnego wyboru",
      text: "Pojedyncza linia tekstu",
      textArea: "Wiele linii tekstu",
      dateTime: "Czas",
      number: "Liczby",
      voice: "",
      customer: "Klient",
      fieldWithLink: "Pole z łączem",
      percentage: "Procent",
      intelligentClassification: "Inteligentna klasyfikacja",
      testSetList: "Zestaw testowy pól dynamicznych",
      inspector: "Inspektor jakości",
    },
    saveTypes: { save: "Zapisz" },
    knowledgeType: { oneToOne: "Jedno pytanie i jedna odpowiedź" },
    updateTypes: { update: "Aktualizacja", saveAs: "Zapisz" },
    recommendItemActionTypes: {
      similar: "Podobne",
      exclude: "Wyklucz",
      ignore: "Ignoruj",
    },
    gradeStatus: {
      unread: "Nieprzeczytane",
      alreadyReview: "Sprawdził",
      alreadyFiled: "Zarchiwizowane",
      waitReview: "Do przeglądu",
      alreadyRead: "Czytaj",
    },
    qualityCheckDetailTypes: {
      review: "Przegląd",
      spotCheck: "Inspekcja losowa",
    },
    sampleAndReviewStatus: {
      init: "Nie wyodrębniono",
      unread: "Nieprzeczytane",
      alreadyRead: "Czytaj",
      complaining: "W skardze",
      reviewed: "Sprawdził",
      sampling: "Ekstrakcja nie została sprawdzona",
      samplingCompleted: "Pobieranie próbek",
    },
    interactiveAnswerTypes: {
      noAnswer: "Nie wykryto odpowiedzi",
      answer: "Wykryto odpowiedź",
      wrongAnswer: "Wykryto nieprawidłową odpowiedź",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "Nie wykryto żadnych problemów",
      question: "Wykryto problem",
      eliminateQuestion: "Wykryto problem rozwiązywania problemów",
    },
    isManual: {
      yes: "Ręczna kontrola jakości",
      no: "Brak ręcznej kontroli jakości",
    },
    readStatus: { yes: "Czytaj", no: "Nieprzeczytane" },
    reInspectionType: {
      recalcScore: "Oblicz tylko wyniki (z ocenami)",
      recheckPoint: "Przeliczenie punktów kontroli jakości i wyników",
    },
    common: {
      eCommerceReview: "Przegląd handlu elektronicznego",
      socialMediaComments: "Komentarze do mediów społecznościowych",
      customer: "Klient",
      customerService: "Obsługa klienta",
      robot: "Robot",
      customerInformation: "Informacje o kliencie",
      workOrderFields: "Pola porządku pracy",
      intelligentConversationAnalysis: "Inteligentna analiza sesji",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "Wgląd w krótki zarząd biznesu, słuchaj głosu klientów, usprawniaj transformację marketingową",
      intelligentAccompanyingPractice: "Inteligentny sparing",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "Stwórz wciągający sparing AI, który symuluje prawdziwe sceny biznesowe, aby osiągnąć pełne opanowanie umiejętności mowy o złotym medalu i szybką poprawę umiejętności dialogowych.",
      intelligentQualityInspection: "Inteligentna kontrola jakości",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "Pełna kontrola jakości, terminowa i wydajna, z dokładnością ponad 85%",
      salesEmpowerment: "Upodmiotowienie sprzedaży",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "Sztuczna inteligencja przekształca zarządzanie sprzedażą, realizuje digitalizację i wizualizację procesu komunikacji, przejrzyście zarządza każdym ogniwem sprzedaży, inteligentnie postrzega każdy etap komunikacji, delikatnie diagnozuje problemy biznesowe i doskonale zmienia doświadczenie sprzedażowe.",
      algorithmPlatform: "Platforma algorytmu",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "Opierając się na semantyce mowy samodzielnie opracowanego oryginalnego silnika serca, modele ASR i NLP są dostosowywane do uzyskiwania danych scenariuszy biznesowych i realizacji samokształcenia systemu, tak aby osiągnąć cel bardziej inteligentnego i dokładniejszego wykorzystania.",
      generalSettings: "Ustawienia ogólne",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "Ustawianie uprawnień danych i ról pracowników oraz zarządzanie nimi w ujednolicony sposób",
      callLog: "Nagrywanie rozmów",
      intelligentRules: "Inteligentne zasady",
      businessRecords: "Dokumentacja biznesowa",
      customFilter: {
        call: "Połączenie głosowe",
        text: "Dialog tekstowy",
        smartTags: "Inteligentna etykieta",
        pretreatment: "Obróbka wstępna",
        smartTagGroup: "Inteligentna grupa etykiet",
        currency: "Dane ogólne",
        wechat: "WeChat dla przedsiębiorstw",
        spotCheck: "Inspekcja losowa",
        personaField: "Lista klientów",
        ticket: "Sesja zlecenia pracy",
        taobao: "Sesja e-commerce",
        wechatRadio: "Przedsiębiorstwo Micro Voice",
        reviewList: "Przegląd",
      },
      talkerRecognitionType: {
        API: "API identyfikuje rolę ścieżki",
        text: "Rozpoznawanie tekstu",
      },
      operatorTypes: {
        keyword: "Dopasowanie słów kluczowych",
        question: "Wyrok pytający",
        regular: "Wyrażenia regularne",
        context: "Powielanie kontekstu",
        semantics: "Semantyczne dopasowanie",
        word: "Podobne słowa",
        dialogue: "Interwał dialogowy",
        speed: "Wykrywanie prędkości mowy",
        grabDetection: "Wykrywanie wyrwania",
        semanticTags: "Etykieta semantyczna",
        keywordStrict: "Dopasowywanie słów kluczowych (ścisłe)",
        link: "Pole łącza",
        entityInfoDetection: "Wykrywanie jednostki informacyjnej",
        basicInfoDetection: "Podstawowe wykrywanie informacji",
        volume: "Wykrywanie głośności",
        emotion: "Analiza nastrojów",
        grammar: "Zasady gramatyczne",
        sentenceLength: "Wykrywanie długości zdania",
        conceptWord: "Słowa koncepcyjne",
        questionsAndAnswers: "Rozwiązanie bazy wiedzy (stare)",
        element: "Podstawowe pytania i odpowiedzi",
        silent: "Limit czasu na odpowiedź",
        associate: "Dane dotyczące ruchu drogowego",
        repeat: "Powtórz Wyślij",
        timeInterval: "Czas trwania interwału",
        typoDetection: "Wykrywanie literówek",
        silentOld: "Cisza",
        knowledge: "Rozwiązanie wiedzy",
        wecomRemark: "Mikro uwagi dotyczące przedsiębiorstwa klienta",
        customerAddTime: "Czas dodawania klienta",
      },
      keywordOperators: {
        all: "Dopasuj wszystkie",
        allNot: "Wszystkie nie pasują",
        any: "Dowolny mecz",
      },
      applyRoles: {
        all: "Wszystkie",
        customer: "Klient",
        agent: "Obsługa klienta",
      },
      sentenceLength: {
        less: "Mniej",
        greater: "Większy",
        equal: "Równe",
        greater_or_equal: "Większy lub równy",
        less_or_equal: "Mniej niż lub równe",
      },
      dialogueApplyScopes: {
        differentRoles: "Między różnymi rolami",
        agent: "Obsługa klienta",
        customer: "Klient",
        agentResponse: "Odpowiedź klienta",
        customerResponse: "Odpowiedź klienta",
        customerNoResponse: "Klient nie odpowiada",
      },
      applyConditionHitTypes: {
        first: "Pierwsze trafienie.",
        every: "Każde trafienie.",
        last: "Ostatnie trafienie",
      },
      applyConditionScopes: {
        current: "Prąd",
        before: "Przed",
        after: "Po",
        beforeAll: "Wszystko przed",
        afterAll: "W końcu",
        around: "W pobliżu",
      },
      voiceDemoTypes: {
        singleChannel: "Nagrywanie rozmów jednokanałowych",
        dualChannel: "Dwukanałowe nagrywanie rozmów",
      },
      sendStatus: {
        sending: "Wysyłanie",
        arrive: "Dostarczone",
        fail: "Nie udało się wysłać",
        off_sending: "Offline Wyślij nieprzeczytane",
        off_arrive: "Czytaj",
        rollback: "Wycofany",
      },
      collectionMethod: {
        assignment: "Bezpośrednie zadanie",
        entity: "Ekstrakcja podmiotów",
      },
      systemTypes: {
        qa: "Inteligentna kontrola jakości",
        wfm: "Inteligentne planowanie",
      },
      entityOperators: {
        equals: "Równe informacje",
        notEquals: "Nie równa się informacjom",
        contains: "Zawiera informacje",
        notContains: "Nie zawiera informacji",
        equalsFix: "Równa wartości stałej",
        notEqualsFix: "Nie równa się wartości stałej",
        containsFix: "Zawiera stałe wartości",
        notContainsFix: "Nie zawiera stałej wartości",
      },
      basicInfoOperators: {
        equals: "Równe",
        notEquals: "Nie równe",
        contains: "Zawiera",
        notContains: "Nie zawiera",
        isNull: "Pusty",
        isNotNull: "Nie pusty",
      },
      volumeDetectionWays: {
        loudness: "Decybel głośności",
        rangeAbilitySelf: "Zakres zmian (niż w poprzednim zdaniu)",
        rangeAbilityOth: "Zakres zmian (w porównaniu do powyższego zdania)",
        rangeAbility3: "Zakres zmian (niż jestem w tym zdaniu)",
      },
      volumeDetectionOperators: {
        is: "Równe",
        not: "Nie równe",
        greaterThan: "Większy",
        greaterThanEq: "Większy lub równy",
        lessThan: "Mniej",
        lessThanEq: "Mniej niż lub równe",
      },
      sentimentTypes: {
        positive: "Pozytywne emocje",
        negative: "Negatywne emocje",
        neutral: "Neutralne emocje",
        thankful: "Dzięki",
        happy: "Szczęśliwy",
        complaining: "Złóż skargę",
        angry: "Wściekły",
        post: "Przód",
        negat: "Ujemny",
        neut: "Neutralny",
      },
      emotionOperators: { is: "Zgodność", not: "Niezgodne" },
      propTypes: { system: "System", user: "Niestandardowe" },
      valueTypes: { text: "Tekst", number: "Liczby" },
    },
    tasks: {
      allowCustomGrievance: "Zezwalaj na niestandardową skargę",
      businessSummary: "Podsumowanie biznesowe",
      theDayBeforeYesterday: "Przedwczorajszy dzień",
      assessActionTypes: { reassess: "Ponowna ocena" },
      scoreType: {
        aiScore: "Inteligentna ocena",
        manualScore: "Ocena ręczna",
        interactiveDetection: "Wykrywanie interakcji",
        smartRules: "Inteligentne zasady",
        machineLearning: "Tryb samokształcenia",
      },
      inspectType: { inspectTrue: "Sprawdź", inspectFalse: "Nie sprawdzono" },
      operatorType: {
        keyword: "Dopasowanie słów kluczowych",
        notKeyword: "Niedopasowanie słów kluczowych",
      },
      applyRole: {
        all: "Wszystkie",
        customer: "Klient",
        agent: "Obsługa klienta",
      },
      applyScope: {
        all: "Wszystkie",
        specific: "Określone zdanie",
        scope: "Określony zakres",
      },
      judgeStrategy: {
        all: "Spełniają wszystkie warunki",
        arbitrarily: "Spełniają dowolny warunek",
        custom: "Niestandardowa logika",
      },
      checkPointStatus: {
        waitFor: "Oczekiwanie na przegląd",
        notDeductScore: "Brak odliczenia",
        deductScore: "Odliczenia",
      },
      applyOptions: {
        hit: "Stan trafił",
        notHit: "Nie określono żadnych warunków",
      },
      gradeTypes: {
        yesOrNo: "Zgodność",
        input: "Ręczne wejście",
        level: "Ocena stopnia",
        multipleFactors: "Wiele elementów",
      },
      gradeChooseHits: { yes: "Tak", no: "Nie" },
      gradeChangeTypes: {
        edit: "Edytuj",
        check: "Przegląd",
        file: "Archiwizacja",
        remark: "Uwagi",
        complain: "Skarga",
        review: "Przegląd",
        reviewEvaluation: "Ocena przeglądu",
        sample: "Inspekcja losowa",
        sampleEvaluation: "Kontrola i ocena pobierania próbek",
        sampleSubmit: "Kontrola pobierania próbek i składanie",
        sampleAssign: "Przydział pobierania próbek",
        reviewAssign: "Przegląd alokacji",
        read: "Czytaj",
        reviewConfirm: "Potwierdzenie przeglądu",
        sampleConfirm: "Potwierdzenie kontroli pobierania próbek",
      },
      gradeStatus: {
        unread: "Nieprzeczytane",
        alreadyReview: "Sprawdził",
        alreadyFild: "Zarchiwizowane",
        waitReview: "Do przeglądu",
        alreadyRead: "Czytaj",
      },
      samplingFrequencyTypes: {
        everyDay: "Codziennie",
        everyWeek: "Tygodnik",
        everyMonth: "Co miesiąc",
      },
      samplingEveryDayTimeTypes: { yesterday: "Wczoraj", today: "Dzisiaj" },
      samplingEveryWeekTimeTypes: {
        thisWeek: "W tym tygodniu",
        lastWeek: "W zeszłym tygodniu.",
      },
      samplingEveryMonthTimeTypes: {
        thisMonth: "W tym miesiącu",
        lastMonth: "W zeszłym miesiącu",
      },
      samplingTypes: {
        random: "Losowe pobieranie próbek",
        proportional: "Próbkowanie proporcjonalne",
      },
      weekdays: {
        Monday: "Poniedziałek",
        Tuesday: "Wtorek",
        Wednesday: "Środa",
        Thursday: "Czwartek",
        Friday: "Piątek",
        Saturday: "Sobota",
        Sunday: "Niedziela",
      },
      distributeTypes: {
        rule: "Zasada alokacji priorytetowej",
        average: "Średni rozkład losowy",
      },
      notMatchAnyRuleTypes: {
        random: "Losowy średni rozkład",
        assign: "Przypisywanie osobom wyznaczonym",
        ignore: "Nie przypisany",
      },
      inspectorTypes: {
        all: "Wszyscy inspektorzy jakości",
        some: "Wyznaczony inspektor jakości",
      },
      pushFrequencyTypes: {
        everyDay: "Codziennie",
        everyWeek: "Tygodnik",
        everyMonth: "Co miesiąc",
        inspectionUpdate: "Aktualizacja kontroli jakości",
        timelyInspection: "Kontrola jakości w czasie rzeczywistym",
      },
      pushObjectTypes: {
        email: "Określ skrzynkę pocztową",
        staff: "Obsługa klienta",
      },
      reviewOptions: {
        reviewResult: "Wyświetl wyniki kontroli jakości podczas przeglądu",
        reviewRecord: "Wyświetl odpowiednie zapisy podczas przeglądu",
        reviewAgentHidden: "Ukryj informacje o agencie podczas przeglądu",
        samplingResult:
          "Wyświetl wyniki kontroli jakości podczas kontroli losowej",
        samplingRecord: "Wyświetl odpowiednie zapisy podczas inspekcji losowej",
        sampleAgentHidden:
          "Ukryj informacje o obsłudze klienta podczas kontroli losowej",
        checkExportAddCallInfoIphone:
          "Dołącz dzienniki połączeń podczas eksportowania listy",
        sampleExportAddCallInfoIphone:
          "Dołącz dzienniki połączeń podczas eksportowania listy",
        checkExportAddCallInfoText:
          "Dołącz rekordy konwersacji podczas eksportowania listy",
        sampleExportAddCallInfoText:
          "Dołącz rekordy konwersacji podczas eksportowania listy",
        inspectionExportAddCallInfoIphone:
          "Dołącz rekordy połączeń podczas eksportowania listy (jednorazowo można wyeksportować do 10000)",
        inspectionExportAddCallInfoText:
          "Dołącz rekordy konwersacji podczas eksportowania listy (jednorazowo można wyeksportować do 10000)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail:
          "Pozwól na szybką kontrolę punktową w szczegółach jakości",
        fastSampleInSampleDetail:
          "Umożliwiają szybkie wykonanie kontroli losowej w szczegółach inspekcji losowej",
        fastSampleInReviewDetail:
          "Umożliwia szybkie wykonanie kontroli na miejscu w szczegółach przeglądu",
      },
      permitCustomReasonList: {
        permitCustomReason: "Zezwalaj na niestandardowe powody roszczeń",
      },
      autoMateTasks: {
        autoLoad: "Dodaj automatycznie",
        sample: "Automatyczna kontrola pobierania próbek",
        assign: "Automatyczny przydział",
        pushes: "Automatyczne push",
        assess: "Automatyczna ocena",
        check: "Przegląd skarg",
        case: "Synchronizacja biblioteki obudowy",
      },
      qualityCheckDetailTypes: {
        review: "Przegląd",
        spotCheck: "Inspekcja losowa",
      },
      appealTypes: {
        transliterationError: "Błąd transkrypcja",
        discriminationError: "Błąd oceny",
        otherError: "Inne błędy",
      },
      inspectDataSources: {
        voiceCall: "Połączenie głosowe",
        textDialogue: "Dialog tekstowy",
        realTimeVoiceCall: "Połączenia głosowe w czasie rzeczywistym",
        realTimeTextDialogue: "Rozmowy tekstowe w czasie rzeczywistym",
      },
      pointTypes: {
        automatic: "Automatyczna ocena",
        manual: "Ocena ręczna",
        interactiveDetection: "Wykrywanie interakcji",
      },
      pointGradeTypes: {
        radio: "Pojedynczy wybór",
        input: "Wejście",
        select: "Wybierz",
      },
      sampleAndReviewStatus: {
        init: "Nie wyodrębniono",
        unread: "Nieprzeczytane",
        alreadyRead: "Czytaj",
        complaining: "W skardze",
        reviewed: "Sprawdził",
        sampling: "Ekstrakcja nie została sprawdzona",
        samplingCompleted: "Pobieranie próbek",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "Zignoruj nadmiar przepełnienia",
        automaticWeightCalculation: "Automatyczne rozliczanie wagi",
      },
      calculateScoreTypes: { add: "Punkty bonusowe", sub: "Minus punkty" },
      naChecked: {
        check: "Ten przedmiot nie jest zaangażowany w dozwolone wykrywanie",
      },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "Punkty procentowe",
        allRight: "Wszystkie prawidłowe punkty",
        anyRight: "Dowolny prawidłowy wynik",
      },
      noHitQuestionResults: {
        true: "Rezultatem jest „ Tak”",
        false: "Wynik to „ Nie”",
      },
      repetRules: {
        same: "Treść dokładnie taka sama",
        similar: "Podobieństwo semantyczne",
      },
      relateConditions: {
        highlightQuestion: "Podkreśl problem",
        highlightAnswer: "Podświetl odpowiedź",
        hightlightFoctors: "Podkreśl element",
      },
      interactiveQuestionTypes: {
        question: "Wykryto problem",
        eliminateQuestion: "Wykryto problem rozwiązywania problemów",
      },
      interactiveAnswerTypes: {
        noAnswer: "Nie wykryto odpowiedzi",
        answer: "Wykryto odpowiedź",
        wrongAnswer: "Wykryto nieprawidłową odpowiedź",
      },
      inspectionUpdateTypes: {
        autoInspection: "Automatyczna kontrola jakości",
        autoInspectionWechat: "Automatyczna analiza",
        artificialSampling: "Ręczne pobieranie próbek",
        artificialReview: "Przegląd ręczny",
        manualModification: "Szybkie pobieranie próbek",
        timelyInspection: "Kontrola jakości w czasie rzeczywistym",
        samplingAssign: "Przydział pobierania próbek",
        artificialComplain: "Złożenie skargi",
        fastSampling: "Szybka kontrola i poddanie próbek",
        recheckInspection: "Ponowna inspekcja",
        recheckInspectionWechat: "Ponowna analiza",
      },
      timelyInspectionTypes: {
        timelyInspection: "Kontrola jakości w czasie rzeczywistym",
      },
      pushActionTypes: { email: "Poczta", interfacePush: "Interfejs Push" },
      pushDetailWays: {
        riskAlert: "Alert o ryzyku",
        subscribe: "Subskrybuj wiadomości",
      },
      riskAlertPushDataTypes: {
        dialogueMessage: "Wiadomość o połączeniu/rozmowie",
        inspectionResults: "Wyniki kontroli jakości",
      },
      subscribePushDataTypes: {
        dialogueMessage: "Wiadomość o połączeniu/rozmowie",
        textContent: "Treść tekstowa połączeń/rozmów",
        inspectionResults: "Wyniki kontroli jakości",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "Średni wynik w grupie",
        addOrSubtract: "Bezpośrednie punkty plus lub minus",
        noChange: "Brak punktów plus lub minus",
      },
      defaultScore: {
        empty: "Pusty",
        add: "Punkty bonusowe",
        notAdd: "Brak dodatkowych punktów",
        sub: "Minus punkty",
        notSub: "Żadne punkty nie zostały zmniejszone",
        na: "N/A",
        input: "Ręczne wejście",
        choose: "Wybierz",
      },
      strategyTypes: {
        hitAdd: "Bonus trafienia",
        hitNotAdd: "Brak punktów za trafienie",
        hitSub: "Hit minus punkty",
        hitNotSub: "Trafienie nie zmniejsza punktów",
      },
    },
    download: {
      exportStatus: {
        prepare: "W przygotowaniu",
        process: "W toku",
        faild: "Eksport nie powiódł się",
        success: "Sukces",
      },
    },
    messageType: {
      all: "Wszystkie",
      monitor: "Natychmiastowe monitorowanie",
      trigger: "Powiadomienie o wyzwalania",
      timing: "Raport czasowy",
    },
    messagePushType: {
      all: "Wszystkie",
      system: "Wiadomości systemowe",
      sdk: "Wiadomość SDK",
      push: "Interfejs Push",
      email: "Poczta",
      udesk: "Powiadomienia udesk",
      udeskWhite: "Powiadomienie systemu obsługi klienta",
      weChat: "Mikro-wiadomość przedsiębiorstwa",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "Przydziel inspektora pobierania próbek",
      sampleInspector: "Inspektor pobierania próbek",
      assignReviewer: "Przypisywanie recenzenta",
      reviewer: "Recenzent",
      taskInspector: "Inspektor Zadaniowy",
    },
    sdkPushType: {
      webHook: "Standardowy haczyk",
      dingDing: "Robot gwoździe",
      weChat: "Enterprise WeChat Robot",
      customer: "Niestandardowa zawartość Json",
    },
    inspectionRemarksType: {
      inspection: "Inspekcja jakości",
      check: "Inspekcja losowa",
      review: "Przegląd",
    },
    segmentationStrategyType: {
      count: "Liczba wiadomości",
      spacing: "Interwał wiadomości",
    },
    segmentationStrategy: {
      byTime: "Segmentacja czasowa",
      customize: "Krojenie kombinowane",
    },
    inspectType: {
      commonInspect: "Ogólna kontrola jakości",
      associateInspect: "Powiązana inspekcja jakości",
    },
    typoDetectionOperators: { less: "Mniej", greater: "Większy" },
    autoAddTypes: {
      realTimeAdd: "Dodaj w czasie rzeczywistym",
      timingAdd: "Dodaj czas",
    },
    messageTypes: {
      text: "Wiadomość tekstowa",
      markdown: "Wiadomość Markdown",
    },
    inServiceStatus: {
      activated: "Aktywowany",
      disabled: "Niepełnosprawni",
      inactivated: "Nie aktywowany",
      resigned: "Wyjście z przedsiębiorstwa",
    },
    roles: { agent: "Pracownik", customer: "Klient" },
    commentVisibleAuthority: {
      all: "Widoczny dla wszystkich",
      self: "Widoczne tylko dla siebie",
      designation: "Widoczna wyznaczona osoba",
    },
    appealCheckingTask: {
      eCommerceReview: "Przegląd handlu elektronicznego",
      socialMediaComments: "Komentarze do mediów społecznościowych",
      largeModel: "Duży model",
      customModel: "Niestandardowy model",
      universalModel: "Model ogólny",
      enterpriseWeChatText: "Przedsiębiorstwo mikro-tekst",
      generalQualityInspection: "Ogólna inspekcja jakości",
      processQualityInspection: "Inspekcja jakości procesu",
      voiceCopy: "Podwójny dysk głosowy",
      beEqualTo: "Równe",
      notEqualTo: "Nie równe",
      canAppeal: "Może się odwołać",
      noAppeal: "Brak skargi",
    },
    wechat: {
      redEnvelopes: "Czerwona koperta",
      text: "Tekst",
      picture: "Zdjęcie",
      voice: "Głos",
      voiceCall: "Połączenie głosowe",
      video: "Wideo",
      businessCard: "Wizytówka",
      position: "Lokalizacja",
      expression: "Wyrażenie",
      link: "Link",
      applet: "Mały program",
      chatRecord: "Zapis czatu",
      mixedMessage: "Wiadomość mieszana",
      file: "Plik",
    },
    gong: {
      sessionActivity: "Aktywność sesyjna",
      conversationAction: "Akcja sesyjna",
      riskSemantics: "Semantyka ryzyka",
      daysNotFollowedUp: "Dni nie przestrzegane",
      waysideData: "Dane dotyczące ruchu drogowego",
      notStarted: "Nie rozpoczęto",
      completed: "Ukończone",
      haveInHand: "W toku",
      unpublished: "Nie opublikowano",
      published: "Opublikowano",
      knowledgePoints: "Punkty wiedzy",
      technologicalProcess: "Proces",
      notMarked: "Nie jest oznakowany",
      inAnnotation: "W callout",
      annotated: "Oznakowane",
    },
    qualityChecingTask: {
      appealCanceled: "Odwołanie anulowane",
      complaintExpired: "Skarga wygasła",
      incomingCall: "Połączenie przychodzące",
      exhale: "Wydech",
      incomingCallthirdParty: "Połączenie przychodzące (trójstronne)",
      incomingCallconsultation: "Połączenie przychodzące (Consulting)",
      incomingCallforcedInsertion: "Połączenie przychodzące (silne)",
      incomingCallmonitoring: "Połączenie przychodzące (słuchanie)",
      incomingCalltransfer: "Połączenie przychodzące (transfer)",
      incomingCallinterception: "Połączenie przychodzące (przechwycenie)",
      incomingCalltransferToExternalLine:
        "Połączenie przychodzące (transfer poza linią)",
      incomingCallthirdPartyExternalLine:
        "Połączenie przychodzące (trójstronna linia zewnętrzna)",
      incomingCallexternalLine:
        "Połączenie przychodzące (konsultacja poza linią)",
      outgoingCallthirdParty: "Wychodzący (trójdrożny)",
      outgoingCalltransfer: "Wychodzące (Transfer)",
      outgoingCallforcedInsertion: "Wychodzący (silny)",
      outgoingCallmonitoring: "Wychodzące (słuchanie)",
      outgoingCallinterception: "Połączenie wychodzące (przechwycenie)",
      outgoingCalltransferToExternalLine: "Wychodzące (transfer poza linią)",
      outgoingCallthreePartyExternalLine:
        "Połączenie wychodzące (trójstronna linia zewnętrzna)",
      customerServiceAnswer: "Odpowiedź obsługi klienta",
      customerServiceMissed: "Brakowało obsługi klienta",
      customerAnswer: "Odpowiedź klienta",
      customerMissed: "Klient przegapił",
      theTelephoneIsBusy: "Telefon zajęty",
      phoneOffline: "Telefon offline",
      customerQuickHanging: "Klient Quick Hanging",
      clientHangsUp: "Klient rozłącza się",
      queueTimeout: "Limit czasu kolejki",
      giveUpQueuing: "Porzuć kolejkę",
      outsideLineAnswering: "Odpowiedź zewnętrzna",
      externalLineIsNotConnected: "Linia zewnętrzna nie jest podłączona",
      noQueueSelected: "Nie wybrano kolejki",
      notEvaluated: "Nie oceniono",
      noEvaluationRequired: "Nie trzeba oceniać",
      queuingSucceeded: "Sukces w kolejce",
      noCustomerServiceOnline: "Brak obsługi klienta online",
      customer: "Klient",
      customerService: "Obsługa klienta",
      outsideLines: "Na zewnątrz",
    },
    sessionActivity: {
      single: "Średni czas pojedynczego połączenia",
      all: "Łączna liczba połączeń",
      allTime: "Całkowity czas trwania połączenia",
    },
    sessionAction: {
      salesProportion: "Współczynnik rozmów o sprzedaży",
      salesLongest: "Średnie najdłuższe zestawienie sprzedaży",
      customerLongest: "Przeciętny najdłuższy klient mówi",
      discussNumber: "Liczba szczegółowych dyskusji",
      frequency: "Częstotliwość pytań",
    },
    monitorCriteria: { all: "Przed i po", before: "Przed", after: "Po" },
    monitorTimeCycle: { day: "Dzień", week: "Tydzień", month: "Miesiąc" },
    monitorGroupTestType: {
      ab: "Porównanie dwóch grup",
      a: "Grupa A",
      b: "Grupa B",
    },
    fields: {
      department: "Departament",
      customerTags: "Etykieta klienta",
      member: "Członkowie",
      time: "Czas",
      cascade: "Kaskada",
      anyMatch: "Dowolny mecz",
      keyWord: "Słowa kluczowe",
      keyEvents: "Kluczowe wydarzenia",
    },
    semanticIntelligence: {
      staff: "Pracownik",
      customer: "Klient",
      employeesAndCustomers: "Pracownicy i klienci",
      notStarted: "Nie rozpoczęto",
      haveInHand: "W toku",
      completed: "Ukończone",
      callRecording: "Nagrywanie rozmów",
      enterpriseWeChatCall: "Przedsiębiorstwo Micro-Talk",
      enterpriseWeChatText: "Przedsiębiorstwo mikro-tekst",
      discourseMiningForObjectionHandling:
        "Wydobywanie mowy w przypadku sprzeciwu",
      productSellingPointScriptsMining:
        "Wydobywanie punktów sprzedaży produktu",
      conversationScenario: "Scenariusze sesji",
      topicOfConversation: "Temat",
      keyEvents: "Kluczowe wydarzenia",
      customerLabel: "Etykieta klienta",
      includeAny: "Zawiera dowolny",
      includeAll: "Zawiera wszystkie",
      and: "I",
      or: "Lub",
      question: "Wyrok pytający",
      nonInterrogativeSentence: "Wyrok bez pytania",
    },
    coach: {
      notPassed: "Nie przeszedł",
      analogTelephone: "Telefon analogowy",
      enterScenarioInformation: "Wprowadź informacje o scenariuszu",
      generateDialogueFramework: "Generowanie ram dialogu",
      generatePromptLanguage: "Prompt wygenerowany",
      aIGeneratedDialogue: "Dialog generowany przez sztuczną inteligencję",
      system: "System",
      custom: "Niestandardowe",
      flow: "Proces",
      script: "Słowa",
      express: "Wyrażenie",
      emotion: "Emocja",
      rateAccordingToCourseConfiguration: "Wynik według konfiguracji kursu",
      intelligentModelRating: "Inteligentna punktacja modelu",
      allData: "Wszystkie dane",
      dataFromThisDepartment: "Dane Departamentu",
      multidepartmentalData: "Dane wielosektorowe",
      underReview: "W przeglądzie",
      no: "Nie",
      yes: "Tak",
      automaticRating: "Automatyczne punktowanie",
      auditRating: "Wynik audytu",
      reRating: "Ponowne punktowanie",
      inAppeal: "W skardze",
      reviewed: "Sprawdził",
      notViewed: "Nie oglądane",
      viewed: "Oglądane",
      uncorrected: "Nie poprawiono",
      corrected: "Poprawiono",
      practice: "Ćwiczenie",
      examination: "Badanie",
      other: "Pozostałe",
      notMeetingStandards: "Nie do standardu",
      meetingStandards: "Standardowy",
      excellent: "Doskonały",
      invalid: "Niepowodzenie",
      notStarted: "Nie rozpoczęto",
      haveInHand: "W toku",
      ended: "Zakończone",
      completed: "Ukończone",
      hangInTheAir: "Niedokończone",
      passed: "Przeszedł",
      voiceConversation: "Dialog głosowy",
      textDialogue: "Dialog tekstowy",
      slidePresentation: "Prezentacja slajdów",
      unpublished: "Nie opublikowano",
      published: "Opublikowano",
      narration: "Narrator",
      studentSpeaking: "Mówiący kadet",
      robotTalk: "Roboty mówią.",
      knowledgeBaseQA: "Pytania i odpowiedzi w bazie wiedzy",
      slide: "Slajd",
      negativeEmotions: "Negatywne emocje",
      emotionallyNormal: "Normalny nastrój",
      incompleteScript: "Niepełna mowa",
      completeScript: "Pełna mowa",
      normalSpeechSpeed: "PRĘDKOŚĆ NORMALNA",
      speakTooFast: "Szybko za szybko",
      speakTooSlowly: "PRĘDKOŚĆ POWOLNA",
      whole: "Wszystkie",
      singlePage: "Pojedyncza strona",
      qA: "Jedno pytanie i jedna odpowiedź",
      situationalDialogue: "Dialog sytuacyjny",
      misses: "Przegapione",
      hit: "Uderzenie",
      sequentialQA: "Sekwencyjne pytania i odpowiedzi",
      randomQA: "Losowe pytania",
      mastered: "Opanowanie",
      notMastered: "Nie opanowany",
    },
    algorithm: {
      salesEmpowerment: "Upodmiotowienie sprzedaży",
      enterpriseWeChatVoice: "Enterprise WeChat głos",
      enterpriseWeChatText: "Enterprise WeChat Tekst",
      waiting: "Czekanie",
      extracting: "W ekstrakcji",
      success: "Sukces",
      fail: "Nie powiodło się",
      intelligentQualityInspection: "Inteligentna kontrola jakości",
      textDialogue: "Dialog tekstowy",
      voiceCall: "Połączenie głosowe",
      smartTags: "Inteligentna etykieta",
      satisfactionEvaluation: "Ocena satysfakcji",
      score: "Ocena",
      grade: "Ocena",
      customerServiceTeam: "Grupa Obsługi Klienta",
      conversationAnalysis: "Analiza konwersacji",
    },
    operatorListGather: {
      businessSummary: "Podsumowanie biznesowe",
      firstSentence: "Pierwsze zdanie",
      lastSentence: "Ostatnie zdanie",
      allTime: "Cały czas",
      nonBusinessWorkingHours: "Godziny niebędące godzinami pracy",
      businessWorkingHours: "Godziny pracy w biznesie",
    },
    callDialogueManage: {
      eCommerceReview: "Przegląd handlu elektronicznego",
      socialMediaComments: "Komentarze do mediów społecznościowych",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "Logowanie",
        email: "Skrzynka pocztowa",
        password: "Hasło",
        forgetPwd: "Zapomniałem hasła",
        emailCannotBeEmpty: "Skrzynka pocztowa nie może być pusta.",
        passwordCannotByEmpty: "Hasło nie może być puste.",
        mustChangeLogin:
          "Przepraszamy, hasło musi zostać zresetowane. Skontaktuj się z administratorem, aby zresetować hasło.",
        dismissionLogin: "Przepraszam, zostawiłeś swoją pracę.",
        forbiddenLogin: "Przepraszamy, Twoje konto jest wyłączone.",
        loginError:
          "Nie udało się zalogować, skrzynka pocztowa lub błąd ha sła.",
        loginPrompt: "Zaloguj się do systemu",
        rememberMe: "Zapamiętaj mnie",
      },
      logout: {
        logoutError:
          "Wylogowanie nie powiodło się. Sprawdź połączenie sieciowe lub spróbuj ponownie później.",
      },
      resetPassword: {
        forgetPwd: "Zapomniałem hasła",
        inputEmailPrompt:
          "Wprowadź poniżej swój adres e-mail do konta, a my wyślemy Ci wiadomość e-mail z instrukcjami, aby zresetować hasło.",
        sendEmailSuccess:
          "List potwierdzający modyfikację hasła został pomyślnie wysłany na wypełniony e-mail, sprawdź go na czas.",
        sendEmailFailed:
          "Nie udało się wysłać skrzynki pocztowej. Sprawdź połączenie sieciowe lub spróbuj ponownie później.",
        setNewPassword: "Ustaw nowe hasło",
        passwordCannotEmpty: "Hasło nie może być puste",
        passwordFormWrong: "Nieprawidłowy format hasła",
        confirmPasswordWrong: "Dwa wprowadzone hasła nie są równe.",
        passwordResetSuccess: "Udało się zresetować hasło.",
        passwordResetFailed:
          "Resetowanie hasła nie powiodło się. Sprawdź połączenie sieciowe lub spróbuj ponownie później.",
        linkTimeover: "Zresetuj hasło link wygasł, spróbuj ponownie",
      },
      form: { title: "Wyświetlacz formularza CASE" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "Skrzynka pocztowa",
          emailFormatError:
            "Błąd formatu skrzynki pocztowej, wprowadź ponownie!",
          forgotPassword: "Zapomniałeś hasła?",
          describe:
            "Wprowadź poniżej swój adres e-mail do konta, a my wyślemy Ci wiadomość e-mail z instrukcjami, aby zresetować hasło.",
          send: "Wyślij",
          emailCannotBeEmpty: "Skrzynka pocztowa nie może być pusta",
          resetPasswordExpire: "Zresetuj hasło link wygasł, spróbuj ponownie",
        },
        resetPassword: {
          setPassword: "Ustaw nowe hasło",
          newPassword: "Nowe hasło",
          repeatPassword: "Zduplikowane hasło",
          resetPassword: "Zresetuj hasło",
          passwordCannotBeEmpty: "Nowe hasło nie może być puste.",
          confirmPasswordCannotBeEmpty:
            "Zduplikowane hasło nie może być puste.",
          passwordsNotMatch: "Dwa hasła są niespójne!",
          passwordFormWrong:
            "Hasło nie może być w języku chińskim, a liczba to 6-14!",
          linkExpiration: "Zresetuj hasło link wygasł, spróbuj ponownie",
        },
      },
      errors: {
        haveNoPermissionWarningText:
          "Nie masz uprawnień do przeglądania tej strony",
      },
    },
    downloadCenter: {
      name: "Centrum pobierania",
      saveTime: "(Domyślnie 30 dni)",
      headerTitle: "Pobierz plik",
      download: "Pobierz",
      downloadPromp: "Zadanie pobierania zostało przesłane, idź",
      viewProgress: "Zobacz postęp",
    },
    uploadCenter: { name: "Przesyłanie centrum" },
    tasks: {
      index: {
        name: "Imię i nazwisko",
        remark: "Opis",
        qualityCheckTasksTitle: "Stół warsztatowy",
        informationCollection: "Zadania zbiórki",
        qualityCheckTasks: "Zadanie kontroli jakości",
        wechatQaTasks: "Zadanie analizy",
        moduleName: "Użyj nowego szablonu",
        creatNewQualityCheckTask: "Nowe zadanie",
        generalInspectionList: "Ogólny raport z inspekcji jakości",
        editQualityCheckTask: "Edytuj zadanie kontroli jakości",
        creatNewQualityCheckTaskError:
          "Nie udało się stworzyć nowego zadania kontroli jakości.",
        editQualityCheckTaskError:
          "Nie udało się edytować zadania kontroli jakości.",
        type: "Rodzaj formularza",
        singleScore: "Pojedynczy wynik",
        singleScoreNullError: "Pojedynczy wynik nie może być pusty",
        inspector: "Inspektor jakości",
        inspectDataSource: "Źródło danych",
        taskName: "Nazwa zadania",
        inspectionType: "Rodzaj kontroli jakości",
      },
      manage: {
        screeningCondition: "Stan filtra",
        template: {
          condition: {
            index: { name: "Stan", existingConditions: "Istniejące warunki" },
            detail: {
              id: "ID",
              name: "Nazwa stanu",
              remark: "Opis",
              checkRange: "Zasięg wykrywania",
              operator: "Operator",
              operatorLogic: "Logika operatora",
            },
          },
          checkPointTest: {
            title: "Test reguł",
            running: "Zasady w teście, proszę później...",
            clearSuccess: "Symulowany dialog został pomyślnie opróżniony",
            mock: "Symulowany dialog",
            save: "Zapisz wybrane okno dialogowe",
            saveAll: "Zapisz wszystkie rozmowy",
            saveSuccess: "Pomyślnie zapisana rozmowa",
            close: "Wyjście",
            delete: "Usuń wybrane",
            clear: "Pusty",
            customer: "Klient",
            agent: "Obsługa klienta",
            test: "Test",
            request:
              "Po wyczyszczeniu okna dialogowego symulacji nie można go odzyskać. Potwierdzić, aby to wyczyścić?",
            deletePopconfirmContent:
              "Czy na pewno chcesz usunąć wybrane okno dialogowe?",
          },
          checkPoint: {
            index: {
              name: "Konfiguracja reguły",
              wechatTitle: "Konfiguracja modelu analizy",
              existingCheckPoints: "Istniejące zasady kontroli jakości",
              currentCheckPointScore:
                "Aktualny wynik reguły kontroli jakości to",
              currentCheckPointScoreWechat: "Aktualny wynik modelu analizy to",
              currentCheckPointWeight:
                "Całkowita waga obecnej zasady kontroli jakości wynosi",
              weightTotalScoreAndItemScore:
                "Wynik całkowity: 100, pojedynczy wynik:",
              template: "Szablon zasady kontroli jakości",
              import: "Szablon zasady kontroli jakości importu",
              getTemplateError:
                "Nie udało się uzyskać szablonu reguły kontroli jakości.",
              tabs: { template: "Szablon", demo: "Próbka" },
              customize: "Blank Szablon",
              move: "Przeciągnij i upuść, aby dostosować klasyfikację i sortowanie",
              classification: "Klasyfikacja kontroli jakości",
              classificationWeChat: "Klasyfikacja modelu",
              first: "Klasyfikacja poziomu 1",
              second: "Klasyfikacja wtórna",
              new: "Nowa zasada kontroli jakości",
              weChatNew: "Dodaj model analizy",
              createWeChat: "Nowy model analizy",
              empty: "Na razie nie ma zasad kontroli jakości,",
              wechatEmpty: "Brak modelu analizy,",
              newSecond: "Nowa klasyfikacja wtórna",
              newFirst: "Nowa klasyfikacja pierwszego poziomu",
              checkPoint: "Zasady kontroli jakości",
              checkPointWeChat: "Model analizy",
            },
            detail: {
              name: "Imię i nazwisko",
              remark: "Opis",
              type: "Rodzaj",
              deduction: "Odliczenia",
              rule: "Zasady",
              highlighted: "Podkreśl",
              gradeType: "Metoda punktacji",
              interactiveCategory: "Klasyfikacja interakcji",
              predeterminedScore: "Wynik",
              weight: "Waga",
              na: "N/A",
              default: "Wynik domyślny",
              preRule: "Reguła warunku wstępnego",
              analysisHighlighted: "Zasady analizowania",
              categorySelectPrompt: "Wybierz kategorię",
              checkHighlightArrayError:
                "Reguła nie pasuje do podświetlenia, kliknij ponownie przycisk reguły parsowania.",
              foctorsHolder: "Proszę wybrać element",
              foctorsErrorNone:
                "Reguła wieloelementowa jest pusta, dodaj typ wieloelementowy.",
              naError:
                "Nie można zamknąć przedmiotu, gdy domyślny wynik to N/A",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  "N/A to nie dotyczy, co określa warunki wstępne dla zasady kontroli jakości. Jeśli warunek jest spełniony, reguła nie ma zastosowania. Jeśli warunek nie zostanie spełniony, reguła nie zostanie wykryta;\nOpis warunków wstępnych: „ && ”oznacza „ i”, „ || ”oznacza „ lub”, „!” Wskazuje „ NIE ”, wypełnij przykład „ (R1 || R2) && R3”",
                applicationMessageType: "Typ komunikatu aplikacji",
                selectAll: "Wybierz wszystkie",
                highlightTags: "Podkreśl etykietę:",
                ruleType: "Rodzaj reguły",
                dataSource: "Źródło danych",
                whole: "Wszystkie",
                enterpriseMicroConversation: "Przedsiębiorstwo mikro-sesja",
                enterpriseAndMicroCommunication: "Przedsiębiorstwo Micro-Talk",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "Węzeł wyroku",
                    dialogueNode: "Węzeł dialogowy",
                    editNodes: "Edytuj węzeł",
                  },
                  nodeConfig: {
                    nodeName: "Nazwa węzła",
                    nodeRules: "Zasady węzła",
                    scoringLogic: "Logika punktacji",
                    score: "Wynik:",
                    rule: "Zasady",
                    multiBranchLogic: "Logika wielobranżowa",
                    highlight: "Podkreśl",
                    submit: "Przedłożenie",
                  },
                  ruleList: {
                    branch: "Oddział",
                    branchName: "Nazwa oddziału:",
                    addRules: "Dodaj regułę",
                  },
                  sidebar: { node: "Węzeł" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "Automatyczne wykańczanie",
                      canvasAdaptation: "Płótno adaptacyjne",
                      copy: "Kopiowanie",
                      delete: "Usuń",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "Węzeł podrzędny węzła oceny nie może być używany jako cel połączenia.",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "Węzeł początkowy nie może być celem połączenia",
                      startingNodeCannotConnectMultipleNodes:
                        "Węzeł startowy nie może połączyć wielu węzłów",
                      nodeCannotBeConnectedToItself:
                        "Węzeł nie może połączyć się z samym sobą",
                      startNode: "Węzeł startowy",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "Anuluj Wybierz wszystkie",
                    selectAll: "Wybierz wszystkie",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "Zgodność",
                    gradedEvaluation: "Ocena stopnia",
                  },
                },
              },
            },
            configuration: {
              title: "Zasady kontroli jakości",
              wechatTitle: "Model analizy",
              neme: "Imię i nazwisko",
              errorMessage:
                "Usuń nie powiodło się z co najmniej jednym warunkiem",
              desc: "Opis",
              status: "Status",
              yes: "Skuteczne",
              no: "Nieważne",
              namePlaceHolder: "Wprowadź nazwę etykiety",
              desPlaceHolder: "Proszę wpisać opis",
              nameEmpty: "Nazwa nie może być pusta",
              desEmpty: "Opis nie może być pusty",
              type: "Rodzaj",
              ruler: "Inteligentne zasady",
              machineLearning: "Tryb samokształcenia",
              autoScore: "Inteligentna punktacja (stara)",
              manualScore: "Punktacja ręczna",
              interaction: "Wykrywanie interakcji (stare)",
              typeTips: {
                model:
                  "Inteligentny model: Jest to wstępnie ustalony system kontroli jakości, który może szybko sprawdzić problemy występujące w usłudze bez konfiguracji i konserwacji. Ten model jest bardziej ogólny, jeśli nie spełniasz wymagań biznesowych, użyj „ inteligentnych zasad ”lub „ ręcznej punktacji”.",
                rule: "Inteligentne zasady: automatyczna punktacja oparta na regule AI",
                manual:
                  "Punktacja ręczna: ręczna kontrola jakości, punktacja ręczna według instrukcji",
              },
              model: "Model:",
              ruleType: {
                words: "Słowa",
                interaction: "Interakcja",
                feature: "Cechy",
                information: "Informacje",
              },
              testingMode: {
                all: "Wykrywanie wszystkich słów kluczowych.",
                any: "Wykryj dowolne słowa kluczowe",
              },
              operatorTypesOfInteraction: {
                question: "Jedno pytanie i jedna odpowiedź",
                element: "Odpowiedź elementów",
                repet: "Powielanie kontekstu",
                snatch: "Wyrwijcie słowa",
                silent: "Cisza",
              },
              role: "Rola wykrywania:",
              preposition: "Operator wstępny:",
              range: "Zakres wykrywania:",
              from: "Nie.",
              to: "Wyrok na nr.",
              end: "Zdanie",
              hasOldSilentTip:
                "Witam, aby poprawić dokładność, „ Inteligentne zasady-interakcja-cisza ”zostały uaktualnione do „ Limit czasu odpowiedzi”. Zaleca się aktualizację zasad użytkowania!",
              changeToOld: "Przełącz na dziedzictwo",
              changeToNew: "Przełącz na nową wersję",
              logic: {
                logic: "Logika punktacji",
                naTip:
                  "N/A to nie dotyczy, co określa warunki wstępne dla zasady kontroli jakości. Jeśli warunki są spełnione, reguła nie ma zastosowania. Jeśli warunki nie zostaną spełnione, reguła nie zostanie wykryta.",
                score: "Wynik:",
                na: "N/A：",
                naDes:
                  "Ten przedmiot nie jest zaangażowany w dozwolone wykrywanie",
                preRule: "Zasady warunku wstępnego:",
                notCatchPreRule: "Gdy warunki wstępne nie są spełnione:",
                naChecked: "Podczas uderzania N/A:",
                preRuleEmpty: "Reguła warunku wstępnego nie może być pusta",
                operatorEmpty:
                  "Warunek konieczny nie może być zerowy w operatorze",
                multipleEmpty:
                  "Warunek konieczny nie może być pusty w wielu wynikach logicznych",
                average: "Średni wynik w grupie",
                addOrSubtract: "Bezpośrednie punkty plus lub minus",
                noChange: "Brak punktów plus lub minus",
                rule: "Zasady:",
                all: "Satysfakcjonować wszystkich operatorów",
                any: "Spełnić dowolny operator",
                customize: "Niestandardowa logika operatora",
                multiple: "Wielokrotny wynik logiczny",
                heightLight: "Podkreśl:",
                machineLearningTip:
                  "Obecnie tryb samokształcenia jest skuteczny tylko w przypadku mowy jednozdaniowych. W przypadku złożonej mowy używaj inteligentnych zasad.",
                strategy: "Strategia:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "Zatwierdzenie złożone pomyślnie",
            },
            components: {
              pageHeaderExtra: {
                test: "Test",
                formal: "Formalne",
                submit: "Przedłożenie",
              },
              createModal: { index: { myRules: "Moje zasady" } },
            },
          },
          automaticAdd: {
            index: {
              name: "Automatyzacja procesów",
              screeningCondition: "Stan filtra",
              aiScreeningCondition: "Stan inteligentnego filtra",
              rule: "Zasady",
              automaticAddition: "Dodaj automatycznie",
              automaticAdditionTip:
                "Automatyczne dodawanie danych do zadań w czasie rzeczywistym lub w regularnych odstępach czasu w celu kontroli jakości AI",
              autoAdd: {
                autoAddType: "Metoda automatycznego dodawania",
                addFrequency: "Dodaj częstotliwość",
                add: "Dodaj",
              },
              automaticSamplingInspection:
                "Automatyczna kontrola pobierania próbek",
              automaticSamplingInspectionTip:
                "Automatycznie wyodrębniaj dane, które wymagają ręcznej kontroli jakości, na przykład pobieraj 10% wczorajszych losowych danych każdego dnia do ręcznego pobierania próbek.",
              everyAgentCheckLimit: "Maksymalne losowanie agenta",
              automaticAllocation: "Automatyczny przydział",
              automaticAllocationTip:
                "Przydział kontroli pobierania próbek: wyodrębnione dane są automatycznie przydzielane inspektorom jakości. Na przykład 10% wczorajszych losowych danych jest pobieranych codziennie w celu ręcznej kontroli próbek, a 2 inspektorów jakości jest losowo przydzielanych do tego zadania średnio.",
              automaticAllocationTip2:
                "Alokacja przeglądu: dane odwoławcze są automatycznie przydzielane inspektorowi jakości do przeglądu, na przykład codzienne dane odwoławcze są równie losowo przydzielane do 2 inspektorów jakości tego zadania do przeglądu.",
              automaticPush: "Automatyczne push",
              automaticPushTip:
                "Powiadomienie wyzwalające: powiadomienie/alarm podczas kontroli jakości, na przykład w przypadku wystąpienia błędu fatalnego w obsłudze klienta, wiadomość z powiadomieniem jest wysyłana do mikro grupy przedsiębiorstwa.",
              automaticPushTip2:
                "Raport czasowy: Wyślij wyniki kontroli jakości w formie poczty, na przykład wyślij wyniki kontroli jakości wczorajszego ręcznego pobierania próbek do skrzynki pocztowej przełożonego obsługi klienta o godzinie 9:00 każdego dnia.",
              automaticAssess: "Automatyczna ocena",
              automaticAssessTip:
                "Wyniki ręcznego pobierania próbek są regularnie porównywane z wynikami kontroli jakości AI w celu oceny dokładności. Na przykład współczynnik dokładności wczorajszych danych próbkowania jest oceniany codziennie o godzinie 8:00.",
              reexaminationComplaint: "Przegląd skarg",
              spoktReviewDetail: "Konfiguracja biznesowa",
              spoktReviewDetailTip:
                "Skonfiguruj funkcje, takie jak lista kontroli jakości, lista inspekcji losowych, działalność reklamacyjna, lista przeglądów i uwagi",
              qaDetailAction: "Konfiguracja inspekcji jakości",
              spoktDetailAction: "Konfiguracja próbkowania",
              reviewDetailAction: "Konfiguracja przeglądu",
              appealAction: "Konfiguracja skargi",
              remarkTemplate: "Szablon Uwaga",
              automaticArchiving: "Automatyczna archiwizacja",
              conditionalRules: "Zasady warunku",
              qualityInspectionConditions: "Warunki kontroli jakości",
              unopened: "Jeszcze nie otwarte",
              sampleInspectionDistributionModel: "Przydział pobierania próbek",
              reviewAllocationModel: "Przegląd alokacji",
              distributionModelTitle: "Polityka alokacji:",
              pushRules: "Zasady push",
              push: "Pchnij",
              assessRules: "Zasady oceny",
              on: "on",
              off: "off",
              updateStatusSuccess: "Zmiana statusu zakończyła się sukcesem!",
              updateStatusFailed:
                "Zmiana statusu nie powiodła się, spróbuj ponownie później!",
              sampleNumberOrPercent: "Numer próbkowania lub proporcja",
              inspectorList: "Wyznaczony inspektor jakości",
              numberOfSubmission: "Liczba oświadczeń dopuszczonych do złożenia",
              rewivewDays: "Dzień",
              TimeOfSubmission: "Data ważności przedłożenia oświadczeń",
              rewivewSecond: "Czasy",
              switchOn: "Otwarte",
              switchOff: "Zamknij",
              notMatchAnyRule: "Gdy żadne reguły nie są dopasowane:",
              appealOptionsList: {
                hiddenInspector: "Ukryte informacje inspektora reklamacji",
                hiddenHit:
                  "Strona odwołania obsługuje wyróżnianie i pozycjonowanie trafień.",
              },
              caseLibrary: "Synchronizacja biblioteki obudowy",
              caseLibrarySyncTip:
                "Synchronizacja przypadków dodanych przez system QA do systemu obsługi klienta",
              addCase: "Zwiększ synchronizację",
              caseLibraryId: "Biblioteka przypadków",
              caseLibraryIdTip: "Grupowanie bazowe przypadków systemu QA",
              udeskCaseLibraryIdTip:
                "Grupowanie przypadków systemu obsługi klienta",
              syncHis: "Przypadek historii synchronicznej",
              otherSettings: "Inne ustawienia",
              udeskCaseLibraryId: "Grupa Push Case",
              url: "Push URL",
              urlTip:
                "Wprowadź adres interfejsu, zaczynając od http:// lub https://",
            },
            templateNew: {
              secondaryReview: "Przegląd wtórny",
              numberOfAppealsAllowedToBeSubmitted:
                "Liczba dopuszczonych odwołań",
              validityOfSubmissionOfAppeal: "Ważność wniesienia odwołania",
              validityOfAppealReview: "Ważność przeglądu odwołania",
              hours: "Godziny",
              businessSummary: "Podsumowanie biznesowe",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "Dolna granica ekstrakcji na środek",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "Podsumowanie biznesowe jest automatycznie generowane przez duży model, a wymiar można dostosować do sytuacji biznesowej.",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "Strategia przypisania tylko dla recenzenta poziomu 1",
              secondaryCompounding: "Kompozyt wtórny:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "Drugorzędni recenzenci obsługują tylko równą dystrybucję",
              secondLevelReviewer: "Recenzent drugiego poziomu:",
              samplingInspectionTarget: "Docelowa kontrola pobierania próbek",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "Ustaw cel, który ma zostać ukończony przez ręczną kontrolę pobierania próbek i podaj komunikat, gdy cel nie zostanie osiągnięty lub przekroczony. Na przykład każda obsługa klienta powinna przeprowadzić kontrolę próbkowania 100 pozycji miesięcznie, a gdy cel jest mniejszy niż 80 pozycji, strona kontroli jakości i kontroli próbek wyświetli monit.",
            },
            component: {
              verificationFailed: "Weryfikacja nie powiodła się",
              pleaseAddASecondLevelQualityInspector:
                "Dodaj inspektora jakości drugiego poziomu",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "Prompt",
                    fieldName: "Nazwa pola",
                    fieldDescription: "Opis pola",
                    generateContent: "Generowanie treści",
                    enabled: "Włączone",
                    generationMethod: "Metoda generowania",
                    automaticGeneration: "Automatycznie generowane",
                    manualTrigger: "Wyzwalacz ręczny",
                    filterCondition: "Stan filtra",
                    return: "Powrót",
                    preserve: "Zapisz",
                  },
                  components: {
                    training: {
                      index: { generateResults: "Generowanie wyników" },
                    },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "Nie kwalifikowany",
                    limitedScope: "Ograniczony zakres",
                    add: "Dodaj",
                    thereIsCurrentlyNoDataAvailable: "Brak danych",
                  },
                },
              },
              index: {
                fieldName: "Nazwa pola",
                fieldDescription: "Opis pola",
                nothing: "Brak",
                generationMethod: "Metoda generowania",
                generateManually: "Generuj ręcznie",
                automaticGeneration: "Automatycznie generowane",
                generateContent: "Generowanie treści",
                notQualified: "Nie kwalifikowany",
                filterCondition: "Stan filtra",
                enabled: "Włączone",
                type: "Rodzaj",
                system: "System",
                custom: "Niestandardowe",
                operation: "Operacja",
                edit: "Edytuj",
                areYouSureToDeleteIt: "Czy na pewno chcesz usunąć?",
                delete: "Usuń",
                addField: "Dodaj pole",
              },
            },
          },
          roleConfig: {
            index: {
              name: "Konfiguracja roli",
              roleConfigTip:
                "Służy do konfigurowania reguł rozpoznawania dla przetwarzania separacji głośników, gdy importowane dane połączenia są mono. Spełnia zasady dotyczące roli agenta",
              operator: "Operator",
              operatorLogic: "Logika operatora",
            },
          },
          manualAdd: {
            index: {
              name: "Dodaj ręcznie",
              tip: "Służy do filtrowania podglądu danych poprzez ręczne konfigurowanie kryteriów i szybkie dodawanie ich do zadania kontroli jakości",
              screeningCondition: "Dodaj według kryteriów filtra",
              aiScreeningCondition: "Stan inteligentnego filtra",
              rule: "Zasady",
              createTime: "Dodaj czas",
              filterTitle: "Zawartość filtra",
              filterRule: "Stan filtra",
              startTime: "Czas rozpoczęcia",
              endTime: "Czas zakończenia",
              records: "Zapisy dotyczące importu",
              createUserName: "Operator",
              successCount: "Dodano pomyślnie",
              conditionRule: "Zasady stanu:",
              preview: "Podgląd",
              previewData:
                "Obecnie istnieją {0} pasujące kryteria filtru, dodano {1} zadań, można dodać {2} zadań i nie można dodać {3} zadań.",
              failCount: "Nie udało się dodać",
            },
          },
          basicInfo: {
            index: {
              title: "Podstawowe informacje",
              name: "Imię i nazwisko",
              taskName: "Nazwa zadania",
              taskNamePlaceholder: "Wprowadź nazwę zadania",
              requiredName: "Nazwa zadania nie może być pusta",
              taskRemarkPlaceholder: "Wprowadź opis zadania",
              requiredRemark: "Opis zadania nie może być pusty",
              remark: "Opis",
              taskRemark: "Opis zadania",
              source: "Źródło danych",
              benchmarkScore: "Wynik odniesienia",
              benchmarkScorePlaceholder: "Wprowadź wynik benchmarku",
              scoreLowerLimit: "Minimalny wynik Niższy Limit",
              scoreUpperLimit: "Maksymalny limit w skali",
              inspectType: "Rodzaj kontroli jakości",
              nlpModel: "Model NLP",
              commonInspect: "Ogólna kontrola jakości",
              associateInspect: "Powiązana inspekcja jakości",
              associateCycle: "Okres korelacji",
              associateRegulation: "Reguła stowarzyszenia",
              judgeStrategy: "Logika wykonania",
              rule: "Zasady",
              commonTip:
                "Ogólna kontrola jakości: dotyczy pojedynczej komunikacji i dialogu oraz kontroli jakości treści pojedynczej komunikacji.",
              associateTip:
                "Powiązana kontrola jakości: zastosowanie do wielu komunikacji i rozmów, łącząca treść wielu komunikatów w celu kontroli jakości.",
              cycleTip:
                "Jeśli istnieje wiele powiązanych komunikatów i dialogów, dane w powiązanym okresie wejdą do zadania kontroli jakości.",
              scoreLowerLimitPlaceholder: "Wprowadź minimalny limit wyniku",
              scoreUpperLimitPlaceholder: "Wprowadź limit maksymalnego wyniku",
              call: "Połączenie głosowe",
              dialogue: "Dialog tekstowy",
              user: "Inspektor jakości",
              wechatUser: "Operator",
              noDesignated: "Nie określaj",
              type: "Rodzaj formularza",
              singleScore: "Pojedynczy wynik",
              conduct:
                "Najpierw uzupełnij następujące informacje, a następnie kliknij Dalej",
              conduct2:
                "Proszę wypełnić konfigurację zasad kontroli jakości i punktów kontroli jakości. Jeśli ukończyłeś konfigurację, możesz kliknąć Przewodnik Next lub Exit",
              conduct3:
                "Ocena kontroli jakości jest skonfigurowana zgodnie z punktem kontroli jakości i jego oceną. Można zdefiniować różne poziomy wyników kontroli jakości. Jeśli nie potrzebujesz oceny, możesz pominąć ten krok. Jeśli ukończyłeś konfigurację, możesz kliknąć Dalej lub wyjść z przewodnika.",
              conduct4:
                "Możesz skonfigurować charakterystykę danych, która zostanie automatycznie dodana do zadania zgodnie z charakterystyką danych, którą zadanie powinno sprawdzić w pozycji automatycznego dodawania. Jeśli na razie nie musisz automatycznie dodawać danych, możesz kliknąć Pomiń. Jeśli ukończyłeś konfigurację, możesz kliknąć Dalej.",
              conduct5:
                "Jeśli używasz nagrywania mono, pamiętaj, aby skonfigurować ten element i kliknij Dalej. Użyj tylko głosu obsługi klienta, aby pomóc systemowi zidentyfikować różne ścieżki między obsługą klienta a klientem. Jeśli korzystasz z nagrywania dwukanałowego lub ukończyłeś konfigurację, możesz kliknąć Pomiń",
              conduct6:
                "Możesz użyć funkcji ręcznego dodawania, aby wprowadzić istniejące dane komunikacyjne w systemie do kontroli jakości lub przesłałeś ręczne zadanie dodawania, kliknij przycisk Zakończ bezpośrednio",
              helper: "Wyświetl dokumentację pomocy",
              quit: "Wyjdź z rozruchowego",
              next: "Następny krok",
              complete: "Kompletny",
              skip: "Pomiń",
              cancel: "Anuluj",
              delete: "Usuń",
              save: "Zapisz",
            },
            template: {
              samplingInspector: "Inspektor pobierania próbek",
              sessionType: "Rodzaj sesji",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "Stół do kontroli jakości",
              search: "Filtr",
              export: "Eksport",
              condition: "Stan",
              logic: "Logika",
              reQualityInspection: "Ponowna inspekcja",
              reQualityInspectionUnderWay: "Ponowna inspekcja zadania...",
              reQualityInspectionUnderWayTip:
                "Opuszczenie strony po zamknięciu okna nie wpływa na zadanie",
              reQualityInspectionDone: "Ponowna inspekcja zakończona",
              reQualityInspectionDoneTip:
                "Ponowna inspekcja została pomyślnie zakończona, kliknij przycisk potwierdzenia.",
              endReQualityInspection: "Zakończcie zadanie",
              alreadyDoneTip: "Ukończone",
              totalTip: "Ogółem",
              unit: "Artykuł",
              endReInspectionSuccess: "Zadanie o ponownej jakości zakończone",
              closeWindow: "Zamknij okno",
              endReInspectionFailed:
                "Zadanie o ponownej jakości nie powiodło się, spróbuj ponownie później!",
            },
            detail: {
              name: "Szczegóły kontroli jakości",
              reviewName: "Szczegóły przeglądu",
              spotCheckName: "Szczegóły dotyczące pobierania próbek",
              audioText: "Nagrany tekst",
              callInfo: "Informacje o połączeniu",
              satisfactionEvaluation: "Ocena satysfakcji:",
              queueTime: "Czas spędzony w kolejce:",
              ringingTime: "Czas dzwonienia:",
              callTime: "Zadzwoń do wydarzeń:",
              hangUpIdentity: "Zadzwoń na imprezę rozłąkę:",
              location: "Dom:",
              source: "Źródło:",
              agent: "Obsługa klienta:",
              deviceType: "Typ urządzenia:",
              callType: "Rodzaj połączenia:",
              phoneNumber: "Numer przekaźnika:",
              task: "Zadania:",
              commentsTip: "Wprowadź uwagi dotyczące kontroli jakości",
              lastPage: "Poprzednia strona",
              nextPage: "Następna strona",
              score: "Ocena jakości",
              review: "Przegląd",
              file: "Archiwizacja",
              changeList: "Zmień rekord",
              fullMarks: "Pełne oceny",
              most: "Większość",
              saveReview: "Zgłosię do przeglądu",
              saveSpotCheck: "Kontrola pobierania próbek i składanie",
              point: "Punkt kontroli jakości",
              sampleAssignInspector: "Przydziel inspektora pobierania próbek",
              reviewAssignInspector: "Przypisywanie recenzenta",
              sampleStatus: "Status pobierania próbek",
              read: "Czytaj",
              user: "Użytkownik",
              confirmer: "Osoba potwierdzona",
              voiceDownloadError:
                "Pobieranie głosowe nie powiodło się, spróbuj ponownie później!",
              smartTags: "Inteligentna etykieta",
              interactiveTip:
                "Użyj rozpoznawania interakcji, aby wykryć korzystanie z FAQ obsługi klienta",
              interactiveIsEmptyTip:
                "Nie wykryto interaktywnych treści rozpoznawania",
              businessRecords: "Dokumentacja biznesowa",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat:
                "Analiza Enterprise WeChat Group",
            },
          },
        },
      },
      cards: {
        health: "Jakość zdrowia inspekcji",
        accuracy: "Wskaźnik zatwierdzenia odkrycia",
        sessionsTotal: "Łączna liczba sesji",
        enable: "Włącz",
        disable: "Wyłącz",
        none: "Nie",
        call: "Zadzwoń",
        dialogue: "Dialog",
        taobao: "E-commerce",
        wecom: "Sesja",
        ticket: "Zamówienie na pracę",
      },
      component: {
        backup: "(Kopia zapasowa)",
        taskSaveAsSucceeded: "Zadanie zapisane jako udane",
        copyContent: "Skopiuj zawartość",
        essentialInformation: "Podstawowe informacje",
        ruleConfiguration: "Konfiguracja reguły",
        basicInformationQualityInspector:
          "Podstawowe informacje-Inspektor Jakości",
        qualityInspectionRating: "Ocena jakości kontroli",
        roleConfiguration: "Konfiguracja roli",
        autoAddConfiguration: "Automatycznie dodaj konfigurację",
        autoPushConfiguration: "Automatyczna konfiguracja push",
        automaticSamplingConfiguration: "Automatyczna konfiguracja próbkowania",
        automaticAllocationConfiguration: "Automatyczna konfiguracja alokacji",
        businessConfiguration: "Konfiguracja biznesowa",
        caseBaseConfiguration: "Biblioteka przypadków",
      },
      template: {
        cancel: "Anuluj",
        complete: "Kompletny",
        sort: "Sortuj",
        ruleApproval: "Zatwierdzenie zasady",
        enterpriseMicroConversationAnalysisTask:
          "Zadanie analizy mikro-sesji przedsiębiorstwa",
        enterpriseAndMicroVoiceAnalysisTask:
          "Zadanie analizy mikro głosu przedsiębiorstwa",
        saveTaskAs: "Zapisz zadanie",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "Szczegóły reguły" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "Modyfikacja się powiodła",
            automaticApproval: "Automatyczne zatwierdzanie",
          },
          pageHeaderExtra: {
            modificationSucceeded: "Modyfikacja się powiodła",
            test: "Test",
            formal: "Formalne",
            adopt: "Przez",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "Czy na pewno odrzucisz",
              pleaseEnterTheRejectionReason: "Proszę podać powód odrzucenia",
              reject: "Odrzucony",
            },
          },
          table: {
            index: {
              modificationSucceeded: "Modyfikacja się powiodła",
              taskName: "Nazwa zadania",
              dataSource: "Źródło danych",
              qualityInspectionType: "Rodzaj kontroli jakości",
              state: "Status",
              updateTime: "Czas aktualizacji",
              updatedBy: "Zaktualizuj osobę",
              approver: "Zatwierdzenie",
              reasonForRejection: "Powód odrzucenia",
              operation: "Operacja",
              details: "Szczegóły",
              adopt: "Przez",
              whole: "Wszystkie",
              approvalStatus: "Status zatwierdzenia",
            },
          },
        },
      },
    },
    dashboard: {
      title: "Przegląd",
      hi: "Cześć,",
      standardEdition: "Edycja standardowa",
      call: "Bilans kontroli jakości głosu",
      text: "Okres ważności kontroli jakości tekstu",
      collectionTasks: "Zebrane zadania kontroli jakości",
      noCollection: "Brak zbierania zadań kontroli jakości,",
      goToCollecte: "Przejdź do kolekcji",
      yesterdayOverView: "Wczorajsze statystyki biznesowe",
      yesterdayAgent: "Wczorajsza lista obsługi klienta",
      yesterdayInspWork: "Wczorajsza sytuacja pracy inspektora jakości",
      operationalStatistics: {
        title: "Statystyki biznesowe",
        qualityTesting: "Inspekcja jakości",
        spotCheck: "Inspekcja losowa",
        appeal: "Skarga",
        chartTitles: {
          qualityTestingTrend: "Trend wolumenu kontroli jakości",
          qualityTestingAnalysis: "Zadanie kontroli jakości",
          spotCheckTrend: "Trend próbkowania",
          spotCheckAnalysis: "Sytuacja zadania próbkowania",
          appealTrend: "Trendy w zakresie skarg",
          appealAnalysis: "Status mandatu odwoławczego",
        },
        titles: {
          qualityDataTend: "Dystrybucja danych kontroli jakości",
          qualityCover: "Zakres kontroli jakości",
          qualityAverageTime: "Czas oczekiwania na kontrolę średniej jakości",
          qualityCoverTend: "Trend w zakresie kontroli jakości",
          handCover: "Obsługa ręczna",
          checkCover: "Zakres pobierania próbek",
          resAverageTime: "Średni czas przetwarzania",
          handTend: "Trend ręcznego przetwarzania",
          dataTend: "Dystrybucja zadań danych",
        },
        qualityAverage: "Średni wynik kontroli jakości",
        scoreTrend: "Trend punktacji kontroli jakości",
        qualityAverageTime: "Czas oczekiwania na kontrolę średniej jakości",
        callCoverage: "Zakres kontroli jakości głosu",
        textCoverage: "Zakres kontroli jakości tekstu",
        checkCount: "Ilość danych próbkowania",
        siphonCount: "Wyodrębnij objętość danych",
        reviewCount: "Przegląd wolumenu danych",
        appealCount: "Wolumen danych reklamacji",
        checkRate: "Procent inspekcji wyrywkowej",
        reviewRate: "Procent przeglądu skarg",
        PieTitle: {
          totalCount: "Łączna liczba rozmów",
          totalCall: "Liczba połączeń głosowych",
          totalIm: "Liczba rozmów tekstowych",
          inspectionCall: "Ilość kontroli jakości głosu",
          inspectionIm: "Ilość kontroli jakości tekstu",
          checkCall: "Ilość próbkowania głosu",
          unCheckCall: "Głos nie jest próbkowany",
          checkIm: "Ilość próbkowania tekstu",
          unCheckIm: "Niepróbkowane ilości tekstu",
          reviewCall: "Głośność recenzji głosowej",
          reviewIm: "Ilość przeglądu tekstu",
          unReviewCall: "Niesprawdzona ilość głosu",
          unReviewIm: "Ilość niesprawdzonego tekstu",
          unInspectionCall: "Ilość głosu nie została sprawdzona",
          unInspectionIm: "Niekontrolowana ilość tekstu",
        },
        check_rate: "Zakres pobierania próbek",
        voice_check_rate: "Pokrycie próbkowania głosu",
        text_check_rate: "Pokrycie próbkowania tekstu",
        sample_execute_time: "Średni czas przetwarzania próbek",
        review_execute_time: "Średni czas przetwarzania skarg",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "Zasady kontroli jakości Kanban",
          agent: "Lista rankingowa obsługi klienta",
        },
        tableTips: {
          conditions:
            "<Div> sortuje całkowitą liczbę rozmów, które naruszają i są zgodne z zasadami kontroli jakości. <Br/>\nPozycja kontroli jakości: nazwa punktu kontroli jakości <br />\nŁączna liczba nielegalnych rozmów: całkowita liczba nielegalnych rozmów na temat tego elementu kontroli jakości <br />\nOdsetek naruszeń: Odsetek naruszeń = całkowita liczba naruszeń/całkowita liczba naruszeń </div>",
          agent:
            "<Div> Sortowanie średniej oceny agentów <br />\nNazwa obsługi klienta: nazwa obsługi klienta <br />\nGrupa obsługi klienta: grupa obsługi klienta, w której znajduje się obsługa klienta (informacje o organizacji w informacji o obsłudze klienta)<br />\nŁączna liczba punktowanych rozmów: całkowita liczba rozmów dotyczących kontroli jakości obsługi klienta <br />\nŚredni wynik kontroli jakości: średni wynik kontroli jakości agenta (łączny wynik/całkowita liczba rozmów)</div>",
        },
        tips: {
          total:
            "Łączna liczba przepustek/rozmów wprowadzonych do zadania kontroli jakości",
          agentTotal:
            "Całkowita liczba agentów kontroli jakości, którzy weszli do zadania kontroli jakości",
          avg: "Średni wynik kontroli jakości",
          violation:
            "Suma, ile razy wszystkie elementy kontroli jakości z przejdą dialogu zostały trafione i naruszone",
          compliance:
            "Suma, ile razy elementy kontroli jakości zostały trafione w proces kontroli jakości poprzez dialog",
          check: "Całkowita liczba ręcznego pobierania próbek",
          appeal: "Łączna liczba złożonych skarg w łącznej liczbie rozmów",
          review:
            "Łączna liczba przesłanych recenzji w łącznej liczbie rozmów komunikacyjnych",
        },
        subTips: {
          violation:
            "Wskaźnik naruszeń: całkowita liczba naruszeń/całkowita liczba elementów kontroli jakości * 100%",
          compliance:
            "Wskaźnik zgodności: całkowita liczba czasów zgodności/całkowita liczba elementów kontroli jakości * 100%",
          check:
            "Zakres pobierania próbek: całkowite ręczne pobieranie próbek/całkowita kontrola jakości * 100%",
          appeal:
            "Odwołanie: całkowita liczba odwołań/całkowita liczba kontroli jakości * 100%",
          review:
            "Wskaźnik przeglądu: całkowita liczba przeglądów/całkowita liczba kontroli jakości * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "Ustawienia systemowe",
          systemName: "Nazwa systemu",
          speakerRecognize: "Identyfikacja głośnika",
          massage: "Powiadomienie o wiadomości",
          evaluate: "Sztuczna ocena poznawcza",
          evaluateTip:
            "Po otwarciu wybierz szybką kontrolę punktową w szczegółach kontroli jakości i szybką ocenę ręczną",
          speakerRecognizeTip:
            "Aby uzyskać dostęp do nagrywania rozmów przez stronę trzecią za pośrednictwem interfejsu API, należy określić, czy lewy kanał jest obsługą klienta, czy klientem.",
          speakerRecognizeTip2:
            "Nagrywanie jednokanałowe wykorzystuje rozpoznawanie tekstu i konfigurację roli do zdefiniowania roli agenta.",
          enabledImSendStatusFilter:
            "Filtrowanie wiadomości z inspekcji jakości tekstu",
          enabledImSendStatusFilterTips:
            "Treść wykrywania mowy operatora podczas kontroli jakości nie obejmuje wiadomości w statusie [Wycofane], [Nie udało się wysłać] i [Nieprzeczytana wiadomość offline]",
          lang: "Ustawienia języka systemowego",
          zh: "Chiński uproszczony",
          en: "English",
          tw: "Tradycyjny chiński",
        },
        template: {
          largeModelSettings: "Duże ustawienia modelu",
          indonesian: "Indonezyjski",
          vietnamese: "Wietnamski",
          portuguese: "Portugalski",
          malay: "Malajski",
          french: "Francuski",
          spanish: "Hiszpański",
          japanese: "Japońskie",
          thai: "Tajskie",
          blockSystemSendingMessages: "Mask System Wyślij wiadomość",
          textDataDesensitization: "Desensytyzacja danych tekstowych",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "Po włączeniu przełącznika wyświetlana jest lista rozwijana pola desensytyzacji (obsługiwana jest multi-selekcja). Pola desensytyzacji obejmują imię i nazwisko osoby, numer telefonu komórkowego i adres.",
          desensitizingField: "Pole odczulone",
          name: "Imię i nazwisko osoby",
          cellphoneNumber: "Numer telefonu komórkowego",
          address: "Adres",
          dataCleaning: "Oczyszczanie danych",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "Po ustawieniu konwersacja kontroli jakości i dane sesji WeChat dla przedsiębiorstw będą regularnie czyszczone i nie można ich odzyskać. Proszę działać ostrożnie.",
          newCleaningRules: "Nowa zasada czyszczenia",
          qualityInspectionRuleApproval: "Zatwierdzenie zasad kontroli jakości",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "Po otwarciu modyfikacja reguły kontroli jakości musi zostać zatwierdzona, zanim wejdzie w życie.",
          maskOfflineMessageUnread: "Maska nieprzeczytanej wiadomości offline",
          privacyNumberProtection: "Ochrona numeru prywatności",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            "Po otwarciu znieczulić pole „ telefonu klienta ”podczas rozmowy i wyświetlić je jako 1881 * * * * * * * * * *",
          shieldingWithdrawn: "Maska została wycofana",
          maskSendingFailed: "Wysyłanie maski nie powiodło się",
        },
        component: {
          conversation: "Zadzwoń",
          dialogue: "Dialog",
          weCom: "WeChat dla przedsiębiorstw",
          day: "Dzień",
          week: "Tydzień",
          month: "Miesiąc",
          year: "Rok",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "Zakres czyszczenia",
            dataCleaningTimeSetting: "Ustawienia czasu oczyszczania danych",
            afterCleanUpTheData: "Po wyczyszczeniu danych",
            dataAfterComplaintCompletion: "Dane po skardze",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "Udana egzekucja",
          saveSuccessful: "Oszczędzaj pomyślnie",
          speechRecognition: "Rozpoznawanie mowy",
          reExecuteErrorCorrection: "Ponownie wykonaj korekcję błędów",
          confirmExecution: "Potwierdź wykonanie",
          voiceDetection: "Wykrywanie głosu",
          noiseDetectionPowerLevel: "Wykrywanie hałasu powerLevel ≤",
          pleaseEnterTheThreshold: "Proszę wprowadzić próg",
          volumeDetectionPowerLevel: "Wykrywanie głośności powerLevel ≥",
          reset: "Zresetuj",
          preserve: "Zapisz",
        },
        coachTemplate: {
          french: "Francuski",
          spanish: "Hiszpański",
          japanese: "Japońskie",
          thai: "Tajskie",
          loginPopupWindow: "Wyskakujące okno logowania",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "Po otwarciu pojawi się wyskakujące okienko podczas wchodzenia do inteligentnego systemu sparingowego.",
          title: "Tytuł",
          text: "Tekst",
          checkBoxContent: "Sprawdź zawartość skrzynki",
          informedConsentForm: "Formularz świadomej zgody",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "Aby pomóc Ci poprawić umiejętności komunikacji i mowy oraz zastosować profesjonalną wiedzę, umożliwiając ćwiczenie w dowolnym miejscu i czasie oraz otrzymywanie informacji zwrotnych w czasie rzeczywistym, będziemy zbierać od Ciebie następujące informacje za pośrednictwem tej platformy:",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "Przeczytałem i w pełni zrozumiałem ten formularz zgody i uroczyście zgadzam się na obchodzenie się z moimi wrażliwymi danymi osobowymi zgodnie z celami i sposobem określonym w niniejszym formularzu zgody.",
        },
      },
      systemIntegration: {
        title: "Ustawienia systemowe",
        ccIntegrationTips:
          "Intelligent Quality Inspection Docking Udesk Call Center Opis dokumentu",
        unauthorized: "Nieautoryzowane",
        authorized: "Autoryzowany",
        udeskDomain: "Nazwa domeny systemu obsługi klienta",
        udeskToken: "Klucz systemu agenta",
        udeskAdminEmail: "Konto administratora systemu obsługi klienta",
        password: "Hasło do konta administratora",
        applyAuth: "Autoryzacja",
        cancelAuth: "Anuluj autoryzację",
        reApplyAuth: "Ponowna autoryzacja",
        keyTips:
          "Zarządzanie tłem systemu obsługi klienta-token uwierzytelniania API w logowania pojedynczym",
      },
      customerField: {
        title: "Pole klienta",
        table: {
          propName: "Nazwa pola",
          propDesc: "Opis pola",
          valueType: "Typ pola",
          propType: "Metoda tworzenia",
          createTime: "Czas tworzenia",
          canNotEdit: "Pola systemowe nie obsługują edycji",
        },
        edit: "Edytuj pole",
        new: "Nowe pole",
      },
      wechatDocking: {
        title: "Mikro dokowanie przedsiębiorstwa",
        formLabels: {
          companyId: "Identyfikator przedsiębiorstwa",
          communicationKey: "Klucz komunikacyjny",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "Adres URL wywołania zwrotne zdarzenia",
          applicationName: "Nazwa aplikacji",
          applicationId: "Identyfikator aplikacji sieci Web",
          applicationSecret: "Zastosowanie tajnego",
        },
        formTitle: {
          initialForm: "Wstępna konfiguracja",
          synchronizeForm: "Synchronizacja książki adresowej",
          settingForm: "Ustawienia aplikacji",
        },
        qrCodeUrlTip:
          "Poproś administratora o zeskanowanie poniższego dwuwymiarowego kodu w celu powiązania aplikacji programistów.",
      },
      taobao: {
        formLabels: {
          name: "Nazwa sklepu",
          account: "Numer konta",
          password: "Hasło",
          confirm: "Potwierdź hasło",
          authorization: "Autoryzacja",
          switchOn: "Otwarte",
          switchOff: "Zamknij",
        },
        tableLabels: {
          name: "Nazwa sklepu",
          account: "Numer konta",
          authorization: "Autoryzacja",
          updateUser: "Ostatnio zaktualizowana osoba",
          updateTime: "Czas ostatniej aktualizacji",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Nazwa domeny wtórnej Weifeng",
          secretKeyTip:
            "Tajny klucz najemcy jest uzyskiwany w miejscu dokowania systemu tła systemu Weifeng.",
        },
      },
      user: {
        index: {
          name: "Zarządzanie pracownikami",
          existingUsers: "Są już pracownicy.",
          deleteSuccess: "Pomyślnie usunięte",
          deleteFailed: "Usuń nie powiodło się",
        },
        detail: {
          accountPrompt: "Wprowadź skrzynkę pocztową",
          resetPassword: "Zresetuj hasło",
          passwordPrompt: "Hasło (6-14 kombinacji liter i cyfr)",
          originPasswordPrompt: "Wprowadź hasło początkowe",
          resumePasswordPrompt: "Wprowadź ponownie swoje hasło",
          passwordFormWrong: "Wprowadź kombinację 6-14 liter i cyfr!",
          resumePasswordWrong: "Dwa hasła są niespójne!",
          suppertImageType: "Wsparcie jpg, gif, png, maksymalnie 500Kb",
          namePrompt: "Wpisz nazwę",
          nickNamePrompt: "Wpisz pseudonim",
          telephonePrompt: "Wprowadź numer telefonu",
          roleSelectedPrompt: "Proszę wybrać rolę",
          agentIdPrompt: "Wprowadź numer zadania",
          disabled: "Wyłącz",
          enabled: "Włącz",
          reloadBowerInfo:
            "Zmiany informacji o użytkowniku, trzeba odświeżyć przeglądarkę?",
          saveUserError:
            "Nie udało się zapisać informacji o użytkowniku, spróbuj ponownie później",
          resetPasswordSuccess: "Resetowanie hasła powiodło się",
          resetPasswordFailed:
            "Resetowanie hasła nie powiodło się, spróbuj ponownie później",
        },
      },
      userGroups: {
        index: {
          name: "Zarządzanie grupą pracowników",
          existingUserGroups: "Istniejąca grupa pracowników",
        },
        detail: {},
      },
      role: {
        index: { name: "Zarządzanie Role", existingRoles: "Istniejące role" },
        edit: {
          editRole: "Edytuj rolę",
          createRole: "Stwórz rolę",
          basicInfo: "Podstawowe informacje",
          taskPermissions: "Uprawnienia do zadań",
          existingTasks: "Już zadania",
        },
      },
      anomalousData: {
        title: "Nieprawidłowe dane",
        list: {
          selectHolder: "Proszę wybrać źródło danych",
          businessTime: "Czas biznesowy",
          businessId: "Id biznesowy",
          export: "Eksport",
        },
      },
      templateComponent: {
        list: {
          title: "Rynek szablonów",
          name: "Nazwa szablonu",
          searchHolder: "Wprowadź treść zapytania",
          update: "Aktualizacja",
          sort: "Sortuj",
          moduleType: "Typ szablonu",
          moduleSourceType: "Obowiązujący typ",
          fetchNum: "Liczba nabytych razy",
          newest: "Ostatnio zaktualizowane",
          usedMost: "Zdobądź jak najwięcej",
          call: "Zadzwoń",
          dialogue: "Dialog",
          waiting: "Do przeglądu",
          cancel: "Unpublish",
          success: "Zatwierdzone i opublikowane",
          failed: "Odrzucenie audytu",
          no: "Niemożliwe do uaktualnienia",
          ok: "Może uaktualnić",
          all: "Wszystkie",
          delete: "Pomyślnie usunięte",
          downOk: "Pomyślnie usunięte",
          withdraw: "Wycofanie się powiodło",
          updateOk: "Uaktualnienie zakończyło się sukcesem",
          get: "Zdobądź",
          width: "Wycofanie",
          getOk: "Sukces nabycia",
          orgName: "Organizacja",
          nowVersion: "Aktualna wersja",
          lastedVersion: "Najnowsza wersja",
          appStatus: "Status aplikacji",
          actions: "Operacja",
          down: "Z półki",
          updateTop: "Uaktualnienie",
          check: "Widok",
          deleteWhen: "Potwierdzić usunięcie?",
          yes: "Tak",
          noo: "Nie",
          deleteOk: "Usuń",
          permissible: "Dozwolone",
          noPermissible: "Niedozwolone",
          openUp: "Otwarte",
          openDown: "Nie otwarte",
          task: "Zadanie kontroli jakości",
          point: "Punkt kontroli jakości",
          tag: "Etykieta semantyczna",
          muti: "Wiele elementów",
          inter: "Jedno pytanie i jedna odpowiedź",
          info: "Podmiot informacyjny",
          concept: "Słowa koncepcyjne",
          detail: "Szczegóły modułu",
          infomation: "Podstawowe informacje",
          detailConfig: "Szczegółowa konfiguracja",
          twoEdit: "Edycja wtórna",
          mustUpdate: "Uaktualnij teraz",
          descition: "Opis szablonu",
          descitionSize: "Opis szablonu nie może przekroczyć 250 słów",
          stencilsDetail: "Szczegóły szablonu",
          updatedRecords: "Aktualizuj rekord",
          version: "Numer wersji",
          updateDescription: "Opis aktualizacji",
          myModule: "Mój szablon",
          addModule: "Nowy moduł",
          publishVersion: "Wersja Release",
          earliestVersion: "Najwcześniejsza wersja",
          edit: "Edytuj",
          add: "Nowy",
          configNo: "Szczegóły konfiguracji modułu nie mogą być puste",
          updateNo: "Opis aktualizacji nie może być pusty",
          editSuccess: "Modyfikacja się powiodła",
          saveSuccess: "Oszczędzaj pomyślnie",
          newer: "Aktualizacja",
          sumbit: "Przedłożenie",
          cancelName: "Anuluj",
          nameRequired: "Nazwa modułu jest polem wymaganym",
          nameSize: "Nazwa modułu nie może przekraczać 16 znaków",
          publishStatus: "Opublikuj status",
          submitUpdate: "Prześlij aktualizację",
          updateVersion: "Aktualizacja wersji",
          updateExplain: "Instrukcje aktualizacji",
        },
      },
      customDataSource: {
        amazonEcommerce: "Amazon e-commerce",
        youtubeVideo: "Wideo na Youtube",
        amazon: {
          component: {
            queuing: "Kolejkowanie",
            notStarted: "Nie rozpoczęto",
            inProgress: "W toku",
            success: "Sukces",
            failed: "Nie powiodło się",
            successfullyCreated: "Pomyślnie stworzony",
            editedSuccessfully: "Edytowane pomyślnie",
            operationSucceeded: "Operacja powiodła się",
            deletedSuccessfully: "Pomyślnie usunięte",
            link: "Link",
            grabState: "Chwyć stan",
            updateTime: "Czas aktualizacji",
            grab: "Chwyć",
            edit: "Edytuj",
          },
          template: {
            amazonEcommerce: "Amazon e-commerce",
            thisProduct: "Ten produkt",
            competition: "Konkurs",
            new: "Nowy",
            edit: "Edytuj",
            link: "Link",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "Wideo na Youtube",
            thisProduct: "Ten produkt",
            competition: "Konkurs",
            new: "Nowy",
            edit: "Edytuj",
            link: "Link",
          },
          component: {
            queuing: "Kolejkowanie",
            notStarted: "Nie rozpoczęto",
            inProgress: "W toku",
            success: "Sukces",
            failed: "Nie powiodło się",
            successfullyCreated: "Pomyślnie stworzony",
            editedSuccessfully: "Edytowane pomyślnie",
            operationSucceeded: "Operacja powiodła się",
            deletedSuccessfully: "Pomyślnie usunięte",
            youTubeVideoLink: "Łącze wideo YouTube",
            grabState: "Chwyć stan",
            updateTime: "Czas aktualizacji",
            grab: "Chwyć",
            edit: "Edytuj",
          },
        },
      },
    },
    demo: {
      title: "Inteligentna kontrola jakości",
      template: "Wybierz szablon inspekcji jakości",
      radio: "Wybierz formularz nagrania",
      uploadAudio: "Prześlij nagrywanie",
      uploadAudioTip:
        "Przesłane nagranie obsługuje tylko formaty wav i mp3, a rozmiar nie przekracza 1G",
      uploadText: "Prześlij tekst",
      uploadTextTip:
        "Przesłany tekst obsługuje tylko formaty srt i txt, a rozmiar nie przekracza 10M. Po przesłaniu tekstu ASR nie będzie już używany do analizowania pliku nagrania.",
      uploadSuccess: "Udało się przesłać plik do nagrywania",
      uploadFailed: "Nie udało się przesłać pliku nagrywającego",
      startQualityInspection: "Rozpocznij inspekcję jakości",
      qualityInspectionStarted: "Kontrola jakości w toku",
      downloadText: "Tekst do pobrania",
      checking: "Postęp kontroli jakości",
      checkingTip:
        "Oczekuje się, że zajmie to kilka minut, prosimy o cierpliwość",
      noCheckContent: "Brak jeszcze wyników kontroli jakości",
      noTextDownload: "Nie można pobrać tekstu kontroli jakości!",
      pleaseCheckParams:
        "Sprawdź, czy wybrać szablon, typ głosu i przesłać plik nagrywający",
      inspectionFailed:
        "Kontrola jakości nie powiodła się, spróbuj ponownie później!",
    },
    wechatQa: {
      baseInfo: { title: "Podstawowe informacje" },
      conversationSetting: {
        title: "Ustawienia sesji",
        segmentationStrategy: "Strategia krojenia",
        rule: "Strategia",
        time: "Czas cięcia",
        daily: "Codziennie",
        passScore: "Kwalifikujący się wynik sesji",
        ruleLogic: "Logika wykonania",
      },
      message: {
        title: "Wydarzenia push",
        table: {
          index: "Numer seryjny",
          name: "Imię i nazwisko",
          type: "Tryb push",
          desc: "Opis",
          status: "Status",
        },
        subTitle: "Lista wydarzeń",
      },
      checkPoint: {
        title: "Model analizy",
        groups: "Grupowanie modeli",
        newClassification: "Nowa grupa",
        editClassification: "Edytuj grupowanie",
        benchmarkScore: "Wynik odniesienia",
        pointCount: "Wspólny model analizy w ramach obecnej klasyfikacji",
        openPointCount: "Liczba możliwych jest",
        closedPointCount: "Ilość dezaktywowana jest",
        scoreLowerLimit: "Minimalny wynik Niższy Limit",
        scoreUpperLimit: "Maksymalny limit w skali",
        classificationName: "Nazwa grupy",
        classificationNamePlaceHolder: "Wpisz nazwę grupy",
        classificationRemark: "Opis grupy",
        classificationRemarkPlaceHolder: "Wprowadź opis grupy",
      },
      chartRecord: {
        title: "Sesja WeChat dla przedsiębiorstw",
        table: {
          id: "Identyfikator sesji",
          type: "Rodzaj sesji",
          startTime: "Czas rozpoczęcia sesji",
          endTime: "Sesja Last End Time",
          groupId: "Identyfikator grupy",
          client: "Klient",
          customer: "Obsługa klienta",
          averageScore: "Średni wynik kontroli jakości",
        },
        detail: {
          title: "Szczegóły sesji Enterprise WeChat",
          inspectionInfo: "Analiza informacji",
          chartInfo: "Informacje o sesji",
          tasks: "Zadanie analizy",
          avgScore: "Średni wynik analizy",
          qualified: "Wykwalifikowany",
          unqualified: "Bez kwalifikacji",
          taskTitle: "Obowiązujący szablon analizy",
          info: "Analiza informacji w czasie rzeczywistym",
          today: "Dzisiejsze wiadomości",
          history: "Wiadomości historyczne",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "Wprowadź komentarz/pseudonim",
                pleaseEnterTheGroupChatName: "Wprowadź nazwę czatu grupowego",
              },
            },
          },
          template: { whole: "Wszystkie" },
        },
      },
      realTimeAnalysis: {
        title: "Analiza w czasie rzeczywistym",
        table: {
          name: "Nazwa sceny",
          count: "Liczba modeli analitycznych",
          baseScore: "Wynik odniesienia",
          passScore: "Kwalifikowany wynik",
          maxScore: "Maksymalny limit w skali",
          minScore: "Minimalny wynik Niższy Limit",
          status: "Status",
          actions: "Operacja",
        },
        detail: {
          title: "Analiza scenariuszy w czasie rzeczywistym",
          form: {
            name: "Nazwa sceny",
            prevCondition: "Warunki wyzwalania scenariusza",
            prevConditionToolTip:
              "Jeśli warunek jest pusty, scenariusz jest analizowany domyślnie",
            prevConditionError:
              "Warunki wyzwalające scenariusz nie mogą pokrywać się z modelem analizy",
            scoreInputPlaceholder: "Wprowadź wynik",
            baseScore: "Wynik odniesienia",
            status: "Status",
            passScore: "Kwalifikowany wynik",
            maxScore: "Maksymalny górny limit punktacji",
            minScore: "Dolna granica minimalnego wyniku",
            analysisModel: "Model analizy",
            message: "Powiadomienie o wyzwalania",
            pushType: "Tryb push",
            createAnalysisModel: "Dodaj model",
            scoreUpperLimitError:
              "Dolna granica minimalnego wyniku nie może być większa niż górna granica maksymalnego wyniku.",
            table: { name: "Nazwa modelu", rule: "Zasady", score: "Wynik" },
            pushConfigSwitchTip1: "Kiedy pojawia się scena",
            pushConfigSwitchTip2: "Czasy i powyżej, powiadomienia push",
          },
        },
      },
      smartCustomerTag: {
        title: "Inteligentne etykietowanie klienta",
        table: {
          name: "Nazwa reguły",
          tags: "Mikro etykieta klienta przedsiębiorstwa",
          status: "Włączony status",
          createUser: "Stwórca",
          createTime: "Czas tworzenia",
        },
        detail: {
          name: "Nazwa reguły",
          condition: "Ustal zasady",
          logic: "Logika",
          status: "Włączony status",
          tags: "Wybierz etykietę",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "Ilość oznaczona przez klienta" },
        },
        messageFiltering: {
          template: {
            edit: "Edytuj",
            messageContent: "Treść wiadomości",
            messageTypeID: "Identyfikator typu wiadomości",
            messageTypeDescription: "Opis typu wiadomości",
            messageFiltering: "Filtrowanie wiadomości",
            suitableForFilteringDataWithoutQualityInspection:
              "Nadaje się do filtrowania danych bez kontroli jakości",
            create: "Stwórz",
            messageDescription: "Opis wiadomości",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "Czas rozmów",
          sessionType: "Rodzaj sesji",
          whole: "Wszystkie",
          staff: "Pracownik",
          customer: "Klient",
          callKeywords: "Zadzwoń do kluczowych słów",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "Dane dotyczące połączeń głosowych dla przedsiębiorstw",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "Archiwum sesji",
        voiceCall: "Połączenie głosowe",
        noMoreVoiceCalls: "Nigdy więcej połączeń głosowych",
        enterpriseWechatVoiceCall: "Połączenie głosowe Enterprise WeChat",
        enterpriseWechatConversationDetails:
          "Szczegóły sesji Enterprise WeChat",
        previous: "Poprzedni artykuł",
        next: "Następny artykuł",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "Zadanie analizy mikro-sesji przedsiębiorstwa",
          enterpriseAndMicroVoiceAnalysisTask:
            "Zadanie analizy mikro głosu przedsiębiorstwa",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat: "Analiza Enterprise WeChat Group",
      },
      wechatQaSettingEntry: { messageFiltering: "Filtrowanie wiadomości" },
    },
    businessAnalysis: {
      keyword: "Nazwa raportu",
      createUser: "Stwórca",
      createTime: "Czas tworzenia",
      updateTime: "Czas aktualizacji",
    },
    semanticIntelligence: {
      applicationMessageType: "Typ komunikatu aplikacji",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "Proszę udoskonalić konfigurację",
              generatePromptLanguage: "Prompt wygenerowany",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "Zgodność",
              gradedEvaluation: "Ocena stopnia",
            },
          },
          training: {
            index: {
              pleaseEnter: "Proszę wejść",
              pleaseGeneratePrompt: "Proszę wygenerować monit",
              pleaseSelectADialogue: "Proszę wybrać dialog",
              training: "Szkolenie",
              dataType: "Typ danych:",
              voiceCall: "Połączenie głosowe",
              textDialogue: "Dialog tekstowy",
              generate: "Generuj",
              qualityInspectionResults: "Wyniki kontroli jakości:",
              hit: "Uderzenie:",
            },
          },
        },
        detail: {
          index: {
            modelName: "Nazwa modelu",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "Wprowadź nazwę modelu, na przykład, czy agent nie ma żadnego działania.",
            modelDescription: "Opis modelu",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "Możesz szczegółowo opisać, co musisz przetestować, co jest łatwe do zrozumienia dla modelu. Na przykład, czy obsługa klienta nie działa, to znaczy obsługa klienta odpowiedziała na pytanie klienta, ale pytanie klienta nie zostało rozpatrzone lub treść odpowiedzi nie ma nic wspólnego z odpowiedzią klienta.",
            validity: "Skuteczność",
            effective: "Skuteczne",
            invalid: "Nieważne",
            type: "Rodzaj",
            hintLanguage: "Prompt",
            editIntelligentModel: "Edytuj inteligentny model",
            createANewIntelligentModel: "Nowy inteligentny model",
          },
        },
        index: {
          modelName: "Nazwa modelu",
          modelDescription: "Opis modelu",
          type: "Rodzaj",
          whetherItMeetsTheRequirements: "Zgodność",
          gradedEvaluation: "Ocena stopnia",
          validity: "Skuteczność",
          intelligentModel: "Inteligentny model",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "Ma zastosowanie do subiektywnej kontroli jakości (np. Czy podejście do kontroli jakości jest entuzjastyczne i czy usługa jest cierpliwa)",
        },
      },
      clusterAnalysis: "Analiza klastrów",
      speechMining: "Wydobywanie słów",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "Jednostki informacyjne można importować w partiach za pośrednictwem interfejsu API. Po zapisaniu nazwy interfejsu API nie można jej edytować.",
        },
        component: { nothing: "Brak" },
      },
      knowledgeBase: {
        index: {
          classification: "Klasyfikacja",
          import: "Import",
          importKnowledgeBase: "Importuj bazę wiedzy",
          moveKnowledgePointsInBatch: "Punkty wiedzy o przeniesieniu partii",
          moveTo: "Przenieś:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "Wybierz punkty wiedzy do przeniesienia w partiach",
        },
        detail: {
          index: {
            intrasentence: "W ramach zdania",
            afterProblem: "Po problemie",
          },
        },
      },
      semanticTags: { component: { nothing: "Brak" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "Wrażliwe słowa",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "Wrażliwe słowa: wrażliwe słowa można skonfigurować za pomocą słów związanych z biznesem, używanych do wykrywania wrażliwych słów w chmurze opinii publicznej",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "Nowe wrażliwe słowo",
            editSensitiveWords: "Edytuj wrażliwe słowa",
            sensitiveWords: "Wrażliwe słowa",
          },
          template: {
            sensitiveWords: "Wrażliwe słowa",
            sensitiveWordsCannotBeEmpty: "Wrażliwe słowo nie może być puste",
            pleaseEnterSensitiveWordsSeparatedBy:
              "Proszę wpisać wrażliwe słowo, wiele wrażliwych słów w środku ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "Proszę wybrać scenariusz aplikacji",
            keyWord: "Słowa kluczowe",
            pleaseEnterKeywords: "Wpisz słowo kluczowe",
            keywordLengthWithinCharacters:
              "Długość słowa kluczowego w granicach 40 znaków",
            dataSources: "Źródło danych",
            pleaseSelectADataSource: "Proszę wybrać źródło danych",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "Obecne grupowanie zostało zakończone. Ponowna edycja wygeneruje nowy fragment danych klastrowania i nie zastąpi ukończonych danych klastrowania. Potwierdź",
            savingSucceeded: "Oszczędzaj pomyślnie",
            clusteringStart: "Rozpoczyna się klastrowanie",
            clustering: "Grupowanie",
            edit: "Edytuj",
            newlyBuild: "Nowy",
            clusterName: "Nazwa klastra",
            pleaseEnterAName: "Wpisz nazwę",
            withinCharactersOfTheName: "Długość imienia w granicach 40 znaków",
            speaker: "Głośnik",
            pleaseSelectASpeaker: "Proszę wybrać głośnik",
            sessionDate: "Data sesji",
            pleaseEnterTheSessionDate: "Proszę wpisać datę sesji",
            selectUpToMonthOfHistoricalSessionData:
              "Wybierz maksymalnie 1 miesiąc historycznych danych sesji",
            automaticallyClusterDailyNewData:
              "Automatyczne grupowanie dziennych nowych danych",
            conversationChannel: "Kanał konwersacji",
            pleaseSelectAConversationChannel: "Wybierz kanał sesji",
            applicationScenario: "Scenariusze aplikacji",
            clusteringRules: "Zasady grupowania",
            incompleteClusteringRuleSettings:
              "Ustawienie reguły klastrowania jest niepełne",
            startClustering: "Rozpocznij klastrowanie",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask: "Wybierz zadanie jakości",
              beEqualTo: "Równe",
            },
          },
        },
        detail: {
          index: {
            theme: "Motyw",
            numberOfCorpora: "Liczba korpusu",
            corpusCoverage: "Pokrycie korpusów",
            clusterDetails: "Szczegóły klastrowania",
          },
          ranking: {
            hotWords: "Gorące słowa",
            numberOfCorpora: "Liczba korpusu",
            speaker: "Głośnik",
            sit: "Usiądź.",
            passenger: "Gość",
            hotSentence: "Gorące zdanie",
            corpusDetails: "Szczegóły Corpus",
          },
        },
        index: {
          delete: "Usuń",
          deletionSucceeded: "Pomyślnie usunięte",
          clusterName: "Nazwa klastra",
          speaker: "Głośnik",
          creationTime: "Czas tworzenia",
          state: "Status",
          operation: "Operacja",
          see: "Widok",
          edit: "Edytuj",
          clusterAnalysis: "Analiza klastrów",
          newCluster: "Nowy klaster",
          clusteringStatus: "Status klastrowania",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "Wpisz słowa kluczowe, aby wyszukać zastrzeżenia",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "Biblioteka mowy",
                recommendedScripts: "Polecane słowa",
                common: "Ogółem",
                strip: "Artykuł",
                scriptFavorites: "Ulubione słowa",
              },
              conditionPostfix: {
                detectRoles: "Wykryj rolę",
                detectionRange: "Zasięg wykrywania",
                sentence: "Zdanie",
              },
              customerFilter: {
                scriptFiltering: "Filtrowanie mowy",
                cancel: "Anuluj",
              },
              index: {
                viewDetails: "Zobacz szczegóły",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "Czy na pewno chcesz to porzucić? Nie można go przywrócić po porzuceniu.",
                abandonment: "Odrzuć",
                deletionSucceeded: "Pomyślnie usunięte",
                passenger: "Gość",
                sit: "Usiądź.",
                adopted: "Przyjęty",
                determine: "Określ",
                cancel: "Anuluj",
                adopt: "Adopcja",
              },
              pageRightFrom: {
                savingSucceeded: "Oszczędzaj pomyślnie",
                pleaseEnterTheScriptContent: "Wprowadź treść mowy",
                pleaseSelectAFavoriteGroup: "Wybierz ulubione grupowanie",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary:
                  "Biblioteka mowy w przypadku sprzeciwu",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "Proszę wybrać przemówienie",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Sprzeciw w przypadku słów ulubionych",
            },
            pageRightTable: {
              likeCancel: "Jak anulowane",
              likeSuccessfully: "Lubię sukces.",
              adoptScripts: "Przyjmij mowę",
              operation: "Operacja",
              viewConversationDetails: "Wyświetl szczegóły rozmowy",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Biblioteka rozmów o punktach sprzedaży produktów",
              },
              pageRightTable: { keyPointsOfScript: "Kluczowe punkty mowy" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites:
                "Sprzeciw w przypadku słów ulubionych",
              newGroup: "Nowa grupa",
              editGroup: "Edytuj grupowanie",
              addGroup: "Dodaj grupowanie",
              savingSucceeded: "Oszczędzaj pomyślnie",
              groupName: "Nazwa grupy",
              pleaseEnterTheGroupName: "Wpisz nazwę grupy",
              groupNameWithinCharacters:
                "Długość nazwy grupy w granicach 20 znaków",
              deletionSucceeded: "Pomyślnie usunięte",
            },
            pageRightTable: {
              configDrawer: {
                index: {
                  scriptRecommendationSettings: "Zalecane ustawienia mowy",
                },
                rightForm: {
                  savingSucceeded: "Oszczędzaj pomyślnie",
                  intelligentRecommendation: "Inteligentna rekomendacja",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "Przyjęte doskonałe umiejętności mowy będą automatycznie wyświetlane na pasku bocznym sesji z podobnymi zastrzeżeniami, a pracownicy będą mogli bezpośrednio przeglądać zalecane umiejętności mowy do nauki na stronie szczegółów wznowienia sesji.",
                  objectionsIncludedByDefault:
                    "Sprzeciw uwzględniony domyślnie",
                  addRecommendationTriggerSemantics:
                    "Dodaj semantykę wyzwalania rekomendacji",
                  remindEmployeesToView:
                    "Przypomnij pracownikom o przeglądanie",
                  shareWithMoreEmployees:
                    "Podziel się z większą liczbą pracowników.",
                  allHands: "Cały personel",
                },
                smartToolbar: {
                  selectSemanticTag: "Wybierz etykietę semantyczną",
                },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "Eksportować wszystkie rekordy danych w ramach bieżącego grupowania?",
                newScript: "Nowa mowa",
                editingScript: "Edytuj mowę",
                savingSucceeded: "Oszczędzaj pomyślnie",
                objection: "Sprzeciw",
                pleaseEnterAScriptObjection:
                  "Proszę wpisać sprzeciw w przemówieniu",
                speechTechnique: "Słowa",
                pleaseEnterTheScriptContent: "Wprowadź treść mowy",
                grouping: "Grupowanie",
                pleaseSelectAFavoriteGroup: "Wybierz ulubione grupowanie",
                adoptScripts: "Przyjmij mowę",
                passenger: "Gość",
                sit: "Usiądź.",
                numberOfViews: "Liczba widoków",
                useful: "Przydatne",
                operation: "Operacja",
                edit: "Edytuj",
                delete: "Usuń",
                deletionSucceeded: "Pomyślnie usunięte",
                scriptRecommendationSettings: "Zalecane ustawienia mowy",
              },
            },
          },
        },
        index: {
          scriptLibrary: "Biblioteka mowy",
          scriptMining: "Wydobywanie słów",
          objectionHandlingScripts: "Obsługa sprzeciwu",
          productSellingPointScripts: "Punkty sprzedaży produktów",
        },
        sellingPoint: {
          index: { newCluster: "Nowy klaster" },
          components: {
            callDetailModal: {
              sessionDetails: "Szczegóły sesji",
              cancel: "Anuluj",
              determine: "Określ",
              choice: "Wybierz",
            },
            pageCenterTable: { scriptOverview: "Przegląd mowy" },
            pageLeftTable: {
              enterSearchKeywords: "Wpisz słowa kluczowe wyszukiwania",
              keyWord: "Słowa kluczowe",
            },
            pageRightTable: {
              index: {
                viewDetails: "Zobacz szczegóły",
                scriptLibrary: "Biblioteka mowy",
                scriptRefinement: "Rafinacja mowy",
                adoptScript: "Przyjmij mowę",
              },
              tagsEdit: { keyPointsOfScript: "Kluczowe punkty mowy" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "Biblioteka rozmów o punktach sprzedaży produktów",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "Domyślnie dołączone punkty sprzedaży",
                  },
                },
                index: {
                  keyWord: "Słowa kluczowe",
                  pleaseSelectAKeyword: "Wybierz słowo kluczowe",
                  keyPointsOfScript: "Kluczowe punkty mowy",
                  pleaseInputTheKeyPointsOfTheScript:
                    "Wprowadź kluczowe punkty mowy",
                  viewDetails: "Zobacz szczegóły",
                  pleaseChooseToAdoptScript: "Proszę wybrać przemówienie",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "Dopasowywanie logiki",
            nLPMatching: "Mecz NLP",
            strictlyMatchByWord: "Ścisłe dopasowanie za słowem",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "Nazwa typu wiadomości",
          messageTypeID: "Identyfikator typu wiadomości",
          messageTypeDescription: "Opis typu wiadomości",
          applicationMessageType: "Typ komunikatu aplikacji",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "Ma zastosowanie do dostępu do danych z różnych kanałów do kontroli jakości, które powinny być klasyfikowane zgodnie z identyfikacją kanału.",
        },
        detail: {
          index: {
            messageTypeName: "Nazwa typu wiadomości",
            pleaseEnterAMessageTypeName: "Wpisz nazwę typu wiadomości",
            messageTypeDescription: "Opis typu wiadomości",
            describeYourMessageTypeInDetail:
              "Opisz szczegółowo swój typ wiadomości",
            editMessageType: "Edytuj typ wiadomości",
            newMessageType: "Nowy typ wiadomości",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "Wybierz członka zespołu",
        existingFilter: "Istnieją już filtry.",
        pleaseSelect: "Proszę wybrać",
        filterCondition: "Warunki filtracji",
        areYouSureToDeleteTheCurrentFilter:
          "Czy na pewno chcesz usunąć bieżący filtr?",
        sessionDate: "Data sesji",
        staff: "Pracownik",
        customer: "Klient",
        pleaseEnterCustomerName: "Wprowadź nazwę klienta",
        keyWord: "Słowa kluczowe",
        whole: "Wszystkie",
        pleaseEnterSessionKeywords: "Proszę wpisać słowa kluczowe sesji",
        sessionDuration: "Czas trwania sesji",
        sessionLabel: "Scenariusze sesji",
        pleaseSelectASessionLabel: "Proszę wybrać scenariusz sesji",
        commentTopic: "Komentarz temat",
        pleaseSelectACommentTopic: "Wybierz temat komentarza",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "Czy na pewno chcesz nadpisać bieżący filtr?",
        overlayFilter: "Filtr osłonowy",
        NewFilter: "Nowy filtr",
        newFilter: "Nowy filtr",
        filterName: "Nazwa filtra",
        pleaseEnter: "Proszę wejść",
        allCall: "Wszystkie połączenia",
        percentage: "Procent",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "Możesz wyszukiwać wiele słów kluczowych. Użyj przecinków (w języku angielskim i chińskim), aby oddzielić LUB i wstrzymać, aby oddzielić i OR. Na przykład:",
          callRecordID: "Identyfikator dziennika połączeń",
          pleaseFillInTheCallRecordID:
            "Proszę wypełnić identyfikator zapisu rozmowy",
          minute: "Minuty",
          second: "Sekundy",
          whole: "Wszystkie",
          callRecording: "Nagrywanie rozmów",
          enterpriseWechat: "WeChat dla przedsiębiorstw",
          conversationChannel: "Kanał konwersacji",
          pleaseSelectASessionChannel: "Wybierz kanał sesji",
          learningStatus: "Stan uczenia się",
          pleaseSelectLearningStatus: "Proszę wybrać status uczenia się",
          notLearned: "Nie nauczyłem się",
          learned: "Uczony",
          Minutes: "Minuty",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "Stan filtra jest pusty, nowy filtr nie jest obsługiwany!",
          filterAddedSuccessfully: "Filtr dodany pomyślnie!",
          failedToAddFilter: "Nie udało się dodać filtra!",
          filterDeletedSuccessfully: "Filtr został pomyślnie usunięty!",
          filterDeletionFailed: "Usuń filtr nie powiodło się!",
          filterOverwriteSucceeded:
            "Nadmiatanie filtra zakończyło się sukcesem!",
          filterOverwriteFailed: "Nadmiatanie filtra nie powiodło się!",
          theme: "Motyw",
          pleaseSelectATheme: "Wybierz motyw",
          keyEvents: "Kluczowe wydarzenia",
          pleaseSelectAKeyEvent: "Wybierz zdarzenie kluczowe",
        },
        filterItem: {
          startRetesting: "Rozpocznij ponowne wykrywanie",
          time: "Czas",
          talkTime: "Czas trwania połączenia",
          keyEvents: "Kluczowe wydarzenia",
          totalImplementationRateOfSupervisionItems:
            "Ogólny wskaźnik realizacji elementów nadzoru",
          sort: "Sortuj:",
          seeMore: "Wyświetl więcej",
        },
        filterStatistics: {
          conversation: "Zadzwoń",
          weChat: "WeChat",
          quantity: "Ilość",
        },
      },
      caseBase: {
        index: {
          copyTo: "Kopiowanie",
          pleaseSelectTheLocationToMove:
            "Wybierz lokalizację do przeniesienia!",
          folderCopiedSuccessfully: "Kopia folderu powiodła się!",
          cancelCollectionSuccessfully: "Anuluj kolekcję pomyślnie!",
          collectionSucceeded: "Sukces kolekcji!",
          starCaseBase: "Biblioteka obudowy gwiazdy",
          move: "Przesuń",
          rename: "Zmiana nazwy",
          delete: "Usuń",
          folderMovedSuccessfully: "Folder przeniósł się pomyślnie!",
          fileMovedSuccessfully: "Plik został pomyślnie przeniesiony!",
          fileMoveFailed: "Ruch pliku nie powiódł się!",
          folderAddedSuccessfully: "Dodaj folder pomyślnie!",
          folderRenameSucceeded: "Nazwa folderu została pomyślnie zmieniona!",
          fileRenameSucceeded: "Nazwa pliku została pomyślnie zmieniona!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Po usunięciu folderu nie będzie można go odzyskać. Czy na pewno go usuniesz?",
          deleteSucceeded: "Usunięty pomyślnie!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "Po usunięciu pliku nie będzie można go odzyskać. Czy na pewno go usuniesz?",
          share: "Ogółem",
          folders: "Teczka,",
          calls: "Połączenie",
          newFolder: "Nowy folder",
          modifyFolder: "Zmodyfikuj folder",
          title: "Tytuł",
          pleaseEnter: "Proszę wejść",
          selectMoveLocation: "Wybierz lokalizację Move",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "Przypomnienie o nauce",
          subscriptionDynamics: "Dynamika subskrypcji",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "Sesja, którą subskrybujesz (ID",
            thereAreDynamicUpdates: "Jest dynamiczna aktualizacja",
            newCommentsReplies: "Dodaj komentarze/odpowiedzi",
            strip: "Artykuł",
            newHelp: "Dodaj pomoc",
            goAndWatch: "Idź do gapiów",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "Odpowiedź na moje uwagi",
            replySucceeded: "Odpowiedź udana!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "@ Me w komentarzach.",
            replySucceeded: "Odpowiedź udana!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "Zadzwoń do Ciebie, abyś się podzielił",
            shareCasesForYou: "Podziel się sprawą dla Ciebie.",
          },
        },
        study: {
          index: {
            learned: "Uczony",
            ignored: "Ignorowane",
            study: "Uczenie się",
            ignore: "Ignoruj",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "Import się powiódł",
          account: "Numer konta",
          password: "Hasło",
          cover: "Osłona",
          skip: "Pomiń",
          import: "Import",
          importEmployees: "Import pracownika",
          importPrompt: "Wskazówki dotyczące importu",
          confirm: "Określ",
          theDataContainsDuplicateAccountsOrNames:
            "W tym wpisie danych istnieje zduplikowane konto lub nazwa",
          allCoverage: "Pokrycie wszystkich",
          skipAll: "Pomiń wszystkie",
          headPortrait: "Avatar",
          fullName: "Imię i nazwisko",
          nickname: "Pseudonim",
          excellentStaff: "Doskonały personel",
          yes: "Tak",
          no: "Nie",
          department: "Departament",
          accountNumberemail: "Numer konta (e-mail)",
          telephone: "Telefon",
          jobNumber: "Praca nr.",
          role: "Rola",
          pleaseEnterANameOrPhoneSearch:
            "Wprowadź adres e-mail, imię i nazwisko lub wyszukiwanie telefonu",
          person: "Ludzie)",
          AddEmployee: "Dodaj pracownika",
        },
        edit: {
          jobNo: "Praca nr.",
          udeskCustomerServiceSystemIdentity:
            "Tożsamość systemu obsługi klienta Udesk",
          enterpriseWechatIdentity: "Enterprise WeChat Tożsamość",
          inputID: "Identyfikator wejściowy",
          newIdentityBinding: "Nowe wiązanie tożsamości",
          identityBinding: "Wiązanie tożsamości",
          avatarGreaterThan500kb: "Avatar większy niż 500Kb",
          uploadSuccessful: "Udało się przesłać",
          upload: "Prześlij",
          supportJPGGIFAndPNGUpTo500kb:
            "Wsparcie jpg, gif, png, maksymalnie 500Kb",
          yes: "Tak",
          no: "Nie",
          accountNumber: "Numer konta",
          pleaseEnterTheAccountNumber: "Wprowadź numer konta",
          initialPassword: "Hasło początkowe",
          pleaseInputAPassword: "Wprowadź hasło",
          confirmPassword: "Potwierdź hasło",
          thePasswordsEnteredTwiceAreDifferent:
            "Hasło wprowadzone dwukrotnie nie jest takie samo!",
          headPortrait: "Avatar",
          fullName: "Imię i nazwisko",
          pleaseEnterYourName: "Wpisz nazwę",
          nickname: "Pseudonim",
          department: "Departament",
          excellentStaff: "Doskonały personel",
          sessionIdentity: "Tożsamość sesji",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Rekord sesji tagowej należy do pracownika",
          telephone: "Telefon",
          jobNumber: "Praca nr.",
          role: "Rola",
          pleaseSelectARole: "Proszę wybrać rolę",
          preservation: "Zapisz",
          cancel: "Anuluj",
          addedSuccessfully: "Dodano pomyślnie!",
          modificationSucceeded: "Zmodyfikowany pomyślnie!",
          newEmployees: "Nowy pracownik",
          editEmployee: "Edytuj pracownika",
        },
        detail: {
          viewDetails: "Zobacz szczegóły",
          accountNumber: "Numer konta",
          headPortrait: "Avatar",
          fullName: "Imię i nazwisko",
          nickname: "Pseudonim",
          department: "Departament",
          excellentStaff: "Doskonały personel",
          yes: "Tak",
          no: "Nie",
          sessionIdentity: "Tożsamość sesji",
          markTheSessionRecordAsBelongingToTheEmployee:
            "Rekord sesji tagowej należy do pracownika",
          telephone: "Telefon",
          jobNumber: "Praca nr.",
          role: "Rola",
        },
      },
      roleManage: {
        list: {
          menu: "Menu",
          functionalCategory: "Kategorie funkcjonalne",
          functionalSubclass: "Podklasa funkcji",
          deletedSuccessfully: "Pomyślnie usunięte",
          NewRole: "Nowa rola",
          role: "Rola",
          dataPermissions: "Uprawnienia danych:",
          edit: "Edytuj",
          areYouSureYouWantToDeleteTheRole: "OK, aby usunąć rolę",
          determine: "Określ",
          cancel: "Anuluj",
          delete: "Usuń",
          applicationPermission: "Zastosuj uprawnienia",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "Możesz zobaczyć tylko własne dane sesji",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "Możesz przeglądać dane sesji działu i podległych działów",
          menu: "Menu",
          functionalCategory: "Kategorie funkcjonalne",
          functionalSubclass: "Podklasa funkcji",
          successfullyAdded: "Nowy sukces",
          modifiedSuccessfully: "Modyfikacja się powiodła",
          edit: "Edytuj",
          cancel: "Anuluj",
          confirm: "Potwierdź",
          roleName: "Nazwa roli",
          pleaseEnterTheRoleName: "Wpisz nazwę roli",
          dataAuthority: "Uprawnienia danych",
          youCanViewCompanyWideSessionData:
            "Może przeglądać dane sesji w całej firmie",
          applicationPermission: "Zastosuj uprawnienia",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "Proces sesji",
          generateProcess: "Proces generowania",
          addProcess: "Dodaj proces",
          cancel: "Anuluj",
          complete: "Kompletny",
          edit: "Edytuj",
          theSettingWillBeSuccessfulLater:
            "Ustaw, aby zobaczyć sukces później!",
          previous: "Poprzedni artykuł",
          next: "Następny artykuł",
          debuggingMode: "Tryb debugowania",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "Po aktywowaniu wiadomości, jeśli jest zgodna z regułami zdarzeń kluczowych, elementów monitorowania, tagów klienta lub punktów wiedzy, wyświetlana jest nazwa reguły, do której wiadomość pasuje, oraz nazwa punktu wiedzy bazy wiedzy.",
          seeLater: "Spójrz później",
          determine: "Określ",
          conversationalInsights: "Session Insights Insights",
          customerInsight: "Wgląd klienta",
          knowledgePointRecommendation: "Zalecenie Knowledge Point",
          comment: "Uwagi",
          sessionQuality: "Jakość sesji",
          setToListenLaterSuccessfully: "Zestaw do słuchania sukcesu później!",
          listenLater: "Posłuchaj później",
          seekCounselingSuccess: "Szukaj sukcesu coachingu!",
          altogether: "W sumie",
          appearsAt: "Pojawia się",
          commentsPublishedSuccessfully: "Udało się opublikować komentarze",
          callRecording: "Nagrywanie rozmów",
          addedSuccessfully: "Dodano pomyślnie",
          shareWithColleagues: "Podziel się z kolegami",
          shareWithCustomers: "Podziel się z klientami",
          resumptionDetails: "Szczegóły ponownej oferty",
          addToCaseBase: "Dodaj do biblioteki spraw",
          shareCases: "Udostępnianie przypadków",
          seekCounseling: "Szukaj porady",
          sessionRecord: "Rekord sesji",
          businessRecord: "Dokumentacja biznesowa",
          keywordSearch: "Wyszukiwanie słów kluczowych",
          whole: "Wszystkie",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "Wprowadź komentarz, wpisz @, aby powiadomić innych, wpisz #, aby dodać wątek",
          subscriptionSession: "Subskrybuj sesję",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "Po subskrypcji, gdy pojawi się nowa pomoc, komentarze i odpowiedzi, otrzymasz powiadomienia i przypomnienia.",
        },
      },
      coachTask: {
        index: {
          employeeName: "Imię i nazwisko pracownika",
          customerName: "Nazwa klienta",
          source: "Źródło",
          viewSessionRecords: "Wyświetl rekordy sesji",
          coachingStatus: "Status coachingu",
          helpTime: "Czas na pomoc",
          counselingTime: "Czas poradnictwa",
          helpContent: "Zawartość pomocy",
          coachingOperation: "Operacja coachingu",
          areYouSureYouWantToIgnoreIt: "Czy na pewno chcesz to zignorować?",
          determine: "Określ",
          cancel: "Anuluj",
          ignoreHelp: "Ignoruj pomoc",
          coaching: "Coaching zachowania",
          viewCoaching: "Zobacz Coaching",
          areYouSureToReturnToCounseling:
            "Czy na pewno wrócisz do poradnictwa?",
          returnToPendingCoaching: "Wznowienie poradnictwa",
          tutor: "Doradca",
          helpSeekingStatus: "Status pomocy",
          recentCoachingTime: "Ostatni czas poradnictwa",
          operation: "Operacja",
          ignoreSuccess: "Ignoruj sukces!",
          recoverySucceeded: "Odzyskiwanie udane!",
          successfulCoaching: "Sukces korepetycje!",
          myMission: "Moja misja",
          selectCoachingStatus: "Wybierz status coachingu",
          whole: "Wszystkie",
          iAskedForHelp: "Poprosiłem o pomoc",
          signOut: "Wyjście",
          submit: "Przedłożenie",
          helpPerson: "Poproś o pomoc",
          customer: "Klient",
          toBeCoached: "Być mentorem",
          counselingSuggestions: "Porady w zakresie poradnictwa",
          pleaseEnterCoachingSuggestions:
            "Wprowadź sugestie dotyczące coachingu",
        },
      },
      coachTaskCenter: {
        title: "Centrum misji",
        list: {
          taskName: "Nazwa zadania",
          lessonGroup: "Klasyfikacja kursu",
          lessonName: "Kurs",
          taskType: "Typ zadania",
          taskStatus: "Status zadania",
          taskExecutionInfo: "Status wdrożenia",
          totalCount: "Liczba osób do stracenia",
          executeCount: "Liczba wykonanych",
          executeRate: "Wskaźnik realizacji",
          completeCount: "Liczba ukończona",
          completeRate: "Wskaźnik ukończenia",
          commitCount: "Liczba osiągnięć",
          commitRate: "Wskaźnik zgodności",
          excellentRate: "Doskonała stawka",
          taskTime: "Czas zadania",
          option: "Operacja",
          edit: "Edytuj",
          taskDetail: "Dane zadaniowe",
          delete: "Usuń",
          new: "Nowa publikacja",
          taskTypeAll: "Wszystkie",
          taskTypePractice: "Ćwiczenie",
          taskTypeTest: "Badanie",
          taskStatusNotStarted: "Nie rozpoczęto",
          taskStatusRunning: "W toku",
          taskStatusEnd: "Zakończone",
          taskStatusInvalid: "Nie obowiązuje",
          unlimitedTime: "Nieograniczony czas",
          to: "Do",
          deleteConfirm:
            "Po usunięciu wszystkie zapisy uczenia się w ramach tego zadania zostaną wyczyszczone. Czy na pewno je usuniesz?",
          deleteSuccess: "Zadanie zostało pomyślnie usunięte",
        },
        edit: {
          addTask: "Nowe zadanie publikowania",
          editTask: "Edytuj zadanie publikowania",
          publishSuccess: "Opublikowane pomyślnie",
          publishFailed: "Publikacja nie powiodła się",
          updateSuccess: "Aktualizacja zakończyła się sukcesem",
          updateFailed: "Aktualizacja nie powiodła się",
          cannotBeNull: "Nie może być pusty",
          explain: "Opis",
          taskDescExtra: "(Będzie to wyświetlane przy wejściu {0})",
          taskTargetObj: "Publikowanie obiektów",
          isEnable: "Skuteczny status",
          dialogueMode: "Tryb dialogu",
          voice: "Głos",
          text: "Tekst",
          userPortrait: "Portret użytkownika",
          difficultyLevel: "Trudność",
          limitDuration: "Limit czasu trwania",
          tipLabel: "Wskazówki",
          tipTooltip: "Pozwól delegatom na przeglądanie mowy referencyjnej",
          limitTimes: "Liczba egzaminów",
          time: "Czas",
          gradingStandart: "Kryteria punktacji",
          gradingStandartDescAffix:
            "(Całkowity wynik dla bieżącego procesu rozmowy wynosi",
          gradingStandartDescSuffix:
            "Punkty, umiejętność mowy {0} punktów, nastrój {1} punktów, prędkość {2} punktów)",
          completeStandart: "Wymagania dotyczące ukończenia zadania",
          byTimes: "Według liczby razy",
          byScore: "Według wyniku",
          completeByTimes: "Liczba ćwiczeń",
          times: "Czasy",
          score: "Punkty",
          anyTimes: "Raz",
          getScore: "Wynik",
          offLimitDuration: "Nieograniczony czas trwania",
          onLimitDuration: "Ograniczona długość czasu",
          commitScore: "Standard, wynik",
          excellentScore: "Doskonały standard, wynik",
          offLimitTime: "Nieograniczony czas",
          onLimitTime: "Ograniczony czas",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "Cześć," },
          callDynamic: {
            today: "Dzisiaj",
            yesterday: "Wczoraj.",
            sessions: "Sesja",
          },
          todoList: {
            strip: "Artykuł",
            theSessionIsAddedTolistenLater:
              "Sesja jest dołączona „ Słuchaj później”",
            askYouForHelp: "Poprosić cię o pomoc",
            iHopeToContactYouLater:
              "Spodziewaj się, że skontaktujesz się później",
            lookingForwardToSendingTheInformationLater:
              "Spodziewaj się, że prześlesz materiał później",
            dialogueRisk: "Rozmowa ma punkty ryzyka",
            tutored: "Trenowany",
            goToCounseling: "Idź do poradnictwa",
            contacted: "Kontakty",
            hasBeenSent: "Wysłane",
            areYouSureYouWantToIgnoreIt: "Czy na pewno chcesz to zignorować?",
            determine: "Określ",
            cancel: "Anuluj",
            ignore: "Ignoruj",
            checked: "Oglądane",
          },
          callListItem: {
            strip: "Artykuł",
            learned: "Uczony",
            keyEvents: "Kluczowe wydarzenia",
            totalImplementationRateOfSupervisionItems:
              "Ogólny wskaźnik realizacji elementów nadzoru",
          },
        },
        index: {
          operationSucceeded: "Operacja zakończyła się sukcesem!",
          sessionDynamics: "Dynamika sesji",
          my: "Mój",
          department: "Departament",
          company: "Firma",
          toDoList: "Do zrobienia",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber:
            "Efektywna głośność połączenia (jednostka)",
          bestPractices: "Najlepsze praktyki",
          averageDurationOfASingleCallminutes:
            "Średni czas trwania pojedynczego połączenia (minuty)",
          totalCallVolumePCs: "Łączna liczba połączeń (jednostka)",
          totalTalkTimehours: "Całkowity czas trwania połączenia (godziny)",
          speakingPercentagepercentage: "Procent mówienia (procent)",
          maximumContinuousSpeakingTimeminutes:
            "Maksymalny ciągły czas mówienia (minuty)",
          numberOfIndepthDiscussionsPCs:
            "Liczba szczegółowych dyskusji (jednostka)",
          maximumCustomerTalkminutes: "Maksymalne wystąpienie klienta (minuty)",
          questionFrequencyPCs: "Częstotliwość pytań (jednostka)",
          averageValue: "Średnia",
          selectDate: "Wybierz datę",
          personnel: "Personel",
          pleaseSelect: "Proszę wybrać",
          callRange: "Zasięg połączenia",
          conversationActivity: "Aktywność sesyjna",
          conversationAction: "Akcja sesyjna",
          keyEvents: "Kluczowe wydarzenia",
          callRecord: "Nagrywanie rozmów",
          filter: "Filtr:",
          conversation: "Zadzwoń",
          minute: "Minuty",
          sale: "Pracownik",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "Styczeń",
            numberOfCalls: "Liczba połączeń",
            accountInformation: "Informacje o koncie",
            month: "Miesiąc",
            today: "Dzisiaj",
            customerDetails: "Szczegóły klienta",
            searchKeywordsInCommunicationContent:
              "Wyszukaj słowa kluczowe w treści komunikacji",
            allMembers: "Wszyscy członkowie",
            reset: "Zresetuj",
            query: "Zapytanie",
            allTrip: "Pełny pokaz podróży",
            contactOverview: "Przegląd kontaktów",
            lastContact: "Ostatni kontakt",
            nextContact: "Następny kontakt",
            previous: "Poprzedni artykuł",
            next: "Następny artykuł",
            viewDetails: "Zobacz szczegóły",
            contactDynamics: "Kontakt dynamiczny",
            lastYear: "W zeszłym roku",
            lastCall: "Ostatnia rozmowa",
            hotTopicsForDiscussion: "Gorący temat dyskusji",
            totalInTheLastYear: "W zeszłym roku w sumie.",
            callTimes: "Połączenie wtórne",
          },
        },
        list: {
          index: {
            risk: "Ryzyko",
            have: "Są",
            nothing: "Brak",
            channel: "Kanał",
            callRecording: "Nagrywanie rozmów",
            enterpriseWeChatConversation: "Przedsiębiorstwo mikro-sesja",
            enterpriseWeChatCall: "Przedsiębiorstwo Micro-Talk",
            followUpSuggestions: "Zalecenia uzupełniające",
            customer: "Centrum klienta",
            followUp: "Działania następcze",
            personInCharge: "Osoba odpowiedzialna",
            recentFollowup: "Niedawno nastąpiło.",
            lastContactTime: "Czas ostatniego kontaktu",
            operation: "Operacja",
            see: "Widok",
            exceed: "Więcej",
            noActivityForMoreThanDays: "Dni bez aktywności",
            lately: "Ostatnio",
            risksOccurredInTheLastDays:
              "Semantyka ryzyka występuje w ciągu kilku dni",
            savedSuccessfully: "Oszczędzaj pomyślnie",
            today: "Dzisiaj",
            allDepartments: "Wszystkie departamenty",
            allMembers: "Wszyscy członkowie",
            customerName: "Nazwa klienta",
            reset: "Zresetuj",
            query: "Zapytanie",
            customers: "Klient",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "Pokaż kontakt między pracownikami a klientami, w którym różowe bąbelki to wiadomości od klientów, a niebieskie bąbelki to wiadomości pracowników. Im większy odsetek przemówień, tym większe bąbelki.",
            riskAllocation: "Konfiguracja ryzyka",
            preservation: "Zapisz",
            pleaseEnter: "Proszę wejść",
            noActivityForDays: "Dni bez aktywności",
            riskSemanticsWithinDays:
              "Semantyka ryzyka występuje w ciągu kilku dni",
            addRiskSemantics: "Dodaj semantykę ryzyka",
            semanticTags: "Etykiety semantyczne:",
            standardSentence: "Standardowe zdanie:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "Dodaj nowy stan" } },
              },
              index: {
                meetAllOfTheFollowingConditions:
                  "Spełnij wszystkie następujące warunki",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "Filtr",
                pleaseSelectFilteringCriteria:
                  "Proszę wybrać kryteria filtrowania",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "Etap zmiany możliwości",
              theCurrentStageIs: "Obecny etap to 【",
              ConfirmToChangeItTo: "], Potwierdź, aby to zmienić 【",
              IsIt: "]?",
              StageCoStay: "] Faza całkowitego pobytu",
              day: "Dzień",
              start: "Start",
              end: "Koniec",
              orderInput: "Utrata porządku",
              restart: "Ponowne uruchomienie",
              pleaseConfigureTheSalesStageInformation:
                "Proszę skonfigurować informacje o etapie sprzedaży",
              reasonForDocumentInput: "Powód utraty zamówienia:",
              completeOrderInput: "Kompletny przegrywając Doc",
              pleaseSelectTheReason: "Proszę wybrać powód",
            },
            modal: {
              customerDynamics: { theManagerSuggests: "Kierownictwo zaleca:" },
              index: {
                sessionDetails: "Szczegóły sesji",
                customerDynamics: "Dynamika klienta",
                riskPoint: "Punkt ryzyka",
                followUpSuggestions: "Zalecenia uzupełniające",
              },
              sessionDetails: {
                common: "Ogółem",
                currentlyViewingThe: "Obecnie Widok nr.",
                sessions: "Sesja",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "Obecna faza została zatrzymana",
              day: "Dzień",
            },
          },
          business: {
            index: { reEnableOpportunity: "Możliwość ponownego włączenia" },
          },
          customerTag: { index: { label: "Etykieta" } },
          moreCustomer: {
            index: {
              essentialInformation: "Podstawowe informacje",
              cancel: "Anuluj",
              confirm: "Potwierdź",
              customer: "Klient",
              currentFollowupStage: "Obecna faza obserwacji",
              telephone: "Telefon",
              followUpDays: "Dni kontrolne",
              day: "Dzień",
              remarks: "Uwagi",
              channelPreference: "Preferencja kanału",
              hello: "Witam.",
              customFields: "Pola niestandardowe",
              opportunityAmount: "Kwota możliwości",
              focus: "Obawy",
              customerInformation: "Informacje o kliencie",
              viewMoreCustomerInformation:
                "Zobacz więcej informacji o klientach",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "Kluczowe wydarzenia:" },
                row1: {
                  customerDynamics: "Dynamika klienta",
                  common: "Ogółem",
                  barDynamic: "Dynamiczny",
                },
                row2: {
                  riskPoint: "Punkt ryzyka",
                  common: "Ogółem",
                  risks: "Artykuł Ryzyko",
                },
                row3: {
                  followUpSuggestions: "Zalecenia uzupełniające",
                  intelligentRecommendation: "Inteligentna rekomendacja",
                  strip: "Artykuł",
                  managersSuggestATotalOf: "Menedżer zaleca sumę",
                  recommendedByManagers: "Zalecany menedżer",
                  creationTime: "Czas tworzenia",
                  administratorSuggestions: "Porady administratora",
                  suggestionsForNewManagers: "Porady nowego menedżera",
                },
              },
            },
            index: { typeTime: "Rodzaj/Czas" },
          },
          userInfo: {
            index: {
              personInCharge: "Osoba odpowiedzialna",
              recentFollower: "Niedawno nastąpiło.",
              startTime: "Pierwszy raz zasięgu",
              followUpDays: "Dni kontrolne",
              day: "Dzień",
              remarks: "Uwagi",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "Członkowie zespołu",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "Całkowita liczba sesji, których członkowie nauczyli się od innych (de-duplikacja). Wyszukiwanie szczegółów sesji i kliknięcie nagrania w celu odtworzenia jest uważane za jednorazową naukę.",
          totalNumberOfStudies: "Łączna liczba uczenia się",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "Łączna liczba komentarzy i odpowiedzi zainicjowanych przez członków na sesje innych osób (deduplikacja)",
          totalNumberOfInteractions: "Łączna liczba interakcji",
          learnTheSumOfConversationCallDuration:
            "Suma wyuczonego czasu trwania połączenia sesji",
          lengthOfStudy: "Czas trwania nauki",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "Łączna liczba próśb o pomoc członków na sesje innych osób (de-duplikacja)",
          totalNumberOfRequests: "Łączna liczba wniosków o pomoc",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "Łączna liczba członków udzielających porad w celu uzyskania pomocy od innych osób (de-duplikacja)",
          totalCounseling: "Łączna liczba samouczków",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "Łączna liczba sesji członków innych osób dodanych do biblioteki spraw (de-duplikacja)",
          addCase: "Dodaj etui",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "Łączna liczba subskrypcji członków sesji innych osób (deduplikacja)",
          subscribe: "Subskrypcja",
          totalNumberOfSessionsCommunicatedByMembers:
            "Łączna liczba sesji przekazanych przez członków",
          totalSessions: "Łączna liczba sesji",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "Całkowita liczba członków odtwarzających swoje sesje (de-duplikacja), wyszukanie szczegółów sesji i kliknięcie nagrania do odtworzenia jest uważana za ponowne odtworzenia raz.",
          totalNumberOfDoubleDisk: "Całkowita liczba redysków",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "Łączna liczba redysków/całkowita liczba sesji * 100%",
          proportionOfDoubleDisk: "Wznowienie udziału",
          summationOfConversationCallDurationOfDuplicateDisk:
            "Suma czasu trwania rozmowy podwójnego dysku.",
          resumptionDuration: "Czas trwania restancji",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "Łączna liczba członków, którzy zainicjowali pomoc na własnych sesjach (de-duplikacja)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "Łączna liczba uwag (w tym odpowiedzi) otrzymanych przez inne osoby na sesję członka",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "Łączna liczba sesji członków dodanych do biblioteki spraw przez innych (de-duplikacja)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "Łączna liczba sesji członkowskich subskrybowanych przez inne osoby (deduplikacja)",
          askForHelpInTheLastMonth: "W zeszłym miesiącu o pomoc.",
          lastTimeForHelp: "Ostatni raz prosić o pomoc",
          operation: "Operacja",
          viewAllSessionsInTheLastMonth:
            "Zobacz wszystkie sesje z ostatniego miesiąca",
          allDepartments: "Wszystkie departamenty",
          reset: "Zresetuj",
          query: "Zapytanie",
          altogether: "W sumie",
          secondSession: "Sesja wtórna",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "Obecny projekt testowy:",
            supervisionItem: "Pozycje nadzoru",
            keyEvents: "Kluczowe wydarzenia",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "Sprawdź elementy monitorowania lub kluczowe zdarzenia, które chcesz przetestować, i upewnij się, że w symulowanej rozmowie jest treść rozmowy",
        },
      },
      executionAnalysis: {
        index: { executiveForceAnalysis: "Analiza wykonania" },
      },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "Proces 1",
              technologicalProcess: "Proces",
              newProcess: "Nowy proces",
            },
          },
          index: {
            upload: "Prześlij",
            preservation: "Zapisz",
            newlyBuild: "Nowy",
            cancel: "Anuluj",
            name: "Imię i nazwisko",
            describe: "Opis",
            type: "Rodzaj",
            classification: "Klasyfikacja",
            content: "Treść",
            enclosure: "Załącznik",
            knowledgePointAssociation: "Powiązanie punktu wiedzy",
            open: "Otwarte",
            close: "Zamknij",
            operator: "Operator",
            logic: "Logika",
            see: "Widok",
            edit: "Edytuj",
          },
        },
        index: {
          name: "Imię i nazwisko",
          describe: "Opis",
          createdBy: "Stwórca",
          type: "Rodzaj",
          lastUpdated: "Czas ostatniej aktualizacji",
          numberOfReferences: "Liczba referencji",
          operation: "Operacja",
          see: "Widok",
          edit: "Edytuj",
          delete: "Usuń",
          categoryDeletedSuccessfully: "Kategoria usunięta pomyślnie",
          classificationOfKnowledgePoints: "Klasyfikacja punktów wiedzy",
          newlyBuild: "Nowy",
          knowledgeBase: "Baza wiedzy",
          knowledgePoints: "Punkty wiedzy",
          technologicalProcess: "Proces",
          keyword: "Słowo kluczowe",
          batchExport: "Eksport partii",
          batchDelete: "Usuń partię",
          importProcess: "Proces importowy",
          importKnowledgePoints: "Importowanie punktów wiedzy",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "Jeśli punkt wiedzy nie istnieje w bazie wiedzy, dodaj bezpośrednio punkt wiedzy; jeśli istnieje, pomiń go i nie aktualizuj.",
          editKnowledgePointClassification:
            "Edytuj klasyfikację punktów wiedzy",
          newKnowledgePointClassification: "Klasyfikacja nowego punktu wiedzy",
          categoryEditingSucceeded: "Sukces edycji kategorii",
          newClassificationSucceeded: "Nowa kategoria z powodzeniem",
          classificationName: "Nazwa kategorii",
        },
      },
      monitoringReport: {
        index: { executiveSupervision: "Nadzór nad siłą wykonawczą" },
      },
      topicCallout: {
        index: {
          whole: "Wszystkie",
          date: "Data",
          dimensionStatus: "Status wywołania",
          callRecordID: "Identyfikator dziennika połączeń",
          callRecordIDMustBeNumeric:
            "Identyfikator dziennika połączeń musi być numerem",
          strip: "Artykuł",
          topicAnnotation: "Objaśnienia do tematu",
          annotated: "Oznakowane",
          stripNotMarked: "Bar, nieoznakowany",
          barInDimension: "Bary, w callout",
          customer: "Klient",
          sale: "Sprzedaż",
          common: "Ogółem",
          sessions: "Sesje",
          currentTopic: "Aktualny temat:",
          staff: "Pracownik",
          topicOfConversation: "Temat",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "Operacja powiodła się, reset wywoławczy",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "Po zresetowaniu wszystkie zapisy etykietowania tej sesji zostaną wyczyszczone. Potwierdź",
          resetAll: "Zresetuj wszystkie",
          pleaseSelectTheTopicToBeMarked: "Wybierz temat do etykiety",
          pleaseTickTheSentenceToBeMarked:
            "Sprawdź zdanie, które ma być oznaczone",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "Operacja się powiodła, etykieta weszła w życie",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "Zakres tej adnotacji obejmuje wiadomości z innych tematów. Kliknij OK, aby je zastąpić.",
          confirmDimensions: "Potwierdź objaśnienie",
          talkTime: "Czas rozmów",
          callID: "Identyfikator wywołania",
        },
      },
      topicList: {
        index: {
          callID: "Identyfikator połączenia",
          latestMarkedTime: "Najnowszy czas znakowania",
          topicOfConversation: "Temat",
          numberOfSentences: "Liczba zdań",
          sentence: "Zdanie",
          operation: "Operacja",
          see: "Widok",
          delete: "Usuń",
          edit: "Edytuj",
          newSubTopic: "Nowy temat podrzędny",
          move: "Przesuń",
          whole: "Wszystkie",
          topicDeletedSuccessfully: "Temat został pomyślnie usunięty",
          newlyBuild: "Nowy",
          topicList: "Lista tematów",
          callIDMustBeNumeric: "Identyfikator połączenia musi być numerem",
          leadInTopic: "Temat importu",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "Jeśli nazwa kategorii jest duplikowana, oryginalna kategoria tematu nie zostanie nadpisana, a przedmiot zostanie pominięty i nie zostanie zaimportowany.",
          mobileClassification: "Klasyfikacja mobilna",
          editTopic: "Edytuj temat",
          newTopic: "Nowy temat",
          topicName: "Nazwa tematu",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "Modyfikacja się powiodła",
            createdSuccessfully: "Pomyślnie stworzony",
            modificationFailed: "Modyfikacja nie powiodła się",
            creationFailed: "Nie udało się utworzyć",
            monitoringName: "Nazwa monitorowania",
            upToCharacters: "Do 40 znaków",
            pleaseEnterAName: "Wpisz nazwę",
            monitoringIndicators: "Wskaźnik monitorowania",
            sessionActivity: "Aktywność sesyjna",
            conversationAction: "Akcja sesyjna",
            keyEvents: "Kluczowe wydarzenia",
            supervisionItems: "Pozycje nadzoru",
            monitoringMembers: "Członkowie monitorujący",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "Grupy otrzymujące wskazówki, szkolenia lub interwencje w zarządzaniu",
            groupA: "Grupa A",
            pleaseSelectGroupAData: "Proszę wybrać dane grupy A",
            thereAreDuplicateUsersInGroupB:
              "Zduplikowani użytkownicy w grupie B",
            groupARemarks: "Uwagi grupy A",
            controlGroupGroupComparedWithGroupA:
              "Grupa kontrolna, w porównaniu z grupą A",
            groupB: "Grupa B",
            pleaseSelectGroupBData: "Proszę wybrać dane grupy B",
            thereAreDuplicateUsersInGroupA:
              "W grupie A są zduplikowani użytkownicy",
            groupBRemarks: "Grupa B Uwagi",
            monitoringPeriod: "Cykl monitorowania",
            startNode: "Węzeł startowy",
            monitoringScope: "Zakres monitorowania",
            pleaseSelectMonitoringConditions:
              "Proszę wybrać warunki monitorowania",
            pleaseEnterANumber: "Wpisz numer",
            pleaseSelectATimePeriod: "Wybierz okres",
            preservation: "Zapisz",
            cancel: "Anuluj",
          },
        },
        list: {
          template: {
            deletionSucceeded: "Pomyślnie usunięte",
            notStarted: "Nie rozpoczęto",
            haveInHand: "W toku",
            closed: "Zakończone",
            newGroupTest: "Nowy test grupowy",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "Faza sprzedaży",
        communicationStrategy1: "Strategia komunikacji",
        riskAllocation: "Konfiguracja ryzyka",
        businessConfiguration: "Konfiguracja biznesowa",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "Obsługa sprzeciwu",
                  productSellingPointScript: "Punkty sprzedaży produktów",
                  caseGrouping: "Grupowanie przypadków",
                  pleaseSelectExcellentCases: "Proszę wybrać dobry przypadek.",
                  pleaseSelectKnowledgePoints: "Proszę wybrać punkty wiedzy",
                  associativeLinks: "Powiązane linki",
                  jumpTo: "Skok",
                  jumpType: "Typ skoku",
                  knowledgePoints: "Punkty wiedzy",
                  excellentCases: "Doskonały futerał",
                  excellentScript: "Doskonała mowa",
                  pleaseSelectASingleKnowledgePoint:
                    "Wybierz pojedynczy punkt wiedzy",
                },
              },
            },
            index: {
              name: "Imię i nazwisko",
              pushText: "Push Tekst",
              enableStatus: "Włączony status",
              triggerRules: "Reguła wyzwalania",
              communicationStrategy: "Strategia komunikacji",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "Grupa strategii komunikacji",
              name: "Imię i nazwisko",
              pushText: "Push Tekst",
              effectiveness: "Skuteczność",
              openSuccessfully: "Otwórz pomyślnie",
              closeSuccessfully: "Zamknięte pomyślnie",
              operationFailed: "Operacja nie powiodła się",
              operation: "Operacja",
              edit: "Edytuj",
              delete: "Usuń",
              newlyBuild: "Nowy",
              communicationStrategy: "Strategia komunikacji",
              editGroup: "Edytuj grupowanie",
              groupName: "Nazwa grupy",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "Wszystkie sesje",
                  recentDays: "Ostatnie dni",
                  recentSessions: "Ostatnie sesje",
                  scopeOfApplication: "Zakres zastosowania:",
                  day: "Dzień",
                  through: "Przełęcz",
                },
              },
            },
            index: {
              describe: "Opis",
              applicableCustomers: "Ma zastosowanie do klientów",
              enableStatus: "Włączony status",
              triggerRules: "Reguła wyzwalania",
              riskRules: "Zasady ryzyka",
            },
          },
          list: {
            index: {
              riskAllocation: "Konfiguracja ryzyka",
              keyWord: "Słowa kluczowe",
              newRiskRule: "Nowa reguła ryzyka",
              riskRuleName: "Nazwa reguły ryzyka",
              describe: "Opis",
              state: "Status",
              openSuccessfully: "Otwórz pomyślnie",
              closeSuccessfully: "Zamknięte pomyślnie",
              operationFailed: "Operacja nie powiodła się",
              operation: "Operacja",
              edit: "Edytuj",
              delete: "Usuń",
              deletionSucceeded: "Pomyślnie usunięte",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "Utrata porządku" },
              endReasonList: {
                addEndReason: "Dodaj powód zakończenia",
                enterEndReason: "Wprowadź powód zakończenia",
              },
              stageList: {
                keyEvents: "Kluczowe wydarzenia",
                waysideData: "Dane dotyczące ruchu drogowego",
                includeAny: "Zawiera dowolny",
                includeAll: "Zawiera wszystkie",
                pleaseEnterTheStageName: "Proszę wpisać nazwę fazy",
                setStageRules: "Ustal zasady sceniczne",
                stageName: "Nazwa fazy",
                operator: "Operator",
                logic: "Logika",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "Format wejściowy pola typu czasu: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "Żądanie nie powiodło się, spróbuj ponownie później",
              salesStage: "Faza sprzedaży",
              essentialInformation: "Podstawowe informacje",
              name: "Imię i nazwisko",
              applicableDepartments: "Obowiązujący dział",
              applicableToOthers: "Ma zastosowanie do innych",
              manualChangePhase: "Faza ręcznej zmiany",
              enableStatus: "Włączony status",
              describe: "Opis",
              opportunityStageSettings: "Ustawienia fazy możliwości",
              stageSettings: "Ustawienia fazy",
              aliasSettings: "Ustawienia Alias",
              closeOpportunity: "Koniec możliwości",
              newSuccessfully: "Nowy sukces",
              newFailed: "Nowy nie powiodło się",
              savingSucceeded: "Oszczędzaj pomyślnie",
              saveFailed: "Nie udało się zapisać",
            },
          },
          list: {
            index: {
              salesStage: "Faza sprzedaży",
              keyWord: "Słowa kluczowe",
              newSalesStage: "Nowy etap sprzedaży",
              salesStageName: "Nazwa etapu sprzedaży",
              describe: "Opis",
              state: "Status",
              openSuccessfully: "Otwórz pomyślnie",
              closeSuccessfully: "Zamknięte pomyślnie",
              operationFailed: "Operacja nie powiodła się",
              operation: "Operacja",
              edit: "Edytuj",
              delete: "Usuń",
              deletionSucceeded: "Pomyślnie usunięte",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "Duża generacja modelu",
            conversationInsights: "Session Insights Insights",
          },
          sessionsInsight: {
            components: {
              autoGenerate: {
                index: { automaticGeneration: "Automatycznie generowane" },
              },
              hooks: {
                conversationSummary: "Podsumowanie sesji",
                conversationProcess: "Proces sesji",
                opportunityPoints: "Punkt możliwości",
              },
              prompt: {
                index: {
                  edit: "Edytuj",
                  restoreDefault: "Przywróć domyślnie",
                  cancel: "Anuluj",
                  confirm: "Potwierdź",
                },
              },
            },
            index: {
              saveSuccessful: "Oszczędzaj pomyślnie",
              hintLanguage: "Prompt",
              preserve: "Zapisz",
              cancel: "Anuluj",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "Duża generacja modelu",
        salesStage: "Faza sprzedaży",
        communicationStrategy: "Strategia komunikacji",
        riskAllocation: "Konfiguracja ryzyka",
        businessConfiguration: "Konfiguracja biznesowa",
      },
      salesLearningCenter: {
        knowledgeBase: "Baza wiedzy",
        speechCorpus: "Biblioteka mowy",
        learningCenter: "Centrum nauki",
      },
      customerFields: {
        components: {
          baseFields: {
            index: { customerInformation: "Informacje o kliencie" },
          },
        },
        index: { otherInformation: "Inne informacje" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "Import" } } },
        hooks: {
          index: {
            all: "Wszystkie",
            intelligentModel: "Inteligentny model",
            smartRules: "Inteligentne zasady",
            manualScoring: "Punktacja ręczna",
            enable: "Włącz",
            notEnabled: "Nie jest włączony",
            voiceCall: "Połączenie głosowe",
            textDialogue: "Dialog tekstowy",
            ecommerceTextSession: "Sesja tekstowa e-commerce",
            workOrder: "Zamówienie na pracę",
          },
        },
        index: {
          ruleName: "Nazwa reguły",
          ruleType: "Rodzaj reguły",
          pleaseSelect: "Proszę wybrać",
          ruleStatus: "Status reguły",
          dataSourceType: "Typ źródła danych",
          voiceCall: "Połączenie głosowe",
          textDialogue: "Dialog tekstowy",
          ecommerceTextSession: "Sesja tekstowa e-commerce",
          workOrder: "Zamówienie na pracę",
          status: "Status",
          operation: "Operacja",
          edit: "Edytuj",
          ruleTemplate: "Szablon reguły",
        },
      },
      analysisReportTitle: "Raport z analizy",
      ruleTest: "Test reguł",
      intelligentTags: {
        labelDisplayQuantity: "Ilość wyświetlania etykiet",
        unlimited: "Nieograniczony",
        ignoringTagLibraries: "Ignoruj bibliotekę tagów",
        automaticallyGenerateLabels: "Automatycznie generuj etykiety",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "Po włączeniu sesji znaczniki są generowane automatycznie na podstawie dużego modelu, a wygenerowane znaczniki są domyślnie klasyfikowane w kategorii Niesklasyfikowane.",
        defaultValidity: "Efektywność domyślna",
        supervisionItem: "Pozycje nadzoru",
        customerLabel: "Etykieta klienta",
        newSupervisionItemRule: "Nowa zasada dotycząca przedmiotu nadzoru",
        editSupervisionItemRule:
          "Edytuj zasady dotyczące przedmiotów nadzorowanych",
        newCustomerLabel: "Nowa etykieta klienta",
        editCustomerLabel: "Edytuj tagi klienta",
        newSessionLabel: "Nowa etykieta sesji",
        editSessionLabel: "Edytuj etykietę sesji",
        newKeyEvent: "Nowe kluczowe wydarzenie",
        editKeyEvents: "Edytuj kluczowe wydarzenie",
        detail: {
          template: { nameIsARequiredField: "Nazwa jest polem wymaganym" },
          formItemComponent: {
            largeModelExtensionLabel: "Duże znaczniki przedłużenia modelu",
            sessionRecordID: "Identyfikator nagrywania sesji",
            sessionTime: "Czas sesji",
            smartTags: "Inteligentna etykieta",
            viewDetails: "Zobacz szczegóły",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "Po usunięciu dane z tego rekordu sesji zostaną wyczyszczone. Czy na pewno go usuniesz?",
            tagData: "Dane znacznika",
            batchDeletion: "Usuń partię",
          },
        },
        template: {
          advancedFiltering: "Zaawansowane filtrowanie",
          dataType: "Typ danych",
          filterCriteria: "Stan filtra",
          edit: "Edytuj",
          createANewSubcategory: "Nowa podkategoria",
          delete: "Usuń",
          nameLengthCannotExceed: "Długość nazwy nie może przekraczać 40",
        },
        component: {
          modelExpansion: "Rozszerzenie modelu",
          enable: "Włącz",
          notEnabled: "Nie jest włączony",
          standardScreening: "Filtr standardowy",
          advancedFiltering: "Zaawansowane filtrowanie",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "Słowa kluczowe",
              sessionLabel: "Etykieta sesji",
              keyEvents: "Kluczowe wydarzenia",
              matchAny: "Dopasuj dowolne",
              matchAll: "Dopasuj wszystkie",
              beEqualTo: "Równe",
              includeAll: "Zawiera wszystkie",
              includeAny: "Zawiera dowolny",
              advancedConditions: "Zaawansowane warunki",
              smartTag: "Inteligentna etykieta",
              smartTagI: "Inteligentna etykieta pierwsza",
              smartTagII: "Inteligentna etykieta II",
            },
            template: {
              pleaseSelectSmartTag: "Wybierz inteligentną etykietę",
              pleaseSelectASessionLabel: "Wybierz etykietę sesji",
              pleaseSelectAKeyEvent: "Wybierz zdarzenie kluczowe",
              pleaseSelectAdvancedCondition:
                "Proszę wybrać kryteria zaawansowane",
              newAdvancedFilter: "Nowy filtr zaawansowany",
              moveUp: "Przesuń w górę",
              moveDown: "Przesuń w dół",
              moveLeft: "Przesuń w lewo",
              shiftRight: "Przesuń w prawo",
            },
          },
          component: {
            businessAnalysispng: "Analityki biznesowe. png",
            savedSuccessfully: "Oszczędzaj pomyślnie",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "Trwale skuteczny",
            permanentlyInvalid: "Trwale nieważny",
            customValidity: "Okres ważności na zamówienie",
            termOfValidity: "Okres ważności",
            name: "Imię i nazwisko",
            explain: "Opis",
            state: "Status",
            classification: "Klasyfikacja",
            smartRecommendation: "Inteligentna rekomendacja",
            preconditions: "Warunek wstępny",
            SetPreconditionsForThisSupervisionItem:
              "1. Ustaw warunki wstępne dla tej pozycji nadzoru:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              "Gdy warunki wstępne są spełnione, uważa się je za „ element nadzoru, który ma zostać wdrożony ”, a pozycja nadzoru jest badana;",
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "Jeśli warunki wstępne nie zostaną spełnione, element nadzoru nie zostanie wykryty;",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. Nie ustawiaj warunków wstępnych, tzn. wszystkie sesje są wykrywane domyślnie.",
            have: "Są",
            nothing: "Brak",
            preconditionRule: "Reguła warunku wstępnego",
            preconditionLogic: "Logika warunku wstępnego",
          },
          recommendation: {
            caseGrouping: "Grupowanie przypadków",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "Gdy pracownicy nie działają prawidłowo, mogą inteligentnie polecić doskonałe umiejętności mowy i doskonałe przypadki.",
            smartRecommendation: "Inteligentna rekomendacja",
            open: "Otwarte",
            close: "Zamknij",
            whenThisSupervisionItem: "Kiedy ten przedmiot nadzoru",
            implement: "Egzekucja",
            unexecuted: "Nie stracony",
            when: "Kiedy:",
            recommendedKnowledgePoints: "Zalecane punkty wiedzy",
            caseRecommendation: "Rekomendacja przypadku",
          },
        },
        index: {
          determine: "Określ",
          sort: "Sortuj",
          sortSucceeded: "Sortuj pomyślnie",
          supervisionItemClassification: "Klasyfikacja pozycji nadzoru",
          pressEnterToCreateANew: "Naciśnij Enter New",
          nameCannotBeEmpty: "Nazwa nie może być pusta",
          newlyBuild: "Nowy",
          name: "Imię i nazwisko",
          explain: "Opis",
          validStatus: "Ważny status",
          permanentlyValid: "Trwale skuteczny",
          permanentlyInvalid: "Trwale nieważny",
          operation: "Operacja",
          edit: "Edytuj",
          batchDelete: "Usuń partię",
          newSupervisionItem: "Nowa pozycja nadzoru",
          keyWord: "Słowa kluczowe",
          delete: "Usuń",
          deleteSucceeded: "Pomyślnie usunięte",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "Po usunięciu danych nie można przywrócić. Czy na pewno chcesz go usunąć?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "Inteligentna etykieta",
              beEqualTo: "Równe",
              includeAll: "Zawiera wszystkie",
              includeAny: "Zawiera dowolny",
              matchAny: "Dopasuj dowolne",
              matchAll: "Dopasuj wszystkie",
              smartTagI: "Inteligentna etykieta pierwsza",
              smartTagII: "Inteligentna etykieta II",
            },
            template: {
              pleaseSelectASmartTag: "Wybierz inteligentną etykietę",
              pleaseSelectAdvancedConditions:
                "Proszę wybrać kryteria zaawansowane",
              newAdvancedFilter: "Nowy filtr zaawansowany",
              rename: "Zmiana nazwy",
              moveUp: "Przesuń w górę",
              moveDown: "Przesuń w dół",
              shiftLeft: "Przesuń w lewo",
              shiftRight: "Przesuń w prawo",
            },
          },
        },
        template: {
          delete: "Usuń",
          labelFiltering: "Filtrowanie etykiet:",
          satisfyAny: "Spotkać się z arbitralnym",
          meetAll: "Poznaj wszystkich",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "Liczba rozmów na etykietach klasyfikacji wtórnej ≥ klasyfikacja podstawowa, sesja może zawierać wiele etykiet",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "Logika obliczania zmiany pierścienia: (objętość dialogowa w wybranym przedziale czasowym-objętość dialogowa w bezpośrednio poprzedzającym okresie)/objętość dialogowa w poprzednim okresie",
          proportionOfPrimaryClassification:
            "Procent klasyfikacji pierwszego stopnia",
          saveAs: "Zapisz",
          cover: "Osłona",
          preservation: "Zapisz",
          qualityInspectionTask: "Zadania analizy:",
          query: "Zapytanie",
          changeFromMonthToMonth: "Zmiana pierścienia",
          tagRanking: "Ranking etykiet",
          proportion: "Sytuacja procentowa",
        },
        component: {
          numberOfConversations: "Liczba rozmów",
          operation: "Operacja",
          businessAnalysispng: "Analityki biznesowe. png",
          savingSucceeded: "Oszczędzaj pomyślnie",
          deletionSucceeded: "Pomyślnie usunięte",
          sequence: "Sekwencja",
          firstLevelClassification: "Klasyfikacja poziomu 1",
          ringRatioChange: "Zmiana pierścienia",
          classClassification: "Klasyfikacja klas",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "Potwierdzić, aby usunąć dokument i jego wyodrębnioną zawartość?",
            addKnowledgeBase: "Dodaj bazę wiedzy",
            uploadDocument: "Prześlij dokument",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "Wsparcie słowa, pdf, ppt, excel, pliki w formacie txt",
            taskName: "Nazwa zadania",
            extractConversationDate: "Wyodrębnij datę rozmowy",
            pleaseSelectAConversationDate: "Wybierz datę rozmowy",
            dataSource: "Źródło danych",
            dataType: "Typ danych",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "Eksport się powiódł",
              problem: "Pytanie",
              answer: "Odpowiedź",
              addToKnowledgeBase: "Dodaj do bazy wiedzy",
              pleaseConfirmWhetherToDeleteIt: "Proszę ustalić, czy usunąć",
            },
            pageRightFrom: {
              addToKnowledgeBase: "Dodaj do bazy wiedzy",
              selectClassification: "Wybierz kategorię",
              pleaseSelectAKnowledgeBaseClassification:
                "Proszę wybrać klasyfikację bazy wiedzy",
            },
          },
        },
        index: {
          intelligentExtraction: "Inteligentna ekstrakcja",
          documentExtraction: "Ekstrakcja dokumentów",
          dialogueExtraction: "Ekstrakcja dialogu",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "Operacja powiodła się",
          labelIgnored: "Znacznik zignorowany",
          add: "Dodaj",
          areYouSureToDeleteIt: "Czy na pewno chcesz usunąć?",
          batchFilterLabels: "Etykieta filtra wsadowego",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "Inteligentna kontrola jakości",
        savedSuccessfully: "Oszczędzaj pomyślnie",
        logoSettings: "Ustawienia logo",
        default: "Domyślnie",
        custom: "Niestandardowe",
        title: "Tytuł",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "Nazwa tytułu wyświetlana przez system jest zalecana do 6 znaków.",
        logoImageUpload: "Przesyłanie obrazu z logo",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "Proszę wybrać zdjęcie o rozmiarze nieprzekraczającym 2048kb",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "Aby zapewnić efekt przeglądania logo, zaleca się przesyłanie zdjęć:",
        Size5050Pixels: "1. Rozmiar: 50*50 pikseli;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. Format: obraz z przezroczystym tłem, png lub jpg, grafika logo za pomocą białego",
        browserLabelIcon: "Ikona zakładki przeglądarki",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "Aby zapewnić efekt przeglądania tagów, zaleca się przesyłanie zdjęć:",
        Size3232Pixels: "1. Rozmiar: 32*32 piksele;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. Format: obraz z przezroczystym tłem, png lub jpg",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "Kontrola jakości w toku, proszę czekać...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "W 2015 r. Nieruchomości mojego kraju osiągnęły krytyczny moment przetrwania. Duża liczba domów była bez opieki, a ludzie nie chcieli ich kupować. Hangzhou spadło kiedyś poniżej 8000, a niektórzy deweloperzy zniszczyli rynek. Shenzhen nadal konsolidowało się w tym czasie około 2w-3w, a domy w miastach pierwszego rzędu również były zagrożone.",
        xiaoAn: "Ann.",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore przechowuj te bariery pamięci nazywane są barierami pamięci jsr, które są na poziomie jvm. Na dole systemu operacyjnego znajdują się dwie implementacje: jedna polega na zablokowaniu magistrali, a druga to prymitywna bariera pamięci, taka jak sfance (bariera zapisu) mfence (pełna bariera) ifence (bariera odczytu). Dolna warstwa maszyny wirtualnej hotspotów wykorzystuje instrukcję asemblera: lock addl,lock an pusta operacja, czyli szyna zamka. Dzieje się tak, ponieważ niektóre systemy operacyjne nie obsługują prymitywów bariery pamięci.",
        xiaobei: "Xiaobei",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. Spróbuj kilkakrotnie marynować mięso i smażyć warzywa po dodaniu wina. Mięso jest zjełczałe i pachnie kwaśno! Od teraz koniec z gotowaniem wina na bekon",
        xiaodong: "Xiaodong",
        xiaoxi: "Xiao Xi",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. Spróbuj marynować mięso kilka razy, a warzywa smażone po dodaniu wina są jak zepsute mięso o kwaśnym zapachu! Od teraz koniec z gotowaniem wina na bekon",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "Zarządzanie przeglądami e-commerce",
        socialMediaReviewManagement:
          "Zarządzanie przeglądem mediów społecznościowych",
        customDataSource: "Niestandardowe źródło danych",
        qualityInspectionResults: "Wyniki kontroli jakości",
        accountManagement: "Zarządzanie kontem",
        employeeProfile: "Portret pracownika",
        intelligentAnalysis: "Inteligentna analiza",
        customKanban: "Niestandardowe Kanban",
        dataScreening: "Przegląd danych",
        learningRecord: "Rekord uczenia się",
        dataAnalysis: "Analiza danych",
        scoringManagement: "Zarządzanie punktowaniem",
        businessConfiguration: "Konfiguracja biznesowa",
        manualReview: "Przegląd ręczny",
        taskList: "Lista zadań",
        videoAnalysis: "Analiza wideo",
        overview: "Przegląd",
        personalSignage: "Osobisty Kanban",
        learningCenter: "Centrum nauki",
        wrongQuestionSet: "Zły zestaw problemów",
        abilityImprovement: "Możliwość aktualizacji",
        close: "Zamknij",
        switchingSystemApplications: "Przełączanie aplikacji systemowych",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "Przesuń mysz tutaj, aby wywołać menu przełączania aplikacji, i kliknij pozycję menu, aby przejść do różnych aplikacji.",
        teamAnalysis: "Analiza zespołu",
        semanticIntelligence: "Inteligencja semantyczna",
        permissionManagement: "Zarządzanie zezwolenie",
        dataConnection: "Dokowanie danych",
        courseManagement: "Zarządzanie kursem",
        labelManagement: "Zarządzanie etykietami",
        curriculum: "Centrum kursów",
        courseConfiguration: "Konfiguracja kursu",
        task: "Moja misja",
        historicalLearningData: "Historia",
        taskCenter: "Zarządzanie zadaniami",
        knowledgeBase: "Baza wiedzy",
        ecommerceSessionManagement: "Zarządzanie sesją e-commerce",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "Przełączanie aplikacji",
          descriptionOfIntelligentQualityInspectionSystem:
            "Opis inteligentnego systemu kontroli jakości",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "Filtr sesji e-commerce",
        allEcommerceSessions: "Wszystkie sesje e-commerce",
        ecommerceSessionManagement: "Zarządzanie sesją e-commerce",
      },
    },
    analysisEnable: {
      bestPractices: "Najlepsze praktyki",
      behaviorAnalysis: "Analiza zachowania",
      teamAnalysis: "Analiza zespołu",
      executiveSupervision: "Nadzór nad siłą wykonawczą",
      executiveForceAnalysis: "Analiza wykonania",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "Ocena:",
          startTime: "Czas rozpoczęcia",
          workOrderNo: "Numer zamówienia pracy",
          customerServiceHasReadNotRead: "Agent przeczytany/nieprzeczytany",
          canYouAppeal: "Czy się odwołać",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "Przedsiębiorstwo mikro uwagi",
          addFriendTime: "Dodaj czas kumpla",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Rozpocznij ponowną inspekcję",
          callID: "Identyfikator połączenia",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Nowe funkcje! Kliknięcie przycisku „ Szybka Kontrola próbkowania ”automatycznie przeskoczy do następnej strony po pomyślnym przesłaniu ~ ~ ~",
        },
      },
      template: {
        deleteRecord: "Usuń rekord",
        areYouSureToDeleteIt: "Czy na pewno chcesz usunąć?",
        confirm: "Określ",
        reIdentification: "Ponowna identyfikacja",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "Ponowna identyfikacja pochłonie czas transferu, proszę działać ostrożnie.",
        determine: "Określ",
        executionSuccessful: "Udana egzekucja",
      },
      component: {
        pleaseSelectData: "Proszę wybrać dane",
        operationSuccessful: "Operacja powiodła się",
        startPerformingReQualityInspection: "Rozpocznij ponowną inspekcję",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "Prześlij nazwę pliku",
        analysisTask: "Zadanie analizy",
        analyzeProgress: "Analizuj postęp",
        analysisSucceeded: "Analiza sukcesu",
        analysisFailed: "Analiza nie powiodła się",
        localDataUpload: "Przesyłanie danych lokalnych",
        recordingUpload: "Przesyłanie nagrania audio",
        textUpload: "Przesyłanie tekstu",
        uploadTime: "Czas przesyłania",
        taskName: "Nazwa zadania",
        operator: "Operator",
        qualityInspectionProgress: "Postęp kontroli jakości",
        whole: "Wszystkie",
        haveInHand: "W toku",
        completed: "Ukończone",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "Eksportować wszystkie wypełnione rekordy danych zgodnie z aktualnymi kryteriami filtrowania?",
        upload: "Prześlij",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "Wpisz imię i nazwisko",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "Wprowadź nazwę, na przykład typ/zbiór/cel importowanych danych.",
            default: "Domyślnie",
            jDCOM: "Jingdong",
            dataType: "Typ danych",
            selectAnalysisTask: "Wybierz zadanie analizy",
            uploadFiles: "Prześlij plik",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. Pobierz szablon Excel i wypełnij go zgodnie z formatem:",
            template: "Szablon",
            SupportUpTo10000PiecesOfData:
              "2. maksymalne wsparcie 10000 sztuk danych",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "Wsparcie Jingdong eksportowane dane dialogowe upload,.txt plik, maksymalne wsparcie rozmiaru pliku 100M",
            alongTheWayDataTemplate:
              "Szablon danych dotyczących śledzenia drogi",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "Proszę cierpliwie czekać podczas kontroli jakości tekstu.",
            textQualityInspectionCompleted: "Kompletna kontrola jakości tekstu",
          },
        },
        template: {
          startAnalysis: "Rozpocznij analizę",
          pleaseUploadTheFile: "Prześlij plik",
          localDataUpload: "Przesyłanie danych lokalnych",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "Nowe funkcje! Kliknięcie przycisku „ Sprawdź przesyłanie ”, po pomyślnym przesłaniu automatycznie przeskoczy do następnej strony ~ ~ ~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission:
              "Pozwolenie na zadanie kontroli jakości",
            edit: "Edytuj",
            iParticipated: "Jestem zaangażowany w",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "Ta rola może zobaczyć dane w wybranym dziale",
            dataPermissions: "Uprawnienia danych",
            selectDepartment: "Wybierz dział",
            edit: "Edytuj",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "Rozpocznij ponowną inspekcję",
          callID: "Identyfikator połączenia",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "Nowe funkcje! Kliknięcie przycisków „ przesyłanie próbek ”i „ przesyłanie szybkiego próbkowania” automatycznie przeskoczy do następnej strony po pomyślnym przesłaniu ~ ~ ~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "Zasady" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "Zarządzanie zamówieniem pracy",
          workOrderDetails: "Szczegóły zamówienia pracy",
        },
      },
      template: {
        workOrderFilter: "Filtr zamówienia pracy",
        allWorkOrders: "Wszystkie zlecenia pracy",
        workOrderManagement: "Zarządzanie zamówieniem pracy",
      },
    },
    trainingCenter: {
      topicAnnotation: "Objaśnienia do tematu",
      topicList: "Lista tematów",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "Rodzaj roli",
              keyWord: "Słowa kluczowe",
              pleaseEnterKeywordSearch:
                "Proszę wpisać słowo kluczowe wyszukiwania",
              query: "Zapytanie",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "Ta funkcja nie została jeszcze otwarta. W razie potrzeby skontaktuj się z administratorem systemu.",
          pleaseSelectAQuestion: "Proszę wybrać pytanie!",
          recommendedSimilarSentencesLargeModel:
            "Zalecane podobne zdania (duży model)",
          selectAll: "Wybierz wszystkie",
          add: "Dodaj",
          changeBatch: "Zmiana partii",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "Godziny pracy w biznesie",
      localDataUpload: "Przesyłanie danych lokalnych",
      assembleMenuListAccordingToCurrentUserPermissions:
        "Zmontuj listę menu zgodnie z aktualnymi uprawnieniami użytkownika",
      sessionSettings: "Ustawienia sesji",
      logoSettings: "Ustawienia logo",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "Witamy z powrotem! Wprowadź swoje dane",
        mailbox: "Skrzynka pocztowa",
        password: "Hasło",
        signIn: "Logowanie",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin:
            "Obecny adres IP nie pozwala na logowanie",
          welcomeToLogin: "Zapraszamy do logowania",
          pursuingExcellenceAndLeadingChange:
            "Dążenie do doskonałości, prowadzenie zmian",
          intelligentPlatform: "Inteligentna platforma",
        },
        virtualLogin: { index: { pleaseSelect: "Proszę wybrać" } },
      },
      oauth: {
        illegalCall: "Nielegalny telefon",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "Witamy z powrotem! Kliknij przycisk poniżej, aby przejść do platformy uwierzytelniania",
        loggingIn: "Zaloguj się...",
        unifiedIdentityAuthenticationLogin:
          "Zunifikowane logowanie uwierzytelniania tożsamości",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "Testowanie reguł: niektórych reguł nie można testować przy użyciu reguł, takich jak szybkość i głośność mowy, dane po drodze itp.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "Dowiedz się więcej",
          enteringTheSystem: "Wejdź do systemu",
        },
      },
      index: {
        cancel: "Anuluj",
        enteringTheSystem: "Wejdź do systemu",
        close: "Zamknij",
        enterSystemApplication: "Wprowadź aplikację systemową",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          "Kliknij „ Enter System ”, aby przejść do włączonej aplikacji systemowej",
      },
    },
    permissionManagement: {
      employeeManagement: "Zarządzanie pracownikami",
      roleManagement: "Zarządzanie Role",
      permissionManagement: "Zarządzanie zezwolenie",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "Równe",
            contain: "Zawiera",
            doesNotContain: "Nie zawiera",
            applicationSystem: "System aplikacji",
            intelligentQualityInspection:
              "Inteligentna inspekcja jakości/inteligentna analiza sesji",
            salesEmpowerment: "Upodmiotowienie sprzedaży",
            dataSourceType: "Typ źródła danych",
            filterCriteria: "Stan filtra",
          },
        },
        index: {
          dataConnection: "Dokowanie danych",
          savingSucceeded: "Oszczędzaj pomyślnie",
          dataIsolation: "Izolacja danych",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "Po otwarciu można ustawić zakres danych każdego połączenia dostępu do systemu aplikacji i sesji WeChat dla przedsiębiorstw. Jeśli przełącznik nie jest włączony, wszystkie systemy są podłączone do pełnych danych.",
          accessRules: "Zasady dostępu",
          preservation: "Zapisz",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "Nazwa aplikacji",
            accessRights: "Dostęp",
            inAppPermissionSettings: "Ustawienia uprawnień w aplikacji",
            setUp: "Konfiguracja",
            applicationAccess: "Dostęp do aplikacji",
            newRole: "Nowa rola",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "Szczegóły sesji" } },
      messageNotification: {
        index: {
          sessionDetails: "Szczegóły sesji",
          caseDetails: "Szczegóły sprawy",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "Widoczni pracownicy",
            newCourse: "Nowy kurs",
            editCourse: "Edytuj kurs",
            courseName: "Nazwa kursu",
            courseIntroduction: "Wprowadzenie do kursu",
            courseType: "Typ kursu",
            courseClassification: "Klasyfikacja kursu",
            courseCover: "Okładka kursu",
            learningContent: "Treści do nauki",
            uploadLearningMaterials: "Prześlij materiały do nauki",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "Test",
              preservation: "Zapisz",
              cancel: "Anuluj",
              editNodeName: "Edytuj nazwę węzła",
              determine: "Określ",
              individual: "Jeden",
              of: ", Ogółem",
              branch: "Punkty",
            },
            configDrawerComponents: {
              pPTParsing: "Analiza PPT w...",
              uploadSuccessful: "Udało się przesłać",
              uploadAttachments: "Prześlij dodatek",
              pPTParsingPleaseWait: "Parsowanie PPT, proszę czekać",
            },
            customerPortraitConfigDrawer: {
              cancel: "Anuluj",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "Nie można go przywrócić po usunięciu. Czy na pewno chcesz usunąć ten portret?",
              delete: "Usuń",
              determine: "Określ",
              customerPortrait: "Portret klienta",
              bindSelectedCharacterPortrait: "Zwiąż wybrany portret postaci",
              customerProfileDetails: "Szczegóły profilu klienta",
              createANewCustomerProfile: "Nowy profil klienta",
            },
            flow: {
              sidebar: { node: "Węzeł" },
              useControlButtons: {
                cancellingPleaseWait: "Cofnij się, proszę później...",
                redoInProgressPleaseWait: "Powtórz, proszę później...",
                automaticOrganization: "Automatyczne wykańczanie",
                canvasAdaptation: "Płótno adaptacyjne",
                delete: "Usuń",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "Węzeł nie może połączyć się z samym sobą",
              },
            },
            globalConfigDrawer: {
              cancel: "Anuluj",
              determine: "Określ",
              globalConfiguration: "Globalna konfiguracja",
              sessionDetection: "Wykrywanie sesji",
              reciprocalRule: "Zasady interakcji",
              robotVoice: "Dźwięk robota",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "Ustawienia limitu czasu dla węzłów studenckich bez odpowiedzi",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "Maksymalny dozwolony czas ciszy",
              promptScriptForFailureToAnswerOverTime:
                "Prompt do przekroczenia limitu czasu",
              addScript: "Dodaj słowa",
            },
            robotTimbreForm: {
              aixianatural: "Aisha (Natura)",
              zhishaChat: "Zhisha (czat)",
              zhiqiangentle: "Zhiqian (Łagodny)",
              zhimiEmotion: "Poznaj ryż (emocje)",
              aiShuoNatural: "Aishuo (Natura)",
              zhishuoChat: "Zhishuo (czat)",
              aiHaoAffinity: "Ai Hao (powinowactwo)",
              zhixiangEmotion: "Zhixiang (emocje)",
              robotVoice: "Dźwięk robota",
              schoolboy: "Chłopcy",
              girlStudent: "Dziewczyny",
              volume: "Głośność",
              tone: "Ton",
              speechSpeed: "Prędkość mowy",
              test: "Test",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "Witam, jestem inteligentnym trenerem treningowym i nie mogę się doczekać komunikacji z Tobą.",
            },
            sessionDetectionForm: {
              expressionDetection: "Wykrywanie ekspresji",
              excessiveDetectionOfModalParticles:
                "Nadmierne wykrywanie słów modalnych",
              theNumberOfRepetitionsIs: "Liczba powtórzeń wynosi",
              minus: "Czas, minus",
              appear: "Pojawiają się",
              subtract: "Czasy, minus",
              branch: "Punkty",
              addRules: "Dodaj regułę",
              includingNegativeAngryComplaining:
                "(W tym negatywne, wściekłe, narzekające)",
              scorePointsDisplay: "Wyświetlanie punktów punktowych",
              reminderOfSpeakingTooFasttooSlow:
                "Szybkie/zbyt wolne przypomnienie Speedtoo",
              scriptViolationReminder: "Przypomnienie o naruszeniu mowy",
              emotionalReminder: "Emocjonalne przypomnienie",
              speechSpeedDetection: "Wykrywanie prędkości mowy",
              normalSpeechSpeedScore: "PRĘDKOŚĆ NORMALNA WYNIKÓW",
              speakTooFast: "Szybko za szybko",
              everyMinuteGreaterThan: "Na minutę większe",
              aWordAppears: "Słowo, pojawiają się.",
              speakTooSlowly: "PRĘDKOŚĆ POWOLNA",
              lessThanPerMinute: "Mniej niż na minutę",
              ignoreLessThan: "Ignoruj mniej",
              aSentenceOfWords: "Zdanie słów",
              emotionalDetection: "Wykrywanie emocji",
              emotionalNormalScore: "Normalny wynik nastroju",
              negativeEmotions: "Negatywne emocje",
              illegalWordDetection: "Nielegalne wykrywanie słów",
              scriptViolationConfiguration: "Konfiguracja naruszenia mowy",
              realTimePromptOnTheFrontEnd:
                "Front-end w czasie rzeczywistym monit",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "Pytania i odpowiedzi w bazie wiedzy",
                  qAMethod: "Metoda pytań i odpowiedzi",
                  numberOfQuestionsAndAnswers: "Liczba pytań i odpowiedzi",
                  scoringRules: "Zasady punktacji",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "Każde pytanie i odpowiedź jest poprawne, masz",
                  branch: "Punkty",
                  successfullySaved: "Oszczędzaj pomyślnie",
                },
                pptNode: {
                  verificationPassed: "Weryfikacja przeszła",
                  upload: "Prześlij",
                  supportPptPdfFormatFiles:
                    "Obsługa plików w formacie ppt i pdf",
                  uploadSlide: "Prześlij slajdy",
                  assessmentMethod: "Metoda oceny",
                  associateSlide: "Slajdy stowarzyszone",
                  standardScript: "Standardowa mowa",
                  successfullySaved: "Oszczędzaj pomyślnie",
                },
                robotNode: {
                  robotScript: "Mowa robota",
                  randomScript: "Losowa mowa",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "Wybierz słowa o podobnej semantyce, a robot wypowie losowo jedno ze zdań",
                  customerPortrait: "Portret klienta",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "Kiedy portret klienta jest spójny, robot mówi to",
                  setAsDefaultScript: "Ustaw jako domyślny",
                  yes: "Tak",
                  no: "Nie",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "Jest to powiedziane, gdy robot nie trafia w jakikolwiek tag użytkownika.",
                  successfullySaved: "Oszczędzaj pomyślnie",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "Ten węzeł gałęzi ma już domyślną mowę. Czy chcesz zastąpić domyślną mowę aktualną?",
                },
                startNode: {
                  paragon: "Głos",
                  successfullySaved: "Oszczędzaj pomyślnie",
                },
                userNode: {
                  verificationPassed: "Weryfikacja przeszła",
                  standardScript: "Standardowa mowa",
                  successfullySaved: "Oszczędzaj pomyślnie",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration:
                    "Proszę udoskonalić konfigurację",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample:
                    "Wprowadź przykładową rozmowę",
                  dialogueExample: "Przykład konwersacji",
                  generateConversationExamples: "Wygeneruj przykład Dialog",
                  mattersNeedingAttention: "Środki ostrożności",
                  pleaseEnterThePromptLanguage: "Wprowadź monit",
                },
                aiGenSceneNode: {
                  dialogueScenario: "Scena dialogu",
                  simulateCharacter: "Symulowane postać",
                  accompanyingTrainees: "Obiekt towarzyszący",
                  selectFromTemplateLibrary: "Wybierz z biblioteki szablonów",
                  templateLibrary: "Szablon Library",
                  role: "Rola",
                  scene: "Scena",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "Okno testowe slajdów 【",
              clearContent: "Wyczyść zawartość",
              close: "Zamknij",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "Proces generowania AI",
                scriptStyle: "Styl mowy",
                theMostConversationRounds: "Większość rund dialogu",
                copyConversation: "Kopiowanie dialogów",
                application: "Aplikacja",
                regenerate: "Regenerować",
                startGenerating: "Rozpocznij generowanie",
                pleaseGenerateAProcess: "Proszę wygenerować proces",
                copySuccessful: "Skopiuj sukces",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "Przedstawiciel handlowy",
                    customerServicePersonnel: "Personel obsługi klienta",
                    storeCounter: "Licznik sklepu",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript:
                  "Generowanie umiejętności mowy pełnotekstowej",
                speaker: "Głośnik",
                scriptStyle: "Styl mowy",
                copyConversation: "Kopiowanie dialogów",
                application: "Aplikacja",
                regenerate: "Regenerować",
                startGenerating: "Rozpocznij generowanie",
                pleaseGenerateAProcess: "Proszę wygenerować proces",
                operationSuccessful: "Operacja powiodła się",
                copySuccessful: "Skopiuj sukces",
              },
            },
            aiTextArea: { index: { scriptPolishing: "Polski mowy" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "Słowa nie mogą być puste!",
              copySuccessful: "Skopiuj sukces",
              cancel: "Anuluj",
              copy: "Kopiowanie",
              replaceTheOriginalText: "Zastąp oryginał",
              aiGeneration: "Pokolenie Ai",
              aiPolishing: "Ai polski",
              currentStyle: "Obecny styl",
              rigorous: "Rygorystyczne",
              mild: "Delikatny",
              lively: "Żywy",
              simplicity: "Proste",
              pleaseEnterTheSpeakerRole: "Wprowadź rolę mówcy",
              startGenerating: "Rozpocznij generowanie",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "Słowa nie mogą być puste!",
              copySuccessful: "Skopiuj sukces",
              aiRewrite: "Ai Rewrite",
              cancel: "Anuluj",
              copy: "Kopiowanie",
              replaceTheOriginalText: "Zastąp oryginał",
              currentStyle: "Obecny styl",
              rigorous: "Rygorystyczne",
              mild: "Delikatny",
              lively: "Żywy",
              simplicity: "Proste",
              pleaseEnterTheSpeakerRole: "Wprowadź rolę mówcy",
              startGenerating: "Rozpocznij generowanie",
            },
          },
          index: {
            verificationPassed: "Weryfikacja przeszła",
            ruleValidation: "Weryfikacja zasady",
            aIGeneration: "Generowanie sztucznej inteligencji",
            generateFullTextSpeechScript:
              "Generowanie umiejętności mowy pełnotekstowej",
            editCourse: "Edytuj kurs",
            importConversation: "Importowanie konwersacji",
            uploadExcelToCompleteDialogueImport:
              "Prześlij Excel Complete Dialog Import",
            editBasicInformation: "Edytuj podstawowe informacje",
            test: "Test",
            release: "Opublikuj",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "Nowa etykieta" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "Nie można odzyskać po usunięciu. Czy na pewno chcesz usunąć tę etykietę?",
              delete: "Usuń",
              cancel: "Anuluj",
            },
          },
        },
        index: {
          labelManagement: "Zarządzanie etykietami",
          newGroup: "Nowa grupa",
          edit: "Edytuj",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "Nie można odzyskać po usunięciu. Czy na pewno chcesz usunąć tę grupę etykiet?",
          delete: "Usuń",
          cancel: "Anuluj",
          editGroup: "Edytuj grupowanie",
          labelGroupName: "Nazwa grupy etykiet",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "Kamera wyłączona",
              noCameraDevicesFound: "Nie znaleziono urządzenia z kamerą",
            },
          },
          cue: {
            index: {
              regenerate: "Regenerować",
              generate: "Generuj",
              referenceScript: "Mowa referencyjna",
              mattersNeedingAttention: "Środki ostrożności",
              examFrequency: "Liczba egzaminów",
              section: "Nie.",
              timestotal: "Czasy/Ogółem",
              second: "Czasy",
              requirementsForPassingTheExam:
                "Wymagania dotyczące zdania egzaminu",
              examScore: "Wynik testu ≥",
              branch: "Punkty",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "Brak portretu",
              customerBackground: "Tło klienta",
              customerLabel: "Etykieta klienta",
            },
          },
          flow: {
            index: {
              processReminder: "Prompt procesu (",
              score: "Wynik",
              TotalScore: "/Wynik całkowity",
            },
          },
          index: {
            taskName: "Nazwa zadania",
            taskStatus: "Status zadania",
            whole: "Wszystkie",
            completionStatus: "Status ukończenia",
            courseClassification: "Klasyfikacja kursu",
            practiceMode: "Tryb ćwiczeń",
            examMode: "Tryb badania",
            practiceTime: "Czas ćwiczeń",
          },
          ppt: {
            index: {
              nextPage: "Następna strona",
              tips: "Prompt",
              continueWithCurrentPage: "Kontynuuj bieżącą stronę",
              goToTheNextPage: "Przejdź do następnej strony",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "Po wejściu na następną stronę nie będziesz mógł wrócić do poprzedniej strony. Potwierdź, że wyjaśnienie bieżącej strony jest kompletne przed skokiem.",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "Kliknij na następną stronę nie pojawi się ten monit",
            },
            list: { index: { slide: "Slajd" } },
          },
          record: {
            index: {
              unableToRecord: "Nie można nagrać:",
              creatingARecorderService: "Utwórz usługę rejestratora",
              turnOffTheRecorderService: "Wyłącz usługę rejestratora",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "Bieżący klient jest już podłączony do serwera ASR Websocket",
              afterProcessingTheLastFrameRestoreTheState:
                "Po przetworzeniu ostatniej ramki przywróć stan",
              aSRConnectionClosed: "Połączenie ASR zamknięte...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "Nagranie jest wstrzymane i zostało przetworzone, ale nadal są dane w pamięci podręcznej",
              foundASRChannelClosedRecreateWebsocketLink:
                "Znaleziono kanał ASR zamknięty, odtworzyć łącze Websocket",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "Stwierdziwszy, że kanał ASR nie jest otwarty, ponownie utwórz łącze Websocket",
            },
            ppt: { hook: { index: { serverError: "Błąd serwera" } } },
            text: {
              index: {
                pleaseEnter: "Proszę wejść",
                sendOut: "Wyślij",
                startPracticing: "Zacznij ćwiczyć",
                startTheExam: "Rozpocznij egzamin",
              },
            },
            util: {
              recorded: "Nagrane",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16-bitowy pcm SendFrameSize musi być całkowitą wielokrotnością 2",
              pCMSamplingRateShouldNotOccur:
                "Częstotliwość próbkowania pcm nie powinna się pojawić",
              andTheRequiredSamplingRate:
                "I wymaganą częstotliwość próbkowania",
              atypism: "Niespójne",
              errorsThatShouldNotOccur: "Błędy, które nie powinny wystąpić:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> Dane, które są niesprawne, można odrzucić, jeśli wymagania nie są wysokie. </Span>',
              flower: "Kwiaty",
              TransferStopped: ": Transfer zatrzymany",
            },
            video: { index: { camera: "Aparat fotograficzny" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "Bieżący klient jest już podłączony do serwera ASR Websocket",
                    aSRConnectionClosed: "Połączenie ASR zamknięte...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "Znaleziono kanał ASR zamknięty, odtworzyć łącze Websocket",
                    unableToRecord: "Nie można nagrać:",
                    aSRChannelClosedReopened:
                      "Kanał ASR zamknięty, ponownie otwarty",
                    theASRChannelWasNotCreatedStartingNow:
                      "Kanał ASR nie został utworzony, zacznij tworzyć teraz",
                    useEffectCallbackToClearTheASRChannel:
                      "UseEffect callback, aby wyczyścić kanał ASR",
                    creatingARecorderService: "Utwórz usługę rejestratora",
                    turnOffTheRecorderService: "Wyłącz usługę rejestratora",
                  },
                  util: {
                    recorded: "Nagrane",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16-bitowy pcm SendFrameSize musi być całkowitą wielokrotnością 2",
                    pCMSamplingRateShouldNotOccur:
                      "Częstotliwość próbkowania pcm nie powinna się pojawić",
                    andTheRequiredSamplingRate:
                      "I wymaganą częstotliwość próbkowania",
                    atypism: "Niespójne",
                    errorsThatShouldNotOccur:
                      "Błędy, które nie powinny wystąpić:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> Dane, które są niesprawne, można odrzucić, jeśli wymagania nie są wysokie. </Span>',
                    flower: "Kwiaty",
                    TransferStopped: ": Transfer zatrzymany",
                  },
                },
                index: { failInSend: "Nie udało się wysłać" },
              },
              index: {
                startRecording: "Rozpocznij nagrywanie",
                endRecording: "Zakończ nagrywanie",
                startPracticing: "Zacznij ćwiczyć",
                startTheExam: "Rozpocznij egzamin",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(Obecna liczba pozostałych egzaminów to",
              totalScriptScore: "Łączny wynik mowy:",
              totalEmotionalScore: "Całkowity wynik emocjonalny:",
              totalScoreOfExpression: "Całkowity wynik ekspresji:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "Aktualne wyniki punktacji zadań należy przejrzeć ręcznie. Po zakończeniu przeglądu zostaniesz powiadomiony o ostatecznych wynikach. Proszę cierpliwie czekać.",
              areYouSureToSubmit: "Potwierdzić złożenie?",
              theExerciseHasEndedAreYouSureToSubmit:
                "Ćwiczenie się skończyło, czy na pewno chcesz je przesłać?",
              theExamHasEndedAreYouSureToSubmit:
                "Egzamin się skończył, czy na pewno chcesz go złożyć?",
              completed: "Ukończone",
              page: "Strona",
              pageCount: "Łączna liczba stron",
              topic: "Temat",
              totalNumberOfQuestions: "Łączna liczba pytań",
              totalScore: "Całkowity wynik",
              branch: "Punkty",
              accumulatedCompletionProgress: "Skumulowany postęp ukończenia",
              accumulatedPracticeDuration: "Skumulowany czas trwania praktyki",
              lastPauseExerciseResults: "Last Time-out-wyniki praktyki",
              exerciseInstructions: "Instrukcje ćwiczeń",
              examInstructions: "Opis egzaminu",
              halfwayPausePracticeResults:
                "Pauza w połowie drogi-wyniki praktyki",
              tips: "Prompt",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "Po rzuceniu palenia ćwiczenie to nie zostanie uwzględnione w wyniku. Czy na pewno chcesz rzucić?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "Rzucenie palenia nie jest dozwolone w połowie egzaminu. Potwierdzić złożenie?",
              pleaseOperateWithCaution: "Czasy, proszę działać ostrożnie)",
              restart: "Ponowne uruchomienie",
              returnToLearning: "Powrót do nauki",
              continuePracticing: "Kontynuuj praktykę",
              continuingTheExam: "Kontynuuj Egzamin",
              return: "Powrót",
              notSubmittedTemporarily: "Nie zostały przesłane",
              iGotIt: "Wiem.",
              confirmExit: "Potwierdź Wyjście",
              confirmSubmission: "Potwierdź poddanie",
              signOut: "Wyjście",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "Przerwa",
              submit: "Przedłożenie",
              remainingTimeOfThisQuestion: "Pozostały czas",
              SecondsLeftUntilTheEndTime: "10 sekund do końca czasu",
              remainingTime: "Pozostały czas",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "Nie wykryto treści głosowych użytkownika, spróbuj ponownie",
          },
          wave: {
            index: {
              continueRecording: "Kontynuuj nagrywanie",
              submit: "Przedłożenie",
              endRecording: "Zakończ nagrywanie",
              suspendRecording: "Wstrzymaj nagrywanie",
              startPracticing: "Zacznij ćwiczyć",
              startTheExam: "Rozpocznij egzamin",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "Tryb dialogu",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "Za dużo hałasu, przełącz się na ciche otoczenie",
              normalNoiseDetection: "Wykrywanie hałasu jest normalne",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "Głośność jest zbyt niska, proszę zwiększyć głośność",
              volumeDetectionIsNormal: "Wykrywanie objętości normalne",
              noiseDetection: "Wykrywanie hałasu",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "Proszę milczeć i nagrywać dźwięk otoczenia 3s",
              endRecording: "Zakończ nagrywanie",
              startRecording: "Rozpocznij nagrywanie",
              volumeDetection: "Wykrywanie głośności",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                'Proszę przeczytać "test głośności teraz" w normalnej objętości"',
              playLastDetectedAudio: "Odtwórz dźwięk z ostatniej detekcji",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "Przeczytaj uważnie wymagania, zanim zaczniesz.",
              return: "Powrót",
              iGotIt: "Wiem.",
              timeLimitForSingleQuestionAnswering:
                "Limit czasu odpowiedzi na jedno pytanie",
              totalTimeLimit: "Całkowity limit czasu",
              unlimitedTime: "Nieograniczony czas",
              stillNeedToCompleteTheTaskInTotal:
                "Nadal konieczne jest wykonanie zadania.",
              practice: "Ćwiczenie",
              second: "Czasy",
              taskScore: "Jeden wynik zadania ≥",
              branch: "Punkty",
              or: "Lub",
              examScore: "Wynik testu ≥",
              remainingExamTimes: "Liczba pozostałych egzaminów",
              totalScore: "Całkowity wynik",
              Script: "(Mówca",
              scoreEmotion: "Emocja",
              fractionExpression: "Subwyrażenie",
              points: "Punkty)",
              standardScore: "Standardowy wynik",
              excellentScore: "Doskonały wynik",
              exerciseInstructions: "Instrukcje ćwiczeń",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "Aby odpowiedzieć na pytanie płynnie, należy najpierw ukończyć test sprzętu:",
              camera: "Aparat fotograficzny",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "Możliwość zobaczenia siebie na filmie oznacza, że kamera jest normalna.",
              microphone: "Mikrofon",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "Proszę mówić do mikrofonu, patrz wzór przebiegu oznacza, że mikrofon jest normalny",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "Pusty",
                  copyTo: "Kopiowanie",
                  classification: "Klasyfikacja",
                  rename: "Zmiana nazwy",
                  newlyBuild: "Nowy",
                  categoryParent: "Wyższa klasyfikacja",
                  moveTo: "Przesuń",
                  name: "Imię i nazwisko",
                },
              },
              util: {
                rename: "Zmiana nazwy",
                moveTo: "Przesuń",
                copyTo: "Kopiowanie",
                delete: "Usuń",
                courseClassification: "Klasyfikacja kursu",
                newlyBuild: "Nowy",
              },
            },
          },
          edit: { index: { curriculum: "Program nauczania" } },
          index: {
            learn: "Uczenie się",
            practice: "Ćwicz",
            examination: "Test",
            curriculum: "Program nauczania",
            courseIntroduction: "Wprowadzenie do kursu:",
            createdOn: "Czas tworzenia:",
            published: "Opublikowano",
            tasks: "Zadania",
            unpublishedTasks: "Niepublikowane zadanie",
            task: "Zadania",
            operation: "Operacja",
            learningMaterials: "Materiały do nauki",
            goPractice: "Idź do praktyki",
            publishTask: "Zadania publikowania",
            taskData: "Dane zadaniowe",
            edit: "Edytuj",
            copy: "Kopiowanie",
            successfullyCopied: "Skopiuj sukces",
            delete: "Usuń",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "Po usunięciu wszystkie dane zadań w ramach tego kursu zostaną wyczyszczone. Czy na pewno usuniesz kurs?",
            courseName: "Nazwa kursu",
            creationTime: "Czas tworzenia",
            releaseStatus: "Opublikuj status",
            whole: "Wszystkie",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "Nie wykryto dźwięku",
              voice: "Głos",
              text: "Tekst",
              referenceScript: "Mowa referencyjna:",
              clickOnTheRightSideToStartRecording:
                "Kliknij po prawej stronie, aby rozpocząć nagrywanie",
              sendOut: "Wyślij",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "Bieżący klient jest już podłączony do serwera ASR Websocket",
                afterProcessingTheLastFrameRestoreTheState:
                  "Po przetworzeniu ostatniej ramki przywróć stan",
                aSRConnectionClosed: "Połączenie ASR zamknięte...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "Nagranie jest wstrzymane i zostało przetworzone, ale nadal są dane w pamięci podręcznej",
                foundASRChannelClosedRecreateWebsocketLink:
                  "Znaleziono kanał ASR zamknięty, odtworzyć łącze Websocket",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "Stwierdziwszy, że kanał ASR nie jest otwarty, ponownie utwórz łącze Websocket",
                unableToRecord: "Nie można nagrać:",
                creatingARecorderService: "Utwórz usługę rejestratora",
                turnOffTheRecorderService: "Wyłącz usługę rejestratora",
              },
              util: {
                recorded: "Nagrane",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16-bitowy pcm SendFrameSize musi być całkowitą wielokrotnością 2",
                pCMSamplingRateShouldNotOccur:
                  "Częstotliwość próbkowania pcm nie powinna się pojawić",
                andTheRequiredSamplingRate:
                  "I wymaganą częstotliwość próbkowania",
                atypism: "Niespójne",
                errorsThatShouldNotOccur: "Błędy, które nie powinny wystąpić:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> Dane, które są niesprawne, można odrzucić, jeśli wymagania nie są wysokie. </Span>',
                flower: "Kwiaty",
                TransferStopped: ": Transfer zatrzymany",
              },
            },
            finishTest: {
              index: {
                endTest: "Test końcowy",
                testResult: "Wyniki testu",
                returnToCanvas: "Wróć do płótna",
                retest: "Ponowne badanie",
              },
            },
          },
          index: {
            testFromCurrentNode: "Test z aktualnego węzła",
            listening: "Radio w...",
            canvasTesting: "Test płótna",
            endOfProcess: "Koniec procesu",
            restart: "Ponowne uruchomienie",
            start: "Start",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "Analiza zawalenia",
                  expandParsing: "Rozwiń Parse",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "Zbyt wiele modalnych słów",
                  second: "Czasy",
                  ratingOverview: "Przegląd punktacji",
                  totalScore: "Całkowity wynik",
                  section: "Nie.",
                  name: "Imię i nazwisko",
                  of: "(Ogółem",
                  people: "Ludzie)",
                  scriptScore: "Wynik mowy",
                  common: "Ogółem",
                  keyPoints: "Kluczowy punkt,",
                  notReached: "Nie osiągnięto,",
                  have: "Są",
                  violationOfRegulations: "Naruszenia",
                  emotionalScore: "Emotion Score",
                  appear: "Pojawiają się",
                  secondaryNegativeEmotions: "Wtórne negatywne emocje",
                  expressionScore: "Wynik wyrażenia",
                  speakTooFast: "Szybko za szybko",
                  times: "Czasy,",
                  speakTooSlowly: "PRĘDKOŚĆ POWOLNA",
                  average: "Średnia",
                  wordminute: "Słowo/minuta",
                },
              },
              score: {
                index: {
                  studentNodeName: "Nazwa węzła studenckiego",
                  keyPointName: "Nazwa punktu kluczowego",
                  slideNodeName: "Nazwa węzła slajdów",
                  dialogue: "Dialog",
                  mainPoints: "Kluczowe punkty",
                  slide: "Slajd",
                  detectionResult: "Wyniki testu",
                  satisfy: "Spotkaj się",
                  dissatisfaction: "Nie jestem zadowolony",
                  score: "Wynik",
                  script: "Słowa",
                  semantics: "Semantyczny",
                  scriptViolation: "Naruszenie mowy",
                  emotion: "Emocja",
                  expression: "Wyrażenie",
                },
              },
              taskTitle: { index: { branch: "Punkty" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "Tym razem",
                  lastTime: "Ostatni raz",
                  comprehensiveEvaluation: "Kompleksowa ocena",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-lewe: 8px">· Zbiorowa mowa standardowa',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Słowo, liczba słów trafionych przez ucznia",
                  aWordspan: "Słowo </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Czasy </span><br /><span style = "margin-lewe: 8px">· Powolna prędkość',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-lewe: 8px">· Zbyt wiele modalnych słów',
                  complete: "Kompletna treść",
                  keyHit: "Uderzenie kluczowych punktów",
                  languageNorm: "Specyfikacja języka",
                  fluentExpression: "Gładka ekspresja",
                  emotionallyPositive: "Emocjonalnie pozytywny",
                  radarChart: "Wykres radarowy",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-lewe: 8px">· Liczba pełnych przepływów',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-lewe: 8px">· Hit kluczowych punktów',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-lewe: 8px">· Tak',
                  dealingWithIllegalWordsspan: "Z naruszeniem słowa </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-lewe: 8px">· Mów zbyt szybko',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '</Span><br /><span style = "margin-lewe: 8px"> zbyt wiele modalnych słów',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-lewe: 8px">· Negatywne emocje',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "Rekord",
                  firstRating: "Ocena po raz pierwszy",
                  totalScore: "Całkowity wynik",
                  changeTo: "Zmień",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "Faza komunikacji",
                  communicationObjectives: "Cele komunikacji",
                  scoringCriteria: "Kryteria punktacji",
                  suggestionsForImprovement: "Rekomendacje promocyjne",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "Pozostałe:",
                  second: "Czasy",
                  doItAgain: "Jeszcze raz.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "Przeglądarka nie obsługuje odtwarzania dźwięku.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions:
                    "Komentarze i sugestie dotyczące mowy",
                  regenerate: "Regenerować",
                  generate: "Generuj",
                  generationFailed: "Budowa nie powiodła się",
                },
              },
              resultModal: {
                index: { return: "Powrót", viewDetails: "Zobacz szczegóły" },
              },
            },
            index: {
              reRatingSuccessful: "Ponowne zdobycie sukcesu",
              reRatingFailed: "Ponowne punktowanie nie powiodło się",
              downloadRecording: "Pobieranie nagrywania",
              downloadText: "Pobierz tekst",
              download: "Pobierz",
              reRating: "Ponowne punktowanie",
              submitARatingAppeal: "Składanie skarg na oceny",
              reviewSubmission: "Zgłosię do przeglądu",
              saveChanges: "Zapisz zmiany",
              previousSentence: "Poprzednie zdanie",
              nextSentence: "Następne zdanie",
              score: "Ocena",
              scoringSituation: "Wynik",
              dialogueAnalysis: "Analiza dialogu",
            },
          },
          index: {
            ratingAppealInProgress: "Punktacja skarg",
            operation: "Operacja",
            wrongQuestionSet: "Zły zestaw problemów",
            ratingUpdate: "Aktualizacja ratingowa",
            viewDetails: "Zobacz szczegóły",
            curriculum: "Program nauczania",
            taskName: "Nazwa zadania",
            taskType: "Typ zadania",
            score: "Wynik",
            scoringResults: "Wyniki wyników",
            time: "Czas",
            courseClassification: "Klasyfikacja kursu",
            whole: "Wszystkie",
            learningTime: "Czas nauki",
            historicalLearningData: "Historyczne dane uczenia się",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "Ćwicz pauzę",
              lastProgress: "Ostatni postęp",
              continuePracticing: "Kontynuuj praktykę",
              practice: "Ćwiczenie",
              startPracticing: "Zacznij ćwiczyć",
              historicalData: "Dane historyczne",
              courseIntroduction: "Wprowadzenie do kursu:",
              exerciseDifficulty: "Trudności w praktyce:",
              practiceCompletionRequirements:
                "Wymagania dotyczące ukończenia ćwiczeń:",
              practiceFrequency: "Liczba ćwiczeń ≥",
              second: "Czasy",
              anyScore: "Dowolny wynik ≥",
              branch: "Punkty",
              or: "Lub",
              taskList: "Lista zadań",
              practiceTime: "Czas ćwiczeń:",
              to: "Do",
              unlimitedTime: "Nieograniczony czas",
              completionStatus: "Status ukończenia",
              numberOfExercises: "Liczba ćwiczeń",
              maximumScore: "Najwyższy wynik",
              operation: "Operacja",
              enteringLearning: "Wejdź do nauki",
              historicalLearningData: "Historyczne dane uczenia się",
              wrongQuestionSet: "Zły zestaw problemów",
            },
          },
          index: {
            practiceTasks: "Ćwicz zadanie",
            examTasks: "Zadania egzaminacyjne",
            task: "Zadania",
          },
          learn: {
            index: {
              courseName: "Nazwa kursu",
              courseIntroduction: "Wprowadzenie do kursu",
              learningContent: "Treści do nauki",
              downloadLearningMaterials: "Materiały do nauki Pobieranie",
              startPracticing: "Zacznij ćwiczyć",
            },
          },
          test: {
            index: {
              taskList: "Lista zadań",
              courseIntroduction: "Wprowadzenie do kursu:",
              examDifficulty: "Trudność egzaminu:",
              requirementsForPassingTheExam:
                "Wymagania dotyczące zdania egzaminu:",
              examScore: "Wynik testu ≥",
              branch: "Punkty",
              examTime: "Czas badania:",
              to: "Do",
              unlimitedTime: "Nieograniczony czas",
              completionStatus: "Status ukończenia",
              completionTimes: "Liczba ukończeń",
              maximumScore: "Najwyższy wynik",
              operation: "Operacja",
              immediateExam: "Natychmiastowe badanie",
              history: "Historia",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "Mowa referencyjna" } },
              flow: {
                index: {
                  wrongQuestionItem: "Niewłaściwy przedmiot (",
                  score: "Wynik",
                  TotalScore: "/Wynik całkowity",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "Błąd serwera" } },
                  index: {
                    startRecording: "Rozpocznij nagrywanie",
                    endRecording: "Zakończ nagrywanie",
                    startPracticing: "Zacznij ćwiczyć",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "Proszę wejść",
                    sendOut: "Wyślij",
                    startPracticing: "Zacznij ćwiczyć",
                  },
                },
                voice: {
                  index: {
                    startRecording: "Rozpocznij nagrywanie",
                    endRecording: "Zakończ nagrywanie",
                    startPracticing: "Zacznij ćwiczyć",
                  },
                  hook: { index: { failInSend: "Nie udało się wysłać" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "Wynik tego złego ćwiczenia",
                  tips: "Prompt",
                  areYouSureToEnd: "Potwierdzić koniec?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "Ćwicz ponownie i nie opanuj niewłaściwych pytań.",
                  continue: "Kontynuuj",
                  returnToTheSetOfIncorrectQuestions:
                    "Powrót do niewłaściwego zestawu pytań",
                  end: "Koniec",
                  wrongQuestionItem: "Zły przedmiot",
                  numberOfExercisesThisTime: "Liczba tego ćwiczenia",
                  masteringTheSituation: "Chwyć sytuację",
                  mastered: "Opanowanie",
                  notMastered: "Nie opanowany",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "Ćwicz to pytanie ponownie",
                  nextQuestion: "Następne pytanie",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "Niewłaściwe ćwiczenie",
                endExercise: "Zakończ ćwiczenie",
              },
            },
            text: {
              index: {
                mispracticeExercises: "Niewłaściwe ćwiczenie",
                endExercise: "Zakończ ćwiczenie",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "Niewłaściwe ćwiczenie",
                endExercise: "Zakończ ćwiczenie",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "Zobacz mowę",
                  section: "Nie.",
                  secondaryDiscourseTechnique: "Mowa wtórna",
                },
              },
              tableItem: {
                count: { second: "Czasy" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "Nie znaleziono żadnych powiązanych informacji",
                  recallWords: "Przypomnij sobie słowo",
                  branch: "Punkty",
                  answerIncorrectly: "Zła odpowiedź",
                  second: "Czasy",
                  wrongAnswerRate: "Zły wskaźnik odpowiedzi",
                },
                standardScript: {
                  standardScript: "Standardowa mowa",
                  myScript: "Moje słowa",
                },
                status: {
                  mastered: "Opanowanie",
                  notMastered: "Nie opanowany",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "Łączna liczba odpowiedzi",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "Wiele razy praktykowano błędne pytania",
                },
              },
            },
            index: {
              wrongQuestionList: "Lista błędnych pytań",
              wrongQuestionSet: "Zły zestaw problemów",
              mispracticeExercises: "Niewłaściwe ćwiczenie",
              taskName: "Nazwa zadania",
              staff: "Pracownik",
              masteringTheSituation: "Chwyć sytuację",
              whole: "Wszystkie",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "Liczba ćwiczeń ≥",
              second: "Czasy",
              or: "Lub",
              excellentScoreAtAnyOneTime: "Każdy wynik doskonały (≥",
              points: "Punkty)",
              examScore: "Wynik testu ≥",
              branch: "Punkty",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "Losowy średni rozkład",
              assignToDesignatedPersonnel: "Przypisywanie osobom wyznaczonym",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams:
                "Sukces w zwiększaniu liczby egzaminów",
              failedToIncreaseTheNumberOfExams: "Zwiększ liczbę awarii testu",
              increaseTheNumberOfExams: "Zwiększ liczbę egzaminów",
              selectObjects: "Wybierz obiekt",
            },
          },
          executionFilter: {
            index: { participationRate: "Wskaźnik uczestnictwa" },
          },
          intelligentReview: {
            index: {
              overallReview: "Ogólny przegląd",
              pleaseEnterTheReviewCriterianotMandatory:
                "Wprowadź kryteria komentarza (nie są wymagane)",
              commentaryOnSingleSentenceRhetoric:
                "Uwagi dotyczące pojedynczego zdania",
              realTimeReview: "Recenzje w czasie rzeczywistym",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "Średni wynik testu",
            averageExamDurationmin: "Średni czas trwania egzaminu (min)",
            accumulatedPracticeDurationmin:
              "Skumulowany czas trwania praktyki (min)",
            accumulatedPracticeTimes: "Skumulowana liczba ćwiczeń",
            totalScore: "Całkowity wynik",
            average: "Średnia",
            total: "Ogółem",
            keyHitCount: "Liczba trafień kluczowych punktów",
            practiceAverageScore: "Ćwicz średni wynik",
            averagePracticeDurationmin: "Średni czas trwania ćwiczeń (min)",
            topOfTheHighestScore: "Najwyższy wynik Top3",
            branch: "Punkty",
            errorProneItemTop: "Przedmioty podatne na błędy Top5",
            hitRate: "Wskaźnik trafień",
            hitRateOfKeyPointsInScript:
              "Wskaźnik trafień kluczowych punktów mowy",
            averageScriptScore: "Średni wynik mowy",
            sortByProcess: "Sortuj według procesu",
            sortByScore: "Sortuj według oceny",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "Średni wynik tej strony/łączny wynik tej strony",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "Średni wynik tego przedmiotu/całkowity wynik tego przedmiotu",
            details: "Szczegóły",
            viewRules: "Wyświetl zasady",
            standardScript: "Standardowa mowa",
          },
          scoreDetailList: {
            section: "Nie.",
            second: "Czasy",
            practice: "Ćwiczenie",
            examination: "Badanie",
            notMeetingStandards: "Nie do standardu",
            meetingStandards: "Standardowy",
            excellent: "Doskonały",
            frequency: "Liczba razy",
            fraction: "Frakcja",
            result: "Wyniki",
            operation: "Operacja",
            viewDetails: "Zobacz szczegóły",
          },
          index: {
            common: "Ogółem",
            people: "Ludzie",
            staff: "Pracownik",
            notMeetingStandards: "Nie do standardu",
            meetingStandards: "Standardowy",
            excellent: "Doskonały",
            notStarted: "Nie rozpoczęto",
            haveInHand: "W toku",
            completed: "Ukończone",
            passed: "Przeszedł",
            hangInTheAir: "Niedokończone",
            failed: "Nie przeszedł",
            unlimitedTime: "Nieograniczony czas",
            to: "Do",
            maximumScoringCriteria: "Najwyższe kryteria punktacji",
            completionStatus: "Status ukończenia",
            employeeName: "Imię i nazwisko pracownika",
            frequency: "Liczba razy",
            practice: "Ćwiczenie",
            examination: "Badanie",
            average: "Średnia",
            speechCraftAvgScore: "Średni wynik mowy",
            emotionAvgScore: "Średni wynik emocjonalny",
            speedAvgScore: "Ekspresowy średni wynik",
            flowAvgScore: "Średni wynik procesu",
            practiceCostTime: "Skumulowany czas trwania",
            participateCommitRate: "Wskaźnik zgodności",
            participateExcellentRate: "Doskonała stawka",
            duration: "Czas trwania",
            score: "Wynik",
            scoringResults: "Wyniki wyników",
            flowScore: "Ocena procesu",
            craftScore: "Wynik mowy",
            emotionScore: "Emotion Score",
            speedScore: "Wynik wyrażenia",
            contentIntegrity: "Integralność treści",
            pointHitRate: "Wskaźnik trafień kluczowych punktów",
            emotionNegativeRate: "Ujemna proporcja emocji",
            paraPhaSiaRate: "Proporcja nieprawidłowej prędkości mowy",
            toneOvermuchRate: "Nadmierna proporcja słów modalnych",
            maximumScore: "Najwyższy wynik",
            highestScoreResult: "Najwyższe wyniki",
            state: "Status",
            averageDurationmin: "Średni czas trwania (min)",
            lately: "Ostatnio",
            time: "Czas",
            operation: "Operacja",
            viewDetails: "Zobacz szczegóły",
            wrongQuestionSet: "Zły zestaw problemów",
            taskName: "Nazwa zadania",
            completeRequirements: "Kompletne wymagania",
            publishTo: "Publikowanie obiektów",
            taskTime: "Czas zadania",
            fractionalComposition: "Skład frakcji",
            totalScore: "Całkowity wynik",
            branch: "Punkty",
            Script: "(Mówca",
            scoreEmotion: "Emocja",
            fractionExpression: "Subwyrażenie",
            points: "Punkty)",
            implementationRate: "Wskaźnik uczestnictwa",
            numberOfExecutors: "Liczba uczestników",
            numberOfPeopleNotExecuted: "Liczba osób niebędących uczestnikami",
            completionRate: "Wskaźnik ukończenia",
            numberOfCompletedPersonnel: "Liczba ukończona",
            numberOfUnfinishedPersonnel: "Liczba niedokończonych",
            complianceRate: "Wskaźnik zgodności",
            numberOfQualifiedPersonnel: "Liczba osiągnięć",
            numberOfNonCompliantIndividuals:
              "Liczba osób niespełniających normy",
            excellentRate: "Doskonała stawka",
            participateCompleteRate: "Wskaźnik ukończenia uczestnictwa",
            numberOfOutstandingIndividuals: "Wybitne liczby",
            numberOfNonOutstandingIndividuals: "Liczba niedoskonała",
            export: "Eksport",
            taskData: "Dane zadaniowe",
            resultAnalysis: "Analiza wyników",
            scoreDetails: "Szczegóły dotyczące oceny-",
            dataOverview: "Przegląd danych",
            taskDetail: "Szczegóły zadania",
            scriptAnalysis: "Analiza mowy",
            flowAnalysis: "Analiza procesu",
            expressionAnalysis: "Analiza ekspresji",
            emotionAnalysis: "Analiza nastrojów",
            byTime: "Widok wymiaru czasu",
            byNumber: "Widok wymiaru stopnia",
            personData: "Statystyki według personelu",
            numberData: "Szczegóły według rekordu",
            count: "Według liczby razy",
            person: "Według liczby osób",
            reachTimes: "Odprawy",
            outOfReachTimes: "Czasy nieosiągane",
            seeOutOfReach: "Kliknij, aby wyświetlić nieosiągnięty",
            accumulative: "Skumulowany",
            script: "Słowa",
            times: "Czasy",
            sentence: "Zdanie",
            expressionAverage: "Średni wynik ekspresji",
            modalAnalysis: "Analiza cząstki modalnej",
            total: "Ogółem",
            muchModalAnalysis: "Zbyt wiele modalnych słów",
            normal: "Normalny",
            speedAnalysis: "Analiza prędkości mowy",
            speedDistribution: "Rozkład szybkości mowy",
            speakingSpeed: "Prędkość mówienia",
            normalSpeak: "Normalna prędkość mowy",
            tooFastSpeak: "Speedy Speedy",
            tooSlowlySpeak: "Powolna prędkość",
            violationWord: "Naruszenie słów",
            violationWordAnalysis: "Analiza naruszeń słów",
            noData: "Nie",
            emotionalScore: "Emotion Score",
            emotionalDistribution: "Dystrybucja emocji",
            negativeEmotion: "Negatywne emocje",
            normalEmotion: "Normalne emocje",
            getDetail: "Zobacz szczegóły",
            barPattern: "Widok na bar",
            timeDimension: "Widok szerokości geograficznej czasu",
            timesDimension: "Widok wymiaru stopnia",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "Średnia",
                  averageScoreOfScript: "Średni wynik mowy",
                  averageEmotionalScore: "Średni wynik emocjonalny",
                  expressionAverageScore: "Ekspresowy średni wynik",
                  processAverageScore: "Średni wynik procesu",
                  accumulatedDuration: "Skumulowany czas trwania",
                  accumulatedTimes: "Skumulowane czasy",
                  participationComplianceRate: "Wskaźnik uczestnictwa",
                  participationRateForAchievingExcellence:
                    "Wskaźnik uczestnictwa",
                  branch: "Punkty",
                  numberOfPeople: "Liczba osób",
                  averageScoreOfExpression: "Średni wynik ekspresji",
                  people: "Ludzie",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "Negatywne emocje" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "Średni wynik mowy",
                  branch: "Punkty",
                },
                useSummaryDetail: {
                  keyHitRate: "Wskaźnik trafień kluczowych punktów",
                },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "Inteligentne recenzje",
            pleaseImproveTheConfiguration: "Proszę udoskonalić konfigurację",
            examResults: "Wyniki egzaminu",
            showTheResultsAfterEachExam: "Pokaż wyniki po każdym egzaminie.",
            showTheResultsAfterTheLastExam:
              "Pokaż wyniki po ostatnim egzaminie.",
            doNotDisplayResults: "Nie pokazuj wyników",
            resultDisplay: "Wyniki pokazują",
            score: "Wynik",
            scoringResults: "Wyniki wyników",
            allowViewingScoreDetails: "Pozwól, aby wyświetlić szczegóły wyniku",
            viewCanvasConfiguration: "Zobacz konfigurację płótna",
            synchronizationFailedProcessNodeHasChanged:
              "Synchronizacja nie powiodła się, węzeł procesu wygenerował zmiany",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "Synchronizacja jest udana, kliknij Opublikuj, aby zaktualizować kurs",
            retrievedTheLatestCanvasConfiguration:
              "Wyciągnięta najnowsza konfiguracja płótna",
            synchronizeCanvasConfiguration:
              "Synchronizacja konfiguracji płótna",
            scoringMethod: "Metoda punktacji",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "Punkty, przepływ {0} punktów, umiejętność mowy {1} punktów, emocje {2} punktów, prędkość {3} punktów)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "Standard doskonałości musi być wyższy niż standard zgodności.",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "Kryteria punktacji muszą być większe niż 0% i mniejsze lub równe 100",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "Kryteria punktacji muszą być większe niż 0 punktów i mniejsze lub równe {0} punktów.",
            doRatingsRequireManualReview:
              "Czy punktacja wymaga ręcznego przeglądu",
            allocationStrategy: "Polityka alokacji",
            pleaseSelectTheAssignedPersonnel: "Proszę wybrać przypisaną osobę",
            pleaseSelectADesignatedPerson: "Proszę wybrać wyznaczoną osobę",
            timeLimitForSingleQuestionAnswering:
              "Limit czasu odpowiedzi na jedno pytanie",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "Domyślnie publikuj do nowo dodanych pracowników",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "Ponowna punktacja wskazuje, że wynik zostanie dopasowany zgodnie z zasadami punktacji najnowszego płótna. Bieżące zadanie ma ręcznie sprawdzone dane punktacji. Czy chcesz nadpisać wynik?",
            notCovered: "Nie obejmuje",
            cover: "Osłona",
            participationSituation: "Udział",
            numberOfParticipants: "Liczba uczestników",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "Zlicz liczbę obiektów ćwiczeń wybranych do tego zadania",
            sumOfTheNumberOfParticipantsInTheTask:
              "Podsumuj liczbę osób, które uczestniczyły w zadaniu",
            numberOfCompletedPersonnel: "Liczba ukończona",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "Suma liczba osób, które spełniają wymagania dotyczące wykonania zadania",
            numberOfQualifiedPersonnel: "Liczba osiągnięć",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "Zsumuj liczbę osób, które spełniają wynik zadania",
            excellentPersonnel: "Wybitne liczby",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "Zsumuj liczbę osób, które spełniają zadanie, z doskonałym wynikiem",
            participationRate: "Wskaźnik uczestnictwa",
            numberOfParticipantsNumberOfParticipants:
              "Liczba uczestników/liczba uczestników",
            numberOfCompletedNumberOfParticipants:
              "Liczba ukończona/liczba powinna wziąć udział",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "Liczba osiągnięć/liczba uczestników",
            excellentNumbernumberOfParticipants:
              "Wybitna liczba/liczba uczestników",
            participationCompletionRate: "Wskaźnik ukończenia uczestnictwa",
            numberOfCompletedParticipated: "Liczba ukończona/uczestnicząca",
            participationComplianceRate: "Wskaźnik uczestnictwa",
            numberOfQualifiedPersonnelnumberOfParticipants:
              "Liczba osiągnięć/uczestnictwa",
            participationRateForAchievingExcellence: "Wskaźnik uczestnictwa",
            accumulatedDuration: "Skumulowany czas trwania",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "Weź udział w czasie trwania zadania i sumy",
            averageDuration: "Średni czas trwania",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "Całkowity czas trwania uczestników/całkowita liczba uczestników",
            accumulatedTimes: "Łączne czasy",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "Suma liczby uczestników zadania",
            averageScore: "Średnia",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "Średni wynik personelu zaangażowanego w zadanie",
            maximumScore: "Najwyższy wynik",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "Najwyższy wynik personelu zaangażowanego w zadanie",
            averageScoreOfScript: "Średni wynik mowy",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "Suma wyników mowy studenckiej/łączne czasy uczestnictwa",
            totalScoreOfScript: "Łączny wynik mowy",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "Całkowity wynik mowy ustawiony w ramach tego zadania",
            processAverageScore: "Średni wynik procesu",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "Suma punktacji procesu studenckiego/całkowita liczba zobowiązań",
            totalProcessScore: "Całkowity wynik procesu",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "Całkowity wynik procesu ustawionego w ramach tego zadania",
            averageEmotionalScore: "Średni wynik emocjonalny",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "Suma wyniku emocji stażysty/całkowitej liczby zobowiązań",
            totalEmotionalScore: "Całkowity wynik emocjonalny",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "Całkowity wynik emocjonalny ustawiony w ramach tego zadania",
            averageScoreOfExpression: "Średni wynik ekspresji",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "Suma wyniku ekspresji ucznia/całkowita liczba zobowiązań",
            totalScoreOfExpression: "Całkowity wynik wyrażenia",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "Całkowity wynik wyrażenia ustawiony w ramach tego zadania",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "Zacznij wykonywać ponowną punktację, możesz przeskoczyć do centrum zadań, aby zobaczyć postęp.",
            reRating: "Ponowne punktowanie",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "Ponowna punktacja wskazuje, że wynik zostanie dopasowany zgodnie z zasadami punktacji na najnowszym płótnie, a bieżący wynik zostanie nadpisany.",
            releaseTime: "Czas zwolnienia",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "Sprawdził",
              pendingReview: "Do przeglądu",
              enterKeywordSearch: "Wpisz słowa kluczowe, aby wyszukać",
            },
            pageRightTable: {
              index: {
                employeeName: "Imię i nazwisko pracownika",
                systemRating: "Wynik systemu",
                scoringResults: "Wyniki wyników",
                duration: "Czas trwania",
                time: "Czas",
                state: "Status",
                whetherToModifyTheRating: "Prawidłowy wynik",
                finalScore: "Wynik końcowy",
                reviewedBy: "Recenzent",
                operation: "Operacja",
                viewScoreDetails: "Wyświetl szczegóły wyniku",
                viewStatus: "Wyświetl status",
                whole: "Wszystkie",
                correctionStatus: "Status korekty",
                completeReview: "Kompletny przegląd",
                tips: "Prompt",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "Ostateczne wyniki stażystów podlegają aktualnym końcowym wynikom przeglądu. Potwierdź",
              },
            },
          },
          index: { manualReview: "Przegląd ręczny" },
        },
        review: {
          index: {
            employeeName: "Imię i nazwisko pracownika",
            score: "Wynik",
            scoringResults: "Wyniki wyników",
            duration: "Czas trwania",
            taskName: "Nazwa zadania",
            numberOfAppealScripts: "Liczba skarg",
            appealTime: "Czas odwołania",
            processStatus: "Status procesu",
            scoreAfterReview: "Wynik po przeglądzie",
            reviewedBy: "Recenzent",
            operation: "Operacja",
            viewScoreStatus: "Zobacz wynik",
            whole: "Wszystkie",
            manualReview: "Przegląd ręczny",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "Dane zadania ćwiczenia",
            implementationRate: "Wskaźnik realizacji",
            numberOfExecutors: "Liczba wykonań",
            numberOfPeopleNotExecuted: "Liczba niewykonanych",
            completionRate: "Wskaźnik ukończenia",
            numberOfCompletedPersonnel: "Liczba ukończona",
            numberOfUnfinishedPersonnel: "Liczba niedokończonych",
            complianceRate: "Wskaźnik zgodności",
            numberOfQualifiedPersonnel: "Liczba osiągnięć",
            numberOfNonCompliantIndividuals:
              "Liczba osób niespełniających normy",
            excellentRate: "Doskonała stawka",
            numberOfOutstandingIndividuals: "Wybitne liczby",
            numberOfNonOutstandingIndividuals: "Liczba niedoskonała",
            examTaskData: "Dane zadań egzaminacyjnych",
            published: "Opublikowano",
            courses: "Kurs",
            courseData: "Dane kursu",
            practiceTasks: "Ćwicz zadanie",
            examTasks: "Zadania egzaminacyjne",
          },
          practiceSummary: {
            practiceSummary: "Podsumowanie praktyki",
            numberOfPracticeTasks: "Liczba zadań praktycznych",
            numberOfExercises: "Liczba ćwiczeń",
            accumulatedPracticeDuration: "Skumulowany czas trwania praktyki",
            complianceRate: "Wskaźnik zgodności",
            excellentRate: "Doskonała stawka",
            ranking: "Ranking",
          },
          rankingList: {
            numberOfExercises: "Liczba ćwiczeń",
            exerciseDuration: "Czas trwania ćwiczenia",
            complianceRate: "Wskaźnik zgodności",
            excellentRate: "Doskonała stawka",
            diligenceChart: "Lista staranności",
            excellentList: "Doskonała lista",
            ranking: "Rankingi",
            fullName: "Imię i nazwisko",
            i: "Ja...",
          },
          taskCard: {
            end: "Termin:",
            noDeadline: "Brak terminu",
            second: "Czasy",
            branch: "Punkty",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "Brak zadań",
            completed: "Ukończone",
          },
          filter: { employeeDepartment: "Dział pracowniczy" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "Eksport się powiódł, przejdź do centrum zadań, aby wyświetlić",
              export: "Eksport",
            },
          },
          filterConfig: {
            dropdown: { custom: "Niestandardowe" },
            modal: {
              updateSuccessful: "Aktualizacja zakończyła się sukcesem",
              successfullySaved: "Oszczędzaj pomyślnie",
              setAsACommonReport: "Ustaw na wspólne raporty",
              updateCurrentReport: "Aktualizacja raportu bieżącego",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "Średnia",
                  averageScoreOfScript: "Średni wynik mowy",
                  averageEmotionalScore: "Średni wynik emocjonalny",
                  expressionAverageScore: "Ekspresowy średni wynik",
                  processAverageScore: "Średni wynik procesu",
                  branch: "Punkty",
                },
              },
              comprehensiveEvaluation: { const: { data: "Dane" } },
              doneStatus: {
                index: {
                  practice: "Ćwiczenie",
                  examination: "Badanie",
                  executed: "Wykonaj",
                  unexecuted: "Nie stracony",
                  accumulatedExamDuration: "Skumulowany czas trwania testu",
                  examFrequency: "Liczba egzaminów",
                },
              },
              filter: {
                index: {
                  practice: "Ćwiczenie",
                  examination: "Badanie",
                  pleaseCompleteTheRequiredFields:
                    "Proszę wypełnić wymagane przedmioty",
                  staff: "Pracownik",
                  taskType: "Typ zadania",
                  pleaseSelect: "Proszę wybrać",
                  taskName: "Nazwa zadania",
                },
              },
            },
            index: {
              completionStatus: "Status ukończenia",
              comprehensiveEvaluation: "Kompleksowa ocena",
              abilityGrowthTrend: "Trend wzrostu mocy produkcyjnych",
              errorProneAnalysis: "Analiza punktów podatnych na błędy",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "Numer aktywny",
            accumulatedPracticeDuration: "Skumulowany czas trwania praktyki",
            averagePracticeDuration: "Średni czas ćwiczeń",
            people: "Ludzie",
            numberOfPracticeTasks: "Liczba zadań praktycznych",
            numberOfExercises: "Liczba ćwiczeń",
            exerciseDuration: "Czas trwania ćwiczenia",
            second: "Czasy",
            avgScore: "Średnia",
            speechCraftAvgScore: "Średni wynik mowy",
            emotionAvgScore: "Średni wynik emocjonalny",
            speedAvgScore: "Ekspresowy średni wynik",
            flowAvgScore: "Średni wynik procesu",
            practiceCostTime: "Skumulowany czas trwania",
            totalPracticeTimes: "Skumulowane czasy",
            participateCommitRate: "Wskaźnik uczestnictwa",
            participateExcellentRate: "Wskaźnik uczestnictwa",
          },
        },
        index: {
          employeeProfile: "Portret pracownika",
          overview: "Przegląd",
          personalSignage: "Osobisty Kanban",
          ongoingExercises: "Ćwiczenia w toku",
          ongoingExams: "Badanie w toku",
          practiceTaskProgress: "Postęp w zadaniach ćwiczeń",
          examTaskProgress: "Postęp zadania testowego",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "Proszę wybrać słowo koncepcyjne",
              sessionDetectionSettings: "Ustawienia wykrywania sesji",
              successfullySaved: "Oszczędzaj pomyślnie",
              abnormalSpeechSpeed: "Nienormalna prędkość mowy",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "Obsługiwany jest standard wykrywania prędkości mowy zdefiniowany przez użytkownika. Rezultatem będzie normalna prędkość mowy, zbyt duża prędkość mowy i zbyt niska prędkość mowy. Gdy prędkość mowy jest nienormalna (zbyt szybka/zbyt wolna), wynik zostanie zmniejszony zgodnie z zasadami punktacji.",
              speechSpeedTooFastGreaterThanEveryMinute:
                "Speedtoo szybko: więcej niż jedna minuta",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "Słowo, zbyt wolne: mniej niż na minutę",
              charactersIgnoringLessThan: "Słowa, ignoruj mniej",
              aSentenceOfWords: "Zdanie słów",
              excessiveModalParticles: "Zbyt wiele modalnych słów",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "Wspieraj niestandardowe słowa tonowe i ustal zasady zbyt wielu słów tonowych. Gdy jest zbyt wiele słów modalnych, wynik zostanie zmniejszony zgodnie z zasadami punktacji.",
              theNumberOfRepetitionsIs: "Liczba powtórzeń wynosi",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "Za drugim razem ustalono, że jest zbyt wiele słów modalnych.",
              definitionOfSensitiveViolations:
                "Definicja wrażliwego naruszenia",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "Możesz dostosować leksykon nielegalnych słów, aby wykryć, czy w zdaniu pojawiają się jakieś nielegalne słowa. W przypadku nielegalnego słowa wynik zostanie zmniejszony zgodnie z zasadami punktacji.",
              violationWords: "Naruszenie słów:",
              emotionalDetection: "Wykrywanie emocji",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "Zgodnie z dialogiem modelu emocji systemu, test emocji zostanie oceniony jako normalna emocja i negatywne emocje (w tym negatywne, gniewne i narzekające). Gdy pojawi się negatywna emocja, wynik zostanie zmniejszony zgodnie z zasadami punktacji.",
              preserve: "Zapisz",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "Wymiar oceny",
              ratingPoints: "Punkty punktacji",
              explain: "Opis",
              type: "Rodzaj",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "Losowe dane" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-lewe: 8px">· Zbiorowa mowa standardowa',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "Słowo, liczba słów trafionych przez ucznia",
                  aWordspan: "Słowo </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Czasy </span><br /><span style = "margin-lewe: 8px">· Powolna prędkość',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-lewe: 8px">· Zbyt wiele modalnych słów',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "Wynik jest" },
                  item: {
                    violationOnceReduced: "1 naruszenie minus",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "Nienormalna prędkość (w tym zbyt duża prędkość i zbyt mała prędkość) 1 spadek",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "Zbyt wiele modalnych słów 1 minus",
                    oneNegativeDecreaseInEmotions: "Negatywne emocje minus 1",
                    branch: "Punkty",
                  },
                  wrap: { name: "Imię i nazwisko", preserve: "Zapisz" },
                },
              },
              index: {
                complete: "Kompletna treść",
                keyHit: "Uderzenie kluczowych punktów",
                accurateFollowupReading: "Dokładny odczyt",
                languageNorm: "Specyfikacja języka",
                speechSpeed: "Prędkość mowy",
                modalParticles: "Słowo modalne",
                emotionallyPositive: "Emocjonalnie pozytywny",
                scoringModel: "Model punktacji",
                successfullyModified: "Modyfikacja się powiodła",
                newSuccessfullyCreated: "Nowy sukces",
              },
            },
            index: {
              scoringModel: "Model punktacji",
              newModel: "Nowy model",
              model: "Model",
              type: "Rodzaj",
              operation: "Operacja",
              preview: "Podgląd",
              edit: "Edytuj",
            },
          },
          index: {
            scoringModel: "Model punktacji",
            ratingDimension: "Wymiar oceny",
            sessionDetectionSettings: "Ustawienia wykrywania sesji",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "Proszę wybrać",
                hit: "Uderzenie",
                misses: "Przegapione",
              },
            },
          },
          index: {
            duration: "Czas trwania",
            processScore: "Ocena procesu",
            branch: "Punkty",
            contentCompleteness: "Integralność treści",
            scriptScore: "Wynik mowy",
            keyHitRate: "Wskaźnik trafień kluczowych punktów",
            emotionalScore: "Emotion Score",
            negativeProportionOfEmotions: "Ujemna proporcja emocji",
            expressionScore: "Wynik wyrażenia",
            theProportionOfAbnormalSpeechSpeed:
              "Proporcja nieprawidłowej prędkości mowy",
            excessiveProportionOfModalParticles:
              "Nadmierna proporcja słów modalnych",
            learningRecordData: "Uczenie się rekordowych danych",
            staff: "Pracownik",
            curriculum: "Program nauczania",
            mainPoints: "Kluczowe punkty",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "Stół",
                        lineChart: "Wykres liniowy",
                        barChart: "Histogram",
                        pieChart: "Wykres na ciasto",
                      },
                      index: { switchingTypes: "Typ przełącznika" },
                    },
                  },
                  index: {
                    saveSuccessful: "Oszczędzaj pomyślnie",
                    editChart: "Edytuj wykres",
                    chartName: "Nazwa wykresu",
                    deleteSuccessful: "Pomyślnie usunięte",
                    refresh: "Odśwież",
                    export: "Eksport",
                    areYouSureToDeleteIt: "Czy na pewno chcesz usunąć?",
                    delete: "Usuń",
                    viewFilteringCriteria: "Zobacz Kryteria Filtrowania",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "Eksport się powiódł, przejdź do centrum zadań, aby wyświetlić",
              },
            },
            header: {
              index: {
                saveSuccessful: "Oszczędzaj pomyślnie",
                deleteSuccessful: "Pomyślnie usunięte",
                editReport: "Edytuj raport",
                reportName: "Nazwa raportu",
                refresh: "Odśwież",
                areYouSureToDeleteIt: "Czy na pewno chcesz usunąć?",
                delete: "Usuń",
                exportToExcel: "Eksport Excela",
                exportSnapshot: "Eksportuj migawkę",
                export: "Eksport",
                authority: "Uprawnienia",
                permissionSettings: "Ustawienia uprawnień",
                reportVisiblePermissions: "Zgłoś widoczne uprawnienia",
                visibleToEveryone: "Widoczny dla wszystkich",
                onlyVisibleToOneself: "Widoczne tylko dla siebie",
                designatedPersonnelVisible: "Określona osoba widoczna",
                pleaseSelect: "Proszę wybrać",
                reportDataPermissions: "Zezwolenie na dane raportu",
                restrictedByUserPermissions:
                  "Ograniczone przez uprawnienia użytkownika",
                visiblePeopleAreTheSame: "Widoczne osoby są takie same.",
              },
            },
            titleFooter: {
              index: { addReport: "Nowy raport", reportName: "Nazwa raportu" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Eksport się powiódł, przejdź do centrum zadań, aby wyświetlić",
            dataQueryInProgress: "Zapytanie o dane w...",
            customKanban: "Niestandardowe Kanban",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "Stół",
                lineChart: "Wykres liniowy",
                barChart: "Histogram",
                pieChart: "Wykres na ciasto",
              },
              index: {
                successfullyAdded: "Dodano pomyślnie",
                viewSQL: "Widok SQL",
                export: "Eksport",
                addToReport: "Dodaj do raportu",
              },
            },
            refer: {
              hooks: {
                data: "Dane",
                interrogativeSentence: "Pytania",
                historicalQuery: "Zapytanie historyczne",
              },
              index: { copySuccessful: "Skopiuj sukces", copy: "Kopiowanie" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "Nie do standardu",
                        meetingStandards: "Standardowy",
                        excellent: "Doskonały",
                        notStartedYet: "Nie rozpoczęto",
                        inProgress: "W toku",
                        completed: "Ukończone",
                        incomplete: "Niedokończone",
                      },
                    },
                  },
                  const: {
                    taskName: "Nazwa zadania",
                    department: "Departament",
                    studentName: "Nazwa studenta",
                    scoringResults: "Wyniki wyników",
                    taskCompletionStatus: "Status ukończenia zadania",
                  },
                  index: { pleaseSelectADimension: "Wybierz wymiar" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "Wprowadź @, aby otworzyć bullet box do wyboru wymiaru",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "Wprowadź instrukcję zapytania, aby bezpośrednio zapytać",
                query: "Zapytanie",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "Eksport się powiódł, przejdź do centrum zadań, aby wyświetlić",
            dataQueryInProgress: "Zapytanie o dane w...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "Wyszukiwanie słów kluczowych",
                previous: "Poprzedni",
                next: "Następny",
                theObjectThatTriggersTheScrollingEventIs:
                  "Obiekt, który wyzwala zdarzenie przewijania, to",
                userScrolling: "Zwój użytkownika",
              },
            },
            summary: {
              index: {
                reminder: "Przypomnienie:",
                edit: "Edytuj",
                name: "Imię i nazwisko",
                hintLanguage: "Prompt",
              },
            },
            viewpoint: {
              index: { viewpointExtraction: "Ekstrakcja punktu widzenia" },
            },
            outline: {
              index: {
                numberOfKeywords: "Liczba słów kluczowych",
                speaker: "Głośnik",
                speechDuration: "Czas trwania mówienia",
                proportionOfSpeaking: "Współczynnik mówienia",
                maximumSpeechDuration: "Maksymalna długość mowy",
                numberOfQuestions: "Liczba pytań",
                averageSpeechSpeed: "Średnia prędkość",
                conversationalActions: "Akcja sesyjna",
                contentSummary: "Podsumowanie treści",
                generateSummary: "Wygeneruj podsumowanie",
                keywordCloud: "Słowa kluczowe słowo chmura",
              },
            },
          },
          index: {
            outline: "Podsumowanie",
            viewpoint: "Opinia",
            contentSummary: "Podsumowanie treści",
            viewpointExtraction: "Ekstrakcja punktu widzenia",
            summaryOfKeyPoints: "Podsumowanie kluczowych punktów",
            videoAnalysis: "Analiza wideo",
          },
        },
        list: {
          index: {
            delete: "Usuń",
            uploadSuccessful: "Udało się przesłać",
            videoList: "Lista wideo",
            uploadVideo: "Prześlij wideo",
            videoName: "Nazwa wideo",
            uploadTime: "Czas przesyłania",
            endTime: "Czas zakończenia",
            analyzeProgress: "Analizuj postęp",
            haveInHand: "W toku",
            completed: "Ukończone",
            fail: "Nie powiodło się",
            toBegin: "Na początek",
            operation: "Operacja",
            see: "Widok",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "Dodaj czas pracy" },
        },
      },
      index: {
        businessWorkingHours: "Godziny pracy w biznesie",
        newlyBuild: "Nowy",
        name: "Imię i nazwisko",
        describe: "Opis",
        operation: "Operacja",
        edit: "Edytuj",
        delete: "Usuń",
        editBusinessWorkingHours: "Edytuj godziny pracy w firmie",
        newBusinessWorkingHours: "Nowe godziny pracy firmy",
        workingHours: "Czas pracy",
      },
    },
    algorithmSemanticIntelligence: {
      intelligentExtraction: "Inteligentna ekstrakcja",
    },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "Czy na pewno chcesz anulować? Anulowanie nie będzie możliwe",
        },
      },
    },
    accountManagement: {
      securitySetting: "Ustawienia zabezpieczeń",
      accountManagement: "Zarządzanie kontem",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "Do",
            switchToIPAddressMode: "Przełącz na tryb adresu IP",
            switchToIPSegmentMode: "Przełącz na tryb segmentu IP",
          },
        },
      },
      index: {
        saveSuccessful: "Oszczędzaj pomyślnie",
        pleaseImproveTheConfiguration: "Proszę udoskonalić konfigurację",
        securitySetting: "Ustawienia zabezpieczeń",
        employeeLoginIPRestrictions:
          "Ograniczenia dotyczące logowania pracowników IP",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "Ogranicz zakres IP, do którego pracownicy mogą się logować. Logowanie nie jest dozwolone poza zasięgiem. Tylko ważne dla stron internetowych, aplikacje mobilne nie są ograniczone, a administratorzy nie są ograniczeni.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "Podczas dodawania lub modyfikowania wprowadź zewnętrzny segment IP sieci (ten sam segment C) lub stały zewnętrzny adres IP sieci.",
        allowLoginIPRange: "Zakres IP pozwolił się zalogować",
        preserve: "Zapisz",
      },
    },
    coachAdminEntry: { speechRecognition: "Rozpoznawanie mowy" },
    billingCenter: {
      template: {
        details: "Szczegóły",
        languageType: "Rodzaj języka",
        aSREngine: "Silnik ASR",
        voiceQualityQuota: "Kwota jakości głosu",
        realtimeData: "Dane w czasie rzeczywistym",
        calculatedByDay: "Obliczane według dnia",
      },
      component: {
        aSRName: "Nazwa ASR",
        language: "Język",
        remainingQuota: "Pozostała kwota",
        hours: "Godziny",
        usedQuota: "Kwota używana",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "Operacja zapisana pomyślnie!",
        deletedSuccessfully: "Usunięty pomyślnie!",
        pleaseSelectLanguage: "Proszę wybrać język",
        pleaseConfigureRules: "Proszę skonfigurować reguły",
        updateSuccessful: "Aktualizacja udana!",
        operationSuccessful: "Operacja zakończyła się sukcesem!",
      },
      template: {
        aSRLanguage: "Język ASR",
        pleaseSelect: "Proszę wybrać",
        model: "Model",
        pleaseSelectLanguage: "Proszę wybrać język",
        delete: "Usuń",
        newASRModel: "Nowy model ASR",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "Analiza konkurencji" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "Filtr przeglądu handlu elektronicznego",
        allComments: "Wszystkie komentarze",
        ecommerceReviewManagement: "Zarządzanie przeglądami e-commerce",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "Filtr przeglądu mediów społecznościowych",
        allComments: "Wszystkie komentarze",
        socialMediaReviewManagement:
          "Zarządzanie przeglądem mediów społecznościowych",
      },
    },
    conversationAnalysisDataAnalysis: {
      competitionAnalysis: "Analiza konkurencji",
    },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "Avatar",
        realname: "Imię i nazwisko",
        nickname: "Pseudonim",
        name: "Imię i nazwisko",
        email: "Numer konta (e-mail)",
        mobile: "Telefon",
        agentId: "Praca nr.",
        position: "Wpis",
        role: "Rola",
        actions: "Operacja",
        createUser: "Nowy pracownik",
        editUser: "Edytuj pracownika",
        account: "Numer konta",
        originPassword: "Hasło początkowe",
        newPassword: "Nowe hasło",
        resumePassword: "Potwierdź hasło",
        userGroup: "Grupa pracowników",
        authorizationGroup: "Grupa uprawnień",
        resetPassword: "Zresetuj hasło",
      },
      userGroups: {
        id: "ID",
        name: "Imię i nazwisko",
        userCount: "Liczba pracowników",
        actions: "Operacja",
        createUserGroup: "Dodaj grupę pracowników",
        editUserGroup: "Edytuj grupę pracowników",
        member: "Członkowie",
      },
      role: {
        id: "ID",
        name: "Imię i nazwisko",
        actions: "Operacja",
        all: "Wszystkie",
        taskName: "Zadania",
        readTask: "Widok zadań",
        manageTemplate: "Zarządzanie szablonami",
        manualInspection: "Przegląd ręczny",
        readResultAll: "Widok wyników",
        readResultPersonal: "Widok wyników (indywidualny)",
        readProcess: "Zobacz proces kontroli jakości",
        readReport: "Statystyki i sprawozdania",
      },
    },
    download: {
      downloadCenter: {
        createTime: "Data",
        taskName: "Imię i nazwisko",
        size: "Rozmiar",
        status: "Status",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "Imię i nazwisko",
            remark: "Opis",
            actions: "Operacja",
          },
          checkPoint: {
            name: "Imię i nazwisko",
            remark: "Opis",
            type: "Rodzaj",
            gradeType: "Metoda oceny",
            conditionLogic: "Zasady",
            predeterminedScore: "Wynik",
            weight: "Waga",
            status: "Status",
            actions: "Operacja",
            switchOn: "Włącz",
            switchOff: "Dezaktywuj",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "Czas",
            customer: "Klient",
            customerPhone: "Telefon klienta",
            callType: "Rodzaj połączenia",
            source: "Źródło",
            voiceRecording: "Nagrywanie rozmów",
            graded: "Ocena",
            openingGreeting: "Pozdrowienia otwarcia",
          },
          checkPointList: {
            name: "Punkt kontroli jakości",
            type: "Rodzaj",
            deductScore: "Frakcja",
            isHit: "Przypadek trafienia",
            status: "Przegląd",
            finalDeductScore: "Potwierdź odliczenie punktów",
            hit: "Uderzenie",
            notHit: "Przegapione",
          },
        },
      },
    },
    custom: { keywords: "Kluczowe słowa dialogu" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "Ustawienia systemowe" },
      systemIntegration: {
        text: "Integracja systemu",
        subTitle: {
          udesk: "System obsługi klienta Udesk",
          udeskWhite: "System obsługi klienta",
          wechat: "WeChat dla przedsiębiorstw",
          taobao: "Taobao Qianniu",
          weifeng: "Weifeng",
        },
      },
      columnsManage: { text: "Zarządzanie polowe inspekcją jakości" },
      customerField: { text: "Pole klienta" },
      speechRecognition: { text: "Rozpoznawanie mowy" },
      user: { text: "Zarządzanie pracownikami" },
      userGroups: { text: "Zarządzanie grupą pracowników" },
      role: { text: "Zarządzanie Role" },
      dataMigration: { text: "Migracja danych" },
      taskCenter: { text: "Centrum misji" },
      recordUpload: { text: "Lokalne przesyłanie nagrań" },
      anomalousData: { text: "Nieprawidłowe dane" },
      systemLog: { text: "Dziennik wiadomości" },
      msgCenter: { text: "Centrum wiadomości" },
      wechatDocking: { text: "Mikro dokowanie przedsiębiorstwa" },
    },
    tasks: {
      homePage: { text: "Strona główna" },
      workbench: { text: "Stół warsztatowy" },
      semanticIntelligence: { text: "Inteligencja semantyczna" },
      intelligentAnalysis: { text: "Inteligentna analiza" },
      intelligentLabel: { text: "Inteligentna etykieta" },
      admin: { text: "Zarządzanie" },
      callManage: { text: "Zarządzanie połączeń" },
      dialogueManage: { text: "Zarządzanie dialogiem" },
      ticketManage: { text: "Zarządzanie zamówieniem pracy" },
      dataAnalysis: { text: "Analiza danych" },
      customerService: { text: "Centrum obsługi klienta" },
      clientCenter: { text: "Centrum klienta" },
      insight: { text: "Insight" },
      trainingCenter: { text: "Centrum szkoleniowe" },
      templates: { text: "Zarządzanie szablonami" },
      wechatQaTasks: { text: "Stół warsztatowy WeChat dla przedsiębiorstw" },
      wechatQaSetting: { text: "Konfiguracja analizy WeChat Enterprise" },
      wechatQaChartRecord: { text: "Sesja WeChat dla przedsiębiorstw" },
      wechatStaffCenter: { text: "Centrum mikro pracowników przedsiębiorstwa" },
      callAnalysis: { text: "Analiza konwersacji" },
      caseBaseFolder: { text: "Biblioteka przypadków" },
      coachTask: { text: "Zadania mentorskie" },
      teamAnalysis: { text: "Analiza zespołu" },
      customer: { text: "Centrum klienta" },
      analysisEnable: { text: "Analiza Empowerment" },
      analysisHelp: { text: "Analiza pomocy" },
      analysisStudy: { text: "Analiza uczenia się" },
      analysisReview: { text: "Analiza dysku złożonego" },
      analysisTags: { text: "Inteligentna analiza etykiet" },
      analysisSentiment: { text: "Analiza opinii publicznej" },
      analysisBusiness: { text: "Analiza biznesowa" },
      groupTest: { text: "Test grupowy" },
    },
    navBar: {
      billingCenter: "Centrum pomocy",
      uploadDownload: "Przesyłanie i pobieranie Center",
      setting: "Ustawienia systemowe",
    },
  },
  fix: {
    selectType: "Wybierz typ:",
    all: "Wszystkie",
    call: "Zadzwoń",
    text: "Dialog",
    wechat: "Sesja WeChat dla przedsiębiorstw",
    businessTrendAnalysis:
      "Analiza trendów biznesowych klasyfikacji na poziomie 1",
    noEchart: "Brak danych dla wykresu",
    checkPointisRequired: "Wymagana jest konfiguracja reguły",
    voiceDownLoad: "Pobieranie nagrywania",
    exportTxt: "Eksportuj tekst TXT",
    exportWord: "Eksport tekstu tekstowego",
    sureReRole: "Czy potwierdzenie odwraca rolę mówcy?",
    sureRecover: "Potwierdzić ponowną korektę?",
    sureReAsr: "Potwierdzić ponowną identyfikację?",
    reRole: "Głośnik wsteczny",
    recover: "Ponowna korekcja błędów",
    reAsr: "Ponowna identyfikacja",
    recordingOperation: "Operacja nagrywania",
    onlySave30Day: "(Tylko 30 dni)",
    appealError:
      "Przed przesłaniem należy odwołać się do punktu kontroli jakości.",
    exportToTaskCenter:
      "Pomyślnie wyeksportowano do Centrum przesyłania i pobierania",
    caseBaseLimit: "Biblioteka spraw nie może przekroczyć 50",
    unnamed: "Nienazwany",
    wordSpeed: "Słowo/min",
    hit: "Uderzenie",
    checkPoint: "Punkt kontroli jakości",
    realTimeAdditionTip1:
      "Dodawanie w czasie rzeczywistym: kontrola jakości rozpoczyna się natychmiast po wejściu danych do systemu kontroli jakości",
    realTimeAdditionTip2:
      "Dodaj czas: Czas automatycznie dodaje dane do kontroli jakości",
    null: "Brak",
    collcetedProps: "Pola kolekcji",
    editingNameNow: "Imię jest edytowane",
    upToFourLevels: "Klasyfikacja do czterech poziomów",
    default: "Domyślnie",
    nameCantBeNull: "Nazwa nie może być pusta",
    editingNow: "Akcja jest edytowana",
    nameError: "Zła nazwa",
    nameMaxLength8: "Maksymalna długość nazwy wynosi 8",
    callTime: "Czas rozmów",
    sentimentValue: "Wartość nastrojów klienta",
    agentSentimentValue: "Wartość nastrojów obsługi klienta",
    sentimentAnalysis: "Analiza nastrojów",
    associatedData: "Dane dotyczące ruchu drogowego",
    notifyTheOwner: "Powiadom właściciela grupy",
    notifyTheEmployee: "Powiadom samego pracownika",
    notifyTheOwnerTip:
      "Gdy typ konwersacji jest czatem grupowym, właściciel grupy zostanie powiadomiony.",
    customNotifyEmployee: "Niestandardowe powiadomienie pracownika",
    customNotifyEmployeeTip:
      "Jeśli osoba wyzwalająca jest pracownikiem, pracownik, do którego należy wiadomość, zostanie powiadomiony.",
    clickToLearnMore: "Kliknij, aby dowiedzieć się więcej",
    repeatMarkingTip:
      "Ten rekord został oznaczony przez ten punkt kontroli jakości i nie ma potrzeby powtarzania oznakowania.",
    successfulEvaluation: "Ocena sukcesu",
    evaluationFailed: "Ocena nie powiodła się",
    qualityInspectionScore: "Ocena jakości",
    semanticTags: "Etykieta semantyczna",
    smartTags: "Inteligentna etykieta",
    collect: "Zbieraj informacje",
    appealRecord: "Zapis skargi",
    satisfy: "Zadowolony",
    notSatisfy: "Nie jestem zadowolony",
    appealPlaceHolder: "Proszę podać powód odwołania",
    giveUp: "Porzuć",
    rejectReasonPlaceHolder: "Proszę podać powód odrzucenia",
    reject: "Odrzucony",
    changeRejectReason: "Popraw powód odrzucenia",
    pass: "Przez",
    revise: "Modyfikacja",
    machineEvaluation: "Ocena maszyny",
    poor: "Biedny",
    excellent: "Dobrze.",
    moderate: "Generał",
    mustMarked: "Ten punkt kontroli jakości musi być oznaczony",
    pleaseChoose: "Proszę wybrać",
    manualEvaluation: "Ocena ręczna",
    qualityInspectionTemplate: "Obowiązujący szablon kontroli jakości",
    collectionTemplate: "Obowiązujący szablon akwizycji",
    keywordsWordCloud: "Słowa kluczowe słowo chmura",
    editCategoryLabel: "Edytuj etykiety kategorii",
    justCall: "Wystarczy spojrzeć na wezwanie",
    justConversation: "Wystarczy spojrzeć na dialog.",
    batchOperation: "Operacja partii",
    customerServicesGroup: "Grupa Obsługi Klienta",
    joinGroup: "Dołącz do grupy",
    removeGroup: "Wyjdź z grupy",
    enterInformationEntity: "Wprowadź zawartość podmiotu informacyjnego",
    enterNotBeNull: "Dane wejściowe nie mogą być puste",
    operationGroup: "Grupa Operacyjna",
    customerServiceSelected: "Wybrana obsługa klienta",
    callAndConversation: "Połączenie/rozmowa",
    conversation: "Dialog",
    system: "System",
    customize: "Niestandardowe",
    effective: "Skuteczne",
    invalid: "Nieważne",
    successfulAndToTaskCenter:
      "Wykonanie jest udane, przejdź do centrum zadań, aby sprawdzić",
    recalculationFailed: "Ponowne obliczenie nie powiodło się",
    selectRecalculatedIndicator: "Wybierz wskaźnik, aby dokonać ponownej oceny",
    source: "Proszę wybrać źródło danych",
    placeholder: "Wpisz słowo kluczowe",
    cumulative: "Skumulowany",
    average: "Przeciętny typ",
    distribution: "Prawo dystrybucji",
    originalDataCannotEmpty: "Surowe dane nie mogą być puste",
    inspection: "Inspekcja jakości",
    check: "Inspekcja losowa",
    review: "Przegląd",
    draftSavedSuccessfully: "Szkic zapisany pomyślnie",
    optimization: "Optymalizacja",
    agentName: "Nazwa obsługi klienta",
    allTasks: "Wszystkie zadania kontroli jakości",
    datePickerSelect: "Wybierz datę",
    callSelect: "Wybierz Dialog",
    taskSelect: "Wybierz zadanie",
    genReport: "Wygeneruj raport",
    dataSource: "Źródło danych",
    callsTotal: "Łączna liczba połączeń",
    intelligentQualityInspection: "Inteligentna kontrola jakości",
    percentage: "Proporcja",
    artificialSampling: "Ręczne pobieranie próbek",
    appealNumber: "Ilość skarg",
    totalConversation: "Łączna kwota dialogu",
    samplingReviewRate: "Wskaźnik przeglądu pomp",
    data: "Dane",
    inspectionResults: "Wyniki kontroli jakości",
    inspected: "Inspekcja jakości",
    totalScore: "Całkowity wynik",
    learnMore: "Wyświetl więcej",
    inspectionEffect: "Efekt kontroli jakości",
    allTotal: "Ogółem",
    manualInspection: "Przegląd ręczny",
    artificialReview: "Przegląd ręczny",
    approvalAmountAndRate: "Zatwierdzona ilość i wskaźnik zatwierdzenia",
    customerScore: "Wynik obsługi klienta",
    inspectionRecommendations: "Sugestie dotyczące kontroli jakości",
    saveDraft: "Zapisz wersję roboczą",
    historicalAdvice: "Porady historyczne",
    advicePlaceHolder: "Proszę wpisać sugestię...",
    upload: "Przesyłanie pliku",
    customAdd: "Dodaj filtr",
    advancedConditions: "Zaawansowane warunki",
    keyWord: "Słowa kluczowe",
    containsAll: "Zawiera wszystkie",
    containsAny: "Zawiera dowolny",
    matchAny: "Dopasuj dowolne",
    matchAll: "Dopasuj wszystkie",
    smartTagsOne: "Inteligentna etykieta pierwsza",
    smartTagsTwo: "Inteligentna etykieta II",
    season: "Sezon",
    month: "Miesiąc",
    week: "Tydzień",
    day: "Dzień",
    hour: "Kiedy",
    notMoreThan366: "Nie więcej niż 366 dni.",
    propsCannotBeNull: "Niektóre pola nie są wypełnione",
    other: "Pozostałe",
    correlationCoefficient: "Współczynnik korelacji",
    repetitionRate: "Częstotliwość powtarzania",
    configurationItemsMoreThan20: "Dodaj do 20 elementów konfiguracji",
    conversionAnalysis: "Analiza transformacji",
    correlationAnalysis: "Analiza korelacji",
    successfulExportAndToTaskCenter:
      "Eksport się powiódł, przejdź do centrum zadań, aby wyświetlić",
    typeSelect: "Wybierz typ",
    exportExcel: "Eksport Excela",
    exportPng: "Eksport png",
    noDataChart: "Brak danych dla wykresu",
    placeOperator: "Wybierz inteligentną etykietę",
    placeAdvancedConditions: "Proszę wybrać kryteria zaawansowane",
    createAdvancedConditions: "Nowy filtr zaawansowany",
    moveUp: "Przesuń w górę",
    moveDown: "Przesuń w dół",
    moveLeft: "Przesuń w lewo",
    moveRight: "Przesuń w prawo",
    statusChangeSuccess: "Status został pomyślnie zmodyfikowany",
    editSuccess: "Modyfikacja się powiodła",
    saveSuccess: "Oszczędzaj pomyślnie",
    firstPage: "Strona główna",
    lastPage: "Strona ogona",
    customerInfo: "Informacje o kliencie",
    type: "Rodzaj",
    NumberTypeErrorMessage:
      "Zawartość informacji o polu typu liczby może zawierać tylko liczby lub „.”",
    taskStartPrompt: "Monit o otwarcie zadania",
    startConfirm:
      "Kliknij przycisk potwierdzenia, aby otworzyć zadanie kontroli jakości",
    continueToSubmitWithoutEvaluation: "Kontynuuj składanie bez oceny",
    rejectTip:
      "Nie wykryto żadnych zmian, czy na pewno odrzucisz wszystkie odwołania bezpośrednio?",
    unmarkedSelfLearningTip:
      "Masz nieoznakowane punkty kontroli jakości samouczące się",
    markedSuccessfully: "Sukces etykietowania",
    markingFailed: "Etykietowanie nie powiodło się",
    exceedingLength: "Przekraczając długość",
    uncategorized: "Niesklasyfikowane",
    addSuccess: "Nowy sukces",
    deleteSuccess: "Pomyślnie usunięte",
    featureName: "Nazwa elementu",
    informationEntity: "Podmiot informacyjny",
    standardAnswer: "Standardowa odpowiedź",
    keyElementAnswer: "Kluczowe elementy do odpowiedzi",
    generalStatement: "Ogólne oświadczenie (nie trzeba pytać)",
    generalQuestionsAndConfirmation: "Ogólne pytania i potwierdzenie",
    specialQuestionsAndAnswers: "Specjalne pytania i odpowiedzi",
    agent: "Obsługa klienta",
    customer: "Klient",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "Kluczowa etykieta semantyczna i jednostka informacyjna nie mogą być jednocześnie puste",
    standardSentence: "Standardowe zdanie",
    acquaintance: "Znajomość",
    smartUePlaceholder: "Wprowadź inteligentną nazwę interakcji",
    typeSelectPlaceholder: "Proszę wybrać rodzaj",
    keyElement: "Kluczowe elementy",
    reQuerySementicTags: "Zmień wybór etykiet semantycznych",
    querySementicTags: "Wybierz etykietę semantyczną",
    QATestForm: "Formularz wykrywania pytań i odpowiedzi",
    selectNotBeNull: "Wybór nie może być pusty",
    featureDetectionTarget: "Cel wykrywania elementów",
    categorized: "Klasyfikacja",
    effectiveness: "Skuteczność",
    hideAdvancedSettings: "Ukryj ustawienia zaawansowane",
    showAdvancedSettings: "Pokaż ustawienia zaawansowane",
    generalStatementOnly: "OŚWIADCZENIE OGÓLNE",
    generalQuestions: "Ogólne wątpliwości",
    affirmativeExpression: "Wyrażenie afirmatywne",
    negativeIntention: "Intencja negatywna",
    searchSementicTags: "Zapytanie o etykiety semantyczne",
    choiceQuestion: "Wybiórczy wyrok pytający",
    selectiveAnswer: "Wybór typu odpowiedzi",
    sementicTagsPlaceholder: "Wprowadź treść etykiety semantycznej",
    openSpecialQuestion: "Otwórz pytania specjalne",
    openAnswer: "Otwórz odpowiedź.",
    customQA: "Niestandardowe pytania i odpowiedzi",
    questions: "Metoda zadanych",
    dataPlaceholder: "Wprowadź zawartość",
    reply: "Odpowiedź",
    notFindSuitableLabel: "Nie znaleziono odpowiedniej etykiety.",
    addSemanticTag: "Dodaj nowy znacznik semantyczny",
    intelligentInteraction: "Inteligentna interakcja",
    matching: "Dopasuj",
    bout: "Czasy",
    keywordMatch: "Dopasowanie słów kluczowych",
    addFilterConditions: "Dodaj stan filtra",
    buttonStartCollectionTask:
      "Kliknij przycisk OK, aby rozpocząć zadanie zbierania",
    buttonStartTask:
      "Kliknij przycisk OK, aby otworzyć zadanie kontroli jakości",
    ruleConfigurationIsRequired: "Wymagana jest konfiguracja reguły",
    asrRunning: "Wykonanie ASR",
    errorCorrectionExecution: "Wykonanie korekcji błędów",
    emotionalVolumeRecognition: "Rozpoznawanie objętości emocji",
    dataPreprocessing: "Wstępne przetwarzanie danych",
    inspectionExecution: "Wykonanie kontroli jakości",
    businessId: "Id biznesowy",
    businessTime: "Czas biznesowy",
    createTime: "Czas tworzenia",
    abnormalReason: "Nienormalny powód",
    singleChat: "Pojedynczy czat",
    groupChat: "Czat grupowy",
    taskContent: "Zawartość zadania",
    submissionTime: "Czas na poddanie",
    startTime: "Czas rozpoczęcia",
    endTime: "Czas zakończenia",
    runningTime: "Czasochłonna operacja",
    totalAmountOfData: "Całkowita objętość danych",
    resultsOfThe: "Wyniki wykonania",
    operator: "Operator",
    taskDetails: "Szczegóły zadania",
    executiveFunction: "Funkcja wykonawcza",
    qualityCheckTasks: "Zadanie kontroli jakości",
    implementationDetails: "Szczegóły wykonania",
    conditionalRules: "Zasady warunku",
    smartCondition: "Inteligentne warunki",
    goThis: "Przenieś się do tego",
    pleaseMarkClassification: "Proszę zaznaczyć klasyfikację",
    advancedScreening: "Zaawansowane filtrowanie",
    noDataToManipulate: "Brak danych do manipulowania",
    concept: "Słowa koncepcyjne",
    checkPoint1: "Konfiguracja reguły",
    requiredFieldsCannotEmpty: "Wymagany przedmiot nie może być pusty",
    rule: "Zasady",
    afterFixUnit: "Słowo",
    unmarkedSelfLearningPointsTip:
      "Masz nieoznakowane punkty kontroli jakości samouczące się",
    onlyReservedFor7Days: "(Tylko 7 dni)",
    downloadError: "Pobieranie nie powiodło się",
    pause: "Przerwa",
    continue: "Kontynuuj",
    recalcScore: "Oblicz tylko wyniki (z ocenami)",
    recalculatePointsAndScores:
      "Przeliczenie punktów kontroli jakości i wyników",
    recalculatePointsAndScoresWithEmtry:
      "Przeliczenie punktów kontroli jakości i wyników (punkty kontroli jakości są puste)",
    inProgress: "W toku",
    toBeExecuted: "Do wdrożenia",
    executeImmediately: "Natychmiastowe wykonanie",
    specialElements: "Elementy specjalne",
    logicRule: "Zasady logiczne",
    every: "Wiele meczów",
    hitRecall: "Hit Recall Słowo",
    notHitRecall: "Missed Recall Word",
    hitRecallRegular: "Regularne przywoływanie hitów",
    notHitRecallRegular: "Przegapione regularnie",
    hitRule: "Zasady trafień",
    notHitRule: "Nieoddanych reguł",
    hitSimilarSentence: "Uderz w podobne zdania",
    notHitSimilarSentence: "Brakowało podobnych zdań",
    hitExcludeSimilarSentences: "Uderz, aby wykluczyć podobne zdania",
    notHitExcludeSimilarSentences: "Nie można wykluczyć podobnych zdań",
    updateSuccess: "Aktualizacja zakończyła się sukcesem",
    qualify: "Regularne",
    recallKeyWord: "Przypomnij sobie słowa kluczowe",
    grammar: "Zasady gramatyczne",
  },
  api: {
    putTypoDatasByIdStatusByStatus:
      "Zmiana ważności nie powiodła się, spróbuj ponownie",
    postAutoMateTasksCaseByTaskId:
      "Nie udało się zapisać konfiguracji synchronizacji biblioteki spraw",
    getAutoMateTasksCaseByTaskId:
      "Nie udało się uzyskać konfiguracji synchronizacji biblioteki przypadków",
    postInspectionRemarkModelConfigs:
      "Nie udało się zapisać szablonu komentarza",
    putInspectionRemarkModelConfigsById:
      "Nie udało się zapisać szablonu komentarza",
    getInspectionRemarkModelConfigsById:
      "Nie udało się uzyskać danych szablonu komentarza",
    postKnowledgeDatas:
      "Tworzenie bazy wiedzy nie powiodło się, spróbuj ponownie",
    putKnowledgeDatasById:
      "Modyfikacja repozytorium nie powiodła się, spróbuj ponownie",
    getLibraryFiles: "Nie udało się uzyskać listy folderów cenowych.",
    getLibraryCalls: "Nie udało się uzyskać pliku",
    getLibraryFilesSearch: "Wyszukiwanie nie powiodło się",
    putLibraryFilesMoveById: "Nie udało się przenieść folderu cenowego.",
    putLibraryCallsById: "Nazwa pliku nie powiodła się!",
    postLibraryFiles: "Nie udało się dodać folderu",
    putLibraryFilesById: "Nie udało się zmienić nazwy folderu",
    deleteLibraryFilesById: "Usuń nie powiodło się",
    deleteLibraryCallsById: "Usuń nie powiodło się",
    postLibraryCalls: "Nie udało się dodać",
    getVoiceUploadResultById: "Eksport wyników przesyłania nie powiódł się",
  },
};
