import React, { useState, useMemo, useEffect } from 'react';
import Locales from 'UdeskLocales';
import { FormBuilder, Modal } from 'udesk-ui';
import { CallManualQaTaskRequest, InspectionTaskFoundResponse } from 'src/api/types';
import { postCalltaskQaTaskManual } from 'src/api/calltask/qaTask/manual';
import { getInspectionTasksSourceBySource } from 'src/api/inspectionTasks/source/{source}';
import Udesk from 'Udesk';
import { getSystemModule } from 'Udesk/system/subApp';

// import './style.scss';

type Props = {
    inspectDataSource: number;
    dataId: number | number[];
    onAdded?: () => void;
    onCancel?: () => void;
    visible?: boolean;
};

const Template = React.memo((props: Props) => {
    const locales = Locales['current'];
    const [form] = FormBuilder.useForm();
    const [taskList, setTaskList] = useState<InspectionTaskFoundResponse[]>([]);
    const { dataId, onCancel, visible, onAdded } = props;
    const options = useMemo(() => {
        return taskList.map((i) => ({
            value: i.id,
            label: i.name,
        }));
    }, [taskList]);
    const onFinish = (values) => {
        let params: CallManualQaTaskRequest = {
            ...values,
            callDataIds: Array.isArray(dataId) ? dataId : [dataId],
            systemModule: getSystemModule(),
        };
        postCalltaskQaTaskManual(params).then(() => {
            onAdded?.();
            Udesk.ui.notify.success(
                /* 添加成功 */ locales.components.addToTaskModal.index.successfullyAdded
            );
            form.setFieldsValue({
                inspectTaskIds: [],
            });
        });
    };

    // useEffect(() => {
    //     if (Array.isArray(dataId)) return;
    //     form.setFieldsValue({
    //         inspectTaskIds: [],
    //     });
    //     getCalltaskQaTaskManual({
    //         params: {
    //             id: dataId,
    //         },
    //     }).then((resp) => {
    //         form.setFieldsValue({
    //             inspectTaskIds: resp.data?.map((i) => i.id) ?? [],
    //         });
    //     });
    // }, [dataId]);

    useEffect(() => {
        getInspectionTasksSourceBySource({
            segments: {
                source: props.inspectDataSource,
            },
        }).then((resp) => {
            if (Array.isArray(resp.data) && resp.data.length) {
                setTaskList(resp.data);
            }
        });
    }, [props.inspectDataSource]);
    return (
        <React.Fragment>
            <Modal
                onOk={() => {
                    form.submit();
                }}
                width={800}
                title={
                    /* 添加至质检任务 */ locales.components.addToTaskModal.index
                        .addToQualityInspectionTask
                }
                visible={visible}
                onCancel={onCancel}
            >
                <FormBuilder
                    fields={[
                        {
                            type: 'Select',
                            name: 'inspectTaskIds',
                            label: /* 质检任务 */ locales.components.addToTaskModal.index
                                .qualityInspectionTask,
                            props: {
                                mode: 'multiple',
                                options,
                                placeholder: locales.labels.pleaseSelect,
                            },
                            rules: [{ required: true }],
                        },
                    ]}
                    form={form}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 20 }}
                    footerRender={false}
                    onFinish={onFinish}
                />
            </Modal>
        </React.Fragment>
    );
});

export default Template;
