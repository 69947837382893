// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { PutUsersByIdStatusByStatusParam, BaseResponseVoid } from '../../../../types';

/**
 * userStatus
 *
 * @export
 * @tags 用户
 * @link [PUT] /users/{id}/status/{status}
 * @param id
 * @param status
 */
export function putUsersByIdStatusByStatus(
    options: ApiOptions<PutUsersByIdStatusByStatusParam, never> & {
        segments: PutUsersByIdStatusByStatusParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, PutUsersByIdStatusByStatusParam, never>('/users/{id}/status/{status}', {
        ...options,
        method: 'put',
        __$requestCalleeName: 'putUsersByIdStatusByStatus',
    });
}

export const meta = [{ tags: ['用户'], path: '/users/{id}/status/{status}', method: 'put' }];
