import React, { useEffect, useState } from 'react';
import Udesk from 'Udesk';
import {
    Menu,
    Alert,
    Button,
    FormBuilder,
    Space,
    Tabs,
    Badge,
    Empty,
    Spin,
    Select,
    Input,
} from 'udesk-ui';
import './style.scss';
import InspectionDetail from '../../components/inspection-detail';
// import _debounce from "lodash-es/debounce";
import UdeskPagination from 'Component/common/udesk-pagination';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import Locales from 'UdeskLocales';
import type { FormProps } from 'udesk-ui/lib/form-builder/types/form';
import 'udesk-ui/lib/form/style/index.less';

let timer: any = null;
let timer2: any = null;
let timer1: any = null;

const { TabPane } = Tabs;

const Card = (props) => {
    const locales = Locales['current'];

    const { item } = props;
    const color =
        item['f_2'] === Udesk.enums.appealCheckStatus.readed.name
            ? '#1A6EFF'
            : item['f_2'] === Udesk.enums.appealCheckStatus.inComplaint.name
            ? '#F09A00'
            : item['f_2'] === Udesk.enums.appealCheckStatus.reviewed.name
            ? '#0FBA26'
            : 'rgba(0, 0, 0, 0.25)';
    return (
        <div className="appeal-checking-task-page-list-item" title={item['f_61']}>
            <div className="appeal-checking-task-page-list-item-row has-margin">
                <div className="appeal-checking-task-page-list-item-row-left">{item['f_61']}</div>
                <div className="appeal-checking-task-page-list-item-row-right">
                    <Space size={0}>
                        <Badge color={color} />
                        <div>{item['f_2']}</div>
                    </Space>
                </div>
            </div>
            <div className="appeal-checking-task-page-list-item-row">
                <div
                    className="appeal-checking-task-page-list-item-row-left"
                    style={{ color: '#1A6EFF' }}
                >
                    {item['f_1']}
                </div>
                <div
                    className="appeal-checking-task-page-list-item-row-right"
                    style={{
                        color:
                            item['f_62'] === Udesk.enums.isManual.yes.id
                                ? '#0FBA26'
                                : 'rgba(0, 0, 0, 0.25)',
                    }}
                >
                    {Udesk.enums.isManual.getName(item['f_62'])}
                </div>
            </div>
            <div className="appeal-checking-task-page-list-item-row">
                <div className="appeal-checking-task-page-list-item-row-left">
                    <Space>
                        <div>
                            {/* 评级: */}
                            {locales.pages.appealCheckingTask.sdk.index.grade}
                        </div>
                        <div style={{ color: '#11A848' }}>{item['f_6']}</div>
                    </Space>
                </div>
            </div>
        </div>
    );
};
const QualityTasksTypeTabs = (props) => {
    const { activeTabKey, onChange: propsOnChange, qualityTasksTypes } = props;
    return (
        <Tabs
            activeKey={`${activeTabKey}`}
            onChange={propsOnChange}
            className="appeal-checking-task-page-task-type-tabs"
            size="small"
            style={{ marginTop: '-16px' }}
        >
            {qualityTasksTypes.map((item) => (
                <TabPane tab={item.name} key={item.id} />
            ))}
            {/* <TabPane
                tab={Udesk.enums.inspectDataSources.voiceCall.name}
                key={Udesk.enums.inspectDataSources.voiceCall.id}
            />
            <TabPane
                tab={Udesk.enums.inspectDataSources.textDialogue.name}
                key={Udesk.enums.inspectDataSources.textDialogue.id}
            />
            <TabPane tab={Udesk.enums.inspectDataSources.ticket.name} key={Udesk.enums.inspectDataSources.ticket.id} />
            */}
        </Tabs>
    );
};

interface ComplainButton {
    complainedCount?: number;

    inputReason?: number;

    isComplainValid?: number;

    permitComplainCount?: number;

    permitComplainDateTime?: string;

    show?: number;

    surplusComplainCount?: number;
}

interface ComplainPoint {
    complainReason?: string;

    complainType?: number;

    pointId?: number;
}

const GradedFilter = (props) => {
    const { onChange, value = {} } = props;
    const { operator, score } = value;

    const onSelect = (v) => {
        onChange({
            ...value,
            operator: v,
        });
    };
    const onInput = (v) => {
        onChange({
            ...value,
            score: v.target.value,
        });
    };
    return (
        <Input
            addonBefore={
                <Select
                    onChange={onSelect}
                    value={operator}
                    style={{ width: 75 }}
                    options={Udesk.enums.gradedOptions.map((i) => ({ label: i.name, value: i.id }))}
                ></Select>
            }
            value={score}
            onChange={onInput}
        />
    );
};
const RatingFilter = (props) => {
    const { onChange, value = {}, searchTasks, inspectDataSource, sdkOptions } = props;
    const { gradeOperator, inspectionGradeId } = value;
    const [tasks, setTasks] = useState<any[]>([]);
    const [ratingOptions, setRatingOptions] = useState<any[]>([]);
    const [taskId, setTaskId] = useState<number | undefined>(undefined);

    const onSelect = (key, v) => {
        onChange({
            ...value,
            [key]: v,
        });
    };

    const onTaskSelected = (taskId) => {
        setTaskId(taskId);
        onChange({});
        if (!taskId) {
            searchTasks({ inspectDataSource }, (resp) => {
                setTasks(resp.data?.map?.((i) => ({ label: i.name, value: i.id })) ?? []);
            });
            setRatingOptions([]);
            return;
        }
        Udesk.business.sdkAjax
            .get(`open_api_v1/inspectionGrades/${taskId}`, sdkOptions, { props })
            .then(
                (resp) => {
                    setRatingOptions(
                        resp.data?.map?.((i) => ({ label: i.gradeName, value: i.id })) ?? []
                    );
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
    };
    useEffect(() => {
        if (inspectDataSource === null) return;
        searchTasks({ inspectDataSource }, (resp) => {
            setTasks(resp.data?.map?.((i) => ({ label: i.name, value: i.id })) ?? []);
        });
    }, [inspectDataSource]);

    return (
        <Space>
            <Select
                onSearch={(value) => {
                    searchTasks({ keywords: value, inspectDataSource }, (resp) => {
                        setTasks(resp.data?.map?.((i) => ({ label: i.name, value: i.id })) ?? []);
                    });
                }}
                onChange={onTaskSelected}
                value={taskId}
                style={{ width: 120 }}
                options={tasks}
                filterOption={false}
                defaultActiveFirstOption={false}
                showSearch
                allowClear
            ></Select>
            <Select
                onChange={onSelect.bind(null, 'gradeOperator')}
                value={gradeOperator}
                style={{ width: 100 }}
                options={Udesk.enums.ratingOptions.map((i) => ({ label: i.name, value: i.id }))}
            ></Select>
            <Select
                onChange={onSelect.bind(null, 'inspectionGradeId')}
                value={inspectionGradeId}
                style={{ width: 120 }}
                options={ratingOptions}
            ></Select>
        </Space>
    );
};

const SDK: React.FC<any> = (props) => {
    const { sdkOptions } = props;
    const locales = Locales['current'];
    const appealLocales = locales.components.pages.appealSdk;

    const [formRef] = FormBuilder.useForm();
    const [selectedTaskId, setSelectedTaskId] = useState<number>(0);
    // const [related, setRelated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [listLoading, setListLoading] = useState<boolean>(false);
    const [isInit, setIsInit] = useState<boolean>(true);
    const [dialogRemarkList, setDialogRemarkList] = useState<any[]>([]);
    const [detail, setDetail] = useState<any>({});
    // const [pointCategoryList, setPointCategoryList] = useState<any[]>([]);
    // const [callDetailsList, setCallDetailsList] = useState<any[]>([]);
    const [pointChildrenComputer, setPointChildrenComputer] = useState<any>({});
    const [pointChildrenPerson, setPointChildrenPerson] = useState<any>({});
    // const [complaintList, setComplaintList] = useState<any>([]);
    const [tasksType, setTasksType] = useState<number | null>(null);
    const [complainButton, setComplainButton] = useState<ComplainButton>({});
    const [complainPointList, setComplainPointList] = useState<ComplainPoint[]>([]);
    const [complaintHistory, setComplaintHistory] = useState<ComplainPoint[]>([]);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<any>({
        beginTime: '',
        endTime: '',
    });
    const [resetComplainPointIdMap, setResetComplainPointIdMap] = useState(0);
    const [paging, setPage] = useState<any>({
        pageNum: 1,
        pageSize: 20,
        total: 0,
    });
    const [taskList, setTaskList] = useState<any[]>([]);
    const [taskIdsOptions, setTaskIdsOptions] = useState<any[]>([]);
    const [showHit, setShowHit] = useState<0 | 1>(0);

    const isTicket = tasksType === Udesk.enums.inspectDataSources.ticket.id;
    const [qualityTasksTypes, setQualityTasksTypes] = useState<any[]>([]);

    const showHitChange = (showHit) => {
        setShowHit(showHit ? 1 : 0);
    };
    const handMarkMap = () => {};

    const onSelect = ({ key, keyPath, selectedKeys, domEvent }: any) => {
        setSelectedTaskId(key * 1);
        changeReadStatus(key, taskList);
    };
    const changeReadStatus = (key, taskList) => {
        const item = taskList.find((i) => i.dataId === key * 1);
        if (!item) return;
        if (item.fieldValueMap['f_2'] === Udesk.enums.appealCheckStatus.unread.name) {
            // let url = Udesk.business.apiPath.concatApiPath(`/open_api_v1/inspection/${task.dataId}/read`, sdkOptions);
            // Udesk.ajax.post(url)
            Udesk.business.sdkAjax
                .post(`/open_api_v1/inspection/${item.dataId}/read`, sdkOptions, { props })
                .then(
                    (resp) => {
                        item.fieldValueMap['f_2'] = Udesk.enums.appealCheckStatus.readed.name;
                        setTaskList([...taskList]);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
        }
    };
    const getParams = (searchParams) => {
        if (!searchParams) {
            searchParams = {};
        }
        if (Array.isArray(searchParams.time) && searchParams.time[0] && searchParams.time[1]) {
            searchParams.beginTime = searchParams.time[0];
            searchParams.endTime = searchParams.time[1];
        }
        const { graded = {}, ...otherParams } = searchParams;
        let params = {
            ...paging,
            inspectDataSource: tasksType,
            ...otherParams,
            ...graded,
        };
        let querryDateRange = dateRange;
        if (
            isInit &&
            sdkOptions?.props?.defaultDateRange &&
            sdkOptions?.props?.defaultDateRange.length
        ) {
            let dateRange = sdkOptions.props.defaultDateRange;
            querryDateRange = {
                beginTime: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') + ' 00:00:00' : '',
                endTime: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') + ' 23:59:59' : '',
            };
            setDateRange(querryDateRange);
            setIsInit(false);
            params = { ...params, ...querryDateRange };
        }
        return params;
    };

    const searchTasks = (listParams, cb) => {
        timer2 && clearTimeout(timer2);
        timer2 = setTimeout(() => {
            Udesk.business.sdkAjax
                .get('open_api_v1/task-list-v1', sdkOptions, { props }, listParams)
                .then(
                    (resp) => {
                        // setTaskIdsOptions(resp.data?.map?.((i) => ({ label: i.name, value: i.id })) ?? []);
                        cb(resp);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        // reject(reason);
                        // this.privates.loading = false;
                        // this.actions.update();
                    }
                );
        }, 500);
    };
    const searchTasksInFilter = (listParams, cb) => {
        timer1 && clearTimeout(timer1);
        timer1 = setTimeout(() => {
            Udesk.business.sdkAjax
                .get('open_api_v1/task-list-v1', sdkOptions, { props }, listParams)
                .then(
                    (resp) => {
                        // setTaskIdsOptions(resp.data?.map?.((i) => ({ label: i.name, value: i.id })) ?? []);
                        cb(resp);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        // reject(reason);
                        // this.privates.loading = false;
                        // this.actions.update();
                    }
                );
        }, 500);
    };

    const setTaskIdsOptionsFromAjax = (resp) =>
        setTaskIdsOptions(resp.data?.map?.((i) => ({ label: i.name, value: i.id })) ?? []);

    const onSelectSearchTask = (value) => {
        searchTasks(
            {
                keywords: value,
                inspectDataSource: tasksType,
            },
            setTaskIdsOptionsFromAjax
        );
    };

    const searchList = (searchParams: any = {}, needResetSelectTaskId = false) => {
        const params = getParams(searchParams);
        setListLoading(true);
        Udesk.business.sdkAjax
            .post('open_api_v1/inspection/list', sdkOptions, { props }, params)
            .then(
                (resp) => {
                    const newTaskList = resp.data?.fieldDataList || [];
                    setTaskList(newTaskList);
                    if ((resp.data?.fieldDataList?.length ?? 0) > 0) {
                        if (needResetSelectTaskId) {
                            setSelectedTaskId(resp.data.fieldDataList[0].dataId);
                            changeReadStatus(resp.data.fieldDataList[0].dataId, newTaskList);
                        }
                    } else {
                        setSelectedTaskId(0);
                    }
                    setPage(resp.paging);
                    setListLoading(false);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    setListLoading(false);
                }
            );
    };
    const searchListFromForm = (searchParams: any = {}) => {
        const { rating, ...params } = searchParams;
        searchList(
            {
                ...params,
                ...rating,
                pageNum: 1,
            },
            true
        );
    };
    // const searchComplaintList = (searchParams: any = {}) => {
    //     const params = getParams(searchParams);
    //     Udesk.business.sdkAjax.post('open_api_v1/complaint/list', sdkOptions, { props }, params).then(
    //         (resp) => {
    //             setComplaintList(resp.data?.fieldDataList || []);
    //         },
    //         (reason) => {
    //             Udesk.ui.notify.error(reason.errorMsg);
    //         }
    //     );
    // };
    const pageChanged = (pageNum) => {
        setPage({
            ...paging,
            pageNum,
        });
        const values = formRef.getFieldsValue();
        searchList({
            pageNum,
            ...values,
        });
    };
    const onTasksTypeChange = (activeKey) => {
        setTasksType(activeKey * 1);
        const values = formRef.getFieldsValue();
        setPage({
            ...paging,
            pageNum: 1,
        });
        searchList(
            {
                inspectDataSource: activeKey * 1,
                pageNum: 1,
                ...values,
            },
            true
        );
        const listParams = {
            inspectDataSource: activeKey,
        };
        searchTasks(listParams, setTaskIdsOptionsFromAjax);
    };
    const getComplainButton = () => {
        Udesk.business.sdkAjax
            .get(`open_api_v1/complain-button/${selectedTaskId}`, sdkOptions, { props })
            .then(
                (resp) => {
                    setComplainButton(resp.data || {});
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
    };
    const onComplainPointListChange = (complainPointList) => {
        setComplainPointList(complainPointList);
    };
    const getComplainHistory = () => {
        Udesk.business.sdkAjax
            .get(`open_api_v1/${selectedTaskId}/complaint/list`, sdkOptions, { props })
            .then(
                (resp) => {
                    setComplaintHistory(resp.data);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
    };
    const onSubmit = () => {
        if (!complainPointList?.length) {
            Udesk.ui.notify.error(locales.fix.appealError);
            return;
        }
        let params = {
            pointList: complainPointList,
        };
        // 这里判断是否是申诉全部
        const item = complainPointList.find((item) => typeof item.pointId === 'string');
        if (item) {
            const ids = item.pointId!.toString().split('/');
            params.pointList = ids.map((pointId) => {
                return {
                    ...item,
                    pointId: +pointId,
                    complainSubmitType: 'CALL',
                } as any;
            });
        }

        setSubmitLoading(true);

        Udesk.business.sdkAjax
            .post(
                `open_api_v1/inspection/${selectedTaskId}/complain`,
                sdkOptions,
                { props },
                params
            )
            .then(
                (resp) => {
                    Udesk.ui.notify.success(appealLocales.complainSuccess);
                    getComplainButton();
                    getComplainHistory();
                    const formValues = formRef.getFieldsValue();
                    searchList(formValues);
                    setComplainPointList([]);
                    setSubmitLoading(false);
                    setResetComplainPointIdMap((prev) => prev + 1);
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    setSubmitLoading(false);
                }
            );
    };
    const refreshList = () => {
        const formValues = formRef.getFieldsValue();
        searchList(formValues);
    };

    useEffect(() => {
        if (!selectedTaskId) return;
        setLoading(true);
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            Udesk.business.sdkAjax
                .get(`open_api_v1/inspection/${selectedTaskId}`, sdkOptions, { props })
                .then(
                    (resp) => {
                        let pointChildrenComputer = {};
                        let pointChildrenPerson = {};
                        setSelectedTaskId(resp.data.id);
                        const {
                            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                            relatedInspectionDataExtension = {},
                            // related,
                            ...detail
                        } = resp.data;
                        // const { related } = detail;
                        // setRelated(Boolean(related));
                        // const callDetailsList = Object.values(relatedInspectionDataExtension);
                        // if (related) {
                        //     setCallDetailsList(callDetailsList);
                        // } else {
                        //     setCallDetailsList([resp.data]);
                        // }
                        // let firstCallDetail: any = callDetailsList[0] || {};
                        // this.privates.activeCallId = `${firstCallDetail.callId}`;
                        //单句备注
                        setDialogRemarkList(detail.remarkList || []);
                        let task = { ...detail };
                        // let task = { ...detail, ...firstCallDetail };
                        //质检点（一级分类）列表
                        if (task.pointCategoryList && task.pointCategoryList.length > 0) {
                            task.pointCategoryList.forEach((item) => {
                                //一级分类下有二级分类
                                if (item.children) {
                                    item.children.forEach((items) => {
                                        //二级分类下有质检点
                                        if (items.pointDataList) {
                                            items.pointDataList.forEach((itemss) => {
                                                //质检点有人工标记的高亮（？不确定）
                                                if (itemss.handMarkHighlightSeqMap) {
                                                    if (itemss.handMarkHighlightSeqMap[1]) {
                                                        itemss.handMarkHighlightSeqMap[1].forEach(
                                                            (itemsss) => {
                                                                if (pointChildrenPerson[itemsss]) {
                                                                    pointChildrenPerson[
                                                                        itemsss
                                                                    ].push({
                                                                        id: itemss.pointId,
                                                                        name: itemss.name,
                                                                    });
                                                                } else {
                                                                    pointChildrenPerson = {
                                                                        ...pointChildrenPerson,
                                                                        [itemsss]: [
                                                                            {
                                                                                id: itemss.pointId,
                                                                                name: itemss.name,
                                                                            },
                                                                        ],
                                                                    };
                                                                }
                                                            }
                                                        );
                                                    }
                                                    if (itemss.handMarkHighlightSeqMap[2]) {
                                                        itemss.handMarkHighlightSeqMap[2].forEach(
                                                            (itemsss) => {
                                                                if (
                                                                    pointChildrenComputer[itemsss]
                                                                ) {
                                                                    pointChildrenComputer[
                                                                        itemsss
                                                                    ].push({
                                                                        id: itemss.pointId,
                                                                        name: itemss.name,
                                                                    });
                                                                } else {
                                                                    pointChildrenComputer = {
                                                                        ...pointChildrenComputer,
                                                                        [itemsss]: [
                                                                            {
                                                                                id: itemss.pointId,
                                                                                name: itemss.name,
                                                                            },
                                                                        ],
                                                                    };
                                                                }
                                                            }
                                                        );
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        // setPointCategoryList(task.pointCategoryList);
                        setDetail(task);
                        setPointChildrenComputer(pointChildrenComputer);
                        setPointChildrenPerson(pointChildrenPerson);
                        setLoading(false);
                    },
                    (reason) => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        setLoading(false);
                    }
                );
            getComplainButton();
            getComplainHistory();
            setComplainPointList([]);
            timer = null;
        }, 500);
    }, [selectedTaskId]);
    const config: FormProps = {
        initialValues: {
            ...(sdkOptions?.props || {}),
        },
        layout: 'inline',
        onFinish: searchListFromForm,
        customComponents: { UdeskDatePicker, GradedFilter, RatingFilter },
        fields: [
            {
                name: 'time',
                label: isTicket
                    ? /* 开始时间 */ locales.pages.appealCheckingTask.sdk.index.startTime
                    : appealLocales.time,
                type: 'UdeskDatePicker',
                props: {
                    defaultValue: sdkOptions?.props.defaultDateRange
                        ? sdkOptions?.props.defaultDateRange.map(
                              (i, index) =>
                                  `${i.format('YYYY-MM-DD')} ${
                                      index === 0 ? '00:00:00' : '23:59:59'
                                  }`
                          )
                        : undefined,
                    disabled: sdkOptions?.props.dateRangeDisabled,
                },
            },
            {
                name: 'externalId',
                label:
                    tasksType === Udesk.enums.inspectDataSources.voiceCall.id
                        ? appealLocales.externalIdCall
                        : isTicket
                        ? /* 工单编号 */ locales.pages.appealCheckingTask.sdk.index.workOrderNo
                        : appealLocales.externalIdText,
                type: 'Input',
                props: {
                    placeholder: locales.labels.pleaseEnter,
                },
            },
            {
                name: 'customerNickName',
                label: appealLocales.customerNickName,
                type: 'Input',
                props: {
                    placeholder: locales.labels.pleaseEnter,
                },
            },
            {
                name: 'readStatus',
                label: isTicket
                    ? /* 客服已读/未读 */ locales.pages.appealCheckingTask.sdk.index
                          .customerServiceHasReadNotRead
                    : appealLocales.readStatus,
                type: 'Select',
                props: {
                    // defaultValue: sdkOptions.props.readStatus,
                    style: {
                        width: 150,
                    },
                    allowClear: true,
                    options: Udesk.enums.readStatus.map((item) => ({
                        label: item.name,
                        value: item.id,
                    })),
                    disabled: sdkOptions?.props.appealStatusDisabled,
                    placeholder: locales.labels.pleaseSelect,
                },
            },
            {
                name: 'status',
                label: appealLocales.status,
                type: 'Select',
                props: {
                    // defaultValue: sdkOptions.props.status,
                    style: {
                        width: 150,
                    },
                    allowClear: true,
                    disabled: sdkOptions?.props.appealStatusDisabled,
                    options: Udesk.enums.appealCheckStatus
                        .filter((i) =>
                            [
                                Udesk.enums.appealCheckStatus.inComplaint.id,
                                Udesk.enums.appealCheckStatus.reviewed.id,
                            ].includes(i.id)
                        )
                        .map((item) => ({ label: item.name, value: item.id })),
                    placeholder: locales.labels.pleaseSelect,
                },
            },
            {
                name: 'isManual',
                label: appealLocales.isManual,
                type: 'Select',
                props: {
                    // defaultValue: sdkOptions.props.isManual,
                    style: {
                        width: 150,
                    },
                    allowClear: true,
                    disabled: sdkOptions?.props.appealStatusDisabled,
                    options: Udesk.enums.isManual.map((item) => ({
                        label: item.name,
                        value: item.id,
                    })),
                    placeholder: locales.labels.pleaseSelect,
                },
            },
            {
                name: 'taskIds',
                label: appealLocales.taskIds,
                type: 'Select',
                props: {
                    style: {
                        width: 250,
                    },
                    mode: 'multiple',
                    allowClear: true,
                    options: taskIdsOptions,
                    onSearch: onSelectSearchTask,
                    filterOption: false,
                    maxTagCount: 'responsive',
                    placeholder: locales.labels.pleaseSelect,
                },
            },
            {
                name: 'graded',
                label: appealLocales.graded,
                type: 'GradedFilter',
                forceHidden: isTicket,
            },
            {
                name: 'rating',
                label: /* 评级 */ locales.pages.appealCheckingTask.sdk.index.grade,
                type: 'RatingFilter',
                forceHidden: isTicket,
                props: {
                    searchTasks: searchTasksInFilter,
                    inspectDataSource: tasksType,
                    sdkOptions,
                },
            },
            {
                name: 'enabledComplainFlag',
                label: /* 是否可申诉 */ locales.pages.appealCheckingTask.sdk.index.canYouAppeal,
                type: 'Select',
                props: {
                    allowClear: true,
                    options: Udesk.enums.canAppealOptions.map((item) => ({
                        label: item.name,
                        value: item.id,
                    })),
                    placeholder: locales.labels.pleaseSelect,
                },
            },
        ],
        footerRender: () => (
            <Space>
                <Button type="primary" htmlType="submit">
                    {locales.labels.query}
                </Button>
                <Button onClick={() => formRef.resetFields()}>{locales.labels.reset}</Button>
            </Space>
        ),

        // onFinish: onFinish,
    };
    useEffect(() => {
        Udesk.business.sdkAjax.get(`open_api_v1/company`, sdkOptions, { props }).then(
            (resp) => {
                const company = resp.data;
                let qualityTasksTypes: any[] = [];
                if (company.enabledCallInspect) {
                    qualityTasksTypes.push(Udesk.enums.inspectDataSources.voiceCall);
                }
                if (company.enabledImInspect) {
                    qualityTasksTypes.push(Udesk.enums.inspectDataSources.textDialogue);
                }
                if (company.enabledWeComInspect) {
                    qualityTasksTypes.push(Udesk.enums.inspectDataSources.wechatDialogue);
                }
                if (company.enabledWeComVoiceInspect) {
                    qualityTasksTypes.push(Udesk.enums.inspectDataSources.wechatRadio);
                }
                if (company.enabledTicket) {
                    qualityTasksTypes.push(Udesk.enums.inspectDataSources.ticket);
                }
                setQualityTasksTypes(qualityTasksTypes);
                const tasksType = qualityTasksTypes[0].id;
                setTasksType(tasksType);
                const values = formRef.getFieldsValue();
                searchList({ ...values, inspectDataSource: tasksType }, true);
                const listParams = {
                    inspectDataSource: tasksType,
                };
                searchTasks(listParams, setTaskIdsOptionsFromAjax);
            },
            (reason) => {
                Udesk.ui.notify.error(reason.errorMsg);
                setLoading(false);
            }
        );
    }, []);
    return (
        <Page
            style={tasksType === null ? undefined : { paddingBottom: 0 }}
            pageBodyClassName="appeal-checking-task-page-new"
            title={locales.components.pages.appealSdk.appealSdkIndex.title}
            // padding={true}
            backGroundGhost={true}
            footer={
                <QualityTasksTypeTabs
                    locales={locales.pages.tasks.index}
                    activeTabKey={tasksType}
                    onChange={onTasksTypeChange}
                    qualityTasksTypes={qualityTasksTypes}
                />
            }
            loading={loading && tasksType === null}
        >
            <div className="appeal-checking-task-page-filter">
                <FormBuilder {...config} form={formRef} />
            </div>
            <div className="appeal-checking-task-page-body">
                <div className="appeal-checking-task-page-left">
                    {(taskList?.length ?? 0) > 0 ? (
                        <React.Fragment>
                            <Spin spinning={listLoading}>
                                <div className="appeal-checking-task-page-left-list">
                                    <Menu selectedKeys={[`${selectedTaskId}`]} onSelect={onSelect}>
                                        {taskList.map((item) => {
                                            return (
                                                <Menu.Item key={item.dataId}>
                                                    <Card item={item.fieldValueMap} />
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu>
                                </div>
                            </Spin>
                            <div className="appeal-checking-task-page-left-page">
                                <UdeskPagination
                                    current={paging.pageNum}
                                    pageSize={paging.pageSize}
                                    total={paging.total}
                                    simple={true}
                                    showSizeChanger={false}
                                    onChange={pageChanged}
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
                <div className="appeal-checking-task-page-right">
                    {selectedTaskId ? (
                        <React.Fragment>
                            <Alert
                                message={Udesk.utils.string.format(
                                    appealLocales.complainButtonInfo,
                                    complainButton.permitComplainCount ?? 0,
                                    complainButton.complainedCount ?? 0,
                                    complainButton.surplusComplainCount ?? 0,
                                    complainButton.permitComplainDateTime ?? 0
                                )}
                                type="info"
                                action={
                                    <Button
                                        onClick={onSubmit}
                                        disabled={!complainButton.show || !complainPointList.length}
                                        size="small"
                                        type="primary"
                                        loading={submitLoading}
                                    >
                                        {appealLocales.submit}
                                    </Button>
                                }
                            />
                            <InspectionDetail
                                // evaluateChange={actions.evaluateChange}
                                resetStatus={false}
                                handMarkMap={handMarkMap}
                                pointChildrenComputer={pointChildrenComputer}
                                pointChildrenPerson={pointChildrenPerson}
                                // keywords={sdkOptions.props._keywords}
                                testSetList={null}
                                testSetListFlag={false}
                                caseBaseStatus={true}
                                // onUpdate={actions.onUpdate}
                                id={detail.id}
                                // caseBase={caseBase}
                                // caseLibraryId={location.search}
                                taskId={detail.taskId}
                                // hasFeature={hasFeature}
                                detail={detail}
                                // smartTags={smartTags}
                                // changeList={changeList}
                                // qualityCheckScoreSaving={qualityCheckScoreSaving}
                                // onQualityCheckScoreCancel={actions.qualityCheckScoreCanceled}
                                // onQualityCheckScoreSave={actions.qualityCheckScoreSaved}
                                // onQualityCheckScoreSubmit={actions.qualityCheckScoreSubmitted}
                                // relatedDatas={relatedDatas}
                                // relatedDatasPaging={relatedDatasPaging}
                                // parentLoadMore={actions.parentLoadMore}
                                // relatedLoading={relatedLoading}
                                dialogRemarkList={dialogRemarkList}
                                // changeDialogRemarkList={actions.changeDialogRemarkList}
                                // querryChangeList={actions.querryChangeList}
                                // querrySmartTags={actions.querrySmartTags}
                                isWechatQa={false}
                                // changeToHighlightItemsCallDetail={related ? actions.changeCallDetail : noop}
                                // onUpdated={actions.onUpdated}
                                // refresh={actions.reloadAsyncModel}
                                // rerenderOptimization={true}
                                // requestRelatedRecords={actions.requestRelatedRecords}
                                // relatedRecordsLoading={relatedRecordsLoading}
                                loading={loading}
                                isAppeal={true}
                                // complaintList={complaintList}
                                onComplainPointListChange={onComplainPointListChange}
                                complaintHistory={complaintHistory}
                                appealSdkOptions={sdkOptions}
                                refershHistory={getComplainHistory}
                                refreshList={refreshList}
                                resetComplainPointIdMap={resetComplainPointIdMap}
                                showHit={showHit}
                                onShowHitChange={showHitChange}
                            />
                        </React.Fragment>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
            </div>
        </Page>
    );
};

class Component extends React.Component {
    render() {
        return <SDK {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
