import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import React from "react";
import PropTypes from "prop-types";


export default class CheckPointConditionListGatherComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        onChange: null,
        conditionList: [],
        condition: {},
        type: []
    };
    //#endregion

    //#region propTypes
    static propTypes = {
        onChange: PropTypes.func,
        conditionList: PropTypes.array,
        condition: PropTypes.object,
        type: PropTypes.array,

    };

    privates = {
        conditionTypeList: [
            { key: 1, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.words },
            { key: 2, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.interaction },
            { key: 3, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.feature },
            { key: 4, name: UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.ruleType.information },
        ],
    };
    actions = {
        handleConditionListChange(index, conditionItem) {
            this.privates.conditionList[index] = conditionItem;
            this.actions.update();
            this.trigger("onChange", this.privates.conditionList);
        },
        addCondition(key, e) {
            let { conditionList } = this.privates;
            let index = conditionList[conditionList.length - 1].idCode.substr(1);
            conditionList.push({
                idCode: `R${parseInt(index, 10) + 1}`,
                conditionType: key,
                applyRole: Udesk.enums.applyRoles[0].id,
                applyConditionHitType: 1,
                applyConditionScope: null,
                operator: "all",
                operatorList: [{
                    applyConditionHitType: 1,
                    applyOperatorScope: {
                        key: 'all',
                        id: 1
                    },
                    operator: "all",
                    applyRole: 'all',
                    param: {},
                }]
            });
            // conditionList && this.actions.getOperatorList(conditionList);
            this.actions.handleConditionListChange();
        },
        deleteCondition(index) {
            let { conditionList } = this.privates;
            if (conditionList.length === 1) {
                Udesk.ui.notify.error(UdeskLocales.current.pages.tasks.manage.template.checkPoint.configuration.errorMessage);
                return;
            }
            conditionList.splice(index, 1);
            this.actions.handleConditionListChange();
        }
    };




    parseProps({ props, prevProps, state, privates, isInitial }) {
        let conditionList = JSON.parse(JSON.stringify(props.conditionList));

        return {
            conditionList: conditionList
        };
    }
    componentDidMount() {
    }
}