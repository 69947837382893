import UploadBaseAdapterClass from "./base";
import UploadAwsCredentialClass from "../credentials/upload/aws";
import string from '../../../utils/string';
import date from '../../../utils/date';

export default class UploadAwsAdapterClass extends UploadBaseAdapterClass {

    /*
     * 允许loadPathData返回对象，返回对象的情况下， 必须有以下接口
     * {
     *      serverUrl/rawUrl
     *      clientUrl/encodedUrl
     * }
    */
    loadPathData(files, tokenData) {
        let file = files;
        if (Array.isArray(files)) {
            file = files[0];
        }
        let dir = tokenData && tokenData.dir;
        if (!dir) {
            dir = 'unknown';
        }
        let companyId = tokenData && tokenData.companyId;
        if (!companyId) {
            companyId = 0;
        }
        let createTime = date.format('YYYYMMDD');
        let randomString = string.random(10);
        let fileName = file.name || string.uuid();
        return `${dir}/${companyId}/${createTime}/${randomString}/${fileName}`;
    }

    upload(files, options) {
        let file = files[0];
        let formData = new FormData();
        let url = options.tokenData.host;
        let path = this.loadPathData(files, options.tokenData);
        let { serviceUploadUrl, clientUploadUrl } = formatPath(path, options);
        let uploadData = {
            name: file.name,
            filename: clientUploadUrl,
            size: file.size,
            key: clientUploadUrl,
            path: path,
            token: options.tokenData,
            createTime: file.createTime,
            file: files[0]
        };
        formData.append("key", serviceUploadUrl);
        formData.append("acl", options.tokenData.acl);
        formData.append("success_action_status", `200`);
        formData.append("x-amz-credential", options.tokenData['x-amz-credential']);
        formData.append("x-amz-algorithm", options.tokenData['x-amz-algorithm']);
        formData.append("x-amz-date", options.tokenData['x-amz-date']);
        formData.append("policy", options.tokenData.policy);
        formData.append("x-amz-signature", options.tokenData['x-amz-signature']);
        formData.append("file", file);
        let timeStamp;
        let lastTimeStamp;
        let xhr = window.jQuery.ajax({
            data: formData,
            type: "POST",
            url: url,
            cache: false,
            contentType: false,
            processData: false,
            xhr: awsXhrOnProgress(function (e) {
                let duration;
                if (!lastTimeStamp) {
                    lastTimeStamp = e.timeStamp;
                }
                timeStamp = e.timeStamp;
                if (timeStamp) {
                    duration = timeStamp - lastTimeStamp;
                    let totalTime = duration / (e.loaded / e.total);
                    uploadData.remainingTime = totalTime - duration;
                }
                var percent = ((e.loaded / e.total) * 100).toFixed(2); //计算百分比
                uploadData.percent = percent;
                uploadData.loaded = e.loaded;
                let schedule = percent + "%";
                if (typeof options.progressCallback === "function") {
                    options.progressCallback(file, schedule);
                }
            }),
            success: function (resp) {
                if (typeof options.successCallback === "function") {
                    options.successCallback(file, uploadData);
                }
            },
            error: function (reason) {
                if (typeof options.errorCallback === "function") {
                    options.errorCallback(file, reason, uploadData);
                }
            }
        });
        uploadData.xhr = xhr;
        return new UploadAwsCredentialClass(uploadData);
    }

    getRequestMethod(files, tokenData) {
        let methodType = "POST";
        return methodType;
    }
    getRequestBody(files, tokenData) {
        let file = files[0];
        let path = this.loadPathData(files, tokenData);
        let options = {
            errorCallback: (errorString) => {
                throw new Error(errorString);
            }
        }
        let { serviceUploadUrl, clientUploadUrl } = formatPath(path, options);
        return {
            key: serviceUploadUrl,
            Filename: clientUploadUrl,
            acl: tokenData.acl,
            success_action_status: 200,
            "x-amz-credential": tokenData['x-amz-credential'],
            "x-amz-algorithm": tokenData['x-amz-algorithm'],
            "x-amz-date": tokenData['x-amz-date'],
            policy: tokenData.policy,
            "x-amz-signature": tokenData['x-amz-signature'],
            file: file,            
        }
    }
    getRequestHeaders(files, tokenData) {
        return null;
    }
}

// 处理path路径
function formatPath(path, options) {
    if (path == null || path === "") {
        options.errorCallback("Upload Error: UploadAwsApaterClass.loadPathData result is not valid");
    }
    let serviceUploadUrl = path;
    let clientUploadUrl = path;
    if (typeof path === "object") {
        if ((path.serverUrl == null || path.serverUrl === "") && (path.rawUrl == null || path.rawUrl === "")) {
            options.errorCallback("Upload Error: UploadAwsApaterClass.loadPathData result is not valid");
        }
        serviceUploadUrl = path.rawUrl || path.serverUrl || "";
        if ((path.clientUrl == null || path.clientUrl === "") && (path.encodedUrl == null || path.encodedUrl === "")) {
            options.errorCallback("Upload Error: UploadAwsApaterClass.loadPathData result is not valid");
        }
        clientUploadUrl = path.encodedUrl || path.clientUrl || "";
    }
    return {
        serviceUploadUrl,
        clientUploadUrl
    }
}

//AWS监听
function awsXhrOnProgress(fun) {
    awsXhrOnProgress.onprogress = fun; //绑定监听
    //使用闭包实现监听绑
    return function () {
        //通过$.ajaxSettings.xhr();获得XMLHttpRequest对象
        var xhr = window.jQuery.ajaxSettings.xhr();
        //判断监听函数是否为函数
        if (typeof awsXhrOnProgress.onprogress !== 'function') {
            return xhr;
        }
        //如果有监听函数并且xhr对象支持绑定时就把监听函数绑定上去
        if (awsXhrOnProgress.onprogress && xhr.upload) {
            xhr.upload.onprogress = awsXhrOnProgress.onprogress;
        }
        return xhr;
    }
}