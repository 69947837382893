// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetIntelligentPartnerByRecordIdRecordReScoringParam,
    BaseResponseVoid,
} from '../../../../types';

/**
 * 单个练习/考试记录重新评分
 *
 * @export
 * @tags 智能陪练学习及考试任务详情接口
 * @link [GET] /intelligentPartner/{recordId}/record/reScoring
 * @param recordId
 */
export function getIntelligentPartnerByRecordIdRecordReScoring(
    options: ApiOptions<GetIntelligentPartnerByRecordIdRecordReScoringParam, never> & {
        segments: GetIntelligentPartnerByRecordIdRecordReScoringParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, GetIntelligentPartnerByRecordIdRecordReScoringParam, never>(
        '/intelligentPartner/{recordId}/record/reScoring',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getIntelligentPartnerByRecordIdRecordReScoring',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练学习及考试任务详情接口'],
        path: '/intelligentPartner/{recordId}/record/reScoring',
        method: 'get',
    },
];
