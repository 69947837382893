// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { PostToDosGenLaterOnListenerBody, BaseResponseToDoFoundResponse } from '../../types';

/**
 * genLaterOnListener
 *
 * @export
 * @tags to-do-controller
 * @link [POST] /toDos/genLaterOnListener
 */
export function postToDosGenLaterOnListener(
    data: PostToDosGenLaterOnListenerBody
): Promise<BaseResponseToDoFoundResponse>;
export function postToDosGenLaterOnListener(
    data: PostToDosGenLaterOnListenerBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseToDoFoundResponse>;
export function postToDosGenLaterOnListener(
    data: PostToDosGenLaterOnListenerBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseToDoFoundResponse> {
    return request<PostToDosGenLaterOnListenerBody, never, never>('/toDos/genLaterOnListener', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postToDosGenLaterOnListener',
    });
}

export const meta = [
    { tags: ['to-do-controller'], path: '/toDos/genLaterOnListener', method: 'post' },
];
