import React from "react";
import Udesk from 'Udesk';
import _extend from "lodash-es/extend";
import _find from "lodash-es/find";
import _isEmpty from "lodash-es/isEmpty";
import _compact from "lodash-es/compact";
import _toString from "lodash-es/toString";
import _size from "lodash-es/size";
class AudioCallInfoComponent extends React.Component {
    //#region Default Props
    static propTypes = {};
    static defaultProps = {
        detail: {},
        highlightSeqList: [],
        markSemanticVisible: true,
    };
    //#endregion

    state = {};
    privates = {
        activeKey: "audioText",
        selectKeys: "",
        caseBaseFields: [],
        caseBaseLists: [],
        fieldsList: [],
        libraryId: null,
        caseBaseId: null,
        remark: "",
        editFlag: true
    };
    static computes = {};
    actions = {
        changeActiveTab(tab) {
            this.privates.activeTab = tab;
            this.actions.update();
        },
        sendCurrentTimeRange(item) {
            this.trigger("onTimeRangeChanged", item);
        },
        markSemantic(content) {
            this.trigger("onMarkSemanticClick", content);
        },
        closeHit() {
            this.trigger("onCloseHitClick");
        },
        selectFocus(value, e) {
            this.privates.visibleTab = value;
            this.actions.update();
        },
        selectChange(value) {
            if (value !== "unnamed") {
                this.actions.roadListSelect(value);
            }
            this.privates.activeKey = "selectNode";
            this.privates.fieldsId = value;
            this.actions.update();
        },
        roadListSelect(value) {
            this.privates.libraryId = value;
            let { sdkOptions, detail, id, caseBaseUnRemrks } = this.props;
            let caseBaseUrl = Udesk.business.apiPath.concatApiPath(
                `caseLibraryDatas/caseSearch/${value}/${detail.id ? detail.id : id}`,
                sdkOptions
            );
            new Promise((resolve, reject) => {
                Udesk.ajax.get(caseBaseUrl).then(
                    resp => {
                        resolve(resp);
                        let lists;
                        if (resp.data.dialogText) {
                            lists = JSON.parse(resp.data.dialogText);
                            let list = lists.map(item => {
                                return { id: item.id, caseBaseFlag: true, field: item.text };
                            });
                            this.privates.caseBaseFields = list;
                        } else {
                            this.privates.caseBaseFields = [];
                        }
                        this.privates.fieldsList = _extend(resp.data, {
                            dialogText: lists || null
                        });
                        this.privates.caseBaseId = resp.data.id;
                        caseBaseUnRemrks && caseBaseUnRemrks(resp.data.remarks, true);
                        this.privates.remark = resp.data.remarks;
                        this.privates.caseBaseFlag = value;
                        this.privates.caseBaseLists = [];
                        this.forceUpdate(() => {
                            this.privates.caseBaseFlag = value;
                        });
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
            this.actions.update();
        },

        onTabClick(value) {
            let { caseBaseUnRemrks } = this.props;
            if (!(value === "selectNode" && this.privates.visibleTab)) {
                this.privates.activeKey = value;
                caseBaseUnRemrks && caseBaseUnRemrks("", false);
                this.actions.update();
            }
        },
        saveCaseBase(value) {
            let list = [];
            let { caseBaseFields } = this.privates;
            if (_isEmpty(this.privates.caseBaseFields)) {
                list = [
                    ...caseBaseFields,
                    {
                        id: value.id,
                        caseBaseFlag: value.flag,
                        field: value.inputValue
                    }
                ];
            } else {
                if (
                    _find(
                        this.privates.caseBaseFields,
                        current => current.id === value.id
                    )
                ) {
                    list = this.privates.caseBaseFields.map(item => {
                        if (item.id === value.id) {
                            item.caseBaseFlag = value.flag;
                            item.field = value.inputValue;
                        }
                        return item;
                    });
                } else {
                    list = [
                        ...caseBaseFields,
                        {
                            id: value.id,
                            caseBaseFlag: value.flag,
                            field: value.inputValue
                        }
                    ];
                }
            }

            this.privates.caseBaseFields = list;
        },
        inputFlags(value) {
            this.privates.editFlag = value;
        },
        save() {
            let {
                caseBaseLists,
                caseBaseFields,
                caseBaseId,
                remark,
                editFlag,
                fieldsList
            } = this.privates;
            let { detail } = this.props;
            let { locales } = this;
            if (editFlag) {
                let list = [];
                if (
                    (_isEmpty(caseBaseLists) &&
                        detail.dialogRecords.dialogSentenceList &&
                        detail.inspectDataSource === 1) ||
                    detail.inspectDataSource === 3
                ) {
                    caseBaseLists = detail.dialogRecords.dialogSentenceList.map(
                        (item, index) => {
                            return {
                                ...item,
                                id: index + 1
                            };
                        }
                    );
                } else if (
                    (_isEmpty(caseBaseLists) &&
                        detail.dialogRecords.dialogSentenceList &&
                        detail.inspectDataSource === 2) ||
                    detail.inspectDataSource === 4
                ) {
                    caseBaseLists = detail.dialogRecords.dialogSentenceList;
                }
                if (
                    !fieldsList.dialogText &&
                    !_isEmpty(caseBaseFields) &&
                    fieldsList.id &&
                    fieldsList.libraryId
                ) {
                    caseBaseFields = caseBaseLists.map(item => {
                        let fields = _find(
                            caseBaseFields,
                            current => current.id === item.id
                        );
                        if (fields && fields.caseBaseFlag) {
                            return {
                                id: item.id,
                                caseBaseFlag: true,
                                field: item.text
                            };
                        } else if (fields && !fields.caseBaseFlag) {
                            return {
                                id: item.id,
                                caseBaseFlag: false,
                                field: item.text
                            };
                        } else {
                            return {
                                id: item.id,
                                caseBaseFlag: true,
                                field: item.text
                            };
                        }
                    });
                    caseBaseFields = _compact(caseBaseFields);
                }
                caseBaseLists.forEach(item => {
                    let caseBaseIds = _find(caseBaseFields, current => {
                        return _toString(current.id) === _toString(item.id);
                    });
                    if (caseBaseIds && caseBaseIds.caseBaseFlag) {
                        if (
                            detail.inspectDataSource === 1 ||
                            detail.inspectDataSource === 3
                        ) {
                            list = [
                                ...list,
                                {
                                    begin_time: item.fromSec * 1000 || "",
                                    channel_id: item.role === "agent" ? 1 : 0,
                                    end_time: item.endSec * 1000 || "",
                                    text: caseBaseIds.field,
                                    id: caseBaseIds.id
                                }
                            ];
                        } else {
                            list = [
                                ...list,
                                {
                                    text: item.content,
                                    createdTime: item.createdTime,
                                    id: item.id,
                                    channel_id: item.role === "agent" ? 1 : 0
                                }
                            ];
                        }
                    }
                });
                let data = {
                    dialogText: _size(list) > 0 ? JSON.stringify(list) : null,
                    inspectionDataId: detail.id,
                    libraryId: this.privates.libraryId,
                    remarks: this.props.qcsRef.current.privates.remark || remark
                };
                if (caseBaseId) {
                    _extend(data, { id: caseBaseId });
                }
                if (this.privates.libraryId) {
                    let { sdkOptions } = this.props;
                    let caseBaseUrl = Udesk.business.apiPath.concatApiPath(
                        `caseLibraryDatas`,
                        sdkOptions
                    );
                    new Promise((resolve, reject) => {
                        Udesk.ajax.post(caseBaseUrl, data).then(
                            resp => {
                                resolve(resp);
                                Udesk.ui.notify.success(
                                    locales.components.pages.caseBaseList.detail.successed
                                );
                                this.actions.roadListSelect(this.privates.libraryId);
                                this.trigger("onUpdate");
                            },
                            reason => {
                                Udesk.ui.notify.error(reason.errorMsg);
                                reject(reason);
                            }
                        );
                    });
                } else {
                    Udesk.ui.notify.error(
                        locales.components.pages.caseBaseList.detail.caseType
                    );
                }
            } else {
                Udesk.ui.notify.error(
                    locales.components.pages.caseBaseList.detail.info
                );
            }
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) { }
    componentDidMount() {
        if (this.props.caseLibraryId) {
            let list = this.props.caseLibraryId.split("=");
            if (list[1] !== "true") {
                this.actions.roadListSelect(list[1]);
                this.privates.activeKey = "selectNode";
                this.privates.fieldsId = parseInt(list[1], 10);
                this.actions.update();
            }
        }
    }
    componentWillUnmount() { }
    asyncModel(changedParams) { }
    //#endregion
}

export default AudioCallInfoComponent;
