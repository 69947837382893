import React from 'react';
import Udesk from 'Udesk';
import AudioCallBasicInfoComponent from './component';
import { Tabs, Icon, Button, Select } from 'udesk-ui';
import BusinessRecordsView from '../business-records-view';
import CallTextView from '../call-text-view';
import { TimelySceneTree } from 'Component/pages/wechat-qa/chart-record/detail/template';
import './style.scss';
import UdeskLocales from 'UdeskLocales';
const { TabPane } = Tabs;

export default class AudioCallBasicInfo extends AudioCallBasicInfoComponent {
    render() {
        let {
            detail,
            width,
            isBusinessRecordsVisible,
            inspectDataSource,
            isWechatQa,
            visible,
            changeBaseInfoOnOff,
            isTimelyScene = false,
            timelySceneList = [], // 触发场景
        } = this.props;
        let { locales } = this;
        let { basicReaf, /* lineFlag, */ phoneFlag /* content */, highlightPoint, ticketIndex } =
            this.privates;
        const isTicket = inspectDataSource === Udesk.enums.inspectDataSources.ticket.id;

        console.log('detail.ticketInfoList', detail?.ticketInfoList);
        return (
            <div
                className="audio-call-basic-info-page-v2"
                style={{ width: visible ? width : 0 }}
                ref={basicReaf}
            >
                {/* activeKey={activeKey} style={{ width: '100%' }} onTabClick={actions.onTabClick} */}
                <Tabs
                    style={{ width: '100%' }}
                    defaultActiveKey={`businessRecords-${detail.id}`}
                    tabBarExtraContent={
                        typeof changeBaseInfoOnOff === 'function' &&
                        visible && (
                            <Button
                                onClick={changeBaseInfoOnOff}
                                type="text"
                                size="small"
                                icon={<Icon type="MenuFoldOutlined" antdIcon={true} />}
                            ></Button>
                        )
                    }
                >
                    <If condition={!phoneFlag}>
                        <TabPane
                            tab={
                                {
                                    [Udesk.enums.inspectDataSources.textDialogue.id]:
                                        locales.components.pages.appealChekingTask.list.dialogInfo,
                                    [Udesk.enums.inspectDataSources.realTimeTextDialogue.id]:
                                        locales.components.pages.appealChekingTask.list.dialogInfo,
                                    [Udesk.enums.inspectDataSources.wechatDialogue.id]:
                                        locales.components.pages.appealChekingTask.list.wechatInfo,
                                    [Udesk.enums.inspectDataSources.ticket.id]:
                                        locales.components.pages.appealChekingTask.list.ticketInfo,
                                }[inspectDataSource] ||
                                locales.components.pages.appealChekingTask.list.callInfo
                            }
                            key={`callInfos-${detail.id}`}
                        >
                            <div className="audio-text-view">
                                <If
                                    condition={
                                        detail && detail.fieldList && detail.fieldList.length > 0
                                    }
                                >
                                    <CallTextView
                                        callInfoList={detail.fieldList}
                                        fieldValueMap={detail.fieldValueMap}
                                    />
                                </If>
                            </div>
                        </TabPane>
                    </If>
                    <If condition={!isWechatQa}>
                        <If condition={isBusinessRecordsVisible && !phoneFlag}>
                            <TabPane
                                tab={
                                    locales.components.pages.appealChekingTask.list.businessRecords
                                }
                                key={`businessRecords-${detail.id}`}
                            >
                                <div
                                    className="audio-text-view"
                                    style={{ display: phoneFlag ? 'none' : 'block' }}
                                >
                                    <If condition={detail.noteInfoExtension}>
                                        <BusinessRecordsView
                                            noteInfoExtension={detail.noteInfoExtension}
                                        />
                                    </If>
                                </div>
                            </TabPane>
                            <TabPane
                                tab={
                                    /* 工单信息 */ UdeskLocales['current'].components
                                        .audioCallBasicInfoV2.template.workOrderInformation
                                }
                                key={`ticket-${detail.id}`}
                            >
                                <div
                                    className="audio-text-view"
                                    style={{ display: phoneFlag ? 'none' : 'block' }}
                                >
                                    {Array.isArray(detail.ticketInfoList) && (
                                        <>
                                            {detail.ticketInfoList.length > 1 && (
                                                <Select
                                                    options={detail.ticketInfoList.map((t, i) => ({
                                                        label:
                                                            t.subject ||
                                                            `${
                                                                /* 工单 */ UdeskLocales['current']
                                                                    .components.audioCallBasicInfoV2
                                                                    .template.workOrder
                                                            }${i + 1}`,
                                                        value: i,
                                                    }))}
                                                    onChange={this.actions.onTicketChange}
                                                    style={{
                                                        width: '100%',
                                                        marginTop: 8,
                                                        padding: '0 8px',
                                                    }}
                                                    value={ticketIndex}
                                                />
                                            )}
                                            {detail.ticketInfoList.length > 0 && (
                                                <If condition={detail.ticketInfoList[ticketIndex]}>
                                                    <div>
                                                        <CallTextView
                                                            callInfoList={
                                                                detail.ticketInfoList[ticketIndex]
                                                                    .fieldList
                                                            }
                                                            fieldValueMap={
                                                                detail.ticketInfoList[ticketIndex]
                                                                    .fieldValueMap
                                                            }
                                                        />
                                                    </div>
                                                </If>
                                            )}
                                        </>
                                    )}
                                </div>
                            </TabPane>
                        </If>
                        {/* <If condition={!phoneFlag}>
                            <TabPane tab={locales.components.pages.semanticTags.title} key='audioText' style={{ display: phoneFlag ? 'none' : 'block' }}>
                                <SemanticMarkInfo content={content} taskId={taskId} />
                            </TabPane>
                        </If> */}
                    </If>
                    {isTicket && (
                        <TabPane
                            tab={
                                /* 关联通话/对话 */ locales.components.audioCallBasicInfoV2.template
                                    .associatedCallConversation
                            }
                            key={`relatedDetails-${detail.id}`}
                        >
                            <div
                                className="audio-text-view"
                                style={{ display: phoneFlag ? 'none' : 'block' }}
                            >
                                <If condition={detail.noteInfoExtension}>
                                    <BusinessRecordsView
                                        noteInfoExtension={detail.noteInfoExtension}
                                    />
                                </If>
                            </div>
                        </TabPane>
                    )}
                    {isTimelyScene && (
                        <TabPane
                            tab={
                                /* 触发场景 */ UdeskLocales['current'].components
                                    .audioCallBasicInfoV2.template.triggerScenario
                            }
                            key={`triggerScene-${detail.id}`}
                        >
                            <div className="timely-scene-view">
                                <TimelySceneTree
                                    {...{
                                        timelySceneList,
                                        highlightPoint,
                                        setHighlightPoint: this.actions.setHighlightPoint,
                                    }}
                                />
                            </div>
                        </TabPane>
                    )}
                </Tabs>
            </div>
        );
    }
}
