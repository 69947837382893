import PieLikeClass from './pie-like';

const DEFAULT_CHART_SIZE_PERCENTAGE = 85;

class RingOptionsBuilderClass extends PieLikeClass {
    getRadius(optionsOwner) {
        return [parseInt(DEFAULT_CHART_SIZE_PERCENTAGE - 35, 10) + "%", parseInt(DEFAULT_CHART_SIZE_PERCENTAGE - 20, 10) + "%"];
    }

    getSeriesOptions(optionsOwner) {
        let series = super.getSeriesOptions(...arguments);
        for (let seriesItem of series) {
            let itemStyle = {
                normal: {
                    borderColor: "#ffffff",
                    borderWidth: 1,
                },
                emphasis: {
                    borderWidth: 2,
                }
            };

            seriesItem.itemStyle = itemStyle;
        }
        return series;
    }
}

export default RingOptionsBuilderClass;
