import React, { useMemo, useState } from 'react';
import Udesk from 'Udesk';
import PageLeftTable from './components/pageLeftTable';
import PageRightTable from './components/pageRightTable';
import Locales from 'UdeskLocales';
import './index.scss'; 

const Template = React.memo((props: any) => {
    const locales = Locales['current'];
    const {
        selectedRowKey, 
        setSelectedRowKey, 
        sdkOptions, 
        history,
        SmartToolBarRef,
    } = props;
    const [data, setData] = useState<any[]>([]);

    const scenario = useMemo(() => {
        return history.location.pathname.indexOf('dissent') > 0 ? Udesk.enums.applicationScenarios.objection.id : Udesk.enums.applicationScenarios.sellingPoints.id;
    }, [history.location.pathname]);

    return (
        <div className='udesk-qa-web-page words-mining-favorite-page selling-point'>
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root'>
                    <div>
                        <PageLeftTable {
                            ...{
                                scenario,
                                data,
                                setData,
                                history,
                                locales,
                                sdkOptions,
                                selectedRowKey, 
                                setSelectedRowKey,
                            }
                        }/>
                        <PageRightTable {
                            ...{
                                scenario,
                                groupDataSource: data,
                                SmartToolBarRef,
                                history,
                                locales,
                                sdkOptions,
                                selectedRowKey, 
                            }
                        }/>
                    </div>

                </div>
            </div>
        </div>
        
    );
});

class Component extends React.Component {
    privates = {
        storages: {
            selectedRowKey: null,
        },
    };
    enableStorage = true;
    storageStrategies = {
        storageWay: 'sessionStorage',
        resetStrategies: {
            transitionToRoutes: [],
            notTransitionToRoutes: [
                /^\/algorithm-platform\/training-center\/words-mining\/*/i,
            ],
        },
    };
    actions = {
        saveStorages(changedParams: any) {
            (this as any).privates.storages = { ...(this as any).privates.storages, ...changedParams };
        },
        setSelectedRowKey (selectedRowKey) {
            (this as any).actions.saveStorages({selectedRowKey});
            (this as any).actions.update();
        }
    };
    render() {
        return (
            <Template {...this.props}
                setSelectedRowKey={this.actions.setSelectedRowKey} 
                selectedRowKey={this.privates.storages.selectedRowKey} />
        );
    }
}

export default Udesk.react.udeskify(Component);