// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewIntelligentPartnerNodeInputSceneConfigQuery,
    BaseResponseIntelligentPartnerDialogNodeInputSceneConfigFoundResponse,
    PostReviewIntelligentPartnerNodeInputSceneConfigBody,
} from '../../../../types';

/**
 * 根据nodeId查询输入场景配置信息
 *
 * @export
 * @tags 智能陪练-AI输入场景节点配置
 * @link [GET] /review/intelligentPartner/node/inputSceneConfig
 */
export function getReviewIntelligentPartnerNodeInputSceneConfig(
    options: ApiOptions<never, GetReviewIntelligentPartnerNodeInputSceneConfigQuery> & {
        params: GetReviewIntelligentPartnerNodeInputSceneConfigQuery;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeInputSceneConfigFoundResponse> {
    return request<never, never, GetReviewIntelligentPartnerNodeInputSceneConfigQuery>(
        '/review/intelligentPartner/node/inputSceneConfig',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeInputSceneConfig',
        }
    );
}

/**
 * save
 *
 * @export
 * @tags 智能陪练-AI输入场景节点配置
 * @link [POST] /review/intelligentPartner/node/inputSceneConfig
 */
export function postReviewIntelligentPartnerNodeInputSceneConfig(
    data: PostReviewIntelligentPartnerNodeInputSceneConfigBody
): Promise<BaseResponseIntelligentPartnerDialogNodeInputSceneConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeInputSceneConfig(
    data: PostReviewIntelligentPartnerNodeInputSceneConfigBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeInputSceneConfigFoundResponse>;
export function postReviewIntelligentPartnerNodeInputSceneConfig(
    data: PostReviewIntelligentPartnerNodeInputSceneConfigBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseIntelligentPartnerDialogNodeInputSceneConfigFoundResponse> {
    return request<PostReviewIntelligentPartnerNodeInputSceneConfigBody, never, never>(
        '/review/intelligentPartner/node/inputSceneConfig',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postReviewIntelligentPartnerNodeInputSceneConfig',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-AI输入场景节点配置'],
        path: '/review/intelligentPartner/node/inputSceneConfig',
        method: 'get',
    },
    {
        tags: ['智能陪练-AI输入场景节点配置'],
        path: '/review/intelligentPartner/node/inputSceneConfig',
        method: 'post',
    },
];
