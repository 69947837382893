// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    BaseResponseLoginIpLimitConfig,
    PostCompanysLoginIpLimitBody,
    BaseResponseVoid,
} from '../../../types';

/**
 * 查询登录限制IP配置
 *
 * @export
 * @tags 租户接口
 * @link [GET] /companys/loginIp/limit
 */
export function getCompanysLoginIpLimit(): Promise<BaseResponseLoginIpLimitConfig>;
export function getCompanysLoginIpLimit(
    options: ApiOptions<never, never>
): Promise<BaseResponseLoginIpLimitConfig>;
export function getCompanysLoginIpLimit(
    options?: ApiOptions<never, never>
): Promise<BaseResponseLoginIpLimitConfig> {
    return request<never, never, never>('/companys/loginIp/limit', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCompanysLoginIpLimit',
    });
}

/**
 * 修改/新增登录限制IP配置
 *
 * @export
 * @tags 租户接口
 * @link [POST] /companys/loginIp/limit
 */
export function postCompanysLoginIpLimit(
    data: PostCompanysLoginIpLimitBody
): Promise<BaseResponseVoid>;
export function postCompanysLoginIpLimit(
    data: PostCompanysLoginIpLimitBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postCompanysLoginIpLimit(
    data: PostCompanysLoginIpLimitBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostCompanysLoginIpLimitBody, never, never>('/companys/loginIp/limit', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCompanysLoginIpLimit',
    });
}

export const meta = [
    { tags: ['租户接口'], path: '/companys/loginIp/limit', method: 'get' },
    { tags: ['租户接口'], path: '/companys/loginIp/limit', method: 'post' },
];
