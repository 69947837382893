import React, { useState, useMemo, useCallback } from 'react';
import { useWordStyle } from 'src/util/hook/useWordStyle';
import { Button, Drawer, Input, Space, Spin, Form, Select, InputNumber, message } from 'udesk-ui';
import FormHeader from '../FormHeader';
import { CustomerPortraitConfig } from 'Component/pages/components/coach/CustomerPortrait';
import { IndeterminateCircleOutlined, AddCircleOutlined } from '@udesk/icons';
import styled from 'styled-components';
import { postReviewIntelligentPartnerNodeAiWords } from 'src/api/review/intelligentPartner/node/aiWords';
import { postReviewIntelligentPartnerNodeAiFlowByFlowId } from 'src/api/review/intelligentPartner/node/aiFlow/{flowId}';
import { onCopyText } from 'src/util/copy';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

const FormWrapper = styled.div`
    padding: 16px;
`;

const NodeListFormWrapper = styled.div`
    padding: 16px;
    background-color: #eee;
    margin: 16px 16px 32px;

    .udesk-qa-ui-form-item {
        margin-bottom: 12px;
    }
`;

const Operations = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ListWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const ListItemWrapper = styled.div`
    flex: 1;
    margin-right: 10px;
`;

const InitializedConfigDrawer = (props) => {
    const {
        visible,
        onClose,
        form,
        loading,
        onFinish,
        nodeForm,
        onApply,
        hasGen,
        getNextSimulatedRoleLabel,
        onCopy,
        wordStyleOptions,
    } = props;

    return (
        <Drawer
            width={500}
            style={{ position: 'absolute' }}
            getContainer={document.getElementsByClassName('udesk-qa-web-page')[0] as any}
            className="udesk-coach-web-config-drawer"
            visible={visible}
            title={/* AI生成流程话术 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.aIGenerationProcessScript}
            mask={false}
            footer={null}
            onClose={onClose}
        >
            <Spin spinning={loading}>
                <FormHeader form={form} />

                <FormWrapper>
                    <Form
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 19 }}
                        initialValues={{
                            wordsStyle: 1,
                            sessionRound: 20,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label={
                                /* 对话场景 */ UdeskLocales['current'].pages.coach.courseManagement
                                    .flow.components.const.nodeConfigs.aiGenSceneNode
                                    .dialogueScenario
                            }
                            name="dialogueScene"
                            rules={[{ required: true }]}
                        >
                            <Input.TextArea rows={2} />
                        </Form.Item>

                        <Form.Item
                            label={
                                /* 模拟角色 */ UdeskLocales['current'].pages.coach.courseManagement
                                    .flow.components.const.nodeConfigs.aiGenSceneNode
                                    .simulateCharacter
                            }
                            name="simulatedRole"
                            rules={[{ required: true }]}
                        >
                            <CustomerPortraitConfig.Select />
                        </Form.Item>

                        <Form.Item
                            label={
                                /* 陪练对象 */ UdeskLocales['current'].pages.coach.courseManagement
                                    .flow.components.const.nodeConfigs.aiGenSceneNode
                                    .accompanyingTrainees
                            }
                            name="sparringPartner"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={/* 话术风格 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.scriptStyle}
                            name="wordsStyle"
                            rules={[{ required: true }]}
                        >
                            <Select options={wordStyleOptions} />
                        </Form.Item>

                        <Form.Item
                            label={/* 对话轮次最多 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.theMostConversationRounds}
                            name="sessionRound"
                            rules={[{ required: true }]}
                        >
                            <InputNumber style={{ width: '100%' }} max={50} min={0} />
                        </Form.Item>

                        <Form.Item noStyle>
                            <Operations>
                                <Space>
                                    {hasGen ? (
                                        <>
                                            <Button onClick={onCopy}>{/* 复制对话 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.copyConversation}</Button>
                                            <Button onClick={onApply}>{/* 应用 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.application}</Button>
                                            <Button type="primary" htmlType="submit">{/* 重新生成 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.regenerate}</Button>
                                        </>
                                    ) : (
                                        <Button type="primary" htmlType="submit">{/* 开始生成 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.startGenerating}</Button>
                                    )}
                                </Space>
                            </Operations>
                        </Form.Item>
                    </Form>
                </FormWrapper>

                {hasGen ? (
                    <NodeListFormWrapper>
                        <Form form={nodeForm} size="small">
                            <Form.List name="aiNodeWordsList">
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            {fields.map((f) => {
                                                return (
                                                    <ListWrapper key={f.key}>
                                                        <ListItemWrapper>
                                                            <Space>
                                                                <Form.Item
                                                                    name={[f.name, 'role']}
                                                                    noStyle
                                                                >
                                                                    <Input
                                                                        bordered={false}
                                                                        readOnly
                                                                        style={{ width: 90 }}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[f.name, 'words']}
                                                                    noStyle
                                                                >
                                                                    <Input style={{ width: 310 }} />
                                                                </Form.Item>
                                                            </Space>
                                                        </ListItemWrapper>

                                                        <IndeterminateCircleOutlined
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => remove(f.name)}
                                                        />
                                                    </ListWrapper>
                                                );
                                            })}

                                            <AddCircleOutlined
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    getNextSimulatedRoleLabel((next) => {
                                                        add({
                                                            role: next,
                                                        });
                                                    });
                                                }}
                                            />
                                        </>
                                    );
                                }}
                            </Form.List>
                        </Form>
                    </NodeListFormWrapper>
                ) : null}
            </Spin>
        </Drawer>
    );
};

const useConfigDrawer = (props) => {
    const { requestFlowData, flowId, allCustomerPortrait, reactFlowInstance } = props;

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [hasGen, setHasGen] = useState(false);

    const { options: wordStyleOptions } = useWordStyle();

    const onOpen = useCallback(() => {
        setVisible(true);
        form.resetFields();
    }, [form]);

    const onClose = () => {
        setVisible(false);
        setHasGen(false);
    };

    const [nodeForm] = Form.useForm();

    const [curSimulatedRole, setCurSimulatedRole] = useState<number>();

    const [curSparringPartner, setCurSparringPartner] = useState<string>();

    const onFinish = useMemo(() => {
        return (values) => {
            setLoading(true);
            return postReviewIntelligentPartnerNodeAiWords({
                ...values,
            })
                .then((res) => {
                    setCurSimulatedRole(values.simulatedRole);
                    setCurSparringPartner(values.sparringPartner);
                    nodeForm.setFieldsValue({ aiNodeWordsList: res.data || [] });
                    setHasGen(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
    }, [nodeForm]);

    const simulatedRoleLabel = useMemo(() => {
        return allCustomerPortrait.find((c) => c.id === curSimulatedRole)?.name;
    }, [allCustomerPortrait, curSimulatedRole]);

    const getNextSimulatedRoleLabel = useCallback(
        (callback) => {
            const aiNodeWordsList = nodeForm.getFieldsValue()?.aiNodeWordsList || [];
            const last = aiNodeWordsList[aiNodeWordsList.length - 1]?.role;
            let next = curSparringPartner;
            if (last === curSparringPartner) {
                next = simulatedRoleLabel;
            }
            callback(next);
        },
        [curSparringPartner, nodeForm, simulatedRoleLabel]
    );

    const onApply = () => {
        nodeForm.validateFields().then((values) => {
            const list = values.aiNodeWordsList || [];
            if (!list.length) {
                message.warning(/* 请生成流程 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.pleaseGenerateAProcess);
                return;
            }

            postReviewIntelligentPartnerNodeAiFlowByFlowId(
                {
                    aiNodeWordsList: values.aiNodeWordsList,
                    inputSceneConfig: { sparringPartner: curSparringPartner },
                },
                { segments: { flowId } }
            ).then(() => {
                requestFlowData(() => {
                    reactFlowInstance.setViewport({ x: 0, y: 0 }, { duration: 300 });
                });
            });
        });
    };

    const onCopy = () => {
        let text = '';
        const aiNodeWordsList = nodeForm.getFieldsValue()?.aiNodeWordsList || [];
        if (!aiNodeWordsList.length) {
            message.warning(/* 请生成流程 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.pleaseGenerateAProcess);
            return;
        }
        text = aiNodeWordsList.reduce((pre, cur) => {
            return `${pre}\n${cur.role}：${cur.words}`;
        }, text);
        onCopyText(text, () => {
            message.success(/* 复制成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.aiGenFlowDrawer.index.copySuccessful);
        });
    };

    return {
        visible,
        onOpen,
        onClose,
        form,
        loading,
        onFinish,
        nodeForm,
        onApply,
        hasGen,
        getNextSimulatedRoleLabel,
        onCopy,
        wordStyleOptions,
    };
};

type ConfigDrawerType = typeof InitializedConfigDrawer & {
    useConfigDrawer: typeof useConfigDrawer;
};

const AiGenFlowDrawer: ConfigDrawerType = InitializedConfigDrawer as ConfigDrawerType;

AiGenFlowDrawer.useConfigDrawer = useConfigDrawer;

export { AiGenFlowDrawer };
