import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';

class ReviewList extends React.Component {
    isRedirect(currentPath, toPath) {
        let fullCurrentPath = Udesk.ui.routing.getRoutePath({
            routeName: currentPath.name,
            pathParams: this.props.match.params
        });
        if (fullCurrentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let {
            routes,
            route,
            location,
            sdkOptions,
            match
        } = this.props;
        if (this.isRedirect(route, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: route.name,
                pathParams: match.params
            });
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else {
            return (
                <div className="quality-checking-task" style={{height: '100%'}}>
                    {routes.map((routeItem, i) => (
                        <SubRoutes key={i} route={routeItem} />
                    ))}
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(ReviewList);