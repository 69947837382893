import { useCallback, useEffect, useState } from 'react';

interface UseRequestOptions {
    autoRequestWhenComponentDidMount?: boolean;
}

function useRequest(cb: () => void, options: UseRequestOptions = {}) {
    const { autoRequestWhenComponentDidMount = false } = options;
    const [flag, triggerRequest] = useState(1);
    useEffect(() => {
        if (!autoRequestWhenComponentDidMount && flag === 1) return;
        typeof cb === 'function' && cb?.();
    }, [flag]);

    const trigger = useCallback(() => {
        triggerRequest((prev) => {
            let ret;
            do {
                ret = Math.random();
            } while (ret === prev);
            return ret;
        });
    }, []);
    return trigger;
}

export default useRequest;
