import {
    ArgumentNullError
} from '../../error';

const DEFAULT_MULTIPLE_SYNC_FILE_LOADER_CLASS_OPTIONS = {
    async: true,
    type: "text/javascript",
    containerTagName: "head",
    fileTagName: "script",
};

export default class MultipleSyncFileLoaderClass {
    constructor(options) {
        this._loadFileMap = new Map();
        this._options = Object.assign({}, DEFAULT_MULTIPLE_SYNC_FILE_LOADER_CLASS_OPTIONS, options);
    }

    loadAsync(url, callback) {
        if (url == null || url === "") {
            throw new ArgumentNullError(`url`);
        }

        if (this._loadFileMap.has(url)) {
            let loadFile = this._loadFileMap.get(url);
            if (!loadFile.isLoaded) {
                loadFile.callbacks.push(callback);
                this._loadFileMap.set(url, loadFile);
            } else {
                callback();
            }
        } else {
            this._loadFileMap.set(url, {
                callbacks: [callback],
                isLoaded: false
            });
            this._loadFileAsync(url);
        }
    }

    getFieldLoadStatus(url) {
        if(url != null) {
            if(this._loadFileMap.has(url)){
                let loadFile = this._loadFileMap.get(url);
                return loadFile.isLoaded;
            }
        }
        return false;
    }

    remove(url, callback) {
        if (this._loadFileMap.has(url)) {
            let loadFile = this._loadFileMap.get(url);
            loadFile.callbacks = loadFile.callbacks.filter(item => callback !== item);
            this._loadFileMap.set(url, loadFile);
        }
    }

    clear() {
        this._loadFileMap.clear();
    }

    destory() {
        if (this._loadFileMap) {
            this.clear();
            this._loadFileMap = null;
        }
    }

    _loadFileAsync(url) {
        if (this._loadFileMap.has(url)) {
            let loadFile = this._loadFileMap.get(url);
            var fileDom = document.createElement(this._options.fileTagName || DEFAULT_MULTIPLE_SYNC_FILE_LOADER_CLASS_OPTIONS.fileTagName);
            fileDom.type = this._options.type || DEFAULT_MULTIPLE_SYNC_FILE_LOADER_CLASS_OPTIONS.type;
            fileDom.async = this._options.async || DEFAULT_MULTIPLE_SYNC_FILE_LOADER_CLASS_OPTIONS.async;
            fileDom.src = url;

            document.getElementsByTagName(this._options.containerTagName || DEFAULT_MULTIPLE_SYNC_FILE_LOADER_CLASS_OPTIONS.containerTagName)[0].appendChild(fileDom);
            fileDom.onload = () => {
                loadFile.callbacks.forEach(callback => callback(true));
                loadFile.callbacks = [];
                loadFile.isLoaded = true;
                this._loadFileMap.set(url, loadFile);
            }
        }
    }
}