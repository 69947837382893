// @ts-nocheck

import type { RequestResponse } from 'umi-request';
import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { GetVoiceUploadResultByIdParam } from '../../../types';

/**
 * result
 *
 * @export
 * @tags native-voice-upload-controller
 * @link [GET] /voice-upload/result/{id}
 * @param id
 */
export function getVoiceUploadResultById(
    options: ApiOptions<GetVoiceUploadResultByIdParam, never> & {
        segments: GetVoiceUploadResultByIdParam;
    }
): Promise<RequestResponse<void>> {
    return request<never, GetVoiceUploadResultByIdParam, never>('/voice-upload/result/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getVoiceUploadResultById',
    });
}

export const meta = [
    { tags: ['native-voice-upload-controller'], path: '/voice-upload/result/{id}', method: 'get' },
];
