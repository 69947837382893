import React, { useEffect, useState } from 'react';
import UdeskList from 'Component/common/udesk-list';
import { Button, FormBuilder, Icon, Input, Modal, Select, Space } from 'udesk-ui';
import { arrayMoveImmutable } from 'array-move';
import cusConfigMap from 'src/common/config/form-config/cus-component-map';
import UdeskLocales from 'UdeskLocales';
import { NewAssociateComponent } from 'Component/pages/components/check-point-operator-list-gather/template';
const OptionItem = (props) => {
    const locales = UdeskLocales['current'];
    const {
        associateList = [],
        value = {
            operatorType: 29,
            idCode: 'R1',
            operatorList: [
                {
                    applyConditionHitType: 1,
                    applyOperatorScope: {
                        id: 1,
                        key: 'all',
                    },
                    type: 29,
                    operator: 'all',
                    applyRole: 'all',
                    param: {
                        type: 29,
                        inputContent: [],
                    },
                },
            ],
            operatorLogic: '1',
            operator: 'all',
            applyRole: 'all',
            param: {},
            conditionType: 4,
            applyConditionHitType: 1,
        },
        onChange,
        keyEventList = [],
    } = props;

    const onAssociateChange = (v) => {
        onChange({
            ...value,
            operatorType: 21,
            operatorList: [
                {
                    applyConditionHitType: 1,
                    applyOperatorScope: {
                        id: 1,
                        key: 'all',
                    },
                    type: 21,
                    operator: 'all',
                    applyRole: 'all',
                    param: {
                        version: 1,
                        type: 21,
                        inputContent: v,
                    },
                },
            ],
            operatorLogic: '1',
            operator: 'all',
            applyRole: 'all',
            param: {},
            conditionType: 4,
            applyConditionHitType: 1,
        });
    };

    return (
        <Space>
            <div style={{ width: 26 }}>{value.idCode}</div>
            <Select
                value={value?.operatorType}
                onSelect={(v) => {
                    let newValue: any = {
                        operatorType: v,
                        idCode: value.idCode,
                    };
                    if (v === 21) {
                        newValue.operatorList = [
                            {
                                param: {
                                    inputContent: '',
                                },
                            },
                        ];
                    }
                    if (v === 29) {
                        newValue.operatorList = [
                            {
                                applyConditionHitType: 1,
                                applyOperatorScope: {
                                    id: 1,
                                    key: 'all',
                                },
                                type: 29,
                                operator: 'all',
                                applyRole: 'all',
                                param: {
                                    type: 29,
                                    inputContent: [], // 内容，关键事件填关键事件ID数组
                                },
                            },
                        ];
                    }
                    onChange(newValue);
                }}
                options={[
                    {
                        label: /* 关键事件 */ UdeskLocales['current'].pages.gong
                            .businessConfiguration.salesStage.detail.components.stageList.keyEvents,
                        value: 29,
                    },
                    {
                        label: /* 随路数据 */ UdeskLocales['current'].pages.gong
                            .businessConfiguration.salesStage.detail.components.stageList
                            .waysideData,
                        value: 21,
                    },
                ]}
            ></Select>
            {value?.operatorType === 21 && (
                <div style={{ marginLeft: '-8px' }}>
                    <NewAssociateComponent
                        // @ts-ignore
                        value={value?.operatorList?.[0]?.param?.inputContent ?? ''}
                        onChange={onAssociateChange}
                        associateList={associateList}
                        locales={locales}
                    ></NewAssociateComponent>
                </div>
            )}
            {value?.operatorType === 29 && (
                <React.Fragment>
                    <Select
                        style={{
                            minWidth: 200,
                        }}
                        value={value?.operatorList?.[0]?.operator ?? ''}
                        onSelect={(v) => {
                            let newValue: any = {
                                ...value,
                            };
                            newValue.operatorList[0].operator = v;
                            newValue.operator = v;
                            onChange(newValue);
                        }}
                        options={[
                            {
                                label: /* 包含任意 */ UdeskLocales['current'].pages.gong
                                    .businessConfiguration.salesStage.detail.components.stageList
                                    .includeAny,
                                value: 'any',
                            },
                            {
                                label: /* 包含全部 */ UdeskLocales['current'].pages.gong
                                    .businessConfiguration.salesStage.detail.components.stageList
                                    .includeAll,
                                value: 'all',
                            },
                        ]}
                    ></Select>
                    <Select
                        mode="multiple"
                        options={keyEventList}
                        value={value?.operatorList?.[0]?.param?.inputContent}
                        onChange={(v) => {
                            let newValue: any = {
                                ...value,
                            };
                            newValue.operatorList[0].param.inputContent = v;
                            onChange(newValue);
                        }}
                        style={{
                            minWidth: 200,
                        }}
                    ></Select>
                </React.Fragment>
            )}
        </Space>
    );
};

const OptionList = (props) => {
    const { associateList, value = [], onChange, keyEventList } = props;
    const onItemChange = (index, newValue) => {
        let res = [...value];
        res[index] = newValue;
        onChange(res);
    };
    return (
        <UdeskList
            deleteItem={(index) => {
                let newValue = [...value];
                newValue[index] = null;
                onChange?.(newValue.filter(Boolean));
            }}
            addItem={() => {
                const idCode = `R${
                    value.length
                        ? parseInt(value[value.length - 1].idCode.replace('R', ''), 10) + 1
                        : 1
                }`;
                onChange?.([
                    ...value,
                    {
                        operatorType: 29,
                        idCode,
                        operatorList: [
                            {
                                applyConditionHitType: 1,
                                applyOperatorScope: {
                                    id: 1,
                                    key: 'all',
                                },
                                type: 29,
                                operator: 'all',
                                applyRole: 'all',
                                param: {
                                    version: 1,
                                    type: 29,
                                    inputContent: [],
                                },
                            },
                        ],
                        operatorLogic: '1',
                        operator: 'all',
                        applyRole: 'all',
                        param: {},
                        conditionType: 4,
                        applyConditionHitType: 1,
                    },
                ]);
            }}
            wrapStyle={{
                maxHeight: 300,
                overflowY: 'auto',
            }}
        >
            {value.map((item, index) => {
                return (
                    <OptionItem
                        value={item}
                        associateList={associateList}
                        keyEventList={keyEventList}
                        onChange={onItemChange.bind(null, index)}
                    ></OptionItem>
                );
            })}
        </UdeskList>
    );
};
const StageItem = (props) => {
    const { index, onChange: propsOnChange, value, associateList, keyEventList } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const [conditions, setConditions] = useState<any>([]);
    const [form] = FormBuilder.useForm();
    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
        setConditions(value.conditionList ?? []);
    }, [visible]);

    const onOk = () => {
        form.validateFields().then((values) => {
            propsOnChange(index, { ...value, ...values });
            setVisible(false);
        });
    };
    return (
        <Space>
            <Input
                onChange={(e) => {
                    propsOnChange(index, { ...value, stageName: e.target.value });
                }}
                placeholder={
                    /* 请输入阶段名称 */ UdeskLocales['current'].pages.gong.businessConfiguration
                        .salesStage.detail.components.stageList.pleaseEnterTheStageName
                }
                value={value.stageName}
                style={{ width: 200 }}
            ></Input>
            <Button
                type="text"
                tooltip={
                    /* 设置阶段规则 */ UdeskLocales['current'].pages.gong.businessConfiguration
                        .salesStage.detail.components.stageList.setStageRules
                }
                onClick={() => setVisible(true)}
                icon={
                    <Icon
                        type="SettingOutlined"
                        antdIcon={true}
                        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                    />
                }
            ></Button>
            <Modal
                title={
                    /* 设置阶段规则 */ UdeskLocales['current'].pages.gong.businessConfiguration
                        .salesStage.detail.components.stageList.setStageRules
                }
                visible={visible}
                onCancel={() => setVisible(false)}
                width={1000}
                onOk={onOk}
            >
                <FormBuilder
                    form={form}
                    customComponents={{ ...cusConfigMap, OptionList }}
                    initialValues={value}
                    fields={[
                        {
                            label: /* 阶段名称 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.salesStage.detail.components.stageList
                                .stageName,
                            name: 'stageName',
                            type: 'Input',
                            props: {
                                style: {
                                    width: 200,
                                },
                                placeholder: UdeskLocales['current'].labels.pleaseEnter,
                            },
                        },
                        {
                            label: /* 算子 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.salesStage.detail.components.stageList
                                .operator,
                            type: 'OptionList',
                            name: 'conditionList',
                            props: {
                                associateList,
                                keyEventList,
                                onChange: setConditions,
                            },
                        },
                        {
                            label: /* 逻辑 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.salesStage.detail.components.stageList.logic,
                            name: 'ruleFormulas',
                            type: 'Logic',
                            props: {
                                conditions,
                                isCondition: true,
                            },
                        },
                    ]}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 20 }}
                    footerRender={false}
                />
            </Modal>
        </Space>
    );
};

const StageList = (props) => {
    const { value: valueProps, onChange, associateList, keyEventList } = props;
    const value = valueProps || [];
    const onDetailChange = (index, values) => {
        let newValue = [...value];
        newValue[index] = values;
        onChange?.(newValue);
    };
    return (
        <React.Fragment>
            <UdeskList
                dragable={true}
                onSort={(oldIndex, newIndex) => {
                    onChange?.(arrayMoveImmutable(value, oldIndex, newIndex));
                }}
                deleteItem={(index) => {
                    let newValue = [...value];
                    newValue[index] = null;
                    onChange?.(newValue.filter(Boolean));
                }}
                addItem={() => {
                    onChange?.([
                        ...value,
                        {
                            ruleFormulas: {
                                judgeStrategy: 1,
                                customJudgeLogic: '',
                            },
                        },
                    ]);
                }}
            >
                {value.map((item, index) => {
                    return (
                        <StageItem
                            value={item}
                            index={index}
                            onChange={onDetailChange}
                            associateList={associateList}
                            keyEventList={keyEventList}
                            StageItemId={item.id}
                        ></StageItem>
                    );
                })}
            </UdeskList>
        </React.Fragment>
    );
};

export { StageList };
