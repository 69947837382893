import React, { useContext, useEffect, useState } from 'react';
import Udesk from 'Udesk';
import styled from 'styled-components';
import Filter from './Filter';
import { FilterContext } from '../context';
import { Item } from './Container';
import { getIntelligentPartnerHomePageAdminTaskDetail } from 'src/api/intelligentPartner/homePage/admin/taskDetail/index';
import { getIntelligentPartnerHomePageAdminTaskStatistics } from 'src/api/intelligentPartner/homePage/admin/taskStatistics/index';
import { Divider, Space } from 'udesk-ui';
import SimplePieChart from 'src/pages/coach/task-center/components/SimplePieChart';
import { PracticeSummary } from './PracticeSummary';
import { RankingList } from './RankingList';
import { IntelligentPartnerHomePageAdminTaskStatisticsResponse } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

const ChartsWrapper = styled.div`
    display: flex;
    flex: 1;
    > div {
        flex: 1;
    }
`;

export const AdminDataCards = (props) => {
    const [params] = useContext(FilterContext);

    const [practiceData, setPracticeData] = useState<any>([]);
    const [examinationData, setExaminationData] = useState<any>([]);

    useEffect(() => {
        getIntelligentPartnerHomePageAdminTaskDetail({
            params: params || {},
        } as any).then(({ data }) => {
            if (!data) return;

            const tasks = data.taskDetails ?? [];
            const practise = tasks.find(
                (t) => t.taskType === Udesk.enums.learningTaskType.notStarted.id
            );
            const examination = tasks.find(
                (t) => t.taskType === Udesk.enums.learningTaskType.underWay.id
            );
            setPracticeData(
                practise?.courseDetail?.map((d) => ({
                    ...d,
                    desc: Udesk.enums.lessonType.getName(d.courseType),
                })) ?? []
            );
            setExaminationData(
                examination?.courseDetail?.map((d) => ({
                    ...d,
                    desc: Udesk.enums.lessonType.getName(d.courseType),
                })) ?? []
            );
        });
    }, [params]);

    const [practiceStatData, setPracticeStatData] =
        useState<IntelligentPartnerHomePageAdminTaskStatisticsResponse>();
    const [examinationStatData, setExaminationStatData] =
        useState<IntelligentPartnerHomePageAdminTaskStatisticsResponse>();

    useEffect(() => {
        getIntelligentPartnerHomePageAdminTaskStatistics({
            params: params || {},
        } as any).then(({ data }) => {
            if (!data) return;
            const tasks = data ?? [];
            const practise = tasks.find(
                (t) => t.taskType === Udesk.enums.learningTaskType.notStarted.id
            );
            setPracticeStatData(practise);
            const examination = tasks.find(
                (t) => t.taskType === Udesk.enums.learningTaskType.underWay.id
            );
            setExaminationStatData(examination);
        });
    }, [params]);

    return (
        <>
            <Item widthRatio={1}>
                <Filter />
            </Item>

            <Item widthRatio={3 / 8}>
                <DataCard
                    title={
                        /* 练习任务 */ UdeskLocales['current'].pages.coach.dashboard.components
                            .adminDataCards.practiceTasks
                    }
                    data={practiceData}
                />
            </Item>
            <Item widthRatio={5 / 8}>
                <DataCard
                    title={
                        /* 练习任务数据 */ UdeskLocales['current'].pages.coach.dashboard.components
                            .adminDataCards.exerciseTaskData
                    }
                >
                    <ChartsWrapper>
                        <SimplePieChart
                            id="eTaskExecuteRateChart"
                            loading={false}
                            height={140}
                            title={getRate(
                                practiceStatData?.executeCount || 0,
                                practiceStatData?.totalCount || 1
                            )}
                            subTitle={
                                /* 执行率 */ UdeskLocales['current'].pages.coach.dashboard
                                    .components.adminDataCards.implementationRate
                            }
                            data={[
                                {
                                    name: /* 执行人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards.numberOfExecutors,
                                    value: practiceStatData?.executeCount,
                                },
                                {
                                    name: /* 未执行人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfPeopleNotExecuted,
                                    value:
                                        (practiceStatData?.totalCount || 0) -
                                        (practiceStatData?.executeCount || 0),
                                },
                            ]}
                            textFontSize={16}
                        />
                        <SimplePieChart
                            id="eTaskCompleteRateChart"
                            loading={false}
                            height={140}
                            title={getRate(
                                practiceStatData?.completeCount || 0,
                                practiceStatData?.totalCount || 1
                            )}
                            subTitle={
                                /* 完成率 */ UdeskLocales['current'].pages.coach.dashboard
                                    .components.adminDataCards.completionRate
                            }
                            data={[
                                {
                                    name: /* 完成人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfCompletedPersonnel,
                                    value: practiceStatData?.completeCount,
                                },
                                {
                                    name: /* 未完成人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfUnfinishedPersonnel,
                                    value:
                                        (practiceStatData?.totalCount || 0) -
                                        (practiceStatData?.completeCount || 0),
                                },
                            ]}
                            textFontSize={16}
                        />
                        <SimplePieChart
                            id="eTaskCommitRateChart"
                            loading={false}
                            height={140}
                            title={getRate(
                                practiceStatData?.commitCount || 0,
                                practiceStatData?.totalCount || 1
                            )}
                            subTitle={
                                /* 达标率 */ UdeskLocales['current'].pages.coach.dashboard
                                    .components.adminDataCards.complianceRate
                            }
                            data={[
                                {
                                    name: /* 达标人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfQualifiedPersonnel,
                                    value: practiceStatData?.commitCount,
                                },
                                {
                                    name: /* 未达标人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfNonCompliantIndividuals,
                                    value:
                                        (practiceStatData?.totalCount || 0) -
                                        (practiceStatData?.commitCount || 0),
                                },
                            ]}
                            textFontSize={16}
                        />
                        <SimplePieChart
                            id="eTaskExcellentRateChart"
                            loading={false}
                            height={140}
                            title={getRate(
                                practiceStatData?.excellentCount || 0,
                                practiceStatData?.totalCount || 1
                            )}
                            subTitle={
                                /* 达优率 */ UdeskLocales['current'].pages.coach.dashboard
                                    .components.adminDataCards.excellentRate
                            }
                            data={[
                                {
                                    name: /* 优秀人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfOutstandingIndividuals,
                                    value: practiceStatData?.excellentCount,
                                },
                                {
                                    name: /* 非优秀人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfNonOutstandingIndividuals,
                                    value:
                                        (practiceStatData?.totalCount || 0) -
                                        (practiceStatData?.excellentCount || 0),
                                },
                            ]}
                            textFontSize={16}
                        />
                    </ChartsWrapper>
                </DataCard>
            </Item>

            <Item widthRatio={3 / 8}>
                <DataCard
                    title={
                        /* 考试任务 */ UdeskLocales['current'].pages.coach.dashboard.components
                            .adminDataCards.examTasks
                    }
                    data={examinationData}
                />
            </Item>
            <Item widthRatio={5 / 8}>
                <DataCard
                    title={
                        /* 考试任务数据 */ UdeskLocales['current'].pages.coach.dashboard.components
                            .adminDataCards.examTaskData
                    }
                >
                    <ChartsWrapper>
                        <SimplePieChart
                            id="tTaskExecuteRateChart"
                            loading={false}
                            height={140}
                            title={getRate(
                                examinationStatData?.executeCount || 0,
                                examinationStatData?.totalCount || 1
                            )}
                            subTitle={
                                /* 执行率 */ UdeskLocales['current'].pages.coach.dashboard
                                    .components.adminDataCards.implementationRate
                            }
                            data={[
                                {
                                    name: /* 执行人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards.numberOfExecutors,
                                    value: examinationStatData?.executeCount,
                                },
                                {
                                    name: /* 未执行人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfPeopleNotExecuted,
                                    value:
                                        (examinationStatData?.totalCount || 0) -
                                        (examinationStatData?.executeCount || 0),
                                },
                            ]}
                            textFontSize={16}
                        />
                        <SimplePieChart
                            id="tTaskCompleteRateChart"
                            loading={false}
                            height={140}
                            title={getRate(
                                examinationStatData?.completeCount || 0,
                                examinationStatData?.totalCount || 1
                            )}
                            subTitle={
                                /* 完成率 */ UdeskLocales['current'].pages.coach.dashboard
                                    .components.adminDataCards.completionRate
                            }
                            data={[
                                {
                                    name: /* 完成人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfCompletedPersonnel,
                                    value: examinationStatData?.completeCount,
                                },
                                {
                                    name: /* 未完成人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfUnfinishedPersonnel,
                                    value:
                                        (examinationStatData?.totalCount || 0) -
                                        (examinationStatData?.completeCount || 0),
                                },
                            ]}
                            textFontSize={16}
                        />
                        <SimplePieChart
                            id="tTaskCommitRateChart"
                            loading={false}
                            height={140}
                            title={getRate(
                                examinationStatData?.commitCount || 0,
                                examinationStatData?.totalCount || 1
                            )}
                            subTitle={
                                /* 达标率 */ UdeskLocales['current'].pages.coach.dashboard
                                    .components.adminDataCards.complianceRate
                            }
                            data={[
                                {
                                    name: /* 达标人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfQualifiedPersonnel,
                                    value: examinationStatData?.commitCount,
                                },
                                {
                                    name: /* 未达标人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfNonCompliantIndividuals,
                                    value:
                                        (examinationStatData?.totalCount || 0) -
                                        (examinationStatData?.commitCount || 0),
                                },
                            ]}
                            textFontSize={16}
                        />
                        <SimplePieChart
                            id="tTaskExcellentRateChart"
                            loading={false}
                            height={140}
                            title={getRate(
                                examinationStatData?.excellentCount || 0,
                                examinationStatData?.totalCount || 1
                            )}
                            subTitle={
                                /* 达优率 */ UdeskLocales['current'].pages.coach.dashboard
                                    .components.adminDataCards.excellentRate
                            }
                            data={[
                                {
                                    name: /* 优秀人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfOutstandingIndividuals,
                                    value: examinationStatData?.excellentCount,
                                },
                                {
                                    name: /* 非优秀人数 */ UdeskLocales['current'].pages.coach
                                        .dashboard.components.adminDataCards
                                        .numberOfNonOutstandingIndividuals,
                                    value:
                                        (examinationStatData?.totalCount || 0) -
                                        (examinationStatData?.excellentCount || 0),
                                },
                            ]}
                            textFontSize={16}
                        />
                    </ChartsWrapper>
                </DataCard>
            </Item>
            <Item width="auto">
                <PracticeSummary isAdmin={true} />
            </Item>
            <Item width={600}>
                <RankingList isAdmin={true} history={props.history} />
            </Item>
        </>
    );
};

const getRate = (numerator: number, denominator: number) => {
    if (denominator) {
        return ((numerator / denominator) * 100).toFixed(2) + '%';
    }
    return '0%';
};

const DataCardWrap = styled.div`
    padding: 12px 16px 12px;
    height: 100%;

    .udesk-qa-web-coach-dashboard-admin-data-card-title {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
    }
    .udesk-qa-web-coach-dashboard-admin-data-card-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 50px);

        .udesk-qa-ui-divider-vertical {
            height: 24px;
        }
        .udesk-qa-ui-space {
            width: 100%;
            .udesk-qa-ui-space-item {
                flex: 1;
                .udesk-qa-web-coach-dashboard-admin-data-card-content-item {
                    text-align: center;
                    width: 100%;
                    .udesk-qa-web-coach-dashboard-admin-data-card-content-item-count {
                        font-weight: 500;
                        font-size: 40px;
                        line-height: 48px;
                        color: #1a6eff;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
`;

function DataCard(props) {
    const { title, data = [], children } = props;

    return (
        <DataCardWrap className="udesk-qa-web-coach-dashboard-admin-data-card">
            <div className="udesk-qa-web-coach-dashboard-admin-data-card-title">{title}</div>
            <div className="udesk-qa-web-coach-dashboard-admin-data-card-content">
                {children ? (
                    children
                ) : (
                    <Space split={<Divider type="vertical" />}>
                        {data.map((d) => {
                            return (
                                <div className="udesk-qa-web-coach-dashboard-admin-data-card-content-item">
                                    <div className="udesk-qa-web-coach-dashboard-admin-data-card-content-item-count">
                                        {d.count}
                                    </div>
                                    <div>{d.desc}</div>
                                </div>
                            );
                        })}
                    </Space>
                )}
            </div>
        </DataCardWrap>
    );
}
