import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Udesk from 'Udesk';
import { posterity } from 'src/util/core';
import { FormBuilder, Modal } from 'udesk-ui';
import './index.less';
import UdeskLocales from 'UdeskLocales';

export type RefType = {
    open: (data: any, type: number) => void;
};

export default React.forwardRef<RefType, any>((props, ref) => {
    const { locales, request, treeData: options, getTreeData } = props;
    const [form] = FormBuilder.useForm();
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState<any>({});
    const [title, setTitle] = useState('');
    const [type, setType] = useState(2);
    const [label, setLabel] = useState('');
    const [treeData, setTreeData] = useState<any>([]);

    useEffect(() => {
        const treeData = $.extend(true, [], options);
        if (type === 3) {
            posterity(
                treeData,
                (node, index, list) => {
                    if (node.id === data.id) {
                        list.splice(index, 1);
                    }
                },
                (node) => node.children
            );
        }
        treeData.unshift({
            value: -1,
            label: /* 空 */ UdeskLocales['current'].pages.coach.learningCenter.course.components
                .folderTree.modal.index.empty,
        });

        setTreeData(treeData);
    }, [data?.id, options, type]);

    useImperativeHandle(ref, () => ({
        open: (data, type) => {
            setVisible(true);
            setData(data);
            setType(type);
            if (type === 0) {
                setTitle(
                    `${data.name}${
                        /* 复制到 */ UdeskLocales['current'].pages.coach.learningCenter.course
                            .components.folderTree.modal.index.copyTo
                    }`
                );
                setLabel(
                    /* 分类 */ UdeskLocales['current'].pages.coach.learningCenter.course.components
                        .folderTree.modal.index.classification
                );
            }
            if (type === 1) {
                setTitle(
                    /* 重命名 */ UdeskLocales['current'].pages.coach.learningCenter.course
                        .components.folderTree.modal.index.rename
                );
            }
            if (type === 2) {
                setTitle(
                    /* 新建 */ UdeskLocales['current'].pages.coach.learningCenter.course.components
                        .folderTree.modal.index.newlyBuild
                );
                setLabel(
                    /* 上级分类 */ UdeskLocales['current'].pages.coach.learningCenter.course
                        .components.folderTree.modal.index.categoryParent
                );
            }
            if (type === 3) {
                setTitle(
                    `${data.name}${
                        /* 移动到 */ UdeskLocales['current'].pages.coach.learningCenter.course
                            .components.folderTree.modal.index.moveTo
                    }`
                );
                setLabel(
                    /* 分类 */ UdeskLocales['current'].pages.coach.learningCenter.course.components
                        .folderTree.modal.index.classification
                );
            }
        },
    }));

    const formSubmitHandle = useCallback(
        (values) => {
            let params = {
                ...data,
                ...values,
            };
            delete params.parentIds;

            if (params.parentId === -1) {
                params.parentId = null;
            }

            if ([2].includes(type)) {
                delete params.id;
            }

            let method = params.id ? 'put' : 'post';
            let url = params.id
                ? `/review/intelligentPartner/category/${params.id}`
                : '/review/intelligentPartner/category';

            // 此处代码接口冗余，移动接口和复制接口可以合并为修改和保存接口
            if (type === 0) {
                method = 'put';
                url = '/review/intelligentPartner/category/copy';
            }
            if (type === 3) {
                method = 'put';
                url = `/review/intelligentPartner/category/move/${params.id}`;
                params = {
                    parentId: params.parentId,
                };
            }

            request(url, params, method).then(
                (resp) => {
                    Udesk.ui.notify.success(locales.business.info.saveSuccess);
                    setVisible(false);
                    getTreeData();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg);
                }
            );
        },
        [data, locales.business.info.saveSuccess, request, type, getTreeData]
    );

    const submitHandle = useCallback(() => {
        form.submit();
    }, [form]);

    const cancelHandle = useCallback(() => {
        setVisible(false);
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            ...data,
            parentId: type === 0 ? -1 : data.parentId || -1,
            name: data.name,
        });
    }, [form, data, type]);

    return (
        <Modal visible={visible} onOk={submitHandle} onCancel={cancelHandle} title={title}>
            <FormBuilder
                {...{
                    labelCol: { span: 6 },
                    wrapperCol: { span: 16 },
                }}
                form={form}
                onFinish={formSubmitHandle}
                footerRender={false}
                fields={[
                    {
                        label,
                        type: 'TreeSelect',
                        name: 'parentId',
                        props: {
                            showSearch: true,
                            treeData,
                            treeNodeFilterProp: 'label',
                        },
                    },
                    {
                        label: /* 名称 */ UdeskLocales['current'].pages.coach.learningCenter.course
                            .components.folderTree.modal.index.name,
                        name: 'name',
                        type: 'Input',
                        props: {
                            maxLength: 40,
                            maxCount: 40,
                            showCount: true,
                            placeholder: UdeskLocales['current'].labels.pleaseEnter,
                        },
                    },
                ].filter((item, index) => {
                    if (type !== 2) {
                        if (type === 1) {
                            return index === 1;
                        }
                        return index === 0;
                    }
                    return true;
                })}
            />
        </Modal>
    );
});
