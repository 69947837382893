import React from 'react';
// import Udesk from 'Udesk';

class BusinessRecordsViewComponent extends React.Component {
    //#region Default Props
    static propTypes = {

    };
    static defaultProps = {
        noteInfoExtension: {}
    };
    //#endregion

    state = {

    };
    privates = {

    };
    static computes = {

    };
    actions = {

    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {

    }
    componentDidMount() {
    }
    componentWillUnmount() {

    }
    asyncModel(changedParams) {

    }
    //#endregion
}


export default BusinessRecordsViewComponent;