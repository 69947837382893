import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import { Button, FormBuilder, Icon, Space, Spin, Upload as UdeskUpload } from 'udesk-ui';
import styled from 'styled-components';
import { postReviewIntelligentPartnerNodePptConfigTransformPPTByFlowId } from 'src/api/review/intelligentPartner/node/pptConfig/transformPPT/{flowId}';
import UdeskLocales from 'UdeskLocales';

const UploadWrap = styled.div`
    .udesk-qa-ui-space-item {
        > span {
            display: block;
            .udesk-qa-ui-upload {
                display: block;
            }
        }
    }
    .uploaded-file {
        height: 48px;
        background: #f5f6f8;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        .icon {
            width: 24px;
            height: 24px;
            background: #fb5127;
            border-radius: 2px;
            margin-right: 8px;
            text-align: center;
            font-weight: 600;
            color: #fff;
        }
        &:hover {
            .udesk-qa-ui-btn {
                display: inline-flex;
            }
        }
        .udesk-qa-ui-btn {
            margin-left: auto;
            color: rgba(0, 0, 0, 0.45);
            display: none;
        }
    }
    .udesk-qa-ui-form-item {
        margin-bottom: 0;
        .udesk-qa-ui-form-item-control-input {
            min-height: unset;
        }
    }
`;

const PptImageList = (props) => {
    const { pptFile, flowId } = props;
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!pptFile) return;
        if (Array.isArray(props.value)) return;
        setLoading(true);
        postReviewIntelligentPartnerNodePptConfigTransformPPTByFlowId(pptFile, {
            segments: {
                flowId,
            },
        })
            .then((resp) => {
                props.onChange(resp.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [pptFile, props.value]);
    return (
        <div style={{ textAlign: 'center' }}>
            {/* {Array.isArray(props.value) && props.value.length > 0 && (
                <div
                    style={{
                        background: '#f5f6f8',
                        padding: '8px 8px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        marginTop: 8,
                    }}
                >
                    {props.value.map((img, index) => {
                        return (
                            <img
                                style={{ width: 'calc(50% - 4px)', marginBottom: 8 }}
                                src={img}
                                alt={`第${index + 1}页`}
                            />
                        );
                    })}
                </div>
            )} */}
            {loading && (
                <Spin spinning={loading} tip={/* PPT解析中... */UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawerComponents.pPTParsing} style={{ marginTop: 8 }} size="small" />
            )}
        </div>
    );
};

export const Upload = (props) => {
    const { onChange, value, onUploadData, flowId, resetPptImageList } = props;
    const [pptFile, setPptFile] = useState<undefined | any>(undefined);
    const [loading, setLoading] = useState(false);

    const reset = () => {
        resetPptImageList?.();
        setPptFile(undefined);
        return Promise.resolve();
    };
    const handlerUpload = (file) => {
        setLoading(true);
        reset().then(() => {
            Udesk.app.fileStorage.upload(file, {
                token: new Promise((resolve, reject) => {
                    Udesk.business
                        .getServerAuthorization(Udesk.enums.uploadFileTypes.image.id)
                        .then(
                            function (resp) {
                                resolve(resp);
                            },
                            function (reason) {
                                reject(reason);
                            }
                        );
                }),
                //progressCallback: progressCallback.bind(this),
                successCallback: (file, uploadData) => {
                    Udesk.ui.notify.success(/* 上传成功 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawerComponents.uploadSuccessful);
                    // let fileUrl = `${uploadData.token.host}/${uploadData.key}`;
                    const res = {
                        keyName: uploadData.key,
                        name: uploadData.name,
                        downloadPath: uploadData.key,
                        host: uploadData.token.host,
                    };
                    onUploadData?.(res);
                    setPptFile(res);
                    onChange(res);
                    setLoading(false);
                },
                errorCallback: () => {
                    setLoading(false);
                }
            });
        });
        return false;
    };
    return (
        <UploadWrap>
            <Space direction="vertical" style={{ width: '100%' }} size={12}>
                {!!value && (
                    <div className="uploaded-file">
                        <div className="icon">P</div>
                        <div>{value.name}</div>
                        <Button
                            type="text"
                            icon={<Icon antdIcon={true} type="CloseOutlined" />}
                            size="small"
                            onClick={() => {
                                onChange(undefined);
                                reset();
                            }}
                            loading={loading}
                        ></Button>
                    </div>
                )}
                {!value && (
                    <UdeskUpload
                        accept=".ppt,.pptx,.pdf"
                        showUploadList={false}
                        beforeUpload={handlerUpload}
                    >
                        <Button block={true}>{/* 上传附件 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawerComponents.uploadAttachments}</Button>
                    </UdeskUpload>
                )}
            </Space>
            <FormBuilder.Item
                name="pptImageList"
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (Array.isArray(value) && getFieldValue('pptFile')) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(/* PPT解析中，请稍后 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.configDrawerComponents.pPTParsingPleaseWait));
                        },
                    }),
                ]}
            >
                <PptImageList pptFile={pptFile} flowId={flowId} />
            </FormBuilder.Item>
        </UploadWrap>
    );
};

const PptPreviewWrap = styled.div`
    padding: 8px;
    background: #fafafa;
    width: 100%;
    img {
        width: 100%;
    }
`;

export const PptPreview = (props) => {
    return (
        <PptPreviewWrap>
            <img src={props.value?.[0]} alt="" />
        </PptPreviewWrap>
    );
};
