// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetIntelligentPartnerTaskInfosByIdParam,
    BaseResponseIntelligentPartnerTaskInfoFoundResponse,
    PutIntelligentPartnerTaskInfosByIdParam,
    PutIntelligentPartnerTaskInfosByIdBody,
    BaseResponseVoid,
    DeleteIntelligentPartnerTaskInfosByIdParam,
} from '../../types';

/**
 * 获取单个发布任务详情
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [GET] /intelligentPartnerTaskInfos/{id}
 * @param id
 */
export function getIntelligentPartnerTaskInfosById(
    options: ApiOptions<GetIntelligentPartnerTaskInfosByIdParam, never> & {
        segments: GetIntelligentPartnerTaskInfosByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerTaskInfoFoundResponse> {
    return request<never, GetIntelligentPartnerTaskInfosByIdParam, never>(
        '/intelligentPartnerTaskInfos/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getIntelligentPartnerTaskInfosById' }
    );
}

/**
 * 更新任务发布配置
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [PUT] /intelligentPartnerTaskInfos/{id}
 * @param id
 */
export function putIntelligentPartnerTaskInfosById(
    data: PutIntelligentPartnerTaskInfosByIdBody,
    options: ApiOptions<PutIntelligentPartnerTaskInfosByIdParam, never> & {
        segments: PutIntelligentPartnerTaskInfosByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutIntelligentPartnerTaskInfosByIdBody,
        PutIntelligentPartnerTaskInfosByIdParam,
        never
    >('/intelligentPartnerTaskInfos/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putIntelligentPartnerTaskInfosById',
    });
}

/**
 * 删除任务发布配置
 *
 * @export
 * @tags 智能陪练发布任务接口
 * @link [DELETE] /intelligentPartnerTaskInfos/{id}
 * @param id
 */
export function deleteIntelligentPartnerTaskInfosById(
    options: ApiOptions<DeleteIntelligentPartnerTaskInfosByIdParam, never> & {
        segments: DeleteIntelligentPartnerTaskInfosByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteIntelligentPartnerTaskInfosByIdParam, never>(
        '/intelligentPartnerTaskInfos/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteIntelligentPartnerTaskInfosById',
        }
    );
}

export const meta = [
    { tags: ['智能陪练发布任务接口'], path: '/intelligentPartnerTaskInfos/{id}', method: 'get' },
    { tags: ['智能陪练发布任务接口'], path: '/intelligentPartnerTaskInfos/{id}', method: 'put' },
    { tags: ['智能陪练发布任务接口'], path: '/intelligentPartnerTaskInfos/{id}', method: 'delete' },
];
