import React, { useMemo, useRef, forwardRef, useImperativeHandle, useState, useLayoutEffect } from 'react';
// import { Input } from 'udesk-ui';
import ReactQuill from 'react-quill';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.min.css';
import 'react-quill/dist/quill.core.css';
import { PointData } from 'src/api/types';
import './style.scss';

interface RemarkProps {
    value: string | undefined;
    onChange?: (value: string, text: string, remarkMap: { [key: string]: string }) => void;
    onlyView?: boolean;
    pointsList?: PointData[];
    loading?: boolean;
    callId?: string | number;
}

const Remark: React.FC<RemarkProps> = forwardRef((props, ref) => {
    const { value, onChange, pointsList = [], callId } = props;
    const [disable, setDisable] = useState<boolean>(true);
    
    const textAreaRef = useRef<HTMLDivElement | null>(null);
    // const qlDoms = Array.from(textAreaRef.current?.getElementsByClassName('ql-editor') || []);
    // const qlDom: any = qlDoms[0];

    const quillRef = useRef<any>(null);

    useImperativeHandle(ref, () => {
        let remarkMap = {};
        let text = '';
        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor);
            // const text = unprivilegedEditor?.getText();
            const richTextHtml = unprivilegedEditor?.getHTML();
            const tempDom = document.createElement('div');
            tempDom.innerHTML = richTextHtml;
            const mentionDoms = Array.from(tempDom.getElementsByClassName('mention')) as HTMLElement[];
            mentionDoms.forEach((item) => {
                if (item.dataset && item.dataset.id) {
                    const nextSibling = item.nextSibling as any;
                    remarkMap[item.dataset.id] = (nextSibling?.tagName ? '' : nextSibling?.data ?? '').trim();
                }
            });
            text = Array.from(tempDom.childNodes)
                .map((i: any) => i.innerText)
                .join('\n');
        }
        return {
            remarkMap,
            text,
        };
    });

    const modules = useMemo(() => {
        return {
            mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö\u4e00-\u9fa5]*$/,
                mentionDenotationChars: ['@', '#'],
                // showDenotationChar: false,
                source: function (searchTerm, renderList, mentionChar) {
                    if (mentionChar === '@') {
                        renderList(
                            pointsList
                                .filter((i) => i.name?.includes(searchTerm))
                                .map((i) => ({ id: i.pointId, value: i.name })),
                            searchTerm
                        );
                    }
                },
                onSelect: (item, insertItem) => {
                    insertItem({
                        ...item,
                        value: item.denotationChar === '@' ? item.value : item.value + '#',
                    });
                },
            },
        };
    }, [pointsList]);
    useLayoutEffect(() => {
        setDisable(true);
    }, [callId]);
    return (
        <div className='quality-detail-remark-content-rich-text'>
            <div
                className='udesk-qa-ui-input'
                ref={textAreaRef}
                style={{ height: '100%' }}
                onClick={() => {
                    quillRef.current!.focus();
                }}
            >
                <div onClick={(e) => {
                    setDisable(false);
                    setTimeout(() => {
                        quillRef.current!.focus();
                    });
                    e.stopPropagation();
                }}>
                    <ReactQuill
                        // onBlur={() => {
                        //     setDisable(true);
                        // }}
                        readOnly={disable}
                        // readOnly={loading || onlyView}
                        theme={null as any}
                        value={value || ''}
                        onChange={(value) => {
                            let remarkMap = {};
                            let text = '';
                            if (quillRef.current) {
                                const editor = quillRef.current.getEditor();
                                const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor);
                                // const text = unprivilegedEditor?.getText();
                                const richTextHtml = unprivilegedEditor?.getHTML();
                                const tempDom = document.createElement('div');
                                tempDom.innerHTML = richTextHtml;
                                const mentionDoms = Array.from(
                                    tempDom.getElementsByClassName('mention')
                                ) as HTMLElement[];
                                mentionDoms.forEach((item) => {
                                    if (item.dataset && item.dataset.id) {
                                        const nextSibling = item.nextSibling as any;
                                        remarkMap[item.dataset.id] = (
                                            nextSibling?.tagName ? '' : nextSibling?.data ?? ''
                                        ).trim();
                                    }
                                });
                                text = Array.from(tempDom.childNodes)
                                    .map((i: any) => i.innerText)
                                    .join('\n');
                            }

                            // return {
                            //     remarkMap,
                            //     text,
                            // };
                            onChange?.(value, text, remarkMap);
                        }}
                        // placeholder={placeholder}
                        // defaultValue={value}
                        ref={quillRef}
                        modules={modules}
                    />
                </div>
            </div>
        </div>
    );
});

export default React.memo(Remark);
