import React from 'react';
import InputBaseClass from './input-base-class';
import DateRangePickerComponent from "../date-range-picker/index";
import Udesk from '../../udesk/index';

class DateRangePicker extends InputBaseClass {
    actions = {
        _validate() {
            return true;
        },

        _onInputValueChanged(start, end) {
            if(start != null && end != null) {
                this.actions._onChanged([start, end]);
            }
        }
    }

    static get emberName() {
        return 'udesk/custom-field-inputs/date-range';
    }

    render() {
        let {
            privates: { computes },
        } = this;

        let {
            internalValue,
            mergedAttributes
        } = computes;
        let {
            disabled
        } = this.props;
        if(internalValue == null || internalValue ==="") {
            internalValue = [];
        }
        let start = internalValue[0];
        let end =  internalValue[1];
        return (
            <DateRangePickerComponent start={start} end={end} classNames={mergedAttributes.classNames} disabled={disabled} timePicker={mergedAttributes.timePicker} format={mergedAttributes.format} serverFormat={mergedAttributes.serverFormat} placeholder={mergedAttributes.placeholder} applyAction={this.actions._onInputValueChanged}  opens={mergedAttributes.opens}/>
        );
    }
}

export default Udesk.react.udeskify(DateRangePicker);