// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetOrganizationsQuery,
    BaseResponseListOrganizationFoundResponse,
    PostOrganizationsBody,
    BaseResponseOrganizationFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags organization-controller
 * @link [GET] /organizations
 */
export function getOrganizations(): Promise<BaseResponseListOrganizationFoundResponse>;
export function getOrganizations(
    options: ApiOptions<never, GetOrganizationsQuery>
): Promise<BaseResponseListOrganizationFoundResponse>;
export function getOrganizations(
    options?: ApiOptions<never, GetOrganizationsQuery>
): Promise<BaseResponseListOrganizationFoundResponse> {
    return request<never, never, GetOrganizationsQuery>('/organizations', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getOrganizations',
    });
}

/**
 * save
 *
 * @export
 * @tags organization-controller
 * @link [POST] /organizations
 */
export function postOrganizations(
    data: PostOrganizationsBody
): Promise<BaseResponseOrganizationFoundResponse>;
export function postOrganizations(
    data: PostOrganizationsBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseOrganizationFoundResponse>;
export function postOrganizations(
    data: PostOrganizationsBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseOrganizationFoundResponse> {
    return request<PostOrganizationsBody, never, never>('/organizations', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postOrganizations',
    });
}

export const meta = [
    { tags: ['organization-controller'], path: '/organizations', method: 'get' },
    { tags: ['organization-controller'], path: '/organizations', method: 'post' },
];
