import React, { useState, useEffect } from 'react';
import { getCallKeywordInfos } from 'src/api/callKeywordInfos';
import { CallKeywordInfoFoundResponse } from 'src/api/types';
import { Space, Empty } from 'udesk-ui';
// import Locales from 'UdeskLocales';
import styled from 'styled-components';

const ItemWrap = styled.div`
    margin: 0 8px;
    border-bottom: ${(props) => (props.isLast ? 'unset' : '1px solid rgba(0, 0, 0, 0.1)')};
    padding: 8px;
    .udesk-qa-web-keywords-component-item-top {
        margin-bottom: 4px;
        .udesk-qa-web-keywords-component-item-top-keyword-time {
            color: rgba(0, 0, 0, 0.45);
        }
    }
    .udesk-qa-web-keywords-component-item-bottom {
        color: #1b6dff;
    }
`;

type ItemProps = CallKeywordInfoFoundResponse & {
    isLast?: boolean;
};

const Item: React.FC<ItemProps> = ({ keywordName, keywordSourceName, keywordTime, isLast }) => {
    return (
        <ItemWrap isLast={isLast}>
            <div className="udesk-qa-web-keywords-component-item-top">
                <Space>
                    <div>{keywordSourceName ?? '-'}</div>
                    <div className="udesk-qa-web-keywords-component-item-top-keyword-time">
                        {keywordTime ?? '-'}
                    </div>
                </Space>
            </div>
            <div className="udesk-qa-web-keywords-component-item-bottom">{keywordName ?? '-'}</div>
        </ItemWrap>
    );
};

const KeywordsWrap = styled.div`
    height: 100%;
    overflow: auto;
`;

const Keywords: React.FC<any> = (props) => {
    // const locales = Locales['current'];

    const { callId } = props;

    const [callKeywordInfos, setCallKeywordInfos] = useState<CallKeywordInfoFoundResponse[]>([]);

    useEffect(() => {
        getCallKeywordInfos({
            params: {
                callId,
            },
        }).then((resp) => {
            setCallKeywordInfos(resp.data ?? []);
        });
    }, []);

    return (
        <KeywordsWrap className="udesk-qa-web-keywords-component">
            {callKeywordInfos.length > 0 ? (
                callKeywordInfos.map((callKeyword, index) => {
                    return <Item {...callKeyword} isLast={index === callKeywordInfos.length - 1} />;
                })
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </KeywordsWrap>
    );
};

export { Keywords };
