import React from 'react';
import Udesk from 'Udesk';
import Template from './template';

class Component extends React.Component{
    render() {
        return <Template {...this.props}/>;
    }
}

export default Udesk.react.udeskify(Component);