export default {
  lang: {
    export: 'Exportieren',
    successfully: 'Die Export aufgabe wurde erfolgreich erstellt',
    pleaseJumpTo: 'Bitte springen Sie zu',
    uploadAndDownloadCenter: 'Upload-Download-Center',
    check: 'Anzeigen',
    exportAll: 'Bestätigen Sie den Export aller Inhalte?',
    total: 'Insgesamt',
    confirm: 'Daten einträge, bestätigen Sie den Export?',
    error:
      'Die Anzahl der exportierten Daten ist 0. Bitte ändern Sie die Filter bedingungen und versuchen Sie es erneut',
  },
};
