import React from 'react';
import { FormBuilder, Button as UdeskButton } from 'udesk-ui';
import LoginRoute from './route';
import { Layout } from './components/Layout';
import VirtualLogin from 'src/components/pages/components/VirtualLogin';
import UdeskLocales from 'UdeskLocales';

const Button = (props) => {
    return <UdeskButton {...props} htmlType="submit"></UdeskButton>;
};

export default class LoginTemplate extends LoginRoute {
    render() {
        const { state, privates, locales } = this;

        return (
            <Layout
                desc={
                    /* 欢迎回来！请输入您的详细信息 */ UdeskLocales['current'].pages.login.template
                        .welcomeBackPleaseEnterYourDetails
                }
                isIpForbidden={privates.isIpForbidden}
                onAfterClose={this.actions.onAfterClose}
            >
                <FormBuilder
                    customComponents={{ Button }}
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    ref={privates.formInstance}
                    onFinish={this.actions.loginV2}
                    fields={[
                        {
                            label: /* 邮箱 */ locales.pages.login.template.mailbox,
                            type: 'Input',
                            name: 'email',
                            props: {
                                size: 'large',
                                placeholder: locales.labels.pleaseEnter,
                            },
                        },
                        {
                            label: /* 密码 */ locales.pages.login.template.password,
                            type: 'Password',
                            name: 'password',
                            validateStatus: state.errorMsg ? 'error' : 'validating',
                            help: state.errorMsg || undefined,
                            props: {
                                size: 'large',
                            },
                        },
                        {
                            type: 'Button',
                            props: {
                                children: /* 登录 */ locales.pages.login.template.signIn,
                                block: true,
                                size: 'large',
                                type: 'primary',
                                loading: state.loading,
                                style: {
                                    marginTop: 24,
                                },
                            },
                        },
                    ]}
                    footerRender={false}
                />

                <VirtualLogin
                    visible={privates.virtualLoginModal}
                    relationCompanyList={privates.relationCompanyList}
                    onCancel={() => this.actions.onVirtualLoginModalClose()}
                />
            </Layout>
        );
    }
}
