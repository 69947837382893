import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
import { Divider, Space, Button, Skeleton, Select, Dropdown, Menu, Icon, Empty } from 'udesk-ui';
import TaskCard from 'Component/pages/components/task-card';
import './style.scss';
import { getInspectionTasksCollectTypeByCollect } from 'src/api/inspectionTasks/CollectType/{collect}';
import { InspectionTaskFoundResponse } from 'src/api/types';
import NumericalCard from './components/numerical-card';
import TableCard from './components/table-card';
import CheckRateComponent from './components/check-rate';
import { postInspectionHomePageQueryOverView } from 'src/api/inspectionHomePage/queryOverView';
import moment from 'moment';
import { postInspectionHomePageQueryAgentTop } from 'src/api/inspectionHomePage/queryAgentTop';
import { postInspectionHomePageQueryAgentDown } from 'src/api/inspectionHomePage/queryAgentDown';
import { getInspectionHomePageQueryTaskList } from 'src/api/inspectionHomePage/queryTaskList';
import { postInspectionHomePageQueryCheckRate } from 'src/api/inspectionHomePage/queryCheckRate';

const BalanceCard = (props) => {
    const { locales } = props;
    return (
        <div className='balance-card'>
            <div className='balance-card-title'>{props.title}</div>
            <div className='balance-card-value' style={{ color: props.isWarning ? '#fb5127' : '#30C73F' }}>
                {typeof props.value === 'number'
                    ? Number.isNaN(props.value)
                        ? '-'
                        : `${props.value > 9999 ? (props.value / 10000).toFixed(1) : props.value}`
                    : props.value}
                {typeof props.value === 'number' && !Number.isNaN(props.value)
                    ? `${
                        props.value > 9999
                            ? locales.components.pages.billingCenter.dashboard.tenThousandHour
                            : locales.components.pages.billingCenter.dashboard.hour
                    }`
                    : ''}
            </div>
        </div>
    );
};

const LayoutCard: React.FC<{ title: string; loading?: boolean; fallback?: any }> = (props) => {
    const { title, loading, fallback } = props;
    return (
        <div className='dashboard-page-card' style={{ marginTop: 8, flexDirection: 'column' }}>
            <div className='dashboard-page-card-title'>{title}</div>
            {loading ? fallback : props.children}
        </div>
    );
};
const staticParams = {
    task_id: '-1',
    // point_id: '-1',
    inspect_data_source: '-1',
    permission: 'all',
};
const iconList = [
    'icon_zhijianzongshu',
    'icon_zhijiankefuzongshu',
    'icon_zhijianpingjunfen',
    'icon_weiguizongcishu',
    'icon_heguizongcishu',
    'icon_rengongchoujianzongshu',
    'icon_heguizongcishu',
    'icon_fuhezongshu',
];
const tipList = ['total', 'agentTotal', 'avg', 'violation', 'compliance', 'check', 'appeal', 'review'];
const Template = React.memo((props: any) => {
    const { accountCharge = {} } = props;
    const locales = Locales['current'];
    const { company, user } = Udesk.data.init;
    const [tasks, setTasks] = useState<InspectionTaskFoundResponse[]>([]);
    const [tasksCollectLoading, setTasksCollectLoading] = useState<boolean>(true);
    const [overviewData, setOverviewData] = useState<any[]>([]);
    const [overviewDataLoading, setOverviewDataLoading] = useState<boolean>(true);
    const [taskOptions, setTaskOptions] = useState<any>([]);
    const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
    const [size, setSize] = useState(5);

    const onSizeChange = ({ item, key, keyPath, domEvent }) => {
        setSize(key * 1);
    };

    const menu = (
        <Menu onClick={onSizeChange} selectedKeys={[`${size}`]}>
            <Menu.Item key={5}>Top 5</Menu.Item>
            <Menu.Item key={10}>Top 10</Menu.Item>
        </Menu>
    );
    useEffect(() => {
        getInspectionHomePageQueryTaskList().then((resp) => {
            setTaskOptions(
                resp.data?.map((item) => ({
                    label: item.qa_inspection__task_name,
                    value: item.qa_inspection__task_id,
                })) || []
            );
            setSelectedTaskId(resp.data?.[0]?.qa_inspection__task_id || null);
        });
        getInspectionTasksCollectTypeByCollect({
            segments: {
                collect: 1,
            },
        })
            .then((resp) => {
                setTasks(resp?.data ?? []);
            })
            .finally(() => {
                setTasksCollectLoading(false);
            });
        postInspectionHomePageQueryOverView({
            // task_id: '-1',
            beginOfWeek: '1',
            endOfWeek: '7',
            ...staticParams,
            agent_no: "'-1'",
            statAt: [
                moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'),
                moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59'),
            ],
        })
            .then((resp) => {
                let ret: any[] = [];
                // let subIndex = 0;
                resp.data?.data &&
                    resp.data.data.header &&
                    resp.data.data.header.indicators &&
                    resp.data.data.header.indicators.forEach((item, index) => {
                        if (item.dataType === 'percent') {
                            if (ret.length - 1 >= 0) {
                                ret[ret.length - 1].percent = !(resp.data?.data?.rows || resp.data?.data?.rows?.length)
                                    ? ''
                                    : resp.data?.data?.rows?.[0]?.[item.fieldKey!] ?? '';
                                ret[ret.length - 1].percentName = item.fieldTitle;
                                ret[ret.length - 1].subTip =
                                    locales.pages.dashboard.analysisOverview.subTips[tipList[ret.length - 1]];
                                // subIndex++;
                            }
                        } else {
                            ret.push({
                                icon: iconList[ret.length],
                                name: item.fieldTitle,
                                tip: locales.pages.dashboard.analysisOverview.tips[tipList[ret.length]],
                                data: !(resp.data?.data?.rows || resp.data?.data?.rows?.length)
                                    ? ''
                                    : resp.data?.data?.rows[0]?.[item.fieldKey!] ?? '',
                            });
                        }
                    });
                setOverviewData(ret);
            })
            .finally(() => {
                setOverviewDataLoading(false);
            });
    }, []);
    return (
        <Page
            pageBodyClassName='dashboard-page-index'
            title={locales.pages.dashboard.title}
            // padding={true}
            backGroundGhost={true}
        >
            <div className='dashboard-page-card space-between header'>
                <Space>
                    <div className='logo'>
                        <img src='/static/images/qa-logo.png' alt='' />
                    </div>
                    <div>
                        <div className='title'>{`${locales.pages.dashboard.hi}${user.realname}`}</div>
                        <Space style={{ marginTop: 11 }}>
                            <div className='company'>{`@${company.sysName}`}</div>
                            <div className='edition'>{locales.pages.dashboard.standardEdition}</div>
                        </Space>
                    </div>
                </Space>
                <div className='billing-module'>
                    <div>
                        {/* <Space>
                            <div className='billing-module-title'>
                                {locales.components.pages.billingCenter.dashboard.remainingQuota}
                            </div>
                            {props.showBillingWarning && (
                                <div className='billing-module-warning'>
                                    {locales.components.pages.billingCenter.dashboard.warning}
                                </div>
                            )}
                        </Space> */}
                        <Space split={<Divider type='vertical' />} style={{ marginRight: 8 }}>
                            <BalanceCard
                                title={locales.pages.dashboard.call}
                                value={accountCharge.balance}
                                isWarning={accountCharge.callWarning}
                                locales={locales}
                            />
                            <BalanceCard
                                title={locales.pages.dashboard.text}
                                value={accountCharge.deadline}
                                isWarning={accountCharge.ImWarning}
                                locales={locales}
                            />
                        </Space>
                    </div>
                </div>
            </div>
            {Udesk.data.init.company.id === 309 || (
                <React.Fragment>
                    <LayoutCard
                        title={locales.pages.dashboard.collectionTasks}
                        loading={tasksCollectLoading}
                        fallback={
                            <div className='task-card-box task-card-box-fake'>
                                <div className='task-card task-card-fake'>
                                    <Skeleton paragraph={{ rows: 1 }} />
                                    <Space style={{ marginTop: 16 }}>
                                        <Skeleton.Button size='small' />
                                        <Skeleton.Button size='small' />
                                    </Space>
                                </div>
                            </div>
                        }
                    >
                        <div className='udesk-wechat-qa-dashboard'>
                            {tasks.length ? (
                                tasks.map((task) => (
                                    <TaskCard
                                        key={task.id}
                                        task={task}
                                        simple={true}
                                        isCollection={task.taskType === 2}
                                        isWechatQa={
                                            task.inspectDataSource === Udesk.enums.inspectDataSources.wechatDialogue.id
                                        }
                                        onCollectTypeChange={() => {
                                            // setTasksCollectLoading(true);
                                            getInspectionTasksCollectTypeByCollect({
                                                segments: {
                                                    collect: 1,
                                                },
                                            }).then((resp) => {
                                                setTasks(resp?.data ?? []);
                                            });
                                            // .finally(() => {
                                            //     setTasksCollectLoading(false);
                                            // });
                                        }}
                                        {...props}
                                    />
                                ))
                            ) : (
                                <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                                    {locales.pages.dashboard.noCollection}
                                    <Button
                                        type='link'
                                        size='small'
                                        onClick={() => {
                                            let routeOptions = {
                                                history: props.history,
                                                routeName: 'tasksIndex',
                                                state: {
                                                    taskType:
                                                        props.sdkOptions.props?.task?.taskType ===
                                                        Udesk.enums.taskType.informationCollection.id
                                                            ? Udesk.enums.taskType.informationCollection.id
                                                            : Udesk.enums.taskType.qualityCheckTasks.id,
                                                },
                                            };
                                            Udesk.ui.routing.transitionTo(routeOptions);
                                        }}
                                    >
                                        {locales.pages.dashboard.goToCollecte}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </LayoutCard>
                    <LayoutCard
                        title={locales.pages.dashboard.yesterdayOverView}
                        loading={overviewDataLoading}
                        fallback={<Skeleton />}
                    >
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {overviewData.length ? (
                                overviewData.map((item) => <NumericalCard {...item} />)
                            ) : (
                                <Empty
                                    style={{ margin: '0 auto', minHeight: 136 }}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            )}
                        </div>
                    </LayoutCard>
                    <LayoutCard
                        title={locales.pages.dashboard.yesterdayAgent}
                        loading={!selectedTaskId}
                        fallback={
                            <Space>
                                <Skeleton />
                                <Skeleton />
                            </Space>
                        }
                    >
                        <Space style={{ marginBottom: 16 }}>
                            <Select
                                options={taskOptions}
                                style={{ width: 250 }}
                                value={selectedTaskId || undefined}
                                onChange={(value) => setSelectedTaskId(value)}
                                showSearch
                                // optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    return option?.label?.includes?.(input);
                                }}
                            />
                            <Dropdown overlay={menu}>
                                <Button type='link' onClick={(e) => e.preventDefault()}>
                                    {`TOP ${size}`}
                                    <Icon antdIcon={true} type='DownOutlined' />
                                </Button>
                            </Dropdown>
                        </Space>
                        <div style={{ display: 'flex' }}>
                            <TableCard
                                request={postInspectionHomePageQueryAgentDown}
                                limit={size}
                                // time={time}
                                color={'#FB5127'}
                                // transToPoint={transToPoint}
                                // agentGroupIds={agentGroupIdsSearch}
                                taskId={selectedTaskId}
                            />
                            <TableCard
                                style={{ marginLeft: 16 }}
                                request={postInspectionHomePageQueryAgentTop}
                                limit={size}
                                // time={time}
                                color={'#1A6EFF'}
                                // transToPoint={transToPoint}
                                // agentGroupIds={agentGroupIdsSearch}
                                taskId={selectedTaskId}
                            />
                        </div>
                    </LayoutCard>
                    <LayoutCard
                        title={locales.pages.dashboard.yesterdayInspWork}
                        loading={!selectedTaskId}
                        fallback={
                            <Space>
                                <Skeleton />
                                <Skeleton />
                            </Space>
                        }
                    >
                        <CheckRateComponent request={postInspectionHomePageQueryCheckRate} />
                    </LayoutCard>
                </React.Fragment>
            )}
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
