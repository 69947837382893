import { useCallback, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { SortEnd, arrayMove } from 'react-sortable-hoc';
import { postReviewShowFields } from 'src/api/review/showFields';
import { getReviewShowFieldsByBusinessType } from 'src/api/review/showFields/{businessType}';
import { TableShowFieldsFoundResponse } from 'src/api/types';
import isNil from 'lodash-es/isNil';

export const BusinessType = {
    /** 销售赋能的客户中心列表 */
    SALES_CLIENT_CENTER_LIST: 1,
};

export const FieldDisplay = {
    SHOW: 1,
    HIDDEN: 0,
};

type UseListControlProps = {
    businessType?: number;
};

export const useListControl = (props: UseListControlProps) => {
    const { businessType } = props;

    const [fields, setFields] = useState<TableShowFieldsFoundResponse[]>();

    const displayFields = useMemo(() => {
        return fields?.filter((c) => c.isShow === FieldDisplay.SHOW);
    }, [fields]);

    useRequest(
        () => getReviewShowFieldsByBusinessType({ segments: { businessType: businessType! } }),
        {
            ready: !isNil(businessType),
            onSuccess: (res) => {
                setFields(res.data);
            },
        }
    );

    const { run: postColumnsShow } = useRequest(postReviewShowFields, {
        manual: true,
        ready: !isNil(businessType),
    });

    const onShowChange = useCallback(
        (fieldCode: string, checked: boolean) => {
            const fds =
                fields?.map((f) => {
                    if (f.fieldCode === fieldCode) {
                        return { ...f, isShow: checked ? FieldDisplay.SHOW : FieldDisplay.HIDDEN };
                    } else {
                        return f;
                    }
                }) || [];
            setFields(fds);
            postColumnsShow(fds, {});
        },
        [fields, postColumnsShow]
    );

    const onSortEnd = useCallback(
        (sort: SortEnd) => {
            const { oldIndex, newIndex } = sort;
            const fds = arrayMove(fields || [], oldIndex, newIndex);
            setFields(fds);
            postColumnsShow(fds, {});
        },
        [fields, postColumnsShow]
    );

    return {
        /** 显示的列字段 */
        displayFields,

        /** 所有字段 */
        fields,
        /** 显隐改变的回调 */
        onShowChange,
        /** 排序改变的回调 */
        onSortEnd,
    };
};
