export default {
  lang: {
    num: '섹션',
    total: '기사/합계',
    strip: '기사',
    more: '더 보기',
    chosen: '선정',
    cancel: '당선자',
    bulkOperations: '배치 작업',
    import: '수입',
  },
};
