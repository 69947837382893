// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { GetReviewCallSummaryCreateQuery, BaseResponseVoid } from '../../../../types';

/**
 * 手动触发获取小结
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/summary/create
 */
export function getReviewCallSummaryCreate(
    options: ApiOptions<never, GetReviewCallSummaryCreateQuery> & {
        params: GetReviewCallSummaryCreateQuery;
    }
): Promise<BaseResponseVoid> {
    return request<never, never, GetReviewCallSummaryCreateQuery>('/review/call/summary/create', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallSummaryCreate',
    });
}

export const meta = [{ tags: ['通话记录'], path: '/review/call/summary/create', method: 'get' }];
