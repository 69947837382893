import React from 'react';
import { Pagination } from 'udesk-ui';
import './style.scss';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';

const DEFAULT_PAGENUMBER = 1;
const DEFAULT_PAGESIZE_OPTIONS = ['5', '10', '20', '50', '100'];
const DEFAULT_PAGESIZE = 20;
// interface propsValue {
//     onChange: () => void;
// }
const UdeskPagination = (props) => {
    const {
        current,
        pageSize,
        pageSizeOptions,
        showTotal: propsShowTotal,
        total,
        onChange,
        overSizeHidden,
        onShowSizeChange,
        notResetCurrentPageNumber,
        showSizeChanger = true,
        ...args
    } = props;
    const showTotal = (total, range) => {
        let pageNum = formatNumber(Math.ceil(props.total / props.pageSize) || 1);
        // return `共${total}条，${total/pageSize}页`;
        let totalString = formatNumber(props.total);
        return Udesk.utils.string.format(Locales.current.components.common.pagination.page, totalString, pageNum);
    };
    const onShowSizeChangeExtra = (current, pageSize) => {
        let currentPageNumber = current;
        //pageSize变化时 默认触发当前页码回到首页
        if (!notResetCurrentPageNumber) {
            currentPageNumber = DEFAULT_PAGENUMBER;
        }
        onShowSizeChange && onShowSizeChange(currentPageNumber, pageSize);
    };
    return (
        <div className='udesk-qa-customize-pagination'>
            <Pagination
                {...args}
                showSizeChanger={showSizeChanger}
                current={current || DEFAULT_PAGENUMBER}
                pageSize={pageSize || DEFAULT_PAGESIZE}
                pageSizeOptions={pageSizeOptions || DEFAULT_PAGESIZE_OPTIONS}
                showTotal={propsShowTotal || showTotal}
                onShowSizeChange={onShowSizeChangeExtra}
                total={total > 10000 ? 10000 : total}
                onChange={onChange}
                // itemRender={itemRender}
            />
            <If condition={overSizeHidden && total > 10000}>
                <div className='quality-inspection-list-index-page-pagination'>{Locales.current.business.notifyMessage.pageOverFlow}</div>
            </If>
        </div>
    );
};

export default UdeskPagination;

const formatNumber = (num) => {
    let str = `${num}`;
    let res = str.replace(/\B(?=(\d{3})+\b)/g, ',');
    return res;
};