import Udesk from 'Udesk';
import React from 'react';
import './style.scss';
import SimilarDiscoverIndexComponent from './component';

export default class SimilarDiscoverIndexTemplate extends SimilarDiscoverIndexComponent {
    render() {

        let { inputValue, similarWords, copyFlag, searchFlag } = this.privates;
        let {
            actions,
            locales
        } = this;
        return (
            <React.Fragment>
                <div className='smart-tools-sider-bar-index-page-drawer-body'>
                    <div className='smart-tools-sider-bar-index-page-drawer-body-search-bar'>
                        <input
                            type="text"
                            className='smart-tools-sider-bar-index-page-drawer-body-input'
                            value={inputValue}
                            onChange={actions.inputValueChangeHandle}
                            onKeyPress={actions.keyupadditem}
                            onBlur={actions.inputValueFormatter}
                            placeholder={locales.components.pages.smartTools.similarDiscover.placeholder}
                        />
                        <span
                            className="udesk-qa-react-web-iconfont smart-tools-sider-bar-index-page-drawer-body-input-search"
                            onClick={actions.search}
                        >
                            &#xe60b;
                        </span>
                    </div>
                    <div className='smart-tools-sider-bar-index-page-drawer-body-similar-discover-content'>
                        {
                            similarWords.map((similarWord, index) => {
                                return(
                                    <div
                                        className='smart-tools-sider-bar-index-page-drawer-body-similar-discover-content-item'
                                        key={Udesk.utils.string.uuid()}
                                        onClick={actions.wordClicked.params(similarWord.word, index)}
                                        style={{backgroundColor:copyFlag[index] === true ? 'rgba(27,109,255,1)':'rgba(232,244,255,1)',
                                            color:copyFlag[index] === true ? '#fff':'rgba(0,0,0,0.65)'
                                        }}
                                    >
                                        {copyFlag[index] === false? similarWord.word:locales.components.pages.smartTools.similarDiscover.copyed}
                                        <span
                                            style={{display:copyFlag[index] === false?null:'none'}}
                                            onClick={actions.clickCopy.params(similarWord.word, index)}
                                            className="udesk-qa-react-web-iconfont smart-tools-sider-bar-index-page-drawer-body-similar-discover-content-item-icon"
                                        >
                                            &#xe736;
                                        </span>
                                    </div>
                                );
                            })
                        }
                        <div style={{display: !similarWords.length && searchFlag? 'block': 'none'}}>
                            {locales.components.pages.smartTools.similarDiscover.none}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}