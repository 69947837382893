import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { inspect, isDefined, isNull } from 'src/util/core';
import {
    Button,
    Space,
    FormBuilder,
    InputNumber,
    Form,
    Select,
    Icon,
    Checkbox,
} from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';

const ConceptWordSelect = React.memo<any>((props) => {
    const {value = [], onChange, width = 150, options, ...others} = props;

    return (
        <Select 
            placeholder={UdeskLocales['current'].components.operatorListGather.conceptWordholder}
            value={value} 
            onChange={onChange} 
            style={{ width: width }} 
            options={options} {...others}/>
    );
});

const ViolationConfigList = (props) => {
    const options = useMemo(() => {
        const map = props.value?.reduce((prev, c) => {
            prev[c.conceptWordsId] = true;
            return prev;
        }, {}) ?? {};
        return props.conceptWords.map(i => ({
            ...i,
            disabled: map[i.value]
        }));
    }, [props.value, props.conceptWords]);

    return (
        <Form.List name="wordsViolationConfigList">
            {(fields, { remove, add, move }) => {
                return (
                    <>
                        {fields.map((field, index) => (
                            <Space key={index} style={{ marginBottom: 8 }}>
                                <Form.Item
                                    {...field}
                                    name={[field.name, 'conceptWordsId']}
                                    fieldKey={[field.fieldKey, 'conceptWordsId']}
                                >
                                    <ConceptWordSelect options={options}/>
                                </Form.Item>{/* 出现 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.appear}<Form.Item
                                    {...field}
                                    name={[field.name, 'appear']}
                                    fieldKey={[field.fieldKey, 'appear']}
                                >
                                    <InputNumber disabled />
                                </Form.Item>{/* 次，减 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.subtract}<Form.Item
                                    {...field}
                                    name={[field.name, 'deduction']}
                                    fieldKey={[field.fieldKey, 'deduction']}
                                >
                                    <InputNumber />
                                </Form.Item>{/* 分 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.branch}<Button
                                    type="text"
                                    size="small"
                                    danger
                                    onClick={() => remove(index)}
                                >
                                    <Icon type="DeleteOutlined" antdIcon={true} />
                                </Button>
                            </Space>
                        ))}
                        <div>
                            <Button
                                onClick={() => add({ appear: 1, deduction: 1 })}
                                type="link"
                                size="small"
                            >{/* 增加规则 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.addRules}</Button>
                        </div>
                    </>
                );
            }}
        </Form.List>
    );
};
const EmotionConfigList = (props) => {
    return (
        <Form.List name="emotionConfigList">
            {(fields, { remove, add, move }) => {

                return (
                    <>
                        {fields.map((field, index) => (
                            <Space>
                                <div style={{ color: 'rgba(0, 0, 0, .45)' }}>{/* (包括负面、生气、抱怨) */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.includingNegativeAngryComplaining}</div>
                                <div>{/* 出现 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.appear}</div>
                                <Form.Item name={[field.name, 'appear']}>
                                    <InputNumber disabled />
                                </Form.Item>
                                <div>{/* 次，减 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.subtract}</div>
                                <Form.Item name={[field.name, 'deduction']}>
                                    <InputNumber />
                                </Form.Item>
                                <div>{/* 分 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.branch}</div>
                            </Space>
                        ))}
                    </>
                );
            }}
        </Form.List>
    );
};

const RealTimeTip = (props) => {
    return (
        <React.Fragment>
            <FormBuilder.Item name="enableScorePointDisplay" valuePropName="checked">
                <Checkbox>{/* 得分要点显示 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.scorePointsDisplay}</Checkbox>
            </FormBuilder.Item>
            <FormBuilder.Item name="enableSpeedWarn" valuePropName="checked">
                <Checkbox>{/* 语速过快/过慢提醒 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.reminderOfSpeakingTooFasttooSlow}</Checkbox>
            </FormBuilder.Item>
            <FormBuilder.Item name="enableWordsViolationWarn" valuePropName="checked">
                <Checkbox>{/* 话术违规提醒 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.scriptViolationReminder}</Checkbox>
            </FormBuilder.Item>
            <FormBuilder.Item name="enableEmotionDisplay" valuePropName="checked">
                <Checkbox>{/* 情绪提醒 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.emotionalReminder}</Checkbox>
            </FormBuilder.Item>
        </React.Fragment>
    );
};
// IntelligentPartnerDialogFlowGlobalConfigCreateRequest

export const initDefaultValues = (values) => {
    Reflect.ownKeys(initValue).forEach((key) => {
        const value = Reflect.get(values, key);
        if (isNull(value)) {
            Reflect.set(values, key, Reflect.get(initValue, key));
        }
    });
    return values;
};

const initValue = {
    /** 情绪配置 负面情绪: -1   正面情绪: 1 */
    emotionConfigList: [{ appear: 1, deduction: 1}],
    /** 是否开启情绪检测 0:关闭 1:开启 */
    enableEmotionDetection: 0,
    /** 前端提示-开启得分要点显示 */
    enableScorePointDisplay: 0,
    /** 是否开启语速检测 0:关闭 1:开启 */
    enableSpeedDetection: 0,
    /** 前端提示-开启语速过快或者过慢提醒 */
    enableSpeedWarn: 0,
    /** 是否开启话术违规 0:关闭 1:开启 */
    enableWordsViolationDetection: 0,
    /** 前端提示-开启话术违规提醒 */
    enableWordsViolationWarn: 0,
    /** 忽略小于句子数量 */
    ignoreLessSentenceNum: 4,
    /** 情绪正常得分 */
    normalEmotionScore: 0,
    /** 语速正常得分 */
    normalSpeedScore: 0,

    speakTooFastConfig: {
        appear: 1,
        deduction: 1,
        gt: 250,
    },

    speakTooSlowlyConfig: {
        appear: 1,
        deduction: 1,
        lt: 80,
    },
    /** 话术违规配置 */
    wordsViolationConfigList: [],
};

const ENABLE_SPEED_DETECTION_EFFECT = [
    'normalSpeedScore',
    ['speakTooFastConfig', 'appear'],
    ['speakTooSlowlyConfig', 'appear'],
    'ignoreLessSentenceNum', 'enableModalParticleTooMuchDetection'
];
const ENABLE_EMOTION_DETECTION_EFFECT = ['normalEmotionScore', 'emotionConfigList'];
const ENABLE_WORDS_VIOLATION_DETECTION_EFFECT = ['wordsViolationConfigList'];
const ENABLE_MODAL_PARTICLE_TOO_MUCH_EFFECT = [['modalParticleTooMuchConfig', 'appear']];

export default React.memo((props: any) => {
    const { data, form, submit, change, conceptWords, layout } = props;
    const [, setEnableSpeedDetection] = useState(false);
    const [, setEnableModalParticleTooMuchDetection] = useState(false);

    const onValuesChange = useCallback((changedValues, ...args) => {
        isDefined(changedValues.enableSpeedDetection, () => {
            setEnableSpeedDetection(!!changedValues.enableSpeedDetection);
            inspect(!changedValues.enableSpeedDetection, () => {
                setEnableModalParticleTooMuchDetection(false);
                form.setFieldsValue({
                    'enableModalParticleTooMuchDetection': false
                });
            });
        });
        isDefined(changedValues.enableModalParticleTooMuchDetection, () => {
            setEnableModalParticleTooMuchDetection(!!changedValues.enableModalParticleTooMuchDetection);
        });
        change(changedValues, ...args);
    }, [
        change, 
        form
    ]);

    useEffect(() => {
        isDefined(data, () => {
            setEnableSpeedDetection(!!data.enableSpeedDetection);
            setEnableModalParticleTooMuchDetection(!!data.enableModalParticleTooMuchDetection);
        });
    }, [data]);
    
    return (
        <FormBuilder
            form={form}
            {...layout}
            initialValues={initValue}
            customComponents={{ ViolationConfigList, RealTimeTip, EmotionConfigList }}
            footerRender={false}
            onFinish={submit}
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: 'enableSpeedDetection',
                    type: 'Switch',
                    label: /* 表达检测 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.expressionDetection,
                    effect: {
                        visible: {
                            true: ENABLE_SPEED_DETECTION_EFFECT,
                            1: ENABLE_SPEED_DETECTION_EFFECT,
                        },
                    },
                },
                {
                    name: 'normalSpeedScore',
                    type: 'InputNumber',
                    label: /* 语速正常得分 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.normalSpeechSpeedScore,
                    addonAfter: /* 分 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.branch,
                },
                {
                    name: ['speakTooFastConfig', 'appear'],
                    type: 'InputNumber',
                    label: /* 语速过快 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.speakTooFast,
                    addonBefore: (
                        <React.Fragment>{/* 每分钟大于 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.everyMinuteGreaterThan}<FormBuilder.Item name={['speakTooFastConfig', 'gt']}>
                            <InputNumber />
                        </FormBuilder.Item>{/* 个字，出现 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.aWordAppears}</React.Fragment>
                    ),
                    addonAfter: (
                        <React.Fragment>{/* 次，减 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.subtract}<FormBuilder.Item name={['speakTooFastConfig', 'deduction']}>
                            <InputNumber />
                        </FormBuilder.Item>{/* 分 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.branch}</React.Fragment>
                    ),
                    props: {
                        disabled: true,
                        defaultValue: 1,
                    },
                },
                {
                    name: ['speakTooSlowlyConfig', 'appear'],
                    type: 'InputNumber',
                    label: /* 语速过慢 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.speakTooSlowly,
                    addonBefore: (
                        <React.Fragment>{/* 每分钟小于 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.lessThanPerMinute}<FormBuilder.Item name={['speakTooSlowlyConfig', 'lt']}>
                            <InputNumber />
                        </FormBuilder.Item>{/* 个字，出现 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.aWordAppears}</React.Fragment>
                    ),
                    addonAfter: (
                        <React.Fragment>{/* 次，减 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.subtract}<FormBuilder.Item name={['speakTooSlowlyConfig', 'deduction']}>
                            <InputNumber />
                        </FormBuilder.Item>{/* 分 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.branch}</React.Fragment>
                    ),
                    props: {
                        disabled: true,
                        defaultValue: 1,
                    },
                },
                {
                    name: 'ignoreLessSentenceNum',
                    type: 'InputNumber',
                    label: /* 忽略小于 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.ignoreLessThan,
                    colon: false,
                    addonAfter: /* 字的句子 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.aSentenceOfWords,
                },
                {
                    name: 'enableModalParticleTooMuchDetection',
                    type: 'Switch',
                    label: /* 语气词过多检测 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.excessiveDetectionOfModalParticles,
                    effect: {
                        visible: {
                            true: ENABLE_MODAL_PARTICLE_TOO_MUCH_EFFECT,
                            1: ENABLE_MODAL_PARTICLE_TOO_MUCH_EFFECT,
                        },
                    },
                },
                {
                    name: ['modalParticleTooMuchConfig', 'appear'],
                    type: 'InputNumber',
                    wrapperCol:{span: layout.wrapperCol.span, offset: layout.labelCol.span},
                    addonBefore: (
                        <React.Fragment><FormBuilder.Item name={['modalParticleTooMuchConfig', 'conceptWordsIdList']}>
                            <ConceptWordSelect options={conceptWords}  mode="multiple" allowClear/>
                        </FormBuilder.Item>{/* 重复次数为 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.theNumberOfRepetitionsIs}</React.Fragment>
                    ),
                    addonAfter: (
                        <React.Fragment>{/* 次时，减 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.minus}<FormBuilder.Item name={['modalParticleTooMuchConfig', 'deduction']}>
                            <InputNumber />
                        </FormBuilder.Item>{/* 分 */}{UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.branch}</React.Fragment>
                    ),
                    props: {
                        defaultValue: 1,
                    },
                },
                {
                    name: 'enableEmotionDetection',
                    type: 'Switch',
                    label: /* 情绪检测 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.emotionalDetection,
                    effect: {
                        visible: {
                            true: ENABLE_EMOTION_DETECTION_EFFECT,
                            1: ENABLE_EMOTION_DETECTION_EFFECT,
                        },
                    },
                },
                {
                    name: 'normalEmotionScore',
                    type: 'InputNumber',
                    label: /* 情绪正常得分 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.emotionalNormalScore,
                    addonAfter: /* 分 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.branch,
                },
                {
                    name: 'emotionConfigList',
                    type: 'EmotionConfigList',
                    label: /* 负向情绪 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.negativeEmotions,
                    colon: false,
                },
                {
                    name: 'enableWordsViolationDetection',
                    type: 'Switch',
                    label: /* 违规词检测 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.illegalWordDetection,
                    effect: {
                        visible: {
                            true: ENABLE_WORDS_VIOLATION_DETECTION_EFFECT,
                            1: ENABLE_WORDS_VIOLATION_DETECTION_EFFECT,
                        },
                    },
                },
                {
                    name: 'wordsViolationConfigList',
                    type: 'ViolationConfigList',
                    label: /* 话术违规配置 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.scriptViolationConfiguration,
                    props: {
                        conceptWords,
                    },
                },
                {
                    name: 'realTimeTip',
                    type: 'RealTimeTip',
                    label: /* 前端实时提示 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.sessionDetectionForm.realTimePromptOnTheFrontEnd,
                    props: {
                        conceptWords,
                    },
                },
            ]}
        />
    );
});
