import React, { FC, useState, useImperativeHandle, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { Spin, message, Input, Button } from 'udesk-ui';
import styled from 'styled-components';
import Empty from '../empty';
import { getSummaryCallByCallId, postSummaryCallByCallId } from 'src/api/summary/call/{callId}';
import { putSummaryById } from 'src/api/summary/{id}';
import { getChatDataCallTaskByCallId } from 'src/api/chatDataCallTask/{callId}';
import { Field } from '../../const';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div``;

type TemplateProps = {
    callId?: number;
    taskId?: number;
    field?: Field;
    value?: string;
    onChange?: (v?: string) => void;
    isEdit?: boolean;
    forwardRef?: any;
    checkEditShow?: () => void;
    updateView?: () => void;
};

const Template: FC<TemplateProps> = (props) => {
    const {
        callId,
        taskId,
        field,
        value,
        onChange,
        isEdit,
        forwardRef,
        checkEditShow,
        updateView,
    } = props;

    const [dataContent, setDataContent] = useState<string>();
    const [dataId, setDataId] = useState<number>();

    const { run: getData, loading: getSummaryLoading } = useRequest(
        () =>
            getSummaryCallByCallId({
                params: { inspectTaskId: taskId!, fieldId: field?.id! },
                segments: { callId: callId! },
            }),
        {
            ready: !!taskId && !!callId && !!field?.id,
            onSuccess: (res) => {
                onChange?.(res.data?.content);
                setDataId(res.data?.id);
                setDataContent(res.data?.content);
                checkEditShow?.();
                updateView?.();
            },
        }
    );

    const [getProgressLoading, setGetProgressLoading] = useState(false);

    const { run: getProgress, cancel: cancelGetSessionSummary } = useRequest(
        () =>
            getChatDataCallTaskByCallId({
                params: { inspectTaskId: taskId!, fieldId: field?.id! },
                segments: { callId: callId! },
            }),
        {
            manual: true,
            pollingInterval: 3000,
            ready: !!taskId && !!callId && !!field?.id,
            onSuccess: (res) => {
                // 2-进行中 3-成功 4-失败
                if (res.data?.status === 3) {
                    cancelGetSessionSummary();
                    getData();
                    setGetProgressLoading(false);
                } else if (res.data?.status === 4) {
                    cancelGetSessionSummary();
                    message.error(
                        /* 生成失败 */ UdeskLocales['current'].components.businessSummary.components
                            .fieldItem.index.generationFailed
                    );
                    setGetProgressLoading(false);
                }
            },
            onError: (e) => {
                cancelGetSessionSummary();
            },
        }
    );

    const { run: genData, loading: genSummaryLoading } = useRequest(
        () =>
            postSummaryCallByCallId(
                { inspectTaskId: taskId, fieldId: field?.id },
                { segments: { callId: callId! } }
            ),
        {
            manual: true,
            ready: !!taskId && !!callId && !!field?.id,
            onSuccess: () => {
                setGetProgressLoading(true);
                getProgress();
            },
        }
    );

    const onGen = () => {
        genData();
    };

    const { run: putData, loading: putLoading } = useRequest(
        () => putSummaryById({ content: value }, { segments: { id: dataId! } }),
        {
            ready: !!dataId && !!dataContent,
            manual: true,
            onSuccess: () => {
                getData();
            },
        }
    );

    const isLaoding = useMemo(() => {
        return genSummaryLoading || getProgressLoading || getSummaryLoading || putLoading;
    }, [genSummaryLoading, getProgressLoading, getSummaryLoading, putLoading]);

    useImperativeHandle(
        forwardRef,
        () => {
            return {
                id: field?.id,
                putData: () => {
                    !isLaoding && putData();
                },
                genData: () => {
                    !isLaoding && genData();
                },
                getDataContent: () => {
                    return dataContent;
                },
            };
        },
        [field?.id, isLaoding, putData, genData, dataContent]
    );

    return (
        <Wrapper>
            <Spin spinning={isLaoding}>
                {dataContent ? (
                    <Input.TextArea
                        bordered={isEdit}
                        readOnly={!isEdit}
                        autoSize
                        value={value}
                        onChange={(e) => onChange?.(e.target.value)}
                    />
                ) : (
                    <Empty>
                        <Button onClick={() => onGen()} type="primary">
                            {/* 生成 */}
                            {
                                UdeskLocales['current'].components.businessSummary.components
                                    .fieldItem.index.generate
                            }
                        </Button>
                    </Empty>
                )}
            </Spin>
        </Wrapper>
    );
};

export default Template;
