// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    PostInspectionRuleApprovesBody,
    BaseResponseInspectionRuleApproveFoundResponse,
} from '../../types';

/**
 * 提交审批
 *
 * @export
 * @tags 质检规则审核
 * @link [POST] /inspectionRule/approves
 */
export function postInspectionRuleApproves(
    data: PostInspectionRuleApprovesBody
): Promise<BaseResponseInspectionRuleApproveFoundResponse>;
export function postInspectionRuleApproves(
    data: PostInspectionRuleApprovesBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseInspectionRuleApproveFoundResponse>;
export function postInspectionRuleApproves(
    data: PostInspectionRuleApprovesBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseInspectionRuleApproveFoundResponse> {
    return request<PostInspectionRuleApprovesBody, never, never>('/inspectionRule/approves', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postInspectionRuleApproves',
    });
}

export const meta = [{ tags: ['质检规则审核'], path: '/inspectionRule/approves', method: 'post' }];
