import React, { useImperativeHandle, useState } from 'react';
import { useRequest } from 'ahooks';
import { useRefer, ReferType } from './hooks';
import { List, message, Radio, Typography } from 'udesk-ui';
import { TreeMenu } from 'udesk_gm_ui';
import { TreeNode } from 'udesk_gm_ui/es/tree-menu/types';
import styled from 'styled-components';
import { getIntelligentPartnerAiAnalysisPromptWordFindAll } from 'src/api/intelligentPartner/ai/analysis/prompt/word/findAll/index';
import { getIntelligentPartnerAiAnalysisPromptSentenceFindAll } from 'src/api/intelligentPartner/ai/analysis/prompt/sentence/findAll/index';
import { getIntelligentPartnerAiAnalysisQueryHistory } from 'src/api/intelligentPartner/ai/analysis/query/history';
import {
    IntelligentPartnerPromptWordClassify,
    IntelligentPartnerPromptSentenceFoundResponse,
    IntelligentPartnerAiTextToSqlFoundResponse,
} from 'src/api/types';
import Highlighter from './components/Highlighter';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    height: 100%;
    padding: 16px;
`;

const Header = styled.div`
    text-align: center;
    margin-bottom: 12px;
`;

const Content = styled.div`
    height: calc(100% - 40px);
    overflow-y: auto;
`;

const EllipsisWrapper = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

type ReferProps = {
    onCheckSentence: (s: string) => void;
    onCheckHistory: (h: IntelligentPartnerAiTextToSqlFoundResponse) => void;
    referRef: any;
    setHistoryId: any;
};

const Refer = (props: ReferProps) => {
    const { onCheckSentence, onCheckHistory, referRef, setHistoryId } = props;

    const { referTypeList } = useRefer();

    const [cueType, setCueType] = useState(ReferType.Data);

    const onTypeChange = (v) => {
        setCueType(v);
    };

    const [words, setWords] = useState<IntelligentPartnerPromptWordClassify[]>([]);
    const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<number[]>([]);

    useRequest(getIntelligentPartnerAiAnalysisPromptWordFindAll, {
        onSuccess: (res) => {
            setWords(terserData(res.data || []));
            setDefaultExpandedKeys((res.data || []).map((d) => d.id!));
        },
    });

    const terserData = (data: IntelligentPartnerPromptWordClassify[]) => {
        return data.map((d) => {
            return {
                ...d,
                key: d.id,
                title: d.name,
                children: d.children?.length && terserData(d.children),
            };
        });
    };

    const [sentences, setSentences] = useState<IntelligentPartnerPromptSentenceFoundResponse[]>([]);

    useRequest(getIntelligentPartnerAiAnalysisPromptSentenceFindAll, {
        onSuccess: (res) => {
            setSentences(res.data || []);
        },
    });

    const onApply = (prompt?: string) => {
        onCheckSentence(prompt || '');
    };

    const onCopy = (v: string) => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', v);
        input.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            message.success(/* 复制成功 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.refer.index.copySuccessful);
        }
        document.body.removeChild(input);
    };

    const [historys, setHistorys] = useState<IntelligentPartnerAiTextToSqlFoundResponse[]>([]);

    const { run: getHistory } = useRequest(getIntelligentPartnerAiAnalysisQueryHistory, {
        onSuccess: (res) => {
            setHistorys(res.data || []);
            setHistoryId(res.data?.[0]?.id);
        },
    });

    useImperativeHandle(referRef, () => {
        return {
            getHistory,
        };
    });

    const onApplyHistory = (his: IntelligentPartnerAiTextToSqlFoundResponse) => {
        onCheckHistory(his);
    };

    return (
        <Wrapper>
            <Header>
                <Radio.Group
                    value={cueType}
                    buttonStyle="solid"
                    onChange={(e) => onTypeChange(e.target.value)}
                >
                    {referTypeList.map((t) => (
                        <Radio.Button value={t.type}>{t.name}</Radio.Button>
                    ))}
                </Radio.Group>
            </Header>

            <Content>
                {cueType === ReferType.Data ? (
                    <TreeMenu
                        treeData={words as TreeNode[]}
                        blockNode
                        defaultExpandedKeys={defaultExpandedKeys}
                        treeNodeActionMenu={[
                            {
                                label: /* 复制 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.refer.index.copy,
                                onClick: (item) => {
                                    onCopy(item.name);
                                },
                                disabled: (item) => {
                                    return item.type !== 3;
                                },
                            },
                        ]}
                    />
                ) : cueType === ReferType.Question ? (
                    <List
                        size="small"
                        bordered
                        dataSource={sentences}
                        renderItem={(item) => (
                            <List.Item
                                title={item.sentence}
                                style={{ cursor: 'pointer' }}
                                onClick={() => onApply(item.sentence)}
                            >
                                <EllipsisWrapper
                                    dangerouslySetInnerHTML={{
                                        __html: Highlighter({
                                            text: item.sentence,
                                            highlightWords: item.highlightWords,
                                        }),
                                    }}
                                ></EllipsisWrapper>
                            </List.Item>
                        )}
                    />
                ) : (
                    <List
                        size="small"
                        bordered
                        dataSource={historys}
                        renderItem={(item) => (
                            <List.Item
                                title={item.title}
                                style={{ cursor: 'pointer' }}
                                onClick={() => onApplyHistory(item)}
                            >
                                <Typography.Text ellipsis={true}>{item.title}</Typography.Text>
                            </List.Item>
                        )}
                    />
                )}
            </Content>
        </Wrapper>
    );
};

export default Refer;
