// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetInspectionDataByIdQuery,
    GetInspectionDataByIdParam,
    BaseResponseInspectionDataWrapper,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 质检工作台
 * @link [GET] /inspectionData/{id}
 * @param id
 */
export function getInspectionDataById(
    options: ApiOptions<GetInspectionDataByIdParam, GetInspectionDataByIdQuery> & {
        segments: GetInspectionDataByIdParam;
    } & { params: GetInspectionDataByIdQuery }
): Promise<BaseResponseInspectionDataWrapper> {
    return request<never, GetInspectionDataByIdParam, GetInspectionDataByIdQuery>(
        '/inspectionData/{id}',
        { ...options, method: 'get', __$requestCalleeName: 'getInspectionDataById' }
    );
}

export const meta = [{ tags: ['质检工作台'], path: '/inspectionData/{id}', method: 'get' }];
