import React from 'react';
import Udesk from 'Udesk';
// import ClassNames from 'classnames';
import AudioTextViewComponent from './component';
// import ReactTinymce from 'udesk-react/src/components/react-tinymce';
import ImageView from 'udesk-react/src/components/image-viewer';
import AudioTextViewContentComponent from '../audio-text-view-content';
import { Radio, Icon, Input } from 'udesk-ui';
import './style.scss';

export default class AudioTextViewTemplate extends AudioTextViewComponent {
    render() {
        let {
            // sdkOptions,
            appealVisible,
            inspectDataSource,
            markSemanticVisible,
            detailId,
            applyReason,
            dialogRemarkList,
        } = this.props;
        let {
            computes,
            audioTextListRef,
            highlightSeqIndex,
            highlightSeqList,
            searchContentList,
            keywords,
        } = this.privates;
        let {
            actions,
            // privates,
            locales
        } = this;
        return (
            <div className="audio-text-list-container">
                <If condition={highlightSeqList.length > 0}>
                    <div className="audio-text-highlight-navigation">
                        <div className="audio-text-highlight-navigation-button button-up" onClick={actions.nextHit.params("up")}>
                            <Icon type="ic-arrow-up-s-line" style={{ height: "12px" }} />
                        </div>
                        <div className="audio-text-highlight-navigation-button button-down" onClick={actions.nextHit.params("down")}>
                            <Icon type="ic-arrow-down-s-xiajiantou" style={{ height: "12px" }} />
                        </div>
                        <div className="audio-text-highlight-navigation-text">
                            {highlightSeqIndex}/{highlightSeqList.length}
                        </div>
                        <div className="audio-text-highlight-navigation-close" onClick={actions.closeHit}>
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-quxiao"></i>
                        </div>
                    </div>
                </If>
                <If condition={searchContentList.length > 0}>
                    <div className="audio-text-highlight-navigation">
                        <div className="audio-text-highlight-navigation-button button-up" onClick={actions.nextSearched.params("up")}>
                            <Icon type="ic-arrow-up-s-line" style={{ height: "12px" }} />
                        </div>
                        <div className="audio-text-highlight-navigation-button button-down" onClick={actions.nextSearched.params("down")}>
                            <Icon type="ic-arrow-down-s-xiajiantou" style={{ height: "12px" }} />
                        </div>
                        <div className="audio-text-highlight-navigation-text">
                            {highlightSeqIndex}/{searchContentList.length}
                        </div>
                        <div className="audio-text-highlight-navigation-close" onClick={actions.closeSearched}>
                            <i className="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-quxiao"></i>
                        </div>
                    </div>
                </If>
                <div className="audio-text-search-container">
                    <input className="audio-text-search-input" value={keywords} onChange={actions.keywordsChanged} />
                    <button className="btn audio-text-search-button" onClick={actions.searchContet}>
                        <i class="udesk-qa-react-web-iconfont icon-udesk-qa-react-web-icon-"></i>
                    </button>
                </div>
                <ul className="audio-text-list" ref={audioTextListRef}>
                    <If condition={computes && computes.audioTextItems && computes.audioTextItems.length > 0}>
                        <ImageView>
                            <For each="item" index="index" of={computes.audioTextItems}>
                                <With type={item.states.content.type} role={item.data.role} content={item.states.content.data.content}>
                                    <If condition={role === "customer"}>
                                        <li key={detailId + item.data.id} className="audio-text-list-item customer">
                                            <div className="audio-text-list-item-icon customer">
                                                {locales.components.pages.appealChekingTask.list.customer}
                                            </div>
                                            <AudioTextViewContentComponent index={index} dialogRemarkList={dialogRemarkList} inputFlag={actions.inputFlag} fieldsId={this.props.fieldsId} markSemanticVisible={markSemanticVisible} caseBaselist={actions.caseBaselist} fieldsList={this.props.fieldsList} inspectDataSource={inspectDataSource} item={item} type={type} content={content} onTimeRangeChanged={actions.sendCurrentTimeRange} onMarkSemanticClick={actions.markSemantic} caseBaseFlag={this.privates.caseBaseFlag} fieldFlag={this.props.fieldFlag} />
                                            <If condition={appealVisible}>
                                                <div className="audio-text-list-item-appeal-container">
                                                    <i className={["udesk-qa-react-web-iconfont", item.states.isComplain ? "icon-udesk-qa-react-web-dengpao" : " icon-udesk-qa-react-web-dengpao1"].join(' ')}></i>
                                                    <div className={["audio-text-list-item-appeal-types", applyReason ? "customer-with-reason" : "customer-without-reason"].join(' ')} style={{ display: item.data.reasonEditStatus ? "block" : "" }}>
                                                        <Radio.Group value={item.data.complainType} onChange={actions.changeAppealType.params(item)} >
                                                            <For each="appealType" index="index" of={Udesk.enums.appealTypes}>
                                                                <Radio value={appealType.id}>{appealType.name}</Radio>
                                                            </For>
                                                        </Radio.Group>
                                                        <If condition={applyReason}>
                                                            <Input.TextArea disabled={!item.data.complainType} value={item.data.complainReason} onChange={actions.changeAppealReason.params(item)} onFocus={actions.changeReasonEditStatus.params(item, true)} onBlur={actions.changeReasonEditStatus.params(item, false)} rows={3} showCount={true} maxCount={50} maxLength={50} placeholder={locales.components.pages.appealChekingTask.list.applyReason} />
                                                        </If>
                                                    </div>
                                                </div>
                                            </If>
                                        </li>
                                    </If>
                                    <If condition={role === "agent"}>
                                        <li key={detailId + item.data.id} className="audio-text-list-item agent" style={{justifyContent: 'flex-end'}}>
                                            <If condition={appealVisible}>
                                                <div className="audio-text-list-item-appeal-container">
                                                    <i className={["udesk-qa-react-web-iconfont", item.states.isComplain ? "icon-udesk-qa-react-web-dengpao" : " icon-udesk-qa-react-web-dengpao1"].join(' ')}></i>
                                                    <div className={["audio-text-list-item-appeal-types", applyReason ? "agent-with-reason" : "agent-without-reason"].join(' ')} style={{ display: item.data.reasonEditStatus ? "block" : "" }}>
                                                        <Radio.Group value={item.data.complainType} onChange={actions.changeAppealType.params(item)} >
                                                            <For each="appealType" index="index" of={Udesk.enums.appealTypes}>
                                                                <Radio value={appealType.id}>{appealType.name}</Radio>
                                                            </For>
                                                        </Radio.Group>
                                                        <If condition={applyReason}>
                                                            <Input.TextArea disabled={!item.data.complainType} value={item.data.complainReason} onChange={actions.changeAppealReason.params(item)} onFocus={actions.changeReasonEditStatus.params(item, true)} onBlur={actions.changeReasonEditStatus.params(item, false)} rows={3} showCount={true} maxCount={50} maxLength={50} placeholder={locales.components.pages.appealChekingTask.list.applyReason} />
                                                        </If>
                                                    </div>
                                                </div>
                                            </If>
                                            <AudioTextViewContentComponent index={index} dialogRemarkList={dialogRemarkList} inputFlag={actions.inputFlag} fieldsId={this.props.fieldsId} markSemanticVisible={markSemanticVisible} caseBaselist={actions.caseBaselist} fieldsList={this.props.fieldsList} inspectDataSource={inspectDataSource} item={item} type={type} content={content} onTimeRangeChanged={actions.sendCurrentTimeRange} onMarkSemanticClick={actions.markSemantic} caseBaseFlag={this.privates.caseBaseFlag} fieldFlag={this.props.fieldFlag} />
                                            <div className="audio-text-list-item-icon agent">
                                                {locales.components.pages.appealChekingTask.list.agent}
                                            </div>
                                        </li>
                                    </If>
                                </With>
                            </For>
                        </ImageView>
                    </If>
                </ul>
            </div>
        );
    }
}

// function getAudioItemTime(startPoint, endPoint) {
//     let start = Udesk.utils.date.formatDuration(startPoint);
//     let end = Udesk.utils.date.formatDuration(endPoint);
//     return `${start} - ${end}`;
// }
