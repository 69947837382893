import React, { FC, memo } from 'react';
import BaseFields, { TemplateProps as BaseFieldsProps } from './components/base-fields';
import Fields, { TemplateProps as OtherFieldsProps } from './components/fields';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 24px;
`;

export type TemplateProps = {
    wrapperProps?: Record<string, unknown>;
    baseFieldsProps?: BaseFieldsProps;
    hiddenBase?: boolean;
    otherFieldsProps?: OtherFieldsProps;
    hiddenOther?: boolean;
};

const Template: FC<TemplateProps> = (props) => {
    const { wrapperProps, baseFieldsProps, otherFieldsProps, hiddenBase, hiddenOther } = props;

    return (
        <Wrapper {...wrapperProps}>
            {!hiddenBase ? <BaseFields {...baseFieldsProps} /> : null}

            {!hiddenOther ? (
                <Fields
                    descriptionsConfig={{ title: /* 其他信息 */UdeskLocales['current'].pages.gong.customerFields.index.otherInformation, column: 2, bordered: true }}
                    {...otherFieldsProps}
                />
            ) : null}
        </Wrapper>
    );
};

export default memo(Template);
