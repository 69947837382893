import React from 'react';
import Udesk from 'Udesk';
import DialogueManageIndexRoute from './route';
import FilterManage from '../components/filter-manage';
import ListManage from '../components/list-manage';
import './style.scss';

export default class DialogueManageIndexTemplate extends DialogueManageIndexRoute {
    render() {
        let {
            filterVisible,
            isUpdate,
            keyword
        } = this.privates;
        let {
            selectedFilter,
        } = this.privates.storages;
        let {
            actions,
            locales
        } = this;
        return (
            // <div className="row qa-react-page call-manage-page">
            <div className='udesk-qa-web-page'>
                <div className='udesk-qa-web-page-body'>
                    <div className='udesk-qa-web-page-body-root call-manage-page'>
                        <div className="call-manage-page-left-part">
                            <FilterManage ref={this.privates.filterManageRef} type={Udesk.enums.filterTypes.dialogue.id} filterManageTitle={locales.components.pages.dialogueManage.title} onEditFilter={actions.editFilter} allFilterTitle={locales.components.pages.components.filterManage.allDialogue} onSelectedFilterChanged={actions.onSelectedFilterChanged} isUpdate={isUpdate} />
                        </div>
                        <div className="call-manage-page-right-part">
                            <ListManage title={locales.components.pages.dialogueManage.listTitle} cacheKey="dialogue-manage.index" searchPlaceHolder={locales.labels.searchPlaceHolder} type={Udesk.enums.filterTypes.dialogue.id} editFilterVisible={filterVisible} onEditFilterVisibleChanged={actions.onEditFilterVisibleChanged} onViewItemDetail={actions.onViewItemDetail} onPageChanged={actions.onPageChanged} selectedFilter={selectedFilter} onConditionFilterSaved={actions.onConditionFilterSaved} keyword={keyword} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

