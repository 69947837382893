import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import TaskBasicInfoComponent from "./component";
export default class TaskBasicInfoRoute extends TaskBasicInfoComponent {
    model() {
        const url = Udesk.business.apiPath.concatApiPath('/account/charge', this.props.sdkOptions);
        let company = new Promise((resolve, reject) => {
            Udesk.ajax.get(url).then(resp => {
                resolve(resp.data);
            }, reason => {
                Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                reject(reason);
            });
        });
        const urls = Udesk.business.apiPath.concatApiPath(`/inspectionTasks/module/${this.props.match.params.moduleVersionId}/${this.props.match.params.taskId}`, this.props.sdkOptions);
        let taskData = new Promise((resolve, reject) => {
            Udesk.ajax.get(urls).then(resp => {
                this.privates.taskName = resp.data.name;
                this.privates.taskRemark = resp.data.remark;
                this.privates.taskSource = resp.data.inspectDataSource;
                // this.privates.qualityUser = resp.data.inspector;
                this.setState({
                    inspectorList: resp.data.inspectorList
                });
                resolve(resp.data);
                resolve(resp.data);
            }, reason => {
                Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.basicInfo.index.title));
                reject(reason);
            });
        });
        return {
            task: taskData,
            company: company
        };
    }
}
