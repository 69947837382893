export default {
  lang: {
    export: 'İhracat',
    successfully: 'Başarıyı oluşturmak için görevleri dışa aktarma',
    pleaseJumpTo: 'Lütfen atlayın',
    uploadAndDownloadCenter: 'Yükleme Merkezi',
    check: 'Görüntüle',
    exportAll: 'Tüm içeriği dışa aktarmayı onayla?',
    total: 'Toplam',
    confirm: 'Verilen verileri onaylayın?',
    error:
      'Dışa aktarılabilir veri sayısı 0, lütfen filtrelemeyi değiştirdikten sonra tekrar deneyin',
  },
};
