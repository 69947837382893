import React, { FC, useState } from 'react';
import { Input, Spin } from 'udesk-ui';
import { Title } from './components/Title';

type GenByAiProps = {
    value?: string;
    onChange?: (v: string) => void;
    onGen?: () => Promise<string>;
    label?: string;
    btnText?: string;
};

export const GenByAi: FC<GenByAiProps> = (props) => {
    const { value, onChange, onGen, label, btnText, ...restProps } = props;

    const [loading, setLoading] = useState(false);

    const onButtonClick = () => {
        setLoading(true);
        onGen?.()
            .then((v) => {
                onChange?.(v);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onInnerChange = (v: string) => {
        onChange?.(v);
    };

    return (
        <>
            <Title
                onButtonClick={onButtonClick}
                disabled={loading}
                label={label}
                btnText={btnText}
            />

            <Spin spinning={loading}>
                <Input.TextArea
                    value={value}
                    onChange={(e) => onInnerChange(e.target.value)}
                    {...restProps}
                />
            </Spin>
        </>
    );
};
