
import InputBaseClass from 'udesk-react/src/components/custom-filter-inputs/input-base-class';
import './index.scss';

class SelectComponent extends InputBaseClass {
    privates = {
        firstValueFieldName: "variableCategory",
        firstValueFieldVariableName: "variableName",
        firstValueFieldVariableType: "variableType",
        secondValueFieldName: "variableValue",
        value: this.props.value || {},
    };

    parseProps({ props, prevProps, privates }) {
        if (props.value !== prevProps.value) {
            this.privates.value = props.value || {};
            this.actions.update();
        }
    }

    actions = {
        _getInternalValueFromValue() {
            let {
                value
            } = this.privates;
            return value || {};
        },
        _onFirstInputVariableChanged(valueInner) {
            let {
                privates: { computes, value },
            } = this;

            let {
                mergedAttributes
            } = computes;

            let {
                firstValueFieldVariableName = this.privates.firstValueFieldVariableName,
                firstValueFieldVariableType = this.privates.firstValueFieldVariableType,
                variableOptions
            } = mergedAttributes;
            let valueObj = variableOptions.find(item => item[firstValueFieldVariableName] === valueInner);
            if (valueObj) {
                value[firstValueFieldVariableName] = valueObj[firstValueFieldVariableName];
                value[firstValueFieldVariableType] = valueObj[firstValueFieldVariableType];
                this.actions.onChanged(value);
            }

        },
        _onFirstInputVariableSmsChanged(valueInner) {
            let {
                privates: { computes, value },
            } = this;

            let {
                mergedAttributes
            } = computes;

            let {
                firstValueFieldName = this.privates.firstValueFieldName,
                smsModelOptions
            } = mergedAttributes;
            let valueObj = smsModelOptions.find(item => item.id === valueInner);
            if (valueObj) {
                value[firstValueFieldName] = valueObj.id;
                this.actions.onChanged(value);
            }
        },
        _onFirstInputVariableCallTypeChanged(valueInner) {
            let {
                privates: { computes, value },
            } = this;

            let {
                mergedAttributes
            } = computes;

            let {
                firstValueFieldName = this.privates.firstValueFieldName,
                callTypeOptions
            } = mergedAttributes;
            let valueObj = callTypeOptions.find(item => item.id === valueInner);
            if (valueObj) {
                value[firstValueFieldName] = valueObj.id;
                this.actions.onChanged(value);
            }
        },
        _onFirstInputVariableQueueChanged(valueInner) {
            let {
                privates: { computes, value },
            } = this;

            let {
                mergedAttributes
            } = computes;

            let {
                firstValueFieldName = this.privates.firstValueFieldName,
                queueOption
            } = mergedAttributes;
            let valueObj = queueOption.find(item => item.id === valueInner);
            if (valueObj) {
                value[firstValueFieldName] = valueObj.id;
                this.actions.onChanged(value);
            }
        },
        _onWorkTimeChanged(valueInner) {
            let {
                privates: { computes, value },
            } = this;

            let {
                mergedAttributes
            } = computes;

            let {
                workTimeOptions,
                firstValueFieldName = this.privates.firstValueFieldName,
            } = mergedAttributes;
            let workTimeObject = workTimeOptions.find(item => item.id === valueInner);
            if (workTimeObject) {
                value[firstValueFieldName] = workTimeObject.id;
                this.actions.onChanged(value);
            }

        },
        _onSelectChanged(valueInner) {
            // let {
            //     mergedAttributes
            // } = computes;
            // let {
            //     firstValueFieldName = this.privates.firstValueFieldName,
            // } = mergedAttributes;
            this.actions.onChanged(valueInner);
        },
        onChanged(value) {
            // this.actions.update();
            this.trigger("onChanged", value);
        }
    };


}

export default SelectComponent;