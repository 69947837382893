// 统一合并为完整的 Locale
var locale = {
  lang: {
    export: '导出',
    successfully: '导出任务创建成功',
    pleaseJumpTo: '请跳转至',
    uploadAndDownloadCenter: '上传下载中心',
    check: '查看',
    exportAll: '确认导出全部内容?',
    total: '总共',
    confirm: '条数据，确认导出?',
    error: '可导出数据数量为0，请更改筛选条件后重试'
  }
};
export default locale;