import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Button, Form, Input, Space } from 'udesk-ui';
import { isExercise } from '../../util';
import { useDisabledForStartButton } from '../hook';
import { Dialogue } from 'Component/pages/components/coach/Dialogue';
import {
    getRtaProps,
    isRta,
} from 'Component/pages/components/coach/Dialogue/components/RealTimeAnalyzer';
import { isBoolean } from 'src/util/core';
import './index.less';
import UdeskLocales from 'UdeskLocales';
// import Udesk from 'Udesk';

export default React.memo((props: any) => {
    const {
        setSubmitMessage,
        nextMessage,
        task,
        globalConfig,
        isStartTask,
        isLastNode,
        list,
        startTaskHandle,
        submitMessageHandle,
        loadingForTaskStart,
        loadingForSendMessage,
        // courseType,
    } = props;

    const chatRecordRef = useRef<any>(null);
    const inputRef = useRef<any>(null);
    const [form] = Form.useForm();
    const [isDisabled] = useDisabledForStartButton(task);

    const finishHandle = useMemo(() => {
        const handle = (values) => {
            submitMessageHandle(isBoolean(values) ? values : values.textMessage);
            form.resetFields();
            inputRef.current?.focus();
        };
        setSubmitMessage({ handle });
        return handle;
    }, [form, setSubmitMessage, submitMessageHandle]);

    const changeHandle = useCallback(
        (data) => {
            if (nextMessage) {
                nextMessage.content = data.textMessage;
            }
        },
        [nextMessage]
    );

    const scoreDetailProps = useMemo(() => getRtaProps(task, globalConfig), [task, globalConfig]);

    useEffect(() => {
        chatRecordRef.current?.scrollTo(list?.length);
    }, [list?.length]);

    // const onCorrect = () => {
    //     finishHandle({
    //         textMessage: `请注意你的角色是${task?.customerPersona?.name}，你只输出作为一个${task?.customerPersona?.name}应该说的话。`,
    //     });
    // };

    return (
        <div className={'chat-record-panel'}>
            <div className="chat-panel">
                <Dialogue
                    ref={chatRecordRef}
                    dialogueSentenceList={list}
                    itemRender={(props, index) => {
                        return (
                            <React.Fragment>
                                <Dialogue.Item
                                    {...props}
                                    {...scoreDetailProps}
                                    index={index}
                                    isRta={isRta(task, props)}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
            {!isLastNode && (
                <div className="record-toolbar">
                    <Form
                        form={form}
                        layout="inline"
                        onValuesChange={changeHandle}
                        onFinish={finishHandle}
                    >
                        <Form.Item name="textMessage">
                            <Input
                                ref={inputRef}
                                placeholder={
                                    /* 请输入 */ UdeskLocales['current'].pages.coach.learningCenter
                                        .components.record.text.index.pleaseEnter
                                }
                            />
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Space>
                                    <Button
                                        loading={loadingForSendMessage}
                                        size="large"
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {/* 发送 */}
                                        {
                                            UdeskLocales['current'].pages.coach.learningCenter
                                                .components.record.text.index.sendOut
                                        }
                                    </Button>
                                    {/* {courseType === Udesk.enums.lessonType.aiGen.id ? (
                                        <Button onClick={onCorrect} size="large">
                                            对话矫正
                                        </Button>
                                    ) : null} */}
                                </Space>
                            )}
                        </Form.Item>
                    </Form>
                    {!isStartTask && (
                        <div className="start-button">
                            <Button
                                disabled={isDisabled}
                                loading={loadingForTaskStart}
                                type="primary"
                                size="large"
                                onClick={startTaskHandle}
                            >
                                {isExercise(
                                    task?.taskType,
                                    /* 开始练习 */ UdeskLocales['current'].pages.coach
                                        .learningCenter.components.record.text.index
                                        .startPracticing,
                                    /* 开始考试 */ UdeskLocales['current'].pages.coach
                                        .learningCenter.components.record.text.index.startTheExam
                                )}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});
