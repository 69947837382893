import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import TasksConditionDetailTemplate from '../detail/template';

export default class TasksConditionEditRoute extends TasksConditionDetailTemplate {
    model() {
        let { props } = this;
        let params = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        let modelPromise = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                '/inspectionConditions/' + params.id + '?taskId=' + params.taskId,
                this.props.sdkOptions
            );
            Udesk.ajax.get(url, params).then(
                resp => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                UdeskLocales.current.business.info.loadError,
                                UdeskLocales.current.pages.tasks.manage.template.condition.index.name
                            )
                    );
                    reject(reason);
                }
            );
        });
        let params2 = Object.assign({}, props.match.params, Object.fromEntries(new URLSearchParams(props.location.search)));
        params2.type = 1;
        let inspectionConditions = new Promise((resolve, reject) => {
            let url = Udesk.business.apiPath.concatApiPath(
                "/inspectionConditions",
                this.props.sdkOptions
            );
            Udesk.ajax.get(url, params2)
                .then(
                    resp => {
                        resolve(resp.data);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg || Udesk.utils.string.format(UdeskLocales.current.business.info.loadError, UdeskLocales.current.pages.tasks.manage.template.condition.index.title));
                        reject(reason);
                    }
                );
        });

        return {
            taskId: params.taskId,
            condition: modelPromise,
            inspectionConditions: inspectionConditions,
            task: props.sdkOptions.props.task,
            basicInfoFields: props.sdkOptions.props.basicInfoFields,
        };
    }
}
