import React from "react";
// import Udesk from 'Udesk';
// import _debounce from 'lodash-es/debounce';
import UdeskLocales from 'UdeskLocales';

class BusinessAnalysisFilterComponent extends React.Component {
    privates = {
        num: 0,
        businessList: [
            // {
            //     type: "0",
            //     text: "高级条件"
            // },
            // {
            //     type: "1",
            //     text: "关键词"
            // },
            {
                type: "2",
                text: /* 智能标签 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.component.smartTag
            }
        ],
        fieldsList: [
            {
                id: "0",
                list: []
            },
            {
                id: "2",
                list: [
                    {
                        type: "is",
                        name: /* 等于 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.component.beEqualTo
                    },
                    {
                        type: "contains_all",
                        name: /* 包含全部 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.component.includeAll
                    },
                    {
                        type: "is_any",
                        name: /* 包含任意 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.component.includeAny
                    }
                ]
            },
            {
                id: "1",
                list: [
                    {
                        type: "contains_any",
                        name: /* 匹配任意 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.component.matchAny
                    },
                    {
                        type: "contains_all",
                        name: /* 匹配全部 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.component.matchAll
                    }
                ]
            }
        ],
        intelligenceList: [
            {
                value: "17",
                text: /* 智能标签一 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.component.smartTagI
            },
            {
                value: "18",
                text: /* 智能标签二 */UdeskLocales['current'].pages.intelligentAnalysis.analysisReport.components.businessAnalysisFiter.component.smartTagII
            }
        ],
        data: [],
        timer: null,
        // findAllSamrtTagsItems: [],
        // isSearch: false,
        domInstance: React.createRef(),
        isVisible: false,
    };
    actions = {
        //添加二级及以下分类
        addTwoCustome(value) {
            let { num } = this.privates;
            value.showChildren = true;
            this.props.fieldAddList(value.num, num);
        },
        //删除二级及以下分类分类
        delTwoCustome(value) {
            this.props.fieldDelList(value);
            this.actions.update();
        },
        dataSourceChanged(item, value) {
            this.privates.data.map(items => {
                if (items.num === item.num) {
                    items.type = value;
                }
                return items;
            });
            this.actions.update();
            this.props.dataSourceChanged(item.num, value);
        },
        dataSourceFromChanged(item, value) {
            this.privates.data.map(items => {
                if (items.num === item.num) {
                    items.operator = value;
                }
                return items;
            });
            this.actions.update();
            this.props.dataSourceFromChanged(item.num, value);
        },
        dataSourceFromThreeChanged(item, str, value) {
            if (str === "input") {
                this.privates.data.map(items => {
                    if (items.num === item.num) {
                        items.value = value.target.value;
                    }
                    return items;
                });
                this.actions.update();
                this.props.dataSourceFromThreeChanged(item.num, value.target.value);
            } else if (str === "select") {
                this.privates.data.map(items => {
                    if (items.num === item.num) {
                        items.value = value;
                    }
                    return items;
                });
                // this.privates.isSearch = false;
                this.actions.update();
                this.props.dataSourceFromThreeChanged(item.num, value);
            } else if (str === "treeSelect") {
                const res = value.filter(i => !i.includes('category-'));
                this.privates.data.map(items => {
                    if (items.num === item.num) {
                        items.value = res;
                    }
                    return items;
                });
                // this.privates.isSearch = false;
                this.actions.update();
                this.props.dataSourceFromThreeChanged(item.num, res);
            }
        },
        dataSourceFromfiveChanged(item, value) {
            this.privates.data.map(items => {
                if (items.num === item.num) {
                    items.value = value;
                }
                return items;
            });
            this.actions.update();
            this.props.dataSourceFromfiveChanged(item.num, value);
        },
        timerInputColor(callBack, param1, param2) {
            if (this.privates.timer) {
                clearTimeout(this.privates.timer);
            }
            this.privates.timer = setTimeout(() => {
                callBack(param1, param2);
            }, 300);
        },
        InputColorItem(item, e) {
            this.actions.timerInputColor(
                this.props.InputColorItemChange,
                item,
                e.target.value
            );
        },
        renameItem(item, e) {
            item.conditionName = e.target.value;
            // this.props.renameItemChange(item, e.target.value);
            // this.actions.timerInputColor(
            //     this.props.InputColorItemChange,
            //     item,
            //     e.target.value
            // );
        },
        newAddFilter() {
            this.props.newAddFilterFileds(true);
        },
        displacement(value, str) {
            this.props.displacementChange(value, str);
        },
        // searchField(value) {
        //     let { sdkOptions } = this.props;
        //     new Promise((resolve, reject) => {
        //         Udesk.ajax.post(Udesk.business.apiPath.concatApiPath(`smartTags/findAll`, sdkOptions), {
        //             tagName: value,
        //             pageSize: 3000,
        //             pageNum: 1,
        //         }).then(
        //             resp => {
        //                 this.privates.findAllSamrtTagsItems = resp.data;
        //                 resolve(resp.data);
                        
        //                 this.privates.isSearch = true;
        //                 this.actions.update();
        //             },
        //             reason => {
        //                 Udesk.ui.notify.error(reason.errorMsg);
        //                 reject(reason);
        //             }
        //         );
        //     });
        // },
        // onSearchSelect: _debounce((value) => {
        //     this.actions.searchField(value);
        // }, 300, { 'trailing': true }),
        onShowChildrenChange(item) {
            item.showChildren = !item.showChildren;
            this.actions.update();
        }
    };
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.privates.data = this.props.data;
            this.privates.num = this.props.num;
            this.actions.update();
        }
    }
    componentDidMount() {
        const domInstance = this.privates.domInstance.current;
        var observer = new IntersectionObserver((changes) => {
            for (const change of changes) {
                if (change.intersectionRatio) {
                    requestAnimationFrame(() => {
                        this.privates.isVisible = true;
                        this.actions.update();
                        observer.unobserve(domInstance);
                    });
                }
            }
        }, {});
        observer.observe(domInstance);
        this.privates.data = this.props.data;
        this.privates.num = this.props.num;
        this.actions.update();
    }
}
export default BusinessAnalysisFilterComponent;
