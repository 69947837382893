import React from 'react';
import Udesk from 'Udesk';
import { Redirect } from 'react-router-dom';
import LayoutMenu from 'Component/common/layout-menu';

class intelligentAnalysis extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }
    render() {
        let {
            route,
            location,
            sdkOptions,
        } = this.props;

        let {
            locales
        } = this;

        let navLists = [];
        // 根据当前用户权限，组装菜单列表
        navLists.push({
            text: locales.menu.tasks.analysisTags.text,
            pathName: 'analysisEnableIntellectTags',
            isActive: (match, location) => {
                return location.pathname.includes('/intelligent-analysis/intellect-tags');
            },
        });
        if (Udesk.data.init.user && Udesk.data.init.user.hasFeature('analysis:groupTest:root')) {
            navLists.push({
                text: locales.menu.tasks.groupTest.text,
                pathName: 'groupTest',
                isActive: (match, location) => {
                    return location.pathname.includes('/intelligent-analysis/group-test');
                },
            });
        }
        // if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("smart:tag:root")) {
        //     let pathName = 'sessionLabelTagsIndex';
        //     navLists.push({
        //         text: locales.components.pages.intelligentTags.title,
        //         pathName,
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/intelligent-tags");
        //         }
        //     });
        // }

        // if (Udesk.data.init.user && Udesk.data.init.user.hasFeature("smart:tag:test")) {
        //     navLists.push({
        //         text: /* 规则测试 */locales.pages.intelligentAnalysis.ruleTest,
        //         pathName: "supervisionTest",
        //         isActive: (match, location) => {
        //             return location.pathname.includes("/supervision-test");
        //         }
        //     });
        // }
        if (this.isRedirect(route.path, location.pathname) && sdkOptions.props.landingPath != null) {
            let toPath = location.pathname + sdkOptions.props.landingPath;
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else if (navLists.length > 0 && this.isRedirect(route.path, location.pathname)) {
            let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                routeName: navLists[0].pathName
            });
            return (
                <Redirect to={toPath} sdkOptions={sdkOptions} />
            );
        } else {
            return (
                <div className="semantic-intelligence">
                    {/* <div className="semantic-intelligence-nav col-md-2 col-sm-3 col-xs-12">
                        <NavBar navLists={navLists} classNames="semantic-intelligence-nav-container" navItemClassNames="semantic-intelligence-nav-item" activeClassNames="semantic-intelligence-nav-item-active" />
                    </div>
                    <div className="semantic-intelligence-content col-md-10 col-sm-9 col-xs-12">
                        <For each="route" index="i" of={routes}>
                            <If condition={!route.authCode || (route.authCode && Udesk.data.init.user.hasFeature(route.authCode))}>
                                <SubRoutes key={i} route={route} />
                            </If>
                        </For>
                    </div> */}
                    <LayoutMenu {...this.props} navLists={navLists} menuTitle={locales.components.pages.menuTitleList.intelligentAnalysisTitle} />
                </div>
            );
        }
    }
}

export default Udesk.react.udeskify(intelligentAnalysis);