import React from "react";
import TaskRoleConfigRoute from "./route";
import TaskOperatorListGather from "Component/pages/components/task-operator-list-gather";
import { Button, Alert, PageHeader } from 'udesk-ui';
import './style.scss';

export default class TaskRoleConfigTemplate extends TaskRoleConfigRoute {
    render() {
        let { actions, locales, state, privates, props } = this;
        let { disabled } = this.privates;
        let hasFeature = props.sdkOptions.props.task ? props.sdkOptions.props.task._hasFeature : null;
        return (
            <React.Fragment>
                <div className='udesk-qa-web-page'>
                    {/* <div className="page tasks-manage-template-role-config-index"> */}
                    <PageHeader
                        className="udesk-qa-web-page-header"
                        onBack={actions.gotoTask}
                        title={locales.pages.tasks.manage.template.roleConfig.index.name}
                        subTitle={locales.pages.tasks.manage.template.roleConfig.index.roleConfigTip}
                    />

                    <div className="udesk-qa-web-page-body">
                        <div className="udesk-qa-web-page-body-root tasks-manage-template-role-config-index-content">
                            <If condition={hasFeature && hasFeature("task:template:roleConfig:view")}>
                                {/* <If condition={true}> */}
                                <If condition={this.privates.model.processCreateStatus.processStatus === 5}>
                                    <Alert
                                        message={locales.pages.tasks.manage.template.basicInfo.index.conduct5}
                                        type="info"
                                        closeText={locales.pages.tasks.manage.template.basicInfo.index.quit}
                                        onClose={actions.exitBoot}
                                    />
                                    {/* <div className="tasks-manage-template-basic-info-index-conduct">
                                            <div className="tasks-manage-template-basic-info-index-conduct-left">
                                                {locales.pages.tasks.manage.template.basicInfo.index.conduct5}
                                                <span style={{marginLeft: 16, color: 'rgba(27,109,255,1)', textDecoration: 'underline', cursor: 'pointer'}}>
                                                    {locales.pages.tasks.manage.template.basicInfo.index.helper}
                                                </span>
                                            </div>
                                            <div className="tasks-manage-template-basic-info-index-conduct-right" onClick={actions.exitBoot}>
                                                <span className="udesk-qa-react-web-iconfont" style={{marginRight: 4}}>
                                                    &#xe6ae;
                                                </span>
                                                {locales.pages.tasks.manage.template.basicInfo.index.quit}
                                            </div>
                                        </div> */}
                                </If>
                                <div className="col-lg-8 col-md-8 col-xs-12 col-12 tasks-manage-template-role-config-index-content-body">
                                    <form
                                        className="form-horizontal form-label-left"
                                    >
                                        <div className="form-group">
                                            <label
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                style={{ padding: "0 15px" }}
                                                htmlFor="condition_operator"
                                            >
                                                {locales.pages.tasks.manage.template.condition.detail.operator}
                                                <span className="required">*</span>
                                            </label>
                                            <div className="col-lg-offset-2 col-md-offset-3 col-xs-12 col-12">
                                                <TaskOperatorListGather
                                                    operatorList={privates.model.condition.operatorList}
                                                    operatorTypes={state.operatorTypes}
                                                    onChange={actions.operatorListChanged}
                                                    disabled={!hasFeature || !hasFeature("task:template:roleConfig:edit")}
                                                    entityFields={privates.model.basicInfoFields}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                className="control-label col-lg-2 col-md-3 col-xs-12 col-12"
                                                style={{ padding: "0 15px" }}
                                                htmlFor="condition_operatorLogic"
                                            >
                                                {locales.pages.tasks.manage.template.condition.detail.operatorLogic}
                                                <span className="required">*</span>
                                            </label>
                                            <div className="col-lg-7 col-md-7 col-xs-12 col-12">
                                                <input
                                                    type="text"
                                                    id="condition_operatorLogic"
                                                    className="form-control"
                                                    maxLength="255"
                                                    value={privates.model.condition.operatorLogic}
                                                    onChange={actions.mutator("privates.model.condition.operatorLogic", e => e.target.value, true)}
                                                    disabled={!hasFeature || !hasFeature("task:template:roleConfig:edit")}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-lg-10 col-md-10 col-xs-12 col-12 col-md-offset-2">
                                                <If condition={hasFeature && hasFeature("task:template:roleConfig:edit")}>
                                                    <button type="button" className="btn btn-success" onClick={actions.save} disabled={disabled}>
                                                        {locales.labels.save}
                                                    </button>
                                                </If>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <If
                                    condition={
                                        privates.model.processCreateStatus.processStatus === 5
                                    }
                                >
                                    <div className='tasks-manage-template-basic-info-index-footer'>
                                        <Button type="primary" onClick={actions.handleNext}>
                                            {this.privates.model.processCreateStatus.related === 1 ? locales.pages.tasks.manage.template.basicInfo.index.complete : locales.pages.tasks.manage.template.basicInfo.index.next}
                                        </Button>
                                        {
                                            this.privates.model.processCreateStatus.related !== 1 && (<Button style={{ marginLeft: 8 }} onClick={actions.sendAjaxToNext}>
                                                {locales.pages.tasks.manage.template.basicInfo.index.skip}
                                            </Button>)
                                        }

                                    </div>
                                </If>
                            </If>
                        </div>
                    </div>
                </div>
                {/* <TasksConditionDictionaries items={privates.model.inspectionConditions}/> */}
            </React.Fragment>
        );
    }
}
