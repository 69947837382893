// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type { GetReviewCallOpportunityCreateQuery, BaseResponseVoid } from '../../../../types';

/**
 * 手动获取机会点
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/opportunity/create
 */
export function getReviewCallOpportunityCreate(
    options: ApiOptions<never, GetReviewCallOpportunityCreateQuery> & {
        params: GetReviewCallOpportunityCreateQuery;
    }
): Promise<BaseResponseVoid> {
    return request<never, never, GetReviewCallOpportunityCreateQuery>(
        '/review/call/opportunity/create',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewCallOpportunityCreate' }
    );
}

export const meta = [
    { tags: ['通话记录'], path: '/review/call/opportunity/create', method: 'get' },
];
