import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import useResizeObserver from 'use-resize-observer';
import { useChartOption } from './hooks/useChartOption';
import Page from 'Component/common/udesk-qa-page-layout';
import Search from './components/search';
import Refer from './components/refer';
import Header from './components/header';
import { StandardizeTable } from 'udesk_gm_ui';
import { message } from 'udesk-ui';
import { ChartType } from './components/header/hooks';
import * as echarts from 'echarts';
import styled from 'styled-components';
import { postIntelligentPartnerAiAnalysisTextToSqlResult } from 'src/api/intelligentPartner/ai/analysis/textToSql/result/index';
import { postIntelligentPartnerAiAnalysisTextToSqlResultFindAll } from 'src/api/intelligentPartner/ai/analysis/textToSql/result/findAll/index';
import { postIntelligentPartnerAiAnalysisTextToSqlResultExport } from 'src/api/intelligentPartner/ai/analysis/textToSql/result/export/index';
import { getSystemModule } from 'Udesk/system/subApp';
import { IntelligentPartnerAiTextToSqlFoundResponse } from 'src/api/types';
import './index.less';
import UdeskLocales from 'UdeskLocales';

type TemplateProps = {};

const Wrapper = styled.div`
    height: 100%;
    overflow-y: auto;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const Sider = styled.div`
    background-color: #fff;
    height: 100%;
    flex: 0 0 300px;
    margin-right: 20px;
    width: 300px;
`;

const Main = styled.div`
    height: 100%;
    width: calc(100% - 340px);
    flex: 1;
    background-color: #fff;
    padding: 20px;
`;

const Content = styled.div`
    height: calc(100% - 96px);
    width: 100%;
`;

const Chart = styled.div`
    height: 100%;
`;

const Template: FC<TemplateProps> = React.memo((props) => {
    const [curPrompt, setCurPrompt] = useState('');
    const [curTitle, setCurTitle] = useState('');
    const [curSql, setCurSql] = useState('');
    const [curXfield, setCurXfield] = useState('');
    const [curYfields, setCurYfields] = useState<string[]>([]);
    const [curColumnEnumMap, setCurColumnEnumMap] = useState<any>();
    const referRef = useRef();

    const { run: getSql, loading: getSqlloading } = useRequest(
        (userInput) => postIntelligentPartnerAiAnalysisTextToSqlResult({ userInput }),
        {
            manual: true,
            onSuccess: (res) => {
                setCurTitle(res.data?.title || curPrompt);
                setCurSql(res.data?.sql || '');
                setCurXfield(res.data?.xcolumns?.[0] || '');
                setCurYfields(res.data?.ycolumns || []);
                setCurColumnEnumMap(res.data?.columnEnumMap);

                getData(
                    {
                        sql: res.data?.sql,
                        columnEnumMap: res.data?.columnEnumMap,
                        pageNum: chartType === ChartType.Table ? 1 : undefined,
                        pageSize: chartType === ChartType.Table ? 20 : undefined,
                    },
                    {}
                );

                // 每次点击查询后刷新一下 history 列表
                (referRef.current as any)?.getHistory?.();
            },
        }
    );

    const [staticData, setStaticData] = useState<any[]>([]);

    const { run: getData, loading: getDataLoading } = useRequest(
        postIntelligentPartnerAiAnalysisTextToSqlResultFindAll,
        {
            manual: true,
            onSuccess: (res) => {
                setStaticData(res.data || []);
                if (chartType === ChartType.Table) {
                    setPagination({
                        total: res.paging?.total || 0,
                        current: res.paging?.pageNum || 1,
                        pageSize: res.paging?.pageSize || 20,
                    });
                }
            },
        }
    );

    const onSearch = (params: string) => {
        setCurPrompt(params);
        setChartType(ChartType.Table);
        getSql(params);
    };

    const [chartType, setChartType] = useState(ChartType.Table);

    const onChartTypeChange = (v) => {
        setChartType(v);
        if (v === ChartType.Table) {
            setPagination({
                total: 0,
                current: 1,
                pageSize: 20,
            });
        } else {
            getData(
                {
                    sql: curSql,
                    columnEnumMap: curColumnEnumMap,
                },
                {}
            );
            echartInstance && echartInstance?.clear();
        }
    };

    const domInstance = useRef(null);
    const [echartInstance, setEchartInstance] = useState<any>(null);

    useEffect(() => {
        if (chartType !== ChartType.Table) {
            const c = echarts.init(domInstance.current);
            setEchartInstance(c);
        }
    }, [chartType]);

    const { chartOption } = useChartOption({
        xField: curXfield,
        yFields: curYfields,
        staticData,
        chartType,
    });

    useEffect(() => {
        if (echartInstance && chartOption) {
            echartInstance.setOption(chartOption);
        }
    }, [echartInstance, chartOption]);

    const { ref: wrapperRef, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        requestIdleCallback(() => {
            echartInstance?.resize?.();
        });
    }, [width, height, echartInstance]);

    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 20,
    });

    const getTableSource = (data) => {
        const { pagination } = data;

        curSql &&
            getData(
                {
                    sql: curSql,
                    columnEnumMap: curColumnEnumMap,
                    pageNum: pagination.current,
                    pageSize: pagination.pageSize,
                },
                {}
            );
    };

    const tableProps = useMemo(() => {
        const columns = [curXfield, ...curYfields]
            .filter((c) => !!c)
            .map((c) => {
                return {
                    title: c,
                    key: c,
                    dataIndex: c,
                };
            });

        return {
            columns,
            dataSource: staticData,
            pagination,
        };
    }, [curXfield, curYfields, pagination, staticData]);

    const [exportLoading, setExportLoading] = useState(false);

    const onExport = () => {
        if (curSql) {
            setExportLoading(true);
            postIntelligentPartnerAiAnalysisTextToSqlResultExport({
                sql: curSql,
                columns: [curXfield, ...curYfields].filter((c) => !!c),
                columnEnumMap: curColumnEnumMap,
                systemModule: getSystemModule(),
            })
                .then(() => {
                    message.success(/* 导出成功，请前往任务中心查看 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.index.exportSuccessfulPleaseGoToTheTaskCenterToCheck);
                })
                .finally(() => {
                    setExportLoading(false);
                });
        }
    };

    const onCheckSentence = (p: string) => {
        setCurPrompt(p);
    };

    const [historyId, setHistoryId] = useState<number>();

    const onCheckHistory = (h: IntelligentPartnerAiTextToSqlFoundResponse) => {
        setHistoryId(h.id);
        setCurPrompt(h.title || '');
        setCurTitle(h.title || '');
        setCurSql(h.sql || '');
        setCurColumnEnumMap(h.columnEnumMap);
        setCurXfield(h?.xcolumns?.[0] || '');
        setCurYfields(h?.ycolumns || []);
        setChartType(ChartType.Table);
        if (chartType !== ChartType.Table) {
            setPagination({
                total: 0,
                current: 1,
                pageSize: 20,
            });
        } else {
            getData(
                {
                    sql: h.sql || '',
                    columnEnumMap: h.columnEnumMap,
                    pageNum: 1,
                    pageSize: 20,
                },
                {}
            );
        }
    };

    return (
        <Page
            pageClassName="intelligent-analysis-page"
            pageBodyClassName="intelligent-analysis-page-body"
            backGroundGhost={true}
            title={
                <Search
                    onSearch={onSearch}
                    loading={getSqlloading || getDataLoading}
                    prompt={curPrompt}
                />
            }
            loading={getSqlloading || getDataLoading}
            loadingTip={/* 数据查询中... */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.index.dataQueryInProgress}
        >
            <Wrapper ref={wrapperRef}>
                <Sider>
                    <Refer
                        onCheckSentence={onCheckSentence}
                        onCheckHistory={onCheckHistory}
                        referRef={referRef}
                        setHistoryId={setHistoryId}
                    />
                </Sider>

                <Main>
                    <Header
                        pageTitle={curTitle}
                        subTitle={curSql}
                        chartType={chartType}
                        onChartTypeChange={onChartTypeChange}
                        chartDisable={!curXfield}
                        pieChartDisabled={curYfields.length > 1}
                        onExport={onExport}
                        exportLoading={exportLoading}
                        historyId={historyId}
                        curSql={curSql}
                    />

                    <Content>
                        {chartType === ChartType.Table ? (
                            <StandardizeTable.RequestTable
                                onRequest={getTableSource}
                                table={tableProps}
                            />
                        ) : (
                            <Chart ref={domInstance}></Chart>
                        )}
                    </Content>
                </Main>
            </Wrapper>
        </Page>
    );
});

export default Template;
