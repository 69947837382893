import React from 'react';
import Udesk from 'Udesk';
import { Tag } from 'udesk-ui';
import styled from 'styled-components';
import Locales from 'UdeskLocales';
// import _groupBy from 'lodash-es/groupBy';
import { InspectionPoint, SmartTag } from 'src/api/types';

const DebugInfoWrap = styled.div`
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.05);
    /* border: 1px solid #91d5ff; */
    border-radius: 3px;
    width: 300px;
    margin-top: 4px;
    display: flex;
    cursor: pointer;

    .left {
        color: rgba(0, 0, 0, 0.45);
        margin-right: 8px;
        div {
            text-align: end;
        }
    }
    .right {
        flex: 1;
        overflow: hidden;
        div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .udesk-qa-ui-tag {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

type DebugInfoProps =
    | (SmartTag & {
          debugInfoType:
              | 'customerTagMatchResult'
              | 'keyEventMatchResult'
              | 'knowledgePointMatchResult'
              | 'smartTagMatchResult';
      })
    | (InspectionPoint & {
          debugInfoType: 'supervisionMatchResult';
      });

const DebugInfo = React.memo<DebugInfoProps>((props) => {
    const locales = Locales['current'];
    const rules = props.rules ? JSON.parse(props.rules) : null;
    const judgeStrategy = rules ? rules[0].judgeStrategy : null;
    const formula = (rules ? rules[0].formula : '').replace(/\|\|/g, /* 或 */locales.components.chatRecord.chatRecordItem.components.debugInfo.or).replace(/&&/g, /* 和 */locales.components.chatRecord.chatRecordItem.components.debugInfo.and).replace(/!/g, /* 非 */locales.components.chatRecord.chatRecordItem.components.debugInfo.wrong);
    const info = getDebugInfoParams(props);
    const openConditionPage = () => {
        window.open(info.path + info.pathParams.id);
    };

    return (
        <DebugInfoWrap
            className='qa-sales-debug-info'
            onClick={openConditionPage}
        >
            <div className='left'>
                <div>{/* 匹配到 */}{locales.components.chatRecord.chatRecordItem.components.debugInfo.matchTo}{info.debugInfoTypeLabel}</div>
                <div>{/* 规则 */}{locales.components.chatRecord.chatRecordItem.components.debugInfo.rule}</div>
                {judgeStrategy === Udesk.enums.judgeStrategy.arbitrarily.id && <div>{/* 满足算子 */}{locales.components.chatRecord.chatRecordItem.components.debugInfo.satisfactionOperator}</div>}
            </div>
            <div className='right'>
                <Tag color='blue'>{props.debugInfoType === 'supervisionMatchResult' ? props.name : props.tagName}</Tag>
                <div style={{ marginTop: '-6px' }}>
                    {judgeStrategy === Udesk.enums.judgeStrategy.custom.id
                        ? formula
                        : Udesk.enums.judgeStrategy.getName(judgeStrategy)}
                </div>
                {judgeStrategy === Udesk.enums.judgeStrategy.arbitrarily.id && (
                    <div>
                        {props.debugInfoType === 'supervisionMatchResult'
                            ? props.debugModeConditionList?.[0].idCode
                            : props.conditionList?.[0].idCode}
                    </div>
                )}
            </div>
        </DebugInfoWrap>
    );
});

const DebugInfoList = (props) => {
    const { list = [] } = props;
    return (
        <div>
            {list.map((item) => (
                <DebugInfo {...item} />
            ))}
        </div>
    );
};

export { DebugInfo, DebugInfoList };

function getDebugInfoParams(item) {
    const locales = Locales['current'];

    const map = {
        customerTagMatchResult: {
            debugInfoTypeLabel: /* 客户标签 */locales.components.chatRecord.chatRecordItem.components.debugInfo.customerLabel,
            routeName: 'customerLabelTagsEdit',
            path: '/site/intelligent-analysis/intelligent-tags/customer-label/edit/',
            pathParams: {
                id: item.id,
            },
        },
        keyEventMatchResult: {
            debugInfoTypeLabel: /* 关键事件 */locales.components.chatRecord.chatRecordItem.components.debugInfo.keyEvents,
            routeName: 'keyEventsTagsEdit',
            path: '/site/intelligent-analysis/intelligent-tags/key-events/edit/',
            pathParams: {
                id: item.id,
            },
        },
        knowledgePointMatchResult: {
            debugInfoTypeLabel: /* 知识点 */locales.components.chatRecord.chatRecordItem.components.debugInfo.knowledgePoints,
            routeName: 'gongKnowledgeBaseDetail',
            path: '/site/sale-knowledge-base/detail/',
            pathParams: {
                id: item.id,
            },
        },
        smartTagMatchResult: {
            debugInfoTypeLabel: /* 会话标签 */locales.components.chatRecord.chatRecordItem.components.debugInfo.sessionLabel,
            routeName: 'sessionLabelTagsEdit',
            path: '/site/intelligent-analysis/intelligent-tags/session-label/edit/',
            pathParams: {
                id: item.id,
            },
        },
        supervisionMatchResult: {
            debugInfoTypeLabel: /* 监督项 */locales.components.chatRecord.chatRecordItem.components.debugInfo.supervisionItem,
            path: '/site/intelligent-analysis/intelligent-tags/supervision/edit/',
            routeName: 'supervisionDetail',
            pathParams: {
                id: item.id,
            },
        },
    };

    return map[item.debugInfoType];
}
