/*
    The default configurations are applied in Udesk.init().
*/

import Locales from "../../locales/index";

export default {
    defaults: {}
};

export function bootstrapDurationPickerDefaults() {
    let locale = Locales.current.ui.widgets.bootstrapDurationPicker;
    return {
        // defines whether to show seconds or not
        showSeconds: true,

        // defines whether to show days or not
        showDays: true,

        // callback function that triggers every time duration is changed 
        //   value - duration in seconds
        //   isInitializing - bool value
        // onChanged: function (value, isInitializing) {

        //     // isInitializing will be `true` when the plugin is initialized for the
        //     // first time or when it's re-initialized by calling `setValue` method
        //     console.log(value, isInitializing);
        // }
        translations: {
            day: locale.day,
            hour: locale.hour,
            minute: locale.minute,
            second: locale.second,
            days: locale.days,
            hours: locale.hours,
            minutes: locale.minutes,
            seconds: locale.seconds,
        }
    };
}
