import React, { useEffect, useMemo, useState } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import { Button, Space, Table } from 'udesk-ui';
import './style.scss';
// import { getRoles } from 'src/api/roles';
import { getRolesById } from 'src/api/roles/{id}';

const locales = Locales['current'];

const AdminGongRole = React.memo((props: any) => {
    const currentId = parseInt(props.match.params.id, 10);
    const sysModule = parseInt(props.match.params.sysModule, 10);

    // const [roleList, setRoleList] = useState<any[]>([]);
    const [dataDetail, setDataDetail] = useState<any>({});
    const [menuList, setMenuList] = useState<any[]>([]);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [dataMainMap, setDataMainMap] = useState({});
    const [dataSecMap, setDataSecMap] = useState({});
    // const [currentTabIdx, setCurrentTabIdx] = useState(0);
    // const [currentId, setCurrentId] = useState<any>();
    const columns = useMemo(() => {
        return [
            {
                title: locales.pages.gong.roleManage.list.menu,
                dataIndex: 'mainMenu',
                key: 'mainMenu',
                render: (text, record, index) => {
                    let rowSpan = 1;
                    if (index === dataMainMap[record?.mainMenu?.id]?.[0]) {
                        rowSpan =
                            dataMainMap[record.mainMenu?.id]?.[1] -
                            dataMainMap[record.mainMenu?.id]?.[0] +
                            1;
                    } else {
                        rowSpan = 0;
                    }
                    return {
                        children: <span>{record?.mainMenu?.name}</span>,
                        props: {
                            rowSpan,
                        },
                    };
                },
            },
            {
                title: locales.pages.gong.roleManage.list.functionalCategory,
                dataIndex: 'secondMenu',
                key: 'secondMenu',
                render: (text, record, index) => {
                    let rowSpan = 1;
                    if (record.secondMenu) {
                        if (index === dataSecMap[record.secondMenu?.id]?.[0]) {
                            rowSpan =
                                dataSecMap[record.secondMenu?.id]?.[1] -
                                dataSecMap[record.secondMenu?.id]?.[0] +
                                1;
                        } else {
                            rowSpan = 0;
                        }
                    }
                    return {
                        children: <span>{record?.secondMenu?.name}</span>,
                        props: {
                            rowSpan,
                        },
                    };
                },
            },
            {
                title: locales.pages.gong.roleManage.list.functionalSubclass,
                dataIndex: 'thirdMenu',
                key: 'thirdMenu',
                render: (text, record, index) => {
                    let permissionData: any = [];
                    record?.secondMenu?.childItems?.forEach((item) => {
                        menuList.forEach((data) => {
                            if (data.id === item.id) {
                                permissionData.push(item);
                            }
                        });
                    });
                    let rowSpan = 1;
                    if (record.secondMenu) {
                        if (index === dataSecMap[record.secondMenu?.id]?.[0]) {
                            rowSpan =
                                dataSecMap[record.secondMenu?.id]?.[1] -
                                dataSecMap[record.secondMenu?.id]?.[0] +
                                1;
                        } else {
                            rowSpan = 0;
                        }
                    }
                    return {
                        children: permissionData?.map(
                            (item) =>
                                item.moduleType === sysModule && (
                                    <span style={{ margin: '0 10px' }}>{item.name}</span>
                                )
                        ),
                        props: {
                            rowSpan,
                        },
                    };
                },
            },
        ];
    }, [dataMainMap, dataSecMap, menuList]);
    const handlerEditRole = (id) => {
        let routeOptions = {
            history: props.history,
            routeName: 'generalSettingRoleEdit',
            pathParams: { id, sysModule },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    // const getRolesData = (id?) => {
    //     getRoles().then((res) => {
    //         setRoleList(res.data ?? []);
    //         currentRolesData(id ? id : res.data?.[0]?.id);
    //         setCurrentId(id ? id : res.data?.[0]?.id);
    //     });
    // };
    const currentRolesData = (id) => {
        getRolesById({ segments: { id } }).then((res) => {
            setDataDetail(res.data);
            setMenuList(res?.data?.menuList ?? []);
            let dataSource: any = [];
            Udesk.data.adminMenuList.root.forEach((item, index) => {
                if (item.childItems && item.childItems.length) {
                    item.childItems.forEach((record) => {
                        if (record.moduleType === sysModule) {
                            dataSource.push({
                                mainMenu: {
                                    ...item,
                                },
                                secondMenu: {
                                    ...record,
                                },
                            });
                        }
                    });
                } else {
                    if (item.moduleType === sysModule) {
                        dataSource.push({
                            mainMenu: {
                                ...item,
                            },
                            secondMenu: null,
                        });
                    }
                }
            });
            let dataMainMap = {};
            let dataSecMap = {};
            let newDataSource: any = [];
            dataSource.forEach((item, index) => {
                if (item?.secondMenu) {
                    res?.data?.menuList?.forEach((record) => {
                        if (item?.secondMenu?.id === record?.id) {
                            newDataSource.push(item);
                        }
                    });
                } else {
                    res?.data?.menuList?.forEach((record) => {
                        if (item?.mainMenu?.id === record?.id) {
                            newDataSource.push(item);
                        }
                    });
                }
            });
            newDataSource.forEach((sin, index) => {
                if (dataMainMap[sin.mainMenu?.id]) {
                    dataMainMap[sin.mainMenu?.id][1] = index;
                } else {
                    dataMainMap[sin.mainMenu?.id] = [index, index];
                }

                if (dataSecMap[sin.secondMenu?.id]) {
                    dataSecMap[sin.secondMenu?.id][1] = index;
                } else {
                    dataSecMap[sin.secondMenu?.id] = [index, index];
                }
            });
            setDataMainMap(dataMainMap);
            setDataSecMap(dataSecMap);
            setDataSource(newDataSource);
        });
    };
    // const handlerToggle = (idx, item) => {
    //     setCurrentTabIdx(idx);
    //     getRolesData(item.id);
    //     // currentRolesData(item.id);
    //     // setCurrentId(item.id);
    // };
    // const deleteRoleData = () => {
    //     deleteRolesById({
    //         segments: { id: currentId },
    //         successMsg: locales.pages.gong.roleManage.list.deletedSuccessfully,
    //     }).then((res) => {
    //         // setCurrentTabIdx(0);
    //         // getRolesData();
    //     });
    // };
    useEffect(() => {
        currentRolesData(currentId);
    }, [currentId]);
    return (
        <Page
            pageBodyClassName="page-role-manage-center"
            title={props.hiddenPageHeader ? '' : locales.menu.admin.role.text}
            padding={true}
            onBack={() => window.history.back()}
            // extra={<Button type='primary' onClick={handlerEditRole.bind(null, -1)}>{locales.pages.gong.roleManage.list.NewRole}</Button>}
        >
            <div className="page-role-manage-container">
                {/* <div className='role-frame'>
                    <h3>{locales.pages.gong.roleManage.list.role}</h3>
                    {
                        roleList.map((item, idx) => {
                            return <p className={currentTabIdx === idx ? 'active role-tabs' : 'role-tabs'} onClick={handlerToggle.bind(null, idx, item)} key={item.id}>{item.name}</p>;
                        })
                    }
                </div> */}
                <div className="role-frame-content">
                    <div className="role-frame-content-actions">
                        <Space className="actions-title">
                            <h3>{dataDetail.name}</h3>
                            {sysModule === Udesk.enums.sysModuleList.sales.id && (
                                <span>
                                    {locales.pages.gong.roleManage.list.dataPermissions}
                                    {
                                        Udesk.enums.roleType.filter(
                                            (item) => item.id === dataDetail?.dataPermissionLevel
                                        )?.[0]?.name
                                    }
                                </span>
                            )}
                        </Space>
                        <Space>
                            <Button onClick={handlerEditRole.bind(null, dataDetail.id)}>
                                {locales.pages.gong.roleManage.list.edit}
                            </Button>
                            {/* <Popconfirm
                                title={`${locales.pages.gong.roleManage.list.areYouSureYouWantToDeleteTheRole}${dataDetail.name}？`}
                                onConfirm={deleteRoleData}
                                okText={locales.pages.gong.roleManage.list.determine}
                                cancelText={locales.pages.gong.roleManage.list.cancel}
                            >
                                <Button>{locales.pages.gong.roleManage.list.delete}</Button>
                            </Popconfirm> */}
                        </Space>
                    </div>
                    <div className="page-role-frame-scroll role-frame-detail">
                        <h3>{locales.pages.gong.roleManage.list.applicationPermission}</h3>
                        <div className="page-role-manage-container level-child">
                            <Table
                                bordered
                                pagination={false}
                                style={{ flex: 1 }}
                                dataSource={dataSource}
                                columns={columns}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <AdminGongRole {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
