var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
export var Layout = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));
export var FilterWrap = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-bottom: 12px;\n"])));
export var RowSelectionInfo = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  span {\n    color: rgba(44, 124, 246, 1);\n  }\n"])));
export var TableWrap = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding: ", ";\n  background-color: #fff;\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  ", " {\n    margin-bottom: 0;\n  }\n"])), function (props) {
  return typeof props.padding === 'number' ? props.padding + 'px' : props.padding;
}, function (props) {
  return ".".concat(props.prefixCls, "-pagination");
});
export var ClassificationWrap = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  padding: 0 8px;\n  margin-right: 16px;\n  border-right: 1px solid rgba(0, 0, 0, 0.1);\n  background-color: #fff;\n  width: 320px;\n  @media (max-width: 1440px) {\n    width: 240px;\n  }\n  overflow-x: hidden;\n"])));
export var Toolbar = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-bottom: 16px;\n  /* justify-content: flex-end; */\n  display: ", ";\n  button {\n    color: rgba(0, 0, 0, 0.65);\n  }\n  .", "-btn-primary {\n    color: #fff;\n  }\n  .", "-btn-link {\n    color: #1b6dff;\n  }\n"])), function (props) {
  return props.visible ? 'flex' : 'none';
}, function (props) {
  return props.prefixCls;
}, function (props) {
  return props.prefixCls;
});