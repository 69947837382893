// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetCaseLibraryCategorysFromccQuery, BaseResponseListResult } from '../../types';

/**
 * 客服系统案例库-列表
 *
 * @export
 * @tags 案例库同步
 * @link [GET] /caseLibraryCategorys/fromcc
 */
export function getCaseLibraryCategorysFromcc(): Promise<BaseResponseListResult>;
export function getCaseLibraryCategorysFromcc(
    options: ApiOptions<never, GetCaseLibraryCategorysFromccQuery>
): Promise<BaseResponseListResult>;
export function getCaseLibraryCategorysFromcc(
    options?: ApiOptions<never, GetCaseLibraryCategorysFromccQuery>
): Promise<BaseResponseListResult> {
    return request<never, never, GetCaseLibraryCategorysFromccQuery>(
        '/caseLibraryCategorys/fromcc',
        { ...options, method: 'get', __$requestCalleeName: 'getCaseLibraryCategorysFromcc' }
    );
}

export const meta = [{ tags: ['案例库同步'], path: '/caseLibraryCategorys/fromcc', method: 'get' }];
