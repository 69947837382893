import SmartWordsLibraryCommonListComponent from './component';
import Udesk from 'Udesk';
class SmartWordsLibraryCommonListRoute extends SmartWordsLibraryCommonListComponent {
    model(changedParams) {

        let {
            sdkOptions,
            getlistDataPath,
            getlistDataMethod,
        } = this.props;

        let {
            pageNum,
            pageSize,
            keywords,
        } = this.privates;

        let getListUrl = Udesk.business.apiPath.concatApiPath(getlistDataPath, sdkOptions);

        let params = {
            pageNum,
            pageSize,
            keywords,
        };
        if (changedParams) {
            Object.assign(params, changedParams);
        }
        let that = this;
        this.privates.loading = true;
        this.actions.update();
        let realPromise = new Promise((resolve, reject) => {
            Udesk.ajax[getlistDataMethod](getListUrl, params).then(function (response) {
                resolve(response);
                that.privates.loading = false;
                that.actions.update();        
                if (response.paging) {
                    that.privates.pageNum = response.paging.pageNum;
                    that.privates.pageSize = response.paging.pageSize;
                    that.privates.total = response.paging.total;
                    that.actions.update();
                }
            }, function (reason) {
                Udesk.ui.notify.error(reason.errorMsg);
                reject(reason);
            });
        });

        return { responseData: realPromise };
    }

    parseModel(res) {
        let { responseData } = res;
        let smartWordsLibraryListData = responseData.data;

        return {
            smartWordsLibraryListData
        };
    }
}
export default SmartWordsLibraryCommonListRoute;
