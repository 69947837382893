import UdeskLocales from "UdeskLocales";
import Udesk from "Udesk";
import React from "react";
import PropTypes from "prop-types";


export default class CheckPointConditionComponent extends React.Component {
    //#region defaultProps
    static defaultProps = {
        onChange: null,
        condition: {},
        conditionTypeList: [],
        type: [],
        index: null,

    };
    //#endregion

    //#region propTypes
    static propTypes = {
        onChange: PropTypes.func,
        condition: PropTypes.object,
        conditionTypeList: PropTypes.array,
        type: PropTypes.array,
        index: PropTypes.number,

    };
    actions = {
        ruleTypeChange(conditionItem, value) {
            Object.keys(conditionItem).forEach(key => {
                if (
                    key !== "_id" &&
                    key !== "operatorType" &&
                    key !== "taskId" &&
                    key !== "id" &&
                    key !== "idCode" &&
                    key !== "operator" &&
                    key !== "applyRole" &&
                    key !== "operatorList" &&
                    key !== "param"
                ) {
                    delete conditionItem[key];
                }
            });
            conditionItem.conditionType = value;
            conditionItem.operatorType = this.actions.setDefaultType(value);
            conditionItem.operatorList[0].type = this.actions.setDefaultType(value);
            conditionItem.operatorList[0].applyOperatorScope = {
                id: Udesk.enums.applyOperatorScopes.all.id,
                key: Udesk.enums.applyOperatorScopes.all.key
            };
            conditionItem.applyConditionHitType = Udesk.enums.preOperatorHitTypes.first.id;
            conditionItem.operatorList[0].operator = "all";
            conditionItem.operatorList[0].applyRole = "all";
            if (!conditionItem.operatorList[0].param) {
                conditionItem.operatorList[0].param = {};
            }
            conditionItem.operatorList[0].param.type = this.actions.setDefaultType(value);
            this.actions.handleConditionItemChange();
        },
        setDefaultType(key) {
            let type = 0;
            if (key === 1) {
                type = this.privates.operatorTypesOfWords[0].id;
            } else if (key === 2) {
                type = this.privates.operatorTypesOfInteraction[0].id;
            } else if (key === 3) {
                type = this.privates.operatorTypesOfFeature[0].id;
            } else if (key === 4) {
                type = this.privates.operatorTypesOfInformation[0].id;
            }
            return type;
        },
        handleConditionItemChange() {
            this.actions.update();
            this.trigger("onChange", this.privates.conditionItem);
        },
        getGearOptionList(conditionItem) {
            this.privates.conditionItem = conditionItem;
            this.actions.handleConditionItemChange();
        },
        selectMenuItem(type, item, key) {
            let { conditionItem } = this.privates;
            if (type === 'applyRole') {
                conditionItem[type] = item.key;
            }
            if (type === 'applyConditionIdCode') {
                conditionItem[type] = item.key === 'item_0' ? null : item.key;
                // conditionItem.applyConditionIdCode = item.key === 'item_0' ? null : item.key;
                conditionItem.applyConditionHitType = Udesk.enums.preOperatorHitTypes.first.id;
            }
            if (type === 'applyConditionHitType') {
                let opera = Udesk.enums.preOperatorHitTypes.find(opera => {
                    return opera.id === parseInt(item.key, 10);
                });
                conditionItem[type] = opera.id;
                //未批配情况下 重置后续选项，检测范围隐藏前置条件选项
                if (conditionItem.applyConditionHitType === Udesk.enums.preOperatorHitTypes.none.id) {
                    conditionItem.applyScopeStart = null;
                    conditionItem.applyScopeEnd = null;
                    conditionItem.applyConditionLimit = null;
                    conditionItem.applyConditionScope = null;
                    conditionItem.operatorList[0].applyOperatorScope = {
                        key: Udesk.enums.applyOperatorScopes.all.key,
                        id: Udesk.enums.applyOperatorScopes.all.id
                    };
                }
            }
            if (type === 'applyOperatorScope') {
                //前端自用属性，后端不保存，使用applyScopeStart，applyScopeEnd的有无判断
                conditionItem.applyScopeStart = null;
                conditionItem.applyScopeEnd = null;
                conditionItem.applyConditionLimit = null;
                conditionItem.applyConditionScope = null;

                let opera = Udesk.enums.applyOperatorScopes.find(opera => {
                    return opera.id === parseInt(item.key, 10);
                });
                conditionItem.operatorList[0][type] = {
                    key: opera.key,
                    id: opera.id
                };
                if (conditionItem.operatorList[0][type].id === Udesk.enums.applyOperatorScopes.preCondition.id) {
                    conditionItem.applyConditionScope = Udesk.enums.applyConditionScopes.current.id;
                }
                if (conditionItem.operatorList[0][type].id === Udesk.enums.applyOperatorScopes.all.id) {
                    conditionItem.applyScopeStart = null;
                    conditionItem.applyScopeEnd = null;
                    conditionItem.applyConditionLimit = null;
                    conditionItem.applyConditionScope = null;
                }
            }
            if (type === 'applyConditionScope') {
                let opera = Udesk.enums.applyConditionScopes.find(opera => {
                    return opera.id === item;
                });
                conditionItem[type] = opera.id;
            }
            this.actions.handleConditionItemChange();
        },
        changeInputValue(conditionItem, type, value) {
            conditionItem[type] = value;
            this.actions.handleConditionItemChange();
        },
        validateRangeValue(condition, attribute, value) {
            if (value === '0' || value === 0) {
                condition[attribute] = '';
                this.actions.handleConditionItemChange();
                return Udesk.ui.notify.error(UdeskLocales.current.components.checkRange.notZero);
            }

            if (condition.applyScopeStart && condition.applyScopeEnd) {
                if (parseInt(condition.applyScopeStart, 10) > parseInt(condition.applyScopeEnd, 10)) {
                    condition[attribute] = '';
                    this.actions.handleConditionItemChange();
                    return Udesk.ui.notify.error(UdeskLocales.current.components.checkRange.startMustLessThanEnd);
                }
            }
        },
        validateGrabInterval(operatorItem, attribute, value) {
            if (operatorItem[attribute] == null || operatorItem[attribute] === "" || operatorItem[attribute] === 0) {
                return Udesk.ui.notify.error(
                    UdeskLocales.current.components.checkRange.notZero
                );
            }
            if (operatorItem.applyScopeStart && operatorItem.applyScopeEnd) {
                if (parseInt(operatorItem.applyScopeStart, 10) > parseInt(operatorItem.applyScopeEnd, 10)) {
                    operatorItem[attribute] = '';
                    this.actions.update();
                    return Udesk.ui.notify.error(UdeskLocales.current.components.checkRange.startMustLessThanEnd);
                }
            }

        },
    };

    parseProps({ props, prevProps, state, privates, isInitial }) {
        let needTrigger = false;
        let conditionItem = JSON.parse(JSON.stringify(props.condition));

        if (isInitial) {
            if (!conditionItem.operatorType) {
                let key = conditionItem.conditionType;
                conditionItem.operatorType = this.actions.setDefaultType(key);
                conditionItem.operatorList[0].type = this.actions.setDefaultType(key);
                conditionItem.operatorList[0].param = { type: this.actions.setDefaultType(key) };
                needTrigger = true;
            }
            //applyOperatorScope 是前端根据applyScopeStart，applyScopeEnd，applyConditionLimit判断出的属性，用于回显范围
            if (!conditionItem.operatorList[0].applyOperatorScope) {
                let applyOperatorScope = {
                    key: Udesk.enums.applyOperatorScopes.all.key,
                    id: Udesk.enums.applyOperatorScopes.all.id,
                    name: Udesk.enums.applyOperatorScopes.all.name,
                };
                if (conditionItem.applyConditionLimit || conditionItem.applyConditionScope) {
                    applyOperatorScope = {
                        key: Udesk.enums.applyOperatorScopes.preCondition.key,
                        id: Udesk.enums.applyOperatorScopes.preCondition.id,
                        name: Udesk.enums.applyOperatorScopes.preCondition.name,
                    };
                }
                if (conditionItem.applyScopeStart || conditionItem.applyScopeEnd) {
                    applyOperatorScope = {
                        key: Udesk.enums.applyOperatorScopes.scope.key,
                        id: Udesk.enums.applyOperatorScopes.scope.id,
                        name: Udesk.enums.applyOperatorScopes.scope.name,
                    };
                }
                conditionItem.operatorList[0].applyOperatorScope = applyOperatorScope;
                needTrigger = true;
            }
        }
        if (
            !(conditionItem.operatorType === Udesk.enums.operatorTypes.questionsAndAnswers.id
                || conditionItem.operatorType === Udesk.enums.operatorTypes.element.id
                || conditionItem.operatorType === Udesk.enums.operatorTypes.conceptWord.id
                || conditionItem.operatorType === Udesk.enums.operatorTypes.sentenceLength.id
                || conditionItem.operatorType === Udesk.enums.operatorTypes.emotion.id
                || conditionItem.operatorType === Udesk.enums.operatorTypes.volume.id
                || conditionItem.operatorType === Udesk.enums.operatorTypes.entityInfoDetection.id
                || conditionItem.operatorType === Udesk.enums.operatorTypes.associate.id
                || conditionItem.operatorType === Udesk.enums.operatorTypes.repeat.id)
            && conditionItem.operatorList[0].param
        ) {
            conditionItem.operatorList[0].param = null;
            needTrigger = true;
        }
        needTrigger && this.trigger("onChange", conditionItem);
        return {
            conditionItem: conditionItem
        };
    }
    componentDidMount() { }
    init() {
        const { props } = this;

        //区别语音、文本、实时语音、实时文本，过滤算子类型
        let operatorTypes = Udesk.enums.operatorTypes.filter(item => item !== null);

        if (
            props.type.includes(Udesk.enums.inspectDataSources.textDialogue.id) ||
            props.type.includes(Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
        ) {
            operatorTypes = operatorTypes.filter(type => {
                return (
                    type.id !== Udesk.enums.operatorTypes.question.id &&
                    type.id !== Udesk.enums.operatorTypes.speed.id &&
                    type.id !== Udesk.enums.operatorTypes.volume.id &&
                    type.id !== Udesk.enums.operatorTypes.emotion.id &&
                    type.id !== Udesk.enums.operatorTypes.grabDetection.id
                );
            });
        }
        if (
            props.type.includes(Udesk.enums.inspectDataSources.realTimeVoiceCall.id) ||
            props.type.includes(Udesk.enums.inspectDataSources.realTimeTextDialogue.id)
        ) {
            operatorTypes = operatorTypes.filter(type => {
                return (
                    type.id !== Udesk.enums.operatorTypes.keyword.id &&
                    type.id !== Udesk.enums.operatorTypes.semantics.id &&
                    type.id !== Udesk.enums.operatorTypes.semanticTags.id &&
                    type.id !== Udesk.enums.operatorTypes.entityInfoDetection.id &&
                    type.id !== Udesk.enums.operatorTypes.grammar.id
                );
            });
        }
        let operatorTypesOfWords = operatorTypes.filter(type => {
            return (
                type.id === Udesk.enums.operatorTypes.semanticTags.id ||
                type.id === Udesk.enums.operatorTypes.conceptWord.id ||
                type.id === Udesk.enums.operatorTypes.keywordStrict.id
            );
        });
        let operatorTypesOfInteraction = operatorTypes.filter(type => {
            return (
                type.id === Udesk.enums.operatorTypes.questionsAndAnswers.id ||
                type.id === Udesk.enums.operatorTypes.element.id ||
                type.id === Udesk.enums.operatorTypes.grabDetection.id ||
                type.id === Udesk.enums.operatorTypes.repeat.id ||
                type.id === Udesk.enums.operatorTypes.dialogue.id
            );
        });
        let operatorTypesOfFeature = operatorTypes.filter(type => {
            return (
                type.id === Udesk.enums.operatorTypes.question.id ||
                type.id === Udesk.enums.operatorTypes.sentenceLength.id ||
                type.id === Udesk.enums.operatorTypes.speed.id ||
                type.id === Udesk.enums.operatorTypes.volume.id ||
                type.id === Udesk.enums.operatorTypes.emotion.id

            );
        });
        let operatorTypesOfInformation = operatorTypes.filter(type => {
            return (
                type.id === Udesk.enums.operatorTypes.entityInfoDetection.id ||
                type.id === Udesk.enums.operatorTypes.associate.id
                // type.id === Udesk.enums.operatorTypes.entityInfoDetection.id
            );
        });

        this.privates.operatorTypesOfWords = operatorTypesOfWords;
        this.privates.operatorTypesOfInteraction = operatorTypesOfInteraction;
        this.privates.operatorTypesOfFeature = operatorTypesOfFeature;
        this.privates.operatorTypesOfInformation = operatorTypesOfInformation;
    }
}