export const onCopyText = (copyContent: string, callback?: () => void) => {
    // 1. 创建并添加一个输入框元素(最后会销毁)
    const textareaEle = document.createElement('textarea');
    document.body.appendChild(textareaEle);
    // 2. 将需要复制的文本传入输入框, 并调用 select 方法, 选中输入框中文本
    textareaEle.value = copyContent;
    textareaEle.select();
    textareaEle.readOnly = true;
    // 3. 调用复制选中文本的方法
    document.execCommand('copy');
    // 4. 销毁输入框
    document.body.removeChild(textareaEle);
    callback?.();
};
