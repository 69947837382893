import React, { FC, memo } from 'react';
import { Select, Space } from 'udesk-ui';
import { DynamicInput } from './components/DynamicInput';
import { TypeEnum } from '../../const';
import { DivideRule } from 'src/api/types';
import UdeskLocales from 'UdeskLocales';

export type TemplatePropsValue = {
    type?: number;
    divideRuleList?: DivideRule[];
};

type TemplateProps = {
    value?: TemplatePropsValue;
    onChange: (v: TemplateProps['value']) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange } = props;

    const onTypeChange = (v) => {
        onChange({
            ...value,
            type: v,
        });
    };

    const onEvaluationChange = (v) => {
        onChange({
            ...value,
            divideRuleList: v,
        });
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Select
                value={value?.type}
                options={[
                    {
                        label: /* 是否符合 */UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.typeSelect.index.whetherItMeetsTheRequirements,
                        value: TypeEnum.Conform,
                    },
                    {
                        label: /* 分档评价 */UdeskLocales['current'].pages.semanticIntelligence.intelligentModel.components.typeSelect.index.gradedEvaluation,
                        value: TypeEnum.GradedEvaluation,
                    },
                ]}
                style={{ width: 200 }}
                onChange={onTypeChange}
            />

            {value?.type === TypeEnum.GradedEvaluation ? (
                <DynamicInput value={value.divideRuleList} onChange={onEvaluationChange} />
            ) : null}
        </Space>
    );
};

export default memo(Template);
