import React, { useContext } from 'react';

const DialogueContext = React.createContext<any>(undefined);

export const DialogueContextProvider = ({ value, children }) => {
    return <DialogueContext.Provider value={value}>{children}</DialogueContext.Provider>;
};

export const useDialogueContext = () => {
    const value = useContext(DialogueContext);
    if (value === undefined) {
        throw new Error('useDialogueContext must be used within a DialogueContextProvider');
    }
    return value;
};
