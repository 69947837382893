import React, { useEffect } from 'react';
import { IntelligentPartnerScoringModelCreateRequest } from 'src/api/types';
import { Button, Form, Input } from 'udesk-ui';
import UdeskLocales from 'UdeskLocales';
const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 16 },
};

export const Wrap = React.memo((props: any) => {
    const { children, pointGroup, onSubmit, name } = props;
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            groups: pointGroup,
        });
    }, [pointGroup]);

    useEffect(() => {
        form.setFieldsValue({
            name,
        });
    }, [name]);

    return (
        <Form
            form={form}
            onFinish={(values) => {
                const params: IntelligentPartnerScoringModelCreateRequest &
                    Required<
                        Pick<
                            IntelligentPartnerScoringModelCreateRequest,
                            'totalScore' | 'modelRuleList'
                        >
                    > = {
                    type: 2,
                    totalScore: 0,
                    modelRuleList: [],
                    name: values.name,
                };
                console.log('values:: ', values);

                values.groups.forEach((group) => {
                    params[group.scoreKey] = group.score;
                    params.totalScore += group.score ? parseInt(group.score, 10) : 0;
                    params.modelRuleList.push(
                        ...group.children
                            .filter((c) => c._enable)
                            .map((c) => ({
                                pointId: c.pointId,
                                name: c.name,
                                appearNum: 1, // 预设的出现次数，默认为1
                                presetScore: c.presetScore, // 预设的扣分值，默认为1
                            }))
                    );
                });

                params.wordsScore = params.wordsScore ?? (null as any);

                onSubmit?.(params);
            }}
            onValuesChange={(changedValues) => {
                if (changedValues?.groups?.[1]?.children?.[0]?._enable) {
                    form.setFields([
                        {
                            name: ['groups', 1, 'children', 1, '_enable'],
                            value: false,
                        },
                    ]);
                }
                if (changedValues?.groups?.[1]?.children?.[1]?._enable) {
                    form.setFields([
                        {
                            name: ['groups', 1, 'children', 0, '_enable'],
                            value: false,
                        },
                    ]);
                }
                if (
                    changedValues?.groups?.[1]?.children?.[0]?.pointId ===
                    pointGroup?.[1]?.children?.[0]?.options?.find((o) => o.pointType === 2)?.value
                ) {
                    form.setFields([
                        {
                            name: ['groups', 1, 'score'],
                            value: undefined,
                        },
                    ]);
                }
            }}
            {...layout}
        >
            <Form.Item
                name="name"
                label={
                    /* 名称 */ UdeskLocales['current'].pages.coach.businessConfiguration
                        .scoreManagement.scoreModel.detail.components.model.wrap.name
                }
            >
                <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.List name="groups">
                {(fields, { remove, add, move }) =>
                    fields.map((field, index) => children(field, pointGroup[index]))
                }
            </Form.List>
            <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    {/* 保存 */}
                    {
                        UdeskLocales['current'].pages.coach.businessConfiguration.scoreManagement
                            .scoreModel.detail.components.model.wrap.preserve
                    }
                </Button>
            </Form.Item>
        </Form>
    );
});
