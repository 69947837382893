// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    BaseResponseListSubjectMarkFileFoundResponse,
    PostSubjectMarkFilesBody,
    BaseResponseSubjectMarkFileFoundResponse,
} from '../types';

/**
 * 查询所有话题标注类别
 *
 * @export
 * @tags 话题标注
 * @link [GET] /subjectMarkFiles
 */
export function getSubjectMarkFiles(): Promise<BaseResponseListSubjectMarkFileFoundResponse>;
export function getSubjectMarkFiles(
    options: ApiOptions<never, never>
): Promise<BaseResponseListSubjectMarkFileFoundResponse>;
export function getSubjectMarkFiles(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListSubjectMarkFileFoundResponse> {
    return request<never, never, never>('/subjectMarkFiles', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getSubjectMarkFiles',
    });
}

/**
 * 保存话题类别
 *
 * @export
 * @tags 话题标注
 * @link [POST] /subjectMarkFiles
 */
export function postSubjectMarkFiles(
    data: PostSubjectMarkFilesBody
): Promise<BaseResponseSubjectMarkFileFoundResponse>;
export function postSubjectMarkFiles(
    data: PostSubjectMarkFilesBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseSubjectMarkFileFoundResponse>;
export function postSubjectMarkFiles(
    data: PostSubjectMarkFilesBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseSubjectMarkFileFoundResponse> {
    return request<PostSubjectMarkFilesBody, never, never>('/subjectMarkFiles', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postSubjectMarkFiles',
    });
}

export const meta = [
    { tags: ['话题标注'], path: '/subjectMarkFiles', method: 'get' },
    { tags: ['话题标注'], path: '/subjectMarkFiles', method: 'post' },
];
