// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetExternalUsersByIdParam,
    BaseResponseExternalUserFoundResponse,
    PutExternalUsersByIdParam,
    PutExternalUsersByIdBody,
    BaseResponseVoid,
    DeleteExternalUsersByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags external-user-controller
 * @link [GET] /externalUsers/{id}
 * @param id
 */
export function getExternalUsersById(
    options: ApiOptions<GetExternalUsersByIdParam, never> & { segments: GetExternalUsersByIdParam }
): Promise<BaseResponseExternalUserFoundResponse> {
    return request<never, GetExternalUsersByIdParam, never>('/externalUsers/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getExternalUsersById',
    });
}

/**
 * update
 *
 * @export
 * @tags external-user-controller
 * @link [PUT] /externalUsers/{id}
 * @param id
 */
export function putExternalUsersById(
    data: PutExternalUsersByIdBody,
    options: ApiOptions<PutExternalUsersByIdParam, never> & { segments: PutExternalUsersByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutExternalUsersByIdBody, PutExternalUsersByIdParam, never>(
        '/externalUsers/{id}',
        { ...options, data, method: 'put', __$requestCalleeName: 'putExternalUsersById' }
    );
}

/**
 * deleteById
 *
 * @export
 * @tags external-user-controller
 * @link [DELETE] /externalUsers/{id}
 * @param id
 */
export function deleteExternalUsersById(
    options: ApiOptions<DeleteExternalUsersByIdParam, never> & {
        segments: DeleteExternalUsersByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteExternalUsersByIdParam, never>('/externalUsers/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteExternalUsersById',
    });
}

export const meta = [
    { tags: ['external-user-controller'], path: '/externalUsers/{id}', method: 'get' },
    { tags: ['external-user-controller'], path: '/externalUsers/{id}', method: 'put' },
    { tags: ['external-user-controller'], path: '/externalUsers/{id}', method: 'delete' },
];
