import React from 'react';
import Udesk from 'Udesk';
import SubRoutes from 'udesk-react/src/components/sub-routes';
import { Redirect } from 'react-router-dom';
import { CommonPage } from 'udesk_gm_ui';

class intelligentTags extends React.Component {
    isRedirect(currentPath, toPath) {
        if (currentPath === toPath) {
            return true;
        }
        return false;
    }

    render() {
        let { routes, route, location, sdkOptions } = this.props;
        return (
            <CommonPage.PageInfoMemo>
                {(() => {
                    if (this.isRedirect(route.path, location.pathname)) {
                        let toPath = Udesk.ui.routing.getRouteFirstBottomedChildRoutePath({
                            routeName: route.name,
                        });
                        return <Redirect to={toPath} sdkOptions={sdkOptions} />;
                    } else {
                        return (
                            <div className='udesk-qa-supervision' style={{ height: '100%' }}>
                                <For each='route' index='i' of={routes}>
                                    <If
                                        condition={
                                            !route.authCode ||
                                            (route.authCode && Udesk.data.init.user.hasFeature(route.authCode))
                                        }
                                    >
                                        <SubRoutes key={i} route={route} />
                                    </If>
                                </For>
                            </div>
                        );
                    }
                })()}
            </CommonPage.PageInfoMemo>
        );
    }
}

export default Udesk.react.udeskify(intelligentTags);
