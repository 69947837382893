import React, { useState, useEffect } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import Page from 'Component/common/udesk-qa-page-layout';
import UdeskPagination from 'Component/common/udesk-pagination';
import './style.scss';
import FolderItem from 'Component/common/gong/files-open/folder-item';
import FileItem from 'Component/common/gong/files-open/file-item';
import FilesTree from 'Component/common/gong/files-open';
import { Menu, Input, Icon, Space, Divider, Modal, Form, Tree } from 'udesk-ui';
import { getLibraryFiles, postLibraryFiles } from 'src/api/libraryFiles';
import { LibraryCallFoundResponse, LibraryFileFoundResponse } from 'src/api/types';
import {
    deleteLibraryFilesById,
    getLibraryFilesById,
    putLibraryFilesById,
} from 'src/api/libraryFiles/{id}';
import { putLibraryFilesMoveById } from 'src/api/libraryFiles/move/{id}';
import { getLibraryCalls } from 'src/api/libraryCalls';
import { deleteLibraryCallsById, putLibraryCallsById } from 'src/api/libraryCalls/{id}';
import { getLibraryFilesSearch } from 'src/api/libraryFiles/search';
import { postLibraryFilesCopy } from 'src/api/libraryFiles/copy';
import { getLibraryFileCollects } from 'src/api/libraryFileCollects';
import {
    deleteLibraryFileCollectsByFileId,
    postLibraryFileCollectsByFileId,
} from 'src/api/libraryFileCollects/{fileId}';
import { putLibraryCallsCopy } from 'src/api/libraryCalls/copy';

const locales = Locales['current'];
const { confirm } = Modal;

interface TreeData extends LibraryFileFoundResponse {
    title?: string;
    key?: number;
    children?: TreeData[];
    selectedKeys?: number[];
    expandedKeys?: number[];
}

const Template = React.memo((props: any) => {
    const [form] = Form.useForm();
    const [userId, setUserId] = useState(Udesk.data.init.user.id);
    const [currentSelect, setCurrentSelect] = useState<TreeData>({});
    const [treeData, setTreeData] = useState<TreeData[]>([]);
    const [moveTreeData, setMoveTreeData] = useState<TreeData[]>([]);
    const [addFolderBool, setAddFolderBool] = useState(false);
    const [currentActionType, setCurrentActionType] = useState('');
    const [moveFolderBool, setMoveFolderBool] = useState(false);
    const [actionFolder, setActionFolder] = useState<TreeData>({});
    const [currentMoveId, setCurrentMoveId] = useState<any>(-1);
    const [filesDetailList, setFilesDetailList] = useState<LibraryFileFoundResponse[]>([]);
    const [fileCallData, setFileCallData] = useState<LibraryCallFoundResponse[]>([]);
    const [storageHistory, setStorageHistory] = useState<any[]>([]);
    const [moveModalTreeData, setmoveModalTreeData] = useState<any[]>([]);
    const [collectionNum, setCollectionNum] = useState(0);
    // 会话个数
    // const [sessionNum, /* setSessionNum */] = useState<string | number>(30);
    const [page, setPage] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    const pageChanged = (pageNum, pageSize) => {
        setPage({
            ...page,
            current: pageNum,
            pageSize,
        });
        let params = {
            current: pageNum,
            pageSize,
        };
        filesCallDetailData(currentSelect.id, params);
    };
    // 搜索
    const handlerSearch = (e) => {
        let searWord = e.target.value;
        if (searWord && searWord !== '') {
            getLibraryFilesSearch({ params: { name: searWord } }).then((resq) => {
                let result = resq.data ?? [];
                let searchData = result.map((item: any) => {
                    item.title = item.name;
                    item.key = item.id;
                    item.parentId = item.parentId * 1;
                    item.selectedKeys = [];
                    item.expandedKeys = [];
                    return item;
                });
                setTreeData(searchData);
            });
        } else {
            libaryFilesList(1);
        }
    };
    // 文件夹操作返回
    const handlerBack = () => {
        if (!storageHistory.length || storageHistory.length < 2) return;
        storageHistory.pop();
        let backStorageHistory = storageHistory[storageHistory.length - 1];
        if (backStorageHistory === 0) {
            setCurrentSelect({});
            collectsListData();
            setFileCallData([]);
            let newTreeData = treeData.map((item) => {
                item.selectedKeys = [];
                return item;
            });
            setTreeData(newTreeData);
        } else {
            if (backStorageHistory.isCollection) {
                setCurrentSelect({});
                filesDetailData(backStorageHistory.id);
                filesCallDetailData(backStorageHistory.id);
            } else {
                setCurrentSelect(backStorageHistory);
            }
        }
        setStorageHistory(storageHistory);
        if (!storageHistory.length) return;

        // 删除后左侧文件夹选中状态
        let popLastNode = storageHistory[storageHistory.length - 1];
        if (popLastNode === 0) {
            setCurrentSelect({});
            collectsListData();
            setFileCallData([]);
            let newTreeData = treeData.map((item) => {
                item.selectedKeys = [];
                return item;
            });
            setTreeData(newTreeData);
        } else {
            if (popLastNode.isCollection) {
                setCurrentSelect({});
                filesDetailData(backStorageHistory.id);
                filesCallDetailData(backStorageHistory.id);
            } else {
                let newTreeData = treeData.map((item) => {
                    if (popLastNode.type === item.type) {
                        item.selectedKeys = [popLastNode.id];
                    } else {
                        item.selectedKeys = [];
                    }
                    return item;
                });
                setTreeData(newTreeData);
            }
        }
    };
    // 打开文件详情
    const handlerFileDetail = (item) => {
        let treeDataKeysObj = {};
        treeData.forEach((record) => {
            if (record.id) {
                treeDataKeysObj[record.id] = {
                    expandedKeys: record.expandedKeys,
                    selectedKeys: record.selectedKeys,
                };
            }
        });
        const routeOptions = {
            history: props.history,
            routeName: 'gongCallDetail',
            pathParams: { callId: item.callId },
            queryParams: { libraryCallId: item.id },
            state: { type: 'case', treeDataKeysObj, storageHistory, currentSelect },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };
    // 打开文件夹
    const handlerOpen = (record) => {
        // if (!record.children || !record.children.length) return;
        // 这里，由于 record 会整体作为路由的参数传递，过程中如果包含函数就会报错，把函数字段全部置空
        record = {
            ...record,
            handlerCollection: undefined,
            handlerOpen: undefined,
            menu: undefined,
        };
        console.log(record);
        setCurrentSelect(record);
        let history = storageHistory;
        history.push(record);
        setStorageHistory(history);
        // 控制左侧文件夹选中状态
        let newTreeData = treeData.map((item) => {
            if (record.type === item.type) {
                item.selectedKeys = [record.id];
                if (item.expandedKeys && item.expandedKeys.length) {
                    item.expandedKeys = [...item.expandedKeys, record.id];
                }
            } else {
                item.selectedKeys = [];
            }
            return item;
        });
        setTreeData(newTreeData);
    };

    /* 树组件逻辑 start */
    const handlerMenuAction = (data) => {
        return (
            <Menu onClick={handlerMenuFolderChange.bind(null, data)}>
                {data.createUserId === userId ? (
                    <Menu.Item key="move">{locales.pages.gong.caseBase.index.move}</Menu.Item>
                ) : null}
                <Menu.Item key="rename">{locales.pages.gong.caseBase.index.rename}</Menu.Item>
                {data.createUserId === userId ? (
                    <Menu.Item key="delete">{locales.pages.gong.caseBase.index.delete}</Menu.Item>
                ) : null}
                {data.createUserId === userId && data.type === 2 ? (
                    <Menu.Item key="copy">{locales.pages.gong.caseBase.index.copyTo}</Menu.Item>
                ) : null}
            </Menu>
        );
    };
    const handlerAdd = (data) => {
        // setCurrentSelect(data);
        setActionFolder(data);
        setAddFolderBool(true);
        setCurrentActionType('add');
        form.resetFields();
    };
    // 树结构展开状态
    const handlerTreeExpand = (expandedKeys, { expanded: bool, node }, isAction) => {
        let newTreeData = treeData.map((item) => {
            if (node.type === item.type) {
                if (item.expandedKeys) {
                    if (bool) {
                        item.expandedKeys = [...item.expandedKeys, node.key];
                    } else {
                        item.expandedKeys.forEach((record, idx) => {
                            if (record === node.id) {
                                item.expandedKeys && item.expandedKeys.splice(idx, 1);
                            }
                        });
                    }
                }
            }
            return item;
        });
        setTreeData(newTreeData);
    };
    const handlerTreeNodes = (
        selectKeys,
        { selected: bool, selectedNodes, node, event },
        isAction
    ) => {
        if (isAction) {
            if (moveTreeData.length) {
                let newTreeData = moveTreeData.map((item) => {
                    if (bool) {
                        item.selectedKeys = selectKeys;
                    } else {
                        item.selectedKeys = [];
                    }
                    return item;
                });
                setMoveTreeData(newTreeData);
            }
        } else {
            if (treeData.length) {
                let newTreeData = treeData.map((item) => {
                    if (bool) {
                        item.selectedKeys = selectKeys;
                        if (item.expandedKeys) {
                            item.expandedKeys = [...item.expandedKeys, ...selectKeys];
                            item.expandedKeys = [...new Set(item.expandedKeys)];
                        }
                    } else {
                        item.selectedKeys = [];
                    }
                    return item;
                });
                setCurrentSelect(node);
                let history = storageHistory;
                // history.push(node);
                history.push({
                    ...node,
                    children: [],
                });
                setStorageHistory(history);
                setTreeData(newTreeData);
            }
        }
    };
    // 移动文件夹保存
    const handlerMoveOkFolder = () => {
        if (!actionFolder.id) return;
        if (currentMoveId < 0) {
            Udesk.ui.notify.error(locales.pages.gong.caseBase.index.pleaseSelectTheLocationToMove);
            return;
        }
        if (currentActionType === 'move') {
            const params = {
                parentId: currentMoveId,
            };
            putLibraryFilesMoveById(params, {
                segments: { id: actionFolder.id },
                successMsg: locales.pages.gong.caseBase.index.folderMovedSuccessfully,
            }).then((resq) => {
                setMoveFolderBool(false);
                libaryFilesList();
                if (actionFolder.id === currentSelect.id) {
                    handlerBack();
                }
                filesDetailData(currentSelect.id);
            });
        } else if (currentActionType === 'copy') {
            const params = {
                parentId: currentMoveId,
                id: actionFolder.id,
            };
            postLibraryFilesCopy(params, {
                successMsg: locales.pages.gong.caseBase.index.folderCopiedSuccessfully,
            }).then((res) => {
                setMoveFolderBool(false);
                libaryFilesList();
                if (currentSelect.id) {
                    filesDetailData(currentSelect.id);
                    filesCallDetailData(currentSelect.id);
                }
            });
        } else if (currentActionType === 'filemove') {
            const params = {
                fileId: currentMoveId,
            };
            putLibraryCallsById(params, {
                segments: { id: actionFolder.id },
                successMsg: locales.pages.gong.caseBase.index.fileMovedSuccessfully,
                errorMsg: locales.pages.gong.caseBase.index.fileMoveFailed,
            }).then((resq) => {
                setMoveFolderBool(false);
                filesCallDetailData(currentSelect.id);
            });
        } else if (currentActionType === 'filecopy') {
            const params = {
                fileId: currentMoveId,
                id: actionFolder.id,
            };
            putLibraryCallsCopy(params).then((res) => {
                setMoveFolderBool(false);
                libaryFilesList();
                if (currentSelect.id) {
                    filesDetailData(currentSelect.id);
                    filesCallDetailData(currentSelect.id);
                }
            });
        }
    };
    // 移动文件夹弹框取消
    const handlerMoveCancelFolder = () => {
        setMoveFolderBool(false);
    };
    // 新增文件夹确认
    const handlerAddOkFolder = () => {
        form.validateFields().then((values) => {
            if (currentActionType === 'add') {
                const params = {
                    name: values.name,
                    type: actionFolder.type,
                    parentId: actionFolder.id,
                };
                postLibraryFiles(params, {
                    successMsg: locales.pages.gong.caseBase.index.folderAddedSuccessfully,
                }).then((resq) => {
                    setAddFolderBool(false);
                    libaryFilesList();
                    filesDetailData(currentSelect.id);
                });
            } else if (currentActionType === 'rename') {
                if (!actionFolder.id) return;
                const params = {
                    name: values.name,
                };
                putLibraryFilesById(params, {
                    segments: { id: actionFolder.id },
                    successMsg: locales.pages.gong.caseBase.index.folderRenameSucceeded,
                }).then((resq) => {
                    setAddFolderBool(false);
                    libaryFilesList();
                });
            } else if (currentActionType === 'filerename') {
                if (!actionFolder.id) return;
                const params = {
                    name: values.name,
                };
                putLibraryCallsById(params, {
                    segments: { id: actionFolder.id },
                    successMsg: locales.pages.gong.caseBase.index.fileRenameSucceeded,
                }).then((resq) => {
                    setAddFolderBool(false);
                    filesCallDetailData(currentSelect.id);
                });
            }
        });
    };
    // 取消新增文件夹
    const handlerAddCancelFolder = () => {
        setAddFolderBool(false);
    };
    // 树结构文件夹操作
    const handlerMenuFolderChange = (value, { item, key, keyPath, domEvent }) => {
        setCurrentActionType(key);
        setActionFolder(value);
        if (key === 'move') {
            let moveModalTreeData = moveTreeData.filter((item) => item.type === value.type);
            setmoveModalTreeData(moveModalTreeData);
            setCurrentMoveId(-1);
            setMoveFolderBool(true);
        } else if (key === 'copy') {
            let moveModalTreeData = moveTreeData.filter((item) => item.type !== value.type);
            setmoveModalTreeData(moveModalTreeData);
            setCurrentMoveId(-1);
            setMoveFolderBool(true);
        } else if (key === 'rename') {
            setAddFolderBool(true);
            form.setFieldsValue({
                name: value.name,
            });
        } else if (key === 'delete') {
            confirm({
                title: locales.pages.gong.caseBase.index
                    .theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt,
                icon: <Icon type="ExclamationCircleOutlined" antdIcon={true}></Icon>,
                onOk() {
                    deleteLibraryFilesById({
                        segments: { id: value.id },
                        successMsg: locales.pages.gong.caseBase.index.deleteSucceeded,
                    }).then((resq) => {
                        if (value.id === currentSelect.id) {
                            handlerBack();
                        } else {
                            filesDetailData(currentSelect.id);

                            // 同步删除本地历史存储数据
                            storageHistory.forEach((item, idx) => {
                                if (item.id === value.id) {
                                    storageHistory.splice(idx, 1);
                                }
                            });
                            setStorageHistory(storageHistory);
                        }
                        libaryFilesList();
                    });
                },
                onCancel() {},
            });
        } else if (key === 'filemove') {
            let moveModalTreeData = moveTreeData.filter((item) => item.type === value.fileType);
            setmoveModalTreeData(moveModalTreeData);
            setCurrentMoveId(-1);
            setMoveFolderBool(true);
        } else if (key === 'filecopy') {
            let moveModalTreeData = moveTreeData.filter((item) => item.type !== value.fileType);
            setmoveModalTreeData(moveModalTreeData);
            setCurrentMoveId(-1);
            setMoveFolderBool(true);
        } else if (key === 'filerename') {
            setAddFolderBool(true);
            form.setFieldsValue({
                name: value.name,
            });
        } else if (key === 'filedelete') {
            confirm({
                title: locales.pages.gong.caseBase.index
                    .theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt,
                icon: <Icon type="ExclamationCircleOutlined" antdIcon={true}></Icon>,
                onOk() {
                    deleteLibraryCallsById({
                        segments: { id: value.id },
                        successMsg: locales.pages.gong.caseBase.index.deleteSucceeded,
                    }).then((resq) => {
                        filesCallDetailData(currentSelect.id);
                    });
                },
                onCancel() {},
            });
        }
        domEvent.stopPropagation();
    };
    /* 树组件逻辑 end */

    /* 文件夹及文件处理 start */
    const filesDetailData = (id) => {
        getLibraryFilesById({ segments: { id } }).then((resq) => {
            if (resq.data) {
                setFilesDetailList(resq.data.childrenList ?? []);
            }
        });
    };
    const filesCallDetailData = (fileId, pageData?) => {
        getLibraryCalls({
            params: {
                fileId,
                pageNum: pageData ? pageData.current : page.current,
                pageSize: pageData ? pageData.pageSize : page.pageSize,
            },
        }).then((resq) => {
            if (resq.data) {
                setFileCallData(resq.data);
            }
            if (resq.paging) {
                setPage({
                    current: resq.paging.pageNum ?? page.current,
                    pageSize: resq.paging.pageSize ?? page.pageSize,
                    total: resq.paging.total ?? page.total,
                });
            }
        });
    };
    /* 文件夹文件处理 end */

    const libaryFilesList = (type?) => {
        getLibraryFiles().then((resq) => {
            let getTreeDataList = transformArrayToTree(resq.data ?? []);
            if (getTreeDataList.length) {
                if (getTreeDataList[0].key && type === 1) {
                    if (props.location.state && props.location.state.type === 'case') {
                        setStorageHistory(props.location.state.storageHistory);
                        setCurrentSelect(props.location.state.currentSelect);
                        getTreeDataList.forEach((item) => {
                            if (item.id) {
                                item.selectedKeys =
                                    props.location.state.treeDataKeysObj[item.id].selectedKeys;
                                item.expandedKeys =
                                    props.location.state.treeDataKeysObj[item.id].expandedKeys;
                            }
                        });
                    } else {
                        // getTreeDataList[0].selectedKeys = [getTreeDataList[0].key];
                        // getTreeDataList[0].expandedKeys = [getTreeDataList[0].key];
                        // let history = storageHistory;
                        // history.push(getTreeDataList[0]);
                        // setStorageHistory(history);
                        // setCurrentSelect(getTreeDataList[0]);
                    }
                } else {
                    if (treeData.length) {
                        getTreeDataList.forEach((item) => {
                            treeData.forEach((record) => {
                                if (item.id === record.id) {
                                    item.selectedKeys = record.selectedKeys;
                                    item.expandedKeys = record.expandedKeys;
                                }
                            });
                        });
                    }
                }
                setTreeData(getTreeDataList);
                setMoveTreeData(JSON.parse(JSON.stringify(getTreeDataList)));
            }
        });
    };
    // 树结构数据处理
    const transformArrayToTree = (arr: LibraryFileFoundResponse[] = []): TreeData[] => {
        if (!arr || !arr.length) return [];
        const sortedArr = arr
            .map((i: any) => ({
                ...i,
                title: i.name,
                key: i.id,
                parentId: i.parentId * 1,
                selectedKeys: [],
                expandedKeys: [],
            }))
            .sort((a, b) => a.parentId - b.parentId);
        let root = sortedArr.filter((i) => i.parentId === sortedArr[0].parentId);
        let nodeMap = {};
        sortedArr.forEach((item) => {
            nodeMap[item.id] = item;
            if (nodeMap[item.parentId]) {
                if (Array.isArray(nodeMap[item.parentId].children)) {
                    nodeMap[item.parentId].children.push(item);
                } else {
                    nodeMap[item.parentId].children = [item];
                }
            }
        });
        return [...root];
    };
    const handlerMoveModalData = (selectedKeys) => {
        setCurrentMoveId(selectedKeys[0]);
    };
    const collectsListData = (type?) => {
        getLibraryFileCollects().then((res) => {
            setFilesDetailList(res.data ?? []);
            if (type && type === 1) {
                let history = storageHistory;
                history.push(0);
                setStorageHistory(history);
            }
        });
    };
    // 星标案例库打开
    const handlerCollectionOpen = (item) => {
        let history = storageHistory;
        item.isCollection = true;
        history.push(item);
        setStorageHistory(history);

        filesDetailData(item.id);
        filesCallDetailData(item.id);
        setCollectionNum(collectionNum + 1);
    };
    const handlerCollectionStar = (item) => {
        if (item.collectFlag) {
            deleteLibraryFileCollectsByFileId({
                segments: { fileId: item.id },
                successMsg: locales.pages.gong.caseBase.index.cancelCollectionSuccessfully,
            }).then((res) => {
                if (collectionNum) {
                    filesDetailData(item.parentId);
                    filesCallDetailData(item.parentId);
                } else {
                    collectsListData();
                }
            });
        } else {
            postLibraryFileCollectsByFileId({
                segments: { fileId: item.id },
                successMsg: locales.pages.gong.caseBase.index.collectionSucceeded,
            }).then((res) => {
                filesDetailData(item.parentId);
                filesCallDetailData(item.parentId);
            });
        }
    };
    const handlerCollectionTab = () => {
        setCollectionNum(0);
        setCurrentSelect({});
        let newTreeData = treeData.map((item) => {
            item.selectedKeys = [];
            return item;
        });
        setTreeData(newTreeData);
        collectsListData(1);
        setFileCallData([]);
        setPage({
            current: 1,
            pageSize: 20,
            total: 0,
        });
    };
    useEffect(() => {
        if (currentSelect.id) {
            filesDetailData(currentSelect.id);
            filesCallDetailData(currentSelect.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelect]);
    useEffect(() => {
        collectsListData(1);
        libaryFilesList(1);
        setUserId(Udesk.data.init.user.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Page
            pageClassName="page-file-case-base-container"
            pageBodyClassName="page-file-case-base-body"
            padding={true}
        >
            <div className="page-folder-files-case-base-container">
                <div className="folder-file-search">
                    <Input
                        onChange={handlerSearch}
                        suffix={<Icon type="SearchOutlined" antdIcon={true} />}
                        allowClear
                    />
                    <p
                        onClick={handlerCollectionTab}
                        className={
                            !currentSelect.id
                                ? 'collects-case-base-tab active'
                                : 'collects-case-base-tab'
                        }
                    >
                        {locales.pages.gong.caseBase.index.starCaseBase}
                    </p>
                    <FilesTree
                        treeData={treeData}
                        handlerAdd={handlerAdd}
                        handlerMenuAction={handlerMenuAction}
                        handlerTreeNodes={handlerTreeNodes}
                        handlerTreeExpand={handlerTreeExpand}
                    ></FilesTree>
                </div>
                <div className="folder-file-container">
                    <div className="folder-back">
                        <Space size={12}>
                            <div style={{ cursor: 'pointer' }} onClick={handlerBack}>
                                <Icon
                                    style={{ fontSize: 20 }}
                                    type="ArrowLeftOutlined"
                                    antdIcon={true}
                                ></Icon>
                            </div>
                            <Divider type="vertical" />
                            <h3>
                                {!currentSelect.id
                                    ? locales.pages.gong.caseBase.index.starCaseBase
                                    : currentSelect.name}
                            </h3>
                        </Space>
                    </div>

                    <div className="folder-file-content">
                        {currentSelect.id ? (
                            <p>
                                {locales.pages.gong.caseBase.index.share}
                                {filesDetailList.length}
                                {locales.pages.gong.caseBase.index.folders}
                                {page.total}
                                {locales.pages.gong.caseBase.index.calls}
                            </p>
                        ) : (
                            <p
                                style={{
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                    fontWeight: 700,
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    lineHeight: '24px',
                                    paddingBottom: 16,
                                    fontSize: 16,
                                }}
                            >
                                {locales.pages.gong.caseBase.index.starCaseBase}
                            </p>
                        )}
                        <div className="folder-content">
                            {filesDetailList.length
                                ? filesDetailList.map((item) => {
                                      return (
                                          <React.Fragment key={item.id}>
                                              {currentSelect.id ? (
                                                  <FolderItem
                                                      handlerOpen={handlerOpen}
                                                      handlerCollection={handlerCollectionStar}
                                                      key={item.id}
                                                      {...item}
                                                      menu={
                                                          <Menu
                                                              onClick={handlerMenuFolderChange.bind(
                                                                  null,
                                                                  item
                                                              )}
                                                          >
                                                              {item.createUserId === userId ? (
                                                                  <Menu.Item key="move">
                                                                      {
                                                                          locales.pages.gong
                                                                              .caseBase.index.move
                                                                      }
                                                                  </Menu.Item>
                                                              ) : null}
                                                              <Menu.Item key="rename">
                                                                  {
                                                                      locales.pages.gong.caseBase
                                                                          .index.rename
                                                                  }
                                                              </Menu.Item>
                                                              {item.createUserId === userId ? (
                                                                  <Menu.Item key="delete">
                                                                      {
                                                                          locales.pages.gong
                                                                              .caseBase.index.delete
                                                                      }
                                                                  </Menu.Item>
                                                              ) : null}
                                                              {item.createUserId === userId &&
                                                              item.type === 2 ? (
                                                                  <Menu.Item key="copy">
                                                                      {
                                                                          locales.pages.gong
                                                                              .caseBase.index.copyTo
                                                                      }
                                                                  </Menu.Item>
                                                              ) : null}
                                                          </Menu>
                                                      }
                                                  ></FolderItem>
                                              ) : (
                                                  <FolderItem
                                                      handlerOpen={handlerCollectionOpen.bind(
                                                          null,
                                                          item
                                                      )}
                                                      key={item.id}
                                                      {...item}
                                                      isCollection={true}
                                                      handlerCollection={handlerCollectionStar}
                                                  ></FolderItem>
                                              )}
                                          </React.Fragment>
                                      );
                                  })
                                : null}
                        </div>
                        <div className="file-content">
                            {fileCallData.map((item) => {
                                return (
                                    <React.Fragment>
                                        {currentSelect.id ? (
                                            <FileItem
                                                key={item.id}
                                                {...item}
                                                handlerFileDetail={handlerFileDetail}
                                                menu={
                                                    <Menu
                                                        onClick={handlerMenuFolderChange.bind(
                                                            null,
                                                            item
                                                        )}
                                                    >
                                                        {item.createUserId === userId ? (
                                                            <Menu.Item key="filemove">
                                                                {
                                                                    locales.pages.gong.caseBase
                                                                        .index.move
                                                                }
                                                            </Menu.Item>
                                                        ) : null}
                                                        <Menu.Item key="filerename">
                                                            {
                                                                locales.pages.gong.caseBase.index
                                                                    .rename
                                                            }
                                                        </Menu.Item>
                                                        {item.createUserId === userId ? (
                                                            <Menu.Item key="filedelete">
                                                                {
                                                                    locales.pages.gong.caseBase
                                                                        .index.delete
                                                                }
                                                            </Menu.Item>
                                                        ) : null}
                                                        {item.createUserId === userId &&
                                                        item.fileType === 2 ? (
                                                            <Menu.Item key="filecopy">
                                                                {
                                                                    locales.pages.gong.caseBase
                                                                        .index.copyTo
                                                                }
                                                            </Menu.Item>
                                                        ) : null}
                                                    </Menu>
                                                }
                                            ></FileItem>
                                        ) : (
                                            <FileItem
                                                key={item.id}
                                                {...item}
                                                handlerFileDetail={handlerFileDetail}
                                                isCollection={true}
                                            ></FileItem>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            {page.total ? (
                                <UdeskPagination {...page} onChange={pageChanged} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title={
                    currentActionType === 'add'
                        ? locales.pages.gong.caseBase.index.newFolder
                        : locales.pages.gong.caseBase.index.modifyFolder
                }
                visible={addFolderBool}
                onOk={handlerAddOkFolder}
                onCancel={handlerAddCancelFolder}
            >
                <Form form={form}>
                    <Form.Item
                        name="name"
                        label={locales.pages.gong.caseBase.index.title}
                        rules={[
                            {
                                required: true,
                                message: locales.pages.gong.caseBase.index.pleaseEnter,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                className="pages-case-base-modal-move-folder-container"
                title={locales.pages.gong.caseBase.index.selectMoveLocation}
                destroyOnClose
                visible={moveFolderBool}
                onOk={handlerMoveOkFolder}
                onCancel={handlerMoveCancelFolder}
            >
                {/* <FilesTree
                    key='-1'
                    treeData={moveTreeData}
                    isAction={true}
                    handlerTreeNodes={handlerTreeNodes}
                ></FilesTree> */}
                <Tree
                    defaultExpandAll
                    treeData={moveModalTreeData}
                    onSelect={handlerMoveModalData}
                />
            </Modal>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
