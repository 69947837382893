import React, { FC } from 'react';
import { useMount } from 'ahooks';
import { Space } from 'udesk-ui';
import InputUseEntity from '../input-use-entity';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const DynamicInputWrapper = styled.div``;
const DynamicInputItem = styled.div`
    margin-bottom: 5px;
`;

type TemplateProps = {
    value?: string[];
    onChange?: (v: string[]) => void;
    inputTextChanged?: (idx: number, val: string) => void;
    addListValue?: () => void;
    deleteListValue?: (idx: number) => void;
    emptyErrorTip?: () => void;
    maxListValueLength?: number;
    width?: number;
    hideList?: boolean;
};

const Template: FC<TemplateProps> = (props) => {
    const {
        value,
        onChange,
        inputTextChanged,
        addListValue,
        deleteListValue,
        emptyErrorTip,
        maxListValueLength,
        width,
        hideList,
    } = props;

    const onContentChange = (idx: number, val: string) => {
        const newValue = (value || []).map((v, _idx) => {
            if (_idx === idx) {
                return val;
            } else {
                return v;
            }
        });
        onChange?.(newValue);
        inputTextChanged?.(idx, val);
    };

    const onAdd = () => {
        if (value?.length && !value?.every(Boolean)) {
            emptyErrorTip?.();
            return;
        }
        const newValue = [...(value || []), ''];
        onChange?.(newValue);
        addListValue?.();
    };

    const onRemove = (idx: number) => {
        const newValue = (value || []).filter((v, _idx) => _idx !== idx);
        onChange?.(newValue);
        deleteListValue?.(idx);
    };

    useMount(() => {
        if ((value || []).length <= 0) {
            onAdd();
        }
    });

    return (
        <DynamicInputWrapper>
            {value?.map((v, idx) => {
                return (
                    <DynamicInputItem>
                        <Space>
                            <InputUseEntity
                                value={v}
                                onChanged={(v) => onContentChange(idx, v)}
                                width={width}
                                maxLength={maxListValueLength}
                                hideList={hideList}
                            />
                            {(value || []).length > 1 ? (
                                <MinusCircleOutlined onClick={() => onRemove(idx)} />
                            ) : null}
                        </Space>
                    </DynamicInputItem>
                );
            })}
            <PlusCircleOutlined onClick={onAdd} />
        </DynamicInputWrapper>
    );
};

export default Template;
