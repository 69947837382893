// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetReviewCallDataCallDetailByIdCustomerCallsQuery,
    GetReviewCallDataCallDetailByIdCustomerCallsParam,
    BaseResponseDataCallNumWithDateDto,
} from '../../../../../../types';

/**
 * 获取通对话的客户的最近通话数据
 *
 * @export
 * @tags 通话记录
 * @link [GET] /review/call/dataCall/detail/{id}/customerCalls
 * @param id
 */
export function getReviewCallDataCallDetailByIdCustomerCalls(
    options: ApiOptions<
        GetReviewCallDataCallDetailByIdCustomerCallsParam,
        GetReviewCallDataCallDetailByIdCustomerCallsQuery
    > & { segments: GetReviewCallDataCallDetailByIdCustomerCallsParam }
): Promise<BaseResponseDataCallNumWithDateDto> {
    return request<
        never,
        GetReviewCallDataCallDetailByIdCustomerCallsParam,
        GetReviewCallDataCallDetailByIdCustomerCallsQuery
    >('/review/call/dataCall/detail/{id}/customerCalls', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallDataCallDetailByIdCustomerCalls',
    });
}

export const meta = [
    { tags: ['通话记录'], path: '/review/call/dataCall/detail/{id}/customerCalls', method: 'get' },
];
