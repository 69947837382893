import React, { useEffect, useState } from 'react';
import { Table } from 'udesk-ui';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import moment from 'moment';

const staticParams = {
    task_id: '-1',
    // point_id: '-1',
    inspect_data_source: '-1',
    permission: 'all',
};

export default function Card(props) {
    const locales = Locales['current'];
    const {
        request,
        limit,
        time = [
            moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'),
            moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59'),
        ],
        color,
        transToPoint,
        agentGroupIds,
        taskId,
        style,
    } = props;
    const [noComplianceTopColumns, setNoComplianceTopColumns] = useState([]);
    const [noComplianceTopData, setNoComplianceTopData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let params = {
            statAt: time,
            beginOfWeek: '1',
            endOfWeek: '7',
            ...staticParams,
            // task_id: props.sdkOptions.props.taskId,
            task_id: taskId,
            agent_no: "'-1'",
            agent_group_id: agentGroupIds,
            limit: limit,
        };
        setLoading(true);
        request({ ...params })
            .then((resp) => resp.data)
            .then(
                (resp) => {
                    if (!resp?.data) return;
                    let data = Udesk.business.report.getChartDataFix(
                        { props },
                        {
                            ...resp.data,
                            header: {
                                ...resp.data.header,
                                dimensions: resp.data.header.indicators,
                            },
                        }
                    );
                    setNoComplianceTopColumns(getTableColumns(data, color));

                    setNoComplianceTopData(Udesk.business.report.getTableDataFromChartData(data).map(i => {
                        const keys = Object.keys(i);
                        return keys.reduce((prev, cur) => {
                            return {
                                ...prev,
                                [cur]: typeof i[cur] === 'object' ? '-' : i[cur],
                            };
                        }, {});
                    }));
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg ||
                            Udesk.utils.string.format(
                                locales.business.notifyMessage.getInformationFailedFormat,
                                locales.components.pages.reports.overview
                            )
                    );
                }
            )
            .finally(() => {
                setLoading(false);
            });
    }, [request, limit, agentGroupIds, taskId]);

    return (
        <div className='table-item' style={style}>
            <Table
                size='small'
                columns={noComplianceTopColumns}
                dataSource={noComplianceTopData}
                scroll={noComplianceTopData.length ? { y: 200 } : undefined}
                pagination={false}
                loading={loading}
                onRow={
                    typeof transToPoint === 'function'
                        ? (record) => {
                            return {
                                onClick: transToPoint.bind(null, record), // 点击行
                            };
                        }
                        : undefined
                }
            />
        </div>
    );
}

function getTableColumns(data, color) {
    let res = Udesk.business.report.getTableColumnsFromChartData(data);
    return res.map((item) => {
        if (
            [
                'qa_inspection_task_agent_top_up__name',
                'qa_inspection_task_agent_top_down__name',
                'qa_inspection_point_no_compliance_top__name',
                'qa_inspection_point_compliance_top__name',
            ].includes(item.dataIndex)
        ) {
            return {
                ...item,
                render: (text, record, index) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <div
                                style={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: '50%',
                                    background: index < 3 ? color : 'rgba(0, 0, 0, 0.25)',
                                    color: '#fff',
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    marginRight: 8,
                                }}
                            >
                                {index + 1}
                            </div>
                            <div
                                title={text}
                                style={{
                                    lineHeight: '28px',
                                    overflow: 'hidden',
                                    flex: 1,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {text}
                            </div>
                        </div>
                    );
                },
            };
        }
        return item;
    });
}
