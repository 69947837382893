// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { DeleteSubjectMarkCallsRestByCallIdParam, BaseResponseVoid } from '../../../types';

/**
 * 重置标注通话
 *
 * @export
 * @tags 话题标注通话
 * @link [DELETE] /subjectMarkCalls/rest/{callId}
 * @param callId
 */
export function deleteSubjectMarkCallsRestByCallId(
    options: ApiOptions<DeleteSubjectMarkCallsRestByCallIdParam, never> & {
        segments: DeleteSubjectMarkCallsRestByCallIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteSubjectMarkCallsRestByCallIdParam, never>(
        '/subjectMarkCalls/rest/{callId}',
        { ...options, method: 'delete', __$requestCalleeName: 'deleteSubjectMarkCallsRestByCallId' }
    );
}

export const meta = [
    { tags: ['话题标注通话'], path: '/subjectMarkCalls/rest/{callId}', method: 'delete' },
];
