import Udesk from 'Udesk';
import AnomalousDataComponent from './component';

export default class AnomalousDataRoute extends AnomalousDataComponent {
    asyncModel() {
        let { sdkOptions } = this.props;
        let data = {
            pageNum: this.privates.paging.pageNum,
            pageSize: this.privates.paging.pageSize,
            sourceType: this.privates.dataSource,
            startTime: this.privates.statAt[0],
            endTime: this.privates.statAt[1],
            businessId: this.privates.businessId
        };
        if (this.privates.statFieldAt && this.privates.statFieldAt.length > 0) {
            data = {
                ...data,
                businessStartTime: this.privates.statFieldAt[0],
                businessEndTime: this.privates.statFieldAt[1]
            };
        }
        let url = Udesk.business.apiPath.concatApiPath(`callIrregularSource/search`, sdkOptions);
        let dataPromise = new Promise((resolve, reject) => {
            Udesk.ajax.post(url, data).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            data: dataPromise
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}
