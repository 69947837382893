import UdeskLocales from 'UdeskLocales';
import Udesk from 'Udesk';
import AdminUserDetailTemplate from '../detail/template';

export default class AdminUserNewRoute extends AdminUserDetailTemplate {
    model() {
        let params = Object.assign(
            {},
            this.props.match.params,
            Object.fromEntries(new URLSearchParams(this.props.location.search))
        );
        let modelPromise = new Promise((resolve, reject) => {
            resolve({
                mobile: null
            });
        });
        let roles = new Promise((resolve, reject) => {
            Udesk.ajax.get(Udesk.business.apiPath.concatApiPath(`/roles`, this.props.sdkOptions), params).then(
                (resp, textStatus, jqXHR) => {
                    resolve(resp.data);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg || UdeskLocales.current.business.role.loadRoleListError);
                    reject(reason);
                }
            );
        });
        return {
            user: modelPromise,
            roles: roles
        };
    }
}
