import RectangularCoordinateSystemBuilderClass from '../rectangular-coordinate-system';
import common from '../../../../../udesk/index';
import Locales from '../../../../../udesk/locales';
let laber = ["弱", '相关性', '强', "  ", '强', '相关性', '弱'];
const KEYWORD_SIZE = 50;
const RELEVANT_MAX_SIZE = 40;
const RELEVANT_MIN_SIZE = 10;
const MAX_CORRELATION_COEFFICIENT = 0.8;
const TOOLTIP_TEXT_STYLE = {
    padding: 15,
    color: "#333",
    fontSize: 12,
    lineHeight: 22
};

class ScatterOptionsBuilderClass extends RectangularCoordinateSystemBuilderClass {
    getLaberOptions(langue) {
        let leftSide = [Locales.get("components.reactEcharts.customScatterPie.weak", langue), Locales.get("components.reactEcharts.customScatterPie.relevance", langue), Locales.get("components.reactEcharts.customScatterPie.strong", langue)];
        let rightSide = [...leftSide].reverse();
        return [...leftSide, " ", ...rightSide];
    }
    getXAxisOptions(optionsOwner) {
        let context = this;
        let xAxis = {
            zlevel: 20,
            type: 'value',
            min: -50,
            max: 50,
            interval: 16.7,
            axisLabel: {
                margin: -240,
                formatter: function (value, index) {
                    let langue = optionsOwner.props.langue;
                    let laber = context.getLaberOptions(langue);
                    return laber[index];
                },
                lineStyle: {
                    color: '#278dfb'
                }
            },
            splitLine: {
                show: false,
            },
            splitArea: {
                show: false,
            }
        };
        return xAxis;
    }
    getYAxisOptions(optionsOwner) {
        let yAxis = {
            type: 'value',
            "min": -50,
            "max": 50,
            interval: 0,
            "show": false,
            "splitLine": {
                "show": false
            },
            "splitArea": {
                "show": false
            }
        };
        return yAxis;
    }
    getGridOptions(optionsOwner) {
        return {
            top: 15,
            bottom: 15,
            left: 15,
            right: 15
        };
    }
    getLegendOptions(optionsOwner) {
        return {
            "show": false,
            "data": []
        };
    }
    getTooltipOptions(optionsOwner) {
        let tooltip = {
            show: false,
            showContent: true
        };
        return tooltip;
    }

    getVisualMapOptions(optionsOwner) {
        let {
            seriesColumns,
            records
        } = optionsOwner.props;
        let maxValue = 0;
        if (seriesColumns && seriesColumns.length > 0) {
            seriesColumns.forEach((column) => {
                if (records && records.length > 0) {
                    records.forEach((record) => {
                        let value = Number(this._getFieldOriginalValue(record, column.field));
                        if (isNaN(value)) {
                            value = 0;
                        }
                        if (value > maxValue) {
                            maxValue = value;
                        }
                    });
                }
            });
        }

        if (maxValue === 0) {
            maxValue = 100;
        }

        let visualMap = {
            show: false,
            type: 'continuous',
            dimension: 4,
            min: 0,
            max: common.utils.math.roundUp(maxValue, 10),
            seriesIndex: 1,
            calculable: true,
            precision: 0.1,
            color: ["#3399FF", "#33CCFF", "#66CCFF", "#00CCFF", "#0099CC", "#6699CC", "#336699", "#006699"]
        };
        return visualMap;
    }
    getSeriesOptions(optionsOwner) {
        let {
            records,
            seriesColumns
        } = optionsOwner.props;

        let countFieldName = seriesColumns[0].field;
        let keywordCount = records[0][countFieldName].originalValue || records[0][countFieldName].value;

        let keyword = getKeyword(records);
        let relatedWords = getRelatedWords(records);

        let series = [
            // related words
            {
                zlevel: 20,
                type: 'scatter',
                symbol: 'circle',
                symbolSize: KEYWORD_SIZE,
                label: {
                    normal: {
                        textStyle: {
                            fontSize: 12,
                            color: "#fff",
                        },
                        show: true,
                        formatter: function (param) {
                            return param.data[2];
                        },
                    },
                },
                itemStyle: {
                    normal: {
                        color: '#2878ff',
                    },
                },
                data: [
                    keyword
                ],
                tooltip: {
                    show: true,
                    backgroundColor: "#ffffff",
                    textStyle: TOOLTIP_TEXT_STYLE,
                    formatter: function (param) {
                        return getTooltipValue(param, optionsOwner, true);
                    }
                }
            },
            {
                "name": "",
                "zlevel": 20,
                "type": "scatter",
                "symbol": "circle",
                symbolSize: function (param) {
                    return getRelatedWordSize(param[4], keywordCount);
                },
                "itemStyle": {
                    "normal": {}
                },
                label: {
                    color: "#000",
                    normal: {
                        textStyle: {
                            fontSize: 12,
                            color: "#000",
                        },
                        position: 'bottom',
                        show: true,
                        formatter: function (param) {
                            return param.data[2];
                        },
                    }
                },
                data: relatedWords,
                tooltip: {
                    show: true,
                    backgroundColor: "#ffffff",
                    textStyle: TOOLTIP_TEXT_STYLE,
                    formatter: function (param) {
                        return getTooltipValue(param, optionsOwner);
                    }
                }
            },
            // pie
            {
                "type": "pie",
                "radius": ["0", "45%"],
                "center": ["50%", "50%"],
                //"zlevel": 6,
                "avoidLabelOverlap": false,
                "label": {
                    "normal": {
                        "show": false,
                        "position": "center"
                    },
                    "emphasis": {
                        "show": false,
                        "textStyle": {
                            "fontWeight": "bold"
                        }
                    }
                },
                "itemStyle": {
                    "normal": {
                        "color": {
                            "type": "linear",
                            "x": 0,
                            "y": 0,
                            "x2": 0,
                            "y2": 1,
                            "colorStops": [{
                                "offset": 0.05,
                                "color": "rgba(130,198,255, 0.1)"
                            }, {
                                "offset": 0.5,
                                "color": "rgba(130,198,255, 0.2)"
                            }, {
                                "offset": 0.95,
                                "color": "rgba(130,198,255, 0.1)"
                            }]
                        }
                    }
                },
                "labelLine": {
                    "normal": {
                        "show": false
                    }
                },
                "data": [{
                    "value": 3357
                }]
            },
            {
                "type": "pie",
                "radius": ["0%", "90%"],
                "center": ["50%", "50%"],
                "avoidLabelOverlap": false,
                "label": {
                    "normal": {
                        "show": false,
                        "position": "center"
                    },
                    "emphasis": {
                        "show": false,
                        "textStyle": {
                            "fontWeight": "bold"
                        }
                    }
                },
                "itemStyle": {
                    "normal": {
                        "color": {
                            "type": "linear",
                            "x": 0,
                            "y": 0,
                            "x2": 0,
                            "y2": 1,
                            "colorStops": [{
                                "offset": 0.1,
                                "color": "rgba(130,198,255, 0)"
                            }, {
                                "offset": 0.5,
                                "color": "rgba(130,198,255, 0.2)"
                            }, {
                                "offset": 0.9,
                                "color": "rgba(130,198,255, 0)"
                            }]
                        }
                    }
                },
                "labelLine": {
                    "normal": {
                        "show": false
                    }
                },
                "data": [{
                    "value": 3235
                }]
            },
            {
                "type": "pie",
                "radius": ["0%", "135%"],
                "center": ["51%", "50%"],
                "avoidLabelOverlap": false,
                "label": {
                    "normal": {
                        "show": false,
                        "position": "center"
                    },
                    "emphasis": {
                        "show": false,
                        "textStyle": {
                            "fontWeight": "bold"
                        }
                    }
                },
                "itemStyle": {
                    "normal": {
                        "color": {
                            "type": "linear",
                            "x": 0,
                            "y": 0,
                            "x2": 0,
                            "y2": 1,
                            "colorStops": [{
                                "offset": 0.2,
                                "color": "rgba(130,198,255, 0.0)"
                            }, {
                                "offset": 0.5,
                                "color": "rgba(130,198,255, 0.2)"
                            }, {
                                "offset": 0.88,
                                "color": "rgba(130,198,255, 0.0)"
                            }]
                        }
                    }
                },
                "labelLine": {
                    "normal": {
                        "show": false
                    }
                },
                "data": [{
                    "value": 3235
                }]
            }
        ];
        return series;
    }
    _getChartType() {
        return common.enums.echartTypes.scatterPie.key;
    }
}

function getKeyword(records) {
    let keyword = [0, 0, null, '#278DFB', 19];
    if (Array.isArray(records) && records.length) {
        keyword[2] = records[0].xLabel;
    }
    return keyword;
}

function getRelatedWords(records) {
    let reg = /''/;
    let relatedWords = [];
    let dataSource = [];
    if (Array.isArray(records) && records.length > 1) {
        reg = new RegExp(`${records[0].xLabel}-`);
        dataSource = records.filter((record, index) => {
            return index !== 0;
        });
    }
    let randomAngle = Math.random() * 360;
    adjustmentCorrelationCoefficient(dataSource); //dataSource中有 相关系数 大于MAX_CORRELATION_COEFFICIENT时，关联系数做调整
    //dataSource.sort(randomsort); //数组随机排序
    dataSource.forEach((data, index) => {
        data.radius = getRadius(data.correlationCoefficient);
        data.angle = getAngle(dataSource, index) + randomAngle;
        data.coordinate = getCoordinate(data.radius, data.angle);
        // let relatedWordSize = getRelatedWordSize();
        let value = data.relative_keyword_freq.originalValue || data.relative_keyword_freq.value;
        relatedWords.push([data.coordinate.x, data.coordinate.y, data.xLabel.replace(reg, ''), "#FCB03d", value]);
    });

    return relatedWords;
}

function adjustmentCorrelationCoefficient(dataSource) {
    dataSource.forEach((data) => {
        data.correlationCoefficient = data.relative_keyword_score.originalValue || data.relative_keyword_score.value;
    });
    let maxCorrelationCoefficient = 0;
    if (Array.isArray(dataSource) && dataSource.length) {
        maxCorrelationCoefficient = dataSource.reduce(function (a, b) {
            return b.correlationCoefficient > a.correlationCoefficient ? b : a;
        }).relative_keyword_score.originalValue;
        if (maxCorrelationCoefficient > MAX_CORRELATION_COEFFICIENT) {
            let offset = 1 - MAX_CORRELATION_COEFFICIENT;
            // 最大关系系数是1的时候，优化一下显示方式
            if (maxCorrelationCoefficient === 1) {
                offset = offset / 0.618;
            }
            dataSource.forEach((data) => {
                // if ((data.correlationCoefficient - offset) > 0) {
                //     data.correlationCoefficient = data.correlationCoefficient - offset;
                // }
                data.correlationCoefficient = data.correlationCoefficient - (data.correlationCoefficient * offset);
            });
        }
    }
}


function getRadius(correlationCoefficient) { //获取与关键词相关联的词距离圆心的位置
    return (1 - correlationCoefficient) * 50;
}

function getAngle(dataSource, index) { //获取角度
    return (360 / dataSource.length) * index;
}

function getCoordinate(radius, angle, circleCenterX = 0, circleCenterY = 0, ) { //获取基于圆心的x,y坐标
    return {
        x: circleCenterX + radius * Math.cos(angle * 3.14 / 180),
        y: circleCenterY + radius * Math.sin(angle * 3.14 / 180),
    };
}

function getRelatedWordSize(RelatedWordValue, keywordCount) { //获取与关键词相关联的词的大小
    if (parseFloat(RelatedWordValue) >= keywordCount) {
        return RELEVANT_MAX_SIZE;
    }
    let relatedWordSize = parseFloat(RelatedWordValue) / keywordCount * RELEVANT_MAX_SIZE;
    if (relatedWordSize > RELEVANT_MIN_SIZE) {
        return relatedWordSize;
    }
    return RELEVANT_MIN_SIZE;
}

function getTooltipValue(params, optionsOwner, isKeyWord = false) {
    let paramsData = params;
    if (!Array.isArray(params)) {
        paramsData = [params];
    }

    return "<div class='echart-tooltip-content'>" + paramsData.map(item => {
        let {
            records,
            seriesColumns
        } = optionsOwner.props;

        let seriesName = item.seriesName;

        if (seriesName === '\u0000-') {
            // Not show '-'
            seriesName = '';
        }

        let color = item.color || '#E3E3E3';
        if (color === "auto") {
            color = '#E3E3E3';
        }

        if (seriesColumns == null) {
            seriesColumns = [];
        }
        if (!Array.isArray(seriesColumns)) {
            seriesColumns = [seriesColumns];
        }

        let normalTooltipItems = seriesColumns.reduce((arr, seriesColumn) => {
            let formattedValue = 0;
            let originalValue = 0;
            if (records && records.length > 0) {
                let fieldKey = seriesColumn.field;
                let record = null;
                if (isKeyWord) {
                    record = records[item.dataIndex];
                } else {
                    record = records[item.dataIndex + 1];
                }

                if (fieldKey && record) {
                    formattedValue = record[fieldKey].value || record[fieldKey].originalValue || 0;
                    originalValue = record[fieldKey].originalValue || 0;
                }

                let name = seriesColumn.name;
                if (isKeyWord) {
                    if (originalValue > 0) {
                        arr.push(`<div class="echart-tooltip-content-item"><div class="echart-tooltip-title">${common.utils.encodeHTML(name)}</div><div class="echart-tooltip-value" style="background-color:${color};">${formattedValue}</div></div>`);
                    }
                } else {
                    arr.push(`<div class="echart-tooltip-content-item"><div class="echart-tooltip-title">${common.utils.encodeHTML(name)}</div><div class="echart-tooltip-value" style="background-color:${color};">${formattedValue}</div></div>`);
                }
            }
            return arr;
        }, []);
        return normalTooltipItems.join('');
    }).join('') + "</div>";

}



export default ScatterOptionsBuilderClass;