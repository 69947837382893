import { algorithmRoutesGlobal } from './algorithm/routes-global';
import { coachRoutesGlobal } from './coach/routes-global';
import { salesRoutesGlobal } from './sales/routes-global';
import { generalSettingRoutesGlobal } from './setting/routes-global';
import { qaRoutesGlobal } from './qa/routes-global';
import { conversationAnalysisRoutesGlobal } from './conversationAnalysis/routes-global';

const RoutesGlobal = [
    {
        path: '/entry-page',
        name: 'appEntry',
    },
    // 系统登录
    {
        path: '/auth/login',
        name: 'authLogin',
    },
    {
        path: '/:subdomain/oauth/authenticate',
        name: 'oauth',
    },
    {
        path: '/oauth/authenticate/:subdomain/callback',
        name: 'oauthCallback',
    },
    // 系统退出
    {
        path: '/auth/logout',
        name: 'authLogout',
    },
    // 404
    {
        path: '/404',
        name: 'pageNotFound',
    },
    // 案例分享页面
    {
        path: '/case-share-qa/:id',
        name: 'coachCaseShareOne',
    },
    // 中转站 - 重定向使用
    {
        path: '/transfer-station',
        name: 'transferStation',
    },
    ...qaRoutesGlobal,
    ...salesRoutesGlobal,
    ...algorithmRoutesGlobal,
    ...generalSettingRoutesGlobal,
    ...coachRoutesGlobal,
    ...conversationAnalysisRoutesGlobal,
];

export default RoutesGlobal;
