import React, { memo, useMemo, useEffect, useRef, FC } from 'react';
import { useImmer } from 'use-immer';
import useResizeObserver from 'use-resize-observer';
import { useRequest } from 'ahooks';
import { Empty } from 'udesk-ui';
import styled from 'styled-components';
import ReactEcharts from 'udesk-react/src/components/react-echarts/base';
import { useConfig } from './hooks';
import { getEchartDataFromData, getItem } from './utils';
import { postIntelligentPartnerHomePageStudentPictureEvaluation } from 'src/api/intelligentPartner/homePage/student/picture/evaluation';
import UdeskLocales from 'UdeskLocales';

const Wrap = styled.div`
    margin-top: 24px;
    overflow: hidden;

    > div {
        height: 300px;
    }
`;

type ComprehensiveEvaluationProps = {
    params?: any;
};

const ComprehensiveEvaluation: FC<ComprehensiveEvaluationProps> = (props) => {
    const { params } = props;

    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const echartsRef = useRef<any>(null);
    useEffect(() => {
        const token = requestIdleCallback(() => {
            echartsRef.current?.resize?.();
        });
        return () => {
            cancelIdleCallback(token);
        };
    }, [width, height]);

    const [data] = useImmer<any>({
        radarLegend: [],
        indicator: [],
        series: [],
    });
    const radarSeriesColumns = useMemo(() => {
        return [
            {
                name: '',
                type: 'radar',
                data,
            },
        ];
    }, [data]);

    const [radarOption, setRadarOption] = useImmer<any>(null);

    const { legend, radarOptionLegend, seriesItemStyle } = useConfig();

    useRequest(() => postIntelligentPartnerHomePageStudentPictureEvaluation(params), {
        ready: !!params?.userId && !!params?.taskId && !!params?.startTime && !!params?.endTime,
        refreshDeps: [params],
        onSuccess: (res) => {
            const [getThisItem] = legend.map((l) => getItem(l.areaStyleColor, l.name));

            const echartData = getEchartDataFromData(
                res.data?.scoreDefaultDes,
                res.data?.scorePointList
            );

            const indicator =
                res.data?.scorePointList?.map((i) => ({ name: i.name, max: 100 })) ?? [];

            const radarOption = {
                title: false,
                color: legend.map((i) => i.color),
                legend: radarOptionLegend,
                tooltip: {
                    confine: true,
                    trigger: 'item',
                    formatter: function (params) {
                        let relVal = params.name;
                        let detail = echartData.detail;
                        relVal += indicator
                            .map((i, index) => {
                                const main =
                                    '<br/><strong>' +
                                    i.name +
                                    ': ' +
                                    params.data.value[index] +
                                    '%</strong>';
                                const sub = detail[index];
                                return main + '<br />' + sub;
                            })
                            .join('');
                        return relVal;
                    },
                },
                radar: [
                    {
                        name: {
                            // (圆外的标签)雷达图每个指示器名称的配置项。
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 14,
                                color: 'rgba(0, 0, 0, .65)',
                            },
                        },
                        nameGap: 15,
                        // 指示器名称和指示器轴的距离。[ default: 15 ]
                        splitNumber: 5,
                        // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
                        shape: 'polygon',
                        // 雷达图绘制类型，支持 'polygon'(多边形) 和 'circle'(圆)。[ default: 'polygon' ]
                        indicator,
                    },
                ],
                series: [
                    {
                        name: /* 雷达图 */ UdeskLocales['current'].pages.coach.learningCenter.record
                            .detail.components.comprehensiveEvaluation.index.radarChart,
                        // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
                        type: 'radar',
                        // 系列类型: 雷达图
                        itemStyle: seriesItemStyle,
                        data: [getThisItem(echartData.res)],
                        zlevel: 2,
                    },
                ],
            };

            setRadarOption(radarOption);
        },
    });

    return (
        <Wrap ref={ref}>
            {radarOption ? (
                <ReactEcharts
                    ref={echartsRef}
                    type="radar"
                    seriesColumns={radarSeriesColumns}
                    top={100}
                    rerenderOptimization={true}
                    finalizeChartOptions={() => radarOption}
                />
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Wrap>
    );
};

export default memo(ComprehensiveEvaluation);
