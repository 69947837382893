import React, { FC, useEffect, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { Select, Space, Tooltip } from 'udesk-ui';
import styled from 'styled-components';
import { InformationOutlined } from '@udesk/icons';
import { getAiModelsFindAll } from 'src/api/aiModels/findAll';
import { AiModelFoundResponse } from 'src/api/types';
import { TypeEnum } from 'Component/pages/semantic-intelligence/intelligent-model/const';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    background-color: rgb(247, 247, 247);
    padding: 16px;
`;

const InfoWrapper = styled.div`
    white-space: pre-wrap;
    color: #1a6eff;
`;

const Tip = styled.div`
    color: rgba(0, 0, 0, 0.45);
`;

type TemplateProps = {
    value?: number;
    onChange?: (v: any) => void;
};

const Template: FC<TemplateProps> = (props) => {
    const { value, onChange } = props;

    const { data } = useRequest(getAiModelsFindAll);

    const [model, setModel] = useState<AiModelFoundResponse>();

    useEffect(() => {
        setModel(data?.data?.find((d) => d.id === value));
    }, [data?.data, value]);

    const onInnerChange = (v) => {
        const t = data?.data?.find((d) => d.id === v);
        onChange?.(t);
        setModel(t);
    };

    const typeLabel = useMemo(() => {
        return model?.type === TypeEnum.Conform
            ? /* 是否符合 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.customModelsSelect.index.whetherItMeetsTheRequirements
            : model?.type === TypeEnum.GradedEvaluation
            ? /* 分档评价 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.customModelsSelect.index.gradedEvaluation
            : '';
    }, [model]);

    return (
        <Wrapper>
            <Space>
                <Select
                    style={{ width: 300 }}
                    value={value}
                    onChange={onInnerChange}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return ((option?.children?.props?.children ?? '') as string)
                            .toLowerCase()
                            .includes(input.toLowerCase());
                    }}
                >
                    {(data?.data || []).map((d) => {
                        return (
                            <Select.Option value={d.id!}>
                                <Tooltip
                                    title={
                                        <InfoWrapper>
                                            <InformationOutlined style={{ marginRight: 8 }} />
                                            {d.modelDesc}
                                        </InfoWrapper>
                                    }
                                    placement="right"
                                    color="#E6F3FF"
                                >
                                    {d.name}
                                </Tooltip>
                            </Select.Option>
                        );
                    })}
                </Select>

                <Tip>{typeLabel}</Tip>
            </Space>
        </Wrapper>
    );
};

export default Template;
