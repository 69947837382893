import React, { FC, useEffect, useRef, useState } from 'react';
import { useKeyPress } from 'ahooks';
import { Button, Icon, Input, Tooltip } from 'udesk-ui';
import LatitudeModal from './components/LatitudeModal';
import styled from 'styled-components';
import { SearchOutlined } from '@udesk/icons';
import './index.less';
import UdeskLocales from 'UdeskLocales';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

type SearchProps = {
    onSearch: (v: string) => void;
    loading: boolean;
    prompt: string;
};

const Search: FC<SearchProps> = (props) => {
    const { loading, prompt } = props;

    const [params, setParams] = useState('');

    useEffect(() => {
        setParams(prompt);
    }, [prompt]);

    const onPromptChange = (v) => {
        setParams(v);
    };

    const onSearch = () => {
        if (params.trim()) {
            props.onSearch(params);
        }
    };

    const inputRef = useRef<any>(null);

    const [visible, setVisible] = useState(false);

    const [selectionPos, setSelectionPos] = useState<number>(0);

    useKeyPress(
        ['shift.2'], // @
        (event: any) => {
            const { value, selectionStart } = event.target;
            console.log(event, value, selectionStart);
            setVisible(true);
            setSelectionPos(selectionStart);
        },
        {
            events: ['keydown'],
            target: () => inputRef.current?.input,
        }
    );

    const onCancel = () => {
        setVisible(false);
        setSelectionPos(0);
    };

    const onOk = (val) => {
        const front = params.slice(0, selectionPos + 1);
        const back = params.slice(selectionPos + 1);
        if (front.endsWith('@')) {
            const v = front.slice(0, front.length - 1) + val + back;
            setParams(v);
            setTimeout(() => {
                const newPos = selectionPos + val.length;
                inputRef.current?.input?.setSelectionRange?.(newPos, newPos);
            });
        }
        onCancel();
    };

    return (
        <Wrapper>
            <Tooltip title={/* 输入@可打开弹框进行维度选择 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.search.index.enterToOpenThePopupBoxForDimensionSelection}>
                <Icon type="jifei" style={{ fontSize: 14 }} />
            </Tooltip>

            <Input
                ref={inputRef}
                value={params}
                onChange={(e) => onPromptChange(e.target.value)}
                style={{
                    flex: 1,
                    fontWeight: 'normal',
                    marginLeft: 8,
                    marginRight: 20,
                }}
                placeholder={/* 请输入问询语句直接进行查询 */UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.search.index.pleaseEnterAQueryStatementToDirectlyQuery}
                prefix={<SearchOutlined />}
                allowClear
            />

            <Button type="primary" onClick={onSearch} disabled={loading}>{/* 查询 */}{UdeskLocales['current'].pages.coach.dataAnalysis.intelligentAnalysis.components.search.index.query}</Button>

            <LatitudeModal visible={visible} onOk={onOk} onCancel={onCancel} />
        </Wrapper>
    );
};

export default Search;
