import React from 'react';
import PropTypes from 'prop-types';
// import Udesk from 'Udesk';

class SelectCallTimeComponent extends React.Component {
    //#region Default Props
    static propTypes = {
        onDateChanged: PropTypes.func.isRequired,
        recordCount: PropTypes.number,
        callTimeText: PropTypes.string,
        callTimeRemindText: PropTypes.string,
        callRecordCountText: PropTypes.string,
        showTime: PropTypes.bool,
    };
    static defaultProps = {
        recordCount: 0,
        callTimeText: null,
        callTimeRemindText: null,
        callRecordCountText: null,
        showTime: false,
    };
    //#endregion

    state = {

    };
    privates = {
        callTimeText: "",
        callTimeRemindText: "",
        callRecordCountText: "",
        selectDate: [],
    };
    static computes = {

    };
    actions = {
        dateRangeChanged(value) {
            if(this.props.showTime){
                this.trigger("onDateChanged", value.map(item => item.second(0).millisecond(0)));
            }else{
                this.trigger("onDateChanged", value.map(item => item.hour(0).minute(0).second(0).millisecond(0)));
            }
            this.privates.selectDate = value;
            this.actions.update();
        },
        clearDateRange() {
            this.privates.selectDate = [];
            this.actions.update();
        }
    };

    //#region Life Cycle
    parseProps({ props, prevProps }) {
        this.privates.callTimeText = props.callTimeText || this.locales.components.pages.components.selectCallTime.callTime;
        this.privates.callTimeRemindText = props.callTimeRemindText || this.locales.components.pages.components.selectCallTime.callTimeRemind;
        this.privates.callRecordCountText = props.callRecordCountText || this.locales.components.pages.components.selectCallTime.callRecordCount;
    }
    componentDidMount() {
    }
    componentWillUnmount() {

    }
    //#endregion
}


export default SelectCallTimeComponent;