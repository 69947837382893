import React, { FC, useMemo } from 'react';
import { Button, Tooltip } from 'udesk-ui';
import { linkToStart } from '../../../../components/util';
import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';

type OnceMoreProps = {
    taskId?: any;
    history: any;
    onClick?: () => void;
    remainingTimes?: number;
    taskStatus?: number;
    courseId?: number;
    taskType?: number;
    dialogueMode?: number;
};

const OnceMore: FC<OnceMoreProps> = (props) => {
    const {
        history,
        taskId,
        onClick,
        taskStatus,
        remainingTimes = 0,
        courseId,
        taskType,
        dialogueMode,
    } = props;

    const disabled = useMemo(() => {
        if (taskType === Udesk.enums.learningTaskType.underWay.id) {
            return (
                [
                    Udesk.enums.learningTaskStatusType.loseEfficacy.id,
                    Udesk.enums.learningTaskStatusType.notStarted.id,
                    Udesk.enums.learningTaskStatusType.finished.id,
                ].includes(taskStatus) || remainingTimes <= 0
            );
        }
        if (taskType === Udesk.enums.learningTaskType.notStarted.id) {
            return [
                Udesk.enums.learningTaskStatusType.loseEfficacy.id,
                Udesk.enums.learningTaskStatusType.notStarted.id,
                Udesk.enums.learningTaskStatusType.finished.id,
            ].includes(taskStatus);
        }
        return true;
    }, [remainingTimes, taskStatus, taskType]);

    const onOnceMore = () => {
        onClick
            ? onClick()
            : linkToStart(
                  history,
                  taskType,
                  dialogueMode,
                  {
                      id: taskId,
                  },
                  {},
                  { lessonId: courseId }
              );
    };

    return (
        <Tooltip
            title={
                taskType === Udesk.enums.learningTaskType.underWay.id
                    ? `${
                          /* 剩余次数： */ UdeskLocales['current'].pages.coach.learningCenter.record
                              .detail.components.onceMore.index.remainingTimes
                      }${remainingTimes}${
                          /* 次 */ UdeskLocales['current'].pages.coach.learningCenter.record.detail
                              .components.onceMore.index.second
                      }`
                    : null
            }
        >
            <Button type="primary" disabled={disabled} onClick={onOnceMore}>
                {/* 再来一次 */}
                {
                    UdeskLocales['current'].pages.coach.learningCenter.record.detail.components
                        .onceMore.index.doItAgain
                }
            </Button>
        </Tooltip>
    );
};

export default OnceMore;
