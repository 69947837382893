import React from "react";
import Udesk from 'Udesk';
import Locales from "UdeskLocales";
import { getSystemModule } from 'Udesk/system/subApp';

class IndexManagementComponent extends React.Component {
    static defaultProps = {

    };
    privates = {
        columns: [],
        data: [],
        paging: {
            pageSize: 20,
            pageNum: 1
        },
        initTreeValue: undefined,
        dataSource: 0,
        keyWord: '',
        selectedItems: [],
        callTimeRange: [],
        sourceList: [
            {
                value: 0,
                text: Locales['current'].fix.callAndConversation
            },
            {
                value: 1,
                text: Locales['current'].fix.call
            },
            {
                value: 2,
                text: Locales['current'].fix.conversation
            }
        ],
        sysType: [
            {
                key: 1,
                text: Locales['current'].fix.system
            },
            {
                key: 2,
                text: Locales['current'].fix.customize
            }
        ],
        status: [
            {
                value: 1,
                text: Locales['current'].fix.invalid
            },
            {
                value: 0,
                text: Locales['current'].fix.effective
            }
        ],
    };
    actions = {
        pageChanged(pageNum, pageSize) {
            this.privates.paging.pageNum = pageNum;
            this.privates.paging.pageSize = pageSize;
            this.privates.selectedItems = [];
            this.actions.update();
            this.actions.reloadAsyncModel();
        },
        onChangeGrounp(value) {
            this.privates.initTreeValue = value;
            this.privates.paging.pageNum = 1;
            this.privates.selectedItems = [];
            this.actions.reloadAsyncModel();
            this.actions.update();
        },
        sourceChange(value) {
            this.privates.dataSource = value;
            this.privates.paging.pageNum = 1;
            this.privates.selectedItems = [];
            this.actions.reloadAsyncModel();
            this.actions.update();
        },
        onPressEnterInput(e) {
            this.privates.keyWord = e.target.value;
            this.privates.paging.pageNum = 1;
            this.privates.selectedItems = [];
            this.actions.reloadAsyncModel();
        },
        fieldsChange() {
            let routeOptions = {
                history: this.props.history,
                routeName: "customerServiceIndexManagementDetailNew"
            };
            Udesk.ui.routing.transitionTo(routeOptions);
        },
        initDataMap(data) {
            if (data && data.length > 0) {
                data.forEach(item => {
                    item.title = item.agentGroupName;
                    item.value = item.id;
                    item.key = item.id;
                    if (item.children) {
                        this.actions.initDataMap(item.children);
                    }
                });
            }
        },
        deleteItem(item) {
            let { sdkOptions } = this.props;
            if (
                this.privates.paging.pageNum !== 1 &&
                (this.privates.paging.pageNum - 1) * this.privates.paging.pageSize === this.privates.paging.total - 1
            ) {
                this.privates.paging.pageNum--;
                this.actions.update();
            }
            let url = Udesk.business.apiPath.concatApiPath(`agentPictureIndicators/${item.id}`, sdkOptions);
            return new Promise((resolve, reject) => {
                Udesk.ajax.del(url).then(
                    resp => {
                        resolve(resp);
                        this.privates.selectedItems = [];
                        this.actions.reloadAsyncModel();
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                        reject(reason);
                    }
                );
            });
        },
        redo(flag) {
            if (!flag) {
                this.privates.selectCallTimeVisible = false;
                this.actions.update();
                return;
            }
            let { callTimeRange, selectedItems } = this.privates;
            let url = Udesk.business.apiPath.concatApiPath("agentPictureIndicators/redo", this.props.sdkOptions);
            let data = {
                startTime: moment(callTimeRange[0]).hour(0).minute(0).second(0).millisecond(0).format("YYYY-MM-DD HH:mm:ss"),
                endTime: moment(callTimeRange[1]).hour(23).minute(59).second(59).millisecond(999).format("YYYY-MM-DD HH:mm:ss"),
                ids: selectedItems.map(item => item.id),
                systemModule: getSystemModule(),
            };
            Udesk.ajax.post(url, data).then(
                (resp) => {
                    this.privates.selectCallTimeVisible = false;
                    this.privates.selectedItems = [];
                    Udesk.ui.notify.success(Locales['current'].fix.successfulAndToTaskCenter);
                    this.actions.update();
                },
                (reason) => {
                    Udesk.ui.notify.error(reason.errorMsg || Locales['current'].fix.recalculationFailed);
                }
            );
        },
        selectCallTimeVisible() {
            let { selectedItems } = this.privates;
            if (selectedItems.length === 0) {
                return Udesk.ui.notify.error(Locales['current'].fix.selectRecalculatedIndicator);
            }
            this.privates.selectCallTimeVisible = true;
            this.actions.update();
        },
        onDateChanged(value) {
            this.privates.callTimeRange = value;
            if (moment(value[0]).add(31, "days") <= value[1]) {
                return Udesk.ui.notify.error(this.locales.components.pages.intelligentTags.index.callTimeError);
            }
        },
        onCheckChange(value) {
            this.privates.selectedItems = value;

            this.actions.update();
        },
        editItem(value) {
            let routeOptions = {
                history: this.props.history,
                routeName: "customerServiceIndexManagementDetailEdit",
                pathParams: {
                    id: value.id
                }

            };
            Udesk.ui.routing.transitionTo(routeOptions);
        }
    };
    init() {
        const company = Udesk.data.init.company;
        if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
            this.privates.dataSource = 0;
        } else if (company.enabledCallInspect !== 0) {
            this.privates.dataSource = 1;
        } else if (company.enabledImInspect !== 0) {
            this.privates.dataSource = 2;
        }
    }
    componentDidMount() {
        getColumns(this);
    }
    onAsyncModelResolved(asyncModel, parseOptions) {
        let { asyncResult, asyncKey } = parseOptions;
        if (asyncKey === 'initTree') {
            if (asyncResult.listData) {
                if (asyncResult.listData.data) {
                    this.privates.data = asyncResult.listData.data;
                }
                if (asyncResult.listData.paging) {
                    this.privates.paging = asyncResult.listData.paging;
                    let { pageNum, pageSize, total } = this.privates.paging;
                    this.trigger('onPageChanged', pageNum, pageSize, total);
                }

            }
            if (asyncResult.initData) {
                this.privates.initData = asyncResult.initData.data;
                this.actions.initDataMap(this.privates.initData);
            }
            this.actions.update();
        }

    }
}
function getColumns(that) {
    // let { sdkOptions } = that.props;
    let { actions, locales } = that;
    let colums = [
        {
            name: "indicatorName",
            width: "20%",

            caption: locales.fields.IndexManagement.list.indicatorName
        },
        {
            name: "indicatorDesc",
            width: "20%",
            sortable: false,
            caption: locales.fields.IndexManagement.list.indicatorDesc
        },
        {
            name: "inspectDataSource",
            width: "10%",
            sortable: false,
            caption: locales.fields.IndexManagement.list.inspectDataSource,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                if (that.privates.sourceList.find(current => current.value === item.inspectDataSource)) {
                    return that.privates.sourceList.find(current => current.value === item.inspectDataSource).text;
                } else {
                    return '--';
                }
            }
        },
        {
            name: "agentGroups",
            width: "20%",
            sortable: false,
            caption: locales.fields.IndexManagement.list.agentGroups,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                if (item.agentGroups && item.agentGroups.length > 0) {
                    let list = item.agentGroups.map(items => items.agentGroupName);
                    return list.join(',');
                } else {
                    return '--';
                }
            }
        },
        {
            name: "sysType",
            width: "10%",
            caption: locales.fields.IndexManagement.list.sysType,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                if (that.privates.sysType.find(current => current.key === item.sysType)) {
                    return that.privates.sysType.find(current => current.key === item.sysType).text;
                } else {
                    return '--';
                }
            }
        },
        {
            name: "activity",
            width: "10%",
            caption: locales.fields.IndexManagement.list.activity,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                if (that.privates.status.find(current => current.value === item.activity)) {
                    return that.privates.status.find(current => current.value === item.activity).text;
                } else {
                    return '--';
                }
            }
        },
        {
            name: "actions",
            width: "20%",
            sortable: false,
            caption: locales.fields.IndexManagement.list.actions,
            hasTemplate: true,
            getYieldContent: (name, item, index) => {
                return (
                    <div className="qa-react-page-body-content-table-actions">

                        <span
                            className="action-btn"
                            title={locales.labels.edit}
                            onClick={actions.editItem.params(item)}
                        >
                            {locales.labels.edit}
                        </span>
                        {
                            item.sysType !== 1 && (
                                <span
                                    className="action-btn delete-btn"
                                    title={locales.labels.delete}
                                    onClick={actions.deleteItem.params(item)}
                                >
                                    {locales.labels.delete}
                                </span>
                            )
                        }

                    </div>
                );
            }
        }
    ];
    that.privates.columns = colums;
    that.actions.update();
}
export default IndexManagementComponent;

