import React, { useState, useMemo, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Udesk from 'Udesk';
import UdeskTable from 'Component/common/udesk-table';
import ColumnsManageModal from 'Component/pages/columns-manage';
import {Spin} from 'udesk-ui';

const STORAGE_KEY_PREFIX = 'udeskQa.React-table.columns.version';

const Template = React.memo(
    forwardRef((props: any, ref: any) => {
        const { originColumnsProps, ...tableProps } = props;
        const { onApply: onApplyProps, ...originColumnsPropsFormat } = originColumnsProps;
        // const [originVersion, setOriginVersion] = useState('');
        // const [originColumns, setOriginColumns] = useState([]);
        const [originData, setOriginData] = useState<any>({});
        const [loading, setLoading] = useState<boolean>(true);
        const tableRef = useRef<any>(null);
        useImperativeHandle(ref, () => tableRef.current);

        const onApply = (checkedList) => {            
            tableRef.current?.actions?.onTransferChangeWithoutSort?.(checkedList);
            typeof onApplyProps === 'function' && onApplyProps();
        };
        useEffect(() => {
            let showListUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionTaskShowFields/${originColumnsProps.taskId}`,
                props.sdkOptions
            );
            Udesk.ajax.get(showListUrl).then(
                (resp) => {
                    if (Array.isArray(resp?.data?.showList) && resp?.data?.updateTime) {
                        // setOriginVersion(resp.data.updateTime);
                        // setOriginColumns(resp.data.showList || []);
                        setOriginData({
                            originVersion: resp.data.updateTime,
                            originColumns: resp.data.showList || [],
                        });
                    }
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                }
            );
        }, []);
        const versionCacheKey = useMemo(() => computeStorageKey(tableProps.cacheKey), [tableProps.cacheKey]);

        return (
            <React.Fragment>
                {loading ? (
                    <Spin tip='Loading...' style={{ width: '100%', height: 300, marginTop: 50 }}></Spin>
                ) : (
                    <UdeskTable
                        {...tableProps}
                        hasOriginColumnsControl={true}
                        ref={tableRef}
                        originVersion={originData.originVersion}
                        versionCacheKey={versionCacheKey}
                        originColumns={originData.originColumns}
                    ></UdeskTable>
                )}
                <ColumnsManageModal {...originColumnsPropsFormat} onApply={onApply} />
            </React.Fragment>
        );
    })
);

class Component extends React.Component {
    render() {
        const { tableRef, ...props } = this.props as any;
        return <Template {...props} ref={tableRef} />;
    }
}
export default Udesk.react.udeskify(Component);

function computeStorageKey(cacheKey) {
    let storageKey = STORAGE_KEY_PREFIX + '.' + cacheKey;
    return storageKey;
}
// function saveLocalStorage(key, target) {
//     if (Udesk.browser.storage.localStorage.available && key !== false) {
//         if (typeof target !== 'string') {
//             Udesk.browser.storage.localStorage.setItem(key, JSON.stringify(target));
//         } else {
//             Udesk.browser.storage.localStorage.setItem(key, target);
//         }
//     }
// }
