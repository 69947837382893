import React from 'react';
import './style.scss';
import Udesk from 'Udesk';
import sentenceSearchIndexComponent from './component';
import { Spin, Button } from 'udesk-ui';
export default class sentenceSearchIndexTemplate extends sentenceSearchIndexComponent {
    render() {
        let { total } = this.state;
        let { inputValue, sentenceDataList, sentencesRef, pageNum, pageSize } = this.privates;
        let {
            actions,
            locales,
            privates
        } = this;
        return (
            <React.Fragment>
                <div className='smart-tools-sider-bar-index-page-drawer-body'>
                    <div className='smart-tools-sider-bar-index-page-drawer-body-sentence-search-bar-container'>
                        <div className={`smart-tools-sider-bar-index-page-drawer-body-sentence-search-bar${privates.showTransBtn ? ' smart-tools-sider-bar-index-page-drawer-body-search-bar-searching' : ''}`}>
                            <input
                                type="text"
                                className='smart-tools-sider-bar-index-page-drawer-body-input'
                                value={inputValue}
                                onChange={actions.inputValueChangeHandle}
                                onKeyPress={actions.keyupadditem}
                                onBlur={actions.inputValueFormatter}
                                placeholder={locales.components.pages.smartTools.sentenceSearch.placeholder}
                            />
                            <span
                                className="udesk-qa-react-web-iconfont smart-tools-sider-bar-index-page-drawer-body-input-search"
                                onClick={actions.search}
                            >
                                &#xe60b;
                            </span>
                        </div>
                        <If condition={privates.showTransBtn}>
                            <div className='smart-tools-sider-bar-index-page-drawer-body-sentence-search-bar-btns'>
                                <Button size='small' type="link" onClick={actions.goToCallList}>{locales.fix.justCall}</Button>
                                <Button size='small' type="link" onClick={actions.goToImList}>{locales.fix.justConversation}</Button>
                            </div>
                        </If>

                    </div>
                    <Spin spinning={privates.loading}>
                        <div className='smart-tools-sider-bar-index-page-drawer-body-sentence-content' ref={sentencesRef} id='drawerBodySentenceContent'>
                            {
                                sentenceDataList.map((sentence, index) => {
                                    return (
                                        <div className='smart-tools-sider-bar-index-page-drawer-body-sentence-content-item'
                                            // key={Udesk.utils.string.uuid()}
                                            key={actions.setKey.params(index)}
                                        >
                                            <div className='smart-tools-sider-bar-index-page-drawer-body-sentence-content-item-text'>
                                                {sentence.sentence}
                                            </div>
                                            <div onClick={actions.learnMore.params(sentence.callId, sentence.dataSource)} style={{ color: 'rgba(27,109,255,1)', cursor: 'pointer', userSelect: 'none' }}>
                                                {locales.components.pages.smartTools.sentenceSearch.more}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Spin>
                </div>
                <div className='smart-tools-sider-bar-index-page-drawer-footer' style={{ display: total === 0 ? 'none' : 'flex' }}>
                    <div className='smart-tools-sider-bar-index-page-drawer-footer-left'>
                        {Udesk.utils.string.format(locales.components.pages.smartTools.sentenceSearch.total, total)}
                    </div>
                    <div className='smart-tools-sider-bar-index-page-drawer-footer-right'>
                        <div className='smart-tools-sider-bar-index-page-drawer-footer-right-page'
                            style={{
                                color: pageNum === 1 ? 'rgba(0,0,0,0.25)' : 'rgba(27,109,255,1)',
                                cursor: pageNum === 1 ? 'not-allowed' : 'pointer'
                            }}
                            onClick={actions.pageUp}
                        >
                            {locales.components.pages.smartTools.sentenceSearch.pageUp}
                        </div>
                        <div className='smart-tools-sider-bar-index-page-drawer-footer-right-page'
                            style={{
                                color: pageNum * pageSize >= total ? 'rgba(0,0,0,0.25)' : 'rgba(27,109,255,1)',
                                cursor: pageNum * pageSize >= total ? 'not-allowed' : 'pointer'
                            }}
                            onClick={actions.pageDown}
                        >
                            {locales.components.pages.smartTools.sentenceSearch.pageDown}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}