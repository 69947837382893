import Udesk from 'Udesk';
import TrainingHistoryIndexComponent from './component';

class TrainingHistoryIndexRoute extends TrainingHistoryIndexComponent {
    asyncModel(changedParams) {
        let {
            sdkOptions,
            // match
        } = this.props;
        let {
            paging,
            startTime,
            endTime
        } = this.privates;

        let params = {
            pageNum: paging.pageNum,
            pageSize: paging.pageSize,
            trainingType: 1,
            startTime: startTime,
            endTime: endTime
        };

        let url = Udesk.business.apiPath.concatApiPath(`tagTrainingHistorys/list`, sdkOptions);

        return new Promise((resolve, reject) => {
            Udesk.ajax.post(url, params).then(
                resp => {
                    if(resp.paging){
                        this.privates.paging.total = resp.paging.total;
                    }
                    this.privates.data = [];
                    resp.data.length !== 0 && resp.data.forEach(item => {
                        let data = {};
                        // {
                        //     key: '1',
                        //     time: 'John Brown',
                        //     user: 'xxxxxxxxxxxx',
                        //     tag: 'xxxxxxxxxxxxxxxxxx',
                        //     similarCount: 12,
                        //     deleteCount: 15,
                        // },
                        data.key = item.id;
                        data.time = item.createTime;
                        data.user = item.name;
                        data.tag = item.tagName;
                        data.similarCount = item.similarCount;
                        data.deleteCount = item.deleteCount;
                        this.privates.data.push(data);
                    });
                    this.actions.update();
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
    }
}

export default TrainingHistoryIndexRoute;
