// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetCompanyGeneralSettingssQuery,
    BaseResponseListCompanyGeneralSettingsFoundResponse,
    PostCompanyGeneralSettingssBody,
    BaseResponseCompanyGeneralSettingsFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags company-general-settings-controller
 * @link [GET] /companyGeneralSettingss
 */
export function getCompanyGeneralSettingss(
    options: ApiOptions<never, GetCompanyGeneralSettingssQuery> & {
        params: GetCompanyGeneralSettingssQuery;
    }
): Promise<BaseResponseListCompanyGeneralSettingsFoundResponse> {
    return request<never, never, GetCompanyGeneralSettingssQuery>('/companyGeneralSettingss', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getCompanyGeneralSettingss',
    });
}

/**
 * save
 *
 * @export
 * @tags company-general-settings-controller
 * @link [POST] /companyGeneralSettingss
 */
export function postCompanyGeneralSettingss(
    data: PostCompanyGeneralSettingssBody
): Promise<BaseResponseCompanyGeneralSettingsFoundResponse>;
export function postCompanyGeneralSettingss(
    data: PostCompanyGeneralSettingssBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseCompanyGeneralSettingsFoundResponse>;
export function postCompanyGeneralSettingss(
    data: PostCompanyGeneralSettingssBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseCompanyGeneralSettingsFoundResponse> {
    return request<PostCompanyGeneralSettingssBody, never, never>('/companyGeneralSettingss', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postCompanyGeneralSettingss',
    });
}

export const meta = [
    {
        tags: ['company-general-settings-controller'],
        path: '/companyGeneralSettingss',
        method: 'get',
    },
    {
        tags: ['company-general-settings-controller'],
        path: '/companyGeneralSettingss',
        method: 'post',
    },
];
