import React from 'react';
import { Button, notification } from 'udesk-ui';
import { isSalesSubApp } from 'Udesk/system/subApp';
import Locales from 'UdeskLocales';

const tranToTaskCenterNotification = () => {
    let locales = Locales['current'];

    const goToTaskCenter = () => {
        window.open(isSalesSubApp() ? '/sales/admin/task-center/task-list' : '/site/admin/task-center/task-list');
    };

    notification.success({
        key: 'exportNotification',
        message: locales.labels.taskCreated,
        description: (
            <div>
                {locales.labels.plzGoTo}
                <Button prefixCls='udesk-qa-ui-btn' type='link' size='small' onClick={goToTaskCenter}>
                    {locales.components.pages.uploadDownloadList.title}
                </Button>
                {locales.labels.view}
            </div>
        ),
    });
};

export default tranToTaskCenterNotification;
