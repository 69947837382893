import Udesk from 'Udesk';
import { getRouteNameWithNameSpace } from 'Udesk/system/subApp';

export const getRouteNameWithCA = getRouteNameWithNameSpace(
    Udesk.enums.sysModuleList.conversationAnalysis.nameSpace
);

const conversationAnalysisRoutesGlobal = [
    // 智能陪练系统
    {
        path: '/conversation-analysis',
        name: 'conversationAnalysisHome',
        routes: [
            // 数据分析
            {
                path: '/data-analysis',
                authCode: 'data:analysis:root',
                name: 'conversationAnalysisDataAnalysis',
                routes: [
                    // 舆情发现
                    {
                        path: '/public-sentiment',
                        name: 'conversationAnalysisSentimentAnalysis',
                        authCode: 'smart:discovery:root',
                        routes: [
                            {
                                path: '/analysis',
                                name: 'conversationAnalysisSentimentAnalysisIndex',
                            },
                        ],
                    },
                    // 业务分析
                    {
                        path: '/business-analysis',
                        name: 'conversationAnalysisBusinessAnalysis',
                        authCode: 'smart:analysis:root',
                        routes: [
                            {
                                path: '/list/index',
                                name: 'conversationAnalysisBusinessAnalysisList',
                            },
                            {
                                path: '/detail/:id',
                                name: 'conversationAnalysisBusinessAnalysisDetail',
                            },
                            {
                                path: '/create',
                                name: 'conversationAnalysisBusinessAnalysisCreate',
                            },
                        ],
                    },
                    // 分析报表
                    {
                        path: '/analysis-report',
                        name: 'conversationAnalysisAnalysisReport',
                        // authCode: 'smart:analysis:root',
                    },

                    //标签分析
                    {
                        path: '/intelligent-tags-analysis/index',
                        name: 'conversationAnalysisIntelligentTagsAnalysis',
                        // authCode: 'inspection:inspectorWorkload:root',
                    },
                    // 竞品分析
                    {
                        path: '/competitive-product-analysis/index',
                        name: 'conversationAnalysisCompetitiveProductAnalysis',
                        // authCode: 'inspection:inspectorWorkload:root',
                    },
                ],
            },
            //客户中心
            {
                path: '/client-center',
                name: 'conversationAnalysisClientCenterEntry',
                authCode: 'customer:root',
                routes: [
                    {
                        path: '/manage',
                        name: 'conversationAnalysisClientManageRoot',
                        routes: [
                            {
                                path: '/index',
                                name: 'conversationAnalysisClientManage',
                            },
                            {
                                path: '/wechat-index',
                                name: 'conversationAnalysisClientManageWechat',
                            },
                        ],
                    },
                    // 客户画像
                    {
                        path: '/customer-portrait',
                        name: 'conversationAnalysisCustomerPortrait',
                        authCode: 'smart:personal:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'conversationAnalysisCustomerPortraitIndex',
                            },
                        ],
                    },
                ],
            },
            {
                path: '/semantic-intelligence',
                authCode: 'semantic:root',
                name: 'conversationAnalysisSemanticIntelligence',
                routes: [
                    // 智能词库
                    {
                        path: '/smart-words-library',
                        name: 'conversationAnalysisSmartWordsLibrary',
                        authCode: 'semantic:corpora:root',
                        routes: [
                            {
                                path: '/synonym-words',
                                name: 'conversationAnalysisSmartWordsLibrarySynonymWords',
                            },
                            {
                                path: '/sensitive-words',
                                name: 'conversationAnalysisSmartWordsLibrarySensitiveWords',
                            },
                            {
                                path: '/professional-words',
                                name: 'conversationAnalysisSmartWordsLibraryProfessionalWords',
                            },
                            {
                                path: '/attention-words',
                                name: 'conversationAnalysisSmartWordsLibraryAttentionWords',
                            },
                            {
                                path: '/ignore-words',
                                name: 'conversationAnalysisSmartWordsLibraryIgnoreWords',
                            },
                            {
                                path: '/words-weight',
                                name: 'conversationAnalysisSmartWordsLibraryWordsWeight',
                            },
                            {
                                path: '/corpus-data',
                                name: 'conversationAnalysisSmartWordsLibraryCorpusData',
                            },
                            {
                                path: '/typo-library',
                                name: 'conversationAnalysisSmartWordsLibraryTypoLibrary',
                            },
                        ],
                    },
                    // 概念词
                    {
                        path: '/concept-word',
                        name: 'conversationAnalysisConceptWord',
                        authCode: 'semantic:conceptWords:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'conversationAnalysisConceptWordIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:conceptWords:add',
                                name: 'conversationAnalysisConceptWordNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:conceptWords:edit',
                                name: 'conversationAnalysisConceptWordEdit',
                            },
                        ],
                    },
                    // 语义标签
                    {
                        path: '/semantic-tags',
                        name: 'conversationAnalysisSemanticTags',
                        authCode: 'semantic:semanticTag:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'conversationAnalysisSemanticTagsIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:semanticTag:add',
                                name: 'conversationAnalysisSemanticTagsNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:semanticTag:edit',
                                name: 'conversationAnalysisSemanticTagsEdit',
                            },
                        ],
                    },
                    // 智能标签
                    {
                        path: '/intelligent-tags',
                        name: getRouteNameWithCA('intelligentTags'),
                        authCode: 'smart:tag:root',
                        routes: [
                            {
                                path: '/smart-conversation',
                                name: getRouteNameWithCA('smartConversationIntelligentTags'),
                                routes: [
                                    {
                                        path: '/index',
                                        name: getRouteNameWithCA('intelligentTagsIndex'),
                                    },
                                    {
                                        path: '/new',
                                        authCode: 'smart:tag:add',
                                        name: getRouteNameWithCA('intelligentTagsNew'),
                                    },
                                    {
                                        path: '/edit/:id',
                                        authCode: 'smart:tag:edit',
                                        name: getRouteNameWithCA('intelligentTagsEdit'),
                                    },
                                ],
                            },
                            {
                                path: '/wechat/index',
                                authCode: 'smart:wecomTag:root',
                                name: getRouteNameWithCA('wechatIntelligentTags'),
                            },
                            {
                                path: '/ignore-label/index',
                                // authCode: 'smart:wecomTag:root',
                                name: getRouteNameWithCA('ignoreIntelligentTags'),
                            },
                        ],
                    },
                    // 信息实体
                    {
                        path: '/information-entities',
                        name: 'conversationAnalysisInformationEntities',
                        authCode: 'semantic:imformation:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'conversationAnalysisInformationEntitiesIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:imformation:add',
                                name: 'conversationAnalysisInformationEntitiesNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:imformation:edit',
                                name: 'conversationAnalysisInformationEntitiesEdit',
                            },
                        ],
                    },
                    // 交互识别
                    {
                        path: '/interactive-recognition',
                        name: 'conversationAnalysisInteractiveRecognition',
                        authCode: 'semantic:interactive:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'conversationAnalysisInteractiveRecognitionIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:interactive:add',
                                name: 'conversationAnalysisInteractiveRecognitionNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:interactive:edit',
                                name: 'conversationAnalysisInteractiveRecognitionEdit',
                            },
                        ],
                    },
                    {
                        path: '/knowledge-base',
                        name: 'conversationAnalysisKnowledgeBase',
                        authCode: 'semantic:knowledgeData:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'conversationAnalysisKnowledgeBaseIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'semantic:knowledgeData:add',
                                name: 'conversationAnalysisKnowledgeBaseNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'semantic:knowledgeData:edit',
                                name: 'conversationAnalysisKnowledgeBaseEdit',
                            },
                        ],
                    },
                    // 数据预处理
                    {
                        path: '/data-preprocess',
                        name: 'conversationAnalysisDataPreprocess',
                        authCode: 'datapre:rule:root',
                        routes: [
                            {
                                path: '/index',
                                authCode: 'datapre:rule:view',
                                name: 'conversationAnalysisDataPreprocessIndex',
                            },
                            {
                                path: '/new',
                                authCode: 'datapre:rule:save',
                                name: 'conversationAnalysisDataPreprocessNew',
                            },
                            {
                                path: '/edit/:id',
                                authCode: 'datapre:rule:update',
                                name: 'conversationAnalysisDataPreprocessEdit',
                            },
                        ],
                    },
                ],
            },
            // 训练中心
            {
                path: '/training-center',
                name: 'conversationAnalysisTrainingCenter',
                authCode: 'training:root',
                routes: [
                    // 快速训练
                    {
                        path: '/fast-training',
                        name: 'conversationAnalysisFastTrainingIndex',
                        authCode: 'training:fast:root',
                    },
                    // 训练历史
                    {
                        path: '/training-history',
                        name: 'conversationAnalysisTrainingHistoryIndex',
                        authCode: 'training:history:root',
                    },
                    // 分类训练
                    {
                        path: '/classified-training',
                        name: 'conversationAnalysisClassifiedTraining',
                        authCode: 'training:categoryTrain:train',
                        routes: [
                            {
                                path: '/index',
                                name: 'conversationAnalysisClassifiedTrainingIndex',
                            },
                            {
                                path: '/detail/:id',
                                name: 'conversationAnalysisClassifiedTrainingDetail',
                            },
                        ],
                    },
                    // 分类训练历史
                    {
                        path: '/classified-history-training',
                        name: 'conversationAnalysisClassifiedTrainingHistoryIndex',
                        authCode: 'training:categoryTrain:trainHistory',
                    },
                    // 聚类标注
                    {
                        path: '/clustering-annotation-index',
                        name: 'conversationAnalysisClusteringAnnotationIndex',
                        authCode: 'training:clusterMark:root',
                    },
                ],
            },
            // 通话管理
            {
                path: '/call-manage',
                authCode: 'call:root',
                name: 'conversationAnalysisCallManage',
                routes: [
                    {
                        path: '/index',
                        name: 'conversationAnalysisCallManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'conversationAnalysisCallManageView',
                    },
                ],
            },
            // 对话管理
            {
                path: '/dialogue-manage',
                authCode: 'im:root',
                name: 'conversationAnalysisDialogueManage',
                routes: [
                    {
                        path: '/index',
                        name: 'conversationAnalysisDialogueManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'conversationAnalysisDialogueManageView',
                    },
                ],
            },
            // 工单管理
            {
                path: '/ticket-manage',
                authCode: 'ticket:root',
                name: 'conversationAnalysisTicketManage',
                routes: [
                    {
                        path: '/index',
                        name: 'conversationAnalysisTicketManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'conversationAnalysisTicketManageView',
                    },
                ],
            },
            // 电商会话管理
            {
                path: '/e-commerce-manage',
                authCode: 'mallcall:root',
                name: 'conversationAnalysisECommerceManage',
                routes: [
                    {
                        path: '/index',
                        name: 'conversationAnalysisECommerceManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'conversationAnalysisECommerceManageView',
                    },
                ],
            },
            // 自定义数据源
            {
                path: '/custom-data-source',
                name: 'customDataSource',
                authCode: 'sys:module:root',
                routes: [
                    {
                        path: '/amazon',
                        name: 'amazonList',
                        authCode: 'sys:module:list:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'amazonListIndex',
                            },
                        ],
                    },
                    {
                        path: '/youtube',
                        name: 'youtubeList',
                        authCode: 'sys:module:list:root',
                        routes: [
                            {
                                path: '/index',
                                name: 'youtubeListIndex',
                            },
                        ],
                    },
                ],
            },
            // 视频分析
            {
                path: '/video-analysis',
                name: getRouteNameWithCA('videoAnalysis'),
                routes: [
                    {
                        path: '/index',
                        name: getRouteNameWithCA('videoAnalysisList'),
                    },
                    {
                        path: '/view/:id',
                        name: getRouteNameWithCA('videoAnalysisDetail'),
                    },
                ],
            },
            // 系统设置
            {
                path: '/admin',
                authCode: 'sys:setting:root',
                name: getRouteNameWithCA('adminEntry'),
                routes: [
                    // 系统设置
                    {
                        path: '/system/index',
                        name: getRouteNameWithCA('adminSystemIndex'),
                        authCode: 'sys:info:root',
                    },
                    // 语音识别
                    {
                        path: '/speech-recognition/index',
                        name: getRouteNameWithCA('speechRecognitionIndex'),
                        authCode: 'sys:voice:root',
                    },
                    // 消息日志
                    {
                        path: '/system-log/index',
                        name: getRouteNameWithCA('systemLogIndex'),
                        authCode: 'sys:message:root',
                    },
                    // 员工管理
                    {
                        path: '/user',
                        name: getRouteNameWithCA('adminUserEntry'),
                        authCode: 'sys:user:root',
                        routes: [
                            {
                                path: '/index',
                                name: getRouteNameWithCA('adminUserIndex'),
                            },
                            {
                                path: '/view/:id',
                                name: getRouteNameWithCA('adminUserView'),
                                authCode: 'sys:user:view',
                            },
                            {
                                path: '/new',
                                name: getRouteNameWithCA('adminUserNew'),
                                authCode: 'sys:user:add',
                            },
                            {
                                path: '/edit/:id',
                                name: getRouteNameWithCA('adminUserEdit'),
                                authCode: 'sys:user:edit',
                            },
                        ],
                    },
                    // 员工组管理
                    {
                        path: '/user-groups',
                        name: getRouteNameWithCA('adminUserGroupsEntry'),
                        authCode: 'sys:group:root',
                        routes: [
                            {
                                path: '/index',
                                name: getRouteNameWithCA('adminUserGroupsIndex'),
                            },
                            {
                                path: '/view/:id',
                                name: getRouteNameWithCA('adminUserGroupsView'),
                                authCode: 'sys:group:view',
                            },
                            {
                                path: '/new',
                                name: getRouteNameWithCA('adminUserGroupsNew'),
                                authCode: 'sys:group:add',
                            },
                            {
                                path: '/edit/:id',
                                name: getRouteNameWithCA('adminUserGroupsEdit'),
                                authCode: 'sys:group:edit',
                            },
                        ],
                    },
                    // 角色管理
                    {
                        path: '/role',
                        name: getRouteNameWithCA('role'),
                        authCode: 'sys:role:root',
                        routes: [
                            {
                                path: '/index',
                                name: getRouteNameWithCA('roleIndex'),
                            },
                            {
                                path: '/new',
                                name: getRouteNameWithCA('roleNew'),
                                authCode: 'sys:role:add',
                                routes: [
                                    {
                                        path: '/index',
                                        name: getRouteNameWithCA('roleNewBasicInfo'),
                                    },
                                ],
                            },
                            {
                                path: '/view/:id',
                                name: getRouteNameWithCA('roleView'),
                                authCode: 'sys:role:view',
                                routes: [
                                    {
                                        path: '/basic-info',
                                        name: getRouteNameWithCA('roleViewBasicInfo'),
                                    },
                                    {
                                        path: '/task-permissions',
                                        name: getRouteNameWithCA('roleViewTaskPermissions'),
                                    },
                                    {
                                        path: '/data-permissions',
                                        name: getRouteNameWithCA('roleViewDataPermissions'),
                                    },
                                ],
                            },
                            {
                                path: '/edit/:id',
                                name: getRouteNameWithCA('roleEdit'),
                                authCode: 'sys:role:edit',
                                routes: [
                                    {
                                        path: '/basic-info',
                                        name: getRouteNameWithCA('roleEditBasicInfo'),
                                    },
                                    {
                                        path: '/task-permissions',
                                        name: getRouteNameWithCA('roleEditTaskPermissions'),
                                    },
                                    {
                                        path: '/data-permissions',
                                        name: getRouteNameWithCA('roleEditDataPermissions'),
                                    },
                                ],
                            },
                        ],
                    },
                    // 数据迁移
                    {
                        path: '/data-migration/index',
                        name: getRouteNameWithCA('dataMigration'),
                        authCode: 'sys:dataTransfer:root',
                    },
                    // 任务中心
                    {
                        path: '/task-center',
                        name: getRouteNameWithCA('taskCenter'),
                        authCode: 'sys:impExp:root',
                        routes: [
                            {
                                path: '/download-list',
                                name: getRouteNameWithCA('taskCenterDownloadList'),
                            },
                            {
                                path: '/upload-list',
                                name: getRouteNameWithCA('taskCenterUploadList'),
                            },
                            {
                                path: '/task-list',
                                name: getRouteNameWithCA('taskCenterTaskList'),
                            },
                        ],
                    },
                    // 计费中心
                    {
                        path: '/billing-center',
                        name: getRouteNameWithCA('billingCenterIndex'),
                        authCode: 'hc:charge:root',
                    },
                    // 系统集成
                    {
                        path: '/integration',
                        name: getRouteNameWithCA('systemIntegration'),
                        routes: [
                            {
                                path: '/cc-pass',
                                name: getRouteNameWithCA('systemIntegrationCCpass'),
                            },
                            //企微对接
                            {
                                path: '/wecom',
                                name: getRouteNameWithCA('wechatDocking'),
                            },
                            {
                                path: '/taobao',
                                name: getRouteNameWithCA('taobaoDocking'),
                            },
                            //微丰对接
                            {
                                path: '/weifeng',
                                name: getRouteNameWithCA('weifengDocking'),
                            },
                        ],
                        // authCode: ('hc:charge:root'),
                    },
                    //客户字段
                    {
                        path: '/customer-field',
                        name: getRouteNameWithCA('customerField'),
                        authCode: 'sys:customer:field',
                    },
                    // 本地录音上传
                    {
                        path: '/record-upload/index',
                        name: getRouteNameWithCA('recordUploadIndex'),
                        authCode: 'sys:voiceUpload:root',
                    },
                    {
                        path: '/record-upload/upload-call',
                        name: getRouteNameWithCA('recordUploadDetail'),
                        authCode: 'sys:voiceUpload:root',
                    },
                    {
                        path: '/record-upload/upload-im',
                        name: getRouteNameWithCA('recordUploadDetailIm'),
                        authCode: 'sys:voiceUpload:root',
                    },
                    {
                        path: '/anomalous-data',
                        name: getRouteNameWithCA('anomalousData'),
                        authCode: 'sys:dataAbnormal:root',
                        routes: [
                            {
                                path: '/index',
                                name: getRouteNameWithCA('anomalousDataIndex'),
                            },
                        ],
                    },
                    //logo设置
                    {
                        path: '/logo-site',
                        name: getRouteNameWithCA('logoSite'),
                        // authCode: ('sys:customer:field'),
                    },
                ],
            },

            // 电商评论管理
            {
                path: '/e-comment-manage',
                authCode: 'call:root',
                name: 'conversationAnalysisECommentManage',
                routes: [
                    {
                        path: '/index',
                        name: 'conversationAnalysisECommentManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'conversationAnalysisECommentManageDetail',
                    },
                ],
            },
            // 社媒评论管理
            {
                path: '/m-comment-manage',
                authCode: 'call:root',
                name: 'conversationAnalysisMCommentManage',
                routes: [
                    {
                        path: '/index',
                        name: 'conversationAnalysisMCommentManageIndex',
                    },
                    {
                        path: '/view/:id',
                        name: 'conversationAnalysisMCommentManageDetail',
                    },
                ],
            },

            //404
            {
                path: '/404',
                name: 'conversationAnalysisPageNotFound',
            },
        ],
    },
];

export { conversationAnalysisRoutesGlobal };
