import React, { useState, useEffect, useRef, useCallback } from 'react';
import Udesk from 'Udesk';
import Locales from 'UdeskLocales';
import { PageHeader, Tabs, Card, Progress, Tag, Tree, Space, Radio, Button, Icon, Select, Empty } from 'udesk-ui';
import ChatRecord from 'Component/common/chat-record';
import type { ChatRecordProps } from 'Component/common/chat-record';
import UdeskDatePicker from 'Component/common/udesk-date-picker';
import CallTextView from 'Component/pages/components/call-text-view';
import ApplyTemplates from 'Component/pages/components/apply-templates';
// import SmartTags from 'Component/pages/components/smart-tags';
import Filter from './components/filter/index';
import './style.scss';
import moment from 'moment';
import { postWeComSessionLogTypeSearch } from 'src/api/weComSessionLog/type/search';

const { TabPane } = Tabs;
// let timer: any = null;

const QualifiedTagUnMemo = (props) => {
    const { qualified, style } = props;
    const locales = Locales['current'];

    return (
        <Tag color={qualified ? 'green' : 'red'} style={style}>
            {locales.pages.wechatQa.chartRecord.detail[qualified ? 'qualified' : 'unqualified']}
        </Tag>
    );
};

const QualifiedTag = React.memo(QualifiedTagUnMemo);

const MARGIN16 = { margin: '16px 0' };

const useAudio: () => [HTMLAudioElement, number, React.Dispatch<React.SetStateAction<number>>] = () => {
    const [instance] = useState(new Audio());
    const [isPlaying, setIsPlaying] = useState<number>(-1);

    useEffect(() => {
        const cb = () => {
            setIsPlaying(-1);
        };
        instance.addEventListener('ended', cb);
        return () => {
            instance.removeEventListener('ended', cb);
        };
    }, []);

    return [instance, isPlaying, setIsPlaying];
};


export const TimelySceneTree = React.memo((props: any) => {
    const {timelySceneList, highlightPoint, setHighlightPoint} = props;

    const triggerHighlight = (pointId) => {
        setHighlightPoint(pointId);
    };

    const titleRender = (nodeData) => {
        const showHighLight = (e) => {
            if (nodeData.pointId) {
                if (highlightPoint === nodeData.key) {
                    triggerHighlight(null);
                } else {
                    triggerHighlight(nodeData.key);
                }
            }
            e.stopPropagation?.();
        };
        return (
            <div
                className={`timely-scene-list ${nodeData.isPoint ? 'timely-scene-list-item' : ''}`}
                key={nodeData.key}
            >
                <div style={{ width: 'calc(100% - 80px)', display: 'flex' }}>
                    {nodeData.isPoint &&
                        Array.isArray(nodeData.highlightGroupsList) &&
                        nodeData.highlightGroupsList.length > 0 && (
                        <Button
                            style={{
                                color: highlightPoint === nodeData.key ? '#1b6dff' : 'rgba(0, 0, 0, .65)',
                            }}
                            type='text'
                            size='small'
                            onClick={showHighLight}
                            icon={<Icon type='daohang' />}
                        />
                    )}
                    <div className='timely-scene-list-title' title={nodeData.title}>
                        {nodeData.title}
                    </div>
                </div>
                <div className='timely-scene-list-score'>
                    <Space>
                        <div>{nodeData.score}</div>
                        <div style={{ minWidth: 60 }}>
                            {!nodeData.isPoint && <QualifiedTag qualified={nodeData.qualified} />}
                        </div>
                    </Space>
                </div>
            </div>
        );
    };

    return (
        timelySceneList.length > 0 ? (
            <Tree
                defaultExpandAll
                blockNode
                treeData={timelySceneList}
                titleRender={titleRender}
                key={timelySceneList.map(node => node.key).join()}
            />
        ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
    );
});


function WechatQaChartRecordDetail(props) {
    // const containerDom = useRef(null);
    const locales = Locales['current'];
    const TABS_OPTIONS = [
        { label: locales.pages.wechatQa.chartRecord.detail.today, value: 'today' },
        { label: locales.pages.wechatQa.chartRecord.detail.history, value: 'history' },
    ];
    const [id, setId] = useState(0);
    const [selectedSession, setSelectedSession] = useState<any>(null);
    const [nextId, setNextId] = useState(0);
    const [height, setHeight] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showTopMore, setShowTopMore] = useState(true);
    const [percent, setPercent] = useState(0);
    const [detail, setDetail] = useState<any>({});
    const [templates, setTemplates] = useState<any>({});
    // const [smartTags, setSmartTags] = useState<any>([]);
    const [data, setData] = useState<any[]>([]);
    const [addDataLength, setAddDataLength] = useState<number>(0);
    const [startTime, setStartTime] = useState(moment().subtract('days', 30).format('YYYY-MM-DD') + ' 00:00:00');
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DD') + ' 23:59:59');
    // const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
    const [mesgType, setMesgType] = useState('today');
    const [qualified, setQualified] = useState<number>(0);
    const [timelySceneList, setTimelySceneList] = useState<any[]>([]);
    // const [currentId, setCurrentId] = useState<number>(0);
    /**
     * highlightPoint 当前高亮的质检点id
     */
    const [highlightPoint, setHighlightPoint] = useState<string | null>(null);

    const [messageType, setMessageType] = useState<string | null>(null);
    /**
     * allHighlightPoints 收集所有的质检点高亮信息
     */
    const [allHighlightPoints, setAllHighlightPoints] = useState<ChatRecordProps['allHighlightPoints']>([]);
    const dataIndexMap = useRef({});
    const [playingRudio, isPlaying, setIsPlaying] = useAudio();

    const playRudio = useCallback(
        (url, index) => {
            playingRudio.pause();
            if (isPlaying === -1 || isPlaying !== index) {
                playingRudio.src = url;
                playingRudio.play();
                setIsPlaying(index);
            } else {
                setIsPlaying(-1);
            }
        },
        [isPlaying]
    );
    const loadMoreData = useCallback(
        (position) => {
            let { sdkOptions } = props;
            if (!nextId) return;

            setLoading(true);
            /**
             * 获取加载更多的会话消息，以判断是否展示按钮
             */
            let url = Udesk.business.apiPath.concatApiPath(`data/${nextId}/weComCallNextDetail`, sdkOptions);
            Udesk.ajax.post(url, { startTime, endTime }).then(
                (resp) => {
                    let map = new Map();
                    [
                        ...(resp.data?.dialogRecords?.dialogSentenceList ?? []).map((i) => ({
                            ...i,
                            playRudio: playRudio,
                            isPlaying: isPlaying,
                        })),
                        {
                            id: `shardingInformation-${nextId}`,
                            content: `{"type":"shardingInformation","data":{"content":"${nextId}"}}`,
                        },
                        ...data,
                    ].forEach((item) => {
                        map.set(item.id, item);
                    });
                    setData(Array.from(map.values()));
                    setNextId(resp.data?.id);
                    setAddDataLength(resp.data?.dialogRecords?.dialogSentenceList?.length ?? 0 + 1);
                    // setLoading(false);
                    if (!resp.data?.dialogRecords?.dialogSentenceList) {
                        setShowTopMore(false);
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || locales.components.pages.components.recordDetail.getDetailFailed
                    );
                }
            );
        },
        [nextId]
    );
    useEffect(() => {
        setData((prev) => prev.map((d) => ({ ...d, isPlaying, playRudio })));
    }, [isPlaying, playRudio]);
    const selectTime = (dateStrings) => {
        setStartTime(dateStrings[0]);
        setEndTime(dateStrings[1]);
        setShowTopMore(true);
    };
    const transitionToTemplate = (template) => {
        let routeOptions = {
            history: props.history,
            routeName: 'wechatQualityInspectionListDetail',
            pathParams: {
                taskId: template.taskId,
                id: template.id,
            },
        };
        Udesk.ui.routing.transitionTo(routeOptions);
    };

    const resizeHandler = () => {
        if (document.getElementById('containerDom')) {
            let newHeight = parseInt(window.getComputedStyle(document.getElementById('containerDom')!).height, 10) - 24;
            if (height !== newHeight) {
                setHeight(newHeight);
            }
        }
    };
    // const goBackToIndex = () => {
    //     let routeOptions = {
    //         history: props.history,
    //         routeName: 'wechatQaChartRecord',
    //     };
    //     Udesk.ui.routing.transitionTo(routeOptions);
    // };
    // const onItemMounted = (item) => {
    //
    // let currentIndex = data.findIndex((i: any) => i.id === item.id);
    // // setCurrentItemIndex(currentIndex);
    // for (const [key, value] of Object.entries(dataIndexMap.current)) {
    //     if (Array.isArray(value) && currentIndex >= value[0] && currentIndex <= value[1]) {
    //         parseInt(key, 10) !== currentId && setCurrentId(parseInt(key, 10));
    //         break;
    //     }
    // }
    // };

    const closeHighlight = useCallback(() => {
        setHighlightPoint(null);
    }, []);
    
    const onMesgTypeChange = useCallback((e) => {
        setMesgType(e.target.value);
        closeHighlight();
    }, []);
    const onChartChange = (callId, clickItem) => {
        if (parseInt(callId, 10) !== id) {
            setId(parseInt(callId, 10));
        }
        if (clickItem) {
            setSelectedSession(clickItem);
        }
    };
    useEffect(() => {
        // const _resizeHandler = () => {
        //     if (timer) {
        //         clearTimeout(timer);
        //     }
        //     timer = setTimeout(resizeHandler, 500);
        // };
        // window.addEventListener('resize', _resizeHandler);
        // return () => {
        //     window.removeEventListener('resize', _resizeHandler);
        // };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        resizeHandler();
    }, [data]);
    // useEffect(() => {
    //     if (parseInt(props.match.params.id, 10) !== id) {
    //         setId(parseInt(props.match.params.id, 10));
    //     }
    // }, [props.match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        let { sdkOptions } = props;
        if (!id) return;
        setLoading(true);

        /**
         * 获取智能标签
         */
        // let smartTagsUrl = Udesk.business.apiPath.concatApiPath(`callTags/${id}/tagsOfCall`, sdkOptions);
        // Udesk.ajax.get(smartTagsUrl).then(
        //     (resp) => {
        //         setSmartTags(resp.data);
        //     },
        //     (reason) => {
        //         Udesk.ui.notify.error(
        //             reason.errorMsg || locales.components.pages.components.recordDetail.getSmartTagsFailed
        //         );
        //     }
        // );
        /**
         * 获取初次会话消息以及右侧会话信息
         */
        let url = Udesk.business.apiPath.concatApiPath(`data/${id}/weComCallDetail`, sdkOptions);
        if (mesgType === 'today') {
            url = Udesk.business.apiPath.concatApiPath(`data/${id}/weComCallDetail/today`, sdkOptions);
            setShowTopMore(false);
        }else{
            setShowTopMore(true);
        }
        let weComCallDetail;
        const isFilterMessageType = messageType && selectedSession;
        if (isFilterMessageType) {
            weComCallDetail = postWeComSessionLogTypeSearch({
                msgType: messageType ?? undefined,
                sessionId: selectedSession.sessionId,
                time: `${startTime},${endTime}`,
            });
            setShowTopMore(false);
        } else {
            weComCallDetail = Udesk.ajax.post(url, { startTime, endTime });
        }
        weComCallDetail &&
            weComCallDetail.then(
                (resp) => {
                    setDetail(
                        isFilterMessageType ? { ...detail, dialogRecords: resp.data?.dialogRecords ?? [] } : resp.data
                    );
                    let dialogSentenceList = resp.data?.dialogRecords?.dialogSentenceList ?? [];
                    let highlightMessageIndex = 1;
                    dialogSentenceList.forEach((item) => {
                        // const content = item?.content ? JSON.parse(item.content) : {};
                        // if(content.type && isHighlightHitType(content.type)){
                        item.highlightMessageIndex = highlightMessageIndex;
                        item.playRudio = playRudio;
                        item.isPlaying = isPlaying;

                        highlightMessageIndex++;
                        // }
                    });
                    let map = new Map();
                    dialogSentenceList.forEach((item) => {
                        map.set(item.id, item);
                    });
                    dialogSentenceList = Array.from(map.values());
                    // setData(resp.data?.dialogRecords?.dialogSentenceList ?? []);
                    
                    setData(dialogSentenceList ?? []);
                    if (mesgType !== 'today') {
                        setNextId(parseInt(resp.data?.id, 10));
                    }
                    setLoading(false);
                    if (resp.data?.id) {
                        /**
                         * 获取右侧分析任务列表
                         */
                        let templatesUrl = Udesk.business.apiPath.concatApiPath(
                            `inspectionData/${resp.data?.id}/appliedTemplates`,
                            sdkOptions
                        );
                        // let nowId = resp.data?.id;
                        Udesk.ajax.get(templatesUrl).then(
                            (resp) => {
                                setTemplates(resp.data);
                            },
                            (reason) => {
                                Udesk.ui.notify.error(
                                    reason.errorMsg ||
                                        locales.components.pages.components.recordDetail.getTemplatesFailed
                                );
                            }
                        );
                    }
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || locales.components.pages.components.recordDetail.getDetailFailed
                    );
                }
            );
        if (mesgType === 'today') {
            /**
             * 获取实时分析信息
             */
            let inspectionWeComTimelyDatasUrl = Udesk.business.apiPath.concatApiPath(
                `inspectionWeComTimelyDatas/${id}`,
                sdkOptions
            );
            const inspectionWeComTimelyDatas = Udesk.ajax.get(inspectionWeComTimelyDatasUrl);
            inspectionWeComTimelyDatas.then(
                (resp) => {
                    const { data } = resp;
                    const { score = 0, qualified = 0, timelySceneList: timelySceneListResp = [] } = data;
                    setPercent(score);
                    setQualified(qualified);
                    let newExpandedKeys: string[] = [];
                    let ret = (timelySceneListResp ?? []).map((item) => {
                        newExpandedKeys.push(`${item.id}`);
                        return {
                            ...item,
                            title: item.name,
                            key: `${item.id}`,
                            selectable: false,
                            children: (item.timelyPointList ?? []).map((point) => ({
                                ...point,
                                title: point.name,
                                key: `${item.id}-${point.pointId}`,
                                selectable: false,
                                isPoint: true,
                                parentId: item.id,
                            })),
                        };
                    });
                    setTimelySceneList([...ret]);
                    const timelySceneList = data?.timelySceneList ?? [];
                    let allPoints: any[] = [];
                    timelySceneList.forEach((scene) => {
                        let timelyPointList = scene.timelyPointList.map((p) => ({
                            ...p,
                            parentId: scene.id,
                            highlightGroupsList: p.highlightGroupsList.map((g, groupIndex) => ({
                                ...g,
                                highlightItemList: g.highlightItemList.map((i, itemIndex) => ({
                                    ...i,
                                    groupIndex,
                                    itemIndex,
                                })),
                            })),
                        }));
                        allPoints = [...allPoints, ...timelyPointList];
                    });
                    setAllHighlightPoints(allPoints);
                },
                (reason) => {
                    Udesk.ui.notify.error(
                        reason.errorMsg || locales.components.pages.components.recordDetail.getSmartTagsFailed
                    );
                }
            );
        }
    }, [id, startTime, endTime, mesgType, messageType, selectedSession]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        let { sdkOptions } = props;
        if (!nextId) return;

        setLoading(true);
        /**
         * 获取加载更多的会话消息，以判断是否展示按钮
         */
        let url = Udesk.business.apiPath.concatApiPath(`data/${nextId}/weComCallNextDetail`, sdkOptions);
        Udesk.ajax.post(url, { startTime, endTime }).then(
            (resp) => {
                setLoading(false);
                if (!resp.data?.dialogRecords?.dialogSentenceList) {
                    setShowTopMore(false);
                } else {
                    setShowTopMore(true);
                }
            },
            (reason) => {}
        );
    }, [nextId]);
    useEffect(() => {
        if (detail.id && mesgType !== 'today') {
            Object.keys(dataIndexMap.current).forEach((key) => {
                dataIndexMap.current[key] = dataIndexMap.current[key].map((i) => i + addDataLength);
            });
            dataIndexMap.current[detail.id] = [0, detail?.dialogRecords?.dialogSentenceList?.length ?? 0];
        }
    }, [data.length, mesgType]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className='udesk-qa-web-page'>
            <PageHeader
                // onBack={goBackToIndex}
                className='udesk-qa-web-page-header'
                title={locales.pages.wechatQa.chartRecord.detail.title}
            />
            <div className='udesk-qa-web-page-body'>
                <div className='udesk-qa-web-page-body-root wechat-qa-chart-record-detail'>
                    <div className='wechat-qa-chart-record-detail-left-filter'>
                        <Filter sdkOptions={props.sdkOptions} onChartChange={onChartChange} />
                    </div>
                    <div className='wechat-qa-chart-record-detail-main'>
                        <div className='wechat-qa-chart-record-detail-left'>
                            <div
                                style={{
                                    width: '100%',
                                    padding: '0 8px 16px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    minHeight: 48,
                                }}
                            >
                                <Radio.Group
                                    value={mesgType}
                                    optionType='button'
                                    options={TABS_OPTIONS}
                                    onChange={onMesgTypeChange}
                                    disabled={loading || Boolean(messageType)}
                                ></Radio.Group>
                                <Space>
                                    {mesgType !== 'today' && (
                                        <UdeskDatePicker
                                            onChange={selectTime}
                                            value={[startTime, endTime]}
                                            allowClear={false}
                                            style={{ width: 255 }}
                                            disabled={loading}
                                        />
                                    )}
                                    {selectedSession && (
                                        <Select
                                            style={{ width: 100 }}
                                            options={[
                                                { label: /* 全部 */locales.pages.wechatQa.chartRecord.detail.template.whole, value: null },
                                                ...Udesk.enums.chatMessageTypes.map((i) => ({
                                                    label: i.name,
                                                    value: i.id,
                                                })),
                                            ]}
                                            defaultValue={null as any}
                                            onChange={(value) => {
                                                setMessageType(value);
                                                if(value !== null){
                                                    onMesgTypeChange({target: {
                                                        value: 'history'
                                                    }});
                                                }
                                            }}
                                        />
                                    )}
                                </Space>
                            </div>
                            <div className='wechat-qa-chart-record-detail-left-chat-record' id='containerDom'>
                                <ChatRecord
                                    key={`${height}`}
                                    data={data}
                                    loadMoreData={loadMoreData}
                                    height={height}
                                    // onItemMounted={onItemMounted}
                                    showTopMore={showTopMore}
                                    loading={loading}
                                    highlightPoint={highlightPoint}
                                    allHighlightPoints={allHighlightPoints}
                                    closeHighlight={closeHighlight}
                                />
                            </div>
                        </div>
                        <div className='wechat-qa-chart-record-detail-right'>
                            <Tabs defaultActiveKey='1'>
                                {mesgType === 'today' && (
                                    <TabPane tab={locales.pages.wechatQa.chartRecord.detail.inspectionInfo} key='1'>
                                        {/* 分析平均分卡片 */}
                                        <Card size='small' title={locales.pages.wechatQa.chartRecord.detail.avgScore}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Progress
                                                    width={80}
                                                    type='circle'
                                                    strokeColor={qualified ? '#11A848' : '#FB5127'}
                                                    percent={100}
                                                    format={() => (
                                                        <div
                                                            style={{ color: qualified ? 'green' : 'red' }}
                                                        >{`${percent} ${locales.labels.scoreUnit}`}</div>
                                                    )}
                                                />
                                                <QualifiedTag qualified={qualified} style={MARGIN16} />
                                            </div>
                                            {/* {id !== 0 && smartTags.length > 0 && (
                                            <SmartTags
                                                // onSmartTagsChange={actions.onSmartTagsChange}
                                                callId={id}
                                                disabled={true}
                                                smartTags={smartTags}
                                                smartTagsTitle={
                                                    locales.components.pages.appealChekingTask.list.smartTags
                                                }
                                                // treeListVisible={actions.treeListVisible}
                                            />
                                        )} */}
                                        </Card>
                                        {/* 实时分析信息卡片 */}
                                        <Card
                                            style={{ marginTop: 16 }}
                                            size='small'
                                            title={locales.pages.wechatQa.chartRecord.detail.info}
                                        >
                                            <TimelySceneTree {...{
                                                timelySceneList, 
                                                highlightPoint, 
                                                setHighlightPoint
                                            }}/>
                                        </Card>
                                    </TabPane>
                                )}
                                <TabPane tab={locales.pages.wechatQa.chartRecord.detail.chartInfo} key='2'>
                                    {detail?.fieldList?.length > 0 && (
                                        <CallTextView
                                            callInfoList={detail.fieldList}
                                            fieldValueMap={detail.fieldValueMap}
                                        />
                                    )}
                                </TabPane>
                                <TabPane tab={locales.pages.wechatQa.chartRecord.detail.tasks} key='3'>
                                    <ApplyTemplates
                                        templates={templates}
                                        onClickTemplate={transitionToTemplate}
                                        title={locales.pages.wechatQa.chartRecord.detail.taskTitle}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(WechatQaChartRecordDetail);

// function isHighlightHitType(type) {
//     return [
//         'message',
//         'rich',
//     ].includes(type);
// }
