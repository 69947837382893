// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetTaskHelpsStatusQuery, BaseResponseListTaskHelpFoundResponse } from '../../types';

/**
 * findByStatus
 *
 * @export
 * @tags task-help-controller
 * @link [GET] /taskHelps/status
 */
export function getTaskHelpsStatus(
    options: ApiOptions<never, GetTaskHelpsStatusQuery> & { params: GetTaskHelpsStatusQuery }
): Promise<BaseResponseListTaskHelpFoundResponse> {
    return request<never, never, GetTaskHelpsStatusQuery>('/taskHelps/status', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getTaskHelpsStatus',
    });
}

export const meta = [{ tags: ['task-help-controller'], path: '/taskHelps/status', method: 'get' }];
