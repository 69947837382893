import React, { useCallback, useState } from 'react';
import Udesk from 'Udesk';
import {
    Button,
    InputNumber,
    Form,
    Icon,
    Input,
    Radio,
    Row,
    Slider,
    Col,
} from 'udesk-ui';
import { useAudioPlay } from 'src/pages/coach/learning-center/components/record/hook';
import { postIntelligentPartnerTtsTest } from 'src/api/intelligentPartner/tts/test';
import UdeskLocales from 'UdeskLocales';

const VOICE = [
    [
        {label: /* 艾夏（自然） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.aixianatural, value: 'aixia'},
        {label: /* 知莎（聊天） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.zhishaChat, value: 'zhistella'},
        {label: /* 知倩（温和） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.zhiqiangentle, value: 'zhiqian'},
        {label: /* 知米（情感） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.zhimiEmotion, value: 'zhimi_emo'},        
    ],
    [
        {label: /* 艾硕（自然） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.aiShuoNatural, value: 'aishuo'},
        {label: /* 知硕（聊天） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.zhishuoChat, value: 'zhishuo'},
        {label: /* 艾浩（亲和） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.aiHaoAffinity, value: 'aihao'},
        {label: /* 知祥（情感） */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.zhixiangEmotion, value: 'zhixiang'},
    ],
];

export const DefaultConfig = { 
    gender: 0,
    voice: 'aixia', 
    volume: 50,
    pitchRate: 0,
    speechRate: 0,
};

export default React.memo((props: any) => {
    const { form, submit, change } = props;
      
    const changeHandle = useCallback((values) => {
        if (values.voice) {
            const gender = VOICE[0].some(item => item.value === values.voice) ? 0 : 1;
            form.setFieldsValue({gender});
        }
        change();
    }, [change, form]);

    return (
        <Form 
            form={form}
            onFinish={submit}
            onValuesChange={changeHandle}
            initialValues={DefaultConfig}
        >
            <Form.Item label={/* 机器人音色 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.robotVoice} labelCol={{span: 24}} wrapperCol={{span: 23, offset: 1}}>
                <Form.Item name="gender" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="voice" label={/* 男生 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.schoolboy}>
                    <Radio.Group options={VOICE[1]} />
                </Form.Item>
                <Form.Item name="voice" label={/* 女生 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.girlStudent}>
                    <Radio.Group options={VOICE[0]} />
                </Form.Item>
                <Form.Item name="volume" label={/* 音量 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.volume}>
                    <SliderPro min={0} max={100}/>
                </Form.Item>
                <Form.Item name="pitchRate" label={/* 音调 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.tone}>
                    <SliderPro />
                </Form.Item>
                <Form.Item name="speechRate" label={/* 语速 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.speechSpeed}>
                    <SliderPro />
                </Form.Item>
                <Form.Item label={/* 测试 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.test}>
                    <Audition form={form}/>
                </Form.Item>
            </Form.Item>
        </Form>
    );
});

export const Audition =  React.memo((props: any) => {
    const {form} = props;
    const [text, setText] = useState(/* 您好，我是智能培训教练，期待与你的交流 */UdeskLocales['current'].pages.coach.courseManagement.flow.components.robotTimbreForm.helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou);
    const [audioRef, audioPlayHandle] = useAudioPlay();

    const play = useCallback(() => {
        if (text) {
            if (form) {
                const values = form.getFieldsValue(true);
                postIntelligentPartnerTtsTest({
                    ...values, text,
                }).then(
                    resp => {
                        audioPlayHandle([resp.data]);
                    },
                    reason => {
                        Udesk.ui.notify.error(reason.errorMsg);
                    }
                );
            }
        }
    }, [audioPlayHandle, form, text]);

    return (
        <Row gutter={16} align="bottom">
            <Col span={18}>
                <Input.TextArea value={text} onChange={(e) => setText(e.target.value)} autoSize={{minRows: 6}}/>
            </Col>
            <Col span={3}>
                <Button className='udesk-qa-audition-button' onClick={play} type="primary" icon={<Icon type='SoundFilled' antdIcon={true} />} />
            </Col>
            <Col>
                <audio 
                    className='hidden' 
                    ref={audioRef} 
                    controls 
                    autoPlay={false} />
            </Col>
        </Row>
    );
});

export const SliderPro = React.memo((props: any) => {
    const { 
        value, 
        onChange,
        min = -500, 
        max = 500, 
    } = props;

    return (
        <Row>
            <Col span={18}>
                <Slider
                    value={value}
                    onChange={onChange}
                    min={min}
                    max={max}
                    marks={{
                        [min]: min,
                        [max]: max
                    }}
                    step={1}
                />
            </Col>
            <Col span={3}>
                <InputNumber
                    value={value}
                    onChange={onChange}
                    min={min}
                    max={max}
                    style={{ margin: '0 16px' }}
                    step={1}
                />
            </Col>
        </Row>
    );
});