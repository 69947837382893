// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type { PostIntelligentExtractTasksResultExportBody, BaseResponseVoid } from '../../../types';

/**
 * 导出抽取数据
 *
 * @export
 * @tags 智能抽取接口
 * @link [POST] /intelligentExtractTasks/result/export
 */
export function postIntelligentExtractTasksResultExport(
    data: PostIntelligentExtractTasksResultExportBody
): Promise<BaseResponseVoid>;
export function postIntelligentExtractTasksResultExport(
    data: PostIntelligentExtractTasksResultExportBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseVoid>;
export function postIntelligentExtractTasksResultExport(
    data: PostIntelligentExtractTasksResultExportBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseVoid> {
    return request<PostIntelligentExtractTasksResultExportBody, never, never>(
        '/intelligentExtractTasks/result/export',
        {
            ...options,
            data,
            method: 'post',
            __$requestCalleeName: 'postIntelligentExtractTasksResultExport',
        }
    );
}

export const meta = [
    { tags: ['智能抽取接口'], path: '/intelligentExtractTasks/result/export', method: 'post' },
];
