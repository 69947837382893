/*
 * @Author: 唐毓斌
 * @Description: 结果分析
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { getIntelligentPartnerTaskInfosResultAnalysisDetailByTaskId } from 'src/api/intelligentPartnerTaskInfos/result/analysis/detail/{taskId}';
// import { getIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId } from 'src/api/intelligentPartnerTaskInfos/result/analysis/overview/{taskId}';
// import {
//     FinishRecordResultAnalysisDetail,
//     FinishRecordResultAnalysisOverview,
// } from 'src/api/types';
import { Button, Empty, Radio, Space } from 'udesk-ui';
import { linkTo } from 'src/util/link';
import Echarts from 'echarts';
import CustomCard from '../../../../components/CutomCard';
import useResizeObserver from 'use-resize-observer';
import './ResultAnalysis.less';
import UdeskLocales from 'UdeskLocales';
import { useRequest } from 'src/util/hook';

const Charts = React.memo((props: any) => {
    const { length, options, onClick, content } = props;
    const chartsRef = useRef<any>(null);
    const devRef = useRef<HTMLDivElement>(null);

    useResizeObserver<HTMLDivElement>({
        ref: content,
        onResize() {
            if (chartsRef.current) {
                chartsRef.current.resize({});
            }
        },
    });

    useEffect(() => {
        if (devRef?.current) {
            chartsRef.current = Echarts.init(devRef.current);
        }
        return () => {
            if (chartsRef.current) {
                chartsRef.current.dispose();
            }
        };
    }, []);

    useEffect(() => {
        if (chartsRef.current) {
            chartsRef.current.clear();
            chartsRef.current.setOption(options);
            chartsRef.current.resize({
                height: ((length || 1) * 2 - 1) * 22 + 56,
            });
        }
    }, [length, options]);

    useEffect(() => {
        if (chartsRef.current) {
            chartsRef.current.off('click');
            chartsRef.current.on('click', onClick);
        }
    }, [onClick]);

    return <div ref={devRef} style={{ width: '100%', flex: 0 }} />;
});

const ResultAnalysis: React.FC<any> = (props) => {
    const { taskId, lessonId, isPPT, startTime, endTime, userSelectObj, showBottom = false } = props;
    const request = useRequest();
    // const [overview, setOverview] = useState<FinishRecordResultAnalysisOverview>();
    const [scoreDetail, setScoreDetail] = useState<any>();
    const [avgScoreData, setAvgScoreData] = useState<any[]>();
    const [point, setPoint] = useState<any>();
    const contentRef = useRef<HTMLDivElement>(null);

    // const fetchOverviewData = useCallback(() => {
    //     getIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId({
    //         segments: { taskId: taskId },
    //     }).then((resp) => {
    //         setOverview(resp.data);
    //     });
    // }, [taskId]);

    const fetchDetailData = useCallback(
        (sortWayParams?: string) => {
            const sortWay = sortWayParams || 'orderByFlow';
            let requestApi = '/intelligentPartnerTaskInfos/result/analysis/detail/';
            let params = {
                sortWay,
                endTime: endTime.split(' ')[0],
                startTime: startTime.split(' ')[0],
                ...userSelectObj,
            };
            console.log('api', params);
            request(requestApi + taskId, params, 'post').then((resp) => {
            // getIntelligentPartnerTaskInfosResultAnalysisDetailByTaskId({
            //     segments: { taskId: taskId },
            //     params: { sortWay }, // orderByScore ：按得分排序 orderByFlow : 按流程排序
            // }).then((resp) => {
                const avgScoreData = 'orderByFlow' === sortWay ? resp.data?.reverse() : resp.data;
                const scoreDetail =
                    Array.isArray(avgScoreData) && avgScoreData.length > 0
                        ? avgScoreData?.[avgScoreData.length - 1]
                        : undefined;

                setAvgScoreData(avgScoreData);
                setScoreDetail(scoreDetail);
                setPoint(scoreDetail?.detail?.[0]);
            });
        },
        [taskId, userSelectObj, startTime, endTime]
    );

    const onSortWayChange = (e) => {
        fetchDetailData(e.target.value);
    };

    const onClickAvgScore = useCallback(
        (params) => {
            if (params.componentType === 'yAxis') {
                const dataIndex = params.event.target.anid.replace('label_', '');
                setScoreDetail(avgScoreData?.[dataIndex]);
            } else {
                setScoreDetail(avgScoreData?.[params.dataIndex]);
            }
        },
        [avgScoreData]
    );

    const onClickScoreDetail = useCallback(
        (params) => {
            if (params.componentType === 'yAxis') {
                const dataIndex = params.event.target.anid.replace('label_', '');
                setPoint(scoreDetail?.detail?.[dataIndex]);
            } else {
                setPoint(scoreDetail?.detail?.[params.dataIndex]);
            }
        },
        [scoreDetail?.detail]
    );

    const avgScoreOptions = useMemo(() => {
        return {
            color: ['#dbdcdf', '#0376FB'],
            grid: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0,
                containLabel: true,
            },
            xAxis: {
                show: false,
                type: 'value',
                boundaryGap: [0, 0.01],
                max: function (value) {
                    return avgScoreData?.reduce((p, item) => Math.max(item.totalScore ?? 0, p), 0);
                },
            },
            yAxis: [
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    triggerEvent: true,
                    data: avgScoreData?.map((item) => item.nodeName),
                },
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    position: 'right',
                    data: avgScoreData?.map(
                        (item) => `${item.avgScore ?? '-'}/${item.totalScore ?? '-'}`
                        // (item) => {
                        //     let first = item.avgScore ? item.avgScore: '-';
                        //     let second = item.totalScore ?item.totalScore : '-';
                        //     return first + '/' + second;
                        // }
                    ),
                },
            ],
            series: [
                {
                    name: /* 总分 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                        .resultAnalysis.totalScore,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    barGap: '-100%',
                    data: avgScoreData?.map((item) => item.totalScore),
                },
                {
                    name: /* 平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                        .resultAnalysis.average,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    // barGap: '-100%',
                    data: avgScoreData?.map((item) => item.avgScore), //[5, 6, 7, 8, 9, 10],
                },
            ],
        };
    }, [avgScoreData]);

    const scoreDetailOptions = useMemo(() => {
        return {
            color: ['#dbdcdf', '#0376FB'],
            grid: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0,
                containLabel: true,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'none',
                },
                formatter: '{b0}',
            },
            xAxis: {
                show: false,
                type: 'value',
                boundaryGap: [0, 0.01],
                max: 1,
            },
            yAxis: [
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        position: 'right',
                        padding: [0, 10, 0, 0],
                        formatter: (value) => {
                            if (value.length > 10) {
                                return value.slice(0, 10) + '...';
                            }
                            return value;
                        },
                    },
                    triggerEvent: true,
                    data: scoreDetail?.detail?.map((item) => {
                        // if (item.scoreRuleName) {
                        //     if (item.scoreRuleName.length > 10) {
                        //         return item.scoreRuleName.slice(0, 10) + '...';
                        //     }
                        //     return item.scoreRuleName;
                        // }
                        // return '';
                        return item.scoreRuleName;
                    }),
                },
                {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    position: 'right',
                    data: scoreDetail?.detail?.map((item) => (item.hitRatio || 0) * 100 + '%'),
                },
            ],
            series: [
                {
                    name: /* 总数 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                        .resultAnalysis.total,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    barGap: '-100%',
                    data: scoreDetail?.detail?.map((item) => 1),
                },
                {
                    name: /* 要点命中次数 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                        .resultAnalysis.keyHitCount,
                    type: 'bar',
                    emphasis: { disabled: true },
                    barMaxWidth: 22,
                    data: scoreDetail?.detail?.map((item) => parseFloat(item.hitRatio + '' || '')),
                    label: {
                        show: true,
                        position: 'insideRight',
                        formatter: ({ dataIndex }) => {
                            return scoreDetail?.detail?.[dataIndex].totalHitCount;
                        },
                    },
                },
            ],
        };
    }, [scoreDetail?.detail]);

    const onClickPointDetail = useCallback(
        (params) => {
            if (params.componentType === 'yAxis') {
                const dataIndex = params.event.target.anid.replace('label_', '');
                window.open(
                    `/coach/data-analysis/learning-record?taskId=${taskId}&nodeId=${scoreDetail?.nodeId}&ruleId=${scoreDetail?.detail?.[dataIndex]?.scoreRuleId}`
                );
            } else {
                window.open(
                    `/coach/data-analysis/learning-record?taskId=${taskId}&nodeId=${
                        scoreDetail?.nodeId
                    }&ruleId=${scoreDetail?.detail?.[params.dataIndex]?.scoreRuleId}`
                );
            }
        },
        [scoreDetail?.detail, scoreDetail?.nodeId, taskId]
    );

    useEffect(() => {
        fetchDetailData();
    }, [fetchDetailData]);

    return (
        <Space className="result-analysis-container" direction="vertical" size={'middle'}>
            {showBottom?(
                <div className="result-analysis-content-wrapper">
                    <CustomCard
                        containerStyle={{ flex: 1, marginRight: 10 }}
                        title={
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ fontWeight: 'bold', lineHeight: '30px' }}>
                                    {isPPT(
                                        () =>
                                            /* 话术要点命中率 */ UdeskLocales['current'].pages.coach
                                                .taskCenter.detail.resultAnalysis
                                                .hitRateOfKeyPointsInScript,
                                        () =>
                                            /* 话术平均得分 */ UdeskLocales['current'].pages.coach
                                                .taskCenter.detail.resultAnalysis.averageScriptScore
                                    )}
                                </span>

                                {isPPT(
                                    () => null,
                                    () => (
                                        <>
                                            <div>
                                                <Radio.Group
                                                    defaultValue="orderByFlow"
                                                    onChange={onSortWayChange}
                                                >
                                                    <Radio.Button value="orderByFlow">
                                                        {/* 按流程排序 */}
                                                        {
                                                            UdeskLocales['current'].pages.coach
                                                                .taskCenter.detail.resultAnalysis
                                                                .sortByProcess
                                                        }
                                                    </Radio.Button>
                                                    <Radio.Button value="orderByScore">
                                                        {/* 按得分排序 */}
                                                        {
                                                            UdeskLocales['current'].pages.coach
                                                                .taskCenter.detail.resultAnalysis
                                                                .sortByScore
                                                        }
                                                    </Radio.Button>
                                                </Radio.Group>
                                            </div>
                                            <div style={{ color: '#00000073', lineHeight: '30px' }}>
                                                {isPPT(
                                                    () =>
                                                        /* 该页平均分/该页总分 */ UdeskLocales[
                                                            'current'
                                                        ].pages.coach.taskCenter.detail.resultAnalysis
                                                            .averageScoreOfThisPagetotalScoreOfThisPage,
                                                    () =>
                                                        /* 该项平均分/该项总分 */ UdeskLocales[
                                                            'current'
                                                        ].pages.coach.taskCenter.detail.resultAnalysis
                                                            .averageScoreOfThisItemtotalScoreOfThisItem
                                                )}
                                            </div>
                                        </>
                                    )
                                )}
                            </div>
                        }
                        content={
                            isPPT(
                                () => scoreDetail?.detail?.length,
                                () => avgScoreData?.length
                            ) > 0 ? (
                                <Charts
                                    content={contentRef}
                                    options={isPPT(
                                        () => scoreDetailOptions,
                                        () => avgScoreOptions
                                    )}
                                    onClick={isPPT(
                                        () => onClickScoreDetail,
                                        () => onClickAvgScore
                                    )}
                                    length={isPPT(
                                        () => scoreDetail?.detail?.length,
                                        () => avgScoreData?.length
                                    )}
                                />
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }
                    />
                    <CustomCard
                        containerStyle={{
                            flex: 1,
                            marginLeft: 10,
                        }}
                        title={
                            <span>
                                <span style={{ fontWeight: 'bold', marginRight: 6 }}>
                                    {isPPT(
                                        () => point?.scoreRuleName,
                                        () => scoreDetail?.nodeName
                                    )}
                                </span>
                                <span style={{ color: '#777' }}>
                                    {/* 详情 */}
                                    {
                                        UdeskLocales['current'].pages.coach.taskCenter.detail
                                            .resultAnalysis.details
                                    }
                                </span>
                            </span>
                        }
                        extra={
                            <Button
                                type="link"
                                onClick={() => {
                                    linkTo(props.history, 'courseManagementFlow', {
                                        id: lessonId,
                                    });
                                }}
                            >
                                {/* 查看规则 */}
                                {
                                    UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis
                                        .viewRules
                                }
                            </Button>
                        }
                        content={
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}
                            >
                                {isPPT(
                                    () => {
                                        if (point) {
                                            return (
                                                <div className="detail-score-standard-words">
                                                    <div>
                                                        {/* 标准话术 */}
                                                        {
                                                            UdeskLocales['current'].pages.coach
                                                                .taskCenter.detail.resultAnalysis
                                                                .standardScript
                                                        }
                                                    </div>
                                                    {point.words}
                                                </div>
                                            );
                                        } else {
                                            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
                                        }
                                    },
                                    () => {
                                        const list = new Array<any>();
                                        if (scoreDetail?.detail?.length) {
                                            list.push(
                                                <Charts
                                                    content={contentRef}
                                                    options={scoreDetailOptions}
                                                    length={scoreDetail?.detail?.length}
                                                    onClick={onClickPointDetail}
                                                />
                                            );
                                        }
                                        if (scoreDetail?.standardWords) {
                                            list.push(
                                                <div className="detail-score-standard-words">
                                                    <div>
                                                        {/* 标准话术 */}
                                                        {
                                                            UdeskLocales['current'].pages.coach
                                                                .taskCenter.detail.resultAnalysis
                                                                .standardScript
                                                        }
                                                    </div>
                                                    {scoreDetail?.standardWords}
                                                </div>
                                            );
                                        }

                                        if (list.length > 0) {
                                            return list;
                                        }
                                        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
                                    }
                                )}
                            </div>
                        }
                    />
                </div>

            ):''}
            
        </Space>
    );
};

export default ResultAnalysis;
