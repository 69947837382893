// 统一合并为完整的 Locale
var locale = {
  lang: {
    selectAll: '全选当页',
    selected: '已选择',
    term: '条',
    deselect: '取消选择',
  },
};
export default locale;
