import Udesk from 'Udesk';
import UdeskLocales from 'UdeskLocales';
import React, { useEffect, useMemo, useState } from 'react';
import { FormBuilder, Modal, TreeSelect } from 'udesk-ui';
// 引入编辑器组件
import BraftEditor, { ExtendControlType } from 'braft-editor';
// 引入编辑器样式
import 'braft-editor/dist/index.css';
import { ContentUtils } from 'braft-utils';
import { array2tree } from 'Component/pages/intelligent-analysis/supervision/detail/recommendation';
import { getKnowledgeBaseSearch } from 'src/api/knowledgeBase/search';
import { getLibraryFilesPublicSearch } from 'src/api/libraryFiles/public/search';
import { getReviewWordsFavoriteByType } from 'src/api/review/words/favorite/{type}';
import { getFavoriteLink } from 'Component/pages/semantic-intelligence/words-mining/favorite-view';
import { language } from 'src/components/common/b-editor';

const default_initial_values = { type: 'knowledge' };

const braftEditorStyle = {
    border: '1px solid #d9d9d9',
};

const CaseSelect = (props) => {
    const [caseOptions, setCaseOptions] = useState<any[]>([]);
    useEffect(() => {
        getLibraryFilesPublicSearch({
            params: {
                name: '',
            },
        })
            .then((res) => {
                const fileList: any[] = [];
                const cache: any = {};

                const addParent = (id) => {
                    const parent = cache[id];
                    if (parent) {
                        if (fileList.indexOf(parent) === -1) {
                            fileList.push(parent);
                        }
                        addParent(parent.parentId);
                    }
                };

                const createFolder = (list) => {
                    if (list) {
                        list.forEach((item) => {
                            const { libraryCallList, children } = item;
                            if (children) {
                                createFolder(children);
                                if (libraryCallList && libraryCallList.length > 0) {
                                    const folderId = ['folder', item.id].join('/');
                                    item.children.unshift({
                                        key: folderId,
                                        value: folderId,
                                        selectable: false,
                                        title: /* 案例分组 */ UdeskLocales['current'].pages.gong
                                            .businessConfiguration.communicationStrategy.detail
                                            .components.pushRichText.index.caseGrouping,
                                        children: libraryCallList.map((item) => {
                                            return {
                                                key: item.id,
                                                value: item.id,
                                                title: item.name,
                                            };
                                        }),
                                    });
                                }
                            } else {
                                if (libraryCallList && libraryCallList.length > 0) {
                                    item.children = libraryCallList.map((item) => {
                                        return {
                                            key: item.id,
                                            value: item.id,
                                            title: item.name,
                                        };
                                    });
                                }
                            }
                        });
                    }
                    return list;
                };

                res.data?.forEach((item) => {
                    const list = item.libraryCallList;
                    if (list && list.length > 0) {
                        fileList.push(item);
                    }
                    cache[item.id!] = item;
                });

                fileList.forEach((item) => addParent(item.parentId));

                const tree = array2tree(fileList!, (item) => {
                    const parentId = ['parent', item.id].join('/');
                    item.key = parentId;
                    item.value = parentId;
                    item.selectable = false;
                    item.title = item.name;
                    return !0;
                }) as [];

                return createFolder(tree);
            })
            .then((list) => {
                setCaseOptions(list);
            });
    }, []);
    const onChange = (newValue: string) => {
        props.onChange(newValue);
    };

    return (
        <TreeSelect
            // treeDataSimpleMode
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            onChange={onChange}
            placeholder={
                /* 请选择优秀案例 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.detail.components.pushRichText.index
                    .pleaseSelectExcellentCases
            }
            // loadData={loadData}
            treeData={caseOptions}
            value={props.value}
        />
    );
};

const KnowledgeSelect = (props) => {
    const [options, setOptions] = useState<any[]>([]);
    useEffect(() => {
        getKnowledgeBaseSearch()
            .then((res) => {
                const result = new Array<any>();
                if (res.data) {
                    res.data.forEach((item) => {
                        const id = ['category', item.id].join('/');
                        const category = {
                            key: id,
                            value: id,
                            selectable: false,
                            children: null as any,
                            title: item.categoryName,
                        };
                        const children = item.knowledgePointDetailList || [];
                        if (children.length > 0) {
                            category.children = children.map((item) => {
                                return {
                                    key: item.id,
                                    value: item.id,
                                    title: item.pointName,
                                };
                            });
                            result.push(category);
                        }
                    });
                }

                return result;
            })
            .then((list) => {
                setOptions(list);
            });
    }, []);
    const onChange = (newValue: string) => {
        props.onChange(newValue);
    };
    return (
        <TreeSelect
            // treeDataSimpleMode
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            onChange={onChange}
            // loadData={loadData}
            placeholder={
                /* 请选择知识点 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.detail.components.pushRichText.index
                    .pleaseSelectKnowledgePoints
            }
            treeData={options}
            value={props.value}
        />
    );
};

const FormRichText = (props) => {
    const { value: valueProps, onChange } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const [speechOptions, setSpeechOptions] = useState<any[]>([]);
    const [initialValues, setInitialValues] = useState<any>({ ...default_initial_values });
    const [formInstance] = FormBuilder.useForm();

    const [value, setValue] = useState(() => {
        return BraftEditor.createEditorState(valueProps || null);
    });

    const extendControls = useMemo<ExtendControlType[]>(() => {
        return [
            {
                key: 'custom-dropdown',
                type: 'button',
                autoHide: true,
                text: /* 关联链接 */ UdeskLocales['current'].pages.gong.businessConfiguration
                    .communicationStrategy.detail.components.pushRichText.index.associativeLinks,
                disabled: !ContentUtils.getSelectionText(value),
                onClick: () => {
                    setVisible(true);
                    // setCurrentLink(ContentUtils.getSelectionEntityData(value, 'LINK'));
                    const currentLink = ContentUtils.getSelectionEntityData(value, 'LINK');

                    if (currentLink?.href) {
                        if (
                            currentLink.href.includes('/sales/learning-center/knowledge-base/view/')
                        ) {
                            setInitialValues({
                                type: 'knowledge',
                                knowledge: parseInt(
                                    currentLink.href.replace(
                                        '/sales/learning-center/knowledge-base/view/',
                                        ''
                                    ),
                                    10
                                ),
                            });
                        }
                        if (currentLink.href.includes('/site/call-analysis/call-detail/')) {
                            setInitialValues({
                                type: 'case',
                                knowledge: parseInt(
                                    currentLink.href.replace(
                                        '/site/call-analysis/call-detail/',
                                        ''
                                    ),
                                    10
                                ),
                            });
                        }
                        if (
                            currentLink.href.includes(
                                '/site/semantic-intelligence/words-mining/dissent/favorite/'
                            )
                        ) {
                            setInitialValues({
                                type: 'speech',
                                knowledge: parseInt(
                                    currentLink.href.replace(
                                        '/site/semantic-intelligence/words-mining/dissent/favorite/',
                                        ''
                                    ),
                                    10
                                ),
                            });
                        }
                    } else {
                        setInitialValues({ ...default_initial_values });
                    }
                },
            },
        ];
    }, [value]);

    useEffect(() => {
        formInstance.resetFields();
    }, [initialValues]);

    useEffect(() => {
        Promise.all([
            getReviewWordsFavoriteByType({
                segments: {
                    type: Udesk.enums.applicationScenarios.objection.id,
                },
            }),
            getReviewWordsFavoriteByType({
                segments: {
                    type: Udesk.enums.applicationScenarios.sellingPoints.id,
                },
            }),
        ]).then(([resp, resp2]) => {
            const list = new Array<any>();
            if (resp.data?.length) {
                list.push({
                    id: Udesk.enums.applicationScenarios.objection.id,
                    title: /* 异议处理话术 */ UdeskLocales['current'].pages.gong
                        .businessConfiguration.communicationStrategy.detail.components.pushRichText
                        .index.objectionHandlingScript,
                    selectable: false,
                });
                resp.data?.forEach((item) => {
                    list.push({
                        id: [item.id, Udesk.enums.applicationScenarios.objection.id].join(),
                        title: item.name,
                        pId: Udesk.enums.applicationScenarios.objection.id,
                        selectable: true,
                    });
                });
            }
            if (resp2.data?.length) {
                list.push({
                    id: Udesk.enums.applicationScenarios.sellingPoints.id,
                    title: /* 产品卖点话术 */ UdeskLocales['current'].pages.gong
                        .businessConfiguration.communicationStrategy.detail.components.pushRichText
                        .index.productSellingPointScript,
                    selectable: false,
                });
                resp2.data?.forEach((item: any) => {
                    list.push({
                        id: [
                            item.clusterId,
                            Udesk.enums.applicationScenarios.sellingPoints.id,
                        ].join(),
                        title: item.name,
                        pId: Udesk.enums.applicationScenarios.sellingPoints.id,
                        selectable: true,
                    });
                });
            }
            setSpeechOptions(list);
        });
    }, []);

    return (
        <React.Fragment>
            <BraftEditor
                style={braftEditorStyle}
                extendControls={extendControls}
                value={value}
                onChange={(editorState) => {
                    setValue(editorState);
                    onChange(editorState.toHTML());
                }}
                language={language}
            />
            <Modal
                title={
                    /* 跳转至 */ UdeskLocales['current'].pages.gong.businessConfiguration
                        .communicationStrategy.detail.components.pushRichText.index.jumpTo
                }
                visible={visible}
                onOk={formInstance.submit}
                onCancel={() => {
                    setVisible(false);
                }}
                forceRender
            >
                <FormBuilder
                    initialValues={initialValues}
                    customComponents={{ KnowledgeSelect, CaseSelect }}
                    form={formInstance}
                    onFinish={(values) => {
                        if (values.type === 'knowledge') {
                            setValue(
                                ContentUtils.toggleSelectionLink(
                                    value,
                                    `/sales/learning-center/knowledge-base/view/${values.knowledge}`,
                                    '_blank'
                                )
                            );
                        }
                        if (values.type === 'case') {
                            setValue(
                                ContentUtils.toggleSelectionLink(
                                    value,
                                    `/sales/call-analysis/call-detail/${values.case}`,
                                    '_blank'
                                )
                            );
                        }
                        if (values.type === 'speech') {
                            setValue(
                                ContentUtils.toggleSelectionLink(
                                    value,
                                    getFavoriteLink.apply(
                                        null,
                                        values.speech.split(',').map((i) => parseInt(i))
                                    ),
                                    '_blank'
                                )
                            );
                        }
                        setVisible(false);
                    }}
                    fields={[
                        // {
                        //     type: 'Input',
                        //     label: '链接名称',
                        //     name: 'linkName',
                        //     rules: [
                        //         {
                        //             required: true,
                        //         },
                        //     ],
                        // },
                        {
                            type: 'Select',
                            label: /* 跳转类型 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.communicationStrategy.detail.components
                                .pushRichText.index.jumpType,
                            name: 'type',
                            props: {
                                options: [
                                    {
                                        label: /* 知识点 */ UdeskLocales['current'].pages.gong
                                            .businessConfiguration.communicationStrategy.detail
                                            .components.pushRichText.index.knowledgePoints,
                                        value: 'knowledge',
                                    },
                                    {
                                        label: /* 优秀案例 */ UdeskLocales['current'].pages.gong
                                            .businessConfiguration.communicationStrategy.detail
                                            .components.pushRichText.index.excellentCases,
                                        value: 'case',
                                    },
                                    {
                                        label: /* 优秀话术 */ UdeskLocales['current'].pages.gong
                                            .businessConfiguration.communicationStrategy.detail
                                            .components.pushRichText.index.excellentScript,
                                        value: 'speech',
                                    },
                                ],
                                placeholder: UdeskLocales['current'].labels.pleaseSelect,
                            },
                            effect: {
                                visible: {
                                    knowledge: ['knowledge'],
                                    case: ['case'],
                                    speech: ['speech'],
                                },
                            },
                        },
                        {
                            type: 'KnowledgeSelect',
                            label: /* 知识点 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.communicationStrategy.detail.components
                                .pushRichText.index.knowledgePoints,
                            name: 'knowledge',
                            rules: [
                                {
                                    required: true,
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value || typeof value === 'number') {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                /* 请选择单个知识点 */ UdeskLocales[
                                                    'current'
                                                ].pages.gong.businessConfiguration.communicationStrategy.detail.components.pushRichText.index.pleaseSelectASingleKnowledgePoint
                                            )
                                        );
                                    },
                                }),
                            ],
                        },
                        {
                            type: 'CaseSelect',
                            label: /* 优秀案例 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.communicationStrategy.detail.components
                                .pushRichText.index.excellentCases,
                            name: 'case',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        {
                            type: 'TreeSelect',
                            label: /* 优秀话术 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.communicationStrategy.detail.components
                                .pushRichText.index.excellentScript,
                            name: 'speech',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            props: {
                                treeData: speechOptions,
                                treeDataSimpleMode: true,
                                showSearch: true,
                                treeNodeFilterProp: 'title',
                            },
                        },
                    ]}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 15 }}
                    footerRender={false}
                />
            </Modal>
        </React.Fragment>
    );
};

export { FormRichText };
