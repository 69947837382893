// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    PostReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerateBody,
    BaseResponseListIntelligentPartnerDialogNodeDfContentFoundResponse,
} from '../../../../../types';

/**
 * 生成对话框架
 *
 * @export
 * @tags 智能陪练-AI对话框架节点配置
 * @link [POST] /review/intelligentPartner/node/dialogueFrameworkConfig/generate
 */
export function postReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerate(
    data: PostReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerateBody
): Promise<BaseResponseListIntelligentPartnerDialogNodeDfContentFoundResponse>;
export function postReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerate(
    data: PostReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerateBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogNodeDfContentFoundResponse>;
export function postReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerate(
    data: PostReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerateBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseListIntelligentPartnerDialogNodeDfContentFoundResponse> {
    return request<
        PostReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerateBody,
        never,
        never
    >('/review/intelligentPartner/node/dialogueFrameworkConfig/generate', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewIntelligentPartnerNodeDialogueFrameworkConfigGenerate',
    });
}

export const meta = [
    {
        tags: ['智能陪练-AI对话框架节点配置'],
        path: '/review/intelligentPartner/node/dialogueFrameworkConfig/generate',
        method: 'post',
    },
];
