import Udesk from 'Udesk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce, useStateForPropsAttribute } from 'src/util/hook';
import { fire } from 'src/util/core';
import {
    useAudioPlay,
    useStartSecond,
} from 'src/pages/coach/learning-center/components/record/hook';
import { useAudioRecorder } from 'src/pages/coach/learning-center/components/record';
import { usePassThroughParams } from 'src/pages/coach/learning-center/components';
import { createMessage } from 'src/pages/coach/learning-center/components/util';
import { getCustomMessageList } from 'src/pages/coach/learning-center/components/record/ppt/hook';
import UdeskLocales from 'UdeskLocales';

const updatePassThroughParams = (passThroughParams, item) => {
    $.extend(passThroughParams, {
        bindNodeId: item.bindNodeId, // 节点ID ，后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionClosely: item.questionClosely, // 是否未追问 0: 否 1: 是,后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionCloselyWordsIndex: item.questionCloselyWordsIndex, // 追问索引句子, 后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionIdList: item.questionIdList, // 已经回答的知识库节点的问答id, 后续交互作为参数传入，如果返回多个，取最后一个节点的
        hasAnswerFinishKnowledge: item.hasAnswerFinishKnowledge, // 知识库节点是否已经回答完毕// 后续交互作为参数传入，如果返回多个，取最后一个节点的
        questionCloselyId: item.questionCloselyId, // 追问增加的id
    });
};

export const useChatRecord = (props: any) => {
    const [audioRef, audioPlayHandle, audioEndedHandle] = useAudioPlay();
    const { request, wrongQuestion } = props;
    const {
        list: recordList,
        clearList,
        startRecord,
        pauseRecord,
        uploadFile,
    } = useAudioRecorder(wrongQuestion);
    const [isLastNode, setIsLastNode] = useState<boolean>(false);
    const [getPassThroughParams, setPassThroughParams, passThroughParams] = usePassThroughParams();
    const [initStartSecond, newStartSecond] = useStartSecond();
    const [record, setRecord] = useState<any>(null);
    const [list, setList] = useStateForPropsAttribute(
        wrongQuestion,
        'messageList',
        useCallback(() => [], [])
    );
    const [referenceWords, setReferenceWords] = useStateForPropsAttribute(
        wrongQuestion,
        'nodeWords',
        '',
        false
    );

    const [loadingForTaskStart, setLoadingForTaskStart] = useState(false);
    const [loadingForSendMessage, setLoadingForSendMessage] = useState(false);

    useEffect(() => {
        setIsLastNode(false);
        clearList();
    }, [clearList, wrongQuestion]);

    const [sendFail, setSendFail] = useState(false);

    const startAudioRecord = useDebounce(() => {
        if (sendFail) {
            coreHandler();
            return;
        }

        const newMessage = createMessage({
            isSync: 0,
            startSecond: newStartSecond(),
        });
        setList((list) => {
            const newList = [...list];
            const index = list.findIndex((item) => item.id === newMessage.id);

            if (index > -1) {
                newList.splice(index, 1, newMessage);
            } else {
                newList.push(newMessage);
            }
            return newList;
        });
        startRecord();
    }, [startRecord, setList, sendFail]);

    const stopAudioRecord = useCallback(() => {
        pauseRecord();
    }, [pauseRecord]);

    const updateDataInfo = useCallback(
        (data, info) => {
            setList((list) => {
                return list.map((item) => {
                    if (item.id === data.id) {
                        return $.extend(true, {}, item, info);
                    }

                    return item;
                });
            });
        },
        [setList]
    );

    const sendMessage = useDebounce(
        (data: any, fileInfo?) => {
            const aiDialogContentList = (data.newList || []).slice(
                0,
                (data.newList || []).length - 1
            );

            const params = getPassThroughParams({
                words: fileInfo?.text, // 用户说的话，如果是语音，需转化为文字
                speakDuration: fileInfo?.duration, // 说话时长 毫秒
                speakTime: data.createDate, // 说话时间
                ossKey: fileInfo?.url, // 语音上传的地址
                aiDialogContentList, // ai对话时使用
            });

            request(
                `/intelligentPartnerMistakeCollections/${wrongQuestion.id}/interactive`,
                params,
                'post'
            )
                .then(
                    (resp) => {
                        const { nextNodeList, traineeWordsResult } = resp.data;
                        const newPassThroughParams = {
                            dialogueMode: params.dialogueMode,
                        };
                        const audioUrlList = new Array<string>();

                        let isLastNode = false;
                        let newList = [];
                        if (nextNodeList) {
                            newList = nextNodeList.map((item, index) => {
                                // 如果发现机器人对话为最后一个阶段，将自动触发后续的逻辑
                                isLastNode = !!item.isLastNode;
                                // 更新透传参数
                                if (index === nextNodeList.length - 1) {
                                    updatePassThroughParams(newPassThroughParams, item);
                                }
                                // 记录语音链接，为自动播放做准备
                                if (item.ossKey) {
                                    audioUrlList.push(item.ossKey);
                                }

                                return createMessage({
                                    channel: 1,
                                    nodeType: item.nodeType,
                                    content: item.words,
                                    ossKey: item.ossKey,
                                    startSecond: newStartSecond(),
                                });
                            });

                            setReferenceWords(resp.data?.referenceWords || ''); // 重置推荐话术
                        } else {
                            isLastNode = true;
                        }

                        if (isLastNode) {
                            wrongQuestion.exerciseTimes += 1; // 练习次数加一
                            setIsLastNode(isLastNode);
                        }
                        setList((list) => {
                            if (traineeWordsResult) {
                                list[list.length - 1]['traineeWordsResult'] = traineeWordsResult;
                            }
                            return new Array<any>().concat(list, newList);
                        });
                        // 如果存在语音文件，将进行播放
                        if (audioUrlList.length > 0) {
                            audioPlayHandle(...audioUrlList);
                        }

                        setPassThroughParams(newPassThroughParams);
                        updateDataInfo(data, {
                            isSync: 2,
                        });
                        setSendFail(false);
                    },
                    (reason) => {
                        updateDataInfo(data, {
                            isSync: 0,
                        });
                        Udesk.ui.notify.error(reason.errorMsg || /* 发送失败 */UdeskLocales['current'].pages.coach.learningCenter.wrongQuestionSet.exercise.components.record.voice.hook.index.failInSend);
                        setSendFail(true);
                    }
                )
                .finally(() => {
                    setLoadingForSendMessage(false);
                });
        },
        [setList, wrongQuestion?.id, request, record?.id, audioPlayHandle]
    );

    // 文件是否已经暂停，并且已经完成了ASR转译及上传
    const isOver = useMemo(() => {
        return recordList.every((item) => {
            if (item.isAsrOver) {
                if (item.text) {
                    return item.isFileUploadOver;
                }
                return true;
            }
            return false;
        });
    }, [recordList]);

    const coreHandler = useCallback(() => {
        const isReady = (isSync, recorder) => {
            return isSync === 0 && recorder.text && recorder.isAsrOver && recorder.isFileUploadOver;
        };
        const isResetLoadingReady = (isSync, recorder) => {
            return isSync === 0 && recorder.text === '' && recorder.isAsrOver;
        };
        const isUploadReady = (isSync, recorder) => {
            return (
                isSync === 0 &&
                recorder.text &&
                recorder.isAsrOver &&
                recorder.isFileUploadOver === false
            );
        };
        if (recordList.length > 0) {
            setList((list) => {
                const newList = [...list];
                getCustomMessageList(list).forEach((item, index) => {
                    const recorder = recordList[index];
                    if (recorder) {
                        item.ossKey = recorder.url || '/';
                        item.content = recorder.text;

                        if (isUploadReady(item.isSync, recorder)) {
                            setLoadingForSendMessage(true);
                            uploadFile(recorder, () => {
                                setLoadingForSendMessage(false);
                            });
                        }
                        if (isResetLoadingReady(item.isSync, recorder)) {
                            item.isSync = 2; // 这里将ASR转译为空的消息标记为已经完成
                            setLoadingForSendMessage(false);
                        }
                        if (isReady(item.isSync, recorder)) {
                            item.isSync = 1; // 更新状态为正在发送消息中
                            setLoadingForSendMessage(true);
                            sendMessage({ ...item, newList }, recorder);
                        }
                    }
                });

                return newList;
            });
        }
    }, [recordList, sendMessage, setList, uploadFile]);

    useEffect(() => {
        coreHandler();
    }, [coreHandler]);

    // 开始任务
    const startTaskHandle = useCallback(
        (callback?) => {
            if (wrongQuestion) {
                setLoadingForTaskStart(true);
                request('/intelligentPartnerMistakeCollections/dialogDetail', {
                    // taskId: wrongQuestion.taskId,
                    // nodeId: wrongQuestion.nodeId,
                    // dialogueMode : wrongQuestion.dialogueMode ,
                    // ruleId: wrongQuestion.mistakeRuleDetailList?.[0].ruleId,
                    collectionId: wrongQuestion.id,
                })
                    .then(
                        (resp) => {
                            const {
                                words,
                                bindNodeId,
                                nodeType,
                                questionIdList,
                                hasAnswerFinishKnowledge,
                                ossKey,
                            } = resp.data;
                            const audioUrlList = new Array<string>();

                            setList([
                                createMessage({
                                    channel: 1,
                                    nodeType,
                                    content: words,
                                    ossKey: ossKey,
                                    startSecond: newStartSecond(),
                                }),
                            ]);

                            if (ossKey) {
                                audioUrlList.push(ossKey);
                                audioPlayHandle(ossKey); // 如果存在语音文件，将进行播放
                            }

                            setPassThroughParams({
                                dialogueMode: wrongQuestion.dialogueMode,
                                bindNodeId,
                                nodeType,
                                questionIdList,
                                hasAnswerFinishKnowledge,
                                questionClosely: null,
                                questionCloselyWordsIndex: null,
                            });
                            setRecord(resp.data?.record);
                            initStartSecond(); // 初始化任务开始时间
                            fire(callback);
                        },
                        (reason) => {
                            Udesk.ui.notify.error(reason.errorMsg);
                        }
                    )
                    .finally(() => {
                        setLoadingForTaskStart(false);
                    });
            }
        },
        [
            wrongQuestion,
            request,
            setList,
            newStartSecond,
            setPassThroughParams,
            initStartSecond,
            audioPlayHandle,
        ]
    );

    return {
        referenceWords,
        loadingForSendMessage,
        loadingForTaskStart, // 任务开始的loading
        passThroughParams,
        record,
        isLastNode,
        isOver,
        pause: stopAudioRecord,
        list,
        audioRef,
        audioEndedHandle,
        startTaskHandle,
        startAudioRecord,
    };
};
