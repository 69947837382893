import React, { useState, useCallback } from 'react';
import Locales from 'UdeskLocales';
import { Button } from 'udesk-ui';
import { InspectionTaskFoundResponse } from 'src/api/types';
import AddToTaskModal from 'Component/pages/components/AddToTaskModal';
// import './style.scss';

type Props = {
    taskList: InspectionTaskFoundResponse[];
    inspectDataSource: number;
    dataId: number;
    onAdded?: () => void;
    onCancel?: () => void;
};

const Template = React.memo((props: Props) => {
    const locales = Locales['current'];
    const [visible, setVisible] = useState<boolean>(false);

    const onAdded = useCallback(() => {
        props.onAdded?.();
        setVisible(false);
    }, [props.onAdded]);

    const onCancel = useCallback(() => {
        props.onCancel?.();
        setVisible(false);
    }, [props.onCancel]);

    return (
        <React.Fragment>
            <Button type='link' size='small' onClick={() => setVisible(true)}>{/* 添加至质检任务 */}{locales.components.recordDetail.components.addToTask.index.addToQualityInspectionTask}</Button>
            <AddToTaskModal {...props} onAdded={onAdded} onCancel={onCancel} visible={visible}></AddToTaskModal>
        </React.Fragment>
    );
});

export default React.memo(Template);
