import React from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import styled from 'styled-components';
import { Button, Form, Space, Switch, message } from 'udesk-ui';
import IPInput, { TemplatePropsValue as IPInputValue } from './components/IPInput';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { getCompanysLoginIpLimit, postCompanysLoginIpLimit } from 'src/api/companys/loginIp/limit';
import './style.scss';
import UdeskLocales from 'UdeskLocales';

const EnableIpLimit = {
    CLOSE: 0,
    OPEN: 1,
};

const Wrapper = styled.div`
    padding: 16px 16px 0;
`;

const Item = styled.div``;

type FormValues = { enableIpLimit: boolean; ipAddresses: IPInputValue[] };

const Template = React.memo((props: any) => {
    const [form] = Form.useForm<FormValues>();

    const { loading: getLoading } = useRequest(getCompanysLoginIpLimit, {
        onSuccess: (res) => {
            form.setFieldsValue({
                enableIpLimit: res.data?.enableIpLimit === EnableIpLimit.OPEN,
                ipAddresses: res.data?.ipAddresses?.map((i) => {
                    const start = i.split(';')[0];
                    const end = i.split(';')[1];

                    return {
                        isPeriod: start && end,
                        start,
                        end,
                    };
                }),
            });
        },
    });

    const { run: save, loading: postLoading } = useRequest(postCompanysLoginIpLimit, {
        manual: true,
        onSuccess: () => {
            message.success(/* 保存成功 */UdeskLocales['current'].pages.securitySettings.index.saveSuccessful);
        },
    });

    const onFinish = (values: FormValues) => {
        const isValid = values.ipAddresses?.every((i) =>
            i?.isPeriod ? i?.start && i?.end : i?.start
        );
        if (values.enableIpLimit && !isValid) {
            message.warning(/* 请完善配置 */UdeskLocales['current'].pages.securitySettings.index.pleaseImproveTheConfiguration);
            return;
        }

        const params = {
            enableIpLimit: values.enableIpLimit ? EnableIpLimit.OPEN : EnableIpLimit.CLOSE,
            ipAddresses: values.ipAddresses?.map((i) => {
                return i?.isPeriod ? `${i?.start};${i.end}` : i?.start;
            }) as string[],
        };

        save(params, {});
    };

    return (
        <Page
            pageBodyClassName="page-user-manage-center"
            title={/* 安全设置 */UdeskLocales['current'].pages.securitySettings.index.securitySetting}
            padding={true}
            loading={getLoading || postLoading}
        >
            <Wrapper>
                <Form<FormValues> form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        label={/* 员工登陆IP限制 */UdeskLocales['current'].pages.securitySettings.index.employeeLoginIPRestrictions}
                        name="enableIpLimit"
                        extra={
                            <>
                                <div>{/* 限制员工可登录的IP范围，超出范围不允许登陆。仅对网站生效，移动app不受限制，管理员不受限制。 */}{UdeskLocales['current'].pages.securitySettings.index.restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted}</div>
                                <div>{/* 新增或修改时，请填写外网IP段（同一C段）或某个固定外网IP地址。 */}{UdeskLocales['current'].pages.securitySettings.index.whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress}</div>
                            </>
                        }
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item label={/* 允许登陆的IP范围 */UdeskLocales['current'].pages.securitySettings.index.allowLoginIPRange}>
                        <Form.List name="ipAddresses">
                            {(fields, { add, remove }) => {
                                return (
                                    <Space direction="vertical">
                                        {fields.map((field, idx) => {
                                            return (
                                                <Item>
                                                    <Space align="baseline">
                                                        <Form.Item name={[field.name]} noStyle>
                                                            <IPInput />
                                                        </Form.Item>
                                                        {fields.length <= 1 ? null : (
                                                            <MinusCircleOutlined
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        )}
                                                    </Space>
                                                </Item>
                                            );
                                        })}
                                        <PlusCircleOutlined onClick={() => add()} />
                                    </Space>
                                );
                            }}
                        </Form.List>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" type="primary">{/* 保存 */}{UdeskLocales['current'].pages.securitySettings.index.preserve}</Button>
                    </Form.Item>
                </Form>
            </Wrapper>
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}

export default Udesk.react.udeskify(Component);
