import Udesk from '../../../udesk';
import ConditionModuleEnums from './condition-module-enums';

const PRIVATE_COMPONENT_STATES_SYMBOL = Symbol("_componentStates");
const AND_LOGICAL_PROPS = ["visible"];
const OR_LOGICAL_PROPS = ["disabled"];

export default class ConditionModuleStatesClass {
    constructor({ disabled = false, visible = true } = {}) {
        let componentStates = this[PRIVATE_COMPONENT_STATES_SYMBOL] = {
            visible: !!visible,
            disabled: !!disabled,
        };

        for (let item of ConditionModuleEnums) {
            this[item.key] = {
                visible: componentStates.visible,
                disabled: componentStates.disabled
            };
        }
    }

    mergeFrom(source) {
        let componentStates = this[PRIVATE_COMPONENT_STATES_SYMBOL];
        if (source != null) {
            for (let [key, sourceValue] of Object.entries(source)) {
                if (Object.prototype.hasOwnProperty.call(this,key)) {
                    if (sourceValue != null && this[key] != null) {
                        for (let [stateKey, sourceStateValue] of Object.entries(sourceValue)) {
                            if (Object.prototype.hasOwnProperty.call(componentStates,stateKey)) {
                                if (AND_LOGICAL_PROPS.includes(stateKey)) {
                                    sourceStateValue = componentStates[stateKey] && sourceStateValue;
                                } else if (OR_LOGICAL_PROPS.includes(stateKey)) {
                                    sourceStateValue = componentStates[stateKey] || sourceStateValue;
                                }
                            }
                            if (this[key][stateKey] !== sourceStateValue) {
                                Udesk.utils.object.set(this[key], stateKey, sourceStateValue);
                            }
                        }
                    }
                }
            }
        }
    }
}
