// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { BaseResponseListInspectionPointSystemDialogFoundResponse } from '../../types';

/**
 * 查询租户下所有的应用消息类型(不分页)
 *
 * @export
 * @tags 应用消息类型
 * @link [GET] /inspectionPointSystemDialogs/findAll
 */
export function getInspectionPointSystemDialogsFindAll(): Promise<BaseResponseListInspectionPointSystemDialogFoundResponse>;
export function getInspectionPointSystemDialogsFindAll(
    options: ApiOptions<never, never>
): Promise<BaseResponseListInspectionPointSystemDialogFoundResponse>;
export function getInspectionPointSystemDialogsFindAll(
    options?: ApiOptions<never, never>
): Promise<BaseResponseListInspectionPointSystemDialogFoundResponse> {
    return request<never, never, never>('/inspectionPointSystemDialogs/findAll', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getInspectionPointSystemDialogsFindAll',
    });
}

export const meta = [
    { tags: ['应用消息类型'], path: '/inspectionPointSystemDialogs/findAll', method: 'get' },
];
