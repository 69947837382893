import React from 'react';
import Udesk from 'Udesk';
import TemplateComponentsListRoute from './route';
import UdeskTable from 'Component/common/udesk-table';
import { PageHeader, Space, Input, Select, Button, Radio, Form } from 'udesk-ui';
import './style.scss';
const { Option } = Select;
export default class TemplateComponentsListTemplate extends TemplateComponentsListRoute {
    render() {
        let {
            targetItems,
            tableData,
            paging,
            keywords,
            needUpdate,
            disabled,
            order,
            moduleSourceType,
            moduleType,
            moduleTypesList,
        } = this.privates;
        let { actions, locales } = this;

        return (
            <div className="udesk-qa-web-page">
                <PageHeader
                    className="udesk-qa-web-page-header"
                    title={locales.pages.admin.templateComponent.list.title}
                />
                <div className="udesk-qa-web-page-body">
                    <div className="udesk-qa-web-page-body-root template-components-list-page">
                        <div className="template-components-list-page-search">
                            <Form layout="inline">
                                <Form.Item label={locales.pages.admin.templateComponent.list.name}>
                                    <Input.Search
                                        placeholder={
                                            locales.pages.admin.templateComponent.list.searchHolder
                                        }
                                        style={{ width: 200 }}
                                        value={keywords}
                                        onChange={actions.inputChange}
                                        onSearch={actions.inputSearch}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={locales.pages.admin.templateComponent.list.update}
                                >
                                    <Select
                                        value={needUpdate}
                                        onChange={actions.updatChange}
                                        style={{ width: 120 }}
                                    >
                                        {Udesk.enums.templateNeedUpdate.map((item) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={locales.pages.admin.templateComponent.list.moduleType}
                                >
                                    <Select
                                        value={moduleType}
                                        onChange={actions.moduleTypeChange}
                                        style={{ width: 120 }}
                                    >
                                        {moduleTypesList.map((item) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={
                                        locales.pages.admin.templateComponent.list.moduleSourceType
                                    }
                                >
                                    <Radio.Group
                                        value={moduleSourceType}
                                        onChange={actions.filterChange.params('moduleSourceType')}
                                        optionType="button"
                                    >
                                        <Radio.Button value={null}>
                                            {locales.labels.all}
                                        </Radio.Button>
                                        <Radio.Button value={1}>
                                            {locales.pages.admin.templateComponent.list.call}
                                        </Radio.Button>
                                        <Radio.Button value={2}>
                                            {locales.pages.admin.templateComponent.list.dialogue}
                                        </Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item>
                                    <Space>
                                        <Button
                                            type="primary"
                                            onClick={actions.search}
                                            loading={disabled}
                                        >
                                            {
                                                locales.components.pages.customerServiceRankList
                                                    .select.search
                                            }
                                        </Button>
                                        <Button onClick={actions.reset}>
                                            {
                                                locales.components.pages.customerServiceRankList
                                                    .select.reset
                                            }
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </div>
                        <div style={{ padding: '0 0 8px' }}>
                            {/* {locales.pages.admin.templateComponent.list.sort}： */}
                            <Radio.Group
                                value={order}
                                onChange={actions.filterChange.params('order')}
                                optionType="button"
                            >
                                <Radio.Button value={'updateTime'}>
                                    {locales.pages.admin.templateComponent.list.newest}
                                </Radio.Button>
                                <Radio.Button value={'fetchNum'}>
                                    {locales.pages.admin.templateComponent.list.usedMost}
                                </Radio.Button>
                            </Radio.Group>
                        </div>

                        <UdeskTable
                            columns={targetItems}
                            dataSource={tableData}
                            loading={disabled}
                            scroll={{ y: 'calc(100vh - 64px - 58px - 32px - 194px)' }}
                            pagination={{
                                onChange: actions.pageNumChange,
                                total: paging.total,
                                pageSize: paging.pageSize,
                                onShowSizeChange: actions.pageSizeChange,
                            }}
                        ></UdeskTable>
                    </div>
                </div>
            </div>
        );
    }
}
