import UdeskError from './udesk-error';

export default class NotImplementedError extends UdeskError {
    constructor(methodName, options) {
        let { message, fileName, lineNumber } = options || {};
        let innerMessage = message;
        if (!innerMessage) {
            if (methodName) {
                innerMessage = `\`${methodName}\` is not implemented yet`;
            } else {
                innerMessage = "The method is not implemented yet";
            }
        }
        super(innerMessage, fileName, lineNumber);
    }
}