import React, { useCallback, useMemo } from 'react';
import { getReviewCallAnalysisOrganizations } from 'src/api/review/call/analysis/organizations';
import { useLiveEffect } from '.';
import { isArray, isDefined, isNotEmpty, isNumber, isString } from '../core';
import { TreeSelect } from 'udesk-ui';
import { getIntelligentPartnerTaskInfosOrganizations } from 'src/api/intelligentPartnerTaskInfos/organizations';
import { getIntelligentPartnerTaskInfosTargetByLessonId } from 'src/api/intelligentPartnerTaskInfos/target/{lessonId}';

export function getUserKeyById(id) {
    return `${id}/u`;
}

export function getUserKeyByIds(ids) {
    if (isNotEmpty(ids)) {
        if (isString(ids)) {
            ids = ids.split(',');
        }
    }
    return ids.map(getUserKeyById);
}

export function getOrgKeyByIds(ids) {
    if (isNotEmpty(ids)) {
        if (isString(ids)) {
            ids = ids.split(',').map((id) => parseInt(id));
        }
    }
    return ids;
}

function useTreeData() {
    const [map, setMap] = React.useState();

    const getUserById = useCallback(
        (id) => {
            if (map) {
                return map.get(`${id}/u`);
            }
        },
        [map]
    );

    const getUserByIds = useCallback(
        (ids) => {
            if (isNotEmpty(ids)) {
                if (isString(ids)) {
                    ids = ids.split(',');
                }
                return ids.map((id) => getUserById(id));
            }
        },
        [getUserById]
    );

    const getOrgById = useCallback(
        (id) => {
            if (map) {
                return map.get(+id);
            }
        },
        [map]
    );

    const getOrgByIds = useCallback(
        (ids) => {
            if (isNotEmpty(ids)) {
                if (isString(ids)) {
                    ids = ids.split(',');
                }
                return ids.map((id) => getOrgById(id));
            }
        },
        [getOrgById]
    );

    const treeData = useMemo(() => {
        return map ? Array.from(map.values()) : [];
    }, [map]);

    return {
        treeData: treeData,
        setTreeData: setMap,
        getUserById,
        getUserByIds,
        getOrgById,
        getOrgByIds,
    };
}

export function useUserTreeData(isMultiple = true) {
    const { setTreeData, ...result } = useTreeData();

    useLiveEffect((success) => {
        getReviewCallAnalysisOrganizations().then(({ data }) => {
            const map = new Map();
            const { users, organizations } = data;

            if (users) {
                users.forEach((user) => {
                    const key = `${user.id}/u`;
                    map.set(key, {
                        key,
                        id: key,
                        pId: user.organizationId,
                        value: key,
                        title: user.realname,
                        data: user,
                    });
                });
            }

            if (organizations) {
                organizations.forEach((org) => {
                    const key = org.id;
                    map.set(key, {
                        key,
                        id: key,
                        pId: org.parentId,
                        value: key,
                        title: org.name,
                        data: org,
                        selectable: isMultiple,
                    });
                });
            }

            success(() => {
                setTreeData(map);
            });
        });
    }, []);

    return result;
}

export function useOrgTreeData(isMultiple = true) {
    const { setTreeData, ...result } = useTreeData();

    useLiveEffect((success) => {
        getReviewCallAnalysisOrganizations().then(({ data }) => {
            const map = new Map();
            const { organizations } = data;

            if (organizations) {
                organizations.forEach((org) => {
                    const key = org.id;
                    map.set(key, {
                        key,
                        id: key,
                        pId: org.parentId,
                        value: key,
                        title: org.name,
                        data: org,
                        selectable: isMultiple,
                    });
                });
            }

            success(() => {
                setTreeData(map);
            });
        });
    }, []);

    return result;
}

export function useCoachLessonUserTreeDataWithPermit(lessonId, isMultiple = true) {
    const { setTreeData, ...result } = useTreeData();

    useLiveEffect(
        (success) => {
            isDefined(
                lessonId,
                () => {
                    getIntelligentPartnerTaskInfosTargetByLessonId({
                        segments: {
                            lessonId,
                        },
                    }).then(({ data }) => {
                        const map = new Map();
                        const { users, organizations } = data;

                        if (users) {
                            users.forEach((user) => {
                                const key = `${user.id}/u`;
                                map.set(key, {
                                    key,
                                    id: key,
                                    pId: user.organizationId,
                                    value: key,
                                    title: user.realname,
                                    data: user,
                                });
                            });
                        }

                        if (organizations) {
                            organizations.forEach((org) => {
                                const key = org.id;
                                map.set(key, {
                                    key,
                                    id: key,
                                    pId: org.parentId,
                                    value: key,
                                    title: org.name,
                                    data: org,
                                    selectable: isMultiple,
                                });
                            });
                        }

                        success(() => {
                            setTreeData(map);
                        });
                    });
                },
                () => {
                    setTreeData(new Map());
                }
            );
        },
        [lessonId]
    );

    return result;
}

export function useCoachUserTreeDataWithPermit(isMultiple = true) {
    const { setTreeData, ...result } = useTreeData();

    useLiveEffect((success) => {
        getIntelligentPartnerTaskInfosOrganizations().then(({ data }) => {
            const map = new Map();
            const { users, organizations } = data;

            if (users) {
                users.forEach((user) => {
                    const key = `${user.id}/u`;
                    map.set(key, {
                        key,
                        id: key,
                        pId: user.organizationId,
                        value: key,
                        title: user.realname,
                        data: user,
                    });
                });
            }

            if (organizations) {
                organizations.forEach((org) => {
                    const key = org.id;
                    map.set(key, {
                        key,
                        id: key,
                        pId: org.parentId,
                        value: key,
                        title: org.name,
                        data: org,
                        selectable: isMultiple,
                    });
                });
            }

            success(() => {
                setTreeData(map);
            });
        });
    }, []);

    return result;
}

export function useCoachOrgTreeDataWithPermit(isMultiple = true) {
    const { setTreeData, ...result } = useTreeData();

    useLiveEffect((success) => {
        getIntelligentPartnerTaskInfosOrganizations().then(({ data }) => {
            const map = new Map();
            const { organizations } = data;

            if (organizations) {
                organizations.forEach((org) => {
                    const key = org.id;
                    map.set(key, {
                        key,
                        id: key,
                        pId: org.parentId,
                        value: key,
                        title: org.name,
                        data: org,
                        selectable: isMultiple,
                    });
                });
            }

            success(() => {
                setTreeData(map);
            });
        });
    }, []);

    return result;
}

export const UserTreeSelect = React.memo((props) => {
    const {
        value,
        onChange,
        width,
        treeCheckable,
        multiple,
        userFieldName = 'userIds',
        orgFieldName = 'organizationIds',
        ...treeProps
    } = props;

    const isMultiple = useMemo(() => {
        return treeCheckable || multiple;
    }, [multiple, treeCheckable]);

    const treeValue = useMemo(() => {
        let data = [];
        if (isMultiple) {
            if (value) {
                const userIds = Reflect.get(value, userFieldName);
                const organizationIds = Reflect.get(value, orgFieldName);

                if (userIds) {
                    data.splice(0, 0, ...getUserKeyByIds(userIds));
                }
                if (organizationIds) {
                    data.splice(0, 0, ...getOrgKeyByIds(organizationIds));
                }
            }
        } else {
            data = undefined;
            if (isNumber(value)) {
                data = getUserKeyById(value);
            }
        }
        return data;
    }, [isMultiple, orgFieldName, userFieldName, value]);

    const changeHandle = useCallback(
        (value, label, extra) => {
            let data = {
                [userFieldName]: [],
                [orgFieldName]: [],
            };
            if (isMultiple) {
                if (isArray(value)) {
                    value.forEach((id) => {
                        if (isNumber(id)) {
                            data[orgFieldName].push(id);
                        } else {
                            data[userFieldName].push(parseInt(id));
                        }
                    });
                }
                onChange(data);
            } else {
                const { preValue } = extra;
                if (isString(value)) {
                    if (isNotEmpty(value)) {
                        if (preValue.length > 0 && preValue[0].value === value) {
                            onChange(undefined);
                        } else {
                            onChange(parseInt(value));
                        }
                    }
                } else {
                    onChange(value);
                }
            }
        },
        [isMultiple, onChange, orgFieldName, userFieldName]
    );

    return (
        <TreeSelect
            style={{ width }}
            value={treeValue}
            onChange={changeHandle}
            multiple={multiple}
            treeCheckable={treeCheckable}
            treeNodeFilterProp="title"
            treeDataSimpleMode={true}
            {...treeProps}
        />
    );
});
